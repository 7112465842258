"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OnBoardingDto = void 0;
const base_dto_1 = require("../base.dto");
/**
 * This is the DTO that is returned from the onBoarding service.
 *
 * Try to keep this DTO as close to the OnBoarding model as possible.
 * @see OnBoarding
 */
class OnBoardingDto extends base_dto_1.BaseDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.OnBoardingDto = OnBoardingDto;
