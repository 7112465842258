import type { FC } from "react";
import React from "react";
import { CheckbookPaymentMethodEnum } from "@doorloop/dto";
import { View } from "DLUI/view";
import { Icon } from "DLUI/icon";
import { EnvelopePicture } from "assets/icons";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { methodSettingsMap } from "screens/outgoingPayments/outgoingPaymentsTransactions/outgoingPaymentsListItem";
import { UpdateNotification } from "screens/outgoingPayments/updateNotification";
import type { PersonOutgoingEPayInfoDto } from "@doorloop/dto";
import { DestinationRow, KeyValueRow } from "screens/outgoingPayments/actions/keyValueRow";
import ColorsEnum from "../../../../utils/colorsEnum";

const sectionGap = 30; // According to figma

interface SendEPaySummaryProps {
  accountName: string;
  method: CheckbookPaymentMethodEnum;
  payeeName: string;
  personEPayInfo: PersonOutgoingEPayInfoDto;
  amount: string;
}

export const SendEPayConfirmation: FC<SendEPaySummaryProps> = ({
  accountName,
  method,
  payeeName,
  personEPayInfo,
  amount
}: SendEPaySummaryProps) => {
  const isDigitalPayment = method === CheckbookPaymentMethodEnum.DIGITAL_PAYMENT;
  return (
    <View marginTop={sectionGap} paddingLeft={20} paddingRight={20} alignItems={"center"}>
      <View
        backgroundColorEnum={ColorsEnum.LightBlueFadedMore}
        borderRadius={15}
        alignItems={"center"}
        paddingTop={20}
        paddingBottom={20}
        marginBottom={sectionGap}
      >
        <Icon Source={EnvelopePicture} />
      </View>
      <Text
        value={AppStrings.Common.OutgoingPayments.AreYouSureYouWantToSendThisPayment}
        fontSize={16}
        bold
        marginBottom={sectionGap}
      />
      <HorizontalSeparationLine marginBottom={sectionGap} />
      <KeyValueRow keyText={AppStrings.Common.Account} valueText={accountName} />
      <KeyValueRow
        keyText={AppStrings.Leases.LeaseTransaction.Payment.PaymentMethod}
        valueText={methodSettingsMap[method].label}
      />
      <KeyValueRow keyText={AppStrings.Common.OutgoingPayments.Recipient} valueText={payeeName} />
      {personEPayInfo && <DestinationRow isDigitalPayment={isDigitalPayment} personEPayInfo={personEPayInfo} />}
      <KeyValueRow keyText={AppStrings.Leases.NewLease.LeaseRent.Amount} valueText={amount} isCurrency />
      {personEPayInfo && <UpdateNotification ePayValues={personEPayInfo} />}
    </View>
  );
};
