import React, { useCallback } from "react";
import type {
  RecurringTransactionDto,
  TaskDto,
  GetAllTasksQuery,
  GetAllRecurringTransactionsQuery
} from "@doorloop/dto";
import { type GetAllBaseQueryRequest, ObjectPermission, TaskViewObjectAccess } from "@doorloop/dto";
import { tasksApi } from "api/tasksApi";
import TasksEmptyDataView from "./tasksEmptyDataView";
import { RestrictedAccessHasAny } from "screens/settings/userRoles/restrictedAccessHasAny";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { useRemount } from "hooks/useRemount";
import { leasesRecurringTransactionsApi } from "api/leasesApi";
import { DataList } from "DLUI/dataList";
import { TasksListItemWrapper } from "screens/tasks/tasksList/tasksListItemWrapper";

const viewListPermissions: AnyPermissionClearance[] = [
  {
    permission: ObjectPermission.tasks,
    field: "viewList",
    requiredLevel: TaskViewObjectAccess.ALL
  },
  {
    permission: ObjectPermission.tasks,
    field: "viewList",
    requiredLevel: TaskViewObjectAccess.ASSIGNED_ONLY
  },
  {
    permission: ObjectPermission.workOrders,
    field: "viewList",
    requiredLevel: TaskViewObjectAccess.ALL
  },
  {
    permission: ObjectPermission.workOrders,
    field: "viewList",
    requiredLevel: TaskViewObjectAccess.ASSIGNED_ONLY
  },
  {
    permission: ObjectPermission.tenantRequests,
    field: "viewList",
    requiredLevel: TaskViewObjectAccess.ALL
  },
  {
    permission: ObjectPermission.tenantRequests,
    field: "viewList",
    requiredLevel: TaskViewObjectAccess.ASSIGNED_ONLY
  },
  {
    permission: ObjectPermission.ownerRequests,
    field: "viewList",
    requiredLevel: TaskViewObjectAccess.ALL
  },
  {
    permission: ObjectPermission.ownerRequests,
    field: "viewList",
    requiredLevel: TaskViewObjectAccess.ASSIGNED_ONLY
  }
];

export interface TasksListProps {
  filterObj?: GetAllTasksQuery | GetAllRecurringTransactionsQuery;
  didPressListItem?: (leaseId: string) => void;
  renderListHeader?: () => JSX.Element;
  hideRelatedToColumn?: boolean;
  onLoadingDataStateChange?: (loadingInProgress: boolean) => void;
  stickyHeaderId?: string;
  recurring?: boolean;
  variant?: string;
}

export function TasksList({
  filterObj,
  renderListHeader,
  hideRelatedToColumn,
  onLoadingDataStateChange,
  stickyHeaderId,
  recurring,
  variant
}: TasksListProps) {
  const { shouldRender, triggerRemount } = useRemount();

  const _renderListHeader = useCallback(() => {
    if (renderListHeader) {
      return renderListHeader();
    }
    return <div />;
  }, [renderListHeader]);

  return (
    <RestrictedAccessHasAny permissions={viewListPermissions} showNoAccess>
      {shouldRender && (
        <>
          <DataList<RecurringTransactionDto | TaskDto, GetAllBaseQueryRequest>
            infinite
            restApi={recurring ? leasesRecurringTransactionsApi : tasksApi}
            emptyListView={() => <TasksEmptyDataView isRecurring={recurring} />}
            listHeader={_renderListHeader}
            queryFilter={filterObj}
            onLoadingDataStateChange={onLoadingDataStateChange}
            stickyHeaderProps={{
              id: stickyHeaderId
            }}
          >
            {(item, _) => (
              <TasksListItemWrapper
                item={item}
                recurring={recurring}
                refreshScreen={triggerRemount}
                hideRelatedToColumn={hideRelatedToColumn}
                stickyHeaderId={stickyHeaderId}
                key={"TL" + item.id}
                variant={variant}
              />
            )}
          </DataList>
        </>
      )}
    </RestrictedAccessHasAny>
  );
}
