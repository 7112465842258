export default {
  tab: {
    addNewNote: "Add New Note",
    unknown: "Unknown",
    createNewNote: "Create New Note",
    emptydataViewInstructionsText: "You Don't Have Any Notes",
    addedOnBy: "Added on {{date}} by {{userName}}",
    createdBy: "Created By",
    viewAs: "View As"
  },
  screen: {
    searchPlaceHolder: "Search notes"
  },
  dialog: {
    newNote: "New Note",
    helpDescription: "Help Description",
    editNote: "Edit Note",
    noteTitlePlaceholder: "What’s your note’s title?",
    noteBodyPlaceholder: "Write your note...",
    createNoteSuccessText: "Note was created",
    loadingText: "Creating Note",
    updatingText: "Updating Note",
    deleteConfirmationText: "Are you sure you want to delete the note?",
    deleteNoteLoadingText: "Deleting note",
    deleteNoteSuccessText: "Note was deleted successfully",
    learnAboutNotes: "Learn about creating and editing notes"
  }
};
