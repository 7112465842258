import type { RentRemindersSettingsDto } from "@doorloop/dto";

// 1. Limit settings levels
export enum SettingsLevels {
  Property = "property",
  Company = "company"
}

// 2. Add unions for multiple DTOs on your settings page
interface DTOs {
  dtoKey: "RentReminders";
  field?: keyof RentRemindersSettingsDto;
}

// 3. Specify field paths for each DTO and settings level
const settingLevelDtoKeyToFieldPathMap: Record<SettingsLevels, Record<DTOs["dtoKey"], string | undefined>> = {
  [SettingsLevels.Property]: {
    RentReminders: "settings.rentReminders"
  },
  [SettingsLevels.Company]: {
    RentReminders: undefined
  }
};

// NO NEED TO EDIT: Function that returns the field/key path for a given DTO and settings level
type GetFieldFunctionArgs = { settingLevel: SettingsLevels } & DTOs;
export type GetFieldKeyFunction = (args: GetFieldFunctionArgs) => string;
export const getRentRemindersDynamicSettingLevelFieldsPath: GetFieldKeyFunction = ({
  dtoKey,
  field,
  settingLevel
}): string => {
  const basePath = settingLevelDtoKeyToFieldPathMap[settingLevel]?.[dtoKey];

  if (!field) {
    if (!basePath) {
      throw new Error("Field or basePath is required");
    }

    return basePath;
  }

  return basePath ? `${basePath}.${field}` : field;
};

// Example usage:
// const fieldPath = getDynamicSettingLevelsFieldsPath({ dtoKey: "PortalRequestSettingsDto", field: "isSpecify", level: SettingsLevels.Lease });
