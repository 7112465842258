import AppStrings from "../../../../locale/keys";
import { MainMenuLayersKeys } from "DLUI/drawer/items/mainMenuItems";
import Routes from "../../../appRouter/routes";
import { ChartOfAccountDefaultFilter } from "screens/accounts/chartOfAccounts/chartOfAccounts.constants";
import { DataCy, ManagementFeeBasedType, ObjectPermission, ReportPermission } from "@doorloop/dto";
import { v4 as uuid } from "uuid";
import type { NavConfigProps } from "DLUI/drawer/types";
import { DrawerItemName, NavLevels } from "DLUI/drawer/types";

export const SubMenuAccountingLayers: NavConfigProps = {
  title: AppStrings.DrawerItems.Accounting,
  level: NavLevels.SUB_MENU,
  subMenuLayerKey: DrawerItemName.Accounting,
  groups: [
    {
      groupName: AppStrings.Common.OnlinePayments,
      parentNavigationKey: MainMenuLayersKeys.Accounting,
      items: [
        {
          label: AppStrings.Common.IncomingPayments.NavTitle,
          navKey: uuid(),
          navPath: [
            {
              name: Routes.INCOMING_PAYMENTS_MERCHANT_ACCOUNT_OVERVIEW_BALANCE_SUMMARY
            }
          ],
          clearance: [
            {
              permission: ReportPermission.banking
            }
          ],
          dataCy: DataCy.sideMenu.accounting.receivePayments,
          inactiveTag: { featureName: "ReceivePayments" }
        },
        {
          label: AppStrings.Common.OutgoingPayments.NavTitle,
          navKey: uuid(),
          inactiveTag: { featureName: "SendPayments" },
          navPath: [
            {
              name: Routes.OUTGOING_PAYMENTS
            }
          ],
          clearance: [
            {
              permission: ObjectPermission.outgoingPayments,
              field: "viewList"
            }
          ],
          dataCy: DataCy.sideMenu.accounting.outgoingPayments
        }
      ]
    },
    {
      groupName: AppStrings.Common.BookKeeping,
      parentNavigationKey: MainMenuLayersKeys.Accounting,
      items: [
        {
          label: AppStrings.DrawerItems.chartOfAccounts,
          navKey: uuid(),
          navPath: [
            {
              name: Routes.CHART_OF_ACCOUNTS,
              queryParams: ChartOfAccountDefaultFilter as Record<string, any>
            }
          ],
          dataCy: DataCy.sideMenu.accounting.chartOfAccounts,
          clearance: [
            {
              permission: ObjectPermission.accounts,
              field: "viewList"
            }
          ]
        },
        {
          label: AppStrings.DrawerItems.Transactions,
          navKey: uuid(),
          navPath: [
            {
              name: Routes.BOOKKEEPING_TRANSACTIONS_OVERVIEW
            }
          ],
          dataCy: DataCy.sideMenu.accounting.transactions,
          clearance: [] // TODO: what permissions do put here?
        },
        {
          label: AppStrings.DrawerItems.Budgets,
          navKey: uuid(),
          navPath: [
            { name: Routes.BOOKKEEPING_BUDGETS_LIST },
            { name: Routes.BOOKKEEPING_BUDGET_NEW },
            { name: Routes.BOOKKEEPING_BUDGET_EDIT }
          ],
          dataCy: DataCy.sideMenu.accounting.budgets,
          excludedDevices: ["tabletOrMobile"],
          clearance: [
            {
              permission: ObjectPermission.budgets,
              field: "viewList"
            }
          ]
        }
      ]
    },
    {
      groupName: AppStrings.DrawerItems.Expensess,
      parentNavigationKey: MainMenuLayersKeys.Accounting,
      items: [
        {
          label: AppStrings.Common.Recurring.RecurringTransactions,
          navKey: uuid(),
          navPath: [
            {
              name: Routes.RECURRING_BILLS_AND_EXPENSES
            }
          ],
          dataCy: DataCy.sideMenu.accounting.recurringBills
        },
        {
          label: AppStrings.Common.CreateNewMenu.PrintChecks,
          navKey: uuid(),
          navPath: [
            {
              name: Routes.PRINT_CHECKS
            }
          ],
          dataCy: DataCy.sideMenu.accounting.printChecks,
          clearance: [
            {
              permission: ReportPermission.printChecks
            }
          ]
        },
        {
          label: AppStrings.Common.CreateNewMenu.BillsAndExpenses,
          navKey: uuid(),
          navPath: [
            {
              name: Routes.BILLS_AND_EXPENSES
            }
          ],
          clearance: [
            {
              permission: ReportPermission.transactionsList
            }
          ],
          dataCy: DataCy.sideMenu.accounting.billsAndExpenses
        },
        {
          label: AppStrings.DrawerItems.OpenBills,
          navKey: uuid(),
          navPath: [
            {
              name: Routes.OPEN_BILLS_ACCOUNTS_PAYABLE_AGING_SUMMARY
            },
            {
              name: Routes.PAY_BILLS
            },
            {
              name: Routes.MANAGEMENT_FEES,
              queryParams: {
                filter_feeBasedType: ManagementFeeBasedType.MANAGEMENT_FEES_AND_ACCOUNTS,
                period: "last-month"
              }
            }
          ],
          dataCy: DataCy.sideMenu.accounting.openBills,
          clearance: [
            {
              permission: ReportPermission.accountsPayable
            }
          ]
        }
      ]
    },
    {
      groupName: AppStrings.Settings.GeneralSettings.DefaultAccounts.Income,
      parentNavigationKey: MainMenuLayersKeys.Accounting,
      items: [
        {
          label: AppStrings.DrawerItems.UnpaidRent,
          navKey: uuid(),
          navPath: [
            {
              name: Routes.INCOME_ACCOUNTS_RECEIVABLE_AGING_SUMMARY
            }
          ],
          dataCy: DataCy.sideMenu.accounting.unpaidRent,
          clearance: [
            {
              permission: ReportPermission.accountsReceivable
            }
          ]
        },
        {
          label: AppStrings.Common.CreateNewMenu.ChargesAndPayment,
          navKey: uuid(),
          navPath: [
            {
              name: Routes.CHARGES_AND_PAYMENT,
              matchMethod: "exact"
            }
          ],
          clearance: [
            {
              permission: ReportPermission.transactionsList
            }
          ],
          dataCy: DataCy.sideMenu.accounting.chargesAndPayment
        }
      ]
    }
  ]
};
