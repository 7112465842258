import React, { useRef } from "react";
import ReactPlayer from "react-player";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { DialogFrame } from "DLUI/dialogs";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import type { VideoItem } from "DLUI/screen/helpPanel/types";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps extends Partial<VideoItem> {
  onClose: () => void;
  onBackdropClick: () => void;
}

const playerConfig = {
  wistia: {
    options: {
      videoFoam: true,
      playsInline: false,
      copyLinkAndThumbnailEnabled: false,
      endVideoBehavior: "reset",
      fullscreenButton: true,
      autoPlay: true,
      fitStrategy: "contain"
    }
  }
};

export const SupportVideoDialog: React.FC<ComponentProps> = ({
  href,
  dialogTitle,
  dialogSubTitle,
  onBackdropClick,
  startTime
}: ComponentProps) => {
  const playerRef = useRef<ReactPlayer>(null);
  const { screenContainerPadding } = useResponsiveHelper();

  const handleOnReady = () => {
    if (startTime && playerRef?.current) {
      playerRef.current.seekTo(startTime);
    }
  };

  const renderView = () => (
    <View
      paddingTop={screenContainerPadding}
      paddingBottom={screenContainerPadding}
      paddingLeft={screenContainerPadding}
      paddingRight={screenContainerPadding}
      backgroundColor={"light"}
      alignItems={"flex-start"}
    >
      {dialogSubTitle && <Text value={dialogSubTitle} marginBottom={20} fontSize={18} color={"gray"} />}
      <View alignItems={"center"}>
        <ReactPlayer
          ref={playerRef}
          url={href}
          controls={true}
          config={playerConfig}
          style={{ border: "1px solid #2F3E83" }}
          width={"100%"}
          height={"auto"}
          onReady={handleOnReady}
        />
      </View>
    </View>
  );

  return (
    <DialogFrame
      onCloseButtonClick={onBackdropClick}
      title={dialogTitle}
      width={getDialogFrameDimension("width", 900)}
      height={getDialogFrameDimension("height", 630)}
      renderView={renderView}
      numViews={1}
      activeView={0}
      frameType={dialogTitle ? "sectionTitleFrame" : "contentOnly"}
      useExperimentalDialogFrame
    />
  );
};
