import type { ChangeEventHandler } from "react";
import React from "react";
import clsx from "clsx";
import { CircularProgress, InputBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { SearchIcon } from "@/assets";

import { Icon } from "DLUI/icon";
import AppStrings from "locale/keys";
import { useResponsiveHelper } from "contexts/responsiveContext";

import makeStyles from "./styles";
import "./styles.css";

export interface FilterPanelSearchInputProps {
  didChangeSearchQuery: ChangeEventHandler<HTMLInputElement>;
  searchQuery?: string;
  searchPlaceHolderText?: string;
  dataCy?: string;
  requestInProgress?: boolean;
}

const FilterPanelSearchInput = ({
  dataCy,
  requestInProgress,
  didChangeSearchQuery,
  searchQuery,
  searchPlaceHolderText = AppStrings.Common.Search
}: FilterPanelSearchInputProps) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();
  const classes = makeStyles();
  return (
    <div className={clsx([classes.searchInputContainer, "DoorLoop", "searchPanel"])} data-cy={dataCy}>
      <div className={clsx([classes.inputWrapper, "rounded", "inputWrapper"])} style={{ height: isMobile ? 44 : 40 }}>
        {searchPlaceHolderText && (
          <InputBase
            className={classes.searchInput}
            placeholder={t(searchPlaceHolderText)}
            inputProps={{ "aria-label": t(searchPlaceHolderText) }}
            onChange={didChangeSearchQuery}
            value={searchQuery}
          />
        )}

        {requestInProgress ? (
          <CircularProgress
            style={{
              width: 20,
              height: 20,
              marginRight: 20,
              color: "#7A8699"
            }}
          />
        ) : (
          <Icon Source={SearchIcon} width={16} height={16} marginRight={20} />
        )}
      </div>
    </div>
  );
};

export { FilterPanelSearchInput };
