import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { lazy } from "react";
import { LazyRoute } from "./lazyRoute";

const SubscriptionCheckoutScreen = lazy(
  () => import("screens/subscription/components/checkout/subscriptionCheckoutScreen")
);
const SubscriptionDetailsScreen = lazy(() => import("screens/subscription/subscriptionDetailsScreen"));
const SubscriptionScreen = lazy(() => import("screens/subscription/subscriptionScreen"));

const SubscriptionRouter = () => (
  <PageRouter>
    <LazyRoute
      key="checkoutPaymentMethodEdit"
      path={Routes.SUBSCRIPTION_CHECKOUT_EDIT_PAYMENT_METHOD}
      render={SubscriptionCheckoutScreen}
      exact
    />
    <LazyRoute
      key="subscriptionCheckout"
      path={Routes.SUBSCRIPTION_CHECKOUT}
      render={SubscriptionCheckoutScreen}
      exact
    />
    <LazyRoute
      key="detailsPaymentMethodEdit"
      path={Routes.SUBSCRIPTION_DETAILS_EDIT_PAYMENT_METHOD}
      render={SubscriptionDetailsScreen}
      exact
    />
    <LazyRoute key="subscriptionDetails" path={Routes.SUBSCRIPTION_DETAILS} render={SubscriptionDetailsScreen} exact />
    <LazyRoute path={Routes.SUBSCRIPTION} render={SubscriptionScreen} />
  </PageRouter>
);

export default SubscriptionRouter;
