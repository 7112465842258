"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConversationMessageType = void 0;
var ConversationMessageType;
(function (ConversationMessageType) {
    ConversationMessageType["SMS"] = "SMS";
    ConversationMessageType["MMS"] = "MMS";
    ConversationMessageType["EMAIL"] = "EMAIL";
    ConversationMessageType["VOICE"] = "VOICE";
    ConversationMessageType["NOTE"] = "NOTE";
    ConversationMessageType["SYSTEM"] = "SYSTEM";
})(ConversationMessageType || (exports.ConversationMessageType = ConversationMessageType = {}));
