import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    tabContainer: {
      width: "100%",
      height: "100%",
      display: "flex"
    },
    tabVisible: {
      display: "flex",
      flex: 1,
      width: "100%"
    },
    tabHidden: {
      position: "absolute",
      top: "1000px"
    },
    tabButton: {
      minWidth: "80px",
      minHeight: "30px"
    },
    tabTextColor: {
      color: theme.palette.black.main,
      textTransform: "none",
      fontFamily: "Lato",
      fontSize: "14px",
      lineHeight: "24px",
      paddingBottom: "5px",
      paddingTop: "5px"
    },
    tabTextColorSelected: {
      color: theme.palette.darkGreen.main
    }
  })
);
