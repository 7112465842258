import { DataCy } from "@doorloop/dto";
import type { useVirtualizer, VirtualItem } from "@tanstack/react-virtual";
import type { MutableRefObject } from "react";
import React, { memo } from "react";

export interface VirtualScrollProps<TElement extends HTMLElement> {
  children: (index: number) => React.ReactNode;
  virtualizer: ReturnType<typeof useVirtualizer<TElement, Element>>;
  gapBetweenRows?: number;
  containerRef?: MutableRefObject<HTMLDivElement | null>;
}

interface RowBlockProps<TElement extends HTMLElement>
  extends Omit<VirtualScrollProps<TElement>, "virtualizer" | "children"> {
  scrollMargin: number;
  measureElement: ReturnType<typeof useVirtualizer<TElement, Element>>["measureElement"];
  virtualRow: VirtualItem;
  renderRow: (index: number) => React.ReactNode;
}

const RowBlock = memo(
  <TElement extends HTMLElement>({
    renderRow,
    virtualRow,
    measureElement,
    scrollMargin,
    gapBetweenRows
  }: RowBlockProps<TElement>) => (
    <div
      role="listitem"
      key={virtualRow.key}
      data-index={virtualRow.index}
      ref={measureElement}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        transform: `translateY(${virtualRow.start - scrollMargin}px)`,
        display: "flex",
        padding: gapBetweenRows ? `${gapBetweenRows}px 0` : 0,
        width: "100%"
      }}
    >
      {renderRow(virtualRow.index)}
    </div>
  )
);

export function VirtualScroll<TElement extends HTMLElement>({
  children,
  virtualizer,
  gapBetweenRows,
  containerRef
}: VirtualScrollProps<TElement>) {
  return (
    <div
      ref={containerRef}
      style={{
        width: "100%"
      }}
    >
      <div
        data-cy={DataCy.DLUI.virtualScroll.scrollContainer}
        role="list"
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative"
        }}
      >
        {virtualizer.getVirtualItems().map((virtualRow) => (
          <RowBlock
            key={virtualRow.key}
            virtualRow={virtualRow}
            measureElement={virtualizer.measureElement}
            scrollMargin={virtualizer.options.scrollMargin}
            gapBetweenRows={gapBetweenRows}
            renderRow={children}
          />
        ))}
      </div>
    </div>
  );
}
