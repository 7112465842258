/**
 * companyInfo.png
 * lateFees.png
 * leases.png
 * payment.png
 * properties.png
 * scheduleSession.png
 * sendAnnouncement.png
 * staticPlaceholder.png
 * subscribe.png
 * tenantPortal.png
 * videoPlaceholder.png
 */

export const DesignImageFileNames = {
  SUPPORT_BLOB_BACKGROUND: "/images/onBoarding/supportBlobBackground.png",
  MATT_CAVE_FACE: "/images/onBoarding/cave.png"
} as const;

export const StepImageFileNames = {
  COMPANY_INFO: "/images/onBoarding/steps/companyInfo.png",
  LATE_FEES: "/images/onBoarding/steps/lateFees.png",
  LEASES: "/images/onBoarding/steps/leases.png",
  PAYMENT: "/images/onBoarding/steps/payment.png",
  PROPERTIES: "/images/onBoarding/steps/properties.png",
  SCHEDULE_SESSION: "/images/onBoarding/steps/scheduleSession.png",
  SEND_ANNOUNCEMENT: "/images/onBoarding/steps/sendAnnouncement.png",
  STATIC_PLACEHOLDER: "/images/onBoarding/steps/staticPlaceholder.png",
  SUBSCRIBE: "/images/onBoarding/steps/subscribe.png",
  TENANT_PORTAL: "/images/onBoarding/steps/tenantPortal.png",
  VIDEO_PLACEHOLDER: "/images/onBoarding/steps/videoPlaceholder.png"
} as const;
