import type { FilterPanelRemoteFiltering } from "DLUI/screen/filterPanel/filterPanel";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { BaseDto, GetAllBaseQueryRequest } from "@doorloop/dto";
import type { FormikMultiSelectAutoCompleteComponentProps } from "@/components/DLUI/form/autoComplete/formikAsyncAutoComplete/formikMultiSelectAutoComplete";
import { useSearchParams } from "@/hooks/useSearchParams";
import { useOptimizedValue } from "@/hooks/useOptimizedValue";
import { useUniqueId } from "@/hooks/useUniqueId";

export function useRemoteMultiAutocompleteFilterInput<
  TDto extends BaseDto,
  TGetAllDto extends GetAllBaseQueryRequest,
  TAutocomplete extends FormikMultiSelectAutoCompleteComponentProps<TDto, TGetAllDto>
>({
  apiHandler,
  name,
  displayNameKey,
  filterFieldValue,
  filterFieldName,
  queryParams = {}
}: Pick<
  FilterPanelRemoteFiltering<TDto>,
  "name" | "apiHandler" | "displayNameKey" | "filterFieldValue" | "filterFieldName" | "queryParams"
>): TAutocomplete {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryValue = useOptimizedValue(searchParams.getAll(filterFieldName as string));

  const { t } = useTranslation();
  const uniqueKey = useUniqueId();

  const handleChange = useCallback<NonNullable<TAutocomplete["onChange"]>>(
    (_, valueArray) => {
      if (!Array.isArray(valueArray) || valueArray.length === 0) {
        setSearchParams((searchParams) => {
          searchParams.delete(filterFieldName as string);
          return searchParams;
        }, "replace");
        return;
      }

      setSearchParams((searchParams) => {
        searchParams.delete(filterFieldName as string);

        valueArray.forEach((value) => {
          searchParams.append(filterFieldName as string, value.filterFieldValue);
        });

        return searchParams;
      }, "replace");
    },
    [filterFieldName, setSearchParams]
  );

  return useMemo<TAutocomplete>(
    () =>
      ({
        label: t(name),
        uniqueIndex: uniqueKey,
        defaultValue: queryValue,
        chips: "counterOnly",
        onChange: handleChange,
        apiHandler,
        displayNameKey,
        filterFieldName,
        filterFieldValue,
        queryParams
      }) as TAutocomplete,
    [
      apiHandler,
      displayNameKey,
      filterFieldName,
      filterFieldValue,
      name,
      queryParams,

      uniqueKey,
      t,
      handleChange,
      queryValue
    ]
  );
}
