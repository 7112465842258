import { InformationIcon, LargeKeyIcon, PetsIcon, PhotoIcon, SettingsIcon } from "assets/icons";
import type { MenuItem } from "DLUI/dialogs";
import AppStrings from "locale/keys";
import { EditPropertyGeneralInfo } from "./editPropertyGeneralInfo";
import type { PropertyTypeChange } from "./editPropertyClassAndType";
import { EditPropertyClassAndType } from "./editPropertyClassAndType";
import { EditPropertyAmenities } from "./editPropertyAmenities";
import { EditPropertyPetPolicy } from "./editPropertyPetPolicy";
import { EditPropertyPhotos } from "./editPropertyPhotos";

export const getPropertyFormMenuItems = (propertyTypeChange: PropertyTypeChange): MenuItem[] => [
  {
    title: AppStrings.Common.GeneralInformation,
    icon: SettingsIcon,
    contentComponent: () => <EditPropertyGeneralInfo />
  },
  {
    title: AppStrings.Listing.Photos,
    icon: PhotoIcon,
    contentComponent: () => <EditPropertyPhotos />
  },
  {
    title: AppStrings.Properties.Settings.PropertyType,
    icon: InformationIcon,
    contentComponent: () => <EditPropertyClassAndType onTypeChange={propertyTypeChange} />
  },
  {
    title: AppStrings.Listing.Amenities,
    icon: LargeKeyIcon,
    contentComponent: () => <EditPropertyAmenities />,
    category: "RESIDENTIAL"
  },
  {
    title: AppStrings.Listing.PetsPolicy,
    icon: PetsIcon,
    contentComponent: () => <EditPropertyPetPolicy />,
    category: "RESIDENTIAL"
  }
];
