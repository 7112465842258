import type { FC } from "react";
import React, { useState } from "react";
import AppStrings from "locale/keys";
import { ChainLinkIcon, CustomizeIcon } from "assets/icons";
import type { MenuItem } from "DLUI/dialogs";
import { AnimatedContentDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { ObjectPermission } from "@doorloop/dto";
import { useTranslation } from "react-i18next";
import { CheckbookSettingsTab } from "screens/settings/outgoingPayments/checkbookSettingsTab";
import {
  initCheckbookFormValues,
  validateCheckbookForm,
  validateForm
} from "screens/settings/outgoingPayments/formikHelper";
import { checkbookApi } from "api/checkbookApi";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import { SendingLimitsTab } from "screens/settings/outgoingPayments/sendingLimitsTab";

interface Props {
  onClose: () => void;
  onBackdropClick: () => void;
}

const sectionItems: (errorSetter: (hasError: boolean) => void) => MenuItem[] = (errorSetter) => [
  {
    title: AppStrings.Settings.GeneralSettings.Settings,
    icon: ChainLinkIcon,
    contentComponent: () => <CheckbookSettingsTab setError={errorSetter} />
  },
  {
    title: AppStrings.Common.OutgoingPayments.SendingLimits,
    icon: CustomizeIcon,
    contentComponent: () => <SendingLimitsTab />
  }
];
export const CheckbookSettingsDialog: FC<Props> = ({ onClose, onBackdropClick }: Props) => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState<boolean>(false);
  return (
    <AnimatedContentDialog
      dialogState={DialogState.Show}
      onClose={onClose}
      sectionItems={sectionItems(setHasError)}
      disableBackdropClick
      onBackdropClick={onBackdropClick}
      getformikInitialValues={initCheckbookFormValues}
      formikValidation={validateForm}
      validationMethod={validateCheckbookForm(hasError)}
      loadingText={t(AppStrings.Common.Loading)}
      errorText={t(AppStrings.Common.GeneralError)}
      apiMethod={checkbookApi}
      dialogTitle={t(AppStrings.Common.OutgoingPayments.OutgoingPayments)}
      dialogHeight={getDialogFrameDimension("height", 1000)}
      dialogWidth={getDialogFrameDimension("width", 1200)}
      permission={ObjectPermission.outgoingPayments}
    />
  );
};
