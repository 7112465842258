export default {
  GeneralSettings: {
    TagName: "settings.generalSettings.tagName",
    Settings: "settings.generalSettings.settings",
    GeneralSettings: "settings.generalSettings.generalSettings",
    Personal: "settings.generalSettings.personal",
    Company: "settings.generalSettings.company",
    RelatedLists: "settings.generalSettings.relatedLists",
    PropertySettings: "settings.generalSettings.propertySettings",
    PersonalInformationTitle: "settings.generalSettings.personalInformationTitle",
    PersonalInformationSubTitle: "settings.generalSettings.personalInformationSubTitle",
    LoginAndPasswordTitle: "settings.generalSettings.loginAndPasswordTitle",
    LoginAndPasswordSubTitle: "settings.generalSettings.loginAndPasswordSubTitle",
    LoginAndPasswordSettingsTitle: "settings.generalSettings.loginAndPasswordSettingsTitle",
    CompanyStartDate: {
      CompanyStartDateTitle: "settings.generalSettings.companyStartDate.companyStartDateTitle",
      CompanyStartDateSubTitle: "settings.generalSettings.companyStartDate.companyStartDateSubTitle",
      Dialog: {
        Subtitle: "settings.generalSettings.companyStartDate.subtitle",
        TenantInvite: "settings.generalSettings.companyStartDate.tenantInvite",
        RentReminder: "settings.generalSettings.companyStartDate.rentReminder",
        DoorloopStartDate: "settings.generalSettings.companyStartDate.doorloopStartDate",
        InfoText: "settings.generalSettings.companyStartDate.infoText"
      }
    },
    ConvenienceFees: {
      SettingsTitle: "settings.generalSettings.convenienceFees.settingsTitle",
      SettingsDialogSubTitle: "settings.generalSettings.convenienceFees.settingsDialogSubTitle",
      SettingsDialogBenefitsTitle: "settings.generalSettings.convenienceFees.settingsDialogBenefitsTitle",
      SettingsDialogBenefits1: "settings.generalSettings.convenienceFees.settingsDialogBenefits1",
      SettingsDialogBenefits2: "settings.generalSettings.convenienceFees.settingsDialogBenefits2",
      SettingsDialogBenefits3: "settings.generalSettings.convenienceFees.settingsDialogBenefits3",
      InputGroupLabel: "settings.generalSettings.convenienceFees.inputGroupLabel",
      InputAmountLabel: "settings.generalSettings.convenienceFees.inputAmountLabel",
      InputMethodLabel: "settings.generalSettings.convenienceFees.inputMethodLabel",
      UseCompanyDefaultConvenienceFees: "settings.generalSettings.convenienceFees.useCompanyDefaultConvenienceFees",
      SpecifyConvenienceFeesForProperty: "settings.generalSettings.convenienceFees.specifyConvenienceFeesForProperty",
      SpecifyConvenienceFeesForLease: "settings.generalSettings.convenienceFees.specifyConvenienceFeesForLease",
      HelpText: "settings.generalSettings.convenienceFees.helpText",
      DefaultAccountError: "settings.generalSettings.convenienceFees.defaultAccountError",
      WarningText: "settings.generalSettings.convenienceFees.warningText"
    },
    PaymentAllocation: {
      PaymentAllocationSettingsTitle: "settings.generalSettings.paymentAllocation.paymentAllocationSettingsTitle",
      PaymentAllocationSettingsDescription:
        "settings.generalSettings.paymentAllocation.paymentAllocationSettingsDescription",
      PaymentAllocationsSettingsDialogSubTitle:
        "settings.generalSettings.paymentAllocation.paymentAllocationsSettingsDialogSubTitle",
      PaymentAllocationsSettingsDialogHowToAllocate:
        "settings.generalSettings.paymentAllocation.paymentAllocationsSettingsDialogHowToAllocate",
      AllocateBy: "settings.generalSettings.paymentAllocation.allocateBy",
      Accounts: "settings.generalSettings.paymentAllocation.accounts",
      Categories: "settings.generalSettings.paymentAllocation.categories",
      AccountsReOrderDescription: "settings.generalSettings.paymentAllocation.accountsReOrderDescription",
      CategoriesReOrderDescription: "settings.generalSettings.paymentAllocation.categoriesReOrderDescription",
      AddAnAccount: "settings.generalSettings.paymentAllocation.addAnAccount",
      AddACategory: "settings.generalSettings.paymentAllocation.addACategory",
      HelpText: "settings.generalSettings.paymentAllocation.helpText",
      NoAccountsSelectedForAllocation: "settings.generalSettings.paymentAllocation.noAccountsSelectedForAllocation"
    },
    CompanyInformationTitle: "settings.generalSettings.companyInformationTitle",
    CompanyInformationSubTitle: "settings.generalSettings.companyInformationSubTitle",
    RegionAndCurrencyTitle: "settings.generalSettings.regionAndCurrencyTitle",
    RegionAndCurrencySubTitle: "settings.generalSettings.regionAndCurrencySubTitle",
    DefaultAccountsTitle: "settings.generalSettings.defaultAccountsTitle",
    DefaultAccountsSubTitle: "settings.generalSettings.defaultAccountsSubTitle",
    OwnerPortalTitle: "settings.generalSettings.ownerPortalTitle",
    UsersTitle: "settings.generalSettings.usersTitle",
    UsersSubTitle: "settings.generalSettings.usersSubTitle",
    UserRolesTitle: "settings.generalSettings.userRolesTitle",
    UserRolesSubTitle: "settings.generalSettings.userRolesSubTitle",
    QuickbooksIntegrationTitle: "settings.generalSettings.quickbooksIntegrationTitle",
    QuickbooksIntegrationSubTitle: "settings.generalSettings.quickbooksIntegrationSubTitle",
    ListingsTitle: "settings.generalSettings.listingsTitle",
    ListingsSubTitle: "settings.generalSettings.listingsSubTitle",
    AutomaticBackupsTitle: "settings.generalSettings.automaticBackupsTitle",
    AutomaticBackupsSubTitle: "settings.generalSettings.automaticBackupsSubTitle",
    CommunicationCenterTitle: "settings.generalSettings.communicationCenterTitle",
    CommunicationCenterSubTitle: "settings.generalSettings.communicationCenterSubTitle",
    CustomAllocations: {
      CustomAllocationsTitle: "settings.generalSettings.customAllocations.customAllocationsTitle",
      CustomAllocationsSubTitle: "settings.generalSettings.customAllocations.customAllocationsSubTitle",
      PropertyNameCustomAllocations: "settings.generalSettings.customAllocations.propertyNameCustomAllocations",
      DeleteAllocation: "settings.generalSettings.customAllocations.deleteAllocation",
      HelpPanel: {
        Description: "settings.generalSettings.customAllocations.helpPanel.description",
        LearnMore: "settings.generalSettings.customAllocations.helpPanel.learnMore"
      }
    },
    ApiKeys: {
      ApiKeys: "settings.generalSettings.apiKeys.apiKeys",
      ApiKeysTitle: "settings.generalSettings.apiKeys.apiKeysTitle",
      ApiKeysSubTitle: "settings.generalSettings.apiKeys.apiKeysSubTitle",
      ApiKeysDialogPanelHelpArticle1: "settings.generalSettings.apiKeys.apiKeysDialogPanelHelpArticle1",
      ApiKeysDialogPanelVideoTutorial: "settings.generalSettings.apiKeys.apiKeysDialogPanelVideoTutorial",
      ApiKeysNameTextError: "settings.generalSettings.apiKeys.apiKeysNameTextError",
      ApiKeysGetAllTextError: "settings.generalSettings.apiKeys.apiKeysGetAllTextError",
      ApiKeysCreateTextError: "settings.generalSettings.apiKeys.apiKeysCreateTextError",
      ApiKeysDeleteTextError: "settings.generalSettings.apiKeys.apiKeysDeleteTextError",
      ApiKeysUpdateTextError: "settings.generalSettings.apiKeys.apiKeysUpdateTextError",
      HelpPanel: {
        Description: "settings.generalSettings.apiKeys.helpPanel.description",
        LearnMore: "settings.generalSettings.apiKeys.helpPanel.learnMore",
        VideoTutorial: "settings.generalSettings.apiKeys.helpPanel.videoTutorial"
      }
    },
    PropertyGroupsTitle: "settings.generalSettings.propertyGroupsTitle",
    PropertyGroupsSubTitle: "settings.generalSettings.propertyGroupsSubTitle",
    TaskCategoriesTitle: "settings.generalSettings.taskCategoriesTitle",
    TaskCategoriesSubTitle: "settings.generalSettings.taskCategoriesSubTitle",
    VendorClassificationsTitle: "settings.generalSettings.vendorClassificationsTitle",
    VendorClassificationsSubTitle: "settings.generalSettings.vendorClassificationsSubTitle",
    LeadSourcesTitle: "settings.generalSettings.leadSourcesTitle",
    LeadSourcesSubTitle: "settings.generalSettings.leadSourcesSubTitle",
    NoteCategoriesTitle: "settings.generalSettings.noteCategoriesTitle",
    NoteCategoriesSubTitle: "settings.generalSettings.noteCategoriesSubTitle",
    FileCategoriesTitle: "settings.generalSettings.fileCategoriesTitle",
    FileCategoriesSubTitle: "settings.generalSettings.fileCategoriesSubTitle",
    UpdatingUserInformation: "settings.generalSettings.updatingUserInformation",
    LoadingUserInformation: "settings.generalSettings.loadingUserInformation",
    FailedToLoadUserInformation: "settings.generalSettings.failedToLoadUserInformation",
    UpdatingUserInformationSuccessText: "settings.generalSettings.updatingUserInformationSuccessText",
    TechnicianAccessEnabldUntil: "settings.generalSettings.technicianAccessEnabldUntil",
    TechnicianAccessEnabled: "settings.generalSettings.technicianAccessEnabled",
    ExpiresOn: "settings.generalSettings.expiresOn",
    AddressAndType: "settings.generalSettings.addressAndType",
    CompanyInfo: {
      CompanyInfo: "settings.generalSettings.companyInfo.companyInfo",
      GeneralInfo: "settings.generalSettings.companyInfo.generalInfo",
      AddCompanyLogo: "settings.generalSettings.companyInfo.addCompanyLogo",
      RecommendedSize: "settings.generalSettings.companyInfo.recommendedSize",
      PrimaryEmailAddress: "settings.generalSettings.companyInfo.primaryEmailAddress",
      PrimaryPhoneNumber: "settings.generalSettings.companyInfo.primaryPhoneNumber",
      CompanyWebsite: "settings.generalSettings.companyInfo.companyWebsite",
      UpdatingCompanyInformation: "settings.generalSettings.companyInfo.updatingCompanyInformation",
      LoadingCompanyInformation: "settings.generalSettings.companyInfo.loadingCompanyInformation",
      FailedToLoadCompanyInformation: "settings.generalSettings.companyInfo.failedToLoadCompanyInformation",
      UpdatingCompanyInformationSuccessText:
        "settings.generalSettings.companyInfo.updatingCompanyInformationSuccessText"
    },
    RentalApplications: {
      CompanyLink: "settings.generalSettings.rentalApplications.companyLink",
      CopyLinkDescription: "settings.generalSettings.rentalApplications.copyLinkDescription",
      PropertyLink: "settings.generalSettings.rentalApplications.propertyLink",
      GeneralInstructions: "settings.generalSettings.rentalApplications.generalInstructions",
      SectionRequired: "settings.generalSettings.rentalApplications.sectionRequired",
      GeneralInstructionsSectionDescription:
        "settings.generalSettings.rentalApplications.generalInstructionsSectionDescription",
      GeneralInstructionsExample: "settings.generalSettings.rentalApplications.generalInstructionsExample",
      AboutYou: "settings.generalSettings.rentalApplications.aboutYou",
      ApplicantInfo: "settings.generalSettings.rentalApplications.applicantInfo",
      AboutYouRentalApplicationDescription:
        "settings.generalSettings.rentalApplications.aboutYouRentalApplicationDescription",
      ManageFeeTitle: "settings.generalSettings.rentalApplications.manageFeeTitle",
      AboutYouSectionDescription: "settings.generalSettings.rentalApplications.aboutYouSectionDescription",
      Pets: "settings.generalSettings.rentalApplications.pets",
      PetsRentalApplicationDescription: "settings.generalSettings.rentalApplications.petsRentalApplicationDescription",
      AskAboutPets: "settings.generalSettings.rentalApplications.askAboutPets",
      PetsSectionDescription: "settings.generalSettings.rentalApplications.petsSectionDescription",
      VehicleInformation: "settings.generalSettings.rentalApplications.vehicleInformation",
      Vehicle: "settings.generalSettings.rentalApplications.vehicle",
      VehicleSectionDescription: "settings.generalSettings.rentalApplications.vehicleSectionDescription",
      Dependants: "settings.generalSettings.rentalApplications.dependants",
      DependantsSectionDescription: "settings.generalSettings.rentalApplications.dependantsSectionDescription",
      AskAboutDependants: "settings.generalSettings.rentalApplications.askAboutDependants",
      AskAboutVehicles: "settings.generalSettings.rentalApplications.askAboutVehicles",
      EmergencyContacts: "settings.generalSettings.rentalApplications.emergencyContacts",
      AskAboutEmergencyContacts: "settings.generalSettings.rentalApplications.askAboutEmergencyContacts",
      EmergencyContactsSectionDescription:
        "settings.generalSettings.rentalApplications.emergencyContactsSectionDescription",
      Employment: "settings.generalSettings.rentalApplications.employment",
      EmploymentSectionDescription: "settings.generalSettings.rentalApplications.employmentSectionDescription",
      AskAboutEmployment: "settings.generalSettings.rentalApplications.askAboutEmployment",
      AdditionalIncome: "settings.generalSettings.rentalApplications.additionalIncome",
      AdditionalIncomeSectionDescription:
        "settings.generalSettings.rentalApplications.additionalIncomeSectionDescription",
      AskAboutAdditionalIncome: "settings.generalSettings.rentalApplications.askAboutAdditionalIncome",
      ResidentialHistory: "settings.generalSettings.rentalApplications.residentialHistory",
      Residential: "settings.generalSettings.rentalApplications.residential",
      ResidentialHistorySectionDescription:
        "settings.generalSettings.rentalApplications.residentialHistorySectionDescription",
      AdditionalInformationSectionDescription:
        "settings.generalSettings.rentalApplications.additionalInformationSectionDescription",
      filesAndDocumentsSectionDescription:
        "settings.generalSettings.rentalApplications.filesAndDocumentsSectionDescription",
      filesAndDocumentsToggleLabel: "settings.generalSettings.rentalApplications.filesAndDocumentsToggleLabel",
      AdditionalAttachments: "settings.generalSettings.rentalApplications.additionalAttachments",
      LimitedToOneFilePerAttachment: "settings.generalSettings.rentalApplications.limitedToOneFilePerAttachment",
      AskAboutResidentialHistory: "settings.generalSettings.rentalApplications.askAboutResidentialHistory",
      AskForAdditionalInformation: "settings.generalSettings.rentalApplications.askForAdditionalInformation",
      AdditionalInformation: "settings.generalSettings.rentalApplications.additionalInformation",
      CreateQuestions: "settings.generalSettings.rentalApplications.createQuestions",
      ManageFee: "settings.generalSettings.rentalApplications.manageFee",
      filesAndDocuments: "settings.generalSettings.rentalApplications.filesAndDocuments",
      RequestDocuments: "settings.generalSettings.rentalApplications.requestDocuments",
      Notifications: "settings.generalSettings.rentalApplications.notifications",
      ApplicationLink: "settings.generalSettings.rentalApplications.applicationLink",
      NotificationsDescription: "settings.generalSettings.rentalApplications.notificationsDescription",
      NotifyUsersWhenSubmitted: "settings.generalSettings.rentalApplications.notifyUsersWhenSubmitted",
      TermsAndConditions: "settings.generalSettings.rentalApplications.termsAndConditions",
      TermsAndConditionsDescription: "settings.generalSettings.rentalApplications.termsAndConditionsDescription",
      RequireTermsAndConditions: "settings.generalSettings.rentalApplications.requireTermsAndConditions",
      AcceptedByLabel: "settings.generalSettings.rentalApplications.acceptedByLabel",
      AcceptedBy: "settings.generalSettings.rentalApplications.acceptedBy",
      AcceptedAt: "settings.generalSettings.rentalApplications.acceptedAt",
      AcceptTermsAndConditions: "settings.generalSettings.rentalApplications.acceptTermsAndConditions"
    },
    doorLoopURL: {
      DoorLoopURL: "settings.generalSettings.doorLoopURL.doorLoopURL",
      DoorLoopURLDescription: "settings.generalSettings.doorLoopURL.doorLoopURLDescription",
      TenantPortalURLDescription: "settings.generalSettings.doorLoopURL.tenantPortalURLDescription",
      OwnerPortalURLDescription: "settings.generalSettings.doorLoopURL.ownerPortalURLDescription",
      Subdomain: "settings.generalSettings.doorLoopURL.subdomain",
      UsersLoginURL: "settings.generalSettings.doorLoopURL.usersLoginURL",
      ClientPortalURL: "settings.generalSettings.doorLoopURL.clientPortalURL",
      OwnerPortalURL: "settings.generalSettings.doorLoopURL.ownerPortalURL"
    },
    AccountOwner: {
      AccountOwnerDescription: "settings.generalSettings.accountOwner.accountOwnerDescription",
      accountOwnerModify: "settings.generalSettings.accountOwner.accountOwnerModify"
    },
    RegionAndCurrency: {
      Region: "settings.generalSettings.regionAndCurrency.region",
      DateFormats: "settings.generalSettings.regionAndCurrency.dateFormats",
      Currency: "settings.generalSettings.regionAndCurrency.currency",
      NumberFormats: "settings.generalSettings.regionAndCurrency.currency",
      LoadingRegionAndCurrencyData: "settings.generalSettings.regionAndCurrency.loadingRegionAndCurrencyData",
      LoadingRegionAndCurrencyDataSuccessText:
        "settings.generalSettings.regionAndCurrency.loadingRegionAndCurrencyDataSuccessText",
      UpdatingRegionAndCurrencyData: "settings.generalSettings.regionAndCurrency.updatingRegionAndCurrencyData",
      UpdateRegionAndCurrencySuccessText:
        "settings.generalSettings.regionAndCurrency.updateRegionAndCurrencySuccessText"
    },
    DefaultAccounts: {
      OperatingAccountChangedNotificationTitle:
        "settings.generalSettings.defaultAccounts.operatingAccountChangedNotificationTitle",
      ConvenienceFeeIncome: "settings.generalSettings.defaultAccounts.convenienceFeeIncome",
      OperatingAccountChangedNotificationText:
        "settings.generalSettings.defaultAccounts.operatingAccountChangedNotificationText",
      DialogDescription: "settings.generalSettings.defaultAccounts.dialogDescription",
      Bank: "settings.generalSettings.defaultAccounts.bank",
      OperatingBankAccount: "settings.generalSettings.defaultAccounts.operatingBankAccount",
      TrustBankAccount: "settings.generalSettings.defaultAccounts.trustBankAccount",
      Income: "settings.generalSettings.defaultAccounts.income",
      RentalIncome: "settings.generalSettings.defaultAccounts.rentalIncome",
      ApplicationFee: "settings.generalSettings.defaultAccounts.applicationFee",
      Liability: "settings.generalSettings.defaultAccounts.liability",
      SecurityDeposit: "settings.generalSettings.defaultAccounts.securityDeposit",
      Expense: "settings.generalSettings.defaultAccounts.expense",
      ManagementFees: "settings.generalSettings.defaultAccounts.managementFees",
      Equity: "settings.generalSettings.defaultAccounts.equity",
      OwnerContribution: "settings.generalSettings.defaultAccounts.ownerContribution",
      OwnerDistribution: "settings.generalSettings.defaultAccounts.ownerDistribution",
      SelectAccount: "settings.generalSettings.defaultAccounts.selectAccount",
      SelectCategory: "settings.generalSettings.defaultAccounts.selectCategory",
      LoadingDefaultAccountsData: "settings.generalSettings.defaultAccounts.loadingDefaultAccountsData",
      LoadingDefaultAccountsDataSuccessText: "settings.generalSettings.loadingDefaultAccountsDataSuccessText.",
      UpdatingDefaultAccountsData: "settings.generalSettings.defaultAccounts.updatingDefaultAccountsData",
      UpdateDefaultAccountsSuccessText: "settings.generalSettings.defaultAccounts.updateDefaultAccountsSuccessText"
    },
    TaskCategories: {
      DialogDescription: "settings.generalSettings.taskCategories.dialogDescription",
      LoadingTaskCategories: "settings.generalSettings.taskCategories.loadingTaskCategories",
      LoadingTaskCategoriesDataSuccessText:
        "settings.generalSettings.taskCategories.loadingTaskCategoriesDataSuccessText"
    },
    NoteCategories: {
      DialogDescription: "settings.generalSettings.noteCategories.dialogDescription",
      LoadingNoteCategories: "settings.generalSettings.noteCategories.loadingNoteCategories",
      LoadingNoteCategoriesDataSuccessText:
        "settings.generalSettings.noteCategories.loadingNoteCategoriesDataSuccessText"
    },
    FileCategories: {
      DialogDescription: "settings.generalSettings.fileCategories.dialogDescription",
      LoadingFileCategories: "settings.generalSettings.fileCategories.loadingFileCategories",
      LoadingFileCategoriesDataSuccessText:
        "settings.generalSettings.fileCategories.loadingFileCategoriesDataSuccessText"
    },
    VendorClassifications: {
      DialogDescription: "settings.generalSettings.vendorClassifications.dialogDescription",
      LoadingVendorClassifications: "settings.generalSettings.vendorClassifications.loadingVendorCategories",
      LoadingVendorClassificationsDataSuccessText:
        "settings.generalSettings.vendorCategories.loadingVendorClassificationsDataSuccessText"
    },
    LeadSource: {
      DialogDescription: "settings.generalSettings.leadSource.dialogDescription",
      LoadingLeadSources: "settings.generalSettings.leadSource.loadingLeadSources",
      LoadingVendorCategoriesDataSuccessText:
        "settings.generalSettings.leadSource.loadingVendorCategoriesDataSuccessText"
    },
    PropertyGroups: {
      DialogDescription: "settings.generalSettings.propertyGroups.dialogDescription",
      LoadingPropertyGroups: "settings.generalSettings.propertyGroups.loadingPropertyGroups",
      LoadingPropertyGroupsDataSuccessText:
        "settings.generalSettings.propertyGroups.loadingPropertyGroupsDataSuccessText",
      PortfolioName: "settings.generalSettings.propertyGroups.portfolioName",
      PropertiesNames: "settings.generalSettings.propertyGroups.propertiesNames"
    },
    ManagementFees: {
      ManagementFees: "settings.generalSettings.managementFees.managementFees",
      CreateManagementFeeBills: "settings.generalSettings.managementFees.createManagementFeeBills",
      PropertiesWithManagementFees: "settings.generalSettings.managementFees.propertiesWithManagementFees",
      ManagementPayoutAccounts: "settings.generalSettings.managementFees.managementPayoutAccounts",
      ManagementPayoutCategories: "settings.generalSettings.managementFees.managementPayoutCategories",
      ManagementFeesDescription: "settings.generalSettings.managementFees.managementFeesDescription",
      ManagementFeesDialogDescription: "settings.generalSettings.managementFees.managementFeesDialogDescription",
      ChargeBasedPaymentsAccrual: "settings.generalSettings.managementFees.chargeBasedPaymentsAccrual",
      ChargeBasedPaymentsCash: "settings.generalSettings.managementFees.chargeBasedPaymentsCash",
      IncomeAccount: "settings.generalSettings.managementFees.incomeAccount",
      BaseCategory: "settings.generalSettings.managementFees.baseCategory",
      PayoutAccount: "settings.generalSettings.managementFees.payoutAccount",
      PayoutCategory: "settings.generalSettings.managementFees.payoutCategory",
      Fee: "settings.generalSettings.managementFees.fee",
      ExpenseCategory: "settings.generalSettings.managementFees.expenseCategory",
      AddAnotherAccount: "settings.generalSettings.managementFees.addAnotherAccount",
      AddAnotherCategory: "settings.generalSettings.managementFees.addAnotherCategory",
      AddAnotherFee: "settings.generalSettings.managementFees.addAnotherFee",
      FlatFeeCharge: "settings.generalSettings.managementFees.flatFeeCharge",
      FlatFeeChargeDescription: "settings.generalSettings.managementFees.flatFeeChargeDescription",
      MinimumFee: "settings.generalSettings.managementFees.minimumFee",
      MinimumFeeDescription: "settings.generalSettings.managementFees.minimumFeeDescription",
      PayoutManagementAccounts: "settings.generalSettings.managementFees.payoutManagementAccounts",
      PayoutManagementCategories: "settings.generalSettings.managementFees.payoutManagementCategories",
      PayoutManagementAccountsDescription:
        "settings.generalSettings.managementFees.payoutManagementAccountsDescription",
      PayoutManagementCategoriesDescription:
        "settings.generalSettings.managementFees.payoutManagementCategoriesDescription",
      SpecifyManagementFeesForProperty: "settings.generalSettings.managementFees.specifyManagementFeesForProperty",
      ManagementFeesScreenDescription: "settings.generalSettings.managementFees.managementFeesScreenDescription",
      ManagementFeesScreenDescription2: "settings.generalSettings.managementFees.managementFeesScreenDescription2",
      BillsCreatedSuccessfully: "settings.generalSettings.managementFees.billsCreatedSuccessfully",
      ManagementFeeBills: "settings.generalSettings.managementFees.managementFeeBills",
      ManagementAccountsPayout: "settings.generalSettings.managementFees.managementAccountsPayout",
      TotalBillsCreatedAmount: "settings.generalSettings.managementFees.totalBillsCreatedAmount",
      ViewBills: "settings.generalSettings.managementFees.viewBills"
    },
    CommunicationCenter: {
      SmsSettings: "settings.generalSettings.communicationCenter.smsSettings",
      SmsSettingsDescription: "settings.generalSettings.communicationCenter.smsSettingsDescription",
      SmsSettingsEnablePhoneNumberLabel:
        "settings.generalSettings.communicationCenter.smsSettingsEnablePhoneNumberLabel",
      SmsSettingsPhoneNumberDescription:
        "settings.generalSettings.communicationCenter.smsSettingsPhoneNumberDescription",
      SmsSettingsPhoneNumberCopy: "settings.generalSettings.communicationCenter.smsSettingsPhoneNumberCopy",
      MessagesSettingsNotifyAutomatically:
        "settings.generalSettings.communicationCenter.messagesSettingsNotifyAutomatically",
      MessagesSettingsTenantsTitle: "settings.generalSettings.communicationCenter.messagesSettingsTenantsTitle",
      MessagesSettingsTenantsAssignToUsersLabel:
        "settings.generalSettings.communicationCenter.messagesSettingsTenantsAssignToUsersLabel",
      MessagesSettingsOwnersTitle: "settings.generalSettings.communicationCenter.messagesSettingsOwnersTitle",
      MessagesSettingsOwnersAssignToUsersLabel:
        "settings.generalSettings.communicationCenter.messagesSettingsOwnersAssignToUsersLabel",
      MessagesSettingsVendorsTitle: "settings.generalSettings.communicationCenter.messagesSettingsVendorsTitle",
      MessagesSettingsVendorsAssignToUsersLabel:
        "settings.generalSettings.communicationCenter.messagesSettingsVendorsAssignToUsersLabel",
      MessagesSettingsProspectsTitle: "settings.generalSettings.communicationCenter.messagesSettingsProspectsTitle",
      MessagesSettingsProspectsAssignToUsersLabel:
        "settings.generalSettings.communicationCenter.messagesSettingsProspectsAssignToUsersLabel"
    }
  }
};
