import {
  DataCy,
  InsurancePolicyStatus,
  LeaseDraftStatus,
  LeaseStatus,
  ReportId,
  ReportPermission
} from "@doorloop/dto";
import AppStrings from "locale/keys";
import moment from "moment";
import { NavigationManager } from "utils/navigation";
import type { ReportScreenItemType } from "DLUI/report/screen/reportScreenItemType";
import { filterObjectsByUserType } from "../../../../../utils/userTypesUtils";

const didPressActivatedDraftLeases = (propertyId?: string) => {
  const params: Record<string, string> = {
    groupBy: "property",
    filter_status: LeaseDraftStatus.Activated
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runDraftLeasingReport(params);
};

const didPressCancelledDraftLeases = (propertyId?: string) => {
  const params: Record<string, string> = {
    groupBy: "property",
    filter_status: LeaseDraftStatus.Cancelled
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runDraftLeasingReport(params);
};

const didPressDraftLeasesbyStatus = (propertyId?: string) => {
  const params: Record<string, string> = {
    groupBy: "status"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runDraftLeasingReport(params);
};

const didPressDraftLeasesbyProperty = (propertyId?: string) => {
  const params: Record<string, string> = {
    groupBy: "property"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runDraftLeasingReport(params);
};

const didPressRenewalStatusbyProperty = (propertyId?: string) => {
  const params: Record<string, string> = {
    groupBy: "property"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runLeasingReport(params);
};

const didPressUpcomingMoveIns = (propertyId?: string) => {
  const params: Record<string, string> = {
    groupBy: "property",
    filter_start_date_from: moment().add("7", "days").format("YYYY-MM-DD").toString()
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runLeasingReport(params);
};

const didPressUpcomingMoveOuts = (propertyId?: string) => {
  const params: Record<string, string> = {
    groupBy: "property",
    filter_end_date_to: moment().add("7", "days").format("YYYY-MM-DD").toString()
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runLeasingReport(params);
};

const didPressLeaseInsuranceSummary = (propertyId?: string) => {
  const params: Record<string, string> = {
    groupBy: "property",
    filter_proofOfInsuranceProvided: "true",
    filter_status: LeaseStatus.ACTIVE
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runLeaseInsuranceSummaryReport(params);
};

const didPressLeaseInsuranceDetails = (propertyId?: string) => {
  const params: Record<string, string> = {
    filter_status: InsurancePolicyStatus.ACTIVE,
    filter_lease_status: LeaseStatus.ACTIVE
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runLeaseInsuranceDetailsReport(params);
};

const didPressLeaseLateFees = (propertyId?: string) => {
  const params = {};
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runLeaseLateFeesReport(params);
};

const didPressLeaseAccountStatements = (propertyId?: string) => {
  const params = {};
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runLeaseAccountStatementsReport(params);
};

const items: ReportScreenItemType[] = [
  {
    description: AppStrings.Reports.ReportsScreen.ActivatedDraftLeases,
    onPress: didPressActivatedDraftLeases,
    permission: ReportPermission.leasing,
    id: ReportId.ACTIVE_DRAFT_LEASE,
    hiddenForUserTypes: ["HOAUser"],
    dataCy: DataCy.reports.screenItems.leasing.activatedDraftLeases
  },
  {
    description: AppStrings.Reports.ReportsScreen.CancelledDraftLeases,
    onPress: didPressCancelledDraftLeases,
    permission: ReportPermission.leasing,
    id: ReportId.CANCEL_DRAFT_LEASE,
    hiddenForUserTypes: ["HOAUser"],
    dataCy: DataCy.reports.screenItems.leasing.canceledDraftLeases
  },
  {
    description: AppStrings.Reports.ReportsScreen.DraftLeasesbyStatus,
    onPress: didPressDraftLeasesbyStatus,
    permission: ReportPermission.leasing,
    id: ReportId.DRAFT_LEASE_BY_STATUS,
    hiddenForUserTypes: ["HOAUser"],
    dataCy: DataCy.reports.screenItems.leasing.draftLeasesByStatus
  },
  {
    description: AppStrings.Reports.ReportsScreen.DraftLeasesbyProperty,
    onPress: didPressDraftLeasesbyProperty,
    permission: ReportPermission.leasing,
    id: ReportId.DRAFT_LEASE_BY_PROPERTY,
    hiddenForUserTypes: ["HOAUser"],
    dataCy: DataCy.reports.screenItems.leasing.draftLeasesByProperty
  },
  {
    description: AppStrings.Reports.ReportsScreen.RenewalStatusbyProperty,
    onPress: didPressRenewalStatusbyProperty,
    permission: ReportPermission.leasing,
    id: ReportId.RENEWAL_STATUS_BY_PROPERTY,
    hiddenForUserTypes: ["HOAUser"],
    dataCy: DataCy.reports.screenItems.leasing.renewalStatusByProperty
  },
  {
    description: AppStrings.Reports.ReportsScreen.UpcomingMoveIns,
    onPress: didPressUpcomingMoveIns,
    permission: ReportPermission.leasing,
    id: ReportId.UPCOMING_MOVE_INS,
    hiddenForUserTypes: ["HOAUser"],
    dataCy: DataCy.reports.screenItems.leasing.upcomingMoveIns
  },
  {
    description: AppStrings.Reports.ReportsScreen.LeaseInsuranceSummaryReport,
    onPress: didPressLeaseInsuranceSummary,
    permission: ReportPermission.leasing,
    id: ReportId.LEASE_INSURANCE_SUMMARY,
    hiddenForUserTypes: ["HOAUser"],
    dataCy: DataCy.reports.screenItems.leasing.insuranceSummaryReport
  },
  {
    description: AppStrings.Reports.ReportsScreen.LeaseInsuranceDetailsReport,
    onPress: didPressLeaseInsuranceDetails,
    permission: ReportPermission.leasing,
    id: ReportId.LEASE_INSURANCE_DETAILS,
    hiddenForUserTypes: ["HOAUser"],
    dataCy: DataCy.reports.screenItems.leasing.insuranceDetailsReport
  },
  {
    description: AppStrings.Reports.ReportsScreen.UpcomingMoveOuts,
    onPress: didPressUpcomingMoveOuts,
    permission: ReportPermission.leasing,
    id: ReportId.UPCOMING_MOVE_OUTS,
    hiddenForUserTypes: ["HOAUser"],
    dataCy: DataCy.reports.screenItems.leasing.upcomingMoveOuts
  },
  {
    description: AppStrings.Reports.ReportsScreen.LeaseLateFeesReport,
    onPress: didPressLeaseLateFees,
    permission: ReportPermission.leasing,
    id: ReportId.LEASE_LATE_FEES,
    dataCy: DataCy.reports.screenItems.leasing.leaseLateFeesReport
  },
  {
    description: AppStrings.Reports.ReportsScreen.LeaseAccountStatementsReport,
    onPress: didPressLeaseAccountStatements,
    permission: ReportPermission.leasing,
    id: ReportId.LEASE_ACCOUNT_STATEMENTS,
    dataCy: DataCy.reports.screenItems.leasing.leaseAccountStatementsReport
  }
];

const getLeasingItems = (isHOAUser: boolean): ReportScreenItemType[] => {
  if (isHOAUser) {
    return filterObjectsByUserType(items, "HOAUser");
  }

  return items;
};

export default getLeasingItems;
