import type { PropsWithChildren } from "react";
import React from "react";
import { motion } from "framer-motion";
import type { CollapsibleTransitionProps } from "DLUI/accordion/types";

interface CollapsibleProps {
  isOpen: boolean;
  transitionProps?: CollapsibleTransitionProps;
}

export const Collapsible = ({ isOpen, transitionProps, children }: PropsWithChildren<CollapsibleProps>) => {
  if (transitionProps?.unmountOnExit && !isOpen) {
    return null;
  }

  return (
    <motion.div
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: isOpen ? "auto" : 0, opacity: isOpen ? 1 : 0 }}
      transition={{ duration: 0.3 }}
      style={{ overflow: "hidden" }}
    >
      {(transitionProps?.preload || isOpen) && children}
    </motion.div>
  );
};
