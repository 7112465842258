import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { leasesApi } from "api/leasesApi";
import { LeaseDto } from "@doorloop/dto";
import { View } from "DLUI/view";
import { Formik } from "formik";
import EditLeaseName from "DLUI/dialogs/settings/leases/editNameDialog/editName";
import AppStrings from "locale/keys";
import { useEffectAsync } from "../../../../../../hooks/useEffectAsync";
import { createValidator } from "@doorloop/dto";

const EditLeaseNameDialogFrameHeight = 515;
const EditLeaseNameDialogFrameWidth = 790;

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
}

const validateForm = createValidator(LeaseDto);

const EditLeaseNameFormikContextWrapper: React.FC<ComponentProps> = ({
  onBackdropClick,
  onClose,
  dialogTitle
}: ComponentProps) => {
  const { leaseId } = useParams<any>();
  const [leaseData, setLeaseData] = useState<LeaseDto | undefined>();
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const initFormValues = (): LeaseDto => leaseData || new LeaseDto();
  const loadData = async () => {
    if (leaseId) {
      setLoadingDialogState(DialogState.Show);
      const { status, data, message } = await leasesApi.get(leaseId);
      if (status && data) {
        setLeaseData(data);
        setLoadingDialogState(DialogState.Hidden);
      } else {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(message ? message : AppStrings.Common.GeneralError);
      }
    }
  };
  useEffectAsync(loadData, []);
  const renderLoadingView = (
    <View
      width={EditLeaseNameDialogFrameWidth}
      height={EditLeaseNameDialogFrameHeight}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <LoadingDialog
        dialogState={loadingDialogState}
        errorText={loadingDialogErrorText}
        didPressDismissButton={onBackdropClick}
        onRetryButtonPress={loadData}
        minHeight={EditLeaseNameDialogFrameHeight}
      />
    </View>
  );
  const renderForm = (
    <Formik initialValues={initFormValues()} onSubmit={() => {}} validate={validateForm}>
      <EditLeaseName onBackdropClick={onBackdropClick} onClose={onClose} dialogTitle={dialogTitle} />
    </Formik>
  );

  return loadingDialogState !== DialogState.Hidden ? renderLoadingView : renderForm;
};

export default EditLeaseNameFormikContextWrapper;
