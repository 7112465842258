"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseRenewalOfferStatus = void 0;
var LeaseRenewalOfferStatus;
(function (LeaseRenewalOfferStatus) {
    LeaseRenewalOfferStatus["Created"] = "Created";
    LeaseRenewalOfferStatus["Sent"] = "Sent";
    LeaseRenewalOfferStatus["Viewed"] = "Viewed";
    LeaseRenewalOfferStatus["Accepted"] = "Accepted";
    LeaseRenewalOfferStatus["Rejected"] = "Rejected";
    LeaseRenewalOfferStatus["Cancelled"] = "Cancelled";
})(LeaseRenewalOfferStatus || (exports.LeaseRenewalOfferStatus = LeaseRenewalOfferStatus = {}));
