import React, { type MouseEventHandler } from "react";
import { Icon } from "DLUI/icon";
import { CloseCircleDarkIcon, DoorloopIcon, LogoIcon, SearchIcon } from "../../../assets";
import AppStrings from "../../../locale/keys";
import { View } from "DLUI/view";
import DLButton from "DLUI/button/dlButton";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import { NavigationManager } from "utils/navigation";
import { DataCy } from "@doorloop/dto";
import { MainMenuLayersKeys } from "DLUI/drawer/items/mainMenuItems";
import type { NavLevels } from "DLUI/drawer/types";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import { useDrawerResponsiveDesign } from "DLUI/drawer/useDrawerResponsiveDesign";

interface ComponentProps {
  didPressGlobalNew: () => void;
  didPressGlobalSearch: () => void;
  didPressCloseDrawer: () => void;
  isMinimized?: boolean;
  isCollapsed?: boolean;
  isHovered?: boolean;
  onMouseOver?: MouseEventHandler<HTMLDivElement>;
  handleNavClick: ({
    subMenuSelected,
    menuItemSelection,
    mainMenuSelected,
    level
  }: {
    subMenuSelected?: string;
    menuItemSelection?: string;
    mainMenuSelected?: string;
    level?: NavLevels;
  }) => void;
}

const DrawerMainHeader: React.FC<ComponentProps> = ({
  didPressGlobalNew,
  didPressGlobalSearch,
  isMinimized,
  isHovered,
  isCollapsed,
  didPressCloseDrawer,
  handleNavClick,
  onMouseOver
}) => {
  const { isTabletOrMobile } = useResponsiveHelper();
  const { isDrawerCollapseFixed } = useDrawerResponsiveDesign();
  const localeData = useSelector((state: IRootState) => state.auth.currentLoginResponse);
  const doorLoopLogoClassName = isMinimized && !isHovered && isCollapsed && !isDrawerCollapseFixed ? "hidden-icon" : "";

  const didPressCompanyLogo = () => {
    if (localeData?.showOnBoardingOnLogin) {
      handleNavClick({ level: 0, menuItemSelection: MainMenuLayersKeys.Onboarding });
      NavigationManager.viewOnBoarding();
      return;
    }
    NavigationManager.viewHome();
    handleNavClick({ level: 0, menuItemSelection: MainMenuLayersKeys.Main });
  };

  return (
    <View
      paddingTop={10}
      paddingLeft={isTabletOrMobile ? 10 : 0}
      paddingRight={isTabletOrMobile ? 20 : 0}
      alignItems={"center"}
      marginBottom={isTabletOrMobile ? 10 : 0}
      onMouseOver={onMouseOver}
    >
      <View
        flexDirection={isTabletOrMobile && isMinimized ? "column" : "row"}
        noWrap
        alignItems={"center"}
        justifyContent={"space-between"}
        paddingLeft={isTabletOrMobile ? 10 : 21}
        marginBottom={30}
      >
        {isTabletOrMobile && (
          <View marginRight={5} autoWidth onClick={didPressCloseDrawer} marginBottom={isMinimized ? 25 : 0}>
            <Icon Source={CloseCircleDarkIcon} size={30} />
          </View>
        )}
        <View flexDirection={"row"} noWrap alignItems={"center"} autoWidth onClick={didPressCompanyLogo}>
          <View marginRight={5} autoWidth className={`main-menu-doorloop-icon ${doorLoopLogoClassName}`}>
            <Icon Source={LogoIcon} width={16} height={30} />
          </View>
          <View
            width={isMinimized ? "0" : "auto"}
            overflow={"hidden"}
            className={`main-menu-doorloop-icon ${doorLoopLogoClassName}`}
          >
            <Icon Source={DoorloopIcon} />
          </View>
        </View>
        {!isMinimized && isTabletOrMobile && (
          <View autoWidth onClick={didPressGlobalSearch}>
            <Icon Source={SearchIcon} pathColor={"white"} size={30} />
          </View>
        )}
      </View>
      <View
        alignItems={"center"}
        paddingLeft={10}
        paddingRight={isTabletOrMobile ? 0 : 5}
        height={40}
        flexDirection="row"
      >
        <DLButton
          actionText={isMinimized ? undefined : AppStrings.Common.CreateNew}
          onClick={didPressGlobalNew}
          icons={{ start: { isHidden: false } }}
          style={{ minWidth: "100%", width: isTabletOrMobile ? undefined : 40 }}
          dataCy={DataCy.sideMenu.createNewMenu.createNewButton}
        />
      </View>
    </View>
  );
};

export default DrawerMainHeader;
