"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CashFlowBulkObjectDto = exports.OwnerStatementReportOwnersBulkObjectDto = exports.OwnerStatementReportBulkResponseDto = void 0;
class OwnerStatementReportBulkResponseDto {
    constructor(owners, allOwnerIds) {
        this.owners = owners;
        this.allOwnerIds = allOwnerIds;
    }
}
exports.OwnerStatementReportBulkResponseDto = OwnerStatementReportBulkResponseDto;
class OwnerStatementReportOwnersBulkObjectDto {
    constructor(ownerData) {
        this.ownerData = ownerData;
    }
}
exports.OwnerStatementReportOwnersBulkObjectDto = OwnerStatementReportOwnersBulkObjectDto;
class CashFlowBulkObjectDto {
    constructor(data, columns) {
        this.data = data;
        this.columns = columns;
    }
}
exports.CashFlowBulkObjectDto = CashFlowBulkObjectDto;
