import React, { useCallback, useMemo } from "react";
import "./styles.css";
import { actionsPanelIconsTypesEnums, actionsPanelTypesEnums } from "DLUI/form/fieldsActionsPanel/actionPanels/enums";
import type { ActionsPanelInterface } from "DLUI/form/fieldsActionsPanel/actionPanels/useActionsPanels";
import ColorsEnum from "../../../../../utils/colorsEnum";
import ActionPanelItem from "../actionPanels/actionPanelItem";
import { SeparationLine } from "DLUI/separatorView";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";

interface ComponentProps {
  data: any;
  actionPanelType: Record<string, actionsPanelTypesEnums>;
  switchFieldModeAnimation: Record<string, string>;
  switchActionModeAnimation: Record<string, string>;
  setViewHideToggleIcon?: (icon: actionsPanelIconsTypesEnums) => void;
  viewHideToggleIcon?: actionsPanelIconsTypesEnums;
  actionsPanel?: ActionsPanelInterface;
  removeActionPanel?: ActionsPanelInterface;
  confirmActionPanel?: ActionsPanelInterface;
  addActionPanel?: ActionsPanelInterface;
  loadingActionPanel?: ActionsPanelInterface;
  validator?: any;
}

const ActionsPanel: React.FC<ComponentProps> = ({
  data,
  actionPanelType,
  switchFieldModeAnimation,
  switchActionModeAnimation,
  viewHideToggleIcon,
  setViewHideToggleIcon,
  actionsPanel,
  confirmActionPanel,
  removeActionPanel,
  addActionPanel,
  loadingActionPanel,
  validator
}: ComponentProps) => {
  const didPressAction = useCallback(
    async (event, actionPanel) => {
      if (actionPanel.onClick) {
        if (actionPanel.type !== actionsPanelIconsTypesEnums.CLOSE_ACTION_PANEL) {
          if (validator && validator(data)) {
            actionPanel.isAsync &&
              toggleSwitchModeAnimation(actionsPanelTypesEnums.LOADING_ACTIONS_PANEL, actionPanel.useSwitchAnimation);

            await actionPanel.onClick(event, {
              actionPanel,
              data,
              iconsConf: { setViewHideToggleIcon },
              editMode: actionPanelType.type === actionsPanelTypesEnums.EDIT_ACTIONS_PANEL
            });

            actionPanel.isAsync &&
              toggleSwitchModeAnimation(actionsPanelTypesEnums.READ_ONLY_ACTIONS_PANEL, actionPanel.useSwitchAnimation);
          }

          return;
        }

        actionPanel.onClick(event, {
          actionPanel,
          data,
          iconsConf: { setViewHideToggleIcon }
        });
      }

      let currTypeMode: actionsPanelTypesEnums;

      switch (actionPanel.type) {
        case actionsPanelIconsTypesEnums.EDIT_ACTION_PANEL:
          currTypeMode = actionsPanelTypesEnums.EDIT_ACTIONS_PANEL;
          break;
        case actionsPanelIconsTypesEnums.REMOVE_ACTION_PANEL:
          currTypeMode = actionsPanelTypesEnums.REMOVE_ACTIONS_PANEL;
          break;
        default:
          currTypeMode = actionsPanelTypesEnums.READ_ONLY_ACTIONS_PANEL;
      }

      toggleSwitchModeAnimation(currTypeMode, actionPanel.useSwitchAnimation);
    },
    [actionsPanel?.type, data]
  );

  const actions = useMemo(() => {
    let currActions;

    switch (actionPanelType?.type) {
      case actionsPanelTypesEnums.LOADING_ACTIONS_PANEL:
        currActions = loadingActionPanel && loadingActionPanel.actions;
        break;
      case actionsPanelTypesEnums.EDIT_ACTIONS_PANEL:
        currActions = confirmActionPanel && confirmActionPanel.actions;
        break;
      case actionsPanelTypesEnums.REMOVE_ACTIONS_PANEL:
        currActions = removeActionPanel && removeActionPanel.actions;
        break;
      case actionsPanelTypesEnums.ADD_ACTIONS_PANEL:
        currActions = addActionPanel && addActionPanel.actions;
        break;
      default:
        currActions = actionsPanel?.actions;
    }

    return currActions;
  }, [actionPanelType.type, data]);

  const toggleSwitchModeAnimation = useCallback((type, useSwitchAnimation) => {
    useSwitchAnimation && (switchActionModeAnimation.mode = "hide");

    setTimeout(() => {
      actionPanelType.type = type;

      switchFieldModeAnimation.mode = "show";
      switchActionModeAnimation.mode = "show";
    }, 250);
  }, []);

  return (
    <>
      {actions &&
        actions.map((actionPanel, index) => (
          <RestrictedPermissionAccess clearance={actionPanel.clearance} key={`${index}+${actionPanel.type}`}>
            {Boolean(index) && <SeparationLine height={20} color={ColorsEnum.LightGrayFaded} />}
            <ActionPanelItem
              {...actionPanel}
              onClick={async (ev) => await didPressAction(ev, actionPanel)}
              marginRight={5}
              marginLeft={5}
              viewHideToggleIcon={viewHideToggleIcon}
            />
          </RestrictedPermissionAccess>
        ))}
    </>
  );
};

export default ActionsPanel;
