import type { FC } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { View } from "DLUI/view";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import AppStrings from "locale/keys";

interface LoadingProps {
  errorText: string;
  retryHandler?: () => void;
  dismissHandler: () => void;
  hideRetryButton?: boolean;
}

export const SimpleLoading: FC<LoadingProps> = ({
  errorText,
  retryHandler,
  dismissHandler,
  hideRetryButton
}: LoadingProps) => {
  const { t } = useTranslation();
  return (
    <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
      <LoadingDialog
        dialogState={errorText ? DialogState.Error : DialogState.Show}
        loadingText={t(AppStrings.Common.Loading)}
        errorText={errorText}
        successText={t(AppStrings.Common.OperationCompleted)}
        onRetryButtonPress={retryHandler}
        didPressDismissButton={dismissHandler}
        hideRetryButton={hideRetryButton}
      />
    </View>
  );
};
