import React, { useMemo } from "react";
import { BulkExecutionDialogFrame } from "DLUI/bulkExecution/bulkExecutionDialogFrame";
import type { BulkPaymentWrapperDto, BulkReceiveSessionDto, LeasePaymentDto } from "@doorloop/dto";
import { useFormikContext } from "formik";
import type { Operation } from "engines/bulkOperationsEngine";
import { leasePaymentsApi } from "api/leasePaymentsApi";
import _ from "lodash";
import type { Path } from "react-hook-form";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import AppStrings from "locale/keys";

interface ComponentProps {
  onClose: (batch: string, shouldRefresh: boolean | undefined) => void;
}

const buildLeasePayment = (
  batch: string,
  session: BulkReceiveSessionDto,
  payment: BulkPaymentWrapperDto
): LeasePaymentDto | null => {
  if (!payment.isFinished) {
    return null;
  }

  const paymentClone = _.cloneDeep(payment);
  paymentClone.date = session.date;
  paymentClone.autoDeposit = session.autoDeposit;
  paymentClone.sendPaymentReceipt = session.sendPaymentReceipt;
  paymentClone.autoApplyPaymentOnCharges = session.autoApplyPaymentOnCharges;
  paymentClone.batch = batch;

  const fields: ReadonlyArray<Path<LeasePaymentDto>> = [
    "amountReceived",
    "lease",
    "date",
    "receivedFromTenant",
    "sendPaymentReceipt",
    "autoDeposit",
    "batch",
    "checkInfo",
    "checkInfo.checkNumber",
    "depositToAccount",
    "memo",
    "paymentMethod",
    "autoApplyPaymentOnCharges"
  ] as const;
  return _.pick(paymentClone, fields) as LeasePaymentDto;
};

const buildOperation = (
  t: TFunction<"translation">,
  batch: string,
  session: BulkReceiveSessionDto,
  payment: BulkPaymentWrapperDto
): Operation => {
  const ongoingMessage = t(AppStrings.BulkPayments.ProcessingPaymentForX, { x: payment.leaseName });

  return {
    action: async () => {
      const leasePayment = buildLeasePayment(batch, session, payment);
      if (!leasePayment) {
        console.warn("Cannot build lease payment for an unfinished payment, we're skipping it");
      } else {
        const result = await leasePaymentsApi.create(leasePayment, { isHidden: true });
        if (result.statusCode !== 200) {
          throw result.message;
        }
      }

      return { message: t(AppStrings.Common.OperationCompleted) };
    },
    ongoingMessage
  };
};

export const BulkExecution = ({ onClose }: ComponentProps) => {
  const { t } = useTranslation();
  const formikContext = useFormikContext<BulkReceiveSessionDto>();
  const [operations, setOperations] = React.useState<Operation[]>([]);

  const batch = useMemo(
    () => Array.from({ length: 24 }, () => "0123456789abcdef".charAt(Math.floor(Math.random() * 16))).join(""),
    []
  );

  React.useEffect(() => {
    setOperations(
      Object.values(formikContext.values.payments).map((payment) =>
        buildOperation(t, batch, formikContext.values, payment)
      )
    );
  }, [formikContext.values]);

  return (
    <BulkExecutionDialogFrame operations={operations} closeHandler={(shouldRefresh) => onClose(batch, shouldRefresh)} />
  );
};
