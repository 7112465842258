import MomentUtils from "@date-io/moment";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ApplicationFeesForm, {
  TRANSUNION_FULL_AMOUNT
} from "screens/settings/rentalApplications/components/applicationSettings/applicationFeesForm";

import { PeopleAutoComplete } from "@/components/DLUI/form/autoComplete/peopleAutoComplete/peopleAutoComplete";
import type { CreateOrUpdateRentalApplicationDto, MerchantAccountDto, PropertyDto } from "@doorloop/dto";
import {
  DataCy,
  MerchantAccountStatus,
  ObjectPermission,
  PersonTypeEnum,
  RentalApplicationChargeOptions,
  RentalApplicationFeeSettingsDto,
  SegmentEventTypes,
  TenantType
} from "@doorloop/dto";
import { Grid } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { FormikDatePicker, SwitchButton } from "DLUI/form";
import { Icon } from "DLUI/icon";
import { DialogRoutes } from "DLUI/screen/dialogsProvider";
import { SeparationLine } from "DLUI/separatorView";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { View } from "DLUI/view";
import { AnimatedView } from "DLUI/view/animatedView";
import { merchantAccountApi } from "api/bankAccountsApi";
import { propertiesApi } from "api/propertiesApi";
import { rentalApplicationsApi } from "api/rentalApplicationsApi";
import { settingsApi } from "api/settingsApi";
import { unitsApi } from "api/unitsApi";
import { usersApi } from "api/usersApi";
import { DoorloopTransUnionIcon } from "assets/icons";
import { FastField, useFormikContext } from "formik";

import { MINIMAL_CREDIT_CARD_FEE } from "screens/settings/rentalApplications/formikHelper";
import { RestrictedPermissionAccess } from "screens/settings/userRoles";
import { NavigationManager } from "utils/navigation";

import type { FrameType } from "@/components/DLUI/dialogs/components/dialogFrame";
import { getDialogFrameDimension } from "@/components/DLUI/dialogs/components/dialogFrame";
import { DialogState } from "screens/transactions/components/bankConnect/dialogs/matchTransactions/matchTransactionsDialog";

import { usePeopleAutoComplete } from "@/components/DLUI/form/autoComplete/peopleAutoComplete/usePeopleAutoComplete";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useToggle } from "usehooks-ts";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { useRentalApplicationEvents } from "../../../../hooks/useRentalApplicationEvents";
import { useRentalApplicationLocale } from "../../../../hooks/useRentalApplicationLocale";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import type { NewRentalApplicationRouteParams } from "./newRentalApplicationFormikContextWrapper.types";

import { FieldSafe } from "@/components/DLUI/fieldSafe/fieldSafe";
import { useTypedTranslation } from "@/locale";
import { isEmpty, size } from "lodash";
import { handleApiResult } from "../../../../api/entityApiStore/entityApiStore.utils";

export enum DialogView {
  FORM,
  LOADING
}

const DIALOG_VIEWS_NUM = size(DialogView);

export interface NewRentalApplicationDialogProps {
  onClose: () => void;
  onBackdropClick: () => void;
}

const dialogFrameWidth = getDialogFrameDimension("width", 880);
const dialogFrameHeight = getDialogFrameDimension("height", 800);

const NewRentalApplicationDialog = ({ onClose, onBackdropClick }: NewRentalApplicationDialogProps) => {
  const { t } = useTranslation();
  const { t: tt } = useTypedTranslation();

  const { screenContainerPadding, verticalInputPadding } = useResponsiveHelper();

  const location = useLocation();
  const routeParams = useParams<NewRentalApplicationRouteParams>();

  const formik = useFormikContext<CreateOrUpdateRentalApplicationDto>();
  const { setFieldValue, setFieldError } = formik;

  const isEditMode = Boolean(routeParams.applicationId && !location.pathname.includes(DialogRoutes.GLOBAL_NEW));

  const [viewIndex, setViewIndex] = useState(DialogView.FORM);

  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    isEditMode ? DialogState.Show : DialogState.Hidden
  );

  const { isMobile } = useResponsiveHelper();

  useEffect(() => {
    routeParams.prospectId && setFieldValue("tenant", routeParams.prospectId);
  }, [routeParams.prospectId, setFieldValue]);

  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState("");
  const [shouldRenderUnitsField, toggleShouldRenderUnitsField] = useToggle(true);

  const [merchantAccountData, setMerchantAccountData] = useState<MerchantAccountDto>();

  const [transunionFee, setTransunionFee] = useState<number>();
  const { fireEvent } = useRentalApplicationEvents({
    propertyId: formik.values.property,
    unitId: formik.values.unit
  });
  const { isUs } = useRentalApplicationLocale();

  const permission: AnyPermissionClearance = isEditMode
    ? { permission: ObjectPermission.rentalApplications, field: "edit" }
    : { permission: ObjectPermission.rentalApplications, field: "create" };

  const getRentalApplicationSettingsForProperty = useCallback(
    async (propertyId?: string) => {
      if (!propertyId) {
        return;
      }

      const responses = await Promise.all([
        merchantAccountApi.getAll({
          filter_property: propertyId,
          filter_status: MerchantAccountStatus.ACTIVE
        }),
        settingsApi.getRentalApplications(),
        propertiesApi.getRentalApplications(propertyId),
        isUs ? rentalApplicationsApi.checkTransunionEligibility(propertyId) : undefined
      ]).catch((error) => error);

      if (!responses) {
        setLoadingDialogErrorText(t("common.generalError"));
        setLoadingDialogState(DialogState.Error);
        return;
      }

      const [merchantAccountResponse, rentalApplicationsSettingsResponse, propertyRentalApplicationsSettingsResponse] =
        responses;

      if (merchantAccountResponse.status && merchantAccountResponse.data) {
        setMerchantAccountData(merchantAccountResponse.data.data[0]);
      }

      if (rentalApplicationsSettingsResponse.data && propertyRentalApplicationsSettingsResponse.data) {
        setTransunionFee(rentalApplicationsSettingsResponse.data.transunionFee);
        setFieldValue("transunionPropertyId", propertyRentalApplicationsSettingsResponse.data?.transunionPropertyId);
        setFieldValue("transunionLandlordId", rentalApplicationsSettingsResponse.data?.transunionLandlordId);
      }
    },
    [isUs, setFieldValue, t]
  );

  const [propertyId, setPropertyId] = useState(formik.values.property);

  useEffect(() => {
    getRentalApplicationSettingsForProperty(propertyId);
  }, [propertyId, getRentalApplicationSettingsForProperty]);

  const refreshUnitField = () => {
    toggleShouldRenderUnitsField();
    window.setTimeout(toggleShouldRenderUnitsField);
  };

  const handleDismiss = () => {
    onBackdropClick();
  };

  const renderView = ({ index }: { index: number }) => {
    if (index === DialogView.FORM) {
      return renderForm();
    }
    if (index === DialogView.LOADING) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          errorText={loadingDialogErrorText}
          successText={t(AppStrings.Tasks.InternalTaskDialog.CreateTaskSuccessText)}
          onRetryButtonPress={handleSave}
          didPressDismissButton={handleDismiss}
        />
      );
    }
    return <div />;
  };

  const handlePropertyChange = (_event: React.ChangeEvent<object>, propertyDto: PropertyDto) => {
    setPropertyId(propertyDto.id);
    setFieldValue("property", propertyDto.id);
    setFieldValue("unit", undefined);
    setFieldError("property", undefined);
    refreshUnitField();
  };

  const renderUnitAutoComplete = () => {
    if (!shouldRenderUnitsField) {
      return null;
    }

    return (
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <View paddingLeft={verticalInputPadding}>
          <FormikCachedAsyncAutoComplete
            uniqueIndex={"unit"}
            apiHandler={unitsApi}
            displayNameKey={"name"}
            filterFieldName={"filter_text"}
            filterFieldValue={"name"}
            selectionFields={["id", "class"]}
            name={"unit"}
            label={t(AppStrings.Leases.NewLease.Unit)}
            queryParams={{
              filter_property: propertyId,
              filter_rentalAppListed: true
            }}
            marginTop={20}
            defaultValue={formik.values.unit}
          />
        </View>
      </Grid>
    );
  };

  const handleToggleFees = (value: boolean) => {
    if (value) {
      const feesData: Pick<RentalApplicationFeeSettingsDto, "rentalApplicationChargeOption" | "applicationFee"> = isUs
        ? {
            applicationFee: TRANSUNION_FULL_AMOUNT,
            rentalApplicationChargeOption: RentalApplicationChargeOptions.CHARGE_APPLICANT
          }
        : {
            applicationFee: 0,
            rentalApplicationChargeOption: RentalApplicationChargeOptions.CHARGE_USER
          };
      formik.setFieldValue(
        "fees",
        new RentalApplicationFeeSettingsDto({
          requireOnlinePayment: false,
          enabled: true,
          ...feesData
        })
      );
    } else {
      formik.setFieldValue("fees", undefined);
    }
  };

  const renderAssignedToFields = () => {
    const selectedUnit = formik.values.unit;

    if (selectedUnit) {
      return (
        <View alignItems={"center"}>
          <View>
            <Text marginTop={20} fontSize={20} fontWeight={700} value={AppStrings.Tasks.TasksList.assignedTo} />
          </View>
          <View flexDirection={"row"}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <View paddingRight={verticalInputPadding}>
                <FormikCachedAsyncAutoComplete
                  uniqueIndex={"assignedToUser"}
                  apiHandler={usersApi}
                  displayNameKey={"name"}
                  filterFieldName={"filter_text"}
                  filterFieldValue={"name"}
                  selectionFields={["id", "class"]}
                  name={"assignedToUser"}
                  label={t(AppStrings.Tasks.TasksList.assignedTo)}
                  marginTop={20}
                  defaultValue={formik.values.assignedToUser}
                />
              </View>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <View paddingLeft={verticalInputPadding}>
                <FastField
                  component={FormikDatePicker}
                  uniqueKey={"expectedMoveInDate"}
                  label={AppStrings.Prospects.NewProspect.ExpectedMoveInDate}
                  name={"expectedMoveInDate"}
                  noMargin
                  marginTop={20}
                  minDate={moment().toDate()}
                />
              </View>
            </Grid>
          </View>
          <HorizontalSeparationLine marginTop={20} />
          <View marginTop={20}>
            <SwitchButton
              label={AppStrings.Common.SpecifyFeesForApplication}
              onChange={handleToggleFees}
              checked={formik.values.fees?.enabled}
            />
          </View>
          {formik.values.fees?.enabled && (
            <AnimatedView>
              <View height={20} />
              <ApplicationFeesForm
                formikRef={formik}
                merchantAccountData={merchantAccountData}
                transunionFee={transunionFee}
                hideTermsAndConditions={!formik.values.fees?.requestTransunionReports}
                hideCompanySettingsRadio
              />
              <View height={20} />
            </AnimatedView>
          )}
        </View>
      );
    }
  };

  const {
    state: { isIdle: isProspectIdle },
    props: prospectFieldProps
  } = usePeopleAutoComplete({
    currentType: PersonTypeEnum.TENANT,
    props: {
      disabled: isEditMode,
      entityId: formik.values.tenant,
      textFieldProps: {
        placeholder: t(AppStrings.Common.SelectProspect),
        error: Boolean(formik.errors.tenant),
        helperText: formik.errors.tenant
      },
      onChange(_event, value) {
        formik.setFieldValue("tenant", value?.data?.id, false);
        formik.setFieldError("tenant", value?.data?.id ? "" : tt("common.chooseAProspectWhoWillReceiveTheApplication"));
      },
      dataCy: DataCy.formFields.selectProspect
    },
    queryOptions: {
      typeFilter: [PersonTypeEnum.TENANT],
      tenantType: TenantType.PROSPECT_TENANT
    }
  });

  const renderForm = () => (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <View
        paddingLeft={screenContainerPadding}
        paddingRight={screenContainerPadding}
        marginTop={20}
        alignItems={"center"}
      >
        <RestrictedPermissionAccess clearance={permission} showNoAccess>
          <Grid container alignItems={"center"} spacing={4}>
            <Grid item xs={12} md={6}>
              <FieldSafe name="prospect" component={PeopleAutoComplete} {...prospectFieldProps} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Icon width={isMobile ? "100%" : 350} Source={DoorloopTransUnionIcon} />
            </Grid>
          </Grid>
          <SeparationLine width={"100%"} height={1} marginTop={20} />
          <View>
            <Text marginTop={20} fontSize={20} fontWeight={700} value={AppStrings.Properties.ListHeader.Property} />
          </View>
          <View flexDirection={"row"}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <View paddingRight={verticalInputPadding}>
                {/* Fix an issue where `input` event is triggered, causing the property field to change its value without an actual change event */}
                <FormikCachedAsyncAutoComplete
                  label={t(AppStrings.Common.FilterOptions.Property)}
                  uniqueIndex={"property"}
                  apiHandler={propertiesApi}
                  onChange={handlePropertyChange}
                  displayNameKey={"name"}
                  filterFieldName={"filter_text"}
                  filterFieldValue={"name"}
                  selectionFields={["id", "class"]}
                  name={"property"}
                  marginTop={20}
                  defaultValue={propertyId}
                  onChangeDelay={500}
                  queryParams={{ filter_active: true, filter_transunion_property_id: isUs ? true : undefined }}
                />
              </View>
            </Grid>
            {renderUnitAutoComplete()}
          </View>
          {renderAssignedToFields()}
        </RestrictedPermissionAccess>
      </View>
    </MuiPickersUtilsProvider>
  );

  const handleBackdropClick = () => {
    if (viewIndex > DialogView.FORM) {
      setViewIndex(viewIndex - 1);
      return;
    }
    onBackdropClick();
  };

  function showLoadingView() {
    setLoadingDialogState(DialogState.Show);
    setViewIndex(DialogView.LOADING);
  }

  function showErrorView(message: string = t("common.generalError")) {
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(message);
  }

  const createRentalApplication = async (values: CreateOrUpdateRentalApplicationDto) => {
    showLoadingView();

    fireEvent({
      eventName: SegmentEventTypes.RentalApplicationUserCompletedCreation,
      trackAnalyticsOptions: { trackEventInIntercom: true }
    });

    const [error, response] = await rentalApplicationsApi.create(values).then(handleApiResult);

    if (error) {
      showErrorView(error.message);
      return;
    }

    if (response.data?.id) {
      fireEvent({
        eventName: SegmentEventTypes.RentalApplicationUserFinishedApplicationCreation,
        trackAnalyticsOptions: { trackEventInIntercom: true }
      });

      NavigationManager.viewRentalApplicationDetails(response.data.id, "rental-application");
      return;
    }

    onClose();
  };

  const updateRentalApplication = async (values: CreateOrUpdateRentalApplicationDto) => {
    if (!values.id) {
      return;
    }

    showLoadingView();

    const [error, response] = await rentalApplicationsApi.update(values.id, values).then(handleApiResult);

    if (error) {
      showErrorView(error.message);
      return;
    }

    if (response.status) {
      onClose();
    }
  };

  function validateFees() {
    const isFeesEnabled = formik.values.fees?.enabled;

    if (!isFeesEnabled) {
      return true;
    }

    formik.setFieldTouched("fees.acceptedTermsAndConditions");

    const deductScreeningCostFromApplication = formik.values.fees?.deductScreeningCostFromApplication;
    const acceptedTermsAndConditions = formik.values.fees?.acceptedTermsAndConditions;

    if (formik.values.fees?.rentalApplicationChargeOption === RentalApplicationChargeOptions.CHARGE_CUSTOM_FEE) {
      formik.setFieldTouched("fees.applicationFee");
    }

    if (!acceptedTermsAndConditions) {
      return false;
    }

    if (formik.values.fees?.rentalApplicationChargeOption === RentalApplicationChargeOptions.CHARGE_CUSTOM_FEE) {
      const totalApplicationFee = formik.values.fees.applicationFee ?? 0;
      const minimalPaymentAmount = deductScreeningCostFromApplication
        ? (transunionFee ?? 0) + MINIMAL_CREDIT_CARD_FEE
        : MINIMAL_CREDIT_CARD_FEE;

      if (totalApplicationFee < minimalPaymentAmount) {
        return false;
      }
    }

    return true;
  }

  async function isValidForm() {
    formik.setFieldTouched("tenant");
    formik.setFieldTouched("property");

    if (!validateFees()) {
      return false;
    }

    const errors = await formik.validateForm();

    errors.tenant && formik.setFieldError("tenant", tt("common.chooseAProspectWhoWillReceiveTheApplication"));

    return isEmpty(errors);
  }

  const handleSave = async () => {
    const isValid = await isValidForm();

    if (isProspectIdle && isValid) {
      if (isEditMode) {
        updateRentalApplication(formik.values);
      } else {
        createRentalApplication(formik.values);
      }
    }
  };

  const renderActionPanelButtons = () => (
    <FormActionButtons
      propsSubButton={{ onClick: handleBackdropClick }}
      propsMainButton={{ type: "cta", props: { onClick: handleSave } }}
    />
  );

  const currentTitle = useMemo(() => {
    if (viewIndex === DialogView.FORM) {
      return AppStrings.Common.RentalApplication;
    }
    return "";
  }, [viewIndex]);

  const frameType: FrameType = useMemo(() => {
    if (viewIndex === DialogView.FORM) {
      return "sectionTitleFrame";
    }
    return "contentOnly";
  }, [viewIndex]);

  return (
    <DialogFrame
      onCloseButtonClick={handleBackdropClick}
      title={currentTitle}
      width={dialogFrameWidth}
      height={dialogFrameHeight}
      renderView={renderView}
      numViews={DIALOG_VIEWS_NUM}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={frameType}
      keepViewsMounted={true}
      useExperimentalDialogFrame
    />
  );
};

export default NewRentalApplicationDialog;
