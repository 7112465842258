import { ApiError } from "@doorloop/dto";

import type { GetAllBaseQueryResponse } from "@doorloop/dto";
import type { InfiniteData } from "@tanstack/react-query";
import type { ApiResult } from "api/apiResult";
import type { QueryFnData } from "./createMutationStore.types";

// https://http.dev/status
export enum StatusCode {
  OK = 200,
  WARNING = 299 // https://http.dev/299
}

export function isStatusOk(statusCode?: number) {
  const isOk = statusCode && statusCode >= StatusCode.OK && statusCode <= StatusCode.WARNING;
  return isOk;
}

export function mapApiResultToApiError<T>(apiResult: ApiResult<T>): ApiError {
  return new ApiError(
    apiResult.message,
    apiResult.errorCode,
    apiResult.errors,
    apiResult.statusCode,
    apiResult.validationErrors
  );
}

export function throwIfNotSuccessful<T>(apiResult: ApiResult<T>) {
  // https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
  const { statusCode } = apiResult;

  const isOk = isStatusOk(statusCode);

  if (isOk) {
    return;
  }

  const apiError = mapApiResultToApiError(apiResult);

  throw apiError;
}

// Workaround for vanilla API calls, where we still don't have `react-query`'s
// `useQuery` or `useMutation`.
export function handleApiResult<T>(
  apiResult: ApiResult<T>
): [ApiError | null, ApiResult<T>, { isEmptyResponse: boolean }] {
  const { statusCode } = apiResult;

  const isOk = isStatusOk(statusCode);

  if (isOk) {
    return [
      null,
      apiResult,
      { isEmptyResponse: Array.isArray(apiResult.data) ? apiResult.data.length === 0 : !apiResult.data }
    ];
  }

  const apiError = mapApiResultToApiError(apiResult);

  return [apiError, apiResult, { isEmptyResponse: true }];
}

export function isPaginatedState<TDto>(
  state: QueryFnData<TDto>
): state is InfiniteData<ApiResult<GetAllBaseQueryResponse<TDto>>> {
  return Boolean(state && Object.hasOwn(state, "pages"));
}
