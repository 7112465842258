import type { TenantNotificationSettingsDto, UserNotificationSettingsDto } from "@doorloop/dto";
import type { SettingOptions } from "./types";

export const userNotificationFieldNameToSettingOptions: Partial<
  Record<keyof UserNotificationSettingsDto, SettingOptions>
> = {
  upcomingWeekAgenda: {
    push: {
      disabled: true
    },
    text: {
      disabled: true
    }
  },
  conversationIncomingMessage: {
    email: {
      disabled: true
    }
  }
};

export const tenantNotificationFieldNameToSettingOptions: Partial<
  Record<keyof TenantNotificationSettingsDto, SettingOptions>
> = {
  requestReceived: {
    text: {
      disabled: true
    }
  },
  requestUpdated: {
    text: {
      disabled: true
    }
  }
};

export const hiddenSettingsForHOA: Array<keyof UserNotificationSettingsDto> = [
  "rentalApplicationSubmitted",
  "upcomingWeekAgenda"
];
