import { memo, useEffect } from "react";
import type { DatePickerProps } from "DLUI/form/datePicker/datePicker";
import DatePicker from "DLUI/form/datePicker/datePicker";
import type {
  FilterPanelDatePicker,
  FilterPanelOptionItem,
  FilterPanelRemoteFiltering,
  FilterPanelStaticOptionsFiltering,
  FilterPanelTextFieldFiltering
} from "DLUI/screen/filterPanel/filterPanel";
import { useAutocompleteFilterInput } from "../hooks/useAutocompleteFilterInput";
import type { AutocompleteProps } from "@material-ui/lab";
import { Autocomplete } from "@material-ui/lab";
import { useRemoteAutocompleteFilterInput } from "../hooks/useRemoteAutocompleteFilterInput";
import { useDatePickerFilterInput } from "../hooks/useDatePickerFilterInput";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import type { FilterOption } from "./types";
import { useDateRangePickerFilterInput } from "../hooks/useDateRangePickerFilterInput";
import type { DateRangePickerProps } from "@/components/DLUI/form/datePicker/dateRangePicker/dateRangePicker";
import { DateRangePicker } from "@/components/DLUI/form/datePicker/dateRangePicker/dateRangePicker";
import { useTextFilterInput } from "../hooks/useTextFilterInput";
import TextInput from "@/components/DLUI/form/textField/textInput";
import { useRemoteMultiAutocompleteFilterInput } from "../hooks/useRemoteMultiAutocompleteFilterInput";
import { DataCy } from "@doorloop/dto";
import type { BaseDto, GetAllBaseQueryRequest } from "@doorloop/dto";
import type { FormikMultiSelectAutoCompleteComponentProps } from "@/components/DLUI/form/autoComplete/formikAsyncAutoComplete/formikMultiSelectAutoComplete";
import FormikMultiSelectAutoComplete from "@/components/DLUI/form/autoComplete/formikAsyncAutoComplete/formikMultiSelectAutoComplete";
import type { FilterPanelDateRangePicker } from "@/components/DLUI/form/datePicker/dateRangePicker/types";

export interface FilterInputComponentProps<TQuery, TFilter extends FilterOption<TQuery>> {
  filterOption: TFilter;
}

interface OnValueChangeProps<TProps> {
  onValueChange?: (props: TProps) => void;
}

export const DatePickerFilterInput = memo(
  <TQuery,>({
    filterOption,
    onValueChange
  }: FilterInputComponentProps<TQuery, FilterPanelDatePicker<TQuery>> & OnValueChangeProps<DatePickerProps>) => {
    const datePickerProps = useDatePickerFilterInput(filterOption);

    useEffect(() => {
      setTimeout(() => {
        onValueChange?.(datePickerProps);
      }, 0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datePickerProps.pickerValue]);

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker {...datePickerProps} />
      </MuiPickersUtilsProvider>
    );
  }
);
DatePickerFilterInput.displayName = "DatePickerFilterInput";

export const DateRangePickerFilterInput = memo(
  <TQuery,>({
    filterOption,
    onValueChange
  }: FilterInputComponentProps<TQuery, FilterPanelDateRangePicker<TQuery>> &
    OnValueChangeProps<DateRangePickerProps>) => {
    const datePickerProps = useDateRangePickerFilterInput(filterOption);

    useEffect(() => {
      setTimeout(() => {
        onValueChange?.(datePickerProps);
      }, 0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datePickerProps.startDateValue, datePickerProps.endDateValue, datePickerProps.selectedPeriod]);

    return <DateRangePicker {...datePickerProps} dataCy={DataCy.DLUI.filters.dateRangeFilter} />;
  }
);
DateRangePickerFilterInput.displayName = "DateRangePickerFilterInput";

export const AutocompleteFilterInput = memo(
  <TQuery,>({
    filterOption,
    onValueChange
  }: FilterInputComponentProps<TQuery, FilterPanelStaticOptionsFiltering<TQuery>> &
    OnValueChangeProps<AutocompleteProps<FilterPanelOptionItem<TQuery, string>, false, boolean, false>>) => {
    const autocompleteProps = useAutocompleteFilterInput(filterOption);

    useEffect(() => {
      setTimeout(() => {
        onValueChange?.(autocompleteProps);
      }, 0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autocompleteProps.value]);

    return <Autocomplete {...autocompleteProps} />;
  }
);
AutocompleteFilterInput.displayName = "AutocompleteFilterInput";

export const RemoteAutocompleteFilterInput = memo(
  <TQuery,>({
    filterOption,
    onValueChange
  }: FilterInputComponentProps<TQuery, FilterPanelRemoteFiltering<TQuery>> &
    OnValueChangeProps<AutocompleteProps<FilterPanelOptionItem<TQuery, string>, false, boolean, false>>) => {
    const autocompleteProps = useRemoteAutocompleteFilterInput(filterOption);

    useEffect(() => {
      setTimeout(() => {
        onValueChange?.(autocompleteProps);
      }, 0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autocompleteProps.value]);

    return <Autocomplete {...autocompleteProps} />;
  }
);
RemoteAutocompleteFilterInput.displayName = "RemoteAutocompleteFilterInput";

export const RemoteMultiAutocompleteFilterInput = memo(
  <TDto extends BaseDto, TGetAllDto extends GetAllBaseQueryRequest>({
    filterOption,
    onValueChange
  }: FilterInputComponentProps<TDto, FilterPanelRemoteFiltering<TDto>> &
    OnValueChangeProps<FormikMultiSelectAutoCompleteComponentProps<TDto, TGetAllDto>>) => {
    const multiAutocompleteProps = useRemoteMultiAutocompleteFilterInput(filterOption);

    useEffect(() => {
      setTimeout(() => {
        onValueChange?.(multiAutocompleteProps);
      }, 0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multiAutocompleteProps.defaultValue]);

    return <FormikMultiSelectAutoComplete {...multiAutocompleteProps} />;
  }
);
RemoteMultiAutocompleteFilterInput.displayName = "RemoteMultiAutocompleteFilterInput";

export const TextFilterInput = memo(
  <TQuery,>({ filterOption }: FilterInputComponentProps<TQuery, FilterPanelTextFieldFiltering<TQuery>>) => {
    const textInputProps = useTextFilterInput(filterOption);

    return <TextInput {...textInputProps} />;
  }
);
TextFilterInput.displayName = "TextFilterInput";
