import type { ReportPermission, ReportsDto } from "@doorloop/dto";

import AppStrings from "locale/keys";
import { REPORTS_SCREEN_SECTION_GROUP_ITEMS } from "screens/ownerPortal/reports/reportsScreenSectionGroupItems";

export interface ReportSection {
  titleAppString?: string;
  items: ReportItem[];
}

export interface ReportItem {
  titleAppString: string;
  objectPath: ReportItemObjectPath;
}

export type ReportItemObjectPath = `reportsAccess.${keyof ReportsDto}`;

const allReportSections: ReportSection[] = [
  {
    titleAppString: AppStrings.UserRoles.BusinessOverview,
    items: [
      {
        titleAppString: AppStrings.UserRoles.BalanceSheet,
        objectPath: "reportsAccess.balanceSheet"
      },
      {
        titleAppString: AppStrings.UserRoles.CashFlowStatement,
        objectPath: "reportsAccess.cashFlowStatement"
      },
      {
        titleAppString: AppStrings.UserRoles.ProfitAndLoss,
        objectPath: "reportsAccess.profitAndLoss"
      },
      {
        titleAppString: AppStrings.UserRoles.RentRoll,
        objectPath: "reportsAccess.rentRoll"
      },
      {
        titleAppString: AppStrings.UserRoles.OwnerStatement,
        objectPath: "reportsAccess.ownerStatement"
      },
      {
        titleAppString: AppStrings.UserRoles.PropertyReserveFunds,
        objectPath: "reportsAccess.propertyReserveFunds"
      }
    ]
  },
  {
    titleAppString: AppStrings.UserRoles.Accounting,
    items: [
      {
        titleAppString: AppStrings.Reports.AccountRegister.AccountRegister,
        objectPath: "reportsAccess.accountRegister"
      },
      {
        titleAppString: AppStrings.UserRoles.ChartOfAccounts,
        objectPath: "reportsAccess.chartOfAccounts"
      },
      {
        titleAppString: AppStrings.UserRoles.Banking,
        objectPath: "reportsAccess.banking"
      },
      {
        titleAppString: AppStrings.UserRoles.BankingBalanceByProperty,
        objectPath: "reportsAccess.bankBalanceByProperty"
      },
      {
        titleAppString: AppStrings.UserRoles.BudgetVsActual,
        objectPath: "reportsAccess.budgetVsActual"
      },
      {
        titleAppString: AppStrings.UserRoles.PropertyAvailableFunds,
        objectPath: "reportsAccess.propertyAvailableFunds"
      },
      {
        titleAppString: AppStrings.UserRoles.OwnerAvailableFunds,
        objectPath: "reportsAccess.ownerAvailableFunds"
      },
      {
        titleAppString: AppStrings.UserRoles.LeaseTransactions,
        objectPath: "reportsAccess.leaseTransactions"
      },
      {
        titleAppString: AppStrings.UserRoles.LeaseDeposits,
        objectPath: "reportsAccess.leaseDeposits"
      },
      {
        titleAppString: AppStrings.UserRoles.OwnerTransactions,
        objectPath: "reportsAccess.ownerTransactions"
      },
      {
        titleAppString: AppStrings.UserRoles.VendorTransactions,
        objectPath: "reportsAccess.vendorTransactions"
      },
      {
        titleAppString: AppStrings.UserRoles.TransactionsList,
        objectPath: "reportsAccess.transactionsList"
      },
      {
        titleAppString: AppStrings.UserRoles.TransactionsDetails,
        objectPath: "reportsAccess.transactionsDetails"
      },
      {
        titleAppString: AppStrings.UserRoles.ARReports,
        objectPath: "reportsAccess.accountsReceivable"
      },
      {
        titleAppString: AppStrings.UserRoles.APReports,
        objectPath: "reportsAccess.accountsPayable"
      },
      {
        titleAppString: AppStrings.UserRoles.RecurringTransactions,
        objectPath: "reportsAccess.recurringTransactions"
      },
      {
        titleAppString: AppStrings.UserRoles.TrialBalance,
        objectPath: "reportsAccess.trialBalance"
      },
      {
        titleAppString: AppStrings.UserRoles.PrintChecks,
        objectPath: "reportsAccess.printChecks"
      },
      {
        titleAppString: AppStrings.UserRoles.Owners1099,
        objectPath: "reportsAccess.owners1099"
      },
      {
        titleAppString: AppStrings.UserRoles.Vendors1099,
        objectPath: "reportsAccess.vendors1099"
      }
    ]
  },
  {
    items: [
      {
        titleAppString: AppStrings.UserRoles.TenantReports,
        objectPath: "reportsAccess.tenants"
      }
    ]
  },
  {
    items: [
      {
        titleAppString: AppStrings.UserRoles.ProspectReports,
        objectPath: "reportsAccess.prospect"
      }
    ]
  },
  {
    items: [
      {
        titleAppString: AppStrings.UserRoles.LeasingReports,
        objectPath: "reportsAccess.leasing"
      }
    ]
  },
  {
    items: [
      {
        titleAppString: AppStrings.UserRoles.VendorReports,
        objectPath: "reportsAccess.vendors"
      }
    ]
  },
  {
    items: [
      {
        titleAppString: AppStrings.UserRoles.TaskReports,
        objectPath: "reportsAccess.tasks"
      }
    ]
  },
  {
    items: [
      {
        titleAppString: AppStrings.UserRoles.WorkOrderReports,
        objectPath: "reportsAccess.workOrders"
      }
    ]
  },
  {
    items: [
      {
        titleAppString: AppStrings.UserRoles.TenantRequestReports,
        objectPath: "reportsAccess.tenantRequests"
      }
    ]
  }
];

const currentReportPermissions: ReportItemObjectPath[] = [
  ...new Set(
    REPORTS_SCREEN_SECTION_GROUP_ITEMS.reduce((permissions, { items }) => {
      const uniqueSectionPermissions = [...new Set(items.map(({ permission }) => permission))];

      return [...permissions, ...uniqueSectionPermissions];
    }, [] as ReportPermission[])
  )
].map((permission) => `reportsAccess.${permission.replace("reports.", "")}` as ReportItemObjectPath);

export const reportSections = allReportSections
  .map((reportSection) => {
    const filteredItems = reportSection.items.filter((item) => currentReportPermissions.includes(item.objectPath));

    if (filteredItems.length === 0) {
      return null;
    }

    return {
      ...reportSection,
      items: filteredItems
    } as ReportSection;
  })
  .filter((reportSection) => reportSection !== null) as ReportSection[];
