import type { LateFeesPolicyDto } from "@doorloop/dto";
import { SegmentEventTypes } from "@doorloop/dto";

import type { AnalyticsSettingsSource } from "../../../../../../services/analyticsService";
import { analyticsService } from "../../../../../../services/analyticsService";

export const trackLateFeesSettings = (source: AnalyticsSettingsSource, settings?: LateFeesPolicyDto) => {
  analyticsService.track(SegmentEventTypes.NOTIFICATION_SETTINGS_LATE_FEES_SAVE_CLICKED, {
    Source: source,
    "One day before late fees reminder": Boolean(
      settings?.reminderTypesBeforeCharge?.email && settings?.reminderTypesBeforeCharge?.push
    ),
    "Late fees charge": Boolean(settings?.reminderTypesAfterCharge?.email && settings?.reminderTypesAfterCharge?.push),
    "Enable SMS": Boolean(settings?.reminderTypesBeforeCharge?.text && settings?.reminderTypesAfterCharge?.text),
    "Recent outstanding charge": Boolean(settings?.onlyChargeLateFeesOnMostRecentCharge),
    "Set Monthly late fees": Boolean(settings?.maxMonthlyLateFees),
    "Set Daily late fees": Boolean(settings?.maxDailyLateFees),
    "Set Minimum balance": Boolean(settings?.minBalanceToApplyLateFee),
    "Specific Outstanding charges": Boolean(settings?.lateFeeSettingsAccountsToConsider)
  });
};
