import React from "react";

import { FastField } from "formik";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import AppStrings from "locale/keys";
import FieldWrapper from "DLUI/form/textField/fieldWrapper";
import Text from "DLUI/text";
import { TextField } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import { View } from "DLUI/view";
import AddressAutoCompleteFormWrapper from "DLUI/form/autoComplete/addressAutoComplete/addressAutoCompleteFormWrapper";

interface ComponentProps {
  textTitle: string;
  formikFieldNamePrefix?: string;
  shouldShow?: boolean;
  renderForm?: boolean;
  renderSpecifyRadioButtons?: () => React.ReactNode;
}

export const PortalContactInfoForm: React.FC<ComponentProps> = ({
  textTitle,
  formikFieldNamePrefix = "contactInfo",
  shouldShow = true,
  renderForm = true,
  renderSpecifyRadioButtons
}: ComponentProps) => {
  const { t } = useTranslation();

  return (
    <View width={"100%"} flex={1}>
      <View>
        <Text color={"black"} fontSize={16} value={textTitle} />
        {renderSpecifyRadioButtons?.()}
        <View shouldShow={shouldShow} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
          {renderForm && (
            <View noWrap>
              <SeparationLine marginTop={20} width={"96%"} height={1} />
              <Text
                fontSize={20}
                marginTop={20}
                fontWeight={700}
                color={"black"}
                value={AppStrings.Settings.GeneralSettings.CompanyInfo.CompanyInfo}
              />
              <View flexDirection={"row"}>
                <Grid item xs={12} md={6} lg={6}>
                  <FieldWrapper type={"endElement"}>
                    <FastField
                      component={TextField}
                      label={t(AppStrings.Auth.CompanyName)}
                      name={`${formikFieldNamePrefix}.companyName`}
                    />
                  </FieldWrapper>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FieldWrapper type={"startElement"}>
                    <FastField
                      component={TextField}
                      label={t(AppStrings.Common.Website)}
                      name={`${formikFieldNamePrefix}.website`}
                    />
                  </FieldWrapper>
                </Grid>
              </View>
              <View flexDirection={"row"}>
                <Grid item xs={12} md={6} lg={6}>
                  <FieldWrapper type={"endElement"}>
                    <FastField
                      component={TextField}
                      label={t(AppStrings.Settings.GeneralSettings.CompanyInfo.PrimaryEmailAddress)}
                      name={`${formikFieldNamePrefix}.email`}
                    />
                  </FieldWrapper>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FieldWrapper type={"startElement"}>
                    <FastField
                      component={TextField}
                      label={t(AppStrings.Settings.GeneralSettings.CompanyInfo.PrimaryPhoneNumber)}
                      name={`${formikFieldNamePrefix}.phone`}
                    />
                  </FieldWrapper>
                </Grid>
              </View>
              <SeparationLine marginTop={20} width={"96%"} height={1} />
              <Text
                fontSize={20}
                marginTop={20}
                fontWeight={700}
                color={"black"}
                value={AppStrings.Common.CompanyAddress}
              />
              <AddressAutoCompleteFormWrapper
                withAddressVerification
                mustAcceptInvalidAddress
                formikName={`${formikFieldNamePrefix}.address`}
              />
            </View>
          )}
        </View>
      </View>
    </View>
  );
};
