import AppStrings from "locale/keys";
import _ from "lodash";

import { DataCy } from "@doorloop/dto";
import { CommercialIcon, ResidentialIcon } from "assets/icons";
import {
  commercialIndustrialItem,
  commercialOfficeItem,
  commercialOtherType,
  commercialTypes
} from "./commercialTypes";
import { PropertyTypeKey } from "./propertyType.types";
import { residentialTypes } from "./residentialTypes";

import type { PropertyType } from "@doorloop/dto";
import type { SVGIconComponent } from "assets/icons/types";
import type { EnTranslation } from "locale";

export interface PropertyClassItemType extends Omit<PropertyTypeItemType, "value"> {
  value?: PropertyTypeKey;
}

export interface PropertyTypeItemType {
  label: string;
  icon: SVGIconComponent;
  onPress: VoidFunction;
  value?: PropertyType;
  isSelected: boolean;
  key: string;
  dataCy?: string;
  descriptionBullets?: EnTranslation[];
}

export const propertySubTypes: Record<string, PropertyTypeItemType[]> = {
  COMMERCIAL: commercialTypes,
  RESIDENTIAL: residentialTypes
};

export const hoaPropertySubTypes: Record<string, PropertyTypeItemType[]> = {
  COMMERCIAL: [commercialIndustrialItem, commercialOfficeItem, commercialOtherType],
  RESIDENTIAL: residentialTypes
};

export const propertyTypes: PropertyClassItemType[] = [
  {
    label: AppStrings.Properties.Type.Residential,
    icon: ResidentialIcon,
    onPress: _.noop,
    isSelected: false,
    key: PropertyTypeKey.Residential,
    value: PropertyTypeKey.Residential,
    dataCy: DataCy.newProperty.propertySelectionButtonGroup.residential
  },
  {
    label: AppStrings.Properties.Type.Commercial,
    icon: CommercialIcon,
    onPress: _.noop,
    isSelected: false,
    key: PropertyTypeKey.Commercial,
    value: PropertyTypeKey.Commercial,
    dataCy: DataCy.newProperty.propertySelectionButtonGroup.commercial
  }
];

export function getPropertySubTypes(isHOAUser: boolean): Record<string, PropertyTypeItemType[]> {
  return isHOAUser ? hoaPropertySubTypes : propertySubTypes;
}
