import Text from "DLUI/text";
import type { ReactNode } from "react";
import React from "react";
import clsx from "clsx";
import type { TextColor, WhiteSpaceProps } from "DLUI/text/text";
import { useStylesLabel } from "DLUI/form/label/styles";
import type { ToggleInputSize } from "DLUI/form/types/toggleInputBaseTypes";
import { Link } from "DLUI/link";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

export type LabelPlacementPosition = "end" | "start";

export interface InputLabelValueProps {
  text?: string;
  component?: ReactNode;
  placement?: LabelPlacementPosition;
  clickable?: boolean;
  color?: TextColor;
  href?: string;
}

export interface InputLabelProps extends InputLabelValueProps {
  size?: ToggleInputSize;
  fontSize?: number;
  fontWidth?: number;
  fullWidth?: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  dataCy?: string;
  htmlFor?: string;
  spaceLabel?: boolean;
  maxWidth?: number | string;
  whiteSpace?: WhiteSpaceProps;
}

export const InputLabel = ({
  text,
  size,
  onClick,
  fontSize,
  fontWidth,
  placement = "end",
  clickable = true,
  disabled,
  fullWidth,
  className,
  spaceLabel,
  color = "black",
  dataCy,
  whiteSpace,
  href,
  htmlFor
}: InputLabelProps) => {
  const classesLabel = useStylesLabel();
  const { isTabletOrMobile } = useResponsiveHelper();
  const calculatedSize = size || (isTabletOrMobile ? "large" : "medium");

  const didClickLabel = () => {
    if (!clickable || disabled) return;

    onClick?.();
  };
  const Label = () => (
    <label onClick={didClickLabel} htmlFor={htmlFor}>
      <Text
        className={clsx([
          className,
          classesLabel.root,
          classesLabel[calculatedSize],
          placement && classesLabel[placement],
          disabled && classesLabel.disabled,
          !disabled && clickable && classesLabel.clickable,
          spaceLabel && classesLabel.spaceLabel
        ])}
        whiteSpace={whiteSpace}
        fontSize={fontSize}
        fontWeight={fontWidth}
        data-cy={dataCy}
        fullWidth={fullWidth}
        color={color}
        value={text}
      />
    </label>
  );

  const LinkComponent = () => (
    <Link hrefUrl={href} type={"tab"}>
      <Label />
    </Link>
  );

  if (href) return <LinkComponent />;

  return <Label />;
};
