export default {
  title: "Notifications",
  additionalInformation: "Verify that each of the notifications set are also enabled in the corresponding settings. ",
  learnMore: "Learn More",
  settings: {
    title: "Notification Settings",
    subtitle: "Choose the method you would like to have notifications sent",
    activitiesHeader: "Activities",
    tasks: "Tasks",
    saveSuccess: "Notifications Saved Successfully",
    latest: "Latest",
    unread: "unread",
    markAll: "Mark all as read",
    text: {
      title: "SMS",
      description: "Notifications will be sent by SMS"
    },
    push: {
      title: "Push",
      description: "Push Notification will be shown on your screen"
    },
    email: {
      title: "Email",
      description: "Notifications will be sent to your inbox"
    },
    merchantApplicationStatusUpdated: "Change of status in merchant account",
    paymentReceived: "Payment received",
    paymentReturned: "Payment returned",
    upcomingWeekAgenda: "Weekly agenda",
    taskAssigned: "Task assigned to you",
    taskUpdated: "Task updated",
    rentalApplicationSubmitted: "New rental application submitted",
    rentalApplicationReportsReady: "Screening reports available",
    rentReminders: "Rent Reminders",
    rentCharged: "Rent Changed",
    paymentReceipt: "Payment Receipt",
    autopayCanceled: "Auto Pay Canceled",
    lateFeeCharged: "Late Fee Charged",
    requestReceived: "Request Received",
    requestUpdated: "Request Updated",
    announcement: "Announcement",
    beforeLateFeeCharged: "Avoid Late Fee Reminder",
    rentOverdueReminders: "Overdue Rent Reminders",
    disabledByAdmin: "Disabled by your administrator",
    disabledByPropertyManager: "Disabled by The Property Manager",
    communicationCenterSetupSmsConfirmed: "Setup SMS",
    conversationIncomingMessage: "Text Messages"
  }
};
