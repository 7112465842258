import React from "react";

import { AddImage } from "assets/icons";
import { RestrictedPermissionAccess } from "screens/settings/userRoles";

import type { PopoverItem } from "DLUI/popover";
import { View } from "DLUI/view";
import FAB from "DLUI/fab";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import { TextTooltip } from "DLUI/popover/textTooltip";

interface ComponentProps {
  items: PopoverItem[];
  actionComponents?: JSX.Element;
}

const ListActionButtons: React.FC<ComponentProps> = ({ items, actionComponents }: ComponentProps) => {
  const { isTabletOrMobile } = useResponsiveHelper();

  const [isFabOpen, setIsFabOpen] = React.useState(false);

  const renderActions = () =>
    isTabletOrMobile ? (
      <View justifyContent={isTabletOrMobile ? "flex-end" : "center"} flexDirection={"row"}>
        {actionComponents}

        {items.length && (
          <FAB
            fabPlacement={"right"}
            icon={AddImage}
            items={items}
            isOpen={isFabOpen}
            onClick={() => setIsFabOpen(!isFabOpen)}
          />
        )}
      </View>
    ) : (
      <View noWrap height={"100%"} alignItems={"center"} justifyContent={"flex-end"} flexDirection={"row"} gap={10}>
        {items.map((item, index) => (
          <RestrictedPermissionAccess key={index} clearance={item.clearance}>
            {item.onClick ? (
              <TextTooltip
                useDark
                show={Boolean(item.disabled && item.tooltipText)}
                value={item.tooltipText}
                maxWidth={250}
              >
                <DLButton
                  onClick={item.onClick}
                  actionText={item.title}
                  variant={DLButtonVariantsEnum.OUTLINED}
                  color={DLButtonColorsEnum.NEUTRAL}
                  size={DLButtonSizesEnum.SMALL}
                  icons={{ start: { src: item.Icon } }}
                  dataCy={item.dataCy}
                  disabled={item.disabled}
                />
              </TextTooltip>
            ) : null}
          </RestrictedPermissionAccess>
        ))}
        {actionComponents}
      </View>
    );

  return <View>{renderActions()}</View>;
};

export default ListActionButtons;
