import type { Theme } from "@material-ui/core/styles/createTheme";
import { makeStyles } from "@material-ui/styles";
import type { ToggleInputSize } from "DLUI/form/types/toggleInputBaseTypes";
import type { LabelPlacementPosition } from "DLUI/form/label/inputLabel";

type StylesLabelClassKeys = "root" | ToggleInputSize | "disabled" | "clickable" | "spaceLabel" | LabelPlacementPosition;

export const useStylesLabel = makeStyles<Theme, NonNullable<unknown>, StylesLabelClassKeys>((theme) => {
  return {
    root: {
      width: "auto",
      display: "flex",
      alignItems: "center",
      alignSelf: "stretch"
    },
    small: {
      fontSize: 12,
      lineHeight: "14px !important"
    },
    medium: {
      fontSize: 14,
      lineHeight: "18px !important"
    },
    large: {
      fontSize: 16,
      lineHeight: "24px !important"
    },
    start: {},
    end: {},
    disabled: {
      color: theme.newPalette.text.disabled
    },
    clickable: {
      cursor: "pointer !important"
    },
    spaceLabel: {
      "&$start$small": {
        marginRight: "4px !important"
      },
      "&$end$small": {
        marginLeft: "4px !important"
      },
      "&$start$medium": {
        marginRight: "4px !important"
      },
      "&$end$medium": {
        marginLeft: "4px !important"
      },
      "&$start$large": {
        marginRight: "12px !important"
      },
      "&$end$large": {
        marginLeft: "12px !important"
      }
    }
  };
});
