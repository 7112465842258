import React, { useMemo } from "react";
import { LeaseListIcon, LocationIcon } from "assets/icons";
import { Icon } from "DLUI/icon";
import { Grid, ListItemContainer, ListItemIcon, ListItemSection } from "DLUI/listItems";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { LeaseDto } from "@doorloop/dto";
import { DataCy } from "@doorloop/dto";
import AppStrings from "locale/keys";
import LeaseStatusElement from "screens/leases/leases/leasesList/leaseStatusElement";
import ListItemText from "DLUI/listItems/listItemText";
import { getPropertyAndUnitValue } from "screens/units/unitsList/utils";

interface ComponentProps {
  leaseDto: LeaseDto;
  didPressListItem?: (leaseId: string) => void;
}

const SelectableLeaseListItem: React.FC<ComponentProps> = ({ leaseDto, didPressListItem }: ComponentProps) => {
  const didPressRowItem = () => {
    if (didPressListItem) {
      didPressListItem(leaseDto.id!);
    }
  };

  const propertyAndUnitValue = useMemo(() => getPropertyAndUnitValue(leaseDto.property!, leaseDto.name), []);

  return (
    <ListItemContainer
      backgroundColor={leaseDto.evictionPending ? "error" : undefined}
      onClick={didPressRowItem}
      dataCy={DataCy.DLUI.listItem.listItemLease}
    >
      <Grid title={AppStrings.Leases.ListHeader.Lease} showDivider xs={12} md={7} lg={7}>
        <View flexDirection={"row"}>
          <ListItemIcon Icon={LeaseListIcon} />
          <ListItemSection
            renderTitle={
              <ListItemText numberOfLines={1} color={"black"} align="left" fontWeight={700}>
                {leaseDto.name}
              </ListItemText>
            }
            renderSubTitle={
              <View justifyContent={"flex-start"} alignItems={"center"} flexDirection={"row"} noWrap>
                <Icon Source={LocationIcon} width={20} height={20} marginRight={5} />
                <ListItemText numberOfLines={1} fontWeight={500} color={"gray"}>
                  {propertyAndUnitValue}
                </ListItemText>
              </View>
            }
          />
        </View>
      </Grid>

      <Grid title={AppStrings.Leases.ListHeader.Status} showDivider xs={12} md={3} lg={3}>
        <View alignItems={"center"} flexDirection={"row"}>
          <LeaseStatusElement leaseDto={leaseDto} />
        </View>
      </Grid>

      <Grid title={AppStrings.Leases.ListHeader.Balance} xs={12} md={2} lg={2} alignTitle={"end"}>
        <View marginRight={10} alignItems={"flex-end"} flex={1}>
          <Text
            color={"black"}
            align="left"
            fontSize={16}
            value={String(leaseDto.totalBalanceDue)}
            formatType={"currency"}
          />
        </View>
      </Grid>
    </ListItemContainer>
  );
};

export default SelectableLeaseListItem;
