"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServerRoutes = void 0;
var ServerRoutes;
(function (ServerRoutes) {
    ServerRoutes["AUTH_POST_SIGNUP"] = "/api/auth/signup";
    ServerRoutes["OTP_AUTH"] = "/api/auth/otp";
    ServerRoutes["AUTH_POST_CREATE_OR_UPDATE_DEMO_LEAD"] = "/api/auth/create-demo-lead";
    ServerRoutes["AUTH_CREATE_DEMO_BASE_LEAD"] = "/api/auth/create-demo-BASE-lead";
    ServerRoutes["AUTH_POST_SIGNUP_CONFIRM"] = "/api/auth/signup/confirm";
    ServerRoutes["PAYMENT_TOKENS_FOR_TENANT"] = "/api/tenants/payment-tokens-for-tenant";
    ServerRoutes["AUTH_POST_RESEND_CONFIRMATION_EMAIL"] = "/api/auth/resend-confirmation-email";
    ServerRoutes["AUTH_GET_CURRENT_LOGIN_RESPONSE"] = "/api/auth/me";
    ServerRoutes["AUTH_GET_LOGIN_RESPONSES"] = "/api/auth/login-responses";
    ServerRoutes["AUTH_GET_LOGIN_THEME"] = "/api/auth/theme";
    ServerRoutes["AUTH_POST_FORGOT_PASSWORD_REQUEST"] = "/api/auth/forgot-password";
    ServerRoutes["AUTH_POST_FORGOT_PASSWORD_CONFIRM"] = "/api/auth/forgot-password/confirm";
    ServerRoutes["AUTH_POST_VALIDATE_PASSWORD"] = "/api/auth/validate-password";
    ServerRoutes["AUTH_POST_CHANGE_MFA"] = "/api/auth/change-mfa";
    ServerRoutes["AUTH_POST_CHANGE_MFA_CONFIRM"] = "/api/auth/change-mfa/confirm";
    ServerRoutes["AUTH_POST_LOGIN"] = "/api/auth/login";
    ServerRoutes["AUTH_SSO_LOGIN"] = "/api/auth/sso";
    ServerRoutes["AUTH_POST_LOGIN_TENANT_PORTAL_IMPERSONATION_TOKEN"] = "/api/auth/login/tenant-portal-impersonation-token";
    ServerRoutes["AUTH_POST_LOGIN_OWNER_PORTAL_IMPERSONATION_TOKEN"] = "/api/auth/login/owner-portal-impersonation-token";
    ServerRoutes["AUTH_POST_LOGOUT"] = "/api/auth/logout";
    ServerRoutes["OAUTH_AUTHORIZE"] = "/api/oauth/authorize";
    ServerRoutes["ONBOARDING"] = "/api/onboarding";
    ServerRoutes["ONBOARDING_UPDATE_SHOW_ONBOARDING_ON_LOGIN"] = "/api/onboarding/show-onboarding-on-login";
    ServerRoutes["AUTH_GET_CHECK_LOGIN_EMAIL_EXISTS"] = "/api/auth/accept-invite/check-login";
    ServerRoutes["AUTH_POST_ACCEPT_INVITE_NEW_LOGIN"] = "/api/auth/accept-invite/new-login";
    ServerRoutes["AUTH_POST_ACCEPT_INVITE_EXISTING_LOGIN"] = "/api/auth/accept-invite/existing-login";
    ServerRoutes["WELCOME_POST_STEP1"] = "/api/welcome/step1";
    ServerRoutes["WELCOME_POST_STEP2"] = "/api/welcome/step2";
    ServerRoutes["WELCOME_POST_STEP3"] = "/api/welcome/step3";
    ServerRoutes["WELCOME_POST_STEP4"] = "/api/welcome/step4";
    ServerRoutes["BUDGETS"] = "/api/budgets";
    ServerRoutes["BUDGET_EMPTY_REPORT"] = "/api/budget-empty-report";
    ServerRoutes["USERS"] = "/api/users";
    ServerRoutes["USERS_NOTIFICATION_SETTINGS"] = "/api/users/notification-settings";
    ServerRoutes["USERS_PRINT_SETUP"] = "/api/users/print-setup";
    ServerRoutes["USERS_POST_RESEND_INVITATION"] = "/api/users/resend-invitation";
    ServerRoutes["USERS_PICTURE"] = "/api/users/pictures";
    ServerRoutes["USER_ROLES"] = "/api/user-roles";
    ServerRoutes["ACCOUNTS"] = "/api/accounts";
    ServerRoutes["ACCOUNTS_POST_DEFAULT_ACCOUNTS"] = "/api/accounts/create-default";
    ServerRoutes["ACCOUNTS_GET_ACCOUNT_BALANCE"] = "/api/accounts/balance";
    ServerRoutes["ACCOUNTS_GET_DEFAULT_ACCOUNTS_FOR_PROPERTY"] = "/api/accounts/default-for-property";
    ServerRoutes["ACCOUNTS_GET_CHART_OF_ACCOUNTS"] = "/api/accounts/chart-of-accounts";
    ServerRoutes["ACCOUNTS_GET_DEFAULT_CLEARING_ACCOUNT"] = "/api/accounts/clearing-account";
    ServerRoutes["PROPERTIES"] = "/api/properties";
    ServerRoutes["PROPERTIES_GET_ALL_REPORT_DATA"] = "/api/properties/report/data";
    ServerRoutes["PROPERTIES_GET_HAS_TENANTS_AUTO_PAYMENTS"] = "/api/properties/:id/has-tenants-auto-payments";
    ServerRoutes["PROPERTIES_POST_PROPERTY_WITH_UNITS"] = "/api/properties/create-property-wizard";
    ServerRoutes["PROPERTIES_PICTURE"] = "/api/properties/pictures";
    ServerRoutes["PROPERTIES_SETTINGS_TENANT_PORTAL_LOGO"] = "/api/properties/tenant-portal-logo/:id";
    ServerRoutes["PROPERTIES_SETTINGS_TENANT_PORTAL_BACKGROUND"] = "/api/properties/tenant-portal-background/:id";
    ServerRoutes["PROPERTIES_SETTINGS_RENTAL_APPLICATIONS"] = "/api/properties/settings/rental-applications/:id";
    ServerRoutes["PROPERTIES_SETTINGS_MANAGEMENT_FEES"] = "/api/properties/settings/management-fees/:id";
    ServerRoutes["PROPERTIES_SETTINGS_PAYMENT_ALLOCATIONS"] = "/api/properties/settings/payment-allocations/:id";
    ServerRoutes["API_KEYS"] = "/api/api-keys";
    ServerRoutes["LEASE_DRAFTS"] = "/api/draft-leases";
    ServerRoutes["LEASES_GET_LEASE_DRAFT_TENANTS"] = "/api/draft-leases/tenants";
    ServerRoutes["LEASES_TENANTS_WIDGETS"] = "/api/leases/tenants/widgets";
    ServerRoutes["LEASES_GET_LEASE_TENANTS"] = "/api/leases/tenants";
    ServerRoutes["LEASE_DRAFTS_POST_ACTIVATE"] = "/api/draft-leases/:id/activate";
    ServerRoutes["LEASE_DRAFTS_POST_CANCEL"] = "/api/draft-leases/:id/cancel";
    ServerRoutes["UNITS"] = "/api/units";
    ServerRoutes["UNITS_PICTURE"] = "/api/units/pictures";
    ServerRoutes["UNITS_LISTINGS_GET_MITS_FOR_DBTENANT"] = "/api/units/listings/mits/:contentType/:dbTenantId";
    ServerRoutes["UNITS_LISTINGS_GET_LISTING_PARTNERS_STATUS"] = "/api/units/listings/partners-status";
    ServerRoutes["UNITS_LISTINGS_CONTACT_FORM"] = "/api/units/listings/contact-form";
    ServerRoutes["UNITS_LISTINGS_GET_ALL"] = "/api/units/listings";
    ServerRoutes["TENANTS"] = "/api/tenants";
    ServerRoutes["TENANTS_POST_TENANT_PORTAL_IMPERSONATION_TOKEN"] = "/api/tenants/portal-impersonation-token";
    ServerRoutes["TENANTS_POST_ACTIVATE_TENANT_PORTAL"] = "/api/tenants/activate-tenant-portal";
    ServerRoutes["TENANTS_PICTURE"] = "/api/tenants/pictures";
    ServerRoutes["TENANTS_POST_RESEND_TENANT_PORTAL_INVITATION"] = "/api/tenants/resend-invitation";
    ServerRoutes["VENDORS"] = "/api/vendors";
    ServerRoutes["VENDORS_PICTURE"] = "/api/vendors/pictures";
    ServerRoutes["VENDOR_BILLS"] = "/api/vendor-bills";
    ServerRoutes["VENDOR_BILLS_MANAGEMENT_FEES"] = "/api/vendor-bills/management-fees";
    ServerRoutes["VENDOR_BILL_PAYMENTS_VOID_CHECK"] = "/api/vendor-bill-payments/:id/void-check";
    ServerRoutes["VENDOR_BILL_PAYMENTS"] = "/api/vendor-bill-payments";
    ServerRoutes["VENDOR_BILL_PAYMENTS_POST_CREATE_MANY"] = "/api/vendor-bill-payments/create-many";
    ServerRoutes["VENDOR_CREDITS"] = "/api/vendor-credits";
    ServerRoutes["TAGS"] = "/api/tags";
    ServerRoutes["TAGS_POST_CREATE_OR_UPDATE_MANY"] = "/api/tags/many";
    ServerRoutes["VENDOR_BILL_PAYMENTS_GET_OUTSTANDING_TRANSACTIONS_VENDOR_BILL_PAYMENT"] = "/api/vendor-bill-payments/outstanding-transactions-vendor-bill-payment";
    ServerRoutes["VENDOR_BILL_PAYMENTS_GET_OUTSTANDING_TRANSACTIONS_PAY_BILL"] = "/api/vendor-bill-payments/outstanding-transactions-pay-bill";
    ServerRoutes["DASHBOARDS"] = "/api/dashboards";
    ServerRoutes["DASHBOARDS_GET_DEFAULT"] = "/api/dashboards/default";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_ACCOUNT_PAYABLE"] = "/api/dashboard-widgets/account-payable";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_ACCOUNT_RECEIVABLE"] = "/api/dashboard-widgets/account-receivable";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_BANKING_STATS"] = "/api/dashboard-widgets/banking-stats";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_DRAFT_LEASES_FUNNEL"] = "/api/dashboard-widgets/draft-leases-funnel";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_LEASES_WITH_OUTSTANDING_BALANCES"] = "/api/dashboard-widgets/leases-with-outstanding-balances";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_LEASE_RENEWALS_FUNNEL"] = "/api/dashboard-widgets/lease-renewals-funnel";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_LEASING_ACTIVITY_NEXT_7_DAYS"] = "/api/dashboard-widgets/leasing-activity-next-7-days";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_OCCUPANCY_RATE"] = "/api/dashboard-widgets/occupancy-rate";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_PROFIT_AND_LOSS"] = "/api/dashboard-widgets/profit-and-loss";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_PROSPECTS_BY_LEAD_SOURCE"] = "/api/dashboard-widgets/prospects-by-lead-source";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_PROSPECTS_BY_PROPERTY"] = "/api/dashboard-widgets/prospects-by-property";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_PROSPECTS_FUNNEL"] = "/api/dashboard-widgets/prospects-funnel";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_PROSPECTS_STATS"] = "/api/dashboard-widgets/prospects-stats";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_RECENT_PAYMENTS_RECEIVED"] = "/api/dashboard-widgets/recent-payments-received";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_RENTAL_APPLICATION_FUNNEL"] = "/api/dashboard-widgets/rental-application-funnel";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_RENTAL_APPLICATION_OVERVIEW"] = "/api/dashboard-widgets/rental-application-overview";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_RENTAL_STATS"] = "/api/dashboard-widgets/rental-stats";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_SUGGESTED_ACTIONS"] = "/api/dashboard-widgets/suggested-actions";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_TASKS_ASSIGNED_TO_ME"] = "/api/dashboard-widgets/tasks-assigned-to-me";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_TASKS_MY_NEXT_7_DAYS"] = "/api/dashboard-widgets/tasks-my-next-7-days";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_TASKS_OPEN_BY_STATUS"] = "/api/dashboard-widgets/tasks-open-by-status";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_TASKS_RECENT_TENANT_REQUESTS"] = "/api/dashboard-widgets/tasks-recent-tenant-requests";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_TENANT_PORTAL_STATS"] = "/api/dashboard-widgets/tenant-portal-stats";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_TENANT_STATS"] = "/api/dashboard-widgets/tenant-stats";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_TO_DO"] = "/api/dashboard-widgets/to-do";
    ServerRoutes["DASHBOARDS_WIDGETS_GET_VACANCIES_BY_PROPERTY"] = "/api/dashboard-widgets/vacancies-by-property";
    ServerRoutes["MERCHANT_ACCOUNTS"] = "/api/merchant-accounts";
    ServerRoutes["MERCHANT_ACCOUNTS_GET_LIMITS_FOR_MERCHANT_ACCOUNT"] = "/api/merchant-accounts/limits-for-merchant-account";
    ServerRoutes["MERCHANT_ACCOUNTS_GET_STRIPE_ACCOUNTS"] = "/api/merchant-accounts/stripe-accounts";
    ServerRoutes["MERCHANT_ACCOUNTS_POST_STRIPE_ACCOUNT_LINK"] = "/api/merchant-accounts/stripe-account-link";
    ServerRoutes["MERCHANT_ACCOUNTS_GET_CREDITS_REMAINING"] = "/api/merchant-accounts/credits-remaining";
    ServerRoutes["MERCHANT_ACCOUNTS_IDENTITY_VERIFICATION"] = "/api/merchant-accounts/identity-verification";
    // STRIPE MIGRATION
    ServerRoutes["MERCHANT_ACCOUNTS_STRIPE_POST_UPDATE_EXTERNAL_ACCOUNT"] = "/api/merchant-accounts/stripe-update-external-account";
    ServerRoutes["MERCHANT_ACCOUNTS_STRIPE_POST_UPDATE_EIN"] = "/api/merchant-accounts/stripe-update-ein";
    // Merchant Account Overview Page
    ServerRoutes["MERCHANT_ACCOUNTS_GET_STRIPE_GENERAL_INFO_OVERVIEW"] = "/api/merchant-accounts/stripe/overview/general-info";
    ServerRoutes["MERCHANT_ACCOUNTS_GET_STRIPE_BALANCE_SUMMARY_OVERVIEW"] = "/api/merchant-accounts/stripe/overview/balance-summary";
    ServerRoutes["MERCHANT_ACCOUNTS_GET_STRIPE_TRANSACTIONS_OVERVIEW"] = "/api/merchant-accounts/stripe/overview/transactions";
    ServerRoutes["MERCHANT_ACCOUNTS_GET_STRIPE_DEPOSITS_OVERVIEW"] = "/api/merchant-accounts/stripe/overview/deposits";
    ServerRoutes["OUTGOING_PAYMENTS"] = "/api/outgoing-payments";
    ServerRoutes["PROPERTY_GROUPS"] = "/api/property-groups";
    ServerRoutes["OWNERS"] = "/api/owners";
    ServerRoutes["OWNERS_ALL"] = "/api/owners-all";
    ServerRoutes["OWNERS_POST_OWNER_PORTAL_IMPERSONATION_TOKEN"] = "/api/owners/portal-impersonation-token";
    ServerRoutes["OWNERS_PICTURE"] = "/api/owners/pictures";
    ServerRoutes["OWNERS_POST_RESEND_OWNER_PORTAL_INVITATION"] = "/api/owners/resend-owner-portal-invitation";
    ServerRoutes["OWNERS_POST_ACTIVATE_OWNER_PORTAL"] = "/api/owners/activate-owner-portal";
    ServerRoutes["FILES"] = "/api/files";
    ServerRoutes["FILES_GET_DOWNLOAD_WITH_TOKEN"] = "/api/files/download/:token";
    ServerRoutes["EXPENSES"] = "/api/expenses";
    ServerRoutes["EXPENSES_POST_CREATE_MANY"] = "/api/expenses/create-many";
    ServerRoutes["EXPENSES_POST_PRINTED_CHECKS"] = "/api/expenses/checks-printed";
    ServerRoutes["EXPENSES_VOID_CHECK"] = "/api/expenses/:id/void-check";
    ServerRoutes["LEASE_CHARGES"] = "/api/lease-charges";
    ServerRoutes["LEASE_CHARGES_GET_PDF"] = "/api/lease-charges/pdf/:id";
    ServerRoutes["LEASE_CHARGES_GET_PDF_TOKEN"] = "/api/lease-charges/pdf-token/:id";
    ServerRoutes["LEASE_CHARGES_GET_PDF_DOWNLOAD_BY_TOKEN"] = "/api/lease-charges/pdf-download-token/:id";
    ServerRoutes["LEASE_REFUNDS"] = "/api/lease-refunds";
    ServerRoutes["LEASE_PAYMENTS"] = "/api/lease-payments";
    ServerRoutes["LEASE_PAYMENTS_POST_SEND_RECEIPT"] = "/api/lease-payments/receipt/:id";
    ServerRoutes["LEASE_PAYMENTS_GET_DOWNLOAD_RECEIPT_PDF"] = "/api/lease-payments/receipt/:id";
    ServerRoutes["CASH_PAYMENTS"] = "/api/lease-payments/cash";
    ServerRoutes["CASH_PAYMENTS_GET_PDF"] = "/api/lease-payments/cash/pdf/:leaseId/:tenantId";
    ServerRoutes["CASH_PAYMENTS_TENANT_PORTAL_GET_PDF"] = "/api/tenant-portal/lease-payments/cash/pdf/:leaseId/:tenantId";
    ServerRoutes["CASH_PAYMENTS_GET_PAYMENT_DETAILS"] = "/api/lease-payments/cash/get-payment-details";
    ServerRoutes["CASH_PAYMENTS_TENANT_PORTAL_GET_PAYMENT_DETAILS"] = "/api/tenant-portal/lease-payments/cash/get-payment-details";
    ServerRoutes["CASH_PAYMENTS_GET_IS_ENABLED"] = "/api/lease-payments/cash/is-enabled/:idType/:id";
    ServerRoutes["CASH_PAYMENTS_TENANT_PORTAL_GET_IS_ENABLED"] = "/api/tenant-portal/lease-payments/cash/is-enabled-tenant/:idType/:id";
    ServerRoutes["LEASE_REVERSED_PAYMENTS"] = "/api/lease-reversed-payments";
    ServerRoutes["LEASE_PAYMENTS_GET_OUTSTANDING_TRANSACTIONS"] = "/api/lease-payments/outstanding-transactions";
    ServerRoutes["LEASE_CREDITS"] = "/api/lease-credits";
    ServerRoutes["LEASES"] = "/api/leases";
    ServerRoutes["LEASE_RENEWAL_OFFERS"] = "/api/lease-renewal-offers";
    ServerRoutes["LEASES_POST_END_LEASE"] = "/api/leases/end-lease";
    ServerRoutes["LEASES_POST_MOVEIN_TENANT"] = "/api/leases/move-in";
    ServerRoutes["LEASES_POST_MOVEOUT_TENANT"] = "/api/leases/move-out";
    ServerRoutes["LEASES_POST_NOT_RENEWING"] = "/api/leases/not-renewing";
    ServerRoutes["LEASES_SEND_RENT_REMINDER"] = "/api/leases/send-rent-reminder";
    ServerRoutes["LEASES_GET_RENT_REMINDER_DATE"] = "/api/lease-recurring-transactions/get-rent-reminder-date";
    ServerRoutes["LEASES_GET_HAS_TENANTS_AUTO_PAYMENTS"] = "/api/leases/:id/has-tenants-auto-payments";
    ServerRoutes["LEASES_POST_CANCEL_TENANTS_AUTO_PAYMENTS"] = "/api/leases/:id/cancel-tenants-auto-payments";
    ServerRoutes["LEASE_RECURRING_TRANSACTIONS"] = "/api/lease-recurring-transactions";
    ServerRoutes["LEASE_RECURRING_TRANSACTIONS_POST_CANCEL"] = "/api/lease-recurring-transactions/:id/cancel";
    ServerRoutes["NOTES"] = "/api/notes";
    ServerRoutes["INSURANCE_POLICIES"] = "/api/insurance-policies";
    ServerRoutes["TASKS"] = "/api/tasks";
    ServerRoutes["TASKS_POST_UPDATE"] = "/api/tasks/update";
    ServerRoutes["DEPOSITS"] = "/api/deposits";
    ServerRoutes["DEPOSITS_IS_ACCOUNT_RECONCILED"] = "/api/deposits/:id/is-account-reconciled";
    ServerRoutes["DEPOSITS_GET_UNDEPOSITED_ENTRIES_FOR_ACCOUNT"] = "/api/deposits/get-undeposited-entries";
    ServerRoutes["TRANSFERS"] = "/api/transfers";
    ServerRoutes["GENERAL_ENTRIES"] = "/api/general-entries";
    ServerRoutes["OWNER_CONTRIBUTIONS"] = "/api/owner-contributions";
    ServerRoutes["OWNER_DISTRIBUTIONS"] = "/api/owner-distributions";
    ServerRoutes["ACCOUNTS_PUT_NEXT_CHECK_NUMBER"] = "/api/expenses/update-next-check-number";
    ServerRoutes["ACCOUNTS_GET_CHECK_INFORMATION"] = "/api/accounts/print-checks-information";
    ServerRoutes["ACCOUNTS_GET_BANK_ACCOUNTS"] = "/api/accounts/bank-accounts";
    ServerRoutes["REPORTS_TRIAL_BALANCE"] = "/api/reports/trial-balance";
    ServerRoutes["REPORTS_LEASE_ACCOUNTS_RECEIVABLE"] = "/api/reports/lease-accounts-receivable";
    ServerRoutes["REPORTS_LEASE_ACCOUNTS_RECEIVABLE_PDF"] = "/api/reports/lease-accounts-receivable/pdf";
    ServerRoutes["REPORTS_LEASE_ACCOUNTS_RECEIVABLE_BATCH_PDF"] = "/api/reports/lease-accounts-receivable/batch-pdf";
    ServerRoutes["REPORTS_ACCOUNT_REGISTER"] = "/api/reports/account-register";
    ServerRoutes["REPORTS_PRINT_CHECKS"] = "/api/reports/print-checks";
    ServerRoutes["REPORTS_OUTGOING_PAYMENTS"] = "/api/reports/outgoing-payments";
    ServerRoutes["REPORTS_PDF"] = "/api/reports/pdf";
    ServerRoutes["REPORTS_ACCOUNTS_RECEIVABLE_AGING_SUMMARY"] = "/api/reports/accounts-receivable-aging-summary";
    ServerRoutes["REPORTS_ACCOUNTS_RECEIVABLE_AGING_DETAIL"] = "/api/reports/accounts-receivable-aging-detail";
    ServerRoutes["REPORTS_ACCOUNTS_RECEIVABLE_AGING_DETAIL_BY_ACCOUNT"] = "/api/reports/accounts-receivable-aging-detail-by-account";
    ServerRoutes["REPORTS_ACCOUNTS_PAYABLE_AGING_SUMMARY"] = "/api/reports/accounts-payable-aging-summary";
    ServerRoutes["REPORTS_ACCOUNTS_PAYABLE_AGING_DETAIL"] = "/api/reports/accounts-payable-aging-detail";
    ServerRoutes["REPORTS_ACCOUNTS_PAYABLE_AGING_DETAIL_BY_ACCOUNT"] = "/api/reports/accounts-payable-aging-detail-by-account";
    ServerRoutes["REPORTS_BUDGET_VS_ACTUAL"] = "/api/reports/budget-vs-actual";
    ServerRoutes["REPORTS_OWNER_TRANSACTIONS_REPORT"] = "/api/reports/owner-transactions";
    ServerRoutes["REPORTS_PROPERTY_AVAILABLE_FUNDS"] = "/api/reports/property-available-funds";
    ServerRoutes["REPORTS_PROPERTY_ACCOUNTS_FUNDS"] = "/api/reports/property-accounts-funds";
    ServerRoutes["REPORTS_BANK_BALANCE_BY_PROPERTY"] = "/api/reports/bank-balance-by-property";
    ServerRoutes["REPORTS_LEASE_DEPOSITS_HELD_BY_ACCOUNT"] = "/api/reports/lease-deposits-held-by-account";
    ServerRoutes["REPORTS_TRANSACTION_REPORT"] = "/api/reports/transactions";
    ServerRoutes["REPORTS_TRANSACTION_LIST_REPORT"] = "/api/reports/transactions-list";
    ServerRoutes["REPORTS_BILLS_AND_PAYMENT"] = "/api/reports/bills-and-payment";
    ServerRoutes["REPORTS_CHARGES_AND_PAYMENT"] = "/api/reports/charges-and-payment";
    ServerRoutes["REPORTS_PROFIT_AND_LOSS_SUMMARY"] = "/api/reports/profit-and-loss-summary";
    ServerRoutes["REPORTS_PROFIT_AND_LOSS_DETAIL"] = "/api/reports/profit-and-loss-detail";
    ServerRoutes["REPORTS_BALANCE_SHEET_DETAIL"] = "/api/reports/balance-sheet-detail";
    ServerRoutes["REPORTS_DEPOSIT_DETAIL"] = "/api/reports/deposit-detail";
    ServerRoutes["REPORTS_OWNERS_1099"] = "/api/reports/owners-1099";
    ServerRoutes["REPORTS_VENDORS_1099"] = "/api/reports/vendors-1099";
    ServerRoutes["REPORTS_CASH_FLOW_STATEMENT"] = "/api/reports/cash-flow-statement";
    ServerRoutes["REPORTS_OWNER_STATEMENT"] = "/api/reports/owner-statement";
    ServerRoutes["REPORTS_OWNER_STATEMENT_PRINTABLE_PDF"] = "/api/reports/owner-statement/print-pdf";
    ServerRoutes["REPORTS_OWNER_STATEMENT_BULK_REPORT"] = "/api/reports/owner-statement/bulk";
    ServerRoutes["REPORTS_OWNER_STATEMENT_SEND_PDF_TO_OWNERS"] = "/api/reports/owner-statement/send-to-pdf-owners";
    ServerRoutes["REPORTS_BALANCE_SHEET_SUMMARY"] = "/api/reports/balance-sheet-summary";
    ServerRoutes["REPORTS_RENT_ROLL"] = "/api/reports/rent-roll";
    ServerRoutes["REPORTS_RENT_ROLL_SUMMARY_BY_PROPERTY"] = "/api/reports/rent-roll-summary-by-property";
    ServerRoutes["REPORTS_RENT_ROLL_SUMMARY_BY_BEDS_AND_BATHS"] = "/api/reports/rent-roll-summary-by-beds-and-baths";
    ServerRoutes["REPORTS_VENDOR_TRANSACTIONS_REPORT"] = "/api/reports/vendor-transactions";
    ServerRoutes["REPORTS_OWNER_AVAILABLE_FUNDS_REPORT"] = "/api/reports/owner-available-funds";
    ServerRoutes["REPORTS_CALCULATE_MANAGEMENT_FEES_REPORT"] = "/api/reports/calculate-management-fees";
    ServerRoutes["REPORTS_PROPERTY_OWNERSHIP_REPORT"] = "/api/reports/property-ownership";
    ServerRoutes["REPORTS_TENANT_VEHICLES_REPORT"] = "/api/reports/tenant-vehicles";
    ServerRoutes["REPORTS_TENANT_PETS_REPORT"] = "/api/reports/tenant-pets";
    ServerRoutes["RENTAL_APPLICATIONS"] = "/api/rental-applications";
    ServerRoutes["RENTAL_APPLICATIONS_APPLICANT_POST_CHARGE"] = "/api/rental-applications/applicant-post-charge";
    ServerRoutes["RENTAL_APPLICATIONS_APPLICANT_POST_CHARGE_SEND_REMINDER"] = "/api/rental-applications/applicant-post-charge-send-reminder";
    ServerRoutes["RENTAL_APPLICATIONS_GET_APPLICANT_POST_CHARGE_DATA"] = "/api/rental-applications/applicant-post-charge-data";
    ServerRoutes["RENTAL_APPLICATIONS_POST_APPLICANT_POST_CHARGE_PAYMENT"] = "/api/rental-applications/applicant-post-charge-payment";
    ServerRoutes["RENTAL_APPLICATIONS_GET_REPORTS_DATA"] = "/api/rental-applications/:id/reports-data";
    ServerRoutes["RENTAL_APPLICATION_GROUPS"] = "/api/rental-application-groups";
    ServerRoutes["RENTAL_APPLICATION_GROUPS_GET_DROPDOWN_ITEMS"] = "/api/rental-application-groups/dropdown-items";
    ServerRoutes["RENTAL_APPLICATION_GROUPS_JOIN_APPLICATIONS"] = "/api/rental-application-groups/join-applications";
    ServerRoutes["REPORTS_RECURRING_TRANSACTIONS_DETAIL"] = "/api/reports/recurring-transactions-detail";
    ServerRoutes["RENTAL_APPLICATIONS_POST_CHECK_TRANSUNION_ELIGIBILITY"] = "/api/rental-applications/check-transunion-eligibility";
    ServerRoutes["RENTAL_APPLICATIONS_POST_SUBMIT"] = "/api/rental-applications/submit";
    ServerRoutes["RENTAL_APPLICATIONS_GET_EXAM_QUESTIONS"] = "/api/rental-applications/exams/questions";
    ServerRoutes["RENTAL_APPLICATIONS_POST_EXAM_ANSWERS"] = "/api/rental-applications/exams/answers";
    ServerRoutes["RENTAL_APPLICATIONS_POST_REQUEST_REPORTS"] = "/api/rental-applications/request-reports";
    ServerRoutes["RENTAL_APPLICATIONS_CHARGE_REPORTS"] = "/api/rental-applications/charge-screening-reports";
    ServerRoutes["RENTAL_APPLICATIONS_GET_SETTINGS"] = "/api/rental-applications/settings";
    ServerRoutes["RENTAL_APPLICATIONS_GET_AVAILABLE_REPORTS"] = "/api/rental-applications/available-reports";
    ServerRoutes["RENTAL_APPLICATIONS_GET_REPORT_DATA"] = "/api/rental-applications/report";
    ServerRoutes["RENTAL_APPLICATIONS_GET_REPORT_PDF"] = "/api/rental-applications/report-pdf";
    ServerRoutes["RENTAL_APPLICATIONS_APPLICANT_GET_REPORT_DATA"] = "/api/rental-applications/applicant/report";
    ServerRoutes["RENTAL_APPLICATIONS_APPLICANT_GET_ALL_REPORTS_SHARE_SCREENING_DATA"] = "/api/rental-applications/applicant/reports-share-screening-data";
    ServerRoutes["RENTAL_APPLICATIONS_APPLICANT_GET_REPORT_PDF"] = "/api/rental-applications/applicant/report-pdf";
    ServerRoutes["RENTAL_APPLICATIONS_PUT_UPDATE_DECISION_STATUS"] = "/api/rental-applications/update-decision-status";
    ServerRoutes["RENTAL_APPLICATIONS_POST_SEND_APPLICATION_COMMUNICATION"] = "/api/rental-applications/send-rental-application-invite";
    ServerRoutes["RENTAL_APPLICATIONS_POST_SEND_APPLICATION_REMINDERS"] = "/api/rental-applications/send-rental-application-reminders";
    ServerRoutes["RENTAL_APPLICATIONS_GET_APPLICATION_VALUES"] = "/api/rental-application/application-fill-values";
    ServerRoutes["RENTAL_APPLICATIONS_SAVE_FOR_LATER"] = "/api/rental-application/save-for-later";
    ServerRoutes["RENTAL_APPLICATIONS_GET_ALL_WITH_STATUS_FILTER"] = "/api/rental-applications-overview/all-with-filter";
    ServerRoutes["SUBSCRIPTION_POST_CREATE_SUBSCRIPTION"] = "/api/subscription/create-subscription";
    ServerRoutes["SUBSCRIPTION_POST_UPDATE_PAYMENT_METHOD"] = "/api/subscription/update-payment-method";
    ServerRoutes["SUBSCRIPTION_POST_RETRIEVE_UPCOMING_INVOICE"] = "/api/subscription/retrieve-next-invoice";
    ServerRoutes["SUBSCRIPTION_POST_UPDATE_SUBSCRIPTION"] = "/api/subscription/update-subscription";
    ServerRoutes["SUBSCRIPTION_POST_RETRIEVE_SUBSCRIPTION_INFORMATION"] = "/api/subscription/retrieve-subscription";
    ServerRoutes["SUBSCRIPTION_GET_PRICES"] = "/api/subscription/prices";
    ServerRoutes["SUBSCRIPTION_GET_PRICE_PUBLISHABLE_TOKEN"] = "/api/subscription/stripe-publishable-token";
    ServerRoutes["SETTINGS"] = "/api/settings";
    ServerRoutes["SETTINGS_COMPANY_INFO"] = "/api/settings/company-info";
    ServerRoutes["SETTINGS_COMPANY_LOGO"] = "/api/settings/company-logo";
    ServerRoutes["SETTINGS_REGION_AND_CURRENCY"] = "/api/settings/region-and-currency";
    ServerRoutes["SETTINGS_PERSONAL_INFO"] = "/api/settings/personal-info";
    ServerRoutes["SETTINGS_LOGIN_AND_PASSWORD"] = "/api/settings/login-and-password";
    ServerRoutes["SETTINGS_GRANT_TECH_ACCESS"] = "/api/settings/grant-tech-access";
    ServerRoutes["SETTINGS_DEFAULT_ACCOUNTS"] = "/api/settings/default-accounts";
    ServerRoutes["SETTINGS_TENANT_PORTAL"] = "/api/settings/tenant-portal";
    ServerRoutes["SETTINGS_TENANT_PORTAL_HAS_TENANTS_AUTO_PAYMENTS"] = "/api/settings/tenant-portal/has-tenants-auto-payments";
    ServerRoutes["SETTINGS_TENANT_PORTAL_LOGO"] = "/api/settings/tenant-portal-logo";
    ServerRoutes["SETTINGS_TENANT_PORTAL_BACKGROUND"] = "/api/settings/tenant-portal-background";
    ServerRoutes["SETTINGS_OWNER_PORTAL"] = "/api/settings/owner-portal";
    ServerRoutes["SETTINGS_OWNER_PORTAL_LOGO"] = "/api/settings/owner-portal-logo";
    ServerRoutes["SETTINGS_OWNER_PORTAL_BACKGROUND"] = "/api/settings/owner-portal-background";
    ServerRoutes["SETTINGS_PAYMENT_INSTRUCTIONS"] = "/api/settings/payment-instructions";
    ServerRoutes["SETTINGS_RENT_REMINDERS"] = "/api/settings/rent-reminders";
    ServerRoutes["SETTINGS_PAYMENT_ALLOCATIONS"] = "/api/settings/payment-allocations";
    ServerRoutes["SETTINGS_TENANT_REQUESTS"] = "/api/settings/tenant-requests";
    ServerRoutes["SETTINGS_OWNER_REQUESTS"] = "/api/settings/owner-requests";
    ServerRoutes["SETTINGS_RENTAL_APPLICATIONS"] = "/api/settings/rental-applications";
    ServerRoutes["SETTINGS_LATE_FEES"] = "/api/settings/late-fees";
    ServerRoutes["SETTINGS_CONVENIENCE_FEES"] = "/api/settings/convenience-fees";
    ServerRoutes["SETTINGS_QUICKBOOKS"] = "/api/settings/quickbooks";
    ServerRoutes["SETTINGS_LISTINGS"] = "/api/settings/quickbooks/listings";
    ServerRoutes["SETTINGS_MANAGEMENT_FEES"] = "/api/settings/management-fees";
    ServerRoutes["SETTINGS_COMPANY_START_DATE"] = "/api/settings/company-start-date";
    ServerRoutes["SETTINGS_COMMUNICATION_CENTER"] = "/api/settings/communication-center";
    ServerRoutes["SETTINGS_CHECKBOOK"] = "/api/settings/checkbook";
    ServerRoutes["SETTINGS_CHECKBOOK_PUT_API_KEYS"] = "/api/settings/checkbook/api-keys";
    ServerRoutes["SETTINGS_CHECKBOOK_GET_AUTH_URI"] = "/api/settings/checkbook/auth-uri";
    ServerRoutes["SEARCH_GET"] = "/api/search";
    ServerRoutes["SEARCH_LEASES"] = "/api/search/leases";
    ServerRoutes["BANK_CONNECT_PLAID_ACCOUNTS"] = "/api/bank-connect/plaid-accounts";
    ServerRoutes["BANK_CONNECT_PLAID_ACCOUNTS_POST_CONNECT"] = "/api/bank-connect/plaid-accounts/connect";
    ServerRoutes["BANK_CONNECT_PLAID_ACCOUNTS_POST_DISCONNECT"] = "/api/bank-connect/plaid-accounts/disconnect";
    ServerRoutes["BANK_CONNECT_PLAID_ITEMS"] = "/api/bank-connect/plaid-items";
    ServerRoutes["BANK_CONNECT_PLAID_ITEMS_GET_UPDATE_PLAID_LINK_TOKEN"] = "/api/bank-connect/plaid-items/get-update-plaid-link-token";
    ServerRoutes["BANK_CONNECT_PLAID_ITEMS_GET_NEW_PLAID_LINK_TOKEN"] = "/api/bank-connect/get-new-plaid-link-token";
    ServerRoutes["BANK_CONNECT_PLAID_ITEMS_POST_RESET_LOGIN"] = "/api/bank-connect/plaid-items/reset-login";
    ServerRoutes["BANK_CONNECT_PLAID_REPAIR_CONNECTION"] = "/api/bank-connect/plaid-items/repair-connection";
    ServerRoutes["BANK_CONNECT_PLAID_ITEMS_GET_NEW_PLAID_LINK_TOKEN_FOR_REPAIR"] = "/api/bank-connect/get-new-plaid-link-token-repair";
    ServerRoutes["BANK_CONNECT_PLAID_TRANSACTIONS"] = "/api/bank-connect/plaid-transactions";
    ServerRoutes["BANK_CONNECT_PLAID_TRANSACTIONS_POST_UNDO"] = "/api/bank-connect/plaid-transactions/undo";
    ServerRoutes["BANK_CONNECT_PLAID_TRANSACTIONS_POST_EXCLUDE"] = "/api/bank-connect/plaid-transactions/exclude";
    ServerRoutes["BANK_CONNECT_PLAID_TRANSACTIONS_POST_MATCH_NEW_EXPENSE"] = "/api/bank-connect/plaid-transactions/match/new-expense";
    ServerRoutes["BANK_CONNECT_PLAID_TRANSACTIONS_POST_MATCH_NEW_DEPOSIT"] = "/api/bank-connect/plaid-transactions/match/new-deposit";
    ServerRoutes["BANK_CONNECT_PLAID_TRANSACTIONS_POST_MATCH_LEASE_PAYMENT"] = "/api/bank-connect/plaid-transactions/match/new-lease-payment";
    ServerRoutes["BANK_CONNECT_PLAID_TRANSACTIONS_POST_MATCH_NEW_TRANSFER"] = "/api/bank-connect/plaid-transactions/match/new-transfer";
    ServerRoutes["BANK_CONNECT_PLAID_TRANSACTIONS_POST_MATCH_EXISTING"] = "/api/bank-connect/plaid-transactions/match/existing";
    ServerRoutes["BANK_CONNECT_PLAID_TRANSACTIONS_POST_REFRESH"] = "/api/bank-connect/plaid-transactions/refresh";
    ServerRoutes["COMMUNICATIONS"] = "/api/communications";
    ServerRoutes["PHONE_NUMBERS_SUGGESTED_AREA_CODES"] = "/api/phone-numbers/suggested-area-codes";
    ServerRoutes["PHONE_NUMBERS_POST_VALIDATE_AREA_CODE"] = "/api/phone-numbers/validate-area-code";
    ServerRoutes["PHONE_NUMBERS_CURRENT_PHONE_NUMBER"] = "/api/phone-numbers/current-phone-number";
    ServerRoutes["PHONE_NUMBERS_POST_SEND_WELCOME_SMS"] = "/api/phone-numbers/send-welcome-sms";
    ServerRoutes["PHONE_NUMBERS_POST_CONVERSATION_PARTICIPANT_SUBSCRIPTION_STATUS"] = "/api/phone-numbers/conversation-participant-subscription-status";
    ServerRoutes["CONVERSATIONS"] = "/api/conversations";
    ServerRoutes["CONVERSATIONS_DETAILS"] = "/api/conversations/:id";
    ServerRoutes["CONVERSATIONS_MESSAGES"] = "/api/conversations/:id/messages";
    ServerRoutes["CONVERSATIONS_PUT_FIX_UNKNOWN_RECIPIENT"] = "/api/conversations/:id/fix-unknown-recipient";
    ServerRoutes["CONVERSATIONS_PDF"] = "/api/conversations/:id/pdf";
    ServerRoutes["CONVERSATIONS_STATS"] = "/api/conversations/stats";
    ServerRoutes["COMMUNICATIONS_CENTER_SETUP_SMS"] = "/api/communications-center/setup-sms";
    ServerRoutes["COMMUNICATIONS_CENTER_SETUP_SMS_PRICES"] = "/api/communications-center/setup-sms-prices";
    ServerRoutes["COMMUNICATIONS_CENTER_CREDITS"] = "/api/communications-center/credits";
    ServerRoutes["COMMUNICATIONS_CENTER_POST_PURCHASE_CREDITS"] = "/api/communications-center/purchase-credits";
    ServerRoutes["RECONCILIATIONS"] = "/api/reconciliations";
    ServerRoutes["RECONCILIATIONS_GET_NEW_RECONCILIATION"] = "/api/reconciliations/get-new-reconciliation";
    ServerRoutes["ANNOUNCEMENTS_POST_SEND"] = "/api/announcements/send";
    ServerRoutes["ANNOUNCEMENTS_REVIEW"] = "/api/announcements/review";
    ServerRoutes["ANNOUNCEMENTS"] = "/api/announcements";
    ServerRoutes["ANNOUNCEMENTS_GET_STATS"] = "/api/announcements/stats";
    ServerRoutes["ESIGNATURE_REQUESTS"] = "/api/esignature-requests";
    ServerRoutes["ESIGNATURE_REQUESTS_POST_SEND_REMINDER"] = "/api/esignature-requests/send-reminder";
    ServerRoutes["ESIGNATURE_REQUESTS_GET_CREDITS_REMAINING"] = "/api/esignature-requests/credits-remaining";
    ServerRoutes["ESIGNATURE_REQUESTS_GET_EMBEDDED_URL"] = "/api/esignature-requests/:id/embedded-url";
    ServerRoutes["ESIGNATURE_REQUESTS_POST_SEND"] = "/api/esignature-requests/:id/send";
    ServerRoutes["ESIGNATURE_REQUESTS_POST_CANCEL"] = "/api/esignature-requests/:id/cancel";
    ServerRoutes["ESIGNATURE_REQUESTS_DOWNLOAD_PDF"] = "/api/esignature-requests/:id/pdf";
    ServerRoutes["ESIGNATURE_TEMPLATES"] = "/api/esignature-templates";
    ServerRoutes["ESIGNATURE_TEMPLATES_GET_EMBEDDED_URL"] = "/api/esignature-templates/:id/embedded-url";
    ServerRoutes["ESIGNATURE_TEMPLATES_DOWNLOAD_PDF"] = "/api/esignature-templates/:id/pdf";
    ServerRoutes["USER_SETTINGS"] = "/api/user-settings";
    ServerRoutes["ACTIVITY_FEED"] = "/api/activity-feed";
    ServerRoutes["CUSTOM_ALLOCATIONS"] = "/api/custom-allocations";
    ServerRoutes["NOTIFICATIONS"] = "/api/notifications";
    ServerRoutes["TOTAL_UNREAD_NOTIFICATIONS"] = "/api/unread-notifications";
    ServerRoutes["MARK_ALL_UNREAD_NOTIFICATIONS"] = "/api/mark-all-unread-notifications";
    ServerRoutes["NOTIFICATIONS_GENERATE_PUSH_TOKEN"] = "/api/notifications/generate-push-token";
    ServerRoutes["NOTIFICATIONS_GENERATE_CHANNEL_TOKEN"] = "/api/notifications/generate-channel-token";
    ServerRoutes["CALENDAR"] = "/api/calendar";
    ServerRoutes["CALENDAR_GET_EVENTS"] = "/api/calendar/events";
    ServerRoutes["CALENDAR_GET_URL"] = "/api/calendar/url";
    ServerRoutes["CALENDAR_RESET_URL"] = "/api/calendar/url/reset";
    ServerRoutes["UNSUBSCRIBE_EMAIL"] = "/api/unsubscribe/email/:userType/:id";
    ServerRoutes["BOARD_MEMBERS"] = "/api/board-members";
    ServerRoutes["JOURNAL_ENTRIES"] = "/api/journal-entries";
    ServerRoutes["JOURNAL_ENTRIES_GET_PDF"] = "/api/journal-entries/pdf/:id";
    ServerRoutes["BULK_EXECUTION"] = "/api/bulk-execution";
})(ServerRoutes || (exports.ServerRoutes = ServerRoutes = {}));
