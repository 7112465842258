import React, { useState } from "react";
import { View } from "DLUI/view";
import { Icon } from "DLUI/icon";
import { ExclamationMarkIconPink } from "assets/icons";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { LoadingDialog } from "./loading";
import { DialogState } from "../loadingDialog";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import type { FileDto } from "@doorloop/dto";
import { DataCy } from "@doorloop/dto";
import type { ApiToastsProps } from "api/apiHelper";
import type { RestApiBase } from "api/restApiBase";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { filesApi } from "api/filesApi";

interface ComponentProps<DTO, GetAllQuery> {
  apiMethod?: RestApiBase<DTO, GetAllQuery>;
  method?: (id: string) => any;
  deleteConfirmationText?: string;
  didPressDismissButton: () => void;
  transactionId?: string;
  didFinishOperation: (values?: any) => void;
  didFailOperation?: (error?: any) => void;
  apiToasts?: ApiToastsProps<DTO>;
  attachments: FileDto[];
}

function DeleteConfirmation<DTO, GetAllQuery>({
  deleteConfirmationText,
  didPressDismissButton,
  apiMethod,
  method,
  transactionId,
  didFinishOperation,
  didFailOperation,
  apiToasts,
  attachments
}: ComponentProps<DTO, GetAllQuery>) {
  const { isMobile } = useResponsiveHelper();
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string | undefined>();

  const didPressDeleteButton = async () => {
    if (apiMethod?.delete && transactionId) {
      setDeleteInProgress(true);
      const response = await apiMethod.delete(transactionId, apiToasts);
      if (response && response.status) {
        if (attachments.length > 0) {
          await deleteRelatedFiles();
        }

        didFinishOperation(response.data);
      } else {
        setDeleteInProgress(false);
        setErrorText(response.message || AppStrings.Common.GeneralError);
        didFailOperation?.(response.message || AppStrings.Common.GeneralError);
      }
    } else if (transactionId && method) {
      setDeleteInProgress(true);
      const response = await method(transactionId);
      if (response && response.status) {
        didFinishOperation(response.data);
      } else {
        setDeleteInProgress(false);
        setErrorText(response.message || AppStrings.Common.GeneralError);
      }
    }
  };

  const deleteRelatedFiles = async () => {
    const deletePromises = attachments.map(async (file) => (file.id ? await filesApi.delete(file.id) : undefined));
    const deleteResponses = await Promise.all(deletePromises);
    const failedResponses = deleteResponses.filter((response) => response && !response.status);

    if (failedResponses.length > 0) {
      setDeleteInProgress(false);
      setErrorText(failedResponses[0]?.message || AppStrings.Common.GeneralError);
      didFailOperation?.(failedResponses[0]?.message || AppStrings.Common.GeneralError);
    }
  };

  if (deleteInProgress) {
    return (
      <LoadingDialog
        dialogState={DialogState.Show}
        loadingText={AppStrings.Common.DeletingTransaction}
        errorText={errorText}
        onRetryButtonPress={didPressDeleteButton}
        didPressDismissButton={didPressDismissButton}
      />
    );
  }

  if (errorText) {
    return (
      <View alignItems={"center"} height={"100%"}>
        <Icon marginTop={50} Source={ExclamationMarkIconPink} size={80} />
        <View flex={1} justifyContent={"center"} alignItems={"center"}>
          <Text color={"black"} fontSize={24} lineHeight={"34px"} fullWidth align={"center"}>
            {errorText}
          </Text>
        </View>
        <View
          flex={1}
          justifyContent={"center"}
          alignItems={"flex-end"}
          flexDirection={"row"}
          marginBottom={50}
          width={"100%"}
        >
          <DLButton
            actionText={AppStrings.Common.Dismiss}
            variant={DLButtonVariantsEnum.OUTLINED}
            color={DLButtonColorsEnum.NEUTRAL}
            size={DLButtonSizesEnum.LARGE}
            onClick={didPressDismissButton}
            style={{ marginRight: 20 }}
          />
        </View>
      </View>
    );
  }

  return (
    <View height={"100%"}>
      <View alignItems={"center"} paddingLeft={20} paddingRight={20}>
        <Icon marginTop={50} Source={ExclamationMarkIconPink} size={80} />
        <Text
          color={"black"}
          fontSize={24}
          fontWeight={700}
          value={AppStrings.Common.DeleteConfirmation}
          marginTop={30}
        />
        <Text
          color={"black"}
          fontSize={24}
          value={deleteConfirmationText ? deleteConfirmationText : AppStrings.Common.DeleteTransactionConfirmationText}
          marginTop={isMobile ? 50 : 20}
          lineHeight={"34px"}
          fullWidth
          align={"center"}
        />
        {attachments.length > 0 && (
          <Text
            color={"black"}
            fontSize={24}
            value={AppStrings.Common.DeleteRelatedFiles}
            marginTop={isMobile ? 50 : 20}
            lineHeight={"34px"}
            fullWidth
            align={"center"}
          />
        )}
      </View>
      <View
        flex={1}
        justifyContent={"center"}
        alignItems={"flex-end"}
        flexDirection={"row"}
        marginBottom={50}
        width={"100%"}
      >
        <DLButton
          actionText={AppStrings.Common.Dismiss}
          variant={DLButtonVariantsEnum.OUTLINED}
          color={DLButtonColorsEnum.NEUTRAL}
          size={DLButtonSizesEnum.LARGE}
          onClick={didPressDismissButton}
          style={{ marginRight: 20 }}
          dataCy={DataCy.dialogActionButtons.cancel}
        />
        <DLButton
          actionText={AppStrings.Common.Delete}
          size={DLButtonSizesEnum.LARGE}
          onClick={didPressDeleteButton}
          dataCy={DataCy.dialogActionButtons.delete}
        />
      </View>
    </View>
  );
}

export default DeleteConfirmation;
