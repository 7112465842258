import React, { Fragment, useRef, useState } from "react";
import type { PopoverItem } from "./types";
import { View } from "DLUI/view";
import makeStyles from "./styles";
import { Icon as DLUI_Icon } from "DLUI/icon";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { Popup } from "@progress/kendo-react-popup";

interface ComponentProps {
  sectionItems: PopoverItem[];
  children: React.ReactNode;
  width?: number;
}

const SelectionPopover: React.FC<ComponentProps> = ({ sectionItems, children, width }: ComponentProps) => {
  const [showPopover, setShowPopover] = useState<boolean>(false);

  const classes = makeStyles();
  const anchorRef = useRef(null);

  const handleClose = () => {
    setShowPopover(false);
  };

  const togglePopover = (event: any) => {
    setShowPopover(!showPopover);
  };

  const renderSeparator = () => <SeparationLine height={1} marginTop={5} marginBottom={5} width={"80%"} />;

  const renderPopoverContent = () => {
    const popoverItems = sectionItems.map((currentElement, index) => {
      const _onClick = () => {
        setShowPopover(false);
        if (currentElement.onClick) {
          currentElement.onClick();
        }
      };
      const CurrentLineIcon = currentElement.Icon;
      return (
        <Fragment key={"POI" + index}>
          <div className={classes.lineWrapper} onClick={_onClick} key={"PO" + index}>
            {CurrentLineIcon ? (
              <DLUI_Icon
                Source={CurrentLineIcon}
                width={20}
                height={20}
                marginLeft={10}
                pathColor={currentElement.iconPathColor || "black"}
              />
            ) : null}

            <Text
              color={currentElement.textColor || "black"}
              fontSize={16}
              value={currentElement.title}
              paddingRight={20}
              marginLeft={10}
            />
          </div>
          {currentElement.showSeparator ? renderSeparator() : null}
        </Fragment>
      );
    });

    return (
      <View paddingTop={10} alignItems={"center"} paddingBottom={10}>
        {popoverItems}
      </View>
    );
  };

  if (sectionItems.length < 1) {
    return (
      <View width={"auto"} alignItems={"center"}>
        {children}
      </View>
    );
  }

  return (
    <div>
      <View width={"auto"} alignItems={"center"} flexDirection={"row"} onClick={togglePopover}>
        {children}
      </View>

      <div style={{ marginTop: 10 }} ref={anchorRef} />
      {anchorRef !== null && anchorRef.current !== null ? (
        <Popup
          id={showPopover ? "mouseOverPopover" : undefined}
          show={showPopover}
          //@ts-ignore
          anchor={anchorRef.current}
        >
          <View width={width || undefined}>{renderPopoverContent()}</View>
        </Popup>
      ) : null}
    </div>
  );
};

export default SelectionPopover;
