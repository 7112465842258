export default {
  amountPerMonth: "Amount Per Month",
  amountPerDay: "Amount Per Day",
  chargeLateFee: "Charge a late fee",
  setupStructure: "Set Up Your Late Fee Structure",
  howOften: "How often",
  feeType: "Fee type",
  amountOrPercentage: "Amount / Percentage",
  account: "Account",
  addAnotherCharge: "Add Another Late Fee",
  advancedOptions: "Advanced options",
  hideAdvancedOptions: "Hide advanced options",
  showAdvancedOptions: "Show advanced options",
  dontChargeMoreThen: "Do not charge more than",
  perMonth: "per month",
  selectFeeAndFrequency: "Select the late fee amount and the charge frequency.",
  outstandingBalanceExceeds: "Only charge late fees if the outstanding balance exceeds",
  outstandingBalanceAccount: "Only charge late fees for outstanding balances on specific accounts",
  notifyTenantsOnCharge: {
    title: "Notify tenants of late fees once charged",
    description: "Let your tenants know each time a late fee is charged via email or push notification"
  },
  maximumFees: {
    titleDaily: "Maximum Daily Fees",
    titleMonthly: "Maximum Monthly Fees",
    descriptionDaily: "Late fees charged will never exceed the entered amount per day.",
    descriptionMonthly: "Late fees charged will never exceed the entered amount per month.",
    description: "Late fees charged will never exceed the entered amount within the specified frequency."
  },
  onlyChargeLateFeesOnMostRecentCharge: {
    title: "Apply Only to Most Recent Charge",
    description: "Late fees will only be applied to the most recent overdue charge (given the above conditions)."
  },
  reminderBefore: {
    title: "Send a reminder 1 day before late fees is charged",
    description: "Let tenants know about their outstanding balance, to avoid late fee charges."
  },
  reminderAfter: {
    title: "Send a notice upon charging late fees",
    description: "Inform tenants once a late fee is applied."
  },
  setMaxMonthlyLateFeesAmount: {
    title: "Set monthly late fee limit",
    description: "Set a limit of how much you can charge for late fees per month"
  },
  setMaxDailyLateFeesAmount: {
    title: "Set daily late fees limit",
    description: "Set a limit of how much you can charge for late fees per day"
  },
  setMinBalanceToApplyLateFeeAmount: {
    title: "Minimum Balance to Apply Fees",
    description: "Late fees will only be charged if the tenant’s balance exceeds the entered amount."
  },
  specifyAccountsToConsider: {
    title: "Apply Only on Specific Outstanding Charges",
    description: "Specify which accounts to consider when charging late fees"
  },
  textMessageOnChange:
    "Provide reminders by text before charging late fees, as well as notifications once fees have been charged (each message will use one communication credit).",
  daysAfterRentDue: "Days after rent is due",
  gracePeriod: "Grace Period",
  addLateFee: "Add Late Fee",
  editLateFee: "Edit late fee",
  addLateFeeDescription: "Set up late fee schedules to automate charges for tenants who pay late.",
  feeLimitations: "Fee Limitations & Restrictions",
  remindersTitle: "Notifications & Reminders",
  feeLimitationsDescription: "Set restrictions on how late fees are applied to avoid over or undercharging tenants.",
  feeRemindersDescription: "Notify tenants before and when late fees are applied.",
  descriptionLateFees:
    "DoorLoop automatically calculates and generates your late fees. Fill in the details below to get started.",
  learnMoreLateFees: "Learn more about late fees",
  sampleCalculator: {
    editInputs: "Edit Inputs",
    intro: `Use this tool to test your fee structure with sample data. Click "Edit Inputs" to change the values.`,
    payoutAccounts: "Payout Accounts:",
    expenseAccount: "Expense account",
    totalFeesSample: "Total",
    visibleToYou: "Visible only to you",
    flat: "Flat",
    totalSection: {
      title: "Total",
      minBalance: "* Minimum balance must be",
      recentCharge: "* Only applied to most recent charge",
      specificAccountsCharge: "* Charge late fees only on specific accounts"
    }
  }
};
