import React from "react";

import { SettingPermission } from "@doorloop/dto";

import type { DialogState } from "DLUI/dialogs/loadingDialog";
import { getOwnerPortalFormMenuItems } from "screens/settings/ownerPortal/menuItems";
import { initFormValues, validateForm, validateOwnerPortalForm } from "screens/settings/ownerPortal/formikHelper";
import { OwnerPortalAnimatedContentDialog } from "screens/settings/ownerPortal/ownerPortalAnimatedContentDialog";
import { SettingsReadOnlyScope } from "DLUI/permissionScope/readOnlyScope";

interface ComponentProps {
  dialogState: DialogState;
  onClose: () => void;
  onBackdropClick: () => void;
  title?: string;
}

export const OwnerPortalDialog: React.FC<ComponentProps> = ({
  dialogState,
  onClose,
  onBackdropClick,
  title
}: ComponentProps) => (
  <SettingsReadOnlyScope permission={SettingPermission.ownerPortal}>
    <OwnerPortalAnimatedContentDialog
      dialogState={dialogState}
      onClose={onClose}
      sectionItems={getOwnerPortalFormMenuItems()}
      disableBackdropClick
      onBackdropClick={onBackdropClick}
      initFormValues={initFormValues}
      formikValidation={validateForm}
      validationMethod={validateOwnerPortalForm}
      dialogTitle={title || ""}
      dialogHeight={920}
    />
  </SettingsReadOnlyScope>
);
