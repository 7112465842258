import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import AppStrings from "../../../../../../locale/keys";
import type { FormikProps } from "formik";
import { FastField, getIn, useFormikContext } from "formik";
import { FormikCheckBox, RadioGroup, TextField, UnControlledCheckBox, ValidationIndicator } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import type { MerchantAccountDto, RentalApplicationPropertySettingsDto } from "@doorloop/dto";
import {
  EPayPaymentMethod,
  mathUtils,
  MerchantAccountPlatform,
  MerchantAccountStatus,
  merchantAccountUtils,
  RentalApplicationChargeOptions
} from "@doorloop/dto";
import ApplicationSettingsSummaryLine from "screens/settings/rentalApplications/components/applicationSettingsSummaryLine";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import { useIntl } from "react-intl";
import AddressRequiredView from "screens/settings/rentalApplications/components/addressRequiredView";
import { useLocation, useParams } from "react-router-dom";
import { useEffectAsync } from "../../../../../../hooks/useEffectAsync";
import { Link } from "DLUI/link";
import { TermsOfServiceUrl, TransunionTermsOfServiceUrl } from "../../../../../../utils/sharedVariables";
import { TextTooltip } from "DLUI/popover/textTooltip";
import { Icon } from "DLUI/icon";
import { ExclamationMarkIconPink, InfoCircleBlueIcon, InformationIcon } from "../../../../../../assets";
import WarningView from "DLUI/form/warningView/warningView";
import ColorsEnum from "../../../../../../utils/colorsEnum";
import { AnimatedView } from "DLUI/view/animatedView";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { NavigationManager } from "../../../../../../utils/navigation";
import type { RadioButtonMap } from "../../../../../../hooks/useRentalApplicationLocale";
import { useRentalApplicationLocale } from "../../../../../../hooks/useRentalApplicationLocale";
import { merchantAccountOverviewApi } from "api/merchantAccountOverviewApi";
import { merchantAccountApi } from "api/bankAccountsApi";
import { FormikRadioGroup } from "DLUI/form/radioGroup/formikRadioGroup";
import { initRentalApplicationFeesSettings } from "screens/settings/rentalApplications/formikHelper";
import _ from "lodash";
import { Typography } from "@material-ui/core";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import type { ChipColorsEnum } from "DLUI/chip";
import { Chip, ChipSizesEnum } from "DLUI/chip";
import { useResponsiveHelper } from "contexts/responsiveContext";

interface Props {
  merchantAccountData?: MerchantAccountDto;
  formikRef: FormikProps<any>;
  transunionFee?: number;
  landlordId?: number;
  hideTermsAndConditions?: boolean;
  hideCompanySettingsRadio?: boolean;
}

interface IRadioButtonLabel {
  title: string;
  subtitle: string;
  disabled?: boolean;
  subTitleReplaceObj?: Record<string, string>;
  toolTipText?: string;
  chipProps?: {
    translationKey: string;
    color?: ChipColorsEnum;
  };
}

interface RadioLabelContentProps {
  item: RadioButtonMap;
  fees: string;
  merchantAccount?: MerchantAccountDto[];
  propertyId?: string;
}

export const TRANSUNION_FULL_AMOUNT = 49.9;

const getMerchantAccount = async (
  propertyId?: string
): Promise<{
  merchantAccounts;
  networkErrorMessage: string;
}> => {
  let merchantAccounts: MerchantAccountDto[] | undefined;
  let networkErrorMessage;

  try {
    if (propertyId) {
      const response = await merchantAccountOverviewApi.getMerchantAccountByProperty(propertyId);

      if (response.status) {
        merchantAccounts = response.data?.data;
      } else {
        networkErrorMessage = response.message;
      }
    } else {
      const response = await merchantAccountApi.getAll({
        filter_status: MerchantAccountStatus.ACTIVE,
        filter_platform: MerchantAccountPlatform.STRIPE
      });

      if (response.status) {
        merchantAccounts = response.data?.data;
      } else {
        networkErrorMessage = response.message;
      }
    }
  } catch (e) {
    const errorMessage = e as Error;
    networkErrorMessage = errorMessage.message;
  }

  return { merchantAccounts, networkErrorMessage };
};

const InfoBullet = ({ textComponent }) => (
  <View flexDirection={"row"} gap={8} noWrap>
    <Icon Source={InfoCircleBlueIcon} pathColor={"light-gray"} size={16} />
    {textComponent}
  </View>
);

const CustomFeeDetails = ({ item, fees, propertyId, merchantAccount }: RadioLabelContentProps) => {
  const { radioButtonsStrings, isUs, isNonNorthAmerica } = useRentalApplicationLocale(merchantAccount);
  const { isMobile } = useResponsiveHelper();
  const { t } = useTranslation();
  const formik = useFormikContext<RentalApplicationPropertySettingsDto>();

  const handleApplicationScreeningDeductionChange = (value: boolean) => {
    formik.setFieldValue("fees.deductScreeningCostFromApplication", value);
  };

  const handleMerchantAccountNavigation = () => {
    if (propertyId) {
      NavigationManager.editBankAccounts(location.pathname);
    } else {
      NavigationManager.runPropertyBankAccountsReport(true);
    }
  };

  return (
    <AnimatedView
      shouldShow={
        item.value === RentalApplicationChargeOptions.CHARGE_CUSTOM_FEE &&
        formik?.values?.fees?.rentalApplicationChargeOption === radioButtonsStrings?.custom?.value
      }
    >
      <HorizontalSeparationLine style={{ alignSelf: "center" }} width={"99%"} marginBottom={16} marginTop={16} />
      <View noWrap paddingLeft={isMobile ? 24 : 40} paddingRight={isMobile ? 24 : 40}>
        <Text value={AppStrings.Common.FeeSettings} fontSize={14} bold marginBottom={16} />
        <View flexDirection={"row"} alignItems={"flex-start"} noWrap>
          <View width={250} marginRight={16}>
            <FastField
              component={TextField}
              label={t(AppStrings.Common.Amount)}
              name={"fees.applicationFee"}
              textAlign={"left"}
              formatType={"currency"}
              decimalScale={2}
              maxNumber={999}
            />
            <Text
              value={AppStrings.Common.CreditCardFee}
              marginTop={4}
              fontSize={12}
              color={"secondary-gray"}
              replaceObject={{ feeAmount: fees }}
            />
          </View>
        </View>
        <AnimatedView flexDirection={"row"} alignItems={"flex-start"} shouldShow={isUs}>
          <View flexDirection={"row"} justifyContent={"flex-start"}>
            <UnControlledCheckBox
              labelValueProps={{
                text: AppStrings.Common.DeductScreeningCostText,
                color: "secondary-gray"
              }}
              name={"fees.deductScreeningCostFromApplication"}
              style={{
                flex: 1
              }}
              key={String(formik?.values?.fees?.deductScreeningCostFromApplication)}
              defaultChecked={formik?.values?.fees?.deductScreeningCostFromApplication}
              onChange={handleApplicationScreeningDeductionChange}
            />
          </View>
        </AnimatedView>
        <HorizontalSeparationLine style={{ alignSelf: "center" }} width={"99%"} marginBottom={16} marginTop={16} />
        <View gap={16} noWrap flex={1} fullWidth>
          <InfoBullet
            textComponent={
              <Text
                value={AppStrings.Common.EveryApplicantWillBeCharged}
                color={"secondary-gray"}
                fontSize={14}
                style={{
                  flex: 1
                }}
              />
            }
          />
          <AnimatedView shouldShow={!isNonNorthAmerica}>
            <InfoBullet
              textComponent={
                <View flex={"unset"} style={{ display: "block" }}>
                  <Text
                    as={"span"}
                    value={AppStrings.Common.ApplicationFeeApplyMerchantAccount}
                    color={"secondary-gray"}
                    fontSize={14}
                    style={{
                      flex: 1
                    }}
                  />{" "}
                  <Link onClick={handleMerchantAccountNavigation} textColor={ColorsEnum.BrightBlue} fontSize={14}>
                    {t(AppStrings.Common.PropertyAccountBankSettings)}
                  </Link>
                </View>
              }
            />
          </AnimatedView>
        </View>
      </View>

      <View height={20} />
    </AnimatedView>
  );
};

const RadioButtonLabelContent = ({ item, fees, propertyId, merchantAccount }: RadioLabelContentProps) => {
  const { isMobile } = useResponsiveHelper();

  return (
    <View paddingBottom={16}>
      <HorizontalSeparationLine style={{ alignSelf: "center" }} width={"99%"} marginTop={8} />
      <View gap={16} paddingTop={16} paddingLeft={isMobile ? 24 : 40} paddingRight={isMobile ? 24 : 40}>
        <View flexDirection={"row"} gap={8}>
          {item.bullets.map((bullet, index) => (
            <View flexDirection={"row"} gap={8} key={index} alignItems={"center"} noWrap>
              <Icon size={16} Source={bullet.icon} />
              <Text style={{ flex: 1 }} color={"secondary-gray"} value={bullet.translationKey} />
            </View>
          ))}
        </View>
      </View>
      {merchantAccount && (
        <CustomFeeDetails fees={fees} propertyId={propertyId} item={item} merchantAccount={merchantAccount} />
      )}
    </View>
  );
};

const ApplicationFeesForm: React.FC<Props> = ({ transunionFee, landlordId, hideCompanySettingsRadio = false }) => {
  const [merchantAccount, setMerchantAccount] = useState<MerchantAccountDto[]>();
  const authState = useSelector((state: IRootState) => state.auth);
  const [dialogErrorMessage, setDialogErrorMessage] = useState<string | undefined>();
  const { localeData } = authState;
  const { currency } = localeData;
  const { propertyId: propertyIdParam } = useParams<{ propertyId?: string }>();
  const { radioButtonsStrings, isUs, isNonNorthAmerica } = useRentalApplicationLocale(merchantAccount);
  const { formatNumber } = useIntl();
  const { t } = useTranslation();
  const location = useLocation<any>();
  // TODO - fix type - its from old implementation
  const formikRef = useFormikContext<any>();
  const propertyId = propertyIdParam || formikRef.values.property;

  useEffectAsync(async () => {
    const { merchantAccounts, networkErrorMessage } = await getMerchantAccount(propertyId);
    setDialogErrorMessage(networkErrorMessage);
    setMerchantAccount(merchantAccounts);
  }, [propertyId]);

  const { fees } = useMemo(() => {
    const totalApplicationFee = getIn(formikRef.values, "fees.applicationFee") || 0;
    const rentalApplicationChargeOption = getIn(formikRef.values, "fees.rentalApplicationChargeOption");

    if (merchantAccount?.[0]?.fees && rentalApplicationChargeOption === radioButtonsStrings?.custom?.value) {
      const { subtotal, fees, totalWithFees } = merchantAccountUtils.calculateFeesFromTotalWithFees(
        merchantAccount?.[0]?.fees,
        totalApplicationFee,
        EPayPaymentMethod.CARD
      );
      return {
        subtotal,
        fees: fees.toFixed(2),
        totalWithFees
      };
    }

    return {
      subtotal: totalApplicationFee,
      fees: "3.50",
      totalWithFees: totalApplicationFee
    };
  }, [merchantAccount, formikRef.values?.fees?.applicationFee, formikRef.values?.fees?.rentalApplicationChargeOption]);

  const renderApplicationSummarySection = () => {
    const applicationFee = getIn(formikRef.values, "fees.applicationFee");
    let totalAmount = applicationFee || 0;
    const rentalApplicationChargeOption = getIn(formikRef.values, "fees.rentalApplicationChargeOption");

    const summaryLines: any[] = [];
    if (rentalApplicationChargeOption === radioButtonsStrings?.custom?.value) {
      summaryLines.push(
        <Fragment key={"SML1"}>
          <ApplicationSettingsSummaryLine amount={applicationFee} label={AppStrings.Common.TotalChargeForApplicant} />
        </Fragment>
      );
      summaryLines.push(
        <Fragment key={"SML2"}>
          <ApplicationSettingsSummaryLine
            amount={Number(fees)}
            label={AppStrings.Common.OnlineCreditCardProcesingLabel}
          />
        </Fragment>
      );
      const deductScreeningCostFromApplication = getIn(formikRef.values, "fees.deductScreeningCostFromApplication");
      if (deductScreeningCostFromApplication) {
        totalAmount = mathUtils.substract(totalAmount, transunionFee);
        summaryLines.push(
          <Fragment key={"SML3"}>
            <ApplicationSettingsSummaryLine amount={transunionFee} label={AppStrings.Common.TransunionReportsCost} />
          </Fragment>
        );
      }

      totalAmount = mathUtils.substract(totalAmount, Number(fees));
    } else {
      summaryLines.push(
        <Fragment key={"SML2"}>
          <ApplicationSettingsSummaryLine amount={applicationFee} label={AppStrings.Common.TotalChargeForApplicant} />
        </Fragment>
      );
    }

    summaryLines.push(
      <Fragment key={"SPL" + 1}>
        <SeparationLine width={"100%"} height={1} />
      </Fragment>
    );

    totalAmount = rentalApplicationChargeOption === radioButtonsStrings?.applicant?.value ? 0 : totalAmount;

    summaryLines.push(
      <Fragment key={"SML4"}>
        <ApplicationSettingsSummaryLine
          amount={totalAmount}
          label={totalAmount >= 0 ? AppStrings.Common.YourProfit : AppStrings.Common.FinalCost}
          amountTextColor={totalAmount >= 0 ? "green" : "error"}
          labelfontWeight={700}
        />
      </Fragment>
    );

    return (
      <View>
        <Text value={AppStrings.Common.ApplicationFeeSummary} fontSize={16} fontWeight={700} marginTop={20} />
        <View borderRadius={4} maxWidth={"99%"}>
          <View>
            {summaryLines}
            {renderValidationError()}
          </View>
        </View>
      </View>
    );
  };

  const renderTransunionReportSection = () => (
    <View paddingTop={6} flexDirection={"row"}>
      <FastFieldSafe
        component={FormikRadioGroup}
        key={JSON.stringify(radioButtonsStrings)}
        name={"fees.rentalApplicationChargeOption"}
        radioGroupItemsProps={{
          fullWidth: true,
          radioOptionViewWrapperProps: {
            gap: 4
          },
          radioGroupItems: _.map(radioButtonsStrings, (value) => {
            return {
              label: () => (
                <RadioButtonLabel
                  disabled={value.disabled}
                  title={value.title}
                  subtitle={value.subtitle}
                  subTitleReplaceObj={value.subTitleReplace}
                  toolTipText={value.toolTipText}
                  chipProps={value.chipProps}
                />
              ),
              renderLabelContent: () => (
                <RadioButtonLabelContent
                  item={value}
                  fees={fees}
                  propertyId={propertyIdParam}
                  merchantAccount={merchantAccount}
                />
              ),
              style: {
                border:
                  formikRef.values?.fees?.rentalApplicationChargeOption === value.value
                    ? "2px solid rgba(22, 101, 216, 1)"
                    : undefined,
                backgroundColor: "white",
                borderRadius: "4px",
                paddingTop: "10px",
                paddingLeft: "10px",
                boxSizing: "border-box"
              } as React.CSSProperties,
              alwaysDisplayLabelContent: true,
              value: value.value,
              disabled: value.disabled
            };
          })
        }}
      />
    </View>
  );

  const renderValidationError = () => {
    const rentalApplicationChargeOption = getIn(formikRef.values, "fees.rentalApplicationChargeOption");
    const deductScreeningCostFromApplication = getIn(formikRef.values, "fees.deductScreeningCostFromApplication");
    const applicationFee = getIn(formikRef.values, "fees.applicationFee");
    const paymentFees = Number(fees || 0);
    const minimalPaymentAmount =
      (rentalApplicationChargeOption === radioButtonsStrings?.custom?.value && deductScreeningCostFromApplication
        ? transunionFee || 0
        : 0) + paymentFees;

    const isValidTransunionValue = applicationFee >= minimalPaymentAmount;

    if (rentalApplicationChargeOption === radioButtonsStrings?.custom?.value) {
      const currencyAmount = formatNumber(minimalPaymentAmount, {
        style: "currency",
        currency: currency || "USD"
      });

      return (
        <View marginBottom={20} fullWidth>
          {!isValidTransunionValue && (
            <WarningView
              type={"danger"}
              subTitle={AppStrings.Common.TransunionAmountError}
              iconSize={18}
              subTitleReplaceObject={{ amount: currencyAmount }}
            />
          )}
        </View>
      );
    }
  };

  const renderTermsValidationError = () => {
    const acceptedTermsAndConditions = getIn(formikRef.values, "fees.acceptedTermsAndConditions");

    return (
      <ValidationIndicator
        shouldShow={!acceptedTermsAndConditions}
        displayText={t(AppStrings.Common.TermsAndConditionsValidationMessage)}
        marginTop={"20px"}
        fullWidth
      />
    );
  };

  const rentalApplicationChargeOption = getIn(formikRef.values, "fees.rentalApplicationChargeOption");
  const rentalApplicationChargeOptionRef = useRef(rentalApplicationChargeOption);
  const { t: tt } = useTranslation();
  useEffect(() => {
    if (rentalApplicationChargeOption === rentalApplicationChargeOptionRef.current) {
      return;
    }

    rentalApplicationChargeOptionRef.current = rentalApplicationChargeOption;

    if (rentalApplicationChargeOption) {
      if (isUs && rentalApplicationChargeOption === radioButtonsStrings.applicant.value) {
        formikRef.setFieldValue("fees.applicationFee", TRANSUNION_FULL_AMOUNT);
      } else {
        formikRef.setFieldValue("fees.applicationFee", 0);
      }

      if (isUs && rentalApplicationChargeOption === radioButtonsStrings.custom.value) {
        formikRef.setFieldValue("fees.deductScreeningCostFromApplication", true);
      } else if (isUs && rentalApplicationChargeOption !== radioButtonsStrings.custom.value) {
        formikRef.setFieldValue("fees.deductScreeningCostFromApplication", undefined);
      }
    }
  }, [rentalApplicationChargeOption]);

  const didChangeDefaultsSelection = (value: string) => {
    const isEnable = value === "true";
    formikRef.setFieldValue("enabled", isEnable);

    if (!isEnable) {
      formikRef.setFieldValue("fees", undefined);
    } else {
      const feeValues = initRentalApplicationFeesSettings(isEnable, Boolean(formikRef.values?.fees), isUs);
      formikRef.setFieldValue("fees", feeValues);
    }
  };

  const addressIsValid =
    (propertyId && Boolean(formikRef?.values?.transunionPropertyId)) ||
    (!propertyId && Boolean(formikRef?.values.transunionLandlordId));

  if (dialogErrorMessage) {
    return (
      <View flexDirection={"row"} justifyContent={"center"} alignItems={"center"} height={"90%"} width={"100%"}>
        <View alignItems={"center"} noWrap>
          <View justifyContent={"center"} alignItems={"center"} marginTop={40}>
            <Icon Source={ExclamationMarkIconPink} width={50} height={50} />
            <Text bold fontSize={22} value={AppStrings.Common.NetworkErrorTitle} align={"center"} marginTop={20} />
          </View>
          <Text marginTop={20} marginBottom={20} align={"center"} fullWidth>
            {dialogErrorMessage}
          </Text>
        </View>
      </View>
    );
  }

  return (
    <>
      <AnimatedView
        shouldShow={Boolean(propertyId) && ((isUs && addressIsValid) || !isUs) && !hideCompanySettingsRadio}
      >
        <Text value={AppStrings.Common.RentalApplicationPropertyLevelCustomizeTitle} bold />
        <View flexDirection={"row"} noWrap marginBottom={16}>
          <FastField
            uniqueKey={"RAPCustomEnabled"}
            component={RadioGroup}
            name={"enabled"}
            defaultValue={`${formikRef?.values?.enabled}`}
            didChangeSelection={didChangeDefaultsSelection}
            radioGroupItems={[
              {
                label: tt("common.applyTheCompanysDefault"),
                value: "false"
              },
              {
                label: tt("common.defineCustomRentalApplicationFee"),
                value: "true"
              }
            ]}
          />
        </View>
        <HorizontalSeparationLine />
      </AnimatedView>
      <AnimatedView shouldShow={isUs && !addressIsValid}>
        <AddressRequiredView propertyId={propertyId} landlordId={landlordId} />
      </AnimatedView>
      <AnimatedView shouldShow={!propertyId || formikRef?.values?.enabled} marginTop={propertyId ? 16 : 0}>
        {(addressIsValid || !isUs) && (
          <View>
            <Text value={AppStrings.Common.RentalApplicationManageApplicationDescription} marginTop={4} fontSize={16} />
            <View height={55} overflow={"hidden"} paddingRight={6} marginTop={24} marginBottom={8}>
              <WarningView
                type={"warning"}
                subTitle={
                  isNonNorthAmerica
                    ? AppStrings.Common.ApplicationFeeIsNotApplicable
                    : AppStrings.Common.VerifyLocalFeeRegulations
                }
                overrideTextColor={ColorsEnum.DarkYellow}
                iconSize={14}
              />
            </View>
            <View maxWidth={"99%"}>{renderTransunionReportSection()}</View>
            {!isNonNorthAmerica && <View>{renderApplicationSummarySection()}</View>}
            <View alignItems={"center"} flexDirection={"row"} noWrap>
              <View flexDirection={"row"} noWrap marginRight={2} autoWidth>
                <FastField
                  component={FormikCheckBox}
                  rightText={AppStrings.Common.ByUsingRentalApp}
                  name={"fees.acceptedTermsAndConditions"}
                  justifyContent={"flex-start"}
                  fontSize={14}
                  iconSize={20}
                  textColor={"secondary-gray"}
                />
              </View>
              <View flexDirection="row" alignItems="center" gap={2} autoWidth>
                <Text value={AppStrings.Common.IAccept} fontSize={14} color={"secondary-gray"} />
                <Link hrefUrl={TermsOfServiceUrl} type="blank" underline={"always"} hoverColor={"lightBlue"}>
                  <Text value="DoorLoop" hoverColor={"lightBlue"} fontSize={14} color={"lightBlue"} />
                </Link>
                {isUs && (
                  <>
                    <Text value={AppStrings.Common.And} fontSize={14} color={"secondary-gray"} />
                    <Link
                      hrefUrl={TransunionTermsOfServiceUrl}
                      type="blank"
                      underline={"always"}
                      hoverColor={"lightBlue"}
                    >
                      <Text value="TransUnion" hoverColor={"lightBlue"} fontSize={14} color={"lightBlue"} />
                    </Link>
                  </>
                )}
                <Text value={AppStrings.Common.TermsAndConditions} fontSize={14} color={"secondary-gray"} />
              </View>
            </View>
            {renderTermsValidationError()}
          </View>
        )}
        <View height={20} />
      </AnimatedView>
    </>
  );
};

const RadioButtonLabel: React.FC<IRadioButtonLabel> = (radioButton) => {
  const { t } = useTranslation();

  return (
    <>
      {radioButton?.toolTipText ? (
        <TextTooltip value={radioButton?.toolTipText} useDark maxWidth={300}>
          <View cursor={"default"} fullWidth flexDirection={"row"} alignItems={"center"} noWrap gap={8}>
            <Text color={radioButton.disabled ? "gray" : undefined} bold value={radioButton.title} />
            <Icon Source={InformationIcon} size={16} pathColor={"light-gray"} />
            {radioButton.chipProps && (
              <Chip
                isRectangle
                color={radioButton.chipProps.color}
                labelProps={{
                  translationKey: radioButton.chipProps.translationKey,
                  isBold: true
                }}
                size={ChipSizesEnum.SMALL}
              />
            )}
          </View>
        </TextTooltip>
      ) : (
        <Typography style={{ lineBreak: "auto", fontSize: 14 }}>
          <View gap={8} flexDirection={"row"}>
            <Typography display={"inline"} style={{ fontWeight: "bold" }}>
              {t(radioButton.title)}
            </Typography>
            {radioButton.chipProps && (
              <Chip
                isRectangle
                color={radioButton.chipProps.color}
                labelProps={{
                  translationKey: radioButton.chipProps.translationKey,
                  isBold: true
                }}
                size={ChipSizesEnum.SMALL}
              />
            )}
          </View>
        </Typography>
      )}
    </>
  );
};

export default ApplicationFeesForm;
