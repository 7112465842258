import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import { View } from "DLUI/view";
import { FastField, getIn, useFormikContext } from "formik";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { SettingsReadOnlyScope } from "DLUI/permissionScope/readOnlyScope";
import type { QuickbooksSettingsDto } from "@doorloop/dto";
import { SettingPermission, SettingsAccessEnum } from "@doorloop/dto";
import { FormikDatePicker, FormikSwitchButton } from "DLUI/form";
import Text from "DLUI/text";
import Grid from "@material-ui/core/Grid";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { settingsApi } from "api/settingsApi";
import type { ApiResult } from "api/apiResult";
import SyncNotificationBox from "screens/settings/quickbooksSync/syncNotificationBox";
import { RestrictedPermissionAccess } from "screens/settings/userRoles";
import AppStrings from "../../../../locale/keys";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  didFinishOperation: (values: QuickbooksSettingsDto) => void;
  onBackdropClick: () => void;
  dialogTitle: string;
}

export const QuickbooksSyncDialogFrameWidth = 780;
export const QuickbooksSyncDialogFrameHeight = 420;

const DialogViews = {
  LoadingView: 0,
  DialogForm: 1
};

const QuickbooksSyncDialog: React.FC<ComponentProps> = ({
  didFinishOperation,
  onBackdropClick,
  dialogTitle
}: ComponentProps) => {
  const formik = useFormikContext<QuickbooksSettingsDto>();
  const { isMobile, screenContainerPadding } = useResponsiveHelper();
  const { dialogHorizontalPadding } = DialogsHelper();
  const [viewIndex, setViewIndex] = useState(DialogViews.DialogForm);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string | undefined>();
  const [showNotificationBox, setShowNotificationBox] = useState<boolean>(getIn(formik.values, "enabled") || false);

  const notificationBoxType = useMemo(() => (getIn(formik.values, "enabled") ? "alreadySynced" : "pendingSync"), []);

  const isValidForm = async () => {
    const errors = (await formik.validateForm()) as any;
    return _.isEmpty(errors);
  };

  const updateQuickbooksSyncSettings = async () => {
    const response = (await settingsApi.updateQuickbooksSettings(formik.values).catch((error) => {
      setLoadingDialogErrorText(error);
      setLoadingDialogState(DialogState.Error);
    })) as ApiResult<any>;

    if (response && response.status && response.data) {
      if (response.data.redirectUrl) {
        window.location.href = response.data.redirectUrl;
      } else {
        didFinishUpdateSyncSettings();
      }
    } else {
      setLoadingDialogErrorText(response.message);
      setLoadingDialogState(DialogState.Error);
    }
  };

  const didPressSaveButton = async () => {
    const isValid = await isValidForm();
    if (isValid) {
      setLoadingDialogState(DialogState.Show);
      setViewIndex(DialogViews.LoadingView);
      await updateQuickbooksSyncSettings();
    }
  };

  const renderActionPanelButtons = () => {
    if (viewIndex === DialogViews.LoadingView) {
      return <div />;
    }
    return (
      <RestrictedPermissionAccess
        clearance={{
          permission: SettingPermission.quickbooks,
          requiredLevel: SettingsAccessEnum.EDIT
        }}
      >
        <FormActionButtons
          propsSubButton={{ onClick: onBackdropClick }}
          propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
        />
      </RestrictedPermissionAccess>
    );
  };

  const didPressDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(DialogViews.DialogForm);
  };

  const didFinishUpdateSyncSettings = () => {
    didFinishOperation(formik.values);
    setLoadingDialogState(DialogState.Hidden);
  };

  const renderSyncInfo = () => (
    <View noWrap shouldShow={showNotificationBox} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
      <Grid item xs={12} md={4} lg={4}>
        <View paddingRight={screenContainerPadding}>
          <FastField
            component={FormikDatePicker}
            uniqueKey={"syncStartDate"}
            label={AppStrings.Common.SyncStartDate}
            name={"startDate"}
          />
        </View>
      </Grid>
      <Text value={AppStrings.Common.SyncStartDateDescription} color={"gray"} fontSize={14} lineHeight={"22px"} />
      <SyncNotificationBox boxType={notificationBoxType} />
    </View>
  );

  const didChangeSwitchButton = (nextValue) => {
    if (!nextValue) {
      setShowNotificationBox(false);
    } else {
      setShowNotificationBox(true);
    }
  };

  const renderForm = () => (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <View
        flexDirection={"row"}
        noWrap
        alignItems={"center"}
        paddingLeft={dialogHorizontalPadding}
        paddingRight={dialogHorizontalPadding}
        marginTop={20}
      >
        <FastField component={FormikSwitchButton} name={"enabled"} onChange={didChangeSwitchButton} />
        <View marginLeft={10} flex={1}>
          <Text value={AppStrings.Common.QuickbooksSyncToggleTitle} />
          <Text
            value={AppStrings.Common.QuickbooksSyncToggleDescription}
            color={"gray"}
            fontSize={14}
            lineHeight={"22px"}
            marginTop={isMobile ? 10 : 0}
          />
          {renderSyncInfo()}
        </View>
      </View>
    </MuiPickersUtilsProvider>
  );

  const renderView = ({ index }: any) => {
    if (index === DialogViews.DialogForm) {
      return renderForm();
    }
    if (index === DialogViews.LoadingView) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            errorText={loadingDialogErrorText}
            onRetryButtonPress={didPressSaveButton}
            didPressDismissButton={didPressDismissButton}
          />
        </View>
      );
    }
    return <div />;
  };

  const _onBackdropClick = () => {
    if (viewIndex === DialogViews.LoadingView) {
      setViewIndex(DialogViews.DialogForm);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const frameType = useMemo(() => {
    if (viewIndex === DialogViews.DialogForm) {
      return "sectionTitleFrame";
    }

    return "contentOnly";
  }, [viewIndex]);

  return (
    <SettingsReadOnlyScope permission={SettingPermission.quickbooks}>
      <DialogFrame
        onCloseButtonClick={_onBackdropClick}
        title={dialogTitle}
        width={getDialogFrameDimension("width", QuickbooksSyncDialogFrameWidth)}
        height={getDialogFrameDimension("height", QuickbooksSyncDialogFrameHeight)}
        renderView={renderView}
        numViews={2}
        activeView={viewIndex}
        RenderActionPanelButtons={renderActionPanelButtons}
        frameType={frameType}
      />
    </SettingsReadOnlyScope>
  );
};

export default QuickbooksSyncDialog;
