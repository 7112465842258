export const isEditFromDetailScreen = (
  screenName: string,
  expectedIdIndex: number,
  expectedEditIndex: number,
  routePath: string,
  locationPath: string
) => {
  const locationParts = locationPath.split("/");
  const expectedLocationParts = routePath.split("/");
  if (locationParts[1] !== screenName) {
    return false;
  }

  if (locationParts.length !== expectedLocationParts.length) {
    return false;
  }
  if (locationParts[locationParts.length - expectedEditIndex] !== "edit") {
    return false;
  }
  if (locationParts[expectedIdIndex].length !== 24) {
    return false;
  }
  return true;
};
