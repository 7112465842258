import type { FieldArrayRenderProps } from "formik";
import { getIn, useFormikContext } from "formik";
import type {
  DeepKeys,
  ManagementFeeRuleDto,
  ManagementFeesCompanySettingsDto,
  ManagementFeesPropertySettingsDto
} from "@doorloop/dto";
import { DataCy } from "@doorloop/dto";
import { isPercentageBasedFee } from "@doorloop/dto";
import { useTypedTranslation } from "locale";
import { TextField } from "DLUI/form";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import type { CSSProperties } from "react";
import { useCallback, useMemo, useRef } from "react";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import { useManagementFeesStyles } from "screens/settings/managementFees/useStyles";
import clsx from "clsx";
import Text from "DLUI/text";
import IconButton from "DLUI/form/iconButton/iconButton";
import { ChevronLeftGray } from "@/assets";
import { useResponsiveHelper } from "contexts/responsiveContext";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import { get } from "lodash";
import { RuleTypeSelector } from "screens/settings/managementFees/formElements/ruleTypeSelector";
import { useGeneralStyles } from "styles/generalStyles";
import { EXPENSE_QUERY_PARAMS, REVENUE_QUERY_PARAMS } from "screens/settings/managementFees/formElements/utils";
import { useMobileStyles } from "DLUI/dialogs/settings/common/settings/mobileStyles";

export type ManagementFeeItemFormMode = "add" | "edit";

export interface ManagementFeeItemFormMobileProps {
  rulesPath: DeepKeys<ManagementFeesCompanySettingsDto | ManagementFeesPropertySettingsDto>;
  handleClose: () => void;
  mode?: ManagementFeeItemFormMode;
  arrayHelpers: FieldArrayRenderProps;
  index: number;
}

const BUTTON_STYLE: CSSProperties = { height: 48, flex: 1 };

export const ManagementFeeItemFormMobile = ({
  rulesPath,
  handleClose,
  mode = "edit",
  arrayHelpers,
  index
}: ManagementFeeItemFormMobileProps) => {
  const formikRef = useFormikContext<ManagementFeesCompanySettingsDto>();
  const { values, errors, setFieldValue, setFieldTouched } = formikRef;
  const rulePath = `${rulesPath}[${index}]`;
  const rule: ManagementFeeRuleDto = getIn(values, rulePath);
  const { ruleType } = rule;
  const initialRule = useRef<ManagementFeeRuleDto>(rule);
  const { screenContainerPadding } = useResponsiveHelper();
  const classes = useManagementFeesStyles({ screenContainerPadding });
  const mobileClasses = useMobileStyles({ screenContainerPadding });
  const generalClasses = useGeneralStyles();

  const isRuleValid = useMemo(() => {
    const errorsObject = get(errors, rulePath);
    return errorsObject ? Object.keys(errorsObject).length === 0 : true;
  }, [errors, rulePath]);

  const { t } = useTypedTranslation();

  const handleExitWithoutSave = useCallback(() => {
    if (mode === "add") {
      arrayHelpers?.remove(arrayHelpers.form.values.feeRules.length - 1);
    }
    if (mode === "edit") {
      const { current: initialData } = initialRule;
      arrayHelpers.replace(index, initialData);
    }

    handleClose();
  }, [arrayHelpers, handleClose, index, mode]);

  const resetFee = useCallback(() => {
    setFieldValue(`${rulePath}.fee`, undefined);
    setFieldTouched(`${rulePath}.fee`, false);
  }, []);

  const headerText = t(
    mode === "add"
      ? "settings.generalSettings.managementFees.addManagementFee"
      : "settings.generalSettings.managementFees.editManagementFee"
  );

  const finishText = t(
    mode === "add"
      ? "settings.generalSettings.feesMobileForm.addFee"
      : "settings.generalSettings.feesMobileForm.saveFeeChanges"
  );

  return (
    <>
      <div
        className={clsx(classes.columnContainer, mobileClasses.ruleItemMobileContainer)}
        data-cy={DataCy.globalSettings.managementFees.rules.rule.mobileFormContainer}
      >
        <div className={mobileClasses.ruleItemMobileHeader}>
          <IconButton Icon={ChevronLeftGray} onClick={handleExitWithoutSave} size={14} width={30} />
          <Text value={headerText} fontSize={20} fontWeight={700} />
        </div>
        <div className={clsx(classes.columnContainer, mobileClasses.ruleItemMobileContent, classes.mobileFormGap)}>
          <RuleTypeSelector rulePath={rulePath} handleChange={resetFee} />
          {ruleType && (
            <>
              {isPercentageBasedFee(ruleType) ? (
                <div className={clsx(generalClasses.flexColumn)}>
                  <div className={classes.verticalPadding}>
                    <Text value={t("settings.generalSettings.managementFees.selectFeePercent")} fontSize={16} />
                  </div>
                  <div className={clsx(generalClasses.flexRow, generalClasses.smallGap, classes.verticalPadding)}>
                    <div className={classes.feeInputWidth}>
                      <FastFieldSafe
                        component={TextField}
                        label={t("settings.generalSettings.managementFees.fee")}
                        name={`${rulePath}.fee`}
                        required
                        dataCy={DataCy.globalSettings.managementFees.rules.rule.feeInput}
                        formatType={"percent"}
                      />
                    </div>
                    <div className={generalClasses.basicFlex}>
                      <BankAccountFormikAutoCompleteField
                        addCreateOption
                        uniqueIndex={"TS"}
                        name={`${rulePath}.baseAccount`}
                        queryParams={REVENUE_QUERY_PARAMS}
                        dataCy={DataCy.globalSettings.managementFees.rules.rule.baseAccountSelect}
                        label={t("settings.generalSettings.managementFees.baseCategory")}
                        defaultValue={getIn(values, `${rulePath}.baseAccount`)}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className={clsx(generalClasses.flexColumn)}>
                  <div className={classes.verticalPadding}>
                    <Text value={t("settings.generalSettings.managementFees.enterFlatFee")} fontSize={16} />
                  </div>
                  <div className={classes.verticalPadding}>
                    <FastFieldSafe
                      component={TextField}
                      label={t("settings.generalSettings.managementFees.fee")}
                      name={`${rulePath}.fee`}
                      required
                      dataCy={DataCy.globalSettings.managementFees.rules.rule.feeInput}
                      formatType={"currency"}
                    />{" "}
                  </div>
                </div>
              )}
              <div className={clsx(generalClasses.flexColumn)}>
                <div className={classes.verticalPadding}>
                  <Text value={t("settings.generalSettings.managementFees.selectExpenseAccount")} fontSize={16} />
                </div>
                <div className={classes.verticalPadding}>
                  <BankAccountFormikAutoCompleteField
                    addCreateOption
                    uniqueIndex={"TS"}
                    name={`${rulePath}.expenseAccount`}
                    queryParams={EXPENSE_QUERY_PARAMS}
                    label={t("settings.generalSettings.managementFees.expenseCategory")}
                    defaultValue={getIn(values, `${rulePath}.expenseAccount`)}
                    dataCy={DataCy.globalSettings.managementFees.rules.rule.expenseAccountSelect}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div className={clsx(mobileClasses.ruleItemMobileContent, mobileClasses.ruleItemMobileButtons)}>
          <DLButton
            onClick={handleExitWithoutSave}
            actionText={t("common.cancel")}
            variant={DLButtonVariantsEnum.CONTAINED}
            color={DLButtonColorsEnum.NEUTRAL}
            size={DLButtonSizesEnum.MEDIUM}
            style={BUTTON_STYLE}
            dataCy={DataCy.dialogActionButtons.cancel}
            disableMobileCollapse
          />
          <DLButton
            onClick={handleClose}
            actionText={finishText}
            variant={DLButtonVariantsEnum.CONTAINED}
            color={DLButtonColorsEnum.SECONDARY}
            size={DLButtonSizesEnum.MEDIUM}
            disabled={!isRuleValid}
            style={BUTTON_STYLE}
            dataCy={DataCy.dialogActionButtons.save}
            disableMobileCollapse
          />
        </div>
      </div>
    </>
  );
};
