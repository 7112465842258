import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    componentContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%"
    },
    instructionsText: {
      marginTop: 10
    },
    actionButtonContainer: {
      marginTop: 30
    },
    backgroundDrawing: {
      position: "absolute",
      zIndex: 10,
      width: "100%",
      height: "100%"
    },
    contentContainer: {
      position: "absolute",
      zIndex: 100,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    }
  })
);
