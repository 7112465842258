import { useMutation } from "@tanstack/react-query";
import { throwIfNotSuccessful } from "./entityApiStore.utils";
import { createUseOptimisticCreateHook } from "./useOptimisticCreate";

import type { BaseDto } from "@doorloop/dto";
import type { RestApiBase } from "../restApiBase";
import type { MutationStoreReturn } from "./createMutationStore.types";

export function createMutationStore<TDto extends BaseDto, TGetAllDto>(
  baseKey: string,
  api: RestApiBase<TDto, TGetAllDto>
): MutationStoreReturn<TDto> {
  return {
    useCreate: (options) =>
      useMutation({
        mutationKey: [`${baseKey}-create`],
        mutationFn: async ({ item, options }) => {
          const apiResult = await api.create(item, options?.toasts);
          throwIfNotSuccessful(apiResult);
          return apiResult;
        },
        ...options
      }),
    useOptimisticCreate: createUseOptimisticCreateHook(baseKey, api),
    useUpdate: (options) =>
      useMutation({
        mutationKey: [`${baseKey}-update`],
        mutationFn: async ({ id, item, options }) => {
          const apiResult = await api.update(id, item, options?.toasts);
          throwIfNotSuccessful(apiResult);
          return apiResult;
        },
        ...options
      }),
    useDelete: (options) =>
      useMutation({
        mutationKey: [`${baseKey}-delete`],
        mutationFn: async (id: string) => {
          const apiResult = await api.delete(id);
          throwIfNotSuccessful(apiResult);
          return apiResult;
        },
        ...options
      }),
    useDuplicate: (options) =>
      useMutation({
        mutationKey: [`${baseKey}-duplicate`],
        mutationFn: async ({ id }) => {
          const apiResult = await api.duplicate(id);
          throwIfNotSuccessful(apiResult);
          return apiResult;
        },
        ...options
      })
  };
}
