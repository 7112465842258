import { makeStyles } from "@material-ui/core/styles";
import ColorsEnum from "../../../utils/colorsEnum";

export default makeStyles((theme) => {
  return {
    dialogBackground: {
      backgroundColor: "rgba(24, 44, 76,0.8)"
    },
    dialogDarkBackground: {
      backgroundColor: "rgba(24, 44, 76)"
    },
    dialogWrapper: {
      backgroundColor: "transparent",
      overflow: "hidden"
    },
    contentWrapper: {
      margin: "20px 80px 60px 80px",
      flex: 1,
      backgroundColor: theme.palette.white.main,
      borderRadius: "20px",
      boxShadow: "5px 5px 10px 0px rgba(40,44,97,0.1)"
    },

    contentWrapperMobile: {
      margin: "20px",
      flex: 1
    },

    contentContainer: {
      display: "flex",
      flexDirection: "column",
      margin: "0px 0px",
      backgroundColor: theme.palette.screenBackground.main
    },
    dialogTitleContainer: {
      display: "flex",
      flexDirection: "row",
      backgroundColor: theme.palette.blue.main
    },
    dialogTitleWrapper: {
      flex: 1,
      paddingLeft: 20,
      backgroundColor: theme.palette.screenBackground.main
    },
    dialogTitle: {
      margin: "25px 30px"
    },
    itemsPlaceholder: {
      width: 220,
      height: "100%"
    },

    closeIconContainer: {
      display: "flex",
      marginRight: "40px"
    },
    closeIconContainerMobile: {
      marginRight: "10px"
    },

    closeIcon: {
      color: theme.palette.gray.light,
      width: "40px",
      height: "40px"
    },
    dialogContainer: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.screenBackground.main,
      width: "100%",
      height: "100%"
    },

    dialogContentContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row"
    },

    loadingContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    navigationBarContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.blue.main,
      position: "relative",
      justifyContent: "flex-start",
      width: 265
    },

    itemsWrapper: {
      position: "relative",
      marginTop: 10,
      width: "100%"
    },
    viewsContainer: {
      display: "flex",
      flex: 1,
      position: "relative",
      flexDirection: "column"
    },
    menuItemContainer: {
      width: "100%",
      height: "35px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      cursor: "pointer",
      marginBottom: 4
    },
    menuItemLink: {
      padding: "0 20px"
    },
    selected: {
      backgroundColor: theme.palette.blue.main
    },
    menuItemIcon: {
      marginRight: "15px",
      marginLeft: "40px"
    },
    menuItemIconMobile: {
      width: "40px",
      height: "20px",
      padding: "0px 20px"
    },
    menuItemIconSelected: {
      marginLeft: 20,
      marginRight: 15
    },
    menuItemIconSelectedMobile: {
      margin: "0px 10px",
      width: "40px",
      height: "20px"
    },
    selectionIndicatorContainer: {
      height: 35,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      position: "relative",
      backgroundColor: ColorsEnum.BrightBlue,
      borderRadius: 4,
      width: "100%"
    },
    selectionIndicator: {
      height: 35,
      position: "absolute",
      left: 10,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "calc(100% - 20px)"
    },

    selctionIndicatorIcon: {
      position: "absolute",
      right: 0
    },

    contentViewWrapper: {
      width: "100%",
      position: "absolute",
      left: 0,
      backgroundColor: theme.palette.screenBackground.main,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      overflowY: "auto"
    },

    startTop: {
      top: -1500
    },
    startBottom: {
      top: 1500
    },
    leave: {
      top: 1500,
      transition: "top 0.5s ease 0s"
    },
    enter: {
      top: 0,
      transition: "top 0.5s ease 0s"
    },
    dialogActionPanelContainer: {
      width: "95%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      height: "90px"
    },

    dialogActionPanelContainerMobile: {
      alignItems: "flex-start",
      marginTop: 20
    },

    mobileDialog: {
      width: "100%",
      height: "100%",
      position: "fixed",
      top: 1000,
      left: 0,
      display: "flex",
      zIndex: 2000
    },
    hide: {
      top: 1000,
      transition: "top 0.3s ease 0s"
    },
    show: {
      top: 0,
      transition: "top 0.3s ease 0s"
    },
    animatedContentDialogWrapper: {
      display: "flex",
      flex: 1,
      overflow: "hidden",
      position: "relative"
    },
    loadingDialogContainer: {
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      display: "flex"
    }
  };
});
