import MomentUtils from "@date-io/moment";
import type {
  DepositDto,
  LeaseChargeDto,
  LeaseCreditDto,
  VendorOutstandingTransactionDto,
  VendorOutstandingTransactionsByResource,
  VendorOutstandingTransactionsResponse
} from "@doorloop/dto";
import {
  createValidator,
  DateFormats,
  LinkedResourceType,
  ObjectPermission,
  PaymentMethod,
  SegmentEventTypes,
  VendorBillPaymentDto
} from "@doorloop/dto";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { accountsApi } from "api/accounts";
import type { ApiResult } from "api/apiResult";
import { vendorBillPaymentsApi, vendorBillPaymentsOutstandingTransactionsApi } from "api/outstandingTransactionsApi";
import { propertiesApi } from "api/propertiesApi";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { FormikDatePicker, FormikReferenceLabel, Select, ViewOnlyInput } from "DLUI/form";
import { AsyncSectionTitle } from "DLUI/screen";
import { SeparationLine } from "DLUI/separatorView";
import { StickyHeader } from "DLUI/stickyHeader";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, Formik } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DialogFrame, { getDialogFrameDimension } from "../components/dialogFrame";
import { DialogsHelper } from "../dialogsHelper";
import PayeeSelection from "../vendor/expense/payeeSelection";
import OutstandingAllocationItem, { OutstandingAllocationType } from "./outstandingAllocationItem";
import OutstandingBillsAllocationDialogSummary from "./outstandingBillsAllocationDialogSummary";
import OutstandingBillsAllocationLinesSummary from "./outstandingBillsAllocationLinesSummary";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import DeleteConfirmation from "DLUI/dialogs/components/deleteConfirmation";
import ReconciledNotificationView from "DLUI/dialogs/components/reconciledNotificationView";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import { filesApi } from "api/filesApi";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import moment from "moment";
import { Notes } from "DLUI/notes";
import { VoidConfirmation } from "../components/voidConfirmation";
import type { DLButtonProps } from "DLUI/button/dlButton";
import { entityApiStore } from "api/entityApiStore/entityApiStore";
import { analyticsService } from "../../../../services/analyticsService";
import { ActivityLabel } from "../../activityLabel/activityLabel";

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
  dialogFrameWidth?: number;
  dialogFrameHeight?: number;
  transactionDto: LeaseChargeDto | LeaseCreditDto | DepositDto;
  transactionCreatedText: string;
  loadingTransactionDataText: string;
  loadingTransactionDataSuccessText: string;
  updatingTransactionText: string;
  transactionUpdatedText: string;
}

enum DialogViews {
  DialogForm,
  DeleteConfirmation,
  VoidConfirmation
}

let formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

const validateForm = createValidator(VendorBillPaymentDto);

interface VendorOutstandingTransaction {
  transactionDto: VendorOutstandingTransactionDto;
  type: OutstandingAllocationType;
}

const VendorBillPayment: React.FC<ComponentProps> = ({
  onBackdropClick,
  dialogTitle,
  transactionCreatedText,
  loadingTransactionDataText,
  loadingTransactionDataSuccessText,
  onClose
}: ComponentProps) => {
  const [files, setFiles] = useState<FileListItemProps[]>([]);

  const { t } = useTranslation();
  const { vendorId, transactionId } = useParams<any>();
  const { dialogHorizontalPadding } = DialogsHelper();
  const { mutateAsync: voidCheck } = entityApiStore.vendorBillPayments.mutations.useVoidCheck();
  const [currentSelectedVendorId, setCurrentSelectedVendorId] = useState<string | undefined>(vendorId);
  const editMode = transactionId !== undefined;
  const permission: AnyPermissionClearance = {
    permission: ObjectPermission.vendorBillPayments,
    field: editMode ? "edit" : "create"
  };
  const [viewIndex, setViewIndex] = useState(0);

  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    editMode ? DialogState.Show : DialogState.Hidden
  );
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const [loadingDialogSuccessText, setLoadingDialogSuccessText] = useState<string>(transactionCreatedText);
  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState<string>(loadingTransactionDataText);

  const [paymentData, setPaymentData] = useState<VendorBillPaymentDto | undefined>();
  const [shouldrenderForm, setShouldRenderForm] = useState<boolean>(!editMode);
  const [outstandingTransactionsData, setOutstandingTransactionsData] = useState<
    _.Dictionary<VendorOutstandingTransactionsByResource[]> | undefined
  >();

  const [paymentAccount, setPaymentAccount] = useState<string | undefined>();

  const isReconciled = useMemo(() => paymentData?.register?.some((item) => item.reconciled), [paymentData]);

  useEffect(() => {
    if (editMode) {
      loadPaymentData();
    }
  }, []);

  const initFormValues = (): VendorBillPaymentDto => {
    if (editMode && paymentData) {
      return paymentData;
    }
    return new VendorBillPaymentDto();
  };

  const showError = (errorMessage: string) => {
    setLoadingDialogState(DialogState.Show);
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(errorMessage);
  };

  const loadPaymentData = async () => {
    if (editMode && transactionId) {
      setLoadingDialogState(DialogState.Show);
      const response = (await vendorBillPaymentsApi.get(transactionId).catch((error) => {
        showError(error);
      })) as ApiResult<VendorBillPaymentDto>;
      if (response && response.status && response.data) {
        setCurrentSelectedVendorId(response.data.vendor);
        if (response.data.payFromAccount) {
          const accountDictionary = await accountsApi.getDictionary();
          const accountName = accountDictionary[response.data.payFromAccount].name;
          setPaymentAccount(accountName);
          // now that we have the bank account and the vendor bill payment id
          // let's get the bill outstanding transactions

          const outstandingTransactionsResponse = (await vendorBillPaymentsOutstandingTransactionsApi
            .getOutstandingTransactionsForVendorBillPayment({
              vendorBillPayment: transactionId,
              bankAccount: response.data.payFromAccount,
              propertyBankBalanceAsOfDate: moment().format(DateFormats.ISO_DATE_SERVER_FORMAT)
            })
            .catch((e) => {
              showError(e);
            })) as ApiResult<VendorOutstandingTransactionsResponse>;

          if (outstandingTransactionsResponse && outstandingTransactionsResponse.status) {
            if (outstandingTransactionsResponse?.data?.outstandingTransactionsByResource?.length) {
              const memo = outstandingTransactionsResponse.data.outstandingTransactionsByResource
                .flatMap((x) => x.outstandingBills)
                .find((x) => Boolean(x?.linkedTransactionLineMemo))?.linkedTransactionLineMemo;

              memo ? setPaymentData({ ...response.data, memo }) : setPaymentData(response.data);

              if (!response.data.isVoidedCheck) {
                const transactionsByAddress = _.groupBy(
                  outstandingTransactionsResponse.data.outstandingTransactionsByResource,
                  ({ linkedToProperty }) => linkedToProperty
                );
                setOutstandingTransactionsData(transactionsByAddress);
              }

              setLoadingDialogSuccessText(loadingTransactionDataSuccessText);
              setLoadingDialogState(DialogState.Success);
              setTimeout(() => {
                setLoadingDialogState(DialogState.Hidden);
                setShouldRenderForm(true);
              }, 500);
            } else {
              setPaymentData(response.data);
              showError(t(AppStrings.Bills.PayBills.Screen.NoOutstandingTransactionsFound));
            }
          } else {
            setPaymentData(response.data);
            showError(outstandingTransactionsResponse.message);
          }
        } else {
          setPaymentData(response.data);
        }
      } else {
        showError(response.message);
      }
    }
  };

  const renderLines = () => {
    if (outstandingTransactionsData) {
      const linesElements: any = [];
      let itemIndex = 0;
      let totalPayments = 0;
      let stickyHeaderIndex = 0;

      _.forEach(
        outstandingTransactionsData,
        (outstandingTransactionsArray: VendorOutstandingTransactionsByResource[], key: string) => {
          const allTransactions: VendorOutstandingTransaction[] = [];
          let totalBills = 0;
          let totalCredits = 0;

          outstandingTransactionsArray.forEach(
            (currentOutstandingTransactions: VendorOutstandingTransactionsByResource) => {
              const outstandingBills = (currentOutstandingTransactions.outstandingBills || []).filter(
                ({ amount }) => Boolean(amount) || (paymentData?.isVoidedCheck && amount === 0)
              );
              outstandingBills.forEach((outstandingBillItem) => {
                totalBills += outstandingBillItem.amount || 0;
                allTransactions.push({
                  type: OutstandingAllocationType.Bill,
                  transactionDto: outstandingBillItem
                });
              });

              const outstandingCredits = (currentOutstandingTransactions.outstandingCredits || []).filter(
                ({ amount }) => Boolean(amount)
              );

              outstandingCredits.forEach((outstandingCreditsItem) => {
                totalCredits += outstandingCreditsItem.amount || 0;
                allTransactions.push({
                  type: OutstandingAllocationType.Credit,
                  transactionDto: outstandingCreditsItem
                });
              });

              itemIndex++;
            }
          );

          if (!allTransactions.length) {
            return;
          }

          stickyHeaderIndex += 1;
          linesElements.push(
            <View key={"PTI" + itemIndex}>
              <AsyncSectionTitle
                type={"simple"}
                fetchId={key}
                apiMethod={propertiesApi}
                marginTop={20}
                uniqueKey={"PTI" + itemIndex}
              />
            </View>
          );
          linesElements.push(
            <Fragment key={"SHI" + stickyHeaderIndex}>
              <StickyHeader
                id={"outstandingBills" + stickyHeaderIndex}
                shouldShow
                uniqueKey={"outstandingBills" + stickyHeaderIndex}
              />
            </Fragment>
          );

          const outstandingAllocationItems = allTransactions.map((currentTransaction) => {
            itemIndex++;
            const { type, transactionDto } = currentTransaction;

            return (
              <Fragment key={"OSB" + itemIndex}>
                <OutstandingAllocationItem
                  type={type}
                  accountId={transactionDto.account || ""}
                  transactionDate={transactionDto.linkedTransactionDate || ""}
                  headerId={"outstandingBills" + stickyHeaderIndex}
                  balance={transactionDto.balance}
                  amount={transactionDto.amount}
                  reference={transactionDto.linkedTransactionReference}
                  unit={transactionDto.linkedToUnit}
                />
              </Fragment>
            );
          });

          linesElements.push(outstandingAllocationItems);
          linesElements.push(
            <Fragment key={"OSBS" + itemIndex}>
              <OutstandingBillsAllocationLinesSummary totalBills={totalBills} totalCredits={totalCredits} />
            </Fragment>
          );
          itemIndex++;
          totalPayments += totalBills - totalCredits;
        }
      );

      return (
        <View alignItems={"center"}>
          {linesElements}
          <SeparationLine width={"98%"} height={1} marginTop={20} />
          <OutstandingBillsAllocationDialogSummary totalPayments={totalPayments} />
        </View>
      );
    }
  };

  const renderFormTopSection = () => (
    <>
      <ReconciledNotificationView register={paymentData?.register} />
      <View flexDirection={"row"}>
        <Grid item xs={9} md={9}>
          <PayeeSelection payeeId={currentSelectedVendorId} payeeType={"vendor"} />
        </Grid>
      </View>
    </>
  );

  const renderCheckInfo = () => {
    if (paymentData && paymentData.paymentMethod === PaymentMethod.CHECK) {
      if (paymentData.checkInfo && Boolean(paymentData.checkInfo.printLater)) {
        return (
          <Grid item xs={12} md={2}>
            <View>
              <ViewOnlyInput
                label={t(AppStrings.DrawerItems.PrintChecks)}
                value={t(AppStrings.Leases.LeaseTransaction.Refund.AddToPrintQueue)}
                fullWidth
                marginLeft={20}
              />
            </View>
          </Grid>
        );
      }

      if (paymentData.checkInfo && paymentData.checkInfo.checkNumber) {
        return (
          <Grid item xs={12} md={2}>
            <View>
              <ViewOnlyInput
                label={AppStrings.Leases.LeaseTransaction.Payment.CheckNumber}
                value={String(paymentData.checkInfo.checkNumber)}
                marginLeft={20}
                fullWidth
              />
            </View>
          </Grid>
        );
      }
    }
  };

  const renderInfoRow = () => (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <View flexDirection={"row"} alignItems={"center"} justifyContent={"flex-start"} marginTop={20} gap={20}>
        <Grid item xs={12} md={3}>
          <ViewOnlyInput
            label={AppStrings.Bills.PayBills.Screen.PaymentAccount}
            value={paymentAccount || ""}
            marginLeft={20}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <View>
            <FastField
              component={Select}
              name={`paymentMethod`}
              label={AppStrings.Leases.LeaseTransaction.Payment.PaymentMethod}
              required
              uniqueKey={"paymentMethod"}
              selectionEnum={PaymentMethod}
              translationKey={"paymentMethod"}
              fullWidth
            />
          </View>
        </Grid>

        {renderCheckInfo()}

        <Grid item xs={12} md={2}>
          <FastField
            component={FormikDatePicker}
            uniqueKey={"date"}
            label={AppStrings.Vendors.VendorDetails.PaymentDate}
            name={"date"}
            noMargin
            required
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <FastField marginLeft={20} component={FormikReferenceLabel} name={"reference"} backgroundColor={"dark"} />
        </Grid>
      </View>
    </MuiPickersUtilsProvider>
  );

  const handleFilesChanged = async (files) => {
    setFiles(files);
  };

  const renderForm = () => {
    const formInitialValues = initFormValues();
    return (
      <Formik initialValues={formInitialValues} onSubmit={() => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;
          return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <View
                paddingLeft={dialogHorizontalPadding}
                paddingRight={dialogHorizontalPadding}
                flexDirection={"column"}
                flex={1}
                width={"100%"}
              >
                <RestrictedPermissionAccess clearance={permission} showNoAccess>
                  <View marginTop={20} flexDirection={"row"}>
                    {renderFormTopSection()}
                  </View>

                  {renderInfoRow()}
                  <SeparationLine width={"100%"} height={1} marginTop={20} />
                  {renderLines()}
                  <View marginTop={20} justifyContent={"center"}>
                    <FastField component={Notes} name={"memo"} height={30} />
                  </View>
                  <View justifyContent={"flex-end"} width={"100%"} flex={1} marginTop={20} marginBottom={20}>
                    <FormAttachments
                      editMode={editMode}
                      resourceId={paymentData?.id}
                      resourceType={LinkedResourceType.VendorBillPayment}
                      onFileReceived={handleFilesChanged}
                      files={files}
                    />
                  </View>
                </RestrictedPermissionAccess>
                {editMode && <ActivityLabel item={paymentData} />}
              </View>
            </MuiPickersUtilsProvider>
          );
        }}
      </Formik>
    );
  };

  const renderView = ({ index }: any) => {
    const onRetryLoadInfoButtonPress = () => {
      loadPaymentData();
    };
    const didPressDismissButton = () => {
      setLoadingDialogState(DialogState.Hidden);
      setViewIndex(DialogViews.DialogForm);
    };

    if (index === DialogViews.DialogForm) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={onRetryLoadInfoButtonPress}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }
      if (shouldrenderForm) {
        return renderForm();
      }
      return <div />;
    }

    if (index === DialogViews.DeleteConfirmation && viewIndex === DialogViews.DeleteConfirmation) {
      return (
        <DeleteConfirmation
          apiMethod={vendorBillPaymentsApi}
          didPressDismissButton={handleCancelButtonClick}
          didFinishOperation={onClose}
          transactionId={transactionId}
          attachments={files}
        />
      );
    }

    if (index === DialogViews.VoidConfirmation) {
      return <VoidConfirmation onCancel={handleCancelButtonClick} onSubmit={handleVoidSubmitButtonClick} />;
    }

    return <div />;
  };

  const _onBackdropClick = () => {
    if (viewIndex === DialogViews.DeleteConfirmation) {
      setViewIndex(DialogViews.DialogForm);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const handleCancelButtonClick = () => {
    setViewIndex(DialogViews.DialogForm);
  };

  const didPressDeleteButton = () => {
    setViewIndex(DialogViews.DeleteConfirmation);
  };

  const handleVoidButtonClick = () => {
    setViewIndex(DialogViews.VoidConfirmation);
  };

  const handleVoidSubmitButtonClick = async (): Promise<void> => {
    if (transactionId) {
      setLoadingDialogState(DialogState.Show);
      setViewIndex(DialogViews.DialogForm);

      const voidCheckResult = await voidCheck(transactionId);

      if (voidCheckResult.status) {
        analyticsService.track(SegmentEventTypes.CHECK_VOIDED, {
          transactionType: "vendorBillPayment",
          payeeType: "vendor",
          isUnitEmpty: true,
          totalAmount: _.sumBy(paymentData?.linkedBills, (linkedBill) => linkedBill.amount || 0),
          numOfLineItems: _.size(paymentData?.linkedBills)
        });

        onClose();
      } else {
        showError(voidCheckResult.message);
      }
    }
  };

  const isValidForm = async (formikRef: FormikProps<DepositDto>) => {
    formikRef.setFieldTouched("date");

    const errors = (await formikRef.validateForm()) as any;
    return _.isEmpty(errors);
  };

  const updateVendorBillPayment = async () => {
    if (formikGlobalRef && transactionId) {
      setShouldRenderForm(false);
      setLoadingDialogState(DialogState.Show);
      setLoadingDialogLoadingText(t(AppStrings.Leases.LeaseTransaction.Payment.Updating));

      const results = await vendorBillPaymentsApi.update(transactionId, formikGlobalRef.values).catch((error) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });

      if (results && results.status && results.data && results.data.id) {
        await filesApi.uploadFiles(files, results.data.id, LinkedResourceType.VendorBillPayment).catch((error) => {
          setLoadingDialogState(DialogState.Error);
          setLoadingDialogErrorText(error);
        });

        setLoadingDialogSuccessText(t(AppStrings.Common.OperationCompleted));
        setLoadingDialogState(DialogState.Success);
        setTimeout(() => {
          onClose();
        }, 500);
      } else {
        setLoadingDialogErrorText(results ? results.message : "");
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const didPressSaveButton = async () => {
    const formikRef = getFormikRef();
    if (formikRef !== null) {
      const isValid = await isValidForm(formikRef);
      if (isValid) {
        if (editMode) {
          await updateVendorBillPayment();
        }
      }
    }
  };

  const renderActionPanelButtons = () => {
    if (viewIndex !== DialogViews.DialogForm) {
      return null;
    }

    const customButtons: DLButtonProps[] = [];

    if (
      editMode &&
      paymentData?.paymentMethod === PaymentMethod.CHECK &&
      !paymentData?.isVoidedCheck &&
      !isReconciled
    ) {
      customButtons.push({
        actionText: AppStrings.Bills.Checks.Void,
        onClick: handleVoidButtonClick
      });
    }

    return (
      <FormActionButtons
        propsActionPanel={{
          editMode,
          onDeleteButtonPress: didPressDeleteButton,
          customButtons
        }}
        propsSubButton={{ onClick: _onBackdropClick }}
        propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
      />
    );
  };

  const frameType = useMemo(() => {
    if (viewIndex === DialogViews.DialogForm) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }
    if (viewIndex === DialogViews.DeleteConfirmation || viewIndex === DialogViews.VoidConfirmation) {
      return "contentOnly";
    }
    return "sectionTitleFrame";
  }, [viewIndex, loadingDialogState]);

  const currentTitle = useMemo(() => {
    if (viewIndex === DialogViews.DialogForm) {
      return dialogTitle;
    }

    if (viewIndex === DialogViews.DeleteConfirmation) {
      return AppStrings.Leases.LeaseCharge.SelectLease;
    }
    return "";
  }, [viewIndex]);

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={currentTitle}
      width={getDialogFrameDimension("width", 1350)}
      height={getDialogFrameDimension("height", window.innerHeight)}
      renderView={renderView}
      numViews={Object.values(DialogViews).length}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={frameType}
    />
  );
};

export default VendorBillPayment;
