export default {
  announcement: "Announcement",
  announcements: "Announcements",
  newAnnouncement: "New Announcement",
  propertiesUnits: "Properties / Units",
  deliveryMethod: "Delivery Method",
  deliveryMethods: "Delivery Methods",
  announcementContent: "Announcement Content",
  reviewAndConfirm: "Review & Confirm",
  whichProperties: "Which properties would you like to send this announcement to?",
  whichUnits: "Which units would you like to send this announcement to?",
  whichTenants: "Which tenants would you like to send this announcement to?",
  selectUnits: "Select Units",
  selectTenants: "Select Tenants",
  selectPortfolios: "Select Portfolios",
  allUnits: "All Units",
  someUnits: "Some Units",
  allTenants: "All Tenants",
  someTenants: "Some Tenants",
  sendByEmail: "Send now by email",
  sendByTextMessage: "Send now by text message",
  sendByVoiceMessage: "Send now by voice message",
  sendByTenantPortal: "Show this announcement in the tenant portal",
  sendByEmailDesc:
    "This announcement will be sent right away to the contacts based on your selection, as long as they have their email on record. Delivery and open rates will be monitored for your convenience.",
  sendByTextMessageDesc:
    "This announcement will be sent immediately to all tenants with a mobile phone number on file and will use 1 communication credit per text message sent.\n\nLimit 140 characters.",
  sendByVoiceMessageDesc:
    "This announcement will be sent immediately as a voice message using text to speech to all tenants with a phone number on file and will use 1 communication credit per voice message sent.\n\nLimit 300 characters.",
  sendByTenantPortalDesc: "This announcement will be posted in the announcement section of the tenant portal",
  howWouldYouLikeToDeliver: "How would you like to deliver this announcement?",
  pinToPortalHomeQuestion: "Also post this announcement on the home screen of the tenant portal",
  portalShouldExpireQuestion: "Remove this announcement from tenants portal feed on this date",
  xCharacters: "{{currentCharCount}}/{{maxCharCount}} characters",
  characterCapMessage:
    "If sending a text message, only the first {{smsMaxChars}} characters will be sent. For voice messages, only the first {{voiceMaxChars}} characters will be read.",
  reviewAnnouncement: "Review Announcement",
  thisAnnouncementWillBeDeliveredVia: "This announcement will be delivered via:",
  communicationCredit: "Announcements Credit",
  communicationCenterCredit: "Communication Center Credit",
  xCredits: "{{x}} Credits",
  email: "Email",
  textMessage: "Text Message",
  phoneCall: "Phone Call",
  tenantPortal: "Tenant Portal",
  xEmailsWillBeSent: "{{x}} emails will be sent",
  xTextMessagesWillBeSent: "{{x}} text messages will be sent",
  xPhoneCallsWillBeMade: "{{x}} phone calls will be made",
  xMessagesWillBePostedOnTenantPortals: "{{x}} messages will be posted on tenant portals",
  creditsUsedOutOfCreditsLeftMessage:
    "{{creditsToBeUsed}} communication credits are needed to send this announcement but you have only {{creditsLeft}} credits remaining this month.",
  creditsAdditionalChargeMessage:
    "You will be charged ${{chargeAmount}} for {{chargeQuantity}} more credits to send this announcement.",
  noDeliveryMethodSelectedError: "Please select at least 1 delivery method to continue",
  nothingToSendError: "Nothing to send!",
  bodyTooLongError: "Announcement content is too long.",
  communicationFromToSummary: "{{fromName}}{{fromType}} sent {{communicationType}} to {{toName}}{{toType}}",
  communicationsLog: "Communications Log",
  learnMoreCommunicationsLog: "Learn more about Communications Log",
  openedXTimesSinceDateTime: "Opened {{x}} times since {{date}} at {{time}}",
  openedOneTimeSinceDateTime: "Opened 1 time on {{date}} at {{time}}",
  clickedXTimesSinceDateTime: "Clicked {{x}} times since {{date}} at {{time}}",
  clickedOneTimeSinceDateTime: "Clicked 1 time on {{date}} at {{time}}",
  fromColon: "From:",
  toColon: "To:",
  viaEmail: "Via Email",
  viaTextMessage: "Via Text Message",
  viaPhoneCall: "Via Phone Call",
  subject: "Subject",
  sentAt: "Sent At",
  sentBy: "Sent By",
  status: "Status",
  draft: "Draft",
  failed: "Failed",
  bounced: "Bounced",
  complaint: "Reported Spam",
  sent: "Sent",
  delivered: "Delivered",
  opened: "Opened",
  clicked: "Clicked",
  type: "Type",
  deleteCommunicationConfirmationText: "Are you sure you want to delete the communication?",
  deleteCommunicationLoadingText: "Deleting communication...",
  deleteCommunicationSuccessText: "This communication was deleted successfully",
  deleteAnnouncementConfirmationText: "Are you sure you want to delete the announcement?",
  deleteAnnouncementLoadingText: "Deleting announcement...",
  deleteAnnouncementSuccessText: "This announcement was deleted successfully",
  searchCommunications: "Search Communications",
  searchAnnouncements: "Search Announcements",
  noAnnouncementsFound: "No Announcements Found",
  noCommunicationsFound: "No Communications Found",
  communications: "Communications",
  allProperties: "All Properties",
  oneProperty: "1 Property",
  oneUnit: "1 Unit",
  oneTenant: "1 Tenant",
  xProperties: "{{x}} Properties",
  xUnits: "{{x}} Units",
  xTenants: "{{x}} Tenants",
  NA: "N/A",
  active: "Active",
  inactive: "Inactive",
  expires: "Expires:",
  expired: "Expired:",
  noExpiry: "No Expiry",
  overview: "Overview",
  emailsSent: "Emails Sent",
  textMessagesSent: "Text Messages Sent",
  voiceMessagesSent: "Voice Messages Sent",
  portalStatus: "Portal Status",
  announcementPreview: "Announcement Preview",
  attachedFiles: "Attached Files",
  total: "Total",
  updateAnnouncement: "Update Announcement",
  pleaseNote: "Please Note:",
  announcementSentNotification1: "This announcement has already been sent.",
  announcementSentNotification2: "Changes to this announcement can only be reflected in the tenant portal",
  announcementOptions: "Announcement Options",
  yourAnnouncementHasBeenSent: "Your Announcement Has Been Sent",
  failedWithReason: "Failed Reason: {{failedReason}}",
  readMore: "Read More",
  noAnnouncements: "No Announcements",
  nothingHereYetCheckBackSoon: "Nothing here yet! Check back soon.",
  sendMessage: "Send Message",
  helpPanel: {
    description: "Message your tenants by text, email, voice message, or tenant portal announcements.",
    sendAnnouncement: "How to send Announcements",
    singleTenantMessage: "How do I message a single tenant?"
  },
  communicationsScreen: {
    helpPanel: {
      description: "Keep track of every message sent through your portal.",
      learnMoreCommunicationsLog: "Learn more about the Communications Log",
      trackPortalInvite: "How do I track tenant portal invites?"
    }
  },
  notificationsLog: "Notifications Log"
};
