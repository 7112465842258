"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProspectStatus = void 0;
var ProspectStatus;
(function (ProspectStatus) {
    ProspectStatus["NEW"] = "NEW";
    ProspectStatus["CONTACT_ATTEMPTED"] = "CONTACT_ATTEMPTED";
    ProspectStatus["CONTACT_MADE"] = "CONTACT_MADE";
    ProspectStatus["SHOWING_SCHEDULED"] = "SHOWING_SCHEDULED";
    ProspectStatus["SHOWING_COMPLETED"] = "SHOWING_COMPLETED";
    ProspectStatus["APPLICATION_SENT"] = "APPLICATION_SENT";
    ProspectStatus["APPLICATION_SUBMITTED"] = "APPLICATION_SUBMITTED";
    ProspectStatus["APPLICATION_IN_PROGRESS"] = "APPLICATION_IN_PROGRESS";
    ProspectStatus["LEASE_OFFER_MADE"] = "LEASE_OFFER_MADE";
    ProspectStatus["LEASE_OFFER_ACCEPTED"] = "LEASE_OFFER_ACCEPTED";
    ProspectStatus["LEASE_SENT_FOR_SIGNATURE"] = "LEASE_SENT";
    ProspectStatus["CLOSED_MOVED_IN"] = "CLOSED_MOVED_IN";
    ProspectStatus["CLOSED_LOST"] = "CLOSED_LOST";
})(ProspectStatus || (exports.ProspectStatus = ProspectStatus = {}));
