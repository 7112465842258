import type { ReactElement } from "react";
import { useEffect, useRef } from "react";
import type { DraggableProvided, DraggableStateSnapshot, DraggingStyle } from "react-beautiful-dnd";
import { createPortal } from "react-dom";

// Hook for drag and drop component
// sets the draggable items to render within a portal
// it helps to render draggable items while being dragged more correctly
// moreover fixes the use cases where draggable list used inside overflow: scroll and while used inside dialogs.
export const useDraggableInPortal = () => {
  const element = useRef<HTMLDivElement>(document.createElement("div")).current;

  useEffect(() => {
    if (element) {
      element.style.pointerEvents = "none";
      element.style.position = "absolute";
      element.style.height = "100%";
      element.style.width = "100%";
      element.style.top = "0";
      element.style.zIndex = "9999";

      document.body.appendChild(element);

      return () => {
        document.body.removeChild(element);
      };
    }
  }, [element]);

  return (render: (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => ReactElement) =>
    (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
      const result = render(provided, snapshot);
      const style = provided.draggableProps.style as DraggingStyle;
      if (style.position === "fixed") {
        return createPortal(result, element);
      }
      return result;
    };
};
