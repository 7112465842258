"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalApplicationJobType = void 0;
var RentalApplicationJobType;
(function (RentalApplicationJobType) {
    RentalApplicationJobType["EMPLOYED"] = "EMPLOYED";
    RentalApplicationJobType["SELF_EMPLOYED"] = "SELF_EMPLOYED";
    RentalApplicationJobType["UNEMPLOYED"] = "UNEMPLOYED";
    RentalApplicationJobType["STUDENT"] = "STUDENT";
})(RentalApplicationJobType || (exports.RentalApplicationJobType = RentalApplicationJobType = {}));
