import { getIn, useFormikContext } from "formik";
import type { ManagementFeeRuleDto } from "@doorloop/dto";
import { DataCy } from "@doorloop/dto";
import { isFixedBasedFee, isPercentageBasedFee } from "@doorloop/dto";
import { useTypedTranslation } from "locale";
import { TextField } from "DLUI/form";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import { useManagementFeesStyles } from "screens/settings/managementFees/useStyles";
import clsx from "clsx";
import { RuleTypeSelector } from "screens/settings/managementFees/formElements/ruleTypeSelector";
import { useGeneralStyles } from "styles/generalStyles";
import { useCallback } from "react";
import { EXPENSE_QUERY_PARAMS, REVENUE_QUERY_PARAMS } from "screens/settings/managementFees/formElements/utils";

interface ManagementFeeItemFormProps {
  rulePath: string;
}

export const ManagementFeeItemForm = ({ rulePath }: ManagementFeeItemFormProps) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const { ruleType }: ManagementFeeRuleDto = getIn(values, rulePath);
  const classes = useManagementFeesStyles();
  const generalClasses = useGeneralStyles();

  const { t } = useTypedTranslation();
  const isFixedBaseFee = isFixedBasedFee(ruleType);

  const resetFee = useCallback(() => {
    setFieldValue(`${rulePath}.fee`, undefined);
    setFieldTouched(`${rulePath}.fee`, false);
  }, []);

  return (
    <div className={clsx(classes.columnContainer, generalClasses.fullWidth)}>
      <div className={clsx(generalClasses.flexRow, generalClasses.smallGap)}>
        <div className={generalClasses.basicFlex}>
          <RuleTypeSelector rulePath={rulePath} handleChange={resetFee} />
        </div>
        <div className={classes.feeInputWidth}>
          <FastFieldSafe
            component={TextField}
            label={t("settings.generalSettings.managementFees.fee")}
            name={`${rulePath}.fee`}
            required
            dataCy={DataCy.globalSettings.managementFees.rules.rule.feeInput}
            formatType={isFixedBaseFee ? "currency" : "percent"}
          />
        </div>
      </div>
      {ruleType && (
        <div className={clsx(generalClasses.flexRow, generalClasses.smallGap)}>
          {isPercentageBasedFee(ruleType) && (
            <div className={generalClasses.basicFlex}>
              <BankAccountFormikAutoCompleteField
                addCreateOption
                uniqueIndex={"TS"}
                name={`${rulePath}.baseAccount`}
                queryParams={REVENUE_QUERY_PARAMS}
                dataCy={DataCy.globalSettings.managementFees.rules.rule.baseAccountSelect}
                label={t("settings.generalSettings.managementFees.baseCategory")}
                defaultValue={getIn(values, `${rulePath}.baseAccount`)}
              />
            </div>
          )}
          <div className={generalClasses.basicFlex}>
            <BankAccountFormikAutoCompleteField
              addCreateOption
              uniqueIndex={"TS"}
              name={`${rulePath}.expenseAccount`}
              queryParams={EXPENSE_QUERY_PARAMS}
              dataCy={DataCy.globalSettings.managementFees.rules.rule.expenseAccountSelect}
              label={t("settings.generalSettings.managementFees.expenseCategory")}
              defaultValue={getIn(values, `${rulePath}.expenseAccount`)}
            />
          </div>
        </div>
      )}
    </div>
  );
};
