import { useMutation, useQuery } from "@tanstack/react-query";
import { createMutationStore } from "../createMutationStore";
import { createQueryStore } from "../createQueryStore";
import { rentalApplicationsApi } from "api/rentalApplicationsApi";
import type { RentalApplicationPostChargeDto } from "@doorloop/dto/dist/settings/shared/rentalApplication/rentalApplicationPostChargeDto.dto";
import type { ApplicantPostChargePayment } from "@doorloop/dto/dist/rentalApplications/getProspectPostCharge.dto";
import type { RentalApplicationPostChargeBaseDto } from "@doorloop/dto/dist/settings/shared/rentalApplication/rentalApplicationPostChargeDto.dto";
import { throwIfNotSuccessful } from "api/entityApiStore/entityApiStore.utils";
import type { ApiResult } from "@doorloop/dto";
import type { UseQueryOptions } from "@tanstack/react-query";
import type { RentalApplicationTransunionInfoDto } from "@doorloop/dto";
import type { ValidationError } from "class-validator";
import type { RentalApplicationApplicantGetAllReportsShareScreeningDataDto } from "@doorloop/dto";

export const rentalApplicationStore = {
  queries: {
    ...createQueryStore("rental-applications", rentalApplicationsApi),
    useGetRentalApplicationPostChargeData: (id?: string) =>
      useQuery({
        enabled: Boolean(id),
        queryKey: [`rental-applications-get-one-post-charge-data`, id],
        queryFn: async ({ signal }) => {
          const result = await rentalApplicationsApi.getRentalApplicationPostChargeData(id);
          if (!result || signal?.aborted) {
            return null;
          }
          if (!result.status) {
            throw new Error(result.message);
          }
          return result?.data;
        },
        onError: (error: Error) => error,
        refetchOnWindowFocus: false
      }),
    useGetTransunionInfo: (
      id?: string,
      options?: UseQueryOptions<unknown, Error, ApiResult<RentalApplicationTransunionInfoDto>>
    ) =>
      useQuery({
        queryKey: [`rental-applications-get-transunion-info-data`, id],
        queryFn: async ({ signal }) => {
          const result = await rentalApplicationsApi.getRentalApplicationTransunionInfo(id);
          if (!result || signal?.aborted) {
            return null;
          }
          if (!result.status) {
            throw new Error(result.message);
          }
          return result?.data;
        },
        refetchOnWindowFocus: false,
        ...options
      }),
    useGetAllReportsShareScreeningDataForApplicant: (
      token: string,
      options?: UseQueryOptions<
        unknown,
        { message: string; validationErrors?: ValidationError[] },
        RentalApplicationApplicantGetAllReportsShareScreeningDataDto
      >
    ) =>
      useQuery({
        queryKey: [`rental-applications-get-all-reports-for-applicant`, token],
        queryFn: async ({ signal }) => {
          const result = await rentalApplicationsApi.getAllReportsShareScreeningDataForApplicant(token);
          if (!result || signal?.aborted) {
            return null;
          }
          if (!result.status) {
            const { message, validationErrors } = result;
            throw { message, validationErrors };
          }
          return result?.data;
        },
        refetchOnWindowFocus: false,
        ...options
      })
  },
  mutations: {
    ...createMutationStore("rental-applications", rentalApplicationsApi),
    useApplicantPostCharge: () =>
      useMutation({
        mutationKey: ["rental-applications-applicant-post-charge"],
        mutationFn: async (data: RentalApplicationPostChargeDto) => {
          const apiResult = await rentalApplicationsApi.putApplicantPostCharge(data);
          throwIfNotSuccessful(apiResult);
          return apiResult;
        }
      }),
    useApplicantPostChargeReminder: () =>
      useMutation({
        mutationKey: ["rental-applications-applicant-post-charge-reminder"],
        mutationFn: async (dto: RentalApplicationPostChargeBaseDto) => {
          const apiResult = await rentalApplicationsApi.putApplicantPostChargeReminder(dto);
          throwIfNotSuccessful(apiResult);
          return apiResult;
        },
        onError: (error: Error) => error
      }),
    useApplicantPostChargePayment: () =>
      useMutation({
        mutationKey: ["rental-applications-applicant-post-charge-payment"],
        mutationFn: async (data: ApplicantPostChargePayment) => {
          const apiResult = await rentalApplicationsApi.postApplicantPostChargePayment(data);
          throwIfNotSuccessful(apiResult);
          return apiResult;
        },
        onError: (error: Error) => error
      })
  }
};
