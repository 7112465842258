import { BankingEmptyState } from "screens/transactions/components/bankingEmptyState";
import { BackgroundImageView, View } from "DLUI/view";
import Text from "DLUI/text";
import ColorsEnum from "utils/colorsEnum";
import DLButton, { DLButtonColorsEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import { NavigationManager } from "utils/navigation";
import { ObjectPermission } from "@doorloop/dto";
import React from "react";
import { useTypedTranslation } from "locale";
import { useLocation } from "react-router-dom";

const OUTGOING_PAYMENTS_EMPTY_STATE_IMG = "/images/outgoing-payments-empty-state.svg";
const POWERED_BY_CHECKBOOK_IO_IMG = "/images/powered-by-checkbook-io.svg";

export const OutgoingPaymentsEmptyState = () => {
  const { t } = useTypedTranslation();
  const location = useLocation();

  return (
    <BankingEmptyState flexDirection={"column"}>
      <BackgroundImageView
        height={200}
        publicFileName={`url(${OUTGOING_PAYMENTS_EMPTY_STATE_IMG})`}
        backgroundSize={"contain"}
        width={"100%"}
      />
      <View gap={10} noWrap alignItems={"center"}>
        <Text align={"center"} fontSize={18} bold value={t("common.outgoingPayments.emptyState.titleLeftSide")} />
        <Text
          align={"center"}
          fontSize={14}
          colorEnum={ColorsEnum.SecondaryGrey}
          value={t("common.outgoingPayments.emptyState.subtitleLeftSide")}
        />
        <DLButton
          onClick={() => NavigationManager.editOutgoingPaymentsSettings(location.pathname)}
          style={{ marginTop: 10 }}
          textProps={{ fontSize: 14 }}
          variant={DLButtonVariantsEnum.CONTAINED}
          color={DLButtonColorsEnum.PRIMARY}
          actionText={t("common.outgoingPayments.emptyState.action")}
          clearance={{
            permission: ObjectPermission.outgoingPayments,
            field: "create"
          }}
        />
      </View>
      <div
        style={{
          backgroundImage: `url(${POWERED_BY_CHECKBOOK_IO_IMG})`,
          height: 20,
          width: "auto",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat"
        }}
      />
    </BankingEmptyState>
  );
};
