import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    listItemsContainer: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      justifyContent: "center",
      width: "100%"
    },
    listContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%"
    },
    noResultsFoundContainer: {
      flex: 1,
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column"
    },

    noResultsFoundText: {
      margin: 20
    },
    skeletonListContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start"
    },
    ListEndComponentContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "20px"
    },
    networkErrorContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column"
    },
    networkErrorTextWrapper: {
      maxWidth: "600px",
      margin: "0px 20px",
      justifyContent: "center",
      alignItems: "center"
    },
    listHeaderContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center"
    }
  })
);
