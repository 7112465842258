"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBudgetEmptyReport = void 0;
const class_validator_1 = require("class-validator");
const common_1 = require("../../common");
const profitAndLoss_1 = require("../../reports/profitAndLoss");
const budgetPeriodType_enum_1 = require("../enums/budgetPeriodType.enum");
const budgetReportType_1 = require("../enums/budgetReportType");
class GetBudgetEmptyReport {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.GetBudgetEmptyReport = GetBudgetEmptyReport;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, common_1.IsDateStringWithoutTime)()
    /** must be start of month */
    ,
    __metadata("design:type", String)
], GetBudgetEmptyReport.prototype, "fiscalYearStartDate", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, common_1.IsDateStringWithoutTime)()
    /** will be auto calc by the client as moment(this.fiscalYearStartDate).add(1, "y").subtract(1, "d").format(DateFormats.ISO_DATE_SERVER_FORMAT) */
    ,
    __metadata("design:type", String)
], GetBudgetEmptyReport.prototype, "fiscalYearEndDate", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(budgetPeriodType_enum_1.BudgetPeriodType),
    __metadata("design:type", String)
], GetBudgetEmptyReport.prototype, "periodType", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(budgetReportType_1.BudgetReportType),
    __metadata("design:type", String)
], GetBudgetEmptyReport.prototype, "reportType", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(profitAndLoss_1.ShowNonZeroOrActiveOnly),
    __metadata("design:type", String)
], GetBudgetEmptyReport.prototype, "showRows", void 0);
