import React, { useMemo } from "react";
import { View } from "DLUI/view";
import { DeleteIcon, NewNoteIcon, PencilSimpleIcon } from "assets/icons";
import { Icon } from "DLUI/icon";
import { Tags } from "DLUI/tags";
import Text from "DLUI/text";
import type { PopoverItem } from "DLUI/popover";
import AppStrings from "locale/keys";
import { useLocation } from "react-router-dom";
import { usersApi } from "api/usersApi";
import { useTranslation } from "react-i18next";
import type { BaseDto, GetAllBaseQueryRequest, NoteDtoDisplay } from "@doorloop/dto";
import { ObjectPermission } from "@doorloop/dto";
import { Grid, ListItemContainer } from "DLUI/listItems";
import { ProfileImage } from "DLUI/profileImage";
import moment from "moment/moment";
import { DateFormats } from "@doorloop/dto";
import { TextTooltip } from "DLUI/popover/textTooltip";
import type { RestApiBaseWithDictionary } from "api/restApiBaseWithDictionary";
import { vendorsApi } from "api/vendorsApi";
import { tenantsApi } from "api/tenantsApi";
import { ownersApi } from "api/ownersApi";
import { unitsApi } from "api/unitsApi";
import { propertiesApi } from "api/propertiesApi";
import { leasesApi } from "api/leasesApi";
import { notesApi } from "api/notesApi";
import { useConfirmationDialog } from "@/hooks/useConfirmationDialog";

interface ComponentProps {
  itemData: NoteDtoDisplay;
  onPressEditNote: (noteToEdit: NoteDtoDisplay) => void;
  hideRelatedToColumn?: boolean;
  disableListItemOptions?: boolean;
  stickyHeaderId?: string;
}

type resourceListObjProps = RestApiBaseWithDictionary<BaseDto, GetAllBaseQueryRequest>;

export enum LinkedNotesResourceType {
  Note = "NOTE",
  Task = "TASK",
  Vendor = "VENDOR",
  Announcement = "ANNOUNCEMENT",
  LeaseDraft = "LEASE_DRAFT",
  Lease = "LEASE",
  Owner = "OWNER",
  Property = "PROPERTY",
  Unit = "UNIT",
  Tenant = "TENANT",
  RentalApplication = "RENTAL_APPLICATION",

  File = "FILE",
  User = "USER"
}

export const ApiByResourceType: Partial<Record<LinkedNotesResourceType, resourceListObjProps>> = {
  [LinkedNotesResourceType.Note]: notesApi,
  [LinkedNotesResourceType.Vendor]: vendorsApi,
  [LinkedNotesResourceType.Lease]: leasesApi,
  [LinkedNotesResourceType.Owner]: ownersApi,
  [LinkedNotesResourceType.Property]: propertiesApi,
  [LinkedNotesResourceType.Unit]: unitsApi,
  [LinkedNotesResourceType.Tenant]: tenantsApi,
  [LinkedNotesResourceType.User]: usersApi
};

const NoteListItem: React.FC<ComponentProps> = ({
  itemData,
  onPressEditNote,
  hideRelatedToColumn = false,
  disableListItemOptions = false,
  stickyHeaderId = "notesList"
}: ComponentProps) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { showConfirmationDialog } = useConfirmationDialog();
  const { title, body, createdBy, tags, createdAt, linkedResource } = itemData;

  const { createdByUserName, createdByUserPictureUrl, relatedTo } = useMemo(() => {
    let createdByUserName = " ";
    let createdByUserPictureUrl;
    let relatedTo = " ";

    if (createdBy) {
      const createdByObj = usersApi.getItemFromDictionary(createdBy);
      createdByUserName = createdByObj?.name ?? " ";
      createdByUserPictureUrl = createdByObj?.pictureUrl;
    }

    if (linkedResource) {
      const { resourceType, resourceId } = linkedResource;
      const relatedApiMethod = ApiByResourceType[resourceType];
      const relatedToObj = relatedApiMethod?.getItemFromDictionary(resourceId);

      if ((resourceType as string) === LinkedNotesResourceType.Unit) {
        const propertyObj = ApiByResourceType[LinkedNotesResourceType.Property]?.getItemFromDictionary(
          relatedToObj?.property
        );

        relatedTo = relatedToObj?.name
          ? `${propertyObj?.name ? `${propertyObj?.name} > ` : ""}${relatedToObj?.name}`
          : relatedTo;
      } else {
        relatedTo = relatedToObj?.name ?? t(relatedTo);
      }
    }

    return { createdByUserName, createdByUserPictureUrl, relatedTo };
  }, []);

  const didPressEditNote = () => {
    onPressEditNote(itemData);
  };

  const didPressDeleteNote = () => {
    showConfirmationDialog(location.pathname, {
      dialogTitle: AppStrings.Common.DeleteConfirmation,
      confirmButtonText: AppStrings.Common.Delete,
      dismissButtonText: AppStrings.Common.Dismiss,
      apiMethod: "notesApi",
      dialogSubTitle: AppStrings.Notes.Dialog.DeleteConfirmationText,
      loadingText: t(AppStrings.Notes.Dialog.DeleteNoteLoadingText),
      successText: t(AppStrings.Notes.Dialog.DeleteNoteSuccessText),
      confirmOperation: "delete",
      itemId: itemData.id
    });
  };

  const popoverTopSectionItems: PopoverItem[] = useMemo(
    () => [
      {
        Icon: PencilSimpleIcon,
        title: AppStrings.Common.Edit,
        onClick: didPressEditNote,
        showSeparator: true,
        clearance: { permission: ObjectPermission.notes, field: "viewOne" }
      },
      {
        Icon: DeleteIcon,
        title: AppStrings.Common.Delete,
        onClick: didPressDeleteNote,
        textColor: "error",
        iconPathColor: "error",
        showSeparator: true,
        clearance: { permission: ObjectPermission.notes, field: "delete" }
      }
    ],
    []
  );

  return (
    <ListItemContainer popoverItems={disableListItemOptions ? undefined : popoverTopSectionItems} id={stickyHeaderId}>
      <Grid
        showDivider
        xs={12}
        sm={hideRelatedToColumn ? 5 : 3}
        md={hideRelatedToColumn ? 5 : 3}
        lg={hideRelatedToColumn ? 5 : 3}
        sortColumn={"name"}
        height={70}
        style={{
          marginTop: 0
        }}
        title={AppStrings.Common.Title}
        onClick={didPressEditNote}
      >
        <View flexDirection={"row"} alignItems={"center"} noWrap width={"100%"} overflow={"hidden"}>
          <View
            minWidth={50}
            minHeight={50}
            maxWidth={50}
            maxHeight={50}
            width={50}
            backgroundColor={"secondary-gray-light"}
            borderRadius={5}
            justifyContent={"center"}
            alignItems={"center"}
            marginRight={10}
            flexDirection={"row"}
          >
            <Icon Source={NewNoteIcon} size={24} pathColor={"black"} />
          </View>
          <View overflow={"hidden"} width={"calc(100% - 60px)"} noWrap>
            <Text bold fontSize={14} overFlow={"ellipsis"} numberOfLines={2}>
              {title}
            </Text>
            <View overflow={"hidden"} noWrap paddingTop={5}>
              <Tags tags={tags} />
            </View>
          </View>
        </View>
      </Grid>
      {!hideRelatedToColumn && (
        <Grid
          showDivider
          xs={12}
          sm={3}
          md={3}
          lg={3}
          title={AppStrings.Tasks.InternalTaskDialog.RelatedTo}
          onClick={didPressEditNote}
        >
          <Text overFlow={"ellipsis"} numberOfLines={2} fontSize={14}>
            {relatedTo}
          </Text>
        </Grid>
      )}
      <Grid
        showDivider
        xs={12}
        sm={hideRelatedToColumn ? 3 : 2}
        md={hideRelatedToColumn ? 3 : 2}
        lg={hideRelatedToColumn ? 3 : 2}
        sortColumn={"body"}
        title={AppStrings.Common.Note}
        onClick={didPressEditNote}
      >
        <TextTooltip value={body} useDark>
          <Text overFlow={"ellipsis"} numberOfLines={3} fontSize={12} lineHeight={"15px"}>
            {body || " "}
          </Text>
        </TextTooltip>
      </Grid>
      <Grid
        xs={12}
        showDivider
        sm={hideRelatedToColumn ? 2 : 2}
        md={hideRelatedToColumn ? 2 : 2}
        lg={hideRelatedToColumn ? 2 : 2}
        title={AppStrings.Common.CreatedBy}
        onClick={didPressEditNote}
      >
        <View flexDirection={"row"} alignItems={"center"} noWrap overflow={"hidden"}>
          <ProfileImage size={30} pictureUrl={createdByUserPictureUrl} marginRight={5} />
          <View overflow={"hidden"} width={"calc(100% - 35px)"} noWrap>
            <Text overFlow={"ellipsis"} numberOfLines={2} fontSize={14}>
              {createdByUserName}
            </Text>
          </View>
        </View>
      </Grid>
      <Grid
        xs={12}
        sm={hideRelatedToColumn ? 2 : 2}
        md={hideRelatedToColumn ? 2 : 2}
        lg={hideRelatedToColumn ? 2 : 2}
        onClick={didPressEditNote}
        title={AppStrings.Common.CreatedAt}
        sortColumn={"createdAt"}
      >
        <View flexDirection={"row"} alignItems={"center"} noWrap overflow={"hidden"}>
          <View overflow={"hidden"} width={"calc(100% - 35px)"} noWrap>
            <Text
              fontSize={12}
              value={AppStrings.Common.CreatedAtDate}
              replaceObject={{
                createdAt: moment(createdAt).format(DateFormats.SHORT_DATE)
              }}
            />
          </View>
        </View>
      </Grid>
    </ListItemContainer>
  );
};

export default NoteListItem;
