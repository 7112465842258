import EmptyDataView from "DLUI/emptyDataView";
import { View } from "DLUI/view";
import { useResponsiveHelper } from "contexts/responsiveContext";
import type { FC } from "react";
import { useMemo } from "react";
import ColorsEnum from "utils/colorsEnum";
import type { ViewComponentProps } from "DLUI/view/view";

type EmptyStateProps = ViewComponentProps;

const BankingEmptyState: FC<EmptyStateProps> = ({ children, ...rest }) => {
  const { isMobile, isOnlyTabletAndPortrait } = useResponsiveHelper();

  const width = useMemo(() => {
    if (isMobile) {
      return "100%";
    }
    if (isOnlyTabletAndPortrait) {
      return "60%";
    }
    return "45%";
  }, [isMobile, isOnlyTabletAndPortrait]);

  return (
    <EmptyDataView>
      <View
        noWrap
        flexDirection={isMobile || isOnlyTabletAndPortrait ? "column" : "row"}
        justifyContent={"center"}
        width={width}
        gap={30}
        paddingTop={isMobile ? 20 : 0}
        paddingBottom={isMobile ? 20 : 0}
        {...rest}
      >
        {children}
      </View>
    </EmptyDataView>
  );
};

const BankingEmptyStateLeftSide: FC = ({ children }) => {
  const { isMobile } = useResponsiveHelper();

  return (
    <View noWrap paddingRight={isMobile ? 0 : 25} alignItems={"center"} fullWidth>
      {children}
    </View>
  );
};

const BankingEmptyStateRightSide: FC = ({ children }) => (
  <View
    noWrap
    border={`1px solid ${ColorsEnum.LightBlackFadedMore}`}
    borderRadius={8}
    paddingLeft={32}
    paddingRight={32}
    paddingTop={40}
    paddingBottom={40}
    height={"100%"}
  >
    {children}
  </View>
);

export { BankingEmptyState, BankingEmptyStateLeftSide, BankingEmptyStateRightSide };
