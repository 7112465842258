import type { LateFeeChargeDto } from "@doorloop/dto";
import { LateFeePolicyFeeType, LateFeePolicyFrequency } from "@doorloop/dto";
import isNil from "lodash/isNil";

export const DAYS_IN_WEEK = 7;
export const DAYS_IN_MONTH = 30;

export function shouldTenantBeChargedInDay(rule: LateFeeChargeDto, delinquency: number): boolean {
  if (isNil(rule.daysAfterDueDateToChargeLateFees) || rule.daysAfterDueDateToChargeLateFees >= delinquency)
    return false;
  if (rule.frequency === LateFeePolicyFrequency.ONCE) {
    return delinquency - rule.daysAfterDueDateToChargeLateFees === 1;
  }
  if (rule.frequency === LateFeePolicyFrequency.DAILY) {
    return true;
  }
  if (rule.frequency === LateFeePolicyFrequency.WEEKLY) {
    return (delinquency - rule.daysAfterDueDateToChargeLateFees) % DAYS_IN_WEEK === 1;
  }
  return (delinquency - rule.daysAfterDueDateToChargeLateFees) % DAYS_IN_MONTH === 1;
}

export function getChargeForRuleInDay(rule: LateFeeChargeDto, delinquency: number, rent: number): number {
  const shouldTenantBeChargedToday = shouldTenantBeChargedInDay(rule, delinquency);
  if (!shouldTenantBeChargedToday) return 0;

  return (
    (rule.feeType === LateFeePolicyFeeType.FIXED_AMOUNT ? rule.amount : (rent * (rule.percentage ?? 0)) / 100) ?? 0
  );
}

export function getNumberOfCharges(rule: LateFeeChargeDto, delinquency: number): number {
  if (!rule.daysAfterDueDateToChargeLateFees || rule.daysAfterDueDateToChargeLateFees >= delinquency) return 0;
  if (rule.frequency === LateFeePolicyFrequency.ONCE) {
    return 1;
  }
  if (rule.frequency === LateFeePolicyFrequency.DAILY) {
    return delinquency - rule.daysAfterDueDateToChargeLateFees;
  }
  if (rule.frequency === LateFeePolicyFrequency.WEEKLY) {
    return Math.ceil((delinquency - rule.daysAfterDueDateToChargeLateFees) / DAYS_IN_WEEK);
  }
  return Math.ceil((delinquency - rule.daysAfterDueDateToChargeLateFees) / DAYS_IN_MONTH);
}

export function getRuleDelinquencyDescription(rule: LateFeeChargeDto, delinquency: number): string {
  if (!rule.daysAfterDueDateToChargeLateFees) return "";
  const charges = getNumberOfCharges(rule, delinquency);
  const isMultiple = charges > 1;
  if (rule.daysAfterDueDateToChargeLateFees >= delinquency) {
    return "0";
  }
  if (rule.frequency === LateFeePolicyFrequency.ONCE) {
    return "One-time";
  }

  if (rule.frequency === LateFeePolicyFrequency.DAILY) {
    return `${charges} ${isMultiple ? "days" : "day"}`;
  }

  if (rule.frequency === LateFeePolicyFrequency.WEEKLY) {
    return `${charges} ${isMultiple ? "weeks" : "week"}`;
  }

  return `${charges} ${isMultiple ? "months" : "month"}`;
}

export function getTotalChargesForRule(rule: LateFeeChargeDto, delinquency: number, rent: number): number {
  const baseSum =
    (rule.feeType === LateFeePolicyFeeType.FIXED_AMOUNT ? rule.amount : (rent * (rule.percentage ?? 0)) / 100) ?? 0;
  const chargesAmount = getNumberOfCharges(rule, delinquency);
  return baseSum * chargesAmount;
}
