"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MarketingLandingPage = exports.MarketingCampaignNotSelectable = exports.MarketingCampaign = void 0;
var MarketingCampaign;
(function (MarketingCampaign) {
    MarketingCampaign["GoogleSearch"] = "GoogleSearch";
    MarketingCampaign["YouTube"] = "YouTube";
    MarketingCampaign["Instagram"] = "Instagram";
    MarketingCampaign["TikTok"] = "TikTok";
    MarketingCampaign["Facebook"] = "Facebook";
    MarketingCampaign["Webinar"] = "Webinar";
    MarketingCampaign["Podcast"] = "Podcast";
    MarketingCampaign["LinkedIn"] = "LinkedIn";
    MarketingCampaign["Reddit"] = "Reddit";
    MarketingCampaign["Quora"] = "Quora";
    MarketingCampaign["Referral"] = "Referral";
    MarketingCampaign["Other"] = "Other";
})(MarketingCampaign || (exports.MarketingCampaign = MarketingCampaign = {}));
var MarketingCampaignNotSelectable;
(function (MarketingCampaignNotSelectable) {
    MarketingCampaignNotSelectable["GoogleAds"] = "google-ads";
    MarketingCampaignNotSelectable["Branded"] = "branded";
    MarketingCampaignNotSelectable["UtmSourceCookieParseFailed"] = "dlmc cookie parse failed";
    MarketingCampaignNotSelectable["UtmSourceCookieNotFound"] = "dlmc cookie not found";
})(MarketingCampaignNotSelectable || (exports.MarketingCampaignNotSelectable = MarketingCampaignNotSelectable = {}));
var MarketingLandingPage;
(function (MarketingLandingPage) {
    MarketingLandingPage["Blog"] = "blog";
    MarketingLandingPage["Resources"] = "resources";
    MarketingLandingPage["Laws"] = "laws";
    MarketingLandingPage["Definitions"] = "definitions";
    MarketingLandingPage["Forms"] = "forms";
    MarketingLandingPage["Hub"] = "hub";
})(MarketingLandingPage || (exports.MarketingLandingPage = MarketingLandingPage = {}));
