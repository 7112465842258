import React, { useMemo } from "react";
import MomentUtils from "@date-io/moment";
import { View } from "DLUI/view";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Text from "DLUI/text";
import AppStrings from "../../../../locale/keys";
import { FormikDatePicker, TextField } from "DLUI/form";
import { FastField, Field, getIn, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { tenantsApi } from "api/tenantsApi";
import { Grid } from "@material-ui/core";
import FieldWrapper from "DLUI/form/textField/fieldWrapper";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import LeaseSelection from "DLUI/dialogs/transactions/leaseSelection";
import { InsurancePolicyDialogViews } from "DLUI/dialogs/insurancePolicy/insurancePolicyFormikContextWrapper";
import type { InsurancePolicyDto } from "@doorloop/dto";
import { LinkedResourceType } from "@doorloop/dto";
import moment from "moment";
import { DateFormats } from "@doorloop/dto";
import { useParams } from "react-router-dom";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface Props {
  showLeaseSelectionError: boolean;
  leaseSelectionErrorText: string;
  setViewIndex: (viewIndex: number) => void;
  currentLeaseId: string | undefined;
  setCurrentLeaseId: (leaseId: string | undefined) => void;
  attachments: FileListItemProps[];
  setAttachments: (files: FileListItemProps[]) => void;
}

const InsurancePolicyForm = ({
  attachments,
  setAttachments,
  setViewIndex,
  showLeaseSelectionError,
  leaseSelectionErrorText,
  currentLeaseId,
  setCurrentLeaseId
}: Props) => {
  const { t } = useTranslation();
  const { screenContainerPadding } = useResponsiveHelper();
  const formikRef = useFormikContext<InsurancePolicyDto>();
  const { policyId, leaseId } = useParams<any>();
  const isEditMode = policyId !== undefined;
  const onFileReceived = (files: FileListItemProps[]) => {
    setAttachments(files);
  };

  const didPressRemoveLease = () => {
    formikRef.setFieldValue("lease", undefined);
    formikRef.setFieldTouched("lease", true);
    setCurrentLeaseId(undefined);
    formikRef.setFieldValue("tenant", undefined);
  };

  const didPressSelectLease = () => {
    setViewIndex(InsurancePolicyDialogViews.SelectLease);
  };

  const expirationMinDate = useMemo(() => {
    let _minDate;
    const selectedDate = getIn(formikRef.values, "effectiveDate");
    if (selectedDate) {
      _minDate = moment(selectedDate).add(1, "d").format(DateFormats.ISO_DATE_SERVER_FORMAT);
    }
    return _minDate;
  }, [formikRef.values.effectiveDate]);

  const effectiveMaxDate = useMemo(() => {
    let _maxDate;
    const selectedDate = getIn(formikRef.values, "expirationDate");
    if (selectedDate) {
      _maxDate = moment(selectedDate).subtract(1, "d").format(DateFormats.ISO_DATE_SERVER_FORMAT);
    }
    return _maxDate;
  }, [formikRef.values.expirationDate]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <View
        paddingLeft={screenContainerPadding}
        paddingRight={screenContainerPadding}
        flexDirection={"column"}
        width={"100%"}
      >
        <View marginTop={20}>
          <LeaseSelection
            didPressSelectLease={didPressSelectLease}
            leaseId={currentLeaseId}
            errorTex={showLeaseSelectionError ? leaseSelectionErrorText : undefined}
            viewOnly={leaseId !== undefined}
          />
        </View>
        <View fullWidth marginTop={20}>
          <Text fontSize={18} value={AppStrings.Leases.InsurancePolicy.InsuranceDetails} bold />
        </View>
        <View>
          <FormikCachedAsyncAutoComplete
            required
            label={t(AppStrings.Leases.InsurancePolicy.PolicyOwner)}
            uniqueIndex={0}
            apiHandler={tenantsApi}
            displayNameKey={"name"}
            filterFieldName={"filter_text"}
            filterFieldValue={"name"}
            selectionFields={["id", "class"]}
            name={"tenant"}
            marginTop={20}
            queryParams={{ filter_lease: currentLeaseId }}
            defaultValue={formikRef.values.tenant}
            disabled={!currentLeaseId}
          />

          <View flexDirection={"row"}>
            <Grid item xs={12} sm={6}>
              <FieldWrapper type={"endElement"}>
                <FastField
                  required
                  component={TextField}
                  label={t(AppStrings.Leases.InsurancePolicy.ProviderName)}
                  name={"provider"}
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldWrapper type={"startElement"}>
                <FastField
                  required
                  component={TextField}
                  label={t(AppStrings.Leases.InsurancePolicy.PolicyNumber)}
                  name={"policyNumber"}
                />
              </FieldWrapper>
            </Grid>
          </View>

          <View flexDirection={"row"}>
            <Grid item xs={12} sm={6}>
              <FieldWrapper type={"endElement"}>
                <Field
                  required
                  component={FormikDatePicker}
                  uniqueKey={"termStartDate"}
                  label={AppStrings.Leases.InsurancePolicy.EffectiveDate}
                  name={"effectiveDate"}
                  maxDate={effectiveMaxDate}
                  noMargin
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldWrapper type={"startElement"}>
                <Field
                  required
                  component={FormikDatePicker}
                  uniqueKey={"termEndDate"}
                  label={AppStrings.Leases.InsurancePolicy.ExpirationDate}
                  name={"expirationDate"}
                  minDate={expirationMinDate}
                  noMargin
                />
              </FieldWrapper>
            </Grid>
          </View>
        </View>
        <FastField
          required
          component={TextField}
          label={t(AppStrings.Leases.InsurancePolicy.LiabilityCoverage)}
          name={"coverage"}
          formatType={"currency"}
          marginTop={20}
        />
        <FormAttachments
          onFileReceived={onFileReceived}
          files={attachments}
          editMode={isEditMode}
          resourceType={LinkedResourceType.InsurancePolicy}
          resourceId={formikRef.values.id}
          marginTop={20}
        />
      </View>
    </MuiPickersUtilsProvider>
  );
};

export default InsurancePolicyForm;
