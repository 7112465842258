import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import type { BankAccountDto } from "@doorloop/dto";

import { bankAccountsApi } from "api/bankAccountsApi";
import { DialogState } from "DLUI/dialogs/loadingDialog";

import { NavigationManager } from "utils/navigation";
import AppStrings from "../../../../../../../locale/keys";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";

const DialogViews = {
  LoadingView: 0,
  ContentView: 1
};

const useDialogStripeOnboarding = () => {
  const { t } = useTranslation();

  const { merchantAccountId } = useParams<any>();

  const [bankAccount, setBankAccount] = useState<BankAccountDto | null>(null);

  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const [viewIndex, setViewIndex] = useState(DialogViews.LoadingView);

  const dialogHeight = getDialogFrameDimension("height", 620);
  const dialogWidth = getDialogFrameDimension("width", 1000);

  const showErrorMessage = (errMessage?: string) => {
    setLoadingDialogState(DialogState.Error);

    if (errMessage) {
      setLoadingDialogErrorText(errMessage);
    }

    setViewIndex(DialogViews.LoadingView);
  };

  const onBackdropClick = () => {
    NavigationManager.viewOnlinePayments(true);
  };

  useEffect(() => {
    if (merchantAccountId) {
      setViewIndex(DialogViews.LoadingView);
      setLoadingDialogState(DialogState.Show);

      bankAccountsApi.get(merchantAccountId).then((response) => {
        if (response.data && response.data.id === merchantAccountId) {
          setBankAccount(response.data);

          setViewIndex(DialogViews.ContentView);
          setLoadingDialogState(DialogState.Hidden);
        } else {
          showErrorMessage(t(AppStrings.Accounts.MerchantAccountNotFound));
        }
      });
    } else {
      showErrorMessage(t(AppStrings.Accounts.MerchantAccountNotFound));
    }
  }, [merchantAccountId]);

  return {
    DialogViews,
    bankAccount,
    loadingDialogState,
    loadingDialogErrorText,
    viewIndex,
    setLoadingDialogState,
    showErrorMessage,
    onBackdropClick,
    dialogHeight,
    dialogWidth
  };
};

export { useDialogStripeOnboarding };
