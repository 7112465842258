"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalApplicationApplicantGetAllReportsShareScreeningDataDto = void 0;
class RentalApplicationApplicantGetAllReportsShareScreeningDataDto {
    constructor(init) {
        Object.assign(this, init);
        this.reports = init.reports;
        this.firstName = init.firstName;
        this.lastName = init.lastName;
        this.reference = init.reference;
    }
}
exports.RentalApplicationApplicantGetAllReportsShareScreeningDataDto = RentalApplicationApplicantGetAllReportsShareScreeningDataDto;
