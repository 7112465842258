import { getUserReportsScreenSectionGroupItem } from "screens/reports/reportsScreen/reportsScreenSectionGroupItems";
import type { ReportScreenItemType } from "DLUI/report/screen/reportScreenItemType";
import { navigationMenuLayers } from "DLUI/drawer/drawerItems";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { history } from "store/history";
import qs from "qs";
import type { AnyPermission } from "@doorloop/dto";
import type { NavConfigProps, NavSelectionProps } from "DLUI/drawer/types";
import { NavLevels } from "DLUI/drawer/types";
import CreateNewMenuSections from "DLUI/screen/createNewMenu/createNewMenuItems";
import { DialogRoutes } from "DLUI/screen/dialogsProvider";
import _ from "lodash";
import { reportsMenuItem } from "DLUI/drawer/items/mainMenuItems";

export enum LocalSearchItemType {
  PAGE = "page",
  ACTION = "action",
  REPORT = "report"
}

export interface LocalSearchItem {
  title: string;
  type: LocalSearchItemType;
  tags: string[];
  onPress: ReportScreenItemType["onPress"];
  clearances?: AnyPermissionClearance[];
  permissions?: AnyPermission[];
  isCategory?: boolean;
  navigation?: NavSelectionProps;
}

const mapMenuItemsToSearchItems = (navConfig: NavConfigProps): LocalSearchItem[] =>
  navConfig.groups.flatMap((group) =>
    group.items
      .map((item) => {
        return {
          isCategory: Boolean(item.subNavigationName),
          title: item.label || "",
          type: LocalSearchItemType.PAGE,
          navigation: {
            menuItemSelection: item.navKey,
            subMenuSelected: item.subNavigationName || navConfig.subMenuLayerKey,
            mainMenuSelected: group.parentNavigationKey,
            level: navConfig.level
          },
          clearances: [...(item.clearance || []), ...(group.clearance || [])],
          tags: [],
          onPress: () => {
            const path = _.first(item.navPath);

            setTimeout(() => {
              if (path) {
                history.push({
                  pathname: path.name,
                  search: qs.stringify(path.queryParams || {})
                });
              }
            }, 0);
          }
        };
      })
      .filter((item) => !item.isCategory)
  );

const mapReportsToSearchItems = (isHOA: boolean): LocalSearchItem[] =>
  getUserReportsScreenSectionGroupItem(isHOA).flatMap((section) =>
    section.items.map((item) => {
      return {
        title: item.description,
        type: LocalSearchItemType.REPORT,
        permissions: [item.permission],
        navigation: {
          level: NavLevels.MAIN_MENU,
          menuItemSelection: reportsMenuItem.navKey
        },
        tags: [],
        onPress: item.onPress
      };
    })
  );

const mapCreateNewToSearchItems = (): LocalSearchItem[] =>
  CreateNewMenuSections.flatMap((section) =>
    section.items.map(({ navigationMethod, permission, title }) => {
      return {
        title,
        type: LocalSearchItemType.ACTION,
        permissions: permission ? [permission] : undefined,
        tags: [],
        onPress: () => {
          if (navigationMethod) {
            if (navigationMethod.method === "append") {
              let combinedSearchParams: string | undefined;

              if (navigationMethod.queryParams) {
                combinedSearchParams = qs.stringify({
                  ...navigationMethod.queryParams,
                  ...qs.parse(location.search)
                });
              }

              const base = location.pathname.split(`/${DialogRoutes.GLOBAL_NEW}`)[0];

              const action = location.pathname.includes(DialogRoutes.GLOBAL_NEW) ? history.replace : history.push;

              action({
                pathname: `${base}/${DialogRoutes.GLOBAL_NEW}/${navigationMethod.actionPath}`,
                search: combinedSearchParams ?? location.search,
                state: {}
              });
            } else {
              history.push({
                pathname: navigationMethod.actionPath,
                search: qs.stringify(navigationMethod.queryParams)
              });
            }
          }
        }
      };
    })
  );

export const getLocalSearchData = (isHOA: boolean) => [
  ...mapReportsToSearchItems(isHOA),
  ...mapCreateNewToSearchItems(),
  ..._.values(navigationMenuLayers).flatMap((layer) => mapMenuItemsToSearchItems(layer))
];
