"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnnouncementReviewDto = void 0;
const base_dto_1 = require("../base.dto");
class AnnouncementReviewDto extends base_dto_1.BaseDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.AnnouncementReviewDto = AnnouncementReviewDto;
