import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text/text";
import type { LeaseDto } from "@doorloop/dto";
import { DefaultListItemFontSize, DefaultListStatusIconSize } from "screens/leases/leases/leasesList/leaseListItem";
import { useLeaseStatus } from "hooks/useLeaseStatus";
import { Icon } from "DLUI/icon";

interface ComponentProps {
  leaseDto: LeaseDto;
  hideIcon?: boolean;
}

const LeaseStatusElement: React.FC<ComponentProps> = ({ leaseDto, hideIcon }: ComponentProps) => {
  const {
    status: { StatusIcon, statusTextColor, statusText }
  } = useLeaseStatus(leaseDto);

  return (
    <View alignItems={"center"} flexDirection={"row"} noWrap>
      {!hideIcon && <Icon height={DefaultListStatusIconSize} Source={StatusIcon} />}
      <Text
        value={statusText}
        color={statusTextColor}
        fontWeight={700}
        fontSize={DefaultListItemFontSize}
        marginLeft={5}
        noWrap
      />
    </View>
  );
};

export default LeaseStatusElement;
