import type { FC } from "react";
import React, { useCallback } from "react";
import { getIn, useFormikContext } from "formik";
import { View } from "DLUI/view";
import type { ClientAnnouncementDto, GetAllTenantsQuery } from "@doorloop/dto";
import { TenantType } from "@doorloop/dto";
import PropertySelection from "screens/announcements/newAnnouncement/selectRecipients/propertySelection";
import AppStrings from "locale/keys";
import { BoxOption } from "DLUI/boxOption/boxOption";
import { whichTenantsItems, whichUnitsItems } from "screens/bills/payBills/types";
import { unitsApi } from "api/unitsApi";
import { tenantsApi } from "api/tenantsApi";

export enum FieldNames {
  Properties = "properties",
  Units = "units",
  Tenants = "tenants",
  Portfolios = "propertyGroups"
}

export const SelectRecipients: FC = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<ClientAnnouncementDto>();
  const properties = getIn(values, FieldNames.Properties) || [];
  const units = getIn(values, FieldNames.Units) || [];
  const tenantQueryParams: GetAllTenantsQuery = {
    filter_properties: properties,
    filter_units: units,
    filter_type: TenantType.LEASE_TENANT,
    filter_withoutLeaseDraft: true
  };
  const enableUnits = properties.length > 0;
  const enableTenants = units.length > 0;

  const resetBoxElement = useCallback((targetField: FieldNames) => {
    setFieldValue(targetField, undefined);
    setFieldTouched(targetField, false);
  }, []);

  const handleUnitsSelectChange = useCallback((value: any) => {
    resetBoxElement(FieldNames.Tenants);

    if (value === 0) {
      resetBoxElement(FieldNames.Units);
    }
  }, []);

  const handleTenantsSelectChange = useCallback((value: any) => {
    if (value === 0) {
      resetBoxElement(FieldNames.Tenants);
    }
  }, []);

  return (
    <View alignItems={"center"}>
      <PropertySelection
        onListChange={() => {
          resetBoxElement(FieldNames.Units);
          resetBoxElement(FieldNames.Tenants);
        }}
      />
      {enableUnits && (
        <BoxOption
          formikName={FieldNames.Units}
          selectLabel={AppStrings.Announcements.selectUnits}
          header={AppStrings.Announcements.whichUnits}
          buttons={whichUnitsItems}
          onListChange={() => resetBoxElement(FieldNames.Tenants)}
          onSelectChange={handleUnitsSelectChange}
          api={unitsApi}
          pageSize={20}
          queryParams={
            properties?.length && {
              filter_properties: properties
            }
          }
        />
      )}
      {enableUnits && enableTenants && (
        <BoxOption
          formikName={FieldNames.Tenants}
          selectLabel={AppStrings.Announcements.selectTenants}
          header={AppStrings.Announcements.whichTenants}
          buttons={whichTenantsItems}
          onSelectChange={handleTenantsSelectChange}
          api={tenantsApi}
          queryParams={(properties?.length || units?.length) && tenantQueryParams}
          pageSize={20}
        />
      )}
    </View>
  );
};
