import { Icon as DLUI_Icon } from "DLUI/icon";
import { View } from "DLUI/view";
import type { SVGIconComponent } from "assets/icons/types";
import React from "react";

import { useResponsiveHelper } from "../../../../../../contexts/responsiveContext";

interface ComponentProps {
  Icon: SVGIconComponent;
  size?: number;
  marginLeft?: number | string;
  marginRight?: number | string;
  containerSize?: {
    height?: number;
    width?: number;
  };
}

const LeaseSettingsIcon: React.FC<ComponentProps> = ({
  Icon,
  containerSize,
  size = 20,
  marginLeft,
  marginRight
}: ComponentProps) => {
  const { isMobile } = useResponsiveHelper();
  const containerStyleObject: React.CSSProperties = {
    backgroundColor: "rgba(47, 62, 131, 0.1)",
    marginRight: 10,
    marginLeft: isMobile ? 0 : 10,
    width: containerSize ? containerSize.width : size + 5,
    height: containerSize ? containerSize.height : size + 5,
    padding: 10
  };

  if (marginLeft) {
    containerStyleObject.marginLeft = marginLeft;
  }

  if (marginRight) {
    containerStyleObject.marginRight = marginRight;
  }

  return (
    <View borderRadius={10} justifyContent={"center"} alignItems={"center"} style={containerStyleObject}>
      <DLUI_Icon pathColor={"black"} Source={Icon} width={size} height={size} />
    </View>
  );
};

export default LeaseSettingsIcon;
