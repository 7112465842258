import type { ObjectPermission, UserRoleDto } from "@doorloop/dto";
import type { FC } from "react";
import React, { useCallback } from "react";
import { useFormikContext } from "formik";
import { usePermissionDependency } from "screens/settings/userRoles/dependencies/usePermissionDependency";
import type { ObjectPermissionField } from "screens/settings/userRoles/clearanceTypes";
import { makePermissionKey } from "screens/settings/userRoles/clearanceTypes";
import { useTranslation } from "react-i18next";
import { ReportSettingsRadioToggle } from "DLUI/reports/settings/reportSettingsRadioToggle";
import { useResponsiveHelper } from "../../../../../../contexts/responsiveContext";

interface ObjectPermissionProps {
  permission: ObjectPermission;
  field: ObjectPermissionField;
  ternary?: boolean;
  label: string;
}

export const ObjectPermissionToggle: FC<ObjectPermissionProps> = ({
  permission,
  field,
  ternary,
  label
}: ObjectPermissionProps) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();
  const formikRef = useFormikContext<UserRoleDto>();
  const { applyObjectDependencies } = usePermissionDependency(formikRef);
  const applyDependency = useCallback((newValue) => applyObjectDependencies(permission, field, newValue), []);
  const permissionKey = makePermissionKey(permission, field);

  return (
    <ReportSettingsRadioToggle
      fieldName={permissionKey}
      label={isMobile ? t(label) : undefined}
      ternary={ternary}
      applyDependency={applyDependency}
    />
  );
};
