import type { PropsWithChildren } from "react";
import React from "react";
import { View } from "DLUI/view";
import "./styles.css";
import type { ViewComponentProps } from "DLUI/view/view";
import clsx from "clsx";

type Props = ViewComponentProps;

const PrintHiddenContainer: React.FC<Props> = ({ children, className, ...viewProps }: PropsWithChildren<Props>) => (
  <View {...viewProps} className={clsx(["print-hide", className])}>
    {children}
  </View>
);

export default PrintHiddenContainer;
