import React, { useMemo } from "react";
import OwnerSelectionButton from "DLUI/dialogs/settings/common/owners/ownerSelectionButton";
import { View } from "DLUI/view";
import { AddSquareButton } from "DLUI/button";
import AppStrings from "../../../../../../locale/keys";
import { ValidationIndicator } from "DLUI/form";
import makeStyles from "DLUI/dialogs/settings/common/owners/styles";
import { useTranslation } from "react-i18next";
import type { OwnerDto } from "@doorloop/dto";
import { DataCy } from "@doorloop/dto";

const defaultOwnerSelectionButtonNameSetter = (index: number) => `owners[${index}].ownershipPercentage`;

interface ComponentProps {
  didPressDeleteButton: (itemIndex: number) => void;
  didChangeOwnerDistribution: () => void;
  currentOwnersSelection: OwnerDto[];
  didPressSelectOwner: () => void;
  shouldShowError: boolean;
  /**
   * receives a method to calculate owner indexed button name
   *
   * Usage: (index: number) => `string${index}string`;
   *
   * Example: (index) => `owners[${index}].ownershipPercentage;
   */
  ownerSelectionButtonNameSetter?: (index) => string;
  dataCy?: string;
}

const OwnersView: React.FC<ComponentProps> = ({
  didPressDeleteButton,
  didChangeOwnerDistribution,
  currentOwnersSelection,
  didPressSelectOwner,
  shouldShowError,
  ownerSelectionButtonNameSetter = defaultOwnerSelectionButtonNameSetter
}) => {
  const { t } = useTranslation();
  const classes = makeStyles();

  const listItems = useMemo(() => {
    if (currentOwnersSelection) {
      return currentOwnersSelection.map((currentItem, index) => {
        const ownerButtonName = ownerSelectionButtonNameSetter(index);

        return (
          <div key={"SB" + currentItem.id} className={classes.ownerWrapper}>
            <OwnerSelectionButton
              didPressDeleteButton={didPressDeleteButton}
              name={currentItem.name}
              pictureUrl={currentItem.pictureUrl}
              index={index}
              buttonFormikName={ownerButtonName}
              didChangeOwnerDistribution={didChangeOwnerDistribution}
            />
          </div>
        );
      });
    }
  }, [currentOwnersSelection]);

  return (
    <View marginTop={20} alignItems={"center"} justifyContent={"center"} flexDirection={"column"} width={"100%"}>
      <View className={classes.selectedOwnerButtons} justifyContent={"center"} flexDirection={"row"}>
        {listItems}
        <AddSquareButton
          label={AppStrings.Owners.EditOwners.AddOwner}
          iconSize={60}
          iconColorFill={"pink"}
          iconGap={20}
          dataCy={DataCy.newProperty.ownership.addOwnerSquareButton}
          rootClassName={classes.AddSquareBigButton}
          onAddButtonPress={didPressSelectOwner}
        />
      </View>
      <View alignItems={"center"} justifyContent={"center"} marginBottom={20}>
        <ValidationIndicator
          shouldShow={shouldShowError}
          displayText={t(AppStrings.Owners.EditOwners.OwnershipValidationText)}
          marginTop={"20px"}
          maxWidth={500}
        />
      </View>
    </View>
  );
};

export default OwnersView;
