"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsValidStateValidator = IsValidStateValidator;
const class_validator_1 = require("class-validator");
const regional_constant_1 = require("../regional.constant");
function IsValidStateValidator(validationOptions) {
    return function (object, propertyName) {
        (0, class_validator_1.registerDecorator)({
            name: "isValidStateValidator",
            target: object.constructor,
            propertyName,
            constraints: [],
            options: validationOptions,
            validator: {
                validate(value, args) {
                    const addressObj = args.object;
                    const { country, state } = addressObj;
                    if (!state) {
                        return Boolean(country && [regional_constant_1.UNITED_STATES, regional_constant_1.CANADA].includes(country));
                    }
                    if (country === regional_constant_1.UNITED_STATES) {
                        return regional_constant_1.usStateAbbreviationList.includes(state);
                    }
                    if (country === regional_constant_1.CANADA) {
                        return regional_constant_1.caProvincesList.includes(state);
                    }
                    return true;
                }
            }
        });
    };
}
