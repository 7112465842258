import _ from "lodash";
import { useMemo } from "react";

export type DataAttributes = Record<string, string | number>;

export const useDataAttributes = (dataAttributes?: DataAttributes) =>
  useMemo(() => {
    if (!dataAttributes) return {};

    return Object.entries(dataAttributes).reduce((acc, [key, value]) => {
      acc[`data-${_.snakeCase(key)}`] = value;
      return acc;
    }, {});
  }, [dataAttributes]);
