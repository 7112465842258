"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonOutgoingEPayInfoDto = void 0;
const checkbookPaymentMethod_enum_1 = require("../outgoingPayments/checkbookPaymentMethod.enum");
const class_validator_1 = require("class-validator");
const address_dto_1 = require("../common/address.dto");
const class_transformer_1 = require("class-transformer");
const isRoutingNumber_validator_1 = require("../common/validators/isRoutingNumber.validator");
const isBankAccountNumber_validator_1 = require("../common/validators/isBankAccountNumber.validator");
class PersonOutgoingEPayInfoDto {
    constructor(data = {}) {
        Object.assign(this, data);
    }
}
exports.PersonOutgoingEPayInfoDto = PersonOutgoingEPayInfoDto;
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], PersonOutgoingEPayInfoDto.prototype, "enabled", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.enabled),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(checkbookPaymentMethod_enum_1.CheckbookPaymentMethodEnum),
    __metadata("design:type", String)
], PersonOutgoingEPayInfoDto.prototype, "method", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.method === checkbookPaymentMethod_enum_1.CheckbookPaymentMethodEnum.PHYSICAL_CHECK),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => address_dto_1.AddressDto),
    __metadata("design:type", address_dto_1.AddressDto)
], PersonOutgoingEPayInfoDto.prototype, "physicalAddress", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.method === checkbookPaymentMethod_enum_1.CheckbookPaymentMethodEnum.DIRECT_DEPOSIT),
    (0, class_validator_1.IsNotEmpty)(),
    (0, isRoutingNumber_validator_1.IsRoutingNumber)(),
    __metadata("design:type", String)
], PersonOutgoingEPayInfoDto.prototype, "directRouting", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.method === checkbookPaymentMethod_enum_1.CheckbookPaymentMethodEnum.DIRECT_DEPOSIT),
    (0, class_validator_1.IsNotEmpty)(),
    (0, isBankAccountNumber_validator_1.IsBankAccountNumber)(),
    __metadata("design:type", String)
], PersonOutgoingEPayInfoDto.prototype, "directAccount", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.method === checkbookPaymentMethod_enum_1.CheckbookPaymentMethodEnum.DIGITAL_PAYMENT),
    (0, class_validator_1.IsEmail)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], PersonOutgoingEPayInfoDto.prototype, "digitalEmail", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], PersonOutgoingEPayInfoDto.prototype, "digitalPinRequired", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.digitalPinRequired),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(20),
    (0, class_validator_1.MinLength)(4),
    __metadata("design:type", String)
], PersonOutgoingEPayInfoDto.prototype, "digitalPin", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(255),
    __metadata("design:type", String)
], PersonOutgoingEPayInfoDto.prototype, "digitalPinDescription", void 0);
