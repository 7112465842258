"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OutgoingPaymentsReportItemDto = void 0;
const printChecksReportItem_dto_1 = require("../printChecks/printChecksReportItem.dto");
class OutgoingPaymentsReportItemDto extends printChecksReportItem_dto_1.PrintChecksReportItemDto {
    constructor(props) {
        super(props);
    }
}
exports.OutgoingPaymentsReportItemDto = OutgoingPaymentsReportItemDto;
