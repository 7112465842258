import { Suspense } from "react";
import type { RouteProps } from "react-router";
import { Route } from "react-router";
import { ScreenLoader } from "./screenLoader";

export interface LazyRouteProps extends Omit<RouteProps, "render"> {
  render: NonNullable<RouteProps["render"]>;
}

export function LazyRoute(props: RouteProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Component = (props.render ?? props.component) as any;

  return (
    <Route
      {...props}
      render={() => (
        <Suspense fallback={<ScreenLoader />}>
          <Component />
        </Suspense>
      )}
    />
  );
}
