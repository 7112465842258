import type { FormikProps } from "formik";
import { AddressDto, PropertyType } from "@doorloop/dto";
import { PropertyClass, PropertyDto } from "@doorloop/dto";
import { PropertySettingsDto } from "@doorloop/dto";
import { PropertyPetPolicyDto } from "@doorloop/dto";
import { createValidator } from "@doorloop/dto";
import _ from "lodash";

export const initFormValues = (): PropertyDto =>
  new PropertyDto({
    address: new AddressDto(),
    settings: new PropertySettingsDto(),
    type: PropertyType.RESIDENTIAL_SINGLE_FAMILY,
    class: PropertyClass.RESIDENTIAL,
    petsPolicy: new PropertyPetPolicyDto(),
    pictures: [],
    amenities: [],
    owners: []
  });

export const validateForm = createValidator(PropertyDto);

enum VIEW_STEPS {
  GENERAL_INFO = 0,
  CLASS_AND_TYPE = 2
}

export const validatePropertyForm = async (
  formikRef: FormikProps<any>
): Promise<{ isValid: boolean; errorStepIndex?: number }> => {
  await formikRef.submitForm();

  [
    "address.street1",
    "address.city",
    "address.country",
    "address.zip",
    "address.state",
    "type",
    "typeDescription"
  ].forEach((field) => {
    formikRef.setFieldTouched(field);
  });

  const errors = await formikRef.validateForm();

  if (!_.isEmpty(errors.address)) {
    formikRef.setSubmitting(false);
    return { isValid: false, errorStepIndex: VIEW_STEPS.GENERAL_INFO };
  }

  if (errors.type || errors.typeDescription) {
    formikRef.setSubmitting(false);
    return { isValid: false, errorStepIndex: VIEW_STEPS.CLASS_AND_TYPE };
  }

  return { isValid: true };
};
