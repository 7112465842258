"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class AccessToken {
    constructor(token, subdomain, companyName) {
        this.token = token;
        this.subdomain = subdomain;
        this.companyName = companyName;
    }
}
exports.default = AccessToken;
