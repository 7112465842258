import React, { useCallback } from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { FormikDatePicker, Select } from "DLUI/form";
import { tenantsApi } from "api/tenantsApi";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";
import { BoardMembershipDto, Position } from "@doorloop/dto";
import { Grid } from "@material-ui/core";
import AppStrings from "../../../../locale/keys";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import moment from "moment";
import { DateFormats } from "@doorloop/dto";
import { FieldSafe } from "DLUI/fieldSafe/fieldSafe";

interface ComponentProps {
  isEdit?: boolean;
  propertyId?: string;
}

const BoardMembershipForm = ({ propertyId, isEdit }: ComponentProps) => {
  const { t } = useTranslation();
  const { values, setFieldValue, setFieldTouched } = useFormikContext<BoardMembershipDto>();

  const handleTermStartChange = useCallback(
    (value) => {
      setFieldTouched("termStart", false, true);
      if (!values.termEnd) {
        setFieldValue("termEnd", moment(value).add(1, "year").format(DateFormats.ISO_DATE_SERVER_FORMAT));
      } else if (values.termEnd && moment(value).isAfter(values.termEnd)) {
        setFieldValue("termEnd", value);
      }
    },
    [values]
  );

  return (
    <View paddingRight={10} paddingLeft={10}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <>
          {isEdit && <Text value={AppStrings.Common.Hoa.FillInBoardMemberDetails} bold marginBottom={20} />}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FastFieldSafe
                name={"tenantId"}
                label={t(AppStrings.Common.Hoa.BoardMemberName)}
                component={FormikCachedAsyncAutoComplete}
                apiHandler={tenantsApi}
                queryParams={{ ...(isEdit ? {} : { filter_notBoardMember: true }), filter_property: propertyId }}
                disabled={isEdit}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FastFieldSafe
                component={Select}
                label={AppStrings.Common.Hoa.Position}
                uniqueKey={"positionDropdown"}
                name={"position"}
                selectionEnum={Position}
                translationKey={"positions"}
                paddingLeft={0}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldSafe
                dto={BoardMembershipDto}
                required
                component={FormikDatePicker}
                uniqueKey={"termStartDate"}
                label={AppStrings.Common.Hoa.TermStart}
                onChange={handleTermStartChange}
                name={"termStart"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldSafe
                dto={BoardMembershipDto}
                component={FormikDatePicker}
                uniqueKey={"termEndDate"}
                minDate={moment(values.termStart).toDate()}
                label={AppStrings.Common.Hoa.TermEnd}
                name={"termEnd"}
              />
            </Grid>
          </Grid>
        </>
      </MuiPickersUtilsProvider>
    </View>
  );
};

export default BoardMembershipForm;
