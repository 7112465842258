import React, { useMemo } from "react";
import { View } from "DLUI/view";
import { Icon } from "DLUI/icon";
import Text from "DLUI/text";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import { ExclamationMarkIconPink, XIcon } from "assets/icons";
import { useResponsiveHelper } from "@/contexts/responsiveContext";
import DialogFrame from "../components/dialogFrame";
import { DataCy } from "@doorloop/dto/src/automation/dataCy";
import ConfirmationDialog from "../confirmationDialog/confirmationDialog";
import { type ConfirmDialogUiProps } from "./types";
import { ConfirmationDialogVariant, ConfirmationDialogViews } from "./constants";

const ConfirmDialogUi: React.FC<ConfirmDialogUiProps> = ({
  onBackdropClick,
  showXButton,
  dialogTitle,
  dialogSubTitle,
  dialogSubTitleDisclaimer,
  dialogSubTitleReplaceObject,
  DialogSubTextChildren,
  dismissButtonText,
  didPressDismissButton,
  confirmButtonText,
  didPressConfirmButton,
  loadingText,
  loadingDialogErrorText,
  successText,
  dialogHeight = 520,
  dialogWidth = 700,
  loadingDialogState = DialogState.Hidden,
  centerContainer,
  MainIcon = ExclamationMarkIconPink,
  mainIconProps,
  dialogVariant = ConfirmationDialogVariant.DEFAULT,
  checkboxText,
  subTitleProps = {
    color: "black"
  }
}) => {
  const { isMobile } = useResponsiveHelper();

  const activeView = useMemo(
    () =>
      loadingDialogState === DialogState.Hidden
        ? ConfirmationDialogViews.ACTIVE_VIEW_CONTENT
        : ConfirmationDialogViews.ACTIVE_VIEW_LOADER,
    [loadingDialogState]
  );

  const renderView = ({ index }) => {
    if (index === ConfirmationDialogViews.ACTIVE_VIEW_CONTENT) {
      return (
        <View height={"100%"} justifyContent={centerContainer ? "center" : undefined}>
          {showXButton && (
            <View onClick={onBackdropClick} autoWidth style={{ position: "absolute", top: 20, right: 20, zIndex: 1 }}>
              <Icon Source={XIcon} size={28} pathColor={"light-gray"} />
            </View>
          )}
          <View alignItems={"center"} paddingLeft={20} paddingRight={20}>
            {MainIcon && <Icon marginTop={50} Source={MainIcon} {...mainIconProps} />}
            <Text color={"black"} fontSize={24} fontWeight={700} value={dialogTitle} marginTop={30} align={"center"} />
            {DialogSubTextChildren || (
              <Text
                fontSize={16}
                value={dialogSubTitle}
                marginTop={isMobile ? 50 : 20}
                lineHeight={"150%"}
                fullWidth
                align={"center"}
                whiteSpace={"pre-line"}
                replaceObject={dialogSubTitleReplaceObject}
                {...subTitleProps}
              />
            )}
            {dialogSubTitleDisclaimer && <Text marginTop={20} value={dialogSubTitleDisclaimer} />}
          </View>
          <View
            flex={centerContainer ? undefined : 1}
            justifyContent={"center"}
            alignItems={"flex-end"}
            flexDirection={"row"}
            marginBottom={50}
            width={"100%"}
            marginTop={centerContainer ? 32 : undefined}
          >
            {dismissButtonText && (
              <DLButton
                actionText={dismissButtonText}
                variant={DLButtonVariantsEnum.OUTLINED}
                color={DLButtonColorsEnum.NEUTRAL}
                size={DLButtonSizesEnum.LARGE}
                onClick={didPressDismissButton}
                style={{ marginRight: 20 }}
                dataCy={DataCy.dialogActionButtons.dismiss}
              />
            )}
            <DLButton
              actionText={confirmButtonText}
              size={DLButtonSizesEnum.LARGE}
              onClick={didPressConfirmButton}
              dataCy={DataCy.dialogActionButtons.confirm}
            />
          </View>
        </View>
      );
    }
    return (
      <LoadingDialog
        dialogState={loadingDialogState}
        loadingText={loadingText}
        errorText={loadingDialogErrorText}
        successText={successText}
        onRetryButtonPress={didPressConfirmButton}
        didPressDismissButton={didPressDismissButton}
      />
    );
  };

  const renderActionPanelButtons = () => <div />;

  if (dialogVariant === ConfirmationDialogVariant.SMALL) {
    return (
      <ConfirmationDialog
        handleClose={onBackdropClick}
        handleConfirm={didPressConfirmButton}
        isLoading={loadingDialogState === DialogState.Show}
        textConfiguration={{
          titleText: dialogTitle,
          descriptionText: dialogSubTitle,
          closeBtnActionText: dismissButtonText,
          confirmBtnActionText: confirmButtonText,
          checkboxText,
          errorMessage: loadingDialogState === DialogState.Error ? loadingDialogErrorText : undefined
        }}
      />
    );
  }

  return (
    <DialogFrame
      onCloseButtonClick={onBackdropClick}
      width={dialogWidth}
      height={dialogHeight}
      renderView={renderView}
      numViews={2}
      activeView={activeView}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={isMobile ? "contentOnlyNoFrame" : "contentOnly"}
    />
  );
};

export { ConfirmDialogUi };
