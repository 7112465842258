import { useEffect } from "react";
import { isProductionEnv } from "utils/environmentHelper";

export const injectClickCaseScript = () => {
  useEffect(() => {
    if (isProductionEnv) {
      // ccConVal = 150;
      const script = document.createElement("script");
      script.async = true;
      script.type = "text/javascript";
      script.src = "https://www.clickcease.com/monitor/cccontrack.js";
      document.head.appendChild(script);
      return () => {
        document.head.removeChild(script);
      };
    }
  }, []);
};
