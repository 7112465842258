import React, { useCallback, useEffect, useMemo, useState } from "react";
import { VendorSelection } from "screens/settings/managementFees/formElements/vendorSelection";
import PayoutManagementAccounts from "screens/settings/managementFees/formElements/payoutManagementAccounts";
import { useParams } from "react-router-dom";
import { FieldArray, getIn, useFormikContext } from "formik";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { useResponsiveHelper } from "@/contexts/responsiveContext";
import { SettingsSection } from "screens/settings/common/settingsSection";
import { CollapsibleWithSwitchButton } from "screens/settings/common/collapsibleWithSwitchButton";
import type { DeepKeys, ManagementFeesCompanySettingsDto, ManagementFeesPropertySettingsDto } from "@doorloop/dto";
import { DataCy } from "@doorloop/dto";
import { FixedBasedFeeDto } from "@doorloop/dto";
import { ManagementFeeRulesList } from "screens/settings/managementFees/managementFeeRulesList";
import { useManagementFeesStyles } from "screens/settings/managementFees/useStyles";
import clsx from "clsx";
import { MinimumFeeForm } from "screens/settings/managementFees/formElements/minimumFeeForm";
import { SampleCalculator } from "screens/settings/managementFees/sampleCalculator/sampleCalculator";
import { TitleSection } from "./formElements/titleSection";
import { FormDefaultValues } from "@/components/DLUI/dialogs/settings/properties/managementFeesPropertyFormikContextWrapper";
import { isEqual, isNil } from "lodash";
import { AnimatedView } from "@/components/DLUI/view/animatedView";
import { useTypedTranslation } from "locale";
import { useGeneralStyles } from "styles/generalStyles";
import { TitleWithDescription } from "screens/settings/common/titleWithDescription";

export type GetFormikManagementFeesKey = (
  fieldName: DeepKeys<ManagementFeesCompanySettingsDto>
) => DeepKeys<ManagementFeesCompanySettingsDto | ManagementFeesPropertySettingsDto>;

interface Props {
  property?: string;
}

const ManagementFeesForm: React.FC<Props> = ({ property }: Props) => {
  const formikRef = useFormikContext();
  const params = useParams<any>();
  const { screenContainerPadding } = useResponsiveHelper();
  const classes = useManagementFeesStyles({ screenContainerPadding });
  const generalClasses = useGeneralStyles();
  const propertyId = property || params?.propertyId;
  const { isDesktop } = useResponsiveHelper();
  const { t } = useTypedTranslation();
  const _showManagementFeesSettings = useMemo(() => {
    const companyLevelInstance = propertyId === undefined;
    const collectManagementFees = getIn(formikRef.values, "collectManagementFees") || false;
    if (!collectManagementFees) return companyLevelInstance;

    const useCompanyDefaultManagementFees = getIn(formikRef.values, "useCompanyDefaultManagementFees") || false;

    return collectManagementFees && !useCompanyDefaultManagementFees;
  }, [formikRef.values, propertyId]);

  const [showManagementFeesSettings, setShowManagementFeesSettings] = useState<boolean>(false);

  useEffect(() => {
    setShowManagementFeesSettings(_showManagementFeesSettings);
  }, [_showManagementFeesSettings]);

  const getFormikManagementFeesKey: GetFormikManagementFeesKey = useCallback(
    (fieldName) => (propertyId ? `fees.${fieldName}` : fieldName),
    [propertyId]
  );

  const handleChangeSpecifyManagementFee = (nextValue: string) => {
    const useCompanyDefaultManagementFees = nextValue === "false";
    const fees: ManagementFeesCompanySettingsDto = getIn(formikRef.values, "fees");
    if (useCompanyDefaultManagementFees) {
      if (isEqual(fees, FormDefaultValues.fees)) {
        formikRef.setFieldValue("fees", undefined);
      }
    } else if (!fees) {
      formikRef.setFieldValue("fees", FormDefaultValues.fees);
    }
    formikRef.setFieldValue("useCompanyDefaultManagementFees", useCompanyDefaultManagementFees);
    setShowManagementFeesSettings(nextValue === "true");
  };

  const handleMinimumFeeToggle = useCallback(
    (isOpen: boolean) => {
      formikRef.setFieldValue(getFormikManagementFeesKey("chargeMinChargeFee"), isOpen);
      formikRef.setFieldValue(getFormikManagementFeesKey("minChargeFee"), isOpen ? new FixedBasedFeeDto() : undefined);
    },
    [formikRef, getFormikManagementFeesKey]
  );

  const handleChangeCollectFees = useCallback(
    (collectFees: boolean) => {
      !collectFees && setShowManagementFeesSettings(false);
      const useCompanyDefaultManagementFees = getIn(formikRef.values, "useCompanyDefaultManagementFees");
      if (isNil(useCompanyDefaultManagementFees)) {
        formikRef.setFieldValue("useCompanyDefaultManagementFees", true);
      }
    },
    [formikRef]
  );

  return (
    <div className={clsx(classes.columnContainer, classes.formContainer)}>
      <TitleSection
        onChangeSpecifyManagementFee={handleChangeSpecifyManagementFee}
        shouldShowPropertyOptions={propertyId !== undefined}
        onChangeCollectFees={handleChangeCollectFees}
      />

      <AnimatedView shouldShow={showManagementFeesSettings}>
        <div className={classes.columnContainer}>
          <VendorSelection fieldName={getFormikManagementFeesKey("vendor")} />
          <div className={clsx(generalClasses.flexRow, generalClasses.largeGap)}>
            <div className={generalClasses.basicFlex}>
              <SettingsSection
                dataCy={DataCy.globalSettings.managementFees.addManagementFees}
                title={t("settings.generalSettings.managementFees.setupStructure")}
              >
                <div className={classes.columnContainer}>
                  <TitleWithDescription
                    title={"settings.generalSettings.managementFees.configureManagementFees"}
                    subtitle={"settings.generalSettings.managementFees.setManagementFeesDescription"}
                  />
                  <FieldArray
                    name={getFormikManagementFeesKey("feeRules")}
                    render={(arrayHelpers) => (
                      <ManagementFeeRulesList
                        feeRulesPath={getFormikManagementFeesKey("feeRules")}
                        arrayHelpers={arrayHelpers}
                      />
                    )}
                  />
                  <HorizontalSeparationLine />
                  <CollapsibleWithSwitchButton
                    title={
                      <TitleWithDescription
                        title={"settings.generalSettings.managementFees.setMinimumFee"}
                        subtitle={"settings.generalSettings.managementFees.minimumFeeDescription"}
                      />
                    }
                    dataCyButton={DataCy.globalSettings.managementFees.minimumFee.toggle}
                    onToggleChange={handleMinimumFeeToggle}
                    defaultState={getIn(formikRef.values, getFormikManagementFeesKey("chargeMinChargeFee"))}
                  >
                    <MinimumFeeForm
                      formikName={getFormikManagementFeesKey("minChargeFee")}
                      defaultExpenseAccount={getIn(
                        formikRef.values,
                        getFormikManagementFeesKey("minChargeFee.expenseAccount")
                      )}
                    />
                  </CollapsibleWithSwitchButton>
                  <HorizontalSeparationLine />
                  <div
                    className={classes.columnContainer}
                    data-cy={DataCy.globalSettings.managementFees.payoutAccounts.container}
                  >
                    <TitleWithDescription
                      title={"settings.generalSettings.managementFees.setManagementPayoutCategories"}
                      subtitle={"settings.generalSettings.managementFees.setManagementPayoutAccountsDescription"}
                    />
                    <PayoutManagementAccounts propertyId={propertyId} />
                  </div>
                </div>
              </SettingsSection>
            </div>

            {isDesktop && (
              <div className={classes.sampleCalculator}>
                <SampleCalculator getFormikManagementFeesKey={getFormikManagementFeesKey} />
              </div>
            )}
          </div>
        </div>
      </AnimatedView>
    </div>
  );
};

export default ManagementFeesForm;
