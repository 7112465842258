import {
  AllBillsIcon,
  AllUnitsIcon,
  BillGrayIcon,
  GroupIcon,
  MenuPropertiesIcon,
  OccupiedIcon,
  PeopleIcon,
  PropertiesIcon,
  SomeUnitsIcon,
  TenantsIcon,
  VendorsIcon
} from "assets/icons";
import type { ImageButtonProps } from "DLUI/form";
import AppStrings from "locale/keys";
import type { PaymentMethod } from "@doorloop/dto";
import { SelectBillsType, SelectPropertiesType, SelectVendorsType } from "@doorloop/dto";

export interface IBillsSummary {
  amountPaid: number;
  countEpays: number;
  countChecks: number;
  numberOfPayments: number;
  vendorsSet: Set<string>;
  propertiesSet: Set<string>;
}

export interface IBillsSummaryWithStats extends IBillsSummary {
  propertiesSet: Set<string>;
  accountIdOfFirstCheck?: string;
}

export interface IBillCtaDialogProps {
  summary: IBillsSummaryWithStats;
  hideCloseBtn?: boolean;
  batch?: string;
  onDismiss?: () => void;
  accountId?: string;
  paymentMethod?: PaymentMethod;
}

export const payBillsWizardSteps = [
  AppStrings.Bills.WizardSteps.Bills,
  AppStrings.Bills.WizardSteps.Properties,
  AppStrings.Bills.WizardSteps.Payments
];

export enum SelectUnitsType {
  AllUnits = "AllUnits",
  SomeUnits = "SomeUnits"
}

export enum SelectTenantsType {
  AllTenants = "AllTenants",
  SomeTenants = "SomeTenants"
}

export const whichPropertiesItems: ImageButtonProps[] = [
  {
    label: AppStrings.Bills.PayBills.Screen.AllProperties,
    icon: PropertiesIcon,
    isSelected: true,
    value: SelectPropertiesType.ALL_PROPERTIES,
    itemIndex: 0
  },
  {
    label: AppStrings.Bills.PayBills.Screen.SomeProperties,
    icon: MenuPropertiesIcon,
    isSelected: false,
    value: SelectPropertiesType.SOME_PROPERTIES,
    itemIndex: 1
  }
];

export const whichPropertiesItemsAnnouncement = [
  ...whichPropertiesItems,
  {
    label: AppStrings.Users.NewUser.PropertyGroups,
    icon: GroupIcon,
    onPress: () => {},
    isSelected: false,
    value: SelectPropertiesType.PORTFOLIO,
    itemIndex: 2
  }
];

export const whichUnitsItems: ImageButtonProps[] = [
  {
    label: AppStrings.Announcements.allUnits,
    icon: AllUnitsIcon,
    onPress: () => {},
    isSelected: true,
    value: SelectUnitsType.AllUnits,
    itemIndex: 0
  },
  {
    label: AppStrings.Announcements.someUnits,
    icon: SomeUnitsIcon,
    onPress: () => {},
    isSelected: false,
    value: SelectUnitsType.SomeUnits,
    itemIndex: 1
  }
];

export const whichTenantsItems: ImageButtonProps[] = [
  {
    label: AppStrings.Announcements.allTenants,
    icon: PeopleIcon,
    onPress: () => {},
    isSelected: true,
    value: SelectTenantsType.AllTenants,
    itemIndex: 0
  },
  {
    label: AppStrings.Announcements.someTenants,
    icon: TenantsIcon,
    onPress: () => {},
    isSelected: false,
    value: SelectTenantsType.SomeTenants,
    itemIndex: 1
  }
];

export const whichVendorsItems: ImageButtonProps[] = [
  {
    label: AppStrings.Bills.PayBills.Screen.AllVendors,
    icon: OccupiedIcon,
    onPress: () => {},
    isSelected: true,
    value: SelectVendorsType.AllVendors,
    itemIndex: 0
  },
  {
    label: AppStrings.Bills.PayBills.Screen.SomeVendors,
    icon: VendorsIcon,
    onPress: () => {},
    isSelected: false,
    value: SelectVendorsType.SomeVendors,
    itemIndex: 1
  }
];
export const whichBillsItems: ImageButtonProps[] = [
  {
    label: AppStrings.Bills.PayBills.Screen.AllBills,
    icon: AllBillsIcon,
    onPress: () => {},
    isSelected: true,
    value: SelectBillsType.AllBills,
    itemIndex: 0
  },
  {
    label: AppStrings.Bills.PayBills.Screen.SomeBills,
    icon: BillGrayIcon,
    onPress: () => {},
    isSelected: false,
    value: SelectBillsType.SomeBills,
    itemIndex: 1
  }
];
