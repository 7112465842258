import React, { useMemo } from "react";
import Screen from "DLUI/screen";
import { useScreenFilterObject } from "@/hooks/useScreenFilterObject";
import { useTypedTranslation } from "locale";
import { getOutgoingPaymentsScreenTabItems } from "screens/outgoingPayments/outgoingPaymentsTabItems";
import { TabsComponent } from "DLUI/tabs";
import { OutgoingPaymentsScreenHeader } from "screens/outgoingPayments/outgoingPaymentsScreenHeader";

export const OutgoingPaymentsScreen = () => {
  const { t } = useTypedTranslation();
  const { screenProps } = useScreenFilterObject();

  const tabItems = useMemo(() => getOutgoingPaymentsScreenTabItems({ t }), []);

  return (
    <Screen
      requireAuth
      showBackButton={false}
      detailsScreenInstance
      detailsScreenInstanceShowRenderDialogProvider
      {...screenProps}
    >
      <TabsComponent tabsItems={tabItems} renderScreenHeader={OutgoingPaymentsScreenHeader} />
    </Screen>
  );
};
