import { TaskViewObjectAccess } from "@doorloop/dto";
import { ObjectPermission, SettingPermission } from "@doorloop/dto";
import { SettingsAccessEnum } from "@doorloop/dto";
import { makePermissionKey } from "screens/settings/userRoles/clearanceTypes";

type NonBooleanGrantValue = Array<{
  permissions: string[];
  grantValue: TaskViewObjectAccess | SettingsAccessEnum;
  denyValue: TaskViewObjectAccess | SettingsAccessEnum;
}>;

const nonBooleanGrantValueMap: NonBooleanGrantValue = [
  {
    permissions: [
      makePermissionKey(ObjectPermission.tasks, "viewList"),
      makePermissionKey(ObjectPermission.tasks, "viewOne"),
      makePermissionKey(ObjectPermission.workOrders, "viewList"),
      makePermissionKey(ObjectPermission.workOrders, "viewOne"),
      makePermissionKey(ObjectPermission.ownerRequests, "viewOne"),
      makePermissionKey(ObjectPermission.ownerRequests, "viewList"),
      makePermissionKey(ObjectPermission.tenantRequests, "viewOne"),
      makePermissionKey(ObjectPermission.tenantRequests, "viewList")
    ],
    grantValue: TaskViewObjectAccess.ALL,
    denyValue: TaskViewObjectAccess.NONE
  },
  {
    permissions: [
      SettingPermission.companyInformation,
      SettingPermission.regionAndCurrency,
      SettingPermission.defaultAccounts,
      SettingPermission.doorloopSubscription,
      SettingPermission.paymentAllocations,
      SettingPermission.tenantPortal,
      SettingPermission.rentReminders,
      SettingPermission.paymentInstructions,
      SettingPermission.tenantRequests,
      SettingPermission.lateFees,
      SettingPermission.rentalApplications
    ],
    grantValue: SettingsAccessEnum.EDIT,
    denyValue: SettingsAccessEnum.NONE
  },
  {
    permissions: [
      makePermissionKey(ObjectPermission.conversations, "viewList"),
      makePermissionKey(ObjectPermission.conversations, "viewOne")
    ],
    grantValue: TaskViewObjectAccess.ALL,
    denyValue: TaskViewObjectAccess.NONE
  }
];

export const getGrantValue = (permission: string) =>
  nonBooleanGrantValueMap.find((set) => set.permissions.includes(permission))?.grantValue || true;

export const getDenyValue = (permission: string) =>
  nonBooleanGrantValueMap.find((set) => set.permissions.includes(permission))?.denyValue || false;
