import type { DeepKeys, ManagementFeesCompanySettingsDto, ManagementFeesPropertySettingsDto } from "@doorloop/dto";
import { DataCy, ManagementFeeRuleDto } from "@doorloop/dto";
import { type FieldArrayRenderProps, getIn, useFormikContext } from "formik";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import { AddIcon } from "@/assets";
import { useManagementFeesStyles } from "screens/settings/managementFees/useStyles";
import { ManagementFeeItemList } from "screens/settings/managementFees/managementFeeItemList";
import { useState } from "react";
import { ManagementFeeItemFormMobileDialog } from "screens/settings/managementFees/formElements/managementFeeItemFormMobileDialog";
import { v4 as uuid } from "uuid";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { useStyles } from "DLUI/dialogs/settings/common/settings/styles";
import isNil from "lodash/isNil";

export interface ManagementFeeRulesListProps {
  arrayHelpers: FieldArrayRenderProps;
  feeRulesPath: DeepKeys<ManagementFeesCompanySettingsDto | ManagementFeesPropertySettingsDto>;
}

export const ManagementFeeRulesList = ({ arrayHelpers, feeRulesPath }: ManagementFeeRulesListProps) => {
  const { values } = useFormikContext();
  const { isMobile } = useResponsiveHelper();

  const [isMobileFormOpen, setIsMobileFormOpen] = useState<boolean>(false);
  const [newAddedRuleKey, setNewAddedRuleKey] = useState<string>();

  const classes = useManagementFeesStyles();
  const settingsClasses = useStyles();
  const feeRules: ManagementFeeRuleDto[] = getIn(values, feeRulesPath) || [];
  const isFeeRulesExist = feeRules.length > 0;

  const handleAddRuleClick = (arrayHelpers: FieldArrayRenderProps) => {
    const uniqueKey = uuid();
    setNewAddedRuleKey(uniqueKey);
    arrayHelpers.push(new ManagementFeeRuleDto({ uniqueKey }));
    setIsMobileFormOpen(true);
  };

  return (
    <div>
      <ManagementFeeItemFormMobileDialog
        rulesPath={feeRulesPath}
        mode={"add"}
        arrayHelpers={arrayHelpers}
        index={feeRules.length - 1}
        isOpen={isMobileFormOpen}
        handleStateChange={setIsMobileFormOpen}
      />
      <div className={classes.columnContainer} data-cy={DataCy.globalSettings.managementFees.rules.container}>
        {feeRules.map((rule, index) => (
          <ManagementFeeItemList
            key={rule._id ?? rule.uniqueKey}
            arrayHelpers={arrayHelpers}
            feeRulesPath={feeRulesPath}
            index={index}
            isNewRuleAdded={!isNil(newAddedRuleKey) && rule.uniqueKey === newAddedRuleKey}
            setNewAddedChargeKey={setNewAddedRuleKey}
          />
        ))}
      </div>
      <DLButton
        onClick={() => handleAddRuleClick(arrayHelpers)}
        actionText={
          isFeeRulesExist
            ? "settings.generalSettings.managementFees.addAnotherFee"
            : "settings.generalSettings.managementFees.addFee"
        }
        variant={isFeeRulesExist ? DLButtonVariantsEnum.TEXT : DLButtonVariantsEnum.CONTAINED}
        color={DLButtonColorsEnum.SECONDARY}
        size={isMobile ? DLButtonSizesEnum.LARGE : DLButtonSizesEnum.SMALL}
        disableMobileCollapse
        dataCy={DataCy.globalSettings.managementFees.rules.newRuleButton}
        icons={{ start: { src: AddIcon } }}
        containerClassName={isFeeRulesExist ? settingsClasses.addAnotherElement : settingsClasses.addElement}
      />
    </div>
  );
};
