import { ListItemContainerMaxWidth } from "DLUI/infiniteList/utils";
import React, { useState } from "react";
import type { NoteDtoDisplay } from "@doorloop/dto";
import { AnimateMarginTop } from "DLUI/animatableView";
import { View } from "DLUI/view";
import { NavigationManager } from "utils/navigation";
import { StickyHeader } from "DLUI/stickyHeader";
import SearchResultsListItemWrapper from "DLUI/screen/globalSearch/searchResultsListItemWrapper";
import NoteListItem from "shared/notes/noteListItem";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import type { ResultsSearchTabComponentProps } from "DLUI/screen/globalSearch/types";
import { globalSearchContstants } from "DLUI/screen/globalSearch/globalSearch.constants";
import { useTimeout } from "../../../../hooks/useTimeout";

const GlobalSearchNotesTab: React.FC<ResultsSearchTabComponentProps<NoteDtoDisplay>> = ({
  listItems,
  handleItemClicked
}: ResultsSearchTabComponentProps<NoteDtoDisplay>) => {
  const [shouldRender, setShouldRender] = useState(false);
  const { isMobile } = useResponsiveHelper();

  useTimeout(() => {
    setShouldRender(true);
  }, globalSearchContstants.tabsDisplayDelay);

  const items = listItems.map((currentItem: NoteDtoDisplay, index) => {
    const didPressNoteItem = (noteDetails: NoteDtoDisplay) => {
      handleItemClicked("Notes");

      setTimeout(() => {
        NavigationManager.viewNoteDetails(location.pathname, noteDetails);
      }, globalSearchContstants.resultsItemHideDelay);
    };

    return (
      <SearchResultsListItemWrapper key={"OSI" + index}>
        <NoteListItem itemData={currentItem} onPressEditNote={didPressNoteItem} disableListItemOptions />
      </SearchResultsListItemWrapper>
    );
  });

  return (
    <View
      alignItems={"center"}
      overflow={"scroll"}
      height={`calc(100vh - ${globalSearchContstants.searchPanelHeight}px)`}
    >
      {shouldRender ? (
        <View maxWidth={ListItemContainerMaxWidth} style={{ display: "block" }}>
          {!isMobile && <StickyHeader id={"searchNotesTab"} shouldShow uniqueKey={"searchNotesTab"} marginTop={20} />}
          {isMobile && <AnimateMarginTop marginTop={20} />}
          <View noWrap id={"searchNotesTab"}>
            {items}
            <View height={60} />
          </View>
        </View>
      ) : (
        <div />
      )}
    </View>
  );
};

export default GlobalSearchNotesTab;
