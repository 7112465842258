import React from "react";
import type { FormikProps } from "formik";
import { FastField } from "formik";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import Text from "DLUI/text";
import { FormikDatePicker } from "DLUI/form";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";

import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";

interface ComponentProps {
  formikRef: FormikProps<any>;
}

const MenegmentAgreement: React.FC<ComponentProps> = ({ formikRef }: ComponentProps) => {
  const { isTabletOrMobile } = useResponsiveHelper();
  const paddingRight = isTabletOrMobile ? 0 : 20;
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <View flex={1}>
        <Text value={AppStrings.Owners.NewOwner.MenegmentAgreementDescription} color={"black"} fontSize={16} />
        <View flexDirection={"row"}>
          <Grid item lg={6} xs={12} sm={12} md={6}>
            <FastField
              component={FormikDatePicker}
              label={AppStrings.Owners.NewOwner.AgreementStartDate}
              name={`managementStartDate`}
              uniqueKey={"managementStartDate"}
              noMargin
              marginTop={20}
              paddingRight={paddingRight}
            />
          </Grid>
          <Grid item lg={6} xs={12} sm={12} md={6}>
            <FastField
              component={FormikDatePicker}
              label={AppStrings.Owners.NewOwner.AgreementEndDate}
              name={`managementEndDate`}
              uniqueKey={"managementEndDate"}
              noMargin
              marginTop={20}
            />
          </Grid>
        </View>
      </View>
    </MuiPickersUtilsProvider>
  );
};

export default MenegmentAgreement;
