import type { CSSProperties, FC, PropsWithChildren } from "react";
import React from "react";
import type { TooltipProps } from "@material-ui/core";
import { Button, Tooltip as MaterialUITooltip } from "@material-ui/core";
import type { FillColors } from "DLUI/icon";
import { Icon } from "DLUI/icon";
import { useTranslation } from "react-i18next";
import type { Alignment, TranslationObject } from "DLUI/text/types";
import type { SVGIconComponent } from "assets/icons";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { withStyles } from "@material-ui/core/styles";
import type { TextColor, WhiteSpaceProps } from "DLUI/text/text";

export interface TextTooltipProps extends Partial<TranslationObject> {
  IconSource?: SVGIconComponent;
  rawText?: boolean;
  rectFill?: FillColors;
  pathColor?: FillColors;
  placement?: TooltipProps["placement"];
  show?: boolean;
  customIconOnLeft?: SVGIconComponent;
  useButton?: boolean;
  enterDelay?: number;
  leaveDelay?: number;
  leaveTouchDelay?: number;
  useDark?: boolean;
  className?: string;
  containerStyle?: CSSProperties;
  maxWidth?: number;
  fontSize?: number;
  textAlign?: Alignment;
  textWhiteSpace?: WhiteSpaceProps;
  buttonMinWidth?: string;
  textColor?: TextColor;
}

const LightTooltip = withStyles((theme) => {
  return {
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1))",
      fontSize: 11,
      padding: "10px",
      maxWidth: "unset"
    },
    arrow: {
      color: "#fff"
    }
  };
})(MaterialUITooltip);

const DarkTooltip = withStyles(() => {
  return {
    tooltip: {
      backgroundColor: "#182C4C",
      borderRadius: 5,
      color: "rgba(0, 0, 0, 0.87)",
      filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1))",
      fontSize: 11,
      padding: "10px 16px",
      maxWidth: "unset"
    },
    arrow: {
      color: "#182C4C"
    }
  };
})(MaterialUITooltip);

export const TextTooltip: FC<TextTooltipProps> = ({
  value,
  replaceObject,
  IconSource,
  rawText,
  children,
  rectFill,
  pathColor,
  placement = "top",
  show = true,
  customIconOnLeft = undefined,
  useButton = true,
  enterDelay = 0,
  useDark,
  className,
  containerStyle,
  maxWidth,
  fontSize = 14,
  textAlign,
  textWhiteSpace,
  buttonMinWidth = "auto",
  textColor,
  leaveDelay = 0,
  leaveTouchDelay = 2500
}: PropsWithChildren<TextTooltipProps>) => {
  const { t } = useTranslation();
  const TooltipComponent = useDark ? DarkTooltip : LightTooltip;

  /**
   * Conditional rendering
   * If `show` is false, we will only render the children
   */
  if (!show) {
    return <>{children}</>;
  }

  return (
    <TooltipComponent
      enterDelay={enterDelay}
      enterTouchDelay={enterDelay}
      leaveDelay={leaveDelay}
      leaveTouchDelay={leaveTouchDelay}
      title={
        <View backgroundColor={useDark ? "black" : "white"} flexDirection={"row"} maxWidth={maxWidth}>
          {value && (
            <View flexDirection={"row"} alignItems={"center"}>
              {customIconOnLeft && <Icon Source={customIconOnLeft} marginRight={7} />}
              <View flex={1} noWrap>
                <Text
                  align={textAlign}
                  whiteSpace={textWhiteSpace}
                  fontSize={fontSize}
                  color={textColor || (useDark ? "white" : undefined)}
                  lineHeight="16px"
                >
                  {rawText ? value : t(value, replaceObject).toString()}
                </Text>
              </View>
            </View>
          )}
        </View>
      }
      placement={placement}
      arrow
      className={className}
    >
      {useButton ? (
        <Button style={{ padding: 0, minWidth: buttonMinWidth, ...containerStyle }}>
          {IconSource ? <Icon rectFill={rectFill} Source={IconSource} pathColor={pathColor} /> : children}
        </Button>
      ) : (
        <div style={containerStyle}>{children}</div>
      )}
    </TooltipComponent>
  );
};
