"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenantPortalGetAllRequestsQuery = void 0;
const class_validator_1 = require("class-validator");
const getAllBaseRequest_query_1 = require("../../getAllBaseRequest.query");
const tasks_1 = require("../../tasks");
const tenantPortalRequestType_enum_1 = require("./tenantPortalRequestType.enum");
class TenantPortalGetAllRequestsQuery extends getAllBaseRequest_query_1.GetAllBaseQueryRequest {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.TenantPortalGetAllRequestsQuery = TenantPortalGetAllRequestsQuery;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], TenantPortalGetAllRequestsQuery.prototype, "lease", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsEnum)(tasks_1.TaskStatus, { each: true }),
    __metadata("design:type", Array)
], TenantPortalGetAllRequestsQuery.prototype, "filter_statuses", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(tenantPortalRequestType_enum_1.TenantRequestType),
    __metadata("design:type", String)
], TenantPortalGetAllRequestsQuery.prototype, "filter_requestType", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(tenantPortalRequestType_enum_1.TenantRequestMaintenanceCategory),
    __metadata("design:type", String)
], TenantPortalGetAllRequestsQuery.prototype, "filter_maintenanceCategory", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], TenantPortalGetAllRequestsQuery.prototype, "filter_date_from", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], TenantPortalGetAllRequestsQuery.prototype, "filter_date_to", void 0);
