import type { CSSProperties } from "react";

export type DisplayMode = "table" | "card";

interface Theme {
  backgroundColor: CSSProperties["backgroundColor"];
  neutral: {
    row: CSSProperties;
    header: CSSProperties;
  };
  checked: {
    row: CSSProperties;
    header: CSSProperties;
  };
}

const tableTheme: Theme = {
  backgroundColor: "white",
  neutral: {
    row: {
      backgroundColor: "white",
      borderBottom: "1px solid #ECEEF5"
    },
    header: { borderBottom: "2px solid #ECEEF5" }
  },
  checked: {
    row: {
      backgroundColor: "#F5F7FA",
      borderBottom: "1px solid #ECEEF5"
    },
    header: { borderBottom: "2px solid #ECEEF5" }
  }
} as const;

const cardTheme: Theme = {
  backgroundColor: "transparent",
  neutral: {
    row: {
      backgroundColor: "white",
      borderBottom: "1px solid #ECEEF5",
      borderRadius: "5px",
      border: "1px solid rgba(122, 134, 153, 0.15)"
    },
    header: {}
  },
  checked: {
    row: {
      backgroundColor: "white",
      borderBottom: "1px solid #ECEEF5",
      borderRadius: "5px",
      border: "1px solid rgba(122, 134, 153, 0.15)"
    },
    header: {}
  }
} as const;

export const EditableTableThemes: Record<DisplayMode, Theme> = {
  card: cardTheme,
  table: tableTheme
} as const;
