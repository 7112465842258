import type { CSSProperties } from "react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import type { FilledInputProps, InputLabelProps, InputProps, OutlinedInputProps } from "@material-ui/core";
import type { NumberFormatValues } from "react-number-format";
import NumberFormat from "react-number-format";
import type { FieldInputProps, FormikProps } from "formik";
import { getIn } from "formik";
import clsx from "clsx";

import type { TextFormatType } from "DLUI/text/text";
import { FieldSizes } from "DLUI/form/textField/types";
import TextInput from "DLUI/form/textField/textInput";

import { useDebounce } from "../../../../hooks/useDebounce";
import type { IRootState } from "../../../../store";

interface ComponentProps {
  id?: string;
  form: FormikProps<any>;
  field: FieldInputProps<any>;
  allowNegative?: boolean;
  decimalScale?: number;
  formatType: TextFormatType;
  required?: boolean;
  variant?: "standard" | "filled" | "outlined";
  label?: string;
  textAlign?: "right" | "left";
  onFocus?: () => void;
  onBlur: (value: string) => void;
  onChange?: (value: string) => void;
  InputProps?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps> | undefined;
  InputLabelProps?: Partial<InputLabelProps>;
  fieldSize?: FieldSizes;
  style?: CSSProperties;
  dataCy?: string;
  disabled?: boolean;
  format?: string;
  warning?: boolean;
  shouldDebounce?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  maxNumber?: number;
  minNumber?: number;
}

const NumberTextField: React.FC<ComponentProps> = ({
  id,
  form,
  field,
  allowNegative,
  maxNumber,
  minNumber,
  decimalScale,
  formatType,
  required,
  variant,
  label,
  textAlign,
  onFocus,
  onBlur,
  onChange,
  InputProps,
  InputLabelProps,
  dataCy,
  style,
  warning = false,
  disabled = false,
  shouldDebounce = false,
  autoFocus = false,
  fieldSize = FieldSizes.WEB,
  format,
  placeholder
}) => {
  const localeData = useSelector((state: IRootState) => state.auth.localeData);
  const { currencySymbol, decimalSeparator, currencySymbolPosition, percentageSymbol, percentSymbolPosition } =
    localeData;
  let { thousandSeparator } = localeData;

  const [renderField, setRenderField] = useState<boolean>(true);
  const [values, setValues] = useState<NumberFormatValues>({
    value: field.value,
    formattedValue: "",
    floatValue: field.value ? parseFloat(field.value) : undefined
  });

  const debouncedValues = shouldDebounce ? useDebounce(values, 300) : values;

  const errorText = getIn(form.errors, field.name);
  const touchedVal = getIn(form.touched, field.name);
  const hasError = touchedVal && errorText !== undefined;

  useEffect(() => {
    if (field.value !== values.value && field.value !== "") {
      // in case the prop changed from formik and not from the user input
      // usually caused from delete line/setFormik values -  we should refresh the field
      // to get the new update value
      refreshField();
    }
  }, [field.value]);

  const refreshField = () => {
    setRenderField(false);
    setValues({
      value: field.value,
      formattedValue: "",
      floatValue: field.value ? parseFloat(field.value) : undefined
    });
    setTimeout(() => {
      setRenderField(true);
    }, 0);
  };

  const onValueChange = (values: any) => {
    if (form && field) {
      setValues({ ...values, value: values.value === "" ? undefined : parseFloat(values.value) });
    }
  };

  useEffect(() => {
    if (debouncedValues.value !== field.value) {
      onChange?.(debouncedValues.value);
      setTimeout(() => {
        form.setFieldValue(field.name, debouncedValues.value !== undefined ? debouncedValues.floatValue : undefined);
        form.setFieldTouched(field.name);
      }, 0);
    }
  }, [debouncedValues]);

  const currencyProps = {
    decimalSeparator,
    decimalScale: decimalScale || 2,
    prefix: currencySymbolPosition === "prefix" ? currencySymbol : undefined,
    suffix: currencySymbolPosition === "suffix" ? currencySymbol : undefined,
    fixedDecimalScale: true
  };

  const numberProps = {
    decimalScale: decimalScale || 0,
    fixedDecimalScale: false
  };

  const percentageProps = {
    decimalSeparator,
    decimalScale: decimalScale || 2,
    prefix: percentSymbolPosition === "prefix" ? percentageSymbol : undefined,
    suffix: percentSymbolPosition === "suffix" ? percentageSymbol : undefined,
    fixedDecimalScale: decimalScale !== undefined ? decimalScale > 0 : true
  };

  let nextProps: any = currencyProps;

  if (formatType === "number") {
    nextProps = numberProps;
  }
  if (formatType === "plain-number") {
    nextProps = numberProps;
    thousandSeparator = "";
  }
  if (formatType === "percent") {
    nextProps = percentageProps;
  }
  if (!renderField) {
    return null;
  }

  return (
    <div className={clsx(["DLUI_NumberFormat", textAlign, fieldSize])}>
      <NumberFormat
        id={id}
        value={values.value}
        onValueChange={onValueChange}
        customInput={TextInput}
        displayType={"input"}
        style={style}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        InputProps={InputProps}
        InputLabelProps={InputLabelProps}
        variant={variant}
        required={required as boolean | undefined}
        error={hasError}
        label={label}
        allowNegative={allowNegative || false}
        maxLength={10}
        dataCy={dataCy || field.name}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        format={format}
        warning={warning}
        placeholder={placeholder}
        autoFocus={autoFocus}
        isAllowed={({ floatValue }) => {
          if (floatValue) {
            if (minNumber) {
              return floatValue >= minNumber;
            }
            if (maxNumber) {
              return floatValue <= maxNumber;
            }
          }
          return true;
        }}
        {...nextProps}
      />
    </div>
  );
};

export default NumberTextField;
