"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CashFlowPrintingStatementItem = exports.CashFlowStatementItem = void 0;
const treeListItemAccount_1 = require("../treeList/treeListItemAccount");
class CashFlowStatementItem extends treeListItemAccount_1.TreeListItemAccount {
}
exports.CashFlowStatementItem = CashFlowStatementItem;
class CashFlowPrintingStatementItem extends CashFlowStatementItem {
    constructor(init) {
        super(init);
        Object.assign(this, init);
    }
}
exports.CashFlowPrintingStatementItem = CashFlowPrintingStatementItem;
