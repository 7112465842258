import React from "react";
import { View } from "DLUI/view";
import type { VendorBillPaymentDto } from "@doorloop/dto";
import { ObjectPermission } from "@doorloop/dto";
import Text from "DLUI/text";
import AppStrings from "../../../../../locale/keys";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { useParams } from "react-router-dom";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import LinkedPaymentItem from "DLUI/dialogs/vendor/bill/linkedPaymentItem";
import { StickyHeader } from "DLUI/stickyHeader";

import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";

interface Props {
  vendorBillPayment: VendorBillPaymentDto[];
}

const PaymentsList: React.FC<Props> = ({ vendorBillPayment }: Props) => {
  const { transactionId } = useParams<any>();
  const { isScreenSizeIn } = useResponsiveHelper();
  const hideHeader = isScreenSizeIn(["xs", "sm"]);
  const hasPermission: AnyPermissionClearance = {
    permission: ObjectPermission.vendorBillPayments,
    field: "viewList"
  };

  if (vendorBillPayment.length > 0 && hasPermission) {
    return (
      <View>
        <HorizontalSeparationLine marginTop={20} height={1} />
        <Text value={AppStrings.Bills.WizardSteps.Payments} marginTop={20} marginBottom={20} fontSize={18} bold />
        {!hideHeader && (
          <StickyHeader id={"paymentsListHeader"} shouldShow uniqueKey={"paymentsListHeader"} marginTop={20} />
        )}
        {vendorBillPayment.map(
          ({ payFromAccount, date, amountPaid, reference, linkedBills, id, paymentMethod }, index) =>
            linkedBills
              ?.filter((x) => x.linkedTransaction === transactionId)
              .map((currentItem) => (
                <LinkedPaymentItem
                  key={index}
                  accountId={currentItem.account || ""}
                  transactionDate={currentItem.linkedTransactionDate || date || ""}
                  headerId={"paymentsListHeader"}
                  amount={currentItem.amount}
                  reference={currentItem.linkedTransactionReference || reference}
                  transactionId={id}
                  paymentMethod={paymentMethod}
                />
              ))
        )}
      </View>
    );
  }
  return null;
};

export default PaymentsList;
