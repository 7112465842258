import { CompanyGlobalSettingsLateFeesDialog, CustomAllocationsDialog, EditTagsDialog } from "DLUI/dialogs";
import AppStrings from "locale/keys";
import { LoginAndPassword, PropertyGroupsDialog, RentRemindersDialog } from "screens/settings";
import DefaultAccounts from "screens/settings/defaultAccounts/defaultAccounts";
import EditPaymentInstructions from "screens/settings/paymentInstructions/paymentInstructions";
import RegionAndCurrency from "screens/settings/regionAndCurrency/regionAndCurrency";
import { DialogRoutes } from "./dialogsRoutes";
import type { DialogProps } from "./dialogsProvider.types";
import QuickbooksSyncFormikWrapper from "screens/settings/quickbooksSync/quickbooksSyncFormikWrapper";
import ListingSyndicationFormikContextWrapper from "screens/settings/listingSyndication/listingSyndicationFormikContextWrapper";
import ManagementFeesFormikContextWrapper from "screens/settings/managementFees/managementFeesFormikContextWrapper";
import TenantPortalLeaseDialog from "screens/settings/tenantPortal/tenantPortalLeaseDialog";
import { OutgoingPaymentsSettingsDialogLauncher } from "screens/settings/outgoingPayments/outgoingPaymentsSettingsDialogLauncher";
import ApiKeysDialog from "screens/settings/apiKeys/apiKeysDialog";
import CompanyStartDateFormikWrapper from "screens/settings/companyStartDate/companyStartDateFormikWrapper";
import EditPaymentAllocations from "DLUI/dialogs/settings/properties/editPaymentAllocations/editPaymentAllocations";
import { TenantRequestsSettings } from "screens/settings/requestsSettings/tenantRequestsSettings";
import { OwnerRequestsSettings } from "screens/settings/requestsSettings/ownerRequestsSettings";
import { EditConvenienceFees } from "DLUI/dialogs/settings/companyGlobalSettings/editConvenienceFees";

class SettingsDialogsProvider {
  matchRoutes(pathname: string): DialogProps | undefined {
    if (pathname.indexOf(DialogRoutes.EDIT_PERSONAL_INFORMATION) > -1) {
      return {
        type: "editPersonalInformation",
        title: AppStrings.Tenants.NewTenant.PersonalInfo
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_LOGIN_AND_PASSWORD) > -1) {
      return {
        type: "editLoginAndPassword",
        title: AppStrings.Settings.GeneralSettings.LoginAndPasswordSettingsTitle,
        Content: LoginAndPassword
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_COMPANY_INFORMATION) > -1) {
      return {
        type: "editCompanyInformation",
        title: AppStrings.Settings.GeneralSettings.CompanyInfo.CompanyInfo
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_RENTAL_APPLICATIONS_SETTINGS) > -1) {
      return {
        type: "editRentalApplications",
        title: AppStrings.Properties.Settings.RentalApplicationsTitle
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_REGION_AND_CURRENCY) > -1) {
      return {
        title: AppStrings.Settings.GeneralSettings.RegionAndCurrencyTitle,
        Content: RegionAndCurrency
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_DEFAULT_ACCOUNTS) > -1) {
      return {
        title: AppStrings.Settings.GeneralSettings.DefaultAccountsTitle,
        Content: DefaultAccounts
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_TASK_CATEGORIES) > -1) {
      return {
        title: AppStrings.Settings.GeneralSettings.TaskCategoriesTitle,
        Content: EditTagsDialog
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_NOTE_CATEGORIES) > -1) {
      return {
        title: AppStrings.Settings.GeneralSettings.NoteCategoriesTitle,
        Content: EditTagsDialog
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_FILE_CATEGORIES) > -1) {
      return {
        title: AppStrings.Settings.GeneralSettings.FileCategoriesTitle,
        Content: EditTagsDialog
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_VENDOR_CATEGORIES) > -1) {
      return {
        title: AppStrings.Settings.GeneralSettings.VendorClassificationsTitle,
        Content: EditTagsDialog
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_LEAD_SOURCE) > -1) {
      return {
        title: AppStrings.Settings.GeneralSettings.LeadSourcesTitle,
        Content: EditTagsDialog
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_PORTFOLIOS) > -1) {
      return {
        title: AppStrings.Settings.GeneralSettings.PropertyGroupsTitle,
        Content: PropertyGroupsDialog
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_OUTGOING_PAYMENTS) > -1) {
      return {
        title: AppStrings.Common.OutgoingPayments.OutgoingPayments,
        Content: OutgoingPaymentsSettingsDialogLauncher
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_CUSTOM_ALLOCATIONS) > -1) {
      return {
        title: AppStrings.Settings.GeneralSettings.CustomAllocations.CustomAllocationsTitle,
        Content: CustomAllocationsDialog
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_LATE_FEES) > -1) {
      return {
        title: AppStrings.Properties.Settings.LateFeesTitle,
        Content: CompanyGlobalSettingsLateFeesDialog
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_TENANT_PORTAL) > -1) {
      return {
        type: "editTenantPortal",
        title: AppStrings.Common.TenantPortalSettingsSections.TenantPortalSettings
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_OWNER_PORTAL) > -1) {
      return {
        type: "editOwnerPortal",
        title: AppStrings.Owners.Screen.OwnerPortal
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_LEASE_TENANT_PORTAL) > -1) {
      return {
        title: AppStrings.Common.TenantPortalSettingsSections.TenantPortalSettings,
        Content: TenantPortalLeaseDialog
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_RENT_REMINDERS) > -1) {
      return {
        title: AppStrings.Common.RentRemindersTitle,
        Content: RentRemindersDialog
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_PAYMENT_INSTRUCTIONS) > -1) {
      return {
        title: AppStrings.Common.PaymentInstructions,
        Content: EditPaymentInstructions
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_TENANT_REQUEST_NOTIFICATIONS) > -1) {
      return {
        title: AppStrings.Common.TenantRequestsSettingItemTitle,
        Content: TenantRequestsSettings
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_OWNER_REQUEST_NOTIFICATIONS) > -1) {
      return {
        title: AppStrings.Common.OwnerRequestsSettingItemTitle,
        Content: OwnerRequestsSettings
      };
    }

    if (pathname.indexOf(DialogRoutes.SYNC_QUICKBOOKS_ACCOUNT) > -1) {
      return {
        title: AppStrings.Settings.GeneralSettings.QuickbooksIntegrationTitle,
        Content: QuickbooksSyncFormikWrapper
      };
    }
    if (pathname.indexOf(DialogRoutes.LISTING_SETTINGS) > -1) {
      return {
        title: AppStrings.Listing.ListingSyncOptions,
        Content: ListingSyndicationFormikContextWrapper
      };
    }
    if (pathname.indexOf(DialogRoutes.API_KEYS) > -1) {
      return {
        title: AppStrings.Settings.GeneralSettings.ApiKeys.ApiKeysTitle,
        Content: ApiKeysDialog
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_MANAGEMENT_FEES) > -1) {
      return {
        title: AppStrings.Settings.GeneralSettings.ManagementFees.ManagementFees,
        Content: ManagementFeesFormikContextWrapper
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_CONVENIENCE_FEES_SETTINGS) > -1) {
      return {
        Content: EditConvenienceFees,
        title: AppStrings.Settings.GeneralSettings.ConvenienceFees.SettingsTitle
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_PAYMENT_ALLOCATIONS) > -1) {
      return {
        Content: EditPaymentAllocations,
        title: AppStrings.Settings.GeneralSettings.PaymentAllocation.PaymentAllocationSettingsTitle
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_COMPANY_START_DATE) > -1) {
      return {
        title: AppStrings.Settings.GeneralSettings.CompanyStartDate.Dialog.DoorloopStartDate,
        Content: CompanyStartDateFormikWrapper
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_COMMUNICATION_CENTER_SETTINGS) > -1) {
      return {
        type: "editCommunicationCenter",
        title: AppStrings.Settings.GeneralSettings.CommunicationCenterTitle
      };
    }

    return undefined;
  }
}

export const settingsDialogsProvider = new SettingsDialogsProvider();
