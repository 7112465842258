"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyndicationV2PropertyType = void 0;
const syndicationIdentifiable_1 = require("../syndicationIdentifiable");
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const syndicationInformation_1 = require("../syndicationInformation");
const syndicationFloorplan_1 = require("../syndicationFloorplan");
const syndicationGeneralAmenityType_1 = require("../syndicationGeneralAmenityType");
const syndicationV2ILS_Unit_1 = require("./syndicationV2ILS_Unit");
const syndicationPolicy_1 = require("../syndicationPolicy");
const syndicationILS_Identification_1 = require("../syndicationILS_Identification");
const syndicationDepositType_1 = require("../syndicationDepositType");
const syndicationFileType_1 = require("../syndicationFileType");
const syndicationV2PropertyID_1 = require("./syndicationV2PropertyID");
const syndicationFee_1 = require("../syndicationFee");
class SyndicationV2PropertyType extends syndicationIdentifiable_1.SyndicationIdentifiable {
}
exports.SyndicationV2PropertyType = SyndicationV2PropertyType;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => syndicationV2PropertyID_1.SyndicationV2PropertyID),
    __metadata("design:type", syndicationV2PropertyID_1.SyndicationV2PropertyID)
], SyndicationV2PropertyType.prototype, "PropertyID", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => syndicationILS_Identification_1.SyndicationILS_Identification),
    __metadata("design:type", syndicationILS_Identification_1.SyndicationILS_Identification)
], SyndicationV2PropertyType.prototype, "ILS_Identification", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ArrayMaxSize)(10),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => syndicationIdentifiable_1.SyndicationIdentifiable),
    __metadata("design:type", Array)
], SyndicationV2PropertyType.prototype, "General_ID", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => syndicationInformation_1.SyndicationInformation),
    __metadata("design:type", syndicationInformation_1.SyndicationInformation)
], SyndicationV2PropertyType.prototype, "Information", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => syndicationV2ILS_Unit_1.SyndicationV2ILS_Unit),
    __metadata("design:type", Array)
], SyndicationV2PropertyType.prototype, "ILS_Unit", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => syndicationDepositType_1.SyndicationDepositType),
    __metadata("design:type", Array)
], SyndicationV2PropertyType.prototype, "Deposit", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => syndicationFloorplan_1.SyndicationFloorplan),
    __metadata("design:type", Array)
], SyndicationV2PropertyType.prototype, "Floorplan", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => syndicationGeneralAmenityType_1.SyndicationGeneralAmenityType),
    __metadata("design:type", Array)
], SyndicationV2PropertyType.prototype, "Amenity", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => syndicationFee_1.SyndicationFee),
    __metadata("design:type", Array)
], SyndicationV2PropertyType.prototype, "Fee", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => syndicationPolicy_1.SyndicationPolicy),
    __metadata("design:type", syndicationPolicy_1.SyndicationPolicy)
], SyndicationV2PropertyType.prototype, "Policy", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => syndicationFileType_1.SyndicationFileType),
    __metadata("design:type", Array)
], SyndicationV2PropertyType.prototype, "File", void 0);
