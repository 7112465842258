import AppStrings from "../../../../locale/keys";
import Routes from "../../../appRouter/routes";
import { DataCy, ObjectPermission } from "@doorloop/dto";
import { MainMenuLayersKeys } from "DLUI/drawer/items/mainMenuItems";
import { v4 as uuid } from "uuid";
import type { DrawerItemType, NavConfigProps } from "DLUI/drawer/types";
import { DrawerItemName, NavLevels } from "DLUI/drawer/types";

const activeLeasesMenuItem: DrawerItemType = {
  label: AppStrings.DrawerItems.ActiveLeases,
  navKey: uuid(),
  navPath: [{ name: Routes.ACTIVE_LEASES }],
  dataCy: DataCy.sideMenu.leasing.activeLeases,
  clearance: [
    {
      permission: ObjectPermission.leases,
      field: "viewList"
    }
  ]
};
const inActiveLeasesMenuItem: DrawerItemType = {
  label: AppStrings.DrawerItems.InActiveLeases,
  navKey: uuid(),
  navPath: [{ name: Routes.INACTIVE_LEASES }],
  clearance: [
    {
      permission: ObjectPermission.leases,
      field: "viewList"
    }
  ]
};
export const SubMenuLeasingLayers: NavConfigProps = {
  title: AppStrings.DrawerItems.Leasing,
  level: NavLevels.SUB_MENU,
  subMenuLayerKey: DrawerItemName.Leasing,
  groups: [
    {
      groupName: AppStrings.Common.Application,
      parentNavigationKey: MainMenuLayersKeys.Leasing,
      items: [
        {
          label: AppStrings.DrawerItems.RentalApplications,
          navKey: uuid(),
          inactiveTag: { featureName: "RentalApplication", permission: ObjectPermission.rentalApplications },
          navPath: [{ name: Routes.RENTAL_APPLICATIONS }],
          dataCy: DataCy.sideMenu.leasing.rentalApplications,
          clearance: [
            {
              permission: ObjectPermission.rentalApplications,
              field: "viewList"
            }
          ]
        }
      ]
    },
    {
      groupName: AppStrings.Common.AllLeases,
      parentNavigationKey: MainMenuLayersKeys.Leasing,
      items: [activeLeasesMenuItem, inActiveLeasesMenuItem]
    },
    {
      groupName: AppStrings.Common.Enums.TaskStatus.IN_PROGRESS,
      parentNavigationKey: MainMenuLayersKeys.Leasing,
      items: [
        {
          label: AppStrings.DrawerItems.LeaseRenewals,
          navKey: uuid(),
          navPath: [
            { name: Routes.LEASE_RENEWALS, queryParams: { sort_by: "end" } },
            { name: "end-lease", matchMethod: "anyMatch" },
            { name: "renew", matchMethod: "anyMatch" }
          ],
          dataCy: DataCy.sideMenu.leasing.leaseRenewals,
          clearance: [
            {
              permission: ObjectPermission.leases,
              field: "viewList"
            }
          ]
        },
        {
          label: AppStrings.DrawerItems.DraftLeases,
          navKey: uuid(),
          navPath: [{ name: Routes.DRAFT_LEASES }],
          dataCy: DataCy.sideMenu.leasing.draftLeases,
          clearance: [
            {
              permission: ObjectPermission.leases,
              field: "viewList"
            }
          ]
        }
      ]
    }
  ]
};
const propertiesMenuItem: DrawerItemType = {
  label: AppStrings.DrawerItems.Properties,
  navPath: [{ name: Routes.PROPERTIES }],
  navKey: uuid(),
  dataCy: DataCy.sideMenu.rentals.properties,
  clearance: [
    {
      permission: ObjectPermission.properties,
      field: "viewList"
    }
  ]
};
const unitsMenuItem: DrawerItemType = {
  label: AppStrings.DrawerItems.Units,
  navPath: [{ name: Routes.UNITS }],
  navKey: uuid(),
  dataCy: DataCy.sideMenu.rentals.units,
  clearance: [
    {
      permission: ObjectPermission.units,
      field: "viewList"
    }
  ]
};
export const SubMenuRentalsLayers: NavConfigProps = {
  title: AppStrings.DrawerItems.Rentals,
  level: NavLevels.SUB_MENU,
  subMenuLayerKey: DrawerItemName.Rentals,
  groups: [
    {
      groupName: AppStrings.DrawerItems.RealEstate,
      parentNavigationKey: MainMenuLayersKeys.Rentals,
      items: [propertiesMenuItem, unitsMenuItem]
    }
  ]
};
export const SubMenuHOAUserRentalsLayers: NavConfigProps = {
  title: AppStrings.DrawerItems.Rentals,
  level: NavLevels.SUB_MENU,
  subMenuLayerKey: DrawerItemName.Rentals,
  groups: [
    {
      groupName: AppStrings.DrawerItems.RealEstate,
      parentNavigationKey: MainMenuLayersKeys.Rentals,
      items: [propertiesMenuItem, unitsMenuItem, activeLeasesMenuItem]
    }
  ]
};
