import type { RentalApplicationCompanySettingsDto } from "@doorloop/dto";
import { RentalApplicationAdditionalQuestionSettingDto, RentalApplicationAdditionalQuestionType } from "@doorloop/dto";
import { View } from "DLUI/view";
import { FastField, getIn, useFormikContext } from "formik";
import React, { Fragment, useMemo, useState } from "react";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { FormikSwitchButton, RadioGroup } from "DLUI/form";
import { useTranslation } from "react-i18next";
import AdditionalInformationQuestions from "./additionalInformationQuestions";
import { SeparationLine } from "DLUI/separatorView";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { RichTextField } from "DLUI/form/richTextField/richTextField";

const AdditionalInformation: React.FC<{}> = () => {
  const { t } = useTranslation();
  const {
    values: { additionalInfo },
    setFieldValue
  } = useFormikContext<RentalApplicationCompanySettingsDto>();

  const [showContent, setShowContent] = useState<boolean>(Boolean(getIn(additionalInfo, "isShown")) || false);
  const { propertyId } = useParams<any>();
  const [renderForm, setRenderForm] = useState<boolean>(true);

  const additionalInfoSelectionDefaultValue = useMemo(() => {
    const emptyObject = _.values(additionalInfo).every((x) => x === undefined);
    if (emptyObject) {
      return "companyDefault";
    }
    return "specifyAdditionalInfo";
  }, []);

  const didChangeShowContentSelection = (value: boolean) => {
    setShowContent(value);

    if (!value) {
      setFieldValue("additionalInfo.additionalInfoQuestions", []);
    } else {
      const rentalApplicationAdditionalQuestionSetting = new RentalApplicationAdditionalQuestionSettingDto();
      rentalApplicationAdditionalQuestionSetting.questionType = RentalApplicationAdditionalQuestionType.YES_NO;
      rentalApplicationAdditionalQuestionSetting.question = "";

      setFieldValue("additionalInfo.additionalInfoQuestions", [rentalApplicationAdditionalQuestionSetting]);
    }
  };

  const [additionalInfoSelectionType, setAdditionalInfoSelectionType] = useState<
    "companyDefault" | "specifyAdditionalInfo"
  >(additionalInfoSelectionDefaultValue);

  const didChangeSpecifyEmploymentSelection = (selectedValue: string) => {
    if (selectedValue === "false") {
      setAdditionalInfoSelectionType("companyDefault");

      setTimeout(() => {
        refreshForm();
      }, 500);
    } else {
      setAdditionalInfoSelectionType("specifyAdditionalInfo");
      setFieldValue("additionalInfo.isShown", false);
    }
  };

  const refreshForm = () => {
    setRenderForm(false);
    setShowContent(false);
    setFieldValue("additionalInfo", undefined);

    setTimeout(() => {
      setRenderForm(true);
    }, 0);
  };

  const renderSpecifyRadioButtons = () => {
    if (propertyId) {
      return (
        <View>
          <RadioGroup
            didChangeSelection={didChangeSpecifyEmploymentSelection}
            uniqueKey={"FRB"}
            defaultValue={additionalInfoSelectionType === "companyDefault" ? "false" : "true"}
            marginTop={20}
            radioGroupItems={[
              {
                label: AppStrings.Common.HideSectionForProperty,
                value: "false"
              },
              {
                label: AppStrings.Common.SpecifySettingsForProperty,
                value: "true"
              }
            ]}
          />
          <SeparationLine marginTop={20} width={"100%"} height={1} />
        </View>
      );
    }
  };

  return (
    <>
      <Text
        value={AppStrings.Settings.GeneralSettings.RentalApplications.AdditionalInformationSectionDescription}
        lineHeight={"26px"}
      />
      {renderSpecifyRadioButtons()}
      <View
        shouldShow={additionalInfoSelectionType === "specifyAdditionalInfo"}
        showAnimation={"fade-in"}
        hideAnimation={"fade-out"}
      >
        {renderForm ? (
          <Fragment>
            <View height={20} />
            <FastField
              component={FormikSwitchButton}
              name={"additionalInfo.isShown"}
              label={AppStrings.Settings.GeneralSettings.RentalApplications.AskForAdditionalInformation}
              onChange={didChangeShowContentSelection}
            />
            <View shouldShow={showContent} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
              <View>
                <Text
                  value={AppStrings.Tasks.WorkOrdersScreen.Instructions}
                  fontSize={20}
                  fontWeight={700}
                  marginTop={20}
                />
                <FastField
                  component={RichTextField}
                  label={t(AppStrings.Tasks.WorkOrdersScreen.Instructions)}
                  name={`additionalInfo.instructions`}
                  styles={{ container: { marginTop: 20 } }}
                  maxLength={5000}
                  html={{ name: "additionalInfo.instructionsHTML", value: additionalInfo?.instructionsHTML }}
                />
                <AdditionalInformationQuestions />

                {/* TODO uncomment when server is ready */}
                {/* <AdditionalInformationAttachments formikRef={formikRef} /> */}
              </View>
            </View>
          </Fragment>
        ) : null}
      </View>
    </>
  );
};

export default AdditionalInformation;
