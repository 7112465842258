import { useMemo } from "react";
import type { FilterOption } from "../../../filterPanel/filterInputs/types";
import { useResponsiveHelper } from "@/contexts/responsiveContext";

export function useSideFilterOptions<TQuery>(filterOptions: Array<FilterOption<TQuery>>) {
  const { isMobile } = useResponsiveHelper();

  return useMemo(
    () => (isMobile ? filterOptions : filterOptions.filter((option) => !option.alwaysVisible)),
    [filterOptions, isMobile]
  );
}
