import { useCallback } from "react";

import _ from "lodash";
import moment from "moment";
import { useAtomCallback } from "jotai/utils";
import { useSetAtom } from "jotai";

import type {
  ConversationClientDto,
  ConversationDto,
  ConversationMessageDto,
  ConversationParticipantDto,
  ConversationPushEventDto,
  ConversationsStatsPushEventDto,
  PropertyDto,
  VendorDto
} from "@doorloop/dto";
import { ConversationLinkedToType, ConversationPushEventStatus, ConversationStatus } from "@doorloop/dto";

import { conversationsDataSourceAtom } from "screens/communicationsCenter/shared/useConversationsListDataSource";
import {
  conversationsStatsAtom,
  conversationsStatsLastDateAtom
} from "screens/communicationsCenter/shared/conversationsStatsAtom";
import { currentConversationAtom } from "screens/communicationsCenter/shared/currentConversationAtom";
import { leasesApi } from "api/leasesApi";
import { messagesListDataSourceAtom } from "screens/communicationsCenter/shared/useMessagesListDataSource";
import { propertiesApi } from "api/propertiesApi";
import { Routes } from "../../../appRouter";
import { vendorsApi } from "api/vendorsApi";

type CommunicationCenterPathKey = "communicationCenter" | "prospects" | "tenants" | "owners" | "vendors" | "leases";

const COMMUNICATION_CENTER_PATH_KEY_WITH_REGEX_MAP: Record<
  Exclude<CommunicationCenterPathKey, "communicationCenter">,
  RegExp
> = {
  prospects: /\/prospects\/([^/]+)\/communications/,
  tenants: /\/tenants\/([^/]+)\/communications/,
  owners: /\/owners\/([^/]+)\/communications/,
  vendors: /\/vendors\/([^/]+)\/communications/,
  leases: /\/leases\/active-leases\/([^/]+)\/communications/
};

const COMMUNICATION_CENTER_PATH_KEY_WITH_LINK_TO_TYPE: Record<
  Exclude<CommunicationCenterPathKey, "communicationCenter" | "leases">,
  ConversationLinkedToType
> = {
  prospects: ConversationLinkedToType.PROSPECT,
  tenants: ConversationLinkedToType.TENANT,
  owners: ConversationLinkedToType.OWNER,
  vendors: ConversationLinkedToType.OWNER
};

interface CommunicationCenterPathData {
  isInCommunicationCenterPath: boolean;
  path: string;
  pathKey: CommunicationCenterPathKey;
  urlItemId?: string;
}

const getPathMatchId = (path: string, regex: RegExp): string | null => {
  const [, match] = regex.exec(path) || [];

  return match || null;
};

const getCommunicationCenterPathData = (): CommunicationCenterPathData | null => {
  const url = new URL(location.href);
  const path = url.pathname;

  if (path.startsWith(Routes.COMMUNICATIONS_CENTER)) {
    return {
      isInCommunicationCenterPath: true,
      path,
      pathKey: "communicationCenter"
    };
  }

  for (const [key, regex] of Object.entries(COMMUNICATION_CENTER_PATH_KEY_WITH_REGEX_MAP)) {
    const pathKey = key as CommunicationCenterPathKey;

    const urlItemId = getPathMatchId(path, regex);

    if (urlItemId) {
      return {
        isInCommunicationCenterPath: true,
        path,
        pathKey,
        urlItemId
      };
    }
  }

  return null;
};

const isConversationRelatedToPath = (
  conversation: ConversationDto,
  { pathKey, urlItemId }: CommunicationCenterPathData
): boolean => {
  if (pathKey === "communicationCenter") {
    return true;
  }

  if (!urlItemId) {
    return false;
  }

  const recipient = conversation.recipient;

  if (pathKey === "leases") {
    const leasesDictionary = leasesApi.getDictionaryFromSessionStorage();

    const lease = leasesDictionary?.[urlItemId];

    if (!lease) {
      return false;
    }

    const propertyId = lease.property;

    if (!propertyId) {
      return false;
    }

    const vendorsDictionary = vendorsApi.getDictionaryFromSessionStorage();
    const vendors: Array<[string, VendorDto]> = (vendorsDictionary && Object.entries(vendorsDictionary)) || [];
    const relatedVendors = vendors
      .filter(([, vendor]) => _.isEmpty(vendor?.properties) || vendor?.properties?.includes(propertyId))
      .map(([id]) => id);

    const propertiesDictionary = propertiesApi.getDictionaryFromSessionStorage();
    const property: PropertyDto | null = propertiesDictionary?.[propertyId] || null;
    const relatedOwners = _.compact(property?.owners?.map(({ owner }) => owner) || []);

    const relatedTenants: string[] = lease.tenants;

    const possibleConversationParticipants: ConversationParticipantDto[] = [
      ...relatedVendors.map((vendor) => {
        return {
          linkedToId: vendor,
          linkedToType: ConversationLinkedToType.VENDOR
        };
      }),
      ...relatedOwners.map((owner) => {
        return {
          linkedToId: owner,
          linkedToType: ConversationLinkedToType.OWNER
        };
      }),
      ...relatedTenants.map((tenant) => {
        return {
          linkedToId: tenant,
          linkedToType: ConversationLinkedToType.TENANT
        };
      })
    ];

    return possibleConversationParticipants.some(
      (participant) =>
        participant.linkedToId === recipient.linkedToId && participant.linkedToType === recipient.linkedToType
    );
  }
  return (
    recipient.linkedToType === COMMUNICATION_CENTER_PATH_KEY_WITH_LINK_TO_TYPE[pathKey] &&
    recipient.linkedToId === urlItemId
  );
};

export const useConversationsPushEvent = () => {
  const getConversationsStatsLaseDate = useAtomCallback(
    useCallback((get) => get(conversationsStatsLastDateAtom), [])
  ) as () => Date;

  const setConversationsStats = useSetAtom(conversationsStatsAtom);

  const getCurrentConversation = useAtomCallback(
    useCallback((get) => get(currentConversationAtom), [])
  ) as () => ConversationDto;

  const setCurrentConversation = useSetAtom(currentConversationAtom);

  const getMessagesList = useAtomCallback(
    useCallback((get) => get(messagesListDataSourceAtom), [])
  ) as () => ConversationMessageDto[];

  const setMessagesList = useSetAtom(messagesListDataSourceAtom);

  const getConversationsList = useAtomCallback(
    useCallback((get) => get(conversationsDataSourceAtom), [])
  ) as () => ConversationClientDto[];

  const setConversationsList = useSetAtom(conversationsDataSourceAtom);

  const handleConversationsStatsEvent = ({ date, conversationsStats }: ConversationsStatsPushEventDto): void => {
    const conversationsStatsLaseDate = getConversationsStatsLaseDate();

    const lastDate = new Date(date);

    if (moment(conversationsStatsLaseDate).isAfter(lastDate)) {
      return;
    }

    setConversationsStats({
      lastDate,
      conversationsStats
    });
  };

  const handleConversationEvent = ({ status, conversation, conversationMessage }: ConversationPushEventDto): void => {
    const communicationCenterPathData = getCommunicationCenterPathData();

    if (!communicationCenterPathData?.isInCommunicationCenterPath) {
      return;
    }

    const currentConversation = getCurrentConversation();
    const messagesList = getMessagesList();
    const conversationsList = getConversationsList();

    const isConversationMessageInsideMessagesList = messagesList.some(
      (message) => message.id === conversationMessage.id
    );

    if (currentConversation?.id === conversation.id) {
      if (status === ConversationPushEventStatus.NEW && !isConversationMessageInsideMessagesList) {
        setMessagesList((oldMessagesList) => [conversationMessage, ...oldMessagesList]);
      } else if (isConversationMessageInsideMessagesList) {
        setMessagesList((oldMessagesList) =>
          oldMessagesList.map((message) => (message.id === conversationMessage.id ? conversationMessage : message))
        );
      }

      setCurrentConversation(conversation);
    }

    if (isConversationRelatedToPath(conversation, communicationCenterPathData)) {
      const isInConversationsList = conversationsList.some(
        (conversationItem) => conversationItem.id === conversation.id
      );

      const { path, pathKey } = communicationCenterPathData;

      let tryToRemoveConversation = false;

      if (pathKey === "communicationCenter") {
        if (
          path.startsWith(Routes.COMMUNICATIONS_CENTER_INBOX) &&
          (!conversation.isAssigned || conversation.status !== ConversationStatus.ACTIVE)
        ) {
          tryToRemoveConversation = true;
        } else if (
          path.startsWith(Routes.COMMUNICATIONS_CENTER_UNASSIGNED_MESSAGES) &&
          (!_.isEmpty(conversation.assignees) || conversation.status !== ConversationStatus.ACTIVE)
        ) {
          tryToRemoveConversation = true;
        }
      }

      const addToList = () =>
        setConversationsList((oldConversationsList) => [
          {
            ...conversation,
            isSelected: false,
            isHidden: false
          },
          ...oldConversationsList
        ]);

      const updateList = () =>
        setConversationsList((oldConversationsList) =>
          oldConversationsList.map((conversationItem) =>
            conversationItem.id === conversation.id
              ? {
                  ...conversation,
                  isSelected: conversationItem.isSelected
                }
              : conversationItem
          )
        );

      const removeFromList = () =>
        setConversationsList((oldConversationsList) =>
          oldConversationsList.map((conversationItem) =>
            conversationItem.id === conversation.id
              ? {
                  ...conversation,
                  isSelected: conversationItem.isSelected,
                  isHidden: true
                }
              : conversationItem
          )
        );

      const moveToTop = () =>
        setConversationsList((oldConversationsList) => {
          const conversationItem = oldConversationsList.find(
            (conversationItem) => conversationItem.id === conversation.id
          );

          return [
            {
              ...conversation,
              isSelected: conversationItem?.isSelected || false
            },
            ...oldConversationsList.filter((conversationItem) => conversationItem.id !== conversation.id)
          ];
        });

      if (tryToRemoveConversation) {
        if (isInConversationsList) {
          removeFromList();
        }
      } else if (status === ConversationPushEventStatus.NEW) {
        if (isInConversationsList) {
          moveToTop();
        } else {
          addToList();
        }
      } else if (status === ConversationPushEventStatus.UPDATE && isInConversationsList) {
        updateList();
      }
    }
  };

  return {
    handleConversationsStatsEvent,
    handleConversationEvent
  };
};
