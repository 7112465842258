import { useMemo } from "react";
import { View } from "DLUI/view";
import type { CheckboxProps } from "DLUI/checkbox";
import { Checkbox } from "DLUI/checkbox";

interface HeaderCheckBoxProps {
  toggleAll: (on?: boolean) => void;
  allChecked: boolean;
  anyChecked: boolean;
}

const HeaderCheckBox = ({ allChecked, anyChecked, toggleAll }: HeaderCheckBoxProps) => {
  const checkedValue = useMemo<CheckboxProps["checked"]>(() => {
    if (allChecked) {
      return true;
    }
    return anyChecked ? "indeterminate" : false;
  }, [allChecked, anyChecked]);

  return (
    <View
      autoWidth
      flexDirection={"row"}
      style={{
        paddingLeft: 12,
        paddingRight: 12
      }}
    >
      <Checkbox checked={checkedValue} onCheckedChange={() => toggleAll(!anyChecked)} />
    </View>
  );
};

export default HeaderCheckBox;
