import MomentUtils from "@date-io/moment";
import type { LeaseChargeDto } from "@doorloop/dto";
import { DuplicateDialogButtonLabelValues } from "@doorloop/dto";
import {
  createValidator,
  LeaseRecurringRentInfoDto,
  LeaseTransactionLineBaseDto,
  LinkedResourceType,
  mathUtils,
  ObjectPermission,
  RecurringTransactionDto,
  RecurringTransactionFrequency,
  RecurringTransactionType
} from "@doorloop/dto";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { filesApi } from "api/filesApi";
import { leasesRecurringTransactionsApi } from "api/leasesApi";
import { AddIcon } from "assets/icons";
import { Button } from "DLUI/button";
import { DialogSearchPanel, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import DeleteConfirmation from "DLUI/dialogs/components/deleteConfirmation";
import DialogAmountView from "DLUI/dialogs/components/totalAmountView";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import { FormikDatePicker, FormikReferenceLabel, Select, TextField } from "DLUI/form";
import { Notes } from "DLUI/notes";
import { View } from "DLUI/view";
import type { FieldArrayRenderProps, FormikProps } from "formik";
import { FastField, FieldArray, Formik, getIn } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import LeasesList from "screens/leases/leases/leasesList/leasesList";
import SelectableLeaseListItem from "screens/leases/leases/leasesList/selectableLeaseListItem";
import DialogFrame from "../../components/dialogFrame";
import LeaseSelection from "../leaseSelection";
import makeStyles from "../styles";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import FieldWrapper from "DLUI/form/textField/fieldWrapper";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";
import { QueryParams } from "utils/queryParams";
import {
  cleanRecurringTransactionDataForDuplicateMode,
  DUPLICATE_MODE_QUERY_PARAMS
} from "DLUI/dialogs/duplicateModeHelper";
import { DialogHeaderActionButtons } from "DLUI/actionButtons/dialogHeaderActionButtons";
import { useAnalyticsService } from "hooks/useAnalyticsService";
import { RecurringRentLineItemWrapper } from "DLUI/dialogs/transactions/recurringTransactions/recurringRentLineItemWrapper";

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
}

let formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

const validateForm = createValidator(RecurringTransactionDto);

const defaultDialogWidth = Math.min(window.innerWidth, 1050);

const RecurringRentDialog: React.FC<ComponentProps> = ({ onBackdropClick, onClose, dialogTitle }: ComponentProps) => {
  const classes = makeStyles();
  const { isMobile } = useResponsiveHelper();
  const { t } = useTranslation();
  const { leaseId, transactionId: paramsTransactionId } = useParams<any>();
  const queryParams = new QueryParams();
  const queryTransactionId = queryParams.get(DUPLICATE_MODE_QUERY_PARAMS.transactionId) as string | undefined;
  const transactionId = queryTransactionId || paramsTransactionId;
  const editMode = Boolean(transactionId);
  const duplicateMode = Boolean(queryTransactionId);
  const clearance: AnyPermissionClearance = {
    permission: ObjectPermission.leaseCharges,
    field: editMode ? "edit" : "create"
  };
  const [showErrorShakeEffect, setShowErrorShakeEffect] = useState(false);
  const [viewIndex, setViewIndex] = useState(0);
  const [renderSelectionList, setRenderSelectionList] = useState<boolean>(false);
  const [currentLeaseId, setCurrentLeaseId] = useState<string | undefined>(leaseId);
  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);
  const [selectLeaseFilterObj, setSelectLeaseFilterObj] = useState({
    filter_text: ""
  });
  const [showLeaseSelectionError, setShowLeaseSelectionError] = useState<boolean>(false);
  const [leaseSelectionErrorText, setLeaseSelectionErrorText] = useState<string>("");
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    editMode ? DialogState.Show : DialogState.Hidden
  );
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const [loadingDialogSuccessText, setLoadingDialogSuccessText] = useState<string>(
    AppStrings.Leases.LeaseTransaction.Rent.CreatedText
  );
  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState<string>(
    AppStrings.Leases.LeaseTransaction.Rent.LoadingData
  );
  const [chargeData, setChargeData] = useState<LeaseChargeDto | undefined>();
  const [shouldrenderForm, setShouldRenderForm] = useState<boolean>(!editMode);

  const { dispatchAnalytics } = useAnalyticsService();

  const { inputRightPadding, dialogHorizontalPadding } = DialogsHelper();

  useEffect(() => {
    formikGlobalRef?.setFieldValue("lease", currentLeaseId);
    setTimeout(() => {
      if (formikGlobalRef) {
        const errorText = getIn(formikGlobalRef.errors, "lease");
        const touchedVal = getIn(formikGlobalRef.touched, "lease");

        setShowLeaseSelectionError(touchedVal && errorText !== undefined);
        if (errorText !== undefined) {
          setLeaseSelectionErrorText(errorText);
        }
      }
    }, 0);
  }, [currentLeaseId]);

  useEffect(() => {
    if (editMode) {
      loadData();
    }
  }, [duplicateMode]);

  const initFormvalues = (): RecurringTransactionDto => {
    if (editMode && chargeData) {
      return chargeData;
    }
    const _leaseRecurringRentDto = new RecurringTransactionDto();
    _leaseRecurringRentDto.type = RecurringTransactionType.LEASE_RENT;
    _leaseRecurringRentDto.start = moment().format("YYYY-MM-DD");
    _leaseRecurringRentDto.end = moment().add(1, "year").format("YYYY-MM-DD");
    _leaseRecurringRentDto.noEndDate = false;
    _leaseRecurringRentDto.frequency = RecurringTransactionFrequency.Monthly;
    _leaseRecurringRentDto.leaseRentInfo = new LeaseRecurringRentInfoDto();
    const transactionLine = new LeaseTransactionLineBaseDto();

    _leaseRecurringRentDto.leaseRentInfo.lines = [transactionLine];
    return _leaseRecurringRentDto;
  };

  const isValidForm = async (formikRef: FormikProps<RecurringTransactionDto>) => {
    formikRef.setFieldTouched("lease");
    if (formikRef.values.leaseRentInfo && formikRef.values.leaseRentInfo.lines) {
      formikRef.values.leaseRentInfo.lines.forEach((currentLine, index) => {
        formikRef.setFieldTouched(`leaseRentInfo.lines[${index}].amount`);
        formikRef.setFieldTouched(`leaseRentInfo.lines[${index}].account`);
      });
    }

    const errors = (await formikRef.validateForm()) as any;
    const inValidLease = Boolean(errors.lease);
    setShowLeaseSelectionError(inValidLease);
    if (inValidLease && formikGlobalRef) {
      const errorText = getIn(formikGlobalRef.errors, "lease");
      if (errorText !== undefined) {
        setLeaseSelectionErrorText(errorText);
      }
    }

    return _.isEmpty(errors);
  };

  const loadData = async () => {
    if (editMode && transactionId) {
      setLoadingDialogState(DialogState.Show);

      const response = await leasesRecurringTransactionsApi.get(transactionId).catch((error) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });
      if (response && response.status && response.data) {
        setChargeData(duplicateMode ? cleanRecurringTransactionDataForDuplicateMode(response.data) : response.data);
        setCurrentLeaseId(response.data.lease);
        setLoadingDialogSuccessText(AppStrings.Leases.LeaseTransaction.Rent.LoadingDataSuccess);
        setLoadingDialogState(DialogState.Success);
        setTimeout(() => {
          setLoadingDialogState(DialogState.Hidden);

          setShouldRenderForm(true);
        }, 500);
      } else {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(response ? response.message : "");
      }
    }
  };

  const createTransaction = async () => {
    if (formikGlobalRef) {
      const chargeValues = _.cloneDeep(formikGlobalRef.values);
      setViewIndex(2);
      setLoadingDialogLoadingText(AppStrings.Leases.LeaseTransaction.Rent.Creating);
      setLoadingDialogState(DialogState.Show);
      const results = await leasesRecurringTransactionsApi.create(chargeValues).catch((error) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });

      if (results && results.status && results.data) {
        await filesApi
          .uploadFiles(attachments, results.data.id!, LinkedResourceType.RecurringTransaction)
          .catch((error: string) => {
            setLoadingDialogState(DialogState.Error);
            setLoadingDialogErrorText(error);
          });
        // TODO support file upload error !
        setLoadingDialogState(DialogState.Success);
        setTimeout(() => {
          onClose();
        }, 500);
      } else {
        setLoadingDialogErrorText(results ? results.message : "");
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const updateTransaction = async () => {
    if (formikGlobalRef && transactionId) {
      const chargeValues = _.cloneDeep(formikGlobalRef.values);
      setViewIndex(2);
      setShouldRenderForm(false);
      setLoadingDialogState(DialogState.Show);
      setLoadingDialogLoadingText(AppStrings.Leases.LeaseTransaction.Rent.Updating);
      const results = await leasesRecurringTransactionsApi.update(transactionId, chargeValues).catch((error) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });

      if (results && results.status && results.data) {
        await filesApi
          .uploadFiles(attachments, results.data.id!, LinkedResourceType.RecurringTransaction)
          .catch((error: string) => {
            setLoadingDialogState(DialogState.Error);
            setLoadingDialogErrorText(error);
          });
        setLoadingDialogSuccessText(AppStrings.Leases.LeaseTransaction.Rent.Updated);
        setLoadingDialogState(DialogState.Success);
        setTimeout(() => {
          onClose();
        }, 500);
      } else {
        setLoadingDialogErrorText(results ? results.message : "");
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const didPressSaveButton = async () => {
    if (formikGlobalRef !== null) {
      const isValid = await isValidForm(formikGlobalRef);
      if (isValid) {
        if (editMode && !duplicateMode) {
          updateTransaction();
        } else {
          createTransaction();
        }
      }
    }
  };

  const getTotalAmount = () => {
    let amount = 0;
    if (formikGlobalRef && formikGlobalRef.values) {
      const leaseRentInfoLines = formikGlobalRef.values.leaseRentInfo.lines;
      leaseRentInfoLines.forEach((currentLine) => {
        amount = mathUtils.add(amount, currentLine.amount || 0);
      });
    }
    return amount;
  };

  const renderLines = (formik: FormikProps<any>, arrayHelpers: FieldArrayRenderProps) => {
    const leaseRentInfoLines = formik.values.leaseRentInfo.lines;
    if (!leaseRentInfoLines) {
      return <div />;
    }
    const lines = leaseRentInfoLines.map((currentItem, index) => {
      const depositAccountDefaultValue = currentItem.account;
      return (
        <View fullWidth key={currentItem._id} noWrap paddingTop={5}>
          <View noWrap>
            <RecurringRentLineItemWrapper arrayHelpers={arrayHelpers} index={index}>
              <Grid item xs={12} md={4} lg={4}>
                <BankAccountFormikAutoCompleteField
                  addCreateOption
                  uniqueIndex={"TS"}
                  name={`leaseRentInfo.lines[${index}].account`}
                  queryParams={{
                    filter_leaseChargeItem: true
                  }}
                  label={t(AppStrings.Common.Settings.LateFees.Account)}
                  defaultValue={depositAccountDefaultValue}
                  paddingRight={inputRightPadding}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <FastField
                  component={TextField}
                  label={t(AppStrings.Leases.NewLease.LeaseRent.Description)}
                  name={`leaseRentInfo.lines[${index}].memo`}
                  paddingRight={inputRightPadding}
                />
              </Grid>
              <Grid container direction={"row"} justify={"center"} item xs={12} md={4} lg={4}>
                <View flex={1}>
                  <FastField
                    component={TextField}
                    name={`leaseRentInfo.lines[${index}].amount`}
                    type={"number"}
                    required
                    formatType={"currency"}
                    label={t(AppStrings.Leases.NewLease.LeaseRent.Amount)}
                    allowNegative={true}
                  />
                </View>
              </Grid>
            </RecurringRentLineItemWrapper>
          </View>
        </View>
      );
    });

    const renderAddButton = () => {
      const didPressAddChargeLine = () => {
        arrayHelpers.push(new LeaseRecurringRentInfoDto());
      };

      return (
        <Button
          color={"lightBlue"}
          type={"inlineText"}
          actionText={AppStrings.Leases.LeaseCharge.AddLineItem}
          onClick={didPressAddChargeLine}
          LeftIcon={AddIcon}
          marginLeft={20}
          applyColorForIcons
          iconSize={15}
        />
      );
    };

    return (
      <View noWrap marginTop={10}>
        {lines}
        <View marginTop={20}>{renderAddButton()}</View>
      </View>
    );
  };

  const renderSummaryLine = () => {
    const totalAmount = getTotalAmount();

    return <DialogAmountView amount={totalAmount} title={AppStrings.Leases.LeaseCharge.TotalAmount} />;
  };

  const onFileReceived = (files: FileListItemProps[]) => {
    setAttachments(files);
  };

  const renderAttachments = () => (
    <View justifyContent={"flex-end"} width={"100%"} flex={1} marginTop={20} marginBottom={20}>
      <FormAttachments
        editMode={editMode}
        onFileReceived={onFileReceived}
        files={attachments}
        resourceId={chargeData ? chargeData.id : undefined}
        resourceType={LinkedResourceType.RecurringTransaction}
      />
    </View>
  );

  const didPressSelectLease = () => {
    setViewIndex(1);
    setTimeout(() => {
      setRenderSelectionList(true);
    }, 500);
  };

  const didchangeStartDate = (nextStartDateValue: string) => {
    if (formikGlobalRef) {
      formikGlobalRef.setFieldValue("end", moment(nextStartDateValue).add(1, "days").format("YYYY-MM-DD"));
    }
  };

  const didPressRemoveLease = () => {
    setCurrentLeaseId(undefined);
  };

  const renderFormTopSection = () => (
    <Grid container>
      <Grid xs={12} md={4} lg={4}>
        <View marginTop={20} paddingRight={20}>
          <LeaseSelection
            didPressSelectLease={didPressSelectLease}
            leaseId={currentLeaseId}
            errorTex={showLeaseSelectionError ? leaseSelectionErrorText : undefined}
          />
        </View>
      </Grid>
      <Grid container xs={12} md={8} lg={8}>
        {isMobile && (
          <Grid item xs={12} md={4} lg={4}>
            <FieldWrapper type={"endElement"}>
              <FastField
                component={FormikReferenceLabel}
                name={"reference"}
                backgroundColor={"dark"}
                valueTextTransform={"uppercase"}
              />
            </FieldWrapper>
          </Grid>
        )}
        <Grid item xs={12} md={4} lg={4}>
          <FieldWrapper type={"endElement"}>
            <FastField
              component={FormikDatePicker}
              uniqueKey={"start"}
              label={AppStrings.Leases.NewLease.StartDate}
              name={"start"}
              noMargin
              required
              onChange={didchangeStartDate}
            />
          </FieldWrapper>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <FieldWrapper type={"middleElement"}>
            <FastField
              component={Select}
              name={`frequency`}
              label={AppStrings.Leases.NewLease.LeaseRent.Frequency}
              required
              uniqueKey={"paymentMethod"}
              selectionEnum={RecurringTransactionFrequency}
              translationKey={"recurringTransactionFrequency"}
            ></FastField>
          </FieldWrapper>
        </Grid>
        {!isMobile && (
          <Grid item xs={12} md={4} lg={4}>
            <FieldWrapper type={"startElement"}>
              <FastField
                component={FormikReferenceLabel}
                name={"reference"}
                backgroundColor={"dark"}
                valueTextTransform={"uppercase"}
              />
            </FieldWrapper>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  const renderNotes = () => (
    <View height={50} marginTop={20} paddingLeft={20} paddingRight={20} justifyContent={"center"}>
      <FastField component={Notes} height={30} name={"memo"} />
    </View>
  );

  const renderForm = () => {
    const formInitialValues = initFormvalues();
    return (
      <Formik initialValues={formInitialValues} onSubmit={(values, { setSubmitting }) => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;
          return (
            <View flex={1} width={"100%"}>
              <RestrictedPermissionAccess clearance={clearance} showNoAccess>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <View
                    paddingLeft={dialogHorizontalPadding}
                    paddingRight={dialogHorizontalPadding}
                    flexDirection={"column"}
                  >
                    <View className={classes.sectionSeparator}>
                      <View flexDirection={"row"} marginBottom={20}>
                        {renderFormTopSection()}
                      </View>
                    </View>

                    <FieldArray
                      name={"leaseRentInfo.lines"}
                      render={(arrayHelpers) => <View>{renderLines(formik, arrayHelpers)}</View>}
                    />

                    {renderNotes()}
                    {renderSummaryLine()}
                    {renderAttachments()}
                  </View>
                </MuiPickersUtilsProvider>
              </RestrictedPermissionAccess>
            </View>
          );
        }}
      </Formik>
    );
  };

  const renderLeaseSelectionList = () => {
    const didChangeSearchQuery = (value: string) => {
      setSelectLeaseFilterObj({
        filter_text: value
      });
    };

    const onListItemPress = (leaseId: string) => {
      setViewIndex(0);
      setCurrentLeaseId(leaseId);
    };

    return (
      <View>
        <DialogSearchPanel onChange={didChangeSearchQuery} />
        <View>
          {renderSelectionList ? (
            <View id={"selectableLeaseContainer"} height={520} overflow={"scroll"} paddingLeft={20} paddingRight={20}>
              <LeasesList
                ListItem={SelectableLeaseListItem}
                filterObj={selectLeaseFilterObj}
                didPressListItem={onListItemPress}
                stickyHeaderId={"selectableLeaseContainer"}
                scrollableTarget={"selectableLeaseContainer"}
                removeDefaultBottomPadding
                selectableItem
              />
            </View>
          ) : null}
        </View>
      </View>
    );
  };

  const renderView = ({ index }: any) => {
    const onRetryButtonPress = async () => {
      await didPressSaveButton();
      //createTransaction();
    };
    const didPressDismissButton = () => {
      setViewIndex(0);
    };

    if (index === 0) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={loadData}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }
      if (shouldrenderForm) {
        return renderForm();
      }
      return <div />;
    }
    if (index === 1) {
      return renderLeaseSelectionList();
    }
    if (index === 2) {
      return (
        <View alignItems={"center"} justifyContent={"center"} height={"100%"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
          />
        </View>
      );
    }
    if (index === 3) {
      return (
        <DeleteConfirmation
          apiMethod={leasesRecurringTransactionsApi}
          apiToasts={{
            translationKey: AppStrings.Toasts.custom.recurring[RecurringTransactionType.LEASE_RENT]?.DELETE
          }}
          didPressDismissButton={didPressDismissButton}
          didFinishOperation={onClose}
          transactionId={transactionId}
          attachments={attachments}
        />
      );
    }
    return <div />;
  };

  const _onBackdropClick = () => {
    if (viewIndex === 1) {
      setViewIndex(0);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const handleDeleteClick = () => {
    setViewIndex(3);
  };
  const handleDuplicateClick = () => {
    queryParams.set(DUPLICATE_MODE_QUERY_PARAMS.transactionId, transactionId);
    queryParams.historyPush();
    dispatchAnalytics("button_click", {
      label: DuplicateDialogButtonLabelValues.DUPLICATE_JOURNAL_ENTRY
    });
  };
  const renderHeaderActionButtons = () => (
    <DialogHeaderActionButtons
      onDeleteClick={handleDeleteClick}
      onDuplicateClick={handleDuplicateClick}
      clearance={clearance}
      hideDeleteButton={duplicateMode || !editMode}
      hideDuplicateButton={duplicateMode || !editMode}
    />
  );

  const renderActionPanelButtons = () => (
    <RestrictedPermissionAccess clearance={clearance}>
      <FormActionButtons
        propsActionPanel={{
          editMode
        }}
        propsSubButton={{ onClick: _onBackdropClick }}
        propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
      />
    </RestrictedPermissionAccess>
  );

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }
    if (viewIndex === 2 || viewIndex === 3) {
      return "contentOnly";
    }
    return "sectionTitleFrame";
  }, [viewIndex, loadingDialogState]);

  const currentTitle = useMemo(() => {
    if (viewIndex === 0) {
      return dialogTitle;
    }

    if (viewIndex === 1) {
      return AppStrings.Leases.LeaseCharge.SelectLease;
    }
    return "";
  }, [viewIndex]);

  const duplicateModeTitle = useMemo(
    () => (duplicateMode ? `${t(currentTitle)} (${t(AppStrings.Common.Duplicated)})` : ""),
    [currentTitle, duplicateMode, t]
  );

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={duplicateModeTitle || currentTitle}
      width={defaultDialogWidth}
      height={740}
      renderView={renderView}
      numViews={4}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      RenderHeaderActionButtons={renderHeaderActionButtons}
      frameType={frameType}
    />
  );
};

export default RecurringRentDialog;
