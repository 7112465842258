import type { FilterPanelTextFieldFiltering } from "DLUI/screen/filterPanel/filterPanel";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSyncWithSearchParams } from "hooks/useSyncWithSearchParams";
import type { TextInputProps } from "@/components/DLUI/form/textField/textInput";
import { DefaultDebounceDelays, useDebounce } from "@/hooks/useDebounce";

export function useTextFilterInput<TQuery>({
  filterFieldName,
  label,
  formatType,
  marginTop,
  dataCy
}: Pick<
  FilterPanelTextFieldFiltering<TQuery>,
  "filterFieldName" | "label" | "formatType" | "marginTop" | "dataCy"
>): TextInputProps {
  const { t } = useTranslation();
  const { queryValue, onValueChange } = useSyncWithSearchParams(filterFieldName);
  const [immediateValue, setImmediateValue] = useState<string | null>(queryValue);
  const debouncedValue = useDebounce(immediateValue, DefaultDebounceDelays.TEXT_SEARCH_CHANGED);

  const handleImmediateValueChange = useCallback<NonNullable<TextInputProps["onChange"]>>((event) => {
    setImmediateValue(event.target.value);
  }, []);

  useEffect(() => {
    onValueChange(debouncedValue);
  }, [debouncedValue, onValueChange]);

  useEffect(() => {
    setImmediateValue(queryValue);
  }, [queryValue]);

  return useMemo<TextInputProps>(
    () =>
      ({
        value: immediateValue ?? "",
        label: t(label),
        formatType,
        onChange: handleImmediateValueChange,
        marginTop,
        dataCy
      }) as TextInputProps,
    [immediateValue, t, label, formatType, marginTop, handleImmediateValueChange, dataCy]
  );
}
