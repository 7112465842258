import React, { useCallback, useEffect, useMemo, useState } from "react";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import type { FrameType } from "DLUI/dialogs/components/dialogFrame";
import DialogFrame, { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import ApiKeysDialogView from "screens/settings/apiKeys/apiKeysDialogView";
import AppStrings from "../../../../locale/keys";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { ArticleIdsEnum, HelpTypeEnum, VideoUrlsEnum } from "DLUI/screen/helpPanel/types";
import { apiKeysApi } from "api/apiKeysApi";
import { ApiKeyDto } from "@doorloop/dto";
import { useTranslation } from "react-i18next";

const helpPanelSectionInfo: HelpPanelProps = {
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      articleId: ArticleIdsEnum.USING_ZAPIER_WITH_DOORLOOP,
      topic: AppStrings.Settings.GeneralSettings.ApiKeys.ApiKeysDialogPanelHelpArticle1
    },
    {
      type: HelpTypeEnum.WATCH_VIDEO,
      topic: AppStrings.Settings.GeneralSettings.ApiKeys.ApiKeysDialogPanelVideoTutorial,
      href: VideoUrlsEnum.USING_ZAPIER_WITH_DOORLOOP,
      dialogTitle: AppStrings.Settings.GeneralSettings.ApiKeys.ApiKeysDialogPanelVideoTutorial
    }
  ]
};

enum ViewsIndices {
  LOADING_VIEW,
  DIALOG_FORM_VIEW
}

interface ComponentProps {
  onClose: () => void;
  onBackdropClick: () => void;
  dialogTitle?: string;
}
const ApiKeysDialog: React.FC<ComponentProps> = ({ onBackdropClick, dialogTitle }: ComponentProps) => {
  const { t } = useTranslation();
  const [apiKeysData, setApiKeys] = useState<ApiKeyDto[] | any>([]);
  const [loading, setLoading] = useState(DialogState.Show);
  const [textError, setTextError] = useState("");
  const [viewIndex, setViewIndex] = useState(ViewsIndices.LOADING_VIEW);
  const frameType: FrameType = useMemo(() => {
    if (viewIndex === ViewsIndices.DIALOG_FORM_VIEW) {
      setLoading(DialogState.Hidden);

      return "sectionTitleFrame";
    }

    return "contentOnly";
  }, [viewIndex]);

  useEffect(() => {
    getAllApiKeys();
  }, []);

  const handleErrorResponse = useCallback((error) => {
    setTextError(error);
    setLoading(DialogState.Error);
    setViewIndex(ViewsIndices.LOADING_VIEW);
  }, []);

  const toggleSecretsVisibility = (id?: string | null, data?: ApiKeyDto[]) => {
    if (id) {
      setApiKeys((oldApiKeys) =>
        oldApiKeys?.map((key) => {
          if (key.id === id) {
            key.hideSecret = !key.hideSecret;
          }

          return key;
        })
      );
    }

    return data?.map((key) => {
      return {
        ...key,
        hideSecret: true
      };
    });
  };

  const getAllApiKeys = useCallback(async () => {
    setLoading(DialogState.Show);
    setViewIndex(ViewsIndices.LOADING_VIEW);
    const { data, status, message } = await apiKeysApi.getAll({});

    if (data) {
      setApiKeys(toggleSecretsVisibility(null, data?.data));
      setViewIndex(ViewsIndices.DIALOG_FORM_VIEW);
    } else if (!status) {
      handleErrorResponse(message || AppStrings.Common.GeneralError);
    }
  }, []);

  const createApiKey = useCallback(async (name) => {
    const newApiKey = new ApiKeyDto({ name, enabled: true });
    const { data, status, message } = await apiKeysApi.create(newApiKey);

    if (data) {
      data.hideSecret = true;

      setApiKeys((prevApiKeys) => [...prevApiKeys, data]);
    } else if (!status) {
      handleErrorResponse(message || AppStrings.Settings.GeneralSettings.ApiKeys.ApiKeysCreateTextError);
    }
  }, []);

  const updateApiKey = useCallback(async (id, name) => {
    const apiKey = new ApiKeyDto({ name });
    const { data, status, message } = await apiKeysApi.update(id, apiKey);

    if (data) {
      data.hideSecret = true;
      setApiKeys((prevApiKeys) => prevApiKeys.map((apiKey) => (apiKey.id === data.id ? data : apiKey)));
    } else if (!status) {
      handleErrorResponse(message || AppStrings.Settings.GeneralSettings.ApiKeys.ApiKeysUpdateTextError);
    }
  }, []);

  const deleteApiKey = useCallback(async (id) => {
    const { data, status, message } = await apiKeysApi.delete(id);

    if (data) {
      setApiKeys((prevApiKeys) => prevApiKeys.filter((apiKey) => apiKey.id !== id));
    } else if (!status) {
      handleErrorResponse(message || AppStrings.Settings.GeneralSettings.ApiKeys.ApiKeysDeleteTextError);
    }
  }, []);

  const renderView = () => {
    if (viewIndex === ViewsIndices.DIALOG_FORM_VIEW) {
      return (
        <ApiKeysDialogView
          apiKeysData={apiKeysData}
          createApiKey={createApiKey}
          updateApiKey={updateApiKey}
          deleteApiKey={deleteApiKey}
          toggleSecretsVisibility={toggleSecretsVisibility}
        />
      );
    }

    return (
      <LoadingDialog
        dialogState={loading}
        errorText={t(textError)}
        didPressDismissButton={onBackdropClick}
        onRetryButtonPress={getAllApiKeys}
      />
    );
  };

  return (
    <DialogFrame
      title={dialogTitle}
      onCloseButtonClick={onBackdropClick}
      width={getDialogFrameDimension("width", 790)}
      height={getDialogFrameDimension("height", 750)}
      numViews={2}
      renderView={renderView}
      activeView={viewIndex}
      helpPanel={helpPanelSectionInfo}
      frameType={frameType}
    />
  );
};

export default ApiKeysDialog;
