import React from "react";

import AppStrings from "locale/keys";
import {
  BadgeIcon,
  FeaturesIcon,
  InvitesIcon,
  LinkIcon,
  ScrollIcon,
  ThemeIcon,
  UnassignedTasksIcon
} from "assets/icons";
import { ContactInfo } from "./components/contactInfo";
import { Features } from "./components/features";
import type { MenuItem } from "DLUI/dialogs";
import { OwnerRequests } from "./components/ownerRequests";
import { PortalInvites } from "./components/portalInvites";
import { PortalUrl } from "./components/portalUrl";
import { Reports } from "./components/reports/reports";
import { ThemeSettings } from "./components/themeSettings";

export const getOwnerPortalFormMenuItems = (): MenuItem[] => [
  {
    title: AppStrings.Common.PortalContactInfo,
    icon: BadgeIcon,
    contentComponent: () => <ContactInfo />,
    uploadFile: true
  },
  {
    title: AppStrings.Common.ThemeSettings,
    icon: ThemeIcon,
    contentComponent: (
      formikRef,
      _onFileReceive,
      _onFileDelete,
      onTenantPortalLogoFileReceive,
      onTenantPortalLogoFileDelete,
      onCoverImageFileReceive,
      onCoverImageFileDelete
    ) => (
      <ThemeSettings
        onTenantPortalLogoFileReceive={onTenantPortalLogoFileReceive}
        onTenantPortalLogoFileDelete={onTenantPortalLogoFileDelete}
        onCoverImageFileReceive={onCoverImageFileReceive}
        onCoverImageFileDelete={onCoverImageFileDelete}
        formikRef={formikRef}
      />
    ),
    uploadFile: true
  },
  {
    title: AppStrings.Common.Features,
    icon: FeaturesIcon,
    contentComponent: (formikRef) => <Features formikRef={formikRef} />
  },
  {
    title: AppStrings.Common.Reports,
    icon: ScrollIcon,
    contentComponent: (formikRef) => <Reports formikRef={formikRef} />
  },
  {
    title: AppStrings.Common.OwnerRequestsSettingItemTitle,
    icon: UnassignedTasksIcon,
    contentComponent: (formikRef) => <OwnerRequests formikRef={formikRef} />
  },
  {
    title: AppStrings.Common.PortalInvites,
    icon: InvitesIcon,
    contentComponent: (formikRef) => <PortalInvites formikRef={formikRef} />
  },
  {
    title: AppStrings.Common.PortalUrl,
    icon: LinkIcon,
    contentComponent: (formikRef) => <PortalUrl formikRef={formikRef} />
  }
];
