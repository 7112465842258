import React, { useContext } from "react";
import type { RecurringTransactionDto } from "@doorloop/dto";
import type { EventBus } from "utils/eventBus";

export interface RecurringContextInterface {
  isRecurring?: boolean;
  validator?: () => Promise<boolean>;
  recurringData?: RecurringTransactionDto;
  editMode?: boolean;
  eventBus?: EventBus;
}

export const RecurringContext = React.createContext<RecurringContextInterface | null>(null);

export const useRecurringContext = () => useContext(RecurringContext);
