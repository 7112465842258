import type { FC } from "react";
import React, { useCallback } from "react";
import { ShakeEffectView } from "DLUI/animatableView";
import useTimeoutTrigger from "components/data/useTimeoutTrigger";
import { shakeEffectClassNames } from "DLUI/animatableView/utils";

export const useShakeEffect = (opts: { fullWidth?: boolean } | undefined = undefined) => {
  const [showShakeEffect, runShakeEffect] = useTimeoutTrigger(300);

  const ShakeEffectWrapper: FC = useCallback(
    ({ children }) => (
      <ShakeEffectView fullWidth={opts?.fullWidth} showEffect={showShakeEffect}>
        {children}
      </ShakeEffectView>
    ),
    [showShakeEffect, opts]
  );

  return {
    className: shakeEffectClassNames(showShakeEffect),
    runShakeEffect,
    ShakeEffectWrapper,
    showShakeEffect
  };
};
