import type { LeaseTenantDto } from "@doorloop/dto";
import { ListItemContainerMaxWidth } from "DLUI/infiniteList/utils";
import { StickyHeader } from "DLUI/stickyHeader";
import { View } from "DLUI/view";
import React, { useState } from "react";
import { LeaseTenantsListItem } from "screens/tenants";
import { NavigationManager } from "utils/navigation";
import { AnimateMarginTop } from "DLUI/animatableView";
import SearchResultsListItemWrapper from "DLUI/screen/globalSearch/searchResultsListItemWrapper";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import type { ResultsSearchTabComponentProps } from "DLUI/screen/globalSearch/types";
import { globalSearchContstants } from "DLUI/screen/globalSearch/globalSearch.constants";
import { useTimeout } from "../../../../hooks/useTimeout";

const LeaseTenantsTab: React.FC<ResultsSearchTabComponentProps<LeaseTenantDto>> = ({
  listItems,
  handleItemClicked
}: ResultsSearchTabComponentProps<LeaseTenantDto>) => {
  const [shouldRender, setShouldRender] = useState(false);
  const { isMobile } = useResponsiveHelper();

  useTimeout(() => {
    setShouldRender(true);
  }, globalSearchContstants.tabsDisplayDelay);

  const items = listItems.map((currentItem: LeaseTenantDto, index) => {
    const didPressTenantItem = (tenantId: string) => {
      handleItemClicked("Lease Tenants");

      setTimeout(() => {
        NavigationManager.viewTenantDetails(tenantId);
      }, globalSearchContstants.resultsItemHideDelay);
    };

    return (
      <SearchResultsListItemWrapper key={"LTI" + index}>
        <LeaseTenantsListItem
          onItemPress={didPressTenantItem}
          tenant={currentItem}
          disableListItemOptions
          disableListItemContactInfoEdit
        />
      </SearchResultsListItemWrapper>
    );
  });

  return (
    <View
      alignItems={"center"}
      overflow={"scroll"}
      height={`calc(100vh - ${globalSearchContstants.searchPanelHeight}px)`}
    >
      {shouldRender ? (
        <View maxWidth={ListItemContainerMaxWidth} style={{ display: "block" }}>
          {!isMobile && <StickyHeader id={"searchLeasesTab"} shouldShow marginTop={20} />}
          {isMobile && <AnimateMarginTop marginTop={20} />}
          <View alignItems={"center"} id={"searchLeasesTab"}>
            {items}
            <View height={60} />
          </View>
        </View>
      ) : (
        <div />
      )}
    </View>
  );
};

export default LeaseTenantsTab;
