import type { FilterChipsContextProps } from "../context";
import { FilterChipsContext } from "../context";
import type { PropsWithChildren } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { FilterChipInfo, SidePanelState } from "../types";

export interface RootProps<TQuery> {
  filterOptions: FilterChipsContextProps<TQuery>["filterOptions"];
  sidePanelState?: SidePanelState;
  onSidePanelStateChange?: (state: SidePanelState) => void;
}

export function Root<TQuery>({
  filterOptions,
  sidePanelState,
  onSidePanelStateChange,
  children
}: PropsWithChildren<RootProps<TQuery>>) {
  const [filterChips, setFilterChips] = useState<FilterChipInfo[]>([]);
  const [innerSidePanelState, setInnerSidePanelState] = useState<FilterChipsContextProps<TQuery>["sidePanelState"]>(
    sidePanelState || "closed"
  );

  const addFilterChip = useCallback((filterChip: FilterChipInfo) => {
    setFilterChips((prevFilterChips) => {
      const filterChipIndex = prevFilterChips.findIndex((chip) => chip.key === filterChip.key);
      if (filterChipIndex !== -1) {
        const newFilterChips = [...prevFilterChips];
        newFilterChips[filterChipIndex] = filterChip;

        return newFilterChips;
      }

      const newFilterChips = [...prevFilterChips, filterChip];

      return newFilterChips;
    });
  }, []);

  const removeFilterChip = useCallback<FilterChipsContextProps<TQuery>["removeFilterChip"]>((key) => {
    setFilterChips((prevFilterChips) => prevFilterChips.filter((chip) => chip.key !== key));
  }, []);

  const toggleSidePanelState = useCallback<FilterChipsContextProps<TQuery>["toggleSidePanelState"]>(
    (flag) => {
      if (flag) {
        onSidePanelStateChange?.(flag);
        setInnerSidePanelState(flag);
        return;
      }

      onSidePanelStateChange?.(innerSidePanelState === "open" ? "closed" : "open");
      setInnerSidePanelState((prevState) => (prevState === "open" ? "closed" : "open"));
    },
    [innerSidePanelState, onSidePanelStateChange]
  );

  useEffect(() => {
    if (!sidePanelState) {
      return;
    }

    setInnerSidePanelState(sidePanelState);
  }, [sidePanelState]);

  const contextValue = useMemo<FilterChipsContextProps<TQuery>>(
    () =>
      ({
        filterChips,
        addFilterChip,
        removeFilterChip,
        filterOptions,
        toggleSidePanelState,
        sidePanelState: innerSidePanelState
      }) satisfies FilterChipsContextProps<TQuery>,
    [filterChips, addFilterChip, removeFilterChip, filterOptions, toggleSidePanelState, innerSidePanelState]
  );

  return <FilterChipsContext.Provider value={contextValue}>{children}</FilterChipsContext.Provider>;
}
