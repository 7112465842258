import { actionsPanelIconsTypesEnums } from "DLUI/form/fieldsActionsPanel/actionPanels/enums";
import type { SVGIconComponent } from "../../../../../assets";
import { CloseIcon, CopyIcon, DeleteIcon, EditBlue, HideIcon, ViewBlueIcon, VSignIcon } from "../../../../../assets";

export const actionsPanelIcons: Partial<Record<actionsPanelIconsTypesEnums, SVGIconComponent>> = {
  [actionsPanelIconsTypesEnums.REMOVE_ACTION_PANEL]: DeleteIcon,
  [actionsPanelIconsTypesEnums.EDIT_ACTION_PANEL]: EditBlue,
  [actionsPanelIconsTypesEnums.CONFIRM_ACTION_PANEL]: VSignIcon,
  [actionsPanelIconsTypesEnums.CLOSE_ACTION_PANEL]: CloseIcon,
  [actionsPanelIconsTypesEnums.SHOW_ACTION_PANEL]: ViewBlueIcon,
  [actionsPanelIconsTypesEnums.HIDE_ACTION_PANEL]: HideIcon,
  [actionsPanelIconsTypesEnums.COPY_ACTION_PANEL]: CopyIcon,
  [actionsPanelIconsTypesEnums.ADD_ACTION_PANEL]: VSignIcon
};
