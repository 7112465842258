import type { OnBoardingDto } from "@doorloop/dto";
import { UpdateOnBoardingItemRequestDto, UpdateShowOnBoardingOnLoginRequestDto } from "@doorloop/dto";
import { apiHelper } from "api/apiHelper";
import { ServerRoutes } from "@doorloop/dto";
import type { ApiResult } from "api/apiResult";
import type { RequireAtLeastOne } from "utils/types";

class OnBoardingApi {
  async getOnBoardingStatus(): Promise<ApiResult<OnBoardingDto>> {
    return await apiHelper.axiosGet(ServerRoutes.ONBOARDING);
  }

  async updateOnBoardingItem<
    _RequiredProperties extends RequireAtLeastOne<Pick<UpdateOnBoardingItemRequestDto, "isCompleted" | "isSkipped">>
  >(itemName: keyof OnBoardingDto, { isCompleted, isSkipped }: _RequiredProperties): Promise<ApiResult<void>> {
    if (isSkipped === false) {
      // if we're settings skipped to false, let's also set completed to false
      isCompleted = false;
    }

    const data = new UpdateOnBoardingItemRequestDto({ itemName, isCompleted, isSkipped });
    return await apiHelper.axiosPut({ url: ServerRoutes.ONBOARDING, data });
  }

  async updateShowOnBoardingOnLogin(showOnBoardingOnLogin: boolean): Promise<ApiResult<void>> {
    const data = new UpdateShowOnBoardingOnLoginRequestDto({ showOnBoardingOnLogin });

    return await apiHelper.axiosPost({
      url: ServerRoutes.ONBOARDING_UPDATE_SHOW_ONBOARDING_ON_LOGIN,
      data
    });
  }
}

export const onBoardingApi = new OnBoardingApi();
