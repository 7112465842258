import React from "react";

import { useAtomValue } from "jotai";

import AppStrings from "../../../../../locale/keys";
import Text from "DLUI/text";
import type { CustomNavComponentProps } from "DLUI/drawer/navigationItem";
import UnreadNotificationsBubble from "DLUI/notificationCenter/components/notificationBubble";
import { AnnouncementIcon } from "../../../../../assets";
import { communicationsMenuItemCustomStyles } from "DLUI/drawer/items/communicationsMenuItemCustom/styles";
import { conversationsInboxCountAtom } from "screens/communicationsCenter/shared/conversationsStatsAtom";
import { Icon } from "DLUI/icon";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";
import { View } from "DLUI/view";

const maxNotificationToShow = 99;

export const CommunicationsMenuItemCustom = ({ isMinimized, handleLinkClick, isActive }: CustomNavComponentProps) => {
  const { isTabletOrMobile } = useResponsiveHelper();

  const count = useAtomValue(conversationsInboxCountAtom);

  const { notificationBubble } = communicationsMenuItemCustomStyles({
    count
  });

  return (
    <View
      flexDirection={"row"}
      onClick={handleLinkClick}
      noWrap
      style={{ cursor: "pointer", padding: "10px 4px 10px 10px" }}
      autoWidth
    >
      <View flexDirection={"row"} autoWidth>
        <View autoWidth>
          <Icon Source={AnnouncementIcon} pathColor={"white"} size={20} />
        </View>
        {!isMinimized && (
          <View autoWidth noWrap>
            <Text
              color={"white"}
              bold={isActive}
              value={AppStrings.Announcements.communications}
              fontSize={isTabletOrMobile ? 16 : 14}
              marginLeft={5}
              marginRight={count ? 5 : 0}
            />
          </View>
        )}
      </View>
      {count > 0 && (
        <UnreadNotificationsBubble
          totalNotifications={count}
          maxTotalNotificationToShow={maxNotificationToShow}
          onTheBell={isMinimized}
          classNames={[notificationBubble]}
        />
      )}
    </View>
  );
};
