import React from "react";

import { useAtomValue } from "jotai";

import AppStrings from "../../../../locale/keys";
import type { DrawerItemRenderLabelComponentProps } from "DLUI/drawer/types";
import { CommunicationCenterMenuItemLabel } from "screens/communicationsCenter/components/communicationCenterMenuItemLabel";
import { conversationsInboxCountAtom } from "screens/communicationsCenter/shared/conversationsStatsAtom";

export const CommunicationCenterInboxMenuItemLabel = ({ isActive, tag }: DrawerItemRenderLabelComponentProps) => {
  const count = useAtomValue(conversationsInboxCountAtom);

  return (
    <CommunicationCenterMenuItemLabel
      count={count}
      label={AppStrings.CommunicationsCenter.Screen.MyInboxTitle}
      isActive={isActive}
      tag={tag}
    />
  );
};
