"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Vendors1099ReportItem = void 0;
const taxForm1099_dto_1 = require("../taxForms/taxForm1099.dto");
class Vendors1099ReportItem extends taxForm1099_dto_1.TaxForm1099Dto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.Vendors1099ReportItem = Vendors1099ReportItem;
