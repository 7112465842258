import { makeStyles, createStyles } from "@material-ui/styles";

import type { Theme } from "styles/defaultTheme";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBlock: 10,
      paddingInline: 15,
      margin: 0,

      backgroundColor: "white",
      overflowY: "auto",

      color: theme.newPalette.neutral.contrastText,
      fontSize: 14,
      fontWeight: 400,
      fontFamily: "Lato",
      letterSpacing: "-0.3px",

      boxSizing: "border-box",
      "-moz-box-sizing": "border-box",
      "-webkit-box-sizing": "border-box"
    },
    fieldsetBorder: {
      border: `1px solid ${theme.newPalette.input.outlined.enabledBorder}`,
      borderRadius: 5,

      "&:hover": {
        borderColor: theme.newPalette.input.outlined.hoverBorder
      }
    },
    fieldsetBorderFocused: {
      borderColor: `${theme.newPalette.secondary.main} !important`,
      borderWidth: "2px !important"
    },
    fieldsetBorderError: {
      borderRadius: 5,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: `${theme.newPalette.error.main} !important`
    },
    labelContainer: {
      position: "absolute",
      top: 4,
      left: 0,
      zIndex: 1,
      transform: "translate(15px, 15px) scale(1)",
      transformOrigin: "top left",
      transition: "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",

      display: "flex",
      flexDirection: "row",

      paddingRight: 10,
      gap: 2
    },
    labelContainerFocused: {
      paddingLeft: 12,
      transform: "translate(12px, -6px) scale(0.75)"
    },
    text: {
      fontSize: 14,
      fontWeight: 400,
      fontFamily: "Lato",
      letterSpacing: "-0.3px"
    },
    labelTextUnfocused: {
      color: theme.newPalette.text.secondary
    },
    labelTextFocused: {
      color: theme.newPalette.secondary.main
    },
    labelTextError: {
      color: theme.newPalette.error.main
    },
    errorText: {
      fontSize: "0.75rem",
      color: theme.newPalette.error.main
    },
    helperText: {
      fontSize: "0.75rem"
    },
    notchDefault: {
      width: "auto",
      height: 11,
      display: "block",
      padding: 0,
      maxWidth: 0.01,
      transition: "max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      visibility: "hidden",
      gap: 2,
      fontSize: "0.8rem",
      fontWeight: 400,
      fontFamily: "Lato",
      letterSpacing: "-0.3px"
    },
    notchActive: {
      maxWidth: 1000,
      transition: "max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms"
    }
  })
);
