import type { FC } from "react";
import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { SettingsSection } from "./settingsSection";
import { getSettingsItems } from "./settingsItems";
import AppStrings from "locale/keys";
import { useUserType } from "../../../../../../hooks/useUserType";
import { useFeatureFlag } from "../../../../../../hooks/useFeatureFlag";

export const UserRoleSettings: FC = ({}) => {
  const { isHOAUser } = useUserType();
  const { getIsFeatureActiveForDbTenant } = useFeatureFlag();

  const settingsItems = getSettingsItems(
    isHOAUser ? "HOAUser" : undefined,
    getIsFeatureActiveForDbTenant("CommunicationsCenter")
  );

  return (
    <View>
      <Text
        fontSize={16}
        color={"black"}
        value={AppStrings.UserRoles.SettingsHeaderText}
        marginLeft={20}
        marginRight={20}
        marginBottom={20}
      />
      <View paddingLeft={20} paddingRight={20} alignItems={"center"}>
        {settingsItems.map(({ sectionTitle, permissions }, index) => (
          <SettingsSection permissions={permissions} title={sectionTitle} key={index} />
        ))}
      </View>
    </View>
  );
};
