import type { ReactElement } from "react";
import React from "react";
import type { VisibleGridColumn } from "DLUI/lists/gridList/gridList";
import AppStrings from "../../../../locale/keys";
import type { ExcelExportColumnProps } from "@progress/kendo-react-excel-export";
import { ExcelExportColumn } from "@progress/kendo-react-excel-export";
import type { TFunction } from "react-i18next";
import { AccountingMethod } from "@doorloop/dto";
import { excelExport } from "../utils";
import { getColumnTotals } from "./utils";
import type { TextFormatType } from "DLUI/text/text";

const mapFormatTypeToExcelFormat: Record<TextFormatType, string | undefined> = {
  currency: "$#,##0.00_);[Red]($#,##0.00)",
  number: "#,##0.00_);[Red](#,##0.00)",
  percent: "0.00%",
  date: "mm/dd/yyyy",
  "relative-time": undefined,
  "plain-number": undefined,
  "date-time": undefined,
  email: undefined
};

function getFooterCellOptions(
  currentColumn: VisibleGridColumn,
  currencySymbol: string
): ExcelExportColumnProps["cellOptions"] {
  let format = mapFormatTypeToExcelFormat[currentColumn.formatType || ""];

  if (!format || currentColumn.formatType === "currency") {
    format = mapFormatTypeToExcelFormat.currency?.replaceAll("$", currencySymbol);
  }

  return {
    format
  };
}

function formatCellValues(cellValue: any, formatType: TextFormatType | undefined) {
  if (formatType === "percent" && cellValue > 0) {
    return cellValue / 100;
  }

  return cellValue;
}

const renderExcelExportColumn = (
  currentColumn: VisibleGridColumn,
  displayColumns: ReactElement[],
  index: number,
  currencySymbol: string,
  data: any[],
  t: TFunction,
  dataTotalLine?: Record<string, number>
) => {
  const cellOptions = getFooterCellOptions(currentColumn, currencySymbol);
  let footerCellOptions: ExcelExportColumnProps["cellOptions"] = {};
  const groupFooterCellOptions: ExcelExportColumnProps["cellOptions"] = {};

  let footer: undefined | Function;
  if (currentColumn.footerCell) {
    if (dataTotalLine && currentColumn.field && dataTotalLine[currentColumn.field]) {
      footerCellOptions = getFooterCellOptions(currentColumn, currencySymbol);
      footer = () => formatCellValues(dataTotalLine[currentColumn.field!], currentColumn.formatType);
    } else {
      footerCellOptions = getFooterCellOptions(currentColumn, currencySymbol);

      if (currentColumn.footerCell.text) {
        const footerText = t(currentColumn.footerCell.text);
        footer = () => footerText;
      }

      if (currentColumn.footerCell.sum && currentColumn.field) {
        const totals = getColumnTotals(data, currentColumn.field, currentColumn.showZeroValues);
        footer = () => formatCellValues(totals.sum, currentColumn.formatType);
      } else if (currentColumn.footerCell.average && currentColumn.field) {
        const totals = getColumnTotals(data, currentColumn.field, currentColumn.showZeroValues);
        footer = () => formatCellValues(totals.average, currentColumn.formatType);
      }
    }
  }

  let groupFooter: undefined | Function;
  if (currentColumn.groupFooterCell) {
    footerCellOptions = getFooterCellOptions(currentColumn, currencySymbol);
    if (currentColumn.groupFooterCell.totalForGroup) {
      groupFooter = (cellProps: any) =>
        t(AppStrings.Reports.TransactionsReport.TotalFor) + " " + cellProps.$implicit.group.value;
    }
    if (currentColumn.groupFooterCell.sum) {
      groupFooter = (cellProps: any) => {
        const totals = getColumnTotals(cellProps?.group?.items, cellProps.field, currentColumn.showZeroValues);

        return formatCellValues(totals.sum, currentColumn.formatType);
      };
    }
    if (currentColumn.groupFooterCell.average) {
      groupFooter = (cellProps: any) => {
        const totals = getColumnTotals(cellProps?.group?.items, cellProps.field, currentColumn.showZeroValues);

        return formatCellValues(totals.average, currentColumn.formatType);
      };
    }
    if (currentColumn.groupFooterCell.customCell) {
      groupFooter = (cellProps: any) => {
        const cellData = currentColumn!.groupFooterCell!.customCell!(cellProps.$implicit.group.items);
        return t(cellData[0]) + " " + t(cellData[1]);
      };
    }
  }

  let groupHeader: undefined | Function;
  if (currentColumn.groupHeader && currentColumn.groupHeader.customCell) {
    const customHeaderText = t(currentColumn.groupHeader.customCell());
    groupHeader = (_props: any) => customHeaderText + " " + _props.value;
  }

  displayColumns.push(
    <ExcelExportColumn
      key={`EXC${currentColumn.field}${index}`}
      field={currentColumn.field}
      title={t(currentColumn.title)}
      cellOptions={cellOptions}
      footer={footer}
      groupFooter={groupFooter}
      groupFooterCellOptions={groupFooterCellOptions}
      footerCellOptions={footerCellOptions}
      groupHeader={groupHeader}
    />
  );
};

export const renderExcelExportColumns = (
  gridColumns: VisibleGridColumn[],
  currencySymbol: string,
  data: any[],
  t: TFunction,
  dataTotalLine?: Record<string, number>
) => {
  const displayColumns: ReactElement[] = [];

  gridColumns.forEach((currentColumn, index) => {
    renderExcelExportColumn(currentColumn, displayColumns, index, currencySymbol, data, t, dataTotalLine);
    currentColumn.subColumns?.forEach((subColumn) =>
      renderExcelExportColumn(subColumn, displayColumns, index, currencySymbol, data, t, dataTotalLine)
    );
  });
  return displayColumns;
};

export const initiateExcelExport = (
  t: TFunction<"translation">,
  excelExporterRef: React.MutableRefObject<any>,
  filterObj: Record<string, any>,
  reportName: string,
  hideExcelGroupHeaderPrefix?: boolean
) => {
  if (excelExporterRef && excelExporterRef.current) {
    let reportDates: string;
    const { filter_date_from, filter_date_to, filter_accountingMethod } = filterObj as unknown as {
      filter_date_from?: string;
      filter_date_to?: string;
      filter_accountingMethod?: string;
    };

    let accountingMethod: string | undefined = undefined;

    if (!filter_date_from || !filter_date_to) {
      reportDates = t(AppStrings.Common.FilterPanelDatePicker.AllTime);
    } else {
      reportDates = filter_date_from + " " + filter_date_to;
    }

    if (filter_accountingMethod) {
      accountingMethod = "";
      if (filter_accountingMethod === AccountingMethod.ACCRUAL) {
        accountingMethod += t(AppStrings.Reports.TransactionsReport.AccountingMethod.Accrual);
      } else {
        accountingMethod += t(AppStrings.Reports.TransactionsReport.AccountingMethod.Cash);
      }
      accountingMethod += " " + t(AppStrings.Reports.TransactionsReport.AccountingMethod.Basis);
    }

    const workBook = excelExporterRef.current.workbookOptions();
    excelExport({
      excelExporterRef,
      reportDates,
      reportName,
      workBook,
      accountingMethod,
      hideExcelGroupHeaderPrefix
    });
  }
};
