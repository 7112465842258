import { useMemo } from "react";
import { unitsApi } from "api/unitsApi";
import AppStrings from "../../../../locale/keys";
import { useTranslation } from "react-i18next";
import { ProspectStatus } from "@doorloop/dto";
import type { RentalApplicationStatusType } from "screens/rentalApplications/useRentalApplicationStatus";

const RentalApplicationDisableByStatusTooltipMap: Partial<Record<RentalApplicationStatusType, string>> = {
  [ProspectStatus.APPLICATION_IN_PROGRESS]: AppStrings.Common.RentalApplicationInProgressTooltip
};

interface HookReturnType {
  unitText?: string | undefined;
  isUnitNotActiveForListing?: boolean;
  isActionButtonDisabled?: boolean;
  tooltipText?: string;
}

export const useRentalApplicationUnitData = (
  isTransunionPropertyDisabled: boolean,
  unitId?: string,
  rentalApplicationStatus?: RentalApplicationStatusType
): HookReturnType => {
  const { t } = useTranslation();
  const { isUnitNotActiveForListing, unitText } = useMemo(() => {
    let unitText: string | undefined;
    let isUnitNotActiveForListing = false;
    if (unitId) {
      const unitsDictionary = unitsApi.getItemFromDictionary(unitId);
      if (unitsDictionary) {
        unitText = unitsDictionary.name;
        isUnitNotActiveForListing = unitsDictionary.rentalApplicationListing?.activeListing === false;
      }
    }
    return { unitText, isUnitNotActiveForListing };
  }, [unitId]);

  const getCtaDisabledData = useMemo(() => {
    const rentalApplicationStatusDisableTooltip =
      rentalApplicationStatus && RentalApplicationDisableByStatusTooltipMap[rentalApplicationStatus];
    const isActionButtonDisabled =
      isTransunionPropertyDisabled || isUnitNotActiveForListing || Boolean(rentalApplicationStatusDisableTooltip);

    const rentalApplicationDisabledtooltipText =
      rentalApplicationStatusDisableTooltip ??
      (isUnitNotActiveForListing
        ? AppStrings.Common.RentalApplicationUnitIsNotOpenForListingTooltip
        : AppStrings.Common.RentalApplicationIsNotConfigured);

    return {
      isActionButtonDisabled,
      tooltipText: t(rentalApplicationDisabledtooltipText)
    };
  }, [isTransunionPropertyDisabled, isUnitNotActiveForListing]);

  return { isUnitNotActiveForListing, unitText, ...getCtaDisabledData };
};
