import React, { useEffect, useState } from "react";
import AppStrings from "../../../../../../locale/keys";
import EvictionStatusDialog from "DLUI/dialogs/settings/leases/evictionStatusDialog/evictionStatusDialog";
import { useParams } from "react-router-dom";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { View } from "DLUI/view";
import { Formik } from "formik";
import { LeaseDto } from "@doorloop/dto";
import { leasesApi } from "api/leasesApi";
import { createValidator } from "@doorloop/dto";

interface Props {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
}

export const EvictionDialogFrameWidth = Math.min(window.innerWidth - 40, 800);
export const EvictionDialogFrameHeight = Math.min(window.innerWidth - 40, 500);

const validateForm = createValidator(LeaseDto);

const EvictionStatusFormikContextWrapper: React.FC<Props> = ({ onBackdropClick, onClose }: Props) => {
  const { leaseId } = useParams<any>();
  const editMode = leaseId !== undefined;
  const [leaseData, setLeaseData] = useState<LeaseDto | undefined>();
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    editMode ? DialogState.Show : DialogState.Hidden
  );
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");

  useEffect(() => {
    if (editMode) {
      loadData();
    }
  }, []);

  const loadData = async () => {
    if (editMode && leaseId) {
      setLoadingDialogState(DialogState.Show);
      const response = await leasesApi.get(leaseId).catch((error) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });
      if (response && response.status && response.data) {
        setLeaseData(response.data);
        setLoadingDialogState(DialogState.Hidden);
      } else {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(response ? response.message : "");
      }
    }
  };

  const initFormValues = (): LeaseDto => leaseData || new LeaseDto();

  if (loadingDialogState !== DialogState.Hidden) {
    return (
      <View
        width={EvictionDialogFrameWidth}
        height={EvictionDialogFrameHeight}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <LoadingDialog
          dialogState={loadingDialogState}
          errorText={loadingDialogErrorText}
          didPressDismissButton={onBackdropClick}
          onRetryButtonPress={loadData}
          minHeight={EvictionDialogFrameHeight}
        />
      </View>
    );
  }

  return (
    <Formik initialValues={initFormValues()} onSubmit={() => {}} validate={validateForm}>
      <EvictionStatusDialog
        onBackdropClick={onBackdropClick}
        onClose={onClose}
        dialogTitle={AppStrings.Leases.Settings.EvictionStatus}
      />
    </Formik>
  );
};

export default EvictionStatusFormikContextWrapper;
