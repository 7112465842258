import type { PropsWithChildren } from "react";
import React from "react";
import { Grid } from "@material-ui/core";
import type { GridProps, GridSize } from "@material-ui/core/Grid/Grid";

interface Props extends GridProps {
  autoHeight?: boolean;
  preset?: PresetNames;
}

interface Preset {
  lg: GridSize;
  md: GridSize;
  sm: GridSize;
  xs: GridSize;
}

type PresetNames = "66612" | "4444" | "44612" | "33612";

const presetsMap: Record<PresetNames, Preset> = {
  "66612": {
    lg: 6,
    md: 6,
    sm: 6,
    xs: 12
  },
  "4444": {
    lg: 4,
    md: 4,
    sm: 4,
    xs: 4
  },
  "44612": {
    lg: 4,
    md: 4,
    sm: 6,
    xs: 12
  },
  "33612": {
    lg: 3,
    md: 3,
    sm: 6,
    xs: 12
  }
};

const BasicGrid: React.FC<Props> = ({ autoHeight, preset, children, ...rest }: PropsWithChildren<any>) => {
  const presetSettings = presetsMap[preset] || {};
  return (
    <Grid
      alignItems={"center"}
      style={{ height: autoHeight ? "auto" : "100%" }}
      xs={12}
      sm={4}
      item
      container
      {...presetSettings}
      {...rest}
    >
      {children}
    </Grid>
  );
};

export default BasicGrid;
