"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkedResourceType = void 0;
var LinkedResourceType;
(function (LinkedResourceType) {
    LinkedResourceType["LeaseCharge"] = "LEASE_CHARGE";
    LinkedResourceType["LeasePayment"] = "LEASE_PAYMENT";
    LinkedResourceType["LeaseReversedPayment"] = "LEASE_REVERSED_PAYMENT";
    LinkedResourceType["LeaseRefund"] = "LEASE_REFUND";
    LinkedResourceType["LeaseCredit"] = "LEASE_CREDIT";
    LinkedResourceType["Expense"] = "EXPENSE";
    LinkedResourceType["VendorBill"] = "BILL";
    LinkedResourceType["VendorBillPayment"] = "BILL_PAYMENT";
    LinkedResourceType["Deposit"] = "BANK_DEPOSIT";
    LinkedResourceType["GeneralEntry"] = "GENERAL_ENTRY";
    LinkedResourceType["Reconciliation"] = "RECONCILIATION";
    LinkedResourceType["Transfer"] = "TRANSFER";
    LinkedResourceType["ManagementFee"] = "MANAGEMENT_FEE";
    LinkedResourceType["OwnerContribution"] = "OWNER_CONTRIBUTION";
    LinkedResourceType["OwnerDistribution"] = "OWNER_DISTRIBUTION";
    LinkedResourceType["VendorCredit"] = "VENDOR_CREDIT";
    LinkedResourceType["Tenant"] = "TENANT";
    LinkedResourceType["Prospect"] = "PROSPECT";
    LinkedResourceType["Property"] = "PROPERTY";
    LinkedResourceType["Unit"] = "UNIT";
    LinkedResourceType["Owner"] = "OWNER";
    LinkedResourceType["File"] = "FILE";
    LinkedResourceType["Lease"] = "LEASE";
    LinkedResourceType["LeaseDraft"] = "LEASE_DRAFT";
    LinkedResourceType["Vendor"] = "VENDOR";
    LinkedResourceType["User"] = "USER";
    LinkedResourceType["LeaseRenewalOffer"] = "LEASE_RENEWAL_OFFER";
    LinkedResourceType["Note"] = "NOTE";
    LinkedResourceType["RecurringTransaction"] = "RECURRING_TRANSACTION";
    LinkedResourceType["RecurringTransactionGeneralEntry"] = "RECURRING_TRANSACTION_GENERAL_ENTRY";
    LinkedResourceType["RecurringTransactionRent"] = "RECURRING_TRANSACTION_RENT";
    LinkedResourceType["RecurringTransactionCharge"] = "RECURRING_TRANSACTION_CHARGE";
    LinkedResourceType["RecurringTransactionCredit"] = "RECURRING_TRANSACTION_CREDIT";
    LinkedResourceType["RecurringTransactionPayment"] = "RECURRING_TRANSACTION_PAYMENT";
    LinkedResourceType["RecurringTransactionVendorBill"] = "RECURRING_TRANSACTION_VENDOR_BILL";
    LinkedResourceType["RecurringTransactionExpense"] = "RECURRING_TRANSACTION_EXPENSE";
    LinkedResourceType["RecurringTransactionTask"] = "RECURRING_TASK";
    LinkedResourceType["Task"] = "TASK";
    LinkedResourceType["RentalApplication"] = "RENTAL_APPLICATION";
    LinkedResourceType["InsurancePolicy"] = "INSURANCE_POLICY";
    LinkedResourceType["Announcement"] = "ANNOUNCEMENT";
    LinkedResourceType["Communication"] = "COMMUNICATION";
    LinkedResourceType["Conversation"] = "CONVERSATION";
    LinkedResourceType["ESignatureRequest"] = "ESIGNATURE_REQUEST";
    LinkedResourceType["ESignatureTemplate"] = "ESIGNATURE_TEMPLATE";
})(LinkedResourceType || (exports.LinkedResourceType = LinkedResourceType = {}));
