import { CheckGray, DescriptionGray, GavelGray, SendGray } from "../../../../assets";
import type { StatusStepInterface } from "DLUI/stepStatus/stepStatus";
import { ProspectStatus, RentalApplicationDecisionStatus, ScreeningRequestReportsStatus } from "@doorloop/dto";
import AppStrings from "../../../../locale/keys";
import type { RentalApplicationStatusType } from "screens/rentalApplications/rentalApplicationsList/RentalApplicationListItem";

const gray = "secondary-gray-light";

export enum StatusStepCategoryGroups {
  OnlyUS = "OnlyUS"
}

export const NOT_STARTED_STEPS: StatusStepInterface[] = [
  {
    IconSource: SendGray,
    connectorColor: gray,
    tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.NotSent,
    isRawText: false
  },
  {
    IconSource: CheckGray,
    connectorColor: gray,
    tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.NotSubmitted,
    isRawText: false
  },
  {
    IconSource: DescriptionGray,
    connectorColor: gray,
    tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.RequestReports,
    isRawText: false,
    showConnector: false,
    categoryGroup: StatusStepCategoryGroups.OnlyUS
  },
  {
    IconSource: GavelGray,
    connectorColor: gray,
    tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.NoDecision,
    isRawText: false
  }
];

export const RENTAL_APPLICATION_STATUS_STEPS_MAP: {
  [key in RentalApplicationStatusType]?: StatusStepInterface[];
} = {
  [ProspectStatus.APPLICATION_SENT]: [
    {
      IconSource: SendGray,
      rectFill: "dark-green",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.ApplicationSent,
      isRawText: false
    },
    {
      IconSource: CheckGray,
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.NotSubmitted,
      isRawText: false
    },
    {
      IconSource: DescriptionGray,
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.RequestReports,
      isRawText: false,
      showConnector: false,
      categoryGroup: StatusStepCategoryGroups.OnlyUS
    },
    {
      IconSource: GavelGray,
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.NoDecision,
      isRawText: false
    }
  ],
  [ProspectStatus.APPLICATION_IN_PROGRESS]: [
    {
      IconSource: SendGray,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.ApplicationSent,
      isRawText: false
    },
    {
      IconSource: CheckGray,
      rectFill: "warning",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.InProgressStep,
      replaceObject: {
        step: ""
      },
      isRawText: false
    },
    {
      IconSource: DescriptionGray,
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.RequestReports,
      isRawText: false,
      showConnector: false,
      categoryGroup: StatusStepCategoryGroups.OnlyUS
    },
    {
      IconSource: GavelGray,
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.NoDecision,
      isRawText: false
    }
  ],
  [ProspectStatus.APPLICATION_SUBMITTED]: [
    {
      IconSource: SendGray,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.ApplicationSent,
      isRawText: false
    },
    {
      IconSource: CheckGray,
      rectFill: "dark-green",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.Submitted,
      isRawText: false
    },
    {
      IconSource: DescriptionGray,
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.RequestReports,
      isRawText: false,
      showConnector: false,
      categoryGroup: StatusStepCategoryGroups.OnlyUS
    },
    {
      IconSource: GavelGray,
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.NoDecision,
      isRawText: false
    }
  ],
  [ProspectStatus.CLOSED_MOVED_IN]: [
    {
      IconSource: SendGray,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.ApplicationSent,
      isRawText: false
    },
    {
      IconSource: CheckGray,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.Submitted,
      isRawText: false
    },
    {
      IconSource: DescriptionGray,
      rectFill: "dark-green",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.ReportsAvailable,
      isRawText: false,
      showConnector: false,
      categoryGroup: StatusStepCategoryGroups.OnlyUS
    },
    {
      IconSource: GavelGray,
      rectFill: "dark-green",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.DecisionApproved,
      isRawText: false
    }
  ],
  [ScreeningRequestReportsStatus.REPORTS_DELIVERY_IN_PROGRESS]: [
    {
      IconSource: SendGray,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.ApplicationSent,
      isRawText: false
    },
    {
      IconSource: CheckGray,
      rectFill: "dark-green",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.Submitted,
      isRawText: false
    },
    {
      IconSource: DescriptionGray,
      rectFill: "warning",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.InProgress,
      isRawText: false,
      showConnector: false,
      categoryGroup: StatusStepCategoryGroups.OnlyUS
    },
    {
      IconSource: GavelGray,
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.NoDecision,
      isRawText: false
    }
  ],
  [ScreeningRequestReportsStatus.IDENTITY_VERIFICATION_FAILED]: [
    {
      IconSource: SendGray,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.ApplicationSent,
      isRawText: false
    },
    {
      IconSource: CheckGray,
      rectFill: "dark-green",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.Submitted,
      isRawText: false
    },
    {
      IconSource: DescriptionGray,
      rectFill: "error",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.IdentityUnverified,
      isRawText: false,
      showConnector: false,
      categoryGroup: StatusStepCategoryGroups.OnlyUS
    },
    {
      IconSource: GavelGray,
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.NoDecision,
      isRawText: false
    }
  ],
  [ScreeningRequestReportsStatus.ADDRESS_NOT_VALID]: [
    {
      IconSource: SendGray,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.ApplicationSent,
      isRawText: false
    },
    {
      IconSource: CheckGray,
      rectFill: "dark-green",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.Submitted,
      isRawText: false
    },
    {
      IconSource: DescriptionGray,
      rectFill: "error",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.IdentityUnverified,
      isRawText: false,
      showConnector: false,
      categoryGroup: StatusStepCategoryGroups.OnlyUS
    },
    {
      IconSource: GavelGray,
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.NoDecision,
      isRawText: false
    }
  ],
  [ScreeningRequestReportsStatus.READY_FOR_REPORT_REQUEST]: [
    {
      IconSource: SendGray,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.ApplicationSent,
      isRawText: false
    },
    {
      IconSource: CheckGray,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.Submitted,
      isRawText: false
    },
    {
      IconSource: DescriptionGray,
      rectFill: "warning",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.RequestReports,
      isRawText: false,
      showConnector: false,
      categoryGroup: StatusStepCategoryGroups.OnlyUS
    },
    {
      IconSource: GavelGray,
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.NoDecision,
      isRawText: false
    }
  ],
  [ScreeningRequestReportsStatus.IDENTITY_VERIFICATION_PENDING]: [
    {
      IconSource: SendGray,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.ApplicationSent,
      isRawText: false
    },
    {
      IconSource: CheckGray,
      rectFill: "dark-green",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.Submitted,
      isRawText: false
    },
    {
      IconSource: DescriptionGray,
      rectFill: "warning",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.InProgress,
      isRawText: false,
      showConnector: false,
      categoryGroup: StatusStepCategoryGroups.OnlyUS
    },
    {
      IconSource: GavelGray,
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.NoDecision,
      isRawText: false
    }
  ],
  [ScreeningRequestReportsStatus.REPORTS_DELIVERY_FAILED]: [
    {
      IconSource: SendGray,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.ApplicationSent,
      isRawText: false
    },
    {
      IconSource: CheckGray,
      rectFill: "dark-green",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.Submitted,
      isRawText: false
    },
    {
      IconSource: DescriptionGray,
      rectFill: "error",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.ReportsFailed,
      isRawText: false,
      showConnector: false,
      categoryGroup: StatusStepCategoryGroups.OnlyUS
    },
    {
      IconSource: GavelGray,
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.NoDecision,
      isRawText: false
    }
  ],
  [ScreeningRequestReportsStatus.SCREENING_REQUEST_EXPIRED]: [
    {
      IconSource: SendGray,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.ApplicationSent,
      isRawText: false
    },
    {
      IconSource: CheckGray,
      rectFill: "dark-green",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.Submitted,
      isRawText: false
    },
    {
      IconSource: DescriptionGray,
      rectFill: "error",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.Expired,
      isRawText: false,
      showConnector: false,
      categoryGroup: StatusStepCategoryGroups.OnlyUS
    },
    {
      IconSource: GavelGray,
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.NoDecision,
      isRawText: false
    }
  ],
  [ScreeningRequestReportsStatus.REPORTS_DELIVERY_SUCCESS]: [
    {
      IconSource: SendGray,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.ApplicationSent,
      isRawText: false
    },
    {
      IconSource: CheckGray,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.Submitted,
      isRawText: false
    },
    {
      IconSource: DescriptionGray,
      rectFill: "dark-green",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.ReportsAvailable,
      isRawText: false,
      showConnector: false,
      categoryGroup: StatusStepCategoryGroups.OnlyUS
    },
    {
      IconSource: GavelGray,
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.NoDecision,
      isRawText: false
    }
  ],
  [RentalApplicationDecisionStatus.NOT_STARTED]: [
    {
      IconSource: SendGray,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.ApplicationSent,
      isRawText: false
    },
    {
      IconSource: CheckGray,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.Submitted,
      isRawText: false
    },
    {
      IconSource: DescriptionGray,
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.RequestReports,
      isRawText: false,
      showConnector: false,
      categoryGroup: StatusStepCategoryGroups.OnlyUS
    },
    {
      IconSource: GavelGray,
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.NoDecision,
      isRawText: false
    }
  ],
  [RentalApplicationDecisionStatus.APPROVED]: [
    {
      IconSource: SendGray,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.ApplicationSent,
      isRawText: false
    },
    {
      IconSource: CheckGray,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.Submitted,
      isRawText: false
    },
    {
      IconSource: DescriptionGray,
      rectFill: "dark-green",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.ReportsAvailable,
      isRawText: false,
      showConnector: false,
      categoryGroup: StatusStepCategoryGroups.OnlyUS
    },
    {
      IconSource: GavelGray,
      rectFill: "dark-green",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.DecisionApproved,
      isRawText: false
    }
  ],
  [RentalApplicationDecisionStatus.IGNORED]: [
    {
      IconSource: SendGray,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.ApplicationSent,
      isRawText: false
    },
    {
      IconSource: CheckGray,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.Submitted,
      isRawText: false
    },
    {
      IconSource: DescriptionGray,
      rectFill: "dark-green",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.ReportsAvailable,
      isRawText: false,
      showConnector: false,
      categoryGroup: StatusStepCategoryGroups.OnlyUS
    },
    {
      IconSource: GavelGray,
      rectFill: "warning",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.DecisionIgnored,
      isRawText: false
    }
  ],
  [RentalApplicationDecisionStatus.REJECTED]: [
    {
      IconSource: SendGray,
      rectFill: "dark-green",
      connectorColor: "green",
      isRawText: false,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.ApplicationSent
    },
    {
      IconSource: CheckGray,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.Submitted,
      isRawText: false
    },
    {
      IconSource: DescriptionGray,
      rectFill: "dark-green",
      connectorColor: gray,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.ReportsAvailable,
      isRawText: false,
      showConnector: false,
      categoryGroup: StatusStepCategoryGroups.OnlyUS
    },
    {
      IconSource: GavelGray,
      rectFill: "error",
      connectorColor: gray,
      isRawText: false,
      tooltip: AppStrings.Common.RentalApplicationListStatusTooltip.DecisionRejected
    }
  ]
};
