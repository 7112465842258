import React, { useEffect } from "react";
import type { LeaseDto } from "@doorloop/dto";
import { DataCy } from "@doorloop/dto";

import { EntitySelector, EntityType } from "DLUI/dialogs/components/entitySelector";
import { entityApiStore } from "api/entityApiStore/entityApiStore";

interface ComponentProps {
  leaseId?: string;
  didPressSelectLease?: () => void;
  errorTex?: string;
  onLeaseSelection?: (lease: LeaseDto) => void;
  viewOnly?: boolean;
}

const LeaseSelection = ({ leaseId, didPressSelectLease, errorTex, onLeaseSelection, viewOnly }: ComponentProps) => {
  const _didPressSelectLease = () => {
    if (viewOnly) {
      return;
    }

    didPressSelectLease?.();
  };

  const { data: response, isLoading } = entityApiStore.leases.queries.useGetOne(leaseId, {
    staleTime: Infinity
  });
  const leaseDto = response?.data;

  useEffect(() => {
    if (!leaseDto) {
      return;
    }

    onLeaseSelection?.(leaseDto);
  }, [leaseDto, onLeaseSelection]);

  return (
    <EntitySelector
      type={EntityType.LEASE}
      onClick={_didPressSelectLease}
      isLoading={isLoading}
      valueText={leaseDto?.name}
      error={errorTex}
      dataCy={DataCy.entitySelector.lease}
    />
  );
};

export default LeaseSelection;
