import {
  LeaseCreditDto,
  LinkedResourceType,
  ObjectPermission,
  shouldBlockFeatureForSubscriptionPriceId
} from "@doorloop/dto";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import { DialogTitleActionButton } from "DLUI/dialogs/components/dialogTitleActionButton";
import BulkCreditDialog from "DLUI/dialogs/transactions/bulkTransactions/credit";
import { DialogRoutes } from "DLUI/screen/dialogsProvider";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { ArticleIdsEnum, HelpTypeEnum, VideoUrlsEnum } from "DLUI/screen/helpPanel/types";
import { leaseCreditApi } from "api/leaseCreditApi";
import { Feature } from "config/featureFlags/featureFlags";
import AppStrings from "locale/keys";
import React, { useMemo } from "react";
import Transaction from "../transaction";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";
import { useUserType } from "../../../../../hooks/useUserType";

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
}

const helpObject: HelpPanelProps = {
  description: AppStrings.Leases.LeaseCharge.IssueCreditHelpPanel.Description,
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Leases.LeaseCharge.IssueCreditHelpPanel.Create,
      articleId: ArticleIdsEnum.ISSUE_LEASE_CREDIT
    },
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Leases.LeaseCharge.IssueCreditHelpPanel.EditDelete,
      articleId: ArticleIdsEnum.EDIT_DELETE_LEASE_TRANSACTIONS
    },
    {
      type: HelpTypeEnum.WATCH_VIDEO,
      topic: AppStrings.Common.WatchExample,
      href: VideoUrlsEnum.LEASE_OVERVIEW,
      dialogTitle: AppStrings.Leases.LeaseCharge.IssueCreditHelpPanel.DialogTitle,
      startTime: 224
    }
  ]
};

const helpObjectHOA: HelpPanelProps = {
  description: AppStrings.Leases.LeaseCharge.IssueCreditHelpPanel.Description,
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Leases.LeaseCharge.IssueCreditHelpPanel.Create,
      articleId: ArticleIdsEnum.HOA_ISSUE_CREDIT
    },
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Leases.LeaseCharge.IssueCreditHelpPanel.EditDelete,
      articleId: ArticleIdsEnum.HOA_EDIT_OR_DELETE_CREDIT
    },
    {
      type: HelpTypeEnum.WATCH_VIDEO,
      topic: AppStrings.Common.WatchExample,
      href: VideoUrlsEnum.LEASE_OVERVIEW,
      dialogTitle: AppStrings.Leases.LeaseCharge.IssueCreditHelpPanel.DialogTitle,
      startTime: 224
    }
  ]
};

const OneTimeCreditDialog: React.FC<ComponentProps> = ({ onBackdropClick, onClose }: ComponentProps) => {
  const { isHOAUser } = useUserType();

  const subscriptionPriceId = useSelector(
    (state: IRootState) => state.auth.currentLoginResponse?.currentDbTenant?.subscriptionPlan?.price_id
  );
  const bulkCreditFeatureFlag =
    Feature.isActive("BulkCharges") &&
    !shouldBlockFeatureForSubscriptionPriceId(ObjectPermission.bulkOperations, subscriptionPriceId);
  const { isMobile } = useResponsiveHelper();
  const [isBulkMode, setIsBulkMode] = React.useState(location.pathname.indexOf(DialogRoutes.NEW_BULK_CREDIT) > -1);

  const dialogActionButtons = useMemo(() => {
    if (!bulkCreditFeatureFlag || isMobile) return;

    return (
      <DialogTitleActionButton
        text={AppStrings.Leases.LeaseTransaction.Credit.SwitchToBulkIssueCredits}
        onClick={() => setIsBulkMode(true)}
      />
    );
  }, [bulkCreditFeatureFlag, isMobile]);

  if (isBulkMode) {
    return (
      <BulkCreditDialog
        onBackdropClick={onBackdropClick}
        onClose={onClose}
        dialogTitleActions={
          <DialogTitleActionButton
            text={AppStrings.Leases.LeaseTransaction.Credit.SwitchToIssueCredit}
            onClick={() => setIsBulkMode(false)}
          />
        }
      />
    );
  }

  return (
    <Transaction
      onBackdropClick={onBackdropClick}
      onClose={onClose}
      dialogTitle={AppStrings.Leases.LeaseCharge.OneTimeCredit}
      apiMethod={leaseCreditApi}
      dialogTitleActions={dialogActionButtons}
      transactionDto={LeaseCreditDto.prototype}
      transactionCreatedText={AppStrings.Leases.LeaseTransaction.Credit.CreatedText}
      loadingTransactionDataText={AppStrings.Leases.LeaseTransaction.Credit.LoadingData}
      loadingTransactionDataSuccessText={AppStrings.Leases.LeaseTransaction.Credit.LoadingDataSuccess}
      updatingTransactionText={AppStrings.Leases.LeaseTransaction.Credit.Updating}
      transactionUpdatedText={AppStrings.Leases.LeaseTransaction.Credit.Updated}
      type={"oneTime"}
      dialogFrameHeight={getDialogFrameDimension("height", 750)}
      linkedResourceType={LinkedResourceType.LeaseCredit}
      permission={ObjectPermission.leaseCredits}
      customDateLabel={AppStrings.Leases.NewLease.LeaseRent.DateIssued}
      helpObject={!isHOAUser ? helpObject : helpObjectHOA}
      accountFilterClass={"filter_leaseCreditItem"}
      filesSharedFormikFieldName={"isFilesSharedWithTenant"}
    />
  );
};

export default OneTimeCreditDialog;
