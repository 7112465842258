import type { FC } from "react";
import React from "react";
import { useSelector } from "react-redux";
import type { IRootState } from "store";
import { useAddressToDisplay } from "hooks/useAddressToDisplay";
import Text from "DLUI/text";

interface TenantPortalLeaseSelectionLineProps {
  underline?: boolean;
  fontSize?: number;
  bold?: boolean;
}

export const TenantPortalLeaseSelectionLine: FC<TenantPortalLeaseSelectionLineProps> = ({
  underline = true,
  fontSize = 16,
  bold
}) => {
  const currentLease = useSelector((state: IRootState) => state.auth.currentLoginResponse?.currentLease);

  const { getAddressToDisplay } = useAddressToDisplay();

  return (
    <Text
      overFlow={"ellipsis"}
      numberOfLines={2}
      lineBreak={"auto"}
      underLine={underline}
      color={"white"}
      bold={bold}
      fontSize={fontSize}
      value={getAddressToDisplay(currentLease)}
    />
  );
};
