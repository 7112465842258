import { useParams } from "react-router-dom";
import { unitsApi } from "api/unitsApi";
import { propertiesApi } from "api/propertiesApi";
import { ownersApi } from "api/ownersApi";
import { usersApi } from "api/usersApi";
import { vendorsApi } from "api/vendorsApi";
import { userRolesApi } from "api/userRolesApi";

const apiToIdMap = new Map<any, string>([
  [unitsApi, "unitId"],
  [propertiesApi, "propertyId"],
  [ownersApi, "ownerId"],
  [vendorsApi, "vendorId"],
  [usersApi, "userId"],
  [userRolesApi, "roleId"]
]);

export const useParamObjectId = (api) => {
  const paramsObj = useParams();
  const foundKey = apiToIdMap.get(api) || ["tenantId", "prospectId", "accountId"].find((key) => paramsObj[key]);
  return foundKey && paramsObj[foundKey];
};
