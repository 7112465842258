import React from "react";
import type { BulkChargeAllocationDto, BulkCreditAllocationDto, LeaseTransactionWithUnitBaseDto } from "@doorloop/dto";
import { LeaseTransactionBaseDto } from "@doorloop/dto";
import { useFormikContext } from "formik";
import type { Operation } from "../../../../../engines/bulkOperationsEngine";
import { BulkExecutionDialogFrame } from "DLUI/bulkExecution/bulkExecutionDialogFrame";
import AppStrings from "../../../../../locale/keys";
import { leaseChargeApi } from "api/leaseChargeApi";
import { leaseCreditApi } from "api/leaseCreditApi";
import { useTranslation } from "react-i18next";
import { leasesApi } from "api/leasesApi";
import type { BulkTransactionDto } from "../formViews/allocationUtil";

interface ComponentProps {
  onClose: (batchId: string, shouldRefresh?: boolean) => void;
}

export const BulkTransactionExecution: React.FC<ComponentProps> = ({ onClose }) => {
  const formikContext = useFormikContext<BulkTransactionDto>();
  const { t } = useTranslation();

  const formatOngoingMessage = (type: "charge" | "credit", leaseId: string) => {
    const leaseName = leasesApi.getItemFromDictionary(leaseId)?.name ?? "";
    const fmt =
      type === "charge"
        ? AppStrings.Leases.LeaseCharge.ProcessingChargeForLeaseX
        : AppStrings.Leases.LeaseCredit.ProcessingCreditForLeaseX;

    return t(fmt, { replace: { leaseName } });
  };

  const getTotalAmount = (transaction: LeaseTransactionWithUnitBaseDto) =>
    transaction.lines?.reduce((acc, line) => acc + (line.amount ?? 0), 0) ?? 0;

  const getLines = (account: string, leaseId: string, transaction: LeaseTransactionWithUnitBaseDto) => {
    if (!transaction.lines) {
      return [];
    }

    return transaction.lines.map((line) => {
      return {
        amount: line.amount,
        memo: line.memo,
        account,
        leaseId
      };
    });
  };

  const getOperationForTransaction = (
    dto: BulkTransactionDto,
    batch: string,
    transaction: LeaseTransactionWithUnitBaseDto
  ): Operation => {
    if (!transaction.lease) {
      // This should never happen since we only process transactions that have a lease associated with them
      return {
        action: async () => {
          throw "No lease associated with transaction";
        },
        ongoingMessage: "Error"
      };
    }

    const transactionInfo = {
      credit: {
        api: leaseCreditApi,
        date: (dto as BulkCreditAllocationDto).issueDate,
        ongoingMessage: formatOngoingMessage("credit", transaction.lease!)
      },
      charge: {
        api: leaseChargeApi,
        date: (dto as BulkChargeAllocationDto).dueDate,
        ongoingMessage: formatOngoingMessage("charge", transaction.lease!)
      }
    }[dto.type];

    return {
      action: async () => {
        const leaseTransactionDto = new LeaseTransactionBaseDto({
          lines: getLines(dto.account, transaction.lease!, transaction),
          totalAmount: getTotalAmount(transaction),
          memo: transaction.lines?.[0]?.memo ?? "",
          date: transactionInfo.date,
          lease: transaction.lease,
          batch
        });

        const result = await transactionInfo.api.create(leaseTransactionDto);
        if (!result.status) {
          throw result.message;
        }
        return { message: t(AppStrings.Common.OperationCompleted) };
      },
      ongoingMessage: transactionInfo.ongoingMessage
    };
  };

  // random batch number to navigate to the transaction in the journal entries
  const batch = Array.from({ length: 24 }, () => "0123456789abcdef".charAt(Math.floor(Math.random() * 16))).join("");

  const operations: Operation[] = formikContext.values.transactions
    // only process transactions that are selected (`isSelected` is true)
    .filter((x) => x.isSelected)
    // map each transaction to an async operation
    ?.map((transaction) => getOperationForTransaction(formikContext.values, batch, transaction));

  return (
    <BulkExecutionDialogFrame operations={operations} closeHandler={(shouldRefresh) => onClose(batch, shouldRefresh)} />
  );
};
