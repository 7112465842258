import DLButton from "@/components/DLUI/button/dlButton/dlButton";
import { DLButtonSizesEnum } from "@/components/DLUI/button/dlButton/enums";
import AppStrings from "@/locale/keys";
import { NavigationManager } from "@/utils/navigation";
import { ObjectPermission } from "@doorloop/dto";

export const NewAnnouncementActionPanel = () => (
  <DLButton
    actionText={AppStrings.Announcements.newAnnouncement}
    onClick={() => NavigationManager.createNewAnnouncement()}
    icons={{ start: { isHidden: false } }}
    size={DLButtonSizesEnum.SMALL}
    clearance={{
      permission: ObjectPermission.announcements,
      field: "create"
    }}
  />
);
