import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    text: {
      fontFamily: "Lato",
      letterSpacing: "-0.3px"
    },
    paragraph: {
      fontSize: 14,
      fontWeight: 400
    },
    link: {
      textDecoration: "none",
      pointerEvents: "none"
    },
    bold: {
      fontWeight: 700
    },
    italic: {
      fontStyle: "italic"
    },
    underline: {
      textDecoration: "underline"
    },
    strikethrough: {
      textDecoration: "line-through"
    },
    underlineStrikethrough: {
      textDecoration: "underline line-through"
    }
  })
);
