"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskStatusForQuery = void 0;
var TaskStatusForQuery;
(function (TaskStatusForQuery) {
    TaskStatusForQuery["NOT_STARTED"] = "NOT_STARTED";
    TaskStatusForQuery["IN_PROGRESS"] = "IN_PROGRESS";
    TaskStatusForQuery["COMPLETED"] = "COMPLETED";
    TaskStatusForQuery["NOT_COMPLETED"] = "NOT_COMPLETED";
    TaskStatusForQuery["RECEIVED"] = "RECEIVED";
    TaskStatusForQuery["ARCHIVED"] = "ARCHIVED";
})(TaskStatusForQuery || (exports.TaskStatusForQuery = TaskStatusForQuery = {}));
