import { PlusCircleIcon } from "assets/icons";
import { Icon } from "DLUI/icon";
import { Link } from "DLUI/link";
import { View } from "DLUI/view";
import React from "react";
import Text from "DLUI/text";
import AppStrings from "locale/keys";

interface ComponentProps {
  onClick: () => void;
  buttonText?: string;
  dataCy?: string;
}

const AddNewFormikLineButton: React.FC<ComponentProps> = ({ onClick, buttonText, dataCy }: ComponentProps) => (
  <View marginTop={20} alignItems={"center"} justifyContent={"center"}>
    <Link onClick={onClick} hoverColor={"lightBlue"} data-cy={dataCy}>
      <View alignItems={"center"} justifyContent={"center"} flexDirection={"row"}>
        <Icon width={25} height={25} circleFill={"light-blue"} Source={PlusCircleIcon} />
        <Text color={"lightBlue"} value={buttonText || AppStrings.Tenants.NewTenant.AddAnother} marginLeft={10} />
      </View>
    </Link>
  </View>
);
export default AddNewFormikLineButton;
