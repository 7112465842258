import type { ComponentProps } from "react";
import React, { useMemo } from "react";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import { Grid, ListItemContainer } from "DLUI/listItems";
import type { DepositLinkedTransactionDto } from "@doorloop/dto";
import { ExpensePayToResourceType, JournalEntryType, PaymentMethod } from "@doorloop/dto";
import { useTranslation } from "react-i18next";
import { ControlledCheckBox, ReferenceLabel } from "DLUI/form";
import type { TenantsApi } from "api/tenantsApi";
import { tenantsApi } from "api/tenantsApi";
import type { OwnersApi } from "api/ownersApi";
import { ownersApi } from "api/ownersApi";
import type { VendorsApi } from "api/vendorsApi";
import { vendorsApi } from "api/vendorsApi";
import ListItemText from "DLUI/listItems/listItemText";
import { propertiesApi } from "api/propertiesApi";
import { unitsApi } from "api/unitsApi";

interface UndepositsEntryLineProps extends Pick<ComponentProps<typeof View>, "domRef"> {
  headerId: string;
  depositLinkedTransactionDto: DepositLinkedTransactionDto;
  onChangeSelected: (selected: boolean) => void;
}

const UndepositsEntryLine: React.FC<UndepositsEntryLineProps> = React.memo(
  ({ domRef, headerId, depositLinkedTransactionDto, onChangeSelected }) => {
    const { t } = useTranslation();
    const {
      linkedTransactionType,
      receivedFromResourceType,
      receivedFromResourceId,
      paymentMethod,
      reference,
      amount,
      transactionDate,
      checkInfo,
      ePayInfo,
      property,
      unit,
      selected = false
    } = depositLinkedTransactionDto;

    const typeText = useMemo(() => {
      let typeText = "";
      if (linkedTransactionType) {
        switch (linkedTransactionType) {
          case JournalEntryType.APPLICATION_FEE:
            typeText = AppStrings.Common.Enums.JournalEntryType.ApplicationFee;
            break;
          case JournalEntryType.DEPOSIT:
            typeText = AppStrings.Common.Enums.JournalEntryType.Deposit;
            break;
          case JournalEntryType.GENERAL_ENTRY:
            typeText = AppStrings.Common.Enums.JournalEntryType.GeneralEntry;
            break;
          case JournalEntryType.LEASE_CHARGE:
            typeText = AppStrings.Common.Enums.JournalEntryType.LeaseCharge;
            break;
          case JournalEntryType.LEASE_CREDIT:
            typeText = AppStrings.Common.Enums.JournalEntryType.LeaseCredit;
            break;
          case JournalEntryType.LEASE_PAYMENT:
            typeText = AppStrings.Common.Enums.JournalEntryType.LeasePayment;
            break;
          case JournalEntryType.LEASE_REFUND:
            typeText = AppStrings.Common.Enums.JournalEntryType.LeaseRefund;
            break;
          case JournalEntryType.OWNER_CONTRIBUTION:
            typeText = AppStrings.Common.Enums.JournalEntryType.OwnerContribution;
            break;
          case JournalEntryType.OWNER_DISTRIBUTION:
            typeText = AppStrings.Common.Enums.JournalEntryType.OwnerDistribution;
            break;
          case JournalEntryType.TRANSFER:
            typeText = AppStrings.Common.Enums.JournalEntryType.Transfer;
            break;
          case JournalEntryType.VENDOR_BILL:
            typeText = AppStrings.Common.Enums.JournalEntryType.VendorBill;
            break;
          case JournalEntryType.VENDOR_BILL_PAYMENT:
            typeText = AppStrings.Common.Enums.JournalEntryType.VendorBillPayment;
            break;
          case JournalEntryType.VENDOR_CREDIT:
            typeText = AppStrings.Common.Enums.JournalEntryType.VendorCredit;
            break;
          case JournalEntryType.LEASE_REVERSED_PAYMENT:
            typeText = AppStrings.Common.Enums.JournalEntryType.ReversedPayments;
            break;
        }
      }
      return typeText;
    }, []);

    const receivedFromText = useMemo(() => {
      let receivedFromName = "";
      let receivedFromTypeName = t(AppStrings.Tenants.Screen.Tenant);
      if (receivedFromResourceType && receivedFromResourceId) {
        let apiMethod: OwnersApi | TenantsApi | VendorsApi = tenantsApi;
        switch (receivedFromResourceType) {
          case ExpensePayToResourceType.OWNER:
            apiMethod = ownersApi;
            receivedFromTypeName = t(AppStrings.Owners.Screen.Owner);
            break;
          case ExpensePayToResourceType.VENDOR:
            apiMethod = vendorsApi;
            receivedFromTypeName = t(AppStrings.Vendors.Screen.Vendor);
            break;
        }

        const apiDictionary = apiMethod.getItemFromDictionary(receivedFromResourceId);
        if (apiDictionary?.name) {
          receivedFromName = `${apiDictionary.name} (${receivedFromTypeName})`;
        }
      }
      return receivedFromName;
    }, []);

    const paymentMethodText = useMemo(() => {
      if (!paymentMethod) {
        return "";
      }

      let paymentMethodText = t(`common.enums.paymentMethod.${paymentMethod}`) + " ";

      switch (paymentMethod) {
        case PaymentMethod.CHECK:
        case PaymentMethod.MONEY_ORDER:
          if (checkInfo?.checkNumber) {
            paymentMethodText += " " + checkInfo.checkNumber;
          }
          break;
        case PaymentMethod.EPAY:
          if (ePayInfo?.method && ePayInfo?.number) {
            paymentMethodText += `${t(`common.enums.ePayPaymentMethod.${ePayInfo.method}`)} ${ePayInfo.number}`;
          }
          break;
      }

      return paymentMethodText;
    }, [checkInfo, ePayInfo, paymentMethod]);

    const propertyAndUnitNames = () => {
      let propertyAndUnitString = "";
      if (property) {
        const propertyDictionary = propertiesApi.getItemFromDictionary(property);
        if (propertyDictionary?.name) {
          propertyAndUnitString = propertyDictionary.name;
        } else {
          return propertyAndUnitString;
        }
      }

      if (unit) {
        const unitDictionary = unitsApi.getItemFromDictionary(unit);
        if (unitDictionary?.name) {
          propertyAndUnitString += ` > ${unitDictionary.name}`;
        }
      }

      return propertyAndUnitString;
    };

    return (
      <ListItemContainer backgroundColor={"dark"} removeVerticalPadding id={headerId} size={"small"} domRef={domRef}>
        <Grid title={AppStrings.Tasks.WorkOrderDialog.PartsAndLaborsItemTitle} showDivider xs={12} md={3} lg={3}>
          <View alignItems={"center"} flexDirection={"row"}>
            <ControlledCheckBox
              checked={selected}
              onChange={onChangeSelected}
              labelValueProps={{
                text: typeText,
                placement: "end",
                clickable: true
              }}
            />
            <ReferenceLabel backgroundColor={"dark"} marginLeft={"auto"} marginRight={5} value={reference} />
          </View>
        </Grid>
        <Grid title={AppStrings.Common.ListHeader.Date} showDivider xs={12} md={1} lg={1}>
          <ListItemText value={transactionDate} formatType={"date"} color={"black"} />
        </Grid>
        <Grid
          title={`${t(AppStrings.Common.FilterOptions.Property)}-${t(AppStrings.Common.FilterOptions.Unit)}`}
          showDivider
          xs={3}
          md={2}
          lg={2}
        >
          <ListItemText color={"black"} numberOfLines={2}>
            {propertyAndUnitNames()}
          </ListItemText>
        </Grid>
        <Grid title={AppStrings.Leases.LeaseTransaction.Payment.ReceivedFrom} showDivider xs={3} md={2} lg={2}>
          <ListItemText color={"black"}>{receivedFromText}</ListItemText>
        </Grid>
        <Grid title={AppStrings.Leases.LeaseTransaction.Payment.PaymentMethod} showDivider xs={12} md={2} lg={2}>
          <ListItemText color={"black"}>{paymentMethodText}</ListItemText>
        </Grid>
        <Grid title={AppStrings.Leases.NewLease.LeaseRent.Amount} xs={12} md={2} lg={2}>
          <ListItemText align={"right"} formatType={"currency"} value={amount ? String(amount) : "0"} color={"black"} />
        </Grid>
      </ListItemContainer>
    );
  }
);

export default UndepositsEntryLine;
