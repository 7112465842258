import AppStrings from "../../../../locale/keys";
import Routes from "../../../appRouter/routes";
import { DataCy, LeaseTenantStatus, ObjectPermission } from "@doorloop/dto";
import { MainMenuLayersKeys } from "DLUI/drawer/items/mainMenuItems";
import { v4 as uuid } from "uuid";
import type { DrawerItemType, NavConfigProps } from "DLUI/drawer/types";
import { DrawerItemName, NavLevels } from "DLUI/drawer/types";

const tenantsMenuItem: DrawerItemType = {
  label: AppStrings.DrawerItems.Tenants,
  navKey: uuid(),
  navPath: [
    {
      name: Routes.TENANTS,
      queryParams: {
        filter_status: LeaseTenantStatus.CURRENT
      }
    }
  ],
  dataCy: DataCy.sideMenu.people.tenants,
  clearance: [
    {
      permission: ObjectPermission.tenants,
      field: "viewList"
    }
  ]
};
const ownersMenuItem: DrawerItemType = {
  label: AppStrings.DrawerItems.Owners,
  navKey: uuid(),
  navPath: [{ name: Routes.OWNERS }],
  dataCy: DataCy.sideMenu.people.owners,
  clearance: [
    {
      permission: ObjectPermission.owners,
      field: "viewList"
    }
  ]
};
const vendorsMenuIte: DrawerItemType = {
  label: AppStrings.DrawerItems.Vendors,
  navKey: uuid(),
  navPath: [{ name: Routes.VENDORS }],
  dataCy: DataCy.sideMenu.people.vendors,
  clearance: [
    {
      permission: ObjectPermission.vendors,
      field: "viewList"
    }
  ]
};
const prospectsMenuItem: DrawerItemType = {
  label: AppStrings.DrawerItems.Prospects,
  navKey: uuid(),
  navPath: [{ name: Routes.PROSPECTS }],
  dataCy: DataCy.sideMenu.people.prospects,
  clearance: [
    {
      permission: ObjectPermission.prospects,
      field: "viewList"
    }
  ]
};
export const SubMenuPeopleLayers: NavConfigProps = {
  title: AppStrings.DrawerItems.People,
  level: NavLevels.SUB_MENU,
  subMenuLayerKey: DrawerItemName.People,
  groups: [
    {
      groupName: AppStrings.DrawerItems.Directory,
      parentNavigationKey: MainMenuLayersKeys.People,
      items: [tenantsMenuItem, ownersMenuItem, vendorsMenuIte, prospectsMenuItem]
    }
  ]
};
export const SubMenuHOAUserPeopleLayers: NavConfigProps = {
  title: AppStrings.DrawerItems.People,
  level: NavLevels.SUB_MENU,
  groups: [
    {
      groupName: AppStrings.DrawerItems.Directory,
      parentNavigationKey: MainMenuLayersKeys.People,
      items: [tenantsMenuItem, vendorsMenuIte]
    }
  ]
};
