import type { CustomAllocationDto, GetAllCustomAllocationsQuery } from "@doorloop/dto";
import { ServerRoutes } from "@doorloop/dto";
import { RestApiBase } from "api/restApiBase";

export class CustomAllocationsApi extends RestApiBase<CustomAllocationDto, GetAllCustomAllocationsQuery> {
  constructor() {
    super(ServerRoutes.CUSTOM_ALLOCATIONS, []);
    this.restRoute = ServerRoutes.CUSTOM_ALLOCATIONS;
  }
}

export const customAllocationsApi = new CustomAllocationsApi();
