export default {
  OnBoardingDrawerTitle: "onBoarding.OnBoardingDrawerTitle",
  GetStartedWithDoorLoop: "onBoarding.GetStartedWithDoorLoop",
  HeroSubtitle: "onBoarding.HeroSubtitle",
  Skip: "onBoarding.Skip",
  WelcomeToDoorLoop: "onBoarding.WelcomeToDoorLoop",
  ThoughtBubbleText: "onBoarding.ThoughtBubbleText",
  ScheduleASupportCall: "onBoarding.ScheduleASupportCall",
  MarkAsCompleted: "onBoarding.MarkAsCompleted",
  MarkAsIncomplete: "onBoarding.MarkAsIncomplete",
  Schedule1On1PersonalTraining: "onBoarding.Schedule1On1PersonalTraining",
  ScheduleNow: "onBoarding.ScheduleNow",
  DontShowAtStartAgain: "onBoarding.DontShowAtStartAgain",
  AlwaysShowAtStart: "onBoarding.AlwaysShowAtStart",
  XoutofYUnits: "onBoarding.XoutofYUnits",
  XoutofYLeases: "onBoarding.XoutofYLeases",
  XoutofYProperties: "onBoarding.XoutofYProperties",
  Footer: {
    Title: "onBoarding.footer.Title",
    Links: {
      RentalApplications: {
        Title: "onBoarding.footer.Links.RentalApplications.title",
        Description: "onBoarding.footer.Links.RentalApplications.description"
      },
      Announcements: {
        Title: "onBoarding.footer.Links.Announcements.title",
        Description: "onBoarding.footer.Links.Announcements.description"
      },
      MarketingAndListings: {
        Title: "onBoarding.footer.Links.MarketingAndListings.title",
        Description: "onBoarding.footer.Links.MarketingAndListings.description"
      }
    }
  },
  StepTitles: {
    SubscribeToDoorLoop: "onBoarding.stepTitles.subscribeToDoorLoop",
    ScheduleAnOnBoardingSession: "onBoarding.stepTitles.scheduleAnOnBoardingSession",
    AddYourCompanyInfo: "onBoarding.stepTitles.addYourCompanyInfo",
    AddYourProperties: "onBoarding.stepTitles.addYourProperties",
    AcceptRentPayments: "onBoarding.stepTitles.acceptRentPayments",
    SetupYourAutoLateFees: "onBoarding.stepTitles.setupYourAutoLateFees",
    CustomizeYourTenantPortal: "onBoarding.stepTitles.customizeYourTenantPortal",
    AddYourLeases: "onBoarding.stepTitles.addYourLeases",
    SendAnnouncement: "onBoarding.stepTitles.sendAnnouncement",
    SmsMessaging: "onBoarding.stepTitles.smsMessaging"
  },
  StepDescriptions: {
    SubscribeToDoorLoop: "onBoarding.stepDescriptions.subscribeToDoorLoop",
    ScheduleAnOnBoardingSession: "onBoarding.stepDescriptions.scheduleAnOnBoardingSession",
    AddYourCompanyInfo: "onBoarding.stepDescriptions.addYourCompanyInfo",
    AddYourProperties: "onBoarding.stepDescriptions.addYourProperties",
    AcceptRentPayments: "onBoarding.stepDescriptions.acceptRentPayments",
    SetupYourAutoLateFees: "onBoarding.stepDescriptions.setupYourAutoLateFees",
    CustomizeYourTenantPortal: "onBoarding.stepDescriptions.customizeYourTenantPortal",
    AddYourLeases: "onBoarding.stepDescriptions.addYourLeases",
    SendAnnouncement: "onBoarding.stepDescriptions.sendAnnouncement",
    SmsMessaging: "onBoarding.stepDescriptions.smsMessaging"
  },
  ActionButtons: {
    ViewSubscription: "onBoarding.actionButtons.viewSubscription",
    SubscribeToDoorLoop: "onBoarding.actionButtons.subscribeToDoorLoop",
    ScheduleACall: "onBoarding.actionButtons.scheduleACall",
    AddYourCompanyInfo: "onBoarding.actionButtons.addYourCompanyInfo",
    AddYourProperties: "onBoarding.actionButtons.addYourProperties",
    AcceptRentPayments: "onBoarding.actionButtons.acceptRentPayments",
    SetupYourAutoLateFees: "onBoarding.actionButtons.setupYourAutoLateFees",
    CustomizeYourTenantPortal: "onBoarding.actionButtons.customizeYourTenantPortal",
    AddYourLeases: "onBoarding.actionButtons.addYourLeases",
    SendAnnouncement: "onBoarding.actionButtons.sendAnnouncement",
    SetupSms: "onBoarding.actionButtons.setupSms",
    StartTexting: "onBoarding.actionButtons.startTexting"
  }
};
