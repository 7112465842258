"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportResponseModelDetails = exports.GetRentalApplicationReportDataResponse = void 0;
class GetRentalApplicationReportDataResponse {
}
exports.GetRentalApplicationReportDataResponse = GetRentalApplicationReportDataResponse;
/**
 * The data contained in the report
 */
class ReportResponseModelDetails {
}
exports.ReportResponseModelDetails = ReportResponseModelDetails;
