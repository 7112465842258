import React from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import Text from "DLUI/text";
import type { ReportItem } from "./reportItems";
import { ReportSettingsRadioToggle } from "DLUI/reports/settings/reportSettingsRadioToggle";
import { SeparationLine } from "DLUI/separatorView";
import { View } from "DLUI/view";

interface ComponentProps {
  titleAppString?: string;
  items: ReportItem[];
}

export const ReportSection: React.FC<ComponentProps> = ({ titleAppString, items }: ComponentProps) => {
  const { t } = useTranslation();

  if (items.length === 0) {
    return null;
  }

  return (
    <View
      borderRadius={10}
      marginBottom={20}
      paddingLeft={20}
      paddingRight={20}
      backgroundColor={"white"}
      justifyContent={"center"}
    >
      {!titleAppString ? (
        <View flexDirection={"row"}>
          <View height={60} justifyContent={"center"}>
            <ReportSettingsRadioToggle
              fieldName={items[0].objectPath}
              label={t(items[0].titleAppString)}
              fontWeight={700}
            />
          </View>
        </View>
      ) : (
        <>
          <View height={60} justifyContent={"center"}>
            <Text fontWeight={700} value={titleAppString} />
          </View>
          <SeparationLine width={"100%"} height={1} />
          <Grid container>
            {items.map(({ titleAppString, objectPath }) => (
              <Grid item lg={4} xs={12} md={4} key={objectPath}>
                <View alignItems={"flex-start"} marginTop={5} justifyContent={"flex-start"}>
                  <ReportSettingsRadioToggle fieldName={objectPath} label={t(titleAppString)} />
                </View>
              </Grid>
            ))}
          </Grid>
          <View height={20} />
        </>
      )}
    </View>
  );
};
