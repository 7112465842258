import { useEffect, useState } from "react";
import { isEqual } from "lodash";

export function useOptimizedValue<TValue>(value: TValue): TValue {
  const [optimizedValue, setOptimizedValue] = useState<TValue>(value);

  useEffect(() => {
    setOptimizedValue((prevValue) => {
      if (isEqual(value, prevValue)) {
        return prevValue;
      }

      return value;
    });
  }, [value]);

  return optimizedValue;
}
