export interface SettingOptions {
  email?: {
    disabled?: boolean;
  };
  push?: {
    disabled?: boolean;
  };
  text?: {
    disabled?: boolean;
  };
}

export enum TenantPortalNotificationSettingsPrefNames {
  ANNOUNCEMENT = "announcement",
  REQUEST_UPDATED = "requestUpdated",
  REQUEST_RECEIVED = "requestReceived",
  LATE_FEE_CHARGED = "lateFeeCharged",
  PAYMENT_RETURNED = "paymentReturned",
  PAYMENT_RECEIVED = "paymentReceived",
  PAYMENT_RECEIPT = "paymentReceipt",
  AUTO_PAY_CANCELED = "autopayCanceled",
  RENT_CHARGED = "rentCharged",
  RENT_REMINDERS = "rentReminders",
  RENT_OVERDUE_REMINDERS = "rentOverdueReminders",
  BEFORE_LATE_FEE_CHARGED = "beforeLateFeeCharged"
}
