import type { GetAllTasksQuery, PostTaskUpdateDto, TaskDto } from "@doorloop/dto";
import { ServerRoutes } from "@doorloop/dto";
import { RestApiBase } from "./restApiBase";
import { usersApi } from "./usersApi";
import { ownersApi } from "./ownersApi";
import { tenantsApi } from "./tenantsApi";
import { unitsApi } from "./unitsApi";
import { propertiesApi } from "./propertiesApi";
import { vendorsApi } from "./vendorsApi";
import type { ApiToastsProps } from "./apiHelper";
import { apiHelper } from "./apiHelper";
import { leasesApi } from "./leasesApi";

class TasksApi extends RestApiBase<TaskDto, GetAllTasksQuery> {
  constructor() {
    super(ServerRoutes.TASKS, [usersApi, ownersApi, tenantsApi, propertiesApi, unitsApi, vendorsApi, leasesApi]);
    this.restRoute = ServerRoutes.TASKS;
  }
  getDetails = async (taskId: string) => {
    await this.loadDictionariesRequiredForGet();
    return await apiHelper.axiosGet(ServerRoutes.TASKS + "/" + taskId);
  };

  postUpdate = async (data: PostTaskUpdateDto, toasts?: ApiToastsProps<PostTaskUpdateDto>) =>
    await apiHelper.axiosPost({ url: ServerRoutes.TASKS_POST_UPDATE, data, toasts });
}

export const tasksApi = new TasksApi();
