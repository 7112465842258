import { Routes } from "components/appRouter";
import { EditReserveFundsDialog } from "DLUI/dialogs/settings";
import AppStrings from "locale/keys";
import { DialogRoutes } from "./dialogsRoutes";
import type { DialogProps } from "./dialogsProvider.types";

class ReportsDialogsProvider {
  matchRoutes(pathname: string): DialogProps | undefined {
    if (pathname.indexOf(DialogRoutes.EDIT_RESERVE_FUNDS) > -1 && pathname.indexOf(Routes.REPORTS) > -1) {
      return {
        type: "editReserveFunds",
        Content: EditReserveFundsDialog,
        title: AppStrings.Properties.Settings.PropertyReserveFunds
      };
    }

    return undefined;
  }
}

export const reportsDialogsProvider = new ReportsDialogsProvider();
