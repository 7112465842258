import AppStrings from "locale/keys";
import { Routes } from "components/appRouter";
import { useState } from "react";
import type { GetDepositDetailReportQuery } from "@doorloop/dto";
import { ReportPermission } from "@doorloop/dto";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import { GridList, GridLoadingSkeleton } from "DLUI/lists";
import GridHeader from "screens/reports/rentRoll/reportGridHeader";
import AccountingMethodFooter from "DLUI/lists/gridList/footers/accountingMethodFooter";
import { depositDetailApi } from "api/reportsApi";
import TransactionsReportSkeletonItem from "DLUI/loadingSkeleton/items/transactionsReportSkeletonItem";
import { depositDetailFilterOptions } from "screens/reports/depositDetailReport/depositDetailsFilterOptions";
import type { GridListProps } from "DLUI/lists/gridList/gridList";
import { viewTransactionDetails } from "DLUI/lists/utils";
import { buildDepositDetailGridColumns } from "screens/reports/depositDetailReport/depositDetailGridColumns";
import { useTypedTranslation } from "locale";
import Screen from "DLUI/screen";
import type { DepositDetailReportItem } from "@doorloop/dto/dist/reports/depositDetailReport";
import { useIntl } from "react-intl";
import { useFilterPanelQueryFilter } from "@/components/DLUI/screen/searchPanel/filterPanel/hooks/useFilterPanelQueryFilter";
import * as FilterPanel from "@/components/DLUI/screen/searchPanel/filterPanel";

export const DepositDetailReport = () => {
  const [requestInProgress, setRequestInProgress] = useState(false);
  const queryFilter = useFilterPanelQueryFilter(depositDetailFilterOptions);
  const { t } = useTypedTranslation();
  const { formatDate } = useIntl();

  const handleRequestInProgressChange = (isInProgress: boolean) => {
    setRequestInProgress(isInProgress);
  };

  const handleGridRowClick: GridListProps<DepositDetailReportItem, GetDepositDetailReportQuery>["onGridRowPress"] = ({
    dataItem
  }) => {
    if (dataItem?.["_type"] && dataItem?.journalEntryId) {
      viewTransactionDetails("deposit", location.pathname, dataItem.journalEntryId, location.search);
    }
  };

  return (
    <Screen
      requireAuth
      titleText={[
        {
          text: t("drawerItems.reports"),
          href: Routes.REPORTS
        }
      ]}
      subTitleText={{
        text: AppStrings.Reports.ReportsScreen.DepositDetail
      }}
      requestInProgress={requestInProgress}
    >
      <RestrictedPermissionAccess clearance={{ permission: ReportPermission.depositDetail }} showNoAccess>
        <FilterPanel.Root filterOptions={depositDetailFilterOptions}>
          <FilterPanel.InlineFilters />
          <FilterPanel.SideFilters />
        </FilterPanel.Root>
        <GridList
          reportName={t("reports.reportsScreen.depositDetail")}
          filterOptions={depositDetailFilterOptions}
          GridHeader={GridHeader}
          GridFooter={AccountingMethodFooter}
          gridColumns={buildDepositDetailGridColumns({ t, formatDate })}
          filterObj={queryFilter}
          onRequestinProgressChange={handleRequestInProgressChange}
          apiMethod={depositDetailApi}
          exportFileName={t("reports.reportsScreen.depositDetailReportFileName")}
          onGridRowPress={handleGridRowClick}
          initLoadingComponent={<GridLoadingSkeleton fullWidth SkeletonItem={TransactionsReportSkeletonItem} />}
          columnMenuItemsToHide={["depositReference", "notes"]}
          hideExcelGroupHeaderPrefix
          groupBy="depositReference"
        />
      </RestrictedPermissionAccess>
    </Screen>
  );
};
