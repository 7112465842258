import { useMemo } from "react";
import type { FormikProps } from "formik";

import type { DepositDto, DepositLinkedTransactionDto } from "@doorloop/dto";
import { mathUtils } from "@doorloop/dto";
import _ from "lodash";

interface Props {
  formik: FormikProps<DepositDto>;
  allRows: DepositLinkedTransactionDto[];
}

const useUndepositedLinesHelper = ({ formik, allRows }: Props) =>
  useMemo(() => {
    const lines = [...(formik.values?.lines || []), ...(formik.values?.linkedTransactions || [])];
    const linesTotalAmount = lines.length ? mathUtils.add(0, ...lines.map((line) => line?.amount || 0)) : 0;

    const totalLinesChecked = formik.values.linkedTransactions?.length;
    const areAllLinesChecked = Boolean(allRows.length === totalLinesChecked);
    const areAnyLinesChecked = !areAllLinesChecked && Boolean(totalLinesChecked);

    const onToggleLine = (indexALlRows: number) => {
      const row = allRows[indexALlRows];

      if (formik.values.linkedTransactions) {
        const indexSelected = formik.values.linkedTransactions.findIndex(
          (selectedRow) => selectedRow.linkedTransaction === row.linkedTransaction
        );

        const list = _.cloneDeep(formik.values.linkedTransactions);

        if (indexSelected !== -1) {
          list.splice(indexSelected, 1);
          formik.setFieldValue("linkedTransactions", list, false);
        } else {
          formik.setFieldValue("linkedTransactions", [...list, row], false);
        }
      } else {
        formik.setFieldValue("linkedTransactions", [row], false);
      }
    };

    const onToggleAllLines = () => {
      formik.setFieldValue(
        "linkedTransactions",
        areAllLinesChecked
          ? undefined
          : allRows.map((entry) => {
              return { ...entry, selected: true };
            }),
        false
      );
    };

    return {
      linesTotalAmount,
      totalLinesChecked,
      areAllLinesChecked,
      areAnyLinesChecked,
      onToggleLine,
      onToggleAllLines
    };
  }, [formik, allRows]);

export { useUndepositedLinesHelper };
