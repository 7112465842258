import type { ReactElement } from "react";
import React, { useMemo } from "react";
import { getRgbaColor } from "DLUI/colorPicker/colorPicker";
import { BackgroundImageView, View } from "DLUI/view";
import type { LoginThemeDto } from "@doorloop/dto";
import { THEME_LOCAL_STORAGE_KEY } from "screens/authScreen/layout/authLayout";
import { FormMaxWidth } from "DLUI/screen/tenantPortalScreen.constants";
import { SeparationLine } from "DLUI/separatorView";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { TenantPortalLeaseSelectionLine } from "screens/tenantPortal/components/tenantPortalLeaseSelectionLine";
import { tenantPortalNavigation } from "utils/tenantPortalNavigation";
import Text from "DLUI/text";

interface ComponentProps {
  children?: React.ReactNode;
  ActionPanel?: ReactElement<any, any>;
  title?: string;
  marginTop?: string;
  height?: number;
  maxWidth?: string | number;
  showLeaseSelectionUnderWelcome?: boolean;
  marginBottom?: boolean;
}

export const themeBackgroundImage = "url(/images/default_theme_background.jpg)";

const TenantPortalBackgroundView: React.FC<ComponentProps> = ({
  children,
  title,
  marginTop,
  height,
  maxWidth = FormMaxWidth,
  showLeaseSelectionUnderWelcome,
  marginBottom = true
}) => {
  const { isTabletOrMobile, isMobile, screenContainerPadding } = useResponsiveHelper();
  const theme: LoginThemeDto | undefined = useMemo(() => {
    const _theme = localStorage.getItem(THEME_LOCAL_STORAGE_KEY);
    if (_theme) {
      return JSON.parse(_theme) as LoginThemeDto;
    }
    return undefined;
  }, []);

  const contentMargin = isMobile ? 16 : -20;

  return (
    <View marginTop={marginTop} marginBottom={marginBottom ? contentMargin : undefined}>
      <BackgroundImageView
        publicFileName={theme?.backgroundUrl ? `url(${theme?.backgroundUrl})` : themeBackgroundImage}
        height={"auto"}
        minHeight={height || 300}
        backgroundSize={"cover"}
        backgroundPosition={!isTabletOrMobile ? "bottom -150px center" : undefined}
      >
        <View
          height={"100%"}
          className={"loginOpacityLayed"}
          style={{
            backgroundColor: getRgbaColor(theme?.color || "#2F3E83", "0.85")
          }}
          alignItems={"center"}
          justifyContent={"flex-start"}
          paddingLeft={isTabletOrMobile ? 20 : 0}
          paddingRight={isTabletOrMobile ? 20 : 0}
          noWrap
        >
          <View maxWidth={maxWidth} flexDirection={"row"} gap={8}>
            {title && (
              <View paddingRight={screenContainerPadding} marginTop={40} alignSelf={"self-end"} flexDirection={"row"}>
                <SeparationLine
                  dividerColor={"pink"}
                  height={showLeaseSelectionUnderWelcome ? 40 : 24}
                  width={5}
                  marginRight={10}
                />

                <View flex={1} gap={4}>
                  <Text
                    value={title || ""}
                    bold={showLeaseSelectionUnderWelcome}
                    fontSize={showLeaseSelectionUnderWelcome ? 14 : 20}
                    color={"white"}
                    overFlow={"ellipsis"}
                  />
                  {showLeaseSelectionUnderWelcome && (
                    <View flexDirection={"row"} onClick={tenantPortalNavigation.viewMyLease}>
                      <TenantPortalLeaseSelectionLine bold fontSize={14} />
                    </View>
                  )}
                </View>
              </View>
            )}
            {children}
          </View>
        </View>
      </BackgroundImageView>
    </View>
  );
};

export default TenantPortalBackgroundView;
