"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SegmentEventTypes = void 0;
var SegmentEventTypes;
(function (SegmentEventTypes) {
    SegmentEventTypes["VISITED_SIGNUP_PAGE"] = "Visited Signup Page";
    SegmentEventTypes["VISITED_LOGIN_PAGE"] = "Visited Login Page";
    SegmentEventTypes["VISITED_CONFIRM_SIGNUP_PAGE"] = "Visited Confirm Signup Page";
    SegmentEventTypes["LOGGED_IN"] = "Logged In";
    SegmentEventTypes["LEAD_SIGNED_UP"] = "Lead Signed Up";
    SegmentEventTypes["CONFIRMED_EMAIL"] = "Confirmed Email";
    SegmentEventTypes["INVITED__USER"] = "Invited User";
    SegmentEventTypes["VISITED_WELCOME_WIZARD_STEP_1"] = "Visited Welcome Wizard Step 1";
    SegmentEventTypes["VISITED_WELCOME_WIZARD_STEP_2"] = "Visited Welcome Wizard Step 2";
    SegmentEventTypes["VISITED_WELCOME_WIZARD_STEP_3"] = "Visited Welcome Wizard Step 3";
    SegmentEventTypes["COMPLETED_WELCOME_WIZARD"] = "Completed Welcome Wizard";
    SegmentEventTypes["RECEIVED_500_ERROR"] = "Received 500 Error";
    SegmentEventTypes["LEAD_FAILED_RECAPTCHA"] = "lead_failed_recaptcha";
    SegmentEventTypes["LEAD_PASSED_RECAPTCHA"] = "lead_passed_recaptcha";
    SegmentEventTypes["LEAD_SUBMITTED_FORM"] = "lead_submitted_form";
    SegmentEventTypes["LEAD_SUBMITTED_PERSONAL_INFO_FORM"] = "lead_submitted_personal_info_form";
    SegmentEventTypes["LEAD_AWAITING_CALLBACK"] = "lead_awaiting_callback";
    SegmentEventTypes["LEAD_SUBMITTED_COMPANY_INFO_FORM"] = "lead_submitted_company_info_form";
    SegmentEventTypes["LEAD_SUBMITTED_GOALS_AND_EXPERIENCE_FORM"] = "lead_submitted_goals_and_experience_form";
    SegmentEventTypes["LEAD_CONVERTED"] = "lead_converted";
    SegmentEventTypes["LEAD_EMAIL_INVALID"] = "lead_email_invalid";
    SegmentEventTypes["LEAD_SCHEDULED_DEMO"] = "lead_scheduled_demo";
    SegmentEventTypes["LEAD_SCHEDULED_CALL"] = "lead_scheduled_call";
    SegmentEventTypes["LEAD_CREATED"] = "lead_created";
    SegmentEventTypes["EXIT_INTENT_SHOWN"] = "exit_intent_shown";
    SegmentEventTypes["EXIT_INTENT_DISMISSED"] = "exit_intent_dismissed";
    SegmentEventTypes["EXIT_INTENT_ACTION_TAKEN"] = "exit_intent_action_taken";
    SegmentEventTypes["USER_CREATED_PROPERTY"] = "user_created_property";
    SegmentEventTypes["USER_CREATED_LEASE"] = "user_created_lease";
    SegmentEventTypes["USER_CREATED_MERCHANT_APPLICATION"] = "user_created_merchant_application";
    SegmentEventTypes["MERCHANT_APPLICATION_STATUS_CHANGED"] = "merchant_application_status_changed";
    SegmentEventTypes["MERCHANT_APPLICATION_STATUS_CHANGED_TO_SUBMITTED"] = "merchant_application_status_changed_to_submitted";
    SegmentEventTypes["MERCHANT_APPLICATION_STATUS_CHANGED_TO_ACTIVE"] = "merchant_application_status_changed_to_active";
    SegmentEventTypes["MERCHANT_APPLICATION_STATUS_CHANGED_TO_DECLINED"] = "merchant_application_status_changed_to_declined";
    SegmentEventTypes["MERCHANT_APPLICATION_STATUS_CHANGED_TO_CLOSED"] = "merchant_application_status_changed_to_closed";
    SegmentEventTypes["MERCHANT_APPLICATION_STATUS_CHANGED_TO_SAVED"] = "merchant_application_status_changed_to_saved";
    SegmentEventTypes["HELP_PANEL_ARTICLE_CLICK"] = "help_panel_article_click";
    SegmentEventTypes["USER_CLICKED_CREATE_NEW_MENU_ITEM"] = "user_clicked_create_new_menu_item";
    SegmentEventTypes["USER_CLICKED_VIEW_REPORT_ITEM"] = "user_clicked_view_report_item";
    /* Single receive payment */
    SegmentEventTypes["SINGLE_RP_SWITCH_MODE_TO_BULK"] = "single_rp_dialog_switch_mode_to_bulk";
    SegmentEventTypes["SINGLE_RP_CLOSE_BUTTON_CLICKED"] = "single_rp_dialog_close_button_clicked";
    SegmentEventTypes["SINGLE_RP_SUBMIT_BUTTON_CLICKED"] = "single_rp_dialog_submit_button_clicked";
    SegmentEventTypes["SINGLE_RP_CANCEL_BUTTON_CLICKED"] = "single_rp_dialog_cancel_button_clicked";
    SegmentEventTypes["SINGLE_RP_AUTOMATICALLY_PAY_TOGGLE_CLICKED"] = "single_rp_dialog_automatically_pay_toggle_clicked";
    SegmentEventTypes["SINGLE_RP_AUTOMATICALLY_DEPOSIT_CHECKBOX_CLICKED"] = "single_rp_dialog_automatically_deposit_checkbox_clicked";
    SegmentEventTypes["SINGLE_RP_SEND_PAYMENT_RECEIPT_CHECKBOX_CLICKED"] = "single_rp_dialog_send_payment_receipt_checkbox_clicked";
    SegmentEventTypes["SINGLE_RP_ADD_NOTES_FIELD_CLICKED"] = "single_rp_dialog_add_notes_field_clicked";
    SegmentEventTypes["SINGLE_RP_UPLOAD_FILE_CLICKED"] = "single_rp_dialog_upload_file_clicked";
    /* Bulk receive payment */
    SegmentEventTypes["BULK_RP_SWITCH_MODE_TO_SINGLE"] = "bulk_rp_dialog_switch_mode_to_single";
    SegmentEventTypes["BULK_RP_SEARCH_ENTER_PRESSED"] = "bulk_rp_dialog_search_enter_pressed";
    SegmentEventTypes["BULK_RP_CLOSE_BUTTON_CLICKED"] = "bulk_rp_dialog_close_button_clicked";
    SegmentEventTypes["BULK_RP_SUBMIT_BUTTON_CLICKED"] = "bulk_rp_dialog_submit_button_clicked";
    SegmentEventTypes["BULK_RP_CANCEL_BUTTON_CLICKED"] = "bulk_rp_dialog_cancel_button_clicked";
    SegmentEventTypes["BULK_RP_AUTOMATICALLY_PAY_TOGGLE_CLICKED"] = "bulk_rp_dialog_automatically_pay_toggle_clicked";
    SegmentEventTypes["BULK_RP_AUTOMATICALLY_DEPOSIT_CHECKBOX_CLICKED"] = "bulk_rp_dialog_automatically_deposit_checkbox_clicked";
    SegmentEventTypes["BULK_RP_SEND_PAYMENT_RECEIPT_CHECKBOX_CLICKED"] = "bulk_rp_dialog_send_payment_receipt_checkbox_clicked";
    SegmentEventTypes["BULK_RP_ADD_NOTES_FIELD_CLICKED"] = "bulk_rp_dialog_add_notes_field_clicked";
    SegmentEventTypes["BULK_RP_UPLOAD_FILE_CLICKED"] = "bulk_rp_dialog_upload_file_clicked";
    SegmentEventTypes["BUlK_RP_PAYMENT_REVIEW_DELETED"] = "bulk_rp_dialog_payment_review_deleted";
    SegmentEventTypes["ONBOARDING_SUBSCRIBED_COMPLETED"] = "onboarding_subscribed_completed";
    SegmentEventTypes["ONBOARDING_DEMO_SCHEDULED_COMPLETED"] = "onboarding_scheduled_demo_completed";
    SegmentEventTypes["ONBOARDING_ADDED_COMPANY_INFO_COMPLETED"] = "onboarding_added_company_info_completed";
    SegmentEventTypes["ONBOARDING_ADDED_PROPERTIES_COMPLETED"] = "onboarding_added_properties_completed";
    SegmentEventTypes["ONBOARDING_ACCEPTED_RENT_PAYMENTS_COMPLETED"] = "onboarding_accepted_rent_payments_completed";
    SegmentEventTypes["ONBOARDING_SETUP_AUTO_LATE_FEES_COMPLETED"] = "onboarding_setup_auto_late_fees_completed";
    SegmentEventTypes["ONBOARDING_CUSTOMIZED_TENANT_PORTAL_COMPLETED"] = "onboarding_customized_tenant_portal_completed";
    SegmentEventTypes["ONBOARDING_ADDED_LEASES_COMPLETED"] = "onboarding_added_leases_completed";
    SegmentEventTypes["ONBOARDING_SENT_ANNOUNCEMENT_COMPLETED"] = "onboarding_sent_announcement_completed";
    SegmentEventTypes["ONBOARDING_SETUP_SMS_MESSAGING_COMPLETED"] = "onboarding_setup_sms_messaging_completed";
    SegmentEventTypes["ONBOARDING_COMPLETED"] = "onboarding_completed";
    SegmentEventTypes["ONBOARDING_SUBSCRIBED_SKIPPED"] = "onboarding_subscribed_skipped";
    SegmentEventTypes["ONBOARDING_DEMO_SCHEDULED_SKIPPED"] = "onboarding_scheduled_demo_skipped";
    SegmentEventTypes["ONBOARDING_ADDED_COMPANY_INFO_SKIPPED"] = "onboarding_added_company_info_skipped";
    SegmentEventTypes["ONBOARDING_ADDED_PROPERTIES_SKIPPED"] = "onboarding_added_properties_skipped";
    SegmentEventTypes["ONBOARDING_ACCEPTED_RENT_PAYMENTS_SKIPPED"] = "onboarding_accepted_rent_payments_skipped";
    SegmentEventTypes["ONBOARDING_SETUP_AUTO_LATE_FEES_SKIPPED"] = "onboarding_setup_auto_late_fees_skipped";
    SegmentEventTypes["ONBOARDING_CUSTOMIZED_TENANT_PORTAL_SKIPPED"] = "onboarding_customized_tenant_portal_skipped";
    SegmentEventTypes["ONBOARDING_ADDED_LEASES_SKIPPED"] = "onboarding_added_leases_skipped";
    SegmentEventTypes["ONBOARDING_SENT_ANNOUNCEMENT_SKIPPED"] = "onboarding_sent_announcement_skipped";
    SegmentEventTypes["ONBOARDING_SETUP_SMS_MESSAGING_SKIPPED"] = "onboarding_setup_sms_messaging_skipped";
    SegmentEventTypes["ONBOARDING_SKIPPED"] = "onboarding_skipped";
    SegmentEventTypes["TENANT_INSURANCE_WELCOME_DIALOG_OPENED"] = "tenant_insurance_welcome_dialog_opened";
    SegmentEventTypes["TENANT_INSURANCE_WELCOME_DIALOG_CTA_CLICKED"] = "tenant_insurance_welcome_dialog_cta_clicked";
    SegmentEventTypes["TENANT_INSURANCE_STATE_SELECTION_DIALOG_CTA_CLICKED"] = "tenant_insurance_state_selection_dialog_cta_clicked";
    SegmentEventTypes["INSURANCE_PROMO_DIALOG_CTA_CLICKED"] = "insurance_promo_dialog_cta_clicked";
    SegmentEventTypes["RECEIVE_PAYMENTS_ACCEPT_PAYMENTS_STATUS"] = "receive_payments_accept_payments_status";
    SegmentEventTypes["RECEIVE_PAYMENTS_SEND_PAYMENTS_STATUS"] = "receive_payments_send_payments_status";
    SegmentEventTypes["RECEIVE_PAYMENTS_NEW_ACCOUNT_CTA_CLICKED"] = "receive_payments_new_account_cta_clicked";
    SegmentEventTypes["BANK_CONNECT_CONNECT_TO_BANK_CLICKED"] = "bank_connect_connect_to_bank_clicked";
    SegmentEventTypes["BANK_CONNECT_CONNECT_SUCCEEDED"] = "bank_connect_successfully_connected";
    SegmentEventTypes["BANK_CONNECT_UNDEPOSITED_FUNDS_CLICKED"] = "bank_connect_undeposited_funds_clicked";
    SegmentEventTypes["BANK_CONNECT_SETTING_CLICKED"] = "bank_connect_setting_clicked";
    SegmentEventTypes["BANK_CONNECT_SET_OPENING_BALANCE_CLICKED"] = "bank_connect_clicked_set_opening_balance";
    SegmentEventTypes["BANK_CONNECT_DID_SET_OPENING_BALANCE"] = "bank_connect_opening_balance_set";
    SegmentEventTypes["BANK_CONNECT_REPAIR_WITHOUT_ERROR_CLICKED"] = "bank_connect_clicked_repair_without_error ";
    SegmentEventTypes["BANK_CONNECT_REPAIR_WITH_ERROR_CLICKED"] = "bank_conect_clicked_repair_error ";
    SegmentEventTypes["BANK_CONNECT_DISCONNECT_CLICKED"] = "bank_connect_clicked_disconnect";
    SegmentEventTypes["BANK_CONNECT_UPDATE_OPENING_BALANCE_CLICKED"] = "bank_connect_clicked_update_opening_balance";
    SegmentEventTypes["ANIMATED_CONTENT_DIALOG_CTA_CLICKED"] = "animated_content_dialog_cta_clicked";
    SegmentEventTypes["BULK_ACCOUNT_STATEMENT_DOWNLOAD_CLICKED"] = "bulk_account_statement_download_clicked";
    SegmentEventTypes["PAYABLE_TRANSACTION_CREATED"] = "Payable_Transaction_Created";
    SegmentEventTypes["TENANTPORTAL_MAKE_A_PAYMENT_CLICKED"] = "tenantportal_make a payment_clicked";
    SegmentEventTypes["TENANTPAYMENT_PAYMENT_DETAILS_NEXT_CLICKED"] = "tenantpayment_payment details_next_clicked";
    SegmentEventTypes["TENANTPAYMENT_PAYMENT_METHOD_PAGE_VIEW"] = "tenantpayment_payment method_page view";
    SegmentEventTypes["TENANTPAYMENT_PAYMENT_METHOD_NEXT_CLICKED"] = "tenantpayment_payment method_next_clicked";
    SegmentEventTypes["TENANTPAYMENT_METHOD_STEP_LEARN_MORE_ACH_POPUP_CLICKED"] = "tenantpayment_method_step_ach_learn_more_popup_clicked";
    SegmentEventTypes["TENANTPAYMENT_PAYMENT_SUMMARY_PAY_NOW"] = "tenantpayment_payment summary_pay now";
    SegmentEventTypes["TENANTPAYMENT_PAYMENT_SUMMARY_SCHEDULE_PAYMENT"] = "tenantpayment_payment summary_schedule payment";
    SegmentEventTypes["TENANTPAYMENT_PAYMENT_SENT"] = "tenantpayment_payment_sent";
    SegmentEventTypes["TENANTPAYMENT_PAYMENT_SCHEDULED_SENT"] = "tenantpayment_payment_scheduled";
    SegmentEventTypes["TENANTPAYMENT_PAYMENT_UNAVAILABLE_POP_UP"] = "tenantpayment_payment_unavailable_pop_up";
    SegmentEventTypes["TENANT_PAYMENT_TYPE"] = "tenant_payment_type";
    SegmentEventTypes["TENANT_PAYMENT_LEDGER_ACCOUNT_STATEMENT_CLICKED"] = "tenant_payment_ledger_account_statement_clicked";
    SegmentEventTypes["TENANT_PAYMENT_SINGLE_ERROR"] = "tenant_payment_single_error";
    SegmentEventTypes["GENERAL_2FA_TOGGLE"] = "General 2FA Toggle";
    SegmentEventTypes["SMS_2FA_RADIO_BUTTON"] = "SMS 2FA Radio Button";
    // REPORTS
    SegmentEventTypes["REPORTS_MANAGEMENT_FEES_CLICKED"] = "ReportsTab_ManagementFeesReport_Clicked";
    SegmentEventTypes["REPORTS_MANAGEMENT_FEES_EDIT_CLICKED"] = "ReportsTab_ManagementFeesReport_EditManagementFees_Clicked";
    SegmentEventTypes["REPORTS_MANAGEMENT_FEES_EDIT_SAVED"] = "EditManagementFees_DialogBox_Save_Clicked";
    SegmentEventTypes["REPORTS_MANAGEMENT_FEES_EDIT_CANCELED"] = "EditManagementFees_DialogBox_Cancel_Clicked";
    SegmentEventTypes["REPORTS_OWNERSTATEMENT_SETTINGSMISSINGPOPUP_VIEWED"] = "reports_ownerstatement_settingsmissingpopup_viewed";
    SegmentEventTypes["REPORTS_OWNERSTATEMENT_NODATAFOUND_VIEWED"] = "reports_ownerstatement_nodatafound_viewed";
    SegmentEventTypes["REPORTS_OWNERSTATEMENT_GENERATEREPORTLOADINGPOPUP_OPENNEWTAB_CLICKED"] = "reports_ownerstatement_generatereportloadingpopup_opennewtab_clicked";
    SegmentEventTypes["REPORTS_OWNERSTATEMENT_GENERATEREPORTLOADINGPOPUP_CANCEL_CLICKED"] = "reports_ownerstatement_generatereportloadingpopup_cancel_clicked";
    SegmentEventTypes["JOURNALENTRYDIALOG_VIEWED"] = "journalentrydialog_viewed";
    SegmentEventTypes["JOURNALENTRYDIALOG_SAVE_CLICKED"] = "journalentrydialog_save_clicked";
    SegmentEventTypes["JOURNALENTRYDIALOG_CANCEL_CLICKED"] = "journalentrydialog_cancel_clicked";
    SegmentEventTypes["ONBOARDING_SUBSCRIBENOW_CLICKED"] = "onboarding_subscribenow_clicked";
    SegmentEventTypes["SUBSCRIBE_GETSTARTED_CLICKED"] = "subscribe_getstarted_clicked";
    SegmentEventTypes["CHECKOUTSUBSCRIBE_CLICKED"] = "checkoutsubscribe_clicked";
    SegmentEventTypes["CHECKOUTSUBSCRIBE_CONFIRMED"] = "checkoutsubscribe_confirmed";
    SegmentEventTypes["LOGINPASSWORDDIALOG_CLICKED"] = "loginpassworddialog_clicked";
    SegmentEventTypes["LOGINPASSWORDDIALOG_SAVE_CLICKED"] = "loginpassworddialog_save_clicked";
    SegmentEventTypes["SETTINGSITEM_CLICKED"] = "settingsitem_clicked";
    SegmentEventTypes["Merchant_Account_Overview_Balance_Page_Viewed"] = "merchant_account_overview_balance_page_viewed";
    SegmentEventTypes["Merchant_Account_Overview_Balance_Page_Tab_Clicked"] = "merchant_account_overview_balance_page_tab_clicked";
    SegmentEventTypes["Merchant_Account_Overview_Transactions_Page_Tab_Clicked"] = "merchant_account_overview_transactions_page_tab_clicked";
    SegmentEventTypes["Merchant_Account_Overview_Deposits_Page_Tab_Clicked"] = "merchant_account_overview_deposits_page_tab_clicked";
    SegmentEventTypes["Merchant_Account_Overview_Balance_Tab_MAFilter_Clicked"] = "merchant_account_overview_balance_tab_mafilter_clicked";
    SegmentEventTypes["Merchant_Account_Overview_Balance_Tab_Processing_Viewall_Clicked"] = "merchant_account_overview_balance_tab_processing_viewall_clicked";
    SegmentEventTypes["Merchant_Account_Overview_Balance_Tab_Succeeded_Viewall_Clicked"] = "merchant_account_overview_balance_tab_succeeded_viewall_clicked";
    SegmentEventTypes["Merchant_Account_Overview_Balance_Tab_Deposited_Viewall_Clicked"] = "merchant_account_overview_balance_tab_deposited_viewall_clicked";
    SegmentEventTypes["Merchant_Account_Overview_Transactions_Tab_Transaction_Clicked"] = "merchant_account_overview_transactions_tab_transaction_clicked";
    SegmentEventTypes["Merchant_Account_Overview_Balance_Tab_Deposited_Transaction_Clicked"] = "merchant_account_overview_balance_tab_deposited_transaction_clicked";
    SegmentEventTypes["Merchant_Account_Overview_Transactions_Tab_Filter_Clicked"] = "merchant_account_overview_transactions_tab_filter_clicked";
    SegmentEventTypes["Merchant_Account_Overview_Deposits_Tab_Filter_Clicked"] = "merchant_account_overview_deposits_tab_filter_clicked";
    SegmentEventTypes["Merchant_Account_Overview_Deposits_Tab_Deposit_Clicked"] = "merchant_account_overview_deposits_tab_deposit_clicked";
    SegmentEventTypes["Merchant_Account_General_Information_Tab_Updateinfo_Clicked"] = "merchant_account_general_information_tab_updateinfo_clicked";
    SegmentEventTypes["EMAIL_2FA_RADIO_BUTTON"] = "Email 2FA Radio Button";
    SegmentEventTypes["MOBILE_APP_FORCE_UPDATE_VIEW"] = "Mobile app force update view";
    SegmentEventTypes["MOBILE_APP_FORCE_UPDATE_CLICKED"] = "Mobile app force update clicked";
    SegmentEventTypes["SIGN_IN_CTA_CLICKED"] = "Signin_CTA_Clicked";
    SegmentEventTypes["GOOGLE_SSO_SIGN_IN_CTA_CLICKED"] = "Google_SSO_Signin_CTA_Clicked";
    SegmentEventTypes["Merchant_Account_General_Information_Tab_Increaselimit_Clicked"] = "merchant_account_general_information_tab_increaselimit_clicked";
    SegmentEventTypes["EMAIL_OR_TEXT_NOTIFICATION_OPENED"] = "email_or_text_notification_opened";
    SegmentEventTypes["FILTER_APPLIED"] = "Filter_Applied";
    SegmentEventTypes["INVITATION_CLICKED"] = "Invitation_Clicked";
    SegmentEventTypes["INVITATION_SENT"] = "Invitation_Sent";
    SegmentEventTypes["OWNER_PORTAL_TRANSACTIONS_PAGE_VIEWED"] = "TransactionsPage_Viewed";
    SegmentEventTypes["OWNER_PORTAL_REQUESTS_PAGE_VIEWED"] = "RequestsPage_Viewed";
    SegmentEventTypes["OWNER_PORTAL_REQUESTS_REQUEST_RECORD_VIEWED"] = "RequestRecord_Viewed";
    SegmentEventTypes["OWNER_PORTAL_REQUESTS_REQUEST_VIEW_REQUEST_DIALOG_SUBMIT_CLICKED"] = "ViewRequestDialog_Submit_Clicked";
    SegmentEventTypes["OWNER_PORTAL_REQUESTS_REQUESTS_PAGE_CREATE_NEW_REQUEST_CLICKED"] = "RequestsPage_CreateNewRequest_Clicked";
    SegmentEventTypes["OWNER_PORTAL_REQUESTS_REQUESTS_PAGE_NEW_REQUEST_SAVED"] = "RequestsPage_NewRequestDialog_Saved";
    SegmentEventTypes["OWNER_PORTAL_OVERVIEW_PAGE_VIEWED"] = "OverviewPage_Viewed";
    SegmentEventTypes["OWNER_PORTAL_OVERVIEW_PAGE_CARD_CLICKED"] = "OverviewPage_Card_Clicked";
    SegmentEventTypes["CHECK_VOIDED"] = "check_voided";
    SegmentEventTypes["USER_CLICKED_TENANT_PORTAL_LOGIN"] = "User_clicked_TenantPortalLogin";
    SegmentEventTypes["USER_CLICKED_OWNER_PORTAL_LOGIN"] = "User_clicked_OwnerPortalLogin";
    SegmentEventTypes["REGISTERED"] = "registered";
    SegmentEventTypes["NOTIFICATION_SETTINGS_RENT_REMINDERS_SAVE_CLICKED"] = "RentAndPaymentsNotificationSettingsSave_Clicked";
    SegmentEventTypes["NOTIFICATION_SETTINGS_LATE_FEES_SAVE_CLICKED"] = "LateFeesNotificationsSettingsSave_Clicked";
    //Calendar
    SegmentEventTypes["CALENDAR_VIEW_TODAY_CLICKED"] = "calendar_view_today_clicked";
    SegmentEventTypes["CALENDAR_OBJECT_CLICKED"] = "calendar_object_clicked";
    SegmentEventTypes["CALENDAR_OBJECT_CTA_CLICKED"] = "calendar_object_CTA_clicked";
    SegmentEventTypes["CALENDAR_PAST_ARROW_CLICKED"] = "calendar_past_arrow_clicked";
    SegmentEventTypes["CALENDAR_FUTURE_ARROW_CLICKED"] = "calendar_future_arrow_clicked";
    SegmentEventTypes["CALENDAR_SUBSCRIBE_TO_CALENDAR_CLICKED"] = "calendar_subscribe_to_calendar_clicked";
    SegmentEventTypes["CALENDAR_SUBSCRIBE_TO_CALENDAR_COPY_CLICKED"] = "calendar_subscribe_to_calendar_copy_clicked";
    SegmentEventTypes["CALENDAR_SUBSCRIBE_TO_CALENDAR_RESET_LINK_CLICKED"] = "calendar_subscribe_to_calendar_reset_link_clicked";
    // OWNER DISTRIBUTION - Step 1 - Distribute
    SegmentEventTypes["OWNER_DISTRIBUTION_RECIPIENTS_PAGE_NEXT"] = "owner_distribution_recipients_page_next";
    // OWNER DISTRIBUTION - Step 2 - Distribute
    SegmentEventTypes["OWNER_DISTRIBUTION_MISSING_INFO_PAGE_BACK"] = "owner_distribution_missing_info_page_back";
    SegmentEventTypes["OWNER_DISTRIBUTION_MISSING_INFO_PAGE_NEXT"] = "owner_distribution_missing_info_page_next";
    // OWNER DISTRIBUTION - Step 3 - Allocate
    SegmentEventTypes["OWNER_DISTRIBUTION_PROPERTY_PAGE_AS_OF_DATE_CHANGED"] = "owner_distribution_property_page_as_of_date_changed";
    SegmentEventTypes["OWNER_DISTRIBUTION_PROPERTY_PAGE_CARD_TOGGLE"] = "owner_distribution_property_page_card_toggle";
    SegmentEventTypes["OWNER_DISTRIBUTION_PROPERTY_PAGE_CHECKBOX_ALL_CHECKED"] = "owner_distribution_property_page_checkbox_all_checked";
    SegmentEventTypes["OWNER_DISTRIBUTION_PROPERTY_PAGE_CHECKBOX_ALL_UNCHECKED"] = "owner_distribution_property_page_checkbox_all_unchecked";
    SegmentEventTypes["OWNER_DISTRIBUTION_PROPERTY_PAGE_CHECKBOX_CHECKED"] = "owner_distribution_property_page_checkbox_checked";
    SegmentEventTypes["OWNER_DISTRIBUTION_PROPERTY_PAGE_CHECKBOX_UNCHECKED"] = "owner_distribution_property_page_checkbox_unchecked";
    SegmentEventTypes["OWNER_DISTRIBUTION_PROPERTY_PAGE_ACCOUNT_CHANGED"] = "owner_distribution_property_page_account_changed";
    SegmentEventTypes["OWNER_DISTRIBUTION_PROPERTY_PAGE_AMOUNT_RESET"] = "owner_distribution_property_page_amount_reset";
    SegmentEventTypes["OWNER_DISTRIBUTION_PROPERTY_PAGE_AMOUNT_CHANGED"] = "owner_distribution_property_page_amount_changed";
    SegmentEventTypes["OWNER_DISTRIBUTION_PROPERTY_PAGE_PROPERTY_ACCOUNT_BALANCE_CLICKED"] = "owner_distribution_property_page_property_account_balance_clicked";
    SegmentEventTypes["OWNER_DISTRIBUTION_PROPERTY_PAGE_PROPERTY_AVAILABLE_FUNDS_CLICKED"] = "owner_distribution_property_page_property_available_funds_clicked";
    SegmentEventTypes["OWNER_DISTRIBUTION_PROPERTY_PAGE_BACK"] = "owner_distribution_property_page_back";
    SegmentEventTypes["OWNER_DISTRIBUTION_PROPERTY_PAGE_NEXT"] = "owner_distribution_property_page_next";
    // OWNER DISTRIBUTION - Step 4 - Distribute
    SegmentEventTypes["OWNER_DISTRIBUTION_OWNERS_PAGE_BACK"] = "owner_distribution_owners_page_back";
    SegmentEventTypes["OWNER_DISTRIBUTION_OWNERS_PAGE_NEXT"] = "owner_distribution_owners_page_submit";
    SegmentEventTypes["OWNER_DISTRIBUTION_OWNERS_PAGE_DISTRIBUTION_DATE_CHANGED"] = "owner_distribution_owners_page_distribution_date_changed";
    SegmentEventTypes["OWNER_DISTRIBUTION_OWNERS_PAGE_CONSOLIDATE_PAYMENTS_ON"] = "owner_distribution_owners_page_consolidate_payments_on";
    SegmentEventTypes["OWNER_DISTRIBUTION_OWNERS_PAGE_CONSOLIDATE_PAYMENTS_OFF"] = "owner_distribution_owners_page_consolidate_payments_off";
    SegmentEventTypes["OWNER_DISTRIBUTION_OWNERS_PAGE_DISTRIBUTION_METHOD_CHANGED"] = "owner_distribution_owners_page_distribution_method_changed";
    SegmentEventTypes["OWNER_DISTRIBUTION_OWNERS_PAGE_DISTRIBUTION_METHOD_CHECK_PRINT_LATER_CHECKED"] = "owner_distribution_owners_page_distribution_method_check_print_later_checked";
    SegmentEventTypes["OWNER_DISTRIBUTION_OWNERS_PAGE_DISTRIBUTION_METHOD_CHECK_PRINT_LATER_UNCHECKED"] = "owner_distribution_owners_page_distribution_method_check_print_later_unchecked";
    // OWNER DISTRIBUTION - Success Dialog
    SegmentEventTypes["OWNER_DISTRIBUTION_SUCCESS_DIALOG_TRANSACTIONS_REPORT_CLICKED"] = "owner_distribution_success_dialog_transactions_report_clicked";
    SegmentEventTypes["OWNER_DISTRIBUTION_SUCCESS_DIALOG_OWNER_STATEMENT_REPORT_CLICKED"] = "owner_distribution_success_dialog_owner_statement_report_clicked";
    SegmentEventTypes["OWNER_DISTRIBUTION_SUCCESS_DIALOG_PRINT_CHECKS_CLICKED"] = "owner_distribution_success_dialog_print_checks_clicked";
    SegmentEventTypes["OWNER_DISTRIBUTION_SUCCESS_DIALOG_COMPLETE_EPAY_CLICKED"] = "owner_distribution_success_dialog_complete_epay_clicked";
    SegmentEventTypes["TENANT_PORTAL_REQUEST_CREATED"] = "tenant_portal_request_created";
    SegmentEventTypes["TENANT_PORTAL_REQUEST_VIEWED"] = "tenant_portal_request_viewed";
    SegmentEventTypes["TENANT_PORTAL_REQUEST_DIALOG_VIEWED"] = "tenant_portal_request_dialog_viewed";
    SegmentEventTypes["TENANT_PORTAL_REQUEST_DIALOG_SUBMIT_CLICKED"] = "tenant_portal_request_dialog_submit_clicked";
    SegmentEventTypes["TENANT_PORTAL_CREATE_NEW_REQUEST_CLICKED"] = "tenant_portal_create_new_request_clicked";
    // RENTAL APPLICATION - PM
    SegmentEventTypes["RentalApplicationUserCopyCompanyLink"] = "rental_application_user_copy_company_link";
    SegmentEventTypes["RentalApplicationUserCopyPropertyLink"] = "rental_application_user_copy_property_link";
    SegmentEventTypes["RentalApplicationUserCopyUnitLink"] = "rental_application_user_copy_unit_link";
    SegmentEventTypes["RentalApplicationUserCopyPersonalLink"] = "rental_application_user_copy_personal_link";
    SegmentEventTypes["RentalApplicationUserSentByEmailSms"] = "rental_application_user_sent_by_email_sms";
    SegmentEventTypes["RentalApplicationUserSentReminder"] = "rental_application_user_sent_reminder";
    SegmentEventTypes["RentalApplicationUserResendApplicationUsingResendCta"] = "rental_application_user_resent_application_using_resend_cta";
    SegmentEventTypes["RentalApplicationUserRequestedReports"] = "rental_application_user_opened_reports";
    SegmentEventTypes["RentalApplicationUserPaidForScreeningReports"] = "rental_application_user_paid_for_reports";
    SegmentEventTypes["RentalApplicationUserMadeDecision"] = "rental_application_user_made_decision";
    SegmentEventTypes["RentalApplicationUserCreatedApplicationClicked"] = "rental_application_user_created_application_clicked";
    SegmentEventTypes["RentalApplicationUserCompletedCreation"] = "rental_application_user_completed_creation";
    SegmentEventTypes["RentalApplicationUserOpenedSettings"] = "rental_application_user_opened_settings";
    SegmentEventTypes["RentalApplicationUserRedirectedPostPropertyCreationDialog"] = "rental_application_user_redirected_post_property_creation_dialog";
    SegmentEventTypes["RentalApplicationUserRedirectedFromApplicationWidget"] = "rental_application_user_redirected_from_application_widget";
    SegmentEventTypes["RentalApplicationUserReceivedSubmittedApplication"] = "rental_application_user_received_submitted_application";
    SegmentEventTypes["RentalApplicationUserClickedLearnMore"] = "rental_application_overview_banner_user_clicked_learn_more";
    SegmentEventTypes["RentalApplicationUserClickedSendApplication"] = "rental_application_overview_banner_user_clicked_send_application";
    SegmentEventTypes["RentalApplicationUserFinishedApplicationCreation"] = "rental_application_overview_banner_user_finished_application_creation";
    SegmentEventTypes["RentalApplicationUserCreatedNewFromEmptyState"] = "rental_application_user_created_new_from_empty_state";
    SegmentEventTypes["RentalApplicationUserOpenedReportsSample"] = "rental_application_brows_reports_sample_clicked";
    // RENTAL APPLICATION - Applicant
    SegmentEventTypes["RentalApplicationProspectLandedInWizard"] = "rental_application_prospect_landed_in_wizard";
    SegmentEventTypes["RentalApplicationProspectIdentified"] = "rental_application_prospect_identified";
    SegmentEventTypes["RentalApplicationApplicationSubmitted"] = "rental_application_application_submitted";
    SegmentEventTypes["RENTAL_APPLICATION_PROSPECT_CLICKED_SAVE_FOR_LATER"] = "rental_application_prospect_clicked_save_for_later";
    SegmentEventTypes["RENTAL_APPLICATION_PROSPECT_ARRIVED_TO_MAKE_PAYMENT"] = "rental_application_prospect_arrived_to_make_payment";
    SegmentEventTypes["RENTAL_APPLICATION_PROSPECT_RESUMED_APPLICATION"] = "rental_application_prospect_resumed_application";
    SegmentEventTypes["RENTAL_APPLICATION_PROSPECT_EDITED_APPLICATION"] = "rental_application_prospect_edited_application";
    SegmentEventTypes["RENTAL_APPLICATION_POST_SUBMISSION_PROSPECT_PAID_FOR_REPORTS"] = "rental_application_post_submission_prospect_paid_for_reports";
    SegmentEventTypes["COMMUNICATIONS_ANNOUNCEMENT_SENT"] = "communications_announcement_sent";
    //end lease events
    SegmentEventTypes["MOVE_IN_TENANT_CLICKED"] = "move_In_Tenant_Clicked";
    SegmentEventTypes["MOVE_IN_TENANT_SAVED"] = "move_In_Tenant_Saved";
    SegmentEventTypes["MOVE_OUT_TENANT_CLICKED"] = "move_Out_Tenant_Clicked";
    SegmentEventTypes["MOVE_OUT_TENANT_SAVED"] = "move_Out_Tenant_Saved";
    SegmentEventTypes["ACTIVELEASES_3DOTS_CLICKED"] = "activeLeases_3dots_Clicked";
    SegmentEventTypes["START_END_LEASE_CLICKED"] = "start_End_Lease_Clicked";
    SegmentEventTypes["END_LEASE_RECEIVE_PAYMENT_CLICKED"] = "end_Lease_Receive_Payment_Clicked";
    SegmentEventTypes["END_LEASE_ISSUE_CREDIT_CLICKED"] = "end_Lease_Issue_Credit_Clicked";
    SegmentEventTypes["END_LEASE_POST_CHARGE_CLICKED"] = "end_Lease_Post_Charge_Clicked";
    SegmentEventTypes["END_LEASE_REFUND_DEPOSIT_CLICKED"] = "end_Lease_Refund_Deposit_Clicked";
    SegmentEventTypes["END_LEASE_END_LEASE_ANYWAYS_CLICKED"] = "end_Lease_End_Lease_Anyways_Clicked";
    SegmentEventTypes["END_LEASE_FINISH_CLICKED"] = "end_Lease_Finish_Clicked";
    SegmentEventTypes["END_LEASE_WITHOLD_DEPOSIT_CLICKED"] = "end_Lease_Withold_Deposit_Clicked";
    SegmentEventTypes["END_LEASE_REFUND_TENANT_CLICKED"] = "end_Lease_Refund_Tenant_Clicked";
    // Cash Payments
    SegmentEventTypes["CASH_PAY_ENABLED_COMPANY"] = "cashpay_enabled_company";
    SegmentEventTypes["CASH_PAY_ENABLED_PROPERTY"] = "cashpay_enabled_property";
    SegmentEventTypes["CASH_PAY_ENABLED_LEASE"] = "cashpay_enabled_lease";
    SegmentEventTypes["CASH_PAY_DETAILS_COPIED"] = "cashpay_details_copied";
    SegmentEventTypes["CASH_PAY_SLIP_PRINTED"] = "cashpay_slip_printed";
    SegmentEventTypes["CASH_PAY_RECEIVED_PAYMENT"] = "cashpay_received_payment";
    // global search events
    SegmentEventTypes["GLOBAL_SEARCH_USER_GOT_RESULTS"] = "Global_Search_User_Got_Results";
    SegmentEventTypes["GLOBAL_SEARCH_USER_CLICKED_RESULT"] = "Global_Search_User_Clicked_Result";
    //bulk operations
    SegmentEventTypes["BULK_OPERATION_DIALOG_OPENED"] = "bulk_operation_dialog_opened";
    SegmentEventTypes["BULK_OPERATION_DIALOG_CLOSED"] = "bulk_operation_dialog_closed";
    SegmentEventTypes["BULK_OPERATION_FINISHED"] = "bulk_operation_finished";
    //convenience fees
    SegmentEventTypes["CONVENIENCE_FEES_SETTINGS_SAVED"] = "convenience_fees_settings_saved";
})(SegmentEventTypes || (exports.SegmentEventTypes = SegmentEventTypes = {}));
