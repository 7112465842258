import ZillowInstructionView from "screens/settings/listingSyndication/zillowInstructionView";
import AppStrings from "locale/keys";
import ZillowExpandedDetails from "screens/settings/listingSyndication/ZillowExpandedDetails";
import type { FC } from "react";
import React from "react";
import type { ListingsSettingsDto } from "@doorloop/dto";
import type { EnTranslation } from "locale";

type SyndicationType = "paid" | "free" | "advanced";

export interface Syndication {
  key: keyof ListingsSettingsDto;
  images?: string[];
  description?: EnTranslation;
  InstructionsView?: FC;
  rentalListingsURL?: string;
  type: SyndicationType;
  autoSyncEnabled?: boolean;
  title?: EnTranslation;
  copyButtonText?: EnTranslation;
  imageSize?: number;
  comingSoon?: boolean;
  partnerUrl?: string;
  urlTextFieldLabel?: string;
  expandedView?: React.ElementType;
}

export type ListingSyndicationByType = Record<SyndicationType, Syndication[]>;

export const SyndicationItems: Syndication[] = [
  {
    key: "zillow",
    images: ["/images/partners/zillow.svg"],
    InstructionsView: () => <ZillowInstructionView />,
    rentalListingsURL: "",
    type: "paid",
    urlTextFieldLabel: AppStrings.Listing.CompanyId,
    partnerUrl: "https://www.zillow.com",
    title: "listing.zillow",
    description: "listing.additionalFeesMayApply",
    copyButtonText: "listing.copyId",
    expandedView: () => <ZillowExpandedDetails />
  },
  {
    key: "apartmentsCom",
    images: ["/images/partners/apartments-logo.png"],
    //InstructionsView: () => <ApartmentsComInstructionView />,
    rentalListingsURL: "https://app.doorloop.com/api/mits/apartments-com/",
    comingSoon: true,
    urlTextFieldLabel: AppStrings.Listing.RentalListingsURL,
    type: "paid",
    imageSize: 135,
    partnerUrl: "https://www.apartments.com"
  },
  {
    key: "dwellsy",
    images: ["/images/partners/dwellsy.svg"],
    autoSyncEnabled: true,
    type: "free",
    partnerUrl: "https://dwellsy.com",
    title: "listing.dwellsy"
  },
  {
    key: "rentalSource",
    images: ["/images/partners/rentalSource.svg"],
    autoSyncEnabled: true,
    type: "free",
    partnerUrl: "https://www.rentalsource.com/",
    title: "listing.rentalSource"
  },
  {
    key: "zumper",
    images: ["/images/partners/zumper.svg"],
    autoSyncEnabled: true,
    type: "free",
    partnerUrl: "https://www.zumper.com",
    title: "listing.zumper"
  },
  {
    key: "rentalBeast",
    images: ["/images/partners/rentalBeast-logo.png"],
    autoSyncEnabled: true,
    type: "free",
    comingSoon: true,
    imageSize: 100,
    partnerUrl: "https://www.rentalbeast.com"
  },
  {
    key: "other",
    rentalListingsURL: "https://app.doorloop.com/api/mits/other/",
    type: "advanced",
    urlTextFieldLabel: AppStrings.Listing.RentalListingsURL,
    title: "listing.embedYourListings",
    description: "listing.embedTheLinkOnThirdPartySites"
  }
];
