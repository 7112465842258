export default {
  home: "Home",
  overview: "Overview",
  welcomeBack: "Welcome Back!",
  hiUser: "Hello {{userName}},",
  inProgress: "In Progress",
  notStarted: "Not Started",
  overdue: "Overdue",
  completed: "Completed",
  tasksAssignedToMe: "Tasks Assigned to Me",
  tasksOpenByStatus: "Tasks - Open by status",
  tasksNext7Days: "Tasks - My next 7 days",
  prospectsFunnel: "Prospects Funnel",
  tenantStats: "Tenant Stats",
  prospectsStats: "Prospects Stats",
  rentalStats: "Rental Stats",
  bankingStats: "Banking Stats",
  recentTenantRequests: "Recent Tenant Requests",
  internalTask: "Internal Task",
  tenantRequests: "Tenant Requests",
  ownerRequests: "Owner Requests",
  workRequests: "Work Requests",
  future: "Future",
  current: "Current",
  past: "Past",
  new: "New",
  contact: "Contact",
  showing: "Showing",
  rentalApplication: "Rental Application",
  setupTenantScreening: "Setup Tenant Screening",
  connectYourBankAccounts: "Connect Your Bank Accounts",
  lease: "Lease",
  totalX: "Total: {{total}}",
  totalNumberOfProspects: "Total Number Of Prospects: {{total}}",
  totalNumberOfVacantUnits: "Total Number Of Vacant Units: {{total}}",
  totalOwedByYou: "Total owed by you: {{total}}",
  totalOwedToYou: "Total owed to you: {{total}}",
  visitOurHelpCenter: "Visit Our Help Center",
  setupOnlinePayments: "Accept Online Rent Payments",
  last30Days: "Last 30 days",
  topFiveSourcesLastThirtyDays: "Top 5 sources, Last 30 days",
  topFiveSourcesByVacancies: "Top 5 properties, by vacancies",
  asOfToday: "As of today",
  next90Days: "Next 90 days",
  next120Days: "Next 120 days",
  referAFriendAndEarnMoney: "Refer a Friend & Earn Money",
  refresh: "Refresh",
  customizeTenantPortal: "Customize Your Tenant Portal",
  scheduleDemo: "Schedule a Demo",
  moveOuts: "Move-Outs",
  moveIns: "Move-Ins",
  moveOutsNext7Days: "Move-Outs Next 7 days",
  moveInsNext7Days: "Move-Ins Next 7 days",
  overdueTasks: "Overdue Tasks",
  expiredLeases: "Expired Leases",
  unassignedTenantRequests: "Unassigned Tenant Requests",
  mon: "Mon",
  tue: "Tue",
  wed: "Wed",
  thu: "Thu",
  fri: "Fri",
  sat: "Sat",
  open: "Open",
  unassigned: "Unassigned",
  newLast30Days: "New (Last 30 Days)",
  thingsToDo: "Things To Do",
  suggested: "Suggested",
  tenantPortalAdoption: "Tenant Portal Adoption",
  prospectsByProperty: "Prospects By Property",
  prospectsByLeadSource: "Prospects By Lead Source",
  occupancyRate: "Occupancy Rate",
  vacanciesByProperty: "Vacancies By Property",
  draftLeasesFunnel: "Draft Leases Funnel",
  leaseRenewalsFunnel: "Lease Renewals Funnel",
  rentalApplicationFunnel: "Rental Application Funnel",
  recentPaymentsReceived: "Recent Payments Received",
  leasesWithOutstandingBalances: "Leases With Outstanding Balances",
  accountsReceivable: "Accounts Receivable",
  accountsPayable: "Accounts Payable",
  pnl: "Profit & Loss",
  properties: "Properties",
  units: "Units",
  sqft: "Sqft",
  vacant: "Vacant",
  occupied: "Occupied",
  leasingActivityNext7Days: "Leasing activity next 7 days",
  notSent: "Not Sent",
  sent: "Sent",
  viewed: "Viewed",
  partiallySigned: "Partially Signed",
  fullyExecuted: "Fully Executed",
  undecided: "Undecided",
  approved: "Approved",
  approvedWithConditions: "Approved With Conditions",
  rejected: "Rejected",
  bankBalance: "Bank Balance",
  doorloopBalance: "DoorLoop Balance",
  accountBalance: "Account Balance",
  inactive: "Inactive",
  invited: "Invited",
  active: "Active",
  missingContactDetails: "Missing Contact Details",
  activatedInPortal: "Activated In Portal",
  paymentSetup: "Payment Set Up",
  renewalOffer: "Renewal Offer",
  draftLease: "Draft Lease",
  notRenewing: "Not Renewing",
  customizeDashboard: "Customize Dashboard",
  removeWidget: "Remove Widget",
  widgets: "Widgets",
  quickTip: "Quick Tip:",
  dndTip: "To add and remove widgets, drag & drop them from this panel to your Dashboard and back",
  bankConnectFirstHeader: "SYNC YOUR",
  bankConnectSecondHeader: "BANK ACCOUNTS",
  bankConnectSubtext: "Categorize income & expenses, reconcile accounts & more",
  bankConnectButtonText: "Connect Now",
  bankDetails: "Bank Details",
  activeAccount: "Active Account",
  status: "Status",
  updatingAccount: "Updating Account",
  onlinePaymentsFirstHeader: "ACCEPT ONLINE",
  onlinePaymentsSecondHeader: "PAYMENTS",
  onlinePaymentsSubtext: "Automate rent collection & get paid faster",
  onlinePaymentsButtonText: "Apply Now",
  scheduleDemoFirstHeader: "SCHEDULE A",
  scheduleDemoSecondHeader: "DEMO TODAY",
  scheduleDemoSubtext: "Ask any question & see if it's the right fit",
  scheduleDemoButtonText: "Schedule a Demo",
  rentalAppFirstHeader: "How well do you know your tenants?",
  rentalAppSecondHeader: "RENTAL APPLICATIONS",
  rentalAppSubtext:
    "Performing screenings on all your prospects reduces the risk of costly evictions and property damage. With DoorLoop, you can run tenant background checks quickly. <Link>Learn More</Link>.",
  rentalAppButtonText: "Send application",
  rentalAppSecondaryButtonText: "Learn more",
  noData: "Nothing Here Yet",
  noDataSubtext: "Please add data, refresh the page, or reset your filters",
  networkError: "No Connection",
  networkErrorSubtext: "Please check your internet connection or refresh the page",
  unassignedTasks: "Unassigned Tasks",
  draftsLeases: "Drafts Leases",
  unassignedProspects: "Unassigned Prospects",
  pendingRentalApplications: "Pending Rental Applications",
  reportReadyToRequest: "Reports Ready To Request",
  rentalApplicationsPendingReports: "Pending reports",
  rentalApplicationsPendingDecision: "Applications Pending Decision",
  rentalApplicationUnitsOpenForApplication: "Vacant Units",
  rentalApplicationsSubmittedApplications: "Submitted applications",
  rentalApplicationsFailedApplications: "Identity Verification Failed",
  pastDueLeases: "Past Due Leases",
  pastDueBills: "Past Due Bills",
  transactionsPendingReview: "Transactions Pending Review",
  xUnits: "{{x}} Units",
  lastNumberOfMonthsToDate: "Last {{numberOfMonthsToDate}} months to date",
  revenue: "Revenue",
  expenses: "Expenses",
  netIncome: "Net Income: {{totalIncome}}"
};
