import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import { getUserLocale } from "./useRentalApplicationLocale";

export const useGetUserLocale = () => {
  const authState = useSelector((state: IRootState) => state.auth);
  const { localeData } = authState;
  const { locale } = localeData;

  return getUserLocale(locale);
};
