import type { GetAllOwnerContributionsQuery, OwnerContributionDto } from "@doorloop/dto";
import { ServerRoutes } from "@doorloop/dto";
import { RestApiBase } from "./restApiBase";
import type { ApiResult } from "./apiResult";
import type { GetAllBaseQueryResponse } from "@doorloop/dto";
import { apiHelper } from "./apiHelper";

class OwnerContributionsApi extends RestApiBase<OwnerContributionDto, GetAllOwnerContributionsQuery> {
  constructor() {
    super(ServerRoutes.OWNER_CONTRIBUTIONS, []);
    this.restRoute = ServerRoutes.OWNER_CONTRIBUTIONS;
  }

  async getAll(
    query: GetAllOwnerContributionsQuery
  ): Promise<ApiResult<GetAllBaseQueryResponse<OwnerContributionDto>>> {
    return await apiHelper.axiosGet<GetAllBaseQueryResponse<OwnerContributionDto>>(
      ServerRoutes.OWNER_CONTRIBUTIONS + "/?",
      query
    );
  }
}

export const ownerContributionsApi = new OwnerContributionsApi();
