import React from "react";
import { View } from "DLUI/view";
import WarningView from "DLUI/form/warningView/warningView";
import Text from "DLUI/text";
import { AnimatedView } from "DLUI/view/animatedView";
import AppStrings from "../../../../../locale/keys";

import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";

interface Props {
  shouldShow?: boolean;
}

const PaymentWarningView: React.FC<Props> = ({ shouldShow }: Props) => {
  const { isMobile, screenContainerPadding } = useResponsiveHelper();
  const renderWarningViewContent = () => (
    <>
      <View
        paddingRight={screenContainerPadding}
        paddingLeft={screenContainerPadding}
        alignItems={isMobile ? "center" : "flex-start"}
      >
        <Text
          value={AppStrings.Common.PaymentWarningTitle}
          bold
          textTransform={"uppercase"}
          lineHeight={"22px"}
          marginTop={isMobile ? 10 : 0}
          align={isMobile ? "center" : "left"}
        />
        <Text
          value={AppStrings.Common.PaymentWarningDescription}
          fontSize={14}
          lineHeight={"20px"}
          align={isMobile ? "center" : "left"}
        />
      </View>
      <View height={20} />
    </>
  );

  return (
    <>
      <AnimatedView shouldShow={Boolean(shouldShow)}>
        <View marginTop={20}>
          <WarningView renderContent={renderWarningViewContent} />
        </View>
      </AnimatedView>
      <View height={20} />
    </>
  );
};

export default PaymentWarningView;
