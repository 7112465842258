import type { FC } from "react";
import React, { useState } from "react";
import { DialogFrame } from "DLUI/dialogs";
import AppStrings from "locale/keys";
import { useParams } from "react-router-dom";
import DeleteConfirmation from "DLUI/dialogs/components/deleteConfirmation";
import { SimpleLoading } from "DLUI/loading/simpleLoading";
import { buildRenderView } from "DLUI/dialogs/components/dialogFrame";
import type { ESignatureRequestDto } from "@doorloop/dto";
import { signatureRequestsApi } from "api/signatureRequestsApi";
import SignatureRequestPreview from "screens/esignatures/viewSignatureRequest/signatureRequestPreview";
import CancelSignatureRequestConfirmation from "screens/esignatures/viewSignatureRequest/cancelSignatureRequestConfirmation";
import { useEffectAsync } from "../../../../hooks/useEffectAsync";

interface Props {
  onBackdropClick: () => void;
  onClose: () => void;
}

export const ViewSignatureRequestDialog: FC<Props> = ({ onBackdropClick, onClose }: Props) => {
  const [errorText, setErrorText] = useState<string>("");
  const [signatureRequestData, setSignatureRequestData] = useState<ESignatureRequestDto>();

  const [currentView, setCurrentView] = useState<number>(0);
  const { signatureRequestId } = useParams<{
    signatureRequestId: string;
  }>();

  const fetchData = async () => {
    const { data, message } =
      (await signatureRequestsApi.get(signatureRequestId).catch((err) => setErrorText(err))) || {};
    if (data) {
      setCurrentView(1);
      setSignatureRequestData(data);
    } else {
      setErrorText(message || "");
    }
  };

  useEffectAsync(async () => {
    await fetchData();
  }, []);

  const onPressDeleteButton = () => setCurrentView(2);
  const didPressCancelRequestButton = () => setCurrentView(3);

  const { renderView } = buildRenderView([
    () => <SimpleLoading errorText={errorText} retryHandler={fetchData} dismissHandler={onBackdropClick} />,
    () =>
      signatureRequestData ? (
        <SignatureRequestPreview
          onBackdropClick={onBackdropClick}
          signatureRequestData={signatureRequestData}
          didPressDeleteButton={onPressDeleteButton}
          didPressCancelRequestButton={didPressCancelRequestButton}
        />
      ) : null,
    () => (
      <DeleteConfirmation
        apiMethod={signatureRequestsApi}
        didPressDismissButton={() => setCurrentView(1)}
        didFinishOperation={() => {
          onClose();
        }}
        transactionId={signatureRequestData?.id}
        deleteConfirmationText={AppStrings.Esignatures.DeleteSignatureRequestConfirmationText}
        attachments={[]}
      />
    ),
    () => (
      <CancelSignatureRequestConfirmation
        apiMethod={signatureRequestsApi.cancelRequest}
        didPressDismissButton={() => setCurrentView(1)}
        didFinishOperation={() => {
          onClose();
        }}
        transactionId={signatureRequestData?.id}
        cancelConfirmationText={AppStrings.Esignatures.CancelSignatureRequestConfirmationText}
      />
    )
  ]);

  return (
    <DialogFrame
      frameType={"contentOnlyNoFrame"}
      numViews={2}
      activeView={currentView}
      onCloseButtonClick={onBackdropClick}
      width={Math.min(window.innerWidth - 80, 1100)}
      height={window.innerHeight - 80}
      renderView={renderView}
      useExperimentalDialogFrame
    />
  );
};
