import React, { useEffect, useRef, useState } from "react";
import type { ImageButtonGroupProps } from "./imageButton";
import { ImageButton } from "./imageButton";
import makeStyles from "./styles";
import _ from "lodash";
import { animated, useTrail } from "react-spring";
import { ValidationIndicator } from "DLUI/form";

interface ComponentProps {
  uniqueId: string;
  buttonItemsArray: ImageButtonGroupProps[];
  onSelect?: (selectedValue: string, itemIndex: number) => void;
  onRef?: (ref: React.MutableRefObject<any>) => void;
  size?: number;
  flexDirection?: "flex-start" | "center";
  marginRight?: number;
  marginTop?: number;
  labelMaxWidth?: number;
  hasError?: boolean;
  errorText?: string;
  disableAnimation?: boolean;
  hasGridStyle?: boolean;
  fontSize?: number;
  dataCy?: string;
}

const config = { mass: 5, tension: 2000, friction: 200, duration: 500 };

const ImageButtonGroup: React.FC<ComponentProps> = ({
  buttonItemsArray,
  onSelect,
  onRef,
  size,
  flexDirection,
  marginRight,
  marginTop,
  labelMaxWidth,
  hasError,
  errorText,
  disableAnimation,
  hasGridStyle,
  fontSize,
  dataCy
}: ComponentProps) => {
  const classes = makeStyles();
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState<number | undefined>();
  const [currentSelectedValue, setCurrentSelectedValue] = useState<string | undefined>();

  const [currentSelection, setCurrentSelection] = useState<ImageButtonGroupProps[]>(buttonItemsArray);

  useEffect(() => {
    setCurrentSelection(buttonItemsArray);
  }, [buttonItemsArray]);

  useEffect(() => {
    if (onRef) {
      onRef(componentRef);
    }
    return () => {
      const nextSelection = _.clone(currentSelection);
      nextSelection.forEach((currentButton) => (currentButton.isSelected = false));
    };
  }, []);

  const clearSelection = () => {
    const nextSelection = _.clone(currentSelection);
    nextSelection.forEach((currentButton) => (currentButton.isSelected = false));
    setCurrentSelectedIndex(undefined);
    setCurrentSelectedValue(undefined);
    setCurrentSelection(nextSelection);
  };

  const componentRef = useRef({
    clearSelection
  });

  const buttonsTransition = useTrail(currentSelection.length, {
    config,
    from: { opacity: 0, height: 0 },
    to: { opacity: 1, height: size ? size : 100 }
  });

  const didPressButton = (itemIndex: number, value: string | boolean) => {
    if (itemIndex === currentSelectedIndex) {
      return;
    }

    const nextSelection = _.clone(currentSelection);
    let selectionValue;

    nextSelection.forEach((currentButton, index) => {
      if (index === itemIndex) {
        currentButton.isSelected = true;
        selectionValue = currentButton.value;
      } else {
        currentButton.isSelected = false;
      }
    });
    setCurrentSelection(nextSelection);
    setCurrentSelectedIndex(itemIndex);
    setCurrentSelectedValue(selectionValue);
    if (onSelect) {
      onSelect(selectionValue, itemIndex);
    }
  };

  const imageButtons = buttonsTransition.map(({ ...rest }, index) => (
    <div
      key={"IMB" + index}
      style={{
        marginTop: marginTop === undefined ? 20 : marginTop,
        marginLeft: index === 0 ? 0 : 20,
        marginRight: marginRight === undefined ? 20 : marginRight
      }}
    >
      <animated.div
        className={`${classes.buttonGroupItems} ${hasGridStyle ? "image-button-grid" : ""}`}
        key={"uniqueId" + index}
        style={disableAnimation ? {} : { ...rest }}
      >
        <ImageButton
          fontSize={fontSize}
          label={buttonItemsArray[index].label}
          icon={buttonItemsArray[index].icon}
          onPress={didPressButton}
          dataCy={buttonItemsArray[index]?.dataCy}
          value={buttonItemsArray[index].value}
          isSelected={
            currentSelectedValue
              ? buttonItemsArray[index].value === currentSelectedValue
              : buttonItemsArray[index].isSelected
          }
          itemIndex={index}
          size={size}
          labelMaxWidth={labelMaxWidth}
        />
      </animated.div>
    </div>
  ));

  return (
    <div style={{ alignItems: flexDirection || "center" }} className={classes.buttonGroupContainer}>
      <div style={{ justifyContent: flexDirection || "center" }} className={classes.buttonGroupItems}>
        {imageButtons}
      </div>
      <ValidationIndicator shouldShow={hasError || false} maxWidth={650} displayText={errorText || ""} />
    </div>
  );
};

export default ImageButtonGroup;
