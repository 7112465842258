"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumOfUnits = void 0;
var NumOfUnits;
(function (NumOfUnits) {
    NumOfUnits[NumOfUnits["LESS_THAN_5"] = 5] = "LESS_THAN_5";
    NumOfUnits[NumOfUnits["LESS_THAN_20"] = 20] = "LESS_THAN_20";
    NumOfUnits[NumOfUnits["LESS_THAN_50"] = 50] = "LESS_THAN_50";
    NumOfUnits[NumOfUnits["LESS_THAN_200"] = 200] = "LESS_THAN_200";
    NumOfUnits[NumOfUnits["LESS_THAN_500"] = 500] = "LESS_THAN_500";
    NumOfUnits[NumOfUnits["MORE_THAN_500"] = 1000] = "MORE_THAN_500";
})(NumOfUnits || (exports.NumOfUnits = NumOfUnits = {}));
