import React, { useCallback, useEffect, useState } from "react";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import type { UnitDto } from "@doorloop/dto";
import { ObjectPermission, RentalApplicationUnitListingInfoDto } from "@doorloop/dto";
import { View } from "DLUI/view";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import { useFormikContext } from "formik";
import { SwitchButton } from "DLUI/form";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import AppStrings from "locale/keys";
import Text from "DLUI/text";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { useParams } from "react-router-dom";
import { getTransunionPropertyId } from "DLUI/dialogs/components/editUnit/editUnitGeneralInfo";

export const EditUnitRentalApplicationSetup = () => {
  const { dialogHorizontalPadding } = DialogsHelper();
  const formikRef = useFormikContext<UnitDto>();
  const { propertyId, unitId } = useParams<any>();
  const propertyIdValue = propertyId || formikRef.values?.property;
  const [isRentalListingFieldDisabled, setIsRentalListingFieldDisabled] = useState(false);

  useEffect(() => {
    if (propertyIdValue) {
      setIsRentalListingFieldDisabled(!getTransunionPropertyId(propertyId || propertyIdValue, unitId));
    }
  }, [propertyIdValue]);

  const toggleListing = useCallback(
    (value) => {
      if (value) {
        formikRef.setFieldValue(
          "rentalApplicationListing",
          new RentalApplicationUnitListingInfoDto({ activeListing: true })
        );
      } else {
        formikRef.setFieldValue(
          "rentalApplicationListing",
          new RentalApplicationUnitListingInfoDto({ activeListing: false })
        );
      }
    },
    [formikRef]
  );

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding} flex={1}>
        <RestrictedPermissionAccess
          clearance={{
            permission: ObjectPermission.properties,
            field: "edit"
          }}
          showNoAccess
        >
          <View alignItems={"flex-start"}>
            <Text fontSize={16} value={AppStrings.Common.RentalApplicationListingTitle} />
            <HorizontalSeparationLine marginTop={20} />
            <Text fontSize={18} value={AppStrings.Common.RentalApplicationListingDescription} marginTop={20} bold />
            <SwitchButton
              marginTop={20}
              label={AppStrings.Common.RentalApplicationListingHandlerText}
              checked={Boolean(formikRef.values.rentalApplicationListing?.activeListing)}
              onChange={toggleListing}
              disabled={isRentalListingFieldDisabled || formikRef.values?.listing?.activeListing}
              noWrap
            />
          </View>
        </RestrictedPermissionAccess>
      </View>
    </MuiPickersUtilsProvider>
  );
};
