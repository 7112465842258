import { vendorsApi } from "api/vendorsApi";
import { ownersApi } from "api/ownersApi";
import { tenantsApi } from "api/tenantsApi";
import { currencyCellWidth, getNameFromDictionary, translateReference, translateTypeField } from "DLUI/lists/utils";
import type { AccountRegisterReportItem, PrintChecksReportItemDto } from "@doorloop/dto";
import type { MultiSelectGridColumn } from "DLUI/lists/multiSelect/multiSelectGridList";
import AppStrings from "locale/keys";

const matchTransactionsColumns: Array<MultiSelectGridColumn<AccountRegisterReportItem>> = [
  {
    width: 200,
    field: "date",
    title: "Date",
    format: "date",
    show: true
  },
  {
    width: 200,
    field: "journalEntryType",
    title: "Type",
    format: "text",
    show: true,
    translate: (value) => translateTypeField(value)
  },
  {
    field: "reference",
    title: AppStrings.Common.ListHeader.Reference,
    show: false,
    format: "text",
    translate: (value: string) => translateReference(value),
    width: 200
  },
  {
    field: "memo",
    title: AppStrings.Common.ListHeader.Memo,
    show: true,
    format: "text",
    width: 200
  },
  {
    field: "amount",
    title: AppStrings.Leases.NewLease.LeaseRent.Amount,
    show: true,
    width: currencyCellWidth,
    format: "currency",
    showZeroValues: true
  }
];

export default matchTransactionsColumns;

export const translatePayeeFromDictionary = (defaultValue: string, Item: PrintChecksReportItemDto) => {
  if (!Item.payToResourceId || !Item.payToResourceType) {
    return defaultValue;
  }
  switch (Item.payToResourceType) {
    case "OWNER":
      return getNameFromDictionary(Item.payToResourceId, ownersApi);
    case "TENANT":
      return getNameFromDictionary(Item.payToResourceId, tenantsApi);
    case "VENDOR":
      return getNameFromDictionary(Item.payToResourceId, vendorsApi);
    default:
      return defaultValue;
  }
};
