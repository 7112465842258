import type { FC } from "react";
import React, { useMemo, useState } from "react";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { useParams } from "react-router-dom";
import type { FrameType } from "DLUI/dialogs/components/dialogFrame";
import { signatureTemplatesApi } from "api/signatureTemplatesApi";
import { useHelloSignClient } from "screens/esignatures/useHelloSignClient";
import AppStrings from "../../../../locale/keys";
import { useTranslation } from "react-i18next";
import { NavigationManager } from "../../../../utils/navigation";
import GeneralSettings from "screens/esignatures/newSignatureTemplate/generalSetting/generalSettings";
import { View } from "DLUI/view";
import { useFormikContext } from "formik";
import type { ESignatureTemplateDto } from "@doorloop/dto";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";

interface Props {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogFrameHeight: number;
  dialogFrameWidth: number;
}

const DialogViews = {
  DialogForm: 0,
  LoadingView: 1
};

export const EditSignatureTemplateDialog: FC<Props> = ({
  onBackdropClick,
  onClose,
  dialogFrameHeight,
  dialogFrameWidth
}: Props) => {
  const { t } = useTranslation();
  const formikRef = useFormikContext<ESignatureTemplateDto>();

  const [errorText, setErrorText] = useState<string>("");
  const [loadingDialogState, setLoadingState] = useState(DialogState.Hidden);
  const [currentView, setCurrentView] = useState<number>(0);
  const { signatureTemplateId } = useParams<{
    signatureTemplateId: string;
  }>();

  const onError = (errorCode) => {
    setErrorText(`${t(AppStrings.Common.GeneralError)} : errorCode = ${errorCode}`);
    setLoadingState(DialogState.Show);
  };

  const { openHelloSignSDK } = useHelloSignClient(
    onError,
    NavigationManager.viewSignatureTemplatesList,
    undefined,
    NavigationManager.viewSignatureTemplatesList
  );

  const showError = (errMessage: string) => {
    setLoadingState(DialogState.Error);
    setErrorText(errMessage);
  };

  const getEmbeddedUrl = async () => {
    if (signatureTemplateId) {
      setLoadingState(DialogState.Show);
      const { data, message } = await signatureTemplatesApi.getEmbeddedUrl(signatureTemplateId);

      if (data?.url) {
        openHelloSignSDK(data.url);
        onClose();
      } else {
        showError(message || t(AppStrings.Common.GeneralError));
      }
    } else {
      showError(t(AppStrings.Common.GeneralError));
    }
  };
  const renderView = ({ index }) => {
    if (index === DialogViews.DialogForm) {
      return (
        <View alignItems={"center"}>
          <GeneralSettings hideSectionTitle />
        </View>
      );
    }
    if (index === DialogViews.LoadingView) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          errorText={errorText}
          onRetryButtonPress={getEmbeddedUrl}
          didPressDismissButton={onBackdropClick}
        />
      );
    }
    return <View />;
  };

  const didPressSaveButton = async () => {
    if (formikRef.values.id) {
      setLoadingState(DialogState.Show);
      setCurrentView(DialogViews.LoadingView);
      const { message, data } = await signatureTemplatesApi.update(formikRef.values.id, formikRef.values);
      if (data) {
        await getEmbeddedUrl();
      } else {
        showError(message);
      }
    }
  };
  const renderActionPanelButtons = () => (
    <FormActionButtons
      propsSubButton={{ onClick: onBackdropClick }}
      propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
    />
  );

  const frameType: FrameType = useMemo(() => {
    if (currentView === 0) {
      return "sectionTitleFrame";
    }
    return "contentOnly";
  }, [currentView]);

  return (
    <DialogFrame
      frameType={frameType}
      numViews={1}
      activeView={currentView}
      onCloseButtonClick={onBackdropClick}
      width={dialogFrameWidth}
      height={dialogFrameHeight}
      renderView={renderView}
      useExperimentalDialogFrame
      title={AppStrings.Esignatures.EditSignatureTemplate}
      RenderActionPanelButtons={renderActionPanelButtons}
    />
  );
};
