import React, { useMemo } from "react";
import { View } from "DLUI/view";
import { Lottie } from "DLUI/lottie";
import spinnerAnimation from "../../../assets/lottie/spinner.json";
import { DefaultLottieHeight } from "DLUI/dropZone/dropZone";
import Text from "DLUI/text";
import AppStrings from "../../../locale/keys";
import { Link } from "DLUI/link";
import { useTranslation } from "react-i18next";
import { BackupIcon, uploadNewIcon } from "../../../assets";
import { Icon } from "DLUI/icon";
import { DEFAULT_MAX_FILE_UPLOAD_SIZE_ALLOWED_IN_MB, getSupportedFileTypesAsString } from "DLUI/dropZone/utils";

interface ComponentProps {
  isDragActive: boolean;
  shouldShow?: boolean;
  loadingFiles?: boolean;
  height?: number;
  dragOrClickText?: string;
  instructionsViewDirection?: "row" | "column";
  hideIcon?: boolean;
  isRequired?: boolean;
  maxFileSizeInMB?: number;
}

const DropZoneInstructionsView: React.FC<ComponentProps> = ({
  shouldShow,
  isDragActive,
  loadingFiles,
  height,
  instructionsViewDirection,
  hideIcon,
  isRequired,
  maxFileSizeInMB = DEFAULT_MAX_FILE_UPLOAD_SIZE_ALLOWED_IN_MB
}: ComponentProps) => {
  const { t } = useTranslation();
  const dragOrClickText = useMemo(
    () => [
      t(AppStrings.Common.Dropzone.InstructionsTextPart1),
      " ",
      <Link key={"DZ"} hoverColor={"lightBlue"}>
        {t(AppStrings.Common.ClickHere)}
      </Link>,
      " ",
      t(AppStrings.Common.Dropzone.InstructionsTextPart2)
    ],
    []
  );

  const supportedFileText = useMemo(() => {
    const supportedFileTypeString = getSupportedFileTypesAsString(window.location.pathname);

    return `${supportedFileTypeString} ${t(AppStrings.Common.SupportedFileTypesSuffix, {
      size: maxFileSizeInMB
    })}`;
  }, []);

  if (!shouldShow) {
    return <View />;
  }
  if (loadingFiles) {
    return (
      <div>
        <Lottie
          loop
          animationData={spinnerAnimation}
          width={height ? height : DefaultLottieHeight}
          height={height ? height : DefaultLottieHeight}
        />
      </div>
    );
  }
  if (isDragActive) {
    return (
      <Text
        color={"black"}
        variant={"listItemTitle"}
        fontWeight={300}
        value={AppStrings.Common.Dropzone.InstructionsDragActiveText}
      />
    );
  }
  return (
    <View
      height={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={instructionsViewDirection || "column"}
      autoWidth
      gap={2}
      paddingTop={16}
      paddingBottom={16}
    >
      <View flexDirection={instructionsViewDirection || "column"} justifyContent={"center"} alignItems={"center"}>
        <View width={"auto"} marginRight={7}>
          {!hideIcon && <Icon Source={isRequired ? uploadNewIcon : BackupIcon} size={isRequired ? 30 : 25} />}
        </View>
        <Text align={"left"} fontSize={14} color={"black"} fontWeight={700}>
          {dragOrClickText}
        </Text>
      </View>
      <View flexDirection={instructionsViewDirection || "column"} justifyContent={"center"} alignItems={"center"}>
        <Text value={supportedFileText} fontSize={12} color="secondary-gray" lineHeight={"16px"} />
      </View>
    </View>
  );
};

export default DropZoneInstructionsView;
