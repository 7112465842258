import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import type { CheckBoxColor } from "DLUI/form/checkBox/base/types";
import type { ToggleInputSize } from "DLUI/form/types/toggleInputBaseTypes";
import type { LabelPlacementPosition } from "DLUI/form/label/inputLabel";

type ContainerClassKey = "root" | LabelPlacementPosition;

export const useStylesContainer = makeStyles<Theme, NonNullable<unknown>, ContainerClassKey>((theme) => {
  return {
    root: {
      alignItems: "center !important" as "center"
    },
    start: {
      flexDirection: "row-reverse !important" as "row-reverse"
    },
    end: {
      flexDirection: "row !important" as "row"
    }
  };
});

type InputClassKey =
  | "root"
  | ToggleInputSize
  | "colorPrimary"
  | "colorSecondary"
  | "checked"
  | CheckBoxColor
  | "disabled";

export const useStylesInput = makeStyles<Theme, NonNullable<unknown>, InputClassKey>((theme) => {
  return {
    root: {
      "&$blue": {
        color: theme.newPalette.action.active, // #182C4C

        "&$checked, &$checked svg, &$checked path": {
          color: theme.newPalette.secondary.main, // #1665D8
          fill: theme.newPalette.secondary.main // #1665D8
        },
        "&$disabled, &$disabled svg, &$disabled path": {
          color: theme.newPalette.info.states.focus, // #1665D8
          fill: theme.newPalette.info.states.focus // #1665D8
        },
        "& .MuiSvgIcon-root": {
          color: theme.newPalette.action.active, // #182C4C
          fill: theme.newPalette.action.active // #182C4C
        },
        "&:hover": {
          backgroundColor: `${theme.newPalette.secondary.states.hover} !important` // #1665D80A
        },
        "&:active .MuiTouchRipple-child, & .MuiTouchRipple-childLeaving": {
          backgroundColor: `${theme.newPalette.secondary.states.focusVisible} !important` // #1665D84D
        }
      },
      "&$green": {
        color: theme.newPalette.success.dark, // #01A35D

        "& svg, & path": {
          fill: theme.newPalette.success.main // #01CC74
        },

        "&$disabled, &$disabled svg, &$disabled path": {
          color: theme.newPalette.success.states.focus, // #01A35D66
          fill: theme.newPalette.success.states.focus // #01A35D66
        },
        "& .MuiSvgIcon-root": {
          color: theme.newPalette.success.dark, // #01A35D
          fill: `${theme.newPalette.success.dark} !important` // #01A35D
        },
        "&:hover": {
          backgroundColor: `${theme.newPalette.success.states.hover} !important` // #01CC740A
        },
        "&:active .MuiTouchRipple-child, & .MuiTouchRipple-childLeaving": {
          backgroundColor: `${theme.newPalette.success.states.focusVisible} !important` // #01CC744D
        }
      },
      "&$red": {
        color: theme.newPalette.error.dark, // #9F2E3D

        "& svg, & path": {
          fill: theme.newPalette.error.main // #E44258
        },

        "&$disabled, &$disabled svg, &$disabled path": {
          color: theme.newPalette.error.states.focus, // #9F2E3D66
          fill: theme.newPalette.error.states.focus // #9F2E3D66
        },
        "& .MuiSvgIcon-root": {
          color: theme.newPalette.error.dark, // #9F2E3D
          fill: `${theme.newPalette.error.dark} !important` // #9F2E3D
        },
        "&:hover": {
          backgroundColor: `${theme.newPalette.error.states.hover} !important` // #E442580A
        },
        "&:active .MuiTouchRipple-child, & .MuiTouchRipple-childLeaving": {
          backgroundColor: `${theme.newPalette.error.states.focusVisible} !important` // #E442584D
        }
      },
      "&$purple": {
        color: "#5820C0",

        "& svg, & path": {
          fill: "#743BDE"
        },

        "&$disabled, &$disabled svg, &$disabled path": {
          color: "#5820C066",
          fill: "#5820C066"
        },
        "& .MuiSvgIcon-root": {
          color: "#5820C0",
          fill: "#5820C0 !important"
        },
        "&:hover": {
          backgroundColor: "#743BDE0A !important"
        },
        "&:active .MuiTouchRipple-child, & .MuiTouchRipple-childLeaving": {
          backgroundColor: "#743BDE4D !important"
        }
      }
    },
    small: {
      "& .DLUI_Icon svg[style]": {
        height: "16px",
        width: "16px"
      }
    },
    medium: {
      "& .DLUI_Icon svg[style]": {
        height: "24px",
        width: "24px"
      }
    },
    large: {
      "& .DLUI_Icon svg[style]": {
        height: "28px",
        width: "28px"
      }
    },
    colorPrimary: {},
    colorSecondary: {},
    checked: {},
    blue: {},
    green: {},
    red: {},
    purple: {},
    disabled: {}
  };
});
