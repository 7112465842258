import { makeStyles } from "@material-ui/styles";
import type { Theme } from "styles/defaultTheme";

export const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      display: "inline-flex",
      alignItems: "center",
      zIndex: 1
    },
    checkboxRoot: {
      display: "flex",
      position: "relative",
      boxSizing: "border-box",
      padding: "2px",
      appearance: "none",
      alignItems: "center",
      justifyContent: "center",
      outline: "none",
      borderRadius: "4px",
      transition: "background-color 0.2s",
      borderStyle: "solid",
      borderWidth: "2px",
      "&:before, &:after": {
        content: "''",
        position: "absolute",
        borderRadius: "50%",
        pointerEvents: "none",
        top: 0,
        left: "50%",
        bottom: 0,
        right: 0,
        margin: "auto",
        transform: "translateX(-50%)",
        transformOrigin: "center center",
        opacity: 0
      },
      "&:before": {
        transition: "opacity 0.2s",
        zIndex: -1
      },
      "&:after": {
        transform: "translateX(-50%) scale(0)",
        transition: "transform 0.2s, opacity 0.2s",
        transitionDelay: "0.1s"
      },
      "&[aria-checked='true'], &[data-state='indeterminate']": {
        "&:disabled": {
          opacity: 0.5
        }
      },
      "&[aria-checked='false']": {
        "&:disabled": {
          borderColor: theme.newPalette.action.disabled
        }
      },
      "&:disabled": {
        cursor: "not-allowed"
      },
      "&:not(:disabled)": {
        "&:hover": {
          "&:before": {
            opacity: 1
          }
        },
        "&:active": {
          "&:after": {
            opacity: 1,
            transform: "translateX(-50%) scale(1)",
            transitionDelay: "0"
          }
        }
      },
      "& svg": {
        maxWidth: "100%",
        "& path": {
          fill: theme.newPalette.background.paper
        }
      }
    },
    sizeVariants: {
      "&.small": {
        height: "18px",
        width: "18px",
        "&:before, &:after": {
          width: "28px",
          height: "28px"
        }
      },
      "&.medium": {
        height: "22px",
        width: "22px",
        borderWidth: "3px",
        "&:before, &:after": {
          width: "42px",
          height: "42px"
        }
      },
      "&.large": {
        height: "28px",
        width: "28px",
        borderWidth: "3px",
        "&:before, &:after": {
          width: "50px",
          height: "50px"
        }
      }
    },
    colorVariants: {
      "&.blue": {
        borderColor: theme.newPalette.text.primary,
        "&:before": {
          backgroundColor: theme.newPalette.secondary.states.hover
        },
        "&:after": {
          backgroundColor: theme.newPalette.secondary.states.focusVisible
        },
        "&[aria-checked='true'], &[data-state='indeterminate']": {
          backgroundColor: theme.newPalette.secondary.main,
          borderColor: theme.newPalette.secondary.main
        }
      },
      "&.green": {
        borderColor: theme.newPalette.success.dark,
        "&:before": {
          backgroundColor: theme.newPalette.success.states.hover
        },
        "&:after": {
          backgroundColor: theme.newPalette.success.states.focusVisible
        },
        "&[aria-checked='true'], &[data-state='indeterminate']": {
          backgroundColor: theme.newPalette.success.main,
          borderColor: theme.newPalette.success.main
        }
      },
      "&.red": {
        borderColor: theme.newPalette.error.dark,
        "&:before": {
          backgroundColor: theme.newPalette.error.states.hover
        },
        "&:after": {
          backgroundColor: theme.newPalette.error.states.focusVisible
        },
        "&[aria-checked='true'], &[data-state='indeterminate']": {
          backgroundColor: theme.newPalette.error.main,
          borderColor: theme.newPalette.error.main
        }
      },
      "&.purple": {
        borderColor: theme.newPalette.purple["600"],
        "&:before": {
          backgroundColor: theme.newPalette.purple["50"]
        },
        "&:after": {
          backgroundColor: theme.newPalette.purple["100"],
          zIndex: -1
        },
        "&[aria-checked='true'], &[data-state='indeterminate']": {
          backgroundColor: theme.newPalette.purple["500"],
          borderColor: theme.newPalette.purple["500"]
        }
      }
    },
    indeterminateShape: {
      display: "inline-block",
      height: "3px",
      background: "#ffffff",
      borderRadius: "10px",
      width: "100%",
      "&.small": {
        height: "2px"
      }
    }
  };
});
