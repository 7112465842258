import type { PropsWithChildren, ReactNode } from "react";
import { DataCy } from "@doorloop/dto";
import { makeStyles } from "@material-ui/styles";
import { Accordion } from "DLUI/accordion/accordion";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(() => {
  return {
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 16
    },
    collapsibleContent: {
      padding: "16px 8px"
    }
  };
});

export type FeeItemProps = PropsWithChildren<{
  title: ReactNode;
  end?: ReactNode;
  isOpen: boolean;
  onToggle: () => void;
  dataCy?: string;
}>;

export const FeeItem = ({ children, title, end, isOpen, onToggle, dataCy }: FeeItemProps) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Accordion
      dataCy={dataCy}
      transitionProps={{ preload: true }}
      isExpanded={isOpen}
      toggleIsExpanded={onToggle}
      expandedButtonOptions={{ color: "black", size: 28, removePadding: true }}
      SummaryContent={<>{title}</>}
      customSummaryClasses={classes.header}
      styles={{
        root: {
          backgroundColor: theme.newPalette.secondary.states.hover,
          borderRadius: 4
        },
        summary: {
          height: 28,
          marginRight: 0
        },
        details: {
          borderTop: `1px solid ${theme.newPalette.other.divider}`
        }
      }}
      SummaryEndContent={<>{end}</>}
      DetailsContent={
        <div className={classes.collapsibleContent} data-cy={DataCy.DLUI.accordion.content}>
          {children}
        </div>
      }
    />
  );
};
