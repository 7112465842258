"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NamesDictionary = void 0;
class NamesDictionary {
    constructor(name, fullName, pictureUrl, type, prospectInfoStatus) {
        this.name = name;
        this.fullName = fullName;
        this.pictureUrl = pictureUrl;
        this.type = type;
        this.prospectInfoStatus = prospectInfoStatus;
    }
}
exports.NamesDictionary = NamesDictionary;
