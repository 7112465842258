import type { CSSProperties } from "react";
import React, { useCallback, useMemo } from "react";
import spinnerAnimation from "assets/lottie/spinner.json";
import { Lottie } from "DLUI/lottie";
import { View } from "DLUI/view";
import { Box, Grid } from "@material-ui/core";
import type { GridSize } from "@material-ui/core/Grid/Grid";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import type { Path } from "react-hook-form";
import type { TextColor, TextComponentProps, TextFormatType } from "DLUI/text/text";
import type { DisplayMode } from "./theme";
import { EditableTableThemes } from "./theme";
import { AnimatePresence } from "framer-motion";
import makeStyles from "./styles";
import _ from "lodash";
import clsx from "clsx";
import { Icon } from "DLUI/icon";
import { LocationIcon } from "assets/icons";
import { DefaultListItemIconSize } from "screens/leases/leases/leasesList/leaseListItem";
import { ControlledCheckBox } from "DLUI/form/checkBox/base/controlledCheckBox";
import { VirtualList } from "DLUI/virtualScroll/components/virtualList";

type RowIndex = number;

export interface EditableTableColumn<DtoType extends object> {
  /**
   * The name of the field in the data object that the column will display. This field can have one of three types:
   *  - "checkbox": This is a special field that will display a checkbox in the column.
   *  - Path<DtoType>: This is a type inference helper that will allow you to specify the path to the field in the data object.
   *  - string: This is a simple string that represents an arbitrary name of the field in the data object.
   *  - anonymous function that returns a string
   */
  field: "checkbox" | Path<DtoType> | string | ((dto: DtoType) => string);

  /**
   * The name of the column that will be displayed in the table's header.
   */
  headerName: string;

  /**
   * The size of the column in the grid. This field can have one of three types:
   *  - GridSize: This is a type that represents the size of the column in the grid. This type is imported from @material-ui/core/Grid
   *  - "auto": This is a special value that indicates that the column should be sized automatically based on the content of the cells in the column.
   *  - true: This is a special value that indicates that the column should take all the remaining space in the grid.
   */
  gridSize: GridSize | "auto" | true;

  /**
   * (optional) The alignment of the text in the cells of the column.
   * This field inherits from text component's "align" property.
   * The default value is "left".
   * This field works only on raw text and is ignored if the "renderEditableCell" function is provided.
   */
  align?: TextComponentProps["align"];

  /**
   * (optional) The format of the text in the cells of the column.
   * This field inherits from text component's "formatType" property.
   */
  formatType?: TextComponentProps["formatType"];

  /**
   * (optional) A function that takes a data object and a translation function as arguments and returns a string
   * that will be displayed in the cells of the column.
   * This function is useful for customizing the way the data is displayed in the cells of the column.
   * Since the return string of this function will be passed into the <Text> component, you may choose to return an untranslated
   * locale key and the <Text> component will take care of translating the string.
   * It's required to provide either this function or the "renderEditableCell" function.
   */
  valueGetter?: (dto: DtoType, t: TFunction<"translation">) => string;

  /**
   * (optional) A function that takes a data object, an index, a translation function,
   * and a flag indicating whether the cell is disabled as arguments and returns a React element.
   *
   * This function is useful for displaying a text field, dropdown, switch,
   * or any other editable formik component in the cells of the column.
   * It's required to provide either this function or the "valueGetter" function.
   */
  renderEditableCell?: (
    dto: DtoType,
    index: number,
    t: TFunction<"translation">,
    isDisabled: boolean
  ) => React.ReactNode;

  /**
   * (optional) A function that takes a data object and an index as arguments and returns a boolean value
   * indicating whether the cell at the given index in the column should be disabled.
   * This function is useful for disabling cells in the column based on the data in the cells.
   * If the special "checkbox" cell in the column is disabled, the checkbox will be hidden.
   */
  isDisabled?: (dto: DtoType, index: number) => boolean;

  /**
   * (optional) A flag indicating whether the column should be hidden if the specific row is not selected using the checkbox.
   * If true, the column will be hidden if the row is not selected using the checkbox.
   * if false, the column will be visible regardless of whether the row is selected using the checkbox.
   */
  hideIfUnselected?: boolean;
}

interface ComponentProps<DtoType extends object> {
  isLoading: boolean;
  data: DtoType[];
  columns: ReadonlyArray<EditableTableColumn<DtoType>>;
  hideFooter?: boolean;
  rowHeight?: CSSProperties["height"];
  headerRowHeight?: CSSProperties["height"];
  rowGap?: CSSProperties["gap"];
  fontSize?: number;
  checkboxSize?: number;
  totalDisplayAmount?: string;
  customTotalLabel?: string;
  singularItemName: string;
  pluralItemName: string;
  totalDisplayFormatType?: TextFormatType;

  onSelectionChanged?: (selected: boolean, index: number) => void;
  onSelectAllClicked?: () => void;
  selectedIndices: Record<RowIndex, boolean>;
  noDataComponent: JSX.Element;
  cellPadding?: CSSProperties["padding"];
  style?: CSSProperties;
  displayMode?: DisplayMode;
  rowTextColor?: TextColor | ((dto: DtoType, index: number, isSelected: boolean) => TextColor);
  rowColor?:
    | React.CSSProperties["backgroundColor"]
    | ((dto: DtoType, index: number, isSelected: boolean) => React.CSSProperties["backgroundColor"]);
  rowHoverColor?:
    | React.CSSProperties["backgroundColor"]
    | ((dto: DtoType, index: number, isSelected: boolean) => React.CSSProperties["backgroundColor"]);
  /**
   * Card Expansion Capabilities
   */
  onRowClicked?: (dto: DtoType, index: number, isDisabled: boolean) => void;
  expandedIndices?: Record<RowIndex, boolean>;
  renderExpandedCard?: (dto: DtoType, index: number) => JSX.Element;
}

const DefaultGridRowHeight: CSSProperties["height"] = 70;
const DefaultTextFontSize = 16;
const DefaultRowGap = {
  table: 0,
  card: 5
} as const satisfies Record<DisplayMode, CSSProperties["gap"]>;
const DefaultCellPadding: CSSProperties["padding"] = "3px 7px";

/**
 * EditableTable is a generic React component that displays a table that can be edited by the user.
 *
 * @param data an array of data objects of generic type DtoType that will be displayed in the table
 * @param isLoading a boolean value indicating whether the data is still being loaded
 * @param columns an array of GridColumn objects that specify the columns to be displayed in the table and how to render them
 * @param hideFooter a boolean value indicating whether the footer should be hidden (defaults to false)
 * @param rowHeight the height of each row in the table (defaults to DefaultGridRowHeight)
 * @param headerRowHeight the height of the header row in the table (defaults to DefaultGridRowHeight)
 * @param rowGap the gap between each row in the table (defaults to DefaultRowGap)
 * @param fontSize the font size of the text in the table (defaults to 16)
 * @param checkboxSize the size of the checkbox in the table
 * @param totalDisplayAmount the total number of items being displayed in the table
 * @param totalDisplayFormatType the format type of the total amount (e.g. currency, percentage). See the <Text> component (defaults to currency)
 * @param customTotalLabel a custom "Total" label to display the total number of items (defaults to "Total Amount")
 * @param singularItemName the singular name of the item being displayed in the table (e.g. "unit", "product", "customer")
 * @param pluralItemName the plural name of the item being displayed in the table (e.g. "units", "products", "customers")
 * @param onSelectionChanged a callback function that is called when the user selects or deselects a row in the table
 * @param noDataComponent a React component to display if there is no data to be displayed in the table
 * @param onSelectAllClicked a callback function that is called when the user clicks the "Select All" checkbox in the table. If this function is not provided, the "Select All" checkbox will not be displayed.
 * @param selectedIndices an object that contains the indices of the selected rows in the table (e.g. { 0: true, 2: true })
 * @param onRowClicked a callback function that is called when the user clicks a row in the table
 * @param renderExpandedCard a function that takes a data object and an index as arguments and returns a React component that will be displayed in the row card
 * @param style an optional style object to apply to the table
 * @param displayMode the display mode of the table (defaults to "table")
 * @param rowTextColor a string color OR a function that takes a data object and an index as arguments and returns a string that specifies the color of the row TEXT
 * @param rowColor a string color OR a function that takes a data object and an index as arguments and returns a string that specifies the color of the row
 * @param rowHoverColor a string color OR a function that takes a data object and an index as arguments and returns a string that specifies the color of the row when the user hovers over it
 *
 * @returns a React component that displays the table
 */
export function EditableTable<DtoType extends object>({
  data,
  isLoading,
  columns,
  hideFooter,
  pluralItemName,
  singularItemName,
  rowHeight = DefaultGridRowHeight,
  headerRowHeight = DefaultGridRowHeight,
  rowGap,
  fontSize = DefaultTextFontSize,
  cellPadding = DefaultCellPadding,
  checkboxSize,
  rowTextColor,
  rowColor,
  totalDisplayFormatType,
  totalDisplayAmount,
  customTotalLabel,
  onSelectionChanged,
  noDataComponent,
  onSelectAllClicked,
  selectedIndices,
  onRowClicked,
  expandedIndices,
  renderExpandedCard,
  rowHoverColor,
  displayMode = "table",
  style
}: ComponentProps<DtoType>) {
  const { t } = useTranslation();
  const theme = EditableTableThemes[displayMode];
  const classes = makeStyles();

  /**
   * Sanity Check to make sure that the column sizes add up to 12
   */
  const totalColumnSize = useMemo(() => {
    const sizeSum = columns.reduce(
      (total, column) => total + (typeof column.gridSize === "number" ? column.gridSize : 0),
      0
    );

    /**
     * We allow the sum to deviate from 12 only if there's a column with gridSize = true or "auto"
     * This is because the column with gridSize = true or "auto" will take up the remaining space
     * @see material-ui Grid documentation
     */
    return sizeSum === 12 || columns.some((column) => column.gridSize === true || column.gridSize === "auto")
      ? 12
      : sizeSum;
  }, [columns]);

  if (totalColumnSize !== 12) {
    throw new Error("The table column sizes MUST add up to 12, but it is " + totalColumnSize);
  }

  // O(1) key lookup
  const isRowSelected = (index: number) => Boolean(selectedIndices?.[index]);

  const isRowExpanded = (index: number) => Boolean(expandedIndices?.[index]);

  const onSelectionClick = (dataRow: DtoType, index: number) => {
    onSelectionChanged?.(!isRowSelected(index), index);
  };

  const isCheckboxDisabledPredicate = useCallback(
    columns.find((column) => column.field === "checkbox")?.isDisabled ?? (() => false),
    [columns]
  );

  const areAllSelected = useMemo(
    () =>
      data
        .filter((_, index) => !isCheckboxDisabledPredicate(data[index], index))
        .every((_, index) => isRowSelected(index)),
    [selectedIndices]
  );

  const selectedRowCount = useMemo(
    () =>
      data
        .filter((_, index) => !isCheckboxDisabledPredicate(data[index], index))
        .filter((_, index) => isRowSelected(index)).length,
    [selectedIndices]
  );

  if (data.length === 0) {
    return noDataComponent;
  }

  if (isLoading) {
    return (
      <View alignItems={"center"} justifyContent={"center"}>
        <Lottie loop={true} animationData={spinnerAnimation} width={50} height={50} />
      </View>
    );
  }

  // get the selected number of rows
  // making sure to filter out the disabled rows

  const renderCell = (column: EditableTableColumn<DtoType>, row: DtoType, index: number): JSX.Element => {
    const isDisabled = column.isDisabled?.(row, index) ?? false;
    const textColor =
      typeof rowTextColor === "function" ? rowTextColor(row, index, isRowSelected(index)) : rowTextColor;
    const valueGetterResult = column.valueGetter?.(row, t);
    const columnField = _.isString(column.field) ? column.field : column.field(row);
    return (
      <Grid
        item
        xs={column.gridSize}
        key={`body-column-${index}-${column.field}`}
        style={{ padding: cellPadding, boxSizing: "border-box" }}
      >
        {columnField === "checkbox" ? (
          <div style={{ visibility: isDisabled ? "hidden" : "visible" }}>
            <ControlledCheckBox checked={isRowSelected(index)} onChange={() => onSelectionClick(row, index)} />
          </div>
        ) : columnField === "multipleUnits" ? (
          <View justifyContent={"flex-start"} alignItems={"center"} flexDirection={"row"} noWrap>
            <Icon
              Source={LocationIcon}
              width={DefaultListItemIconSize}
              height={DefaultListItemIconSize}
              marginRight={5}
            />
            <Text
              overFlow="ellipsis"
              showTooltipOnHover={Boolean(valueGetterResult && valueGetterResult.length > 20)}
              formatType={column.formatType}
              fontSize={fontSize}
              align={column.align}
              color={isDisabled ? "secondary-gray" : textColor}
              value={valueGetterResult ?? ""}
            />
          </View>
        ) : (
          (column.hideIfUnselected && !isRowSelected(index)
            ? null // If the column needs to be hidden if the row is unselected, return null
            : column.renderEditableCell?.(row, index, t, isDisabled)) ?? (
            <Text
              overFlow="ellipsis"
              showTooltipOnHover={Boolean(valueGetterResult && valueGetterResult.length > 20)}
              formatType={column.formatType}
              fontSize={fontSize}
              align={column.align}
              color={isDisabled ? "secondary-gray" : textColor}
              value={valueGetterResult ?? ""}
            />
          )
        )}
      </Grid>
    );
  };

  const renderHeaderCell = (column: EditableTableColumn<DtoType>, index: number): JSX.Element => (
    <Grid
      item
      xs={column.gridSize}
      key={`header-column-${index}-${column.field}`}
      style={{ padding: cellPadding, boxSizing: "border-box" }}
    >
      {column.field === "checkbox" ? (
        <span style={{ visibility: typeof onSelectAllClicked === "function" ? "visible" : "hidden" }}>
          <ControlledCheckBox checked={areAllSelected} onChange={onSelectAllClicked} />
        </span>
      ) : (
        <Text fontSize={fontSize} align={column.align} value={column.headerName} bold />
      )}
    </Grid>
  );

  const getRowStyle = (index: number): React.CSSProperties => {
    const isSelected = isRowSelected(index);
    const rowStyle = {
      width: "100%",
      borderBottom: "1px solid #ECEEF5",
      ...theme?.[isSelected ? "checked" : "neutral"].row
    };

    if (typeof rowColor === "function") {
      rowStyle.backgroundColor = rowColor(data[index], index, isSelected);
    } else if (typeof rowColor === "string") {
      rowStyle.backgroundColor = rowColor;
    }

    if (typeof rowHoverColor === "function") {
      rowStyle["--editable-table-hover-color"] = rowHoverColor(data[index], index, isSelected);
    } else if (typeof rowHoverColor === "string") {
      rowStyle["--editable-table-hover-color"] = rowHoverColor;
    }

    return rowStyle;
  };

  return (
    <Box
      style={{
        backgroundColor: theme.backgroundColor,
        borderRadius: 20,
        paddingBottom: 10,
        paddingTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
        boxSizing: "border-box",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: rowGap ?? DefaultRowGap[displayMode],
        ...style
      }}
    >
      {/*Table Header*/}
      <Grid
        container
        alignItems={"center"}
        style={{
          height: headerRowHeight,
          overflow: "hidden",
          boxSizing: "border-box",
          ...theme.neutral.header
        }}
      >
        {columns.map((column, index) => renderHeaderCell(column, index))}
      </Grid>
      {/* Table body */}
      <VirtualList items={data} estimateSize={() => 70}>
        {(row, index) => (
          <div
            className={clsx({ [classes.tableRowHoverable]: Boolean(rowHoverColor) })}
            key={`tr-${index}`}
            style={getRowStyle(index)}
          >
            <Grid
              key={`tr-row-${index}`}
              container
              alignItems={"center"}
              onClick={() => onRowClicked?.(row, index, isCheckboxDisabledPredicate(row, index))}
              style={{
                height: rowHeight,
                overflow: "hidden",
                cursor: onRowClicked ? "pointer" : undefined
              }}
            >
              {columns.map((column) => renderCell(column, row, index))}
            </Grid>

            <AnimatePresence>
              {renderExpandedCard !== undefined && isRowExpanded(index) && (
                <div key={"card-" + index} style={{ width: "100%" }}>
                  {renderExpandedCard(row, index)}
                </div>
              )}
            </AnimatePresence>
          </div>
        )}
      </VirtualList>
      {!hideFooter && (
        <Grid
          container
          alignItems={"center"}
          style={{ height: DefaultGridRowHeight, paddingLeft: 10, paddingRight: 10 }}
        >
          <Grid container item justifyContent="flex-start" xs>
            <Grid item xs={"auto"}>
              {/* x items selected */}
              {selectedRowCount > 0 && (
                <Text
                  bold
                  color="secondary-gray"
                  value={AppStrings.Common.xItemNameSelected}
                  replaceObject={{
                    x: selectedRowCount,
                    itemName: selectedRowCount === 1 ? t(singularItemName) : t(pluralItemName)
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Grid container item justifyContent="flex-end" xs={4} spacing={1}>
            <Grid item xs={"auto"}>
              <Text value={customTotalLabel ?? AppStrings.Leases.LeaseCharge.TotalAmount} bold suffix={":"} />
            </Grid>
            <Grid item xs={"auto"}>
              <Text value={totalDisplayAmount} formatType={totalDisplayFormatType} bold />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
