import type { ComponentProps } from "react";
import React from "react";
import { ListItemInputsContainer } from "DLUI/listItems";
import { View } from "DLUI/view";
import { FastField } from "formik";
import { TextField } from "DLUI/form";
import Text from "DLUI/text";
import { Grid } from "@material-ui/core";
import AppStrings from "../../../../locale/keys";
import { useTranslation } from "react-i18next";
import { SeparationLine } from "DLUI/separatorView";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface DepositHeldLineProps extends Pick<ComponentProps<typeof View>, "domRef"> {
  accountName: string;
  amountHeld: number;
  lineIndex: number;
}

const DepositHeldLine: React.FC<DepositHeldLineProps> = ({ accountName, amountHeld, lineIndex, domRef }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();
  const textHeight = isMobile ? "auto" : "40px";

  return (
    <ListItemInputsContainer domRef={domRef} showDeleteButton={false} showDuplicateButton={false}>
      <Grid xs={12} md={4}>
        <View justifyContent="center" height={textHeight}>
          <Text color={"black"} fontSize={14} fontWeight={700}>
            {accountName}
          </Text>
        </View>
      </Grid>
      {isMobile && <SeparationLine width={"100%"} height={1} />}
      <Grid xs={12} md={2}>
        <View
          flexDirection="row"
          height={textHeight}
          justifyContent={isMobile ? "space-between" : "flex-end"}
          alignItems="center"
        >
          {isMobile && (
            <Text color={"black"} fontSize={14} fontWeight={700}>
              {t(AppStrings.Leases.LeaseTransaction.Deposit.AmountHeld)}
            </Text>
          )}
          <Text
            color={"black"}
            fontSize={14}
            fontWeight={400}
            formatType={"currency"}
            value={String(amountHeld)}
            align={"left"}
          />
        </View>
      </Grid>
      <Grid xs={12} md={3}>
        <FastField
          label={t(AppStrings.Leases.LeaseCharge.Description)}
          component={TextField}
          name={`lines[${lineIndex}].memo`}
        />
      </Grid>
      <Grid xs={12} md={3}>
        <FastField
          component={TextField}
          name={`lines[${lineIndex}].amountToWithhold`}
          type={"number"}
          required
          formatType={"currency"}
          allowNegative
          label={t(AppStrings.Leases.NewLease.LeaseRent.Amount)}
        />
      </Grid>
    </ListItemInputsContainer>
  );
};

export default DepositHeldLine;
