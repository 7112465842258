"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAllMerchantAccountsQuery = void 0;
const getAllBaseRequest_query_1 = require("../getAllBaseRequest.query");
const merchantAccountPlatform_enum_1 = require("./merchantAccountPlatform.enum");
const merchantAccountStatus_enum_1 = require("./merchantAccountStatus.enum");
const class_validator_1 = require("class-validator");
const verificationSessionStatus_enum_1 = require("../merchantAccounts/identityVerification/verificationSessionStatus.enum");
class GetAllMerchantAccountsQuery extends getAllBaseRequest_query_1.GetAllBaseQueryRequest {
    constructor(init) {
        super();
        Object.assign(this, init);
        if (!this.page_size) {
            this.page_size = getAllBaseRequest_query_1.DEFAULT_PAGE_SIZE;
        }
    }
}
exports.GetAllMerchantAccountsQuery = GetAllMerchantAccountsQuery;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllMerchantAccountsQuery.prototype, "filter_account", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], GetAllMerchantAccountsQuery.prototype, "filter_identity_session_id", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(verificationSessionStatus_enum_1.VerificationSessionStatus),
    __metadata("design:type", String)
], GetAllMerchantAccountsQuery.prototype, "filter_identity_status", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], GetAllMerchantAccountsQuery.prototype, "filter_owner_first_name", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], GetAllMerchantAccountsQuery.prototype, "filter_owner_last_name", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], GetAllMerchantAccountsQuery.prototype, "filter_accounts", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], GetAllMerchantAccountsQuery.prototype, "filter_payrix_merchantId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(merchantAccountPlatform_enum_1.MerchantAccountPlatform),
    __metadata("design:type", String)
], GetAllMerchantAccountsQuery.prototype, "filter_platform", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(merchantAccountStatus_enum_1.MerchantAccountStatus),
    __metadata("design:type", String)
], GetAllMerchantAccountsQuery.prototype, "filter_status", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllMerchantAccountsQuery.prototype, "filter_property", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllMerchantAccountsQuery.prototype, "filter_defaultProperty", void 0);
