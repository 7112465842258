import type { ApiResult, FileDto, RentalApplicationDto } from "@doorloop/dto";
import {
  createValidator,
  DataCy,
  LinkedResourceType,
  PutUpdateDecisionStatusDto,
  RentalApplicationDecisionStatus,
  SegmentEventTypes
} from "@doorloop/dto";
import { rentalApplicationsApi } from "api/rentalApplicationsApi";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { TextField } from "DLUI/form";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, FormikContext, useFormik } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DecisionImageButtonGroup from "./decisionImageButtonGroup";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { rentalApplicationEventsType, useRentalApplicationEvents } from "../../../../hooks/useRentalApplicationEvents";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { ConfirmDialogUi } from "DLUI/dialogs/confirm/confirmDialogUi";
import { NavigationManager } from "../../../../utils/navigation";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import WarningView from "DLUI/form/warningView/warningView";
import { AnimatedView } from "DLUI/view/animatedView";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import { filesApi } from "api/filesApi";
import ColorsEnum from "utils/colorsEnum";
import { FormikSwitchButtonRow } from "DLUI/form/switchButton/FormikSwitchButtonRow";
import { makeStyles } from "@material-ui/core/styles";
import { useRentalApplicationLocale } from "hooks/useRentalApplicationLocale";
import { SpyAlertIcon } from "@/assets";

interface ComponentProps {
  onClose: VoidFunction;
  onBackdropClick: VoidFunction;
  dialogTitle: string;
}

enum DialogView {
  DecisionForm,
  NoScreeningAlert,
  Loading
}

const RENTAL_APPLICATION_DECISION_STATUSES_WITH_EMAIL_SENDING = [
  RentalApplicationDecisionStatus.APPROVED,
  RentalApplicationDecisionStatus.REJECTED
];

const RENTAL_APPLICATION_DECISION_STATUSES_WITH_REASON = [RentalApplicationDecisionStatus.REJECTED];

const useStyles = makeStyles({
  customPlaceholder: {
    "& ::placeholder": {
      color: `#7A8699 !important`,
      opacity: 1
    }
  }
});
const validateForm = createValidator(PutUpdateDecisionStatusDto);

const UpdateRentalApplicationDecisionStatusDialog: React.FC<ComponentProps> = ({
  onClose,
  onBackdropClick,
  dialogTitle
}: ComponentProps) => {
  const { t } = useTranslation();
  const textFieldClasses = useStyles();
  const { screenContainerPadding, isMobile } = useResponsiveHelper();
  const { applicationId } = useParams<any>();
  const editMode = applicationId !== undefined;
  const [viewIndex, setViewIndex] = useState<DialogView>(DialogView.DecisionForm);
  const [applicationData, setApplicationData] = useState<RentalApplicationDto | undefined>();
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    editMode ? DialogState.Show : DialogState.Hidden
  );
  const { isUs, isNonNorthAmerica } = useRentalApplicationLocale();

  const { fireEvent } = useRentalApplicationEvents({ rentalApplicationId: applicationId });

  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");

  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState<string>("");

  const [loadingDialogSuccessText, setLoadingDialogSuccessText] = useState<string>(
    t(AppStrings.Tasks.InternalTaskDialog.LoadingTaskDataSuccessText)
  );
  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);

  const confirmDialogPadding = isMobile ? 0 : 60;

  const initFormValues = useMemo(() => {
    if (applicationData) {
      return new PutUpdateDecisionStatusDto({
        rentalApplication: applicationId,
        decisionStatusNotes: applicationData.decisionStatusNotes,
        newDecisionStatus: applicationData.decisionStatus
      });
    }

    return new PutUpdateDecisionStatusDto();
  }, [applicationData]);

  const formik = useFormik<PutUpdateDecisionStatusDto>({
    initialValues: initFormValues,
    onSubmit: _.noop,
    validate: validateForm,
    enableReinitialize: true
  });

  const shouldShowWarningAlert = Boolean(
    !isNonNorthAmerica && formik.values?.newDecisionStatus === RentalApplicationDecisionStatus.REJECTED
  );

  const shouldShowSendAutomatedEmailToggle = Boolean(
    formik.values?.newDecisionStatus &&
      RENTAL_APPLICATION_DECISION_STATUSES_WITH_EMAIL_SENDING.includes(formik?.values?.newDecisionStatus)
  );

  const isStatusRejected = Boolean(
    formik.values?.newDecisionStatus &&
      RENTAL_APPLICATION_DECISION_STATUSES_WITH_REASON.includes(formik?.values?.newDecisionStatus)
  );

  const shouldHaveReasons = Boolean(formik.values.shouldSendAutomatedResponseEmail && isStatusRejected);

  const dialogHeight = useMemo(() => {
    let height = 430;
    if (viewIndex === DialogView.NoScreeningAlert) {
      height = 500;
    } else if (
      formik.values.newDecisionStatus &&
      RENTAL_APPLICATION_DECISION_STATUSES_WITH_REASON.includes(formik.values.newDecisionStatus)
    ) {
      const baseHeight = formik.values.shouldSendAutomatedResponseEmail ? 670 : 430;
      const legalNoteHeight = 130;
      height = shouldShowWarningAlert ? baseHeight + legalNoteHeight : baseHeight;
    }

    return getDialogFrameDimension("height", height);
  }, [
    formik.values.newDecisionStatus,
    formik.values.shouldSendAutomatedResponseEmail,
    shouldShowWarningAlert,
    viewIndex,
    window.innerHeight
  ]);

  const dialogWidth = useMemo(() => {
    const width = viewIndex === DialogView.NoScreeningAlert ? 690 : 600;
    return getDialogFrameDimension("width", width);
  }, [window.innerWidth, viewIndex]);

  useEffect(() => {
    if (editMode) {
      loadApplicationData();
    }
  }, []);

  useEffect(() => {
    const decision = formik.values.newDecisionStatus;
    if (
      decision &&
      [
        RentalApplicationDecisionStatus.IGNORED,
        RentalApplicationDecisionStatus.UNDECIDED,
        RentalApplicationDecisionStatus.NOT_STARTED
      ].includes(decision)
    ) {
      formik.setFieldValue("shouldSendAutomatedResponseEmail", false);
    }
  }, [formik.values.newDecisionStatus]);

  const showErrorMessage = (message: string) => {
    setLoadingDialogErrorText(message);
    setLoadingDialogState(DialogState.Error);
  };

  const loadApplicationData = async () => {
    if (applicationId) {
      setLoadingDialogLoadingText(t(AppStrings.Common.Loading));
      setLoadingDialogState(DialogState.Show);
      const response = await rentalApplicationsApi.get(applicationId).catch((e) => {
        showErrorMessage(e);
      });
      if (response && response.data) {
        setLoadingDialogSuccessText(t(AppStrings.Tasks.InternalTaskDialog.LoadingTaskDataSuccessText));
        setLoadingDialogState(DialogState.Success);
        setApplicationData(response.data);
        setLoadingDialogState(DialogState.Hidden);
        return;
      }
      if (response) {
        showErrorMessage(response.message);
      }
    }
    showErrorMessage(t(AppStrings.Common.NetworkErrorSubTitle));
  };

  const didPressDismissButton = () => {
    onBackdropClick();
  };

  const handleScreenProspect = () => {
    applicationData?.id && NavigationManager.navigateToRentalApplicationTab(applicationData.id, "reports");
  };

  const renderView = ({ index }: any) => {
    if (index === 0) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={loadApplicationData}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }
      return renderForm();
    }
    if (index === 1) {
      return (
        <ConfirmDialogUi
          dialogTitle={AppStrings.Common.YouAreSkipTenantScreening}
          dialogSubTitle={AppStrings.Common.DescriptionScreeningAlert}
          confirmButtonText={AppStrings.Common.ScreenProspect}
          dismissButtonText={AppStrings.Common.SkipAndApprove}
          onBackdropClick={_onBackdropClick}
          didPressDismissButton={handleApproveAnyway}
          didPressConfirmButton={handleScreenProspect}
          loadingDialogState={loadingDialogState}
          dialogHeight={dialogHeight}
          dialogWidth={dialogWidth}
          centerContainer
          subTitleProps={{
            color: "gray",
            paddingLeft: confirmDialogPadding,
            paddingRight: confirmDialogPadding
          }}
          showXButton
          MainIcon={SpyAlertIcon}
        />
      );
    }
    if (index === 2) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          loadingText={loadingDialogLoadingText}
          errorText={loadingDialogErrorText}
          successText={t(AppStrings.Tasks.InternalTaskDialog.CreateTaskSuccessText)}
          onRetryButtonPress={didPressSaveButton}
          didPressDismissButton={didPressDismissButton}
        />
      );
    }

    return <div />;
  };

  const renderForm = () => (
    <FormikContext.Provider value={formik}>
      <View alignItems={"center"} paddingRight={screenContainerPadding} paddingLeft={screenContainerPadding} gap={16}>
        <DecisionImageButtonGroup />
        <View noWrap gap={12}>
          <View noWrap>
            <FastField
              component={TextField}
              label={t(AppStrings.Common.InternalNotes)}
              name={`decisionStatusNotes`}
              multiline
              rows="4"
              dataCy={DataCy.rentalApplications.decisionDialog.internalNotes}
              InputLabelProps={{
                shrink: true
              }}
              textFieldClasses={textFieldClasses}
              placeholder={t(AppStrings.Common.AddInternalNotesHere)}
            />
          </View>
        </View>
        <AnimatedView shouldShow={shouldShowSendAutomatedEmailToggle}>
          <FastField
            component={FormikSwitchButtonRow}
            name={"shouldSendAutomatedResponseEmail"}
            label={AppStrings.Common.SendAutomatedEmail}
            textProps={{
              bold: false
            }}
            viewProps={{
              paddingRight: 0,
              paddingLeft: 0,
              paddingTop: 0,
              paddingBottom: 0
            }}
            dataCy={DataCy.rentalApplications.decisionDialog.sendAutomatedEmailToggle}
          />
        </AnimatedView>
        {shouldShowWarningAlert && (
          <View noWrap>
            <WarningView
              iconSize={24}
              type={"warning"}
              dataCy={DataCy.rentalApplications.decisionDialog.mustSendLetterWarning}
              title={AppStrings.Common.ImportantLegalNote}
              titleTextProps={{
                fontSize: 14,
                colorEnum: ColorsEnum.DarkYellow
              }}
              pathColor={"secondary-yellow"}
            >
              <Text
                value={AppStrings.Common.DecisionStatusDialogWarningText}
                fontSize={14}
                colorEnum={ColorsEnum.DarkYellow}
              />
            </WarningView>
          </View>
        )}
        <AnimatedView
          shouldShow={shouldHaveReasons}
          justifyContent={"flex-end"}
          width={"100%"}
          flex={1}
          noWrap
          paddingTop={5}
          paddingBottom={10}
        >
          <FastField
            component={TextField}
            label={t(AppStrings.Common.Reasons)}
            textFieldClasses={textFieldClasses}
            placeholder={t(AppStrings.Common.ProvideAReason)}
            name={`decisionReason`}
            rows="4"
            multiline
            dataCy={DataCy.rentalApplications.decisionDialog.reason}
            InputLabelProps={{
              shrink: true
            }}
            required
          />
          <Text
            value={AppStrings.Common.Attachment}
            fontSize={14}
            color={"secondary-gray"}
            marginBottom={12}
            marginTop={12}
          />
          <FormAttachments
            onFileReceived={setAttachments}
            files={attachments}
            maxFileSizeInMB={10}
            maxTotalFilesSizeInMB={10}
            maxFiles={1}
            resourceType={LinkedResourceType.RentalApplication}
            resourceId={applicationData?.id}
            getAttachmentsByLinkedResource={false}
            allowDelete
          />
        </AnimatedView>
      </View>
    </FormikContext.Provider>
  );

  const _onBackdropClick = () => {
    if (viewIndex > 0) {
      setViewIndex(viewIndex - 1);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const isValidForm = async (formikRef: FormikProps<PutUpdateDecisionStatusDto>) => {
    if (shouldHaveReasons) {
      formikRef.setFieldTouched("decisionReason", true);
    }
    const errors = (await formikRef.validateForm()) as any;
    return _.isEmpty(errors);
  };

  const handleSave = async () => {
    const isValid = await isValidForm(formik);

    if (isValid && editMode) {
      fireEvent({
        eventName: SegmentEventTypes.RentalApplicationUserMadeDecision,
        properties: {
          [rentalApplicationEventsType.DecisionStatus]: formik.values?.newDecisionStatus
        },
        trackAnalyticsOptions: { trackEventInIntercom: true }
      });
      updateDecision();
    }
  };

  const didPressSaveButton = async () => {
    const isScreeningCompleted = Boolean(applicationData?.transunionInfo?.screeningRequestReportsCompletedAt);
    const updatedDecision = formik.values?.newDecisionStatus;

    const isValid = await isValidForm(formik);
    if (!isValid) {
      return;
    }
    if (isUs && updatedDecision === RentalApplicationDecisionStatus.APPROVED && !isScreeningCompleted) {
      setViewIndex(DialogView.NoScreeningAlert);
    } else {
      await handleSave();
    }
  };

  const handleApproveAnyway = async () => {
    await handleSave();
  };

  const updateDecision = async () => {
    if (applicationData?.id) {
      setLoadingDialogLoadingText(t(AppStrings.Tasks.InternalTaskDialog.UpdatingTask));
      setLoadingDialogState(DialogState.Show);
      setViewIndex(DialogView.Loading);
      let fileId;

      if (attachments.length && attachments.find((attach) => attach.fileData)) {
        const filesUploadResult: Array<ApiResult<FileDto>> = await filesApi.uploadFiles(
          attachments,
          applicationData?.id,
          LinkedResourceType.RentalApplication
        );
        const fileUploadResult = _.first(filesUploadResult);
        if (!(fileUploadResult?.status && fileUploadResult?.data?.id)) {
          setLoadingDialogLoadingText(t(AppStrings.Common.GeneralError));
          setLoadingDialogState(DialogState.Error);
          return;
        }
        fileId = fileUploadResult.data?.id;
      }
      const response = await rentalApplicationsApi
        .updateRentalApplicationDecisionStatus({ ...formik.values, fileId })
        .catch((e) => {
          showErrorMessage(e);
        });
      if (response && response.data) {
        setLoadingDialogSuccessText(t(AppStrings.Tasks.InternalTaskDialog.UpdateTaskSuccessText));
        setLoadingDialogState(DialogState.Success);
        onClose();
      } else if (response) {
        showErrorMessage(response.message);
      }
    }
  };

  const renderActionPanelButtons = () => (
    <FormActionButtons
      propsSubButton={{ onClick: _onBackdropClick }}
      propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton, actionText: AppStrings.Common.Update } }}
    />
  );

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      if (loadingDialogState !== DialogState.Hidden) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }
    if (viewIndex === 1) {
      return "contentOnly";
    }
    return "contentOnly";
  }, [viewIndex, loadingDialogState]);

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={AppStrings.Common.UpdateDecision}
      width={dialogWidth}
      height={dialogHeight}
      renderView={renderView}
      numViews={3}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={frameType}
      keepViewsMounted={false}
    />
  );
};

export default UpdateRentalApplicationDecisionStatusDialog;
