"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecurringTaskInfoDto = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const linkedResource_dto_1 = require("../common/linkedResource.dto");
const tasks_1 = require("../tasks");
class RecurringTaskInfoDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.RecurringTaskInfoDto = RecurringTaskInfoDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(255),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], RecurringTaskInfoDto.prototype, "reference", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(255),
    __metadata("design:type", String)
], RecurringTaskInfoDto.prototype, "subject", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(10000),
    __metadata("design:type", String)
], RecurringTaskInfoDto.prototype, "description", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], RecurringTaskInfoDto.prototype, "category", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => linkedResource_dto_1.LinkedResourceDto),
    __metadata("design:type", linkedResource_dto_1.LinkedResourceDto)
], RecurringTaskInfoDto.prototype, "linkedResource", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => tasks_1.WorkOrderDto),
    __metadata("design:type", tasks_1.WorkOrderDto)
], RecurringTaskInfoDto.prototype, "workOrder", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(tasks_1.WorkOrderPermissionToEnter),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], RecurringTaskInfoDto.prototype, "entryPermission", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], RecurringTaskInfoDto.prototype, "entryContact", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(10000),
    __metadata("design:type", String)
], RecurringTaskInfoDto.prototype, "entryNotes", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(tasks_1.TaskDateType),
    (0, class_validator_1.ValidateIf)((x) => x.dueDate || x.dueDateTime),
    __metadata("design:type", String)
], RecurringTaskInfoDto.prototype, "dateType", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(tasks_1.TaskPriority),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], RecurringTaskInfoDto.prototype, "priority", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(tasks_1.TaskStatus),
    __metadata("design:type", String)
], RecurringTaskInfoDto.prototype, "status", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(tasks_1.TaskType),
    __metadata("design:type", String)
], RecurringTaskInfoDto.prototype, "type", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.type === tasks_1.TaskType.INTERNAL_TASK || x.type === tasks_1.TaskType.WORK_ORDER),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], RecurringTaskInfoDto.prototype, "requestedByUser", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.type === tasks_1.TaskType.TENANT_REQUEST),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], RecurringTaskInfoDto.prototype, "requestedByTenant", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.type === tasks_1.TaskType.OWNER_REQUEST),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], RecurringTaskInfoDto.prototype, "requestedByOwner", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], RecurringTaskInfoDto.prototype, "assignedToUsers", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], RecurringTaskInfoDto.prototype, "notifyTenant", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], RecurringTaskInfoDto.prototype, "notifyOwner", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], RecurringTaskInfoDto.prototype, "notifyAssignees", void 0);
