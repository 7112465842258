import MomentUtils from "@date-io/moment";
import type { GetTransactionsListReportQuery } from "@doorloop/dto";
import {
  BulkReceiveSessionDto,
  createValidator,
  JournalEntryType,
  ObjectPermission,
  SegmentEventTypes
} from "@doorloop/dto";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { leasesApi } from "api/leasesApi";
import { propertiesApi } from "api/propertiesApi";
import { unitsApi } from "api/unitsApi";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { DialogTitleActionButton } from "DLUI/dialogs/components/dialogTitleActionButton";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { ArticleIdsEnum, HelpTypeEnum, VideoUrlsEnum } from "DLUI/screen/helpPanel/types";
import { SeparationLine } from "DLUI/separatorView";
import type { FormikProps } from "formik";
import { Formik } from "formik";
import { useEffectAsync } from "hooks/useEffectAsync";
import AppStrings from "locale/keys";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { analyticsService } from "services/analyticsService";
import { NavigationManager } from "utils/navigation";
import DialogFrame, { getDialogFrameDimension } from "../../components/dialogFrame";
import { BulkPaymentFooter } from "./bulkPaymentFooter";
import { BulkExecution } from "./dialogViews/bulkExecution";
import Review from "./dialogViews/review";
import SearchAndFill from "./dialogViews/searchAndFill";

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
  setIsBulkMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export enum BulkDialogView {
  SearchAndFill = 0,
  Review = 1,
  BulkExecution = 2
}

export const HelpPanel: Partial<Record<BulkDialogView, HelpPanelProps>> = {
  [BulkDialogView.SearchAndFill]: {
    description: AppStrings.BulkPayments.HelpPanel.SearchAndFill.Description,
    actionItems: [
      {
        type: HelpTypeEnum.INTERCOM_ARTICLE,
        topic: AppStrings.Leases.LeaseCharge.OneTimePaymentHelpPanel.Record,
        articleId: ArticleIdsEnum.LEASE_RECEIVE_PAY
      },
      {
        type: HelpTypeEnum.INTERCOM_ARTICLE,
        topic: AppStrings.Leases.LeaseCharge.OneTimePaymentHelpPanel.EditDelete,
        articleId: ArticleIdsEnum.EDIT_DELETE_LEASE_TRANSACTIONS
      },
      {
        type: HelpTypeEnum.WATCH_VIDEO,
        topic: AppStrings.Common.WatchExample,
        href: VideoUrlsEnum.LEASE_OVERVIEW,
        dialogTitle: AppStrings.Leases.LeaseCharge.OneTimePaymentHelpPanel.DialogTitle,
        startTime: 134
      }
    ]
  },
  [BulkDialogView.Review]: {
    description: AppStrings.BulkPayments.HelpPanel.Review.Description,
    actionItems: [
      {
        type: HelpTypeEnum.INTERCOM_ARTICLE,
        topic: AppStrings.Leases.LeaseCharge.OneTimePaymentHelpPanel.Record,
        articleId: ArticleIdsEnum.LEASE_RECEIVE_PAY
      },
      {
        type: HelpTypeEnum.INTERCOM_ARTICLE,
        topic: AppStrings.Leases.LeaseCharge.OneTimePaymentHelpPanel.EditDelete,
        articleId: ArticleIdsEnum.EDIT_DELETE_LEASE_TRANSACTIONS
      },
      {
        type: HelpTypeEnum.WATCH_VIDEO,
        topic: AppStrings.Common.WatchExample,
        href: VideoUrlsEnum.LEASE_OVERVIEW,
        dialogTitle: AppStrings.Leases.LeaseCharge.OneTimePaymentHelpPanel.DialogTitle,
        startTime: 134
      }
    ]
  }
};

const BulkPayment = ({ setIsBulkMode, ...props }: ComponentProps) => {
  const { t } = useTranslation();
  const permission: AnyPermissionClearance = {
    permission: ObjectPermission.bulkReceivePayments,
    field: "create"
  } as const;

  const [viewIndex, setViewIndex] = React.useState<BulkDialogView>(BulkDialogView.SearchAndFill);
  const helpObject = HelpPanel[viewIndex];

  function confirmExit(formikProps: FormikProps<BulkReceiveSessionDto>) {
    if (Object.values(formikProps.values?.payments ?? {}).some((payment) => payment.isFinished)) {
      if (!window.confirm(t(AppStrings.BulkPayments.UserConfirmLeaveCurrentSession))) return false;
    }

    return true;
  }

  function onBackdropClick(formikProps: FormikProps<BulkReceiveSessionDto>) {
    if (!confirmExit(formikProps)) return;
    analyticsService.track(SegmentEventTypes.BULK_RP_CLOSE_BUTTON_CLICKED);
    props.onBackdropClick();
  }

  function onClose(formikProps: FormikProps<BulkReceiveSessionDto>) {
    if (!confirmExit(formikProps)) return;

    analyticsService.track(SegmentEventTypes.BULK_RP_CLOSE_BUTTON_CLICKED);
    props.onClose();
  }

  function onBackClicked(formikProps: FormikProps<BulkReceiveSessionDto>) {
    const actionMap: Record<BulkDialogView, () => void> = {
      [BulkDialogView.SearchAndFill]: () => onClose(formikProps),
      [BulkDialogView.Review]: () => setViewIndex(BulkDialogView.SearchAndFill),
      [BulkDialogView.BulkExecution]: () => {}
    } as const;

    actionMap[viewIndex]();
  }

  useEffectAsync(async () => {
    await Promise.allSettled([
      leasesApi.loadDictionariesRequiredForGet(),
      unitsApi.loadDictionariesRequiredForGet(),
      propertiesApi.loadDictionariesRequiredForGet()
    ]);
  }, []);

  function onReviewClicked(formikProps: FormikProps<BulkReceiveSessionDto>) {
    const { payments } = formikProps.values;
    const paymentsArray = Object.values(payments);
    // don't allow to go to review if there are no payments
    if (paymentsArray.length === 0) {
      formikProps.setFieldError("payments", t(AppStrings.BulkPayments.Errors.NoPaymentsSelected));
      return;
    }
    formikProps.setFieldError("payments", undefined);

    // at this point, the form is guaranteed to be valid because of nested validation
    setViewIndex(BulkDialogView.Review);
  }

  const onBulkExecutionFinished = (batch: string, shouldRefresh?: boolean) => {
    if (shouldRefresh) {
      // navigate to the transactions list report with the current date range
      const query: GetTransactionsListReportQuery = {
        filter_type: JournalEntryType.LEASE_PAYMENT,
        filter_batch: batch
      };
      NavigationManager.runTransactionsListReport(query as Record<string, string>);
    }
  };

  function onSessionSubmitted() {
    analyticsService.track(SegmentEventTypes.BULK_RP_SUBMIT_BUTTON_CLICKED, null, { trackEventInIntercom: true });
    setViewIndex(BulkDialogView.BulkExecution);
  }

  const renderActionPanelButtons = (formikProps: FormikProps<BulkReceiveSessionDto>) => {
    const isReviewMode = viewIndex === BulkDialogView.Review;
    const shouldDisableReviewButton = Object.values(formikProps.values.payments).length === 0;
    return (
      <>
        <BulkPaymentFooter isReviewMode={isReviewMode} />
        <SeparationLine height={1} width={"100%"} />
        <FormActionButtons
          propsSubButton={
            viewIndex === BulkDialogView.SearchAndFill
              ? { onClick: () => onBackdropClick(formikProps), actionText: AppStrings.Common.Close }
              : { onClick: () => onBackClicked(formikProps), actionText: AppStrings.Common.Back }
          }
          propsMainButton={{
            type: "cta",
            props:
              viewIndex === BulkDialogView.Review
                ? { onClick: formikProps.submitForm, actionText: AppStrings.Common.Submit }
                : {
                    onClick: () => onReviewClicked(formikProps),
                    actionText: AppStrings.Common.Review
                  }
          }}
        />
      </>
    );
  };

  const viewMap: Record<Exclude<BulkDialogView, typeof BulkDialogView.BulkExecution>, React.ReactElement> = {
    [BulkDialogView.SearchAndFill]: <SearchAndFill />,
    [BulkDialogView.Review]: <Review onGoBackRequested={() => setViewIndex(BulkDialogView.SearchAndFill)} />
  } as const;

  function switchToSinglePaymentReceive(formikProps: FormikProps<BulkReceiveSessionDto>) {
    if (!confirmExit(formikProps)) return;
    analyticsService.track(SegmentEventTypes.BULK_RP_SWITCH_MODE_TO_SINGLE);
    setIsBulkMode(false);
  }

  const initialValues = new BulkReceiveSessionDto({
    payments: {},
    autoDeposit: true,
    sendPaymentReceipt: true,
    autoApplyPaymentOnCharges: true,
    date: moment().format("YYYY-MM-DD")
  });

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSessionSubmitted}
        validate={createValidator(BulkReceiveSessionDto)}
      >
        {(formikProps) =>
          viewIndex === BulkDialogView.BulkExecution ? (
            <BulkExecution onClose={onBulkExecutionFinished} />
          ) : (
            <DialogFrame
              disableEscapeExit
              onCloseButtonClick={() => onBackdropClick(formikProps)}
              title={AppStrings.Leases.LeaseTransaction.Payment.BulkReceivePayments}
              width={getDialogFrameDimension("width", 1340)}
              height={getDialogFrameDimension("height", 1580)}
              titleActions={
                <DialogTitleActionButton
                  text={AppStrings.Leases.LeaseTransaction.Payment.SwitchToSinglePaymentReceive}
                  onClick={() => switchToSinglePaymentReceive(formikProps)}
                />
              }
              renderView={({ index }) => viewMap[index]}
              numViews={Object.keys(BulkDialogView).length}
              activeView={viewIndex}
              RenderActionPanelButtons={() => renderActionPanelButtons(formikProps)}
              frameType={"sectionTitleFrame"}
              useStickyFooter
              helpPanel={helpObject}
            />
          )
        }
      </Formik>
    </MuiPickersUtilsProvider>
  );
};

export default BulkPayment;
