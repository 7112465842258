import { lazy } from "react";
import Routes from "./routes";
import { LazyRoute } from "./lazyRoute";
import { PageRouter } from "./pageRouter";

const CalendarScreen = lazy(() => import("screens/calendar/screens/calendar"));
const OccupancyOverviewScreen = lazy(() => import("screens/calendar/screens/occupancyOverview"));

export const CalendarRouter = () => (
  <PageRouter>
    <LazyRoute path={Routes.CALENDAR} render={CalendarScreen} exact />
    <LazyRoute path={Routes.CALENDAR_OCCUPANCY_OVERVIEW} render={OccupancyOverviewScreen} exact />
    <LazyRoute path={Routes.CALENDAR_GLOBAL_NEW} render={CalendarScreen} />
    <LazyRoute path={Routes.CALENDAR_OCCUPANCY_OVERVIEW_GLOBAL_NEW} render={OccupancyOverviewScreen} />
  </PageRouter>
);
