"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MerchantAccountStatus = void 0;
var MerchantAccountStatus;
(function (MerchantAccountStatus) {
    MerchantAccountStatus["SAVED"] = "SAVED";
    MerchantAccountStatus["SUBMITTED"] = "SUBMITTED";
    MerchantAccountStatus["ACTIVE"] = "ACTIVE";
    MerchantAccountStatus["DECLINED"] = "DECLINED";
    MerchantAccountStatus["CLOSED"] = "CLOSED"; // TODO: What is the difference between closed and declined?
})(MerchantAccountStatus || (exports.MerchantAccountStatus = MerchantAccountStatus = {}));
