import type { PropertyOwnerDto } from "@doorloop/dto";
import {
  createValidator,
  PropertyDto,
  PropertySettingsDto,
  RentReminderOverdueNotificationsFrequency,
  RentRemindersSettingsDto
} from "@doorloop/dto";
import { Grid } from "@material-ui/core";
import type { ApiResult } from "api/apiResult";
import { propertiesApi } from "api/propertiesApi";
import { usersApi } from "api/usersApi";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import { FormikSwitchButton, RadioGroup, Select, SwitchButton, TextField } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, Formik } from "formik";
import AppStrings from "locale/keys";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import FormikCachedMultiSelectAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedMultiSelectAutoComplete";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { trackRentReminderSettings } from "DLUI/dialogs/settings/common/analytics/trackRentReminderSettings";
import { AnimatedView } from "DLUI/view/animatedView";
import { useCashPayment } from "../../../../../hooks/useCashPayments";
import { PopoverHover } from "DLUI/popover/popoverHover";
import { useFeatureFlag } from "../../../../../hooks/useFeatureFlag";
import { SettingsLevels } from "screens/settings/rentReminders/getRentRemindersDynamicSettingLevelFieldsPath";
import { RentChargeNotifications } from "screens/settings/rentReminders/rentChargeNotificationsSection";

interface ComponentProps {
  didFinishOperation: (values: PropertyDto) => void;
  onBackdropClick: () => void;
  refreshEvent?: () => void;
  dialogTitle: string;
}

let formikGlobalRef: FormikProps<PropertyDto> | null = null;

export const getFormikRef = () => formikGlobalRef;

const validateForm = createValidator(PropertyDto);

const DialogFrameMinHeight = 820;
const DialogLineHeight = 80;

const EditRentReminders: React.FC<ComponentProps> = ({
  didFinishOperation,
  refreshEvent,
  onBackdropClick,
  dialogTitle
}: ComponentProps) => {
  const { t } = useTranslation();
  const { dialogHorizontalPadding } = DialogsHelper();
  const { propertyId } = useParams<any>();
  const [dialogFrameHeight, setDialogFrameHeight] = useState<number>(DialogFrameMinHeight);
  const [propertyData, setPropertyData] = useState<PropertyDto | undefined>();

  const [showDaysBeforeDueDateToPostRecurringCharges, setShowDaysBeforeDueDateToPostRecurringCharges] =
    useState<boolean>(false);

  const [showNotifyUsersByEmail, setShowNotifyUsersByEmail] = useState<boolean>(false);

  const [showNotifyUsersByEmailReversedPayment, setShowNotifyUsersByEmailReversedPayment] = useState<boolean>(false);

  const [viewIndex, setViewIndex] = useState(0);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);

  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>(AppStrings.Common.NetworkErrorSubTitle);

  const [hideLoadingDialogActionButtons, setHideLoadingDialogActionButtons] = useState<boolean>(false);

  const [rentRemindersSelectionType, setRentRemindersSelectionType] = useState<
    "companyDefault" | "specifyRentReminders"
  >("companyDefault");

  const loadPropertyData = async () => {
    if (propertyId) {
      const response = await propertiesApi.get(propertyId).catch((e: string) => {
        showDialogError(e);
      });
      if (response && response.status) {
        if (response.data && response.data.settings && response.data.settings.rentReminders) {
          const rentReminders = response.data.settings.rentReminders;
          if (rentReminders) {
            setRentRemindersSelectionType("specifyRentReminders");
            if (
              rentReminders.sendEmailToUsersWhenPaymentIsReturnedUsers &&
              rentReminders.sendEmailToUsersWhenPaymentIsReturnedUsers.length > 0
            ) {
              setShowNotifyUsersByEmailReversedPayment(true);
            }
            if (
              rentReminders.sendEmailToUsersWhenOnlinePaymentIsReceivedUsers &&
              rentReminders.sendEmailToUsersWhenOnlinePaymentIsReceivedUsers.length > 0
            ) {
              setShowNotifyUsersByEmail(true);
            }
            if (rentReminders.postRecurringChargesBeforeDueDate) {
              setShowDaysBeforeDueDateToPostRecurringCharges(true);
            }
          }
        }
        setPropertyData(response.data);
        setLoadingDialogState(DialogState.Success);
      } else {
        showDialogError();
      }
    } else {
      showDialogError();
    }
  };

  const showDialogError = (errorMessage?: string) => {
    setHideLoadingDialogActionButtons(true);
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(errorMessage || AppStrings.Common.GeneralError);
  };

  useEffect(() => {
    loadPropertyData();
    return () => {
      formikGlobalRef = null;
    };
  }, []);

  const initFormvalues = (): PropertyDto => {
    if (formikGlobalRef && formikGlobalRef.values) {
      return formikGlobalRef.values;
    }
    if (propertyData) {
      if (!propertyData.settings) {
        propertyData.settings = new PropertySettingsDto();
      }
      if (!propertyData.settings?.rentReminders) {
        propertyData.settings.rentReminders = new RentRemindersSettingsDto();
      }

      if (!propertyData?.settings?.rentReminders?.recurringNotificationsForOverdueRentFrequency) {
        propertyData.settings.rentReminders.recurringNotificationsForOverdueRentFrequency =
          RentReminderOverdueNotificationsFrequency.ONCE;
      }

      return propertyData;
    }
    return new PropertyDto();
  };

  const isValidForm = async (formikRef: FormikProps<PropertyDto>) => {
    formikRef.setFieldTouched("settings.rentReminders.daysBeforeToSendRentReminders");
    formikRef.setFieldTouched("settings.rentReminders.sendEmailToUsersWhenOnlinePaymentIsReceivedUsers");
    formikRef.setFieldTouched("settings.rentReminders.sendEmailToUsersWhenPaymentIsReturnedUsers");
    formikRef.setFieldTouched("settings.rentReminders.daysAfterDueDateToSendRecurringNotifications");
    formikRef.setFieldTouched("settings.rentReminders.recurringNotificationsForOverdueRentFrequency");

    const errors = (await formikRef.validateForm()) as any;

    return !errors?.settings?.rentReminders;
  };

  const updateProperty = async (values: PropertyDto) => {
    const response = (await propertiesApi.update(values.id!, values).catch((error) => {
      setLoadingDialogErrorText(error);
      setLoadingDialogState(DialogState.Error);
    })) as ApiResult<any>;

    if (response.status !== undefined && !response.status) {
      setLoadingDialogErrorText(response.message);
      setLoadingDialogState(DialogState.Error);
    } else {
      if (values.settings?.rentReminders) {
        trackRentReminderSettings("Property Settings", values.settings.rentReminders);
      }

      setLoadingDialogState(DialogState.Success);
      didFinishUpdateProperty();
      formikGlobalRef = null;
    }
  };

  const didPressSaveButton = async (owners?: PropertyOwnerDto[]) => {
    if (formikGlobalRef !== null && propertyData) {
      const isValid = await isValidForm(formikGlobalRef);
      if (isValid) {
        setLoadingDialogState(DialogState.Show);
        setViewIndex(1);
        await updateProperty(formikGlobalRef.values);
      }
    }
  };

  const renderActionPanelButtons = () => {
    if (viewIndex === 1) {
      return <div />;
    }

    return (
      <FormActionButtons
        propsSubButton={{ onClick: onBackdropClick }}
        propsMainButton={{ type: "cta", props: { onClick: async () => await didPressSaveButton() } }}
      />
    );
  };

  const didPressDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(0);
  };

  const onRetryButtonPress = () => {
    if (formikGlobalRef !== null) {
      setLoadingDialogState(DialogState.Hidden);
      setViewIndex(0);
    }
  };

  const didFinishUpdateProperty = () => {
    if (formikGlobalRef !== null) {
      didFinishOperation(formikGlobalRef.values);
      if (refreshEvent) {
        refreshEvent();
      }
      setLoadingDialogState(DialogState.Hidden);
    }
  };

  const didChangeRentRemindersSelection = (selectedValue: string) => {
    if (formikGlobalRef !== null) {
      if (selectedValue === "false") {
        formikGlobalRef.setFieldValue("settings.rentReminders", undefined);
        setShowNotifyUsersByEmail(false);
        setShowNotifyUsersByEmailReversedPayment(false);
      } else {
        formikGlobalRef.setFieldValue("settings.rentReminders", new RentRemindersSettingsDto());
      }
    }

    setTimeout(() => {
      setRentRemindersSelectionType(selectedValue === "false" ? "companyDefault" : "specifyRentReminders");
    }, 300);
  };

  const didChangeRentNotification = (nextSwitchState: boolean) => {
    !nextSwitchState &&
      formikGlobalRef?.setFieldValue("settings.rentReminders.daysBeforeToSendRentReminders", undefined);
  };

  const { isFeatureFlagActive } = useFeatureFlag("CashPayments");

  const { checkIfCashPaymentIsEnabled, isCashPaymentEnabled } = useCashPayment();

  useEffect(() => {
    checkIfCashPaymentIsEnabled("property", propertyId);
  }, [propertyId]);

  const renderRentRemindersNotifications = () => {
    const showDaysBeforeToSendRentReminders = Boolean(
      formikGlobalRef?.values?.settings?.rentReminders?.sendRentReminders
    );

    return (
      <View marginTop={16} flexDirection={"row"}>
        <Grid item container alignItems={"center"} xs={12} md={12} lg={12}>
          <Text fontSize={16} fontWeight={700} value={AppStrings.Common.RentReminders} />
          <View>
            <FastField
              component={FormikSwitchButton}
              name={"settings.rentReminders.sendRentReminders"}
              label={AppStrings.Common.RentChargeNotificationsDescription}
              onChange={didChangeRentNotification}
              marginTop={16}
            />
            <View showAnimation={"fade-in"} hideAnimation={"fade-out"} shouldShow={showDaysBeforeToSendRentReminders}>
              <Grid item xs={12} md={5} lg={5}>
                <FastField
                  component={TextField}
                  label={t(AppStrings.Common.DaysBeforeRentIsDue)}
                  name={"settings.rentReminders.daysBeforeToSendRentReminders"}
                  marginTop={16}
                  formatType={"number"}
                  decimalScale={1}
                />
              </Grid>
              <FastField
                component={FormikSwitchButton}
                name={"settings.rentReminders.doNotSendRentRemindersIfTenantPrepaid"}
                label={AppStrings.Common.RentChargeDoNotSendReminderIfTenantAlreadyPaid}
                marginTop={16}
              />
            </View>
          </View>
        </Grid>
      </View>
    );
  };

  const didChangePaymentReceivedNotifyUsersByEmail = (nextValue) => {
    const toggleOff = !nextValue;
    if (toggleOff && formikGlobalRef) {
      formikGlobalRef.setFieldValue(
        "settings.rentReminders.sendEmailToUsersWhenOnlinePaymentIsReceivedUsers",
        undefined
      );
    }

    setTimeout(() => {
      setShowNotifyUsersByEmail(nextValue);
    }, 300);
  };
  const didChangePaymentReversedNotifyUsersByEmail = (nextValue) => {
    const toggleOff = !nextValue;
    if (toggleOff && formikGlobalRef) {
      formikGlobalRef.setFieldValue("settings.rentReminders.sendEmailToUsersWhenPaymentIsReturnedUsers", undefined);
    }

    setTimeout(() => {
      setShowNotifyUsersByEmailReversedPayment(nextValue);
    }, 300);
  };

  const renderPaymentReceivedNotifications = () => (
    <View marginTop={16} flexDirection={"row"}>
      <Grid item container alignItems={"flex-start"} xs={12} md={12} lg={12}>
        <Text fontSize={16} fontWeight={700} value={AppStrings.Common.PaymentReceivedNotifications} />
        <View>
          <FastField
            component={FormikSwitchButton}
            name={"settings.rentReminders.sendPaymentReceiptsWhenOnlinePaymentIsReceived"}
            label={AppStrings.Common.PaymentReceivedNotifyByEmail}
            marginTop={16}
          />
          <SwitchButton
            onChange={didChangePaymentReceivedNotifyUsersByEmail}
            label={AppStrings.Common.PaymentReceivedNotifyUsersByEmail}
            marginTop={16}
            checked={showNotifyUsersByEmail}
            fullWidth
          />
        </View>
        <View showAnimation={"fade-in"} hideAnimation={"fade-out"} shouldShow={showNotifyUsersByEmail}>
          <FormikCachedMultiSelectAutoComplete
            uniqueIndex={"notifyUsersByEmail"}
            apiHandler={usersApi}
            displayNameKey={"name"}
            filterFieldName={"filter_text"}
            filterFieldValue={"name"}
            selectionFields={["id", "class"]}
            name={"settings.rentReminders.sendEmailToUsersWhenOnlinePaymentIsReceivedUsers"}
            label={t(AppStrings.Common.SelectUsers)}
            marginTop={16}
            errorLabelPaddingLeft={15}
          />
        </View>
      </Grid>
    </View>
  );

  const renderTextMessageNotifications = () => (
    <View marginTop={16} flexDirection={"row"}>
      <Grid item container alignItems={"flex-start"} xs={12} md={12} lg={12}>
        <Text fontSize={16} fontWeight={700} value={AppStrings.Common.TextMessages} />

        <View noWrap>
          <FastField
            component={FormikSwitchButton}
            name={"settings.rentReminders.doNotSentNotificationsAsTextMessages"}
            label={AppStrings.Common.DoNotSentNotificationsAsTextMessagesRentReminders}
            marginTop={16}
            flipValue
          />
          {isFeatureFlagActive &&
            (isCashPaymentEnabled ? (
              <FastField
                component={FormikSwitchButton}
                name={"settings.rentReminders.sendCashPaymentDetailsUponMoveIn"}
                label={AppStrings.Common.SendCashPaymentDetailsUponMoveIn}
                marginTop={16}
              />
            ) : (
              <PopoverHover
                tooltipProps={{
                  value: AppStrings.Common.CashPaymentMethodNotSelected,
                  containerStyle: {
                    marginTop: 16
                  }
                }}
              >
                <SwitchButton disabled checked={false} label={AppStrings.Common.SendCashPaymentDetailsUponMoveIn} />
              </PopoverHover>
            ))}
        </View>
      </Grid>
    </View>
  );

  const renderPaymentReversedNotifications = () => (
    <View marginTop={16} flexDirection={"row"}>
      <Grid item container alignItems={"flex-start"} xs={12} md={12} lg={12}>
        <Text fontSize={16} fontWeight={700} value={AppStrings.Common.PaymentReversedNotifications} />

        <View>
          <FastField
            component={FormikSwitchButton}
            name={"settings.rentReminders.sendEmailToTenantWhenPaymentIsReturned"}
            label={AppStrings.Common.PaymentReversedNotifyByEmail}
            marginTop={16}
          />
          <SwitchButton
            onChange={didChangePaymentReversedNotifyUsersByEmail}
            label={AppStrings.Common.PaymentReversedNotifyUsersByEmail}
            marginTop={16}
            checked={showNotifyUsersByEmailReversedPayment}
            fullWidth
          />
          <AnimatedView shouldShow={showNotifyUsersByEmailReversedPayment}>
            <FormikCachedMultiSelectAutoComplete
              uniqueIndex={"notifyUsersByEmail"}
              apiHandler={usersApi}
              displayNameKey={"name"}
              filterFieldName={"filter_text"}
              filterFieldValue={"name"}
              selectionFields={["id", "class"]}
              name={"settings.rentReminders.sendEmailToUsersWhenPaymentIsReturnedUsers"}
              label={t(AppStrings.Common.SelectUsers)}
              marginTop={16}
              errorLabelPaddingLeft={15}
            />
          </AnimatedView>
        </View>
      </Grid>
    </View>
  );

  const renderRentOverdueNotifications = () => (
    <View marginTop={16} flexDirection={"row"}>
      <Grid item container alignItems={"center"} xs={12} md={12} lg={12}>
        <Text fontSize={16} fontWeight={700} value={AppStrings.Common.RentOverdue} />
        <View>
          <FastField
            component={FormikSwitchButton}
            name={"settings.rentReminders.sendRecurringNotificationsForOverdueRent"}
            label={AppStrings.Common.RentOverdueSettingTitle}
            marginTop={16}
          />
        </View>
        <View
          showAnimation={"fade-in"}
          hideAnimation={"fade-out"}
          shouldShow={Boolean(
            formikGlobalRef?.values.settings?.rentReminders?.sendRecurringNotificationsForOverdueRent
          )}
        >
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} sm={6}>
              <Text value={AppStrings.Common.WhenShouldWeSendThisNotification} marginTop={16} fontSize={14} />
              <FastFieldSafe
                component={TextField}
                label={t(AppStrings.Common.DaysAfterRentIsDue)}
                name={"settings.rentReminders.daysAfterDueDateToSendRecurringNotifications"}
                marginTop={16}
                formatType={"number"}
                decimalScale={1}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text
                value={AppStrings.Common.HowOftenShouldWeSendReminders}
                fontSize={14}
                marginBottom={20}
                marginTop={16}
              />
              <FastFieldSafe
                uniqueKey={"recurringNotificationsForOverdueRentFrequency"}
                translationKey={"rentReminderOverdueNotificationsFrequency"}
                component={Select}
                name={"settings.rentReminders.recurringNotificationsForOverdueRentFrequency"}
                label={AppStrings.Leases.NewLease.LeaseRent.Frequency}
                selectionEnum={RentReminderOverdueNotificationsFrequency}
                required
              />
            </Grid>
          </Grid>
        </View>
      </Grid>
    </View>
  );

  const renderForm = () => {
    const formInitialValues = initFormvalues();
    return (
      <Formik initialValues={formInitialValues} onSubmit={(values, { setSubmitting }) => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;

          return (
            <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding}>
              <View alignItems={"flex-start"}>
                <Text
                  value={AppStrings.Common.RentRemindersDialogInstructions}
                  fontSize={16}
                  color={"black"}
                  maxWidth={550}
                  lineHeight={"26px"}
                  marginTop={16}
                />
                <FastField
                  uniqueKey={"LF"}
                  component={RadioGroup}
                  name={"automaticLateFees"}
                  defaultValue={rentRemindersSelectionType === "companyDefault" ? "false" : "true"}
                  didChangeSelection={didChangeRentRemindersSelection}
                  radioGroupItems={[
                    {
                      label: AppStrings.Common.UseCompanyDefaultRentReminders,
                      value: "false"
                    },
                    {
                      label: AppStrings.Common.SpecifyRentRemindersForProperty,
                      value: "true"
                    }
                  ]}
                  marginTop={10}
                />
                <View
                  shouldShow={rentRemindersSelectionType === "specifyRentReminders"}
                  showAnimation={"fade-in"}
                  hideAnimation={"fade-out"}
                >
                  <View>
                    <SeparationLine marginTop={16} width={"100%"} height={1} />
                    {renderRentRemindersNotifications()}
                    <SeparationLine marginTop={16} width={"100%"} height={1} />
                    {renderRentOverdueNotifications()}
                    <SeparationLine marginTop={10} width={"100%"} height={1} />
                    <RentChargeNotifications
                      showDaysBeforeDueDateToPostRecurringCharges={showDaysBeforeDueDateToPostRecurringCharges}
                      setShowDaysBeforeDueDateToPostRecurringCharges={setShowDaysBeforeDueDateToPostRecurringCharges}
                      isCashPaymentEnabled={Boolean(isCashPaymentEnabled)}
                      settingsLevel={SettingsLevels.Property}
                    />
                    <SeparationLine marginTop={16} width={"100%"} height={1} />
                    {renderPaymentReceivedNotifications()}
                    <SeparationLine marginTop={16} width={"100%"} height={1} />
                    {renderPaymentReversedNotifications()}
                    <SeparationLine marginTop={16} width={"100%"} height={1} />
                    {renderTextMessageNotifications()}
                    <View height={20} />
                  </View>
                </View>
              </View>
            </View>
          );
        }}
      </Formik>
    );
  };

  const renderView = ({ index }: any) => {
    if (index === 0 && propertyData) {
      return renderForm();
    }
    if (index === 1 || !propertyData) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={t(AppStrings.Common.Loading)}
            errorText={loadingDialogErrorText}
            successText={t(AppStrings.Common.OperationCompleted)}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
            hideButtons={hideLoadingDialogActionButtons}
          />
        </View>
      );
    }
    return <div />;
  };

  const _onBackdropClick = () => {
    if (viewIndex === 1) {
      setViewIndex(0);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const frameType = useMemo(() => {
    if (propertyData === undefined) {
      return "contentOnly";
    }

    if (viewIndex === 0) {
      return "sectionTitleFrame";
    }
    if (viewIndex === 1) {
      return "contentOnly";
    }

    return "topPanel";
  }, [viewIndex, propertyData]);

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={dialogTitle}
      width={750}
      height={dialogFrameHeight}
      renderView={renderView}
      numViews={2}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={frameType}
    />
  );
};

export default EditRentReminders;
