import type { FC } from "react";
import React from "react";
import { View } from "DLUI/view";

import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface Props {
  InstructionsView: FC;
}

const SyncInstructions: React.FC<Props> = ({ InstructionsView }: Props) => {
  const { isMobile } = useResponsiveHelper();
  return (
    <View flexDirection={"row"} alignItems={"center"} justifyContent={isMobile ? "center" : "flex-end"}>
      <InstructionsView />
    </View>
  );
};

export default SyncInstructions;
