import type { RecurringTransactionDto } from "@doorloop/dto";
import { RecurringTransactionType } from "@doorloop/dto";
import type { LeaseTransactionLineBaseDto } from "@doorloop/dto";
import AppStrings from "locale/keys";

interface MetaData {
  typeText: string;
  accountLines?: LeaseTransactionLineBaseDto[] | null;
  frequency: string;
}

type MetaDataMap = Record<RecurringTransactionType, (dto: RecurringTransactionDto) => MetaData>;

const getFrequency = (frequencyKey?: string): string => (frequencyKey ? AppStrings.Common[frequencyKey] : "");

const metaDataMap: MetaDataMap = {
  [RecurringTransactionType.GENERAL_ENTRY]: (dto: RecurringTransactionDto) => {
    return {
      typeText: AppStrings.OtherTransactions.GeneralEntry.GeneralEntryTitle,
      accountLines: dto.generalEntryInfo?.lines,
      frequency: getFrequency(dto.frequency)
    };
  },
  [RecurringTransactionType.LEASE_CHARGE]: (dto: RecurringTransactionDto) => {
    return {
      typeText: AppStrings.Leases.LeaseDetails.LeaseCharge,
      accountLines: dto.leaseChargeInfo?.lines,
      frequency: getFrequency(dto.frequency)
    };
  },
  [RecurringTransactionType.LEASE_CREDIT]: (dto: RecurringTransactionDto) => {
    return {
      typeText: AppStrings.Leases.LeaseDetails.LeaseCredit,
      accountLines: dto.leaseCreditInfo?.lines,
      frequency: getFrequency(dto.frequency)
    };
  },
  [RecurringTransactionType.LEASE_PAYMENT]: (dto: RecurringTransactionDto) => {
    return {
      typeText: AppStrings.Leases.LeaseDetails.LeasePayment,
      accountLines: null,
      frequency: getFrequency(dto.frequency)
    };
  },
  [RecurringTransactionType.LEASE_RENT]: (dto: RecurringTransactionDto) => {
    return {
      typeText: AppStrings.Leases.NewLease.LeaseRent.RecurringRent,
      accountLines: dto.leaseRentInfo?.lines,
      frequency: getFrequency(dto.frequency)
    };
  },
  [RecurringTransactionType.VENDOR_BILL]: (dto: RecurringTransactionDto) => {
    return {
      typeText: AppStrings.Leases.LeaseDetails.VedorBill,
      accountLines: dto.leaseChargeInfo?.lines,
      frequency: getFrequency(dto.frequency)
    };
  },
  [RecurringTransactionType.TASK]: (dto: RecurringTransactionDto) => {
    return {
      typeText: AppStrings.Tasks.TasksList.InternalTask,
      accountLines: null,
      frequency: getFrequency(dto.frequency)
    };
  },
  [RecurringTransactionType.EXPENSE]: (dto: RecurringTransactionDto) => {
    return {
      typeText: AppStrings.Common.Enums.JournalEntryType.Expense,
      accountLines: dto.expenseInfo?.lines,
      frequency: getFrequency(dto.frequency)
    };
  }
};
export const getMetaDataByTransactionType = (dto: RecurringTransactionDto): MetaData => metaDataMap[dto.type!](dto);
