import {
  ArchiveIcon,
  CheckBlueIcon,
  CheckmarkCircleIcon,
  ClockGrayIcon,
  ClockOrangeIcon,
  ExclamationMarkIconPink
} from "assets/icons";
import type { IconTextAndColor } from "DLUI/text/text";
import AppStrings from "locale/keys";
import { TaskStatus } from "@doorloop/dto";

const statusesMap: Record<TaskStatus, IconTextAndColor> = {
  [TaskStatus.NOT_STARTED]: {
    StatusIcon: ClockGrayIcon,
    statusTextColor: "gray",
    statusText: AppStrings.Common.Enums.TaskStatus.NOT_STARTED
  },
  [TaskStatus.IN_PROGRESS]: {
    StatusIcon: ClockOrangeIcon,
    statusTextColor: "orange",
    statusText: AppStrings.Common.Enums.TaskStatus.IN_PROGRESS
  },
  [TaskStatus.COMPLETED]: {
    StatusIcon: CheckmarkCircleIcon,
    statusTextColor: "green",
    statusText: AppStrings.Common.Enums.TaskStatus.COMPLETED
  },
  [TaskStatus.RECEIVED]: {
    StatusIcon: CheckBlueIcon,
    statusTextColor: "black",
    statusText: AppStrings.Common.Enums.TaskStatus.RECEIVED
  },
  [TaskStatus.ARCHIVED]: {
    StatusIcon: ArchiveIcon,
    statusTextColor: "gray",
    statusText: AppStrings.Common.Enums.TaskStatus.ARCHIVED
  }
};

export const getTaskStatusUI = (status: TaskStatus = TaskStatus.NOT_STARTED) => statusesMap[status];

export const getOverdueTaskUI = (): IconTextAndColor => {
  return {
    StatusIcon: ExclamationMarkIconPink,
    statusTextColor: "pink",
    statusText: AppStrings.Dashboards.overdue
  };
};
