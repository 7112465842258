import type { OwnersApi } from "api/ownersApi";
import { ownersApi } from "api/ownersApi";
import type { TenantsApi } from "api/tenantsApi";
import { tenantsApi } from "api/tenantsApi";
import { AlertDialog, AnimatedContentDialog, ConfirmDialog, Dialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import AppStrings from "locale/keys";
import React, { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { AccountDialog } from "screens/accounts";
import {
  initFormValues as ownerInitFormValues,
  validateForm as ownerValidateForm,
  validateOwnerForm
} from "screens/owners/newOwner/formikHelper";
import { getOwnerFormMenuItems } from "screens/owners/newOwner/menuItems";
import { ProspectDialog } from "screens/prospects";
import { CompanyInformationDialog, PersonalInformationDialog } from "screens/settings";
import TenantPortalDialog from "screens/settings/tenantPortal/tenantPortalDialog";
import {
  getTenantFormMenuItems,
  initFormValues as tenantInitFormValues,
  validateForm,
  validateTenantForm
} from "screens/tenants";
import { UserDialog } from "screens/users";
import { VendorDialog } from "screens/vendors";
import { history } from "store/history";
import Routes from "../../../appRouter/routes";
import { accountsDialogsProvider } from "./accountsDialogsProvider";
import { DialogRoutes } from "./dialogsRoutes";
import { leasesDialogsProvider } from "./leasesDialogsProvider";
import { otherTransactionsDialogsProvider } from "./otherTransactionsDialogsProvider";
import { ownersDialogsProvider } from "./ownersDialogsProvider";
import { propertiesDialogsProvider } from "./propertiesDialogsProvider";
import { prospectsDialogsProvider } from "./prospectsDialogsProvider";
import { settingsDialogsProvider } from "./settingsDialogsProvider";
import { tasksDialogsProvider } from "./tasksDialogsProvider";
import { tenantsDialogsProvider } from "./tenantsDialogsProvider";
import type { DialogTypeOptions } from "./dialogsProvider.types";
import { unitsDialogsProvider } from "./unitsDialogsProvider";
import { usersDialogsProvider } from "./usersDialogsProvider";
import { vendorsDialogsProvider } from "./vendorsDialogsProvider";
import qs from "query-string";
import { userRolesDialogsProvider } from "DLUI/screen/dialogsProvider/userRolesDialogsProvider";
import { UserRoleDialog } from "screens/userRoles/newUserRole/userRoleDialog";
import { ObjectPermission } from "@doorloop/dto";

import RentalApplicationsSettingsDialog from "screens/settings/rentalApplications/rentalApplicationsSettingsDialog";
import { rentalApplicationsDialogsProvider } from "./rentalApplicationsDialogsProvider";
import { printChecksDilogsProvider } from "./printChecksDialogsProvider";
import { PrintSetupDialog } from "screens/printChecks/dialogs/printSetup/printSetupDialog";
import { announcementsDialogsProvider } from "DLUI/screen/dialogsProvider/announcementsDialogsProvider";
import EditPropertyDialog from "components/DLUI/dialogs/settings/properties/editProperty/editPropertyDialog";
import { signatureRequestsDialogsProvider } from "DLUI/screen/dialogsProvider/signatureRequestDialogsProvider";
import EditUnitDialog from "DLUI/dialogs/components/editUnit/editUnitDialog";
import { signatureTemplatesDialogsProvider } from "DLUI/screen/dialogsProvider/signatureTemplatesDialogsProvider";
import { reportsDialogsProvider } from "DLUI/screen/dialogsProvider/reportsDialogsProvider";
import { taxFormDialogsProvider } from "./taxFormDialogsProvider";
import { OwnerPortalDialog } from "screens/settings/ownerPortal/ownerPortalDialog";
import { useUserType } from "../../../../hooks/useUserType";
import { filesDialogsProvider } from "DLUI/screen/dialogsProvider/filesDialogProvider";
import { useBasePersonValidation } from "screens/vendors/useBasePersonValidation";
import { CommunicationCenterDialog } from "screens/settings/communicationCenter/cummunicationCenterDialog";
import type { DialogProps } from "./dialogsProvider.types";
import { isDuplicateMode } from "DLUI/dialogs/duplicateModeHelper";
import { useConfirmationDialog } from "@/hooks/useConfirmationDialog";

interface ComponentProps {
  children: React.ReactNode;
  onRefreshEvent?: () => void;
}
const DUPLICATE_MODE_BACK_NAVIGATION_STEPS = -2;

const providers = [
  printChecksDilogsProvider,
  unitsDialogsProvider,
  tenantsDialogsProvider,
  ownersDialogsProvider,
  vendorsDialogsProvider,
  leasesDialogsProvider,
  propertiesDialogsProvider,
  reportsDialogsProvider,
  settingsDialogsProvider,
  tasksDialogsProvider,
  prospectsDialogsProvider,
  otherTransactionsDialogsProvider,
  accountsDialogsProvider,
  usersDialogsProvider,
  userRolesDialogsProvider,
  rentalApplicationsDialogsProvider,
  announcementsDialogsProvider,
  signatureRequestsDialogsProvider,
  signatureTemplatesDialogsProvider,
  taxFormDialogsProvider,
  filesDialogsProvider
];

const DIALOG_STATE = DialogState.Show;

const DialogsProvider: React.FC<ComponentProps> = ({ children, onRefreshEvent }: ComponentProps) => {
  const { t } = useTranslation();
  const location = useLocation<any>();
  const [renderContent, setRenderContent] = useState<boolean>(true);
  const { isHOAUser } = useUserType();
  const { personValidateBase } = useBasePersonValidation();
  const { confirmationData } = useConfirmationDialog();

  const dialogProps: DialogProps | undefined = useMemo(() => {
    for (const index in providers) {
      const matchResults = providers[index].matchRoutes(location.pathname);
      if (matchResults) {
        return matchResults;
      }
    }

    if (location.pathname.indexOf(DialogRoutes.CONFIRM) > -1 && confirmationData) {
      return {
        type: "confirm",
        Content: ConfirmDialog
      };
    }

    if (location.pathname.indexOf(DialogRoutes.ALERT) > -1 && location.state && location.state.alertData) {
      return {
        type: "alert",
        Content: AlertDialog
      };
    }
    return undefined;
  }, [location.pathname]);

  const tenantsApiMethod = useMemo(() => {
    if (location.pathname.indexOf(Routes.DRAFT_LEASES) > -1) {
      return tenantsApi;
    }
    return tenantsApi;
  }, []);

  const didCloseSelectionModal = () => {
    if (isDuplicateMode()) {
      history.go(DUPLICATE_MODE_BACK_NAVIGATION_STEPS);
    } else {
      history.goBack();
    }
  };
  const onBackdropClick = () => {
    didCloseSelectionModal();
  };

  const refreshAndHide = () => {
    const queryString = qs.parse(window.location.search);
    const returnPathExists = queryString["dialogReturnPath"] !== undefined && queryString["dialogReturnPath"] !== null;
    let nextPathName = "";

    if (returnPathExists) {
      nextPathName = queryString["dialogReturnPath"]!.toString();
      delete queryString["dialogReturnPath"];
    }

    if (onRefreshEvent) {
      didCloseSelectionModal();
      setTimeout(() => {
        onRefreshEvent();
        if (returnPathExists) {
          history.push({
            pathname: nextPathName,
            search: qs.stringify(queryString)
          });
        }
      }, 300);
    } else {
      setRenderContent(false);
      didCloseSelectionModal();
      setTimeout(() => {
        if (returnPathExists) {
          history.push({
            pathname: nextPathName,
            search: qs.stringify(queryString)
          });
        }
        setRenderContent(true);
      }, 0);
    }
  };

  const renderDialog = () => {
    if (!dialogProps) {
      return null;
    }
    const commonProps = {
      dialogState: DIALOG_STATE,
      onBackdropClick,
      onClose: refreshAndHide,
      permission: dialogProps.permission,
      title: dialogProps.title
    };

    const dialogMap: {
      [key in DialogTypeOptions]?: () => React.ReactNode;
    } = {
      newProspect: () => <ProspectDialog {...commonProps} />,
      editPersonalInformation: () => <PersonalInformationDialog {...commonProps} />,
      editCompanyInformation: () => <CompanyInformationDialog {...commonProps} />,
      editTenantPortal: () => <TenantPortalDialog {...commonProps} />,
      editOwnerPortal: () => <OwnerPortalDialog {...commonProps} />,
      newUser: () => <UserDialog {...commonProps} />,
      printSetup: () => <PrintSetupDialog {...commonProps} />,
      newAccount: () => <AccountDialog {...commonProps} />,
      newUserRole: () => <UserRoleDialog {...commonProps} />,
      editRentalApplications: () => <RentalApplicationsSettingsDialog {...commonProps} />,
      editCommunicationCenter: () => <CommunicationCenterDialog {...commonProps} />
    };
    dialogMap.editProperty = () => <EditPropertyDialog {...commonProps} />;
    dialogMap.newUnit = () => <EditUnitDialog {...commonProps} />;
    const targetComponent = dialogProps.type && dialogMap[dialogProps.type];

    if (targetComponent) {
      return targetComponent();
    }

    if (dialogProps.type === "newVendor") {
      return (
        <VendorDialog
          dialogState={DIALOG_STATE}
          onClose={refreshAndHide}
          onBackdropClick={onBackdropClick}
          title={dialogProps.title}
        />
      );
    }

    if (dialogProps.type === "newTenant" || dialogProps.type === "newOwner" || dialogProps.type === "editOwner") {
      let dialogItems = getTenantFormMenuItems(false, isHOAUser);
      let loadingText = t(AppStrings.Tenants.NewTenant.LoadingText);
      let errorText = t(AppStrings.Tenants.NewTenant.ErrorText);
      let successText = t(AppStrings.Tenants.NewTenant.SuccessText);
      let apiMethod: OwnersApi | TenantsApi = tenantsApiMethod;
      let dialogHeight: number | undefined;
      let validationMethod = validateTenantForm;
      let _validateForm: any = validateForm;
      let _initFormValues: any = tenantInitFormValues;
      let permission: ObjectPermission | undefined;
      if (dialogProps.type === "newOwner" || dialogProps.type === "editOwner") {
        dialogItems = getOwnerFormMenuItems();
        loadingText = t(AppStrings.Owners.NewOwner.LoadingText);
        errorText = t(AppStrings.Owners.NewOwner.ErrorText);
        successText = t(AppStrings.Owners.NewOwner.SuccessText);
        apiMethod = ownersApi;
        validationMethod = validateOwnerForm;
        _validateForm = ownerValidateForm;
        _initFormValues = ownerInitFormValues;
        permission = ObjectPermission.owners;
      }
      if (dialogProps.type === "editOwner") {
        permission = ObjectPermission.owners;
      }
      if (dialogProps.type === "newTenant") {
        permission = ObjectPermission.tenants;
        dialogHeight = 840;
      }

      return (
        <AnimatedContentDialog
          dialogState={DIALOG_STATE}
          onClose={refreshAndHide}
          sectionItems={dialogItems}
          disableBackdropClick={true}
          onBackdropClick={onBackdropClick}
          getformikInitialValues={_initFormValues}
          formikValidation={_validateForm}
          validationMethod={async (formik) => await personValidateBase(formik, validationMethod, dialogItems)}
          loadingText={loadingText}
          errorText={errorText}
          successText={successText}
          apiMethod={apiMethod}
          refreshEvent={onRefreshEvent}
          dialogTitle={dialogProps.title || AppStrings.Tenants.Screen.AddNewTenant}
          dialogHeight={dialogHeight}
          permission={permission}
        />
      );
    }

    return (
      <Dialog
        dialogState={DIALOG_STATE}
        onClose={refreshAndHide}
        Content={dialogProps.Content}
        onBackdropClick={onBackdropClick}
        refreshEvent={onRefreshEvent}
        showCloseIcon
        dialogTitle={dialogProps.title}
        permission={dialogProps.permission}
      />
    );
  };

  return (
    <Fragment>
      {renderContent ? children : null}
      <div id="dialogContainer">{renderDialog()}</div>
    </Fragment>
  );
};

export default DialogsProvider;
