"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAllLeaseRefundsQuery = void 0;
const getAllLeasePayments_query_1 = require("../leasePayments/getAllLeasePayments.query");
class GetAllLeaseRefundsQuery extends getAllLeasePayments_query_1.GetAllLeasePaymentsQuery {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.GetAllLeaseRefundsQuery = GetAllLeaseRefundsQuery;
