import type { ImageButtonProps } from "DLUI/form";
import { ImageButtonGroup } from "DLUI/form";
import type { FC } from "react";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getIn, useFormikContext } from "formik";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { AnimatedView } from "DLUI/view/animatedView";
import _ from "lodash";
import FormikCachedMultiSelectAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedMultiSelectAutoComplete";

interface Props {
  header: string;
  selectLabel: string;
  formikName: string;
  buttons: ImageButtonProps[];
  api: any;
  queryParams?: Record<string, string>;
  onListChange?: (event: object, value: any) => void;
  onSelectChange?: (value: any) => void;
  pageSize?: number;
}

export const BoxOption: FC<Props> = ({
  header,
  selectLabel,
  formikName,
  buttons,
  api,
  queryParams = {},
  pageSize,
  onListChange,
  onSelectChange
}: Props) => {
  const { t } = useTranslation();
  const formikRef = useFormikContext();
  const [showSecondStep, setSecondStep] = useState<boolean>(!_.isEmpty(getIn(formikRef.values, formikName)));
  const buttonsLocal = useMemo(() => [...buttons], []);
  buttonsLocal[showSecondStep ? 1 : 0].isSelected = true;

  const handleSelectChange = useCallback((label, value) => {
    onSelectChange?.(value);

    const isSecondStep = value === 1;

    setSecondStep(isSecondStep);
  }, []);

  return (
    <View marginBottom={20} alignItems={"center"}>
      <Text value={header} fontSize={16} color={"black"} marginBottom={20} />
      <ImageButtonGroup
        uniqueId={formikName + "imageButton"}
        buttonItemsArray={buttonsLocal}
        size={135}
        onSelect={handleSelectChange}
      />
      <AnimatedView shouldShow={showSecondStep}>
        <View justifyContent={"center"} alignItems={"center"} marginBottom={20}>
          {showSecondStep && (
            <FormikCachedMultiSelectAutoComplete
              uniqueIndex={formikName}
              apiHandler={api}
              displayNameKey={"name"}
              filterFieldName={"filter_text"}
              filterFieldValue={"name"}
              selectionFields={["id", "class"]}
              name={formikName}
              // defaultValue={formikRef?.values?.[formikName]}
              label={t(selectLabel)}
              marginTop={20}
              errorLabelPaddingLeft={15}
              onChange={onListChange}
              maxWidth={450}
              queryParams={queryParams}
              pageSize={pageSize}
            />
          )}
        </View>
      </AnimatedView>
    </View>
  );
};
