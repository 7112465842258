import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    editorScroller: {
      height: 180,
      border: 0,
      display: "flex",
      position: "relative",
      outline: 0,
      zIndex: 0,
      overflow: "auto",
      resize: "vertical"
    },
    editorContainer: {
      height: "100%",
      width: "100%",
      border: 0,
      outline: 0,
      flex: "auto",
      position: "relative",
      resize: "vertical",
      zIndex: -1
    },
    editor: {
      height: "100%",
      width: "100%",
      borderWidth: 0,
      outline: 0
    }
  })
);
