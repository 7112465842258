import { memo } from "react";
import clsx from "clsx";

import { NewCheckboxMarkIcon } from "assets/icons";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { useStyles } from "./styles";
import type { CheckboxColor, CheckboxSize } from "./types";

export interface CheckboxProps extends Omit<CheckboxPrimitive.CheckboxProps, "children" | "onChange"> {
  size?: CheckboxSize;
  color?: CheckboxColor;
}

export const Checkbox = memo(({ size = "small", color = "blue", className, ...checkboxProps }: CheckboxProps) => {
  const classes = useStyles();
  const classNames = clsx(
    classes.checkboxRoot,
    classes.sizeVariants,
    classes.colorVariants,
    size,
    color,
    "button-reset",
    className
  );

  return (
    <div className={clsx(classes.container)}>
      <CheckboxPrimitive.Root className={classNames} {...checkboxProps}>
        <CheckboxPrimitive.Indicator asChild>
          {checkboxProps.checked === "indeterminate" ? (
            <span className={clsx(classes.indeterminateShape, size)} />
          ) : (
            <NewCheckboxMarkIcon />
          )}
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
    </div>
  );
});
Checkbox.displayName = "Checkbox";
