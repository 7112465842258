import React, { useMemo } from "react";
import { View } from "DLUI/view";
import { PlaidAccountListItemHeight } from "screens/transactions/components/bankConnect/dialogs/bankConnect/plaidAccountListItem";
import { Icon } from "DLUI/icon";
import { AddCirclePinkIcon } from "@/assets";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import type { PlaidAccountDto } from "@doorloop/dto";
import _ from "lodash";
import { PlaidAccountListAccordion } from "screens/transactions/components/bankConnect/dialogs/bankConnect/plaidAccountListAccordion";

interface ComponentProps {
  accounts?: PlaidAccountDto[];
  onAccountSelection: (accountId: string) => void;
  didPressConnectAnotherAccount?: () => void;
  showAddAccountButton?: boolean;
}

const PlaidAccountsList: React.FC<ComponentProps> = ({
  accounts,
  onAccountSelection,
  didPressConnectAnotherAccount,
  showAddAccountButton
}: ComponentProps) => {
  const renderNewAccountRow = () => {
    const _didPressConnectAnotherAccount = () => {
      if (didPressConnectAnotherAccount) {
        didPressConnectAnotherAccount();
      }
    };
    return (
      showAddAccountButton && (
        <View marginTop={20} paddingLeft={20} paddingRight={20}>
          <View
            alignItems={"center"}
            height={PlaidAccountListItemHeight}
            flexDirection={"row"}
            backgroundColor={"white"}
            borderRadius={10}
            onClick={_didPressConnectAnotherAccount}
            gap={15}
          >
            <Icon
              width={30}
              height={30}
              marginLeft={20}
              marginRight={20}
              Source={AddCirclePinkIcon}
              circleFill={"light-blue"}
            />

            <SeparationLine height={"50%"} width={1} />
            <View width={"auto"} minWidth={280}>
              <Text value={AppStrings.Common.ConnectAnotherAccount} fontSize={16} bold />
            </View>
          </View>
        </View>
      )
    );
  };

  const accountsListItems = useMemo(
    () =>
      _.map(_.groupBy(accounts, "institution_name"), (institutionAccounts, institutionName) => (
        <View paddingLeft={20}>
          <PlaidAccountListAccordion
            institutionAccounts={institutionAccounts}
            institutionName={institutionName}
            onAccountSelection={onAccountSelection}
          />
        </View>
      )),
    [accounts, onAccountSelection]
  );

  return (
    <View style={{ gap: "12px" }}>
      {renderNewAccountRow()}
      {accountsListItems}
    </View>
  );
};

export default PlaidAccountsList;
