import { DeleteIcon } from "assets/icons";
import { IconButton } from "DLUI/form";
import { Link } from "DLUI/link";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import React, { useMemo } from "react";
import type { ViewComponentProps } from "DLUI/view/view";
import { DataCy } from "@doorloop/dto";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import type { DLButtonProps } from "DLUI/button/dlButton";
import { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import DLButton from "DLUI/button/dlButton/dlButton";
import _ from "lodash";

export interface ActionPanelProps {
  children: React.ReactNode;
  marginTop?: number;
  paddingRight?: number;
  marginBottom?: number;
  isDeleteButtonLoading?: boolean;
  marginLeft?: number;
  customButtons?: DLButtonProps[];
  onDeleteButtonPress?: () => void;
  editMode?: boolean;
  customDeleteText?: string;
  noWrap?: ViewComponentProps["noWrap"];
  dataCy?: string;
}

const ActionPanel: React.FC<ActionPanelProps> = ({
  children,
  marginTop,
  customDeleteText,
  customButtons = [],
  isDeleteButtonLoading,
  paddingRight,
  marginLeft = 5,
  onDeleteButtonPress,
  editMode,
  noWrap
}) => {
  const { isMobile } = useResponsiveHelper();
  const renderDeleteButton = () => {
    if (isMobile) {
      return null;
    }
    if (Boolean(editMode) && onDeleteButtonPress) {
      return (
        <View
          cursor={"pointer"}
          marginLeft={marginLeft}
          flexDirection={"row"}
          width={"auto"}
          height={"100%"}
          alignItems={"center"}
          dataCy={DataCy.dialogActionButtons.delete}
        >
          <IconButton
            Icon={DeleteIcon}
            loadingInProgress={isDeleteButtonLoading}
            onClick={onDeleteButtonPress}
            width={25}
            height={25}
          />
          <Link hoverColor={"error"} underline={"always"} onClick={onDeleteButtonPress}>
            <Text color={"error"} value={customDeleteText ?? AppStrings.Common.Delete} />
          </Link>
        </View>
      );
    }
  };

  const _paddingRight = useMemo(() => {
    let nextValue = isMobile ? 0 : 20;
    if (paddingRight !== undefined) {
      nextValue = paddingRight;
    }
    return nextValue;
  }, []);

  return (
    <View alignItems={"center"} flexDirection={"row"} marginTop={marginTop} paddingBottom={12} paddingTop={12}>
      {renderDeleteButton()}
      {!_.isEmpty(customButtons) && (
        <View flexDirection="row" alignItems="center" justifyContent="center" gap={8} autoWidth>
          {customButtons.map(({ style, ...rest }, index) => (
            <View key={index} flexDirection="row" alignItems="center" justifyContent="center" marginLeft={20}>
              <View height={30} width={1} backgroundColor="secondary-gray" />
              <DLButton
                variant={DLButtonVariantsEnum.TEXT}
                color={DLButtonColorsEnum.NEUTRAL}
                size={DLButtonSizesEnum.LARGE}
                style={{
                  minWidth: 40,
                  marginLeft: 10,
                  ...style
                }}
                {...rest}
              />
            </View>
          ))}
        </View>
      )}
      <View flex={1}>
        <View
          paddingRight={_paddingRight}
          justifyContent={isMobile ? "space-around" : "flex-end"}
          alignItems={"flex-end"}
          flexDirection={"row"}
          noWrap={noWrap}
        >
          {children}
        </View>
      </View>
    </View>
  );
};

export default ActionPanel;
