import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";
import { lazy } from "react";

const UserRolesListScreen = lazy(() => import("screens/userRoles/userRolesList/userRolesListScreen"));

const UserRolesRouter = () => (
  <PageRouter>
    <LazyRoute exact path={Routes.NEW_USER_ROLE} render={UserRolesListScreen} />
    <LazyRoute exact path={Routes.EDIT_USER_ROLE} render={UserRolesListScreen} />
    <LazyRoute path={Routes.USER_ROLES} render={UserRolesListScreen} />
  </PageRouter>
);

export default UserRolesRouter;
