import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";
import { lazy } from "react";

const IncomingPaymentsScreen = lazy(() => import("screens/incomingPayments/incomingPaymentsScreen"));
const NewMerchantAccountScreen = lazy(() => import("screens/incomingPayments/newMerchantAccountScreen"));

export const IncomingPaymentsRouter = () => (
  <PageRouter>
    <LazyRoute path={Routes.INCOMING_PAYMENTS_NEW_MERCHANT_ACCOUNT} render={NewMerchantAccountScreen} />
    <LazyRoute
      exact
      path={[
        Routes.INCOMING_PAYMENTS_STRIPE_ACTION_REQUIRED_BANK_ACCOUNT,
        Routes.INCOMING_PAYMENTS_ACCOUNTS_VERIFY_IDENTITY,
        Routes.INCOMING_PAYMENTS_STRIPE_MERCHANT_ACCOUNT,
        Routes.INCOMING_PAYMENTS_STRIPE_ACTION_REQUIRED_EIN,
        Routes.INCOMING_PAYMENTS_STRIPE_ACTION_REQUIRED_OTHER,
        Routes.INCOMING_PAYMENTS_ACCOUNTS_UPDATE_INFO,
        Routes.INCOMING_PAYMENTS_MERCHANT_ACCOUNT_OVERVIEW_TRANSACTIONS_EDIT_PAYMENTS,
        Routes.INCOMING_PAYMENTS_MERCHANT_ACCOUNT_OVERVIEW_TRANSACTIONS_EDIT_BANK_DEPOSIT,
        Routes.INCOMING_PAYMENTS_MERCHANT_ACCOUNT_OVERVIEW_DEPOSITS_EDIT_BANK_DEPOSIT,
        Routes.INCOMING_PAYMENTS_NEW_ACCOUNT
      ]}
      render={IncomingPaymentsScreen}
    />
    <LazyRoute
      path={[
        Routes.INCOMING_PAYMENTS,
        Routes.INCOMING_PAYMENTS_GLOBAL_NEW,
        Routes.INCOMING_PAYMENTS_OUTGOING_PAYMENTS_SETTINGS,
        Routes.INCOMING_PAYMENTS_MERCHANT_ACCOUNT_OVERVIEW_BALANCE_SUMMARY,
        Routes.INCOMING_PAYMENTS_MERCHANT_ACCOUNT_OVERVIEW_GENERAL_INFO,
        Routes.INCOMING_PAYMENTS_MERCHANT_ACCOUNT_OVERVIEW_TRANSACTIONS,
        Routes.INCOMING_PAYMENTS_MERCHANT_ACCOUNT_OVERVIEW_DEPOSITS
      ]}
      render={IncomingPaymentsScreen}
    />
  </PageRouter>
);
