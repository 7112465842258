"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseRenewalOfferDto = void 0;
const base_dto_1 = require("../base.dto");
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const leaseRenewalOfferStatus_enum_1 = require("./leaseRenewalOfferStatus.enum");
const leaseRenewalOfferTerm_dto_1 = require("./leaseRenewalOfferTerm.dto");
class LeaseRenewalOfferDto extends base_dto_1.BaseDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.LeaseRenewalOfferDto = LeaseRenewalOfferDto;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(leaseRenewalOfferStatus_enum_1.LeaseRenewalOfferStatus),
    __metadata("design:type", String)
], LeaseRenewalOfferDto.prototype, "status", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], LeaseRenewalOfferDto.prototype, "lease", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], LeaseRenewalOfferDto.prototype, "notifyTenants", void 0);
__decorate([
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ArrayMinSize)(1),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => leaseRenewalOfferTerm_dto_1.LeaseRenewalOfferTermDto),
    __metadata("design:type", Array)
], LeaseRenewalOfferDto.prototype, "proposedTerms", void 0);
