import AppStrings from "locale/keys";
import type { StaticRemoteOrDateFilter } from "DLUI/screen/filterPanel/filterPanel";
import { JournalEntryType } from "@doorloop/dto";
import type { GetAccountRegisterReportQuery } from "@doorloop/dto";

const FilterOptions: Array<StaticRemoteOrDateFilter<GetAccountRegisterReportQuery>> = [
  {
    label: AppStrings.Common.ListHeader.Date,
    type: "dateRangePicker",
    period: "all-time",
    startDateFieldKey: "filter_report_date_from",
    endDateFieldKey: "filter_report_date_to"
  },
  {
    type: "staticFiltering",
    name: AppStrings.Common.FilterOptions.Type,
    values: [
      {
        displayName: AppStrings.Common.Enums.JournalEntryType.Deposit,
        value: JournalEntryType.DEPOSIT,
        filterFieldName: "filter_type"
      },
      {
        displayName: AppStrings.Common.Enums.JournalEntryType.Transfer,
        value: JournalEntryType.TRANSFER,
        filterFieldName: "filter_type"
      },
      {
        displayName: AppStrings.Common.Enums.JournalEntryType.Expense,
        value: JournalEntryType.EXPENSE,
        filterFieldName: "filter_type"
      },
      {
        displayName: AppStrings.Common.Enums.JournalEntryType.VendorBillPayment,
        value: JournalEntryType.VENDOR_BILL_PAYMENT,
        filterFieldName: "filter_type"
      }
    ]
  }
];

export default FilterOptions;
