import type { FC } from "react";
import React from "react";
import { QueryClient, QueryClientProvider as ReactQueryClientProvider } from "@tanstack/react-query";

const RETRY_COUNT = 2;
const RETRY_DELAY_IN_MS = 200;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount) => failureCount <= RETRY_COUNT,
      retryDelay: RETRY_DELAY_IN_MS
    }
  }
});

export const QueryClientProvider: FC = ({ children }) => (
  <ReactQueryClientProvider client={queryClient}>{children}</ReactQueryClientProvider>
);
