function get<T>(key: string, defaultValue: T): T {
  const storage = localStorage.getItem(key);
  return storage ? JSON.parse(storage) : defaultValue;
}

function set<T>(key: string, value: T): void {
  localStorage.setItem(key, JSON.stringify(value));
}

export const storage = {
  get,
  set
};
