import type { DashboardWidgetDto, UserTypes } from "@doorloop/dto";
import type { WidgetTypeToComponentMap } from "DLUI/widgets/widgets.map";

export interface ObjectWithHiddenForUserTypes {
  hiddenForUserTypes?: UserTypes[];
}

export const filterObjectsByUserType = <T extends ObjectWithHiddenForUserTypes>(
  objects: T[],
  userType: UserTypes
): T[] => objects.filter((obj) => isObjectUserType(obj, userType));

export const isObjectUserType = <T extends ObjectWithHiddenForUserTypes>(object: T, userType: UserTypes): boolean =>
  !object.hiddenForUserTypes || !object.hiddenForUserTypes.includes(userType);

export const filterMapByUserType = <T extends ObjectWithHiddenForUserTypes>(
  objects: Record<string, T>,
  userType: UserTypes
): Record<string, T> =>
  Object.fromEntries(Object.entries(objects).filter(([, obj]) => isObjectUserType(obj, userType)));

export const filterDashboardWidgetsByUserType = (
  widgets: DashboardWidgetDto[],
  widgetTypesMap: WidgetTypeToComponentMap,
  userType: UserTypes
): DashboardWidgetDto[] =>
  widgets.filter(({ type }) => type && !widgetTypesMap[type]?.hiddenForUserTypes?.includes(userType));
