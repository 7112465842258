"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityFeedFieldType = void 0;
var ActivityFeedFieldType;
(function (ActivityFeedFieldType) {
    ActivityFeedFieldType[ActivityFeedFieldType["DATE"] = 0] = "DATE";
    ActivityFeedFieldType[ActivityFeedFieldType["DATE_TIME"] = 1] = "DATE_TIME";
    ActivityFeedFieldType[ActivityFeedFieldType["STRING"] = 2] = "STRING";
    ActivityFeedFieldType[ActivityFeedFieldType["CURRENCY"] = 3] = "CURRENCY";
    ActivityFeedFieldType[ActivityFeedFieldType["INTEGER"] = 4] = "INTEGER";
    ActivityFeedFieldType[ActivityFeedFieldType["DECIMAL"] = 5] = "DECIMAL";
    ActivityFeedFieldType[ActivityFeedFieldType["PERCENTAGE"] = 6] = "PERCENTAGE";
    ActivityFeedFieldType[ActivityFeedFieldType["BOOLEAN"] = 7] = "BOOLEAN";
    ActivityFeedFieldType[ActivityFeedFieldType["ENUM"] = 8] = "ENUM";
    ActivityFeedFieldType[ActivityFeedFieldType["OBJECTID"] = 9] = "OBJECTID";
})(ActivityFeedFieldType || (exports.ActivityFeedFieldType = ActivityFeedFieldType = {}));
