"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenantPortalSettingsDto = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const common_1 = require("../../../common");
const portalContactInfo_dto_1 = require("../portalContactInfo.dto");
const portalEmailInvite_dto_1 = require("../portalEmailInvite.dto");
const portalRequestSettings_dto_1 = require("../portalRequestSettings.dto");
const portalTheme_dto_1 = require("../portalTheme.dto");
const tenantPortalAccess_dto_1 = require("./tenantPortalAccess.dto");
const userRoles_1 = require("../../../userRoles");
class TenantPortalSettingsDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.TenantPortalSettingsDto = TenantPortalSettingsDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => portalContactInfo_dto_1.PortalContactInfoDto),
    __metadata("design:type", portalContactInfo_dto_1.PortalContactInfoDto)
], TenantPortalSettingsDto.prototype, "contactInfo", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => portalTheme_dto_1.PortalThemeDto),
    __metadata("design:type", portalTheme_dto_1.PortalThemeDto)
], TenantPortalSettingsDto.prototype, "theme", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => tenantPortalAccess_dto_1.TenantPortalAccessDto),
    __metadata("design:type", tenantPortalAccess_dto_1.TenantPortalAccessDto)
], TenantPortalSettingsDto.prototype, "access", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => portalRequestSettings_dto_1.PortalRequestSettingsDto),
    __metadata("design:type", portalRequestSettings_dto_1.PortalRequestSettingsDto)
], TenantPortalSettingsDto.prototype, "requestSettings", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => portalEmailInvite_dto_1.PortalEmailInviteDto),
    __metadata("design:type", portalEmailInvite_dto_1.PortalEmailInviteDto)
], TenantPortalSettingsDto.prototype, "emailInvite", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, common_1.IsValidSubdomain)({
        message: "Invalid subdomain"
    }),
    __metadata("design:type", String)
], TenantPortalSettingsDto.prototype, "subdomain", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => userRoles_1.ReportsDto),
    __metadata("design:type", userRoles_1.ReportsDto)
], TenantPortalSettingsDto.prototype, "reportsAccess", void 0);
