"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyOwnershipReportItemDto = void 0;
const owner_dto_1 = require("../../owners/owner.dto");
class PropertyOwnershipReportItemDto extends owner_dto_1.OwnerDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.PropertyOwnershipReportItemDto = PropertyOwnershipReportItemDto;
