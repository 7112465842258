"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UndepositedFundsForOwnerDistributionsDto = void 0;
class UndepositedFundsForOwnerDistributionsDto {
    constructor(initValues) {
        this.propertyId = initValues.propertyId;
        this.total = initValues.total;
    }
}
exports.UndepositedFundsForOwnerDistributionsDto = UndepositedFundsForOwnerDistributionsDto;
