import React from "react";
import clsx from "clsx";
import Text from "DLUI/text";
import makeStyles from "./styles";
import { ReactComponent as Checkmark } from "assets/icons/checkmark_circle.svg";
import { Button } from "@material-ui/core";
import type { FillColors } from "DLUI/icon";
import { Icon } from "DLUI/icon";
import type { SVGIconComponent } from "assets/icons/types";
import { useReadOnlyContext } from "../../../../contexts/readOnlyContext";
import type { ObjectWithHiddenForUserTypes } from "../../../../utils/userTypesUtils";

export interface ImageButtonGroupProps<T = string | boolean> extends ObjectWithHiddenForUserTypes {
  label: string;
  icon: SVGIconComponent;
  onPress?: (itemIndex: number, value: T) => void;
  value: T;
  isSelected: boolean;
  size?: number;
  labelMaxWidth?: number;
  isMinimized?: boolean;
  fontSize?: number;
  dataCy?: string;
  width?: number;
  borderRadius?: number;
  iconPathColor?: FillColors;
}

export interface ImageButtonProps<T = string | boolean> extends ImageButtonGroupProps<T> {
  onPress?: (itemIndex: number, value: T) => void;
  itemIndex: number;
  dataCy?: string;
}

export const ImageButton: React.FC<ImageButtonProps> = ({
  label,
  icon,
  onPress,
  value,
  isSelected,
  itemIndex,
  size,
  labelMaxWidth,
  fontSize = 16,
  dataCy,
  width,
  borderRadius = 10,
  iconPathColor = "black"
}: ImageButtonProps) => {
  const isReadOnly = useReadOnlyContext();
  const classes = makeStyles();
  const _onClick = () => {
    onPress && onPress(itemIndex, value);
  };
  return (
    <Button
      disabled={isReadOnly}
      style={{
        width: width || size || 100,
        height: size || 100,
        borderRadius
      }}
      onClick={_onClick}
      data-cy={dataCy}
      classes={{
        label: classes.buttonLabelContainer
      }}
      className={clsx(classes.buttonContainer, {
        [classes.itemSelected]: isSelected
      })}
    >
      {isSelected ? (
        <Checkmark
          style={{
            width: size ? size * 0.15 : 20,
            height: size ? size * 0.15 : 20
          }}
          className={classes.checkmarkIcon}
        />
      ) : null}
      <Icon width={size ? size / 3.5 : 45} height={size ? size / 3.5 : 45} Source={icon} pathColor={iconPathColor} />

      <Text
        className={classes.buttonLabel}
        bold={isSelected}
        value={label}
        marginTop={10}
        align={"center"}
        color={"black"}
        fontSize={fontSize}
        removeDefaultPadding
        maxWidth={labelMaxWidth}
      />
    </Button>
  );
};
