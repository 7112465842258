import { ListItemContainerMaxWidth } from "DLUI/infiniteList/utils";
import { View } from "DLUI/view";
import React, { Fragment, useState } from "react";

import { NavigationManager } from "utils/navigation";
import type { OwnerDto } from "@doorloop/dto";
import OwnersListItem from "screens/owners/ownersList/ownersListItem";
import { AnimateMarginTop } from "DLUI/animatableView";
import { StickyHeader } from "DLUI/stickyHeader";

import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { globalSearchContstants } from "DLUI/screen/globalSearch/globalSearch.constants";
import type { ResultsSearchTabComponentProps } from "DLUI/screen/globalSearch/types";
import { useTimeout } from "../../../../hooks/useTimeout";

const OwnersTab: React.FC<ResultsSearchTabComponentProps<OwnerDto>> = ({ listItems, handleItemClicked }) => {
  const [shouldRender, setShouldRender] = useState(false);
  const { isMobile } = useResponsiveHelper();

  useTimeout(() => {
    setShouldRender(true);
  }, globalSearchContstants.tabsDisplayDelay);

  const items = listItems.map((currentItem: OwnerDto, index) => {
    const didPressOwnerItem = (ownerId: string) => {
      handleItemClicked("Owners");

      setTimeout(() => {
        NavigationManager.viewOwnerDetails(ownerId);
      }, globalSearchContstants.resultsItemHideDelay);
    };

    return (
      <Fragment key={"OSI" + index}>
        <OwnersListItem disableListItemOptions onItemPress={didPressOwnerItem} owner={currentItem} />
      </Fragment>
    );
  });

  return (
    <View
      alignItems={"center"}
      overflow={"scroll"}
      height={`calc(100vh - ${globalSearchContstants.searchPanelHeight}px)`}
    >
      {shouldRender && (
        <View maxWidth={ListItemContainerMaxWidth} style={{ display: "block" }}>
          {!isMobile && <StickyHeader id={"searchOwnersTab"} shouldShow marginTop={20} />}
          {isMobile && <AnimateMarginTop marginTop={20} />}
          <View id={"searchOwnersTab"}>
            {items}
            <View height={60} />
          </View>
        </View>
      )}
    </View>
  );
};

export default OwnersTab;
