import type { FC } from "react";
import React, { useMemo } from "react";
import { View } from "DLUI/view";
import type { ViewBackgroundColor } from "DLUI/view/view";

import { useResponsiveHelper } from "../../../contexts/responsiveContext";

export interface StatusStepInterface {
  StepView?: JSX.Element;
  connectorColor?: ViewBackgroundColor;
  minWidth?: number;
}

interface StepperBaseProps {
  steps: StatusStepInterface[];
  minWidth?: number;
}

const StepperDefaultWidth = 700;

export const StepperBase: FC<StepperBaseProps> = ({ steps, minWidth }: StepperBaseProps) => {
  const { isMobile, screenContainerPadding } = useResponsiveHelper();

  const ContainerWidth = useMemo(
    () => minWidth || Math.min(window.innerWidth - screenContainerPadding * 2, StepperDefaultWidth),
    [window.innerWidth, minWidth]
  );

  return (
    <View alignItems={"center"}>
      <View
        justifyContent={isMobile ? "center" : "space-between"}
        flexDirection={"row"}
        autoWidth
        minWidth={ContainerWidth}
      >
        {steps.map(({ connectorColor, StepView }, index) => {
          const showConnector = index > 0 && index <= steps.length - 1;
          const showMobileConnector = index >= 0 && index <= steps.length - 1;

          return (
            <View width={isMobile ? "auto" : ContainerWidth / steps.length} key={index} flexDirection={"row"}>
              <View marginLeft={index > 0 ? (isMobile ? 5 : 10) : 0}>
                {((isMobile && showMobileConnector) || showConnector) && (
                  <View
                    style={{
                      position: "absolute",
                      top: isMobile ? 5 : 15,
                      left: "-50%",
                      width: "calc(100% - 15px)",
                      zIndex: isMobile ? 1 : -500,
                      transition: ".all 2s"
                    }}
                    height={2}
                    backgroundColor={connectorColor || "green"}
                  />
                )}
                {StepView}
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};
