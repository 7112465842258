import React from "react";
import type { StepPropsWithDto } from "screens/onBoarding/types";
import AppStrings from "locale/keys";
import Text from "DLUI/text";
import StepSection from "screens/onBoarding/components/stepSection";
import { Icon } from "DLUI/icon";
import { OnBoardingLateFeesIcon } from "assets/.";
import { Grid } from "@material-ui/core";
import ActionButton from "screens/onBoarding/components/actionButton";
import { View } from "DLUI/view";
import { NavigationManager } from "utils/navigation";
import { useTranslation } from "react-i18next";
import { onBoardingApi } from "api/onBoardingApi";
import { VideoUrlsEnum } from "DLUI/screen/helpPanel/types";

type Props = StepPropsWithDto;

const SetupLateFeesStep: React.FC<Props> = ({
  isCollapsed,
  onHeaderClick,
  name,
  onBoardingDto,
  onInvalidate,
  isCompleted,
  isSkipped
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = React.useState(false);

  const icon = OnBoardingLateFeesIcon;
  const title = AppStrings.OnBoarding.StepTitles.SetupYourAutoLateFees;
  const description = AppStrings.OnBoarding.StepDescriptions.SetupYourAutoLateFees;

  const handleMarkAsComplete = async () => {
    setIsLoading(true);
    const response = await onBoardingApi.updateOnBoardingItem(name, { isSkipped: !isSkipped });

    const hasError = response.statusCode !== 200 ? response.message ?? true : undefined;
    await onInvalidate({ error: hasError });
    setIsLoading(false);
  };

  const handleSetupLateFeesClick = () => {
    NavigationManager.editLateFees(location.pathname);

    setTimeout(() => {
      /**
       * Invalidate the step to update the progress after 2 seconds
       * this is a workaround for the fact that auto late fees
       * are updated on the server on GET request to the late fees dialog settings
       */
      onInvalidate().then((_) => {});
    }, 2000);
  };

  const LeftSideContent = () => (
    <View gap={15}>
      <Text whiteSpace={"pre-line"} value={description} />
      <Grid container direction={"row"} alignItems={"center"} spacing={2}>
        <Grid item xs={12} sm={"auto"} md={"auto"}>
          <ActionButton
            fullWidth
            width={"100%"}
            onClick={handleSetupLateFeesClick}
            actionText={AppStrings.OnBoarding.ActionButtons.SetupYourAutoLateFees}
          />
        </Grid>
        <Grid item xs={12} sm={"auto"} md={"auto"}>
          {/* Don't show the mark as complete/incomplete button if the step is actually completed. show if skipped */}
          {(!isCompleted || isSkipped) && (
            <ActionButton
              onClick={handleMarkAsComplete}
              faded
              requestInProgress={isLoading}
              actionText={
                isCompleted || isSkipped
                  ? AppStrings.OnBoarding.MarkAsIncomplete
                  : AppStrings.OnBoarding.MarkAsCompleted
              }
              fullWidth
              width={"100%"}
            />
          )}
        </Grid>
      </Grid>
    </View>
  );

  return (
    <StepSection
      icon={<Icon size={24} Source={icon} />}
      title={title}
      leftSideContent={<LeftSideContent />}
      isCompleted={isCompleted}
      isSkipped={isSkipped}
      onHeaderClick={onHeaderClick}
      isCollapsed={isCollapsed}
      reactPlayerVideoUrl={VideoUrlsEnum.OB_LATE_FEES}
    />
  );
};

export default SetupLateFeesStep;
