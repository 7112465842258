import type { FormikProps } from "formik";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import type { PropertyDto } from "@doorloop/dto";
import { createValidator, BoardMembershipDto } from "@doorloop/dto";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import BoardMembershipForm from "screens/tenants/boardMember/boardMembershipForm";
import { View } from "DLUI/view";
import { useParams } from "react-router-dom";
import type { FrameType } from "DLUI/dialogs/components/dialogFrame";
import { useEffectAsync } from "../../../../hooks/useEffectAsync";
import AppStrings from "../../../../locale/keys";
import { propertiesApi } from "api/propertiesApi";

interface ComponentProps {
  didFinishOperation: (property: PropertyDto) => void;
  onBackdropClick: () => void;
  refreshEvent?: () => void;
  dialogTitle: string;
}

const validator = createValidator(BoardMembershipDto);

enum DialogViews {
  Loading,
  BoardMembershipForm
}

let formikRef: FormikProps<BoardMembershipDto> | null;
const BoardMembershipDialog = ({ didFinishOperation, onBackdropClick }: ComponentProps) => {
  const { propertyId, boardMemberId } = useParams<any>();
  const [viewIndex, setViewIndex] = React.useState<number>(DialogViews.BoardMembershipForm);
  const [loadingDialogState, setLoadingDialogState] = React.useState<DialogState>(DialogState.Hidden);
  const [errorText, setErrorText] = React.useState<string>("");
  const [frameType, setFrameType] = React.useState<FrameType>("sectionTitleFrame");
  const [initialData, setInitialData] = React.useState<BoardMembershipDto>();
  const [isEdit, setIsEdit] = React.useState<boolean>(false);

  useEffectAsync(async () => {
    showLoadingDialog();
    const initialValues = await getInitialValues();
    setInitialData(initialValues);
    hideLoadingDialog();
  }, [boardMemberId]);

  useEffect(
    () => () => {
      formikRef = null;
    },
    []
  );

  const getInitialValues = async (): Promise<BoardMembershipDto | undefined> => {
    if (!boardMemberId) {
      return new BoardMembershipDto();
    }

    const property = await propertiesApi.get(propertyId || "");
    const boardMembership = property?.data?.boardMembers?.find((boardMember) => boardMember.id === boardMemberId);

    if (boardMembership) {
      setIsEdit(true);
      return {
        id: boardMembership.id,
        tenantId: boardMembership.tenantId,
        position: boardMembership.position,
        termStart: boardMembership.termStart,
        termEnd: boardMembership.termEnd
      };
    }

    showErrorMessage(AppStrings.Common.GeneralError);
  };

  const showLoadingDialog = () => {
    setViewIndex(DialogViews.Loading);
    setLoadingDialogState(DialogState.Show);
    setFrameType("contentOnly");
  };

  const hideLoadingDialog = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(DialogViews.BoardMembershipForm);
    setFrameType("sectionTitleFrame");
  };

  const showErrorMessage = (message: string) => {
    setErrorText(message);
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogState(DialogState.Hidden);
  };
  const renderView = () => {
    if (viewIndex === DialogViews.Loading || !initialData) {
      return <LoadingDialog dialogState={loadingDialogState} errorText={errorText} />;
    }

    return (
      <Formik initialValues={initialData} validate={validator} enableReinitialize validateOnBlur onSubmit={() => {}}>
        {(formik) => {
          formikRef = formik;
          return (
            <View flexDirection={"row"} paddingTop={20} paddingRight={20} paddingLeft={20}>
              <BoardMembershipForm propertyId={propertyId} isEdit={isEdit} />
            </View>
          );
        }}
      </Formik>
    );
  };

  const didPressSave = async () => {
    if (formikRef) {
      await formikRef.validateForm();
      formikRef.setFieldTouched("tenantId", true);
      formikRef.setFieldTouched("position", true);
      formikRef.setFieldTouched("termStart", true);

      if (formikRef.isValid) {
        showLoadingDialog();
        const propertyResult = await propertiesApi.get(propertyId || "");

        if (propertyResult.status && propertyResult.data?.id) {
          const property = propertyResult.data;
          property.boardMembers ??= [];

          if (isEdit) {
            property.boardMembers = property.boardMembers.map((boardMember) =>
              boardMember.id === formikRef?.values.id && formikRef ? formikRef.values : boardMember
            );
          } else {
            property.boardMembers.push(formikRef.values);
          }
          const propertyUpdateResult = await propertiesApi.update(propertyId || "", property);

          if (propertyUpdateResult.status && propertyUpdateResult.data?.id) {
            didFinishOperation(propertyUpdateResult.data);
          } else {
            hideLoadingDialog();
            showErrorMessage(propertyUpdateResult.message);
          }
        } else {
          hideLoadingDialog();
          showErrorMessage(propertyResult.message);
        }
      }
    }
  };

  const renderActionPanelButtons = () => {
    if (viewIndex === DialogViews.Loading) {
      return <div />;
    }

    return (
      <FormActionButtons
        propsSubButton={{ onClick: onBackdropClick }}
        propsMainButton={{ type: "cta", props: { onClick: didPressSave } }}
      />
    );
  };

  return (
    <DialogFrame
      onCloseButtonClick={onBackdropClick}
      title={isEdit ? AppStrings.Common.Hoa.EditBoardMember : AppStrings.Common.Hoa.NewBoardMember}
      width={750}
      height={500}
      renderView={renderView}
      numViews={2}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={frameType}
    />
  );
};
export default BoardMembershipDialog;
