// Action Type Definition
import { ServerRoutes } from "@doorloop/dto";

const ACTION_TYPE_CLEAR_TOAST = "CLEAR_TOAST";
const ACTION_TYPE_SET_TOAST = "SET_TOAST";

const EXCLUDED_URLS_WITH_NO_ID = [
  ServerRoutes.SETTINGS,
  ServerRoutes.RENTAL_APPLICATIONS_PUT_UPDATE_DECISION_STATUS,
  ServerRoutes.REPORTS_OWNER_STATEMENT_PRINTABLE_PDF
];

export { ACTION_TYPE_SET_TOAST, ACTION_TYPE_CLEAR_TOAST, EXCLUDED_URLS_WITH_NO_ID };
