import React, { useMemo } from "react";
import { LeaseDraftStatus } from "@doorloop/dto";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import ListIndicator from "./components/listIndicator";
import "./styles.css";

interface ComponentProps {
  onStepClick?: () => void;
  status: LeaseDraftStatus;
  height?: number;
}

const StatusStepIndicator: React.FC<ComponentProps> = ({ onStepClick, status }: ComponentProps) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(1);
  const [completed, setCompleted] = React.useState(new Set<number>([0]));

  const steps = useMemo(() => {
    let nextSet = new Set<number>([]);
    switch (status) {
      case LeaseDraftStatus.Activated:
        nextSet = new Set<number>([0, 1, 2]);
        setCompleted(nextSet);
        setActiveStep(3);
        return [
          t(AppStrings.Leases.NewLease.LeaseStatus.Created),
          t(AppStrings.Leases.NewLease.LeaseStatus.Sent),
          t(AppStrings.Leases.NewLease.LeaseStatus.Signed),
          t(AppStrings.Leases.NewLease.LeaseStatus.Activated)
        ];
      case LeaseDraftStatus.Cancelled:
        nextSet = new Set<number>([0, 1, 2, 3]);
        setCompleted(nextSet);
        setActiveStep(3);
        return [
          t(AppStrings.Leases.NewLease.LeaseStatus.Created),
          t(AppStrings.Leases.NewLease.LeaseStatus.Sent),
          t(AppStrings.Leases.NewLease.LeaseStatus.Signed),
          t(AppStrings.Leases.NewLease.LeaseStatus.Cancelled)
        ];

      case LeaseDraftStatus.Countersigned:
        nextSet = new Set<number>([0, 1, 2]);
        setCompleted(nextSet);
        setActiveStep(2);
        return [
          t(AppStrings.Leases.NewLease.LeaseStatus.Created),
          t(AppStrings.Leases.NewLease.LeaseStatus.Sent),
          t(AppStrings.Leases.NewLease.LeaseStatus.CounterSigned),
          t(AppStrings.Leases.NewLease.LeaseStatus.Activated)
        ];

      case LeaseDraftStatus.NotSent:
        nextSet = new Set<number>([0]);
        setCompleted(nextSet);
        setActiveStep(0);
        return [
          t(AppStrings.Leases.NewLease.LeaseStatus.Created),
          t(AppStrings.Leases.NewLease.LeaseStatus.Sent),
          t(AppStrings.Leases.NewLease.LeaseStatus.Signed),
          t(AppStrings.Leases.NewLease.LeaseStatus.Activated)
        ];

      case LeaseDraftStatus.PartiallySigned:
        nextSet = new Set<number>([0, 1, 2]);
        setCompleted(nextSet);
        setActiveStep(2);
        return [
          t(AppStrings.Leases.NewLease.LeaseStatus.Created),
          t(AppStrings.Leases.NewLease.LeaseStatus.Sent),
          t(AppStrings.Leases.NewLease.LeaseStatus.PartiallySigned),
          t(AppStrings.Leases.NewLease.LeaseStatus.Activated)
        ];

      case LeaseDraftStatus.Sent:
        nextSet = new Set<number>([0, 1]);
        setCompleted(nextSet);
        setActiveStep(1);
        return [
          t(AppStrings.Leases.NewLease.LeaseStatus.Created),
          t(AppStrings.Leases.NewLease.LeaseStatus.Sent),
          t(AppStrings.Leases.NewLease.LeaseStatus.Signed),
          t(AppStrings.Leases.NewLease.LeaseStatus.Activated)
        ];
    }
    return [];
  }, []);

  return (
    <ListIndicator
      onStepClick={onStepClick}
      activeStep={activeStep}
      steps={steps}
      completed={completed}
      status={status}
    />
  );
};

export default StatusStepIndicator;
