import React, { useMemo } from "react";
import type { TextComponentProps } from "DLUI/text/text";
import Text from "DLUI/text";
import { Link } from "DLUI/link";
import { history } from "store/history";

interface ComponentProps extends TextComponentProps {
  children?: any;
  size?: "sm" | "md" | "lg";
  overflow?: "ellipsis" | "visible";
  href?: string;
  openInNewTab?: boolean;
  openInThisTab?: boolean;
  autoWidth?: boolean;
}

const defaultFontSizes = {
  sm: 12,
  md: 14,
  lg: 16
};

const ListItemText: React.FC<ComponentProps> = ({
  children,
  align,
  color,
  value,
  size,
  formatType,
  overflow,
  numberOfLines,
  openInNewTab,
  openInThisTab,
  href,
  autoWidth,
  ...rest
}: ComponentProps) => {
  const _overFlow = useMemo(() => {
    if (formatType) {
      return "visible";
    }
    return overflow || "ellipsis";
  }, []);

  if (!value && !children) {
    return <div />;
  }

  const _onClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (!href) {
      return;
    }
    event.preventDefault();

    if (openInNewTab) {
      window.open(href, "_blank");
    } else if (openInThisTab) {
      history.push(href);
    }
  };

  const textElement = (
    <Text
      value={value}
      color={color || "black"}
      fontSize={defaultFontSizes[size || "md"]}
      align={align || "left"}
      overFlow={_overFlow}
      numberOfLines={numberOfLines || 1}
      formatType={formatType}
      {...rest}
    >
      {children}
    </Text>
  );

  if (href) {
    return (
      <Link onClick={_onClick} hrefUrl={href} width={autoWidth ? "auto" : "100%"}>
        {textElement}
      </Link>
    );
  }

  return textElement;
};

export default ListItemText;
