"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.formikValidate = formikValidate;
exports.createValidator = createValidator;
const classValidator = __importStar(require("class-validator"));
const class_transformer_1 = require("class-transformer");
__exportStar(require("class-validator"), exports);
function mapErrorsToFormikFormat(errors) {
    var _a;
    const result = {};
    for (const error of errors) {
        let validationMessage = "";
        let highestPriority = 0;
        for (const constrain in error.constraints) {
            if (error.contexts && error.contexts[constrain]) {
                if (!highestPriority || error.contexts[constrain].errorDisplayPriority > highestPriority) {
                    highestPriority = error.contexts[constrain].errorDisplayPriority;
                    validationMessage = error.constraints[constrain];
                }
            }
            else {
                validationMessage = error.constraints[constrain];
            }
        }
        // TODO Figure out how to translate these
        if (validationMessage) {
            if (validationMessage.indexOf("type must be one of the following values") > -1 ||
                validationMessage.indexOf("must contain at least") > -1 ||
                validationMessage.indexOf("mongodb id") > -1 ||
                validationMessage.indexOf("should not be empty") > -1 ||
                validationMessage.indexOf("valid enum value") > -1 ||
                validationMessage.indexOf("must be a string") > -1 ||
                validationMessage.indexOf("should not be null") > -1) {
                validationMessage = "This field is required";
            }
            else if (validationMessage.indexOf("number conforming to the specified") > -1 ||
                validationMessage.indexOf("must be longer than") > -1 ||
                validationMessage.indexOf("must be shorter than") > -1 ||
                validationMessage.indexOf("must not be less than") > -1 ||
                validationMessage.indexOf("must match") > -1) {
                validationMessage = "This value is invalid";
            }
            else if (validationMessage.indexOf("must be a valid phone number") > -1) {
                validationMessage = "Please enter a valid phone number";
            }
            else if (validationMessage.indexOf("must be an email") > -1) {
                validationMessage = "Invalid email address";
            }
        }
        result[error.property] = validationMessage;
        if ((_a = error.children) === null || _a === void 0 ? void 0 : _a.length) {
            const nestedErrors = mapErrorsToFormikFormat(error.children);
            result[error.property] = nestedErrors;
        }
    }
    return result;
}
function validateSync(classType, data, options) {
    let object;
    if (typeof data === "string") {
        object = JSON.parse(data);
    }
    else if (typeof data === "object" && data !== null) {
        object = data;
    }
    else {
        throw new Error(`Invalid \`data\` parameter of type "${typeof data}".` +
            "Only string, plain object and array of plain objects are valid.");
    }
    const classObject = (0, class_transformer_1.plainToClass)(classType, object, options === null || options === void 0 ? void 0 : options.transformer);
    if (Array.isArray(classObject)) {
        const errors = classObject.map((objectElement) => classValidator.validateSync(objectElement, options === null || options === void 0 ? void 0 : options.validator));
        if (errors.some((errors) => errors.length)) {
            throw errors;
        }
        return classObject;
    }
    const errors = classValidator.validateSync(classObject, options === null || options === void 0 ? void 0 : options.validator);
    if (errors.length) {
        throw errors;
    }
    return classObject;
}
function formikValidate(classType, data, options) {
    try {
        validateSync(classType, data, options);
        return {};
    }
    catch (error) {
        if (error instanceof Error) {
            throw error;
        }
        return mapErrorsToFormikFormat(error);
    }
}
class TransformValidationOptions {
}
function createValidator(classConstructor, initialOptions) {
    return (data, options) => formikValidate(classConstructor, data, Object.assign(Object.assign({}, options), { validator: Object.assign(Object.assign({ forbidUnknownValues: true }, initialOptions === null || initialOptions === void 0 ? void 0 : initialOptions.validator), options === null || options === void 0 ? void 0 : options.validator), transformer: Object.assign(Object.assign({ enableImplicitConversion: true }, initialOptions === null || initialOptions === void 0 ? void 0 : initialOptions.transformer), options === null || options === void 0 ? void 0 : options.transformer) }));
}
