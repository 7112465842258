import React from "react";
import Grid from "@material-ui/core/Grid";
import { Select, TextField } from "DLUI/form";
import { Field } from "formik";
import { useTranslation } from "react-i18next";
import makeStyles from "./styles";
import { View } from "DLUI/view";
import type { SelectInputProps } from "../select/selectInput";
import { FastFieldSafe } from "../../fastFieldSafe/fastFieldSafe";
import { PhoneNumberInputField } from "DLUI/form/phoneNumberInput/phoneNumberInputField";

interface ComponentProps {
  selectName: string;
  inputName: string;
  inputLabel: string;
  selectKeysEnum: any;
  removeTopPadding?: boolean;
  onTransformSelect?: NonNullable<SelectInputProps["onTransform"]>;
  disabled?: boolean;
  isMobilePhone?: boolean;
}
const SelectWithInput: React.FC<ComponentProps> = ({
  selectName,
  selectKeysEnum,
  inputName,
  inputLabel,
  onTransformSelect,
  disabled,
  isMobilePhone
}: ComponentProps) => {
  const classes = makeStyles();
  const { t } = useTranslation();

  return (
    <View flexDirection={"row"}>
      <Grid item xs={6} md={6}>
        <View paddingRight={10}>
          <FastFieldSafe
            component={Select}
            className={classes.selectField}
            name={selectName}
            size={"90%"}
            selectionEnum={selectKeysEnum}
            uniqueKey={inputName}
            onTransform={onTransformSelect}
          />
        </View>
      </Grid>
      <Grid item xs={6} md={6}>
        <View paddingLeft={10}>
          <Field
            component={isMobilePhone ? PhoneNumberInputField : TextField}
            label={t(inputLabel)}
            name={inputName}
            size={"95%"}
            disabled={disabled}
          />
        </View>
      </Grid>
    </View>
  );
};

export default SelectWithInput;
