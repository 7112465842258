"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArAgingSummaryReportItem = void 0;
class ArAgingSummaryReportItem {
    constructor(agingPeriods) {
        this.agingPeriods = {};
        for (const period of agingPeriods) {
            this.agingPeriods[period.label] = 0;
        }
        this.total = 0;
    }
}
exports.ArAgingSummaryReportItem = ArAgingSummaryReportItem;
