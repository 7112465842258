import React, { useState } from "react";

import { ControlledCheckBox } from "DLUI/form/checkBox/base/controlledCheckBox";
import type { UnControlledToggleInputBaseTypes } from "DLUI/form/types/toggleInputBaseTypes";
import type { CheckBoxColor } from "DLUI/form/checkBox/base/types";

export interface UnControlledCheckBoxComponentProps<T> extends UnControlledToggleInputBaseTypes<T> {
  color?: CheckBoxColor;
  indeterminate?: boolean;
}

export function UnControlledCheckBox<T>({ onChange, defaultChecked, ...rest }: UnControlledCheckBoxComponentProps<T>) {
  const [isChecked, setIsChecked] = useState<boolean>(Boolean(defaultChecked));

  const didChangeCheckBoxSelection = () => {
    setIsChecked((checked) => !checked);
    onChange?.(!isChecked);
  };

  return <ControlledCheckBox onChange={didChangeCheckBoxSelection} checked={isChecked} {...rest} />;
}
