import { lazy } from "react";
import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";

const RecurringTasksListScreen = lazy(() => import("screens/tasks/tasksList/recurringTasksListScreen"));
const RecurringBillsExpensesListScreen = lazy(
  () => import("screens/bills/recurringBillsExpenses/recurringBillsExpensesListScreen")
);

const RecurringRouter = () => (
  <PageRouter>
    <LazyRoute path={Routes.EDIT_RECURRING_TASK} render={RecurringTasksListScreen} />
    <LazyRoute path={Routes.EDIT_RECURRING_WORK_ORDER} render={RecurringTasksListScreen} />
    <LazyRoute path={Routes.RECURRING_TASKS} render={RecurringTasksListScreen} />
    <LazyRoute exact path={Routes.RECURRING_BILLS_AND_EXPENSES_EDIT_BILL} render={RecurringBillsExpensesListScreen} />
    <LazyRoute
      exact
      path={Routes.RECURRING_BILLS_AND_EXPENSES_EDIT_GENERAL_ENTRY}
      render={RecurringBillsExpensesListScreen}
    />
    <LazyRoute
      exact
      path={Routes.RECURRING_BILLS_AND_EXPENSES_EDIT_EXPENSE}
      render={RecurringBillsExpensesListScreen}
    />
    <LazyRoute path={Routes.RECURRING_BILLS_AND_EXPENSES} render={RecurringBillsExpensesListScreen} />
  </PageRouter>
);

export default RecurringRouter;
