import type {
  RentalApplicationCompanySettingsDto,
  RentalApplicationFilesAndDocumentsFileDtoSettingsDto
} from "@doorloop/dto";
import { RentalApplicationAttachmentSettingsDto } from "@doorloop/dto";
import { View } from "DLUI/view";
import { FastField, getIn, useFormikContext } from "formik";
import React, { useMemo, useState } from "react";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { FormikSwitchButton, RadioGroup } from "DLUI/form";
import AttachmentsList from "./AttachmentsList";
import { AnimatedView } from "DLUI/view/animatedView";
import { SeparationLine } from "DLUI/separatorView";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { useEffectOnce } from "hooks/useEffectOnce";

const AdditionalAttachments: React.FC<{}> = () => {
  const { propertyId } = useParams<any>();
  const {
    values: { filesAndDocuments },
    setFieldValue
  } = useFormikContext<RentalApplicationCompanySettingsDto>();

  const filesSelectionDefaultValue = useMemo(() => getIn(filesAndDocuments, "isShown") || false, []);
  const [renderForm, setRenderForm] = useState<boolean>(true);
  const [fileRequestsEnabled, setFileRequestsEnabled] = useState<boolean>(filesSelectionDefaultValue);
  const initFiles = useMemo(() => getIn(filesAndDocuments, "files") || [], []);

  const documentsSelectionDefaultValue = useMemo(() => {
    const fieldValue = filesAndDocuments || {};
    const emptyObject = Object.values(fieldValue).every((x) => x === undefined);

    return emptyObject && propertyId ? "companyDefault" : "specifyDocumentsInfo";
  }, []);

  const [documentsSelectionType, setDocumentsSelectionType] = useState<"companyDefault" | "specifyDocumentsInfo">(
    documentsSelectionDefaultValue
  );

  const handleFilesAndDocumentsToggle = (value: boolean) => {
    let files: RentalApplicationFilesAndDocumentsFileDtoSettingsDto[] = initFiles;
    if (!value) {
      files = [];
    } else if (_.isEmpty(files)) {
      files = [new RentalApplicationAttachmentSettingsDto()];
    }
    setFieldValue("filesAndDocuments.files", files);
  };

  const toggleRenderForm = (value: boolean) => {
    setFileRequestsEnabled(value);
    setFieldValue("filesAndDocuments.isShown", value);
    handleFilesAndDocumentsToggle(value);
  };

  useEffectOnce(() => {
    if (_.isEmpty(filesAndDocuments?.files) && fileRequestsEnabled) {
      handleFilesAndDocumentsToggle(true);
    }
  });

  const didChangeSpecifyDocumentsSelection = (selectedValue: string) => {
    if (selectedValue === "false") {
      setDocumentsSelectionType("companyDefault");

      setTimeout(() => {
        refreshForm();
      }, 500);
    } else {
      setDocumentsSelectionType("specifyDocumentsInfo");
      setFieldValue("filesAndDocuments.isShown", false);
    }
  };

  const refreshForm = () => {
    setRenderForm(false);
    setFileRequestsEnabled(false);
    setFieldValue("filesAndDocuments", undefined);

    setTimeout(() => {
      setRenderForm(true);
    }, 0);
  };

  const renderSpecifyRadioButtons = () => {
    if (propertyId) {
      return (
        <View>
          <RadioGroup
            didChangeSelection={didChangeSpecifyDocumentsSelection}
            uniqueKey={"FRB"}
            defaultValue={documentsSelectionType === "companyDefault" ? "false" : "true"}
            marginTop={20}
            radioGroupItems={[
              {
                label: AppStrings.Common.HideSectionForProperty,
                value: "false"
              },
              {
                label: AppStrings.Common.SpecifySettingsForProperty,
                value: "true"
              }
            ]}
          />
          <SeparationLine marginTop={20} width={"100%"} height={1} />
        </View>
      );
    }
  };

  return (
    <>
      <Text
        value={AppStrings.Settings.GeneralSettings.RentalApplications.filesAndDocumentsSectionDescription}
        lineHeight={"26px"}
      />
      {renderSpecifyRadioButtons()}
      <View marginTop={20} />
      <AnimatedView shouldShow={documentsSelectionType === "specifyDocumentsInfo"}>
        {renderForm ? (
          <>
            <FastField
              component={FormikSwitchButton}
              name={"filesAndDocuments.isShown"}
              defaultValue={fileRequestsEnabled}
              label={AppStrings.Settings.GeneralSettings.RentalApplications.filesAndDocumentsToggleLabel}
              onChange={toggleRenderForm}
            />
            <AnimatedView shouldShow={fileRequestsEnabled} marginTop={20}>
              <View gap={4}>
                <Text
                  value={AppStrings.Settings.GeneralSettings.RentalApplications.AdditionalAttachments}
                  fontSize={16}
                  bold
                />

                <Text
                  value={AppStrings.Settings.GeneralSettings.RentalApplications.LimitedToOneFilePerAttachment}
                  fontSize={14}
                />
              </View>
              <AttachmentsList />
            </AnimatedView>
          </>
        ) : null}
      </AnimatedView>
    </>
  );
};

export default AdditionalAttachments;
