import { LearnMoreText } from "DLUI/text/learnMoreText";
import type { DeepKeys } from "@doorloop/dto";
import { SettingsSection } from "screens/settings/common/settingsSection";
import React from "react";
import { useTypedTranslation } from "locale";
import clsx from "clsx";
import { useGeneralStyles } from "styles/generalStyles";
import { useLateFeeSettings } from "DLUI/dialogs/settings/common/lateFees/useLateFeeSettings";
import type { LateFeesPrefixes } from "DLUI/dialogs/settings/common/lateFees/types";
import { ReminderSection } from "DLUI/dialogs/settings/common/lateFees/reminderSection";

export interface LateFeeReminderProps {
  formikPathName?: DeepKeys<LateFeesPrefixes>;
}

export const LateFeeReminder = ({ formikPathName }: LateFeeReminderProps) => {
  const { t } = useTypedTranslation();
  const generalClasses = useGeneralStyles();
  const { getFieldPath, lateFeeSettings } = useLateFeeSettings({ formikPathName });

  if (!lateFeeSettings) return null;

  return (
    <SettingsSection title={t("lateFee.remindersTitle")}>
      <div className={clsx(generalClasses.flexColumn, generalClasses.largeGap)}>
        <LearnMoreText value={t("lateFee.feeRemindersDescription")} />
        <ReminderSection
          title="lateFee.reminderBefore.title"
          subtitle="lateFee.reminderBefore.description"
          timing={"before"}
          getFieldPath={getFieldPath}
        />
        <ReminderSection
          title="lateFee.reminderAfter.title"
          subtitle="lateFee.reminderAfter.description"
          timing={"after"}
          getFieldPath={getFieldPath}
        />
      </div>
    </SettingsSection>
  );
};
