import Footer from "./footer";
import AppBarContainer from "./AppBars/appBarContainer";
import AppLayout from "./appLayout";
import SideBarLayout from "./sidebarLayout";
import FullWidthLayout from "./fullWidthLayout";

export const AppLayouts = {
  SideBarLayout,
  FullWidthLayout
};

export { Footer, AppBarContainer as Header, AppLayout };
