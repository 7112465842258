import type { FieldProps } from "formik";
import type { FilledInputProps, InputLabelProps, InputProps, OutlinedInputProps } from "@material-ui/core";
import type React from "react";
import type { CSSProperties } from "react";
import type { SVGIconComponent } from "../../../../assets";
import type { TextFormatType, TextTransformProp } from "DLUI/text/text";
import type { ClassNameMap } from "@material-ui/styles";

export type BackgroundColor = "white" | "transparent";
export type TextFieldType = "number" | "string" | undefined | "password" | "email";
export type FormatType = "currency" | "date" | "number" | "text" | "percent";

export enum FieldSizes {
  "WEB" = "WEB",
  "MOBILE" = "MOBILE"
}

export interface FieldSizesMapType {
  InputProps: Partial<InputProps>;
  InputLabelProps: Partial<InputLabelProps>;
}

export interface ComponentProps extends FieldProps<any> {
  id?: string;
  variant?: "standard" | "filled" | "outlined";
  label?: string;
  size?: number | string;
  InputProps?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps> | undefined;
  InputLabelProps?: Partial<InputLabelProps>;
  startAdornment?: React.ReactNode;
  viewOnlyHeight?: number;
  backgroundColor?: BackgroundColor;
  marginLeft?: number;
  EndAdornmentIcon?: SVGIconComponent;
  style?: {};
  paddingRight?: number;
  marginTop?: number;
  paddingLeft?: number;
  endAdornmentSize?: number;
  TextFieldType?: TextFieldType;
  onBlur?: (nextValue: string) => void;
  onBlurUnconditionally?: () => void;
  formatType?: TextFormatType;
  required?: boolean;
  allowNegative?: boolean;
  textAlign?: "right" | "left";
  multiLineSize?: "small" | "medium" | "normal" | "large";
  onChange?: (nextValue: string) => void;
  onClick?: (ev: any) => void;
  autoWidth?: boolean;
  decimalScale?: number;
  autoComplete?: string;
  onFocus?: (event?: any) => void;
  viewOnly?: boolean;
  onSubmit?: (e: any) => void;
  ignoreSpaces?: boolean;
  asyncAutoCompleteInstance?: boolean;
  maxLength?: number;
  textTransform?: TextTransformProp;
  fieldSize?: FieldSizes;
  multiline?: boolean;
  hideErrorText?: boolean;
  warning?: boolean;
  dataCy?: string;
  disabled?: boolean;
  format?: string;
  maxNumber?: number;
  minNumber?: number;
  placeholder?: string;
  shouldDebounce?: boolean;
  autoFocus?: boolean;
  maxLengthStyle?: CSSProperties;
  sliceOnMaxLength?: boolean;
  useFormikOnBlur?: boolean;
  textFieldClasses?: ClassNameMap;
}
