"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportResponseDto = void 0;
class ImportResponseDto {
    constructor() {
        //@ts-ignore
        this.errors = [];
    }
}
exports.ImportResponseDto = ImportResponseDto;
