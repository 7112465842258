import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import AppStrings from "../../../../../locale/keys";

export interface CancelAutoPaymentsTexts {
  cancelWarning: string;
  confirmationCancelTitle: string;
  confirmationCancelDescription: string;
}

export const useCancelLeaseAutoPaymentsTexts = (): CancelAutoPaymentsTexts => {
  const { t } = useTranslation();

  return useMemo(() => {
    return {
      cancelWarning: t(AppStrings.Common.TenantPortalFeaturesOnlinePayments.Lease.CancelWarning),
      confirmationCancelTitle: t(AppStrings.Common.TenantPortalFeaturesOnlinePayments.Lease.ConfirmationCancelTitle),
      confirmationCancelDescription: t(
        AppStrings.Common.TenantPortalFeaturesOnlinePayments.Lease.ConfirmationCancelDescription
      )
    };
  }, []);
};
