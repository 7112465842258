"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectBillsType = exports.SelectVendorsType = exports.SelectPropertiesType = void 0;
var SelectPropertiesType;
(function (SelectPropertiesType) {
    SelectPropertiesType["ALL_PROPERTIES"] = "ALL_PROPERTIES";
    SelectPropertiesType["SOME_PROPERTIES"] = "SOME_PROPERTIES";
    SelectPropertiesType["PORTFOLIO"] = "PORTFOLIO";
})(SelectPropertiesType || (exports.SelectPropertiesType = SelectPropertiesType = {}));
var SelectVendorsType;
(function (SelectVendorsType) {
    SelectVendorsType["AllVendors"] = "AllVendors";
    SelectVendorsType["SomeVendors"] = "SomeVendors";
})(SelectVendorsType || (exports.SelectVendorsType = SelectVendorsType = {}));
var SelectBillsType;
(function (SelectBillsType) {
    SelectBillsType["AllBills"] = "AllBills";
    SelectBillsType["SomeBills"] = "SomeBills";
})(SelectBillsType || (exports.SelectBillsType = SelectBillsType = {}));
