"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityFeedCreatedByType = void 0;
var ActivityFeedCreatedByType;
(function (ActivityFeedCreatedByType) {
    ActivityFeedCreatedByType["SYSTEM"] = "SYSTEM";
    ActivityFeedCreatedByType["USER"] = "USER";
    ActivityFeedCreatedByType["TENANT"] = "TENANT";
    ActivityFeedCreatedByType["OWNER"] = "OWNER";
    ActivityFeedCreatedByType["VENDOR"] = "VENDOR";
})(ActivityFeedCreatedByType || (exports.ActivityFeedCreatedByType = ActivityFeedCreatedByType = {}));
