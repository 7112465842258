import type {
  DisplayLeaseDraftTenantDto,
  DoorLoopLeadDto,
  ESignatureRelatedToType,
  FileDisplayDto,
  GetAllBudgetsQuery,
  GetAllLeasesQuery,
  GetDepositDetailReportQuery,
  GetProfitAndLossReportQuery,
  GetTransactionReportQuery,
  LeaseDraftDto,
  LeaseDto,
  NoteDtoDisplay,
  PaymentMethod,
  PersonType,
  PrintChecksReportItemDto,
  SetupSmsOrigin,
  TaxForm1099Dto,
  TransunionReportTypes
} from "@doorloop/dto";
import {
  AccountingMethod,
  GetAllOwnersQuery,
  GetOwners1099ReportQuery,
  GetPropertyOwnershipQuery,
  GetVendors1099ReportQuery,
  OwnerDto,
  Owners1099ReportItem,
  PropertyOwnershipReportItemDto,
  RecurringTransactionType,
  SegmentEventTypes,
  TaskStatusForQuery,
  Vendors1099ReportItem
} from "@doorloop/dto";
import type { ConfirmOperation } from "DLUI/dialogs/confirm/confirm";
import { DialogRoutes } from "DLUI/screen/dialogsProvider";
import { history } from "store/history";
import Routes, { makeEditRoute } from "../components/appRouter/routes";
import qs from "query-string";
import { HelpCenterTenantUrl, HelpCenterUrl } from "./sharedVariables";
import { leaseReversedPaymentsApi } from "api/leaseReversedPaymentsApi";
import type { SupportedAPIMethods } from "DLUI/dialogs/confirm/apiMethodNameMap";
import { analyticsService } from "services/analyticsService";
import type { ApiToastsProps } from "api/apiHelper";
import type { BudgetVsActualReportForm } from "screens/reports/budgetVsActual/types";
import { conversationUtils } from "screens/communicationsCenter/shared/conversationUtils";
import type { PeriodType } from "DLUI/form/datePicker/datePicker.types";
import type { LocationDescriptorObject, LocationState } from "history";
import type { ConfirmationDialogVariant } from "@/components/DLUI/dialogs/confirm/constants";

type RoutesType = typeof Routes;
type KeyOfRoutesType = keyof RoutesType;
type RoutesObjectType = RoutesType[KeyOfRoutesType];

export type ReportRoutes = {
  [K in RoutesObjectType]: K extends `/reports/${string}` ? K : never;
}[RoutesObjectType];

export type ConfirmationDialogConfigs = Partial<{
  apiMethodName: string;
  dismissRouterPath: string;
  showXButton: boolean;
  openInNewTab: boolean;
}> &
  ConfirmationDialogProps;

export interface ConfirmationDialogProps {
  dialogTitle: string;
  dialogSubTitle: string;
  dialogSubTitleDisclaimer?: string;
  loadingText?: string;
  successText?: string;
  confirmButtonText: string;
  dismissButtonText: string;
  apiMethod?: SupportedAPIMethods;
  confirmOperation?: ConfirmOperation;
  itemId?: string;
  ignoreFiles?: boolean;
  nextRouterPath?: string;
  itemDto?: any;
  toasts?: ApiToastsProps<any>;
  dialogHeight?: number;
  dialogVariant?: ConfirmationDialogVariant;
  checkboxText?: string;
}

export interface AlertDialogProps {
  dialogTitle: string;
  dialogSubTitle: string;
  dismissButtonText: string;
  textMaxWidth?: number;
  contactSupportText?: string;
  backToRoute?: string;
  severity?: "error" | "warning" | "info" | "success";
}

export interface CreateOwnerDistributionsProps {
  openInNewTab?: boolean;
  owners?: string[];
}

export const REDIRECT_URL_KEY = "redirectUrl";
export type rentalApplicationTabsList = "overview" | "rental-application" | "reports" | "tasks" | "notes" | "files";

export const openNewTabAndFocus = (url: string) => {
  const win = window.open(url, "_blank");
  if (win !== null) {
    win.focus();
  }
};

const NavigationDefinitions = {
  [Routes.OWNERS_1099_REPORT]: {
    query: GetOwners1099ReportQuery,
    dto: Owners1099ReportItem
  },
  [Routes.VENDORS_1099_REPORT]: {
    query: GetVendors1099ReportQuery,
    dto: Vendors1099ReportItem
  },
  [Routes.PROPERTY_OWNERSHIP_REPORT]: {
    query: GetPropertyOwnershipQuery,
    dto: PropertyOwnershipReportItemDto
  },
  [Routes.OWNERS_REPORT]: {
    query: GetAllOwnersQuery,
    dto: OwnerDto
  }
} as const;

type QueryWithPeriod<DtoQuery extends Record<string, any>> = DtoQuery & {
  period: PeriodType;
};

function mergeQueries(query = "", targetQuery = window.location.search) {
  const result = qs.stringify({
    ...qs.parse(targetQuery),
    ...qs.parse(query)
  });

  if (!result) {
    return "";
  }

  return `?${result}`;
}

export function historyPushPersistentSearch(
  path: string | LocationDescriptorObject,
  state?: LocationState,
  action: "push" | "replace" = "push"
) {
  if (typeof path === "string") {
    const pathWithQuery = `${path}${window.location.search}`;
    action === "push" ? history.push(pathWithQuery, state) : history.replace(pathWithQuery, state);
    return;
  }

  const pathWithQuery = `${path.pathname}${mergeQueries(path.search)}`;
  action === "push" ? history.push(pathWithQuery, state) : history.replace(pathWithQuery, state);
}

export class Navigation {
  private TAX_FORM_ROUTES = {
    MISC: DialogRoutes.EXPORT_TAX_FORMS_1099_MISC,
    NEC: DialogRoutes.EXPORT_TAX_FORMS_1099_NEC
  };

  visitHelpCenter = () => {
    window.open(HelpCenterUrl, "_blank");
  };

  visitTenantHelpCenter = () => {
    window.open(HelpCenterTenantUrl, "_blank");
  };

  goToRelativeUrl(absoluteUrl: string) {
    const relativeUrl = new URL(absoluteUrl);
    history.replace(relativeUrl.pathname);
  }

  goTo<TNav extends keyof typeof NavigationDefinitions>(
    pathname: TNav,
    search?: InstanceType<(typeof NavigationDefinitions)[TNav]["query"]> & { groupBy?: string }
  ) {
    return history.push({
      pathname,
      search: search && qs.stringify(search),
      state: { lastRoute: location.pathname }
    });
  }

  addNewCharge = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_CHARGE);
  };

  signUp = (lastRoute?: string, searchQuery?: string) => {
    history.push({
      pathname: Routes.SIGNUP,
      state: {
        lastRoute
      },
      search: searchQuery
    });
  };

  forgotPassword = (lastRoute?: string) => {
    history.push(Routes.FORGOT_PASSWORD, { lastRoute });
  };

  forgotPasswordSent = (lastRoute?: string) => {
    history.push(Routes.FORGOT_PASSWORD_SENT, { lastRoute });
  };

  signIn = (lastRoute?: string) => {
    history.push(Routes.LOGIN, { lastRoute });
  };

  newSubscriptionPage = (units?: string, redirectUrl?: string) => {
    const queryString = qs.parse(window.location.search);
    if (units) {
      queryString["units"] = units;
    }
    if (redirectUrl) {
      queryString[REDIRECT_URL_KEY] = redirectUrl;
    }

    history.push({
      pathname: Routes.SUBSCRIPTION,
      search: qs.stringify(queryString)
    });
  };

  printAccountChecks = (accountId?: string, openInNewTab?: boolean) => {
    const queryString = qs.parse(window.location.search);
    if (accountId) {
      queryString["filter_payableInfoPayFromAccount"] = accountId;
    }

    const queryStringify = qs.stringify(queryString);

    if (openInNewTab) {
      openNewTabAndFocus(`${Routes.PRINT_CHECKS}?${queryStringify}`);
    } else {
      history.push({
        pathname: Routes.PRINT_CHECKS,
        search: queryStringify
      });
    }
  };

  viewChecksPreview = (accountId: string, checks: PrintChecksReportItemDto[], balance: number, amount: number) => {
    const queryString = qs.parse(window.location.search);
    if (accountId) {
      queryString["filter_payableInfoPayFromAccount"] = accountId;
    }

    history.push({
      pathname: Routes.PRINT_CHECKS_PREVIEW,
      state: { checks, balance, amount, accountId },
      search: qs.stringify(queryString)
    });
  };

  reviewChecksPrint = (checks: PrintChecksReportItemDto[]) => {
    history.push({
      pathname: Routes.PRINT_CHECKS_PRINT_REVIEW_PRINTING,
      state: { checks },
      search: window.location.search
    });
  };

  viewSubscriptionDetails = () => {
    history.push({
      pathname: Routes.SUBSCRIPTION_DETAILS
    });
  };

  subscriptionCehckout = (planName: string, units: string, priceId: string, planType: string) => {
    const queryString = qs.parse(window.location.search);

    if (planName) {
      queryString["planName"] = planName;
    }
    if (units) {
      queryString["units"] = units;
    }
    if (priceId) {
      queryString["priceId"] = priceId;
    }
    if (planType) {
      queryString["planType"] = planType;
    }

    history.push({
      pathname: Routes.SUBSCRIPTION_CHECKOUT,
      search: qs.stringify(queryString)
    });
  };

  startWelcomeWizard = (lastRoute?: string, redirectUrl?: string) => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
      return false;
    }
    history.push(Routes.WELCOME_WIZARD_STEP1, { lastRoute });
  };

  showWelcomeWizardStep2 = (lastRoute?: string) => {
    history.push(Routes.WELCOME_WIZARD_STEP2, { lastRoute });
  };

  showWelcomeWizardStep3 = (lastRoute?: string) => {
    history.push(Routes.WELCOME_WIZARD_STEP3, { lastRoute });
  };

  showWelcomeWizardStep4 = (lastRoute?: string) => {
    history.push(Routes.WELCOME_WIZARD_STEP4, { lastRoute });
  };

  editCharge = (
    locationPath: string,
    transactionId: string,
    leaseId?: string,
    openInNewTab?: boolean,
    searchQuery?: string
  ) => {
    const pathname = `${Routes.ACTIVE_LEASES}/${leaseId}/transactions/${DialogRoutes.EDIT_CHARGE}/${transactionId}`;
    if (openInNewTab && leaseId) {
      openNewTabAndFocus(pathname);
    } else {
      historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_CHARGE + "/" + transactionId, {
        search: searchQuery
      });
    }
  };

  editVendorBill = (locationPath: string, transactionId: string, searchQuery?: string) => {
    historyPushPersistentSearch({
      pathname: locationPath + "/" + DialogRoutes.EDIT_VENDOR_BILL + "/" + transactionId,
      search: searchQuery
    });
  };

  editVendorBillPayment = (locationPath: string, transactionId: string, searchQuery?: string) => {
    historyPushPersistentSearch({
      pathname: locationPath + "/" + DialogRoutes.EDIT_VENDOR_BILL_PAYMENT + "/" + transactionId,
      search: searchQuery
    });
  };

  editVendorCredit = (locationPath: string, transactionId: string, searchQuery?: string) => {
    historyPushPersistentSearch({
      pathname: locationPath + "/" + DialogRoutes.EDIT_VENDOR_CREDIT + "/" + transactionId,
      search: searchQuery
    });
  };

  editBankTransfer = (locationPath: string, transactionId: string, searchQuery?: string) => {
    historyPushPersistentSearch({
      pathname: locationPath + "/" + DialogRoutes.EDIT_BANK_TRANSFER + "/" + transactionId,
      search: searchQuery
    });
  };

  editExpense = (locationPath: string, transactionId: string, searchQuery?: string) => {
    historyPushPersistentSearch({
      pathname: locationPath + "/" + DialogRoutes.EDIT_EXPENSE + "/" + transactionId,
      search: searchQuery
    });
  };

  printSetup = (locationPath: string) => {
    historyPushPersistentSearch({
      pathname: locationPath + "/" + DialogRoutes.PRINT_SETUP
    });
  };

  printRemoveCheck = (locationPath: string, journalEntry: string, search?: string) => {
    historyPushPersistentSearch({
      pathname: locationPath + "/" + DialogRoutes.PRINT_REMOVE_CHECK,
      search: mergeQueries(`&journalEntry=${journalEntry}`, search || "")
    });
  };

  addNewCredit = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_CREDIT);
  };

  editCredit = (
    locationPath: string,
    transactionId: string,
    leaseId?: string,
    openInNewTab?: boolean,
    searchQuery?: string
  ) => {
    if (openInNewTab && leaseId) {
      openNewTabAndFocus(
        Routes.ACTIVE_LEASES + "/" + leaseId + "/transactions/" + DialogRoutes.EDIT_CREDIT + "/" + transactionId
      );
    } else {
      historyPushPersistentSearch({
        pathname: locationPath + "/" + DialogRoutes.EDIT_CREDIT + "/" + transactionId,
        search: searchQuery
      });
    }
  };

  editPayment = (locationPath: string, transactionId: string, searchQuery?: string) => {
    historyPushPersistentSearch({
      pathname: locationPath + "/" + DialogRoutes.EDIT_PAYMENT + "/" + transactionId,
      search: searchQuery
    });
  };

  editAppliedCredit = (locationPath: string, transactionId: string, searchQuery?: string) => {
    historyPushPersistentSearch({
      pathname: `${locationPath}/${DialogRoutes.EDIT_APPLIED_CREDIT}/${transactionId}`,
      search: searchQuery
    });
  };

  editReversedPayment = async (locationPath: string, transactionId: string, searchQuery?: string) => {
    const reversePaymentResonse = await leaseReversedPaymentsApi.get(transactionId).catch(() => {});
    if (reversePaymentResonse && reversePaymentResonse?.status && reversePaymentResonse.data?.leasePayment) {
      historyPushPersistentSearch({
        pathname: locationPath + "/" + DialogRoutes.EDIT_PAYMENT + "/" + reversePaymentResonse.data.leasePayment,
        search: searchQuery
      });
    }
  };

  addNewDeposit = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_LEASE_DEPOSIT);
  };

  addNewReccuringCharge = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_RECURRING_CHARGE);
  };

  addNewBulkCharges = (locationPath?: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_BULK_CHARGE);
  };

  addNewBulkCredits = (locationPath?: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_BULK_CREDIT);
  };

  addNewReccuringPayment = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_RECURRING_PAYMENT);
  };

  addNewReccuringCredit = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_RECURRING_CREDIT);
  };

  addNewReccuringRent = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_RECURRING_RENT);
  };

  viewLeaseRenewals = (query?: string) => {
    history.push({
      pathname: Routes.LEASE_RENEWALS,
      search: query
    });
  };

  exportTaxForm1099(type: "MISC" | "NEC", selection?: TaxForm1099Dto[]) {
    const locationSearch = qs.parse(window.location.search);
    this.navigateChild({
      path: this.TAX_FORM_ROUTES[type],
      search: { type, ids: selection?.map(({ id }) => id), ...locationSearch },
      state: { selection }
    });
  }

  viewCommunication = (communicationId: string) => {
    historyPushPersistentSearch(DialogRoutes.VIEW_COMMUNICATION + "/" + communicationId);
  };

  viewAnnouncements = () => {
    history.push({
      pathname: Routes.ANNOUNCEMENTS
    });
  };

  viewAnnouncementDetails = (announcementId: string) => {
    history.push({
      pathname: Routes.ANNOUNCEMENTS + "/" + announcementId + "/overview"
    });
  };

  editAnnouncement = (announcementId: string, currentTab = "overview") => {
    history.push({
      pathname: `${Routes.ANNOUNCEMENTS}/${announcementId}/${currentTab}/edit`
    });
  };

  showRenewalOfferDialog = (locationPath: string, leaseData: LeaseDto, propertyName: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.RENEWAL_OFFER, {
      leaseData,
      propertyName
    });
  };

  showNotRenewingDialog = (locationPath: string, leaseData: LeaseDto, propertyName: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NOT_RENEWING_OFFER, {
      leaseData,
      propertyName
    });
  };

  addNewPayment = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_PAYMENT);
  };

  addNewRefund = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_REFUND);
  };

  editRefund = (locationPath: string, transactionId: string, searchQuery?: string) => {
    historyPushPersistentSearch({
      pathname: locationPath + "/" + DialogRoutes.EDIT_REFUND + "/" + transactionId,
      search: searchQuery
    });
  };

  editGeneralEntry = (locationPath: string, transactionId: string, searchQuery?: string) => {
    historyPushPersistentSearch({
      pathname: locationPath + "/" + DialogRoutes.EDIT_GENERAL_ENTRY + "/" + transactionId,
      search: searchQuery
    });
  };

  viewDepositFromDialog = (depositEntry: string, dialogRoute: DialogRoutes) => {
    let nextPathName = location.pathname;
    const locationPathDialogIndex = location.pathname.indexOf(dialogRoute);
    if (locationPathDialogIndex > -1) {
      nextPathName = location.pathname.slice(0, locationPathDialogIndex - 1);
    }
    const queryString = qs.parse(location.search);
    queryString["dialogReturnPath"] = nextPathName;
    this.editBankDeposit(nextPathName, depositEntry, qs.stringify(queryString));
  };

  refundDeposit = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.REFUND_DEPOSIT);
  };

  WithholdDeposit = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.WITHHOLD_DEPOSIT);
  };

  editRecurringGeneralEntry = (locationPath: string, transactionId: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_RECURRING_GENERAL_ENTRY + "/" + transactionId);
  };
  editRecurringCharge = (locationPath: string, transactionId: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_RECURRING_CHARGE + "/" + transactionId);
  };

  editRecurringCredit = (locationPath: string, transactionId: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_RECURRING_CREDIT + "/" + transactionId);
  };

  editRecurringPayment = (locationPath: string, transactionId: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_RECURRING_PAYMENT + "/" + transactionId);
  };

  editRecurringRent = (locationPath: string, transactionId: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_RECURRING_RENT + "/" + transactionId);
  };

  editRecurring = (
    type: RecurringTransactionType,
    locationPath: string,
    transactionId: string,
    isWorkOrder = false
  ) => {
    if (!isWorkOrder) {
      const actionByType: Record<RecurringTransactionType, (locationPath: string, transactionId: string) => void> = {
        [RecurringTransactionType.LEASE_CHARGE]: this.editRecurringCharge,
        [RecurringTransactionType.LEASE_CREDIT]: this.editRecurringCredit,
        [RecurringTransactionType.LEASE_PAYMENT]: this.editRecurringPayment,
        [RecurringTransactionType.LEASE_RENT]: this.editRecurringRent,
        [RecurringTransactionType.TASK]: this.editRecurringTask,
        [RecurringTransactionType.EXPENSE]: this.editRecurringExpense,
        [RecurringTransactionType.VENDOR_BILL]: this.editRecurringVendorBill,
        [RecurringTransactionType.GENERAL_ENTRY]: this.editRecurringGeneralEntry
      };
      actionByType[type](locationPath, transactionId);
    } else {
      this.editRecurringWorkOrder(locationPath, transactionId);
    }
  };

  showConfirmDialog = (locationPath: string) => {
    const nextPath =
      locationPath.indexOf(DialogRoutes.CONFIRM) === -1 ? locationPath + DialogRoutes.CONFIRM : locationPath;

    historyPushPersistentSearch(nextPath, {});
  };

  showAlertDialog = (locationPath: string, alertData: AlertDialogProps) => {
    historyPushPersistentSearch(locationPath + DialogRoutes.ALERT, { alertData });
  };

  moveInTenant = (locationPath: string, leaseData?: LeaseDto) => {
    const locationParts = locationPath.split("/");
    if (locationParts.length > 0) {
      const lastRoute = locationParts[locationParts.length - 1];
      if (lastRoute === "tenants") {
        historyPushPersistentSearch(locationPath + "/move-in", {
          leaseData
        });
      } else {
        historyPushPersistentSearch(locationPath + "/" + DialogRoutes.MOVE_IN_TENANTS, {
          leaseData
        });
      }
    }
  };

  moveOutTenant = (
    locationPath: string,
    {
      leaseData,
      defaultCurrentTenantsSelection = []
    }: {
      leaseData: LeaseDto;
      defaultCurrentTenantsSelection?: DisplayLeaseDraftTenantDto[];
    }
  ): void => {
    const locationParts = locationPath.split("/");

    if (locationParts.length <= 0) {
      return;
    }

    const lastRoute = locationParts[locationParts.length - 1];

    let location: string;

    if (lastRoute === "tenants") {
      location = `${locationPath}/move-out`;
    } else {
      location = `${locationPath}/${DialogRoutes.MOVE_OUT_TENANTS}${window.location.search}`;
    }

    historyPushPersistentSearch(location, {
      leaseData,
      defaultCurrentTenantsSelection
    });
  };

  viewLeaseDetails = (leaseId: string) => {
    history.push(Routes.ACTIVE_LEASES + "/" + leaseId + "/overview", {});
  };

  getLeaseDetailsOverviewRoute = (leaseId: string) => `${Routes.ACTIVE_LEASES}/${leaseId}/overview`;

  viewLeaseTenants = (leaseId: string) => {
    history.push(Routes.ACTIVE_LEASES + "/" + leaseId + "/tenants", {});
  };

  viewLeaseTransactions = (leaseId: string) => {
    history.push(Routes.ACTIVE_LEASES + "/" + leaseId + "/transactions", {});
  };

  viewDraftLeaseDetails = (leaseId: string) => {
    history.push(Routes.LEASES + "/draft-leases/" + leaseId + "/overview", {});
  };

  viewDraftLeases = () => {
    history.push(Routes.DRAFT_LEASES);
  };

  getDraftLeaseDetailsOverviewRoute = (leaseId: string) => `${Routes.LEASES}/draft-leases/${leaseId}/overview`;

  viewHome = (hardRefresh = false) => {
    if (!hardRefresh) {
      history.push(Routes.HOME);
    } else {
      window.location.href = new URL(Routes.HOME, location.origin).toString();
    }
  };

  viewProperties = () => {
    history.push(Routes.PROPERTIES);
  };

  viewBankAccounts = (hardRefresh?) => {
    if (hardRefresh) {
      window.location.href = new URL(Routes.BOOKKEEPING_TRANSACTIONS, location.origin).toString();
    } else {
      history.push(Routes.BOOKKEEPING_TRANSACTIONS);
    }
  };

  viewOnlinePayments = (hardRefresh?) => {
    if (hardRefresh) {
      window.location.href = new URL(Routes.INCOMING_PAYMENTS, location.origin).toString();
    } else {
      history.push(Routes.INCOMING_PAYMENTS);
    }
  };

  viewMerchantAccountSetup = () => {
    history.push(Routes.INCOMING_PAYMENTS_MERCHANT_ACCOUNT_SETUP);
  };

  viewBanking = () => {
    history.push(Routes.BOOKKEEPING_TRANSACTIONS);
  };

  viewPropertyDetails = (propertyId: string, nextSelectedTabName?: string, query = "") => {
    if (nextSelectedTabName) {
      history.push(Routes.PROPERTIES + "/" + propertyId + "/" + nextSelectedTabName + query);
    } else {
      history.push(Routes.PROPERTIES + "/" + propertyId + "/overview" + query);
    }
  };

  getPropertyDetailsOverviewRoute = (propertyId: string) => `${Routes.PROPERTIES}/${propertyId}/overview`;

  viewPropertyReservedFundsSettings = (propertyId: string) => {
    historyPushPersistentSearch(
      `${Routes.REPORTS}/property/reserve-funds/${DialogRoutes.EDIT_RESERVE_FUNDS}/${propertyId}`
    );
  };

  viewUnitDetails = (unitId: string, nextSelectedTabName?: string) => {
    history.push(Routes.UNITS + "/" + unitId + "/" + (nextSelectedTabName || "overview"));
  };

  getUnitDetailsOverviewRoute = (unitId: string) => `${Routes.UNITS}/${unitId}/overview`;

  viewTenantDetails = (tenantId: string) => {
    history.push(Routes.TENANTS + "/" + tenantId + "/overview");
  };

  getTenantDetailsOverviewRoute = (tenantId: string) => `${Routes.TENANTS}/${tenantId}/overview`;

  getTenantDetailsRentalApplicationsRoute = (tenantId: string) => `${Routes.TENANTS}/${tenantId}/rental-applications`;

  viewActiveLeases = (query?: GetAllLeasesQuery) => {
    history.push({
      pathname: Routes.ACTIVE_LEASES,
      search: qs.stringify(query || {})
    });
  };

  viewTenants = (query?: string) => {
    history.push({
      pathname: Routes.TENANTS,
      search: query
    });
  };

  viewUnits = (query?: string) => {
    history.push({
      pathname: Routes.UNITS,
      search: query
    });
  };

  viewUnitsAvailableForApplications = () => {
    history.push({
      pathname: Routes.UNITS,
      search: qs.stringify({ filter_currentStatusOccupied: false })
    });
  };

  viewVendorDetails = (vendorId: string, tabName?: string) => {
    let nextTabName = "overview";
    if (tabName) {
      nextTabName = tabName;
    }
    history.push(Routes.VENDORS + "/" + vendorId + "/" + nextTabName);
  };

  getVendorDetailsOverviewRoute = (vendorId: string) => `${Routes.VENDORS}/${vendorId}/overview`;

  viewOpenBills = () => {
    history.push(Routes.ACCOUNTS_PAYABLE_AGING_SUMMARY);
  };

  viewProspects = (query?: string) => {
    history.push({
      pathname: Routes.PROSPECTS,
      search: query
    });
  };

  viewProspectDetails = (prospectId: string, tabName?: string) => {
    let nextTabName = "overview";
    if (tabName) {
      nextTabName = tabName;
    }
    history.push(Routes.PROSPECTS + "/" + prospectId + "/" + nextTabName);
  };

  getProspectDetailsOverviewRoute = (prospectId: string) => `${Routes.PROSPECTS}/${prospectId}/overview`;

  getProspectDetailsRentalApplicationsRoute = (prospectId: string) =>
    `${Routes.PROSPECTS}/${prospectId}/rental-applications`;

  viewOwnerDetails = (ownerId: string, tabName?: string) => {
    let nextTabName = "overview";
    if (tabName) {
      nextTabName = tabName;
    }
    history.push(Routes.OWNERS + "/" + ownerId + "/" + nextTabName);
  };

  getOwnerDetailsOverviewRoute = (ownerId: string) => `${Routes.OWNERS}/${ownerId}/overview`;

  addNewOwner = (locationPath: string) => {
    const locationParts = locationPath.split("/");
    if (locationParts.length > 0) {
      const lastRoute = locationParts[locationParts.length - 1];
      if (lastRoute === "owners") {
        historyPushPersistentSearch(locationPath + "/new");
      } else {
        historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_OWNER);
      }
    }
  };

  editOwner = (locationPath: string, ownerId: string, tabName = "") => {
    const locationParts = locationPath.split("/");
    if (locationParts.length > 0) {
      const lastRoute = locationParts[locationParts.length - 1];
      const tabPostfix = tabName ? `/${tabName}` : "";
      if (lastRoute === "owners") {
        history.push(locationPath + "/edit/" + ownerId + tabPostfix);
      } else {
        history.push(locationPath + "/owners/edit/" + ownerId + tabPostfix);
      }
    }
  };

  addNewVendor = (locationPath: string) => {
    const locationParts = locationPath.split("/");
    if (locationParts.length > 0) {
      const lastRoute = locationParts[locationParts.length - 1];
      if (lastRoute === "vendors") {
        historyPushPersistentSearch(locationPath + "/new");
      } else {
        historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_VENDOR);
      }
    }
  };

  editVendor = (locationPath: string, vendorId: string, tabName = "") => {
    const locationParts = locationPath.split("/");
    if (locationParts.length > 0) {
      const lastRoute = locationParts[locationParts.length - 1];
      const tabPostfix = tabName ? `/${tabName}` : "";
      if (lastRoute === "vendors") {
        history.push(locationPath + "/edit/" + vendorId + tabPostfix);
      } else {
        history.push(locationPath + "/vendors/edit/" + vendorId + tabPostfix);
      }
    }
  };

  addNewProspect = (locationPath: string) => {
    const locationParts = locationPath.split("/");
    if (locationParts.length > 0) {
      const lastRoute = locationParts[locationParts.length - 1];
      if (lastRoute === "prospects") {
        historyPushPersistentSearch(locationPath + "/new");
      } else {
        historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_PROSPECT);
      }
    }
  };

  editProspect = (locationPath: string, prospectId: string) => {
    const locationParts = locationPath.split("/");
    if (locationParts.length > 0) {
      const lastRoute = locationParts[locationParts.length - 1];
      if (lastRoute === "prospects") {
        history.push(locationPath + "/edit/" + prospectId);
      } else {
        history.push(locationPath + "/prospects/edit" + prospectId);
      }
    }
  };

  editTenant = (locationPath: string, tenantId: string, tabName = "") => {
    const locationParts = locationPath.split("/");
    if (locationParts.length > 0) {
      const lastRoute = locationParts[locationParts.length - 1];
      const tabPostfix = tabName ? `/${tabName}` : "";
      if (lastRoute === "tenants") {
        history.push(locationPath + "/edit/" + tenantId + tabPostfix);
      } else {
        history.push(locationPath + "/tenants/edit/" + tenantId + tabPostfix);
      }
    }
  };

  newBoardMember(locationPath: string) {
    historyPushPersistentSearch(`${locationPath}/${DialogRoutes.NEW_BOARD_MEMBER}`);
  }

  editBoardMembership(locationPath: string, boardMemberId: string) {
    historyPushPersistentSearch(`${locationPath}/${DialogRoutes.EDIT_BOARD_MEMBER}/${boardMemberId}`);
  }

  removeBoardTerm(locationPath: string, boardMemberId: string) {
    historyPushPersistentSearch(`${locationPath}/${DialogRoutes.REMOVE_BOARD_TERM}/${boardMemberId}`);
  }

  endLease = (leaseId: string, leaseData: LeaseDto, propertyName?: string) => {
    history.push(Routes.LEASES + "/end-lease/" + leaseId + "/terms", {
      leaseData,
      propertyName
    });
  };

  createNewDraftLease = (propertyId?: string, unitId?: string, redirectUrl?: string, tenantId?: string) => {
    const searchQuery: Record<string, string> = {};
    if (redirectUrl) {
      searchQuery[REDIRECT_URL_KEY] = redirectUrl;
    }
    if (tenantId) {
      searchQuery.tenantId = tenantId;
    }

    history.push({
      pathname: Routes.NEW_DRAFT_LEASE,
      state: {
        selectedProperty: propertyId,
        selectedUnit: unitId
      },
      search: qs.stringify(searchQuery)
    });
  };

  editDraftLease = (id: string, screenData?: LeaseDraftDto, initialStep?: number) => {
    history.push(Routes.LEASES + "/draft-leases/edit-lease/" + id, {
      draftLeaseData: screenData,
      initialStep
    });
  };

  renewLease = (leaseId: string, leaseData: LeaseDto, propertyName: string) => {
    history.push(Routes.LEASES + "/renew/" + leaseId + "/overview", {
      leaseData,
      propertyName
    });
  };

  viewSendPayments = (openInNewTab?: boolean) => {
    if (openInNewTab) {
      openNewTabAndFocus(Routes.OUTGOING_PAYMENTS);
    } else {
      history.push(Routes.OUTGOING_PAYMENTS);
    }
  };

  editOutgoingPaymentsSettings = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_OUTGOING_PAYMENTS);
  };

  createNewVendorBill = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_VENDOR_BILL);
  };

  createNewVendorCredit = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_VENDOR_CREDIT);
  };

  createNewVendorExpense = (
    locationPath: string,
    paymentMethod?: PaymentMethod,
    accountId?: string,
    payeeId?: string,
    payeeType?: PersonType
  ) => {
    const queryString = qs.parse(window.location.search);

    if (paymentMethod) {
      queryString["paymentMethod"] = paymentMethod;
    }
    if (accountId) {
      queryString["accountId"] = accountId;
    }
    if (payeeId) {
      queryString["payeeId"] = payeeId;
    }
    if (payeeType) {
      queryString["payeeType"] = payeeType;
    }
    historyPushPersistentSearch({
      pathname: locationPath + "/" + DialogRoutes.NEW_VENDOR_EXPENSE,
      search: qs.stringify(queryString)
    });
  };

  createNewOwnerContribution = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_OWNER_CONTRIBUTION);
  };

  editOwnerContribution = (locationPath: string, transactionId: string, searchQuery?: string) => {
    historyPushPersistentSearch({
      pathname: locationPath + "/" + DialogRoutes.EDIT_OWNER_CONTRIBUTION + "/" + transactionId,
      search: searchQuery
    });
  };

  payBills = (vendorId?: string, batchId?: string, openInNewTab?: boolean) => {
    const filterObject = {};
    if (vendorId) {
      filterObject["vendorId"] = vendorId;
    }
    if (batchId) {
      filterObject["filter_batch"] = batchId;
    }
    if (openInNewTab) {
      openNewTabAndFocus(`${Routes.PAY_BILLS}?${qs.stringify(filterObject)}`);
    } else {
      history.push({
        pathname: Routes.PAY_BILLS,
        search: qs.stringify(filterObject)
      });
    }
  };

  newProperty = (redirectUrl?: string) => {
    const searchQuery: Record<string, string> = {};
    if (redirectUrl) {
      searchQuery.redirectUrl = redirectUrl;
    }

    history.push({
      pathname: Routes.NEW_PROPERTY,
      search: qs.stringify(searchQuery)
    });
  };

  createOwnerDistributions = ({ owners, openInNewTab }: CreateOwnerDistributionsProps) => {
    const routerParams: any = {};

    if (owners?.length) {
      routerParams.owners = owners;
    }

    if (openInNewTab) {
      openNewTabAndFocus(`${Routes.OWNERS_DISTRIBUTIONS}?${qs.stringify(routerParams)}`);
    } else {
      history.push(Routes.OWNERS_DISTRIBUTIONS, routerParams);
    }
  };

  editPropertyOwner = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_PROPERTY_OWNERS);
  };

  editProperty = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_PROPERTY);
  };

  editPropertyDialog = (propertyId: string, tabName?: string) => {
    historyPushPersistentSearch(
      `${Routes.PROPERTIES}/${propertyId}/settings/edit/property${tabName ? `/${tabName}` : ""}`
    );
  };

  editUnitDialog = (unitId: string, tabName?: string) => {
    historyPushPersistentSearch(`${Routes.UNITS}/${unitId}/overview/edit${tabName ? `/${tabName}` : ""}`);
  };

  editBankAccounts = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_BANK_ACCOUNTS);
  };

  editReserveFunds = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_RESERVE_FUNDS);
  };

  editManagementFees = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_MANAGEMENT_FEES);
  };

  editPaymentAllocations = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_PAYMENT_ALLOCATIONS);
  };

  editLateFees = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_LATE_FEES);
  };

  editTenantPortal = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_TENANT_PORTAL);
  };

  editOwnerPortal(locationPath: string): void {
    historyPushPersistentSearch(`${locationPath}/${DialogRoutes.EDIT_OWNER_PORTAL}`);
  }

  editLeaseTenantPortal = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_LEASE_TENANT_PORTAL);
  };

  editRentersInsurance = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_RENTERS_INSURANCE);
  };

  editLeaseName = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_LEASE_NAME);
  };

  editRentalApplcaitionsSettings = (locationPath?: string, propertyId?: string) => {
    if (propertyId) {
      historyPushPersistentSearch(
        `/properties/${propertyId}/settings/${DialogRoutes.EDIT_RENTAL_APPLICATIONS_SETTINGS}`
      );
    } else {
      historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_RENTAL_APPLICATIONS_SETTINGS);
    }
  };

  editConvenienceFeesSettings = (locationPath?: string, propertyId?: string) => {
    if (propertyId) {
      historyPushPersistentSearch(`/properties/${propertyId}/settings/${DialogRoutes.EDIT_CONVENIENCE_FEES_SETTINGS}`);
    } else {
      historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_CONVENIENCE_FEES_SETTINGS);
    }
  };

  editPaymentsAllocation = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_PAYMENTS_ALLOCATION);
  };

  editRentReminders = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_RENT_REMINDERS);
  };

  editPaymentInstructions = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_PAYMENT_INSTRUCTIONS);
  };

  editTenantRequestNotifications = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_TENANT_REQUEST_NOTIFICATIONS);
  };

  editOwnerRequestNotifications = (locationPath: string): void => {
    historyPushPersistentSearch(`${locationPath}/${DialogRoutes.EDIT_OWNER_REQUEST_NOTIFICATIONS}`);
  };

  editLease = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_LEASE);
  };

  editEvictionStatus = (locationPath: string) => {
    historyPushPersistentSearch(`${locationPath}/${DialogRoutes.EDIT_EVICTION_STATUS}`);
  };

  addNewInternalTask = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_INTERNAL_TASK);
  };

  editInternalTask = (locationPath: string, taskId: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_INTERNAL_TASK + "/" + taskId);
  };

  editRecurringTask = (locationPath: string, recurringTaskId: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_RECURRING_TASK + "/" + recurringTaskId);
  };

  editRecurringWorkOrder = (locationPath: string, recurringTaskId: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_RECURRING_WORK_ORDER + "/" + recurringTaskId);
  };

  editRecurringExpense = (locationPath: string, recurringExpenseId: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_RECURRING_EXPENSE + "/" + recurringExpenseId);
  };

  editRecurringVendorBill = (locationPath: string, recurringVendorBillId: string) => {
    historyPushPersistentSearch(
      locationPath + "/" + DialogRoutes.EDIT_RECURRING_VENDOR_BILL + "/" + recurringVendorBillId
    );
  };

  postTaskUpdate = (locationPath: string, taskId: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.POST_TASK_UPDATE + "/" + taskId);
  };

  addNewOwnerRequest = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_OWNER_REQUEST);
  };

  editOwnerRequest = (locationPath: string, taskId: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_OWNER_REQUEST + "/" + taskId);
  };

  addNewTenantRequest = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_TENANT_REQUEST);
  };

  editTenantRequest = (locationPath: string, taskId: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_TENANT_REQUEST + "/" + taskId);
  };

  viewTenantRequests = (searchQuery?: Record<string, string>) =>
    history.push({
      pathname: Routes.TENANT_REQUESTS,
      search: searchQuery ? qs.stringify(searchQuery) : undefined
    });

  addNewWorkOrder = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_WORK_ORDER);
  };

  editVendorServices = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_SERVICES);
  };

  editWorkOrder = (locationPath: string, taskId: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_WORK_ORDER + "/" + taskId);
  };

  viewFileDetails = (locationPath: string, fileDetails: FileDisplayDto) => {
    historyPushPersistentSearch({
      pathname: locationPath + "/" + DialogRoutes.EDIT_FILE,
      state: fileDetails
    });
  };

  viewNoteDetails = (locationPath: string, noteDetails: NoteDtoDisplay) => {
    historyPushPersistentSearch({
      pathname: locationPath + "/" + DialogRoutes.EDIT_NOTE,
      state: noteDetails
    });
  };

  viewTaskDetails = (taskId: string, tabName?: string, locationPath?: string) => {
    let nextTabName = "overview";
    if (tabName) {
      nextTabName = tabName;
    }

    const pathBase = locationPath || Routes.TASKS;
    history.push(pathBase + "/" + taskId + "/" + nextTabName);
  };

  getTaskDetailsOverviewRoute = (taskId: string) => `${Routes.TASKS}/${taskId}/overview`;

  viewMyTasks = (query?: string) => {
    history.push({
      pathname: Routes.MY_TASKS,
      search: query
    });
  };

  viewTasks = (query?: string) => {
    history.push({
      pathname: Routes.TASKS,
      search: query
    });
  };

  viewIncompleteTasks = (query?: string) => {
    history.push({
      pathname: Routes.INCOMPLETE_TASKS,
      search: query
    });
  };

  createNew = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.CREATE_NEW);
  };

  newAccount = (locationPath: string) => {
    const locationParts = locationPath.split("/");
    if (locationParts.length > 0) {
      const lastRoute = locationParts[locationParts.length - 1];
      if (lastRoute === "chart-of-accounts") {
        historyPushPersistentSearch(locationPath + "/new");
      } else {
        historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_CHART_OF_ACCOUNT);
      }
    }
    analyticsService.track(SegmentEventTypes.RECEIVE_PAYMENTS_NEW_ACCOUNT_CTA_CLICKED, undefined, {
      trackEventInIntercom: true
    });
  };

  editAccount = (locationPath: string, accountId: string, tabName?: string) => {
    const locationParts = locationPath.split("/");
    if (locationParts.length > 0) {
      const lastRoute = locationParts[locationParts.length - 1];
      if (lastRoute === "chart-of-accounts") {
        history.push(locationPath + "/edit/" + accountId);
      } else {
        let nextPath = `${locationPath}/chart-of-accounts/edit/${accountId}`;
        if (tabName) {
          nextPath += `/${tabName}`;
        }
        history.push(nextPath);
      }
    }
  };

  addNewUnit = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_UNIT);
  };

  addNewUser = (locationPath: string) => {
    const locationParts = locationPath.split("/");
    if (locationParts.length > 0) {
      const lastRoute = locationParts[locationParts.length - 1];
      if (lastRoute === "company-users") {
        historyPushPersistentSearch(locationPath + "/new");
      } else {
        historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_USER);
      }
    }
  };

  editUser = (locationPath: string, userId: string, searchQuery?: Record<string, string>) => {
    const locationParts = locationPath.split("/");
    if (locationParts.length > 0) {
      const lastRoute = locationParts[locationParts.length - 1];
      if (lastRoute === "company-users") {
        history.push({
          pathname: locationPath + "/edit/" + userId,
          search: qs.stringify({ ...searchQuery })
        });
      } else {
        history.push({
          pathname: locationPath + "/users/edit/" + userId,
          search: qs.stringify({ ...searchQuery })
        });
      }
    }
  };

  addNewLeaseInsurancePolicy = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_INSURANCE_POLICY);
  };

  editLeaseInsurancePolicy = (locationPath: string, policyId: string) =>
    historyPushPersistentSearch(`${locationPath}/${DialogRoutes.EDIT_INSURANCE_POLICY}/${policyId}`);

  addNewUserRole = () => {
    history.pushSteps([Routes.USER_ROLES, Routes.NEW]);
  };

  editUserRole = (locationPath: string, roleId: string) => {
    history.pushSteps([Routes.USER_ROLES, makeEditRoute(roleId)]);
  };

  acceptInviteNewLogin = (locationPath: string, email?: string, isTOSRequired?: boolean) => {
    // const base64Email = email ? btoa(email) : "";
    // use new Base64 api
    const base64Email = email ? encodeURIComponent(btoa(email)) : "";
    history.push(
      {
        pathname: locationPath + "/new-login",
        search: qs.stringify({ email: base64Email, isTOSRequired })
      },
      { lastRoute: Routes.ACCEPT_INVITE }
    );
  };

  acceptInviteExistingLogin = (locationPath: string, email?: string, isTOSRequired?: boolean) => {
    const base64Email = email ? encodeURIComponent(btoa(email)) : "";
    history.push(
      {
        pathname: locationPath + "/existing-login",
        search: qs.stringify({ email: base64Email, isTOSRequired })
      },
      { lastRoute: Routes.ACCEPT_INVITE }
    );
  };

  showInviteInvalidToken = () => {
    history.push(Routes.ACCEPT_INVITE);
  };

  editPersonalInformation = () => {
    historyPushPersistentSearch(DialogRoutes.EDIT_PERSONAL_INFORMATION);
  };

  editLoginAndPassword = (pathname = "") => {
    if (pathname) {
      historyPushPersistentSearch(`${pathname}/${DialogRoutes.EDIT_LOGIN_AND_PASSWORD}`);
    } else {
      historyPushPersistentSearch(DialogRoutes.EDIT_LOGIN_AND_PASSWORD);
    }
  };

  editProspectInfo = (id) => {
    historyPushPersistentSearch(`${DialogRoutes.EDIT_PROSPECT}/${id}`);
  };

  editCompanyInformation = (locationPath?: string) => {
    historyPushPersistentSearch((locationPath ? `${locationPath}/` : "") + DialogRoutes.EDIT_COMPANY_INFORMATION);
  };

  editCompanyStartDate = () => {
    historyPushPersistentSearch(DialogRoutes.EDIT_COMPANY_START_DATE);
  };

  editRegionAndCurrency = () => {
    historyPushPersistentSearch(DialogRoutes.EDIT_REGION_AND_CURRENCY);
  };

  editRegionAndCurrencyWithAbsolute = () => {
    historyPushPersistentSearch(`/settings/${DialogRoutes.EDIT_REGION_AND_CURRENCY}`);
  };

  editDefaultAccounts = () => {
    historyPushPersistentSearch(DialogRoutes.EDIT_DEFAULT_ACCOUNTS);
  };

  viewUserRoles = () => {
    history.push(Routes.USER_ROLES);
  };

  viewCompanyUsers = () => {
    history.push(Routes.SETTINGS_COMPANY_USERS);
  };

  editTaskCategories = () => {
    history.push(Routes.EDIT_TASK_CATEGORIES);
  };

  editNoteCategories = () => {
    history.push(Routes.EDIT_NOTE_CATEGORIES);
  };

  editFileCategories = () => {
    history.push(Routes.EDIT_FILE_CATEGORIES);
  };

  editVendorCategories = () => {
    history.push(Routes.EDIT_VENDOR_CATEGORIES);
  };

  editLeadSource = () => {
    history.push(Routes.EDIT_LEAD_SOURCE);
  };

  editPropertyGroups = () => {
    history.push(Routes.EDIT_PORTFOLIOS);
  };

  editCompanyLateFees = () => {
    history.push(Routes.EDIT_COMPANY_LATE_FEES);
  };

  runLeaseInsuranceSummaryReport = (searchQuery?: Record<string, string>) =>
    history.push({
      pathname: Routes.LEASE_INSURANCE_SUMMARY_REPORT,
      search: searchQuery ? qs.stringify(searchQuery) : undefined
    });

  runBudgetVsActualReport = (searchQuery?: Partial<BudgetVsActualReportForm>) =>
    history.push({
      pathname: Routes.BUDGET_VS_ACTUAL_REPORT,
      search: searchQuery ? qs.stringify(searchQuery) : undefined
    });

  runLeaseInsuranceDetailsReport = (searchQuery?: Record<string, string>) =>
    history.push({
      pathname: Routes.LEASE_INSURANCE_DETAILS_REPORT,
      search: searchQuery ? qs.stringify(searchQuery) : undefined
    });

  getLeaseInsuranceDetailsReportRoute = (searchQuery?: Record<string, string>) =>
    `${Routes.LEASE_INSURANCE_DETAILS_REPORT}${searchQuery ? `?${qs.stringify(searchQuery)}` : ""}`;

  runTransactionsDetailReport = (searchQuery?: Record<string, string>, defaultAccountMethod?: AccountingMethod) => {
    const _searchQuery: Record<string, string> = {
      ...searchQuery,
      filter_accountingMethod: defaultAccountMethod || AccountingMethod.CASH
    };
    history.push({
      pathname: Routes.TRANSACTION_DETAILS_REPORT,
      search: qs.stringify({ ...searchQuery, ..._searchQuery })
    });
  };

  getTransactionsDetailReportRoute = (searchQuery?: Record<string, string>) => {
    const scopedQuery: Record<string, string> = {
      ...searchQuery,
      filter_accountingMethod: AccountingMethod.CASH
    };
    const query = qs.stringify(scopedQuery);

    return `${Routes.TRANSACTION_DETAILS_REPORT}?${query}`;
  };

  runAccountTransactionsReport = (accountId?: string, searchQuery?: Record<string, string>) => {
    const _searchQuery: Record<string, string> = {
      filter_accountingMethod: AccountingMethod.CASH
    };
    if (accountId) {
      _searchQuery["filter_account"] = accountId;
    }

    history.push({
      pathname: Routes.TRANSACTION_DETAILS_REPORT,
      search: qs.stringify({ ..._searchQuery, ...searchQuery })
    });
  };

  runDepositDetailReport = (propertyId?: string) => {
    const query: QueryWithPeriod<GetDepositDetailReportQuery> = {
      period: "last-7-days",
      ...(propertyId ? { filter_property: propertyId } : {})
    };
    history.push({
      pathname: Routes.DEPOSIT_DETAIL_REPORT,
      search: qs.stringify(query)
    });
  };

  getAccountTransactionsReportRoute = (accountId?: string, searchQuery?: Record<string, string>) => {
    if (searchQuery?.filter_propertyOwner) {
      searchQuery.filter_owner = searchQuery.filter_propertyOwner;
      delete searchQuery.filter_propertyOwner;
    }

    const scopedSearchQuery: Record<string, string> = {
      ...searchQuery,
      filter_accountingMethod: searchQuery?.filter_accountingMethod || AccountingMethod.CASH
    };

    if (accountId) {
      scopedSearchQuery.filter_account = accountId;
    }

    return `${Routes.TRANSACTION_DETAILS_REPORT}?${qs.stringify(scopedSearchQuery)}`;
  };

  runBankBalanceByPropertyReport = (searchQuery?: Record<string, string>, newWindow?: boolean) => {
    if (newWindow) {
      const win = window.open(`${Routes.BANK_BALANCE_BY_PROPERTY}/?${qs.stringify(searchQuery || {})}`, "_blank");
      if (win !== null) {
        win.focus();
      }
    } else {
      history.push({
        pathname: Routes.BANK_BALANCE_BY_PROPERTY,
        search: qs.stringify(searchQuery || {})
      });
    }
  };

  runProfitAndLossReport = (searchQuery?: GetProfitAndLossReportQuery) => {
    const _searchQuery = {
      filter_accountingMethod: "CASH"
    };
    history.push({
      pathname: Routes.PROFIT_AND_LOSS_REPORT,
      search: qs.stringify({ ..._searchQuery, ...searchQuery })
    });
  };

  runProfitAndLossDetailsReport = (searchQuery?: GetTransactionReportQuery) => {
    const baseQuery: GetTransactionReportQuery = {
      filter_accountingMethod: AccountingMethod.CASH,
      ...searchQuery
    };
    history.push({
      pathname: Routes.PROFIT_AND_LOSS_DETAIL_REPORT,
      search: qs.stringify(baseQuery)
    });
  };

  runBalanceSheetDetailsReport = (searchQuery?: GetTransactionReportQuery) => {
    const baseQuery: GetTransactionReportQuery = {
      filter_accountingMethod: AccountingMethod.CASH,
      ...searchQuery
    };
    history.push({
      pathname: Routes.BALANCE_SHEET_DETAIL_REPORT,
      search: qs.stringify(baseQuery)
    });
  };

  getProfitAndLossReportRoute = (searchQuery?: GetProfitAndLossReportQuery) => {
    const scopedQuery = {
      filter_accountingMethod: "CASH",
      ...searchQuery
    };
    return `${Routes.PROFIT_AND_LOSS_REPORT}?${qs.stringify(scopedQuery)}`;
  };

  runBalanceSheetReport = (searchQuery?: Record<string, string>) => {
    const _searchQuery = {
      filter_accountingMethod: "CASH"
    };
    history.push({
      pathname: Routes.BALANCE_SHEET_REPORT,
      search: qs.stringify({ ..._searchQuery, ...searchQuery })
    });
  };

  runCashFlowStatementReport = (searchQuery?: Record<string, string>) => {
    const _searchQuery = {
      filter_accountingMethod: "CASH"
    };
    history.push({
      pathname: Routes.CASH_FLOW_STATEMENT_REPORT,
      search: qs.stringify({ ..._searchQuery, ...searchQuery })
    });
  };

  runRentRollReport = (searchQuery?: Record<string, string>) => {
    const _searchQuery = {};
    history.push({
      pathname: Routes.RENT_ROLL_REPORT,
      search: qs.stringify({ ..._searchQuery, ...searchQuery })
    });
  };

  runRentRollSummaryByBedsAndBathsReport = (searchQuery?: Record<string, string>) => {
    history.push({
      pathname: Routes.REPORTS_RENT_ROLL_SUMMARY_BY_BEDS_AND_BATHS,
      search: searchQuery && qs.stringify(searchQuery)
    });
  };

  runRentRollSummaryByPropertyReport = (searchQuery?: Record<string, string>) => {
    history.push({
      pathname: Routes.REPORTS_RENT_ROLL_SUMMARY_BY_PROPERTY,
      search: searchQuery && qs.stringify(searchQuery)
    });
  };

  runReport = (pathname: ReportRoutes, searchQuery?: Record<string, string | undefined>) =>
    history.push({
      pathname,
      search: qs.stringify({ ...searchQuery })
    });

  runProspectsReport = (searchQuery?: Record<string, string>) => {
    const _searchQuery = {};
    history.push({
      pathname: Routes.PROSPECTS_REPORT,
      search: qs.stringify({ ..._searchQuery, ...searchQuery })
    });
  };

  runDraftLeasingReport = (searchQuery?: Record<string, string>) => {
    const _searchQuery = {};
    history.push({
      pathname: Routes.LEASING_DRAFT_LEASES_REPORT,
      search: qs.stringify({ ..._searchQuery, ...searchQuery })
    });
  };

  runLeasingReport = (searchQuery?: Record<string, string>) => {
    const _searchQuery = {};
    history.push({
      pathname: Routes.LEASING_LEASES_REPORT,
      search: qs.stringify({ ..._searchQuery, ...searchQuery })
    });
  };

  runVendorsReport = (searchQuery?: Record<string, string>) => {
    const _searchQuery = {};
    history.push({
      pathname: Routes.VENDORS_REPORT,
      search: qs.stringify({ ..._searchQuery, ...searchQuery })
    });
  };

  runVendorsLedgerReport = (searchQuery?: Record<string, string>) => {
    const _searchQuery = {};
    history.push({
      pathname: Routes.VENDORS_LEDGER_REPORT,
      search: qs.stringify({ ..._searchQuery, ...searchQuery })
    });
  };

  runWorkOrdersReport = (searchQuery?: Record<string, string>) => {
    const _searchQuery = {
      filter_status: TaskStatusForQuery.NOT_COMPLETED
    };
    history.push({
      pathname: Routes.WORK_ORDERS_REPORT,
      search: qs.stringify({ ..._searchQuery, ...searchQuery })
    });
  };

  runTasksReport = (searchQuery?: Record<string, string>) => {
    const _searchQuery = {
      filter_status: TaskStatusForQuery.NOT_COMPLETED
    };
    history.push({
      pathname: Routes.TASKS_REPORT,
      search: qs.stringify({ ..._searchQuery, ...searchQuery })
    });
  };

  runPropertyOwnershipReport = (searchQuery?: Record<string, string>) => {
    history.push({
      pathname: Routes.PROPERTY_OWNERSHIP_REPORT,
      search: qs.stringify({ ...searchQuery })
    });
  };

  runTransactionsListReport = (searchQuery?: Record<string, string>, openInNewTab?: boolean) => {
    const _searchQuery = qs.stringify({ ...searchQuery });
    if (openInNewTab) {
      openNewTabAndFocus(`${Routes.TRANSACTIONS_LIST_REPORT}?${_searchQuery}`);
    } else {
      history.push({
        pathname: Routes.TRANSACTIONS_LIST_REPORT,
        search: _searchQuery
      });
    }
  };

  getTransactionsListReportRoute = (searchQuery?: Record<string, string>) =>
    `${Routes.TRANSACTIONS_LIST_REPORT}${searchQuery ? `?${qs.stringify(searchQuery)}` : ""}`;

  runAccountRegisterReport = (searchQuery?: Record<string, string>) => {
    const _searchQuery = {
      period: "last-30-days"
    };
    history.push({
      pathname: Routes.ACCOUNT_REGISTER_REPORT,
      search: qs.stringify({ ..._searchQuery, ...searchQuery })
    });
  };

  runTrialBalanceReport = (searchQuery?: Record<string, string>) => {
    const _searchQuery = {};
    history.push({
      pathname: Routes.TRIAL_BALANCE_REPORT,
      search: qs.stringify({ ..._searchQuery, ...searchQuery })
    });
  };

  runOwnerStatementReport = (searchQuery?: Record<string, string | string[]>, openInNewTab?: boolean) => {
    const query = {
      filter_accountingMethod: "CASH",
      ...searchQuery
    };

    if (openInNewTab) {
      openNewTabAndFocus(`${Routes.OWNER_STATEMENT_REPORT}?${qs.stringify(query)}`);
    } else {
      history.push({
        pathname: Routes.OWNER_STATEMENT_REPORT,
        search: qs.stringify(query)
      });
    }
  };

  runPropertyReserveFundsReport = (searchQuery?: Record<string, string>) => {
    history.push({
      pathname: Routes.PROPERTY_RESERVE_FUNDS_REPORT,
      search: searchQuery ? qs.stringify(searchQuery) : undefined
    });
  };

  runManagementFeesReport = (searchQuery?: Record<string, string>) => {
    history.push({
      pathname: Routes.MANAGEMENT_FEES_REPORT,
      search: searchQuery ? qs.stringify(searchQuery) : undefined
    });
  };

  runAccountPayableAgingDetailReport = (searchQuery?: Record<string, string>) => {
    const _searchQuery = {};
    history.push({
      pathname: Routes.ACCOUNTS_PAYABLE_AGING_DETAIL,
      search: qs.stringify({ ..._searchQuery, ...searchQuery })
    });
  };

  getAccountPayableAgingDetailReportRoute = (searchQuery?: Record<string, string>) =>
    `${Routes.ACCOUNTS_PAYABLE_AGING_DETAIL}${searchQuery ? `?${qs.stringify(searchQuery)}` : ""}`;

  runAccountPayableAgingDetailByAccountReport = (searchQuery?: Record<string, string>) => {
    const _searchQuery = {};
    history.push({
      pathname: Routes.ACCOUNTS_PAYABLE_AGING_DETAIL_BY_ACCOUNT,
      search: qs.stringify({ ..._searchQuery, ...searchQuery })
    });
  };

  runAccountReceivableAgingDetailReport = (searchQuery?: Record<string, string>) => {
    const _searchQuery = {};
    history.push({
      pathname: Routes.ACCOUNTS_RECEIVABLE_AGING_DETAIL,
      search: qs.stringify({ ..._searchQuery, ...searchQuery })
    });
  };

  getAccountReceivableAgingDetailReportRoute = (searchQuery?: Record<string, string>) =>
    `${Routes.ACCOUNTS_RECEIVABLE_AGING_DETAIL}${searchQuery ? `?${qs.stringify(searchQuery)}` : ""}`;

  runAccountReceivableAgingDetailByAccountReport = (searchQuery?: Record<string, string>) => {
    const _searchQuery = {};
    history.push({
      pathname: Routes.ACCOUNTS_RECEIVABLE_AGING_DETAIL_BY_ACCOUNT,
      search: qs.stringify({ ..._searchQuery, ...searchQuery })
    });
  };

  runAccountPayableAgingSummaryReport = (searchQuery?: Record<string, string | number | boolean>) => {
    const _searchQuery = {};
    history.push({
      pathname: Routes.ACCOUNTS_PAYABLE_AGING_SUMMARY,
      search: qs.stringify({ ..._searchQuery, ...searchQuery })
    });
  };

  runAccountReceivableAgingSummaryReport = (searchQuery?: Record<string, string | number | boolean>) => {
    const _searchQuery = {};
    history.push({
      pathname: Routes.ACCOUNTS_RECEIVABLE_AGING_SUMMARY,
      search: qs.stringify({ ..._searchQuery, ...searchQuery })
    });
  };

  runRecurringTransactionsReport = (searchQuery?: Record<string, string>) => {
    const _searchQuery = {};
    history.push({
      pathname: Routes.RECURRING_TRANSACTIONS_REPORT,
      search: qs.stringify({ ..._searchQuery, ...searchQuery })
    });
  };

  runRecurringTransactionsDetailReport = (searchQuery?: Record<string, string>) => {
    history.push({
      pathname: Routes.RECURRING_TRANSACTIONS_DETAIL_REPORT,
      search: searchQuery ? qs.stringify(searchQuery) : undefined
    });
  };

  runRecurringTasksReport = (searchQuery?: Record<string, string>) => {
    history.push({
      pathname: Routes.RECURRING_TASKS_REPORT,
      search: searchQuery ? qs.stringify(searchQuery) : undefined
    });
  };

  runRecurringWorkOrdersReport = (searchQuery?: Record<string, string>) => {
    history.push({
      pathname: Routes.RECURRING_WORK_ORDERS_REPORT,
      search: searchQuery ? qs.stringify(searchQuery) : undefined
    });
  };

  runRecurringExpensesReport = (searchQuery?: Record<string, string>) => {
    history.push({
      pathname: Routes.RECURRING_EXPENSES_REPORT,
      search: searchQuery ? qs.stringify(searchQuery) : undefined
    });
  };

  runRecurringVendorBillsReport = (searchQuery?: Record<string, string>) => {
    history.push({
      pathname: Routes.RECURRING_VENDOR_BILLS_REPORT,
      search: searchQuery ? qs.stringify(searchQuery) : undefined
    });
  };

  runRentalApplicationsReport = (searchQuery?: Record<string, string>) => {
    history.push({
      pathname: Routes.RENTAL_APPLICATIONS_REPORT,
      search: searchQuery ? qs.stringify(searchQuery) : undefined
    });
  };

  editBankDeposit = (locationPath: string, transactionId: string, searchQuery?: string) => {
    historyPushPersistentSearch({
      pathname: locationPath + "/" + DialogRoutes.EDIT_BANK_DEPOSIT + "/" + transactionId,
      search: searchQuery
    });
  };

  editBankAccountDeposit = (locationPath: string, accountId: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_BANK_DEPOSIT + "/" + accountId);
  };

  setupMerchantAccount = () => {
    history.push(Routes.INCOMING_PAYMENTS_MERCHANT_ACCOUNT_SETUP);
  };

  createMerchantAccount = (accountId: string) => {
    history.push(Routes.INCOMING_PAYMENTS + "/merchant-account/" + accountId, {});
  };

  scheduleDemo = (searchQuery?: string, allowClose = false) => {
    history.push({
      pathname: Routes.DEMO_SCHEDULE,
      search: searchQuery,
      state: { allowClose }
    });
  };

  routeToMAOPage = (searchQuery?: Record<string, string>) => {
    history.push({
      pathname: Routes.INCOMING_PAYMENTS_MERCHANT_ACCOUNT_OVERVIEW_BALANCE_SUMMARY,
      search: searchQuery ? qs.stringify(searchQuery) : ""
    });
  };

  addNewRentalApplication = (locationPath: string) => {
    const locationParts = locationPath.split("/");
    if (locationParts.length > 0) {
      const lastRoute = locationParts[locationParts.length - 1];
      if (lastRoute === "rental-applications") {
        historyPushPersistentSearch(locationPath + "/new");
      } else {
        historyPushPersistentSearch(locationPath + "/" + DialogRoutes.NEW_RENTAL_APPLICATION);
      }
    }
  };

  editRentalApplcaition = (locationPath: string, applicationId: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_RENTAL_APPLICATION + "/" + applicationId);
  };

  updateRentalApplicationDecisionStatus = (locationPath: string, applicationId: string) => {
    historyPushPersistentSearch(
      locationPath + "/" + DialogRoutes.UPDATE_RENTAL_APPLICATION_DECISION_STATUS + "/" + applicationId
    );
  };

  createNewRentalApplicationForProspect = (prospectId: string) => {
    history.push(
      `${Routes.PROSPECT_DETAILS.replace(":prospectId/:tabName", prospectId)}/${DialogRoutes.NEW_RENTAL_APPLICATION}`
    );
  };

  getDemoAdditionalUserInfo = (leadInfo: DoorLoopLeadDto, recaptchaToken?: string, searchQuery?: string) => {
    history.push({
      pathname: Routes.DEMO_ADDITIONAL_INFO,
      search: searchQuery,
      state: {
        leadInfo,
        recaptchaToken
      }
    });
  };

  viewRentalApplicationDetails = (
    applicationId: string,
    tabName?: string,
    search?: {
      report?: TransunionReportTypes;
    },
    isNewTab?: boolean
  ) => {
    let nextTabName = "overview";
    if (tabName) {
      nextTabName = tabName;
    }

    if (isNewTab) {
      openNewTabAndFocus(
        `${Routes.RENTAL_APPLICATIONS}/${applicationId}/${nextTabName}${search ? `?${qs.stringify(search)}` : ""}`
      );
    } else {
      history.push(
        `${Routes.RENTAL_APPLICATIONS}/${applicationId}/${nextTabName}${search ? `?${qs.stringify(search)}` : ""}`
      );
    }
  };

  getRentalApplicationDetailsOverviewRoute = (applicationId: string) =>
    `${Routes.RENTAL_APPLICATIONS}/${applicationId}/overview`;

  viewRentalApplications = (query?: string) => {
    history.push({
      pathname: Routes.RENTAL_APPLICATIONS,
      search: query
    });
  };

  connectBankAccount = (accountId, searchQuery?: Record<string, string>) => {
    historyPushPersistentSearch({
      pathname: `${Routes.BOOKKEEPING_TRANSACTIONS_OVERVIEW}/${accountId}/${DialogRoutes.BANK_ACCOUNT_CONNECT}`,
      search: searchQuery ? qs.stringify(searchQuery) : ""
    });
  };

  repairBankConnectionRoute = (accountId: string, searchQuery: Record<string, string>) =>
    `${Routes.BOOKKEEPING_TRANSACTIONS}/${accountId}/${DialogRoutes.BANK_ACCOUNT_REPAIR_CONNECTION}?${qs.stringify(
      searchQuery
    )}`;

  disconnectBankAccount = (accountId, searchQuery?: Record<string, string>) => {
    historyPushPersistentSearch({
      pathname: `${Routes.BOOKKEEPING_TRANSACTIONS_OVERVIEW}/${accountId}/${DialogRoutes.BANK_ACCOUNT_DISCONNECT}`,
      search: searchQuery ? qs.stringify(searchQuery) : ""
    });
  };

  viewBankConnectTransactions = (searchQuery?: Record<string, string>) => {
    history.push({
      pathname: `${Routes.BOOKKEEPING_TRANSACTIONS}/${DialogRoutes.BANK_ACCOUNT_TRANSACTIONS}`,
      search: searchQuery ? qs.stringify(searchQuery) : ""
    });
  };
  syncQuickbooksAccount = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.SYNC_QUICKBOOKS_ACCOUNT);
  };

  listingSyndicationSettings = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.LISTING_SETTINGS);
  };

  apiKeysSettings = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.API_KEYS);
  };

  createNewAnnouncement = (tenantId?: string, redirectUrl?: string) => {
    const searchQuery: Record<string, string> = {};
    if (tenantId) {
      searchQuery.tenantId = tenantId;
    }
    if (redirectUrl) {
      searchQuery[REDIRECT_URL_KEY] = redirectUrl;
    }

    history.push({
      pathname: Routes.ANNOUNCEMENTS_NEW,
      search: qs.stringify(searchQuery)
    });
  };

  createNewSignatureRequest = (requestType?: ESignatureRelatedToType, selectionId?: string) => {
    history.push({
      pathname: Routes.NEW_SIGNATURE_REQUESTS,
      search: requestType && selectionId ? qs.stringify({ requestType, selectionId }) : ""
    });
  };

  createNewSignatureTemplate = () => {
    history.push(Routes.NEW_SIGNATURE_TEMPLATE);
  };

  viewSignatureRequestsList = () => {
    history.push(Routes.SIGNATURE_REQUESTS);
  };

  viewSignatureTemplatesList = () => {
    history.push(Routes.SIGNATURE_TEMPLATES);
  };

  viewSignatureRequest = (signatureRequestId: string) => {
    historyPushPersistentSearch({
      pathname: DialogRoutes.VIEW_SIGNATURE_REQUEST + "/" + signatureRequestId
    });
  };

  editSignatureTemplate = (signatureTemplateId: string) => {
    historyPushPersistentSearch({
      pathname: DialogRoutes.EDIT_SIGNATURE_TEMPLATE + "/" + signatureTemplateId
    });
  };

  verifyMerchantAccountIdentity = (locationPath: string, merchantAccountId: string) => {
    const pathNameToAppend = `${DialogRoutes.VERIFY_IDENTITY}/${merchantAccountId}`;

    this.handleMerchantAccountDialog(locationPath, pathNameToAppend);
  };

  stripeApplyMerchantAccount = (locationPath: string, merchantAccountId: string) => {
    const pathNameToAppend = `${DialogRoutes.STRIPE_MERCHANT_ACCOUNT}/${merchantAccountId}`;

    this.handleMerchantAccountDialog(locationPath, pathNameToAppend);
  };

  createStripeMerchantAccount = (merchantAccountId: string) => {
    const pathNameToAppend = `${Routes.INCOMING_PAYMENTS}/${DialogRoutes.STRIPE_MERCHANT_ACCOUNT}/${merchantAccountId}`;

    historyPushPersistentSearch(pathNameToAppend, {});
  };

  stripeActionRequiredEIN = (locationPath: string, merchantAccountId: string) => {
    const pathNameToAppend = `${DialogRoutes.STRIPE_ACTION_REQUIRED_EIN}/${merchantAccountId}`;

    this.handleMerchantAccountDialog(locationPath, pathNameToAppend);
  };

  stripeActionRequiredBankAccount = (locationPath: string, merchantAccountId: string) => {
    const pathNameToAppend = `${DialogRoutes.STRIPE_ACTION_REQUIRED_BANK_ACCOUNT}/${merchantAccountId}`;

    this.handleMerchantAccountDialog(locationPath, pathNameToAppend);
  };

  stripeActionRequiredOther = (locationPath: string, merchantAccountId: string) => {
    const pathNameToAppend = `${DialogRoutes.STRIPE_ACTION_REQUIRED_OTHER}/${merchantAccountId}`;

    this.handleMerchantAccountDialog(locationPath, pathNameToAppend);
  };

  stripeUpdateMerchantAccount = (locationPath: string, merchantAccountId: string) => {
    const pathNameToAppend = `${DialogRoutes.STRIPE_UPDATE_MERCHANT_ACCOUNT}/${merchantAccountId}`;

    this.handleMerchantAccountDialog(locationPath, pathNameToAppend);
  };

  viewReconciliationList = () => {
    history.push({
      pathname: Routes.BOOKKEEPING_RECONCILIATION
    });
  };

  newBankReconciliation = (searchQuery?: Record<string, string>) => {
    history.push({
      pathname: Routes.BOOKKEEPING_NEW_BANK_RECONCILIATION,
      search: searchQuery ? qs.stringify(searchQuery) : ""
    });
  };

  viewBankReconciliationReport = (reconciliationId) => {
    history.push({
      pathname: `${Routes.BOOKKEEPING_RECONCILIATION_REPORT_INIT}/report/${reconciliationId}`
    });
  };

  viewGeneralSettings = () => {
    history.push(Routes.SETTINGS_GENERAL_SETTINGS);
  };

  viewOnBoarding() {
    history.push(Routes.ONBOARDING);
  }

  getRedirectUrlIfAny = () => {
    const searchQuery = qs.parse(location.search);
    const redirectUrl = searchQuery[REDIRECT_URL_KEY];
    if (Array.isArray(redirectUrl) || !redirectUrl) {
      // We should never have an array of redirect urls
      return undefined;
    }
    return redirectUrl;
  };

  goToRedirectUrl = (redirectUrl?: string) => {
    // if redirectUrl is not provided, we will try to get it from the search query
    redirectUrl ??= this.getRedirectUrlIfAny();
    if (!redirectUrl) {
      return;
    }

    history.push(redirectUrl);
  };

  runLeaseLateFeesReport = (searchQuery?: Record<string, string>) => {
    history.push({
      pathname: Routes.LEASE_LATE_FEES_REPORT,
      search: searchQuery ? qs.stringify(searchQuery) : ""
    });
  };

  runLeaseAccountStatementsReport = (searchQuery?: Record<string, string>) => {
    history.push({
      pathname: Routes.LEASE_ACCOUNT_STATEMENTS_REPORT,
      search: searchQuery ? qs.stringify(searchQuery) : ""
    });
  };

  runPropertyBankAccountsReport(openInNewTab = false) {
    if (openInNewTab) {
      openNewTabAndFocus(Routes.PROPERTY_BANK_ACCOUNTS_REPORT);
    } else {
      history.push(Routes.PROPERTY_BANK_ACCOUNTS_REPORT);
    }
  }

  editCustomAllocations = (locationPath: string) => {
    historyPushPersistentSearch(locationPath + "/" + DialogRoutes.EDIT_CUSTOM_ALLOCATIONS);
  };

  filePreview(fileId: string) {
    const searchParams = qs.stringify({ fileId });

    historyPushPersistentSearch({
      pathname: `${location.pathname}/${DialogRoutes.FILE_PREVIEW}`,
      search: searchParams
    });
  }

  private appendPath(path: string) {
    return `${location.pathname}/${path}`;
  }

  private appendSearch(search?: Record<string, string | string[] | undefined>) {
    return [location.search, qs.stringify(search || {})].join("&");
  }

  private appendState(state: Record<string, any>) {
    return { ...history.state, ...state };
  }

  private navigateChild({
    path,
    search,
    state
  }: {
    path: string;
    search?: Record<string, string | string[] | undefined>;
    state?: Record<string, any>;
  }) {
    history.push({
      pathname: this.appendPath(path),
      search: this.appendSearch(search),
      state: this.appendState({ ...state, lastRoute: location.pathname })
    });
  }

  private handleMerchantAccountDialog = (locationPath: string, pathNameToAppend: string) => {
    if (!locationPath.endsWith("/")) {
      pathNameToAppend = "/" + pathNameToAppend;
    }
    historyPushPersistentSearch({
      pathname: `${locationPath}${pathNameToAppend}`
    });
  };

  navigateToRentalApplication = (search: string) => {
    history.push({
      pathname: Routes.TENANT_PORTAL_RENTAL_APPLICATION,
      search
    });
  };

  navigateToRentalApplicationListing = (search: string) => {
    history.push({
      pathname: Routes.TENANT_PORTAL_LISTING_RENTAL_APPLICATION,
      search
    });
  };

  navigateToRentalApplicationPropertySettings = () => {
    historyPushPersistentSearch(DialogRoutes.EDIT_RENTAL_APPLICATIONS_SETTINGS);
  };

  navigateToRentalApplicationTab = (rentalApplicationId: string, tab: rentalApplicationTabsList) => {
    history.push(`${Routes.RENTAL_APPLICATIONS}/${rentalApplicationId}/${tab}`);
  };

  navigateToSetupMerchantAccount = (bankAccountId: string) => {
    history.push(Routes.INCOMING_PAYMENTS_STRIPE_MERCHANT_ACCOUNT.replace(":merchantAccountId", bankAccountId));
  };

  navigateToRentalApplicationPropertyPhotos = () => {
    historyPushPersistentSearch(`${DialogRoutes.EDIT_PROPERTY}/photos`);
  };

  navigateToRentalApplications = (search: { filter_decisionStatuses?: string[]; filter_reportStatus?: string }) => {
    history.push({
      pathname: Routes.RENTAL_APPLICATIONS,
      search: qs.stringify(search)
    });
  };

  navigateToBudgetsList(query?: GetAllBudgetsQuery, openInNewTab?: boolean) {
    const baseRoute = Routes.BOOKKEEPING_BUDGETS_LIST;
    const searchQuery = qs.stringify(query || {});

    if (openInNewTab) {
      openNewTabAndFocus(`${baseRoute}?${searchQuery}`);
    } else {
      history.push({
        pathname: baseRoute,
        search: searchQuery
      });
    }
  }

  navigateToNewBudget(openInNewTab?: boolean) {
    const baseRoute = Routes.BOOKKEEPING_BUDGET_NEW;

    if (openInNewTab) {
      openNewTabAndFocus(baseRoute);
    } else {
      history.push({ pathname: baseRoute });
    }
  }

  navigateToEditBudget({ id, openInNewTab, isReplace }: NavigateToEditBudgetProps) {
    const baseRoute = Routes.BOOKKEEPING_BUDGET_EDIT.replace(":id", id);

    if (openInNewTab) {
      openNewTabAndFocus(baseRoute);
    } else if (isReplace) {
      history.replace({ pathname: baseRoute });
    } else {
      history.push({ pathname: baseRoute });
    }
  }

  navigateToCommunicationsCenter = () => {
    history.push({
      pathname: Routes.COMMUNICATIONS_CENTER
    });
  };

  navigateToSetupSmsWizard = (origin: SetupSmsOrigin) => {
    history.push({
      pathname: Routes.COMMUNICATIONS_CENTER_SETUP_SMS,
      search: qs.stringify({ origin })
    });
  };

  viewConversation = (conversationId: string) => {
    if (conversationUtils.isTabView()) {
      historyPushPersistentSearch(location.pathname, { conversationId }, "replace");
    } else {
      const viewConversationRoute = conversationUtils.getViewConversationRoute();
      historyPushPersistentSearch({
        pathname: viewConversationRoute?.replace(/:conversationId/, conversationId)
      });
    }
  };

  newConversation = () => {
    if (conversationUtils.isTabView()) {
      historyPushPersistentSearch(location.pathname, { conversationId: "new" }, "replace");
    } else {
      const communicationCenterRoute = conversationUtils.getCommunicationCenterRoute();
      historyPushPersistentSearch({
        pathname: `${communicationCenterRoute}/new`
      });
    }
  };

  editCommunicationCenterSettings = () => {
    historyPushPersistentSearch(DialogRoutes.EDIT_COMMUNICATION_CENTER_SETTINGS);
  };

  viewTransactionsListReport = (inNewWindow = false) => {
    if (inNewWindow) {
      openNewTabAndFocus(Routes.TRANSACTIONS_LIST_REPORT);
    } else {
      history.push(Routes.TRANSACTIONS_LIST_REPORT);
    }
  };

  viewPrintChecks = (accountId?: string) => {
    if (!accountId) {
      history.push(Routes.PRINT_CHECKS);
      return;
    }

    history.push(`${Routes.PRINT_CHECKS}?filter_payableInfoPayFromAccount=${accountId}`);
  };

  viewOutgoingPayment = () => {
    history.push(Routes.OUTGOING_PAYMENTS);
  };
  navigatoToTenantPortalHome = (leaseId: string) => {
    history.push(`/tenant-portal/${leaseId}/home`);
  };

  navigatoToTenantPortalRequests = (leaseId: string) => {
    history.push(`/tenant-portal/${leaseId}/requests`);
  };

  navigatoToTenantPortalAnnouncements = (leaseId: string) => {
    history.push(`/tenant-portal/${leaseId}/announcements`);
  };
}

interface NavigateToEditBudgetProps {
  id: string;
  openInNewTab?: boolean;
  isReplace?: boolean;
}

export const NavigationManager = new Navigation();
