import { View } from "DLUI/view";
import { DialogSearchPanel } from "DLUI/dialogs";
import AppStrings from "../../../../locale/keys";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import type { GetAllVendorsQuery, VendorDto } from "@doorloop/dto";
import { ObjectPermission } from "@doorloop/dto";
import DLButton from "DLUI/button/dlButton";
import { getDialogSelectionHeight } from "DLUI/dialogs/components/dialogFrame";
import { VendorsListContainerId } from "screens/vendors/vendorsList/vendorsList";
import { SelectableVendorListItem, VendorsList } from "screens/vendors";
import type { FC } from "react";
import React, { useMemo, useState } from "react";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  filterObj?: GetAllVendorsQuery;
  didPressAddNewVendor?: () => void;
  didSelectVendorItem: (selectedItem: VendorDto, itemIndex: number) => void;
  height: number;
}

export const VendorSelectionView: FC<ComponentProps> = ({
  filterObj,
  height,
  didSelectVendorItem,
  didPressAddNewVendor
}) => {
  const { screenContainerPadding } = useResponsiveHelper();

  const [searchQuery, setSearchQuery] = useState<string>("");

  const _filterObject = useMemo(() => {
    return { ...filterObj, filter_text: searchQuery };
  }, [searchQuery, filterObj]);

  return (
    <View paddingRight={screenContainerPadding} paddingLeft={screenContainerPadding}>
      <View flexDirection={"row"}>
        <View autoWidth>
          <DialogSearchPanel
            placeholder={AppStrings.Vendors.Screen.SearchPlaceHolder}
            onChange={setSearchQuery}
            borderRadius={30}
          />
        </View>
        <View flex={1} justifyContent={"center"} alignItems="flex-end" marginTop={20}>
          <RestrictedPermissionAccess
            clearance={{
              permission: ObjectPermission.vendors,
              field: "create"
            }}
          >
            <DLButton
              actionText={AppStrings.Vendors.Screen.AddNewVendor}
              onClick={didPressAddNewVendor}
              icons={{ start: { isHidden: false } }}
            />
          </RestrictedPermissionAccess>
        </View>
      </View>

      <View
        marginTop={20}
        alignItems={"center"}
        height={getDialogSelectionHeight(height)}
        overflow={"scroll"}
        id={VendorsListContainerId}
      >
        <VendorsList
          ListItem={SelectableVendorListItem}
          filterObj={_filterObject || {}}
          didSelectVendor={didSelectVendorItem}
          stickyHeaderId={VendorsListContainerId}
          fullWidth
          didPressAddNewButton={didPressAddNewVendor}
          selectableListItem
          scrollableTarget={VendorsListContainerId}
          removeDefaultBottomPadding
        />
      </View>
    </View>
  );
};
