"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuggestedActionsEnum = void 0;
var SuggestedActionsEnum;
(function (SuggestedActionsEnum) {
    SuggestedActionsEnum[SuggestedActionsEnum["viewDocs"] = 0] = "viewDocs";
    SuggestedActionsEnum[SuggestedActionsEnum["onlinePayments"] = 1] = "onlinePayments";
    SuggestedActionsEnum[SuggestedActionsEnum["tenantPortal"] = 2] = "tenantPortal";
    SuggestedActionsEnum[SuggestedActionsEnum["rentalApplications"] = 3] = "rentalApplications";
    SuggestedActionsEnum[SuggestedActionsEnum["bankConnect"] = 4] = "bankConnect";
    SuggestedActionsEnum[SuggestedActionsEnum["referFriend"] = 5] = "referFriend";
    SuggestedActionsEnum[SuggestedActionsEnum["scheduleDemo"] = 6] = "scheduleDemo";
})(SuggestedActionsEnum || (exports.SuggestedActionsEnum = SuggestedActionsEnum = {}));
