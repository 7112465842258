const Allocations = {
  CustomAllocation: "allocations.customAllocation",
  Allocate: "allocations.allocate",
  Allocation: "allocations.allocation",
  Allocations: "allocations.allocations",
  AllocationName: "allocations.allocationName",
  AllocationDescription: "allocations.allocationDescription",
  AllocationType: "allocations.allocationType",
  AllocationAmount: "allocations.allocationAmount",
  AllocationSettings: "allocations.allocationSettings",
  NoAllocations: "allocations.noAllocations",
  NoAllocationsDescription: "allocations.noAllocationsDescription",
  NewAllocation: "allocations.newAllocation",
  EditAllocation: "allocations.editAllocation",
  AllocationInfo: "allocations.allocationInfo",
  Percentage: "allocations.percentage",
  TotalAllocation: "allocations.totalAllocation",
  UseCommonAllocation: "allocations.useCommonAllocation",
  IncludeVacantUnits: "allocations.includeVacantUnits",
  DeleteConfirmationText: "allocations.deleteConfirmationText",
  Errors: {
    InvalidPercentagesExist: "allocations.errors.invalidPercentagesExist",
    SumOfAllocationsMustBeGreaterThanZero: "allocations.errors.sumOfAllocationsMustBeGreaterThanZero",
    SumOfAllocationsMustBeLessThanOrEqualToOneHundred:
      "allocations.errors.sumOfAllocationsMustBeLessThanOrEqualToOneHundred"
  }
};

export default Allocations;
