import { View } from "DLUI/view";
import { Checkbox } from "DLUI/checkbox";
import { Link } from "DLUI/link";
import ColorsEnum from "utils/colorsEnum";
import React from "react";
import AppStrings from "locale/keys";
import { Trans } from "react-i18next";
import { PrivacyPolicyUrl, TermsOfServiceUrl } from "utils/sharedVariables";
import { FormMaxWidth } from "screens/authScreen/layout/authLayout";
import { useFormikContext } from "formik";
import Text from "DLUI/text";
import { type Translation, useTypedTranslation } from "locale";

interface AcceptTOSProps {
  id?: string;
  i18nKey?: string;
  tosProps?: {
    tosUrl?: string;
    tosLinkTranslation?: Translation;
  };
  privacyPolicyProps?: {
    privacyPolicyUrl?: string;
    privacyPolicyLinkTranslation?: Translation | null;
  };
  onClick?: (checked: boolean) => void;
  checked?: boolean;
  style?: React.CSSProperties;
}

export const AcceptTOS = ({
  id = "acceptTOS",
  onClick,
  i18nKey = AppStrings.Common.AcceptTOS,
  style,
  checked,
  tosProps = {
    tosUrl: TermsOfServiceUrl,
    tosLinkTranslation: "common.termsOfService"
  },
  privacyPolicyProps = {
    privacyPolicyUrl: PrivacyPolicyUrl,
    privacyPolicyLinkTranslation: "common.privacyPolicy"
  }
}: AcceptTOSProps) => {
  const { t } = useTypedTranslation();
  const formik = useFormikContext<any>();

  const renderLink = (key: string, hrefUrl: string) => (
    <Link type="blank" textColor={ColorsEnum.LightBlack} underline="always" key={key} hrefUrl={hrefUrl} />
  );

  const handleCheckedChange = (checked: boolean) => {
    onClick ? onClick(checked) : formik.setFieldValue("acceptedOnTOS", checked ? new Date() : undefined);
  };

  return (
    <View
      noWrap
      style={{
        maxWidth: FormMaxWidth,
        ...style
      }}
    >
      <View flexDirection="row" gap={10} noWrap alignItems="center" maxWidth="max-content">
        <Checkbox
          id={id}
          onCheckedChange={handleCheckedChange}
          checked={Boolean(onClick ? checked : formik.values.acceptedOnTOS)}
        />
        <label htmlFor={id}>
          <Text fontSize={12} style={{ flex: 1, cursor: "pointer" }}>
            <Trans
              i18nKey={i18nKey}
              values={{
                tosLink: tosProps.tosLinkTranslation ? t(tosProps.tosLinkTranslation) : "",
                privacyPolicyLink:
                  privacyPolicyProps.privacyPolicyUrl && privacyPolicyProps.privacyPolicyLinkTranslation
                    ? t(privacyPolicyProps.privacyPolicyLinkTranslation)
                    : ""
              }}
              components={[
                renderLink("tosLink", tosProps.tosUrl || ""),
                renderLink("privacyPolicyLink", privacyPolicyProps.privacyPolicyUrl || "")
              ]}
            />
          </Text>
        </label>
      </View>
    </View>
  );
};
