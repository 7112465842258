import React from "react";
import PrimaryAppBar from "./primaryAppBar";
import SecondaryAppBar from "./secondaryAppBar";

export default function AppBarContainer() {
  return (
    <div>
      <PrimaryAppBar />
      <SecondaryAppBar />
    </div>
  );
}
