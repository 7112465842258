"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapReportColumnFieldToTransactionReportItemKey = exports.REPORT_TRANSACTION_DETAILS_COLUMNS = exports.REPORT_CURRENCY_COLUMNS = exports.REPORT_TRANSACTION_DETAILS_CURRENCY_COLUMNS = exports.REPORT_ENUM_COLUMNS = exports.REPORT_TEXT_COLUMNS = exports.REPORT_REFERENCE_COLUMNS = void 0;
const REPORT_REFERENCE_COLUMNS = ["account", "property", "tenant", "vendor", "owner", "lease", "unit"];
exports.REPORT_REFERENCE_COLUMNS = REPORT_REFERENCE_COLUMNS;
const REPORT_TEXT_COLUMNS = ["name", "reference", "memo"];
exports.REPORT_TEXT_COLUMNS = REPORT_TEXT_COLUMNS;
const REPORT_ENUM_COLUMNS = ["journalEntryType", "paymentMethod"];
exports.REPORT_ENUM_COLUMNS = REPORT_ENUM_COLUMNS;
const REPORT_TRANSACTION_DETAILS_CURRENCY_COLUMNS = ["amount", "runningBalance", "debit", "credit"];
exports.REPORT_TRANSACTION_DETAILS_CURRENCY_COLUMNS = REPORT_TRANSACTION_DETAILS_CURRENCY_COLUMNS;
const REPORT_CURRENCY_COLUMNS = ["total", ...REPORT_TRANSACTION_DETAILS_CURRENCY_COLUMNS];
exports.REPORT_CURRENCY_COLUMNS = REPORT_CURRENCY_COLUMNS;
const REPORT_TRANSACTION_DETAILS_COLUMNS = [
    ...REPORT_ENUM_COLUMNS,
    ...REPORT_REFERENCE_COLUMNS,
    ...REPORT_TEXT_COLUMNS,
    ...REPORT_TRANSACTION_DETAILS_CURRENCY_COLUMNS
];
exports.REPORT_TRANSACTION_DETAILS_COLUMNS = REPORT_TRANSACTION_DETAILS_COLUMNS;
const MapReportColumnFieldToTransactionReportItemKey = {
    account: "accountFullName",
    lease: "leaseName",
    owner: "ownerName",
    property: "propertyName",
    tenant: "tenantName",
    unit: "unitName",
    vendor: "vendorName",
    journalEntryType: "type"
};
exports.MapReportColumnFieldToTransactionReportItemKey = MapReportColumnFieldToTransactionReportItemKey;
