import type { LateFeeChargeDto, PropertyDto } from "@doorloop/dto";
import { createValidator, DataCy, LateFeesSettingsDto, LeaseDto, LeaseSettingsDto } from "@doorloop/dto";
import type { ApiResult } from "api/apiResult";
import { leasesApi } from "api/leasesApi";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import { FormikSwitchButton, RadioGroup } from "DLUI/form";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, Formik } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import LateFeeSettings from "../common/lateFees/lateFeesSettings";
import { settingsApi } from "api/settingsApi";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { trackLateFeesSettings } from "DLUI/dialogs/settings/common/analytics/trackLateFeesSettings";
import { getChargesWithUniqueKeys, getCurrentSettings } from "DLUI/dialogs/settings/common/lateFees/utils";
import { entityApiStore } from "api/entityApiStore/entityApiStore";
import { propertiesApi } from "api/propertiesApi";
import { editLateFeesFrameWidth } from "DLUI/dialogs/settings/common/lateFees/constants";

interface ComponentProps {
  didFinishOperation?: (values: LeaseDto) => void;
  onBackdropClick: () => void;
  refreshEvent?: () => void;
  dialogTitle: string;
  shouldLoadData?: boolean;
  onSaveButtonPress?: (values: LeaseDto) => Promise<unknown>;
  initialValues?: LeaseDto;
}

let formikGlobalRef: FormikProps<LeaseDto> | null = null;

export const getFormikRef = () => formikGlobalRef;

const validateForm = createValidator(LeaseDto);

const EditLateFees: React.FC<ComponentProps> = ({
  didFinishOperation,
  refreshEvent,
  onBackdropClick,
  dialogTitle,
  shouldLoadData = true,
  onSaveButtonPress,
  initialValues
}: ComponentProps) => {
  const { t } = useTranslation();

  const { leaseId } = useParams<any>();
  const [leaseData, setLeaseData] = useState<LeaseDto | undefined>();
  const [property, setProperty] = useState<PropertyDto | undefined>();
  const { dialogHorizontalPadding } = DialogsHelper();
  const [viewIndex, setViewIndex] = useState(0);
  const [enabledLateFeesForLease, setEnabledLateFeesForLease] = useState<boolean>(
    () => initialValues?.settings?.lateFees?.enabled ?? leaseData?.settings?.lateFees?.enabled ?? false
  );
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);

  const [lateFeesDefaultAccount, setLateFeesDefaultAccount] = useState<string | undefined>();
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>(AppStrings.Common.NetworkErrorSubTitle);

  const [hideLoadingDialogActionButtons, setHideLoadingDialogActionButtons] = useState<boolean>(false);

  const [lateFeesSelectionType, setLateFeesSelectionType] = useState<"companyDefault" | "specifyFees">(() =>
    (initialValues?.settings?.lateFees ?? leaseData?.settings?.lateFees) !== undefined
      ? "specifyFees"
      : "companyDefault"
  );

  const { data: companyLateFeesData } = entityApiStore.globalSettings.queries.useLateFeesSettings();

  const loadLeaseData = async () => {
    if (leaseId) {
      const response = await leasesApi.get(leaseId).catch((e: string) => {
        showDialogError(e);
      });
      if (response && response.status && response.data) {
        if (!response.data.settings) {
          response.data.settings = new LeaseSettingsDto();
        }
        if (response.data.property) {
          const propertyForLease = await propertiesApi.get(response.data.property);
          setProperty(propertyForLease.data);
        }
        if (!response.data.settings.lateFees) {
          response.data.settings.lateFees = new LateFeesSettingsDto();
          response.data.settings.lateFees.enabled = false;
        }

        const defaultLateFees = response.data.settings.lateFees;
        if (defaultLateFees && defaultLateFees.enabled) {
          setEnabledLateFeesForLease(defaultLateFees.enabled);
        }
        if (defaultLateFees.policy !== undefined) {
          setLateFeesSelectionType("specifyFees");
        }

        const { data } = await settingsApi.getDefaultAccountsData();
        if (data?.income_lateFees) {
          setLateFeesDefaultAccount(data.income_lateFees);
        }
        setLeaseData(response.data);
        setLoadingDialogState(DialogState.Success);
      } else {
        showDialogError();
      }
    } else {
      showDialogError();
    }
  };

  const showDialogError = (errorMessage?: string) => {
    setHideLoadingDialogActionButtons(true);
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(errorMessage || AppStrings.Common.GeneralError);
  };

  useEffect(() => {
    if (!shouldLoadData) {
      setLoadingDialogState(DialogState.Success);
      return;
    }

    loadLeaseData().then((_) => {});
  }, [shouldLoadData]);

  useEffect(
    () => () => {
      formikGlobalRef = null;
    },
    []
  );

  const initFormValues = (): LeaseDto => {
    if (initialValues) return initialValues;

    if (formikGlobalRef && formikGlobalRef.values) {
      return formikGlobalRef.values;
    }
    if (leaseData) {
      if (!leaseData.settings) {
        leaseData.settings = new LeaseSettingsDto();
      }
      if (leaseData.settings.lateFees?.policy) {
        leaseData.settings.lateFees.policy.lateFeeCharges = getChargesWithUniqueKeys(
          leaseData.settings.lateFees.policy.lateFeeCharges
        );
      }
      return leaseData;
    }
    return new LeaseDto();
  };

  const isValidForm = async (formikRef: FormikProps<LeaseDto>) => {
    const settingsObject =
      formikRef.values && formikRef.values.settings && formikRef.values.settings.lateFees
        ? formikRef.values.settings.lateFees
        : undefined;

    if (settingsObject) {
      if (settingsObject.policy?.lateFeeCharges) {
        settingsObject.policy?.lateFeeCharges.forEach((currentItem: LateFeeChargeDto, index: number) => {
          formikRef.setFieldTouched(`settings.lateFees.policy.lateFeeCharges[${index}].frequency`);
          formikRef.setFieldTouched(`settings.lateFees.policy.lateFeeCharges[${index}].amount`);
          formikRef.setFieldTouched(`settings.lateFees.policy.lateFeeCharges[${index}].percentage`);
          formikRef.setFieldTouched(
            `settings.lateFees.policy.lateFeeCharges[${index}].daysAfterDueDateToChargeLateFees`
          );
          formikRef.setFieldTouched(`settings.lateFees.policy.lateFeeCharges[${index}].account`);
          formikRef.setFieldTouched(`settings.lateFees.policy.lateFeeCharges[${index}].feeType`);
        });
      }
      formikRef.setFieldTouched("settings.lateFees.policy.applyOnlyToSpecificItemsOutstandingBalance");
      formikRef.setFieldTouched("settings.lateFees.policy.minBalanceToApplyLateFeeAmount");
      formikRef.setFieldTouched("settings.lateFees.policy.maxMonthlyLateFeesAmount");
      formikRef.setFieldTouched("settings.lateFees.policy.lateFeeSettingsAccountsToConsiderSpecificAccounts");
    }

    const errors = (await formikRef.validateForm()) as any;
    return _.isEmpty(errors?.settings?.lateFees);
  };

  const updateLease = async (values: LeaseDto) => {
    const response = (await leasesApi.update(values.id!, values).catch((error) => {
      setLoadingDialogErrorText(error);
      setLoadingDialogState(DialogState.Error);
    })) as ApiResult<any>;

    if (!response.status) {
      setLoadingDialogErrorText(response.message);
      setLoadingDialogState(DialogState.Error);
    } else {
      if (values.settings?.lateFees?.policy) {
        trackLateFeesSettings("Lease Settings", values.settings.lateFees.policy);
      }

      setLoadingDialogState(DialogState.Success);
      didFinishUpdateProperty();
      formikGlobalRef = null;
    }
  };

  const didPressSaveButton = async (formikGlobalRef) => {
    if (formikGlobalRef !== null) {
      if (shouldLoadData && !leaseData) return;
      await formikGlobalRef.submitForm();
      const isValid = await isValidForm(formikGlobalRef);
      if (isValid) {
        const newValues = formikGlobalRef.values;

        if (typeof onSaveButtonPress === "function") {
          return await onSaveButtonPress?.(newValues);
        }

        setLoadingDialogState(DialogState.Show);
        setViewIndex(1);
        await updateLease(formikGlobalRef.values);
      }
    }
  };

  const renderActionPanelButtons = React.useCallback(() => {
    if (viewIndex === 1) {
      return <div />;
    }

    return (
      <FormActionButtons
        propsSubButton={{ onClick: onBackdropClick }}
        propsMainButton={{ type: "cta", props: { onClick: async () => await didPressSaveButton(formikGlobalRef) } }}
      />
    );
  }, [formikGlobalRef]);

  const didPressDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(0);
  };

  const onRetryButtonPress = () => {
    if (formikGlobalRef !== null) {
      setLoadingDialogState(DialogState.Hidden);
      setViewIndex(0);
    }
  };

  const didFinishUpdateProperty = () => {
    if (formikGlobalRef !== null) {
      didFinishOperation?.(formikGlobalRef.values);
      if (refreshEvent) {
        refreshEvent();
      }
      setLoadingDialogState(DialogState.Hidden);
    }
  };

  const didChangeLateFeeSelection = (selectedValue: string) => {
    if (formikGlobalRef !== null) {
      const isPropertySettingsChosen = selectedValue === "false";
      const lateFeesSettings = getCurrentSettings({
        isCompanySettingsChosen: isPropertySettingsChosen,
        companySettings: companyLateFeesData?.data,
        propertySettings: property?.settings?.lateFeesPolicy,
        defaultAccountId: lateFeesDefaultAccount
      });
      formikGlobalRef.setFieldValue("settings.lateFees.policy", lateFeesSettings);
    }
    setLateFeesSelectionType(selectedValue === "false" ? "companyDefault" : "specifyFees");
  };

  const didChangeEnabledLateFees = (nextValue: boolean) => {
    const switchOff = !nextValue;
    if (formikGlobalRef) {
      formikGlobalRef.setFieldValue("settings.lateFees.enabled", nextValue);
      setEnabledLateFeesForLease(nextValue);
      if (switchOff) {
        formikGlobalRef.setFieldValue("settings.lateFees.policy", undefined);
        setLateFeesSelectionType("companyDefault");
      }
    }
  };

  const renderForm = () => {
    const formInitialValues = initFormValues();
    return (
      <Formik initialValues={formInitialValues} onSubmit={(values, { setSubmitting }) => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;

          return (
            <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding}>
              <FastField
                component={FormikSwitchButton}
                name={"settings.lateFees.enabled"}
                label={AppStrings.Common.LeaseLateFeesSwitchLabel}
                onChange={didChangeEnabledLateFees}
                marginTop={20}
                dataCy={DataCy.leaseDetails.settings.editLeaseLateFees.automaticLateFees}
              />
              <View marginTop={20} alignItems={"flex-start"}>
                <View shouldShow={enabledLateFeesForLease} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
                  <Text
                    value={AppStrings.Leases.Settings.LateFeesDescription}
                    fontSize={16}
                    color={"black"}
                    maxWidth={550}
                    lineHeight={"26px"}
                  />
                  <FastField
                    uniqueKey={"LF"}
                    component={RadioGroup}
                    name={"automaticLateFees"}
                    defaultValue={lateFeesSelectionType === "companyDefault" ? "false" : "true"}
                    didChangeSelection={didChangeLateFeeSelection}
                    radioGroupItems={[
                      {
                        label: AppStrings.Properties.Settings.UsePropertyDefaultLateFees,
                        value: "false",
                        dataCy: DataCy.leaseDetails.settings.editLeaseLateFees.useDefaultLateFees
                      },
                      {
                        label: AppStrings.Leases.Settings.SpecifyLateFeesForLease,
                        value: "true",
                        dataCy: DataCy.leaseDetails.settings.editLeaseLateFees.specifyLateFees
                      }
                    ]}
                    marginTop={10}
                  />
                  <View
                    shouldShow={lateFeesSelectionType === "specifyFees"}
                    showAnimation={"fade-in"}
                    hideAnimation={"fade-out"}
                  >
                    <View>
                      <LateFeeSettings name={"settings.lateFees.policy"} />
                    </View>
                  </View>
                </View>
              </View>
            </View>
          );
        }}
      </Formik>
    );
  };

  const renderView = ({ index }: any) => {
    if (index === 1 || (shouldLoadData && !leaseData)) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={t(AppStrings.Common.Loading)}
            errorText={loadingDialogErrorText}
            successText={t(AppStrings.Common.OperationCompleted)}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
            hideButtons={hideLoadingDialogActionButtons}
          />
        </View>
      );
    }
    return renderForm();
  };

  const _onBackdropClick = () => {
    if (viewIndex === 1) {
      setViewIndex(0);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const frameType = useMemo(() => {
    if (shouldLoadData && leaseData === undefined) {
      return "contentOnly";
    }

    if (viewIndex === 0) {
      return "sectionTitleFrame";
    }
    if (viewIndex === 1) {
      return "contentOnly";
    }

    return "sectionTitleFrame";
  }, [viewIndex, leaseData]);

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={dialogTitle}
      width={editLateFeesFrameWidth}
      height={window.innerHeight > 1000 ? 1000 : window.innerHeight}
      renderView={renderView}
      numViews={2}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={frameType}
    />
  );
};

export default EditLateFees;
