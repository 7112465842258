import React from "react";
import type { BaseButtonComponentProps } from "DLUI/button/baseButton";
import BaseButton from "DLUI/button/baseButton";
import ColorsEnum from "../../../../utils/colorsEnum";

interface Props extends BaseButtonComponentProps {
  faded?: boolean;
  href?: string;
}

const ActionButton: React.FC<Props> = ({ href, faded, onClick, actionText, ...rest }) => {
  const handleOnClick = () => {
    if (href) {
      const url = new URL(href, window.location.origin);
      window.open(url.href, "_blank");
    }

    if (onClick) {
      onClick();
    }
  };
  return (
    <BaseButton
      onClick={handleOnClick}
      actionText={actionText}
      backgroundColor={faded ? ColorsEnum.LightBlueFaded : ColorsEnum.BrightBlue}
      borderRadius={5}
      textColor={faded ? "lightBlue" : "white"}
      borderColor={ColorsEnum.Transparent}
      padding={"0 50px"}
      height={40}
      {...rest}
    />
  );
};

export default ActionButton;
