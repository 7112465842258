import React, { useCallback, useEffect, useMemo, useState } from "react";
import type {
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState
} from "@material-ui/lab/Autocomplete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FieldProps } from "formik";
import { getIn } from "formik";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useReadOnlyContext } from "contexts/readOnlyContext";
import type { BaseDto, GetAllBaseQueryRequest } from "@doorloop/dto";
import { DataCy } from "@doorloop/dto";
import type { RestApiBaseWithDictionary } from "api/restApiBaseWithDictionary";
import { Box, makeStyles, Popper } from "@material-ui/core";
import useAutoComplete, { SELECT_ALL_ID } from "DLUI/form/autoComplete/formikAsyncAutoComplete/useAutoComplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { filter, handleFilterOptions } from "DLUI/form/autoComplete/formikAsyncAutoComplete/autoCompleteHandlers";
import TextField from "@material-ui/core/TextField";
import { CreateOptionItem } from "DLUI/form/autoComplete/formikAsyncAutoComplete/optionItem";
import useStylesTextInput from "DLUI/form/textField/textInput/useStylesTextInput";
import "DLUI/form/textField/textInput/styles.css";
import { FieldSizes } from "DLUI/form/textField/types";
import { fieldSizesMap, fontSizeMap } from "DLUI/form/textField/textInput";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";
import { ControlledCheckBox } from "DLUI/form/checkBox/base/controlledCheckBox";

export interface MultiSelectChipsValue {
  name: string;
}

interface SelectionEnumProps {
  selectionEnum: Record<string, string>;
}

interface ApiHandlerProps<TDto extends BaseDto, TQuery extends GetAllBaseQueryRequest> {
  apiHandler: RestApiBaseWithDictionary<TDto, Record<string, string>>;
  queryParams?: Record<string, string>;
  filterFieldName: string;
  filterFieldValue: string;
}

const popperStyles = makeStyles((theme) => {
  return {
    popper: {
      maxWidth: "fit-content"
    }
  };
});

export type FormikMultiSelectAutoCompleteComponentProps<
  TDto extends BaseDto,
  TQuery extends GetAllBaseQueryRequest
> = Partial<FieldProps<any>> & {
  label: string;
  uniqueIndex: number | string;
  displayNameKey: string;
  onChange?: (event: object, value: any) => void;
  selectionFields?: string[];
  onInputChange?: (inputValue: string) => {};
  defaultValue?: string[];
  selectedValue?: string;
  marginTop?: number | string;
  paddingRight?: number;
  errorLabelPaddingLeft?: number;
  validationMessage?: string;
  defaultMode?: "cacheMode" | "serverMode";
  maxWidth?: number;
  variant?: "standard" | "filled" | "outlined";
  CreateNewOptionComponent?: React.FunctionComponent<any>;
  createNewOptionType?: string;
  createNewOptionTitle?: string;
  groupNameProp?: string;
  pageSize?: number;
  selectionEnum?: Record<string, string>;
  apiHandler?: RestApiBaseWithDictionary<TDto, TQuery>;
  queryParams?: Record<string, string | boolean | string[]>;
  filterFieldName?: string;
  filterFieldValue?: string;
  dataCy?: string;
  classes?: Record<string, string>;
  disableSelectAll?: boolean;
  chips?: "showAllChips" | "chipPlusCounter" | "counterOnly";
  fontSize?: keyof typeof fontSizeMap;
  onDropdownOpenChanged?: (isOpen: boolean) => void;
  customChipsTemplate?: (value: MultiSelectChipsValue[]) => string;
  autoFocus?: boolean;
  dropDownWidth?: number;
  inputProps?: Record<string, unknown>;
  // if apiHandler is not passed, we need to make selectionEnum mandatory using typescript discriminated unions
} & (SelectionEnumProps | ApiHandlerProps<TDto, TQuery>);

const FormikMultiSelectAutoComplete = <TDto extends BaseDto, TQuery extends GetAllBaseQueryRequest>({
  label,
  uniqueIndex,
  apiHandler,
  displayNameKey,
  filterFieldName,
  onChange,
  filterFieldValue,
  queryParams,
  selectionFields,
  field,
  form,
  defaultValue,
  defaultMode,
  marginTop,
  paddingRight,
  errorLabelPaddingLeft,
  validationMessage,
  maxWidth,
  selectionEnum,
  variant,
  CreateNewOptionComponent,
  createNewOptionTitle,
  groupNameProp,
  createNewOptionType,
  chips,
  dataCy,
  disableSelectAll,
  fontSize = "regular",
  pageSize = 20,
  onDropdownOpenChanged,
  customChipsTemplate,
  autoFocus,
  dropDownWidth = 300,
  inputProps
}: FormikMultiSelectAutoCompleteComponentProps<TDto, TQuery>) => {
  const isReadOnly = useReadOnlyContext();
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState<string | undefined>();

  const classes = useStylesTextInput();
  const deviceClassName = clsx(["DLUI_TextField", isMobile ? "MOBILE" : "WEB"]);
  const _fieldSize: FieldSizes = useMemo(() => (isMobile ? FieldSizes.MOBILE : FieldSizes.WEB), [isMobile]);
  const componentId = "AsyncAutoComplete" + uniqueIndex;

  const [
    { isLoading, options, showCreateOption, isOpen, createError, valueFilter, multiSelectInnerValue, selectedMode },
    { handleScroll, onCreate, onCreateError, setShowCreateOption, _onChange },
    setState
  ] = useAutoComplete({
    defaultValue,
    apiHandler,
    defaultMode,
    queryParams,
    selectionFields,
    displayNameKey,
    filterFieldValue,
    filterFieldName,
    componentId,
    t,
    onChangeCallback: onChange,
    pageSize,
    CreateNewOptionComponent,
    createNewOptionTitle,
    groupNameProp,
    field,
    form,
    label,
    createNewOptionType,
    marginTop,
    selectionEnum,
    multiple: true
  });
  const allSelected = options?.length === multiSelectInnerValue?.length;

  const _onInputChange = (event: React.ChangeEvent<{}>, value: string) => {
    if (value !== valueFilter) {
      setState({ valueFilter: value });
    }
  };

  const inputStyle = {
    width: "100%",
    marginTop: marginTop || 0,
    fontSize: fontSizeMap[fontSize]
  };

  useEffect(() => {
    if (validationMessage !== undefined) {
      setErrorText(validationMessage);
      setHasError(true);
    } else {
      setErrorText(undefined);
      setHasError(false);
    }
  }, [validationMessage]);

  useEffect(() => {
    if (field?.name) {
      const _errorText = getIn(form?.errors, field.name);
      const _touchedVal = getIn(form?.touched, field.name);
      const _hasError = _touchedVal && _errorText !== undefined;
      setErrorText(_errorText);
      setHasError(_hasError);
    }
  }, [form?.touched, form?.values, form?.errors]);

  const renderError = () => (
    <View shouldShow={hasError !== undefined && Boolean(hasError)} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
      <View marginTop={5} height={25}>
        <Text paddingLeft={errorLabelPaddingLeft} fontSize={14} color={"error"}>
          {errorText}
        </Text>
      </View>
    </View>
  );

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (options) {
        const filteredOptions = filter(options, {
          inputValue: valueFilter || "",
          getOptionLabel: (option) => option.name
        });

        if (filteredOptions?.length === 1) {
          _onChange?.(e, [...(multiSelectInnerValue || []), ...filteredOptions]);
        }
      }
    }
  };

  const handleRenderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      autoFocus={autoFocus}
      variant={variant || "outlined"}
      label={label}
      classes={{
        root: clsx([classes.root, deviceClassName])
      }}
      style={inputStyle}
      InputProps={{
        ...params.InputProps,
        ...fieldSizesMap[_fieldSize].InputProps,
        endAdornment: <>{isLoading ? <CircularProgress color="inherit" size={20} /> : params.InputProps.endAdornment}</>
      }}
      inputProps={{
        ...params.inputProps,
        onKeyDown: handleKeyDown,
        ...inputProps
      }}
      InputLabelProps={{
        ...fieldSizesMap[_fieldSize].InputLabelProps,
        margin: _fieldSize === FieldSizes.MOBILE ? undefined : "dense",
        className: deviceClassName
      }}
    />
  );

  const renderCheckboxTags = useCallback(
    (value: MultiSelectChipsValue[], getTagProps: AutocompleteGetTagProps) => {
      const numTags = value.length;
      const chipsTemplate = chips || (value?.length <= 3 ? "showAllChips" : "counterOnly");
      const limitTags = { showAllChips: numTags, chipPlusCounter: 1, counterOnly: 0 }?.[chipsTemplate] || 0;
      const remainingSelected = numTags - limitTags;

      const text =
        customChipsTemplate?.(value) ||
        {
          showAllChips: "",
          chipPlusCounter: `+${remainingSelected} more`,
          counterOnly: `${remainingSelected} Selected`
        }?.[chipsTemplate] ||
        "";

      if (valueFilter) {
        return null;
      }

      return (
        <>
          {value.slice(0, limitTags).map((option, index) => (
            <View autoWidth maxWidth={"100%"} marginRight={3} key={index}>
              <Text
                {...getTagProps({ index })}
                value={`${option.name}${index !== value.length - 1 ? "," : ""}`}
                overFlow={"ellipsis"}
                numberOfLines={1}
                fontSize="inherit"
              />
            </View>
          ))}
          {numTags > limitTags && (
            <div style={{ position: "absolute", zIndex: 2 }}>
              <Text fontSize="inherit" bold>
                {text}
              </Text>
            </div>
          )}
        </>
      );
    },
    [chips, valueFilter]
  );

  const renderCheckboxOption = (option: any, { selected }: AutocompleteRenderOptionState) =>
    option.CreateNewOptionComponent ? (
      <CreateOptionItem
        inputValue={valueFilter || ""}
        plusIconProps={{ marginLeft: 12 }}
        option={{
          ...option,
          filterOptions: queryParams
        }}
      />
    ) : (
      <Box height={25} display={"flex"} alignItems={"center"}>
        <ControlledCheckBox id={option?.id} checked={option?.id === SELECT_ALL_ID ? allSelected : selected} />
        <Text overFlow="ellipsis" numberOfLines={2} lineBreak={"auto"} fontSize={fontSizeMap[fontSize]}>
          {option.name}
        </Text>
      </Box>
    );

  const AutocompletePopper = React.useCallback(
    (props) => (
      <Popper
        {...props}
        style={{ ...props.style, minWidth: isMobile ? undefined : dropDownWidth }}
        placement="bottom-end"
        data-cy={DataCy.DLUI.popper}
      />
    ),
    [isMobile]
  );

  const onClose = () => {
    if (showCreateOption) {
      return;
    }

    setState({ isOpen: false });
    onDropdownOpenChanged?.(false);
  };

  const onOpen = () => {
    setState({ isOpen: true });
    onDropdownOpenChanged?.(true);
  };

  return (
    <View
      maxWidth={maxWidth}
      className={clsx([hasError ? "error" : "", isMobile ? "mobile" : ""])}
      paddingRight={paddingRight}
    >
      <Autocomplete
        openOnFocus
        disabled={isReadOnly}
        className={field?.value !== "" ? "notEmpty" : ""}
        classes={{
          root: "FormikMultiSelectAutoComplete AutoComplete",
          popper: "FormikMultiSelectAutoComplete AutoComplete"
        }}
        id={componentId}
        open={isOpen}
        onOpen={onOpen}
        PopperComponent={AutocompletePopper}
        onClose={onClose}
        onChange={_onChange}
        clearOnBlur={!showCreateOption}
        onInputChange={_onInputChange}
        multiple
        value={multiSelectInnerValue}
        getOptionSelected={(option: { name?: string; id?: string }, value: { name?: string; id?: string }) =>
          // if option has id - compare by id, else compare by name
          option.id ? option.id === value.id : option.name === value.name
        }
        loading={isLoading}
        data-cy={dataCy || field?.name}
        options={options!}
        disableCloseOnSelect
        disableListWrap
        getOptionLabel={(option) => option.name}
        renderTags={renderCheckboxTags}
        ListboxProps={{
          onScroll: handleScroll
        }}
        renderInput={handleRenderInput}
        renderOption={renderCheckboxOption}
        filterOptions={(options, params) =>
          handleFilterOptions(options, params, {
            setState,
            CreateNewOptionComponent,
            isLoading,
            groupNameProp,
            createNewOptionTitle,
            createError,
            onCreateError,
            queryParams,
            createNewOptionType,
            onCreate,
            multiple: true,
            setShowCreateOption,
            disableSelectAll,
            t
          })
        }
      />
      {renderError()}
    </View>
  );
};

export default FormikMultiSelectAutoComplete;
