import type { UserRoleDto } from "@doorloop/dto";
import { ReportPermission, SettingPermission } from "@doorloop/dto";
import type { FC } from "react";
import React, { useCallback } from "react";
import { useFormikContext } from "formik";
import { usePermissionDependency } from "screens/settings/userRoles/dependencies/usePermissionDependency";
import { ReportSettingsRadioToggle } from "DLUI/reports/settings/reportSettingsRadioToggle";

interface ReportPermissionProps {
  permission: ReportPermission;
  label: string;
  fontSize?: number;
  fontWeight?: number;
}

export const ReportPermissionToggle: FC<ReportPermissionProps> = ({
  permission,
  label,
  fontSize,
  fontWeight
}: ReportPermissionProps) => {
  const formikRef = useFormikContext<UserRoleDto>();
  const { applyReportPermission } = usePermissionDependency(formikRef);
  const applyDependency = useCallback(
    (newValue) => {
      if (permission === ReportPermission.managementFees) {
        if (newValue) {
          formikRef.setFieldValue(SettingPermission.managementFees, "edit");
        } else {
          formikRef.setFieldValue(SettingPermission.managementFees, "none");
        }
      }
      return applyReportPermission(permission, newValue);
    },
    [permission]
  );

  return (
    <ReportSettingsRadioToggle
      fieldName={permission}
      label={label}
      applyDependency={applyDependency}
      fontSize={fontSize}
      fontWeight={fontWeight}
    />
  );
};
