import type { FC } from "react";
import React from "react";
import { View } from "DLUI/view";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import { FastField, useFormikContext } from "formik";
import { TextField, ValidationIndicator } from "DLUI/form";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import type { AnnouncementDto } from "@doorloop/dto";
import { MAX_TOTAL_EMAIL_ATTACHMENTS_SIZE_IN_MB } from "@doorloop/dto";
import Text from "DLUI/text";
import { RichTextField } from "DLUI/form/richTextField/richTextField";
import { DEFAULT_MAX_FILE_UPLOAD_SIZE_ALLOWED_IN_MB } from "DLUI/dropZone/utils";

interface Props {
  attachments: FileListItemProps[];
  setAttachments: (files: FileListItemProps[]) => void;
  bodyTooLongError: boolean;
  maxCharsDefault: number;
  maxCharsSms: number;
  maxCharsVoice: number;
}

export const AnnouncementContent: FC<Props> = ({
  attachments,
  setAttachments,
  bodyTooLongError,
  maxCharsVoice,
  maxCharsSms,
  maxCharsDefault
}) => {
  const { t } = useTranslation();
  const {
    values: { emailInfo, textInfo, voiceInfo, portalInfo, bodyHTML }
  } = useFormikContext<AnnouncementDto>();
  const enforceShortContent = Boolean(textInfo?.send || voiceInfo?.send);

  /**
   * Pick the minimum of the max chars for the selected delivery methods
   */
  let maxChars = maxCharsDefault;

  if (voiceInfo?.send) {
    maxChars = maxCharsVoice;
  }

  if (textInfo?.send) {
    maxChars = maxCharsSms;
  }

  const maxTotalFilesSizeInMB = emailInfo?.send ? MAX_TOTAL_EMAIL_ATTACHMENTS_SIZE_IN_MB : undefined;
  const maxFileSizeInMB = emailInfo?.send
    ? MAX_TOTAL_EMAIL_ATTACHMENTS_SIZE_IN_MB
    : DEFAULT_MAX_FILE_UPLOAD_SIZE_ALLOWED_IN_MB;

  return (
    <View>
      <FastField
        component={TextField}
        label={t(AppStrings.Tasks.InternalTaskDialog.Subject)}
        name={`subject`}
        marginTop={20}
        multiLineSize={"normal"}
        required
      />
      <FastField
        component={RichTextField}
        label={t(AppStrings.Announcements.announcementContent)}
        name={`body`}
        html={{ name: "bodyHTML", value: bodyHTML }}
        required
        styles={{ container: { marginTop: 20 } }}
        maxLength={maxChars}
      />
      {enforceShortContent && (
        <Text
          value={AppStrings.Announcements.characterCapMessage}
          replaceObject={{
            smsMaxChars: maxCharsSms,
            voiceMaxChars: maxCharsVoice
          }}
          fontSize={12}
          color={"secondary-gray"}
        />
      )}
      <ValidationIndicator
        shouldShow={bodyTooLongError}
        displayText={t(AppStrings.Announcements.bodyTooLongError)}
        justifyContent={"center"}
        fullWidth
      />
      {(emailInfo?.send || portalInfo?.send) && (
        <View justifyContent={"flex-end"} width={"100%"} flex={1} marginTop={10} marginBottom={20}>
          <FormAttachments
            onFileReceived={setAttachments}
            files={attachments}
            maxFileSizeInMB={maxFileSizeInMB}
            maxTotalFilesSizeInMB={maxTotalFilesSizeInMB}
          />
        </View>
      )}
    </View>
  );
};
