import { accountsApi } from "api/accounts";
import { AlertCircleIcon, BillGrayIcon, CalendarIconBlue, CreaditGrayIcon } from "assets/icons";
import { ReferenceLabel } from "DLUI/form";
import { Icon } from "DLUI/icon/icon";
import { Grid, ListItemContainer, ListItemSection } from "DLUI/listItems";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import ListItemText from "DLUI/listItems/listItemText";
import { Routes } from "../../../appRouter";
import { DialogRoutes } from "DLUI/screen/dialogsProvider";
import { unitsApi } from "api/unitsApi";

export enum OutstandingAllocationType {
  Bill = "bill",
  Credit = "credit"
}

interface ComponentProps {
  type: OutstandingAllocationType;
  transactionDate: string;
  reference?: string;
  balance?: number;
  accountId: string;
  headerId: string;
  amount?: number;
  transactionId?: string;
  onDeleteButtonPress?: () => void;
  unit?: string;
}

const OutstandingAllocationItem: React.FC<ComponentProps> = ({
  type,
  transactionDate,
  amount,
  reference,
  balance,
  accountId,
  headerId,
  transactionId,
  onDeleteButtonPress,
  unit
}: ComponentProps) => {
  const [accountName, setAccountName] = useState<string | undefined>();
  const [unitName, setUnitName] = useState<string | undefined>();
  const didPressEditItem = () => {};

  const getUnitName = async () => {
    if (unit) {
      const unitsFromDictionary = await unitsApi.getDictionary();
      const unitName = unitsFromDictionary[unit].name;

      setUnitName(unitName);
    }
  };

  const _ListItemIcon = useMemo(() => {
    if (type === OutstandingAllocationType.Bill) {
      return BillGrayIcon;
    }
    return CreaditGrayIcon;
  }, []);

  const getAccountName = async () => {
    const accountsDictionary = await accountsApi.getDictionary();
    const accountName = accountsDictionary[accountId].name;

    setAccountName(accountName);
  };

  const daysToPayBill = useMemo(() => {
    const duration = moment.duration(moment(transactionDate).diff(moment()));
    let days = duration.asDays();

    if (days > 0) {
      days = Math.ceil(duration.asDays());
    } else {
      days = Math.floor(Math.abs(duration.asDays()));
    }
    return days;
  }, []);

  const exceededBillDueDate = daysToPayBill < 0;

  useEffect(() => {
    getAccountName();
    getUnitName();
  }, []);

  const renderTransactionDate = () => {
    if (type === OutstandingAllocationType.Credit) {
      return (
        <View flexDirection={"row"} justifyContent={"center"} alignItems={"flex-start"}>
          <Icon Source={CalendarIconBlue} pathColor={"black"} />
          <Text fullWidth color={"black"} fontSize={14} formatType={"date"} value={transactionDate} marginLeft={10} />
        </View>
      );
    }
    return (
      <ListItemSection
        renderTitle={
          <View flexDirection={"row"} justifyContent={"center"} alignItems={"flex-start"}>
            <Icon Source={CalendarIconBlue} pathColor={"black"} />
            <Text fullWidth color={"black"} fontSize={14} formatType={"date"} value={transactionDate} marginLeft={10} />
          </View>
        }
        renderSubTitle={
          <View alignItems={"center"} flexDirection={"row"}>
            {exceededBillDueDate ? <Icon Source={AlertCircleIcon} marginRight={5} /> : null}
            <Text
              color={exceededBillDueDate ? "error" : "gray"}
              fontSize={14}
              value={AppStrings.Bills.PayBills.Screen.BillDue}
            />
            <Text
              color={exceededBillDueDate ? "error" : "gray"}
              fontSize={14}
              formatType={"relative-time"}
              value={String(daysToPayBill)}
              marginLeft={5}
            />
          </View>
        }
      />
    );
  };

  return (
    <View>
      <ListItemContainer
        backgroundColor={"dark"}
        removeVerticalPadding
        id={headerId}
        deleteProps={{ onClick: onDeleteButtonPress }}
      >
        <Grid title={AppStrings.Bills.PayBills.Screen.Transaction} showDivider xs={12} md={3} lg={4}>
          <View alignItems={"center"} flexDirection={"row"}>
            <Icon Source={_ListItemIcon} width={40} height={40} pathColor={"black"} marginLeft={10} />
            <ListItemSection
              renderTitle={
                <ListItemText
                  href={
                    transactionId
                      ? `${Routes.TRANSACTIONS_LIST_REPORT}/${DialogRoutes.EDIT_VENDOR_BILL_PAYMENT}/${transactionId}`
                      : undefined
                  }
                  openInNewTab
                  marginLeft={20}
                  color={"black"}
                  overflow={"ellipsis"}
                  numberOfLines={2}
                  fontWeight={700}
                  value={
                    type === OutstandingAllocationType.Bill ? accountName : AppStrings.Leases.NewLease.LeaseRent.Credit
                  }
                />
              }
              renderSubTitle={
                <View marginLeft={20} justifyContent={"flex-start"}>
                  <ListItemText numberOfLines={1} overflow={"ellipsis"} color={"gray"}>
                    {unitName}
                  </ListItemText>
                </View>
              }
            />
            <ReferenceLabel backgroundColor={"secondary-gray"} value={reference} marginRight={20} />
          </View>
        </Grid>
        <Grid title={AppStrings.Leases.NewLease.LeaseRent.DueDate} showDivider xs={12} md={3} lg={3}>
          {renderTransactionDate()}
        </Grid>
        <Grid title={AppStrings.Leases.ListHeader.Balance} showDivider xs={12} md={3} lg={2} onClick={didPressEditItem}>
          <View paddingRight={10} alignItems={"flex-end"}>
            <Text color={"black"} fontSize={14} value={String(balance)} formatType={"currency"} />
          </View>
        </Grid>
        <Grid title={AppStrings.Leases.LeaseTransaction.Payment.PaymentAmount} xs={12} md={3} lg={3}>
          <View alignItems={"flex-end"} paddingLeft={20} paddingRight={20}>
            <Text value={String(amount)} fontSize={14} color={"black"} formatType={"currency"} fullWidth />
          </View>
        </Grid>
      </ListItemContainer>
    </View>
  );
};

export default OutstandingAllocationItem;
