import type { FC } from "react";
import { DataCy } from "@doorloop/dto";

import { View } from "DLUI/view";
import { Icon } from "DLUI/icon";
import { IconButton } from "DLUI/form";
import { CloseCircleDarkIcon, type SVGIconComponent } from "assets/icons";

interface Props {
  icon: SVGIconComponent;
  iconWidth?: number;
  iconHeight?: number;
  onDismiss?: () => void;
  dataCy?: string;
}

const SuccessDialogHeader: FC<Props> = ({ onDismiss, icon: IconSource, iconHeight, iconWidth }) => (
  <View fullWidth flexDirection={"row"} justifyContent={"center"}>
    <Icon Source={IconSource} width={iconWidth} height={iconHeight} />

    {onDismiss && (
      <div style={{ position: "absolute", top: 0, right: 0 }}>
        <IconButton
          onClick={onDismiss}
          Icon={CloseCircleDarkIcon}
          size={20}
          removePadding
          dataCy={DataCy.dialog.xButton}
        />
      </div>
    )}
  </View>
);

export { SuccessDialogHeader };
