import { apiHelper } from "api/apiHelper";
import type { CheckbookAccountClientMap } from "@doorloop/dto";
import { CheckbookSettingsDto, ServerRoutes } from "@doorloop/dto";
import { v4 as uuid } from "uuid";
import { store } from "store/index";
import { updateIsFeatureEnabled } from "store/auth/actions";

class CheckbookApi {
  constructor() {}

  async getDetails() {
    const dto = await apiHelper.axiosGet<CheckbookSettingsDto>(ServerRoutes.SETTINGS_CHECKBOOK);
    const accountsMapping: CheckbookAccountClientMap[] = [];
    dto.data?.checkbookBankAccounts?.forEach(({ name, id }) => {
      const matchingDLAccountIndex = dto.data?.accountMappings?.findIndex(
        ({ checkbookBankId }) => checkbookBankId === id
      );
      if (matchingDLAccountIndex !== undefined && matchingDLAccountIndex > -1) {
        const doorloopAccountId = dto.data?.accountMappings
          ? dto.data?.accountMappings[matchingDLAccountIndex].doorloopAccountId
          : undefined;
        accountsMapping.push({
          uniqueIndex: uuid(),
          checkbookBankName: name,
          checkbookBankId: id,
          doorloopAccountId
        });
      } else {
        accountsMapping.push({
          uniqueIndex: uuid(),
          checkbookBankName: name,
          checkbookBankId: id
        });
      }
    });
    if (!dto.data) {
      dto.data = new CheckbookSettingsDto();
    }
    dto.data.accountMappings = accountsMapping;
    return dto;
  }

  async update(id: string, settings: CheckbookSettingsDto) {
    const response = await apiHelper.axiosPut<CheckbookSettingsDto>({
      url: ServerRoutes.SETTINGS_CHECKBOOK,
      data: settings
    });

    if (response.status) {
      store.dispatch(updateIsFeatureEnabled(Boolean(response.data?.enabled), "SendPayments"));
    }

    return response;
  }
}

export const checkbookApi = new CheckbookApi();
