import type { FC } from "react";
import React, { useMemo, useState } from "react";
import { View } from "DLUI/view";
import { DialogFrame } from "DLUI/dialogs";
import { BulkConfirmation } from "DLUI/bulkExecution/bulkConfirmation";
import { BulkExecution } from "DLUI/bulkExecution/bulkExecution";
import type { BulkExecutionDialogProps } from "DLUI/bulkExecution/bulkExecutionDialog";
import _ from "lodash";
import { bulkExecutionApi } from "api/bulkExecutionApi";

const dialogWidth = 580;
const bulkExecutionWrapperElement = React.createRef<HTMLDivElement>();

enum BulkExecutionDialogState {
  CONFIRMATION = 0,
  EXECUTION = 1
}

export const BulkExecutionDialogFrame: FC<BulkExecutionDialogProps> = ({
  operations,
  confirm,
  onFinish,
  closeHandler,
  minHeight,
  skipConfirmation = false,
  bulkExecutionDialogTimeout,
  totalAllExecutionRequestType,
  isTotalAllChecked,
  queryFilter
}) => {
  const baseDialogHeight = useMemo(
    () => (confirm?.hasCheckboxConfirmation ? 186 : 150),
    [confirm?.hasCheckboxConfirmation]
  );

  const [dialogHeight, setDialogHeight] = useState<number>(minHeight || baseDialogHeight);

  const [awaitingConfirmation, setAwaitingConfirmation] = useState<boolean>(Boolean(confirm) && !skipConfirmation);

  const handleConfirm = async () => {
    if (isTotalAllChecked && totalAllExecutionRequestType) {
      const { status } = await bulkExecutionApi.requestBulkExecution({
        getAllQuery: queryFilter || {},
        requestType: totalAllExecutionRequestType
      });

      if (status) {
        closeHandler(false);
      }
    } else {
      setAwaitingConfirmation(false);
    }
  };

  return (
    <DialogFrame
      onCloseButtonClick={_.noop}
      width={dialogWidth}
      height={dialogHeight}
      numViews={2}
      activeView={awaitingConfirmation ? BulkExecutionDialogState.CONFIRMATION : BulkExecutionDialogState.EXECUTION}
      frameType={"contentOnly"}
      useExperimentalDialogFrame
      renderView={({ index }: { index: BulkExecutionDialogState }) => (
        <View
          style={{ background: "white" }}
          borderRadius={10}
          height={"100%"}
          overflow={"hidden"}
          flexDirection={"row"}
        >
          <View
            domRef={bulkExecutionWrapperElement}
            paddingLeft={20}
            paddingRight={20}
            paddingTop={20}
            paddingBottom={20}
            noWrap
          >
            {index === BulkExecutionDialogState.CONFIRMATION && confirm ? (
              <BulkConfirmation {...confirm} closeHandler={closeHandler} onConfirm={handleConfirm} />
            ) : (
              <BulkExecution
                closeHandler={closeHandler}
                operations={operations}
                onFinish={onFinish}
                updateWrapperHeight={() => {
                  setTimeout(() => {
                    if (bulkExecutionWrapperElement.current) {
                      const newHeight = bulkExecutionWrapperElement.current.clientHeight + 5;
                      setDialogHeight(newHeight);
                    }
                  }, 0);
                }}
                bulkExecutionDialogTimeout={bulkExecutionDialogTimeout}
              />
            )}
          </View>
        </View>
      )}
    />
  );
};
