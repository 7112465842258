import type { FC } from "react";
import React from "react";
import AppStrings from "locale/keys";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { PortalDescription } from "screens/announcements/newAnnouncement/deliveryMethod/portalDescription";
import type { FormikProps } from "formik";
import { Formik } from "formik";
import { MethodComponent } from "screens/announcements/newAnnouncement/deliveryMethod/methodComponent";
import { SeparationLine } from "DLUI/separatorView";
import type { AnnouncementDto, ClientAnnouncementDto } from "@doorloop/dto";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { useTranslation } from "react-i18next";

function omitEmptyArrays<T>(object: T, properties: string[]): Partial<T> {
  properties.forEach((property) => {
    if (object[property]?.length === 0) {
      delete object[property];
    }
  });
  return object;
}

interface Props {
  dto: AnnouncementDto;
  updateHandler: (dto: AnnouncementDto) => void;
  dismissHandler: () => void;
  deleteHandler: () => void;
}

export const EditAnnouncementForm: FC<Props> = ({ dto, updateHandler, dismissHandler, deleteHandler }: Props) => {
  const { t } = useTranslation();

  const updateAnnouncement = async (formikRef: FormikProps<ClientAnnouncementDto>) => {
    const formikValues = formikRef.values;

    const hasExpirationDateError = Boolean(
      formikValues.portalInfo?.send && formikValues.portalInfoShouldExpire && !formikValues.portalInfo?.expiresOn
    );

    if (hasExpirationDateError) {
      await formikRef.setFieldTouched("portalInfo.expiresOn", true);
      await formikRef.setFieldError("portalInfo.expiresOn", t(AppStrings.Common.Validation.Required));

      return false;
    }

    const dto = omitEmptyArrays(formikValues, ["properties", "units", "tenants"]);

    if (dto.id) {
      updateHandler(dto);
    }
  };

  return (
    <Formik initialValues={dto || {}} onSubmit={() => {}}>
      {(formikRef) => (
        <View height={"100%"}>
          <View flex={1} fullWidth>
            <View paddingTop={20} paddingRight={20} paddingLeft={20}>
              <View
                style={{ background: "#FEFBF2" }}
                borderRadius={7}
                paddingTop={15}
                paddingBottom={15}
                paddingRight={20}
                paddingLeft={20}
              >
                <Text fontSize={16} bold value={AppStrings.Announcements.pleaseNote} />
                <Text fontSize={16} value={AppStrings.Announcements.announcementSentNotification1} paddingTop={5} />
                <Text fontSize={16} value={AppStrings.Announcements.announcementSentNotification2} />
              </View>
              <SeparationLine width={580} height={2} marginTop={30} marginBottom={30} />
              <Text fontSize={16} bold marginBottom={20} value={AppStrings.Announcements.announcementOptions} />
              <MethodComponent
                name={"portalInfo.send"}
                mainText={AppStrings.Announcements.sendByTenantPortal}
                DescriptionView={PortalDescription}
              />
            </View>
          </View>
          <View marginBottom={10}>
            <FormActionButtons
              propsActionPanel={{ editMode: true, onDeleteButtonPress: deleteHandler }}
              propsSubButton={{ onClick: dismissHandler }}
              propsMainButton={{ type: "cta", props: { onClick: async () => await updateAnnouncement(formikRef) } }}
            />
          </View>
        </View>
      )}
    </Formik>
  );
};
