"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScreeningRequestReportsStatus = exports.RentalApplicationTransunionInfoDto = void 0;
class RentalApplicationTransunionInfoDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.RentalApplicationTransunionInfoDto = RentalApplicationTransunionInfoDto;
var ScreeningRequestReportsStatus;
(function (ScreeningRequestReportsStatus) {
    ScreeningRequestReportsStatus["IDENTITY_VERIFICATION_PENDING"] = "IdentityVerificationPending";
    ScreeningRequestReportsStatus["IDENTITY_VERIFICATION_FAILED"] = "IdentityVerificationFailed";
    ScreeningRequestReportsStatus["IDENTITY_VERIFICATION_SUCCESS"] = "IdentityVerificationSuccess";
    ScreeningRequestReportsStatus["SCREENING_REQUEST_CANCELED"] = "ScreeningRequestCanceled";
    ScreeningRequestReportsStatus["READY_FOR_REPORT_REQUEST"] = "ReadyForReportRequest";
    ScreeningRequestReportsStatus["PAYMENT_FAILURE"] = "PaymentFailure";
    ScreeningRequestReportsStatus["PAYMENT_SUCCESS"] = "PaymentSuccess";
    ScreeningRequestReportsStatus["REPORTS_REQUESTED"] = "ReportsRequested";
    ScreeningRequestReportsStatus["REQUEST_REPORTS_DISABLED"] = "RequestReportsDisabled";
    ScreeningRequestReportsStatus["REPORTS_DELIVERY_IN_PROGRESS"] = "ReportsDeliveryInProgress";
    ScreeningRequestReportsStatus["REPORTS_DELIVERY_FAILED"] = "ReportsDeliveryFailed";
    ScreeningRequestReportsStatus["REPORTS_DELIVERY_SUCCESS"] = "ReportsDeliverySuccess";
    ScreeningRequestReportsStatus["RETRY_LIMIT_EXCEEDED"] = "RetryLimitExceeded";
    ScreeningRequestReportsStatus["SCREENING_REQUEST_EXPIRED"] = "ScreeningRequestExpired";
    ScreeningRequestReportsStatus["ADDRESS_NOT_VALID"] = "ApplicantCurrentAddressNotValid";
})(ScreeningRequestReportsStatus || (exports.ScreeningRequestReportsStatus = ScreeningRequestReportsStatus = {}));
