import React from "react";
import type { PropertyDto } from "@doorloop/dto";
import { ListItemSection } from "DLUI/listItems";
import ListItemText from "DLUI/listItems/listItemText";
import { View } from "DLUI/view";
import type { ViewComponentProps } from "DLUI/view/view";
import { RentalsIconNew } from "assets/icons/.";
import LeaseSettingsIcon from "DLUI/dialogs/settings/common/settings/leaseSettingsIcon";
import { getAddressText } from "DLUI/reports/persons/personGridColumns";

interface ComponentProps {
  property: Pick<PropertyDto, "name" | "address">;
}

export const PropertyListItem = ({
  property,
  ...viewProps
}: ComponentProps & Exclude<ViewComponentProps, ComponentProps>) => (
  <View
    cursor={viewProps.onClick ? "pointer" : viewProps.cursor}
    paddingBottom={10}
    paddingTop={10}
    paddingLeft={5}
    paddingRight={5}
    backgroundColor="secondary-gray-light"
    border="1px solid rgba(122, 134, 153, 0.15)"
    borderRadius={10}
    alignItems={"center"}
    flexDirection={"row"}
    {...viewProps}
  >
    <LeaseSettingsIcon size={30} containerSize={{ height: 50, width: 50 }} Icon={RentalsIconNew} />
    <ListItemSection
      renderTitle={
        <ListItemText color={"black"} align="left" fontWeight={700} overflow={"ellipsis"} numberOfLines={1}>
          {property.name}
        </ListItemText>
      }
      renderSubTitle={
        property.address ? (
          <View gap={5} flexDirection={"row"}>
            <View flex={1}>
              <ListItemText overflow={"ellipsis"} color={"gray"}>
                {getAddressText(property.address)}
              </ListItemText>
            </View>
          </View>
        ) : null
      }
    />
  </View>
);
