"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayrixPaymentTokenBankRequest = exports.Payment = exports.Customer = void 0;
// Generated by https://quicktype.io
//
// To change quicktype's target language, run command:
//
//   "Set quicktype target language"
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const isBankAccountNumber_validator_1 = require("../../common/validators/isBankAccountNumber.validator");
const isEqualTo_validator_1 = require("../../common/validators/isEqualTo.validator");
const isRoutingNumber_validator_1 = require("../../common/validators/isRoutingNumber.validator");
const payrixPaymentTokenBankType_enum_1 = require("./payrixPaymentTokenBankType.enum");
class Customer {
}
exports.Customer = Customer;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(50),
    __metadata("design:type", String)
], Customer.prototype, "first", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(50),
    __metadata("design:type", String)
], Customer.prototype, "last", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(50),
    __metadata("design:type", String)
], Customer.prototype, "merchant", void 0);
class Payment {
}
exports.Payment = Payment;
__decorate([
    (0, class_validator_1.IsEnum)(payrixPaymentTokenBankType_enum_1.PayrixPaymentTokenBankType),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], Payment.prototype, "method", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, isBankAccountNumber_validator_1.IsBankAccountNumber)({ message: "Invalid account number" }),
    __metadata("design:type", String)
], Payment.prototype, "number", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, isEqualTo_validator_1.IsEqualTo)("number", { message: "Account number don't match" }),
    __metadata("design:type", String)
], Payment.prototype, "accountNumberConfirmation", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, isRoutingNumber_validator_1.IsRoutingNumber)({ message: "Invalid routing number" }),
    __metadata("design:type", String)
], Payment.prototype, "routing", void 0);
class PayrixPaymentTokenBankRequest {
}
exports.PayrixPaymentTokenBankRequest = PayrixPaymentTokenBankRequest;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", Customer)
], PayrixPaymentTokenBankRequest.prototype, "customer", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => Payment),
    __metadata("design:type", Payment)
], PayrixPaymentTokenBankRequest.prototype, "payment", void 0);
