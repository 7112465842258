import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { Link } from "DLUI/link";

import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { useTypedTranslation } from "locale";

const ZillowInstructionView = () => {
  const { isMobile } = useResponsiveHelper();
  const { t } = useTypedTranslation();

  return (
    <View alignItems={"flex-start"} gap={16} width={isMobile ? undefined : "91%"}>
      <View noWrap gap={3}>
        <Text value={t("common.subscription.title")} bold fontSize={14} color={"secondary-gray"} marginTop={8} />
        <Text value={t("listing.toListOnZillow")} fontSize={14} color={"secondary-gray"} />
      </View>
      <View noWrap gap={3}>
        <Text value={t("listing.setupRequirement")} bold fontSize={14} color={"secondary-gray"} />
        <View flexDirection={"row"} flex={1} gap={3} alignItems={"center"}>
          <Text value={t("listing.toSetupYourAccount")} fontSize={14} color={"secondary-gray"} />
          <Link hrefUrl={"mailto:rentalfeedinquiries@zillowgroup.com"} underline={"always"} type={"tab"}>
            <Text fontSize={14} color={"secondary-gray"}>
              rentalfeedinquiries@zillowgroup.com
            </Text>
          </Link>
          <Text value={t("listing.provideCompanyId")} fontSize={14} color={"secondary-gray"} />
        </View>
      </View>
    </View>
  );
};

export default ZillowInstructionView;
