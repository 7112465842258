import type { DeepKeys, LateFeeChargeDto } from "@doorloop/dto";
import { DataCy } from "@doorloop/dto";
import { useGeneralStyles } from "styles/generalStyles";
import clsx from "clsx";
import { getIn, useFormikContext } from "formik";
import { useTypedTranslation } from "locale";
import type { LateFeeChargeItemFormProps } from "DLUI/dialogs/settings/common/lateFees/lateFeeChargeItemForm";
import { LateFeeChargeItemForm } from "DLUI/dialogs/settings/common/lateFees/lateFeeChargeItemForm";
import { useResponsiveHelper } from "contexts/responsiveContext";
import type { CSSProperties } from "react";
import { useCallback, useMemo, useRef } from "react";
import { get } from "lodash";
import IconButton from "DLUI/form/iconButton/iconButton";
import { ChevronLeftGray } from "@/assets";
import Text from "DLUI/text";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import { useMobileStyles } from "DLUI/dialogs/settings/common/settings/mobileStyles";
import { useLateFeeSettings } from "DLUI/dialogs/settings/common/lateFees/useLateFeeSettings";
import type { LateFeesFormTypes } from "DLUI/dialogs/settings/common/lateFees/types";

export type LateFeeChargeItemFormMobileProps = LateFeeChargeItemFormProps & {
  mode?: "add" | "edit";
  onClose: () => void;
};

const BUTTON_STYLE: CSSProperties = { height: 48, flex: 1 };

export const LateFeeChargeItemFormMobile = ({ mode = "edit", onClose, ...props }: LateFeeChargeItemFormMobileProps) => {
  const { arrayHelpers, formikPathName, index } = props;
  const formikRef = useFormikContext<LateFeesFormTypes>();
  const { values, errors } = formikRef;
  const { screenContainerPadding } = useResponsiveHelper();
  const mobileClasses = useMobileStyles({ screenContainerPadding });
  const generalClasses = useGeneralStyles();
  const { getFieldPath } = useLateFeeSettings({ formikPathName });
  const lateFeeChargePrefix: DeepKeys<LateFeesFormTypes> = getFieldPath("lateFeeCharges");
  const chargesPathWithIndex = `${lateFeeChargePrefix}[${index}]`;
  const rule: LateFeeChargeDto | undefined = useMemo(
    () => getIn(values, chargesPathWithIndex),
    [values, chargesPathWithIndex]
  );
  const initialRule = useRef<LateFeeChargeDto | undefined>(rule);

  const isRuleValid = useMemo(() => {
    const errorsObject = get(errors, chargesPathWithIndex);
    return errorsObject ? Object.keys(errorsObject).length === 0 : true;
  }, [errors, chargesPathWithIndex]);

  const { t } = useTypedTranslation();

  const handleExitWithoutSave = useCallback(() => {
    if (mode === "add") {
      arrayHelpers?.remove(arrayHelpers.form.values.lateFeeCharges.length - 1);
    }
    if (mode === "edit") {
      const { current: initialData } = initialRule;
      arrayHelpers.replace(index, initialData);
    }

    onClose();
  }, [arrayHelpers, onClose, index, mode]);

  const headerText = t(mode === "add" ? "lateFee.addLateFee" : "lateFee.editLateFee");

  const finishText = t(
    mode === "add"
      ? "settings.generalSettings.feesMobileForm.addFee"
      : "settings.generalSettings.feesMobileForm.saveFeeChanges"
  );

  return (
    <div
      className={clsx(generalClasses.flexColumn, generalClasses.largeGap, mobileClasses.ruleItemMobileContainer)}
      data-cy={DataCy.globalSettings.lateFees.rules.rule.mobileFormContainer}
    >
      <div className={mobileClasses.ruleItemMobileHeader}>
        <IconButton Icon={ChevronLeftGray} onClick={handleExitWithoutSave} size={14} width={30} />
        <Text value={headerText} fontSize={20} fontWeight={700} />
      </div>
      <div className={clsx(mobileClasses.ruleItemMobileContent)}>
        <LateFeeChargeItemForm {...props} />
      </div>
      <div className={clsx(mobileClasses.ruleItemMobileContent, mobileClasses.ruleItemMobileButtons)}>
        <DLButton
          onClick={handleExitWithoutSave}
          actionText={t("common.cancel")}
          variant={DLButtonVariantsEnum.CONTAINED}
          color={DLButtonColorsEnum.NEUTRAL}
          size={DLButtonSizesEnum.MEDIUM}
          style={BUTTON_STYLE}
          dataCy={DataCy.dialogActionButtons.cancel}
          disableMobileCollapse
        />
        <DLButton
          onClick={onClose}
          actionText={finishText}
          variant={DLButtonVariantsEnum.CONTAINED}
          color={DLButtonColorsEnum.SECONDARY}
          size={DLButtonSizesEnum.MEDIUM}
          disabled={!isRuleValid}
          style={BUTTON_STYLE}
          dataCy={DataCy.dialogActionButtons.save}
          disableMobileCollapse
        />
      </div>
    </div>
  );
};
