"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalApplicationFeeSettingsDto = void 0;
const class_validator_1 = require("class-validator");
const RentalApplicationChargeOptions_enum_1 = require("./RentalApplicationChargeOptions.enum");
const screeningReportPayment_dto_1 = require("../../../rentalApplications/screeningReportPayment.dto");
const rentalApplicationFeeSettingsPostCharge_dto_1 = require("./rentalApplicationFeeSettingsPostCharge.dto");
class RentalApplicationFeeSettingsDto {
    constructor(init) {
        this.rentalApplicationChargeOption = RentalApplicationChargeOptions_enum_1.RentalApplicationChargeOptions.CHARGE_APPLICANT;
        this.screeningReportPayed = false;
        this.rentalAppFeesPayed = false;
        this.enabled = false;
        this.acceptedTermsAndConditions = true;
        Object.assign(this, init);
    }
}
exports.RentalApplicationFeeSettingsDto = RentalApplicationFeeSettingsDto;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], RentalApplicationFeeSettingsDto.prototype, "enabled", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], RentalApplicationFeeSettingsDto.prototype, "screeningReportPayed", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], RentalApplicationFeeSettingsDto.prototype, "rentalAppFeesPayed", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(RentalApplicationChargeOptions_enum_1.RentalApplicationChargeOptions),
    __metadata("design:type", String)
], RentalApplicationFeeSettingsDto.prototype, "rentalApplicationChargeOption", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], RentalApplicationFeeSettingsDto.prototype, "deductScreeningCostFromApplication", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], RentalApplicationFeeSettingsDto.prototype, "chargeApplicationFee", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => {
        var _a;
        return x.rentalApplicationChargeOption === RentalApplicationChargeOptions_enum_1.RentalApplicationChargeOptions.CHARGE_CUSTOM_FEE ||
            ((_a = x.fees) === null || _a === void 0 ? void 0 : _a.rentalApplicationChargeOption) === RentalApplicationChargeOptions_enum_1.RentalApplicationChargeOptions.CHARGE_CUSTOM_FEE;
    }),
    (0, class_validator_1.IsNumber)({ maxDecimalPlaces: 2 }),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.Min)(0),
    (0, class_validator_1.Max)(9999),
    __metadata("design:type", Number)
], RentalApplicationFeeSettingsDto.prototype, "applicationFee", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], RentalApplicationFeeSettingsDto.prototype, "requireOnlinePayment", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], RentalApplicationFeeSettingsDto.prototype, "requestTransunionReports", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.enabled),
    (0, class_validator_1.Equals)(true),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], RentalApplicationFeeSettingsDto.prototype, "acceptedTermsAndConditions", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(screeningReportPayment_dto_1.ReportPaymentOptions),
    __metadata("design:type", String)
], RentalApplicationFeeSettingsDto.prototype, "userSelectedPaymentMethod", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    __metadata("design:type", rentalApplicationFeeSettingsPostCharge_dto_1.RentalApplicationFeeSettingsPostChargeDto)
], RentalApplicationFeeSettingsDto.prototype, "postCharge", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(screeningReportPayment_dto_1.PayedType),
    __metadata("design:type", String)
], RentalApplicationFeeSettingsDto.prototype, "payedType", void 0);
