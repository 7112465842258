"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BusinessInfo = void 0;
const isDateStringWithoutTime_validator_1 = require("../../common/validators/isDateStringWithoutTime.validator");
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const class_validator_2 = require("class-validator");
const isTIN_validator_1 = require("../../common/validators/isTIN.validator");
const address_dto_1 = require("./address.dto");
const businessType_enum_1 = require("./businessType.enum");
// https://portal.payrix.com/docs/index/boarding
class BusinessInfo {
}
exports.BusinessInfo = BusinessInfo;
__decorate([
    (0, class_validator_2.IsString)(),
    (0, class_validator_2.IsNotEmpty)(),
    (0, class_validator_2.MaxLength)(100),
    __metadata("design:type", String)
], BusinessInfo.prototype, "name", void 0);
__decorate([
    (0, class_validator_2.IsString)(),
    (0, class_validator_2.IsNotEmpty)(),
    (0, class_validator_2.MaxLength)(50),
    (0, class_validator_1.Matches)(new RegExp("^[a-zA-Z0-9_ &\\-,'.]*$")),
    __metadata("design:type", String)
], BusinessInfo.prototype, "dba", void 0);
__decorate([
    (0, class_validator_2.ValidateIf)((x) => !x.ein_encrypted),
    (0, class_validator_2.IsNotEmpty)(),
    (0, isTIN_validator_1.IsTIN)({
        message: "Invalid TIN"
    }),
    __metadata("design:type", String)
], BusinessInfo.prototype, "ein", void 0);
__decorate([
    (0, class_validator_2.IsOptional)(),
    __metadata("design:type", String)
], BusinessInfo.prototype, "ein_encrypted", void 0);
__decorate([
    (0, class_validator_2.IsNotEmpty)(),
    (0, class_validator_2.IsBoolean)(),
    __metadata("design:type", Boolean)
], BusinessInfo.prototype, "new", void 0);
__decorate([
    (0, class_validator_2.IsNotEmpty)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], BusinessInfo.prototype, "established", void 0);
__decorate([
    (0, class_validator_2.IsNotEmpty)(),
    (0, class_validator_2.IsNumber)({ maxDecimalPlaces: 2 }),
    (0, class_validator_2.IsPositive)(),
    __metadata("design:type", Number)
], BusinessInfo.prototype, "avgTicket", void 0);
__decorate([
    (0, class_validator_2.IsNotEmpty)(),
    (0, class_validator_2.IsEnum)(businessType_enum_1.BusinessType),
    __metadata("design:type", String)
], BusinessInfo.prototype, "type", void 0);
__decorate([
    (0, class_validator_2.IsOptional)(),
    (0, class_validator_2.IsBoolean)(),
    __metadata("design:type", Boolean)
], BusinessInfo.prototype, "public", void 0);
__decorate([
    (0, class_validator_2.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => address_dto_1.PayrixAddressDto),
    __metadata("design:type", address_dto_1.PayrixAddressDto)
], BusinessInfo.prototype, "address", void 0);
__decorate([
    (0, class_validator_2.IsNotEmpty)(),
    (0, class_validator_2.IsString)(),
    (0, class_validator_2.IsPhoneNumber)("US"),
    (0, class_validator_2.Length)(5, 15),
    __metadata("design:type", String)
], BusinessInfo.prototype, "phone", void 0);
__decorate([
    (0, class_validator_2.IsNotEmpty)(),
    (0, class_validator_2.IsEmail)(),
    (0, class_validator_2.MaxLength)(100),
    __metadata("design:type", String)
], BusinessInfo.prototype, "email", void 0);
__decorate([
    (0, class_validator_2.IsNotEmpty)(),
    (0, class_validator_2.IsString)(),
    (0, class_validator_2.MaxLength)(500),
    (0, class_validator_1.IsUrl)({ require_protocol: false }, { message: "Invalid URL." }),
    __metadata("design:type", String)
], BusinessInfo.prototype, "website", void 0);
