import DemoAdditionalInfo from "screens/demo/additionalInfo";
import AppStrings from "locale/keys";
import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { lazy } from "react";
import { LazyRoute } from "./lazyRoute";

const DemoSignUp = lazy(() => import("screens/demo/signup"));
const DemoSchedule = lazy(() => import("screens/demo/schedule"));

const DemoScheduleWithTitle = () => <DemoSchedule documentTitle={AppStrings.Common.ScheduleDemo} />;
const DemoAdditionalInfoWithTitle = () => (
  <DemoAdditionalInfo documentTitle={AppStrings.Common.ScheduleDemoAdditionalinfoDocumentTitle} />
);

const DemoRouter = () => (
  <PageRouter>
    <LazyRoute exact path={Routes.DEMO_SIGNUP} render={DemoSignUp} />
    <LazyRoute exact path={Routes.DEMO_SCHEDULE} render={DemoScheduleWithTitle} />
    <LazyRoute exact path={Routes.DEMO_ADDITIONAL_INFO} render={DemoAdditionalInfoWithTitle} />
  </PageRouter>
);

export default DemoRouter;
