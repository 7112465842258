"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommunicationLinkedToType = void 0;
var CommunicationLinkedToType;
(function (CommunicationLinkedToType) {
    CommunicationLinkedToType["USER"] = "USER";
    CommunicationLinkedToType["TENANT"] = "TENANT";
    CommunicationLinkedToType["OWNER"] = "OWNER";
    CommunicationLinkedToType["VENDOR"] = "VENDOR";
    CommunicationLinkedToType["SYSTEM"] = "SYSTEM";
})(CommunicationLinkedToType || (exports.CommunicationLinkedToType = CommunicationLinkedToType = {}));
