import { useTypedTranslation } from "@/locale";
import type { TaskDto } from "@doorloop/dto";
import { TaskType } from "@doorloop/dto";
import { usersApi } from "api/usersApi";
import { vendorsApi } from "api/vendorsApi";
import Text from "DLUI/text";
import type { ProfileImageProps } from "DLUI/profileImage";
import ProfileImages from "DLUI/profileImages/profileImages";
import { DefaultListItemFontSize } from "screens/leases/leases/leasesList/leaseListItem";

interface TaskAssigneesProps {
  taskDto: TaskDto;
}

const TaskAssignees = ({ taskDto }: TaskAssigneesProps) => {
  const { t } = useTypedTranslation();

  const assignToVendor = taskDto.type === TaskType.WORK_ORDER && taskDto.workOrder?.assignedToVendor;

  const getAssignedUsersProps: () => ProfileImageProps[] | undefined = () =>
    taskDto.assignedToUsers?.map((currentAssignee: string) => {
      const dictionary = usersApi.getItemFromDictionary(currentAssignee);
      let initialsLetters = "";
      if (dictionary?.name) {
        const nameParts = dictionary.name.split(" ");
        nameParts.forEach((currentPart: string) => {
          initialsLetters += currentPart.split("")[0] !== "undefined" ? currentPart.split("")[0] : "";
        });
      }

      return {
        pictureUrl: dictionary?.pictureUrl,
        initialsLetters: initialsLetters.slice(0, 2),
        bottomText: t("tasks.tasksList.user")
      };
    });

  const getAssignedVendorProps: () => ProfileImageProps | undefined = () => {
    if (taskDto.type !== TaskType.WORK_ORDER || !taskDto.workOrder?.assignedToVendor) {
      return;
    }

    const vendorDictionary = vendorsApi.getItemFromDictionary(taskDto.workOrder.assignedToVendor);
    if (!vendorDictionary) {
      return;
    }

    let initialsLetters = "";
    if (vendorDictionary.name) {
      const nameParts = vendorDictionary.name.split(" ");
      nameParts.forEach((currentPart: string) => {
        initialsLetters += currentPart.split("")[0] === undefined ? "" : currentPart.split("")[0].charAt(0);
      });
    }

    return {
      pictureUrl: vendorDictionary.pictureUrl,
      initialsLetters: initialsLetters.slice(0, 2),
      bottomText: t("bills.payBills.screen.vendor")
    };
  };

  if ((!taskDto.assignedToUsers || taskDto.assignedToUsers.length === 0) && !assignToVendor) {
    return <Text value={t("tasks.tasksList.notAssigned")} color={"black"} fontSize={DefaultListItemFontSize} />;
  }

  const assignedUsersProps = getAssignedUsersProps();
  const assignedVendorProps = getAssignedVendorProps();

  const taskAssignees = [...(assignedVendorProps ? [assignedVendorProps] : []), ...(assignedUsersProps || [])];

  return <ProfileImages items={taskAssignees} />;
};

export { TaskAssignees };
