import React, { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import type { TenantDto } from "@doorloop/dto";
import { DisplayEmailDto, DisplayPhoneDto, EmailType, LeaseTenantStepStatus, PhoneType } from "@doorloop/dto";

import AppStrings from "../../../../locale/keys";
import type { ActiveCtaProps } from "DLUI/listItems/listItemPortalStatusContent";
import { ListItemPortalStatusContent } from "DLUI/listItems/listItemPortalStatusContent";
import { tenantsApi } from "api/tenantsApi";
import { useShakeEffect } from "../../../../hooks/useShakeEffect";
import { HotCoffieIcon, SendBlueBorder } from "../../../../assets";
import { leasesApi } from "api/leasesApi";
import { usePortalStatus } from "screens/tenants/tenantsList/usePortalStatus";
import { useUserType } from "../../../../hooks/useUserType";
import { useDispatch } from "react-redux";
import { handleToast } from "store/toast/actions";

interface Props {
  tenant: TenantDto;
  onInviteSent: (tenant: TenantDto) => void;
  renderOnlyInvitationLink?: boolean;
  onClickMissingContactInfo?: (e: React.MouseEvent) => void;
  isBalanceDue?: boolean;
  ctaId?: string;
  onClickCta?: (id: string, value: boolean) => void;
  actionDisableObj?: Record<string, boolean>;
  onSendRentReminder?: (leaseId: string) => void;
  leaseId?: string;
}

const TenantListItemPortalStatus = ({
  tenant,
  onInviteSent,
  renderOnlyInvitationLink,
  onClickMissingContactInfo,
  isBalanceDue,
  ctaId,
  onClickCta,
  actionDisableObj,
  onSendRentReminder,
  leaseId
}: Props) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingText, setIsLoadingText] = useState<string>(() => t(AppStrings.Tenants.Screen.SendingInvite));
  const { ShakeEffectWrapper, runShakeEffect } = useShakeEffect({ fullWidth: true });
  const { currentStatus } = usePortalStatus(tenant);
  const { isHOAUser } = useUserType();
  const dispatch = useDispatch();

  const portalInfo = tenant?.portalInfo;

  const handleSendPaymentReminder = async () => {
    if (leaseId && tenant.id) {
      setIsLoadingText(t(AppStrings.Tenants.Screen.SendingReminder));
      setIsLoading(true);
      const result = await leasesApi.sendPaymentReminder(leaseId, tenant.id);
      if (!result.status) {
        dispatch(
          handleToast({
            severity: "error",
            translationKey: AppStrings.Common.GeneralError
          })
        );
      } else {
        await onSendRentReminder?.(leaseId);
      }
      setIsLoading(false);
    }
  };

  const activeCtaProps: ActiveCtaProps | undefined = useMemo(() => {
    if (currentStatus && [LeaseTenantStepStatus.PAYMENT_SETUP, LeaseTenantStepStatus.ACTIVE].includes(currentStatus)) {
      if (isBalanceDue && !isHOAUser) {
        return {
          textColor: "lightBlue",
          Icon: SendBlueBorder,
          text: AppStrings.Common.SendRentReminder,
          iconSize: 12,
          onClick: handleSendPaymentReminder
        };
      }
      return {
        textColor: "secondary-gray",
        Icon: HotCoffieIcon,
        text: AppStrings.Common.AllSetForNow
      };
    }
  }, [tenant]);

  const onInviteToPortalClick = async (): Promise<void> => {
    setIsLoadingText(t(AppStrings.Tenants.Screen.SendingInvite));

    if (!tenant || !tenant?.id) {
      return;
    }

    const tenantId = tenant.id;

    if ((portalInfo?.loginEmail || portalInfo?.mobilePhone) && !isLoading) {
      setIsLoading(true);

      const hasPortalEmailInContacts = tenant.emails?.find((email) => email.address === portalInfo?.loginEmail);
      if (!hasPortalEmailInContacts && portalInfo?.loginEmail) {
        tenant.emails?.unshift(
          new DisplayEmailDto({
            type: EmailType.PRIMARY,
            address: portalInfo?.loginEmail
          })
        );
      }

      const hasPortalMobilePhoneInContacts = tenant.phones?.find((phone) => phone.number === portalInfo?.mobilePhone);

      if (!hasPortalMobilePhoneInContacts && portalInfo?.mobilePhone) {
        tenant.phones?.unshift(
          new DisplayPhoneDto({
            type: PhoneType.MOBILE,
            number: portalInfo?.mobilePhone
          })
        );
      }

      const result = await tenantsApi.resendTenantPortalInvite({ id: tenantId });

      setIsLoading(false);

      if (result.status && result.data) {
        onInviteSent(result.data);
      } else {
        runShakeEffect();
      }
    }
  };

  return (
    <ShakeEffectWrapper>
      <ListItemPortalStatusContent
        onInviteToPortalClick={onInviteToPortalClick}
        sendingInviteText={isLoadingText}
        missingInfoTooltipText={t(AppStrings.Common.TenantPortalMissingInfoTooltip)}
        portalInfo={portalInfo}
        renderOnlyInvitationLink={renderOnlyInvitationLink}
        isLoading={isLoading}
        onClickMissingContactInfo={onClickMissingContactInfo}
        activeCtaProps={activeCtaProps}
        id={ctaId}
        actionDisableObj={actionDisableObj}
        onClickCta={onClickCta}
        dto={tenant}
      />
    </ShakeEffectWrapper>
  );
};

export default TenantListItemPortalStatus;
