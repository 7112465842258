import { ListItemContainerMaxWidth } from "DLUI/infiniteList/utils";
import { StickyHeader } from "DLUI/stickyHeader";
import { View } from "DLUI/view";
import React, { useMemo } from "react";
import { AnimateMarginTop } from "DLUI/animatableView";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import type { LocalSearchItem } from "../../../../constants/localSearchData";
import { LocalSearchItemType } from "../../../../constants/localSearchData";
import { LocalSearchResultSection } from "DLUI/screen/globalSearch/localSearchResultSection";
import _ from "lodash";
import AppStrings from "../../../../locale/keys";
import { useTranslation } from "react-i18next";
import type { ResultsSearchTabComponentProps } from "DLUI/screen/globalSearch/types";
import { globalSearchContstants } from "DLUI/screen/globalSearch/globalSearch.constants";

const LocalSearchTab: React.FC<ResultsSearchTabComponentProps<LocalSearchItem>> = ({
  listItems,
  handleItemClicked
}) => {
  const { isMobile } = useResponsiveHelper();
  const { t } = useTranslation();

  const { pages, actions, reports } = useMemo(() => {
    return {
      pages: listItems.filter((item) => item.type === LocalSearchItemType.PAGE),
      actions: listItems.filter((item) => item.type === LocalSearchItemType.ACTION),
      reports: listItems.filter((item) => item.type === LocalSearchItemType.REPORT)
    };
  }, [listItems]);

  return (
    <View
      alignItems={"center"}
      overflow={"scroll"}
      height={`calc(100vh - ${globalSearchContstants.searchPanelHeight}px)`}
    >
      <View maxWidth={isMobile ? ListItemContainerMaxWidth : undefined} style={{ display: "block" }}>
        {!isMobile && <StickyHeader id={"localSearchTab"} shouldShow uniqueKey={"localSearchTab"} marginTop={20} />}
        {isMobile && <AnimateMarginTop marginTop={20} />}
        <View noWrap id={"localSearchTab"}>
          {!_.isEmpty(pages) && (
            <LocalSearchResultSection
              handleItemClicked={() => handleItemClicked("Pages")}
              items={pages}
              title={t(AppStrings.DrawerItems.Pages)}
            />
          )}
          {!_.isEmpty(actions) && (
            <LocalSearchResultSection
              handleItemClicked={() => handleItemClicked("Actions")}
              items={actions}
              title={t(AppStrings.DrawerItems.Actions)}
            />
          )}
          {!_.isEmpty(reports) && (
            <LocalSearchResultSection
              handleItemClicked={() => handleItemClicked("Reports")}
              items={reports}
              title={t(AppStrings.DrawerItems.Reports)}
            />
          )}
          <View height={40} backgroundColor={"white"} />
        </View>
      </View>
    </View>
  );
};

export { LocalSearchTab };
