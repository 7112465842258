import type { PropertyOwnerDto, UnitDto } from "@doorloop/dto";
import { createValidator, PropertyDto, PropertySettingsDto, RequestsSettingsDto } from "@doorloop/dto";
import { Grid } from "@material-ui/core";
import type { ApiResult } from "api/apiResult";
import { propertiesApi } from "api/propertiesApi";
import { usersApi } from "api/usersApi";
import { RequestMaintenanceIcon } from "assets/icons";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import { FormikSwitchButton, RadioGroup } from "DLUI/form";
import { Icon } from "DLUI/icon";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, Formik } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import FormikCachedMultiSelectAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedMultiSelectAutoComplete";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";

interface ComponentProps {
  didFinishOperation: (values: UnitDto) => void;
  onBackdropClick: () => void;
  refreshEvent?: () => void;
  dialogTitle: string;
}

let formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

const validateForm = createValidator(PropertyDto);

const DialogFrameMinHeight = 630;

const EditTenantRequestsNotifications: React.FC<ComponentProps> = ({
  didFinishOperation,
  refreshEvent,
  onBackdropClick,
  dialogTitle
}: ComponentProps) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();
  const { dialogHorizontalPadding } = DialogsHelper();
  const { propertyId } = useParams<any>();
  const [propertyData, setPropertyData] = useState<PropertyDto | undefined>();
  const [viewIndex, setViewIndex] = useState(0);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);
  const [showAssignUsersWhenRequestIsReceived, setShowAssignUsersWhenRequestIsReceived] = useState<boolean>(false);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>(AppStrings.Common.NetworkErrorSubTitle);

  const [hideLoadingDialogActionButtons, setHideLoadingDialogActionButtons] = useState<boolean>(false);

  const [tenantRequestsNotificationsSelectionType, setTenantRequestsNotificationsSelectionType] = useState<
    "companyDefault" | "specifyTenantRequestsNotifications"
  >("companyDefault");

  const loadPropertyData = async () => {
    if (propertyId) {
      const response = await propertiesApi.get(propertyId).catch((e: string) => {
        showDialogError(e);
      });
      if (response && response.status) {
        if (response.data && response.data.settings && response.data.settings.tenantRequests) {
          const tenantRequests = response.data.settings.tenantRequests;
          if (tenantRequests) {
            setTenantRequestsNotificationsSelectionType("specifyTenantRequestsNotifications");
            if (tenantRequests.assignUsersWhenRequestIsReceived) {
              setShowAssignUsersWhenRequestIsReceived(true);
            }
          }
        }
        setPropertyData(response.data);
        setLoadingDialogState(DialogState.Success);
      } else {
        showDialogError();
      }
    } else {
      showDialogError();
    }
  };

  const showDialogError = (errorMessage?: string) => {
    setHideLoadingDialogActionButtons(true);
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(errorMessage || AppStrings.Common.GeneralError);
  };

  useEffect(() => {
    loadPropertyData();
    return () => {
      formikGlobalRef = null;
    };
  }, []);

  const initFormvalues = (): PropertyDto => {
    if (formikGlobalRef && formikGlobalRef.values) {
      return formikGlobalRef.values;
    }
    if (propertyData) {
      if (!propertyData.settings) {
        propertyData.settings = new PropertySettingsDto();
      }
      if (!propertyData.settings?.tenantRequests) {
        propertyData.settings.tenantRequests = new RequestsSettingsDto();
      }

      return propertyData;
    }
    return new PropertyDto();
  };

  const isValidForm = async (formikRef: FormikProps<PropertyDto>) => {
    formikRef.setFieldTouched("settings.tenantRequests.assignUsersWhenRequestIsReceivedUsers");

    const errors = (await formikRef.validateForm()) as any;
    return _.isEmpty(errors?.settings?.tenantRequests);
  };

  const updateProperty = async (values: PropertyDto) => {
    const response = (await propertiesApi.update(values.id!, values).catch((error) => {
      setLoadingDialogErrorText(error);
      setLoadingDialogState(DialogState.Error);
    })) as ApiResult<any>;

    if (response.status !== undefined && !response.status) {
      setLoadingDialogErrorText(response.message);
      setLoadingDialogState(DialogState.Error);
    } else {
      setLoadingDialogState(DialogState.Success);
      didFinishUpdateProperty();
      formikGlobalRef = null;
    }
  };

  const didPressSaveButton = async (owners?: PropertyOwnerDto[]) => {
    if (formikGlobalRef !== null && propertyData) {
      const isValid = await isValidForm(formikGlobalRef);
      if (isValid) {
        setLoadingDialogState(DialogState.Show);
        setViewIndex(1);
        updateProperty(formikGlobalRef.values);
      }
    }
  };

  const renderActionPanelButtons = () => {
    if (viewIndex === 1) {
      return <div />;
    }

    return (
      <FormActionButtons
        propsSubButton={{ onClick: onBackdropClick }}
        propsMainButton={{ type: "cta", props: { onClick: async () => await didPressSaveButton() } }}
      />
    );
  };

  const didPressDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(0);
  };

  const onRetryButtonPress = () => {
    if (formikGlobalRef !== null) {
      setLoadingDialogState(DialogState.Hidden);
      setViewIndex(0);
    }
  };

  const didFinishUpdateProperty = () => {
    if (formikGlobalRef !== null) {
      didFinishOperation(formikGlobalRef.values);
      if (refreshEvent) {
        refreshEvent();
      }
      setLoadingDialogState(DialogState.Hidden);
    }
  };

  const didChangeTenantRequestNotificationsSelection = (selectedValue: string) => {
    if (formikGlobalRef !== null) {
      if (selectedValue === "false") {
        formikGlobalRef.setFieldValue("settings.tenantRequests", undefined);
      } else {
        formikGlobalRef.setFieldValue("settings.tenantRequests", new RequestsSettingsDto());
      }
    }

    setTenantRequestsNotificationsSelectionType(
      selectedValue === "false" ? "companyDefault" : "specifyTenantRequestsNotifications"
    );
  };

  const didChangeAutomaticallyAssignUsers = (nextSwitchState) => {
    const toggleOff = !nextSwitchState;
    if (toggleOff && formikGlobalRef) {
      formikGlobalRef.setFieldValue("settings.tenantRequests.assignUsersWhenRequestIsReceivedUsers", undefined);
      formikGlobalRef.setFieldValue("settings.tenantRequests.assignUsersWhenRequestIsReceived", false);
    }
    setShowAssignUsersWhenRequestIsReceived(nextSwitchState);
  };

  const renderForm = () => {
    const formInitialValues = initFormvalues();
    return (
      <Formik initialValues={formInitialValues} onSubmit={(values, { setSubmitting }) => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;

          return (
            <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding}>
              <View alignItems={"flex-start"}>
                <Text
                  value={AppStrings.Common.TenantPortalNotificationsDialogInstructions}
                  fontSize={16}
                  color={"black"}
                  maxWidth={550}
                  lineHeight={"26px"}
                  marginTop={20}
                />
                <FastField
                  uniqueKey={"LF"}
                  component={RadioGroup}
                  name={""}
                  defaultValue={tenantRequestsNotificationsSelectionType === "companyDefault" ? "false" : "true"}
                  didChangeSelection={didChangeTenantRequestNotificationsSelection}
                  radioGroupItems={[
                    {
                      label: AppStrings.Common.UseCompanyDefaultTenantPortalNotifications,
                      value: "false"
                    },
                    {
                      label: AppStrings.Common.SpecifyTenantPortalNotificationsForProperty,
                      value: "true"
                    }
                  ]}
                  marginTop={10}
                />
                <View
                  shouldShow={tenantRequestsNotificationsSelectionType === "specifyTenantRequestsNotifications"}
                  showAnimation={"fade-in"}
                  hideAnimation={"fade-out"}
                >
                  <View>
                    <SeparationLine marginTop={20} width={"100%"} height={1} />
                    <View marginTop={20} flexDirection={"row"}>
                      <Grid item container justify={"center"} alignItems={"center"} xs={12} md={2} lg={2}>
                        {isMobile ? null : <Icon Source={RequestMaintenanceIcon} marginRight={20} />}
                      </Grid>

                      <Grid item container alignItems={"center"} xs={12} md={10} lg={10}>
                        <Text fontSize={20} fontWeight={700} value={AppStrings.Common.TenantRequestsSettings}></Text>

                        <FastField
                          component={FormikSwitchButton}
                          name={"settings.tenantRequests.sendEmailToTenantWhenRequestIsReceived"}
                          label={AppStrings.Common.NotifyTenantOnReceived}
                          marginTop={16}
                        />
                        <FastField
                          component={FormikSwitchButton}
                          name={"settings.tenantRequests.sendEmailToUsersWhenRequestIsReceived"}
                          label={AppStrings.Common.NotifyAssignedOnReceived}
                          marginTop={16}
                        />
                        <FastField
                          component={FormikSwitchButton}
                          name={"settings.tenantRequests.assignUsersWhenRequestIsReceived"}
                          label={AppStrings.Common.AutomaticallyAssignUsers}
                          marginTop={16}
                          onChange={didChangeAutomaticallyAssignUsers}
                        />
                        <View
                          showAnimation={"fade-in"}
                          hideAnimation={"fade-out"}
                          shouldShow={showAssignUsersWhenRequestIsReceived}
                        >
                          <Grid item xs={12} md={12} lg={12}>
                            <FormikCachedMultiSelectAutoComplete
                              uniqueIndex={"assignUsersWhenRequestIsReceivedUsers"}
                              apiHandler={usersApi}
                              displayNameKey={"name"}
                              filterFieldName={"filter_text"}
                              filterFieldValue={"name"}
                              selectionFields={["id", "class"]}
                              name={"settings.tenantRequests.assignUsersWhenRequestIsReceivedUsers"}
                              label={t(AppStrings.Common.SelectUsers)}
                              marginTop={16}
                              errorLabelPaddingLeft={15}
                            />
                          </Grid>
                        </View>
                      </Grid>
                    </View>
                    {isMobile ? <View height={20} /> : null}
                  </View>
                </View>
              </View>
            </View>
          );
        }}
      </Formik>
    );
  };

  const renderView = ({ index }: any) => {
    if (index === 0 && propertyData) {
      return renderForm();
    }
    if (index === 1 || !propertyData) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={t(AppStrings.Common.Loading)}
            errorText={loadingDialogErrorText}
            successText={t(AppStrings.Common.OperationCompleted)}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
            hideButtons={hideLoadingDialogActionButtons}
          />
        </View>
      );
    }
    return <div />;
  };

  const _onBackdropClick = () => {
    if (viewIndex === 1) {
      setViewIndex(0);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const frameType = useMemo(() => {
    if (propertyData === undefined) {
      return "contentOnly";
    }

    if (viewIndex === 0) {
      return "sectionTitleFrame";
    }
    if (viewIndex === 1) {
      return "contentOnly";
    }

    return "topPanel";
  }, [viewIndex, propertyData]);

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={dialogTitle}
      width={750}
      height={DialogFrameMinHeight}
      renderView={renderView}
      numViews={2}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={frameType}
    />
  );
};

export default EditTenantRequestsNotifications;
