import type { FC } from "react";
import React, { useMemo } from "react";
import { View } from "DLUI/view";
import type { OutgoingPaymentsReportItemDto } from "@doorloop/dto";
import { CheckbookBankStatusEnum, CheckbookPaymentStatusEnum } from "@doorloop/dto";
import type { ActionComponentProps } from "screens/outgoingPayments/actions/actionComponents";
import { outgoingPaymentActionByStatus } from "screens/outgoingPayments/actions/actionComponents";
import type { ShowConfirmationHandler } from "@/hooks/useConfirmationDialog";

interface Props {
  paymentDto?: OutgoingPaymentsReportItemDto;
  clickOverride?: () => void;
  showConfirmationDialog?: ShowConfirmationHandler;
}

const noop = () => null;

const settingsMap: Record<CheckbookPaymentStatusEnum, FC<ActionComponentProps>> = {
  [CheckbookPaymentStatusEnum.PAID]: outgoingPaymentActionByStatus.void,
  [CheckbookPaymentStatusEnum.IN_PROCESS]: outgoingPaymentActionByStatus.void,
  [CheckbookPaymentStatusEnum.UNPAID]: outgoingPaymentActionByStatus.void,
  [CheckbookPaymentStatusEnum.VOID]: noop,
  [CheckbookPaymentStatusEnum.EXPIRED]: outgoingPaymentActionByStatus.resend,
  [CheckbookPaymentStatusEnum.PRINTED]: noop,
  [CheckbookPaymentStatusEnum.MAILED]: noop,
  [CheckbookPaymentStatusEnum.FAILED]: noop,
  [CheckbookPaymentStatusEnum.REFUNDED]: noop
};
const defaultAction = outgoingPaymentActionByStatus.send;

export const OutgoingPaymentAction: FC<Props> = ({ paymentDto, clickOverride, showConfirmationDialog }: Props) => {
  const missingInfo = !paymentDto?.personOutgoingEPayInfoMethod;
  const isNotVerified = paymentDto?.cbStatus === CheckbookBankStatusEnum.PENDING;
  const status = paymentDto?.ePayInformation?.status;
  const ActionComponent = useMemo(() => {
    if (isNotVerified) {
      return outgoingPaymentActionByStatus.notVerifiedBank;
    }
    if (missingInfo) {
      return outgoingPaymentActionByStatus.addInfo;
    }
    return status ? settingsMap[status] : defaultAction;
  }, [status, missingInfo, isNotVerified]);

  return (
    <View>
      {paymentDto && ActionComponent && (
        <ActionComponent
          paymentDto={paymentDto}
          clickOverride={clickOverride}
          showConfirmationDialog={showConfirmationDialog}
        />
      )}
    </View>
  );
};
