import { IconButton } from "DLUI/form";
import type { SVGIconComponent } from "../../../../assets";
import { MobileMenuIcon, SearchIcon, WhiteArrowLeft } from "../../../../assets";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import React, { useMemo } from "react";
import _ from "lodash";
import type { BreadCrumbsText } from "DLUI/screenTitle";
import { Icon } from "DLUI/icon";
import { notificationApi } from "api/notificationApi";
import NotificationCenter from "DLUI/notificationCenter/notificationCenter";
import PrintHiddenContainer from "../../../layouts/print/printHiddenContainer";
import type { ViewComponentProps } from "DLUI/view/view";

export const MOBILE_TOP_NAVIGATION_BAR_HEIGHT = 60;

interface ComponentProps {
  didPressToggleMenu?: () => void;
  title?: BreadCrumbsText | BreadCrumbsText[] | string;
  didPressSearchMenu?: () => void;
  hasNotificationCenter?: boolean;
  headerIcon?: SVGIconComponent | undefined;
  viewProps?: ViewComponentProps;
  onCloseButtonClick?: VoidFunction;
}

const MobileTopNavigationBar: React.FC<ComponentProps> = ({
  title,
  didPressToggleMenu,
  didPressSearchMenu,
  hasNotificationCenter,
  headerIcon,
  viewProps,
  onCloseButtonClick
}: ComponentProps) => {
  const screenTitle = useMemo(() => {
    if (typeof title === "string") {
      return title;
    }
    if (_.isArray(title)) {
      return title[0].text;
    }
    if (title) {
      return title.text;
    }
  }, []);

  return (
    <PrintHiddenContainer
      alignItems={"center"}
      backgroundColor={"blue"}
      height={MOBILE_TOP_NAVIGATION_BAR_HEIGHT}
      flexDirection={"row"}
      className={"top-bar-navigation"}
      {...viewProps}
    >
      <View justifyContent={"flex-start"} alignItems={"center"} height={"100%"} width={60} flexDirection={"row"}>
        {didPressToggleMenu && <IconButton onClick={didPressToggleMenu} width={35} height={35} Icon={MobileMenuIcon} />}
        {onCloseButtonClick && <IconButton onClick={onCloseButtonClick} Icon={WhiteArrowLeft} />}
      </View>

      <View flex={1} justifyContent={"center"} alignItems={"center"} height={"100%"} flexDirection={"row"}>
        {headerIcon && <Icon Source={headerIcon} width={20} height={20} marginRight={10} />}
        <Text value={screenTitle} fontSize={16} color={"white"} />
      </View>
      <View alignItems={"flex-end"} justifyContent={"center"} height={"100%"} width={100}>
        {didPressSearchMenu && (
          <View width={30} height={30} alignItems={"center"} justifyContent={"center"} flexDirection={"row"}>
            <IconButton pathColor={"white"} size={30} Icon={SearchIcon} onClick={didPressSearchMenu} removePadding />
          </View>
        )}
        {hasNotificationCenter && (
          <NotificationCenter top={0} apiMethod={notificationApi} pathColor={"white"} marginRight={15} />
        )}
      </View>
    </PrintHiddenContainer>
  );
};

export default MobileTopNavigationBar;
