"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalApplicationEmploymentAndIncomeInfoDto = void 0;
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const rentalApplicationAdditionalIncome_dto_1 = require("./rentalApplicationAdditionalIncome.dto");
const rentalApplicationEmploymentHistory_dto_1 = require("./rentalApplicationEmploymentHistory.dto");
class RentalApplicationEmploymentAndIncomeInfoDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.RentalApplicationEmploymentAndIncomeInfoDto = RentalApplicationEmploymentAndIncomeInfoDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_validator_1.IsArray)(),
    (0, class_transformer_1.Type)(() => rentalApplicationEmploymentHistory_dto_1.RentalApplicationEmploymentHistoryDto),
    __metadata("design:type", Array)
], RentalApplicationEmploymentAndIncomeInfoDto.prototype, "employmentHistory", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => rentalApplicationAdditionalIncome_dto_1.RentalApplicationAdditionalIncomeDto),
    __metadata("design:type", Array)
], RentalApplicationEmploymentAndIncomeInfoDto.prototype, "additionalIncome", void 0);
