import type { RecurringTransactionDto, TaskDto } from "@doorloop/dto";
import TaskListItem from "screens/tasks/tasksList/taskListItem";
import { useMemo } from "react";

export interface TasksListItemWrapperProps {
  item: RecurringTransactionDto | TaskDto;
  stickyHeaderId?: string;
  recurring?: boolean;
  hideRelatedToColumn?: boolean;
  refreshScreen?: () => void;
  variant?: string;
}

const WRAPPER_STYLE = { width: "100%" };

export const TasksListItemWrapper = ({
  item,
  stickyHeaderId,
  recurring,
  hideRelatedToColumn,
  refreshScreen,
  variant
}: TasksListItemWrapperProps) => {
  const taskDto = useMemo(
    () => (recurring ? (item as RecurringTransactionDto).taskInfo : item) as TaskDto,
    [item, recurring]
  );

  if (!recurring && !item.type) {
    return null;
  }

  return (
    <div style={WRAPPER_STYLE}>
      <TaskListItem
        hideRelatedToColumn={hideRelatedToColumn}
        taskDto={taskDto}
        stickyHeaderId={stickyHeaderId}
        refreshScreen={refreshScreen}
        recurring={recurring ? (item as RecurringTransactionDto) : undefined}
        variant={variant}
      />
    </div>
  );
};
