import { apiHelper } from "api/apiHelper";
import { ApiResult } from "api/apiResult";
import { RestApiBaseWithDictionary } from "api/restApiBaseWithDictionary";
import type {
  BalancesSummaryDto,
  DepositsOverviewDto,
  GeneralInfoDto,
  GeneralInfoItemDto,
  GetAllJournalEntriesQuery,
  GetAllMerchantAccountDisbursementQuery,
  MerchantAccountDto,
  TransactionsOverviewDto
} from "@doorloop/dto";
import {
  GetAllBaseQueryResponse,
  GetAllMerchantAccountsQuery,
  MerchantAccountStatus,
  ServerRoutes
} from "@doorloop/dto";
import { accountsApi } from "api/accounts";
import { tenantsApi } from "api/tenantsApi";
import { RestApiBase } from "api/restApiBase";

export class MerchantAccountOverviewApi extends RestApiBaseWithDictionary<
  MerchantAccountDto,
  GetAllMerchantAccountsQuery
> {
  constructor() {
    super(ServerRoutes.MERCHANT_ACCOUNTS);
    this.restRoute = ServerRoutes.MERCHANT_ACCOUNTS;
  }

  getAll = async () => {
    const merchantAccountsResponse = await super.getAll(
      new GetAllMerchantAccountsQuery({ filter_status: MerchantAccountStatus.ACTIVE })
    );
    const merchantAccountsData =
      merchantAccountsResponse.data?.data.map(({ account, ...rest }) => {
        return {
          ...rest,
          account,
          name: accountsApi.getItemFromDictionary(account || "").name
        };
      }) || [];
    if (merchantAccountsResponse?.data) {
      merchantAccountsResponse.data.data = merchantAccountsData;
    }
    return merchantAccountsResponse;
  };

  getMerchantAccountByProperty = async (propertyId: string) =>
    await super.getAll(
      new GetAllMerchantAccountsQuery({ filter_status: MerchantAccountStatus.ACTIVE, filter_property: propertyId })
    );
}

export const merchantAccountOverviewApi = new MerchantAccountOverviewApi();

class GetStripeMerchantAccountGeneralInfo {
  getAll: () => Promise<ApiResult<GetAllBaseQueryResponse<GeneralInfoItemDto>>> = async () => {
    const result = await apiHelper.axiosGet<GeneralInfoDto>(
      ServerRoutes.MERCHANT_ACCOUNTS_GET_STRIPE_GENERAL_INFO_OVERVIEW
    );
    return new ApiResult(new GetAllBaseQueryResponse(result.data?.dtos || [], result.data?.dtos?.length || 0));
  };

  getMonthlyData: () => Promise<ApiResult<GeneralInfoDto>> = async () =>
    await apiHelper.axiosGet(ServerRoutes.MERCHANT_ACCOUNTS_GET_STRIPE_GENERAL_INFO_OVERVIEW);
}

export const getStripeMerchantAccountGeneralInfo: GetStripeMerchantAccountGeneralInfo =
  new GetStripeMerchantAccountGeneralInfo();

class GetStripeMerchantAccountTransactions extends RestApiBaseWithDictionary<
  TransactionsOverviewDto,
  GetAllJournalEntriesQuery
> {
  constructor() {
    super(ServerRoutes.MERCHANT_ACCOUNTS_GET_STRIPE_TRANSACTIONS_OVERVIEW);
    this.restRoute = ServerRoutes.MERCHANT_ACCOUNTS_GET_STRIPE_TRANSACTIONS_OVERVIEW;
  }

  getAll: (query: GetAllJournalEntriesQuery) => Promise<ApiResult<any>> = async (query) =>
    await apiHelper.axiosGet(this.restRoute, query);
}

export const getStripeMerchantAccountTransactions: GetStripeMerchantAccountTransactions =
  new GetStripeMerchantAccountTransactions();

class GetStripeMerchantAccountDeposits extends RestApiBaseWithDictionary<
  GetAllBaseQueryResponse<TransactionsOverviewDto>,
  GetAllMerchantAccountDisbursementQuery
> {
  constructor() {
    super(ServerRoutes.MERCHANT_ACCOUNTS);
    this.restRoute = ServerRoutes.MERCHANT_ACCOUNTS;
  }

  getAll: (query: GetAllMerchantAccountDisbursementQuery) => Promise<ApiResult<any>> = async (query) =>
    await apiHelper.axiosGet<GetAllBaseQueryResponse<DepositsOverviewDto>>(
      ServerRoutes.MERCHANT_ACCOUNTS_GET_STRIPE_DEPOSITS_OVERVIEW,
      query
    );
}

export const getStripeMerchantAccountDeposits: GetStripeMerchantAccountDeposits =
  new GetStripeMerchantAccountDeposits();

export class GetStripeMerchantAccountBalanceSummary extends RestApiBase<
  BalancesSummaryDto,
  GetAllMerchantAccountsQuery
> {
  constructor() {
    super(ServerRoutes.MERCHANT_ACCOUNTS_GET_STRIPE_BALANCE_SUMMARY_OVERVIEW, [tenantsApi, accountsApi]);
    this.restRoute = ServerRoutes.MERCHANT_ACCOUNTS_GET_STRIPE_BALANCE_SUMMARY_OVERVIEW;
  }

  getDetails = async (filter = {}) => await this.getAll(filter);
}

export const getStripeMerchantAccountBalanceSummary: GetStripeMerchantAccountBalanceSummary =
  new GetStripeMerchantAccountBalanceSummary();
