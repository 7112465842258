import React from "react";
import { AgendaIcon, BadgeIcon, BankIcon, HomeIcon, LocationIcon, UsersIcon } from "assets/icons";
import type { MenuItem } from "DLUI/dialogs";
import { ContactInfo, PersonAddress } from "DLUI/person";
import AppStrings from "locale/keys";
import { PropertiesAccess, UserPersonalInfo } from "./components";
import { BankAccountsAccess } from "screens/users/newUser/components/bankAccountsAccess";
import TimeZone from "DLUI/person/timeZone";

export const getUserFormMenuItems = (isEditingCurrentUser: boolean): MenuItem[] => [
  {
    title: AppStrings.Tenants.NewTenant.PersonalInfo,
    icon: BadgeIcon,
    contentComponent: (formikRef, onFileReceive, onFileDelete) => (
      <UserPersonalInfo formikRef={formikRef} onFileReceive={onFileReceive} onFileDelete={onFileDelete} />
    ),
    uploadFile: true
  },
  {
    title: AppStrings.Tenants.NewTenant.ContactInfo,
    icon: AgendaIcon,
    contentComponent: (formikRef) => <ContactInfo formikRef={formikRef} />
  },
  {
    title: AppStrings.Tenants.NewTenant.Address,
    icon: LocationIcon,
    contentComponent: (formikRef) => (
      <PersonAddress
        formikName={"primaryAddress"}
        title={AppStrings.Tenants.NewTenant.PrimaryAddress}
        formikRef={formikRef}
      />
    )
  },
  ...(!isEditingCurrentUser
    ? [
        {
          title: AppStrings.DrawerItems.Properties,
          icon: HomeIcon,
          contentComponent: (formikRef) => <PropertiesAccess formikRef={formikRef} />
        },
        {
          title: AppStrings.Properties.Settings.BankAccountsTitle,
          icon: BankIcon,
          contentComponent: (formikRef) => <BankAccountsAccess formikRef={formikRef} />
        }
      ]
    : []),
  {
    title: AppStrings.Common.TimeZone,
    icon: UsersIcon,
    contentComponent: (formikRef) => <TimeZone formikRef={formikRef} />
  }
];
