import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import type { ToggleInputSize } from "DLUI/form/types/toggleInputBaseTypes";
import type { LabelPlacementPosition } from "DLUI/form/label/inputLabel";

type ContainerClassKey = "root" | LabelPlacementPosition;

export const useStylesContainer = makeStyles<Theme, NonNullable<unknown>, ContainerClassKey>((theme) => {
  return {
    root: {
      alignItems: "center !important" as "center",
      left: "-10px !important"
    },
    start: {
      flexDirection: "row-reverse !important" as "row-reverse"
    },
    end: {
      flexDirection: "row !important" as "row"
    }
  };
});

type InputClassKey = "root" | ToggleInputSize | "colorPrimary" | "colorSecondary" | "checked" | "disabled";

export const useStylesInput = makeStyles<Theme, NonNullable<unknown>, InputClassKey>((theme) => {
  return {
    root: {
      padding: 0,
      color: theme.newPalette.secondary.main,

      "& .MuiSvgIcon-root, &$checked .MuiSvgIcon-root:nth-child(1)": {
        color: theme.newPalette.secondary.main
      },
      "& .MuiSvgIcon-root:nth-child(1)": {
        color: theme.newPalette.text.primary
      },
      "&:hover": {
        backgroundColor: `${theme.newPalette.secondary.states.hover} !important`
      },
      "&:active .MuiTouchRipple-child, & .MuiTouchRipple-childLeaving": {
        color: `${theme.newPalette.secondary.states.focusVisible} !important`
      }
    },
    small: {
      padding: 4,
      "& .MuiSvgIcon-root": {
        height: 16,
        width: 16
      }
    },
    medium: {
      padding: 10,
      "& .MuiSvgIcon-root": {
        height: 20,
        width: 20
      }
    },
    large: {
      padding: 12,
      "& .MuiSvgIcon-root": {
        height: 24,
        width: 24
      }
    },
    colorPrimary: {
      color: theme.newPalette.primary.main
    },
    colorSecondary: {},
    checked: {
      color: `${theme.newPalette.secondary.main} !important`
    },
    disabled: {
      "& .MuiSvgIcon-root": {
        color: `${theme.newPalette.text.disabled} !important`
      }
    }
  };
});
