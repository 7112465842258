import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles(() =>
  createStyles({
    notCurrentLoginText: {
      border: "1px solid #182C4C1A",
      paddingBottom: 12,
      minHeight: 60,
      width: "98%",
      borderRadius: 10
    }
  })
);
