import { lazy } from "react";
import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";

const ChartOfAccounts = lazy(() => import("screens/accounts/chartOfAccounts/chartOfAccounts"));

const AccountsRouter = () => (
  <PageRouter>
    <LazyRoute exact path={Routes.EDIT_CHART_OF_ACCOUNTS} render={ChartOfAccounts} />
    <LazyRoute exact path={Routes.ACCOUNTS_EDIT_BANK_ACCOUNT} render={ChartOfAccounts} />
    <LazyRoute path={Routes.CHART_OF_ACCOUNTS} render={ChartOfAccounts} />
  </PageRouter>
);

export default AccountsRouter;
