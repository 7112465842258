import AppStrings from "locale/keys";
import { FileErrorTypes, FileFilterTypes } from "@doorloop/dto";

export enum SupportedFileTypes {
  JPG = "jpg",
  PNG = "png",
  MP4 = "mp4",
  DOC = "doc",
  DOCX = "docx",
  PDF = "pdf",
  XLSX = "xlsx",
  XLS = "xls"
}

export enum SupportedFileTypesForSignatureTemplates {
  DOCX = "docx",
  PDF = "pdf"
}

export const DEFAULT_MAX_FILE_UPLOAD_SIZE_ALLOWED_IN_MB = 50;

export const getSupportedFileTypesAsString = (pathname?: string): string => {
  if (pathname?.includes("signature-templates")) {
    return Object.values(SupportedFileTypesForSignatureTemplates).join(", ") + ",";
  }

  return Object.values(SupportedFileTypes).join(", ") + ",";
};

export const generateUploadError = (erroredFile?: FileErrorTypes) => {
  let error;

  if (erroredFile === FileErrorTypes.MULTIPLE_NOT_ALLOWED_WARNING) {
    error = AppStrings.Common.FileAlreadyExistError;
  } else if (erroredFile === FileErrorTypes.MULTIPLE) {
    error = AppStrings.Common.MultipleNotSupportedFileError;
  } else if (erroredFile === FileErrorTypes.MULTIPLE_TYPE_ERROR) {
    error = AppStrings.Common.MultipleNotSupportedTypesError;
  } else {
    error = AppStrings.Common.NotSupportedFileError;
  }

  return error;
};

export const fileExtensionsMap: Record<FileFilterTypes, string> = {
  [FileFilterTypes.GENERAL]:
    ".mp4,.webm,.avi,.wmv,.mpeg,.m4v,.mov,.txt,.xls,.xlsx,.doc,.docx,.pdf,.jpeg,.jpg,.png,.gif",
  [FileFilterTypes.PICTURE]: ".jpeg,.jpg,.png,.gif",
  [FileFilterTypes.DOCUMENT]: ".txt,.xls,.xlsx,.doc,.docx,.pdf",
  [FileFilterTypes.VIDEOS]: ".mp4,.webm,.avi,.wmv,.mpeg,.m4v,.mov"
};
