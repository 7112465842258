import { UnitAmenity } from "@doorloop/dto";

interface AmenitiesInterface {
  categoryLabel: string;
  amenities: UnitAmenity[];
}

export const unitAmenities: AmenitiesInterface[] = [
  {
    categoryLabel: "",
    amenities: [
      UnitAmenity.AdditionalStorage,
      UnitAmenity.AirConditioner,
      UnitAmenity.Alarm,
      UnitAmenity.Balcony,
      UnitAmenity.Cable,
      UnitAmenity.Carpet,
      UnitAmenity.Carport,
      UnitAmenity.CeilingFan,
      UnitAmenity.ControlledAccess,
      UnitAmenity.Courtyard,
      UnitAmenity.Dishwasher,
      UnitAmenity.Disposal,
      UnitAmenity.DoubleSinkVanity,
      UnitAmenity.Dryer,
      UnitAmenity.Fireplace,
      UnitAmenity.FramedMirrors,
      UnitAmenity.Furnished,
      UnitAmenity.Garage,
      UnitAmenity.Handrails,
      UnitAmenity.HardwoodFlooring,
      UnitAmenity.HardSurfaceCounterTops,
      UnitAmenity.Heat,
      UnitAmenity.IndividualClimateControl,
      UnitAmenity.IslandKitchen,
      UnitAmenity.LaminateCounterTops,
      UnitAmenity.VinylFlooring,
      UnitAmenity.LargeClosets,
      UnitAmenity.LinenCloset,
      UnitAmenity.Microwave,
      UnitAmenity.Pantry,
      UnitAmenity.Patio,
      UnitAmenity.PrivateBalcony,
      UnitAmenity.PrivatePatio,
      UnitAmenity.Range,
      UnitAmenity.Refrigerator,
      UnitAmenity.Satellite,
      UnitAmenity.Skylight,
      UnitAmenity.TileFlooring,
      UnitAmenity.VaultedCeiling,
      UnitAmenity.View,
      UnitAmenity.Washer,
      UnitAmenity.WheelChair,
      UnitAmenity.WD_Hookup,
      UnitAmenity.WindowCoverings,
      UnitAmenity.Other
    ]
  }
];
