import type { GetAllPropertiesQuery, PropertyDto } from "@doorloop/dto";
import { TenantPortalServerRoutes } from "@doorloop/dto";

import { RestApiBaseWithDictionary } from "api/restApiBaseWithDictionary";

export class TenantPortalPropertiesApi extends RestApiBaseWithDictionary<PropertyDto, GetAllPropertiesQuery> {
  constructor() {
    super(TenantPortalServerRoutes.PROPERTIES);
    this.restRoute = TenantPortalServerRoutes.PROPERTIES;
  }
}

export const tenantPortalPropertiesApi = new TenantPortalPropertiesApi();
