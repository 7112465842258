import type { AccountIdentityType, OwnerDto } from "@doorloop/dto";
import { DuplicateDialogButtonLabelValues } from "@doorloop/dto";
import {
  createValidator,
  LinkedResourceDto,
  LinkedResourceType,
  ObjectPermission,
  TaskDateType,
  TaskDto,
  TaskStatus,
  TaskType
} from "@doorloop/dto";
import type { ApiResult } from "api/apiResult";
import { filesApi } from "api/filesApi";
import { ownersApi } from "api/ownersApi";
import { tasksApi } from "api/tasksApi";
import { Routes } from "components/appRouter";
import DLButton, { DLButtonSizesEnum } from "DLUI/button/dlButton";
import { DialogFrame, DialogSearchPanel, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import { renderReferenceString } from "../../../../../utils/strings";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { Formik, getIn } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { OwnersList, SelectableOwnersListItem } from "screens/owners";
import {
  initFormValues as ownerInitFormValues,
  validateForm as validateNewOwnerForm
} from "screens/owners/newOwner/formikHelper";
import { getOwnerFormMenuItems } from "screens/owners/newOwner/menuItems";
import type { IRootState } from "store";
import { NavigationManager } from "utils/navigation";
import AnimatedContent from "../../components/animatedContent";
import OwnerSelection from "../../ownerContribution/ownerSelection";
import RelatedToSection from "../relatedToSection";
import TaskInfo from "../taskInfo";
import { Grid } from "@material-ui/core";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { getDialogFrameDimension, getDialogSelectionHeight } from "../../components/dialogFrame";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";
import { QueryParams } from "utils/queryParams";
import { cleanTaskDataForDuplicateMode, DUPLICATE_MODE_QUERY_PARAMS } from "DLUI/dialogs/duplicateModeHelper";
import { useAnalyticsService } from "hooks/useAnalyticsService";
import { DialogHeaderActionButtons } from "DLUI/actionButtons/dialogHeaderActionButtons";

interface ComponentProps {
  onClose: (task?: TaskDto) => void;
  onBackdropClick: () => void;
  dialogTitle: string;
  apiObjectId?: string;
  initialTaskData?: TaskDto;
}

let formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

const validateForm = createValidator(TaskDto);

const dialogWidth = Math.min(window.innerWidth, 880);

const OwnerRequestDialog: React.FC<ComponentProps> = ({
  onClose,
  onBackdropClick,
  dialogTitle,
  apiObjectId,
  initialTaskData
}: ComponentProps) => {
  const queryParams = new QueryParams();
  const { t } = useTranslation();
  const queryTaskId = queryParams.get(DUPLICATE_MODE_QUERY_PARAMS.taskId) as string | undefined;
  const { propertyId, taskId: taskIdParam, unitId, ownerId, leaseId } = useParams<any>();
  const location = useLocation<any>();
  const { screenContainerPadding, isMobile } = useResponsiveHelper();
  const taskId = apiObjectId || taskIdParam || queryTaskId;
  const editMode = Boolean(taskId);
  const duplicateMode = Boolean(queryTaskId);
  const [viewIndex, setViewIndex] = useState(0);
  const userData = useSelector((state: IRootState) => state.auth.currentLoginResponse);
  const [taskData, setTaskData] = useState<TaskDto | undefined>(initialTaskData);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    editMode ? DialogState.Show : DialogState.Hidden
  );
  const [renderSelectionList, setRenderSelectionList] = useState<boolean>(false);
  const [selectLeaseFilterObj, setSelectLeaseFilterObj] = useState({
    filter_text: ""
  });
  const { dispatchAnalytics } = useAnalyticsService();
  const [currentOwnerId, setCurrentOwnerId] = useState<string | undefined>(
    initialTaskData?.requestedByOwner || ownerId
  );
  const [pendingFile, setPendingFile] = useState<File | undefined>();
  const [currentMenuItemTitle, setCurrentMenuItemTitle] = useState<string>("");

  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);

  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState<string>("");

  const [loadingDialogSuccessText, setLoadingDialogSuccessText] = useState<string>("");

  const [showOwnerSelectionError, setShowOwnerSelectionError] = useState<boolean>(false);
  const [ownerSelectionErrorText, setOwnerSelectionErrorText] = useState<string>("");

  const [showRelatedToSection, setShowRelatedToSection] = useState<boolean>(true);

  const isTaskTypeOwnerRequest = formikGlobalRef?.values?.type === TaskType.OWNER_REQUEST;
  const isTaskCreatedByOwner = (formikGlobalRef?.values?.createdByType as AccountIdentityType) === "OWNER";

  const displayOwnerRequestViewOnlyDetails = isTaskCreatedByOwner && isTaskTypeOwnerRequest;

  useEffect(() => {
    if (editMode) {
      loadTaskData();
    }
  }, [duplicateMode]);

  useEffect(() => {
    if (loadingDialogState === DialogState.Hidden) {
      autoSelectRelatedToField();
    }
  }, [loadingDialogState]);

  const dialogHeight = useMemo(() => {
    let dialogHeight = 840;
    if (showRelatedToSection) {
      dialogHeight = 940;
    }
    return dialogHeight;
  }, [showRelatedToSection]);

  const autoSelectRelatedToField = () => {
    if (formikGlobalRef !== null) {
      if (propertyId) {
        formikGlobalRef.setFieldValue("linkedResource.resourceId", propertyId);
        formikGlobalRef.setFieldValue("linkedResource.resourceType", LinkedResourceType.Property);
        setShowRelatedToSection(false);
        return;
      }
      if (unitId) {
        formikGlobalRef.setFieldValue("linkedResource.resourceId", unitId);
        formikGlobalRef.setFieldValue("linkedResource.resourceType", LinkedResourceType.Unit);
        setShowRelatedToSection(false);
        return;
      }
      if (ownerId) {
        formikGlobalRef.setFieldValue("linkedResource.resourceId", ownerId);
        formikGlobalRef.setFieldValue("linkedResource.resourceType", LinkedResourceType.Owner);
        setShowRelatedToSection(false);
        return;
      }
      if (leaseId) {
        const linkedResourceType =
          location.pathname.indexOf(Routes.DRAFT_LEASES) > -1
            ? LinkedResourceType.LeaseDraft
            : LinkedResourceType.Lease;
        formikGlobalRef.setFieldValue("linkedResource.resourceId", leaseId);
        formikGlobalRef.setFieldValue("linkedResource.resourceType", linkedResourceType);
        setShowRelatedToSection(false);
        return;
      }
    }
    setShowRelatedToSection(true);
  };

  const showErrorMessage = (message: string) => {
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(message);
  };

  const loadTaskData = async () => {
    if (taskId) {
      setLoadingDialogState(DialogState.Show);
      const response = await tasksApi.get(taskId).catch((e) => {
        showErrorMessage(e);
      });
      if (response && response.data) {
        const data = duplicateMode ? cleanTaskDataForDuplicateMode(response.data) : response.data;
        setCurrentOwnerId(data.requestedByOwner);
        setLoadingDialogState(DialogState.Success);
        setTaskData(data);
        setLoadingDialogState(DialogState.Hidden);
        return;
      }
      if (response) {
        showErrorMessage(response.message);
      }

      showErrorMessage(t(AppStrings.Common.NetworkErrorSubTitle));
    }
  };

  const didPressDismissButton = () => {
    onBackdropClick();
  };

  const renderOwnerSelectionList = () => {
    const didChangeSearchQuery = (value: string) => {
      setSelectLeaseFilterObj({
        filter_text: value
      });
    };

    const onListItemPress = (selectedItem: OwnerDto) => {
      if (selectedItem.id) {
        formikGlobalRef?.setFieldValue("linkedResource", undefined);
        formikGlobalRef?.setFieldValue("property", undefined);
        formikGlobalRef?.setFieldValue("unit", undefined);

        setViewIndex(0);
        setCurrentOwnerId(selectedItem.id);
        setShowRelatedToSection(false);

        setTimeout(() => {
          setShowRelatedToSection(true);

          if (formikGlobalRef) {
            formikGlobalRef.setFieldValue("requestedByOwner", selectedItem.id);
            isValidForm(formikGlobalRef);
          }
        }, 0);
      }
    };

    const didPressAddNewOwner = () => {
      setViewIndex(2);
    };

    return (
      <View>
        <View paddingLeft={screenContainerPadding} paddingRight={screenContainerPadding} flexDirection={"row"}>
          <Grid xs={12} sm={6} md={6} lg={6}>
            <DialogSearchPanel
              placeholder={AppStrings.Owners.Screen.SearchPlaceHolder}
              onChange={didChangeSearchQuery}
              borderRadius={30}
            />
          </Grid>
          <Grid xs={12} sm={6} md={6} lg={6}>
            <View
              paddingRight={20}
              flex={1}
              justifyContent={"center"}
              alignItems={isMobile ? "center" : "flex-end"}
              marginTop={20}
            >
              <DLButton
                clearance={{
                  permission: ObjectPermission.owners,
                  field: "create"
                }}
                actionText={AppStrings.Owners.Screen.AddNewOwner}
                onClick={didPressAddNewOwner}
                icons={{ start: { isHidden: false } }}
                size={DLButtonSizesEnum.MEDIUM}
              />
            </View>
          </Grid>
        </View>

        {renderSelectionList ? (
          <View
            paddingLeft={screenContainerPadding}
            paddingRight={screenContainerPadding}
            id={"selectableOwnerListContainer"}
            overflow={"scroll"}
            height={getDialogSelectionHeight(dialogHeight)}
          >
            <OwnersList
              filterObj={selectLeaseFilterObj}
              ListItem={SelectableOwnersListItem}
              fullWidth
              didSelectOwnerItem={onListItemPress}
              listDirection={"column"}
              selectableListItem
              stickyHeaderId={"selectableOwnerListContainer"}
              removeDefaultBottomPadding
              scrollableTarget={"selectableOwnerListContainer"}
              didPressAddNewButton={didPressAddNewOwner}
            />
          </View>
        ) : null}
      </View>
    );
  };

  const renderNewOwnerForm = () => {
    const onFileReceive = (receivedFile: File) => {
      setPendingFile(receivedFile);
    };

    const onFileDelete = () => {
      setPendingFile(undefined);
    };

    const onMenuItemSelection = (menuItemTitle: string) => {
      setCurrentMenuItemTitle(menuItemTitle);
    };

    const uploadFile = async (formikRef: FormikProps<any>, id: string, onClose: (values?: any) => void) => {
      setLoadingDialogState(DialogState.Success);
      const LinkedResource = new LinkedResourceDto(id, LinkedResourceType.Tenant);

      const response = (await ownersApi.uploadPicture(pendingFile!, LinkedResource, id).catch((error: string) => {
        formikRef!.setSubmitting(false);
        setLoadingDialogState(DialogState.Error);
      })) as ApiResult<any>;

      if (response && response.status) {
        setLoadingDialogState(DialogState.Success);
        NavigationManager.viewTaskDetails(response.data.id!);
      } else {
        formikRef!.setSubmitting(false);
        setLoadingDialogErrorText(response.message);
        setLoadingDialogState(DialogState.Error);
      }
    };

    const didCreateNewOwner = (values?: any) => {
      setViewIndex(0);
      if (values && values.id) {
        setCurrentOwnerId(values.id);
        if (formikGlobalRef && formikGlobalRef !== null) {
          formikGlobalRef.setFieldValue("requestedByOwner", values.id);
        }
      }
    };

    const createNewOwner = async (formikRef: FormikProps<any>) => {
      if (formikRef !== null) {
        const response = (await ownersApi.create(formikRef.values).catch((error: string) => {
          formikRef!.setSubmitting(false);
          setLoadingDialogErrorText(t(AppStrings.Owners.NewOwner.ErrorText));
          setLoadingDialogState(DialogState.Error);
        })) as ApiResult<any>;

        if (response && response.status) {
          if (pendingFile) {
            uploadFile(formikRef, response.data.id, didCreateNewOwner);
          } else {
            setLoadingDialogSuccessText(t(AppStrings.Owners.NewOwner.SuccessText));
            setLoadingDialogState(DialogState.Hidden);
            didCreateNewOwner(response.data);
          }
        } else {
          formikRef!.setSubmitting(false);
          setLoadingDialogErrorText(t(AppStrings.Owners.NewOwner.ErrorText));
          setLoadingDialogState(DialogState.Error);
        }
      }
    };

    const renderNewOwnerActionPanel = (formikRef: FormikProps<any>) => {
      const didPressCreateNewOwner = async () => {
        if (formikRef !== null) {
          const validationResult = await validateNewOwnerForm(formikRef.values);
          if (_.isEmpty(validationResult)) {
            formikRef.setSubmitting(true);
            setLoadingDialogLoadingText(t(AppStrings.Owners.NewOwner.LoadingText));
            setLoadingDialogState(DialogState.Show);
            setViewIndex(3);
            createNewOwner(formikRef);
          } else if (validationResult.errorStepIndex !== undefined) {
          }
        }
      };

      return (
        <FormActionButtons
          propsSubButton={{ onClick: _onBackdropClick }}
          propsMainButton={{ type: "cta", props: { onClick: didPressCreateNewOwner } }}
        />
      );
    };

    const sectionsItems = getOwnerFormMenuItems();

    return (
      <AnimatedContent
        formikInitialValues={ownerInitFormValues}
        sectionItems={sectionsItems}
        formikValidation={validateNewOwnerForm}
        renderActionPanelButtons={renderNewOwnerActionPanel}
        dialogHeight={dialogHeight}
        onFileDelete={onFileDelete}
        onFileReceive={onFileReceive}
        onMenuItemSelection={onMenuItemSelection}
      />
    );
  };

  const renderView = ({ index }: any) => {
    if (index === 0) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={t(AppStrings.Tasks.InternalTaskDialog.LoadingTaskDataSuccessText)}
            onRetryButtonPress={loadTaskData}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }
      return renderForm();
    }
    if (index === 1) {
      return renderOwnerSelectionList();
    }
    if (index === 2) {
      return renderNewOwnerForm();
    }

    if (index === 3) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          loadingText={loadingDialogLoadingText}
          errorText={loadingDialogErrorText}
          successText={loadingDialogSuccessText}
          onRetryButtonPress={didPressSaveButton}
          didPressDismissButton={didPressDismissButton}
        />
      );
    }

    return <div />;
  };

  const initFormvalues = (): TaskDto => {
    if (taskData) {
      return taskData;
    }
    const taskDto = new TaskDto();

    if (userData) {
      taskDto.assignedToUsers = [userData.id];
    }
    taskDto.type = TaskType.OWNER_REQUEST;
    taskDto.dateType = TaskDateType.DATE;
    taskDto.reference = renderReferenceString();
    taskDto.status = TaskStatus.NOT_STARTED;
    taskDto.notifyAssignees = true;
    taskDto.notifyOwner = true;

    if (ownerId) {
      taskDto.requestedByOwner = ownerId;
    }

    return taskDto;
  };

  const didPressSelectOwner = () => {
    setViewIndex(1);
    setTimeout(() => {
      setRenderSelectionList(true);
    }, 500);
  };

  const onDeleteItem = (itemIndex: number) => {
    const nextlistFiles = _.cloneDeep(attachments);
    nextlistFiles.splice(itemIndex, 1);
    setAttachments(nextlistFiles);
  };

  const onFileReceived = (files: FileListItemProps[]) => {
    setAttachments(files);
  };

  const renderForm = () => {
    const formInitialValues = initFormvalues();
    return (
      <Formik initialValues={formInitialValues} onSubmit={(values, { setSubmitting }) => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;
          return (
            <View paddingLeft={screenContainerPadding} paddingRight={screenContainerPadding} noWrap>
              {!displayOwnerRequestViewOnlyDetails && (
                <View marginTop={20}>
                  <OwnerSelection
                    didPressSelectOwner={didPressSelectOwner}
                    ownerId={currentOwnerId}
                    errorTex={showOwnerSelectionError ? ownerSelectionErrorText : undefined}
                  />
                </View>
              )}
              <View>
                <TaskInfo
                  formikRef={formik}
                  showNotifyOwner
                  disableRecurring
                  displayRequestViewOnlyDetails={displayOwnerRequestViewOnlyDetails}
                />
              </View>
              <View>
                <RelatedToSection shouldShow={showRelatedToSection} filterByOwner={currentOwnerId} formikRef={formik} />
                <FormAttachments
                  onFileReceived={onFileReceived}
                  files={attachments}
                  marginTop={20}
                  editMode={editMode}
                  resourceType={LinkedResourceType.Task}
                  resourceId={taskData ? taskData.id! : undefined}
                />
              </View>
            </View>
          );
        }}
      </Formik>
    );
  };

  const _onBackdropClick = () => {
    if (viewIndex > 0) {
      setViewIndex(viewIndex - 1);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const currentTitle = useMemo(() => {
    if (viewIndex === 0) {
      return dialogTitle;
    }
    if (viewIndex === 1) {
      return AppStrings.Owners.NewOwner.SelectOwner;
    }

    if (viewIndex === 2) {
      return AppStrings.Owners.Screen.AddNewOwner;
    }
    return "";
  }, [viewIndex]);

  const duplicateModeTitle = useMemo(
    () => (duplicateMode ? `${t(AppStrings.Tasks.Screen.NewOwnerRequest)} (${t(AppStrings.Common.Duplicated)})` : ""),
    [duplicateMode, t]
  );

  const isValidForm = async (formikRef: FormikProps<TaskDto>) => {
    formikRef.setFieldTouched("subject");
    formikRef.setFieldTouched("status");
    formikRef.setFieldTouched("requestedByOwner");
    formikRef.setFieldTouched("linkedResource.resourceId");

    const errors = (await formikRef.validateForm()) as any;
    const ownerId = getIn(formikRef.values, "requestedByOwner");
    if (ownerId === undefined) {
      setOwnerSelectionErrorText(t(AppStrings.Tasks.OwnerRequestDialog.SelectOwnerValidationText));
      setShowOwnerSelectionError(true);
      return false;
    }
    setShowOwnerSelectionError(false);

    return _.isEmpty(errors);
  };

  const didPressSaveButton = async () => {
    if (formikGlobalRef !== null) {
      const isValid = await isValidForm(formikGlobalRef);
      if (isValid) {
        if (editMode && !duplicateMode) {
          updateTask(formikGlobalRef.values);
        } else {
          createTask(formikGlobalRef.values);
        }
      }
    }
  };

  const createTask = async (values: TaskDto) => {
    setViewIndex(3);
    setLoadingDialogLoadingText(t(AppStrings.Tasks.InternalTaskDialog.CreatingTask));
    setLoadingDialogState(DialogState.Show);
    const response = await tasksApi
      .create(values, {
        translationKey: AppStrings.Toasts.custom.tasks?.[TaskType.OWNER_REQUEST]?.POST
      })
      .catch(() => {
        setLoadingDialogErrorText(AppStrings.Common.NetworkErrorTitle);
        setLoadingDialogState(DialogState.Error);
      });
    if (response && response.data) {
      await filesApi.uploadFiles(attachments, response.data.id!, LinkedResourceType.Task).catch((error: string) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });
      onClose(response.data);
    } else if (response && response.message) {
      setLoadingDialogErrorText(response.message);
      setLoadingDialogState(DialogState.Error);
    }
  };

  const updateTask = async (values: TaskDto) => {
    setViewIndex(3);
    setLoadingDialogLoadingText(t(AppStrings.Tasks.InternalTaskDialog.UpdatingTask));
    setLoadingDialogState(DialogState.Show);
    const response = await tasksApi.update(taskId!, values).catch((e) => {
      setLoadingDialogErrorText(e);
      setLoadingDialogState(DialogState.Error);
    });
    if (response && response.data) {
      await filesApi.uploadFiles(attachments, response.data.id!, LinkedResourceType.Task).catch((error: string) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });
      setLoadingDialogState(DialogState.Success);
      onClose(response.data);
    } else if (response) {
      setLoadingDialogErrorText(response.message);
      setLoadingDialogState(DialogState.Error);
    }
  };

  const handleDuplicateClick = () => {
    queryParams.set(DUPLICATE_MODE_QUERY_PARAMS.taskId, taskId);
    queryParams.historyPush();

    dispatchAnalytics("button_click", {
      label: DuplicateDialogButtonLabelValues.DUPLICATE_OWNER_REQUEST
    });
  };
  const renderHeaderActionButtons = () => (
    <DialogHeaderActionButtons
      onDuplicateClick={handleDuplicateClick}
      hideDeleteButton
      hideDuplicateButton={duplicateMode || !editMode}
    />
  );

  const renderActionPanelButtons = () => (
    <FormActionButtons
      propsSubButton={{ onClick: _onBackdropClick }}
      propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
    />
  );

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      if (loadingDialogState !== DialogState.Hidden) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }
    if (viewIndex === 1) {
      return "topPanel";
    }
    if (viewIndex === 2) {
      return "sideMenu";
    }

    if (viewIndex === 3) {
      return "contentOnly";
    }

    return "contentOnly";
  }, [viewIndex, loadingDialogState]);

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={duplicateModeTitle || currentTitle}
      width={getDialogFrameDimension("width", dialogWidth)}
      height={getDialogFrameDimension("height", dialogHeight)}
      renderView={renderView}
      numViews={4}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      RenderHeaderActionButtons={renderHeaderActionButtons}
      frameType={frameType}
      keepViewsMounted={false}
      sectionTitle={currentMenuItemTitle}
    />
  );
};

export default OwnerRequestDialog;
