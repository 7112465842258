import { useResponsiveHelper } from "contexts/responsiveContext";
import { useEffect, useState } from "react";
import type { ScreenSize } from "contexts/utils";

const fixedCollapseSizes: ScreenSize[] = ["xs", "sm", "md"];

export const useDrawerResponsiveDesign = () => {
  const { size, isScreenSizeIn } = useResponsiveHelper();
  const [isDrawerCollapseFixed, setIsDrawerCollapseFixed] = useState(false);

  useEffect(() => {
    setIsDrawerCollapseFixed(isScreenSizeIn(fixedCollapseSizes));
  }, [size]);

  return {
    isDrawerCollapseFixed
  };
};
