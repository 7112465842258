"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OwnerDistributionGetPropertiesAndMissingInfoDto = void 0;
class OwnerDistributionGetPropertiesAndMissingInfoDto {
    constructor(initValues) {
        this.allPropertyIds = [];
        this.missingInfoProperties = [];
        Object.assign(this, initValues);
    }
}
exports.OwnerDistributionGetPropertiesAndMissingInfoDto = OwnerDistributionGetPropertiesAndMissingInfoDto;
