"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionUtils = void 0;
class SubscriptionUtils {
    constructor(plans) {
        this.planIncreaseStep = 20;
        //pana la cat merge sliderul (numarul maxim de units)
        this.maxUnits = 5000;
        //dupa cate unitati apare enterprise
        this.enterpriseAfterUnits = 5000;
        //dupa cate units basic devine inactiv
        this.disableBasicAfter = 160;
        //dupa cate units pretul se calculeaza per unit
        this.pricePerUnitAfterLimit = 500;
        //textul care apare sub pret daca e monthly selectat
        this.monthlyText = "Billed monthly";
        //textul care apare sub pret daca e yearly selectat
        this.yearlyText = "Billed yearly";
        this.plans = plans;
    }
    calulatePrice(plan, numberOfUnits) {
        let priceYearly = 0;
        let priceMonthly = 0;
        let extraUnits = 0;
        if (numberOfUnits > this.pricePerUnitAfterLimit) {
            extraUnits = numberOfUnits - this.pricePerUnitAfterLimit;
            numberOfUnits = this.pricePerUnitAfterLimit;
        }
        priceYearly = plan.startPriceAnnual;
        priceMonthly = plan.startPriceMonthly;
        let increaseByAnnual = 0;
        let increaseByMonthly = 0;
        plan.hardcodedSteps = plan.hardcodedSteps.sort((a, b) => b.from - a.from);
        for (let i = 0; i < Math.trunc(numberOfUnits / this.planIncreaseStep); i++) {
            const from = numberOfUnits % this.planIncreaseStep === 0
                ? i * this.planIncreaseStep - (numberOfUnits % this.planIncreaseStep) + 1
                : (i + 1) * this.planIncreaseStep + 1;
            for (let j = 0; j < plan.hardcodedSteps.length; j++) {
                if (from > plan.hardcodedSteps[j].from - 1 && from !== 1) {
                    increaseByAnnual = plan.hardcodedSteps[j].increaseByAnnual;
                    increaseByMonthly = plan.hardcodedSteps[j].increaseByMonthly;
                    break;
                }
            }
            priceYearly += increaseByAnnual;
            priceMonthly += increaseByMonthly;
        }
        if (extraUnits) {
            priceYearly += extraUnits * plan.pricePerUnitAnnual;
            priceMonthly += extraUnits * plan.pricePerUnitMonthly;
        }
        return { priceYearly, priceMonthly };
    }
    getPricing(numberOfUnits, plans) {
        const response = [];
        for (const plan of plans) {
            const prices = this.calulatePrice(plan, numberOfUnits);
            if (plan.name === "Basic" && numberOfUnits > this.disableBasicAfter)
                continue;
            response.push({
                annualPriceId: plan.annualPriceId,
                monthlyPriceId: plan.monthlyPriceId,
                planName: plan.name,
                monthlyPrice: prices.priceMonthly.toFixed(2),
                annualPrice: prices.priceYearly.toFixed(2)
            });
        }
        return response;
    }
    getPlanName(planData, plans) {
        var _a;
        return (_a = plans.find((plan) => plan.annualPriceId === planData.price_id || plan.monthlyPriceId === planData.price_id)) === null || _a === void 0 ? void 0 : _a.name;
    }
}
exports.SubscriptionUtils = SubscriptionUtils;
