import type { FC } from "react";
import React from "react";
import type { CommunicationDto } from "@doorloop/dto";
import { StepStatus } from "DLUI/stepStatus/stepStatus";
import { useTranslation } from "react-i18next";
import { stepsPerTypePerStatus } from "screens/communications/statusesMap";

interface Props {
  dto: CommunicationDto;
}

export const CommunicationStatus: FC<Props> = ({ dto }: Props) => {
  const { t } = useTranslation();
  const { type, status } = dto;
  if (!type || !status) {
    return null;
  }

  const steps = stepsPerTypePerStatus[type][status];
  return steps ? <StepStatus steps={steps(t, dto)} /> : null;
};
