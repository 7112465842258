import React from "react";

import type { FormikProps } from "formik";

import type { OwnerPortalSettingsDto } from "@doorloop/dto";

import AppStrings from "../../../../../locale/keys";
import { PortalUrlForm } from "DLUI/settings/portal/portalUrlForm";
import { Routes } from "../../../../appRouter";

interface ComponentProps {
  formikRef: FormikProps<OwnerPortalSettingsDto>;
}

export const PortalUrl: React.FC<ComponentProps> = ({ formikRef }: ComponentProps) => (
  <PortalUrlForm
    formikRef={formikRef}
    loginRoute={Routes.OWNERS_LOGIN}
    portalUrlText={AppStrings.Settings.GeneralSettings.doorLoopURL.OwnerPortalURL}
    portalUrlDescriptionText={AppStrings.Settings.GeneralSettings.doorLoopURL.OwnerPortalURLDescription}
  />
);
