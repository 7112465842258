import type { PreviewThemeForDeviceProps } from "DLUI/preview/previewThemeForDevice";
import { PreviewThemeForDevice } from "DLUI/preview/previewThemeForDevice";
import ColorsEnum from "utils/colorsEnum";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => {
  return {
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column"
    },
    upperBar: {
      backgroundColor: ColorsEnum.LightShadeGray,
      height: 18,
      borderRadius: "5px 5px 0px 0px",
      alignContent: "center",
      paddingLeft: 10
    },
    dotsContainer: {
      display: "flex",
      flexDirection: "row",
      gap: 4,
      marginBottom: "auto",
      marginTop: "auto",
      height: 18,
      alignItems: "center"
    },
    dot: {
      height: 6,
      width: 6,
      borderRadius: 360,
      backgroundColor: ColorsEnum.LightGray
    },
    bottomBar: {
      backgroundColor: ColorsEnum.LightGray,
      height: "100%",
      display: "flex"
    },
    bottomContainer: {
      backgroundColor: "rgba(245, 246, 250, 1)",
      height: "calc(100% - 10px)",
      width: "calc(100% - 32px)",
      marginTop: "auto",
      marginRight: "auto",
      marginLeft: "auto",
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4
    }
  };
});

export const PreviewForDesktop: React.FC<Omit<PreviewThemeForDeviceProps, "type">> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.upperBar}>
        <div className={classes.dotsContainer}>
          <div className={classes.dot} />
          <div className={classes.dot} />
          <div className={classes.dot} />
        </div>
      </div>
      <PreviewThemeForDevice backgroundImageDimensions={{ height: 123 }} {...props} />
      <div className={classes.bottomBar}>
        <div className={classes.bottomContainer} />
      </div>
    </div>
  );
};
