import { ValidationIndicator } from "DLUI/form";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ColorsEnum from "utils/colorsEnum";
import Text from "DLUI/text/text";
import type { LeaseTenantDto } from "@doorloop/dto";
import { DateFormats, type AddressDisplayDto, type LeaseDto, type PropertyType } from "@doorloop/dto";
import { Link } from "@/components/DLUI/link";
import { Routes } from "@/components/appRouter";
import moment from "moment";
import { entityApiStore } from "@/api/entityApiStore/entityApiStore";

type InferProps<T> = T extends React.ComponentType<infer P> ? P : never;

export interface ComponentProps extends Omit<InferProps<typeof ValidationIndicator>, "displayText"> {
  units?: ReadonlyArray<{
    name: string;
    property: string;
    id: string;
    lease: LeaseDto | undefined;
    address: AddressDisplayDto | undefined;
    propertyType: PropertyType;
  }>;
}

export const ConflictingLeaseError: React.FC<ComponentProps> = ({
  units,
  ...validationIndicatorProps
}: ComponentProps) => {
  const { t } = useTranslation();
  const [tenants, setTenants] = useState<LeaseTenantDto[]>([]);

  const formatDateString = (date: string): string =>
    moment(date, DateFormats.ISO_DATE_SERVER_FORMAT).format(DateFormats.AMERICAN_DATE);

  const allUnitIds = units
    ?.map((unit) => unit?.lease?.units || "")
    .flat()
    .filter(Boolean);

  const tenantsData = entityApiStore.leaseTenants.queries.useGetAll({ filter_units: allUnitIds });

  useEffect(() => {
    if (tenantsData?.data?.data?.data) {
      setTenants(tenantsData?.data?.data?.data);
    }
  }, [tenantsData]);

  const formatTenantsString = (tenants, lease) => {
    if (!tenants || !lease) return "";

    const relevantTenants = tenants?.filter((tenant) => tenant?.lease === lease.id);
    const tenantNames = relevantTenants.map((tenant) => tenant.name);

    if (tenantNames.length <= 2) {
      return tenantNames.join(` ${t(AppStrings.Common.And)} `);
    }

    const lastTenant = tenantNames.pop();
    return `${tenantNames.join(", ")}, ${t(AppStrings.Common.And)} ${lastTenant}`;
  };

  const getLinkData = (unit) => {
    const tenantsString = formatTenantsString(tenants, unit?.lease);
    const propertyType = t(AppStrings.Common.Enums.PropertyType[unit?.propertyType || ""]) || "";
    const start = formatDateString(unit?.lease?.start || "");
    const end = formatDateString(unit?.lease?.end || "");

    const conflictStringToDisplay = `${unit.address?.street1} (${propertyType}) - ${unit.name} ${t(AppStrings.Common.For)} ${tenantsString},
    ${t(AppStrings.Common.RunningFrom)} ${start} ${t(AppStrings.Common.To)} ${end}.`;

    const routeToLink = `${Routes.ACTIVE_LEASES}/${unit?.lease?.id}/overview`;

    return { routeToLink, conflictStringToDisplay };
  };

  const conflictingLeasesMessage = useMemo(
    (): React.ReactNode =>
      units && units.length > 0 ? (
        <View gap={5}>
          <Text colorEnum={ColorsEnum.ErrorDark}>{t(AppStrings.Leases.NewLease.NewLeaseConflictErrorMessage)}</Text>

          <View gap={5}>
            {units.map((unit, index) => {
              const { routeToLink, conflictStringToDisplay } = getLinkData(unit);

              return (
                <Link key={(unit.id ?? "unitListItem") + index} type={"tab"} hrefUrl={routeToLink}>
                  <Text fontSize={16} color={"lightBlue"} value={conflictStringToDisplay} underLine bold />
                </Link>
              );
            })}
          </View>
          <Text colorEnum={ColorsEnum.ErrorDark}>{t(AppStrings.Leases.NewLease.IfNewLeaseDatesCorrectHelperText)}</Text>
        </View>
      ) : (
        t(AppStrings.Leases.NewLease.ConflictErrorMessage)
      ),
    [units, t]
  );

  return <ValidationIndicator maxWidth={600} displayText={conflictingLeasesMessage} {...validationIndicatorProps} />;
};
