/* eslint-disable @typescript-eslint/no-use-before-define */
import { buildPaginatedQueryKey } from "@/api/entityApiStore/createQueryStore";
import { entityApiStore } from "@/api/entityApiStore/entityApiStore";
import { PersonTypeEnum } from "@doorloop/dto";
import { useMemo } from "react";
import { NEXT_PAGE_SIZE } from "./peopleAutoComplete.constants";
import { narrowPersonDto } from "./peopleAutoComplete.utils";

import type {
  GetAllBaseQueryRequest,
  GetAllOwnersQuery,
  GetAllPeopleQueryFilter,
  GetAllTenantsQuery,
  GetAllVendorsQuery,
  OwnerDto,
  PersonDtoType,
  TenantDto,
  TenantType,
  VendorDto
} from "@doorloop/dto";
import type { UseGetAllPaginatedOptions } from "../../../../../api/entityApiStore/createQueryStore.types";

export type PeopleOrderDictionary = Record<PersonTypeEnum, number>;
export type PersonTypeQueryFilter = Partial<Record<PersonTypeEnum, GetAllPeopleQueryFilter>>;

export const defaultTypeFilter: readonly PersonTypeEnum[] = [
  PersonTypeEnum.VENDOR,
  PersonTypeEnum.OWNER,
  PersonTypeEnum.TENANT
];

export const defaultPersonQueryFilter = {
  page_offset: 0,
  page_size: 4,
  sort_by: "createdAt",
  sort_descending: true,
  filter_text: "",
  filter_consistentSortFields: ["createdAt"]
};

export interface UseGetPeopleProps<TFilter extends readonly PersonTypeEnum[], TOrderFilter extends TFilter> {
  queryFilter?: GetAllBaseQueryRequest;
  typeFilter?: TFilter;
  orderFilter?: TOrderFilter;
  tenantType?: TenantType;
  staleTime?: number;
  nextPageSize?: number;
  queryOptions?: UseGetAllPaginatedOptions<PersonDtoType>;
}

/**
 * The current implementation for fetching people is slower and less efficient
 * in terms of network requests and database queries. It was originally designed
 * to be fetched through aggregation.
 * See https://github.com/doorloop/doorloop/pull/4819
 * @description How Query Filters Work
 * If the type filter isn't set and the order filter is set, the query result
 * will contain all types; If the order filter is set with partial types and
 * the type filter isn't set, the query result will be partially ordered. This
 * is so a consumer doesn't have to pass all types to the order filter if the
 * type filter isn't set, because it's a hassle to have to pass all types to
 * the order filter when the type filter isn't set.
 * @example
 * typeFilter = [] // when empty, the query will contain all types, so intuitively, if order
 * filter is set, it should contain all types, but it's inconvenient, so we allow
 * partial order filter and the query result will be partially ordered.
 * orderFilter = ['owner', 'tenant']
 */
export function useGetPeople<TFilter extends readonly PersonTypeEnum[], TOrderFilter extends TFilter>({
  queryFilter: queryFilterBase = {},
  staleTime,
  nextPageSize = queryFilterBase.page_size || NEXT_PAGE_SIZE,
  queryOptions,
  ...restOptions
}: UseGetPeopleProps<TFilter, TOrderFilter>) {
  const queryFilter = useMemo<GetAllBaseQueryRequest>(() => {
    return { ...defaultPersonQueryFilter, ...queryFilterBase };
  }, [queryFilterBase]);

  const typeFilter = restOptions.typeFilter || defaultTypeFilter;
  const orderFilterBase = restOptions.orderFilter || typeFilter;
  const tenantType = restOptions.tenantType;

  // It seems TS can't infer the type of the `filter_type` and `filter_order`
  // when applying them indirectly to the method signature, so we have to
  // manually check if the order filter contains types that are not in the
  // type filter and throw an error, or else this will cause a silent runtime
  // bug.
  if (orderFilterBase.some((type) => !typeFilter.includes(type))) {
    throw new Error("Order filter contains types that are not included in type filter");
  }

  const orderFilter = orderFilterBase.filter((type) => typeFilter.includes(type));
  // To allow partial order, we setup a _Set_ where we apply the order filter
  // first and the rest of the types, e.g.: Given a `filter_order` of
  // `['tenant', 'owner']`, and a `type_filter` of `['owner', 'tenant',
  // 'vendor']` the resulted order will be `['tenant', 'owner', 'vendor']`.
  const orderedQueryTypesSet = new Set<PersonTypeEnum>([...orderFilter, ...typeFilter]);
  const orderedQueryTypes = Array.from(orderedQueryTypesSet);

  const orderDictionary = useMemo(
    () => Object.fromEntries(orderedQueryTypes.map((type, index) => [type, index])) as PeopleOrderDictionary,
    [orderedQueryTypes]
  );

  const vendors = useGetVendors(queryFilter, {
    enabled: typeFilter.includes(PersonTypeEnum.VENDOR),
    staleTime,
    nextPageSize,
    ...queryOptions
  });

  const owners = useGetOwners(
    { ...queryFilter, filter_skipProperties: true },
    {
      enabled: typeFilter.includes(PersonTypeEnum.OWNER),
      staleTime,
      nextPageSize,
      ...queryOptions
    }
  );

  const tenants = useGetTenants(
    {
      ...queryFilter,
      ...(tenantType && { filter_type: tenantType })
    },
    {
      enabled: typeFilter.includes(PersonTypeEnum.TENANT),
      staleTime,
      nextPageSize,
      ...queryOptions
    }
  );

  // https://tanstack.com/query/v4/docs/framework/react/guides/disabling-queries#isinitialloading
  const isInitialLoading =
    vendors.query.isInitialLoading || owners.query.isInitialLoading || tenants.query.isInitialLoading;
  const isLoading = vendors.query.isLoading || owners.query.isLoading || tenants.query.isLoading;
  const isFetching = vendors.query.isFetching || owners.query.isFetching || tenants.query.isFetching;
  const isRefetching = vendors.query.isRefetching || owners.query.isRefetching || tenants.query.isRefetching;

  const dataObject = {
    vendor: vendors.allData.map((vendorDto) =>
      narrowPersonDto(vendorDto, PersonTypeEnum.VENDOR, orderDictionary.vendor)
    ),
    owner: owners.allData.map((ownerDto) => narrowPersonDto(ownerDto, PersonTypeEnum.OWNER, orderDictionary.owner)),
    tenant: tenants.allData.map((tenantDto) =>
      narrowPersonDto(tenantDto, PersonTypeEnum.TENANT, orderDictionary.tenant)
    )
  };

  return {
    data: orderedQueryTypes.map((personType) => dataObject[personType]).flat(),
    queries: {
      vendor: vendors.query,
      owner: owners.query,
      tenant: tenants.query
    },
    orderDictionary,
    isInitialLoading,
    isLoading,
    isFetching,
    isRefetching,
    queryFilter
  };
}

export function useGetVendors(query: GetAllVendorsQuery, options: UseGetAllPaginatedOptions<VendorDto>) {
  return entityApiStore.vendors.queries.useGetAllPaginated(query, options);
}

export function useGetOwners(query: GetAllOwnersQuery, options: UseGetAllPaginatedOptions<OwnerDto>) {
  return entityApiStore.owners.queries.useGetAllPaginated(query, options);
}

export function useGetTenants(query: GetAllTenantsQuery, options: UseGetAllPaginatedOptions<TenantDto>) {
  return entityApiStore.tenants.queries.useGetAllPaginated(query, options);
}

export const peopleQueryKeys = {
  vendors: buildPaginatedQueryKey("vendors", defaultPersonQueryFilter),
  owners: buildPaginatedQueryKey("owners", defaultPersonQueryFilter),
  tenants: buildPaginatedQueryKey("tenants", defaultPersonQueryFilter)
};
