import { DataCy } from "@doorloop/dto";
import { LoadingAnimation } from "DLUI/animations/loadingAnimation";
import { View } from "DLUI/view";
import type { FC } from "react";
import React from "react";

export const ListLoadingIndicator: FC = () => (
  <View
    justifyContent="center"
    alignItems="center"
    marginBottom={20}
    fullWidth
    data-cy={DataCy.DLUI.dataList.loadingIndicator}
  >
    <LoadingAnimation type="dots" width={50} height={50} />
  </View>
);
