import "reflect-metadata";
import ReactDOM from "react-dom";
import "./baseStyles.css";
import * as serviceWorker from "./serviceWorker";
import "@progress/kendo-theme-material/dist/all.css";
import { App } from "./app";

window.addEventListener("vite:preloadError", (event) => {
  event.preventDefault();
  window.location.reload();
});

document.body.classList.add("DoorLoop");
ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.register();
