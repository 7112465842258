import { View } from "DLUI/view";
import { IconButton } from "DLUI/form";
import { DuplicateIconAlt, TrashIconOutline } from "../../../assets/icons";
import type { Ref } from "react";
import React from "react";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import { DataCy } from "@doorloop/dto";
import AppStrings from "../../../locale/keys";
import { TextTooltip } from "DLUI/popover/textTooltip";
import { useTheme } from "@material-ui/core/styles";
import noop from "lodash/noop";

interface ListInputItemContainerProps {
  id?: string;
  domRef?: Ref<HTMLDivElement>;
  showDeleteButton?: boolean;
  isDeleteButtonDisabled?: boolean;
  showDuplicateButton?: boolean;
  onClickDeleteButton?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClickDuplicateButton?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const ListInputsContainer = ({ children }: React.PropsWithChildren<unknown>) => (
  <View marginTop={20} marginBottom={20} flexDirection="column" gap={5}>
    {children}
  </View>
);

export const ListItemInputsContainer = ({
  id,
  domRef,
  showDeleteButton = true,
  showDuplicateButton = true,
  isDeleteButtonDisabled = false,
  onClickDeleteButton = noop,
  onClickDuplicateButton = noop,
  children
}: React.PropsWithChildren<ListInputItemContainerProps>) => {
  const { isMobile } = useResponsiveHelper();
  const theme = useTheme();
  const rootPadding = isMobile ? 15 : 10;

  return (
    <View
      id={id}
      domRef={domRef}
      flexDirection="row"
      backgroundColor="dark"
      paddingTop={rootPadding}
      paddingRight={rootPadding}
      paddingBottom={rootPadding}
      paddingLeft={rootPadding}
      borderRadius={5}
      alignItems="flex-start"
      style={{
        boxShadow: "inset 0px 0px 0px 1px rgba(24, 44, 76, 0.1)",
        boxSizing: "border-box",
        width: "100%"
      }}
    >
      <View flex={1} alignItems="flex-start" flexDirection="row" gap={20} noWrap={!isMobile}>
        {children}
      </View>
      {showDuplicateButton && (
        <TextTooltip value={AppStrings.Common.Duplicate} useButton={false}>
          <IconButton
            paddingTop={8}
            paddingRight={8}
            paddingBottom={8}
            paddingLeft={8}
            size={isMobile ? 28 : 24}
            aria-label="duplicate"
            Icon={DuplicateIconAlt}
            marginTop={isMobile ? 6 : 2}
            style={{ color: theme.newPalette.text.secondary }}
            pathColor={"current-color"}
            onClick={onClickDuplicateButton}
            dataCy={DataCy.DLUI.duplicateIconButton}
          />
        </TextTooltip>
      )}
      {showDeleteButton && (
        <IconButton
          paddingTop={8}
          paddingRight={8}
          paddingBottom={8}
          paddingLeft={8}
          size={isMobile ? 24 : 20}
          aria-label="delete"
          pathColor={"current-color"}
          style={{ color: theme.newPalette.text.secondary }}
          Icon={TrashIconOutline}
          marginTop={isMobile ? 6 : 2}
          disabled={isDeleteButtonDisabled}
          onClick={onClickDeleteButton}
          dataCy={DataCy.DLUI.trashIconButton}
        />
      )}
    </View>
  );
};
