"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaxpayerIdType = void 0;
var TaxpayerIdType;
(function (TaxpayerIdType) {
    TaxpayerIdType["SSN"] = "SSN";
    TaxpayerIdType["EIN"] = "EIN";
    TaxpayerIdType["ITIN"] = "ITIN";
    TaxpayerIdType["ATIN"] = "ATIN";
})(TaxpayerIdType || (exports.TaxpayerIdType = TaxpayerIdType = {}));
