import type { ManagementFeeRuleDto } from "@doorloop/dto";
import { DataCy, isFixedBasedFee, isPercentageBasedFee, ManagementFeeRuleTypesEnum } from "@doorloop/dto";

import type { TextColor } from "DLUI/text/text";
import type { EnTranslation } from "locale";
import { useTypedTranslation } from "locale";
import { ItemLabelChip } from "screens/settings/common/itemLabelChip";
import clsx from "clsx";
import { entityApiStore } from "api/entityApiStore/entityApiStore";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { useGeneralStyles } from "styles/generalStyles";
import { useCallback } from "react";
import isNil from "lodash/isNil";
import { useManagementFeesStyles } from "screens/settings/managementFees/useStyles";
import Text from "DLUI/text/text";

export interface AccountsDescriptionProps {
  rule: ManagementFeeRuleDto;
  index: number;
}

function getRuleTypeDescription(rule: ManagementFeeRuleTypesEnum | undefined, isMobile = false): EnTranslation {
  if (!rule) return "settings.generalSettings.managementFees.defaults.type";
  const ruleTypes: Record<ManagementFeeRuleTypesEnum, EnTranslation> = {
    [ManagementFeeRuleTypesEnum.AccountBasedFeeAccrual]: isMobile
      ? "settings.generalSettings.managementFees.rules.accrualBasis"
      : "settings.generalSettings.managementFees.rules.accrual",
    [ManagementFeeRuleTypesEnum.AccountBasedFeeCash]: isMobile
      ? "settings.generalSettings.managementFees.rules.cashBasis"
      : "settings.generalSettings.managementFees.rules.cash",
    [ManagementFeeRuleTypesEnum.FixedBasedFee]: "settings.generalSettings.managementFees.rules.flat"
  };
  return ruleTypes[rule];
}

function getFeeText(rule: ManagementFeeRuleDto): string {
  const { fee, ruleType } = rule;
  if (isNil(fee)) return "settings.generalSettings.managementFees.defaults.fee";
  return isFixedBasedFee(ruleType) ? `$${fee}` : `${fee}%`;
}

const FallbackText = () => <Text fontSize={14} bold value={"userRoles.accounts"} color={"secondary-gray"} />;

export const AccountsDescription = ({ rule, index }: AccountsDescriptionProps) => {
  const { data: accounts } = entityApiStore.accounts.queries.useGetDictionary();
  const generalClasses = useGeneralStyles();
  const classes = useManagementFeesStyles();
  const { t } = useTypedTranslation();
  const { isDesktop, isMobile } = useResponsiveHelper();

  const color: TextColor = !isNil(rule.fee) && rule.ruleType ? "black" : "secondary-gray";

  const canShowAccountsDescription = useCallback(
    (rule: ManagementFeeRuleDto): boolean => {
      if (!accounts) return false;
      const isBaseAccountExist = Boolean(rule.baseAccount && accounts[rule.baseAccount]);
      const isExpenseAccountExist = Boolean(rule.expenseAccount && accounts[rule.expenseAccount]);
      if (isFixedBasedFee(rule.ruleType)) {
        return isExpenseAccountExist;
      }
      return isBaseAccountExist && isExpenseAccountExist;
    },
    [accounts]
  );

  return (
    <div
      data-cy={DataCy.globalSettings.managementFees.rules.rule.title}
      className={clsx(generalClasses.flexRow, generalClasses.verticalAlign)}
    >
      <ItemLabelChip value={index + 1} />
      &nbsp;
      <Text fontSize={14} bold value={getFeeText(rule)} color={color} />
      &nbsp;
      <Text fontSize={14} bold value={`(${t(getRuleTypeDescription(rule.ruleType, isMobile))})`} color={color} />
      {isDesktop && (
        <>
          &nbsp;
          <Text fontSize={14} bold value={`·`} color={color} />
          &nbsp;
          {accounts && canShowAccountsDescription(rule) ? (
            <div className={clsx(generalClasses.flexRow, classes.accountsText)}>
              {rule.baseAccount && isPercentageBasedFee(rule.ruleType) && (
                <>
                  <Text fontSize={14} value={"common.from"} color={"black"} />
                  &nbsp;
                  <Text fontSize={14} bold value={accounts[rule.baseAccount].name} color={"black"} />
                  &nbsp;
                </>
              )}
              {rule.expenseAccount && (
                <>
                  <Text fontSize={14} value={"common.to"} color={"black"} />
                  &nbsp;
                  <Text fontSize={14} bold value={accounts[rule.expenseAccount].name} color={"black"} />
                </>
              )}
            </div>
          ) : (
            <FallbackText />
          )}
        </>
      )}
    </div>
  );
};
