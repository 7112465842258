import { View } from "DLUI/view";
import type { PropsWithChildren } from "react";
import type { ViewComponentProps } from "DLUI/view/view";

export const SettingsBlock = ({ children, ...rest }: PropsWithChildren<ViewComponentProps>) => (
  <View
    backgroundColor={"white"}
    borderRadius={8}
    border={"1px solid rgba(24, 44, 76, 0.1)"}
    style={{
      boxSizing: "border-box"
    }}
    {...rest}
  >
    {children}
  </View>
);
