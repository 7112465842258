"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsValidSubdomain = IsValidSubdomain;
const class_validator_1 = require("class-validator");
///https://stackoverflow.com/questions/7930751/regexp-for-subdomain
function IsValidSubdomain(validationOptions) {
    return function (object, propertyName) {
        (0, class_validator_1.registerDecorator)({
            name: "isValidSubdomain",
            target: object.constructor,
            propertyName,
            constraints: [],
            options: validationOptions,
            validator: {
                validate(value, args) {
                    if (!value)
                        return false;
                    /*check if string contains spaces*/
                    if (/\s/.test(value)) {
                        return false;
                    }
                    return /^[a-zA-Z0-9]+[a-zA-Z0-9\-]*$/.test(value);
                }
            }
        });
    };
}
