import { baseRouteHistoryLength } from "utils/atoms";
import { useCallback } from "react";
import { isNull } from "lodash";
import { useEffect } from "react";
import { useSetAtom } from "jotai";

const baseHistoryLengthKey = "baseHistoryLength";

export const useBaseHistoryLength = () => {
  const setInitialHistoryLength = useSetAtom(baseRouteHistoryLength);

  const initializeInitialHistoryLength = useCallback(() => {
    // This is used to determine if the user has a DoorLoop page to return back to
    // See `breadCrumbs.tsx` back button disabled state for better context
    const baseHistoryLength = sessionStorage.getItem(baseHistoryLengthKey);

    if (isNull(baseHistoryLength)) {
      setInitialHistoryLength(window.history.length);
      sessionStorage.setItem(baseHistoryLengthKey, String(window.history.length));
    } else {
      setInitialHistoryLength(Number(baseHistoryLength));
    }
  }, [setInitialHistoryLength]);

  useEffect(() => {
    initializeInitialHistoryLength();
  }, [initializeInitialHistoryLength]);
};
