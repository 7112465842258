import React from "react";
import Text from "DLUI/text";
import { tagsApi } from "api/tagsApi";
import { View } from "DLUI/view";
import { Icon } from "DLUI/icon";
import { TagIcon } from "assets/icons";

interface ComponentProps {
  tags?: string[];
}

const Tags: React.FC<ComponentProps> = ({ tags }: ComponentProps) => {
  let tagResult = "";

  if (tags) {
    let tagIdx = 0;
    for (const tag of tags) {
      const tagName = tagsApi.getItemFromDictionary(tag as string);

      if (tagName && tag !== tagName.name) {
        tagResult += `${tagIdx === 0 ? tagName.name : `, ${tagName.name}`}`;
        tagIdx++;
      }
    }
  }

  return (
    <View flexDirection={"row"} alignItems={"center"} noWrap>
      {tagResult && <Icon size={12} Source={TagIcon} pathColor={"light-gray"} marginRight={4} />}
      <Text fontSize={12} overFlow={"ellipsis"} numberOfLines={1}>
        {tagResult ? tagResult : " "}
      </Text>
    </View>
  );
};

export default Tags;
