import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      marginRight: 20,
      marginTop: 20,
      width: 50,
      height: 50,
      backgroundColor: theme.palette.screenBackground.light
    },
    iconButtonContainer: {
      borderRadius: 10
    },
    inputMargin: {
      marginTop: 20,
      paddingRight: 20,
      paddingLeft: 20
    },
    renewalOfferLineContainer: {
      backgroundColor: theme.palette.screenBackground.light,
      borderRadius: 10
    },
    separationLine: {
      borderTop: "1px solid rgba(124, 132, 172, 0.2);",
      height: 1,
      width: "97%"
    },
    MultiLineInput: {
      margin: "0px 20px 0px 0px",
      height: 100
    }
  })
);
