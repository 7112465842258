import type { ReactNode } from "react";
import _ from "lodash";

interface ConfInterface {
  CreateNewOptionComponent: ReactNode;
  onCreate: (data: any) => void;
  createError?: string;
  onCreateError?: (error: string) => void;
  setShowCreateOption?: (bool: boolean) => void;
  groupNameProp?: string;
  optionTitle?: string;
  queryParams?: Record<string, string | boolean | string[]>;
  createNewOptionType?: string;
}

export const addCreateNewOption = (options: any[], conf: ConfInterface) => {
  const {
    groupNameProp,
    optionTitle,
    CreateNewOptionComponent,
    onCreate,
    createError,
    onCreateError,
    setShowCreateOption,
    createNewOptionType
  } = conf;
  const title = optionTitle || "Create new";
  let results: any[] = [];

  if (groupNameProp) {
    const tempOptions = _.groupBy(options, (x) => x[groupNameProp]);
    _.forOwn(tempOptions, (arr) =>
      results.push(
        {
          CreateNewOptionComponent,
          [groupNameProp]: arr[0][groupNameProp],
          name: title,
          onCreate,
          onCreateError,
          createError,
          setShowCreateOption,
          type: createNewOptionType
        },
        ...arr
      )
    );
  } else {
    results = [
      {
        CreateNewOptionComponent,
        name: title,
        onCreate,
        onCreateError,
        createError,
        setShowCreateOption,
        type: createNewOptionType
      },
      ...options
    ];
  }

  if (!results.length) {
    results.push({
      CreateNewOptionComponent,
      name: title,
      onCreateError,
      createError,
      onCreate,
      setShowCreateOption,
      type: createNewOptionType
    });
  }

  return results;
};

const getTypeByClassGroup = (types, classGroupName) => types.filter((type) => type.startsWith(classGroupName));

const selectOptionsMapByFilterTypes = {
  filter_type: (queryParams) => [queryParams.filter_type],
  filter_year: (queryParams) => queryParams.filter_year,

  filter_types: (queryParams, conf) =>
    conf.groupEnumName ? getTypeByClassGroup(queryParams.filter_types, conf.groupEnumName) : queryParams.filter_types,

  filter_class: (queryParams, conf) => conf.enumSelection[queryParams.filter_class],

  filter_classes: (queryParams, conf) => {
    if (conf.groupEnumName) {
      return conf.enumSelection[conf.groupEnumName];
    }
    const filterClassesArr: any[] = [];
    queryParams.filter_classes.forEach((filterClass) => filterClassesArr.push(...conf.enumSelection[filterClass]));

    return filterClassesArr;
  }
};

export const selectOptionsByQueryParams = (queryParams, conf) => {
  if (!queryParams) return null;

  let selectOptions = null;
  const queryParamKey = Object.keys(queryParams).find((key) => selectOptionsMapByFilterTypes[key]);

  if (queryParamKey) {
    selectOptions = selectOptionsMapByFilterTypes[queryParamKey](queryParams, conf);
  }

  return selectOptions;
};
