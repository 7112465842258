"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactDetailsDto = exports.DisplayTenantDto = exports.TenantDto = void 0;
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const prospectInfo_dto_1 = require("./prospectInfo.dto");
const dependant_dto_1 = require("../common/dependant.dto");
const person_dto_1 = require("../common/person.dto");
const pet_dto_1 = require("../common/pet.dto");
const vehicle_dto_1 = require("../common/vehicle.dto");
const emergencyContact_dto_1 = require("../common/emergencyContact.dto");
const portalInfo_dto_1 = require("../common/portalInfo.dto");
const email_dto_1 = require("../common/email.dto");
const phone_dto_1 = require("../common/phone.dto");
const validators_1 = require("../common/validators");
class TenantDto extends person_dto_1.PersonDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.TenantDto = TenantDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => dependant_dto_1.DependantDto),
    __metadata("design:type", Array)
], TenantDto.prototype, "dependants", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => pet_dto_1.PetDto),
    __metadata("design:type", Array)
], TenantDto.prototype, "pets", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => vehicle_dto_1.VehicleDto),
    __metadata("design:type", Array)
], TenantDto.prototype, "vehicles", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => emergencyContact_dto_1.EmergencyContactDto),
    __metadata("design:type", Array)
], TenantDto.prototype, "emergencyContacts", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], TenantDto.prototype, "useUnitAddressAsPrimaryAddress", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => prospectInfo_dto_1.ProspectInfoDto),
    __metadata("design:type", prospectInfo_dto_1.ProspectInfoDto)
], TenantDto.prototype, "prospectInfo", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => portalInfo_dto_1.PortalInfoDto),
    (0, class_validator_1.ValidateIf)((dto) => !dto.disablePersonDetailsValidation),
    (0, class_validator_1.ValidateNested)({ each: true }),
    __metadata("design:type", portalInfo_dto_1.PortalInfoDto)
], TenantDto.prototype, "portalInfo", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateIf)((dto) => !dto.disablePersonDetailsValidation),
    (0, class_validator_1.Validate)(validators_1.isPersonPhoneOrEmailArrayNotEmpty, ["phones"]),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => email_dto_1.EmailDto),
    __metadata("design:type", Array)
], TenantDto.prototype, "emails", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateIf)((dto) => !dto.disablePersonDetailsValidation),
    (0, class_validator_1.Validate)(validators_1.isPersonPhoneOrEmailArrayNotEmpty, ["emails"]),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => phone_dto_1.PhoneDto),
    __metadata("design:type", Array)
], TenantDto.prototype, "phones", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], TenantDto.prototype, "stripeCustomerId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsDate)(),
    __metadata("design:type", Date)
], TenantDto.prototype, "acceptedOnTOS", void 0);
class DisplayTenantDto extends TenantDto {
    constructor() {
        super();
        this.selected = false;
    }
}
exports.DisplayTenantDto = DisplayTenantDto;
class ContactDetailsDto {
}
exports.ContactDetailsDto = ContactDetailsDto;
__decorate([
    (0, class_validator_1.Validate)(validators_1.isPersonPhoneOrEmailArrayNotEmpty, ["phones"]),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => email_dto_1.EmailDto),
    __metadata("design:type", Array)
], ContactDetailsDto.prototype, "emails", void 0);
__decorate([
    (0, class_validator_1.Validate)(validators_1.isPersonPhoneOrEmailArrayNotEmpty, ["emails"]),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => phone_dto_1.PhoneDto),
    __metadata("design:type", Array)
], ContactDetailsDto.prototype, "phones", void 0);
