import type { ReactElement } from "react";
import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import AppStrings from "../../../../../locale/keys";
import type { AccountingMethod } from "@doorloop/dto";

export interface GridHeaderProps {
  filter_date_from?: string;
  filter_date_to?: string;
  filter_asOfDate?: string;
  filter_property?: string;
  gridText?: string;
  exportInProgress?: boolean;
  didPressExportExcel?: () => void;

  accountingMethod?: AccountingMethod;
  isDateEditable?: boolean;
}

interface WrapperProps<GetAllDtoType> {
  reportName: string;
  selectionLength?: number;
  requestInProgress: boolean;
  didPressExportExcel: () => void;
  Header?: React.FC<GridHeaderProps>;
  CustomHeader?: ReactElement;
  actionButtons?: React.ReactNode;
  hideActionButtons?: boolean;
  filterObj: GetAllDtoType;
}

export const GridHeaderWrapper = <GetAllDtoType,>({
  reportName,
  Header,
  CustomHeader,
  selectionLength,
  requestInProgress,
  didPressExportExcel,
  actionButtons,
  hideActionButtons,
  filterObj
}: WrapperProps<GetAllDtoType>) => {
  const { filter_date_from, filter_date_to, filter_asOfDate, filter_property } = (filterObj as any) || {};
  const accountingMethod = filterObj?.["filter_accountingMethod"];

  return (
    <>
      {CustomHeader
        ? CustomHeader
        : Header && (
            <Header
              gridText={reportName}
              filter_date_from={filter_date_from}
              filter_date_to={filter_date_to}
              filter_asOfDate={filter_asOfDate}
              filter_property={filter_property}
              exportInProgress={requestInProgress}
              didPressExportExcel={didPressExportExcel}
              accountingMethod={accountingMethod}
            />
          )}

      {!hideActionButtons && (
        <View flexDirection={"row"} className={"hide-on-print"} alignItems={"center"} gap={10}>
          <View width={"auto"} noWrap>
            <Text
              value={AppStrings.BulkActions.XSelected}
              color={"secondary-gray"}
              fontSize={14}
              bold
              replaceObject={{ count: selectionLength ?? 0 }}
            />
          </View>
          <View width={"auto"} noWrap>
            {actionButtons}
          </View>
        </View>
      )}
    </>
  );
};
