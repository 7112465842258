import type { FC } from "react";
import React from "react";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import { ObjectPermission } from "@doorloop/dto";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { FastField } from "formik";
import { TextField } from "DLUI/form";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import { useTranslation } from "react-i18next";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import AddressAutoCompleteFormWrapper from "DLUI/form/autoComplete/addressAutoComplete/addressAutoCompleteFormWrapper";

export const EditPropertyGeneralInfo: FC = () => {
  const { dialogHorizontalPadding } = DialogsHelper();
  const { t } = useTranslation();

  return (
    <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding} flex={1}>
      <RestrictedPermissionAccess
        clearance={{
          permission: ObjectPermission.properties,
          field: "edit"
        }}
        showNoAccess
      >
        <View alignItems={"flex-start"}>
          <View>
            <Text fontSize={16} value={AppStrings.Listing.GeneralPropertyInfoDescription} />
          </View>
          <HorizontalSeparationLine marginTop={20} />
          <View marginTop={20}>
            <Text fontSize={18} value={AppStrings.Properties.Settings.Property} bold />
          </View>
          <View>
            <FastField
              component={TextField}
              label={t(AppStrings.Properties.Settings.PropertyName)}
              name={"name"}
              marginTop={20}
            />
          </View>
          <AddressAutoCompleteFormWrapper
            withAddressVerification
            mustAcceptInvalidAddress
            formikName={"address"}
            fullWidthAddresses
          />
        </View>
        <View marginTop={20}>
          <FastField
            component={TextField}
            label={t(AppStrings.Properties.Settings.DetailedDescriptionOfThisProperty)}
            name={`description`}
            variant="outlined"
            rowsMax={6}
            rows={8}
            multiline
            maxLength={1000}
          />
        </View>
        <View height={40} />
      </RestrictedPermissionAccess>
    </View>
  );
};
