import { TenantType } from "@doorloop/dto";

export default {
  status: "Status",
  occupied: "Occupied",
  available: "Available",
  issues: "Issues",
  eviction: "Eviction",
  marketRent: "Market Rent",
  listingStatus: "Listing Status",
  open: "Open",
  tasks: "Tasks",
  active: "Active",
  inactive: "Inactive",
  usDollar: "US$",
  listed: "Listed",
  notListed: "Not Listed",
  quickActions: "Quick Actions",
  quickActionsInstructions: "What would you like to do next?",
  renewalProcess: "Renewal Process",
  cashPaymentDetails: "CashPayments Details",
  cashPaymentDetailsInstructions:
    "Print your tenant's payment details for their convenience when making cash payments.",
  rentalApplicationScreeningReportsSection: {
    title: "Screening Reports",
    [TenantType.LEASE_TENANT]: "tenant",
    [TenantType.PROSPECT_TENANT]: "prospect",
    description:
      "Screening reports provide crucial information that can help you mitigate the risk of costly evictions and property damage by screening your applicants.",
    reports: {
      applicationTitle: "Application #{{reference}}",
      submittedOn: "Submitted on: <Date/>",
      expiresOn: "Expires on: <Date/>",
      getReports: "Get Reports"
    },
    inProgress: {
      title: "Screening in progress",
      description:
        "This tenant has a screening process in progress. Once it is completed, you will be able to access this tenant's screening report.",
      linkText: "View Screening Status"
    },
    notScreened: {
      title: "Avoid Costly Future Issues - {{person}} Was Not Screened",
      description:
        "Selecting an unscreened {{person}} can result in significant rent losses, property damages, and costly eviction processes. Investing in screening could save you up to $3,500 in eviction expenses. \n\nDon't gamble with your property – screen your prospects using <Link>DoorLoop's reliable solution.</Link>",
      sendApplication: "Start Screening",
      viewReportsExample: "Browse Report Samples",
      reportsExampleDialogTitle: "Screening Reports Example"
    }
  }
};
