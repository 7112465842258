import type { JSX } from "react";
import React from "react";

import { Grid } from "@material-ui/core";

import AppStrings from "../../../../../../locale/keys";
import DLButton from "DLUI/button/dlButton";
import Text from "DLUI/text";
import { ShakeEffectView } from "DLUI/animatableView";
import { TermsAndPrivacyView } from "DLUI/termsAndPrivacy";
import { ValidationIndicator } from "DLUI/form";
import { View } from "DLUI/view";
import { useResponsiveHelper } from "../../../../../../contexts/responsiveContext";

export interface CreditCardBottomFormProps {
  currency?: string;
  totalAmount?: number;
  showErrorShakeEffect: boolean;
  onButtonClick?: () => void;
  isLoading: boolean;
  errorText?: string | null;
  isButtonDisabled?: boolean;
  PaymentSummaryDetailsComponent?: JSX.Element;
  tosLink?: string;
  showTitle?: boolean;
}

const CreditCardBottomForm: React.FC<CreditCardBottomFormProps> = ({
  currency,
  totalAmount,
  showErrorShakeEffect,
  onButtonClick,
  isLoading,
  errorText,
  isButtonDisabled,
  PaymentSummaryDetailsComponent,
  tosLink,
  showTitle = true
}: CreditCardBottomFormProps): JSX.Element => {
  const { isMobile } = useResponsiveHelper();

  return (
    <>
      <View paddingLeft={isMobile ? 20 : 0} flexDirection={"row"}>
        <View>
          {PaymentSummaryDetailsComponent}
          {showTitle && <Text value={AppStrings.Common.PaymentSummary} fontWeight={700} fontSize={18} marginTop={20} />}
        </View>
        <Grid item xs={6}>
          <Text value={AppStrings.Common.ListHeader.Total} fontWeight={700} marginTop={20} />
        </Grid>
        <Grid item xs={6}>
          <View alignItems={"flex-end"} paddingRight={isMobile ? 20 : 0}>
            <Text
              fontWeight={700}
              marginTop={20}
              color={"green"}
              formatType={"currency"}
              value={String(totalAmount)}
              overrideCurrency={currency}
              useOverrideCurrency={Boolean(currency)}
            />
          </View>
        </Grid>
      </View>
      <ShakeEffectView fullWidth showEffect={showErrorShakeEffect}>
        <View marginTop={50} flexDirection="row">
          <View alignItems="center">
            <DLButton
              onClick={onButtonClick ? onButtonClick : undefined}
              actionText={AppStrings.Common.MakeAPayment}
              style={{ minWidth: 300 }}
              isLoading={isLoading}
              disabled={isButtonDisabled}
            />

            <TermsAndPrivacyView tosLink={tosLink} />
          </View>
        </View>
      </ShakeEffectView>
      <ValidationIndicator shouldShow={Boolean(errorText)} displayText={errorText || ""} fullWidth />
    </>
  );
};

export default CreditCardBottomForm;
