"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAllLeaseTenantsQuery = void 0;
const isDateStringWithoutTime_validator_1 = require("../common/validators/isDateStringWithoutTime.validator");
const class_validator_1 = require("class-validator");
const getAllBaseRequest_query_1 = require("../getAllBaseRequest.query");
const properties_1 = require("../properties");
const leaseTenantStatus_enum_1 = require("./leaseTenantStatus.enum");
const tenants_1 = require("../tenants");
const common_1 = require("../common");
const leaseSituation_enum_1 = require("./leaseSituation.enum");
class GetAllLeaseTenantsQuery extends getAllBaseRequest_query_1.GetAllBaseQueryRequest {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.GetAllLeaseTenantsQuery = GetAllLeaseTenantsQuery;
__decorate([
    (0, class_validator_1.IsEnum)(properties_1.PropertyClass),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllLeaseTenantsQuery.prototype, "filter_propertyClass", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(properties_1.PropertyType),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllLeaseTenantsQuery.prototype, "filter_propertyType", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllLeaseTenantsQuery.prototype, "filter_property", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], GetAllLeaseTenantsQuery.prototype, "filter_properties", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllLeaseTenantsQuery.prototype, "filter_movedInAt_to", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllLeaseTenantsQuery.prototype, "filter_movedInAt_from", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllLeaseTenantsQuery.prototype, "filter_movedOutAt_to", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllLeaseTenantsQuery.prototype, "filter_movedOutAt_from", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllLeaseTenantsQuery.prototype, "filter_lease", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllLeaseTenantsQuery.prototype, "filter_leaseDraft", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllLeaseTenantsQuery.prototype, "filter_unit", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], GetAllLeaseTenantsQuery.prototype, "filter_units", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllLeaseTenantsQuery.prototype, "filter_group", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllLeaseTenantsQuery.prototype, "filter_owner", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(leaseTenantStatus_enum_1.LeaseTenantStatus),
    __metadata("design:type", String)
], GetAllLeaseTenantsQuery.prototype, "filter_status", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllLeaseTenantsQuery.prototype, "filter_portalEnabled", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(common_1.PortalStatus),
    __metadata("design:type", String)
], GetAllLeaseTenantsQuery.prototype, "filter_tenantPortalStatus", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(common_1.FilterPortalStatus),
    __metadata("design:type", String)
], GetAllLeaseTenantsQuery.prototype, "filter_tenantPortal", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(tenants_1.TenantType),
    __metadata("design:type", String)
], GetAllLeaseTenantsQuery.prototype, "filter_type", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllLeaseTenantsQuery.prototype, "filter_text_searchByPropertyOrUnitName", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(leaseSituation_enum_1.LeaseSituation),
    __metadata("design:type", String)
], GetAllLeaseTenantsQuery.prototype, "filter_situation", void 0);
