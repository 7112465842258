import React, { useEffect, useState } from "react";
import { View } from "DLUI/view";
import type { SVGIconComponent } from "assets/icons";
import { FilePdfIcon } from "assets/icons";
import { IconButton } from "DLUI/form";
import { filesApi } from "api/filesApi";
import { useShakeEffect } from "../../../hooks/useShakeEffect";

interface ComponentProps {
  icon?: SVGIconComponent;
  fileName: string;
  width?: number;
  height?: number;
  onExportPdfButtonPress?: () => void;
  onExportPdfComplete?: () => void;
  exportPdfInProgress?: boolean;
}

const ExportPdfButton: React.FC<ComponentProps> = ({
  icon,
  fileName,
  width,
  height,
  onExportPdfButtonPress,
  onExportPdfComplete,
  exportPdfInProgress
}: ComponentProps) => {
  const { ShakeEffectWrapper, runShakeEffect } = useShakeEffect();
  const [exportInProgress, setExportInProgress] = useState<boolean>(exportPdfInProgress || false);

  useEffect(() => {
    setExportInProgress(exportPdfInProgress || false);
  }, [exportPdfInProgress]);

  const didPressExportToPDF = async () => {
    if (onExportPdfButtonPress) {
      onExportPdfButtonPress();
      return;
    }

    if (exportInProgress) {
      runShakeEffect();
      return;
    }

    setExportInProgress(true);
    const documentHtml = document.documentElement.outerHTML;
    const { data } = await filesApi.getPdfFromHtml(documentHtml, fileName);
    if (!data) {
      runShakeEffect();
    } else {
      onExportPdfComplete && onExportPdfComplete();
    }
    setExportInProgress(false);
  };

  return (
    <View width={"auto"}>
      <ShakeEffectWrapper>
        <IconButton
          width={width || 25}
          height={height || 25}
          Icon={icon || FilePdfIcon}
          onClick={didPressExportToPDF}
          loadingInProgress={exportInProgress}
        />
      </ShakeEffectWrapper>
    </View>
  );
};

export default ExportPdfButton;
