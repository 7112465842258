"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyDto = exports.PropertyOwnerDto = void 0;
const class_validator_1 = require("class-validator");
const base_dto_1 = require("../base.dto");
const address_dto_1 = require("../common/address.dto");
const types_1 = require("./types");
const class_transformer_1 = require("class-transformer");
const propertyAmenity_enum_1 = require("../properties/propertyAmenity.enum");
const propertyPetPolicy_dto_1 = require("../properties/propertyPetPolicy.dto");
const propertySettings_dto_1 = require("../settings/propertySettings.dto");
const identifiable_1 = require("../identifiable");
const tenants_1 = require("../tenants");
class PropertyOwnerDto extends identifiable_1.IdentifiableObject {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.PropertyOwnerDto = PropertyOwnerDto;
__decorate([
    (0, class_validator_1.IsDefined)(),
    (0, class_validator_1.Length)(24, 24),
    (0, class_validator_1.IsHexadecimal)(),
    __metadata("design:type", String)
], PropertyOwnerDto.prototype, "owner", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.Min)(0, {
        message: "Value between 0% and 100%"
    }),
    (0, class_validator_1.Max)(100, {
        message: "Value between 0% and 100%"
    }),
    (0, class_validator_1.IsNumber)({ maxDecimalPlaces: 4 }),
    __metadata("design:type", Number)
], PropertyOwnerDto.prototype, "ownershipPercentage", void 0);
class PropertyDto extends base_dto_1.BaseDto {
    constructor(init) {
        super();
        this.active = true;
        this.address = new address_dto_1.AddressDto();
        this.numActiveUnits = 0;
        Object.assign(this, init);
    }
    static isOtherType(type) {
        return Boolean(type === null || type === void 0 ? void 0 : type.endsWith("_OTHER"));
    }
    static getClassFromType(type) {
        const typeSplit = type.split("_");
        const propertyClass = typeSplit[0];
        const c = types_1.PropertyClass[propertyClass];
        return c;
    }
}
exports.PropertyDto = PropertyDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(255),
    __metadata("design:type", String)
], PropertyDto.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(types_1.PropertyType),
    (0, class_validator_1.IsDefined)(),
    __metadata("design:type", String)
], PropertyDto.prototype, "type", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)({
        message: "Please add property type"
    }),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.ValidateIf)((property) => PropertyDto.isOtherType(property.type)),
    __metadata("design:type", String)
], PropertyDto.prototype, "typeDescription", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], PropertyDto.prototype, "class", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], PropertyDto.prototype, "active", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(1000),
    __metadata("design:type", String)
], PropertyDto.prototype, "description", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsObject)(),
    (0, class_transformer_1.Type)(() => address_dto_1.AddressDto),
    __metadata("design:type", address_dto_1.AddressDto)
], PropertyDto.prototype, "address", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => PropertyOwnerDto),
    __metadata("design:type", Array)
], PropertyDto.prototype, "owners", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => propertySettings_dto_1.PropertySettingsDto),
    __metadata("design:type", propertySettings_dto_1.PropertySettingsDto)
], PropertyDto.prototype, "settings", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsEnum)(propertyAmenity_enum_1.PropertyAmenity, { each: true }),
    __metadata("design:type", Array)
], PropertyDto.prototype, "amenities", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsObject)(),
    (0, class_transformer_1.Type)(() => propertyPetPolicy_dto_1.PropertyPetPolicyDto),
    __metadata("design:type", propertyPetPolicy_dto_1.PropertyPetPolicyDto)
], PropertyDto.prototype, "petsPolicy", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => tenants_1.BoardMembershipDto),
    __metadata("design:type", Array)
], PropertyDto.prototype, "boardMembers", void 0);
