import type {
  GenerateManagementFeesBillsRequestDto,
  GenerateManagementFeesBillsResponseDto,
  GetAllVendorBillsQuery,
  GetAllVendorCreditsQuery,
  GetAllVendorsQuery,
  VendorBillDto,
  VendorCreditDto,
  VendorDto
} from "@doorloop/dto";
import { ServerRoutes } from "@doorloop/dto";
import { RestApiBaseWithDictionary } from "./restApiBaseWithDictionary";
import { apiHelper } from "./apiHelper";
import { RestApiBase } from "./restApiBase";
import { usersApi } from "api/usersApi";
import { propertiesApi } from "./propertiesApi";
import { accountsApi } from "./accounts";
import type { ApiResult } from "./apiResult";
import { unitsApi } from "./unitsApi";

export class VendorsApi extends RestApiBaseWithDictionary<VendorDto, GetAllVendorsQuery> {
  constructor() {
    super(ServerRoutes.VENDORS, [propertiesApi, usersApi, unitsApi]);
    this.restRoute = ServerRoutes.VENDORS;
  }

  getDetails = async (vendorId: string) => await apiHelper.axiosGet(ServerRoutes.VENDORS + "/" + vendorId);
}

export const vendorsApi = new VendorsApi();

export class VendorBillsApi extends RestApiBase<VendorBillDto, GetAllVendorBillsQuery> {
  constructor() {
    super(ServerRoutes.VENDOR_BILLS, [vendorsApi, accountsApi, propertiesApi, unitsApi]);
    this.restRoute = ServerRoutes.VENDOR_BILLS;
  }

  generateManagementFeesBills = async (
    params: GenerateManagementFeesBillsRequestDto
  ): Promise<ApiResult<GenerateManagementFeesBillsResponseDto>> =>
    await apiHelper.axiosPost({ url: ServerRoutes.VENDOR_BILLS_MANAGEMENT_FEES, data: params });
}

export const vendorBillsApi = new VendorBillsApi();

export class VendorCreditsApi extends RestApiBase<VendorCreditDto, GetAllVendorCreditsQuery> {
  constructor() {
    super(ServerRoutes.VENDOR_CREDITS, []);
    this.restRoute = ServerRoutes.VENDOR_CREDITS;
  }
}

export const vendorCreditsApi = new VendorCreditsApi();
