"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CashFlowObjectDto = exports.OwnerStatementReportOwnersObjectDto = exports.OwnerStatementReportResponseDto = void 0;
class OwnerStatementReportResponseDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.OwnerStatementReportResponseDto = OwnerStatementReportResponseDto;
class OwnerStatementReportOwnersObjectDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.OwnerStatementReportOwnersObjectDto = OwnerStatementReportOwnersObjectDto;
class CashFlowObjectDto {
    constructor(data) {
        this.data = data;
    }
}
exports.CashFlowObjectDto = CashFlowObjectDto;
