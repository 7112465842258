import type { DialogProps } from "./dialogsProvider.types";
import { DialogRoutes } from "./dialogsRoutes";
import SignatureTemplateFormikWrapper from "screens/esignatures/editSignatureTemplate/signatureTemplateFormikWrapper";

class SignatureTemplatesDialogsProvider {
  matchRoutes(pathname: string): DialogProps | undefined {
    if (pathname.includes(DialogRoutes.EDIT_SIGNATURE_TEMPLATE)) {
      return {
        Content: SignatureTemplateFormikWrapper
      };
    }
  }
}

export const signatureTemplatesDialogsProvider = new SignatureTemplatesDialogsProvider();
