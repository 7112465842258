import { vendorsApi } from "api/vendorsApi";
import { ownersApi } from "api/ownersApi";
import { tenantsApi } from "api/tenantsApi";
import { DeleteIcon, EditBlue } from "assets/icons";
import { getNameFromDictionary, translateTypeField, viewTransactionDetails } from "DLUI/lists/utils";
import { NavigationManager } from "utils/navigation";
import type { PrintChecksReportItemDto } from "@doorloop/dto";
import type { MultiSelectGridColumn } from "DLUI/lists/multiSelect/multiSelectGridList";
import AppStrings from "locale/keys";

const getChecksListMultiselectColumns = (filter: string): Array<MultiSelectGridColumn<PrintChecksReportItemDto>> => [
  {
    width: 150,
    field: "date",
    title: "Date",
    format: "date",
    show: true
  },
  {
    width: 150,
    field: "journalEntryType",
    title: "Type",
    format: "text",
    show: true,
    translate: (value) => translateTypeField(value)
  },
  {
    width: 300,
    field: "payToResourceId",
    title: "Payee",
    format: "text",
    show: true,
    translate: (value, Item) => translatePayeeFromDictionary(value, Item.payToResourceId, Item.payToResourceType)
  },
  {
    width: 150,
    field: "amount",
    title: "Amount",
    format: "currency",
    show: true,
    align: "left"
  },
  {
    width: 150,
    field: "checkNumber",
    title: AppStrings.Leases.LeaseTransaction.Payment.CheckNumber,
    show: true,
    format: "text",
    align: "left"
  },
  {
    width: 250,
    field: "memo",
    title: AppStrings.Common.ListHeader.Memo,
    format: "text",
    show: true
  },

  {
    field: "",
    title: "",
    format: "popover",
    show: true,
    getPopoverColumns: (dataItem) => {
      if (filter === "true")
        return [
          {
            Icon: EditBlue,
            title: AppStrings.Common.Edit,
            onClick: () => {
              dataItem.journalEntryType &&
                viewTransactionDetails(
                  dataItem.journalEntryType,
                  location.pathname,
                  dataItem.journalEntry || "",
                  location.search
                );
            }
          },
          {
            Icon: DeleteIcon,
            title: AppStrings.Common.PrintChecks.RemoveFromQueue,
            textColor: "error",
            iconPathColor: "error",
            onClick: () => {
              dataItem.journalEntry &&
                NavigationManager.printRemoveCheck(location.pathname, dataItem.journalEntry, location.search);
            }
          }
        ];

      return [
        {
          Icon: EditBlue,
          title: AppStrings.Common.Edit,
          onClick: () => {
            dataItem.journalEntryType &&
              viewTransactionDetails(
                dataItem.journalEntryType,
                location.pathname,
                dataItem.journalEntry || "",
                location.search
              );
          }
        }
      ];
    }
  }
];

export default getChecksListMultiselectColumns;

export const translatePayeeFromDictionary = (
  defaultValue: string,
  payToResourceId: string | undefined,
  payToResourceType: string | undefined
) => {
  if (!payToResourceId || !payToResourceType) {
    return defaultValue;
  }
  switch (payToResourceType) {
    case "OWNER":
      return getNameFromDictionary(payToResourceId, ownersApi);
    case "TENANT":
      return getNameFromDictionary(payToResourceId, tenantsApi);
    case "VENDOR":
      return getNameFromDictionary(payToResourceId, vendorsApi);
    default:
      return defaultValue;
  }
};
