import React, { useMemo, useState } from "react";
import type { FilesApi } from "api/filesApi";
import { filesApi } from "api/filesApi";
import {
  DeleteIcon,
  DownloadSimpleIcon,
  EyeViewIcon,
  FileDocIcon,
  FileJpgIcon,
  FilePdfIcon,
  FilePngIcon,
  FileUnknownIcon,
  FileXlsIcon,
  PencilSimpleIcon
} from "assets/icons";
import { Icon } from "DLUI/icon";
import type { PopoverItem } from "DLUI/popover";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { NavigationManager } from "utils/navigation";
import type { FileDisplayDto } from "@doorloop/dto";
import { LinkedResourceType, ObjectPermission } from "@doorloop/dto";
import { Grid, ListItemContainer } from "DLUI/listItems";
import { Tags } from "DLUI/tags";
import { TextTooltip } from "DLUI/popover/textTooltip";
import { ApiByResourceType, LinkedNotesResourceType } from "shared/notes/noteListItem";
import { ProfileImage } from "DLUI/profileImage";
import moment from "moment";
import { DateFormats } from "@doorloop/dto";
import { usersApi } from "api/usersApi";
import { tenantsApi } from "api/tenantsApi";
import { ownersApi } from "api/ownersApi";
import { isFilePreviewSupported } from "screens/files/isFilePreviewSupported";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import { isBrowser } from "react-device-detect";
import { useConfirmationDialog } from "@/hooks/useConfirmationDialog";

interface ComponentProps {
  didPressEditFile?: (itemData: FileDisplayDto) => void;
  itemData: FileDisplayDto;
  hideRelatedToColumn?: boolean;
  disableListItemOptions?: boolean;
  filesApiToUse?: FilesApi;
  stickyHeaderId?: string;
}

export const getFileIcon = (mimeType?: string) => {
  let iconSource = FileUnknownIcon;
  switch (mimeType) {
    case "image/png":
      iconSource = FilePngIcon;
      break;
    case "application/pdf":
      iconSource = FilePdfIcon;
      break;
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      iconSource = FileDocIcon;
      break;
    case "image/jpeg":
      iconSource = FileJpgIcon;
      break;
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      iconSource = FileXlsIcon;
      break;
  }
  return iconSource;
};

const FileListItem: React.FC<ComponentProps> = ({
  itemData,
  didPressEditFile,
  hideRelatedToColumn = false,
  disableListItemOptions = false,
  filesApiToUse = filesApi,
  stickyHeaderId
}: ComponentProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { name, notes, tags, mimeType, linkedResource, createdBy, createdAt, downloadUrl, id: fileId } = itemData;
  const { isMobile } = useResponsiveHelper();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const fileTypeIcon = useMemo(() => getFileIcon(mimeType), []);
  const { showConfirmationDialog } = useConfirmationDialog();

  const didPressPreviewFile = (): void => {
    fileId && NavigationManager.filePreview(fileId);
  };

  const didPressDownloadFile = async () => {
    if (downloadUrl && fileId) {
      setIsDownloading(true);

      await filesApiToUse.downloadFile(fileId);

      setIsDownloading(false);
    }
  };

  const didPressDeleteFile = () => {
    if (fileId) {
      showConfirmationDialog(location.pathname, {
        dialogTitle: AppStrings.Common.DeleteConfirmation,
        dialogSubTitle: AppStrings.Common.Dropzone.DeleteFileConfirmationText,
        loadingText: t(AppStrings.Common.Dropzone.DeleteFileLoadingText),
        successText: t(AppStrings.Common.Dropzone.DeleteFileSuccessText),
        confirmButtonText: AppStrings.Common.Delete,
        dismissButtonText: AppStrings.Common.Dismiss,
        apiMethod: "filesApi",
        confirmOperation: "delete",
        itemId: fileId
      });
    }
  };

  const _didPressEditFile = () => {
    didPressEditFile && didPressEditFile(itemData!);
  };

  const popoverTopSectionItems: PopoverItem[] = useMemo<PopoverItem[]>(() => {
    const items: PopoverItem[] = [];

    if (mimeType && isFilePreviewSupported(mimeType) && isBrowser) {
      items.push({
        Icon: EyeViewIcon,
        title: AppStrings.Common.Preview,
        onClick: didPressPreviewFile
      });
    }

    items.push(
      {
        Icon: DownloadSimpleIcon,
        title: AppStrings.Common.Dropzone.Download,
        onClick: didPressDownloadFile
      },
      {
        Icon: PencilSimpleIcon,
        title: AppStrings.Common.Dropzone.EditFileInfo,
        onClick: _didPressEditFile,
        showSeparator: true,
        clearance: { permission: ObjectPermission.files, field: "edit" }
      },
      {
        Icon: DeleteIcon,
        title: AppStrings.Common.Dropzone.DeleteFile,
        onClick: didPressDeleteFile,
        iconPathColor: "error",
        textColor: "error",
        clearance: { permission: ObjectPermission.files, field: "delete" }
      }
    );

    return items;
  }, [mimeType, isMobile]);

  const { createdByUserName, createdByUserPictureUrl, relatedTo } = useMemo(() => {
    let createdByUserName = " ";
    let createdByUserPictureUrl;
    let relatedTo = " ";

    if (itemData.linkedResource?.resourceType === LinkedResourceType.RentalApplication && itemData?.createdByName) {
      createdByUserName = tenantsApi.getItemFromDictionary(itemData.createdByName)?.name || "";
    } else if (createdBy) {
      const createdByObj =
        usersApi.getItemFromDictionary(createdBy) ||
        tenantsApi.getItemFromDictionary(createdBy) ||
        ownersApi.getItemFromDictionary(createdBy);
      createdByUserName = createdByObj?.name ?? "";
      createdByUserPictureUrl = createdByObj?.pictureUrl;
    }

    if (linkedResource) {
      const { resourceType, resourceId } = linkedResource;
      const relatedApiMethod = ApiByResourceType[resourceType];
      const relatedToObj = relatedApiMethod?.getItemFromDictionary(resourceId);

      if ((resourceType as string) === LinkedNotesResourceType.Unit) {
        const propertyObj = ApiByResourceType[LinkedNotesResourceType.Property]?.getItemFromDictionary(
          relatedToObj?.property
        );

        relatedTo = relatedToObj?.name
          ? `${propertyObj?.name ? `${propertyObj?.name} > ` : ""}${relatedToObj?.name}`
          : relatedTo;
      } else {
        relatedTo = relatedToObj?.name ?? t(relatedTo);
      }
    }

    return { createdByUserName, createdByUserPictureUrl, relatedTo };
  }, []);

  return (
    <ListItemContainer popoverItems={disableListItemOptions ? undefined : popoverTopSectionItems} id={stickyHeaderId}>
      <Grid
        showDivider
        xs={12}
        sm={hideRelatedToColumn ? 5 : 3}
        md={hideRelatedToColumn ? 5 : 3}
        lg={hideRelatedToColumn ? 5 : 3}
        sortColumn={"name"}
        height={70}
        title={AppStrings.Common.Title}
        onClick={_didPressEditFile}
      >
        <View flexDirection={"row"} alignItems={"center"} noWrap width={"100%"} overflow={"hidden"}>
          <View
            minWidth={50}
            minHeight={50}
            maxWidth={50}
            maxHeight={50}
            width={50}
            backgroundColor={"secondary-gray-light"}
            borderRadius={5}
            justifyContent={"center"}
            alignItems={"center"}
            marginRight={10}
            flexDirection={"row"}
          >
            <Icon Source={fileTypeIcon} size={24} pathColor={"black"} />
          </View>
          <View overflow={"hidden"} width={"calc(100% - 60px)"} noWrap>
            <Text bold fontSize={14} overFlow={"ellipsis"} numberOfLines={2}>
              {name}
            </Text>
            <View overflow={"hidden"} noWrap paddingTop={5}>
              <Tags tags={tags} />
            </View>
          </View>
        </View>
      </Grid>
      {!hideRelatedToColumn && (
        <Grid
          showDivider
          xs={12}
          sm={3}
          md={3}
          lg={3}
          onClick={_didPressEditFile}
          title={AppStrings.Tasks.InternalTaskDialog.RelatedTo}
        >
          <Text overFlow={"ellipsis"} numberOfLines={2} fontSize={14}>
            {relatedTo}
          </Text>
        </Grid>
      )}
      <Grid
        showDivider
        xs={12}
        sm={hideRelatedToColumn ? 3 : 2}
        md={hideRelatedToColumn ? 3 : 2}
        lg={hideRelatedToColumn ? 3 : 2}
        onClick={_didPressEditFile}
        sortColumn={"notes"}
        title={AppStrings.Common.Description}
      >
        <TextTooltip value={notes} useDark>
          {notes === `undefined` || !notes ? null : (
            <Text paddingRight={20} fullWidth overFlow={"ellipsis"} maxLines={"2"} fontSize={14}>
              {notes}
            </Text>
          )}
        </TextTooltip>
      </Grid>
      <Grid
        showDivider
        xs={12}
        sm={hideRelatedToColumn ? 2 : 2}
        md={hideRelatedToColumn ? 2 : 2}
        lg={hideRelatedToColumn ? 2 : 2}
        onClick={_didPressEditFile}
        title={AppStrings.Common.CreatedBy}
      >
        <View flexDirection={"row"} alignItems={"center"} noWrap overflow={"hidden"}>
          <ProfileImage size={30} pictureUrl={createdByUserPictureUrl} marginRight={5} />
          <View overflow={"hidden"} width={"calc(100% - 35px)"} noWrap>
            <Text overFlow={"ellipsis"} numberOfLines={2} fontSize={14}>
              {createdByUserName}
            </Text>
          </View>
        </View>
      </Grid>
      <Grid
        xs={12}
        sm={hideRelatedToColumn ? 2 : 2}
        md={hideRelatedToColumn ? 2 : 2}
        lg={hideRelatedToColumn ? 2 : 2}
        onClick={_didPressEditFile}
        title={AppStrings.Common.CreatedAt}
        sortColumn={"createdAt"}
      >
        <View flexDirection={"row"} alignItems={"center"} noWrap overflow={"hidden"}>
          <View overflow={"hidden"} noWrap>
            <Text
              fontSize={12}
              value={AppStrings.Common.CreatedAtDate}
              replaceObject={{
                createdAt: moment(createdAt).format(DateFormats.SHORT_DATE)
              }}
            />
          </View>
        </View>
      </Grid>
    </ListItemContainer>
  );
};

export default FileListItem;
