import DLUI_Dialog from "DLUI/dialogs/dialog";
import { useResponsiveHelper } from "contexts/responsiveContext";
import type { ReactElement } from "react";
import { useCallback } from "react";
import { DialogFrame } from "DLUI/dialogs";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";

interface FeeItemFormMobileDialog {
  isOpen: boolean;
  handleStateChange: (isOpen: boolean) => void;
  hasItems: boolean;
  Form: ReactElement;
}

export const FeeItemFormMobileDialogWrapper = ({
  isOpen,
  handleStateChange,
  hasItems = false,
  Form
}: FeeItemFormMobileDialog) => {
  const { isMobile } = useResponsiveHelper();

  const handleClose = useCallback(() => {
    handleStateChange(false);
  }, [handleStateChange]);

  if (!isMobile || !hasItems || !isOpen) return null;

  return (
    <DLUI_Dialog open={isMobile && isOpen} onClose={handleClose}>
      <DialogFrame
        width={getDialogFrameDimension("width", 1000)}
        height={getDialogFrameDimension("height", 1000)}
        frameType={"contentOnly"}
        onCloseButtonClick={handleClose}
        numViews={1}
        renderView={() => Form}
        activeView={0}
      />
    </DLUI_Dialog>
  );
};
