import { DefaultDebounceDelays, useDebounce } from "@/hooks/useDebounce";
import { useInputState } from "@/hooks/useInputState";
import { useSearchParamsSetter } from "@/hooks/useSearchParamsSetter";
import { useEffect } from "react";
import { SEARCH_QUERY_KEY } from "../filterPanel.constants";
import { useSearchParams } from "@/hooks/useSearchParams";

export function useDebouncedInputQuery(queryKey = SEARCH_QUERY_KEY) {
  const [searchParams] = useSearchParams();
  const [query, setQuery] = useInputState(searchParams.get(queryKey) || "");
  const searchParamsSetter = useSearchParamsSetter();
  const debouncedQuery = useDebounce(query, DefaultDebounceDelays.GLOBAL_TEXT_SEARCH_CHANGED);

  useEffect(() => {
    searchParamsSetter((searchParams) => {
      if (!debouncedQuery) {
        searchParams.delete(queryKey);
        return searchParams;
      }

      searchParams.set(queryKey, debouncedQuery);
      return searchParams;
    }, "replace");
  }, [debouncedQuery, searchParamsSetter, queryKey]);

  return [query, setQuery] as const;
}
