import type { FC } from "react";
import React, { useMemo } from "react";
import type { VendorDto } from "@doorloop/dto";
import { ObjectPermission, PhoneNumberStatus } from "@doorloop/dto";
import { vendorsApi } from "api/vendorsApi";
import AppStrings from "locale/keys";
import VendorsEmptyDataView from "./vendorsEmptyDataView";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import { TrashIconOutline } from "../../../../assets";
import { DataList } from "DLUI/dataList";
import { entityApiStore } from "api/entityApiStore/entityApiStore";
import compact from "lodash/fp/compact";
import { sendWelcomeSmsBulkActionDefinition } from "../../../../utils/bulkOperationsUtils";
import { useCurrentPhoneNumber } from "screens/communicationsCenter/shared/useCurrentPhoneNumber";
import { useFeatureFlag } from "../../../../hooks/useFeatureFlag";
import { DLButtonColorsEnum } from "DLUI/button/dlButton";

interface ComponentProps {
  multiSelection?: boolean;
  didSelectVendor?: (selectedItem: VendorDto, itemIndex: number) => void;
  ListItem: React.ComponentType<any>;
  filterObj: Record<string, any>;
  fullWidth?: boolean;
  scrollableTarget?: string;
  listDirection?: "row" | "column";
  didPressAddNewButton?: () => void;
  stickyHeaderId?: string;
  onLoadingDataStateChange?: (loadingInProgress: boolean) => void;
  listItemMinWidth?: number;
  selectableListItem?: boolean;
  removeDefaultBottomPadding?: boolean;
}

export const VendorsListContainerId = "selectableVendorList";

const VendorsList: React.FC<ComponentProps> = ({
  didSelectVendor,
  ListItem,
  filterObj,
  fullWidth,
  scrollableTarget,
  didPressAddNewButton,
  onLoadingDataStateChange,
  selectableListItem,
  stickyHeaderId,
  removeDefaultBottomPadding
}: ComponentProps) => {
  const { getIsFeatureActiveForDbTenant } = useFeatureFlag();
  const { currentPhoneNumber } = useCurrentPhoneNumber();

  return (
    <RestrictedPermissionAccess clearance={{ permission: ObjectPermission.vendors, field: "viewList" }} showNoAccess>
      <DataList
        infinite
        restApi={vendorsApi}
        queryFilter={filterObj}
        onLoadingDataStateChange={onLoadingDataStateChange}
        scrollElement={scrollableTarget ? document.getElementById(scrollableTarget) : undefined}
        emptyListView={() => <VendorsEmptyDataView didPressAddNewButton={didPressAddNewButton} />}
        paddingEnd={removeDefaultBottomPadding ? 0 : undefined}
        stickyHeaderProps={{
          id: stickyHeaderId
        }}
        bulkOperationResourceIdGetter={(item, index) => item.id ?? `${item.name} - ${index}`}
        bulkOperationsProps={{
          bulkActionDefinitions: selectableListItem
            ? undefined
            : [
                {
                  ...sendWelcomeSmsBulkActionDefinition,
                  actionName: "sendVendorWelcomeSms",
                  confirmMessageOne: AppStrings.BulkActions.SendVendorWelcomeSmsConfirmMessageOne,
                  confirmMessageMultiple: AppStrings.BulkActions.SendVendorWelcomeSmsConfirmMessageMultiple,
                  isHidden:
                    !getIsFeatureActiveForDbTenant("CommunicationsCenter") ||
                    currentPhoneNumber?.status !== PhoneNumberStatus.ACTIVE
                },
                {
                  actionName: "deleteVendors",
                  actionNameTranslated: AppStrings.Common.Delete,
                  actionButtonColor: DLButtonColorsEnum.DANGER,
                  icon: TrashIconOutline,
                  dialogTextColor: "error",
                  confirmHeader: AppStrings.Common.DeleteConfirmation,
                  confirmMessageOne: AppStrings.BulkActions.ConfirmDeleteOneVendor,
                  confirmMessageMultiple: AppStrings.BulkActions.ConfirmDeleteMultipleVendors
                }
              ],
          minHeight: 150
        }}
      >
        {(item, _, resourceId) => (
          <InfiniteListVendorListItem
            item={item}
            resourceId={resourceId}
            listItem={ListItem}
            onVendorSelect={didSelectVendor}
            fullWidth={fullWidth}
          />
        )}
      </DataList>
    </RestrictedPermissionAccess>
  );
};

const InfiniteListVendorListItem: FC<{
  item: VendorDto;
  resourceId?: string;
  onVendorSelect?: (selectedItem: VendorDto, itemIndex: number) => void;
  fullWidth?: boolean;
  listItem: ComponentProps["ListItem"];
}> = ({ item, resourceId, onVendorSelect, listItem: ListItem, fullWidth }) => {
  const { data: categoriesDictionary } = entityApiStore.tags.queries.useGetDictionary({
    enabled: Boolean(item.services)
  });

  const categoriesText = useMemo(() => {
    if (!item?.services || !categoriesDictionary) {
      return "";
    }

    const serviceNames = compact(item.services.map((categoryId) => categoriesDictionary[categoryId]?.name));
    return serviceNames.join(", ");
  }, [item?.services, categoriesDictionary]);

  return (
    <div style={{ width: fullWidth ? "100%" : "auto" }}>
      <ListItem
        vendor={item}
        didSelectVendor={onVendorSelect}
        categoriesText={categoriesText}
        resourceId={resourceId}
        {...item}
      />
    </div>
  );
};

export default VendorsList;
