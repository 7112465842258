import type { FC } from "react";
import React from "react";
import { FastField } from "formik";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { Grid } from "@material-ui/core";
import { TextField } from "DLUI/form";
import { useTranslation } from "react-i18next";
import AppStrings from "locale/keys";

export const GeneralInfo: FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <View noWrap flex={1}>
      <View paddingRight={20} paddingLeft={20}>
        <View marginTop={20} flexDirection={"row"}>
          <Grid item lg={12} md={12}>
            <Text fontSize={16} color={"black"} value={AppStrings.UserRoles.NameDesc} />
          </Grid>
          <View>
            <Grid item lg={12} md={6} container>
              <FastField component={TextField} label={t(AppStrings.UserRoles.RoleName)} name={`name`} marginTop={20} />
            </Grid>
          </View>
          <Grid item lg={12} md={12}>
            <Text marginTop={20} fontSize={16} color={"black"} value={AppStrings.UserRoles.DescriptionDesc} />
          </Grid>
          <Grid item lg={12} md={12} container>
            <FastField
              component={TextField}
              label={t(AppStrings.UserRoles.RoleDescription)}
              name={"description"}
              multiline
              rows="4"
              multiLineSize={"small"}
              marginTop={20}
              maxLength={1000}
            />
          </Grid>
        </View>
      </View>
    </View>
  );
};
