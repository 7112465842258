import { LogoWhiteIcon } from "assets/icons";
import { Icon } from "DLUI/icon";
import Screen from "DLUI/screen";
import { BackgroundImageView, View } from "DLUI/view";
import React from "react";
import "./styles.css";
import { AppLayouts } from "../../../../contexts/appLayoutContext";

interface ComponentProps {
  children: any;
  containerAnimation?: string;
  logoUrl?: string;
  renderBackgroundlayer?: () => React.ReactNode;
  requireAuth?: boolean;
  ignoreUserSubscriptionCheck?: boolean;
}

export const FrameMaxHeight = 640;
export const FrameMaxWidth = 640;
export const FormMaxWidth = 320;

const MobileAuthLayout = ({
  children,
  containerAnimation,
  logoUrl,
  renderBackgroundlayer,
  requireAuth,
  ignoreUserSubscriptionCheck
}: ComponentProps) => {
  const renderLogo = () => {
    if (logoUrl) {
      return (
        <View alignItems={"center"} justifyContent={"center"} height={120}>
          <View maxWidth={FormMaxWidth} backgroundColor={"white"} borderRadius={10} marginTop={20}>
            <BackgroundImageView publicFileName={"url(" + logoUrl + ")"} height={80} />
          </View>
        </View>
      );
    }
    return (
      <View marginTop={20} alignItems={"center"} justifyContent={"center"}>
        <Icon Source={LogoWhiteIcon} marginTop={20} />
      </View>
    );
  };

  return (
    <Screen
      renderBackgroundLayer={renderBackgroundlayer}
      hideLeftNavBar
      screenLayout={AppLayouts.FullWidth}
      requireAuth={requireAuth}
      ignoreUserSubscriptionCheck={ignoreUserSubscriptionCheck}
    >
      <View className={containerAnimation}>
        <View>
          {renderLogo()}
          {children}

          <View height={40} />
        </View>
      </View>
    </Screen>
  );
};

export default MobileAuthLayout;
