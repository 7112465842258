const Calendar = {
  Calendar: "calendar.calendar",
  OccupancyOverview: "calendar.occupancyOverview",
  ActionPanel: {
    SubscribeToCalendar: "calendar.actionPanel.subscribeToCalendar"
  },
  HelpPanel: {
    Description: "calendar.helpPanel.description",
    HowToUseCalendar: "calendar.helpPanel.howToUseCalendar",
    HowToUseOccupancyOverview: "calendar.helpPanel.howToUseOccupancyOverview",
    HowToSubscribeToCalendar: "calendar.helpPanel.howToSubscribeToCalendar"
  },
  Views: {
    MoreItems: "calendar.views.moreItems",
    NoEventsToDisplay: "calendar.views.noEventsToDisplay",
    Today: "calendar.views.today"
  },
  FilterPanel: {
    LeaseFilters: "calendar.filterPanel.leaseFilters",
    TasksAndRequestsFilters: "calendar.filterPanel.tasksAndRequestsFilters",
    WorkOrdersFilters: "calendar.filterPanel.workOrdersFilters",
    GeneralFilters: "calendar.filterPanel.generalFilters",
    MoveIn: "calendar.filterPanel.moveIn",
    MoveOut: "calendar.filterPanel.moveOut",
    EventType: "calendar.filterPanel.eventType"
  },
  EventDetails: {
    Lease: "calendar.eventDetails.lease",
    Subject: "calendar.eventDetails.subject",
    Description: "calendar.eventDetails.description",
    RequestedBy: "calendar.eventDetails.requestedBy",
    Assignees: "calendar.eventDetails.assignees",
    Property: "calendar.eventDetails.property",
    MoveInDate: "calendar.eventDetails.moveInDate",
    MoveOutDate: "calendar.eventDetails.moveOutDate",
    Tenants: "calendar.eventDetails.tenants",
    ViewLease: "calendar.eventDetails.viewLease",
    ViewTask: "calendar.eventDetails.viewTask",
    ViewRequest: "calendar.eventDetails.viewRequest",
    ViewWorkOrders: "calendar.eventDetails.viewWorkOrders",
    DueDate: "calendar.eventDetails.dueDate",
    MoveIn: "calendar.eventDetails.moveIn",
    MoveOut: "calendar.eventDetails.moveOut",
    MovingInTenants: "calendar.eventDetails.movingInTenants",
    MovingOutTenants: "calendar.eventDetails.movingOutTenants",
    Task: "calendar.eventDetails.task",
    OwnerRequest: "calendar.eventDetails.ownerRequest",
    WorkOrder: "calendar.eventDetails.workOrder",
    TenantRequest: "calendar.eventDetails.tenantRequest",
    MonthToMonth: "calendar.eventDetails.monthToMonth",
    LowPriority: "calendar.eventDetails.lowPriority",
    HighPriority: "calendar.eventDetails.highPriority",
    MediumPriority: "calendar.eventDetails.mediumPriority",
    Received: "calendar.eventDetails.received",
    Completed: "calendar.eventDetails.completed",
    InProgress: "calendar.eventDetails.inProgress",
    NotStarted: "calendar.eventDetails.notStarted",
    Archived: "calendar.eventDetails.archived"
  },
  Subscription: {
    CalendarUrl: {
      SubscribeToCalendar: "calendar.subscription.calendarUrl.subscribeToCalendar",
      SubscriptionDescription: "calendar.subscription.calendarUrl.subscriptionDescription",
      CopyCalendarUrl: "calendar.subscription.calendarUrl.copyCalendarUrl",
      ResetLink: "calendar.subscription.calendarUrl.resetLink",
      CopyLinkSuccessful: "calendar.subscription.calendarUrl.copyLinkSuccessful"
    },
    ResetUrl: {
      ResetCalendarLink: "calendar.subscription.resetUrl.resetCalendarLink",
      Description: "calendar.subscription.resetUrl.description",
      Cancel: "calendar.subscription.resetUrl.cancel",
      Reset: "calendar.subscription.resetUrl.reset",
      SuccessfulReset: "calendar.subscription.resetUrl.successfulReset"
    }
  }
};

export default Calendar;
