import type {
  AccessValueEnabledStatus,
  GetAllBaseQueryResponse,
  GetCashPaymentDetailsQuery,
  GetTenantPortalImpersonationTokenRequest,
  GetTenantPortalImpersonationTokenResponseDto,
  LeaseAccountsReceivablePdfReportItem,
  MerchantAccountRemainingLimitsDto,
  PaymentTokenDto,
  TenantPortalCreateSetupIntentRequestDto,
  TenantPortalGetAllPaymentsQuery,
  TenantPortalGetAllPaymentTokensQuery,
  TenantPortalGetAvailablePaymentMethodsQuery,
  TenantPortalGetLeaseAccountsReceivableReportQuery,
  TenantPortalGetMerchantAccountQuery,
  TenantPortalGetSetupIntentRequestDto,
  TenantPortalMerchantAccountDto,
  TenantPortalPaymentDto
} from "@doorloop/dto";
import { ServerRoutes, TenantPortalServerRoutes } from "@doorloop/dto";
import { apiHelper } from "../apiHelper";
import type { ApiResult } from "../apiResult";
import type * as Stripe from "stripe";
import fileDownload from "js-file-download";
import type { CashPaymentLevels } from "api/cashPaymentsApi";
import type { AxiosRequestConfig } from "axios";

export class TenantPortalPaymentsApi {
  restRoute = TenantPortalServerRoutes.PAYMENTS;

  async create(data: TenantPortalPaymentDto): Promise<ApiResult<TenantPortalPaymentDto>> {
    return await apiHelper.axiosPost<TenantPortalPaymentDto>({ url: this.restRoute + "/", data });
  }

  async getAll(
    query: TenantPortalGetAllPaymentsQuery
  ): Promise<ApiResult<GetAllBaseQueryResponse<TenantPortalPaymentDto>>> {
    return await apiHelper.axiosGet<GetAllBaseQueryResponse<TenantPortalPaymentDto>>(this.restRoute + "/?", query);
  }

  async createImpersonationToken(
    data: GetTenantPortalImpersonationTokenRequest
  ): Promise<ApiResult<GetTenantPortalImpersonationTokenResponseDto>> {
    return await apiHelper.axiosPost({
      url: TenantPortalServerRoutes.AUTH_POST_CREATE_IMPERSONATION_TOKEN,
      data
    });
  }

  async getMerchantAccountData(
    query: TenantPortalGetMerchantAccountQuery
  ): Promise<ApiResult<TenantPortalMerchantAccountDto>> {
    return await apiHelper.axiosGet<TenantPortalMerchantAccountDto>(
      TenantPortalServerRoutes.GET_MERCHANT_ACCOUNT + "/?",
      query
    );
  }

  async getLimitsForMerchantAccount(
    query: TenantPortalGetAvailablePaymentMethodsQuery
  ): Promise<ApiResult<MerchantAccountRemainingLimitsDto>> {
    return await apiHelper.axiosGet(TenantPortalServerRoutes.GET_LIMITS_FOR_MERCHANT_ACCOUNT + "/?", query);
  }

  async getPaymentTokens(
    query: TenantPortalGetAllPaymentTokensQuery
  ): Promise<ApiResult<GetAllBaseQueryResponse<PaymentTokenDto>>> {
    return await apiHelper.axiosGet<GetAllBaseQueryResponse<PaymentTokenDto>>(
      TenantPortalServerRoutes.PAYMENT_TOKENS + "/?",
      query,
      { headers: { "Cache-Control": "no-cache" } }
    );
  }

  async getSetupIntent(query: TenantPortalGetSetupIntentRequestDto): Promise<ApiResult<Stripe.Stripe.SetupIntent>> {
    return await apiHelper.axiosGet(TenantPortalServerRoutes.GET_SETUP_INTENT, query);
  }

  async deletePaymentToken(tokenId: string): Promise<ApiResult<GetAllBaseQueryResponse<PaymentTokenDto>>> {
    return await apiHelper.axiosDelete({ url: TenantPortalServerRoutes.PAYMENT_TOKENS + "/" + tokenId });
  }

  async getPaymentHistory(
    query: TenantPortalGetLeaseAccountsReceivableReportQuery
  ): Promise<ApiResult<LeaseAccountsReceivablePdfReportItem[]>> {
    return await apiHelper.axiosGet<LeaseAccountsReceivablePdfReportItem[]>(
      TenantPortalServerRoutes.REPORTS_LEASE_ACCOUNTS_RECEIVABLE + "/?",
      query
    );
  }

  async getPaymentHistoryPdfToken(
    query: TenantPortalGetLeaseAccountsReceivableReportQuery,
    configParams?: AxiosRequestConfig
  ): Promise<ApiResult<{ token: string }>> {
    return await apiHelper.axiosGet<{ token: string }>(
      TenantPortalServerRoutes.REPORTS_LEASE_ACCOUNTS_RECEIVABLE_PDF_TOKEN + "/?",
      query,
      configParams
    );
  }

  async createClientIntentSecret(data: TenantPortalCreateSetupIntentRequestDto) {
    return await apiHelper.axiosPost({
      url: TenantPortalServerRoutes.MERCHANT_ACCOUNTS_POST_CREATE_SETUP_INTENT,
      data
    });
  }

  async delete(id: string): Promise<ApiResult<TenantPortalPaymentDto>> {
    return await apiHelper.axiosDelete({ url: this.restRoute, options: { params: { id } } });
  }

  getCashPaymentDetails = async (query: GetCashPaymentDetailsQuery) =>
    await apiHelper.axiosPost({
      url: ServerRoutes.CASH_PAYMENTS_TENANT_PORTAL_GET_PAYMENT_DETAILS,
      data: query
    });

  getIsCashPaymentsEnabled = async (
    idType: CashPaymentLevels,
    id: string
  ): Promise<ApiResult<AccessValueEnabledStatus>> =>
    await apiHelper.axiosGet(
      ServerRoutes.CASH_PAYMENTS_TENANT_PORTAL_GET_IS_ENABLED.replace(":idType", idType).replace(":id", id)
    );

  downloadCashPaymentPdf = async (leaseId: string, tenantId: string) => {
    const response = await apiHelper.axiosGetFile(
      ServerRoutes.CASH_PAYMENTS_TENANT_PORTAL_GET_PDF.replace(":leaseId", leaseId).replace(":tenantId", tenantId)
    );

    if (!response.status || !response.data) {
      return response;
    }

    fileDownload(response.data, response.data.name);

    return response;
  };
}

export const tenantPortalPaymentsApi = new TenantPortalPaymentsApi();
