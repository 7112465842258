import type { CSSProperties } from "react";
import React from "react";
import type { TextComponentProps } from "DLUI/text/text";
import Text from "DLUI/text/text";

interface ComponentProps extends TextComponentProps {
  lines?: number;
  text: string | Date;
  ellipsisPosition?: "start" | "end";
  lineBreak?: "anywhere" | "auto";
  autoWidth?: boolean;
}

const TextEllipsis: React.FC<ComponentProps> = ({
  text,
  lines,
  ellipsisPosition,
  children,
  lineBreak,
  autoWidth,
  overFlow,
  ...rest
}: ComponentProps) => {
  const baseProps: CSSProperties = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    whiteSpace: "normal",
    WebkitLineClamp: lines || 1,
    WebkitBoxOrient: "vertical",
    width: autoWidth ? "auto" : "100%",
    // width: "100%",
    lineBreak: lineBreak || "anywhere",
    direction: ellipsisPosition && ellipsisPosition === "start" ? "rtl" : "ltr"
  };

  return (
    <div style={baseProps}>
      <Text {...rest} value={text}>
        {text}
      </Text>
    </div>
  );
};

export default TextEllipsis;
