import type { PhoneNumberDto } from "@doorloop/dto";
import { TenantPortalServerRoutes } from "@doorloop/dto";

import type { ApiResult } from "api/apiResult";
import { apiHelper } from "api/apiHelper";

class TenantPortalPhoneNumbersApi {
  async getCurrentPhoneNumber(): Promise<ApiResult<PhoneNumberDto>> {
    return await apiHelper.axiosGet(TenantPortalServerRoutes.PHONE_NUMBERS_COMMUNICATION_PHONE_NUMBER);
  }
}

export const tenantPortalPhoneNumbersApi = new TenantPortalPhoneNumbersApi();
