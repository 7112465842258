import { lazy } from "react";

import Routes from "./routes";
import { LazyRoute } from "./lazyRoute";
import { PageRouter } from "./pageRouter";

const PropertiesScreen = lazy(() => import("screens/properties/propertiesList/propertiesListScreen"));
const PropertyDetailsScreen = lazy(() => import("screens/properties/propertyDetails/propertyDetails"));
const NewPropertyScreen = lazy(() => import("screens/properties/newProperty/newPropertyScreen"));

const PropertiesRouter = () => (
  <PageRouter>
    <LazyRoute path={Routes.PROPERTIES_GLOBAL_NEW} render={PropertiesScreen} />
    <LazyRoute path={Routes.PROPERTY_DETAILS_GLOBAL_NEW} render={PropertyDetailsScreen} />
    <LazyRoute path={Routes.NEW_PROPERTY_GLOBAL_NEW} render={NewPropertyScreen} />

    <LazyRoute exact path={Routes.PROPERTIES} render={PropertiesScreen} />
    <LazyRoute exact path={Routes.PROPERTIES_CONFIRM} render={PropertiesScreen} />
    <LazyRoute exact path={Routes.NEW_PROPERTY} render={NewPropertyScreen} />

    <LazyRoute path={Routes.PROPERTY_DETAILS_EDIT_VIEW_SIGNATURE_REQUEST} exact render={PropertyDetailsScreen} />
    <LazyRoute
      path={[
        Routes.PROPERTY_DETAILS_EDIT_TENANT,
        Routes.PROPERTY_DETAILS_EDIT_BOARD_MEMBER,
        Routes.PROPERTY_DETAILS_REMOVE_BOARD_TERM
      ]}
      exact
      render={PropertyDetailsScreen}
    />
    <LazyRoute path={Routes.PROPERTY_DETAILS_EDIT_RENTAL_APPLICATION} exact render={PropertyDetailsScreen} />

    <LazyRoute
      path={Routes.PROPERTY_DETAILS_UPDATE_RENTAL_APPLICATION_DECISION_STATUS}
      exact
      render={PropertyDetailsScreen}
    />

    <LazyRoute path={Routes.PROPERTY_DETAILS_EDIT_UNIT} exact render={PropertyDetailsScreen} />
    <LazyRoute path={Routes.PROPERTY_DETAILS_EDIT_INTERNAL_TASK} exact render={PropertyDetailsScreen} />

    <LazyRoute path={Routes.PROPERTY_DETAILS_EDIT_OWNER_REQUEST} exact render={PropertyDetailsScreen} />
    <LazyRoute path={Routes.PROPERTY_DETAILS_EDIT_WORK_ORDER} exact render={PropertyDetailsScreen} />
    <LazyRoute path={Routes.PROPERTY_DETAILS_EDIT_TENANT_REQUEST} exact render={PropertyDetailsScreen} />
    <LazyRoute path={Routes.PROPERTY_DETAILS} render={PropertyDetailsScreen} />
  </PageRouter>
);

export default PropertiesRouter;
