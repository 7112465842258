import type { FC } from "react";
import React from "react";
import { View } from "DLUI/view";
import { FastField, useFormikContext } from "formik";
import { FormikImageButtonGroup, FormikSwitchButton } from "DLUI/form";
import AppStrings from "locale/keys";
import type { EmailDto } from "@doorloop/dto";
import { CheckbookPaymentMethodEnum, EmailType } from "@doorloop/dto";
import Text from "DLUI/text";
import { AnimatableView } from "DLUI/animatableView";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import type { PersonDto } from "@doorloop/dto";
import type { PersonOutgoingEPayInfoDto } from "@doorloop/dto";
import type { SupportedPersonTypes } from "screens/outgoingPayments/mappings";
import {
  ePayField,
  getSettingsByMethod,
  labelToPersonType,
  readOnlyPermissionForPerson,
  sendingMethodsItems
} from "screens/outgoingPayments/mappings";
import { UpdateNotification } from "screens/outgoingPayments/updateNotification";
import { SettingsReadOnlyScope } from "DLUI/permissionScope/readOnlyScope";

interface Props {
  personType: SupportedPersonTypes;
}

export const PersonOutgoingPaymentsTab: FC<Props> = ({ personType }: Props) => {
  const formikRef = useFormikContext<PersonDto>();
  const ePayValues: PersonOutgoingEPayInfoDto = formikRef.values.outgoingEPay || {};

  const handleEPaymentsEnabledChanged = (isEnabled: boolean) => {
    formikRef.setFieldValue(ePayField("method"), isEnabled ? CheckbookPaymentMethodEnum.DIGITAL_PAYMENT : undefined);
    // This will prefill the digital payment email value
    const digitalEmailValue = ePayValues.digitalEmail
      ? ePayValues.digitalEmail
      : Object.values(formikRef.values.emails as EmailDto[]).find((arr) => arr.type === EmailType.PRIMARY)?.address;
    formikRef.setFieldValue(ePayField("digitalEmail"), digitalEmailValue);
  };

  const enableForType = labelToPersonType[personType];
  const { InfoBox, FormSection } = getSettingsByMethod(ePayValues.method, personType);

  return (
    <SettingsReadOnlyScope permission={readOnlyPermissionForPerson[personType]}>
      <View flex={1}>
        <View marginBottom={20}>
          <FastField
            component={FormikSwitchButton}
            name={ePayField("enabled")}
            label={enableForType}
            onChange={handleEPaymentsEnabledChanged}
          />
        </View>
        <AnimatableView shouldShow={Boolean(ePayValues.enabled)}>
          <HorizontalSeparationLine marginBottom={20} />
          <Text value={AppStrings.Common.OutgoingPayments.PaymentSendingMethod} fontSize={18} bold />
          <View marginBottom={20}>
            <FastField
              buttonItemsArray={sendingMethodsItems}
              name={ePayField("method")}
              component={FormikImageButtonGroup}
              uniqueId={"sendingMethods"}
              size={135}
            />
          </View>
          <HorizontalSeparationLine marginBottom={20} />
          <InfoBox />
          <FormSection />
          <UpdateNotification ePayValues={ePayValues} />
        </AnimatableView>
      </View>
    </SettingsReadOnlyScope>
  );
};
