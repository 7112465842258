import React from "react";
import EmptyDataView from "DLUI/emptyDataView";
import AppStrings from "locale/keys";

interface ComponentProps {
  emptyViewInstructionsText?: string;
}

const TenantsEmptyDataView: React.FC<ComponentProps> = ({ emptyViewInstructionsText }: ComponentProps) => (
  <EmptyDataView
    instructionsText={emptyViewInstructionsText || AppStrings.Tenants.Screen.EmptydataViewInstructionsText}
  />
);

export default TenantsEmptyDataView;
