"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentifiableObject = exports.uniqueIndexSymbol = void 0;
exports.getUniqueIndex = getUniqueIndex;
exports.Identifiable = Identifiable;
const uuid_1 = require("uuid");
exports.uniqueIndexSymbol = Symbol();
function getUniqueIndex(o) {
    return o[exports.uniqueIndexSymbol];
}
function Identifiable(Base) {
    return class Identifiable extends Base {
        constructor(...args) {
            super(...args);
            this[exports.uniqueIndexSymbol] = (0, uuid_1.v4)();
        }
    };
}
class IdentifiableObject {
    constructor(init) {
        Object.assign(this, init);
        this[exports.uniqueIndexSymbol] = (0, uuid_1.v4)();
    }
}
exports.IdentifiableObject = IdentifiableObject;
