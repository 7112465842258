import { TextField } from "DLUI/form";
import { DataCy } from "@doorloop/dto";
import StateAutoComplete from "DLUI/form/autoComplete/addressAutoComplete/stateAutoComplete";
import {
  caProvinceOptions,
  usStateOptions
} from "DLUI/form/autoComplete/addressAutoComplete/addressAutoCompleteFormWrapper";
import React from "react";
import { useSelectedCountry } from "DLUI/form/autoComplete/addressAutoComplete/useSelectedCountry";
import { Field, getIn, useFormikContext } from "formik";
import { useTypedTranslation } from "locale";

interface StateAddressProps {
  handleChangeSearchAddressEngine?: (engineType: "google" | "smarty") => void;
  formikName: string;
  disabled?: boolean;
}

export const StateAddress = ({ formikName, disabled, handleChangeSearchAddressEngine }: StateAddressProps) => {
  const formik = useFormikContext();
  const { isNonNorthAmerica, isUs } = useSelectedCountry(getIn(formik.values, formikName));
  const { t } = useTypedTranslation();

  const sharedProps = {
    label: t("properties.address.labels.state"),
    disabled,
    name: `${formikName}.state`
  };

  if (isNonNorthAmerica) {
    return <Field component={TextField} size={"100%"} data-cy={DataCy.addressInformation.state} {...sharedProps} />;
  }

  return (
    <Field
      component={StateAutoComplete}
      handleChangeSearchAddressEngine={handleChangeSearchAddressEngine}
      uniqueId={"AutoCompleteStateAddress"}
      values={isUs ? usStateOptions : caProvinceOptions}
      isAddressDefined={Boolean(formik.values?.[formikName])}
      size={"100%"}
      data-cy={DataCy.addressInformation.state}
      {...sharedProps}
    />
  );
};
