import type { FC } from "react";
import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";
import Snackbar from "@material-ui/core/Snackbar";
import Fade from "@material-ui/core/Fade";
import { Portal } from "@material-ui/core";

import { NavigationActionType } from "store/toast/enums";
import type { IRootState } from "store/index";
import type { NavigationActionPropsDetails } from "store/toast/actions";
import { handleClearToast } from "store/toast/actions";

import AppStrings from "../../../locale/keys";
import { NavigationManager } from "../../../utils/navigation";

import type { MappedToastValues } from "./utils";
import { toastMapToValues } from "./utils";
import type { ToastAction, ToastValues } from "DLUI/toast/toast";
import { Toast } from "DLUI/toast/toast";

import { useResponsiveHelper } from "../../../contexts/responsiveContext";

interface ToastRendererValues extends ToastValues {
  keepMounted: boolean;
}

const ToastRenderer: FC = ({ children }) => {
  const { t } = useTranslation();

  const { isMobile } = useResponsiveHelper();
  const dispatch = useDispatch();

  const state = useSelector((state: IRootState) => state.toast);

  const refIsHovered = useRef(false);

  const values = useMemo(() => {
    if (state) {
      const defaultValues: MappedToastValues | undefined =
        state.endpoint && state.method ? toastMapToValues[state.endpoint]?.[state.method] : undefined;

      const severity = state.severity || defaultValues?.severity;
      let translationKey = state.translationKey || defaultValues?.translationKey || "";

      if (!translationKey && state.endpoint && state.method) {
        translationKey = AppStrings.Toasts.api[state.endpoint]?.[state.method];
      }

      let navigationAction:
        | undefined
        | (MappedToastValues["navigationAction"] & { props?: Partial<NavigationActionPropsDetails> });

      if (defaultValues?.navigationAction) {
        navigationAction = {
          isHidden: false,
          translationKey: AppStrings.Common.View,
          ...defaultValues.navigationAction,
          ...state.action,
          props: {
            ...defaultValues.navigationAction.props,
            ...state.action?.props
          }
        };
      } else if (state.action?.name && state.action?.type) {
        navigationAction = {
          isHidden: false,
          translationKey: AppStrings.Common.View,
          type: state.action.type,
          name: state.action.name,
          props: state.action?.props
        };
      }

      const onClick = navigationAction
        ? () => {
            switch (navigationAction!.type) {
              case NavigationActionType.EMPTY: {
                // @ts-ignore
                NavigationManager[navigationAction.name]?.();

                break;
              }
              case NavigationActionType.DETAILS: {
                if (navigationAction!.props?.id) {
                  // @ts-ignore
                  NavigationManager[navigationAction.name]?.(navigationAction.props.id);
                }

                break;
              }
              case NavigationActionType.TAB: {
                if (navigationAction!.props?.id) {
                  // @ts-ignore
                  NavigationManager[navigationAction.name]?.(navigationAction.props.id, navigationAction.props.tab);
                }

                break;
              }
              case NavigationActionType.REPORT: {
                // @ts-ignore
                NavigationManager[navigationAction.name]?.(navigationAction.props?.params);

                break;
              }
            }
          }
        : undefined;

      const action: ToastAction | undefined = navigationAction
        ? {
            isHidden: navigationAction.isHidden,
            value: navigationAction.translationKey ? t(navigationAction.translationKey) : undefined,
            onClick
          }
        : undefined;

      const values: ToastRendererValues = {
        keepMounted: Boolean(state.keepMounted),
        severity,
        value: translationKey ? t(translationKey) : undefined,
        action
      };

      return values;
    }
    return null;
  }, [state]);

  const onMouseEnter = () => {
    refIsHovered.current = true;
  };

  const onMouseLeave = () => {
    refIsHovered.current = false;
  };

  const onClearData = () => {
    onMouseLeave();
    dispatch(handleClearToast());
  };

  const handleClose = () => {
    if (refIsHovered.current) {
      const interval = setInterval(() => {
        if (!refIsHovered.current) {
          onClearData();
          clearInterval(interval);
        }
      }, 2000);
    } else {
      onClearData();
    }
  };

  useEffect(() => {
    if (values) {
      if (!values.value) {
        onClearData();
      }
    } else {
      onMouseLeave();
    }
  }, [values]);

  return (
    <>
      {children}

      <Portal>
        <Snackbar
          key={Fade.name}
          open={Boolean(values?.value)}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          TransitionComponent={Fade}
          autoHideDuration={values?.keepMounted ? null : 5000}
          onClose={values?.keepMounted ? undefined : handleClose}
          style={{ zIndex: 10000 }}
        >
          <AnimatePresence>
            {values?.value ? (
              <motion.div
                key={"toastRenderer"}
                initial={{ opacity: 0.3, y: 100 }}
                animate={{ opacity: 1, y: -(state?.verticalOffset || 0) }}
                exit={{ opacity: 0.3, y: 100 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
                style={isMobile ? { width: "calc(100vw - 20px)" } : undefined}
              >
                <Toast value={values.value} severity={values.severity} action={values.action} onClose={onClearData} />
              </motion.div>
            ) : (
              <div />
            )}
          </AnimatePresence>
        </Snackbar>
      </Portal>
    </>
  );
};

export { ToastRenderer };
