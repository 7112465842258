import type { FieldArrayRenderProps } from "formik";
import { getIn, useFormikContext } from "formik";
import type { DeepKeys, ManagementFeesCompanySettingsDto, ManagementFeesPropertySettingsDto } from "@doorloop/dto";
import { DataCy, ManagementFeeRuleDto } from "@doorloop/dto";
import { FeeItem } from "screens/settings/common/feeItem";
import { AccountsDescription } from "screens/settings/managementFees/accountsDescription";
import type { Dispatch, SetStateAction } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { ManagementFeeItemForm } from "screens/settings/managementFees/formElements/managementFeeItemForm";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { SettingsSection } from "screens/settings/common/settingsSection";
import { useManagementFeesStyles } from "screens/settings/managementFees/useStyles";
import clsx from "clsx";
import Text from "DLUI/text";
import { useTypedTranslation } from "locale";
import { entityApiStore } from "api/entityApiStore/entityApiStore";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { ManagementFeeItemFormMobileDialog } from "screens/settings/managementFees/formElements/managementFeeItemFormMobileDialog";
import { useGeneralStyles } from "styles/generalStyles";
import { get } from "lodash";
import { FeeItemPopover } from "screens/settings/common/feeItemPopover";
import { useEffectOnce } from "hooks/useEffectOnce";

interface ManagementFeeItemListProps {
  feeRulesPath: DeepKeys<ManagementFeesCompanySettingsDto | ManagementFeesPropertySettingsDto>;
  arrayHelpers: FieldArrayRenderProps;
  index: number;
  isNewRuleAdded: boolean;
  setNewAddedChargeKey: Dispatch<SetStateAction<string | undefined>>;
}

export const ManagementFeeItemList = ({
  feeRulesPath,
  arrayHelpers,
  index,
  isNewRuleAdded,
  setNewAddedChargeKey
}: ManagementFeeItemListProps) => {
  const formikRef = useFormikContext();
  const { errors, submitCount } = formikRef;
  const rulePath = `${feeRulesPath}[${index}]`;
  const { data: accounts } = entityApiStore.accounts.queries.useGetDictionary();
  const rule: ManagementFeeRuleDto = getIn(formikRef.values, rulePath);
  const classes = useManagementFeesStyles();
  const generalClasses = useGeneralStyles();
  const { isDesktop } = useResponsiveHelper();
  const { t } = useTypedTranslation();
  const [isAccordionFormOpen, setIsAccordionFormOpen] = useState<boolean>(false);
  const [isMobileFormOpen, setIsMobileFormOpen] = useState<boolean>(false);
  const isNewlyAddedAndOpenedRef = useRef<boolean>(false);

  const toggleAccordion = useCallback(() => {
    setIsAccordionFormOpen((prev) => !prev);
  }, []);

  useEffectOnce(() => {
    if (isNewRuleAdded && !isNewlyAddedAndOpenedRef.current) {
      isNewlyAddedAndOpenedRef.current = true;
      setIsAccordionFormOpen(true);
    }
  });

  const isRuleValid = useMemo(() => {
    const errorsObject = get(errors, rulePath);
    return errorsObject ? Object.keys(errorsObject).length === 0 : true;
  }, [errors, rulePath]);

  useEffect(() => {
    if (submitCount > 0 && !isRuleValid) {
      setIsAccordionFormOpen(true);
    }
  }, [isRuleValid, submitCount]);

  const handleDuplicate = useCallback(() => {
    const uniqueKey = uuid();
    setNewAddedChargeKey(uniqueKey);
    const newRule = new ManagementFeeRuleDto({ ...rule, uniqueKey, _id: undefined });
    arrayHelpers.insert(index + 1, newRule);
  }, [arrayHelpers, index, rule]);

  const handleDelete = useCallback(() => {
    arrayHelpers.remove(index);
  }, [arrayHelpers, index]);

  const handleEdit = useCallback(() => {
    setIsMobileFormOpen(true);
  }, []);

  if (!accounts) return null;

  return (
    <>
      <ManagementFeeItemFormMobileDialog
        rulesPath={feeRulesPath}
        arrayHelpers={arrayHelpers}
        index={index}
        isOpen={isMobileFormOpen}
        handleStateChange={setIsMobileFormOpen}
      />
      {isDesktop ? (
        <FeeItem
          dataCy={DataCy.globalSettings.managementFees.rules.rule.container}
          title={<AccountsDescription rule={rule} index={index} />}
          end={<FeeItemPopover handleDelete={handleDelete} handleDuplicate={handleDuplicate} handleEdit={handleEdit} />}
          isOpen={isAccordionFormOpen}
          onToggle={toggleAccordion}
        >
          <ManagementFeeItemForm rulePath={rulePath} />
        </FeeItem>
      ) : (
        <SettingsSection
          dataCy={DataCy.globalSettings.managementFees.rules.rule.container}
          backgroundColor={"newPalette.secondary.states.hover"}
          title={<AccountsDescription rule={rule} index={index} />}
          end={<FeeItemPopover handleDelete={handleDelete} handleDuplicate={handleDuplicate} handleEdit={handleEdit} />}
        >
          <div className={classes.columnContainer}>
            {rule.baseAccount && (
              <>
                <div className={clsx(generalClasses.flexColumn, generalClasses.mediumGap)}>
                  <Text
                    value={t("settings.generalSettings.managementFees.baseCategory")}
                    fontSize={14}
                    fontWeight={700}
                    color="secondary-gray"
                  />
                  <Text value={accounts[rule.baseAccount].name} fontSize={14} fontWeight={700} />
                </div>
                <HorizontalSeparationLine />
              </>
            )}
            {rule.expenseAccount && (
              <>
                <div className={clsx(generalClasses.flexColumn, generalClasses.mediumGap)}>
                  <Text
                    value={t("settings.generalSettings.managementFees.expenseCategory")}
                    fontSize={14}
                    fontWeight={700}
                    color="secondary-gray"
                  />
                  <Text value={accounts[rule.expenseAccount].name} fontSize={14} fontWeight={700} />
                </div>
              </>
            )}
          </div>
        </SettingsSection>
      )}
    </>
  );
};
