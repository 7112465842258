import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { useResponsiveHelper } from "contexts/responsiveContext";
import AppStrings from "locale/keys";

interface Props {}

const SendLinkMessage: React.FC<Props> = ({}: Props) => {
  const { screenContainerPadding } = useResponsiveHelper();
  return (
    <View marginTop={20} borderRadius={10} backgroundColor={"yellow-light"}>
      <View paddingLeft={screenContainerPadding} marginRight={screenContainerPadding}>
        <Text bold value={AppStrings.Accounts.PleaseNote} marginTop={20} />
        <Text value={AppStrings.Accounts.SendLinkMessage} marginTop={10} lineHeight={"24px"} />
        <View height={20} />
      </View>
    </View>
  );
};

export default SendLinkMessage;
