import type { FC } from "react";
import React, { useCallback, useMemo, useState } from "react";
import { Dialog, DialogFrame } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import AppStrings from "locale/keys";
import { buildRenderView } from "DLUI/dialogs/components/dialogFrame";
import { translatePayeeFromDictionary } from "screens/printChecks/gridColums";
import type { CheckbookPaymentMethodEnum } from "@doorloop/dto";
import { useEffectAsync } from "hooks/useEffectAsync";
import { payeeTypeToApiMethod } from "DLUI/lists/utils";
import type { PersonOutgoingEPayInfoDto } from "@doorloop/dto";
import { SimpleLoading } from "DLUI/loading/simpleLoading";
import { SendEPayConfirmation } from "screens/outgoingPayments/actions/sendEPayConfirmation";
import { outgoingPaymentsApi } from "api/outgoingPaymentsApi";
import { EPaySentMessage } from "screens/outgoingPayments/actions/ePaySentMessage";
import { SendOutgoingPaymentActionPanel } from "screens/outgoingPayments/actions/sendOutgoingPaymentActionPanel";
import type { PrintChecksReportItemDto } from "@doorloop/dto";
import { accountsApi } from "api/accounts";

interface Props {
  paymentDto?: PrintChecksReportItemDto;
  onClose: () => void;
}

export enum DialogViews {
  LOADING,
  BEFORE_SEND,
  AFTER_SEND
}

const heightByViewIndex: number[] = [300, 720, 440];

export const SendOutgoingPayment: FC<Props> = ({ paymentDto, onClose }: Props) => {
  const accountName = paymentDto?.payFromAccount
    ? accountsApi.getItemFromDictionary(paymentDto.payFromAccount)?.name || ""
    : "";
  const [errorText, setErrorText] = useState<string>("");
  const [currentView, setCurrentView] = useState<DialogViews>(DialogViews.LOADING);
  const [personEPayInfo, setPersonEPayInfo] = useState<PersonOutgoingEPayInfoDto | null>(null);

  const fetchPersonDetails = useCallback(async () => {
    if (paymentDto?.payToResourceType) {
      const api = payeeTypeToApiMethod[paymentDto.payToResourceType];
      const { data, status } = await api.getDetails(paymentDto.payToResourceId);
      if (status && data.outgoingEPay) {
        setPersonEPayInfo(data.outgoingEPay);
        setCurrentView(DialogViews.BEFORE_SEND);
      }
    }
  }, [paymentDto?.payToResourceType, paymentDto?.payToResourceId]);

  useEffectAsync(fetchPersonDetails, []);

  const method = useMemo<CheckbookPaymentMethodEnum | undefined>(() => {
    if (personEPayInfo) {
      return personEPayInfo.method;
    }
    return paymentDto?.ePayInformation?.method;
  }, [personEPayInfo]);

  const payeeName = translatePayeeFromDictionary("---", paymentDto?.payToResourceId, paymentDto?.payToResourceType);

  const sendHandler = useCallback(async () => {
    if (paymentDto?.journalEntry) {
      setCurrentView(DialogViews.LOADING);
      const { status, message } = await outgoingPaymentsApi.send(paymentDto.journalEntry);
      if (!status) {
        setErrorText(message);
      }
      setCurrentView(DialogViews.AFTER_SEND);
    }
  }, [paymentDto]);

  const { renderView } = buildRenderView([
    () => <SimpleLoading errorText={""} retryHandler={fetchPersonDetails} dismissHandler={onClose} />,
    () =>
      method && personEPayInfo ? (
        <SendEPayConfirmation
          accountName={accountName}
          method={method}
          payeeName={payeeName}
          personEPayInfo={personEPayInfo}
          amount={`${paymentDto?.amount || ""}`}
        />
      ) : null,
    () => <EPaySentMessage errorText={errorText} />
  ]);

  return (
    <Dialog
      onClose={onClose}
      Content={() => (
        <DialogFrame
          width={410}
          height={heightByViewIndex[currentView]}
          frameType={currentView === DialogViews.LOADING ? "contentOnly" : "sectionTitleFrame"}
          activeView={currentView}
          numViews={3}
          renderView={renderView}
          onCloseButtonClick={onClose}
          title={AppStrings.Common.Enums.JournalEntryType.LeasePayment}
          RenderActionPanelButtons={() => (
            <SendOutgoingPaymentActionPanel
              currentView={currentView}
              sendHandler={sendHandler}
              onClose={onClose}
              errorText={errorText}
            />
          )}
        />
      )}
      dialogState={DialogState.Show}
    />
  );
};
