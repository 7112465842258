import type { Method as AxiosMethod } from "axios";

import { JournalEntryType, ServerRoutes } from "@doorloop/dto";

import type { ToastSeverity } from "store/toast/types";
import { NavigationActionType } from "store/toast/enums";
import type { NavigationActionName } from "store/toast/actions";

export interface MappedToastValues {
  severity?: ToastSeverity;
  translationKey?: string;
  navigationAction?: {
    isHidden?: boolean;
    translationKey?: string;
    type: NavigationActionType;
    name: NavigationActionName;
    props?: {
      tab?: string;
      params?: Record<string, string>;
    };
  };
}

type ToastMapToValues = {
  [Route in ServerRoutes]?: {
    [Method in AxiosMethod]?: MappedToastValues;
  };
};

const toastMapToValues: ToastMapToValues = {
  [ServerRoutes.GENERAL_ENTRIES]: {
    POST: {
      navigationAction: {
        type: NavigationActionType.REPORT,
        name: "runTransactionsListReport",
        props: {
          params: {
            filter_type: JournalEntryType.GENERAL_ENTRY,
            period: "last-7-days"
          }
        }
      }
    }
  },
  [ServerRoutes.VENDOR_CREDITS]: {
    POST: {
      navigationAction: {
        type: NavigationActionType.TAB,
        name: "viewVendorDetails",
        props: {
          tab: "transactions"
        }
      }
    }
  },
  [ServerRoutes.LEASE_CREDITS]: {
    POST: {
      navigationAction: {
        type: NavigationActionType.DETAILS,
        name: "viewLeaseTransactions"
      }
    }
  },
  [ServerRoutes.TASKS]: {
    POST: {
      navigationAction: {
        type: NavigationActionType.DETAILS,
        name: "viewTaskDetails"
      }
    }
  },
  [ServerRoutes.ANNOUNCEMENTS]: {
    POST: {
      navigationAction: {
        type: NavigationActionType.DETAILS,
        name: "viewAnnouncementDetails"
      }
    }
  },
  [ServerRoutes.TENANTS]: {
    POST: {
      navigationAction: {
        type: NavigationActionType.DETAILS,
        name: "viewProspectDetails"
      }
    }
  },
  [ServerRoutes.OWNERS]: {
    POST: {
      navigationAction: {
        type: NavigationActionType.DETAILS,
        name: "viewOwnerDetails"
      }
    }
  },
  [ServerRoutes.VENDORS]: {
    POST: {
      navigationAction: {
        type: NavigationActionType.DETAILS,
        name: "viewVendorDetails"
      }
    }
  },
  [ServerRoutes.USERS]: {
    POST: {
      navigationAction: {
        type: NavigationActionType.EMPTY,
        name: "addNewUserRole"
      }
    }
  },
  [ServerRoutes.UNITS]: {
    POST: {
      navigationAction: {
        type: NavigationActionType.DETAILS,
        name: "viewUnitDetails"
      }
    }
  },
  [ServerRoutes.LEASE_CHARGES]: {
    POST: {
      navigationAction: {
        type: NavigationActionType.DETAILS,
        name: "viewLeaseTransactions"
      }
    }
  },
  [ServerRoutes.LEASE_PAYMENTS]: {
    POST: {
      navigationAction: {
        type: NavigationActionType.DETAILS,
        name: "viewLeaseTransactions"
      }
    }
  },
  [ServerRoutes.LEASE_REFUNDS]: {
    POST: {
      navigationAction: {
        type: NavigationActionType.DETAILS,
        name: "viewLeaseTransactions"
      }
    }
  },
  [ServerRoutes.VENDOR_BILLS]: {
    POST: {
      navigationAction: {
        type: NavigationActionType.TAB,
        name: "viewVendorDetails",
        props: {
          tab: "transactions"
        }
      }
    }
  },
  [ServerRoutes.OWNER_CONTRIBUTIONS]: {
    POST: {
      navigationAction: {
        type: NavigationActionType.TAB,
        name: "viewOwnerDetails",
        props: {
          tab: "transactions"
        }
      }
    }
  },
  [ServerRoutes.TRANSFERS]: {
    POST: {
      navigationAction: {
        type: NavigationActionType.REPORT,
        name: "runTransactionsListReport",
        props: {
          params: {
            filter_type: JournalEntryType.TRANSFER,
            period: "last-7-days"
          }
        }
      }
    }
  },
  [ServerRoutes.DEPOSITS]: {
    POST: {
      navigationAction: {
        type: NavigationActionType.REPORT,
        name: "runTransactionsListReport",
        props: {
          params: {
            filter_type: JournalEntryType.DEPOSIT,
            period: "last-7-days"
          }
        }
      }
    }
  },
  [ServerRoutes.EXPENSES]: {
    POST: {
      navigationAction: {
        type: NavigationActionType.REPORT,
        name: "runTransactionsListReport",
        props: {
          params: {
            filter_type: JournalEntryType.EXPENSE,
            period: "last-7-days"
          }
        }
      }
    }
  }
};

export { toastMapToValues };
