import React from "react";
import { View } from "DLUI/view";
import { Grid } from "@material-ui/core";
import TextEllipsis from "DLUI/text/textEllipsis";
import { SeparationLine } from "DLUI/separatorView";
import { Icon } from "DLUI/icon";
import { CalendarIconBlue } from "../../../../assets";
import { DefaultListItemIconSize } from "screens/leases/leases/leasesList/leaseListItem";
import { DateLabel } from "DLUI/date/dateLabel";
import { DateFormats } from "@doorloop/dto";

interface ComponentProps {
  title?: string;
  createdAt?: Date;
}

const DialogHeader: React.FC<ComponentProps> = ({ title, createdAt }: ComponentProps) => (
  <View height={75} flexDirection={"row"} alignItems={"center"}>
    <Grid item container xs={6} sm={9}>
      <TextEllipsis fontSize={18} bold lines={2} text={title || ""} />
    </Grid>
    <Grid item container xs={6} sm={3}>
      <View justifyContent={"flex-end"} alignItems={"center"} flexDirection={"row"} height={"100%"}>
        <SeparationLine marginRight={10} width={1} height={30} />
        <Icon
          Source={CalendarIconBlue}
          pathColor={"light-gray"}
          width={DefaultListItemIconSize}
          height={DefaultListItemIconSize}
          marginRight={10}
        />
        <DateLabel
          textProps={{ color: "secondary-gray" }}
          date={createdAt}
          format={DateFormats.THREE_LETTER_MONTH_DATE_AND_TIME}
        />
      </View>
    </Grid>
  </View>
);

export default DialogHeader;
