"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OwnerInfo = void 0;
const common_1 = require("../../common");
const class_validator_1 = require("class-validator");
const address_dto_1 = require("./address.dto");
const class_transformer_1 = require("class-transformer");
class OwnerInfo {
}
exports.OwnerInfo = OwnerInfo;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.MaxLength)(100),
    (0, class_validator_1.Matches)(new RegExp("^[\\w\\-\\s.-]+$")),
    __metadata("design:type", String)
], OwnerInfo.prototype, "first", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(100),
    (0, class_validator_1.Matches)(new RegExp("^[\\w\\-\\s.-]+$")),
    __metadata("design:type", String)
], OwnerInfo.prototype, "middle", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.Matches)(new RegExp("^[\\w\\-\\s.-]+$")),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], OwnerInfo.prototype, "last", void 0);
__decorate([
    (0, common_1.IsDateStringWithoutTime)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], OwnerInfo.prototype, "dateOfBirth", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], OwnerInfo.prototype, "title", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], OwnerInfo.prototype, "owner", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.owner),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsNumber)({ maxDecimalPlaces: 0 }, { message: "Ownership percentage must be an integer" }),
    (0, class_validator_1.Min)(1, {
        message: "Ownership percentage must be at least 1%",
        context: { errorDisplayPriority: 1 }
    }),
    __metadata("design:type", Number)
], OwnerInfo.prototype, "ownership", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => !x.ssn_encrypted),
    (0, class_validator_1.IsNotEmpty)(),
    (0, common_1.IsSSN)({
        message: "Invalid SSN. Format: ###-##-####",
        context: { errorDisplayPriority: 1 }
    }),
    __metadata("design:type", String)
], OwnerInfo.prototype, "ssn", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], OwnerInfo.prototype, "ssn_encrypted", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => address_dto_1.PayrixAddressDto),
    __metadata("design:type", address_dto_1.PayrixAddressDto)
], OwnerInfo.prototype, "address", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsPhoneNumber)("US"),
    (0, class_validator_1.Length)(5, 15),
    __metadata("design:type", String)
], OwnerInfo.prototype, "phone", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEmail)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], OwnerInfo.prototype, "email", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], OwnerInfo.prototype, "primary", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], OwnerInfo.prototype, "significantReponsibility", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], OwnerInfo.prototype, "potentiallyExposedPerson", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => !x.dl_encrypted),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(15),
    (0, class_validator_1.Matches)(new RegExp("^[a-zA-Z0-9]*$")),
    __metadata("design:type", String)
], OwnerInfo.prototype, "dl", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], OwnerInfo.prototype, "dl_encrypted", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(common_1.UsStates),
    __metadata("design:type", String)
], OwnerInfo.prototype, "dlState", void 0);
