import clsx from "clsx";
import { ListItemContainerMaxWidth } from "DLUI/infiniteList/utils";
import type { View } from "DLUI/view";
import type { ComponentProps, FC } from "react";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import makeStyles from "./styles";
import type { PopoverItem } from "DLUI/popover";
import ListItemOptions from "DLUI/listItems/listItemOptions";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { usePermission } from "screens/settings/userRoles/usePermission";
import { IconButton } from "DLUI/form";
import type { SVGIconComponent } from "assets/icons";
import { useListBulkActionsContext } from "DLUI/infiniteList/listBulkActionsContext";
import type { SubscribedActions } from "hooks/useListBulkActionsManager";
import { DeleteIcon } from "../../../assets";
import { SeparationLine } from "DLUI/separatorView";
import _ from "lodash";
import { useShakeEffect } from "hooks/useShakeEffect";
import { DataCy } from "@doorloop/dto";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import type { PopoverHoverProps } from "DLUI/popover/popoverHover";
import { PopoverHover } from "DLUI/popover/popoverHover";
import { useAnalyticsService } from "../../../hooks/useAnalyticsService";
import { Checkbox } from "DLUI/checkbox";

interface ListItemContainerProps extends Pick<ComponentProps<typeof View>, "domRef"> {
  children: React.ReactNode;
  onClick?: () => void;
  width?: string;
  lastColumnTitle?: string;
  id?: string;
  removeVerticalPadding?: boolean;
  backgroundColor?: "light" | "dark" | "transparent" | "error";
  size?: "small" | "medium" | "large";
  borderRadius?: number;
  border?: string;
  fullWidth?: boolean;
  borderBottom?: string;
  margin?: number;
  popoverItems?: PopoverItem[];
  popoverWidth?: number;
  clearance?: AnyPermissionClearance;
  resourceId?: string;
  bulkActions?: SubscribedActions;
  keepPopoverPlaceHolderOnEmptyItems?: boolean;
  dataCy?: string;
  containerMaxWidth?: number;
  iconPadding?: { paddingRight?: number; paddingLeft?: number; paddingTop?: number; paddingBottom?: number };
  removeSeparationLine?: boolean;
  deleteProps?: {
    onClick?: () => void;
    isDisabled?: boolean;
    Icon?: SVGIconComponent;
    hoverPopoverProps?: PopoverHoverProps;
  };
  removePopoverMarginTop?: boolean;
  skipDataCy?: boolean;
}

export const SmallListItemHeight = 50;

export const MediumListItemHeight = 70;

export const LargeListItemHeight = 88;

const popoverDefaultWidth = 200;

export const ListItemSizes = {
  small: {
    height: SmallListItemHeight
  },
  medium: {
    height: MediumListItemHeight
  },
  large: {
    height: LargeListItemHeight
  }
};

const ListItemContainer: React.FC<ListItemContainerProps> = memo((props: ListItemContainerProps) => {
  const {
    domRef,
    children,
    onClick,
    width,
    lastColumnTitle,
    id,
    backgroundColor,
    size = "medium",
    borderRadius,
    border,
    borderBottom,
    fullWidth,
    margin,
    clearance,
    resourceId,
    bulkActions,
    containerMaxWidth,
    skipDataCy
  } = props;

  const { enabled: bulkActionsEnabled, toggleItem, subscribe, isItemChecked } = useListBulkActionsContext();
  const { isTabletOrMobile, isMobile } = useResponsiveHelper();
  const smallDisplay = size && size === "small";

  const toggleChecked = useCallback(
    (on: boolean) => {
      toggleItem && resourceId && toggleItem(resourceId, on);
    },
    [resourceId, toggleItem]
  );

  const isChecked = useMemo(() => {
    if (!resourceId) return false;

    return isItemChecked(resourceId);
  }, [isItemChecked, resourceId]);

  useEffect(() => {
    if (!bulkActionsEnabled || !resourceId || !subscribe || !bulkActions) {
      return;
    }

    subscribe(bulkActions);
  }, [bulkActionsEnabled]);

  const classes = makeStyles();
  const { dispatchAnalytics } = useAnalyticsService();
  const _onClick = () => {
    dispatchAnalytics("listItem_click");
    if (onClick) {
      onClick();
    }
  };

  const _margin = margin ?? 5;

  const baseStyle = useMemo(() => {
    let _backgroundColor = "#fff";
    if (backgroundColor === "dark") {
      _backgroundColor = "#ECEEF5";
    }
    if (backgroundColor === "transparent") {
      _backgroundColor = "transparent";
    }
    if (backgroundColor === "error") {
      _backgroundColor = "rgb(228, 66, 88,0.05)";
    }

    const _baseStyle = {
      border: border ? border : backgroundColor === "error" ? "" : "1px solid #EAEDF3",
      boxShadow: border ? "none" : "0 1px 3px 0 rgba(0,0,0,0.04)",
      backgroundColor: _backgroundColor
    };
    if (borderBottom) {
      _baseStyle["border"] = "none";
      _baseStyle["boxShadow"] = "none";
      _baseStyle["borderBottom"] = borderBottom;
    }
    return _baseStyle;
  }, [backgroundColor, border, borderBottom]);

  return (
    <div
      ref={domRef}
      id={id}
      onClick={_onClick}
      data-cy={skipDataCy ? undefined : DataCy.DLUI.listItem.listItem}
      style={{
        height: isMobile ? "auto" : ListItemSizes[size].height,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        flexWrap: "nowrap",
        margin: `${_margin}px 0`,
        position: "relative"
      }}
    >
      <div
        className={clsx([
          isTabletOrMobile ? classes.listItemContainerMobile : "",
          smallDisplay ? classes.listItemContainerSmall : "",
          classes.listItemGridContainer,
          "listItemGridContainer"
        ])}
        style={{
          ...baseStyle,
          display: "flex",
          alignItems: "center",
          boxSizing: "border-box",
          paddingLeft: bulkActionsEnabled && !isMobile ? 12 : undefined,
          borderRadius: borderRadius ?? 10,
          width: width ?? "100%",
          height: "100%",
          maxWidth: fullWidth ? "100%" : containerMaxWidth || ListItemContainerMaxWidth,
          paddingTop: 0,
          paddingBottom: 0,
          minHeight: "initial"
        }}
      >
        {bulkActionsEnabled && !isMobile && (
          <div
            style={{
              display: "flex",
              paddingRight: 12
            }}
          >
            <Checkbox checked={isChecked} onCheckedChange={toggleChecked} />
          </div>
        )}
        <div
          className={"listItemGrid"}
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            flex: 1,
            height: isMobile ? "auto" : "100%"
          }}
        >
          {children}
          <div data-maxwidth={width || "100%"} />
          {lastColumnTitle ? <div data-lastcolumntitle={lastColumnTitle} /> : null}
        </div>
        <ListItemContainerOptions
          size={size}
          removeSeparationLine={props.removeSeparationLine}
          iconPadding={props.iconPadding}
          deleteProps={props.deleteProps}
          popoverItems={props.popoverItems}
          popoverWidth={props.popoverWidth}
          keepPopoverPlaceHolderOnEmptyItems={props.keepPopoverPlaceHolderOnEmptyItems}
          clearance={clearance}
          removePopoverMarginTop={props.removePopoverMarginTop}
        />
      </div>
    </div>
  );
});
ListItemContainer.displayName = "ListItemContainer";

const ListItemContainerOptions = memo(
  ({
    popoverItems,
    popoverWidth,
    deleteProps,
    removeSeparationLine,
    keepPopoverPlaceHolderOnEmptyItems,
    clearance,
    iconPadding,
    size,
    removePopoverMarginTop
  }: Pick<
    ListItemContainerProps,
    | "popoverItems"
    | "popoverWidth"
    | "deleteProps"
    | "removeSeparationLine"
    | "keepPopoverPlaceHolderOnEmptyItems"
    | "clearance"
    | "iconPadding"
    | "size"
    | "removePopoverMarginTop"
  >) => {
    const { hasPermission } = usePermission();
    const hasViewOnePermission = hasPermission(clearance?.permission, clearance?.field);
    const { isMobile } = useResponsiveHelper();
    const { ShakeEffectWrapper, runShakeEffect } = useShakeEffect();

    if (_.isEmpty(popoverItems) && keepPopoverPlaceHolderOnEmptyItems) {
      return (
        <div
          style={{
            display: "flex",
            width: "45px",
            height: "100%"
          }}
        />
      );
    }

    if (hasViewOnePermission && !_.isEmpty(popoverItems)) {
      return (
        <div
          className={"listItemPopover"}
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "auto",
            height: "100%",
            position: isMobile ? "absolute" : undefined,
            top: isMobile ? 0 : undefined,
            right: isMobile ? 0 : undefined
          }}
        >
          <ListItemOptions
            size={size === "large" ? "medium" : size}
            popoverWidth={popoverWidth || popoverDefaultWidth}
            popoverTopSectionItems={popoverItems}
            showSeparationLine={popoverItems && popoverItems.length > 0}
            removePopoverMarginTop={removePopoverMarginTop}
          />
        </div>
      );
    }
    if (!deleteProps?.onClick) {
      return null;
    }

    const Container: FC = ({ children }) =>
      deleteProps.hoverPopoverProps ? (
        <PopoverHover {...deleteProps.hoverPopoverProps}>{children}</PopoverHover>
      ) : (
        <>{children}</>
      );

    return (
      <div
        style={{
          display: "flex",
          alignItems: isMobile ? "flex-start" : "center",
          width: "auto",
          height: "100%",
          flexWrap: "wrap",
          position: isMobile ? "absolute" : undefined,
          top: isMobile ? 0 : undefined,
          right: isMobile ? 0 : undefined
        }}
      >
        {!isMobile && !removeSeparationLine && <SeparationLine height={"50%"} width={1} marginRight={10} />}
        <Container>
          <ShakeEffectWrapper>
            <IconButton
              Icon={deleteProps.Icon || DeleteIcon}
              marginRight={10}
              onClick={deleteProps.isDisabled ? runShakeEffect : deleteProps.onClick}
              {...iconPadding}
            />
          </ShakeEffectWrapper>
        </Container>
        <div className={"delete-button"} />
      </div>
    );
  }
);
ListItemContainerOptions.displayName = "ListItemContainerOptions";

export default ListItemContainer;
