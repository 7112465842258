import { ConfirmDialogUi } from "DLUI/dialogs/confirm/confirmDialogUi";
import React, { useState } from "react";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { useParams } from "react-router-dom";
import type { PropertyDto } from "@doorloop/dto";
import AppStrings from "../../../../locale/keys";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import { propertiesApi } from "api/propertiesApi";

interface ComponentProps {
  didFinishOperation: (values: PropertyDto) => void;
  onBackdropClick: () => void;
  refreshEvent?: () => void;
  dialogTitle: string;
}

const RemoveBoardMemberTermDialog = ({ didFinishOperation, onBackdropClick }: ComponentProps) => {
  const { propertyId, boardMemberId } = useParams<{ propertyId: string; boardMemberId: string }>();
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");

  const didPressConfirmButton = async () => {
    setLoadingDialogState(DialogState.Show);

    if (propertyId && boardMemberId) {
      const property = await propertiesApi.getFromDictionaryOrFetch(propertyId || "");

      if (!property) {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(AppStrings.Common.GeneralError);
        return;
      }

      property.boardMembers = property?.boardMembers?.filter((boardMember) => boardMember.id !== boardMemberId);

      const result = await propertiesApi.update(propertyId, property);

      if (result.status && result.data) {
        didFinishOperation?.(result.data);
      } else {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(result.message);
      }
    }
    setLoadingDialogState(DialogState.Hidden);
  };

  return (
    <ConfirmDialogUi
      onBackdropClick={onBackdropClick}
      showXButton
      dialogTitle={AppStrings.Common.Hoa.RemoveTerm}
      dialogSubTitle={AppStrings.Common.Hoa.RemoveTermDialogSubtitle}
      dismissButtonText={AppStrings.Common.Cancel}
      confirmButtonText={AppStrings.Common.Hoa.RemoveTerm}
      loadingDialogState={loadingDialogState}
      loadingDialogErrorText={loadingDialogErrorText}
      didPressConfirmButton={didPressConfirmButton}
      didPressDismissButton={onBackdropClick}
      dialogHeight={getDialogFrameDimension("height", 520)}
    />
  );
};

export default RemoveBoardMemberTermDialog;
