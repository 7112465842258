import { Button as MUIButton, CircularProgress } from "@material-ui/core";
import clsx from "clsx";
import type { CSSProperties, MouseEventHandler } from "react";
import React, { useMemo } from "react";

import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { TextComponentProps } from "DLUI/text/text";

import type { SVGIconComponent } from "../../../../../assets";
import { AddCta, OpenInNewIcon } from "../../../../../assets";
import { useShakeEffect } from "../../../../../hooks/useShakeEffect";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";
import type { DataAttributes } from "../../../../../hooks/useDataAttributes";
import { useDataAttributes } from "../../../../../hooks/useDataAttributes";
import { useAnalyticsService } from "../../../../../hooks/useAnalyticsService";

import { DLButtonSizesEnum } from "../enums";
import type { UseStylesButtonProps } from "./useStylesButton";
import useStylesButton from "./useStylesButton";

interface DLButtonIconProps {
  src?: SVGIconComponent;
  isHidden?: boolean;
  keepColor?: boolean;
}

export interface DLButtonIcons {
  start?: DLButtonIconProps;
  end?: DLButtonIconProps;
}

export interface DLButtonUIProps extends UseStylesButtonProps {
  icons?: DLButtonIcons;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  actionText?: string;
  disableMobileCollapse?: boolean;
  isLoading?: boolean;
  id?: string;
  style?: CSSProperties;
  textProps?: Partial<TextComponentProps>;
  ref?: React.Ref<HTMLButtonElement>;
  dataCy?: string;
  dataAttributes?: DataAttributes;
  containerClassName?: string;
  contentContainerStyle?: CSSProperties;
  tabIndex?: number;
}

const DefaultProps = {
  icons: {
    start: { isHidden: true, src: AddCta },
    end: { isHidden: true, src: OpenInNewIcon }
  },
  disabled: false,
  style: { minWidth: 100 }
};

/**
 * @private {@link DLButton}
 * @deprecated this is a subcomponent, use {@link DLButton}
 * */
const ButtonUI: React.FC<DLButtonUIProps> = React.forwardRef(
  (
    {
      variant,
      size,
      color,
      icons,
      actionText,
      onClick,
      disabled = DefaultProps.disabled,
      isLoading,
      disableMobileCollapse = false,
      style,
      textProps,
      dataCy,
      containerClassName,
      id,
      dataAttributes,
      contentContainerStyle,
      tabIndex,
      ...rest
    },
    ref
  ) => {
    const { isMobile } = useResponsiveHelper();
    const { className: shakeClassName, runShakeEffect } = useShakeEffect();

    const classNames = useStylesButton({
      variant,
      color,
      size: size ?? (isMobile ? DLButtonSizesEnum.LARGE : DLButtonSizesEnum.MEDIUM),
      keepColorIconStart: icons?.start?.keepColor,
      keepColorIconEnd: icons?.end?.keepColor,
      classNames: { container: clsx(shakeClassName, containerClassName) }
    });

    const shouldCollapse = useMemo(() => isMobile && !disableMobileCollapse, [isMobile, disableMobileCollapse]);

    const { isCompactMode, minWidth, iconsProps, IconSourceStart, IconSourceEnd } = useMemo(() => {
      const iconsProps = icons
        ? {
            start: icons.start
              ? {
                  ...DefaultProps.icons.start,
                  ...icons.start,
                  isHidden: icons.start.isHidden ?? (icons.start.src ? false : DefaultProps.icons.start.isHidden)
                }
              : undefined,
            end: icons.end
              ? {
                  ...DefaultProps.icons.end,
                  ...icons.end,
                  isHidden: icons.end.isHidden ?? (icons.end.src ? false : DefaultProps.icons.end.isHidden)
                }
              : undefined
          }
        : undefined;

      const isCompactMode =
        !actionText ||
        (shouldCollapse &&
          ((iconsProps?.start && !iconsProps.start.isHidden) || (iconsProps?.end && !iconsProps.end.isHidden)) &&
          !style?.minWidth);
      const minWidth = isCompactMode ? "auto" : style?.minWidth ?? DefaultProps.style.minWidth;

      return {
        isCompactMode,
        minWidth,
        iconsProps,
        IconSourceStart: iconsProps?.start?.src,
        IconSourceEnd: iconsProps?.end?.src
      };
    }, [icons, actionText, shouldCollapse, style?.minWidth]);

    const { dispatchAnalytics, formatAppStringsValue } = useAnalyticsService();
    const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
      dispatchAnalytics("button_click", { label: formatAppStringsValue(actionText) });
      return isLoading ? runShakeEffect() : onClick?.(e);
    };

    const dataAttributesProps = useDataAttributes(dataAttributes);

    return (
      <MUIButton
        id={id}
        ref={ref}
        className={classNames.container}
        onClick={handleClick}
        type={onClick ? "button" : "submit"}
        disabled={disabled || isLoading}
        disableFocusRipple
        tabIndex={tabIndex}
        data-cy={dataCy}
        {...dataAttributesProps}
        style={{ ...style, minWidth }}
        {...rest}
      >
        <View
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"row"}
          gap={5}
          style={contentContainerStyle}
        >
          {(IconSourceStart || !IconSourceEnd) &&
            (isLoading ? (
              <CircularProgress className={clsx([classNames.iconStart, classNames.loadingIcon])} />
            ) : (
              !iconsProps?.start?.isHidden &&
              IconSourceStart && (
                <IconSourceStart
                  className={disabled ? clsx([classNames.iconStart, classNames.loadingIcon]) : classNames.iconStart}
                />
              )
            ))}

          {!isCompactMode && <Text className={classNames.text} bold value={actionText} {...textProps} />}

          {IconSourceEnd &&
            (isLoading ? (
              <CircularProgress className={clsx([classNames.iconEnd, classNames.loadingIcon])} />
            ) : (
              !iconsProps?.end?.isHidden && (
                <IconSourceEnd
                  className={disabled ? clsx([classNames.iconEnd, classNames.loadingIcon]) : classNames.iconEnd}
                />
              )
            ))}
        </View>
      </MUIButton>
    );
  }
);
ButtonUI.displayName = "ButtonUI";

export default ButtonUI;
