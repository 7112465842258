import { useLoadConversationsStats } from "@/components/screens/communicationsCenter/shared/useLoadConversationsStats";
import { setupBuildMetadata } from "@/globalVariables";
import { useEffect } from "react";
import { useBaseHistoryLength } from "hooks/useBaseHistoryLength";

export function AppInitializer() {
  useLoadConversationsStats();
  useBaseHistoryLength();

  useEffect(() => {
    setupBuildMetadata();
  }, []);

  return null;
}
