import React, { useMemo, useState } from "react";

import _ from "lodash";
import type { FormikProps } from "formik";
import { getIn } from "formik";
import { useParams } from "react-router-dom";

import AppStrings from "locale/keys";
import { PortalInvitesForm } from "DLUI/settings/portal/portalInvitesForm";
import { RadioGroup } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import { View } from "DLUI/view";

interface ComponentProps {
  formikRef: FormikProps<any>;
}

const PortalInvites: React.FC<ComponentProps> = ({ formikRef }: ComponentProps) => {
  const { propertyId } = useParams<any>();

  const formikFieldName = useMemo(() => (propertyId ? "settings.tenantPortal.emailInvite" : "emailInvite"), []);

  const invitesSelectionDefaultValue = useMemo(() => {
    const fieldValue = getIn(formikRef.values, formikFieldName);
    const emptyObject = _.values(fieldValue).every((x) => x === undefined);
    if (emptyObject) {
      return "companyDefault";
    }
    return "specifyInvitesInfo";
  }, []);

  const [invitesSelectionType, setInvitesSelectionType] = useState<"companyDefault" | "specifyInvitesInfo">(
    invitesSelectionDefaultValue
  );
  const [renderForm, setRenderForm] = useState<boolean>(true);

  const refreshForm = (): void => {
    setRenderForm(false);

    formikRef.setFieldValue(formikFieldName, undefined);

    setTimeout(() => {
      setRenderForm(true);
    }, 0);
  };

  const didChangeSpecifyFeaturesSelection = (selectedValue: string): void => {
    if (selectedValue === "false") {
      setInvitesSelectionType("companyDefault");

      setTimeout(() => {
        refreshForm();
      }, 500);
    } else {
      setInvitesSelectionType("specifyInvitesInfo");
    }
  };

  const renderSpecifyRadioButtons = () => {
    if (propertyId) {
      return (
        <View>
          <RadioGroup
            didChangeSelection={didChangeSpecifyFeaturesSelection}
            uniqueKey={"FRB"}
            defaultValue={invitesSelectionType === "companyDefault" ? "false" : "true"}
            marginTop={20}
            radioGroupItems={[
              {
                label: AppStrings.Common.HideInvitesForProperty,
                value: "false"
              },
              {
                label: AppStrings.Common.SpecifyInvitesForProperty,
                value: "true"
              }
            ]}
          />
          <SeparationLine marginTop={20} width={"100%"} height={1} />
        </View>
      );
    }
  };

  return (
    <PortalInvitesForm
      titleText={AppStrings.Common.TenantPortalInvitesDescription}
      formikFieldNamePrefix={formikFieldName}
      shouldShow={invitesSelectionType === "specifyInvitesInfo"}
      renderForm={renderForm}
      renderSpecifyRadioButtons={renderSpecifyRadioButtons}
    />
  );
};

export default PortalInvites;
