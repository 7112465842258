import Routes from "components/appRouter/routes";
import { NewAnnouncementScreen } from "screens/announcements/newAnnouncement/newAnnouncementScreen";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";
import { lazy } from "react";

const AnnouncementsScreen = lazy(() => import("screens/announcements/announcementsScreen"));
const AnnouncementDetails = lazy(() => import("screens/announcements/announcementDetails/announcementDetails"));

const AnnouncementsRouter = () => (
  <PageRouter>
    <LazyRoute path={Routes.ANNOUNCEMENTS_CONFIRM} render={AnnouncementsScreen} />
    <LazyRoute path={Routes.ANNOUNCEMENTS_GLOBAL_NEW} render={AnnouncementsScreen} />
    <LazyRoute path={Routes.ANNOUNCEMENTS} render={AnnouncementsScreen} exact />
    <LazyRoute path={Routes.ANNOUNCEMENTS_NEW} render={NewAnnouncementScreen} exact />
    <LazyRoute path={Routes.ANNOUNCEMENTS_DETAILS_GLOBAL_NEW} render={AnnouncementDetails} />
    <LazyRoute
      path={[
        Routes.ANNOUNCEMENTS_DETAILS,
        Routes.ANNOUNCEMENTS_DETAILS_EDIT,
        Routes.ANNOUNCEMENTS_DETAILS_DELETE,
        Routes.ANNOUNCEMENTS_DETAILS_VIEW_COMMUNICATION
      ]}
      exact
      render={AnnouncementDetails}
    />
  </PageRouter>
);

export default AnnouncementsRouter;
