"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseDepositsHeldByAccountReportLine = void 0;
class LeaseDepositsHeldByAccountReportLine {
    constructor(journalEntryId, journalEntryType, amount, runningBalance, date, memo, reference) {
        this.memo = memo;
        this.amount = amount;
        this.runningBalance = runningBalance;
        this.journalEntryId = journalEntryId;
        this.journalEntryType = journalEntryType;
        this.reference = reference;
        this.date = date;
    }
}
exports.LeaseDepositsHeldByAccountReportLine = LeaseDepositsHeldByAccountReportLine;
