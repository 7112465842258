import type { FC } from "react";
import { View } from "DLUI/view";
import type { HelpPanelProps } from "./types";
import { useHelpVideoDialog } from "./useHelpVideoDialog";
import { HelpPanelActionItems } from "./helpPanelActionItems";
import { HelpPanelAccordion } from "./helpPanelAccordion";

import { HelpPanelSingleActionItem } from "./helpPanelSingleActionItem";

export const HelpPanel: FC<HelpPanelProps> = ({ actionText, actionItems, description, noWrapActionItem }) => {
  const { DialogView, onOpenHelpVideo } = useHelpVideoDialog();

  return (
    <>
      <View flexDirection={"row"} alignItems={"center"} paddingTop={4}>
        {actionItems?.length === 1 ? (
          <HelpPanelSingleActionItem
            actionItem={actionItems[0]}
            description={description}
            onOpenHelpVideo={onOpenHelpVideo}
          />
        ) : (
          <HelpPanelAccordion actionText={actionText} description={description}>
            <HelpPanelActionItems
              actionItems={actionItems}
              noWrapActionItem={noWrapActionItem}
              onOpenHelpVideo={onOpenHelpVideo}
            />
          </HelpPanelAccordion>
        )}
      </View>
      <DialogView />
    </>
  );
};
