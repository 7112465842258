import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import type { HTMLAttributes, PropsWithChildren } from "react";

const useStyles = makeStyles({
  root: {
    display: "flex",
    gap: 8
  }
});

type ContentProps = HTMLAttributes<HTMLDivElement>;

export function Content(props: PropsWithChildren<ContentProps>) {
  const { children, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {children}
    </div>
  );
}
