import React, { useMemo, useState } from "react";
import { View } from "DLUI/view";
import { Grid } from "@material-ui/core";
import type { AccountRegisterReportItem } from "@doorloop/dto";
import { mathUtils } from "@doorloop/dto";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { SeparationLine } from "DLUI/separatorView";
import { Icon } from "DLUI/icon";
import type { SVGIconComponent } from "assets/icons";
import { AlertIconCircle, CircleEquals, CircleSubtract } from "assets/icons";
import type { TextColor } from "DLUI/text/text";
import { ShakeEffectView } from "DLUI/animatableView";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";

interface ComponentProps {
  didPressSaveButton: () => void;
  didPressCancelButton: () => void;
  selectedTransactions?: AccountRegisterReportItem[];
  transactionAmount?: number;
  transactionDate?: string;
}

interface LabelValueElementProps {
  label: string;
  value?: number;
  icon?: SVGIconComponent;
  valueColor?: TextColor;
}

const LabelValueElement: React.FC<LabelValueElementProps> = ({
  label,
  value,
  icon,
  valueColor
}: LabelValueElementProps) => (
  <View flexDirection={"row"} alignItems={"center"}>
    {icon && (
      <View width={"auto"}>
        <Icon Source={icon} marginRight={30} />
      </View>
    )}
    <View flex={icon ? 1 : undefined}>
      <Text value={label} color={"gray"} fontWeight={700} fontSize={14} />
      <Text
        value={String(value)}
        color={valueColor || "white"}
        fontWeight={700}
        fontSize={24}
        formatType={"currency"}
        marginTop={5}
      />
    </View>
  </View>
);

const MatchTransactionDialogSummaryPanel: React.FC<ComponentProps> = ({
  didPressSaveButton,
  didPressCancelButton,
  selectedTransactions = [],
  transactionAmount,
  transactionDate
}: ComponentProps) => {
  const [showValidationMessage, setShowValidationMessage] = useState<boolean>(false);
  const [showErrorShakeEffect, setShowErrorShakeEffect] = useState(false);

  const { matchTransactionsAmount, differenceAmount } = useMemo(() => {
    let matchTransactionsAmount = 0;
    let differenceAmount = 0;

    selectedTransactions.forEach((x) => (matchTransactionsAmount += x.amount || 0));

    differenceAmount = mathUtils.substract(transactionAmount, matchTransactionsAmount);

    if (differenceAmount === 0) {
      setShowValidationMessage(false);
    }

    return {
      matchTransactionsAmount,
      differenceAmount
    };
  }, [selectedTransactions]);

  const runShakeEffect = () => {
    setShowErrorShakeEffect(true);
    setTimeout(() => {
      setShowErrorShakeEffect(false);
    }, 300);
  };

  const _didPressSaveButton = () => {
    setShowValidationMessage(differenceAmount !== 0);
    if (differenceAmount !== 0) {
      runShakeEffect();
    } else {
      didPressSaveButton();
    }
  };

  const renderValidationView = () =>
    showValidationMessage && (
      <View borderRadius={7} marginTop={20} backgroundColor={"white"}>
        <View
          style={{
            backgroundColor: "rgba(228, 66, 88,0.1)"
          }}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"row"}
          height={50}
        >
          <Icon
            pathColor={"white"}
            circleStroke={"error"}
            circleFill={"error"}
            Source={AlertIconCircle}
            width={25}
            height={25}
            marginRight={10}
          />
          <Text value={AppStrings.Common.MatchedTransactionsValidationMessage} color={"error"} />
        </View>
      </View>
    );

  return (
    <View backgroundColor={"black"} style={{ position: "absolute", bottom: 0 }}>
      <View marginTop={20} paddingRight={20} paddingLeft={20}>
        <View>
          <View flexDirection={"row"}>
            <Grid item container xs={3}>
              <Text color={"white"} fontSize={18} fontWeight={700} value={AppStrings.Common.MatchSummary} />
              <View flexDirection={"row"} marginTop={5}>
                <Text value={AppStrings.Common.TransactionDate} color={"white"} />
                <Text value={transactionDate} color={"white"} formatType={"date"} paddingLeft={5} />
              </View>
            </Grid>
            <Grid item container xs={3}>
              <LabelValueElement label={AppStrings.Common.BankTransaction} value={transactionAmount} />
            </Grid>
            <Grid item container xs={3}>
              <LabelValueElement
                label={AppStrings.Common.MatchTransactions}
                value={matchTransactionsAmount}
                icon={CircleSubtract}
              />
            </Grid>
            <Grid item container xs={3}>
              <LabelValueElement
                label={AppStrings.Common.Difference}
                value={differenceAmount}
                icon={CircleEquals}
                valueColor={differenceAmount === 0 ? "green" : "error"}
              />
            </Grid>
          </View>
          {renderValidationView()}
          <SeparationLine marginTop={20} width={"100%"} height={1} />

          <FormActionButtons
            propsActionPanel={{ paddingRight: 0, marginTop: 20, marginBottom: 20 }}
            propsSubButton={{ onClick: didPressCancelButton }}
            propsMainButton={{
              type: "cta",
              props: { onClick: _didPressSaveButton },
              Wrapper: ({ children }) => <ShakeEffectView showEffect={showErrorShakeEffect}>{children}</ShakeEffectView>
            }}
          />
        </View>
      </View>
    </View>
  );
};

export default MatchTransactionDialogSummaryPanel;
