import useStyles from "./styles";
import { Icon } from "DLUI/icon";
import Text from "DLUI/text";
import React from "react";
import { View } from "DLUI/view";
import clsx from "clsx";
import { TextTooltip } from "DLUI/popover/textTooltip";
import type { ComponentProps } from "DLUI/drawer/navigationItem";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import { useOnBoarding } from "screens/onBoarding/useOnBoarding";
import RadialProgressBar from "DLUI/progressBar/radialProgressBar";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

const useOnboardingDrawerItemStyles = makeStyles({
  link: {
    "&, &:hover, &:visited": {
      textDecoration: "none",
      color: "inherit"
    }
  }
});
const OnBoardingDrawerItem: React.FC<ComponentProps> = ({
  navPath,
  label,
  icon,
  handleNavClick,
  isActive,
  isMinimized,
  navKey,
  withActiveSideIndicator
}) => {
  const classes = useStyles();
  const onboardingClasses = useOnboardingDrawerItemStyles();
  const { isTabletOrMobile } = useResponsiveHelper();
  const onBoardingData = useSelector(
    (state: IRootState) => state.auth.currentLoginResponse?.currentDbTenant?.onBoarding
  );

  const { isOnBoardingCompleted, getProgress } = useOnBoarding();

  if (!onBoardingData) {
    // This should never happen
    // Login response should always have an onBoarding object according to the login response dto
    console.error("OnBoarding data is not available");
    return <></>;
  }

  const { completedCount, totalCount } = getProgress(onBoardingData);
  const percentCompleted = Math.min(100, Math.max(0, Math.ceil((completedCount / totalCount) * 100)));

  const handleLinkClick = () => {
    handleNavClick({ menuItemSelection: navKey });
  };

  /**
   * Don't show the item if the user has completed the onboarding.
   */
  if (
    isOnBoardingCompleted(onBoardingData, { completedCount, totalCount }) ||
    percentCompleted > 99 ||
    !navPath?.[0]?.name
  ) {
    return <></>;
  }

  return (
    <NavLink
      to={navPath[0].name}
      exact={navPath[0].matchMethod === "exact"}
      onClick={handleLinkClick}
      className={clsx([
        classes.menuItem,
        onboardingClasses.link,
        isActive && !isMinimized ? "active" : "",
        isMinimized ? "minimized" : ""
      ])}
      style={{
        width: `calc(100% - ${isTabletOrMobile ? 40 : 20}px)`,
        height: isMinimized ? 40 : 40,
        marginLeft: isTabletOrMobile && !isMinimized ? 20 : 10,
        marginRight: isTabletOrMobile && !isMinimized ? 20 : 10,
        borderRadius: 5,
        display: "flex"
      }}
    >
      <View
        className={clsx([classes.menuLink, isActive ? "active" : "", isMinimized ? "minimized" : ""])}
        flexDirection={"row"}
        alignItems={"center"}
        noWrap
        autoWidth
        paddingRight={10}
        paddingTop={10}
        paddingLeft={10}
        paddingBottom={10}
        borderRadius={5}
      >
        {isMinimized || !withActiveSideIndicator ? null : <span />}
        {icon && (
          <TextTooltip value={label} placement={"right"} show={isMinimized}>
            <View autoWidth={!isMinimized}>
              <View autoWidth marginRight={!isMinimized ? 5 : 0}>
                <Icon Source={icon} pathColor={"white"} size={20} />
              </View>
            </View>
          </TextTooltip>
        )}
        <View
          width={isMinimized ? "0" : "auto"}
          style={{ transition: "width ease-in-out 150ms" }}
          overflow={"hidden"}
          flexDirection={"row"}
          alignItems={"center"}
          noWrap
        >
          <Text value={label} color={"white"} fontSize={14} />
        </View>
      </View>
      {!isMinimized && (
        <View flexDirection={"row"} paddingRight={10} autoWidth alignItems={"center"} height={"100%"}>
          <RadialProgressBar progress={percentCompleted} />
        </View>
      )}
    </NavLink>
  );
};

export default OnBoardingDrawerItem;
