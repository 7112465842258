import type { TenantRequestMaintenanceCategory, TenantRequestType } from "@doorloop/dto";
import { TaskStatus } from "@doorloop/dto";
import AppStrings from "locale/keys";
import ColorsEnum from "@/utils/colorsEnum";
import { useTranslation } from "react-i18next";

export const useRequestStatusesMap = () => {
  const { t } = useTranslation();

  const statusLabelColorMap = {
    [TaskStatus.NOT_STARTED]: { label: t(AppStrings.Common.Enums.TaskStatus.NOT_STARTED), color: ColorsEnum.Black },
    [TaskStatus.IN_PROGRESS]: {
      label: t(AppStrings.Common.Enums.TaskStatus.IN_PROGRESS),
      color: ColorsEnum.Yellow
    },
    [TaskStatus.COMPLETED]: { label: t(AppStrings.Common.Enums.TaskStatus.COMPLETED), color: ColorsEnum.DarkGreen },
    [TaskStatus.ARCHIVED]: {
      label: t(AppStrings.Common.Enums.TaskStatus.ARCHIVED),
      color: ColorsEnum.LightGraySecondary
    },
    [TaskStatus.RECEIVED]: { label: t(AppStrings.Common.Enums.TaskStatus.RECEIVED), color: ColorsEnum.BrightBlue }
  };

  const getTaskTypeLabel = (key: TenantRequestType | undefined) =>
    t(AppStrings.Common.Enums.TenantRequestType[key || ""]) || t(AppStrings.Common.NotSet);

  const getMaintenanceTypeLabel = (key: TenantRequestMaintenanceCategory | undefined) =>
    t(AppStrings.Common.Enums.TenantRequestMaintenanceCategory[key || ""]) || t(AppStrings.Common.NotApplicable);

  return { statusLabelColorMap, getMaintenanceTypeLabel, getTaskTypeLabel };
};
