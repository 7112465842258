const BulkPayments = {
  bulkReceivePayments: "Bulk Receive Payments",
  receivePayments: "Receive Payments",
  searchToBegin: "Search to begin",
  receivePaymentsIntroText: "Receive payments by adding tenants, properties, and leases",
  paymentsAdded: "Payments Added:",
  totalAmountReceived: "Total Amount Received:",
  amountReceived: "Amount Received",
  paymentDetails: "Payment Details",
  searchPlaceholder: "Search for leases, properties or units",
  balance: "Balance",
  receivedFrom: "Received From",
  depositAccount: "Deposit Account",
  pleaseWaitWhileWeLoadTheSummary: "Please wait while we load the summary",
  processingPayment: "Processing payment",
  processingPaymentForX: "Processing payment for {{x}}",
  userConfirmLeaveCurrentSession: "You have unsaved changes. Are you sure you want to close this dialog?",
  userConfirmUnselectRows: "Are you sure you want to unselect all rows? You will lose the changes you have made.",
  helpPanelSearchAndFill: {},
  errors: {
    couldNotFetchTenants: "Could not fetch tenants, please try again",
    couldNotFetchProperties: "Could not fetch properties, please try again",
    couldNotFetchLeases: "Could not fetch leases, please try again",
    couldNotFetchDepositAccounts: "Could not fetch deposit accounts, please try again",
    couldNotFetchPaymentMethods: "Could not fetch payment methods, please try again",
    noPaymentsSelected: "Please add at least one payment"
  },
  helpPanel: {
    searchAndFill: {
      description: "Receive bulk payments by adding tenants, properties, and leases"
    },
    review: {
      description: "Review your added payments"
    }
  }
};

export default BulkPayments;
