import {
  DataCy,
  JournalEntryType,
  RecurringTransactionStatus,
  RecurringTransactionType,
  ReportId,
  ReportPermission
} from "@doorloop/dto";
import AppStrings from "locale/keys";
import { NavigationManager } from "utils/navigation";
import type { ReportScreenItemType } from "DLUI/report/screen/reportScreenItemType";
import { filterObjectsByUserType } from "utils/userTypesUtils";

const didPressAccountsReceivableAgingDetail = () => {
  const params: Record<string, string> = {};

  NavigationManager.runAccountReceivableAgingDetailReport(params);
};

const didPressAccountsReceivableAgingDetailByAccount = () => {
  const params: Record<string, string> = {};

  NavigationManager.runAccountReceivableAgingDetailByAccountReport(params);
};

const didPressAccountsReceivableAgingSummary = () => {
  NavigationManager.runAccountReceivableAgingSummaryReport({});
};

const didPressRecurringPayments = (propertyId?: string) => {
  const params: Record<string, string> = {
    filter_type: RecurringTransactionType.LEASE_PAYMENT,
    filter_status: RecurringTransactionStatus.ACTIVE
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runRecurringTransactionsReport(params);
};

const didPressRecurringTransactionsDetail = (propertyId?: string) => {
  const params: Record<string, string> = {
    filter_type: RecurringTransactionType.LEASE_RENT,
    filter_status: RecurringTransactionStatus.ACTIVE
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runRecurringTransactionsDetailReport(params);
};

const didPressReversedPayments = (propertyId?: string) => {
  const params: Record<string, string> = {
    filter_type: JournalEntryType.LEASE_REVERSED_PAYMENT
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runTransactionsListReport(params);
};

const items: ReportScreenItemType[] = [
  {
    description: AppStrings.Reports.ReportsScreen.AccountsReceivableAgingDetail,
    onPress: didPressAccountsReceivableAgingDetail,
    permission: ReportPermission.accountsReceivable,
    id: ReportId.ACCOUNT_RECEIVABLE_AGING_DETAILS,
    dataCy: DataCy.reports.screenItems.whoOwesYou.arAgingDetail
  },
  {
    description: AppStrings.Reports.ReportsScreen.AccountsReceivableAgingDetailByAccount,
    onPress: didPressAccountsReceivableAgingDetailByAccount,
    permission: ReportPermission.accountsReceivable,
    id: ReportId.ACCOUNT_RECEIVABLE_AGING_DETAILS_BY_ACCOUNT,
    dataCy: DataCy.reports.screenItems.whoOwesYou.arAgingDetailByAccount
  },
  {
    description: AppStrings.Reports.ReportsScreen.AccountsReceivableAgingSummary,
    onPress: didPressAccountsReceivableAgingSummary,
    permission: ReportPermission.accountsReceivable,
    id: ReportId.ACCOUNT_RECEIVABLE_AGING_SUMMARY,
    dataCy: DataCy.reports.screenItems.whoOwesYou.arAgingSummary
  },
  {
    description: AppStrings.Reports.ReportsScreen.RecurringTransactionsDetail,
    onPress: didPressRecurringTransactionsDetail,
    permission: ReportPermission.recurringTransactions,
    id: ReportId.RECURRING_TRANSACTIONS_DETAIL,
    dataCy: DataCy.reports.screenItems.whoOwesYou.recurringRentTransactions
  },
  {
    description: AppStrings.Reports.ReportsScreen.RecurringPayments,
    onPress: didPressRecurringPayments,
    permission: ReportPermission.recurringTransactions,
    id: ReportId.RECURRING_PAYMENTS,
    dataCy: DataCy.reports.screenItems.whoOwesYou.recurringPaymentsAutoPay
  },
  {
    description: AppStrings.Common.Enums.JournalEntryType.ReversedPayments,
    onPress: didPressReversedPayments,
    permission: ReportPermission.recurringTransactions,
    id: ReportId.REVERSED_PAYMENTS,
    dataCy: DataCy.reports.screenItems.whoOwesYou.reversedPayment
  }
];

const getWhoOwesYouItems = (isHOAUser: boolean): ReportScreenItemType[] => {
  if (isHOAUser) {
    return filterObjectsByUserType(items, "HOAUser");
  }

  return items;
};

export default getWhoOwesYouItems;
