import MomentUtils from "@date-io/moment";
import type { DepositDto, GetAllAccountsQuery, LeaseChargeDto, LeaseCreditDto, LeaseDto } from "@doorloop/dto";
import {
  AccountType,
  CheckReceivableInfoDto,
  ClientJournalEntryType,
  createValidator,
  DataCy,
  DepositStatus,
  DuplicateDialogButtonLabelValues,
  LeasePaymentDto,
  LinkedResourceType,
  mathUtils,
  ObjectPermission,
  PaymentMethod,
  PaymentMethodNoEpay,
  SegmentEventTypes,
  shouldBlockFeatureForSubscriptionPriceId
} from "@doorloop/dto";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { accountsApi } from "api/accounts";
import { filesApi } from "api/filesApi";
import { leasePaymentsApi } from "api/leasePaymentsApi";
import { leaseReversedPaymentsApi } from "api/leaseReversedPaymentsApi";
import { leaseTenantsApi, tenantsApi } from "api/tenantsApi";
import { ErrorSignIcon } from "assets/icons";
import { ShakeEffectView } from "DLUI/animatableView";
import { DialogSearchPanel, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import {
  FormikDatePicker,
  FormikReferenceLabel,
  FormikSwitchButton,
  Select,
  SetAsDefaultField,
  TextField,
  ValidationIndicator
} from "DLUI/form";
import { Notes } from "DLUI/notes";
import { DialogRoutes } from "DLUI/screen/dialogsProvider";
import { SeparationLine } from "DLUI/separatorView";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { Field, Formik, getIn } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import LeasesList from "screens/leases/leases/leasesList/leasesList";
import SelectableLeaseListItem from "screens/leases/leases/leasesList/selectableLeaseListItem";
import TenantPortalNotificationPanel from "screens/tenantPortal/components/tenantPortalNotificationPanel";
import { NavigationManager } from "utils/navigation";
import DeleteConfirmation from "../components/deleteConfirmation";
import DialogFrame, { getDialogFrameDimension, getDialogSelectionHeight } from "../components/dialogFrame";
import EPayInfoView from "../components/ePayInfoView";
import LeaseSelection from "../transactions/leaseSelection";
import OutstandingTransactionsList from "./outstandingTransactionsList";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { leasesApi } from "api/leasesApi";
import { Lottie } from "DLUI/lottie";
import spinner from "../../../../assets/lottie/spinner.json";
import ReconciledNotificationView from "DLUI/dialogs/components/reconciledNotificationView";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { ArticleIdsEnum, HelpTypeEnum, VideoUrlsEnum } from "DLUI/screen/helpPanel/types";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";
import { Feature } from "config/featureFlags/featureFlags";
import BulkPayment from "./bulkPayment/bulkPayment";
import { DialogTitleActionButton } from "../components/dialogTitleActionButton";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { analyticsService } from "services/analyticsService";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum } from "DLUI/button/dlButton";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { useLocalStorage } from "hooks/useLocalStorage";
import { usePermission } from "screens/settings/userRoles/usePermission";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import { useEPayStatusText } from "shared/ePayStatus/ePayStatusText";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { useUserType } from "hooks/useUserType";
import RestrictedDeviceAccess from "DLUI/restrictedAccess/restrictedDeviceAccess";
import { DialogHeaderActionButtons } from "DLUI/actionButtons/dialogHeaderActionButtons";
import { QueryParams } from "utils/queryParams";
import { cleanLeasePaymentDataForDuplicateMode, DUPLICATE_MODE_QUERY_PARAMS } from "DLUI/dialogs/duplicateModeHelper";
import { useAnalyticsService } from "hooks/useAnalyticsService";
import { useShakeEffect } from "hooks/useShakeEffect";
import { ActivityLabel } from "DLUI/activityLabel/activityLabel";
import { generalUtils } from "@doorloop/utils";
import { useJournalEntryPrinting } from "hooks/useJournalEntryPrinting";

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: (values?: any, shouldRefresh?: boolean) => void;
  dialogTitle: string;
  dialogFrameWidth?: number;
  dialogFrameHeight?: number;
  transactionDto: LeaseChargeDto | LeaseCreditDto | DepositDto;
  transactionCreatedText: string;
  loadingTransactionDataText: string;
  loadingTransactionDataSuccessText: string;
  updatingTransactionText: string;
  transactionUpdatedText: string;
}

let formikGlobalRef: FormikProps<any> | null = null;

const helpObject: HelpPanelProps = {
  description: AppStrings.Leases.LeaseCharge.OneTimePaymentHelpPanel.Description,
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Leases.LeaseCharge.OneTimePaymentHelpPanel.Record,
      articleId: ArticleIdsEnum.LEASE_RECEIVE_PAY
    },
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Leases.LeaseCharge.OneTimePaymentHelpPanel.EditDelete,
      articleId: ArticleIdsEnum.EDIT_DELETE_LEASE_TRANSACTIONS
    },
    {
      type: HelpTypeEnum.WATCH_VIDEO,
      topic: AppStrings.Common.WatchExample,
      href: VideoUrlsEnum.LEASE_OVERVIEW,
      dialogTitle: AppStrings.Leases.LeaseCharge.OneTimePaymentHelpPanel.DialogTitle,
      startTime: 134
    }
  ]
};

const helpObjectHOA: HelpPanelProps = {
  description: AppStrings.Leases.LeaseCharge.OneTimePaymentHelpPanel.Description,
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Leases.LeaseCharge.OneTimePaymentHelpPanel.Record,
      articleId: ArticleIdsEnum.HOA_RECORD_PAYMENTS
    },
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Leases.LeaseCharge.OneTimePaymentHelpPanel.EditDelete,
      articleId: ArticleIdsEnum.HOA_EDIT_OR_DELETE_PAYMENTS
    },
    {
      type: HelpTypeEnum.WATCH_VIDEO,
      topic: AppStrings.Common.WatchExample,
      href: VideoUrlsEnum.LEASE_OVERVIEW,
      dialogTitle: AppStrings.Leases.LeaseCharge.OneTimePaymentHelpPanel.DialogTitle,
      startTime: 134
    }
  ]
};

const dialogWidth = Math.min(window.innerWidth, 1000);
const ViewOnlyModeDialogHeight = 720;
const CreateOrEditDialogHeight = getDialogFrameDimension("height", 760);
const AnimationDelay = 300;

const ShowCheckNumberMethods = [PaymentMethod.CHECK, PaymentMethod.CASHIERS_CHECK, PaymentMethod.MONEY_ORDER];

const PaymentDialog: React.FC<ComponentProps> = ({
  onBackdropClick,
  onClose,
  dialogTitle,
  transactionCreatedText,
  loadingTransactionDataText,
  loadingTransactionDataSuccessText,
  updatingTransactionText
}: ComponentProps) => {
  const { isHOAUser } = useUserType();
  const subscriptionPriceId = useSelector(
    (state: IRootState) => state.auth.currentLoginResponse?.currentDbTenant?.subscriptionPlan?.price_id
  );
  const { t } = useTranslation();
  const { leaseId, transactionId: paramsTransactionId } = useParams<any>();
  const { dispatchAnalytics } = useAnalyticsService();
  const { hasPermission } = usePermission();
  const { isMobile, screenContainerPadding } = useResponsiveHelper();
  const [viewOnlyMode, setViewOnlyMode] = useState<boolean>(false);
  const location = useLocation();
  const isAppliedCredit = location.pathname.includes(DialogRoutes.EDIT_APPLIED_CREDIT);
  const queryParams = new QueryParams();
  const queryTransactionId = queryParams.get(DUPLICATE_MODE_QUERY_PARAMS.transactionId);
  const transactionId = queryTransactionId || paramsTransactionId;
  const editMode = Boolean(transactionId);
  const duplicateMode = Boolean(queryTransactionId);
  const permission: AnyPermissionClearance = {
    permission: ObjectPermission.leasePayments,
    field: editMode ? "edit" : "create"
  };
  const createDepositsPermission: AnyPermissionClearance = {
    permission: ObjectPermission.bankDeposits,
    field: "create"
  };
  const hasCreateDepositsPermission = hasPermission(
    createDepositsPermission.permission,
    createDepositsPermission.field
  );
  const [isBulkMode, setIsBulkMode] = useState<boolean>(false);
  const [viewIndex, setViewIndex] = useState(0);
  const [renderSelectionList, setRenderSelectionList] = useState<boolean>(false);
  const [currentLeaseId, setCurrentLeaseId] = useState<string | undefined>(leaseId);
  const [currentPropertyId, setCurrentPropertyId] = useState<string | undefined>(undefined);
  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);
  const [selectLeaseFilterObj, setSelectLeaseFilterObj] = useState({
    filter_text: ""
  });
  const [showLeaseSelectionError, setShowLeaseSelectionError] = useState<boolean>(false);
  const [leaseSelectionErrorText, setLeaseSelectionErrorText] = useState<string>("");
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    editMode ? DialogState.Show : DialogState.Hidden
  );
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const [loadingDialogSuccessText, setLoadingDialogSuccessText] = useState<string>(transactionCreatedText);
  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState<string>(loadingTransactionDataText);
  const [paymentData, setPaymentData] = useState<LeasePaymentDto | undefined>();
  const [shouldRenderForm, setShouldRenderForm] = useState<boolean>(!editMode);
  const [shouldRenderLines, setShouldRenderLines] = useState<boolean>(true);
  const [showCheckNumberInput, setShowCheckNumberInput] = useState<boolean>(false);
  const [autoApplyPaymentOnCharges, setAutoApplyPaymentOnCharges] = useState(true);

  const [receivedFromTenantDefaultValue, setReceivedFromTenantDefaultValue] = useState<string | undefined>();

  const [depositToAccountDefaultValue, setDepositToAccountDefaultValue] = useState<string | undefined>();

  const [showAutoApplyPaymentOnInt, setShowAutoApplyPaymentOnInt] = useState<boolean>(false);

  const [invalidAmountReceivedErrorMsg, setInvalidAmountReceivedErrorMsg] = useState<string | undefined>();
  const lastSaveButtonSelectedIndex = useRef(0);
  const [receivedFromTenantQueryParams, setReceivedFromTenantQueryParams] = useState<Record<string, string>>({});

  const [depositAccountQueryParams, setDepositAccountQueryParams] = useState<GetAllAccountsQuery>({
    filter_type: AccountType.ASSET_BANK,
    filter_active: true
  });

  const [dialogFrameHeight, setDialogFrameHeight] = useState<number>(CreateOrEditDialogHeight);
  const { showShakeEffect, runShakeEffect } = useShakeEffect();
  const [cancelReversepaymentInProgress, setCancelReversepaymentInProgress] = useState(false);
  const [isEpayment, setIsEpayment] = useState(false);
  const [forceRefreshOnClose, setForceRefreshOnClose] = useState<boolean>(false);

  const [autoDepositDefaultValue, setAutoDepositDefaultValue] = useLocalStorage("autoDepositDefaultValue", "true");
  const [sendPaymentReceiptDefaultValue, setSendPaymentReceiptDefaultValue] = useLocalStorage(
    "sendPaymentReceiptDefaultValue",
    "true"
  );

  const ePayStatusText = useEPayStatusText(paymentData?.ePayInfo?.status);

  const inputPaddingRight = isMobile ? 0 : 20;

  const appliedCreditHelpObject: HelpPanelProps = {
    description: AppStrings.Leases.LeaseTransaction.Payment.AppliedCreditHelpPanel.Description
  };

  const initDefaultDropdownValues = async () => {
    setShouldRenderLines(false);
    const tenants = await leaseTenantsApi.getAll({
      filter_lease: currentLeaseId
    });
    if (tenants?.data?.data?.[0]?.tenant?.id) {
      const tenant = tenants.data.data[0].tenant.id;
      setReceivedFromTenantDefaultValue(tenant);
      if (formikGlobalRef) {
        formikGlobalRef.setFieldValue("receivedFromTenant", tenant);
        formikGlobalRef.setFieldTouched("receivedFromTenant");
      }
    }
    let propertyId = currentPropertyId;
    if (!propertyId && currentLeaseId) {
      const lease = await leasesApi.get(currentLeaseId);
      if (lease?.data?.property) {
        propertyId = lease.data.property;
      }
    }

    if (propertyId) {
      const accounts = await accountsApi.getDefaultAccountsForProperty({
        property: propertyId
      });
      if (accounts.data?.bank_operating) {
        const account = accounts.data.bank_operating;
        setDepositToAccountDefaultValue(account);
        if (formikGlobalRef) {
          formikGlobalRef.setFieldValue("depositToAccount", account);
          formikGlobalRef.setFieldTouched("depositToAccount");
        }
      }
    }
    setShouldRenderLines(true);
  };
  useEffect(() => {
    if (currentLeaseId) {
      setDepositAccountQueryParams({
        filter_type: AccountType.ASSET_BANK,
        filter_lease: currentLeaseId
      });
      setReceivedFromTenantQueryParams({
        filter_lease: currentLeaseId
      });

      if (!editMode && !viewOnlyMode) {
        initDefaultDropdownValues();
      }
    }

    formikGlobalRef?.setFieldValue("lease", currentLeaseId);
    setTimeout(() => {
      if (formikGlobalRef) {
        const errorText = getIn(formikGlobalRef.errors, "lease");
        const touchedVal = getIn(formikGlobalRef.touched, "lease");

        setShowLeaseSelectionError(touchedVal && errorText !== undefined);
        if (errorText !== undefined) {
          // setLeaseSelectionErrorText(errorText);
          setLeaseSelectionErrorText(t(AppStrings.Leases.LeaseCharge.PressToSelectLease));
        }
      }
    }, 0);
  }, [currentLeaseId]);

  useEffect(() => {
    if (editMode) {
      loadPaymentData().then();
    } else {
      setAutoApplyPaymentOnChargesInitValue();
    }
    return () => {
      formikGlobalRef = null;
    };
  }, [duplicateMode]);

  const setAutoApplyPaymentOnChargesInitValue = () => {
    if (formikGlobalRef) {
      const defaultValue = getIn(formikGlobalRef.values, "autoApplyPaymentOnCharges");
      const autoApplyPaymentOnCharges = defaultValue || true;
      const showAutoApplyPaymentOnInt = defaultValue !== undefined && !defaultValue;

      if (!autoApplyPaymentOnCharges || Boolean(showAutoApplyPaymentOnInt)) {
        setDialogFrameHeight(window.innerHeight);
        setTimeout(() => {
          setAutoApplyPaymentOnCharges(autoApplyPaymentOnCharges);
          setShowAutoApplyPaymentOnInt(showAutoApplyPaymentOnInt);
        }, AnimationDelay);
      } else {
        setAutoApplyPaymentOnCharges(autoApplyPaymentOnCharges);
        setShowAutoApplyPaymentOnInt(showAutoApplyPaymentOnInt);
      }
    }
  };

  const initFormvalues = (): LeasePaymentDto => {
    if (editMode && paymentData) {
      return paymentData;
    }

    if (formikGlobalRef !== null) {
      return formikGlobalRef.values;
    }
    const _leasePaymentDto = new LeasePaymentDto();
    _leasePaymentDto.date = moment().format("YYYY-MM-DD").toString();
    _leasePaymentDto.autoApplyPaymentOnCharges = true;
    _leasePaymentDto.checkInfo = new CheckReceivableInfoDto();
    _leasePaymentDto.depositStatus = DepositStatus.UNDEPOSITED;
    _leasePaymentDto.amountReceived = 0;
    _leasePaymentDto.autoDeposit = hasCreateDepositsPermission ? autoDepositDefaultValue === "true" : false;
    _leasePaymentDto.sendPaymentReceipt = sendPaymentReceiptDefaultValue === "true";
    return _leasePaymentDto;
  };

  const isValidForm = async (formikRef: FormikProps<LeasePaymentDto>) => {
    formikRef.setFieldTouched("lease");
    formikRef.setFieldTouched("depositToAccount");
    formikRef.setFieldTouched("amountReceived");
    formikRef.setFieldTouched("paymentMethod");
    formikRef.setFieldTouched("checkInfo.checkNumber");

    const amountReceived = getIn(formikRef.values, "amountReceived") || 0;
    let totalCredits = 0;
    let totalCharges = 0;

    if (!formikRef.values.autoApplyPaymentOnCharges) {
      if (formikRef.values.linkedCharges) {
        formikRef.values.linkedCharges.forEach((currentPayment, index) => {
          totalCharges = mathUtils.add(totalCharges, currentPayment.amount || 0);
          formikRef.setFieldTouched(`linkedCharges[${index}].amount`);
        });
      }
      if (formikRef.values.linkedCredits) {
        formikRef.values.linkedCredits.forEach((currentPayment, index) => {
          totalCredits = mathUtils.add(totalCredits, currentPayment.amount || 0);
          formikRef.setFieldTouched(`linkedCredits[${index}].amount`);
        });
      }
    }

    if (mathUtils.add(amountReceived, totalCredits) < totalCharges || totalCharges < totalCredits) {
      setInvalidAmountReceivedErrorMsg(t(AppStrings.Leases.LeaseTransaction.Payment.InvalidAmountReceived));
      return false;
    }
    const errors = await formikRef.validateForm();
    const inValidLease = Boolean(errors.lease);
    setShowLeaseSelectionError(inValidLease);
    if (inValidLease && formikGlobalRef) {
      const errorText = getIn(formikGlobalRef.errors, "lease");
      if (errorText !== undefined) {
        setLeaseSelectionErrorText(t(AppStrings.Leases.LeaseCharge.PressToSelectLease));
      }
    }
    return _.isEmpty(errors);
  };

  const validateForm = createValidator(LeasePaymentDto);

  const loadPaymentData = async () => {
    if (editMode && transactionId) {
      setLoadingDialogState(DialogState.Show);

      const response = await leasePaymentsApi.get(transactionId).catch((error) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });
      if (response && response.status && response.data) {
        const isEpayment = !_.isEmpty(response.data?.ePayInfo);
        setIsEpayment(isEpayment);
        if (duplicateMode && !isEpayment) {
          response.data = cleanLeasePaymentDataForDuplicateMode({
            leasePayment: response.data,
            autoDeposit: hasCreateDepositsPermission && generalUtils.isTrue(autoDepositDefaultValue || ""),
            sendPaymentReceipt: generalUtils.isTrue(sendPaymentReceiptDefaultValue || "")
          });
        }
        if (
          response.data.ePayInfo !== undefined ||
          response.data.depositStatus == DepositStatus.DEPOSITED ||
          response.data.reversedPayment !== undefined
        ) {
          setViewOnlyMode(true);
          setDialogFrameHeight(ViewOnlyModeDialogHeight);
        } else {
          setViewOnlyMode(false);
          setDialogFrameHeight(CreateOrEditDialogHeight);
        }
        setCurrentLeaseId(response.data.lease);
        setPaymentData(response.data);
        setReceivedFromTenantDefaultValue(response.data.receivedFromTenant);
        setDepositToAccountDefaultValue(response.data.depositToAccount);
        setLoadingDialogSuccessText(loadingTransactionDataSuccessText);
        setLoadingDialogState(DialogState.Success);
        setTimeout(() => {
          setLoadingDialogState(DialogState.Hidden);
          if (response.data?.paymentMethod && ShowCheckNumberMethods.includes(response.data.paymentMethod)) {
            setShowCheckNumberInput(true);
          }
          setShouldRenderForm(true);
          setAutoApplyPaymentOnChargesInitValue();
        }, 500);
      } else {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(response ? response.message : "");
      }
    }
  };

  const handleClose = async (isAddNew?: boolean) => {
    if (isAddNew) {
      setForceRefreshOnClose(true);

      if (formikGlobalRef) {
        setDialogFrameHeight(CreateOrEditDialogHeight);

        await initDefaultDropdownValues();

        formikGlobalRef.setFieldValue("amountReceived", 0);
        formikGlobalRef.setFieldValue("paymentMethod", undefined);
        formikGlobalRef.setFieldValue("autoApplyPaymentOnCharges", true);
        formikGlobalRef.setFieldValue("autoDeposit", false);
        formikGlobalRef.setFieldValue("sendPaymentReceipt", false);
        formikGlobalRef.setFieldValue("checkInfo.checkNumber", undefined);
        formikGlobalRef.setFieldValue("memo", undefined);

        setShowCheckNumberInput(false);
        setAutoApplyPaymentOnCharges(true);
        setLoadingDialogState(DialogState.Hidden);
        setAttachments([]);
        setViewIndex(0);
      }
    } else {
      onClose();
    }
  };

  const uploadFiles = async (id: string, isFilesSharedWithTenant: boolean) => {
    if (isFilesSharedWithTenant !== undefined) {
      attachments.forEach((file) => {
        file.isSharedWithTenant = isFilesSharedWithTenant;
      });
    }

    await filesApi.uploadFiles(attachments, id, LinkedResourceType.LeasePayment).catch((error: string) => {
      setLoadingDialogState(DialogState.Error);
      setLoadingDialogErrorText(error);
    });
  };

  const createTransaction = async (isAddNew?: boolean): Promise<LeasePaymentDto | false> => {
    if (formikGlobalRef) {
      const chargeValues = _.cloneDeep(formikGlobalRef.values);
      setReceivedFromTenantDefaultValue(formikGlobalRef.values.receivedFromTenant);
      setDepositToAccountDefaultValue(formikGlobalRef.values.depositToAccount);
      setViewIndex(2);
      setLoadingDialogState(DialogState.Show);

      if (!attachments.length) {
        chargeValues.isFilesSharedWithTenant = false;
      }

      const results = await leasePaymentsApi.create(chargeValues, { idKey: "lease" }).catch((error) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });

      if (results && results.status && results.data?.id) {
        await uploadFiles(results.data.id, chargeValues.isFilesSharedWithTenant);
        await handleClose(isAddNew);

        return results.data;
      }
      setLoadingDialogErrorText(results ? results.message : "");
      setLoadingDialogState(DialogState.Error);
    }

    return false;
  };

  const updateTransaction = async (): Promise<LeasePaymentDto | false> => {
    if (formikGlobalRef && transactionId) {
      const chargeValues = _.cloneDeep(formikGlobalRef.values);
      setViewIndex(2);
      setShouldRenderForm(false);
      setLoadingDialogState(DialogState.Show);
      setLoadingDialogLoadingText(updatingTransactionText);

      if (!attachments.length) {
        chargeValues.isFilesSharedWithTenant = false;
      }

      const results = await leasePaymentsApi.update(transactionId, chargeValues).catch((error) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });

      if (results && results.status && results.data?.id) {
        await uploadFiles(results.data.id, chargeValues.isFilesSharedWithTenant);
        onClose();

        return results.data;
      }
      setLoadingDialogErrorText(results ? results.message : "");
      setLoadingDialogState(DialogState.Error);
    }

    return false;
  };

  const didPressSaveButton = async (saveButtonIndex?: number, isAddNew?: boolean) => {
    analyticsService.track(SegmentEventTypes.SINGLE_RP_SUBMIT_BUTTON_CLICKED, undefined, {
      trackEventInIntercom: true
    });
    if (formikGlobalRef !== null) {
      const isValid = await isValidForm(formikGlobalRef);
      let dataResults: LeasePaymentDto | false = false;

      if (isValid) {
        if (editMode && !duplicateMode) {
          dataResults = await updateTransaction();
        } else {
          lastSaveButtonSelectedIndex.current = saveButtonIndex || 0;
          dataResults = await createTransaction(isAddNew);
        }

        if (dataResults) {
          dispatchAnalytics("lease_transaction_dialog_saved", {
            transactionType: isAppliedCredit
              ? ClientJournalEntryType.APPLIED_CREDIT
              : ClientJournalEntryType.RECEIVED_PAYMENT,
            isFileAdded: Boolean(attachments.length)
          });
        }
      }
    }
  };
  const didChangePaymentMethod = (nextValue: string) => {
    if (ShowCheckNumberMethods.includes(nextValue as PaymentMethod)) {
      setShowCheckNumberInput(true);
    } else {
      setShowCheckNumberInput(false);
      formikGlobalRef && formikGlobalRef.setFieldValue("checkInfo.checkNumber", undefined);
    }
  };

  const renderEPayInfo = () => {
    if (viewOnlyMode) {
      return <EPayInfoView ePayInfo={paymentData?.ePayInfo} />;
    }
  };

  const renderLines = () => {
    const paymentMethodValue = formikGlobalRef?.values?.paymentMethod;

    return (
      <View flexDirection={"row"}>
        <Grid container alignItems={"flex-start"} item xs={12} sm={4} md={4}>
          <FormikCachedAsyncAutoComplete
            uniqueIndex={"TS"}
            apiHandler={tenantsApi}
            displayNameKey={"name"}
            filterFieldName={"filter_text"}
            filterFieldValue={"name"}
            selectionFields={["id", "class"]}
            name={"receivedFromTenant"}
            queryParams={receivedFromTenantQueryParams}
            label={t(AppStrings.Leases.LeaseTransaction.Payment.ReceivedFrom)}
            defaultValue={receivedFromTenantDefaultValue}
            viewOnly={viewOnlyMode}
            marginTop={20}
            paddingRight={inputPaddingRight}
          />
        </Grid>

        <Grid container alignItems={"flex-start"} item xs={12} sm={4} md={viewOnlyMode ? 6 : 4}>
          <BankAccountFormikAutoCompleteField
            uniqueIndex={"TS"}
            name={"depositToAccount"}
            queryParams={depositAccountQueryParams}
            label={t(AppStrings.Leases.LeaseTransaction.Payment.DepositAccount)}
            defaultValue={depositToAccountDefaultValue}
            viewOnly={viewOnlyMode}
            marginTop={20}
            paddingRight={inputPaddingRight}
          />
        </Grid>
        <Grid container alignItems={"flex-start"} xs={12} sm={4} md={viewOnlyMode ? 2 : 4} item>
          <FastFieldSafe
            component={TextField}
            name={"amountReceived"}
            required
            formatType={"currency"}
            label={t(AppStrings.Leases.LeaseTransaction.Payment.AmountReceived)}
            viewOnly={viewOnlyMode}
            marginTop={viewOnlyMode ? 0 : 20}
          />
        </Grid>
        {((viewOnlyMode && paymentMethodValue) || !viewOnlyMode) && (
          <Grid container alignItems={"flex-start"} item xs={12} sm={4} md={4}>
            <Field
              component={Select}
              name={`paymentMethod`}
              label={AppStrings.Leases.LeaseTransaction.Payment.PaymentMethod}
              uniqueKey={"paymentMethod"}
              selectionEnum={viewOnlyMode ? PaymentMethod : PaymentMethodNoEpay}
              onChange={didChangePaymentMethod}
              translationKey={"paymentMethod"}
              viewOnly={viewOnlyMode}
              viewOnlyExtraValue={ePayStatusText ? ` - ${ePayStatusText}` : ""}
              marginTop={20}
              paddingRight={inputPaddingRight}
            />
          </Grid>
        )}

        {showCheckNumberInput ? (
          <Grid container alignItems={"flex-start"} item xs={12} sm={4} md={viewOnlyMode ? 6 : 4}>
            <Field
              component={TextField}
              label={t(AppStrings.Leases.LeaseTransaction.Payment.CheckNumber)}
              name={"checkInfo.checkNumber"}
              viewOnly={viewOnlyMode}
              marginTop={20}
              paddingRight={inputPaddingRight}
            />
          </Grid>
        ) : null}
        {renderEPayInfo()}
      </View>
    );
  };

  const didChangeAutoApplyPaymentOnCharges = (nextValue: boolean) => {
    analyticsService.track(SegmentEventTypes.SINGLE_RP_AUTOMATICALLY_PAY_TOGGLE_CLICKED);
    if (nextValue) {
      setDialogFrameHeight(viewOnlyMode ? ViewOnlyModeDialogHeight : CreateOrEditDialogHeight);
      setTimeout(() => {
        setAutoApplyPaymentOnCharges(nextValue);
        setShowAutoApplyPaymentOnInt(false);
      }, AnimationDelay);
    } else {
      setDialogFrameHeight(window.innerHeight);
      setTimeout(() => {
        setAutoApplyPaymentOnCharges(nextValue);
        setShowAutoApplyPaymentOnInt(false);
      }, AnimationDelay);
    }
  };

  const didPressCancelReversePayment = async () => {
    if (cancelReversepaymentInProgress) {
      runShakeEffect();
      return;
    }
    if (paymentData?.reversedPayment) {
      setCancelReversepaymentInProgress(true);
      const reversedPaymentsResponse = await leaseReversedPaymentsApi.delete(paymentData?.reversedPayment).catch(() => {
        runShakeEffect();
        setCancelReversepaymentInProgress(false);
      });
      if (reversedPaymentsResponse && reversedPaymentsResponse.status) {
        setPaymentData({
          ...paymentData,
          reversedPayment: undefined
        });
        if (paymentData?.ePayInfo !== undefined || paymentData?.depositStatus == DepositStatus.DEPOSITED) {
          setViewOnlyMode(true);
          setDialogFrameHeight(ViewOnlyModeDialogHeight);
        } else {
          setViewOnlyMode(true);
          setDialogFrameHeight(CreateOrEditDialogHeight);
        }
        setCancelReversepaymentInProgress(false);
        onClose(null, true);
      } else {
        runShakeEffect();
        setCancelReversepaymentInProgress(false);
      }
    } else {
      runShakeEffect();
      setCancelReversepaymentInProgress(false);
    }
  };

  const didPressViewDeposit = () => {
    if (paymentData?.depositEntry) {
      NavigationManager.viewDepositFromDialog(paymentData.depositEntry, DialogRoutes.EDIT_PAYMENT);
    } else {
      runShakeEffect();
    }
  };

  const renderNotificationActionPanel = () => {
    if (
      paymentData?.reversedPayment &&
      paymentData.paymentMethod !== PaymentMethod.EPAY // can't cancel epay reversed payments..
    ) {
      return (
        <ShakeEffectView showEffect={showShakeEffect}>
          <View alignItems={"center"}>
            <DLButton
              actionText={AppStrings.Common.Cancel}
              onClick={didPressCancelReversePayment}
              color={DLButtonColorsEnum.SECONDARY}
              size={DLButtonSizesEnum.LARGE}
              style={{ minWidth: 180 }}
              isLoading={cancelReversepaymentInProgress}
            />
          </View>
        </ShakeEffectView>
      );
    }
    if (paymentData?.depositStatus === DepositStatus.DEPOSITED) {
      return (
        <ShakeEffectView showEffect={showShakeEffect}>
          <View alignItems={"center"}>
            <DLButton
              actionText={AppStrings.Common.ViewDeposit}
              onClick={didPressViewDeposit}
              color={DLButtonColorsEnum.SECONDARY}
              size={DLButtonSizesEnum.LARGE}
              style={{ minWidth: 180 }}
              isLoading={cancelReversepaymentInProgress}
            />
          </View>
        </ShakeEffectView>
      );
    }
    return <div />;
  };

  const renderViewOnlyNotification = () => {
    if (viewOnlyMode) {
      let notificationTitle = AppStrings.Common.ViewOnlyPaymentTitle;
      if (paymentData?.depositStatus === DepositStatus.DEPOSITED) {
        notificationTitle = AppStrings.Common.PaymentHasBeenDeposited;
      }
      if (paymentData?.reversedPayment) {
        notificationTitle = t(AppStrings.Common.PaymentHasBeenReversed) + " ";
        if (paymentData?.reversedPaymentDate) {
          notificationTitle += t(AppStrings.Common.On) + " ";
          notificationTitle += moment(paymentData?.reversedPaymentDate).format("YYYY-MM-DD").toString();
        }
      }
      return (
        <TenantPortalNotificationPanel
          title={notificationTitle}
          notificationText={paymentData?.reversedPaymentMemo || ""}
          icon={ErrorSignIcon}
          hideBoxShadow
          marginTop={20}
          borderRadius={10}
          border={"1px solid #F2C94C"}
          minHeight={50}
          renderActionPanel={renderNotificationActionPanel}
        />
      );
    }
  };

  const onFileReceived = (files: FileListItemProps[]) => {
    analyticsService.track(SegmentEventTypes.SINGLE_RP_UPLOAD_FILE_CLICKED);
    setAttachments(files);
  };

  const renderAttachments = () => (
    <View justifyContent={"flex-end"} width={"100%"} marginTop={10} marginBottom={20}>
      <FormAttachments
        editMode={editMode}
        resourceType={LinkedResourceType.LeasePayment}
        resourceId={paymentData ? paymentData.id : undefined}
        onFileReceived={onFileReceived}
        files={attachments}
        filesSharedFormikFieldName={"isFilesSharedWithTenant"}
      />
    </View>
  );

  const didPressSelectLease = () => {
    setDialogFrameHeight(window.innerHeight);
    setTimeout(() => {
      setViewIndex(1);
      setTimeout(() => {
        setRenderSelectionList(true);
      }, AnimationDelay + 200);
    }, AnimationDelay);
  };

  const renderFormTopSection = () => (
    <View marginTop={20} flexDirection={"row"} noWrap={!isMobile} gap={20}>
      <Grid item xs={12} sm={6}>
        <LeaseSelection
          didPressSelectLease={didPressSelectLease}
          leaseId={currentLeaseId}
          errorTex={showLeaseSelectionError ? leaseSelectionErrorText : undefined}
          viewOnly={viewOnlyMode}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Field
          component={FormikDatePicker}
          uniqueKey={"chargeDueDate"}
          label={AppStrings.Tasks.Screen.Date}
          name={"date"}
          noMargin
          required
          //viewOnly={viewOnlyMode}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Field
          component={FormikReferenceLabel}
          name={"reference"}
          backgroundColor={"dark"}
          valueTextTransform={"uppercase"}
          viewOnly={viewOnlyMode}
        />
      </Grid>
    </View>
  );

  const renderNotes = () => (
    <View
      marginTop={20}
      justifyContent={"center"}
      onClick={() => analyticsService.track(SegmentEventTypes.SINGLE_RP_ADD_NOTES_FIELD_CLICKED)}
    >
      <FastFieldSafe dto={LeasePaymentDto} component={Notes} height={30} name={"memo"} />
    </View>
  );

  const onListItemPress = (leaseId: string, currentItem: LeaseDto) => {
    let autoApplyPaymentOnCharges = true;
    if (formikGlobalRef) {
      autoApplyPaymentOnCharges = getIn(formikGlobalRef.values, "autoApplyPaymentOnCharges");
    }
    setDialogFrameHeight(autoApplyPaymentOnCharges ? CreateOrEditDialogHeight : window.innerHeight);
    setTimeout(() => {
      setViewIndex(0);
      setCurrentPropertyId(currentItem.property);
      setCurrentLeaseId(leaseId);
    }, AnimationDelay);
  };

  const renderAutoApplyPaymentAllocation = (formikRef: FormikProps<any>) => (
    <OutstandingTransactionsList
      shouldLoad={!autoApplyPaymentOnCharges || showAutoApplyPaymentOnInt}
      leaseId={currentLeaseId}
      leasePaymentId={duplicateMode ? undefined : transactionId}
      amountReceived={formikGlobalRef ? getIn(formikGlobalRef.values, "amountReceived") : 0}
      formikRef={formikRef}
    />
  );

  const renderValidationMessage = () => (
    <View alignItems={"flex-end"}>
      <ValidationIndicator
        shouldShow={invalidAmountReceivedErrorMsg !== undefined}
        fullWidth
        displayText={invalidAmountReceivedErrorMsg || ""}
        marginTop={"0px"}
        wrapperWidth={"96%"}
      />
    </View>
  );

  const handleSetAutoDepositDefaultValue = () => {
    setAutoDepositDefaultValue(formikGlobalRef?.values.autoDeposit);
  };

  const handleSetSendPaymentReceiptDefaultValue = () => {
    setSendPaymentReceiptDefaultValue(formikGlobalRef?.values.sendPaymentReceipt);
  };

  const renderAutoDepositSwitch = () => (
    <View marginTop={20}>
      <SetAsDefaultField
        component={FormikSwitchButton}
        viewProps={{ gap: 12 }}
        onSetAsDefaultClick={handleSetAutoDepositDefaultValue}
        onChange={() => analyticsService.track(SegmentEventTypes.SINGLE_RP_AUTOMATICALLY_DEPOSIT_CHECKBOX_CLICKED)}
        dto={LeasePaymentDto}
        name={"autoDeposit"}
        label={AppStrings.Leases.LeaseTransaction.Payment.AutomaticDepositCreation}
        dataCy={DataCy.transactions.autoDepositFundsSwitch}
      />
    </View>
  );

  const renderSendPaymentReceiptSwitch = () => (
    <View marginTop={20}>
      <SetAsDefaultField
        component={FormikSwitchButton}
        onSetAsDefaultClick={handleSetSendPaymentReceiptDefaultValue}
        dto={LeasePaymentDto}
        onChange={() => analyticsService.track(SegmentEventTypes.SINGLE_RP_SEND_PAYMENT_RECEIPT_CHECKBOX_CLICKED)}
        name={"sendPaymentReceipt"}
        label={AppStrings.Leases.LeaseTransaction.Payment.SendPaymentReceipts}
        marginRight={4}
        dataCy={DataCy.transactions.emailPaymentReceiptSwitch}
      />
    </View>
  );

  const renderAutoPaySwitch = () => (
    <View marginTop={20}>
      <FastFieldSafe
        component={FormikSwitchButton}
        dto={LeasePaymentDto}
        name={"autoApplyPaymentOnCharges"}
        label={AppStrings.Leases.LeaseTransaction.Payment.AutomaticPaymentAllocation}
        onChange={didChangeAutoApplyPaymentOnCharges}
        dataCy={DataCy.transactions.autoApplyPaymentsSwitch}
      />
    </View>
  );

  const renderForm = () => {
    const formInitialValues = initFormvalues();
    return (
      <Formik initialValues={formInitialValues} onSubmit={() => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;
          return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <View paddingLeft={screenContainerPadding} paddingRight={screenContainerPadding} flexDirection={"column"}>
                <RestrictedPermissionAccess clearance={permission} showNoAccess>
                  <ReconciledNotificationView register={paymentData?.register} />
                  {renderViewOnlyNotification()}

                  <View flexDirection={"row"}>{renderFormTopSection()}</View>

                  {!isAppliedCredit && (
                    <>
                      <SeparationLine marginTop={20} width={"100%"} height={1} />

                      {shouldRenderLines ? (
                        renderLines()
                      ) : (
                        <View marginTop={20} alignItems={"center"}>
                          <Lottie loop={true} animationData={spinner} width={50} height={50} />
                        </View>
                      )}

                      <HorizontalSeparationLine marginTop={20} height={1} />
                      {renderAutoPaySwitch()}
                      <RestrictedPermissionAccess clearance={createDepositsPermission}>
                        {paymentData?.depositEntry || viewOnlyMode || (editMode && !duplicateMode)
                          ? null
                          : renderAutoDepositSwitch()}
                      </RestrictedPermissionAccess>
                      {viewOnlyMode || (editMode && !duplicateMode) ? null : renderSendPaymentReceiptSwitch()}
                      <HorizontalSeparationLine height={1} marginTop={20} />
                    </>
                  )}
                  {renderNotes()}

                  {renderAutoApplyPaymentAllocation(formik)}
                  {renderValidationMessage()}
                  {renderAttachments()}
                </RestrictedPermissionAccess>
                {editMode && <ActivityLabel item={formik.values} />}
              </View>
            </MuiPickersUtilsProvider>
          );
        }}
      </Formik>
    );
  };

  const renderLeaseSelectionList = () => {
    const didChangeSearchQuery = (value: string) => {
      setSelectLeaseFilterObj({
        filter_text: value
      });
    };

    return (
      <View paddingRight={screenContainerPadding} paddingLeft={screenContainerPadding}>
        <View autoWidth>
          <DialogSearchPanel borderRadius={30} onChange={didChangeSearchQuery} />
        </View>

        <View id={"selectableLeaseContainer"} height={getDialogSelectionHeight(dialogFrameHeight)} overflow={"scroll"}>
          {renderSelectionList ? (
            <LeasesList
              ListItem={SelectableLeaseListItem}
              filterObj={selectLeaseFilterObj}
              didPressListItem={onListItemPress}
              stickyHeaderId={"selectableLeaseContainer"}
              scrollableTarget={"selectableLeaseContainer"}
              removeDefaultBottomPadding
              selectableItem
            />
          ) : null}
        </View>
      </View>
    );
  };

  const renderView = ({ index }: any) => {
    const onRetryButtonPress = async () => {
      await didPressSaveButton();
    };
    const didPressDismissButton = () => {
      setShouldRenderForm(true);
      setLoadingDialogState(DialogState.Hidden);
      setDialogFrameHeight(CreateOrEditDialogHeight);
      setTimeout(() => {
        setViewIndex(0);
      }, AnimationDelay);
    };

    if (index === 0) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={loadPaymentData}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }
      if (shouldRenderForm) {
        return renderForm();
      }
      return <div />;
    }
    if (index === 1) {
      return renderLeaseSelectionList();
    }
    if (index === 2) {
      return (
        <View alignItems={"center"} justifyContent={"center"} height={"100%"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
          />
        </View>
      );
    }

    if (index === 3) {
      return (
        <DeleteConfirmation
          apiMethod={leasePaymentsApi}
          didPressDismissButton={didPressDismissButton}
          didFinishOperation={onClose}
          transactionId={transactionId}
          attachments={attachments}
        />
      );
    }
    return <div />;
  };

  const _onBackdropClick = () => {
    analyticsService.track(SegmentEventTypes.SINGLE_RP_CLOSE_BUTTON_CLICKED);
    if (viewIndex === 1) {
      if (dialogFrameHeight === window.innerHeight) {
        setDialogFrameHeight(CreateOrEditDialogHeight);
        setTimeout(() => {
          setViewIndex(0);
        }, AnimationDelay);
      } else {
        setViewIndex(0);
      }
      return;
    }
    if (onBackdropClick && !forceRefreshOnClose) {
      onBackdropClick();
    } else {
      onClose();
    }
  };

  const handleDeleteClick = () => {
    setViewIndex(3);
  };
  const handleDuplicateClick = () => {
    queryParams.set(DUPLICATE_MODE_QUERY_PARAMS.transactionId, transactionId);
    queryParams.historyPush();

    dispatchAnalytics("button_click", {
      label: DuplicateDialogButtonLabelValues.DUPLICATE_JOURNAL_ENTRY
    });
  };

  const { printJournalEntry, printLoadingStatus } = useJournalEntryPrinting({ journalEntryId: transactionId });

  const renderHeaderActionButtons = () => (
    <DialogHeaderActionButtons
      onDeleteClick={handleDeleteClick}
      onDuplicateClick={handleDuplicateClick}
      clearance={permission}
      hideDeleteButton={!editMode || duplicateMode}
      hideDuplicateButton={isEpayment || isAppliedCredit || duplicateMode || !editMode}
      onPrintClick={editMode ? printJournalEntry : undefined}
      printLoadingStatus={printLoadingStatus}
    />
  );

  const renderActionPanelButtons = () => (
    <FormActionButtons
      clearance={permission}
      propsActionPanel={{
        editMode: editMode && !viewOnlyMode
      }}
      propsSubButton={{ onClick: _onBackdropClick }}
      propsMainButton={
        editMode && !duplicateMode
          ? { type: "cta", props: { onClick: async () => await didPressSaveButton() } }
          : {
              type: "split",
              props: {
                lastSaveButtonIndex: lastSaveButtonSelectedIndex.current,
                options: [
                  { text: AppStrings.Common.Save, onClick: didPressSaveButton },
                  {
                    text: AppStrings.Common.SaveAndNew,
                    onClick: async (saveButtonIndex) => await didPressSaveButton(saveButtonIndex, true)
                  }
                ]
              }
            }
      }
    />
  );

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }
    if (viewIndex === 1) {
      return "topPanel";
    }
    if (viewIndex === 3 || viewIndex === 2) {
      return "contentOnly";
    }
    return "sectionTitleFrame";
  }, [viewIndex, loadingDialogState]);

  const currentTitle = useMemo(() => {
    if (viewIndex === 0) {
      return dialogTitle;
    }

    if (viewIndex === 1) {
      return AppStrings.Leases.LeaseCharge.SelectLease;
    }
    return "";
  }, [viewIndex]);

  const duplicateModeTitle = useMemo(
    () =>
      queryTransactionId
        ? `${t(AppStrings.Leases.LeaseCharge.OneTimePayment)} (${t(AppStrings.Common.Duplicated)})`
        : "",
    [queryTransactionId, t]
  );

  const dialogTitleActions = useMemo(() => {
    if (editMode || viewOnlyMode) {
      return;
    }

    const bulkReceivePaymentsFeatureFlag =
      Feature.isActive("BulkReceivePayments") &&
      !shouldBlockFeatureForSubscriptionPriceId(ObjectPermission.bulkOperations, subscriptionPriceId);

    if (!bulkReceivePaymentsFeatureFlag) {
      return;
    }

    const onSwitchToBulkReceivePayments = () => {
      analyticsService.track(SegmentEventTypes.SINGLE_RP_SWITCH_MODE_TO_BULK);
      setIsBulkMode(true);
    };

    return (
      <RestrictedDeviceAccess hideDisplayView excludedDevices={["tabletOrMobile"]}>
        <DialogTitleActionButton
          onClick={onSwitchToBulkReceivePayments}
          text={AppStrings.Leases.LeaseTransaction.Payment.SwitchToBulkReceivePayments}
        />
      </RestrictedDeviceAccess>
    );
  }, []);

  if (isBulkMode) {
    return <BulkPayment onBackdropClick={onBackdropClick} onClose={onClose} setIsBulkMode={setIsBulkMode} />;
  }

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={duplicateModeTitle || currentTitle}
      width={dialogWidth}
      height={dialogFrameHeight}
      titleActions={dialogTitleActions}
      renderView={renderView}
      numViews={4}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      RenderHeaderActionButtons={renderHeaderActionButtons}
      frameType={frameType}
      helpPanel={isAppliedCredit ? appliedCreditHelpObject : !isHOAUser ? helpObject : helpObjectHOA}
    />
  );
};

export default PaymentDialog;
