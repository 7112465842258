import React, { useState } from "react";

import _ from "lodash";
import { FastField, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import type { CommunicationCenterSettingsDto } from "@doorloop/dto";
import { UserStatus } from "@doorloop/dto";

import AppStrings from "locale/keys";
import FormikCachedMultiSelectAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedMultiSelectAutoComplete";
import Text from "DLUI/text";
import { FormikSwitchButton, SwitchButton } from "DLUI/form";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";
import { usersApi } from "api/usersApi";
import { View } from "DLUI/view";

interface ComponentProps {
  messagesSettingsKey: keyof Omit<CommunicationCenterSettingsDto, "isPhoneNumberEnabled">;
  messagesSettingsTitle: string;
  assignToUsersLabel: string;
}

export const MessagesSettings: React.FC<ComponentProps> = ({
  messagesSettingsKey,
  messagesSettingsTitle,
  assignToUsersLabel
}) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();

  const formikRef = useFormikContext<CommunicationCenterSettingsDto>();

  const [isAssignToUsers, setIsAssignToUsers] = useState(
    !_.isUndefined(formikRef.values?.[messagesSettingsKey]?.assignToUsers)
  );

  const didChangeAutomaticallyAssignUsers = (checked: boolean): void => {
    if (checked) {
      formikRef.setFieldValue(`${messagesSettingsKey}.assignToUsers`, []);
    } else {
      formikRef.setFieldValue(`${messagesSettingsKey}.assignToUsers`, undefined);
    }

    setIsAssignToUsers(checked);
  };

  return (
    <View width={"100%"} flex={1}>
      <Text bold value={messagesSettingsTitle} marginTop={24} />

      <View>
        <FastField
          component={FormikSwitchButton}
          name={`${messagesSettingsKey}.notifyAutomatically`}
          label={AppStrings.Settings.GeneralSettings.CommunicationCenter.MessagesSettingsNotifyAutomatically}
          marginTop={16}
        />

        <SwitchButton
          checked={isAssignToUsers}
          label={t(assignToUsersLabel)}
          marginTop={16}
          onChange={didChangeAutomaticallyAssignUsers}
        />

        <View showAnimation={"fade-in"} hideAnimation={"fade-out"} shouldShow={isAssignToUsers}>
          <View paddingLeft={isMobile ? 0 : 36} width={isMobile ? "100%" : "50%"}>
            <FormikCachedMultiSelectAutoComplete
              uniqueIndex={`${messagesSettingsKey}.assignToUsers`}
              apiHandler={usersApi}
              displayNameKey={"name"}
              filterFieldName={"filter_text"}
              filterFieldValue={"name"}
              selectionFields={["id", "class"]}
              queryParams={{ filter_status: UserStatus.ACTIVE }}
              name={`${messagesSettingsKey}.assignToUsers`}
              label={t(AppStrings.Common.SelectUsers)}
              marginTop={20}
              width={"100%"}
            />
          </View>
        </View>
      </View>
    </View>
  );
};
