"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsComplexPassword = IsComplexPassword;
const class_validator_1 = require("class-validator");
const strongPasswordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/;
function IsComplexPassword(validationOptions) {
    return function (object, propertyName) {
        (0, class_validator_1.registerDecorator)({
            name: "isComplexPassword",
            target: object.constructor,
            propertyName,
            constraints: [],
            options: validationOptions,
            validator: {
                validate(value) {
                    return Boolean(value) && strongPasswordRegex.test(value);
                },
                defaultMessage(validationArguments) {
                    return ("Your password must have at least 8 characters, including:\n" +
                        "• 1 capital letter\n" +
                        "• 1 lowercase letter\n" +
                        "• 1 number\n" +
                        "• 1 special character (eg: !@#$%)");
                }
            }
        });
    };
}
