import { useContextSelector } from "use-context-selector";
import { FilterChipsContext } from "../context";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { ChipItem } from "../shared/chipItem";
import { DataCy } from "@doorloop/dto";
import { useResponsiveHelper } from "@/contexts/responsiveContext";

const useStyles = makeStyles({
  chipsList: {
    display: "flex",
    gap: 8,
    flexWrap: "wrap",
    alignItems: "center"
  }
});

export interface ChipsProps {
  hiddenOnMobile?: boolean;
}

export function Chips({ hiddenOnMobile = true }: ChipsProps) {
  const classes = useStyles();
  const filterChips = useContextSelector(FilterChipsContext, (context) => context.filterChips);
  const { isMobile } = useResponsiveHelper();

  if (isMobile && hiddenOnMobile) {
    return null;
  }

  return (
    <ul
      className={clsx(classes.chipsList, "border-box", "list-reset")}
      tabIndex={0}
      data-cy={DataCy.DLUI.filters.chipsList}
    >
      {filterChips.map((chip, index) => (
        <ChipItem key={index} chip={chip} />
      ))}
    </ul>
  );
}
