export default {
  calendar: "Calendar",
  occupancyOverview: "Occupancy Overview",
  actionPanel: {
    subscribeToCalendar: "Subscribe to calendar"
  },
  helpPanel: {
    description:
      "A birds-eye view of move ins and outs, and all crucial tasks, so that you can avoid scheduling conflicts and stay on top of all the moving parts.",
    howToUseCalendar: "How to use the calendar",
    howToUseOccupancyOverview: "How to use the occupancy overview",
    howToSubscribeToCalendar: "How to subscribe to your Doorloop calendar"
  },
  views: {
    moreItems: "+{{total}} More...",
    noEventsToDisplay: "No events to display",
    today: "Today"
  },
  filterPanel: {
    leaseFilters: "Lease Filters",
    tasksAndRequestsFilters: "Tasks & Requests Filters",
    workOrdersFilters: "Work Orders Filters",
    generalFilters: "General Filters",
    moveIn: "Move In",
    moveOut: "Move Out",
    eventType: "Event Type"
  },
  eventDetails: {
    lease: "Lease",
    subject: "Subject",
    description: "Description",
    requestedBy: "Requested by",
    assignees: "Assignees",
    property: "Property",
    moveInDate: "Move-in date",
    moveOutDate: "Move-out date",
    tenants: "Tenants",
    viewLease: "View lease",
    viewTask: "View task",
    viewRequest: "View request",
    viewWorkOrders: "View work orders",
    dueDate: "Due date",
    moveIn: "Move-in",
    moveOut: "Move-out",
    movingInTenants: "Moving-in tenants",
    movingOutTenants: "Moving-out tenants",
    task: "Task",
    ownerRequest: "Owner Request",
    workOrder: "Work Order",
    tenantRequest: "Tenant Request",
    monthToMonth: "Month-to-Month",
    lowPriority: "Low Priority",
    highPriority: "High Priority",
    mediumPriority: "Medium Priority",
    received: "Received",
    completed: "Completed",
    inProgress: "In Progress",
    notStarted: "Not Started",
    archived: "Archived"
  },
  subscription: {
    calendarUrl: {
      subscribeToCalendar: "Subscribe to your Doorloop calendar",
      subscriptionDescription:
        "You can enable other calendars and applications to display your Doorloop calendar. <learn-link>Learn more</learn-link>",
      copyCalendarUrl:
        "Doorloop calendar link:<br/><wrapper>Copy and paste the link below into other applications</wrapper>",
      resetLink:
        "You can reset this link address and make the current one invalid. <reset-link>Reset link</reset-link>",
      copyLinkSuccessful: "Your Doorloop calendar link has been copied to clipboard."
    },
    resetUrl: {
      resetCalendarLink: "Reset calendar link?",
      description: "This will invalidate the existing calendar link. Are you sure you want to reset the calendar link?",
      cancel: "Cancel",
      reset: "Reset",
      successfulReset: "Your Doorloop calendar link has been reset."
    }
  }
};
