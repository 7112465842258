import React from "react";
import { usersApi } from "api/usersApi";
import type { DialogState } from "DLUI/dialogs/loadingDialog";
import { AnimatedContentDialog } from "DLUI/dialogs";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import { initFormValues, validateForm, validateUserForm } from "./formikHelper";
import { getUserFormMenuItems } from "./menuItems";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import { useParams } from "react-router-dom";
import { ObjectPermission } from "@doorloop/dto";

interface ComponentProps {
  dialogState: DialogState;
  onClose: () => void;
  onBackdropClick: () => void;
  title?: string;
}

const UserDialog: React.FC<ComponentProps> = ({ dialogState, onClose, onBackdropClick, title }: ComponentProps) => {
  const currentUserId = useSelector((state: IRootState) => state.auth.currentLoginResponse?.id);
  const { userId } = useParams<Record<string, string>>();
  const isEditingCurrentUser = currentUserId === userId;

  const { t } = useTranslation();
  const newUserDialogItems = getUserFormMenuItems(isEditingCurrentUser);
  return (
    <AnimatedContentDialog
      dialogState={dialogState}
      onClose={onClose}
      sectionItems={newUserDialogItems}
      disableBackdropClick
      onBackdropClick={onBackdropClick}
      getformikInitialValues={initFormValues}
      formikValidation={validateForm}
      validationMethod={validateUserForm}
      loadingText={t(AppStrings.Users.NewUser.LoadingText)}
      errorText={t(AppStrings.Users.NewUser.ErrorText)}
      successText={t(AppStrings.Users.NewUser.SuccessText)}
      apiMethod={usersApi}
      toasts={{ POST: { action: { translationKey: AppStrings.Users.NewUser.SetUserRoles } } }}
      dialogTitle={title || AppStrings.Users.Screen.AddNewUser}
      dialogHeight={870}
      permission={ObjectPermission.users}
    />
  );
};

export default UserDialog;
