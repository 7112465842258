import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { settingsApi } from "api/settingsApi";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import AppStrings from "../../../../locale/keys";
import { useTranslation } from "react-i18next";
import { View } from "DLUI/view";
import {
  createValidator,
  ManagementFeesCompanySettingsClientDto,
  PayOutManagementAccounts,
  SettingPermission
} from "@doorloop/dto";
import { ManagementFeesSettingsDialog } from "screens/settings/managementFees/managementFeesSettingsDialog";
import { v4 as uuid } from "uuid";
import { validateManagementFeeForm } from "screens/settings/managementFees/formElements/utils";
import { FormDefaultValues } from "DLUI/dialogs/settings/properties/managementFeesPropertyFormikContextWrapper";
import _ from "lodash";
import { usePermission } from "screens/settings/userRoles/usePermission";
import { buildViewOrEditSettingsClearances } from "screens/settings/userRoles/clearanceTypes";
import { RestrictedAccessHasAny } from "screens/settings/userRoles/restrictedAccessHasAny";

export const ManagementFeesDialogFrameHeight = Math.min(window.innerHeight - 80, 1200);
export const ManagementFeesDialogFrameWidth = Math.min(window.innerWidth, 1105);

interface Props {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
}

const validateForm = createValidator(ManagementFeesCompanySettingsClientDto);

const ManagementFeesFormikContextWrapper: React.FC<Props> = ({ onClose, onBackdropClick, dialogTitle }: Props) => {
  const { t } = useTranslation();
  const { hasAnyPermission } = usePermission();
  const hasViewOrEditPermission = hasAnyPermission(buildViewOrEditSettingsClearances(SettingPermission.managementFees));
  const [settingsData, setSettingsData] = useState<ManagementFeesCompanySettingsClientDto>(
    new ManagementFeesCompanySettingsClientDto({ ...FormDefaultValues.fees })
  );

  const [dialogState, setDialogState] = useState<DialogState>(DialogState.Show);
  const [errorText, setErrorText] = useState<string>("");

  const showErrorMessage = (error: string) => {
    setDialogState(DialogState.Error);
    setErrorText(error);
  };

  const loadData = async () => {
    const { data, message, status } = await settingsApi.getManagementFeesSettings();
    if (data && status) {
      const payOutAccounts: PayOutManagementAccounts[] =
        data.managementAccounts?.map(
          (account) =>
            new PayOutManagementAccounts({
              incomeAccount: account,
              uniqueKey: uuid()
            })
        ) ?? [];
      const initData = _.isEmpty(data) ? FormDefaultValues.fees : data;
      setSettingsData(
        new ManagementFeesCompanySettingsClientDto({
          ...initData,
          payOutAccounts
        })
      );
      setDialogState(DialogState.Hidden);
    } else {
      setDialogState(DialogState.Hidden);
      showErrorMessage(message || t(AppStrings.Common.GeneralError));
    }
  };

  useEffect(() => {
    hasViewOrEditPermission && loadData();
  }, []);

  if (dialogState !== DialogState.Hidden) {
    return (
      <View width={ManagementFeesDialogFrameWidth} height={ManagementFeesDialogFrameHeight} backgroundColor={"light"}>
        <RestrictedAccessHasAny
          showNoAccess
          permissions={buildViewOrEditSettingsClearances(SettingPermission.managementFees)}
        >
          <View
            width={ManagementFeesDialogFrameWidth}
            height={ManagementFeesDialogFrameHeight}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <LoadingDialog
              dialogState={dialogState}
              errorText={errorText}
              didPressDismissButton={onBackdropClick}
              onRetryButtonPress={loadData}
              minHeight={ManagementFeesDialogFrameHeight}
            />
          </View>
        </RestrictedAccessHasAny>
      </View>
    );
  }

  return (
    <Formik initialValues={settingsData} onSubmit={() => {}} validate={validateForm}>
      <ManagementFeesSettingsDialog
        onBackdropClick={onBackdropClick}
        dialogTitle={dialogTitle}
        onClose={onClose}
        validateForm={validateManagementFeeForm}
        apiMethod={async (values) => {
          const _values = {
            ...values
          } as ManagementFeesCompanySettingsClientDto;
          _values.managementAccounts = [];
          _values.payOutAccounts?.forEach(
            ({ incomeAccount }) => incomeAccount && _values.managementAccounts?.push(incomeAccount)
          );
          delete _values.payOutAccounts;
          return await settingsApi.updateManagementFeesSettings(_values as ManagementFeesCompanySettingsClientDto);
        }}
      />
    </Formik>
  );
};

export default ManagementFeesFormikContextWrapper;
