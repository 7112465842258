import React, { useMemo } from "react";
import { AllocationPercentageTableColumns, AllocationPercentageTableColumnsWithDisabledInputs } from "./tableColumns";
import { EditableTable } from "DLUI/editableTable/editableTable";
import EmptyDataView from "DLUI/emptyDataView";
import AppStrings from "locale/keys";
import { useFormikContext } from "formik";
import { View } from "DLUI/view";
import type { CustomAllocationDto } from "@doorloop/dto";
import { AllocationStrategy } from "@doorloop/dto";

interface ComponentProps {
  strategy: AllocationStrategy;
}

export const AllocationPercentageTable = ({ strategy }: ComponentProps) => {
  const {
    values: { allocations }
  } = useFormikContext<CustomAllocationDto>();

  const isManualStrategy = strategy === AllocationStrategy.MANUAL;

  const totalPercentage = useMemo(
    () => (allocations.reduce((acc, curr) => Number(acc) + Number(curr.percentage ?? 0), 0) || 0).toString(),
    [allocations]
  );

  const columns = useMemo(() => {
    if (isManualStrategy) {
      return AllocationPercentageTableColumns;
    }
    return AllocationPercentageTableColumnsWithDisabledInputs;
  }, [strategy]);

  return (
    <View borderRadius={20} backgroundColor={"white"}>
      <EditableTable
        singularItemName={AppStrings.Common.Unit}
        pluralItemName={AppStrings.Common.Units}
        isLoading={false}
        data={allocations}
        columns={columns}
        headerRowHeight={50}
        checkboxSize={25}
        rowHeight={isManualStrategy ? 55 : 45}
        fontSize={14}
        onSelectionChanged={() => {}}
        onSelectAllClicked={() => {}}
        selectedIndices={[]}
        totalDisplayFormatType={"percent"}
        customTotalLabel={AppStrings.Allocations.TotalAllocation}
        totalDisplayAmount={totalPercentage}
        noDataComponent={
          <EmptyDataView defaultHeight={500} instructionsText={AppStrings.Units.Screen.EmptydataViewInstructionsText} />
        }
      />
    </View>
  );
};
