import type { KeyboardEventHandler, MouseEventHandler } from "react";
import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import type ColorsEnum from "../../../utils/colorsEnum";
import makeStyles from "./styles";
import "./styles.css";
import Measure from "react-measure";
import type { JustifyContentFlexType } from "DLUI/text/types";
import type { ViewBackgroundColor } from "./colorMappings";
import { ViewBackgroundColorMapping, ViewHoverColorMapping } from "./colorMappings";
import { isCSSVarValue } from "styles/dynamicStyling/dynamicStylesConfig";
import type { CSSVarValue } from "styles/dynamicStyling/dynamicStyles.types";

export type { ViewBackgroundColor } from "./colorMappings";

export interface ViewComponentProps {
  children?: React.ReactNode;
  marginLeft?: number | "auto";
  marginTop?: number | string;
  gap?: number | string;
  marginRight?: number | "auto";
  marginBottom?: number;
  paddingLeft?: number;
  paddingTop?: number;
  paddingRight?: number;
  paddingBottom?: number;
  className?: string | string[];
  flexDirection?: "row" | "column" | "row-reverse" | "column-reverse";
  alignItems?: "center" | "flex-start" | "flex-end" | "stretch";
  justifyContent?: JustifyContentFlexType;
  alignSelf?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "stretch"
    | "base-start"
    | "base-end"
    | "self-start"
    | "self-end"
    | "self-center"
    | "self-stretch"
    | "self-base-start"
    | "self-base-end";
  height?: string | number;
  width?: string | number;
  maxWidth?: string | number;
  maxHeight?: string | number;
  flex?: number | string;
  showAnimation?: "slide-in-left" | "fade-in" | "slide-in-bottom" | "slide-in-top" | "slide-in" | "scale-in";
  hideAnimation?: "slide-out-left" | "slide-out-right" | "fade-out" | "slide-out-bottom" | "scale-out";
  shouldShow?: boolean;
  noWrap?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  style?: React.CSSProperties;
  cursor?: "pointer" | "default" | "auto" | "inherit" | "grab";
  autoWidth?: boolean;
  id?: string;
  minHeight?: number | string;
  borderRadius?: number | CSSVarValue;
  backgroundColor?: ViewBackgroundColor | CSSVarValue;
  backgroundColorEnum?: ColorsEnum;
  minWidth?: number;
  borderTopLeftRadius?: number;
  borderTopRightRadius?: number;
  borderBottomRightRadius?: number;
  borderBottomLeftRadius?: number;
  overflow?: "visible" | "scroll" | "hidden" | "auto";
  overflowWrap?: "anywhere";
  border?: string;
  outline?: string;
  hoverColor?: ViewBackgroundColor;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
  domRef?: React.Ref<HTMLDivElement>;
  fullWidth?: boolean;
  contentEditable?: boolean;
  onBlur?: () => void;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  onMouseOver?: MouseEventHandler<HTMLDivElement>;
  dataCy?: string;
  scrollIntoViewOnAnimation?: boolean;
  dataId?: string;
  disableOnClickCursorChange?: boolean;
  transform?: string;
  role?: string;
  opacity?: number;
}

const View: React.FC<ViewComponentProps> = ({
  children,
  marginLeft,
  marginTop,
  marginBottom,
  className,
  paddingLeft,
  paddingTop,
  paddingRight,
  paddingBottom,
  flexDirection,
  marginRight,
  height,
  width,
  maxWidth,
  maxHeight,
  alignItems,
  showAnimation,
  hideAnimation,
  shouldShow,
  justifyContent,
  alignSelf,
  noWrap,
  overflowWrap,
  onClick,
  style,
  flex,
  cursor,
  autoWidth,
  id,
  minHeight,
  borderRadius,
  backgroundColor,
  backgroundColorEnum,
  minWidth,
  borderTopLeftRadius,
  borderTopRightRadius,
  borderBottomLeftRadius,
  borderBottomRightRadius,
  overflow,
  border,
  outline,
  hoverColor,
  onScroll,
  domRef,
  fullWidth,
  contentEditable,
  gap,
  onBlur,
  onKeyDown,
  onMouseEnter,
  onMouseLeave,
  onMouseOver,
  dataCy,
  dataId,
  scrollIntoViewOnAnimation = false,
  disableOnClickCursorChange,
  transform,
  role,
  opacity,
  ...rest
}: ViewComponentProps) => {
  const classes = makeStyles();
  const animationStartClass = showAnimation ? showAnimation + "-before" : "";
  const [animationClass, setAnimationClass] = useState<string[]>([animationStartClass]);
  const [renderChildren, setRenderChildren] = useState<boolean>(true);
  const [contentHeight, setContentHeight] = useState<number | undefined>();
  const [showContent, setShowContent] = useState<boolean>(false);

  useEffect(() => {
    if (contentHeight) {
      if (shouldShow) {
        if (showAnimation) {
          setAnimationClass([showAnimation]);
        }

        if (scrollIntoViewOnAnimation) {
          setTimeout(() => {
            if (dataId) {
              const element = document.querySelector(`[data-id="${dataId}"]`);
              element?.scrollIntoView({ behavior: "smooth" });
            }
          }, 500);
        }
      } else if (hideAnimation) {
        setAnimationClass([hideAnimation]);
        setTimeout(() => {
          setRenderChildren(false);
        }, 500);
      }
    }
  }, [showContent]);

  useEffect(() => {
    if (contentHeight) {
      setShowContent(shouldShow || false);
    }
  }, [shouldShow]);

  useEffect(() => {
    if (shouldShow && contentHeight && contentHeight > 0) {
      setShowContent(true);
    }
  }, [contentHeight]);

  useEffect(() => {
    if (!renderChildren) {
      setRenderChildren(true);
    }
  }, [renderChildren]);

  const bgIsCSSVar = useMemo(() => isCSSVarValue(backgroundColor), [backgroundColor]);

  const _className = useMemo(() => {
    const results = [
      "DLUI_View",
      classes.flexBase,
      shouldShow && "animatedContainer",
      backgroundColor && !bgIsCSSVar && ViewBackgroundColorMapping[backgroundColor],
      hoverColor && ViewHoverColorMapping[hoverColor]
    ].filter(Boolean);

    // className can be a string or an array of strings
    if (className) {
      results.push(...(Array.isArray(className) ? className : [className]));
    }

    return results;
  }, [className, backgroundColor, hoverColor, shouldShow, overflow]);

  const viewWidth = useMemo(() => {
    let nextWidth = width || "100%";
    if (fullWidth) {
      return "100%";
    }
    if (typeof nextWidth === "number") {
      nextWidth = `${nextWidth}px`;
    }
    if (paddingRight) {
      nextWidth = "calc(" + nextWidth + " - " + paddingRight + "px)";
    }
    if (paddingLeft) {
      nextWidth = "calc(" + nextWidth + " - " + paddingLeft + "px)";
    }
    if ((flex && !width) || autoWidth) {
      nextWidth = "auto";
    }
    return nextWidth;
  }, [width, paddingLeft, paddingRight]);

  const overflowStyle = useMemo(() => {
    const overflowObj: React.CSSProperties = {};
    if (overflowWrap) {
      overflowObj.overflowWrap = overflowWrap;
    }

    if (overflow === "scroll") {
      overflowObj.overflowY = "auto";
    } else if (overflow && ["hidden", "auto"].includes(overflow)) {
      overflowObj.overflow = overflow;
    }

    return overflowObj;
  }, [overflow]);

  const nextHeight = shouldShow && contentHeight ? `${contentHeight}px` : "0px";

  const viewHeight = showAnimation ? "100%" : `${height || "auto"}`;

  const animatableView = () => (
    <div
      className={clsx([..._className, classes.animatedComponentContainer])}
      style={{ ...style, height: nextHeight }}
      id={id}
    >
      <div className={clsx([classes.animatedChildrenWrapper, ...animationClass])} data-id={dataId}>
        {renderChildren ? (
          <Measure
            bounds
            onResize={(contentRect: any) => {
              if (viewHeight !== contentRect.bounds.height) {
                setContentHeight(contentRect.bounds.height);
              }
            }}
          >
            {({ measureRef }: any) => (
              <div
                style={{
                  ...styleProps,
                  flexWrap: noWrap ? "nowrap" : "wrap"
                }}
                ref={measureRef}
              >
                {children}
              </div>
            )}
          </Measure>
        ) : null}
      </div>
    </div>
  );

  const styleProps = {
    marginLeft: marginLeft || 0,
    marginTop: marginTop || 0,
    marginRight: marginRight || 0,
    paddingLeft: paddingLeft || 0,
    paddingRight: paddingRight || 0,
    paddingTop: paddingTop || 0,
    paddingBottom: paddingBottom || 0,
    flexDirection: flexDirection || "column",
    marginBottom: marginBottom || 0,
    height: height || "auto",
    width: viewWidth,
    alignItems: alignItems || "flex-start",
    justifyContent: justifyContent || "flex-start",
    alignSelf: alignSelf || "initial",
    maxWidth: maxWidth || "none",
    maxHeight: maxHeight || "none",
    flex: flex || "none",
    cursor: onClick && !disableOnClickCursorChange ? "pointer" : cursor || "inherit",
    minHeight: minHeight || "auto",
    borderRadius: borderRadius || 0,
    minWidth: minWidth || "none",
    borderTopLeftRadius: borderTopLeftRadius || borderRadius || 0,
    borderTopRightRadius: borderTopRightRadius || borderRadius || 0,
    borderBottomRightRadius: borderBottomRightRadius || borderRadius || 0,
    borderBottomLeftRadius: borderBottomLeftRadius || borderRadius || 0,
    gap: typeof gap === "number" ? `${gap}px` : gap ?? "unset",
    backgroundColor: backgroundColorEnum || (bgIsCSSVar ? backgroundColor : undefined),
    flexWrap: noWrap ? "nowrap" : "wrap",
    opacity,
    transform,
    border,
    ...overflowStyle
  } as const;

  if (shouldShow !== undefined) {
    return animatableView();
  }

  return (
    <div
      role={role}
      onClick={onClick}
      onKeyDown={onKeyDown}
      style={{ ...styleProps, ...style }}
      contentEditable={contentEditable}
      suppressContentEditableWarning={true}
      onBlur={onBlur}
      className={clsx(_className)}
      id={id}
      onScroll={onScroll}
      ref={domRef}
      data-cy={dataCy}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      {...rest}
    >
      {children}
    </div>
  );
};

export default View;
