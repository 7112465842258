import { ActionPanel } from "DLUI/dialogs";
import type { FC, FunctionComponent } from "react";
import React, { Fragment } from "react";
import { useReadOnlyContext } from "contexts/readOnlyContext";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import AppStrings from "locale/keys";
import type { DLButtonProps } from "DLUI/button/dlButton";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import type { ActionPanelProps } from "DLUI/dialogs/components/actionPanel";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import type { SplitButtonProps } from "DLUI/button/splitButton/splitButton";
import SplitButton from "DLUI/button/splitButton/splitButton";
import { DataCy } from "@doorloop/dto";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";

type ActionContainerProps = Omit<ActionPanelProps, "children">;

interface ActionButtonProps extends Omit<DLButtonProps, "actionText"> {
  actionText?: DLButtonProps["actionText"];
}

interface MainProps {
  type: "cta" | "split";
  Wrapper?: FC;
}

interface CtaProps extends MainProps {
  type: "cta";
  props: ActionButtonProps;
}

interface SplitProps extends MainProps {
  type: "split";
  props: SplitButtonProps;
}

export interface FormActionButtonsProps {
  propsActionPanel?: ActionContainerProps;
  propsMainButton?: CtaProps | SplitProps;
  propsSubButton?: ActionButtonProps;
  clearance?: AnyPermissionClearance;
}

const minWidthMobile = "calc(50% - 30px)";

export const FormActionButtons: FunctionComponent<FormActionButtonsProps> = ({
  propsActionPanel = {},
  propsMainButton,
  propsSubButton,
  clearance
}) => {
  const { isMobile } = useResponsiveHelper();
  const isReadOnlyContext = useReadOnlyContext();

  const WrapperMain = propsMainButton?.Wrapper ?? Fragment;

  return (
    <RestrictedPermissionAccess clearance={clearance}>
      <ActionPanel paddingRight={isMobile ? 0 : 20} noWrap {...propsActionPanel}>
        {!isReadOnlyContext ? (
          <>
            {propsSubButton && (
              <DLButton
                actionText={AppStrings.Common.Cancel}
                variant={DLButtonVariantsEnum.OUTLINED}
                dataCy={DataCy.dialogActionButtons.cancel}
                color={DLButtonColorsEnum.NEUTRAL}
                size={DLButtonSizesEnum.LARGE}
                {...propsSubButton}
                style={
                  isMobile
                    ? {
                        marginLeft: propsMainButton ? 20 : 0,
                        marginRight: 20,
                        minWidth: minWidthMobile,
                        ...(propsSubButton?.style ?? {})
                      }
                    : { marginRight: propsMainButton ? 20 : 0, ...(propsSubButton?.style ?? {}) }
                }
              />
            )}

            {propsMainButton && (
              <WrapperMain>
                {propsMainButton?.type === "cta" ? (
                  <DLButton
                    actionText={AppStrings.Common.Save}
                    dataCy={DataCy.dialogActionButtons.save}
                    size={DLButtonSizesEnum.LARGE}
                    {...propsMainButton.props}
                    style={
                      isMobile
                        ? { marginRight: 20, minWidth: minWidthMobile, ...(propsMainButton?.props.style ?? {}) }
                        : propsMainButton?.props.style ?? {}
                    }
                  />
                ) : (
                  <SplitButton
                    {...propsMainButton.props}
                    dataCy={DataCy.dialogActionButtons.save}
                    styles={
                      isMobile
                        ? {
                            group: { marginRight: 20, minWidth: minWidthMobile },
                            ...(propsMainButton?.props.styles ?? {})
                          }
                        : propsMainButton?.props.styles
                    }
                  />
                )}
              </WrapperMain>
            )}
          </>
        ) : (
          propsSubButton && (
            <DLButton
              actionText={AppStrings.Common.Dismiss}
              variant={DLButtonVariantsEnum.OUTLINED}
              dataCy={DataCy.dialogActionButtons.dismiss}
              color={DLButtonColorsEnum.NEUTRAL}
              size={DLButtonSizesEnum.LARGE}
              {...propsSubButton}
              style={
                isMobile
                  ? { marginRight: 20, minWidth: minWidthMobile, ...(propsSubButton?.style ?? {}) }
                  : propsSubButton?.style ?? {}
              }
            />
          )
        )}
      </ActionPanel>
    </RestrictedPermissionAccess>
  );
};
