import type { FC } from "react";
import React, { useCallback, useMemo } from "react";
import type { BulkExecutionDialogProps } from "DLUI/bulkExecution/bulkExecutionDialog";
import { BulkExecutionDialog } from "DLUI/bulkExecution/bulkExecutionDialog";
import type { useListBulkActions } from "../hooks/useListBulkActions";
import { analyticsService } from "services/analyticsService";
import { SegmentEventTypes } from "@doorloop/dto";
import size from "lodash/size";
import { asyncUtils } from "@doorloop/utils";

type ListBulkActionsDialogProps = Pick<
  ReturnType<typeof useListBulkActions>,
  "actionCount" | "bulkProps" | "setBulkProps" | "toggleAll" | "isTotalAllChecked"
> & {
  refetchMethod?: () => void;
  minHeight?: number;
  timeout?: number;
  queryFilter: Record<string, any>;
  totalAll: number;
};

export const ListBulkActionsDialog: FC<ListBulkActionsDialogProps> = ({
  actionCount,
  bulkProps,
  setBulkProps,
  toggleAll,
  isTotalAllChecked,
  minHeight,
  timeout,
  refetchMethod = () => {
    window.location.reload();
  },
  queryFilter,
  totalAll
}) => {
  const message = useMemo(() => {
    if (!bulkProps?.definition) return "";

    if (actionCount === 1) {
      return bulkProps.definition.confirmMessageOne ?? "";
    }

    return bulkProps.definition.confirmMessageMultiple ?? "";
  }, [actionCount, bulkProps?.definition]);

  const handleFinish = useCallback<NonNullable<BulkExecutionDialogProps["onFinish"]>>(
    (resultAggregate, finishResult) => {
      analyticsService.track(SegmentEventTypes.BULK_OPERATION_FINISHED, {
        bulkOperationName: bulkProps.definition?.actionName,
        numberOfCompleted: size(resultAggregate),
        numberOfFailed: size(finishResult?.failedOperations),
        numberOfSkipped: size(finishResult?.skippedOperations)
      });

      bulkProps.definition?.onFinishAction?.(resultAggregate);
    },
    [bulkProps.definition]
  );

  const handleClose = useCallback<BulkExecutionDialogProps["closeHandler"]>(
    async (refresh) => {
      setBulkProps({ shouldShow: false, operations: [] });

      analyticsService.track(SegmentEventTypes.BULK_OPERATION_DIALOG_CLOSED, {
        bulkOperationName: bulkProps.definition?.actionName
      });

      if (refresh) {
        toggleAll(false);
        await asyncUtils.sleep(100);
        refetchMethod();
      } else if (isTotalAllChecked && bulkProps.definition?.totalAllExecutionRequestType) {
        toggleAll(false);
      }
    },
    [
      toggleAll,
      setBulkProps,
      bulkProps.definition?.actionName,
      refetchMethod,
      isTotalAllChecked,
      bulkProps.definition?.totalAllExecutionRequestType
    ]
  );

  if (!bulkProps.shouldShow || !bulkProps.definition) {
    return null;
  }

  return (
    <BulkExecutionDialog
      operations={bulkProps.operations}
      minHeight={minHeight}
      confirm={{
        header: bulkProps.definition.confirmHeader ?? "",
        message,
        color: bulkProps.definition.dialogTextColor,
        actionName: bulkProps.definition.actionNameTranslated,
        confirmButtonVariant: bulkProps.definition.confirmButtonVariant,
        actionCount: isTotalAllChecked ? totalAll : actionCount
      }}
      onFinish={handleFinish}
      closeHandler={handleClose}
      bulkExecutionDialogTimeout={timeout}
      totalAllExecutionRequestType={bulkProps.definition.totalAllExecutionRequestType}
      isTotalAllChecked={isTotalAllChecked}
      queryFilter={queryFilter}
    />
  );
};
