import type React from "react";
import { useRef } from "react";

export const useFocusElement = () => {
  const elementRef = useRef<HTMLElement>(null);
  const handleFocusElement = (e: React.MouseEvent): void => {
    e.stopPropagation();
    elementRef.current?.click();
  };

  return {
    elementRef,
    handleFocusElement
  };
};
