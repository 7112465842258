import { lazy } from "react";
import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";

const DefaultLeasesScreen = lazy(() => import("screens/leases/leases/leasesList/defaultLeasesListScreen"));
const ActiveLeasesScreen = lazy(() => import("screens/leases/leases/leasesList/activeLeasesListScreen"));
const InactiveLeasesScreen = lazy(() => import("screens/leases/leases/leasesList/inactiveLeasesListScreen"));
const DraftLeasesScreen = lazy(() => import("screens/leases/draftLeases/draftLeasesList/draftLeasesListScreen"));
const RenewLeaseScreen = lazy(() => import("screens/leases/renewLease/renewLeaseScreen"));
const LeaseDetails = lazy(() => import("screens/leases/leases/leaseDetails/leaseDetails"));
const DraftLeaseDetails = lazy(() => import("screens/leases/draftLeases/draftLeaseDetails/draftLeaseDetails"));
const NewLeasesScreen = lazy(() => import("screens/leases/newLease/newLeaseScreen"));
const EndLeaseScreen = lazy(() => import("screens/leases/endLease/endLeaseScreen"));
const LeaseRenewalsScreen = lazy(() => import("screens/leases/leaseRenewals/leaseRenewalsList/leaseRenewalsScreen"));

const LeasesRouter = () => (
  <PageRouter>
    <LazyRoute path={Routes.ACTIVE_LEASES_GLOBAL_NEW} render={DefaultLeasesScreen} />

    <LazyRoute path={Routes.DRAFT_LEASES_GLOBAL_NEW} render={DraftLeasesScreen} />

    <LazyRoute path={Routes.ACTIVE_LEASES_DETAILS_GLOBAL_NEW} render={LeaseDetails} />

    <LazyRoute path={Routes.DRAFT_LEASE_DETAILS_GLOBAL_NEW} render={DraftLeaseDetails} />

    <LazyRoute exact path={Routes.ACTIVE_LEASES_DETAILS_EDIT_TENANT} render={LeaseDetails} />
    <LazyRoute exact path={Routes.ACTIVE_LEASES_DETAILS_VIEW_SIGNATURE_REQUEST} render={LeaseDetails} />
    <LazyRoute exact path={Routes.ACTIVE_LEASES_DETAILS_BANK_DEPOSIT} render={LeaseDetails} />
    <LazyRoute exact path={Routes.ACTIVE_LEASES_DETAILS_NEW_CHARGE} render={LeaseDetails} />

    <LazyRoute exact path={Routes.ACTIVE_LEASES_DETAILS_EDIT_CHARGE} render={LeaseDetails} />

    <LazyRoute exact path={Routes.ACTIVE_LEASES_DETAILS_EDIT_EVICTION_STATUS} render={LeaseDetails} />

    <LazyRoute exact path={Routes.ACTIVE_LEASES_DETAILS_NEW_CREDIT} render={LeaseDetails} />

    <LazyRoute exact path={Routes.ACTIVE_LEASES_DETAILS_EDIT_CREDIT} render={LeaseDetails} />

    <LazyRoute exact path={Routes.ACTIVE_LEASES_DETAILS_EDIT_APPLIED_CREDIT} render={LeaseDetails} />

    <LazyRoute exact path={Routes.ACTIVE_LEASES_DETAILS_EDIT_RECURRING_CHARGE} render={LeaseDetails} />

    <LazyRoute exact path={Routes.ACTIVE_LEASES_DETAILS_EDIT_RECURRING_CREDIT} render={LeaseDetails} />

    <LazyRoute exact path={Routes.ACTIVE_LEASES_DETAILS_EDIT_RECURRING_PAYMENT} render={LeaseDetails} />

    <LazyRoute exact path={Routes.ACTIVE_LEASES_DETAILS_EDIT_RECURRING_RENT} render={LeaseDetails} />

    <LazyRoute exact path={Routes.ACTIVE_LEASES_DETAILS_EDIT_PAYMENT} render={LeaseDetails} />

    <LazyRoute exact path={Routes.ACTIVE_LEASES_DETAILS_EDIT_REFUND} render={LeaseDetails} />

    <LazyRoute exact path={Routes.ACTIVE_LEASES_DETAILS_EDIT_INTERNAL_TASK} render={LeaseDetails} />

    <LazyRoute exact path={Routes.ACTIVE_LEASES_DETAILS_EDIT_OWNER_REQUEST} render={LeaseDetails} />

    <LazyRoute exact path={Routes.ACTIVE_LEASES_DETAILS_EDIT_TENANT_REQUEST} render={LeaseDetails} />

    <LazyRoute exact path={Routes.ACTIVE_LEASES_DETAILS_EDIT_WORK_ORDER} render={LeaseDetails} />

    <LazyRoute path={Routes.NEW_DRAFT_LEASE_GLOBAL_NEW} render={NewLeasesScreen} />

    <LazyRoute exact path={Routes.NEW_DRAFT_LEASE} render={NewLeasesScreen} />

    <LazyRoute path={Routes.EDIT_DRAFT_LEASE_GLOBAL_NEW} render={NewLeasesScreen} />
    <LazyRoute exact path={Routes.EDIT_DRAFT_LEASE} render={NewLeasesScreen} />

    <LazyRoute path={Routes.RENEW_LEASE} render={RenewLeaseScreen} />

    <LazyRoute exact path={Routes.DRAFT_LEASE_DETAILS_VIEW_SIGNATURE_REQUEST} render={DraftLeaseDetails} />
    <LazyRoute exact path={Routes.DRAFT_LEASE_DETAILS_NEW_TENANT} render={DraftLeaseDetails} />
    <LazyRoute exact path={Routes.DRAFT_LEASE_DETAILS_EDIT_TENANT} render={DraftLeaseDetails} />

    <LazyRoute exact path={Routes.DRAFT_LEASE_DETAILS_EDIT_INTERNAL_TASK} render={DraftLeaseDetails} />
    <LazyRoute path={Routes.END_LEASE} render={EndLeaseScreen} />
    <LazyRoute path={Routes.ACTIVE_LEASES_DETAILS} render={LeaseDetails} />
    <LazyRoute path={Routes.DRAFT_LEASE_DETAILS} render={DraftLeaseDetails} />
    <LazyRoute path={Routes.DRAFT_LEASES} render={DraftLeasesScreen} />
    <LazyRoute path={Routes.LEASE_RENEWALS} render={LeaseRenewalsScreen} />
    <LazyRoute path={Routes.LEASE_RENEWALS_EDIT_OFFER} render={LeaseRenewalsScreen} />
    <LazyRoute path={Routes.ACTIVE_LEASES} render={ActiveLeasesScreen} />
    <LazyRoute path={Routes.INACTIVE_LEASES} render={InactiveLeasesScreen} />
  </PageRouter>
);

export default LeasesRouter;
