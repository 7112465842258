import React from "react";
import { View } from "DLUI/view";

interface ComponentProps {
  SkeletonItem: React.FC<any>;
  SkeletonHeader?: React.FC<any>;
  numOfSkeletonItems?: number;
  maxWidth?: number;
}

const NUM_OF_SKELETON_ITEMS = 10;

const LoadingSkeleton: React.FC<ComponentProps> = ({ SkeletonItem, numOfSkeletonItems, maxWidth }: ComponentProps) => {
  const createItemsArray = (): any[] => {
    const itemsArray: any = [];
    const numOfItems = numOfSkeletonItems ? numOfSkeletonItems : NUM_OF_SKELETON_ITEMS;

    for (let i = 0; i < numOfItems; i++) {
      itemsArray.push(<SkeletonItem key={i} />);
    }
    return itemsArray;
  };
  const skeletonItems = createItemsArray();

  return (
    <View
      flexDirection={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      maxWidth={maxWidth}
      alignSelf={"center"}
    >
      {skeletonItems}
    </View>
  );
};

export default LoadingSkeleton;
