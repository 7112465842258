import IconButton from "@material-ui/core/IconButton";
import { CloseCircleIcon } from "assets/icons";
import { Icon } from "DLUI/icon";
import { SectionTitle } from "DLUI/screen";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FC, ReactNode } from "react";
import React, { useEffect, useMemo, useState } from "react";

import { default as DLUI_ViewSlider } from "DLUI/viewSlider/viewSlider";
//@ts-ignore
import ViewSlider from "react-view-slider";
import makeStyles from "./styles";
import { usePermission } from "screens/settings/userRoles/usePermission";
import { NoAccessDisplay } from "screens/settings/userRoles/NoAccessDisplay";
import AppStrings from "locale/keys";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import clsx from "clsx";
import MobileTopNavigationBar from "DLUI/screen/mobile/mobileTopNavigationBar";
import { useKeyboard } from "hooks/useKeyboard";
import _ from "lodash";
import DialogHeader from "DLUI/dialogs/components/dialogHeader";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import DLButton, { DLButtonSizesEnum } from "DLUI/button/dlButton";
import { DataCy } from "@doorloop/dto";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { useAnalyticsService } from "../../../../hooks/useAnalyticsService";
import type { TextComponentProps, TextTransformProp } from "DLUI/text/text";

interface IndexObject {
  index: number;
}

interface BuildRenderViews {
  renderView: (obj: IndexObject) => JSX.Element;
  numViews: number;
}

export const buildRenderView = (views: FC[]): BuildRenderViews => {
  return {
    renderView: ({ index }: IndexObject) => {
      const CurrentView = views[index];
      return CurrentView ? <CurrentView /> : <></>;
    },
    numViews: _.size(views)
  };
};

export const getDialogFrameDimension = (type: "width" | "height", frameDimension = 0): number => {
  const windowDimension = type === "width" ? window.innerWidth : window.innerHeight;
  return Math.min(windowDimension - 80, frameDimension);
};

export const DIALOG_HEADER_HEIGHT = 60;
const DialogSelectionSubtractor = 170;
const DialogSelectionInputSubtractor = 55;

export const getDialogSelectionHeight = (height: number, isInput = false): number => {
  const subtractor = DialogSelectionSubtractor + (isInput ? DialogSelectionInputSubtractor : 0);

  return getDialogFrameDimension("height", height > subtractor ? height : subtractor) - subtractor;
};

export type FrameType =
  | "topPanel"
  | "contentOnly"
  | "fullFrame"
  | "sideMenu"
  | "contentOnlyNoFrame"
  | "sectionTitleFrame";

const DialogFrameContainerClassName = "dialog_frame_container";

interface ComponentProps<TDialogView extends number> {
  onCloseButtonClick: () => void;
  title?: string;
  titleActions?: React.ReactNode;
  width: number | string;
  height: number | string;
  numViews: number;
  renderView: React.FC<{ index: TDialogView; active: boolean }>;
  activeView: number;
  RenderHeaderActionButtons?: () => ReactNode;
  RenderActionPanelButtons?: () => JSX.Element | null;
  sectionTitle?: string;
  frameType: FrameType;
  keepViewsMounted?: boolean;
  hideActionPanel?: boolean;
  renderFrameHeader?: () => JSX.Element;
  hideCloseButton?: boolean;
  clearance?: AnyPermissionClearance;
  useStickyFooter?: boolean;
  disableEscapeExit?: boolean;
  showPrintButton?: boolean;
  showExportToPdfButton?: boolean;
  exportFileName?: string;
  onExportPdfButtonPress?: () => void;
  exportPdfInProgress?: boolean;
  useExperimentalDialogFrame?: boolean;
  helpPanel?: HelpPanelProps;
  titleProps?: TextComponentProps;
  onMobileBackButtonClick?: VoidFunction;
  sectionTitleProps?: {
    textTransform?: TextTransformProp;
    type?: "underline" | "rounded" | "none";
  };
}

const preventTabViewSwitch = (e: KeyboardEvent) => {
  const target = e.target as HTMLButtonElement;
  if (e.key === "Tab" && target?.dataset?.cy === DataCy.dialogActionButtons.save) {
    e.preventDefault();
  }
};

const DialogFrame = <TDialogView extends number>({
  onCloseButtonClick,
  title,
  titleActions,
  width,
  height,
  numViews,
  renderView,
  activeView,
  RenderActionPanelButtons,
  RenderHeaderActionButtons,
  keepViewsMounted,
  sectionTitle,
  frameType,
  hideActionPanel,
  renderFrameHeader,
  hideCloseButton,
  clearance,
  disableEscapeExit,
  useExperimentalDialogFrame,
  showPrintButton,
  showExportToPdfButton,
  exportFileName,
  onExportPdfButtonPress,
  exportPdfInProgress,
  useStickyFooter = false,
  helpPanel,
  titleProps,
  onMobileBackButtonClick,
  sectionTitleProps
}: ComponentProps<TDialogView>) => {
  const classes = makeStyles();
  const { isMobile, screenContainerPadding } = useResponsiveHelper();
  const { hasPermission } = usePermission();
  const [dialogWidth, setDialogWidth] = useState<number | string>();
  const [dialogHeight, setDialogHeight] = useState<number | string>(500);
  const [currentFrameType, setCurrentFrameType] = useState<FrameType>(frameType || "fullFrame");

  useKeyboard("Escape", disableEscapeExit ? () => {} : onCloseButtonClick, []);

  useEffect(() => {
    setCurrentFrameType(frameType || "fullFrame");
  }, [frameType]);

  const { dispatchAnalytics, formatAppStringsValue } = useAnalyticsService();

  useEffect(() => {
    dispatchAnalytics("dialog_view", { dialogTitle: formatAppStringsValue(title) });
  }, [title]);

  useEffect(() => {
    if (isMobile) {
      setDialogWidth(window.innerWidth);
    } else {
      setDialogWidth(width);
    }
  }, [width]);

  useEffect(() => {
    if (isMobile) {
      setDialogHeight("100dvh");
    } else {
      setDialogHeight(height);
    }
  }, [height]);

  const hasNoAccess = useMemo(() => {
    const { permission, field, requiredLevel } = clearance || {};
    return clearance && !hasPermission(permission, field, requiredLevel);
  }, [clearance]);

  const renderMobileSideMenuFrame = (dialogContent: any) => (
    <View noWrap>
      <MobileTopNavigationBar title={title} onCloseButtonClick={onMobileBackButtonClick} />
      {dialogContent}
    </View>
  );

  const renderSideMenuFrame = (dialogContent: any) => {
    if (isMobile) {
      return renderMobileSideMenuFrame(dialogContent);
    }
    return (
      <View width={dialogWidth} height={dialogHeight} noWrap dataCy={DataCy.dialog.container}>
        <View height={DIALOG_HEADER_HEIGHT} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"}>
          <View flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} height={"100%"}>
            <View
              className={classes.sidebarContainer}
              width={isMobile ? 80 : 265}
              height={"100%"}
              flexDirection={"row"}
            >
              <View
                justifyContent={"flex-start"}
                alignItems={"center"}
                height={"95%"}
                width="100%"
                marginLeft={0}
                flexDirection={"row"}
                className={classes.sidebarTitleContainer}
              >
                <View paddingLeft={20} justifyContent={"flex-start"} alignItems={"center"} flexDirection={"row"}>
                  {!isMobile && (
                    <View flex={1} noWrap>
                      <Text
                        value={title}
                        color={"white"}
                        fontSize={20}
                        fontWeight={700}
                        marginLeft={15}
                        {...titleProps}
                      />
                    </View>
                  )}
                </View>
              </View>
            </View>
            <View flex={1} justifyContent={"flex-start"} alignItems={"center"} flexDirection={"row"}>
              <View marginLeft={screenContainerPadding} width={"auto"} flexDirection={"row"}>
                <SectionTitle
                  textTransform={"uppercase"}
                  type={"underline"}
                  title={sectionTitle}
                  {...sectionTitleProps}
                />
              </View>
              <View flex={1} alignItems={"center"} justifyContent={"flex-end"} marginRight={20} flexDirection={"row"}>
                <IconButton onClick={onCloseButtonClick}>
                  <Icon width={30} height={30} Source={CloseCircleIcon} />
                </IconButton>
              </View>
            </View>
          </View>
        </View>
        <div
          style={{
            width,
            overflowY: "auto",
            height: typeof dialogHeight === "string" ? dialogHeight : dialogHeight - DIALOG_HEADER_HEIGHT
          }}
        >
          {dialogContent}
        </div>
      </View>
    );
  };

  const renderContentOnlyNoFrameLayout = (dialogContent: any) => (
    <View width={dialogWidth} height={dialogHeight} noWrap>
      {renderFrameHeader ? renderFrameHeader() : null}
      <div
        className={classes.overflowScroll}
        style={{
          width: dialogWidth || "100%",
          height: typeof dialogHeight === "string" ? dialogHeight : dialogHeight
        }}
      >
        <div
          style={{
            display: "flex",
            height: "100%",
            flexDirection: "column"
          }}
        >
          {dialogContent}
        </div>
      </div>
    </View>
  );

  useEffect(() => {
    window.addEventListener("keydown", preventTabViewSwitch);

    return () => {
      window.removeEventListener("keydown", preventTabViewSwitch);
    };
  }, []);

  const renderSectionTitleLayout = (dialogContent: any) => (
    <View
      // className={DialogFrameContainerClassName}
      width={dialogWidth}
      height={dialogHeight}
      flexDirection={"row"}
    >
      <div
        className={classes.overflowScroll}
        style={{
          width: dialogWidth,
          height: dialogHeight
        }}
      >
        <div
          style={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            width: "100%"
          }}
        >
          <DialogHeader
            onClose={onCloseButtonClick}
            title={title || ""}
            hideCloseButton={hideCloseButton}
            helpPanel={helpPanel}
            titleActions={titleActions}
            renderActionButtons={RenderHeaderActionButtons}
            showExportToPdfButton={showExportToPdfButton}
            showPrintButton={showPrintButton}
            exportFileName={exportFileName}
            onExportPdfButtonPress={onExportPdfButtonPress}
            exportPdfInProgress={exportPdfInProgress}
          />
          <View flex={1} noWrap justifyContent="space-between">
            {dialogContent}

            {RenderActionPanelButtons && !hideActionPanel && currentFrameType !== "topPanel" ? (
              <div
                className="action-panel-buttons"
                style={{
                  width: "100%",
                  position: useStickyFooter ? "sticky" : "relative",
                  bottom: 0,
                  backgroundColor: "#f5f6fa",
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "column",
                  borderTop: "1px solid #e0e0e0",
                  zIndex: 10
                }}
              >
                {RenderActionPanelButtons()}
              </div>
            ) : null}
          </View>
        </div>
      </div>
    </View>
  );

  const renderNoAccess = () => (
    <View width={dialogWidth} height={dialogHeight}>
      <div
        className={classes.overflowScroll}
        style={{
          width: dialogWidth,
          height: dialogHeight
        }}
      >
        <div
          style={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            width: "100%"
          }}
        >
          <DialogHeader onClose={onCloseButtonClick} title={title || ""} />
          <View height={100} />
          <NoAccessDisplay />
          <View flex={1}>
            <View
              height={"80px"}
              alignItems={"center"}
              justifyContent={"center"}
              style={{ position: "absolute", bottom: 0, left: 0 }}
            >
              <DLButton
                actionText={AppStrings.Common.Dismiss}
                size={DLButtonSizesEnum.LARGE}
                onClick={onCloseButtonClick}
                style={{ marginRight: 20 }}
              />
            </View>
          </View>
        </div>
      </div>
    </View>
  );

  const renderFrame = (dialogContent: any) => (
    <View width={dialogWidth} height={dialogHeight}>
      <div
        className={classes.overflowScroll}
        style={{
          width: dialogWidth,
          height: dialogHeight
        }}
      >
        <div
          style={{
            display: "flex",
            height: "100%",
            flexDirection: "column"
          }}
        >
          <View paddingLeft={isMobile ? 20 : 40} paddingRight={isMobile ? 20 : 40}>
            <DialogHeader onClose={onCloseButtonClick} title={title || ""} hideCloseButton={hideCloseButton} />
          </View>
          {dialogContent}
        </div>
      </div>
    </View>
  );

  const _renderView = ({ index, active }: any) => {
    if (hasNoAccess) {
      return renderNoAccess();
    }
    const dialogContent = renderView({ index, active });
    switch (currentFrameType) {
      case "contentOnly":
      case "contentOnlyNoFrame":
        return renderContentOnlyNoFrameLayout(dialogContent);
      case "fullFrame":
        return renderFrame(dialogContent);
      case "sideMenu":
        return renderSideMenuFrame(dialogContent);
      case "topPanel":
      case "sectionTitleFrame":
        return renderSectionTitleLayout(dialogContent);
    }
  };

  return (
    <div
      style={{
        width: dialogWidth,
        height: dialogHeight,
        transition: "all 0.3s ease",
        borderRadius: isMobile ? 0 : 10
      }}
      className={clsx([classes.dialogContainer, DialogFrameContainerClassName])}
    >
      {useExperimentalDialogFrame ? (
        <DLUI_ViewSlider renderView={_renderView} activeView={activeView} />
      ) : (
        <ViewSlider
          keepViewsMounted={keepViewsMounted || true}
          renderView={_renderView}
          numViews={numViews}
          activeView={activeView}
          innerViewWrapper={{
            display: "flex",
            height: "100%",
            width: "100%"
          }}
        />
      )}
    </div>
  );
};

export default DialogFrame;
