import Routes from "./routes";
import { Redirect } from "react-router-dom";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";
import { lazy } from "react";

const GLOBAL_NEW = `/global-new`;

const SetupSmsScreen = lazy(() => import("screens/communicationsCenter/setupSmsWizard/setupSmsScreen"));
const InboxScreen = lazy(() => import("screens/communicationsCenter/inbox/inboxScreen"));
const AllMessagesScreen = lazy(() => import("screens/communicationsCenter/allMessages/allMessagesScreen"));
const UnassignedMessagesScreen = lazy(
  () => import("screens/communicationsCenter/unassignedMessages/unassignedMessagesScreen")
);

export const CommunicationsCenterRouter = () => (
  <PageRouter>
    <Redirect exact from={Routes.COMMUNICATIONS_CENTER} to={Routes.COMMUNICATIONS_CENTER_INBOX} />
    <LazyRoute path={Routes.COMMUNICATIONS_CENTER_SETUP_SMS} render={SetupSmsScreen} />

    <LazyRoute
      path={[
        `${Routes.COMMUNICATIONS_CENTER_INBOX}${GLOBAL_NEW}`,
        `${Routes.COMMUNICATIONS_CENTER_INBOX_VIEW_CONVERSATION}${GLOBAL_NEW}`,
        `${Routes.COMMUNICATIONS_CENTER_INBOX_VIEW_CONVERSATION}${Routes.CONVERSATION_TASK}`
      ]}
      render={InboxScreen}
    />

    <LazyRoute
      exact
      path={[Routes.COMMUNICATIONS_CENTER_INBOX, Routes.COMMUNICATIONS_CENTER_INBOX_VIEW_CONVERSATION]}
      render={InboxScreen}
    />

    <LazyRoute
      exact
      path={[Routes.COMMUNICATIONS_CENTER_ALL_MESSAGES, Routes.COMMUNICATIONS_CENTER_ALL_MESSAGES_VIEW_CONVERSATION]}
      render={AllMessagesScreen}
    />

    <LazyRoute
      path={[
        `${Routes.COMMUNICATIONS_CENTER_ALL_MESSAGES}${GLOBAL_NEW}`,
        `${Routes.COMMUNICATIONS_CENTER_ALL_MESSAGES_VIEW_CONVERSATION}${GLOBAL_NEW}`,
        `${Routes.COMMUNICATIONS_CENTER_ALL_MESSAGES_VIEW_CONVERSATION}${Routes.CONVERSATION_TASK}`
      ]}
      render={AllMessagesScreen}
    />

    <LazyRoute
      exact
      path={[
        Routes.COMMUNICATIONS_CENTER_UNASSIGNED_MESSAGES,
        Routes.COMMUNICATIONS_CENTER_UNASSIGNED_MESSAGES_VIEW_CONVERSATION
      ]}
      render={UnassignedMessagesScreen}
    />

    <LazyRoute
      path={[
        `${Routes.COMMUNICATIONS_CENTER_UNASSIGNED_MESSAGES}${GLOBAL_NEW}`,
        `${Routes.COMMUNICATIONS_CENTER_UNASSIGNED_MESSAGES_VIEW_CONVERSATION}${GLOBAL_NEW}`,
        `${Routes.COMMUNICATIONS_CENTER_UNASSIGNED_MESSAGES_VIEW_CONVERSATION}${Routes.CONVERSATION_TASK}`
      ]}
      render={UnassignedMessagesScreen}
    />
  </PageRouter>
);
