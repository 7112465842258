"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsOptionalEmail = IsOptionalEmail;
const class_validator_1 = require("class-validator");
//https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
function IsOptionalEmail(validationOptions) {
    return function (object, propertyName) {
        (0, class_validator_1.registerDecorator)({
            name: "isOptionalEmail",
            target: object.constructor,
            propertyName,
            constraints: [],
            options: validationOptions,
            validator: {
                validate(value, args) {
                    if (value === "" || value === undefined)
                        return true;
                    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
                }
            }
        });
    };
}
