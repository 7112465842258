"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsAcceptInvalidAddressValidator = IsAcceptInvalidAddressValidator;
const class_validator_1 = require("class-validator");
function IsAcceptInvalidAddressValidator(validationOptions) {
    return function (object, propertyName) {
        (0, class_validator_1.registerDecorator)({
            name: "isAcceptInvalidAddressValidator",
            target: object.constructor,
            propertyName,
            constraints: [],
            options: validationOptions,
            validator: {
                validate(value, args) {
                    const addressObj = args.object;
                    return addressObj.acceptInvalidAddress === undefined || addressObj.acceptInvalidAddress;
                }
            }
        });
    };
}
