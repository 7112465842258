import React, { useMemo } from "react";
import { accountsApi } from "api/accounts";
import CreateNewAccountOptionForm from "./createNewAccountOptionForm";
import AppStrings from "../../../../../locale/keys";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";

interface Props {
  displayNameKey?: string;
  addCreateOption?: boolean;
  selectionFields?: string[];
  shouldHideClearIcon?: boolean;
  createNewOptionComponentProps?: any;
  [x: string]: any;
}

const BankAccountFormikAutoCompleteField: React.FC<Props> = ({
  displayNameKey,
  selectionFields,
  addCreateOption,
  ...restProps
}: Props) => {
  const selectionFieldsMemo = useMemo(() => {
    const finalSelectionFields = ["id", "class"];
    if (selectionFields?.length) {
      finalSelectionFields.push(...selectionFields);
    }

    return finalSelectionFields;
  }, []);

  return (
    <FormikCachedAsyncAutoComplete
      required
      apiHandler={accountsApi}
      displayNameKey={displayNameKey || "fullyQualifiedName"}
      filterFieldName={"filter_text"}
      filterFieldValue={"fullyQualifiedName"}
      selectionFields={selectionFieldsMemo}
      groupBy={"accountClass"}
      {...restProps}
      CreateNewOptionComponent={addCreateOption && CreateNewAccountOptionForm}
      createNewOptionTitle={addCreateOption && AppStrings.Accounts.CreateNewAccountTitleOption}
      groupNameProp={addCreateOption && "class"}
      queryParams={{
        ...restProps.queryParams,
        filter_active: true
      }}
    />
  );
};

export default BankAccountFormikAutoCompleteField;
