import type { FC } from "react";
import React from "react";

import { View } from "DLUI/view";
import Text from "DLUI/text";

import type { TextFormatType } from "DLUI/text/text";

interface TitleProps {
  text: string;
  fontSize: number;
  formatType?: TextFormatType;
  bold?: boolean;
  dataCy?: string;
}
interface Props {
  titleProps: TitleProps;
  subTitleProps: TitleProps;
}

const SuccessDialogTitle: FC<Props> = ({ titleProps, subTitleProps }) => (
  <View fullWidth alignItems={"center"} gap={5}>
    <Text value={titleProps.text} fontSize={titleProps.fontSize} bold={titleProps.bold} dataCy={titleProps.dataCy} />

    <Text
      value={subTitleProps.text}
      formatType={subTitleProps.formatType}
      fontSize={subTitleProps.fontSize}
      bold={subTitleProps.bold}
      dataCy={subTitleProps.dataCy}
    />
  </View>
);

export { SuccessDialogTitle };
