import type { DialogProps } from "./dialogsProvider.types";
import { Routes } from "../../../appRouter";
import { DialogRoutes } from "DLUI/screen/dialogsProvider/dialogsRoutes";

class UserRolesDialogsProvider {
  matchRoutes(pathname: string): DialogProps | undefined {
    if (pathname.indexOf(Routes.NEW_USER_ROLE) > -1 || pathname.indexOf(DialogRoutes.EDIT_USER_ROLE) > -1) {
      return {
        type: "newUserRole"
      };
    }

    return undefined;
  }
}

export const userRolesDialogsProvider = new UserRolesDialogsProvider();
