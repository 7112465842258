import type { ReactElement } from "react";
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import type { DataResult, GroupResult, SortDescriptor, State } from "@progress/kendo-data-query";

import type { GridCellProps, GridColumnReorderEvent, GridProps as KendoGridProps } from "@progress/kendo-react-grid";

import { View } from "DLUI/view";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import type { IRootState } from "store";
import "../styles.css";
import type { WellKnownTranslationsMap } from "../utils";
import { getAdditionalColumnsFromApiData, processData, WELL_KNOWN_TRANSLATIONS_MAP } from "../utils";

import type { AccountingMethodFooterProps } from "./footers/accountingMethodFooter";

import type {
  GridColumn,
  GridListType,
  GridRowWithId,
  Queryable,
  TranslatedRow,
  VisibleGridColumn
} from "DLUI/lists/types";
import { SELECTION_IDENTIFIER } from "DLUI/lists/types";
import type { GridContainerProps } from "./dataGrid/gridContainer";
import GridContainer from "./dataGrid/gridContainer";
import NetworkError from "./dataGrid/networkError";
import {
  columnQueryKey,
  buildColumnQueryString,
  getExcelExportFileName,
  getSortedDataSource,
  pickColumnsByQuery,
  pickColumnsByLocalStorage,
  getColumnComparer,
  setColumnsLocalStorage
} from "./utils";

import { useAtom } from "jotai";
import { isPrintingAtom } from "utils/atoms";
import type { GridHeaderProps } from "./dataGrid/gridHeaderWrapper";
import { useGridSelection } from "./useGridSelection";

import type { RestApiBase } from "api/restApiBase";
import { BuildGridCell } from "./dataGrid/gridCell";
import { EmptyListView } from "./emptyListView";
import { initiateExcelExport } from "./excelExportHelper";
import { DRAWER_WIDTH, MINIMIZED_DRAWER_WIDTH } from "DLUI/drawer/drawer";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { BuildGridHeaderCell } from "DLUI/lists/gridList/dataGrid/gridHeaderCell";
import type { ClassConstructor } from "class-transformer";
import { sideMenuCollapsingAtom } from "components/layouts/layoutAtom";
import { useSyncWithSearchParams } from "@/hooks/useSyncWithSearchParams";
import { useSearchParams } from "@/hooks/useSearchParams";
import { getGroupProp } from "./getGroupProp";
import type { FilterOption } from "../../screen/filterPanel/filterInputs/types";

// re-export for backwards compatibility
export type { GridColumn, VisibleGridColumn, GridSubColumn } from "DLUI/lists/types";

export interface GridListProps<TDto extends object, TGetAllDto> {
  apiMethod?: RestApiBase<TDto, TGetAllDto>;
  initLoadingComponent?: ReactElement;
  filterObj: TGetAllDto;
  filterOptions?: Array<FilterOption<TGetAllDto>>;
  groupBy?: keyof TDto;
  onRequestinProgressChange: (inProgress: boolean) => void;
  gridColumns: Array<GridColumn<TDto>>;
  GridHeader?: React.FC<GridHeaderProps>;
  GridFooter?: React.FC<AccountingMethodFooterProps>;
  exportFileName: string;
  reportName: string;
  reorderable?: boolean;
  resizable?: boolean;
  onGridRowPress?: (cellProps: Omit<GridCellProps, "dataItem"> & { dataItem: TranslatedRow<TDto> }) => void;
  initialSort?: SortDescriptor[];
  isMultiSelect?: boolean;
  onSelectionChange?: (selectedItems: Array<GridRowWithId<TDto>>) => void;
  actionButtons?: React.ReactNode;
  overrideGridType?: GridListType;
  innerRef?: React.Ref<FetchDataHandle>;
  rowHeight?: number;
  listPadding?: number;
  onFetchSuccessCallback?: (filters: any, data: any) => void;
  rawData?: { data: TDto[] };
  /**
   * Dto is only necessary if the grid is editable. It's used for validation.
   */
  dto?: ClassConstructor<object>;
  hideActionButtonsIfNoSelection?: boolean;
  disableMaxWidth?: boolean;
  wellKnownTranslationsMap?: WellKnownTranslationsMap;
  getRoute?: (cellProps: Omit<GridCellProps, "dataItem"> & { dataItem: TranslatedRow<TDto> }) => string;
  dataItemKey?: string;
  hideColumnMenu?: boolean;
  columnMenuItemsToHide?: Array<keyof TDto>;
  hideExcelGroupHeaderPrefix?: boolean;
  dataCy?: string;
}

export interface FetchDataHandle {
  reFetchData: () => Promise<void>;
}

const DEFAULT_SCREEN_PADDING = 80;

const PAGE_SIZE = 1000;

const omitShowFields: (gridColumns: GridColumn[]) => VisibleGridColumn[] = (
  gridColumns: GridColumn[]
): VisibleGridColumn[] => gridColumns.map(({ show: _show, ...restOfColumn }) => restOfColumn);

const isShown: (column: GridColumn) => boolean = ({ show }) => show;

const DLUI_GridList = <TDto extends object, GetAllDtoType extends Queryable>({
  apiMethod,
  initLoadingComponent,
  filterObj,
  onRequestinProgressChange,
  gridColumns,
  GridHeader,
  exportFileName,
  reportName,
  reorderable,
  filterOptions,
  resizable,
  onGridRowPress,
  GridFooter,
  initialSort,
  isMultiSelect,
  onSelectionChange,
  listPadding,
  actionButtons,
  overrideGridType,
  innerRef,
  rowHeight,
  groupBy,
  dto,
  rawData,
  onFetchSuccessCallback,
  hideActionButtonsIfNoSelection = true,
  disableMaxWidth = true,
  wellKnownTranslationsMap = WELL_KNOWN_TRANSLATIONS_MAP,
  getRoute,
  dataItemKey,
  hideColumnMenu,
  columnMenuItemsToHide,
  hideExcelGroupHeaderPrefix,
  dataCy
}: GridListProps<TDto, GetAllDtoType>) => {
  const { t } = useTranslation();
  const { queryValue: columnValue, onValueChange: onColumnChange } = useSyncWithSearchParams(columnQueryKey);
  const [dataSource, setDataSource] = useState<DataResult>({ data: [], total: 0 });
  const [dataTotalLine, setDataTotalLine] = useState<Record<string, number>>();
  const { isTabletOrMobile } = useResponsiveHelper();
  const [sort, setSort] = useState<SortDescriptor[]>(initialSort || []);
  const [originalDataHashMap, setOriginalDataHashMap] = useState<Map<string, GridRowWithId<TDto>>>(new Map());
  const [searchParams] = useSearchParams();
  const groupByValue = groupBy ? groupBy : searchParams.get("groupBy") ?? undefined;
  const wrappedFilterObj = useMemo(() => {
    return { page_size: PAGE_SIZE, page_number: 1, ...filterObj, groupBy: groupByValue };
  }, [filterObj, groupByValue]);

  const {
    _gridListInternal: {
      selectedRows,
      setSelectedRows,
      onSelectAll,
      onSelectionCheckboxClick,
      onGridCellEditRequest,
      onGridCellEditFinish,
      resetSelection,
      onGroupSelectionChanged,
      selectionLength,
      onExpandChange
    }
  } = useGridSelection<TDto>({ dataSource, setDataSource, originalDataHashMap, setOriginalDataHashMap });

  const localeData = useSelector((state: IRootState) => state.auth.localeData);
  const userData = useSelector((state: IRootState) => state.auth.currentLoginResponse);
  const { currencySymbol } = localeData;

  const [showInitLoading, setShowInitLoading] = useState(Boolean(initLoadingComponent));
  const [showEmptyListView, setShowEmptyListview] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [networkError, setNetworkError] = useState<boolean>(false);
  const [isPrinting] = useAtom(isPrintingAtom);
  const isInitialColumnQueryUpdateRef = useRef(true);
  const excelExporterRef = useRef<any>();
  const [isSideMenuIsCollapsed] = useAtom(sideMenuCollapsingAtom);
  const [reportMaxWidth, setReportMaxWidth] = useState<number>();
  const [index, setIndex] = useState(0);

  const initializeVisibleGridColumns = () => {
    if (hideColumnMenu) {
      return omitShowFields(gridColumns.filter(isShown));
    }

    const columnsByQuery = pickColumnsByQuery(gridColumns, columnValue as string);
    if (columnsByQuery && columnsByQuery.length > 0) {
      return omitShowFields(columnsByQuery);
    }

    const columnsByLocalStorage = pickColumnsByLocalStorage(gridColumns, reportName);
    if (columnsByLocalStorage && columnsByLocalStorage.length > 0) {
      return omitShowFields(columnsByLocalStorage);
    }

    return omitShowFields(gridColumns.filter(isShown));
  };

  const [allGridColumns, setAllGridColumns] = useState<GridColumn[]>(gridColumns);
  const [visibleGridColumns, setVisibleGridColumns] = useState(initializeVisibleGridColumns);
  const defaultGridColumns = useMemo(() => omitShowFields(allGridColumns.filter(isShown)), [allGridColumns]);
  const [groupProp, setGroupProp] = useState<KendoGridProps["group"]>(() => {
    if (Boolean(rawData) || !wrappedFilterObj.groupBy) {
      return [];
    }

    return getGroupProp(wrappedFilterObj.groupBy, allGridColumns);
  });

  useEffect(() => {
    const drawerWidth = isSideMenuIsCollapsed ? MINIMIZED_DRAWER_WIDTH : DRAWER_WIDTH;
    setReportMaxWidth(window.innerWidth - (drawerWidth + DEFAULT_SCREEN_PADDING));
  }, [isSideMenuIsCollapsed]);

  useEffect(() => {
    if (columnValue === buildColumnQueryString(defaultGridColumns)) {
      onColumnChange(null);
    }
  }, [columnValue, onColumnChange]);

  const handleColsReordered = (event: GridColumnReorderEvent) => {
    setVisibleGridColumns((prevVisibleGridColumns) => {
      const sortedColumns = _.sortBy(event.columns, ["orderIndex"]);
      return sortedColumns
        .map(
          ({ field: sortedColumnField }) =>
            prevVisibleGridColumns.find(getColumnComparer(sortedColumnField)) as VisibleGridColumn
        )
        .filter(Boolean);
    });
    setIndex((prev) => prev + 1);
  };

  /**
   * useImperativeHandle allows us to expose fetchData to the parent component.
   * This is useful when we want to trigger CHILD (grid) data fetch from the PARENT component.
   */
  useImperativeHandle(innerRef, () => {
    return {
      reFetchData: fetchData
    };
  });

  useEffect(() => {
    onSelectionChange?.(selectedRows);
  }, [selectedRows]);

  const _exportFileName = useMemo(
    () => getExcelExportFileName(t, exportFileName, userData, wrappedFilterObj),
    [wrappedFilterObj, exportFileName, userData]
  );

  useEffect(() => {
    if (!wrappedFilterObj || rawData) {
      return;
    }

    const timeout = setTimeout(fetchData, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [wrappedFilterObj, rawData]);

  const isInGridColumns = (gridColumn: GridColumn | VisibleGridColumn) =>
    Boolean(gridColumns.find(getColumnComparer(gridColumn.field)));

  const processAndSaveListData = (dataResponse) => {
    const { data } = dataResponse;

    setOriginalDataHashMap(
      new Map(
        data.map((currentDataItem) => [
          (currentDataItem as GridRowWithId<TDto>).id,
          _.cloneDeep(currentDataItem) as GridRowWithId<TDto>
        ])
      )
    );

    if (data && data.length === 0) {
      setShowEmptyListview(true);
    }

    const totalLine = dataResponse.totalLine;
    if (totalLine) {
      setDataTotalLine(totalLine);
    }

    const additionalColumns = getAdditionalColumnsFromApiData(dataResponse as any);

    const newColumns = additionalColumns.filter(_.negate(isInGridColumns));
    const updatedListOfColumns = [...gridColumns, ...newColumns];
    const state: State = { group: groupProp, sort };
    const processedData = processData(t, data, state, updatedListOfColumns, {
      wellKnownTranslationsMap
    });

    setAllGridColumns(updatedListOfColumns);
    setVisibleGridColumns((prevVisibleGridColumns) => [
      ...prevVisibleGridColumns.filter(isInGridColumns),
      ...omitShowFields(newColumns)
    ]);
    setSelectedRows([]);
    setDataSource(processedData);
    setTimeout(() => {
      setShowInitLoading(false);
    }, 0);
  };

  useEffect(() => {
    if (rawData) {
      setShowInitLoading(true);
      processAndSaveListData(rawData);
    }
  }, [rawData]);

  useEffect(() => {
    if (!rawData) {
      setShowEmptyListview(false);
      setDataSource({ data: [], total: 0 });

      if (!wrappedFilterObj.groupBy) {
        return;
      }

      const nextGroupProp = getGroupProp(wrappedFilterObj.groupBy, allGridColumns);

      setGroupProp(nextGroupProp);
    }
  }, [wrappedFilterObj, rawData]);

  useEffect(() => {
    onRequestinProgressChange(requestInProgress);
  }, [requestInProgress]);

  const onColumnsSubmit = (nextColumns: GridColumn[]) => {
    setVisibleGridColumns((prevVisibleGridColumns) => {
      const prevColumnsToKeep = prevVisibleGridColumns.filter((column) =>
        nextColumns.filter(({ show }) => show).find(getColumnComparer(column.field))
      );

      const newColumnsToAdd = nextColumns.filter(
        ({ show, field }) => show && !prevVisibleGridColumns.find(getColumnComparer(field))
      );

      return [...prevColumnsToKeep, ...omitShowFields(newColumnsToAdd)];
    });
    setIndex((prev) => prev + 1);
  };

  const onColumnsReset = () => {
    setVisibleGridColumns(defaultGridColumns);
  };

  useEffect(() => {
    if (isInitialColumnQueryUpdateRef.current) {
      isInitialColumnQueryUpdateRef.current = false;
      return;
    }

    onColumnChange(buildColumnQueryString(visibleGridColumns));
    setColumnsLocalStorage(reportName, visibleGridColumns);
  }, [visibleGridColumns]);

  const fetchData = async () => {
    if (requestInProgress || wrappedFilterObj === undefined || !apiMethod) {
      // don't fetch if the wrappedFilterObj is undefined
      // this happens when the Grid is initialized but the <Screen> hasn't passed us the wrappedFilterObj yet
      return;
    }

    setNetworkError(false);
    setRequestInProgress(true);
    const { data: responseData, status: responseStatus } = (await apiMethod.getAll(wrappedFilterObj)) ?? {};

    if (!responseStatus || !responseData?.data) {
      setRequestInProgress(false);
      setNetworkError(true);
      return;
    }

    const nextDatasource = responseData.data;
    onFetchSuccessCallback?.(wrappedFilterObj, nextDatasource);
    processAndSaveListData(responseData);

    setShowInitLoading(false);
    setRequestInProgress(false);
  };

  const didPressExportExcel = () => {
    initiateExcelExport(t, excelExporterRef, wrappedFilterObj, reportName, hideExcelGroupHeaderPrefix);
  };

  const getIsAllOrSomeRowsSelected = (sortedDataSource: DataResult, allOrSome: "all" | "some") => {
    const operationName = {
      all: "every",
      some: "some"
    }[allOrSome];

    /**
     * The way we determine if a row is selected is by checking if the
     * row has a property `selected` [defined by SELECTION_IDENTIFIER]
     * and if it's set to true
     */
    return sortedDataSource.data[operationName]((row) => {
      if (row.items) {
        // If the row is a group, we need to check if all or some the items in the group are selected
        const group = row as GroupResult;
        return group.items[operationName]((item) => item[SELECTION_IDENTIFIER]);
      }

      // If it's a simple row, we just check if it's selected
      return row[SELECTION_IDENTIFIER];
    });
  };

  const isAllRowsSelected = useCallback(
    (sortedDataSource: DataResult) => getIsAllOrSomeRowsSelected(sortedDataSource, "all"),
    []
  );
  const isSomeRowsSelected = useCallback(
    (sortedDataSource: DataResult) => getIsAllOrSomeRowsSelected(sortedDataSource, "some"),
    []
  );

  const renderGridHeaderCell = BuildGridHeaderCell({
    isAllRowsSelected,
    isSomeRowsSelected,
    dataSource,
    onHeaderSelectionChange: onSelectAll
  });

  if (networkError) {
    return <NetworkError fetchData={fetchData} />;
  }

  if (showInitLoading && initLoadingComponent) {
    return <View noWrap>{initLoadingComponent}</View>;
  }

  if (showEmptyListView) {
    return (
      <EmptyListView
        GridFooter={GridFooter}
        dataSource={dataSource}
        filterObj={wrappedFilterObj}
        requestInProgress={requestInProgress}
      />
    );
  }

  const renderGridCell = BuildGridCell({
    t,
    isMultiSelect,
    allGridColumns,
    onGridRowPress,
    onGroupSelectionChanged,
    onGridCellEditFinish,
    onSelectionCheckboxClick,
    onGridCellEditRequest,
    getRoute
  });

  /**
   * This is all the state that the view layer needs
   * and is what is coupling all of this together
   */
  const gridProps: GridContainerProps<TDto, GetAllDtoType> = {
    requestInProgress,
    dataSource,
    getSortedDataSource,
    sort,
    resizable,
    reorderable,
    onGridRowPress,
    groupProp,
    filterOptions,
    excelExporterRef,
    _exportFileName,
    allGridColumns,
    visibleGridColumns: visibleGridColumns || [],
    currencySymbol,
    t,
    dataTotalLine,
    onColumnsSubmit,
    onColumnsReset,
    setSort,
    reportName,
    header: GridHeader,
    didPressExportExcel,
    isTabletOrMobile,
    isPrinting,
    rowHeight,
    GridFooter,
    filterObj: wrappedFilterObj,
    resetSelection,
    listPadding,
    isMultiSelect: isPrinting ? false : isMultiSelect,
    selectionLength,
    actionButtons,
    hideActionButtons: isPrinting || (hideActionButtonsIfNoSelection && selectedRows.length === 0),
    overrideGridType: isPrinting ? "fixed" : overrideGridType,
    onSelectionChange: onSelectionCheckboxClick,
    onExpandChange,
    renderGridCell,
    renderGridHeaderCell,
    originalDataHashMap,
    dto,
    onColumnReorder: handleColsReordered,
    dataItemKey,
    columnMenuItemsToHide,
    dataCy,
    hideColumnMenu
  };

  return (
    <View
      alignItems={"center"}
      className={"list-container grid"}
      maxWidth={isTabletOrMobile || disableMaxWidth ? undefined : reportMaxWidth}
      flexDirection={"row"}
    >
      <GridContainer {...gridProps} key={index} />
    </View>
  );
};

export default DLUI_GridList;
