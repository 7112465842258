import AppStrings from "locale/keys";
import _ from "lodash";

import { Icon } from "@/components/DLUI/icon";
import { DataCy, PropertyType } from "@doorloop/dto";
import {
  IndustrialIcon,
  OfficeIcon,
  ParkingIcon,
  RentalsIcon,
  RetailIcon,
  ShoppingCenterIcon,
  StorageIcon
} from "assets/icons";
import { PropertyTypeKey } from "./propertyType.types";

import type { PropertyTypeItemType } from "./propertyTypes";

export const commercialOtherType: PropertyTypeItemType = {
  label: AppStrings.Properties.Subtype.Other,
  icon: RentalsIcon,
  value: PropertyType.COMMERCIAL_OTHER,
  descriptionBullets: [`properties.propertyTypeDescription.${PropertyType.COMMERCIAL_OTHER}.one`],
  dataCy: DataCy.newProperty.propertySelectionButtonGroup.subTypes.commercial.other,
  onPress: _.noop,
  isSelected: false,
  key: PropertyTypeKey.Commercial
};

export const commercialIndustrialItem: PropertyTypeItemType = {
  label: AppStrings.Properties.Subtype.Industrial,
  icon: () => <Icon Source={IndustrialIcon} pathColor="black" />,
  value: PropertyType.COMMERCIAL_INDUSTRIAL,
  descriptionBullets: [
    `properties.propertyTypeDescription.${PropertyType.COMMERCIAL_INDUSTRIAL}.one`,
    `properties.propertyTypeDescription.${PropertyType.COMMERCIAL_INDUSTRIAL}.two`
  ],
  dataCy: DataCy.newProperty.propertySelectionButtonGroup.subTypes.commercial.industrial,
  onPress: _.noop,
  isSelected: false,
  key: PropertyTypeKey.Commercial
};

export const commercialOfficeItem: PropertyTypeItemType = {
  label: AppStrings.Properties.Subtype.Office,
  icon: () => <Icon Source={OfficeIcon} pathColor="black" />,
  value: PropertyType.COMMERCIAL_OFFICE,
  descriptionBullets: [
    `properties.propertyTypeDescription.${PropertyType.COMMERCIAL_OFFICE}.one`,
    `properties.propertyTypeDescription.${PropertyType.COMMERCIAL_OFFICE}.two`
  ],
  dataCy: DataCy.newProperty.propertySelectionButtonGroup.subTypes.commercial.office,
  onPress: _.noop,
  isSelected: false,
  key: PropertyTypeKey.Commercial
};

export const commercialTypes: PropertyTypeItemType[] = [
  commercialIndustrialItem,
  commercialOfficeItem,
  {
    label: AppStrings.Properties.Subtype.Retail,
    icon: () => <Icon Source={RetailIcon} pathColor="black" />,
    value: PropertyType.COMMERCIAL_RETAIL,
    dataCy: DataCy.newProperty.propertySelectionButtonGroup.subTypes.commercial.retail,
    onPress: _.noop,
    isSelected: false,
    key: PropertyTypeKey.Commercial,
    descriptionBullets: [
      `properties.propertyTypeDescription.${PropertyType.COMMERCIAL_RETAIL}.one`,
      `properties.propertyTypeDescription.${PropertyType.COMMERCIAL_RETAIL}.two`
    ]
  },
  {
    label: AppStrings.Properties.Subtype.ShoppingCenter,
    icon: () => <Icon Source={ShoppingCenterIcon} pathColor="black" />,
    value: PropertyType.COMMERCIAL_SHOPPING_CENTER,
    dataCy: DataCy.newProperty.propertySelectionButtonGroup.subTypes.commercial.shoppingCenter,
    onPress: _.noop,
    isSelected: false,
    key: PropertyTypeKey.Commercial,
    descriptionBullets: [
      `properties.propertyTypeDescription.${PropertyType.COMMERCIAL_SHOPPING_CENTER}.one`,
      `properties.propertyTypeDescription.${PropertyType.COMMERCIAL_SHOPPING_CENTER}.two`
    ]
  },
  {
    label: AppStrings.Properties.Subtype.Storage,
    icon: () => <Icon Source={StorageIcon} pathColor="black" />,
    value: PropertyType.COMMERCIAL_STORAGE,
    dataCy: DataCy.newProperty.propertySelectionButtonGroup.subTypes.commercial.storage,
    onPress: _.noop,
    isSelected: false,
    key: PropertyTypeKey.Commercial,
    descriptionBullets: [
      `properties.propertyTypeDescription.${PropertyType.COMMERCIAL_STORAGE}.one`,
      `properties.propertyTypeDescription.${PropertyType.COMMERCIAL_STORAGE}.two`
    ]
  },
  {
    label: AppStrings.Properties.Subtype.Parking,
    icon: () => <Icon Source={ParkingIcon} pathColor="black" />,
    value: PropertyType.COMMERCIAL_PARKING,
    dataCy: DataCy.newProperty.propertySelectionButtonGroup.subTypes.commercial.parking,
    onPress: _.noop,
    isSelected: false,
    key: PropertyTypeKey.Commercial,
    descriptionBullets: [
      `properties.propertyTypeDescription.${PropertyType.COMMERCIAL_PARKING}.one`,
      `properties.propertyTypeDescription.${PropertyType.COMMERCIAL_PARKING}.two`
    ]
  },
  commercialOtherType
];
