import React from "react";

import { SettingPermission } from "@doorloop/dto";

import { CommunicationCenterAnimatedContentDialog } from "screens/settings/communicationCenter/communicationCenterAnimatedContentDialog";
import { getCommunicationCenterFormMenuItems } from "screens/settings/communicationCenter/menuItems";
import { SettingsReadOnlyScope } from "DLUI/permissionScope/readOnlyScope";
import { useCurrentPhoneNumber } from "screens/communicationsCenter/shared/useCurrentPhoneNumber";
import { useUserType } from "../../../../hooks/useUserType";

interface ComponentProps {
  onClose: () => void;
  onBackdropClick: () => void;
  title?: string;
}

export const CommunicationCenterDialog: React.FC<ComponentProps> = ({
  onClose,
  onBackdropClick,
  title
}: ComponentProps) => {
  const { isHOAUser } = useUserType();
  const { currentPhoneNumber, isCurrentPhoneNumberActive } = useCurrentPhoneNumber();

  const sectionItems = getCommunicationCenterFormMenuItems(currentPhoneNumber, isHOAUser);

  return (
    <SettingsReadOnlyScope permission={SettingPermission.communicationCenter}>
      <CommunicationCenterAnimatedContentDialog
        onClose={onClose}
        onBackdropClick={onBackdropClick}
        dialogTitle={title || ""}
        sectionItems={sectionItems}
        isPhoneNumberActive={isCurrentPhoneNumberActive}
      />
    </SettingsReadOnlyScope>
  );
};
