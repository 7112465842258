import type { FC } from "react";
import React, { useState } from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { FastField, useFormikContext } from "formik";
import { FormikSwitchButton, TextField, ValidationIndicator } from "DLUI/form";
import { useTranslation } from "react-i18next";
import { AnimatableView } from "DLUI/animatableView";
import type { PersonDto } from "@doorloop/dto";
import { ePayField } from "screens/outgoingPayments/mappings";
import BasicGrid from "DLUI/listItems/basicGrid";
import FieldWrapper from "DLUI/form/textField/fieldWrapper";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";

interface Props {}

export const EmailSection: FC<Props> = ({}: Props) => {
  const { t } = useTranslation();
  const formikRef = useFormikContext<PersonDto>();
  const userData = useSelector((state: IRootState) => state.auth.currentLoginResponse);
  const [pinEnabled, setPinEnabled] = useState<boolean>(Boolean(formikRef.values.outgoingEPay?.digitalPinRequired));

  const isEmailValidated = userData?.loginEmail !== formikRef?.values?.outgoingEPay?.digitalEmail;

  return (
    <View marginBottom={20}>
      <Text fontSize={18} bold value={AppStrings.Common.OutgoingPayments.EmailSettings} />
      <View marginBottom={20}>
        <FastField
          component={TextField}
          label={t(AppStrings.Common.OutgoingPayments.BillingEmail)}
          name={ePayField("digitalEmail")}
          marginTop={20}
        />
        <ValidationIndicator shouldShow={!isEmailValidated} displayString={AppStrings.Common.BillingEmailError} />
      </View>
      <View marginBottom={10}>
        <FastField
          component={FormikSwitchButton}
          name={ePayField("digitalPinRequired")}
          label={AppStrings.Common.OutgoingPayments.AskThePayeeForAPIN}
          onChange={setPinEnabled}
        />
      </View>
      <AnimatableView shouldShow={pinEnabled}>
        <View flexDirection={"row"}>
          <BasicGrid sm={6}>
            <FieldWrapper type={"endElement"}>
              <FastField
                component={TextField}
                label={t(AppStrings.Common.OutgoingPayments.PINCode)}
                name={ePayField("digitalPin")}
              />
            </FieldWrapper>
          </BasicGrid>
          <BasicGrid sm={6}>
            <FieldWrapper type={"startElement"}>
              <FastField
                component={TextField}
                label={t(AppStrings.Common.OutgoingPayments.HintWillBeVisible)}
                name={ePayField("digitalPinDescription")}
              />
            </FieldWrapper>
          </BasicGrid>
        </View>
      </AnimatableView>
    </View>
  );
};
