"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAllAccountsQuery = void 0;
const class_validator_1 = require("class-validator");
const getAllBaseRequest_query_1 = require("../getAllBaseRequest.query");
const accountClass_enum_1 = require("./accountClass.enum");
const accountType_enum_1 = require("./accountType.enum");
const defaultAccountType_enum_1 = require("./defaultAccountType.enum");
class GetAllAccountsQuery extends getAllBaseRequest_query_1.GetAllBaseQueryRequest {
    constructor(init) {
        super();
        Object.assign(this, init);
        if (!this.page_size) {
            this.page_size = getAllBaseRequest_query_1.DEFAULT_PAGE_SIZE;
        }
    }
}
exports.GetAllAccountsQuery = GetAllAccountsQuery;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], GetAllAccountsQuery.prototype, "filter_name", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllAccountsQuery.prototype, "filter_class", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(accountClass_enum_1.AccountClass, { each: true }),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Array)
], GetAllAccountsQuery.prototype, "filter_classes", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllAccountsQuery.prototype, "filter_type", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(accountType_enum_1.AccountType, { each: true }),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    __metadata("design:type", Array)
], GetAllAccountsQuery.prototype, "filter_types", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Object)
], GetAllAccountsQuery.prototype, "filter_active", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllAccountsQuery.prototype, "filter_parentAccount", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllAccountsQuery.prototype, "filter_ancestorAccount", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllAccountsQuery.prototype, "filter_propertyGroup", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllAccountsQuery.prototype, "filter_property", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllAccountsQuery.prototype, "filter_lease", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllAccountsQuery.prototype, "filter_leaseChargeItem", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllAccountsQuery.prototype, "filter_isSystemAccountOrLeaseChargeItem", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllAccountsQuery.prototype, "filter_leaseDepositItem", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllAccountsQuery.prototype, "filter_DefaultAccountFor", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllAccountsQuery.prototype, "filter_id", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllAccountsQuery.prototype, "filter_openingBalanceEntry", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllAccountsQuery.prototype, "filter_outgoingEPayEnabled", void 0);
