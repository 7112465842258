import React from "react";
import NavigationItem from "DLUI/drawer/navigationItem";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import useStyles from "./styles";
import { Icon } from "DLUI/icon";
import { ChevronLeftWhite } from "../../../assets";
import { useTranslation } from "react-i18next";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import type { DrawerItemType, NavConfigProps, NavSelectionProps } from "DLUI/drawer/types";
import { NavLevels } from "DLUI/drawer/types";
import { useAnalyticsService } from "../../../hooks/useAnalyticsService";
import clsx from "clsx";
interface ComponentProps {
  handleNavClick: ({
    subMenuSelected,
    menuItemSelection,
    mainMenuSelected,
    level
  }: {
    subMenuSelected?: string;
    menuItemSelection?: string;
    mainMenuSelected?: string;
    level?: NavLevels;
  }) => void;
  navConfig: NavConfigProps;
  subMenuSelection?: string;
  selectedMenuItem?: string;
  openedMenuItem?: string;
  groupMarginBottom?: number;
  withActiveSideIndicator?: boolean;
  handleDrawerClose?: () => void;
  isMobile?: boolean;
  isCollapsed?: boolean;
}

const NavigationList: React.FC<ComponentProps> = ({
  navConfig,
  handleNavClick,
  selectedMenuItem,
  subMenuSelection,
  openedMenuItem,
  withActiveSideIndicator = false,
  groupMarginBottom,
  handleDrawerClose,
  isMobile = false,
  isCollapsed
}) => {
  const { dispatchAnalytics } = useAnalyticsService();
  const { t } = useTranslation();
  const classes = useStyles();
  const { isTabletOrMobile } = useResponsiveHelper();
  const { title, groups, showLayerTitle = true, level } = navConfig;

  const handleNavItemClick = (
    navSelection: NavSelectionProps,
    parentNavigationKey?: string,
    navItem?: DrawerItemType
  ) => {
    dispatchAnalytics(
      parentNavigationKey ? "sub_navitem_clicked" : "navitem_clicked",
      {
        name: t(navItem?.label || "")
      },
      { addPlatformLocation: false }
    );

    handleNavClick({ ...navSelection, level, mainMenuSelected: parentNavigationKey });
  };

  const handlePrevMenu = () => {
    handleNavClick({ subMenuSelected: undefined, level: 0, mainMenuSelected: selectedMenuItem });
  };

  return (
    <View noWrap className={"drawer-navigation-list-container"}>
      <View
        flexDirection={"row"}
        noWrap
        autoWidth
        alignItems={"center"}
        marginTop={level === NavLevels.SUB_MENU && isTabletOrMobile ? 20 : 0}
        marginBottom={isTabletOrMobile ? (level === NavLevels.SUB_MENU ? 30 : 2) : 20}
        onClick={handlePrevMenu}
      >
        {level === NavLevels.SUB_MENU && (
          <View autoWidth marginLeft={isTabletOrMobile ? 23 : 5}>
            <Icon Source={ChevronLeftWhite} size={25} pathColor={"white"} />
          </View>
        )}
        {showLayerTitle && title && (
          <Text
            value={title}
            color={"white"}
            bold
            fontSize={isTabletOrMobile ? 18 : 14}
            numberOfLines={2}
            overFlow={"ellipsis"}
            lineBreak={"auto"}
            paddingRight={20}
            marginLeft={0}
          />
        )}
      </View>
      {groups.map(({ items, groupName, showGroupName = true, parentNavigationKey }, idx) => (
        <View
          key={`${groupName}${idx}`}
          marginBottom={groupMarginBottom ?? items?.length > 0 ? 20 : 0}
          className={clsx([classes.groupItem, "drawer-list-group-item-container"])}
          noWrap
        >
          {showGroupName && groupName && items?.length > 0 && (
            <Text
              value={groupName}
              marginBottom={5}
              paddingLeft={isTabletOrMobile ? 30 : 20}
              textTransform={"uppercase"}
              color={"white"}
              fontSize={isTabletOrMobile ? 12 : 10}
              className={classes.groupName}
            />
          )}

          {items.map((navItem, idx) => (
            <NavigationItem
              {...navItem}
              key={`${navItem?.label ? navItem?.label : ""}${idx}`}
              withActiveSideIndicator={withActiveSideIndicator}
              isMinimized={Boolean(subMenuSelection) || Boolean(isCollapsed)}
              isMobile={isMobile}
              isActive={navItem.navKey ? navItem.navKey === selectedMenuItem : false}
              handleDrawerClose={handleDrawerClose}
              isOpen={(openedMenuItem && navItem.navKey === openedMenuItem) || false}
              handleNavClick={(navSelection) => handleNavItemClick(navSelection, parentNavigationKey, navItem)}
            />
          ))}
        </View>
      ))}
    </View>
  );
};

export default NavigationList;
