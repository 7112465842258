import type { GetAllAccountsQuery, GetAllLeaseTenantsQuery, LeaseDto } from "@doorloop/dto";
import { AccountType, BulkPaymentWrapperDto, PaymentMethodNoEpay, SegmentEventTypes } from "@doorloop/dto";
import { Grid } from "@material-ui/core";
import { accountsApi } from "api/accounts";
import { leaseTenantsApi, tenantsApi } from "api/tenantsApi";
import DLButton, { DLButtonColorsEnum } from "DLUI/button/dlButton";
import { FieldSafe } from "DLUI/fieldSafe/fieldSafe";
import { FormikAsyncAutoComplete, Select, TextField } from "DLUI/form";
import { Notes } from "DLUI/notes";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import { useFormikContext } from "formik";
import { motion } from "framer-motion";
import { useEffectAsync } from "hooks/useEffectAsync";
import AppStrings from "locale/keys";
import memoize from "lodash/memoize";
import React from "react";
import { useTranslation } from "react-i18next";
import { analyticsService } from "services/analyticsService";

interface ComponentProps {
  index: number;
  dto: LeaseDto;
  onAddClick: () => void;
  onCancelClick: () => void;
}

const ShowCheckNumberMethods = {
  [PaymentMethodNoEpay.CHECK]: true,
  [PaymentMethodNoEpay.CASHIERS_CHECK]: true,
  [PaymentMethodNoEpay.MONEY_ORDER]: true
};

const getDefaultAccountsForProperty = memoize(
  async (property: string) =>
    await accountsApi.getDefaultAccountsForProperty({
      property
    })
);

const getLeaseTenantsForLease = memoize(
  async (lease: string) =>
    await leaseTenantsApi.getAll({
      filter_lease: lease
    })
);

const ExpandedCard = ({ dto, index, onAddClick, onCancelClick }: ComponentProps) => {
  const { t } = useTranslation();
  const formikContext = useFormikContext<BulkPaymentWrapperDto>();

  /**
   * Pre-load the default values for async autocompletes
   */
  useEffectAsync(async () => {
    if (!formikContext.values.depositToAccount && dto.id) {
      const { data: leaseTenantsResult } = await getLeaseTenantsForLease(dto.id);

      formikContext.setFieldValue("receivedFromTenant", leaseTenantsResult?.data?.[0]?.id);
    }

    if (!formikContext.values.depositToAccount && dto.property) {
      const accounts = await getDefaultAccountsForProperty(dto.property);

      formikContext.setFieldValue("depositToAccount", accounts.data?.bank_operating);
    }
  }, []);

  const useCheck = Boolean(
    formikContext.values.paymentMethod && ShowCheckNumberMethods[formikContext.values.paymentMethod]
  );

  const depositToAccountQueryParams: GetAllAccountsQuery = {
    filter_active: true,
    filter_type: AccountType.ASSET_BANK,
    filter_lease: dto.id
  };

  const receivedFromQueryParams: GetAllLeaseTenantsQuery = {
    filter_lease: dto.id
  };

  return (
    <motion.div
      style={{ width: "100%", padding: 10, boxSizing: "border-box", overflow: "hidden" }}
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.2, ease: "easeIn", type: "tween" }}
      key={"motion-payment-card-" + index}
    >
      <View
        backgroundColor="secondary-gray-light"
        borderRadius={5}
        height={"100%"}
        width={"100%"}
        style={{ boxSizing: "border-box" }}
      >
        <View paddingBottom={20} paddingTop={20} paddingLeft={20} paddingRight={20} gap={15}>
          <Text value={AppStrings.BulkPayments.PaymentDetails} fontSize={16} fontWeight={600} />
          {/* Inputs */}
          <Grid container direction="row" spacing={1}>
            <Grid item xs={6} sm container alignItems="flex-start" justifyContent="center">
              {/* Received From */}

              <FieldSafe
                component={FormikAsyncAutoComplete}
                uniqueIndex={"tenantReceivedFromBulkPayment"}
                dto={BulkPaymentWrapperDto}
                apiHandler={tenantsApi}
                displayNameKey={"name"}
                required
                filterFieldName={"filter_text"}
                filterFieldValue={"name"}
                selectionFields={["id", "class"]}
                defaultValue={formikContext.values.receivedFromTenant ?? "DEFAULT_TENANT"}
                name={"receivedFromTenant"}
                // as any is required because of bad typing in the FormikAsyncAutoComplete
                queryParams={receivedFromQueryParams as any}
                label={t(AppStrings.Leases.LeaseTransaction.Payment.ReceivedFrom)}
              />
            </Grid>
            <Grid item xs={6} sm container alignItems="flex-start" justifyContent="center">
              {/* Deposit Account */}
              <FieldSafe
                name={"depositToAccount"}
                required
                // as any is required because of bad typing in the FormikAsyncAutoComplete
                queryParams={depositToAccountQueryParams as any}
                component={FormikAsyncAutoComplete}
                apiHandler={accountsApi}
                displayNameKey={"fullyQualifiedName"}
                filterFieldName={"filter_text"}
                defaultValue={formikContext.values.depositToAccount ?? "DEFAULT_ACCOUNT"}
                filterFieldValue={"fullyQualifiedName"}
                selectionFields={["id", "class"]}
                groupBy={"accountClass"}
                label={t(AppStrings.Leases.LeaseTransaction.Payment.DepositAccount)}
                uniqueIndex={"depositAccountBulk"}
              />
            </Grid>
            <Grid item xs={6} sm={4} container alignItems="flex-start" justifyContent="space-between" spacing={1}>
              <Grid item xs={useCheck ? 6 : true}>
                {/* Payment Method */}
                <FieldSafe
                  component={Select}
                  name={`paymentMethod`}
                  label={AppStrings.Leases.LeaseTransaction.Payment.PaymentMethod}
                  uniqueKey={"paymentMethodBulk"}
                  dto={BulkPaymentWrapperDto}
                  selectionEnum={PaymentMethodNoEpay}
                  defaultValue=""
                  translationKey={"paymentMethod"}
                />
              </Grid>
              {useCheck && (
                <Grid item xs={6}>
                  {/* Check Number */}
                  <FieldSafe
                    component={TextField}
                    dto={BulkPaymentWrapperDto}
                    name={`checkInfo.checkNumber`}
                    label={t(AppStrings.Leases.LeaseTransaction.Payment.CheckNumberAbbreviated)}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={6} sm container alignItems="flex-start" justifyContent="center">
              {/* Amount Received */}
              <FieldSafe
                component={TextField}
                dto={BulkPaymentWrapperDto}
                name={"amountReceived"}
                required
                label={t(AppStrings.BulkPayments.AmountReceived)}
                formatType="currency"
              />
            </Grid>
          </Grid>

          {/* Note */}
          <div
            onClick={() => analyticsService.track(SegmentEventTypes.BULK_RP_ADD_NOTES_FIELD_CLICKED)}
            style={{ width: "100%" }}
          >
            <FieldSafe
              component={Notes}
              dto={BulkPaymentWrapperDto}
              name="memo"
              backgroundColor={"transparent"}
              rowsMax={1}
              height={20}
            />
          </div>

          {/* Separator */}
          <HorizontalSeparationLine />

          {/* Buttons */}

          <View flexDirection="row" justifyContent="flex-end">
            <View flexDirection="row" gap={5} autoWidth>
              {/* Cancel */}
              <DLButton
                onClick={onCancelClick}
                actionText={AppStrings.Common.Cancel}
                color={DLButtonColorsEnum.NEUTRAL}
              />

              {/* Save */}
              <DLButton onClick={onAddClick} actionText={AppStrings.Common.Save} />
            </View>
          </View>
        </View>
      </View>
    </motion.div>
  );
};

export default ExpandedCard;
