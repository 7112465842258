import { ObjectPermission } from "@doorloop/dto";
import DLButton from "DLUI/button/dlButton";
import EmptyDataView from "DLUI/emptyDataView";
import AppStrings from "locale/keys";
import React from "react";

interface ComponentProps {
  didPressAddNewButton: () => void;
}

const OwnersEmptyDataView: React.FC<ComponentProps> = ({ didPressAddNewButton }: ComponentProps) => (
  <EmptyDataView
    instructionsText={AppStrings.Owners.Screen.EmptydataViewInstructionsText}
    actionButton={
      <DLButton
        clearance={{ permission: ObjectPermission.owners, field: "create" }}
        actionText={AppStrings.Owners.Screen.EmptydataAddNew}
        onClick={didPressAddNewButton}
        disableMobileCollapse
        icons={{ start: { isHidden: false } }}
      />
    }
    defaultHeight={430}
  />
);

export default OwnersEmptyDataView;
