import type { FC } from "react";
import React from "react";
import { View } from "DLUI/view";
import type { FillColors } from "DLUI/icon";
import { Icon } from "DLUI/icon";
import Text from "DLUI/text";
import type { SVGIconComponent } from "assets/icons/types";
import type { ReplaceObjectInterface } from "DLUI/text/text";

interface IconClauseProps {
  IconSrc: SVGIconComponent;
  headerText: string;
  subText: string;
  smallerHeader?: boolean;
  size?: number;
  pathColor?: FillColors;
  replaceObject?: ReplaceObjectInterface;
}

export const IconClause: FC<IconClauseProps> = ({
  IconSrc,
  headerText,
  subText,
  smallerHeader,
  size = 50,
  pathColor,
  replaceObject
}: IconClauseProps) => (
  <View flexDirection={"row"}>
    <View autoWidth marginRight={10}>
      <Icon Source={IconSrc} size={size} pathColor={pathColor} />
    </View>
    <View flex={1} marginBottom={20}>
      <Text fontSize={smallerHeader ? 16 : 18} bold marginBottom={5} value={headerText} />
      <Text fontSize={16} value={subText} replaceObject={replaceObject} />
    </View>
  </View>
);
