import type { FC, PropsWithChildren } from "react";
import React, { useMemo } from "react";
import { Formik } from "formik";
import type { RecurringTransactionType } from "@doorloop/dto";
import { RecurringTransactionDto } from "@doorloop/dto";
import { RecurringDateForm } from "DLUI/recurringDateForm/recurringDateForm";
import { View } from "DLUI/view";
import { RadioGroup } from "DLUI/form";
import { getRecurringSettings } from "DLUI/recurringDateForm/recurringStringsMap";
import { useRecurringState } from "hooks/useRecurringState";
import BasicGrid from "DLUI/listItems/basicGrid";
import { createValidator } from "@doorloop/dto";
import { DataCy } from "@doorloop/dto";

interface ComponentProps {
  type: RecurringTransactionType;
  isWorkOrder?: boolean;
  disableRecurring?: boolean;
  dataCy?: string;
}

export const RecurringDate: FC<ComponentProps> = ({
  type,
  isWorkOrder = false,
  disableRecurring = false,
  children,
  dataCy
}: PropsWithChildren<ComponentProps>) => {
  const { strings } = getRecurringSettings(type, isWorkOrder);
  const { getRecurringData, getIsRecurring, setIsRecurring, editMode } = useRecurringState();
  const isRecurring = getIsRecurring();

  const initFormValues = (): RecurringTransactionDto =>
    getRecurringData() || {
      type,
      noEndDate: false
    };

  const isValid = createValidator(RecurringTransactionDto);

  const radioGroupItems = useMemo(() => {
    const items = [
      {
        label: strings.oneTime,
        value: "single",
        dataCy: DataCy.people.vendors.vendorDetails.oneTimeBillRadioButton
      }
    ];

    if (!disableRecurring) {
      items.push({
        label: strings.recurring,
        value: "recurring",
        dataCy: DataCy.people.vendors.vendorDetails.recurringBillRadioButton
      });
    }

    return items;
  }, []);

  return (
    <>
      {!editMode && (
        <View flexDirection={"row"}>
          <BasicGrid preset={"66612"}>
            <RadioGroup
              didChangeSelection={(value) => setIsRecurring(value === "recurring")}
              flexDirection={"row"}
              uniqueKey={"singleOrRecurring"}
              defaultValue={isRecurring && !disableRecurring ? "recurring" : "single"}
              marginTop={20}
              radioGroupItems={radioGroupItems}
            />
          </BasicGrid>
        </View>
      )}
      {isRecurring ? (
        <Formik initialValues={initFormValues()} onSubmit={() => {}} validate={isValid}>
          <RecurringDateForm type={type} />
        </Formik>
      ) : (
        children
      )}
    </>
  );
};
