import React from "react";
import type { GetAllLeasesQuery, LeaseDto } from "@doorloop/dto";
import { ObjectPermission } from "@doorloop/dto";
import { leasesApi } from "api/leasesApi";
import AppStrings from "locale/keys";
import LeasesEmptyDataView from "./leasesEmptyDataView";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import { FileDownloadIcon } from "../../../../../assets";
import { Feature } from "../../../../../config/featureFlags/featureFlags";
import { handleBulkPrintReportFinish } from "screens/reports/leaseAccountStatementsReport/utils";
import { LeaseListItem } from "screens/leases/leases/leasesList/leaseListItem";
import { DataList } from "DLUI/dataList";
import type { BulkActionDefinition } from "DLUI/dataList/types";

interface ComponentProps {
  filterObj?: GetAllLeasesQuery;
  ListItem?: React.ComponentType<any>;
  didPressListItem?: (leaseId: string, selectedLease: LeaseDto) => void;
  stickyHeaderId?: string;
  renderListHeader?: () => JSX.Element;
  propertyId?: string;
  unitId?: string;
  onLoadingDataStateChange?: (loadingInProgress: boolean) => void;
  scrollableTarget?: string | undefined;
  removeDefaultBottomPadding?: boolean;
  selectableItem?: boolean;
}

const LeasesList: React.FC<ComponentProps> = ({
  filterObj,
  ListItem,
  didPressListItem,
  stickyHeaderId,
  renderListHeader,
  propertyId,
  unitId,
  onLoadingDataStateChange,
  scrollableTarget,
  removeDefaultBottomPadding,
  selectableItem
}: ComponentProps) => {
  const bulkActionDefinitions: BulkActionDefinition[] = [
    {
      actionName: "printLeaseAccountsReceivable",
      actionNameTranslated: AppStrings.BulkActions.LeasePrintAccountReceivable,
      icon: FileDownloadIcon,
      dialogTextColor: "blue",
      confirmHeader: AppStrings.BulkActions.LeasePrintAccountReceivableConfirmation,
      confirmMessageOne: AppStrings.BulkActions.LeasePrintAccountReceivableConfirmationOne,
      confirmMessageMultiple: AppStrings.BulkActions.LeasePrintAccountReceivableConfirmationMultiple,
      onFinishAction: handleBulkPrintReportFinish
    }
  ];

  const _renderListHeader = () => {
    if (renderListHeader) {
      return renderListHeader();
    }
    return <div />;
  };

  const isBulkDownloadTenantStatementFeatureActive = Feature.isActive("BulkDownloadTenantStatement");

  return (
    <RestrictedPermissionAccess clearance={{ permission: ObjectPermission.leases, field: "viewList" }} showNoAccess>
      <DataList
        infinite
        restApi={leasesApi}
        queryFilter={filterObj}
        emptyListView={() => <LeasesEmptyDataView propertyId={propertyId} unitId={unitId} />}
        stickyHeaderProps={{
          id: stickyHeaderId
        }}
        listHeader={_renderListHeader}
        onLoadingDataStateChange={onLoadingDataStateChange}
        scrollElement={scrollableTarget ? document.getElementById(scrollableTarget) : undefined}
        paddingEnd={removeDefaultBottomPadding ? 0 : undefined}
        bulkOperationResourceIdGetter={(item, index) => item.id ?? `${item.name} - ${index}`}
        bulkOperationsProps={{
          hideBulkSelectAll: true,
          minHeight: 190,
          bulkActionDefinitions:
            selectableItem || !isBulkDownloadTenantStatementFeatureActive ? undefined : bulkActionDefinitions
        }}
      >
        {(item, _, resourceId) => {
          const _didPressListItem = (leaseId: string) => {
            if (didPressListItem) {
              didPressListItem(leaseId, item);
            }
          };

          return (
            <div style={{ width: "100%" }}>
              {ListItem ? (
                <ListItem
                  resourceId={resourceId}
                  leaseDto={item}
                  didPressListItem={_didPressListItem}
                  selectableItem={selectableItem}
                />
              ) : (
                <LeaseListItem resourceId={resourceId} leaseDto={item} stickyHeaderId={stickyHeaderId} />
              )}
            </div>
          );
        }}
      </DataList>
    </RestrictedPermissionAccess>
  );
};

export default LeasesList;
