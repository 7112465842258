import React from "react";
import { Notification } from "DLUI/notification/notification";
import { LearnMoreText } from "DLUI/text/learnMoreText";
import { ArticleIdsEnum } from "DLUI/screen/helpPanel/types";
import AppStrings from "locale/keys";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";

interface Props {
  creditsLeft: number;
  creditsToBeUsed: number;
  nothingToSend: boolean;
}

const LearnMoreLink = ({ creditsLeft, creditsToBeUsed }: { creditsLeft: number; creditsToBeUsed: number }) => {
  const { isMobile } = useResponsiveHelper();

  return (
    <LearnMoreText
      articleId={ArticleIdsEnum.ANNOUNCEMENTS_DOCS}
      fontSize={16}
      value={AppStrings.Announcements.creditsUsedOutOfCreditsLeftMessage}
      marginTop={isMobile ? 10 : 0}
      replaceObject={{
        creditsLeft,
        creditsToBeUsed
      }}
    />
  );
};

const CreditNotification: React.FC<Props> = ({ creditsLeft, creditsToBeUsed, nothingToSend }: Props) => {
  const customLabel = () => LearnMoreLink({ creditsLeft, creditsToBeUsed });

  return <Notification LabelComponent={customLabel} shouldShow={!nothingToSend} iconSize={50} />;
};

export default CreditNotification;
