import type { GetAllFilesQuery, LinkedResourceType } from "@doorloop/dto";
import {
  FileDto,
  GetPdfFromHtmlRequest,
  GetRentalApplicationApplicantReportDataQuery,
  GetRentalApplicationReportDataQuery,
  LinkedResourceDto,
  OwnerPortalServerRoutes,
  ServerRoutes,
  TenantPortalServerRoutes
} from "@doorloop/dto";
import { RestApiBase } from "./restApiBase";
import { ApiResult } from "./apiResult";
import type { FileListItemProps } from "DLUI/dropZone";
import type { DraggableFileListItem } from "screens/esignatures/newSignatureRequest/documents/uploadFilesView/draggableFileListItemComponent";
import { usersApi } from "./usersApi";
import { ownersApi } from "./ownersApi";
import type { ApiToastsProps } from "./apiHelper";
import { apiHelper } from "./apiHelper";
import { tagsApi } from "api/tagsApi";
import { propertiesApi } from "api/propertiesApi";
import { vendorsApi } from "api/vendorsApi";
import { unitsApi } from "api/unitsApi";
import { tenantsApi } from "api/tenantsApi";
import { leasesApi } from "api/leasesApi";
import AppStrings from "../locale/keys";
import type { ExternalFilesInterface } from "../utils/zipfiles";
import { triggerDownload } from "api/utils/apiUtils";

const FileUploadTimout = process.env.REACT_APP_MAX_UPLOAD_TIMEOUT;
const MaxFileSize = process.env.REACT_APP_MAX_FILE_UPLOAD_SIZE;

export class FilesApi extends RestApiBase<FileDto, GetAllFilesQuery> {
  constructor(route?: string) {
    super(
      route || ServerRoutes.FILES,
      route ? [] : [usersApi, ownersApi, tagsApi, propertiesApi, vendorsApi, unitsApi, usersApi, tenantsApi, leasesApi]
    );
    this.restRoute = route || ServerRoutes.FILES;
  }

  generateDownloadLink(token: string) {
    return `${window.location.protocol}//${window.location.host.replace(":3000", ":3001")}${
      this.restRoute
    }/download/${token}`;
  }

  async getAllAllowedDownloadUrls(resourceId: string): Promise<ExternalFilesInterface[]> {
    const data: ApiResult<Array<{ token: string; name: string }>> = await apiHelper.axiosGet(
      `${this.restRoute}/getAllAllowedFiles?filter_resourceId=${resourceId}`
    );

    return (
      data.data?.map(({ token, name }) => {
        return {
          downloadUrl: this.generateDownloadLink(token),
          fileName: name
        };
      }) || []
    );
  }

  async update(
    id: string,
    data: FileDto,
    toasts?: ApiToastsProps<FileDto>,
    subRoute = "/"
  ): Promise<ApiResult<FileDto>> {
    return await apiHelper.axiosPut<FileDto>({ url: this.restRoute + subRoute + id, data });
  }

  async delete(id: string, toasts?: ApiToastsProps<FileDto>, subRoute = "/"): Promise<ApiResult<{}>> {
    return await apiHelper.axiosDelete<{}>({
      url: this.restRoute + subRoute + id,
      options: {
        timeout: FileUploadTimout
      }
    });
  }

  async getDownloadUrl(id: string): Promise<ApiResult<string>> {
    const downloadUrl = await apiHelper.axiosGet(`${this.restRoute}/${id}/token`);

    if (!downloadUrl.status) {
      return downloadUrl;
    }

    const url = this.generateDownloadLink(downloadUrl.data?.token);

    return new ApiResult<string>(url);
  }

  async getObjectUrl(id: string): Promise<ApiResult<string>> {
    const downloadUrl = await this.getDownloadUrl(id);

    if (!downloadUrl.data) {
      return downloadUrl;
    }

    const result = await apiHelper.axiosGet(
      downloadUrl.data,
      {},
      {
        responseType: "blob"
      }
    );

    if (!result.status) {
      return result;
    }

    const objectUrl = URL.createObjectURL(result.data);

    return new ApiResult<string>(objectUrl);
  }

  async downloadFile(id: string): Promise<void> {
    const { data: url } = await this.getDownloadUrl(id);

    url && window.open(url);
  }

  async getPdfFromHtml(htmlString: string, fileName: string): Promise<ApiResult<{}>> {
    const data = new GetPdfFromHtmlRequest();
    data.html = htmlString;
    const response = await apiHelper.axiosPost({
      url: ServerRoutes.REPORTS_PDF,
      data,
      options: {
        responseType: "blob"
      }
    });
    triggerDownload(response.data, fileName);
    return response;
  }

  async getRentalApplicationReportPdf(
    reportName: string,
    rentalApplicationId: string,
    fileName: string
  ): Promise<ApiResult<{}>> {
    const params = new GetRentalApplicationReportDataQuery();
    params.reportName = reportName;
    params.rentalApplicationId = rentalApplicationId;

    const response = await apiHelper.axiosGet(ServerRoutes.RENTAL_APPLICATIONS_GET_REPORT_PDF, params, {
      responseType: "blob"
    });
    triggerDownload(response.data, fileName);
    return response;
  }

  async getRentalApplicationShareScreeningReportPdf(
    reportName: string,
    token: string,
    fileName: string
  ): Promise<ApiResult<{}>> {
    const params = new GetRentalApplicationApplicantReportDataQuery({
      token,
      reportName
    });

    const response = await apiHelper.axiosGet(ServerRoutes.RENTAL_APPLICATIONS_APPLICANT_GET_REPORT_PDF, params, {
      responseType: "blob"
    });
    triggerDownload(response.data, fileName);
    return response;
  }

  async downloadSignatureRequestPdf(signatureRequestId: string, fileName: string): Promise<ApiResult<{}>> {
    const response = await apiHelper.axiosGet(`${ServerRoutes.ESIGNATURE_REQUESTS}/${signatureRequestId}/pdf`);
    if (response.data && response.data.file_url) {
      window.open(response.data.file_url);
    }

    return response;
  }

  uploadSignatureRequestFiles = async (
    files: DraggableFileListItem[],
    resourceId: string,
    resourceType: LinkedResourceType
  ): Promise<Array<ApiResult<FileDto>>> => {
    try {
      const filesRequests: Array<ReturnType<typeof this.upload>> = [];
      for (let i = 0; i < files.length; i++) {
        const linkedResourceDto: LinkedResourceDto = new LinkedResourceDto(resourceId, resourceType);
        filesRequests.push(
          this.upload(files[i].fileData, linkedResourceDto, "/", {
            rank: i + 1
          })
        );
      }

      return await Promise.all(filesRequests);
    } catch (e) {
      return await Promise.reject(e);
    }
  };

  uploadFiles = async (
    files: FileListItemProps[],
    resourceId: string,
    resourceType: LinkedResourceType,
    maxFileSize?: number | string,
    token?: string,
    subRoute?: string
  ): Promise<any> => {
    try {
      const filesRequests: any[] = [];
      for (let i = 0; i < files.length; i++) {
        const linkedResourceDto: LinkedResourceDto = new LinkedResourceDto(resourceId, resourceType);
        if (files[i] && files[i].fileData) {
          const { fileData, ...restFileDetails } = files[i];

          filesRequests.push(
            this.upload(
              fileData!,
              linkedResourceDto,
              subRoute ? subRoute : "",
              undefined,
              maxFileSize,
              token,
              restFileDetails
            )
          );
        }
      }

      return await Promise.all(filesRequests);
    } catch (e) {
      return await Promise.reject(e);
    }
  };

  async upload(
    file: File,
    linkedResourceDto: LinkedResourceDto,
    subRoute = "/",
    metaData: FileDto = { rank: 1 },
    maxFileSize?: number | string,
    token?: string,
    fileDetails?: FileListItemProps
  ): Promise<ApiResult<FileDto>> {
    const fileMaxSizeInBytes = parseInt(maxFileSize as string) || parseInt(MaxFileSize as string) || 50 * 1024 * 1024;
    if (file.size > fileMaxSizeInBytes) {
      return new ApiResult<FileDto>(undefined, "File size cannot exceed 50MB", 401);
    }

    const data = new FormData();
    data.append("file", file);
    const fileDto = new FileDto(metaData);
    fileDto.name ||= file.name;
    fileDto.linkedResource = linkedResourceDto;
    fileDto.rank ??= 1;
    fileDto.notes ||= fileDetails?.label;
    fileDto.createdByName = fileDetails?.createdBy || "";

    if (fileDetails?.isSharedWithTenant !== undefined) {
      fileDto.isSharedWithTenant = fileDetails?.isSharedWithTenant;
    }

    if (fileDetails?.metadata) {
      fileDto.metadata = fileDetails.metadata;
    }

    if (!fileDto.notes && !fileDetails?.label) {
      delete fileDto.notes;
    }

    for (const key of Object.keys(fileDto)) {
      const value = fileDto[key];
      if (typeof value === "object" && value !== null) {
        for (const objectKey of Object.keys(value)) {
          data.append(key + "[" + objectKey + "]", value[objectKey]);
        }
      } else {
        data.append(key, value);
      }
    }

    const headers = token
      ? {
          Authorization: `bearer ${token}`
        }
      : undefined;

    return await apiHelper.axiosPost<FileDto>({
      url: this.restRoute + subRoute,
      data,
      options: { timeout: FileUploadTimout },
      headers,
      toasts: { isHidden: true, translationKey: AppStrings.Toasts.custom.upload.file.POST }
    });
  }
}

export const filesApi = new FilesApi();

export const tenantPortalFilesApi = new FilesApi(TenantPortalServerRoutes.FILES);

export const ownerPortalFilesApi = new FilesApi(OwnerPortalServerRoutes.FILES);
