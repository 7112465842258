import type {
  CSSVarValue,
  DynamicStyles,
  StylesVariables,
  StyleVariables
} from "styles/dynamicStyling/dynamicStyles.types";
import _ from "lodash";
import { kendoStyleVariables } from "styles/dynamicStyling/kendoStyling.config";

const defaultStyle: StyleVariables = {
  "primary-bg": "blue",
  "primary-text": "40px",
  "primary-border-radius": "10px",
  ...kendoStyleVariables
};

const barely: StyleVariables = {
  ...defaultStyle,
  "primary-text": "86px"
};

const dynamicStylesCSSVariables: StylesVariables = {
  default: defaultStyle,
  barely
};

export const getCSSVarDefinition = _.memoize(
  (style: DynamicStyles): string => `
  :root {
    ${_.map(dynamicStylesCSSVariables[style], (value, variable) => `--${variable}: ${value};`).join("\n")}
  }
`
);

export const isCSSVarValue = _.memoize((value = ""): value is CSSVarValue => /^var\(--[\w\-]+\)$/.test(value));
