import type { TextColor } from "DLUI/text/text";
import clsx from "clsx";
import { useTypedTranslation } from "locale";
import Text from "DLUI/text";
import { useManagementFeesStyles } from "screens/settings/managementFees/useStyles";
import { useGeneralStyles } from "styles/generalStyles";
import { formatNumberToFractionWithCommas } from "utils/formatNumberToFractionWithCommas";
import { DataCy } from "@doorloop/dto";

interface SubtotalSectionProps {
  rulesSubtotal: number;
  minimumFeeAmount: number;
  isChargeFeeAmount: boolean;
  textColor: TextColor;
}

export const SubtotalSection: React.FC<SubtotalSectionProps> = ({
  rulesSubtotal,
  minimumFeeAmount,
  isChargeFeeAmount,
  textColor
}) => {
  const { t } = useTypedTranslation();
  const classes = useManagementFeesStyles();
  const generalClasses = useGeneralStyles();

  return (
    <div className={classes.columnContainer}>
      <div className={clsx(generalClasses.flexRow, generalClasses.spaceBetween)}>
        <Text value={t("settings.generalSettings.subtotal")} fontSize={12} fontWeight={700} color={textColor} />
        <Text
          value={`$${formatNumberToFractionWithCommas(rulesSubtotal)}`}
          fontSize={12}
          fontWeight={700}
          color={textColor}
          dataCy={DataCy.globalSettings.managementFees.sampleCalculator.subtotalAmount}
        />
      </div>
      {isChargeFeeAmount && (
        <div
          className={clsx(generalClasses.flexRow, generalClasses.spaceBetween)}
          data-cy={DataCy.globalSettings.managementFees.sampleCalculator.minimumFee.container}
        >
          <Text
            value={`${t("settings.generalSettings.managementFees.minimumFee")} ($${formatNumberToFractionWithCommas(minimumFeeAmount)})`}
            fontSize={12}
            fontWeight={700}
            color={textColor}
            dataCy={DataCy.globalSettings.managementFees.sampleCalculator.minimumFee.title}
          />
          <Text
            value={`$${formatNumberToFractionWithCommas(Math.max(minimumFeeAmount - rulesSubtotal, 0))}`}
            fontSize={12}
            fontWeight={700}
            color={textColor}
            dataCy={DataCy.globalSettings.managementFees.sampleCalculator.minimumFee.amount}
          />
        </div>
      )}
    </div>
  );
};
