import type { BulkChargeAllocationDto } from "@doorloop/dto";
import type { BulkCreditAllocationDto } from "@doorloop/dto";
import type { LeaseTransactionWithUnitBaseDto } from "@doorloop/dto";
import { EditableTable } from "DLUI/editableTable/editableTable";
import EmptyDataView from "DLUI/emptyDataView";
import { useFormikContext } from "formik";
import React, { useEffect, useMemo } from "react";
import AppStrings from "../../../../../locale/keys";
import { EditableTransactionTableColumns } from "./tableColumns";

interface ComponentProps {
  isLoading: boolean;
  transactions: LeaseTransactionWithUnitBaseDto[];
  onTotalAmountChanged: (totalAmount: number) => void;
}

export const EditableTransactionTable: React.FC<ComponentProps> = ({
  transactions,
  isLoading,
  onTotalAmountChanged
}) => {
  const formikContext = useFormikContext<BulkChargeAllocationDto | BulkCreditAllocationDto>();
  const [totalAmount, setTotalAmount] = React.useState(0);

  const selectedIndices = useMemo(
    () =>
      // loop over formikContext.values.transactions and create an object that looks like Record<number, boolean> where boolean is true if transaction.isSelect is true
      formikContext.values.transactions.reduce(
        (acc, transaction, index) => (transaction.isSelected ? { ...acc, [index]: true } : acc),
        {}
      ),
    [formikContext.values.transactions]
  );

  /*
   * Calculate the total amount of the selected transactions
   */
  useEffect(() => {
    const { transactions } = formikContext.values;
    const totalAmount =
      transactions.reduce(
        (acc, currentValue) => acc + (currentValue?.isSelected ? currentValue.lines?.[0]?.amount ?? 0 : 0),
        0
      ) || 0;

    setTotalAmount(totalAmount);

    onTotalAmountChanged(totalAmount);
  }, [formikContext.values.transactions]);

  const isIndexSelected = (index: number): boolean => Boolean(formikContext.values.transactions?.[index]?.isSelected);

  const onSelectionChanged = (selected: boolean, index: number) => {
    // Ignore selection of disabled rows
    if (!transactions[index].lease) return;

    formikContext.setFieldValue(`transactions[${index}].isSelected`, !isIndexSelected(index), false);
  };

  const onSelectAllClicked = () => {
    const allSelected = transactions.filter((t) => t.lease).every((transaction, index) => isIndexSelected(index));
    transactions.forEach((transaction, index) => {
      // Ignore selection of disabled rows
      if (!transaction.lease) return;
      formikContext.setFieldValue(`transactions[${index}].isSelected`, !allSelected, false);
    });
  };

  return (
    <EditableTable
      columns={EditableTransactionTableColumns}
      data={transactions}
      headerRowHeight={50}
      checkboxSize={25}
      rowHeight={52}
      fontSize={14}
      isLoading={isLoading}
      singularItemName={AppStrings.Common.Unit}
      pluralItemName={AppStrings.Common.Units}
      onSelectAllClicked={onSelectAllClicked}
      selectedIndices={selectedIndices}
      onSelectionChanged={onSelectionChanged}
      totalDisplayAmount={totalAmount.toString()}
      totalDisplayFormatType="currency"
      noDataComponent={
        <EmptyDataView defaultHeight={500} instructionsText={AppStrings.Leases.LeaseCharge.NoPropertySelected} />
      }
    />
  );
};
