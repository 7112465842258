const menuItems = [
  { title: "Owners", url: "/owners" },
  { title: "Properties", url: "/properties" },
  { title: "Tenants", url: "/tenants" },
  { title: "Vendors", url: "/vendors" },
  { title: "Accounting", url: "/accounting" },
  { title: "Maintenance", url: "/maintenance" },
  { title: "Reports", url: "/reports" }
];
export default menuItems;
