import type { KeyboardEvent, ReactNode } from "react";
import React, { useRef, useState } from "react";
import { View } from "DLUI/view";
import "./styles.css";
import { Icon } from "DLUI/icon";
import { EditSimpleIcon } from "assets/icons";
import type { FormikProps } from "formik";
import { Form, Formik, useFormikContext } from "formik";
import type { BaseDto, GetAllBaseQueryRequest } from "@doorloop/dto";
import type { RestApiBase } from "api/restApiBase";
import { useShakeEffect } from "@/hooks/useShakeEffect";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import InputActionPanel from "DLUI/form/editableInput/inputActionPanel";
import type { ApiResult } from "api/apiResult";
import _ from "lodash";
import { useResponsiveHelper } from "@/contexts/responsiveContext";
import { useOnClickOutside } from "usehooks-ts";

interface EditableInputComponentProps<T> {
  renderInput: (isLoading: boolean) => ReactNode;
  renderActionPanel?: (isLoading: boolean, onClose: () => void, onConfirm: () => void) => ReactNode;
  renderChildren: (values: T, isLoading: boolean) => ReactNode;
  onEdit?: () => void;
  onCancel?: () => void;
  onError?: (result: ApiResult<T & BaseDto>) => void;
  onConfirm?: (formikRef: FormikProps<T>) => void;
  onSaveSuccess?: (result: T) => void;
  dto: T & BaseDto;
  api: RestApiBase<T & BaseDto, GetAllBaseQueryRequest>;
  disableEdit?: boolean;
  disableSave?: boolean;
  cancelOnClickOutside?: boolean;
}

const EditableInputComponent = <T extends BaseDto>({
  renderChildren,
  renderInput,
  renderActionPanel,
  onEdit,
  onCancel,
  onConfirm,
  onError,
  onSaveSuccess,
  dto,
  api,
  disableEdit = false,
  disableSave = false,
  cancelOnClickOutside = true
}: EditableInputComponentProps<T>) => {
  const formikRef = useFormikContext<T>();
  const { values, resetForm, validateForm } = formikRef;
  const [isEditMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { ShakeEffectWrapper, runShakeEffect } = useShakeEffect();
  const { isMobile } = useResponsiveHelper();

  const componentRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(componentRef, () => {
    if (cancelOnClickOutside) {
      setEditMode(false);

      if (!isLoading) {
        onCancel?.();
        resetForm();
      }
    }
  });

  const handleEditClicked = () => {
    if (!isEditMode && !disableEdit) {
      onEdit?.();
      setEditMode(true);
    }
  };

  const handleCloseClicked = () => {
    onCancel?.();
    setEditMode(false);
    resetForm();
  };

  const handleConfirmClicked = async () => {
    const errors = await validateForm();
    const isValid = _.isEmpty(errors);

    if (isValid && !isLoading && !disableSave) {
      onConfirm?.(formikRef);

      setIsLoading(true);
      const result = await api?.update(dto.id!, values);
      setIsLoading(false);

      if (!result.status) {
        resetForm();
        onError?.(result);
      } else {
        onSaveSuccess?.(result.data!);
        resetForm({ values: result.data! });
        setEditMode(false);
      }
    } else {
      runShakeEffect();
    }
  };

  const handleLeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleConfirmClicked();
    } else if (event.key === "Escape") {
      handleCloseClicked();
    }
  };

  return (
    <ShakeEffectWrapper>
      <View
        domRef={componentRef}
        flexDirection={"row"}
        noWrap
        gap={16}
        alignItems={"center"}
        className="container"
        onClick={handleEditClicked}
        onKeyDown={handleLeyDown}
      >
        {isEditMode && (
          <View flexDirection={"row"} noWrap gap={5}>
            {renderInput?.(isLoading)}
            {renderActionPanel ? (
              renderActionPanel(isLoading, handleCloseClicked, handleConfirmClicked)
            ) : (
              <InputActionPanel isLoading={isLoading} onClose={handleCloseClicked} onConfirm={handleConfirmClicked} />
            )}
          </View>
        )}
        {!isEditMode && (
          <View flexDirection="row">
            <View flex={9}>{renderChildren(values, isLoading)}</View>
            {!disableEdit && (
              <View alignItems="flex-end" flex={1}>
                <Icon size={13} Source={EditSimpleIcon} className={isMobile ? "" : "hideEditIcon"} />
              </View>
            )}
          </View>
        )}
      </View>
    </ShakeEffectWrapper>
  );
};

export interface EditableInputProps<T> extends EditableInputComponentProps<T> {
  validate: (values: T, t: TFunction<"translation", undefined>) => void;
}

const EditableInput = <T extends BaseDto>({ validate, dto, ...rest }: EditableInputProps<T>) => {
  const { t } = useTranslation();

  return (
    <Formik onSubmit={() => {}} initialValues={dto} validate={(values) => validate(values, t)} enableReinitialize>
      <Form autoComplete={"off"} style={{ width: "100%" }}>
        <EditableInputComponent dto={dto} {...rest} />
      </Form>
    </Formik>
  );
};

export default EditableInput;
