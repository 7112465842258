import { handleToast } from "store/toast/actions";
import { useDispatch } from "react-redux";
import type { CashPaymentLevels } from "api/cashPaymentsApi";
import { cashPaymentsApi } from "api/cashPaymentsApi";
import AppStrings from "../locale/keys";
import { useState } from "react";
import { tenantPortalPaymentsApi } from "api/tenantPortal/tenantPortalPaymentsApi";
import type { CashPaymentDetailsDto } from "@doorloop/dto";
import { LoadingStatus } from "common/native/types";
import { useFeatureFlag } from "./useFeatureFlag";
import { usePrint } from "hooks/usePrint";

interface UseCashPaymentsArgs {
  leaseId?: string;
  chooseTenantPortalApi?: boolean;
}

export const useCashPayment = ({ leaseId, chooseTenantPortalApi }: UseCashPaymentsArgs = {}) => {
  const dispatch = useDispatch();
  const [isCashPaymentEnabled, setIsCashPaymentEnabled] = useState<boolean>();
  const [getDetailsLoadingStatus, setGetDetailsLoadingStatus] = useState<LoadingStatus>(LoadingStatus.initial);
  const [cashPaymentDetails, setCashPaymentDetails] = useState<CashPaymentDetailsDto[]>();
  const { isFeatureFlagActive } = useFeatureFlag("CashPayments");

  const { printLoadingStatus, printDocumentFromServer } = usePrint();

  const printDocument = async (tenantId?: string) => {
    if (!isFeatureFlagActive) {
      return false;
    }

    if (!(tenantId && leaseId)) {
      dispatch(
        handleToast({
          severity: "error",
          translationKey: AppStrings.Common.DownloadFailed
        })
      );
      return;
    }

    const api = chooseTenantPortalApi ? tenantPortalPaymentsApi : cashPaymentsApi;
    await printDocumentFromServer(async () => await api.downloadCashPaymentPdf(leaseId, tenantId));
  };

  const checkIfCashPaymentIsEnabled = async (idType: CashPaymentLevels, id: string) => {
    if (!isFeatureFlagActive) {
      return false;
    }

    const api = chooseTenantPortalApi ? tenantPortalPaymentsApi : cashPaymentsApi;
    const isEnabledResponse = await api.getIsCashPaymentsEnabled(idType, id);

    setIsCashPaymentEnabled(isEnabledResponse.data?.isEnabled);

    return isEnabledResponse.data?.isEnabled;
  };

  const initiateCashPaymentDetails = async (tenantId?: string) => {
    if (!leaseId || !isFeatureFlagActive) {
      return;
    }

    setGetDetailsLoadingStatus(LoadingStatus.loading);

    const api = chooseTenantPortalApi ? tenantPortalPaymentsApi : cashPaymentsApi;
    const response = await api.getCashPaymentDetails({
      filter_leaseId: leaseId,
      filter_tenantId: tenantId
    });

    if (!response.status) {
      setGetDetailsLoadingStatus(LoadingStatus.error);

      dispatch(
        handleToast({
          severity: "error",
          translationKey: AppStrings.Common.CouldNotLoadDataError
        })
      );
      return;
    }

    setGetDetailsLoadingStatus(LoadingStatus.loaded);

    setCashPaymentDetails(response.data);
  };

  return {
    getDetailsLoadingStatus,
    printLoadingStatus,
    cashPaymentDetails,
    isCashPaymentEnabled,
    initiateCashPaymentDetails,
    checkIfCashPaymentIsEnabled,
    printDocument
  };
};
