import { useAtom } from "jotai";
import { confirmationDialogAtom } from "@/utils/atoms";
import type { ConfirmationDialogConfigs } from "@/utils/navigation";
import { NavigationManager } from "@/utils/navigation";
import { tenantPortalNavigation } from "@/utils/tenantPortalNavigation";
import { ownerPortalNavigation } from "@/utils/ownerPortalNavigation";

export type ShowConfirmationHandler = (pathname: string, configs: ConfirmationDialogConfigs) => void;

type UseConfirmationDialogType = "rentals" | "owner" | "tenant";

const NAVIGATION_MAP: Record<UseConfirmationDialogType, (pathname: string) => void> = {
  rentals: (pathname) => {
    NavigationManager.showConfirmDialog(pathname);
  },
  owner: (pathname) => {
    ownerPortalNavigation.showConfirmDialog(pathname);
  },
  tenant: (pathname) => {
    tenantPortalNavigation.showConfirmDialog(pathname);
  }
};

export const useConfirmationDialog = (type: UseConfirmationDialogType = "rentals") => {
  const [confirmationData, setConfirmationData] = useAtom(confirmationDialogAtom);

  const showConfirmationDialog: ShowConfirmationHandler = (pathname: string, configs: ConfirmationDialogConfigs) => {
    setConfirmationData(configs);

    NAVIGATION_MAP[type](pathname);
  };

  const clearConfirmationDialogData = () => {
    setConfirmationData(null);
  };

  return { confirmationData, showConfirmationDialog, clearConfirmationDialogData };
};
