"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.widgetAccessMap = void 0;
const widgetType_enum_1 = require("../dashboards/widgetType.enum");
exports.widgetAccessMap = {
    [widgetType_enum_1.WidgetType.ACCOUNTING_ACCOUNTS_PAYABLE]: {
        reports: ["accountsPayable"]
    },
    [widgetType_enum_1.WidgetType.ACCOUNTING_ACCOUNTS_RECEIVABLE]: {
        reports: ["accountsReceivable"]
    },
    [widgetType_enum_1.WidgetType.RENTAL_APPLICATION_OVERVIEW]: {
        reports: ["prospect"]
    },
    [widgetType_enum_1.WidgetType.ACCOUNTING_BANKING_STATS]: {
        reports: ["banking"]
    },
    [widgetType_enum_1.WidgetType.LEASING_DRAFT_LEASES_FUNNEL]: {
        objects: [{ objectName: "leases", access: "viewList" }]
    },
    [widgetType_enum_1.WidgetType.LEASES_WITH_OUTSTANDING_BALANCES]: {
        objects: [{ objectName: "leases", access: "viewList" }]
    },
    [widgetType_enum_1.WidgetType.LEASING_UPCOMING_RENEWALS]: {
        objects: [{ objectName: "leases", access: "viewList" }]
    },
    [widgetType_enum_1.WidgetType.LEASING_NEXT_7_DAYS]: {
        objects: [{ objectName: "leases", access: "viewList" }]
    },
    [widgetType_enum_1.WidgetType.RENTALS_TOTAL_OCCUPANCY_RATE]: {
        reports: ["rentRoll"]
    },
    [widgetType_enum_1.WidgetType.PEOPLE_PROSPECTS_BY_LEAD_SOURCE]: {
        objects: [{ objectName: "prospects", access: "viewList" }]
    },
    [widgetType_enum_1.WidgetType.PEOPLE_PROSPECT_BY_PROPERTY]: {
        objects: [{ objectName: "prospects", access: "viewList" }]
    },
    [widgetType_enum_1.WidgetType.PEOPLE_PROSPECT_FUNNEL]: {
        objects: [{ objectName: "prospects", access: "viewList" }]
    },
    [widgetType_enum_1.WidgetType.PEOPLE_PROSPECT_STATS]: {
        objects: [{ objectName: "prospects", access: "viewList" }]
    },
    [widgetType_enum_1.WidgetType.ACCOUNTING_RECENT_PAYMENTS]: {
        objects: [{ objectName: "leasePayments", access: "viewList" }]
    },
    [widgetType_enum_1.WidgetType.LEASING_RENTAL_APPLICATIONS_FUNNEL]: {
        objects: [{ objectName: "rentalApplications", access: "viewList" }]
    },
    [widgetType_enum_1.WidgetType.RENTALS_STATS]: {
        objects: [{ objectName: "units", access: "viewList" }]
    },
    [widgetType_enum_1.WidgetType.GENERAL_SUGGESTED]: {},
    [widgetType_enum_1.WidgetType.GENERAL_THINGS_TO_DO]: {},
    [widgetType_enum_1.WidgetType.TASKS_ASSIGNED_TO_ME]: {
        objects: [
            { objectName: "tasks", access: "viewList" },
            { objectName: "workOrders", access: "viewList" },
            { objectName: "ownerRequests", access: "viewList" },
            { objectName: "tenantRequests", access: "viewList" }
        ]
    },
    [widgetType_enum_1.WidgetType.TASKS_MY_NEXT_7_DAYS]: {
        objects: [
            { objectName: "tasks", access: "viewList" },
            { objectName: "workOrders", access: "viewList" },
            { objectName: "ownerRequests", access: "viewList" },
            { objectName: "tenantRequests", access: "viewList" }
        ]
    },
    [widgetType_enum_1.WidgetType.TASKS_OPEN_BY_STATUS]: {
        objects: [
            { objectName: "tasks", access: "viewList" },
            { objectName: "workOrders", access: "viewList" },
            { objectName: "ownerRequests", access: "viewList" },
            { objectName: "tenantRequests", access: "viewList" }
        ]
    },
    [widgetType_enum_1.WidgetType.RECENT_TENANT_REQUESTS]: {
        objects: [{ objectName: "tenantRequests", access: "viewList" }]
    },
    [widgetType_enum_1.WidgetType.PEOPLE_TENANT_PORTAL_STATS]: {
        objects: [{ objectName: "tenants", access: "viewList" }]
    },
    [widgetType_enum_1.WidgetType.PEOPLE_TENANT_STATS]: {
        objects: [{ objectName: "tenants", access: "viewList" }]
    },
    [widgetType_enum_1.WidgetType.RENTALS_VACANCIES_BY_PROPERTY]: {
        reports: ["rentRoll"]
    },
    [widgetType_enum_1.WidgetType.PROFIT_AND_LOSS]: {
        reports: ["profitAndLoss"]
    }
};
