import React, { useEffect } from "react";
import type { FieldProps } from "formik";
import { getIn } from "formik";

import type { SelectInputProps } from "./selectInput";
import SelectInput from "./selectInput";
import _ from "lodash";

interface ComponentProps extends Omit<SelectInputProps, "onChange" | "value">, FieldProps<any> {
  onChange?: (selectedValue: string) => void;
}

const SelectField: React.FC<ComponentProps> = ({
  selectionEnum,
  form,
  field,
  onChange,
  viewOnly,
  defaultValue,
  ...props
}) => {
  const { value: selectValue } = form.getFieldMeta<string>(field.name) ?? null;
  const errorText = getIn(form.errors, field.name);
  const touchedVal = getIn(form.touched, field.name);

  useEffect(() => {
    if (defaultValue && !selectValue) {
      form.setFieldValue(field.name, String(defaultValue));
    }
  }, [form, selectValue]);

  const handleChange: SelectInputProps["onChange"] = (event) => {
    field.onChange(event);

    if (_.isString(event.target.value)) {
      onChange?.(event.target.value);
    }
  };

  return (
    <SelectInput
      {...props}
      {...field}
      onChange={handleChange}
      selectionEnum={selectionEnum}
      viewOnly={viewOnly}
      errorText={touchedVal && errorText}
    />
  );
};

export default SelectField;
