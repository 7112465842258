import React, { useCallback } from "react";

import { Radio, RadioGroup } from "@material-ui/core";

import { TaskViewObjectAccess } from "@doorloop/dto";

import Text from "DLUI/text";
import { CheckRound, CheckRoundInactive, Private, PrivateInactive, XIcon, XIconInactive } from "assets/icons";
import { View } from "DLUI/view";

import "./reportSettingsRadioGroup.styles.css";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { Icon } from "DLUI/icon";

interface ComponentProps {
  name: string;
  label: string;
  currentValue: TaskViewObjectAccess;
  onChange: (value: string) => void;
}

export const ReportSettingsRadioGroup: React.FC<ComponentProps> = ({
  name,
  label,
  currentValue,
  onChange
}: ComponentProps) => {
  const { isMobile } = useResponsiveHelper();

  const handleRadioGroupOnChange = useCallback((_event, value) => onChange(value), [onChange]);

  return (
    <RadioGroup onChange={handleRadioGroupOnChange} name={name}>
      <View
        className={"radio-group-container"}
        flexDirection={"row"}
        paddingLeft={isMobile ? 8 : 0}
        marginTop={isMobile ? 10 : 0}
        marginBottom={isMobile ? 10 : 0}
      >
        <Radio
          icon={<Icon Source={XIconInactive} />}
          checkedIcon={<Icon Source={XIcon} pathColor={"light-blue"} />}
          value={TaskViewObjectAccess.NONE}
          checked={!currentValue || currentValue === TaskViewObjectAccess.NONE}
        />
        <Radio
          icon={<Icon Source={PrivateInactive} />}
          checkedIcon={<Icon Source={Private} pathColor={"light-blue"} />}
          value={TaskViewObjectAccess.ASSIGNED_ONLY}
          checked={currentValue === TaskViewObjectAccess.ASSIGNED_ONLY}
        />
        <Radio
          icon={<Icon Source={CheckRoundInactive} />}
          checkedIcon={<Icon Source={CheckRound} pathColor={"light-blue"} />}
          value={TaskViewObjectAccess.ALL}
          checked={currentValue === TaskViewObjectAccess.ALL}
        />
        {isMobile && <Text marginLeft={20}>{label}</Text>}
      </View>
    </RadioGroup>
  );
};
