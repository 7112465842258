import { ClientAnnouncementDto } from "@doorloop/dto";
import { FieldNames } from "screens/announcements/newAnnouncement/selectRecipients/selectRecipients";
import type { WizardStepValidator } from "DLUI/screen/wizard/wizardScreen";
import { createValidator } from "@doorloop/dto";
import AppStrings from "../../../../locale/keys";

interface StepValidators {
  firstStepValidator: WizardStepValidator;
  secondStepValidator: (
    setDeliveryMethodErrorMessageAppString: (appString: string) => void,
    requiredErrorMessage: string
  ) => WizardStepValidator;
  thirdStepValidator: (setBodyTooLong, { maxCharsVoice, maxCharsSms, maxCharsDefault }) => WizardStepValidator;
}

export const newAnnouncementStepValidators: StepValidators = {
  firstStepValidator: async (formik) => {
    Object.values(FieldNames).forEach((field) => formik.setFieldTouched(field));

    const { enableSpecificProperties, properties } = formik.values as {
      enableSpecificProperties?: boolean;
      properties: string[];
    };

    if (enableSpecificProperties && (!properties || properties.length === 0)) {
      formik.setFieldError("properties", "This field is required");
      return false;
    }

    const { properties: propertiesErrors, units, tenants, propertyGroups } = await formik.validateForm();

    return !propertiesErrors && !units && !tenants && !propertyGroups;
  },
  secondStepValidator: (setDeliveryMethodErrorMessageAppString, requiredErrorMessage) => async (formik) => {
    const {
      values: { emailInfo, voiceInfo, textInfo, portalInfo, portalInfoShouldExpire }
    } = formik;

    const hasAnyMethod = Boolean(emailInfo?.send || voiceInfo?.send || textInfo?.send || portalInfo?.send);

    if (!hasAnyMethod) {
      setDeliveryMethodErrorMessageAppString(AppStrings.Announcements.noDeliveryMethodSelectedError);

      return false;
    }

    const hasExpirationDateError = Boolean(portalInfo?.send && portalInfoShouldExpire && !portalInfo?.expiresOn);

    if (hasExpirationDateError) {
      await formik.setFieldTouched("portalInfo.expiresOn", true);
      await formik.setFieldError("portalInfo.expiresOn", requiredErrorMessage);

      return false;
    }

    setDeliveryMethodErrorMessageAppString("");

    return true;
  },
  thirdStepValidator:
    (setBodyTooLong, { maxCharsVoice, maxCharsSms, maxCharsDefault }) =>
    async (formik) => {
      const {
        values: { body, textInfo, voiceInfo }
      } = formik;

      formik.setFieldTouched("subject");
      formik.setFieldTouched("body");

      let isBodyTooLong = false;
      /**
       * if sending SMS, body must be <= 140 chars
       * if sending voice, body must be <= 500 chars
       * in any case, body must be <= 10000 chars
       */
      if (
        body &&
        ((textInfo?.send && body.length > maxCharsSms) ||
          (voiceInfo?.send && body.length > maxCharsVoice) ||
          body.length > maxCharsDefault)
      ) {
        isBodyTooLong = true;
      }

      setBodyTooLong(isBodyTooLong);

      const errors: any = await formik.validateForm();

      return !(errors.subject || errors.body || isBodyTooLong);
    }
};

export const validateNewAnnouncementForm = createValidator(ClientAnnouncementDto);
