export default {
  Screen: {
    AddNewUser: "users.screen.addNewUser",
    SearchPlaceHolder: "users.screen.searchPlaceHolder",
    EmptydataAddNew: "users.screen.emptydataAddNew",
    EmptydataViewInstructionsText: "users.screen.emptydataViewInstructionsText",
    Users: "users.screen.users",
    User: "users.screen.user",
    AccountOwner: "users.screen.accountOwner",
    AccountOwnerDescription: "users.screen.accountOwnerDescription",
    ContactInformation: "users.screen.contactInformation",
    Role: "users.screen.role"
  },
  UserStatus: {
    Active: "users.userStatus.active",
    Suspended: "users.userStatus.suspended",
    Invited: "users.userStatus.invited"
  },
  NewUser: {
    LoadingText: "users.newUser.loadingText",
    ErrorText: "users.newUser.errorText",
    SuccessText: "users.newUser.successText",
    LoginEmailAddress: "users.newUser.loginEmailAddress",
    LoginEmailAddressDescription: "users.newUser.loginEmailAddressDescription",
    UserRole: "users.newUser.userRole",
    UserRoleDescription: "users.newUser.userRoleDescription",
    EditUser: "users.newUser.editUser",
    ActiveUser: "users.newUser.activeUser",
    InactiveUser: "users.newUser.inactiveUser",
    NewUserDescriptionActive: "users.newUser.newUserDescriptionActive",
    NewUserDescriptionInactive: "users.newUser.newUserDescriptionInactive",
    InviteSent: "users.newUser.inviteSent",
    InviteFailed: "users.newUser.inviteFailed",
    SendingInvite: "users.newUser.sendingInvite",
    InviteSentDescriptionPart1: "users.newUser.inviteSentDescriptionPart1",
    InviteSentDescriptionPart2: "users.newUser.inviteSentDescriptionPart2",
    InviteSentDescriptionPart3: "users.newUser.inviteSentDescriptionPart3",
    ResetLogin: "users.newUser.resetLogin",
    AccessAllProperties: "users.newUser.accessAllProperties",
    AccessSomeProperties: "users.newUser.accessSomeProperties",
    PropertiesSelectionDescription: "users.newUser.propertiesSelectionDescription",
    GroupSelectionDescription: "users.newUser.groupSelectionDescription",
    AccessAllBankAccounts: "users.newUser.accessAllBankAccounts",
    AccessSomeBankAccounts: "users.newUser.accessSomeBankAccounts",
    BankAccountsSelectionDescription: "users.newUser.bankAccountsSelectionDescription",
    PropertyGroups: "users.newUser.propertyGroups",
    SetUserRoles: "users.newUser.setUserRoles"
  }
};
