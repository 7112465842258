import type { RentalApplicationCompanySettingsDto, RentalApplicationSettingsProps } from "@doorloop/dto";
import { RentalApplicationFieldSettings } from "@doorloop/dto";

import { FormikSwitchButton, RadioGroup } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import { FastField, useFormikContext } from "formik";
import AppStrings from "../../../../../../locale/keys";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { RichTextField } from "DLUI/form/richTextField/richTextField";
import { RentalAppFieldsGenerator } from "screens/settings/rentalApplications/rentalAppFieldsGenerator";
import { RentalApplicationWizardSteps } from "screens/tenantPortal/rentalApplication/types";
import { useRentalApplicationLocale } from "../../../../../../hooks/useRentalApplicationLocale";
import _ from "lodash";
import { didChangeShowContentSelection } from "screens/settings/rentalApplications/formikHelper";

const FORMIK_KEY_SWITCH = "residentialHistory.isShown";

interface ComponentProps {
  rentalApplicationFieldSettings: RentalApplicationSettingsProps;
}

const ResidentialHistory: React.FC<ComponentProps> = ({ rentalApplicationFieldSettings }) => {
  const { t } = useTranslation();
  const formik = useFormikContext<RentalApplicationCompanySettingsDto>();
  const { values, setFieldValue } = formik;
  const { residentialHistory } = values;

  const { propertyId } = useParams<any>();
  const { isUs } = useRentalApplicationLocale();

  const [residentialHistorySelectionType, setResidentialHistorySelectionType] = useState<
    "companyDefault" | "specifyResidentialHistory"
  >(() =>
    _.values(residentialHistory).every((x) => x === undefined) ? "companyDefault" : "specifyResidentialHistory"
  );

  const shouldRenderForm = residentialHistorySelectionType === "specifyResidentialHistory";

  const didChangeSpecifyResidentialHistorySelection = (selectedValue: string) => {
    didChangeShowContentSelection({
      value: selectedValue === "true",
      formik,
      formName: "residentialHistory",
      fieldsObj: RentalApplicationFieldSettings.residentialHistory.fields
    });

    if (selectedValue === "false") {
      setResidentialHistorySelectionType("companyDefault");
      setFieldValue("residentialHistory", undefined);
    } else {
      if (isUs) {
        formik.setFieldValue(FORMIK_KEY_SWITCH, true);
      }

      setResidentialHistorySelectionType("specifyResidentialHistory");
    }
  };

  const renderSpecifyRadioButtons = () => {
    if (propertyId) {
      return (
        <View>
          <RadioGroup
            didChangeSelection={didChangeSpecifyResidentialHistorySelection}
            uniqueKey={"FRB"}
            defaultValue={residentialHistorySelectionType === "companyDefault" ? "false" : "true"}
            marginTop={20}
            radioGroupItems={[
              {
                label: AppStrings.Common.HideSectionForProperty,
                value: "false"
              },
              {
                label: AppStrings.Common.SpecifySettingsForProperty,
                value: "true"
              }
            ]}
          />
          <SeparationLine marginTop={20} width={"100%"} height={1} />
        </View>
      );
    }
  };

  useEffect(() => {
    if (shouldRenderForm && isUs) {
      formik.setFieldValue(FORMIK_KEY_SWITCH, true);
    }
  }, []);

  return (
    <>
      <Text
        value={AppStrings.Settings.GeneralSettings.RentalApplications.ResidentialHistorySectionDescription}
        lineHeight={"26px"}
      />
      {renderSpecifyRadioButtons()}
      {shouldRenderForm && (
        <>
          {!isUs && (
            <>
              <FastField
                component={FormikSwitchButton}
                name={FORMIK_KEY_SWITCH}
                label={AppStrings.Settings.GeneralSettings.RentalApplications.AskAboutResidentialHistory}
                marginTop={20}
              />
            </>
          )}
          {residentialHistory?.isShown ? (
            <View>
              <Text
                value={AppStrings.Tasks.WorkOrdersScreen.Instructions}
                fontSize={20}
                fontWeight={700}
                marginTop={20}
              />
              <FastField
                component={RichTextField}
                label={t(AppStrings.Tasks.WorkOrdersScreen.Instructions)}
                name={`residentialHistory.instructions`}
                styles={{ container: { marginTop: 20 } }}
                maxLength={5000}
                html={{ name: "residentialHistory.instructionsHTML", value: residentialHistory?.instructionsHTML }}
              />
              <View flexDirection={"row"} justifyContent={"space-between"} noWrap marginTop={30} marginBottom={12}>
                <Text value={AppStrings.Common.Field} fontSize={12} color={"secondary-gray"} />
                <Text value={AppStrings.Common.Required} fontSize={12} color={"secondary-gray"} />
              </View>
              <View noWrap>
                <RentalAppFieldsGenerator
                  fieldsConfigObject={rentalApplicationFieldSettings.residentialHistory.fields}
                  formik={formik}
                  formName={RentalApplicationWizardSteps.ResidentialHistory}
                />
              </View>
            </View>
          ) : null}
        </>
      )}
    </>
  );
};

export default ResidentialHistory;
