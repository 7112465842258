"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsDateStringWithoutTime = IsDateStringWithoutTime;
const class_validator_1 = require("class-validator");
function IsDateStringWithoutTime(validationOptions) {
    return function (object, propertyName) {
        (0, class_validator_1.registerDecorator)({
            name: "isDateStringWithoutTime",
            target: object.constructor,
            propertyName,
            constraints: [],
            options: validationOptions,
            validator: {
                validate(value, args) {
                    if (!value)
                        return false;
                    const regEx = /^\d{4}-\d{2}-\d{2}$/;
                    if (!value.match) {
                        return false;
                    }
                    if (!value.match(regEx))
                        return false; // Invalid format
                    const d = new Date(value);
                    const dNum = d.getTime();
                    if (!dNum && dNum !== 0)
                        return false; // NaN value, Invalid date
                    return d.toISOString().slice(0, 10) === value;
                }
            }
        });
    };
}
