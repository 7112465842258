import { useQuery } from "@tanstack/react-query";
import { settingsApi } from "api/settingsApi";
import type { ApiResult, LateFeesPolicyDto } from "@doorloop/dto";

export const globalSettingsStore = {
  queries: {
    useLateFeesSettings: () =>
      useQuery(["use-late-fees-settings"], async ({ signal }) => {
        const result: ApiResult<LateFeesPolicyDto> = await settingsApi.getLateFeesSettings();
        if (!result || signal?.aborted) {
          return null;
        }
        if (!result.status) {
          throw new Error(result.message);
        }

        return result;
      })
  }
};
