import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BankAccountMerchantStatus, GetStripeAccountLinkRequestDto } from "@doorloop/dto";
import { stripeApi } from "api/stripeApi";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import AppStrings from "../../../../../../locale/keys";
import { DialogHeightNoAction, StripeAlertNoActionRequired } from "./components/stripeAlertNoActionRequired";
import { useDialogStripeOnboarding } from "./hooks/useDialogStripeOnboarding";
import { Form, FormikContext, useFormik } from "formik";
import _ from "lodash";
import { StripeConnectDialogContent } from "screens/incomingPayments/tabs/merchantAccountSetup/stripeOnbording/components/stripeConnectDialogContent";
import type { Translation } from "locale";
import { NavigationManager } from "utils/navigation";
import type { StripeConnectPoints } from "screens/incomingPayments/tabs/merchantAccountSetup/stripeOnboarding.types";

export const StripeApplyNow = () => {
  const { t } = useTranslation();
  const formik = useFormik<GetStripeAccountLinkRequestDto>({
    onSubmit: _.noop,
    initialValues: new GetStripeAccountLinkRequestDto()
  });

  const {
    DialogViews,
    bankAccount,
    loadingDialogState,
    loadingDialogErrorText,
    viewIndex,
    setLoadingDialogState,
    showErrorMessage,
    dialogHeight,
    dialogWidth
  } = useDialogStripeOnboarding();

  const isActionRequired = useMemo(
    () =>
      Boolean(
        bankAccount &&
          (!bankAccount.merchantAccountStatus ||
            bankAccount.merchantAccountStatus === BankAccountMerchantStatus.MERCHANT_ONBOARDING_INCOMPLETE)
      ),
    [bankAccount]
  );

  const handleBackdropClick = () => {
    NavigationManager.viewMerchantAccountSetup();
  };

  const handleApplyNow = async () => {
    setLoadingDialogState(DialogState.Show);

    if (bankAccount?.id) {
      const response = await stripeApi.postStripeAccountLinkWithAcceptedTOS({
        accountId: bankAccount.id,
        acceptedOnTOS: formik.values.acceptedOnTOS,
        acceptedOnRapidRentTOS: formik.values.acceptedOnRapidRentTOS
      });

      if (response?.data?.url) {
        window.open(response?.data?.url, "_self");
      } else {
        showErrorMessage(response.message || t(AppStrings.Common.GeneralError));
      }
    } else {
      showErrorMessage(t(AppStrings.Accounts.MerchantAccountNotFound));
    }
  };

  const applyPoints = useMemo(() => {
    const points: StripeConnectPoints[] = [
      {
        translation: "common.incomingPayments.stripeConnectDialog.point1"
      },
      {
        translation: "common.incomingPayments.stripeConnectDialog.point2"
      }
    ];

    if (bankAccount?.merchantAccountStripeApplicationFee) {
      points.push({
        translation: "accounts.stripeApplyNowContentIV" as Translation,
        translationOptions: {
          stripeApplicationFee: bankAccount.merchantAccountStripeApplicationFee
        }
      });
    }

    return points;
  }, [bankAccount?.merchantAccountStripeApplicationFee]);

  const renderView = ({ index }: { index: number }) => {
    if (index === DialogViews.LoadingView) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          errorText={loadingDialogErrorText}
          onRetryButtonPress={handleApplyNow}
          didPressDismissButton={handleBackdropClick}
        />
      );
    }

    if (index === DialogViews.ContentView && bankAccount) {
      if (isActionRequired) {
        return (
          <FormikContext.Provider value={formik}>
            <Form noValidate style={{ height: "100%" }}>
              <StripeConnectDialogContent
                onBackdropClick={handleBackdropClick}
                onSubmit={handleApplyNow}
                isLoading={loadingDialogState === DialogState.Show}
                account={bankAccount}
                points={applyPoints}
                mode={"apply"}
                formik={formik}
              />
            </Form>
          </FormikContext.Provider>
        );
      }
      return <StripeAlertNoActionRequired dialogWidth={dialogWidth} onBackdropClick={handleBackdropClick} />;
    }

    return null;
  };

  return (
    <DialogFrame
      useExperimentalDialogFrame
      onCloseButtonClick={handleBackdropClick}
      width={dialogWidth}
      height={isActionRequired ? dialogHeight : DialogHeightNoAction}
      renderView={renderView}
      numViews={2}
      activeView={viewIndex}
      frameType={"contentOnly"}
    />
  );
};
