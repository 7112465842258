import type { FC, PropsWithChildren } from "react";
import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { SeparationLine } from "DLUI/separatorView";
import { ObjectPermissionToggle } from "./objectPermissionToggle";
import AppStrings from "locale/keys";
import type { ObjectPermissionField } from "screens/settings/userRoles/clearanceTypes";
import type { ObjectPermission } from "@doorloop/dto";
import { useResponsiveHelper } from "../../../../../../contexts/responsiveContext";

const DynamicBox: FC = ({ children }: PropsWithChildren<{}>) => {
  const { isMobile } = useResponsiveHelper();
  return isMobile ? (
    <View
      borderRadius={10}
      backgroundColor={"white"}
      paddingRight={20}
      paddingLeft={20}
      marginBottom={20}
      paddingBottom={20}
    >
      {children}
    </View>
  ) : (
    <>{children}</>
  );
};

interface PermissionWrapperProps {
  fields?: ObjectPermissionField[];
  testField: ObjectPermissionField;
}

const PermissionWrapper: FC<PermissionWrapperProps> = ({
  fields,
  testField,
  children
}: PropsWithChildren<PermissionWrapperProps>) =>
  (fields || []).includes(testField) ? <View flex={1} /> : <>{children}</>;

interface Props {
  permission: ObjectPermission;
  title: string;
  ternary?: boolean;
  ignoredFields?: ObjectPermissionField[];
}

const ObjectRow: FC<Props> = ({ permission, title, ternary, ignoredFields }: Props) => {
  const { isMobile } = useResponsiveHelper();

  return (
    <DynamicBox>
      <View alignItems={isMobile ? "flex-start" : "center"} flexDirection={isMobile ? "column" : "row"}>
        <View flex={2} marginBottom={isMobile ? 20 : 0} marginTop={isMobile ? 20 : 0}>
          <Text fontWeight={isMobile ? 700 : undefined}>{title}</Text>
        </View>
        {isMobile && <SeparationLine width={"100%"} height={1} />}
        <PermissionWrapper fields={ignoredFields} testField={"viewList"}>
          <ObjectPermissionToggle
            permission={permission}
            label={AppStrings.UserRoles.ViewOne}
            field={"viewList"}
            ternary={ternary}
          />
        </PermissionWrapper>
        <PermissionWrapper fields={ignoredFields} testField={"viewOne"}>
          <ObjectPermissionToggle
            permission={permission}
            label={AppStrings.UserRoles.ViewList}
            field={"viewOne"}
            ternary={ternary}
          />
        </PermissionWrapper>
        <PermissionWrapper fields={ignoredFields} testField={"create"}>
          <ObjectPermissionToggle permission={permission} label={AppStrings.UserRoles.Create} field={"create"} />
        </PermissionWrapper>
        <PermissionWrapper fields={ignoredFields} testField={"edit"}>
          <ObjectPermissionToggle permission={permission} label={AppStrings.UserRoles.Edit} field={"edit"} />
        </PermissionWrapper>
        <PermissionWrapper fields={ignoredFields} testField={"delete"}>
          <ObjectPermissionToggle permission={permission} label={AppStrings.UserRoles.Delete} field={"delete"} />
        </PermissionWrapper>
      </View>
    </DynamicBox>
  );
};

export default ObjectRow;
