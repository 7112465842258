import { ProfileImage } from "DLUI/profileImage/index";
import React, { useEffect, useState } from "react";
import type { SVGIconComponent } from "../../../assets";
import { ESignatureSignerType } from "@doorloop/dto";
import { ownersApi } from "api/ownersApi";
import { tenantsApi } from "api/tenantsApi";
import { usersApi } from "api/usersApi";
import { vendorsApi } from "api/vendorsApi";

interface ComponentProps {
  signerId?: string;
  signerType?: ESignatureSignerType;
  marginLeft?: number;
  size?: number;
  AvatarIcon?: SVGIconComponent;
  marginRight?: number;
}

const SignerProfileImage: React.FC<ComponentProps> = React.memo(
  ({ marginLeft, size, AvatarIcon, signerType, signerId, marginRight }: ComponentProps) => {
    const [pictureUrl, setPictureUrl] = useState<string | undefined>();

    useEffect(() => {
      getPictureUrl();
    }, []);

    const getPictureUrl = async () => {
      let apiMethod: any;
      switch (signerType) {
        case ESignatureSignerType.OWNER:
          apiMethod = ownersApi;
          break;
        case ESignatureSignerType.PROSPECT:
        case ESignatureSignerType.TENANT:
          apiMethod = tenantsApi;
          break;
        case ESignatureSignerType.USER:
          apiMethod = usersApi;
          break;
        case ESignatureSignerType.VENDOR:
          apiMethod = vendorsApi;
      }

      const signerDictionary = signerId && (await apiMethod.getItemFromDictionary(signerId));
      setPictureUrl(signerDictionary?.pictureUrl);
    };

    return (
      <ProfileImage
        pictureUrl={pictureUrl}
        marginLeft={marginLeft}
        size={size || 30}
        AvatarIcon={AvatarIcon}
        marginRight={marginRight}
      />
    );
  }
);

export default SignerProfileImage;
