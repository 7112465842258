import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: "none"
    },
    hoverPopover: {
      background: "black"
    },
    buttonContainer: {
      width: "50px",
      height: "50px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    lightBlue: {
      "&:hover": {
        backgroundColor: "rgba(47, 62, 131, 0.8);"
      }
    },
    lineWrapper: {
      display: "flex",
      width: "90%",
      flexDirection: "row",
      alignItems: "center",
      minHeight: "32px",
      cursor: "pointer",
      borderRadius: 5,
      "&:hover": {
        backgroundColor: theme.palette.screenBackground.light
      },
      justifyContent: "flex-start"
    },
    lineIcon: {
      marginRight: "10px",
      marginLeft: "10px",
      width: "16px",
      height: "16px"
    },
    popoverContainer: {
      borderRadius: 10
    },
    separatorView: {
      backgroundColor: theme.palette.gray.main
    },
    moreIcon: {
      color: theme.palette.white.main
    },
    smallIcon: {
      width: "20px",
      height: "20px"
    },
    tooltipContainer: {
      position: "relative",
      width: "100%"
    },
    tooltipAnchorTop: {
      position: "absolute",
      right: 150,
      top: -20
    },
    tooltipAnchorBottom: {
      position: "absolute",
      right: 150,
      top: 30
    },
    tooltipContentContainer: {
      cursor: "pointer"
    }
  })
);
