import React from "react";

import type { PhoneNumberDto, SetupSmsOrigin } from "@doorloop/dto";

import AppStrings from "../../../../locale/keys";
import DLButton, { DLButtonColorsEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import EmptyDataView from "DLUI/emptyDataView";
import { EmptyMessagesIcon, WorldIcon } from "../../../../assets";
import { NavigationManager } from "../../../../utils/navigation";
import { useGetUserLocale } from "../../../../hooks/useGetUserLocale";

interface ComponentProps {
  currentPhoneNumber?: PhoneNumberDto | null;
  defaultHeight?: number;
  origin?: SetupSmsOrigin;
}

export const EmptyPhoneNumberView: React.FC<ComponentProps> = ({ currentPhoneNumber, defaultHeight, origin }) => {
  const { isNonNorthAmerica } = useGetUserLocale();

  if (isNonNorthAmerica) {
    return (
      <EmptyDataView
        displayIcon={WorldIcon}
        instructionsText={AppStrings.CommunicationsCenter.ServiceNotAvailableInRegionTitle}
        instructionsSubText={AppStrings.CommunicationsCenter.ServiceNotAvailableInRegionDescription}
        actionButton={
          <DLButton
            variant={DLButtonVariantsEnum.TEXT}
            color={DLButtonColorsEnum.SECONDARY}
            actionText={AppStrings.CommunicationsCenter.ChangeYourRegion}
            onClick={NavigationManager.editRegionAndCurrencyWithAbsolute}
          />
        }
        defaultHeight={defaultHeight}
      />
    );
  }
  if (!currentPhoneNumber) {
    return (
      <EmptyDataView
        displayIcon={EmptyMessagesIcon}
        instructionsText={AppStrings.CommunicationsCenter.SetupSmsTitle}
        instructionsSubText={AppStrings.CommunicationsCenter.SetupSmsSubtitle}
        actionButton={
          <DLButton
            actionText={AppStrings.CommunicationsCenter.SetupSms}
            onClick={() => origin && NavigationManager.navigateToSetupSmsWizard(origin)}
          />
        }
        defaultHeight={defaultHeight}
      />
    );
  }

  return (
    <EmptyDataView
      displayIcon={EmptyMessagesIcon}
      instructionsText={AppStrings.CommunicationsCenter.PhoneNumberPendingTitle}
      instructionsSubText={AppStrings.CommunicationsCenter.PhoneNumberPendingSubtitle}
      defaultHeight={defaultHeight}
    />
  );
};
