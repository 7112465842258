import { useAnalyticsService } from "./useAnalyticsService";
import { useCallback } from "react";
import type { ActionItem } from "DLUI/screen/helpPanel/types";
import { HelpTypeEnum } from "DLUI/screen/helpPanel/types";
import type { VideoItem } from "../components/DLUI/screen/helpPanel/types";

export const useHelpActionItem = (actionItem: ActionItem, onOpenHelpVideo: (video: VideoItem) => void) => {
  const { dispatchAnalytics } = useAnalyticsService();

  const clickHandler = useCallback(() => {
    switch (actionItem.type) {
      case HelpTypeEnum.INTERCOM_ARTICLE:
        dispatchAnalytics("help_panel_article_click", { articleId: actionItem.articleId });

        if (window.Intercom) {
          window.Intercom("showArticle", actionItem.articleId);
        }
        break;

      case HelpTypeEnum.WATCH_VIDEO:
        onOpenHelpVideo?.(actionItem);
        break;
    }
  }, [actionItem.type]);

  return { clickHandler };
};
