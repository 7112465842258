import type { ArticleIdsEnum } from "DLUI/screen/helpPanel/types";
import Text from "DLUI/text/text";
import { LearnMoreText } from "DLUI/text/learnMoreText";
import type { ReactNode } from "react";
import { useGeneralStyles } from "styles/generalStyles";
import clsx from "clsx";
import { DataCy } from "@doorloop/dto";

export interface TitleWithDescriptionProps {
  title: string;
  titleEnd?: ReactNode;
  subtitle: string;
  articleId?: ArticleIdsEnum;
}

export const TitleWithDescription = ({ title, titleEnd, subtitle, articleId }: TitleWithDescriptionProps) => {
  const generalClasses = useGeneralStyles();
  return (
    <div className={clsx(generalClasses.flexColumn, generalClasses.smallGap)}>
      <div>
        <Text
          dataCy={DataCy.globalSettings.titleWithDescription.title}
          value={title}
          fontSize={14}
          fontWeight={700}
          color="black"
        />
        {titleEnd && <div>{titleEnd}</div>}
      </div>
      {articleId ? (
        <LearnMoreText
          dataCy={DataCy.globalSettings.titleWithDescription.title}
          articleId={articleId}
          value={subtitle}
          fontSize={14}
          fontWeight={400}
        />
      ) : (
        <Text
          dataCy={DataCy.globalSettings.titleWithDescription.title}
          value={subtitle}
          fontSize={14}
          fontWeight={400}
          color="secondary-gray"
        />
      )}
    </div>
  );
};
