import type { FC } from "react";
import React, { useMemo } from "react";
import { View } from "DLUI/view";
import { Tooltip } from "@material-ui/core";
import ColorsEnum from "../../../utils/colorsEnum";
import makeStyles from "./styles";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import clsx from "clsx";

interface Props {
  progress: number;
  total: number;
  color?: ColorsEnum;
  height?: number;
  backgroundColor?: ColorsEnum;
  showProgressTooltipOnHover?: boolean;
  shineAnimation?: boolean;
  alwaysShowTooltip?: boolean;
}

const DEFAULT_HEIGHT = 12;
const DEFAULT_PADDING = 2;

export const ProgressBar: FC<Props> = ({
  backgroundColor,
  progress,
  total,
  color,
  height,
  showProgressTooltipOnHover,
  shineAnimation,
  alwaysShowTooltip
}: Props) => {
  const classes = makeStyles();

  const percentage = useMemo(
    () =>
      // Ceil and clamp progress to 0-100
      Math.min(100, Math.max(0, Math.ceil((progress / total) * 100))),
    [progress, total]
  );

  return (
    <View flexDirection={"row"}>
      <View
        paddingLeft={DEFAULT_PADDING}
        paddingRight={DEFAULT_PADDING}
        paddingTop={DEFAULT_PADDING}
        paddingBottom={DEFAULT_PADDING}
        borderRadius={30}
        height={height ?? DEFAULT_HEIGHT}
        style={{ background: backgroundColor ?? ColorsEnum.LightGray, boxSizing: "border-box" }}
        noWrap
      >
        <Tooltip
          title={
            <Text
              fontSize={14}
              color={"white"}
              value={AppStrings.Common.ProgressBar}
              replaceObject={{ progress: percentage }}
            />
          }
          disableHoverListener={!showProgressTooltipOnHover}
          disableFocusListener={!showProgressTooltipOnHover}
          disableTouchListener={!showProgressTooltipOnHover}
          placement="top"
          arrow
          PopperProps={{
            disablePortal: true,
            modifiers: {
              preventOverflow: {
                enabled: true,
                boundariesElement: "window"
              }
            }
          }}
          open={alwaysShowTooltip && percentage > 0 ? true : undefined}
          style={{ width: "100%", height: "100%" }}
          classes={{ popper: classes.customPopper, tooltip: classes.customTooltip, arrow: classes.customArrow }}
        >
          {/*div is necessary for tooltip to show up. Tooltip won't work with non-ref components*/}
          <div
            style={{
              width: `${percentage}%`,
              height: "100%",
              transition: "width 0.2s ease-in-out",
              willChange: "width"
            }}
          >
            <View
              marginRight={5}
              borderRadius={30}
              height="100%"
              width="100%"
              flexDirection={"row"}
              className={clsx([classes.progressBarInner, shineAnimation && classes.shinyProgressBar])}
              style={{
                // lime -> dark green gradient (figma)
                background: color || "linear-gradient(90deg, #01CC74 0%, #01B4CC 100.87%)"
              }}
            />
          </div>
        </Tooltip>
      </View>
    </View>
  );
};
