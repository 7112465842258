import type {
  GetAllUsersQuery,
  LinkedResourceDto,
  UserDto,
  UserNotificationSettingsDto,
  UserPrintSetupConfigDto
} from "@doorloop/dto";
import { FileDto, LoginResponseType, SegmentEventTypes, ServerRoutes } from "@doorloop/dto";
import { apiHelper } from "./apiHelper";
import { RestApiBaseWithDictionary } from "./restApiBaseWithDictionary";
import { userRolesApi } from "./userRolesApi";
import { propertyGroupsApi } from "./propertyGroupsApi";
import { analyticsService } from "../services/analyticsService";

export class UsersApi extends RestApiBaseWithDictionary<UserDto, GetAllUsersQuery> {
  constructor() {
    super(ServerRoutes.USERS, [userRolesApi, propertyGroupsApi]);
    this.restRoute = ServerRoutes.USERS;
  }

  getDetails = async (userId: string) => await apiHelper.axiosGet(ServerRoutes.USERS + "/" + userId);
  resendInvitation = async (userId: string) => {
    analyticsService.track(
      SegmentEventTypes.INVITATION_SENT,
      {
        sentToId: userId,
        sentToType: LoginResponseType.USER
      },
      { trackEventInIntercom: true }
    );

    return await apiHelper.axiosPost({ url: ServerRoutes.USERS_POST_RESEND_INVITATION, data: { user: userId } });
  };

  async uploadUserPicture(file: File, linkedResourceDto: LinkedResourceDto, id: string, serverRoute?: string) {
    const data = new FormData();
    data.append("file", file);
    const fileDto = new FileDto({ rank: 1 });
    fileDto.name = file.name;
    fileDto.linkedResource = linkedResourceDto;

    for (const key of Object.keys(fileDto)) {
      // @ts-ignore
      const value = fileDto[key];
      if (typeof value === "object" && value !== null) {
        for (const objectKey of Object.keys(value)) {
          data.append(key + "[" + objectKey + "]", value[objectKey]);
        }
      } else {
        // @ts-ignore
        data.append(key, fileDto[key]);
      }
    }

    return await apiHelper.axiosPost<FileDto>({ url: serverRoute || ServerRoutes.USERS + "/pictures/" + id, data });
  }

  async deleteUserPicture(id: string) {
    return await apiHelper.axiosDelete({ url: ServerRoutes.USERS + "/pictures/" + id });
  }

  async getUserNotificationsSettings() {
    return await apiHelper.axiosGet(ServerRoutes.USERS_NOTIFICATION_SETTINGS);
  }

  async updateUserNotificationsSettings(data: UserNotificationSettingsDto) {
    return await apiHelper.axiosPut({ url: ServerRoutes.USERS_NOTIFICATION_SETTINGS, data });
  }

  async getUserPrintSetup() {
    return await apiHelper.axiosGet(ServerRoutes.USERS_PRINT_SETUP);
  }

  async updateUserPrintSetup(data: UserPrintSetupConfigDto) {
    return await apiHelper.axiosPut({ url: ServerRoutes.USERS_PRINT_SETUP, data });
  }
}

export const usersApi = new UsersApi();
