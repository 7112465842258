"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecurringTransactionDto = void 0;
const validators_1 = require("../common/validators");
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const base_dto_1 = require("../base.dto");
const leaseRecurringChargeInfo_dto_1 = require("./leaseRecurringChargeInfo.dto");
const leaseRecurringCreditInfo_dto_1 = require("./leaseRecurringCreditInfo.dto");
const leaseRecurringPaymentInfo_dto_1 = require("./leaseRecurringPaymentInfo.dto");
const leaseRecurringRentInfo_dto_1 = require("./leaseRecurringRentInfo.dto");
const recurringTaskInfo_dto_1 = require("../recurringTransactions/recurringTaskInfo.dto");
const recurringExpenseInfo_dto_1 = require("../recurringTransactions/recurringExpenseInfo.dto");
const recurringVendorBillInfo_dto_1 = require("../recurringTransactions/recurringVendorBillInfo.dto");
const recurringTransactionFrequency_enum_1 = require("./recurringTransactionFrequency.enum");
const recurringTransactionType_enum_1 = require("./recurringTransactionType.enum");
const generalEntryInfo_dto_1 = require("./generalEntryInfo.dto");
class RecurringTransactionDto extends base_dto_1.BaseDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.RecurringTransactionDto = RecurringTransactionDto;
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(1000),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], RecurringTransactionDto.prototype, "memo", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(recurringTransactionType_enum_1.RecurringTransactionType),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], RecurringTransactionDto.prototype, "type", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", Boolean)
], RecurringTransactionDto.prototype, "noEndDate", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, validators_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], RecurringTransactionDto.prototype, "start", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateIf)((x) => !x.noEndDate),
    (0, validators_1.IsAfter)("start", { message: "End date must be after start date" }),
    (0, validators_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], RecurringTransactionDto.prototype, "end", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.type === recurringTransactionType_enum_1.RecurringTransactionType.LEASE_RENT ||
        x.type === recurringTransactionType_enum_1.RecurringTransactionType.LEASE_CHARGE ||
        x.type === recurringTransactionType_enum_1.RecurringTransactionType.LEASE_CREDIT ||
        x.type === recurringTransactionType_enum_1.RecurringTransactionType.LEASE_PAYMENT),
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], RecurringTransactionDto.prototype, "lease", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(1000),
    __metadata("design:type", String)
], RecurringTransactionDto.prototype, "reference", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(recurringTransactionFrequency_enum_1.RecurringTransactionFrequency),
    __metadata("design:type", String)
], RecurringTransactionDto.prototype, "frequency", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.ValidateIf)((x) => x.type === recurringTransactionType_enum_1.RecurringTransactionType.GENERAL_ENTRY),
    (0, class_transformer_1.Type)(() => generalEntryInfo_dto_1.GeneralEntryInfoDto),
    __metadata("design:type", generalEntryInfo_dto_1.GeneralEntryInfoDto)
], RecurringTransactionDto.prototype, "generalEntryInfo", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.ValidateIf)((x) => x.type === recurringTransactionType_enum_1.RecurringTransactionType.LEASE_CHARGE),
    (0, class_transformer_1.Type)(() => leaseRecurringChargeInfo_dto_1.LeaseRecurringChargeInfoDto),
    __metadata("design:type", leaseRecurringChargeInfo_dto_1.LeaseRecurringChargeInfoDto)
], RecurringTransactionDto.prototype, "leaseChargeInfo", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.ValidateIf)((x) => x.type === recurringTransactionType_enum_1.RecurringTransactionType.LEASE_RENT),
    (0, class_transformer_1.Type)(() => leaseRecurringRentInfo_dto_1.LeaseRecurringRentInfoDto),
    __metadata("design:type", leaseRecurringRentInfo_dto_1.LeaseRecurringRentInfoDto)
], RecurringTransactionDto.prototype, "leaseRentInfo", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.ValidateIf)((x) => x.type === recurringTransactionType_enum_1.RecurringTransactionType.LEASE_PAYMENT),
    (0, class_transformer_1.Type)(() => leaseRecurringPaymentInfo_dto_1.LeaseRecurringPaymentInfoDto),
    __metadata("design:type", leaseRecurringPaymentInfo_dto_1.LeaseRecurringPaymentInfoDto)
], RecurringTransactionDto.prototype, "leasePaymentInfo", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.ValidateIf)((x) => x.type === recurringTransactionType_enum_1.RecurringTransactionType.LEASE_CREDIT),
    (0, class_transformer_1.Type)(() => leaseRecurringCreditInfo_dto_1.LeaseRecurringCreditInfoDto),
    __metadata("design:type", leaseRecurringCreditInfo_dto_1.LeaseRecurringCreditInfoDto)
], RecurringTransactionDto.prototype, "leaseCreditInfo", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.ValidateIf)((x) => x.type === recurringTransactionType_enum_1.RecurringTransactionType.TASK),
    (0, class_transformer_1.Type)(() => recurringTaskInfo_dto_1.RecurringTaskInfoDto),
    __metadata("design:type", recurringTaskInfo_dto_1.RecurringTaskInfoDto)
], RecurringTransactionDto.prototype, "taskInfo", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.ValidateIf)((x) => x.type === recurringTransactionType_enum_1.RecurringTransactionType.EXPENSE),
    (0, class_transformer_1.Type)(() => recurringExpenseInfo_dto_1.RecurringExpenseInfoDto),
    __metadata("design:type", recurringExpenseInfo_dto_1.RecurringExpenseInfoDto)
], RecurringTransactionDto.prototype, "expenseInfo", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.ValidateIf)((x) => x.type === recurringTransactionType_enum_1.RecurringTransactionType.VENDOR_BILL),
    (0, class_transformer_1.Type)(() => recurringVendorBillInfo_dto_1.RecurringVendorBillInfoDto),
    __metadata("design:type", recurringVendorBillInfo_dto_1.RecurringVendorBillInfoDto)
], RecurringTransactionDto.prototype, "vendorBillInfo", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateIf)((x) => [recurringTransactionType_enum_1.RecurringTransactionType.TASK, recurringTransactionType_enum_1.RecurringTransactionType.VENDOR_BILL].includes(x.type)),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], RecurringTransactionDto.prototype, "numberOfDaysUntilDue", void 0);
