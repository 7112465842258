import React, { useMemo, useState } from "react";
import type { DisplayTenantDto, LeaseTenantDto, TenantDto } from "@doorloop/dto";
import { LinkedResourceDto, LinkedResourceType } from "@doorloop/dto";
import { tenantsApi } from "api/tenantsApi";
import type { FormikProps } from "formik";
import AppStrings from "locale/keys";
import type { AnimatedContentProps } from "../components/animatedContent";
import AnimatedContent from "../components/animatedContent";
import DialogFrame, { getDialogFrameDimension } from "../components/dialogFrame";
import { LoadingDialog } from "../components/loading";
import { DialogState } from "../loadingDialog";
import type { TenantSelectionViewProps } from "./tenantSelectionView";
import TenantSelectionView from "./tenantSelectionView";
import { useTranslation } from "react-i18next";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import _ from "lodash";

export interface SelectTenantDialogProps {
  didFinishOperation?: (values?: DisplayTenantDto | LeaseTenantDto) => void;
  dialogState?: DialogState;
  tenantSelectionViewProps?: Partial<TenantSelectionViewProps>;
  tenantSelectionTitle?: string;
  addTenantTitle?: string;
  animatedContentProps?: Partial<AnimatedContentProps>;
  onCreateNewTenant?: (values: TenantDto) => void;
  validationMethod?: (formikRef: FormikProps<TenantDto>) => Promise<{ isValid: boolean; errorStepIndex?: number }>;
}

const SelectTenantDialog: React.FC<SelectTenantDialogProps> = ({
  didFinishOperation,
  dialogState,
  animatedContentProps,
  validationMethod,
  onCreateNewTenant,
  tenantSelectionViewProps,
  tenantSelectionTitle = AppStrings.Leases.NewLease.LeaseTenants.AddNewTenantToLease,
  addTenantTitle = AppStrings.Tenants.Screen.AddNewTenant
}: SelectTenantDialogProps) => {
  const { isTabletOrMobile } = useResponsiveHelper();
  const dialogHeight = useMemo(() => getDialogFrameDimension("height", 885), []);
  const { t } = useTranslation();
  const [activeView, setActiveView] = useState<number>(0);
  const didSelectTenantItem = (selectedItem: TenantDto) => {
    didFinishOperation?.(selectedItem);
  };
  const [currentSelectedMenuIndex, setCurrentSelectedMenuIndex] = useState<number | undefined>();
  const [currentMenuItemTitle, setCurrentMenuItemTitle] = useState<string>("");
  const [pendingFile, setPendingFile] = useState<File | undefined>();
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>(t(AppStrings.Common.GeneralError));

  const didPressAddNewTenant = () => {
    setActiveView(3);
  };

  const uploadFile = async (formikRef: FormikProps<TenantDto>, id: string, onClose: (values?: TenantDto) => void) => {
    if (!pendingFile) {
      return;
    }

    setLoadingDialogState(DialogState.Success);
    const LinkedResource = new LinkedResourceDto(id, LinkedResourceType.Tenant);

    const response = await tenantsApi.uploadPicture(pendingFile, LinkedResource, id);

    if (response && response.status) {
      setLoadingDialogState(DialogState.Success);
      onClose(response.data);
    } else {
      formikRef.setSubmitting(false);
      setLoadingDialogState(DialogState.Error);
    }
  };

  const didCreateNewTenant = (values?: any) => {
    setActiveView(0);

    if (onCreateNewTenant) {
      onCreateNewTenant(values);
    }
  };

  const create = async (formikRef: FormikProps<TenantDto>) => {
    const { status, data, message } = await tenantsApi.create(formikRef.values);

    if (status && data?.id) {
      if (pendingFile) {
        await uploadFile(formikRef, data.id, didCreateNewTenant);
      } else {
        setLoadingDialogState(DialogState.Success);
        didCreateNewTenant(data);
      }
    } else {
      setLoadingDialogErrorText(message || t(AppStrings.Common.GeneralError));
      setLoadingDialogState(DialogState.Error);
    }
  };

  const didPressCreateNewTenant = async (formikRef: FormikProps<TenantDto>) => {
    if (formikRef && validationMethod) {
      const validationResult = await validationMethod(formikRef);
      if (validationResult.isValid) {
        formikRef.setSubmitting(true);
        setLoadingDialogState(DialogState.Show);
        setActiveView(2);
        await create(formikRef);
      } else if (validationResult.errorStepIndex !== undefined) {
        setCurrentSelectedMenuIndex(validationResult.errorStepIndex);
      }
    }
  };

  const renderActionPanelButtons = (formikRef: FormikProps<TenantDto>) => {
    const _didPressCreateNewTenant = () => {
      didPressCreateNewTenant(formikRef);
    };

    return (
      <FormActionButtons
        propsSubButton={{ onClick: onCloseButtonClick }}
        propsMainButton={{ type: "cta", props: { onClick: _didPressCreateNewTenant } }}
      />
    );
  };

  const didPressLoadingDialogDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setActiveView(3);
  };

  const onFileReceive = (receivedFile: File) => {
    setPendingFile(receivedFile);
  };

  const onFileDelete = () => {
    setPendingFile(undefined);
  };

  const disablePersonDetailsValidation = useSelector<IRootState>(
    (state) => state.auth.currentLoginResponse?.currentDbTenant.disablePersonDetailsValidation
  );

  const _formikInitialValues = () => {
    const values = animatedContentProps?.formikInitialValues?.();

    if (!_.isUndefined(values)) {
      values.disablePersonDetailsValidation = disablePersonDetailsValidation;
    }

    return values;
  };

  const renderView = ({ index, active }: { index: number; active: boolean }) => {
    if (index === 0 && active && dialogState === DialogState.Show) {
      return (
        <TenantSelectionView
          didPressAddNewTenant={didPressAddNewTenant}
          didSelectTenantItem={didSelectTenantItem}
          height={isTabletOrMobile ? window.innerHeight : dialogHeight}
          {...tenantSelectionViewProps}
        />
      );
    }
    if (index === 3 && active) {
      const { formikInitialValues, sectionItems, formikValidation } = animatedContentProps || {};
      if (formikInitialValues && sectionItems && formikValidation) {
        return (
          <AnimatedContent
            formikInitialValues={_formikInitialValues}
            sectionItems={sectionItems}
            formikValidation={formikValidation}
            dialogHeight={dialogHeight}
            renderActionPanelButtons={renderActionPanelButtons}
            onMenuItemSelection={onMenuItemSelection}
            onFileReceive={onFileReceive}
            onFileDelete={onFileDelete}
            selectedMenuIndex={currentSelectedMenuIndex}
          />
        );
      }
      return <div />;
    }
    if (index === 2 && active) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          errorText={loadingDialogErrorText}
          hideRetryButton
          didPressDismissButton={didPressLoadingDialogDismissButton}
        />
      );
    }
    return <div />;
  };

  const onMenuItemSelection = (menuItemTitle: string, currentSelectedMenuIndex?: number) => {
    setCurrentMenuItemTitle(menuItemTitle);
    setCurrentSelectedMenuIndex(currentSelectedMenuIndex);
  };

  const onCloseButtonClick = () => {
    if (activeView === 3) {
      setActiveView(0);
      return;
    }
    didFinishOperation?.();
  };

  const frameType = useMemo(() => {
    if (activeView === 0) {
      return "topPanel";
    }
    if (activeView === 3) {
      return "sideMenu";
    }
    if (activeView === 2) {
      return "contentOnly";
    }
    return "topPanel";
  }, [activeView]);

  return (
    <DialogFrame
      onCloseButtonClick={onCloseButtonClick}
      title={activeView === 3 ? addTenantTitle : tenantSelectionTitle}
      width={962}
      height={dialogHeight}
      activeView={activeView}
      numViews={4}
      renderView={renderView}
      frameType={frameType}
      sectionTitle={currentMenuItemTitle}
    />
  );
};

export default SelectTenantDialog;
