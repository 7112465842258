import React, { createRef, useState } from "react";
import type { DropzoneRef } from "react-dropzone";
import Dropzone from "react-dropzone";
import CompanyImageSelection from "./companyImageSelection";
import ProfileImageSelection from "./profileImageSelection";
import makeStyles from "./styles";
import "./styles.css";
import clsx from "clsx";
import CoverImageSelection from "./coverImageSelection";
import { useReadOnlyContext } from "contexts/readOnlyContext";
import { fileExtensionsMap } from "DLUI/dropZone/utils";
import { FileFilterTypes } from "@doorloop/dto";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";

interface ComponentProps {
  didReceivedFile: (receivedFile: File) => void;
  didDeleteFile: () => void;
  selectedImageUrl?: string;
  companyImage?: boolean;
  coverImage?: boolean;
  marginLeft?: string;
  alignSelf?: "center" | "flex-start";
  backgroundColor?: string;
}

const ImageUpload: React.FC<ComponentProps> = ({
  didReceivedFile,
  didDeleteFile,
  selectedImageUrl,
  companyImage,
  alignSelf,
  coverImage,
  backgroundColor
}: ComponentProps) => {
  const isReadOnlyContext = useReadOnlyContext();
  const dropzoneRef = createRef<DropzoneRef>();
  const { isMobile } = useResponsiveHelper();
  const classes = makeStyles();
  const [selectedImage, setSelectedImage] = useState<File | undefined>(undefined);
  const [imageUrl, setImageUrl] = useState(selectedImageUrl);
  const acceptFormats = fileExtensionsMap[FileFilterTypes.PICTURE];

  const onFileReceive = (receiveFiles: File[]) => {
    setSelectedImage(receiveFiles[0]);
    didReceivedFile(receiveFiles[0]);
  };

  const onFileDelete = () => {
    setSelectedImage(undefined);
    setImageUrl(undefined);
    didDeleteFile();
  };

  const renderDropZone = () => {
    let selectionComponent: any = (
      <ProfileImageSelection
        onFileDelete={onFileDelete}
        selectedImageFile={selectedImage}
        selectedImageUrl={imageUrl}
      />
    );

    if (companyImage) {
      selectionComponent = (
        <CompanyImageSelection
          onFileDelete={onFileDelete}
          selectedImageFile={selectedImage}
          selectedImageUrl={imageUrl}
        />
      );
    }
    if (coverImage) {
      selectionComponent = (
        <CoverImageSelection
          onFileDelete={onFileDelete}
          selectedImageFile={selectedImage}
          selectedImageUrl={imageUrl}
          backgroundColor={backgroundColor}
        />
      );
    }

    return (
      <Dropzone
        noDragEventsBubbling
        noClick
        onDrop={onFileReceive}
        ref={dropzoneRef}
        multiple={false}
        accept={acceptFormats}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            onClick={() => {
              if (dropzoneRef.current) {
                dropzoneRef.current.open();
              }
            }}
            className={classes.componentContainer}
            style={{
              alignSelf: alignSelf || "center"
            }}
          >
            <div
              className={clsx([classes.dropzoneRootComponent, "uploadContainer"])}
              style={{
                height: coverImage ? "145px" : "auto",
                alignItems: coverImage ? "flex-start" : "center"
              }}
              {...getRootProps()}
            >
              {selectionComponent}

              <input {...getInputProps()} />
            </div>
          </div>
        )}
      </Dropzone>
    );
  };

  const renderProfileImage = () => {
    let selectionComponent: any = (
      <ProfileImageSelection
        onFileDelete={onFileDelete}
        selectedImageFile={selectedImage}
        selectedImageUrl={imageUrl}
      />
    );

    if (companyImage) {
      selectionComponent = (
        <CompanyImageSelection
          onFileDelete={onFileDelete}
          selectedImageFile={selectedImage}
          selectedImageUrl={imageUrl}
        />
      );
    }
    if (coverImage) {
      selectionComponent = (
        <CoverImageSelection
          onFileDelete={onFileDelete}
          selectedImageFile={selectedImage}
          selectedImageUrl={imageUrl}
          backgroundColor={backgroundColor}
        />
      );
    }
    return (
      <div
        style={{
          alignSelf: alignSelf || "center",
          height: coverImage ? "155px" : "auto",
          width: isMobile ? "100%" : "auto"
        }}
        className={classes.componentContainer}
      >
        <div
          className={classes.dropzoneRootComponent}
          style={{
            height: coverImage ? "145px" : "auto",
            alignItems: coverImage ? "flex-start" : "center"
          }}
        >
          {selectionComponent}
        </div>
      </div>
    );
  };

  if (selectedImage || imageUrl) {
    return renderProfileImage();
  }
  return isReadOnlyContext ? null : renderDropZone();
};

export default ImageUpload;
