import type { GetAllBaseQueryRequest } from "@doorloop/dto";
import { useInfiniteQuery } from "@tanstack/react-query";
import type { RestApiBase } from "api/restApiBase";
import { useMemo } from "react";
import compact from "lodash/compact";
import { useSearchParams } from "hooks/useSearchParams";
import { DATA_LIST_QUERY_KEY, PAGE_SIZE } from "../constants";
import _ from "lodash";

const getShouldSortDescending = (sortDescending?: string) => {
  if (sortDescending === "yes") {
    return true;
  }

  if (sortDescending === "true" || sortDescending === "false") {
    return sortDescending === "true";
  }
};

interface UseDataListProps<TDto, TGetAllDto extends GetAllBaseQueryRequest> {
  restApi: Pick<RestApiBase<TDto, TGetAllDto>, "getAll">;
  queryFilter?: Partial<Omit<TGetAllDto, "page_number">>;
  infinite?: boolean;
  uniqueKey?: string;
}

export function useDataList<TDto, TGetAllDto extends GetAllBaseQueryRequest>({
  restApi,
  queryFilter = {},
  infinite = false,
  uniqueKey = "defaultKey"
}: UseDataListProps<TDto, TGetAllDto>) {
  const [searchParams] = useSearchParams();
  const sortBy = searchParams.get("sort_by") || queryFilter?.sort_by;
  const sortDescending = searchParams.get("sort_descending") || queryFilter?.sort_descending?.toString();

  const shouldSortDescending = getShouldSortDescending(sortDescending);
  const pageSize = queryFilter?.page_size ?? PAGE_SIZE;

  const queryKey = [DATA_LIST_QUERY_KEY, queryFilter, sortBy, shouldSortDescending, infinite, uniqueKey];

  const infiniteQuery = useInfiniteQuery(
    queryKey,
    async ({ pageParam = 1 }) => {
      if (!infinite) {
        return await restApi.getAll({
          ...queryFilter,
          sort_by: sortBy,
          sort_descending: shouldSortDescending
        } as TGetAllDto);
      }

      return await restApi.getAll({
        ...queryFilter,
        page_number: pageParam,
        page_size: pageSize,
        sort_by: sortBy,
        sort_descending: shouldSortDescending
      } as TGetAllDto);
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (!infinite || !lastPage.data?.total) {
          return undefined;
        }

        const { total } = lastPage.data;
        const allRowsLength = allPages.flatMap((d) => d.data?.data).length;
        if (allRowsLength >= total) {
          return undefined;
        }

        const currentPageNumber = Math.ceil(allRowsLength / pageSize);
        const nextPage = total > currentPageNumber * pageSize ? currentPageNumber + 1 : undefined;
        return nextPage;
      },
      refetchOnWindowFocus: false
    }
  );

  const { data } = infiniteQuery;
  const allRows = useMemo(() => (data ? compact(data.pages.flatMap((d) => d.data?.data)) : []), [data]);
  const totalAllData = useMemo(() => (data ? _.last(data.pages)?.data?.total : 0), [data]);

  return {
    allRows,
    totalAllData,
    infiniteQuery,
    queryKey
  };
}
