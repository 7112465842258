import React, { useEffect, useState } from "react";
import { Scroller } from "DLUI/screen";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import type { DialogBackgroundColor } from "./dialog";
import Dialog from "./dialog";
import clsx from "clsx";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { DataCy } from "@doorloop/dto";

export enum DialogState {
  Hidden,
  Show,
  Success,
  Error
}

type DialogProps<T> = (T extends React.ComponentType<infer P> ? P : never) & {
  onClose: (values?: any, shouldRefresh?: boolean) => void;
  className?: string;
  disableBackdropClick?: boolean;
  Content: T;
  dialogState: DialogState;
  loadingText?: string;
  errorText?: string;
  successText?: string;
  onRetryButtonPress?: (formikRef?: FormikProps<any>) => void;
  onBackdropClick?: () => void;
  formikRef?: FormikProps<any>;
  showCloseIcon?: boolean;
  refreshEvent?: () => void;
  title?: string;
  dataCy?: string;
  width?: number;
  height?: number;
  backgroundColor?: DialogBackgroundColor;
  permission?: AnyPermissionClearance;
};

const AlertDialog = <T extends React.ComponentType<any>>({
  dialogState,
  onClose,
  Content,
  className,
  disableBackdropClick,
  loadingText,
  errorText,
  successText,
  onRetryButtonPress,
  onBackdropClick,
  showCloseIcon,
  refreshEvent,
  title,
  width,
  height,
  backgroundColor,
  permission,
  ...rest
}: DialogProps<T>) => {
  const [scroller, setScroller] = useState<Scroller | null>(null);
  const [showDialog, setShowDialog] = useState<boolean>(dialogState !== DialogState.Hidden);

  useEffect(() => {
    if (dialogState === DialogState.Show) {
      setShowDialog(true);
      if (scroller == null) {
        setScroller(new Scroller(document.getElementsByClassName("MuiDialog-scrollBody")[0] as HTMLDivElement));
      }
    } else if (dialogState === DialogState.Hidden) {
      setShowDialog(false);
    }
  }, [dialogState, location.pathname]);

  const didFinishOperation = (values?: any) => {
    setShowDialog(false);
    onClose(values);
  };

  const _onBackdropClick = () => {
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  if (dialogState === DialogState.Hidden) {
    return <div />;
  }

  return (
    <Dialog
      open={showDialog}
      className={clsx([className, "DLUI_Dialog"])}
      onClose={_onBackdropClick}
      disableBackdropClick={disableBackdropClick}
      backgroundColor={backgroundColor}
    >
      <View
        width={"100%"}
        height={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        dataCy={DataCy.dialog.content}
        noWrap
      >
        <Content
          dialogState={dialogState}
          loadingText={loadingText}
          errorText={errorText}
          successText={successText}
          onRetryButtonPress={onRetryButtonPress}
          didFinishOperation={didFinishOperation}
          scroller={scroller}
          refreshEvent={refreshEvent}
          didPressDismissButton={_onBackdropClick}
          onBackdropClick={_onBackdropClick}
          title={title}
          onClose={onClose}
          permission={permission}
          {...rest}
        />
      </View>
    </Dialog>
  );
};

export default AlertDialog;
