import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";
import { lazy } from "react";

const FilesListScreen = lazy(() => import("screens/files/filesListScreen"));

const FilesRouter = () => (
  <PageRouter>
    <LazyRoute path={Routes.FILES_GLOBAL_NEW} render={FilesListScreen} />
    <LazyRoute path={Routes.FILES} exact render={FilesListScreen} />
    <LazyRoute path={Routes.FILES_FILE_PREVIEW} render={FilesListScreen} />
    <LazyRoute path={Routes.FILES_CONFIRM_DIALOG} render={FilesListScreen} />
    <LazyRoute path={Routes.FILES_EDIT} render={FilesListScreen} />
  </PageRouter>
);

export default FilesRouter;
