import ColorsEnum from "utils/colorsEnum";
import type { CSSProperties, PropsWithChildren, ReactNode } from "react";
import Text from "DLUI/text";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { makeStyles } from "@material-ui/styles";
import type { Theme } from "styles/defaultTheme";
import type { DeepKeys } from "@doorloop/dto";
import { get } from "lodash";

export type ThemeColorsNewPalette = DeepKeys<Pick<Theme, "newPalette">>;

export const useStyles = makeStyles<Theme>((theme) => {
  return {
    section: ({ backgroundColor }: Partial<{ backgroundColor: ThemeColorsNewPalette }>) => {
      return {
        backgroundColor: backgroundColor ? get(theme, backgroundColor)?.toString() : theme.newPalette.background.paper,
        borderRadius: 8,
        border: `1px solid ${theme.newPalette.other.divider}`,
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        width: "100%"
      };
    },
    container: {
      padding: 16
    },
    headerContainer: {
      padding: 16,
      height: 20,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    }
  };
});

export interface SectionProps {
  title: string | ReactNode;
  backgroundColor?: ThemeColorsNewPalette;
  end?: ReactNode;
  dataCy?: string;
  headerStyle?: CSSProperties;
}

export const SettingsSection = ({
  title,
  backgroundColor,
  end,
  children,
  dataCy,
  headerStyle = {}
}: PropsWithChildren<SectionProps>) => {
  const classes = useStyles({ backgroundColor });

  return (
    <section className={classes.section} data-cy={dataCy}>
      <div className={classes.headerContainer} style={headerStyle}>
        {typeof title === "string" ? <Text fontSize={16} value={title} bold /> : title}
        {end}
      </div>

      <HorizontalSeparationLine color={ColorsEnum.LightBlackFadedMore} width="100%" />
      <div className={classes.container}>{children}</div>
    </section>
  );
};
