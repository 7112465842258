export default {
  bankTransfer: {
    transferDate: "Transfer Date",
    entryDate: "Entry Date",
    transferFrom: "Transfer from",
    transferTo: "Transfer to",
    amountToTransfer: "Amount To Transfer",
    loadingText: "Creating transfer",
    createTransferSuccessText: "Transfer was created",
    noPropertySelectionText: "Select Property",
    updatingBankTransfer: "Updating transaction",
    sourceAccount: "Source Account",
    sourceProperty: "Source Property",
    receivingAccount: "Receiving Account",
    receivingProperty: "Receiving Property",
    clearingAccount: "Clearing Account",
    zeroAmountTransferWarning:
      'Please note that you have selected different properties but the same bank account. This will result in a "zero amount transaction". We will use your default clearing account for this transfer.',
    differentOwnershipTransferWarning:
      "Please be aware that you are about to transfer funds between properties that either have different owners or different ownership percentages. "
  },
  generalEntry: {
    generalEntryTitle: "Journal Entry",
    debits: "Debits",
    debitEqualsCreditsValidationMessage: "Total credits must be equal to total debits",
    mustContainAtLeast2LinesValidationMessage: "Journal entry must contain at least 2 lines",
    loadingTransactionData: "Loading transaction data",
    loadingTransactionDataSuccessText: "Loading completed"
  },
  bankDeposit: {
    dialogTitle: "Bank Deposit",
    editBankDeposit: "Edit Bank Deposit",
    instructionsText: "Please select deposit account",
    loadingUndepositEntries: "Loading Undeposited Entries",
    failedToLoadUndepositEntries: "Can't load Undeposited Entries. Check your internet connection and try again.",
    LoadUndepositEntriesSuccessText: "Loading completed",
    undepositedFundsFor: "Undeposited Funds for",
    depositDetailsFor: "Deposit Details For {{accountName}}",
    otherDepositItems: "Other Deposit Items",
    totalDeposit: "Total Deposit",
    updatingDeposit: "Updating deposit ...",
    invalidLinkDeposit: "At least one linked deposit or other deposit item is required",
    printDepositSlip: "Print Deposit Slip"
  }
};
