import React, { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import useIntercomBubble from "../../../hooks/useIntercomBubble";
import MobileDialog from "./mobileDialog";
import makeStyles from "./styles";

import { DataCy } from "@doorloop/dto";
import { Dialog } from "@material-ui/core";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import { Transition } from "../Transition";

import type { ModalProps } from "@material-ui/core";

import "./styles.css";

export type DialogBackgroundColor = "dark";

interface ComponentProps {
  open: boolean;
  className?: string;
  paperClassName?: string;
  onClose: () => void;
  disableBackdropClick?: boolean;
  onBackdropClick?: () => void;
  children: React.ReactNode;
  backgroundColor?: DialogBackgroundColor;
}

const DLUI_Dialog: React.FC<ComponentProps> = ({
  open,
  className,
  onClose,
  disableBackdropClick = true,
  onBackdropClick,
  children,
  backgroundColor,
  paperClassName
}: ComponentProps) => {
  const classes = makeStyles();
  const { isMobile } = useResponsiveHelper();

  const { hideIntercomBubble } = useIntercomBubble();

  useEffect(() => {
    isMobile && hideIntercomBubble();
  }, [hideIntercomBubble, isMobile]);

  const [dialogContainer, setDialogContainer] = useState<HTMLElement | null>(null);

  const rootClass = backgroundColor === "dark" ? classes.dialogDarkBackground : classes.dialogBackground;

  useEffect(() => {
    if (dialogContainer === null) {
      setDialogContainer(document.getElementById("dialogContainer"));
    }
  }, [dialogContainer]);

  const handleClose: ModalProps["onClose"] = useCallback(
    (_event, reason) => {
      if (reason === "backdropClick") {
        onBackdropClick?.();

        if (!disableBackdropClick) {
          onClose();
        }
      } else {
        onClose();
      }
    },
    [disableBackdropClick, onBackdropClick, onClose]
  );

  if (isMobile) {
    if (dialogContainer === null) {
      return <div />;
    }

    const element = (
      <MobileDialog open={open} onClose={onClose}>
        {children}
      </MobileDialog>
    );
    return ReactDOM.createPortal(element, dialogContainer);
  }

  return (
    <Dialog
      disableEscapeKeyDown
      disableEnforceFocus
      open={open}
      className={className}
      data-cy={DataCy.dialog.container}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      scroll={"body"}
      maxWidth={false}
      classes={{
        root: rootClass,
        paperScrollBody: classes.dialogWrapper,
        paper: paperClassName
      }}
    >
      {children}
    </Dialog>
  );
};

export default DLUI_Dialog;
