"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayBillsWizardDto = exports.PayBillsWizardStep2dDto = void 0;
const validators_1 = require("../../common/validators");
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const vendorBillPayment_dto_1 = require("../vendorBillPayments/vendorBillPayment.dto");
const vendorOutstandingTransactionsByResource_dto_1 = require("../vendorBillPayments/vendorOutstandingTransactionsByResource.dto");
const payBillsWizardStep1_dto_1 = require("./payBillsWizardStep1.dto");
const vendorOutstandingTransaction_dto_1 = require("../vendorBillPayments/vendorOutstandingTransaction.dto");
class PayBillsWizardStep2dDto {
}
exports.PayBillsWizardStep2dDto = PayBillsWizardStep2dDto;
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_validator_1.IsArray)(),
    (0, class_transformer_1.Type)(() => vendorOutstandingTransactionsByResource_dto_1.VendorOutstandingTransactionsByResource),
    __metadata("design:type", Array)
], PayBillsWizardStep2dDto.prototype, "vendorOutstandingTransactionsByResource", void 0);
class PayBillsWizardDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.PayBillsWizardDto = PayBillsWizardDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, validators_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], PayBillsWizardDto.prototype, "paymentDate", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", payBillsWizardStep1_dto_1.PayBillsWizardStep1Dto)
], PayBillsWizardDto.prototype, "step1", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true }),
    __metadata("design:type", PayBillsWizardStep2dDto)
], PayBillsWizardDto.prototype, "step2", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_validator_1.IsArray)(),
    (0, class_transformer_1.Type)(() => vendorBillPayment_dto_1.VendorBillPaymentDto),
    __metadata("design:type", Object)
], PayBillsWizardDto.prototype, "step3", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_validator_1.IsArray)(),
    (0, class_transformer_1.Type)(() => vendorOutstandingTransaction_dto_1.VendorOutstandingTransactionDto),
    __metadata("design:type", Array)
], PayBillsWizardDto.prototype, "outstandingBillsAllocation", void 0);
