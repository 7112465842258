import type { ListBulkActionsContextInterface } from "DLUI/infiniteList/listBulkActionsContext";
import type { Dispatch, SetStateAction } from "react";
import { useCallback, useMemo, useState } from "react";
import noop from "lodash/noop";
import forIn from "lodash/forIn";
import size from "lodash/size";
import remove from "lodash/fp/remove";
import includes from "lodash/fp/includes";
import uniq from "lodash/fp/uniq";
import isEmpty from "lodash/isEmpty";
import isBoolean from "lodash/isBoolean";
import type { Operation } from "engines/bulkOperationsEngine";

type BuildBulkOperations = (actionName: string) => Operation[];

export type SubscribedActions = Record<string, Record<string, Operation>>;

interface UseListBulkActionsManagerReturn {
  toggleAll: (on?: boolean) => void;
  listBulkActionsInterface: ListBulkActionsContextInterface;
  allChecked: boolean;
  anyChecked: boolean;
  isTotalAllChecked: boolean;
  selectTotalAll: VoidFunction;
  clearTotalAll: VoidFunction;
  actions: SubscribedActions;
  buildBulkOperations: BuildBulkOperations;
  checkedIds: string[];
  setCheckedIds: Dispatch<SetStateAction<string[]>>;
}

export const useListBulkActionsManager = (enabled: boolean, resourceIds: string[]): UseListBulkActionsManagerReturn => {
  const [actions, setActions] = useState<SubscribedActions>({});
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [isTotalAllChecked, setIsTotalAllChecked] = useState<boolean>(false);

  const allChecked: boolean = useMemo(() => size(checkedIds) === size(resourceIds), [checkedIds, resourceIds]);
  const anyChecked = useMemo(() => !isEmpty(checkedIds), [checkedIds]);

  const isItemChecked = useCallback((id: string) => includes(id, checkedIds), [checkedIds]);

  const toggleItem = useCallback(
    (id: string, on?: boolean) => {
      const isChecked = isBoolean(on) ? !on : isItemChecked(id);
      if (isChecked) {
        setCheckedIds((prev) => remove((checkedId) => checkedId === id, prev));
      } else {
        setCheckedIds((prev) => uniq([...prev, id]));
      }
      setIsTotalAllChecked(false);
    },
    [setCheckedIds, isItemChecked]
  );

  const subscribe = useCallback(
    (actionsToAdd: SubscribedActions) => {
      forIn(actionsToAdd, (action, actionName) => {
        actions[actionName] = {
          ...actions[actionName],
          ...action
        };
      });
      setActions({ ...actions });
    },
    [actions]
  );

  const toggleAll = useCallback(
    (forceValue) => {
      const on = forceValue ?? !allChecked;
      const newCheckedIds = on ? resourceIds : [];
      setCheckedIds(newCheckedIds);

      if (!on) {
        setIsTotalAllChecked(false);
      }
    },
    [allChecked, resourceIds]
  );

  const selectTotalAll = (): void => {
    toggleAll(true);
    setIsTotalAllChecked(true);
  };

  const clearTotalAll = (): void => {
    toggleAll(false);
    setIsTotalAllChecked(false);
  };

  const buildBulkOperations: BuildBulkOperations = (actionName) =>
    checkedIds.map((checkedId) => actions[actionName][checkedId]);

  const listBulkActionsInterface: ListBulkActionsContextInterface = enabled
    ? {
        enabled,
        toggleItem,
        subscribe,
        isItemChecked
      }
    : {
        enabled: false,
        toggleItem: noop,
        subscribe: noop,
        isItemChecked: () => false
      };

  return {
    listBulkActionsInterface,
    buildBulkOperations,
    toggleAll,
    allChecked,
    anyChecked,
    isTotalAllChecked,
    selectTotalAll,
    clearTotalAll,
    actions,
    checkedIds,
    setCheckedIds
  };
};
