"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.personTypeToCollectionMap = exports.PersonCollectionConst = void 0;
const personType_enum_1 = require("../common/personType.enum");
exports.PersonCollectionConst = {
    OWNERS: "owners",
    TENANTS: "tenants",
    VENDORS: "vendors"
};
exports.personTypeToCollectionMap = {
    [personType_enum_1.PersonTypeEnum.OWNER]: exports.PersonCollectionConst.OWNERS,
    [personType_enum_1.PersonTypeEnum.VENDOR]: exports.PersonCollectionConst.VENDORS,
    [personType_enum_1.PersonTypeEnum.TENANT]: exports.PersonCollectionConst.TENANTS
};
