export default {
  back: "Back",
  next: "Next",
  continue: "Continue",
  finish: "Finish",
  submit: "Submit",
  gotIt: "Got it!",
  helper: {
    dismiss: "Dismiss",
    scheduleACall: "Schedule a  call",
    newLeaseWizardBanner: {
      title: "Need Help Adding Your Existing Data?",
      description:
        "We can do the heavy lifting for you and import your properties, units and leases free of charge. Schedule a call with a migration consultant."
    }
  },
  updateNow: "Update Now"
};
