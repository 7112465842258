import React, { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import type { FormikProps } from "formik";
import { Formik, getIn } from "formik";
import _ from "lodash";

import { PostUpdateStripeExternalAccountRequestDto, createValidator, MerchantAccountPlatform } from "@doorloop/dto";

import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { TextField } from "DLUI/form";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { stripeApi } from "api/stripeApi";

import AppStrings from "../../../../../../locale/keys";

import { BankAccountNumber } from "./components/bankAccountNumber";
import { StripeAlertNoActionRequired, DialogHeightNoAction } from "./components/stripeAlertNoActionRequired";
import { StripeDialogContainer } from "./components/stripeDialogContainer";

import { useDialogStripeOnboarding } from "./hooks/useDialogStripeOnboarding";
import { StripeNeedAssistance } from "./components/StripeNeedAssistance";

const DialogHeight = Math.min(window.innerHeight, 610);
const DialogWidth = Math.min(window.innerWidth, 450);

let formikGlobalRef: FormikProps<any> | null = null;

const StripeActionRequiredBankAccount = () => {
  const { t } = useTranslation();

  const {
    DialogViews,
    bankAccount,
    loadingDialogState,
    loadingDialogErrorText,
    viewIndex,
    setLoadingDialogState,
    showErrorMessage,
    onBackdropClick
  } = useDialogStripeOnboarding();

  const isActionRequired = useMemo(
    () =>
      bankAccount &&
      bankAccount.merchantAccountPlatform === MerchantAccountPlatform.PAYRIX_USD &&
      bankAccount.merchantAccountActionRequired &&
      bankAccount.merchantAccountExternalAccountRequired &&
      bankAccount.merchantAccountExternalAccountRequiredLast4Digits,
    [bankAccount]
  );

  const formInitialValues = useMemo(() => {
    const dto = new PostUpdateStripeExternalAccountRequestDto();

    if (bankAccount?.id) {
      dto.accountId = bankAccount.id;
    }

    return dto;
  }, [bankAccount]);

  const handleSubmit = async () => {
    setLoadingDialogState(DialogState.Show);

    if (bankAccount?.id) {
      if (formikGlobalRef) {
        const isValid = await isValidForm(formikGlobalRef);

        if (isValid) {
          const response = await stripeApi.postStripeExternalAccount(formikGlobalRef.values);

          if (response.status) {
            setLoadingDialogState(DialogState.Success);
            onBackdropClick();
          } else {
            showErrorMessage(response.message);
          }
        } else {
          setLoadingDialogState(DialogState.Error);
        }
      }
    } else {
      showErrorMessage(t(AppStrings.Accounts.MerchantAccountNotFound));
    }
  };

  const validateForm = createValidator(PostUpdateStripeExternalAccountRequestDto);

  const isValidForm = async (formikRef: FormikProps<any>) => {
    formikRef.setFieldTouched("accountId");
    formikRef.setFieldTouched("fullAccountNumber");
    formikRef.setFieldTouched("verifyFullAccountNumber");

    const errors = (await formikRef.validateForm()) as any;

    if (_.isEmpty(errors)) {
      const accountNumber = getIn(formikRef.values, "fullAccountNumber");

      if (accountNumber && bankAccount?.merchantAccountExternalAccountRequiredLast4Digits) {
        if (accountNumber.endsWith(bankAccount.merchantAccountExternalAccountRequiredLast4Digits)) {
          setTimeout(() => {
            formikRef.setFieldError("fullAccountNumber", undefined);
          }, 0);

          return true;
        }
        setTimeout(() => {
          formikRef.setFieldError("fullAccountNumber", t(AppStrings.Accounts.Stripe4digitsValidationError));
        }, 0);

        return false;
      }
      return false;
    }
    return false;
  };

  const renderView = ({ index }: any) => {
    if (index === DialogViews.LoadingView) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          errorText={loadingDialogErrorText}
          onRetryButtonPress={handleSubmit}
          didPressDismissButton={onBackdropClick}
        />
      );
    }
    if (index === DialogViews.ContentView && bankAccount) {
      if (isActionRequired) {
        return (
          <StripeDialogContainer
            onBackdropClick={onBackdropClick}
            onSubmit={handleSubmit}
            dialogHeight={DialogHeight}
            backgroundHeight={260}
            backgroundMobileOffset={20}
            ctaActionText={t(AppStrings.Common.Save)}
            isLoading={loadingDialogState === DialogState.Show}
          >
            <View marginTop={20} paddingRight={20} paddingLeft={20} gap={10}>
              <Text align={"center"} color={"white"}>
                {t(AppStrings.Accounts.StripeActionRequiredBankAccountContentI)}
              </Text>
              <Text align={"center"} color={"white"}>
                {t(AppStrings.Accounts.StripeActionRequiredBankAccountContentII)}
              </Text>
            </View>

            <View marginTop={20} paddingLeft={20} paddingRight={40}>
              <BankAccountNumber
                accountName={bankAccount.name}
                last4digits={bankAccount.merchantAccountExternalAccountRequiredLast4Digits}
                isActionRequired
              />
            </View>

            <View marginTop={20} paddingRight={20} paddingLeft={20}>
              <Text color={"gray"}>{t(AppStrings.Accounts.StripeActionRequiredBankAccountContentIII)}</Text>
            </View>

            <View marginTop={10} paddingRight={20} paddingLeft={20}>
              <Formik
                initialValues={formInitialValues}
                onSubmit={() => {}}
                validate={validateForm}
                className={"formPosition"}
              >
                {(formik) => {
                  formikGlobalRef = formik;

                  return (
                    <View fullWidth gap={10}>
                      <FastFieldSafe
                        component={TextField}
                        label={t(AppStrings.Common.BankAccountNumber)}
                        name={`fullAccountNumber`}
                        size={"100%"}
                        required
                      />

                      <FastFieldSafe
                        component={TextField}
                        label={t(AppStrings.Common.ConfirmBankAccountNumber)}
                        name={`verifyFullAccountNumber`}
                        size={"100%"}
                        required
                      />
                    </View>
                  );
                }}
              </Formik>
            </View>

            <View marginTop={10} paddingRight={20} paddingLeft={20}>
              <StripeNeedAssistance />
            </View>
          </StripeDialogContainer>
        );
      }
      return <StripeAlertNoActionRequired dialogWidth={DialogWidth} onBackdropClick={onBackdropClick} />;
    }

    return <View />;
  };

  useEffect(() => {
    if (bankAccount?.id) {
      if (
        !bankAccount.merchantAccountExternalAccountRequiredLast4Digits ||
        bankAccount.merchantAccountExternalAccountRequiredLast4Digits.length !== 4
      ) {
        showErrorMessage();
      }
    }
  }, [bankAccount?.merchantAccountExternalAccountRequiredLast4Digits]);

  return (
    <DialogFrame
      useExperimentalDialogFrame
      onCloseButtonClick={onBackdropClick}
      width={DialogWidth}
      height={isActionRequired ? DialogHeight : DialogHeightNoAction}
      renderView={renderView}
      numViews={2}
      activeView={viewIndex}
      frameType={"contentOnly"}
    />
  );
};

export { StripeActionRequiredBankAccount };
