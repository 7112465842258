import React from "react";
import Text from "DLUI/text";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { View } from "DLUI/view";
import type { IndicationTag } from "DLUI/drawer/types";
import { Tag } from "DLUI/tag/tag";

interface ComponentProps {
  isActive: boolean;
  label: string;
  count: number;
  tag?: IndicationTag;
}

const maxCountToView = 99;

export const CommunicationCenterMenuItemLabel = ({ isActive, label, count, tag }: ComponentProps) => {
  const { isTabletOrMobile } = useResponsiveHelper();

  const fontSize = isTabletOrMobile ? 16 : 14;

  return (
    <View width={"100%"} noWrap flexDirection={"row"} justifyContent={"space-between"}>
      <View autoWidth flexDirection={"row"}>
        <Text color={"white"} bold={isActive} value={label} fontSize={fontSize} marginLeft={5} marginRight={2} />
        {tag && <Tag value={tag} />}
      </View>
      {count > 0 && (
        <Text
          color={"white"}
          bold={isActive}
          value={`(${count > maxCountToView ? `${maxCountToView}+` : count})`}
          fontSize={fontSize}
          marginLeft={5}
        />
      )}
    </View>
  );
};
