import type { PropsWithChildren } from "react";
import React from "react";
import { View } from "DLUI/view";

interface ComponentProps {
  maxWidth?: number;
}

export const StepDefaultMaxWidth = 550;

const StepContainer: React.FC<ComponentProps> = ({ maxWidth, children }: PropsWithChildren<ComponentProps>) => (
  <View marginTop={20} marginBottom={80} alignItems={"center"}>
    <View maxWidth={maxWidth || StepDefaultMaxWidth} alignItems={"center"}>
      {children}
    </View>
  </View>
);

export default StepContainer;
