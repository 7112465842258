import * as Filters from "@/components/common/filters";
import { useContextSelector } from "use-context-selector";
import { FilterPanelContext } from "../context";
import { useSideFilterOptions } from "../hooks/useSideFilterOptions";
import type { SidePanelProps } from "@/components/common/filters/components/sidePanel";
import type { RootProps } from "@/components/common/filters/components/root";
import type { ToggleProps } from "@/components/common/filters/components/toggle";

export interface SideFiltersProps<TQuery> {
  sidePanelProps?: SidePanelProps;
  filtersProps?: Pick<RootProps<TQuery>, "sidePanelState" | "onSidePanelStateChange">;
  renderButton?: ToggleProps["renderButton"];
  hideChips?: boolean;
}

export function SideFilters<TQuery>({
  sidePanelProps,
  filtersProps,
  renderButton,
  hideChips = false
}: SideFiltersProps<TQuery>) {
  const filterOptions = useContextSelector(FilterPanelContext, (context) => context.filterOptions);
  const sidePanelFilterOptions = useSideFilterOptions(filterOptions);
  if (sidePanelFilterOptions.length === 0) {
    return null;
  }

  return (
    <Filters.Root filterOptions={sidePanelFilterOptions} {...filtersProps}>
      <Filters.Content>
        <Filters.Toggle renderButton={renderButton} />
        {!hideChips && <Filters.Chips hiddenOnMobile={false} />}
        <Filters.SidePanel {...sidePanelProps} />
      </Filters.Content>
    </Filters.Root>
  );
}
