import { AnimateMarginTop } from "DLUI/animatableView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import type { Queryable } from "../types";
import ReportFooterText from "./dataGrid/reportFooterText";
import React from "react";

export const EmptyListView = <GetAllDtoType extends Queryable>({
  requestInProgress,
  dataSource,
  filterObj,
  GridFooter
}) => (
  <View alignItems={"center"} className={"list-container"} noWrap>
    <AnimateMarginTop marginTop={20}>
      <div />
    </AnimateMarginTop>
    <View maxWidth={1514} backgroundColor={"white"} justifyContent={"center"} borderRadius={10} flexDirection={"row"}>
      <View alignItems={"center"} justifyContent={"center"} flexDirection={"row"}>
        <View alignItems={"center"} marginBottom={20} noWrap>
          <Text
            value={AppStrings.Reports.TransactionsReport.EmptyDataText}
            color={"black"}
            fontSize={16}
            marginTop={20}
          />
          <ReportFooterText
            GridFooter={GridFooter}
            requestInProgress={requestInProgress}
            dataSource={dataSource}
            filterObj={filterObj}
          />
        </View>
      </View>
    </View>
  </View>
);
