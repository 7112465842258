import type { PropsWithChildren } from "react";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useLocalStorageState } from "../../../../hooks/useLocalStorage";
import AppStrings from "../../../../locale/keys";
import { Typography } from "@material-ui/core";

interface ComponentProps {
  description?: string;
  actionText?: string;
}

export const HelpPanelAccordion = ({ description, actionText, children }: PropsWithChildren<ComponentProps>) => {
  const { t } = useTranslation();
  const [seenPagesMapLocalStorage, setSeenPagesMapLocalStorage] = useLocalStorageState("seenPagesMap", "{}");
  const seenPagesMap = JSON.parse((seenPagesMapLocalStorage as string) || "{}");

  const isCollapsedByDefault = seenPagesMap[window.location.pathname];
  const [isCollapsed, setIsCollapsed] = useState<boolean>(isCollapsedByDefault);

  useEffect(() => {
    if (!isCollapsed) {
      setSeenPagesMapLocalStorage(JSON.stringify({ ...seenPagesMap, [window.location.pathname]: true }));
    }
  }, [isCollapsed]);

  return (
    <>
      <Typography style={{ lineBreak: "auto", fontSize: "14px", color: " #182C4C99" }}>
        {description && t(description)} {description && Boolean(t(description)) && children ? " | " : ""}
        {children ? (
          <Typography
            display={"inline"}
            variant="caption"
            style={{
              color: "#1665D8",
              cursor: "pointer",
              fontSize: "14px",
              textDecoration: "underline",
              userSelect: "none"
            }}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {t(actionText ?? AppStrings.Common.NeedHelp)}
          </Typography>
        ) : null}
      </Typography>
      <AnimatePresence initial={false}>
        {!isCollapsed && (
          <motion.div
            initial={{ height: 0, opacity: 0, y: -10 }}
            animate={{ height: "auto", opacity: 1, y: 0 }}
            exit={{ height: 0, opacity: 0 }}
            style={{ overflow: "hidden", width: "100%" }}
            transition={{ duration: 0.2, bounce: 0.7, ease: "easeInOut" }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
