import type { TranslationOverride } from "../types";
import { ServerRoutes, TaskType } from "@doorloop/dto";

export const homeowner_en_overrides: TranslationOverride = {
  common: {
    allowTenantOnlineRequests: "Allow Owners to submit online requests",
    bankTransferAch: "Owners can pay with bank transfer (ACH)",
    requireInsuranceDocuments: "Owners Can Upload Insurance Policy",
    tenantPortalSettingsSections: {
      allowTenantToAccessTheTenantPortal: "Allow Owners To Access The Resident Center",
      tenantPortalSettings: "Resident Center Settings",
      addInstructions:
        "Add instructions for home owner requests. It will be displayed when new requests are created at the resident center.",
      tenantCanPayWithCreditCard: "Owners can pay with credit card",
      instructions: "Add instructions for owner requests."
    },
    tenantPageWidgets: {
      titles: {
        notSignedToThePortal: "Didn’t sign into Resident Center"
      },
      tooltips: {
        balanceDue: "Click to see all owners with outstanding balances",
        missingContactInfo: "Click to filter out all the owners with missing contact information.",
        notSignedToThePortal: "Click to filter out all the owners who didn’t sign into the residential center."
      },
      tenants: "Owners",
      seeTenants: "See owners"
    },
    switchLease: "Switch Homeowner Account",
    sendTenantPortalInvitation: "Send resident portal invitation",
    tenantPortalStatus: "Resident Center Status",
    sendPortalInvitation: "Invite to Resident Center",
    tenantLoginToPortal: "Owner log in to portal",
    sendWelcomeSmsDescription: "Send a welcome message to your owners",
    sendWelcomeSmsInEditDialog: `Once you click "Save", they will receive an SMS with the association manager's contact information.`,
    receivePaymentsHelpPanelArticleTopic: "Check the Status of Online Payments With the Received Payments Page",
    merchantAccount: {
      helpPanel: {
        application: "Setup a Merchant Account to Accept Online Payments"
      }
    },
    myLease: "My Homeowner Account Details",
    leaseAddress: "Homeowner Address",
    recurring: {
      helpPanel: {
        createRecurringBill: "How do I create a recurring bill?"
      }
    },
    tenantPortalMissingInfoTooltip: "In order to send an invite please fill out the owner email or mobile number",
    daysBeforeRentIsDue: "Days before dues are charged",
    daysAfterRentIsDue: "Days after dues are charged",
    doNotSentNotificationsAsTextMessagesRentReminders: "Send text message notifications to owners",
    rentChargeDoNotSendReminderIfTenantAlreadyPaid: "Do not send a notification if the owner has already paid",
    paymentReceivedNotifyByEmail: "Send a notification to the owner when they make an online payment",
    printChecks: {
      helpPanel: {
        description: "Print checks to pay your vendors & owners."
      }
    },
    inviteToPortal: "Invite to Resident Center",
    pleaseContactYourPropertyManager:
      "Please contact your association manager for further information on how to make a payment",
    welcomeDialog: {
      dialogTitle: "Welcome to your Resident Center 🎉",
      dialogDescription:
        "Your personal resident center is an all-in-one destination for checking your balance, making payments, submitting requests and viewing important announcements."
    },
    noActiveRequestsDescription: "There are no requests for this homeowner account",
    noActiveAutoPayDescription: "Auto-Pay is not setup for this homeowner account",
    tenantRequestSettingsInstructionsDescription:
      "You can add additional instructions for your owners that will be displayed when new requests are created from the Resident Center",
    tenantPortalFeaturesAccess: "Allow owners to access the Resident Center",
    tenantPortalContactInfoDescription: "You can customize the Resident Center contact info",
    portalInvites: "Center Invites",
    hideTenantPortalContactInfo: "Do not show company contact info in the resident center",
    portalUrl: "Center URL",
    portalContactInfo: "Center Contact Info",
    tenantPortalInvitesDescription: "You can customize the look and feel of the email invites sent to your owners",
    tenantPortalThemeSettingsDescription: "You can customize the look and feel of your resident center",
    properties: "Associations",
    searchProperties: "Search associations",
    globalSearch: {
      searchInstructions: "Search for associations, units, homeowner accounts, people, tasks, & more"
    },
    enums: {
      receivedFrom: {
        TENANT: "Owner"
      },
      taskType: {
        TENANT_REQUEST: "Owner Request"
      },
      communicationLinkedToType: {
        TENANT: "OWNER"
      },
      defaultAccountType: {
        UNAPPLIED_LEASE_PAYMENTS_INCOME: "Unapplied Homeowner Account Payments Income",
        RENTAL_INCOME: "Association Dues"
      },
      eSignatureSignerType: {
        TENANT: "Owner"
      }
    },
    specifyTenantPortalContactInfo: "Specify company contact info for the resident center",
    specifyOwnerPortalContactInfo: "Specify company contact info for the resident center",
    specifyFeaturesForLease: "Specify resident center features for this homeowner account",
    tenantRequestsSettings: "Owner Requests Settings",
    notifyTenantOnReceived: "Automatically notify the owner once an owner request is received",
    notifyAssignedOnReceived: "Automatically notify assigned when an owner request is received",
    automaticallyAssignUsers: "Automatically assign users when an owner request is received",
    tenantPortalAccess: "Invite this owner to the resident center",
    tenantRequestsSettingItemTitle: "Association Owners Requests",
    tenantRequestsSettingItemDescription: "Get notified and automatically assign new owner requests.",
    rentRemindersTitle: "Dues & Payment Notifications",
    rentRemindersTitleDescription: "Send email and text notifications to your owners.",
    rentOverdueSettingTitle: "Send recurring notifications to remind the owner about overdue dues",
    rentChargeNotificationsDescription: "Send a notification to remind the owner about an upcoming dues",
    rentChargeNotifications: "Dues Posted",
    rentReminders: "Dues Reminder",
    upcomingRent: "Upcoming Dues",
    rentNotificationSendEmailWhenRentIsCharged: "Send a notification to the owner when dues are posted",
    paymentReceivedNotifyUsersByEmail: "Send a notification to DoorLoop users when an owner makes an online payment",
    paymentReversedNotifyByTextMessage: "Send a text to the owner when a payment is returned",
    rentOverdue: "Dues Overdue",
    paymentReversedNotifyByEmail: "Send a notification to the owner when an online payment is returned",
    settings: {
      lateFees: {
        daysAfterRentDue: "Days after dues are due"
      }
    },
    oneDayBeforeLateFeeChargedSettingDescription:
      "Send a notification reminder to the owner via email or push notification before late fees are charged",
    hidePortalContactInfoForProperty: "Use company default center contact info for this association",
    specifyPortalContactInfoForProperty: "Specify center contact info for this association",

    hideInvitesForProperty: "Use company default center invites info for this association",
    specifyInvitesForProperty: "Specify center invites info for this association",

    hidePortalUrlForProperty: "Use company default center URL for this association",
    specifyPortalUrlForProperty: "Specify center URL for this association",

    hideTenantRequestSettingsForProperty: "Use company default owner request settings for this association",
    specifyTenantRequestSettingsForProperty: "Specify owner request settings for this association",

    hideThemeForProperty: "Use company default center theme for this association",
    specifyThemeForProperty: "Specify center theme for this association",

    hideFeaturesForProperty: "Use company default center features for this association",
    specifyFeaturesForProperty: "Specify center features for this association",

    hideFeaturesForLease: "Use association default center features for this homeowner account",
    tenantRequestSettingsInstructions: "Owner Request Instructions",
    hideSectionForProperty: "Use company default settings for this association",
    specifySettingsForProperty: "Specify settings for this association",
    specifyRentRemindersForProperty: "Specify notifications for this association",
    specifyPaymentInstructionsForProperty: "Specify payment Instructions for this association",
    specifyTenantPortalNotificationsForProperty: "Specify notifications settings for this association",
    tenantsPortalSettingsDescription: "Set features and permissions for your resident center",
    viewLeaseLedger: "View homeowner account ledger",
    outgoingPayments: {
      emailInfoDescription: "An email will be sent, asking them how they prefer to get paid.",
      enableOutgoingPaymentsForThisTenant: "Enable send payments for this owner",
      connectToCheckbookDesc2:
        "Follow the steps below to connect DoorLoop to Checkbook and start sending electronic payments to your owners and vendors directly from DoorLoop.",
      tenantOutgoingPaymentInfo: "Owner Send Payment Info"
    },
    filterOptions: {
      property: "Association",
      leaseStatus: "Account status",
      lease: "Homeowner account",
      tenantPortalStatus: "Resident Center Status"
    },
    createNewMenu: {
      tenantTransactions: "Owner Transactions",
      lease: "Homeowner account"
    },
    paymentInstructionsSettingsDescription: "Specify instructions for owners on how they should pay their dues.",
    endLeaseWarning:
      "Please note, once the homeowner account is ended, it can no longer be reactivated. The end date you enter for the homeowner account will also be the end date for the resident center and access will end for all owners on this homeowner account.",
    tenantPortalAccessActive: "Resident Center Active",
    tenantPortalAccessActiveDescription: "The owner successfully logged into the resident center.",
    tenantPortalInvitedDescription: "We sent the owner an invitation to join the resident center.",
    listHeader: {
      tenant: "Owner"
    },
    newPortalInviteDescription:
      "Once you click “Save”, they will receive an email and a text message to access their resident center.",
    activityFeed: {
      visibleToTenant: "Owner Notified",
      propertyManager: "Association Manager"
    },
    leaseDetails: "Homeowner account details"
  },
  drawerItems: {
    properties: "Associations List",
    rentals: "Associations",
    activeLeases: "Homeowner Accounts",
    tenants: "Owners",
    leases: "Homeowner accounts",
    tenantRequests: "Owner Requests",
    leasing: "Homeowner accounts",
    unpaidRent: "Unpaid Dues"
  },
  listing: {
    generalUnitInfoDescription: "General information about your unit.",
    photosDescription: "Upload, rearrange, or delete photos.",
    propertyAmenities: "Association Amenities",
    propertyDetails: "Association Details",
    selectWhichPetsLabel: "Please select which pets are allowed in this association",
    petsPolicyDescription: "Set your pet policy for this association.",
    unitListingActiveDescription: "List this unit for rent on partner websites",
    propertyNoAmenitiesDescription: "Add all the amenities included in your association.",
    propertyNoOwnersDescription: "Add the owner(s) of the association here.",
    unitNoAmenitiesDescription: "Add all the amenities included in your unit.",
    unitAmenitiesInfoDescription: "Please select which amenities are included in this unit.",
    propertyAmenitiesInfoDescription: "Please select which amenities are included in this association.",
    updatePropertyInformation: "Update Association Information",
    generalInformationSettingsDescription: "Update your association address, name and description.",
    photosSettingsDescription: "Upload photos for this association",
    classAndTypeSettingsDescription: "Let us know which association",
    amenitiesSettingsDescription: "Specify which amenities are included in this association",
    petPolicySettingsDescription: "Update the pet policy for this association",
    propertyDescription: "Association Description"
  },
  properties: {
    screen: {
      addNewProperty: "New Association",
      emptydataViewInstructionsText: "No Associations Found",
      emptydataAddNew: "Add your first association",
      choosePropertyTypelabel: "Let's add your association",
      whatKindOfProperty: "Which kind of association do you manage?",
      maintainReserveFunds: "Would you like to maintain a minimum balance (“Reserve”) for this association?",
      whoOwnsProperty: "Who owns this association?",
      setPropertyReserve: "Set association reserve",
      dontSetPropertyReserve: "Don't set association reserve",
      propertyOwnedByMe: "Owned by me",
      propertyOwnedBySomeoneElse: "Owned by someone else",
      choosePropertyOwnersText: "Add one or more owners and set their ownership percentage",
      choosePropertySubTypelabel: "Which type best describes your association?",
      loadingProperties: "Loading Association",
      creatingNewProperty: "Please wait while we create your association...",
      creatingNewPropertyFailed:
        "Failed to create the association. Make sure you are connected to the internet and try again.",
      creatingNewPropertySuccess: "Your association was successfully added!",
      searchPaneltitle: "Manage your associations",
      searchPlaceHolder: "Search associations",
      vacantUnits: "Vacant Units",
      listedUnits: "Listed Units",
      deleteFileConfirmationText: "Are you sure you want to delete the association?",
      deleteFileLoadingText: "Deleting association",
      deleteFileSuccessText: "The association was deleted successfully",
      makeInactiveFileConfirmationText: "Are you sure you want to make the association inactive?",
      makeActiveFileConfirmationText: "Are you sure you want to activate the association?"
    },
    address: {
      titleText: "Association address",
      instructionsText: "What's the association address?"
    },
    bankAccounts: {
      title: "Association Bank Accounts",
      subtitle: "Which bank accounts should be used for this association?",
      chooseAccount: "Which account should we use as the main bank account for this association?",
      notificationTitleDefault: "Why do I need to select a bank account for this association?",
      notificationBodyDefault:
        "Each association must be associated with a bank account." +
        " This should be the bank account you will be depositing dues payments into and paying expenses from.",
      notificationTitleSpecify: "Why do I need to select a bank account for this association?",
      notificationBodySpecify:
        "Each association must be associated with a bank account. " +
        "This should be the bank account you will be depositing dues payments into and paying expenses from."
    },
    listHeader: {
      property: "Association"
    },
    units: {
      addUnitsText: "Let's add the units for the association"
    },
    settings: {
      convenienceFeesDescriptionCompanySubTitle: "Set extra convenience fees associated with owner payments.",
      whichEscrowAccount: "Which account should we use as the reserve funds bank account?”",
      residentPortalDescriptionTitle: "Resident Center access settings.",
      addressDescriptionTitle: "Association primary address settings",
      addressDescriptionSubTitle: "Press to edit your association address details",
      typeDescriptionTitle: "Association type settings",
      typeDescriptionSubTitle: "Edit the association name, address, and type.",
      ownersDescriptionTitle: "Association owner settings",
      ownersDescriptionSubTitle: "Update the owners for this association.",
      managerDescriptionTitle: "Association managers settings",
      managerDescriptionSubTitle: "Select your association managers.",
      bankAccountsDescriptionTitle: "Association bank accounts settings",
      bankAccountsDescriptionSubTitle: "Select the association bank accounts.",
      reserveFundsDescriptionTitle: "Association reserve funds settings",
      reserveFundsDescriptionSubTitle: "Edit the amount of reserve funds.",
      managementFeesDescriptionTitle: "Association management fees settings",
      lateFeesDescriptionTitle: "Association management late fees",
      paymentsDescriptionTitle: "Association payment settings",
      residentPortalDescriptionSubTitle: "Edit the resident center settings",
      propertySettings: "Association Settings",
      property: "Association",
      detailedDescriptionOfThisProperty: "Detailed description of this association",
      editProperty: "Edit Association",
      propertyName: "Association Name",
      propertyAddress: "Association Address",
      propertyType: "Association Type",
      propertyBankAccounts: "Association Bank Accounts",
      propertyBankAccountsDescription:
        "You can set different bank accounts for each association as needed, or use your default accounts.",
      useCompanyDefaultBankAccounts: "Use company default bank accounts",
      specifyBankAccountForProperty: "Specify a bank account for this association",
      propertyReserveFunds: "Association Reserve Funds",
      propertyReserveFundsDescription:
        "Reserve funds will make sure there is always enough money in the bank. It is used to calculate the available cash for this association.",
      whatIsReserveFundsToolTipTitle: "What is an association reserve?",
      whatIsPropertyOwnershipToolTipTitle: "What is association ownership?",
      whatIsPropertyOwnershipToolTipDescription:
        "If you own this association, you can skip this step. If you manage associations for 3rd parties, adding them as owners will automate your distributions and 1099 tax forms.",
      whatIsReserveFundsToolTipDescription:
        "Setting an association reserve tells DoorLoop to make sure there are always sufficient funds in the bank to cover expenses when making bill payments.",
      propertyManagementFees: "Association Management Fees",
      propertyManagementFeesDescription:
        "If you manage this association for someone else, you can set your management fee structure.",
      useCompanyDefaultManagementFees: "Use the default management fee structure",
      specifyManagementFeesForProperty: "Use a custom management fee for this association",
      propertyLateFees: "Association Late Fees",
      propertyLateFeesDescription:
        "If you manage this Association on behalf of a landlord, you can set up your late fee structure or use the company default setting",
      useCompanyDefaultLateFees: "Use company default late fee structure",
      usePropertyDefaultLateFees: "Use association default late fee structure",
      specifyLateFeesForProperty: "Specify a late fee structure for this association",
      propertyResidentPortal: "Association Resident Center",
      propertyResidentPortalDescription:
        "Give your owners access to the resident center where they can make payments and create maintenance requests.",
      specifyResidentPortalForProperty: "Select the resident center options for this association",

      propertyPaymentsAllocation: "Association Payments Allocation",
      propertyPaymentsAllocationDescription: "You can set a custom payment allocation or use the default allocation.",
      specifyPaymentAllocationForProperty: "Customize the payment allocation for this association.",
      managementFeesDescriptionSubTitle: "Set the late fees for these homeowner accounts"
    }
  },
  leases: {
    reviewStepItems: {
      propertyNameTitle: "Association Name",
      unitNameTitle: "Unit Name",
      leaseTermsTitle: "Homeowner Account Terms",
      rentChargesTitle: "Association Dues",
      reviewTitle: "Your Homeowner Account Summary",
      tenantsTitle: "Owners",
      stepSubtitle:
        "Before completing the new homeowner account, please conduct a thorough review to ensure all details are in order.",
      paymentLimit:
        "By default, there is a limit on the amount you can receive from owner payments via credit card or ACH. To increase this limit, please submit a request."
    },
    leaseRenewals: {
      endLease: "End Account"
    },
    endLease: {
      endingLeaseTitle: "End Account: Follow these steps to terminate it.",
      hasOBAndDepositButDepositGreater: "Your homeowner account has an outstanding balance.",
      hasOBAndDepositButDepositLessThan: "Your homeowner account has an outstanding balance.",
      leaseHasOBAndNoSecurityDeposit: "Your homeowner account has an outstanding balance.",
      moveOutDate: "End date",
      moveOutDateDescription: "What is the end date?",
      currentLeaseTerms: "Current Homeowner Account Terms",
      endLeaseAnyway: "End Homeowner account Anyway",
      moneyOwedToTenant: "Money owed to owner",
      leaseBalance: "Homeowner account Balance",
      helpPanel: {
        subtitle:
          "Terminate a Homeowner account within DoorLoop and deactivate tenant access. You'll still retain the ability to manually input transactions.",
        endLease: "How to end a Homeowner account",
        endedLeaseEdit: "How to edit a Homeowner account after ending it",
        endedLeaseDeposit: "How to withhold or refund a lease deposit after ending the Homeowner account",
        videoEndLease: "Ending a Homeowner account"
      },
      refundTenant: "Refund Owner",
      zeroBalanceNotificationLabel:
        "This homeowner account has no outstanding balance.\nYou can go ahead and end the homeowner account!",
      leaseHasSecurityDepositAndNoOB: "This homeowner account has a security deposit and no outstanding balance.",
      refundDescription: "Refund the security deposit to the owner",
      actionsLabelOneAction: "In order to zero out the balances you will need to perform the following action:",
      actionsLabelExactlyOneAction: "In order to end the homeowner account please choose one of the following options:",
      actionsLabelTwoActions: "In order to fix this issue, you will need to perform the following actions:",
      actionsLabelOneOrMoreActions:
        "In order to end the homeowner account you will need to perform one or more of the following actions:",
      deleteLeaseAnywayDescription: "Alternatively, you can end the homeowner account and adjust the balances later",
      issueCreditToReduceAmountOwedDescription:
        "Add a credit to the homeowner account to reduce the amount owed to you.",
      postChargeToZeroOutDescription:
        "Post a charge to the security deposit account to zero out the homeowner account balance.",
      withholdDepositToApplyToLease: "Withhold the security deposit to apply it to charges on the homeowner account",
      leaseHasOutstandingCredit:
        "This homeowner account has an outstanding credit. This can happen when the owner overpaid their rent.",
      leaseHasOutstandingCreditWithSecurityDeposit:
        "This homeowner account has an outstanding credit. This can happen when the owner overpaid their rent.\n\nThere is also a security deposit balance.",
      leaseOutstandingCredit: "Homeowner account Outstanding Credit",
      refundTheAmountOwedTheTenant: "Refund the amount owed the owner",
      refundTheCreditAndDepositToTenant: "Refund the outstanding credit and the security deposit to the owner"
    },
    wizardSteps: {
      tenants: "Owners",
      rent: "Association Dues"
    },
    screen: {
      manageLease: "Manage Account",
      lease: "Homeowner account",
      addNewLease: "New Homeowner Account",
      emptydataViewInstructionsText: "No Homeowner accounts Found",
      emptydataAddNew: "Add your first homeowner account",
      searchPaneltitle: "Manage your homeowner accounts",
      searchPlaceHolder: "Search homeowner accounts",
      createLease: "Create Homeowner account",
      makeActive: "Activate Homeowner account",
      cancelLease: "Cancel Homeowner account",
      cancelLeaseLoadingText: "Canceling draft homeowner account",
      cancelLeaseSuccessText: "Homeowner account was canceled",
      activateLeaseLoadingText: "Activating homeowner account",
      activateLeaseSuccessText: "Homeowner account was activated",
      leaseType: "Homeowner account Type",
      termduring: "Term During:",
      termStarts: "Term Starts:",
      newLease: "New Homeowner account",
      draftLease: "Homeowner account",
      existingLease: "Homeowner account",
      leaseRenewal: "Homeowner account Renewal",
      renewalLease: "Renewal Homeowner account",
      deleteConfirmationText: "Are you sure you want to delete this homeowner account?",
      deleteLoadingText: "Deleting homeowner account",
      deleteSuccessText: "Homeowner account was deleted successfully",
      helpPanel: {
        draftLease: {
          description: "Manage and edit your homeowner accounts."
        }
      }
    },
    insurancePolicy: {
      selectLease: "Select Homeowner account"
    },
    editLease: {
      selectUnitsAddRemoveFromXLease: 'Select units to add or remove from "{{x}}" homeowner account'
    },
    newLease: {
      leaseOverview: {
        propertySection: {
          title: "Association",
          subtitle: "Which association is this homeowner account for?",
          propertyPlaceholder: "Choose Association"
        },
        termsSection: {
          dates: {
            startDate: "Purchase Date"
          }
        }
      },
      leaseStatus: {
        screenTitle: "Account Status"
      },
      leaseRent: {
        occurrencesRemaining: "Occurrences Remaining",
        occurrences: "Occurrences",
        nextOccurrence: "Next Occurrence",
        lastOccurrence: "Last Occurrence",
        rentCharges: "Dues Charges",
        charges: "Charges",
        charge: "Charge",
        recurringCreditDescriptionHeader: "How often do you want to post this credit?",
        addSecurityDepositCharge: "Add deposit charge",

        addAnotherSecurityDepositCharge: "Add another deposit charge",
        firstRentDate: "When do you want to start charging dues?",
        firstRentPlaceholder: "First Dues Date",
        rentFrequency: "How often do you charge dues?",
        rentChargesItemDescription: "What are your dues charges?",
        rentChargesDescription:
          "These are the scheduled charges this homeowner account will incur for dues going forward.",
        addFutureRent: "Add Future Dues",
        scheduleRentIncrease: "Schedule Dues Increase",
        amount: "Amount",
        whicAccount: "What is this for?",
        whichRentAccount: "What is this charge for?",
        description: "Add description",
        addAnotherCharge: "Add another charge",
        addAnotherChargeOneTime: "Add another one-time charge",

        prorateCharges: {
          notification:
            "Your first dues date is different than your homeowner account start date. Should we prorate the charges?",
          prorateCharges: "Prorate Charges",
          prorateDate: "When are these charges due?",
          addAdditionalCharge: "Add additional charge",
          recalculateCharges: "Recalculate Charges"
        },
        addAnotherRentSchedule: "Add another dues schedule",
        otherCharges: "Prorations",
        otherChargesDescription:
          "A proration is a one-time homeowner account charge for the partial month’s dues prior to the recurring dues charge taking over at the start of the next full month.",
        recurringCharges: "New Recurring Charges",
        recurringCredit: "Recurring Credit",
        recurringPayment: "Recurring Payment",
        addAnotherRecurringCharge: "Add another recurring charge",
        addAnotherRecurringCredit: "Add another recurring credit",
        addAnotherOneTimeCharge: "Add another one-time charge",
        addAnotherOneTimeCredit: "Add another one-time credit",
        addRecurringCharge: "Add recurring charge",
        addRecurringCredit: "Add recurring credit",
        addOneTimeCharge: "Add one-time charge",
        addOneTimeCredit: "Add one-time credit",
        oneTimeCharges: "One-time charges",
        oneTimeChargesDescription: "When is this charge due?",
        oneTimeCreditsDescription: "When is this credit due?",
        credits: "Credits",
        credit: "Credit",
        creditsDescription:
          "Use this section to add one-time or recurring concessions or credits to this homeowner account. This is for things like monthly dues discounts, a free month of dues signing incentive, or to record that you’ve already collected a homeowner account deposit.",
        dueDate: "Due Date",
        dateIssued: "Date Issued",
        securityDeposit: "Security Deposit",
        securityDepositDescription:
          "This toggle is for homeowner account deposit charges. You need to add a homeowner account deposit charge even if you’ve already collected payment for the deposit.",
        untilEndOfLease: "End of Homeowner account",
        repeatUntilEndOfLease: "Repeat until the end of the homeowner account",
        frequency: "Frequency",
        lateFees: "Late Fees",
        defaultPolicy: "Default Policy",
        lateFeesDescription:
          "Late fees need to be turned on for every homeowner account, even if you are using the same late fee schedule as you do for the rest of your associations.",
        usePropertyLateFeesPolicy: "Use the association late fee policy",
        specifyLateFeesPolicy: "Use a custom late fee policy for this homeowner account",
        startChargingLateFee: "Start charging a late fee",
        days: "Days",
        daysAfterRentDue: "days after the dues is due.",
        chargeAmountOf: "Charge an amount of",
        continueCharging: "and continue charging it",
        untilRentPaid: "until the dues is paid off.",
        feeIncomeAccount: "Charge the fee to this income account",
        recurringRent: "Recurring Dues",
        otherRecurringTransactions: "Other Recurring Transactions",
        nextCharge: "Next charge"
      },

      leaseTenants: {
        residentPortalAccess: "Resident Center",
        leaseTenantsTitle: "Account Owners",
        selectTenants: "Who are the owners?",
        addTenant: "Add Owner",
        createNewTenant: "Create new owner",
        selectExistingTenant: "Select an existing owner",
        addNewTenantToLease: "Add owner to homeowner account",
        residentPortalAccessDescription:
          "If turned on, owners will receive an invitation by email to access their online center.",
        learnMoreResidentPortal: "[Click here] to learn more about the resident center."
      },
      leaseTermsTitle: "Homeowner account Terms",
      helpPanel: {
        newLease: "How to add a new  homeowner account",
        subtitle: "Tell us your  homeowner account terms, owners, and charges.",
        addPreviousLeases: "Need to add  homeowner accounts you had before starting DoorLoop?",
        dialogTitle: "Create a  Homeowner account"
      },
      learnMoreNewLease: "Learn more about adding a new homeowner account",
      unitEditConfirmations: {
        confirmAddRemove:
          'Are you sure you want to add $t(leases.newLease.unitEditConfirmations.unit, {"count": {{add}} }) and remove $t(leases.newLease.unitEditConfirmations.unit, {"count": {{remove}} }) from this homeowner account?',
        confirmAdd:
          'Are you sure you want to add $t(leases.newLease.unitEditConfirmations.unit, {"count": {{add}} }) to this homeowner account?',
        confirmRemove:
          'Are you sure you want to remove $t(leases.newLease.unitEditConfirmations.unit, {"count": {{remove}} }) from this homeowner account?',
        unit: "{{count}} unit",
        unit_plural: "{{count}} units"
      },
      multipleUnitsLeaseTotalsInfo:
        "When adding multiple units to one homeowner account, homeowner account totals will be divided equally between all units.",
      screenTitle: "Homeowner account Terms",
      whichProperty: "Which association is this homeowner account for?",
      whichUnit: "Which unit is the homeowner account for?",
      leaseTerm: "What is the purchase date of this homeowner account?",
      leaseDates: "What are the homeowner account dates?",
      leaseCharges: "Charges & Credits",
      conflictErrorMessage:
        "An active homeowner account already exists for the selected unit and dates. If the dates for the new homeowner account are correct, fix the start or end dates of the existing homeowner account for this unit.",
      multipleConflictsErrorMessage:
        "An active homeowner account currently exists for the selected units below. If the dates for the new homeowner account are correct, please adjust the dates or terms of the existing homeowner accounts for those units.",
      leaseChargesDescription: "Add your association dues, credits, and enable late fees.",
      leaseLateFees: {
        subtitle: "Do you charge late fees for unpaid dues?",
        policySettingsDescription: "You can always change your association’s late fee policy in your {{link}}",
        policyOptions: {
          propertyLateFees: "Use association late fees policy",
          customLateFees: "Specify a late fees policy for this homeowner account"
        },
        policyType: {
          property: "Association Policy Activated"
        },
        notificationAlert: {
          description:
            "Always make sure your late fee policy is the same as in your actual homeowner account agreement and that it is compliant with your state and local laws."
        }
      },
      atwill: "Purchase Date"
    },
    draftLease: {
      terms: "Account Term",
      moveInDate: "Invitation date",
      moveOutToday: "End today",
      expired: "Ended"
    },
    settings: {
      leaseSettings: "Homeowner account Settings",
      termsDescriptionTitle: "Homeowner account terms option",
      termsDescriptionSubTitle: "Set the homeowner account start date.",
      lateFeesDescriptionTitle: "Homeowner account management late fees",
      paymentsDescriptionTitle: "homeowner account payments settings",
      lateFees: "Homeowner account Late Fees",
      lateFeesDescription:
        "If you manage this homeowner account for someone else, you can set your late fee structure.",
      specifyLateFeesForLease: "Select a late fee structure for this homeowner account",
      residentPortal: "Homeowner account Resident Center",
      specifyResidentPortalForLease: "Select resident center options for this homeowner account",
      paymentsAllocation: "Homeowner account Payment Allocation",
      specifyPaymentAllocation: "Specify payment allocation options for this homeowner account",
      evictionStatus: "Eviction status",
      evictionStatusDescription: "Update the eviction status for this homeowner account.",
      leaseInEviction: "This homeowner account is in an eviction process",
      evictionDialogDescription: "You can keep track of homeowner accounts that are in eviction proceedings",
      evictionRecommend: "We also recommend the following:",
      evictionNotificationDisableOnlinePayments: "Disable future online payments for this homeowner account",
      evictionNotificationStopRecurringPayments: "Stop all active recurring payments",
      nameAndNotes: "Homeowner account Name & Notes",
      nameAndNotesDialogDescription: "Update the name of this homeowner account and add notes as needed",
      leaseName: "Homeowner account Name",
      startDate: "Purchase Date",
      rentCharges: "Dues Charges"
    },
    leaseTransaction: {
      deposit: {
        leaseOutstandingBalance: "Homeowner account Outstanding Balance"
      },
      refund: {
        leaseRefund: "Homeowner account Refund",
        payToTenant: "Paid To Owner"
      },
      payment: {
        sendPaymentReceipts: "Email Payment Receipt to the Owner"
      }
    },
    leaseCharge: {
      noPropertySelected: "Please select an association with active homeowner accounts to continue.",
      selectLease: "Select Homeowner account",
      selectProperty: "Select Association",
      pressToSelectLease: "Click here to select a homeowner account",
      oneTimePaymentHelpPanel: {
        description: "Create a payment to show a owner has paid"
      },

      refundHelpPanel: {
        description: "Refund owners for overpayments"
      },
      oneTimeChargeHelpPanel: {
        description: "Create charges for your owners to pay"
      }
    },
    leaseCredit: {
      processingCreditForLeaseX: "Processing credit for {{leaseName}}"
    },
    bulkActionErrors: {
      noLeasesFoundForThisProperty: "No homeowner accounts found for this association",
      noLeaseSelected: "Please select at least one homeowner account to allocate"
    },
    listHeader: {
      lease: "Account name",
      leaseNotes: "Homeowner account Notes",
      rent: "Association Dues"
    },
    leaseUnits: {
      property: "Association",
      leaseUnits: "Homeowner account Units",
      leaseSubTitle: "Select the units you would like to add or remove from {{address}} homeowner account"
    },
    leaseDetails: {
      rent: "Dues",
      monthlyRent: "Monthly Dues",
      changeUnitsForLeaseConfirmation:
        "Note, After removing or adding a unit from a homeowner account, you need to update the deposit, resident center permissions, dues price, and homeowner account agreement for each unit."
    },
    existingLease: {
      leaseTenants: {
        notification: {
          title: "When will my owners be invited to the Resident Center?",
          description:
            "Owners will receive a association dues reminder to login to their resident center and pay their association dues automatically before their first association dues are charged in DoorLoop. You can always customize it in your company settings."
        }
      },
      leaseRent: {
        firstRentDate: "When should DoorLoop start charging dues?",
        notification:
          "It looks like you are adding a homeowner account that has already started. We recommend you start charging dues in DoorLoop on the 1st of the following month (if needed: after your DoorLoop start date)."
      },
      leaseDeposits: {
        securityDepositSubTitle: "Are you currently holding a security deposit for this homeowner account?",
        securityDepositAmount: "What is the deposit amount?",
        notification:
          "It looks like you are adding a homeowner account that has already started. DoorLoop will do some accounting magic on the back end to mark these deposits as already collected."
      },
      leaseStatus: {
        originalLeaseCopy: "Original Homeowner account Copy",
        description: {
          firstLine: "Drag & drop your homeowner account copy below.",
          secondLine:
            "If you don’t have your homeowner account copy on you right now, you can always add it later under the files tab."
        },
        smallDirectionTip: "Please upload only DOC or PDF files"
      }
    }
  },
  tenants: {
    screen: {
      loginToPortal: "Login to Center",
      tenant: "Owner",
      tenantPortal: "Resident Center",
      emptydataViewInstructionsText: "No Owners Found",
      emptydataAddNew: "Add your first Owner",
      addNewTenant: "New Owner",
      editTenant: "Edit Owner",
      moveInTenant: "Add Secondary Owner",
      moveOutTenant: "Replace Ownership",
      deleteConfirmationText: "Are you sure you want to delete this owner?",
      deleteLoadingText: "Deleting owner",
      deleteSuccessText: "Owner was deleted successfully",
      explainer:
        "In DoorLoop, owners are added by creating a homeowner account or moving someone into an existing homeowner account.",
      helpPanel: {
        overview: "Owners Overview",
        addToNewLease: "How do I add an owner to a new homeowner account?",
        addToExistingLease: "How do I add owners to an existing homeowner account?",
        findTenants: "How do I edit owner information?",
        description: "Add owners by creating a homeowner account or moving someone into an existing homeowner account.",
        dialogTitle: "Owner Overview"
      }
    },
    newTenant: {
      loadingText: "Creating owner ...",
      successText: "Owner created successfully",
      createNewTenant: "Create New Owner",
      deleteConfirmationText: "Are you sure you want to delete the owner?",
      deleteLoadingText: "Deleting owner",
      deleteProspectSuccessText: "The owner was deleted successfully",
      tenantLoginEmailPhoneDescription:
        "These details are required for dues reminders, owner communications and resident center access."
    },
    status: {
      current: "Current Owner",
      past: "Past Owner",
      future: "Future Owner"
    },
    moveTenants: {
      whoIsMovingIn: "Which secondary owner would you like to add?",
      whoIsMovingOut: "Which owner is moving out?",
      selectTenant: "Select Owner",
      inviteToPortal: "Invite to the resident center",
      moveInCreateErrorText: "Failed to add owner",
      moveOutCreateErrorText: "Failed to remove owner",
      moveInCreateSuccessText: "Add owner successful",
      moveOutCreateSuccessText: "remove owner successful",
      moveInDate: "start date",
      noticeDate: "Notice date",
      helpPanel: {
        in: {
          description: "Add another owner to this homeowner account.",
          move: "How do I add an owner?",
          dialogTitle: "Add another owner to this homeowner account."
        },
        out: {
          description: "Remove an owner without ending a homeowner account for everyone else.",
          move: "How do I replace an owner?",
          dialogTitle: "Move-Out an owner"
        }
      }
    }
  },
  lateFee: {
    onlyChargeLateFeesOnMostRecentCharge: {
      description:
        "A late fee is created for each outstanding charge. If dues hasn't been paid in several months multiple late fees will be created unless this is turned on."
    },
    daysAfterRentDue: "Days after dues are due",
    descriptionLateFees: "Automate the late fees that should be incurred on your homeowner accounts",
    notifyTenantsOnCharge: {
      title: "Notify owners of late fees once charged",
      description: "Let your owners know each time a late fee is charged via email or push notification"
    },
    learnMoreLateFees: "Set Up Automatic Late Fees"
  },
  settings: {
    generalSettings: {
      convenienceFees: {
        settingsDialogSubTitle:
          "A convenience fee is an extra amount that is added to any transaction made by your owners when using ACH as a payment method.",
        specifyConvenienceFeesForProperty: "Specify convenience fee for this association",
        specifyConvenienceFeesForLease: "Specify a late fee structure for this homeowner",
        inputGroupLabel: "Add a convenience fee to dues payments"
      },
      doorLoopURL: {
        tenantPortalURLDescription: "You can modify your Resident Center URL to match your company brand.",
        clientPortalURL: "Resident Center URL"
      },
      companyStartDate: {
        companyStartDateSubTitle:
          "Your company start date is when DoorLoop should start charging your association dues",
        subtitle: "This is the date to start charging your existing owners and:",
        tenantInvite: "Invite your existing owners to the resident center",
        rentReminder: "Send association dues reminders to existing owners"
      },
      managementFees: {
        propertiesWithManagementFees: "Associations with Billable Management Fees",
        managementFeesDescription: "Calculate and generate bills automatically for your associations",
        managementFeesDialogDescription:
          "If you charge management fees to manage your associations, DoorLoop can calculate the fees automatically and create bills on your behalf.",
        collectManagementFeesForProperty: "Enable management fee for this association",
        specifyManagementFeesForProperty: "Enable management fee for this association"
      },
      leadSourcesSubTitle: "Keep track of where your leads, and owners came from.",
      propertyGroupsSubTitle: "Bundle multiple associations together when running reports or other features.",
      propertySettings: "Association Settings",
      defaultAccounts: {
        rentalIncome: "Association Dues"
      },
      propertyGroups: {
        dialogDescription: "Link your associations with portfolios, run reports, and keep track of your associations.",
        propertiesNames: "Association Names"
      }
    }
  },
  tasks: {
    screen: {
      newTenantRequest: "New Owner Request",
      requestedByTenant: "Requested By Owner"
    },
    tasksList: {
      tenantRequest: "Owner Request"
    },
    tenantRequestDialog: {
      newTenantRequest: "New Owner Request",
      selectTenantValidationText: "Click here to select owner",
      editTenantRequest: "Edit Owner Request",
      entryNotes: "Please provide instructions on how to access your association"
    },
    taskDetails: {
      notifyTenant: "Notify Owner"
    }
  },
  vendors: {
    newVendor: {
      properties: "Associations",
      selectProperties: "Select Associations",
      singlePropertyDescription: "This vendor can work on all my associations",
      specificPropertyDescription: "This vendor can work only on specific associations",
      selectPropertiesDescription: "Which associations does this vendor work at?"
    },
    expenseDescription: "Record costs incurred by your associations",
    vendorDetails: {
      addTenant: "Add Owner"
    },
    screen: {
      helpPanel: {
        vendorManage: "Edit Vendor Information"
      }
    }
  },
  bills: {
    payBills: {
      screen: {
        allProperties: "All Associations",
        someProperties: "Some Associations"
      }
    }
  },
  notifications: {
    settings: {
      rentReminders: "Dues Reminders",
      rentCharged: "Dues Changed",
      rentOverdueReminders: "Overdue Dues Reminders"
    }
  },
  announcements: {
    sendByVoiceMessageDesc:
      "This announcement will be sent immediately as a voice message using text to speech to all owners with a phone number on file and will use 1 communication credit per voice message sent.\n\nLimit 300 characters.",
    propertiesUnits: "Associations / Units",
    whichProperties: "Which associations would you like to send this announcement to?",
    allProperties: "All Associations",
    xProperties: "{{x}} Associations",
    oneProperty: "1 Association",
    whichTenants: "Which owner would you like to send this announcement to?",
    selectTenants: "Select Owners",
    allTenants: "All Owners",
    someTenants: "Some Owners",
    sendByTenantPortal: "Show this announcement in the resident center",
    sendByEmailDesc:
      "This announcement will be sent immediately to all owner with an email address on file. We will track delivery and open rates for you.",
    sendByTextMessageDesc:
      "This announcement will be sent immediately to all owner with a mobile phone number on file and will use 1 communication credit per text message sent.\n\nLimit 140 characters.",
    sendByTenantPortalDesc: "This announcement will be posted in the announcement section of the resident center",
    pinToPortalHomeQuestion: "Also post this announcement on the home screen of the resident center",
    portalShouldExpireQuestion: "Remove this announcement from resident center feed on this date",
    tenantPortal: "Resident Center",
    xMessagesWillBePostedOnTenantPortals: "{{x}} messages will be posted on resident centers",
    oneTenant: "1 Owner",
    xTenants: "{{x}} Owners",
    helpPanel: {
      description: "Message your owner by text, email, voice message, or resident center announcements.",
      singleTenantMessage: "How do I message a single owner?"
    },
    communicationsScreen: {
      helpPanel: {
        trackPortalInvite: "How do I track resident center invites?",
        description: "Keep track of every message sent."
      }
    }
  },
  toasts: {
    custom: {
      tasks: {
        [TaskType.TENANT_REQUEST]: { POST: "New owner request has been created" }
      },
      properties: {
        activate: { PUT: "Association has been activated" },
        deactivate: { PUT: "Association has been deactivated" }
      },
      lease: {
        activate: { POST: "homeowner account has been activated" },
        cancel: { POST: "homeowner account has been canceled" }
      }
    },
    api: {
      [ServerRoutes.SETTINGS_TENANT_REQUESTS]: { PUT: "Owner Requests settings has been updated" },
      [ServerRoutes.SETTINGS_TENANT_PORTAL]: { PUT: "Resident Center settings has been updated" },
      [ServerRoutes.TENANTS]: {
        POST: "New owner has been added",
        PUT: "Owner information has been saved",
        DELETE: "Owner has been deleted"
      },
      [ServerRoutes.PROPERTIES]: {
        POST: "New association has been added",
        PUT: "Association information has been saved",
        DELETE: "Association has been Deleted"
      },
      [ServerRoutes.PROPERTIES_POST_PROPERTY_WITH_UNITS]: { POST: "New association has been added" },
      [ServerRoutes.LEASES_POST_NOT_RENEWING]: { POST: "homeowner account set to not renewing" },
      [ServerRoutes.LEASES_POST_END_LEASE]: { POST: "homeowner account has been ended" },
      [ServerRoutes.LEASE_DRAFTS]: {
        POST: "New homeowner account has been created",
        PUT: "Homeowner account term has been saved",
        DELETE: "Homeowner account has been deleted"
      },
      [ServerRoutes.LEASES]: {
        POST: "New Homeowner account has been created",
        PUT: "Homeowner account term has been saved",
        DELETE: "Homeowner account has been deleted"
      },
      [ServerRoutes.LEASES_POST_MOVEIN_TENANT]: { POST: "Owner has been successfully moved-in" },
      [ServerRoutes.LEASES_POST_MOVEOUT_TENANT]: { POST: "Owner has been successfully moved-out" }
    }
  },
  dashboards: {
    onlinePaymentsSubtext: "Automate dues collection & get paid faster",
    pastDueLeases: "Past due accounts",
    setupOnlinePayments: "Accept online dues payments",
    customizeTenantPortal: "Customize your resident center",
    leasesWithOutstandingBalances: "Accounts with outstanding balances",
    recentTenantRequests: "Recent owner requests",
    tenantPortalAdoption: "Resident center adoption",
    rentalStats: "Association stats",
    properties: "Associations",
    tenantRequests: "Owner Requests"
  },
  reports: {
    reportsScreen: {
      leaseLateFeesReport: "Homeowner Late Fees Report",
      recurringRentTransactions: "Recurring Dues Transactions",
      leaseAccountStatementsReport: "Homeowner Account Statement Report",
      tenantsReport: "Association Owner Reports",
      bankbalanceByProperty: "Bank Balance By Association",
      BankbalanceByPropertyFileName: "bank-balance-by-association",
      bankbalanceByPropertyReport: "Bank Balance By Association",
      tasksByProperty: "Tasks By Association",
      balanceSheetByProperty: "Balance Sheet By Association",
      profitAndLossByProperty: "Profit And Loss By Association",
      propertyReserveFunds: "Association Reserve Funds",
      propertyBankAccounts: "Association Bank Accounts",
      currentTenants: "Current Association Owner",
      futureTenants: "Future Association Owner",
      previousTenants: "Previous Association Owner",
      tenantPets: "Association Owner Pets Report",
      tenantVehicles: "Association Owner Vehicles Report"
    },
    transactionsReport: {
      transactionReportGroupBy: {
        property: "Association",
        lease: "Homeowner Account",
        tenant: "Owner"
      }
    }
  },
  onBoarding: {
    XoutofYProperties: "{{progress}} out of {{total}} associations",
    XoutofYLeases: "{{progress}} out of {{total}}  homeowner accounts",
    footer: {
      Links: {
        RentalApplications: {
          title: "Rental Applications and Owner Screening"
        },
        Announcements: {
          description:
            "Communicate directly with your owners via email, text, voice message, and announcements through the resident center."
        },
        MarketingAndListings: {
          description:
            "List your units for rent online and use DoorLoop’s CRM features to manage prospects and find better owners faster."
        }
      }
    },
    stepTitles: {
      customizeYourTenantPortal: "Customize Your Resident Center",
      sendAnnouncement: "Send an Announcement to Your Owners",
      addYourProperties: "Add Your Associations",
      addYourLeases: "Add Your Homeowner Accounts",
      acceptRentPayments: "Accept Online Dues Payments"
    },
    stepDescriptions: {
      subscribeToDoorLoop: `Manage, grow, and automate your association portfolio.

    Collect association dues and manage maintenance requests, all in one place.
    `,

      acceptRentPayments: `Say goodbye to chasing owners for online dues payments. Now you can get paid faster and automate online dues collection on the same day each month.

Owners can pay by credit card or ACH at no cost to you and you can disable it for certain owners or associations.
`,
      customizeYourTenantPortal: `The Resident Center is where your owners can make online payments, submit maintenance requests, view their previous payments, and more.

Customize the design and features of the resident center now.`,
      addYourLeases: `Add all your homeowner accounts to your association.

See which homeowner accounts are expiring soon, send renewal notices, and even schedule automated dues increases.`,
      sendAnnouncement: `Once everything is ready to go, it’s time to WOW your owners and show them why you’re the most professional and modern association manager ever!

Tell your Owners they can now use DoorLoop to make dues payments, submit maintenance requests, and much more.`,
      addYourProperties:
        "Creating your associations is the first step towards managing them better than ever. Soon you’ll be automating dues collection, seamlessly filling vacancies, and managing your owners."
    },
    actionButtons: {
      customizeYourTenantPortal: "Customize the Resident Center",
      addYourProperties: "Add an Association",
      addYourLeases: "Add a Homeowner account",
      acceptRentPayments: "Accept Online Dues Payments"
    }
  },
  otherTransactions: {
    bankTransfer: {
      receivingProperty: "Receiving Association",
      noPropertySelectionText: "Select Association",
      sourceProperty: "Source Association",
      zeroAmountTransferWarning:
        'Please note that you have selected different associations but the same bank account. This will result in a "zero amount transaction". We will use your default clearing account for this transfer.',
      differentOwnershipTransferWarning:
        "Please be aware that you are about to transfer funds between associations that either have different owners or different ownership percentages. "
    }
  },
  users: {
    newUser: {
      accessSomeProperties: "User will have access only to some associations",
      propertiesSelectionDescription: "Associations the user will gain access to:",
      accessAllProperties: "User has access to all of your associations"
    }
  },
  prospects: {
    newProspect: {
      addAnotherProperty: "Add Another Association"
    },
    screen: {
      prospect: "Unassociated Owner"
    }
  },
  userRoles: {
    tenantTransactions: "Owner Transactions",
    tenants: "Owners",
    tenantRequests: "Owner Requests",
    properties: "Associations",
    leases: "Homeowner Accounts",
    leaseCharges: "Homeowner Account Charges & Dues",
    leaseCredits: "Homeowner Account Credits",
    leasePayments: "Homeowner Account Payments & Reversed Payments",
    leaseRefunds: "Homeowner Account Refund",
    rentals: "Associations",
    leasing: "Homeowner Accounts",
    tenantReports: "Owner Reports",
    prospectReports: "Homeowner Reports",
    leasingReports: "Association Reports",
    tenantRequestReports: "Owner Request Reports",
    tenantPortal: "Resident Center",
    rentalApplications: "Associations Applications",
    propertySettings: "Associations Settings",
    leaseTransactions: "Homeowner Account Transactions",
    leaseDeposits: "Homeowner Account Deposits"
  },
  esignature: {
    selectDraftLease: "Select Homeowner account",
    selectLease: "Select Homeowner account",
    selectProperty: "Select Association",
    signatureRequestLeaseRenewalAgreementTitle: "Homeowner account Agreement",
    helpPanel: {
      requestDescription: "Get all of your documents signed electronically."
    }
  },
  accounts: {
    chartOfAccounts: {
      leaseOptions: "Homeowner Account Options",
      leaseChargeItem: "Use this account for homeowner account Charges",
      leaseChargeItemDescription:
        "This will allow you to select this account when creating a new homeowner account charge, credit, or refund.",
      leaseStatementMemo: "Set a default homeowner account statement memo",
      leaseDepositsDescription:
        "This will allow you to select this account when creating a new homeowner account deposit charge.",
      balanceByPropertyTitle: "Great! Now we need to know the balance by association",
      balanceByPropertyDescription:
        "Please add the balance per association below. The total per association must be equal to the total Account Balance. This should include the total amount for each association, including deposits and reserves."
    }
  },
  bulkPayments: {
    helpPanel: {
      searchAndFill: {
        description: "Receive bulk payments by adding owners, associations, and homeowner accounts"
      }
    }
  },
  navigation: {
    helper: {
      newLeaseWizardBanner: {
        description:
          "We can do the heavy lifting for you and import your associations, units and homeowner accounts free of charge. Schedule a call with a migration consultant."
      }
    }
  },
  communicationsCenter: {
    searchForRecipients: "Search for Owners and Vendors"
  },
  bulkActions: {
    sendTenantWelcomeSmsConfirmMessageOne: "Are you sure you want to send a welcome message to one owner?",
    sendTenantWelcomeSmsConfirmMessageMultiple: "Are you sure you want to send a welcome message to {{x}} owners?"
  }
};
