import type { ChangeEventHandler } from "react";
import React, { useCallback, useEffect, useState } from "react";

import type { FilterPanelSearchInputProps } from "DLUI/screen/searchPanel/filterPanelSearchInput_old";
import { FilterPanelSearchInput } from "DLUI/screen/searchPanel/filterPanelSearchInput_old";
import { useDebounce } from "hooks/useDebounce";

export interface UndepositsSearchProps
  extends Omit<FilterPanelSearchInputProps, "didChangeSearchQuery" | "searchQuery"> {
  defaultValue?: string;
  onChange: (newValue: string) => void;
}

const UndepositsSearchInput = ({ defaultValue, onChange, ...props }: UndepositsSearchProps) => {
  const [isInit, setIsInit] = useState(true);
  const [searchQuery, setSearchQuery] = React.useState<string>(defaultValue || "");

  const debouncedSearchQuery = useDebounce(searchQuery, 400);

  const handleChangeSearchQuery = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setSearchQuery(event.target.value);
  }, []);

  useEffect(
    () => {
      if (!isInit) {
        onChange(debouncedSearchQuery);
      } else {
        setIsInit(false);
      }
    },
    [debouncedSearchQuery] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return <FilterPanelSearchInput searchQuery={searchQuery} didChangeSearchQuery={handleChangeSearchQuery} {...props} />;
};

export { UndepositsSearchInput };
