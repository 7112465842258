import { DataCy, ReportId, ReportPermission } from "@doorloop/dto";

import AppStrings from "locale/keys";
import { ownerPortalNavigation } from "../../../../../utils/ownerPortalNavigation";
import type { ReportScreenItemType } from "DLUI/report/screen/reportScreenItemType";

const didPressAccountsPayableAgingDetail = (): void => {
  ownerPortalNavigation.runAccountPayableAgingDetailReport();
};

const didPressAccountsPayableAgingDetailByAccount = (): void => {
  ownerPortalNavigation.runAccountPayableAgingDetailByAccountReport();
};

export const WHAT_YOU_OWE_REPORT_ITEMS: ReportScreenItemType[] = [
  {
    description: AppStrings.Reports.ReportsScreen.AccountsPayableAgingDetail,
    onPress: didPressAccountsPayableAgingDetail,
    permission: ReportPermission.accountsPayable,
    id: ReportId.ACCOUNT_PAYABLE_AGING_DETAILS,
    dataCy: DataCy.reports.screenItems.whatYouOwe.apAgingDetail
  },
  {
    description: AppStrings.Reports.ReportsScreen.AccountsPayableAgingDetailByAccount,
    onPress: didPressAccountsPayableAgingDetailByAccount,
    permission: ReportPermission.accountsPayable,
    id: ReportId.ACCOUNT_PAYABLE_AGING_DETAILS_BY_ACCOUNT,
    dataCy: DataCy.reports.screenItems.whatYouOwe.apAgingDetailByAccount
  }
];
