import React from "react";

import _ from "lodash";
import type { FieldProps } from "formik";
import { getIn } from "formik";

import type { PhoneNumberInputProps } from "DLUI/form/phoneNumberInput/phoeNumberInput";
import { PhoneNumberInput } from "DLUI/form/phoneNumberInput/phoeNumberInput";

export interface PhoneNumberInputFieldProps extends FieldProps, PhoneNumberInputProps {}

export const PhoneNumberInputField: React.FC<PhoneNumberInputFieldProps> = ({ field, form, ...props }) => {
  const fieldName = field.name;
  const fieldValue = field.value;

  const error = getIn(form.errors, field.name);
  const isTouched = getIn(form.touched, field.name);
  const hasError = isTouched && !_.isEmpty(error);

  return (
    <PhoneNumberInput
      {...props}
      {...field}
      value={fieldValue}
      onChange={(value) => {
        form.setFieldValue(fieldName, value);
        form.setFieldTouched(fieldName, true, false);
      }}
      error={hasError}
      helperText={hasError && error}
    />
  );
};
