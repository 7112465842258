import { Icon } from "DLUI/icon";
import { Link } from "DLUI/link";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import React from "react";
import type { SVGIconComponent } from "assets/icons/types";

export interface ContactInfoItemProps {
  icon: SVGIconComponent;
  text?: string;
  type?: "tel" | "mail" | "blank";
  marginTop?: number;
  marginRight?: number;
}

export const ContactInfoItem: React.FC<ContactInfoItemProps> = ({
  icon,
  text,
  type,
  marginTop,
  marginRight
}: ContactInfoItemProps) => {
  if (type) {
    return (
      <View
        width={"auto"}
        id={"textContainer"}
        flexDirection={"row"}
        alignItems={"center"}
        marginTop={marginTop}
        marginRight={marginRight}
      >
        <Icon marginRight={5} width={16} height={16} Source={icon} />

        <Link hoverColor={"black"} type={type} hrefUrl={text}>
          <Text marginRight={20} overFlow={"ellipsis"} color={"black"} fontSize={14}>
            {text}
          </Text>
        </Link>
      </View>
    );
  }

  return (
    <View marginRight={marginRight} width={"auto"} flexDirection={"row"}>
      <Icon marginRight={5} width={16} height={16} Source={icon} />
      <Text marginLeft={10} fontSize={14}>
        {text}
      </Text>
    </View>
  );
};
