const Announcements = {
  announcement: "announcements.announcement",
  announcements: "announcements.announcements",
  newAnnouncement: "announcements.newAnnouncement",
  propertiesUnits: "announcements.propertiesUnits",
  deliveryMethod: "announcements.deliveryMethod",
  deliveryMethods: "announcements.deliveryMethods",
  announcementContent: "announcements.announcementContent",
  reviewAndConfirm: "announcements.reviewAndConfirm",
  whichProperties: "announcements.whichProperties",
  whichUnits: "announcements.whichUnits",
  whichTenants: "announcements.whichTenants",
  selectUnits: "announcements.selectUnits",
  selectTenants: "announcements.selectTenants",
  selectPortfolios: "announcements.selectPortfolios",
  allUnits: "announcements.allUnits",
  someUnits: "announcements.someUnits",
  allTenants: "announcements.allTenants",
  someTenants: "announcements.someTenants",
  sendByEmail: "announcements.sendByEmail",
  sendByTextMessage: "announcements.sendByTextMessage",
  sendByVoiceMessage: "announcements.sendByVoiceMessage",
  sendByTenantPortal: "announcements.sendByTenantPortal",
  sendByEmailDesc: "announcements.sendByEmailDesc",
  sendByTextMessageDesc: "announcements.sendByTextMessageDesc",
  sendByVoiceMessageDesc: "announcements.sendByVoiceMessageDesc",
  sendByTenantPortalDesc: "announcements.sendByTenantPortalDesc",
  howWouldYouLikeToDeliver: "announcements.howWouldYouLikeToDeliver",
  pinToPortalHomeQuestion: "announcements.pinToPortalHomeQuestion",
  portalShouldExpireQuestion: "announcements.portalShouldExpireQuestion",
  xCharacters: "announcements.xCharacters",
  characterCapMessage: "announcements.characterCapMessage",
  reviewAnnouncement: "announcements.reviewAnnouncement",
  thisAnnouncementWillBeDeliveredVia: "announcements.thisAnnouncementWillBeDeliveredVia",
  communicationCredit: "announcements.communicationCredit",
  communicationCenterCredit: "announcements.communicationCenterCredit",
  xCredits: "announcements.xCredits",
  email: "announcements.email",
  textMessage: "announcements.textMessage",
  phoneCall: "announcements.phoneCall",
  tenantPortal: "announcements.tenantPortal",
  xEmailsWillBeSent: "announcements.xEmailsWillBeSent",
  xTextMessagesWillBeSent: "announcements.xTextMessagesWillBeSent",
  xPhoneCallsWillBeMade: "announcements.xPhoneCallsWillBeMade",
  xMessagesWillBePostedOnTenantPortals: "announcements.xMessagesWillBePostedOnTenantPortals",
  creditsUsedOutOfCreditsLeftMessage: "announcements.creditsUsedOutOfCreditsLeftMessage",
  creditsAdditionalChargeMessage: "announcements.creditsAdditionalChargeMessage",
  noDeliveryMethodSelectedError: "announcements.noDeliveryMethodSelectedError",
  nothingToSendError: "announcements.nothingToSendError",
  bodyTooLongError: "announcements.bodyTooLongError",
  communicationFromToSummary: "announcements.communicationFromToSummary",
  CommunicationsLog: "announcements.communicationsLog",
  openedXTimesSinceDateTime: "announcements.openedXTimesSinceDateTime",
  openedOneTimeSinceDateTime: "announcements.openedOneTimeSinceDateTime",
  clickedXTimesSinceDateTime: "announcements.clickedXTimesSinceDateTime",
  clickedOneTimeSinceDateTime: "announcements.clickedOneTimeSinceDateTime",
  fromColon: "announcements.fromColon",
  toColon: "announcements.toColon",
  viaEmail: "announcements.viaEmail",
  viaTextMessage: "announcements.viaTextMessage",
  viaPhoneCall: "announcements.viaPhoneCall",
  subject: "announcements.subject",
  sentAt: "announcements.sentAt",
  sentBy: "announcements.sentBy",
  status: "announcements.status",
  draft: "announcements.draft",
  failed: "announcements.failed",
  bounced: "announcements.bounced",
  complaint: "announcements.complaint",
  sent: "announcements.sent",
  Delivered: "announcements.delivered",
  Opened: "announcements.opened",
  Clicked: "announcements.clicked",
  type: "announcements.type",
  deleteCommunicationConfirmationText: "announcements.deleteCommunicationConfirmationText",
  deleteCommunicationLoadingText: "announcements.deleteCommunicationLoadingText",
  deleteCommunicationSuccessText: "announcements.deleteCommunicationSuccessText",
  deleteAnnouncementConfirmationText: "announcements.deleteAnnouncementConfirmationText",
  deleteAnnouncementLoadingText: "announcements.deleteAnnouncementLoadingText",
  deleteAnnouncementSuccessText: "announcements.deleteAnnouncementSuccessText",
  searchCommunications: "announcements.searchCommunications",
  searchAnnouncements: "announcements.searchAnnouncements",
  noAnnouncementsFound: "announcements.noAnnouncementsFound",
  noCommunicationsFound: "announcements.noCommunicationsFound",
  communications: "announcements.communications",
  allProperties: "announcements.allProperties",
  oneProperty: "announcements.oneProperty",
  oneUnit: "announcements.oneUnit",
  oneTenant: "announcements.oneTenant",
  xProperties: "announcements.xProperties",
  xUnits: "announcements.xUnits",
  xTenants: "announcements.xTenants",
  NA: "announcements.NA",
  active: "announcements.active",
  inactive: "announcements.inactive",
  expires: "announcements.expires",
  expired: "announcements.expired",
  noExpiry: "announcements.noExpiry",
  overview: "announcements.overview",
  emailsSent: "announcements.emailsSent",
  textMessagesSent: "announcements.textMessagesSent",
  voiceMessagesSent: "announcements.voiceMessagesSent",
  portalStatus: "announcements.portalStatus",
  announcementPreview: "announcements.announcementPreview",
  attachedFiles: "announcements.attachedFiles",
  total: "announcements.total",
  updateAnnouncement: "announcements.updateAnnouncement",
  pleaseNote: "announcements.pleaseNote",
  announcementSentNotification1: "announcements.announcementSentNotification1",
  announcementSentNotification2: "announcements.announcementSentNotification2",
  announcementOptions: "announcements.announcementOptions",
  yourAnnouncementHasBeenSent: "announcements.yourAnnouncementHasBeenSent",
  failedWithReason: "announcements.failedWithReason",
  readMore: "announcements.readMore",
  noAnnouncements: "announcements.noAnnouncements",
  nothingHereYetCheckBackSoon: "announcements.nothingHereYetCheckBackSoon",
  SendMessage: "announcements.sendMessage",
  HelpPanel: {
    Description: "announcements.helpPanel.description",
    SendAnnouncement: "announcements.helpPanel.sendAnnouncement",
    SingleTenantMessage: "announcements.helpPanel.singleTenantMessage"
  },
  CommunicationsScreen: {
    HelpPanel: {
      Description: "announcements.communicationsScreen.helpPanel.description",
      LearnMoreCommunicationsLog: "announcements.communicationsScreen.helpPanel.learnMoreCommunicationsLog",
      TrackPortalInvite: "announcements.communicationsScreen.helpPanel.trackPortalInvite"
    }
  },
  NotificationsLog: "announcements.notificationsLog"
};
export default Announcements;
