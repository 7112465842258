"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsuranceDialogOrigin = void 0;
var InsuranceDialogOrigin;
(function (InsuranceDialogOrigin) {
    InsuranceDialogOrigin["HOME_SCREEN"] = "Home screen";
    InsuranceDialogOrigin["HOME_SCREEN_NOTIFICATION"] = " Home screen notification";
    InsuranceDialogOrigin["INTERCOM"] = "Intercom";
    InsuranceDialogOrigin["INSURANCE_TAB"] = "Insurance tab";
    InsuranceDialogOrigin["INSURANCE_LIST_EDIT"] = "Insurance list edit";
    InsuranceDialogOrigin["INSURANCE_LIST_WARNING"] = "Insurance list edit";
    InsuranceDialogOrigin["PAYMENT_WIZARD"] = "Payment wizard";
})(InsuranceDialogOrigin || (exports.InsuranceDialogOrigin = InsuranceDialogOrigin = {}));
