import React from "react";
import WarningView from "DLUI/form/warningView/warningView";
import Text from "DLUI/text/text";
import AppStrings from "locale/keys";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import { useTranslation } from "react-i18next";

interface ComponentProps {
  delta: number;
  type: "charge" | "credit";
}

export const DeltaConflictWarning = ({ delta, type }: ComponentProps) => {
  const { formatNumber } = useIntl();
  const { t } = useTranslation();
  const localeData = useSelector((state: IRootState) => state.auth.localeData);
  const { currency } = localeData;

  return (
    <WarningView
      renderContent={() => (
        <Text
          value={AppStrings.Leases.LeaseCharge.ChargeTotalDeltaConflict}
          replaceObject={{
            op: delta > 0 ? t(AppStrings.Leases.LeaseCharge.Greater) : t(AppStrings.Leases.LeaseCharge.Less),
            delta: formatNumber(Math.abs(delta), {
              style: "currency",
              currency
            }),
            type
          }}
        />
      )}
    />
  );
};
