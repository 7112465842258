import React from "react";
import { View } from "DLUI/view";
import { PencilIcon } from "assets/icons";
import "./styles.css";
import { Icon } from "DLUI/icon";
import { SeparationLine } from "DLUI/separatorView";

interface ComponentProps {
  children: React.ReactNode;
  maxWidth?: number;
  withBorderBottom?: boolean;
}

const EditableContentView: React.FC<ComponentProps> = ({ children, maxWidth, withBorderBottom }: ComponentProps) => (
  <View alignItems={"flex-start"}>
    <View
      className={"editable-text-content-row"}
      flexDirection={"row"}
      autoWidth
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <View className={"editable-text-content-container"} autoWidth style={{ wordBreak: "break-all" }}>
        <View maxWidth={maxWidth} className={"editable-text-content"} contentEditable>
          {children}
        </View>
      </View>
      <View className={"editable-text-icon"} width={30}>
        <Icon marginLeft={10} Source={PencilIcon} width={15} />
      </View>
      {withBorderBottom && <SeparationLine marginTop={5} height={2} dividerColor={"gray"} width={"100%"} />}
    </View>
  </View>
);

export default EditableContentView;
