import React from "react";
import { BalanceIcon, CheckIcon, LeaseListIcon, PencilIcon } from "assets/icons";
import type { MenuItem } from "DLUI/dialogs";
import AppStrings from "locale/keys";
import { AccountInfo, CheckPrinting, OpeningBalances } from "./components";
import LeaseOptions from "./components/leaseOptions";

interface Props {
  selectedTabIndex?: number;
}

export const getAccountFormMenuItems = ({ selectedTabIndex }: Props): MenuItem[] =>
  [
    {
      title: AppStrings.Accounts.ChartOfAccounts.AccountInfo,
      icon: PencilIcon,
      contentComponent: (formikRef) => <AccountInfo formikRef={formikRef} />
    },
    {
      title: AppStrings.Accounts.ChartOfAccounts.OpeningBalances,
      icon: BalanceIcon,
      contentComponent: (formikRef) => <OpeningBalances formikRef={formikRef} />
    },
    {
      title: AppStrings.Accounts.ChartOfAccounts.LeaseOptions,
      icon: LeaseListIcon,
      contentComponent: (formikRef) => <LeaseOptions formikRef={formikRef} />
    },
    {
      title: "Check Printing",
      icon: CheckIcon,
      contentComponent: (formikRef) => <CheckPrinting formikRef={formikRef} />
    }
  ].map((tab, index) => {
    return { ...tab, isSelected: index === selectedTabIndex };
  });
