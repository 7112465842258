import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { DataCy, LeaseDraftDto } from "@doorloop/dto";
import { FormikDatePicker } from "DLUI/form";
import AppStrings from "../../../../../../locale/keys";
import React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { View } from "DLUI/view";
import Text from "DLUI/text";

const HomeownerAccountSettingsForm = () => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <View paddingTop={20} alignItems={"center"} gap={10} paddingRight={20} paddingLeft={20} paddingBottom={50}>
      <Text align={"center"} value={AppStrings.Leases.NewLease.LeaseTermsTitle} bold fontSize={20} color={"black"} />
      <Text align={"center"} value={AppStrings.Leases.NewLease.LeaseTerm} fontSize={16} color={"gray"} />
      <FastFieldSafe
        dto={LeaseDraftDto}
        component={FormikDatePicker}
        uniqueKey={"termStartDate"}
        label={AppStrings.Leases.NewLease.LeaseOverview.TermsSection.Dates.StartDate}
        name={"start"}
        noMargin
        dataCy={DataCy.newLease.leaseTerms.startDate}
        maxWidth={300}
      />
    </View>
  </MuiPickersUtilsProvider>
);

export default HomeownerAccountSettingsForm;
