import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { Icon } from "DLUI/icon";
import type { SVGIconComponent } from "assets/icons/types";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  previewStrings: string[];
  icon: SVGIconComponent;
  title: string;
}

const RegionAndCurrencyPreviewItem: React.FC<ComponentProps> = ({ icon, title, previewStrings }: ComponentProps) => {
  const { isMobile } = useResponsiveHelper();
  return (
    <View
      justifyContent={"center"}
      marginTop={20}
      height={isMobile ? 100 : 80}
      borderRadius={10}
      backgroundColor={"dark"}
      alignItems={"center"}
    >
      <View paddingLeft={20} paddingRight={20} flexDirection={"row"}>
        <Icon Source={icon} width={20} height={20} pathColor={"black"} />
        <Text value={title} marginLeft={10} color={"black"} fontWeight={700} fontSize={16} />
        <View paddingLeft={30} alignItems={"center"} flexDirection={"row"}>
          <Text marginTop={5} fontSize={16} color={"gray"}>
            {previewStrings[0] || ""}
          </Text>
          <Text marginLeft={10} marginRight={10} fontSize={16} color={"gray"}>
            |
          </Text>
          <Text marginTop={5} fontSize={16} color={"gray"}>
            {previewStrings[1] || ""}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default RegionAndCurrencyPreviewItem;
