"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationActionType = void 0;
var NotificationActionType;
(function (NotificationActionType) {
    NotificationActionType["CREATED"] = "CREATED";
    NotificationActionType["UPDATED"] = "UPDATED";
    NotificationActionType["RECEIVED"] = "RECEIVED";
    NotificationActionType["CANCELED"] = "CANCELED";
})(NotificationActionType || (exports.NotificationActionType = NotificationActionType = {}));
