import React from "react";
import { View } from "DLUI/view";
import BasicGrid from "DLUI/listItems/basicGrid";
import { ProfileImage } from "DLUI/profileImage";
import ListItemText from "DLUI/listItems/listItemText";
import { SeparationLine } from "DLUI/separatorView";
import type { MerchantAccountIdentityVerificationDto } from "@doorloop/dto";
import AppStrings from "locale/keys";

interface Props {
  identityVerificationData: MerchantAccountIdentityVerificationDto;
}

const VerifyIdentityListItem: React.FC<Props> = ({ identityVerificationData }: Props) => {
  const { owner_name, owner_dl, owner_ssn } = identityVerificationData;

  return (
    <View marginTop={20} backgroundColor={"white"} borderRadius={10} height={80} flexDirection={"row"}>
      <BasicGrid sm={6}>
        <ProfileImage marginLeft={20} />
        <View flex={1}>
          <View alignItems={"center"} flexDirection={"row"} paddingLeft={10} paddingRight={10}>
            <View width={"95%"}>
              <ListItemText bold value={AppStrings.Common.FilterOptions.Name} color={"gray"} />
              <ListItemText lineBreak={"auto"} numberOfLines={2} marginTop={5} color={"gray"}>
                {owner_name}
              </ListItemText>
            </View>
            <View flex={1} alignItems={"flex-end"}>
              <SeparationLine height={40} width={1} />
            </View>
          </View>
        </View>
      </BasicGrid>
      <BasicGrid sm={4} alignItems={"flex-start"} justify={"center"} direction={"column"}>
        <View paddingLeft={20} paddingRight={10}>
          <ListItemText bold value={AppStrings.Accounts.DriversLicense} color={"gray"} />
          <ListItemText marginTop={5} color={"gray"}>
            {owner_dl}
          </ListItemText>
        </View>
      </BasicGrid>
      <BasicGrid sm={2} alignItems={"flex-start"} justify={"center"} direction={"column"}>
        <View paddingLeft={0} paddingRight={20}>
          <ListItemText bold value={AppStrings.Common.SSNorITIN} color={"gray"} />
          <ListItemText marginTop={5} color={"gray"}>
            {owner_ssn}
          </ListItemText>
        </View>
      </BasicGrid>
    </View>
  );
};

export default VerifyIdentityListItem;
