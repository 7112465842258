import {
  ObjectPermission,
  PaymentMethod,
  PaymentMethodNoEpay,
  shouldBlockFeatureForSubscriptionPriceId
} from "@doorloop/dto";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";

export const usePaymentMethodBySubscription = () => {
  const subscriptionPriceId = useSelector(
    (state: IRootState) => state.auth.currentLoginResponse?.currentDbTenant?.subscriptionPlan?.price_id
  );

  return shouldBlockFeatureForSubscriptionPriceId(ObjectPermission.ePayPayments, subscriptionPriceId)
    ? PaymentMethodNoEpay
    : PaymentMethod;
};
