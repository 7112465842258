"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisplayPropertyWithUnitsDto = exports.PropertyWithUnitsDto = void 0;
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const units_1 = require("../units");
const property_dto_1 = require("./property.dto");
class PropertyWithUnitsDto {
    constructor(init) {
        this.property = new property_dto_1.PropertyDto();
        this.units = new Array();
        Object.assign(this, init);
    }
}
exports.PropertyWithUnitsDto = PropertyWithUnitsDto;
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsDefined)(),
    __metadata("design:type", property_dto_1.PropertyDto)
], PropertyWithUnitsDto.prototype, "property", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsDefined)(),
    (0, class_transformer_1.Type)(() => units_1.UnitDto),
    __metadata("design:type", Array)
], PropertyWithUnitsDto.prototype, "units", void 0);
class DisplayPropertyWithUnitsDto extends PropertyWithUnitsDto {
    constructor(init) {
        super();
        this.property = new property_dto_1.PropertyDto();
        this.units = new Array();
        this.specifyAccounts = "default";
        this.specifySecurityDeposit = "";
        Object.assign(this, init);
    }
}
exports.DisplayPropertyWithUnitsDto = DisplayPropertyWithUnitsDto;
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.specifySecurityDeposit === "ENABLED"),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], DisplayPropertyWithUnitsDto.prototype, "client_bank_trust", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.specifyAccounts !== "default"),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], DisplayPropertyWithUnitsDto.prototype, "client_bank_operating", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], DisplayPropertyWithUnitsDto.prototype, "specifyAccounts", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.specifyAccounts !== "default"),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], DisplayPropertyWithUnitsDto.prototype, "specifySecurityDeposit", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], DisplayPropertyWithUnitsDto.prototype, "reserveFundsEnabled", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], DisplayPropertyWithUnitsDto.prototype, "propertyOwnershipEnabled", void 0);
