"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsGenericPhoneNumber = IsGenericPhoneNumber;
const class_validator_1 = require("class-validator");
//https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number
function IsGenericPhoneNumber(validationOptions) {
    return function (object, propertyName) {
        (0, class_validator_1.registerDecorator)({
            name: "isGenericPhoneNumber",
            target: object.constructor,
            propertyName,
            constraints: [],
            options: validationOptions,
            validator: {
                validate(value, args) {
                    if (!value)
                        return false;
                    return /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(value);
                }
            }
        });
    };
}
