import { QueryParams } from "utils/queryParams";

enum SortOrder {
  DESC = "yes"
}

const qp = new QueryParams<"sort_by" | "sort_descending">();

export const applySort = (fieldName: string) => {
  const { sort_by, sort_descending } = qp.getAll();
  if (sort_by !== fieldName) {
    qp.set("sort_by", fieldName);
    qp.clear("sort_descending");
  } else if (sort_descending === undefined) {
    qp.set("sort_descending", SortOrder.DESC);
  } else {
    qp.clear("sort_by");
    qp.clear("sort_descending");
  }
  qp.historyReplace();
};

export const getCurrentSort = (): {
  sort_by: string;
  sort_descending?: SortOrder;
} => {
  const { sort_by, sort_descending } = qp.getAll();
  return {
    sort_by: sort_by as string,
    sort_descending: sort_descending as SortOrder
  };
};
