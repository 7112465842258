import React, { useEffect, useState } from "react";
import { View } from "DLUI/view";
import makeStyles from "./styles";
import Text from "DLUI/text";
import type { TextColor } from "DLUI/text/text";

interface ComponentProps {
  fetchId: string;
  type: "underline" | "rounded" | "simple";
  marginLeft?: number;
  marginTop?: number;
  textColor?: TextColor;
  apiMethod: any;
  uniqueKey?: string;
  width?: string | number;
}

const AsyncSectionTitle: React.FC<ComponentProps> = ({
  fetchId,
  apiMethod,
  type,
  marginLeft,
  marginTop,
  textColor,
  uniqueKey,
  width
}: ComponentProps) => {
  const classes = makeStyles();
  const [title, setTitle] = useState<string | undefined>();

  const getNameFromDictionary = async () => {
    const dictionary = await apiMethod.getDictionary();

    if (dictionary && dictionary[fetchId] && dictionary[fetchId].name) {
      setTitle(dictionary[fetchId].name);
    }
  };

  useEffect(() => {
    getNameFromDictionary();
  }, []);

  if (!title) {
    return null;
  }

  if (type === "simple") {
    return (
      <Text marginTop={20} bold fontSize={18}>
        {title}
      </Text>
    );
  }

  if (type === "rounded") {
    return (
      <div className={classes.componentContainer}>
        <Text marginLeft={60} fontSize={24} fontWeight={700} color={"white"} value={title} />
      </div>
    );
  }

  return (
    <View width={width || "100%"} key={uniqueKey} marginTop={marginTop} paddingLeft={marginLeft}>
      <Text
        removeDefaultPadding
        fontSize={24}
        fontWeight={700}
        color={textColor || "black"}
        value={title}
        align={"center"}
      />
      <div className={classes.indicatorView} />
    </View>
  );
};

export default AsyncSectionTitle;
