import React from "react";
import { Provider } from "react-redux";
import { store } from "store";

interface ComponentProps {
  children: React.ReactNode;
}
const StoreProvider: React.FC<ComponentProps> = ({ children }: ComponentProps) => (
  <Provider store={store}>{children}</Provider>
);

export default StoreProvider;
