import type { TenantDto } from "@doorloop/dto";
import { ConversationLinkedToType, ObjectPermission } from "@doorloop/dto";
import { CloseIcon, EditBlue, ViewBlueIcon } from "assets/icons";
import { PersonInfoRow } from "DLUI/person";
import type { PopoverItem } from "DLUI/popover";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { NavigationManager } from "utils/navigation";
import { useTranslation } from "react-i18next";
import { usersApi } from "api/usersApi";
import { ProfileImage } from "DLUI/profileImage";
import { usePermission } from "screens/settings/userRoles/usePermission";
import { Routes } from "../../../appRouter";
import { Grid, ListItemContainer } from "DLUI/listItems";
import type { SubscribedActions } from "@/hooks/useListBulkActionsManager";
import { tenantsApi } from "api/tenantsApi";
import ListItemContactSection from "DLUI/listItems/listItemContactSection";
import { useResponsiveHelper } from "@/contexts/responsiveContext";
import { TextTooltip } from "DLUI/popover/textTooltip";
import { Link } from "DLUI/link";
import { sendWelcomeSmsAction } from "@/utils/bulkOperationsUtils";
import { useConfirmationDialog } from "@/hooks/useConfirmationDialog";

interface ComponentProps {
  onItemPress?: (tenantId: string) => void;
  resourceId?: string;
  disableListItemOptions?: boolean;
  tenant: TenantDto;
}

const ProspectsListItem: React.FC<ComponentProps> = ({
  tenant,
  onItemPress,
  disableListItemOptions,
  resourceId
}: ComponentProps) => {
  const [tenantData, setTenantData] = useState<TenantDto>(tenant);

  const { fullName, pictureUrl, prospectInfo, id, name } = tenantData;
  const { isMobile } = useResponsiveHelper();
  const { showConfirmationDialog } = useConfirmationDialog();

  const { hasPermission } = usePermission();
  const location = useLocation<any>();
  const { t } = useTranslation();

  const noPhoneAndEmailForTenant = !tenantData?.emails?.[0]?.address && !tenantData?.phones?.[0]?.number;

  const didPressViewProfile = hasPermission(ObjectPermission.prospects, "viewOne")
    ? () => {
        if (id) {
          if (onItemPress) {
            onItemPress(id);
          } else {
            NavigationManager.viewProspectDetails(id);
          }
        }
      }
    : undefined;

  const didPressEditButton = () => {
    if (id) {
      NavigationManager.editProspect(location.pathname, id);
    }
  };

  const didPressViewNewApplication = () => {
    tenant.id && NavigationManager.viewProspectDetails(tenant.id, "rental-applications");
  };

  const didPressDeleteButton = () => {
    if (id) {
      showConfirmationDialog(location.pathname, {
        dialogTitle: AppStrings.Common.DeleteConfirmation,
        dialogSubTitle: AppStrings.Prospects.Screen.DeleteConfirmationText,
        loadingText: t(AppStrings.Prospects.Screen.DeleteLoadingText),
        successText: t(AppStrings.Prospects.Screen.DeleteProspectSuccessText),
        confirmButtonText: AppStrings.Common.Delete,
        dismissButtonText: AppStrings.Common.Dismiss,
        apiMethod: "tenantsApi",
        confirmOperation: "delete",
        toasts: { translationKey: AppStrings.Toasts.custom.prospects.DELETE },
        itemId: id
      });
    }
  };

  const cardTopButtons: PopoverItem[] = [
    {
      Icon: EditBlue,
      title: AppStrings.Common.Edit,
      onClick: didPressEditButton,
      clearance: { permission: ObjectPermission.prospects, field: "edit" }
    },
    {
      Icon: ViewBlueIcon,
      title: AppStrings.Tenants.Screen.ViewProfile,
      onClick: didPressViewProfile,
      showSeparator: true,
      clearance: { permission: ObjectPermission.prospects, field: "viewOne" }
    },
    {
      Icon: CloseIcon,
      title: AppStrings.Common.Delete,
      onClick: didPressDeleteButton,
      iconPathColor: "error",
      textColor: "error",
      clearance: { permission: ObjectPermission.prospects, field: "delete" }
    }
  ];

  const handleContactInfoUpdated = (tenant: TenantDto) => {
    setTenantData(tenant);
  };

  const renderAssignees = () => {
    if (prospectInfo && prospectInfo.assignedToUser) {
      let pictureUrl;
      let userName;
      const usersDictionary = usersApi.getItemFromDictionary(prospectInfo.assignedToUser);
      if (usersDictionary) {
        pictureUrl = usersDictionary.pictureUrl;
        userName = usersDictionary.name;
      }

      return (
        <View alignItems={"center"} justifyContent={"center"} flexDirection={"row"}>
          <ProfileImage pictureUrl={pictureUrl} size={30} />
          <Text
            maxWidth={"100%"}
            numberOfLines={2}
            overFlow={"ellipsis"}
            align={"center"}
            color={"black"}
            fontSize={14}
            fontWeight={700}
          >
            {userName}
          </Text>
        </View>
      );
    }
    return (
      <View alignItems={"center"} justifyContent={"center"} flexDirection={"column"}>
        <ProfileImage size={30} />
        <Text color={"secondary-gray"} fontSize={14} fontWeight={700} value={AppStrings.Prospects.Screen.Unassigned} />
      </View>
    );
  };

  const bulkActions: SubscribedActions | undefined = resourceId
    ? {
        deleteProspects: {
          [resourceId]: {
            action: async () => {
              const result = await tenantsApi.delete(id!);
              if (!result.status) {
                throw result.message;
              }

              return { message: t(AppStrings.Common.ProspectDeleted) };
            },
            ongoingMessage: t(AppStrings.Prospects.Screen.DeleteLoadingText)
          }
        },
        sendProspectWelcomeSms: {
          [resourceId]: {
            action: sendWelcomeSmsAction(ConversationLinkedToType.PROSPECT, id),
            ongoingMessage: t(AppStrings.BulkActions.SendWelcomeSmsOngoingMessage)
          }
        }
      }
    : undefined;

  return (
    <ListItemContainer
      resourceId={resourceId}
      bulkActions={bulkActions}
      popoverItems={disableListItemOptions ? undefined : cardTopButtons}
    >
      <Grid
        showDivider
        xs={12}
        sm={4}
        md={4}
        lg={4}
        sortColumn={"name"}
        onClick={didPressViewProfile}
        title={AppStrings.Prospects.Screen.Prospect}
        paddingLeft={20}
      >
        <PersonInfoRow
          marginLeft={0}
          pictureUrl={pictureUrl}
          fullName={fullName}
          name={name}
          onClick={didPressViewProfile}
          prospectStatus={prospectInfo?.status}
          href={id ? `${Routes.PROSPECTS}/${id}/overview` : undefined}
          showSeparator={false}
        />
      </Grid>

      <Grid
        showDivider
        xs={12}
        sm={2}
        md={2}
        lg={2}
        onClick={didPressViewProfile}
        title={AppStrings.Prospects.NewProspect.MoveIn}
        paddingLeft={20}
        sortColumn={"prospectInfo.expectedMoveInDate"}
      >
        <View justifyContent={"center"} flexDirection={"row"}>
          <View flex={1} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} paddingLeft={15}>
            <Text
              color={"black"}
              fontSize={14}
              value={prospectInfo?.expectedMoveInDate ?? "--"}
              formatType={prospectInfo?.expectedMoveInDate ? "date" : undefined}
            />
          </View>
        </View>
      </Grid>

      <Grid
        showDivider
        xs={12}
        sm={3}
        md={3}
        lg={3}
        title={AppStrings.Users.Screen.ContactInformation}
        paddingRight={15}
        paddingLeft={15}
      >
        <ListItemContactSection
          disableEdit={disableListItemOptions}
          onSaveSuccess={handleContactInfoUpdated}
          dto={tenantData}
          api={tenantsApi}
          shouldUpdatePortalInfo={false}
        />
      </Grid>

      <Grid
        xs={12}
        sm={2}
        md={2}
        lg={2}
        onClick={didPressViewProfile}
        sortColumn={"prospectInfo.assignedToUser"}
        title={AppStrings.Prospects.Screen.Assigned}
        showDivider
      >
        {renderAssignees()}
        {/* <View justifyContent={"center"} height={60}></View> */}
        {/* <View
            flexDirection={"row"}
            alignItems={"center"}
            width={"40%"}
            height={60}
          >
            <SeparationLine width={1} height={"50%"} />
            {renderNextTaskDue()}
          </View> */}
      </Grid>

      <Grid xs={12} sm={1} md={1} lg={1} title={AppStrings.Prospects.NewProspect.NextStep}>
        <View
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          maxWidth={isMobile ? undefined : 130}
        >
          <TextTooltip
            show={noPhoneAndEmailForTenant}
            useDark
            value={AppStrings.Prospects.Screen.CreateRentalApplicationTooltip}
          >
            <Link onClick={() => !noPhoneAndEmailForTenant && didPressViewNewApplication()}>
              <View flexDirection={"row"} gap={3} flex={1}>
                <Text
                  color={noPhoneAndEmailForTenant ? "black" : "lightBlue"}
                  fontSize={14}
                  maxWidth={"100%"}
                  value={AppStrings.Prospects.Screen.CreateRentalApplication}
                />
              </View>
            </Link>
          </TextTooltip>
        </View>
      </Grid>
    </ListItemContainer>
  );
};

export default ProspectsListItem;
