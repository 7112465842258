"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCashFlowStatementReportQuery = void 0;
const isDateStringWithoutTime_validator_1 = require("../../common/validators/isDateStringWithoutTime.validator");
const showNonZeroOrActiveOnly_enum_1 = require("../profitAndLoss/showNonZeroOrActiveOnly.enum");
const transactionReportGroupBy_enum_1 = require("../transactionReport/transactionReportGroupBy.enum");
const class_validator_1 = require("class-validator");
const accountingMethod_enum_1 = require("../../journalEntries/accountingMethod.enum");
class GetCashFlowStatementReportQuery {
}
exports.GetCashFlowStatementReportQuery = GetCashFlowStatementReportQuery;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(transactionReportGroupBy_enum_1.TransactionReportGroupBy),
    __metadata("design:type", String)
], GetCashFlowStatementReportQuery.prototype, "groupBy", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(showNonZeroOrActiveOnly_enum_1.ShowNonZeroOrActiveOnly),
    __metadata("design:type", String)
], GetCashFlowStatementReportQuery.prototype, "showRows", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetCashFlowStatementReportQuery.prototype, "filter_date_from", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetCashFlowStatementReportQuery.prototype, "filter_date_to", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(accountingMethod_enum_1.AccountingMethod),
    __metadata("design:type", String)
], GetCashFlowStatementReportQuery.prototype, "filter_accountingMethod", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetCashFlowStatementReportQuery.prototype, "filter_property", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], GetCashFlowStatementReportQuery.prototype, "filter_properties", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetCashFlowStatementReportQuery.prototype, "filter_unit", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetCashFlowStatementReportQuery.prototype, "filter_propertyGroup", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetCashFlowStatementReportQuery.prototype, "filter_tenant", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetCashFlowStatementReportQuery.prototype, "filter_propertyOwner", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], GetCashFlowStatementReportQuery.prototype, "filter_propertyOwners", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetCashFlowStatementReportQuery.prototype, "filter_vendor", void 0);
