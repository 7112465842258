import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    dropzoneRootComponent: {
      ":hover": {
        cursor: "pointer"
      },
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center"
    },
    componentContainer: {
      alignSelf: "center",
      display: "flex",
      flexDirection: "row"
    },
    tenantPhotoContainer: {
      width: 122,
      height: 122,
      borderRadius: "61px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(47,62,131,0.05)",
      position: "relative"
    },

    tenantPhotoAvatar: {
      height: 100,
      width: 100
    },
    selectedImage: {
      width: 100,
      height: 100,
      borderRadius: "100%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      border: "4px solid",
      borderColor: theme.palette.gray.dark
    },
    uploadImageButtonContainer: {
      width: 150,
      height: 40,
      borderColor: theme.palette.grey[400],
      backgroundColor: "rgba(47,62,131,0.05)",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      marginLeft: -3,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    uploadIcon: {
      marginLeft: 10,
      marginRight: 10
    },
    deleteImageContainer: {
      position: "absolute",
      right: 0,
      top: 15,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(47,62,131,0.05)",
      zIndex: 1000,
      width: "20px",
      height: "20px",
      borderRadius: 20
    },
    companyLogoDeleteImageContainer: {
      position: "absolute",
      right: 20,
      top: 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(47,62,131,0.05)",
      zIndex: 1000,
      width: "20px",
      height: "20px",
      borderRadius: 20
    },
    deleteImage: {
      color: theme.palette.pink.main,
      width: 30,
      height: 30
    }
  })
);
