import type { FC } from "react";
import { ControlledCheckBox } from "DLUI/form";
import { AnimatedView } from "DLUI/view/animatedView";
import { Notification } from "DLUI/notification/notification";
import ColorsEnum from "utils/colorsEnum";
import Text from "DLUI/text";
import { ErrorSignIcon } from "assets/icons";
import { useTypedTranslation } from "@/locale";
import { HorizontalSeparationLine } from "@/components/DLUI/separatorView/horizontalSeparationLine";

interface ComponentProps {
  show: boolean;
  message: string;
  showCheckbox?: boolean;
  isEvictionNoteConfirmed: boolean;
  handleToggleEvictionCheckboxConfirmation: (value: boolean) => void;
}

export const EvictionStatusConfirmationAlert: FC<ComponentProps> = ({
  show,
  message,
  showCheckbox,
  isEvictionNoteConfirmed,
  handleToggleEvictionCheckboxConfirmation
}) => {
  const { t } = useTypedTranslation();

  return (
    <AnimatedView shouldShow={show} minHeight={showCheckbox ? 160 : 100}>
      <HorizontalSeparationLine marginTop={10} />
      <Notification
        marginTop={10}
        padding={10}
        LabelComponent={() => (
          <Text
            marginLeft={5}
            fontWeight={400}
            fontSize={14}
            maxWidth={"90%"}
            color={"secondary-yellow"}
            value={message}
          />
        )}
        background={ColorsEnum.LightYellow}
        separatorColor={ColorsEnum.DarkYellow}
        iconPathColor={"yellow"}
        IconComponent={ErrorSignIcon}
      />
      {showCheckbox && (
        <ControlledCheckBox
          checked={isEvictionNoteConfirmed}
          style={{ marginTop: 10 }}
          fontSize="small"
          onChange={handleToggleEvictionCheckboxConfirmation}
          labelValueProps={{
            text: t("leases.settings.evictionConfirmation"),
            color: "gray"
          }}
        />
      )}
    </AnimatedView>
  );
};
