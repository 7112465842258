import React from "react";

import { SettingPermission } from "@doorloop/dto";

import AppStrings from "../../../../locale/keys";
import type { RequestsSettingsFormikWrapperProps } from "screens/settings/requestsSettings/requestsSettingsFormikWrapper";
import { RequestsSettingsFormikWrapper } from "screens/settings/requestsSettings/requestsSettingsFormikWrapper";
import { settingsApi } from "api/settingsApi";

export const TenantRequestsSettings: React.FC<RequestsSettingsFormikWrapperProps> = (
  props: RequestsSettingsFormikWrapperProps
) => (
  <RequestsSettingsFormikWrapper
    {...props}
    getRequestsSettingsApi={settingsApi.getTenantRequestsNotifications}
    updateRequestsSettingsApi={settingsApi.updateTenantRequestsNotifications}
    textTitle={AppStrings.Common.TenantRequestsSettings}
    settingPermission={SettingPermission.tenantRequests}
    notifyRecipientFormikFieldName={"sendEmailToTenantWhenRequestIsReceived"}
    notifyRecipientFormikFieldLabel={AppStrings.Common.NotifyTenantOnReceived}
  />
);
