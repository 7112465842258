import React, { useMemo } from "react";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { getIn } from "formik";
import Text from "DLUI/text";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import "moment-timezone/index";
import AppStrings from "../../../locale/keys";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import TextInput from "DLUI/form/textField/textInput/textInput";

interface Props {
  formikRef: FormikProps<any>;
}

const TimeZone: React.FC<Props> = ({ formikRef }: Props) => {
  const { t } = useTranslation();
  const fieldName = "timezone";
  const defaultValue = getIn(formikRef.values, fieldName);

  const didSelectAutoCompleteValue = (event: object, timezone: any) => {
    formikRef.setFieldValue(fieldName, timezone?.value);
  };

  const options = useMemo(() => {
    let timezones = moment.tz.names().map((tz) => {
      return {
        value: tz,
        label: tz ? "(GMT " + moment.tz(tz).format("Z") + ") " + tz?.split("/").join(" / ").split("_").join(" ") : ""
      };
    });
    timezones = timezones.filter((x) => x.label.indexOf("Etc") === -1);
    timezones = _.sortBy(timezones, (x) => x.label);
    return timezones;
  }, []);

  const defaultValueOptionIndex = useMemo(() => {
    const index = options.findIndex((x) => x.value === defaultValue);
    return index > -1 ? index : undefined;
  }, [defaultValue]);

  return (
    <View flex={1}>
      <Text value={AppStrings.Common.TimeZoneDescription} />
      <View marginTop={20}>
        <Autocomplete
          id={"timeZonePicker"}
          options={options}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, selectedItem) => option.value === selectedItem.value}
          defaultValue={
            defaultValueOptionIndex && options[defaultValueOptionIndex] ? options[defaultValueOptionIndex] : undefined
          }
          onChange={didSelectAutoCompleteValue}
          renderInput={(params) => <TextInput {...params} label={t(AppStrings.Common.TimeZone)} />}
        />
      </View>
    </View>
  );
};

export default TimeZone;
