import type { FC } from "react";
import React from "react";
import { Link } from "DLUI/link";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import ColorsEnum from "utils/colorsEnum";
import { IconButton } from "DLUI/form";
import { OpenInNewIcon } from "assets/icons";

export interface SummaryLinkProps {
  translationKey: string;
  onClick: () => void;
  label?: string;
}

const SummaryLink: FC<SummaryLinkProps> = ({ translationKey, onClick, label }) => (
  <Link width={"100%"} onClick={onClick} underline={"hover"} hoverColor={"lightBlue"} label={label}>
    <View fullWidth flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} noWrap>
      <Text value={translationKey} fontSize={14} colorEnum={ColorsEnum.BrightBlue} />

      <IconButton
        Icon={OpenInNewIcon}
        onClick={onClick}
        size={20}
        pathColor={"light-blue"}
        removePadding
        marginRight={0}
      />
    </View>
  </Link>
);

export { SummaryLink };
