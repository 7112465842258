import { createStyles, makeStyles } from "@material-ui/core/styles";
import ColorsEnum from "../../../utils/colorsEnum";

interface Props {
  isRing?: boolean;
  totalUnread?: number;
}

const sideNotificationBubble = (props: Props) => {
  if (!props.totalUnread) return "unset";

  if (props?.totalUnread < 10) {
    return 21;
  }
  if (props?.totalUnread <= 99) {
    return 19;
  }
  return 13;
};

export default makeStyles(() =>
  createStyles({
    notificationItemWrapper: {
      transition: "background-color .4s ease",
      "&:hover": {
        backgroundColor: ColorsEnum.LightGray
      }
    },
    unopenedIndicator: {
      height: "6px",
      width: "6px",
      backgroundColor: "#1665D8",
      borderRadius: "50%",
      marginTop: "1px"
    },
    ringBell: {
      animation: (props: Props) => (props?.isRing ? "notificationsRing .7s ease" : "none")
    },
    notificationBubble: {
      left: sideNotificationBubble
    },
    mobileNotificationBubble: {
      right: 24
    },
    title: {
      color: "#182C4C"
    },
    placeholder: {
      color: "#182C4C80"
    },
    timestamp: {
      color: "#182C4C80"
    },
    description: {
      color: "#182C4C80"
    }
  })
);
