import React, { createRef, useMemo, useState } from "react";
import clsx from "clsx";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import type { DropzoneRef } from "react-dropzone";
import Dropzone from "react-dropzone";
import type { FileListItemProps, FileRetryMethod } from "./fileListItem";
import FilesList from "./filesList";
import makeStyles from "./styles";
import DropZoneInstructionsView from "DLUI/dropZone/dropZoneInstructionsView";
import { DataCy, FileErrorTypes, FileFilterTypes } from "@doorloop/dto";
import {
  DEFAULT_MAX_FILE_UPLOAD_SIZE_ALLOWED_IN_MB,
  fileExtensionsMap,
  generateUploadError
} from "DLUI/dropZone/utils";
import WarningView from "DLUI/form/warningView/warningView";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";

export type FormAttachmentDropZoneBackgroundColor = "dark" | "white" | "transparent";

interface ComponentProps {
  onFileReceive: (acceptedFiles: any[]) => void;
  files: FileListItemProps[];
  onDeleteItem: (itemIndex: number) => void;
  onRetryPress: (fileIndex: number, retryMethod: FileRetryMethod) => void;
  height?: number;
  loadingFiles?: boolean;
  instructionsViewDirection?: "row" | "column";
  backgroundColor?: FormAttachmentDropZoneBackgroundColor;
  lottieHeight?: number;
  displayType?: "small" | "large";
  baseUrl?: string;
  maxFiles?: number;
  allowDelete?: boolean;
  isRequired?: boolean;
  error?: string;
  dataCy?: string;
  accept?: string;
  maxFileSizeInMB?: number;
}

const FormAttachmentDropZone: React.FC<ComponentProps> = ({
  onFileReceive,
  files,
  onDeleteItem,
  height,
  loadingFiles,
  onRetryPress,
  instructionsViewDirection,
  backgroundColor,
  lottieHeight,
  displayType,
  baseUrl,
  maxFiles = 0,
  allowDelete = true,
  isRequired,
  error,
  dataCy,
  accept = fileExtensionsMap[FileFilterTypes.GENERAL],
  maxFileSizeInMB = DEFAULT_MAX_FILE_UPLOAD_SIZE_ALLOWED_IN_MB
}: ComponentProps) => {
  const [errorType, setErrorType] = useState<FileErrorTypes | undefined>();
  const [erroredFile, setErroredFile] = useState<string>("");
  const isMultipleFilesAllowed = maxFiles === 0 || maxFiles > 1;
  const classes = makeStyles();
  const { isMobile } = useResponsiveHelper();
  const dropZoneRootClass = isMobile ? classes.dropzoneRootComponentMobile : classes.dropzoneRootComponentWeb;
  const dropzoneRef = createRef<DropzoneRef>();
  const errorMessage = generateUploadError(errorType);
  const errorTypeWithErrorBorder = errorType && errorType !== FileErrorTypes.MULTIPLE_NOT_ALLOWED_WARNING;

  const _onFileReceive = (acceptedFiles: any[], erroredFiles: any[]) => {
    clearErrorState();
    if (maxFiles === 1 && files?.length > 0) {
      setErrorType(FileErrorTypes.MULTIPLE_NOT_ALLOWED_WARNING);
      setErroredFile(acceptedFiles[0]?.name || erroredFiles[0]?.name);
    } else if (maxFiles === 1 && (acceptedFiles?.length > 1 || erroredFiles?.length > 1)) {
      setErrorType(FileErrorTypes.MULTIPLE);
    } else if (erroredFiles?.length > 1) {
      setErrorType(FileErrorTypes.MULTIPLE_TYPE_ERROR);
    } else if (erroredFiles?.length === 1) {
      setErrorType(erroredFiles?.[0]?.name);
      setErroredFile(erroredFiles?.[0]?.name);
    } else {
      onFileReceive(acceptedFiles);
    }
  };

  const clearErrorState = () => {
    setErrorType(undefined);
    setErroredFile("");
  };

  const handleDeleteFile = (itemIndex: number) => {
    clearErrorState();
    onDeleteItem?.(itemIndex);
  };

  const renderSelectedFiles = () => {
    if (shouldShowInstructions()) {
      return;
    }
    return (
      <FilesList
        onDeleteItem={handleDeleteFile}
        maxFiles={maxFiles}
        filesItemsArray={files}
        onRetryPress={onRetryPress}
        allowDelete={allowDelete}
        displayType={displayType}
        itemHeight={height}
        baseUrl={baseUrl}
      />
    );
  };

  const shouldShowInstructions = (): boolean => {
    let numberOfFilesToDisplay = 0;
    if (files.length === 0) {
      return true;
    }
    files.forEach((currentFile) => {
      if (
        !currentFile.fileUploadState ||
        currentFile.fileUploadState === "WaitingForUpload" ||
        currentFile.fileUploadState === "UploadInProgress" ||
        currentFile.fileUploadState === "UploadFailed" ||
        currentFile.fileUploadState === "WaitingForDelete" ||
        currentFile.fileUploadState === "UploadSuccessful"
      ) {
        numberOfFilesToDisplay++;
      }
    });
    return numberOfFilesToDisplay === 0;
  };

  const backgroundColorClass = useMemo(() => {
    switch (backgroundColor) {
      case "transparent":
        return classes.transparentBackground;
      case "dark":
        return classes.darkBackground;
    }
  }, []);

  const handleDropZoneClick = () => {
    if (maxFiles === 0 || (maxFiles > 0 && files.length < maxFiles)) {
      dropzoneRef.current?.open();
    }
  };

  return (
    <View flexDirection={"row"} dataCy={DataCy.DLUI.dropZone}>
      <Dropzone
        noDragEventsBubbling
        noClick
        onDrop={_onFileReceive}
        ref={dropzoneRef}
        accept={accept}
        multiple={isMultipleFilesAllowed}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            onClick={handleDropZoneClick}
            className={clsx([
              classes.componentContainer,
              backgroundColorClass,
              error || errorTypeWithErrorBorder ? "errored" : ""
            ])}
          >
            <div className={clsx([classes.dropzoneRootComponent, dropZoneRootClass])} {...getRootProps()}>
              <input {...getInputProps()} />
              <DropZoneInstructionsView
                shouldShow={shouldShowInstructions()}
                isDragActive={isDragActive}
                loadingFiles={loadingFiles}
                isRequired={isRequired}
                height={lottieHeight}
                instructionsViewDirection={instructionsViewDirection || "column"}
                maxFileSizeInMB={maxFileSizeInMB}
              />
              {renderSelectedFiles()}
            </div>
          </div>
        )}
      </Dropzone>
      {errorType === FileErrorTypes.MULTIPLE_NOT_ALLOWED_WARNING ? (
        <WarningView iconSize={20} marginTop={10}>
          <Text value={errorMessage} color={"yellow"} fontSize={14} replaceObject={{ fileName: erroredFile }} />
        </WarningView>
      ) : (
        errorType && (
          <WarningView iconSize={20} marginTop={10} type={"danger"}>
            <Text value={errorMessage} replaceObject={{ fileName: erroredFile }} color={"error"} fontSize={14} />
          </WarningView>
        )
      )}
    </View>
  );
};

export default FormAttachmentDropZone;
