import React, { Fragment, useMemo } from "react";
import type { LeaseDto } from "@doorloop/dto";
import {
  CalendarIconBlue,
  CalendarIconError,
  CashIcon,
  LeaseListIcon,
  LocationIcon,
  MoveOutBlueIcon
} from "assets/icons";
import { Icon } from "DLUI/icon";
import { Grid, ListItemContainer, ListItemIcon, ListItemSection } from "DLUI/listItems";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import moment from "moment";

import type { GridSpace } from "DLUI/listItems/grid";
import ListItemText from "DLUI/listItems/listItemText";

interface ComponentProps {
  leaseData: LeaseDto;
  propertyName: string;
  hideRentSection?: boolean;
}

const CurrentTermsItem: React.FC<ComponentProps> = ({ leaseData, propertyName, hideRentSection }: ComponentProps) => {
  const { name, end } = leaseData;

  const { leaseGridSize, rentGridSize, datesGridSize } = useMemo(() => {
    let leaseGridSize: GridSpace = 5;
    let datesGridSize: GridSpace = 4;
    const rentGridSize: GridSpace = 3;
    if (hideRentSection) {
      leaseGridSize = 6;
      datesGridSize = 6;
    }
    return { leaseGridSize, rentGridSize, datesGridSize };
  }, []);

  const daysToMoveOut = useMemo(() => {
    if (end) {
      const duration = moment.duration(moment(end).diff(moment()));
      let days = duration.asDays();
      if (days > 0) {
        days = Math.ceil(duration.asDays());
      } else {
        days = Math.floor(duration.asDays());
      }
      return days;
    }
    return 0;
  }, []);

  const exceededLeaseEndDate = daysToMoveOut < 0;

  const renderEndDate = () => {
    if (end) {
      return (
        <Fragment>
          <Text marginLeft={5} marginRight={5} color={"black"} align="left" fontSize={14} fontWeight={700}>
            -
          </Text>
          <Text color={"black"} align="left" fontSize={14} fontWeight={700} value={end} formatType={"date"} />
        </Fragment>
      );
    }
    return null;
  };

  return (
    <View marginTop={20}>
      <ListItemContainer removeVerticalPadding borderRadius={10} backgroundColor={"dark"}>
        <Grid
          title={AppStrings.Leases.ListHeader.Lease}
          showDivider
          dividerColor={"gray"}
          xs={12}
          md={leaseGridSize}
          lg={leaseGridSize}
        >
          <View height={"100%"} alignItems={"center"} flexDirection={"row"} justifyContent={"center"}>
            <ListItemIcon marginLeft={10} marginRight={20} Icon={LeaseListIcon} />
            <ListItemSection
              renderTitle={
                <ListItemText color={"black"} overflow={"ellipsis"} numberOfLines={2} fontWeight={700}>
                  {name}
                </ListItemText>
              }
              renderSubTitle={
                <View justifyContent={"flex-start"} alignItems={"center"} flexDirection={"row"}>
                  <Icon Source={LocationIcon} width={20} height={20} marginRight={5} />
                  <View flex={1}>
                    <ListItemText color={"gray"} numberOfLines={1} overflow={"ellipsis"}>
                      {propertyName}
                    </ListItemText>
                  </View>
                </View>
              }
            />
          </View>
        </Grid>
        <Grid showDivider={!hideRentSection} dividerColor={"gray"} xs={12} md={datesGridSize} lg={datesGridSize}>
          <ListItemSection
            renderTitle={
              <View flexDirection={"row"} height={"100%"}>
                <View flexDirection={"row"} justifyContent={"center"} alignItems={"center"} height={"100%"}>
                  <Icon marginRight={10} Source={CalendarIconBlue} pathColor={"black"} />
                  <Text
                    color={"black"}
                    align="left"
                    fontSize={14}
                    fontWeight={700}
                    value={leaseData.start}
                    formatType={"date"}
                  />
                  {renderEndDate()}
                </View>
              </View>
            }
            renderSubTitle={
              <View justifyContent={"center"} alignItems={"center"} flexDirection={"row"}>
                {exceededLeaseEndDate ? (
                  <Icon marginRight={10} Source={CalendarIconError} />
                ) : (
                  <Icon marginRight={10} Source={MoveOutBlueIcon} pathColor={"black"} />
                )}

                <Text
                  color={"gray"}
                  variant={"span"}
                  value={AppStrings.Leases.DraftLease.MoveOut}
                  marginRight={5}
                  fontSize={16}
                />
                <Text color={"gray"} fontSize={16} formatType={"relative-time"} value={String(daysToMoveOut)} />
              </View>
            }
          />
        </Grid>
        {hideRentSection ? null : (
          <Grid xs={12} md={rentGridSize} lg={rentGridSize}>
            <ListItemSection
              renderTitle={
                <View flexDirection={"row"} height={"100%"}>
                  <View flexDirection={"row"} justifyContent={"center"} alignItems={"center"} height={"100%"}>
                    <Icon Source={CashIcon} pathColor={"black"} marginRight={10} />

                    <Text
                      color={"black"}
                      align="left"
                      fontSize={14}
                      fontWeight={700}
                      value={AppStrings.Leases.LeaseDetails.MonthlyRent}
                    />
                  </View>
                </View>
              }
              renderSubTitle={
                <View justifyContent={"center"} alignItems={"center"} flexDirection={"row"}>
                  <ListItemText
                    fontWeight={500}
                    color={"gray"}
                    numberOfLines={1}
                    overflow={"ellipsis"}
                    formatType={"currency"}
                    value={String(leaseData.totalRecurringRent)}
                  />
                </View>
              }
            />
          </Grid>
        )}
      </ListItemContainer>
    </View>
  );
};

export default CurrentTermsItem;
