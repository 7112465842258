import { ServerRoutes } from "@doorloop/dto";
import { RestApiBase } from "./restApiBase";
import { apiHelper } from "./apiHelper";
import type { ApiResult } from "./apiResult";
import type { GetOutgoingPaymentsReportQuery } from "@doorloop/dto";
import type { OutgoingPaymentsReportItemDto } from "@doorloop/dto";

export class OutgoingPaymentsApi extends RestApiBase<OutgoingPaymentsReportItemDto, GetOutgoingPaymentsReportQuery> {
  constructor() {
    super(ServerRoutes.OUTGOING_PAYMENTS);
    this.restRoute = ServerRoutes.OUTGOING_PAYMENTS;
  }

  async send(journalEntryId: string): Promise<ApiResult<{}>> {
    return await apiHelper.axiosPost<{}>({ url: this.restRoute + "/" + journalEntryId });
  }

  async void(journalEntryId: string): Promise<ApiResult<{}>> {
    return await apiHelper.axiosDelete<{}>({ url: this.restRoute + "/" + journalEntryId });
  }
}

export const outgoingPaymentsApi = new OutgoingPaymentsApi();
