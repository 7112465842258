import type { FC } from "react";
import React, { useMemo } from "react";
import MobileNotSupportedView from "./mobileNotSupportedView";
import { isIOSWebView } from "common/native/utils";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";

export type RestrictedDeviceAccessTypes = "iphoneDevice" | "androidDevice" | "tabletOrMobile";

interface Props {
  excludedDevices?: readonly RestrictedDeviceAccessTypes[];
  children: React.ReactNode;
  DisplayView?: FC<any>;
  hideDisplayView?: boolean;
}

const RestrictedDeviceAccess: FC<Props> = ({ excludedDevices, children, DisplayView, hideDisplayView }: Props) => {
  const { size, isTabletOrMobile } = useResponsiveHelper();
  const { hasAccess } = useMemo(() => {
    let hasAccess = true;
    excludedDevices?.forEach((deviceType) => {
      if (deviceType === "iphoneDevice" && isIOSWebView()) {
        hasAccess = false;
      }
      if (deviceType === "tabletOrMobile" && isTabletOrMobile) {
        hasAccess = false;
      }
    });
    return { hasAccess };
  }, [size]);

  return hasAccess ? (
    <>{children}</>
  ) : hideDisplayView ? (
    <> </>
  ) : (
    (DisplayView && <DisplayView />) || <MobileNotSupportedView />
  );
};

export default RestrictedDeviceAccess;
