import Common from "./keys/common";
import Navigation from "./keys/navigation";
import BulkPayments from "./keys/bulkPayments";
import Auth from "./keys/auth";
import DrawerItems from "./keys/drawerItems";
import Properties from "./keys/properties";
import Units from "./keys/units";
import Tenants from "./keys/tenants";
import Leases from "./keys/leases";
import Overview from "./keys/overview";
import Owners from "./keys/owners";
import Vendors from "./keys/vendors";
import LateFee from "./keys/lateFee";
import Bills from "./keys/bills";
import Notes from "./keys/notes";
import Files from "./keys/files";
import Tasks from "./keys/tasks";
import Prospects from "./keys/prospects";
import OtherTransactions from "./keys/otherTransactions";
import Accounts from "./keys/accounts";
import Calendar from "./keys/calendar";
import Users from "./keys/users";
import Settings from "./keys/settings";
import Tags from "./keys/tags";
import Reports from "./keys/reports";
import UserRoles from "./keys/userRoles";
import Dashboards from "./keys/dashboards";
import Announcements from "./keys/announcements";
import BulkActions from "./keys/bulkActions";
import Esignatures from "./keys/esignature";
import Listing from "./keys/listing";
import OnBoarding from "./keys/onBoarding";
import Allocations from "./keys/allocations";
import Notifications from "./keys/notifications";
import Toasts from "./keys/toasts";
import OwnerPortal from "./keys/ownerPortal";
import Budgets from "./keys/budgets";
import CommunicationsCenter from "./keys/communicationsCenter";

const AppStrings = {
  Announcements,
  Allocations,
  Common,
  OnBoarding,
  BulkPayments,
  Navigation,
  Auth,
  DrawerItems,
  Properties,
  Units,
  Calendar,
  Tenants,
  Leases,
  Overview,
  Owners,
  Vendors,
  LateFee,
  Bills,
  Notes,
  Files,
  Tasks,
  Prospects,
  OtherTransactions,
  Accounts,
  Users,
  UserRoles,
  Settings,
  Tags,
  Reports,
  Dashboards,
  BulkActions,
  Esignatures,
  Listing,
  Notifications,
  Toasts,
  OwnerPortal,
  Budgets,
  CommunicationsCenter
};

export default AppStrings;
