import type {
  GetAllBaseQueryResponse,
  GetAllUnitsQuery,
  GetUnitsListingsPartnersStatusResponseDto,
  UnitDto
} from "@doorloop/dto";
import { ServerRoutes } from "@doorloop/dto";
import { RestApiBaseWithDictionary } from "./restApiBaseWithDictionary";
import { apiHelper } from "./apiHelper";
import { propertiesApi } from "./propertiesApi";
import type { ApiResult } from "api/apiResult";

class UnitsListingApi {
  async getAll(query: GetAllUnitsQuery): Promise<ApiResult<GetAllBaseQueryResponse<UnitDto>>> {
    return await apiHelper.axiosGet<GetAllBaseQueryResponse<UnitDto>>(ServerRoutes.UNITS_LISTINGS_GET_ALL, query);
  }
}

export const unitsListingApi = new UnitsListingApi();

export class UnitsApi extends RestApiBaseWithDictionary<UnitDto, GetAllUnitsQuery> {
  constructor() {
    super(ServerRoutes.UNITS, [propertiesApi]);
    this.restRoute = ServerRoutes.UNITS;
  }

  getSyndicationStatuses = async (unitId: string): Promise<ApiResult<GetUnitsListingsPartnersStatusResponseDto>> =>
    await apiHelper.axiosGet<GetUnitsListingsPartnersStatusResponseDto>(
      ServerRoutes.UNITS_LISTINGS_GET_LISTING_PARTNERS_STATUS,
      { unit: unitId }
    );

  getDetails = async (unitId: string): Promise<ApiResult<UnitDto>> => await this.getUnitDetails(unitId);

  getUnitDetails = async (unitId: string): Promise<ApiResult<UnitDto>> =>
    await apiHelper.axiosGet<UnitDto>(ServerRoutes.UNITS + "/" + unitId);
}

export const unitsApi = new UnitsApi();
