import React, { useState } from "react";
import DialogFrame, { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import { View } from "DLUI/view";
import { useTranslation } from "react-i18next";
import { LoginResponseType, TenantNotificationSettingsDto } from "@doorloop/dto";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import type { FormikProps } from "formik";
import { Form, Formik } from "formik";
import { NotificationSettingsContextWrapper } from "DLUI/dialogs/notifications/components/notificationSettingsContextWrapper";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { tenantPortalNotificationApi } from "api/tenantPortal/tenantPortalNotificationApi";
import { useEffectAsync } from "../../../../../../hooks/useEffectAsync";
import { useResponsiveHelper } from "../../../../../../contexts/responsiveContext";
import { useParams } from "react-router-dom";
import { useAnalyticsService } from "@/hooks/useAnalyticsService";

let formikGlobalRef: FormikProps<TenantNotificationSettingsDto> | null = null;

enum TenantNotificationSettingsDialogViews {
  DONE,
  Loading
}

interface NotificationsSettingsProps {
  onClose: () => void;
  onBackdropClick: () => void;
  dialogTitle;
}

const NotificationsTenantSettings = ({ onClose, onBackdropClick, dialogTitle }: NotificationsSettingsProps) => {
  const { dispatchAnalytics } = useAnalyticsService();
  const { isMobile } = useResponsiveHelper();
  const { leaseId } = useParams<{ leaseId?: string }>();
  const { t } = useTranslation();
  const [loadingState, setLoadingState] = useState<DialogState>(DialogState.Show);
  const [viewIndex, setViewIndex] = useState(TenantNotificationSettingsDialogViews.Loading);
  const [errorText, setErrorText] = useState<string>();
  const [settings, setSettings] = useState<TenantNotificationSettingsDto>();

  const handleSaveClick = async () => {
    if (formikGlobalRef?.values) {
      setViewIndex(TenantNotificationSettingsDialogViews.Loading);
      const res = await tenantPortalNotificationApi.updateTenantNotificationsSettings(formikGlobalRef.values);

      if (res.status) {
        dispatchAnalytics("notification_center_settings_saved");
        onBackdropClick();
      } else {
        setErrorText(res.message || t(AppStrings.Common.GeneralError));
        setLoadingState(DialogState.Error);
      }
    }
  };

  useEffectAsync(async () => {
    const response = await tenantPortalNotificationApi.getTenantNotificationsSettings(leaseId);

    if (response?.status) {
      const data = new TenantNotificationSettingsDto({ ...response.data });

      setSettings(data);
      setViewIndex(TenantNotificationSettingsDialogViews.DONE);
    } else {
      setErrorText(response.message);
      setLoadingState(DialogState.Error);
    }
  }, []);

  const renderForm = () => (
    <View
      alignItems={"center"}
      paddingTop={5}
      gap={10}
      paddingBottom={10}
      paddingRight={20}
      paddingLeft={20}
      flex={1}
      width={"100%"}
      noWrap
    >
      {settings && (
        <Formik initialValues={settings} onSubmit={() => {}}>
          {(formik) => {
            formikGlobalRef = formik;

            return (
              <Form style={{ width: "100%", height: "100%" }}>
                <NotificationSettingsContextWrapper userType={LoginResponseType.TENANT} />
              </Form>
            );
          }}
        </Formik>
      )}
    </View>
  );

  const renderView = ({ index }: any) => {
    if (index === TenantNotificationSettingsDialogViews.DONE && loadingState === DialogState.Show) {
      return (
        <View>
          <View paddingBottom={10} paddingTop={10} paddingRight={20} paddingLeft={20}>
            <Text color={"gray"} value={AppStrings.Notifications.Settings.Subtitle} />

            <HorizontalSeparationLine height={1} marginTop={10} />
          </View>
          {renderForm()}
        </View>
      );
    }
    return (
      <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
        <LoadingDialog
          didPressDismissButton={onBackdropClick}
          dialogState={loadingState}
          errorText={errorText}
          successText={t(AppStrings.Notifications.Settings.SaveSuccess)}
        />
      </View>
    );
  };

  return (
    <DialogFrame
      onCloseButtonClick={onClose}
      title={dialogTitle}
      width={getDialogFrameDimension("width", 1000)}
      height={getDialogFrameDimension("height", 850)}
      renderView={renderView}
      numViews={2}
      activeView={viewIndex}
      frameType={viewIndex === TenantNotificationSettingsDialogViews.DONE ? "sectionTitleFrame" : "contentOnly"}
      RenderActionPanelButtons={() => (
        <FormActionButtons
          propsSubButton={{ onClick: onBackdropClick }}
          propsMainButton={{ type: "cta", props: { onClick: handleSaveClick } }}
        />
      )}
    />
  );
};

export default NotificationsTenantSettings;
