import React from "react";
import { FastField } from "formik";
import { useTranslation } from "react-i18next";

import type { PhoneNumberDto } from "@doorloop/dto";

import AppStrings from "../../../../../locale/keys";
import Text from "DLUI/text";
import TextInput from "DLUI/form/textField/textInput";
import { CopyLinkNewIcon } from "../../../../../assets";
import { copyToClipboard } from "../../../../../utils/clipboard";
import { formatPhoneNumber } from "../../../../../utils/formatPhoneNumber";
import { FormikSwitchButton, IconButton } from "DLUI/form";
import { handleToast } from "store/toast/actions";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { useDispatch } from "react-redux";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";
import { View } from "DLUI/view";

interface ComponentProps {
  phoneNumberDto: PhoneNumberDto;
}

export const SmsSettings: React.FC<ComponentProps> = ({ phoneNumberDto }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile } = useResponsiveHelper();

  const { phoneNumber } = phoneNumberDto;
  const formattedPhoneNumber = formatPhoneNumber(phoneNumber, "(NNN) NNN-NNNN");

  const handleCopyClick = (): void => {
    copyToClipboard(formattedPhoneNumber);

    dispatch(
      handleToast({
        severity: "neutral",
        translationKey: AppStrings.Settings.GeneralSettings.CommunicationCenter.SmsSettingsPhoneNumberCopy
      })
    );
  };

  return (
    <View width={"100%"} flex={1}>
      <Text bold value={AppStrings.Settings.GeneralSettings.CommunicationCenter.SmsSettings} marginTop={24} />

      <Text
        fontWeight={400}
        value={AppStrings.Settings.GeneralSettings.CommunicationCenter.SmsSettingsDescription}
        marginTop={12}
      />

      <FastField
        component={FormikSwitchButton}
        name={"isPhoneNumberEnabled"}
        label={AppStrings.Settings.GeneralSettings.CommunicationCenter.SmsSettingsEnablePhoneNumberLabel}
        marginTop={16}
      />

      <HorizontalSeparationLine marginTop={24} />

      <Text bold value={AppStrings.Common.PhoneNumber} marginTop={24} />

      <Text
        fontWeight={400}
        value={AppStrings.Settings.GeneralSettings.CommunicationCenter.SmsSettingsPhoneNumberDescription}
        marginTop={12}
      />

      <View
        width={isMobile ? "100%" : "50%"}
        flexDirection={"row"}
        gap={10}
        alignItems={"center"}
        noWrap
        marginTop={16}
      >
        <TextInput label={t(AppStrings.Common.PhoneNumber)} value={formattedPhoneNumber} disabled />
        <IconButton onClick={handleCopyClick} Icon={CopyLinkNewIcon} pathColor={"black"} />
      </View>
    </View>
  );
};
