import { getShareApplicationUrl } from "screens/tenantPortal/rentalApplication/components/beforeYouBegin/shareApplicationView";
import { rentalApplicationsApi } from "api/rentalApplicationsApi";
import type { NavigationActionProps } from "store/toast/actions";
import { handleToast } from "store/toast/actions";
import AppStrings from "../../../locale/keys";
import { NavigationActionType } from "store/toast/enums";
import { copyToClipboard } from "../../../utils/clipboard";
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";
import type { RentalApplicationDto } from "@doorloop/dto";
import {
  AddressDto,
  ContactDetailsDto,
  formikValidate,
  PersonalInfoSettingsDto,
  RentalApplicationAboutInfoPersonalInfo,
  SegmentEventTypes,
  TenantType
} from "@doorloop/dto";
import { propertiesApi } from "api/propertiesApi";
import _ from "lodash";
import {
  rentalApplicationEventSource,
  rentalApplicationEventsType,
  useRentalApplicationEvents
} from "../../../hooks/useRentalApplicationEvents";
import { tenantsApi } from "api/tenantsApi";
import type { Navigation } from "../../../utils/navigation";
import { useTranslation } from "react-i18next";
import type { IRootState } from "store/index";

const validationRedirectDialogMap: Record<string, Record<TenantType, keyof Navigation>> = {
  propertyValidationResult: {
    [TenantType.LEASE_TENANT]: "editProperty",
    [TenantType.PROSPECT_TENANT]: "editProperty"
  },
  personalInfoValidationResult: {
    [TenantType.LEASE_TENANT]: "editTenant",
    [TenantType.PROSPECT_TENANT]: "editProspectInfo"
  },
  aboutInfoValidationResult: {
    [TenantType.LEASE_TENANT]: "editTenant",
    [TenantType.PROSPECT_TENANT]: "editProspectInfo"
  }
};

interface actionRedirectType {
  name?: keyof Navigation;
  props?: NavigationActionProps;
}

interface IActionValidation {
  actionValidationToastText: string;
  actionRedirectDialogToast: actionRedirectType;
}

interface HookProps {
  rentalApplication: RentalApplicationDto;
  personalInfoSettingsDto?: PersonalInfoSettingsDto;
}

export const useSendRentalApplication = ({ rentalApplication, personalInfoSettingsDto }: HookProps) => {
  const [sendRentalApplicationLoadingStatus, setSendRentalApplicationLoadingStatus] = useState<boolean>(false);
  const [sendRentalApplicationReminderLoadingStatus, setSendRentalApplicationReminderLoadingStatus] =
    useState<boolean>(false);
  const userData = useSelector((state: IRootState) => state.auth.currentLoginResponse);
  const { fireEvent } = useRentalApplicationEvents({
    propertyId: rentalApplication?.property,
    rentalApplicationId: rentalApplication?.id
  });
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const [error, setErrors] = useState<any>();

  const { actionValidationToastText, actionRedirectDialogToast } = useMemo<IActionValidation>(() => {
    const { property: propertyId, aboutInfo } = rentalApplication;
    let actionValidationToastText = "";
    let actionRedirectDialogToast: actionRedirectType = rentalApplication?.tenant
      ? {
          name: validationRedirectDialogMap?.personalInfoValidationResult[TenantType.PROSPECT_TENANT],
          props: {
            id: rentalApplication.tenant
          }
        }
      : {};

    if (propertyId && aboutInfo && personalInfoSettingsDto) {
      const property = propertiesApi.getItemFromDictionary(propertyId);
      let tenantType: TenantType | null = null;
      let prospectValidation = {};
      if (rentalApplication.tenant) {
        const tenant = tenantsApi.getItemFromDictionary(rentalApplication.tenant);
        tenantType = tenant?.type;

        if (rentalApplication.submittedAt && !_.isEmpty(aboutInfo)) {
          const { firstName, middleName, noMiddleName, lastName, mobileNumber, emailAddress } = aboutInfo;
          const aboutInfoToCheck = {
            firstName,
            middleName,
            noMiddleName,
            lastName,
            mobileNumber,
            emailAddress
          };
          const formikValidation = formikValidate(RentalApplicationAboutInfoPersonalInfo, aboutInfoToCheck);
          if (!formikValidation?.mobileNumber && !formikValidation?.emailAddress) {
            prospectValidation = formikValidation;
          }
        } else {
          const contactDetails = new ContactDetailsDto();
          contactDetails.emails = tenant?.emails;
          contactDetails.phones = tenant?.phones;
          prospectValidation = formikValidate(ContactDetailsDto, contactDetails);
        }
      }
      const propertyValidationResult = formikValidate(AddressDto, property.address);
      const personalInfoValidationResult = formikValidate(PersonalInfoSettingsDto, personalInfoSettingsDto);

      const validationUnion = {
        propertyValidationResult,
        prospectValidation,
        personalInfoValidationResult
      };

      for (const validationKey in validationUnion) {
        const validationObj = validationUnion[validationKey];
        for (const validationName in validationObj) {
          const validationValue = validationObj[validationName];
          if (validationValue) {
            actionValidationToastText = t(AppStrings.Common.RentalApplicationValidationFailedMsg, {
              fieldName: _.startCase(validationName)
            });
            if (tenantType && validationRedirectDialogMap[validationKey]?.[tenantType]) {
              actionRedirectDialogToast = {
                name: validationRedirectDialogMap[validationKey][tenantType],
                props: {
                  id:
                    (validationRedirectDialogMap[validationKey][tenantType] === "editProperty"
                      ? rentalApplication.property
                      : rentalApplication.tenant) || ""
                }
              };
            }
            break;
          }
        }
      }
    }
    return { actionValidationToastText, actionRedirectDialogToast };
  }, [rentalApplication, personalInfoSettingsDto]);

  const applicationUrl = getShareApplicationUrl({
    applicationId: rentalApplication.id,
    propertyId: rentalApplication.property,
    unitId: rentalApplication.unit,
    dbTenant: userData?.currentDbTenant?.id,
    source: rentalApplicationEventSource.email
  });

  const rentalApplicationCommunicationPayload = {
    rentalApplicationId: rentalApplication.id,
    tenantId: rentalApplication.tenant,
    propertyId: rentalApplication.property,
    unitId: rentalApplication.unit,
    applicationUrl,
    isCheckedByTransunion: rentalApplication.transunionInfo?.screeningRequestRenterIdentityVerified !== undefined
  };

  const didPressSendRentalApplicationReminder = async () => {
    try {
      setIsLoading(true);

      setSendRentalApplicationReminderLoadingStatus(true);
      const result = await rentalApplicationsApi.sendRentalApplicationReminder(rentalApplicationCommunicationPayload);
      fireEvent({
        eventName: SegmentEventTypes.RentalApplicationUserSentReminder,
        trackAnalyticsOptions: { trackEventInIntercom: true }
      });
      if (!result.status) {
        dispatch(
          handleToast({
            severity: "error",
            translationKey: AppStrings.Common.RentalApplicationReminderSendFailure,
            action: {
              type: NavigationActionType.TAB,
              name: actionRedirectDialogToast?.name,
              props: actionRedirectDialogToast?.props
            }
          })
        );
      }
      setSendRentalApplicationReminderLoadingStatus(false);

      return result.status;
    } catch (error) {
      dispatch(
        handleToast({
          severity: "error",
          translationKey: AppStrings.Common.RentalApplicationReminderSendFailure,
          action: {
            type: NavigationActionType.TAB,
            name: actionRedirectDialogToast?.name,
            props: actionRedirectDialogToast?.props
          }
        })
      );
      setErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const didPressSendRentalApplication = async (
    updateLoadingCircle = false,
    segmentType = SegmentEventTypes.RentalApplicationUserSentByEmailSms
  ) => {
    let isSuccess = false;
    try {
      setIsLoading(true);

      if (actionValidationToastText) {
        dispatch(
          handleToast({
            severity: "error",
            translationKey: actionValidationToastText,
            action: {
              type: NavigationActionType.TAB,
              name: actionRedirectDialogToast?.name,
              props: actionRedirectDialogToast?.props
            }
          })
        );
      } else {
        updateLoadingCircle && setSendRentalApplicationLoadingStatus(true);

        const result = await rentalApplicationsApi.sendRentalApplicationInvite(rentalApplicationCommunicationPayload);
        if (result.status) {
          const { email, sms } = result.data;
          const properties =
            segmentType === SegmentEventTypes.RentalApplicationUserSentByEmailSms
              ? {
                  [rentalApplicationEventsType.SentBy]: `${email ? "email" : ""}${sms ? (email ? `+sms` : "sms") : ""}`
                }
              : {};
          fireEvent({ eventName: segmentType, properties, trackAnalyticsOptions: { trackEventInIntercom: true } });
          isSuccess = true;
        } else {
          dispatch(
            handleToast({
              severity: "error",
              translationKey: AppStrings.Common.RentalApplicationSendFailure,
              action: {
                type: NavigationActionType.TAB,
                name: actionRedirectDialogToast?.name,
                props: actionRedirectDialogToast?.props
              }
            })
          );
        }
        updateLoadingCircle && setSendRentalApplicationLoadingStatus(false);
      }
    } catch (error) {
      dispatch(
        handleToast({
          severity: "error",
          translationKey: AppStrings.Common.RentalApplicationReminderSendFailure,
          action: {
            type: NavigationActionType.TAB,
            name: actionRedirectDialogToast?.name,
            props: actionRedirectDialogToast?.props
          }
        })
      );
      setErrors(error);
    } finally {
      setIsLoading(false);
    }

    return isSuccess;
  };

  const didPressCopyLink = () => {
    const applicationUrl = getShareApplicationUrl({
      applicationId: rentalApplication.id,
      propertyId: rentalApplication.property,
      unitId: rentalApplication.unit,
      dbTenant: userData?.currentDbTenant?.id,
      source: rentalApplication.id
        ? rentalApplicationEventSource.personallink
        : rentalApplication.unit
          ? rentalApplicationEventSource.unitlink
          : rentalApplication.property
            ? rentalApplicationEventSource.propertylink
            : rentalApplicationEventSource.companylink
    });

    if (actionValidationToastText) {
      dispatch(
        handleToast({
          severity: "error",
          translationKey: actionValidationToastText,
          action: {
            type: NavigationActionType.TAB,
            name: actionRedirectDialogToast?.name,
            props: actionRedirectDialogToast?.props
          }
        })
      );
    } else {
      copyToClipboard(applicationUrl);
      fireEvent({
        eventName: SegmentEventTypes.RentalApplicationUserCopyPersonalLink,
        trackAnalyticsOptions: { trackEventInIntercom: true }
      });
      dispatch(
        handleToast({
          severity: "neutral",
          translationKey: AppStrings.Toasts.custom.copy.text
        })
      );
    }
  };

  return {
    actionValidationToastText,
    sendRentalApplicationLoadingStatus,
    sendRentalApplicationReminderLoadingStatus,
    didPressSendRentalApplicationReminder,
    didPressCopyLink,
    didPressSendRentalApplication,
    isLoading,
    error
  };
};
