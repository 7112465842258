import React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import defaultTheme from "../../styles/defaultTheme";
import Header from "./AppBars/appBarContainer";
import Footer from "./footer";

interface IAppLayoutProps extends RouteComponentProps {
  children: any;
}

const AppLayout: React.FC<IAppLayoutProps> = (props: IAppLayoutProps) => (
  <ThemeProvider theme={defaultTheme}>
    <Header />
    <Grid container xs={12}>
      <Grid item xs={12}>
        {props.children}
      </Grid>
    </Grid>
    <Footer />
  </ThemeProvider>
);

export default withRouter(AppLayout);
