import React from "react";
import { Grid } from "@material-ui/core";
import type { FormActionButtonsProps } from "DLUI/actionButtons/formActionButtons";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { Icon } from "DLUI/icon";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import Text from "DLUI/text/text";
import { View } from "DLUI/view";
import { InformationIcon2 } from "assets/.";
import type { ViewComponentProps } from "DLUI/view/view";

interface ComponentProps {
  title: string | React.ReactNode;
  subTitle: string | React.ReactNode;
  actionButtons: FormActionButtonsProps;
  icon?: React.ReactNode;
}

export const GenericConfirmation = ({
  title,
  subTitle,
  actionButtons,
  icon,
  ...viewProps
}: ComponentProps & Exclude<ViewComponentProps, ComponentProps>) => (
  <View height={"100%"} justifyContent="space-between" {...viewProps}>
    <View paddingLeft={25} paddingRight={25} paddingBottom={25} paddingTop={25}>
      <Grid container spacing={2}>
        {/* Left - Icon */}
        <Grid item xs={"auto"}>
          <View
            width={60}
            height={60}
            backgroundColor="secondary-gray-light"
            borderRadius={5}
            alignItems="center"
            justifyContent="center"
          >
            {icon ?? <Icon Source={InformationIcon2} pathColor="light-gray" width={35} height={35} />}
          </View>
        </Grid>
        {/* Right - Texts */}
        <Grid item container direction="column" xs>
          {typeof title === "string" ? <Text fontFamily="Roboto Slab" value={title} bold fontSize={16} /> : title}
          <HorizontalSeparationLine marginTop={5} height={2} />
          {typeof subTitle === "string" ? (
            <Text marginTop={10} value={subTitle} fontSize={14} color="secondary-gray" />
          ) : (
            subTitle
          )}
        </Grid>
      </Grid>
    </View>
    {/* Action Buttons */}
    <FormActionButtons {...actionButtons} />
  </View>
);
