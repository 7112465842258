import type { ComponentProps, Ref } from "react";
import React, { useMemo, useState } from "react";
import type { View } from "DLUI/view";
import Grid from "@material-ui/core/Grid";
import type { FormikProps } from "formik";
import { FastField, getIn } from "formik";
import { TextField } from "DLUI/form";
import { useTranslation } from "react-i18next";
import AppStrings from "locale/keys";
import type { GeneralEntryDto } from "@doorloop/dto";
import { DuplicateDialogButtonLabelValues } from "@doorloop/dto";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import { ListItemInputsContainer } from "DLUI/listItems";
import { unitsApi } from "api/unitsApi";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";
import { useAnalyticsService } from "hooks/useAnalyticsService";

interface GeneralEntryLineProps extends Pick<ComponentProps<typeof View>, "domRef"> {
  lineIndex: number;
  didPressDeleteButton: (lineIndex: number) => void;
  didPressDuplicateButton?: (lineIndex: number) => void;
  formikRef: FormikProps<any>;
  onInputChange: () => void;
  domRef?: Ref<HTMLDivElement>;
}

const GeneralEntryLine: React.FC<GeneralEntryLineProps> = ({
  domRef,
  lineIndex,
  didPressDeleteButton,
  didPressDuplicateButton,
  formikRef,
  onInputChange
}: GeneralEntryLineProps) => {
  const { t } = useTranslation();
  const [renderDebitField, setRenderDebitField] = useState<boolean>(true);
  const [renderCreditField, setRenderCreditField] = useState<boolean>(true);
  const { dispatchAnalytics } = useAnalyticsService();

  const accountDefaultValue = useMemo(() => {
    const values = formikRef.values as GeneralEntryDto;
    if (values && values.lines && values.lines[lineIndex]) {
      return values.lines[lineIndex].account;
    }
    return undefined;
  }, []);

  const _didPressDeleteButton = () => {
    didPressDeleteButton(lineIndex);
  };

  const _didPressDuplicateButton = () => {
    didPressDuplicateButton?.(lineIndex);
    dispatchAnalytics("button_click", {
      label: DuplicateDialogButtonLabelValues.DUPLICATE_LINE_ITEM
    });
  };

  const didChangeCreditField = () => {
    const currentFieldValue = getIn(formikRef.values, `lines[${lineIndex}].debit`);
    if (currentFieldValue && currentFieldValue > 0) {
      formikRef.setFieldValue(`lines[${lineIndex}].debit`, 0);
      refreshDebitField();
    }
    onInputChange();
  };

  const didChangeDebitField = () => {
    const currentFieldValue = getIn(formikRef.values, `lines[${lineIndex}].credit`);
    if (currentFieldValue && currentFieldValue > 0) {
      formikRef.setFieldValue(`lines[${lineIndex}].credit`, 0);
      refreshCreditField();
    }
    onInputChange();
  };

  const refreshDebitField = () => {
    setRenderDebitField(false);
    setTimeout(() => {
      setRenderDebitField(true);
    }, 0);
  };
  const refreshCreditField = () => {
    setRenderCreditField(false);
    setTimeout(() => {
      setRenderCreditField(true);
    }, 0);
  };

  return (
    <ListItemInputsContainer
      onClickDeleteButton={_didPressDeleteButton}
      onClickDuplicateButton={_didPressDuplicateButton}
      domRef={domRef}
    >
      <Grid item xs={12} sm={3}>
        <BankAccountFormikAutoCompleteField
          addCreateOption
          queryParams={{ filter_active: true }}
          uniqueIndex={"account"}
          name={`lines[${lineIndex}].account`}
          label={t(AppStrings.Common.ListHeader.Category)}
          onChange={onInputChange}
          defaultValue={accountDefaultValue}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormikCachedAsyncAutoComplete
          label={t(AppStrings.Common.FilterOptions.Unit)}
          uniqueIndex={"unit"}
          apiHandler={unitsApi}
          displayNameKey={"name"}
          filterFieldName={"filter_text"}
          filterFieldValue={"name"}
          selectionFields={["id", "class"]}
          name={"unit"}
          disabled={!formikRef.values.property}
          queryParams={{ filter_active: true, filter_property: formikRef.values.property }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FastField
          component={TextField}
          label={t(AppStrings.Leases.LeaseCharge.Description)}
          name={`lines[${lineIndex}].memo`}
          onBlur={onInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        {renderDebitField && (
          <FastField
            label={t(AppStrings.OtherTransactions.GeneralEntry.Debits)}
            component={TextField}
            name={`lines[${lineIndex}].debit`}
            formatType={"currency"}
            onBlur={didChangeDebitField}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={3}>
        {renderCreditField && (
          <FastField
            label={t(AppStrings.Leases.NewLease.LeaseRent.Credits)}
            component={TextField}
            name={`lines[${lineIndex}].credit`}
            formatType={"currency"}
            onBlur={didChangeCreditField}
          />
        )}
      </Grid>
    </ListItemInputsContainer>
  );
};

export default GeneralEntryLine;
