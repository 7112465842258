import type { HasTenantsAutoPayments, MerchantAccountDto, ObjectPermission } from "@doorloop/dto";
import {
  DisplayEmailDto,
  DisplayPhoneDto,
  EmailType,
  LinkedResourceDto,
  LinkedResourceType,
  PhoneType,
  PortalInfoDto,
  SegmentEventTypes
} from "@doorloop/dto";
import type { ApiResult } from "api/apiResult";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";

import type { FormikProps } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import type { CSSProperties } from "react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import AnimatedContent, { getAnimatedContentFormikRef } from "./components/animatedContent";
import DialogFrame from "./components/dialogFrame";
import Dialog from "./dialog";
import { DialogRoutes } from "DLUI/screen/dialogsProvider";
import { useParamObjectId } from "hooks/useParam";
import type { SVGIconComponent } from "assets/icons/types";
import type { Operation, OperationsBuilder } from "engines/bulkOperationsEngine";
import { BulkExecutionDialog } from "DLUI/bulkExecution/bulkExecutionDialog";
import { checkbookApi } from "api/checkbookApi";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { usePermission } from "screens/settings/userRoles/usePermission";
import { v4 as uuid } from "uuid";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { analyticsService } from "../../../services/analyticsService";
import type { ApiToastsProps } from "api/apiHelper";
import type { Method } from "axios";
import type { ObjectWithHiddenForUserTypes } from "../../../utils/userTypesUtils";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";

export const DefaultDialogHeight = 700;
const DefaultDialogWidth = Math.min(window.innerWidth, 962);

export interface MenuItem extends ObjectWithHiddenForUserTypes {
  title: string;
  menuItemTitle?: string;
  isSelected?: boolean;
  icon: SVGIconComponent;
  clearances?: AnyPermissionClearance[];
  contentComponent: (
    formikRef: FormikProps<any>,
    onFileReceive?: (receivedFile: File) => void,
    onFileDelete?: () => void,
    onCompanyLogoReceive?: (receivedFile: File) => void,
    onCompanyLogoDelete?: () => void,
    onCoverImageFileReceive?: (receivedFile: File) => void,
    onCoverImageFileDelete?: () => void,
    merchantAccount?: MerchantAccountDto,
    transunionEligibilityMessage?: string,
    transunionFee?: number,
    hasTenantsAutoPayments?: HasTenantsAutoPayments,
    landlordId?: number,
    transunionPropertyId?: number
  ) => any;
  contentComponentParentStyle?: CSSProperties;
  uploadFile?: boolean;
  category?: string;
  isMandatory?: boolean;
  dtoName?: string;
}

interface ComponentProps<T> {
  onClose: (values?: any, shouldRefresh?: boolean) => void;
  className?: string;
  disableBackdropClick?: boolean;
  dialogState: DialogState;
  loadingText?: string;
  errorText?: string;
  successText?: string;
  onRetryButtonPress?: (formikRef?: FormikProps<any>) => void;
  onBackdropClick?: () => void;
  showCloseIcon?: boolean;
  refreshEvent?: () => void;
  sectionItems: MenuItem[];
  getformikInitialValues: () => any;
  formikValidation?: (values: any) => any;
  validationMethod: (formikRef: FormikProps<any>) => Promise<{ isValid: boolean; errorStepIndex?: number }>;
  apiMethod: any;
  toasts?: Partial<Record<Method, ApiToastsProps<T>>>;
  dialogTitle: string;
  dialogHeight?: number;
  dialogWidth?: number;
  type?: "newAccount";
  paperClassName?: string;
  permission?: ObjectPermission;
  onDataLoad?: (data: any) => void;
  bulkOperationsBuilder?: OperationsBuilder;
  apiObjectId?: string;
}

let formikGlobalRef: FormikProps<any> | null = null;

const DialogViews = {
  LoadingView: 0,
  DialogForm: 1
};

export function AnimatedContentDialog<T>({
  onClose,
  className,
  errorText,
  onBackdropClick,
  refreshEvent,
  sectionItems,
  getformikInitialValues,
  formikValidation,
  validationMethod,
  apiMethod,
  toasts,
  dialogTitle,
  dialogHeight,
  type,
  dialogWidth,
  paperClassName,
  permission,
  onDataLoad,
  bulkOperationsBuilder,
  apiObjectId
}: ComponentProps<T>) {
  const location = useLocation();
  const { t } = useTranslation();

  const paramObjectId = apiObjectId || useParamObjectId(apiMethod);
  let editMode: boolean = Boolean(paramObjectId) || location.pathname.indexOf(DialogRoutes.EDIT_OUTGOING_PAYMENTS) > -1;
  if (location.pathname.indexOf(DialogRoutes.GLOBAL_NEW) > -1) {
    editMode = false;
  }

  const disablePersonDetailsValidation = useSelector<IRootState>(
    (state) => state.auth.currentLoginResponse?.currentDbTenant.disablePersonDetailsValidation
  );

  const [currentMenuItemTitle, setCurrentMenuItemTitle] = useState<string>("");
  const [viewIndex, setViewIndex] = useState(editMode ? DialogViews.LoadingView : DialogViews.DialogForm);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);
  const [currentRetryMethod, setCurrentRetryMethod] = useState<"loadData" | "updateData" | "create">("loadData");

  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>(
    errorText || t(AppStrings.Common.GeneralError)
  );

  const [currentSelectedMenuIndex, setCurrentSelectedMenuIndex] = useState<number | undefined>();
  const [pendingFile, setPendingFile] = useState<File | undefined>();
  const [dialogData, setDialogData] = useState<undefined | any>();
  const [deleteImageOnSave, setDeleteImageOnSave] = useState<boolean>(false);
  const [onBulkExecutionComplete, setOnBulkExecutionComplete] = useState<(() => void) | undefined>();
  const [showBulkExecution, setShowBulkExecution] = useState<boolean>(false);
  const [bulkActions, setBulkActions] = useState<Operation[]>([]);
  const isNonREST = apiMethod === checkbookApi;
  const { hasAnyPermission } = usePermission();
  const permittedSectionItems = useMemo(
    () => sectionItems.filter(({ clearances }) => hasAnyPermission(clearances)),
    [sectionItems]
  );

  useEffect(() => {
    if (editMode || isNonREST) {
      loadPersonData();
    }
    return () => {
      formikGlobalRef = null;
    };
  }, [disablePersonDetailsValidation]);

  const loadPersonData = async () => {
    if (apiMethod.getDetails) {
      setCurrentRetryMethod("loadData");
      setViewIndex(DialogViews.LoadingView);
      setLoadingDialogState(DialogState.Show);
      const results = (await apiMethod.getDetails(paramObjectId).catch((e: string) => {
        setLoadingDialogErrorText(e);
        setLoadingDialogState(DialogState.Error);
      })) as ApiResult<any>;
      if (results && results.status) {
        const nextDialogData = _.cloneDeep(results.data);
        if (!nextDialogData.portalInfo) {
          nextDialogData.portalInfo = new PortalInfoDto();
        }
        if (nextDialogData.phones) {
          _.forEach(nextDialogData.phones, (currentItem) => {
            currentItem["uniqueIndex"] = uuid();
          });

          if (nextDialogData.portalInfo.mobilePhone) {
            const isPortalPhoneExistsInPhoneArray =
              nextDialogData.phones?.findIndex((phone) => phone.number === nextDialogData.portalInfo.mobilePhone) > -1;

            if (!isPortalPhoneExistsInPhoneArray) {
              const emptyPhoneIndex = nextDialogData.phones?.findIndex((phone) => !phone.number);
              if (emptyPhoneIndex >= 0) {
                nextDialogData.phones[emptyPhoneIndex].number = nextDialogData.portalInfo.mobilePhone;
              } else {
                nextDialogData.phones.unshift(
                  new DisplayPhoneDto({
                    type: PhoneType.MOBILE,
                    number: nextDialogData.portalInfo.mobilePhone
                  })
                );
              }
            }
          } else {
            nextDialogData.portalInfo.mobilePhone = nextDialogData.phones?.find(
              (phone) => phone.type === PhoneType.MOBILE
            )?.number;
          }
        }
        if (nextDialogData.emails?.length > 0) {
          _.forEach(nextDialogData.emails, (currentItem) => {
            currentItem["uniqueIndex"] = uuid();
          });
        } else {
          nextDialogData.emails = [];
        }
        if (nextDialogData.portalInfo.loginEmail) {
          const isLoginEmailExistsInEmailArray =
            nextDialogData.emails?.findIndex((email) => email.address === nextDialogData.portalInfo.loginEmail) > -1;

          if (!isLoginEmailExistsInEmailArray) {
            const emptyEmailIndex = nextDialogData.emails?.findIndex((x) => !x.address);
            if (emptyEmailIndex >= 0) {
              nextDialogData.emails[emptyEmailIndex].address = nextDialogData.portalInfo.loginEmail;
            } else {
              nextDialogData.emails.unshift(
                new DisplayEmailDto({
                  type: EmailType.PRIMARY,
                  address: nextDialogData.portalInfo.loginEmail,
                  uniqueIndex: uuid()
                })
              );
            }
          }
        } else {
          nextDialogData.portalInfo.loginEmail = nextDialogData.emails?.[0]?.address;
        }

        if (nextDialogData.emergencyContacts) {
          _.forEach(nextDialogData.emergencyContacts, (currentItem) => {
            currentItem["shouldShow"] = true;
          });
        }
        nextDialogData.disablePersonDetailsValidation = disablePersonDetailsValidation;
        setDialogData(nextDialogData);
        onDataLoad && onDataLoad(nextDialogData);
        setViewIndex(DialogViews.DialogForm);
      } else {
        setLoadingDialogErrorText(results.message);
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const didFinishOperation = (values?: any) => {
    onClose(values);
    setTimeout(() => {
      setViewIndex(0);
      if (refreshEvent) {
        refreshEvent();
      }
    }, 500);
  };

  const _onBackdropClick = () => {
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const renderActionPanelButtons = (formik: FormikProps<any>) => {
    formikGlobalRef = formik;

    return (
      <FormActionButtons
        propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
        propsSubButton={{ onClick: _onBackdropClick }}
      />
    );
  };

  const onMenuItemSelection = (menuItemTitle: string, itemIndex?: number) => {
    setCurrentSelectedMenuIndex(itemIndex);
    setCurrentMenuItemTitle(menuItemTitle);
  };

  const didPressDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(DialogViews.DialogForm);
    analyticsService.track(
      SegmentEventTypes.RECEIVE_PAYMENTS_NEW_ACCOUNT_CTA_CLICKED,
      {
        action: "dismiss",
        type
      },
      { trackEventInIntercom: true }
    );
  };

  const getFormikInitialValues = () => {
    if (formikGlobalRef && formikGlobalRef.values) {
      return formikGlobalRef.values;
    }
    if ((editMode || isNonREST) && dialogData) {
      return dialogData;
    }

    const initialValues = getformikInitialValues();

    initialValues.disablePersonDetailsValidation = disablePersonDetailsValidation;

    return initialValues;
  };

  const didPressRetryButton = () => {
    switch (currentRetryMethod) {
      case "loadData":
        loadPersonData();
        break;
      case "create":
        create();
        break;
      case "updateData":
        updatePersonData();
        break;
    }
  };

  const renderView = ({ index }: any) => {
    if (index === DialogViews.LoadingView) {
      return (
        <>
          {bulkOperationsBuilder && showBulkExecution ? (
            <BulkExecutionDialog
              operations={bulkActions}
              onFinish={onBulkExecutionComplete}
              closeHandler={() => setShowBulkExecution(false)}
            />
          ) : null}
          <LoadingDialog
            dialogState={loadingDialogState}
            errorText={loadingDialogErrorText}
            onRetryButtonPress={didPressRetryButton}
            didPressDismissButton={didPressDismissButton}
          />
        </>
      );
    }

    if (index === DialogViews.DialogForm) {
      return (
        <AnimatedContent
          formikInitialValues={getFormikInitialValues}
          sectionItems={permittedSectionItems}
          formikValidation={formikValidation}
          dialogHeight={dialogHeight || DefaultDialogHeight}
          renderActionPanelButtons={renderActionPanelButtons}
          onFileReceive={onFileReceive}
          onFileDelete={onFileDelete}
          onMenuItemSelection={onMenuItemSelection}
          selectedMenuIndex={currentSelectedMenuIndex}
          type={type}
        />
      );
    }
    return <div />;
  };

  const updatePersonData = async () => {
    const formikRef = getAnimatedContentFormikRef();
    if (formikRef !== null) {
      setCurrentRetryMethod("updateData");
      setLoadingDialogState(DialogState.Show);
      setViewIndex(DialogViews.LoadingView);
      const response = (await apiMethod
        .update(formikRef.values.id, formikRef.values, toasts?.PUT)
        .catch((e: string) => {
          setLoadingDialogErrorText(e);
          setLoadingDialogState(DialogState.Error);
        })) as ApiResult<any>;

      if (response.status) {
        const resourceId = response.data.id;
        const closeDialogAndFireFinish = () => {
          setShowBulkExecution(false);
          didFinishOperation(response.data);
          setLoadingDialogState(DialogState.Hidden);
          formikGlobalRef = null;
        };
        const startBulkExecution = () => {
          if (bulkOperationsBuilder) {
            setBulkActions(bulkOperationsBuilder(resourceId));
            setOnBulkExecutionComplete(() => closeDialogAndFireFinish);
            setShowBulkExecution(true);
          }
        };
        if (pendingFile) {
          await uploadFile(resourceId, onClose);
        } else {
          if (deleteImageOnSave) {
            await apiMethod.deletePicture(resourceId);
          }
          if (bulkOperationsBuilder) {
            startBulkExecution();
          } else {
            closeDialogAndFireFinish();
          }
        }
      } else {
        setLoadingDialogErrorText(response.message);
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const didPressSaveButton = async () => {
    if (formikGlobalRef !== null) {
      const validationResult = await validationMethod(formikGlobalRef);
      if (validationResult.isValid) {
        setTimeout(() => {
          if (editMode) {
            updatePersonData();
          } else {
            create();
          }
        }, 0);
      } else if (validationResult.errorStepIndex !== undefined) {
        setCurrentSelectedMenuIndex(validationResult.errorStepIndex);
      }
    }
  };

  const uploadFile = async (id: string, onClose?: (values?: any) => void) => {
    setLoadingDialogState(DialogState.Show);
    const LinkedResource = new LinkedResourceDto(id, LinkedResourceType.Tenant);

    const response = (await apiMethod.uploadPicture(pendingFile, LinkedResource, id).catch((error: string) => {
      formikGlobalRef!.setSubmitting(false);
      setLoadingDialogErrorText(error);
      setLoadingDialogState(DialogState.Error);
    })) as ApiResult<any>;

    if (response && response.status) {
      setLoadingDialogState(DialogState.Hidden);
      formikGlobalRef = null;
      if (onClose) {
        onClose(response.data);
      }
    } else {
      formikGlobalRef!.setSubmitting(false);
      setLoadingDialogErrorText(response.message);
      setLoadingDialogState(DialogState.Error);
    }
  };

  const create = async () => {
    if (formikGlobalRef !== null) {
      setCurrentRetryMethod("create");
      setLoadingDialogState(DialogState.Show);
      setViewIndex(DialogViews.LoadingView);
      analyticsService.track(SegmentEventTypes.ANIMATED_CONTENT_DIALOG_CTA_CLICKED, {
        action: "create",
        type,
        data: formikGlobalRef.values
      });

      const response = (await apiMethod.create(formikGlobalRef.values, toasts?.POST).catch((error: string) => {
        formikGlobalRef!.setSubmitting(false);
        setLoadingDialogErrorText(error);
        setLoadingDialogState(DialogState.Error);
      })) as ApiResult<any>;

      if (response && response.status) {
        const resourceId = response.data.id;
        const closeDialogAndFireFinish = () => {
          setShowBulkExecution(false);
          setLoadingDialogState(DialogState.Hidden);
          didFinishOperation(response.data);
          formikGlobalRef = null;
        };
        const startBulkExecution = () => {
          if (bulkOperationsBuilder) {
            setBulkActions(bulkOperationsBuilder(resourceId));
            setOnBulkExecutionComplete(() => closeDialogAndFireFinish);
            setShowBulkExecution(true);
          }
        };
        if (pendingFile) {
          await uploadFile(resourceId, onClose);
        }
        if (bulkOperationsBuilder) {
          startBulkExecution();
        } else {
          closeDialogAndFireFinish();
        }
      } else {
        formikGlobalRef!.setSubmitting(false);
        setLoadingDialogErrorText(response.message);
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const onFileReceive = (receivedFile: File) => {
    formikGlobalRef?.setFieldValue("pictureUrl", URL.createObjectURL(receivedFile));
    setPendingFile(receivedFile);
    setDeleteImageOnSave(false);
  };

  const onFileDelete = () => {
    formikGlobalRef?.setFieldValue("pictureUrl", undefined);
    setPendingFile(undefined);
    if (editMode) {
      setDeleteImageOnSave(true);
    }
  };

  const emptyCallback = () => {};

  const frameType = useMemo(() => {
    if (viewIndex === DialogViews.LoadingView) {
      return "contentOnly";
    }
    return "sideMenu";
  }, [viewIndex]);

  return (
    <Dialog className={className} open onClose={emptyCallback} disableBackdropClick paperClassName={paperClassName}>
      <DialogFrame
        useExperimentalDialogFrame
        onCloseButtonClick={_onBackdropClick}
        width={dialogWidth || DefaultDialogWidth}
        height={dialogHeight || DefaultDialogHeight}
        renderView={renderView}
        numViews={2}
        activeView={viewIndex}
        frameType={frameType}
        title={dialogTitle}
        sectionTitle={currentMenuItemTitle}
        clearance={
          permission
            ? {
                permission,
                field: editMode ? "edit" : "create"
              }
            : undefined
        }
      />
    </Dialog>
  );
}
