import { View } from "DLUI/view";
import Text from "DLUI/text";
import React from "react";
import { useTranslation } from "react-i18next";
import AppStrings from "locale/keys";
import { useResponsiveHelper } from "../../../../../../contexts/responsiveContext";

interface SectionHeaderProps {
  title: string;
}

export const SectionHeader = ({ title }: SectionHeaderProps) => {
  const { isMobile } = useResponsiveHelper();
  const { t } = useTranslation();
  return (
    <View height={60} alignItems={"center"} flexDirection={"row"}>
      <View flex={2}>
        <Text fontWeight={700}>{title}</Text>
      </View>
      {!isMobile && (
        <>
          <View alignItems={"center"} flex={1}>
            <Text value={AppStrings.UserRoles.ViewList} color={"gray"} />
          </View>
          <View alignItems={"center"} flex={1}>
            <Text value={AppStrings.UserRoles.ViewOne} color={"gray"} />
          </View>
          <View alignItems={"center"} flex={1}>
            <Text value={AppStrings.UserRoles.Create} color={"gray"} />
          </View>
          <View alignItems={"center"} flex={1}>
            <Text value={AppStrings.UserRoles.Edit} color={"gray"} />
          </View>
          <View alignItems={"center"} flex={1}>
            <Text value={AppStrings.UserRoles.Delete} color={"gray"} />
          </View>
        </>
      )}
    </View>
  );
};
