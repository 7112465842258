import type { PersonOutgoingEPayInfoDto } from "@doorloop/dto";
import type { FC } from "react";
import React, { useMemo } from "react";
import { usersApi } from "api/usersApi";
import moment from "moment";
import { DateFormats } from "@doorloop/dto";
import { View } from "DLUI/view";
import { Icon } from "DLUI/icon";
import { GreenRefreshArrowsIcon } from "assets/icons";
import Text from "DLUI/text";
import AppStrings from "locale/keys";

interface UpdateNotificationProps {
  ePayValues: PersonOutgoingEPayInfoDto;
}

export const UpdateNotification: FC<UpdateNotificationProps> = ({ ePayValues }: UpdateNotificationProps) => {
  const notificationData = useMemo(() => {
    const userDictionary = ePayValues.updatedByUser ? usersApi.getItemFromDictionary(ePayValues.updatedByUser) : {};
    return {
      updatedBy: ePayValues.updatedByUser ? userDictionary?.name : "",
      updatedOn: moment(ePayValues.updatedAt).format(DateFormats.SHORT_DATE),
      updatedFrom: ePayValues.updatedByIp || ""
    };
  }, [ePayValues.updatedByUser, ePayValues.updatedAt, ePayValues.updatedByIp]);

  return notificationData.updatedFrom && notificationData.updatedBy ? (
    <View
      borderRadius={7}
      backgroundColor={"light-green"}
      justifyContent={"center"}
      flexDirection={"row"}
      alignItems={"center"}
      height={50}
      noWrap
      paddingLeft={10}
      paddingRight={10}
    >
      <Icon marginLeft={10} Source={GreenRefreshArrowsIcon} marginRight={10} />
      <Text
        fontSize={14}
        value={AppStrings.Common.OutgoingPayments.LastUpdatedByOnFrom}
        color={"green"}
        replaceObject={notificationData}
        maxLines={"2"}
        lineBreak={"auto"}
        align={"center"}
        fullWidth
        lineHeight={"20px"}
      />
    </View>
  ) : null;
};
