import React, { useCallback, useMemo, useState } from "react";
import { WizardScreen } from "DLUI/screen/wizard/wizardScreen";
import AppStrings from "locale/keys";
import { DeliveryMethod } from "screens/announcements/newAnnouncement/deliveryMethod/deliveryMethod";
import type { AnnouncementReviewDto } from "@doorloop/dto";
import {
  AnnouncementDto,
  ClientAnnouncementDto,
  LinkedResourceType,
  ObjectPermission,
  SegmentEventTypes
} from "@doorloop/dto";
import { AnnouncementContent } from "screens/announcements/newAnnouncement/announcementContent";
import { ReviewAnnouncement } from "screens/announcements/newAnnouncement/review/reviewAnnouncement";
import { announcementsApi } from "api/announcementsApi";
import type { FileListItemProps } from "DLUI/dropZone";
import { filesApi } from "api/filesApi";
import { SelectRecipients } from "screens/announcements/newAnnouncement/selectRecipients/selectRecipients";
import {
  newAnnouncementStepValidators,
  validateNewAnnouncementForm
} from "screens/announcements/newAnnouncement/stepValidations";
import { ProgressDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import Dialog from "DLUI/dialogs/dialog";
import { SuccessDialog } from "screens/announcements/newAnnouncement/successDialog";
import clsx from "clsx";
import { NavigationManager } from "utils/navigation";
import { QueryParams } from "utils/queryParams";
import { useTranslation } from "react-i18next";
import { analyticsService } from "../../../../services/analyticsService";

const { firstStepValidator, secondStepValidator, thirdStepValidator } = newAnnouncementStepValidators;

const sum: (...values: Array<number | undefined>) => number = (...values) =>
  values.reduce<number>((acc, item = 0) => acc + item, 0);

const MAX_CHARS_DEFAULT = 10000;
const MAX_CHARS_SMS = 140;
const MAX_CHARS_VOICE = 300;

export const NewAnnouncementScreen = () => {
  const { t } = useTranslation();

  const [reviewData, setReviewData] = useState<AnnouncementReviewDto | null>(null);
  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);
  const [error, setError] = useState<string>("");
  const [deliveryMethodErrorMessageAppString, setDeliveryMethodErrorMessageAppString] = useState<string>("");
  const [createdAnnouncementId, setCreatedAnnouncementId] = useState<string | null>(null);
  const [bodyTooLongError, setBodyTooLongError] = useState<boolean>(false);

  const fireEvent = (dto: AnnouncementDto) => {
    analyticsService.track(
      SegmentEventTypes.COMMUNICATIONS_ANNOUNCEMENT_SENT,
      {
        numberOfProperties: dto.properties?.length || 0,
        numberOfUnits: dto.units?.length || 0,
        numberOfTenants: dto.tenants?.length || 0,
        deliveryMethod_email: Boolean(dto.emailInfo?.send),
        deliveryMethod_textMessage: Boolean(dto.textInfo?.send),
        deliveryMethod_voiceMessage: Boolean(dto.voiceInfo?.send),
        deliveryMethod_TenantPortal: Boolean(dto.portalInfo?.send),
        postOnHomeScreen: Boolean(dto.portalInfo?.pinToHome),
        removeFromHomeScreen: Boolean(dto.portalInfo?.expiresOn),
        attachment: attachments.length > 0
      },
      { trackEventInIntercom: true }
    );
  };

  const viewAnnouncement = useCallback(() => {
    if (createdAnnouncementId) {
      const redirectUrl = NavigationManager.getRedirectUrlIfAny();
      if (redirectUrl) {
        NavigationManager.goToRedirectUrl(redirectUrl);
        return;
      }

      NavigationManager.viewAnnouncementDetails(createdAnnouncementId);
    }
  }, [createdAnnouncementId]);

  const onFinish = useCallback(
    async (formikRef) => {
      const dto = formikRef.values;
      const { data, status, message } = await announcementsApi.create(dto);

      if (data && status) {
        const announcementId = data?.id;

        if (announcementId) {
          fireEvent(dto);

          await filesApi.uploadFiles(attachments, announcementId, LinkedResourceType.Announcement);
          await announcementsApi.broadcastAnnouncement(announcementId);
          setCreatedAnnouncementId(announcementId);
        }
      } else {
        setError(message || AppStrings.Common.GeneralError);
      }
    },
    [attachments]
  );

  const onFinishValidator = useCallback(async () => {
    const { emails, texts, phoneCalls, portalMessages } = reviewData || {};
    return sum(emails, texts, phoneCalls, portalMessages) > 0;
  }, [reviewData]);

  const initialActiveStep = useMemo(() => (QueryParams.get("tenantId") ? 1 : 0), []);

  const formInitialValues = useMemo(() => {
    const tenantId = QueryParams.get("tenantId");
    if (tenantId) {
      return new ClientAnnouncementDto({
        tenants: [tenantId]
      });
    }
    return new ClientAnnouncementDto();
  }, []);

  const steps = [
    {
      label: AppStrings.Announcements.propertiesUnits,
      Component: () => <SelectRecipients />,
      validator: firstStepValidator
    },
    {
      label: AppStrings.Announcements.deliveryMethod,
      Component: () => <DeliveryMethod errorMessageAppString={deliveryMethodErrorMessageAppString} />,
      validator: secondStepValidator(setDeliveryMethodErrorMessageAppString, t(AppStrings.Common.Validation.Required))
    },
    {
      label: AppStrings.Announcements.announcementContent,
      Component: () => (
        <>
          <AnnouncementContent
            attachments={attachments}
            setAttachments={setAttachments}
            bodyTooLongError={bodyTooLongError}
            maxCharsVoice={MAX_CHARS_VOICE}
            maxCharsSms={MAX_CHARS_SMS}
            maxCharsDefault={MAX_CHARS_DEFAULT}
          />
          {error && (
            <ProgressDialog
              dialogState={DialogState.Error}
              errorText={error}
              hideRetryButton
              onDismissButtonPress={() => setError("")}
            />
          )}
        </>
      ),
      validator: thirdStepValidator(setBodyTooLongError, {
        maxCharsVoice: MAX_CHARS_VOICE,
        maxCharsSms: MAX_CHARS_SMS,
        maxCharsDefault: MAX_CHARS_DEFAULT
      }),
      beforeNext: async ({ values }) => {
        const { recipientPropertiesType, ...rest } = values;
        const reviewDto = new AnnouncementDto(rest);
        const { data, status, message } = await announcementsApi.reviewAnnouncement(reviewDto);

        if (status && data) {
          setReviewData(data);
        } else {
          setError(message);
          return false;
        }

        return true;
      }
    },
    {
      label: AppStrings.Announcements.reviewAndConfirm,
      Component: () =>
        reviewData && (
          <>
            <ReviewAnnouncement reviewData={reviewData} />{" "}
            {error && (
              <ProgressDialog
                dialogState={DialogState.Error}
                errorText={error}
                hideRetryButton
                onDismissButtonPress={() => setError("")}
              />
            )}
          </>
        ),
      validator: onFinishValidator
    }
  ];

  return (
    <WizardScreen<ClientAnnouncementDto>
      initialActiveStep={initialActiveStep}
      steps={steps}
      onFinish={onFinish}
      title={{ text: AppStrings.Announcements.newAnnouncement }}
      subTitle={AppStrings.Announcements.newAnnouncement}
      formInitialValues={formInitialValues}
      validateForm={validateNewAnnouncementForm}
      clearance={{
        permission: ObjectPermission.announcements,
        field: "create"
      }}
    >
      <Dialog
        open={Boolean(createdAnnouncementId)}
        className={clsx(["DLUI_Dialog"])}
        onClose={viewAnnouncement}
        disableBackdropClick
      >
        <SuccessDialog onDoneClick={viewAnnouncement} />
      </Dialog>
    </WizardScreen>
  );
};
