import { DialogRoutes } from "./dialogsRoutes";
import type { DialogProps } from "./dialogsProvider.types";
import { FilePreviewDialog } from "screens/files/filePreviewDialog";

class FilesDialogsProvider {
  matchRoutes(pathname: string): DialogProps | undefined {
    if (pathname.includes(DialogRoutes.FILE_PREVIEW)) {
      return {
        type: "filePreview",
        Content: FilePreviewDialog
      };
    }

    return undefined;
  }
}

export const filesDialogsProvider = new FilesDialogsProvider();
