import React, { useEffect, useMemo, useState } from "react";
import {
  createValidator,
  FileDisplayDto,
  LinkedResourceDto,
  LinkedResourceType,
  ObjectPermission,
  TagType
} from "@doorloop/dto";
import type { ApiResult } from "api/apiResult";
import { filesApi } from "api/filesApi";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { LoadingDialog } from "DLUI/dialogs/components/loading";
import { TextField } from "DLUI/form";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, Formik } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import DialogFrame, { getDialogFrameDimension } from "../components/dialogFrame";
import { DialogsHelper } from "../dialogsHelper";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { ArticleIdsEnum, HelpTypeEnum } from "DLUI/screen/helpPanel/types";
import { tagsApi } from "api/tagsApi";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import Text from "DLUI/text";
import { unitsApi } from "api/unitsApi";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import CreateNewTagOptionForm from "DLUI/form/autoComplete/formikAsyncAutoComplete/createNewTagOptionForm";
import { TagIcon } from "../../../../assets";
import FormikCachedMultiSelectAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedMultiSelectAutoComplete";
import { useHistory } from "react-router";
import DeleteConfirmation from "DLUI/dialogs/components/deleteConfirmation";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import { Icon } from "DLUI/icon";
import { Link } from "DLUI/link";
import { useLinkedResource } from "../../../../hooks/useLinkedResource";
import ColorsEnum from "../../../../utils/colorsEnum";

interface ComponentProps {
  didFinishOperation?: (values: FileDisplayDto) => void;
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle?: string;
  linkedResource?: LinkedResourceType;
  linkedResourceId?: string;
  fileData?: FileDisplayDto;
  //TODO: Not in use. Remove?
  onDeleteItem?: (fileIndex) => void;
  hideRelatedTo?: boolean;
}

let formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

const helpObject: HelpPanelProps = {
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Files.Dialog.LearnAboutFiles,
      articleId: ArticleIdsEnum.WORKING_WITH_FILES
    }
  ]
};

const FileDialog: React.FC<ComponentProps> = ({
  didFinishOperation,
  onBackdropClick,
  onClose,
  dialogTitle,
  linkedResource,
  linkedResourceId,
  fileData: _fileData
}: ComponentProps) => {
  const { t } = useTranslation();
  const { location } = useHistory<FileDisplayDto | Record<string, any>>();
  const fileData = _fileData ?? (Object.keys(location?.state || {}).length > 0 ? location.state : undefined);
  const editMode = fileData !== undefined;
  const permission: AnyPermissionClearance = {
    permission: ObjectPermission.files,
    field: editMode ? "edit" : "create"
  };
  const localeData = useSelector((state: IRootState) => state.auth.currentLoginResponse);
  const [viewIndex, setViewIndex] = useState(0);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);
  const [createFileErrorText, setCreateFileErrorText] = useState<string>("");
  const [fileUploadError, setFileUploadError] = useState<string>();
  const [files, setFiles] = useState<FileListItemProps[]>([]);
  const { dialogHorizontalPadding } = DialogsHelper();

  useEffect(
    () => () => {
      formikGlobalRef = null;
    },
    []
  );

  const initFormvalues = (): FileDisplayDto => {
    const fileDto = fileData ? new FileDisplayDto(fileData) : new FileDisplayDto({ rank: 1 });

    const currentLinkedResourceType = fileData?.linkedResource?.resourceType || linkedResource;
    const currentLinkedResourceId = fileData?.linkedResource?.resourceId || linkedResourceId;

    if (currentLinkedResourceType && currentLinkedResourceId) {
      fileDto.linkedResource = new LinkedResourceDto(currentLinkedResourceId, currentLinkedResourceType);

      if (currentLinkedResourceType === LinkedResourceType.Unit && currentLinkedResourceId) {
        fileDto.unit = currentLinkedResourceId;
        fileDto.property = unitsApi.getItemFromDictionary(currentLinkedResourceId)?.property;
      } else if (currentLinkedResourceType === LinkedResourceType.Property && currentLinkedResourceId) {
        fileDto.property = currentLinkedResourceId;
      }
    }

    return fileDto;
  };

  const validateForm = createValidator(FileDisplayDto);

  const isValidForm = async (formikRef: FormikProps<FileDisplayDto>) => {
    formikRef.setFieldTouched("name", true);
    formikRef.setFieldTouched("property", true);

    const errors = (await formikRef.validateForm()) as any;
    const fileExists = files.length === 1;

    if (!fileExists && !editMode) {
      setFileUploadError(t(AppStrings.Files.Dialog.NoFileSelectedError));
    }

    return _.isEmpty(errors) && (fileExists || editMode);
  };

  const formInitialValues = initFormvalues();

  const createFileData = (values) => {
    if (values?.property) {
      delete values.property;
    }
    if (values?.unit) {
      delete values.unit;
    }

    return values;
  };

  const updateFile = async (values: FileDisplayDto) => {
    setViewIndex(1);
    setLoadingDialogState(DialogState.Show);
    const response = (await filesApi.update(values.id!, createFileData(values)).catch((err) => {
      setCreateFileErrorText(err.message);
      setLoadingDialogState(DialogState.Error);
    })) as ApiResult<any>;

    if (response.status) {
      setLoadingDialogState(DialogState.Success);
      didFinishOperation?.(values);
    } else {
      setCreateFileErrorText(response.message);
      setLoadingDialogState(DialogState.Error);
    }
  };

  const createFile = async (values: FileDisplayDto) => {
    setViewIndex(1);
    setLoadingDialogState(DialogState.Show);
    const [file] = files;

    if (values.linkedResource?.resourceId && file.fileData) {
      const newFile: File = file.fileData;
      const linkedResourceDto: LinkedResourceDto = new LinkedResourceDto(
        values.linkedResource?.resourceId,
        values.linkedResource?.resourceType
      );
      const createdBy = localeData?.firstName
        ? `${localeData?.firstName}${localeData?.lastName ? " " + localeData?.lastName : ""}`
        : undefined;
      const response = (await filesApi
        .upload(newFile, linkedResourceDto, "/", {
          ...(values?.notes ? { notes: values.notes } : {}),
          name: values.name,
          createdByName: createdBy,
          ...(values.tags ? { tags: values.tags as string[] } : {})
        })
        .catch((err) => {
          setCreateFileErrorText(err.message);
          setLoadingDialogState(DialogState.Error);
        })) as ApiResult<any>;

      if (response.status) {
        setLoadingDialogState(DialogState.Success);
        didFinishOperation?.(values);
      } else {
        setCreateFileErrorText(response.message || t(AppStrings.Files.Dialog.FileNotSupportedErrorMsg));
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const didPressSaveButton = async () => {
    if (formikGlobalRef !== null) {
      const isValid = await isValidForm(formikGlobalRef);

      if (isValid) {
        if (editMode) {
          await updateFile(formikGlobalRef.values);
        } else {
          await createFile(formikGlobalRef.values);
        }
      }
    }
  };

  const didPressDeleteFile = () => {
    if (fileData) {
      setViewIndex(2);
    }
  };

  const renderActionPanelButtons = () => {
    if (viewIndex === 1 || viewIndex === 2) {
      return <div />;
    }
    return (
      <RestrictedPermissionAccess clearance={permission}>
        <FormActionButtons
          propsActionPanel={{
            editMode: Boolean(fileData),
            customDeleteText: AppStrings.Common.Delete,
            onDeleteButtonPress: editMode ? didPressDeleteFile : undefined
          }}
          propsSubButton={{ onClick: onBackdropClick }}
          propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
        />
      </RestrictedPermissionAccess>
    );
  };

  const didPressDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(0);
  };

  const onRetryButtonPress = () => {
    if (formikGlobalRef !== null) {
      updateFile(formikGlobalRef.values);
    }
  };

  const handleFileReceived = (files: FileListItemProps[]) => {
    if (!fileData) {
      setFiles(files);
      setFileUploadError("");
    }
  };

  const renderAttachments = () => (
    <View justifyContent={"flex-end"} width={"100%"} flex={1} marginTop={10} marginBottom={20}>
      <FormAttachments
        resourceId={fileData?.id}
        editMode={editMode}
        onFileReceived={handleFileReceived}
        files={files}
        setFiles={setFiles}
        getAttachmentsByLinkedResource={false}
        allowDelete={!editMode}
        error={fileUploadError}
        maxFiles={1}
      />
      {Boolean(fileUploadError) && (
        <Text
          value={fileUploadError}
          fontSize={12}
          fontWeight={400}
          lineHeight={"14px"}
          marginTop={5}
          paddingLeft={15}
          color={"error"}
        />
      )}
    </View>
  );

  const renderReadOnlyRelatedToFields = () => {
    const _linkedResourceType = linkedResource || fileData?.linkedResource.resourceType;
    const _linkedResourceId = linkedResourceId || fileData?.linkedResource.resourceId;
    const hrefSuffix = "/files";

    const { linkedResourceIcon, linkedResourceText, linkedResourceHref, linkedResourceName } = useLinkedResource(
      new LinkedResourceDto(_linkedResourceId, _linkedResourceType),
      hrefSuffix
    );

    return (
      <>
        <HorizontalSeparationLine marginTop={20} />
        <View>
          <Text marginTop={20} fontSize={14} bold value={AppStrings.Tasks.InternalTaskDialog.RelatedTo} />
          <View flexDirection={"row"} alignItems={"center"} marginTop={10}>
            {linkedResourceIcon && <Icon Source={linkedResourceIcon} size={19} pathColor={"light-blue"} />}
            {linkedResourceHref ? (
              <Link
                hrefUrl={linkedResourceHref}
                marginLeft={5}
                fontSize={14}
                type={"blank"}
                textColor={ColorsEnum.BrightBlue}
                underline={"always"}
              >
                {linkedResourceText}
              </Link>
            ) : (
              <Text marginLeft={5} fontSize={14} value={linkedResourceText} />
            )}
            {linkedResourceName && (
              <Text value={`(${t(linkedResourceName)})`} color={"secondary-gray"} marginLeft={2} fontSize={14} />
            )}
          </View>
        </View>
      </>
    );
  };

  const renderForm = () => (
    <Formik initialValues={formInitialValues} onSubmit={(values, { setSubmitting }) => {}} validate={validateForm}>
      {(formik) => {
        formikGlobalRef = formik;

        return (
          <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding}>
            <RestrictedPermissionAccess clearance={permission} showNoAccess>
              {renderAttachments()}

              <View shouldShow showAnimation={"fade-in"} hideAnimation={"fade-out"} flexDirection={"row"}>
                <View flexDirection={"row"} height={"100%"}>
                  <View flex={1}>
                    <FastField
                      component={TextField}
                      label={t(AppStrings.Common.Dropzone.FileName)}
                      name={"name"}
                      marginTop={20}
                      required
                    />
                  </View>
                </View>
              </View>
              <View flexDirection={"row"}>
                <FastField
                  component={TextField}
                  label={t(AppStrings.Leases.NewLease.LeaseRent.Description)}
                  name={`notes`}
                  multiline
                  rows="2"
                  marginTop={20}
                  multiLineSize={"normal"}
                  maxLength={1000}
                />
              </View>
              <FormikCachedMultiSelectAutoComplete
                uniqueIndex={"selectCategories"}
                apiHandler={tagsApi}
                displayNameKey={"name"}
                filterFieldName={"filter_text"}
                filterFieldValue={"name"}
                selectionFields={["id", "type"]}
                name={"tags"}
                label={t(AppStrings.Tags.SelectTags)}
                tagIcon={TagIcon}
                marginTop={20}
                defaultValue={fileData?.tags}
                errorLabelPaddingLeft={15}
                queryParams={{ filter_type: TagType.FILES_TAG }}
                CreateNewOptionComponent={CreateNewTagOptionForm}
                createNewOptionTitle={AppStrings.Tags.CreateNewTag}
                groupNameProp={"type"}
                createNewOptionType={TagType.FILES_TAG}
              />
              {editMode && renderReadOnlyRelatedToFields()}
            </RestrictedPermissionAccess>
          </View>
        );
      }}
    </Formik>
  );

  const onDeleteFail = (err) => {
    setCreateFileErrorText(err);
  };

  const renderView = ({ index }: any) => {
    if (index === 0) {
      return renderForm();
    }
    if (index === 1) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={
              editMode ? t(AppStrings.Common.Dropzone.UpdatingFile) : t(AppStrings.Common.Dropzone.LoadingFile)
            }
            errorText={createFileErrorText}
            successText={t(AppStrings.Notes.Dialog.CreateNoteSuccessText)}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
          />
        </View>
      );
    }
    if (index === 2) {
      return (
        <DeleteConfirmation
          deleteConfirmationText={t(AppStrings.Common.Dropzone.DeleteFileConfirmationText)}
          apiMethod={filesApi}
          didPressDismissButton={() => setViewIndex(0)}
          didFinishOperation={onClose}
          didFailOperation={onDeleteFail}
          transactionId={fileData?.id}
          attachments={[]}
        />
      );
    }
    return <div />;
  };

  const _onBackdropClick = () => {
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      return "sectionTitleFrame";
    }
    return "contentOnly";
  }, [viewIndex, loadingDialogState]);

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={dialogTitle}
      width={getDialogFrameDimension("width", viewIndex !== 2 || createFileErrorText ? 600 : 500)}
      height={getDialogFrameDimension("height", viewIndex !== 2 || createFileErrorText ? 800 : 500)}
      renderView={renderView}
      numViews={3}
      activeView={viewIndex}
      helpPanel={helpObject}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={frameType}
      disableEscapeExit
    />
  );
};

export default FileDialog;
