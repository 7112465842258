export default {
  metadata: {
    title: "Vendors",
    titleSingular: "Vendor"
  },
  creatable: {
    title: "Add New Vendor",
    buttonLabel: "Add New Vendor"
  },
  screen: {
    addNewVendor: "New Vendor",
    editVendor: "Edit Vendor",
    searchPlaceHolder: "Search vendors",
    emptydataAddNew: "Add your first Vendor",
    emptydataViewInstructionsText: "No Vendors Found",
    openBalance: "Open Balance",
    balance: "Balance",
    hasOpenBalance: "Has Open Balance",
    noOpenBalance: "No Open Balance",
    insuranceStatus: "Insurance Status",
    vendor: "Vendor",
    insuranceExpires: "Insurance Expires",
    pressToSelectVendor: "Click here to select a vendor",
    pressToSelectPayee: "Click here to select a payee",
    helpPanel: {
      overview: "Vendors Overview",
      vendorCategory: "How do I categorize vendors?",
      vendorManage: "Learn more about Vendor Management",
      createVendor: "Creating Vendors",
      description: "Add vendors to assign them maintenance requests, create work orders, and pay them."
    }
  },
  newVendor: {
    createNewVendor: "Create New Vendor",
    loadingText: "Creating vendor ...",
    errorText: "Something went wrong. Please try again later or contact us.",
    successText: "Vendor created successfully",
    services: "Services",
    classification: "Classification",
    insurance: "Insurance",
    properties: "Properties",
    federalTaxes: "Federal Taxes",
    electronicPayments: "Electronic Payments",
    vendorCategoriesDescription: "Select vendor categories",
    vendorClassificationsDescription: "Select vendor classifications",
    selectCategories: "Select Categories",
    selectClassifications: "Select Classifications",
    insuranceDescription: "What insurance does this vendor have?",
    provider: "Provider",
    policyNumber: "Policy Number",
    coverage: "Coverage",
    effectiveDate: "Effective Date",
    expirationDate: "Expiration Date",
    singlePropertyDescription: "This vendor can work on all my properties",
    specificPropertyDescription: "This vendor can work only on specific properties",
    selectPropertiesDescription: "Which properties does this vendor work at?",
    selectProperties: "Select Properties",
    dontSend1099Forms: "Do not send 1099 forms to this vendor",
    send1099Forms: "I want to send 1099 forms to this vendor",
    sameAsVendorAddress: "Same as vendor’s address",
    taxpayerName: "Taxpayer Name",
    taxpayerID: "Taxpayer ID",
    taxpayerIDType: "Taxpayer ID Type",
    useElectronicPayments: "I want to pay this vendor using Electronic Funds Transfer",
    dontUseElectronicPayments: "I don’t want to pay this vendor electronically, I will pay by check or another method.",
    deleteConfirmationText: "Are you sure you want to delete this vendor?",
    deleteLoadingText: "Deleting vendor...",
    deleteVendorSuccessText: "Payment was deleted successfully",
    insured: "Insured",
    notInsured: "Not Insured"
  },
  vendorDetails: {
    createBill: "Create Bill",
    createNewBill: "Create New Bill",
    addExpense: "Add Expense",
    payBill: "Pay Bills",
    createNewPayBill: "Pay New Bill",
    addCredit: "Add Credit",
    createNewAddCredit: "Add New Credit",
    newBill: "New Vendor Bill",
    editBill: "Edit Vendor Bill",
    editJustBill: "Edit Bill",
    editRecurringBill: "Edit Recurring Vendor Bill",
    vendorBillPayment: "Vendor Bill Payment",
    newCredit: "New Vendor Credit",
    newExpense: "New Expense",
    totalExpense: "Total Expense",
    editExpense: "Edit Expense",
    editJournalEntry: "Edit Journal Entry",
    editRecurringExpense: "Edit Recurring Expense",
    billReferenceLabel: "Bill",
    expenseReferenceLabel: "Expense",
    deleteBill: "Delete Bill",
    deleteExpense: "Delete Expense",
    deleteJournalEntry: "Delete Journal Entry",
    billDate: "Bill Date",
    selectVendor: "Select Vendor",
    selectAVendor: "Select a vendor",
    searchVendors: "Search Vendors",
    bill: "Bill",
    deleteBillConfirmationText: "Are you sure you want to delete the bill?",
    deleteJournalEntryConfirmationText: "Are you sure you want to delete the journal entry?",
    deleteBillLoadingText: "Deleting bill",
    deleteBillSuccessText: "The bill was deleted successfully",
    deleteCreditConfirmationText: "Are you sure you want to delete the credit?",
    deleteCreditLoadingText: "Deleting credit",
    deleteCreditSuccessText: "The credit was deleted successfully",
    deleteExpenseConfirmationText: "Are you sure you want to delete the expense?",
    deleteExpenseLoadingText: "Deleting expense",
    deleteExpenseSuccessText: "The expense was deleted successfully",
    deleteVendorBillPaymentConfirmationText: "Are you sure you want to delete the vendor bill payment?",
    deleteVendorBillPaymentLoadingText: "Deleting vendor bill payment",
    deleteVendorBillPaymentSuccessText: "The vendor bill payment was deleted successfully",
    expenseDate: "Expense Date",
    selectPayee: "Select Payee",
    payee: "Payee",
    paymentRecipient: "Payment Recipient",
    addVendor: "Add Vendor",
    addOwner: "Add Owner",
    addTenant: "Add Tenant",
    paymentDate: "Payment Date",
    noInsurance: "No insurance"
  },
  learnMoreExpense: "Learn more about creating an expense",
  expenseDescription: "Record costs incurred by your properties",
  learnMoreBills: "Learn more about creating bills",
  billDescription: "Create the bills for money owed to vendors"
};
