import type {
  ConversationDto,
  ConversationParticipantDto,
  GetAllBaseQueryRequest,
  LeaseDto,
  PersonDto,
  PersonWithPortalInfoDto,
  PropertyAndUnitDto,
  PropertyDto,
  TenantDto
} from "@doorloop/dto";
import { ConversationLinkedToType, ConversationMethod, DateFormats, EmailType, PhoneType } from "@doorloop/dto";
import { tenantsApi } from "api/tenantsApi";
import { ownersApi } from "api/ownersApi";
import { vendorsApi } from "api/vendorsApi";
import { usersApi } from "api/usersApi";
import type { RestApiBaseWithDictionary } from "api/restApiBaseWithDictionary";
import PhoneIphoneRoundedIcon from "@material-ui/icons/PhoneIphoneRounded";
import React from "react";
import AppStrings from "../../../../locale/keys";
import ColorsEnum from "../../../../utils/colorsEnum";
import MailOutlineRoundedIcon from "@material-ui/icons/MailOutlineRounded";
import { Routes } from "../../../appRouter";
import moment from "moment";
import { leasesApi } from "api/leasesApi";
import { propertiesApi } from "api/propertiesApi";
import { unitsApi } from "api/unitsApi";
import i18n from "i18next";
import { tagsApi } from "api/tagsApi";
import { formatPhoneNumber } from "../../../../utils/formatPhoneNumber";
import _ from "lodash";
import type { ConversationAdditionalInfo } from "screens/communicationsCenter/shared/types";
import { history } from "store/history";

type ParticipantTypeToApiMap = Record<
  Exclude<ConversationLinkedToType, ConversationLinkedToType.SYSTEM>,
  RestApiBaseWithDictionary<PersonDto, GetAllBaseQueryRequest>
>;

interface ReciepientAddtionalInfoOptions {
  recipient: ConversationParticipantDto;
  method?: ConversationMethod;
  includeContactDetails: boolean;
}

type RecipientAdditionalInfoGetterMap = Record<
  Exclude<ConversationLinkedToType, ConversationLinkedToType.USER | ConversationLinkedToType.SYSTEM>,
  ({ recipient, method, includeContactDetails }: ReciepientAddtionalInfoOptions) => ConversationAdditionalInfo[]
>;

class ConversationUtils {
  participantTypeToApiMap: ParticipantTypeToApiMap = {
    [ConversationLinkedToType.TENANT]: tenantsApi,
    [ConversationLinkedToType.OWNER]: ownersApi,
    [ConversationLinkedToType.PROSPECT]: tenantsApi,
    [ConversationLinkedToType.VENDOR]: vendorsApi,
    [ConversationLinkedToType.USER]: usersApi
  };

  conversationIconByMethod: Record<ConversationMethod, JSX.Element> = {
    [ConversationMethod.EMAIL]: <MailOutlineRoundedIcon style={{ color: ColorsEnum.BrightGray, height: 18 }} />,
    [ConversationMethod.PHONE]: <PhoneIphoneRoundedIcon style={{ color: ColorsEnum.BrightGray, height: 18 }} />
  };

  participantTypeToRouteMap: Record<
    Exclude<ConversationLinkedToType, ConversationLinkedToType.SYSTEM | ConversationLinkedToType.USER>,
    string
  > = {
    [ConversationLinkedToType.TENANT]: Routes.TENANTS,
    [ConversationLinkedToType.OWNER]: Routes.OWNERS,
    [ConversationLinkedToType.PROSPECT]: Routes.PROSPECTS,
    [ConversationLinkedToType.VENDOR]: Routes.VENDORS
  };

  getAdditionalInfoHref = (route: string, id?: string): string | undefined => {
    if (id) {
      return `${route}/${id}/overview`;
    }
  };

  getParticipantFromDictionary = (participant: ConversationParticipantDto): PersonWithPortalInfoDto | undefined => {
    if (participant.linkedToType && participant.linkedToId) {
      const api = this.participantTypeToApiMap[participant.linkedToType];

      if (api) {
        return api.getItemFromDictionary(participant.linkedToId);
      }
    }
  };

  getViewConversationRoute = () => {
    if (location.pathname.includes("inbox")) {
      return Routes.COMMUNICATIONS_CENTER_INBOX_VIEW_CONVERSATION;
    }
    if (location.pathname.includes("unassigned")) {
      return Routes.COMMUNICATIONS_CENTER_UNASSIGNED_MESSAGES_VIEW_CONVERSATION;
    }
    if (location.pathname.includes("all-messages")) {
      return Routes.COMMUNICATIONS_CENTER_ALL_MESSAGES_VIEW_CONVERSATION;
    }
  };

  getCommunicationCenterRoute = () => {
    if (location.pathname.includes("inbox")) {
      return Routes.COMMUNICATIONS_CENTER_INBOX;
    }
    if (location.pathname.includes("unassigned")) {
      return Routes.COMMUNICATIONS_CENTER_UNASSIGNED_MESSAGES;
    }
    if (location.pathname.includes("all-messages")) {
      return Routes.COMMUNICATIONS_CENTER_ALL_MESSAGES;
    }
  };

  getParticipantPhoneNumber = (participant: ConversationParticipantDto): string | undefined => {
    const participantDto = this.getParticipantFromDictionary(participant);
    return (
      formatPhoneNumber(participantDto?.e164PhoneMobileNumber) ||
      participantDto?.portalInfo?.mobilePhone ||
      participantDto?.phones?.find((phone) => phone?.type === PhoneType.MOBILE)?.number
    );
  };

  getParticipantEmail = (participant: ConversationParticipantDto): string | undefined => {
    const participantDto = this.getParticipantFromDictionary(participant);
    return (
      participantDto?.portalInfo?.loginEmail ||
      participantDto?.emails?.find((email) => email?.type === EmailType.PRIMARY)?.address
    );
  };

  getParticipantContactDetails = (
    participant: ConversationParticipantDto,
    method?: ConversationMethod
  ): ConversationAdditionalInfo[] => {
    const contactDetails: ConversationAdditionalInfo[] = [];

    if (method === ConversationMethod.PHONE) {
      const phoneNumber = this.getParticipantPhoneNumber(participant);
      phoneNumber && contactDetails.push({ label: phoneNumber });
    } else if (method === ConversationMethod.EMAIL) {
      const email = this.getParticipantEmail(participant);
      email && contactDetails.push({ label: email });
    }

    return contactDetails;
  };

  getEmailConversationSubject = (conversation: ConversationDto): string =>
    conversation.subject || AppStrings.Common.UnknownContact;

  getPhoneConversationSubject = (conversation: ConversationDto): string => {
    const recipient = this.getParticipantFromDictionary(conversation.recipient);
    return recipient?.fullName || AppStrings.Common.UnknownContact;
  };

  conversationSubjectByMethodGetterMap: Record<ConversationMethod, (conversation: ConversationDto) => string> = {
    [ConversationMethod.EMAIL]: this.getEmailConversationSubject,
    [ConversationMethod.PHONE]: this.getPhoneConversationSubject
  };

  getConversationSubject = (conversation: ConversationDto): string =>
    this.conversationSubjectByMethodGetterMap[conversation.method](conversation);

  getParticipantLinkedToTypeLabel = (participant: ConversationParticipantDto): string | undefined => {
    if (participant.linkedToType) {
      return i18n.t(AppStrings.Common.Enums.ESignatureSignerType[participant.linkedToType]);
    }
  };

  getProspectRecipientAdditionalInfo = ({
    recipient,
    method
  }: ReciepientAddtionalInfoOptions): ConversationAdditionalInfo[] => {
    const prospectId = recipient.linkedToId;

    let contactDetails: ConversationAdditionalInfo[] = [];

    contactDetails = this.getParticipantContactDetails(recipient, method);

    const prospectLabel = this.getParticipantLinkedToTypeLabel(recipient);
    const prospectHref = this.getAdditionalInfoHref(Routes.PROSPECTS, prospectId);
    const prospectInfo: ConversationAdditionalInfo = {
      label: prospectLabel,
      href: prospectHref
    };
    const additionalInfo: ConversationAdditionalInfo[] = [prospectInfo];

    return [...additionalInfo, ...contactDetails];
  };

  getTenantLeases = (tenantId?: string): LeaseDto[] | undefined => {
    if (!tenantId) {
      return [];
    }

    const leasesDictionary = leasesApi.getDictionaryFromSessionStorage();

    if (leasesDictionary) {
      return Object.values(leasesDictionary).filter((lease) => lease?.tenants?.some((tenant) => tenant === tenantId));
    }
  };

  getTenantRecipientAdditionalInfo = ({
    recipient,
    method,
    includeContactDetails
  }: ReciepientAddtionalInfoOptions): ConversationAdditionalInfo[] => {
    const tenantId = recipient.linkedToId;

    let contactDetails: ConversationAdditionalInfo[] = [];

    if (includeContactDetails) {
      contactDetails = this.getParticipantContactDetails(recipient, method);
    }

    const tenantLabel = this.getParticipantLinkedToTypeLabel(recipient);
    const tenantInfo: ConversationAdditionalInfo = {
      label: tenantLabel,
      href: this.getAdditionalInfoHref(Routes.TENANTS, tenantId)
    };

    const tenantLeases = this.getTenantLeases(tenantId);

    if (tenantLeases?.length === 1) {
      const lease = _.first(tenantLeases);
      const unit = unitsApi.getItemFromDictionary(_.first(lease?.units) || "");
      const property = propertiesApi.getItemFromDictionary(lease?.property || "");
      const propertyInfo: ConversationAdditionalInfo = {
        label: property?.name,
        href: this.getAdditionalInfoHref(Routes.PROPERTIES, property?.id)
      };
      const unitInfo: ConversationAdditionalInfo = {
        label: unit?.name,
        href: this.getAdditionalInfoHref(Routes.UNITS, unit?.id)
      };
      return [tenantInfo, propertyInfo, unitInfo, ...contactDetails];
    }
    if (!_.isEmpty(tenantLeases)) {
      const numOfProperties = _.size(_.uniq(tenantLeases?.map((lease) => lease.property)));
      const multipleLeasesInfo = { label: `${numOfProperties} ${i18n.t(AppStrings.Common.Properties)}` };
      return [tenantInfo, multipleLeasesInfo, ...contactDetails];
    }

    return [tenantInfo, ...contactDetails];
  };

  getOwnerProperties = (ownerId?: string): PropertyDto[] => {
    if (!ownerId) {
      return [];
    }

    const propertiesDictionary = propertiesApi.getDictionaryFromSessionStorage();
    const properties = (propertiesDictionary && Object.values(propertiesDictionary)) || [];

    return properties.filter(
      (property) => property.active && property.owners?.some((owner) => owner.owner === ownerId)
    );
  };

  getOwnerPropertiesIds = (ownerId?: string): string[] =>
    _.compact(conversationUtils.getOwnerProperties(ownerId).map((property) => property.id));

  getVendorPropertiesIds = (vendorId?: string): string[] => {
    if (!vendorId) {
      return [];
    }

    const vendor = vendorsApi.getItemFromDictionary(vendorId);
    return vendor?.properties || [];
  };

  getProspectInterests = (prospectId?: string): PropertyAndUnitDto[] => {
    if (!prospectId) {
      return [];
    }

    const prospect: TenantDto = tenantsApi.getItemFromDictionary(prospectId);

    return prospect?.prospectInfo?.interests || [];
  };

  getOwnerRecipientAdditionalInfo = ({
    recipient,
    method,
    includeContactDetails
  }: ReciepientAddtionalInfoOptions): ConversationAdditionalInfo[] => {
    const ownerId = recipient.linkedToId;

    let contactDetails: ConversationAdditionalInfo[] = [];

    if (includeContactDetails) {
      contactDetails = this.getParticipantContactDetails(recipient, method);
    }

    const ownerLabel = this.getParticipantLinkedToTypeLabel(recipient);
    const ownerInfo: ConversationAdditionalInfo = {
      label: ownerLabel,
      href: this.getAdditionalInfoHref(Routes.OWNERS, ownerId)
    };

    const ownerProperties = this.getOwnerProperties(ownerId);

    if (ownerProperties?.length === 1) {
      const property = ownerProperties[0];
      const propertyInfo: ConversationAdditionalInfo = {
        label: property.name,
        href: this.getAdditionalInfoHref(Routes.PROPERTIES, property.id)
      };
      return [ownerInfo, propertyInfo, ...contactDetails];
    }
    if (!_.isEmpty(ownerProperties)) {
      const multiplePropertiesInfo: ConversationAdditionalInfo = {
        label: `${ownerProperties?.length} ${i18n.t(AppStrings.Common.Properties)}`
      };
      return [ownerInfo, multiplePropertiesInfo, ...contactDetails];
    }

    return [ownerInfo, ...contactDetails];
  };

  getVendorRecipientAdditionalInfo = ({
    recipient,
    method,
    includeContactDetails
  }: ReciepientAddtionalInfoOptions): ConversationAdditionalInfo[] => {
    const vendorId = recipient.linkedToId;

    if (!vendorId) {
      return [];
    }

    let contactDetails: ConversationAdditionalInfo[] = [];

    if (includeContactDetails) {
      contactDetails = this.getParticipantContactDetails(recipient, method);
    }

    const vendorLabel = this.getParticipantLinkedToTypeLabel(recipient);
    const vendorInfo: ConversationAdditionalInfo = {
      label: vendorLabel,
      href: this.getAdditionalInfoHref(Routes.VENDORS, vendorId)
    };

    const vendorServicesIds: string[] = vendorsApi.getItemFromDictionary(vendorId)?.services || [];
    const vendorServices = vendorServicesIds.map((serviceId) => tagsApi.getItemFromDictionary(serviceId)?.name);

    if (vendorServices.length > 0) {
      const vendorServicesInfo: ConversationAdditionalInfo = { label: vendorServices.join(", ") };
      return [vendorInfo, vendorServicesInfo, ...contactDetails];
    }
    return [vendorInfo, ...contactDetails];
  };

  recipientAdditionalInfoGetterMap: RecipientAdditionalInfoGetterMap = {
    [ConversationLinkedToType.PROSPECT]: this.getProspectRecipientAdditionalInfo,
    [ConversationLinkedToType.TENANT]: this.getTenantRecipientAdditionalInfo,
    [ConversationLinkedToType.OWNER]: this.getOwnerRecipientAdditionalInfo,
    [ConversationLinkedToType.VENDOR]: this.getVendorRecipientAdditionalInfo
  };

  getConversationRecipientAdditionalInfo = (
    recipient: ConversationParticipantDto,
    method?: ConversationMethod,
    includeContactDetails = false
  ): ConversationAdditionalInfo[] => {
    if (recipient.linkedToType && recipient.linkedToId) {
      return this.recipientAdditionalInfoGetterMap[recipient.linkedToType]({
        recipient,
        method,
        includeContactDetails
      });
    }
    return this.getUnknownContactRecipientAdditionalInfo(recipient);
  };

  getUnknownContactRecipientAdditionalInfo = (recipient: ConversationParticipantDto): ConversationAdditionalInfo[] => {
    const additionalInfo: ConversationAdditionalInfo[] = [];
    if (recipient.phoneNumber) {
      additionalInfo.push({ label: formatPhoneNumber(recipient.phoneNumber) });
    }

    if (recipient.email) {
      additionalInfo.push({ label: recipient.email });
    }

    return additionalInfo;
  };

  formatLastConversationDate = (date: Date): string => {
    const now = moment();
    const messageDate = moment(date);

    const minutesDiff = now.diff(messageDate, "minutes");

    if (minutesDiff <= 1) {
      return AppStrings.Common.JustNow;
    }
    if (minutesDiff < 60) {
      return messageDate.fromNow();
    }
    if (messageDate.isSame(now, "day")) {
      return messageDate.format("h:mm A");
    }
    if (messageDate.isSame(now.clone().subtract(1, "day"), "day")) {
      return AppStrings.Common.Yesterday;
    }
    if (messageDate.isSame(now, "year")) {
      return messageDate.format(DateFormats.THREE_LETTER_MONTH_AND_DATE);
    }
    return messageDate.format("L");
  };

  getAssigneePictureUrl = (assignee?: string): string | undefined => {
    const participantDto = this.getParticipantFromDictionary({
      linkedToType: ConversationLinkedToType.USER,
      linkedToId: assignee
    });
    return participantDto?.pictureUrl;
  };

  getConversationListItemDetails = (conversation: ConversationDto, includeContactDetails = false) => {
    const subject = this.getConversationSubject(conversation);
    const renderIcon = this.conversationIconByMethod[conversation.method];
    const lastMessageDate = this.formatLastConversationDate(conversation.lastMessage.sentAt);
    const recipientAdditionalInfo = this.getConversationRecipientAdditionalInfo(
      conversation.recipient,
      conversation.method,
      includeContactDetails
    );
    const assigneePicture = this.getAssigneePictureUrl(_.first(conversation.assignees));

    return {
      subject,
      renderIcon,
      lastMessageDate,
      recipientAdditionalInfo,
      assigneePicture
    };
  };

  getAssigneesLabel = (assignees?: string[]): string => {
    if (assignees?.length === 0) {
      return AppStrings.Prospects.Screen.Unassigned;
    }

    if (assignees?.length === 1) {
      return usersApi.getItemFromDictionary(assignees[0])?.name;
    }

    return `${assignees?.length} ${i18n.t(AppStrings.Calendar.EventDetails.Assignees)}`;
  };

  isTabView = () => !location.pathname.includes("communications-center");

  navigateBack = () => {
    if (this.isTabView()) {
      history.replace(location.pathname, { conversationId: "" });
    } else {
      const route = this.getViewConversationRoute()?.replace(":conversationId", "");
      route && history.replace(route);
    }
  };

  getScreenLabel = () => {
    const route = conversationUtils.getViewConversationRoute();

    if (route === Routes.COMMUNICATIONS_CENTER_INBOX_VIEW_CONVERSATION) {
      return AppStrings.CommunicationsCenter.Screen.MyInboxTitle;
    }
    if (route === Routes.COMMUNICATIONS_CENTER_UNASSIGNED_MESSAGES_VIEW_CONVERSATION) {
      return AppStrings.CommunicationsCenter.Screen.UnAssignedTitle;
    }
    return AppStrings.CommunicationsCenter.Screen.AllMessagesTitle;
  };
}

export const conversationUtils = new ConversationUtils();
