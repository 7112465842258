"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPropertyOwnershipQuery = void 0;
const properties_1 = require("../../properties");
class GetPropertyOwnershipQuery extends properties_1.GetAllPropertiesQuery {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.GetPropertyOwnershipQuery = GetPropertyOwnershipQuery;
