import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    componentContainer: {
      width: 200,
      height: 200,
      margin: 20,
      borderRadius: 25
    },
    lineRowBorder: {
      borderBottom: "1px solid #ECEEF5"
    }
  })
);
