import type React from "react";
import { useCallback, useMemo, useState } from "react";
import type { GridRowWithId } from "DLUI/lists/types";
import { EDITING_IDENTIFIER, EXPANSION_IDENTIFIER, SELECTION_IDENTIFIER } from "DLUI/lists/types";
import type { DataResult, GroupResult } from "@progress/kendo-data-query";
import type { GridCellProps, GridExpandChangeEvent, GridSelectionChangeEvent } from "@progress/kendo-react-grid";
import _ from "lodash";

interface UseGridSelectionParams<DtoType extends object> {
  dataSource: DataResult;
  setDataSource: React.Dispatch<React.SetStateAction<DataResult>>;
  originalDataHashMap: Map<string, GridRowWithId<DtoType>>;
  setOriginalDataHashMap: React.Dispatch<React.SetStateAction<Map<string, GridRowWithId<DtoType>>>>;
}

/**
 * Hook to manage the selection of a grid
 * **THIS HOOK IS USED INTERNALLY BY THE GRID LIST COMPONENT**
 * **NOT MEANT TO BE USED BY OTHER COMPONENTS**
 * @param dataSource The data source of the grid (usually received from the apiMethod)
 * @param setDataSource The React dispatch setter of the data source of the grid
 * @param originalDataHashMap The original data hash map (id -> data) of the grid
 * @param onSelectionChange Callback to be called when the selection changes
 */
export const useGridSelection = <DtoType extends object>({
  dataSource,
  setDataSource,
  originalDataHashMap,
  setOriginalDataHashMap
}: UseGridSelectionParams<DtoType>) => {
  const [selectedRowsSet, setSelectedRowsSet] = useState<Set<GridRowWithId<DtoType>>>(new Set());
  const selectedRows = useMemo(() => Array.from(selectedRowsSet), [selectedRowsSet]);

  function onGridCellEditRequest(cellProps: GridCellProps) {
    setDataSource({
      ...dataSource,
      data: dataSource.data.map((row) => {
        const dataItem = row as GridRowWithId<DtoType>;
        const selectedRowItem = cellProps.dataItem as GridRowWithId<DtoType>;
        const field = cellProps.field;

        // if the row id is the same as the selected row id, then set the editing flag to true
        if (dataItem.id === selectedRowItem.id) {
          return {
            ...dataItem,
            [EDITING_IDENTIFIER]: field
          };
        }
        return row;
      })
    });
  }

  const setSelectedRowsHandler = (newSelectionArray) => {
    setSelectedRowsSet(new Set(newSelectionArray));
  };

  function onGridCellEditFinish(cellProps: GridCellProps) {
    // the dataItem is updated
    const dataItem = cellProps.dataItem as GridRowWithId<DtoType>;

    setDataSource({
      ...dataSource,
      data: dataSource.data.map((row) => {
        const rowItem = row as GridRowWithId<DtoType>;
        // if the row id is the same as the selected row id, then set the editing flag to true
        if (rowItem.id === dataItem.id) {
          return {
            ...dataItem,
            [EDITING_IDENTIFIER]: null
          };
        }
        return row;
      })
    });

    const updatedField = cellProps.field;
    if (!updatedField) return;

    const untranslatedFieldValue = _.get(dataItem, `_${updatedField}`) ?? _.get(dataItem, updatedField);

    const originalDataItem = originalDataHashMap.get(dataItem.id);
    if (!originalDataItem) return;

    originalDataHashMap.set(dataItem.id, {
      ...originalDataItem,
      [updatedField]: untranslatedFieldValue
    });

    // set the original data hash map to the updated value
    setOriginalDataHashMap(new Map(originalDataHashMap));

    // set the selected rows to the updated value
    setSelectedRowsSet((prevSelectedRows) => {
      const updatedSelectionRows = Array.from(prevSelectedRows).map((row) => {
        if (row.id === dataItem.id) {
          return {
            ...row,
            [updatedField]: untranslatedFieldValue
          };
        }
        return row;
      });

      return new Set(updatedSelectionRows);
    });
  }

  function resetSelection() {
    setDataSource((prevState) => {
      return {
        ...prevState,
        data: prevState.data.map((item) => {
          if (item.items) {
            const { items } = item as GroupResult;
            return {
              ...item,
              items: items.map((subItem) => {
                return { ...subItem, [SELECTION_IDENTIFIER]: false };
              })
            };
          }
          return { ...item, [SELECTION_IDENTIFIER]: false };
        })
      };
    });

    setSelectedRowsSet(new Set());
  }

  function changeDataSourceSelection(rowIds: Set<string>, isChecked: boolean) {
    const selectedItems: Array<GridRowWithId<DtoType>> = [];
    setDataSource((prevState): DataResult => {
      const { data } = prevState;
      if (!data) {
        return prevState;
      }

      const newState = {
        total: prevState.total,
        data: data.map((item) => {
          if (rowIds.has(item.id)) {
            const originalItem = originalDataHashMap.get(item.id);
            if (!originalItem) {
              console.error("Original item not found in hash map");
              return item;
            }

            item[SELECTION_IDENTIFIER] = isChecked;
            originalItem[SELECTION_IDENTIFIER] = isChecked;

            selectedItems.push(originalItem);
          }

          /**
           * If the item has nested item then it's an aggregate row
           * We perform DFS to find the nested item with selectedRowId and update the selection state
           */
          if (Array.isArray(item.items)) {
            // cast the group item to Kendo GroupResult to enforce the type
            const groupItem = item as GroupResult;
            for (const nestedItem of groupItem.items as Array<GridRowWithId<DtoType>>) {
              if (!rowIds.has(nestedItem.id)) continue;

              // we found the nested item with selectedRowId
              const originalItem = originalDataHashMap.get(nestedItem.id);
              if (!originalItem) {
                console.error("Original item (grouped) not found in hash map");
                return item;
              }
              nestedItem[SELECTION_IDENTIFIER] = isChecked;
              originalItem[SELECTION_IDENTIFIER] = isChecked;
              selectedItems.push(originalItem);
            }
          }

          return item;
        })
      };

      if (selectedItems.length === 0) {
        console.error("couldn't find the row with the given id: ", rowIds);
      }

      return newState;
    });

    setSelectedRowsSet((prevState) => {
      if (isChecked) {
        return new Set([...prevState, ...new Set(selectedItems)]);
      }
      return new Set(Array.from(prevState).filter((row) => !rowIds.has(row?.id)));
    });
  }

  /**
   * This function is called when the user clicks on the checkbox in any row
   */
  function onSelectionCheckboxClick(event: GridSelectionChangeEvent) {
    const checkboxElement = event.syntheticEvent.target as HTMLInputElement;
    const isChecked = checkboxElement.checked;
    const selectedRowId: string | undefined = event.dataItem.id;

    if (!selectedRowId) {
      return;
    }

    changeDataSourceSelection(new Set([selectedRowId]), isChecked);
  }

  /**
   * This function is called when the user clicks on the SELECT ALL checkbox in the header
   */
  const onSelectAll = useCallback(
    (newValue: boolean) => {
      /**
       * If the user clicks on the header checkbox to select all the rows
       * then we need to update the selection state of all the rows
       */
      const rowIds = dataSource.data
        .map((item) => {
          if (item.items) {
            // aggregate row has nested items
            // we are assuming all nested items have an `id` field
            return (item as GroupResult).items.map(({ id }: any) => id);
          }
          // simple row
          return item.id;
        })
        .flat();

      changeDataSourceSelection(new Set(rowIds), newValue);
    },
    [dataSource]
  );

  function onGroupSelectionChanged(isChecked: boolean, dataItem: GroupResult) {
    const items = dataItem.items as Array<GridRowWithId<DtoType>>;
    // iterate over the items and collect the ids
    const ids = items.map(({ id }) => id);

    changeDataSourceSelection(new Set(ids), isChecked);
  }

  function onExpandChange(event: GridExpandChangeEvent) {
    // Apply the `expanded` prop to the group items in data based on the provided collection of group ids.
    event.dataItem[EXPANSION_IDENTIFIER] = event.value;
    setDataSource({ ...dataSource });
  }

  return {
    // discourage from using this hook in other components other than the grid list
    _gridListInternal: {
      selectedRows,
      setSelectedRows: setSelectedRowsHandler,
      selectionLength: selectedRows.length,
      resetSelection,
      onSelectionCheckboxClick,
      onSelectAll,
      onGroupSelectionChanged,
      onExpandChange,
      onGridCellEditRequest,
      onGridCellEditFinish
    }
  };
};
