import Grid from "@material-ui/core/Grid";
import { FormikSwitchButton, TextField } from "DLUI/form";
import { BackgroundImageView, View } from "DLUI/view";
import Text from "DLUI/text";
import type { FormikProps } from "formik";
import { FastField, getIn } from "formik";
import AppStrings from "locale/keys";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "DLUI/link";
import { ImageURLsEnum } from "DLUI/link/linkURLsEnum";

interface ComponentProps {
  formikRef: FormikProps<any>;
}

const CheckPrintSupportUrl = "https://support.doorloop.com/en/articles/6076148-printing-checks";

const CheckPrinting: React.FC<ComponentProps> = ({ formikRef }: ComponentProps) => {
  const { t } = useTranslation();
  const [printAccountNumberOnCheck, setPrintAccountNumberOnCheck] = useState(
    getIn(formikRef.values, "printAccountNumbersOnCheck") || false
  );
  const [initialBankAccountNumber] = useState(getIn(formikRef.values, "bankAccountNumber") || "");
  const [initialBankAccountNumberEncrypted] = useState(getIn(formikRef.values, "bankAccountNumber_encrypted") || "");

  const didChangeAccountNumber = (currentValue) => {
    formikRef.setFieldValue(
      "bankAccountNumber_encrypted",
      currentValue && currentValue !== initialBankAccountNumber ? undefined : initialBankAccountNumberEncrypted
    );
  };

  const didChangeIncludeRoutingAccount = (nextSwitchState: boolean) => {
    setPrintAccountNumberOnCheck(nextSwitchState);
    if (!nextSwitchState) {
      formikRef.setFieldValue("bankRoutingNumber", undefined);
      formikRef.setFieldValue("bankAccountNumber", undefined);
      formikRef.setFieldValue("nextCheckNumber", undefined);
      formikRef.setFieldValue("bankAccountName", undefined);
      formikRef.setFieldValue("bankAccountOwnerName", undefined);
      formikRef.setFieldValue("bankAccountContactInfo", undefined);
    }
  };

  return (
    <View flex={1}>
      <View>
        <Text value={t(AppStrings.Accounts.AccountInfo.YouCanPrintMessage)} />
        <Link type={"tab"} hoverColor={"lightBlue"} hrefUrl={CheckPrintSupportUrl}>
          <Text color={"lightBlue"} value={t(AppStrings.Accounts.AccountInfo.LearnMore)} />
        </Link>
      </View>
      <View>
        <FastField
          component={FormikSwitchButton}
          marginTop={20}
          label={t(AppStrings.Accounts.AccountInfo.AlsoInclude)}
          name={"printAccountNumbersOnCheck"}
          onChange={didChangeIncludeRoutingAccount}
        />
        <Text
          paddingLeft={60}
          paddingRight={20}
          fontSize={12}
          color={"gray"}
          value={t(AppStrings.Accounts.AccountInfo.SomeChecksMessage)}
        />
        <View shouldShow={printAccountNumberOnCheck} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
          <View alignItems={"flex-start"} flexDirection={"row"}>
            <Grid item sm={6} xs={12}>
              <FastField
                component={TextField}
                label={t(AppStrings.Accounts.AccountInfo.Routing)}
                name={`bankRoutingNumber`}
                paddingRight={20}
                marginTop={20}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FastField
                component={TextField}
                label={t(AppStrings.Accounts.AccountInfo.Number)}
                name={`bankAccountNumber`}
                paddingRight={20}
                marginTop={20}
                onChange={didChangeAccountNumber}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FastField
                component={TextField}
                label={t(AppStrings.Accounts.AccountInfo.NextCheck)}
                name={`nextCheckNumber`}
                paddingRight={20}
                marginTop={20}
                formatType={"number"}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FastField
                component={TextField}
                label={t(AppStrings.Accounts.AccountInfo.BankName)}
                name={`bankAccountName`}
                paddingRight={20}
                marginTop={20}
              />
            </Grid>
            <Grid item xs={12}>
              <FastField
                component={TextField}
                label={t(AppStrings.Accounts.AccountInfo.AccountOwnerName)}
                name={`bankAccountOwnerName`}
                paddingRight={20}
                marginTop={20}
              />
            </Grid>
            <Grid item xs={12}>
              <FastField
                component={TextField}
                label={t(AppStrings.Accounts.AccountInfo.BankAccountContactInfo)}
                name={`bankAccountContactInfo`}
                paddingRight={20}
                multiline
                rows="4"
                marginTop={20}
              />
            </Grid>
          </View>
          <BackgroundImageView
            marginTop={20}
            width={640}
            height={100}
            publicFileName={`url(${ImageURLsEnum.PAYOUT_INSTRUCTIONS})`}
            backgroundSize={"cover"}
            backgroundPosition={"center"}
          />
        </View>
      </View>
    </View>
  );
};

export default CheckPrinting;
