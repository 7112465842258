/* eslint-disable arrow-body-style */

import { DifferenceEngine } from "engines/differenceEngine";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIsMounted } from "usehooks-ts";

import type { LinkedResourceType, PictureDto } from "@doorloop/dto";
import type { OperationsBuilderSetter } from "./pictureGallery.utils";
import {
  mapPictureDtoToRUGFileState,
  mapRUGFileStateToOrderIndex,
  mapRUGFileStateToPictureDto
} from "./pictureGallery.utils";
import { useOperationsBuilder } from "./useBuildOperations";

import type { RUGFileState, RUGInterface } from "shared/pictureGallery/types";

import { createContext } from "use-context-selector";
import { resourceTypeToPicturesAPI } from "../../../api/picturesApi";

export function useRUG({
  resourceType,
  resourceId,
  onOperationsChange
}: {
  resourceType: LinkedResourceType.Property | LinkedResourceType.Unit;
  resourceId?: string;
  onOperationsChange?: OperationsBuilderSetter;
}) {
  const [initialState, setInitialState] = useState<RUGFileState[] | null>(null);
  const [state, setState] = useState<RUGFileState[] | null>(null);

  const [rugComponent, setRUGComponent] = useState<RUGInterface | null>(null);

  const isMounted = useIsMounted();
  const isInitialSet = useRef(true);

  const [picturesDto, setPicturesDto] = useState<PictureDto[]>([]);

  const picturesApi = resourceTypeToPicturesAPI[resourceType];

  const fetchPictures = useCallback(async () => {
    if (!resourceId) {
      return;
    }

    const response = await picturesApi.getAll({ resourceId });

    if (isMounted()) {
      setPicturesDto(response.data?.data || []);
    }
  }, [isMounted, picturesApi, resourceId]);

  useEffect(() => {
    fetchPictures();
  }, [fetchPictures]);

  const initialize = useCallback(() => {
    if (!isMounted() || !rugComponent || !picturesDto) {
      return;
    }

    let initialStateBase: RUGFileState[];

    // Reset RUG internal state for consistency
    rugComponent.increment = 0;

    if (initialState) {
      // State (`state`) is hydrated initially and then on `change` event, so it
      // has precedence over `initialState` to hydrate the RUG component
      // instance.
      initialStateBase = (state || initialState).map((item) => {
        const { uid = "" } = item;

        if (!item.uid) {
          console.error(`Failed to find \`uid\` in the \`initialState\` item: ${item}`);
        }

        return rugComponent.create.call(rugComponent, {
          ...item,
          remove: () => rugComponent.remove(uid),
          click: () => rugComponent.onClick(uid),
          select: () => rugComponent.onSelected(uid),
          upload: (data) => rugComponent.tryUpload(uid, data)
        });
      });
    } else {
      initialStateBase = picturesDto.map((item) =>
        rugComponent.create.call(rugComponent, {
          // https://github.com/m-inan/react-upload-gallery/blob/master/src/RUG.js#L33
          done: true,
          ...mapPictureDtoToRUGFileState(item)
        })
      );
      if (isInitialSet.current) {
        setInitialState(initialStateBase);
      }
    }

    rugComponent.setState({ images: initialStateBase.slice() });

    // Re-fire the initial `onChange` event synthetically since the RUG
    // component fires it only on `componentDidMount` lifecycle, which is
    // _before_ the API state is ready; We should consider setting an `isReady`
    // flag, so the component renders only once we have the API state ready.
    if (isInitialSet.current) {
      setState(initialStateBase);
    }

    isInitialSet.current = false;
  }, [isMounted, rugComponent, picturesDto, initialState, state]);

  const isInitialChange = useRef(true);

  const handleChange = useCallback(
    (images: RUGFileState[]) => {
      if (isInitialChange.current) {
        isInitialChange.current = false;
        // Skip initial event
      } else {
        setState(images);
      }
    },
    [setState]
  );

  const resetState = useCallback(async () => {
    await fetchPictures();

    if (!isMounted()) {
      return;
    }

    isInitialChange.current = true;
    setInitialState(null);
    setState(null);
  }, [fetchPictures, isMounted]);

  const onFinish = useCallback(async () => {
    resetState();
  }, [resetState]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const diffEngine = useMemo(() => new DifferenceEngine<RUGFileState>("uid"), []);
  const diff = useMemo(() => diffEngine.analyze(initialState || [], state || []), [initialState, state, diffEngine]);

  const added = useMemo(
    () => diff.added.filter((item) => !item.fileId).map((item) => mapRUGFileStateToOrderIndex(item, state)),
    [state, diff.added]
  );

  const removed = useMemo(
    () => diff.removed.filter((item) => item.fileId).map(mapRUGFileStateToPictureDto),
    [diff.removed]
  );

  const changed = useMemo(
    () => diff.changed.filter((item) => item.fileId).map((item) => mapRUGFileStateToOrderIndex(item, state)),
    [state, diff.changed]
  );

  const operationsBuilder = useOperationsBuilder(picturesApi, added, removed, changed);

  useEffect(() => {
    onOperationsChange?.(operationsBuilder);
  }, [onOperationsChange, operationsBuilder]);

  return useMemo(
    () => ({
      initialState,
      state,
      setState,
      setComponent: setRUGComponent,
      onFinish,
      onChange: handleChange
    }),
    [handleChange, initialState, onFinish, state]
  );
}

export interface IRUGContext {
  initialState: RUGFileState[] | null;
  state: RUGFileState[] | null;
  setState: (state: RUGFileState[] | null) => void;
  setComponent: (component: RUGInterface | null) => void;
  onFinish: () => void;
  onChange: (images: RUGFileState[]) => void;
}

export const RUGContext = createContext<IRUGContext | null>(null);
