/* eslint-disable arrow-body-style */

import Text from "DLUI/text";
import AppStrings from "locale/keys";

import { BulkExecutionDialog } from "DLUI/bulkExecution/bulkExecutionDialog";
import { IconButton } from "DLUI/form";
import { Icon } from "DLUI/icon";
import { Picture } from "DLUI/picture/picture";
import { View } from "DLUI/view";
import { BackupIcon, DeleteIcon } from "assets/icons";
import { noop } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import RUG, { DragArea, DropArea } from "react-upload-gallery";
import { useContextSelector } from "use-context-selector";
import { PICTURE_FILE_TYPES } from "./pictureGallery.constants";
import { RUGContext } from "./pictureGallery.hooks";

import type { Operation } from "engines/bulkOperationsEngine";
import type { RUGFileState, RUGInterface } from "shared/pictureGallery/types";

import "react-upload-gallery/dist/style.css";
import "./style.css";

const noopCustomRequest = () => ({ abort: noop });

const rugStyle = { width: "100%" };

export const PictureGallery = () => {
  const [showBulkExecution, setShowBulkExecution] = useState(false);
  const [bulkOperations, setBulkOperations] = useState<Operation[]>([]);

  const setComponent = useContextSelector(RUGContext, (context) => context?.setComponent);
  const onChange = useContextSelector(RUGContext, (context) => context?.onChange);
  const onFinishBulkExecution = useContextSelector(RUGContext, (context) => context?.onFinish);
  const state = useContextSelector(RUGContext, (context) => context?.state);

  // The gallery component is a pretty old React component (class component)
  // that takes an initial state and then controls the state itself, namely,
  // an uncontrolled component. This is why we need to use a ref to control
  // the state from the parent (namely, make it a controlled component).
  const rugRef = useRef<RUGInterface>();

  useEffect(() => {
    const rugComponent = rugRef.current;

    if (!rugComponent) {
      return;
    }

    setComponent?.(rugComponent);

    return () => setComponent?.(null);
  }, [setComponent]);

  const handleChange = useCallback(
    (images: RUGFileState[]) => {
      onChange?.([...images]);
    },
    [onChange]
  );

  useEffect(() => {
    // Stacking context hack to fix an issue where the dragged picture is not visible.
    // https://stackoverflow.com/a/11742116/4106263
    window.document.querySelectorAll<HTMLDivElement>(".rug .drag-area > div").forEach((element) => {
      element.style.zIndex = "99999";
    });
  });

  const handleCloseBulkExecution = useCallback(() => {
    setShowBulkExecution(false);
    setBulkOperations([]);
  }, []);

  return (
    <View flex={1} fullWidth alignItems={"center"}>
      {showBulkExecution && (
        <BulkExecutionDialog
          operations={bulkOperations}
          onFinish={onFinishBulkExecution}
          closeHandler={handleCloseBulkExecution}
        />
      )}
      <View flex={1} fullWidth>
        {/* https://github.com/m-inan/react-upload-gallery/issues/32 */}
        <RUG
          value={state}
          style={rugStyle}
          ref={rugRef}
          onChange={handleChange}
          inOrder
          accept={PICTURE_FILE_TYPES}
          customRequest={noopCustomRequest}
          header={({ openDialogue }) => (
            <DropArea>
              {() => (
                <View
                  style={{ border: "1px dashed #515F78" }}
                  height={150}
                  borderRadius={10}
                  marginBottom={20}
                  onClick={openDialogue}
                  justifyContent={"center"}
                >
                  <View alignItems={"center"} marginBottom={15}>
                    <Icon Source={BackupIcon} width={40} height={35} />
                  </View>
                  <View alignItems={"center"}>
                    <Text value={AppStrings.Common.DragFilesOrClickHereToUpload} fontSize={14} bold />
                  </View>
                </View>
              )}
            </DropArea>
          )}
        >
          <DragArea
            className={"drag-area"}
            style={{
              background: "white",
              borderRadius: "5px",
              boxShadow: "0px 0px 8px 0px #33333330"
            }}
          >
            {(rugFile: RUGFileState) => (
              <Picture url={rugFile.source} cursor={"grab"}>
                <View alignItems={"flex-end"} justifyContent={"flex-end"} height={"100%"} marginBottom={10}>
                  <IconButton Icon={DeleteIcon} pathColor={"white"} size={16} onClick={rugFile.remove} />
                </View>
              </Picture>
            )}
          </DragArea>
        </RUG>
      </View>
    </View>
  );
};
