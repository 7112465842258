import type { FormikProps } from "formik";
import type { ObjectPermission, ReportPermission } from "@doorloop/dto";
import {
  getRequiredSections,
  getRequiringSections
} from "screens/settings/userRoles/dependencies/sectionsDependencies";
import { getRequiredFields, getRequiringFields } from "screens/settings/userRoles/dependencies/fieldDependencies";
import { getDenyValue, getGrantValue } from "screens/settings/userRoles/dependencies/grantValue";
import type { TaskViewObjectAccess } from "@doorloop/dto";
import type { ObjectPermissionField } from "screens/settings/userRoles/clearanceTypes";
import { makePermissionKey } from "screens/settings/userRoles/clearanceTypes";

type formikRef = FormikProps<{}>;

const grantPermissions = (formikRef: formikRef, permissions: string[]) => {
  permissions.forEach((permission) => formikRef.setFieldValue(permission, getGrantValue(permission)));
};

const denyPermissions = (formikRef: formikRef, permissions: string[]) => {
  permissions.forEach((permission) => formikRef.setFieldValue(permission, getDenyValue(permission)));
};

const getAllDependencies = (permissionKey: string): string[] => {
  const fieldDependencies = getRequiredFields(permissionKey);
  const sectionDependencies = getRequiredSections(permissionKey);
  const secondDepthDependencies = sectionDependencies.concat(fieldDependencies).map(getRequiredSections).flat();
  return [...fieldDependencies, ...sectionDependencies, ...secondDepthDependencies];
};

const getAllDependants = (permissionKey: string): string[] => {
  const fieldDependencies = getRequiringFields(permissionKey);
  const sectionDependencies = getRequiringSections(permissionKey);
  const secondDepthDependencies = sectionDependencies.concat(fieldDependencies).map(getRequiringSections).flat();
  return [...fieldDependencies, ...sectionDependencies, ...secondDepthDependencies];
};

export const usePermissionDependency = (formikRef: formikRef) => {
  return {
    applyObjectDependencies: (
      permission: ObjectPermission,
      field: ObjectPermissionField,
      grantValue: boolean | TaskViewObjectAccess
    ): void => {
      const permissionKey = makePermissionKey(permission, field);
      if (grantValue === getGrantValue(permissionKey)) {
        grantPermissions(formikRef, getAllDependencies(permissionKey));
      } else if (grantValue === getDenyValue(permissionKey)) {
        denyPermissions(formikRef, getAllDependants(permissionKey));
      }
    },
    applyReportPermission: (permission: ReportPermission, granted: boolean): void => {
      if (granted) {
        grantPermissions(formikRef, getRequiredSections(permission));
      } else {
        denyPermissions(formikRef, getRequiringSections(permission));
      }
    }
  };
};
