import { ObjectPermission } from "@doorloop/dto";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import { PictureGallery } from "shared/pictureGallery/pictureGallery";

export const EditUnitPhotos = () => (
  <View flex={1} marginBottom={40} paddingRight={5} paddingLeft={5}>
    <RestrictedPermissionAccess clearance={{ permission: ObjectPermission.units, field: "edit" }} showNoAccess>
      <View marginBottom={40}>
        <Text fontSize={16} value={AppStrings.Listing.PhotosDescription} />
      </View>
      <PictureGallery />
    </RestrictedPermissionAccess>
  </View>
);
