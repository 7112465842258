import { createContext } from "use-context-selector";
import type { FilterChipInfo, SidePanelState } from "./types";
import noop from "lodash/noop";
import type { FilterOption } from "@/components/DLUI/screen/filterPanel/filterInputs/types";

export interface FilterChipsContextProps<TQuery> {
  filterChips: FilterChipInfo[];
  addFilterChip: (filterChip: FilterChipInfo) => void;
  removeFilterChip: (key: string) => void;
  filterOptions: Array<FilterOption<TQuery>>;
  sidePanelState: SidePanelState;
  toggleSidePanelState: (flag?: "open" | "closed") => void;
}

export const FilterChipsContext = createContext<FilterChipsContextProps<never>>({
  filterOptions: [],
  filterChips: [],
  addFilterChip: noop,
  removeFilterChip: noop,
  sidePanelState: "closed",
  toggleSidePanelState: noop
});
