"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESignatureRelatedToType = void 0;
var ESignatureRelatedToType;
(function (ESignatureRelatedToType) {
    ESignatureRelatedToType["PROPERTY"] = "PROPERTY";
    ESignatureRelatedToType["UNIT"] = "UNIT";
    ESignatureRelatedToType["LEASE"] = "LEASE";
    ESignatureRelatedToType["LEASE_DRAFT"] = "LEASE_DRAFT";
})(ESignatureRelatedToType || (exports.ESignatureRelatedToType = ESignatureRelatedToType = {}));
