import { Redirect, Route, Router } from "react-router-dom";
import { history } from "store/history";
import LeasesRouter from "./leasesRouter";
import OwnersRouter from "./ownersRouter";
import PropertiesRouter from "./propertiesRouter";
import Routes from "./routes";
import TenantsRouter from "./tenantsRouter";
import UnitsRouter from "./unitsRouter";
import VendorsRouter from "./vendorsRouter";
import WelcomeRouter from "./welcomeRouter";
import BillsRouter from "./billsRouter";
import TasksRouter from "./tasksRouter";
import ProspectsRouter from "./prospectsRouter";
import AccountsRouter from "./accountsRouter";
import UsersRouter from "./usersRouter";
import SettingsRouter from "./settingsRouter";
import ReportsRouter from "./reportsRouter";
import BankingRouter from "./bankingRouter";
import DemoRouter from "./demoRouter";
import TenantPortalRouter from "./tenantPortalRouter";
import SubscriptionRouter from "./subscriptionRouter";
import RentalApplicationsRouter from "./rentalApplicationsRouter";
import UserRolesRouter from "components/appRouter/userRolesRouter";
import AnnouncementsRouter from "components/appRouter/announcementsRouter";
import { Feature } from "config/featureFlags/featureFlags";
import CommunicationsRouter from "components/appRouter/communicationsRouter";
import { TenantPortalContext } from "contexts/tenantPortalContext";
import SignatureRequestsRouter from "./signatureRequestsRouter";
import SignatureTemplatesRouter from "./signatureTemplatesRouter";
import RecurringRouter from "components/appRouter/recurringRouter";
import ManagementFeesRouter from "./managementFeesRouter";
import { OutgoingPaymentsRouter } from "components/appRouter/outgoingPaymentsRouter";
import OnBoardingRouter from "./onBoardingRouter";
import { AppLayoutContextProvider } from "../../contexts/appLayoutContext";
import LayoutRenderer from "../layouts/layoutRenderer";
import FilesRouter from "./filesRouter";
import NotesRouter from "./notesRouter";
import { OwnerPortalRouter } from "./ownerPortalRouter";
import { CalendarRouter } from "./calendarRouter";
import { CommunicationsCenterRouter } from "./communicationsCenterRouter";
import { lazy } from "react";
import { LazyRoute } from "./lazyRoute";
import { PageRouter } from "./pageRouter";
import { IncomingPaymentsRouter } from "./incomingPaymentsRouter";
import { BookkeepingRouter } from "./bookkeepingRouter";

const ArAgingSummaryReport = lazy(
  () => import("screens/reports/agingSummaryReport/accountReceivable/arAgingSummaryReport")
);
const ApAgingSummaryReport = lazy(
  () => import("screens/reports/agingSummaryReport/accountPayable/apAgingSummaryReport")
);
const DashboardsScreen = lazy(() => import("screens/dashboards/dashboardScreen"));
const ListingScreen = lazy(() => import("screens/listing/listingScreen"));
const IconBrowserScreen = lazy(() => import("screens/iconBrowser/iconBrowserScreen"));
const OAuthRedirectScreen = lazy(() => import("screens/oauthRedirectScreen/OAuthRedirectScreen"));
const AuthRouter = lazy(() => import("./authRouter"));

const TenantPortalRouterWithContext = () => (
  <TenantPortalContext.Provider value>
    <TenantPortalRouter />
  </TenantPortalContext.Provider>
);

// https://v5.reactrouter.com/
const AppRouter = () => (
  <Router history={history.history}>
    <AppLayoutContextProvider>
      <LayoutRenderer>
        <PageRouter>
          <Redirect exact from="/" to={Routes.LOGIN} />
          <Route path={Routes.DEMO} render={DemoRouter} />
          <LazyRoute path={Routes.AUTH} render={AuthRouter} />
          <Route path={Routes.WELCOME} render={WelcomeRouter} />
          <Route path={Routes.RENTAL_APPLICATIONS} render={RentalApplicationsRouter} />
          <LazyRoute path={Routes.HOME} render={DashboardsScreen} />
          <LazyRoute path={Routes.LISTINGS} render={ListingScreen} />
          <Route path={Routes.ONBOARDING} render={OnBoardingRouter} />
          <Route path={Routes.PROPERTIES} render={PropertiesRouter} />
          <Route path={Routes.OUTGOING_PAYMENTS} render={OutgoingPaymentsRouter} />
          <Route path={Routes.INCOMING_PAYMENTS} render={IncomingPaymentsRouter} />
          <Route path={Routes.CALENDAR} render={CalendarRouter} />
          <Route path={Routes.LEASES} render={LeasesRouter} />
          <Route path={Routes.UNITS} render={UnitsRouter} />
          <Route path={Routes.TENANTS} render={TenantsRouter} />
          <Route path={Routes.OWNERS} render={OwnersRouter} />
          <Route path={Routes.VENDORS} render={VendorsRouter} />
          <Route path={Routes.BILLS} render={BillsRouter} />
          <Route path={Routes.TASKS} render={TasksRouter} />
          <Route path={Routes.RECURRING} render={RecurringRouter} />
          <Route path={Routes.PROSPECTS} render={ProspectsRouter} />
          <Route path={Routes.ACCOUNTS} render={AccountsRouter} />
          <Route path={Routes.BANKING} render={BankingRouter} />
          <Route path={Routes.BOOKKEEPING} render={BookkeepingRouter} />
          <Route path={Routes.USERS} render={UsersRouter} />
          <Route path={Routes.USER_ROLES} render={UserRolesRouter} />
          <Route path={Routes.SETTINGS} render={SettingsRouter} />
          <Route path={Routes.REPORTS} render={ReportsRouter} />
          <Route path={Routes.SUBSCRIPTION} render={SubscriptionRouter} />
          <Route path={Routes.MANAGEMENT_FEES} render={ManagementFeesRouter} />
          <Route key={Routes.ANNOUNCEMENTS} path={Routes.ANNOUNCEMENTS} render={AnnouncementsRouter} />,
          <Route key={Routes.COMMUNICATIONS} path={Routes.COMMUNICATIONS} render={CommunicationsRouter} />
          <Route path={Routes.TENANT_PORTAL_BASE_URL} render={TenantPortalRouterWithContext} />
          <Route path={Routes.OWNER_PORTAL_BASE_URL} render={OwnerPortalRouter} />
          <Route path={Routes.SIGNATURE_REQUESTS} render={SignatureRequestsRouter} />
          <Route path={Routes.SIGNATURE_TEMPLATES} render={SignatureTemplatesRouter} />
          <LazyRoute path={Routes.INCOME_ACCOUNTS_RECEIVABLE_AGING_SUMMARY} render={ArAgingSummaryReport} />
          <LazyRoute path={Routes.OPEN_BILLS_ACCOUNTS_PAYABLE_AGING_SUMMARY} render={ApAgingSummaryReport} />
          {Feature.isActive("IconBrowser") && <LazyRoute path={Routes.ICON_BROWSER} render={IconBrowserScreen} />}
          <Route path={Routes.NOTES} render={NotesRouter} />
          <Route path={Routes.FILES} render={FilesRouter} />
          <LazyRoute path={Routes.OAUTH_REDIRECT} exact render={OAuthRedirectScreen} />
          <Route path={Routes.COMMUNICATIONS_CENTER} render={CommunicationsCenterRouter} />
        </PageRouter>
      </LayoutRenderer>
    </AppLayoutContextProvider>
  </Router>
);

export default AppRouter;
