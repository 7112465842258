"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiResult = void 0;
class ApiResult {
    constructor(data, message = "", statusCode, validationErrors, errorCode, headers, errors) {
        this.data = data;
        this.message = message;
        this.statusCode = statusCode;
        this.validationErrors = validationErrors;
        this.errorCode = errorCode;
        this.headers = headers;
        this.errors = errors;
        this.status = Boolean(data);
        if (!statusCode) {
            this.statusCode = this.status ? 200 : 500;
        }
        else {
            this.statusCode = statusCode;
        }
    }
}
exports.ApiResult = ApiResult;
