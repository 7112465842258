import { AgendaIcon, BadgeIcon, LocationIcon, MarketingIcon, MenuExtraLocationIcon } from "assets/icons";
import { ContactInfo, PersonAddress, PersonInfo } from "DLUI/person";
import AppStrings from "locale/keys";
import React from "react";
import { MarketingProfile } from "./components";
import type { MenuItem } from "DLUI/dialogs";
import { SHARED_TENANT_FIELDS } from "screens/tenants/newTenant/menuItems";
import { SendWelcomeSms } from "DLUI/person/sendWelcomeSms";
import { ConversationLinkedToType } from "@doorloop/dto";

export const PROSPECT_MENU_ITEMS: MenuItem[] = [
  {
    title: AppStrings.Tenants.NewTenant.PersonalInfo,
    icon: BadgeIcon,
    contentComponent: (formikRef, onFileReceive, onFileDelete) => (
      <>
        <PersonInfo formikRef={formikRef} onFileReceive={onFileReceive} onFileDelete={onFileDelete} />
        <SendWelcomeSms personType={ConversationLinkedToType.PROSPECT} />
      </>
    ),
    uploadFile: true
  },
  {
    title: AppStrings.Tenants.NewTenant.ContactInfo,
    icon: AgendaIcon,
    contentComponent: (formikRef) => <ContactInfo formikRef={formikRef} />
  },
  {
    title: AppStrings.Tenants.NewTenant.Address,
    icon: LocationIcon,
    contentComponent: (formikRef) => (
      <PersonAddress
        formikName={"primaryAddress"}
        title={AppStrings.Tenants.NewTenant.PrimaryAddress}
        formikRef={formikRef}
      />
    )
  },
  {
    title: AppStrings.Tenants.NewTenant.AlternateAddress,
    icon: MenuExtraLocationIcon,
    contentComponent: (formikRef) => (
      <PersonAddress
        formikName={"alternateAddress"}
        title={AppStrings.Tenants.NewTenant.AlternateAddress}
        formikRef={formikRef}
      />
    )
  },
  {
    title: AppStrings.Prospects.NewProspect.MarketingProfile,
    icon: MarketingIcon,
    contentComponent: (formikRef) => <MarketingProfile formikRef={formikRef} />
  },
  ...SHARED_TENANT_FIELDS
];
