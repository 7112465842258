import type { PropsWithChildren } from "react";
import { useRef } from "react";
import { View } from "DLUI/view";
import type { ViewComponentProps } from "DLUI/view/view";

import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import { DataCy } from "@doorloop/dto";

import { useOnClickOutside } from "usehooks-ts";
import noop from "lodash/noop";
export interface SidePanelProps extends ViewComponentProps {
  isOpen?: boolean;
  zIndex?: number;
  top?: number;
  onClickOutside?: (event: MouseEvent | TouchEvent | FocusEvent) => void;
}

const SidePanel = ({
  zIndex = 999,
  top = 0,
  backgroundColor = "light",
  children,
  isOpen,
  onClickOutside = noop,
  ...viewProps
}: PropsWithChildren<SidePanelProps>) => {
  const componentRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(componentRef, onClickOutside);

  const { isMobile } = useResponsiveHelper();

  const width = isMobile
    ? `calc(100% - ${viewProps.paddingRight}px - ${viewProps.paddingLeft}px)`
    : viewProps.width ?? "auto";

  return (
    <View
      style={{
        zIndex,
        position: "fixed",
        top,
        height: `calc(100% - ${top}px)`,
        right: 0,
        width,
        pointerEvents: isOpen ? "auto" : "none",
        visibility: isOpen ? "visible" : "hidden",
        transform: isOpen ? "translateX(0)" : "translateX(100%)",
        transition: "visibility 0.4s, transform 0.4s",
        boxShadow: "rgba(0, 0, 0, 0.13) -5px 1px 20px 0px"
      }}
      backgroundColor={backgroundColor}
      domRef={componentRef}
      overflow={"auto"}
      noWrap
      dataCy={DataCy.DLUI.filters.sidePanel}
      {...viewProps}
    >
      {children}
    </View>
  );
};

export default SidePanel;
