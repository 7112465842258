"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenantNotificationSettingsDto = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const base_dto_1 = require("../../base.dto");
const tenantNotificationChannels_dto_1 = require("./tenantNotificationChannels.dto");
class TenantNotificationSettingsDto extends base_dto_1.BaseDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.TenantNotificationSettingsDto = TenantNotificationSettingsDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => tenantNotificationChannels_dto_1.TenantNotificationChannelsDto),
    __metadata("design:type", tenantNotificationChannels_dto_1.TenantNotificationChannelsDto)
], TenantNotificationSettingsDto.prototype, "announcement", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => tenantNotificationChannels_dto_1.TenantNotificationChannelsDto),
    __metadata("design:type", tenantNotificationChannels_dto_1.TenantNotificationChannelsDto)
], TenantNotificationSettingsDto.prototype, "requestUpdated", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => tenantNotificationChannels_dto_1.TenantNotificationChannelsDto),
    __metadata("design:type", tenantNotificationChannels_dto_1.TenantNotificationChannelsDto)
], TenantNotificationSettingsDto.prototype, "requestReceived", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => tenantNotificationChannels_dto_1.TenantNotificationChannelsDto),
    __metadata("design:type", tenantNotificationChannels_dto_1.TenantNotificationChannelsDto)
], TenantNotificationSettingsDto.prototype, "lateFeeCharged", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => tenantNotificationChannels_dto_1.TenantNotificationChannelsDto),
    __metadata("design:type", tenantNotificationChannels_dto_1.TenantNotificationChannelsDto)
], TenantNotificationSettingsDto.prototype, "paymentReturned", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => tenantNotificationChannels_dto_1.TenantNotificationChannelsDto),
    __metadata("design:type", tenantNotificationChannels_dto_1.TenantNotificationChannelsDto)
], TenantNotificationSettingsDto.prototype, "paymentReceived", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => tenantNotificationChannels_dto_1.TenantNotificationChannelsDto),
    __metadata("design:type", tenantNotificationChannels_dto_1.TenantNotificationChannelsDto)
], TenantNotificationSettingsDto.prototype, "paymentReceipt", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => tenantNotificationChannels_dto_1.TenantNotificationChannelsDto),
    __metadata("design:type", tenantNotificationChannels_dto_1.TenantNotificationChannelsDto)
], TenantNotificationSettingsDto.prototype, "autopayCanceled", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => tenantNotificationChannels_dto_1.TenantNotificationChannelsDto),
    __metadata("design:type", tenantNotificationChannels_dto_1.TenantNotificationChannelsDto)
], TenantNotificationSettingsDto.prototype, "rentCharged", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => tenantNotificationChannels_dto_1.TenantNotificationChannelsDto),
    __metadata("design:type", tenantNotificationChannels_dto_1.TenantNotificationChannelsDto)
], TenantNotificationSettingsDto.prototype, "rentReminders", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => tenantNotificationChannels_dto_1.TenantNotificationChannelsDto),
    __metadata("design:type", tenantNotificationChannels_dto_1.TenantNotificationChannelsDto)
], TenantNotificationSettingsDto.prototype, "rentOverdueReminders", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => tenantNotificationChannels_dto_1.TenantNotificationChannelsDto),
    __metadata("design:type", tenantNotificationChannels_dto_1.TenantNotificationChannelsDto)
], TenantNotificationSettingsDto.prototype, "beforeLateFeeCharged", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => tenantNotificationChannels_dto_1.TenantNotificationChannelsDto),
    __metadata("design:type", tenantNotificationChannels_dto_1.TenantNotificationChannelsDto)
], TenantNotificationSettingsDto.prototype, "autopayCanceledByUser", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => tenantNotificationChannels_dto_1.TenantNotificationChannelsDto),
    __metadata("design:type", tenantNotificationChannels_dto_1.TenantNotificationChannelsDto)
], TenantNotificationSettingsDto.prototype, "rentalApplicationInvite", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => tenantNotificationChannels_dto_1.TenantNotificationChannelsDto),
    __metadata("design:type", tenantNotificationChannels_dto_1.TenantNotificationChannelsDto)
], TenantNotificationSettingsDto.prototype, "rentalApplicationReminder", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => tenantNotificationChannels_dto_1.TenantNotificationChannelsDto),
    __metadata("design:type", tenantNotificationChannels_dto_1.TenantNotificationChannelsDto)
], TenantNotificationSettingsDto.prototype, "submitRentalApplication", void 0);
