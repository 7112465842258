import type { TenantDto } from "@doorloop/dto";
import type { ApiResult } from "api/apiResult";
import { tenantsApi } from "api/tenantsApi";
import AppStrings from "locale/keys";
import React, { useEffect, useMemo, useState } from "react";
import { EntitySelector, EntityType } from "DLUI/dialogs/components/entitySelector";
import { useTranslation } from "react-i18next";

interface ComponentProps {
  tenantId?: string;
  didPressSelectTenant: () => void;
  errorTex?: string;
  onTenantSelection?: (vendorId: string) => void;
  viewOnly?: boolean;
  label?: string;
}

const TenantSelection: React.FC<ComponentProps> = ({
  tenantId,
  didPressSelectTenant,
  errorTex,
  onTenantSelection,
  viewOnly,
  label = AppStrings.Tenants.Screen.Tenant
}: ComponentProps) => {
  const { t } = useTranslation();
  const [loadingInProgress, setLoadingInProgress] = useState<boolean>(false);
  const [selectedTenant, setSelectedTenant] = useState<TenantDto | undefined>();

  const { tenantName, tenantInfo } = useMemo(() => {
    let tenantName = "";
    let tenantInfo = "";

    if (selectedTenant) {
      tenantName = selectedTenant.name || "";
      tenantInfo += selectedTenant.jobTitle || "";
      if (selectedTenant.jobTitle) {
        tenantInfo += ", ";
      }
      tenantInfo += selectedTenant.companyName || "";
    }
    return { tenantName, tenantInfo };
  }, [selectedTenant, tenantId]);

  const getTenant = async () => {
    if (tenantId) {
      setLoadingInProgress(true);
      const results = (await tenantsApi.getDetails(tenantId).catch(() => {
        // in case of failure loading unit data just hide the loading indicator
        setLoadingInProgress(false);
      })) as ApiResult<any>;
      if (results && results.status) {
        setSelectedTenant(results.data);
        setLoadingInProgress(false);
        if (onTenantSelection) {
          if (results.data && results.data) {
            onTenantSelection(results.data.id);
          }
        }
      } else {
        setLoadingInProgress(false);
      }
    }
  };

  useEffect(() => {
    getTenant();
  }, [tenantId]);

  return (
    <EntitySelector
      type={EntityType.USER}
      label={t(label)}
      onClick={didPressSelectTenant}
      isLoading={loadingInProgress}
      valueText={tenantName}
      error={errorTex}
      disabled={viewOnly}
    />
  );
};

export default TenantSelection;
