import type { CSSProperties, ElementType } from "react";
import React, { useMemo } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import type ColorsEnum from "../../../utils/colorsEnum";
import styles from "./styles";
import "./styles.css";
import type { Alignment } from "./types";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import moment from "moment";
import TextEllipsis from "DLUI/text/textEllipsis";
import type { SVGIconComponent } from "assets/icons";
import { useTextColor } from "DLUI/text/useTextColor";

export type TextVariant = "title" | "largeTitle" | "label" | "listItemTitle" | "span" | "lightTitle" | "subTitle";

export type TextColor =
  | "blue"
  | "boldBlue"
  | "lightBlue"
  | "lightPurple"
  | "purple"
  | "white"
  | "semiWhite"
  | "gray"
  | "error"
  | "pink"
  | "black"
  | "green"
  | "orange"
  | "secondary-gray"
  | "yellow"
  | "currentColor"
  | "secondary-yellow"
  | "light-black-faded";

export type TextFormatType =
  | "currency"
  | "date"
  | "relative-time"
  | "number"
  | "plain-number"
  | "percent"
  | "date-time"
  | "email";

export type TextFonts = "Roboto Slab" | "Poppins";

export type TextTransformProp = "uppercase" | "none" | "capitalize";

export interface IconTextAndColor {
  StatusIcon: SVGIconComponent;
  statusTextColor: TextColor;
  statusText: string;
}

export type ReplaceObjectInterface = Record<string, string | number>;

export type WhiteSpaceProps = "pre" | "pre-line" | "nowrap" | "break-spaces" | "pre-wrap";

export interface TextComponentProps {
  as?: ElementType;
  value?: string | Date;
  align?: Alignment;
  children?: any;
  className?: string;
  variant?: TextVariant;
  color?: TextColor;
  colorEnum?: ColorsEnum;
  style?: CSSProperties;
  marginTop?: number;
  paddingTop?: number;
  paddingRight?: number;
  paddingLeft?: number;
  marginBottom?: number;
  hoverColor?: TextColor;
  fontWeight?: number;
  removeDefaultPadding?: boolean;
  noWrap?: boolean;
  fullWidth?: boolean;
  marginLeft?: number;
  marginRight?: number;
  maxWidth?: number | string;
  fontSize?: number | string;
  lineHeight?: string;
  textTransform?: TextTransformProp;
  formatType?: TextFormatType;
  underLine?: "dotted" | "solid" | "dashed" | boolean;
  overFlow?: "ellipsis" | "visible";
  fontFamily?: TextFonts;
  numberOfLines?: number;
  maxLines?: string;
  whiteSpace?: WhiteSpaceProps;
  useOverrideCurrency?: boolean;
  overrideCurrency?: string;
  alwaysShowDecimalPoint?: boolean;
  bold?: boolean;
  replaceObject?: ReplaceObjectInterface;
  lineBreak?: "anywhere" | "auto";
  fractionDigits?: number;
  showTooltipOnHover?: boolean;
  prefix?: string;
  suffix?: string;
  dataCy?: string;
  italic?: boolean;
  alignSelf?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "stretch"
    | "base-start"
    | "base-end"
    | "self-start"
    | "self-end"
    | "self-center"
    | "self-stretch"
    | "self-base-start"
    | "self-base-end";
  alignItems?: CSSProperties["alignItems"];
  balancedText?: boolean;
  textWrap?: "wrap" | "nowrap" | "balance" | "pretty" | "stable" | "unset";
  domRef?: React.RefObject<HTMLElement | null>;
  htmlFor?: string;
  preventTranslate?: boolean;
}

const Text: React.FC<TextComponentProps> = ({
  value,
  children,
  align,
  className,
  variant,
  color,
  colorEnum,
  hoverColor,
  marginTop,
  paddingTop,
  marginBottom,
  fontWeight,
  removeDefaultPadding,
  noWrap,
  fullWidth,
  fontFamily,
  marginLeft,
  dataCy,
  showTooltipOnHover,
  maxWidth,
  fontSize,
  marginRight,
  lineHeight,
  textTransform,
  paddingRight,
  formatType,
  underLine,
  paddingLeft,
  overFlow,
  numberOfLines,
  whiteSpace,
  useOverrideCurrency = false,
  overrideCurrency,
  alwaysShowDecimalPoint,
  style,
  bold,
  replaceObject,
  lineBreak,
  fractionDigits,
  prefix,
  suffix,
  alignSelf,
  as: AsElement = "p",
  alignItems,
  italic,
  domRef,
  htmlFor,
  balancedText,
  textWrap,
  preventTranslate
}) => {
  const classes = styles();

  const { t } = useTranslation();
  const { formatNumber, formatDate, formatRelativeTime, formatTime } = useIntl();
  const localeData = useSelector((state: IRootState) => state.auth.localeData);
  const { currency, percentageSymbol } = localeData;
  const { textColor: fontColor, hoverColor: textHoverColor } = useTextColor(color, hoverColor);

  const _whiteSpace = useMemo(() => {
    if (whiteSpace) {
      return whiteSpace;
    }
    if (noWrap) {
      return "nowrap";
    }
    return "normal";
  }, []);

  if (value === "") {
    return null;
  }

  const numberHasDecimalPoint = (formattedNumber: string) => formattedNumber.includes(".");

  const getFontSizeClass = () => {
    switch (variant) {
      case "title":
        return classes.titleFontSize;
      case "lightTitle":
        return classes.lightTitleFontSize;
      case "listItemTitle":
        return classes.listItemTitleFontSize;
      case "span":
        return classes.spanFontSize;
      case "subTitle":
        return classes.subTitleFontSize;
      case "largeTitle":
        return classes.largeTitleFontSize;
      case "label":
        return classes.labelFontSize;
      default:
        return classes.baseFontSize;
    }
  };

  const getFontFamilyClass = () => {
    const fontFamilyMap = {
      "Roboto Slab": classes.robotoSlabFont,
      Poppins: classes.poppinsFont
    } as { [key in TextFonts]: string };

    return fontFamily ? fontFamilyMap[fontFamily] : "";
  };

  const shouldAddZeroBeforeDecimal = (num: string): boolean =>
    num.split(".")[1] !== undefined && num.split(".")[1].length === 1;

  const _fontSize = getFontSizeClass();

  const fontFamilyClass = getFontFamilyClass();

  const underlineValue = underLine === true ? "solid" : underLine;

  const styleObject: any = {
    marginTop: marginTop ? marginTop : 0,
    paddingTop: paddingTop ? paddingTop : 0,
    textAlign: align ? align : "left",
    marginBottom: marginBottom ? marginBottom : 0,
    alignSelf: alignSelf || "initial",
    alignItems: alignItems || "initial",
    fontWeight: bold ? "700" : fontWeight || "normal",
    whiteSpace: _whiteSpace,
    flex: fullWidth ? 1 : "none",
    marginLeft: marginLeft || 0,
    maxWidth: maxWidth || "none",
    marginRight: marginRight || 0,
    lineHeight: lineHeight || "inherit",
    textTransform: textTransform || "none",
    cursor: "inherit",
    paddingRight: paddingRight || 0,
    paddingLeft: paddingLeft || 0,
    textDecoration: underlineValue || "none",
    textDecorationLine: underLine ? "underline" : "none",
    fontStyle: italic ? "italic" : "none",
    ...style
  };

  if (balancedText || textWrap) {
    styleObject["text-wrap"] = textWrap ?? (balancedText ? "balance" : undefined);
  }

  if (fullWidth) {
    styleObject["display"] = "flex";
    styleObject["alignItems"] = alignItems || "center";
  }

  if (colorEnum) {
    styleObject["color"] = colorEnum;
  }

  if (fontSize) {
    styleObject["fontSize"] = fontSize;
  }

  if (overFlow && overFlow === "ellipsis") {
    styleObject["overflow"] = "hidden";
    styleObject["whiteSpace"] = "pre-wrap";
    styleObject["textOverflow"] = "ellipsis";
    styleObject["width"] = "100%";
  }

  let nextTextValue: any;
  if (value && !formatType && value !== "undefined") {
    if (typeof value === "string" && value.indexOf(".") > -1) {
      nextTextValue = preventTranslate ? (value as string) : t(value as string, replaceObject);
    } else {
      nextTextValue = value as string;
    }
  }
  if (value && formatType && value !== "undefined") {
    try {
      if (formatType === "currency") {
        nextTextValue = formatNumber(Number(value), {
          style: "currency",
          currency: useOverrideCurrency ? overrideCurrency || "USD" : currency || "USD", // TODO: When submitting a rental application we're not receiving a currency, I set the default to USD but will need to change
          signDisplay: "negative" // this will make zero value to shown with no sign
        });

        // force align right on currency text
        styleObject["textAlign"] = align || "right";
        styleObject["justifyContent"] = "flex-end";
        // styleObject["width"] = "100%";
        //  }
      }
      if (formatType === "percent") {
        nextTextValue = parseFloat(value as string).toFixed(fractionDigits !== undefined ? fractionDigits : 2);
        nextTextValue += percentageSymbol;
        styleObject["textAlign"] = align || "right";
      }
      if (formatType === "date") {
        const date = moment(value).toDate();
        // eslint-disable-next-line no-self-compare
        const isValidDate = date.getTime() === date.getTime();
        if (isValidDate) {
          nextTextValue = formatDate(date);
        } else {
          nextTextValue = value;
        }
      }
      if (formatType === "date-time") {
        const date = moment(value).toDate();
        nextTextValue = formatDate(date, {});
        nextTextValue += " " + formatTime(date, {});
      }
      if (formatType === "relative-time") {
        nextTextValue = formatRelativeTime(Number(value), "day", {
          numeric: "auto"
        });
      }
      if (formatType === "number") {
        nextTextValue = formatNumber(Number(value), {});
        if (alwaysShowDecimalPoint && !numberHasDecimalPoint(nextTextValue)) {
          nextTextValue = `${nextTextValue}.00`;
        }
        if (shouldAddZeroBeforeDecimal(nextTextValue)) {
          nextTextValue = `${nextTextValue}0`;
        }
        // force align right on number text
        styleObject["textAlign"] = align || "right";
        styleObject["justifyContent"] = "flex-end";
      }
      if (formatType === "plain-number") {
        nextTextValue = value;
      }
    } catch (error) {
      // TODO log parse errors
      nextTextValue = value;
    }
  }

  if (overFlow && overFlow === "ellipsis") {
    return (
      <TextEllipsis
        showTooltipOnHover={showTooltipOnHover}
        fontSize={fontSize}
        fontFamily={fontFamily}
        text={children || (preventTranslate ? nextTextValue : t(String(nextTextValue)))}
        lines={numberOfLines || 1}
        marginLeft={marginLeft}
        marginRight={marginRight}
        paddingLeft={paddingLeft}
        textTransform={textTransform}
        paddingRight={paddingRight}
        lineHeight={lineHeight}
        marginTop={marginTop}
        dataCy={dataCy}
        bold={bold || fontWeight === 700}
        color={color}
        align={align}
        replaceObject={replaceObject}
        lineBreak={lineBreak}
        formatType={formatType}
        maxWidth={maxWidth || "none"}
        className={className}
        underLine={underLine}
        fontWeight={fontWeight}
        style={style}
        as={AsElement}
        colorEnum={colorEnum}
        domRef={domRef}
      />
    );
  }

  return (
    <AsElement
      ref={domRef}
      style={styleObject}
      className={clsx([
        _fontSize,
        removeDefaultPadding ? "" : classes.padding,
        className,
        fontColor.className,
        textHoverColor.className,
        fontFamilyClass
      ])}
      data-cy={dataCy}
      title={showTooltipOnHover ? nextTextValue : undefined}
      htmlFor={htmlFor}
    >
      {prefix && <>{prefix}</>}
      {!children && value ? nextTextValue : null}
      {children}
      {suffix && <>{suffix}</>}
    </AsElement>
  );
};

export default Text;
