import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ColorsEnum from "../../../utils/colorsEnum";
import { DRAWER_WIDTH, MINIMIZED_DRAWER_WIDTH } from "DLUI/drawer/drawer";

export const drawerOpenWidth = 250;

export enum DrawerColorsEnum {
  hoverColor = "#48649266"
}

export const DEFAULT_TRANSITION_MS = 150;

export default makeStyles((theme: Theme) =>
  createStyles({
    drawerContainer: {
      backgroundColor: ColorsEnum.Blue,
      transition: `width ${DEFAULT_TRANSITION_MS}ms linear`
    },
    drawerMobilePosition: {
      position: "fixed",
      display: "flex",
      flexDirection: "row",
      zIndex: 5000,
      width: "100% !important",
      right: "100%"
    },
    mainDrawerMobile: {
      zIndex: 5000,
      color: ColorsEnum.White,
      backgroundColor: ColorsEnum.DarkBlue,
      position: "absolute",
      height: "100%",
      transition: `width ease-in-out ${DEFAULT_TRANSITION_MS}ms`,
      width: "100%",
      display: "flex",
      flexFlow: "column nowrap",
      overflowX: "hidden",
      overflowY: "auto",

      "& .inner-menu-slider": {
        transition: `translate ease-in-out ${DEFAULT_TRANSITION_MS}ms`
      },
      "& .slide-left": {
        translate: "-100vw 0"
      },
      "@media screen and (max-width: 500px)": {
        "& .menuLink, & .menuLink *": {
          userSelect: "none"
        }
      }
    },
    subDrawerMobile: {
      width: "calc(100% - 60px) !important"
    },
    mainDrawer: {
      color: ColorsEnum.White,
      backgroundColor: ColorsEnum.DarkBlue,
      position: "absolute",
      left: 0,
      height: "100%",
      zIndex: 3,
      transition: `width ease-in-out ${DEFAULT_TRANSITION_MS}ms`,
      width: DRAWER_WIDTH,
      display: "flex",
      flexFlow: "column nowrap",
      overflow: "hidden"
    },
    mainDrawerContent: {
      overflowX: "hidden",
      overflowY: "auto",
      height: "100%",
      flexFlow: "column nowrap !important"
    },
    menuItem: {
      "&:not(.minimized):hover": {
        backgroundColor: DrawerColorsEnum.hoverColor
      },
      "&.active": {
        backgroundColor: ColorsEnum.BrightBlue,

        "& .menuItemActiveIndicator": {
          display: "block",
          position: "absolute",
          height: 20,
          width: 3,
          background: ColorsEnum.Pink,
          borderRadius: 0,
          top: "calc(50% - 9px)",
          left: -8
        }
      }
    },
    groupItem: {
      minWidth: DRAWER_WIDTH - MINIMIZED_DRAWER_WIDTH
    },
    groupName: {
      opacity: 0.5
    },
    tooltip: {
      height: 40,
      display: "flex"
    },
    drawerSearchInput: {
      border: "none",
      width: "100%",
      background: "transparent",
      borderBottom: "solid 2px white",
      padding: "0 0 5px 0",
      cursor: "pointer",
      outline: "none",
      caretColor: "transparent"
    },
    onBoardingProgress: {
      position: "absolute",
      bottom: -12,
      left: -7
    },
    menuLink: {
      position: "relative",

      "&.minimized.open": {
        backgroundColor: DrawerColorsEnum.hoverColor
      },
      "&.minimized.active": {
        backgroundColor: ColorsEnum.BrightBlue
      },
      "&.minimized:hover": {
        backgroundColor: DrawerColorsEnum.hoverColor
      }
    },
    subDrawer: {
      overflow: "hidden",
      color: ColorsEnum.White,
      position: "relative",
      zIndex: 2,
      height: "100%",
      paddingLeft: 60,
      backgroundColor: ColorsEnum.Blue,
      paddingTop: 30,
      overflowY: "auto",
      transition: `width ease-in-out ${DEFAULT_TRANSITION_MS}ms`
    }
  })
);
