import type { LeaseDto } from "@doorloop/dto";
import { LeaseStatus } from "@doorloop/dto";
import moment from "moment";
import type { SVGIconComponent } from "../assets";
import { CheckmarkCircleIcon, CloseCircleIcon, ErrorSignIcon, ExpiredCircleIcon, OverdueCircleIcon } from "../assets";
import type { TextColor } from "DLUI/text/text";
import AppStrings from "../locale/keys";

export const useLeaseStatus = (leaseDto: LeaseDto) => {
  const getStatus = (
    leaseDto: LeaseDto
  ): { StatusIcon: SVGIconComponent; statusTextColor: TextColor; statusText: string } => {
    let StatusIcon: SVGIconComponent;
    let statusTextColor: TextColor;
    let statusText = "";

    let daysTillEnd = 0;
    if (leaseDto.end) {
      daysTillEnd = moment.duration(moment(leaseDto.end).diff(moment())).asDays();
    }

    const monthlyLeaseExceeded = Boolean(leaseDto.rolloverToAtWill) && daysToMoveOut !== null && daysToMoveOut < 0;

    if (leaseDto.evictionPending) {
      return {
        StatusIcon: ErrorSignIcon,
        statusTextColor: "error" as TextColor,
        statusText: AppStrings.Overview.Eviction
      };
    }

    if (leaseDto.status === LeaseStatus.ACTIVE) {
      if (daysTillEnd >= 0 || monthlyLeaseExceeded) {
        if (Number(leaseDto.totalBalanceDue) === 0) {
          // active
          StatusIcon = CheckmarkCircleIcon;
          statusTextColor = "green";
          statusText = AppStrings.Leases.NewLease.LeaseStatus.Active;
        } else if (Number(leaseDto.totalBalanceDue) > 0) {
          // balance due
          StatusIcon = OverdueCircleIcon;
          statusTextColor = "error";
          statusText = AppStrings.Leases.LeaseCharge.BalanceDue;
        } else {
          // credit available
          StatusIcon = CheckmarkCircleIcon;
          statusTextColor = "green";
          statusText = AppStrings.Common.CreditAvailable;
        }
      } else {
        // expired
        StatusIcon = ExpiredCircleIcon;
        statusTextColor = "orange";
        statusText = AppStrings.Leases.DraftLease.Expired;
      }
    } else {
      // move-out
      StatusIcon = CloseCircleIcon;
      statusTextColor = "gray";
      statusText = AppStrings.Leases.NewLease.LeaseStatus.Inactive;
    }

    return { StatusIcon, statusTextColor, statusText };
  };
  const getDaysToMoveOut = (leaseEndDate: string) => {
    const duration = moment.duration(moment(leaseEndDate).diff(moment()));
    let days = duration.asDays();

    if (days > 0) {
      days = Math.ceil(duration.asDays());
    } else {
      days = Math.floor(duration.asDays());
    }
    return days;
  };

  /**
   * Number of days to move out, or null if there is no end date
   */
  const daysToMoveOut = leaseDto.end ? getDaysToMoveOut(leaseDto.end) : null;
  const status = getStatus(leaseDto);

  return {
    daysToMoveOut,
    status
  };
};
