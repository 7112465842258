import type { MerchantAccountDto, RentalApplicationCompanySettingsDto } from "@doorloop/dto";
import type { FormikProps } from "formik";
import React from "react";
import ApplicationFeesForm from "screens/settings/rentalApplications/components/applicationSettings/applicationFeesForm";

interface ComponentProps {
  formikRef: FormikProps<RentalApplicationCompanySettingsDto>;
  merchantAccountData?: MerchantAccountDto;
  transunionEligibilityMessage?: string;
  transunionFee?: number;
  landlordId?: number;
}

const ApplicationSettings: React.FC<ComponentProps> = ({
  formikRef,
  merchantAccountData,
  transunionFee,
  landlordId
}) => (
  <ApplicationFeesForm
    formikRef={formikRef}
    merchantAccountData={merchantAccountData}
    transunionFee={transunionFee}
    landlordId={landlordId}
  />
);

export default ApplicationSettings;
