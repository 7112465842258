"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyManagementSoftware = void 0;
var PropertyManagementSoftware;
(function (PropertyManagementSoftware) {
    PropertyManagementSoftware["APPFOLIO"] = "AppFolio";
    PropertyManagementSoftware["ARTHUR_ONLINE"] = "Arthur Online";
    PropertyManagementSoftware["AVAIL"] = "Avail";
    PropertyManagementSoftware["BUILDINGSTACK"] = "BuildingStack";
    PropertyManagementSoftware["BUILDIUM"] = "Buildium";
    PropertyManagementSoftware["COZY"] = "Cozy";
    PropertyManagementSoftware["ENTRATA"] = "Entrata";
    PropertyManagementSoftware["MICROSOFT_EXCEL_WORD"] = "Microsoft Excel/Word";
    PropertyManagementSoftware["GOOGLE_SHEETS_DOCS"] = "Google Sheets/Docs";
    PropertyManagementSoftware["HEMLANE"] = "Hemlane";
    PropertyManagementSoftware["INNAGO"] = "Innago";
    PropertyManagementSoftware["LANDLORD123"] = "Landlord123";
    PropertyManagementSoftware["LANDLORDSTUDIO"] = "LandlordStudio";
    PropertyManagementSoftware["MANAGECASA"] = "ManageCasa";
    PropertyManagementSoftware["MANUAL_PEN_PAPER"] = "Manual - Pen & Paper";
    PropertyManagementSoftware["MRI"] = "MRI";
    PropertyManagementSoftware["ONESITE"] = "Onesite";
    PropertyManagementSoftware["OTHER"] = "Other";
    PropertyManagementSoftware["PAYHOA"] = "PayHOA";
    PropertyManagementSoftware["PROPERTYBOSS"] = "PropertyBoss";
    PropertyManagementSoftware["PROPERTY_MATRIX"] = "Property Matrix";
    PropertyManagementSoftware["PROPERTYWARE"] = "PropertyWare";
    PropertyManagementSoftware["QUICKBOOKS"] = "QuickBooks";
    PropertyManagementSoftware["REAL_PAGE"] = "Real Page";
    PropertyManagementSoftware["RE_LEASED"] = "Re-Leased";
    PropertyManagementSoftware["RENT_MANAGER"] = "Rent Manager";
    PropertyManagementSoftware["RENTREDI"] = "RentRedi";
    PropertyManagementSoftware["RENTTEC_DIRECT"] = "RentTec Direct";
    PropertyManagementSoftware["RENTVINE"] = "RentVine";
    PropertyManagementSoftware["RESMAN"] = "ResMan";
    PropertyManagementSoftware["SIMPLIFYEM"] = "SimplifyEM";
    PropertyManagementSoftware["SITELINK"] = "SiteLink";
    PropertyManagementSoftware["STESSA"] = "Stessa";
    PropertyManagementSoftware["TENANT_CLOUD"] = "Tenant Cloud";
    PropertyManagementSoftware["TURBOTENANT"] = "TurboTenant";
    PropertyManagementSoftware["YARDI_BREEZE"] = "Yardi Breeze";
    PropertyManagementSoftware["YARDI_VOYAGER"] = "Yardi Voyager";
    PropertyManagementSoftware["ZILLOW"] = "Zillow";
})(PropertyManagementSoftware || (exports.PropertyManagementSoftware = PropertyManagementSoftware = {}));
