import AppStrings from "../../../../../locale/keys";
import type { SVGIconComponent } from "../../../../../assets";
import {
  LeaseEntitySelector,
  PropertyEntitySelector,
  UserEntitySelector,
  TenantsIcon,
  OwnersIcon,
  VendorsIcon
} from "../../../../../assets";

import { EntityType } from "./enums";
import type { ObjectWithHiddenForUserTypes } from "../../../../../utils/userTypesUtils";
import type { PersonType } from "@doorloop/dto";

interface MapValue {
  labelTranslationKey: string;
  IconStartSource: SVGIconComponent;
}

const MAP_BY_TYPE: Record<EntityType, MapValue> = {
  [EntityType.PROPERTY]: {
    labelTranslationKey: AppStrings.Properties.Screen.Property,
    IconStartSource: PropertyEntitySelector
  },
  [EntityType.LEASE]: {
    labelTranslationKey: AppStrings.Leases.Screen.Lease,
    IconStartSource: LeaseEntitySelector
  },
  [EntityType.USER]: {
    labelTranslationKey: AppStrings.Users.Screen.User,
    IconStartSource: UserEntitySelector
  },
  [EntityType.PAYEE]: {
    labelTranslationKey: AppStrings.Common.Payee,
    IconStartSource: UserEntitySelector
  }
};

interface MenuItemMapValue extends ObjectWithHiddenForUserTypes {
  id: PersonType;
  Icon: SVGIconComponent;
  titleTranslationKey: string;
}

const PAYEE_ITEMS: MenuItemMapValue[] = [
  {
    id: "tenant" as PersonType,
    Icon: TenantsIcon,
    titleTranslationKey: AppStrings.Vendors.VendorDetails.AddTenant
  },
  {
    id: "owner" as PersonType,
    Icon: OwnersIcon,
    titleTranslationKey: AppStrings.Vendors.VendorDetails.AddOwner,
    hiddenForUserTypes: ["HOAUser"]
  },
  {
    id: "vendor" as PersonType,
    Icon: VendorsIcon,
    titleTranslationKey: AppStrings.Vendors.VendorDetails.AddVendor
  }
];

export { MAP_BY_TYPE, PAYEE_ITEMS };
