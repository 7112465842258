import React from "react";
import { FastField } from "formik";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import makeStyles from "./styles";
import clsx from "clsx";
import { TextField } from "DLUI/form";

const Notes: React.FC<{}> = () => {
  const classes = makeStyles();
  const { t } = useTranslation();

  return (
    <div className={clsx([classes.notesContainer, "WizardMultiLineInput"])}>
      <FastField
        component={TextField}
        className={classes.tenantDetailsMultiLineInput}
        label={t(AppStrings.Tenants.NewTenant.Notes)}
        name={`notes`}
        multiline
        rows="4"
        variant="outlined"
        maxLength={1000}
      />
    </div>
  );
};

export default Notes;
