import { ObjectPermission, ReportPermission } from "@doorloop/dto";
import { makePermissionKey } from "screens/settings/userRoles/clearanceTypes";

type SectionDependencies = Array<{
  permissions: string[];
  require: string[];
}>;

const sectionDependenciesMap: SectionDependencies = [
  {
    permissions: [
      makePermissionKey(ObjectPermission.ownerContributions, "create"),
      makePermissionKey(ObjectPermission.ownerContributions, "edit")
    ],
    require: [makePermissionKey(ObjectPermission.owners, "viewList")]
  },
  {
    permissions: [
      makePermissionKey(ObjectPermission.leaseCharges, "create"),
      makePermissionKey(ObjectPermission.leaseCharges, "edit"),
      makePermissionKey(ObjectPermission.leaseCredits, "create"),
      makePermissionKey(ObjectPermission.leaseCredits, "edit"),
      makePermissionKey(ObjectPermission.leasePayments, "create"),
      makePermissionKey(ObjectPermission.leasePayments, "edit"),
      makePermissionKey(ObjectPermission.leaseRefunds, "create"),
      makePermissionKey(ObjectPermission.leaseRefunds, "edit"),
      makePermissionKey(ObjectPermission.vendorBills, "create"),
      makePermissionKey(ObjectPermission.vendorBills, "edit"),
      makePermissionKey(ObjectPermission.vendorBillPayments, "create"),
      makePermissionKey(ObjectPermission.vendorBillPayments, "edit"),
      makePermissionKey(ObjectPermission.vendorCredits, "create"),
      makePermissionKey(ObjectPermission.vendorCredits, "edit"),
      makePermissionKey(ObjectPermission.ownerContributions, "create"),
      makePermissionKey(ObjectPermission.ownerContributions, "edit"),
      makePermissionKey(ObjectPermission.journalEntries, "create"),
      makePermissionKey(ObjectPermission.journalEntries, "edit"),
      makePermissionKey(ObjectPermission.bankTransfers, "create"),
      makePermissionKey(ObjectPermission.bankTransfers, "edit"),
      makePermissionKey(ObjectPermission.bankDeposits, "create"),
      makePermissionKey(ObjectPermission.bankDeposits, "edit"),
      makePermissionKey(ObjectPermission.expenses, "create"),
      makePermissionKey(ObjectPermission.expenses, "edit"),
      makePermissionKey(ObjectPermission.applicationFees, "create"),
      makePermissionKey(ObjectPermission.applicationFees, "edit"),
      makePermissionKey(ObjectPermission.leases, "create"),
      makePermissionKey(ObjectPermission.leases, "edit"),
      makePermissionKey(ObjectPermission.leaseCharges, "create"),
      makePermissionKey(ObjectPermission.leaseCharges, "edit"),
      makePermissionKey(ObjectPermission.merchantAccounts, "create")
    ],
    require: [makePermissionKey(ObjectPermission.accounts, "viewList")]
  },
  {
    permissions: [
      makePermissionKey(ObjectPermission.rentalApplications, "create"),
      makePermissionKey(ObjectPermission.rentalApplications, "edit")
    ],
    require: [
      makePermissionKey(ObjectPermission.properties, "viewList"),
      makePermissionKey(ObjectPermission.units, "viewList")
    ]
  },
  {
    permissions: [
      makePermissionKey(ObjectPermission.leases, "viewList"),
      makePermissionKey(ObjectPermission.leases, "create")
    ],
    require: [
      makePermissionKey(ObjectPermission.properties, "viewList"),
      makePermissionKey(ObjectPermission.units, "viewList"),
      makePermissionKey(ObjectPermission.tenants, "viewList"),
      makePermissionKey(ObjectPermission.prospects, "viewList")
    ]
  },
  {
    permissions: [
      makePermissionKey(ObjectPermission.prospects, "create"),
      makePermissionKey(ObjectPermission.prospects, "edit"),
      makePermissionKey(ObjectPermission.owners, "create"),
      makePermissionKey(ObjectPermission.owners, "edit"),
      makePermissionKey(ObjectPermission.vendors, "create"),
      makePermissionKey(ObjectPermission.vendors, "edit"),
      makePermissionKey(ObjectPermission.tenants, "create"),
      makePermissionKey(ObjectPermission.tenants, "edit"),
      makePermissionKey(ObjectPermission.users, "create"),
      makePermissionKey(ObjectPermission.users, "edit"),
      makePermissionKey(ObjectPermission.units, "create"),
      makePermissionKey(ObjectPermission.units, "edit"),
      makePermissionKey(ObjectPermission.bankTransfers, "create"),
      makePermissionKey(ObjectPermission.bankTransfers, "edit"),
      makePermissionKey(ObjectPermission.journalEntries, "create"),
      makePermissionKey(ObjectPermission.journalEntries, "edit"),
      makePermissionKey(ObjectPermission.propertyGroups, "viewList"),
      makePermissionKey(ObjectPermission.propertyGroups, "create")
    ],
    require: [makePermissionKey(ObjectPermission.properties, "viewList")]
  },
  {
    permissions: [
      makePermissionKey(ObjectPermission.tasks, "create"),
      makePermissionKey(ObjectPermission.workOrders, "create"),
      makePermissionKey(ObjectPermission.ownerRequests, "create"),
      makePermissionKey(ObjectPermission.tenantRequests, "create")
    ],
    require: [
      makePermissionKey(ObjectPermission.users, "viewList"),
      makePermissionKey(ObjectPermission.properties, "viewList"),
      makePermissionKey(ObjectPermission.units, "viewList")
    ]
  },
  {
    permissions: [
      makePermissionKey(ObjectPermission.workOrders, "create"),
      makePermissionKey(ObjectPermission.vendorBills, "create"),
      makePermissionKey(ObjectPermission.vendorBillPayments, "create"),
      makePermissionKey(ObjectPermission.vendorCredits, "create"),
      makePermissionKey(ObjectPermission.vendorBills, "edit"),
      makePermissionKey(ObjectPermission.vendorBillPayments, "edit"),
      makePermissionKey(ObjectPermission.vendorCredits, "edit")
    ],
    require: [makePermissionKey(ObjectPermission.vendors, "viewList")]
  },
  {
    permissions: [
      makePermissionKey(ObjectPermission.users, "create"),
      makePermissionKey(ObjectPermission.users, "edit")
    ],
    require: [makePermissionKey(ObjectPermission.userRoles, "viewList")]
  },
  {
    permissions: [makePermissionKey(ObjectPermission.ownerRequests, "create")],
    require: [makePermissionKey(ObjectPermission.owners, "viewList")]
  },
  {
    permissions: [makePermissionKey(ObjectPermission.tenantRequests, "create")],
    require: [makePermissionKey(ObjectPermission.tenants, "viewList")]
  },
  {
    permissions: [makePermissionKey(ObjectPermission.properties, "create")],
    require: [makePermissionKey(ObjectPermission.units, "create")]
  },
  {
    permissions: [makePermissionKey(ObjectPermission.leases, "create")],
    require: [
      makePermissionKey(ObjectPermission.tenants, "create"),
      makePermissionKey(ObjectPermission.prospects, "create"),
      makePermissionKey(ObjectPermission.leaseCharges, "create"),
      makePermissionKey(ObjectPermission.leaseCredits, "create")
    ]
  },
  {
    permissions: [
      makePermissionKey(ObjectPermission.rentalApplications, "create"),
      makePermissionKey(ObjectPermission.rentalApplications, "edit")
    ],
    require: [
      makePermissionKey(ObjectPermission.prospects, "viewList"),
      makePermissionKey(ObjectPermission.prospects, "create")
    ]
  },
  {
    permissions: [
      makePermissionKey(ObjectPermission.leaseCharges, "create"),
      makePermissionKey(ObjectPermission.leaseCredits, "create"),
      makePermissionKey(ObjectPermission.leasePayments, "create"),
      makePermissionKey(ObjectPermission.leaseRefunds, "create"),
      makePermissionKey(ObjectPermission.leaseCharges, "edit"),
      makePermissionKey(ObjectPermission.leaseCredits, "edit"),
      makePermissionKey(ObjectPermission.leasePayments, "edit"),
      makePermissionKey(ObjectPermission.leaseRefunds, "edit"),
      ReportPermission.leasing,
      ReportPermission.vendors
    ],
    require: [makePermissionKey(ObjectPermission.leases, "viewList")]
  },
  {
    permissions: [
      makePermissionKey(ObjectPermission.leasePayments, "create"),
      makePermissionKey(ObjectPermission.leaseRefunds, "create"),
      ReportPermission.tenants
    ],
    require: [makePermissionKey(ObjectPermission.tenants, "viewList")]
  },
  {
    permissions: [
      makePermissionKey(ObjectPermission.vendors, "create"),
      makePermissionKey(ObjectPermission.vendors, "edit")
    ],
    require: [makePermissionKey(ObjectPermission.tags, "viewList")]
  },
  {
    permissions: [makePermissionKey(ObjectPermission.expenses, "create")],
    require: [
      makePermissionKey(ObjectPermission.vendors, "viewList"),
      makePermissionKey(ObjectPermission.owners, "viewList"),
      makePermissionKey(ObjectPermission.tenants, "viewList"),
      makePermissionKey(ObjectPermission.properties, "viewList")
    ]
  },
  {
    permissions: [ReportPermission.chartOfAccounts],
    require: [makePermissionKey(ObjectPermission.accounts, "viewList")]
  },
  {
    permissions: [ReportPermission.prospect],
    require: [makePermissionKey(ObjectPermission.prospects, "viewList")]
  },
  {
    permissions: [ReportPermission.tasks],
    require: [makePermissionKey(ObjectPermission.tasks, "viewList")]
  },
  {
    permissions: [
      makePermissionKey(ObjectPermission.outgoingPayments, "create"),
      makePermissionKey(ObjectPermission.outgoingPayments, "edit"),
      makePermissionKey(ObjectPermission.outgoingPayments, "viewOne"),
      makePermissionKey(ObjectPermission.outgoingPayments, "viewList"),
      makePermissionKey(ObjectPermission.outgoingPayments, "delete")
    ],
    require: [makePermissionKey(ObjectPermission.accounts, "viewList")]
  },
  {
    permissions: [makePermissionKey(ObjectPermission.budgets, "delete")],
    require: [makePermissionKey(ObjectPermission.budgets, "create")]
  },
  {
    permissions: [makePermissionKey(ObjectPermission.budgets, "edit")],
    require: [makePermissionKey(ObjectPermission.budgets, "create")]
  },
  {
    permissions: [makePermissionKey(ObjectPermission.budgets, "create")],
    require: [
      makePermissionKey(ObjectPermission.budgets, "edit"),
      makePermissionKey(ObjectPermission.budgets, "viewOne"),
      makePermissionKey(ObjectPermission.budgets, "viewList")
    ]
  },
  {
    permissions: [
      makePermissionKey(ObjectPermission.conversations, "edit"),
      makePermissionKey(ObjectPermission.conversations, "viewList")
    ],
    require: [makePermissionKey(ObjectPermission.phoneNumbers, "viewOne")]
  }
];

export const getRequiredSections = (permission: string) =>
  sectionDependenciesMap
    .filter((dependency) => dependency.permissions.includes(permission))
    .map((dep) => dep.require)
    .flat();

export const getRequiringSections = (permission: string) =>
  sectionDependenciesMap
    .filter((dependency) => dependency.require.includes(permission))
    .map((dep) => dep.permissions)
    .flat();
