import React, { useEffect, useMemo, useState } from "react";
import MomentUtils from "@date-io/moment";
import type { PropertyDto } from "@doorloop/dto";
import {
  AccountType,
  createValidator,
  DateFormats,
  DuplicateDialogButtonLabelValues,
  LinkedResourceType,
  SegmentEventTypes,
  TransferDto
} from "@doorloop/dto";
import { DataCy } from "@doorloop/dto/src/automation/dataCy";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import type { ApiResult } from "api/apiResult";
import { propertiesApi } from "api/propertiesApi";
import { transferApi } from "api/transferApi";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { LoadingDialog } from "DLUI/dialogs/components/loading";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import { FormikDatePicker, FormikReferenceLabel, TextField } from "DLUI/form";
import { Notes } from "DLUI/notes";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, FormikContext, useFormik } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import DialogFrame, { getDialogFrameDimension } from "../../components/dialogFrame";
import { Icon } from "DLUI/icon";
import { ArrowDownIcon, ArrowRightIcon } from "assets/icons";
import AccountBalanceLoader from "DLUI/dialogs/refund/accountBalanceLoader";
import { filesApi } from "api/filesApi";
import { useParams } from "react-router-dom";
import DeleteConfirmation from "DLUI/dialogs/components/deleteConfirmation";
import moment from "moment";
import ReconciledNotificationView from "DLUI/dialogs/components/reconciledNotificationView";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import WarningView from "DLUI/form/warningView/warningView";
import { useEffectAsync } from "../../../../../hooks/useEffectAsync";
import { accountsApi } from "api/accounts";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";
import { unitsApi } from "api/unitsApi";
import { analyticsService } from "../../../../../services/analyticsService";
import { ActivityLabel } from "@/components/DLUI/activityLabel/activityLabel";
import { QueryParams } from "utils/queryParams";
import { cleanTransferDataForDuplicateMode, DUPLICATE_MODE_QUERY_PARAMS } from "DLUI/dialogs/duplicateModeHelper";
import { DialogHeaderActionButtons } from "DLUI/actionButtons/dialogHeaderActionButtons";
import { useAnalyticsService } from "hooks/useAnalyticsService";

interface ComponentProps {
  didFinishOperation: (values: TransferDto) => void;
  onBackdropClick: () => void;
  dialogTitle?: string;
  onClose: () => void;
}

enum DialogViews {
  DISMISS = 0,
  CREATING_UPDATING = 1,
  DELETING = 2
}

const validateForm = createValidator(TransferDto);

const BankTransferDialog: React.FC<ComponentProps> = ({
  didFinishOperation,
  onBackdropClick,
  dialogTitle,
  onClose
}: ComponentProps) => {
  const { t } = useTranslation();
  const { transactionId: paramsTransactionId } = useParams<any>();
  const { isMobile, screenContainerPadding } = useResponsiveHelper();
  const queryParams = new QueryParams();
  const queryTransactionId = queryParams.get(DUPLICATE_MODE_QUERY_PARAMS.transactionId) as string | undefined;
  const transactionId = queryTransactionId || paramsTransactionId;
  const editMode = Boolean(transactionId);
  const duplicateMode = Boolean(queryTransactionId);
  const [viewIndex, setViewIndex] = useState(0);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    editMode ? DialogState.Show : DialogState.Hidden
  );
  const { dispatchAnalytics } = useAnalyticsService();

  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);

  const [createTransferErrorText, setCreateTransferErrorText] = useState<string>(
    t(AppStrings.Common.NetworkErrorTitle)
  );

  const [selectedFromProperty, setSelectedFromProperty] = useState<PropertyDto | undefined>();
  const [transferFromAccount, setTransferFromAccount] = useState<string | undefined>();

  const [selectedToProperty, setSelectedToProperty] = useState<PropertyDto | undefined>();
  const [transferToAccount, setTransferToAccount] = useState<string | undefined>();

  const [selectedClearingAccount, setSelectedClearingAccount] = useState<string | undefined>();
  const [defaultClearingAccount, setDefaultClearingAccount] = useState<string | undefined>();
  const [shouldShowClearingAccount, setShouldShowClearingAccount] = useState<boolean>(false);
  const [shouldShowDifferentOwnersWarning, setShouldShowDifferentOwnersWarning] = useState<boolean>(false);

  const [shouldrenderForm, setShouldRenderForm] = useState<boolean>(!editMode);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const [loadingDialogSuccessText, setLoadingDialogSuccessText] = useState<string>(
    t(AppStrings.OtherTransactions.GeneralEntry.LoadingTransactionDataSuccessText)
  );
  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState<string>(
    t(AppStrings.OtherTransactions.GeneralEntry.LoadingTransactionData)
  );

  const [transactionData, setTransactionData] = useState<TransferDto | undefined>();
  const initFormValues = useMemo<TransferDto>(() => {
    if (editMode && transactionData) {
      return transactionData;
    }

    const transferDto = new TransferDto();
    transferDto.date = moment().format(DateFormats.ISO_DATE_SERVER_FORMAT).toString();
    transferDto.isZeroAmountTransfer = true;
    return transferDto;
  }, [editMode, transactionData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initFormValues,
    onSubmit: _.noop,
    validate: validateForm
  });

  const inputPadding = isMobile ? 0 : 10;

  useEffect(() => {
    if (editMode) {
      loadTransactionData();
    }

    getDefaultClearingAccount();
  }, [duplicateMode]);

  const getDefaultClearingAccount = async () => {
    const response = await accountsApi.getDefaultClearingAccount();
    if (response && response.data?.id) {
      const accountDictionary = await accountsApi.getDictionary();
      if (!accountDictionary[response.data.id]) {
        await accountsApi.getDictionary(true);
      }
      setDefaultClearingAccount(response.data.id);
    } else {
      showErrorMessage(response.message);
    }
  };

  const showErrorMessage = (errorMessage: string) => {
    setLoadingDialogErrorText(errorMessage);
    setLoadingDialogState(DialogState.Error);
  };

  const loadTransactionData = async () => {
    if (editMode && transactionId) {
      setLoadingDialogState(DialogState.Show);

      const response = (await transferApi.get(transactionId).catch((error) => {
        showErrorMessage(error);
      })) as ApiResult<TransferDto>;

      if (response?.data?.toProperty && response?.data.fromProperty) {
        const toProperty = await getProperty(response.data.toProperty);
        const fromProperty = await getProperty(response.data.fromProperty);
        const data = duplicateMode ? cleanTransferDataForDuplicateMode(response.data) : response.data;
        setSelectedToProperty(toProperty);
        setSelectedFromProperty(fromProperty);
        setTransferFromAccount(data.fromAccount);
        setTransferToAccount(data.toAccount);
        setTransactionData(data);
        setSelectedClearingAccount(data.clearingAccount);
        setLoadingDialogState(DialogState.Hidden);
        setShouldRenderForm(true);
        formik?.setFieldValue("isZeroAmountTransfer", true);
      } else if (response.message) {
        showErrorMessage(response.message);
      } else {
        showErrorMessage(t(AppStrings.Common.GeneralError));
      }
    }
  };

  const isValidForm = async (formikRef: FormikProps<TransferDto>) => {
    formikRef.setFieldTouched("date");
    formikRef.setFieldTouched("toProperty");
    formikRef.setFieldTouched("fromProperty");
    formikRef.setFieldTouched("clearingAccount");
    formikRef.setFieldTouched("fromAccount");
    formikRef.setFieldTouched("toAccount");
    formikRef.setFieldTouched("amount");
    const errors = (await formikRef.validateForm()) as any;

    return _.isEmpty(errors);
  };

  useEffectAsync(async () => {
    setShouldShowDifferentOwnersWarning(!checkIsOwnershipEqual());

    const isEqualsPropertyOrUnit = () => {
      const { fromProperty, toProperty, fromUnit, toUnit } = formik.values;
      const isUnitEqual = fromUnit && toUnit && fromUnit === toUnit;
      const isPropertyEqual = !fromUnit && !toUnit && fromProperty && toProperty && fromProperty === toProperty;

      return isUnitEqual || isPropertyEqual;
    };

    if (!isEqualsPropertyOrUnit()) {
      if (transferFromAccount && transferToAccount) {
        setShouldShowClearingAccount(true);
        formik?.setFieldValue("clearingAccount", defaultClearingAccount);
      } else {
        setShouldShowClearingAccount(false);
        formik?.setFieldValue("clearingAccount", undefined);
      }
    } else {
      setShouldShowClearingAccount(false);
      formik?.setFieldValue("clearingAccount", undefined);
    }
  }, [
    selectedFromProperty,
    selectedToProperty,
    transferFromAccount,
    transferToAccount,
    formik.values?.fromProperty,
    formik.values?.toProperty,
    formik.values?.fromUnit,
    formik.values?.toUnit
  ]);

  const checkIsOwnershipEqual = () => {
    const fromPropertyOwnersSorted = _.sortBy(selectedFromProperty?.owners, "owner");
    const toPropertyOwnersSorted = _.sortBy(selectedToProperty?.owners, "owner");

    return _.isEqual(fromPropertyOwnersSorted, toPropertyOwnersSorted);
  };

  const createTransfer = async (values: TransferDto) => {
    setViewIndex(DialogViews.CREATING_UPDATING);
    setLoadingDialogState(DialogState.Show);
    const response = (await transferApi.create(values).catch((error) => {
      setLoadingDialogState(DialogState.Error);
      setCreateTransferErrorText(error);
    })) as ApiResult<any>;

    if (response.status) {
      await filesApi.uploadFiles(attachments, response.data.id!, LinkedResourceType.Transfer).catch((error: string) => {
        setLoadingDialogState(DialogState.Error);
        setCreateTransferErrorText(error);
      });
      setLoadingDialogState(DialogState.Success);
      analyticsService.track(SegmentEventTypes.PAYABLE_TRANSACTION_CREATED, {
        transactionType: "Bank Transfer",
        payeeType: "Owner",
        isRecurring: false,
        isUnitEmpty: Boolean(values.toUnit || values.fromUnit),
        totalAmount: values.amount || 0,
        numOfLineItems: 0
      });
      didFinishOperation(values);
      formik.resetForm();
    } else {
      setCreateTransferErrorText(response.message);
      setLoadingDialogState(DialogState.Error);
    }
  };

  const updateTransaction = async () => {
    if (formik && transactionId) {
      const transferValues = _.cloneDeep(formik.values) as TransferDto;
      setViewIndex(DialogViews.CREATING_UPDATING);
      setShouldRenderForm(false);
      setLoadingDialogLoadingText(t(AppStrings.OtherTransactions.BankTransfer.UpdatingBankTransfer));
      setLoadingDialogState(DialogState.Show);

      const results = await transferApi.update(transactionId, transferValues).catch((error) => {
        showErrorMessage(error);
      });
      if (results && results.status && results.data) {
        await filesApi
          .uploadFiles(attachments, results.data.id!, LinkedResourceType.Transfer)
          .catch((error: string) => {
            showErrorMessage(error);
          });

        setLoadingDialogState(DialogState.Success);
        formik.resetForm();
        if (onClose) {
          onClose();
        } else {
          didFinishOperation(results.data);
        }
      } else {
        setLoadingDialogState(DialogState.Error);
        setCreateTransferErrorText(results && results.message ? results.message : t(AppStrings.Common.GeneralError));
        setViewIndex(DialogViews.CREATING_UPDATING);
      }
    }
  };

  const didPressSaveButton = async () => {
    if (formik !== null) {
      formik.setFieldTouched("toProperty");
      formik.setFieldTouched("fromProperty");
      formik.setFieldTouched("toAccount");
      formik.setFieldTouched("fromAccount");
      formik.setFieldTouched("amount");
      formik.setFieldTouched("clearingAccount");

      const isValid = await isValidForm(formik);
      if (isValid) {
        if (editMode && !duplicateMode) {
          await updateTransaction();
        } else {
          await createTransfer(formik.values);
        }
      }
    }
  };

  const handleDeleteClick = () => {
    setViewIndex(DialogViews.DELETING);
  };
  const handleDuplicateClick = () => {
    queryParams.set(DUPLICATE_MODE_QUERY_PARAMS.transactionId, transactionId || "");
    queryParams.historyPush();
    dispatchAnalytics("button_click", {
      label: DuplicateDialogButtonLabelValues.DUPLICATE_JOURNAL_ENTRY
    });
  };
  const renderHeaderActionButtons = () => (
    <DialogHeaderActionButtons
      onDeleteClick={handleDeleteClick}
      onDuplicateClick={handleDuplicateClick}
      hideDeleteButton={duplicateMode || !editMode}
      hideDuplicateButton={duplicateMode || !editMode}
    />
  );

  const renderActionPanelButtons = () => {
    if (viewIndex === 1) {
      return <div />;
    }

    return (
      <FormActionButtons
        propsActionPanel={{
          editMode
        }}
        propsSubButton={{ onClick: onBackdropClick }}
        propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
      />
    );
  };

  const didPressDismissButton = () => {
    setSelectedFromProperty(undefined);
    setTransferToAccount(undefined);
    setTransferFromAccount(undefined);
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(DialogViews.DISMISS);
  };

  const onRetryButtonPress = async () => {
    if (editMode) {
      await updateTransaction();
    } else {
      await didPressSaveButton();
    }
  };

  const onFileReceived = (files: FileListItemProps[]) => {
    setAttachments(files);
  };

  const renderAttachments = () => (
    <View justifyContent={"flex-end"} flex={1} width={"100%"}>
      <View marginBottom={20} paddingLeft={screenContainerPadding} paddingRight={screenContainerPadding}>
        <FormAttachments
          onFileReceived={onFileReceived}
          files={attachments}
          editMode={editMode}
          resourceId={transactionData?.id}
          resourceType={LinkedResourceType.Transfer}
        />
      </View>
    </View>
  );

  const didSelectFromProperty = async (event, value: any) => {
    if (value === null) {
      formik?.setFieldValue("fromUnit", undefined);
      setSelectedFromProperty(undefined);
      setTransferToAccount(undefined);
      formik?.setFieldValue("fromAccount", undefined);
      setTransferFromAccount(undefined);
      formik?.setFieldValue("toAccount", undefined);
      setSelectedClearingAccount(undefined);
      formik?.setFieldValue("clearingAccount", undefined);
    } else if (formik?.values.fromProperty !== value.id && event.type === "click") {
      const property = await getProperty(value.id);
      setSelectedFromProperty(property);
      if (!selectedToProperty) {
        formik?.setFieldValue("toProperty", value.id);
        setSelectedToProperty(property);
      }
    }
  };

  const didSelectToProperty = async (event, value: any) => {
    if (value === null) {
      formik?.setFieldValue("toUnit", undefined);
      setSelectedToProperty(undefined);
      setTransferToAccount(undefined);
      formik?.setFieldValue("transferToAccount", undefined);
      setTransferFromAccount(undefined);
      formik?.setFieldValue("transferFromAccount", undefined);
      setSelectedClearingAccount(undefined);
      formik?.setFieldValue("clearingAccount", undefined);
    } else if (formik?.values.toProperty !== value.id && event.type === "click") {
      const property = await getProperty(value.id);
      setSelectedToProperty(property);
    }
  };

  const getProperty = async (propertyId: string) => {
    const property = await propertiesApi.get(propertyId);

    if (property.status) {
      return property.data;
    }
    showErrorMessage(property.message);
  };

  const didSelectTransferFromAccount = (event: object, value: any) => {
    if (value === null) {
      setTransferFromAccount(undefined);
    } else {
      setTransferFromAccount(value.id);
    }
  };

  const didSelectTransferToAccount = (event: object, value: any) => {
    if (value === null) {
      setTransferToAccount(undefined);
    } else {
      setTransferToAccount(value.id);
    }
  };

  const didSelectClearingAccount = (event: object, value: any) => {
    if (value === null) {
      setSelectedClearingAccount(undefined);
    } else {
      setSelectedClearingAccount(value.id);
    }
  };

  const renderFields = () => (
    <>
      <View fullWidth flexDirection={"row"} paddingTop={20}>
        <View width={isMobile ? "100%" : "47%"}>
          <View>
            <Text
              value={AppStrings.OtherTransactions.BankTransfer.TransferFrom}
              fontSize={12}
              bold
              color={"secondary-gray"}
            />
            <FormikCachedAsyncAutoComplete
              label={t(AppStrings.OtherTransactions.BankTransfer.SourceProperty)}
              uniqueIndex={0}
              apiHandler={propertiesApi}
              displayNameKey={"name"}
              filterFieldName={"filter_text"}
              filterFieldValue={"name"}
              selectionFields={["id", "class"]}
              name={"fromProperty"}
              marginTop={20}
              required
              onChange={didSelectFromProperty}
              defaultValue={selectedFromProperty}
              queryParams={{ filter_active: true }}
            />
            <FormikCachedAsyncAutoComplete
              label={t(AppStrings.Common.FilterOptions.Unit)}
              uniqueIndex={0}
              apiHandler={unitsApi}
              displayNameKey={"name"}
              filterFieldName={"filter_text"}
              filterFieldValue={"name"}
              selectionFields={["id", "class"]}
              name={"fromUnit"}
              disabled={!selectedFromProperty}
              marginTop={20}
              queryParams={{ filter_active: true, filter_property: selectedFromProperty?.id }}
            />
            <BankAccountFormikAutoCompleteField
              addCreateOption
              uniqueIndex={"fromAccount"}
              name={"fromAccount"}
              queryParams={{
                filter_types: [AccountType.ASSET_BANK, AccountType.LIABILITY_CREDIT_CARD],
                filter_active: true
              }}
              label={t(AppStrings.OtherTransactions.BankTransfer.SourceAccount)}
              marginTop={20}
              onChange={didSelectTransferFromAccount}
              defaultValue={transferFromAccount}
            />
            <AccountBalanceLoader
              propertyId={selectedFromProperty?.id}
              depositAccountId={transferFromAccount}
              marginTop={20}
            />
          </View>
        </View>
        <View width={isMobile ? "100%" : "6%"} height={isMobile ? "auto" : "100%"}>
          <View alignItems={"center"} justifyContent={"center"} height={"75%"} paddingTop={20} paddingBottom={10}>
            <Icon Source={isMobile ? ArrowDownIcon : ArrowRightIcon} pathColor={"black"} />
          </View>
        </View>
        <View width={isMobile ? "100%" : "47%"}>
          <Text
            value={AppStrings.OtherTransactions.BankTransfer.TransferTo}
            fontSize={12}
            bold
            color={"secondary-gray"}
          />
          <View>
            <FormikCachedAsyncAutoComplete
              label={t(AppStrings.OtherTransactions.BankTransfer.ReceivingProperty)}
              uniqueIndex={1}
              apiHandler={propertiesApi}
              displayNameKey={"name"}
              filterFieldName={"filter_text"}
              filterFieldValue={"name"}
              selectionFields={["id", "class"]}
              name={"toProperty"}
              marginTop={20}
              required
              onChange={didSelectToProperty}
              defaultValue={selectedFromProperty}
              queryParams={{ filter_active: true }}
            />
            <FormikCachedAsyncAutoComplete
              label={t(AppStrings.Common.FilterOptions.Unit)}
              uniqueIndex={0}
              apiHandler={unitsApi}
              displayNameKey={"name"}
              filterFieldName={"filter_text"}
              filterFieldValue={"name"}
              selectionFields={["id", "class"]}
              name={"toUnit"}
              marginTop={20}
              disabled={!selectedToProperty}
              queryParams={{ filter_active: true, filter_property: selectedToProperty?.id }}
            />
            <BankAccountFormikAutoCompleteField
              addCreateOption
              uniqueIndex={"toAccount"}
              name={"toAccount"}
              queryParams={{
                filter_types: [AccountType.ASSET_BANK, AccountType.LIABILITY_CREDIT_CARD],
                filter_active: true
              }}
              label={t(AppStrings.OtherTransactions.BankTransfer.ReceivingAccount)}
              marginTop={20}
              onChange={didSelectTransferToAccount}
              defaultValue={transferToAccount}
            />
            <AccountBalanceLoader
              propertyId={selectedToProperty?.id}
              depositAccountId={transferToAccount}
              marginTop={20}
            />
          </View>
        </View>
      </View>
    </>
  );

  const renderAmountFields = () => (
    <>
      <SeparationLine marginTop={20} width={"100%"} height={1} />
      {shouldShowDifferentOwnersWarning && (
        <WarningView type={"info"} iconSize={20} marginTop={20}>
          <Text
            value={AppStrings.OtherTransactions.BankTransfer.DifferentOwnershipTransferWarning}
            fontSize={14}
            color={"blue"}
          />
        </WarningView>
      )}
      {shouldShowClearingAccount && (
        <WarningView type={"info"} iconSize={20} marginTop={20}>
          <Text
            value={AppStrings.OtherTransactions.BankTransfer.ZeroAmountTransferWarning}
            fontSize={14}
            color={"blue"}
          />
        </WarningView>
      )}
      <View flexDirection={"row"} paddingTop={20} gap={20} noWrap={!isMobile}>
        {shouldShowClearingAccount && (
          <Grid item xs={12} sm={6}>
            <BankAccountFormikAutoCompleteField
              addCreateOption
              uniqueIndex={"clearingAccount"}
              name={"clearingAccount"}
              queryParams={{
                filter_types: [AccountType.EQUITY_EQUITY],
                filter_active: true
              }}
              label={t(AppStrings.OtherTransactions.BankTransfer.ClearingAccount)}
              onChange={didSelectClearingAccount}
              defaultValue={selectedClearingAccount}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <FastField
            component={TextField}
            name={"amount"}
            type={"number"}
            required
            formatType={"currency"}
            label={t(AppStrings.OtherTransactions.BankTransfer.AmountToTransfer)}
            paddingRight={inputPadding}
          />
        </Grid>
      </View>
    </>
  );

  const renderForm = () => (
    <FormikContext.Provider value={formik}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <View flex={1} width={"100%"}>
          <View paddingLeft={screenContainerPadding} paddingRight={screenContainerPadding} flexDirection={"column"}>
            <ReconciledNotificationView register={transactionData?.register} />

            <View flexDirection={"row"} gap={20} noWrap={!isMobile} paddingTop={20}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <View>
                  <FastField
                    component={FormikDatePicker}
                    uniqueKey={"date"}
                    label={AppStrings.OtherTransactions.BankTransfer.TransferDate}
                    name={"date"}
                    noMargin
                    required
                    dataCy={DataCy.bankTransfer.dialog.transferDate}
                  />
                </View>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FastField component={FormikReferenceLabel} name={"reference"} backgroundColor={"dark"} />
              </Grid>
            </View>

            <SeparationLine marginTop={20} width={"100%"} height={1} />
            {renderFields()}
            {renderAmountFields()}
            <SeparationLine marginTop={20} marginBottom={20} width={"100%"} height={1} />
            <FastField component={Notes} height={30} name={"memo"} />
          </View>
          {renderAttachments()}
          {editMode && <ActivityLabel item={transactionData} />}
        </View>
      </MuiPickersUtilsProvider>
    </FormikContext.Provider>
  );

  const renderView = ({ index }: any) => {
    if (index === 0) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        const onRetryLoadInfoButtonPress = () => {
          loadTransactionData();
        };
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={onRetryLoadInfoButtonPress}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }

      if (shouldrenderForm) {
        return renderForm();
      }
      return <div />;
    }
    if (index === 1) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={t(loadingDialogLoadingText)}
            errorText={createTransferErrorText}
            successText={t(AppStrings.OtherTransactions.BankTransfer.CreateTransferSuccessText)}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
          />
        </View>
      );
    }
    if (index === 2) {
      return (
        <DeleteConfirmation
          apiMethod={transferApi}
          didPressDismissButton={didPressDismissDeleteButton}
          didFinishOperation={onClose}
          transactionId={transactionId}
          attachments={attachments}
        />
      );
    }
    return <div />;
  };

  const didPressDismissDeleteButton = () => {
    setViewIndex(DialogViews.DISMISS);
  };

  const _onBackdropClick = () => {
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }

    if (viewIndex === 1 || viewIndex === 2) {
      return "contentOnly";
    }
    return "sectionTitleFrame";
  }, [viewIndex, loadingDialogState]);

  const duplicateModeTitle = useMemo(
    () =>
      duplicateMode ? `${t(AppStrings.Common.CreateNewMenu.BankTransfer)} (${t(AppStrings.Common.Duplicated)})` : "",
    [duplicateMode, t]
  );

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={duplicateModeTitle || dialogTitle}
      width={getDialogFrameDimension("width", 740)}
      height={getDialogFrameDimension("height", 730)}
      renderView={renderView}
      numViews={3}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      RenderHeaderActionButtons={renderHeaderActionButtons}
      frameType={frameType}
    />
  );
};

export default BankTransferDialog;
