import type { FC } from "react";
import { TextTooltip } from "DLUI/popover/textTooltip";
import { useTypedTranslation } from "locale";
import { useSelector } from "react-redux";
import type { IRootState } from "store";
import type { GetStripeAccountLinkRequestDto } from "@doorloop/dto";
import { useFormikContext } from "formik";

export const TosRequiredTooltip: FC = ({ children }) => {
  const { t } = useTypedTranslation();
  const { currentLoginResponse } = useSelector((state: IRootState) => state.auth);
  const formik = useFormikContext<GetStripeAccountLinkRequestDto>();

  return (
    <TextTooltip
      show={
        (!currentLoginResponse?.acceptedOnTOS && !formik?.values.acceptedOnTOS) ||
        !formik?.values.acceptedOnRapidRentTOS
      }
      useButton={false}
      value={t("common.mustAcceptTOS")}
    >
      {children}
    </TextTooltip>
  );
};
