import React, { useState } from "react";

import _ from "lodash";

import type { FileDto } from "@doorloop/dto";

import AppStrings from "../../../locale/keys";
import Text from "DLUI/text";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { FilePreviewSupportedMimeType } from "screens/files/filePreviewSupportedMimeType";
import { filesApi } from "api/filesApi";
import type { FrameType } from "DLUI/dialogs/components/dialogFrame";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import { QueryParams } from "../../../utils/queryParams";
import { useEffectAsync } from "../../../hooks/useEffectAsync";
import { View } from "DLUI/view";

interface ComponentProps {
  onBackdropClick: () => void;
  refreshEvent?: () => void;
  dialogTitle: string;
}

enum DialogView {
  Loading,
  FilePreview
}

const NUMBER_OF_VIEWS = _.size(DialogView);

export const FilePreviewDialog: React.FC<ComponentProps> = ({ onBackdropClick }: ComponentProps) => {
  const fileId = QueryParams.get("fileId");

  const [file, setFile] = useState<FileDto | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  const [viewIndex, setViewIndex] = useState<DialogView>(DialogView.Loading);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>();

  const dialogWidth = getDialogFrameDimension("width", 1100);
  const dialogHeight = window.innerHeight - 80;
  const filePreviewMaxHeight = dialogHeight - 61;

  useEffectAsync(async (): Promise<void> => {
    await loadFile();
  }, []);

  const loadFile = async (): Promise<void> => {
    if (!fileId || _.isArray(fileId)) {
      onBackdropClick();
      return;
    }

    const [fileResult, objectUrlResult] = await Promise.all([filesApi.get(fileId), filesApi.getObjectUrl(fileId)]);

    if (!fileResult.status || !fileResult.data) {
      showError(fileResult.message);
      return;
    }

    if (!objectUrlResult.data || !objectUrlResult.data) {
      showError(objectUrlResult.message);
      return;
    }

    setFile(fileResult.data);
    setFileUrl(objectUrlResult.data);
    setViewIndex(DialogView.FilePreview);
  };

  const showError = (message: string): void => {
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(message);
  };

  const renderFilePreview = () => {
    if (!file || !fileUrl) {
      return null;
    }

    switch (file.mimeType) {
      case FilePreviewSupportedMimeType.PDF:
        return (
          <iframe
            src={fileUrl}
            style={{
              border: "none",
              marginTop: "16px"
            }}
            width={"100%"}
            height={"100%"}
          ></iframe>
        );
      case FilePreviewSupportedMimeType.JPEG:
      case FilePreviewSupportedMimeType.PNG:
        return (
          <img
            src={fileUrl}
            width={"100%"}
            height={"100%"}
            style={{
              marginTop: 16,
              maxHeight: filePreviewMaxHeight,
              objectFit: "contain"
            }}
            alt={file.name}
          />
        );
      default:
        return (
          <View
            justifyContent={"center"}
            marginTop={16}
            height={"100%"}
            maxHeight={filePreviewMaxHeight}
            alignItems={"center"}
          >
            <Text variant={"title"} value={AppStrings.Common.FileNotSupported} />
          </View>
        );
    }
  };

  const renderView = ({ index }: { index: DialogView }) => {
    if (index === DialogView.FilePreview) {
      return renderFilePreview();
    }
    if (index === DialogView.Loading) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            errorText={loadingDialogErrorText}
            onRetryButtonPress={loadFile}
            didPressDismissButton={onBackdropClick}
          />
        </View>
      );
    }

    return null;
  };

  const frameType: FrameType = viewIndex === DialogView.Loading ? "contentOnly" : "sectionTitleFrame";

  return (
    <DialogFrame
      onCloseButtonClick={onBackdropClick}
      title={AppStrings.Common.FilePreview}
      width={dialogWidth}
      height={dialogHeight}
      renderView={renderView}
      numViews={NUMBER_OF_VIEWS}
      activeView={viewIndex}
      frameType={frameType}
    />
  );
};
