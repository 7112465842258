import type { PropsWithChildren } from "react";
import React from "react";
import type { GridPageChangeEvent, GridProps as KendoGridProps } from "@progress/kendo-react-grid";
import { Grid as BaseGrid } from "@progress/kendo-react-grid";
import clsx from "clsx";
import type { DataResult } from "@progress/kendo-data-query";

const VirtualGrid = ({ children, ...kendoProps }: PropsWithChildren<KendoGridProps> & { data?: DataResult }) => {
  const [skip, setSkip] = React.useState<number>(0);

  const pageChange = (event: GridPageChangeEvent) => {
    setTimeout(() => setSkip(event.page.skip), 100);
  };

  return (
    <BaseGrid
      {...kendoProps}
      className={clsx("virtual-grid", kendoProps.className)}
      data={kendoProps.data?.data.slice(skip, skip + 25)}
      pageSize={25}
      rowHeight={30}
      total={kendoProps.data?.total}
      skip={skip}
      scrollable={"virtual"}
      onPageChange={pageChange}
    >
      {children}
    </BaseGrid>
  );
};

export default VirtualGrid;
