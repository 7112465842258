import { AuthLogoIcon, PageNotFoundBackground } from "assets/icons";
import { Icon } from "DLUI/icon";
import { ListItemContainerDefaultMinWidth } from "DLUI/infiniteList/utils";
import Text from "DLUI/text";
import { BackgroundImageView, View } from "DLUI/view";
import AppStrings from "locale/keys";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavigationManager } from "utils/navigation";
import DLButton, { DLButtonSizesEnum } from "DLUI/button/dlButton";

const MobileNotSupportedView: React.FC = () => {
  const { t } = useTranslation();
  const headerFontSize = 40;
  const subHeaderFontSize = 20;
  const pageHeight = window.innerHeight - 60;
  const didPressHomepage = () => {
    NavigationManager.viewHome();
  };

  const iconSize = useMemo(() => {
    let _iconSize = window.innerWidth - 40;
    if (_iconSize > ListItemContainerDefaultMinWidth) {
      _iconSize = ListItemContainerDefaultMinWidth;
    }
    return _iconSize;
  }, []);

  return (
    <View height={pageHeight} width={"100%"} flexDirection={"column"}>
      <BackgroundImageView
        height={pageHeight}
        width={window.innerWidth}
        publicFileName={"url(/images/404_background_drawing.jpg)"}
        backgroundSize={"cover"}
      >
        <View width={"100%"} flex={1} alignItems={"center"} justifyContent={"center"}>
          <Icon Source={AuthLogoIcon} width={200} />
        </View>
        <View width={"100%"} flex={1} alignItems={"center"} justifyContent={"center"}>
          <Text align={"center"} fontSize={headerFontSize} color={"black"}>
            {t(AppStrings.Common.RestrictedOnMobileHeader)}
          </Text>
          <Text
            marginTop={20}
            paddingRight={20}
            paddingLeft={20}
            align={"center"}
            fontSize={subHeaderFontSize}
            color={"black"}
          >
            {t(AppStrings.Common.RestrictedOnMobile)}
          </Text>

          <DLButton
            onClick={didPressHomepage}
            actionText={AppStrings.Common.PageNotFound.ButtonText}
            size={DLButtonSizesEnum.LARGE}
            style={{ marginTop: 30 }}
          />
        </View>
        <View alignItems={"center"} justifyContent={"flex-end"} width={"100%"} flex={1}>
          <Icon width={iconSize} Source={PageNotFoundBackground} />
        </View>
      </BackgroundImageView>
    </View>
  );
};

export default MobileNotSupportedView;
