import {
  DataCy,
  RecurringTransactionStatus,
  RecurringTransactionType,
  ReportId,
  ReportPermission
} from "@doorloop/dto";

import AppStrings from "locale/keys";
import { ownerPortalNavigation } from "utils/ownerPortalNavigation";
import type { ReportScreenItemType } from "DLUI/report/screen/reportScreenItemType";

const didPressAccountsReceivableAgingDetail = (): void => {
  ownerPortalNavigation.runAccountReceivableAgingDetailReport();
};

const didPressAccountsReceivableAgingDetailByAccount = (): void => {
  ownerPortalNavigation.runAccountReceivableAgingDetailByAccountReport();
};

const didPressAccountsReceivableAgingSummary = (): void => {
  ownerPortalNavigation.runAccountReceivableAgingSummaryReport();
};

const didPressRecurringTransactionsDetail = (propertyId?: string): void => {
  const params = {
    filter_type: RecurringTransactionType.LEASE_RENT,
    filter_status: RecurringTransactionStatus.ACTIVE
  };

  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  ownerPortalNavigation.runRecurringTransactionsDetailReport(params);
};

export const WHO_OWES_YOU_REPORT_ITEMS: ReportScreenItemType[] = [
  {
    description: AppStrings.Reports.ReportsScreen.AccountsReceivableAgingDetail,
    onPress: didPressAccountsReceivableAgingDetail,
    permission: ReportPermission.accountsReceivable,
    id: ReportId.ACCOUNT_RECEIVABLE_AGING_DETAILS,
    dataCy: DataCy.reports.screenItems.whoOwesYou.arAgingDetail
  },
  {
    description: AppStrings.Reports.ReportsScreen.AccountsReceivableAgingDetailByAccount,
    onPress: didPressAccountsReceivableAgingDetailByAccount,
    permission: ReportPermission.accountsReceivable,
    id: ReportId.ACCOUNT_RECEIVABLE_AGING_DETAILS_BY_ACCOUNT,
    dataCy: DataCy.reports.screenItems.whoOwesYou.arAgingDetailByAccount
  },
  {
    description: AppStrings.Reports.ReportsScreen.AccountsReceivableAgingSummary,
    onPress: didPressAccountsReceivableAgingSummary,
    permission: ReportPermission.accountsReceivable,
    id: ReportId.ACCOUNT_RECEIVABLE_AGING_SUMMARY,
    dataCy: DataCy.reports.screenItems.whoOwesYou.arAgingSummary
  },
  {
    description: AppStrings.Reports.ReportsScreen.RecurringTransactionsDetail,
    onPress: didPressRecurringTransactionsDetail,
    permission: ReportPermission.recurringTransactions,
    id: ReportId.RECURRING_TRANSACTIONS_DETAIL,
    dataCy: DataCy.reports.screenItems.whoOwesYou.recurringRentTransactions
  }
];
