import React, { useEffect, useMemo, useState } from "react";
import MomentUtils from "@date-io/moment";
import type { LeaseDto, LeaseRenewalOfferDto, TenantDto, UnitDto } from "@doorloop/dto";
import {
  createValidator,
  DisplayLeaseDraftTenantDto,
  LinkedResourceType,
  MoveOutTenantDto,
  SegmentEventTypes
} from "@doorloop/dto";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import type { ApiResult } from "api/apiResult";
import { leasesApi, leasesMoveOutTenantApi } from "api/leasesApi";
import { AddSquareButton, SelectionButton } from "DLUI/button";
import { TenantSelectionView } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { LoadingDialog } from "DLUI/dialogs/components/loading";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import { FormikDatePicker, ValidationIndicator } from "DLUI/form";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, Formik, getIn } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import DialogFrame from "../components/dialogFrame";
import CurrentTermsItem from "./currentTermsItem";
import MakeStyle from "./styles";
import { filesApi } from "api/filesApi";
import { DialogsHelper } from "../dialogsHelper";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { ArticleIdsEnum, HelpTypeEnum, VideoUrlsEnum } from "DLUI/screen/helpPanel/types";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { analyticsService } from "../../../../services/analyticsService";

interface ComponentProps {
  didFinishOperation: (values: UnitDto) => void;
  onBackdropClick: () => void;
  refreshEvent?: () => void;
  dialogTitle: string;
}

let formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

const helpObject: HelpPanelProps = {
  description: AppStrings.Tenants.MoveTenants.HelpPanel.Out.Description,
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Tenants.MoveTenants.HelpPanel.Out.Move,
      articleId: ArticleIdsEnum.MOVE_OUT_TENANT
    },
    {
      type: HelpTypeEnum.WATCH_VIDEO,
      topic: AppStrings.Common.WatchExample,
      href: VideoUrlsEnum.MOVE_OUT_TENANT,
      dialogTitle: AppStrings.Tenants.MoveTenants.HelpPanel.Out.DialogTitle
    }
  ]
};

const validateForm = createValidator(MoveOutTenantDto);

const MoveOutTenants: React.FC<ComponentProps> = ({
  didFinishOperation,
  refreshEvent,
  onBackdropClick,
  dialogTitle
}: ComponentProps) => {
  const { t } = useTranslation();
  const { dialogHorizontalPadding } = DialogsHelper();
  const location = useLocation<any>();
  const classes = MakeStyle();
  const leaseData: LeaseDto = location.state && location.state.leaseData ? location.state.leaseData : undefined;

  const [viewIndex, setViewIndex] = useState(0);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);

  const [moveOuttenantErrorText, setMoveOuttenantErrorText] = useState<string>(
    t(AppStrings.Tenants.MoveTenants.MoveOutCreateErrorText)
  );

  const defaultCurrentTenantsSelection: DisplayLeaseDraftTenantDto[] =
    location.state && location.state.defaultCurrentTenantsSelection
      ? location.state.defaultCurrentTenantsSelection
      : [];
  const [currentTenantsSelection, setCurrentTenantsSelection] =
    useState<DisplayLeaseDraftTenantDto[]>(defaultCurrentTenantsSelection);

  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);

  const [renderTenantListPicker, setRenderTenantListPicker] = useState<boolean>(false);

  useEffect(() => {
    setTenantsIds(defaultCurrentTenantsSelection);

    return () => {
      formikGlobalRef = null;
    };
  }, []);

  const initFormvalues = (): MoveOutTenantDto => {
    if (formikGlobalRef && formikGlobalRef.values) {
      return formikGlobalRef.values;
    }
    const moveOutTenantDto = new MoveOutTenantDto();
    if (leaseData) {
      moveOutTenantDto.leaseId = leaseData.id;
      moveOutTenantDto.movedOutAt = moment().format("YYYY-MM-DD").toString();
      moveOutTenantDto.moveOutNoticeAt = moment().format("YYYY-MM-DD").toString();

      // moveOutTenantDto.portalEnabled = true;
      moveOutTenantDto.tenants = [];
    }
    return moveOutTenantDto;
  };

  const isValidForm = async (formikRef: FormikProps<LeaseRenewalOfferDto>) => {
    formikRef.setFieldTouched("leaseId");
    formikRef.setFieldTouched("movedOutAt");
    formikRef.setFieldTouched("moveOutNoticeAt");
    formikRef.setFieldTouched("tenants");
    const lease = leasesApi.getItemFromDictionary(formikGlobalRef?.values?.leaseId);
    if (lease) {
      const moveoutTenantList = formikGlobalRef?.values?.tenants;
      if (moveoutTenantList?.length >= lease?.currentTenants?.length) {
        setTimeout(() => {
          formikGlobalRef?.setFieldError(
            "tenants",
            t(AppStrings.Common.AtLeastOneTenantNeedsToBeAssociatedWithTheLease)
          );
        }, 700);
        return false;
      }
    }

    const errors = (await formikRef.validateForm()) as any;
    return _.isEmpty(errors);
  };

  const moveOutTenant = async () => {
    if (formikGlobalRef !== null) {
      const response = (await leasesMoveOutTenantApi.create(formikGlobalRef.values).catch(() => {
        setLoadingDialogState(DialogState.Error);
      })) as ApiResult<any>;

      if (response.status !== undefined && !response.status) {
        setMoveOuttenantErrorText(response.message);
        setLoadingDialogState(DialogState.Error);
      } else {
        await filesApi
          .uploadFiles(attachments, formikGlobalRef.values.leaseId, LinkedResourceType.Lease)
          .catch((error: string) => {
            setLoadingDialogState(DialogState.Error);
            setMoveOuttenantErrorText(error);
          });
        setLoadingDialogState(DialogState.Success);
        await didFinishMoveTenant();
        formikGlobalRef = null;
      }
    }
  };

  const didPressSaveButton = async () => {
    if (formikGlobalRef !== null) {
      const isValid = await isValidForm(formikGlobalRef);
      if (isValid) {
        setTimeout(() => {
          setViewIndex(2);
        }, 500);

        setTimeout(() => {
          setLoadingDialogState(DialogState.Show);
          moveOutTenant();
        }, 700);
      }
    }
  };

  const renderActionPanelButtons = () => {
    if (viewIndex === 1) {
      return <div />;
    }

    return (
      <FormActionButtons
        propsSubButton={{ onClick: onBackdropClick }}
        propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
      />
    );
  };

  const didPressDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setRenderTenantListPicker(false);
    setViewIndex(0);
  };

  const clearForm = () => {};

  const onRetryButtonPress = () => {
    if (formikGlobalRef !== null) {
      clearForm();
      setLoadingDialogState(DialogState.Hidden);
      setViewIndex(0);
    }
  };

  const didFinishMoveTenant = async () => {
    if (formikGlobalRef !== null) {
      await leasesApi.getDictionary(true);
      analyticsService.track(
        SegmentEventTypes.MOVE_OUT_TENANT_SAVED,
        {
          numOfCurrentTenants: leaseData?.id ? leasesApi.getItemFromDictionary(leaseData.id).tenants?.length : 0
        },
        { trackEventInIntercom: true }
      );

      didFinishOperation(formikGlobalRef.values);
      if (refreshEvent) {
        refreshEvent();
      }
      setLoadingDialogState(DialogState.Hidden);
    }
  };

  const didPressSelectTenant = () => {
    setViewIndex(1);
    setTimeout(() => {
      setRenderTenantListPicker(true);
    }, 300);
  };

  const didPressDeleteButton = (itemIndex: number) => {
    if (formikGlobalRef) {
      currentTenantsSelection.splice(itemIndex, 1);
      setCurrentTenantsSelection(currentTenantsSelection);
      setTenantsIds(currentTenantsSelection);
    }
  };

  const renderSelectionList = () => {
    let listItems: any = null;
    if (currentTenantsSelection) {
      listItems = currentTenantsSelection.map((currentItem, index) => (
        <div key={"SB" + index}>
          <SelectionButton
            didPressDeleteButton={didPressDeleteButton}
            name={currentItem.name}
            pictureUrl={currentItem.pictureUrl}
            index={index}
          />
        </div>
      ));
    }
    return (
      <View marginTop={10} justifyContent={"center"} flexDirection={"row"}>
        {listItems}
        <AddSquareButton
          label={AppStrings.Leases.NewLease.LeaseTenants.AddTenant}
          onAddButtonPress={didPressSelectTenant}
        />
      </View>
    );
  };

  const onFileReceived = (files: FileListItemProps[]) => {
    setAttachments(files);
  };

  const renderAttachments = () => (
    <View justifyContent={"flex-end"} width={"100%"} flex={1} marginBottom={20}>
      <FormAttachments onFileReceived={onFileReceived} files={attachments} />
    </View>
  );

  const renderValidationMessage = () => {
    if (formikGlobalRef) {
      const errorText = getIn(formikGlobalRef.errors, "tenants");
      const touchedVal = getIn(formikGlobalRef.touched, "tenants");

      const hasError = touchedVal && errorText !== undefined;

      return (
        <View alignItems={"center"} justifyContent={"center"}>
          <ValidationIndicator
            shouldShow={hasError || false}
            maxWidth={420}
            displayText={errorText || ""}
            marginTop={"20px"}
          />
        </View>
      );
    }
  };

  const renderForm = () => {
    const formInitialValues = initFormvalues();
    return (
      <Formik initialValues={formInitialValues} onSubmit={(values, { setSubmitting }) => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;

          return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <View width={"100%"} flex={1}>
                <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding} height={"100%"}>
                  <CurrentTermsItem leaseData={leaseData} />
                  <View marginTop={20} justifyContent={"center"} alignItems={"center"}>
                    <Text value={AppStrings.Tenants.MoveTenants.WhoIsMovingOut} fontSize={20} color={"black"} />
                  </View>
                  {renderSelectionList()}
                  {renderValidationMessage()}
                  <View flexDirection={"row"} justifyContent={"center"} alignItems={"center"} marginTop={40}>
                    <Grid className={classes.gridPadding} item xs={4} md={4} lg={4}>
                      <FastField
                        component={FormikDatePicker}
                        uniqueKey={"moveOutNoticeAt"}
                        label={AppStrings.Tenants.MoveTenants.NoticeDate}
                        name={"moveOutNoticeAt"}
                        noMargin
                        required
                        size={"100%"}
                      />
                    </Grid>
                    <Grid className={classes.gridPadding} item xs={4} md={4} lg={4}>
                      <FastField
                        component={FormikDatePicker}
                        uniqueKey={"movedOutAt"}
                        label={AppStrings.Tenants.MoveTenants.MoveOutDate}
                        name={"movedOutAt"}
                        noMargin
                        required
                        size={"100%"}
                      />
                    </Grid>
                  </View>

                  {renderAttachments()}
                </View>
              </View>
            </MuiPickersUtilsProvider>
          );
        }}
      </Formik>
    );
  };

  const setTenantsIds = (tenantSelection: DisplayLeaseDraftTenantDto[]) => {
    if (!formikGlobalRef) {
      return;
    }

    const formikIdsArray: string[] = tenantSelection.map(({ id }) => id!);
    formikGlobalRef.setFieldValue("tenants", formikIdsArray);
  };

  const didSelectTenantItem = (selectedItem: TenantDto) => {
    if (selectedItem) {
      const newTenenat = new DisplayLeaseDraftTenantDto();
      const previousSelectionIndex = currentTenantsSelection.findIndex((currentItem) => {
        if (selectedItem) {
          return currentItem.id === selectedItem.id;
        }
      });
      if (previousSelectionIndex > -1) {
        setViewIndex(0);
        setRenderTenantListPicker(false);
        return;
      }
      newTenenat.id = selectedItem.id;
      newTenenat.name = selectedItem.name;
      newTenenat.pictureUrl = selectedItem.pictureUrl;
      currentTenantsSelection.push(newTenenat);

      setCurrentTenantsSelection(currentTenantsSelection);
      setTenantsIds(currentTenantsSelection);

      setTimeout(() => {
        setRenderTenantListPicker(false);
        setViewIndex(0);
      }, 300);
    }
  };

  const renderView = ({ index }: any) => {
    if (index === 0) {
      return renderForm();
    }
    if (index === 1 && renderTenantListPicker) {
      return (
        <TenantSelectionView
          showAddNewTenant={false}
          didSelectTenantItem={didSelectTenantItem}
          height={800}
          filterObj={{
            filter_lease: leaseData ? leaseData.id! : ""
          }}
        />
      );
    }
    if (index === 2) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={t(AppStrings.Common.Loading)}
            errorText={moveOuttenantErrorText}
            successText={t(AppStrings.Units.NewUnitDialog.SuccessText)}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
          />
        </View>
      );
    }
    return <div />;
  };

  const _onBackdropClick = () => {
    if (viewIndex === 1) {
      setViewIndex(0);
      setRenderTenantListPicker(false);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const _dialogTitle = useMemo(() => {
    let returnValue = "";
    if (viewIndex === 0) {
      returnValue = dialogTitle;
    }
    if (viewIndex === 1) {
      returnValue = AppStrings.Tenants.MoveTenants.SelectTenant;
    }
    return returnValue;
  }, [viewIndex]);

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      return "sectionTitleFrame";
    }
    if (viewIndex === 1) {
      return "topPanel";
    }
    if (viewIndex === 2) {
      return "contentOnly";
    }
    return "contentOnly";
  }, [viewIndex]);

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={_dialogTitle}
      width={962}
      height={800}
      renderView={renderView}
      numViews={3}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={frameType}
      helpPanel={helpObject}
    />
  );
};

export default MoveOutTenants;
