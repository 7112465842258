import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    componentContainer: {
      width: "100%",
      height: "50px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 10
    },
    separatorView: {
      height: "6px",
      width: "100%",
      backgroundImage: "linear-gradient(to right, gray 33%, rgb(222, 225, 237) 0%)",
      backgroundPosition: "center",
      backgroundSize: "5px 1px",
      backgroundRepeat: "repeat-x",
      margin: "0px 10px"
    },
    labelItemContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center"
    }
  })
);
