import { makeStyles } from "@material-ui/styles";
import type { Theme } from "styles/defaultTheme";

export const useManagementFeesStyles = makeStyles<Theme>((theme) => {
  return {
    placeContainer: {
      backgroundColor: theme.newPalette.action.hover,
      width: 22,
      height: 22,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 4,
      marginInlineEnd: 8
    },
    formContainer: ({ screenContainerPadding = 0 }: Partial<{ screenContainerPadding: number }>) => {
      return {
        paddingLeft: screenContainerPadding,
        paddingRight: screenContainerPadding,
        paddingBottom: 32,
        width: `calc(100% - ${2 * screenContainerPadding}px)`
      };
    },
    columnContainer: {
      display: "flex",
      flexDirection: "column",
      gap: 16
    },
    deleteIcon: ({ trashIconContainerSize = 0 }: Partial<{ trashIconContainerSize: number }>) => {
      return {
        alignSelf: "flex-start",
        marginTop: (40 - trashIconContainerSize) / 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
          marginTop: (50 - trashIconContainerSize) / 2
        }
      };
    },
    verticalPadding: {
      paddingTop: 8,
      paddingBottom: 8
    },
    feeInputWidth: {
      width: 120
    },
    sampleCalculator: {
      width: 380,
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    instructionsText: {
      marginTop: 20
    },
    minimumFeeInputs: {
      display: "flex",
      flexDirection: "row",
      gap: 4,
      paddingTop: 8,
      paddingBottom: 8,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: 16,
        alignItems: "stretch"
      }
    },
    mobileFormGap: {
      gap: 24
    },
    accountsText: {
      overflow: "hidden",
      whiteSpace: "nowrap"
    }
  };
});
