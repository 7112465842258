import { ServerRoutes } from "@doorloop/dto";
import type { GetAllTagsQuery, TagDto } from "@doorloop/dto";
import { RestApiBaseWithDictionary } from "./restApiBaseWithDictionary";

class TagsApi extends RestApiBaseWithDictionary<TagDto, GetAllTagsQuery> {
  constructor() {
    super(ServerRoutes.TAGS);
    this.restRoute = ServerRoutes.TAGS;
  }
}

export const tagsApi = new TagsApi();
