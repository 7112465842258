import React from "react";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { useTranslation } from "react-i18next";
import moment from "moment";

/**
 * DLUI Copyright - generates the platform copyright text
 */

const Copyright: React.FC = () => {
  const { t } = useTranslation();

  return (
    <View width={"100%"} alignItems={"center"} justifyContent={"flex-end"}>
      <HorizontalSeparationLine />
      <View justifyContent={"center"} alignItems={"center"} flexDirection={"row"} height={60}>
        <Text color={"secondary-gray"} align="center" fontSize={14}>
          {`${t(AppStrings.Common.Copyright)} ${moment().format("YYYY")}`}
        </Text>
      </View>
    </View>
  );
};

export default Copyright;
