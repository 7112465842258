import React, { useMemo } from "react";
import { View } from "DLUI/view";
import { Grid, ListItemContainer, ListItemSection } from "DLUI/listItems";
import AppStrings from "../../../../../locale/keys";
import { Icon } from "DLUI/icon";
import ListItemText from "DLUI/listItems/listItemText";
import { Routes } from "../../../../appRouter";
import { DialogRoutes } from "DLUI/screen/dialogsProvider";
import { ReferenceLabel } from "DLUI/form";
import { accountsApi } from "api/accounts";
import { BillGrayIcon, CalendarIconBlue } from "../../../../../assets";
import Text from "DLUI/text";
import type { PaymentMethod } from "@doorloop/dto";
import { Link } from "DLUI/link";

import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";

interface Props {
  headerId: string;
  transactionId?: string;
  accountId?: string;
  reference?: string;
  transactionDate?: string;
  amount?: number;
  paymentMethod?: PaymentMethod;
}

const LinkedPaymentItem: React.FC<Props> = ({
  headerId,
  transactionId,
  accountId,
  reference,
  transactionDate,
  paymentMethod,
  amount
}: Props) => {
  const { isScreenSizeIn } = useResponsiveHelper();
  const hideMemoLabel = isScreenSizeIn(["md", "sm"]);
  const accountName = useMemo(
    () => (accountId ? accountsApi.getItemFromDictionary(accountId)?.name || "" : ""),
    [accountId]
  );

  return (
    <ListItemContainer backgroundColor={"dark"} removeVerticalPadding id={headerId}>
      <Grid title={AppStrings.Bills.PayBills.Screen.Transaction} showDivider xs={12} sm={3} md={3} lg={3}>
        <View alignItems={"center"} flexDirection={"row"}>
          <Icon Source={BillGrayIcon} width={40} height={40} pathColor={"black"} />
          <ListItemSection
            renderTitle={
              <Link
                hrefUrl={
                  transactionId
                    ? `${Routes.TRANSACTIONS_LIST_REPORT}/${DialogRoutes.EDIT_VENDOR_BILL_PAYMENT}/${transactionId}`
                    : undefined
                }
              >
                <ListItemText
                  marginLeft={20}
                  color={"black"}
                  overflow={"ellipsis"}
                  numberOfLines={2}
                  fontWeight={700}
                  value={AppStrings.Bills.PayBills.Screen.BillPayment}
                />
              </Link>
            }
            renderSubTitle={
              <View marginLeft={20} justifyContent={"flex-start"}>
                <ListItemText numberOfLines={1} overflow={"ellipsis"} color={"gray"}>
                  {accountName}
                </ListItemText>
              </View>
            }
          />
          {!hideMemoLabel && <ReferenceLabel backgroundColor={"secondary-gray"} value={reference} marginRight={20} />}
        </View>
      </Grid>
      <Grid showDivider title={AppStrings.Common.ListHeader.Date} sm={3} md={3} lg={3}>
        <View flexDirection={"row"} justifyContent={"center"} alignItems={"flex-start"}>
          <Icon Source={CalendarIconBlue} pathColor={"black"} />
          <Text fullWidth color={"black"} fontSize={16} formatType={"date"} value={transactionDate} marginLeft={10} />
        </View>
      </Grid>
      <Grid showDivider title={AppStrings.Common.PaymentMethod} sm={3} md={3} lg={3}>
        <View flexDirection={"row"} justifyContent={"center"} alignItems={"flex-start"}>
          <Text
            fullWidth
            color={"black"}
            fontSize={16}
            value={paymentMethod ? `common.enums.paymentMethod.${paymentMethod}` : ""}
            marginLeft={10}
          />
        </View>
      </Grid>
      <Grid title={AppStrings.Leases.NewLease.LeaseRent.Amount} sm={3} md={3} lg={3}>
        <Text fullWidth color={"black"} fontSize={16} formatType={"currency"} value={String(amount)} marginRight={20} />
      </Grid>
    </ListItemContainer>
  );
};

export default LinkedPaymentItem;
