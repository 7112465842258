export const DataCy = {
  sideMenu: {
    createNewMenu: {
      createNewButton: "SideMenu-CreateNewMenu-Button",
      prospect: "SideMenu-CreateNewMenu-Prospect",
      owner: "SideMenu-CreateNewMenu-Owner",
      vendor: "SideMenu-CreateNewMenu-Vendor",
      user: "SideMenu-CreateNewMenu-User",
      task: "SideMenu-CreateNewMenu-Task",
      workOrder: "SideMenu-CreateNewMenu-WorkOrder",
      ownerRequest: "SideMenu-CreateNewMenu-OwnerRequest",
      tenantRequest: "SideMenu-CreateNewMenu-TenantRequest",
      property: "SideMenu-CreateNewMenu-Property",
      unit: "SideMenu-CreateNewMenu-Unit",
      lease: "SideMenu-CreateNewMenu-Lease",
      rentalApplications: "SideMenu-CreateNewMenu-RentalApplications",
      oneTimeCharge: "SideMenu-CreateNewMenu-OneTimeCharge",
      receivePayment: "SideMenu-CreateNewMenu-ReceivePayment",
      oneTimeCredit: "SideMenu-CreateNewMenu-OneTimeCredit",
      oneTimeRefund: "SideMenu-CreateNewMenu-OneTimeRefund",
      withholdDeposit: "SideMenu-CreateNewMenu-WithholdDeposit",
      createBill: "SideMenu-CreateNewMenu-CreateBill",
      payBill: "SideMenu-CreateNewMenu-PayBill",
      addCredit: "SideMenu-CreateNewMenu-AddCredit",
      managementFees: "SideMenu-CreateNewMenu-ManagementFees",
      ownerContribution: "SideMenu-CreateNewMenu-OwnerContribution",
      ownerDistribution: "SideMenu-CreateNewMenu-OwnerDistribution",
      journalEntry: "SideMenu-CreateNewMenu-JournalEntry",
      bankTransfer: "SideMenu-CreateNewMenu-BankTransfer",
      bankDeposit: "SideMenu-CreateNewMenu-BankDeposit",
      expense: "SideMenu-CreateNewMenu-Expense",
      check: "SideMenu-CreateNewMenu-Check",
      announcement: "SideMenu-CreateNewMenu-Announcement",
      signatureRequest: "SideMenu-CreateNewMenu-SignatureRequest"
    },
    main: {
      overview: "SideMenu-Main-Overview",
      calendar: "SideMenu-Main-Calendar",
      rentals: "SideMenu-Main-Rentals",
      leasing: "SideMenu-Main-Leasing",
      people: "SideMenu-Main-People",
      tasksAndMaintenance: "SideMenu-Main-TasksAndMaintenance",
      accounting: "SideMenu-Main-Accounting",
      communications: "SideMenu-Main-Communications",
      notes: "SideMenu-Main-Notes",
      files: "SideMenu-Main-Files",
      reports: "SideMenu-Main-Reports"
    },
    mainFooter: {
      onBoardingDrawerTitle: "SideMenu-Footer-OnBoardingDrawerTitle",
      help: "SideMenu-Footer-Help",
      settings: "SideMenu-Footer-Settings",
      userProfile: {
        icon: "SideMenu-Footer-UserIcon",
        personalSettings: "SideMenu-Footer-PersonalSettings",
        logout: "SideMenu-Footer-Logout"
      }
    },
    rentals: {
      properties: "SideMenu-Rentals-Properties",
      units: "SideMenu-Rentals-Units"
    },
    leasing: {
      inactiveLeases: "SideMenu-Leasing-InactiveLeases",
      activeLeases: "SideMenu-Leasing-ActiveLeases",
      leaseRenewals: "SideMenu-Leasing-LeaseRenewals",
      draftLeases: "SideMenu-Leasing-DraftLeases",
      rentalApplications: "SideMenu-Leasing-RentalApplications"
    },
    calendar: {
      calendarView: "SideMenu-Calendar-CalendarView",
      occupancyOverview: "SideMenu-Calendar-OccupancyOverview"
    },
    tasks: {
      unassignedTasks: "SideMenu-Tasks-UnassignedTasks",
      myTasks: "SideMenu-Tasks-MyTasks",
      tenantRequests: "SideMenu-Tasks-TenantRequests",
      ownerRequests: "SideMenu-Tasks-OwnerRequests",
      recurringTasks: "SideMenu-Tasks-RecurringTasks",
      incompleteTasks: "SideMenu-Tasks-IncompleteTasks",
      allTasks: "SideMenu-Tasks-AllTasks",
      workOrders: "SideMenu-Tasks-WorkOrders"
    },
    accounting: {
      chartOfAccounts: "SideMenu-Accounting-ChartOfAccounts",
      banking: "SideMenu-Accounting-Banking",
      transactions: "SideMenu-Accounting-Transactions",
      recurringBills: "SideMenu-Accounting-RecurringBills",
      reconciliation: "SideMenu-Accounting-Reconciliation",
      outgoingPayments: "SideMenu-Accounting-OutgoingPayments",
      printChecks: "SideMenu-Accounting-PrintChecks",
      unpaidRent: "SideMenu-Accounting-UnpaidRent",
      openBills: "SideMenu-Accounting-OpenBills",
      billsAndExpenses: "SideMenu-Accounting-BillsAndExpenses",
      receivePayments: "SideMenu-Accounting-ReceivePayments",
      chargesAndPayment: "SideMenu-Accounting-ChargesAndPayment",
      budgets: "SideMenu-Accounting-Budgets"
    },
    people: {
      tenants: "SideMenu-People-Tenants",
      owners: "SideMenu-People-Owners",
      vendors: "SideMenu-People-Vendors",
      prospects: "SideMenu-People-Prospects"
    },
    communications: {
      announcements: "SideMenu-Communications-Announcements",
      communicationsLog: "SideMenu-Communications-CommunicationsLog",
      signatureRequests: "SideMenu-Communications-SignatureRequests",
      signatureTemplates: "SideMenu-Communications-SignatureTemplates",
      inbox: "SideMenu-Communications-Inbox",
      allMessages: "SideMenu-Communications-AllMessages",
      unassignedMessages: "SideMenu-Communications-UnassignedMessages"
    }
  },
  allTasksPage: {
    addNewButton: "DLUI-NewTasksPopover"
  },
  calendar: {
    layout: "Calendar-Layout",
    navigation: {
      rightArrow: "Calendar-Navigation-RightArrow",
      leftArrow: "Calendar-Navigation-LeftArrow",
      today: "Calendar-Navigation-Today"
    },
    calendarView: {
      eventItem: "CalendarView-EventItem"
    },
    quickView: {
      title: "Calendar-QuickView-Title",
      subject: "Calendar-QuickView-Subject",
      property: "Calendar-QuickView-Property",
      close: "Calendar-QuickView-Close",
      taskQuickView: {
        description: "Calendar-QuickView-Task-Description",
        dueDate: "Calendar-QuickView-Task-DueDate",
        requestedByItem: "Calendar-QuickView-Task-RequestedByItem",
        assigneeItem: "Calendar-QuickView-Task-AssigneeItem"
      },
      leaseQuickView: {
        moveInDate: "Calendar-QuickView-Lease-MoveInDate",
        moveOutDate: "Calendar-QuickView-Lease-MoveOutDate",
        tenantItem: "Calendar-QuickView-Lease-TenantItem"
      }
    },
    subscription: {
      subscribeButton: "Calendar-Subscription-SubscribeButton",
      copyLinkButton: "Calendar-Subscription-CopyLinkButton"
    }
  },
  newProperty: {
    propertySelectionButtonGroup: {
      residential: "NewProperty-Selection-Residential",
      commercial: "NewProperty-Selection-Commercial",
      subTypes: {
        residential: {
          singleFamily: "NewProperty-Selection-SingleFamily",
          multiFamily: "NewProperty-Selection-MultiFamily",
          condo: "NewProperty-Selection-Condo",
          townhome: "NewProperty-Selection-Townhome",
          other: "NewProperty-Selection-ResidentialOther"
        },
        commercial: {
          industrial: "NewProperty-Selection-Industrial",
          office: "NewProperty-Selection-Office",
          retail: "NewProperty-Selection-Retail",
          shoppingCenter: "NewProperty-Selection-ShoppingCenter",
          storage: "NewProperty-Selection-Storage",
          parking: "NewProperty-Selection-Parking",
          other: "NewProperty-Selection-CommercialOther"
        }
      }
    },
    unitList: {
      addUnitLink: "NewProperty-NewUnitLink",
      removeUnit: "NewProperty-RemoveUnit",
      unitName: "units[].name"
    },
    bankAccount: {
      tooltip: "NewProperty-Tooltip-BankAccountForProperty",
      defaultAccountName: "NewProperty-DefaultAccountName",
      specifyBankButton: "NewProperty-SpecifyAccountButton",
      defaultBankButton: "NewProperty-DefaultAccountButton",
      securityDepositYesButton: "NewProperty-SecurityDepositYesButton",
      securityDepositNoButton: "NewProperty-SecurityDepositNoButton",
      clientBankOperating: "client_bank_operating",
      clientBankTrust: "client_bank_trust"
    },
    reserveFunds: {
      enableReserveFunds: "NewProperty-EnableReserveFunds",
      disableReserveFunds: "NewProperty-DisableReserveFunds",
      tooltip: "NewProperty-Tooltip-WhatIsPropertyReserve"
    },
    ownership: {
      ownedByMe: "NewProperty-Ownership-OwnedByMe",
      ownedBySomeoneElse: "NewProperty-Ownership-OwnedBySomeoneElse",
      tooltip: "NewProperty-Tooltip-WhatIsPropertyOwnership",
      addOwnerSquareButton: "NewProperty-Ownership-AddOwnerSquareButton"
    }
  },
  newLease: {
    newLeaseButton: "ActiveLeases-NewLeaseButton",
    leaseTerms: {
      fixedTerm: "NewLease-LeaseTerms-FixedTerm",
      monthToMonth: "NewLease-LeaseTerms-MonthToMonth",
      startDate: "NewLease-LeaseTerms-StartDate",
      endDate: "NewLease-LeaseTerms-EndDate"
    },
    leaseWizard: {
      overviewScreen: {
        choosePropertyDDL: "choosePropertyDDL",
        chooseUnitDDL: "chooseUnitDDL"
      },
      rentScreen: {
        firstRentDataInput: "rentCharges[0].start",
        frequencyDDL: "rentCharges[0].frequency",
        rentAmountInput: "rentAmount"
      },
      reviewScreen: {
        leaseSummary: "NewLease-ReviewScreen-LeaseSummary"
      }
    },
    leaseTenants: {
      addTenantButton: "NewLease-LeaseTenants-AddTenantButton",
      selectedTenantSquare: "NewLease-LeaseTenants-SelectedTenantSquare",
      tenantPortalCard: "NewLease-LeaseTenants-TenantPortalCard",
      rentersInsuranceCard: "NewLease-LeaseTenants-RentersInsuranceCard",
      sendWelcomeSmsCard: "NewLease-LeaseTenants-SendWelcomeSmsCard"
    },
    securityDeposit: {
      securityDepositEnabled: "NewLease-SecurityDeposit-EnableDeposit",
      securityDepositDisabled: "NewLease-SecurityDeposit-DisableDeposit"
    },
    lateFees: {
      lateFeesEnabled: "NewLease-LateFees-EnableLateFees",
      lateFeesDisabled: "NewLease-LateFees-DisableLateFees",
      warningMessage: "NewLease-LateFees-WarningMessage",
      usePropertyPolicyRadioButton: "NewLease-LateFees-PropertyPolicyRadioButton",
      specifyPolicyRadioButton: "NewLease-LateFees-SpecifyPolicyRadioButton"
    },
    signatureStatus: {
      signedButton: "NewLease-SignatureStatus-SignedButton",
      notSignedButton: "NewLease-SignatureStatus-NotSignedButton",
      saveAsDraftButton: "NewLease-SignatureStatus-SaveAsDraftButton",
      sendESignatureButton: "NewLease-SignatureStatus-SendESignatureButton",
      notSentButton: "NewLease-SignatureStatus-NotSentButton",
      sentButton: "NewLease-SignatureStatus-SentButton",
      partiallySigned: "NewLease-SignatureStatus-PartiallySigned",
      counterSigned: "NewLease-SignatureStatus-CounterSigned"
    }
  },
  propertyDetails: {
    navigationBar: {
      overview: "PropertyDetails-NavigationBar-Overview",
      reports: "PropertyDetails-NavigationBar-Reports",
      units: "PropertyDetails-NavigationBar-Units",
      leases: "PropertyDetails-NavigationBar-Leases",
      tenants: "PropertyDetails-NavigationBar-Tenants",
      rentalApplications: "PropertyDetails-NavigationBar-RentalApplications",
      signatureRequests: "PropertyDetails-NavigationBar-SignatureRequests",
      tasks: "PropertyDetails-NavigationBar-Tasks",
      notes: "PropertyDetails-NavigationBar-Notes",
      files: "PropertyDetails-NavigationBar-Files",
      settings: "PropertyDetails-NavigationBar-Settings",
      menu: "PropertyDetails-NavigationBar-Menu"
    },
    overview: {
      quickActions: {
        newLeaseButton: "PropertyDetails-Overview-NewLeaseButton"
      },
      propertyType: "PropertyDetails-Overview-PropertyType",
      activeUnits: "PropertyDetails-Overview-ActiveUnits",
      activityStatus: "PropertyDetails-Overview-ActivityStatus"
    },
    settings: {
      //region Settings page items
      generalInformationItem: "PropertyDetails-Settings-GeneralInformation",
      photosItem: "PropertyDetails-Settings-Photos",
      propertyTypeItem: "PropertyDetails-Settings-PropertyType",
      amenitiesItem: "PropertyDetails-Settings-Amenities",
      petPolicyItem: "PropertyDetails-Settings-PetPolicy",
      ownersItem: "PropertyDetails-Settings-Owners",
      bankAccountsItem: "PropertyDetails-Settings-BankAccounts",
      reserveFundsItem: "PropertyDetails-Settings-ReserveFunds",
      lateFeesItem: "PropertyDetails-Settings-LateFees",
      tenantPortalItem: "PropertyDetails-Settings-TenantPortal",
      rentAndPaymentNotificationsItem: "PropertyDetails-Settings-RentAndPaymentNotification",
      paymentInstructionsItem: "PropertyDetails-Settings-PaymentInstructions",
      tenantRequestsItem: "PropertyDetails-Settings-TenantRequests",
      rentalApplicationsItem: "PropertyDetails-Settings-RentalApplications",
      managementFeesItem: "PropertyDetails-Settings-ManagementFees",
      paymentAllocationItem: "PropertyDetails-Settings-PaymentAllocation",
      customAllocationsItem: "PropertyDetails-Settings-CustomAllocations",
      convenienceFeesItem: "PropertyDetails-Settings-ConvenienceFees",
      // endregion Settings page items
      editPropertyPetPolicy: {
        petsAllowed: "PropertyDetails-Settings-PetsAllowed",
        petsNotAllowed: "PropertyDetails-Settings-PetsNotAllowed"
      },
      editPropertyBankAccounts: {
        useDefaultRadioButton: "PropertyDetails-Settings-UseDefaultBankAccount",
        specifyAccountRadioButton: "PropertyDetails-Settings-SpecifyBankAccount",
        bankOperating: "settings.defaultAccounts.bank_operating",
        bankTrust: "settings.defaultAccounts.bank_trust"
      },
      editPropertyLateFees: {
        useDefaultLateFees: "PropertyDetails-Settings-UseDefaultLateFee",
        specifyLateFees: "PropertyDetails-Settings-SpecifyLateFee"
      },
      editPropertyConvenienceFees: {
        useDefaultLateFees: "PropertyDetails-Settings-UseDefaultConvenienceFees",
        specifyLateFees: "PropertyDetails-Settings-SpecifyLateConvenienceFees"
      },
      customAllocations: {
        allocationName: "allocationName",
        allocationDescription: "allocationDescription",
        allocationSettings: "strategy",
        allocationsPercentage: "allocations[0].percentage"
      }
    }
  },
  unitDetails: {
    overview: {
      bedsAndBaths: "UnitDetails-Overview-BedsAndBaths",
      unitSize: "UnitDetails-Overview-UnitSize",
      marketRent: "UnitDetails-Overview-MarketRent"
    }
  },
  leaseDetails: {
    navigationBar: {
      overview: "LeaseDetails-NavigationBar-Overview",
      tenants: "LeaseDetails-NavigationBar-Tenants",
      transactions: "LeaseDetails-NavigationBar-Transactions",
      rent: "LeaseDetails-NavigationBar-Rent",
      deposits: "LeaseDetails-NavigationBar-Deposits",
      signatureRequests: "LeaseDetails-NavigationBar-SignatureRequests",
      tasks: "LeaseDetails-NavigationBar-Tasks",
      notes: "LeaseDetails-NavigationBar-Notes",
      files: "LeaseDetails-NavigationBar-Files",
      settings: "LeaseDetails-NavigationBar-Settings"
    },
    overview: {
      outstandingBalance: "LeaseDetails-Overview-OutstandingBalance",
      upcomingBalance: "LeaseDetails-Overview-UpcomingBalance",
      termStart: "LeaseDetails-Overview-TermStart",
      termEnd: "LeaseDetails-Overview-TermEnd",
      status: "LeaseDetails-Overview-Status",
      rent: "LeaseDetails-Overview-Rent",
      deposit: "LeaseDetails-Overview-Deposit",
      getInsuranceButton: "LeaseDetails-Overview-GetInsuranceButton"
    },
    transactions: {
      postChargeActionButton: "LeaseDetails-Transactions-PostChargeActionButton",
      receivePaymentActionButton: "LeaseDetails-Transactions-receivePaymentActionButton",
      issueCreditActionButton: "LeaseDetails-Transactions-issueCreditActionButton",
      giveRefundActionButton: "LeaseDetails-Transactions-giveRefundActionButton",
      saveAsPdfActionButton: "LeaseDetails-Transactions-saveAsPdfActionButton"
    },
    settings: {
      //region Settings page items
      lateFeesItem: "LeaseDetails-Settings-LateFees",
      leaseTermsItem: "LeaseDetails-Settings-LeaseTerms",
      rentersInsuranceItem: "LeaseDetails-Settings-RentersInsurance",
      tenantPortalItem: "LeaseDetails-Settings-TenantPortal",
      evictionStatusItem: "LeaseDetails-Settings-EvictionStatus",
      leaseNameAndNotesItem: "LeaseDetails-Settings-LeaseNameAndNotes",
      convenienceFeesItem: "LeaseDetails-Settings-ConvenienceFees",
      // endregion Settings page items
      evictionInProgressToggle: "LeaseDetails-Settings-EvictionInProgressToggle",
      editLeaseLateFees: {
        automaticLateFees: "LeaseDetails-Settings-AutomaticLateFees",
        useDefaultLateFees: "LeaseDetails-Settings-UseDefaultLateFee",
        specifyLateFees: "LeaseDetails-Settings-SpecifyLateFee"
      }
    },
    deposits: {
      refundDepositActionButton: "LeaseDetails-Deposits-RefundDepositActionButton",
      withholdDepositActionButton: "LeaseDetails-Deposits-RefundWithholdActionButton",
      refundDeposit: {
        depositsHeld: "LeaseDetails-Deposits-RefundDeposit-DepositsHeld",
        depositRefunded: "LeaseDetails-Deposits-RefundDeposit-DepositRefunded",
        remainingDeposits: "LeaseDetails-Deposits-RefundDeposit-RemainingDeposits"
      }
    }
  },
  taskDetails: {
    overview: {
      postAnUpdate: "TaskDetails-Overview-PostAnUpdate",
      createdAt: "TaskDetails-Overview-CreatedAt",
      unit: "TaskDetails-Overview-Unit",
      requestedByOwner: "TaskDetails-Overview-RequestedByOwner",
      requestedByTenant: "TaskDetails-Overview-RequestedByTenant",
      dueDate: "TaskDetails-Overview-DueDate",
      status: "TaskDetails-Overview-Status",
      description: "TaskDetails-Overview-Description",
      assignees: "TaskDetails-Overview-Assignees"
    }
  },
  people: {
    vendors: {
      newVendorButton: "People-Vendors-NewVendorButton",
      vendorDetails: {
        overviewTab: "Vendor-OverviewTab",
        transactionsTab: "Vendor-TransactionsTab",
        tasksTab: "Vendor-TasksTab",
        notesTab: "Vendor-NotesTab",
        filesTab: "Vendor-FilesTab",
        createBillQuickAction: "Vendor-QuickAction-CreateBill",
        payBillQuickAction: "Vendor-QuickAction-PayBill",
        newWorkOrderQuickAction: "Vendor-QuickAction-NewWorkOrder",
        addressStatusElement: "Vendor-Overview-AddressStatusElement",
        contactInfoStatusElement: "Vendor-Overview-ContactInfoStatusElement",
        propertiesStatusElement: "Vendor-Overview-PropertiesStatusElement",
        servicesInfoStatusElement: "Vendor-Overview-ServicesStatusElement",
        openBalanceStatusElement: "Vendor-Overview-openBalanceStatusElement",
        insuranceExpiresStatusElement: "Vendor-Overview-InsuranceExpiresStatusElement",
        oneTimeBillRadioButton: "Vendor-Bill-OneTimeRB",
        recurringBillRadioButton: "Vendor-Bill-RecurringRB",
        vendorFirstName: "firstName",
        vendorLastName: "lastName",
        vendorPhoneNumber: "phones[0].number",
        vendorEmailAddress: "emails[0].address",
        propertiesCheckbox: "properties"
      },
      transactionsTab: {
        createBill: "Vendor-Transactions-CreateBill",
        addExpense: "Vendor-Transactions-AddExpense",
        payBill: "Vendor-Transactions-PayBill",
        addCredit: "Vendor-Transactions-AddCredit",
        vendorLedger: "Vendor-Transactions-VendorLedger"
      }
    },
    owners: {
      newOwnerButton: "People-Owners-NewOwnerButton",
      ownerDetails: {
        overviewTab: "Owner-OverviewTab",
        transactionsTab: "Owner-TransactionsTab",
        propertiesTab: "Owner-PropertiesTab",
        tasksTab: "Owner-TasksTab",
        notesTab: "Owner-NotesTab",
        filesTab: "Owner-FilesTab",
        addressStatusElement: "Owner-Overview-AddressStatusElement",
        contactInfoStatusElement: "Owner-Overview-ContactInfoStatusElement",
        agreementExpiresStatusElement: "Owner-Overview-AgreementExpiresStatusElement",
        ownerPortalStatusElement: "Owner-Overview-OwnerPortalStatusElement",
        oneTimeBillRadioButton: "Owner-Bill-OneTimeRB",
        recurringBillRadioButton: "Owner-Bill-RecurringRB"
      }
    }
  },
  detailScreens: {
    screenTitle: "DetailScreen-ScreenTitle",
    backButton: "DetailScreen-BackButton"
  },
  dialogActionButtons: {
    save: "Action-Button-Save",
    confirm: "Action-Button-Confirm",
    cancel: "Action-Button-Cancel",
    dismiss: "Action-Button-Dismiss",
    delete: "Action-Button-Delete",
    duplicate: "Action-Button-Duplicate",
    send: "Action-Button-Send",
    close: "Action-Button-Close",
    print: "Action-Button-Print"
  },
  dialog: {
    xButton: "Dialog-X-Button",
    container: "Dialog-Container",
    searchBar: "dialogSearchPanelInput",
    content: "Dialog-Content",
    title: "Dialog-Title"
  },
  addressInformation: {
    street1: "street1",
    street2: "street2",
    city: "city",
    state: "state",
    zip: "zip",
    country: "country"
  },
  creditCard: {
    cardNumber: "card-number",
    cardExpiry: "card-expiry",
    cardCVC: "card-cvc"
  },
  transactions: {
    referenceNumber: "Transactions-ReferenceNumber",
    selectPayFromAccount: "Transactions-SelectPayFromAccount",
    selectDepositToAccount: "Transactions-SelectDepositToAccount",
    selectVendor: "Transactions-SelectVendor",
    selectUpdatedVendor: "FormFields-SelectVendor",
    autoApplyPaymentsSwitch: "Transactions-AutoApplyPaymentsSwitch",
    autoDepositFundsSwitch: "Transactions-AutoDepositFundsSwitch",
    emailPaymentReceiptSwitch: "Transactions-EmailPaymentReceiptSwitch",
    selectPayToTenantAccount: "Transactions-SelectPayToTenant"
  },
  login: {
    signIn: "signIn",
    doorloopIcon: "doorloopIcon",
    subAccountContainer: "SubAccountContainer"
  },

  tasks: {
    entryPermissionSwitch: "Entry-Permission-Switch"
  },
  entitySelector: {
    lease: "EntitySelector-Lease",
    owner: "EntitySelector-Owner"
  },
  tenantPortal: {
    navigationBar: {
      navHome: "Tenant-Nav-Home",
      navPayments: "Tenant-Nav-Payments",
      navInsurance: "Tenant-Nav-Insurance",
      navFinancials: "Tenant-Nav-Financials",
      navRequests: "Tenant-Nav-Requests",
      navAnnouncements: "Tenant-Nav-Announcements",
      userNav: "Tenant-Nav-User",
      notificationBell: "Tenant-Nav-NotificationBell",
      menu: "Tenant-Nav-Menu"
    },
    payments: {
      setAutoPay: "Tenant-Payments-setAutoPay",
      makeAPayment: "Tenant-Payments-MakeAPayment",
      viewExistingSettingsButton: "Tenant-Payments-ViewExistingSettings",
      makeAnotherPaymentButton: "Tenant-Payments-MakeAnotherPayment",
      paymentWizard: {
        autoPayRB: "PaymentWizard-AutoPayRadioButton",
        singlePayRB: "PaymentWizard-SinglePayRadioButton",
        summaryFrequency: "PaymentWizard-Summary-Frequency",
        summaryDate: "PaymentWizard-Summary-Date",
        summaryBankAccount: "PaymentWizard-Summary-BankAccount",
        summaryAppliedTowardsCharges: "PaymentWizard-Summary-AppliedTowardsCharges",
        summaryPlatformFee: "PaymentWizard-Summary-PlatformFee",
        summaryTotal: "PaymentWizard-Summary-Total",
        submitButton: "PaymentWizard-SubmitButton",
        ammountToPayField: "paymentOptions.amountWithoutFee",
        paymentDateDatePickerButton: "DLUI-DatePicker-Icon",
        paymentDate: "paymentOptions.paymentDate",
        existingMethodDeleteIcon: "paymentMethodDeleteButton"
      },
      paymentSuccessMessage: {
        paymentAmount: "PaymentSuccess-Amount"
      }
    },
    insurance: {
      insuranceDialog: {
        lemonadePromoBack: "Tenant-Insurance-Dialog-Lemonade-Back",
        surePromoBack: "Tenant-Insurance-Dialog-Sure-Back",
        dialogBack: "Tenant-Insurance-Dialog-Back"
      },
      insuranceRequiredMessage: "Tenant-Insurance-InsuranceRequiredMessage",
      insurancePolicyListItem: "Tenant-Insurance-PolicyListItem"
    },
    requests: {
      requestsListItem: "Tenant-Requests-RequestsListItem"
    },
    lease: {
      leaseSelection: "Tenant-Portal-Lease-Selection",
      myLeaseName: "Tenant-Portal-My-Lease-LeaseName",
      myLeaseAddress: "Tenant-Portal-My-Lease-Address",
      myLeaseStart: "Tenant-Portal-My-Lease-Start",
      myLeaseEnd: "Tenant-Portal-My-Lease-End",
      myLeaseRent: "Tenant-Portal-My-Lease-Rent"
    }
  },
  ownerPortal: {
    navigationBar: {
      navOverview: "Owner-Nav-Overview",
      navTransactions: "Owner-Nav-Transactions",
      navRequests: "Owner-Nav-Requests",
      navReports: "Owner-Nav-Reports",
      userNav: "Owner-Nav-User"
    },
    overview: {
      propertyFilter: "OwnerPortal-Overview-PropertyFilter"
    }
  },
  rentalApplications: {
    createNewRentalApplication: "RentalApplications-CreateNewRentalApplication",
    decisionDialog: {
      approvedButton: "RentalApplications-DecisionDialog-ApprovedButton",
      rejectedButton: "RentalApplications-DecisionDialog-RejectedButton",
      ignoredButton: "RentalApplications-DecisionDialog-IgnoredButton",
      noDecisionButton: "RentalApplications-DecisionDialog-NoDecisionButton",
      mustSendLetterWarning: "RentalApplications-DecisionDialog-MustSendLetterWarning",
      sendAutomatedEmailToggle: "RentalApplications-DecisionDialog-SendAutomatedEmailToggle",
      internalNotes: "RentalApplications-DecisionDialog-InternalNotes",
      reason: "RentalApplications-DecisionDialog-Reason"
    },
    RentalAppDialog: {
      termsAndConditionsCheckbox: "fees.acceptedTermsAndConditions",
      applicationFee: "fees.applicationFee"
    }
  },
  activityFeed: {
    eventItem: "ActivityFeed-EventItem",
    itemTitle: "ActivityFeed-ListItem-Title",
    itemMessage: "ActivityFeed-ListItem-Message",
    itemTimestamp: "ActivityFeed-ListItem-Timestamp",
    inputField: "ActivityFeed-InputMessageField",
    submitMessage: "ActivityFeed-SubmitMessageButton"
  },
  DLUI: {
    screenWidgets: {
      link: "DLUI-screenWidgets-link"
    },
    entitySelector: {
      listContainer: "DLUI-EntitySelector-ListContainer"
    },
    payeeSelector: {
      listContainer: "FormFields-SelectPerson"
    },
    virtualScroll: {
      scrollContainer: "DLUI-VirtualScroll-ScrollContainer"
    },
    dataList: {
      loadingIndicator: "DLUI-DataList-LoadingIndicator",
      scrollContainer: "DLUI-DataList-ScrollContainer"
    },
    filters: {
      sidePanel: "DLUI-Filters-SidePanel",
      closeIconButton: "DLUI-Filters-CloseIconButton",
      dateRangeFilter: "DLUI-Filters-DateRangeFilter",
      property: "DLUI-Filters-PropertyFilter",
      portfolio: "DLUI-Filters-PortfolioFilter",
      active: "DLUI-Filters-ActiveFilter",
      status: "DLUI-Filters-StatusFilter",
      rollover: "DLUI-Filters-RolloverToAtWill",
      periodDateFilter: "DLUI-Filters-PeriodDateFilter",
      toDateFilter: "DLUI-Filters-ToDateFilter",
      accountFilter: "DLUI-Filters-AccountFilter",
      fromDateFilter: "DLUI-Filters-FromDateFilter",
      textFilter: "DLUI-Filters-TextFilter",
      staticFilter: "DLUI-Filters-StaticFilter",
      remoteFilter: "DLUI-Filters-RemoteFilter",
      chipsList: "DLUI-Filters-ChipsList",
      toggleButton: "DLUI-Filters-ToggleButton",
      filterPanel: "DLUI-Filters-FilterPanel",
      filterPanelInlineFilters: "DLUI-Filters-FilterPanel-InlineFilters"
    },
    searchPanel: {
      close: "DLUI-SearchPanel-Close"
    },
    accordion: {
      toggle: "DLUI-Accordion-Toggle",
      end: "DLUI-Accordion-End",
      content: "DLUI-Accordion-Content"
    },
    searchBarContainer: "DLUI-SearchBarContainer",
    listItem: {
      listItem: "DLUI-ListItem",
      listItemOptionsButton: "DLUI-ListItemOptionsButton",
      listItemView: "DLUI-ListItem-View",
      listItemEdit: "DLUI-ListItem-Edit",
      listItemDuplicate: "DLUI-ListItem-Duplicate",
      listItemMakeInactive: "DLUI-ListItem-MakeInactive",
      listItemMakeActive: "DLUI-ListItem-MakeActive",
      listItemDelete: "DLUI-ListItem-Delete",
      listItemSavePDF: "DLUI-ListItem-SavePDF",
      listItemReceivePayment: "DLUI-ListItem-ReceivePayment",
      listItemAddCharge: "DLUI-ListItem-AddCharge",
      listItemGiveRefund: "DLUI-ListItem-GiveRefund",
      listItemIssueCredit: "DLUI-ListItem-IssueCredit",
      listItemCompleteTask: "DLUI-ListItem-CompleteTask",
      listItemLease: "DLUI-ListItem-Lease",
      listItemLoginToPortal: "DLUI-ListItem-LoginToPortal",
      listItemSendPortalInvitation: "DLUI-ListItem-SendPortalInvitation",
      listItemActionsSubTitle: "DLUI-ListItem-ActionsSubTitle",
      listItemAddContactInfo: "DLUI-ListItem-AddContactInfo"
    },
    noResultsFoundMessage: "DLUI-NoResultsFoundMessage",
    noOutstandingTransactionsMessage: "DLUI-NoOutstandingTransactionsMessage",
    datePicker: {
      datePickerIcon: "DLUI-DatePicker-Icon"
    },
    notifications: {
      unreadNotificationBubble: "DLUI-UnreadNotificationBubble",
      notificationsSidePanel: "DLUI-Notifications-SidePanel",
      title: "DLUI-Notifications-Title",
      unopenedIndicator: "DLUI-Notifications-UnopenedIndicator"
    },
    trashIconButton: "DLUI-TrashIconButton",
    duplicateIconButton: "DLUI-DuplicateIconButton",
    dropZone: "DLUI-DropZone",
    editPopover: "DLUI-EditPopover",
    newTasksPopover: "DLUI-NewTasksPopover",
    screenTitle: "DLUI-ScreenTitle",
    screenSubtitle: "DLUI-ScreenSubtitle",
    editableContactInfo: {
      input: "DLUI-EditableContactInfo-Input"
    },
    popper: "DLUI-Popper",
    tenantPortal: {
      mobile: {
        sidePanel: "DLUI-TenantPortal-Mobile-SidePanel"
      },
      topNavigationBar: {
        menu: "DLUI-TenantPortal-TopNavigation-Menu",
        announcements: "DLUI-TenantPortal-TopNavigation-Announcements",
        logout: "DLUI-TenantPortal-TepNavigationBar-Logout"
      }
    }
  },
  formFields: {
    selectPerson: "FormFields-SelectPerson",
    selectVendor: "FormFields-SelectVendor",
    selectProspect: "FormFields-SelectProspect"
  },
  globalSettings: {
    listingSyndication: {
      switchButton: "GlobalSettings-ListingSyndication-SwitchButton",
      instructionsContainer: "GlobalSettings-ListingSyndication-InstructionsContainer",
      copyLink: "GlobalSettings-ListingSyndication-CopyLink"
    },
    collapsibleWithSwitch: {
      switchButton: "GlobalSettings-CollapsibleWithSwitch-SwitchButton"
    },
    titleWithDescription: {
      title: "GlobalSettings-CollapsibleWithSwitch-Text-Title",
      description: "GlobalSettings-CollapsibleWithSwitch-Text-Description"
    },
    generalSettingsScreen: "GlobalSettings-GeneralSettingsScreen",
    convenienceFeesSettings: {
      convenienceFeesItem: "GlobalSettings-ConvenienceFees",
      convenienceFeesSettingsCompanyDefaultOrSpecifyRadioButton: "GlobalSettings-ConvenienceFees-CompanyDefault",
      dialogItems: {
        amountInput: "GlobalSettings-ConvenienceFees-AmountInput",
        accountInput: "GlobalSettings-ConvenienceFees-AccountInput",
        typeSelect: "GlobalSettings-ConvenienceFees-TypeSelect",
        saveButton: "GlobalSettings-ConvenienceFees-SaveButton",
        cancelButton: "GlobalSettings-ConvenienceFees-CancelButton"
      }
    },
    managementFees: {
      enableForProperty: "GlobalSettings-ManagementFees-EnableForProperty",
      specifyForProperty: "GlobalSettings-ManagementFees-SpecifyForProperty",
      vendorSelection: "GlobalSettings-ManagementFees-VendorSelection",
      addManagementFees: "GlobalSettings-ManagementFees-AddManagementFees",
      rules: {
        container: "GlobalSettings-ManagementFees-Rules-Container",
        newRuleButton: "GlobalSettings-ManagementFees-NewRuleButton",
        rule: {
          menuOpen: "GlobalSettings-ManagementFees-Rules-Rule-Options-MenuOpen",
          options: {
            delete: "GlobalSettings-ManagementFees-Rules-Rule-Options-Delete",
            duplicate: "GlobalSettings-ManagementFees-Rules-Rule-Options-Duplicate",
            edit: "GlobalSettings-ManagementFees-Rules-Rule-Options-Edit"
          },
          title: "GlobalSettings-ManagementFees-Rules-Rule-Title",
          container: "GlobalSettings-ManagementFees-Rules-Rule-Container",
          mobileFormContainer: "GlobalSettings-ManagementFees-Rules-Rule-MobileFormContainer",
          ruleSelector: "GlobalSettings-ManagementFees-Rules-Rule-Selector",
          feeInput: "GlobalSettings-ManagementFees-Rules-Rule-FeeInput",
          expenseAccountSelect: "GlobalSettings-ManagementFees-Rules-Rule-ExpenseAccountSelect",
          baseAccountSelect: "GlobalSettings-ManagementFees-Rules-Rule-BaseAccountSelect"
        }
      },
      minimumFee: {
        toggle: "GlobalSettings-ManagementFees-MinimumFee-Toggle",
        container: "GlobalSettings-ManagementFees-MinimumFee-Container",
        minimumFeeInput: "GlobalSettings-ManagementFees-MinimumFee-Input",
        expenseAccountSelect: "GlobalSettings-ManagementFees-MinimumFee-ExpenseAccountSelect"
      },
      payoutAccounts: {
        container: "GlobalSettings-ManagementFees-PayoutAccounts-Container",
        accountRow: "GlobalSettings-ManagementFees-PayoutAccounts-AccountRow",
        payoutAccountSelect: "GlobalSettings-ManagementFees-PayoutAccounts-PayoutAccountSelect",
        removeAccountButton: "GlobalSettings-ManagementFees-PayoutAccounts-RemoveAccountButton",
        newPayoutAccountButton: "GlobalSettings-ManagementFees-PayoutAccounts-NewPayoutAccountButton"
      },
      sampleCalculator: {
        container: "GlobalSettings-ManagementFees-SampleCalculator-Container",
        editButton: "GlobalSettings-ManagementFees-SampleCalculator-EditButton",
        resetButton: "GlobalSettings-ManagementFees-SampleCalculator-ResetButton",
        rule: {
          container: "GlobalSettings-ManagementFees-SampleCalculator-Rule-Container",
          amountInput: "GlobalSettings-ManagementFees-SampleCalculator-Rule-AmountInput",
          result: "GlobalSettings-ManagementFees-SampleCalculator-Rule-Result"
        },
        subtotalAmount: "GlobalSettings-ManagementFees-SampleCalculator-SubtotalAmount",
        minimumFee: {
          container: "GlobalSettings-ManagementFees-SampleCalculator-MinimumFee-Container",
          title: "GlobalSettings-ManagementFees-SampleCalculator-MinimumFee-Title",
          amount: "GlobalSettings-ManagementFees-SampleCalculator-MinimumFee-Amount"
        },
        payoutAccounts: {
          container: "GlobalSettings-ManagementFees-SampleCalculator-PayoutAccounts-Container",
          accountInput: "GlobalSettings-ManagementFees-SampleCalculator-PayoutAccounts-AccountInput",
          accountResult: "GlobalSettings-ManagementFees-SampleCalculator-PayoutAccounts-AccountResult"
        },
        total: {
          amount: "GlobalSettings-ManagementFees-SampleCalculator-Total-Amount"
        }
      }
    },
    lateFees: {
      addLateFee: "GlobalSettings-LateFees-AddLateFee",
      rules: {
        container: "GlobalSettings-LateFees-Rules-Container",
        newRuleButton: "GlobalSettings-LateFees-NewRuleButton",
        rule: {
          menuOpen: "GlobalSettings-LateFees-Rules-Rule-Options-MenuOpen",
          mobileFormContainer: "GlobalSettings-LateFees-Rules-Rule-MobileFormContainer",
          container: "GlobalSettings-LateFees-Rules-RuleContainer",
          title: "GlobalSettings-LateFees-Rules-Rule-Title",
          feeTypeSelect: "GlobalSettings-LateFees-Rules-Rule-FeeTypeSelect",
          frequency: "GlobalSettings-LateFees-Rules-Rule-Frequency",
          gracePeriod: "GlobalSettings-LateFees-Rules-Rule-GracePeriod",
          revenueAccount: "GlobalSettings-LateFees-Rules-Rule-RevenueAccount",
          description: "GlobalSettings-LateFees-Rules-Rule-Description",
          amount: "GlobalSettings-LateFees-Rules-Rule-Amount"
        }
      },
      limitations: {
        maximumDailyFee: {
          toggle: "GlobalSettings-LateFeesLimitations-MaximumDailyFee-Toggle",
          amount: "GlobalSettings-LateFeesLimitations-MaximumDailyFee-Amount"
        },
        maximumMonthlyFee: {
          toggle: "GlobalSettings-LateFeesLimitations-MaximumMonthlyFee-Toggle",
          amount: "GlobalSettings-LateFeesLimitations-MaximumMonthlyFee-Amount"
        },
        minBalance: {
          toggle: "GlobalSettings-LateFeesLimitations-MinBalance-Toggle",
          amount: "GlobalSettings-LateFeesLimitations-MinBalance-Amount"
        },
        applyToMostRecent: {
          toggle: "GlobalSettings-LateFeesLimitations-ApplyToMostRecent-Toggle"
        },
        outstandingCharges: {
          toggle: "GlobalSettings-LateFeesLimitations-OutstandingCharges-Toggle",
          type: "GlobalSettings-LateFeesLimitations-OutstandingCharges-Type",
          accounts: "GlobalSettings-LateFeesLimitations-OutstandingCharges-Accounts"
        }
      },
      reminder: {
        beforeCharge: {
          email: "GlobalSettings-LateFeesReminder-BeforeCharge-Email",
          text: "GlobalSettings-LateFeesReminder-BeforeCharge-Text",
          push: "GlobalSettings-LateFeesReminder-BeforeCharge-Push"
        },
        afterCharge: {
          email: "GlobalSettings-LateFeesReminder-AfterCharge-Email",
          text: "GlobalSettings-LateFeesReminder-AfterCharge-Text",
          push: "GlobalSettings-LateFeesReminder-AfterCharge-Push"
        }
      },
      sampleCalculator: {
        container: "GlobalSettings-LateFees-SampleCalculator-Container",
        editButton: "GlobalSettings-LateFees-SampleCalculator-EditButton",
        resetButton: "GlobalSettings-LateFees-SampleCalculator-ResetButton",
        rent: "GlobalSettings-LateFees-SampleCalculator-Rent",
        delinquency: "GlobalSettings-LateFees-SampleCalculator-Delinquency",
        rule: {
          container: "GlobalSettings-LateFees-SampleCalculator-Rule-Container",
          result: "GlobalSettings-LateFees-SampleCalculator-Rule-Result",
          type: "GlobalSettings-LateFees-SampleCalculator-Rule-Type",
          rent: "GlobalSettings-LateFees-SampleCalculator-Rule-Rent",
          frequencyDescription: "GlobalSettings-LateFees-SampleCalculator-Rule-FrequencyDescription"
        },
        subtotal: {
          amountBeforeReduction: "GlobalSettings-LateFees-SampleCalculator-Subtotal-AmountBeforeReduction",
          dailyReduction: "GlobalSettings-LateFees-SampleCalculator-Subtotal-DailyReduction",
          monthlyReduction: "GlobalSettings-LateFees-SampleCalculator-Subtotal-MonthlyReduction"
        },
        total: {
          amount: "GlobalSettings-LateFees-SampleCalculator-Total-Amount"
        }
      }
    }
  },
  communicationsCenter: {
    messagesPanel: {
      messagesList: "CommunicationsCenter-MessagesList",
      messageListItem: "CommunicationsCenter-MessageListItem",
      conversationInputTextArea: "CommunicationsCenter-ConversationInputTextArea",
      sendMessageButton: "CommunicationsCenter-SendMessageButton",
      conversationActionsMenu: "CommunicationsCenter-ConversationActionsMenu",
      markAsUnread: "CommunicationsCenter-MarkAsUnread",
      archive: "CommunicationsCenter-Archive",
      unarchive: "CommunicationsCenter-Unarchive",
      recipientInput: "CommunicationsCenter-RecipientInput",
      assignees: "CommunicationsCenter-Assignees",
      newTask: "CommunicationsCenter-NewTask"
    },
    conversationsPanel: {
      screenTitle: "CommunicationsCenter-ConversationsPanel-ScreenTitle",
      conversationsList: "CommunicationsCenter-ConversationsList",
      conversationListItem: "CommunicationsCenter-ConversationListItem",
      searchInput: "CommunicationsCenter-SearchInput",
      unreadIndicator: "CommunicationsCenter-UnreadIndicator",
      newConversationButton: "CommunicationsCenter-NewConversationButton",
      recipientListItem: "CommunicationsCenter-RecipientListItem"
    }
  },
  listings: {
    internalListings: {
      filterSection: {
        propertyFilterButton: "Listing-FilterSection-PropertyFilterButton",
        priceFilterButton: "Listing-FilterSection-PriceFilterButton",
        bedsFilterButton: "Listing-FilterSection-BedsFilterButton",
        bathsFilterButton: "Listing-FilterSection-BathsFilterButton",
        typeFilterButton: "Listing-FilterSection-TypeFilterButton",
        searchBar: "Listing-FilterSection-SearchBar",
        popOvers: {
          toggleButton: "Listing-PopOvers-ToggleButton",
          saveAndCloseButton: "Listing-PopOvers-SaveAndCloseButton",
          pricePopover: {
            container: "Listing-PopOvers-PricePopover-Container",
            minInput: "Listing-PopOvers-PricePopover-MinInput",
            maxInput: "Listing-PopOvers-PricePopover-MaxInput"
          },
          bedsPopover: "Listing-PopOvers-BedsPopover",
          bathsPopover: "Listing-PopOvers-BathsPopover",
          typePopover: {
            container: "Listing-PopOvers-TypePopover-Container",
            checkboxes: {
              houseForRent: "Listing-PopOvers-TypePopover-HouseForRent",
              apartment: "Listing-PopOvers-TypePopover-Apartment",
              condo: "Listing-PopOvers-TypePopover-Condo",
              townhouse: "Listing-PopOvers-TypePopover-Townhouse"
            }
          },
          useExactMatchCheckbox: "Listing-PopOvers-UseExactMatchCheckbox"
        }
      },
      listingList: "Listing-ListItemsContainer",
      noResultsFoundMessage: "Listing-NoResultsFoundMessage",
      listingItem: "Listing-ListingItem",
      listingDetailsView: {
        container: "Listing-ListingDetailsView-Container",
        requestInformationButton: "Listing-ListingDetailsView-RequestInformationButton",
        applyOnlineButton: "Listing-ListingDetailsView-ApplyOnlineButton",
        amenitiesContainer: "Listing-ListingDetailsView-AmenitiesContainer"
      },
      listingImage: "Listing-ListingImage"
    }
  },
  watchVideoHelpLink: "WatchVideoHelpLink",
  bottomNavigationPanel: {
    cancelButton: "BottomNavigationPanel-CancelButton",
    nextButton: "BottomNavigationPanel-NextButton",
    finishButton: "BottomNavigationPanel-NextButton"
  },
  reports: {
    apAging: {
      summary: "Report-AP-Aging-Summary",
      detail: "Report-AP-Aging-Detail",
      detailByAccount: "Report-AP-Aging-DetailByAccount"
    },
    arAging: {
      summary: "Report-AR-Aging-Summary",
      detail: "Report-AR-Aging-Detail"
    },
    filters: {
      vendor: "Report-Filters-Vendor",
      portfolio: "Report-Filters-Portfolio",
      property: "Report-Filters-Property",
      unit: "Report-Filters-Unit",
      account: "Report-Filters-Account",
      lease: "Report-Filters-Lease",
      tenant: "Report-Filters-Tenant",
      owner: "Report-Filters-Owner",
      credits: "Report-Filters-Credits",
      agingPeriodDays: "Report-Filters-AgingPeriodDays",
      numberOfAgingPeriods: "Report-Filters-NumberOfAgingPeriods",
      minDaysPastDue: "Report-Filters-MinDaysPastDue",
      asOfDate: "Report-Filters-AsOfDate"
    },
    screenItems: {
      businessOverview: {
        balanceSheet: "Report-BusinessOverview-BalanceSheet",
        balanceSheetByMonth: "Report-BusinessOverview-BalanceSheetByMonth",
        balanceSheetByProperty: "Report-BusinessOverview-BalanceSheetByProperty",
        balanceSheetByQuarter: "Report-BusinessOverview-BalanceSheetByQuarter",
        balanceSheetByYear: "Report-BusinessOverview-BalanceSheetByYear",
        balanceSheetDetails: "Report-BusinessOverview-BalanceSheetDetails",
        cashFlowStatement: "Report-BusinessOverview-CashFlowStatement",
        cashFlowStatementByMonth: "Report-BusinessOverview-CashFlowStatementByMonth",
        cashFlowStatementByProperty: "Report-BusinessOverview-CashFlowStatementByProperty",
        managementFeesSettingsReport: "Report-BusinessOverview-ManagementFeesSettingsReport",
        ownerStatement: "Report-BusinessOverview-OwnerStatement",
        profitAndLoss: "Report-BusinessOverview-ProfitAndLoss",
        profitAndLossByMonth: "Report-BusinessOverview-ProfitAndLossByMonth",
        profitAndLossByProperty: "Report-BusinessOverview-ProfitAndLossByProperty",
        profitAndLossByQuarter: "Report-BusinessOverview-ProfitAndLossByQuarter",
        profitAndLossByYear: "Report-BusinessOverview-ProfitAndLossByYear",
        profitAndLossDetails: "Report-BusinessOverview-ProfitAndLossDetails",
        propertyReserveFunds: "Report-BusinessOverview-PropertyReserveFunds",
        rentRoll: "Report-BusinessOverview-RentRoll",
        rentRollSummaryByBedsBaths: "Report-BusinessOverview-RentRollSummaryByBedsBaths",
        rentRollSummaryByProperty: "Report-BusinessOverview-RentRollSummaryByProperty"
      },
      accounting: {
        accountRegister: "Report-Accounting-AccountRegister",
        bankBalanceByProperty: "Report-Accounting-BankBalanceByProperty",
        budgetVsActual: "Report-Accounting-BudgetVsActual",
        depositDetail: "Report-Accounting-DepositDetail",
        depositTransactions: "Report-Accounting-DepositTransactions",
        ePayTransactions: "Report-Accounting-EPayTransactions",
        ePayDepositsReport: "Report-Accounting-EPayDepositsReport",
        expenseTransactions: "Report-Accounting-ExpenseTransactions",
        generalLedger: "Report-Accounting-GeneralLedger",
        propertyBankAccounts: "Report-Accounting-PropertyBankAccounts",
        recurringExpenses: "Report-Accounting-RecurringExpenses",
        recurringTransactionsSummary: "Report-Accounting-RecurringTransactionsSummary",
        recurringVendorBills: "Report-Accounting-RecurringVendorBills",
        refundTransactions: "Report-Accounting-RefundTransactions",
        transactionDetails: "Report-Accounting-TransactionDetails",
        transactionsList: "Report-Accounting-TransactionsList",
        transactionsListByType: "Report-Accounting-TransactionsListByType",
        trialBalance: "Report-Accounting-TrialBalance",
        undepositedFunds: "Report-Accounting-UndepositedFunds"
      },
      whoOwesYou: {
        arAgingDetail: "Report-WhoOwesYou-ARAgingDetail",
        arAgingDetailByAccount: "Report-WhoOwesYou-ARAgingDetailByAccount",
        arAgingSummary: "Report-WhoOwesYou-ARAgingSummary",
        recurringPaymentsAutoPay: "Report-WhoOwesYou-RecurringPaymentsAutoPay",
        recurringRentTransactions: "Report-WhoOwesYou-RecurringRentTransactions",
        reversedPayment: "Report-WhoOwesYou-ReversedPayment"
      },
      whatYouOwe: {
        apAgingDetail: "Report-WhatYouOwe-APAgingDetail",
        apAgingDetailByAccount: "Report-WhatYouOwe-APAgingDetailByAccount",
        apAgingSummary: "Report-WhatYouOwe-APAgingSummary",
        billPaymentsList: "Report-WhatYouOwe-BillPaymentsList",
        unpaidBills: "Report-WhatYouOwe-UnpaidBills"
      },
      tenants: {
        currentTenants: "Report-Tenants-CurrentTenants",
        futureTenants: "Report-Tenants-FutureTenants",
        previousTenants: "Report-Tenants-PreviousTenants",
        tenantPetsReport: "Report-Tenants-TenantPetsReport",
        tenantVehiclesReport: "Report-Tenants-TenantVehiclesReport"
      },
      prospects: {
        activeProspects: "Report-Prospects-ActiveProspects",
        lostProspects: "Report-Prospects-LostProspects",
        movedInProspects: "Report-Prospects-MovedInProspects",
        prospectsByLeasingAgent: "Report-Prospects-ProspectsByLeasingAgent",
        prospectsBySource: "Report-Prospects-ProspectsBySource",
        rentalApplicationsReport: "Report-Prospects-RentalApplicationsReport"
      },
      leasing: {
        activatedDraftLeases: "Report-Leasing-ActivatedDraftLeases",
        canceledDraftLeases: "Report-Leasing-CanceledDraftLeases",
        draftLeasesByProperty: "Report-Leasing-DraftLeasesByProperty",
        draftLeasesByStatus: "Report-Leasing-DraftLeasesByStatus",
        insuranceDetailsReport: "Report-Leasing-InsuranceDetailsReport",
        insuranceSummaryReport: "Report-Leasing-InsuranceSummaryReport",
        leaseAccountStatementsReport: "Report-Leasing-LeaseAccountStatementsReport",
        leaseLateFeesReport: "Report-Leasing-LeaseLateFeesReport",
        renewalStatusByProperty: "Report-Leasing-RenewalStatusByProperty",
        upcomingMoveIns: "Report-Leasing-UpcomingMoveIns",
        upcomingMoveOuts: "Report-Leasing-UpcomingMoveOuts"
      },
      vendors: {
        _1099NEC: "Report-Vendors-1099NEC",
        allWorkOrdersByVendor: "Report-Vendors-AllWorkOrdersByVendor",
        openWorkOrdersByVendor: "Report-Vendors-OpenWorkOrdersByVendor",
        recurringWorkOrders: "Report-Vendors-RecurringWorkOrders",
        vendorLedger: "Report-Vendors-VendorLedger",
        vendorsByInsuranceStatus: "Report-Vendors-VendorsByInsuranceStatus",
        vendorsDirectory: "Report-Vendors-VendorsDirectory"
      },
      tasks: {
        completedTasks: "Report-Tasks-CompletedTasks",
        openTasks: "Report-Tasks-OpenTasks",
        overdueTasks: "Report-Tasks-OverdueTasks",
        recurringTasks: "Report-Tasks-RecurringTasks",
        tasksByPriority: "Report-Tasks-TasksByPriority",
        tasksByProperty: "Report-Tasks-TasksByProperty",
        tasksByStatus: "Report-Tasks-TasksByStatus",
        tenantRequests: "Report-Tasks-TenantRequests",
        workOrder: "Report-Tasks-WorkOrder"
      },
      owners: {
        _1099MISC: "Report-Owners-1099MISC",
        ownersDirectory: "Report-Owners-OwnersDirectory",
        propertyOwnership: "Report-Owners-PropertyOwnership"
      }
    }
  },
  common: {
    doorLoopLogoLoader: "DoorLoop-Logo-Loader",
    printChecksButton: "PrintChecks-Button",
    ePayButton: "EPay-Button"
  },
  toast: {
    message: "Toast-Message",
    closeButton: "Toast-CloseButton"
  },

  bankTransfer: {
    dialog: {
      transferFromInput: "fromProperty",
      transferToInput: "toProperty",
      sourceAccountInput: "fromAccount",
      receivingAccountInput: "toAccount",
      transferAmountInput: "amount",
      transferDate: "BankTransfer-TransferDate"
    }
  },
  fileUploadArea: "fileUploadArea"
};
