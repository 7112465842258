import type { FC } from "react";
import React from "react";
import type { ViewComponentProps } from "DLUI/view/view";
import View from "DLUI/view/view";

interface AnimatedViewProps extends ViewComponentProps {
  shouldShow?: boolean;
}

export const AnimatedView: FC<AnimatedViewProps> = (props) => (
  <View showAnimation={"fade-in"} hideAnimation={"fade-out"} {...props} />
);
