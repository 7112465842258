import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { AccountingMethod } from "@doorloop/dto";
import moment from "moment";

export interface AccountingMethodFooterProps {
  accountingMethod: AccountingMethod;
}

const AccountingMethodFooter: React.FC<AccountingMethodFooterProps> = ({
  accountingMethod
}: AccountingMethodFooterProps) => {
  let accountingMethodText = AppStrings.Reports.TransactionsReport.AccountingMethod.Cash;

  if (accountingMethod === AccountingMethod.ACCRUAL) {
    accountingMethodText = AppStrings.Reports.TransactionsReport.AccountingMethod.Accrual;
  }

  return (
    <View className={"reportFooter"} marginTop={20} justifyContent={"center"} flexDirection={"row"}>
      <Text value={accountingMethodText} color={"black"} fontSize={14} />
      <Text
        value={AppStrings.Reports.TransactionsReport.AccountingMethod.Basis}
        color={"black"}
        fontSize={14}
        marginLeft={5}
      />
      <Text value={moment().toDate()} color={"black"} fontSize={14} marginLeft={5} formatType={"date-time"} />
    </View>
  );
};

export default AccountingMethodFooter;
