import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
});
class IFooterProps {
  isAuthed?: boolean;
  muscles!: string[];
}

const Footer: React.FC<IFooterProps> = () => {
  const classes = useStyles();

  return <Paper className={classes.root} />;
};

function mapStateToProps() {
  const props = new IFooterProps();
  return props;
}

export default connect(mapStateToProps)(Footer);
