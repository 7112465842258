import React, { useMemo } from "react";
import { View } from "DLUI/view";
import type { EsignatureRequestSignerDto } from "@doorloop/dto";
import { Grid, ListItemContainer } from "DLUI/listItems";
import { getSignersIconAndText } from "screens/esignatures/newSignatureRequest/signers/draggableSignerListItemComponent";
import { Icon } from "DLUI/icon";
import { useTranslation } from "react-i18next";
import TextEllipsis from "DLUI/text/textEllipsis";
import SignatureRequestStatus from "screens/esignatures/viewSignatureRequest/signatureRequestStatus";
import AppStrings from "locale/keys";
import { DateLabel } from "DLUI/date/dateLabel";
import { DateFormats } from "@doorloop/dto";
import { ESignatureRequestSignerStatus } from "@doorloop/dto";
import Text from "DLUI/text";
import { useParams } from "react-router-dom";
import { CheckmarkCircleIcon, OverdueCircleIcon } from "../../../../assets";
import ResendRequestElement from "screens/esignatures/viewSignatureRequest/resendRequestElement";
import { ESignatureRequestStatus } from "@doorloop/dto";
import SignerProfileImage from "DLUI/profileImage/signerProfileImage";

interface ComponentProps {
  signer: EsignatureRequestSignerDto;
  createdAt?: Date;
  nextSignerIndex?: number;
  index: number;
  requestStatus?: ESignatureRequestStatus;
}

const SignersListItem: React.FC<ComponentProps> = ({
  signer,
  createdAt,
  nextSignerIndex,
  index,
  requestStatus
}: ComponentProps) => {
  const {
    name,
    email_address,
    signerType,
    role,
    lastRemindedAt,
    status = ESignatureRequestSignerStatus.SENT,
    signerId,
    signedAt
  } = signer;
  const { signatureRequestId } = useParams<{
    signatureRequestId: string;
  }>();

  const { t } = useTranslation();
  const { signerTypeText } = useMemo(() => getSignersIconAndText(signerType), [signerType]);

  const renderStatusDataColumn = () => {
    if (requestStatus === ESignatureRequestStatus.CANCELED) {
      return (
        <View alignItems={"center"} flexDirection={"row"}>
          <View width={"auto"}>
            <Text value={AppStrings.Esignatures.LastReminderSentAt} fontSize={12} color={"gray"} />
            <DateLabel
              format={DateFormats.SHORT_DATE_TIME}
              date={lastRemindedAt || createdAt}
              textProps={{ fontSize: 14, color: "gray", marginTop: 5 }}
            />
          </View>
          <View flexDirection={"row"} justifyContent={"flex-end"} alignItems={"center"} height={"100%"} flex={1}>
            <Icon marginRight={5} width={15} height={15} Source={OverdueCircleIcon} />
            <Text color={"error"} value={AppStrings.Esignatures.Canceled} fontSize={14} marginRight={20} />
          </View>
        </View>
      );
    }
    if (status === ESignatureRequestSignerStatus.SENT || status === ESignatureRequestSignerStatus.VIEWED) {
      return (
        nextSignerIndex === index &&
        signerId && (
          <ResendRequestElement
            lastRemindedAt={lastRemindedAt}
            createdAt={createdAt}
            signerId={signerId}
            signatureRequestId={signatureRequestId}
          />
        )
      );
    }
    if (status === ESignatureRequestSignerStatus.SIGNED) {
      return (
        <View alignItems={"center"} flexDirection={"row"}>
          <View width={"auto"}>
            <Text value={AppStrings.Esignatures.SignedAt} fontSize={12} color={"gray"} />
            <DateLabel
              format={DateFormats.SHORT_DATE_TIME}
              date={signedAt}
              textProps={{ fontSize: 14, color: "gray", marginTop: 5 }}
            />
          </View>
          <View flexDirection={"row"} justifyContent={"flex-end"} alignItems={"center"} height={"100%"} flex={1}>
            <Icon marginRight={5} width={15} height={15} Source={CheckmarkCircleIcon} />
            <Text color={"green"} value={AppStrings.Esignatures.Signed} fontSize={14} marginRight={20} />
          </View>
        </View>
      );
    }
  };

  return (
    <ListItemContainer backgroundColor={"dark"}>
      <Grid showDivider xs={12} md={3} lg={3}>
        <View flexDirection={"row"} alignItems={"center"}>
          <SignerProfileImage size={35} signerId={signerId} signerType={signerType} marginRight={10} />
          <View flex={1}>
            <View alignItems={"center"} flexDirection={"row"}>
              {role && <TextEllipsis fontSize={12} color={"gray"} text={role} lines={1} />}
              {signerTypeText && (
                <TextEllipsis
                  fontSize={12}
                  color={"gray"}
                  text={role ? `(${t(signerTypeText)})` : t(signerTypeText)}
                  lines={1}
                />
              )}
            </View>
            <TextEllipsis bold fontSize={14} color={"gray"} text={name || ""} lines={1} marginTop={5} />
          </View>
        </View>
      </Grid>
      <Grid showDivider xs={12} md={3} lg={3}>
        <View>
          <Text value={AppStrings.Tenants.NewTenant.Email} fontSize={12} color={"gray"} />
          <TextEllipsis fontSize={14} bold color={"gray"} text={email_address || ""} lines={2} marginTop={5} />
        </View>
      </Grid>
      <Grid showDivider xs={12} md={2} lg={2}>
        <SignatureRequestStatus createdAt={createdAt} signer={signer} />
      </Grid>
      <Grid xs={12} md={4} lg={4}>
        {renderStatusDataColumn()}
      </Grid>
    </ListItemContainer>
  );
};

export default SignersListItem;
