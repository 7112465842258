"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LateFeePolicyFrequency = void 0;
var LateFeePolicyFrequency;
(function (LateFeePolicyFrequency) {
    LateFeePolicyFrequency["ONCE"] = "ONCE";
    LateFeePolicyFrequency["DAILY"] = "DAILY";
    LateFeePolicyFrequency["WEEKLY"] = "WEEKLY";
    LateFeePolicyFrequency["MONTHLY"] = "MONTHLY";
})(LateFeePolicyFrequency || (exports.LateFeePolicyFrequency = LateFeePolicyFrequency = {}));
