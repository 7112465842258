import { useCallback } from "react";

import type { BankAccountDto } from "@doorloop/dto";
import { BankAccountMerchantStatus } from "@doorloop/dto";

import type { TextColor } from "DLUI/text/text";
import AppStrings from "locale/keys";

import type { SVGIconComponent } from "assets/.";
import { AlertCircleIcon, CheckmarkCircleIcon, ClockOrangeIcon, InformationCircleIcon } from "assets/.";

const MerchantStatusMap: Record<
  BankAccountMerchantStatus | "FALLBACK",
  {
    statusText: string;
    statusColor: TextColor;
    statusIcon: SVGIconComponent;
  }
> = {
  [BankAccountMerchantStatus.ACCEPTING_ONLINE_PAYMENTS]: {
    statusText: AppStrings.Common.AcceptingOnlinePayments,
    statusColor: "green",
    statusIcon: CheckmarkCircleIcon
  },
  [BankAccountMerchantStatus.IDENTITY_VERIFICATION_REQUIRED]: {
    statusText: AppStrings.Accounts.IdentityVerificationRequired,
    statusColor: "error",
    statusIcon: AlertCircleIcon
  },
  [BankAccountMerchantStatus.ACCOUNT_CLOSED]: {
    statusText: AppStrings.Common.AccountClosed,
    statusColor: "orange",
    statusIcon: InformationCircleIcon
  },
  [BankAccountMerchantStatus.ACCOUNT_NOT_APPROVED]: {
    statusText: AppStrings.Common.ApplicationDeclined,
    statusColor: "error",
    statusIcon: AlertCircleIcon
  },
  [BankAccountMerchantStatus.PENDING_UNDERWRITING_APPROVAL]: {
    statusText: AppStrings.Common.ApplicationSubmitted,
    statusColor: "orange",
    statusIcon: ClockOrangeIcon
  },
  [BankAccountMerchantStatus.IDENTITY_VERIFICATION_FAILED]: {
    statusText: AppStrings.Accounts.IdentityVerificationFailed,
    statusColor: "error",
    statusIcon: AlertCircleIcon
  },
  [BankAccountMerchantStatus.MERCHANT_ONBOARDING_INCOMPLETE]: {
    statusText: AppStrings.Common.ApplicationSubmitted,
    statusColor: "orange",
    statusIcon: ClockOrangeIcon
  },
  FALLBACK: {
    statusText: "N/A",
    statusColor: "orange",
    statusIcon: InformationCircleIcon
  }
};

export const useBankAccountStatus = (bankAccountDto: BankAccountDto) => {
  const getStatus = useCallback(() => {
    const { merchantAccountStatus } = bankAccountDto;
    return MerchantStatusMap[merchantAccountStatus ?? "FALLBACK"];
  }, [bankAccountDto]);

  return {
    status: getStatus()
  };
};
