"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Step4Dto = void 0;
const class_validator_1 = require("class-validator");
const accountingExperienceLevel_1 = require("../accountingExperienceLevel");
const purposes_1 = require("../purposes");
const propertyManagementSoftware_1 = require("../enums/propertyManagementSoftware");
class Step4Dto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.Step4Dto = Step4Dto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], Step4Dto.prototype, "selectedPurpose", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((dto) => dto.selectedPurpose === purposes_1.Purposes.OTHER),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], Step4Dto.prototype, "purpose", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(accountingExperienceLevel_1.AccountingExperienceLevel),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], Step4Dto.prototype, "accountingExperienceLevel", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(propertyManagementSoftware_1.PropertyManagementSoftware),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], Step4Dto.prototype, "selectedManagementSoftware", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((dto) => dto.selectedManagementSoftware === propertyManagementSoftware_1.PropertyManagementSoftware.OTHER),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], Step4Dto.prototype, "managementSoftware", void 0);
