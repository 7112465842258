"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BalanceSheetReportRootAccounts = void 0;
var BalanceSheetReportRootAccounts;
(function (BalanceSheetReportRootAccounts) {
    BalanceSheetReportRootAccounts["ASSETS"] = "ASSETS";
    BalanceSheetReportRootAccounts["CURRENT_ASSETS"] = "CURRENT_ASSETS";
    BalanceSheetReportRootAccounts["ASSET_ACCOUNTS_RECEIVABLE"] = "ASSET_ACCOUNTS_RECEIVABLE";
    BalanceSheetReportRootAccounts["ASSET_BANK"] = "ASSET_BANK";
    BalanceSheetReportRootAccounts["ASSET_OTHER_CURRENT_ASSETS"] = "ASSET_OTHER_CURRENT_ASSETS";
    BalanceSheetReportRootAccounts["ASSET_FIXED_ASSETS"] = "ASSET_FIXED_ASSETS";
    BalanceSheetReportRootAccounts["ASSET_OTHER_ASSETS"] = "ASSET_OTHER_ASSETS";
    BalanceSheetReportRootAccounts["LIABILITIES_AND_EQUITY"] = "LIABILITIES_AND_EQUITY";
    BalanceSheetReportRootAccounts["LIABILITIES"] = "LIABILITIES";
    BalanceSheetReportRootAccounts["CURRENT_LIABILITIES"] = "CURRENT_LIABILITIES";
    BalanceSheetReportRootAccounts["LIABILITY_ACCOUNTS_PAYABLE"] = "LIABILITY_ACCOUNTS_PAYABLE";
    BalanceSheetReportRootAccounts["LIABILITY_OTHER_CURRENT_LIABILIY"] = "LIABILITY_OTHER_CURRENT_LIABILIY";
    BalanceSheetReportRootAccounts["LIABILITY_LONG_TERM_LIABILITY"] = "LIABILITY_LONG_TERM_LIABILITY";
    BalanceSheetReportRootAccounts["EQUITY"] = "EQUITY";
    BalanceSheetReportRootAccounts["EQUITY_RETAINED_EARNINGS"] = "EQUITY_RETAINED_EARNINGS";
    BalanceSheetReportRootAccounts["EQUITY_NET_INCOME"] = "EQUITY_NET_INCOME";
})(BalanceSheetReportRootAccounts || (exports.BalanceSheetReportRootAccounts = BalanceSheetReportRootAccounts = {}));
