import AppStrings from "../../../../locale/keys";

import { PropertyBankSelection } from "@/components/screens/properties/newProperty/components/propertyBankAccount/bankSelectionButtonGroup";
import { VisibilityKey } from "@/components/screens/properties/newProperty/components/propertyReserveFunds/types";
import { PropertyDefaultAccountsSettingsDto, PropertyDto, PropertySettingsDto } from "@doorloop/dto";
import { getIn } from "formik";

import type { AddressDto, DisplayPropertyWithUnitsDto, PropertyOwnerDto, UnitDto } from "@doorloop/dto";
import type { FormikProps } from "formik";
import type { Dispatch, SetStateAction } from "react";

export const isValidatePropertyType = async (formik: FormikProps<{ property: PropertyDto | undefined }>) => {
  formik.setFieldTouched("property.type");

  const isOther = PropertyDto.isOtherType(formik.values?.property?.type);

  if (isOther) {
    formik.setFieldTouched("property.typeDescription");
  }

  const errors = await formik.validateForm();

  if (getIn(errors, "property.type")) {
    return false;
  }

  if (isOther && getIn(errors, "property.typeDescription")) {
    return false;
  }

  return true;
};

export const validateAddress = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formikBase: FormikProps<any>,
  formikName: string
) => {
  const formik = formikBase as FormikProps<AddressDto>;

  formik.setFieldTouched(formikName + ".street1");
  formik.setFieldTouched(formikName + ".city");
  formik.setFieldTouched(formikName + ".country");
  formik.setFieldTouched(formikName + ".zip");
  formik.setFieldTouched(formikName + ".state");

  const errors = await formik.validateForm();
  const addressErrors = getIn(errors, formikName);

  if (!addressErrors) {
    return true;
  }

  return (
    !addressErrors.zip &&
    !addressErrors.street1 &&
    !addressErrors.city &&
    !addressErrors.country &&
    !addressErrors.state
  );
};

export const validateUnits = async (formikRef: FormikProps<{ units: UnitDto[] | undefined }>): Promise<boolean> => {
  const { units } = formikRef.values;

  if (!units) {
    return false;
  }

  const unitsNames = new Set<string>();

  units.forEach((unit: UnitDto, index) => {
    formikRef.setFieldTouched(`units[${index}].name`);
    if (unit.name) {
      unitsNames.add(unit.name);
    }
  });

  const invalidUnitsName = unitsNames.size > 0 && unitsNames.size !== units.length;

  if (invalidUnitsName) {
    formikRef.setFieldValue("invalidUnitsName", true);
    return false;
  }
  formikRef.setFieldValue("invalidUnitsName", false);

  const errors = await formikRef.validateForm();

  return !errors.units;
};

export const validateReserveFunds = async (formikRef: FormikProps<DisplayPropertyWithUnitsDto>) => {
  const reserveFundsEnabled = formikRef.values?.reserveFundsEnabled;
  formikRef.setFieldTouched("reserveFundsEnabled");

  if (reserveFundsEnabled === VisibilityKey.ENABLED) {
    formikRef.setFieldTouched("property.settings.propertyReserve.propertyReserveAmount");
  }

  const errors = (await formikRef.validateForm()) as any;

  return (
    !errors.reserveFundsEnabled &&
    ((reserveFundsEnabled === VisibilityKey.ENABLED &&
      !errors.property?.settings?.propertyReserve?.propertyReserveAmount) ||
      reserveFundsEnabled === VisibilityKey.DISABLED)
  );
};

export const isValidOwnershipDistribution = (owners: PropertyOwnerDto[]): boolean => {
  let totalOwnership = 0;

  owners.forEach((arrayItem: PropertyOwnerDto) => {
    totalOwnership += Number(arrayItem.ownershipPercentage || 0);
  });

  return totalOwnership === 100 || owners.length === 0;
};

export const validateOwnership = async (
  formikRef: FormikProps<DisplayPropertyWithUnitsDto>,
  _,
  __,
  setWizardGeneralError: Dispatch<SetStateAction<string | undefined>>
) => {
  formikRef.setFieldTouched(`propertyOwnershipEnabled`);

  if (formikRef.values?.propertyOwnershipEnabled === VisibilityKey.ENABLED) {
    if (!formikRef.values?.property?.owners?.length) {
      setWizardGeneralError(AppStrings.Owners.EditOwners.OwnershipValidationNoOwnerSelected);
      return false;
    }
    formikRef.values.property.owners.forEach((arrayItem, index) => {
      formikRef.setFieldTouched(`property.owners[${index}].owner`);
      formikRef.setFieldTouched(`property.owners[${index}].ownershipPercentage`);
    });

    await formikRef.validateForm();

    if (!isValidOwnershipDistribution(formikRef.values.property.owners)) {
      setWizardGeneralError(AppStrings.Owners.EditOwners.OwnershipValidationText);
      return false;
    }
  } else {
    const errors = await formikRef.validateForm();

    return !errors.propertyOwnershipEnabled;
  }

  return true;
};

export const validateBankAccounts = async (formikRef: FormikProps<DisplayPropertyWithUnitsDto>): Promise<boolean> => {
  if (formikRef.values.specifyAccounts === PropertyBankSelection.Specify) {
    formikRef.setFieldTouched("client_bank_operating");
    formikRef.setFieldTouched("client_bank_trust");
    formikRef.setFieldTouched("specifySecurityDeposit");

    const errors = await formikRef.validateForm();

    if (errors.client_bank_operating || errors.client_bank_trust || errors.specifySecurityDeposit) {
      return false;
    }
  }

  const bankOperating = getIn(formikRef.values, "client_bank_operating");
  const bankTrust = getIn(formikRef.values, "client_bank_trust");
  const specifyAccounts = getIn(formikRef.values, "specifyAccounts");
  const bankSettings = new PropertySettingsDto({
    defaultAccounts: new PropertyDefaultAccountsSettingsDto({
      bank_operating: bankOperating,
      bank_trust: bankTrust
    })
  });
  formikRef.setFieldValue("property.settings", specifyAccounts === "specify" ? bankSettings : undefined);
  return true;
};
