import React, { Fragment, useMemo } from "react";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import type { FillColors } from "DLUI/icon";
import { actionsPanelIconsTypesEnums } from "DLUI/form/fieldsActionsPanel/actionPanels/enums";
import { actionsPanelIcons } from "DLUI/form/fieldsActionsPanel/actionPanels/consts";
import { IconButton } from "DLUI/form";
import { CircularProgress } from "@material-ui/core";
import { Tooltip } from "DLUI/popover";

export type ActionPanelClick = (
  event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  options?: {
    actionPanel?: ActionPanelItemInterface;
    data?: any;
    iconsSetters?: { setViewHideToggleIcon: any };
    editMode?: boolean;
  }
) => void;

export interface ActionPanelItemInterface {
  type: actionsPanelIconsTypesEnums;
  viewHideToggleIcon?: actionsPanelIconsTypesEnums;
  clearance?: AnyPermissionClearance;
  onClick?: ActionPanelClick;
  pathColor?: FillColors;
  marginRight?: number;
  marginLeft?: number;
  tooltipContent?: JSX.Element;
  hideToolTipDelay?: number;
}

const ActionPanelItem: React.FC<ActionPanelItemInterface> = ({
  type,
  clearance,
  viewHideToggleIcon,
  tooltipContent,
  hideToolTipDelay,
  ...rest
}: ActionPanelItemInterface) => {
  const currType = useMemo(() => {
    switch (type) {
      case actionsPanelIconsTypesEnums.SHOW_ACTION_PANEL:
      case actionsPanelIconsTypesEnums.HIDE_ACTION_PANEL:
        return viewHideToggleIcon || type;
      default:
        return type;
    }
  }, [viewHideToggleIcon]);

  return (
    <Fragment>
      {currType === actionsPanelIconsTypesEnums.LOADING_ACTION_PANEL ? (
        <CircularProgress
          color={"inherit"}
          style={{
            width: "20px",
            height: "20px"
          }}
        />
      ) : actionsPanelIcons[currType] ? (
        tooltipContent ? (
          <Tooltip
            anchorTopMargin={50}
            contentView={tooltipContent}
            position={"bottom"}
            automaticCloseDelayTIme={hideToolTipDelay}
          >
            <IconButton width={20} height={20} Icon={actionsPanelIcons[currType]!} {...rest} />
          </Tooltip>
        ) : (
          <IconButton width={20} height={20} Icon={actionsPanelIcons[currType]!} {...rest} />
        )
      ) : null}
    </Fragment>
  );
};

export default ActionPanelItem;
