import React from "react";

import { Grid } from "@material-ui/core";
import type { FormikProps } from "formik";
import { FastField } from "formik";

import type { OwnerPortalSettingsDto } from "@doorloop/dto";

import AppStrings from "locale/keys";
import Text from "DLUI/text";
import { FormikSwitchButton } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import { View } from "DLUI/view";

interface ComponentProps {
  formikRef: FormikProps<OwnerPortalSettingsDto>;
}

export const Features: React.FC<ComponentProps> = () => (
  <View width={"100%"} flex={1}>
    <View>
      <Text color={"black"} fontSize={16} value={AppStrings.Common.OwnersPortalFeaturesDescription} marginBottom={16} />

      <View shouldShow={true} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
        <Text color={"black"} fontSize={20} value={AppStrings.Common.Permissions} fontWeight={700} />
        <Grid item container xs={12} md={8} lg={8}>
          <FastField
            component={FormikSwitchButton}
            name={"access.portalEnabled"}
            label={AppStrings.Common.OwnerPortalFeaturesAccess}
            marginTop={16}
          />
        </Grid>

        <SeparationLine marginTop={16} width={"100%"} height={1} />

        <View flexDirection={"row"} marginTop={16}>
          <Text color={"black"} fontSize={20} value={AppStrings.Common.TabPermissions} fontWeight={700} />
          <Grid container>
            <Grid item xs={12} md={6}>
              <View noWrap>
                <FastField
                  component={FormikSwitchButton}
                  name={"access.showTransactions"}
                  label={AppStrings.Common.Transactions}
                  marginTop={16}
                />
                <FastField
                  component={FormikSwitchButton}
                  name={"access.showRequests"}
                  label={AppStrings.Common.Requests}
                  marginTop={16}
                />
                <FastField
                  component={FormikSwitchButton}
                  name={"access.showReports"}
                  label={AppStrings.Common.Reports}
                  marginTop={16}
                />
              </View>
            </Grid>
          </Grid>
        </View>
      </View>
    </View>
  </View>
);
