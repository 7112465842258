import React from "react";
import { Button } from "@material-ui/core";
import { PlusCircleIcon } from "assets/icons";
import type { FillColors } from "DLUI/icon";
import { Icon } from "DLUI/icon";
import Text from "DLUI/text";
import makeStyles from "./styles";

interface ComponentProps {
  onAddButtonPress: () => void;
  label: string;
  iconColorFill?: FillColors | undefined;
  iconSize?: number;
  iconGap?: number;
  rootClassName?: string;
  dataCy?: string;
}

const AddSquareButton: React.FC<ComponentProps> = ({
  onAddButtonPress,
  label,
  iconColorFill = "gray",
  iconSize,
  iconGap = 10,
  rootClassName,
  dataCy
}: ComponentProps) => {
  const classes = makeStyles();

  return (
    <Button
      onClick={onAddButtonPress}
      data-cy={dataCy}
      classes={{
        root: rootClassName || classes.AddSquareButton,
        label: classes.AddSquareButtonLabel
      }}
    >
      <Icon circleFill={iconColorFill} Source={PlusCircleIcon} size={iconSize} />
      <Text align={"center"} marginTop={iconGap} value={label} fontSize={16} color={"gray"} />
    </Button>
  );
};

export default AddSquareButton;
