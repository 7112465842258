"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseOutstandingTransactionDto = void 0;
const paymentLinkedTransaction_dto_1 = require("../shared/paymentLinkedTransaction.dto");
class LeaseOutstandingTransactionDto extends paymentLinkedTransaction_dto_1.PaymentLinkedTransactionDto {
    constructor(init) {
        super(init);
        Object.assign(this, init);
    }
}
exports.LeaseOutstandingTransactionDto = LeaseOutstandingTransactionDto;
