"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConversationLinkedToType = void 0;
var ConversationLinkedToType;
(function (ConversationLinkedToType) {
    ConversationLinkedToType["PROSPECT"] = "PROSPECT";
    ConversationLinkedToType["TENANT"] = "TENANT";
    ConversationLinkedToType["OWNER"] = "OWNER";
    ConversationLinkedToType["VENDOR"] = "VENDOR";
    ConversationLinkedToType["USER"] = "USER";
    ConversationLinkedToType["SYSTEM"] = "SYSTEM";
})(ConversationLinkedToType || (exports.ConversationLinkedToType = ConversationLinkedToType = {}));
