import { queryClient } from "@/utils/queryClientProvider";
import { useMutation } from "@tanstack/react-query";
import { ApiResult } from "api/apiResult";
import { immerable, produce } from "immer";
import { buildPaginatedQueryKey } from "./createQueryStore";
import { isPaginatedState, throwIfNotSuccessful } from "./entityApiStore.utils";

import type { ApiError, BaseDto, GetAllBaseQueryResponse } from "@doorloop/dto";
import type { Draft } from "immer";
import type { RestApiBase } from "../restApiBase";
import type {
  MutationContext,
  MutationUseCreateProps,
  QueryFnData,
  UseOptimisticCreateOptions
} from "./createMutationStore.types";

// https://tanstack.com/query/v4/docs/framework/react/guides/optimistic-updates
export function createUseOptimisticCreateHook<TDto extends BaseDto, TGetAllDto>(
  baseKey: string,
  api: RestApiBase<TDto, TGetAllDto, GetAllBaseQueryResponse<TDto>>
) {
  return function useOptimisticCreate(options?: UseOptimisticCreateOptions<TDto>) {
    const queryKey = buildPaginatedQueryKey(baseKey, options?.queryFilter || {});

    return useMutation<ApiResult<TDto>, ApiError, MutationUseCreateProps<TDto>, MutationContext<TDto>>({
      mutationKey: [`${baseKey}-create`],
      mutationFn: async ({ item, options }) => {
        const apiResult = await api.create(item, options?.toasts);
        throwIfNotSuccessful(apiResult);
        return apiResult;
      },
      onMutate: async ({ item }) => {
        // Lift a mutation `Promise` so consumers can guarantee (given no error
        // occurred) that the entity was created and that the state has updated
        // from the server with the newly created entity. Otherwise, they won't
        // have the entity id. Alternatively, we can fix the server to be able
        // to create IDs from the client and disregard this promise since
        // rollback (in case of an error) is already handled for us.
        const promiseContext = {} as MutationContext<TDto>["promiseContext"];
        const mutationPromise = new Promise<ApiResult<TDto>>((resolve, reject) => {
          promiseContext.resolve = resolve;
          promiseContext.reject = reject;
        });

        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({ queryKey });

        // Snapshot the previous value
        const prevState = queryClient.getQueryData<QueryFnData<TDto>>(queryKey);

        // https://www.reddit.com/r/algorithms/comments/rlr201/binary_search_string_in_sorted_array_of_string/
        // https://www.npmjs.com/package/binary-search
        // https://immerjs.github.io/immer/complex-objects
        // Ideally, we don't want to store class instances, so we don't have
        // do these hacks to be able to mutate the state with `immer`.
        if (isPaginatedState(prevState)) {
          const [firstPage] = prevState.pages;
          if (firstPage instanceof ApiResult) {
            firstPage[immerable] = true;
          }
        } else if (prevState instanceof ApiResult) {
          prevState[immerable] = true;
        }

        // Optimistically update to the new value
        const itemDraft = item as Draft<TDto>;
        const nextState = produce(prevState, (draft) => {
          // Coerce TSC to treat `draft` as paginated query state
          if (isPaginatedState(draft)) {
            const firstPageData = draft.pages[0].data;
            if (firstPageData?.data) {
              // Caters to default sort by "createdAt" in descending order
              // (newest first) Use binary search to find the sorted index by
              // the `sort_by` field and reverse the list if the order is
              // descending.
              firstPageData.data.unshift(itemDraft);
            } else {
              // Replicate the initial state of a paginated query exactly
              draft = {
                pages: [new ApiResult({ data: [itemDraft], total: 1 })],
                pageParams: [null]
              };
            }
          } else if (draft instanceof ApiResult) {
            draft.data.data.unshift(itemDraft);
          } else {
            draft = new ApiResult({ data: [itemDraft], total: 1 });
          }
        });
        queryClient.setQueryData<QueryFnData<TDto>>(queryKey, nextState);

        // Defer the change to the next tick to avoid layout bugs
        if (options?.onMutateHook) {
          window.setTimeout(() => options.onMutateHook?.({ item, prevState, mutationPromise }));
        }

        // Return a context object with the snapshotted value
        return { prevState, promiseContext };
      },
      // If the mutation fails,
      // use the context returned from onMutate to roll back
      onError: (error, variables, context) => {
        context?.promiseContext.reject(error);
        queryClient.setQueryData<QueryFnData<TDto>>(queryKey, context?.prevState);

        // Defer the change to the next tick to avoid layout bugs
        if (options?.onErrorHook) {
          window.setTimeout(() => options.onErrorHook?.(error, variables, context));
        }
      },
      onSuccess: (data, _variables, context) => {
        context?.promiseContext.resolve(data);
      },
      // Always refetch after error or success:
      onSettled: async (data, error, variables, context) => {
        // This should be commented out when debugging or it will override the
        // optimistic update and cause us to overlook potential bugs in the
        // optimistic update itself.
        if (process.env.REACT_APP_ENV === "prod") {
          await queryClient.invalidateQueries({ queryKey });
        }

        options?.onSettledHook?.(data, error, variables, context);
      },
      ...options
    });
  };
}
