import DLButton from "DLUI/button/dlButton";
import React, { useCallback, useState } from "react";

import { useResponsiveHelper } from "@/contexts/responsiveContext";
import { PersonTypeEnum } from "@doorloop/dto";
import { AddCircleIcon } from "assets/icons";
import { useTypedTranslation } from "locale/useTypedTranslation";
import { useContextSelector } from "use-context-selector";
import { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "../../../button/dlButton/enums";
import { AddNewPersonFormMemo } from "./addNewPersonForm";
import { PeopleAutoCompleteContext } from "./peopleAutoCompleteContext";

import type { CSSProperties } from "react";

export const addNewButtonStyle: CSSProperties = {
  minHeight: 32,
  borderColor: "rgba(24, 44, 76, 0.23)"
};

const containerStyle: CSSProperties = {
  margin: "12px 12px 0px 12px",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: 12
};

export const AddNewPerson = ({ style }: { style?: CSSProperties }) => {
  const { t } = useTypedTranslation();

  const autoCompleteContextMeta = useContextSelector(PeopleAutoCompleteContext, (context) => context?.meta);
  const typeFilter = autoCompleteContextMeta?.typeFilter;

  const [personType, setPersonType] = useState<PersonTypeEnum | null>(null);

  const handleCancel = useCallback(() => {
    setPersonType(null);
  }, []);

  const { isMobile } = useResponsiveHelper();

  const buttons = [
    {
      personType: PersonTypeEnum.VENDOR,
      label: t(isMobile ? "vendors.metadata.titleSingular" : "vendors.creatable.buttonLabel")
    },
    {
      personType: PersonTypeEnum.OWNER,
      label: t(isMobile ? "owners.metadata.titleSingular" : "owners.creatable.buttonLabel")
    },
    {
      personType: PersonTypeEnum.TENANT,
      label: t(isMobile ? "tenants.metadata.titleSingular" : "tenants.creatable.buttonLabel")
    }
  ].filter((button) => !typeFilter || typeFilter.includes(button.personType));

  // Override the label, if there's only one option, to singular form.
  if (buttons.length === 1) {
    buttons[0].label = t("common.addNew");
  }

  // TODO Read `autoCompleteContext.value` and filter it against the options
  // filter (`autoCompleteContext.filterOptions`). If it's not in the filter or
  // none of the filtered options are an exact match, pass it to the form, so
  // when a user clicks to create a person, it will populate the first field in
  // the form with the value.

  return (
    <div style={{ ...containerStyle, ...style }}>
      {!personType &&
        buttons.map((button) => (
          <DLButton
            key={button.personType}
            actionText={button.label}
            variant={DLButtonVariantsEnum.OUTLINED}
            color={DLButtonColorsEnum.NEUTRAL}
            size={isMobile ? DLButtonSizesEnum.MEDIUM : DLButtonSizesEnum.SMALL}
            style={{
              ...addNewButtonStyle,
              ...(isMobile && {
                minHeight: 48,
                minWidth: "fit-content",
                maxWidth: "33.333%",
                flexGrow: 1
              })
            }}
            icons={{ start: { src: AddCircleIcon } }}
            onClick={() => setPersonType(button.personType)}
            tabIndex={0}
            disableMobileCollapse
          ></DLButton>
        ))}
      {personType && (
        <AddNewPersonFormMemo
          personType={personType}
          numPersonTypes={typeFilter?.length ?? 0}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export const AddNewPersonMemo = React.memo(AddNewPerson);
