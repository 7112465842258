import React, { useEffect, useState } from "react";
import { View } from "DLUI/view";
import DialogFrameHeader from "screens/transactions/components/bankConnect/dialogs/bankConnect/dialogFrameHeader";
import { Icon } from "DLUI/icon";
import { BankConnectIcon } from "@/assets";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { plaidTransactionsApi } from "api/plaidTransactionsApi";
import DLButton, { DLButtonSizesEnum } from "DLUI/button/dlButton";
import { QueryParams } from "utils/queryParams";

interface ComponentProps {
  didReceivePlaidToken: (token: string) => void;
  onClose: () => void;
  isRepairConnectionMode?: boolean;
}

const AddNewConnectionView: React.FC<ComponentProps> = ({ didReceivePlaidToken, onClose, isRepairConnectionMode }) => {
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const plaidItemId = QueryParams.get("plaidItem") as string | undefined;

  const showErrorMessage = (error: string) => {
    setLoadingDialogErrorText(error);
    setLoadingDialogState(DialogState.Error);
  };

  const getPlaidToken = async () => {
    setLoadingDialogState(DialogState.Show);
    const plaidTokenResponse = await plaidTransactionsApi.getPlaidToken().catch((e) => {
      showErrorMessage(e);
    });
    if (plaidTokenResponse?.data) {
      didReceivePlaidToken(plaidTokenResponse.data.token);
      setLoadingDialogState(DialogState.Hidden);
    } else {
      showErrorMessage(
        plaidTokenResponse && plaidTokenResponse?.message ? plaidTokenResponse?.message : AppStrings.Common.GeneralError
      );
    }
  };

  const getPlaidTokenOnRepair = async () => {
    try {
      setLoadingDialogState(DialogState.Show);
      const plaidTokenResponse = await plaidTransactionsApi.getPlaidTokenForRepair(plaidItemId);
      if (plaidTokenResponse && plaidTokenResponse.data) {
        didReceivePlaidToken(plaidTokenResponse.data.token);
        setLoadingDialogState(DialogState.Hidden);
      } else {
        showErrorMessage(plaidTokenResponse?.message || AppStrings.Common.GeneralError);
      }
    } catch (e: any) {
      showErrorMessage(e?.message || AppStrings.Common.GeneralError);
    }
  };

  const didPressConnectAccount = () => {
    setLoadingDialogState(DialogState.Show);
    getPlaidToken();
  };

  useEffect(() => {
    if (isRepairConnectionMode) {
      getPlaidTokenOnRepair();
    }
  }, [isRepairConnectionMode]);

  const renderContent = () => {
    if (loadingDialogState !== DialogState.Hidden) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          errorText={loadingDialogErrorText}
          onRetryButtonPress={getPlaidToken}
          didPressDismissButton={onClose}
        />
      );
    }
    return (
      <>
        <Icon Source={BankConnectIcon} />
        <Text fontSize={45} value={AppStrings.Common.ConnectYourAccounts} marginTop={20} />
        <Text
          value={AppStrings.Common.ConnectYourAccountsDescription}
          marginTop={20}
          fontSize={20}
          maxWidth={650}
          lineHeight={"26px"}
          align={"center"}
        />
        <DLButton
          actionText={AppStrings.Common.ConnectAccount}
          onClick={didPressConnectAccount}
          size={DLButtonSizesEnum.LARGE}
          style={{ marginTop: 20 }}
          icons={{ start: { isHidden: false } }}
        />
      </>
    );
  };

  return (
    <View height={"100%"}>
      <DialogFrameHeader onCloseButtonPress={onClose} />
      <View width={"100%"} justifyContent={"center"} alignItems={"center"} flex={1}>
        {renderContent()}
      </View>
    </View>
  );
};

export default AddNewConnectionView;
