import { ServerRoutes } from "@doorloop/dto";
import { tenantsApi } from "./tenantsApi";
import { ownersApi } from "./ownersApi";
import type { GetPrintChecksReportQuery, PrintChecksReportItemDto } from "@doorloop/dto";
import { apiHelper } from "./apiHelper";
import type { ApiResult } from "./apiResult";
import type { PostPrintedChecksRequest } from "@doorloop/dto";
import { RestApiBase } from "api/restApiBase";
import type { GetCheckInformationForAccountRequestDto } from "@doorloop/dto";
import type { PutNextCheckNumberForAccountRequestDto } from "@doorloop/dto";
import { accountsApi } from "api/accounts";
import { vendorsApi } from "./vendorsApi";
import { propertiesApi } from "./propertiesApi";

export class ChecksApi extends RestApiBase<PrintChecksReportItemDto, GetPrintChecksReportQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_PRINT_CHECKS, [tenantsApi, ownersApi, vendorsApi, propertiesApi, accountsApi]);
    this.restRoute = ServerRoutes.REPORTS_PRINT_CHECKS;
  }

  postPrintedChecks = async (data: PostPrintedChecksRequest): Promise<ApiResult<any>> =>
    await apiHelper.axiosPost({ url: ServerRoutes.EXPENSES_POST_PRINTED_CHECKS, data });

  postPrintedChecksLastCheckNumber = async (data: PutNextCheckNumberForAccountRequestDto): Promise<ApiResult<any>> =>
    await apiHelper.axiosPut({ url: ServerRoutes.ACCOUNTS_PUT_NEXT_CHECK_NUMBER, data });

  getCheckInformation = async (data: GetCheckInformationForAccountRequestDto): Promise<ApiResult<any>> =>
    await apiHelper.axiosGet(ServerRoutes.ACCOUNTS_GET_CHECK_INFORMATION, data);
}

export const checksApi = new ChecksApi();
