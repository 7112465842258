import type { DisplayEmailDto, OwnerDto, PersonDto, TenantDto } from "@doorloop/dto";
import { type DisplayPhoneDto, EmailType, PhoneType } from "@doorloop/dto";
import type { FormikProps } from "formik";
import { getIn } from "formik";
import AppStrings from "locale/keys";
import { phoneRegEx } from "utils/regExps";
import type { TFunction } from "react-i18next";
import { isEmail } from "class-validator";

interface OnPersonChangeProps<TData> {
  formikRef: FormikProps<TData>;
  value: string;
  shouldOverrideCurrentValue?: boolean;
}

export function onPortalLoginEmailChange<TData>({
  formikRef,
  value,
  shouldOverrideCurrentValue = true
}: OnPersonChangeProps<TData>) {
  const emails = getIn(formikRef.values, "emails") ?? ([] as DisplayEmailDto[]);

  const existingPrimaryEmail = emails.find((x: DisplayEmailDto) => x.type === EmailType.PRIMARY);

  if (existingPrimaryEmail && shouldOverrideCurrentValue) {
    existingPrimaryEmail.address = value;
  } else {
    emails.unshift({
      type: EmailType.PRIMARY,
      address: value
    });
  }
  const validEmails = emails.filter((x: DisplayEmailDto) => x.address && x.type);

  if (validEmails.length === 0) {
    // add default empty primary email address row
    validEmails.push({
      type: EmailType.PRIMARY
    });
  }

  formikRef.setFieldValue("emails", validEmails);
}

export function onPortalPhoneNumberChange<TData>({
  formikRef,
  value,
  shouldOverrideCurrentValue = true
}: OnPersonChangeProps<TData>) {
  const phones = getIn(formikRef.values, "phones") ?? ([] as DisplayPhoneDto[]);

  const existingMobilePhone = phones.find((x: DisplayPhoneDto) => x.type === PhoneType.MOBILE);
  if (existingMobilePhone && shouldOverrideCurrentValue) {
    existingMobilePhone.number = value;
  } else {
    phones.unshift({
      type: PhoneType.MOBILE,
      number: value
    });
  }
  const validPhones = phones.filter((x: DisplayPhoneDto) => x.number && x.type);

  if (validPhones.length === 0) {
    // add default empty mobile phone row
    validPhones.push({
      type: PhoneType.MOBILE
    });
  }

  formikRef.setFieldValue("phones", validPhones);
}

type PersonTypesWithPortalInfo = TenantDto | OwnerDto;

export function validatePortalInfoEmail(values: PersonTypesWithPortalInfo, t: TFunction<"translation", undefined>) {
  if (!values.portalInfo?.loginEmail || !isEmail(values.portalInfo?.loginEmail)) {
    return { portalInfo: { loginEmail: t(AppStrings.Common.InvalidEmail) } };
  }

  return null;
}

export function validatePortalInfoPhone(values: PersonTypesWithPortalInfo, t: TFunction<"translation", undefined>) {
  if (!values.portalInfo?.mobilePhone || !phoneRegEx.test(values.portalInfo?.mobilePhone)) {
    return { portalInfo: { mobilePhone: t(AppStrings.Common.InvalidPhoneNumber) } };
  }

  return null;
}

export function validatePersonFirstEmail(values: PersonDto, t: TFunction<"translation", undefined>) {
  if (!values.emails?.[0]?.address || !isEmail(values.emails?.[0]?.address)) {
    return { emails: [{ address: t(AppStrings.Common.InvalidEmail) }] };
  }
}

export function validatePersonFirstPhone(values: PersonDto, t: TFunction<"translation", undefined>) {
  if (!values.phones?.[0]?.number || !phoneRegEx.test(values.phones?.[0]?.number)) {
    return { phones: [{ number: t(AppStrings.Common.InvalidPhoneNumber) }] };
  }
}
