"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntroPersonDto = exports.PersonDto = void 0;
const base_dto_1 = require("../base.dto");
const class_validator_1 = require("class-validator");
const address_dto_1 = require("./address.dto");
const email_dto_1 = require("./email.dto");
const phone_dto_1 = require("./phone.dto");
const class_transformer_1 = require("class-transformer");
const gender_enum_1 = require("./gender.enum");
const validators_1 = require("./validators");
const personOutgoingEPayInfo_dto_1 = require("../common/personOutgoingEPayInfo.dto");
const validators_utils_1 = require("./validators/validators.utils");
const personType_enum_1 = require("./personType.enum");
class PersonDto extends base_dto_1.BaseDto {
}
exports.PersonDto = PersonDto;
__decorate([
    (0, validators_utils_1.IsPersonName)({ required: true }),
    __metadata("design:type", String)
], PersonDto.prototype, "firstName", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], PersonDto.prototype, "middleName", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(gender_enum_1.Gender),
    __metadata("design:type", String)
], PersonDto.prototype, "gender", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, validators_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], PersonDto.prototype, "dateOfBirth", void 0);
__decorate([
    (0, validators_utils_1.IsPersonName)({ required: true }),
    __metadata("design:type", String)
], PersonDto.prototype, "lastName", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((person) => !person.lastName && !person.firstName),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], PersonDto.prototype, "companyName", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(255),
    __metadata("design:type", String)
], PersonDto.prototype, "timezone", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], PersonDto.prototype, "company", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], PersonDto.prototype, "jobTitle", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(1000),
    __metadata("design:type", String)
], PersonDto.prototype, "notes", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(200),
    __metadata("design:type", String)
], PersonDto.prototype, "intercomContactId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => email_dto_1.EmailDto),
    __metadata("design:type", Array)
], PersonDto.prototype, "emails", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => phone_dto_1.PhoneDto),
    __metadata("design:type", Array)
], PersonDto.prototype, "phones", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => address_dto_1.AddressDto),
    (0, class_validator_1.ValidateIf)((person) => person.primaryAddress &&
        (person.primaryAddress.street1 ||
            person.primaryAddress.street2 ||
            person.primaryAddress.city ||
            person.primaryAddress.country)),
    __metadata("design:type", address_dto_1.AddressDto)
], PersonDto.prototype, "primaryAddress", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => address_dto_1.AddressDto),
    (0, class_validator_1.ValidateIf)((person) => person.alternateAddress &&
        (person.alternateAddress.street1 ||
            person.alternateAddress.street2 ||
            person.alternateAddress.city ||
            person.alternateAddress.country)),
    __metadata("design:type", address_dto_1.AddressDto)
], PersonDto.prototype, "alternateAddress", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], PersonDto.prototype, "useAlternateAsMailingAddress", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => personOutgoingEPayInfo_dto_1.PersonOutgoingEPayInfoDto),
    __metadata("design:type", personOutgoingEPayInfo_dto_1.PersonOutgoingEPayInfoDto)
], PersonDto.prototype, "outgoingEPay", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], PersonDto.prototype, "disablePersonDetailsValidation", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], PersonDto.prototype, "shouldSendWelcomeSms", void 0);
class IntroPersonDto {
}
exports.IntroPersonDto = IntroPersonDto;
__decorate([
    (0, validators_utils_1.IsPersonName)({ required: true }),
    __metadata("design:type", String)
], IntroPersonDto.prototype, "firstName", void 0);
__decorate([
    (0, validators_utils_1.IsPersonName)({ required: true }),
    __metadata("design:type", String)
], IntroPersonDto.prototype, "lastName", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((person, value) => (person.type === personType_enum_1.PersonTypeEnum.VENDOR && value) || person.type !== personType_enum_1.PersonTypeEnum.VENDOR),
    (0, class_validator_1.IsEmail)(),
    __metadata("design:type", Object)
], IntroPersonDto.prototype, "email", void 0);
