"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfitAndLossDataDto = exports.ProfitAndLossDto = void 0;
class ProfitAndLossDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.ProfitAndLossDto = ProfitAndLossDto;
class ProfitAndLossDataDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.ProfitAndLossDataDto = ProfitAndLossDataDto;
