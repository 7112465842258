import React from "react";
import type { DialogState } from "DLUI/dialogs/loadingDialog";
import { AnimatedContentDialog } from "DLUI/dialogs";
import { getVendorFormMenuItems } from "./menuItems";
import { initFormValues, validateForm, validateVendorForm } from "./formikHelper";
import AppStrings from "locale/keys";
import { vendorsApi } from "api/vendorsApi";
import { useTranslation } from "react-i18next";
import { ObjectPermission } from "@doorloop/dto";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import { useBasePersonValidation } from "screens/vendors/useBasePersonValidation";

interface ComponentProps {
  dialogState: DialogState;
  onClose: () => void;
  onBackdropClick: () => void;
  apiObjectId?: string;
  title?: string;
}

const VendorDialog: React.FC<ComponentProps> = ({
  dialogState,
  onClose,
  onBackdropClick,
  apiObjectId,
  title
}: ComponentProps) => {
  const newTenantDialogItems = getVendorFormMenuItems();
  const { personValidateBase } = useBasePersonValidation();

  const { t } = useTranslation();
  return (
    <AnimatedContentDialog
      dialogState={dialogState}
      onClose={onClose}
      sectionItems={newTenantDialogItems}
      disableBackdropClick
      onBackdropClick={onBackdropClick}
      getformikInitialValues={initFormValues}
      formikValidation={validateForm}
      validationMethod={async (formik) => await personValidateBase(formik, validateVendorForm, newTenantDialogItems)}
      loadingText={t(AppStrings.Vendors.NewVendor.LoadingText)}
      errorText={t(AppStrings.Vendors.NewVendor.ErrorText)}
      successText={t(AppStrings.Vendors.NewVendor.SuccessText)}
      apiMethod={vendorsApi}
      dialogTitle={title ?? AppStrings.Vendors.Screen.AddNewVendor}
      dialogHeight={getDialogFrameDimension("height", 800)}
      dialogWidth={getDialogFrameDimension("width", 1100)}
      permission={ObjectPermission.vendors}
      apiObjectId={apiObjectId}
    />
  );
};

export default VendorDialog;
