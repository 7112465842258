import type { FC } from "react";
import React from "react";
import { DialogViews } from "screens/outgoingPayments/actions/sendOutgoingPayment";
import AppStrings from "locale/keys";
import { DLButtonVariantsEnum, DLButtonColorsEnum } from "DLUI/button/dlButton";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";

interface Props {
  currentView: DialogViews;
  onClose: () => void;
  sendHandler: () => void;
  errorText?: string;
}

export const SendOutgoingPaymentActionPanel: FC<Props> = ({ currentView, onClose, sendHandler, errorText }: Props) => {
  if (currentView) {
    if (currentView === DialogViews.BEFORE_SEND) {
      return (
        <FormActionButtons
          propsSubButton={{ onClick: onClose }}
          propsMainButton={{ type: "cta", props: { onClick: sendHandler, actionText: AppStrings.Common.Confirm } }}
        />
      );
    }
    if (errorText) {
      return (
        <FormActionButtons
          propsSubButton={{
            onClick: onClose,
            variant: DLButtonVariantsEnum.OUTLINED,
            color: DLButtonColorsEnum.DANGER,
            actionText: AppStrings.Common.Dismiss
          }}
        />
      );
    }
    return (
      <FormActionButtons
        propsSubButton={{
          actionText: AppStrings.Common.Done,
          onClick: () => {
            onClose();
            window.location.reload();
          },
          color: DLButtonColorsEnum.PRIMARY
        }}
      />
    );
  }
  return null;
};
