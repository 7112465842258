"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fileFilterExtensionsMap = void 0;
const fileFilterTypes_enum_1 = require("./fileFilterTypes.enum");
exports.fileFilterExtensionsMap = {
    [fileFilterTypes_enum_1.FileFilterTypes.GENERAL]: {
        fileTypes: /mp4|webm|avi|wmv|mpeg|m4v|mov|video\/quicktime|jpeg|jpg|png|gif|svg|doc|docx|pdf|xlsx|xls/
    },
    [fileFilterTypes_enum_1.FileFilterTypes.PICTURE]: {
        fileTypes: /jpeg|jpg|png|gif|svg/
    },
    [fileFilterTypes_enum_1.FileFilterTypes.DOCUMENT]: {
        fileTypes: /doc|docx|pdf|xlsx|xls/
    },
    [fileFilterTypes_enum_1.FileFilterTypes.VIDEOS]: {
        fileTypes: /mp4|webm|avi|wmv|mpeg|m4v|mov|video\/quicktime/
    }
};
