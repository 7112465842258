import type { FC } from "react";
import React from "react";
import type { ReactNode } from "react";
import { useMemo } from "react";
import type { PropsWithChildren } from "react";

import { Grid } from "@material-ui/core";

import type { IconProps } from "DLUI/icon";
import { Icon } from "DLUI/icon";
import Text from "DLUI/text";

import type { SVGIconComponent } from "@/assets";
import { InfoOutlineIcon } from "../../../../../../../assets";
import type { TextComponentProps } from "DLUI/text/text";
import { View } from "DLUI/view";

interface ElementPrefixedTextProps extends PropsWithChildren<unknown> {
  iconSrc?: SVGIconComponent;
  elementType?: ElementType;
  text: ReactNode;
  textProps?: TextComponentProps;
  iconProps?: Omit<IconProps, "Source">;
  useFlex?: boolean;
  onClick?: VoidFunction;
}

export enum ElementType {
  BULLET = "•",
  ICON = "ICON"
}

const ElementPrefixedText: FC<ElementPrefixedTextProps> = ({
  text,
  iconSrc = InfoOutlineIcon,
  children,
  elementType = ElementType.ICON,
  textProps = {},
  useFlex,
  iconProps,
  onClick
}) => {
  const element: ReactNode = useMemo(() => {
    const elements = {
      [ElementType.BULLET]: <Text {...textProps} value={"•"} />,
      [ElementType.ICON]: (
        <Icon pathColor="black" circleFill="black" Source={iconSrc} width={20} height={20} {...iconProps} />
      )
    };

    return elements[elementType];
  }, [iconSrc, elementType]);

  if (useFlex) {
    return (
      <View flexDirection={"row"} noWrap gap={4}>
        <View width={"auto"}>{element}</View>
        <View noWrap flex={1} onClick={onClick}>
          <Text {...textProps}>{text}</Text>
          {children}
        </View>
      </View>
    );
  }

  return (
    <Grid container alignItems="flex-start">
      <Grid item xs={1}>
        {element}
      </Grid>
      <Grid item xs={11} onClick={onClick}>
        <Text fullWidth color="black" {...textProps}>
          {text}
        </Text>
        {children}
      </Grid>
    </Grid>
  );
};

type ElementPrefixedTextListProps = Omit<ElementPrefixedTextProps, "text"> & {
  items: string[];
  gap?: number;
};

const ElementPrefixedTextList: FC<ElementPrefixedTextListProps> = ({
  elementType,
  items,
  gap = 4,
  textProps,
  useFlex
}) => (
  <View noWrap gap={gap}>
    {items.map((item, index) => (
      <ElementPrefixedText key={index} elementType={elementType} text={item} textProps={textProps} useFlex={useFlex} />
    ))}
  </View>
);

export { ElementPrefixedText, ElementPrefixedTextList };
