import { PropertyType } from "@doorloop/dto";

import {
  CondoIcon,
  CoWorkingIcon,
  IndustrialIcon,
  MultiFamilyIcon,
  OfficeIcon,
  StorageIcon,
  ParkingIcon,
  RetailIcon,
  ShoppingCenterIcon,
  SingleFamilyIcon,
  TownHomeIcon,
  RentalsIcon
} from "assets/icons";
import type { SVGIconComponent } from "assets/icons/types";

export const getPropertyIcon = (propertyType: string): SVGIconComponent => {
  switch (propertyType) {
    case PropertyType.COMMERCIAL_CO_WORKING:
      return CoWorkingIcon;
    case PropertyType.RESIDENTIAL_CONDO:
      return CondoIcon;
    case PropertyType.COMMERCIAL_INDUSTRIAL:
      return IndustrialIcon;
    case PropertyType.RESIDENTIAL_MULTI_FAMILY:
      return MultiFamilyIcon;
    case PropertyType.COMMERCIAL_OFFICE:
      return OfficeIcon;
    case PropertyType.COMMERCIAL_STORAGE:
      return StorageIcon;
    case PropertyType.COMMERCIAL_PARKING:
      return ParkingIcon;
    case PropertyType.COMMERCIAL_RETAIL:
      return RetailIcon;
    case PropertyType.COMMERCIAL_SHOPPING_CENTER:
      return ShoppingCenterIcon;
    case PropertyType.RESIDENTIAL_SINGLE_FAMILY:
      return SingleFamilyIcon;
    case PropertyType.RESIDENTIAL_TOWNHOME:
      return TownHomeIcon;
    case PropertyType.COMMERCIAL_OTHER:
    case PropertyType.RESIDENTIAL_OTHER:
      return RentalsIcon;
    default:
      return CondoIcon;
  }
};
