import { ListItemContainerDefaultMinWidth } from "DLUI/infiniteList/utils";
import type { PropsWithChildren } from "react";
import React from "react";
import { View } from "DLUI/view";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

const SearchResultsListItemWrapper: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const { screenContainerPadding, isMobile } = useResponsiveHelper();
  return (
    <View
      paddingRight={screenContainerPadding}
      paddingLeft={screenContainerPadding}
      minWidth={isMobile ? undefined : ListItemContainerDefaultMinWidth}
    >
      {children}
    </View>
  );
};

export default SearchResultsListItemWrapper;
