import React, { useState } from "react";
import { authApi } from "api/authApi";
import { CloseIcon, ErrorSignIcon } from "assets/icons";
import { ShakeEffectView } from "DLUI/animatableView";
import { IconButton } from "DLUI/form";
import { Icon } from "DLUI/icon";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import { useSelector } from "react-redux";
import type { IRootState } from "../../../../store";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum } from "DLUI/button/dlButton";

import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import {
  NOTIFICATIONS_CONTENT_DESKTOP_HEIGHT,
  NOTIFICATIONS_CONTENT_MOBILE_HEIGHT
} from "DLUI/screen/notificationsPanel/notificationsPanel";

interface ComponentProps {
  onDismissButtonPress: () => void;
}

const EmailConfirmation: React.FC<ComponentProps> = ({ onDismissButtonPress }: ComponentProps) => {
  const [showErrorShakeEffect, setShowErrorShakeEffect] = useState(false);
  const [loadingInProgress, setLoadingInProgress] = useState(false);

  const { isMobile } = useResponsiveHelper();

  const currentUser = useSelector((state: IRootState) => state.auth.currentLoginResponse);

  const userEmail = currentUser?.loginEmail || "";

  const runShakeEffect = () => {
    setShowErrorShakeEffect(true);
    setTimeout(() => {
      setShowErrorShakeEffect(false);
    }, 300);
  };

  const didPressResendEmail = async () => {
    if (loadingInProgress) {
      runShakeEffect();
      return;
    }

    setLoadingInProgress(true);
    await authApi.resendEmailConfirmation();

    setTimeout(() => {
      setLoadingInProgress(false);
      onDismissButtonPress();
    }, 1000);
  };

  return (
    // don't show in subscription page
    <View
      className={"DLUI_EmailConfirmation"}
      flexDirection={"row"}
      height={isMobile ? NOTIFICATIONS_CONTENT_MOBILE_HEIGHT : NOTIFICATIONS_CONTENT_DESKTOP_HEIGHT}
    >
      <View flexDirection={"row"} justifyContent={"center"} alignItems={"center"} flex={1} height={"100%"}>
        <Icon Source={ErrorSignIcon} pathColor={"white"} />
        <Text
          marginLeft={10}
          value={AppStrings.Common.NotificationsPanel.EmailConfirm.ConfirmMessage}
          fontSize={16}
          color={"white"}
        />
        <Text fontSize={16} color={"white"} marginLeft={10}>
          {userEmail}
        </Text>
        <ShakeEffectView showEffect={showErrorShakeEffect}>
          <DLButton
            actionText={AppStrings.Common.NotificationsPanel.EmailConfirm.ResendEmail}
            onClick={didPressResendEmail}
            color={DLButtonColorsEnum.NEUTRAL}
            size={DLButtonSizesEnum.LARGE}
            style={{ marginLeft: 20 }}
            isLoading={loadingInProgress}
          />
        </ShakeEffectView>
      </View>
      <View justifyContent={"center"} alignItems={"center"} height={"100%"} width={"50px"}>
        <IconButton onClick={onDismissButtonPress} pathColor={"white"} Icon={CloseIcon} />
      </View>
    </View>
  );
};

export default EmailConfirmation;
