import { lazy } from "react";
import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";

const PayBillsScreen = lazy(() => import("screens/bills/payBills/payBillsScreen"));

const BillsRouter = () => (
  <PageRouter>
    <LazyRoute path={Routes.PAY_BILLS} render={PayBillsScreen} />
  </PageRouter>
);

export default BillsRouter;
