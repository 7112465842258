import type { Periods } from "@doorloop/dto";
import { PropertyClass, PropertyType, RentalApplicationDecisionStatus } from "@doorloop/dto";
import type { SVGIconComponent } from "assets/icons";
import { AlertCircleIcon, CheckmarkCircleIcon, ClockOrangeIcon, IgnoreCircleIcon } from "assets/icons";
import type { FillColors } from "DLUI/icon";
import type { TextColor } from "DLUI/text/text";
import AppStrings from "locale/keys";
import moment from "moment";
import qs from "query-string";

export const getPropertyClass = (stringToConvert: string) => {
  let convertedString = "";
  switch (stringToConvert) {
    case PropertyClass.COMMERCIAL:
      convertedString = AppStrings.Properties.Type.Commercial;
      break;
    case PropertyClass.RESIDENTIAL:
      convertedString = AppStrings.Properties.Type.Residential;
      break;
  }
  return convertedString;
};

export const getCleanUnicodeString = (stringToConvert: string) => stringToConvert.replace(/[\u{0080}-\u{FFFF}]/gu, "");

export const getPropertyType = (stringToConvert: string) => {
  let convertedString = "";
  switch (stringToConvert) {
    case PropertyType.COMMERCIAL_INDUSTRIAL:
      convertedString = AppStrings.Properties.Subtype.Industrial;
      break;
    case PropertyType.COMMERCIAL_OFFICE:
      convertedString = AppStrings.Properties.Subtype.Office;
      break;
    case PropertyType.COMMERCIAL_PARKING:
      convertedString = AppStrings.Properties.Subtype.Parking;
      break;
    case PropertyType.COMMERCIAL_RETAIL:
      convertedString = AppStrings.Properties.Subtype.Retail;
      break;
    case PropertyType.COMMERCIAL_SHOPPING_CENTER:
      convertedString = AppStrings.Properties.Subtype.ShoppingCenter;
      break;
    case PropertyType.COMMERCIAL_STORAGE:
      convertedString = AppStrings.Properties.Subtype.Storage;
      break;
    case PropertyType.RESIDENTIAL_CONDO:
      convertedString = AppStrings.Properties.Subtype.Condo;
      break;
    case PropertyType.RESIDENTIAL_MULTI_FAMILY:
      convertedString = AppStrings.Properties.Subtype.MultiFamily;
      break;
    case PropertyType.RESIDENTIAL_SINGLE_FAMILY:
      convertedString = AppStrings.Properties.Subtype.SingleFamily;
      break;
    case PropertyType.RESIDENTIAL_TOWNHOME:
      convertedString = AppStrings.Properties.Subtype.Townhome;
      break;
    case PropertyType.RESIDENTIAL_OTHER:
    case PropertyType.COMMERCIAL_OTHER:
      convertedString = AppStrings.Properties.Subtype.Other;
      break;
  }
  return convertedString;
};

interface DecisionStatusElementsProps {
  decisionStatusText: string;
  decisionStatusIcon: SVGIconComponent;
  decisionStatusIconCircleFill: FillColors | undefined;
  decisionStatusTextColor: TextColor | undefined;
}

export const getDecisionStatus = (decisionStatus?: RentalApplicationDecisionStatus): DecisionStatusElementsProps => {
  let decisionStatusText;
  let decisionStatusIcon;
  let decisionStatusIconCircleFill: FillColors | undefined;
  let decisionStatusTextColor: TextColor | undefined;
  switch (decisionStatus) {
    case RentalApplicationDecisionStatus.NOT_STARTED:
      decisionStatusText = AppStrings.Common.Enums.RentalApplicationDecisionStatus.NOT_STARTED;
      decisionStatusIcon = ClockOrangeIcon;
      decisionStatusIconCircleFill = "light-gray";
      decisionStatusTextColor = "gray";
      break;
    case RentalApplicationDecisionStatus.UNDECIDED:
      decisionStatusText = AppStrings.Common.Enums.RentalApplicationDecisionStatus.UNDECIDED;
      decisionStatusIcon = ClockOrangeIcon;
      decisionStatusTextColor = "orange";
      break;
    case RentalApplicationDecisionStatus.APPROVED:
      decisionStatusText = AppStrings.Common.Enums.RentalApplicationDecisionStatus.APPROVED;
      decisionStatusIcon = CheckmarkCircleIcon;
      decisionStatusTextColor = "green";
      break;
    case RentalApplicationDecisionStatus.REJECTED:
      decisionStatusText = AppStrings.Common.Enums.RentalApplicationDecisionStatus.REJECTED;
      decisionStatusIcon = AlertCircleIcon;
      decisionStatusTextColor = "error";
      break;
    case RentalApplicationDecisionStatus.IGNORED:
      decisionStatusText = AppStrings.Common.Enums.RentalApplicationDecisionStatus.IGNORED;
      decisionStatusIcon = IgnoreCircleIcon;
      decisionStatusTextColor = "gray";

      break;
    default:
      decisionStatusText = AppStrings.Common.Enums.RentalApplicationDecisionStatus.NOT_STARTED;
      decisionStatusIcon = ClockOrangeIcon;
      decisionStatusIconCircleFill = "light-gray";
      decisionStatusTextColor = "gray";

      break;
  }
  return {
    decisionStatusText,
    decisionStatusIcon,
    decisionStatusIconCircleFill,
    decisionStatusTextColor
  };
};

export const getDatesFromPeriod = (
  selectedPeriod?: Periods,
  pickerFromFiledName?: string,
  pickerToFiledName?: string
): { startDate: string | undefined; endDate: string | undefined } => {
  let startDate;
  let endDate;

  switch (selectedPeriod) {
    case "custom":
      const urlParams = qs.parse(window.location.search);
      const dateFromField = pickerFromFiledName || "filter_date_from";
      const dateToField = pickerToFiledName || "filter_date_to";
      if (urlParams[dateFromField]) {
        startDate = moment(urlParams[dateFromField]!);
      }
      if (urlParams[dateToField]) {
        endDate = moment(urlParams[dateToField]!);
      }
      break;
    case "this-week":
      startDate = moment().startOf("week");
      endDate = moment().endOf("week");
      break;
    case "this-week-to-date":
      startDate = moment().startOf("week");
      endDate = moment();
      break;
    case "this-month":
      startDate = moment().startOf("month");
      endDate = moment().endOf("month");
      break;
    case "this-month-to-date":
      startDate = moment().startOf("month");
      endDate = moment();
      break;
    case "this-year":
      startDate = moment().startOf("year");
      endDate = moment().endOf("year");
      break;
    case "last-week":
      startDate = moment().add(-1, "week").startOf("week");
      endDate = moment().add(-1, "week").endOf("week");
      break;
    case "last-month":
      startDate = moment().add(-1, "month").startOf("month");
      endDate = moment().add(-1, "month").endOf("month");
      break;
    case "last-month-to-date":
      startDate = moment().add(-1, "month").startOf("month");
      endDate = moment().add(-1, "month");
      break;
    case "this-year-to-date":
      startDate = moment().startOf("year");
      endDate = moment();
      break;
    case "this-year-to-last-month":
      startDate = moment().startOf("year");
      endDate = moment().add(-1, "month").endOf("month");
      break;
    case "this-quarter":
      startDate = moment().startOf("quarter");
      endDate = moment().endOf("quarter");
      break;
    case "last-quarter":
      startDate = moment().add(-1, "quarter").startOf("quarter");
      endDate = moment().add(-1, "quarter").endOf("quarter");
      break;
    case "last-quarter-to-date":
      startDate = moment().add(-1, "quarter").startOf("quarter");
      endDate = moment().add(-1, "quarter");
      break;
    case "this-quarter-to-date":
      startDate = moment().startOf("quarter");
      endDate = moment();
      break;
    case "today":
      startDate = moment();
      endDate = moment();
      break;
    case "last-week-to-date":
      startDate = moment().add(-1, "week").startOf("week");
      endDate = moment().add(-1, "week");
      break;
    case "yesterday":
      startDate = moment().add(-1, "days");
      endDate = moment().add(-1, "days");
      break;
    case "next-90-days":
      startDate = moment();
      endDate = moment().add(90, "days");
      break;
    case "next-60-days":
      startDate = moment();
      endDate = moment().add(60, "days");
      break;
    case "next-30-days":
      startDate = moment();
      endDate = moment().add(30, "days");
      break;
    case "last-90-days":
      startDate = moment().add(-90, "days");
      endDate = moment();
      break;
    case "last-60-days":
      startDate = moment().add(-60, "days");
      endDate = moment();
      break;
    case "last-30-days":
      startDate = moment().add(-30, "days");
      endDate = moment();
      break;
    case "last-year":
      startDate = moment().add(-1, "year").startOf("year");
      endDate = moment().add(-1, "year").endOf("year");
      break;
    case "all-time":
      startDate = undefined;
      endDate = undefined;
      break;
    default:
      selectedPeriod satisfies never | undefined;
  }

  return {
    startDate: startDate ? startDate.format("YYYY-MM-DD").toString() : undefined,
    endDate: endDate ? endDate.format("YYYY-MM-DD").toString() : undefined
  };
};

export const makeFullName = (firstName = "", lastName = ""): string => `${firstName} ${lastName}`;
