import React from "react";
import type { TextComponentProps } from "DLUI/text/text";
import Text from "DLUI/text/text";
import { useTranslation } from "react-i18next";
import { Link } from "DLUI/link";
import ColorsEnum from "../../../utils/colorsEnum";
import AppStrings from "../../../locale/keys";
import type { LinkURLsEnum } from "DLUI/link/linkURLsEnum";
import type { ArticleIdsEnum } from "DLUI/screen/helpPanel/types";
import type { LinkUnderLineType } from "DLUI/link/link";
import { openIntercomArticle } from "DLUI/screen/helpPanel/utils";
import { useAnalyticsService } from "@/hooks/useAnalyticsService";

interface LearnMoreTextProps extends TextComponentProps {
  value?: string;
  articleId?: ArticleIdsEnum;
  href?: LinkURLsEnum;
  hrefUrl?: string;
  underline?: LinkUnderLineType;
  textColor?: ColorsEnum;
  displayLabel?: string;
  fontSize?: number;
}

export const LearnMoreText: React.FC<LearnMoreTextProps> = ({
  value,
  articleId,
  href,
  hrefUrl,
  underline = "hover",
  hoverColor = "lightBlue",
  textColor = ColorsEnum.BrightBlue,
  displayLabel = AppStrings.Common.LearnMore,
  fontSize,
  replaceObject,
  marginLeft,
  ...rest
}: LearnMoreTextProps) => {
  const { t } = useTranslation();

  const { dispatchAnalytics } = useAnalyticsService();

  const clickHandler = () => {
    if (!articleId) return;

    dispatchAnalytics("help_panel_article_click", { articleId });

    openIntercomArticle(articleId);
  };

  return (
    <Text {...rest} fullWidth fontSize={fontSize}>
      <span>
        {value && t(value, replaceObject)}
        <Link
          underline={underline}
          hoverColor={hoverColor}
          type={"tab"}
          hrefEnum={href}
          hrefUrl={hrefUrl}
          textColor={textColor}
          onClick={clickHandler}
          fontSize={fontSize}
          marginLeft={marginLeft ?? 5}
        >
          {displayLabel && t(displayLabel) + "."}
        </Link>
      </span>
    </Text>
  );
};
