import React from "react";
import { View } from "DLUI/view";
import Skeleton from "@material-ui/lab/Skeleton";

interface ComponentProps {
  SkeletonItem: React.FC<any>;
  SkeletonHeader?: React.FC<any>;
  numOfSkeletonItems?: number;
  fullWidth?: boolean;
}

const NUM_OF_SKELETON_ITEMS = 20;

const GridLoadingSkeleton: React.FC<ComponentProps> = ({
  SkeletonItem,
  numOfSkeletonItems,
  fullWidth
}: ComponentProps) => {
  const createItemsArray = (): any[] => {
    const itemsArray: any = [];
    const numOfItems = numOfSkeletonItems ? numOfSkeletonItems : NUM_OF_SKELETON_ITEMS;

    for (let i = 0; i < numOfItems; i++) {
      itemsArray.push(
        <div
          style={{
            width: fullWidth ? "100%" : "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
          key={"SKI" + i}
        >
          <SkeletonItem />
        </div>
      );
    }
    return itemsArray;
  };
  const skeletonItems = createItemsArray();

  return (
    <View
      alignItems={"center"}
      width={"100%"}
      marginBottom={20}
      paddingRight={20}
      paddingLeft={20}
      flexDirection={"row"}
    >
      <View width={"100%"} backgroundColor={"white"} alignItems={"center"} borderRadius={10} maxWidth={1514} noWrap>
        <Skeleton style={{ marginTop: 20 }} width={"200px"} variant="text" />
        <Skeleton style={{ marginTop: 10 }} width={"200px"} variant="text" />
        <Skeleton style={{ marginTop: 10 }} width={"200px"} variant="text" />
        <View alignItems={"center"} justifyContent={"center"} marginTop={20} noWrap>
          {skeletonItems}
        </View>
      </View>
    </View>
  );
};

export default GridLoadingSkeleton;
