import type { GetAllTransfersQuery, TransferDto } from "@doorloop/dto";
import { ServerRoutes } from "@doorloop/dto";
import { RestApiBase } from "./restApiBase";

export class TransferApi extends RestApiBase<TransferDto, GetAllTransfersQuery> {
  constructor() {
    super(ServerRoutes.TRANSFERS);
    this.restRoute = ServerRoutes.TRANSFERS;
  }
}

export const transferApi = new TransferApi();
