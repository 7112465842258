import { isProductionEnv } from "@/utils/environmentHelper";
import type { PropsWithChildren } from "react";
import { EventHelmet, type EventHelmetProps } from "./eventHelmet";

export const ProdHelmet = ({ children, ...rest }: PropsWithChildren<EventHelmetProps>) => {
  if (!isProductionEnv) {
    return null;
  }

  return <EventHelmet {...rest}>{children}</EventHelmet>;
};
