import { useUserType } from "../../../hooks/useUserType";
import { HOAUserMainMenuLayers, MainMenuFooterLayers, MainMenuLayers } from "DLUI/drawer/items/mainMenuItems";
import {
  SubMenuHOAUserRentalsLayers,
  SubMenuLeasingLayers,
  SubMenuRentalsLayers
} from "DLUI/drawer/items/rentalsMenuItems";
import { SubMenuCalendarLayers } from "DLUI/drawer/items/calendarMenuItems";
import { SubMenuHOAUserPeopleLayers, SubMenuPeopleLayers } from "DLUI/drawer/items/peopleMenuItems";
import { SubMenuCommunicationsLayers } from "DLUI/drawer/items/communicationsMenuItems";
import { SubMenuAccountingLayers } from "DLUI/drawer/items/accountingMenuItems";
import { SubMenuHOAUserTasksLayers, SubMenuTasksLayers } from "DLUI/drawer/items/tasksMenuItems";
import type { NavConfigProps } from "DLUI/drawer/types";

export const navigationMenuLayers = {
  MainMenuLayers,
  MainMenuFooterLayers,
  SubMenuCalendarLayers,
  SubMenuRentalsLayers,
  SubMenuLeasingLayers,
  SubMenuTasksLayers,
  SubMenuAccountingLayers,
  SubMenuPeopleLayers,
  SubMenuCommunicationsLayers
} satisfies Record<string, NavConfigProps>;

export const HOAUserNavigationMenuLayers = {
  MainMenuLayers: HOAUserMainMenuLayers,
  MainMenuFooterLayers,
  SubMenuRentalsLayers: SubMenuHOAUserRentalsLayers,
  SubMenuTasksLayers: SubMenuHOAUserTasksLayers,
  SubMenuAccountingLayers,
  SubMenuPeopleLayers: SubMenuHOAUserPeopleLayers,
  SubMenuCommunicationsLayers
} satisfies Record<string, NavConfigProps>;

export const useNavigationMenuLayers = () => {
  const { isHOAUser } = useUserType();
  return isHOAUser ? HOAUserNavigationMenuLayers : navigationMenuLayers;
};
