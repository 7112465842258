import moment from "moment";

import { DateFormats, DEFAULT_TIMEZONE } from "@doorloop/dto";

export function filterYear(year?: number) {
  return {
    filter_date_from: year ? moment([year]).startOf("year").format(DateFormats.ISO_DATE_SERVER_FORMAT) : undefined,
    filter_date_to: year ? moment([year]).endOf("year").format(DateFormats.ISO_DATE_SERVER_FORMAT) : undefined
  };
}

export const formatDate = (dateStr: string, format = DateFormats.ISO_DATE_SERVER_FORMAT) =>
  moment(dateStr).format(format);

export const getISOServerFormattedDateWithTimezone = (timezone: string) => {
  const dateWithTimezone = moment().tz(timezone || DEFAULT_TIMEZONE);

  return dateWithTimezone.format(DateFormats.ISO_DATE_SERVER_FORMAT);
};

interface CheckDaysReturnType {
  daysCount: number;
  isPastDate: boolean;
  noDate?: boolean;
}

export const checkDaysLeft = (date?: Date | string): CheckDaysReturnType => {
  if (!date) {
    return { daysCount: 0, isPastDate: false, noDate: true };
  }

  const daysCount = moment(date).startOf("day").diff(moment().startOf("day"), "days");
  const isPastDate = daysCount < 0;

  return { daysCount, isPastDate };
};
