import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";
import { lazy } from "react";
import { RecurringTransactionType } from "@doorloop/dto";
import Routes from "./routes";
import { DepositDetailReportRouter } from "components/appRouter/reports/depositDetailReportRouter";

const ReportsScreen = lazy(() => import("screens/reports/reportsScreen/reportsScreen"));
const ArAgingDetailReportRouter = lazy(() => import("./reports/arAgingDetailReportRouter"));
const ApAgingDetailReportRouter = lazy(() => import("./reports/apAgingDetailReportRouter"));
const TransactionsListReportRouter = lazy(() => import("./reports/transactionsListReportRouter"));
const TransactionsReportRouter = lazy(() => import("./reports/transactionsReportRouter"));
const AccountRegisterReportRouter = lazy(() => import("./reports/accountRegisterReportRouter"));
const OwnerStatementReportRouter = lazy(() => import("./reports/ownerStatementReportRouter"));
const ProfitAndLossDetailReportRouter = lazy(() => import("./reports/profitAndLossDetailReportRouter"));
const BalanceSheetDetailReportRouter = lazy(() => import("./reports/balanceSheetDetailReportRouter"));
const RecurringTransactionsDetailReport = lazy(
  () => import("screens/reports/recurringTransactionsDetailReport/recurringTransactionsDetailReport")
);
const RentRollSummaryReportByBedsAndBaths = lazy(
  () => import("screens/reports/rentRollSummary/rentRollSummaryReportByBedsAndBaths")
);
const RentRollSummaryReportByProperty = lazy(
  () => import("screens/reports/rentRollSummaryByProperty/rentRollSummaryReportByProperty")
);
const ApAgingDetailByAccountReport = lazy(
  () => import("screens/reports/agingDetailByAccount/accountPayable/apAgingDetailByAccountsReport")
);
const ApAgingSummaryReport = lazy(
  () => import("screens/reports/agingSummaryReport/accountPayable/apAgingSummaryReport")
);
const ArAgingDetailByAccountReport = lazy(
  () => import("screens/reports/agingDetailByAccount/accountReceivable/arAgingDetailByAccountReport")
);
const ArAgingSummaryReport = lazy(
  () => import("screens/reports/agingSummaryReport/accountReceivable/arAgingSummaryReport")
);
const PropertyBankAccountsReport = lazy(
  () => import("screens/reports/propertyBankAccountsReport/propertyBankAccountsReport")
);
const ProfitAndLossReport = lazy(() => import("screens/reports/profitAndLossReport/profitAndLossReport"));
const BudgetVsActualReportScreen = lazy(() => import("screens/reports/budgetVsActual/budgetVsActualReportScreen"));
const BankBalanceByPropertyReport = lazy(
  () => import("screens/reports/bankBalanceByProperty/bankBalanceByPropertyReport")
);
const TrialBalanceReport = lazy(() => import("screens/reports/trialBalanceReport/trialBalanceReport"));
const LeasingReport = lazy(() => import("screens/reports/leasingReport/leasingReport"));
const BalanceSheetReport = lazy(() => import("screens/reports/balanceSheet/balanceSheetReport"));
const CashFlowStatementReport = lazy(() => import("screens/reports/cashFlowStatement/cashFlowStatement"));
const RentRollReport = lazy(() => import("screens/reports/rentRoll/rentRollReport"));
const PropertyReserveFundsReport = lazy(
  () => import("screens/reports/propertyReserveFundsReport/propertyReserveFundsReport")
);
const PropertyOwnershipReport = lazy(() => import("screens/reports/propertyOwnershipReport/propertyOwnershipReport"));
const Owners1099Report = lazy(() => import("screens/reports/owners1099Report/owners1099Report"));
const Vendors1099Report = lazy(() => import("screens/reports/vendors1099Report/vendors1099Report"));
const BillAndExpensesReport = lazy(() => import("screens/reports/billAndExpensesReport/billAndExpensesReport"));
const ChargesAndPaymentReport = lazy(() => import("screens/reports/chargesAndPaymentReport/chargesAndPaymentReport"));
const RecurringTransactionsReport = lazy(
  () => import("screens/reports/recurringTransactionsReport/recurringTransactionsReport")
);
const LeaseInsuranceSummaryReport = lazy(
  () => import("screens/reports/leaseInsurance/insuranceSummaryReport/insuranceSummaryReport")
);
const LeaseInsuranceDetailsReport = lazy(
  () => import("screens/reports/leaseInsurance/insuranceDetailsReport/insuranceDetailsReport")
);
const RentalApplicationsReport = lazy(
  () => import("screens/reports/persons/rentalApplicationsReport/rentalApplicationsReport")
);
const TenantPetsReport = lazy(() => import("screens/reports/tenantPets/tenantPets"));
const LeaseLateFeesReport = lazy(() => import("screens/reports/leaseLateFeesReport/leaseLateFeesReport"));
const ManagementFeesReport = lazy(() => import("screens/reports/managementFeesReport/managementFeesReport"));
const LeaseAccountStatementsReport = lazy(
  () => import("screens/reports/leaseAccountStatementsReport/leaseAccountStatementsReport")
);
const TenantVehiclesReport = lazy(() => import("screens/reports/tenantVehicles/tenantVehicles"));
const VendorsLedgerReport = lazy(
  () => import("screens/reports/persons/vendorsReport/vendorsLedger/vendorsLedgerReport")
);
const WorkOrdersReport = lazy(() => import("screens/reports/workOrdersReport/workOrdersReport"));
const TasksReport = lazy(() => import("screens/reports/tasksReport/tasksReport"));
const OwnersReport = lazy(() => import("screens/reports/persons/ownersReport/ownersReport"));
const VendorsReport = lazy(() => import("screens/reports/persons/vendorsReport/vendorsReport"));
const TenantsReport = lazy(() => import("screens/reports/persons/tenantsReport/tenantsReport"));
const ProspectsReport = lazy(() => import("screens/reports/persons/prospectsReport/prospectsReport"));

const EmptyRecurringTransactionsReport = () => <RecurringTransactionsReport />;
const RecurringTasks = () => <RecurringTransactionsReport transactionsType={RecurringTransactionType.TASK} />;
const RecurringWorkOrders = () => (
  <RecurringTransactionsReport transactionsType={RecurringTransactionType.TASK} workOrders />
);
const RecurringExpenses = () => <RecurringTransactionsReport transactionsType={RecurringTransactionType.EXPENSE} />;
const RecurringVendorBills = () => (
  <RecurringTransactionsReport transactionsType={RecurringTransactionType.VENDOR_BILL} />
);

const ReportsRouter = () => (
  <PageRouter>
    <LazyRoute path={Routes.TRANSACTION_DETAILS_REPORT} render={TransactionsReportRouter} />
    <LazyRoute path={Routes.PROFIT_AND_LOSS_DETAIL_REPORT} render={ProfitAndLossDetailReportRouter} />
    <LazyRoute path={Routes.DEPOSIT_DETAIL_REPORT} render={DepositDetailReportRouter} />
    <LazyRoute path={Routes.BALANCE_SHEET_DETAIL_REPORT} render={BalanceSheetDetailReportRouter} />
    <LazyRoute
      path={Routes.PROPERTY_BANK_ACCOUNTS_REPORT_EDIT_BANK_ACCOUNT}
      render={PropertyBankAccountsReport}
      exact
    />
    <LazyRoute path={Routes.PROPERTY_BANK_ACCOUNTS_REPORT} render={PropertyBankAccountsReport} />
    <LazyRoute path={Routes.PROFIT_AND_LOSS_REPORT} render={ProfitAndLossReport} />
    <LazyRoute path={Routes.BUDGET_VS_ACTUAL_REPORT} render={BudgetVsActualReportScreen} />
    <LazyRoute path={Routes.BALANCE_SHEET_REPORT} render={BalanceSheetReport} />
    <LazyRoute path={Routes.CASH_FLOW_STATEMENT_REPORT} render={CashFlowStatementReport} />
    <LazyRoute path={Routes.RENT_ROLL_REPORT} render={RentRollReport} />
    <LazyRoute path={Routes.PROPERTY_RESERVE_FUNDS_REPORT_EDIT_SETTINGS} render={PropertyReserveFundsReport} />
    <LazyRoute path={Routes.PROPERTY_RESERVE_FUNDS_REPORT} render={PropertyReserveFundsReport} />

    <LazyRoute path={Routes.REPORTS_RENT_ROLL_SUMMARY_BY_BEDS_AND_BATHS} render={RentRollSummaryReportByBedsAndBaths} />
    <LazyRoute path={Routes.REPORTS_RENT_ROLL_SUMMARY_BY_PROPERTY} render={RentRollSummaryReportByProperty} />
    <LazyRoute path={Routes.BANK_BALANCE_BY_PROPERTY} render={BankBalanceByPropertyReport} />
    <LazyRoute path={Routes.TENANTS_REPORT} render={TenantsReport} />
    <LazyRoute path={Routes.TENANT_VEHICLES_REPORT} render={TenantVehiclesReport} />
    <LazyRoute path={Routes.TENANTS_PETS_REPORT} render={TenantPetsReport} />
    <LazyRoute path={Routes.RENTAL_APPLICATIONS_REPORT} render={RentalApplicationsReport} />
    <LazyRoute path={Routes.PROSPECTS_REPORT} render={ProspectsReport} />
    <LazyRoute
      exact
      path={Routes.LEASE_INSURANCE_DETAILS_REPORT_EDIT_INSURANCE_POLICY}
      render={LeaseInsuranceDetailsReport}
    />
    <LazyRoute path={Routes.LEASE_LATE_FEES_REPORT} render={LeaseLateFeesReport} />
    <LazyRoute path={Routes.MANAGEMENT_FEES_REPORT} render={ManagementFeesReport} />
    <LazyRoute path={Routes.LEASE_ACCOUNT_STATEMENTS_REPORT} render={LeaseAccountStatementsReport} />
    <LazyRoute path={Routes.LEASE_INSURANCE_SUMMARY_REPORT} render={LeaseInsuranceSummaryReport} />
    <LazyRoute path={Routes.LEASE_INSURANCE_DETAILS_REPORT} render={LeaseInsuranceDetailsReport} />
    <LazyRoute path={Routes.LEASING_DRAFT_LEASES_REPORT} render={LeasingReport} />
    <LazyRoute path={Routes.LEASING_LEASES_REPORT} render={LeasingReport} />

    <LazyRoute path={Routes.VENDORS_REPORT} render={VendorsReport} />
    <LazyRoute path={Routes.VENDORS_LEDGER_REPORT_EDIT_BILL_PAYMENT} render={VendorsLedgerReport} exact />
    <LazyRoute path={Routes.VENDORS_LEDGER_REPORT_EDIT_BILL} render={VendorsLedgerReport} exact />
    <LazyRoute path={Routes.VENDORS_LEDGER_REPORT_EDIT_CREDIT} render={VendorsLedgerReport} exact />
    <LazyRoute path={Routes.VENDORS_LEDGER_REPORT_EDIT_EXPENSE} render={VendorsLedgerReport} exact />
    <LazyRoute path={Routes.VENDORS_LEDGER_REPORT} render={VendorsLedgerReport} />
    <LazyRoute exact path={Routes.WORK_ORDERS_REPORT_EDIT_USER} render={WorkOrdersReport} />
    <LazyRoute path={Routes.WORK_ORDERS_REPORT} render={WorkOrdersReport} />

    <LazyRoute path={Routes.TASKS_REPORT} render={TasksReport} />

    <LazyRoute path={Routes.TRANSACTIONS_LIST_REPORT} render={TransactionsListReportRouter} />
    <LazyRoute path={Routes.OWNER_STATEMENT_REPORT} render={OwnerStatementReportRouter} />
    <LazyRoute path={Routes.ACCOUNT_REGISTER_REPORT} render={AccountRegisterReportRouter} />

    <LazyRoute path={Routes.TRIAL_BALANCE_REPORT} render={TrialBalanceReport} />

    <LazyRoute path={Routes.OWNERS_REPORT} render={OwnersReport} />

    <LazyRoute path={Routes.ACCOUNTS_RECEIVABLE_AGING_DETAIL} render={ArAgingDetailReportRouter} />
    <LazyRoute path={Routes.ACCOUNTS_PAYABLE_AGING_DETAIL} render={ApAgingDetailReportRouter} />

    <LazyRoute
      exact
      path={Routes.ACCOUNTS_PAYABLE_AGING_DETAIL_BY_ACCOUNT_EDIT_VENDOR_CREDIT}
      render={ApAgingDetailByAccountReport}
    />

    <LazyRoute
      exact
      path={Routes.ACCOUNTS_PAYABLE_AGING_DETAIL_BY_ACCOUNT_EDIT_BILL_PAYMENT}
      render={ApAgingDetailByAccountReport}
    />

    <LazyRoute
      exact
      path={Routes.ACCOUNTS_PAYABLE_AGING_DETAIL_BY_ACCOUNT_EDIT_BILL}
      render={ApAgingDetailByAccountReport}
    />
    <LazyRoute path={Routes.ACCOUNTS_PAYABLE_AGING_DETAIL_BY_ACCOUNT} render={ApAgingDetailByAccountReport} />

    <LazyRoute
      exact
      path={Routes.ACCOUNTS_RECEIVABLE_AGING_DETAIL_BY_ACCOUNT_EDIT_LEASE_CHARGE}
      render={ArAgingDetailByAccountReport}
    />
    <LazyRoute
      exact
      path={Routes.ACCOUNTS_RECEIVABLE_AGING_DETAIL_BY_ACCOUNT_EDIT_PAYMENT}
      render={ArAgingDetailByAccountReport}
    />
    <LazyRoute
      exact
      path={Routes.ACCOUNTS_RECEIVABLE_AGING_DETAIL_BY_ACCOUNT_EDIT_LEASE_CREDIT}
      render={ArAgingDetailByAccountReport}
    />

    <LazyRoute path={Routes.ACCOUNTS_RECEIVABLE_AGING_DETAIL_BY_ACCOUNT} render={ArAgingDetailByAccountReport} />

    <LazyRoute path={Routes.ACCOUNTS_RECEIVABLE_AGING_SUMMARY} render={ArAgingSummaryReport} />
    <LazyRoute path={Routes.ACCOUNTS_PAYABLE_AGING_SUMMARY} render={ApAgingSummaryReport} />
    <LazyRoute
      path={[
        Routes.RECURRING_TRANSACTIONS_DETAIL_REPORT_EDIT_RECURRING_RENT,
        Routes.RECURRING_TRANSACTIONS_DETAIL_REPORT_EDIT_RECURRING_CREDIT,
        Routes.RECURRING_TRANSACTIONS_DETAIL_REPORT_EDIT_RECURRING_CHARGE,
        Routes.RECURRING_TRANSACTIONS_DETAIL_REPORT_EDIT_RECURRING_EXPENSE,
        Routes.RECURRING_TRANSACTIONS_DETAIL_REPORT_EDIT_RECURRING_BILL
      ]}
      render={RecurringTransactionsDetailReport}
      exact
    />
    <LazyRoute
      path={[Routes.RECURRING_VENDOR_BILLS_REPORT_EDIT_BILL, Routes.RECURRING_VENDOR_BILLS_REPORT]}
      render={RecurringVendorBills}
    />
    <LazyRoute path={Routes.RECURRING_TRANSACTIONS_DETAIL_REPORT} render={RecurringTransactionsDetailReport} exact />

    <LazyRoute
      path={Routes.RECURRING_TRANSACTIONS_DETAIL_REPORT_GLOBAL_NEW}
      render={RecurringTransactionsDetailReport}
    />

    <LazyRoute
      path={[
        Routes.RECURRING_TASKS_REPORT_EDIT_TASK,
        Routes.RECURRING_TASKS_REPORT_EDIT_WORK_ORDER,
        Routes.RECURRING_TASKS_REPORT
      ]}
      render={RecurringTasks}
    />
    <LazyRoute
      path={[Routes.RECURRING_WORK_ORDERS_REPORT_EDIT_WORK_ORDER, Routes.RECURRING_WORK_ORDERS_REPORT]}
      render={RecurringWorkOrders}
    />
    <LazyRoute
      path={[Routes.RECURRING_EXPENSES_REPORT_EDIT_EXPENSE, Routes.RECURRING_EXPENSES_REPORT]}
      render={RecurringExpenses}
    />

    <LazyRoute
      path={Routes.RECURRING_TRANSACTIONS_REPORT_EDIT_RECURRING_RENT}
      render={EmptyRecurringTransactionsReport}
      exact
    />
    <LazyRoute
      path={Routes.RECURRING_TRANSACTIONS_REPORT_EDIT_RECURRING_CHARGE}
      render={EmptyRecurringTransactionsReport}
      exact
    />
    <LazyRoute
      path={Routes.RECURRING_TRANSACTIONS_REPORT_EDIT_RECURRING_GENERAL_ENTRY}
      render={EmptyRecurringTransactionsReport}
      exact
    />
    <LazyRoute
      path={Routes.RECURRING_TRANSACTIONS_REPORT_EDIT_RECURRING_CREDIT}
      render={EmptyRecurringTransactionsReport}
      exact
    />
    <LazyRoute
      path={Routes.RECURRING_TRANSACTIONS_REPORT_EDIT_RECURRING_PAYMENT}
      render={EmptyRecurringTransactionsReport}
      exact
    />
    <LazyRoute
      path={Routes.RECURRING_TRANSACTIONS_REPORT_EDIT_RECURRING_EXPENSE}
      render={EmptyRecurringTransactionsReport}
      exact
    />
    <LazyRoute
      path={Routes.RECURRING_TRANSACTIONS_REPORT_EDIT_RECURRING_BILL}
      render={EmptyRecurringTransactionsReport}
      exact
    />
    <LazyRoute path={Routes.RECURRING_TRANSACTIONS_REPORT} render={EmptyRecurringTransactionsReport} />
    <LazyRoute path={Routes.PROPERTY_OWNERSHIP_REPORT} render={PropertyOwnershipReport} />
    <LazyRoute path={Routes.OWNERS_1099_REPORT} render={Owners1099Report} />
    <LazyRoute path={Routes.VENDORS_1099_REPORT} render={Vendors1099Report} />

    <LazyRoute
      path={[`${Routes.BILLS_AND_EXPENSES}/*/edit/:transactionId`, Routes.BILLS_AND_EXPENSES]}
      render={BillAndExpensesReport}
    />

    <LazyRoute
      path={[`${Routes.CHARGES_AND_PAYMENT}/*/edit/:transactionId`, Routes.CHARGES_AND_PAYMENT]}
      render={ChargesAndPaymentReport}
    />

    <LazyRoute path={Routes.REPORTS} render={ReportsScreen} />
  </PageRouter>
);

export default ReportsRouter;
