import MomentUtils from "@date-io/moment";
import type { GetTransactionsListReportQuery, ObjectPermission, PropertyDto } from "@doorloop/dto";
import {
  AllocationRounding,
  AllocationStrategyWithCustom,
  BulkChargeAllocationDto,
  BulkCreditAllocationDto,
  createValidator
} from "@doorloop/dto";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DialogSearchPanel } from "DLUI/dialogs";
import { BulkTransactionExecution } from "DLUI/dialogs/transactions/bulkExecutionViews/bulkTransactionExecution";
import { BulkChargeOrCreditFormView } from "DLUI/dialogs/transactions/formViews/bulkChargeOrCredit";
import { getCurrentSort } from "DLUI/infiniteList/sortManager";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { View } from "DLUI/view";
import type { FormikErrors, FormikHelpers, FormikProps } from "formik";
import { Formik } from "formik";
import { useShakeEffect } from "hooks/useShakeEffect";
import AppStrings from "locale/keys";
import React, { useMemo, useState } from "react";
import type { Path } from "react-hook-form";
import { SelectableListContainer } from "screens/esignatures/newSignatureRequest/selectionDialog";
import PropertiesList from "screens/properties/propertiesList/propertiesList";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { NavigationManager } from "utils/navigation";
import DialogFrame, { getDialogFrameDimension } from "../components/dialogFrame";
import type { BulkTransactionDto } from "./formViews/allocationUtil";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
  dialogTitleActions?: React.ReactNode;
  dialogFrameWidth?: number;
  dialogFrameHeight?: number;
  type: "charge" | "credit";
  permission?: ObjectPermission;
  customDateLabel?: string;
  helpObject?: HelpPanelProps;
}

const formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

const DialogViews = {
  FORM: 0,
  PROPERTY_SELECTION: 1,
  BULK_EXECUTION: 2
} as const;

type DialogView = (typeof DialogViews)[keyof typeof DialogViews];

const StickyHeaderId = "bulkCCPropertyList";

const BulkTransaction: React.FC<ComponentProps> = ({
  onBackdropClick,
  dialogTitle,
  dialogTitleActions,
  type,
  dialogFrameHeight,
  dialogFrameWidth,
  permission,
  helpObject
}: ComponentProps) => {
  const dialogWidth = getDialogFrameDimension("width", dialogFrameWidth || 1000);
  const clearance: AnyPermissionClearance | undefined = permission
    ? {
        permission,
        field: "create"
      }
    : undefined;
  const [dialogView, setDialogView] = useState<DialogView>(DialogViews.PROPERTY_SELECTION);
  const [selectedProperty, setSelectedProperty] = useState<PropertyDto | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [propertiesListFilterObj, setSelectLeaseFilterObj] = useState({
    filter_text: ""
  });
  const { ShakeEffectWrapper, runShakeEffect } = useShakeEffect();
  const { sort_by, sort_descending } = getCurrentSort();

  const onBulkExecutionFinished = (batchId: string, shouldRefresh?: boolean) => {
    if (shouldRefresh) {
      // navigate to the transactions list with the given batch
      const query: GetTransactionsListReportQuery = { filter_batch: batchId };
      NavigationManager.runTransactionsListReport(query as Record<string, string>);
    }
  };

  const onFormikSubmit = (values: BulkTransactionDto, formikHelpers: FormikHelpers<BulkTransactionDto>) => {
    setError(undefined);
    if (dialogView !== DialogViews.FORM) return;

    if (!isFormValid(values)) {
      formikHelpers.setSubmitting(false);
      runShakeEffect();
      return;
    }
    if (!values.transactions.filter((t) => t.lease).some((t) => t.isSelected)) {
      setError(AppStrings.Leases.LeaseCharge.NoUnitsSelected);
      runShakeEffect();
      formikHelpers.setSubmitting(false);
      return;
    }
    formikHelpers.setSubmitting(false);
    setDialogView(DialogViews.BULK_EXECUTION);
  };

  const didChangeSearchQuery = (value: string) => {
    setSelectLeaseFilterObj({
      filter_text: value
    });
  };

  const renderActionPanelButtons = (formik: FormikProps<BulkTransactionDto>) => () => {
    if (dialogView !== DialogViews.FORM) return <></>;

    return (
      <FormActionButtons
        clearance={clearance}
        propsSubButton={{
          actionText: dialogView == DialogViews.FORM ? AppStrings.Common.Cancel : AppStrings.Common.GoBack,
          onClick: onBackdropClick
        }}
        propsMainButton={{ type: "cta", Wrapper: ShakeEffectWrapper, props: { onClick: formik.submitForm } }}
      />
    );
  };

  const FormView = useMemo(
    () => (
      <BulkChargeOrCreditFormView
        selectedProperty={selectedProperty}
        didModifyTransactions={() => error !== undefined && setError(undefined)}
        didPressSelectProperty={() => setDialogView(DialogViews.PROPERTY_SELECTION)}
        error={error}
        type={type}
      />
    ),
    [selectedProperty, error]
  );

  const PropertySelectionView = useMemo(
    () => (
      <View style={{ padding: 20, boxSizing: "border-box" }}>
        <DialogSearchPanel onChange={didChangeSearchQuery} borderRadius={30} marginTop={0} marginBottom={10} />
        <View id={StickyHeaderId}>
          <PropertiesList
            stickyHeaderId={StickyHeaderId}
            onItemPress={(propertyId, propertyDto) => {
              setSelectedProperty(propertyDto);
              setDialogView(DialogViews.FORM);
            }}
            filterObject={propertiesListFilterObj}
            scrollableTarget={SelectableListContainer}
            removeDefaultBottomPadding
          />
        </View>
      </View>
    ),
    [propertiesListFilterObj, sort_by, sort_descending]
  );

  const BulkExecutionView = useMemo(() => <BulkTransactionExecution onClose={onBulkExecutionFinished} />, []);

  const viewMap: Partial<Record<DialogView, { render: JSX.Element; title: string }>> = {
    [DialogViews.FORM]: { render: FormView, title: dialogTitle },
    [DialogViews.PROPERTY_SELECTION]: {
      render: PropertySelectionView,
      title: AppStrings.Leases.LeaseCharge.SelectProperty
    }
    // We don't have a dedicated view for bulk execution because we completely replace the dialog with the execution view
  } as const;

  const renderView = ({ index }: { index: number }): JSX.Element => viewMap?.[index]?.render ?? <></>;
  const currentTitle = viewMap?.[dialogView]?.title;

  const initialValues = useMemo((): BulkTransactionDto => {
    if (type === "charge") {
      return new BulkChargeAllocationDto({
        account: "",
        property: selectedProperty?.id ?? "",
        transactions: [],
        strategy: AllocationStrategyWithCustom.MANUAL,
        dueDate: "",
        rounding: AllocationRounding.NEAREST_HUNDREDTH,
        total: 0,
        type
      });
    }
    if (type === "credit") {
      return new BulkCreditAllocationDto({
        account: "",
        property: selectedProperty?.id ?? "",
        transactions: [],
        strategy: AllocationStrategyWithCustom.MANUAL,
        issueDate: "",
        rounding: AllocationRounding.NEAREST_HUNDREDTH,
        total: 0,
        type
      });
    }
    // this should never happen
    throw new Error("Invalid type for bulk transaction: " + type);
  }, []);

  function isFormValid(values: BulkTransactionDto): FormikErrors<BulkTransactionDto> {
    const dto = type === "charge" ? BulkChargeAllocationDto : BulkCreditAllocationDto;

    const errors = createValidator<BulkTransactionDto>(dto)(values);

    // pick which errors matter to us
    const errorsThatMatter: Array<Path<BulkTransactionDto>> =
      type === "charge" ? ["account", "property", "dueDate"] : ["account", "property", "issueDate"];

    if (errorsThatMatter.some((error) => errors[error])) {
      return errors;
    }

    return Object.values(errors.transactions || {}).some((chargeErrors: unknown) => {
      const _chargeErrors = chargeErrors as FormikErrors<BulkTransactionDto["transactions"][0]>;
      return _chargeErrors?.memo || _chargeErrors?.lines;
    })
      ? errors
      : {};
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onFormikSubmit} enableReinitialize validate={isFormValid}>
      {(formik) => (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          {dialogView === DialogViews.BULK_EXECUTION ? (
            // The view gets completely replaced when we switch to the bulk execution view
            BulkExecutionView
          ) : (
            <DialogFrame
              onCloseButtonClick={onBackdropClick}
              title={currentTitle}
              titleActions={dialogTitleActions}
              width={dialogWidth}
              height={dialogFrameHeight || 800}
              renderView={renderView}
              numViews={Object.keys(viewMap).length}
              activeView={dialogView}
              RenderActionPanelButtons={renderActionPanelButtons(formik)}
              frameType={"sectionTitleFrame"}
              helpPanel={dialogView === 0 ? helpObject : undefined}
            />
          )}
        </MuiPickersUtilsProvider>
      )}
    </Formik>
  );
};

export default BulkTransaction;
