import type {
  AccountRegisterReportItem,
  ApAgingDetailByAccountReportItem,
  ApAgingDetailReportItem,
  ApAgingSummaryReportItem,
  ArAgingDetailByAccountReportItem,
  ArAgingDetailReportItem,
  ArAgingSummaryReportItem,
  BankBalanceByPropretyReportItem,
  CalculateManagementFeesReportItemDto,
  CashFlowStatementItem,
  DepositDetailReportItem,
  GetAccountRegisterReportQuery,
  GetAllBaseQueryResponse,
  GetAllLeaseTenantsQuery,
  GetApAgingReportQuery,
  GetArAgingReportQuery,
  GetBankBalanceByPropertyReport,
  GetCalculateManagementFeesReportQuery,
  GetCashFlowStatementReportQuery,
  GetDepositDetailReportQuery,
  GetLeaseAccountsReceivableReportQuery,
  GetLeaseDepositsHeldByAccountReportQuery,
  GetOwnerAvailableFundsReportQuery,
  GetOwners1099ReportQuery,
  GetOwnerStatementReportQuery,
  GetOwnerStatementReportRequestDto,
  GetProfitAndLossReportQuery,
  GetPropertyAccountsFundsReportQuery,
  GetPropertyAvailableFundsReportQuery,
  GetRecurringTransactionDetailReportQuery,
  GetRentRollReportQuery,
  GetRentRollSummaryReportQuery,
  GetTransactionReportQuery,
  GetTransactionsListReportQuery,
  GetTrialBalanceReportQuery,
  GetVendors1099ReportQuery,
  GetVendorTransactionsReportQuery,
  LeaseAccountsReceivableReportItem,
  LeaseDepositsHeldByAccountReportItem,
  OwnerAvailableFundsReportItem,
  Owners1099ReportItem,
  OwnerStatementItemDto,
  OwnerStatementReportBulkResponseDto,
  OwnerTransactionReportItem,
  PropertyAccountsFundsReportItem,
  PropertyAvailableFundsReportItem,
  RecurringTransactionsDetailReportItem,
  RentRollReportItem,
  RentRollSummaryByBedsAndBathsReportItem,
  RentRollSummaryByPropertyReportItem,
  TenantPetsReportItemDto,
  TenantVehiclesReportItemDto,
  TransactionReportItem,
  TransactionsListReportItem,
  TreeListItemAccount,
  TrialBalanceReportItem,
  Vendors1099ReportItem,
  VendorTransactionReportItem
} from "@doorloop/dto";
import { RentRollSummaryReportItem, ServerRoutes } from "@doorloop/dto";
import { vendorsApi } from "./vendorsApi";
import { accountsApi } from "./accounts";
import { apiHelper, LONG_TIMEOUT } from "./apiHelper";
import type { ApiResult } from "./apiResult";
import { leasesApi } from "./leasesApi";
import { ownersApi } from "./ownersApi";
import { propertiesApi } from "./propertiesApi";
import { RestApiBase } from "./restApiBase";
import { RestApiBaseWithDictionary } from "./restApiBaseWithDictionary";
import { tenantsApi } from "./tenantsApi";
import { unitsApi } from "./unitsApi";
import { usersApi } from "api/usersApi";
import moment from "moment";
import type { CancelToken } from "axios";
import AppStrings from "locale/keys";

class LeaseAccountsReceivableReportApi {
  async getAll(
    query: GetLeaseAccountsReceivableReportQuery
  ): Promise<ApiResult<GetAllBaseQueryResponse<LeaseAccountsReceivableReportItem>>> {
    return await apiHelper.axiosGet<GetAllBaseQueryResponse<LeaseAccountsReceivableReportItem>>(
      ServerRoutes.REPORTS_LEASE_ACCOUNTS_RECEIVABLE + "/?",
      query
    );
  }
}

export const leaseAccountsReceivableReportApi = new LeaseAccountsReceivableReportApi();

class LeaseDepositsHeldByAccountApi {
  async getAll(
    query: GetLeaseDepositsHeldByAccountReportQuery
  ): Promise<ApiResult<GetAllBaseQueryResponse<LeaseDepositsHeldByAccountReportItem>>> {
    return await apiHelper.axiosGet<GetAllBaseQueryResponse<LeaseDepositsHeldByAccountReportItem>>(
      ServerRoutes.REPORTS_LEASE_DEPOSITS_HELD_BY_ACCOUNT + "/?",
      query
    );
  }
}

export const leaseDepositsHeldByAccountApi = new LeaseDepositsHeldByAccountApi();

class VendorTransactionsReportApi extends RestApiBaseWithDictionary<
  VendorTransactionReportItem,
  GetVendorTransactionsReportQuery
> {
  constructor() {
    super(ServerRoutes.REPORTS_VENDOR_TRANSACTIONS_REPORT, [accountsApi]);
    this.restRoute = ServerRoutes.REPORTS_VENDOR_TRANSACTIONS_REPORT;
  }

  async getAll(
    query: GetVendorTransactionsReportQuery
  ): Promise<ApiResult<GetAllBaseQueryResponse<VendorTransactionReportItem>>> {
    await this.loadDictionariesRequiredForGet();
    return await apiHelper.axiosGet<GetAllBaseQueryResponse<VendorTransactionReportItem>>(
      ServerRoutes.REPORTS_VENDOR_TRANSACTIONS_REPORT + "/?",
      query
    );
  }
}

export const vendorTransactionsReportApi = new VendorTransactionsReportApi();

class OwnerTransactionsReportApi {
  async getAll(
    query: GetOwnerAvailableFundsReportQuery
  ): Promise<ApiResult<GetAllBaseQueryResponse<OwnerTransactionReportItem>>> {
    return await apiHelper.axiosGet<GetAllBaseQueryResponse<OwnerTransactionReportItem>>(
      ServerRoutes.REPORTS_OWNER_TRANSACTIONS_REPORT + "/?",
      query
    );
  }
}

export const ownerTransactionsReportApi = new OwnerTransactionsReportApi();

class OwnerAvailableFoundsApi extends RestApiBase<OwnerAvailableFundsReportItem, GetOwnerAvailableFundsReportQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_OWNER_AVAILABLE_FUNDS_REPORT);
    this.restRoute = ServerRoutes.REPORTS_OWNER_AVAILABLE_FUNDS_REPORT;
  }

  getOwnerAvailableFundsReport = async (owners?: string[], properties?: string[]) => {
    const paramsObj: GetOwnerAvailableFundsReportQuery = {};
    if (owners) {
      paramsObj.filter_owners = owners;
    }
    if (properties) {
      paramsObj.filter_properties = properties;
    }
    paramsObj.filter_asOfDate = moment().format("YYYY-MM-DD");
    return await apiHelper.axiosGet(ServerRoutes.REPORTS_OWNER_AVAILABLE_FUNDS_REPORT, paramsObj);
  };
}

export const ownerAvailableFoundsApi = new OwnerAvailableFoundsApi();

class TransactionReportApi extends RestApiBase<TransactionReportItem, GetTransactionReportQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_TRANSACTION_REPORT, [
      accountsApi,
      propertiesApi,
      tenantsApi,
      vendorsApi,
      unitsApi,
      ownersApi,
      leasesApi
    ]);
    this.restRoute = ServerRoutes.REPORTS_TRANSACTION_REPORT;
  }
}

export const transactionReportApi = new TransactionReportApi();

class TransactionListReportApi extends RestApiBase<TransactionsListReportItem, GetTransactionsListReportQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_TRANSACTION_LIST_REPORT, [
      accountsApi,
      propertiesApi,
      tenantsApi,
      vendorsApi,
      unitsApi,
      ownersApi,
      leasesApi,
      usersApi
    ]);
    this.restRoute = ServerRoutes.REPORTS_TRANSACTION_LIST_REPORT;
  }
}

export const transactionListReportApi = new TransactionListReportApi();

class BillAndExpensesApi extends RestApiBase<TransactionsListReportItem, GetTransactionsListReportQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_BILLS_AND_PAYMENT, [
      accountsApi,
      propertiesApi,
      tenantsApi,
      vendorsApi,
      unitsApi,
      ownersApi,
      leasesApi,
      usersApi
    ]);
    this.restRoute = ServerRoutes.REPORTS_BILLS_AND_PAYMENT;
  }
}

export const billsAndExpensesApi = new BillAndExpensesApi();

class ChargesAndPaymentApi extends RestApiBase<TransactionsListReportItem, GetTransactionsListReportQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_CHARGES_AND_PAYMENT, [
      accountsApi,
      propertiesApi,
      tenantsApi,
      vendorsApi,
      unitsApi,
      ownersApi,
      leasesApi,
      usersApi
    ]);
    this.restRoute = ServerRoutes.REPORTS_CHARGES_AND_PAYMENT;
  }
}

export const chargesAndPaymentApi = new ChargesAndPaymentApi();

class OwnerStatementReportApi extends RestApiBase<OwnerStatementItemDto, GetOwnerStatementReportQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_OWNER_STATEMENT, [
      accountsApi,
      propertiesApi,
      tenantsApi,
      vendorsApi,
      unitsApi,
      ownersApi,
      leasesApi,
      usersApi
    ]);
    this.restRoute = ServerRoutes.REPORTS_OWNER_STATEMENT;
  }

  getPrintablePdfReport = async (
    payload: GetOwnerStatementReportRequestDto,
    cancelToken: CancelToken
  ): Promise<ApiResult<Blob>> => {
    await this.loadDictionariesRequiredForGet();
    return await apiHelper.axiosPost({
      url: ServerRoutes.REPORTS_OWNER_STATEMENT_PRINTABLE_PDF,
      data: payload,
      options: {
        responseType: "blob",
        timeout: LONG_TIMEOUT,
        cancelToken
      }
    });
  };

  getBulkReport = async (
    payload: GetOwnerStatementReportRequestDto,
    cancelToken: CancelToken
  ): Promise<ApiResult<OwnerStatementReportBulkResponseDto>> => {
    await this.loadDictionariesRequiredForGet();
    return await apiHelper.axiosPost({
      url: ServerRoutes.REPORTS_OWNER_STATEMENT_BULK_REPORT,
      data: payload,
      options: {
        timeout: LONG_TIMEOUT,
        cancelToken
      }
    });
  };

  sendPdfToOwners = async (payload: GetOwnerStatementReportRequestDto): Promise<ApiResult<unknown>> =>
    await apiHelper.axiosPost({
      url: ServerRoutes.REPORTS_OWNER_STATEMENT_SEND_PDF_TO_OWNERS,
      data: payload,
      toasts: {
        severity: "success",
        translationKey: AppStrings.Reports.ReportsScreen.OwnerStatementSentSuccessfully
      },
      shouldShowErrorToast: true
    });
}

export const ownerStatementReportApi = new OwnerStatementReportApi();

class TrialBalanceReportApi extends RestApiBase<TrialBalanceReportItem, GetTrialBalanceReportQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_TRIAL_BALANCE, [accountsApi, propertiesApi, vendorsApi, unitsApi, ownersApi, leasesApi]);
    this.restRoute = ServerRoutes.REPORTS_TRIAL_BALANCE;
  }
}

export const trialBalanceReportApi = new TrialBalanceReportApi();

class ProfitAndLossApi extends RestApiBase<TreeListItemAccount, GetProfitAndLossReportQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_PROFIT_AND_LOSS_SUMMARY, [
      accountsApi,
      propertiesApi,
      vendorsApi,
      unitsApi,
      ownersApi,
      leasesApi,
      tenantsApi
    ]);
    this.restRoute = ServerRoutes.REPORTS_PROFIT_AND_LOSS_SUMMARY;
  }
}

class ProfitAndLossDetailApi extends RestApiBase<TreeListItemAccount, GetTransactionReportQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_PROFIT_AND_LOSS_DETAIL, [
      accountsApi,
      propertiesApi,
      vendorsApi,
      unitsApi,
      ownersApi,
      leasesApi,
      tenantsApi
    ]);
    this.restRoute = ServerRoutes.REPORTS_PROFIT_AND_LOSS_DETAIL;
  }
}

class BalanceSheetDetailApi extends RestApiBase<TreeListItemAccount, GetTransactionReportQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_BALANCE_SHEET_DETAIL, [
      accountsApi,
      propertiesApi,
      vendorsApi,
      unitsApi,
      ownersApi,
      leasesApi,
      tenantsApi
    ]);
    this.restRoute = ServerRoutes.REPORTS_BALANCE_SHEET_DETAIL;
  }
}

class DepositDetailApi extends RestApiBase<DepositDetailReportItem, GetDepositDetailReportQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_DEPOSIT_DETAIL, [
      accountsApi,
      propertiesApi,
      tenantsApi,
      vendorsApi,
      unitsApi,
      ownersApi,
      leasesApi
    ]);
    this.restRoute = ServerRoutes.REPORTS_DEPOSIT_DETAIL;
  }
}

export const profitAndLossApi = new ProfitAndLossApi();

export const profitAndLossDetailApi = new ProfitAndLossDetailApi();

export const balanceSheetDetailApi = new BalanceSheetDetailApi();

export const depositDetailApi = new DepositDetailApi();

class Owners1099Api extends RestApiBase<Owners1099ReportItem, GetOwners1099ReportQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_OWNERS_1099, [accountsApi, propertiesApi, ownersApi]);
  }
}

export const owners1099Api = new Owners1099Api();

class Vendors1099Api extends RestApiBase<Vendors1099ReportItem, GetVendors1099ReportQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_VENDORS_1099, [accountsApi, propertiesApi, vendorsApi]);
  }
}

export const vendors1099Api = new Vendors1099Api();

class BalanceSheetApi extends RestApiBase<TreeListItemAccount, GetProfitAndLossReportQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_BALANCE_SHEET_SUMMARY, [
      accountsApi,
      propertiesApi,
      vendorsApi,
      unitsApi,
      ownersApi,
      leasesApi,
      tenantsApi
    ]);
    this.restRoute = ServerRoutes.REPORTS_BALANCE_SHEET_SUMMARY;
  }
}

export const balanceSheetApi = new BalanceSheetApi();

class CashFlowStatementApi extends RestApiBase<CashFlowStatementItem, GetCashFlowStatementReportQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_CASH_FLOW_STATEMENT, [
      accountsApi,
      propertiesApi,
      vendorsApi,
      unitsApi,
      ownersApi,
      leasesApi,
      tenantsApi
    ]);
    this.restRoute = ServerRoutes.REPORTS_CASH_FLOW_STATEMENT;
  }
}

export const cashFlowStatementApi = new CashFlowStatementApi();

class RentRollApi extends RestApiBase<RentRollReportItem, GetRentRollReportQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_RENT_ROLL, [
      accountsApi,
      propertiesApi,
      vendorsApi,
      unitsApi,
      ownersApi,
      leasesApi,
      tenantsApi
    ]);
    this.restRoute = ServerRoutes.REPORTS_RENT_ROLL;
  }
}

export const rentRollApi = new RentRollApi();

export const rentRollReportAppendTotalLine = (
  response: ApiResult<GetAllBaseQueryResponse<RentRollSummaryReportItem>>
) => {
  const totalLineIndex = response.data?.data.findIndex((x) => x.type === "total") || -1;
  let totalLine: RentRollSummaryReportItem = new RentRollSummaryReportItem();
  if (response.data?.data && totalLineIndex > -1) {
    totalLine = response.data?.data.splice(totalLineIndex, 1)[0] as RentRollSummaryReportItem;
  }
  if (response.data) {
    response.data["totalLine"] = totalLine;
  }

  return response;
};

class RentRollSummaryByBedsAndBathsApi extends RestApiBase<
  RentRollSummaryByBedsAndBathsReportItem,
  GetRentRollSummaryReportQuery
> {
  constructor() {
    super(ServerRoutes.REPORTS_RENT_ROLL_SUMMARY_BY_BEDS_AND_BATHS, []);
    this.restRoute = ServerRoutes.REPORTS_RENT_ROLL_SUMMARY_BY_BEDS_AND_BATHS;
  }

  async getAll(query: GetRentRollReportQuery): Promise<ApiResult<GetAllBaseQueryResponse<RentRollReportItem>>> {
    const response = await super.getAll(query);
    return rentRollReportAppendTotalLine(response);
  }
}

export const rentRollSummaryByBedsAndBathsApi = new RentRollSummaryByBedsAndBathsApi();

class RentRollSummaryByProperty extends RestApiBase<
  RentRollSummaryByPropertyReportItem,
  GetRentRollSummaryReportQuery
> {
  constructor() {
    super(ServerRoutes.REPORTS_RENT_ROLL_SUMMARY_BY_PROPERTY, []);
    this.restRoute = ServerRoutes.REPORTS_RENT_ROLL_SUMMARY_BY_PROPERTY;
  }

  async getAll(query: GetRentRollReportQuery): Promise<ApiResult<GetAllBaseQueryResponse<RentRollReportItem>>> {
    const response = await super.getAll(query);
    return rentRollReportAppendTotalLine(response);
  }
}

export const rentRollSummaryByProperty = new RentRollSummaryByProperty();

class BankBalanceApiByProperty extends RestApiBase<BankBalanceByPropretyReportItem, GetBankBalanceByPropertyReport> {
  constructor() {
    super(ServerRoutes.REPORTS_BANK_BALANCE_BY_PROPERTY, [accountsApi, propertiesApi]);
    this.restRoute = ServerRoutes.REPORTS_BANK_BALANCE_BY_PROPERTY;
  }
}

export const bankBalanceApiByProperty = new BankBalanceApiByProperty();

class AccountsReceivableAgingDetailApi extends RestApiBase<ArAgingDetailReportItem, GetArAgingReportQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_ACCOUNTS_RECEIVABLE_AGING_DETAIL, [propertiesApi, unitsApi, leasesApi]);
    this.restRoute = ServerRoutes.REPORTS_ACCOUNTS_RECEIVABLE_AGING_DETAIL;
  }
}

export const accountsReceivableAgingDetailApi = new AccountsReceivableAgingDetailApi();

class AccountsReceivableAgingSummaryApi extends RestApiBase<ArAgingSummaryReportItem, GetArAgingReportQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_ACCOUNTS_RECEIVABLE_AGING_SUMMARY, [propertiesApi, unitsApi, leasesApi]);
    this.restRoute = ServerRoutes.REPORTS_ACCOUNTS_RECEIVABLE_AGING_SUMMARY;
  }
}

export const accountsReceivableAgingSummaryApi = new AccountsReceivableAgingSummaryApi();

class AccountsPayableAgingDetailApi extends RestApiBase<ApAgingDetailReportItem, GetApAgingReportQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_ACCOUNTS_PAYABLE_AGING_DETAIL, [vendorsApi, leasesApi, propertiesApi, unitsApi]);
    this.restRoute = ServerRoutes.REPORTS_ACCOUNTS_PAYABLE_AGING_DETAIL;
  }
}

export const accountsPayableAgingDetailApi = new AccountsPayableAgingDetailApi();

class AccountsPayableAgingDetailByAccountApi extends RestApiBase<
  ApAgingDetailByAccountReportItem,
  GetApAgingReportQuery
> {
  constructor() {
    super(ServerRoutes.REPORTS_ACCOUNTS_PAYABLE_AGING_DETAIL_BY_ACCOUNT, [vendorsApi, accountsApi, propertiesApi]);
    this.restRoute = ServerRoutes.REPORTS_ACCOUNTS_PAYABLE_AGING_DETAIL_BY_ACCOUNT;
  }
}

export const accountsPayableAgingDetailByAccountApi = new AccountsPayableAgingDetailByAccountApi();

class AccountsReceivableAgingDetailByAccountApi extends RestApiBase<
  ArAgingDetailByAccountReportItem,
  GetArAgingReportQuery
> {
  constructor() {
    super(ServerRoutes.REPORTS_ACCOUNTS_RECEIVABLE_AGING_DETAIL_BY_ACCOUNT, [vendorsApi, accountsApi, propertiesApi]);
    this.restRoute = ServerRoutes.REPORTS_ACCOUNTS_RECEIVABLE_AGING_DETAIL_BY_ACCOUNT;
  }
}

export const accountsReceivableAgingDetailByAccountApi = new AccountsReceivableAgingDetailByAccountApi();

class AccountsPayableAgingSummaryApi extends RestApiBase<ApAgingSummaryReportItem, GetApAgingReportQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_ACCOUNTS_PAYABLE_AGING_SUMMARY, [vendorsApi]);
    this.restRoute = ServerRoutes.REPORTS_ACCOUNTS_PAYABLE_AGING_SUMMARY;
  }
}

export const accountsPayableAgingSummaryApi = new AccountsPayableAgingSummaryApi();

class AccountRegisterReportApi extends RestApiBase<AccountRegisterReportItem, GetAccountRegisterReportQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_ACCOUNT_REGISTER, [
      propertiesApi,
      leasesApi,
      unitsApi,
      vendorsApi,
      tenantsApi,
      ownersApi
    ]);
    this.restRoute = ServerRoutes.REPORTS_ACCOUNT_REGISTER;
  }
}

export const accountRegisterReportApi = new AccountRegisterReportApi();

class PropertyAvailableFoundsReportApi extends RestApiBase<
  PropertyAvailableFundsReportItem,
  GetPropertyAvailableFundsReportQuery
> {
  constructor() {
    super(ServerRoutes.REPORTS_PROPERTY_AVAILABLE_FUNDS, []);
    this.restRoute = ServerRoutes.REPORTS_PROPERTY_AVAILABLE_FUNDS;
  }
}

export const propertyAvailableFoundsReportApi = new PropertyAvailableFoundsReportApi();

class PropertyAccountsFoundsReportApi extends RestApiBase<
  PropertyAccountsFundsReportItem,
  GetPropertyAccountsFundsReportQuery
> {
  constructor() {
    super(ServerRoutes.REPORTS_PROPERTY_ACCOUNTS_FUNDS, [propertiesApi, accountsApi, ownersApi]);
    this.restRoute = ServerRoutes.REPORTS_PROPERTY_ACCOUNTS_FUNDS;
  }

  getData = async (
    payload: GetPropertyAccountsFundsReportQuery
  ): Promise<ApiResult<GetAllBaseQueryResponse<PropertyAccountsFundsReportItem>>> => {
    await this.loadDictionariesRequiredForGet();
    return await apiHelper.axiosPost({
      url: ServerRoutes.REPORTS_PROPERTY_ACCOUNTS_FUNDS,
      data: payload
    });
  };
}

export const propertyAccountsFoundsReportApi = new PropertyAccountsFoundsReportApi();

class TenantPetsReportApi extends RestApiBase<TenantPetsReportItemDto, GetAllLeaseTenantsQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_TENANT_PETS_REPORT, [propertiesApi, unitsApi]);
  }
}

export const tenantPetsReportApi = new TenantPetsReportApi();

class TenantVehiclesReportApi extends RestApiBase<TenantVehiclesReportItemDto, GetAllLeaseTenantsQuery> {
  constructor() {
    super(ServerRoutes.REPORTS_TENANT_VEHICLES_REPORT, [propertiesApi, unitsApi]);
  }
}

export const tenantVehiclesReportApi = new TenantVehiclesReportApi();

class ManagementFeesReportApi extends RestApiBase<
  CalculateManagementFeesReportItemDto,
  GetCalculateManagementFeesReportQuery
> {
  constructor() {
    super(ServerRoutes.REPORTS_CALCULATE_MANAGEMENT_FEES_REPORT, [vendorsApi, propertiesApi]);
    this.restRoute = ServerRoutes.REPORTS_CALCULATE_MANAGEMENT_FEES_REPORT;
  }
}

export const managementFeesReportApi = new ManagementFeesReportApi();

class RecurringTransactionsDetailReportApi extends RestApiBase<
  RecurringTransactionsDetailReportItem,
  GetRecurringTransactionDetailReportQuery
> {
  constructor() {
    super(ServerRoutes.REPORTS_RECURRING_TRANSACTIONS_DETAIL, [leasesApi, propertiesApi, unitsApi, accountsApi]);
    this.restRoute = ServerRoutes.REPORTS_RECURRING_TRANSACTIONS_DETAIL;
  }
}

export const recurringTransactionsDetailReportApi = new RecurringTransactionsDetailReportApi();
