import React from "react";
import { View } from "DLUI/view";
import { ObjectSection } from "./objectSection";
import type { ObjectPermissionItemsInterface } from "components/screens/userRoles/newUserRole/components/objects/objectPermissionItems";
import { getObjectPermissionItems } from "components/screens/userRoles/newUserRole/components/objects/objectPermissionItems";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { useUserType } from "../../../../../../hooks/useUserType";
import { useFeatureFlag } from "../../../../../../hooks/useFeatureFlag";

export const UserRoleObjects = () => {
  const { isHOAUser } = useUserType();
  const { getIsFeatureActiveForDbTenant } = useFeatureFlag();

  const objectPermissionItems = getObjectPermissionItems(
    isHOAUser,
    getIsFeatureActiveForDbTenant("CommunicationsCenter")
  ).map<ObjectPermissionItemsInterface>((objectPermission) => {
    return {
      ...objectPermission,
      permissions: objectPermission.permissions.filter(({ inactive }) => !inactive)
    };
  });

  return (
    <View flex={1}>
      <View paddingLeft={20} paddingRight={20}>
        <Text
          fontSize={16}
          color={"black"}
          value={AppStrings.UserRoles.ObjectsHeaderText}
          marginLeft={20}
          marginRight={20}
          marginBottom={20}
        />
        {objectPermissionItems.map(({ sectionTitle, permissions, ternary }, index) => (
          <ObjectSection permissions={permissions} title={sectionTitle} ternary={ternary} key={index} />
        ))}
      </View>
    </View>
  );
};
