import { CommunicationSubjectType } from "@doorloop/dto";

const communicationSubjectTypes: Record<CommunicationSubjectType, string> = {
  [CommunicationSubjectType.RENT_REMINDER]: "Rent Reminder",
  [CommunicationSubjectType.RENT_REMINDER_HOA]: "Rent Reminder",
  [CommunicationSubjectType.WELCOME_MOVE_IN_TENANT]: "Welcome Move In Tenant",
  [CommunicationSubjectType.PAYMENT_RECEIVED]: "Received Payment",
  [CommunicationSubjectType.RENT_OVERDUE]: "Send Rent Overdue ",
  [CommunicationSubjectType.RENT_OVERDUE_HOA]: "Send Rent Overdue",
  [CommunicationSubjectType.TENANT_PORTAL_INVITE]: "Tenant Portal Invite",
  [CommunicationSubjectType.TENANT_PORTAL_INVITE_HOA]: "Tenant Portal Invite",
  [CommunicationSubjectType.OWNER_PORTAL_INVITE]: "Owner Portal Invite",
  [CommunicationSubjectType.RENTAL_APPLICATION_INVITE]: "Rental Application Invite",
  [CommunicationSubjectType.RENT_CHARGED]: "Rent Charged Reminder",
  [CommunicationSubjectType.RENT_CHARGED_HOA]: "Rent Charged Reminder",
  [CommunicationSubjectType.TENANT_LEASE_REVERSED_PAYMENT]: "Tenant Lease Reversed Payment",
  [CommunicationSubjectType.LATE_FEE]: "Late Fee",
  [CommunicationSubjectType.LATE_FEE_HOA]: "Late Fee",
  [CommunicationSubjectType.BEFORE_LATE_FEE]: "Before Late Fee",
  [CommunicationSubjectType.BEFORE_LATE_FEE_HOA]: "Before Late Fee",
  [CommunicationSubjectType.ANNOUNCEMENT]: "Announcement",
  [CommunicationSubjectType.WELCOME_TEXT_MESSAGE]: "Send Welcome Text Message"
};

export default {
  screen: {
    title: "Communications",
    myInboxTitle: "My Inbox",
    allMessagesTitle: "All Messages",
    unAssignedTitle: "Unassigned",
    helpPanel: {
      description: "Communicate with tenants, owners, prospects, and vendors through SMS."
    }
  },
  filterPanel: {
    inboxFilters: "Inbox Filters"
  },
  setupSmsWizard: {
    screen: {
      title: "Set up SMS",
      subTitle: "{Assistive text, help, page description and guidelines}",
      successTitle: "Phone Number Successfully Acquired",
      successSubtitle:
        "Your number will be activated within 3 days. If you don’t receive a notification by then, please get in touch with our support team.",
      successSubtitleCanada:
        "Your number will be activated within a few hours. If you don’t receive a notification by then, please get in touch with our support team.",
      helpPanel: {
        description: "Your centralized hub for SMS, requests & more."
      }
    },
    setupSmsDescription: {
      title: "Welcome to the DoorLoop \n Communication Center!",
      bulletDescriptions: {
        instantUpdates: "Get a shared business number to send & receive messages",
        effortlessChat: "Each teammate can send & receive messages using their own account",
        quickAssistance: "Follow up on all conversations in one place"
      },
      haveMoreQuestions: "Have more questions?",
      readOurFAQs: "Read our FAQs."
    },
    businessDetailsStep: {
      businessDetails: "Business Details",
      fillInYourBusinessDetails:
        "Enter your business details to meet carrier requirements, secure your account, and prevent spam",
      einTitle: "EIN Number",
      confirmationDescription:
        "Please ensure that the EIN matches exactly as it is displayed in the letter provided by the IRS.",
      businessDetailsHint:
        "To meet carrier requirements, only the IRS-verified business details associated with your Merchant Account(s) will be accepted. Please enter them accordingly."
    },
    paymentStep: {
      title: "Activate Your Phone Number",
      subtitle: "Unlock your business virtual phone number for only ${{setupSmsPrice}}",
      subtitleCanada: "Activate Now",
      phoneNumber: "One-Time Fee",
      first500Messages: "First 500 Messages",
      phoneNumberDescription:
        "Unlock your virtual number for ${{setupSmsPrice}} and receive {{creditsChargeQuantity}} complimentary messages. When exhausted, easily refill for ${{creditsChargeAmount}}/{{creditsChargeQuantity}} messages.",
      phoneNumberDnaescriptionFree:
        "Unlock your virtual number for free and receive {{creditsChargeQuantity}} complimentary messages. Once used up, easily refill with {{creditsChargeQuantity}} messages for just ${{creditsChargeAmount}}.",
      total: "Total",
      free: "Free",
      autoPurchaseDescription:
        "Enable auto-renewal:  ${{creditsChargeAmount}} billed every {{creditsChargeQuantity}} messages.",
      paymentDescription: "Charges will automatically apply to the credit card set up on your DoorLoop account."
    }
  },
  errors: {
    failedRetrieveAvailablePhoneNumbers: "Failed to retrieve available phone numbers. please try again.",
    contactNotEmpty: "Contacts types cannot be empty.",
    failedActivateNumber: "Failed to activate phone number. Please try again.",
    failedToFindNumbers: "Failed to allocate available number to your region."
  },
  phoneNumberPendingTitle: "The Activation of Your Phone Number is Pending",
  phoneNumberPendingSubtitle: "Activation of your phone number is awaiting approval from the provider.",
  contactUs: "Contact Us",
  failedMessageTitle: "Failed message",
  failedMessageSubtitle: `Experiencing connection issues \n\nPlease contact customer support.`,
  setupSmsTitle: "Get started with SMS",
  setupSmsSubtitle: "Send and receive SMS messages from Doorloop",
  setupSms: "Set up SMS",
  read: "Read",
  unread: "Unread",
  invalidContact: "Invalid contact. Search by name or email\n" + "and select from search results.",
  searchForRecipients: "Search for a contact",
  noRecipientsFound: "No recipients found",
  searching: "Searching...",
  serviceNotAvailableInRegionTitle: "This service is not available in your country or region",
  serviceNotAvailableInRegionDescription: "DoorLoop does not support virtual phone numbers in your region",
  changeYourRegion: "Change your region",
  conversationInputCredits: "CREDITS: {{messageCredits}}",
  systemMessage: "System Message",
  automatedSmsAlert: "Automated Outgoing SMS Alert",
  communicationSubjectTypes
};
