import type { BudgetDto, GetAllBudgetsQuery } from "@doorloop/dto";
import { OwnerPortalServerRoutes } from "@doorloop/dto";

import { RestApiBase } from "api/restApiBase";

export class OwnerPortalBudgetsApi extends RestApiBase<BudgetDto, GetAllBudgetsQuery> {
  constructor() {
    super(OwnerPortalServerRoutes.BUDGETS);
    this.restRoute = OwnerPortalServerRoutes.BUDGETS;
  }
}

export const ownerPortalBudgetsApi = new OwnerPortalBudgetsApi();
