import type { FC } from "react";
import React, { useCallback, useState } from "react";

import type { PopoverProps } from "./popover";
import Popover from "./popover";
import type { TextTooltipProps } from "./textTooltip";
import { TextTooltip } from "./textTooltip";

export interface PopoverHoverProps {
  tooltipProps: TextTooltipProps;
  popoverProps?: Omit<PopoverProps, "shouldShow">;
}

const PopoverHover: FC<PopoverHoverProps> = ({ tooltipProps, popoverProps, children }) => {
  const [isOpenPopover, setIsOpenPopover] = useState(false);

  const handleStateChange: NonNullable<PopoverProps["onStateChange"]> = useCallback(
    (nextState) => {
      popoverProps?.onStateChange?.(nextState);
      setIsOpenPopover((prevState) => !prevState);
    },
    [popoverProps]
  );

  return (
    <>
      <TextTooltip show useButton={false} {...tooltipProps}>
        {children}
      </TextTooltip>

      <Popover topSectionItems={[]} {...popoverProps} onStateChange={handleStateChange} shouldShow={isOpenPopover} />
    </>
  );
};

export { PopoverHover };
