"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CashFlowStatementRootAccounts = void 0;
var CashFlowStatementRootAccounts;
(function (CashFlowStatementRootAccounts) {
    CashFlowStatementRootAccounts["OPERATING_ACTIVITIES"] = "OPERATING_ACTIVITIES";
    CashFlowStatementRootAccounts["NET_INCOME"] = "NET_INCOME";
    CashFlowStatementRootAccounts["ADJUSTMENTS_TO_RECONCILE_NET_INCOME_TO_NET_CASH_PROVIDED_BY_OPERATIONS"] = "ADJUSTMENTS_TO_RECONCILE_NET_INCOME_TO_NET_CASH_PROVIDED_BY_OPERATIONS";
    CashFlowStatementRootAccounts["INVESTING_ACTIVITIES"] = "INVESTING_ACTIVITIES";
    CashFlowStatementRootAccounts["FINANCING_ACTIVITIES"] = "FINANCING_ACTIVITIES";
    CashFlowStatementRootAccounts["NET_CASH_INCREASE_FOR_PERIOD"] = "NET_CASH_INCREASE_FOR_PERIOD";
    CashFlowStatementRootAccounts["CASH_AT_BEGNINNING_OF_PERIOD"] = "CASH_AT_BEGNINNING_OF_PERIOD";
    CashFlowStatementRootAccounts["CASH_AT_END_OF_PERIOD"] = "CASH_AT_END_OF_PERIOD";
    CashFlowStatementRootAccounts["SECURITY_DEPOSITS_HELD"] = "SECURITY_DEPOSITS_HELD";
    CashFlowStatementRootAccounts["PROPERTY_RESERVES"] = "PROPERTY_RESERVES";
    CashFlowStatementRootAccounts["CASH_AVAILABLE_TO_WITHDRAW"] = "CASH_AVAILABLE_TO_WITHDRAW";
    CashFlowStatementRootAccounts["UNDEPOSITED_FUNDS"] = "UNDEPOSITED_FUNDS";
})(CashFlowStatementRootAccounts || (exports.CashFlowStatementRootAccounts = CashFlowStatementRootAccounts = {}));
