import type { LeaseOutstandingTransactionDto, LeasePaymentDto } from "@doorloop/dto";
import { StickyHeader } from "DLUI/stickyHeader";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import type { FC } from "react";
import React from "react";
import OutstandingTransactionsListItem from "./outstandingTransactionsListItem";
import isEmpty from "lodash/isEmpty";
import { MediumListItemHeight } from "DLUI/listItems/listItemContainer";
import { VirtualList } from "DLUI/virtualScroll/components/virtualList";

interface OutstandingChargeTransactionsProps {
  outstandingCharges: LeaseOutstandingTransactionDto[];
  formikRef: FormikProps<LeasePaymentDto>;
  leaseId: string;
}

export const OutstandingChargeTransactions: FC<OutstandingChargeTransactionsProps> = ({
  outstandingCharges,
  formikRef,
  leaseId
}) => {
  if (isEmpty(outstandingCharges)) {
    return null;
  }

  return (
    <View marginTop={20}>
      <StickyHeader removeVerticalPadding id={"outstandingChargeTransactions"} shouldShow />
      <VirtualList items={outstandingCharges} estimateSize={() => MediumListItemHeight}>
        {(item, index) => {
          if (isEmpty(item) || !item.linkedTransaction) {
            return null;
          }

          return (
            <div style={{ width: "100%" }}>
              <OutstandingTransactionsListItem
                type="charge"
                id={"outstandingChargeTransactions"}
                accountId={item.account || ""}
                transactionDate={item.linkedTransactionDate || ""}
                name={`linkedCharges[${index}]`}
                formikRef={formikRef}
                transactionId={item.linkedTransaction}
                leaseId={leaseId}
                reference={item.linkedTransactionReference}
              />
            </div>
          );
        }}
      </VirtualList>
    </View>
  );
};
