import type { GetAllLeaseTenantsQuery, TenantDto } from "@doorloop/dto";
import { DataCy, ObjectPermission } from "@doorloop/dto";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import React, { useMemo, useState } from "react";
import { TenantsList } from "screens/leases/leases/leaseDetails/tenants";
import { SelectableTenantsListItem } from "screens/tenants";
import { DialogSearchPanel } from "DLUI/dialogs";
import DLButton from "DLUI/button/dlButton";
import { getDialogSelectionHeight } from "../components/dialogFrame";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

export interface TenantSelectionViewProps {
  showAddNewTenant?: boolean;
  didPressAddNewTenant?: () => void;
  didSelectTenantItem: (selectedItem: TenantDto, itemIndex: number) => void;
  height?: number;
  filterObj?: GetAllLeaseTenantsQuery;
  addNewTenantButtonText?: string;
  emptyViewInstructionsText?: string;
  dataCy?: string;
}

const TenantSelectionView: React.FC<TenantSelectionViewProps> = ({
  didPressAddNewTenant,
  didSelectTenantItem,
  showAddNewTenant = true,
  height,
  filterObj,
  addNewTenantButtonText = AppStrings.Tenants.Screen.AddNewTenant,
  emptyViewInstructionsText
}: TenantSelectionViewProps) => {
  const { screenContainerPadding } = useResponsiveHelper();

  const [searchQuery, setSearchQuery] = useState<string>("");

  const _filterObject = useMemo(() => {
    return { ...filterObj, filter_text: searchQuery };
  }, [searchQuery, filterObj]);

  const _didPressAddNewTenant = () => {
    if (didPressAddNewTenant) {
      didPressAddNewTenant();
    }
  };

  const renderSelection = () => (
    <TenantsList
      didSelectTenantItem={didSelectTenantItem}
      ListItem={SelectableTenantsListItem}
      filterObj={_filterObject}
      fullWidth
      scrollableTarget={"TenantListContainer"}
      listDirection={"column"}
      emptyViewInstructionsText={emptyViewInstructionsText}
      selectableListItem
      stickyHeaderId={"TenantListContainer"}
      removeDefaultBottomPadding
    />
  );

  const renderAddNewButton = () => {
    if (!showAddNewTenant) {
      return null;
    }
    return (
      <DLButton
        clearance={{ permission: ObjectPermission.tenants, field: "create" }}
        onClick={_didPressAddNewTenant}
        actionText={addNewTenantButtonText}
        icons={{ start: { isHidden: false } }}
        style={{ marginTop: 20, marginRight: 15 }}
      />
    );
  };

  return (
    <View paddingLeft={screenContainerPadding} paddingRight={screenContainerPadding}>
      <View alignItems={"center"} flexDirection={"row"}>
        <View autoWidth>
          <DialogSearchPanel
            placeholder={AppStrings.Tenants.Screen.SearchPlaceHolder}
            onChange={setSearchQuery}
            borderRadius={30}
            dataCy={DataCy.DLUI.searchBarContainer}
          />
        </View>

        <View flex={1} justifyContent={"center"} alignItems={"flex-end"}>
          {renderAddNewButton()}
        </View>
      </View>

      <View
        height={height ? getDialogSelectionHeight(height) : undefined}
        overflow={"scroll"}
        id={"TenantListContainer"}
        noWrap
        marginTop={20}
      >
        {renderSelection()}
      </View>
    </View>
  );
};

export default TenantSelectionView;
