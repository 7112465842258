import { IconButton } from "DLUI/form";
import { DotsIcon, DuplicateIcon, TrashIconOutline } from "../../../assets";
import { MobileBottomDrawer } from "DLUI/drawer/mobileBottomDrawer";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import AppStrings from "../../../locale/keys";
import ColorsEnum from "../../../utils/colorsEnum";
import type { FC } from "react";
import React, { useState } from "react";
import compact from "lodash/compact";
import { DataCy } from "@doorloop/dto";

interface DialogHeaderActionButtonsProps {
  onDeleteClick?: VoidFunction;
  onDuplicateClick: VoidFunction;
  hasDeleteClearance: boolean;
  hasCreateClearance: boolean;
  hideDeleteButton?: boolean;
  hideDuplicateButton?: boolean;
}

export const MobileDialogHeaderActionButtons: FC<DialogHeaderActionButtonsProps> = ({
  onDeleteClick,
  onDuplicateClick,
  hasDeleteClearance,
  hasCreateClearance,
  hideDeleteButton,
  hideDuplicateButton
}) => {
  const [isBottomDrawerOpen, setIsBottomDrawerOpen] = useState(false);
  const showMobileMenuButton = Boolean(compact([!hideDeleteButton, !hideDuplicateButton]).length);

  return (
    <>
      {showMobileMenuButton && <IconButton Icon={DotsIcon} onClick={() => setIsBottomDrawerOpen(true)} size={16} />}
      <MobileBottomDrawer showDrawer={isBottomDrawerOpen} onCloseClick={() => setIsBottomDrawerOpen(false)}>
        <View noWrap gap={24}>
          {hasCreateClearance && !hideDuplicateButton && (
            <View
              flexDirection={"row"}
              alignItems={"center"}
              gap={10}
              onClick={onDuplicateClick}
              dataCy={DataCy.dialogActionButtons.delete}
            >
              <IconButton size={24} pathColor={"black"} Icon={DuplicateIcon} removePadding />
              <Text value={AppStrings.Common.Duplicate} />
            </View>
          )}
          {hasDeleteClearance && !hideDeleteButton && (
            <View
              flexDirection={"row"}
              alignItems={"center"}
              gap={10}
              onClick={onDeleteClick}
              dataCy={DataCy.dialogActionButtons.duplicate}
            >
              <IconButton size={24} pathColor={"error"} Icon={TrashIconOutline} removePadding />
              <Text colorEnum={ColorsEnum.Red} value={AppStrings.Common.Delete} />
            </View>
          )}
        </View>
      </MobileBottomDrawer>
    </>
  );
};
