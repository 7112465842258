import { DataCy, ProspectStatus, ReportId, ReportPermission } from "@doorloop/dto";

import AppStrings from "locale/keys";
import { ownerPortalNavigation } from "../../../../../utils/ownerPortalNavigation";
import type { ReportScreenItemType } from "DLUI/report/screen/reportScreenItemType";

const didPressActiveProspects = (propertyId?: string): void => {
  const params = {
    groupBy: "prospectInfo.status"
  };

  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  ownerPortalNavigation.runProspectsReport(params);
};

const didPressMovedInProspects = (propertyId?: string): void => {
  const params = {
    groupBy: "prospectInfo.interests",
    filter_prospect_status: ProspectStatus.CLOSED_MOVED_IN
  };

  if (propertyId) {
    params["filter_prospect_interests_property"] = propertyId;
  }

  ownerPortalNavigation.runProspectsReport(params);
};

const didPressLostProspects = (propertyId?: string): void => {
  const params = {
    filter_prospect_status: ProspectStatus.CLOSED_LOST
  };

  if (propertyId) {
    params["filter_prospect_interests_property"] = propertyId;
  }

  ownerPortalNavigation.runProspectsReport(params);
};

const didPressProspectsByLeasingAgent = (propertyId?: string): void => {
  const params = {
    groupBy: "prospectInfo.assignedToUser"
  };

  if (propertyId) {
    params["filter_prospect_interests_property"] = propertyId;
  }

  ownerPortalNavigation.runProspectsReport(params);
};

const didPressProspectsBySource = (propertyId?: string): void => {
  const params = {
    groupBy: "prospectInfo.leadSource"
  };

  if (propertyId) {
    params["filter_prospect_interests_property"] = propertyId;
  }

  ownerPortalNavigation.runProspectsReport(params);
};

const didPressRentalApplications = (propertyId?: string): void => {
  const params: Record<string, string> = {
    period: "last-30-days"
  };

  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  ownerPortalNavigation.runRentalApplicationsReport(params);
};

export const PROSPECTS_REPORT_ITEMS: ReportScreenItemType[] = [
  {
    description: AppStrings.Reports.ReportsScreen.ActiveProspects,
    onPress: didPressActiveProspects,
    permission: ReportPermission.prospect,
    id: ReportId.ACTIVE_PROSPECTS,
    dataCy: DataCy.reports.screenItems.prospects.activeProspects
  },
  {
    description: AppStrings.Reports.ReportsScreen.LostProspects,
    onPress: didPressLostProspects,
    permission: ReportPermission.prospect,
    id: ReportId.LOST_PROSPECTS,
    dataCy: DataCy.reports.screenItems.prospects.lostProspects
  },
  {
    description: AppStrings.Reports.ReportsScreen.MovedInProspects,
    onPress: didPressMovedInProspects,
    permission: ReportPermission.prospect,
    id: ReportId.MOVED_IN_PROSPECTS,
    dataCy: DataCy.reports.screenItems.prospects.movedInProspects
  },
  {
    description: AppStrings.Reports.ReportsScreen.ProspectsbyLeasingAgent,
    onPress: didPressProspectsByLeasingAgent,
    permission: ReportPermission.prospect,
    id: ReportId.PROSPECTS_BY_LEASING_AGENT,
    dataCy: DataCy.reports.screenItems.prospects.prospectsByLeasingAgent
  },
  {
    description: AppStrings.Reports.ReportsScreen.ProspectsbySource,
    onPress: didPressProspectsBySource,
    permission: ReportPermission.prospect,
    id: ReportId.PROSPECTS_BY_SOURCE,
    dataCy: DataCy.reports.screenItems.prospects.prospectsBySource
  },
  {
    description: AppStrings.Reports.ReportsScreen.RentalApplicationsReport,
    onPress: didPressRentalApplications,
    permission: ReportPermission.prospect,
    id: ReportId.RENTAL_APPLICATIONS,
    dataCy: DataCy.reports.screenItems.prospects.rentalApplicationsReport
  }
];
