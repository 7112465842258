import React from "react";
import { View } from "DLUI/view";
import { useTranslation } from "react-i18next";
import Text from "DLUI/text";
import AppStrings from "../../../../locale/keys";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { FastField } from "formik";
import { FormikDatePicker } from "DLUI/form";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Notification } from "DLUI/notification/notification";
import "./companyStartDateForm.css";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

const CompanyStartDateForm: React.FC = () => {
  const { screenContainerPadding } = useResponsiveHelper();
  const { t } = useTranslation();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <View paddingLeft={screenContainerPadding} paddingRight={screenContainerPadding}>
        <Text
          marginTop={20}
          color={"gray"}
          value={AppStrings.Settings.GeneralSettings.CompanyStartDate.Dialog.Subtitle}
        />
        <ul>
          <li className={"DLUI_ListItem"}>
            <Text color={"gray"} value={AppStrings.Settings.GeneralSettings.CompanyStartDate.Dialog.TenantInvite} />
          </li>
          <li className={"DLUI_ListItem"}>
            <Text color={"gray"} value={AppStrings.Settings.GeneralSettings.CompanyStartDate.Dialog.RentReminder} />
          </li>
        </ul>
        <HorizontalSeparationLine marginBottom={20} />
        <FastField
          component={FormikDatePicker}
          label={t(AppStrings.Settings.GeneralSettings.CompanyStartDate.Dialog.DoorloopStartDate)}
          name={"startDate"}
          marginBottom={20}
          required
        />
        <Notification
          minHeight={95}
          iconSize={50}
          marginTop={20}
          fontSize={16}
          label={AppStrings.Settings.GeneralSettings.CompanyStartDate.Dialog.InfoText}
        />
      </View>
    </MuiPickersUtilsProvider>
  );
};

export default CompanyStartDateForm;
