import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    ReportsSectionUserView: {
      padding: "0 20px !important",
      width: "calc(100% - 40px) !important",
      backgroundColor: "white !important",
      borderRadius: "10px !important"
    }
  })
);

export const useReportScreenItemStyles = makeStyles({
  root: {
    borderBottom: "1px solid rgba(124, 132, 172, 0.2)",
    "&:hover": {
      backgroundColor: "#eceef5",
      borderRadius: "10px !important"
    }
  }
});
