"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyndicationPetType = exports.SyndicationPetSizeEnum = exports.SyndicationPetTypeEnum = void 0;
const class_validator_1 = require("class-validator");
var SyndicationPetTypeEnum;
(function (SyndicationPetTypeEnum) {
    SyndicationPetTypeEnum["Dog"] = "Dog";
    SyndicationPetTypeEnum["Cat"] = "Cat";
    SyndicationPetTypeEnum["Other"] = "Other";
})(SyndicationPetTypeEnum || (exports.SyndicationPetTypeEnum = SyndicationPetTypeEnum = {}));
var SyndicationPetSizeEnum;
(function (SyndicationPetSizeEnum) {
    SyndicationPetSizeEnum["Large"] = "Large";
    SyndicationPetSizeEnum["Medium"] = "Medium";
    SyndicationPetSizeEnum["Small"] = "Small";
})(SyndicationPetSizeEnum || (exports.SyndicationPetSizeEnum = SyndicationPetSizeEnum = {}));
class SyndicationPetType {
}
exports.SyndicationPetType = SyndicationPetType;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(SyndicationPetTypeEnum),
    __metadata("design:type", String)
], SyndicationPetType.prototype, "_PetType", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], SyndicationPetType.prototype, "_Description", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], SyndicationPetType.prototype, "_Count", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(SyndicationPetSizeEnum),
    __metadata("design:type", String)
], SyndicationPetType.prototype, "_Size", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], SyndicationPetType.prototype, "_Weight", void 0);
