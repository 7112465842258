"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TasksAssignedToMeDto = void 0;
const taskAmounts_1 = require("../../dashboards/widgets/taskAmounts");
class TasksAssignedToMeDto extends taskAmounts_1.TaskAmounts {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.TasksAssignedToMeDto = TasksAssignedToMeDto;
