"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseDepositsHeldByAccountReportItem = void 0;
class LeaseDepositsHeldByAccountReportItem {
    constructor(account, accountName, total, lines) {
        this.account = account;
        this.accountName = accountName;
        this.lines = lines;
        this.total = total;
    }
}
exports.LeaseDepositsHeldByAccountReportItem = LeaseDepositsHeldByAccountReportItem;
