import type { GetTransactionReportQuery } from "@doorloop/dto";
import { DataCy } from "@doorloop/dto";
import { ReportId, ReportPermission, SegmentEventTypes, TransactionReportGroupBy } from "@doorloop/dto";
import AppStrings from "locale/keys";
import { NavigationManager } from "utils/navigation";
import { ReportType } from "screens/reports/cashFlowStatement/reportType.enum";
import { analyticsService } from "../../../../../services/analyticsService";
import type { ReportScreenItemType } from "DLUI/report/screen/reportScreenItemType";
import { filterObjectsByUserType } from "../../../../../utils/userTypesUtils";

const didPressProfitAndLoss = (propertyId?: string) => {
  const params: Record<string, string> = {
    period: "this-year-to-date"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runProfitAndLossReport(params);
};

const didPressProfitAndLossDetails = (propertyId?: string) => {
  const params: GetTransactionReportQuery = {
    period: "this-year-to-date"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runProfitAndLossDetailsReport(params);
};

const didPressBalanceSheetDetails = (propertyId?: string) => {
  const params: GetTransactionReportQuery = {
    period: "this-year-to-date"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runBalanceSheetDetailsReport(params);
};

const didPressProfitAndLossByProperty = (propertyId?: string) => {
  const params: Record<string, string> = {
    groupBy: TransactionReportGroupBy.PROPERTY,
    period: "this-year-to-date"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runProfitAndLossReport(params);
};
const didPressProfitAndLossByYear = (propertyId?: string) => {
  const params: Record<string, string> = {
    groupBy: TransactionReportGroupBy.YEAR,
    period: "all-time"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runProfitAndLossReport(params);
};
const didPressProfitAndLossByMonth = (propertyId?: string) => {
  const params: Record<string, string> = {
    groupBy: TransactionReportGroupBy.MONTH,
    period: "this-year-to-date"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runProfitAndLossReport(params);
};
const didPressProfitAndLossByQuarter = (propertyId?: string) => {
  const params: Record<string, string> = {
    groupBy: TransactionReportGroupBy.QUARTER,
    period: "this-year-to-date"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  NavigationManager.runProfitAndLossReport(params);
};

const didPressBalanceSheet = (propertyId?: string) => {
  const params: Record<string, string> = {
    period: "this-year-to-date"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runBalanceSheetReport(params);
};

const didPressBalanceSheetByMonth = (propertyId?: string) => {
  const params: Record<string, string> = {
    groupBy: TransactionReportGroupBy.MONTH,
    period: "this-year-to-date"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  NavigationManager.runBalanceSheetReport(params);
};

const didPressBalanceSheetByYear = (propertyId?: string) => {
  const params: Record<string, string> = {
    groupBy: TransactionReportGroupBy.YEAR,
    period: "all-time"
  };

  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  NavigationManager.runBalanceSheetReport(params);
};

const didPressBalanceSheetByQuarter = (propertyId?: string) => {
  const params: Record<string, string> = {
    groupBy: TransactionReportGroupBy.QUARTER,
    period: "this-year-to-date"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  NavigationManager.runBalanceSheetReport(params);
};

const didPressBalanceSheetByProperty = (propertyId?: string) => {
  const params: Record<string, string> = {
    groupBy: TransactionReportGroupBy.PROPERTY,
    period: "this-year-to-date"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  NavigationManager.runBalanceSheetReport(params);
};

const didPressCashFlowStatement = (propertyId?: string) => {
  const params: Record<string, string> = {
    period: "this-month"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runCashFlowStatementReport(params);
};

const didPressCashFlowStatementByMonth = (propertyId?: string) => {
  const params: Record<string, string> = {
    groupBy: TransactionReportGroupBy.MONTH,
    period: "this-year-to-date"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runCashFlowStatementReport(params);
};

const didPressOwnerStatement = (propertyId?: string) => {
  const params: Record<string, string> = {
    groupBy: TransactionReportGroupBy.PROPERTY,
    period: "last-month",
    filter_report_type: ReportType.ALL
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runOwnerStatementReport(params);
};

const didPressPropertyReserveFunds = () => {
  NavigationManager.runPropertyReserveFundsReport();
};

const didPressManagementFees = () => {
  analyticsService.track(SegmentEventTypes.REPORTS_MANAGEMENT_FEES_CLICKED);
  NavigationManager.runManagementFeesReport();
};

const didPressCashFlowStatementByProperty = (propertyId?: string) => {
  const params: Record<string, string> = {
    groupBy: TransactionReportGroupBy.PROPERTY,
    period: "this-month"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runCashFlowStatementReport(params);
};

const didPressRentRoll = (propertyId?: string) => {
  const params: Record<string, string> = {
    groupBy: "property"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runRentRollReport(params);
};
const didPressRentRollSummaryByBedsAndBaths = (propertyId?: string) => {
  const params: Record<string, string> = {};
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runRentRollSummaryByBedsAndBathsReport(params);
};

const didPressRentRollSummaryByProperty = (propertyId?: string) => {
  const params: Record<string, string> = {};
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runRentRollSummaryByPropertyReport(params);
};

const items: ReportScreenItemType[] = [
  {
    description: AppStrings.Reports.ReportsScreen.CashFlowStatementByProperty,
    onPress: didPressCashFlowStatementByProperty,
    permission: ReportPermission.cashFlowStatement,
    id: ReportId.CASH_FLOW,
    hiddenForUserTypes: ["HOAUser"],
    dataCy: DataCy.reports.screenItems.businessOverview.cashFlowStatementByProperty
  },
  {
    description: AppStrings.Reports.ReportsScreen.ProfitAndLoss,
    onPress: didPressProfitAndLoss,
    permission: ReportPermission.profitAndLoss,
    id: ReportId.PROFIT_AND_LOST,
    dataCy: DataCy.reports.screenItems.businessOverview.profitAndLoss
  },
  {
    description: AppStrings.Reports.ReportsScreen.ProfitAndLossDetails,
    onPress: didPressProfitAndLossDetails,
    permission: ReportPermission.transactionsDetails,
    id: ReportId.PROFIT_AND_LOST_DETAILS,
    dataCy: DataCy.reports.screenItems.businessOverview.profitAndLossDetails
  },
  {
    description: AppStrings.Reports.ReportsScreen.ProfitAndLossByMonth,
    onPress: didPressProfitAndLossByMonth,
    permission: ReportPermission.profitAndLoss,
    id: ReportId.PROFIT_AND_LOST_BY_MONTH,
    dataCy: DataCy.reports.screenItems.businessOverview.profitAndLossByMonth
  },
  {
    description: AppStrings.Reports.ReportsScreen.ProfitAndLossByProperty,
    onPress: didPressProfitAndLossByProperty,
    permission: ReportPermission.profitAndLoss,
    id: ReportId.PROFIT_AND_LOST_BY_PROPERTY,
    dataCy: DataCy.reports.screenItems.businessOverview.profitAndLossByProperty
  },
  {
    description: AppStrings.Reports.ReportsScreen.ProfitAndLossByQuarter,
    onPress: didPressProfitAndLossByQuarter,
    permission: ReportPermission.profitAndLoss,
    id: ReportId.PROFIT_AND_LOST_BY_QUARTER,
    dataCy: DataCy.reports.screenItems.businessOverview.profitAndLossByQuarter
  },
  {
    description: AppStrings.Reports.ReportsScreen.ProfitAndLossByYear,
    onPress: didPressProfitAndLossByYear,
    permission: ReportPermission.profitAndLoss,
    id: ReportId.PROFIT_AND_LOST_BY_YEAR,
    dataCy: DataCy.reports.screenItems.businessOverview.profitAndLossByYear
  },
  {
    description: AppStrings.Reports.ReportsScreen.RentRoll,
    onPress: didPressRentRoll,
    permission: ReportPermission.rentRoll,
    id: ReportId.RENT_ROLL,
    hiddenForUserTypes: ["HOAUser"],
    dataCy: DataCy.reports.screenItems.businessOverview.rentRoll
  },
  {
    description: AppStrings.Reports.ReportsScreen.RentRollSummaryByBedsAndBaths,
    onPress: didPressRentRollSummaryByBedsAndBaths,
    permission: ReportPermission.rentRoll,
    id: ReportId.RENT_ROLL_SUMMARY_BY_BEDS_BATHS,
    hiddenForUserTypes: ["HOAUser"],
    dataCy: DataCy.reports.screenItems.businessOverview.rentRollSummaryByBedsBaths
  },
  {
    description: AppStrings.Reports.ReportsScreen.RentRollSummaryByProperty,
    onPress: didPressRentRollSummaryByProperty,
    permission: ReportPermission.rentRoll,
    id: ReportId.RENT_ROLL_SUMMARY_BY_PROPERTY,
    hiddenForUserTypes: ["HOAUser"],
    dataCy: DataCy.reports.screenItems.businessOverview.rentRollSummaryByProperty
  },
  {
    description: AppStrings.Reports.ReportsScreen.BalanceSheet,
    onPress: didPressBalanceSheet,
    permission: ReportPermission.balanceSheet,
    id: ReportId.BALANCE_SHEET,
    dataCy: DataCy.reports.screenItems.businessOverview.balanceSheet
  },
  {
    description: AppStrings.Reports.ReportsScreen.BalanceSheetDetails,
    onPress: didPressBalanceSheetDetails,
    permission: ReportPermission.transactionsDetails,
    id: ReportId.BALANCE_SHEET_DETAILS,
    dataCy: DataCy.reports.screenItems.businessOverview.balanceSheetDetails
  },
  {
    description: AppStrings.Reports.ReportsScreen.BalanceSheetByMonth,
    onPress: didPressBalanceSheetByMonth,
    permission: ReportPermission.balanceSheet,
    id: ReportId.BALANCE_SHEET_BY_MONTH,
    dataCy: DataCy.reports.screenItems.businessOverview.balanceSheetByMonth
  },
  {
    description: AppStrings.Reports.ReportsScreen.BalanceSheetByProperty,
    onPress: didPressBalanceSheetByProperty,
    permission: ReportPermission.balanceSheet,
    id: ReportId.BALANCE_SHEET_BY_PROPERTY,
    dataCy: DataCy.reports.screenItems.businessOverview.balanceSheetByProperty
  },
  {
    description: AppStrings.Reports.ReportsScreen.BalanceSheetByQuarter,
    onPress: didPressBalanceSheetByQuarter,
    permission: ReportPermission.balanceSheet,
    id: ReportId.BALANCE_SHEET_BY_QUARTER,
    dataCy: DataCy.reports.screenItems.businessOverview.balanceSheetByQuarter
  },
  {
    description: AppStrings.Reports.ReportsScreen.BalanceSheetByYear,
    onPress: didPressBalanceSheetByYear,
    permission: ReportPermission.balanceSheet,
    id: ReportId.BALANCE_SHEET_BY_YEAR,
    dataCy: DataCy.reports.screenItems.businessOverview.balanceSheetByYear
  },
  {
    description: AppStrings.Reports.ReportsScreen.CashFlowStatement,
    onPress: didPressCashFlowStatement,
    permission: ReportPermission.cashFlowStatement,
    id: ReportId.CASH_FLOW_STATEMENT,
    dataCy: DataCy.reports.screenItems.businessOverview.cashFlowStatement
  },
  {
    description: AppStrings.Reports.ReportsScreen.CashFlowStatementByMonth,
    onPress: didPressCashFlowStatementByMonth,
    permission: ReportPermission.cashFlowStatement,
    id: ReportId.CASH_FLOW_STATEMENT_BY_MONTH,
    dataCy: DataCy.reports.screenItems.businessOverview.cashFlowStatementByMonth
  },
  {
    description: AppStrings.Reports.ReportsScreen.OwnerStatement,
    onPress: didPressOwnerStatement,
    permission: ReportPermission.ownerStatement,
    id: ReportId.OWNER_STATEMENT,
    hiddenForUserTypes: ["HOAUser"],
    dataCy: DataCy.reports.screenItems.businessOverview.ownerStatement
  },
  {
    description: AppStrings.Reports.ReportsScreen.PropertyReserveFunds,
    onPress: didPressPropertyReserveFunds,
    permission: ReportPermission.propertyReserveFunds,
    id: ReportId.PROPERTY_RESERVE_FUNDS,
    dataCy: DataCy.reports.screenItems.businessOverview.propertyReserveFunds
  },
  {
    description: AppStrings.Reports.ReportsScreen.ManagementFeesReport,
    onPress: didPressManagementFees,
    permission: ReportPermission.managementFees,
    id: ReportId.MANAGEMENT_FEES,
    dataCy: DataCy.reports.screenItems.businessOverview.managementFeesSettingsReport
  }
];

const getBusinessOverviewItems = (isHOAUser: boolean): ReportScreenItemType[] => {
  if (isHOAUser) {
    return filterObjectsByUserType(items, "HOAUser");
  }

  return items;
};

export default getBusinessOverviewItems;
