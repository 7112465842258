import { useStylesContainer, useStylesInput } from "DLUI/form/switchButton/base/styles";

import clsx from "clsx";
import type { ToggleInputSize } from "DLUI/form/types/toggleInputBaseTypes";
import type { LabelPlacementPosition } from "DLUI/form/label/inputLabel";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";

interface Props {
  size?: ToggleInputSize;
  labelPlacement?: LabelPlacementPosition;
}

export const useStylesSwitchButton = ({ size, labelPlacement = "end" }: Props) => {
  const classesContainer = useStylesContainer();
  const classesInput = useStylesInput();

  const { isTabletOrMobile } = useResponsiveHelper();
  const calculatedSize = size || (isTabletOrMobile ? "large" : "medium");

  return {
    classNameContainer: clsx([
      classesContainer.root,
      classesContainer[calculatedSize],
      classesContainer[labelPlacement]
    ]),
    classNameInput: {
      root: clsx([classesInput.root, classesInput[calculatedSize], classesInput[labelPlacement]]),
      switchBase: classesInput.switchBase,
      thumb: classesInput.thumb,
      track: classesInput.track,
      checked: classesInput.checked
    }
  };
};
