import type { GetAllAccountsQuery } from "@doorloop/dto";
import { PaymentMethodNoEpay } from "@doorloop/dto";
import type { FC } from "react";
import React from "react";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { View } from "DLUI/view";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";
import { propertiesApi } from "api/propertiesApi";
import AppStrings from "locale/keys";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import { TextField } from "DLUI/form";
import { ViewOnlyInput } from "DLUI/form";
import { Select } from "DLUI/form";
import { useFormikContext } from "formik";
import { getIn } from "formik";
import { FastField } from "formik";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";

interface FormFieldsProps {
  didChangeProperty: (event: any, value: any) => void;
  propertyQueryParams: any;
  propertyDefaultValue: string | undefined;
  queryParams: GetAllAccountsQuery;
  accountDefaultValue: string | undefined;
  didChangePaymentMethod: (nextValue: string) => void;
  showCheckNumberInput: boolean;
}

export const OwnerContributionFormFields: FC<FormFieldsProps> = ({
  propertyDefaultValue,
  accountDefaultValue,
  didChangeProperty,
  didChangePaymentMethod,
  propertyQueryParams,
  queryParams,
  showCheckNumberInput
}) => {
  const { t } = useTranslation();
  const { verticalInputPadding } = useResponsiveHelper();
  const formik = useFormikContext();
  const paymentMethod = getIn(formik.values, "paymentMethod");

  return (
    <View noWrap>
      <Grid container>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <FormikCachedAsyncAutoComplete
            uniqueIndex={"property"}
            apiHandler={propertiesApi}
            displayNameKey={"name"}
            filterFieldName={"filter_text"}
            filterFieldValue={"name"}
            selectionFields={["id", "class"]}
            onChange={didChangeProperty}
            name={"property"}
            queryParams={{ ...propertyQueryParams, filter_active: true }}
            required
            defaultValue={propertyDefaultValue}
            label={t(AppStrings.Common.FilterOptions.Property)}
            marginTop={20}
            paddingRight={verticalInputPadding}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <View noWrap paddingRight={verticalInputPadding} paddingLeft={verticalInputPadding}>
            <BankAccountFormikAutoCompleteField
              uniqueIndex={"depositToAccount"}
              name={"depositToAccount"}
              queryParams={queryParams}
              defaultValue={accountDefaultValue}
              label={t(AppStrings.Leases.LeaseTransaction.Payment.DepositAccount)}
              marginTop={20}
            />
          </View>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <View noWrap paddingLeft={verticalInputPadding}>
            <FastField
              component={TextField}
              name={"amountReceived"}
              required
              formatType={"currency"}
              label={t(AppStrings.Owners.OwnerContribution.ContributionAmount)}
              marginTop={20}
            />
          </View>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <View noWrap paddingRight={verticalInputPadding}>
            <ViewOnlyInput
              label={t(AppStrings.Common.Settings.LateFees.Account)}
              value={t(AppStrings.Owners.OwnerContribution.OwnerContributions)}
              fullWidth
              marginTop={20}
            />
          </View>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <View noWrap paddingRight={verticalInputPadding} paddingLeft={verticalInputPadding}>
            <FastField
              key={paymentMethod}
              component={Select}
              name={`paymentMethod`}
              label={AppStrings.Leases.LeaseTransaction.Payment.PaymentMethod}
              required
              uniqueKey={"paymentMethod"}
              selectionEnum={PaymentMethodNoEpay}
              onChange={didChangePaymentMethod}
              translationKey={"paymentMethod"}
              marginTop={20}
            />
          </View>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          {showCheckNumberInput ? (
            <View noWrap paddingLeft={verticalInputPadding}>
              <FastField
                component={TextField}
                label={t(AppStrings.Leases.LeaseTransaction.Payment.CheckNumber)}
                name={"checkInfo.checkNumber"}
                marginTop={20}
              />
            </View>
          ) : null}
        </Grid>
      </Grid>
    </View>
  );
};
