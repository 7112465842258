import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    flexBase: {
      display: "flex",
      flexWrap: "wrap",
      position: "relative",
      width: "100%"
    },

    childrenWrapper: {
      height: "100%",
      width: "100%",
      display: "flex"
    },
    animatedComponentContainer: {
      overflow: "hidden",
      height: "0px",
      width: "100%"
    },
    animatedChildrenWrapper: {
      width: "100%",
      position: "absolute",
      opacity: 0
    },
    containerHide: {
      display: "none"
    },
    containerShow: {
      display: "block"
    }
  })
);
