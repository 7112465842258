import BasicGrid from "DLUI/listItems/basicGrid";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import React from "react";
import DLButton, { DLButtonColorsEnum } from "DLUI/button/dlButton";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface Props {
  stepNumber: number;
  onStepActionButtonPress: () => void;
  actionButtonText: string;
  actionDescription: string;
}

const ConnectStepRow: React.FC<Props> = ({
  stepNumber,
  onStepActionButtonPress,
  actionButtonText,
  actionDescription
}: Props) => {
  const { isMobile } = useResponsiveHelper();
  return (
    <View marginTop={20} flexDirection={"row"}>
      <BasicGrid autoHeight md={7}>
        <View flexDirection={"row"}>
          <View
            height={40}
            width={40}
            borderRadius={20}
            backgroundColor={"secondary-gray-light"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Text color={"secondary-gray"}>{stepNumber}</Text>
          </View>
          <Text value={actionDescription} lineHeight={"24px"} marginLeft={10} fullWidth />
        </View>
      </BasicGrid>
      <BasicGrid justifyContent={isMobile ? "center" : "flex-end"} autoHeight md={5}>
        <DLButton
          onClick={onStepActionButtonPress}
          actionText={actionButtonText}
          color={DLButtonColorsEnum.SECONDARY}
          style={{ minWidth: 220, marginTop: isMobile ? 20 : 0 }}
        />
      </BasicGrid>
    </View>
  );
};

export default ConnectStepRow;
