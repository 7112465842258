"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseAccountsReceivableReportItem = void 0;
class LeaseAccountsReceivableReportItem {
    constructor(id, type, date, lines, totalAmount, runningBalance, createdAt, reference, paymentMethod, reversedPayment, ePayStatus, depositStatus, isFilesSharedWithTenants) {
        this.id = id;
        this.type = type;
        this.date = date;
        this.lines = lines;
        this.totalAmount = totalAmount;
        this.runningBalance = runningBalance;
        this.reference = reference;
        this.paymentMethod = paymentMethod;
        this.reversedPayment = reversedPayment;
        this.createdAt = createdAt;
        this.ePayStatus = ePayStatus;
        this.depositStatus = depositStatus;
        this.isFilesSharedWithTenants = isFilesSharedWithTenants;
    }
}
exports.LeaseAccountsReceivableReportItem = LeaseAccountsReceivableReportItem;
