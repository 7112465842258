import type { FilterPanelOptionItem, FilterPanelStaticOptionsFiltering } from "DLUI/screen/filterPanel/filterPanel";
import type { AutocompleteProps } from "@material-ui/lab/Autocomplete";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import TextInput from "DLUI/form/textField/textInput";
import { useSyncWithSearchParams } from "hooks/useSyncWithSearchParams";

export function useAutocompleteFilterInput<
  TQuery,
  TAutocomplete extends AutocompleteProps<FilterPanelOptionItem<TQuery>, false, boolean, false> = AutocompleteProps<
    FilterPanelOptionItem<TQuery>,
    false,
    boolean,
    false
  >
>({
  name,
  values,
  defaultValue,
  disableClearable,
  filterFieldName,
  disabled,
  dataCy,
  inputProps = {}
}: Pick<
  FilterPanelStaticOptionsFiltering<TQuery>,
  "name" | "values" | "defaultValue" | "disableClearable" | "disabled" | "inputProps" | "filterFieldName" | "dataCy"
>): TAutocomplete {
  const { t } = useTranslation();
  const key = filterFieldName || values[0].filterFieldName;
  const { queryValue, onValueChange } = useSyncWithSearchParams(key as string, defaultValue as string);
  const value = useMemo(() => {
    if (!queryValue) return null;

    return values.find(({ value }) => value === queryValue) ?? null;
  }, [queryValue, values]);

  const onChange = useCallback<NonNullable<TAutocomplete["onChange"]>>(
    (_, value) => {
      onValueChange(value?.value as string);
    },
    [onValueChange]
  );

  return useMemo<TAutocomplete>(
    () =>
      ({
        renderInput: (params) => <TextInput label={t(name)} dataCy={dataCy} {...params} {...inputProps} />,
        onChange,
        options: values,
        value: value ?? null,
        disableClearable,
        getOptionLabel: (option) => t(option.displayName),
        getOptionSelected: (option, selectedItem) => option.value === selectedItem.value,
        disabled,
        classes: { popper: "FilterPanel" },
        className: disableClearable ? "disableClearable" : undefined
      }) as TAutocomplete,
    [onChange, values, value, disableClearable, disabled, t, name, dataCy, inputProps]
  );
}
