export default {
  UserRoles: "userRoles.userRoles",
  UserRole: "userRoles.userRole",
  NewUserRole: "userRoles.newUserRole",
  RoleName: "userRoles.roleName",
  Description: "userRoles.description",
  Users: "userRoles.users",
  GeneralInfo: "userRoles.generalInfo",
  Objects: "userRoles.objects",
  Reports: "userRoles.reports",
  Settings: "userRoles.settings",
  ApiKeys: "userRoles.apiKeys",
  RoleDescription: "userRoles.roleDescription",
  NameDesc: "userRoles.nameDesc",
  DescriptionDesc: "userRoles.descriptionDesc",
  GeneralInfoHeaderText: "userRoles.generalInfoHeaderText",
  ObjectsHeaderText: "userRoles.objectsHeaderText",
  ReportsHeaderText: "userRoles.reportsHeaderText",
  SettingsHeaderText: "userRoles.settingsHeaderText",
  ViewOnly: "userRoles.viewOnly",
  ViewOne: "userRoles.viewOne",
  ViewList: "userRoles.viewList",
  Create: "userRoles.create",
  Edit: "userRoles.edit",
  Delete: "userRoles.delete",
  Prospects: "userRoles.prospects",
  Owners: "userRoles.owners",
  Vendors: "userRoles.vendors",
  Tenants: "userRoles.tenants",
  Tasks: "userRoles.tasks",
  WorkOrders: "userRoles.workOrders",
  TenantRequests: "userRoles.tenantRequests",
  OwnerRequests: "userRoles.ownerRequests",
  Properties: "userRoles.properties",
  Units: "userRoles.units",
  Leases: "userRoles.leases",
  RentalApplications: "userRoles.rentalApplications",
  BudgetVsActual: "userRoles.budgetVsActual",
  LeaseCharges: "userRoles.leaseCharges",
  LeaseCredits: "userRoles.leaseCredits",
  LeasePayments: "userRoles.leasePayments",
  LeaseRefunds: "userRoles.leaseRefunds",
  VendorBills: "userRoles.vendorBills",
  VendorBillPayments: "userRoles.vendorBillPayments",
  VendorCredits: "userRoles.vendorCredits",
  OwnerContributions: "userRoles.ownerContributions",
  JournalEntries: "userRoles.journalEntries",
  BankTransfers: "userRoles.bankTransfers",
  BankDeposits: "userRoles.bankDeposits",
  Expenses: "userRoles.expenses",
  ApplicationFees: "userRoles.applicationFees",
  Accounts: "userRoles.accounts",
  BankConnectAccounts: "userRoles.bankConnectAccounts",
  BankConnectTransactions: "userRoles.bankConnectTransactions",
  MerchantAccounts: "userRoles.merchantAccounts",
  People: "userRoles.people",
  TasksAndMaintenance: "userRoles.tasksAndMaintenance",
  Rentals: "userRoles.rentals",
  Leasing: "userRoles.leasing",
  TenantTransactions: "userRoles.tenantTransactions",
  VendorTransactions: "userRoles.vendorTransactions",
  OwnerTransactions: "userRoles.ownerTransactions",
  OtherTransactions: "userRoles.otherTransactions",
  ManagementFees: "userRoles.managementFees",
  ProspectsSSNAndDLFullInfo: "userRoles.prospectsSSNAndDLFullInfo",
  GeneralObjects: "userRoles.generalObjects",
  BusinessOverview: "userRoles.businessOverview",
  Accounting: "userRoles.accounting",
  BalanceSheet: "userRoles.balanceSheet",
  CashFlowStatement: "userRoles.cashFlowStatement",
  ProfitAndLoss: "userRoles.profitAndLoss",
  Owners1099: "userRoles.owners1099",
  Vendors1099: "userRoles.vendors1099",
  RentRoll: "userRoles.rentRoll",
  OwnerStatement: "userRoles.ownerStatement",
  PropertyReserveFunds: "userRoles.propertyReserveFunds",
  ChartOfAccounts: "userRoles.chartOfAccounts",
  Banking: "userRoles.banking",
  Calendar: "userRoles.calendar",
  BankingBalanceByProperty: "userRoles.bankingBalanceByProperty",
  DepositDetail: "userRoles.depositDetail",
  PropertyAvailableFunds: "userRoles.propertyAvailableFunds",
  OwnerAvailableFunds: "userRoles.ownerAvailableFunds",
  LeaseTransactions: "userRoles.leaseTransactions",
  LeaseDeposits: "userRoles.leaseDeposits",
  TransactionsList: "userRoles.transactionsList",
  TransactionsDetails: "userRoles.transactionsDetails",
  ARReports: "userRoles.ARReports",
  APReports: "userRoles.APReports",
  RecurringTransactions: "userRoles.recurringTransactions",
  TrialBalance: "userRoles.trialBalance",
  TenantReports: "userRoles.tenantReports",
  ProspectReports: "userRoles.prospectReports",
  LeasingReports: "userRoles.leasingReports",
  VendorReports: "userRoles.vendorReports",
  TaskReports: "userRoles.taskReports",
  WorkOrderReports: "userRoles.workOrderReports",
  TenantRequestReports: "userRoles.tenantRequestReports",
  CompanySettings: "userRoles.companySettings",
  PropertySettings: "userRoles.propertySettings",
  CompanyInfo: "userRoles.companyInfo",
  RegionAndCurrency: "userRoles.regionAndCurrency",
  DefaultAccounts: "userRoles.defaultAccounts",
  TenantPortal: "userRoles.tenantPortal",
  OwnerPortal: "userRoles.ownerPortal",
  RentRemindersAndReceipts: "userRoles.rentRemindersAndReceipts",
  PaymentInstructions: "userRoles.paymentInstructions",
  LateFees: "userRoles.lateFees",
  AccessDenied: "userRoles.accessDenied",
  NoAccessSubtext: "userRoles.noAccessSubtext",
  NoAccessSubtext2: "userRoles.noAccessSubtext2",
  NoAccess: "userRoles.noAccess",
  DeleteConfirmationText: "userRoles.deleteConfirmationText",
  DeleteNoteLoadingText: "userRoles.deleteNoteLoadingText",
  DeleteNoteSuccessText: "userRoles.deleteNoteSuccessText",
  Files: "userRoles.files",
  Notes: "userRoles.notes",
  Portfolios: "userRoles.portfolios",
  Tags: "userRoles.tags",
  PrintChecks: "userRoles.printChecks",
  Communications: "userRoles.communications",
  Announcements: "userRoles.announcements",
  EsignatureRequests: "userRoles.esignatureRequests",
  EsignatureTemplates: "userRoles.esignatureTemplates",
  BankReconciliation: "userRoles.bankReconciliation"
};
