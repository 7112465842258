"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayBillsWizardStep1Dto = void 0;
const getOutstandingTransactionsForPayBills_query_1 = require("./getOutstandingTransactionsForPayBills.query");
class PayBillsWizardStep1Dto extends getOutstandingTransactionsForPayBills_query_1.GetOutstandingTransactionsForPayBillsQuery {
    constructor(vendors, properties, dueBefore, selectVendorsType, selectPropertiesType, selectBillsType) {
        super(vendors, properties, dueBefore);
        this.selectVendorsType = selectVendorsType;
        this.selectPropertiesType = selectPropertiesType;
        this.selectBillsType = selectBillsType;
    }
}
exports.PayBillsWizardStep1Dto = PayBillsWizardStep1Dto;
