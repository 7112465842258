import { useTypedTranslation } from "locale";
import { BankingScreenHeader } from "screens/transactions/components/bankingScreenHeader";
import { NavigationManager } from "utils/navigation";
import { Routes } from "components/appRouter";

export const OutgoingPaymentsScreenHeader = () => {
  const { t } = useTypedTranslation();

  return (
    <BankingScreenHeader
      actionProps={{
        text: t("accounts.chartOfAccounts.newAccount"),
        onClick: () => NavigationManager.newAccount(location.pathname)
      }}
      titleTextProps={{
        text: t("common.outgoingPayments.title"),
        href: Routes.OUTGOING_PAYMENTS
      }}
    />
  );
};
