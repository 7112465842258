import type { CustomAllocationDto, LeaseDto, UnitAllocationWithPercentageDto, UnitDto } from "@doorloop/dto";
import { AllocationStrategy, DEFAULT_PAGE_SIZE } from "@doorloop/dto";
import { Grid } from "@material-ui/core";
import { FormikCheckBox, Select, TextField, ValidationIndicator } from "DLUI/form";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import { leasesApi } from "api/leasesApi";
import { unitsApi } from "api/unitsApi";
import { BlueRefreshIcon } from "assets/.";
import { FastField, useFormikContext } from "formik";
import { useEffectAsync } from "hooks/useEffectAsync";
import AppStrings from "locale/keys";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AllocationPercentageTable } from "./allocationPercentageTable/allocationPercentageTable";
import NetworkError from "DLUI/lists/gridList/dataGrid/networkError";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { customAllocateUsingStrategy } from "./allocationUtil";
import WarningView from "DLUI/form/warningView/warningView";
import { usePrevious } from "hooks/usePrevious";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum } from "DLUI/button/dlButton";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  propertyId: string;
  onUnitAllocationsDataReceived: (unitAllocations: UnitAllocationWithPercentageDto[]) => void;
  errors: string[];
}

const buildAllocationDto = (unit: UnitDto, lease?: LeaseDto): UnitAllocationWithPercentageDto => {
  return {
    leaseId: lease?.id,
    unitId: unit.id!,
    percentage: 0.0
  };
};

export const EditCreateCustomAllocationView = ({
  propertyId,
  onUnitAllocationsDataReceived,
  errors
}: ComponentProps) => {
  const { t } = useTranslation();

  const formikContext = useFormikContext<CustomAllocationDto>();
  const { screenContainerPadding } = useResponsiveHelper();
  const [unitMap, setUnitMap] = useState<Map<string, UnitDto>>(new Map());
  const [networkError, setNetworkError] = useState(false);

  const strategy = formikContext.values.strategy ?? AllocationStrategy.MANUAL;
  const includeVacantUnits = formikContext.values.includeVacantUnits ?? false;

  // We use these to detect changes in the form since useEffects trigger unnecessarily when the form is updated
  const lastStrategy = usePrevious(strategy);
  const lastIncludeVacantUnits = usePrevious(includeVacantUnits);
  const [lastAllocationValues, setLastAllocationValues] = useState<UnitAllocationWithPercentageDto[]>([]);

  const allocationsHaveInvalidPercentages = useMemo(
    () =>
      formikContext.values.allocations.some(
        (allocation) => allocation.percentage !== undefined && isNaN(allocation.percentage)
      ),
    [formikContext.values.allocations]
  );

  const loadUnits = async (propertyId: string) => {
    try {
      setNetworkError(false);
      const { data: leases, statusCode: leasesStatusCode } = await leasesApi.getAll({
        filter_property: propertyId,
        page_size: DEFAULT_PAGE_SIZE
      });
      const { data: units, statusCode: unitsStatusCode } = await unitsApi.getAll({
        filter_property: propertyId,
        page_size: DEFAULT_PAGE_SIZE
      });

      if (leasesStatusCode !== 200 || unitsStatusCode !== 200) {
        setNetworkError(true);
        return;
      }

      if (!leases || !units) return;

      setUnitMap(new Map(units.data.map((unit) => [unit.id!, unit])));

      if (formikContext.values.id) {
        // Editing an existing allocation
        // We already have data in the form, so we don't need to build it again
        return;
      }

      /**
       * The process below is basically a SQL `join` between leases and units.
       * Since we use MongoDB, our options are limited, so we have to do it manually.
       */
      const seenUnitIds = new Set();
      const localAllocations = leases.data
        .map((leaseDto) => {
          const allLinkedUnits = leaseDto.units;
          // return an array of allocations for each unit
          return allLinkedUnits.map((unitId): UnitAllocationWithPercentageDto | undefined => {
            const unitDto = units.data.find((unit) => unit.id === unitId);

            if (!unitDto || leaseDto.status !== "ACTIVE") return;
            seenUnitIds.add(unitId);
            return buildAllocationDto(unitDto, leaseDto);
          });
        })
        .flat()
        .filter(Boolean);
      /**
       * Here we inject the Vacant units to the end of the list.
       *
       * Vacant units are units that are not linked to any lease
       * We loop over all units and check if we've already seen it before while looping over the leases
       * If we haven't, that means unit is NOT associated with any lease, so we add it to the list as a Vacant unit
       */
      units.data.forEach((unit) => {
        if (seenUnitIds.has(unit.id)) return;
        localAllocations.push(buildAllocationDto(unit, undefined));
      });

      if (localAllocations) {
        formikContext.setFieldValue("allocations", localAllocations as UnitAllocationWithPercentageDto[]);
      }
    } catch (error: unknown) {
      console.error(error);
    }
  };

  useEffectAsync(async () => {
    await loadUnits(propertyId);
  }, [propertyId, formikContext.values?.id]);

  const invalidateAllocationPercentages = () => {
    if (!allocationsHaveInvalidPercentages) {
      // preserve the last valid allocation values
      setLastAllocationValues(formikContext.values.allocations);
    }

    const invalidatedValues = formikContext.values.allocations.map((allocation) => {
      return {
        ...allocation,
        percentage: NaN
      };
    });
    formikContext.setFieldValue("allocations", invalidatedValues);
  };

  const onStrategyChanged = (strategy: AllocationStrategy) => {
    // if the strategy is not manual, we need to invalidate the percentages
    // so that the user can't submit the form with incomplete percentages
    // this forces the user to hit the "Allocate" button or change the strategy to back to manual
    if (strategy !== AllocationStrategy.MANUAL) {
      invalidateAllocationPercentages();
    } else if (lastAllocationValues.length > 0 && allocationsHaveInvalidPercentages) {
      // if the strategy is manual, restore the last valid allocation values
      formikContext.setFieldValue("allocations", lastAllocationValues);
    }
  };

  useEffect(() => {
    // Last strategy is undefined when the form is first loaded
    // we only want to trigger it when the user changes the strategy combobox
    if (strategy !== undefined && lastStrategy !== undefined && lastStrategy !== strategy) {
      onStrategyChanged(strategy);
    }
  }, [strategy]);

  useEffect(() => {
    if (
      includeVacantUnits !== undefined &&
      lastIncludeVacantUnits !== undefined &&
      lastIncludeVacantUnits !== includeVacantUnits
    ) {
      invalidateAllocationPercentages();
    }
  }, [includeVacantUnits]);

  const onAllocateButtonClicked = () => {
    // If there are no units, we don't need to do anything
    if (!formikContext.values.allocations?.length) return;

    const newAllocations = customAllocateUsingStrategy({
      strategy,
      blankAllocations: formikContext.values.allocations,
      includeVacantUnits,
      unitMap
    });

    // new allocations should never be undefined, but just in case
    if (!newAllocations) return;

    formikContext.setFieldValue("allocations", newAllocations);
  };

  if (networkError) {
    return (
      <View alignItems="center" justifyContent="center" height="100%">
        <NetworkError
          fetchData={async () => {
            if (propertyId) {
              await loadUnits(propertyId);
            }
          }}
        />
      </View>
    );
  }

  return (
    <View
      marginBottom={20}
      marginTop={10}
      paddingRight={screenContainerPadding}
      paddingLeft={screenContainerPadding}
      height={"100%"}
      width={"100%"}
      flex={1}
      gap={20}
    >
      {/* First Row */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FastFieldSafe
            component={TextField}
            label={t(AppStrings.Allocations.AllocationName)}
            name={"allocationName"}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <FastField
            component={TextField}
            label={t(AppStrings.Allocations.AllocationDescription)}
            name={"allocationDescription"}
          />
        </Grid>
      </Grid>
      {/* Second Row */}
      <Text value={AppStrings.Allocations.AllocationSettings} fontSize={18} bold />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <FastFieldSafe
            component={Select}
            name={`strategy`}
            label={AppStrings.Allocations.UseCommonAllocation}
            required
            uniqueKey={"strategy"}
            selectionEnum={AllocationStrategy}
            translationKey={"allocationStrategy"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md container alignItems="center" justifyContent="flex-start">
          {strategy !== AllocationStrategy.MANUAL && (
            <FastFieldSafe
              component={FormikCheckBox}
              name="includeVacantUnits"
              labelText={AppStrings.Allocations.IncludeVacantUnits}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={3} container alignItems="center" justifyContent="flex-end">
          {strategy !== AllocationStrategy.MANUAL && (
            <DLButton
              disabled={!formikContext.values.allocations?.length}
              onClick={onAllocateButtonClicked}
              actionText={AppStrings.Common.Allocate}
              color={DLButtonColorsEnum.SECONDARY}
              size={DLButtonSizesEnum.LARGE}
              icons={{ start: { src: BlueRefreshIcon } }}
              style={{ minWidth: 150 }}
            />
          )}
        </Grid>
      </Grid>
      {/* Third Row - Table */}
      <AllocationPercentageTable strategy={strategy} />
      {/* Fourth Row - Errors */}
      <View flexDirection={"column"} alignItems={"center"} justifyContent={"center"} gap={5}>
        {errors.map((error) => (
          <ValidationIndicator
            key={"ver_" + error}
            shouldShow={true}
            maxWidth={700}
            justifyContent={"center"}
            displayText={t(error)}
          />
        ))}
        {allocationsHaveInvalidPercentages && (
          <WarningView renderContent={() => <Text value={AppStrings.Allocations.Errors.InvalidPercentagesExist} />} />
        )}
      </View>
    </View>
  );
};
