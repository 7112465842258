"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LateFeeChargeDto = void 0;
const class_validator_1 = require("class-validator");
const lateFeeSettingsFeeType_enum_1 = require("./lateFeeSettingsFeeType.enum");
const lateFeeSettingsFrequency_enum_1 = require("./lateFeeSettingsFrequency.enum");
class LateFeeChargeDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.LateFeeChargeDto = LateFeeChargeDto;
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], LateFeeChargeDto.prototype, "account", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(255),
    __metadata("design:type", String)
], LateFeeChargeDto.prototype, "memo", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.feeType !== lateFeeSettingsFeeType_enum_1.LateFeePolicyFeeType.FIXED_AMOUNT),
    (0, class_validator_1.IsNumber)({ maxDecimalPlaces: 6 }),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", Number)
], LateFeeChargeDto.prototype, "percentage", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.feeType === lateFeeSettingsFeeType_enum_1.LateFeePolicyFeeType.FIXED_AMOUNT),
    (0, class_validator_1.IsNumber)({ maxDecimalPlaces: 6 }),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", Number)
], LateFeeChargeDto.prototype, "amount", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsNumber)({ maxDecimalPlaces: 0 }),
    (0, class_validator_1.Min)(1),
    __metadata("design:type", Number)
], LateFeeChargeDto.prototype, "daysAfterDueDateToChargeLateFees", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(lateFeeSettingsFrequency_enum_1.LateFeePolicyFrequency),
    __metadata("design:type", String)
], LateFeeChargeDto.prototype, "frequency", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(lateFeeSettingsFeeType_enum_1.LateFeePolicyFeeType),
    __metadata("design:type", String)
], LateFeeChargeDto.prototype, "feeType", void 0);
