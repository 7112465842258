"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalApplicationReportStatus = exports.RentalApplicationStatus = exports.RentalApplicationDecisionStatus = void 0;
var RentalApplicationDecisionStatus;
(function (RentalApplicationDecisionStatus) {
    RentalApplicationDecisionStatus["NOT_STARTED"] = "NOT_STARTED";
    RentalApplicationDecisionStatus["UNDECIDED"] = "UNDECIDED";
    RentalApplicationDecisionStatus["APPROVED"] = "APPROVED";
    RentalApplicationDecisionStatus["REJECTED"] = "REJECTED";
    RentalApplicationDecisionStatus["IGNORED"] = "IGNORED";
})(RentalApplicationDecisionStatus || (exports.RentalApplicationDecisionStatus = RentalApplicationDecisionStatus = {}));
var RentalApplicationStatus;
(function (RentalApplicationStatus) {
    RentalApplicationStatus["NOT_SENT"] = "NOT_SENT";
    RentalApplicationStatus["SENT"] = "SENT";
    RentalApplicationStatus["SENDING_FAILED"] = "SENDING_FAILED";
    RentalApplicationStatus["SUBMITTED"] = "SUBMITTED";
})(RentalApplicationStatus || (exports.RentalApplicationStatus = RentalApplicationStatus = {}));
var RentalApplicationReportStatus;
(function (RentalApplicationReportStatus) {
    RentalApplicationReportStatus["PENDING_REPORT"] = "PENDING_REPORT";
    RentalApplicationReportStatus["IDENTITY_VERIFICATION_FAILED"] = "IDENTITY_VERIFICATION_FAILED";
    RentalApplicationReportStatus["REPORT_AVAILABLE"] = "REPORT_AVAILABLE";
    RentalApplicationReportStatus["READY_FOR_REPORT_REQUEST"] = "ReadyForReportRequest";
})(RentalApplicationReportStatus || (exports.RentalApplicationReportStatus = RentalApplicationReportStatus = {}));
