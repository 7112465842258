import DLButton from "DLUI/button/dlButton";

import { useResponsiveHelper } from "@/contexts/responsiveContext";
import { useTypedTranslation } from "@/locale";
import { IconDown } from "assets/icons";
import { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "../../../button/dlButton/enums";

import type { CSSProperties } from "react";

const styles: CSSProperties = {
  display: "flex",
  justifyContent: "center",
  padding: "12px 8px"
};

export function ShowMoreResults({
  isFetching,
  fetchNextPage,
  hasNextPage
}: {
  isFetching: boolean;
  fetchNextPage: () => void;
  hasNextPage?: boolean;
}) {
  const { t } = useTypedTranslation();
  const { isMobile } = useResponsiveHelper();

  const buttonStyle: CSSProperties = {
    minWidth: isMobile ? 180 : 140
  };

  const buttonIcons = { end: { src: hasNextPage ? IconDown : undefined } };

  // We use a non-existent Mui-compliant class `MuiAutocomplete-groupShowMoreResults`
  // to easily identify this element in virtualization.
  return (
    <div className="MuiAutocomplete-groupShowMoreResults" style={styles}>
      <DLButton
        actionText={t(hasNextPage ? "common.autoComplete.showMoreResults" : "common.autoComplete.endOfResults")}
        disabled={isFetching || !hasNextPage}
        variant={DLButtonVariantsEnum.TEXT}
        color={DLButtonColorsEnum.SECONDARY}
        size={isMobile ? DLButtonSizesEnum.MEDIUM : DLButtonSizesEnum.SMALL}
        style={buttonStyle}
        icons={buttonIcons}
        onClick={() => {
          fetchNextPage();
        }}
        tabIndex={0}
        disableMobileCollapse
      />
    </div>
  );
}
