import { makeStyles } from "@material-ui/styles";

import type { Theme } from "styles/defaultTheme";
import type { ToastSeverity } from "store/toast/types";

import { ToastSizesEnum } from "./enums";

const makeStylesRoot = makeStyles<Theme, {}, "root" | ToastSeverity>((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      boxShadow: "0 4px 10px rgba(6, 66, 40, 0.1)",
      zIndex: 1000000,
      padding: 12,

      "& button.MuiIconButton-root": {
        opacity: 0.5,
        color: `${theme.newPalette.text.primary} !important`
      },

      "& .MuiAlert-message": {
        padding: 0,
        width: "100%"
      },

      "& .MuiAlert-icon": {
        padding: 0
      }
    },
    error: {
      backgroundColor: theme.newPalette.error.main
    },
    neutral: {
      backgroundColor: theme.newPalette.info.main
    },
    warning: {
      backgroundColor: theme.newPalette.warning.main
    },
    success: {
      backgroundColor: theme.newPalette.success.main
    }
  };
});

const makeStylesContentContainer = makeStyles<Theme, {}, ToastSizesEnum>({
  [ToastSizesEnum.MOBILE]: {
    display: "flex",
    flexDirection: "column",
    gap: 6
  },
  [ToastSizesEnum.WEB]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 64,
    maxWidth: 386
  }
});

const makeStylesText = makeStyles<Theme, {}, "action" | ToastSeverity | ToastSizesEnum>((theme) => {
  return {
    action: {
      textDecoration: "underline !important",

      "&:hover": {
        fontWeight: "bold !important",
        cursor: "pointer !important"
      }
    },
    [ToastSizesEnum.MOBILE]: {
      fontSize: 16
    },
    [ToastSizesEnum.WEB]: {
      fontSize: 14
    },
    error: {
      color: theme.newPalette.error.contrastText
    },
    neutral: {
      color: theme.newPalette.info.contrastText
    },
    warning: {
      color: theme.newPalette.text.primary
    },
    success: {
      color: theme.newPalette.success.contrastText
    }
  };
});

const makeStylesIcon = makeStyles<Theme, {}, ToastSeverity | ToastSizesEnum>((theme) => {
  return {
    [ToastSizesEnum.MOBILE]: {
      height: 30,
      width: 30
    },
    [ToastSizesEnum.WEB]: {
      height: 20,
      width: 20
    },
    error: {
      color: theme.newPalette.error.contrastText,

      "& path": {
        fill: `${theme.newPalette.error.contrastText} !important`
      }
    },
    neutral: {
      color: theme.newPalette.info.contrastText,

      "& path": {
        fill: `${theme.newPalette.info.contrastText} !important`
      }
    },
    warning: {
      color: theme.newPalette.text.primary,

      "& path": {
        fill: `${theme.newPalette.text.primary} !important`
      }
    },
    success: {
      color: theme.newPalette.success.contrastText,

      "& path": {
        fill: `${theme.newPalette.success.contrastText} !important`
      }
    }
  };
});

export { makeStylesRoot, makeStylesContentContainer, makeStylesText, makeStylesIcon };
