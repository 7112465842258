"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfitAndLossReportRootAccounts = void 0;
var ProfitAndLossReportRootAccounts;
(function (ProfitAndLossReportRootAccounts) {
    ProfitAndLossReportRootAccounts["INCOME"] = "INCOME";
    ProfitAndLossReportRootAccounts["COST_OF_GOODS_SOLD"] = "COST_OF_GOODS_SOLD";
    ProfitAndLossReportRootAccounts["GROSS_PROFIT"] = "GROSS_PROFIT";
    ProfitAndLossReportRootAccounts["EXPENSES"] = "EXPENSES";
    ProfitAndLossReportRootAccounts["NET_OPERATING_INCOME"] = "NET_OPERATING_INCOME";
    ProfitAndLossReportRootAccounts["OTHER_INCOME"] = "OTHER_INCOME";
    ProfitAndLossReportRootAccounts["OTHER_EXPENSES"] = "OTHER_EXPENSES";
    ProfitAndLossReportRootAccounts["NET_OTHER_INCOME"] = "NET_OTHER_INCOME";
    ProfitAndLossReportRootAccounts["NET_INCOME"] = "NET_INCOME";
})(ProfitAndLossReportRootAccounts || (exports.ProfitAndLossReportRootAccounts = ProfitAndLossReportRootAccounts = {}));
