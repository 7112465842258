import { View } from "DLUI/view";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";

export const NotificationItemSkeleton = () => (
  <View gap={10}>
    <View flexDirection={"row"} noWrap gap={10} paddingLeft={20} paddingRight={20}>
      <Skeleton width={"40px"} height={"40px"} variant="rect" />
      <View width={"75%"}>
        <Skeleton width={"80%"} variant="text" />
        <Skeleton width={"80%"} variant="text" />
        <Skeleton width={"40%"} variant="text" />
        <Skeleton width={"60%"} variant="text" />
      </View>
      <View width={"8%"}>
        <Skeleton width={"100%"} variant="text" />
      </View>
    </View>
    <View paddingRight={20} paddingLeft={20}>
      <HorizontalSeparationLine height={2} marginTop={5} marginBottom={5} />
    </View>
  </View>
);
