"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionLoginResponseDto = exports.CurrentTenantPortalUnitDto = exports.CurrentTenantPortalPropertyDto = exports.CurrentOwnerPortalSettingsDto = exports.CurrentTenantPortalLeaseDto = exports.CurrentDbTenantDto = exports.UserFeaturesDto = exports.LoginResponseDto = exports.AuthResponseDto = exports.LoginResponseCookieDto = exports.JWTSecureType = exports.LoginResponseType = void 0;
var LoginResponseType;
(function (LoginResponseType) {
    LoginResponseType["USER"] = "USER";
    LoginResponseType["TENANT"] = "TENANT";
    LoginResponseType["OWNER"] = "OWNER";
    LoginResponseType["PROSPECT"] = "PROSPECT";
    LoginResponseType["API"] = "API";
})(LoginResponseType || (exports.LoginResponseType = LoginResponseType = {}));
var JWTSecureType;
(function (JWTSecureType) {
    JWTSecureType["RENTAL_APPLICATION"] = "RENTAL_APPLICATION";
    JWTSecureType["LOGIN_ID"] = "LOGIN_ID";
})(JWTSecureType || (exports.JWTSecureType = JWTSecureType = {}));
class LoginResponseCookieDto {
}
exports.LoginResponseCookieDto = LoginResponseCookieDto;
class AuthResponseDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.AuthResponseDto = AuthResponseDto;
class LoginResponseDto {
}
exports.LoginResponseDto = LoginResponseDto;
class UserFeaturesDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.UserFeaturesDto = UserFeaturesDto;
class CurrentDbTenantDto {
}
exports.CurrentDbTenantDto = CurrentDbTenantDto;
class CurrentTenantPortalLeaseDto {
}
exports.CurrentTenantPortalLeaseDto = CurrentTenantPortalLeaseDto;
class CurrentOwnerPortalSettingsDto {
}
exports.CurrentOwnerPortalSettingsDto = CurrentOwnerPortalSettingsDto;
class CurrentTenantPortalPropertyDto {
}
exports.CurrentTenantPortalPropertyDto = CurrentTenantPortalPropertyDto;
class CurrentTenantPortalUnitDto {
}
exports.CurrentTenantPortalUnitDto = CurrentTenantPortalUnitDto;
class SubscriptionLoginResponseDto {
}
exports.SubscriptionLoginResponseDto = SubscriptionLoginResponseDto;
