export default {
  Screen: {
    CopyCompanyLink: "prospects.screen.copyCompanyLink",
    Assigned: "prospects.screen.assigned",
    Prospect: "prospects.screen.prospect",
    Prospects: "prospects.screen.prospects",
    AddNewProspect: "prospects.screen.addNewProspect",
    SearchPlaceHolder: "prospects.screen.searchPlaceHolder",
    Unassigned: "prospects.screen.unassigned",
    NoOpenTasks: "prospects.screen.noOpenTasks",
    DeleteConfirmationText: "prospects.screen.deleteConfirmationText",
    DeleteLoadingText: "prospects.screen.deleteLoadingText",
    DeleteProspectSuccessText: "prospects.screen.deleteProspectSuccessText",
    EmptydataViewInstructionsText: "prospects.screen.emptydataViewInstructionsText",
    NewApplication: "prospects.screen.newApplication",
    SendRentalApplication: "prospects.screen.sendRentalApplication",
    ViewRentalApplication: "prospects.screen.viewRentalApplication",
    ViewRentalApplications: "prospects.screen.viewRentalApplications",
    CreateRentalApplication: "prospects.screen.createRentalApplication",
    CreateRentalApplicationTooltip: "prospects.screen.createRentalApplicationTooltip",
    LookingFor: "prospects.screen.lookingFor",
    MinBeds: "prospects.screen.minBeds",
    MinBaths: "prospects.screen.minBaths",
    MaxRent: "prospects.screen.maxRent",
    HelpPanel: {
      Overview: "prospects.screen.helpPanel.overview",
      Description: "prospects.screen.helpPanel.description",
      CreateProspect: "prospects.screen.helpPanel.createProspect",
      TrackProspects: "prospects.screen.helpPanel.trackProspects",
      ScreenProspects: "prospects.screen.helpPanel.screenProspects"
    }
  },
  ProspectStatus: {
    ApplicationSent: "prospects.prospectStatus.applicationSent",
    ApplicationSubmitted: "prospects.prospectStatus.applicationSubmitted",
    ApplicationInProgress: "prospects.prospectStatus.applicationInProgress",
    LeaseOfferMade: "prospects.prospectStatus.leaseOfferMade",
    LeaseSentForSignature: "prospects.prospectStatus.leaseSentForSignature",
    New: "prospects.prospectStatus.new",
    ShowingCompleted: "prospects.prospectStatus.showingCompleted",
    ShowingScheduled: "prospects.prospectStatus.showingScheduled",
    ContactAttempted: "prospects.prospectStatus.contactAttempted",
    ContactMade: "prospects.prospectStatus.contactMade",
    ClosedLost: "prospects.prospectStatus.closedLost",
    ClosedMoveIn: "prospects.prospectStatus.closedMoveIn",
    LeaseOfferAccepted: "prospects.prospectStatus.leaseOfferAccepted"
  },
  NewProspect: {
    LoadingText: "prospects.newProspect.loadingText",
    ErrorText: "prospects.newProspect.errorText",
    SuccessText: "prospects.newProspect.successText",
    MarketingProfile: "prospects.newProspect.marketingProfile",
    EditProspect: "prospects.newProspect.editProspect",
    LeadStatus: "prospects.newProspect.leadStatus",
    MonthlyIncome: "prospects.newProspect.monthlyIncome",
    LeadSource: "prospects.newProspect.leadSource",
    LeadMedium: "prospects.newProspect.leadMedium",
    CreditScore: "prospects.newProspect.creditScore",
    SearchCriteria: "prospects.newProspect.searchCriteria",
    MinBedrooms: "prospects.newProspect.minBedrooms",
    MinBathrooms: "prospects.newProspect.minBathrooms",
    MaxRent: "prospects.newProspect.maxRent",
    MoveInDate: "prospects.newProspect.moveInDate",
    AddAnotherProperty: "prospects.newProspect.addAnotherProperty",
    NextStep: "prospects.newProspect.nextStep",
    ExpectedMoveInDate: "prospects.newProspect.expectedMoveInDate",
    MoveIn: "prospects.newProspect.moveIn",
    Interests: "prospects.newProspect.interests"
  },
  ProspectDetails: {}
};
