"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisplayAccountDto = exports.AccountDto = void 0;
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const class_validator_2 = require("class-validator");
const base_dto_1 = require("../base.dto");
const isBankAccountNumber_validator_1 = require("../common/validators/isBankAccountNumber.validator");
const isRoutingNumber_validator_1 = require("../common/validators/isRoutingNumber.validator");
const accountCashFlowActivity_enum_1 = require("./accountCashFlowActivity.enum");
const accountClass_enum_1 = require("./accountClass.enum");
const accountOpeningBalanceInfo_1 = require("./accountOpeningBalanceInfo");
const accountType_enum_1 = require("./accountType.enum");
const defaultAccountType_enum_1 = require("./defaultAccountType.enum");
class AccountDto extends base_dto_1.BaseDto {
    constructor(init) {
        super();
        this.active = true;
        this.systemAccount = false;
        Object.assign(this, init);
    }
    /**
     * Gets class from type.
     * @param type
     * @returns
     */
    static getClassFromType(type) {
        const typeSplit = type.split("_"); // Type is structured as CLASS_TYPE, so we can use a split
        const accountClass = typeSplit[0];
        const c = accountClass_enum_1.AccountClass[accountClass];
        if (!c) {
            throw new Error("Unable to cast account class from type: " + accountClass);
        }
        return c;
    }
    static getTypesFromClass(accountClass) {
        const accountTypes = [];
        switch (accountClass) {
            case accountClass_enum_1.AccountClass.ASSET: {
                accountTypes.push(accountType_enum_1.AccountType.ASSET_ACCOUNTS_RECEIVABLE, accountType_enum_1.AccountType.ASSET_BANK, accountType_enum_1.AccountType.ASSET_FIXED_ASSETS, accountType_enum_1.AccountType.ASSET_OTHER_ASSETS, accountType_enum_1.AccountType.ASSET_OTHER_CURRENT_ASSETS);
                break;
            }
            case accountClass_enum_1.AccountClass.EQUITY: {
                accountTypes.push(accountType_enum_1.AccountType.EQUITY_EQUITY);
                break;
            }
            case accountClass_enum_1.AccountClass.EXPENSE: {
                accountTypes.push(accountType_enum_1.AccountType.EXPENSE_COGS, accountType_enum_1.AccountType.EXPENSE_EXPENSE, accountType_enum_1.AccountType.EXPENSE_OTHER_EXPENSE);
                break;
            }
            case accountClass_enum_1.AccountClass.REVENUE: {
                accountTypes.push(accountType_enum_1.AccountType.REVENUE_INCOME, accountType_enum_1.AccountType.REVENUE_OTHER_INCOME);
                break;
            }
            case accountClass_enum_1.AccountClass.LIABILITY: {
                accountTypes.push(accountType_enum_1.AccountType.LIABILITY_ACCOUNTS_PAYABLE, accountType_enum_1.AccountType.LIABILITY_CREDIT_CARD, accountType_enum_1.AccountType.LIABILITY_LONG_TERM_LIABILITY, accountType_enum_1.AccountType.LIABILITY_OTHER_CURRENT_LIABILIY);
                break;
            }
        }
        return accountTypes;
    }
}
exports.AccountDto = AccountDto;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_2.IsBoolean)(),
    __metadata("design:type", Boolean)
], AccountDto.prototype, "active", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_2.IsArray)(),
    (0, class_validator_1.IsEnum)(defaultAccountType_enum_1.DefaultAccountType, { each: true }),
    __metadata("design:type", Array)
], AccountDto.prototype, "defaultAccountFor", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_2.MaxLength)(100),
    __metadata("design:type", String)
], AccountDto.prototype, "number", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_2.IsString)(),
    (0, class_validator_2.MaxLength)(1000),
    __metadata("design:type", String)
], AccountDto.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(accountType_enum_1.AccountType),
    __metadata("design:type", String)
], AccountDto.prototype, "type", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(accountCashFlowActivity_enum_1.AccountCashFlowActivity),
    __metadata("design:type", String)
], AccountDto.prototype, "cashFlowActivity", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.Length)(24, 24),
    (0, class_validator_2.IsHexadecimal)(),
    __metadata("design:type", String)
], AccountDto.prototype, "parentAccount", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_2.IsBoolean)(),
    __metadata("design:type", Boolean)
], AccountDto.prototype, "leaseChargeItem", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_2.IsBoolean)(),
    __metadata("design:type", Boolean)
], AccountDto.prototype, "leaseDepositItem", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_2.IsString)(),
    (0, class_validator_2.MaxLength)(255),
    __metadata("design:type", String)
], AccountDto.prototype, "leaseChargeMemo", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_2.IsString)(),
    (0, class_validator_2.MaxLength)(10000),
    __metadata("design:type", String)
], AccountDto.prototype, "description", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_2.ValidateNested)(),
    (0, class_transformer_1.Type)(() => accountOpeningBalanceInfo_1.AccountOpeningBalanceInfo),
    __metadata("design:type", accountOpeningBalanceInfo_1.AccountOpeningBalanceInfo)
], AccountDto.prototype, "openingBalanceInfo", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], AccountDto.prototype, "currentBalance", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_2.IsBoolean)(),
    __metadata("design:type", Boolean)
], AccountDto.prototype, "printAccountNumbersOnCheck", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_2.IsString)(),
    (0, class_validator_2.MaxLength)(100),
    __metadata("design:type", String)
], AccountDto.prototype, "bankAccountOwnerName", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_2.IsString)(),
    (0, class_validator_2.MaxLength)(500),
    __metadata("design:type", String)
], AccountDto.prototype, "bankAccountContactInfo", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_2.IsString)(),
    (0, class_validator_2.MaxLength)(100),
    __metadata("design:type", String)
], AccountDto.prototype, "bankAccountName", void 0);
__decorate([
    (0, class_validator_2.ValidateIf)((x) => x.printAccountNumbersOnCheck),
    (0, class_validator_1.IsNotEmpty)(),
    (0, isRoutingNumber_validator_1.IsRoutingNumber)({ message: "Invalid routing number" }),
    __metadata("design:type", String)
], AccountDto.prototype, "bankRoutingNumber", void 0);
__decorate([
    (0, class_validator_2.ValidateIf)((x) => x.printAccountNumbersOnCheck && !x.bankAccountNumber_encrypted),
    (0, class_validator_2.MaxLength)(15),
    (0, class_validator_2.MinLength)(5),
    (0, isBankAccountNumber_validator_1.IsBankAccountNumber)({ message: "Invalid account number" }),
    __metadata("design:type", String)
], AccountDto.prototype, "bankAccountNumber", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_2.IsString)(),
    __metadata("design:type", String)
], AccountDto.prototype, "bankAccountNumber_encrypted", void 0);
__decorate([
    (0, class_validator_2.ValidateIf)((x) => x.printAccountNumbersOnCheck),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_2.IsNumber)(),
    (0, class_validator_2.Min)(1),
    __metadata("design:type", Number)
], AccountDto.prototype, "nextCheckNumber", void 0);
class DisplayAccountDto extends AccountDto {
    constructor() {
        super();
    }
}
exports.DisplayAccountDto = DisplayAccountDto;
