import React from "react";

import _ from "lodash";
import type { FormikProps } from "formik";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { ChangeMfaDto, createValidator } from "@doorloop/dto";

import AppStrings from "../../../../locale/keys";
import DLButton, { DLButtonColorsEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import Text from "DLUI/text";
import { authApi } from "api/authApi";
import { FieldSafe } from "DLUI/fieldSafe/fieldSafe";
import { formatPhoneNumber } from "../../../../utils/formatPhoneNumber";
import { formatTime } from "../../../../utils/formatTime";
import { Icon } from "DLUI/icon";
import { OtpInput } from "DLUI/form/otpInput/otpInput";
import type { SVGIconComponent } from "../../../../assets";
import { PhoneVerificationSendCodeIcon, PhoneVerificationVerifyCodeIcon } from "../../../../assets";
import { TextField } from "DLUI/form";
import { View } from "DLUI/view";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

enum PhoneVerificationFormStep {
  SendCode,
  VerifyCode
}

const MIN_SECONDS_TO_SEND_AGAIN_VERIFICATION = 60;

export interface PhoneVerificationFormProps {
  isStepActive: boolean;
  defaultMobileNumber: string;
  onVerifiedMobileNumber: (mobileNumber: string) => void;
}

let formikGlobalRef: FormikProps<ChangeMfaDto> | null = null;

export const getFormikRef = () => formikGlobalRef;

const PhoneVerificationForm = ({
  isStepActive,
  defaultMobileNumber,
  onVerifiedMobileNumber
}: PhoneVerificationFormProps): JSX.Element => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();

  const [step, setStep] = React.useState<PhoneVerificationFormStep>(PhoneVerificationFormStep.SendCode);
  const [isLoadingSendCode, setIsLoadingSendCode] = React.useState<boolean>(false);
  const [isLoadingVerifyCode, setIsLoadingVerifyCode] = React.useState<boolean>(false);
  const [otpValue, setOtpValue] = React.useState<string>("");
  const [otpErrorMessage, setOtpErrorMessage] = React.useState<string | null>(null);
  const [remainingSeconds, setRemainingSeconds] = React.useState<number>(MIN_SECONDS_TO_SEND_AGAIN_VERIFICATION);

  const remainingSecondsIntervalRef = React.useRef<number | null>(null);

  const validateForm = createValidator(ChangeMfaDto);

  const mobileNumber = formikGlobalRef?.values.mobileNumber;

  React.useEffect(
    () => () => {
      stopCountRemainingSeconds();
    },
    []
  );

  const startCountRemainingSeconds = (): void => {
    stopCountRemainingSeconds();

    setRemainingSeconds(MIN_SECONDS_TO_SEND_AGAIN_VERIFICATION);

    // @ts-ignore
    remainingSecondsIntervalRef.current = setInterval(() => {
      setRemainingSeconds((remainingSeconds) => {
        if (remainingSeconds <= 0) {
          stopCountRemainingSeconds();
        }

        return remainingSeconds - 1;
      });
    }, 1000);
  };

  const stopCountRemainingSeconds = (): void => {
    if (remainingSecondsIntervalRef.current !== null) {
      clearInterval(remainingSecondsIntervalRef.current);
      remainingSecondsIntervalRef.current = null;
    }
  };

  const handleSendCode = async (): Promise<void> => {
    if (!isStepActive || !formikGlobalRef || isLoadingSendCode) {
      return;
    }

    if (!(await isValidForm(formikGlobalRef))) {
      return;
    }

    setIsLoadingSendCode(true);

    const changeMfaResult = await authApi.changeMfa(formikGlobalRef.values);

    if (changeMfaResult.status) {
      setStep(PhoneVerificationFormStep.VerifyCode);
      startCountRemainingSeconds();
    } else {
      formikGlobalRef.setFieldError("mobileNumber", changeMfaResult.message);
    }

    setIsLoadingSendCode(false);
  };

  const handleVerifyCode = async (totp: string): Promise<void> => {
    setIsLoadingVerifyCode(true);

    const changeMfaConfirmResult = await authApi.changeMfaConfirm({
      totp
    });

    if (changeMfaConfirmResult.status) {
      mobileNumber && onVerifiedMobileNumber(mobileNumber);

      handleEditMobileNumber();
    } else {
      setOtpErrorMessage(changeMfaConfirmResult.message);
    }

    setIsLoadingVerifyCode(false);
  };

  const handleOtpInputChange = (value: string): void => {
    setOtpValue(value);
    setOtpErrorMessage(null);
  };

  const handleEditMobileNumber = () => {
    stopCountRemainingSeconds();
    setStep(PhoneVerificationFormStep.SendCode);
    setOtpValue("");
    setOtpErrorMessage("");
  };

  const isValidForm = async (formikRef: FormikProps<ChangeMfaDto>): Promise<boolean> => {
    formikRef.setFieldTouched("mobileNumber");

    const errors = await formikRef.validateForm();

    return _.isEmpty(errors);
  };

  const renderSendCodeForm = (): JSX.Element => (
    <Formik onSubmit={() => {}} initialValues={{ mobileNumber: defaultMobileNumber }} validate={validateForm}>
      {(formik) => {
        formikGlobalRef = formik;

        return (
          <>
            <FieldSafe
              required
              label={t(AppStrings.Common.PhoneNumber)}
              name="mobileNumber"
              component={TextField}
              onSubmit={handleSendCode}
            ></FieldSafe>

            <DLButton isLoading={isLoadingSendCode} onClick={handleSendCode} actionText={t(AppStrings.Auth.SendCode)} />
          </>
        );
      }}
    </Formik>
  );

  const renderVerifyCodeForm = (): JSX.Element => (
    <>
      <OtpInput
        value={otpValue}
        onChange={handleOtpInputChange}
        onComplete={handleVerifyCode}
        errorMessage={otpErrorMessage}
      />

      <DLButton
        isLoading={isLoadingVerifyCode}
        onClick={async () => await handleVerifyCode(otpValue)}
        actionText={t(AppStrings.Auth.VerifyCode)}
      />

      <View flexDirection="row" alignItems="center" gap={2}>
        <DLButton
          disabled={remainingSeconds >= 0}
          isLoading={isLoadingSendCode}
          color={DLButtonColorsEnum.SECONDARY}
          variant={DLButtonVariantsEnum.TEXT}
          actionText={t(AppStrings.Auth.ResendCode)}
          onClick={handleSendCode}
        />
        {Boolean(remainingSeconds >= 0) && (
          <Text fontSize={14} fontWeight={400} color="secondary-gray">
            ({formatTime(remainingSeconds)} {t(AppStrings.Common.Sec)})
          </Text>
        )}
      </View>
    </>
  );

  let icon: SVGIconComponent | null = null;
  let title = "";
  let subTitle = "";
  let form: JSX.Element | null = null;

  if (step === PhoneVerificationFormStep.SendCode) {
    icon = PhoneVerificationSendCodeIcon;
    title = t(AppStrings.Auth.EnterYourPhoneNumber);
    subTitle = t(AppStrings.Auth.EnterYourPhoneNumberDescription);
    form = renderSendCodeForm();
  } else if (step === PhoneVerificationFormStep.VerifyCode) {
    icon = PhoneVerificationVerifyCodeIcon;
    title = t(AppStrings.Auth.EnterYourConfirmationCode);
    subTitle = t(AppStrings.Auth.EnterYourConfirmationCodeDescription, {
      phoneNumber: formatPhoneNumber(mobileNumber)
    });
    form = renderVerifyCodeForm();
  }

  return (
    <View>
      <View
        gap={20}
        paddingTop={40}
        paddingRight={isMobile ? 20 : 80}
        paddingBottom={isMobile ? 0 : 40}
        paddingLeft={isMobile ? 20 : 80}
      >
        {icon && <Icon width={50} height={50} Source={icon} />}

        <View gap={5}>
          <Text fontSize={16} fontWeight={700}>
            {title}
          </Text>
          <View flexDirection="row" noWrap={!isMobile} gap={2}>
            <Text fontSize={14} fontWeight={400} color="secondary-gray">
              {subTitle}
            </Text>
            {step === PhoneVerificationFormStep.VerifyCode && (
              <View onClick={handleEditMobileNumber}>
                <Text fontSize={14} fontWeight={400} color="lightBlue" style={{ cursor: "pointer" }}>
                  {t(AppStrings.Common.Edit)}
                </Text>
              </View>
            )}
          </View>
        </View>

        {form}
      </View>
    </View>
  );
};

export default PhoneVerificationForm;
