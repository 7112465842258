import type { OwnerDto, TenantDto } from "@doorloop/dto";
import { LeaseTenantStepStatus, PortalStatus, TenantType } from "@doorloop/dto";
import { useMemo } from "react";

function isTenant(tenant: TenantDto | OwnerDto): tenant is TenantDto {
  const type = (tenant as TenantDto)?.type;
  return Boolean(type && [TenantType.PROSPECT_TENANT, TenantType.LEASE_TENANT].includes(type));
}
export const usePortalStatus = (person: TenantDto | OwnerDto) => {
  const tenantsDepArray = isTenant(person) ? [person.stripeCustomerId] : [];

  const statuses = useMemo(() => {
    const portalInfo = person?.portalInfo;

    const isInvited = portalInfo?.status === PortalStatus.INVITED;
    const isActive = portalInfo?.status === PortalStatus.ACTIVE;
    const isMissingContactDetails = !portalInfo?.loginEmail && !portalInfo?.mobilePhone;

    const pendingInvite =
      (!isMissingContactDetails && !portalInfo?.status) || portalInfo?.status === PortalStatus.INACTIVE;

    const paymentSetup = isTenant(person) && isActive && Boolean(person?.stripeCustomerId);
    const isPassActive = isActive || paymentSetup;

    return {
      isInvited,
      isActive,
      isMissingContactDetails,
      pendingInvite,
      paymentSetup,
      isPassActive
    };
  }, [person?.portalInfo?.mobilePhone, person?.portalInfo?.loginEmail, person?.portalInfo?.status, ...tenantsDepArray]);

  const currentStatus = useMemo<LeaseTenantStepStatus>(() => {
    if (statuses.paymentSetup) {
      return LeaseTenantStepStatus.PAYMENT_SETUP;
    }
    if (statuses.isActive) {
      return LeaseTenantStepStatus.ACTIVE;
    }
    if (statuses.isInvited) {
      return LeaseTenantStepStatus.INVITED;
    }
    if (statuses.pendingInvite) {
      return LeaseTenantStepStatus.PENDING_INVITE;
    }
    if (statuses.isMissingContactDetails) {
      return LeaseTenantStepStatus.MISSING_CONTACT_DETAILS;
    }

    return LeaseTenantStepStatus.MISSING_CONTACT_DETAILS;
  }, [statuses]);

  return { statuses, currentStatus };
};
