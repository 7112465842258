import React, { useCallback } from "react";
import { View } from "DLUI/view";
import AppStrings from "../../../../../locale/keys";
import { FieldArray, useFormikContext } from "formik";
import type { LeaseTransactionLineBaseDto, VendorBillDto } from "@doorloop/dto";
import { ExpenseTransactionLineDto } from "@doorloop/dto";
import ExpenseTransactionLine from "DLUI/dialogs/vendor/expenseTransactionLine";
import Grid from "@material-ui/core/Grid";
import { v4 as uuid } from "uuid";
import { Button } from "DLUI/button";
import { AddIcon } from "../../../../../assets";
import { useLastArrayItemRef } from "hooks/useLastArrayItemRef";
import { ListInputsContainer } from "DLUI/listItems";

export interface BillLinesListProps {
  hasLinkedPayments?: boolean;
  accounts?: string[];
  editMode?: boolean;
}

const BillLinesList: React.FC<BillLinesListProps> = ({ hasLinkedPayments, accounts, editMode }: BillLinesListProps) => {
  const formik = useFormikContext<VendorBillDto>();
  const { setFieldValue } = formik;

  const addLine = useCallback(() => {
    const isOneVendorAccount = accounts?.length === 1;
    const lines = formik.values.lines || [];
    const previousLine = lines[lines.length - 1];

    setFieldValue("lines", [
      ...lines,
      new ExpenseTransactionLineDto({
        property: previousLine?.property,
        unit: previousLine?.unit,
        uniqueKey: uuid(),
        account: accounts && isOneVendorAccount && !editMode ? accounts[0] : undefined
      })
    ]);
  }, [accounts, editMode, formik.values.lines, setFieldValue]);

  const lastLineRef = useLastArrayItemRef<HTMLDivElement>(addLine, []);

  return (
    <View>
      <FieldArray
        name={"lines"}
        render={(arrayHelpers) => {
          const lines = formik.values.lines;
          const linesJSX = lines?.map((currentLine: LeaseTransactionLineBaseDto, lineIndex: number) => (
            <View showAnimation={"fade-in"} hideAnimation={"fade-out"} shouldShow key={currentLine.uniqueKey}>
              <ExpenseTransactionLine
                domRef={lineIndex === lines.length - 1 ? lastLineRef : undefined}
                transactionItem={currentLine}
                lineIndex={lineIndex}
                arrayHelpers={arrayHelpers}
                key={lineIndex}
                name={"lines"}
                formikRef={formik}
                viewOnly={hasLinkedPayments}
              />
            </View>
          ));

          return (
            <View>
              <ListInputsContainer>{linesJSX}</ListInputsContainer>
              {!hasLinkedPayments && (
                <View alignItems={"flex-end"} flexDirection={"row"}>
                  <Grid item lg={8} md={8} xs={8}>
                    <Button
                      color={"lightBlue"}
                      type={"inlineText"}
                      actionText={AppStrings.Leases.LeaseCharge.AddLineItem}
                      onClick={addLine}
                      LeftIcon={AddIcon}
                      iconSize={15}
                      applyColorForIcons
                    />
                  </Grid>
                </View>
              )}
            </View>
          );
        }}
      />
    </View>
  );
};

export default BillLinesList;
