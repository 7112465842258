"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VendorTransactionReportItem = void 0;
class VendorTransactionReportItem {
    constructor(id, lines, type, date, total, balance, reference) {
        this.id = id;
        this.lines = lines;
        this.type = type;
        this.date = date;
        this.total = total;
        this.balance = balance;
        this.reference = reference;
    }
}
exports.VendorTransactionReportItem = VendorTransactionReportItem;
