import type { FilterOption } from "@/components/DLUI/screen/filterPanel/filterInputs/types";
import {
  hasDefaultValue,
  isDateRangePickerFilterItem,
  isMultiSelectFilterItem
} from "@/components/common/filters/utils";
import { DateFormats, type LoginResponseDto } from "@doorloop/dto";
import { getFilterOptionKeys } from "./getFilterOptionKeys";
import { resolveDatesFromPeriodString } from "@/components/DLUI/form/datePicker/utils";

export function buildQueryFilter<TQuery>(
  filterOptions: Array<FilterOption<TQuery>>,
  searchParams: URLSearchParams,
  userData?: LoginResponseDto
): TQuery {
  return filterOptions.reduce((acc, filterOption) => {
    const filterOptionKeys = getFilterOptionKeys(filterOption);
    if (!filterOptionKeys.length) {
      return acc;
    }

    filterOptionKeys.forEach((key) => {
      const queryValue = searchParams.get(key);
      if (isDateRangePickerFilterItem(filterOption)) {
        const periodKey = filterOption.periodDateFieldKey ?? "period";
        const period = searchParams.get(periodKey) ?? filterOption.period;
        if (period === "all-time") {
          return;
        }

        const { startDate, endDate } = resolveDatesFromPeriodString(period);
        if (!startDate || !endDate) {
          return;
        }

        acc[filterOption.startDateFieldKey as string] = startDate.format(DateFormats.ISO_DATE_SERVER_FORMAT);
        acc[filterOption.endDateFieldKey as string] = endDate.format(DateFormats.ISO_DATE_SERVER_FORMAT);
        return;
      }

      if (isMultiSelectFilterItem(filterOption)) {
        acc[key] = searchParams.getAll(key);
        return;
      }

      if (!hasDefaultValue(filterOption) || queryValue) {
        if (!queryValue) return;
        acc[key] = queryValue;
        return;
      }

      if (!filterOption.defaultValue) {
        return;
      }

      if (filterOption.defaultValue !== "currentUser") {
        acc[key] = filterOption.defaultValue as string;
        return;
      }

      acc[key] = userData?.id;
    });

    return acc;
  }, {} as TQuery);
}
