import { CommunicationSubjectType } from "@doorloop/dto";

import {
  CommunicationSubjectLateFeeIcon,
  CommunicationSubjectLeaseIcon,
  CommunicationSubjectMoneyIcon,
  CommunicationSubjectNotificationIcon,
  CommunicationSubjectPortalIcon,
  CommunicationSubjectPortalSiteIcon,
  CommunicationSubjectSmsIcon,
  type SVGIconComponent
} from "@/assets";

export const communicationSubjectTypeToIconMap: Record<CommunicationSubjectType, SVGIconComponent> = {
  [CommunicationSubjectType.RENT_REMINDER]: CommunicationSubjectNotificationIcon,
  [CommunicationSubjectType.RENT_REMINDER_HOA]: CommunicationSubjectNotificationIcon,
  [CommunicationSubjectType.WELCOME_MOVE_IN_TENANT]: CommunicationSubjectSmsIcon,
  [CommunicationSubjectType.PAYMENT_RECEIVED]: CommunicationSubjectMoneyIcon,
  [CommunicationSubjectType.RENT_OVERDUE]: CommunicationSubjectMoneyIcon,
  [CommunicationSubjectType.RENT_OVERDUE_HOA]: CommunicationSubjectMoneyIcon,
  [CommunicationSubjectType.TENANT_PORTAL_INVITE]: CommunicationSubjectPortalIcon,
  [CommunicationSubjectType.TENANT_PORTAL_INVITE_HOA]: CommunicationSubjectPortalIcon,
  [CommunicationSubjectType.OWNER_PORTAL_INVITE]: CommunicationSubjectPortalIcon,
  [CommunicationSubjectType.RENTAL_APPLICATION_INVITE]: CommunicationSubjectPortalSiteIcon,
  [CommunicationSubjectType.RENT_CHARGED]: CommunicationSubjectMoneyIcon,
  [CommunicationSubjectType.RENT_CHARGED_HOA]: CommunicationSubjectMoneyIcon,
  [CommunicationSubjectType.TENANT_LEASE_REVERSED_PAYMENT]: CommunicationSubjectLeaseIcon,
  [CommunicationSubjectType.LATE_FEE]: CommunicationSubjectLateFeeIcon,
  [CommunicationSubjectType.LATE_FEE_HOA]: CommunicationSubjectLateFeeIcon,
  [CommunicationSubjectType.BEFORE_LATE_FEE]: CommunicationSubjectLateFeeIcon,
  [CommunicationSubjectType.BEFORE_LATE_FEE_HOA]: CommunicationSubjectLateFeeIcon,
  [CommunicationSubjectType.ANNOUNCEMENT]: CommunicationSubjectSmsIcon,
  [CommunicationSubjectType.WELCOME_TEXT_MESSAGE]: CommunicationSubjectSmsIcon
};
