import { Routes } from "components/appRouter";
import {
  EditBankAccountsDialog,
  EditPaymentsAllocationsDialog,
  EditPropertyLateFeesDialog,
  EditPropertyOwnersDialog,
  EditReserveFundsDialog
} from "DLUI/dialogs/settings";
import EditPaymentInstructions from "DLUI/dialogs/settings/properties/editPaymentInstructions";
import EditRentReminders from "DLUI/dialogs/settings/properties/editRentReminders";
import EditTenantRequestsNotifications from "DLUI/dialogs/settings/properties/editTenantRequestsNotifications";
import AppStrings from "locale/keys";
import { DialogRoutes } from "./dialogsRoutes";
import type { DialogProps } from "./dialogsProvider.types";
import ManagementFeesPropertyFormikContextWrapper from "DLUI/dialogs/settings/properties/managementFeesPropertyFormikContextWrapper";
import EditPaymentAllocations from "DLUI/dialogs/settings/properties/editPaymentAllocations/editPaymentAllocations";
import { EditConvenienceFees } from "DLUI/dialogs/settings/companyGlobalSettings/editConvenienceFees";

class PropertiesDialogsProvider {
  matchRoutes(pathname: string): DialogProps | undefined {
    if (pathname.indexOf(DialogRoutes.EDIT_PROPERTY_OWNERS) > -1 && pathname.indexOf(Routes.PROPERTIES) > -1) {
      return {
        type: "editPropertyOwners",
        Content: EditPropertyOwnersDialog,
        title: AppStrings.Owners.Details.PropertyOwners
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_PROPERTY) > -1 && pathname.indexOf(Routes.PROPERTIES) > -1) {
      return {
        type: "editProperty",
        title: AppStrings.Settings.GeneralSettings.CompanyInfo.GeneralInfo
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_BANK_ACCOUNTS) > -1 && pathname.indexOf(Routes.PROPERTIES) > -1) {
      return {
        type: "editBankAccounts",
        Content: EditBankAccountsDialog,
        title: AppStrings.Properties.Settings.PropertyBankAccounts
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_RESERVE_FUNDS) > -1 && pathname.indexOf(Routes.PROPERTIES) > -1) {
      return {
        type: "editReserveFunds",
        Content: EditReserveFundsDialog,
        title: AppStrings.Properties.Settings.PropertyReserveFunds
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_MANAGEMENT_FEES) > -1 && pathname.indexOf(Routes.PROPERTIES) > -1) {
      return {
        type: "editManagementFees",
        Content: ManagementFeesPropertyFormikContextWrapper,
        title: AppStrings.Properties.Settings.PropertyManagementFees
      };
    }
    if (
      pathname.indexOf(DialogRoutes.EDIT_CONVENIENCE_FEES_SETTINGS) > -1 &&
      pathname.indexOf(Routes.PROPERTIES) > -1
    ) {
      return {
        type: "editConvenienceFees",
        Content: EditConvenienceFees,
        title: AppStrings.Settings.GeneralSettings.ConvenienceFees.SettingsTitle
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_PAYMENT_ALLOCATIONS) > -1 && pathname.indexOf(Routes.PROPERTIES) > -1) {
      return {
        type: "editPaymentsAllocation",
        Content: EditPaymentAllocations,
        title: AppStrings.Settings.GeneralSettings.PaymentAllocation.PaymentAllocationSettingsTitle
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_LATE_FEES) > -1 && pathname.indexOf(Routes.PROPERTIES) > -1) {
      return {
        type: "editLateFees",
        Content: EditPropertyLateFeesDialog,
        title: AppStrings.Properties.Settings.LateFeesTitle
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_PAYMENTS_ALLOCATION) > -1 && pathname.indexOf(Routes.PROPERTIES) > -1) {
      return {
        type: "editPaymentsAllocation",
        Content: EditPaymentsAllocationsDialog,
        title: AppStrings.Properties.Settings.PropertyPaymentsAllocation
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_RENT_REMINDERS) > -1 && pathname.indexOf(Routes.PROPERTIES) > -1) {
      return {
        Content: EditRentReminders,
        title: AppStrings.Common.RentRemindersTitle
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_PAYMENT_INSTRUCTIONS) > -1 && pathname.indexOf(Routes.PROPERTIES) > -1) {
      return {
        Content: EditPaymentInstructions,
        title: AppStrings.Common.PaymentInstructions
      };
    }
    if (
      pathname.indexOf(DialogRoutes.EDIT_TENANT_REQUEST_NOTIFICATIONS) > -1 &&
      pathname.indexOf(Routes.PROPERTIES) > -1
    ) {
      return {
        Content: EditTenantRequestsNotifications,
        title: AppStrings.Common.TenantRequestsSettingItemTitle
      };
    }
    return undefined;
  }
}

export const propertiesDialogsProvider = new PropertiesDialogsProvider();
