"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESignatureRequestClientDto = exports.ESignatureRequestDto = void 0;
const class_validator_1 = require("class-validator");
const base_dto_1 = require("../base.dto");
const esignatureRelatedToType_enum_1 = require("../esignatureRequests/esignatureRelatedToType.enum");
const esignatureRequestType_enum_1 = require("../esignatureRequests/esignatureRequestType.enum");
const esignatureRequestSigner_dto_1 = require("../esignatureRequests/esignatureRequestSigner.dto");
const class_transformer_1 = require("class-transformer");
class ESignatureRequestDto extends base_dto_1.BaseDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.ESignatureRequestDto = ESignatureRequestDto;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(esignatureRelatedToType_enum_1.ESignatureRelatedToType),
    __metadata("design:type", String)
], ESignatureRequestDto.prototype, "relatedToType", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.relatedToType === esignatureRelatedToType_enum_1.ESignatureRelatedToType.PROPERTY),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], ESignatureRequestDto.prototype, "property", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.relatedToType === esignatureRelatedToType_enum_1.ESignatureRelatedToType.UNIT),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], ESignatureRequestDto.prototype, "unit", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.relatedToType === esignatureRelatedToType_enum_1.ESignatureRelatedToType.LEASE),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], ESignatureRequestDto.prototype, "lease", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.relatedToType === esignatureRelatedToType_enum_1.ESignatureRelatedToType.LEASE_DRAFT),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], ESignatureRequestDto.prototype, "leaseDraft", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(255),
    __metadata("design:type", String)
], ESignatureRequestDto.prototype, "title", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(1000),
    __metadata("design:type", String)
], ESignatureRequestDto.prototype, "description", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(esignatureRequestType_enum_1.ESignatureRequestType),
    __metadata("design:type", String)
], ESignatureRequestDto.prototype, "type", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.type === esignatureRequestType_enum_1.ESignatureRequestType.TEMPLATES),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    (0, class_validator_1.ArrayMinSize)(1),
    (0, class_validator_1.ArrayMaxSize)(5),
    __metadata("design:type", Array)
], ESignatureRequestDto.prototype, "esignatureTemplates", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => esignatureRequestSigner_dto_1.EsignatureRequestSignerDto),
    (0, class_validator_1.ArrayMinSize)(1),
    __metadata("design:type", Array)
], ESignatureRequestDto.prototype, "signers", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], ESignatureRequestDto.prototype, "setSignersOrder", void 0);
class ESignatureRequestClientDto extends ESignatureRequestDto {
}
exports.ESignatureRequestClientDto = ESignatureRequestClientDto;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], ESignatureRequestClientDto.prototype, "documentsAdded", void 0);
