import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: "12px",
      width: "100%",
      backgroundColor: "transparent",
      height: 80,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    button: {
      marginRight: theme.spacing(1)
    },
    backButton: {
      marginRight: theme.spacing(1)
    },
    completed: {
      display: "inline-block"
    },
    instructions: {},
    icon: {
      width: "15px",
      height: "15px"
    },
    iconActive: {
      width: "25px",
      height: "25px",
      zIndex: 1000
    },
    overviewContainer: {
      backgroundColor: theme.palette.white.main,
      borderRadius: 30,
      boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.07)"
    },
    stepperWrapper: {
      marginTop: 10,
      borderTopWidth: 1,
      borderTopColor: theme.palette.screenBackground.light,
      borderTopStyle: "solid"
    },
    overViewIcon: {
      width: "80px",
      height: "80px"
    },
    overViewIconActive: {
      width: "80px",
      height: "80px",
      zIndex: 1000
    },
    StatusStepperRoot: {
      padding: "0px 20px 0px 0px",
      width: "100%"
    },
    StatusStepperRootMobile: {
      padding: 0,
      width: "100%"
    },
    StatusStepperLabelMobile: {
      marginTop: "5px !important"
    }
  })
);
