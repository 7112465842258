import React from "react";
import TenantsEmptyDataView from "screens/tenants/tenantsList/tenantsEmptyDataView";
import { tenantsApi } from "api/tenantsApi";
import type { GetAllLeaseTenantsQuery, LeaseDto, TenantDto } from "@doorloop/dto";
import moment from "moment";
import CardSkeletonItem from "DLUI/skeletonItem/card/cardSkeletonItem";
import DefaultSkeletonItem from "DLUI/skeletonItem/defaultSkeletonItem";
import { DataList } from "DLUI/dataList";
import { LoadingSkeleton } from "DLUI/loadingSkeleton";

interface ComponentProps {
  didSelectTenantItem?: (selectedItem: TenantDto, itemIndex: number) => void;
  ListItem: React.ComponentType<any>;
  filterObj: GetAllLeaseTenantsQuery;
  fullWidth?: boolean;
  scrollableTarget?: string;
  listDirection?: "row" | "column";
  leaseData?: LeaseDto;
  renderListHeader?: boolean;
  onLoadingDataStateChange?: (loadingInProgress: boolean) => void;
  emptyViewInstructionsText?: string;
  showStickyHeader?: boolean;
  selectableListItem?: boolean;
  stickyHeaderId?: string;
  removeDefaultBottomPadding?: boolean;
}

const TenantsList: React.FC<ComponentProps> = ({
  didSelectTenantItem,
  ListItem,
  filterObj,
  fullWidth,
  scrollableTarget,
  leaseData,
  onLoadingDataStateChange,
  emptyViewInstructionsText,
  selectableListItem,
  showStickyHeader = true,
  stickyHeaderId,
  removeDefaultBottomPadding
}: ComponentProps) => (
  <DataList
    infinite
    restApi={tenantsApi}
    emptyListView={() => <TenantsEmptyDataView emptyViewInstructionsText={emptyViewInstructionsText} />}
    skeleton={() => <LoadingSkeleton SkeletonItem={selectableListItem ? DefaultSkeletonItem : CardSkeletonItem} />}
    queryFilter={filterObj}
    scrollElement={scrollableTarget ? document.getElementById(scrollableTarget) : undefined}
    onLoadingDataStateChange={onLoadingDataStateChange}
    paddingEnd={removeDefaultBottomPadding ? 0 : undefined}
    stickyHeaderProps={{
      id: stickyHeaderId,
      shouldShow: showStickyHeader
    }}
  >
    {(item, _, resourceId) => (
      <div style={{ width: fullWidth ? "100%" : "auto" }}>
        <ListItem
          didSelectTenantItem={didSelectTenantItem}
          moveInAt={moment(leaseData?.start)}
          tenant={item}
          resourceId={resourceId}
        />
      </div>
    )}
  </DataList>
);

export default TenantsList;
