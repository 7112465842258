import React, { useMemo, useState } from "react";
import { history } from "store/history";
import { Routes } from "../../appRouter";
import { DialogRoutes } from "DLUI/screen/dialogsProvider";
import type { PopoverItem } from "DLUI/popover";
import { Popover } from "DLUI/popover";
import { LogoutIcon, UserCircleIcon } from "../../../assets";
import AppStrings from "../../../locale/keys";
import { authApi } from "api/authApi";
import { View } from "DLUI/view";
import UserProfileImage from "DLUI/profileImage/userProfileImage";
import { TextTooltip } from "DLUI/popover/textTooltip";
import type { CustomNavComponentProps } from "DLUI/drawer/navigationItem";
import { DataCy, personUtils } from "@doorloop/dto";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import type { IRootState } from "store";
import { useSelector } from "react-redux";
import Text from "DLUI/text";

const NavigationProfileItem: React.FC<CustomNavComponentProps> = ({
  isMinimized,
  handleDrawerClose,
  iconSize,
  AvatarIcon
}) => {
  const { isTabletOrMobile } = useResponsiveHelper();
  const loginData = useSelector((state: IRootState) => state.auth.currentLoginResponse);
  const [showUserPopover, setShowUserPopover] = useState<boolean>(false);

  const handlePropOverItemClick = (
    route: DialogRoutes.EDIT_PERSONAL_INFORMATION | DialogRoutes.EDIT_LOGIN_AND_PASSWORD
  ) => {
    history.push(`${Routes.SETTINGS_GENERAL_SETTINGS}/${route}`);
    handleDrawerClose?.();
  };

  const { firstName, lastName } = useMemo(() => {
    if (loginData) {
      return {
        firstName: loginData.firstName,
        lastName: loginData.lastName,
        pictureUrl: loginData.pictureUrl
      };
    }

    return { firstName: "", lastName: "" };
  }, [loginData]);

  const profileLinkText = useMemo(() => {
    const fullName = personUtils.getFullName(firstName, undefined, lastName);
    return fullName || AppStrings.Common.PersonalSettings;
  }, [firstName, lastName]);

  const userPopoverItems: PopoverItem[] = useMemo(
    () => [
      {
        Icon: UserCircleIcon,
        title: profileLinkText,
        onClick: () => handlePropOverItemClick(DialogRoutes.EDIT_PERSONAL_INFORMATION),
        dataCy: DataCy.sideMenu.mainFooter.userProfile.personalSettings
      },
      {
        Icon: LogoutIcon,
        title: AppStrings.Common.LogOut,
        onClick: authApi.logout,
        dataCy: DataCy.sideMenu.mainFooter.userProfile.logout
      }
    ],
    [profileLinkText]
  );

  const onUserPopoverStateChange = (nextState: "open" | "close") => setShowUserPopover(nextState === "open");

  const didPressUserName = () => setShowUserPopover(true);

  return (
    <View
      height={!isTabletOrMobile ? 40 : undefined}
      justifyContent={"center"}
      alignItems={isTabletOrMobile ? "flex-start" : "center"}
      autoWidth={!isTabletOrMobile}
      fullWidth={isTabletOrMobile}
    >
      <TextTooltip
        value={profileLinkText || AppStrings.Common.Profile}
        placement={isTabletOrMobile ? "right" : "bottom"}
        show={isMinimized && !showUserPopover}
        useButton={false}
      >
        <View
          alignItems={"center"}
          flexDirection={"row"}
          paddingLeft={10}
          paddingRight={10}
          paddingBottom={10}
          paddingTop={isTabletOrMobile ? 10 : 13}
          className={"userPopoverContainer"}
          autoWidth={!isTabletOrMobile}
          fullWidth={isTabletOrMobile}
          onClick={didPressUserName}
          noWrap
          dataCy={DataCy.sideMenu.mainFooter.userProfile.icon}
        >
          <UserProfileImage
            size={iconSize ? iconSize : 22}
            borderWidth={1}
            borderColor={"white"}
            pathColor={isTabletOrMobile ? "white" : "black"}
            AvatarIcon={AvatarIcon}
          />
          {isTabletOrMobile && (
            <View
              flexDirection={"row"}
              alignItems={"center"}
              width={isMinimized ? "0" : "auto"}
              maxWidth={"90%"}
              noWrap
              overflow={"hidden"}
            >
              <Text
                marginLeft={5}
                color={"white"}
                fontSize={isTabletOrMobile ? 16 : 14}
                overFlow={"ellipsis"}
                numberOfLines={1}
              >
                {firstName} {lastName}
              </Text>
            </View>
          )}
        </View>
      </TextTooltip>
      <Popover
        hideDotsIcon
        width={220}
        topSectionItems={userPopoverItems}
        iconSize={16}
        fontSize={isTabletOrMobile ? 16 : 14}
        anchorPositionTop={isTabletOrMobile ? -1 : 50}
        shouldShow={showUserPopover}
        iconPathColor={"white"}
        onStateChange={onUserPopoverStateChange}
      />
    </View>
  );
};

export default NavigationProfileItem;
