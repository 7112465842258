import type { AxiosError, AxiosRequestConfig, Method } from "axios";
import axios from "axios";
import moment from "moment";
import Cookies from "universal-cookie";
import _ from "lodash";

import type { ApiError, LoginResponseDto, NamesDictionary } from "@doorloop/dto";
import {
  AuthResponseDto,
  GetLoginResponseForTenantPortal,
  LoginResponseCookieDto,
  LoginResponseType,
  OwnerPortalServerRoutes,
  ServerRoutes,
  TenantPortalServerRoutes
} from "@doorloop/dto";

import type { ToastSeverity } from "store/toast/types";
import { loginSuccess } from "store/auth/actions";
import type { ToastStateValid } from "store/toast/actions";
import { handleToast } from "store/toast/actions";

import { isLocalEnv, isProductionEnv } from "../utils/environmentHelper";
import { Routes } from "../components/appRouter";
import { store } from "../store";

import type { ValidationErrors } from "./apiResult";
import { ApiResult } from "./apiResult";
import type { RestApiBaseWithDictionary } from "./restApiBaseWithDictionary";
import AppStrings from "../locale/keys";
import qs from "query-string";

const DEFAULT_TIMEOUT = 1000 * 60 * 2;
export const LONG_TIMEOUT = 1000 * 60 * 10;

export interface ApiToastsProps<T> {
  severity?: ToastSeverity;
  isHidden?: boolean;
  idKey?: keyof T;
  translationKey?: string;
  action?: ToastStateValid["action"];
}

interface AxiosPost<T> {
  url: string;
  data?: any;
  options?: {};
  headers?: {};
  tokenId?: string;
  toasts?: ApiToastsProps<T>;
  shouldShowErrorToast?: boolean;
}

interface AxiosPut<T> {
  url: string;
  data?: any;
  toasts?: ApiToastsProps<T>;
  shouldShowErrorToast?: boolean;
}

interface AxiosDelete<T> {
  url: string;
  options?: {};
  toasts?: ApiToastsProps<T>;
  shouldShowErrorToast?: boolean;
}

interface AxiosToast<T> {
  url?: string;
  method?: Method;
  status: number;
  data?: T;
  toasts?: ApiToastsProps<T>;
}

class ApiHelper {
  loginResponses?: LoginResponseDto[];

  LOGIN_RESPONSES_COOKIE_NAME = "login_responses";

  cookies = new Cookies();

  private axiosToast = <T = any>({ url, method, status, data, toasts }: AxiosToast<T>) => {
    if (status === 200) {
      if (!toasts || !toasts?.isHidden) {
        // @ts-ignore
        const id = data?.[toasts?.idKey ?? "id"] as string;

        setTimeout(() => {
          store.dispatch(
            handleToast({
              endpoint: url,
              method,
              severity: toasts?.severity ?? (method === "DELETE" ? "error" : "success"),
              translationKey: toasts?.translationKey,
              action: {
                ...toasts?.action,
                props: { id, ...toasts?.action?.props }
              }
            })
          );
        }, 0);
      }
    }
  };

  private axiosToastError = <T>(apiResult: ApiResult<T>) => {
    setTimeout(() => {
      store.dispatch(
        handleToast({
          severity: "error",
          translationKey: apiResult?.message || AppStrings.Common.GeneralError
        })
      );
    }, 0);
  };

  private handleAxiosError<T>(error: AxiosError<T>, shouldShowErrorToast?: boolean): ApiResult<T> {
    const res = this.getApiResultFromAxiosError(error);

    if (shouldShowErrorToast) {
      this.axiosToastError(res);
    }

    return res;
  }

  axiosPost = async <T = any>({
    url,
    data,
    options,
    headers,
    tokenId,
    toasts,
    shouldShowErrorToast
  }: AxiosPost<T>): Promise<ApiResult<T>> => {
    if (!tokenId) {
      tokenId = this.getTokenIdForCurrentSubdomain();
    }
    try {
      const res = await axios.post<T>(url, data, {
        headers: { tokenId, ...headers },
        ...options
      });

      if (!toasts?.isHidden) {
        this.axiosToast({ status: res.status, method: "POST", url, data: res.data, toasts });
      }

      return new ApiResult<T>(res.data, res.statusText, res.status);
    } catch (e) {
      return this.handleAxiosError(e as AxiosError<T>, shouldShowErrorToast);
    }
  };

  axiosPut = async <T = any>({ url, data, toasts, shouldShowErrorToast }: AxiosPut<T>): Promise<ApiResult<T>> => {
    const tokenId = this.getTokenIdForCurrentSubdomain();

    try {
      const res = await axios.put<T>(url, data, {
        headers: { tokenId }
      });

      this.axiosToast({ status: res.status, method: "PUT", url, data: res.data, toasts });

      return new ApiResult<T>(res.data, res.statusText, res.status);
    } catch (e) {
      return this.handleAxiosError(e as AxiosError<T>, shouldShowErrorToast);
    }
  };

  axiosGet = async <T = any>(
    url: string,
    queryParams?: any,
    configParams?: AxiosRequestConfig,
    subdomain?: string
  ): Promise<ApiResult<T>> => {
    let tokenId: string | undefined = "";
    if (subdomain) {
      tokenId = this.getTokenIdFromCookieBySubdomain(subdomain);
    } else {
      tokenId = this.getTokenIdForCurrentSubdomain();
    }
    const _configParams: AxiosRequestConfig = {
      params: queryParams,
      headers: { tokenId },
      timeout: DEFAULT_TIMEOUT
    };
    try {
      const res = await axios.get<T>(url, _.merge(configParams, _configParams));

      if (url.includes(ServerRoutes.REPORTS_OWNER_STATEMENT_PRINTABLE_PDF)) {
        this.axiosToast({ status: res.status, method: "GET", url, data: res.data });
      }

      return new ApiResult<T>(res.data, res.statusText, res.status, undefined, undefined, res.headers);
    } catch (e) {
      return this.getApiResultFromAxiosError<T>(e as AxiosError<T>);
    }
  };

  axiosGetFile = async (
    url: string,
    queryParams?: any,
    configParams?: Omit<AxiosRequestConfig, "responseType">,
    subdomain?: string
  ): Promise<ApiResult<File>> => {
    let tokenId: string | undefined = "";
    if (subdomain) {
      tokenId = this.getTokenIdFromCookieBySubdomain(subdomain);
    } else {
      tokenId = this.getTokenIdForCurrentSubdomain();
    }
    const _configParams: AxiosRequestConfig = {
      params: queryParams,
      headers: { tokenId },
      timeout: DEFAULT_TIMEOUT,
      responseType: "blob"
    };
    try {
      const res = await axios.get<Blob>(url, _.merge(configParams, _configParams));

      if (url.includes(ServerRoutes.REPORTS_OWNER_STATEMENT_PRINTABLE_PDF)) {
        this.axiosToast({ status: res.status, method: "GET", url, data: res.data });
      }

      const contentType = res.headers["content-type"];
      const contentTypeExtension = contentType?.split(";")[0]?.split("/")[1];

      const fileName =
        res.headers["content-disposition"]?.split("filename=")[1]?.replace(/"/g, "") || `file.${contentTypeExtension}`;

      const file = new File([res.data], fileName, { type: contentType });

      return new ApiResult<File>(file, res.statusText, res.status);
    } catch (e) {
      return this.getApiResultFromAxiosError<File>(e as AxiosError<File>);
    }
  };

  axiosDelete = async <T = any>({
    url,
    options,
    toasts,
    shouldShowErrorToast
  }: AxiosDelete<T>): Promise<ApiResult<T>> => {
    const tokenId = this.getTokenIdForCurrentSubdomain();

    try {
      const res = await axios.delete<T>(url, {
        headers: { tokenId },
        ...options
      });

      this.axiosToast({ status: res.status, method: "DELETE", url, data: res.data, toasts });

      return new ApiResult<T>(res.data, res.statusText, res.status);
    } catch (e) {
      return this.handleAxiosError(e as AxiosError<T>, shouldShowErrorToast);
    }
  };

  deleteAuthCookies = () => this.cookies.remove(this.LOGIN_RESPONSES_COOKIE_NAME, this.getLoginResponseCookieOptions());

  getDomainParams() {
    const hostParts = document.location.host.split(".");
    const doorloopIndex = hostParts.indexOf("doorloop");
    return {
      env: doorloopIndex > 0 ? hostParts[doorloopIndex - 1] : undefined,
      company: doorloopIndex > 1 ? hostParts[doorloopIndex - 2] : undefined
    };
  }

  getEnvironmentSubdomain() {
    if (isLocalEnv) return "dev";

    const { env } = this.getDomainParams();

    if (!env) {
      throw new Error("Could not determine environment subdomain");
    } else if (["beta-portal", "dev-portal"].indexOf(env || "") > -1) {
      return env.replace("-portal", "");
    } else {
      return env;
    }
  }

  saveLoginResponsesToCookie = (loginResponses: LoginResponseDto[], rememberMe = true): boolean => {
    this.cookies.set(
      this.LOGIN_RESPONSES_COOKIE_NAME,
      JSON.stringify(
        loginResponses.map((x) => {
          const cookieDto = new LoginResponseCookieDto();
          cookieDto.type = x.type;
          cookieDto.id = x.id;
          cookieDto.subdomain = x?.currentDbTenant?.subdomain;
          return cookieDto;
        })
      ),
      this.getLoginResponseCookieOptions(rememberMe)
    );

    // localStorage.setItem(this.AUTH_LOCAL_STORAGE_KEY, token);

    return true;
  };

  getLoginResponsesFromCookie = (): LoginResponseCookieDto[] | undefined => {
    const tokens = this.cookies.get<LoginResponseCookieDto[]>(this.LOGIN_RESPONSES_COOKIE_NAME);
    return tokens;
  };

  getTokenIdForCurrentSubdomain = (): string => {
    const loginResponses = this.getLoginResponsesFromCookie();
    if (loginResponses) {
      const subdomain = window.location.host.split(".")[1] ? window.location.host.split(".")[0] : "";

      return this.getTokenIdFromCookieBySubdomain(subdomain);
    }

    return "";
  };

  getTokenIdFromCookieBySubdomain = (subdomain: string): string => {
    const loginResponses = this.getLoginResponsesFromCookie();

    if (loginResponses) {
      let loginResponseType = LoginResponseType.USER;

      const isTenantPortal = document.location.pathname.startsWith(Routes.TENANT_PORTAL_BASE_URL);

      if (isTenantPortal) {
        loginResponseType = LoginResponseType.TENANT;
        const isRentalApplication = document.location.pathname.startsWith(Routes.TENANT_PORTAL_RENTAL_APPLICATION);
        if (isRentalApplication) {
          loginResponseType = LoginResponseType.PROSPECT;
        }
      }

      const isOwnerPortal = document.location.pathname.startsWith(Routes.OWNER_PORTAL_BASE_URL);

      if (isOwnerPortal) {
        loginResponseType = LoginResponseType.OWNER;
      }

      let subdomainTokens = loginResponses.filter(
        (x) => x.type === loginResponseType && x.subdomain && x.subdomain.toLowerCase() === subdomain.toLowerCase()
      );

      if (subdomainTokens.length === 0) {
        subdomainTokens = loginResponses.filter(
          (x) => x.subdomain && x.subdomain.toLowerCase() === subdomain.toLowerCase()
        );
      }

      if (subdomainTokens.length > 0) {
        return subdomainTokens[0].id;
      }
    }

    return "";
  };

  getReturnPathQueryParamName = () => "returnPath";

  getHostURL = () => {
    let redirectHost = window.location.host;
    const environmentSubdomain = this.getEnvironmentSubdomain();
    const subdomaIndex = redirectHost.indexOf(environmentSubdomain);

    if (subdomaIndex > -1) {
      redirectHost = redirectHost.substring(subdomaIndex, redirectHost.length);
    }

    return window.location.protocol + "//" + redirectHost;
  };

  getApiResultFromAxiosError = <T = any>(e: AxiosError): ApiResult<T> => {
    const error = e as AxiosError<ApiError<T>>;
    let message = "An unexpected error has occurred.";
    if (error.response && error.response.data.message) {
      message = error.response.data.message;
    }

    let statusCode = 500;
    if (error?.response?.status) {
      statusCode = Number(error?.response?.status);
    }
    let errorCode;
    if (error?.response?.data.errorCode) {
      errorCode = error?.response?.data.errorCode;
    }

    if (typeof error?.response?.data === "string" || error?.response?.data instanceof String) {
      if (error?.response?.data?.indexOf("403") > -1 || error?.code === "403 Forbidden") {
        message = "To proceed with a payment, turn off any active VPN services and try again.";
      }
    }

    if (!message) {
      message = e.toString();
    }
    if (statusCode === 401) {
      // Unauthorized request, delete JWT Token
      //this.deleteJwtToken();

      if (!window.location.href.includes(Routes.AUTH)) {
        const isRentalApplication = document.location.pathname.startsWith(Routes.TENANT_PORTAL_RENTAL_APPLICATION);
        if (isRentalApplication) {
          const isPostCharge = document.location.pathname.includes(
            Routes.TENANT_PORTAL_RENTAL_APPLICATION_POST_SUBMISSION_PAYMENT
          );
          const returnPathParamName = this.getReturnPathQueryParamName();

          const searchObject = isPostCharge
            ? {
                [returnPathParamName]: location.pathname + location.search,
                ...qs.parse(location.search)
              }
            : qs.parse(location.search);

          window.location.href = `${this.getHostURL()}${Routes.TENANT_PORTAL_RENTAL_APPLICATION_AUTH}?${qs.stringify(searchObject)}`;
        } else {
          const params = `?${this.getReturnPathQueryParamName()}=${encodeURIComponent(
            window.location.pathname + window.location.search
          )}`;
          window.location.href = this.getHostURL() + Routes.LOGIN + params;
        }
      }
    }

    return new ApiResult<T>(
      undefined,
      message,
      statusCode,
      error?.response?.data?.errors as ValidationErrors,
      errorCode
    );
  };

  isTenantAuthenticated = async (leaseId: string): Promise<LoginResponseDto | boolean> => {
    try {
      const tokenId = this.getTokenIdForCurrentSubdomain();
      if (tokenId) {
        // check to see if we have a current user
        const state = store.getState();
        if (
          state.auth.currentLoginResponse &&
          state.auth.currentLoginResponse.type === LoginResponseType.TENANT &&
          state.auth.currentLoginResponse.currentLease?.id === leaseId
        ) {
          return state.auth.currentLoginResponse;
        }
        const { status, data } = await this.getCurrentTenantLoginResponseFromServer(leaseId);
        if (status && data && data.currentLoginResponse) {
          store.dispatch(
            loginSuccess(
              new AuthResponseDto({
                ...data
              })
            )
          );

          return data.currentLoginResponse;
        }
        return false;
      }
      return false;
    } catch (e) {
      return false;
    }
  };

  isProspectAuthenticated = async (): Promise<LoginResponseDto | null> => {
    const tokenId = this.getTokenIdForCurrentSubdomain();
    if (tokenId) {
      // check to see if we have a current user
      const state = store.getState();
      if (state.auth.currentLoginResponse && state?.auth?.currentLoginResponse?.type === LoginResponseType.PROSPECT) {
        return state.auth.currentLoginResponse;
      }
      // get current user and load it to the state
      const { status, data } = await this.getCurrentProspectLoginResponseFromServer();
      if (status && data && data.currentLoginResponse) {
        store.dispatch(
          loginSuccess(
            new AuthResponseDto({
              ...data
            })
          )
        );

        return data.currentLoginResponse;
      }
    }
    return null;
  };

  async isOwnerAuthenticated(): Promise<LoginResponseDto | boolean> {
    try {
      const tokenId = this.getTokenIdForCurrentSubdomain();

      if (tokenId) {
        const state = store.getState();

        if (state.auth?.currentLoginResponse && state.auth?.currentLoginResponse?.type === LoginResponseType.OWNER) {
          return state.auth.currentLoginResponse;
        }
        const { status, data } = await this.getCurrentOwnerLoginResponseFromServer();

        if (status && data) {
          store.dispatch(
            loginSuccess(
              new AuthResponseDto({
                currentLoginResponse: data
              })
            )
          );

          return data;
        }
      }
    } catch (error) {
      return false;
    }

    return false;
  }

  isUserAuthenticated = async (): Promise<LoginResponseDto | boolean> => {
    const tokenId = this.getTokenIdForCurrentSubdomain();
    if (tokenId) {
      // check to see if we have a current user
      const state = store.getState();
      if (state.auth.currentLoginResponse && state.auth.currentLoginResponse.type === LoginResponseType.USER) {
        return state.auth.currentLoginResponse;
      }
      // get current user and load it to the state
      const res = await this.getCurrentUserLoginResponseFromServer();
      if (res.status && res.data) {
        store.dispatch(
          loginSuccess(
            new AuthResponseDto({
              currentLoginResponse: res.data
            })
          )
        );
        //@ts-ignore
        if (window.newrelic) {
          if (res.data.type === LoginResponseType.TENANT) {
            //@ts-ignore
            window.newrelic.setCustomAttribute("userId", res.data.id);
          } else {
            //@ts-ignore
            window.newrelic.setCustomAttribute("tenantId", res.data.id);
          }
          //@ts-ignore
          //@ts-ignore
          window.newrelic.setCustomAttribute("loginEmail", res.data.loginEmail);
          //@ts-ignore
          window.newrelic.setCustomAttribute("tokenType", res.data.type);
          //@ts-ignore
          window.newrelic.setCustomAttribute("dbTenantId", res.data.currentDbTenant.id);
          //@ts-ignore
          window.newrelic.setCustomAttribute("dbTenantName", res.data.currentDbTenant.companyName);
          //@ts-ignore
          window.newrelic.setCustomAttribute("language", res.data.currentDbTenant.language);
          //@ts-ignore
          window.newrelic.setCustomAttribute("currency", res.data.currentDbTenant.currency);
        }

        return res.data;
      }
      return false;
    }
    return false;
  };

  /**
   * Verifies that all the dictionaries needed for get calls are loaded
   * @returns
   */
  async loadDictionariesRequiredForGet(dictionariesRequiredForGet: Array<RestApiBaseWithDictionary<any, any>>) {
    const promises = new Array<Promise<Record<string, NamesDictionary>>>();
    dictionariesRequiredForGet.forEach((dic) => promises.push(dic.getDictionary()));
    await Promise.all(promises);
    return true;
  }

  getUserFromServerBySubdomain = async (subdomain: string) => {
    const user = await this.axiosGet(ServerRoutes.AUTH_GET_CURRENT_LOGIN_RESPONSE, undefined, undefined, subdomain);
    return user;
  };

  getAllLoginResponses = async () => {
    const cookieLoginResponses = this.getLoginResponsesFromCookie();
    if (cookieLoginResponses && cookieLoginResponses.length > 0) {
      const result = await this.axiosGet<LoginResponseDto[]>(
        ServerRoutes.AUTH_GET_LOGIN_RESPONSES,
        undefined,
        undefined,
        cookieLoginResponses[0].subdomain
      );
      if (result.status) return result.data;
    } else {
      return undefined;
    }
  };

  getCurrentUserLoginResponseFromServer = async (): Promise<ApiResult<LoginResponseDto>> => {
    const currentUser = await this.axiosGet(ServerRoutes.AUTH_GET_CURRENT_LOGIN_RESPONSE);

    return currentUser;
  };

  getCurrentTenantLoginResponseFromServer = async (leaseId: string): Promise<ApiResult<AuthResponseDto>> => {
    const currentTenant = await this.axiosGet(
      TenantPortalServerRoutes.AUTH_GET_CURRENT_LOGIN_RESPONSE,
      new GetLoginResponseForTenantPortal({ leaseId })
    );
    return currentTenant;
  };

  getCurrentProspectLoginResponseFromServer = async (): Promise<ApiResult<AuthResponseDto>> =>
    await this.axiosGet(TenantPortalServerRoutes.AUTH_GET_CURRENT_PROSPECT_LOGIN_RESPONSE);

  async getCurrentOwnerLoginResponseFromServer(): Promise<ApiResult<LoginResponseDto>> {
    return await this.axiosGet(OwnerPortalServerRoutes.AUTH_GET_CURRENT_LOGIN_RESPONSE);
  }

  private getLoginResponseCookieOptions(rememberMe = true) {
    const indexOfApp = window.location.hostname.lastIndexOf(this.getEnvironmentSubdomain());
    const domain = "." + window.location.hostname.substring(indexOfApp);

    let expires: Date | undefined;
    if (rememberMe) {
      expires = moment.utc().add(60, "days").toDate(); // otherwise this will be a session cookie
    }

    return {
      expires,
      domain,
      path: "/",
      secure: isProductionEnv
    };
  }
}

export const apiHelper = new ApiHelper();
