export enum RentalApplicationWizardSteps {
  BeforeYouBegin = "beforeYouBegin",
  Pets = "pets",
  AboutYou = "aboutYou",
  Vehicles = "vehicles",
  Dependants = "dependants",
  EmergencyContacts = "emergencyContacts",
  Employment = "employment",
  Additionalincome = "additionalIncome",
  ResidentialHistory = "residentialHistory",
  AdditionalInformation = "additionalInformation"
}

export interface RentalApplicationWizardStepProps {
  numberOfSteps: number;
  stepIndex: number;
}
