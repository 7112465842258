import { ObjectPermission } from "@doorloop/dto";
import DLButton from "DLUI/button/dlButton";
import EmptyDataView from "DLUI/emptyDataView";
import AppStrings from "locale/keys";
import React from "react";
import { NavigationManager } from "utils/navigation";
import { DataCy } from "@doorloop/dto/src/automation/dataCy";

interface ComponentProps {
  propertyId?: string;
  unitId?: string;
}

const LeasesEmptyDataView = ({ propertyId, unitId }: ComponentProps) => {
  const didPressAddNewLease = () => {
    NavigationManager.createNewDraftLease(propertyId, unitId);
  };

  return (
    <EmptyDataView
      instructionsText={AppStrings.Leases.Screen.EmptydataViewInstructionsText}
      actionButton={
        <DLButton
          actionText={AppStrings.Leases.Screen.AddNewLease}
          onClick={didPressAddNewLease}
          icons={{ start: { isHidden: false } }}
          disableMobileCollapse
          clearance={{ permission: ObjectPermission.leases, field: "create" }}
          dataCy={DataCy.newLease.newLeaseButton}
        />
      }
    />
  );
};

export default LeasesEmptyDataView;
