import { tenantPortalRequestsApi } from "@/api/tenantPortal/tenantPortalRequestsApi";
import { useMutation } from "@tanstack/react-query";

import { createMutationStore } from "../createMutationStore";
import { createQueryStore } from "../createQueryStore";
import type { TenantPortalRequestPostUpdateDto } from "@doorloop/dto";

export const tenantPortalRequestsStore = {
  queries: createQueryStore("tenant-portal-requests", tenantPortalRequestsApi),
  mutations: {
    ...createMutationStore("tenant-portal-requests", tenantPortalRequestsApi),
    usePostMessage: () =>
      useMutation({
        mutationKey: ["tenant-portal-requests-post-message"],
        mutationFn: async (dto: TenantPortalRequestPostUpdateDto) => await tenantPortalRequestsApi.postMessage(dto)
      })
  }
};
