"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultAccountType = void 0;
var DefaultAccountType;
(function (DefaultAccountType) {
    DefaultAccountType["ACCOUNTS_RECEIVABLE"] = "ACCOUNTS_RECEIVABLE";
    DefaultAccountType["ACCOUNTS_PAYABLE"] = "ACCOUNTS_PAYABLE";
    DefaultAccountType["UNAPPLIED_LEASE_PAYMENTS_INCOME"] = "UNAPPLIED_LEASE_PAYMENTS_INCOME";
    DefaultAccountType["UNDEPOSITED_FUNDS"] = "UNDEPOSITED_FUNDS";
    DefaultAccountType["LATE_FEES"] = "LATE_FEES";
    DefaultAccountType["MANAGEMENT_FEES"] = "MANAGEMENT_FEES";
    DefaultAccountType["RENTAL_INCOME"] = "RENTAL_INCOME";
    DefaultAccountType["SECURITY_DEPOSIT"] = "SECURITY_DEPOSIT";
    DefaultAccountType["APPLICATION_FEE"] = "APPLICATION_FEE";
    DefaultAccountType["OPERATING_ACCOUNT"] = "OPERATING_ACCOUNT";
    DefaultAccountType["TRUST_ACCOUNT"] = "TRUST_ACCOUNT";
    DefaultAccountType["OWNER_CONTRIBUTION"] = "OWNER_CONTRIBUTION";
    DefaultAccountType["OWNER_DISTRIBUTION"] = "OWNER_DISTRIBUTION";
    DefaultAccountType["OPENING_BALANCE"] = "OPENING_BALANCE";
    DefaultAccountType["MERCHANT_PLATFORM_FEES"] = "MERCHANT_PLATFORM_FEES";
    DefaultAccountType["MERCHANT_PAYOUT_CLEARING"] = "MERCHANT_PAYOUT_CLEARING";
    DefaultAccountType["MERCHANT_APPLICATION_FEES"] = "MERCHANT_APPLICATION_FEES";
    DefaultAccountType["MERCHANT_RESERVES"] = "MERCHANT_RESERVES";
    DefaultAccountType["MERCHANT_RETURNED_PAYMENTS_FEES"] = "MERCHANT_RESERVES";
    DefaultAccountType["MERCHANT_RETURNED_PAYMENTS"] = "MERCHANT_RETURNED_PAYMENT";
    DefaultAccountType["CLEARING_ACCOUNT"] = "CLEARING_ACCOUNT";
    DefaultAccountType["APPLICATION_FEE_EXPENSE"] = "APPLICATION_FEE_EXPENSE";
    DefaultAccountType["MERCHANT_RETURNED_PAYMENT"] = "MERCHANT_RETURNED_PAYMENT";
    DefaultAccountType["CONVENIENCE_FEE"] = "CONVENIENCE_FEE";
})(DefaultAccountType || (exports.DefaultAccountType = DefaultAccountType = {}));
