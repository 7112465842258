"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnitAmenity = void 0;
var UnitAmenity;
(function (UnitAmenity) {
    UnitAmenity["AdditionalStorage"] = "AdditionalStorage";
    UnitAmenity["AirConditioner"] = "AirConditioner";
    UnitAmenity["Alarm"] = "Alarm";
    UnitAmenity["Balcony"] = "Balcony";
    UnitAmenity["Cable"] = "Cable";
    UnitAmenity["Carpet"] = "Carpet";
    UnitAmenity["Carport"] = "Carport";
    UnitAmenity["CeilingFan"] = "CeilingFan";
    UnitAmenity["ControlledAccess"] = "ControlledAccess";
    UnitAmenity["Courtyard"] = "Courtyard";
    UnitAmenity["Dishwasher"] = "Dishwasher";
    UnitAmenity["Disposal"] = "Disposal";
    UnitAmenity["DoubleSinkVanity"] = "DoubleSinkVanity";
    UnitAmenity["Dryer"] = "Dryer";
    UnitAmenity["Fireplace"] = "Fireplace";
    UnitAmenity["FramedMirrors"] = "FramedMirrors";
    UnitAmenity["Furnished"] = "Furnished";
    UnitAmenity["Garage"] = "Garage";
    UnitAmenity["Handrails"] = "Handrails";
    UnitAmenity["HardwoodFlooring"] = "HardwoodFlooring";
    UnitAmenity["HardSurfaceCounterTops"] = "HardSurfaceCounterTops";
    UnitAmenity["Heat"] = "Heat";
    UnitAmenity["IndividualClimateControl"] = "IndividualClimateControl";
    UnitAmenity["IslandKitchen"] = "IslandKitchen";
    UnitAmenity["LaminateCounterTops"] = "LaminateCounterTops";
    UnitAmenity["VinylFlooring"] = "VinylFlooring";
    UnitAmenity["LargeClosets"] = "LargeClosets";
    UnitAmenity["LinenCloset"] = "LinenCloset";
    UnitAmenity["Microwave"] = "Microwave";
    UnitAmenity["Pantry"] = "Pantry";
    UnitAmenity["Patio"] = "Patio";
    UnitAmenity["PrivateBalcony"] = "PrivateBalcony";
    UnitAmenity["PrivatePatio"] = "PrivatePatio";
    UnitAmenity["Range"] = "Range";
    UnitAmenity["Refrigerator"] = "Refrigerator";
    UnitAmenity["Satellite"] = "Satellite";
    UnitAmenity["Skylight"] = "Skylight";
    UnitAmenity["TileFlooring"] = "TileFlooring";
    UnitAmenity["VaultedCeiling"] = "VaultedCeiling";
    UnitAmenity["View"] = "View";
    UnitAmenity["Washer"] = "Washer";
    UnitAmenity["WheelChair"] = "WheelChair";
    UnitAmenity["WD_Hookup"] = "WD_Hookup";
    UnitAmenity["WindowCoverings"] = "WindowCoverings";
    UnitAmenity["Other"] = "Other";
})(UnitAmenity || (exports.UnitAmenity = UnitAmenity = {}));
