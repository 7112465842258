import type { ViewComponentProps } from "DLUI/view/view";
import View from "DLUI/view/view";
import { ProfileImage } from "DLUI/profileImage";
import React from "react";
import ListItemText from "DLUI/listItems/listItemText";
import Text from "DLUI/text";
import ListItemSection from "DLUI/listItems/listItemSection";

interface ListItemInfoCellProps extends Partial<ViewComponentProps> {
  showProfileImage?: boolean;
  pictureUrl?: string;
  primaryText?: string;
  secondaryText?: string;
  href?: string;
}

export const ListItemInfoCell = ({
  pictureUrl,
  primaryText,
  secondaryText,
  href,
  showProfileImage,
  ...viewProps
}: ListItemInfoCellProps) => (
  <View alignItems={"center"} flexDirection={"row"} gap={10} {...viewProps}>
    {showProfileImage && (
      <View justifyContent={"center"} alignItems={"center"} width={"auto"} flexDirection={"row"}>
        <ProfileImage pictureUrl={pictureUrl} />
      </View>
    )}

    <ListItemSection
      renderTitle={
        <ListItemText
          href={href}
          bold
          color={"black"}
          lineHeight={"20px"}
          marginRight={10}
          numberOfLines={2}
          fontSize={14}
        >
          {primaryText}
        </ListItemText>
      }
      renderSubTitle={
        <Text color={"gray"} fontSize={12}>
          {secondaryText}
        </Text>
      }
    />
  </View>
);
