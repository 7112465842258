import { useEffect } from "react";
import _ from "lodash";

type SupportedWindowEvents = "resize";

type Handler = () => void;

type useWindowEventInterface = (event: SupportedWindowEvents, Handler: Handler, deps: any[], debounce?: number) => void;

const eventHandlers: Record<SupportedWindowEvents, Handler[]> = {
  resize: []
};

window.addEventListener("resize", () => eventHandlers["resize"].forEach((handler) => handler()));

export const useWindowEvent: useWindowEventInterface = (event, handler, deps, debounce) => {
  useEffect(() => {
    const finalHandler = debounce ? _.debounce(handler, debounce) : handler;
    eventHandlers[event].push(finalHandler);
    return () => {
      eventHandlers[event] = _.without(eventHandlers[event], finalHandler);
    };
  }, deps);
};
