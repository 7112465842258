import React from "react";
import type { CustomAllocationDto } from "@doorloop/dto";
import { customAllocationsApi } from "api/customAllocationsApi";
import { useFormikContext } from "formik";
import DeleteConfirmation from "../components/deleteConfirmation";
import AppStrings from "locale/keys";

interface ComponentProps {
  didFinishOperation: () => void;
  didPressDismissButton: () => void;
  didFailOperation?: () => void;
}

export const DeleteCustomAllocationConfirmationView = ({
  didFinishOperation,
  didPressDismissButton,
  didFailOperation
}: ComponentProps) => {
  const {
    values: { id }
  } = useFormikContext<CustomAllocationDto>();

  if (!id) {
    return null;
  }

  return (
    <DeleteConfirmation
      apiMethod={customAllocationsApi}
      transactionId={id}
      deleteConfirmationText={AppStrings.Allocations.DeleteConfirmationText}
      didFinishOperation={didFinishOperation}
      didPressDismissButton={didPressDismissButton}
      didFailOperation={didFailOperation ?? didPressDismissButton}
      attachments={[]}
    />
  );
};
