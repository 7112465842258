import React from "react";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { Icon } from "DLUI/icon";
import { CheckBoldIcon } from "@/assets";
import { useResponsiveHelper } from "contexts/responsiveContext";

const useStyles = makeStyles(() => {
  return {
    container: {
      cursor: "pointer",
      margin: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: ({ color }: { color: string }) => color
    },
    mobile: {
      width: 40,
      height: 40,
      borderRadius: 20
    },
    desktop: {
      width: 26,
      height: 26,
      borderRadius: 13
    }
  };
});

interface ColorContainerProps {
  color: string;
  onClick?: () => void;
  selected: boolean;
}

export const ColorContainer = ({ color, onClick, selected }: ColorContainerProps) => {
  const { isMobile } = useResponsiveHelper();
  const classes = useStyles({ color });

  return (
    <div
      className={clsx(classes.container, {
        [classes.mobile]: isMobile,
        [classes.desktop]: !isMobile
      })}
      onClick={onClick}
    >
      {selected && <Icon className={"fadeIn"} Source={CheckBoldIcon} size={13} />}
    </div>
  );
};

export default ColorContainer;
