import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles(() =>
  createStyles({
    show: {
      display: "block",
      width: "100%"
    },
    hide: {
      height: 0,
      overflow: "hidden"
    }
  })
);
