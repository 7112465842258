import { RentalApplicationAdditionalQuestionType } from "@doorloop/dto";
import { View } from "DLUI/view";
import type { FieldArrayRenderProps } from "formik";
import { FastField } from "formik";
import React from "react";
import Text from "DLUI/text";
import { Grid } from "@material-ui/core";
import { IconButton, Select, TextField } from "DLUI/form";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import { DeleteIcon } from "assets/icons";

interface ComponentProps {
  itemIndex: number;
  arrayHelpers: FieldArrayRenderProps;
  isOnlyElement: boolean;
}

const AdditionalInformationQuestionsItem: React.FC<ComponentProps> = ({ itemIndex, arrayHelpers, isOnlyElement }) => {
  const { t } = useTranslation();

  const didPressDeleteButton = () => {
    arrayHelpers.remove(itemIndex);
  };
  return (
    <View marginTop={itemIndex > 0 ? 20 : 0} justifyContent={"center"} flexDirection={"row"}>
      <Grid container item md={6}>
        <View flexDirection={"row"}>
          <View
            borderRadius={20}
            backgroundColor={"black"}
            width={40}
            height={40}
            alignItems={"center"}
            justifyContent={"center"}
            marginLeft={20}
            marginRight={20}
          >
            <Text color={"white"}>{itemIndex + 1}</Text>
          </View>
          <View flex={1}>
            <FastField
              component={TextField}
              name={`additionalInfo.additionalInfoQuestions[${itemIndex}].question`}
              required
              label={t(AppStrings.Common.Question)}
              paddingRight={20}
            />
          </View>
        </View>
      </Grid>
      <Grid item md={4}>
        <FastField
          component={Select}
          name={`additionalInfo.additionalInfoQuestions[${itemIndex}].questionType`}
          label={AppStrings.Common.AnswerType}
          required
          uniqueKey={"RentalApplicationAdditionalQuestionType"}
          selectionEnum={RentalApplicationAdditionalQuestionType}
          translationKey={"rentalApplicationAdditionalQuestionType"}
        />
      </Grid>
      <Grid container item justify={"center"} md={2}>
        {!isOnlyElement && (
          <View alignItems={"center"}>
            <IconButton onClick={didPressDeleteButton} Icon={DeleteIcon} />
          </View>
        )}
      </Grid>
    </View>
  );
};

export default AdditionalInformationQuestionsItem;
