import type { MerchantAccountDto } from "@doorloop/dto";
import { AuthResponseDto, LinkedResourceDto, LinkedResourceType, MerchantAccountStatus } from "@doorloop/dto";
import type { ApiResult } from "api/apiResult";
import { merchantAccountApi } from "api/bankAccountsApi";
import { propertiesApi } from "api/propertiesApi";
import { rentalApplicationsApi } from "api/rentalApplicationsApi";
import { settingsApi } from "api/settingsApi";
import { usersApi } from "api/usersApi";
import clsx from "clsx";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import AnimatedContent, { getAnimatedContentFormikRef } from "DLUI/dialogs/components/animatedContent";
import DialogFrame from "DLUI/dialogs/components/dialogFrame";
import Dialog from "DLUI/dialogs/dialog";
import { Scroller } from "DLUI/screen";
import type { FormikProps } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import makeStyles from "./styles";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import type { SVGIconComponent } from "assets/icons/types";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import { useRentalApplicationLocale } from "../../../hooks/useRentalApplicationLocale";
import { store } from "store/index";
import { loginSuccess } from "store/auth/actions";
import { apiHelper } from "api/apiHelper";

const DialogWidth = 962;
const DialogHeight = 700;

export enum SettingsContentTypes {
  CompanyInfo = "companyInfo",
  PersonalInformation = "personalInformation",
  RentalApplication = "rentalApplication"
}

const USER_UPDATE_TYPES = [SettingsContentTypes.CompanyInfo, SettingsContentTypes.PersonalInformation];

export interface MenuItem {
  title: string;
  isSelected?: boolean;
  icon: SVGIconComponent;

  contentComponent: (
    formikRef: FormikProps<any>,
    onFileReceive?: (receivedFile: File) => void,
    onFileDelete?: () => void
  ) => any;
  uploadFile?: boolean;
}

interface ComponentProps {
  onClose: (values?: any, shouldRefresh?: boolean) => void;
  className?: string;
  disableBackdropClick?: boolean;
  dialogState: DialogState;
  loadingText?: string;
  errorText?: string;
  successText?: string;
  onRetryButtonPress?: (formikRef?: FormikProps<any>) => void;
  onBackdropClick?: () => void;
  showCloseIcon?: boolean;
  refreshEvent?: () => void;
  sectionItems: MenuItem[];
  getformikInitialValues: (props?: any) => any;
  formikValidation?: (values: any) => any;
  validationMethod: ({
    formikRef,
    transunionFee,
    merchantAccountData,
    sectionItems
  }: {
    formikRef: FormikProps<any>;
    transunionFee?: number;
    merchantAccountData?: MerchantAccountDto;
    sectionItems?: MenuItem[];
  }) => Promise<{
    isValid: boolean;
    errorStepIndex?: number;
  }>;
  getApiMethod: (propertyId?: string) => Promise<any>;
  updateApiMethod: (settingsObj: any) => Promise<any>;
  dialogTitle: string;
  dialogHeight?: number;
  dialogWidth?: number;
  type?: SettingsContentTypes;
  propertyId?: string;
}

let formikGlobalRef: FormikProps<any> | null = null;

export const SettingsAnimatedContentDialog: React.FC<ComponentProps> = ({
  dialogState,
  onClose,
  className,
  loadingText,
  errorText,
  successText,
  onBackdropClick,
  refreshEvent,
  sectionItems,
  getformikInitialValues,
  formikValidation,
  validationMethod,
  getApiMethod,
  updateApiMethod,
  dialogTitle,
  dialogHeight,
  dialogWidth,
  type,
  propertyId
}: ComponentProps) => {
  const classes = makeStyles();
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();
  const [scroller, setScroller] = useState<Scroller | null>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [merchantAccountData, setMerchantAccountData] = useState<undefined | MerchantAccountDto>();
  const [currentMenuItemTitle, setCurrentMenuItemTitle] = useState<string>("");
  const [viewIndex, setViewIndex] = useState(0);
  const [transunionEligibilityMessage, setTransunionEligibilityMessage] = useState<string | undefined>();
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);
  const { isUs } = useRentalApplicationLocale();

  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>(
    errorText || t(AppStrings.Common.GeneralError)
  );

  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState<string>(
    loadingText || t(AppStrings.Common.Loading)
  );

  const [loadingDialogSuccessText, setLoadingDialogSuccessText] = useState<string>(
    successText || t(AppStrings.Common.OperationCompleted)
  );

  const [currentSelectedMenuIndex, setCurrentSelectedMenuIndex] = useState<number | undefined>();
  const [landlordId, setLandlordId] = useState<number | undefined>();
  const [pendingFile, setPendingFile] = useState<File | undefined>();
  const [settingsData, setSettingsData] = useState<undefined | any>();
  const [deleteImageOnSave, setDeleteImageOnSave] = useState<boolean>(false);
  const [transunionFee, setTransunionFee] = useState<number | undefined>();

  useEffect(() => {
    if (dialogState === DialogState.Show) {
      setShowDialog(true);
      if (scroller == null) {
        setScroller(new Scroller(document.getElementsByClassName("MuiDialog-scrollBody")[0] as HTMLDivElement));
      }
    } else if (dialogState === DialogState.Hidden) {
      setShowDialog(false);
    }
  }, [dialogState]);

  useEffect(() => {
    loadSettingsData();

    return () => {
      formikGlobalRef = null;
    };
  }, [isUs]);

  const loadSettingsData = async () => {
    if (getApiMethod) {
      setLoadingDialogLoadingText(t(AppStrings.Common.Loading));
      setLoadingDialogState(DialogState.Show);
      const results = (await getApiMethod(propertyId).catch((e: string) => {
        setLoadingDialogErrorText(e);
        setLoadingDialogState(DialogState.Hidden);
      })) as ApiResult<any>;
      if (results && results.status && results.data) {
        const nextSettingsData = _.cloneDeep(results.data);
        if (type !== "rentalApplication") {
          if (nextSettingsData.phones) {
            _.forEach(nextSettingsData.phones, (currentItem) => {
              currentItem["shouldShow"] = true;
            });
          }

          if (nextSettingsData.emails) {
            _.forEach(nextSettingsData.emails, (currentItem) => {
              currentItem["shouldShow"] = true;
            });
          }

          if (nextSettingsData.emergencyContacts) {
            _.forEach(nextSettingsData.emergencyContacts, (currentItem) => {
              currentItem["shouldShow"] = true;
            });
          }
        }

        const rentalApplicationsSettingsResponse = await settingsApi.getRentalApplications().catch(() => {
          setLoadingDialogErrorText(t(AppStrings.Common.GeneralError));
          setLoadingDialogState(DialogState.Error);
        });

        if (rentalApplicationsSettingsResponse && rentalApplicationsSettingsResponse.data) {
          setTransunionFee(rentalApplicationsSettingsResponse.data.transunionFee);
          setLandlordId(rentalApplicationsSettingsResponse.data.transunionLandlordId);
        }

        if (propertyId && type === "rentalApplication") {
          await Promise.all([
            await merchantAccountApi.getAll({
              filter_property: propertyId,
              filter_status: MerchantAccountStatus.ACTIVE
            }),
            isUs ? rentalApplicationsApi.checkTransunionEligibility(propertyId) : undefined
          ])
            .then((values) => {
              const merchantAccountResponse = values[0];
              if (merchantAccountResponse.status && merchantAccountResponse.data) {
                setMerchantAccountData(merchantAccountResponse.data.data[0]);
              }
              const checkTransunionEligibilityResponse = values[1];
              if (checkTransunionEligibilityResponse && !checkTransunionEligibilityResponse.status) {
                setTransunionEligibilityMessage(checkTransunionEligibilityResponse.message);
              }
            })
            .catch(() => {
              setLoadingDialogErrorText(t(AppStrings.Common.GeneralError));
              setLoadingDialogState(DialogState.Error);
            });
        }
        setSettingsData(nextSettingsData);
        setLoadingDialogSuccessText(t(AppStrings.Common.OperationCompleted));
        setLoadingDialogState(DialogState.Success);
        setTimeout(() => {
          setLoadingDialogState(DialogState.Hidden);
        }, 0);
      } else {
        setLoadingDialogErrorText(results.message);
        setLoadingDialogState(DialogState.Error);
      }
    }
  };
  const didFinishOperation = (values?: any) => {
    onClose(values);
    setTimeout(() => {
      setViewIndex(0);
      if (refreshEvent) {
        refreshEvent();
      }
    }, 500);
  };

  const _onBackdropClick = () => {
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const renderActionPanelButtons = (formik: FormikProps<any>) => {
    formikGlobalRef = formik;
    return (
      <FormActionButtons
        propsSubButton={{ onClick: _onBackdropClick }}
        propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
      />
    );
  };

  const onMenuItemSelection = (menuItemTitle: string) => {
    setCurrentMenuItemTitle(menuItemTitle);
  };

  const onRetryLoadInfoButtonPress = () => {
    loadSettingsData();
  };

  const didPressDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(0);
  };

  const getFormikInitialValues = () => {
    if (formikGlobalRef && formikGlobalRef.values && formikGlobalRef.values) {
      return formikGlobalRef.values;
    }
    if (settingsData) {
      return getformikInitialValues(settingsData);
    }
    return getformikInitialValues();
  };

  const renderView = ({ index }: any) => {
    if (index === 0) {
      if (loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={onRetryLoadInfoButtonPress}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }
      return (
        <AnimatedContent
          formikInitialValues={getFormikInitialValues}
          sectionItems={sectionItems}
          formikValidation={formikValidation}
          dialogHeight={dialogHeight ? dialogHeight : DialogHeight}
          renderActionPanelButtons={renderActionPanelButtons}
          onFileReceive={onFileReceive}
          onFileDelete={onFileDelete}
          onMenuItemSelection={onMenuItemSelection}
          selectedMenuIndex={currentSelectedMenuIndex}
          merchantAccountData={merchantAccountData}
          transunionEligibilityMessage={transunionEligibilityMessage}
          transunionFee={transunionFee}
          landlordId={landlordId}
        />
      );
    }

    if (index === 1) {
      return (
        <div className={clsx([isMobile ? classes.mobileDialogContainer : classes.dialogContainer])}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={onLoadingDialogRetryButtonPress}
            didPressDismissButton={didPressLoadingDialogDismissButton}
          />
        </div>
      );
    }
    return <div />;
  };

  const updateSettingsData = async () => {
    const formikRef = getAnimatedContentFormikRef();
    if (formikRef !== null) {
      setLoadingDialogLoadingText(loadingText || t(AppStrings.Common.Loading));
      setLoadingDialogState(DialogState.Show);
      setViewIndex(1);
      let response: any;

      if (type === "rentalApplication" && propertyId) {
        response = await propertiesApi.updateRentalApplications(propertyId, formikRef.values);
      } else {
        response = (await updateApiMethod(formikRef.values).catch((e: string) => {
          setLoadingDialogErrorText(e);
          setLoadingDialogState(DialogState.Error);
        })) as ApiResult<any>;

        if (response.status && type && USER_UPDATE_TYPES.includes(type)) {
          const res = await apiHelper.getCurrentUserLoginResponseFromServer();
          if (res.status && res.data) {
            store.dispatch(
              loginSuccess(
                new AuthResponseDto({
                  currentLoginResponse: res.data
                })
              )
            );
          }
        }
      }

      if (response.status) {
        if (pendingFile) {
          await uploadFile(response.data.id, onClose);
        } else {
          if (deleteImageOnSave) {
            if (type && type === "companyInfo") {
              await settingsApi.deletePicture(response.data.id);
            }
            if (type && type === "personalInformation") {
              await usersApi.deleteUserPicture(response.data.id);
            }
            response.data.pictureUrl = undefined;
          }
          didFinishOperation(response.data);
          setLoadingDialogSuccessText(successText || t(AppStrings.Common.OperationCompleted));
          setLoadingDialogState(DialogState.Success);
          setTimeout(() => {
            setLoadingDialogState(DialogState.Hidden);
          }, 0);
          formikGlobalRef = null;
        }
      } else {
        setLoadingDialogErrorText(response.message);
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const didPressSaveButton = async () => {
    if (formikGlobalRef !== null) {
      const validationResult = await validationMethod({
        formikRef: formikGlobalRef,
        transunionFee,
        merchantAccountData,
        sectionItems
      });

      if (validationResult.isValid) {
        setTimeout(() => {
          updateSettingsData();
        }, 0);
      } else if (validationResult.errorStepIndex !== undefined) {
        setCurrentSelectedMenuIndex(validationResult.errorStepIndex);
        setTimeout(() => {
          setCurrentSelectedMenuIndex(undefined);
        }, 0);
      }
    }
  };

  const onLoadingDialogRetryButtonPress = () => {
    setLoadingDialogState(DialogState.Show);
    updateSettingsData();
  };

  const didPressLoadingDialogDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(0);
  };

  const uploadFile = async (id: string, onClose?: (values?: any) => void) => {
    setLoadingDialogState(DialogState.Show);
    const LinkedResource = new LinkedResourceDto(id, LinkedResourceType.User);
    let fileApiMethod = usersApi.uploadUserPicture;
    if (type && type === "companyInfo") {
      fileApiMethod = settingsApi.uploadCompanyLogo;
    }

    const response = await fileApiMethod(pendingFile!, LinkedResource, settingsData.id);

    if (response && response.status) {
      setLoadingDialogSuccessText(successText || t(AppStrings.Common.OperationCompleted));
      setLoadingDialogState(DialogState.Success);
      setTimeout(() => {
        setLoadingDialogState(DialogState.Hidden);
      }, 0);
      formikGlobalRef = null;
      if (onClose) {
        onClose(response.data);
      }
    } else {
      formikGlobalRef!.setSubmitting(false);
      setLoadingDialogErrorText(response.message);
      setLoadingDialogState(DialogState.Error);
    }
  };

  const onFileReceive = (receivedFile: File) => {
    formikGlobalRef?.setFieldValue("pictureUrl", URL.createObjectURL(receivedFile));
    setPendingFile(receivedFile);
  };

  const onFileDelete = () => {
    formikGlobalRef?.setFieldValue("pictureUrl", undefined);
    setPendingFile(undefined);
    setDeleteImageOnSave(true);
  };

  const emptyCallback = () => {};

  const frameType = useMemo(() => {
    if (loadingDialogState !== DialogState.Hidden) {
      return "contentOnly";
    }
    return "sideMenu";
  }, [loadingDialogState]);

  return (
    <Dialog className={className} open={showDialog} onClose={emptyCallback} disableBackdropClick>
      <DialogFrame
        onCloseButtonClick={_onBackdropClick}
        width={dialogWidth || DialogWidth}
        height={dialogHeight || DialogHeight}
        renderView={renderView}
        numViews={2}
        activeView={viewIndex}
        frameType={frameType}
        title={dialogTitle}
        sectionTitle={currentMenuItemTitle}
      />
    </Dialog>
  );
};
