import React, { useState } from "react";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, getIn } from "formik";
import { RadioGroup } from "DLUI/form";
import AppStrings from "locale/keys";
import { SeparationLine } from "DLUI/separatorView";
import { useTranslation } from "react-i18next";
import { propertiesApi } from "api/propertiesApi";
import Text from "DLUI/text";
import { propertyGroupsApi } from "api/propertyGroupsApi";
import FormikCachedMultiSelectAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedMultiSelectAutoComplete";

interface ComponentProps {
  formikRef: FormikProps<any>;
}

const PropertiesAccess: React.FC<ComponentProps> = ({ formikRef }: ComponentProps) => {
  const { t } = useTranslation();
  const properties = getIn(formikRef.values, "properties");
  const propertyGroups = getIn(formikRef.values, "propertyGroups");
  const _propertiesAccessSelectionType =
    (properties !== undefined && properties.length > 0) || (propertyGroups !== undefined && propertyGroups.length > 0);
  const [propertiesAccessSelectionType, setPropertiesAccessSelectionType] = useState<
    "allProperties" | "specifyProperties"
  >(_propertiesAccessSelectionType ? "specifyProperties" : "allProperties");

  const didChangePropertiesSelection = (selectedValue: string) => {
    if (selectedValue === "false") {
      formikRef.setFieldValue("properties", undefined);
      formikRef.setFieldValue("propertyGroups", undefined);
      setPropertiesAccessSelectionType("allProperties");
    } else {
      formikRef.setFieldValue("properties", []);
      formikRef.setFieldValue("propertyGroups", []);
      setPropertiesAccessSelectionType("specifyProperties");
    }
  };
  return (
    <View flex={1}>
      <View width={"100%"}>
        <FastField
          uniqueKey={"LF"}
          component={RadioGroup}
          name={"automaticLateFees"}
          defaultValue={propertiesAccessSelectionType === "allProperties" ? "false" : "true"}
          didChangeSelection={didChangePropertiesSelection}
          radioGroupItems={[
            {
              label: AppStrings.Users.NewUser.AccessAllProperties,
              value: "false"
            },
            {
              label: AppStrings.Users.NewUser.AccessSomeProperties,
              value: "true"
            }
          ]}
        />
        <View
          shouldShow={propertiesAccessSelectionType === "specifyProperties"}
          showAnimation={"fade-in"}
          hideAnimation={"fade-out"}
        >
          <View>
            <SeparationLine marginTop={20} width={"100%"} height={1} />
            <Text value={AppStrings.Users.NewUser.PropertiesSelectionDescription} fontSize={16} marginTop={20} />
            <FormikCachedMultiSelectAutoComplete
              uniqueIndex={"usersApi"}
              apiHandler={propertiesApi}
              displayNameKey={"name"}
              filterFieldName={"filter_text"}
              filterFieldValue={"name"}
              selectionFields={["id", "class"]}
              name={"properties"}
              label={t(AppStrings.DrawerItems.Properties)}
              marginTop={20}
            />
            <Text value={AppStrings.Users.NewUser.GroupSelectionDescription} fontSize={16} marginTop={20} />
            <FormikCachedMultiSelectAutoComplete
              uniqueIndex={"usersApi"}
              apiHandler={propertyGroupsApi}
              displayNameKey={"name"}
              filterFieldName={"filter_text"}
              filterFieldValue={"name"}
              selectionFields={["id", "class"]}
              name={"propertyGroups"}
              label={t(AppStrings.Users.NewUser.PropertyGroups)}
              marginTop={20}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default PropertiesAccess;
