import Routes from "./routes";
import { lazy } from "react";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";

const NewSignatureTemplateScreen = lazy(
  () => import("screens/esignatures/newSignatureTemplate/newSignatureTemplateScreen")
);

const SignatureTemplatesListScreen = lazy(
  () => import("screens/esignatures/signatureTemplates/signatureTemplatesListScreen")
);

const SignatureTemplatesRouter = () => (
  <PageRouter>
    <LazyRoute path={Routes.NEW_SIGNATURE_TEMPLATE} render={NewSignatureTemplateScreen} />

    <LazyRoute path={[Routes.EDIT_SIGNATURE_TEMPLATE]} render={SignatureTemplatesListScreen} exact />
    <LazyRoute path={[Routes.SIGNATURE_TEMPLATES]} render={SignatureTemplatesListScreen} />
  </PageRouter>
);

export default SignatureTemplatesRouter;
