"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectsDto = void 0;
const class_validator_1 = require("class-validator");
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
const objectAccess_dto_1 = require("./objectAccess.dto");
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
const taskObjectAccess_dto_1 = require("./taskObjectAccess.dto");
class ObjectsDto {
}
exports.ObjectsDto = ObjectsDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "prospects", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "owners", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "vendors", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "tenants", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "users", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", taskObjectAccess_dto_1.TaskObjectAccessDto)
], ObjectsDto.prototype, "tasks", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", taskObjectAccess_dto_1.TaskObjectAccessDto)
], ObjectsDto.prototype, "workOrders", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", taskObjectAccess_dto_1.TaskObjectAccessDto)
], ObjectsDto.prototype, "ownerRequests", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", taskObjectAccess_dto_1.TaskObjectAccessDto)
], ObjectsDto.prototype, "tenantRequests", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "properties", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "units", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "leases", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "rentalApplications", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "leaseCharges", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "leaseCredits", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "leasePayments", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "leaseRefunds", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "bulkReceivePayments", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "vendorBills", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "vendorBillPayments", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "vendorCredits", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "ownerContributions", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "journalEntries", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "bankTransfers", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "bankDeposits", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "expenses", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "applicationFees", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "managementFees", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "files", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "notes", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "accounts", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "merchantAccounts", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "insurancePolicies", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "communications", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "calendar", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "announcements", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "esignatureRequests", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "esignatureTemplates", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsObject)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "apiKeys", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsObject)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "propertyGroups", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsObject)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "tags", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsObject)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "userRoles", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsObject)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "bankAccountsPlaidAccounts", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsObject)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "bankAccountsPlaidTransactions", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsObject)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "bankReconciliation", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "budgets", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsObject)(),
    __metadata("design:type", objectAccess_dto_1.ObjectAccessDto)
], ObjectsDto.prototype, "phoneNumbers", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsObject)(),
    __metadata("design:type", taskObjectAccess_dto_1.TaskObjectAccessDto)
], ObjectsDto.prototype, "conversations", void 0);
