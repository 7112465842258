import type { LeaseDto } from "@doorloop/dto";
import {
  LeaseRenewalOfferDto,
  LeaseRenewalOfferStatus,
  LeaseRenewalOfferTermDto,
  LeaseTermType,
  createValidator
} from "@doorloop/dto";
import type { ApiResult } from "api/apiResult";
import { leaseRenewalOfferApi } from "api/leaseRenewalOfferApi";
import { AddIcon } from "assets/icons";
import { Button } from "DLUI/button";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { LoadingDialog } from "DLUI/dialogs/components/loading";
import { Select } from "DLUI/form";
import { SectionTitle } from "DLUI/screen";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FieldArrayRenderProps, FormikProps } from "formik";
import { FastField, FieldArray, Formik } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import DialogFrame from "../components/dialogFrame";
import { DialogsHelper } from "../dialogsHelper";
import CurrentTermsItem from "./currentTermsItem";
import RenewalOfferLine from "./renewalOfferLine";
import { v4 as uuid } from "uuid";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";

interface ComponentProps {
  didFinishOperation: (values: LeaseRenewalOfferDto) => void;
  onBackdropClick: () => void;
  refreshEvent?: () => void;
}

let formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

const RenewalOffer: React.FC<ComponentProps> = ({
  didFinishOperation,
  refreshEvent,
  onBackdropClick
}: ComponentProps) => {
  const location = useLocation<any>();
  const leaseData: LeaseDto = location.state && location.state.leaseData ? location.state.leaseData : undefined;

  const propertyName = leaseData ? location.state.propertyName : "";
  const { t } = useTranslation();
  const [viewIndex, setViewIndex] = useState(0);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);

  const [loadingOfferDataErrorText, setLoadingOfferDataErrorText] = useState<string>(
    AppStrings.Leases.LeaseRenewals.LoadingOfferDataErrorText
  );

  const [renewalInfo, setRenewalInfo] = useState<LeaseRenewalOfferDto | undefined>();
  const { dialogHorizontalPadding } = DialogsHelper();

  useEffect(() => {
    loadOfferData();
    return () => {
      formikGlobalRef = null;
    };
  }, []);

  const loadOfferData = async () => {
    setLoadingDialogState(DialogState.Show);
    if (leaseData && leaseData.renewalInfo && leaseData.renewalInfo.renewalOffer) {
      const response = await leaseRenewalOfferApi.get(leaseData.renewalInfo.renewalOffer).catch((error) => {
        setLoadingOfferDataErrorText(error);
        setLoadingDialogState(DialogState.Error);
      });
      if (response && response.data) {
        setRenewalInfo(response.data);
        setLoadingDialogState(DialogState.Success);
      } else {
        setLoadingOfferDataErrorText(AppStrings.Leases.LeaseRenewals.LoadingOfferDataErrorText);
        setLoadingDialogState(DialogState.Error);
      }
    } else {
      setLoadingDialogState(DialogState.Success);
    }
  };

  const initFormvalues = (): LeaseRenewalOfferDto => {
    if (renewalInfo) {
      renewalInfo.proposedTerms?.forEach((currentTerm) => {
        currentTerm.uniqueIndex = uuid();
      });
      return renewalInfo;
    }
    const leaseRenewalOfferDto = new LeaseRenewalOfferDto();
    leaseRenewalOfferDto.lease = leaseData.id;
    if (leaseData.renewalInfo && leaseData.renewalInfo.renewalOfferStatus) {
      leaseRenewalOfferDto.status = leaseData.renewalInfo.renewalOfferStatus;
    } else {
      leaseRenewalOfferDto.status = LeaseRenewalOfferStatus.Created;
    }
    const leaseRenewalOfferTermDto = new LeaseRenewalOfferTermDto();
    let proposedEndDate = moment().add(1, "year").format("YYYY-MM-DD").toString();
    if (leaseData.end) {
      // set the proposed end date for the first line to be in one year
      proposedEndDate = moment(leaseData.end).add(1, "year").format("YYYY-MM-DD").toString();
    }
    leaseRenewalOfferDto.notifyTenants = true;
    leaseRenewalOfferTermDto.newTerm = LeaseTermType.Fixed;
    leaseRenewalOfferTermDto.newEndDate = proposedEndDate;
    leaseRenewalOfferTermDto.uniqueIndex = uuid();
    leaseRenewalOfferDto.proposedTerms = [leaseRenewalOfferTermDto];
    return leaseRenewalOfferDto;
  };

  const validateForm = createValidator(LeaseRenewalOfferDto);

  const isValidForm = async (formikRef: FormikProps<LeaseRenewalOfferDto>) => {
    formikRef.setFieldTouched("lease");
    formikRef.setFieldTouched("notifyTenants");

    const proposedTerms = formikRef.values.proposedTerms;
    if (proposedTerms) {
      proposedTerms.forEach((arrayItem: LeaseRenewalOfferTermDto, arrayIndex: number) => {
        formikRef.setFieldTouched(`proposedTerms[${arrayIndex}].newTerm`);
        formikRef.setFieldTouched(`proposedTerms[${arrayIndex}].newEndDate`);
        formikRef.setFieldTouched(`proposedTerms[${arrayIndex}].newRent`);
      });
    }

    const errors = (await formikRef.validateForm()) as any;
    return _.isEmpty(errors);
  };

  const createOffer = async () => {
    if (formikGlobalRef !== null) {
      const nextValues = _.cloneDeep(formikGlobalRef.values);
      setLoadingDialogState(DialogState.Show);
      setViewIndex(1);
      const response = (await leaseRenewalOfferApi.create(nextValues).catch((error) => {
        setLoadingDialogState(DialogState.Error);
      })) as ApiResult<any>;

      if (response.status !== undefined && !response.status) {
        setLoadingOfferDataErrorText(response.message);
        setLoadingDialogState(DialogState.Error);
      } else {
        setLoadingDialogState(DialogState.Success);
        didFinishCreatingOffer();
        formikGlobalRef = null;
      }
    }
  };

  const didPressCreateOfferButton = () => {
    if (formikGlobalRef !== null) {
      isValidForm(formikGlobalRef).then((isValid) => {
        if (isValid) {
          createOffer();
        }
      });
    }
  };

  const renderActionPanelButtons = () => {
    if (viewIndex === 1) {
      return <div />;
    }

    return (
      <FormActionButtons
        propsSubButton={{ onClick: onBackdropClick }}
        propsMainButton={{ type: "cta", props: { onClick: didPressCreateOfferButton } }}
      />
    );
  };

  const onRetryLoadInfoButtonPress = () => {
    loadOfferData();
  };

  const didPressDismissButton = () => {
    setLoadingDialogState(DialogState.Success);
    setViewIndex(0);
  };

  const didPressLoadingOfferDataDismissButton = () => {
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const clearForm = (formikGlobalRef: FormikProps<any>) => {
    formikGlobalRef.setFieldValue("name", "");
    formikGlobalRef.setFieldValue("beds", "");
    formikGlobalRef.setFieldValue("baths", "");
    formikGlobalRef.setFieldValue("size", "");
    formikGlobalRef.setFieldValue("marketRent", "");
    formikGlobalRef.setFieldTouched("name", false);
  };

  const onRetryButtonPress = () => {
    if (formikGlobalRef !== null) {
      clearForm(formikGlobalRef);
      setLoadingDialogState(DialogState.Hidden);
      setViewIndex(0);
    }
  };

  const didFinishCreatingOffer = () => {
    if (formikGlobalRef !== null) {
      didFinishOperation(formikGlobalRef.values);
      if (refreshEvent) {
        refreshEvent();
      }
      setLoadingDialogState(DialogState.Hidden);
    }
  };

  const renderAddButton = (arrayHelpers: FieldArrayRenderProps) => {
    const didPressAddChargeLine = () => {
      const leaseRenewalOfferTermDto = new LeaseRenewalOfferTermDto();
      leaseRenewalOfferTermDto.newTerm = LeaseTermType.Fixed;
      let proposedEndDate = moment().add("1", "year").format("YYYY-MM-DD").toString();
      if (leaseData.end) {
        // set the proposed end date for the first line to be in one year
        proposedEndDate = moment(leaseData.end).add(1, "year").format("YYYY-MM-DD").toString();
      }
      leaseRenewalOfferTermDto.newEndDate = proposedEndDate;
      leaseRenewalOfferTermDto.uniqueIndex = uuid();
      arrayHelpers.push(leaseRenewalOfferTermDto);
    };
    let numOfProposedTerms = 1;
    if (formikGlobalRef) {
      const ProposedTerms = formikGlobalRef.values.proposedTerms;
      numOfProposedTerms = ProposedTerms.length;
    }
    return (
      <View shouldShow={numOfProposedTerms < 3} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
        <Button
          color={"lightBlue"}
          type={"inlineText"}
          actionText={AppStrings.Leases.LeaseRenewals.AddAnotherOffer}
          onClick={didPressAddChargeLine}
          LeftIcon={AddIcon}
          applyColorForIcons
          iconSize={15}
        />
      </View>
    );
  };

  const renderProposedTermSection = () => (
    <FieldArray
      name={"proposedTerms"}
      render={(arrayHelpers) => {
        if (formikGlobalRef && formikGlobalRef.values && formikGlobalRef.values.proposedTerms) {
          const formLines = formikGlobalRef.values.proposedTerms;

          const lines = formLines.map((currentLine: LeaseRenewalOfferTermDto, lineIndex: number) => (
            <div
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                width: "100%"
              }}
              key={"LTL" + currentLine.uniqueIndex}
            >
              <View shouldShow showAnimation={"fade-in"} hideAnimation={"fade-out"}>
                <RenewalOfferLine
                  transactionItem={currentLine}
                  lineIndex={lineIndex}
                  arrayHelpers={arrayHelpers}
                  formikRef={formikGlobalRef!}
                  leaseEndDate={leaseData.end}
                />
              </View>
            </div>
          ));

          return (
            <View>
              {lines}
              <View>{renderAddButton(arrayHelpers)}</View>
            </View>
          );
        }
        return <div />;
      }}
    />
  );

  const renderOfferStatus = () => {
    if (renewalInfo) {
      return (
        <View marginTop={20} justifyContent={"center"} alignItems={"center"}>
          <SeparationLine width={"90%"} height={1} />
          <View marginTop={dialogHorizontalPadding} paddingLeft={dialogHorizontalPadding}>
            <SectionTitle title={AppStrings.Leases.LeaseRenewals.OfferStatus} type={"underline"} />
            <View marginTop={30} width={200}>
              <FastField
                component={Select}
                name={`status`}
                label={AppStrings.Leases.LeaseRenewals.OfferStatus}
                required
                uniqueKey={"status"}
                selectionEnum={LeaseRenewalOfferStatus}
              />
            </View>
          </View>
        </View>
      );
    }
  };

  const renderForm = () => {
    const formInitialValues = initFormvalues();
    return (
      <Formik initialValues={formInitialValues} onSubmit={(values, { setSubmitting }) => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;

          return (
            <View marginTop={20}>
              <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding}>
                <SectionTitle title={AppStrings.Leases.LeaseRenewals.CurrentTerms} type={"underline"} />
                <CurrentTermsItem leaseData={leaseData} propertyName={propertyName} />
                <SectionTitle
                  title={AppStrings.Leases.LeaseRenewals.PoposedNewTerms}
                  type={"underline"}
                  marginTop={20}
                />
                <View>
                  <Text
                    color={"black"}
                    marginTop={20}
                    marginBottom={20}
                    fontSize={16}
                    value={AppStrings.Leases.LeaseRenewals.ProposedNewTermsDescription}
                  />
                  {renderProposedTermSection()}
                </View>
              </View>
              {/*<View marginTop={20} alignItems={"center"}>*/}
              {/*  <SeparationLine height={1} width={"90%"} />*/}
              {/*  <View*/}
              {/*    marginTop={20}*/}
              {/*    paddingLeft={dialogHorizontalPadding}*/}
              {/*    paddingRight={dialogHorizontalPadding}*/}
              {/*  >*/}
              {/*    <FastField*/}
              {/*      component={FormikSwitchButton}*/}
              {/*      name={"notifyTenants"}*/}
              {/*      label={*/}
              {/*        AppStrings.Leases.LeaseRenewals.NotifyTenantsDescription*/}
              {/*      }*/}
              {/*    />*/}
              {/*  </View>*/}
              {/*</View>*/}
              {renderOfferStatus()}
            </View>
          );
        }}
      </Formik>
    );
  };

  const renderView = ({ index }: any) => {
    if (index === 0) {
      if (!leaseData || loadingDialogState !== DialogState.Success) {
        return (
          <View flex={1}>
            <LoadingDialog
              dialogState={loadingDialogState}
              loadingText={t(AppStrings.Leases.LeaseRenewals.LoadingOfferData)}
              errorText={t(AppStrings.Leases.LeaseRenewals.LoadingOfferDataErrorText)}
              successText={t(AppStrings.Leases.LeaseRenewals.LoadingOfferDataSuccessText)}
              onRetryButtonPress={onRetryLoadInfoButtonPress}
              didPressDismissButton={didPressLoadingOfferDataDismissButton}
            />
          </View>
        );
      }
      return renderForm();
    }
    if (index === 1) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={t(AppStrings.Leases.LeaseRenewals.CreatingOffer)}
            errorText={loadingOfferDataErrorText}
            successText={t(AppStrings.Units.NewUnitDialog.SuccessText)}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
          />
        </View>
      );
    }
    return <div />;
  };

  const _onBackdropClick = () => {
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      if (loadingDialogState !== DialogState.Success) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }

    return "contentOnly";
  }, [viewIndex, loadingDialogState]);

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={AppStrings.Leases.LeaseRenewals.NewRenewalOffer}
      width={1000}
      height={renewalInfo ? 950 : 780}
      renderView={renderView}
      numViews={2}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={frameType}
    />
  );
};

export default RenewalOffer;
