"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HOAExpensePayToResourceType = exports.ExpensePayToResourceType = void 0;
var ExpensePayToResourceType;
(function (ExpensePayToResourceType) {
    ExpensePayToResourceType["VENDOR"] = "VENDOR";
    ExpensePayToResourceType["OWNER"] = "OWNER";
    ExpensePayToResourceType["TENANT"] = "TENANT";
})(ExpensePayToResourceType || (exports.ExpensePayToResourceType = ExpensePayToResourceType = {}));
var HOAExpensePayToResourceType;
(function (HOAExpensePayToResourceType) {
    HOAExpensePayToResourceType["VENDOR"] = "VENDOR";
    HOAExpensePayToResourceType["TENANT"] = "TENANT";
})(HOAExpensePayToResourceType || (exports.HOAExpensePayToResourceType = HOAExpensePayToResourceType = {}));
