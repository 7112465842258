import React from "react";
import { Notification } from "DLUI/notification/notification";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { LearnMoreText } from "DLUI/text/learnMoreText";
import { LinkURLsEnum } from "DLUI/link/linkURLsEnum";
import ColorsEnum from "utils/colorsEnum";
import { AlertWarningIcon } from "../../../../assets";
import type { LeaseChargeDto } from "@doorloop/dto";
import { useFormikContext } from "formik";
import { useResponsiveHelper } from "contexts/responsiveContext";

export const ReversePaymentNotification: React.FC = () => {
  const { isMobile } = useResponsiveHelper();
  const formik = useFormikContext<LeaseChargeDto>();

  return (
    <Notification
      marginTop={20}
      iconSize={isMobile ? 27 : 20}
      minHeight={isMobile ? 90 : 60}
      LabelComponent={() => (
        <View>
          <Text
            marginLeft={5}
            fontWeight={400}
            fontSize={14}
            color={"secondary-yellow"}
            value={formik.values.memo}
            marginBottom={5}
          />
          <LearnMoreText fontSize={14} href={LinkURLsEnum.LEARN_MORE_STRIPE_FAILED_CHARGE_FEE} />
        </View>
      )}
      background={ColorsEnum.LightYellow}
      separatorColor={ColorsEnum.DarkYellow}
      iconPathColor={"secondary-yellow"}
      IconComponent={AlertWarningIcon}
    />
  );
};
