import type { StaticRemoteOrDateFilter } from "DLUI/screen/filterPanel/filterPanel";
import AppStrings from "locale/keys";
import { CommunicationDeliveryStatus } from "@doorloop/dto";
import { CommunicationType } from "@doorloop/dto";
import type { GetAllCommunicationsQuery } from "@doorloop/dto";

export const FilterOptions: Array<StaticRemoteOrDateFilter<GetAllCommunicationsQuery>> = [
  {
    label: AppStrings.Announcements.sentAt,
    type: "dateRangePicker",
    period: "all-time",
    startDateFieldKey: "filter_sentAt_from",
    endDateFieldKey: "filter_sentAt_to"
  },
  {
    type: "staticFiltering",
    name: AppStrings.Common.FilterOptions.Status,
    values: [
      {
        displayName: AppStrings.Announcements.draft,
        value: CommunicationDeliveryStatus.DRAFT,
        filterFieldName: "filter_status"
      },
      {
        displayName: AppStrings.Announcements.failed,
        value: CommunicationDeliveryStatus.FAILED,
        filterFieldName: "filter_status"
      },
      {
        displayName: AppStrings.Announcements.bounced,
        value: CommunicationDeliveryStatus.BOUNCED,
        filterFieldName: "filter_status"
      },
      {
        displayName: AppStrings.Announcements.complaint,
        value: CommunicationDeliveryStatus.COMPLAINT,
        filterFieldName: "filter_status"
      },
      {
        displayName: AppStrings.Announcements.sent,
        value: CommunicationDeliveryStatus.SENT,
        filterFieldName: "filter_status"
      },
      {
        displayName: AppStrings.Announcements.Delivered,
        value: CommunicationDeliveryStatus.DELIVERED,
        filterFieldName: "filter_status"
      },
      {
        displayName: AppStrings.Announcements.Opened,
        value: CommunicationDeliveryStatus.OPENED,
        filterFieldName: "filter_status"
      },
      {
        displayName: AppStrings.Announcements.Clicked,
        value: CommunicationDeliveryStatus.CLICKED,
        filterFieldName: "filter_status"
      }
    ]
  },
  {
    type: "staticFiltering",
    name: AppStrings.Announcements.type,
    values: [
      {
        displayName: AppStrings.Announcements.email,
        value: CommunicationType.EMAIL,
        filterFieldName: "filter_type"
      },
      {
        displayName: AppStrings.Announcements.textMessage,
        value: CommunicationType.TEXT,
        filterFieldName: "filter_type"
      },
      {
        displayName: AppStrings.Announcements.phoneCall,
        value: CommunicationType.PHONE,
        filterFieldName: "filter_type"
      }
    ]
  }
];

export default FilterOptions;
