import type { IRootState } from "@/store";
import { useSelector } from "react-redux";

export function useRemoteDefaultValue(defaultValue?: string) {
  const userData = useSelector((state: IRootState) => state.auth.currentLoginResponse);
  if (defaultValue === "currentUser" && userData?.id) {
    return userData.id;
  }

  return defaultValue;
}
