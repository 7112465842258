"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisplayEmailDto = exports.EmailDto = exports.EmailType = void 0;
const class_validator_1 = require("class-validator");
const validators_1 = require("../common/validators");
var EmailType;
(function (EmailType) {
    EmailType["PRIMARY"] = "Primary";
    EmailType["SECONDARY"] = "Secondary";
    EmailType["PERSONAL"] = "Personal";
    EmailType["WORK"] = "Work";
    EmailType["OTHER"] = "Other";
})(EmailType || (exports.EmailType = EmailType = {}));
class EmailDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.EmailDto = EmailDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(EmailType),
    __metadata("design:type", String)
], EmailDto.prototype, "type", void 0);
__decorate([
    (0, validators_1.IsOptionalEmail)({ message: "This email is invalid" }),
    __metadata("design:type", String)
], EmailDto.prototype, "address", void 0);
class DisplayEmailDto extends EmailDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.DisplayEmailDto = DisplayEmailDto;
