import { useTypedTranslation } from "@/locale";
import { FilterNew } from "@/assets";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import type { Theme } from "@/styles/defaultTheme";
import { DataCy } from "@doorloop/dto";
import { useResponsiveHelper } from "@/contexts/responsiveContext";

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      color: theme.newPalette.secondary.main,
      fontSize: 14,
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
      whiteSpace: "nowrap",
      gap: 6,
      padding: "0 8px",
      borderRadius: 4,
      height: 32,
      "&:hover": {
        backgroundColor: theme.newPalette.secondary.states.hover
      },
      "&:active": {
        backgroundColor: theme.newPalette.secondary.states.focus
      },
      "& span": {
        fontFamily: "Lato, sans-serif !important"
      },
      "& svg": {
        flexShrink: 0
      }
    },
    rootMobile: {
      height: 48,
      padding: "0 18px",
      border: `1px solid ${theme.newPalette.other.divider}`,
      backgroundColor: theme.newPalette.background.paper,
      "&:hover": {
        backgroundColor: "none"
      }
    }
  };
});

function getButtonText(totalChips: number, t: ReturnType<typeof useTypedTranslation>["t"], isMobile: boolean) {
  if (isMobile) {
    return totalChips > 0 ? `(${totalChips})` : "";
  }

  return totalChips > 0 ? `${t("common.filter")} (${totalChips})` : t("common.filter");
}

interface FilterButtonProps {
  totalChips?: number;
  onClick?: VoidFunction;
}

export function FilterButton({ totalChips = 0, onClick }: FilterButtonProps) {
  const classes = useStyles();
  const { isMobile } = useResponsiveHelper();
  const { t } = useTypedTranslation();

  return (
    <button
      onClick={onClick}
      className={clsx(classes.root, "border-box", "button-reset", {
        [classes.rootMobile]: isMobile
      })}
      data-cy={DataCy.DLUI.filters.toggleButton}
    >
      <FilterNew />
      <span>{getButtonText(totalChips, t, isMobile)}</span>
    </button>
  );
}
