import { RestApiBase } from "../restApiBase";
import type { GetAllNotificationsQuery, PushNotificationDto } from "@doorloop/dto";
import { TenantPortalServerRoutes } from "@doorloop/dto";

import { apiHelper } from "api/apiHelper";

export class TenantPortalNotificationApi extends RestApiBase<PushNotificationDto, GetAllNotificationsQuery> {
  constructor() {
    super(TenantPortalServerRoutes.NOTIFICATIONS, []);
    this.restRoute = TenantPortalServerRoutes.NOTIFICATIONS;
  }

  getTotalUnreadNotifications = async () =>
    await apiHelper.axiosGet(TenantPortalServerRoutes.TOTAL_UNREAD_NOTIFICATIONS);

  markAllUnreadNotificationsAsRead = async () =>
    await apiHelper.axiosPut({ url: TenantPortalServerRoutes.MARK_ALL_UNREAD_NOTIFICATIONS });

  getTenantNotificationsSettings = async (leaseId?: string) => {
    const paramsObj = {
      leaseId
    };
    return await apiHelper.axiosGet(TenantPortalServerRoutes.TENANTS_NOTIFICATION_SETTINGS + "?", paramsObj);
  };

  updateTenantNotificationsSettings = async (data) =>
    await apiHelper.axiosPut({ url: TenantPortalServerRoutes.TENANTS_NOTIFICATION_SETTINGS, data });
}

export const tenantPortalNotificationApi = new TenantPortalNotificationApi();
