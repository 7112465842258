import type { GetAllTasksQuery } from "@doorloop/dto";
import { TaskPriority, TaskType } from "@doorloop/dto";
import { usersApi } from "api/usersApi";
import type { FilterPanelOptionItem } from "DLUI/screen/filterPanel/filterPanel";
import AppStrings from "locale/keys";
import { TaskStatusForQuery } from "@doorloop/dto";
import { propertiesApi } from "api/propertiesApi";
import { unitsApi } from "api/unitsApi";
import type { FilterOption } from "@/components/DLUI/screen/filterPanel/filterInputs/types";
import { toTypedKey } from "@/locale";

export const taskStatusFilterValues: Array<FilterPanelOptionItem<GetAllTasksQuery>> = [
  {
    displayName: AppStrings.Tasks.Screen.FilterOptions.NotStarted,
    value: TaskStatusForQuery.NOT_STARTED,
    filterFieldName: "filter_status"
  },
  {
    displayName: AppStrings.Common.Enums.TaskStatus.RECEIVED,
    value: TaskStatusForQuery.RECEIVED,
    filterFieldName: "filter_status"
  },
  {
    displayName: AppStrings.Tasks.Screen.FilterOptions.InProgress,
    value: TaskStatusForQuery.IN_PROGRESS,
    filterFieldName: "filter_status"
  },
  {
    displayName: AppStrings.Tasks.Screen.FilterOptions.Completed,
    value: TaskStatusForQuery.COMPLETED,
    filterFieldName: "filter_status"
  },
  {
    displayName: AppStrings.Tasks.Screen.FilterOptions.NotCompleted,
    value: TaskStatusForQuery.NOT_COMPLETED,
    filterFieldName: "filter_status"
  },
  {
    displayName: AppStrings.Common.Enums.TaskStatus.ARCHIVED,
    value: TaskStatusForQuery.ARCHIVED,
    filterFieldName: "filter_status"
  }
];

export const filterOptions: Array<FilterOption<GetAllTasksQuery>> = [
  {
    label: AppStrings.Tasks.Screen.DueDate,
    type: "dateRangePicker",
    period: "all-time",
    periodDateFieldKey: "filter_period_dueDate",
    startDateFieldKey: "filter_dueDate_from",
    endDateFieldKey: "filter_dueDate_to"
  },
  {
    label: AppStrings.Tasks.Screen.CreatedAt,
    type: "dateRangePicker",
    period: "all-time",
    periodDateFieldKey: "filter_period_createdAt",
    startDateFieldKey: "filter_createdAt_from",
    endDateFieldKey: "filter_createdAt_to"
  },
  {
    type: "remoteFiltering",
    name: AppStrings.Tasks.TasksList.assignedTo,
    apiHandler: usersApi,
    label: toTypedKey("common.filterOptions.filterBy"),
    displayNameKey: "name",
    filterFieldName: "filter_assignedToUser",
    filterFieldValue: "id"
  },
  {
    type: "remoteFiltering",
    name: AppStrings.Tasks.Screen.RequestedBy,
    apiHandler: usersApi,
    displayNameKey: "name",
    filterFieldName: "filter_requestedById",
    filterFieldValue: "id"
  },

  {
    type: "staticFiltering",
    name: AppStrings.Common.FilterOptions.Status,
    values: taskStatusFilterValues
  },
  {
    type: "staticFiltering",
    name: AppStrings.Tasks.InternalTaskDialog.Priority,
    values: [
      {
        displayName: AppStrings.Tasks.Screen.FilterOptions.Low,
        value: TaskPriority.LOW,
        filterFieldName: "filter_priority"
      },
      {
        displayName: AppStrings.Tasks.Screen.FilterOptions.Medium,
        value: TaskPriority.MEDIUM,
        filterFieldName: "filter_priority"
      },
      {
        displayName: AppStrings.Tasks.Screen.FilterOptions.High,
        value: TaskPriority.HIGH,
        filterFieldName: "filter_priority"
      }
    ]
  },
  {
    name: AppStrings.Common.FilterOptions.Type,
    type: "staticFiltering",
    values: [
      {
        displayName: AppStrings.Tasks.TasksList.InternalTask,
        value: TaskType.INTERNAL_TASK,
        filterFieldName: "filter_type"
      },
      {
        displayName: AppStrings.Tasks.TasksList.TenantRequest,
        value: TaskType.TENANT_REQUEST,
        filterFieldName: "filter_type"
      },
      {
        displayName: AppStrings.Tasks.TasksList.OwnerRequest,
        value: TaskType.OWNER_REQUEST,
        filterFieldName: "filter_type",
        hiddenForUserTypes: ["HOAUser"]
      },
      {
        displayName: AppStrings.Tasks.InternalTaskDialog.WorkOrder,
        value: TaskType.WORK_ORDER,
        filterFieldName: "filter_type"
      }
    ]
  },
  {
    name: AppStrings.Common.FilterOptions.Property,
    type: "remoteFiltering",
    apiHandler: propertiesApi,
    displayNameKey: "name",
    filterFieldName: "filter_property",
    filterFieldValue: "id"
  },
  {
    name: AppStrings.Common.FilterOptions.Unit,
    type: "remoteFiltering",
    apiHandler: unitsApi,
    displayNameKey: "name",
    filterFieldName: "filter_unit",
    filterFieldValue: "id"
  }
];
