import type { PictureDto, ReportPermission } from "@doorloop/dto";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import _ from "lodash";
import type { ReportScreenSectionGroupItemType } from "DLUI/report/screen/reportScreenSectionGroupItemType";

const extractReportSectionPermissions = (
  sectionGroups: ReportScreenSectionGroupItemType[]
): AnyPermissionClearance[] => {
  const allPermissions: ReportPermission[] = [];
  sectionGroups.forEach(({ items }) =>
    items.forEach(({ permission }) => permission && allPermissions.push(permission))
  );
  return [...new Set(allPermissions)].map((permission) => {
    return { permission };
  });
};

export const ArrayUtils = {
  getFirstHalf: <T>(arr: T[]): T[] => arr.slice(0, Math.ceil(arr.length / 2)),
  getSecondHalf: <T>(arr: T[]): T[] => arr.slice(Math.ceil(arr.length / 2), arr.length),
  extractReportSectionPermissions,
  move: <T>(arr: T[], fromIndex: number, toIndex: number): void => {
    arr.splice(toIndex, 0, arr.splice(fromIndex, 1)[0]);
  }
};

type MapInterface<KEY_TYPE extends string, VALUE> = {
  [key in KEY_TYPE]?: VALUE;
};

export const reduceArrayToObjectKeys = <T extends string, VAL>(
  arr: T[],
  value: VAL | (() => VAL)
): MapInterface<T, VAL> =>
  _.reduce(
    arr,
    (acc, item) => {
      return {
        ...acc,
        [item]: _.isFunction(value) ? value() : value
      };
    },
    {}
  );

export type BooleanMapInterface<T extends string = string> = MapInterface<T, boolean>;

// ["a", "b"] <--> { a: true, b: true }
export const BooleanMap = {
  toMap: <T extends string>(arr: T[]): BooleanMapInterface<T> => reduceArrayToObjectKeys(arr, true),
  toArray: <T extends string>(map: BooleanMapInterface<T>): T[] => _.keys(_.pickBy(map, (x) => x)) as T[]
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
export const rankSorter = (pic1: PictureDto, pic2: PictureDto) => {
  const rank1 = pic1.rank ?? 0;
  const rank2 = pic2.rank ?? 0;

  if (rank1 < rank2) {
    return -1;
  }

  if (rank1 > rank2) {
    return 1;
  }

  return 0;
};
