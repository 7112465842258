import type { FilterPanelDateRangePicker } from "@/components/DLUI/form/datePicker/dateRangePicker/types";
import type {
  FilterPanelDatePicker,
  FilterPanelRemoteFiltering,
  FilterPanelStaticOptionsFiltering,
  FilterPanelTextFieldFiltering
} from "@/components/DLUI/screen/filterPanel/filterPanel";

export function isStaticFilterItem<TFilter, TTranslation = string>(
  filterOption: unknown
): filterOption is FilterPanelStaticOptionsFiltering<TFilter, TTranslation> {
  return (filterOption as FilterPanelStaticOptionsFiltering<TFilter, TTranslation>).type === "staticFiltering";
}

export function isRemoteFilterItem<TFilter, TTranslation = string>(
  filterOption: unknown
): filterOption is FilterPanelRemoteFiltering<TFilter, TTranslation> {
  return (filterOption as FilterPanelRemoteFiltering<TFilter, TTranslation>).type === "remoteFiltering";
}

export function isMultiSelectFilterItem<TFilter, TTranslation = string>(
  filterOption: unknown
): filterOption is FilterPanelRemoteFiltering<TFilter, TTranslation> {
  return (filterOption as FilterPanelRemoteFiltering<TFilter, TTranslation>).type === "remoteMultiFiltering";
}

export function isDatePickerFilterItem<TFilter, TTranslation = string>(
  filterOption: unknown
): filterOption is FilterPanelDatePicker<TFilter, TTranslation> {
  return (filterOption as FilterPanelDatePicker<TFilter, TTranslation>).type === "datePicker";
}

export function isDateRangePickerFilterItem<TFilter, TTranslation = string>(
  filterOption: unknown
): filterOption is FilterPanelDateRangePicker<TFilter, TTranslation> {
  return (filterOption as FilterPanelDateRangePicker<TFilter, TTranslation>).type === "dateRangePicker";
}

export function isTextFilteringFilterItem<TFilter>(
  filterOption: unknown
): filterOption is FilterPanelTextFieldFiltering<TFilter> {
  return (filterOption as FilterPanelTextFieldFiltering<TFilter>).type === "textFieldFiltering";
}

export function hasDefaultValue<TFilter, TTranslation = string>(
  filterOption: unknown
): filterOption is
  | FilterPanelStaticOptionsFiltering<TFilter, TTranslation>
  | FilterPanelRemoteFiltering<TFilter, TTranslation> {
  return (
    (
      filterOption as
        | FilterPanelStaticOptionsFiltering<TFilter, TTranslation>
        | FilterPanelRemoteFiltering<TFilter, TTranslation>
    ).defaultValue !== undefined
  );
}
