"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAllUnitsQuery = void 0;
const getAllBaseRequest_query_1 = require("../getAllBaseRequest.query");
const class_validator_1 = require("class-validator");
const types_1 = require("../properties/types");
class GetAllUnitsQuery extends getAllBaseRequest_query_1.GetAllBaseQueryRequest {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.GetAllUnitsQuery = GetAllUnitsQuery;
__decorate([
    (0, class_validator_1.IsEnum)(types_1.PropertyClass),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllUnitsQuery.prototype, "filter_propertyClass", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(types_1.PropertyType),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllUnitsQuery.prototype, "filter_propertyType", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllUnitsQuery.prototype, "filter_property", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllUnitsQuery.prototype, "filter_tenant", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    (0, class_validator_1.IsArray)(),
    __metadata("design:type", Array)
], GetAllUnitsQuery.prototype, "filter_properties", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllUnitsQuery.prototype, "filter_owner", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllUnitsQuery.prototype, "filter_addressSameAsProperty", void 0);
__decorate([
    (0, class_validator_1.Length)(24, 24),
    (0, class_validator_1.IsHexadecimal)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllUnitsQuery.prototype, "filter_group", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllUnitsQuery.prototype, "filter_listed", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllUnitsQuery.prototype, "filter_rentalAppListed", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllUnitsQuery.prototype, "filter_occupied", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllUnitsQuery.prototype, "filter_inEviction", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Object)
], GetAllUnitsQuery.prototype, "filter_active", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], GetAllUnitsQuery.prototype, "filter_batch", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsString)({ each: true }),
    __metadata("design:type", Array)
], GetAllUnitsQuery.prototype, "filter_multipleRecordsByText", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllUnitsQuery.prototype, "filter_text_searchByProperty", void 0);
