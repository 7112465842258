import type { Component, FC, ReactElement } from "react";
import type { Path, PathValue } from "react-hook-form";
import type { TextFormatType } from "DLUI/text/text";
import type { GetAllBaseQueryRequest, RentRollReportItem } from "@doorloop/dto";
import type { ObjectWithHiddenForUserTypes } from "utils/userTypesUtils";

export const SCROLLABLE_GRID_MAX_HEIGHT = "72vh";

/**
 * Selection Identifier is the name of the unique key in the data object
 * that is used to check if the row is selected or not
 *
 * @example
 * const dataSource = {
 *     data: [
 *        {
 *          id: 1,
 *          name: "John",
 *          age: 20
 *          [SELECTION_IDENTIFIER]: true
 *        }
 *     ]
 *   }
 * }
 */
export const SELECTION_IDENTIFIER = "selected";
export const EXPANSION_IDENTIFIER = "expanded";
export const EDITING_IDENTIFIER = "editing";

export type WellKnownTranslatableField =
  | "unit"
  | "account"
  | "property"
  | "lease"
  | "vendor"
  | "tenant"
  | "owner"
  | "journalEntryType"
  | "category";

/**
 * GridRowWithId guarantees that the Dto row has an id property
 */
export type GridRowWithId<DtoType> = {
  id: string;
} & DtoType;

export type GridListType = "fixed" | "scrollable" | "virtual";

export type InferReactComponentProps<T> =
  T extends FC<infer P>
    ? P
    : T extends Component<infer P>
      ? P
      : T extends new (props: infer P) => Component
        ? P
        : never;

/**
 * The original untranslated mongo document id.
 *
 * Useful on CellPress to navigate to different pages, show dialogs, etc.
 *
 * The type ORIGINAL_MONGO_ID_STRING serves no functional purpose, it's just there to show up on hover in the IDE
 */
type ORIGINAL_MONGO_ID_STRING = string & {};

/**
 * All the fields in the Dto that are Well known translatable fields must have a duplicate field with a _ prefix
 * that contains the original mongo document id.
 * @see translateFieldValue
 * @see WellKnownTranslatableField
 *
 * @example
 * { unit: "John's Shiny Apartment", _unit: "a3b4c5d6e7f8g9h0f" }

 */
export type TranslatedRow<DtoType> = {
  [K in keyof DtoType as K extends WellKnownTranslatableField ? `_${K}` : never]?: ORIGINAL_MONGO_ID_STRING;
} & DtoType;

export type GridColumn<TDto = any> = {
  [TField in Path<TDto>]: {
    className?: string;
    field?: TField;
    title: string;
    show: boolean;
    width?: number;
    minWidth?: number;
    formatType?: TextFormatType;
    translate?: (cellValue: NonNullable<PathValue<TDto, TField>>, currentItem: TDto) => string;
    translateIgnoreLocaleStrings?: boolean;
    translateEmptyCells?: boolean;
    aggregate?: "sum" | "average";
    headerSelectionValue?: boolean;
    renderCell?: (data: any) => ReactElement;
    footerCell?: {
      text?: string;
      sum?: boolean;
      average?: boolean;
      formatType?: TextFormatType;
    };
    groupHeader?: {
      customCell?: (dataProps?: any) => string;
    };
    groupFooterCell?: {
      totalForGroup?: boolean;
      text?: string;
      sum?: boolean;
      average?: boolean;
      customCell?: (dataItems: RentRollReportItem[]) => string[];
    };
    onCellPress?: (currentItem: TranslatedRow<TDto>) => void;
    subColumns?: GridSubColumn[];
    showZeroValues?: boolean;
    editOnClick?: boolean;
    editComponent?: (
      defaultValue: NonNullable<PathValue<TDto, TField>>,
      onChange: (value: NonNullable<PathValue<TDto, TField>>) => void
    ) => JSX.Element;
    getRoute?: (currentItem: TranslatedRow<TDto>) => string;
  };
}[Path<TDto>] &
  ObjectWithHiddenForUserTypes;

export type VisibleGridColumn = Omit<GridColumn, "show">;
export type GridSubColumn = Omit<GridColumn, "show">;

export type Queryable = GetAllBaseQueryRequest &
  Record<string, any> & {
    groupBy?: string | undefined;
  };

export interface TreeListVirtualizationProps {
  virtual?: boolean;
  virtualRowHeight?: number;
  virtualTableHeight?: number;
}
