import React, { useEffect, useMemo, useState } from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import type { FieldProps } from "formik";
import { FastField, getIn } from "formik";
import AppStrings from "locale/keys";
import type { TextTransformProp } from "DLUI/text/text";
import ViewOnlyInput from "../viewOnlyInput/viewOnlyInput";
import TextField from "../textField/textField";
import { DataCy } from "@doorloop/dto";

import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { renderReferenceString } from "../../../../utils/strings";

interface ComponentProps extends FieldProps<any> {
  value?: string;
  marginRight?: number;
  backgroundColor?: "dark" | "secondary-gray";
  viewOnly?: boolean;
  minWidth?: number;
  label?: string;
  valueTextTransform?: TextTransformProp;
  paddingLeft?: number;
  editable?: boolean;
  dataCy?: string;
}

const DefaultMinWidth = 220;

const FormikReferenceLabel: React.FC<ComponentProps> = ({
  field,
  form,
  value,
  viewOnly,
  backgroundColor,
  marginRight,
  minWidth,
  label,
  valueTextTransform = "uppercase",
  paddingLeft,
  editable,
  dataCy
}: ComponentProps) => {
  const { isMobile } = useResponsiveHelper();
  const currentFieldValue = getIn(form.values, field.name);
  const [reference, setReference] = useState(currentFieldValue);
  useEffect(() => {
    if (currentFieldValue) {
      setReference(currentFieldValue);
    } else if (value) {
      setReference(value);
    } else {
      generateReferenceLabel();
    }
  }, []);

  useEffect(() => {
    if (currentFieldValue !== reference) {
      form.setFieldValue(field.name, reference);
    }
  }, [reference]);

  const generateReferenceLabel = () => {
    const randomLabel = renderReferenceString();
    setReference(randomLabel);
  };

  const className = backgroundColor === "secondary-gray" ? "backgroundOpacity" : "";

  if (editable) {
    return (
      <FastField
        component={TextField}
        name={`reference`}
        label={label}
        defaultValue={reference}
        textTransform={valueTextTransform}
      />
    );
  }
  if (viewOnly) {
    return (
      <ViewOnlyInput
        value={"#" + reference}
        label={label || AppStrings.Common.ListHeader.Reference}
        backgroundColor={"transparent"}
        textTransform={valueTextTransform}
        fullWidth
        marginLeft={0}
        dataCy={dataCy}
      />
    );
  }

  const styles = useMemo(() => (isMobile ? { height: 50, fontSize: 16 } : { height: 40, fontSize: 14 }), [isMobile]);

  return (
    <View
      className={className}
      justifyContent={"flex-start"}
      alignItems={"center"}
      height={styles.height}
      borderRadius={5}
      minWidth={minWidth || DefaultMinWidth}
      backgroundColor={backgroundColor}
      width={"100%"}
      marginRight={marginRight}
      paddingLeft={paddingLeft}
      flexDirection={"row"}
      style={{
        backgroundColor: backgroundColor === "secondary-gray" ? "rgba(213,217,227,0.8)" : "none"
      }}
    >
      <Text
        value={label || AppStrings.Common.ListHeader.Reference}
        color={"black"}
        fontSize={styles.fontSize}
        marginLeft={20}
      />
      <View flex={1} alignItems={"flex-end"}>
        <Text
          textTransform={"uppercase"}
          color={"black"}
          fontSize={styles.fontSize}
          marginRight={20}
          dataCy={DataCy.transactions.referenceNumber}
        >
          #{reference}
        </Text>
      </View>
    </View>
  );
};

export default FormikReferenceLabel;
