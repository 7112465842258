export const Navigation = {
  Next: "navigation.next",
  Back: "navigation.back",
  Continue: "navigation.continue",
  Finish: "navigation.finish",
  Submit: "navigation.submit",
  GotIt: "navigation.gotIt",
  Helper: {
    Dismiss: "navigation.helper.dismiss",
    ScheduleACall: "navigation.helper.scheduleACall",
    NewLeaseWizardBanner: {
      Title: "navigation.helper.newLeaseWizardBanner.title",
      Description: "navigation.helper.newLeaseWizardBanner.description"
    }
  },
  UpdateNow: "navigation.updateNow"
};

export default Navigation;
