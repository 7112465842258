import type { DialogProps } from "./dialogsProvider.types";
import { DialogRoutes } from "./dialogsRoutes";
import Routes from "../../../appRouter/routes";
import AppStrings from "locale/keys";
import {
  DepositDialog,
  MoveInTenantsDialog,
  MoveOutTenantsDialog,
  NotRenewingDialog,
  OneTimeChargeDialog,
  OneTimeCreditDialog,
  PaymentDialog,
  RecurringChargeDialog,
  RecurringCreditDialog,
  RecurringPaymentDialog,
  RecurringRentDialog,
  RefundDepositDialog,
  RefundDialog,
  RenewalOfferDialog,
  WithholdDepositDialog
} from "DLUI/dialogs";
import {
  EditLeaseLateFeesDialog,
  EditLeasePaymentsAllocationsDialog,
  EditLeaseRentersInsuranceDialog,
  EditLeaseDialog
} from "DLUI/dialogs/settings";
import InsurancePolicyFormikContextWrapper from "DLUI/dialogs/insurancePolicy/insurancePolicyFormikContextWrapper";
import EvictionStatusFormikContextWrapper from "DLUI/dialogs/settings/leases/evictionStatusDialog/evictionStatusFormikContextWrapper";
import EditLeaseNameFormikContextWrapper from "DLUI/dialogs/settings/leases/editNameDialog/editNameFormikContextWrapper";

class LeasesDialogsProvider {
  matchRoutes(pathname: string): DialogProps | undefined {
    if (pathname.indexOf(DialogRoutes.EDIT_APPLIED_CREDIT) > -1) {
      return {
        type: "appliedCredit",
        Content: PaymentDialog,
        title: AppStrings.Common.AppliedCredit
      };
    }
    if (pathname.indexOf(DialogRoutes.NEW_BULK_CHARGE) > -1) {
      return {
        type: "newBulkCharge",
        Content: OneTimeChargeDialog,
        title: AppStrings.Leases.LeaseCharge.BulkPostCharges
      };
    }
    if (pathname.indexOf(DialogRoutes.NEW_BULK_CREDIT) > -1) {
      return {
        type: "newBulkCredit",
        Content: OneTimeCreditDialog,
        title: AppStrings.Leases.LeaseCredit.BulkIssueCredits
      };
    }
    if (
      pathname.indexOf(DialogRoutes.NEW_RECURRING_CHARGE) > -1 ||
      pathname.indexOf(DialogRoutes.EDIT_RECURRING_CHARGE) > -1
    ) {
      return {
        type: "newRecurringCharge",
        Content: RecurringChargeDialog
      };
    }
    if (
      pathname.indexOf(DialogRoutes.NEW_RECURRING_CREDIT) > -1 ||
      pathname.indexOf(DialogRoutes.EDIT_RECURRING_CREDIT) > -1
    ) {
      return {
        type: "newRecurringCredit",
        Content: RecurringCreditDialog
      };
    }
    if (pathname.indexOf(DialogRoutes.NEW_CHARGE) > -1 || pathname.indexOf(DialogRoutes.EDIT_CHARGE) > -1) {
      return {
        type: "newCharge",
        Content: OneTimeChargeDialog,
        title: AppStrings.Leases.LeaseCharge.OneTimeCharge
      };
    }
    if (pathname.indexOf(DialogRoutes.NEW_CREDIT) > -1 || pathname.indexOf(DialogRoutes.EDIT_CREDIT) > -1) {
      return {
        type: "newCredit",
        Content: OneTimeCreditDialog,
        title: AppStrings.Leases.LeaseCharge.OneTimeCredit
      };
    }
    if (pathname.indexOf(DialogRoutes.REFUND_DEPOSIT) > -1) {
      return {
        type: "refundDeposit",
        Content: RefundDepositDialog,
        title: AppStrings.Leases.LeaseTransaction.Refund.RefundDeposit
      };
    }
    if (pathname.indexOf(DialogRoutes.WITHHOLD_DEPOSIT) > -1) {
      return {
        type: "withholdDeposit",
        Content: WithholdDepositDialog,
        title: AppStrings.Leases.LeaseTransaction.Refund.WithholdDeposit
      };
    }
    if (pathname.indexOf(DialogRoutes.NEW_LEASE_DEPOSIT) > -1) {
      return {
        type: "newDeposit",
        Content: DepositDialog,
        title: AppStrings.Leases.LeaseTransaction.Refund.RefundDeposit
      };
    }
    if (pathname.indexOf(DialogRoutes.RENEWAL_OFFER) > -1) {
      return {
        type: "renewalOffer",
        Content: RenewalOfferDialog
      };
    }
    if (pathname.indexOf(DialogRoutes.NOT_RENEWING_OFFER) > -1) {
      return {
        type: "notRenewing",
        Content: NotRenewingDialog
      };
    }
    if (
      pathname.indexOf(DialogRoutes.NEW_RECURRING_PAYMENT) > -1 ||
      pathname.indexOf(DialogRoutes.EDIT_RECURRING_PAYMENT) > -1
    ) {
      return {
        type: "newRecurringPayment",
        Content: RecurringPaymentDialog,
        title: AppStrings.Leases.NewLease.LeaseRent.RecurringPayment
      };
    }
    if (
      pathname.indexOf(DialogRoutes.NEW_RECURRING_RENT) > -1 ||
      pathname.indexOf(DialogRoutes.EDIT_RECURRING_RENT) > -1
    ) {
      return {
        type: "newRecurringRent",
        Content: RecurringRentDialog,
        title: AppStrings.Leases.NewLease.LeaseRent.RecurringRent
      };
    }

    if (pathname.indexOf(DialogRoutes.NEW_PAYMENT) > -1 || pathname.indexOf(DialogRoutes.EDIT_PAYMENT) > -1) {
      return {
        type: "newPayment",
        Content: PaymentDialog,
        title: AppStrings.Leases.LeaseCharge.OneTimePayment
      };
    }

    if (pathname.indexOf(DialogRoutes.NEW_REFUND) > -1 || pathname.indexOf(DialogRoutes.EDIT_REFUND) > -1) {
      return {
        type: "newRefund",
        Content: RefundDialog,
        title: AppStrings.Leases.LeaseTransaction.Refund.LeaseRefund
      };
    }
    if (pathname.indexOf(DialogRoutes.MOVE_IN_TENANTS) > -1) {
      return {
        type: "moveInTenants",
        Content: MoveInTenantsDialog,
        title: AppStrings.Tenants.Screen.MoveInTenant
      };
    }

    if (pathname.indexOf(DialogRoutes.MOVE_OUT_TENANTS) > -1) {
      return {
        type: "moveInTenants",
        Content: MoveOutTenantsDialog,
        title: AppStrings.Tenants.Screen.MoveOutTenant
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_LATE_FEES) > -1 && pathname.indexOf(Routes.LEASES) > -1) {
      return {
        type: "editLateFees",
        Content: EditLeaseLateFeesDialog,
        title: AppStrings.Leases.Settings.LateFees
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_RENTERS_INSURANCE) > -1 && pathname.indexOf(Routes.LEASES) > -1) {
      return {
        type: "editRentersInsurance",
        Content: EditLeaseRentersInsuranceDialog,
        title: AppStrings.Leases.Settings.RentersInsurance
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_PAYMENTS_ALLOCATION) > -1 && pathname.indexOf(Routes.LEASES) > -1) {
      return {
        type: "editPaymentsAllocation",
        Content: EditLeasePaymentsAllocationsDialog,
        title: AppStrings.Leases.Settings.PaymentsAllocation
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_LEASE) > -1 && pathname.indexOf(Routes.LEASES) > -1) {
      return {
        type: "editLease",
        Content: EditLeaseDialog,
        title: AppStrings.Leases.Settings.LeaseSettings
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_EVICTION_STATUS) > -1 && pathname.indexOf(Routes.LEASES) > -1) {
      return {
        Content: EvictionStatusFormikContextWrapper,
        title: AppStrings.Leases.Settings.EvictionStatus
      };
    }
    if (
      pathname.indexOf(DialogRoutes.NEW_INSURANCE_POLICY) > -1 ||
      pathname.indexOf(DialogRoutes.EDIT_INSURANCE_POLICY) > -1
    ) {
      return {
        Content: InsurancePolicyFormikContextWrapper,
        title: AppStrings.Leases.InsurancePolicy.InsurancePolicy
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_LEASE_NAME) > -1) {
      return {
        Content: EditLeaseNameFormikContextWrapper,
        title: AppStrings.Leases.Settings.NameAndNotes
      };
    }
    return undefined;
  }
}

export const leasesDialogsProvider = new LeasesDialogsProvider();
