import React, { useMemo } from "react";
import { leaseChargeApi } from "api/leaseChargeApi";
import {
  LeaseChargeDto,
  LinkedResourceType,
  ObjectPermission,
  shouldBlockFeatureForSubscriptionPriceId
} from "@doorloop/dto";
import AppStrings from "locale/keys";
import Transaction from "../transaction";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { ArticleIdsEnum, HelpTypeEnum, VideoUrlsEnum } from "DLUI/screen/helpPanel/types";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import { Feature } from "config/featureFlags/featureFlags";
import BulkChargeDialog from "DLUI/dialogs/transactions/bulkTransactions/charge";
import { DialogRoutes } from "DLUI/screen/dialogsProvider";
import { DialogTitleActionButton } from "DLUI/dialogs/components/dialogTitleActionButton";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
}

const helpObject: HelpPanelProps = {
  description: AppStrings.Leases.LeaseCharge.OneTimeChargeHelpPanel.Description,
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Leases.LeaseCharge.OneTimeChargeHelpPanel.Create,
      articleId: ArticleIdsEnum.POSTING_A_CHARGE
    },
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Leases.LeaseCharge.OneTimeChargeHelpPanel.EditDelete,
      articleId: ArticleIdsEnum.EDIT_DELETE_LEASE_TRANSACTIONS
    },
    {
      type: HelpTypeEnum.WATCH_VIDEO,
      topic: AppStrings.Common.WatchExample,
      href: VideoUrlsEnum.LEASE_OVERVIEW,
      dialogTitle: AppStrings.Leases.LeaseCharge.OneTimeChargeHelpPanel.DialogTitle,
      startTime: 95
    }
  ]
};

const OneTimeChargeDialog: React.FC<ComponentProps> = ({ onBackdropClick, onClose }: ComponentProps) => {
  const subscriptionPriceId = useSelector(
    (state: IRootState) => state.auth.currentLoginResponse?.currentDbTenant?.subscriptionPlan?.price_id
  );
  const bulkChargeFeatureFlag =
    Feature.isActive("BulkCharges") &&
    !shouldBlockFeatureForSubscriptionPriceId(ObjectPermission.bulkOperations, subscriptionPriceId);

  const [isBulkMode, setIsBulkMode] = React.useState(location.pathname.indexOf(DialogRoutes.NEW_BULK_CHARGE) > -1);

  const dialogActionButtons = useMemo(() => {
    if (!bulkChargeFeatureFlag) return;

    return (
      <DialogTitleActionButton
        text={AppStrings.Leases.LeaseTransaction.Charge.SwitchToBulkPostCharges}
        onClick={() => setIsBulkMode(true)}
      />
    );
  }, [bulkChargeFeatureFlag]);

  if (isBulkMode) {
    const dialogTitleActions = (
      <DialogTitleActionButton
        text={AppStrings.Leases.LeaseTransaction.Charge.SwitchToPostCharge}
        onClick={() => setIsBulkMode(false)}
      />
    );

    return (
      <BulkChargeDialog onBackdropClick={onBackdropClick} onClose={onClose} dialogTitleActions={dialogTitleActions} />
    );
  }

  return (
    <Transaction
      onBackdropClick={onBackdropClick}
      onClose={onClose}
      dialogTitle={AppStrings.Leases.LeaseCharge.OneTimeCharge}
      dialogTitleActions={dialogActionButtons}
      apiMethod={leaseChargeApi}
      transactionDto={LeaseChargeDto.prototype}
      transactionCreatedText={AppStrings.Leases.LeaseTransaction.Charge.CreatedText}
      loadingTransactionDataText={AppStrings.Leases.LeaseTransaction.Charge.LoadingData}
      loadingTransactionDataSuccessText={AppStrings.Leases.LeaseTransaction.Charge.LoadingDataSuccess}
      updatingTransactionText={AppStrings.Leases.LeaseTransaction.Charge.Updating}
      transactionUpdatedText={AppStrings.Leases.LeaseTransaction.Charge.Updated}
      type={"oneTime"}
      dialogFrameHeight={getDialogFrameDimension("height", 750)}
      linkedResourceType={LinkedResourceType.LeaseCharge}
      permission={ObjectPermission.leaseCharges}
      helpObject={helpObject}
      accountFilterTypes={"filter_leaseChargeItem"}
      source={"charge"}
      filesSharedFormikFieldName={"isFilesSharedWithTenant"}
      showPrintButton
    />
  );
};

export default OneTimeChargeDialog;
