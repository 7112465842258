import type { ExpenseDto, GetAllExpensesQuery } from "@doorloop/dto";
import { ExpensesDto, ServerRoutes } from "@doorloop/dto";

import type { ApiResult } from "api/apiResult";
import { apiHelper } from "./apiHelper";
import { RestApiBase } from "./restApiBase";

export class ExpensesApi extends RestApiBase<ExpenseDto, GetAllExpensesQuery> {
  constructor() {
    super(ServerRoutes.EXPENSES, []);
    this.restRoute = ServerRoutes.EXPENSES;
  }

  createMany = async (expenses: ExpenseDto[]) => {
    const data = new ExpensesDto();
    data.expenses = expenses;

    return await apiHelper.axiosPost<ExpenseDto[]>({
      url: ServerRoutes.EXPENSES_POST_CREATE_MANY + "/",
      data
    });
  };

  async voidCheck(id: string): Promise<ApiResult<ExpenseDto>> {
    return await apiHelper.axiosPost({ url: ServerRoutes.EXPENSES_VOID_CHECK.replace(":id", id) });
  }
}

export const expensesApi = new ExpensesApi();
