import React from "react";
import type { DataResult } from "@progress/kendo-data-query";
import type { Queryable } from "DLUI/lists/types";

export interface ReportFooterTextProps<GetAllDtoType extends Queryable> {
  requestInProgress: boolean;
  dataSource: DataResult;
  filterObj: GetAllDtoType;
  GridFooter: any;
}

const ReportFooterText = <GetAllDtoType extends Queryable>({
  requestInProgress,
  dataSource,
  filterObj,
  GridFooter
}: ReportFooterTextProps<GetAllDtoType>) => {
  if (requestInProgress || dataSource.total === 0 || !GridFooter) {
    return null;
  }

  const accountingMethod = filterObj?.["filter_accountingMethod"];

  return <GridFooter accountingMethod={accountingMethod} />;
};

export default ReportFooterText;
