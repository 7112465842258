import type { CSSProperties, ReactNode } from "react";
import React from "react";
import clsx from "clsx";

import { IconButton } from "DLUI/form";
import { makeStylesAccordion } from "DLUI/accordion/makeStyles";
import { IconDown } from "../../../assets";

import { Collapsible } from "../collapsible/collapsible";
import { DataCy } from "@doorloop/dto";
import { useGeneralStyles } from "styles/generalStyles";
import type { FillColors } from "DLUI/icon";
import type { CollapsibleTransitionProps } from "DLUI/accordion/types";

interface ExpandedButtonOptionsProps {
  color?: FillColors;
  size?: number;
  isVisible?: boolean;
  removePadding?: boolean;
  hidden?: boolean;
}

interface AccordionStylesProps {
  summary?: CSSProperties;
  summaryContent?: CSSProperties;
  details?: CSSProperties;
  root?: CSSProperties;
}

export interface AccordionProps {
  isExpanded: boolean;
  toggleIsExpanded?: () => void;
  SummaryEndContent?: ReactNode;
  SummaryContent: ReactNode;
  DetailsContent: ReactNode;
  styles?: AccordionStylesProps;
  customSummaryClasses?: string;
  expandedButtonOptions?: ExpandedButtonOptionsProps;
  removeBorder?: boolean;
  transitionProps?: CollapsibleTransitionProps;
  isSummaryContentCompact?: boolean;
  dataCy?: string;
}

export const Accordion = ({
  isExpanded,
  toggleIsExpanded,
  SummaryEndContent,
  SummaryContent,
  DetailsContent,
  styles,
  customSummaryClasses,
  expandedButtonOptions,
  removeBorder,
  transitionProps,
  isSummaryContentCompact,
  dataCy
}: AccordionProps) => {
  const classes = makeStylesAccordion();
  const generalClasses = useGeneralStyles();

  const isEndSectionVisible = Boolean(SummaryEndContent) || !expandedButtonOptions?.hidden;

  return (
    <div className={clsx([classes.root, !removeBorder && classes.rootBorder])} style={styles?.root} data-cy={dataCy}>
      <div
        style={{
          cursor: toggleIsExpanded ? "pointer" : "default",
          marginRight: toggleIsExpanded ? 16 : 0,
          ...styles?.summary
        }}
        className={clsx(classes.summary, customSummaryClasses)}
      >
        <div
          className={clsx(isSummaryContentCompact ? generalClasses.fitContent : generalClasses.basicFlex)}
          style={styles?.summaryContent}
          onClick={toggleIsExpanded}
        >
          {SummaryContent}
        </div>
        {isEndSectionVisible && (
          <div className={clsx(generalClasses.flexRow, generalClasses.verticalAlign)}>
            {!expandedButtonOptions?.hidden && (
              <IconButton
                dataCy={DataCy.DLUI.accordion.toggle}
                Icon={IconDown}
                pathColor={expandedButtonOptions?.color ?? "light-gray"}
                size={expandedButtonOptions?.size ?? 24}
                removePadding={expandedButtonOptions?.removePadding ?? false}
                onClick={toggleIsExpanded}
                className={clsx(classes.flipIcon, { [classes.flipIconExpanded]: isExpanded })}
              />
            )}
            {SummaryEndContent && <div data-cy={DataCy.DLUI.accordion.end}>{SummaryEndContent}</div>}
          </div>
        )}
      </div>

      <Collapsible isOpen={isExpanded} transitionProps={transitionProps}>
        <div style={styles?.details}>{DetailsContent}</div>
      </Collapsible>
    </div>
  );
};
