"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseTransactionDto = void 0;
const journalEntry_dto_1 = require("../journalEntry.dto");
class BaseTransactionDto extends journalEntry_dto_1.JournalEntryDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.BaseTransactionDto = BaseTransactionDto;
