import React from "react";
import { View } from "DLUI/view";
import { Link } from "DLUI/link";
import type { ActionItem, VideoItem } from "./types";
import { useTranslation } from "react-i18next";
import AppStrings from "../../../../locale/keys";
import ColorsEnum from "../../../../utils/colorsEnum";
import type { LinkUnderLineType } from "DLUI/link/link";
import type { TextComponentProps } from "DLUI/text/text";
import { useHelpActionItem } from "@/hooks/useHelpActionItem";

interface Props extends TextComponentProps {
  actionItem: ActionItem;
  description?: string;
  noWrapActionItem?: boolean;
  underline?: LinkUnderLineType;
  textColor?: ColorsEnum;
  fontSize?: number;
  spanFontSize?: number;
  onOpenHelpVideo: (video: VideoItem) => void;
}

export const HelpPanelSingleActionItem: React.FC<Props> = ({
  actionItem,
  description,
  underline = "hover",
  hoverColor = "lightBlue",
  textColor = ColorsEnum.BrightBlue,
  fontSize,
  onOpenHelpVideo,
  spanFontSize
}) => {
  const { t } = useTranslation();
  const { clickHandler } = useHelpActionItem(actionItem, onOpenHelpVideo);

  return (
    <View style={{ lineBreak: "auto", fontSize: "14px", color: " #182C4C99" }}>
      <span style={{ fontSize: spanFontSize }}>
        {description && t(description)}
        <Link
          underline={underline}
          hoverColor={hoverColor}
          type="tab"
          textColor={textColor}
          onClick={clickHandler}
          fontSize={fontSize}
          marginLeft={5}
        >
          {t(AppStrings.Common.LearnMore) + "."}
        </Link>
      </span>
    </View>
  );
};
