"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationServiceType = void 0;
var NotificationServiceType;
(function (NotificationServiceType) {
    // TENANT SERVICES
    NotificationServiceType["TENANT_RENT_CHARGED"] = "TENANT_RENT_CHARGED";
    NotificationServiceType["TENANT_PAYMENT_RECEIPT"] = "TENANT_PAYMENT_RECEIPT";
    NotificationServiceType["TENANT_AUTO_PAY_CANCELED"] = "TENANT_AUTO_PAY_CANCELED";
    NotificationServiceType["TENANT_PAYMENT_RETURNED"] = "TENANT_PAYMENT_RETURNED";
    NotificationServiceType["TENANT_ANNOUNCEMENTS"] = "TENANT_ANNOUNCEMENTS";
    NotificationServiceType["TENANT_REQUEST"] = "TENANT_REQUEST";
    NotificationServiceType["TENANT_LATE_FEE_CHARGED"] = "TENANT_LATE_FEE_CHARGED";
    NotificationServiceType["TENANT_BEFORE_LATE_FEE_CHARGED"] = "TENANT_BEFORE_LATE_FEE_CHARGED";
    NotificationServiceType["TENANT_RENT_REMINDER"] = "TENANT_RENT_REMINDER";
    NotificationServiceType["TENANT_RENT_OVERDUE_REMINDER"] = "TENANT_RENT_OVERDUE_REMINDER";
    // USER SERVICES
    NotificationServiceType["TASKS"] = "TASKS";
    NotificationServiceType["RENTAL_APPLICATION"] = "RENTAL_APPLICATION";
    NotificationServiceType["RENTAL_APPLICATION_REPORTS_READY"] = "RENTAL_APPLICATION_REPORTS_READY";
    NotificationServiceType["MERCHANT_STATUS_UPDATED"] = "MERCHANT_STATUS_UPDATED";
    NotificationServiceType["PAYMENT_RETURNED"] = "PAYMENT_RETURNED";
    NotificationServiceType["PAYMENT_RECEIVED"] = "PAYMENT_RECEIVED";
    NotificationServiceType["COMMUNICATION_CENTER_SETUP_SMS_CONFIRMED"] = "COMMUNICATION_CENTER_SETUP_SMS_CONFIRMED";
    NotificationServiceType["CONVERSATION_INCOMING_MESSAGE"] = "CONVERSATION_INCOMING_MESSAGE";
    // SYSTEM SERVICES
    NotificationServiceType["SYSTEM"] = "SYSTEM";
})(NotificationServiceType || (exports.NotificationServiceType = NotificationServiceType = {}));
