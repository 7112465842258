"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseDraftStatus = void 0;
var LeaseDraftStatus;
(function (LeaseDraftStatus) {
    LeaseDraftStatus["NotSent"] = "NotSent";
    LeaseDraftStatus["Sent"] = "Sent";
    LeaseDraftStatus["Viewed"] = "Viewed";
    LeaseDraftStatus["PartiallySigned"] = "PartiallySigned";
    LeaseDraftStatus["Countersigned"] = "CounterSigned";
    LeaseDraftStatus["Activated"] = "Activated";
    LeaseDraftStatus["Cancelled"] = "Cancelled";
})(LeaseDraftStatus || (exports.LeaseDraftStatus = LeaseDraftStatus = {}));
