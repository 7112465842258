import { useCallback, useEffect, useState } from "react";

enum IntercomWindowState {
  CLOSED,
  OPEN,
  UNKNOWN
}

/**
 * Hook to get the Intercom bubble.
 * @description This hook can be used to manipulate the Intercom bubble.
 * __Be careful with setting the vertical padding because the intercom API doesn't always respect it.__
 * It gets set to the default value after the bubble is clicked
 */
const useIntercomBubble = () => {
  const [intercomVerticalPadding, setIntercomVerticalPadding] = useState(0);

  const [intercomWindowState, setIntercomWindowState] = useState<IntercomWindowState>(IntercomWindowState.UNKNOWN);

  if (window.Intercom) {
    window.Intercom("onHide", () => setIntercomWindowState(IntercomWindowState.CLOSED));
    window.Intercom("onShow", () => setIntercomWindowState(IntercomWindowState.OPEN));
  }

  const changeIntercomBubbleAlignment = useCallback((alignment: "right" | "left" = "right") => {
    window.Intercom("update", { alignment });
  }, []);

  const resetIntercomBubbleAlignment = useCallback(() => {
    changeIntercomBubbleAlignment("right");
  }, [changeIntercomBubbleAlignment]);

  const setIntercomToOppositeSide = useCallback(() => {
    changeIntercomBubbleAlignment("left");
  }, [changeIntercomBubbleAlignment]);

  const setIntercomHorizontalPadding = useCallback((padding: number) => {
    window.Intercom("update", { horizontal_padding: padding });
  }, []);

  const setIsBubbleVisible = (isVisible: boolean) => {
    window.Intercom("update", {
      hide_default_launcher: !isVisible
    });
  };

  useEffect(() => {
    window.Intercom("update", {
      vertical_padding: intercomVerticalPadding
    });
  }, [intercomVerticalPadding]);

  const showIntercomBubble = () => {
    setIsBubbleVisible(true);
  };

  const showArticle = (articleId: string) => {
    window.Intercom("showArticle", articleId);
  };

  /**
   * @description Attempts to hide the Intercom bubble.
   * @returns {boolean} True if the bubble was hidden successfully. False if the bubble cannot be hidden because
   * the intercom window is currently open.
   */
  const hideIntercomBubble = (): boolean => {
    if (intercomWindowState === IntercomWindowState.OPEN) {
      // This will prevent the bubble from hiding if there's an ongoing customer support conversation
      return false;
    }
    setIsBubbleVisible(false);
    return true;
  };

  const forceShowIntercomWindow = () => {
    showIntercomBubble();
    window.Intercom("show");
  };

  const forceHideIntercomWindow = () => {
    window.Intercom("hide");
  };

  const sendMessage = (message: string) => {
    window.Intercom("showNewMessage", message);
  };

  return {
    showIntercomBubble,
    hideIntercomBubble,
    sendMessage,
    changeIntercomBubbleAlignment,
    resetIntercomBubbleAlignment,
    setIntercomToOppositeSide,
    forceShowIntercomWindow,
    forceHideIntercomWindow,
    intercomVerticalPadding,
    intercomWindowState,
    setIntercomVerticalPadding,
    showArticle,
    setIntercomHorizontalPadding
  };
};
export default useIntercomBubble;
