import { sum } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { AutoCompleteGroup } from "./autoComplete.utils";
import { ShowMoreResults } from "./showMoreResults";

import type { ApiResult, GetAllBaseQueryResponse } from "@doorloop/dto";
import type { AutocompleteRenderGroupParams } from "@material-ui/lab";
import type { UseInfiniteQueryResult } from "@tanstack/react-query";
import type { AutoCompleteProps } from "./autoComplete.types";

// We don't use application types here to avoid coupling the component with
// proprietary code. However, objects are set as the baseline for describing
// options.
export interface AutoCompleteBaseOptionData {
  id?: string;
}
export interface AutoCompleteRemoteState {
  isIdle: boolean;
}

export function useRemoteAutoComplete<T extends AutoCompleteBaseOptionData, TData extends AutoCompleteBaseOptionData>(
  props: PickRequired<Partial<AutoCompleteProps<T>>, "onChange">
): [AutoCompleteRemoteState, typeof props] {
  const [isIdle, setIsIdle] = useState(true);

  const { onChange: onChangeProp } = props;

  const onChange = useCallback<NonNullable<AutoCompleteProps<T>["onChange"]>>(
    async (event, value, reason, details, promise) => {
      if (reason === "create-option") {
        setIsIdle(false);

        const result: ApiResult<TData> = await promise?.catch((error) => error);

        if (result instanceof Error) {
          // Abort the event if the API call fails
          console.error(`Failed to create option, aborting \`change\` event. See error: "${result.message}"`);
          return;
        }
        // Patch `ID` for the newly created item
        if (value?.data) {
          value.data.id = result.data?.id;
        }

        // Queue the state change after `props.onChange` to ensure
        // `isIdle` is set to `true` after the event is handled.
        window.setTimeout(() => setIsIdle(true));
      }
      onChangeProp?.(event, value, reason, details, promise);
    },
    [onChangeProp]
  );

  return useMemo(() => [{ isIdle }, { ...props, onChange }], [isIdle, onChange, props]);
}

export interface UsePaginatedGroupRendererProps<TDto> {
  queries: Record<string, UseInfiniteQueryResult<ApiResult<GetAllBaseQueryResponse<TDto>>, unknown>>;
  groupToQueryMap: Record<string, string>;
  solo?: boolean;
  excludeGroups?: string[];
}

export function usePaginatedGroupRenderer<TDto>({
  solo,
  queries,
  groupToQueryMap,
  excludeGroups
}: UsePaginatedGroupRendererProps<TDto>) {
  return useCallback(
    (params: AutocompleteRenderGroupParams) => {
      function renderShowMoreResults() {
        if (excludeGroups?.includes(params.group)) {
          return null;
        }

        const groupQuery = queries[groupToQueryMap[params.group]];

        const total = groupQuery.data?.pages[0].data?.total;
        const totalFetched = sum(groupQuery.data?.pages.map((page) => page.data?.data.length));
        const isSinglePage = groupQuery.data?.pageParams.length === 1 && totalFetched === total;

        if (!groupQuery) {
          if (process.env.REACT_APP_ENV === "dev") {
            console.error(
              `Group query not found for group "${params.group}".` +
                `Pass the group name to \`excludeGroups\` to suppress this error.`
            );
          }
          return null;
        }

        // Extract what we need, keep it simple and performant.
        const { hasNextPage, isFetching, fetchNextPage } = groupQuery;

        return (
          !isSinglePage && (
            <ShowMoreResults hasNextPage={hasNextPage} isFetching={isFetching} fetchNextPage={fetchNextPage} />
          )
        );
      }

      return (
        <AutoCompleteGroup key={params.group.toLowerCase()} params={params} solo={solo}>
          {renderShowMoreResults()}
        </AutoCompleteGroup>
      );
    },
    [excludeGroups, groupToQueryMap, queries, solo]
  );
}
