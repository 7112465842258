export interface AndroidMetadata {
  version: string;
  buildNumber: string;
}

export enum LoadingStatus {
  initial = "initial",
  loading = "loading",
  loaded = "loaded",
  error = "error"
}
