"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.personUtils = void 0;
const common_1 = require("../common");
const vendors_1 = require("../vendors");
exports.personUtils = {
    getFullName(firstName, middleName, lastName) {
        let fullName;
        if (!firstName && !lastName) {
            fullName = undefined;
        }
        else {
            fullName = "";
            if (firstName) {
                fullName = firstName.trim();
            }
            if (middleName) {
                if (firstName) {
                    fullName += " ";
                }
                fullName += middleName.substring(0, 1) + ".";
            }
            if (lastName) {
                if (firstName || middleName) {
                    fullName += " ";
                }
                fullName += lastName.trim();
            }
        }
        return fullName;
    },
    getDisplayName(firstName, middleName, lastName, companyName) {
        let displayName;
        const fullName = this.getFullName(firstName, middleName, lastName);
        if (companyName) {
            if (!fullName) {
                displayName = companyName;
            }
            else {
                displayName = companyName + " | " + fullName;
            }
        }
        else {
            displayName = fullName;
        }
        return displayName;
    },
    getEmailDto(personDto) {
        var _a, _b;
        // Some documents have a malformed `EmailDto`, so we have to truthy check
        // `email.address`. Once we run a migration, we can remove this check.
        const primaryEmail = (_a = personDto.emails) === null || _a === void 0 ? void 0 : _a.find((email) => email.type === common_1.EmailType.PRIMARY && email.address);
        if (personDto instanceof vendors_1.VendorDto) {
            return primaryEmail;
        }
        if ((_b = personDto.portalInfo) === null || _b === void 0 ? void 0 : _b.loginEmail) {
            return new common_1.EmailDto({
                type: common_1.EmailType.OTHER,
                address: personDto.portalInfo.loginEmail
            });
        }
        return primaryEmail;
    }
};
