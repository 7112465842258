"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseRenewalStage = void 0;
var LeaseRenewalStage;
(function (LeaseRenewalStage) {
    LeaseRenewalStage["NOT_STARTED"] = "NOT_STARTED";
    LeaseRenewalStage["RENEWAL_DRAFT"] = "RENEWAL_DRAFT";
    LeaseRenewalStage["RENEWAL_OFFER"] = "RENEWAL_OFFER";
    LeaseRenewalStage["NOT_RENEWING"] = "NOT_RENEWING";
})(LeaseRenewalStage || (exports.LeaseRenewalStage = LeaseRenewalStage = {}));
