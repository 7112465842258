import type { NoteDto } from "@doorloop/dto";
import {
  createValidator,
  LinkedResourceDto,
  LinkedResourceType,
  NoteDtoDisplay,
  ObjectPermission,
  TagType
} from "@doorloop/dto";
import type { ApiResult } from "api/apiResult";
import { filesApi } from "api/filesApi";
import { notesApi } from "api/notesApi";
import { tagsApi } from "api/tagsApi";
import Text from "DLUI/text";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { LoadingDialog } from "DLUI/dialogs/components/loading";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import { TextField } from "DLUI/form";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, Formik } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DialogFrame, { getDialogFrameDimension } from "../components/dialogFrame";
import { DialogsHelper } from "../dialogsHelper";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import FormikCachedMultiSelectAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedMultiSelectAutoComplete";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { ArticleIdsEnum, HelpTypeEnum } from "DLUI/screen/helpPanel/types";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { unitsApi } from "api/unitsApi";
import CreateNewTagOptionForm from "DLUI/form/autoComplete/formikAsyncAutoComplete/createNewTagOptionForm";
import { TagIcon } from "../../../../assets";
import { useHistory } from "react-router";
import DeleteConfirmation from "DLUI/dialogs/components/deleteConfirmation";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";

import { useLinkedResource } from "../../../../hooks/useLinkedResource";
import { Icon } from "DLUI/icon";
import { Link } from "DLUI/link";
import ColorsEnum from "../../../../utils/colorsEnum";

interface ComponentProps {
  didFinishOperation?: (values: NoteDtoDisplay) => void;
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle?: string;
  linkedResource?: LinkedResourceType;
  linkedResourceId?: string;
  noteData?: NoteDtoDisplay;
}

let formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

const helpObject: HelpPanelProps = {
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Notes.Dialog.LearnAboutNotes,
      articleId: ArticleIdsEnum.WORKING_WITH_NOTES
    }
  ]
};

const NoteDialog: React.FC<ComponentProps> = ({
  didFinishOperation,
  onBackdropClick,
  onClose,
  dialogTitle,
  linkedResource,
  linkedResourceId,
  noteData: _noteData
}: ComponentProps) => {
  const { t } = useTranslation();
  const { dialogHorizontalPadding } = DialogsHelper();
  const { location } = useHistory<NoteDtoDisplay | Record<string, any>>();
  const noteData = _noteData ?? (Object.keys(location?.state || {}).length > 0 ? location.state : undefined);
  const editMode = noteData !== undefined;
  const permission: AnyPermissionClearance = {
    permission: ObjectPermission.notes,
    field: editMode ? "edit" : "create"
  };
  const localeData = useSelector((state: IRootState) => state.auth.currentLoginResponse);
  const [viewIndex, setViewIndex] = useState(0);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);
  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);
  const [createNoteErrorText, setCreateNoteErrorText] = useState<string>("");

  useEffect(
    () => () => {
      formikGlobalRef = null;
    },
    []
  );

  const initFormvalues = (): NoteDtoDisplay => {
    const noteDto = noteData ? new NoteDtoDisplay(noteData) : new NoteDtoDisplay();

    const currentLinkedResourceType = noteData?.linkedResource?.resourceType || linkedResource;
    const currentLinkedResourceId = noteData?.linkedResource?.resourceId || linkedResourceId;

    if (currentLinkedResourceType && currentLinkedResourceId) {
      noteDto.linkedResource = new LinkedResourceDto(currentLinkedResourceId, currentLinkedResourceType);

      if (currentLinkedResourceType === LinkedResourceType.Unit) {
        noteDto.unit = currentLinkedResourceId;
        noteDto.property = unitsApi.getItemFromDictionary(currentLinkedResourceId)?.property;
      } else if (currentLinkedResourceType === LinkedResourceType.Property) {
        noteDto.property = currentLinkedResourceId;
      }
    }

    return noteDto;
  };

  const validateForm = createValidator(NoteDtoDisplay);

  const isValidForm = async (formikRef: FormikProps<NoteDtoDisplay>) => {
    formikRef.setFieldTouched("title");

    if (!editMode) {
      formikRef.setFieldTouched("property");
    }

    const errors = (await formikRef.validateForm()) as any;

    return _.isEmpty(errors);
  };

  const formInitialValues = initFormvalues();

  const createNoteData = (values: NoteDtoDisplay) => {
    if (values?.property) {
      delete values.property;
    }
    if (values?.unit) {
      delete values.unit;
    }

    const newNoteData: NoteDto = {
      ...values,
      tags: values.tags as string[]
    };

    return newNoteData;
  };

  const createNote = async (values: NoteDtoDisplay) => {
    setViewIndex(1);
    setLoadingDialogState(DialogState.Show);
    const response = (await notesApi.create(createNoteData(values)).catch((err) => {
      setCreateNoteErrorText(err.message);
      setLoadingDialogState(DialogState.Error);
    })) as ApiResult<any>;
    if (response.status) {
      const createdBy = localeData?.firstName
        ? `${localeData?.firstName}${localeData?.lastName ? " " + localeData?.lastName : ""}`
        : undefined;
      const attachmentsWithNoteData = attachments.map((file) => {
        return {
          ...file,
          label: values?.title,
          createdBy
        };
      });
      await filesApi
        .uploadFiles(Object.values(attachmentsWithNoteData), response.data.id!, LinkedResourceType.Note)
        .catch((error: string) => {
          setLoadingDialogState(DialogState.Error);
          setCreateNoteErrorText(error);
        });
      setLoadingDialogState(DialogState.Success);
      didFinishOperation?.(values);
      formikGlobalRef = null;
    } else {
      setCreateNoteErrorText(response.message);
      setLoadingDialogState(DialogState.Error);
    }
  };

  const updateNote = async (values: NoteDtoDisplay) => {
    setViewIndex(1);
    setLoadingDialogState(DialogState.Show);
    const response = (await notesApi.update(values.id!, createNoteData(values)).catch((err) => {
      setCreateNoteErrorText(err.message);
      setLoadingDialogState(DialogState.Error);
    })) as ApiResult<any>;

    if (response.status) {
      await filesApi.uploadFiles(attachments, response.data.id!, LinkedResourceType.Note).catch((error: string) => {
        setLoadingDialogState(DialogState.Error);
        setCreateNoteErrorText(error);
      });
      setLoadingDialogState(DialogState.Success);
      didFinishOperation?.(values);
    } else {
      setCreateNoteErrorText(response.message);
      setLoadingDialogState(DialogState.Error);
    }
  };

  const didPressSaveButton = async () => {
    if (formikGlobalRef !== null) {
      const isValid = await isValidForm(formikGlobalRef);

      if (isValid) {
        if (editMode) {
          await updateNote(formikGlobalRef.values);
          return;
        }
        await createNote(formikGlobalRef.values);
      }
    }
  };

  const onDeleteClick = () => {
    setViewIndex(2);
  };

  const renderActionPanelButtons = () => {
    if (viewIndex === 1) {
      return <div />;
    }
    return (
      <RestrictedPermissionAccess>
        <FormActionButtons
          clearance={permission}
          propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
          propsSubButton={{ onClick: onBackdropClick }}
          propsActionPanel={{
            editMode: true,
            onDeleteButtonPress: editMode ? onDeleteClick : undefined
          }}
        />
      </RestrictedPermissionAccess>
    );
  };

  const didPressDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(0);
  };

  const onRetryButtonPress = async () => {
    await didPressSaveButton();
  };

  const onFileReceived = (files: FileListItemProps[]) => {
    setAttachments(files);
  };

  const renderAttachments = () => (
    <View justifyContent={"flex-end"} width={"100%"} flex={1} marginTop={10} marginBottom={20}>
      <FormAttachments
        resourceId={noteData?.id!}
        resourceType={LinkedResourceType.Note}
        editMode={editMode}
        isRequired={false}
        onFileReceived={onFileReceived}
        files={attachments}
      />
    </View>
  );

  const renderReadOnlyRelatedToFields = () => {
    const _linkedResourceType = linkedResource || noteData?.linkedResource.resourceType;
    const _linkedResourceId = linkedResourceId || noteData?.linkedResource.resourceId;
    const hrefSuffix = "/notes";

    const { linkedResourceIcon, linkedResourceText, linkedResourceHref, linkedResourceName } = useLinkedResource(
      new LinkedResourceDto(_linkedResourceId, _linkedResourceType),
      hrefSuffix
    );

    return (
      <>
        <HorizontalSeparationLine marginTop={20} />
        <View>
          <Text marginTop={20} fontSize={14} bold value={AppStrings.Tasks.InternalTaskDialog.RelatedTo} />
          <View flexDirection={"row"} alignItems={"center"} marginTop={10}>
            {linkedResourceIcon && <Icon Source={linkedResourceIcon} size={19} />}
            {linkedResourceHref ? (
              <Link
                hrefUrl={linkedResourceHref}
                marginLeft={5}
                fontSize={14}
                type={"blank"}
                textColor={ColorsEnum.BrightBlue}
                underline={"always"}
              >
                {linkedResourceText}
              </Link>
            ) : (
              <Text marginLeft={5} fontSize={14} value={linkedResourceText} />
            )}
            {linkedResourceName && (
              <Text value={`(${t(linkedResourceName)})`} color={"secondary-gray"} marginLeft={2} fontSize={14} />
            )}
          </View>
        </View>
      </>
    );
  };

  const renderForm = () => (
    <Formik initialValues={formInitialValues} onSubmit={(values, { setSubmitting }) => {}} validate={validateForm}>
      {(formik) => {
        formikGlobalRef = formik;

        return (
          <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding}>
            <RestrictedPermissionAccess clearance={permission} showNoAccess>
              <FastField
                component={TextField}
                label={t(AppStrings.Notes.Dialog.NoteTitlePlaceholder)}
                name={"title"}
                marginTop={20}
                required
              />
              <FastField
                component={TextField}
                label={t(AppStrings.Notes.Dialog.NoteBodyPlaceholder)}
                name={`body`}
                multiline
                rows="4"
                marginTop={20}
                multiLineSize={"normal"}
                maxLength={10000}
              />
              <FormikCachedMultiSelectAutoComplete
                uniqueIndex={"selectCategories"}
                apiHandler={tagsApi}
                displayNameKey={"name"}
                filterFieldName={"filter_text"}
                filterFieldValue={"name"}
                selectionFields={["id", "type"]}
                name={"tags"}
                label={t(AppStrings.Tags.SelectTags)}
                tagIcon={TagIcon}
                marginTop={20}
                defaultValue={noteData?.tags}
                errorLabelPaddingLeft={15}
                queryParams={{ filter_type: TagType.NOTES_TAG }}
                CreateNewOptionComponent={CreateNewTagOptionForm}
                createNewOptionTitle={AppStrings.Tags.CreateNewTag}
                groupNameProp={"type"}
                createNewOptionType={TagType.NOTES_TAG}
              />
              {renderAttachments()}
              {editMode && renderReadOnlyRelatedToFields()}
            </RestrictedPermissionAccess>
          </View>
        );
      }}
    </Formik>
  );

  const onDeleteFail = (err) => {
    setCreateNoteErrorText(err);
  };

  const renderView = ({ index }: any) => {
    if (index === 0) {
      return renderForm();
    }
    if (index === 1) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={editMode ? t(AppStrings.Notes.Dialog.UpdatingText) : t(AppStrings.Notes.Dialog.LoadingText)}
            errorText={createNoteErrorText}
            successText={t(AppStrings.Notes.Dialog.CreateNoteSuccessText)}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
          />
        </View>
      );
    }
    if (index === 2) {
      return (
        <DeleteConfirmation
          deleteConfirmationText={t(AppStrings.Notes.Dialog.DeleteConfirmationText)}
          apiMethod={notesApi}
          didPressDismissButton={() => setViewIndex(0)}
          didFinishOperation={onClose}
          didFailOperation={onDeleteFail}
          transactionId={noteData?.id}
          attachments={attachments}
        />
      );
    }

    return <div />;
  };

  const _onBackdropClick = () => {
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={dialogTitle}
      width={getDialogFrameDimension("width", viewIndex !== 2 || createNoteErrorText ? 600 : 500)}
      height={getDialogFrameDimension("height", viewIndex !== 2 || createNoteErrorText ? 800 : 500)}
      renderView={renderView}
      numViews={3}
      activeView={viewIndex}
      helpPanel={helpObject}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={viewIndex === 0 ? "sectionTitleFrame" : "contentOnly"}
    />
  );
};

export default NoteDialog;
