import type {
  AnnouncementDto,
  AnnouncementReviewDto,
  FileDto,
  GetAllAnnouncementsQuery,
  GetAnnouncementStatsRequestDto,
  GetAnnouncementStatsResponse
} from "@doorloop/dto";
import { LinkedResourceType, SendAnnouncementRequestDto, ServerRoutes } from "@doorloop/dto";
import { RestApiBase } from "./restApiBase";
import { apiHelper } from "api/apiHelper";
import { ApiResult } from "api/apiResult";
import { filesApi } from "api/filesApi";
import { usersApi } from "api/usersApi";

export interface AnnouncementDetailsInterface {
  announcement: AnnouncementDto;
  stats: GetAnnouncementStatsResponse;
  files: FileDto[];
}

export class AnnouncementsApi extends RestApiBase<AnnouncementDto, GetAllAnnouncementsQuery> {
  constructor() {
    super(ServerRoutes.ANNOUNCEMENTS, [usersApi]);
    this.restRoute = ServerRoutes.ANNOUNCEMENTS;
  }

  getDetails = async (announcementId: string): Promise<ApiResult<AnnouncementDetailsInterface>> => {
    await this.loadDictionariesRequiredForGet();
    const [{ data: filesData }, { data: stats }, announcementCall] = await Promise.all([
      filesApi.getAll({
        filter_resourceId: announcementId,
        filter_resourceType: LinkedResourceType.Announcement
      }),
      apiHelper.axiosGet(ServerRoutes.ANNOUNCEMENTS_GET_STATS, {
        announcementId
      }),
      apiHelper.axiosGet(ServerRoutes.ANNOUNCEMENTS + "/" + announcementId)
    ]);
    const { data: announcement } = announcementCall;
    if (announcementCall?.status && announcement) {
      return new ApiResult({ announcement, stats, files: filesData?.data || [] });
    }
    return announcementCall;
  };

  broadcastAnnouncement = async (announcementId: string) =>
    await apiHelper.axiosPost<unknown>({
      url: ServerRoutes.ANNOUNCEMENTS_POST_SEND,
      data: new SendAnnouncementRequestDto({ announcementId })
    });

  reviewAnnouncement = async (dto: AnnouncementDto) =>
    await apiHelper.axiosPost<AnnouncementReviewDto>({ url: ServerRoutes.ANNOUNCEMENTS_REVIEW, data: dto });

  getAnnouncementStats = async ({ announcementId }: GetAnnouncementStatsRequestDto) =>
    await apiHelper.axiosGet<GetAnnouncementStatsResponse>(ServerRoutes.ANNOUNCEMENTS_GET_STATS, {
      announcementId
    });
}

export const announcementsApi = new AnnouncementsApi();
