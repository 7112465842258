import Skeleton from "@material-ui/lab/Skeleton";
import type { SVGIconComponent } from "assets/icons";
import { CheckmarkCircleIcon, ClockOrangeIcon, CloseCircleIcon, DotsIcon } from "assets/icons";
import type { PopoverItem } from "DLUI/popover";
import { Popover } from "DLUI/popover";
import { ProfileImage } from "DLUI/profileImage";
import Text from "DLUI/text";
import type { TextColor } from "DLUI/text/text";
import { View } from "DLUI/view";
import { TenantType } from "@doorloop/dto";
import AppStrings from "locale/keys";
import React, { Fragment, useMemo } from "react";
import makeStyles from "../styles";
import { LeaseTenantStatus } from "@doorloop/dto";
import { ProspectStatus } from "@doorloop/dto";
import { DefaultListItemFontSize } from "screens/leases/leases/leasesList/leaseListItem";
import ListItemText from "DLUI/listItems/listItemText";
import type { ViewComponentProps } from "DLUI/view/view";

interface ComponentProps extends Partial<ViewComponentProps> {
  fullName?: string;
  name?: string;
  status?: TenantType | boolean | undefined;
  pictureUrl?: string;
  companyName?: string;
  jobTitle?: string;
  displayAsSkeleton?: boolean;
  popoverTopSectionItems?: PopoverItem[];
  popoverBottomSectionItems?: PopoverItem[];
  leaseStatus?: LeaseTenantStatus;
  prospectStatus?: ProspectStatus;
  onClick?: () => void;
  href?: string;
  showSeparator?: boolean;
}

const PersonInfoRow: React.FC<ComponentProps> = ({
  status,
  name,
  pictureUrl,
  displayAsSkeleton,
  popoverTopSectionItems,
  popoverBottomSectionItems,
  leaseStatus,
  jobTitle,
  prospectStatus,
  companyName,
  onClick,
  href,
  showSeparator = true,
  marginLeft = 20,
  ...viewProps
}: ComponentProps) => {
  const classes = makeStyles();

  const { StatusIcon, statusTextColor, statusText } = useMemo(() => {
    let StatusIcon: SVGIconComponent = CheckmarkCircleIcon;
    let statusTextColor: TextColor = "black";
    let statusText = "";

    if (leaseStatus && leaseStatus === LeaseTenantStatus.CURRENT) {
      StatusIcon = CheckmarkCircleIcon;
      statusTextColor = "green";
      statusText = AppStrings.Tenants.Status.Current;
      return { StatusIcon, statusTextColor, statusText };
    }
    if (leaseStatus && leaseStatus === LeaseTenantStatus.FUTURE) {
      StatusIcon = ClockOrangeIcon;
      statusTextColor = "orange";
      statusText = AppStrings.Tenants.Status.Future;
      return { StatusIcon, statusTextColor, statusText };
    }
    if (leaseStatus && leaseStatus === LeaseTenantStatus.PAST) {
      StatusIcon = CloseCircleIcon;
      statusTextColor = "gray";
      statusText = AppStrings.Tenants.Status.Past;
      return { StatusIcon, statusTextColor, statusText };
    }

    if (status && typeof status === "boolean" && !status) {
      statusText = AppStrings.Tenants.Status.Draft;
      StatusIcon = CloseCircleIcon;
      statusText = AppStrings.Tenants.Status.Inactive;
      return { StatusIcon, statusTextColor, statusText };
    }

    if (status && typeof status === "boolean" && Boolean(status)) {
      StatusIcon = CheckmarkCircleIcon;
      statusTextColor = "green";
      statusText = AppStrings.Tenants.Status.Active;
      return { StatusIcon, statusTextColor, statusText };
    }
    switch (status) {
      case TenantType.LEASE_TENANT:
        StatusIcon = CheckmarkCircleIcon;
        statusTextColor = "green";
        statusText = AppStrings.Tenants.Status.Future;
        return { StatusIcon, statusTextColor, statusText };

      case TenantType.PROSPECT_TENANT:
        StatusIcon = ClockOrangeIcon;
        statusTextColor = "orange";
        statusText = AppStrings.Prospects.Screen.Prospect;
        return { StatusIcon, statusTextColor, statusText };
    }
    switch (prospectStatus) {
      case ProspectStatus.APPLICATION_SENT:
        statusText = AppStrings.Prospects.ProspectStatus.ApplicationSent;
        break;
      case ProspectStatus.APPLICATION_SUBMITTED:
        statusText = AppStrings.Prospects.ProspectStatus.ApplicationSubmitted;
        break;
      case ProspectStatus.LEASE_OFFER_MADE:
        statusText = AppStrings.Prospects.ProspectStatus.LeaseOfferMade;
        break;
      case ProspectStatus.LEASE_SENT_FOR_SIGNATURE:
        statusText = AppStrings.Prospects.ProspectStatus.LeaseSentForSignature;
        break;
      case ProspectStatus.NEW:
        statusText = AppStrings.Prospects.ProspectStatus.New;
        StatusIcon = ClockOrangeIcon;
        statusTextColor = "orange";
        break;
      case ProspectStatus.SHOWING_COMPLETED:
        statusText = AppStrings.Prospects.ProspectStatus.ShowingCompleted;
        break;
      case ProspectStatus.SHOWING_SCHEDULED:
        statusText = AppStrings.Prospects.ProspectStatus.ShowingScheduled;
        break;
      case ProspectStatus.CONTACT_ATTEMPTED:
        statusText = AppStrings.Prospects.ProspectStatus.ContactAttempted;
        break;
      case ProspectStatus.CONTACT_MADE:
        statusText = AppStrings.Prospects.ProspectStatus.ContactMade;
        break;

      case ProspectStatus.CLOSED_LOST:
        statusText = AppStrings.Prospects.ProspectStatus.ClosedLost;
        StatusIcon = CloseCircleIcon;
        statusTextColor = "gray";
        break;
      case ProspectStatus.CLOSED_MOVED_IN:
        statusText = AppStrings.Prospects.ProspectStatus.ClosedMoveIn;
        break;
      case ProspectStatus.LEASE_OFFER_ACCEPTED:
        statusText = AppStrings.Prospects.ProspectStatus.LeaseOfferAccepted;
        StatusIcon = CheckmarkCircleIcon;
        statusTextColor = "green";
    }
    return { StatusIcon, statusTextColor, statusText };
  }, []);

  const renderPopover = () => {
    if (displayAsSkeleton) {
      return null;
    }
    return (
      <View width={45}>
        <Popover
          topSectionItems={popoverTopSectionItems || []}
          bottomSectionItems={popoverBottomSectionItems || []}
          Icon={DotsIcon}
          width={180}
        />
      </View>
    );
  };

  const renderProfileImage = () => {
    if (displayAsSkeleton) {
      return <Skeleton variant={"circle"} width={50} height={50} />;
    }
    return <ProfileImage pictureUrl={pictureUrl} />;
  };

  const renderStatus = () => {
    if (!status && !prospectStatus) {
      if (jobTitle) {
        return (
          <Text marginTop={5} color={"gray"} fontSize={DefaultListItemFontSize}>
            {jobTitle}
          </Text>
        );
      }
      return null;
    }

    return (
      <View marginTop={5} flexDirection={"row"} alignItems={"center"}>
        <StatusIcon style={{ width: "15px", height: "15px" }} />
        <View flex={1}>
          <Text marginLeft={5} color={statusTextColor} value={statusText} fontSize={DefaultListItemFontSize} />
        </View>
      </View>
    );
  };

  const _onClick = () => {
    if (onClick) {
      onClick();
    }
  };
  const renderPersonName = () => {
    if (displayAsSkeleton) {
      return (
        <Fragment>
          <Skeleton variant="text" width={150} />
          <Skeleton style={{ marginTop: 10 }} variant="text" width={150} />
        </Fragment>
      );
    }
    return (
      <ListItemText href={href} bold color={"black"} lineHeight={"20px"} marginRight={10} numberOfLines={2}>
        {name}
      </ListItemText>
    );
  };

  return (
    <View cursor={onClick ? "pointer" : undefined} alignItems={"center"} justifyContent={"center"} {...viewProps}>
      <View justifyContent={"center"} alignItems={"center"} height={80} flexDirection={"row"}>
        <View onClick={_onClick} justifyContent={"center"} alignItems={"center"} width={"auto"} marginLeft={marginLeft}>
          {renderProfileImage()}
        </View>
        <View justifyContent={"center"} flex={1} height={"100%"} onClick={_onClick} marginLeft={10}>
          {renderPersonName()}
          {renderStatus()}
        </View>
        {renderPopover()}
      </View>
      {showSeparator && <View marginTop={5} width={"90%"} height={1} className={classes.separatorView} />}
    </View>
  );
};

export default PersonInfoRow;
