"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyDefaultAccountType = exports.PropertyStatus = exports.PropertyType = exports.PropertyClass = void 0;
var PropertyClass;
(function (PropertyClass) {
    PropertyClass["RESIDENTIAL"] = "RESIDENTIAL";
    PropertyClass["COMMERCIAL"] = "COMMERCIAL";
})(PropertyClass || (exports.PropertyClass = PropertyClass = {}));
var PropertyType;
(function (PropertyType) {
    PropertyType["RESIDENTIAL_SINGLE_FAMILY"] = "RESIDENTIAL_SINGLE_FAMILY";
    PropertyType["RESIDENTIAL_MULTI_FAMILY"] = "RESIDENTIAL_MULTI_FAMILY";
    PropertyType["RESIDENTIAL_CONDO"] = "RESIDENTIAL_CONDO";
    PropertyType["RESIDENTIAL_TOWNHOME"] = "RESIDENTIAL_TOWNHOME";
    PropertyType["RESIDENTIAL_OTHER"] = "RESIDENTIAL_OTHER";
    PropertyType["COMMERCIAL_OTHER"] = "COMMERCIAL_OTHER";
    PropertyType["COMMERCIAL_INDUSTRIAL"] = "COMMERCIAL_INDUSTRIAL";
    PropertyType["COMMERCIAL_OFFICE"] = "COMMERCIAL_OFFICE";
    PropertyType["COMMERCIAL_RETAIL"] = "COMMERCIAL_RETAIL";
    PropertyType["COMMERCIAL_SHOPPING_CENTER"] = "COMMERCIAL_SHOPPING_CENTER";
    PropertyType["COMMERCIAL_STORAGE"] = "COMMERCIAL_STORAGE";
    PropertyType["COMMERCIAL_PARKING"] = "COMMERCIAL_PARKING";
    PropertyType["COMMERCIAL_CO_WORKING"] = "COMMERCIAL_CO_WORKING";
})(PropertyType || (exports.PropertyType = PropertyType = {}));
var PropertyStatus;
(function (PropertyStatus) {
    PropertyStatus["ACTIVE"] = "true";
    PropertyStatus["InACTIVE"] = "false";
})(PropertyStatus || (exports.PropertyStatus = PropertyStatus = {}));
var PropertyDefaultAccountType;
(function (PropertyDefaultAccountType) {
    PropertyDefaultAccountType["OPERATING"] = "bank_operating";
    PropertyDefaultAccountType["TRUST"] = "bank_trust";
})(PropertyDefaultAccountType || (exports.PropertyDefaultAccountType = PropertyDefaultAccountType = {}));
