import React from "react";
import { BackgroundImageView, View } from "DLUI/view";
import { SectionTitle } from "DLUI/screen";
import AppStrings from "locale/keys";
import Text from "DLUI/text";
import SeparationLine from "../../../../DLUI/separatorView/separationLine";
import { TextField } from "DLUI/form";
import { FastField } from "formik";
import { useTranslation } from "react-i18next";
import { StepDefaultMaxWidth } from "DLUI/screen/wizard/stepContainer";

interface ComponentProps {
  hideSectionTitle?: boolean;
}

const GeneralSettings: React.FC<ComponentProps> = React.memo(({ hideSectionTitle }: ComponentProps) => {
  const { t } = useTranslation();

  return (
    <View maxWidth={StepDefaultMaxWidth} alignItems={"center"}>
      {!hideSectionTitle && (
        <SectionTitle
          type={"underline"}
          alignItems={"center"}
          title={AppStrings.Esignatures.SignatureTemplates}
          marginTop={20}
        />
      )}

      <Text
        marginTop={20}
        align={"center"}
        value={AppStrings.Esignatures.SignatureTemplateGeneralSettingsDescription}
      />

      <BackgroundImageView
        backgroundSize={"contain"}
        publicFileName={"url(/images/dropbox-sign-icon.png)"}
        height={40}
        marginTop={20}
      />
      <SeparationLine marginTop={20} width={"100%"} height={1} />
      <View marginTop={20} justifyContent={"flex-start"}>
        <Text value={AppStrings.Common.GeneralInformation} fontSize={18} bold />
        <FastField
          component={TextField}
          label={t(AppStrings.Esignatures.SignatureTemplateTitle)}
          name={`title`}
          marginTop={20}
          required
        />
        <FastField
          component={TextField}
          label={t(AppStrings.Leases.LeaseCharge.Description)}
          name={`description`}
          multiline
          rows="5"
          marginTop={20}
          maxLength={1000}
        />
      </View>
    </View>
  );
});

export default GeneralSettings;
