const Notes = {
  Tab: {
    AddNewNote: "notes.tab.addNewNote",
    Unknown: "notes.tab.unknown",
    CreateNewNote: "notes.tab.createNewNote",
    EmptydataViewInstructionsText: "notes.tab.emptydataViewInstructionsText",
    AddedOnBy: "notes.tab.addedOnBy",
    CreatedBy: "notes.tab.createdBy",
    ViewAs: "notes.tab.viewAs"
  },
  Screen: {
    SearchPlaceHolder: "notes.screen.searchPlaceHolder"
  },
  Dialog: {
    NewNote: "notes.dialog.newNote",
    HelpDescription: "notes.dialog.helpDescription",
    EditNote: "notes.dialog.editNote",
    NoteTitlePlaceholder: "notes.dialog.noteTitlePlaceholder",
    NoteBodyPlaceholder: "notes.dialog.noteBodyPlaceholder",
    CreateNoteSuccessText: "notes.dialog.createNoteSuccessText",
    LoadingText: "notes.dialog.loadingText",
    UpdatingText: "notes.dialog.updatingText",
    DeleteConfirmationText: "notes.dialog.deleteConfirmationText",
    DeleteNoteLoadingText: "notes.dialog.deleteNoteLoadingText",
    DeleteNoteSuccessText: "notes.dialog.deleteNoteSuccessText",
    LearnAboutNotes: "notes.dialog.learnAboutNotes"
  }
};

export default Notes;
