import { signatureRequestsApi } from "api/signatureRequestsApi";
import spinnerSuccessAnimation from "assets/lottie/spinner-success.json";
import { DateLabel } from "DLUI/date/dateLabel";
import { Lottie } from "DLUI/lottie";
import Text from "DLUI/text";
import TextEllipsis from "DLUI/text/textEllipsis";
import { View } from "DLUI/view";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import type { RowActionPanelLoadingState } from "screens/transactions/components/bankConnect/rowActionsPanel";
import spinnerAnimation from "../../../../assets/lottie/spinner.json";
import { useShakeEffect } from "../../../../hooks/useShakeEffect";
import AppStrings from "../../../../locale/keys";
import { DateFormats } from "@doorloop/dto";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum } from "DLUI/button/dlButton";

interface ComponentProps {
  lastRemindedAt?: Date;
  createdAt?: Date;
  signerId: string;
  signatureRequestId: string;
}

const ReminderRetryMinTime = 60;

const ResendRequestElement: React.FC<ComponentProps> = ({
  lastRemindedAt,
  createdAt,
  signatureRequestId,
  signerId
}: ComponentProps) => {
  const [loadingState, setLoadingState] = useState<RowActionPanelLoadingState | undefined>();
  const [currentDisplayedLottie, setCurrentDisplayedLottie] = useState<any>();
  const { ShakeEffectWrapper, runShakeEffect } = useShakeEffect();
  const [reminderSentSuccessfully, setReminderSentSuccessfully] = useState(false);
  const [lastRemindedSentAt, setLastRemindedSentAt] = useState<Date | undefined>(lastRemindedAt);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const hideResendReminderButton = useMemo(
    () =>
      reminderSentSuccessfully ||
      (lastRemindedAt && moment().diff(moment(lastRemindedSentAt), "m") < ReminderRetryMinTime),
    [reminderSentSuccessfully, lastRemindedSentAt]
  );

  const didPressSendReminder = async () => {
    if (signerId && signatureRequestId) {
      setLoadingState("loading");
      const { data, message, status } = await signatureRequestsApi.sendReminder({
        esignatureRequest: signatureRequestId,
        signerId
      });
      if (data && status) {
        setLoadingState("success");
        setReminderSentSuccessfully(true);
        setLastRemindedSentAt(moment().toDate());
      } else {
        setErrorMessage(message);
        setLoadingState("error");
        setTimeout(() => {
          runShakeEffect();
        }, 500);
      }
    }
  };
  useEffect(() => {
    switch (loadingState) {
      case "loading":
        setCurrentDisplayedLottie(spinnerAnimation);
        break;
      case "success":
        setCurrentDisplayedLottie(spinnerSuccessAnimation);
        setTimeout(() => {
          setCurrentDisplayedLottie(undefined);
        }, 2500);
        break;
      default:
        setCurrentDisplayedLottie(undefined);
    }
  }, [loadingState]);

  const renderButton = () => {
    if (currentDisplayedLottie) {
      return (
        <View marginRight={20} width={"auto"}>
          <Lottie
            loop={loadingState === "loading"}
            animationData={currentDisplayedLottie}
            width={25}
            height={25}
            autoPlay
          />
        </View>
      );
    }
    return (
      !hideResendReminderButton && (
        <ShakeEffectWrapper>
          <DLButton
            onClick={didPressSendReminder}
            actionText={AppStrings.Esignatures.SendReminder}
            color={DLButtonColorsEnum.SECONDARY}
            size={DLButtonSizesEnum.SMALL}
          />
        </ShakeEffectWrapper>
      )
    );
  };

  return (
    <View alignItems={"center"} flexDirection={"row"}>
      <View flex={1}>
        {errorMessage ? (
          <TextEllipsis color={"gray"} fontSize={14} lines={3} text={errorMessage} lineBreak={"auto"} />
        ) : (
          <>
            <Text value={AppStrings.Esignatures.LastReminderSentAt} fontSize={12} color={"gray"} />
            <DateLabel
              noTooltip
              format={DateFormats.SHORT_DATE_TIME}
              date={reminderSentSuccessfully ? new Date() : lastRemindedAt || createdAt}
              textProps={{ fontSize: 14, color: "gray", marginTop: 5 }}
            />
          </>
        )}
      </View>
      {loadingState !== "error" && (
        <View alignItems={"flex-end"} height={"100%"} flex={1}>
          {renderButton()}
        </View>
      )}
    </View>
  );
};

export default ResendRequestElement;
