import _ from "lodash";
import React, { useEffect, useState } from "react";
import type { FormikProps } from "formik";
import { useTranslation } from "react-i18next";

import { CommunicationCenterMessagesSettingsDto, CommunicationCenterSettingsDto, createValidator } from "@doorloop/dto";

import AnimatedContent, { getAnimatedContentFormikRef } from "DLUI/dialogs/components/animatedContent";
import AppStrings from "locale/keys";
import Dialog from "DLUI/dialogs/dialog";
import type { FrameType } from "DLUI/dialogs/components/dialogFrame";
import DialogFrame, { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import type { MenuItem } from "DLUI/dialogs";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { settingsApi } from "api/settingsApi";
import { useResponsiveHelper } from "contexts/responsiveContext";

const DIALOG_WIDTH = 1100;

const DIALOG_HEIGHT = 700;

const validateForm = createValidator(CommunicationCenterSettingsDto);

const initFormValues = (): CommunicationCenterSettingsDto => {
  const dto = new CommunicationCenterSettingsDto();

  dto.tenantsMessagesSettings = new CommunicationCenterMessagesSettingsDto();
  dto.ownersMessagesSettings = new CommunicationCenterMessagesSettingsDto();
  dto.vendorsMessagesSettings = new CommunicationCenterMessagesSettingsDto();
  dto.prospectsMessagesSettings = new CommunicationCenterMessagesSettingsDto();

  return dto;
};

interface ComponentProps {
  onClose: (values?: CommunicationCenterSettingsDto, shouldRefresh?: boolean) => void;
  onBackdropClick?: () => void;
  refreshEvent?: () => void;
  sectionItems: MenuItem[];
  dialogTitle: string;
  isPhoneNumberActive: boolean;
}

let formikGlobalRef: FormikProps<CommunicationCenterSettingsDto> | null = null;

enum ViewIndex {
  LoadingOrContent,
  Alert
}

export const CommunicationCenterAnimatedContentDialog: React.FC<ComponentProps> = ({
  onClose,
  onBackdropClick,
  refreshEvent,
  sectionItems,
  dialogTitle,
  isPhoneNumberActive
}: ComponentProps) => {
  const { t } = useTranslation();
  const { isTabletOrMobile } = useResponsiveHelper();

  const [currentMenuItemTitle, setCurrentMenuItemTitle] = useState<string>("");
  const [viewIndex, setViewIndex] = useState(ViewIndex.LoadingOrContent);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>(t(AppStrings.Common.GeneralError));
  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState<string>(t(AppStrings.Common.Loading));
  const [settingsData, setSettingsData] = useState<CommunicationCenterSettingsDto | null>(null);

  useEffect(() => {
    loadSettingsData();

    return () => {
      formikGlobalRef = null;
    };
  }, []);

  const loadSettingsData = async (): Promise<void> => {
    setLoadingDialogLoadingText(t(AppStrings.Common.Loading));
    setLoadingDialogState(DialogState.Show);

    const { status, data, message } = await settingsApi.getCommunicationCenterSettings();

    if (status && data) {
      setSettingsData(data);
      setLoadingDialogState(DialogState.Hidden);
    } else {
      setLoadingDialogErrorText(message);
      setLoadingDialogState(DialogState.Error);
    }
  };

  const didFinishOperation = (values: CommunicationCenterSettingsDto): void => {
    onClose(values);

    setTimeout(() => {
      setViewIndex(ViewIndex.LoadingOrContent);

      if (refreshEvent) {
        refreshEvent();
      }
    }, 500);
  };

  const renderActionPanelButtons = (formik: FormikProps<CommunicationCenterSettingsDto>) => {
    formikGlobalRef = formik;

    if (isPhoneNumberActive || isTabletOrMobile) {
      return (
        <FormActionButtons
          propsSubButton={{ onClick: () => onBackdropClick?.() }}
          propsMainButton={isPhoneNumberActive ? { type: "cta", props: { onClick: didPressSaveButton } } : undefined}
        />
      );
    }

    return null;
  };

  const didPressDismissButton = (): void => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(ViewIndex.LoadingOrContent);
  };

  const getFormikInitialValues = (): CommunicationCenterSettingsDto => {
    if (formikGlobalRef?.values) {
      return formikGlobalRef.values;
    }

    if (settingsData) {
      return settingsData;
    }

    return initFormValues();
  };

  const renderView = ({ index }: { index: ViewIndex }) => {
    if (index === ViewIndex.LoadingOrContent) {
      if (loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={t(AppStrings.Common.OperationCompleted)}
            onRetryButtonPress={loadSettingsData}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }

      return (
        <AnimatedContent
          formikInitialValues={getFormikInitialValues}
          sectionItems={sectionItems}
          formikValidation={validateForm}
          dialogHeight={DIALOG_HEIGHT}
          renderActionPanelButtons={renderActionPanelButtons}
          onMenuItemSelection={setCurrentMenuItemTitle}
        />
      );
    }

    if (index === ViewIndex.Alert) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          loadingText={loadingDialogLoadingText}
          errorText={loadingDialogErrorText}
          successText={t(AppStrings.Common.OperationCompleted)}
          onRetryButtonPress={onLoadingDialogRetryButtonPress}
          didPressDismissButton={didPressLoadingDialogDismissButton}
        />
      );
    }

    return null;
  };

  const updateSettingsData = async (): Promise<void> => {
    const formikRef = getAnimatedContentFormikRef();

    if (!formikRef) {
      return;
    }

    setLoadingDialogLoadingText(t(AppStrings.Common.Loading));
    setLoadingDialogState(DialogState.Show);
    setViewIndex(ViewIndex.Alert);

    const { status, data, message } = await settingsApi.updateCommunicationCenterSettings(formikRef.values);

    if (status && data) {
      didFinishOperation(data);
      setLoadingDialogState(DialogState.Hidden);
      formikGlobalRef = null;
    } else {
      setLoadingDialogErrorText(message);
      setLoadingDialogState(DialogState.Error);
    }
  };

  const didPressSaveButton = async (): Promise<void> => {
    if (!formikGlobalRef) {
      return;
    }

    const errors = await formikGlobalRef.validateForm();

    if (!_.isEmpty(errors)) {
      return;
    }

    await updateSettingsData();
  };

  const onLoadingDialogRetryButtonPress = async (): Promise<void> => {
    setLoadingDialogState(DialogState.Show);

    await updateSettingsData();
  };

  const didPressLoadingDialogDismissButton = (): void => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(ViewIndex.LoadingOrContent);
  };

  const getFrameType = (): FrameType => {
    if (loadingDialogState !== DialogState.Hidden) {
      return "contentOnly";
    }

    return "sideMenu";
  };

  const frameType = getFrameType();

  return (
    <Dialog open={true} onClose={_.noop} disableBackdropClick>
      <DialogFrame
        onCloseButtonClick={() => onBackdropClick?.()}
        width={getDialogFrameDimension("width", DIALOG_WIDTH)}
        height={getDialogFrameDimension("height", DIALOG_HEIGHT)}
        renderView={renderView}
        numViews={2}
        activeView={viewIndex}
        frameType={frameType}
        title={dialogTitle}
        sectionTitle={currentMenuItemTitle}
      />
    </Dialog>
  );
};
