import type { FormikProps } from "formik";
import { createValidator, UnitDto, UnitListingInfoDto, UnitRentalAppListingInfoDto } from "@doorloop/dto";
import _ from "lodash";
import { getTransunionPropertyId } from "DLUI/dialogs/components/editUnit/editUnitGeneralInfo";

export const initFormValues = (defaultPropertyId?: string, unitId?: string): UnitDto => {
  const transunionPropertyId = getTransunionPropertyId(defaultPropertyId, unitId);

  return new UnitDto({
    pictures: [],
    listing: new UnitListingInfoDto(),
    rentalApplicationListing: transunionPropertyId
      ? new UnitRentalAppListingInfoDto({
          activeListing: true
        })
      : new UnitRentalAppListingInfoDto(),
    amenities: [],
    property: defaultPropertyId
  });
};

export const validateForm = createValidator(UnitDto);

export const validateUnitForm = async (
  formikRef: FormikProps<any>
): Promise<{ isValid: boolean; errorStepIndex?: number }> => {
  await formikRef.submitForm();
  formikRef.setFieldTouched("name");
  formikRef.setFieldTouched("listing.dateAvailable");
  formikRef.setFieldTouched("listing.rent");
  formikRef.setFieldTouched("listing.contact");
  const errors = (await formikRef.validateForm()) as any;
  if (errors.listing) {
    formikRef.setSubmitting(false);
    return { isValid: false, errorStepIndex: 3 };
  }
  return { isValid: _.isEmpty(errors) };
};
