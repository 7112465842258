import type { ReactElement } from "react";
import React, { useEffect, useMemo, useState } from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import makeStyles from "./styles";
import "./styles.css";
import { drawerOpenWidth } from "../drawer/styles";
import { history } from "store/history";
import { useLocation } from "react-router-dom";
import { View } from "DLUI/view";
import type { ViewBackgroundColor } from "DLUI/view/view";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import { usePermission } from "screens/settings/userRoles/usePermission";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { MOBILE_TOP_NAVIGATION_BAR_HEIGHT } from "DLUI/screen/mobile/mobileTopNavigationBar";
import { analyticsService } from "../../../services/analyticsService";
import type { ObjectWithHiddenForUserTypes } from "../../../utils/userTypesUtils";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import { useAnalyticsService } from "../../../hooks/useAnalyticsService";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { DESKTOP_TOP_BAR_HEIGHT } from "DLUI/screen/topNavigationBar/topNavigationBar";
import { notificationsContentVisibilityAtom } from "components/layouts/layoutAtom";
import { useAtom } from "jotai";
import {
  NOTIFICATIONS_CONTENT_DESKTOP_HEIGHT,
  NOTIFICATIONS_CONTENT_MOBILE_HEIGHT
} from "DLUI/screen/notificationsPanel/notificationsPanel";
import type { IndicationTag } from "DLUI/drawer/types";
import { Tag } from "DLUI/tag/tag";

export interface TabItemProps<DATA_TYPE> {
  screenData?: DATA_TYPE;
  refreshScreen?: () => void;
  isSelected?: boolean;
}

export interface TabItem<DATA_TYPE = {}> extends ObjectWithHiddenForUserTypes {
  label: string;
  component: React.ComponentType<TabItemProps<DATA_TYPE>>;
  resultsCount?: number;
  key?: string;
  clearance?: AnyPermissionClearance[];
  dataCy?: string;
  removeContentPadding?: boolean;
  removeBottomPadding?: boolean;
  hideSeparator?: boolean;
  tag?: IndicationTag;
}

interface ComponentProps {
  tabsItems: TabItem[];
  screenData?: any;
  tabsPanelBackgroundColor?: ViewBackgroundColor;
  tabsPanelPadding?: number;
  permission?: AnyPermissionClearance;
  refreshScreen?: () => void;
  renderScreenHeader?: (screenData: any) => ReactElement;
}

export const TabsComponent: React.FC<ComponentProps> = ({
  tabsItems,
  screenData,
  tabsPanelBackgroundColor,
  permission,
  refreshScreen,
  renderScreenHeader
}: ComponentProps) => {
  const { hasAnyPermission } = usePermission();
  const { t } = useTranslation();
  const classes = makeStyles();
  const { isTabletOrMobile, screenContainerPadding, isMobile } = useResponsiveHelper();
  const [isNotificationsContentIsVisible] = useAtom(notificationsContentVisibilityAtom);

  const [screenTabs] = useState<TabItem[]>(
    tabsItems.filter((tabItem) => !tabItem.clearance || hasAnyPermission(tabItem.clearance))
  );

  const location = useLocation<any>();

  const getDisplayTabIndex = () => {
    const locationParts = location.pathname.split("/");
    let selectedTabIndex = 0;
    locationParts.forEach((currentPart) => {
      screenTabs.forEach((currentTabItem, index) => {
        if (currentPart === t(currentTabItem.label).toLowerCase().replace(/ /g, "-")) {
          selectedTabIndex = index;
        }
      });
    });
    return selectedTabIndex;
  };
  const displayTabIndex = getDisplayTabIndex();

  const currentTab = screenTabs[displayTabIndex];
  const contentPadding = currentTab.removeContentPadding ? 0 : screenContainerPadding;

  const [visitedTabs, setVisitedTabs] = useState<number[]>([]);

  useEffect(() => {
    const visitedTabsSet = new Set(visitedTabs);
    visitedTabsSet.add(getDisplayTabIndex());
    setVisitedTabs(Array.from(visitedTabsSet));
  }, [location.pathname]);

  const { dispatchAnalytics, formatAppStringsValue } = useAnalyticsService();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    const locationParts = location.pathname.split("/");
    const newTab = screenTabs[newValue];
    let nextLocation = location.pathname + "/" + t(newTab.label).toLowerCase().replace(/ /g, "-");
    let shouldReplaceTabName = false;

    dispatchAnalytics("entity_details_tab_click", {
      label: formatAppStringsValue(newTab.label)
    });

    screenTabs.forEach((currentTab) => {
      const tabName = t(currentTab.label).toLowerCase().replace(/ /g, "-");
      locationParts.forEach((currentPart) => {
        if (tabName === currentPart) {
          shouldReplaceTabName = true;
        }
      });
    });

    if (shouldReplaceTabName) {
      locationParts[locationParts.length - 1] = t(newTab.label).toLowerCase().replace(/ /g, "-");
      nextLocation = locationParts.join("/");
    }

    const set = new Set(visitedTabs);
    set.add(newValue);
    setVisitedTabs(Array.from(set));
    history.push(nextLocation);
    analyticsService.page();
  };

  const renderTabLabel = (item: TabItem) => {
    const { label, tag } = item;

    if (!tag) {
      return t(label);
    }

    return (
      <View flexDirection={"row"}>
        {t(label)}
        <Tag value={tag} variant={"contained"} marginLeft={4} />
      </View>
    );
  };

  const renderTabs = () =>
    screenTabs.map((currentItem, index) => (
      <Tab
        classes={{
          root: classes.tabButton,
          textColorPrimary: classes.tabTextColor,
          selected: classes.tabTextColorSelected
        }}
        key={"TB" + index}
        data-cy={currentItem.dataCy}
        label={renderTabLabel(currentItem)}
      />
    ));

  const renderTabPanels = () =>
    screenTabs.map((currentItem, index) => {
      const CurrentTab = currentItem.component;
      const shouldRender = index === displayTabIndex;
      const currentClass = !shouldRender ? classes.tabHidden : classes.tabVisible;
      return (
        <Typography
          component="div"
          role="tabpanel"
          hidden={!shouldRender}
          id={`full-width-tabpanel-${index}`}
          className={currentClass}
          key={"TP" + index}
        >
          <div className={classes.tabContainer} style={{ paddingRight: contentPadding, paddingLeft: contentPadding }}>
            {shouldRender ? (
              <CurrentTab
                refreshScreen={refreshScreen}
                screenData={screenData}
                isSelected={displayTabIndex === index}
              />
            ) : (
              <div />
            )}
          </div>
        </Typography>
      );
    });

  const getElementHeight = (className: string) => {
    const backgroundLayerElement = document.getElementsByClassName(className)[0];
    if (backgroundLayerElement) {
      // @ts-ignore
      return backgroundLayerElement.offsetHeight;
    }
    return 0;
  };

  const tabHeight = useMemo(() => {
    let calculatedHeightDelta = isMobile ? MOBILE_TOP_NAVIGATION_BAR_HEIGHT : DESKTOP_TOP_BAR_HEIGHT;
    if (isNotificationsContentIsVisible) {
      calculatedHeightDelta += isMobile ? NOTIFICATIONS_CONTENT_MOBILE_HEIGHT : NOTIFICATIONS_CONTENT_DESKTOP_HEIGHT;
    }

    return `calc(100vh - ${calculatedHeightDelta}px)`;
  }, [isNotificationsContentIsVisible, isMobile]);

  return (
    <View noWrap>
      <div
        style={{
          overflowY: "auto",
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          height: tabHeight
        }}
        id={"screenScroll"}
      >
        <View noWrap>
          <View paddingRight={screenContainerPadding} paddingLeft={screenContainerPadding}>
            {renderScreenHeader && renderScreenHeader(screenData)}
          </View>

          <View
            backgroundColor={tabsPanelBackgroundColor}
            className={"tabs_container"}
            flexDirection={"row"}
            paddingRight={screenContainerPadding}
            paddingLeft={screenContainerPadding}
          >
            <Tabs
              value={displayTabIndex}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="tabs"
              variant="scrollable"
              scrollButtons="auto"
              className={"tabsContainer"}
              style={{
                width: isTabletOrMobile
                  ? window.innerWidth - screenContainerPadding * 2
                  : window.innerWidth - drawerOpenWidth - screenContainerPadding * 2
              }}
            >
              {renderTabs()}
            </Tabs>
            {!currentTab.hideSeparator && <HorizontalSeparationLine marginBottom={20} />}
          </View>
          <RestrictedPermissionAccess clearance={permission} showNoAccess>
            {renderTabPanels()}
          </RestrictedPermissionAccess>
          {!currentTab.removeBottomPadding && <View height={40} flexDirection={"row"} />}
        </View>
      </div>
    </View>
  );
};
