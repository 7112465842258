import type { GetAllCommunicationsQuery } from "@doorloop/dto";
import { CommunicationListItem } from "screens/communications/communicationListItem/communicationListItem";
import { communicationsApi } from "api/communicationsApi";
import { EmptyAnnouncement } from "screens/announcements/emptyAnnouncement";
import { DataList } from "@/components/DLUI/dataList";

interface CommunicationsListProps {
  filterObj: GetAllCommunicationsQuery;
  tenantId?: string;
}

export const CommunicationsList = ({ filterObj, tenantId }: CommunicationsListProps) => (
  <DataList
    infinite
    restApi={communicationsApi}
    emptyListView={() => <EmptyAnnouncement isCommunication tenantId={tenantId} />}
    queryFilter={filterObj}
  >
    {(currentItem) => <CommunicationListItem dto={currentItem} />}
  </DataList>
);
