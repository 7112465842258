import type { BulkTransactionDto, GetAllAccountsQuery, PropertyDto, UnitDto } from "@doorloop/dto";
import {
  AccountClass,
  AllocationRounding,
  AllocationStrategy,
  AllocationStrategyWithCustom,
  LeaseTransactionWithUnitBaseDto,
  ObjectPermission
} from "@doorloop/dto";
import { Grid } from "@material-ui/core";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum } from "DLUI/button/dlButton";
import { EditableTransactionTable } from "DLUI/dialogs/transactions/editableTransactionTable/editableTransactionTable";
import PropertySelection from "DLUI/dialogs/transactions/propertySelection";
import { FormikDatePicker, Select, ValidationIndicator } from "DLUI/form";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";
import TextField from "DLUI/form/textField/textField";
import NetworkError from "DLUI/lists/gridList/dataGrid/networkError";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import { customAllocationsApi } from "api/customAllocationsApi";
import { leasesApi } from "api/leasesApi";
import { unitsApi } from "api/unitsApi";
import { BlueRefreshIcon } from "assets/.";
import { FastField, useFormikContext } from "formik";
import { useEffectAsync } from "hooks/useEffectAsync";
import AppStrings from "locale/keys";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { usePermission } from "screens/settings/userRoles/usePermission";
import { allocateUsingStrategy } from "./allocationUtil";
import { DeltaConflictWarning } from "./deltaConflictWarning";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";

const PAGE_SIZE = 500;

interface ComponentProps {
  selectedProperty: PropertyDto | undefined;
  didPressSelectProperty: () => void;
  didModifyTransactions: () => void;
  error?: string;
  type: "charge" | "credit";
}

const buildTransaction = ({ unitId, leaseId }: { unitId: string; leaseId: string }): LeaseTransactionWithUnitBaseDto =>
  new LeaseTransactionWithUnitBaseDto({
    unitId,
    lease: leaseId,
    totalAmount: undefined, // we don't care about this value, here for validation
    memo: "", // we don't care about this value, here for validation
    date: "1974-01-01", // we don't care about this value, here for validation
    lines: [
      {
        amount: 0,
        memo: "",
        account: "0".repeat(24) // we don't care about this value, here for validation
      }
    ]
  });

export const BulkChargeOrCreditFormView: React.FC<ComponentProps> = ({
  selectedProperty,
  didModifyTransactions,
  didPressSelectProperty,
  error: errorProp,
  type
}) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermission();
  const formikContext = useFormikContext<BulkTransactionDto>();

  const { strategy: strategyValue } = formikContext.values;

  const showAutomaticAllocationOptions = strategyValue !== AllocationStrategyWithCustom.MANUAL;
  const useCustomAllocation = strategyValue === AllocationStrategyWithCustom.CUSTOM;
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [totalAmountConflictDelta, setTotalAmountConflictDelta] = React.useState<number | undefined>(undefined);
  const [leaseToUnitMap, setLeaseToUnitMap] = React.useState<Map<string, UnitDto>>(new Map());
  const [transactions, setTransactions] = React.useState<LeaseTransactionWithUnitBaseDto[]>([]);
  const [areUnitsLoading, setAreUnitsLoading] = React.useState(false);
  const [isAutomaticAllocationLoading, setIsAutomaticAllocationLoading] = React.useState(false);
  const [networkError, setNetworkError] = React.useState(false);
  const isCustomAllocationAllowed = hasPermission(ObjectPermission.bulkOperations);
  const AllocationStrategyEnum = React.useMemo(
    () => (isCustomAllocationAllowed ? AllocationStrategyWithCustom : AllocationStrategy),
    [isCustomAllocationAllowed]
  );

  const bankAccountQueryParams: GetAllAccountsQuery = {
    filter_active: true,
    filter_classes:
      type === "credit"
        ? [AccountClass.LIABILITY, AccountClass.REVENUE, AccountClass.EXPENSE, AccountClass.EQUITY]
        : [AccountClass.LIABILITY, AccountClass.REVENUE, AccountClass.EXPENSE]
  };

  useEffect(() => {
    formikContext.resetForm();
    setError(undefined);
    setTotalAmountConflictDelta(undefined);
    formikContext.setFieldValue("property", selectedProperty?.id!, false);
  }, [selectedProperty]);

  useEffect(() => {
    if (strategyValue === AllocationStrategyWithCustom.MANUAL) {
      setTotalAmountConflictDelta(undefined);
    }
  }, [strategyValue]);

  const loadUnits = async (property: PropertyDto) => {
    /**
     * Get the units from the property.
     * Then, for each unit, get the associated lease
     * Then map the units to create a charge for each unit.
     */
    try {
      setAreUnitsLoading(true);
      setNetworkError(false);

      /**
       * We will need dictionaries later in the table view.
       */
      await leasesApi.loadDictionariesRequiredForGet();
      await unitsApi.loadDictionariesRequiredForGet();

      const { data: leases, statusCode: leasesStatusCode } = await leasesApi.getAll({
        filter_property: property.id,
        page_size: PAGE_SIZE
      });
      const { data: units, statusCode: unitsStatusCode } = await unitsApi.getAll({
        filter_property: property.id,
        page_size: PAGE_SIZE
      });

      if (leasesStatusCode !== 200 || unitsStatusCode !== 200) {
        setNetworkError(true);
        return;
      }

      if (!leases?.data || !units?.data) {
        setError(AppStrings.Leases.LeaseCharge.NoUnitsSelected);
        return;
      }

      /**
       * The process below is basically a SQL `join` between leases and units.
       * Since we use MongoDB, our options are limited, so we have to do it manually.
       */
      const unitsSet = new Set();
      const newLeaseToUnitMap = new Map<string, UnitDto>();
      const transactions = leases.data
        .map((lease) => {
          const allLinkedUnits = lease.units;
          // return an array of transactions for each unit
          return allLinkedUnits.map((unitId) => {
            const unitDto = units.data.find((unit) => unit.id === unitId);

            if (!unitDto || lease.status !== "ACTIVE") return;

            unitsSet.add(unitId);
            newLeaseToUnitMap.set(lease.id!, unitDto);
            return buildTransaction({ unitId, leaseId: lease.id! });
          });
        })
        .flat()
        .filter(Boolean);

      /**
       * Here we inject the Vacant units to the end of the list.
       *
       * Vacant units are units that are not linked to any lease
       * We loop over all units and check if we've already seen it before while looping over the leases
       * If we haven't, that means unit is NOT associated with any lease, so we add it to the list as a Vacant unit
       */
      units.data.forEach((unit) => {
        if (unitsSet.has(unit.id)) return;
        transactions.push(buildTransaction({ unitId: unit.id!, leaseId: "" }));
      });

      if (!transactions) {
        setError(AppStrings.Leases.BulkActionErrors.NoLeasesFoundForThisProperty);
        return;
      }

      setLeaseToUnitMap(newLeaseToUnitMap);
      setTransactions(transactions as LeaseTransactionWithUnitBaseDto[]);
      formikContext.setFieldValue("transactions", transactions, false);
    } catch (error) {
      console.error(error);
    } finally {
      setAreUnitsLoading(false);
    }
  };

  useEffectAsync(async () => {
    if (!selectedProperty) {
      setTransactions([]);
      return;
    }

    await loadUnits(selectedProperty);
  }, [selectedProperty]);

  useEffect(() => {
    didModifyTransactions();
  }, [formikContext.values.transactions]);

  const onAllocateTransactionsClick = async () => {
    const strategy = formikContext.values.strategy;
    const total = formikContext.values.total;
    const rounding = formikContext.values.rounding ?? AllocationRounding.NEAREST_HUNDREDTH;
    let actualAllocatedAmount: number | undefined = 0;
    setError(undefined);
    if (strategy === AllocationStrategyWithCustom.MANUAL) {
      return;
    }

    if (!total || total <= 0) {
      setError(
        type === "charge"
          ? AppStrings.Leases.BulkActionErrors.TotalChargeMustBeGreaterThanZero
          : AppStrings.Leases.BulkActionErrors.TotalCreditMustBeGreaterThanZero
      );
      return;
    }

    const areAnyTransactionsSelected = formikContext.values.transactions.some(
      (charge) => charge.lease && charge?.isSelected
    );

    if (!areAnyTransactionsSelected) {
      setError(AppStrings.Leases.LeaseCharge.NoUnitsSelected);
      return;
    }
    const selectedChargeCount = formikContext.values.transactions.filter(
      (charge) => charge.lease && charge?.isSelected
    ).length;

    if (selectedChargeCount === 0) {
      setError(AppStrings.Leases.LeaseCharge.NoUnitsSelected);
      return;
    }
    try {
      setIsAutomaticAllocationLoading(true);
      if (strategy === AllocationStrategyWithCustom.EVEN) {
        actualAllocatedAmount = await allocateUsingStrategy({
          strategy,
          total,
          rounding,
          selectedChargeCount,
          leaseToUnitMap,
          transactions,
          formikContext
        });
      } else if (strategy === AllocationStrategyWithCustom.BY_SIZE) {
        // Allocate the transactions based on the size of the unit (unit.sqft)
        // similarly, we only need to allocate the transactions that are selected.
        const totalSelectedSqft = transactions
          .filter((transaction, index) => transaction.lease && formikContext.values.transactions[index].isSelected)
          .map((charge) => {
            const unit = leaseToUnitMap.get(charge.lease!);
            return unit?.size ?? 0;
          })
          .reduce((a, b) => a + b, 0);

        if (!totalSelectedSqft || totalSelectedSqft <= 0) {
          setError(AppStrings.Leases.BulkActionErrors.NoUnitsWithValidSizes);
          return;
        }

        actualAllocatedAmount = await allocateUsingStrategy({
          strategy,
          total,
          rounding,
          selectedChargeCount,
          totalSelectedSqft,
          leaseToUnitMap,
          transactions,
          formikContext
        });
      } else if (strategy === AllocationStrategyWithCustom.CUSTOM) {
        const customAllocationId = formikContext.values.customAllocation;
        if (!customAllocationId) {
          setError(AppStrings.Leases.BulkActionErrors.NoCustomAllocationSelected);
          return;
        }

        actualAllocatedAmount = await allocateUsingStrategy({
          strategy,
          total,
          rounding,
          selectedChargeCount,
          leaseToUnitMap,
          customAllocationId,
          transactions,
          formikContext
        });
      }
    } finally {
      setIsAutomaticAllocationLoading(false);
      if (actualAllocatedAmount) {
        onTotalAmountChanged(actualAllocatedAmount);
      }
    }
  };

  const onTotalAmountChanged = (newTotalAmount: number) => {
    const totalAmount = formikContext.values.total;
    if (!totalAmount || strategyValue === AllocationStrategyWithCustom.MANUAL) {
      setTotalAmountConflictDelta(undefined);
      return;
    }
    const delta = totalAmount - newTotalAmount;

    setTotalAmountConflictDelta(Math.abs(delta) > 0.001 ? delta : undefined);
  };

  if (networkError) {
    return (
      <View alignItems="center" justifyContent="center" height="100%">
        <NetworkError
          fetchData={async () => {
            if (selectedProperty) {
              await loadUnits(selectedProperty);
            }
          }}
        />
      </View>
    );
  }

  return (
    <View paddingTop={20} paddingLeft={20} paddingRight={20} paddingBottom={20} gap={20}>
      <Grid container alignItems={"center"} spacing={2}>
        <Grid item xs={12} sm={12} md lg>
          <PropertySelection propertyId={selectedProperty?.id} didPressSelectProperty={didPressSelectProperty} />
        </Grid>
        <Grid container item xs={6} sm={6} md={3} lg={3} style={{ height: 70 }} alignItems="center">
          <BankAccountFormikAutoCompleteField
            addCreateOption
            uniqueIndex={"bulk_bankAccountsApi"}
            name={"account"}
            queryParams={bankAccountQueryParams}
            label={t(AppStrings.Common.Account)}
          />
        </Grid>
        <Grid container item xs={6} sm={6} md={3} lg={3} style={{ height: 70 }} alignItems="center">
          <FastField
            component={FormikDatePicker}
            uniqueKey={`date_bulk`}
            label={
              type === "charge"
                ? AppStrings.Leases.NewLease.LeaseRent.DueDate
                : AppStrings.Leases.NewLease.LeaseRent.DateIssued
            }
            name={type === "charge" ? "dueDate" : "issueDate"}
            noMargin
            required
          />
        </Grid>
      </Grid>
      {/* --- Automatic Allocation --- */}

      {selectedProperty && (
        <>
          {(error || errorProp) && (
            <View flexDirection={"column"} alignItems={"center"} justifyContent={"center"} gap={5}>
              <ValidationIndicator
                shouldShow={Boolean(error)}
                maxWidth={700}
                justifyContent={"center"}
                displayText={error ? t(error) : ""}
              />
              <ValidationIndicator
                shouldShow={Boolean(errorProp)}
                maxWidth={700}
                justifyContent={"center"}
                displayText={errorProp ? t(errorProp) : ""}
              />
            </View>
          )}
          <Text
            value={
              type === "charge"
                ? AppStrings.Leases.LeaseCharge.PerUnitCharges
                : AppStrings.Leases.LeaseCredit.PerUnitCredits
            }
            bold
            fontSize={18}
          />
          <Grid container item alignItems={"center"} spacing={3} style={{ width: "100%" }}>
            <Grid item sm={showAutomaticAllocationOptions ? true : 4} xs={12}>
              <FastField
                component={Select}
                name={`strategy`}
                label={
                  type === "charge"
                    ? AppStrings.Leases.LeaseCharge.CommonChargeAllocation
                    : AppStrings.Leases.LeaseCredit.CommonCreditAllocation
                }
                required
                uniqueKey={"strategy"}
                selectionEnum={AllocationStrategyEnum}
                onChange={() => {}}
                translationKey={"allocationStrategyWithCustom"}
              />
            </Grid>
            {useCustomAllocation && (
              <Grid item sm={true} xs={12}>
                <FormikCachedAsyncAutoComplete
                  hideErrorText
                  name={`customAllocation`}
                  label={t(AppStrings.Allocations.CustomAllocation)}
                  apiHandler={customAllocationsApi}
                  displayNameKey={"allocationName"}
                  queryParams={{ filter_property: selectedProperty.id ?? "" }}
                  filterFieldName={"filter_property"}
                  filterFieldValue={selectedProperty.id ?? ""}
                  selectionFields={["id"]}
                  uniqueIndex={150}
                />
              </Grid>
            )}
            {showAutomaticAllocationOptions && (
              <>
                <Grid item lg md sm xs={12}>
                  <FastFieldSafe
                    component={TextField}
                    hideErrorText
                    name={`total`}
                    label={t(
                      type === "charge"
                        ? AppStrings.Leases.LeaseCharge.TotalCharge
                        : AppStrings.Leases.LeaseCredit.TotalCredit
                    )}
                    required
                    formatType={"currency"}
                  />
                </Grid>
                <Grid item lg md sm xs={12}>
                  <FastFieldSafe
                    component={Select}
                    name={`rounding`}
                    label={AppStrings.Leases.LeaseCharge.Rounding}
                    required
                    uniqueKey={"rounding"}
                    selectionEnum={AllocationRounding}
                    translationKey={"rounding"}
                  />
                </Grid>
                <View autoWidth>
                  <DLButton
                    onClick={onAllocateTransactionsClick}
                    actionText={AppStrings.Common.Allocate}
                    color={DLButtonColorsEnum.SECONDARY}
                    size={DLButtonSizesEnum.LARGE}
                    isLoading={isAutomaticAllocationLoading}
                    icons={{ start: { src: BlueRefreshIcon } }}
                    style={{ minWidth: 150 }}
                  />
                </View>
              </>
            )}
          </Grid>
        </>
      )}
      {/*  ---- Editable Form table ---- */}
      <EditableTransactionTable
        onTotalAmountChanged={onTotalAmountChanged}
        isLoading={areUnitsLoading}
        transactions={transactions}
      />
      {Boolean(totalAmountConflictDelta) && <DeltaConflictWarning delta={totalAmountConflictDelta ?? 0} type={type} />}
    </View>
  );
};
