import {
  createValidator,
  RentReminderOverdueNotificationsFrequency,
  RentRemindersSettingsDto,
  SettingPermission,
  UserStatus
} from "@doorloop/dto";
import { Grid } from "@material-ui/core";
import type { ApiResult } from "api/apiResult";
import { settingsApi } from "api/settingsApi";
import { usersApi } from "api/usersApi";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import { FormikSwitchButton, Select, SwitchButton, TextField } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, FormikContext, useFormik } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SettingsReadOnlyScope } from "DLUI/permissionScope/readOnlyScope";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import FormikCachedMultiSelectAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedMultiSelectAutoComplete";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { trackRentReminderSettings } from "DLUI/dialogs/settings/common/analytics/trackRentReminderSettings";
import { useCashPayment } from "hooks/useCashPayments";
import { PopoverHover } from "DLUI/popover/popoverHover";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { RentChargeNotifications } from "screens/settings/rentReminders/rentChargeNotificationsSection";
import { SettingsLevels } from "screens/settings/rentReminders/getRentRemindersDynamicSettingLevelFieldsPath";
import { useSelector } from "react-redux";
import type { IRootState } from "store";

interface ComponentProps {
  didFinishOperation: (values: RentRemindersSettingsDto) => void;
  onBackdropClick: () => void;
  refreshEvent?: () => void;
  dialogTitle: string;
}

const validateForm = createValidator(RentRemindersSettingsDto);

const DialogFrameMinHeight = 850;

const EditRentReminders: React.FC<ComponentProps> = ({
  didFinishOperation,
  refreshEvent,
  onBackdropClick,
  dialogTitle
}: ComponentProps) => {
  const { t } = useTranslation();
  const { dialogHorizontalPadding } = DialogsHelper();

  const [dialogFrameHeight] = useState<number>(DialogFrameMinHeight);
  const [rentRemindersSettingsData, setRentRemindersSettingsData] = useState<RentRemindersSettingsDto | undefined>();
  const [showNotifyUsersByEmail, setShowNotifyUsersByEmail] = useState<boolean>(false);
  const [showDaysBeforeDueDateToPostRecurringCharges, setShowDaysBeforeDueDateToPostRecurringCharges] =
    useState<boolean>(false);

  const [showNotifyUsersByEmailReversedPayment, setShowNotifyUsersByEmailReversedPayment] = useState<boolean>(false);

  const [viewIndex, setViewIndex] = useState(0);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);

  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>(AppStrings.Common.NetworkErrorSubTitle);

  const [hideLoadingDialogActionButtons, setHideLoadingDialogActionButtons] = useState<boolean>(false);

  const { checkIfCashPaymentIsEnabled, isCashPaymentEnabled } = useCashPayment();
  const { isFeatureFlagActive } = useFeatureFlag("CashPayments");
  const dbTenantId = useSelector((state: IRootState) => state.auth.currentLoginResponse?.currentDbTenant?.id);

  useEffect(() => {
    if (!dbTenantId) {
      return;
    }

    checkIfCashPaymentIsEnabled("company", dbTenantId);
  }, [dbTenantId]);

  const initFormValues = (): RentRemindersSettingsDto => {
    if (rentRemindersSettingsData) {
      if (!rentRemindersSettingsData?.recurringNotificationsForOverdueRentFrequency) {
        rentRemindersSettingsData.recurringNotificationsForOverdueRentFrequency =
          RentReminderOverdueNotificationsFrequency.ONCE;
      }

      return rentRemindersSettingsData;
    }

    return new RentRemindersSettingsDto();
  };

  const formikRef = useFormik<RentRemindersSettingsDto>({
    initialValues: initFormValues(),
    onSubmit: _.noop,
    validate: validateForm,
    enableReinitialize: true
  });

  const loadRentRemindersSettings = async () => {
    try {
      const response = await settingsApi.getRentReminders();
      const rentReminders: RentRemindersSettingsDto = response?.data;

      if (!response?.status || !rentReminders) {
        showDialogError();
        return;
      }

      if (rentReminders.postRecurringChargesBeforeDueDate) {
        setShowDaysBeforeDueDateToPostRecurringCharges(true);
      }
      if (!_.isEmpty(rentReminders.sendEmailToUsersWhenOnlinePaymentIsReceivedUsers)) {
        setShowNotifyUsersByEmail(true);
      }
      if (!_.isEmpty(rentReminders.sendEmailToUsersWhenPaymentIsReturnedUsers)) {
        setShowNotifyUsersByEmailReversedPayment(true);
      }

      setRentRemindersSettingsData(rentReminders);
      setLoadingDialogState(DialogState.Success);

      formikRef.setValues(rentReminders);
    } catch (e: unknown) {
      showDialogError("Error loading rent reminders settings");
    }
  };

  const showDialogError = (errorMessage?: string) => {
    setHideLoadingDialogActionButtons(true);
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(errorMessage || t(AppStrings.Common.GeneralError));
  };

  useEffect(() => {
    loadRentRemindersSettings();
  }, []);

  const isValidForm = async (formikRef: FormikProps<RentRemindersSettingsDto>) => {
    formikRef.setFieldTouched("daysBeforeToSendRentReminders");
    formikRef.setFieldTouched("sendEmailToUsersWhenOnlinePaymentIsReceivedUsers");
    formikRef.setFieldTouched("sendEmailToUsersWhenPaymentIsReturnedUsers");
    formikRef.setFieldTouched("daysAfterDueDateToSendRecurringNotifications");
    formikRef.setFieldTouched("recurringNotificationsForOverdueRentFrequency");

    const errors = await formikRef.validateForm();

    return _.isEmpty(errors);
  };

  const updateRentRemindersSettings = async (values: RentRemindersSettingsDto) => {
    if (!rentRemindersSettingsData) {
      return;
    }

    const response = (await settingsApi.updateRentReminders(values).catch((error) => {
      setLoadingDialogErrorText(error);
      setLoadingDialogState(DialogState.Error);
    })) as ApiResult<any>;

    if (response.status !== undefined && !response.status) {
      setLoadingDialogErrorText(response.message);
      setLoadingDialogState(DialogState.Error);
    } else {
      trackRentReminderSettings("General Settings", values);

      setLoadingDialogState(DialogState.Success);
      didFinishUpdateProperty();
    }
  };

  const didPressSaveButton = async () => {
    if (!rentRemindersSettingsData) {
      return;
    }

    const isValid = await isValidForm(formikRef);
    if (isValid) {
      setLoadingDialogState(DialogState.Show);
      setViewIndex(1);
      updateRentRemindersSettings(formikRef.values);
    }
  };

  const renderActionPanelButtons = () => {
    if (viewIndex === 1) {
      return <div />;
    }

    return (
      <FormActionButtons
        propsSubButton={{ onClick: onBackdropClick }}
        propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
      />
    );
  };

  const didPressDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(0);
  };

  const onRetryButtonPress = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(0);
  };

  const didFinishUpdateProperty = () => {
    didFinishOperation(formikRef.values);
    refreshEvent?.();
    setLoadingDialogState(DialogState.Hidden);
  };

  const didChangeRentNotification = (nextSwitchState: boolean) => {
    !nextSwitchState && formikRef?.setFieldValue("daysBeforeToSendRentReminders", undefined);
  };

  const didChangePaymentReceivedNotifyUsersByEmail = (nextValue) => {
    if (!nextValue) {
      formikRef.setFieldValue("sendEmailToUsersWhenOnlinePaymentIsReceivedUsers", undefined);
    }

    setTimeout(() => {
      setShowNotifyUsersByEmail(nextValue);
    }, 300);
  };
  const didChangePaymentReversedNotifyUsersByEmail = (nextValue) => {
    if (!nextValue) {
      formikRef.setFieldValue("sendEmailToUsersWhenPaymentIsReturnedUsers", undefined);
    }

    setTimeout(() => {
      setShowNotifyUsersByEmailReversedPayment(nextValue);
    }, 300);
  };

  const renderTextMessageNotifications = () => (
    <View marginTop={20} flexDirection={"row"}>
      <Grid item container alignItems={"flex-start"} xs={12} md={12} lg={12}>
        <Text fontSize={16} fontWeight={700} value={AppStrings.Common.TextMessages} />

        <View noWrap>
          <FastField
            component={FormikSwitchButton}
            name={"doNotSentNotificationsAsTextMessages"}
            label={AppStrings.Common.DoNotSentNotificationsAsTextMessagesRentReminders}
            marginTop={16}
            flipValue
          />
          {isFeatureFlagActive &&
            (isCashPaymentEnabled ? (
              <FastField
                component={FormikSwitchButton}
                name={"sendCashPaymentDetailsUponMoveIn"}
                label={AppStrings.Common.SendCashPaymentDetailsUponMoveIn}
                marginTop={16}
              />
            ) : (
              <PopoverHover
                tooltipProps={{
                  value: AppStrings.Common.CashPaymentMethodNotSelected,
                  containerStyle: {
                    marginTop: 16
                  }
                }}
              >
                <SwitchButton disabled checked={false} label={AppStrings.Common.SendCashPaymentDetailsUponMoveIn} />
              </PopoverHover>
            ))}
        </View>
      </Grid>
    </View>
  );

  const renderPaymentReceivedNotifications = () => (
    <View marginTop={20} flexDirection={"row"}>
      <Grid item container xs={12} md={12} lg={12}>
        <Text fontSize={16} fontWeight={700} value={AppStrings.Common.PaymentReceivedNotifications} />
        <View flexDirection={"row"}>
          <FastField
            component={FormikSwitchButton}
            name={"sendPaymentReceiptsWhenOnlinePaymentIsReceived"}
            label={AppStrings.Common.PaymentReceivedNotifyByEmail}
            marginTop={16}
          />
          <SwitchButton
            onChange={didChangePaymentReceivedNotifyUsersByEmail}
            label={AppStrings.Common.PaymentReceivedNotifyUsersByEmail}
            marginTop={16}
            checked={showNotifyUsersByEmail}
            fullWidth
          />
        </View>
        <View showAnimation={"fade-in"} hideAnimation={"fade-out"} shouldShow={showNotifyUsersByEmail}>
          <FormikCachedMultiSelectAutoComplete
            uniqueIndex={"notifyUsersByEmail"}
            apiHandler={usersApi}
            displayNameKey={"name"}
            filterFieldName={"filter_text"}
            filterFieldValue={"name"}
            selectionFields={["id", "class"]}
            queryParams={{ filter_status: UserStatus.ACTIVE }}
            name={"sendEmailToUsersWhenOnlinePaymentIsReceivedUsers"}
            label={t(AppStrings.Common.SelectUsers)}
            marginTop={20}
            errorLabelPaddingLeft={15}
          />
        </View>
      </Grid>
    </View>
  );

  const renderRentRemindersNotifications = () => {
    const showDaysBeforeToSendRentReminders = Boolean(formikRef?.values?.sendRentReminders);

    return (
      <View marginTop={20} flexDirection={"row"}>
        <Grid item container alignItems={"center"} xs={12} md={12} lg={12}>
          <Text fontSize={16} fontWeight={700} value={AppStrings.Common.RentReminders} />
          <View>
            <FastField
              component={FormikSwitchButton}
              name={"sendRentReminders"}
              label={AppStrings.Common.RentChargeNotificationsDescription}
              onChange={didChangeRentNotification}
              marginTop={16}
            />
            <View showAnimation={"fade-in"} hideAnimation={"fade-out"} shouldShow={showDaysBeforeToSendRentReminders}>
              <Grid item xs={12} md={5} lg={5}>
                <FastField
                  component={TextField}
                  label={t(AppStrings.Common.DaysBeforeRentIsDue)}
                  name={"daysBeforeToSendRentReminders"}
                  marginTop={20}
                  formatType={"number"}
                  decimalScale={1}
                />
              </Grid>
              <FastField
                component={FormikSwitchButton}
                name={"doNotSendRentRemindersIfTenantPrepaid"}
                label={AppStrings.Common.RentChargeDoNotSendReminderIfTenantAlreadyPaid}
                marginTop={16}
              />
            </View>
          </View>
        </Grid>
      </View>
    );
  };

  const renderPaymentReversedNotifications = () => (
    <View marginTop={20} flexDirection={"row"}>
      <Grid item container alignItems={"flex-start"} xs={12} md={12} lg={12}>
        <Text fontSize={16} fontWeight={700} value={AppStrings.Common.PaymentReversedNotifications} />

        <View>
          <FastField
            component={FormikSwitchButton}
            name={"sendEmailToTenantWhenPaymentIsReturned"}
            label={AppStrings.Common.PaymentReversedNotifyByEmail}
            marginTop={16}
          />
          <SwitchButton
            onChange={didChangePaymentReversedNotifyUsersByEmail}
            label={AppStrings.Common.PaymentReversedNotifyUsersByEmail}
            marginTop={16}
            checked={showNotifyUsersByEmailReversedPayment}
            fullWidth
          />
          <View showAnimation={"fade-in"} hideAnimation={"fade-out"} shouldShow={showNotifyUsersByEmailReversedPayment}>
            <FormikCachedMultiSelectAutoComplete
              uniqueIndex={"notifyUsersByEmail"}
              apiHandler={usersApi}
              displayNameKey={"name"}
              filterFieldName={"filter_text"}
              filterFieldValue={"name"}
              selectionFields={["id", "class"]}
              name={"sendEmailToUsersWhenPaymentIsReturnedUsers"}
              label={t(AppStrings.Common.SelectUsers)}
              queryParams={{ filter_status: UserStatus.ACTIVE }}
              marginTop={20}
              errorLabelPaddingLeft={15}
            />
          </View>
        </View>
      </Grid>
    </View>
  );

  const renderRentOverdueNotifications = () => (
    <View
      marginBottom={formikRef?.values.sendRecurringNotificationsForOverdueRent ? -10 : 0}
      marginTop={20}
      flexDirection={"row"}
    >
      <Grid item container alignItems={"center"} xs={12} md={12} lg={12}>
        <Text fontSize={16} fontWeight={700} value={AppStrings.Common.RentOverdue} />
        <View>
          <FastField
            component={FormikSwitchButton}
            name={"sendRecurringNotificationsForOverdueRent"}
            label={AppStrings.Common.RentOverdueSettingTitle}
            marginTop={16}
          />
        </View>
        <View
          showAnimation={"fade-in"}
          hideAnimation={"fade-out"}
          shouldShow={Boolean(formikRef?.values.sendRecurringNotificationsForOverdueRent)}
        >
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} sm={6}>
              <Text value={AppStrings.Common.WhenShouldWeSendThisNotification} marginTop={20} fontSize={14} />
              <FastFieldSafe
                component={TextField}
                label={t(AppStrings.Common.DaysAfterRentIsDue)}
                name={"daysAfterDueDateToSendRecurringNotifications"}
                marginTop={20}
                formatType={"number"}
                decimalScale={1}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text
                value={AppStrings.Common.HowOftenShouldWeSendReminders}
                fontSize={14}
                marginBottom={20}
                marginTop={20}
              />
              <FastFieldSafe
                uniqueKey={"recurringNotificationsForOverdueRentFrequency"}
                translationKey={"rentReminderOverdueNotificationsFrequency"}
                component={Select}
                name={"recurringNotificationsForOverdueRentFrequency"}
                label={AppStrings.Leases.NewLease.LeaseRent.Frequency}
                selectionEnum={RentReminderOverdueNotificationsFrequency}
                required
              />
            </Grid>
          </Grid>
        </View>
      </Grid>
    </View>
  );

  const renderForm = () => (
    <FormikContext.Provider value={formikRef}>
      <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding}>
        <View alignItems={"flex-start"}>
          <SeparationLine marginTop={20} width={"100%"} height={1} />
          {renderRentRemindersNotifications()}
          <SeparationLine marginTop={20} width={"100%"} height={1} />
          {renderRentOverdueNotifications()}
          <SeparationLine marginTop={20} width={"100%"} height={1} />
          <RentChargeNotifications
            showDaysBeforeDueDateToPostRecurringCharges={showDaysBeforeDueDateToPostRecurringCharges}
            setShowDaysBeforeDueDateToPostRecurringCharges={setShowDaysBeforeDueDateToPostRecurringCharges}
            isCashPaymentEnabled={Boolean(isCashPaymentEnabled)}
            settingsLevel={SettingsLevels.Company}
          />
          <SeparationLine marginTop={20} width={"100%"} height={1} />
          {renderPaymentReceivedNotifications()}
          <SeparationLine marginTop={20} width={"100%"} height={1} />
          {renderPaymentReversedNotifications()}
          <SeparationLine marginTop={20} width={"100%"} height={1} />
          {renderTextMessageNotifications()}
          <View height={20} />
        </View>
      </View>
    </FormikContext.Provider>
  );

  const renderView = ({ index }: any) => {
    if (index === 0 && rentRemindersSettingsData) {
      return renderForm();
    }
    if (index === 1 || !rentRemindersSettingsData) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={t(AppStrings.Common.Loading)}
            errorText={loadingDialogErrorText}
            successText={t(AppStrings.Common.OperationCompleted)}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
            hideButtons={hideLoadingDialogActionButtons}
          />
        </View>
      );
    }
    return <div />;
  };

  const _onBackdropClick = () => {
    if (viewIndex === 1) {
      setViewIndex(0);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const frameType = useMemo(() => {
    if (rentRemindersSettingsData === undefined) {
      return "contentOnly";
    }

    if (viewIndex === 0) {
      return "sectionTitleFrame";
    }
    if (viewIndex === 1) {
      return "contentOnly";
    }

    return "topPanel";
  }, [viewIndex, rentRemindersSettingsData]);

  return (
    <SettingsReadOnlyScope permission={SettingPermission.rentReminders}>
      <DialogFrame
        onCloseButtonClick={_onBackdropClick}
        title={dialogTitle}
        width={750}
        height={dialogFrameHeight}
        renderView={renderView}
        numViews={2}
        activeView={viewIndex}
        RenderActionPanelButtons={renderActionPanelButtons}
        frameType={frameType}
      />
    </SettingsReadOnlyScope>
  );
};

export default EditRentReminders;
