import React from "react";
import type { StepPropsWithDto } from "screens/onBoarding/types";
import { OnBoardingScheduleIcon } from "assets/.";
import AppStrings from "locale/keys";
import Text from "DLUI/text";
import StepSection from "screens/onBoarding/components/stepSection";
import { Icon } from "DLUI/icon";
import { StepImageFileNames } from "screens/onBoarding/imageFileNames";
import ActionButton from "screens/onBoarding/components/actionButton";
import { View } from "DLUI/view";
import { Grid } from "@material-ui/core";
import { onBoardingApi } from "api/onBoardingApi";
import Image from "DLUI/image/image";
import { useTranslation } from "react-i18next";

type Props = StepPropsWithDto;

const ScheduleOnBoardingStep: React.FC<Props> = ({
  onInvalidate,
  isCollapsed,
  onHeaderClick,
  name,
  onBoardingDto,
  isCompleted,
  isSkipped
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = React.useState(false);

  const icon = OnBoardingScheduleIcon;
  const title = AppStrings.OnBoarding.StepTitles.ScheduleAnOnBoardingSession;
  const description = AppStrings.OnBoarding.StepDescriptions.ScheduleAnOnBoardingSession;

  const handleMarkAsComplete = async () => {
    setIsLoading(true);
    const response = await onBoardingApi.updateOnBoardingItem(name, { isCompleted: !isCompleted });

    const hasError = response.statusCode !== 200 ? response.message ?? true : undefined;
    await onInvalidate({ error: hasError });
    setIsLoading(false);
  };

  const handleScheduleACall = async () => {
    /**
     * This function is not implemented on purpose.
     * The Intercom will catch the click (by button ID) and open the chat window.
     */
  };

  const LeftSideContent = () => (
    <View gap={15}>
      <Text whiteSpace={"pre-line"} value={description} />
      <Grid container direction={"row"} alignItems={"center"} spacing={2}>
        <Grid item xs={12} sm={"auto"} md={"auto"}>
          <ActionButton
            onClick={handleScheduleACall}
            actionText={AppStrings.OnBoarding.ActionButtons.ScheduleACall}
            fullWidth
            id={"INTERCOM_SCHEDULE_ONBOARDING_STEP_BUTTON"}
            width={"100%"}
          />
        </Grid>
        <Grid item xs={12} sm={"auto"} md={"auto"}>
          <ActionButton
            onClick={handleMarkAsComplete}
            faded
            requestInProgress={isLoading}
            actionText={isCompleted ? AppStrings.OnBoarding.MarkAsIncomplete : AppStrings.OnBoarding.MarkAsCompleted}
            fullWidth
            width={"100%"}
          />
        </Grid>
      </Grid>
    </View>
  );

  return (
    <StepSection
      icon={<Icon size={24} Source={icon} />}
      title={title}
      leftSideContent={<LeftSideContent />}
      isCompleted={isCompleted}
      isSkipped={isSkipped}
      onHeaderClick={onHeaderClick}
      isCollapsed={isCollapsed}
      mediaContent={
        <Image style={{ borderRadius: 5, maxWidth: "100%" }} alt={t(title)} src={StepImageFileNames.SCHEDULE_SESSION} />
      }
      isLoading={isLoading}
    />
  );
};

export default ScheduleOnBoardingStep;
