import { useLocation, useParams } from "react-router-dom";

export const useConversationParams = () => {
  const locationState = useLocation<{ conversationId?: string }>();
  const params = useParams<{ conversationId?: string }>();
  const conversationId = locationState.state?.conversationId || params.conversationId;
  const isNewConversation = conversationId === "new";

  return { isNewConversation, conversationId };
};
