import React, { useEffect, useMemo, useState } from "react";
import { ObjectPermission, TagDto, TagType } from "@doorloop/dto";
import { tagsApi } from "api/tagsApi";
import { AddIcon } from "assets/icons";
import { Button } from "DLUI/button";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { DialogRoutes } from "DLUI/screen/dialogsProvider";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import TagListItem from "./tagListItem";
import { DialogsHelper } from "../dialogsHelper";
import { usePermission } from "screens/settings/userRoles/usePermission";

interface ComponentProps {
  onClose: () => void;
  onBackdropClick: () => void;
  dialogTitle?: string;
}

const EditTags: React.FC<ComponentProps> = ({ onBackdropClick, dialogTitle }: ComponentProps) => {
  const { hasPermission } = usePermission();
  const { t } = useTranslation();
  const location = useLocation<any>();
  const { dialogHorizontalPadding } = DialogsHelper();
  const [viewIndex, setViewIndex] = useState(0);
  const [tagsData, setTagsData] = useState<TagDto[] | undefined>();
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState<string>("");
  const [loadingDialogSuccessText, setLoadingDialogSuccessText] = useState<string>(
    t(AppStrings.Settings.GeneralSettings.RegionAndCurrency.LoadingRegionAndCurrencyDataSuccessText)
  );

  const { tagType, dialogDescriptionText, loadingText, loadingSuccessText } = useMemo(() => {
    if (location.pathname.indexOf(DialogRoutes.EDIT_TASK_CATEGORIES) > -1) {
      return {
        tagType: TagType.TASKS_TAG,
        dialogDescriptionText: t(AppStrings.Settings.GeneralSettings.TaskCategories.DialogDescription),
        loadingText: t(AppStrings.Settings.GeneralSettings.TaskCategories.LoadingTaskCategories),
        loadingSuccessText: t(AppStrings.Settings.GeneralSettings.TaskCategories.LoadingTaskCategoriesDataSuccessText)
      };
    }
    if (location.pathname.indexOf(DialogRoutes.EDIT_NOTE_CATEGORIES) > -1) {
      return {
        tagType: TagType.NOTES_TAG,
        dialogDescriptionText: t(AppStrings.Settings.GeneralSettings.NoteCategories.DialogDescription),
        loadingText: t(AppStrings.Settings.GeneralSettings.NoteCategories.LoadingNoteCategories),
        loadingSuccessText: t(AppStrings.Settings.GeneralSettings.NoteCategories.LoadingNoteCategoriesDataSuccessText)
      };
    }
    if (location.pathname.indexOf(DialogRoutes.EDIT_FILE_CATEGORIES) > -1) {
      return {
        tagType: TagType.FILES_TAG,
        dialogDescriptionText: t(AppStrings.Settings.GeneralSettings.FileCategories.DialogDescription),
        loadingText: t(AppStrings.Settings.GeneralSettings.FileCategories.LoadingFileCategories),
        loadingSuccessText: t(AppStrings.Settings.GeneralSettings.FileCategories.LoadingFileCategoriesDataSuccessText)
      };
    }
    if (location.pathname.indexOf(DialogRoutes.EDIT_VENDOR_CATEGORIES) > -1) {
      return {
        tagType: TagType.VENDOR_SERVICE,
        dialogDescriptionText: t(AppStrings.Settings.GeneralSettings.VendorClassifications.DialogDescription),
        loadingText: t(AppStrings.Settings.GeneralSettings.VendorClassifications.LoadingVendorClassifications),
        loadingSuccessText: t(AppStrings.Settings.GeneralSettings.VendorClassifications.LoadingVendorClassifications)
      };
    }

    if (location.pathname.indexOf(DialogRoutes.EDIT_LEAD_SOURCE) > -1) {
      return {
        tagType: TagType.LEAD_SOURCE,
        dialogDescriptionText: t(AppStrings.Settings.GeneralSettings.LeadSource.DialogDescription),
        loadingText: t(AppStrings.Settings.GeneralSettings.LeadSource.LoadingLeadSources),
        loadingSuccessText: t(AppStrings.Settings.GeneralSettings.LeadSource.LoadingVendorCategoriesDataSuccessText)
      };
    }
    return {
      tagType: TagType.TASKS_TAG,
      dialogDescriptionText: "",
      loadingText: "",
      loadingSuccessText: ""
    };
  }, []);

  useEffect(() => {
    loadTagsData();
  }, []);

  const showErrorMessage = (message: string) => {
    setLoadingDialogErrorText(message);
    setLoadingDialogState(DialogState.Error);
  };

  const loadTagsData = async () => {
    setLoadingDialogLoadingText(loadingText);
    setLoadingDialogState(DialogState.Show);
    const response = await tagsApi.getAll({ filter_type: tagType }).catch((e) => {
      showErrorMessage(e);
    });
    if (response && response.data) {
      setLoadingDialogSuccessText(loadingSuccessText);
      setLoadingDialogState(DialogState.Success);
      const tagsData = response.data.data as TagDto[];
      if (tagsData && tagsData.length === 0 && hasPermission(ObjectPermission.tags, "create")) {
        const tagItem = new TagDto();
        tagItem.type = tagType;
        tagsData.push(tagItem);
      }
      setTagsData(tagsData);
      setLoadingDialogState(DialogState.Hidden);
      return;
    }
    if (response) {
      showErrorMessage(response.message);
    }

    showErrorMessage(t(AppStrings.Common.NetworkErrorSubTitle));
  };

  const didPressDismissButton = () => {
    onBackdropClick();
  };

  const renderView = ({ index }: any) => {
    if (index === 0) {
      if (loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={loadTagsData}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }
      return renderForm();
    }

    return <div />;
  };

  const renderTagsList = () =>
    tagsData
      ? tagsData.map((currentTagItem: TagDto, index: number) => (
          <TagListItem
            key={index}
            tagType={tagType}
            id={currentTagItem.id}
            name={currentTagItem.name}
            onDeleteEvent={didDeleteTagItem}
          />
        ))
      : null;

  const didDeleteTagItem = (id?: string) => {
    if (tagsData && tagsData.length > 0) {
      const nextTags: TagDto[] = [...tagsData];
      let deleteTagIndex = -1;
      if (id) {
        deleteTagIndex = tagsData.findIndex((x) => x.id === id);
      }
      if (deleteTagIndex === -1) {
        deleteTagIndex = tagsData.length - 1;
      }

      nextTags.splice(deleteTagIndex, 1);
      setTagsData(nextTags);
    }
  };

  const didPressAddTag = () => {
    const newTag = new TagDto();
    newTag.type = tagType;

    let nextTags: TagDto[] = [];
    if (tagsData) {
      nextTags = [...tagsData];
    }
    nextTags.push(newTag);
    setTagsData(nextTags);
  };

  const renderForm = () => (
    <View marginTop={10} width={"100%"} flex={1} overflow={"hidden"}>
      <View paddingRight={dialogHorizontalPadding} paddingLeft={dialogHorizontalPadding}>
        <Text fontSize={16}>{dialogDescriptionText}</Text>

        {renderTagsList()}
        <Button
          color={"lightBlue"}
          type={"inlineText"}
          actionText={AppStrings.Leases.LeaseCharge.AddLineItem}
          onClick={didPressAddTag}
          marginLeft={10}
          LeftIcon={AddIcon}
          iconSize={15}
          applyColorForIcons
          marginTop={20}
          clearance={{
            permission: ObjectPermission.tags,
            field: "create"
          }}
        />
        <View height={20} />
      </View>
    </View>
  );

  const _onBackdropClick = () => {
    if (viewIndex > 0) {
      setViewIndex(viewIndex - 1);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const currentTitle = useMemo(() => {
    if (viewIndex === 0) {
      return dialogTitle;
    }

    if (viewIndex === 2) {
      return AppStrings.Vendors.Screen.AddNewVendor;
    }

    if (viewIndex === 1) {
      return AppStrings.Vendors.VendorDetails.SelectVendor;
    }
    return "";
  }, [viewIndex]);

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      if (loadingDialogState !== DialogState.Hidden) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }
    if (viewIndex === 1) {
      return "contentOnly";
    }
    return "sectionTitleFrame";
  }, [viewIndex, loadingDialogState]);

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={currentTitle}
      width={760}
      height={560}
      renderView={renderView}
      numViews={2}
      activeView={viewIndex}
      frameType={frameType}
      keepViewsMounted={false}
    />
  );
};

export default EditTags;
