"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetSearchResultsQuery = exports.SearchCollections = void 0;
const class_validator_1 = require("class-validator");
var SearchCollections;
(function (SearchCollections) {
    SearchCollections["PROPERTIES"] = "PROPERTIES";
    SearchCollections["UNITS"] = "UNITS";
    SearchCollections["LEASES"] = "LEASES";
    SearchCollections["DRAFT_LEASES"] = "DRAFT_LEASES";
    SearchCollections["LEASE_TENANTS"] = "LEASE_TENANTS";
    SearchCollections["OWNERS"] = "OWNERS";
    SearchCollections["VENDORS"] = "VENDORS";
    SearchCollections["PROSPECT_TENANTS"] = "PROSPECT_TENANTS";
    SearchCollections["TASKS"] = "TASKS";
    SearchCollections["FILES"] = "FILES";
    SearchCollections["NOTES"] = "NOTES";
})(SearchCollections || (exports.SearchCollections = SearchCollections = {}));
class GetSearchResultsQuery {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.GetSearchResultsQuery = GetSearchResultsQuery;
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.MinLength)(1),
    __metadata("design:type", String)
], GetSearchResultsQuery.prototype, "filter_text", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsEnum)(SearchCollections, { each: true }),
    __metadata("design:type", Array)
], GetSearchResultsQuery.prototype, "filter_collections", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetSearchResultsQuery.prototype, "filter_relatedToPropertiesAndUnits", void 0);
