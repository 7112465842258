import React, { Fragment, useMemo } from "react";
import type { UnitDto } from "@doorloop/dto";
import { ObjectPermission } from "@doorloop/dto";
import {
  BathsIcon,
  BedsIcon,
  CloseIcon,
  EditBlue,
  LocationIcon,
  NotRenewingLeaseIcon,
  RenewLeaseIcon,
  SqftIcon,
  UnitIcon,
  ViewBlueIcon
} from "assets/icons";
import { Icon } from "DLUI/icon";
import { Grid, ListItemContainer, ListItemIcon, ListItemSection } from "DLUI/listItems";
import type { PopoverItem } from "DLUI/popover";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { history } from "store/history";
import { usePermission } from "screens/settings/userRoles/usePermission";
import { Hidden } from "@material-ui/core";
import ListItemText from "DLUI/listItems/listItemText";
import UnitListItemLabel from "screens/units/unitsList/unitListItemLabel";
import { getPropertyAndUnitValue, getStreetLocation } from "./utils";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { unitsApi } from "api/unitsApi";
import { UnitRentalAppListingInfoDto } from "@doorloop/dto";
import { handleToast } from "store/toast/actions";
import { useDispatch } from "react-redux";
import { useConfirmationDialog } from "@/hooks/useConfirmationDialog";

interface ComponentProps {
  unitDto: UnitDto;
  onItemPress: (unitId: string) => void;
  disableListItemOptions?: boolean;
}

const UnitsApiUnitListItem: React.FC<ComponentProps> = ({
  unitDto,
  onItemPress,
  disableListItemOptions
}: ComponentProps) => {
  const { isMobile } = useResponsiveHelper();
  const { t } = useTranslation();
  const location = useLocation<any>();
  const { hasPermission } = usePermission();
  const hasViewOnePermission = hasPermission(ObjectPermission.units, "viewOne");
  const dispatch = useDispatch();
  const { showConfirmationDialog } = useConfirmationDialog();

  const didPressUnitItem = hasViewOnePermission
    ? () => {
        onItemPress(unitDto.id!);
      }
    : undefined;

  const didPressEditButton = () => {
    history.push(location.pathname + "/edit/" + unitDto.id!);
  };

  const streetLocation = useMemo(() => getStreetLocation(unitDto.address), []);

  const propertyAndUnitValue = useMemo(() => getPropertyAndUnitValue(unitDto.property!, unitDto.name), []);

  const didPressDeleteUnit = () => {
    if (unitDto.id) {
      showConfirmationDialog(location.pathname, {
        dialogTitle: AppStrings.Common.DeleteConfirmation,
        dialogSubTitle: AppStrings.Units.Screen.DeleteConfirmationText,
        loadingText: t(AppStrings.Units.Screen.DeleteUnitLoadingText),
        successText: t(AppStrings.Units.Screen.DeleteUnitSuccessText),
        confirmButtonText: AppStrings.Common.Delete,
        dismissButtonText: AppStrings.Common.Dismiss,
        apiMethod: "unitsApi",
        confirmOperation: "delete",
        itemId: unitDto.id
      });
    }
  };

  const didPressMakeInactive = () => {
    showConfirmationDialog(location.pathname, {
      dialogTitle: AppStrings.Common.InactiveConfirmation,
      dialogSubTitle: AppStrings.Units.Screen.MakeInactiveFileConfirmationText,
      loadingText: t(AppStrings.Common.OperationInprogress),
      successText: t(AppStrings.Common.OperationSucceeded),
      confirmButtonText: AppStrings.Accounts.ChartOfAccounts.MakeInactive,
      dismissButtonText: AppStrings.Common.Dismiss,
      apiMethod: "unitsApi",
      confirmOperation: "makeInactive",
      itemId: unitDto.id,
      itemDto: unitDto
    });
  };

  const didPressMakeActive = () => {
    showConfirmationDialog(location.pathname, {
      dialogTitle: AppStrings.Common.ActiveConfirmation,
      dialogSubTitle: AppStrings.Units.Screen.MakeActiveFileConfirmationText,
      loadingText: t(AppStrings.Common.OperationInprogress),
      successText: t(AppStrings.Common.OperationSucceeded),
      confirmButtonText: AppStrings.Common.Activate,
      dismissButtonText: AppStrings.Common.Dismiss,
      apiMethod: "unitsApi",
      confirmOperation: "makeActive",
      itemId: unitDto.id,
      itemDto: unitDto
    });
  };

  const handleOpenForApplicationsError = () => {
    dispatch(
      handleToast({
        severity: "error",
        translationKey: AppStrings.Common.RentalApplicationCouldHandleOpenForApplicationError
      })
    );
  };

  const handleOpenForApplications = async (value: boolean) => {
    if (unitDto.id) {
      const response = await unitsApi
        .update(unitDto.id, {
          ...unitDto,
          rentalApplicationListing: new UnitRentalAppListingInfoDto({ activeListing: value })
        })
        .catch((e) => {
          handleOpenForApplicationsError();
        });

      if (!response?.status) {
        handleOpenForApplicationsError();
      }
    } else {
      handleOpenForApplicationsError();
    }
  };

  const popoverTopSectionItems: PopoverItem[] = useMemo(() => {
    const popOverItems: PopoverItem[] = [
      {
        Icon: ViewBlueIcon,
        title: AppStrings.Common.View,
        onClick: didPressUnitItem,
        showSeparator: true,
        clearance: { permission: ObjectPermission.units, field: "viewOne" }
      },
      {
        Icon: EditBlue,
        title: AppStrings.Common.Edit,
        onClick: didPressEditButton,
        showSeparator: true,
        clearance: { permission: ObjectPermission.units, field: "edit" }
      }
    ];

    if (unitDto.active) {
      popOverItems.push({
        Icon: NotRenewingLeaseIcon,
        title: AppStrings.Accounts.ChartOfAccounts.MakeInactive,
        onClick: didPressMakeInactive,
        iconPathColor: "error",
        textColor: "error",
        showSeparator: true,
        clearance: { permission: ObjectPermission.units, field: "edit" }
      });
    } else {
      popOverItems.push({
        Icon: RenewLeaseIcon,
        title: AppStrings.Common.MakeActive,
        onClick: didPressMakeActive,
        showSeparator: true,
        clearance: { permission: ObjectPermission.units, field: "edit" }
      });
    }
    if (unitDto.rentalApplicationListing?.activeListing) {
      popOverItems.push({
        Icon: NotRenewingLeaseIcon,
        title: AppStrings.Common.OpenUnitForRentalApplication,
        onClick: async () => await handleOpenForApplications(true),
        iconPathColor: "error",
        textColor: "error",
        showSeparator: true,
        clearance: { permission: ObjectPermission.units, field: "edit" }
      });
    } else {
      popOverItems.push({
        Icon: RenewLeaseIcon,
        title: AppStrings.Common.CloseUnitForRentalApplication,
        onClick: async () => await handleOpenForApplications(false),
        showSeparator: true,
        clearance: { permission: ObjectPermission.units, field: "edit" }
      });
    }
    popOverItems.push({
      Icon: CloseIcon,
      title: AppStrings.Common.Delete,
      onClick: didPressDeleteUnit,
      iconPathColor: "error",
      textColor: "error",
      clearance: { permission: ObjectPermission.units, field: "delete" }
    });

    return popOverItems;
  }, []);

  return (
    <ListItemContainer
      backgroundColor={unitDto.active ? "light" : "dark"}
      popoverItems={disableListItemOptions ? undefined : popoverTopSectionItems}
      removeVerticalPadding
    >
      <Grid
        title={AppStrings.Units.ListHeader.Unit}
        showDivider
        xs={12}
        md={5}
        lg={4}
        onClick={didPressUnitItem}
        sortColumn={"name"}
      >
        <View alignItems={"center"} flexDirection={"row"}>
          <ListItemIcon Icon={UnitIcon} />
          <ListItemSection
            renderTitle={
              <ListItemText color={"black"} align="left" fontWeight={700} overflow={"ellipsis"} numberOfLines={1}>
                {propertyAndUnitValue}
              </ListItemText>
            }
            renderSubTitle={
              <View justifyContent={"flex-start"} alignItems={"flex-start"} flexDirection={"row"}>
                <Icon Source={LocationIcon} width={16} height={16} marginRight={5} />
                <View flex={1}>
                  <ListItemText numberOfLines={1} overflow={"ellipsis"} fontWeight={500} color={"gray"}>
                    {streetLocation}
                  </ListItemText>
                </View>
              </View>
            }
          />
        </View>
      </Grid>
      <Grid
        title={AppStrings.Units.ListHeader.Features}
        showDivider={!isMobile}
        xs={12}
        md={4}
        lg={4}
        onClick={didPressUnitItem}
      >
        <View flexDirection={"row"} alignItems={"center"} flex={1}>
          <Icon Source={BedsIcon} width={20} height={20} pathColor={"black"} />
          <UnitListItemLabel
            label={unitDto.beds && unitDto.beds == 1 ? AppStrings.Units.ListItem.Bed : AppStrings.Units.ListItem.Beds}
            labelValue={unitDto.beds?.toString()}
          />
        </View>
        <View flexDirection={"row"} alignItems={"center"} flex={1}>
          <Icon Source={BathsIcon} width={20} height={20} pathColor={"black"} />
          <UnitListItemLabel
            label={
              unitDto.baths && unitDto.baths == 1 ? AppStrings.Units.ListItem.Bath : AppStrings.Units.ListItem.Baths
            }
            labelValue={unitDto.baths?.toString()}
          />
        </View>
        <View flexDirection={"row"} alignItems={"center"} flex={1}>
          <Icon Source={SqftIcon} width={20} height={20} pathColor={"black"} />
          <UnitListItemLabel label={AppStrings.Units.ListItem.Sqft} labelValue={unitDto.size?.toString()} />
        </View>
      </Grid>
      <Hidden smDown>
        <Grid xs={12} md={2} lg={2} onClick={didPressUnitItem}>
          <Fragment />
        </Grid>
      </Hidden>
    </ListItemContainer>
  );
};

export default UnitsApiUnitListItem;
