import React from "react";
import type { DialogState } from "DLUI/dialogs/loadingDialog";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { initFormValues, validateForm, validateTenantPortalForm } from "./formikHelper";
import { TenantPortalAnimatedContentDialog } from "./tenantPortalAnimatedContentDialog";
import { getLeaseResidentPortalFormMenuItems } from "screens/settings/tenantPortal/leaseMenuItems";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";

interface ComponentProps {
  dialogState: DialogState;
  onClose: () => void;
  onBackdropClick: () => void;
  title?: string;
}

const TenantPortalLeaseDialog: React.FC<ComponentProps> = ({
  dialogState,
  onClose,
  onBackdropClick,
  title
}: ComponentProps) => {
  const { t } = useTranslation();
  const residentPortalFormMenuItems = getLeaseResidentPortalFormMenuItems();
  const { leaseId } = useParams<any>();
  const dialogHeight = getDialogFrameDimension("height", 920);

  return (
    <TenantPortalAnimatedContentDialog
      dialogState={dialogState}
      onClose={onClose}
      sectionItems={residentPortalFormMenuItems}
      disableBackdropClick
      onBackdropClick={onBackdropClick}
      getformikInitialValues={initFormValues}
      formikValidation={validateForm}
      validationMethod={validateTenantPortalForm}
      loadingText={t(AppStrings.Settings.GeneralSettings.CompanyInfo.LoadingCompanyInformation)}
      errorText={t(AppStrings.Settings.GeneralSettings.CompanyInfo.FailedToLoadCompanyInformation)}
      successText={t(AppStrings.Settings.GeneralSettings.CompanyInfo.UpdatingCompanyInformationSuccessText)}
      dialogTitle={title || AppStrings.Common.TenantPortalSettingsSections.TenantPortalSettings}
      dialogHeight={dialogHeight}
      leaseId={leaseId}
    />
  );
};

export default TenantPortalLeaseDialog;
