import { accountsApi } from "api/accounts";
import { CalendarIconBlue, ChargeGrayIcon, CreaditGrayIcon } from "assets/icons";
import { TextField } from "DLUI/form";
import { Grid, ListItemContainer, ListItemIcon, ListItemSection } from "DLUI/listItems";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, getIn } from "formik";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { NavigationManager } from "utils/navigation";
import ListItemText from "DLUI/listItems/listItemText";
import AppStrings from "../../../../locale/keys";

interface ComponentProps {
  type: "credit" | "charge";
  id: string;
  accountId: string;
  transactionDate: string;
  name: string;
  formikRef: FormikProps<any>;
  transactionId: string;
  leaseId: string;
  reference?: string;
}

const OutstandingTransactionsListItem: React.FC<ComponentProps> = ({
  type,
  id,
  accountId,
  transactionDate,
  name,
  formikRef,
  transactionId,
  leaseId,
  reference
}: ComponentProps) => {
  const { t } = useTranslation();
  const location = useLocation<any>();

  const didPressEditItem = () => {
    if (type === "charge") {
      NavigationManager.editCharge(location.pathname, transactionId, leaseId, true);
    }
    NavigationManager.editCredit(location.pathname, transactionId, leaseId, true);
  };

  const balance = getIn(formikRef.values, name + ".balance");

  const _ListItemIcon = useMemo(() => {
    if (type === "charge") {
      return ChargeGrayIcon;
    }
    return CreaditGrayIcon;
  }, []);

  const accountName = useMemo(() => {
    const account = accountsApi.getItemFromDictionary(accountId);
    if (account) {
      return account.name;
    }
    return "";
  }, []);

  return (
    <ListItemContainer backgroundColor={"dark"} removeVerticalPadding id={id}>
      <Grid
        title={
          type === "charge"
            ? AppStrings.Leases.NewLease.LeaseRent.Charges
            : AppStrings.Leases.NewLease.LeaseRent.Credits
        }
        showDivider
        xs={12}
        md={5}
        lg={5}
        onClick={didPressEditItem}
      >
        <View alignItems={"center"} flexDirection={"row"}>
          <ListItemIcon size={30} Icon={_ListItemIcon} />
          <ListItemSection
            renderTitle={
              <ListItemText color={"black"} align="left" overflow={"ellipsis"} numberOfLines={2} fontWeight={700}>
                {accountName}
              </ListItemText>
            }
            renderSubTitle={
              <View justifyContent={"flex-start"} alignItems={"center"} flexDirection={"row"}>
                <Text
                  color={"gray"}
                  fontSize={12}
                  value={
                    type === "charge"
                      ? AppStrings.Leases.NewLease.LeaseRent.Charge
                      : AppStrings.Leases.NewLease.LeaseRent.Credit
                  }
                />
                <Text marginLeft={5} color={"gray"} fontSize={12} textTransform={"uppercase"}>
                  {" #"}
                  {reference}
                </Text>
              </View>
            }
          />
        </View>
      </Grid>
      <Grid title={AppStrings.Common.ListHeader.Date} showDivider xs={12} md={2} lg={2} onClick={didPressEditItem}>
        <View flexDirection={"row"} justifyContent={"center"} alignItems={"flex-start"}>
          <CalendarIconBlue />
          <Text fullWidth color={"black"} fontSize={14} formatType={"date"} value={transactionDate} marginLeft={10} />
        </View>
      </Grid>
      <Grid title={AppStrings.Leases.ListHeader.Balance} showDivider xs={12} md={2} lg={2} onClick={didPressEditItem}>
        <View paddingRight={10} alignItems={"flex-end"}>
          <Text color={"black"} fontSize={14} value={String(balance)} formatType={"currency"} />
        </View>
      </Grid>
      <Grid title={AppStrings.Leases.LeaseTransaction.Payment.PaymentAmount} xs={12} md={3} lg={3}>
        <View paddingLeft={20} paddingRight={20}>
          <FastField
            component={TextField}
            name={name + ".amount"}
            label={t(AppStrings.Leases.LeaseTransaction.Payment.PaymentAmount)}
            formatType={"currency"}
          />
        </View>
      </Grid>
    </ListItemContainer>
  );
};

export default OutstandingTransactionsListItem;
