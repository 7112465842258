import type { FC } from "react";
import React, { useCallback, useMemo, useRef, useState } from "react";
import type { SidePanelProps } from "DLUI/sidePanel/sidePanel";
import SidePanel from "DLUI/sidePanel/sidePanel";
import { DataCy, LoginResponseType, type PushNotificationDto } from "@doorloop/dto";
import View from "../DLUI/view/view";
import ColorsEnum from "utils/colorsEnum";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { IconButton } from "DLUI/form";
import { NotificationSettingIcon, XIcon } from "@/assets";
import { Link } from "DLUI/link";
import { Dialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { shallowEqual, useSelector } from "react-redux";
import type { IRootState } from "store";
import { store } from "store";
import { useTranslation } from "react-i18next";
import { InfiniteList } from "DLUI/infiniteList";
import { NotificationsEmptyListView } from "DLUI/notificationCenter/components/notificationsEmptyListView";
import { NotificationItemSkeleton } from "DLUI/notificationCenter/components/notificationItemSkeleton";
import NotificationItem from "DLUI/notificationCenter/components/notificationItem";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { NavigationManager } from "utils/navigation";
import { updateTotalUnreadNotification } from "store/auth/actions";
import NotificationSettingsDialog from "DLUI/dialogs/notifications/notificationsSettingsDialog";
import NotificationsTenantSettings from "screens/tenantPortal/components/dialogs/notification/notificationsTenantSettings";
import type { NotificationApi } from "api/notificationApi";
import type { TenantPortalNotificationApi } from "api/tenantPortal/tenantPortalNotificationApi";
import { notificationsLayoutVisibilityAtom } from "components/layouts/layoutAtom";
import { useAtom, useSetAtom } from "jotai";
import { useAnalyticsService } from "@/hooks/useAnalyticsService";

const notificationsScrollableTarget = "notificationsScrollableTarget";

interface ComponentProps {
  apiMethod: NotificationApi | TenantPortalNotificationApi;
  top?: number;
}

export const SidePanelLayout: FC<ComponentProps> = ({ apiMethod, top = 0 }) => {
  const { t } = useTranslation();
  const { dispatchAnalytics } = useAnalyticsService();
  const { currentLoginResponse, isAuthenticated } = useSelector((state: IRootState) => state.auth, shallowEqual);
  const [dialogState, setDialogState] = useState(DialogState.Hidden);
  const toggleButtonRef = useRef<HTMLDivElement | null>(null);
  const DialogSettingsContent = useMemo(
    () =>
      currentLoginResponse?.type === LoginResponseType.USER ? NotificationSettingsDialog : NotificationsTenantSettings,
    [currentLoginResponse?.type]
  );
  const [isNotificationsLayoutIsVisible] = useAtom(notificationsLayoutVisibilityAtom);
  const setNotificationsLayoutVisibilityAtom = useSetAtom(notificationsLayoutVisibilityAtom);

  const onOpenNotificationSettingsDialog = () => {
    setDialogState(DialogState.Show);
    setNotificationsLayoutVisibilityAtom(false);

    dispatchAnalytics("notification_center_settings_clicked");
  };

  const closeNotificationCenter = useCallback(() => {
    setNotificationsLayoutVisibilityAtom(false);
  }, []);

  const handleClickOutside = useCallback<NonNullable<SidePanelProps["onClickOutside"]>>(
    (event) => {
      if (!toggleButtonRef.current?.contains(event.target as Node)) {
        setNotificationsLayoutVisibilityAtom(false);
      }
    },
    [toggleButtonRef.current]
  );

  const decreaseTotalUnread = useCallback(() => {
    if (currentLoginResponse?.totalUnreadNotifications && currentLoginResponse.totalUnreadNotifications > 0) {
      store.dispatch(updateTotalUnreadNotification(currentLoginResponse.totalUnreadNotifications - 1));
    }
  }, [currentLoginResponse?.totalUnreadNotifications]);

  const handleNotificationClicked = useCallback(
    async (data: PushNotificationDto) => {
      try {
        if (!data.isOpened) {
          await apiMethod.update(data.id!, { ...data, isOpened: true }).then((res) => {
            if (res.status) {
              decreaseTotalUnread();
            }
          });
        }
      } catch (err) {
        console.error(err);
      }

      if (data.link) {
        NavigationManager.goToRelativeUrl(data.link);
      }

      dispatchAnalytics(
        "push_notification_opened",
        {
          notificationType: data.serviceType
        },
        {
          trackEventInIntercom: true
        }
      );

      setNotificationsLayoutVisibilityAtom(false);
    },
    [currentLoginResponse?.totalUnreadNotifications]
  );

  const renderNotificationItem = async (currentItem: PushNotificationDto, index: number) => (
    <View dataCy={DataCy.DLUI.listItem.listItem} key={index}>
      <NotificationItem key={currentItem.id} data={currentItem} onClick={handleNotificationClicked} />

      <View paddingRight={20} paddingLeft={20}>
        <HorizontalSeparationLine height={2} marginTop={5} marginBottom={5} />
      </View>
    </View>
  );

  const NotificationsList = () => (
    <InfiniteList
      emptyListView={<NotificationsEmptyListView />}
      skeletonItem={NotificationItemSkeleton}
      scrollableTarget={notificationsScrollableTarget}
      showStickyHeader={false}
      removeDefaultBottomPadding
      renderItem={renderNotificationItem}
      apiMethod={apiMethod}
      filterObj={{ page_size: 20 }}
    />
  );

  const handleMarkAllUnreadClicked = async () => {
    if (currentLoginResponse?.totalUnreadNotifications && currentLoginResponse.totalUnreadNotifications > 0) {
      await apiMethod.markAllUnreadNotificationsAsRead().then((res) => {
        if (res.statusCode === 200) {
          store.dispatch(updateTotalUnreadNotification());
        }
      });
    }
    dispatchAnalytics("notification_center_mark_all_as_read_clicked");
  };

  return (
    <div ref={toggleButtonRef}>
      <SidePanel
        zIndex={9999}
        width={420}
        noWrap
        top={top}
        isOpen={isNotificationsLayoutIsVisible}
        overflow={"hidden"}
        backgroundColor={"white"}
        onClickOutside={handleClickOutside}
        className={"side-panel-notifications"}
        dataCy={DataCy.DLUI.notifications.notificationsSidePanel}
      >
        <View
          paddingRight={5}
          paddingLeft={20}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          height={50}
          style={{ borderBottom: `2px solid ${ColorsEnum.LightGray}`, flex: "0 1 auto", overflow: "hidden" }}
        >
          <Text bold fontSize={16} value={AppStrings.Notifications.Title} />
          <View flex={0.8} flexDirection={"row"} justifyContent={"flex-end"}>
            <IconButton size={20} Icon={NotificationSettingIcon} onClick={onOpenNotificationSettingsDialog} />
            <IconButton size={20} Icon={XIcon} pathColor={"light-gray"} onClick={closeNotificationCenter} />
          </View>
        </View>

        <View style={{ flex: "1", overflow: "hidden auto" }} noWrap id={notificationsScrollableTarget}>
          <View
            flexDirection={"row"}
            paddingRight={20}
            paddingLeft={20}
            paddingBottom={15}
            paddingTop={20}
            justifyContent={"space-between"}
          >
            <Text bold fontSize={14}>
              {t(AppStrings.Notifications.Settings.Latest)}{" "}
              {Boolean(currentLoginResponse?.totalUnreadNotifications) &&
                `(${currentLoginResponse?.totalUnreadNotifications} ${t(AppStrings.Notifications.Settings.UnRead)})`}
            </Text>
            <Link fontSize={14} underline={"none"} hoverColor={"lightBlue"} onClick={handleMarkAllUnreadClicked}>
              {t(AppStrings.Notifications.Settings.MarkAll)}
            </Link>
          </View>

          {isNotificationsLayoutIsVisible && <NotificationsList />}
        </View>
      </SidePanel>
      <Dialog
        dialogState={dialogState}
        onClose={() => setDialogState(DialogState.Hidden)}
        Content={DialogSettingsContent}
        onBackdropClick={() => setDialogState(DialogState.Hidden)}
        showCloseIcon
        dialogTitle={AppStrings.Notifications.Settings.Title}
      />
    </div>
  );
};
