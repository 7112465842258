import type { RentalApplicationCompanySettingsDto } from "@doorloop/dto";
import { RentalApplicationFieldSettings, RentalApplicationOptionalControlledSections } from "@doorloop/dto";
import { View } from "DLUI/view";
import { FastField, getIn, useFormikContext } from "formik";
import React, { Fragment, useMemo, useState } from "react";
import Text from "DLUI/text";
import AppStrings from "../../../../../../locale/keys";
import { FormikSwitchButton, RadioGroup } from "DLUI/form";
import { useTranslation } from "react-i18next";
import { SeparationLine } from "DLUI/separatorView";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { RichTextField } from "DLUI/form/richTextField/richTextField";
import { RentalAppFieldsGenerator } from "screens/settings/rentalApplications/rentalAppFieldsGenerator";
import { RentalApplicationWizardSteps } from "screens/tenantPortal/rentalApplication/types";
import { didChangeShowContentSelection } from "screens/settings/rentalApplications/formikHelper";
import { renderFieldsCannotBeEmptyError } from "screens/settings/rentalApplications/components/vehicleInformation/vehicleInformation";

const EmergencyContacts: React.FC<{}> = () => {
  const { t } = useTranslation();
  const formik = useFormikContext<RentalApplicationCompanySettingsDto>();
  const {
    values: { emergencyContacts },
    setFieldValue
  } = formik;
  const [showContent, setShowContent] = useState<boolean>(Boolean(getIn(emergencyContacts, "isShown")) || false);
  const { propertyId } = useParams<any>();
  const [renderForm, setRenderForm] = useState<boolean>(true);

  const emergencyContactsSelectionDefaultValue = useMemo(() => {
    const emptyObject = _.values(emergencyContacts).every((x) => x === undefined);
    if (emptyObject) {
      return "companyDefault";
    }
    return "specifyEmergencyContactsInfo";
  }, []);

  const [emergencyContactsSelectionType, setEmergencyContactsSelectionType] = useState<
    "companyDefault" | "specifyEmergencyContactsInfo"
  >(emergencyContactsSelectionDefaultValue);

  const didChangeSpecifyEmergencyContactsSelection = (selectedValue: string) => {
    if (selectedValue === "false") {
      setEmergencyContactsSelectionType("companyDefault");

      setTimeout(() => {
        refreshForm();
      }, 500);
    } else {
      setEmergencyContactsSelectionType("specifyEmergencyContactsInfo");
      setFieldValue("emergencyContacts.isShown", false);
    }
  };

  const refreshForm = () => {
    setRenderForm(false);
    setShowContent(false);
    setFieldValue("emergencyContacts", undefined);

    setTimeout(() => {
      setRenderForm(true);
    }, 0);
  };

  const renderSpecifyRadioButtons = () => {
    if (propertyId) {
      return (
        <View>
          <RadioGroup
            didChangeSelection={didChangeSpecifyEmergencyContactsSelection}
            uniqueKey={"FRB"}
            defaultValue={emergencyContactsSelectionType === "companyDefault" ? "false" : "true"}
            marginTop={20}
            radioGroupItems={[
              {
                label: AppStrings.Common.HideSectionForProperty,
                value: "false"
              },
              {
                label: AppStrings.Common.SpecifySettingsForProperty,
                value: "true"
              }
            ]}
          />
          <SeparationLine marginTop={20} width={"100%"} height={1} />
        </View>
      );
    }
  };

  return (
    <>
      <Text
        value={AppStrings.Settings.GeneralSettings.RentalApplications.EmergencyContactsSectionDescription}
        lineHeight={"26px"}
      />
      {renderSpecifyRadioButtons()}
      <View
        shouldShow={emergencyContactsSelectionType === "specifyEmergencyContactsInfo"}
        showAnimation={"fade-in"}
        hideAnimation={"fade-out"}
      >
        {renderForm ? (
          <Fragment>
            <View height={20} />
            <FastField
              component={FormikSwitchButton}
              name={"emergencyContacts.isShown"}
              label={AppStrings.Settings.GeneralSettings.RentalApplications.AskAboutEmergencyContacts}
              onChange={(value: boolean) =>
                didChangeShowContentSelection({
                  value,
                  setFunction: setShowContent,
                  formik,
                  formName: "emergencyContacts",
                  fieldsObj: RentalApplicationFieldSettings.emergencyContacts.fields
                })
              }
            />
            <View shouldShow={showContent} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
              <Text
                value={AppStrings.Tasks.WorkOrdersScreen.Instructions}
                fontSize={20}
                fontWeight={700}
                marginTop={20}
              />

              <FastField
                component={RichTextField}
                label={t(AppStrings.Tasks.WorkOrdersScreen.Instructions)}
                name={`emergencyContacts.instructions`}
                styles={{ container: { marginTop: 20 } }}
                maxLength={5000}
                html={{ name: "emergencyContacts.instructionsHTML", value: emergencyContacts?.instructionsHTML }}
              />
              <View flexDirection={"row"} justifyContent={"space-between"} noWrap marginTop={30} marginBottom={12}>
                <Text value={AppStrings.Common.Field} fontSize={12} color={"secondary-gray"} />
                <Text value={AppStrings.Common.Required} fontSize={12} color={"secondary-gray"} />
              </View>
              <View noWrap>
                <RentalAppFieldsGenerator
                  fieldsConfigObject={RentalApplicationFieldSettings.emergencyContacts.fields}
                  formik={formik}
                  formName={RentalApplicationWizardSteps.EmergencyContacts}
                />
                {renderFieldsCannotBeEmptyError(
                  emergencyContacts,
                  RentalApplicationOptionalControlledSections.EmergencyContacts
                )}
              </View>
              <View height={50} />
            </View>
          </Fragment>
        ) : null}
      </View>
    </>
  );
};

export default EmergencyContacts;
