import type { FC } from "react";
import React from "react";
import { View } from "DLUI/view";
import { InfoFullGrayIcon, PaymentIcon, PrintIcon } from "assets/icons";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { IconClause } from "screens/outgoingPayments/infoBoxes/iconClause";
import type { SupportedPersonTypes } from "screens/outgoingPayments/mappings";

interface Props {
  personType?: SupportedPersonTypes;
}

export const EmailInfo: FC<Props> = ({ personType }: Props) => (
  <View backgroundColor={"secondary-gray-light"} borderRadius={10} marginBottom={20}>
    <View marginRight={20} marginTop={20} marginLeft={20} autoWidth>
      <IconClause
        IconSrc={InfoFullGrayIcon}
        headerText={AppStrings.Tenants.NewTenant.Email}
        subText={AppStrings.Common.OutgoingPayments.EmailInfoDescription}
        replaceObject={
          personType && {
            personType
          }
        }
      />
      <View marginBottom={20}>
        <Text fontSize={16} value={AppStrings.Common.OutgoingPayments.EmailInfoDescription2} />
      </View>
      <HorizontalSeparationLine marginBottom={20} />
      <Text fontSize={16} value={AppStrings.Common.OutgoingPayments.TheyCanChooseBetween} marginBottom={20} />
      <IconClause
        IconSrc={PaymentIcon}
        headerText={AppStrings.Common.OutgoingPayments.DirectDeposit}
        subText={AppStrings.Common.OutgoingPayments.DirectDepositSummary}
        pathColor={"black"}
        size={40}
        smallerHeader
      />
      <IconClause
        IconSrc={PrintIcon}
        headerText={AppStrings.Common.OutgoingPayments.PrintAndDeposit}
        subText={AppStrings.Common.OutgoingPayments.PrintAndDepositDesc}
        pathColor={"black"}
        size={40}
        smallerHeader
      />
    </View>
  </View>
);
