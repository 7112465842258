import { AbstractAutoCompleteContext as AutoCompleteContext } from "../autoCompleteTyped/autoComplete.context";

import type { NarrowedPersonDto } from "@doorloop/dto";
import type { AutoCompleteContext as IAutoCompleteContext } from "../autoCompleteTyped/autoComplete.context";
import type { PeopleAutoCompleteMetadata } from "./peopleAutoComplete";

export const PeopleAutoCompleteContext = AutoCompleteContext as IAutoCompleteContext<
  NarrowedPersonDto,
  PeopleAutoCompleteMetadata
>;
