import type { FC } from "react";
import React, { useMemo } from "react";
import type { PictureDto } from "@doorloop/dto";
import type { SVGIconComponent } from "assets/icons";
import { View } from "DLUI/view";
import { Icon } from "DLUI/icon";
import _ from "lodash";
import { Picture } from "DLUI/picture/picture";
import { rankSorter } from "utils/dataStructureUtils";

interface Props {
  pictures?: PictureDto[];
  alternateIcon: SVGIconComponent;
}

export const PictureThumbnail: FC<Props> = ({ pictures, alternateIcon }: Props) => {
  const firstPictureUrl = useMemo<string>(() => {
    const [firstPicture] = pictures?.sort(rankSorter) || [];
    return firstPicture?.url || "";
  }, [pictures]);

  return (
    <View
      width={70}
      height={50}
      borderRadius={5}
      backgroundColor={"secondary-gray-light"}
      alignItems={"center"}
      justifyContent={"center"}
      marginRight={10}
    >
      {_.isEmpty(pictures) ? (
        <Icon Source={alternateIcon} width={40} height={30} pathColor={"black"} />
      ) : (
        <Picture url={firstPictureUrl} />
      )}
    </View>
  );
};
