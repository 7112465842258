import React, { useCallback, useMemo } from "react";
import type { GridCellProps, GridProps, GridSelectionChangeEvent } from "@progress/kendo-react-grid";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import _ from "lodash";
import type { TFunction } from "react-i18next";
import type { GridColumn, GridSubColumn } from "DLUI/lists/gridList/gridList";
import type { GroupResult } from "@progress/kendo-data-query";
import { EDITING_IDENTIFIER, EXPANSION_IDENTIFIER, SELECTION_IDENTIFIER } from "DLUI/lists/types";
import clsx from "clsx";
import { Link } from "../../../link";
import { getColumnTotals } from "../utils";
import { UnControlledCheckBox } from "DLUI/form/checkBox/base/unControlledCheckBox";
import { ControlledCheckBox } from "DLUI/form";

interface GridCellConstructorProps {
  t: TFunction<"translation">;
  allGridColumns: GridColumn[];
  onGridRowPress?: (cellProps: GridCellProps) => void;
  onGridCellEditFinish?: (cellProps: GridCellProps) => void;
  onGroupSelectionChanged?: (isChecked: boolean, dataItem: GroupResult) => void;
  onGridCellEditRequest?: (cellProps: GridCellProps) => void;
  onSelectionCheckboxClick?: GridProps["onSelectionChange"];
  isMultiSelect?: boolean;
  getRoute?: (cellProps: GridCellProps) => string;
}

export const CurrencyCell = ({ cellValue }: { cellValue: number }) => (
  <td>
    {isNaN(cellValue) ? null : (
      <Text
        className={"currency-td"}
        value={String(cellValue)}
        fontWeight={700}
        fontSize={14}
        formatType={"currency"}
        color={"black"}
      />
    )}
  </td>
);

/**
 * GridCell is a builder function that returns a GridCell component
 * @param allGridColumns - all grid columns
 * @param onGridRowPress - the function to call when a row is pressed
 * @param onGroupSelectionChanged - the function to call when a group selection checkbox is changed
 * @param isMultiSelect - whether the grid is multi-select
 * @constructor
 */
export const BuildGridCell = <DtoType extends object>({
  t,
  allGridColumns,
  onGridRowPress,
  onGridCellEditRequest,
  onGridCellEditFinish,
  onSelectionCheckboxClick,
  onGroupSelectionChanged,
  isMultiSelect,
  getRoute
}: GridCellConstructorProps) => {
  const cellRenderer: GridProps["cellRender"] = (tdElement, cellProps) => {
    const _onGroupSelectionChanged = useCallback(
      (isChecked: boolean) => {
        onGroupSelectionChanged?.(isChecked, cellProps.dataItem as GroupResult);
      },
      [cellProps.dataItem]
    );

    return useMemo(() => {
      let currentColumn: GridColumn | GridSubColumn | undefined = allGridColumns.find(
        (x) => x.field === cellProps.field
      );

      if (!currentColumn) {
        const subColumns = _.flatten(allGridColumns.map((x) => x.subColumns));
        currentColumn = subColumns.find((x: any) => x?.field === cellProps.field);
      }

      if (cellProps.rowType === "data" && cellProps.field === SELECTION_IDENTIFIER) {
        return (
          <td
            {...(tdElement?.props as any)}
            style={{
              overflow: "visible"
            }}
          >
            <ControlledCheckBox
              checked={cellProps.dataItem[SELECTION_IDENTIFIER] === true}
              onChange={(checked, value, e) => {
                onSelectionCheckboxClick?.({
                  syntheticEvent: e,
                  dataItem: cellProps.dataItem
                } as unknown as GridSelectionChangeEvent);
              }}
              style={{ height: "32px !important" }}
            />
          </td>
        );
      }

      if (cellProps.rowType === "groupHeader" && tdElement) {
        const [expansionCaret, nameTag] = (tdElement as any).props.children.props.children;

        return (
          <td
            {...(tdElement.props as any)}
            style={{
              display: "flex",
              alignItems: "center"
            }}
          >
            {expansionCaret}
            {isMultiSelect && <UnControlledCheckBox onChange={_onGroupSelectionChanged} />}
            {nameTag}
          </td>
        );
      }

      if (currentColumn && currentColumn.field === cellProps.field) {
        const hasClickHandler =
          typeof currentColumn.onCellPress === "function" ||
          typeof onGridRowPress === "function" ||
          typeof currentColumn.getRoute === "function" ||
          typeof getRoute === "function";
        const currentColRoute = currentColumn.getRoute && currentColumn.getRoute(cellProps.dataItem);
        const globalRoute = getRoute?.(cellProps);
        const href = currentColRoute || globalRoute;
        const renderText = (cellValue: string | Date) => (
          <Text formatType={currentColumn?.formatType} color={"black"} value={cellValue} fontSize={14} />
        );
        const clickableCellClass = clsx([hasClickHandler && "clickable-cell"]);

        const _didPressGridRow = () => {
          if (currentColumn?.editOnClick) {
            onGridCellEditRequest?.(cellProps);
            return;
          }

          if (currentColumn && currentColumn.onCellPress) {
            currentColumn.onCellPress(cellProps.dataItem);
          } else if (onGridRowPress) {
            onGridRowPress(cellProps);
          }
        };

        if (cellProps.rowType === "groupFooter" && cellProps.expanded) {
          if (currentColumn.groupFooterCell) {
            if (currentColumn.groupFooterCell.totalForGroup) {
              return (
                <td>
                  <View justifyContent={"flex-start"} alignItems={"center"} flexDirection={"row"}>
                    <Text color={"black"} fontSize={14} fontWeight={700} overFlow={"ellipsis"}>
                      {`${t(AppStrings.Reports.TransactionsReport.TotalFor)} ${cellProps.dataItem.value} `}
                    </Text>
                  </View>
                </td>
              );
            }

            if (currentColumn.groupFooterCell.sum) {
              const totals = getColumnTotals(
                cellProps.dataItem.items,
                cellProps.field || "",
                currentColumn.showZeroValues
              );

              return <CurrencyCell cellValue={totals.sum} />;
            }

            if (currentColumn.groupFooterCell.average && cellProps.dataItem.aggregates) {
              const totals = getColumnTotals(
                cellProps.dataItem.items,
                cellProps.field || "",
                currentColumn.showZeroValues
              );

              return (
                <td>
                  <View justifyContent={"flex-start"} alignItems={"center"} flexDirection={"row"}>
                    <Text color={"black"} fontSize={14} formatType={currentColumn.formatType} fontWeight={700}>
                      {totals.average}
                    </Text>
                  </View>
                </td>
              );
            }

            if (currentColumn.groupFooterCell.customCell) {
              const countText = currentColumn.groupFooterCell.customCell(cellProps.dataItem.items);

              return (
                <td>
                  <View flexDirection={"row"}>
                    <Text fontWeight={700} fontSize={14} color={"black"}>
                      {t(countText[0])} {" " + t(countText[1])}
                    </Text>
                  </View>
                </td>
              );
            }
          }
        } else if (cellProps.rowType === "data") {
          if (currentColumn?.formatType !== undefined) {
            const cellValue = cellProps.field && _.get(cellProps.dataItem, cellProps.field);
            return href ? (
              <td className={clickableCellClass}>
                <Link hrefUrl={href}>{renderText(cellValue)}</Link>
              </td>
            ) : (
              <td onClick={hasClickHandler ? _didPressGridRow : undefined} className={clickableCellClass}>
                {renderText(cellValue)}
              </td>
            );
          }
        }

        if (cellProps.field) {
          const cellValue = _.get(cellProps.dataItem, cellProps.field);

          return href ? (
            <td className={clickableCellClass}>
              <Link hrefUrl={href}>{renderText(cellValue)}</Link>
            </td>
          ) : (
            <td onClick={hasClickHandler ? _didPressGridRow : undefined} className={clickableCellClass}>
              {renderText(cellValue)}
            </td>
          );
        }
      }

      return tdElement;
    }, [
      cellProps.dataItem?.[SELECTION_IDENTIFIER],
      cellProps.dataItem?.[EXPANSION_IDENTIFIER],
      cellProps.dataItem?.[EDITING_IDENTIFIER],
      cellProps.selectionChange
    ]);
  };
  return cellRenderer;
};
