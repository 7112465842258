import { atom, useAtom } from "jotai";

import type { CommunicationCenterCreditsDto } from "@doorloop/dto";

import { conversationsApi } from "api/conversationsApi";

const communicationsCenterCreditsAtom = atom<CommunicationCenterCreditsDto | undefined>(undefined);

export const useCommunicationsCenterCredits = () => {
  const [credits, setCredits] = useAtom(communicationsCenterCreditsAtom);

  const fetchCredits = async () => {
    const creditsResponse = await conversationsApi.getCommunicationsCredits();

    if (creditsResponse.status) {
      setCredits(creditsResponse.data);
      return creditsResponse.data;
    }
  };

  const purchaseCredits = async () => {
    const creditsResponse = await conversationsApi.purchaseCommunicationCredits();

    if (creditsResponse.status) {
      setCredits(creditsResponse.data);
      return creditsResponse.data;
    }
  };

  return {
    fetchCredits,
    purchaseCredits,
    credits
  };
};
