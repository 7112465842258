import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { SeparationLine } from "DLUI/separatorView";

interface BankDepositDialogFooterProps {
  children?: React.ReactNode;
  totalLabel?: string;
  totalValue?: string;
  totalSelectedLabel?: string;
  totalSelectedValue?: string;
  footerHeight?: number;
}

export const BankDepositDialogFooter = ({
  children,
  totalLabel,
  totalValue,
  totalSelectedLabel,
  totalSelectedValue,
  footerHeight = 50
}: BankDepositDialogFooterProps) => (
  <View
    flexDirection={"row"}
    alignItems={"center"}
    justifyContent={"space-between"}
    height={footerHeight}
    paddingRight={36}
    paddingLeft={36}
  >
    <View flex={"none"} flexDirection={"row"}>
      {children}
    </View>
    <View height={17} flex={"none"} gap={10} flexDirection={"row"} alignItems={"center"}>
      <View flex={"none"} gap={6} flexDirection={"row"}>
        <Text value={totalSelectedLabel} />
        <Text bold value={totalSelectedValue} />
      </View>
      <SeparationLine width={1} dividerColor={"gray"} height={"100%"} />
      <View flex={"none"} gap={6} flexDirection={"row"}>
        <Text value={totalLabel} />
        <Text formatType={"currency"} bold value={totalValue} />
      </View>
    </View>
  </View>
);
