import React from "react";
import type { CompanyInfoSettingsDto } from "@doorloop/dto";
import { SettingPermission } from "@doorloop/dto";
import type { DialogState } from "DLUI/dialogs/loadingDialog";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { initFormValues, validateForm, validateTenantPortalForm } from "./formikHelper";
import { getResidentPortalFormMenuItems } from "./menuItems";
import { TenantPortalAnimatedContentDialog } from "./tenantPortalAnimatedContentDialog";
import { SettingsReadOnlyScope } from "DLUI/permissionScope/readOnlyScope";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";

interface ComponentProps {
  dialogState: DialogState;
  onClose: () => void;
  onBackdropClick: () => void;
  title?: string;
}

const TenantPortalDialog: React.FC<ComponentProps> = ({
  dialogState,
  onClose,
  onBackdropClick,
  title
}: ComponentProps) => {
  const { t } = useTranslation();
  const residentPortalFormMenuItems = getResidentPortalFormMenuItems();
  const dialogHeight = getDialogFrameDimension("height", 920);

  const _onClose = (values: CompanyInfoSettingsDto) => {
    onClose();
  };
  const { propertyId } = useParams<any>();

  return (
    <SettingsReadOnlyScope permission={SettingPermission.tenantPortal}>
      <TenantPortalAnimatedContentDialog
        dialogState={dialogState}
        onClose={_onClose}
        sectionItems={residentPortalFormMenuItems}
        disableBackdropClick
        onBackdropClick={onBackdropClick}
        getformikInitialValues={initFormValues}
        formikValidation={validateForm}
        validationMethod={validateTenantPortalForm}
        loadingText={t(AppStrings.Settings.GeneralSettings.CompanyInfo.LoadingCompanyInformation)}
        errorText={t(AppStrings.Settings.GeneralSettings.CompanyInfo.FailedToLoadCompanyInformation)}
        successText={t(AppStrings.Settings.GeneralSettings.CompanyInfo.UpdatingCompanyInformationSuccessText)}
        dialogTitle={title || ""}
        dialogHeight={dialogHeight}
        propertyId={propertyId}
      />
    </SettingsReadOnlyScope>
  );
};

export default TenantPortalDialog;
