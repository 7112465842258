import type {
  FieldSettingsType,
  MerchantAccountDto,
  RentalApplicationAdditionalInfoSettingsDto,
  RentalApplicationControlledSections,
  RentalApplicationFieldControlledSectionSettingsDto
} from "@doorloop/dto";
import {
  createValidator,
  EPayPaymentMethod,
  merchantAccountUtils,
  RentalApplicationChargeOptions,
  RentalApplicationCompanySettingsDto,
  RentalApplicationFeeSettingsDto,
  RentalApplicationFieldSettings,
  RentalApplicationOptionalControlledSections,
  RentalApplicationPropertySettingsDto
} from "@doorloop/dto";
import type { FormikProps } from "formik";
import { getIn } from "formik";
import _, { isNumber } from "lodash";
import { TRANSUNION_FULL_AMOUNT } from "screens/settings/rentalApplications/components/applicationSettings/applicationFeesForm";
import type { MenuItem } from "DLUI/dialogs";
import type { AnimatedContentDialogValidationProps } from "screens/settings/personalInformation/formikHelper";

export const MINIMAL_CREDIT_CARD_FEE = 3.5;

export const initFormValues = (
  settingsData: RentalApplicationCompanySettingsDto,
  isUs: boolean
): RentalApplicationCompanySettingsDto => {
  const rentalApplicationCompanySettings = new RentalApplicationCompanySettingsDto(settingsData);

  if (!rentalApplicationCompanySettings.fees) {
    rentalApplicationCompanySettings.fees = new RentalApplicationFeeSettingsDto({
      rentalApplicationChargeOption: isUs
        ? RentalApplicationChargeOptions.CHARGE_APPLICANT
        : RentalApplicationChargeOptions.CHARGE_USER,
      screeningReportPayed: false,
      rentalAppFeesPayed: false,
      acceptedTermsAndConditions: true,
      enabled: true
    });
  }

  if (
    !rentalApplicationCompanySettings.fees?.rentalApplicationChargeOption ||
    (!isUs &&
      rentalApplicationCompanySettings.fees?.rentalApplicationChargeOption ===
        RentalApplicationChargeOptions.CHARGE_APPLICANT)
  ) {
    rentalApplicationCompanySettings.fees.rentalApplicationChargeOption = isUs
      ? RentalApplicationChargeOptions.CHARGE_APPLICANT
      : RentalApplicationChargeOptions.CHARGE_USER;
  }

  if (
    (isNumber(rentalApplicationCompanySettings.fees?.applicationFee) &&
      rentalApplicationCompanySettings.fees?.applicationFee < 0) ||
    (!isUs && rentalApplicationCompanySettings.fees?.applicationFee === TRANSUNION_FULL_AMOUNT)
  ) {
    rentalApplicationCompanySettings.fees.applicationFee = isUs ? TRANSUNION_FULL_AMOUNT : 0;
  }

  if (!rentalApplicationCompanySettings.fees?.acceptedTermsAndConditions) {
    rentalApplicationCompanySettings.fees.acceptedTermsAndConditions = true;
  }

  if (isUs && !rentalApplicationCompanySettings?.employment?.isShown) {
    rentalApplicationCompanySettings.employment.isShown = true;
  }

  if (isUs && !rentalApplicationCompanySettings?.residentialHistory?.isShown) {
    rentalApplicationCompanySettings.residentialHistory.isShown = true;
  }

  return rentalApplicationCompanySettings;
};

interface didChangeShowContentSelectionProps {
  value: boolean;
  setFunction?: (value: boolean) => void;
  fieldsObj: FieldSettingsType;
  formik: FormikProps<RentalApplicationPropertySettingsDto>;
  formName: RentalApplicationControlledSections;
}

export const didChangeShowContentSelection = ({
  value,
  setFunction,
  fieldsObj,
  formik,
  formName
}: didChangeShowContentSelectionProps) => {
  setFunction?.(value);

  if (value) {
    toggleAllRentalAppSectionFields({
      fieldsObj,
      formik,
      formName,
      check: true
    });
  }
};

export const validateForm = createValidator(RentalApplicationCompanySettingsDto);

export const validatePropertyForm = createValidator(RentalApplicationPropertySettingsDto);

const handleMandatorySectionsDefaultFields = (
  rentalApplicationPropertySettings: RentalApplicationPropertySettingsDto,
  sectionName: "employment" | "residentialHistory"
) => {
  const section = rentalApplicationPropertySettings?.[sectionName];

  if (section?.isShown) {
    const sectionFields = _.keys(RentalApplicationFieldSettings?.[sectionName]?.fields);
    const fieldsSettings: FieldSettingsType = { ...section.fieldsSettings };

    for (const fieldName in sectionFields) {
      if (!fieldsSettings[fieldName]) {
        fieldsSettings[fieldName] = {
          isVisible: true,
          isMandatory: false
        };
      }
    }

    section.fieldsSettings = fieldsSettings;
  }
};

export const initRentalApplicationFeesSettings = (feesEnabled?: boolean, feesExists?: boolean, isUs?: boolean) => {
  let feesSettingsDto;
  if (feesEnabled && !feesExists) {
    feesSettingsDto = new RentalApplicationFeeSettingsDto();
    feesSettingsDto.rentalApplicationChargeOption = isUs
      ? RentalApplicationChargeOptions.CHARGE_APPLICANT
      : RentalApplicationChargeOptions.CHARGE_USER;

    if (isUs) {
      feesSettingsDto.applicationFee = TRANSUNION_FULL_AMOUNT;
    }

    feesSettingsDto.acceptedTermsAndConditions = true;
  }

  return feesSettingsDto;
};

export const initPropertySettingsFormValues = (
  settingsData: RentalApplicationPropertySettingsDto,
  isUs?: boolean
): RentalApplicationPropertySettingsDto => {
  const rentalApplicationPropertySettings = new RentalApplicationPropertySettingsDto(settingsData);

  if (rentalApplicationPropertySettings?.enabled === undefined) {
    rentalApplicationPropertySettings.enabled = false;
  }

  const newFees = initRentalApplicationFeesSettings(
    rentalApplicationPropertySettings.enabled,
    Boolean(rentalApplicationPropertySettings.fees),
    isUs
  );

  if (newFees) {
    rentalApplicationPropertySettings.fees = newFees;
  }

  handleMandatorySectionsDefaultFields(rentalApplicationPropertySettings, "employment");
  handleMandatorySectionsDefaultFields(rentalApplicationPropertySettings, "residentialHistory");

  return rentalApplicationPropertySettings;
};

export const toggleAllRentalAppSectionFields = ({
  fieldsObj,
  formik,
  formName,
  check
}: {
  fieldsObj: FieldSettingsType;
  formik: FormikProps<RentalApplicationPropertySettingsDto>;
  formName: RentalApplicationControlledSections;
  check: boolean;
}) => {
  _.each(fieldsObj, (objItem, fieldName) => {
    formik.setFieldValue(`${formName}.fieldsSettings[${fieldName}].isVisible`, check);
  });
};

const validateControlledFields = (formValues: RentalApplicationPropertySettingsDto, sectionItems?: MenuItem[]) => {
  let isValid = true;
  let errorStepIndex: number | undefined;

  for (const sectionName in formValues) {
    const sectionFields: RentalApplicationFieldControlledSectionSettingsDto = formValues[sectionName];

    if (
      _.isObject(sectionFields) &&
      sectionFields?.isShown &&
      Object.values(RentalApplicationOptionalControlledSections).includes(
        sectionName as RentalApplicationOptionalControlledSections
      )
    ) {
      const sectionIndex = sectionItems?.findIndex((item) => item.dtoName === sectionName);
      const initialSectionFieldsObject = RentalApplicationFieldSettings?.[sectionName]?.fields as FieldSettingsType;

      const hasMandatoryFields = initialSectionFieldsObject
        ? Object.values(initialSectionFieldsObject).some((field) => field?.isMandatory)
        : false;

      if (!hasMandatoryFields) {
        if (sectionFields?.fieldsSettings && !_.isEmpty(sectionFields?.fieldsSettings)) {
          const foundVisibleField = _.some(sectionFields?.fieldsSettings, (fieldObj) => Boolean(fieldObj?.isVisible));

          if (!foundVisibleField) {
            isValid = false;
            errorStepIndex = sectionIndex;
            break;
          }
        } else {
          isValid = false;
          errorStepIndex = sectionIndex;
          break;
        }
      }
    }
  }

  return { isValid, errorStepIndex };
};

export const validatePropertySettingsForm = async ({
  formikRef,
  transunionFee,
  merchantAccountData,
  sectionItems
}: {
  formikRef: FormikProps<RentalApplicationPropertySettingsDto>;
  transunionFee?: number;
  merchantAccountData?: MerchantAccountDto;
  sectionItems?: MenuItem[];
}): Promise<AnimatedContentDialogValidationProps> => {
  formikRef.setFieldTouched("notifications.notifyWhenApplicationSubmittedUsers");
  const deductScreeningCostFromApplication = getIn(formikRef.values, "fees.deductScreeningCostFromApplication");
  const filesAndDocumentsFiles = getIn(formikRef.values, "filesAndDocuments.files");
  if (filesAndDocumentsFiles?.length) {
    filesAndDocumentsFiles.forEach((doc, idx) => formikRef.setFieldTouched(`filesAndDocuments.files[${idx}].label`));
  }

  if (formikRef?.values?.enabled) {
    formikRef.setFieldTouched("fees.acceptedTermsAndConditions");

    if (
      merchantAccountData?.fees &&
      formikRef.values?.fees?.rentalApplicationChargeOption === RentalApplicationChargeOptions.CHARGE_CUSTOM_FEE
    ) {
      const totalApplicationFee = formikRef?.values?.fees?.applicationFee || 0;
      const { fees } = merchantAccountUtils.calculateFeesFromTotalWithFees(
        merchantAccountData.fees,
        totalApplicationFee,
        EPayPaymentMethod.CARD
      );
      const feesAmount = Number(fees || 0);

      const minimalPaymentAmount = deductScreeningCostFromApplication ? (transunionFee || 0) + feesAmount : feesAmount;

      if (totalApplicationFee < minimalPaymentAmount) {
        return { isValid: false, errorStepIndex: 0 };
      }
    }

    const acceptedTermsAndConditions = getIn(formikRef.values, "fees.acceptedTermsAndConditions");

    if (!acceptedTermsAndConditions && formikRef.values.enabled) {
      return { isValid: false, errorStepIndex: 0 };
    }
  }

  return await validateErrors({ formikRef, sectionItems });
};

export const validateCompanySettingsForm = async ({
  formikRef,
  transunionFee,
  sectionItems
}: {
  formikRef: FormikProps<RentalApplicationCompanySettingsDto>;
  transunionFee?: number;
  merchantAccountData?: MerchantAccountDto;
  sectionItems?: MenuItem[];
}): Promise<AnimatedContentDialogValidationProps> => {
  formikRef.setFieldTouched("notifications.notifyWhenApplicationSubmittedUsers");
  formikRef.setFieldTouched("fees.acceptedTermsAndConditions");
  const deductScreeningCostFromApplication = getIn(formikRef.values, "fees.deductScreeningCostFromApplication");

  const acceptedTermsAndConditions = getIn(formikRef.values, "fees.acceptedTermsAndConditions");

  if (!acceptedTermsAndConditions) {
    return { isValid: false, errorStepIndex: 0 };
  }

  if (formikRef.values?.fees?.rentalApplicationChargeOption === RentalApplicationChargeOptions.CHARGE_CUSTOM_FEE) {
    const totalApplicationFee = formikRef?.values?.fees?.applicationFee || 0;
    const minimalPaymentAmount = deductScreeningCostFromApplication
      ? (transunionFee || 0) + MINIMAL_CREDIT_CARD_FEE
      : MINIMAL_CREDIT_CARD_FEE;

    if (totalApplicationFee < minimalPaymentAmount) {
      return { isValid: false, errorStepIndex: 0 };
    }
  }

  return await validateErrors({ formikRef, sectionItems });
};

interface ValidateErrorsProps {
  formikRef: FormikProps<RentalApplicationCompanySettingsDto | RentalApplicationPropertySettingsDto>;
  sectionItems?: MenuItem[];
}

const validateErrors = async ({
  formikRef,
  sectionItems
}: ValidateErrorsProps): Promise<AnimatedContentDialogValidationProps> => {
  const additionalInfo: RentalApplicationAdditionalInfoSettingsDto | undefined = getIn(
    formikRef.values,
    "additionalInfo"
  );

  if (additionalInfo?.isShown) {
    additionalInfo.additionalInfoQuestions?.forEach((item, index) => {
      formikRef.setFieldTouched(`additionalInfo.additionalInfoQuestions[${index}].question`);
    });
  }

  const isValidControlledFields = validateControlledFields(formikRef.values, sectionItems);
  if (!isValidControlledFields.isValid) {
    return { isValid: isValidControlledFields.isValid, errorStepIndex: isValidControlledFields.errorStepIndex };
  }

  let errorStepIndex: number | undefined = undefined;
  const errors = (await formikRef.validateForm()) as any;

  if (errors.additionalInfo?.additionalInfoQuestions) {
    errorStepIndex = 10;
  }

  if (errors.filesAndDocuments) {
    errorStepIndex = 11;
  }

  if (errors.landlordTermsAndConditions) {
    errorStepIndex = 12;
  }

  if (errors.notifications) {
    errorStepIndex = 13;
  }

  return { isValid: _.isEmpty(errors), errorStepIndex };
};
