import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import { ObjectPermission } from "@doorloop/dto";
import { useScreenFilterObject } from "hooks/useScreenFilterObject";
import { useTypedTranslation } from "locale";
import React, { useState } from "react";
import { SearchPanel } from "DLUI/screen";
import FilterOptions from "screens/transactions/transactionsOverview/filterOptions";
import { View } from "DLUI/view";
import { OutgoingPaymentsAccountSetupAccountList } from "screens/outgoingPayments/outgoingPaymentAccountSetup/outgoingPaymentsAccountSetupAccountList";

export const OutgoingPaymentsAccountSetupTab = () => {
  const { t } = useTypedTranslation();
  const { filterObject, screenProps } = useScreenFilterObject();
  const [requestInProgress, setRequestInProgress] = useState<boolean>(false);

  const handleLoadingDataStateChange = (loadingInProgress: boolean) => {
    setRequestInProgress(loadingInProgress);
  };

  return (
    <View flex={1} noWrap fullWidth>
      <SearchPanel
        filterOptions={FilterOptions}
        searchPlaceHolderText={t("common.outgoingPayments.searchPayments")}
        requestInProgress={requestInProgress}
        {...screenProps}
      />
      <RestrictedPermissionAccess
        clearance={{
          permission: ObjectPermission.accounts,
          field: "viewList"
        }}
        showNoAccess
      >
        <OutgoingPaymentsAccountSetupAccountList
          filterObj={filterObject}
          onLoadingDataStateChange={handleLoadingDataStateChange}
          stickyHeaderId={"BankAccountsList"}
        />
      </RestrictedPermissionAccess>
    </View>
  );
};
