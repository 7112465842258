import React from "react";
import makeStyles from "./styles";
import loadingDotsLottie from "assets/lottie/loadingDotsLottie.json";
import { Lottie } from "DLUI/lottie";

const LoadingComponent: React.FC = () => {
  const classes = makeStyles();
  return (
    <div className={classes.ListEndComponentContainer}>
      <Lottie loop animationData={loadingDotsLottie} width={50} height={50} autoPlay />
    </div>
  );
};

export default LoadingComponent;
