import React, { useEffect, useMemo, useState } from "react";
import type { EventListener } from "react-lottie";
import Lottie from "react-lottie";
import { View } from "DLUI/view";
import "./styles.css";
import type { FillColors } from "DLUI/icon";
import clsx from "clsx";
import { DataCy } from "@doorloop/dto/src/automation/dataCy";

interface ComponentProps {
  loop?: boolean;
  autoPlay?: boolean;
  animationData: any;
  height: number;
  width: number;
  onAnimationCompleted?: () => void;
  animationDelay?: number;
  pathColor?: FillColors;
  isPaused?: boolean;
  segments?: readonly number[];
  direction?: number;
}

const LottieElement: React.FC<ComponentProps> = ({
  animationData,
  loop,
  autoPlay,
  height,
  width,
  animationDelay,
  onAnimationCompleted,
  pathColor,
  isPaused,
  segments,
  direction
}: ComponentProps) => {
  const [renderLottie, setRenderLottie] = useState(false);

  const pathColorClass = useMemo(() => {
    switch (pathColor) {
      case "light-blue":
        return "pathLightBlue";
      default:
        return "";
    }
  }, []);

  useEffect(() => {
    const timeoutRef = setTimeout(() => setRenderLottie(true), animationDelay || 100);

    return () => clearTimeout(timeoutRef);
  }, []);

  const lottieOptions = {
    loop: loop || false,
    autoplay: autoPlay || false,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const _onAnimationCompleted = () => {
    if (onAnimationCompleted) {
      onAnimationCompleted();
    }
  };

  const eventListeners: EventListener[] = [
    {
      eventName: "complete",
      callback: _onAnimationCompleted
    }
  ];

  if (!renderLottie) {
    return <div />;
  }

  return (
    <View className={clsx(["DLUI_lottie", pathColorClass])} width={"auto"} data-cy={DataCy.common.doorLoopLogoLoader}>
      <Lottie
        eventListeners={eventListeners}
        options={lottieOptions}
        height={height}
        width={width}
        isPaused={isPaused || false}
        segments={segments}
        direction={direction || 1}
      />
    </View>
  );
};

export default LottieElement;
