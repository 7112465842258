import Text from "DLUI/text";
import { IndicationTag } from "DLUI/drawer/types";
import React from "react";
import type { FC } from "react";
import type { TextColor } from "DLUI/text/text";
import type { CSSProperties } from "react";
import { DLTheme } from "styles/defaultTheme";
import type { TextComponentProps } from "DLUI/text/text";

type TagVariant = "text" | "contained";

interface Props extends Omit<TextComponentProps, "value" | "variant"> {
  value: IndicationTag;
  variant?: TagVariant;
}

const variants: Record<TagVariant, CSSProperties> = {
  text: {
    translate: "0 -2px"
  },
  contained: {
    backgroundColor: DLTheme.newPalette.primary.light,
    padding: "2px 4px",
    borderRadius: "4px",
    alignSelf: "center"
  }
};

const colorsConfig: Record<IndicationTag, TextColor> = {
  [IndicationTag.NEW]: "pink"
};

export const Tag: FC<Props> = ({ value, variant = "text", ...restProps }) => (
  <Text
    fontSize={10}
    color={colorsConfig[value]}
    bold
    value={value}
    lineHeight={"normal"}
    style={variants[variant]}
    {...restProps}
  />
);
