import React from "react";
import { View } from "DLUI/view";
import { AnimatedView } from "DLUI/view/animatedView";
import type { Syndication } from "screens/settings/listingSyndication/listingSyndication.constant";
import SyncInstructions from "screens/settings/listingSyndication/syncInstructions";
import ListingSyndicationItem from "./listingSyndicationItem";
import { getIn, useFormikContext } from "formik";
import type { ListingsSettingsDto } from "@doorloop/dto";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { CopyLink } from "screens/settings/listingSyndication/copyLink";
import { DataCy } from "@doorloop/dto";

interface Props {
  syndicationItem: Syndication;
}

const ListingSyndicationInstructionsItem: React.FC<Props> = ({ syndicationItem }: Props) => {
  const {
    key,
    InstructionsView,
    rentalListingsURL,
    expandedView: ExpandedView,
    urlTextFieldLabel,
    copyButtonText
  } = syndicationItem;
  const formikRef = useFormikContext<ListingsSettingsDto>();
  const showInstructions = getIn(formikRef.values, key);
  const { screenContainerPadding } = useResponsiveHelper();
  const userData = useSelector((state: IRootState) => state.auth.currentLoginResponse);

  const _rentalListingsURL = `${rentalListingsURL}${userData?.currentDbTenant.id}`;

  return (
    <View
      flexDirection={"row"}
      backgroundColor={"white"}
      paddingRight={screenContainerPadding}
      paddingLeft={screenContainerPadding}
      alignItems={"center"}
    >
      <ListingSyndicationItem syndicationItem={syndicationItem} />
      <AnimatedView shouldShow={showInstructions}>
        <View noWrap dataCy={DataCy.globalSettings.listingSyndication.instructionsContainer}>
          {InstructionsView && <SyncInstructions InstructionsView={InstructionsView} />}
          {ExpandedView ? (
            <ExpandedView />
          ) : (
            <CopyLink label={urlTextFieldLabel} link={_rentalListingsURL} copyButtonText={copyButtonText} />
          )}
        </View>
      </AnimatedView>
    </View>
  );
};

export default ListingSyndicationInstructionsItem;
