import { useTypedTranslation } from "locale";
import { DataCy, ManagementFeeRuleTypesEnum } from "@doorloop/dto";
import { useMemo } from "react";
import { Select } from "DLUI/form";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";

interface RuleTypeSelectorProps {
  rulePath: string;
  handleChange?: (nextValue: string) => void;
}

export const RuleTypeSelector = ({ rulePath, handleChange }: RuleTypeSelectorProps) => {
  const { t } = useTypedTranslation();

  const ruleTypesSelector: Record<ManagementFeeRuleTypesEnum, string> = useMemo(() => {
    return {
      [ManagementFeeRuleTypesEnum.AccountBasedFeeAccrual]: t(
        "settings.generalSettings.managementFees.accrualDescription"
      ),
      [ManagementFeeRuleTypesEnum.AccountBasedFeeCash]: t("settings.generalSettings.managementFees.cashDescription"),
      [ManagementFeeRuleTypesEnum.FixedBasedFee]: t("settings.generalSettings.managementFees.flatFeeDescription")
    };
  }, [t]);

  return (
    <FastFieldSafe
      uniqueKey={"rule.ruleType"}
      name={`${rulePath}.ruleType`}
      component={Select}
      required
      onChange={handleChange}
      label={t("settings.generalSettings.managementFees.selectFeeType")}
      selectionEnum={ruleTypesSelector}
      dataCy={DataCy.globalSettings.managementFees.rules.rule.ruleSelector}
    />
  );
};
