import type { FC } from "react";
import React, { useCallback, useState } from "react";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import { ObjectPermission } from "@doorloop/dto";
import { View } from "DLUI/view";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import Text from "DLUI/text";
import { useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import type { BooleanMapInterface } from "utils/dataStructureUtils";
import { BooleanMap } from "utils/dataStructureUtils";
import type { UnitDto } from "@doorloop/dto";
import { unitAmenities } from "DLUI/dialogs/components/editUnit/unitAmenities";
import type { UnitAmenity } from "@doorloop/dto";
import { Amenity } from "DLUI/amenity/amenity";
import AppStrings from "locale/keys";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";

interface Props {}

export const EditUnitAmenities: FC<Props> = ({}: Props) => {
  const { dialogHorizontalPadding } = DialogsHelper();
  const formikRef = useFormikContext<UnitDto>();
  const [selectedAmenities, setSelectedAmenities] = useState<BooleanMapInterface<UnitAmenity>>(
    BooleanMap.toMap(formikRef.values.amenities || [])
  );

  const toggleAmenity = useCallback(
    (amenity: UnitAmenity) =>
      setSelectedAmenities((current) => {
        const newMap = {
          ...current,
          [amenity]: !current[amenity]
        };
        formikRef.setFieldValue("amenities", BooleanMap.toArray(newMap));
        return newMap;
      }),
    [setSelectedAmenities]
  );

  return (
    <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding} flex={1}>
      <RestrictedPermissionAccess
        clearance={{
          permission: ObjectPermission.properties,
          field: "edit"
        }}
        showNoAccess
      >
        <Text fontSize={16} value={AppStrings.Listing.UnitAmenitiesInfoDescription} />
        <HorizontalSeparationLine marginTop={20} marginBottom={20} />
        {unitAmenities.map(({ categoryLabel, amenities }, index) => (
          <Grid container key={index}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <View alignItems={"flex-start"}>
                <Text fontSize={18} value={categoryLabel} />
              </View>
            </Grid>
            {amenities.map((value, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                <View alignItems={"flex-start"}>
                  <Amenity
                    value={value}
                    checked={Boolean(selectedAmenities[value])}
                    onToggle={() => toggleAmenity(value)}
                    showCheckbox
                  />
                </View>
              </Grid>
            ))}
          </Grid>
        ))}
      </RestrictedPermissionAccess>
    </View>
  );
};
