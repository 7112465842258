import React from "react";
import { FastField } from "formik";
import View from "DLUI/view/view";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import AppStrings from "locale/keys";
import Text from "DLUI/text";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { FormikDatePicker, ReferenceLabel } from "DLUI/form";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Notes } from "DLUI/notes";
import { Grid, ListItemIcon } from "DLUI/listItems";
import { CalendarIcon, PaymentIcon } from "../../../../assets";
import { Icon } from "DLUI/icon";
import { DefaultListItemIconSize } from "screens/leases/leases/leasesList/leaseListItem";
import type { DialogData } from "screens/leases/leases/leaseDetails/transactions/transactionListItem";

import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  dialogData: DialogData;
}

const ReversePaymentForm: React.FC<ComponentProps> = ({ dialogData }: ComponentProps) => {
  const { reference, date, totalAmount } = dialogData;
  const { dialogHorizontalPadding } = DialogsHelper();
  const { isMobile } = useResponsiveHelper();
  return (
    <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Text
          marginTop={30}
          marginBottom={30}
          fontSize={16}
          color={"black"}
          value={AppStrings.Leases.LeaseTransaction.Dialog.ExplainerText}
        />
        <HorizontalSeparationLine />
        <Text
          marginTop={30}
          marginBottom={20}
          fontSize={18}
          bold
          color={"black"}
          value={AppStrings.Leases.LeaseTransaction.Dialog.OriginalPayment}
        />
        <View flexDirection={"row"} minHeight={70} backgroundColor={"white"} borderRadius={10}>
          <Grid showDivider xs={12} md={6} lg={6} height={"auto"}>
            <View minHeight={70} alignItems={"center"} flexDirection={"row"}>
              <ListItemIcon Icon={PaymentIcon} />
              <Text marginLeft={10} value={AppStrings.Leases.LeaseDetails.LeasePayment} />
              <View alignItems={"flex-end"} flex={1}>
                <ReferenceLabel backgroundColor={"dark"} marginRight={20} value={reference} />
              </View>
            </View>
          </Grid>
          <Grid
            height={isMobile ? "auto" : undefined}
            title={AppStrings.Common.ListHeader.Date}
            showDivider
            xs={12}
            md={3}
            lg={3}
          >
            <View flexDirection={"row"} alignItems={"center"} justifyContent={isMobile ? "flex-end" : "center"}>
              <Icon
                Source={CalendarIcon}
                width={DefaultListItemIconSize}
                height={DefaultListItemIconSize}
                pathColor={"black"}
              />
              <Text marginLeft={5} value={date} formatType={"date"} />
            </View>
          </Grid>
          <Grid height={isMobile ? "auto" : undefined} title={AppStrings.Common.ListHeader.Total} xs={12} md={3} lg={3}>
            <View justifyContent={"flex-end"} alignItems={"flex-end"}>
              <Text marginLeft={5} value={`${totalAmount}`} formatType={"currency"} />
            </View>
          </Grid>
        </View>
        <HorizontalSeparationLine marginTop={20} />
        <Text
          marginTop={30}
          marginBottom={20}
          fontSize={18}
          bold
          color={"black"}
          value={AppStrings.Leases.LeaseTransaction.Dialog.ReturnedPayment}
        />
        <FastField
          component={FormikDatePicker}
          label={AppStrings.Leases.LeaseTransaction.Dialog.ReturnDate}
          name={"date"}
          size={350}
          required
          minDate={date}
        />
        <View height={50} marginTop={20} paddingLeft={20} paddingRight={20} justifyContent={"center"}>
          <FastField component={Notes} height={30} name={"memo"} />
        </View>
      </MuiPickersUtilsProvider>
    </View>
  );
};

export default ReversePaymentForm;
