import _ from "lodash";
import type { FeatureFlagSettings, ProjectFeatureIds } from "./features";
import { ProjectFeatures } from "./features";
import { isAndroidWebView, isIOSWebView } from "common/native/utils";
import { getDbTenantsByFeatureFlag } from "./specificDbTenantsFeatureFlags";

const host = window.location.host;

const isProduction = host.includes("app.doorloop.com") || host.includes("app-portal.doorloop.com");
export const isBeta = host.includes("beta.doorloop.com") || host.includes("beta-portal.doorloop.com");
export const isDev = host.includes("dev.doorloop.com") || host.includes("dev-portal.doorloop.com");

const testPlatform = (featureId: ProjectFeatureIds, platform: keyof FeatureFlagSettings): boolean => {
  const featureToggle = ProjectFeatures(featureId)[platform];

  if (_.isBoolean(featureToggle)) {
    return featureToggle;
  }

  const { web, ios, android } = featureToggle;

  if (isAndroidWebView()) {
    return android;
  }

  if (isIOSWebView()) {
    return ios;
  }

  return web;
};

const isActive = (featureId: ProjectFeatureIds): boolean => {
  if (isProduction) {
    return testPlatform(featureId, "production");
  }
  if (isBeta) {
    return testPlatform(featureId, "beta");
  }
  if (isDev) {
    return testPlatform(featureId, "dev");
  }
  return testPlatform(featureId, "localDev");
};

const isInactive = (featureId: ProjectFeatureIds): boolean => !isActive(featureId);

const isEnabledForDbTenant = (featureId: ProjectFeatureIds, dbTenantId: string): boolean =>
  getDbTenantsByFeatureFlag(featureId)?.includes(dbTenantId) ?? true;

export const Feature = {
  isActive,
  isInactive,
  isEnabledForDbTenant
};
