import React from "react";
import { BackgroundImageView, View } from "DLUI/view";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import EditableContentView from "DLUI/text/editableContentView";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import ReportExportPanel from "DLUI/report/reportExportPanel";
import "../../../DLUI/text/styles.css";
import moment from "moment";
import { useIntl } from "react-intl";
import type { GridHeaderProps } from "DLUI/lists/gridList/dataGrid/gridHeaderWrapper";
import AccountingMethodHeader from "DLUI/lists/gridList/accountingMethodHeader";
import { useResponsiveHelper } from "@/contexts/responsiveContext";
import { EditableContent } from "@/components/DLUI/text/editableContent";
import { PencilIconNew } from "@/assets";

const gridHeaderMaxWidth = 650;

export default function GridHeader({
  filter_date_from,
  filter_date_to,
  filter_asOfDate,
  gridText,
  exportInProgress,
  didPressExportExcel,
  accountingMethod,
  isDateEditable = false
}: GridHeaderProps) {
  const { formatDate } = useIntl();
  const { isMobile } = useResponsiveHelper();

  const renderReportDates = () => {
    if (filter_asOfDate) {
      return (
        <Text formatType={"date"} value={filter_asOfDate} fontSize={16} paddingRight={10} color={"secondary-gray"} />
      );
    }
    if (!filter_date_from || !filter_date_to) {
      return (
        <Text
          value={AppStrings.Common.FilterPanelDatePicker.AllTime}
          fontSize={16}
          paddingRight={10}
          color={"secondary-gray"}
        />
      );
    }
    const fromDate = moment(filter_date_from).toDate();
    const toDate = moment(filter_date_to).toDate();
    return <Text value={`${formatDate(fromDate)} - ${formatDate(toDate)}`} color={"secondary-gray"} />;
  };

  const companyLogoURL = useSelector((state: IRootState) => state.auth?.currentLoginResponse?.currentDbTenant.logoUrl);

  return (
    <View
      className={"report-header"}
      flexDirection={"row"}
      marginTop={20}
      paddingRight={20}
      justifyContent={"space-between"}
    >
      <div
        style={{
          flexBasis: "75%"
        }}
      >
        <View
          className={"report-header-name"}
          marginBottom={20}
          flexDirection={"row"}
          alignItems={"center"}
          gap={8}
          noWrap
        >
          <EditableContent
            style={{
              order: 1
            }}
          >
            <Text value={gridText} fontSize={24} />
          </EditableContent>
          <PencilIconNew
            className="no-print"
            style={{
              order: isMobile ? 2 : 0,
              flexShrink: 0
            }}
          />
        </View>
        <View className={"report-header-dates"} marginBottom={20}>
          {isDateEditable ? (
            <EditableContentView maxWidth={gridHeaderMaxWidth} withBorderBottom>
              {renderReportDates()}
            </EditableContentView>
          ) : (
            <View maxWidth={gridHeaderMaxWidth} flexDirection={"row"}>
              {renderReportDates()}
            </View>
          )}
        </View>
        {accountingMethod && (
          <View className={"report-header-cash-basis"}>
            <AccountingMethodHeader accountingMethod={accountingMethod} />
          </View>
        )}
      </div>
      <View
        maxWidth={200}
        style={{
          flexBasis: "25%"
        }}
      >
        {companyLogoURL && !isMobile && (
          <View height={60} alignItems={"flex-end"} justifyContent={"center"}>
            <BackgroundImageView
              publicFileName={`url(${companyLogoURL})`}
              height={60}
              backgroundPosition={"right center"}
            />
          </View>
        )}
        <ReportExportPanel
          requestInProgress={exportInProgress}
          onExportExcelPress={didPressExportExcel}
          noHorizontalPadding
          noMarginTop
        />
      </View>
    </View>
  );
}
