import { usePermission } from "screens/settings/userRoles/usePermission";
import { ObjectPermission } from "@doorloop/dto";

export const useCommunicationsCenterPermissions = () => {
  const { hasAllPermissions } = usePermission();
  const hasEditPermission = hasAllPermissions([
    {
      permission: ObjectPermission.conversations,
      field: "edit"
    }
  ]);

  const hasViewPermission = hasAllPermissions([
    {
      permission: ObjectPermission.conversations,
      field: "viewList"
    },
    {
      permission: ObjectPermission.conversations,
      field: "viewOne"
    }
  ]);

  return { hasEditPermission, hasViewPermission };
};
