"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyndicationPropertyType = void 0;
const syndicationIdentifiable_1 = require("./syndicationIdentifiable");
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const syndicationPropertyID_1 = require("./syndicationPropertyID");
const syndicationILS_Identification_1 = require("./syndicationILS_Identification");
const syndicationInformation_1 = require("./syndicationInformation");
const syndicationILS_Unit_1 = require("./syndicationILS_Unit");
const syndicationPolicy_1 = require("./syndicationPolicy");
const syndicationContactAgent_1 = require("./syndicationContactAgent");
class SyndicationPropertyType extends syndicationIdentifiable_1.SyndicationIdentifiable {
}
exports.SyndicationPropertyType = SyndicationPropertyType;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", syndicationPropertyID_1.SyndicationPropertyID)
], SyndicationPropertyType.prototype, "PropertyID", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], SyndicationPropertyType.prototype, "_ListingID", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ArrayMaxSize)(10),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => syndicationIdentifiable_1.SyndicationIdentifiable),
    __metadata("design:type", Array)
], SyndicationPropertyType.prototype, "General_ID", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", syndicationContactAgent_1.SyndicationContactAgent)
], SyndicationPropertyType.prototype, "ContactAgent", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", syndicationILS_Identification_1.SyndicationILS_Identification)
], SyndicationPropertyType.prototype, "ILS_Identification", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", syndicationInformation_1.SyndicationInformation)
], SyndicationPropertyType.prototype, "Information", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", syndicationILS_Unit_1.SyndicationILS_Unit)
], SyndicationPropertyType.prototype, "ILS_Unit", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    __metadata("design:type", Array)
], SyndicationPropertyType.prototype, "Floorplan", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    __metadata("design:type", Array)
], SyndicationPropertyType.prototype, "Amenity", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", syndicationPolicy_1.SyndicationPolicy)
], SyndicationPropertyType.prototype, "Policy", void 0);
