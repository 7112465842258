const Accounts = {
  AccountInfo: {
    Number: "accounts.accountInfo.number",
    Routing: "accounts.accountInfo.routing",
    NextCheck: "accounts.accountInfo.nextCheck",
    SomeChecksMessage: "accounts.accountInfo.someChecksMessage",
    YouCanPrintMessage: "accounts.accountInfo.youCanPrintMessage",
    AlsoInclude: "accounts.accountInfo.alsoInclude",
    LearnMore: "accounts.accountInfo.learnMore",
    BankName: "accounts.accountInfo.bankName",
    AccountOwnerName: "accounts.accountInfo.accountOwnerName",
    BankAccountContactInfo: "accounts.accountInfo.bankAccountContactInfo",
    PayToTheOrderOf: "accounts.accountInfo.payToTheOrderOf",
    Dollars: "accounts.accountInfo.dollars",
    AuthorizedSignature: "accounts.accountInfo.authorizedSignature"
  },
  AccountClass: {
    ASSET: "accounts.accountClass.asset",
    LIABILITY: "accounts.accountClass.liability",
    EQUITY: "accounts.accountClass.equity",
    REVENUE: "accounts.accountClass.revenue",
    EXPENSE: "accounts.accountClass.expense"
  },
  AccountType: {
    AccountsReceivable: "common.enums.accountType.ASSET_ACCOUNTS_RECEIVABLE",
    OtherCurrentAssets: "common.enums.accountType.ASSET_OTHER_CURRENT_ASSETS",
    AssetBank: "common.enums.accountType.ASSET_BANK",
    FixedAssets: "common.enums.accountType.ASSET_FIXED_ASSETS",
    OtherAsset: "common.enums.accountType.ASSET_OTHER_ASSETS",
    LiabilityAccountsPayable: "common.enums.accountType.LIABILITY_ACCOUNTS_PAYABLE",
    LiabilityCreditCard: "common.enums.accountType.LIABILITY_CREDIT_CARD",
    LiabilityOtherCurrentLiabiliy: "common.enums.accountType.LIABILITY_OTHER_CURRENT_LIABILIY",
    LiabilityLongTermLiability: "common.enums.accountType.LIABILITY_LONG_TERM_LIABILITY",
    Equity: "common.enums.accountType.EQUITY_EQUITY",
    RevenueIncome: "common.enums.accountType.REVENUE_INCOME",
    RevenueOtherIncome: "common.enums.accountType.REVENUE_OTHER_INCOME",
    Expense: "common.enums.accountType.EXPENSE_EXPENSE",
    OtherExpense: "common.enums.accountType.EXPENSE_OTHER_EXPENSE",
    ExpenseCogs: "common.enums.accountType.EXPENSE_COGS"
  },
  DefaultAccountType: {
    AccountsReceivable: "common.enums.defaultAccountType.ACCOUNTS_RECEIVABLE",
    AccountsPayable: "common.enums.defaultAccountType.ACCOUNTS_PAYABLE",
    UnappliedLeasePaymentsIncome: "common.enums.defaultAccountType.UNAPPLIED_LEASE_PAYMENTS_INCOME",
    UnappliedBillpaymentsExpense: "common.enums.defaultAccountType.UNAPPLIED_BILL_PAYMENTS_EXPENSE",
    UndepositedFunds: "common.enums.defaultAccountType.UNDEPOSITED_FUNDS",
    LateFees: "common.enums.defaultAccountType.LATE_FEES",
    ManagementFees: "common.enums.defaultAccountType.MANAGEMENT_FEES",
    RentalIncome: "common.enums.defaultAccountType.RENTAL_INCOME",
    SecurityDeposit: "common.enums.defaultAccountType.SECURITY_DEPOSIT",
    ApplicationFee: "common.enums.defaultAccountType.APPLICATION_FEE",
    OperatingAccount: "common.enums.defaultAccountType.OPERATING_ACCOUNT",
    TrustAccount: "common.enums.defaultAccountType.TRUST_ACCOUNT",
    OwnerContribution: "common.enums.defaultAccountType.OWNER_CONTRIBUTION",
    OwnerDistribution: "common.enums.defaultAccountType.OWNER_DISTRIBUTION",
    OpeningBalance: "common.enums.defaultAccountType.OPENING_BALANCE",
    merchantReserves: "common.enums.defaultAccountType.MERCHANT_RESERVES",
    clearingAccount: "common.enums.defaultAccountType.CLEARING_ACCOUNT",
    merchantPlatformFees: "common.enums.defaultAccountType.MERCHANT_PLATFORM_FEES",
    merchantReturnedPayment: "common.enums.defaultAccountType.MERCHANT_RETURNED_PAYMENT",
    application_fee_expense: "common.enums.defaultAccountType.APPLICATION_FEE_EXPENSE",
    convenience_fees: "common.enums.defaultAccountType.CONVENIENCE_FEES"
  },
  ChartOfAccounts: {
    HelpPanel: {
      Description: "accounts.chartOfAccounts.helpPanel.description",
      LearnMoreChartOfAccounts: "accounts.chartOfAccounts.helpPanel.learnMoreChartOfAccounts",
      SetUpCharts: "accounts.chartOfAccounts.helpPanel.setUpCharts",
      ChangeDefault: "accounts.chartOfAccounts.helpPanel.changeDefault",
      DialogTitle: "accounts.chartOfAccounts.helpPanel.dialogTitle"
    },
    DownloadExcel: "accounts.chartOfAccounts.downloadExcel",
    CompanyDefaultAccount: "accounts.chartOfAccounts.companyDefaultAccount",
    SearchAccounts: "accounts.chartOfAccounts.searchAccounts",
    DefaultAccountFor: "accounts.chartOfAccounts.defaultAccountFor",
    RunReport: "accounts.chartOfAccounts.runReport",
    MakeInactive: "accounts.chartOfAccounts.makeInactive",
    ViewRegister: "accounts.chartOfAccounts.viewRegister",
    NewAccount: "accounts.chartOfAccounts.newAccount",
    EditAccount: "accounts.chartOfAccounts.editAccount",
    AccountInfo: "accounts.chartOfAccounts.accountInfo",
    AccountName: "accounts.chartOfAccounts.accountName",
    SubAccountDescription: "accounts.chartOfAccounts.subAccountDescription",
    ParentAccount: "accounts.chartOfAccounts.parentAccount",
    ActiveAccount: "accounts.chartOfAccounts.activeAccount",
    OpeningBalances: "accounts.chartOfAccounts.openingBalances",
    OpeningBalancesTitle: "accounts.chartOfAccounts.openingBalancesTitle",
    OpeningBalancesDescription: "accounts.chartOfAccounts.openingBalancesDescription",
    AccountingStartDate: "accounts.chartOfAccounts.accountingStartDate",
    AccountBalanceDescriptionPart1: "accounts.chartOfAccounts.accountBalanceDescriptionPart1",
    AccountBalanceDescriptionPart2: "accounts.chartOfAccounts.accountBalanceDescriptionPart2",
    BalanceByPropertyTitle: "accounts.chartOfAccounts.balanceByPropertyTitle",
    BalanceByPropertyDescription: "accounts.chartOfAccounts.balanceByPropertyDescription",
    BalanceOn: "accounts.chartOfAccounts.balanceOn",
    RemainingBalance: "accounts.chartOfAccounts.remainingBalance",
    LeaseOptions: "accounts.chartOfAccounts.leaseOptions",
    LeaseChargeItem: "accounts.chartOfAccounts.leaseChargeItem",
    LeaseChargeItemDescription: "accounts.chartOfAccounts.leaseChargeItemDescription",
    LeaseDeposits: "accounts.chartOfAccounts.leaseDeposits",
    LeaseDepositsDescription: "accounts.chartOfAccounts.leaseDepositsDescription",
    LeaseStatementMemo: "accounts.chartOfAccounts.leaseStatementMemo",
    LeaseStatementMemoDescription: "accounts.chartOfAccounts.leaseStatementMemoDescription",
    DefaultLeaseMemo: "accounts.chartOfAccounts.defaultLeaseMemo",
    DoorLoopPayments: "accounts.chartOfAccounts.doorLoopPayments",
    InvalidOpeningBalanceByProperty: "accounts.chartOfAccounts.invalidOpeningBalanceByProperty",
    LoadingText: "accounts.chartOfAccounts.loadingText",
    ErrorText: "accounts.chartOfAccounts.errorText",
    SuccessText: "accounts.chartOfAccounts.successText",
    OpeningBalancesExists: "accounts.chartOfAccounts.openingBalancesExists",
    OpeningBalancesExistsDescription: "accounts.chartOfAccounts.openingBalancesExistsDescription",
    CantEditSystemAccountTitle: "accounts.chartOfAccounts.cantEditSystemAccountTitle",
    cantEditSystemAccountsubTitle: "accounts.chartOfAccounts.cantEditSystemAccountsubTitle"
  },
  CompanyName: "accounts.companyName",
  EIN: "accounts.ein",
  MerchantAccountNotFound: "accounts.merchantAccountNotFound",
  StripeNoActionRequiredTitle: "accounts.stripeNoActionRequiredTitle",
  StripeNoActionRequiredContent: "accounts.stripeNoActionRequiredContent",
  StripeApplyNowDialogTitle: "accounts.stripeApplyNowDialogTitle",
  StripeActionRequired: "accounts.stripeActionRequired",
  StripeUpdateInformation: "accounts.stripeUpdateInformation",
  StripeVerifyInformation: "accounts.stripeVerifyInformation",
  Stripe4digitsValidationError: "accounts.stripe4digitsValidationError",
  StripeApplyNowContentI: "accounts.stripeApplyNowContentI",
  StripeApplyNowContentII: "accounts.stripeApplyNowContentII",
  StripeApplyNowContentIII: "accounts.stripeApplyNowContentIII",
  StripeApplyNowContentIV: "accounts.stripeApplyNowContentIV",
  StripeApplyNowContentV: "accounts.stripeApplyNowContentV",
  StripeApplyNowContentCountryAndCurrency: "accounts.stripeApplyNowContentCountryAndCurrency",
  StripeActionRequiredNeedAssistance: "accounts.stripeActionRequiredNeedAssistance",
  StripeActionRequiredSendUsAMessage: "accounts.stripeActionRequiredSendUsAMessage",
  StripeActionRequiredEINBulletI: "accounts.stripeActionRequiredEINBulletI",
  StripeActionRequiredEINBulletIIPartI: "accounts.stripeActionRequiredEINBulletIIPartI",
  StripeActionRequiredEINBulletIIPartII: "accounts.stripeActionRequiredEINBulletIIPartII",
  StripeActionRequiredEINBulletIIPartIII: "accounts.stripeActionRequiredEINBulletIIPartIII",
  StripeActionRequiredEINBulletIII: "accounts.stripeActionRequiredEINBulletIII",
  StripeActionRequiredBankAccountContentI: "accounts.stripeActionRequiredBankAccountContentI",
  StripeActionRequiredBankAccountContentII: "accounts.stripeActionRequiredBankAccountContentII",
  StripeActionRequiredBankAccountContentIII: "accounts.stripeActionRequiredBankAccountContentIII",
  StripeActionRequiredOtherPayrixContentI: "accounts.stripeActionRequiredOtherPayrixContentI",
  StripeActionRequiredOtherPayrixContentII: "accounts.stripeActionRequiredOtherPayrixContentII",
  StripeActionRequiredOtherStripeContentI: "accounts.stripeActionRequiredOtherStripeContentI",
  StripeActionRequiredOtherStripeContentII: "accounts.stripeActionRequiredOtherStripeContentII",
  StripeActionRequiredOtherBulletI: "accounts.stripeActionRequiredOtherBulletI",
  StripeActionRequiredOtherBulletII: "accounts.stripeActionRequiredOtherBulletII",
  StripeUpdateMerchantAccount: "accounts.stripeUpdateMerchantAccount",
  IdentityVerification: "accounts.identityVerification",
  IdentityVerificationDescription: "accounts.identityVerificationDescription",
  OwnerListDescription: "accounts.ownerListDescription",
  DriversLicense: "accounts.driversLicense",
  PleaseNote: "accounts.pleaseNote",
  SendLinkMessage: "accounts.sendLinkMessage",
  VerifyIdentity: "accounts.verifyIdentity",
  IdentityVerificationSubmitted: "accounts.identityVerificationSubmitted",
  IdentityVerificationSubmittedDescription: "accounts.identityVerificationSubmittedDescription",
  IdentityVerificationRequired: "accounts.identityVerificationRequired",
  IdentityVerificationFailed: "accounts.identityVerificationFailed",
  IdentityVerificationFailedDescription: "accounts.identityVerificationFailedDescription",
  PleaseContactCustomerSupport: "accounts.pleaseContactCustomerSupport",
  CreateNewAccountTitleOption: "accounts.createNewAccountTitleOption",
  CreateNewAccountOption: "accounts.createNewAccountOption",
  AdjustOpeningBalance: "accounts.adjustOpeningBalance",
  AdjustOpeningBalanceDescription: "accounts.adjustOpeningBalanceDescription",
  PayoutBankAccount: "accounts.payoutBankAccount",
  MaxPaymentLimit: "accounts.maxPaymentLimit",
  MaxMonthlyTotal: "accounts.maxMonthlyTotal",
  MaxMonthlyPaymentPerLease: "accounts.maxMonthlyPaymentPerLease",
  MonthlyUsage: "accounts.monthlyUsage",
  MaximumReceivableForLease: "accounts.maximumReceivableForLease",
  MaximumReceivableForCard: "accounts.maximumReceivableForCard",
  MonthlyLimitsBreakdown: "accounts.monthlyLimitsBreakdown"
};

export default Accounts;
