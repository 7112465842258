import { useMemo } from "react";

import type { HasTenantsAutoPaymentParams } from "./hasTenantsAutoPaymentParams";
import { TenantPortalAccessObjectPath } from "./tenantPortalAccessObjectPath";

export const useTenantPortalAccessObjectPath = ({
  leaseId,
  propertyId
}: HasTenantsAutoPaymentParams): TenantPortalAccessObjectPath =>
  useMemo(() => {
    if (leaseId) {
      return TenantPortalAccessObjectPath.Lease;
    }

    if (propertyId) {
      return TenantPortalAccessObjectPath.Property;
    }

    return TenantPortalAccessObjectPath.Company;
  }, [leaseId, propertyId]);
