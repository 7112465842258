"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsITIN = IsITIN;
const class_validator_1 = require("class-validator");
const itinRegex = /^9\d{2}-?((5[0-9]|6[0-5])|(8[3-8])|(9[0-2])|(9[4-9]))-?\d{4}$/;
function IsITIN(validationOptions) {
    /**
     * ITIN - Individual Taxpayer Identification Number
     * @source https://regexlib.com/REDetails.aspx?regexp_id=1919
     *
     * __IMPORTANT NOTE:__
     *
     * TIN Numbers are constantly changing by the IRS.
     * For example: per IRS revision 6-2019 some middle digits have expired.
     * TINs with the middle digits of 73, 74, 75, 76, 77, 81 or 82 expired.
     * Middle digits 70, 71, 72 & 80 expired in 2017. Those with 78 and 79 expired in 2016.
     * @link https://www.irs.gov/pub/irs-access/p5259_accessible.pdf
     *
     * If a customer complains about their TIN being invalid, they should check
     * if their TIN is up-to-date.
     **/
    return function (object, propertyName) {
        (0, class_validator_1.registerDecorator)({
            name: "isITIN",
            target: object.constructor,
            propertyName,
            constraints: [],
            options: validationOptions,
            validator: {
                validate(value, args) {
                    return Boolean(value) && itinRegex.test(value);
                }
            }
        });
    };
}
