"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsSSN = IsSSN;
const class_validator_1 = require("class-validator");
function IsSSN(validationOptions) {
    const ssnRegex = new RegExp("^(?!(000|9))\\d{3}-(?!00)\\d{2}-(?!0000)\\d{4}$|^(?!(000|9))\\d{3}(?!00)\\d{2}(?!0000)\\d{4}$");
    return function (object, propertyName) {
        (0, class_validator_1.registerDecorator)({
            name: "isSSN",
            target: object.constructor,
            propertyName,
            constraints: [],
            options: validationOptions,
            validator: {
                validate(value, args) {
                    if (!value)
                        return false;
                    return ssnRegex.test(value);
                }
            }
        });
    };
}
