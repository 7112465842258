const OtherTransactions = {
  BankTransfer: {
    TransferDate: "otherTransactions.bankTransfer.transferDate",
    EntryDate: "otherTransactions.bankTransfer.entryDate",
    TransferFrom: "otherTransactions.bankTransfer.transferFrom",
    TransferTo: "otherTransactions.bankTransfer.transferTo",
    AmountToTransfer: "otherTransactions.bankTransfer.amountToTransfer",
    LoadingText: "otherTransactions.bankTransfer.loadingText",
    CreateTransferSuccessText: "otherTransactions.bankTransfer.createTransferSuccessText",
    NoPropertySelectionText: "otherTransactions.bankTransfer.noPropertySelectionText",
    UpdatingBankTransfer: "otherTransactions.bankTransfer.updatingBankTransfer",
    SourceAccount: "otherTransactions.bankTransfer.sourceAccount",
    SourceProperty: "otherTransactions.bankTransfer.sourceProperty",
    ReceivingAccount: "otherTransactions.bankTransfer.receivingAccount",
    ReceivingProperty: "otherTransactions.bankTransfer.receivingProperty",
    ClearingAccount: "otherTransactions.bankTransfer.clearingAccount",
    ZeroAmountTransferWarning: "otherTransactions.bankTransfer.zeroAmountTransferWarning",
    DifferentOwnershipTransferWarning: "otherTransactions.bankTransfer.differentOwnershipTransferWarning"
  },
  GeneralEntry: {
    GeneralEntryTitle: "otherTransactions.generalEntry.generalEntryTitle",
    Debits: "otherTransactions.generalEntry.debits",
    DebitEqualsCreditsValidationMessage: "otherTransactions.generalEntry.debitEqualsCreditsValidationMessage",
    MustContainAtLeast2LinesValidationMessage:
      "otherTransactions.generalEntry.mustContainAtLeast2LinesValidationMessage",
    LoadingTransactionData: "otherTransactions.generalEntry.loadingTransactionData",
    LoadingTransactionDataSuccessText: "otherTransactions.generalEntry.loadingTransactionDataSuccessText"
  },
  BankDeposit: {
    DialogTitle: "otherTransactions.bankDeposit.dialogTitle",
    EditBankDeposit: "otherTransactions.bankDeposit.editBankDeposit",
    InstructionsText: "otherTransactions.bankDeposit.instructionsText",
    LoadingUndepositEntries: "otherTransactions.bankDeposit.loadingUndepositEntries",
    FailedToLoadUndepositEntries: "otherTransactions.bankDeposit.failedToLoadUndepositEntries",
    LoadUndepositEntriesSuccessText: "otherTransactions.bankDeposit.loadUndepositEntriesSuccessText",
    UndepositedFundsFor: "otherTransactions.bankDeposit.undepositedFundsFor",
    DepositDetailsFor: "otherTransactions.bankDeposit.depositDetailsFor",
    OtherDepositItems: "otherTransactions.bankDeposit.otherDepositItems",
    TotalDeposit: "otherTransactions.bankDeposit.totalDeposit",
    UpdatingDeposit: "otherTransactions.bankDeposit.updatingDeposit",
    InvalidLinkDeposit: "otherTransactions.bankDeposit.invalidLinkDeposit",
    PrintDepositSlip: "otherTransactions.bankDeposit.printDepositSlip"
  }
};

export default OtherTransactions;
