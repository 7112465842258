import React from "react";
import { useTranslation } from "react-i18next";

import Text from "DLUI/text";

import AppStrings from "../../../../../../../locale/keys";

const StripeNeedAssistance = () => {
  const { t } = useTranslation();

  const onClick = () => {
    if (window.Intercom) {
      window.Intercom("showNewMessage");
    }
  };

  return (
    <Text fontSize={14}>
      {t(AppStrings.Accounts.StripeActionRequiredNeedAssistance)}

      <a style={{ paddingLeft: 4, cursor: "pointer", textDecoration: "underline" }} color={"inherit"} onClick={onClick}>
        {t(AppStrings.Accounts.StripeActionRequiredSendUsAMessage)}
      </a>
    </Text>
  );
};

export { StripeNeedAssistance };
