import { DataCy, LeaseTenantStatus, ReportId, ReportPermission } from "@doorloop/dto";
import { Routes } from "components/appRouter";
import AppStrings from "locale/keys";
import type { ReportRoutes } from "utils/navigation";
import { NavigationManager } from "utils/navigation";
import type { ReportScreenItemType } from "DLUI/report/screen/reportScreenItemType";
import { filterObjectsByUserType } from "utils/userTypesUtils";

const buildReportHandler = (pathname: ReportRoutes, filter_status?: LeaseTenantStatus) => (filter_property?: string) =>
  NavigationManager.runReport(pathname, {
    filter_property,
    filter_status
  });

const items: ReportScreenItemType[] = [
  {
    description: AppStrings.Reports.ReportsScreen.CurrentTenants,
    onPress: buildReportHandler(Routes.TENANTS_REPORT, LeaseTenantStatus.CURRENT),
    permission: ReportPermission.tenants,
    id: ReportId.CURRENT_TENANTS,
    dataCy: DataCy.reports.screenItems.tenants.currentTenants
  },
  {
    description: AppStrings.Reports.ReportsScreen.FutureTenants,
    onPress: buildReportHandler(Routes.TENANTS_REPORT, LeaseTenantStatus.FUTURE),
    permission: ReportPermission.tenants,
    id: ReportId.FUTURE_TENANTS,
    dataCy: DataCy.reports.screenItems.tenants.futureTenants
  },
  {
    description: AppStrings.Reports.ReportsScreen.PreviousTenants,
    onPress: buildReportHandler(Routes.TENANTS_REPORT, LeaseTenantStatus.PAST),
    permission: ReportPermission.tenants,
    id: ReportId.PREVIOUS_TENANTS,
    dataCy: DataCy.reports.screenItems.tenants.previousTenants
  },
  {
    description: AppStrings.Reports.ReportsScreen.TenantVehicles,
    onPress: buildReportHandler(Routes.TENANT_VEHICLES_REPORT, LeaseTenantStatus.CURRENT),
    permission: ReportPermission.tenants,
    id: ReportId.TENANT_VEHICLES,
    dataCy: DataCy.reports.screenItems.tenants.tenantVehiclesReport
  },
  {
    description: AppStrings.Reports.ReportsScreen.TenantPets,
    onPress: buildReportHandler(Routes.TENANTS_PETS_REPORT, LeaseTenantStatus.CURRENT),
    permission: ReportPermission.tenants,
    id: ReportId.TENANT_PETS,
    dataCy: DataCy.reports.screenItems.tenants.tenantPetsReport
  }
];

const getTenantItems = (isHOAUser: boolean): ReportScreenItemType[] => {
  if (isHOAUser) {
    return filterObjectsByUserType(items, "HOAUser");
  }

  return items;
};

export default getTenantItems;
