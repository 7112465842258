import type { PropsWithChildren } from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";

export const AppHelmet = ({ children }: PropsWithChildren<unknown>) => (
  <HelmetProvider>
    <AwsWafScript />
    <ProfitWellScript />
    <GoogleTagScript />
    {children}
  </HelmetProvider>
);

const AwsWafScript = () => (
  <Helmet>
    <script
      type="text/javascript"
      src="https://941a25c0a60d.edge.sdk.awswaf.com/941a25c0a60d/4e312c840398/challenge.js"
      async
    ></script>
  </Helmet>
);

const GoogleTagScript = () => (
  <Helmet>
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-CSHBWMC49K"></script>
    <script>
      {`
    window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", "G-CSHBWMC49K");
        gtag("config", "UA-150888816-1")
        `}
    </script>
  </Helmet>
);

const ProfitWellScript = () => (
  <Helmet>
    <script data-pw-auth="36c4cc764885b5f99b48ca0007cb47ba" id="profitwell-js">
      {`
      (function (i, s, o, g, r, a, m) {
        i[o] =
          i[o] ||
          function () {
            (i[o].q = i[o].q || []).push(arguments);
          };
        a = s.createElement(g);
        m = s.getElementsByTagName(g)[0];
        a.async = 1;
        a.src = r + "?auth=" + s.getElementById(o + "-js").getAttribute("data-pw-auth");
        m.parentNode.insertBefore(a, m);
      })(window, document, "profitwell", "script", "https://public.profitwell.com/js/profitwell.js");
      `}
    </script>
  </Helmet>
);
