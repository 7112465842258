import clsx from "clsx";
import type { EditorThemeClasses } from "lexical/LexicalEditor";

import makeStyles from "./styles";

export interface UseRichTextEditorThemeProps {
  theme?: EditorThemeClasses;
}

type UseRichTextEditorTheme = (props: UseRichTextEditorThemeProps) => EditorThemeClasses;

const useRichTextEditorTheme: UseRichTextEditorTheme = ({ theme }) => {
  const classes = makeStyles();

  return {
    ...theme,
    text: {
      ...theme?.text,
      bold: clsx([classes.text, classes.bold, theme?.text?.bold]),
      italic: clsx([classes.text, classes.italic, theme?.text?.italic]),
      underline: clsx([classes.text, classes.underline, theme?.text?.underline]),
      strikethrough: clsx([classes.text, classes.strikethrough, theme?.text?.strikethrough]),
      underlineStrikethrough: clsx([classes.text, classes.underlineStrikethrough, theme?.text?.underlineStrikethrough])
    },
    link: clsx([classes.text, classes.link, theme?.link]),
    paragraph: clsx([classes.text, classes.paragraph, theme?.paragraph]),
    list: {
      ...theme?.list,
      nested: {
        ...theme?.list?.nested,
        listitem: clsx([classes.text, theme?.list?.nested?.listitem])
      },
      ol: clsx([classes.text, theme?.list?.ol]),
      ul: clsx([classes.text, theme?.list?.ul]),
      listitem: clsx([classes.text, theme?.list?.listitem])
    },
    heading: {
      ...theme?.heading,
      h1: clsx([classes.text, theme?.heading?.h1]),
      h2: clsx([classes.text, theme?.heading?.h2]),
      h3: clsx([classes.text, theme?.heading?.h3]),
      h4: clsx([classes.text, theme?.heading?.h4]),
      h5: clsx([classes.text, theme?.heading?.h5]),
      h6: clsx([classes.text, theme?.heading?.h6])
    }
  };
};

export { useRichTextEditorTheme };
