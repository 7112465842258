import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useSearchParamsSetter } from "./useSearchParamsSetter";

export function useSearchParams(): readonly [URLSearchParams, ReturnType<typeof useSearchParamsSetter>] {
  const setSearchParams = useSearchParamsSetter();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  return [searchParams, setSearchParams] as const;
}
