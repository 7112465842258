import React, { useEffect, useMemo, useState } from "react";
import { Grid } from "@material-ui/core";
import { Routes } from "components/appRouter";
import Screen from "DLUI/screen";
import { BackgroundImageView, View } from "DLUI/view";
import { useLocation } from "react-router-dom";
import MarketingViewSeenOn from "screens/demo/marketingViewSeenOn";
import styles from "./styles";
import "./styles.css";
import { AppLayouts } from "../../../../contexts/appLayoutContext";
import clsx from "clsx";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { ProdHelmet } from "@/components/helmets/prodHelmet";

interface ComponentProps {
  children: React.ReactNode;
  documentTitle?: string;
  hideMarketingView?: boolean;
  gap?: number;
}

export const SignupFrameMaxHeight = 700;
export const SignupFrameMaxWidth = 520;

export const ScheduleFrameMaxHeight = 700;
const ScheduleFrameMaxWidth = 1100;

const DemoSignupLayout = ({ children, documentTitle, hideMarketingView, gap }: ComponentProps) => {
  const classes = styles();
  const location = useLocation<any>();
  const { isTabletOrMobile } = useResponsiveHelper();

  const hotjarScriptUrl = useMemo(() => {
    let hotjarScriptUrl = `${window.location.protocol}//${window.location.hostname}`;
    if (window.location.port) {
      hotjarScriptUrl += ":" + window.location.port;
    }
    hotjarScriptUrl += "/scripts/hotjar.js";
    return hotjarScriptUrl;
  }, []);

  useEffect(() => {
    location.state = {};
  }, []);

  const [frameMaxWidth, setFrameMaxWidth] = useState(SignupFrameMaxWidth);
  const [FrameMaxHeight, setFrameMaxHeight] = useState(SignupFrameMaxHeight);

  useEffect(() => {
    if (isTabletOrMobile) {
      setFrameMaxWidth(window.innerWidth);
      setFrameMaxHeight(window.innerHeight);
    } else if (location.pathname === Routes.DEMO_SCHEDULE) {
      setTimeout(() => {
        setFrameMaxWidth(ScheduleFrameMaxWidth);
        setFrameMaxHeight(ScheduleFrameMaxHeight);
      }, 300);
    }
  }, []);

  const renderMarketingView = () => {
    if (location.pathname.indexOf(Routes.DEMO_SCHEDULE) > -1 || hideMarketingView) {
      return null;
    }
    return <MarketingViewSeenOn />;
  };

  const renderBackgroundLayer = () => {
    if (isTabletOrMobile) {
      return (
        <BackgroundImageView
          publicFileName={"url(/images/mobile-demo-signup-background.jpg"}
          height={"100vh"}
          backgroundSize={"cover"}
        >
          <View
            style={{
              backgroundColor: "rgba(47,62,131,0.8)"
            }}
            height={"100vh"}
          />
        </BackgroundImageView>
      );
    }
  };

  const renderLayout = () =>
    isTabletOrMobile ? (
      <View height={window.innerHeight} overflow={"scroll"} width={"100%"}>
        <View width={"100%"} alignItems={"center"} flex={1}>
          {children}
          {renderMarketingView()}
        </View>
      </View>
    ) : (
      <View height={"100%"} overflow={"hidden"} className={classes.signup_backgroundImage}>
        <Grid justify={"center"} alignItems={"center"} style={{ height: "100%" }} container>
          <View
            overflow={"scroll"}
            gap={gap ?? 40}
            paddingBottom={40}
            backgroundColor={"white"}
            noWrap
            borderRadius={20}
            maxHeight={FrameMaxHeight < window.innerHeight ? FrameMaxHeight : window.innerHeight - 40}
            width={frameMaxWidth}
            className={clsx([classes.demoSignupLayout, "slide-in-right"])}
          >
            {children}
            {renderMarketingView()}
          </View>
        </Grid>
      </View>
    );

  return (
    <Screen
      renderBackgroundLayer={renderBackgroundLayer}
      hideLeftNavBar
      screenLayout={AppLayouts.FullWidth}
      documentTitle={documentTitle}
      removeScreenPaddings
      screenContentFullHeight
    >
      <ProdHelmet>
        <script src={hotjarScriptUrl} async></script>
      </ProdHelmet>
      {renderLayout()}
    </Screen>
  );
};

export default DemoSignupLayout;
