import AppStrings from "locale/keys";
import Screen from "DLUI/screen";
import { RestrictedPermissionAccess } from "screens/settings/userRoles";
import { ObjectPermission } from "@doorloop/dto";
import { FilterOptions } from "./filterOptions";
import { CommunicationsList } from "screens/communications/communicationsList";
import { NewAnnouncementActionPanel } from "screens/announcements/newAnnouncementActionPanel";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { ArticleIdsEnum, HelpTypeEnum, VideoUrlsEnum } from "DLUI/screen/helpPanel/types";
import { useUserType } from "../../../hooks/useUserType";
import { useScreenFilterObject } from "@/hooks/useScreenFilterObject";

const helpObject: HelpPanelProps = {
  description: AppStrings.Announcements.CommunicationsScreen.HelpPanel.Description,
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Announcements.CommunicationsScreen.HelpPanel.LearnMoreCommunicationsLog,
      articleId: ArticleIdsEnum.COMMUNICATIONS
    },
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Announcements.CommunicationsScreen.HelpPanel.TrackPortalInvite,
      articleId: ArticleIdsEnum.TRACK_PORTAL_INVITES
    },
    {
      type: HelpTypeEnum.WATCH_VIDEO,
      topic: AppStrings.Common.WatchVideo,
      href: VideoUrlsEnum.COMMUNICATIONS_LOG,
      dialogTitle: AppStrings.Announcements.CommunicationsLog
    }
  ]
};
const helpObjectHOA: HelpPanelProps = {
  description: AppStrings.Announcements.CommunicationsScreen.HelpPanel.Description,
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Announcements.CommunicationsScreen.HelpPanel.LearnMoreCommunicationsLog,
      articleId: ArticleIdsEnum.HOA_COMMUNICATIONS_LOG
    },
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Announcements.CommunicationsScreen.HelpPanel.TrackPortalInvite,
      articleId: ArticleIdsEnum.HOA_TRACK_INVITATIONS
    },
    {
      type: HelpTypeEnum.WATCH_VIDEO,
      topic: AppStrings.Common.WatchVideo,
      href: VideoUrlsEnum.COMMUNICATIONS_LOG,
      dialogTitle: AppStrings.Announcements.CommunicationsLog
    }
  ]
};

export const CommunicationsScreen = () => {
  const { isHOAUser } = useUserType();
  const { filterObject, screenProps } = useScreenFilterObject();

  return (
    <Screen
      subTitleText={{ text: AppStrings.Announcements.CommunicationsLog }}
      searchPanelFilterOptions={FilterOptions}
      searchPanelPlaceHolderText={AppStrings.Common.Search}
      ActionPanel={NewAnnouncementActionPanel}
      helpPanel={!isHOAUser ? helpObject : helpObjectHOA}
      requireAuth
      {...screenProps}
    >
      <RestrictedPermissionAccess
        clearance={{
          permission: ObjectPermission.communications,
          field: "viewList"
        }}
        showNoAccess
      >
        <CommunicationsList filterObj={filterObject} />
      </RestrictedPermissionAccess>
    </Screen>
  );
};
