import { propertiesApi } from "api/propertiesApi";
import { settingsApi } from "api/settingsApi";
import type { DialogState } from "DLUI/dialogs/loadingDialog";
import AppStrings from "locale/keys";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { SettingsAnimatedContentDialog, SettingsContentTypes } from "../settingsAnimatedContentDialog";
import {
  initFormValues,
  initPropertySettingsFormValues,
  validateCompanySettingsForm,
  validateForm,
  validatePropertyForm,
  validatePropertySettingsForm
} from "./formikHelper";
import { getRentalApplicationsMenuItems } from "./menuItems";
import { useRentalApplicationEvents } from "../../../../hooks/useRentalApplicationEvents";
import { SegmentEventTypes } from "@doorloop/dto";
import { useRentalApplicationLocale } from "../../../../hooks/useRentalApplicationLocale";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";

interface ComponentProps {
  dialogState: DialogState;
  onClose: () => void;
  onBackdropClick: () => void;
  title?: string;
}

const dialogHeight = getDialogFrameDimension("height", 1100);
const dialogWidth = getDialogFrameDimension("width", 1020);

const RentalApplicationsSettingsDialog: React.FC<ComponentProps> = ({
  dialogState,
  onClose,
  onBackdropClick,
  title
}: ComponentProps) => {
  const { t } = useTranslation();
  const { propertyId } = useParams<any>();
  const rentalApplicationDialogItems = getRentalApplicationsMenuItems();
  const { fireEvent } = useRentalApplicationEvents({ propertyId, useCompanySettings: !propertyId });
  const { isUs } = useRentalApplicationLocale();

  useEffect(() => {
    fireEvent({ eventName: SegmentEventTypes.RentalApplicationUserOpenedSettings });
  }, []);

  const { getApiMethod, updateApiMethod, formValidationMethod, formInitValues } = useMemo(() => {
    let getApiMethod: any = settingsApi.getRentalApplications;
    let updateApiMethod: any = settingsApi.updateRentalApplications;
    let formValidationMethod: any = validateCompanySettingsForm;
    let formInitValues = (values) => initFormValues(values, isUs);

    if (propertyId) {
      getApiMethod = propertiesApi.getRentalApplications;
      updateApiMethod = propertiesApi.updateRentalApplications;
      formValidationMethod = validatePropertySettingsForm;
      formInitValues = (values) => initPropertySettingsFormValues(values, isUs);
    }
    return {
      getApiMethod,
      updateApiMethod,
      formValidationMethod,
      formInitValues
    };
  }, []);

  return (
    <SettingsAnimatedContentDialog
      dialogState={dialogState}
      onClose={onClose}
      sectionItems={rentalApplicationDialogItems}
      disableBackdropClick
      onBackdropClick={onBackdropClick}
      getformikInitialValues={formInitValues}
      formikValidation={propertyId ? validatePropertyForm : validateForm}
      validationMethod={formValidationMethod}
      loadingText={t(AppStrings.Settings.GeneralSettings.UpdatingUserInformation)}
      errorText={t(AppStrings.Settings.GeneralSettings.FailedToLoadUserInformation)}
      successText={t(AppStrings.Settings.GeneralSettings.UpdatingUserInformationSuccessText)}
      getApiMethod={getApiMethod}
      updateApiMethod={updateApiMethod}
      dialogTitle={title || ""}
      type={SettingsContentTypes.RentalApplication}
      propertyId={propertyId}
      dialogWidth={dialogWidth}
      dialogHeight={dialogHeight}
    />
  );
};

export default RentalApplicationsSettingsDialog;
