import type {
  GetAllVendorBillPaymentsQuery,
  GetLeaseOutstandingTransactionsQuery,
  GetLeaseOutstandingTransactionsResponse,
  GetOutstandingTransactionsForPayBillsQuery,
  GetOutstandingTransactionsForVendorBillPaymentQuery,
  VendorBillPaymentDto
} from "@doorloop/dto";
import { ServerRoutes, VendorBillPaymentsDto } from "@doorloop/dto";

import type { ApiResult } from "api/apiResult";
import { accountsApi } from "api/accounts";
import { apiHelper } from "./apiHelper";
import { propertiesApi } from "./propertiesApi";
import { RestApiBase } from "./restApiBase";
import { vendorsApi } from "api/vendorsApi";

class OutstandingTransactionsApi extends RestApiBase<
  GetLeaseOutstandingTransactionsResponse,
  GetLeaseOutstandingTransactionsQuery
> {
  constructor() {
    super(ServerRoutes.LEASE_RECURRING_TRANSACTIONS);
    this.restRoute = ServerRoutes.LEASE_RECURRING_TRANSACTIONS;
  }

  getOutstandingTransactions = async (leaseId: string, leasePaymentId?: string) => {
    const paramsObj: any = {
      leaseId
    };
    if (leasePaymentId) {
      paramsObj["leasePaymentId"] = leasePaymentId;
    }
    return await apiHelper.axiosGet(ServerRoutes.LEASE_PAYMENTS_GET_OUTSTANDING_TRANSACTIONS + "?", paramsObj);
  };
}

export const outstandingTransactionsApi = new OutstandingTransactionsApi();

class VendorBillPayments extends RestApiBase<VendorBillPaymentDto, GetAllVendorBillPaymentsQuery> {
  constructor() {
    super(ServerRoutes.VENDOR_BILL_PAYMENTS);
    this.restRoute = ServerRoutes.VENDOR_BILL_PAYMENTS;
  }

  async voidCheck(id: string): Promise<ApiResult<VendorBillPaymentDto>> {
    return await apiHelper.axiosPost({ url: ServerRoutes.VENDOR_BILL_PAYMENTS_VOID_CHECK.replace(":id", id) });
  }
}

export const vendorBillPaymentsApi = new VendorBillPayments();

class VendorBillPaymentsOutstandingTransactionsApi {
  dictionaries = [propertiesApi, vendorsApi, accountsApi];
  constructor() {}

  loadDictionariesRequiredForGet = async () => await apiHelper.loadDictionariesRequiredForGet(this.dictionaries);

  getOutstandingTransactionsForVendorBillPayment = async (
    query: GetOutstandingTransactionsForVendorBillPaymentQuery
  ) => {
    await this.loadDictionariesRequiredForGet();

    return await apiHelper.axiosGet(
      ServerRoutes.VENDOR_BILL_PAYMENTS_GET_OUTSTANDING_TRANSACTIONS_VENDOR_BILL_PAYMENT,
      query
    );
  };

  getOutstandingTransactionsForPayBills = async (query: GetOutstandingTransactionsForPayBillsQuery) => {
    await this.loadDictionariesRequiredForGet();

    return await apiHelper.axiosGet(ServerRoutes.VENDOR_BILL_PAYMENTS_GET_OUTSTANDING_TRANSACTIONS_PAY_BILL, query);
  };

  createPayments = async (arrVendorBillPayments: VendorBillPaymentDto[]) => {
    const data = new VendorBillPaymentsDto();
    data.vendorBillPayments = arrVendorBillPayments;
    return await apiHelper.axiosPost<VendorBillPaymentsDto[]>({
      url: ServerRoutes.VENDOR_BILL_PAYMENTS_POST_CREATE_MANY + "/",
      data
    });
  };
}

export const vendorBillPaymentsOutstandingTransactionsApi = new VendorBillPaymentsOutstandingTransactionsApi();
