import React from "react";
import { FormikSwitchButton } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import { FastField } from "formik";
import AppStrings from "locale/keys";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";

interface ComponentProps {
  descriptionText?: string;
}
const RentersInsuranceForm: React.FC<ComponentProps> = ({ descriptionText }: ComponentProps) => {
  const { dialogHorizontalPadding } = DialogsHelper();

  return (
    <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding}>
      <View alignItems={"flex-start"}>
        <Text
          value={descriptionText || AppStrings.Properties.Settings.PropertyRentersInsuranceDescription}
          fontSize={16}
          color={"black"}
          maxWidth={550}
          lineHeight={"26px"}
        />
        <SeparationLine marginTop={20} width={"100%"} height={1} />
        <FastField
          component={FormikSwitchButton}
          name={"settings.rentersInsurance.requireRentersInsurance"}
          label={AppStrings.Properties.Settings.RequireRentersInsurance}
          marginTop={20}
        />
      </View>
    </View>
  );
};

export default RentersInsuranceForm;
