"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountingExperienceLevel = void 0;
var AccountingExperienceLevel;
(function (AccountingExperienceLevel) {
    AccountingExperienceLevel["ONE"] = "1";
    AccountingExperienceLevel["TWO"] = "2";
    AccountingExperienceLevel["THREE"] = "3";
    AccountingExperienceLevel["FOUR"] = "4";
    AccountingExperienceLevel["FIVE"] = "5";
    AccountingExperienceLevel["SIX"] = "6";
    AccountingExperienceLevel["SEVEN"] = "7";
    AccountingExperienceLevel["EIGHT"] = "8";
    AccountingExperienceLevel["NINE"] = "9";
    AccountingExperienceLevel["TEN"] = "10";
})(AccountingExperienceLevel || (exports.AccountingExperienceLevel = AccountingExperienceLevel = {}));
