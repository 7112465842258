import type { MenuItem } from "DLUI/dialogs";
import AppStrings from "../../../locale/keys";
import type { FormikProps } from "formik";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import { useCallback } from "react";

const validateDigitalEmail = (userEmail?: string, digitalEmail?: string) =>
  userEmail?.toLowerCase() === digitalEmail?.toLowerCase();

type PromiseValidationResultType = Promise<{ isValid: boolean; errorStepIndex?: number }>;

interface IValidationResult {
  isValid: boolean;
  errorStepIndex?: number;
}

export const useBasePersonValidation = () => {
  const userData = useSelector((state: IRootState) => state.auth.currentLoginResponse);

  const getOutgoingPaymentsIndex = useCallback(
    (dialogItems: MenuItem[]): number =>
      dialogItems.findIndex((menuItem) => menuItem.title === AppStrings.Common.OutgoingPayments.OutgoingPayments),
    []
  );

  const personValidateBase = useCallback(
    async (
      formikRef: FormikProps<any>,
      validationFunc: (formikRef: FormikProps<any>) => PromiseValidationResultType,
      dialogItems: MenuItem[]
    ): Promise<IValidationResult> => {
      const validationResult = await validationFunc(formikRef);
      if (validateDigitalEmail(userData?.loginEmail, formikRef.values?.outgoingEPay?.digitalEmail)) {
        formikRef.setSubmitting(false);
        return { isValid: false, errorStepIndex: getOutgoingPaymentsIndex(dialogItems) };
      }
      return validationResult;
    },
    [userData?.loginEmail]
  );

  return { personValidateBase, getOutgoingPaymentsIndex };
};
