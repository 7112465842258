import type { CSSProperties } from "react";
import React, { useRef, useState } from "react";
import {
  Button as MUIButton,
  ButtonGroup,
  CircularProgress,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import Text from "DLUI/text";

import { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "../dlButton/enums";
import type { DLButtonUIProps } from "../dlButton/buttonUI";
import useStylesButton from "../dlButton/buttonUI/useStylesButton";

interface SplitButtonOption {
  text: string;
  onClick: (saveButtonIndex?: number) => void;
}

interface SplitButtonStyles {
  group?: CSSProperties;
  text?: CSSProperties;
  icon?: CSSProperties;
}

export interface SplitButtonProps extends Omit<DLButtonUIProps, "onClick" | "actionText" | "icons" | "style"> {
  styles?: SplitButtonStyles;
  options: SplitButtonOption[];
  lastSaveButtonIndex?: number;
  variant?: DLButtonVariantsEnum.OUTLINED | DLButtonVariantsEnum.CONTAINED;
  dataCy?: string;
}

const DefaultProps = {
  styles: {
    group: { minWidth: 120 },
    text: { minWidth: "calc(100% - 36px)" }
  }
};

const SplitButton = ({
  variant = DLButtonVariantsEnum.CONTAINED,
  size = DLButtonSizesEnum.LARGE,
  color = DLButtonColorsEnum.PRIMARY,
  options,
  lastSaveButtonIndex,
  styles,
  disabled,
  isLoading,
  dataCy
}: SplitButtonProps) => {
  const refAnchor = useRef(null);

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [indexSelected, setIndexSelected] = useState(lastSaveButtonIndex ?? 0);

  const itemSelected = options[indexSelected];

  const classNames = useStylesButton({ variant, color, size });

  const handleOpenMenu = () => {
    setIsOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setIsOpenMenu(false);
  };

  const handleSelectItem = (index: number) => {
    setIndexSelected(index);
    handleCloseMenu();
  };

  const handleClick = () => {
    itemSelected.onClick(indexSelected);
  };

  return (
    <>
      <ButtonGroup
        innerRef={refAnchor}
        variant={"text"}
        disabled={disabled || isLoading}
        style={{ minWidth: DefaultProps.styles.group.minWidth, ...(styles?.group ?? {}) }}
      >
        <MUIButton
          className={classNames.container}
          style={{ minWidth: DefaultProps.styles.text.minWidth, ...(styles?.text ?? {}) }}
          onClick={handleClick}
          data-cy={dataCy}
        >
          <Text className={classNames.text} bold value={itemSelected.text} />
        </MUIButton>

        <MUIButton className={classNames.container} onClick={handleOpenMenu} style={styles?.icon}>
          {isLoading ? (
            <CircularProgress className={classNames.iconStart} />
          ) : (
            <ArrowDropDownIcon className={classNames.iconStart} />
          )}
        </MUIButton>
      </ButtonGroup>

      <Popper open={isOpenMenu} anchorEl={refAnchor.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={`${option.text}.${index}`}
                      selected={index === indexSelected}
                      onClick={() => handleSelectItem(index)}
                    >
                      <Text value={option.text} />
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default SplitButton;
