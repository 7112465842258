import type { FC } from "react";
import React, { useState } from "react";
import { View } from "DLUI/view";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import type { ManagementFeesCompanySettingsClientDto, ManagementFeesPropertySettingsDto } from "@doorloop/dto";
import { SettingPermission } from "@doorloop/dto";
import { ListingSyndicationDialogFrameHeight } from "screens/settings/listingSyndication/listingSyndicationFormikContextWrapper";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import {
  ManagementFeesDialogFrameHeight,
  ManagementFeesDialogFrameWidth
} from "screens/settings/managementFees/managementFeesFormikContextWrapper";
import ManagementFeesForm from "screens/settings/managementFees/managementFeesForm";
import AppStrings from "../../../../locale/keys";
import type { ApiResult } from "api/apiResult";
import { SettingsReadOnlyScope } from "DLUI/permissionScope/readOnlyScope";

interface Props {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
  propertyId?: string;
  validateForm: (formikRef) => Promise<boolean>;
  onSaveButtonPress?: (values) => void;
  apiMethod: (
    values: ManagementFeesPropertySettingsDto | ManagementFeesCompanySettingsClientDto
  ) => Promise<ApiResult<ManagementFeesPropertySettingsDto | ManagementFeesCompanySettingsClientDto>>;
}

enum DialogViews {
  LOADING,
  FORM
}

export const ManagementFeesSettingsDialog: FC<Props> = ({
  onBackdropClick,
  onClose,
  dialogTitle,
  validateForm,
  apiMethod,
  onSaveButtonPress,
  propertyId
}: Props) => {
  const { t } = useTranslation();
  const formikRef = useFormikContext<ManagementFeesCompanySettingsClientDto>();
  const [dialogView, setDialogView] = useState<DialogViews>(DialogViews.FORM);
  const [errorText, setErrorText] = useState<string>("");
  const [dialogState, setDialogState] = useState(DialogState.Show);

  const renderView = ({ index }) => {
    if (index === DialogViews.FORM) {
      return <ManagementFeesForm property={propertyId} />;
    }
    if (index === DialogViews.LOADING) {
      return (
        <LoadingDialog
          dialogState={dialogState}
          errorText={errorText}
          didPressDismissButton={() => setDialogView(DialogViews.FORM)}
          onRetryButtonPress={didPressSaveButton}
          minHeight={ListingSyndicationDialogFrameHeight}
        />
      );
    }

    return <View />;
  };

  const showErrorMessage = (error: string) => {
    setDialogState(DialogState.Error);
    setErrorText(error);
  };

  const didPressSaveButton = async () => {
    if (await validateForm(formikRef)) {
      if (onSaveButtonPress) {
        onSaveButtonPress(formikRef.values);
      } else {
        setDialogView(DialogViews.LOADING);
        const { status, data, message } = await apiMethod(formikRef.values);
        if (status && data) {
          onClose();
        } else {
          showErrorMessage(message || t(AppStrings.Common.GeneralError));
        }
      }
    }
  };

  return (
    <SettingsReadOnlyScope permission={SettingPermission.managementFees}>
      <DialogFrame
        onCloseButtonClick={onBackdropClick}
        title={dialogTitle}
        width={ManagementFeesDialogFrameWidth}
        height={ManagementFeesDialogFrameHeight}
        renderView={renderView}
        numViews={2}
        activeView={dialogView}
        RenderActionPanelButtons={() => (
          <FormActionButtons
            propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
            propsSubButton={{ onClick: onBackdropClick }}
          />
        )}
        frameType={DialogViews.LOADING === dialogView ? "contentOnly" : "sectionTitleFrame"}
      />
    </SettingsReadOnlyScope>
  );
};
