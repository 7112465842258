import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import MomentUtils from "@date-io/moment";
import type { RecurringTransactionDto } from "@doorloop/dto";
import { DuplicateDialogButtonLabelValues } from "@doorloop/dto";
import {
  createValidator,
  GeneralEntryDto,
  GeneralEntryInfoDto,
  GeneralEntryLineDto,
  LinkedResourceType,
  mathUtils,
  ObjectPermission,
  OnetimeToRecurringMapper,
  RecurringTransactionType,
  SegmentEventTypes
} from "@doorloop/dto";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ApiResult } from "api/apiResult";
import { filesApi } from "api/filesApi";
import { generalEntriesApi } from "api/generalEntriesApi";
import { propertiesApi } from "api/propertiesApi";
import { AddIcon } from "assets/icons";
import { Button } from "DLUI/button";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { LoadingDialog } from "DLUI/dialogs/components/loading";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import { FormikDatePicker, FormikReferenceLabel, ValidationIndicator } from "DLUI/form";
import { Notes } from "DLUI/notes";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, FieldArray, Formik, getIn } from "formik";
import AppStrings from "locale/keys";
import _, { noop } from "lodash";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DialogFrame, { getDialogFrameDimension } from "../../components/dialogFrame";
import GeneralEntryLine from "./generalEntryLine";
import DeleteConfirmation from "DLUI/dialogs/components/deleteConfirmation";
import { v4 as uuid } from "uuid";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import ReconciledNotificationView from "DLUI/dialogs/components/reconciledNotificationView";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { useLastArrayItemRef } from "hooks/useLastArrayItemRef";
import { leasesRecurringTransactionsApi } from "api/leasesApi";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { RecurringDate } from "DLUI/recurringDateForm/recurringDate";
import { useRecurringState } from "hooks/useRecurringState";
import moment from "moment/moment";
import { ListInputsContainer } from "DLUI/listItems";
import { analyticsService } from "../../../../../services/analyticsService";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";
import { QueryParams } from "../../../../../utils/queryParams";
import { DialogHeaderActionButtons } from "DLUI/actionButtons/dialogHeaderActionButtons";
import {
  cleanGeneralEntryDataForDuplicateMode,
  cleanGeneralEntryInfoDataForDuplicateMode,
  cleanRecurringTransactionDataForDuplicateMode,
  DUPLICATE_MODE_QUERY_PARAMS
} from "DLUI/dialogs/duplicateModeHelper";
import { useAnalyticsService } from "hooks/useAnalyticsService";

export interface GeneralEntryDialogProps {
  didFinishOperation: (values: GeneralEntryDto) => void;
  onBackdropClick: () => void;
  dialogTitle?: string;
  editingRecurring?: boolean;
}

let formikGlobalRef: FormikProps<GeneralEntryDto> | null = null;

export const getFormikRef = () => formikGlobalRef;

const onetimeDateFields = ["dueDate", "date"];
const validateGeneralEntry = createValidator(GeneralEntryDto);

const JournalEntryDialogFrameWidth = Math.min(window.innerWidth, 1350);

const CreateDialogHeight = 860;
const AnimationDelay = 300;

const GeneralEntryDialog: React.FC<GeneralEntryDialogProps> = ({
  didFinishOperation,
  onBackdropClick,
  dialogTitle,
  editingRecurring
}) => {
  const { t } = useTranslation();
  const params = useParams<any>();
  const { transactionId: paramsTransactionId } = params;
  const { isMobile, isTabletOrMobile, screenContainerPadding } = useResponsiveHelper();
  const [viewIndex, setViewIndex] = useState(0);
  const queryParams = new QueryParams();
  const queryTransactionId = queryParams.get(DUPLICATE_MODE_QUERY_PARAMS.transactionId) as string | undefined;
  const transactionId = queryTransactionId || paramsTransactionId;
  const editMode = Boolean(transactionId);
  const duplicateMode = Boolean(queryTransactionId);
  const permission: AnyPermissionClearance = {
    permission: ObjectPermission.journalEntries,
    field: editMode ? "edit" : "create"
  };
  const topRowFlexDirection = isMobile ? "column-reverse" : "row";
  const topRowAlignItems = isMobile ? "stretch" : "flex-start";
  const topRowInputPadding = isMobile ? 0 : 20;
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    editMode ? DialogState.Show : DialogState.Hidden
  );
  const [validationMessage, setValidationMessage] = useState<string | undefined>();
  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);
  const [shouldrenderForm, setShouldRenderForm] = useState<boolean>(!editMode);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const [loadingDialogSuccessText, setLoadingDialogSuccessText] = useState<string>(
    t(AppStrings.OtherTransactions.GeneralEntry.LoadingTransactionDataSuccessText)
  );
  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState<string>(
    t(AppStrings.OtherTransactions.GeneralEntry.LoadingTransactionData)
  );
  const [propertyDefaultValue, setPropertyDefaultValue] = useState<string | undefined>();
  const [transactionData, setTransactionData] = useState<GeneralEntryDto | undefined>();
  const [dialogFrameHeight, setDialogFrameHeight] = useState<number>(CreateDialogHeight);

  const { getValidator, getIsRecurring, getRecurringData, RecurringContextWrapper, setRecurringData } =
    useRecurringState(editMode, editingRecurring);

  const { dispatchAnalytics } = useAnalyticsService();

  const isRecurring = () => getIsRecurring();
  const getApi = () => (isRecurring() ? leasesRecurringTransactionsApi : generalEntriesApi);
  const getData = (values: GeneralEntryDto) =>
    isRecurring() ? OnetimeToRecurringMapper.buildRecurring.generalEntry(values, getRecurringData()!) : values;

  const validateForm = createValidator(isRecurring() ? GeneralEntryDto : GeneralEntryInfoDto);

  const linkedResourceType = getIsRecurring()
    ? LinkedResourceType.RecurringTransaction
    : LinkedResourceType.GeneralEntry;

  useEffect(() => {
    if (editMode) {
      loadTransactionData();
    }
    analyticsService.track(SegmentEventTypes.JOURNALENTRYDIALOG_VIEWED);

    return () => {
      formikGlobalRef = null;
    };
  }, [duplicateMode]);

  const duplicateModeTitle = useMemo(
    () =>
      duplicateMode
        ? `${t(AppStrings.OtherTransactions.GeneralEntry.GeneralEntryTitle)} (${t(AppStrings.Common.Duplicated)})`
        : "",
    [duplicateMode, t]
  );

  const showErrorMessage = (errorMessage: unknown) => {
    setLoadingDialogState(DialogState.Error);
    if (typeof errorMessage === "string") {
      setLoadingDialogErrorText(errorMessage);
    } else if (errorMessage instanceof Error) {
      setLoadingDialogErrorText(errorMessage.message);
    } else if (errorMessage instanceof ApiResult) {
      setLoadingDialogErrorText(errorMessage.message);
    } else {
      setLoadingDialogErrorText(t(AppStrings.Common.GeneralError));
    }
  };

  const loadTransactionData = async () => {
    if (editMode && transactionId) {
      setLoadingDialogState(DialogState.Show);
      try {
        const { data, message, status } = await getApi().get(transactionId);
        if (editingRecurring) {
          const recurringTransactionData = data as RecurringTransactionDto;
          setRecurringData(
            duplicateMode
              ? cleanRecurringTransactionDataForDuplicateMode(recurringTransactionData)
              : recurringTransactionData
          );
        }

        if (!status || !data) {
          throw message;
        }
        const isGeneralEntryInfo = "generalEntryInfo" in data;
        const generalEntry = isGeneralEntryInfo ? data.generalEntryInfo : (data as GeneralEntryDto);
        setTimeout(() => {
          if (formikGlobalRef) {
            formikGlobalRef.setFieldValue("memo", data?.memo);
          }
        }, 0);
        const cleanDataForDuplicateMode = isGeneralEntryInfo
          ? cleanGeneralEntryInfoDataForDuplicateMode
          : cleanGeneralEntryDataForDuplicateMode;
        setTransactionData(duplicateMode && generalEntry ? cleanDataForDuplicateMode(generalEntry) : generalEntry);
        setLoadingDialogState(DialogState.Hidden);
        setShouldRenderForm(true);
      } catch (error) {
        showErrorMessage(error);
      }
    }
  };

  const initFormvalues = (): GeneralEntryDto => {
    if (editMode && transactionData) {
      transactionData.lines?.forEach((currentLine) => {
        currentLine.uniqueIndex = uuid();
      });
      return transactionData;
    }

    const generalEntryDto = new GeneralEntryDto();
    const creditLine = new GeneralEntryLineDto();
    creditLine.uniqueIndex = uuid();
    const depositLine = new GeneralEntryLineDto();
    depositLine.uniqueIndex = uuid();
    generalEntryDto.lines = [creditLine, depositLine];
    generalEntryDto.date = moment().format("YYYY-MM-DD").toString();

    return generalEntryDto;
  };

  const isValidForm = async (formikRef: FormikProps<GeneralEntryDto>) => {
    formikRef.setFieldTouched(`property`);
    if (formikRef.values && formikRef.values.lines) {
      formikRef.values.lines.forEach((arrayItem: any, arrayIndex: number) => {
        formikRef.setFieldTouched(`lines[${arrayIndex}].credit`);
        formikRef.setFieldTouched(`lines[${arrayIndex}].debit`);
        formikRef.setFieldTouched(`lines[${arrayIndex}].account`);
      });
    }

    const errors = (await formikRef.validateForm()) as any;
    let recurringIsValid = true;
    const recurringValidator = getValidator();
    const isRecurring = getIsRecurring();

    if (isRecurring && recurringValidator) {
      recurringIsValid = await recurringValidator();
    } else {
      formikRef.setFieldTouched(`date`);
    }

    const { totalDebits, totalCredits } = getTotalAmounts();
    const isValidAmounts = totalDebits === totalCredits;
    if (!isValidAmounts) {
      setValidationMessage(t(AppStrings.OtherTransactions.GeneralEntry.DebitEqualsCreditsValidationMessage));
    }

    const mainDtoErrors = isRecurring ? _.omit(errors, onetimeDateFields) : errors;
    return _.isEmpty(mainDtoErrors) && isValidAmounts && recurringIsValid;
  };

  const formInitialValues = initFormvalues();

  const didPressSaveButton = async () => {
    if (formikGlobalRef !== null) {
      const isValid = await isValidForm(formikGlobalRef);
      if (isValid) {
        const values = formikGlobalRef.values;
        setViewIndex(1);
        setLoadingDialogState(DialogState.Show);
        try {
          const requestData = getData(values);
          const api = getApi();
          const { status, message, data } =
            editMode && !duplicateMode ? await api.update(transactionId, requestData) : await api.create(requestData);

          if (!editMode) {
            analyticsService.track(SegmentEventTypes.PAYABLE_TRANSACTION_CREATED, {
              transactionType: "Journal Entry",
              payeeType: "Owner",
              isRecurring: Boolean(isRecurring()),
              isUnitEmpty: Boolean(values.unit),
              numOfLineItems: _.size(formikGlobalRef.values.lines)
            });
          }

          if (!status) {
            throw message;
          }

          await filesApi.uploadFiles(attachments, data?.id!, linkedResourceType);
          setLoadingDialogState(DialogState.Success);
          didFinishOperation(values);
          formikGlobalRef = null;

          analyticsService.track(SegmentEventTypes.JOURNALENTRYDIALOG_SAVE_CLICKED, {
            lineItemCount: values?.lines?.length,
            method: isRecurring() ? "recurring" : "onetime",
            repeatForeverToggle: getRecurringData()?.noEndDate
          });
        } catch (e) {
          showErrorMessage(e);
        }
      }
    }
  };

  const handleDeleteClick = () => {
    setViewIndex(2);
  };
  const handleDuplicateClick = () => {
    queryParams.set(DUPLICATE_MODE_QUERY_PARAMS.transactionId, transactionId);
    queryParams.historyPush();

    dispatchAnalytics("button_click", {
      label: DuplicateDialogButtonLabelValues.DUPLICATE_JOURNAL_ENTRY
    });
  };

  const renderHeaderActionButtons = () => (
    <DialogHeaderActionButtons
      onDeleteClick={handleDeleteClick}
      onDuplicateClick={handleDuplicateClick}
      clearance={permission}
      hideDeleteButton={duplicateMode || !editMode}
      hideDuplicateButton={duplicateMode || !editMode}
    />
  );

  const renderActionPanelButtons = () => {
    if (viewIndex === 1) {
      return <div />;
    }

    return (
      <FormActionButtons
        clearance={permission}
        propsActionPanel={{
          editMode
        }}
        propsSubButton={{ onClick: onBackdropClick }}
        propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
      />
    );
  };

  const didPressDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(0);
  };

  const onRetryButtonPress = async () => {
    await didPressSaveButton();

    // if (formikGlobalRef !== null) {
    //   createGeneralEntry(formikGlobalRef.values);
    // }
  };

  const onFileReceived = (files: FileListItemProps[]) => {
    setAttachments(files);
  };

  const getTotalAmounts = () => {
    let totalCredits = 0;
    let totalDebits = 0;

    if (formikGlobalRef !== null && formikGlobalRef.values.lines) {
      formikGlobalRef.values.lines.forEach((currentLine: GeneralEntryLineDto) => {
        totalCredits = mathUtils.add(totalCredits, currentLine.credit || 0);
        totalDebits = mathUtils.add(totalDebits, currentLine.debit || 0);
      });
    }
    return { totalCredits, totalDebits };
  };

  const renderAttachments = () => (
    <View justifyContent={"center"} width={"100%"} flex={1} marginBottom={10}>
      <FormAttachments
        onFileReceived={onFileReceived}
        files={attachments}
        editMode={editMode}
        resourceId={transactionId}
        resourceType={linkedResourceType}
      />
    </View>
  );

  const addLine = useCallback(() => {
    !isTabletOrMobile && setDialogFrameHeight(dialogFrameHeight + 100);
    const generalEntryLineDto = new GeneralEntryLineDto();
    generalEntryLineDto.credit = 0;
    generalEntryLineDto.debit = 0;
    generalEntryLineDto.uniqueIndex = uuid();
    const lines = formikGlobalRef?.values.lines || [];
    formikGlobalRef?.setFieldValue("lines", [...lines, generalEntryLineDto]);
  }, [isTabletOrMobile, dialogFrameHeight]);

  const lastLineRef = useLastArrayItemRef<HTMLDivElement>(addLine, []);

  const renderLines = (formik: FormikProps<any>) => (
    <View shouldShow showAnimation={"fade-in"} hideAnimation={"fade-out"}>
      <FieldArray
        name={"lines"}
        render={(arrayHelpers) => {
          if (formik.values && formik.values.lines) {
            const didPressDeleteButton = (lineIndex: number) => {
              const currentLines = getIn(formik.values, "lines");
              if (currentLines.length < 3) {
                setValidationMessage(
                  t(AppStrings.OtherTransactions.GeneralEntry.MustContainAtLeast2LinesValidationMessage)
                );
                return;
              }
              arrayHelpers.remove(lineIndex);
              !isTabletOrMobile && setDialogFrameHeight(dialogFrameHeight - 100);
            };
            const didPressDuplicateButton = (lineIndex: number) => {
              const line = formik.values?.lines?.[lineIndex];
              arrayHelpers.insert(lineIndex + 1, {
                ...line,
                uniqueIndex: uuid()
              });
            };
            const lines = formik.values.lines.map((currentLine: GeneralEntryLineDto, index: number) => (
              <Fragment key={"GEL" + currentLine.uniqueIndex}>
                <GeneralEntryLine
                  domRef={index === formik.values.lines.length - 1 ? lastLineRef : undefined}
                  didPressDeleteButton={didPressDeleteButton}
                  didPressDuplicateButton={didPressDuplicateButton}
                  lineIndex={index}
                  formikRef={formik}
                  onInputChange={onInputChange}
                />
              </Fragment>
            ));
            return (
              <View>
                <ListInputsContainer>{lines}</ListInputsContainer>
                <View>
                  <Button
                    color={"lightBlue"}
                    type={"inlineText"}
                    actionText={AppStrings.Leases.LeaseCharge.AddLineItem}
                    onClick={addLine}
                    LeftIcon={AddIcon}
                    applyColorForIcons
                    iconSize={15}
                  />
                  <View justifyContent={"center"} height={50} marginTop={20}>
                    <FastField component={Notes} height={30} name={"memo"} />
                  </View>
                </View>
              </View>
            );
          }
          return <div />;
        }}
      />
    </View>
  );

  const renderSummaryLine = () => {
    const { totalDebits, totalCredits } = getTotalAmounts();
    return (
      <View marginTop={20} marginBottom={20} flexDirection={"row"}>
        <View
          backgroundColor={"dark"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          height={50}
          width={"100%"}
          flexDirection={"row"}
        >
          <View flexDirection={"row"} flex={1} paddingRight={20} paddingLeft={20}>
            {isTabletOrMobile ? null : <Grid item md={4} />}
            <Grid item xs={6} sm={6} md={4}>
              <View flexDirection={"row"} justifyContent={isMobile ? "flex-start" : "flex-end"}>
                <Text value={AppStrings.Common.ListHeader.TotalDebit} fontSize={16} color={"black"} paddingRight={20} />
                <Text
                  value={String(totalDebits)}
                  fontSize={16}
                  color={"black"}
                  formatType={"currency"}
                  fontWeight={700}
                />
              </View>
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <View flexDirection={"row"} justifyContent={isMobile ? "flex-start" : "flex-end"}>
                <Text
                  value={AppStrings.Common.ListHeader.TotalCredit}
                  fontSize={16}
                  color={"black"}
                  paddingRight={20}
                />
                <Text
                  value={String(totalCredits)}
                  fontSize={16}
                  color={"black"}
                  formatType={"currency"}
                  fontWeight={700}
                />
              </View>
            </Grid>
          </View>
        </View>
      </View>
    );
  };

  const onInputChange = () => {
    setValidationMessage(undefined);
  };

  const renderValidation = () => (
    <View alignItems={"flex-end"}>
      <ValidationIndicator
        shouldShow={validationMessage !== undefined}
        displayText={validationMessage || ""}
        maxWidth={500}
      />
    </View>
  );

  const renderForm = () => (
    <RecurringContextWrapper>
      <Formik initialValues={formInitialValues} onSubmit={noop} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;

          return (
            <RestrictedPermissionAccess clearance={permission} showNoAccess>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <View
                  width={"100%"}
                  flex={1}
                  paddingRight={screenContainerPadding}
                  paddingLeft={screenContainerPadding}
                >
                  <View shouldShow showAnimation={"fade-in"} hideAnimation={"fade-out"} width={"100%"} marginTop={20}>
                    <View width={"100%"} flexDirection="column">
                      <ReconciledNotificationView register={transactionData?.register} />
                      <View flexDirection="row" justifyContent="space-between" gap={"0 20px"} width={"100%"} noWrap>
                        <View flex={"1 1 auto"} minWidth={250} maxWidth={350}>
                          <FormikCachedAsyncAutoComplete
                            label={t(AppStrings.Common.FilterOptions.Property)}
                            uniqueIndex={0}
                            apiHandler={propertiesApi}
                            displayNameKey={"name"}
                            filterFieldName={"filter_text"}
                            filterFieldValue={"name"}
                            selectionFields={["id", "class"]}
                            name={"property"}
                            required
                            defaultValue={propertyDefaultValue}
                            paddingRight={topRowInputPadding}
                            queryParams={{ filter_active: true }}
                          />
                        </View>
                        <View flex={"1 1 auto"} minWidth={105} maxWidth={250}>
                          <FastFieldSafe
                            component={FormikReferenceLabel}
                            name={"reference"}
                            backgroundColor={"dark"}
                            minWidth={105}
                          />
                        </View>
                      </View>
                    </View>
                    <SeparationLine marginTop={20} width={"100%"} height={1} />
                    <RecurringDate type={RecurringTransactionType.GENERAL_ENTRY}>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <FastField
                          component={FormikDatePicker}
                          uniqueKey={"date"}
                          label={AppStrings.OtherTransactions.BankTransfer.EntryDate}
                          name={"date"}
                          noMargin
                          required
                          marginTop={20}
                          paddingRight={topRowInputPadding}
                        />
                      </Grid>
                    </RecurringDate>
                    <SeparationLine marginTop={20} width={"100%"} height={1} />
                    <View>
                      {!isTabletOrMobile && renderItemsHeader()}
                      {renderLines(formik)}
                    </View>
                    <View>{renderSummaryLine()}</View>
                  </View>
                  {renderValidation()}
                  {renderAttachments()}
                </View>
              </MuiPickersUtilsProvider>
            </RestrictedPermissionAccess>
          );
        }}
      </Formik>
    </RecurringContextWrapper>
  );

  const renderView = ({ index }: any) => {
    const onRetryLoadInfoButtonPress = () => {
      loadTransactionData();
    };
    const didPressDismissButton = () => {
      setViewIndex(0);
    };

    if (index === 0) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={onRetryLoadInfoButtonPress}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }

      if (shouldrenderForm) {
        return renderForm();
      }
      return <div />;
    }
    if (index === 1) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={t(AppStrings.OtherTransactions.BankTransfer.LoadingText)}
            errorText={loadingDialogErrorText}
            successText={t(AppStrings.OtherTransactions.BankTransfer.CreateTransferSuccessText)}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
          />
        </View>
      );
    }
    if (index === 2) {
      return (
        <DeleteConfirmation
          apiMethod={getApi()}
          apiToasts={
            isRecurring()
              ? { translationKey: AppStrings.Toasts.custom.recurring[RecurringTransactionType.GENERAL_ENTRY]?.DELETE }
              : undefined
          }
          didPressDismissButton={didPressDismissDeleteButton}
          didFinishOperation={didFinishOperation}
          transactionId={transactionId}
          attachments={attachments}
        />
      );
    }
    return <div />;
  };

  const didPressDismissDeleteButton = () => {
    setViewIndex(0);
  };

  const _onBackdropClick = () => {
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      if (loadingDialogState !== DialogState.Hidden) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }

    return "contentOnly";
  }, [viewIndex, loadingDialogState]);

  const renderItemsHeader = () => {
    if (isMobile) {
      return null;
    }
    return (
      <View height={20} marginTop={20} flexDirection={"row"} width={"calc(100% - 30px)"} paddingLeft={10} gap={40}>
        <Grid item md={2}>
          <Text value={AppStrings.Common.ListHeader.Category} color={"black"} fontSize={16} />
        </Grid>
        <Grid item md={2}>
          <Text value={AppStrings.Common.Unit} color={"black"} fontSize={16} />
        </Grid>
        <Grid item md={2}>
          <Text value={AppStrings.Leases.LeaseCharge.Description} color={"black"} fontSize={16} />
        </Grid>
        <Grid item md={2}>
          <Text value={AppStrings.OtherTransactions.GeneralEntry.Debits} color={"black"} fontSize={16} />
        </Grid>
        <Grid item md={2}>
          <Text value={AppStrings.Leases.NewLease.LeaseRent.Credits} color={"black"} fontSize={16} />
        </Grid>
      </View>
    );
  };

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={duplicateModeTitle || dialogTitle}
      width={JournalEntryDialogFrameWidth}
      height={getDialogFrameDimension("height", dialogFrameHeight)}
      renderView={renderView}
      numViews={3}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      RenderHeaderActionButtons={renderHeaderActionButtons}
      frameType={frameType}
    />
  );
};

export default GeneralEntryDialog;
