import type {
  ConvenienceFeesPolicyDto,
  GetAllBaseQueryResponse,
  GetAllLeasesQuery,
  TenantPortalGetLeaseAccountsReceivableReportQuery,
  TenantPortalLeaseDto
} from "@doorloop/dto";
import { TenantPortalServerRoutes } from "@doorloop/dto";
import { apiHelper } from "../apiHelper";
import type { ApiResult } from "../apiResult";
import { RestApiBaseWithDictionary } from "api/restApiBaseWithDictionary";

export class TenantPortalLeasesApi extends RestApiBaseWithDictionary<TenantPortalLeaseDto, GetAllLeasesQuery> {
  constructor() {
    super(TenantPortalServerRoutes.LEASES);
    this.restRoute = TenantPortalServerRoutes.LEASES;
  }

  async get(id: string): Promise<ApiResult<TenantPortalLeaseDto>> {
    return await apiHelper.axiosGet<TenantPortalLeaseDto>(this.restRoute + "/" + id);
  }

  async getLeaseAccountsReceivableReport(
    query: TenantPortalGetLeaseAccountsReceivableReportQuery
  ): Promise<ApiResult<GetAllBaseQueryResponse<TenantPortalGetLeaseAccountsReceivableReportQuery>>> {
    return await apiHelper.axiosGet<GetAllBaseQueryResponse<TenantPortalGetLeaseAccountsReceivableReportQuery>>(
      TenantPortalServerRoutes.REPORTS_LEASE_ACCOUNTS_RECEIVABLE + "/?",
      query
    );
  }

  async getConvenienceFeesForLease(leaseId: string): Promise<ApiResult<ConvenienceFeesPolicyDto>> {
    return await apiHelper.axiosGet(TenantPortalServerRoutes.GET_CONVENIENCE_FEES_FOR_LEASE, { leaseId });
  }
}

export const tenantPortalLeasesApi = new TenantPortalLeasesApi();
