import React, { useState, useEffect } from "react";
import makeStyles from "./styles";
import clsx from "clsx";
interface ComponentProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const MobileDialog: React.FC<ComponentProps> = ({ open, children }: ComponentProps) => {
  const classes = makeStyles();
  const [showDialog, setShowDialog] = useState<boolean>(open);

  useEffect(() => {
    setShowDialog(open);
  }, [open]);

  return (
    <div
      style={{
        overflowX: "hidden",
        width: window.innerWidth
      }}
      className={clsx([classes.mobileDialog, showDialog ? classes.show : classes.hide])}
    >
      {children}
    </div>
  );
};

export default MobileDialog;
