"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagType = void 0;
var TagType;
(function (TagType) {
    TagType["VENDOR_SERVICE"] = "VENDOR_SERVICE";
    TagType["NOTES_TAG"] = "NOTE_CATEGORY";
    TagType["TASKS_TAG"] = "TASK_CATEGORY";
    TagType["LEAD_SOURCE"] = "LEAD_SOURCE";
    TagType["FILES_TAG"] = "FILE_CATEGORY";
})(TagType || (exports.TagType = TagType = {}));
