import {
  AccountingIcon,
  BadgeIcon,
  CampaignIcon,
  ConvenienceFeeIcon,
  DatePlay,
  EditUserIcon,
  FileIcon,
  GlobeIcon,
  GroupIcon,
  InfoTaxIcon,
  LateFeesIcon,
  LeadsIcon,
  ListingSyndicationIcon,
  LockIcon,
  ManagementFeesSettingsIcon,
  MyTasksIcon,
  OfficeIcon,
  OutgoingPaymentsIcon,
  PaymentAllocationsSettingsIcon,
  QuickbooksIcon,
  RentalApplicationsIcon,
  RentRemindersIcon,
  RequestMaintenanceIcon,
  ResidentPortalIcon,
  TaskPadIcon,
  UsersIcon,
  WorkOrderIcon,
  ZapierIcon
} from "assets/icons";
import type { SettingsElementProps } from "DLUI/settings";
import AppStrings from "locale/keys";
import { NavigationManager } from "utils/navigation";
import { DataCy, ObjectPermission, SettingPermission, SettingsAccessEnum } from "@doorloop/dto";
import { buildViewOrEditSettingsClearances } from "screens/settings/userRoles/clearanceTypes";
import { filterObjectsByUserType } from "../../../../utils/userTypesUtils";
import { IndicationTag } from "DLUI/drawer/types";

const didPressEditPersonalInformation = () => {
  NavigationManager.editPersonalInformation();
};

const didPressEditLoginAndPassword = () => {
  NavigationManager.editLoginAndPassword();
};

const didPressEditCompanyInformation = () => {
  NavigationManager.editCompanyInformation();
};
const didPressEditRegionAndCurrency = () => {
  NavigationManager.editRegionAndCurrency();
};

const didPressCompanyStartDate = () => {
  NavigationManager.editCompanyStartDate();
};

const didPressCommunicationCenter = () => {
  NavigationManager.editCommunicationCenterSettings();
};

const didPressEditDefaultAccounts = () => {
  NavigationManager.editDefaultAccounts();
};

const didPressUserRoles = () => {
  NavigationManager.viewUserRoles();
};

const didPressEditCompanyUsers = () => {
  NavigationManager.viewCompanyUsers();
};

const didPressEditNoteCategories = () => {
  NavigationManager.editNoteCategories();
};

const didPressEditFileCategories = () => {
  NavigationManager.editFileCategories();
};

const didPressEditVendorCategories = () => {
  NavigationManager.editVendorCategories();
};

const didPressEditLeadSource = () => {
  NavigationManager.editLeadSource();
};

const didPressEditPropertyGroups = () => {
  NavigationManager.editPropertyGroups();
};

const didPressEditLatefees = () => {
  NavigationManager.editCompanyLateFees();
};

const didPressEditTenantPortal = () => {
  NavigationManager.editTenantPortal(window.location.pathname);
};

const didPressEditOwnerPortal = (): void => {
  NavigationManager.editOwnerPortal(window.location.pathname);
};

const didPressEditRentReminders = (pathName: string) => {
  NavigationManager.editRentReminders(pathName);
};

const didPressEditPaymentInstructions = (pathName: string) => {
  NavigationManager.editPaymentInstructions(pathName);
};

const didPressEditTenantRequestNotifications = (pathName: string) => {
  NavigationManager.editTenantRequestNotifications(pathName);
};

const didPressEditOwnerRequestNotifications = (pathName: string): void => {
  NavigationManager.editOwnerRequestNotifications(pathName);
};

const didPressViewSubscription = () => {
  NavigationManager.viewSubscriptionDetails();
};

const didPressEditRentalApplication = () => {
  NavigationManager.editRentalApplcaitionsSettings(location.pathname);
};

const onEditConvenienceFees = () => {
  NavigationManager.editConvenienceFeesSettings(location.pathname);
};

const didPressSyncQuickbooks = () => {
  NavigationManager.syncQuickbooksAccount(location.pathname);
};

const didPressListingSyndication = () => {
  NavigationManager.listingSyndicationSettings(location.pathname);
};

const didPressApiKeys = () => {
  NavigationManager.apiKeysSettings(location.pathname);
};

const didPressManagementFees = () => {
  NavigationManager.editManagementFees(location.pathname);
};

const didPressPaymentAllocations = () => {
  NavigationManager.editPaymentAllocations(location.pathname);
};

const didPressOutgoingPaymentsSettings = () => {
  NavigationManager.editOutgoingPaymentsSettings(location.pathname);
};

export const PersonalInfoSettingsItems: SettingsElementProps[] = [
  {
    ElementIcon: BadgeIcon,
    title: AppStrings.Settings.GeneralSettings.PersonalInformationTitle,
    description: [AppStrings.Settings.GeneralSettings.PersonalInformationSubTitle],
    onPress: didPressEditPersonalInformation
  },
  {
    ElementIcon: LockIcon,
    title: AppStrings.Settings.GeneralSettings.LoginAndPasswordTitle,
    description: [AppStrings.Settings.GeneralSettings.LoginAndPasswordSubTitle],
    onPress: didPressEditLoginAndPassword
  }
];

export const getCompanySettingsItems: (
  shouldShowCompanyStart: boolean,
  isHOAUser: boolean,
  isCommunicationsCenterFeatureFlagEnabled?: boolean
) => SettingsElementProps[] = (shouldShowCompanyStart, isHOAUser, isCommunicationsCenterFeatureFlagEnabled) => {
  const companySettingsItems: SettingsElementProps[] = [
    {
      ElementIcon: OfficeIcon,
      title: AppStrings.Settings.GeneralSettings.CompanyInformationTitle,
      description: [AppStrings.Settings.GeneralSettings.CompanyInformationSubTitle],
      clearances: buildViewOrEditSettingsClearances(SettingPermission.companyInformation),
      onPress: didPressEditCompanyInformation
    },
    {
      ElementIcon: GlobeIcon,
      title: AppStrings.Settings.GeneralSettings.RegionAndCurrencyTitle,
      description: [AppStrings.Settings.GeneralSettings.RegionAndCurrencySubTitle],
      clearances: buildViewOrEditSettingsClearances(SettingPermission.regionAndCurrency),
      onPress: didPressEditRegionAndCurrency
    },
    {
      ElementIcon: AccountingIcon,
      title: AppStrings.Settings.GeneralSettings.DefaultAccountsTitle,
      description: [AppStrings.Settings.GeneralSettings.DefaultAccountsSubTitle],
      clearances: buildViewOrEditSettingsClearances(SettingPermission.defaultAccounts),
      onPress: didPressEditDefaultAccounts
    },
    {
      ElementIcon: UsersIcon,
      title: AppStrings.Settings.GeneralSettings.UsersTitle,
      description: [AppStrings.Settings.GeneralSettings.UsersSubTitle],
      clearances: [{ permission: ObjectPermission.users, field: "viewList" }],
      onPress: didPressEditCompanyUsers
    },
    {
      ElementIcon: EditUserIcon,
      title: AppStrings.Settings.GeneralSettings.UserRolesTitle,
      description: [AppStrings.Settings.GeneralSettings.UserRolesSubTitle],
      clearances: [{ permission: ObjectPermission.userRoles, field: "viewList" }],
      onPress: didPressUserRoles
    },
    {
      ElementIcon: WorkOrderIcon,
      title: AppStrings.Common.Subscription.Settings.Header,
      description: [AppStrings.Common.Subscription.Settings.Text],
      clearances: buildViewOrEditSettingsClearances(SettingPermission.doorloopSubscription),
      onPress: didPressViewSubscription
    },
    {
      ElementIcon: QuickbooksIcon,
      title: AppStrings.Settings.GeneralSettings.QuickbooksIntegrationTitle,
      description: [AppStrings.Settings.GeneralSettings.QuickbooksIntegrationSubTitle],
      clearances: buildViewOrEditSettingsClearances(SettingPermission.quickbooks),
      onPress: didPressSyncQuickbooks
    },
    {
      ElementIcon: ListingSyndicationIcon,
      title: AppStrings.Listing.ListingSyndication,
      description: [AppStrings.Listing.ListAndMarketYourVacancies],
      clearances: buildViewOrEditSettingsClearances(SettingPermission.listings),
      onPress: didPressListingSyndication,
      hiddenForUserTypes: ["HOAUser"]
    },
    {
      ElementIcon: ZapierIcon,
      title: AppStrings.Settings.GeneralSettings.ApiKeys.ApiKeysTitle,
      description: [AppStrings.Settings.GeneralSettings.ApiKeys.ApiKeysSubTitle],
      clearances: [{ permission: ObjectPermission.apiKeys, field: "viewList" }],
      onPress: didPressApiKeys
    },
    {
      ElementIcon: OutgoingPaymentsIcon,
      title: AppStrings.Common.OutgoingPayments.OutgoingPayments,
      description: [AppStrings.Common.OutgoingPayments.OutgoingPaymentsSettingsDescription],
      clearances: [
        {
          permission: SettingPermission.outgoingPayments,
          requiredLevel: SettingsAccessEnum.VIEW
        },
        { permission: SettingPermission.outgoingPayments, requiredLevel: SettingsAccessEnum.EDIT }
      ],
      onPress: didPressOutgoingPaymentsSettings
    }
  ];

  shouldShowCompanyStart &&
    companySettingsItems.push({
      ElementIcon: DatePlay,
      title: AppStrings.Settings.GeneralSettings.CompanyStartDate.CompanyStartDateTitle,
      description: [AppStrings.Settings.GeneralSettings.CompanyStartDate.CompanyStartDateSubTitle],
      clearances: buildViewOrEditSettingsClearances(SettingPermission.companyInformation),
      onPress: didPressCompanyStartDate
    });

  if (isCommunicationsCenterFeatureFlagEnabled) {
    companySettingsItems.push({
      ElementIcon: CampaignIcon,
      title: AppStrings.Settings.GeneralSettings.CommunicationCenterTitle,
      description: [AppStrings.Settings.GeneralSettings.CommunicationCenterSubTitle],
      clearances: buildViewOrEditSettingsClearances(SettingPermission.communicationCenter),
      onPress: didPressCommunicationCenter,
      tag: IndicationTag.NEW
    });
  }

  return !isHOAUser ? companySettingsItems : filterObjectsByUserType(companySettingsItems, "HOAUser");
};

export const getRelatedListsSettingsItems: (isHOAUser: boolean) => SettingsElementProps[] = (isHOAUser) => {
  const items: SettingsElementProps[] = [
    {
      ElementIcon: GroupIcon,
      title: AppStrings.Settings.GeneralSettings.PropertyGroupsTitle,
      description: [AppStrings.Settings.GeneralSettings.PropertyGroupsSubTitle],
      clearances: [{ permission: ObjectPermission.propertyGroups, field: "viewList" }],
      onPress: didPressEditPropertyGroups
    },
    {
      ElementIcon: TaskPadIcon,
      title: AppStrings.Settings.GeneralSettings.NoteCategoriesTitle,
      description: [AppStrings.Settings.GeneralSettings.NoteCategoriesSubTitle],
      clearances: [{ permission: ObjectPermission.tags, field: "viewList" }],
      onPress: didPressEditNoteCategories
    },
    {
      ElementIcon: FileIcon,
      title: AppStrings.Settings.GeneralSettings.FileCategoriesTitle,
      description: [AppStrings.Settings.GeneralSettings.FileCategoriesSubTitle],
      clearances: [{ permission: ObjectPermission.tags, field: "viewList" }],
      onPress: didPressEditFileCategories
    },
    {
      ElementIcon: MyTasksIcon,
      title: AppStrings.Settings.GeneralSettings.VendorClassificationsTitle,
      description: [AppStrings.Settings.GeneralSettings.VendorClassificationsSubTitle],
      clearances: [{ permission: ObjectPermission.tags, field: "viewList" }],
      onPress: didPressEditVendorCategories
    },
    {
      ElementIcon: LeadsIcon,
      title: AppStrings.Settings.GeneralSettings.LeadSourcesTitle,
      description: [AppStrings.Settings.GeneralSettings.LeadSourcesSubTitle],
      clearances: [{ permission: ObjectPermission.tags, field: "viewList" }],
      onPress: didPressEditLeadSource,
      hiddenForUserTypes: ["HOAUser"]
    }
  ];

  return !isHOAUser ? items : filterObjectsByUserType(items, "HOAUser");
};

export const getPropertySettingsItems = (isHOAUser: boolean) => {
  const items: SettingsElementProps[] = [
    {
      ElementIcon: ResidentPortalIcon,
      title: AppStrings.Leases.NewLease.LeaseTenants.ResidentPortalAccess,
      description: [AppStrings.Properties.Settings.ResidentPortalDescriptionSubTitle],
      clearances: buildViewOrEditSettingsClearances(SettingPermission.tenantPortal),
      onPress: didPressEditTenantPortal
    },
    {
      ElementIcon: ResidentPortalIcon,
      title: AppStrings.Owners.Screen.OwnerPortal,
      description: [AppStrings.Owners.Screen.EditOwnerPortalSettings],
      clearances: buildViewOrEditSettingsClearances(SettingPermission.ownerPortal),
      onPress: didPressEditOwnerPortal,
      hiddenForUserTypes: ["HOAUser"]
    },
    {
      ElementIcon: RentRemindersIcon,
      pathColor: "none",
      title: AppStrings.Common.RentRemindersTitle,
      description: [AppStrings.Common.RentRemindersTitleDescription],
      clearances: buildViewOrEditSettingsClearances(SettingPermission.rentReminders),
      onPress: didPressEditRentReminders
    },
    {
      ElementIcon: InfoTaxIcon,
      pathColor: "none",
      title: AppStrings.Common.PaymentInstructions,
      description: [AppStrings.Common.PaymentInstructionsSettingsDescription],
      clearances: buildViewOrEditSettingsClearances(SettingPermission.paymentInstructions),
      onPress: didPressEditPaymentInstructions
    },
    {
      ElementIcon: RequestMaintenanceIcon,
      pathColor: "none",
      title: AppStrings.Common.TenantRequestsSettingItemTitle,
      description: [AppStrings.Common.TenantRequestsSettingItemDescription],
      clearances: buildViewOrEditSettingsClearances(SettingPermission.tenantRequests),
      onPress: didPressEditTenantRequestNotifications
    },
    {
      ElementIcon: RequestMaintenanceIcon,
      pathColor: "none",
      title: AppStrings.Common.OwnerRequestsSettingItemTitle,
      description: [AppStrings.Common.OwnerRequestsSettingItemDescription],
      clearances: buildViewOrEditSettingsClearances(SettingPermission.ownerRequests),
      onPress: didPressEditOwnerRequestNotifications,
      hiddenForUserTypes: ["HOAUser"]
    },
    {
      ElementIcon: LateFeesIcon,
      title: AppStrings.Properties.Settings.LateFeesTitle,
      description: [AppStrings.Properties.Settings.LateFeesDescriptionSubTitle],
      clearances: buildViewOrEditSettingsClearances(SettingPermission.lateFees),
      onPress: didPressEditLatefees
    },
    {
      ElementIcon: RentalApplicationsIcon,
      title: AppStrings.Properties.Settings.RentalApplicationsTitle,
      description: [AppStrings.Properties.Settings.RentalApplicationsDescriptionCompanySubTitle],
      onPress: didPressEditRentalApplication,
      clearances: buildViewOrEditSettingsClearances(SettingPermission.rentalApplications),
      hiddenForUserTypes: ["HOAUser"]
    },
    {
      ElementIcon: ConvenienceFeeIcon,
      title: AppStrings.Properties.Settings.ConvenienceFees,
      description: [AppStrings.Properties.Settings.ConvenienceFeesDescriptionCompanySubTitle],
      onPress: onEditConvenienceFees,
      clearances: buildViewOrEditSettingsClearances(SettingPermission.convenienceFees),
      dataCy: DataCy.globalSettings.convenienceFeesSettings.convenienceFeesItem
    },
    {
      ElementIcon: ManagementFeesSettingsIcon,
      title: AppStrings.Settings.GeneralSettings.ManagementFees.ManagementFees,
      description: [AppStrings.Settings.GeneralSettings.ManagementFees.ManagementFeesDescription],
      clearances: buildViewOrEditSettingsClearances(SettingPermission.managementFees),
      onPress: didPressManagementFees
    },
    {
      ElementIcon: PaymentAllocationsSettingsIcon,
      title: AppStrings.Settings.GeneralSettings.PaymentAllocation.PaymentAllocationSettingsTitle,
      description: [AppStrings.Settings.GeneralSettings.PaymentAllocation.PaymentAllocationSettingsDescription],
      clearances: [
        {
          permission: ObjectPermission.paymentAllocations,
          field: "edit"
        }
      ],
      onPress: didPressPaymentAllocations
    }
  ];
  return !isHOAUser ? items : filterObjectsByUserType(items, "HOAUser");
};
