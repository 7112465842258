import type { FilterOption } from "@/components/DLUI/screen/filterPanel/filterInputs/types";
import { FilterInputRendererItem } from "../shared/filterRenderers";

interface InputProps<TQuery> {
  filterOption: FilterOption<TQuery>;
}

export function Input<TQuery>({ filterOption }: InputProps<TQuery>) {
  return <FilterInputRendererItem filterOption={filterOption} showLabel={false} />;
}
