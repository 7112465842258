import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";
import { lazy } from "react";

const VendorsListScreen = lazy(() => import("screens/vendors/vendorsList/vendorsListScreen"));
const VendorDetails = lazy(() => import("screens/vendors/vendorDetails/vendorDetails"));

const VendorsRouter = () => (
  <PageRouter>
    <LazyRoute path={Routes.VENDORS_GLOBAL_NEW} render={VendorsListScreen} />
    <LazyRoute path={Routes.VENDOR_DETAILS_GLOBAL_NEW} render={VendorDetails} />
    <LazyRoute exact path={Routes.VENDOR_DETAILS_EDIT_BILL_PAYMENT} render={VendorDetails} />
    <LazyRoute exact path={Routes.VENDOR_DETAILS_EDIT_BILL} render={VendorDetails} />
    <LazyRoute exact path={Routes.VENDOR_DETAILS_EDIT_CREDIT} render={VendorDetails} />
    <LazyRoute exact path={Routes.VENDOR_DETAILS_EDIT_EXPENSE} render={VendorDetails} />
    <LazyRoute exact path={Routes.VENDOR_DETAILS_EDIT_WORK_ORDER} render={VendorDetails} />
    <LazyRoute exact path={Routes.VENDOR_DETAILS_EDIT_INTERNAL_TASK} render={VendorDetails} />
    <LazyRoute exact path={Routes.EDIT_VENDOR} render={VendorsListScreen} />
    <LazyRoute path={Routes.VENDOR_DETAILS} render={VendorDetails} />
    <LazyRoute path={Routes.VENDORS} render={VendorsListScreen} />
  </PageRouter>
);

export default VendorsRouter;
