import type { Owners1099ReportItem, Vendors1099ReportItem } from "@doorloop/dto";
import clsx from "clsx";
import { DialogFrame } from "DLUI/dialogs";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import Dialog from "DLUI/dialogs/dialog";
import { IconButton } from "DLUI/form";
import { Link } from "DLUI/link";
import Text from "DLUI/text";
import { BackgroundImageView, View } from "DLUI/view";
import AppStrings from "locale/keys";
import type { FC } from "react";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { history } from "store/history";
import { CloseCircleIcon, InformationCircleLightBlueIcon } from "../../../../../assets";
import ColorsEnum from "../../../../../utils/colorsEnum";
import { useClasses } from "./styles";
import { downloadReportAsCsv, getSearchParams } from "./utils";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import type { DLButtonProps } from "DLUI/button/dlButton/dlButton";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";

interface Props {
  onClose: () => void;
}

const TAX_BANDITS_SIGNUP = "https://www.track1099.com/";
const TAX_BANDITS_INFO = "https://www.track1099.com/info/how-it-works";

const ActionInstruction: FC = ({ children }) => <View flex={"1 0 260px"}>{children}</View>;

const ActionButton: FC<DLButtonProps> = (props) => {
  const { size } = useResponsiveHelper();
  const isMobile = size === "xs";
  return (
    <DLButton
      variant={DLButtonVariantsEnum.CONTAINED}
      color={DLButtonColorsEnum.SECONDARY}
      style={{ minWidth: isMobile ? "100%" : 220 }}
      {...props}
    />
  );
};

const TaxForm1099ExportDialog: React.FC<Props> = ({ onClose }: Props) => {
  const { size } = useResponsiveHelper();
  const isMobile = size === "xs";
  const { type, ids } = useMemo(() => getSearchParams(), []);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const { t } = useTranslation();

  const classes = useClasses();

  const { selection } = history.state as { selection: Array<Owners1099ReportItem | Vendors1099ReportItem> };
  const onExportClick = useCallback(async () => {
    try {
      setRequestInProgress(true);
      await downloadReportAsCsv(selection || ids, type);
    } finally {
      setRequestInProgress(false);
    }
  }, [ids, type]);

  return (
    <Dialog open={true} className={clsx(["DLUI_Dialog"])} onClose={onClose} disableBackdropClick>
      <DialogFrame
        width={isMobile ? "100%" : getDialogFrameDimension("width", 600)}
        height="min-content"
        frameType="contentOnly"
        onCloseButtonClick={onClose}
        numViews={1}
        activeView={0}
        renderView={() => (
          <View
            justifyContent="flex-start"
            alignItems="center"
            paddingLeft={isMobile ? 15 : 30}
            paddingRight={isMobile ? 15 : 30}
            width={"100%"}
            flex={1}
            gap={30}
            paddingTop={30}
            paddingBottom={30}
          >
            <View flexDirection="row" justifyContent="center" alignItems="flex-start">
              <BackgroundImageView
                publicFileName={`url(/images/track1099logo.webp)`}
                backgroundSize={"contain"}
                height={50}
                width={250}
                marginTop={5}
                backgroundPosition={"center center"}
              />
              <IconButton
                className={classes.closeIconButton}
                Icon={CloseCircleIcon}
                width={30}
                height={30}
                onClick={onClose}
              />
            </View>

            <ReactPlayer
              url={"https://www.youtube.com/watch?v=pxs2F6hx-ZA"}
              controls={true}
              config={{
                wistia: {
                  options: {
                    silentAutoPlay: "false",
                    videoFoam: true,
                    playsInline: false,
                    copyLinkAndThumbnailEnabled: false,
                    endVideoBehavior: "reset",
                    fullscreenButton: true
                  }
                }
              }}
              width={350}
              height={200}
            />
            <View flex={1} flexDirection="column" alignItems="center">
              <Text
                align="center"
                textTransform="uppercase"
                fontSize={26}
                lineHeight="31.65px"
                marginBottom={5}
                fontWeight={700}
                fontFamily="Roboto Slab"
              >
                {t(AppStrings.Reports.TaxForms1099.DialogTitle)}
              </Text>
              <Text fontSize={16} color="secondary-gray" align="center">
                {t(AppStrings.Reports.TaxForms1099.DialogSubtitle1)} <br />
                {t(AppStrings.Reports.TaxForms1099.DialogSubtitle2)} <br />
                {t(AppStrings.Reports.TaxForms1099.DialogSubtitle3)}
                <br />
              </Text>
              <Link textColor={ColorsEnum.BrightBlue} type="blank" hrefUrl={TAX_BANDITS_INFO}>
                <View flexDirection="row" gap={5} alignItems="center" marginTop={5}>
                  <InformationCircleLightBlueIcon height={20} width={20} />
                  {t(AppStrings.Reports.TaxForms1099.InfoLink)}
                </View>
              </Link>
            </View>
            <ol className={classes.actionList}>
              <li>
                <ActionInstruction>{t(AppStrings.Reports.TaxForms1099.CreateAccountInstruction)}</ActionInstruction>
                <ActionButton
                  onClick={() => window.open(TAX_BANDITS_SIGNUP, "_blank")?.focus()}
                  actionText={t(AppStrings.Reports.TaxForms1099.CreateAccountLink)}
                />
              </li>
              <li>
                <ActionInstruction>{t(AppStrings.Reports.TaxForms1099.ExportInstruction)}</ActionInstruction>
                <ActionButton
                  isLoading={requestInProgress}
                  onClick={onExportClick}
                  actionText={t(AppStrings.Reports.TaxForms1099.ExportLink)}
                />
              </li>
            </ol>
            <View flexDirection="row" justifyContent="flex-end" alignItems="center" gap={10}>
              <DLButton
                onClick={onClose}
                actionText={AppStrings.Common.Close}
                variant={DLButtonVariantsEnum.OUTLINED}
                color={DLButtonColorsEnum.NEUTRAL}
                size={DLButtonSizesEnum.LARGE}
                style={{ minWidth: 160 }}
              />
            </View>
          </View>
        )}
      />
    </Dialog>
  );
};

export default TaxForm1099ExportDialog;
