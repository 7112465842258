import type { SVGIconComponent } from "../../../assets";
import { AlertDangerIcon, AlertInfoIcon, AlertWarningIcon, AlertSuccessIcon } from "../../../assets";
import type { ToastSeverity } from "store/toast/types";

const SeverityIconMap: Record<ToastSeverity, SVGIconComponent> = {
  error: AlertDangerIcon,
  neutral: AlertInfoIcon,
  warning: AlertWarningIcon,
  success: AlertSuccessIcon
};

export { SeverityIconMap };
