import {
  AboutYouIcon,
  AttachmentClipIcon,
  DependantsIcon,
  EmergencyIcon,
  EmploymentIcon,
  IncomeIcon,
  InformationIcon,
  LinkIcon,
  NotificationIcon,
  PetsIcon,
  ResidentialHistoryIcon,
  SettingsIcon,
  TermsAndConditionsIcon,
  VehicleIcon
} from "assets/icons";
import type { MenuItem } from "DLUI/dialogs";
import AppStrings from "locale/keys";
import React from "react";
import AboutYou from "./components/aboutYou/aboutYou";
import AdditionalInformation from "./components/additionalInformation";
import ApplicationSettings from "./components/applicationSettings/applicationSettings";
import Dependants from "./components/dependants/dependants";
import EmergencyContacts from "./components/emergencyContacts/emergencyContacts";
import Employment from "./components/employment/employment";
import GeneralInstructions from "./components/generalInstructions";
import Income from "./components/income/income";
import Pets from "./components/pets/pets";
import ResidentialHistory from "./components/residentialHistory/residentialHistory";
import VehicleInformation from "./components/vehicleInformation/vehicleInformation";
import Notifications from "screens/settings/rentalApplications/components/notifications";
import ApplicationLink from "screens/settings/rentalApplications/components/applicationLink";
import Terms from "screens/settings/rentalApplications/components/terms";
import FilesAndDocuments from "screens/settings/rentalApplications/components/filesAndDocuments/filesAndDocuments";
import { useRentalApplicationLocale } from "../../../../hooks/useRentalApplicationLocale";
import { RentalApplicationOptionalControlledSections } from "@doorloop/dto";

export const getRentalApplicationsMenuItems = (): MenuItem[] => {
  const { rentalApplicationFieldSettings, isUs } = useRentalApplicationLocale();

  const menuItems: MenuItem[] = [];
  menuItems.push(
    {
      title: AppStrings.Settings.GeneralSettings.RentalApplications.ManageFeeTitle,
      menuItemTitle: AppStrings.Common.SettingsText,
      icon: SettingsIcon,
      contentComponent: (
        formikRef,
        onFileReceive,
        onFileDelete,
        onTenantPortalLogoFileReceive,
        onTenantPortalLogoFileDelete,
        onCoverImageFileReceive,
        onCoverImageFileDelete,
        merchantAccountData,
        transunionEligibilityMessage,
        transunionFee,
        hasTenantsAutoPayments,
        landlordId
      ) => (
        <ApplicationSettings
          merchantAccountData={merchantAccountData}
          formikRef={formikRef}
          transunionFee={transunionFee}
          landlordId={landlordId}
        />
      )
    },
    {
      title: AppStrings.Common.Instructions,
      icon: InformationIcon,
      contentComponent: () => <GeneralInstructions />
    },
    {
      title: AppStrings.Settings.GeneralSettings.RentalApplications.ApplicantInfo,
      icon: AboutYouIcon,
      contentComponent: () => <AboutYou rentalApplicationFieldSettings={rentalApplicationFieldSettings} />,
      isMandatory: true
    },
    {
      title: AppStrings.Settings.GeneralSettings.RentalApplications.Residential,
      icon: ResidentialHistoryIcon,
      contentComponent: () => <ResidentialHistory rentalApplicationFieldSettings={rentalApplicationFieldSettings} />,
      isMandatory: isUs
    },
    {
      title: AppStrings.Settings.GeneralSettings.RentalApplications.Employment,
      icon: EmploymentIcon,
      contentComponent: () => <Employment rentalApplicationFieldSettings={rentalApplicationFieldSettings} />,
      isMandatory: isUs
    },

    {
      title: AppStrings.Settings.GeneralSettings.RentalApplications.Vehicle,
      icon: VehicleIcon,
      dtoName: RentalApplicationOptionalControlledSections.Vehicles,
      contentComponent: () => <VehicleInformation />
    },
    {
      title: AppStrings.Settings.GeneralSettings.RentalApplications.Dependants,
      icon: DependantsIcon,
      dtoName: RentalApplicationOptionalControlledSections.Dependants,
      contentComponent: () => <Dependants />
    },
    {
      title: AppStrings.Settings.GeneralSettings.RentalApplications.EmergencyContacts,
      icon: EmergencyIcon,
      dtoName: RentalApplicationOptionalControlledSections.EmergencyContacts,
      contentComponent: () => <EmergencyContacts />
    },
    {
      title: AppStrings.Settings.GeneralSettings.RentalApplications.Pets,
      icon: PetsIcon,
      dtoName: RentalApplicationOptionalControlledSections.Pets,
      contentComponent: () => <Pets />
    },

    {
      title: AppStrings.Settings.GeneralSettings.RentalApplications.AdditionalIncome,
      icon: IncomeIcon,
      dtoName: RentalApplicationOptionalControlledSections.AdditionalIncome,
      contentComponent: () => <Income />
    },

    {
      title: AppStrings.Settings.GeneralSettings.RentalApplications.CreateQuestions,
      icon: InformationIcon,
      contentComponent: () => <AdditionalInformation />
    },
    {
      title: AppStrings.Settings.GeneralSettings.RentalApplications.RequestDocuments,
      icon: AttachmentClipIcon,
      contentComponent: () => <FilesAndDocuments />
    },
    {
      title: AppStrings.Settings.GeneralSettings.RentalApplications.TermsAndConditions,
      icon: TermsAndConditionsIcon,
      contentComponent: () => <Terms />
    },
    {
      title: AppStrings.Settings.GeneralSettings.RentalApplications.Notifications,
      icon: NotificationIcon,
      contentComponent: () => <Notifications />
    },
    {
      title: AppStrings.Settings.GeneralSettings.RentalApplications.ApplicationLink,
      icon: LinkIcon,
      contentComponent: (
        formikRef,
        onFileReceive,
        onFileDelete,
        onTenantPortalLogoFileReceive,
        onTenantPortalLogoFileDelete,
        onCoverImageFileReceive,
        onCoverImageFileDelete,
        merchantAccountData,
        transunionEligibilityMessage,
        transunionFee,
        hasTenantsAutoPayments,
        landlordId
      ) => <ApplicationLink landlordId={landlordId} />
    }
  );
  return menuItems;
};
