"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenantPortalPaymentWizardDto = void 0;
const tenantPortalPaymentOptions_dto_1 = require("./tenantPortalPaymentOptions.dto");
const recurringPayments_1 = require("../recurringPayments");
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const tenantPortalPayment_dto_1 = require("./tenantPortalPayment.dto");
class TenantPortalPaymentWizardDto {
    constructor() { }
}
exports.TenantPortalPaymentWizardDto = TenantPortalPaymentWizardDto;
__decorate([
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => tenantPortalPaymentOptions_dto_1.TenantPortalPaymentOptions),
    __metadata("design:type", tenantPortalPaymentOptions_dto_1.TenantPortalPaymentOptions)
], TenantPortalPaymentWizardDto.prototype, "paymentOptions", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => recurringPayments_1.TenantPortalRecurringPaymentDto),
    __metadata("design:type", recurringPayments_1.TenantPortalRecurringPaymentDto)
], TenantPortalPaymentWizardDto.prototype, "recurringPayment", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => tenantPortalPayment_dto_1.TenantPortalPaymentDto),
    __metadata("design:type", tenantPortalPayment_dto_1.TenantPortalPaymentDto)
], TenantPortalPaymentWizardDto.prototype, "oneTimePayment", void 0);
