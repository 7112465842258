import type {
  ExpenseDto,
  GeneralEntryDto,
  GeneralEntryInfoDto,
  LeaseChargeDto,
  LeasePaymentDto,
  OwnerContributionDto,
  TaskDto,
  LeaseRefundDto,
  TransferDto,
  VendorBillDto,
  VendorCreditDto
} from "@doorloop/dto";
import { DepositStatus } from "@doorloop/dto";
import { type RecurringTransactionDto } from "@doorloop/dto";
import { renderReferenceString } from "utils/strings";
import pick from "lodash/pick";
import isEmpty from "lodash/isEmpty";
import { QueryParams } from "utils/queryParams";
import moment from "moment";
import flow from "lodash/flow";

interface CleanPaymentDataParams {
  leasePayment: LeasePaymentDto;
  autoDeposit: boolean;
  sendPaymentReceipt: boolean;
}

interface CleanOwnerContributionParams {
  ownerContribution: OwnerContributionDto;
  autoDeposit: boolean;
}

export const DUPLICATE_MODE_QUERY_PARAMS = {
  transactionId: "tnxId",
  vendorId: "vendorId",
  taskId: "taskId"
} as const;

const VENDOR_BILL_DUPLICATE_FIELDS: ReadonlyArray<keyof VendorBillDto> = [
  "date",
  "dueDate",
  "lines",
  "totalAmount",
  "reference",
  "vendor",
  "memo"
] as const;
const RECURRING_TRANSACTION_DUPLICATE_FIELDS: ReadonlyArray<keyof RecurringTransactionDto> = [
  "start",
  "end",
  "noEndDate",
  "frequency",
  "memo",
  "numberOfDaysUntilDue",
  "property",
  "reference",
  "status",
  "vendorBillInfo",
  "type",
  "lease",
  "expenseInfo",
  "leaseChargeInfo",
  "leasePaymentInfo",
  "leaseRentInfo",
  "leaseCreditInfo",
  "generalEntryInfo",
  "taskInfo",
  "unit",
  "total"
] as const;
const EXPENSE_DUPLICATE_FIELDS: ReadonlyArray<keyof ExpenseDto> = [
  "reference",
  "date",
  "lines",
  "payFromAccount",
  "payToResourceId",
  "payToResourceType",
  "paymentMethod",
  "totalAmount"
] as const;
const VENDOR_CREDIT_DUPLICATE_FIELDS: ReadonlyArray<keyof VendorCreditDto> = [
  "date",
  "reference",
  "memo",
  "totalAmount",
  "vendor",
  "lines"
] as const;
const LEASE_CHARGE_DUPLICATE_FIELDS: ReadonlyArray<keyof LeaseChargeDto> = [
  "date",
  "lease",
  "lines",
  "memo",
  "reference",
  "totalAmount"
] as const;
const LEASE_REFUND_DUPLICATE_FIELDS: ReadonlyArray<keyof LeaseRefundDto> = [
  "date",
  "lease",
  "lines",
  "memo",
  "reference",
  "totalAmount",
  "payToTenant",
  "payFromAccount",
  "paymentMethod"
] as const;
const GENERAL_ENTRY_DUPLICATE_FIELDS: ReadonlyArray<keyof GeneralEntryDto> = [
  "date",
  "lines",
  "memo",
  "property",
  "unit",
  "reference"
] as const;
const GENERAL_ENTRY_INFO_DUPLICATE_FIELDS: ReadonlyArray<keyof GeneralEntryInfoDto> = [
  "date",
  "lines",
  "memo",
  "property",
  "unit"
] as const;
const LEASE_PAYMENT_DUPLICATE_FIELDS: ReadonlyArray<keyof LeasePaymentDto> = [
  "receivedFromTenant",
  "paymentMethod",
  "lease",
  "depositToAccount",
  "reference",
  "depositStatus",
  "amountReceived",
  "date",
  "autoDeposit",
  "sendPaymentReceipt",
  "autoApplyPaymentOnCharges",
  "memo"
] as const;
const TRANSFER_DUPLICATE_FIELDS: ReadonlyArray<keyof TransferDto> = [
  "amount",
  "clearingAccount",
  "fromAccount",
  "fromProperty",
  "toAccount",
  "toProperty",
  "date",
  "memo",
  "reference",
  "fromUnit",
  "toUnit"
];
const TASK_DUPLICATE_FIELDS: ReadonlyArray<keyof TaskDto> = [
  "dueDateTime",
  "dueDate",
  "dateType",
  "category",
  "reference",
  "description",
  "status",
  "type",
  "dateType",
  "entryPermission",
  "notifyAssignees",
  "assignedToUsers",
  "conversationMessage",
  "requestedByUser",
  "requestedByOwner",
  "requestedByTenant",
  "workOrder",
  "linkedResource",
  "entryContact",
  "entryNotes",
  "priority",
  "subject",
  "unit",
  "property"
];
const OWNER_CONTRIBUTION_DUPLICATE_FIELDS: ReadonlyArray<keyof OwnerContributionDto> = [
  "account",
  "amountReceived",
  "date",
  "depositToAccount",
  "memo",
  "owner",
  "paymentMethod",
  "property",
  "reference",
  "autoDeposit",
  "depositStatus",
  "checkInfo"
];

export const isDuplicateMode = () =>
  !isEmpty(
    pick(new QueryParams().getAll(), [
      DUPLICATE_MODE_QUERY_PARAMS.transactionId,
      DUPLICATE_MODE_QUERY_PARAMS.vendorId,
      DUPLICATE_MODE_QUERY_PARAMS.taskId
    ])
  );

const initDataDuplicateMode =
  <TDto>(fields: ReadonlyArray<keyof TDto>) =>
  (dto: TDto) =>
    pick({ ...dto, reference: renderReferenceString() }, fields);

const setPaymentData = ({ leasePayment, autoDeposit, sendPaymentReceipt }: CleanPaymentDataParams): LeasePaymentDto => {
  return {
    ...leasePayment,
    depositStatus: DepositStatus.UNDEPOSITED,
    autoApplyPaymentOnCharges: true,
    sendPaymentReceipt,
    autoDeposit
  };
};
const initDuplicateOwnerContributionData = ({
  ownerContribution,
  autoDeposit
}: CleanOwnerContributionParams): OwnerContributionDto => {
  return {
    ...ownerContribution,
    depositStatus: DepositStatus.UNDEPOSITED,
    autoDeposit,
    checkInfo: { checkNumber: "" }
  };
};
const setInfoDateFields = (recurringTransaction: RecurringTransactionDto) => {
  const today = moment().format("YYYY-MM-DD").toString();
  if (recurringTransaction.generalEntryInfo) {
    recurringTransaction.generalEntryInfo.date = today;
  }
  if (recurringTransaction.vendorBillInfo) {
    recurringTransaction.vendorBillInfo.date = today;
  }
  return recurringTransaction;
};

export const cleanVendorBillDataForDuplicateMode = initDataDuplicateMode<VendorBillDto>(VENDOR_BILL_DUPLICATE_FIELDS);
export const cleanRecurringTransactionDataForDuplicateMode = flow(
  initDataDuplicateMode<RecurringTransactionDto>(RECURRING_TRANSACTION_DUPLICATE_FIELDS),
  setInfoDateFields
);
export const cleanExpenseDataForDuplicateMode = initDataDuplicateMode<ExpenseDto>(EXPENSE_DUPLICATE_FIELDS);
export const cleanVendorCreditDataForDuplicateMode =
  initDataDuplicateMode<VendorCreditDto>(VENDOR_CREDIT_DUPLICATE_FIELDS);
export const cleanLeaseChargeDataForDuplicateMode =
  initDataDuplicateMode<LeaseChargeDto>(LEASE_CHARGE_DUPLICATE_FIELDS);
export const cleanGeneralEntryDataForDuplicateMode =
  initDataDuplicateMode<GeneralEntryDto>(GENERAL_ENTRY_DUPLICATE_FIELDS);
export const cleanGeneralEntryInfoDataForDuplicateMode = initDataDuplicateMode<GeneralEntryInfoDto>(
  GENERAL_ENTRY_INFO_DUPLICATE_FIELDS
);
export const cleanLeasePaymentDataForDuplicateMode = (params: CleanPaymentDataParams) =>
  initDataDuplicateMode<LeasePaymentDto>(LEASE_PAYMENT_DUPLICATE_FIELDS)(setPaymentData(params));
export const cleanTaskDataForDuplicateMode = initDataDuplicateMode<TaskDto>(TASK_DUPLICATE_FIELDS);
export const cleanLeaseRefundDataForDuplicateMode =
  initDataDuplicateMode<LeaseRefundDto>(LEASE_REFUND_DUPLICATE_FIELDS);
export const cleanOwnerContributionDataForDuplicateMode = (params: CleanOwnerContributionParams) =>
  initDataDuplicateMode<OwnerContributionDto>(OWNER_CONTRIBUTION_DUPLICATE_FIELDS)(
    initDuplicateOwnerContributionData(params)
  );
export const cleanTransferDataForDuplicateMode = initDataDuplicateMode<TransferDto>(TRANSFER_DUPLICATE_FIELDS);
