import { useMemo, type FC } from "react";
import { View } from "DLUI/view";
import { SummaryLink } from "DLUI/dialogs/successDialog/summaryLink";
import { NavigationManager } from "utils/navigation";
import type { EnTranslation } from "@/locale";
import { useTypedTranslation } from "@/locale";
import { SummaryItem } from "@/components/screens/owners/ownerDistributions/components/successDialogOwnerDistribution/components/successDialogSummary";
import { HorizontalSeparationLine } from "@/components/DLUI/separatorView/horizontalSeparationLine";

interface Props {
  propertiesUniqueCount: number;
  reference?: string;
  payeeType?: string;
}

export const ExpenseCtaSummary: FC<Props> = ({ propertiesUniqueCount, reference, payeeType }) => {
  const { t } = useTypedTranslation();

  const handleViewTransactions = () => {
    NavigationManager.runTransactionsListReport(reference ? { filter_reference: reference } : {}, true);
  };

  const payeeTypePluralized = useMemo((): EnTranslation | undefined => {
    switch (payeeType) {
      case "vendor":
        return "drawerItems.vendors";
      case "owner":
        return "drawerItems.owners";
      case "tenant":
        return "drawerItems.tenants";
    }
  }, [payeeType]);

  return (
    <View
      border="1px solid #182C4C1A"
      backgroundColor="white"
      alignItems="stretch"
      paddingTop={16}
      paddingBottom={16}
      paddingLeft={16}
      paddingRight={16}
      gap={16}
    >
      <SummaryItem translationKey={t("common.properties")} value={propertiesUniqueCount} />
      {payeeTypePluralized && (
        <SummaryItem textTransform="capitalize" translationKey={t(payeeTypePluralized)} value={1} />
      )}
      <SummaryItem translationKey={t("common.numberOfPayments")} value={1} />
      <HorizontalSeparationLine marginTop={4} marginBottom={4} />
      <SummaryLink
        translationKey={t("owners.ownerDistributions.viewTransactionsReport")}
        onClick={handleViewTransactions}
      />
    </View>
  );
};
