import { useEffect, useRef } from "react";
import { isProductionEnv } from "../../../utils/environmentHelper";
import HelloSign from "hellosign-embedded";

const HELLOSIGN_CLIENT_ID = "f3e16ecdd8a9022c1197143be944c6a6";

const HelloSignOptions = {
  clientId: HELLOSIGN_CLIENT_ID,
  skipDomainVerification: !isProductionEnv,
  testMode: !isProductionEnv
};

export const useHelloSignClient = (onError, onCancel?, onClose?, onSend?, onCreateTemplate?) => {
  const client = useRef(new HelloSign(HelloSignOptions));

  const resetClientInstance = () => {
    client.current = new HelloSign(HelloSignOptions);
  };

  const registerHelloSignEvents = () => {
    client.current.on("cancel", () => {
      onCancel?.();
    });
    client.current.on("close", () => {
      onClose?.();
    });
    client.current.on("error", (data) => {
      onError(data.code);
      client.current.close();
    });
    client.current.on("createTemplate", () => {
      resetClientInstance();
      onCreateTemplate && onCreateTemplate();
    });

    client.current.on("finish", async () => {
      resetClientInstance();
      onSend?.();
    });
  };

  const openHelloSignSDK = (embeddedUrl: string) => {
    client.current.open(embeddedUrl);
  };

  useEffect(() => {
    registerHelloSignEvents();
    return () => {
      client.current.off("close");
      client.current.off("finish");
      client.current.off("error");
      client.current.off("createTemplate");
    };
  }, []);

  return { openHelloSignSDK };
};
