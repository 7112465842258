"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.verifyAddressInUs = exports.usCountryList = void 0;
exports.usCountryList = ["usa", "us", "united states"];
const verifyAddressInUs = (residentialHistoryInfo) => {
    var _a, _b, _c;
    let isInUs = true;
    if (residentialHistoryInfo) {
        const applicantCurrentAddress = residentialHistoryInfo === null || residentialHistoryInfo === void 0 ? void 0 : residentialHistoryInfo.find((x) => x.currentlyLivingHere);
        if ((_a = applicantCurrentAddress === null || applicantCurrentAddress === void 0 ? void 0 : applicantCurrentAddress.address) === null || _a === void 0 ? void 0 : _a.country) {
            isInUs = exports.usCountryList.includes((_c = (_b = applicantCurrentAddress === null || applicantCurrentAddress === void 0 ? void 0 : applicantCurrentAddress.address) === null || _b === void 0 ? void 0 : _b.country) === null || _c === void 0 ? void 0 : _c.toLowerCase());
        }
    }
    return isInUs;
};
exports.verifyAddressInUs = verifyAddressInUs;
