"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WidgetType = void 0;
var WidgetType;
(function (WidgetType) {
    WidgetType["GENERAL_THINGS_TO_DO"] = "GENERAL_THINGS_TO_DO";
    WidgetType["GENERAL_SUGGESTED"] = "GENERAL_SUGGESTED";
    WidgetType["TASKS_MY_NEXT_7_DAYS"] = "TASKS_MY_NEXT_7_DAYS";
    WidgetType["TASKS_ASSIGNED_TO_ME"] = "TASKS_ASSIGNED_TO_ME";
    WidgetType["TASKS_OPEN_BY_STATUS"] = "TASKS_OPEN_BY_STATUS";
    WidgetType["PEOPLE_TENANT_STATS"] = "PEOPLE_TENANT_STATS";
    WidgetType["PEOPLE_TENANT_PORTAL_STATS"] = "PEOPLE_TENANT_PORTAL_STATS";
    WidgetType["PEOPLE_PROSPECT_STATS"] = "PEOPLE_PROSPECT_STATS";
    WidgetType["PEOPLE_PROSPECT_FUNNEL"] = "PEOPLE_PROSPECT_FUNNEL";
    WidgetType["PEOPLE_PROSPECT_BY_PROPERTY"] = "PEOPLE_PROSPECT_BY_PROPERTY";
    WidgetType["PEOPLE_PROSPECTS_BY_LEAD_SOURCE"] = "PEOPLE_PROSPECTS_BY_LEAD_SOURCE";
    WidgetType["RENTALS_STATS"] = "RENTALS_STATS";
    WidgetType["RENTALS_TOTAL_OCCUPANCY_RATE"] = "RENTALS_TOTAL_OCCUPANCY_RATE";
    WidgetType["RENTAL_APPLICATION_OVERVIEW"] = "RENTAL_APPLICATION_OVERVIEW";
    WidgetType["RENTALS_VACANCIES_BY_PROPERTY"] = "RENTALS_VACANCIES_BY_PROPERTY";
    WidgetType["LEASING_NEXT_7_DAYS"] = "LEASING_NEXT_7_DAYS";
    WidgetType["LEASING_DRAFT_LEASES_FUNNEL"] = "LEASING_DRAFT_LEASES_FUNNEL";
    WidgetType["LEASING_RENTAL_APPLICATIONS_FUNNEL"] = "LEASING_RENTAL_APPLICATIONS_FUNNEL";
    WidgetType["ACCOUNTING_BANKING_STATS"] = "ACCOUNTING_BANKING_STATS";
    WidgetType["ACCOUNTING_ACCOUNTS_RECEIVABLE"] = "ACCOUNTING_ACCOUNTS_RECEIVABLE";
    WidgetType["ACCOUNTING_ACCOUNTS_PAYABLE"] = "ACCOUNTING_ACCOUNTS_PAYABLE";
    WidgetType["ACCOUNTING_RECENT_PAYMENTS"] = "ACCOUNTING_RECENT_PAYMENTS";
    WidgetType["LEASING_UPCOMING_RENEWALS"] = "LEASING_UPCOMING_RENEWALS";
    WidgetType["RECENT_TENANT_REQUESTS"] = "RECENT_TENANT_REQUESTS";
    WidgetType["LEASES_WITH_OUTSTANDING_BALANCES"] = "LEASES_WITH_OUTSTANDING_BALANCES";
    WidgetType["PROFIT_AND_LOSS"] = "PROFIT_AND_LOSS";
})(WidgetType || (exports.WidgetType = WidgetType = {}));
