import { InstructionsText } from "screens/settings/managementFees/formElements/instructionsText";
import { FormikSwitchButton, RadioGroup } from "DLUI/form";
import { FastField, getIn, useFormikContext } from "formik";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { useUserType } from "@/hooks/useUserType";
import { ArticleIdsEnum } from "DLUI/screen/helpPanel/types";
import { useTypedTranslation } from "locale";
import { useMemo } from "react";
import type { RadioGroupItem } from "DLUI/form/radioGroup/types";
import { AnimatedView } from "@/components/DLUI/view/animatedView";
import { DataCy } from "@doorloop/dto";

interface TitleSectionProps {
  shouldShowPropertyOptions?: boolean;
  onChangeSpecifyManagementFee: (chargeFees: string) => void;
  onChangeCollectFees: (collectFees: boolean) => void;
}

export const TitleSection = ({
  shouldShowPropertyOptions,
  onChangeSpecifyManagementFee,
  onChangeCollectFees
}: TitleSectionProps) => {
  const { isHOAUser } = useUserType();
  const formikRef = useFormikContext();
  const { t } = useTypedTranslation();

  const useCompanyDefaultManagementFees = getIn(formikRef.values, "useCompanyDefaultManagementFees");

  const radioButtonOptions: RadioGroupItem[] = useMemo(
    () => [
      {
        label: t("settings.generalSettings.managementFees.useCompanyDefaultManagementFees"),
        value: "false"
      },
      {
        label: t("settings.generalSettings.managementFees.specifyManagementFeeStructureForProperty"),
        value: "true",
        dataCy: DataCy.globalSettings.managementFees.specifyForProperty
      }
    ],
    [t]
  );

  if (!shouldShowPropertyOptions) {
    return null;
  }

  return (
    <div>
      <InstructionsText articleId={!isHOAUser ? ArticleIdsEnum.MANAGEMENT_FEES : ArticleIdsEnum.HOA_MANAGEMENT_FEES} />
      <FastField
        component={FormikSwitchButton}
        name={"collectManagementFees"}
        label={t("settings.generalSettings.managementFees.collectManagementFeesForProperty")}
        marginTop={16}
        onChange={onChangeCollectFees}
        dataCy={DataCy.globalSettings.managementFees.enableForProperty}
      />
      <HorizontalSeparationLine marginTop={16} marginBottom={6} />
      <AnimatedView shouldShow={getIn(formikRef.values, "collectManagementFees") || false}>
        <>
          <RadioGroup
            noWrap
            didChangeSelection={onChangeSpecifyManagementFee}
            uniqueKey={"MAL"}
            defaultValue={useCompanyDefaultManagementFees === true ? "false" : "true"}
            textMaxWidth={440}
            radioGroupItems={radioButtonOptions}
          />
          {!useCompanyDefaultManagementFees && <HorizontalSeparationLine marginTop={6} />}
        </>
      </AnimatedView>
    </div>
  );
};
