import { DataCy, type GetAllBaseQueryRequest } from "@doorloop/dto";
import { applySort } from "DLUI/infiniteList/sortManager";
import { LoadingSkeleton } from "DLUI/loadingSkeleton";
import DefaultSkeletonItem from "DLUI/skeletonItem/defaultSkeletonItem";
import { StickyHeader } from "DLUI/stickyHeader";
import { View } from "DLUI/view";
import type { ComponentType, CSSProperties, RefObject } from "react";
import React, { useEffect, useImperativeHandle, useMemo } from "react";
import { useDataList } from "./hooks/useDataList";
import type { RestApiBase } from "api/restApiBase";
import isEmpty from "lodash/isEmpty";
import { ListEmptyIndicator } from "./components/listEmptyIndicator";
import { DataListVirtualScroll } from "./components/dataListVirtualScroll";
import type { StickyHeaderProps } from "DLUI/stickyHeader/stickyHeader";
import { useListBulkActions } from "./hooks/useListBulkActions";
import { ListBulkActionsDialog } from "./components/listBulkActionsDialog";
import type { BulkActionDefinition, DataListRefType } from "./types";
import type { VirtualizerOptions } from "@tanstack/react-virtual";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { ListBulkActionsContext } from "DLUI/infiniteList/listBulkActionsContext";
import { DataListScroll } from "./components/dataListScroll";
import type { ViewComponentProps } from "DLUI/view/view";

interface WithVirtualizationProps {
  virtual: true;
  virtualizerProps?: Partial<VirtualizerOptions<HTMLElement, Element>>;
}
interface NoVirtualizationProps {
  virtual?: false;
}
type VirtualizationProps = WithVirtualizationProps | NoVirtualizationProps;
export interface BulkOperationsSettings {
  bulkActionDefinitions?: BulkActionDefinition[];
  hideBulkSelectAll?: boolean;
  minHeight?: number;
  dialogTimeout?: number;
}
interface NoBulkOperationsProps {
  bulkOperationsProps?: undefined;
  bulkOperationResourceIdGetter?: undefined;
}
interface WithBulkOperationsProps<TDto> {
  bulkOperationsProps: BulkOperationsSettings;
  bulkOperationResourceIdGetter: (item: TDto, index: number) => string;
}
type BulkOperationsProps<TDto> = NoBulkOperationsProps | WithBulkOperationsProps<TDto>;

export type DataListProps<TDto, TGetAllDto extends GetAllBaseQueryRequest> = VirtualizationProps &
  BulkOperationsProps<TDto> & {
    children: (item: TDto, index: number, resourceId?: string) => React.ReactNode;
    restApi: Pick<RestApiBase<TDto, TGetAllDto>, "getAll">;
    infinite?: boolean;
    queryFilter?: Partial<Omit<TGetAllDto, "page_number">>;
    scrollElement?: HTMLElement | null;
    paddingEnd?: number;
    skeleton?: ComponentType;
    emptyListView?: ComponentType;
    listHeader?: ComponentType<{ listData: TDto[] }>;
    onLoadingDataStateChange?: (loadingInProgress: boolean) => void;
    stickyHeaderProps?: Partial<Omit<StickyHeaderProps, "sortHandler" | "bulkActions">>;
    hideSkeleton?: boolean;
    listRef?: RefObject<DataListRefType<TDto>>;
    dataCy?: string;
    listItemStyle?: CSSProperties;
    listItemsContainerProps?: ViewComponentProps;
  };

//Notion Docs: https://www.notion.so/Data-List-Component-f1b3de9af9b544bd8cf43a852949ba92?pvs=4
export function DataList<TDto, TGetAllDto extends GetAllBaseQueryRequest>(props: DataListProps<TDto, TGetAllDto>) {
  const {
    children,
    restApi,
    scrollElement = document.getElementById("screenScroll"),
    skeleton: Skeleton = () => <LoadingSkeleton SkeletonItem={DefaultSkeletonItem} />,
    emptyListView: EmptyListView = () => <ListEmptyIndicator />,
    queryFilter,
    listHeader: ListHeader,
    onLoadingDataStateChange,
    bulkOperationsProps,
    infinite = false,
    stickyHeaderProps = { shouldShow: true },
    paddingEnd = 80,
    hideSkeleton,
    listRef,
    listItemStyle,
    listItemsContainerProps,
    dataCy,
    ...rest
  } = props;

  const { isMobile } = useResponsiveHelper();
  const { allRows, totalAllData, infiniteQuery, queryKey } = useDataList<TDto, TGetAllDto>({
    restApi,
    infinite,
    queryFilter
  });
  const { isInitialLoading, isFetching, isRefetching } = infiniteQuery;

  const { bulkOperationResourceIdGetter } = props.bulkOperationsProps ? props : ({} as never);

  useImperativeHandle(
    listRef,
    (): { queryKey: unknown[]; allRows: TDto[] } => {
      return {
        queryKey,
        allRows
      };
    },
    [queryKey, allRows]
  );

  const resourceIds = useMemo<string[]>(
    () =>
      isEmpty(bulkOperationsProps?.bulkActionDefinitions) || !bulkOperationResourceIdGetter
        ? []
        : allRows.map(bulkOperationResourceIdGetter),
    [allRows, bulkOperationResourceIdGetter, bulkOperationsProps?.bulkActionDefinitions]
  );

  const allRowsEmpty = useMemo(() => isEmpty(allRows), [allRows]);
  const listBulkActions = useListBulkActions(resourceIds, bulkOperationsProps?.bulkActionDefinitions);

  useEffect(() => {
    onLoadingDataStateChange?.(isInitialLoading);
  }, [isInitialLoading, onLoadingDataStateChange]);

  if (!hideSkeleton && (isInitialLoading || isRefetching)) {
    return Skeleton ? <Skeleton /> : null;
  }

  if (allRows.length === 0) {
    return (
      <View width={"100%"} flex={1} justifyContent={"flex-start"} alignItems={"center"} flexDirection={"row"}>
        {ListHeader && <ListHeader listData={allRows} />}
        <EmptyListView />
      </View>
    );
  }

  const shouldShowStickyHeader = stickyHeaderProps.shouldShow ?? true;

  const totalChecked = listBulkActions.checkedIds.length;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        flex: 1
      }}
      {...rest}
      data-cy={dataCy}
    >
      {allRowsEmpty && !isFetching && <ListEmptyIndicator />}
      <View alignItems={"center"} noWrap fullWidth>
        {!isInitialLoading && ListHeader && <ListHeader listData={allRows} />}
        {bulkOperationsProps && (
          <ListBulkActionsDialog
            {...bulkOperationsProps}
            {...listBulkActions}
            queryFilter={queryFilter || {}}
            totalAll={totalAllData || 0}
          />
        )}
        {!allRowsEmpty && (
          <StickyHeader
            {...stickyHeaderProps}
            shouldShow={shouldShowStickyHeader && !isInitialLoading && !isMobile}
            sortHandler={applySort}
            bulkActions={
              bulkOperationsProps?.bulkActionDefinitions
                ? {
                    isAllChecked: listBulkActions.allChecked,
                    isAnyChecked: listBulkActions.anyChecked,
                    totalChecked,
                    totalViewable: allRows?.length || 0,
                    totalAll: totalAllData || 0,
                    isTotalAllChecked: listBulkActions.isTotalAllChecked,
                    selectTotalAll: () => listBulkActions.selectTotalAll(),
                    clearTotalAll: () => listBulkActions.clearTotalAll(),
                    toggleAll: listBulkActions.toggleAll,
                    bulkActionDefinitions: bulkOperationsProps.bulkActionDefinitions,
                    fireAction: listBulkActions.handleDispatchAction,
                    hideBulkSelectAll: bulkOperationsProps.hideBulkSelectAll
                  }
                : undefined
            }
          />
        )}
        <View
          fullWidth
          overflow="hidden"
          justifyContent="flex-start"
          alignItems="center"
          id={"listItemsContainer"}
          dataCy={DataCy.DLUI.dataList.scrollContainer}
          {...listItemsContainerProps}
        >
          <ListBulkActionsContext.Provider value={listBulkActions?.listBulkActionsInterface}>
            {props.virtual && !props.infinite && (
              <DataListVirtualScroll
                rows={allRows}
                resourceIdGetter={bulkOperationResourceIdGetter}
                renderRow={children}
                infinite={false}
                virtualizerProps={{
                  paddingEnd,
                  getScrollElement: () => scrollElement,
                  ...props.virtualizerProps
                }}
              />
            )}
            {props.virtual && props.infinite && (
              <DataListVirtualScroll
                rows={allRows}
                resourceIdGetter={bulkOperationResourceIdGetter}
                renderRow={children}
                infinite
                hasNextPage={infiniteQuery.hasNextPage}
                isFetchingNextPage={infiniteQuery.isFetchingNextPage}
                fetchNextPage={infiniteQuery.fetchNextPage}
                virtualizerProps={{
                  paddingEnd,
                  getScrollElement: () => scrollElement,
                  ...props.virtualizerProps
                }}
              />
            )}
            {!props.virtual && !props.infinite && (
              <DataListScroll
                rows={allRows}
                resourceIdGetter={bulkOperationResourceIdGetter}
                renderRow={children}
                infinite={false}
                paddingEnd={paddingEnd}
                scrollElement={scrollElement}
              />
            )}
            {!props.virtual && props.infinite && (
              <DataListScroll
                rows={allRows}
                resourceIdGetter={bulkOperationResourceIdGetter}
                renderRow={children}
                infinite
                hasNextPage={infiniteQuery.hasNextPage}
                isFetchingNextPage={infiniteQuery.isFetchingNextPage}
                fetchNextPage={infiniteQuery.fetchNextPage}
                paddingEnd={paddingEnd}
                scrollElement={scrollElement}
                style={listItemStyle}
              />
            )}
          </ListBulkActionsContext.Provider>
        </View>
      </View>
    </div>
  );
}
