import type { LoginAndPasswordSettingsDisplayDto, LoginAndPasswordSettingsDto } from "@doorloop/dto";
import { OwnerPortalServerRoutes } from "@doorloop/dto";

import { apiHelper } from "api/apiHelper";
import { RestApiBase } from "api/restApiBase";

export class OwnerPortalSettingsApi extends RestApiBase<LoginAndPasswordSettingsDisplayDto, unknown> {
  constructor() {
    super(OwnerPortalServerRoutes.SETTINGS_LOGIN_AND_PASSWORD);
    this.restRoute = OwnerPortalServerRoutes.SETTINGS_LOGIN_AND_PASSWORD;
  }

  async updateLoginAndPassword(data: LoginAndPasswordSettingsDto) {
    return await apiHelper.axiosPost<LoginAndPasswordSettingsDto>({
      url: OwnerPortalServerRoutes.SETTINGS_LOGIN_AND_PASSWORD,
      data
    });
  }
}

export const ownerPortalSettingsApi = new OwnerPortalSettingsApi() as Pick<
  OwnerPortalSettingsApi,
  "getAll" | "updateLoginAndPassword"
>;
