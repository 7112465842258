import React from "react";
import type { StepPropsWithDto } from "screens/onBoarding/types";
import { OnBoardingLeaseIcon } from "assets/.";
import AppStrings from "locale/keys";
import Text from "DLUI/text";
import StepSection from "screens/onBoarding/components/stepSection";
import { Icon } from "DLUI/icon";
import { View } from "DLUI/view";
import { Grid } from "@material-ui/core";
import ActionButton from "screens/onBoarding/components/actionButton";
import { NavigationManager } from "utils/navigation";
import { useTranslation } from "react-i18next";
import { onBoardingApi } from "api/onBoardingApi";
import { VideoUrlsEnum } from "DLUI/screen/helpPanel/types";

type Props = StepPropsWithDto;

const AddYourLeasesStep: React.FC<Props> = ({
  isCollapsed,
  onHeaderClick,
  name,
  onBoardingDto,
  onInvalidate,
  isCompleted,
  isSkipped
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);

  const icon = OnBoardingLeaseIcon;
  const title = AppStrings.OnBoarding.StepTitles.AddYourLeases;
  const description = AppStrings.OnBoarding.StepDescriptions.AddYourLeases;

  const progress = onBoardingDto.leases?.createdCount;
  const total = onBoardingDto.unitsSubscribed;

  const hasFinishedAddingUnits = progress !== undefined && total !== undefined && progress > 0 && progress === total;

  const handleMarkAsComplete = async () => {
    setIsLoading(true);
    const response = await onBoardingApi.updateOnBoardingItem(name, { isSkipped: !isSkipped });

    const hasError = response.statusCode !== 200 ? response.message ?? true : undefined;
    await onInvalidate({ error: hasError });
    setIsLoading(false);
  };

  const handleCreateLease = () => {
    const redirectUrl = location.pathname;
    NavigationManager.createNewDraftLease(undefined, undefined, redirectUrl);
  };

  const LeftSideContent = () => (
    <View gap={15}>
      <Text whiteSpace={"pre-line"} value={description} />
      <Grid container direction={"row"} alignItems={"center"} spacing={2}>
        <Grid item xs={12} sm={"auto"} md={"auto"}>
          <ActionButton
            onClick={handleCreateLease}
            actionText={AppStrings.OnBoarding.ActionButtons.AddYourLeases}
            fullWidth
            width={"100%"}
          />
        </Grid>
        <Grid item xs={12} sm={"auto"} md={"auto"}>
          {/* Don't show the mark as complete/incomplete button if the step is actually completed. show if skipped */}
          {(!isCompleted || isSkipped) && !hasFinishedAddingUnits && (
            <ActionButton
              onClick={handleMarkAsComplete}
              faded
              requestInProgress={isLoading}
              actionText={
                isCompleted || isSkipped
                  ? AppStrings.OnBoarding.MarkAsIncomplete
                  : AppStrings.OnBoarding.MarkAsCompleted
              }
              fullWidth
              width={"100%"}
            />
          )}
        </Grid>
      </Grid>
    </View>
  );

  return (
    <StepSection
      icon={<Icon size={24} Source={icon} />}
      title={title}
      leftSideContent={<LeftSideContent />}
      isCompleted={isCompleted}
      isSkipped={isSkipped}
      onHeaderClick={onHeaderClick}
      progress={progress}
      total={total}
      progressText={
        progress !== undefined && total !== undefined
          ? t(AppStrings.OnBoarding.XoutofYUnits, { progress, total })
          : undefined
      }
      isCollapsed={isCollapsed}
      reactPlayerVideoUrl={VideoUrlsEnum.OB_CREATING_A_LEASE}
      isLoading={isLoading}
    />
  );
};

export default AddYourLeasesStep;
