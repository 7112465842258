import React from "react";
import { View } from "DLUI/view";
import { ListItemIcon } from "DLUI/listItems";
import { LeaseListIcon } from "assets/icons";
import AppStrings from "locale/keys";
import Text from "DLUI/text";

interface ComponentProps {
  didPressSelectLease: () => void;
}

const NoLeaseSelectionView: React.FC<ComponentProps> = ({ didPressSelectLease }: ComponentProps) => (
  <View marginTop={20} justifyContent={"center"} alignItems={"center"}>
    <View
      flexDirection={"row"}
      cursor={"pointer"}
      height={100}
      backgroundColor={"dark"}
      borderRadius={10}
      width={"auto"}
      alignItems={"center"}
      onClick={didPressSelectLease}
    >
      <ListItemIcon Icon={LeaseListIcon} />
      <Text
        fontSize={16}
        color={"black"}
        value={AppStrings.Leases.LeaseCharge.PressToSelectLease}
        paddingLeft={20}
        paddingRight={60}
      />
    </View>
  </View>
);
export default NoLeaseSelectionView;
