"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountsTypesByClass = exports.AccountType = void 0;
/**
 * Account type. Structure must be CLASS_TYPE, as it is used to derive the class from the type.
 */
const accountClass_enum_1 = require("./accountClass.enum");
var AccountType;
(function (AccountType) {
    AccountType["ASSET_ACCOUNTS_RECEIVABLE"] = "ASSET_ACCOUNTS_RECEIVABLE";
    AccountType["ASSET_OTHER_CURRENT_ASSETS"] = "ASSET_OTHER_CURRENT_ASSETS";
    AccountType["ASSET_BANK"] = "ASSET_BANK";
    AccountType["ASSET_FIXED_ASSETS"] = "ASSET_FIXED_ASSETS";
    AccountType["ASSET_OTHER_ASSETS"] = "ASSET_OTHER_ASSETS";
    AccountType["LIABILITY_ACCOUNTS_PAYABLE"] = "LIABILITY_ACCOUNTS_PAYABLE";
    AccountType["LIABILITY_CREDIT_CARD"] = "LIABILITY_CREDIT_CARD";
    AccountType["LIABILITY_OTHER_CURRENT_LIABILIY"] = "LIABILITY_OTHER_CURRENT_LIABILIY";
    AccountType["LIABILITY_LONG_TERM_LIABILITY"] = "LIABILITY_LONG_TERM_LIABILITY";
    AccountType["EQUITY_EQUITY"] = "EQUITY_EQUITY";
    AccountType["REVENUE_INCOME"] = "REVENUE_INCOME";
    AccountType["REVENUE_OTHER_INCOME"] = "REVENUE_OTHER_INCOME";
    AccountType["EXPENSE_EXPENSE"] = "EXPENSE_EXPENSE";
    AccountType["EXPENSE_OTHER_EXPENSE"] = "EXPENSE_OTHER_EXPENSE";
    AccountType["EXPENSE_COGS"] = "EXPENSE_COGS"; // net income
})(AccountType || (exports.AccountType = AccountType = {}));
exports.AccountsTypesByClass = {
    [accountClass_enum_1.AccountClass.ASSET]: [
        AccountType.ASSET_ACCOUNTS_RECEIVABLE,
        AccountType.ASSET_OTHER_CURRENT_ASSETS,
        AccountType.ASSET_BANK,
        AccountType.ASSET_FIXED_ASSETS,
        AccountType.ASSET_OTHER_ASSETS
    ],
    [accountClass_enum_1.AccountClass.LIABILITY]: [
        AccountType.LIABILITY_ACCOUNTS_PAYABLE,
        AccountType.LIABILITY_CREDIT_CARD,
        AccountType.LIABILITY_OTHER_CURRENT_LIABILIY,
        AccountType.LIABILITY_LONG_TERM_LIABILITY
    ],
    [accountClass_enum_1.AccountClass.EQUITY]: [AccountType.EQUITY_EQUITY],
    [accountClass_enum_1.AccountClass.REVENUE]: [AccountType.REVENUE_INCOME, AccountType.REVENUE_OTHER_INCOME],
    [accountClass_enum_1.AccountClass.EXPENSE]: [AccountType.EXPENSE_EXPENSE, AccountType.EXPENSE_OTHER_EXPENSE, AccountType.EXPENSE_COGS]
};
