import type { GetAllPaymentTokensForTenantQuery } from "@doorloop/dto";
import { useQuery } from "@tanstack/react-query";
import { tenantsApi } from "api/tenantsApi";
import { createQueryStoreWithDictionary } from "api/entityApiStore/createDictionaryQueryStore";
import { createMutationStore } from "api/entityApiStore/createMutationStore";

export const tenantsStore = {
  queries: {
    ...createQueryStoreWithDictionary("tenants", tenantsApi),
    useGetTenantPaymentMethods: (query: GetAllPaymentTokensForTenantQuery) =>
      useQuery(["tenants-payments-search-from-dictionary", query?.tenant], async ({ signal }) => {
        if (!query?.tenant) {
          return null;
        }

        const response = await tenantsApi.getPaymentTokensForTenant(query);

        if (signal?.aborted) return null;

        return response;
      })
  },
  mutations: createMutationStore("tenants", tenantsApi)
} as const;
