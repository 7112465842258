"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecurringExpenseInfoDto = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const checkPayableInfo_dto_1 = require("../common/checkPayableInfo.dto");
const expensePayTo_enum_1 = require("../journalEntries/expenses/expensePayTo.enum");
const paymentMethod_enum_1 = require("../journalEntries/paymentMethod.enum");
const expenseTransactionLine_dto_1 = require("../journalEntries/shared/expenseTransactionLine.dto");
class RecurringExpenseInfoDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.RecurringExpenseInfoDto = RecurringExpenseInfoDto;
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], RecurringExpenseInfoDto.prototype, "payFromAccount", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(paymentMethod_enum_1.PaymentMethod),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], RecurringExpenseInfoDto.prototype, "paymentMethod", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(expensePayTo_enum_1.ExpensePayToResourceType),
    __metadata("design:type", String)
], RecurringExpenseInfoDto.prototype, "payToResourceType", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], RecurringExpenseInfoDto.prototype, "payToResourceId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], RecurringExpenseInfoDto.prototype, "memo", void 0);
__decorate([
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ArrayMinSize)(1),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => expenseTransactionLine_dto_1.ExpenseTransactionLineDto),
    __metadata("design:type", Array)
], RecurringExpenseInfoDto.prototype, "lines", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => checkPayableInfo_dto_1.CheckPayableInfoDto),
    __metadata("design:type", checkPayableInfo_dto_1.CheckPayableInfoDto)
], RecurringExpenseInfoDto.prototype, "checkInfo", void 0);
