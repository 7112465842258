import type { FC } from "react";
import React, { useMemo } from "react";
import Text from "DLUI/text";
import { FormikImageButtonGroup } from "DLUI/form";
import { FastField, getIn, useFormikContext } from "formik";
import type { ClientAnnouncementDto } from "@doorloop/dto";
import { SelectPropertiesType } from "@doorloop/dto";
import { FieldNames } from "screens/announcements/newAnnouncement/selectRecipients/selectRecipients";
import AppStrings from "locale/keys";
import { View } from "DLUI/view";
import { whichPropertiesItemsAnnouncement } from "screens/bills/payBills/types";
import { AnimatedView } from "DLUI/view/animatedView";
import { useTranslation } from "react-i18next";
import { propertyGroupsApi } from "api/propertyGroupsApi";
import { propertiesApi } from "api/propertiesApi";
import FormikCachedMultiSelectAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedMultiSelectAutoComplete";

interface Props {
  onListChange: () => void;
}

const PropertySelection: FC<Props> = ({ onListChange }: Props) => {
  const { t } = useTranslation();
  const formikRef = useFormikContext<ClientAnnouncementDto>();
  /**
   * needed?
   * not in use
   */
  const properties = getIn(formikRef.values, FieldNames.Properties) || [];

  const didSelectPropertyRecipients = (selectedValue) => {
    formikRef.resetForm();
    formikRef.setFieldValue("recipientPropertiesType", selectedValue);
  };

  const recipientPropertiesType = useMemo(
    () => formikRef?.values?.recipientPropertiesType,
    [formikRef.values?.recipientPropertiesType]
  );

  return (
    <View marginBottom={20} alignItems={"center"}>
      <Text value={AppStrings.Announcements.whichProperties} fontSize={16} color={"black"} marginBottom={20} />
      <FastField
        component={FormikImageButtonGroup}
        uniqueId={`${FieldNames.Properties}-imageButton`}
        buttonItemsArray={whichPropertiesItemsAnnouncement}
        onSelect={didSelectPropertyRecipients}
        name={"recipientPropertiesType"}
        size={140}
      />
      <AnimatedView shouldShow={recipientPropertiesType === SelectPropertiesType.SOME_PROPERTIES}>
        <View justifyContent={"center"} alignItems={"center"} marginBottom={20}>
          {recipientPropertiesType === SelectPropertiesType.SOME_PROPERTIES && (
            <FormikCachedMultiSelectAutoComplete
              uniqueIndex={FieldNames.Properties}
              apiHandler={propertiesApi}
              displayNameKey={"name"}
              filterFieldName={"filter_text"}
              filterFieldValue={"name"}
              selectionFields={["id", "class"]}
              name={FieldNames.Properties}
              label={t(AppStrings.Vendors.NewVendor.SelectProperties)}
              marginTop={20}
              errorLabelPaddingLeft={15}
              // defaultValue={formikRef?.values?.properties}
              maxWidth={450}
              queryParams={{ filter_active: true }}
              onChange={onListChange}
            />
          )}
        </View>
      </AnimatedView>
      <AnimatedView shouldShow={recipientPropertiesType === SelectPropertiesType.PORTFOLIO}>
        <View justifyContent={"center"} alignItems={"center"} marginBottom={20}>
          {recipientPropertiesType === SelectPropertiesType.PORTFOLIO && (
            <FormikCachedMultiSelectAutoComplete
              uniqueIndex={FieldNames.Portfolios}
              apiHandler={propertyGroupsApi}
              displayNameKey={"name"}
              filterFieldName={"filter_text"}
              filterFieldValue={"name"}
              selectionFields={["id", "class"]}
              name={FieldNames.Portfolios}
              // defaultValue={formikRef?.values?.propertyGroups}
              label={t(AppStrings.Announcements.selectPortfolios)}
              marginTop={20}
              errorLabelPaddingLeft={15}
              maxWidth={450}
            />
          )}
        </View>
      </AnimatedView>
    </View>
  );
};

export default PropertySelection;
