import type { ConvenienceFeesSelectionType } from "@doorloop/dto";
import {
  AccountType,
  ConvenienceFeesPolicyDto,
  createValidator,
  DataCy,
  LeaseDto,
  PropertyDto,
  PropertySettingsDto,
  SegmentEventTypes,
  SettingPermission
} from "@doorloop/dto";
import { propertiesApi } from "api/propertiesApi";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import { View } from "DLUI/view";
import { FastField, FormikContext, useFormik } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import { useParams } from "react-router-dom";
import { useEffectAsync } from "../../../../../hooks/useEffectAsync";
import { settingsApi } from "api/settingsApi";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { ArticleIdsEnum, HelpTypeEnum } from "DLUI/screen/helpPanel/types";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import Text from "DLUI/text";
import { CheckmarkIcon } from "../../../../../assets";
import { Icon } from "DLUI/icon";
import ColorsEnum from "../../../../../utils/colorsEnum";
import WarningView from "DLUI/form/warningView/warningView";
import { RadioGroup, TextField } from "DLUI/form";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { leasesApi } from "api/leasesApi";
import ActionIconLink from "../../../link/actionIconLink";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { SettingsReadOnlyScope } from "DLUI/permissionScope/readOnlyScope";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import { useUserType } from "../../../../../hooks/useUserType";
import type { SettingsLevelType } from "../../../../../utils/settingsLevel";
import { analyticsService } from "../../../../../services/analyticsService";
import { accountsApi } from "api/accounts";

interface EditConvenienceFeesProps {
  didFinishOperation: (values: ConvenienceFeesPolicyDto) => void;
  onBackdropClick: () => void;
  dialogTitle: string;
}

export const EditConvenienceFees: React.FC<EditConvenienceFeesProps> = ({
  didFinishOperation,
  onBackdropClick,
  dialogTitle
}: EditConvenienceFeesProps) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();
  const { dialogHorizontalPadding } = DialogsHelper();
  const { propertyId } = useParams<{ propertyId?: string }>();
  const { leaseId } = useParams<{ leaseId?: string }>();
  const [propertyData, setPropertyData] = useState<Readonly<PropertyDto | null>>(null);
  const [leaseData, setLeaseData] = useState<Readonly<LeaseDto | null>>(null);
  const [viewIndex, setViewIndex] = useState<number>(1);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>(AppStrings.Common.NetworkErrorSubTitle);
  const [hideLoadingDialogActionButtons, setHideLoadingDialogActionButtons] = useState<boolean>(false);
  const [hasCriticalError, setHasCriticalError] = useState(false);
  const { isHOAUser } = useUserType();

  const helpObj: HelpPanelProps = {
    description: AppStrings.Settings.GeneralSettings.ConvenienceFees.SettingsDialogSubTitle,
    actionItems: [
      {
        type: HelpTypeEnum.INTERCOM_ARTICLE,
        topic: AppStrings.Settings.GeneralSettings.ConvenienceFees.HelpText,
        articleId: isHOAUser ? ArticleIdsEnum.HOA_CONVENIENCE_FEES : ArticleIdsEnum.CONVENIENCE_FEES
      }
    ]
  };

  const supportUrl = isHOAUser
    ? "https://support.doorloop.com/en/articles/8951328-setting-up-convenience-fees-hoa#h_57dca2dcd2"
    : "https://support.doorloop.com/en/articles/8751594-setting-up-convenience-fees#h_eaef0de940";

  const validateForm = useMemo(() => createValidator(ConvenienceFeesPolicyDto), []);

  const initFormValues = (): ConvenienceFeesPolicyDto =>
    new ConvenienceFeesPolicyDto({
      amount: 0,
      account: "",
      selectionType: "companyDefault"
    });

  const formik = useFormik({
    initialValues: initFormValues(),
    onSubmit: _.noop,
    validate: validateForm
  });

  const setConvenienceFeeFieldsValues = async (convenienceFeesSettings: ConvenienceFeesPolicyDto) => {
    await formik.setFieldValue("amount", convenienceFeesSettings.amount);
    await formik.setFieldValue("account", convenienceFeesSettings.account);
  };

  const getConvenienceFeeDefaultAccount = useCallback(async (setFormikValues = true) => {
    const defaultAccounts = await settingsApi.getDefaultAccountsData();
    if (defaultAccounts.status && defaultAccounts.data.income_convenienceFee) {
      const accountId = defaultAccounts.data.income_convenienceFee;
      const convenienceFeesPolicyDto = new ConvenienceFeesPolicyDto({
        amount: 0,
        account: accountId
      });
      if (setFormikValues) {
        await setConvenienceFeeFieldsValues(convenienceFeesPolicyDto);
      }

      setViewIndex(0);

      return convenienceFeesPolicyDto;
    }
    showDialogError(t(AppStrings.Settings.GeneralSettings.ConvenienceFees.DefaultAccountError), true);
  }, []);

  const handleLoadGlobalSettingsData = useCallback(async () => {
    const response = await settingsApi.getConvenienceFeesSettings();
    if (response.status && !_.isEmpty(response.data)) {
      const convenienceFeesPolicy = response.data;
      await setConvenienceFeeFieldsValues(convenienceFeesPolicy);
      await formik.setFieldValue("selectionType", "specifyFees");
      setViewIndex(0);
    } else {
      await getConvenienceFeeDefaultAccount(true);
    }
  }, []);

  const handleLoadPropertyData = useCallback(async () => {
    if (propertyId) {
      const propertyResponse = await propertiesApi.get(propertyId);
      if (propertyResponse.status) {
        const propertyDto = new PropertyDto({ ...propertyResponse.data });
        if (!propertyDto.settings) {
          propertyDto.settings = new PropertySettingsDto();
        }
        if (propertyDto.settings?.convenienceFeesPolicy) {
          const { convenienceFeesPolicy } = propertyDto.settings;
          await setConvenienceFeeFieldsValues(convenienceFeesPolicy);
          await formik.setFieldValue("selectionType", "specifyFees");
        } else {
          propertyDto.settings.convenienceFeesPolicy = await getConvenienceFeeDefaultAccount();
          await formik.setFieldValue("selectionType", "companyDefault");
        }

        setPropertyData(propertyDto);
        setViewIndex(0);
      } else {
        showDialogError(propertyResponse.message, true);
      }
    }
  }, [getConvenienceFeeDefaultAccount, propertyId]);

  const handleLoadLeaseData = useCallback(async () => {
    if (leaseId) {
      const leaseResponse = await leasesApi.get(leaseId);
      if (leaseResponse.status) {
        const leaseDto = new LeaseDto({ ...leaseResponse.data });
        if (!leaseDto.settings) {
          leaseDto.settings = new PropertySettingsDto();
        }
        if (leaseDto.settings?.convenienceFeesPolicy) {
          const { convenienceFeesPolicy } = leaseDto.settings;
          await setConvenienceFeeFieldsValues(convenienceFeesPolicy);
          await formik.setFieldValue("selectionType", "specifyFees");
        } else {
          leaseDto.settings.convenienceFeesPolicy = await getConvenienceFeeDefaultAccount();
          await formik.setFieldValue("selectionType", "companyDefault");
        }

        setLeaseData(leaseDto);
        setViewIndex(0);
      } else {
        showDialogError(leaseResponse.message, true);
      }
    }
  }, [getConvenienceFeeDefaultAccount, leaseId]);

  const handleLoadedData = async () => {
    if (propertyId) {
      await handleLoadPropertyData();
    } else if (leaseId) {
      await handleLoadLeaseData();
    } else {
      await handleLoadGlobalSettingsData();
    }
  };

  const showDialogError = (errorMessage?: string, isCritical = false) => {
    setHideLoadingDialogActionButtons(false);
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(errorMessage || t(AppStrings.Common.GeneralError));
    setHasCriticalError(isCritical);
  };

  useEffectAsync(async () => {
    await handleLoadedData();
  }, []);

  const isValidForm = async () => {
    await formik.setFieldTouched(`amount`, true, true);
    await formik.setFieldTouched(`account`, true, true);

    const errors = await formik.validateForm();

    return _.isEmpty(errors);
  };

  const sendSettingsSavedAnalyticsEvent = (
    level: SettingsLevelType,
    amount: number,
    account: string,
    isCompanyDefault = false
  ) => {
    const accountName = accountsApi.getItemFromDictionary(account)?.name;
    analyticsService.track(
      SegmentEventTypes.CONVENIENCE_FEES_SETTINGS_SAVED,
      {
        level,
        amount,
        accountName,
        isCompanyDefault
      },
      { trackEventInIntercom: true }
    );
  };

  const updateProperty = async (resetConvenienceFeesValues = false) => {
    const propertyDto = new PropertyDto({ ...propertyData });
    if (propertyDto && propertyDto.settings && propertyId) {
      if (resetConvenienceFeesValues) {
        propertyDto.settings.convenienceFeesPolicy = undefined;
      } else {
        propertyDto.settings.convenienceFeesPolicy = formik.values;
      }

      const response = await propertiesApi.update(propertyId, propertyDto);
      if (response.status) {
        setLoadingDialogState(DialogState.Success);
        sendSettingsSavedAnalyticsEvent(
          "property",
          _.toNumber(propertyDto.settings.convenienceFeesPolicy?.amount),
          _.toString(propertyDto.settings.convenienceFeesPolicy?.account),
          resetConvenienceFeesValues
        );
        didFinishOperation(formik.values);
      } else {
        showDialogError(response.message);
      }
    }
  };
  const updateLease = async (resetConvenienceFeesValues = false) => {
    const leaseDto = new LeaseDto({ ...leaseData });
    if (leaseDto && leaseDto.settings && leaseId) {
      if (resetConvenienceFeesValues) {
        leaseDto.settings.convenienceFeesPolicy = undefined;
      } else {
        leaseDto.settings.convenienceFeesPolicy = formik.values;
      }
      const response = await leasesApi.update(leaseId, leaseDto);
      if (response.status) {
        setLoadingDialogState(DialogState.Success);
        sendSettingsSavedAnalyticsEvent(
          "lease",
          _.toNumber(leaseDto.settings.convenienceFeesPolicy?.amount),
          _.toString(leaseDto.settings.convenienceFeesPolicy?.account),
          resetConvenienceFeesValues
        );
        didFinishOperation(formik.values);
      } else {
        showDialogError(response.message);
      }
    }
  };
  const updateDefaultAccount = async () => {
    const response = await settingsApi.updateConvenienceFeesSettings(formik.values);
    if (response.status) {
      setLoadingDialogState(DialogState.Success);
      sendSettingsSavedAnalyticsEvent("global", _.toNumber(formik.values.amount), _.toString(formik.values.account));
      didFinishOperation(formik.values);
    } else {
      showDialogError(response.message);
    }
  };

  const didPressSaveButton = async () => {
    if (formik !== null) {
      const isValid = await isValidForm();
      const resetConvenienceFeesValues = formik.values.selectionType === "companyDefault";
      if (isValid || resetConvenienceFeesValues) {
        setLoadingDialogState(DialogState.Show);
        setViewIndex(1);
        if (propertyId) {
          await updateProperty(resetConvenienceFeesValues);
        } else if (leaseId) {
          await updateLease(resetConvenienceFeesValues);
        } else {
          await updateDefaultAccount();
        }
      }
    }
  };

  const renderActionPanelButtons = () => (
    <FormActionButtons
      propsMainButton={{
        type: "cta",
        props: {
          onClick: didPressSaveButton,
          dataCy: DataCy.globalSettings.convenienceFeesSettings.dialogItems.saveButton
        }
      }}
      propsSubButton={{
        onClick: onBackdropClick,
        dataCy: DataCy.globalSettings.convenienceFeesSettings.dialogItems.cancelButton
      }}
    />
  );

  const didPressDismissButton = () => {
    if (hasCriticalError) {
      onBackdropClick();
    } else {
      setLoadingDialogState(DialogState.Hidden);
      setViewIndex(0);
    }
  };

  const onRetryButtonPress = () => {
    if (formik !== null) {
      setLoadingDialogState(DialogState.Hidden);
      setViewIndex(0);
    }
  };

  const onAmountChange = async (nextValue: string) => {
    await formik.setFieldValue("amount", nextValue);
  };

  const didChangeSelectionType = async (value: ConvenienceFeesSelectionType) => {
    await formik.setFieldValue("selectionType", value);
  };

  const renderForm = () => (
    <FormikContext.Provider value={formik}>
      <View
        marginTop={16}
        marginBottom={16}
        paddingLeft={dialogHorizontalPadding}
        paddingRight={dialogHorizontalPadding}
        height={"auto"}
        gap={16}
      >
        {(leaseId || propertyId) && (
          <View gap={16}>
            <FastField
              uniqueKey={"LF"}
              component={RadioGroup}
              name={"selectionType"}
              defaultValue={formik.values.selectionType === "companyDefault" ? "companyDefault" : "specifyFees"}
              didChangeSelection={didChangeSelectionType}
              radioGroupItems={[
                {
                  label: AppStrings.Settings.GeneralSettings.ConvenienceFees.UseCompanyDefaultConvenienceFees,
                  value: "companyDefault"
                },
                {
                  label: propertyId
                    ? AppStrings.Settings.GeneralSettings.ConvenienceFees.SpecifyConvenienceFeesForProperty
                    : AppStrings.Settings.GeneralSettings.ConvenienceFees.SpecifyConvenienceFeesForLease,
                  value: "specifyFees"
                }
              ]}
              dataCy={
                DataCy.globalSettings.convenienceFeesSettings.convenienceFeesSettingsCompanyDefaultOrSpecifyRadioButton
              }
            />
            <HorizontalSeparationLine />
          </View>
        )}
        <View
          alignItems={"flex-start"}
          shouldShow={formik.values.selectionType === "specifyFees"}
          showAnimation={"fade-in"}
          hideAnimation={"fade-out"}
        >
          <View flexDirection={"column"} gap={8} marginBottom={12}>
            <Text
              bold
              lineHeight={"18px"}
              value={AppStrings.Settings.GeneralSettings.ConvenienceFees.SettingsDialogBenefitsTitle}
            />
            <View flexDirection={"column"} gap={8}>
              <View flexDirection={"row"} alignItems={"center"} gap={8}>
                <Icon Source={CheckmarkIcon} pathColor={"green"} size={16} />
                <Text value={AppStrings.Settings.GeneralSettings.ConvenienceFees.SettingsDialogBenefits1} />
              </View>
              <View flexDirection={"row"} alignItems={"center"} gap={8}>
                <Icon Source={CheckmarkIcon} pathColor={"green"} size={16} />
                <Text value={AppStrings.Settings.GeneralSettings.ConvenienceFees.SettingsDialogBenefits2} />
              </View>
              <View flexDirection={"row"} alignItems={"center"} gap={8}>
                <Icon Source={CheckmarkIcon} pathColor={"green"} size={16} />
                <Text value={AppStrings.Settings.GeneralSettings.ConvenienceFees.SettingsDialogBenefits3} />
              </View>
            </View>
          </View>
          <HorizontalSeparationLine />
          <View flexDirection={"column"} noWrap marginTop={16}>
            <Text
              bold
              fontSize={16}
              lineHeight={"19px"}
              value={AppStrings.Settings.GeneralSettings.ConvenienceFees.InputGroupLabel}
            />
            <View flexDirection={isMobile ? "column" : "row"} marginTop={24} noWrap gap={16}>
              <View flexDirection={"column"} gap={16} flex={1} width={isMobile ? "100%" : 310} noWrap>
                <Text
                  align={"left"}
                  value={AppStrings.Settings.GeneralSettings.ConvenienceFees.InputAmountLabel}
                  color={"secondary-gray"}
                  variant={"listItemTitle"}
                  fontWeight={500}
                />
                <View>
                  <FastFieldSafe
                    required
                    textAlign={"left"}
                    component={TextField}
                    label={"Amount"}
                    name={"amount"}
                    formatType={"currency"}
                    decimalScale={2}
                    onChange={onAmountChange}
                    dataCy={DataCy.globalSettings.convenienceFeesSettings.dialogItems.amountInput}
                  />
                </View>
              </View>
              <View flexDirection={"column"} gap={16} flex={1} width={isMobile ? "100%" : 310} noWrap>
                <Text
                  align={"left"}
                  value={AppStrings.Settings.GeneralSettings.ConvenienceFees.InputMethodLabel}
                  color={"secondary-gray"}
                  variant={"listItemTitle"}
                  fontWeight={500}
                />
                <View>
                  <BankAccountFormikAutoCompleteField
                    label={t(AppStrings.Common.Settings.LateFees.Account)}
                    uniqueIndex={"convenienceFeeAccount"}
                    queryParams={{
                      filter_types: AccountType.REVENUE_INCOME,
                      filter_active: true
                    }}
                    name={"account"}
                    defaultValue={formik.values.account}
                    selectionFields={["id", "name", "defaultAccountFor"]}
                    dataCy={DataCy.globalSettings.convenienceFeesSettings.dialogItems.accountInput}
                  />
                </View>
              </View>
            </View>
          </View>
          <View marginTop={16}>
            <WarningView
              type={"warning"}
              iconSize={24}
              minHeight={30}
              justifyContent={"center"}
              separationLineColor={ColorsEnum.YellowFaded}
              borderRadius={4}
            >
              <View>
                <Text
                  color={"secondary-yellow"}
                  value={AppStrings.Settings.GeneralSettings.ConvenienceFees.WarningText}
                />
                <ActionIconLink
                  linkText={AppStrings.Notifications.LearnMore}
                  marginTop={0}
                  onClick={() => window.open(supportUrl, "_blank")}
                />
              </View>
            </WarningView>
          </View>
        </View>
      </View>
    </FormikContext.Provider>
  );

  const renderView = ({ index }: any) => {
    if (index === 0) {
      return renderForm();
    }
    if (index === 1) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={t(AppStrings.Common.Loading)}
            errorText={loadingDialogErrorText}
            successText={t(AppStrings.Common.OperationCompleted)}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
            hideButtons={hideLoadingDialogActionButtons}
          />
        </View>
      );
    }
    return <div />;
  };

  const _onBackdropClick = () => {
    if (viewIndex === 1) {
      setViewIndex(0);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      return "sectionTitleFrame";
    }
    if (viewIndex === 1) {
      return "contentOnly";
    }

    return "topPanel";
  }, [viewIndex]);

  return (
    <SettingsReadOnlyScope permission={SettingPermission.convenienceFees}>
      <DialogFrame
        onCloseButtonClick={_onBackdropClick}
        title={dialogTitle}
        width={getDialogFrameDimension("width", 670)}
        height={getDialogFrameDimension("height", 700)}
        renderView={renderView}
        numViews={2}
        activeView={viewIndex}
        RenderActionPanelButtons={renderActionPanelButtons}
        frameType={frameType}
        helpPanel={helpObj}
      />
    </SettingsReadOnlyScope>
  );
};
