"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAllTasksQuery = void 0;
const linkedResourceType_enum_1 = require("../common/linkedResourceType.enum");
const isDateStringWithoutTime_validator_1 = require("../common/validators/isDateStringWithoutTime.validator");
const class_validator_1 = require("class-validator");
const getAllBaseRequest_query_1 = require("../getAllBaseRequest.query");
const taskPriority_enum_1 = require("./taskPriority.enum");
const taskStatus_enum_1 = require("./taskStatus.enum");
const taskType_enum_1 = require("./taskType.enum");
const taskStatusForQuery_enum_1 = require("../tasks/taskStatusForQuery.enum");
class GetAllTasksQuery extends getAllBaseRequest_query_1.GetAllBaseQueryRequest {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.GetAllTasksQuery = GetAllTasksQuery;
__decorate([
    (0, class_validator_1.IsEnum)(taskPriority_enum_1.TaskPriority),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllTasksQuery.prototype, "filter_priority", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(taskType_enum_1.TaskType),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllTasksQuery.prototype, "filter_requestedByType", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllTasksQuery.prototype, "filter_requestedById", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllTasksQuery.prototype, "filter_assignedToUser", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllTasksQuery.prototype, "filter_status", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsEnum)(taskStatus_enum_1.TaskStatus, { each: true }),
    __metadata("design:type", Array)
], GetAllTasksQuery.prototype, "filter_statuses", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllTasksQuery.prototype, "filter_category", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllTasksQuery.prototype, "filter_portfolio", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllTasksQuery.prototype, "filter_property", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], GetAllTasksQuery.prototype, "filter_properties", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllTasksQuery.prototype, "filter_propertyGroup", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllTasksQuery.prototype, "filter_unit", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllTasksQuery.prototype, "filter_linkedResourceId", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.filter_linkedResourceId),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(linkedResourceType_enum_1.LinkedResourceType),
    __metadata("design:type", String)
], GetAllTasksQuery.prototype, "filter_linkedResourceType", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllTasksQuery.prototype, "filter_createdAt_to", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllTasksQuery.prototype, "filter_createdAt_from", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllTasksQuery.prototype, "filter_dueDate_to", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllTasksQuery.prototype, "filter_dueDate_from", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllTasksQuery.prototype, "filter_completedAt_to", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllTasksQuery.prototype, "filter_completedAt_from", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(taskType_enum_1.TaskType),
    __metadata("design:type", String)
], GetAllTasksQuery.prototype, "filter_type", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllTasksQuery.prototype, "filter_workOrderAssignedToVendor", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllTasksQuery.prototype, "filter_workOrderApprovedByOwner", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllTasksQuery.prototype, "filter_workOrderBilled", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], GetAllTasksQuery.prototype, "filter_requestType", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], GetAllTasksQuery.prototype, "filter_maintenanceCategory", void 0);
