import React, { useEffect, useState } from "react";

import clsx from "clsx";
import type { FormikProps } from "formik";
import { getIn } from "formik";
import { useTranslation } from "react-i18next";

import type { FileDto, OwnerPortalSettingsDto } from "@doorloop/dto";

import AnimatedContent, { getAnimatedContentFormikRef } from "DLUI/dialogs/components/animatedContent";
import AppStrings from "locale/keys";
import Dialog from "DLUI/dialogs/dialog";
import type { FrameType } from "DLUI/dialogs/components/dialogFrame";
import DialogFrame from "DLUI/dialogs/components/dialogFrame";
import type { ApiResult } from "api/apiResult";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { Scroller } from "DLUI/screen";
import { settingsApi } from "api/settingsApi";
import type { SVGIconComponent } from "assets/icons/types";
import type { ValidateOwnerPortalFormResponse } from "screens/settings/ownerPortal/formikHelper";

import makeStyles from "./styles";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

const DIALOG_WIDTH = 1100;

const DIALOG_HEIGHT = 700;

export interface MenuItem {
  title: string;
  isSelected?: boolean;
  icon: SVGIconComponent;
  contentComponent: (
    formikRef: FormikProps<OwnerPortalSettingsDto>,
    onFileReceive?: (receivedFile: File) => void,
    onFileDelete?: () => void
  ) => any;
  uploadFile?: boolean;
}

interface ComponentProps {
  onClose: (values?: OwnerPortalSettingsDto, shouldRefresh?: boolean) => void;
  className?: string;
  disableBackdropClick?: boolean;
  dialogState: DialogState;
  onRetryButtonPress?: (formikRef?: FormikProps<OwnerPortalSettingsDto>) => void;
  onBackdropClick?: () => void;
  showCloseIcon?: boolean;
  refreshEvent?: () => void;
  sectionItems: MenuItem[];
  initFormValues: () => OwnerPortalSettingsDto;
  formikValidation?: (values: OwnerPortalSettingsDto) => any;
  validationMethod: (formikRef: FormikProps<OwnerPortalSettingsDto>) => Promise<ValidateOwnerPortalFormResponse>;
  dialogTitle: string;
  dialogHeight?: number;
  dialogWidth?: number;
}

let formikGlobalRef: FormikProps<OwnerPortalSettingsDto> | null = null;

type UploadFileType = "logo" | "coverImage";

enum ViewIndex {
  LoadingOrContent,
  Alert
}

export const OwnerPortalAnimatedContentDialog: React.FC<ComponentProps> = ({
  dialogState,
  onClose,
  className,
  onBackdropClick,
  refreshEvent,
  sectionItems,
  initFormValues,
  formikValidation,
  validationMethod,
  dialogTitle,
  dialogHeight,
  dialogWidth
}: ComponentProps) => {
  const classes = makeStyles();
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();

  const [scroller, setScroller] = useState<Scroller | null>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [currentMenuItemTitle, setCurrentMenuItemTitle] = useState<string>("");
  const [viewIndex, setViewIndex] = useState(ViewIndex.LoadingOrContent);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>(t(AppStrings.Common.GeneralError));
  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState<string>(t(AppStrings.Common.Loading));
  const [loadingDialogSuccessText, setLoadingDialogSuccessText] = useState<string>(
    t(AppStrings.Common.OperationCompleted)
  );
  const [currentSelectedMenuIndex, setCurrentSelectedMenuIndex] = useState<number | undefined>();
  const [ownerPortalLogoPendingFile, setOwnerPortalLogoPendingFile] = useState<File | undefined>();
  const [ownerPortalCoverPendingFile, setOwnerPortalCoverPendingFile] = useState<File | undefined>();
  const [settingsData, setSettingsData] = useState<OwnerPortalSettingsDto | null>(null);
  const [deleteCoverImageOnSave, setDeleteCoverImageOnSave] = useState<boolean>(false);
  const [deleteLogoImageOnSave, setDeleteLogoImageOnSave] = useState<boolean>(false);

  useEffect(() => {
    if (dialogState === DialogState.Show) {
      setShowDialog(true);

      if (scroller == null) {
        setScroller(new Scroller(document.getElementsByClassName("MuiDialog-scrollBody")[0] as HTMLDivElement));
      }
    } else if (dialogState === DialogState.Hidden) {
      setShowDialog(false);
    }
  }, [dialogState]);

  useEffect(() => {
    loadSettingsData();

    return () => {
      formikGlobalRef = null;
    };
  }, []);

  const loadSettingsData = async (): Promise<void> => {
    setLoadingDialogLoadingText(t(AppStrings.Common.Loading));
    setLoadingDialogState(DialogState.Show);

    const { status, data, message } = await settingsApi.getOwnerPortalSettings();

    if (status && data) {
      setSettingsData(data);

      setLoadingDialogSuccessText(t(AppStrings.Common.OperationCompleted));
      setLoadingDialogState(DialogState.Success);

      setTimeout(() => {
        setLoadingDialogState(DialogState.Hidden);
      }, 0);
    } else {
      setLoadingDialogErrorText(message);
      setLoadingDialogState(DialogState.Error);
    }
  };

  const didFinishOperation = (values: OwnerPortalSettingsDto) => {
    onClose(values);

    setTimeout(() => {
      setViewIndex(ViewIndex.LoadingOrContent);

      if (refreshEvent) {
        refreshEvent();
      }
    }, 500);
  };

  const renderActionPanelButtons = (formik: FormikProps<any>) => {
    formikGlobalRef = formik;
    return (
      <FormActionButtons
        propsSubButton={{ onClick: () => onBackdropClick?.() }}
        propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
      />
    );
  };

  const didPressDismissButton = (): void => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(ViewIndex.LoadingOrContent);
  };

  const getFormikInitialValues = (): OwnerPortalSettingsDto => {
    if (formikGlobalRef && formikGlobalRef.values && formikGlobalRef.values) {
      return formikGlobalRef.values;
    }

    if (settingsData) {
      return settingsData;
    }

    return initFormValues();
  };

  const renderView = ({ index }: { index: ViewIndex }) => {
    if (index === ViewIndex.LoadingOrContent) {
      if (loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={loadSettingsData}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }

      return (
        <AnimatedContent
          formikInitialValues={getFormikInitialValues}
          sectionItems={sectionItems}
          formikValidation={formikValidation}
          dialogHeight={dialogHeight || DIALOG_HEIGHT}
          renderActionPanelButtons={renderActionPanelButtons}
          onMenuItemSelection={setCurrentMenuItemTitle}
          selectedMenuIndex={currentSelectedMenuIndex}
          onTenantPortalLogoFileReceive={handleLogoFileReceive}
          onTenantPortalLogoFileDelete={handleLogoFileDelete}
          onCoverImageFileReceive={handleCoverImageFileReceive}
          onCoverImageFileDelete={handleCoverImageFileDelete}
        />
      );
    }

    if (index === ViewIndex.Alert) {
      return (
        <div className={clsx([isMobile ? classes.mobileDialogContainer : classes.dialogContainer])}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={onLoadingDialogRetryButtonPress}
            didPressDismissButton={didPressLoadingDialogDismissButton}
          />
        </div>
      );
    }

    return <div />;
  };

  const uploadFile = async (type: UploadFileType, file?: File): Promise<void> => {
    if (!file) {
      return;
    }

    setLoadingDialogState(DialogState.Show);

    let result: ApiResult<FileDto>;

    if (type === "logo") {
      result = await settingsApi.uploadOwnerPortalLogo(file);
    } else if (type === "coverImage") {
      result = await settingsApi.uploadOwnerPortalCoverImage(file);
    } else {
      return;
    }

    const { status, message } = result;

    if (status) {
      setLoadingDialogSuccessText(t(AppStrings.Common.OperationCompleted));
      setLoadingDialogState(DialogState.Success);

      setTimeout(() => {
        setLoadingDialogState(DialogState.Hidden);
      }, 0);

      formikGlobalRef = null;
    } else {
      formikGlobalRef!.setSubmitting(false);

      setLoadingDialogErrorText(message);
      setLoadingDialogState(DialogState.Error);
    }
  };

  const deleteOwnerPortalLogo = async (): Promise<void> => {
    await settingsApi.deleteOwnerPortalLogo();
  };

  const deleteOwnerPortalCoverImage = async (): Promise<void> => {
    await settingsApi.deleteOwnerPortalCover();
  };

  const updateSettingsData = async (): Promise<void> => {
    const formikRef = getAnimatedContentFormikRef();

    if (formikRef !== null) {
      setLoadingDialogLoadingText(t(AppStrings.Common.Loading));
      setLoadingDialogState(DialogState.Show);
      setViewIndex(ViewIndex.Alert);

      const { status, data, message } = await settingsApi.updateOwnerPortalSettings(formikRef.values);

      if (status && data) {
        if (ownerPortalLogoPendingFile) {
          await uploadFile("logo", ownerPortalLogoPendingFile);
        }

        if (ownerPortalCoverPendingFile) {
          await uploadFile("coverImage", ownerPortalCoverPendingFile);
        }

        if (deleteLogoImageOnSave) {
          const isLogoUrlPrefilled = getIn(formikRef.values, "theme.isLogoUrlPrefilled");

          if (!isLogoUrlPrefilled) {
            await deleteOwnerPortalLogo();
          }
        }

        if (deleteCoverImageOnSave) {
          const isBackgroundUrlPrefilled = getIn(formikRef.values, "theme.isBackgroundUrlPrefilled");

          if (!isBackgroundUrlPrefilled) {
            await deleteOwnerPortalCoverImage();
          }
        }

        didFinishOperation(data);
        setLoadingDialogSuccessText(t(AppStrings.Common.OperationCompleted));
        setLoadingDialogState(DialogState.Hidden);
        formikGlobalRef = null;
      } else {
        setLoadingDialogErrorText(message);
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const didPressSaveButton = async (): Promise<void> => {
    if (!formikGlobalRef) {
      return;
    }

    const { isValid, errorStepIndex } = await validationMethod(formikGlobalRef);

    if (isValid) {
      setTimeout(() => {
        updateSettingsData();
      }, 0);
    } else if (errorStepIndex !== undefined) {
      setCurrentSelectedMenuIndex(errorStepIndex);

      setTimeout(() => {
        setCurrentSelectedMenuIndex(undefined);
      }, 0);
    }
  };

  const onLoadingDialogRetryButtonPress = async (): Promise<void> => {
    setLoadingDialogState(DialogState.Show);

    await updateSettingsData();
  };

  const didPressLoadingDialogDismissButton = (): void => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(ViewIndex.LoadingOrContent);
  };

  const handleLogoFileReceive = (receivedFile: File) => {
    formikGlobalRef?.setFieldValue("theme.logoUrl", URL.createObjectURL(receivedFile));

    setOwnerPortalLogoPendingFile(receivedFile);
  };

  const handleLogoFileDelete = () => {
    formikGlobalRef?.setFieldValue("theme.logoUrl", undefined);

    setOwnerPortalLogoPendingFile(undefined);
    setDeleteLogoImageOnSave(true);
  };

  const handleCoverImageFileReceive = (receivedFile: File) => {
    formikGlobalRef?.setFieldValue("theme.backgroundUrl", URL.createObjectURL(receivedFile));

    setOwnerPortalCoverPendingFile(receivedFile);
  };

  const handleCoverImageFileDelete = () => {
    formikGlobalRef?.setFieldValue("theme.backgroundUrl", undefined);

    setOwnerPortalCoverPendingFile(undefined);
    setDeleteCoverImageOnSave(true);
  };

  const getFrameType = (): FrameType => {
    if (loadingDialogState !== DialogState.Hidden) {
      return "contentOnly";
    }

    return "sideMenu";
  };

  const frameType = getFrameType();

  return (
    <Dialog className={className} open={showDialog} onClose={() => {}} disableBackdropClick>
      <DialogFrame
        onCloseButtonClick={() => onBackdropClick?.()}
        width={dialogWidth || DIALOG_WIDTH}
        height={dialogHeight || DIALOG_HEIGHT}
        renderView={renderView}
        numViews={2}
        activeView={viewIndex}
        frameType={frameType}
        title={dialogTitle}
        sectionTitle={currentMenuItemTitle}
      />
    </Dialog>
  );
};
