"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OwnerPortalServerRoutes = void 0;
var OwnerPortalServerRoutes;
(function (OwnerPortalServerRoutes) {
    OwnerPortalServerRoutes["AUTH_GET_CURRENT_LOGIN_RESPONSE"] = "/api/owner-portal/auth/me";
    OwnerPortalServerRoutes["SETTINGS_LOGIN_AND_PASSWORD"] = "/api/owner-portal/settings/login-and-password";
    OwnerPortalServerRoutes["OWNER_REQUESTS"] = "/api/owner-portal/owner-requests";
    OwnerPortalServerRoutes["OWNER_REQUESTS_POST_UPDATE"] = "/api/owner-portal/owner-requests/update";
    OwnerPortalServerRoutes["OWNER_REQUESTS_GET_ACTIVITY_FEED"] = "/api/owner-portal/owner-requests/activity-feed";
    OwnerPortalServerRoutes["PROPERTIES"] = "/api/owner-portal/properties";
    OwnerPortalServerRoutes["BUDGETS"] = "/api/owner-portal/budgets";
    OwnerPortalServerRoutes["UNITS"] = "/api/owner-portal/units";
    OwnerPortalServerRoutes["LEASES"] = "/api/owner-portal/leases";
    OwnerPortalServerRoutes["LEASES_GET_LEASE_TENANTS"] = "/api/owner-portal/leases/tenants";
    OwnerPortalServerRoutes["TRANSACTIONS"] = "/api/owner-portal/transactions";
    OwnerPortalServerRoutes["FILES"] = "/api/owner-portal/files";
    OwnerPortalServerRoutes["TENANTS"] = "/api/owner-portal/tenants";
    OwnerPortalServerRoutes["TASKS"] = "/api/owner-portal/tasks";
    OwnerPortalServerRoutes["RENTAL_APPLICATIONS"] = "/api/owner-portal/rental-applications";
    OwnerPortalServerRoutes["DASHBOARDS_WIDGETS_GET_PROSPECTS_FUNNEL"] = "/api/owner-portal/dashboard-widgets/prospects-funnel";
    OwnerPortalServerRoutes["DASHBOARDS_WIDGETS_GET_TASKS_RECENT_TENANT_REQUESTS"] = "/api/owner-portal/dashboard-widgets/tasks-recent-tenant-requests";
    OwnerPortalServerRoutes["DASHBOARDS_WIDGETS_GET_LEASES_WITH_OUTSTANDING_BALANCES"] = "/api/owner-portal/dashboard-widgets/leases-with-outstanding-balances";
    OwnerPortalServerRoutes["DASHBOARDS_WIDGETS_GET_RECENT_PAYMENTS_RECEIVED"] = "/api/owner-portal/dashboard-widgets/recent-payments-received";
    OwnerPortalServerRoutes["DASHBOARDS_WIDGETS_GET_VACANCIES_BY_PROPERTY"] = "/api/owner-portal/dashboard-widgets/vacancies-by-property";
    OwnerPortalServerRoutes["DASHBOARDS_WIDGETS_GET_PROFIT_AND_LOSS"] = "/api/owner-portal/dashboard-widgets/profit-and-loss";
    OwnerPortalServerRoutes["REPORTS_ACCOUNTS_PAYABLE_AGING_DETAIL"] = "/api/owner-portal/reports/accounts-payable-aging-detail";
    OwnerPortalServerRoutes["REPORTS_ACCOUNTS_PAYABLE_AGING_DETAIL_BY_ACCOUNT"] = "/api/owner-portal/reports/accounts-payable-aging-detail-by-account";
    OwnerPortalServerRoutes["REPORTS_ACCOUNTS_RECEIVABLE_AGING_DETAIL"] = "/api/owner-portal/reports/accounts-receivable-aging-detail";
    OwnerPortalServerRoutes["REPORTS_ACCOUNTS_RECEIVABLE_AGING_DETAIL_BY_ACCOUNT"] = "/api/owner-portal/reports/accounts-receivable-aging-detail-by-account";
    OwnerPortalServerRoutes["REPORTS_ACCOUNTS_RECEIVABLE_AGING_SUMMARY"] = "/api/owner-portal/reports/accounts-receivable-aging-summary";
    OwnerPortalServerRoutes["REPORTS_BALANCE_SHEET_SUMMARY"] = "/api/owner-portal/reports/balance-sheet-summary";
    OwnerPortalServerRoutes["REPORTS_BANK_BALANCE_BY_PROPERTY"] = "/api/owner-portal/reports/bank-balance-by-property";
    OwnerPortalServerRoutes["REPORTS_CASH_FLOW_STATEMENT"] = "/api/owner-portal/reports/cash-flow-statement";
    OwnerPortalServerRoutes["REPORTS_OWNER_STATEMENT"] = "/api/owner-portal/reports/owner-statement";
    OwnerPortalServerRoutes["REPORTS_OWNER_STATEMENT_BULK_REPORT"] = "/api/owner-portal/reports/owner-statement/bulk";
    OwnerPortalServerRoutes["REPORTS_OWNER_BUDGET_VS_ACTUAL"] = "/api/owner-portal/reports/budget-vs-actual";
    OwnerPortalServerRoutes["REPORTS_OWNER_STATEMENT_PRINTABLE_PDF"] = "/api/owner-portal/reports/owner-statement/print-pdf";
    OwnerPortalServerRoutes["REPORTS_OWNERS_1099"] = "/api/owner-portal/reports/owners-1099";
    OwnerPortalServerRoutes["REPORTS_PROFIT_AND_LOSS_SUMMARY"] = "/api/owner-portal/reports/profit-and-loss-summary";
    OwnerPortalServerRoutes["REPORTS_PROPERTY_OWNERSHIP_REPORT"] = "/api/owner-portal/reports/property-ownership";
    OwnerPortalServerRoutes["REPORTS_RECURRING_TRANSACTIONS_DETAIL"] = "/api/owner-portal/reports/recurring-transactions-detail";
    OwnerPortalServerRoutes["REPORTS_RENT_ROLL"] = "/api/owner-portal/reports/rent-roll";
    OwnerPortalServerRoutes["REPORTS_RENT_ROLL_SUMMARY_BY_BEDS_AND_BATHS"] = "/api/owner-portal/reports/rent-roll-summary-by-beds-and-baths";
    OwnerPortalServerRoutes["REPORTS_RENT_ROLL_SUMMARY_BY_PROPERTY"] = "/api/owner-portal/reports/rent-roll-summary-by-property";
    OwnerPortalServerRoutes["REPORTS_TENANT_PETS_REPORT"] = "/api/owner-portal/reports/tenant-pets";
    OwnerPortalServerRoutes["REPORTS_TENANT_VEHICLES_REPORT"] = "/api/owner-portal/reports/tenant-vehicles";
    OwnerPortalServerRoutes["REPORTS_TRANSACTION_LIST_REPORT"] = "/api/owner-portal/reports/transactions-list";
    OwnerPortalServerRoutes["REPORTS_TRANSACTION_REPORT"] = "/api/owner-portal/reports/transactions";
    OwnerPortalServerRoutes["PHONE_NUMBERS_COMMUNICATION_PHONE_NUMBER"] = "/api/owner-portal/phone-numbers/communication-phone-number";
})(OwnerPortalServerRoutes || (exports.OwnerPortalServerRoutes = OwnerPortalServerRoutes = {}));
