import type { Dispatch, SetStateAction } from "react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { DeepKeys, LateFeePolicyFeeType, LateFeesPolicyDto } from "@doorloop/dto";
import { DataCy, LateFeeChargeDto } from "@doorloop/dto";
import type { FieldArrayRenderProps } from "formik";
import { useFormikContext } from "formik";
import { useToggle } from "usehooks-ts";
import { useGeneralStyles } from "styles/generalStyles";
import clsx from "clsx";
import { useTypedTranslation } from "locale";
import { FeeItem } from "screens/settings/common/feeItem";
import { LateFeeItemTitle } from "DLUI/dialogs/settings/common/lateFees/lateFeeItemTitle";
import { v4 as uuid } from "uuid";
import { FeeItemPopover } from "screens/settings/common/feeItemPopover";
import { useResponsiveHelper } from "@/contexts/responsiveContext";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { SettingsSection } from "screens/settings/common/settingsSection";
import { entityApiStore } from "api/entityApiStore/entityApiStore";
import { FeeItemFormMobileDialogWrapper } from "screens/settings/common/feeItemFormMobileDialogWrapper";
import type { LateFeesFormTypes, LateFeesPrefixes } from "DLUI/dialogs/settings/common/lateFees/types";
import { LateFeeChargeItemForm } from "DLUI/dialogs/settings/common/lateFees/lateFeeChargeItemForm";
import { LateFeeChargeItemFormMobile } from "DLUI/dialogs/settings/common/lateFees/mobile/lateFeeChargeItemFormMobile";
import get from "lodash/get";
import { useLateFeeSettings } from "DLUI/dialogs/settings/common/lateFees/useLateFeeSettings";
import Text from "DLUI/text";
import { useEffectOnce } from "hooks/useEffectOnce";

interface LateFeeChargeItemProps {
  index: number;
  arrayHelpers: FieldArrayRenderProps;
  formikPathName?: DeepKeys<LateFeesPrefixes>;
  feeType?: LateFeePolicyFeeType;
  isNewChargeAdded: boolean;
  setNewAddedChargeKey: Dispatch<SetStateAction<string | undefined>>;
}

export const LateFeeChargeItem = ({
  index,
  arrayHelpers,
  formikPathName,
  isNewChargeAdded,
  setNewAddedChargeKey
}: LateFeeChargeItemProps) => {
  const { t } = useTypedTranslation();
  const formikRef = useFormikContext<LateFeesFormTypes>();
  const [isOpen, toggle, setIsOpen] = useToggle(false);
  const generalClasses = useGeneralStyles();
  const { getFieldPath, lateFeeSettings } = useLateFeeSettings({ formikPathName });
  const { errors, submitCount } = formikRef;
  const chargesPath: DeepKeys<LateFeesPolicyDto> = "lateFeeCharges";
  const chargesPathWithIndex = `${chargesPath}[${index}]`;
  const fieldNamePrefix = getFieldPath(chargesPathWithIndex as DeepKeys<LateFeesPolicyDto>);
  const isNewlyAddedAndOpenedRef = useRef<boolean>(false);

  const { isDesktop } = useResponsiveHelper();
  const { data: accounts } = entityApiStore.accounts.queries.useGetDictionary();
  const [isMobileFormOpen, setIsMobileFormOpen] = useState(false);

  const lateFeeCharges = useMemo(() => lateFeeSettings?.lateFeeCharges ?? [], [lateFeeSettings]);
  const rule: LateFeeChargeDto | undefined = lateFeeCharges[index];

  const isRuleValid = useMemo(() => {
    const errorsObject = get(errors, fieldNamePrefix);
    return errorsObject ? Object.keys(errorsObject).length === 0 : true;
  }, [errors, fieldNamePrefix]);

  useEffect(() => {
    if (submitCount > 0 && !isRuleValid) {
      setIsOpen(true);
    }
  }, [isRuleValid, submitCount]);

  useEffectOnce(() => {
    if (isNewChargeAdded && !isNewlyAddedAndOpenedRef.current) {
      isNewlyAddedAndOpenedRef.current = true;
      setIsOpen(true);
    }
  });

  const handleDuplicate = useCallback(() => {
    const uniqueKey = uuid();
    const newRule = new LateFeeChargeDto({ ...rule, uniqueKey });
    setNewAddedChargeKey(uniqueKey);
    arrayHelpers.insert(index + 1, newRule);
  }, [arrayHelpers, index, rule]);

  const handleDelete = useCallback(() => {
    arrayHelpers.remove(index);
  }, [arrayHelpers, index]);

  const handleEdit = useCallback(() => {
    setIsMobileFormOpen(true);
  }, []);

  if (!accounts || !rule) return null;

  return (
    <>
      <FeeItemFormMobileDialogWrapper
        isOpen={isMobileFormOpen}
        handleStateChange={setIsMobileFormOpen}
        hasItems={lateFeeCharges.length > 0}
        Form={
          <LateFeeChargeItemFormMobile
            formikPathName={formikPathName}
            onClose={() => setIsMobileFormOpen(false)}
            index={index}
            arrayHelpers={arrayHelpers}
          />
        }
      />
      {isDesktop ? (
        <FeeItem
          dataCy={DataCy.globalSettings.lateFees.rules.rule.container}
          title={<LateFeeItemTitle index={index} rule={rule} />}
          isOpen={isOpen}
          onToggle={toggle}
          end={<FeeItemPopover handleDelete={handleDelete} handleDuplicate={handleDuplicate} handleEdit={handleEdit} />}
        >
          <LateFeeChargeItemForm index={index} arrayHelpers={arrayHelpers} formikPathName={formikPathName} />
        </FeeItem>
      ) : (
        <SettingsSection
          dataCy={DataCy.globalSettings.lateFees.rules.rule.container}
          backgroundColor={"newPalette.secondary.states.hover"}
          title={<LateFeeItemTitle index={index} rule={rule} />}
          headerStyle={{ height: "unset", padding: "16px 8px 16px 16px" }}
          end={<FeeItemPopover handleDelete={handleDelete} handleDuplicate={handleDuplicate} handleEdit={handleEdit} />}
        >
          <div className={clsx(generalClasses.flexColumn, generalClasses.largeGap)}>
            {rule.daysAfterDueDateToChargeLateFees && (
              <>
                <div className={clsx(generalClasses.flexColumn, generalClasses.mediumGap)}>
                  <Text bold value={t("lateFee.gracePeriod")} color="secondary-gray" />
                  <Text bold value={rule.daysAfterDueDateToChargeLateFees.toString()} />
                </div>
              </>
            )}
            {rule.account && (
              <>
                <HorizontalSeparationLine />
                <div className={clsx(generalClasses.flexColumn, generalClasses.mediumGap)}>
                  <Text bold value={t("common.revenueAccount")} color="secondary-gray" />
                  <Text bold value={accounts[rule.account].name} />
                </div>
              </>
            )}
          </div>
        </SettingsSection>
      )}
    </>
  );
};
