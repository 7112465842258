const BulkPayments = {
  BulkReceivePayments: "bulkPayments.bulkreceivePayments",
  ReceivePayments: "bulkPayments.receivePayments",
  SearchToBegin: "bulkPayments.searchToBegin",
  ReceivePaymentsIntroText: "bulkPayments.receivePaymentsIntroText",
  PaymentsAdded: "bulkPayments.paymentsAdded",
  TotalAmountReceived: "bulkPayments.totalAmountReceived",
  AmountReceived: "bulkPayments.amountReceived",
  SearchPlaceholder: "bulkPayments.searchPlaceholder",
  PaymentDetails: "bulkPayments.paymentDetails",
  Balance: "bulkPayments.balance",
  ReceivedFrom: "bulkPayments.receivedFrom",
  DepositAccount: "bulkPayments.depositAccount",
  PleaseWaitWhileWeLoadTheSummary: "bulkPayments.pleaseWaitWhileWeLoadTheSummary",
  ProcessingPayment: "bulkPayments.processingPayment",
  ProcessingPaymentForX: "bulkPayments.processingPaymentForX",
  UserConfirmLeaveCurrentSession: "bulkPayments.userConfirmLeaveCurrentSession",
  UserConfirmUnselectRows: "bulkPayments.userConfirmUnselectRows",
  Errors: {
    CouldNotFetchTenants: "bulkPayments.errors.couldNotFetchTenants",
    CouldNotFetchProperties: "bulkPayments.errors.couldNotFetchProperties",
    CouldNotFetchLeases: "bulkPayments.errors.couldNotFetchLeases",
    CouldNotFetchDepositAccounts: "bulkPayments.errors.couldNotFetchDepositAccounts",
    CouldNotFetchPaymentMethods: "bulkPayments.errors.couldNotFetchPaymentMethods",
    NoPaymentsSelected: "bulkPayments.errors.noPaymentsSelected"
  },
  HelpPanel: {
    SearchAndFill: {
      Description: "bulkPayments.helpPanel.searchAndFill.description"
    },
    Review: {
      Description: "bulkPayments.helpPanel.review.description"
    }
  }
};

export default BulkPayments;
