import React, { Fragment, useState } from "react";
import { View } from "DLUI/view";

import type { FillColors } from "DLUI/icon";
import { FilesApi } from "api/filesApi";
import { SeparationLine } from "DLUI/separatorView";
import { CircularProgress } from "@material-ui/core";
import { DownloadIcon } from "assets/icons";
import type { SVGIconComponent } from "assets/icons/types";
import { ServerRoutes } from "@doorloop/dto";
import IconButton from "../iconButton/iconButton";

interface ComponentProps {
  Icon?: SVGIconComponent;
  width?: number;
  height?: number;
  pathColor?: FillColors;
  fileId?: string;
  name: string;
  hideSeparationLine?: boolean;
  baseUrl?: string;
  dataCy?: string;
}

const DownloadButton: React.FC<ComponentProps> = ({
  pathColor,
  width,
  height,
  Icon,
  fileId,
  name,
  hideSeparationLine,
  baseUrl,
  dataCy
}: ComponentProps) => {
  const [downloadingFileInProgress, setDownloadingFileInProgress] = useState<boolean>(false);

  const _didPressDownloadButton = async () => {
    if (fileId && !downloadingFileInProgress) {
      setDownloadingFileInProgress(true);
      let filesApi = new FilesApi(ServerRoutes.FILES);
      if (baseUrl) {
        filesApi = new FilesApi(baseUrl);
      }
      await filesApi.downloadFile(fileId);
      setDownloadingFileInProgress(false);
    }
  };

  if (!fileId) {
    return null;
  }

  if (downloadingFileInProgress) {
    return (
      <View
        width={45}
        height={"100%"}
        justifyContent={"center"}
        alignItems={hideSeparationLine ? "center" : "flex-start"}
      >
        {hideSeparationLine ? null : <SeparationLine width={1} height={"80%"} />}
        <CircularProgress
          style={{
            width: 15,
            height: 15,
            color: "#182C4C"
          }}
        />
      </View>
    );
  }
  return (
    <Fragment>
      {hideSeparationLine ? null : <SeparationLine width={1} height={"80%"} />}
      <IconButton
        width={width || 15}
        data-cy={dataCy}
        height={height || 15}
        onClick={_didPressDownloadButton}
        Icon={Icon || DownloadIcon}
        marginRight={5}
        marginLeft={5}
        paddingLeft={6}
        paddingRight={6}
        paddingTop={6}
        paddingBottom={6}
        pathColor={pathColor}
      />
    </Fragment>
  );
};

export default DownloadButton;
