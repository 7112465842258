import type { CSSProperties, FC, FocusEventHandler } from "react";
import React, { useMemo, useState } from "react";
import clsx from "clsx";

import { $getRoot } from "lexical";
import { $generateHtmlFromNodes } from "@lexical/html";
import type { InitialConfigType } from "@lexical/react/LexicalComposer";

import { View } from "DLUI/view";
import Text from "DLUI/text";

import AppStrings from "../../../../../locale/keys";

import type { RichTextPluginsProps } from "./richTextEditor";
import { RichTextEditor } from "./richTextEditor";
import makeStyles from "./styles";

export interface ValueRichTextField {
  stringSimple: string;
  stringHTML: string;
}

export interface RichTextInputBaseProps {
  label: string;
  required?: boolean;
  maxLength?: number;
  styles?: {
    container?: CSSProperties;
  };
}

export interface RichTextInputProps extends RichTextInputBaseProps {
  name: string;
  value?: string;
  onError: InitialConfigType["onError"];
  onChange?: (value: ValueRichTextField) => void;
  onBlur?: FocusEventHandler;
  error?: string;
}

const RichTextInput: FC<RichTextInputProps> = ({
  name,
  value,
  maxLength,
  onError,
  onChange,
  onBlur,
  label,
  required,
  error,
  styles
}) => {
  const [currentCharCount, setCurrentCharCount] = useState(0);
  const [isFocused, setIsFocused] = useState(false);

  const classes = makeStyles();

  const isOverLimit = useMemo(() => maxLength && currentCharCount > maxLength, [currentCharCount, maxLength]);

  const isError = useMemo(() => Boolean(error) || Boolean(isOverLimit), [error, isOverLimit]);

  const classNameLabelText = clsx([
    isError ? classes.labelTextError : isFocused ? classes.labelTextFocused : classes.labelTextUnfocused,
    classes.text
  ]);

  const handleChange: RichTextPluginsProps["onChange"] = (editorState, lexicalState) => {
    const stringSimple = editorState.read(() => $getRoot().getTextContent());
    const stringHTML = editorState.read(() => $generateHtmlFromNodes(lexicalState));

    setCurrentCharCount(stringSimple.length);

    onChange?.({ stringSimple, stringHTML });
  };

  const handleBlur: FocusEventHandler = (event) => {
    setIsFocused(false);
    onBlur?.(event);
  };

  const classFieldset = useMemo(
    () =>
      clsx([
        classes.root,
        isError ? classes.fieldsetBorderError : classes.fieldsetBorder,
        isFocused ? classes.fieldsetBorderFocused : ""
      ]),
    [isError, isFocused]
  );

  const isValue = isFocused || currentCharCount || Boolean(value);

  return (
    <>
      <View style={styles?.container} fullWidth>
        <div style={{ width: "100%" }} onFocus={() => setIsFocused(true)} onBlur={handleBlur}>
          <label className={clsx([classes.labelContainer, isValue ? classes.labelContainerFocused : ""])}>
            <Text className={classNameLabelText} value={label} />

            {Boolean(required) && <span className={classNameLabelText}>*</span>}
          </label>

          <fieldset className={classFieldset}>
            <RichTextEditor name={name} value={value} onChange={handleChange} onError={onError} />

            <legend className={clsx([classes.notchDefault, isValue ? classes.notchActive : ""])}>
              <span>
                {label}
                {required ? "*" : ""}
              </span>
            </legend>
          </fieldset>

          {isError && (
            <Text
              marginTop={3}
              marginRight={10}
              marginLeft={10}
              className={clsx([classes.text, classes.errorText])}
              value={error}
            />
          )}

          {maxLength && (
            <Text
              value={AppStrings.Announcements.xCharacters}
              replaceObject={{ currentCharCount, maxCharCount: maxLength }}
              marginTop={10}
              marginLeft={10}
              marginRight={10}
              className={clsx([classes.text, classes.helperText])}
              color={isOverLimit ? "error" : "secondary-gray"}
              bold
            />
          )}
        </div>
      </View>
    </>
  );
};

export { RichTextInput };
