"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarSubEventType = void 0;
var CalendarSubEventType;
(function (CalendarSubEventType) {
    CalendarSubEventType["MOVE_IN"] = "MOVE_IN";
    CalendarSubEventType["MOVE_OUT"] = "MOVE_OUT";
    CalendarSubEventType["TASK"] = "TASK";
    CalendarSubEventType["WORK_ORDER"] = "WORK_ORDER";
    CalendarSubEventType["TENANT_REQUEST"] = "TENANT_REQUEST";
    CalendarSubEventType["OWNER_REQUEST"] = "OWNER_REQUEST";
})(CalendarSubEventType || (exports.CalendarSubEventType = CalendarSubEventType = {}));
