import type { PushNotificationDto } from "@doorloop/dto";
import { NotificationServiceType, NotificationType } from "@doorloop/dto";

import ColorsEnum from "./colorsEnum";
import type { FillColors } from "DLUI/icon";
import type { SVGIconComponent } from "../assets";
import {
  AnnouncementItemIcon,
  AnnouncementNotificationIcon,
  MerchantAccountNotificationIcon,
  NotificationIcon,
  PaymentNotificationIcon,
  RentalApplicationNotificationIcon,
  TaskNotificationIcon,
  WarningNotificationIcon
} from "../assets";

const defaultThemeColors: Record<NotificationType, ColorsEnum> = {
  [NotificationType.INFO]: ColorsEnum.Blue,
  [NotificationType.SUCCESS]: ColorsEnum.DarkGreen,
  [NotificationType.WARNING]: ColorsEnum.Yellow,
  [NotificationType.ERROR]: ColorsEnum.Red,
  [NotificationType.GENERAL]: ColorsEnum.Gray,
  [NotificationType.SYSTEM]: ColorsEnum.Pink
};

export interface NotificationItemDto {
  theme: Record<NotificationType, ColorsEnum>;
  title?: string | undefined;
  placeholder?: string;
  subtitle?: string;
  description?: string;
  icon?: SVGIconComponent;
  iconPathColor?: FillColors;
  iconBgColor?: ColorsEnum;
  className?: string;
}

const buildDefaultPlaceHolder = (dto: PushNotificationDto): string | undefined =>
  dto.propertyName && `${dto.propertyName}${dto.unitName ? ` > ${dto.unitName}` : ""}`;

const buildPaymentReceivedContent = (dto: PushNotificationDto): NotificationItemDto => {
  return {
    title: dto.title,
    placeholder: buildDefaultPlaceHolder(dto),
    description: dto.description,
    icon: PaymentNotificationIcon,
    theme: defaultThemeColors
  };
};

const buildPaymentReturnedContent = (dto: PushNotificationDto): NotificationItemDto => {
  return {
    title: dto.title,
    placeholder: buildDefaultPlaceHolder(dto),
    description: dto.description,
    icon: WarningNotificationIcon,
    iconPathColor: "error",
    iconBgColor: ColorsEnum.LightRed,
    className: dto?.isOpened ? "iconOpacity" : "",
    theme: defaultThemeColors
  };
};

const buildTenantRequestToastContent = (dto: PushNotificationDto): NotificationItemDto => {
  return {
    title: dto.title,
    placeholder: buildDefaultPlaceHolder(dto),
    icon: TaskNotificationIcon,
    theme: defaultThemeColors
  };
};

const buildTenantRentChargedToastContent = (dto: PushNotificationDto): NotificationItemDto => {
  return {
    title: dto.title,
    placeholder: buildDefaultPlaceHolder(dto),
    description: dto.description,
    icon: PaymentNotificationIcon,
    theme: defaultThemeColors
  };
};

const buildTenantRentReminderToastContent = (dto: PushNotificationDto): NotificationItemDto => {
  return {
    title: dto.title,
    placeholder: buildDefaultPlaceHolder(dto),
    icon: NotificationIcon,
    theme: defaultThemeColors
  };
};

const buildTenantAutoPayCanceledToastContent = (dto: PushNotificationDto): NotificationItemDto => {
  return {
    title: dto.title,
    placeholder: buildDefaultPlaceHolder(dto),
    icon: PaymentNotificationIcon,
    theme: defaultThemeColors
  };
};

const buildTenantLateFeeChargedToastContent = (dto: PushNotificationDto): NotificationItemDto => {
  return {
    title: dto.title,
    placeholder: buildDefaultPlaceHolder(dto),
    icon: PaymentNotificationIcon,
    theme: defaultThemeColors
  };
};

const buildTenantAnnouncementContent = (dto: PushNotificationDto): NotificationItemDto => {
  return {
    title: dto.title,
    placeholder: buildDefaultPlaceHolder(dto),
    icon: AnnouncementNotificationIcon,
    theme: defaultThemeColors
  };
};

const buildTenantRentOverdueReminderContent = (dto: PushNotificationDto): NotificationItemDto => {
  return {
    title: dto.title,
    placeholder: buildDefaultPlaceHolder(dto),
    description: dto.description,
    icon: PaymentNotificationIcon,
    theme: defaultThemeColors
  };
};

const buildTenantBeforeLateFeeChargedToastContent = (dto: PushNotificationDto): NotificationItemDto => {
  return {
    title: dto.title,
    placeholder: buildDefaultPlaceHolder(dto),
    description: dto.description,
    icon: PaymentNotificationIcon,
    theme: defaultThemeColors
  };
};

const buildTaskUserToastContent = (dto: PushNotificationDto): NotificationItemDto => {
  return {
    title: `${dto.title} by ${dto.updatedByName || dto.requestedByName}`,
    placeholder: buildDefaultPlaceHolder(dto),
    subtitle: dto.subtitle,
    icon: TaskNotificationIcon,
    theme: defaultThemeColors
  };
};

const buildRentalApplicationUserToastContent = (dto: PushNotificationDto): NotificationItemDto => {
  return {
    title: dto.title,
    placeholder: buildDefaultPlaceHolder(dto),
    icon: RentalApplicationNotificationIcon,
    theme: defaultThemeColors
  };
};

const buildMerchantStatusUpdatedUserToastContent = (dto: PushNotificationDto): NotificationItemDto => {
  return {
    title: dto.title,
    placeholder: dto.description,
    icon: MerchantAccountNotificationIcon,
    theme: defaultThemeColors
  };
};

const buildSetupSmsConfirmedToastContent = (dto: PushNotificationDto): NotificationItemDto => {
  return {
    title: dto.title,
    placeholder: dto.description,
    icon: AnnouncementItemIcon,
    theme: defaultThemeColors
  };
};

const buildConversationIncomingMessageToastContent = (dto: PushNotificationDto): NotificationItemDto => {
  return {
    title: dto.title,
    placeholder: dto.description,
    icon: AnnouncementItemIcon,
    theme: defaultThemeColors
  };
};

export type NotificationItemsServiceType = Exclude<NotificationServiceType, NotificationServiceType.SYSTEM>;

export const pushNotificationTypesMap: Record<
  NotificationItemsServiceType,
  (dto: PushNotificationDto) => NotificationItemDto
> = {
  // TENANT NOTIFICATIONS
  [NotificationServiceType.TENANT_REQUEST]: buildTenantRequestToastContent,
  [NotificationServiceType.TENANT_RENT_CHARGED]: buildTenantRentChargedToastContent,
  [NotificationServiceType.TENANT_RENT_REMINDER]: buildTenantRentReminderToastContent,
  [NotificationServiceType.TENANT_PAYMENT_RETURNED]: buildPaymentReturnedContent,
  [NotificationServiceType.TENANT_PAYMENT_RECEIPT]: buildPaymentReceivedContent,
  [NotificationServiceType.TENANT_AUTO_PAY_CANCELED]: buildTenantAutoPayCanceledToastContent,
  [NotificationServiceType.TENANT_LATE_FEE_CHARGED]: buildTenantLateFeeChargedToastContent,
  [NotificationServiceType.TENANT_ANNOUNCEMENTS]: buildTenantAnnouncementContent,
  [NotificationServiceType.TENANT_RENT_OVERDUE_REMINDER]: buildTenantRentOverdueReminderContent,
  [NotificationServiceType.TENANT_BEFORE_LATE_FEE_CHARGED]: buildTenantBeforeLateFeeChargedToastContent,
  // USER NOTIFICATIONS
  [NotificationServiceType.TASKS]: buildTaskUserToastContent,
  [NotificationServiceType.RENTAL_APPLICATION]: buildRentalApplicationUserToastContent,
  [NotificationServiceType.RENTAL_APPLICATION_REPORTS_READY]: buildRentalApplicationUserToastContent,
  [NotificationServiceType.MERCHANT_STATUS_UPDATED]: buildMerchantStatusUpdatedUserToastContent,
  [NotificationServiceType.PAYMENT_RETURNED]: buildPaymentReturnedContent,
  [NotificationServiceType.PAYMENT_RECEIVED]: buildPaymentReceivedContent,
  [NotificationServiceType.COMMUNICATION_CENTER_SETUP_SMS_CONFIRMED]: buildSetupSmsConfirmedToastContent,
  [NotificationServiceType.CONVERSATION_INCOMING_MESSAGE]: buildConversationIncomingMessageToastContent
};
