import type { AccountClass } from "@doorloop/dto";
import { AccountDto, AccountsTypesByClass, AccountType, DisplayAccountDto } from "@doorloop/dto";
import { accountsApi } from "api/accounts";
import { Select, TextField, ValidationIndicator } from "DLUI/form";
import { selectOptionsByQueryParams } from "DLUI/form/autoComplete/formikAsyncAutoComplete/utils";
import FieldWrapper from "DLUI/form/textField/fieldWrapper";
import View from "DLUI/view/view";
import { FastField, Formik, useFormikContext } from "formik";
import type { MouseEventHandler } from "react";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { validateForm } from "screens/accounts/chartOfAccounts/newAccount/formikHelper";
import { v4 as uuid } from "uuid";
import AppStrings from "../../../../../locale/keys";
import DLButton, { DLButtonColorsEnum } from "DLUI/button/dlButton";
import type { SelectInputProps } from "../../select/selectInput";

interface CreateNewAccountOptionFormInterface {
  onCreate: (newOption: any) => void;
  inputValue: string;
  createError: string;
  onCreateError: (error: string) => void;
  filterOptions?: Record<string, string>;
  class?: AccountClass;
  source?: string;
}

const CreateNewAccountOptionForm: React.FC<CreateNewAccountOptionFormInterface> = ({
  onCreate,
  inputValue,
  filterOptions,
  createError,
  onCreateError,
  class: accountClass,
  source
}: CreateNewAccountOptionFormInterface) => {
  const onSubmit = useCallback(async (values, { setSubmitting }) => {
    const accountValues = new AccountDto({
      ...values,
      leaseDepositItem: filterOptions?.filter_leaseDepositItem,
      leaseChargeItem: source === "charge" || filterOptions?.filter_leaseChargeItem
    });
    const response = await accountsApi.create(accountValues);
    if (response.status && response?.data?.id) {
      onCreate(response.data);
    } else {
      setSubmitting(false);
      onCreateError(response?.message);
    }
  }, []);

  const handleSelect = useCallback((ev) => ev.target.select(), []);

  const selectOptions = useMemo((): any => {
    const currSelectOptions = selectOptionsByQueryParams(filterOptions, {
      groupEnumName: accountClass,
      enumSelection: AccountsTypesByClass
    });

    return !currSelectOptions ? (accountClass ? AccountsTypesByClass[accountClass] : AccountType) : currSelectOptions;
  }, [filterOptions]);

  const onTransform: SelectInputProps["onTransform"] = (items) =>
    items.map(({ value, label }) => {
      return { label, value: selectOptions[value] };
    });

  const type = useMemo(() => (selectOptions && selectOptions.length === 1 ? selectOptions[0] : null), [selectOptions]);

  const initialValues: DisplayAccountDto = useMemo(() => {
    return {
      ...new DisplayAccountDto(),
      name: inputValue,
      type: type ? AccountType[type] : undefined
    };
  }, [type]);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validateForm}>
      <CreateAccountForm
        type={type}
        inputValue={inputValue}
        selectOptions={selectOptions}
        onTransform={onTransform}
        handleSelect={handleSelect}
        onCreateError={onCreateError}
        createError={createError}
      />
    </Formik>
  );
};

interface CreateAccountFormInterface {
  selectOptions: AccountType[];
  onTransform: SelectInputProps["onTransform"];
  type?: AccountType;
  inputValue?: string;
  handleSelect: (ev: MouseEventHandler<any>) => void;
  createError?: string;
  onCreateError: (error: string) => void;
}

const CreateAccountForm: React.FC<CreateAccountFormInterface> = ({
  type,
  inputValue,
  selectOptions,
  onTransform,
  handleSelect,
  onCreateError,
  createError
}: CreateAccountFormInterface) => {
  const { t } = useTranslation();
  const uniqueKeyId = useMemo(() => uuid(), []);
  const { handleSubmit, isValid, isSubmitting, setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue("name", inputValue);

    if (createError !== "") {
      onCreateError("");
    }

    if (!type) {
      setFieldValue("type", null);
    }
  }, [inputValue, type]);

  return (
    <View alignItems={"center"}>
      <FieldWrapper type={"singleField"} marginTop={10}>
        <FastField
          disabled={false}
          required
          component={TextField}
          name={"name"}
          onClick={handleSelect}
          label={t(AppStrings.Accounts.ChartOfAccounts.AccountName)}
        />
      </FieldWrapper>
      <ValidationIndicator shouldShow={Boolean(createError)} displayText={createError} justifyContent={"flex-start"} />
      {!type && (
        <FieldWrapper type={"singleField"} marginTop={10}>
          <FastField
            required
            component={Select}
            name={"type"}
            uniqueKey={uniqueKeyId}
            translationKey={"accountType"}
            selectionEnum={selectOptions}
            onTransform={onTransform}
            label={t(AppStrings.Common.FilterOptions.Type)}
          />
        </FieldWrapper>
      )}
      <DLButton
        onClick={() => handleSubmit()}
        actionText={AppStrings.Accounts.CreateNewAccountOption}
        disabled={!isValid}
        isLoading={isSubmitting}
        color={DLButtonColorsEnum.SECONDARY}
        icons={{ start: { isHidden: false } }}
        style={{ marginTop: 15 }}
      />
    </View>
  );
};

export default CreateNewAccountOptionForm;
