import React, { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { View } from "DLUI/view";
import { useLocation } from "react-router-dom";
import { NavLevels } from "DLUI/drawer/types";
import { useNavigationMenuLayers } from "./drawerItems";
import useStyles, { DEFAULT_TRANSITION_MS } from "./styles";
import "./styles.css";
import NavigationList from "DLUI/drawer/navigationList";
import DrawerMainHeader from "DLUI/drawer/DrawerHeader";
import { selectedLocalNavigation } from "../../../hooks/useLocalGlobalSearch";
import { useAtom, useSetAtom } from "jotai";
import { Icon } from "DLUI/icon";
import { useAnalyticsService } from "../../../hooks/useAnalyticsService";
import { TextTooltip } from "DLUI/popover/textTooltip";
import AppStrings from "../../../locale/keys";
import { getMainMenuLayersFiltered } from "DLUI/drawer/getMainMenuLayersFiltered";
import { sideMenuCollapsingAtom } from "components/layouts/layoutAtom";
import { CaretArrowRight } from "@/assets";
import { useDrawerResponsiveDesign } from "DLUI/drawer/useDrawerResponsiveDesign";
import { useSelectedMainItemKey } from "./hooks/useSelectedMainItemKey";
import { useSelectedSubItem } from "./hooks/useSelectedSubItem";

interface ComponentProps {
  onGlobalNewPress: () => void;
  onGlobalSearchPress: () => void;
  onDrawerClosePress?: () => void;
}

interface DrawerPrefProps {
  isCollapsed?: boolean;
}

export const DrawerMaxWidth = 340;
export const DRAWER_WIDTH = 256;
export const MINIMIZED_DRAWER_WIDTH = 60;
const DRAWER_COLLAPSE_BUTTON_TOP = 10;
const SUB_DRAWER_WIDTH = DRAWER_WIDTH - 60;
const DRAWER_COLLAPSE_BUTTON_SIZE = 20;
const DRAWER_PREF_STORAGE_KEY = "Drawer-Preferences";
const DRAWER_HEADER_ICON_SIZE_MULTIPLIER = 2.2;

export const getDrawerPreferencesFromStorage = (): DrawerPrefProps =>
  JSON.parse(localStorage.getItem(DRAWER_PREF_STORAGE_KEY) || "{}");

const setDrawerPreferencesFromStorage = (drawerPref: DrawerPrefProps) => {
  const currentPref = getDrawerPreferencesFromStorage();
  return localStorage.setItem(DRAWER_PREF_STORAGE_KEY, JSON.stringify({ ...currentPref, ...drawerPref }));
};

const DLDrawer: React.FC<ComponentProps> = ({ onGlobalNewPress, onGlobalSearchPress, onDrawerClosePress }) => {
  const classes = useStyles();
  const location = useLocation();
  const [selectedMenuItem, setSelectedMenuItem] = useState<string | undefined>();
  const { dispatchAnalytics } = useAnalyticsService();
  const navigationMenuLayers = useNavigationMenuLayers();
  const [selectedNavigationKey] = useAtom(selectedLocalNavigation);
  const setSideMenuIsCollapsed = useSetAtom(sideMenuCollapsingAtom);
  const [isCollapsed, setIsCollapsed] = useState(getDrawerPreferencesFromStorage()?.isCollapsed || false);
  const [isMenuHovered, setIsMenuHovered] = useState(false);
  const [isCollapseButtonHovered, setIsCollapseButtonHovered] = useState(false);
  const selectedMainMenuItem = useSelectedMainItemKey({
    menuLayers: navigationMenuLayers,
    pathToMatch: location.pathname
  });
  const { navigationItem: selectedSubMenuItem, group: selectedGroup } = useSelectedSubItem({
    menuLayers: navigationMenuLayers,
    pathToMatch: location.pathname
  });
  const [forceNoSubNavigation, setForceNoSubNavigation] = useState<boolean>(false);
  const [innerSubNavigationName, setInnerSubNavigationName] = useState<string>();
  const subNavigationName = useMemo(() => {
    if (forceNoSubNavigation) {
      return;
    }

    if (innerSubNavigationName || !selectedGroup) {
      return innerSubNavigationName;
    }

    return navigationMenuLayers.MainMenuLayers.groups
      .flatMap((group) => group.items)
      .find((item) => item.navKey === selectedGroup.parentNavigationKey)?.subNavigationName;
  }, [innerSubNavigationName, navigationMenuLayers, selectedGroup, forceNoSubNavigation]);

  useEffect(() => {
    setInnerSubNavigationName(undefined);
    setForceNoSubNavigation(false);
    setSelectedMenuItem(undefined);
  }, [location.pathname]);

  const hasSubMenuSelectionOrIsCollapsed = Boolean(subNavigationName) || isCollapsed;
  const isHovered = isMenuHovered || isCollapseButtonHovered;
  const hasSubMenuSelectionOrIsCollapsedAndNotHovered = Boolean(subNavigationName) || (isCollapsed && !isHovered);
  const isCollapsing = useRef<boolean>(false);
  const { isDrawerCollapseFixed } = useDrawerResponsiveDesign();

  const _onDrawerClosePress = () => {
    onDrawerClosePress?.();
  };

  const handleNavClick = ({
    subMenuSelected,
    menuItemSelection,
    level
  }: {
    subMenuSelected?: string;
    menuItemSelection?: string;
    level?: NavLevels;
  }) => {
    if (!subMenuSelected && menuItemSelection) {
      setSelectedMenuItem(menuItemSelection);

      _onDrawerClosePress();
    }

    if (level === NavLevels.MAIN_MENU) {
      setSelectedMenuItem(menuItemSelection);
      setInnerSubNavigationName(subMenuSelected);
      setForceNoSubNavigation(!subMenuSelected);
    }
  };

  useEffect(() => {
    if (!selectedNavigationKey) {
      return;
    }

    const { menuItemSelection } = selectedNavigationKey;

    setSelectedMenuItem(menuItemSelection);
    _onDrawerClosePress();
  }, [selectedNavigationKey]);

  useEffect(() => {
    const storedCollapsePreference = getDrawerPreferencesFromStorage()?.isCollapsed;
    if (isDrawerCollapseFixed && !isCollapsed) {
      setIsCollapsed(true);
    } else if (!isDrawerCollapseFixed && isCollapsed !== storedCollapsePreference) {
      setIsCollapsed(storedCollapsePreference || false);
    }
  }, [isDrawerCollapseFixed, isCollapsed]);

  const handleIsHovered = () => {
    if (!isCollapsing.current) {
      setIsMenuHovered(true);
    }
  };

  const handleNotHovered = () => {
    if (!isCollapsing.current) {
      setIsMenuHovered(false);
    }
  };

  const toggleCollapsed = () => {
    const toggledValue = !isCollapsed;
    setIsCollapsed(toggledValue);
    setSideMenuIsCollapsed(toggledValue);
    setDrawerPreferencesFromStorage({ isCollapsed: toggledValue });
    dispatchAnalytics("nav_collapseExpandIcon_click", {
      navStateUponClick: toggledValue ? "collapsed" : "expanded"
    });

    if (toggledValue && (isMenuHovered || isCollapseButtonHovered)) {
      isCollapsing.current = true;
      setIsMenuHovered(false);
      setIsCollapseButtonHovered(false);

      setTimeout(() => {
        isCollapsing.current = false;
      }, DEFAULT_TRANSITION_MS);
    }
  };

  const getDrawerCollapseButtonStyles = useMemo(() => {
    const isCollapsedAndSubMenuHidden = isCollapsed && !isHovered;

    return {
      top: DRAWER_COLLAPSE_BUTTON_TOP,
      left:
        (isCollapsedAndSubMenuHidden ? MINIMIZED_DRAWER_WIDTH : DRAWER_WIDTH) -
        DRAWER_COLLAPSE_BUTTON_SIZE * DRAWER_HEADER_ICON_SIZE_MULTIPLIER,
      display: isDrawerCollapseFixed ? "none" : "flex"
    };
  }, [isCollapsed, isHovered, subNavigationName, isDrawerCollapseFixed]);

  const mainMenuLayers = getMainMenuLayersFiltered(navigationMenuLayers);

  return (
    <View
      width={isCollapsed ? MINIMIZED_DRAWER_WIDTH : DRAWER_WIDTH}
      height={"100%"}
      className={clsx([classes.drawerContainer, "DLUI_drawer"])}
      noWrap
      onMouseOver={handleIsHovered}
      onMouseLeave={handleNotHovered}
    >
      <View
        autoWidth
        className={"main-menu-collapse-button-wrapper"}
        style={getDrawerCollapseButtonStyles}
        onMouseOver={handleIsHovered}
        noWrap
      >
        <TextTooltip
          value={isCollapsed ? AppStrings.Common.DrawerExpand : AppStrings.Common.DrawerCollapse}
          placement={"right"}
          show={!isCollapsing.current}
          enterDelay={300}
          useButton={false}
        >
          <View
            className={`main-menu-collapse-button ${isCollapsed ? "rotate" : ""}`}
            onClick={toggleCollapsed}
            width={28}
            height={28}
            borderRadius={4}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Icon Source={CaretArrowRight} pathColor={"white"} size={DRAWER_COLLAPSE_BUTTON_SIZE} />
          </View>
        </TextTooltip>
      </View>
      <View
        className={clsx([classes.mainDrawer, hasSubMenuSelectionOrIsCollapsed ? "minimized" : "", "main-menu"])}
        width={hasSubMenuSelectionOrIsCollapsedAndNotHovered ? MINIMIZED_DRAWER_WIDTH : DRAWER_WIDTH}
        noWrap
        height={"100%"}
      >
        <View
          width={(hasSubMenuSelectionOrIsCollapsedAndNotHovered ? MINIMIZED_DRAWER_WIDTH : DRAWER_WIDTH) - 3}
          className={clsx([classes.mainDrawerContent, "main-menu-content"])}
          height={"100%"}
          noWrap
        >
          <DrawerMainHeader
            didPressGlobalSearch={onGlobalSearchPress}
            didPressGlobalNew={onGlobalNewPress}
            didPressCloseDrawer={_onDrawerClosePress}
            isMinimized={hasSubMenuSelectionOrIsCollapsedAndNotHovered}
            isHovered={isHovered}
            isCollapsed={isCollapsed}
            handleNavClick={handleNavClick}
            onMouseOver={handleIsHovered}
          />
          <View noWrap>
            <NavigationList
              navConfig={mainMenuLayers}
              subMenuSelection={subNavigationName}
              handleNavClick={handleNavClick}
              selectedMenuItem={selectedMainMenuItem}
              openedMenuItem={selectedMenuItem}
              isCollapsed={isCollapsed && !isHovered}
            />
          </View>
          <View marginTop={"auto"} noWrap>
            <NavigationList
              navConfig={navigationMenuLayers.MainMenuFooterLayers}
              subMenuSelection={subNavigationName}
              handleNavClick={handleNavClick}
              selectedMenuItem={selectedMainMenuItem}
              groupMarginBottom={15}
              isCollapsed={isCollapsed && !isHovered}
            />
          </View>
        </View>
      </View>
      <div
        className={clsx([classes.subDrawer, hasSubMenuSelectionOrIsCollapsed ? "minimized" : ""], "main-menu-sub-menu")}
        style={{
          width: (isCollapsed && !isHovered) || (isCollapsed && isHovered && !subNavigationName) ? 0 : SUB_DRAWER_WIDTH,
          position: isCollapsed && isHovered ? "absolute" : "relative"
        }}
        onMouseLeave={handleNotHovered}
        onMouseOver={handleIsHovered}
      >
        {subNavigationName && (
          <View noWrap paddingTop={50}>
            <NavigationList
              navConfig={navigationMenuLayers[subNavigationName]}
              handleNavClick={handleNavClick}
              selectedMenuItem={selectedSubMenuItem?.navKey}
              withActiveSideIndicator
            />
          </View>
        )}
      </div>
    </View>
  );
};

export default DLDrawer;
