import type { FC } from "react";
import React, { useState } from "react";
import PlaidAccountListItem from "screens/transactions/components/bankConnect/dialogs/bankConnect/plaidAccountListItem";
import "./plaidAccountListAccordion.css";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { PlaidAccountDto } from "@doorloop/dto";
import { Accordion } from "DLUI/accordion/accordion";

interface PlaidAccountListAccordionProps {
  onAccountSelection: (accountId: string) => void;
  institutionAccounts: PlaidAccountDto[];
  institutionName: string;
}

export const PlaidAccountListAccordion: FC<PlaidAccountListAccordionProps> = ({
  onAccountSelection,
  institutionAccounts,
  institutionName
}) => {
  const [expanded, setExpanded] = useState(false);

  const renderInstitutionAccountsListItems = () => (
    <View flexDirection={"row"}>
      {institutionAccounts.map((currentItem) => (
        <PlaidAccountListItem
          type={currentItem.type}
          key={currentItem.id}
          name={currentItem.name || ""}
          official_name={currentItem.official_name || ""}
          mask={currentItem.mask}
          onAccountItemPress={onAccountSelection}
          id={currentItem.id}
        />
      ))}
    </View>
  );

  return (
    <Accordion
      isExpanded={expanded}
      toggleIsExpanded={() => setExpanded(!expanded)}
      SummaryContent={<Text bold fontSize={20} value={institutionName} />}
      key={institutionName}
      isSummaryContentCompact
      customSummaryClasses={"plaidAccountSelectionListIcon plaidAccountSelectionListAccordionSummary"}
      DetailsContent={renderInstitutionAccountsListItems()}
      styles={{
        summary: {
          backgroundColor: "transparent",
          justifyContent: "start"
        },
        root: {
          backgroundColor: "transparent",
          border: "none"
        }
      }}
    />
  );
};
