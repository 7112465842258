import type { PropsWithChildren } from "react";
import React, { useMemo } from "react";
import { View } from "DLUI/view";
import type { JustifyContentFlexType } from "DLUI/text/types";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  type: "singleField" | "startElement" | "middleElement" | "endElement";
  marginTop?: number;
  marginRight?: number;
  noWrap?: boolean;
  justifyContent?: JustifyContentFlexType;
}

const FieldWrapper: React.FC<ComponentProps> = React.memo(
  ({ children, type, marginTop, marginRight, noWrap, justifyContent }: PropsWithChildren<ComponentProps>) => {
    const { isScreenSizeIn } = useResponsiveHelper();
    const isXSScreen = isScreenSizeIn(["xs"]);

    const [paddingRight, paddingLeft] = useMemo(() => {
      if (!isXSScreen) {
        return {
          singleField: [0, 0],
          startElement: [0, 10],
          middleElement: [10, 10],
          endElement: [10, 0]
        }[type];
      }
      return [0, 0];
    }, [isXSScreen, type]);

    return (
      <View
        paddingRight={paddingRight}
        paddingLeft={paddingLeft}
        alignItems={"center"}
        justifyContent={justifyContent || "flex-start"}
        flexDirection={"row"}
        noWrap={noWrap}
        marginTop={marginTop !== undefined ? marginTop : 20}
        marginRight={marginRight}
      >
        {children}
      </View>
    );
  }
);

export default FieldWrapper;
