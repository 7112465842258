"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertToBytes = void 0;
const convertToBytes = (size, unit) => {
    switch (unit) {
        case "b":
            return size;
        case "kb":
            return size * 1024;
        case "mb":
            return size * Math.pow(1024, 2);
        case "gb":
            return size * Math.pow(1024, 3);
        case "tb":
            return size * Math.pow(1024, 4);
        case "pb":
            return size * Math.pow(1024, 5);
        default:
            throw new Error(`Unsupported unit: ${unit}`);
    }
};
exports.convertToBytes = convertToBytes;
