import React from "react";
import EmptyDataView from "DLUI/emptyDataView";
import AppStrings from "locale/keys";

interface ComponentProps {
  isRecurring?: boolean;
}

const TasksEmptyDataView: React.FC<ComponentProps> = ({ isRecurring }: ComponentProps) => (
  <EmptyDataView
    instructionsText={
      isRecurring
        ? AppStrings.Common.Recurring.EmptyDataViewInstructionsTextTasks
        : AppStrings.Tasks.Screen.EmptydataViewInstructionsText
    }
  />
);

export default TasksEmptyDataView;
