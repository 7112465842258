import React, { lazy } from "react";
import type { RouteComponentProps } from "react-router-dom";
import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";

const UsersListScreen = lazy(() => import("screens/users/usersList/usersListScreen"));
const GeneralSettings = lazy(() => import("screens/settings/generalSettings/generalSettings"));

const SettingsRouter: React.FC<RouteComponentProps> = () => (
  <PageRouter>
    <LazyRoute exact path={Routes.EDIT_USER} render={UsersListScreen} />
    <LazyRoute path={Routes.SETTINGS_COMPANY_USERS} render={UsersListScreen} />
    <LazyRoute path={Routes.SETTINGS_GENERAL_SETTINGS} render={GeneralSettings} />
  </PageRouter>
);

export default SettingsRouter;
