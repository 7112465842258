import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    componentContainer: {
      boxSizing: "border-box",
      height: "100px",
      borderRadius: "4px",
      backgroundColor: theme.palette.white.main,
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",
      boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.07)",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "20px"
    },
    indicatorView: {
      width: "5px",
      height: "100%",
      borderRadius: "20px"
    },
    iconContainer: {
      width: "100px",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      display: "flex"
    },
    iconElmentContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    contentContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%"
    },

    actionIconContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      flexDirection: "column"
    },
    actionIcon: {
      marginBottom: "30px",
      margin: "0px 20px"
    },

    green: {
      backgroundColor: theme.palette.darkGreen.main
    },
    blue: {
      backgroundColor: theme.palette.blue.main
    },
    red: {
      backgroundColor: theme.palette.error.light
    },
    orange: {
      backgroundColor: theme.palette.orange.main
    },
    multiLineElementContainer: {
      backgroundColor: theme.palette.white.main,
      boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.07)",
      borderRadius: "4px",
      overflow: "hidden",
      position: "relative",
      display: "flex",

      marginTop: "20px",
      width: "100%",
      height: "100%"
    },
    multiLineSeparator: {
      backgroundColor: theme.palette.screenBackground.light
    },
    hoverViewContainer: {
      position: "absolute",
      left: 5,
      top: 5,
      borderRadius: 4,
      width: "calc(100% - 10px)",
      height: 145,
      zIndex: 5000,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer"
    },
    showEditView: {
      background: "rgba(124, 132, 172,0.9)",
      opacity: 1,
      transition: "background-color 0.5s ease-in"
    },
    hideEditView: {
      backgroundColor: theme.palette.white.main,
      opacity: 0,
      transition: "all 0.5s ease-out"
    },
    errorIcon: {
      marginLeft: 5
    },
    separator: {
      height: "50%",
      width: 1,
      backgroundColor: "rgba(124, 132, 172, 0.2);"
    },
    statusIcon: {
      width: "40px",
      height: "40px"
    },
    statusIconSmall: {
      width: "40px",
      height: "40px"
    }
  })
);
