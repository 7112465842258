import { DownloadSimpleIcon } from "assets/icons";
import clsx from "clsx";
import { View } from "DLUI/view";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { DefaultDebounceDelays, useDebounce } from "hooks/useDebounce";
import type { ObjectWithAnyStringKey, StaticRemoteTextOrDateFilter } from "../filterPanel/filterPanel";
import makeStyles from "./styles";
import _ from "lodash";
import type { PopoverItem } from "DLUI/popover";
import ListActionButtons from "DLUI/listActionButtons/listActionButtons";
import AppStrings from "locale/keys";
import DLButton, { DLButtonColorsEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import type { SearchPanelInputChangeFn } from "DLUI/screen/searchPanel/searchPanelInputChange";
import { isWebView } from "common/native/utils";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { filterObjectsByUserType } from "../../../../utils/userTypesUtils";
import { useUserType } from "../../../../hooks/useUserType";
import { SearchPanelFilterSection } from "./searchPanelFilterSection";
import { useQueryFilter } from "@/hooks/useQueryFilter";
import { useAnalyticsService } from "@/hooks/useAnalyticsService";

interface ComponentProps {
  searchPlaceHolderText?: string;
  filterOptions?: Array<StaticRemoteTextOrDateFilter<ObjectWithAnyStringKey>>;
  didChangeFilterOptions?: (filterObj: Record<string, any>) => void;
  initialFilterSelection?: Record<string, any>;
  requestInProgress?: boolean;
  actionButtons?: PopoverItem[];
  titleText?: string;
  preventUrlChange?: boolean;
  marginBottom?: number;
  hideLeftSide?: boolean;
  actionComponents?: JSX.Element;
  avoidOnChangeOnSetFilterPanel?: boolean;
  hideSearchBar?: boolean;
  hideFilterButton?: boolean;
  filterTriggers?: Record<string, any>;
  dataCy?: string;
  customQueryFilter?: string;
  moveFilterButtonToRight?: boolean;
  inputChangeFn?: SearchPanelInputChangeFn;
  width?: string;
}

const SearchPanel: React.FC<ComponentProps> = ({
  searchPlaceHolderText,
  filterOptions,
  didChangeFilterOptions,
  requestInProgress,
  actionButtons,
  actionComponents,
  marginBottom,
  initialFilterSelection,
  hideLeftSide = false,
  hideSearchBar = false,
  hideFilterButton = false,
  dataCy,
  filterTriggers,
  customQueryFilter,
  moveFilterButtonToRight = false,
  inputChangeFn,
  width
}: ComponentProps) => {
  const classes = makeStyles();
  const [searchQuery, setSearchQuery] = useState("");
  const isSearchQueryFirstRun = useRef(true);
  const { isTabletOrMobile, isMobile } = useResponsiveHelper();

  const { dispatchAnalytics } = useAnalyticsService();
  const trackFilterChange = (filterKey: string, filterValue: string) => {
    if (filterKey.startsWith("?")) {
      return;
    }

    if (filterKey === "filter_text") {
      dispatchAnalytics("inpage_search_applied", {
        userSearchQuery: filterValue
      });
      return;
    }

    dispatchAnalytics("filter_applied", {
      filterType: filterKey,
      filterValue
    });
  };

  const debouncedSearchQuery = useDebounce(searchQuery, DefaultDebounceDelays.TEXT_SEARCH_CHANGED);
  const debounceSearchQueryHadFirstInput = useRef(false);

  const { isHOAUser } = useUserType();

  const filteredFilterOptions =
    filterOptions && isHOAUser ? filterObjectsByUserType(filterOptions, "HOAUser") : filterOptions;

  const didChangeSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const renderSearchRightSide = () => {
    const availableActionButtons = !isHOAUser ? actionButtons : filterObjectsByUserType(actionButtons || [], "HOAUser");
    return availableActionButtons && !_.isEmpty(availableActionButtons) ? (
      <View autoWidth flexDirection={"row"} alignSelf="flex-end">
        <ListActionButtons items={availableActionButtons} actionComponents={actionComponents} />
      </View>
    ) : null;
  };

  const queryFilter = useQueryFilter(filteredFilterOptions ?? [], initialFilterSelection);
  const textFieldQuery = useMemo(
    () => (customQueryFilter ? { [customQueryFilter]: debouncedSearchQuery } : { filter_text: debouncedSearchQuery }),
    [customQueryFilter, debouncedSearchQuery]
  );

  useEffect(() => {
    if (!filteredFilterOptions) {
      return;
    }

    if (!debounceSearchQueryHadFirstInput.current) {
      didChangeFilterOptions?.(queryFilter);
    } else {
      didChangeFilterOptions?.({
        ...queryFilter,
        ...textFieldQuery
      });
    }

    const selectionKeys = Object.keys(queryFilter);
    const selectionValues = Object.values(queryFilter);
    selectionValues.forEach((currentSelectionValue, index) => {
      if (!selectionKeys[index]) {
        return;
      }

      trackFilterChange(selectionKeys[index], currentSelectionValue);

      inputChangeFn?.({
        fieldKey: selectionKeys[index],
        fieldValue: currentSelectionValue
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryFilter, textFieldQuery]);

  useEffect(() => {
    if (isSearchQueryFirstRun.current) {
      isSearchQueryFirstRun.current = false;
      return;
    }

    trackFilterChange("filter_text", debouncedSearchQuery);
    didChangeFilterOptions?.({
      ...queryFilter,
      ...textFieldQuery
    });

    if (debouncedSearchQuery) {
      debounceSearchQueryHadFirstInput.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  return (
    <View paddingTop={10} paddingBottom={10} noWrap width={width}>
      <div
        style={{ marginBottom }}
        className={clsx([
          classes.componentContainer,
          "searchPanel",
          isTabletOrMobile ? classes.mobileComponentContainer : ""
        ])}
      >
        <View alignItems={"center"} gap={10}>
          {hideLeftSide ? null : (
            <SearchPanelFilterSection
              inputProps={{
                query: searchQuery,
                placeholder: searchPlaceHolderText ?? "",
                isLoading: requestInProgress,
                onChange: didChangeSearchQuery
              }}
              hideSearchBar={hideSearchBar}
              filterButtonAlignment={moveFilterButtonToRight ? "right" : "left"}
              filterOptions={filteredFilterOptions}
              hideFilterButton={hideFilterButton || !filteredFilterOptions || filteredFilterOptions.length === 0}
              data-cy={dataCy}
            />
          )}
          {renderSearchRightSide()}
        </View>
        {isTabletOrMobile && filterTriggers && (
          <View
            marginTop={12}
            paddingRight={12}
            alignItems={"center"}
            flexDirection={"row"}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            {!isWebView() && (
              <DLButton
                onClick={filterTriggers?.downloadPdf?.trigger}
                isLoading={filterTriggers?.downloadPdf?.status}
                actionText={AppStrings.Common.DownloadPrintable}
                color={DLButtonColorsEnum.NEUTRAL}
                icons={{ start: { src: DownloadSimpleIcon } }}
                variant={DLButtonVariantsEnum.OUTLINED}
                style={{ marginRight: 10 }}
              />
            )}
            <DLButton
              onClick={filterTriggers?.run?.trigger}
              isLoading={filterTriggers?.run?.status}
              actionText={AppStrings.Reports.ReportsScreen.RunReport}
              color={DLButtonColorsEnum.PRIMARY}
            />
          </View>
        )}
      </div>
    </View>
  );
};

export default SearchPanel;
