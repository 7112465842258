import { View } from "DLUI/view";
import React, { useMemo, useState } from "react";
import DemoSignupLayout from "screens/authScreen/layout/demoSignupLayout";
import Text from "DLUI/text";
import { Icon } from "DLUI/icon";
import { AuthLogoIcon, LogoWhiteIcon } from "assets/icons";
import { SeparationLine } from "DLUI/separatorView";
import AppStrings from "locale/keys";
import { ShakeEffectView } from "DLUI/animatableView";
import { Grid } from "@material-ui/core";
import type { FormikProps } from "formik";
import { FastField, Formik } from "formik";
import { Select, TextField, ValidationIndicator } from "DLUI/form";
import { useTranslation } from "react-i18next";
import {
  createValidator,
  DoorLoopLeadDto,
  isValidLandingPage,
  isValidUtmSource,
  MarketingCampaign,
  MarketingCampaignNotSelectable,
  SegmentEventTypes
} from "@doorloop/dto";
import { useLocation } from "react-router-dom";
import _, { shuffle } from "lodash";
import { authApi } from "api/authApi";
import { NavigationManager } from "utils/navigation";
import qs from "query-string";
import { Dialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import RegisterAccountOfferDialog from "./registerAccountOfferDialog";
import { analyticsService } from "services/analyticsService";
import { history } from "store/history";
import { injectClickCaseScript } from "./clickCeaseScript";
import DLButton, { DLButtonSizesEnum } from "DLUI/button/dlButton";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import { ProdHelmet } from "@/components/helmets/prodHelmet";

interface ComponentProps {
  documentTitle?: string;
}

export const ExitIntentFieldName = "exitIntent";

const decodeURIComponentIteratively = (str: string, maxTries = 4): string => {
  // some cookies may be encoded multiple times
  // parse it in a loop until it doesn't change anymore
  for (let i = 0; i < maxTries; i++) {
    const decoded = decodeURIComponent(str);
    if (decoded === str) {
      // there was no change, we reached the final result
      return decoded;
    }
    str = decoded;
  }

  // if we reached here, we couldn't properly decode the cookie
  return str;
};

const parseCookie = (str: string): Record<string, string> => {
  // edge case - cookie is empty
  if (!str?.trim()) return {};

  // edge case - cookie is not in the format of key=value
  if (!str.includes("=")) return {};

  return str
    .split(";")
    .map((v) => v.split("="))
    .reduce((acc, v) => {
      // edge case - v?.[1] might not have a value - use optional access ?. to avoid crash
      acc[decodeURIComponentIteratively(v[0].trim())] = decodeURIComponentIteratively(v?.[1]?.trim());
      return acc;
    }, {});
};

const AnalyticsTrackProperties = {
  category: "Leads",
  label: "Demo"
};

const DemoAdditionalInfo: React.FC<ComponentProps> = ({ documentTitle }: ComponentProps) => {
  const cookieObj = parseCookie(decodeURIComponent(document.cookie));
  const dlmc = cookieObj?.dlmc ? JSON.parse(cookieObj.dlmc) : null;
  const { isMobile } = useResponsiveHelper();
  const [showErrorShakeEffect, setShowErrorShakeEffect] = useState(false);
  const [loadingInProgress, setLoadingInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { t } = useTranslation();
  const location = useLocation<any>();
  const recaptchaToken = useMemo(() => location.state?.recaptchaToken, []);
  const [dialogState, setDialogState] = useState(DialogState.Hidden);

  injectClickCaseScript();

  const runShakeEffect = () => {
    setShowErrorShakeEffect(true);
    setTimeout(() => {
      setShowErrorShakeEffect(false);
    }, 300);
  };

  const isDirectOrSourceNotEmpty = useMemo(
    () => dlmc?.utmSource?.toLowerCase() === "direct" || !isValidUtmSource(dlmc?.utmSource),
    [dlmc]
  );
  const validLandingPage = useMemo(() => isValidLandingPage(dlmc?.landingPage), [dlmc]);
  const isQueryParamsSourceGoogleAdsAndCampaignBranded = useMemo(
    () =>
      dlmc?.utmSource === MarketingCampaignNotSelectable.GoogleAds &&
      dlmc?.utmCampaign === MarketingCampaignNotSelectable.Branded,
    []
  );

  const shouldShowSourceFields = useMemo((): boolean => {
    if (validLandingPage) {
      return !validLandingPage;
    }
    return isDirectOrSourceNotEmpty || isQueryParamsSourceGoogleAdsAndCampaignBranded;
  }, [isDirectOrSourceNotEmpty, validLandingPage, isQueryParamsSourceGoogleAdsAndCampaignBranded]);

  const isValidForm = async (formikRef: FormikProps<DoorLoopLeadDto>) => {
    formikRef.setFieldTouched("name");
    formikRef.setFieldTouched("phone");
    formikRef.setFieldTouched("numberOfUnits");
    if (shouldShowSourceFields) {
      formikRef.setFieldTouched("source");
      if (formikRef.values.source === MarketingCampaign.Other) {
        formikRef.setFieldTouched("sourceOther");
      }
    }
    let errors = (await formikRef.validateForm()) as any;
    if (!shouldShowSourceFields) {
      errors = _.omit(errors, "source");
    }
    const _errors = _.isEmpty(errors);
    if (!_errors) {
      runShakeEffect();
      setErrorMessage(undefined);
    }
    return _errors;
  };

  const handleSubmit = async (values: DoorLoopLeadDto) => {
    if (loadingInProgress) {
      runShakeEffect();
      return;
    }
    setLoadingInProgress(true);
    if (isQueryParamsSourceGoogleAdsAndCampaignBranded) {
      values.medium = values.source;
      values.source = MarketingCampaignNotSelectable.GoogleAds;
      values.campaign = MarketingCampaignNotSelectable.Branded;
    }
    /**
     * in cases where we dont show the source input to the use and
     * if form validation passed we need to submit the form, since source field is required in dto,
     * we need to insert a default value to make server validation pass
     */
    if (_.isEmpty(values.source)) {
      values.source = MarketingCampaignNotSelectable.UtmSourceCookieNotFound;
    }
    const results = await authApi.createOrUpdateDemoLead(values, recaptchaToken).catch((e) => {
      setErrorMessage(e);
      setLoadingInProgress(false);
      runShakeEffect();
    });
    if (results && results.status && results.data) {
      analyticsService.track(SegmentEventTypes.LEAD_SUBMITTED_PERSONAL_INFO_FORM, {
        ...AnalyticsTrackProperties,
        value: 165
      });
      analyticsService.identifyLead(values.email, values.name, values.phone, values.numberOfUnits, values.sfLeadId);
      setErrorMessage(undefined);
      const queryString = qs.parse(location.search);
      queryString["email"] = values.email || "";
      queryString["name"] = values.name || "";
      queryString["phone"] = values.phone || "";
      queryString["numberOfUnits"] = values.numberOfUnits?.toString() || "";
      if (queryString["callback"]) {
        if (results.data.redirectToCallbackPage) {
          analyticsService.track(SegmentEventTypes.LEAD_AWAITING_CALLBACK, AnalyticsTrackProperties);
          // redirect to thanks page, do not let them schedule a demo
          document.location.href = "https://www.doorloop.com/thanks" + location.search;
          return;
        }
      }
      if (results.data.redirectToThanksPage) {
        analyticsService.track(SegmentEventTypes.LEAD_AWAITING_CALLBACK, AnalyticsTrackProperties);
        // redirect to thanks page, do not let them schedule a demo
        document.location.href = "https://www.doorloop.com/thanks-email" + location.search;
        return;
      }

      NavigationManager.scheduleDemo(qs.stringify(queryString));
    } else {
      runShakeEffect();
      setLoadingInProgress(false);
      setErrorMessage((results && results.message) || t(AppStrings.Common.GeneralError));
    }
  };

  const didPressScheduleDemo = async (formik: FormikProps<DoorLoopLeadDto>) => {
    const isValid = await isValidForm(formik);
    if (isValid) {
      handleSubmit(formik.values);
    } else {
      runShakeEffect();
    }
  };

  const initFormValues = useMemo(() => {
    if (location.state?.leadInfo) {
      return location.state?.leadInfo;
    }
    const signupDto = new DoorLoopLeadDto();
    const queryString = qs.parse(window.location.search);
    signupDto.email ||= queryString["email"] as string;
    return signupDto;
  }, []);

  const validateForm = createValidator(DoorLoopLeadDto);

  const didPressDismissButton = () => {
    setDialogState(DialogState.Hidden);
    analyticsService.track(SegmentEventTypes.EXIT_INTENT_DISMISSED, AnalyticsTrackProperties);
  };

  const didBlurFullNameInput = (nextValue: string) => {
    if (nextValue && nextValue !== "") {
      const queryString = qs.parse(window.location.search);
      queryString["name"] = nextValue;
      history.replace({
        search: qs.stringify(queryString)
      });
    }
  };

  const didBlurPhoneInput = (nextValue: string) => {
    if (nextValue && nextValue !== "") {
      const queryString = qs.parse(window.location.search);
      queryString["phone"] = nextValue;
      history.replace({
        search: qs.stringify(queryString)
      });
    }
  };

  return (
    <DemoSignupLayout hideMarketingView documentTitle={documentTitle} gap={0}>
      <ProdHelmet>
        <script async src="/scripts/vwo.js"></script>
      </ProdHelmet>
      <Formik initialValues={initFormValues} validate={validateForm} onSubmit={(values, { setSubmitting }) => {}}>
        {(formik) => (
          <View paddingLeft={isMobile ? 0 : 40} paddingRight={isMobile ? 0 : 40}>
            <View alignItems={"center"}>
              <Icon Source={isMobile ? LogoWhiteIcon : AuthLogoIcon} width={200} marginTop={30} />
              <SeparationLine width={"100%"} height={1} marginTop={20} />
            </View>

            <View
              paddingLeft={isMobile ? 10 : 40}
              paddingRight={isMobile ? 10 : 40}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Text
                fontSize={30}
                fontWeight={900}
                textTransform={"uppercase"}
                value={AppStrings.Common.DoorloopDemo}
                marginTop={20}
                color={isMobile ? "white" : "black"}
              />
              <Text
                fontSize={20}
                value={AppStrings.Common.DoorloopDemoAdditionalInfoDescription}
                marginTop={10}
                color={isMobile ? "white" : "gray"}
                align={"center"}
              />
              <View flexDirection={"row"} marginTop={20} gap={20}>
                <Grid item xs={12}>
                  <FastField
                    component={TextField}
                    label={t(AppStrings.Common.FullName)}
                    name={"name"}
                    required
                    variant={isMobile ? "filled" : "outlined"}
                    autoComplete={"on"}
                    onBlur={didBlurFullNameInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FastField
                    component={TextField}
                    label={t(AppStrings.Tenants.NewTenant.Phone)}
                    name={"phone"}
                    required
                    variant={isMobile ? "filled" : "outlined"}
                    autoComplete={"on"}
                    onBlur={didBlurPhoneInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FastField
                    component={TextField}
                    label={t(AppStrings.Auth.WizardStep2NumberOfUnits)}
                    name={"numberOfUnits"}
                    required
                    variant={isMobile ? "filled" : "outlined"}
                    formatType={"number"}
                  />
                </Grid>
                {shouldShowSourceFields && (
                  <>
                    <Grid item xs={12}>
                      <FastField
                        component={Select}
                        label={AppStrings.Auth.WizardStep2HowDidYouHear}
                        name={"source"}
                        required
                        selectionEnum={MarketingCampaign}
                        transform={shuffle}
                        uniqueKey={"source"}
                        variant={isMobile ? "filled" : "outlined"}
                        translationKey={"marketingCampaign"}
                      />
                    </Grid>
                    {formik.values.source === MarketingCampaign.Other && (
                      <Grid item xs={12}>
                        <FastField
                          component={TextField}
                          name="sourceOther"
                          label={t(AppStrings.Auth.WizardStep2HowDidYouHearOther)}
                          variant={isMobile ? "filled" : "outlined"}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </View>
              <ShakeEffectView showEffect={showErrorShakeEffect}>
                <View alignItems={"center"} marginTop={20}>
                  <DLButton
                    onClick={async () => await didPressScheduleDemo(formik)}
                    actionText={AppStrings.Navigation.Continue}
                    size={DLButtonSizesEnum.LARGE}
                    isLoading={loadingInProgress}
                    style={{ minWidth: 360 }}
                  />
                </View>
              </ShakeEffectView>
              <View maxWidth={390}>
                <ValidationIndicator
                  shouldShow={errorMessage !== undefined}
                  displayText={errorMessage || ""}
                  justifyContent={"center"}
                  fullWidth
                  themeColor={isMobile ? "white" : "red"}
                />
              </View>
            </View>
            <Dialog
              dialogState={dialogState}
              onClose={didPressDismissButton}
              Content={RegisterAccountOfferDialog}
              onBackdropClick={didPressDismissButton}
              showCloseIcon
              backgroundColor={"dark"}
            />
          </View>
        )}
      </Formik>
    </DemoSignupLayout>
  );
};

export default DemoAdditionalInfo;
