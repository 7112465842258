import React, { useMemo, useState } from "react";
import type { FormikProps } from "formik";
import { FastField, getIn } from "formik";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import Text from "DLUI/text";
import { RadioGroup } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import { propertiesApi } from "api/propertiesApi";
import { useTranslation } from "react-i18next";
import FormikCachedMultiSelectAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedMultiSelectAutoComplete";

interface ComponentProps {
  formikRef: FormikProps<any>;
}

const Properties: React.FC<ComponentProps> = ({ formikRef }: ComponentProps) => {
  const { t } = useTranslation();
  const propertiesDefualtValue = useMemo(() => {
    const _propertiesDefualtValue = getIn(formikRef.values, "properties");
    if (_propertiesDefualtValue && Array.isArray(_propertiesDefualtValue) && _propertiesDefualtValue.length > 0) {
      return _propertiesDefualtValue;
    }
    return undefined;
  }, []);

  const [selectSpecificProperty, setSelectSpecificProperty] = useState<boolean>(Boolean(propertiesDefualtValue));
  const onRadioGroupSelection = (selectedValue: string) => {
    setSelectSpecificProperty(selectedValue === "true");
    formikRef.setFieldValue("properties", selectedValue === "false" ? [] : propertiesDefualtValue);
  };

  return (
    <View flex={1}>
      <FastField
        uniqueKey={"LF"}
        component={RadioGroup}
        name={""}
        defaultValue={propertiesDefualtValue ? "true" : "false"}
        didChangeSelection={onRadioGroupSelection}
        radioGroupItems={[
          {
            label: AppStrings.Vendors.NewVendor.SinglePropertyDescription,
            value: "false"
          },
          {
            label: AppStrings.Vendors.NewVendor.SpecificPropertyDescription,
            value: "true"
          }
        ]}
      />
      <View
        shouldShow={selectSpecificProperty}
        showAnimation={"fade-in"}
        hideAnimation={"fade-out"}
        alignItems={"center"}
      >
        <View>
          <SeparationLine marginBottom={20} width={"100%"} height={1} />
          <View marginTop={20}>
            <Text value={AppStrings.Vendors.NewVendor.SelectPropertiesDescription} fontSize={16} color={"black"} />
            <FormikCachedMultiSelectAutoComplete
              uniqueIndex={"properties"}
              label={t(AppStrings.Vendors.NewVendor.SelectProperties)}
              apiHandler={propertiesApi}
              selectionFields={["id", "class"]}
              name={"properties"}
              displayNameKey={"name"}
              filterFieldName={"filter_text"}
              filterFieldValue={"name"}
              marginTop={10}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default Properties;
