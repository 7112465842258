import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import AppStrings from "locale/keys";
import { useParams } from "react-router-dom";
import qs from "query-string";
import { bankConnectApi } from "api/bankAccountsApi";
import type { AccountType } from "@doorloop/dto";
import AddNewConnectionView from "screens/transactions/components/bankConnect/dialogs/bankConnect/addNewConnectionView";
import PlaidIntegrationView from "screens/transactions/components/bankConnect/dialogs/bankConnect/plaidIntegrationView";
import type { GetAllPlaidAccountsQuery } from "@doorloop/dto";
import type { PlaidAccountListItemProps } from "screens/transactions/components/bankConnect/dialogs/bankConnect/plaidAccountListItem";
import PlaidAccountsList from "screens/transactions/components/bankConnect/dialogs/bankConnect/plaidAccountsList";
import FrameScrollableContainer from "screens/transactions/components/bankConnect/dialogs/bankConnect/frameScrollableContainer";
import { GetUpdateLinkTokenDto } from "@doorloop/dto";
import { plaidTransactionsApi } from "api/plaidTransactionsApi";
import OpeningBalancesRequiredView from "screens/transactions/components/bankConnect/dialogs/bankConnect/openingBalancesRequiredView";
import { NavigationManager } from "utils/navigation";
import { accountsApi } from "api/accounts";
import { QueryParams } from "utils/queryParams";

interface ComponentProps {
  onClose: () => void;
  onBackdropClick: () => void;
  dialogTitle: string;
}

const formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

export const BankConnectDialogHeight = 730;
export const BankConnectDialogWidth = 755;

const DialogViews = {
  LoadingView: 0,
  AddBankConnection: 1,
  PlaidIntegration: 2,
  ConnectBankAccount: 3,
  PlaidAccountsList: 4,
  OpeningBalanceRequired: 5
};

export const BankAccountConnectDialog: React.FC<ComponentProps> = ({ onClose, onBackdropClick }: ComponentProps) => {
  const { t } = useTranslation();
  const repairConnectionParam = QueryParams.get("repair");
  const reconnectAccountParam = QueryParams.get("reconnect");
  const plaidItemParam = qs.parse(location.search)["plaidItem"] as string | undefined;
  const reconnectAccountMode = reconnectAccountParam && plaidItemParam;
  const { accountId } = useParams<any>();
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const [connectBankAccountDialogState, setConnectBankAccountDialogState] = useState<DialogState>(DialogState.Show);
  const [connectBankAccountLoadingDialogErrorText, setConnectBankAccountLoadingDialogErrorText] = useState<string>("");

  const [accounts, setAccounts] = useState<PlaidAccountListItemProps[]>();
  const [plaidToken, setPlaidToken] = useState<string | undefined>();
  const [viewIndex, setViewIndex] = useState(0);
  const [currentSelectedPlaidAccount, setCurrentSelectedPlaidAccount] = useState<string | undefined>();

  const [initialLoadingRetryMethod, setInitialLoadingRetryMethod] = useState<
    "getBankConnectAccounts" | "updatePlaidLinkToken"
  >("getBankConnectAccounts");

  useEffect(() => {
    if (reconnectAccountMode) {
      updatePlaidLinkToken();
    } else if (repairConnectionParam) {
      repairConnection();
    } else {
      checkOpeningBalanceAndGetAccounts();
    }
  }, []);

  const checkOpeningBalanceAndGetAccounts = async () => {
    const getAccountResponse = await accountsApi.get(accountId);
    if (getAccountResponse && getAccountResponse.data && getAccountResponse.data.openingBalanceEntry === undefined) {
      setViewIndex(DialogViews.OpeningBalanceRequired);
    } else {
      await getBankConnectAccounts();
    }
  };

  const showInitialLoadErrorMessage = (error: string) => {
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(error);
  };
  const showConnectBankAccountErrorMessage = (error: string) => {
    setConnectBankAccountDialogState(DialogState.Error);
    setConnectBankAccountLoadingDialogErrorText(error);
  };

  const repairConnection = () => {
    setViewIndex(DialogViews.AddBankConnection);
  };

  const updatePlaidLinkToken = async () => {
    setLoadingDialogState(DialogState.Show);

    const params = new GetUpdateLinkTokenDto();
    params.plaidItem = plaidItemParam;
    const updatePlaidTokenResponse = await plaidTransactionsApi
      .updatePlaidAccountLinkToken(params)
      .catch((e) => showInitialLoadErrorMessage(e));
    setInitialLoadingRetryMethod("updatePlaidLinkToken");

    if (updatePlaidTokenResponse && updatePlaidTokenResponse.data && updatePlaidTokenResponse.data.token) {
      if (plaidItemParam) {
        didReceivedPlaidToken(updatePlaidTokenResponse.data.token);
      }
    } else {
      setInitialLoadingRetryMethod("updatePlaidLinkToken");
      showInitialLoadErrorMessage(
        updatePlaidTokenResponse && updatePlaidTokenResponse.message
          ? updatePlaidTokenResponse.message
          : t(AppStrings.Common.GeneralError)
      );
    }
  };

  const getBankConnectAccounts = async () => {
    setLoadingDialogState(DialogState.Show);
    const queryString = qs.parse(window.location.search);
    const accountType = queryString["accountType"] as AccountType.ASSET_BANK | AccountType.LIABILITY_CREDIT_CARD;
    const queryObject: GetAllPlaidAccountsQuery = {
      filter_isLinkedToAccount: false
    };
    if (accountType) {
      queryObject.filter_accountType = accountType;
    }
    const bankConnectResponse = await bankConnectApi.getAll(queryObject).catch((e) => {
      setInitialLoadingRetryMethod("getBankConnectAccounts");
      showInitialLoadErrorMessage(e);
    });

    if (bankConnectResponse && bankConnectResponse.status) {
      if (bankConnectResponse.data?.data.length === 0) {
        setLoadingDialogState(DialogState.Hidden);
        setViewIndex(DialogViews.AddBankConnection);
      } else {
        setAccounts(bankConnectResponse.data?.data as PlaidAccountListItemProps[]);
        setViewIndex(DialogViews.PlaidAccountsList);
      }
    } else {
      setInitialLoadingRetryMethod("getBankConnectAccounts");
      showInitialLoadErrorMessage(
        bankConnectResponse && bankConnectResponse.message
          ? bankConnectResponse.message
          : t(AppStrings.Common.GeneralError)
      );
    }
  };

  const didPressBackButton = () => {
    if (repairConnectionParam) {
      NavigationManager.viewBankAccounts();
    } else if (viewIndex > DialogViews.AddBankConnection) {
      setViewIndex(viewIndex - 1);
    } else if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const didReceivedPlaidToken = (token: string) => {
    setPlaidToken(token);
    setViewIndex(DialogViews.PlaidIntegration);
  };

  const didSelectAccountToConnect = async (plaidAccount: string) => {
    setCurrentSelectedPlaidAccount(plaidAccount);
    setConnectBankAccountDialogState(DialogState.Show);
    setViewIndex(DialogViews.ConnectBankAccount);
    const connectResponse = await plaidTransactionsApi
      .connectPlaidAccount(plaidAccount, accountId)
      .catch((e) => showConnectBankAccountErrorMessage(e));
    if (connectResponse && connectResponse.data) {
      NavigationManager.viewBankAccounts(true);
    } else {
      showConnectBankAccountErrorMessage(
        connectResponse && connectResponse.message ? connectResponse.message : t(AppStrings.Common.GeneralError)
      );
    }
  };

  const onAccountConnectRetry = () => {
    if (currentSelectedPlaidAccount) {
      didSelectAccountToConnect(currentSelectedPlaidAccount);
    }
  };

  const onConnectAnotherAccountPress = () => {
    setViewIndex(DialogViews.AddBankConnection);
  };

  const showAccountsSelectionList = () => {
    setViewIndex(DialogViews.PlaidAccountsList);
  };

  const onInitialLoadRetryPress = () => {
    if (initialLoadingRetryMethod === "getBankConnectAccounts") {
      getBankConnectAccounts();
    } else {
      updatePlaidLinkToken();
    }
  };

  const renderView = ({ index }: any) => {
    if (index === DialogViews.LoadingView) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          errorText={loadingDialogErrorText}
          onRetryButtonPress={onInitialLoadRetryPress}
          hideDismissButton
        />
      );
    }
    if (index === DialogViews.PlaidAccountsList) {
      return (
        <FrameScrollableContainer onClose={onBackdropClick}>
          <PlaidAccountsList
            accounts={accounts}
            onAccountSelection={didSelectAccountToConnect}
            showAddAccountButton
            didPressConnectAnotherAccount={onConnectAnotherAccountPress}
          />
        </FrameScrollableContainer>
      );
    }
    if (index === DialogViews.AddBankConnection) {
      return (
        <AddNewConnectionView
          isRepairConnectionMode={Boolean(repairConnectionParam)}
          didReceivePlaidToken={didReceivedPlaidToken}
          onClose={didPressBackButton}
        />
      );
    }
    if (index === DialogViews.PlaidIntegration) {
      return (
        <PlaidIntegrationView
          plaidToken={plaidToken || ""}
          onClose={onClose}
          onBackPress={didPressBackButton}
          onAccountSelection={didSelectAccountToConnect}
        />
      );
    }
    if (index === DialogViews.ConnectBankAccount) {
      return (
        <LoadingDialog
          dialogState={connectBankAccountDialogState}
          errorText={connectBankAccountLoadingDialogErrorText}
          onRetryButtonPress={onAccountConnectRetry}
          didPressDismissButton={showAccountsSelectionList}
          showLoadingText
        />
      );
    }
    if (index === DialogViews.OpeningBalanceRequired) {
      return <OpeningBalancesRequiredView accountId={accountId} onClose={onBackdropClick} />;
    }
    return <View />;
  };

  return (
    <DialogFrame
      useExperimentalDialogFrame
      onCloseButtonClick={didPressBackButton}
      width={BankConnectDialogWidth}
      height={BankConnectDialogHeight}
      renderView={renderView}
      numViews={5}
      activeView={viewIndex}
      frameType={"contentOnlyNoFrame"}
      hideActionPanel
    />
  );
};
