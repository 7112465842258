"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseSituation = void 0;
var LeaseSituation;
(function (LeaseSituation) {
    LeaseSituation["BALANCE_DUE"] = "BALANCE_DUE";
    LeaseSituation["CREDIT_AVAILABLE"] = "CREDIT_AVAILABLE";
    LeaseSituation["ZERO_BALANCE"] = "ZERO_BALANCE";
    LeaseSituation["EXPIRED"] = "EXPIRED";
    LeaseSituation["EVICTION"] = "EVICTION";
})(LeaseSituation || (exports.LeaseSituation = LeaseSituation = {}));
