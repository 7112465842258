import type { CSSProperties } from "react";
import React from "react";

import { View } from "DLUI/view";
import Text from "DLUI/text";
import type { IconProps } from "DLUI/icon";
import { Icon } from "DLUI/icon";

import { BankIcon, InfoOutlineIcon } from "../../../../../../../assets";
import { Chip, ChipColorsEnum } from "DLUI/chip";
import AppStrings from "../../../../../../../locale/keys";
import type { TextComponentProps } from "DLUI/text/text";

interface BankAccountNumberProps {
  accountName?: string;
  last4digits?: string;
  isActionRequired?: boolean;
  isUpdate?: boolean;
  iconProps?: Omit<IconProps, "Source">;
  textProps?: { accountText?: TextComponentProps; last4digitsText?: TextComponentProps };
  containerStyle?: CSSProperties;
}

const BankAccountNumber = ({
  accountName,
  last4digits,
  isActionRequired = false,
  isUpdate = false,
  iconProps,
  textProps,
  containerStyle
}: BankAccountNumberProps) => {
  const CARD_NUMBER_PREFIX = `•••• ${last4digits}`;
  return (
    <View
      fullWidth
      flex={1}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={isActionRequired || isUpdate ? "space-between" : "flex-start"}
      backgroundColor={"white"}
      borderRadius={5}
      noWrap
      gap={isActionRequired || isUpdate ? 0 : 10}
      paddingTop={10}
      paddingBottom={10}
      paddingRight={10}
      paddingLeft={10}
      style={containerStyle}
    >
      <View
        height={50}
        width={50}
        borderRadius={5}
        justifyContent={"center"}
        alignItems={"center"}
        style={{ backgroundColor: "rgba(122, 134, 153, 0.2)" }}
      >
        <Icon Source={BankIcon} height={30} {...iconProps} />
      </View>

      <View height={"100%"} maxWidth={185} justifyContent={"center"} marginRight={5} gap={2}>
        <Text bold overFlow={"ellipsis"} fontSize={14} {...textProps?.accountText}>
          {accountName}
        </Text>

        {last4digits && (
          <Text fontSize={14} lineHeight={"17px"} color={"secondary-gray"} {...textProps?.last4digitsText}>
            {CARD_NUMBER_PREFIX}
          </Text>
        )}
      </View>

      {isActionRequired ? (
        <Chip
          labelProps={{ translationKey: AppStrings.Accounts.StripeActionRequired }}
          color={ChipColorsEnum.DANGER}
          iconProps={{ src: InfoOutlineIcon }}
          isRectangle
        />
      ) : isUpdate ? (
        <Chip
          labelProps={{ translationKey: AppStrings.Common.Update }}
          color={ChipColorsEnum.SECONDARY}
          iconProps={{ src: InfoOutlineIcon }}
          isRectangle
        />
      ) : null}
    </View>
  );
};

export { BankAccountNumber };
