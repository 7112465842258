import { View } from "DLUI/view";
import Text from "DLUI/text";
import { SeparationLine } from "DLUI/separatorView";
import { Icon } from "DLUI/icon";
import { CopyLinkNewIcon } from "@/assets";
import React from "react";
import type { EnTranslation } from "locale";
import { useTypedTranslation } from "locale";
import { useCopyText } from "DLUI/button/useCopyText";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { DataCy } from "@doorloop/dto";

interface CopyLinkProps {
  label?: string;
  link: string;
  copyButtonText?: EnTranslation;
}

export const CopyLink = ({ label, link, copyButtonText = "common.copyLink" }: CopyLinkProps) => {
  const { t } = useTypedTranslation();
  const handleClickCopyLink = useCopyText(link || "");
  const { isMobile } = useResponsiveHelper();

  return (
    <View
      borderRadius={4}
      border={"1px solid rgba(24, 44, 76, 0.1)"}
      noWrap
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      marginTop={16}
      width={"100%"}
      style={{
        boxSizing: "border-box"
      }}
    >
      <View
        noWrap
        paddingTop={8}
        paddingRight={16}
        paddingLeft={16}
        paddingBottom={8}
        autoWidth
        maxWidth={isMobile ? "50%" : undefined}
        style={{ overflow: "hidden" }}
      >
        <Text fontSize={12} bold color={"secondary-gray"} value={label} />
        <Text fontSize={12} color={"secondary-gray"} textWrap={"nowrap"} overFlow={"ellipsis"}>
          {link}
        </Text>
      </View>
      <View noWrap autoWidth flexDirection={"row"} height={"100%"} alignItems={"center"}>
        <SeparationLine width={1} height={37} />
        <View
          noWrap
          flexDirection={"row"}
          alignItems={"center"}
          gap={2}
          onClick={handleClickCopyLink}
          paddingLeft={16}
          paddingRight={16}
          dataCy={DataCy.globalSettings.listingSyndication.copyLink}
        >
          <Icon Source={CopyLinkNewIcon} size={12} pathColor={"light-blue"} />
          <Text value={t(copyButtonText)} color={"lightBlue"} fontSize={12} bold />
        </View>
      </View>
    </View>
  );
};
