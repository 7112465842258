import type { FC } from "react";
import React, { useMemo } from "react";
import type { ConfirmationDialogTypes, ConfirmationTextConfiguration } from "./utils";
import makeStyles from "./style";
import { View } from "DLUI/view";
import { useResponsiveHelper } from "@/contexts/responsiveContext";
import { LoadingDialog } from "../components/loading";
import { DialogState } from "../loadingDialog";
import { ConfirmationDialogContent } from "./confirmationDialogContent";
import { CHECKBOX_DIALOG_WIDTH, DEFAULT_DIALOG_WIDTH, MOBILE_DIALOG_WIDTH } from "./utils";

interface ConfirmationDialogProps {
  dialogType?: ConfirmationDialogTypes;
  handleClose: () => void;
  handleConfirm?: () => void;
  textConfiguration: ConfirmationTextConfiguration;
  isLoading?: boolean;
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  dialogType = "info",
  handleConfirm,
  handleClose,
  textConfiguration,
  isLoading
}) => {
  const { isMobile } = useResponsiveHelper();
  const { discardUnsavedDialog, discardUnsavedContainer, discardUnsavedOverlay } = makeStyles();
  const { checkboxText, errorMessage } = textConfiguration;

  const maxWidth = useMemo(() => {
    if (isMobile) {
      return MOBILE_DIALOG_WIDTH;
    }

    if (checkboxText) {
      return CHECKBOX_DIALOG_WIDTH;
    }

    return DEFAULT_DIALOG_WIDTH;
  }, [checkboxText, isMobile]);

  return (
    <View
      className={discardUnsavedDialog}
      alignItems={"center"}
      justifyContent={"center"}
      height={"100vh"}
      width={"100vw"}
    >
      <div className={discardUnsavedOverlay} />
      <View
        className={discardUnsavedContainer}
        backgroundColor={"white"}
        maxWidth={maxWidth}
        alignItems={isMobile ? "center" : "flex-start"}
        justifyContent={"space-between"}
        borderRadius={10}
      >
        {errorMessage || isLoading ? (
          <LoadingDialog
            errorText={errorMessage}
            backgroundColor={"white"}
            dialogState={isLoading ? DialogState.Show : DialogState.Error}
          />
        ) : (
          <ConfirmationDialogContent
            handleClose={handleClose}
            handleConfirm={handleConfirm}
            textConfiguration={textConfiguration}
            dialogType={dialogType}
          />
        )}
      </View>
    </View>
  );
};

export default ConfirmationDialog;
