import React, { useState } from "react";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, getIn } from "formik";
import { RadioGroup } from "DLUI/form";
import AppStrings from "locale/keys";
import { SeparationLine } from "DLUI/separatorView";
import { useTranslation } from "react-i18next";
import Text from "DLUI/text";
import type { UserDto } from "@doorloop/dto";
import { AccountType } from "@doorloop/dto";
import _ from "lodash";
import { accountsApi } from "api/accounts";
import FormikCachedMultiSelectAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedMultiSelectAutoComplete";

interface ComponentProps {
  formikRef: FormikProps<UserDto>;
}

type RestrictionTypes = "all" | "some";

export const BankAccountsAccess: React.FC<ComponentProps> = ({ formikRef }: ComponentProps) => {
  const { t } = useTranslation();
  const bankAccounts = getIn(formikRef.values, "bankAccounts") || [];
  const [restriction, setRestriction] = useState<RestrictionTypes>(_.isEmpty(bankAccounts) ? "all" : "some");

  const didChangeRestriction = (selectedValue: RestrictionTypes) => {
    formikRef.setFieldValue("bankAccounts", selectedValue === "some" ? [] : undefined);
    setRestriction(selectedValue);
  };

  return (
    <View flex={1}>
      <View width={"100%"}>
        <FastField
          uniqueKey={"LF"}
          component={RadioGroup}
          name={"bankAccountsRestriction"}
          defaultValue={restriction}
          didChangeSelection={didChangeRestriction}
          radioGroupItems={[
            {
              label: AppStrings.Users.NewUser.AccessAllBankAccounts,
              value: "all"
            },
            {
              label: AppStrings.Users.NewUser.AccessSomeBankAccounts,
              value: "some"
            }
          ]}
        />
        <View shouldShow={restriction === "some"} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
          <View>
            <SeparationLine marginTop={20} width={"100%"} height={1} />
            <Text value={AppStrings.Users.NewUser.BankAccountsSelectionDescription} fontSize={16} marginTop={20} />
            <FormikCachedMultiSelectAutoComplete
              uniqueIndex={"usersApi"}
              apiHandler={accountsApi}
              displayNameKey={"name"}
              filterFieldName={"filter_text"}
              filterFieldValue={"name"}
              selectionFields={["id", "class"]}
              name={"bankAccounts"}
              queryParams={{
                filter_types: [AccountType.ASSET_BANK, AccountType.LIABILITY_CREDIT_CARD]
              }}
              label={t(AppStrings.Properties.Settings.BankAccountsTitle)}
              marginTop={20}
            />
          </View>
        </View>
      </View>
    </View>
  );
};
