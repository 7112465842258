"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OwnerAvailableFundsReportItem = void 0;
const class_validator_1 = require("class-validator");
const propertyAvailableFundsReportItem_dto_1 = require("../propertyAvailableFunds/propertyAvailableFundsReportItem.dto");
const mathUtils_1 = require("../../utils/mathUtils");
class OwnerAvailableFundsReportItem extends propertyAvailableFundsReportItem_dto_1.PropertyAvailableFundsReportItem {
    constructor(property, propertyOperatingAcount, bankBalance, propertyReserve, depositsHeld, owner, ownershipPercent) {
        super(property, propertyOperatingAcount, bankBalance, propertyReserve, depositsHeld);
        this.owner = owner;
        this.ownershipPercent = ownershipPercent;
        this.ownerMaxDistribution = mathUtils_1.mathUtils.divide(mathUtils_1.mathUtils.multiply(ownershipPercent, this.getAvailableFundsExcludingPropertyReserve()), 100);
    }
}
exports.OwnerAvailableFundsReportItem = OwnerAvailableFundsReportItem;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsNumber)({ maxDecimalPlaces: 2 }),
    (0, class_validator_1.Min)(0),
    __metadata("design:type", Number)
], OwnerAvailableFundsReportItem.prototype, "amountToDistribute", void 0);
