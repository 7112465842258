import React, { useEffect, useRef, useState } from "react";
import { ValidationIndicator } from "DLUI/form";
import { FormikImageButtonGroup } from "DLUI/form";
import Text from "DLUI/text";
import { Field, getIn, useFormikContext } from "formik";
import AppStrings from "locale/keys";
import type { PropertyTypeItemType } from "./propertyTypes";
import _ from "lodash";
import type { Scroller } from "DLUI/screen";
import { View } from "DLUI/view";
import { useUserType } from "hooks/useUserType";
import { CommercialSubTypeSelector } from "screens/properties/newProperty/components/propertyType/commercialSubTypeSelector";
import { ResidentialSubTypeSelector } from "screens/properties/newProperty/components/propertyType/residentialSubTypeSelector";
import { getPropertySubTypes, propertyTypes } from "./propertyTypes";
import { PropertyTypeKey } from "./propertyType.types";

interface ComponentProps {
  scrollerRef: Scroller | null;
  name: string;
  typeDescriptionName: string;
  hideTitle?: boolean;
  flexDirection?: "flex-start" | "center";
  validationMaxWidth?: number;
  onMainTypeChange?: (newType: string) => void;
  customSize?: number;
}

const PropertyType: React.FC<ComponentProps> = ({
  scrollerRef,
  name,
  hideTitle,
  flexDirection,
  validationMaxWidth,
  onMainTypeChange,
  customSize,
  typeDescriptionName
}) => {
  const { isHOAUser } = useUserType();
  const propertySubTypes = getPropertySubTypes(isHOAUser);

  const formikRef = useFormikContext();
  const [scroller, setScroller] = useState(scrollerRef);
  const propertyTypeDefaultValue = getIn(formikRef.values, name);

  const nextPropertySelection = _.cloneDeep(propertyTypes);
  let propertySelectionName = "";

  if (propertyTypeDefaultValue) {
    propertySelectionName = propertyTypeDefaultValue.split("_")[0];
    if (propertySelectionName === PropertyTypeKey.Residential) {
      nextPropertySelection[0].isSelected = true;
    } else {
      nextPropertySelection[1].isSelected = true;
    }
  }

  const propertySelection = useRef(nextPropertySelection).current;

  const [propertySubTypeSelection, setPropertySubTypeSelection] = useState<PropertyTypeItemType[]>(
    propertyTypeDefaultValue && propertySelectionName !== "" ? propertySubTypes[propertySelectionName] : []
  );

  const [propertySelectedType, setPropertySelectedType] = useState(propertySelectionName);

  const errorText = getIn(formikRef.errors, name);
  const touchedVal = getIn(formikRef.touched, name);
  const propertyTypeHasError = touchedVal && errorText && !propertySelectedType;

  const propertySubTypeHasError = Boolean(touchedVal && errorText && propertySelectedType);

  const propertySubTypeSelectionRef = useRef(null);

  useEffect(() => {
    if (propertySelectedType) {
      const nextSelection = propertySubTypes[propertySelectedType];
      setPropertySubTypeSelection(nextSelection);

      if (scroller) {
        scroller.scrollBottom(500);
      }
    }
  }, [propertySelectedType, propertySubTypes, scroller]);

  useEffect(() => {
    setScroller(scrollerRef);
  }, [scrollerRef]);

  const didPressPropertyItem = (_selectedValue: string, propertyIndex: number) => {
    if (propertySubTypeSelectionRef.current) {
      // @ts-expect-error Missing type
      propertySubTypeSelectionRef.current.clearSelection();
    }
    if (formikRef) {
      formikRef.setTouched({});
    }
    setPropertySubTypeSelection([]);
    const newType = propertySelection[propertyIndex].key;
    setPropertySelectedType(newType);
    onMainTypeChange && onMainTypeChange(newType);
  };

  const onPropertySubTypeSelectionRef = (ref: React.MutableRefObject<any>) => {
    propertySubTypeSelectionRef.current = ref.current;
  };

  return (
    <View alignItems={flexDirection || "center"} justifyContent={"center"}>
      <View flex={1} marginTop={20}>
        <View alignItems={flexDirection || "center"}>
          {hideTitle ? null : (
            <Text
              align={"center"}
              value={AppStrings.Properties.Screen.ChoosePropertyTypelabel}
              color={"black"}
              fontWeight={700}
              fontSize={20}
            />
          )}

          <Text value={AppStrings.Properties.Screen.WhatKindOfProperty} fontSize={16} color={"gray"} marginTop={10} />
        </View>
        <View alignItems={"center"}>
          <Field
            component={FormikImageButtonGroup}
            uniqueId={name}
            buttonItemsArray={propertySelection}
            onSelect={didPressPropertyItem}
            size={customSize || 120}
            flexDirection={flexDirection}
            noWrap
          />

          <ValidationIndicator shouldShow={propertyTypeHasError} maxWidth={650} displayText={errorText} />
          {propertySelectedType === "COMMERCIAL" ? (
            <CommercialSubTypeSelector
              onPropertySubTypeSelectionRef={onPropertySubTypeSelectionRef}
              name={name}
              typeDescriptionName={typeDescriptionName}
              propertyTypeItems={propertySubTypeSelection}
            />
          ) : (
            <ResidentialSubTypeSelector
              onPropertySubTypeSelectionRef={onPropertySubTypeSelectionRef}
              name={name}
              typeDescriptionName={typeDescriptionName}
              propertyTypeItems={propertySubTypeSelection}
            />
          )}
          <ValidationIndicator
            shouldShow={propertySubTypeHasError}
            maxWidth={validationMaxWidth || 650}
            displayText={errorText}
          />
        </View>
      </View>
    </View>
  );
};

export default PropertyType;
