import React, { useMemo } from "react";
import makeStyles from "./styles";
import type { SVGIconComponent } from "assets/icons";
import { AddCloseCirclePinkIcon, RedCircleXIcon, UserProfileDefault, UserProfileDefaultLight } from "assets/icons";
import IconButton from "@material-ui/core/IconButton";
import type { FillColors } from "DLUI/icon";
import { Icon } from "DLUI/icon";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { NAVIGATION_ITEM_ICON_SIZE } from "DLUI/drawer/navigationItem";

export interface ProfileImageProps {
  pictureUrl?: string;
  size?: number;
  marginTop?: number;
  marginLeft?: number;
  marginRight?: number;
  showDeleteButton?: boolean;
  alternateDeleteButtonPlacing?: boolean;
  didPressDeleteButton?: () => void;
  initialsLetters?: string;
  bottomText?: string;
  onPress?: () => void;
  AvatarIcon?: SVGIconComponent;
  borderWidth?: number;
  borderColor?: string;
  pathColor?: FillColors | undefined;
}

const ProfileImage: React.FC<ProfileImageProps> = React.memo(
  ({
    pictureUrl,
    size = 50,
    marginTop,
    marginLeft,
    marginRight,
    alternateDeleteButtonPlacing,
    showDeleteButton,
    bottomText,
    didPressDeleteButton,
    initialsLetters,
    onPress,
    AvatarIcon,
    borderWidth,
    borderColor,
    pathColor
  }: ProfileImageProps) => {
    const { isMobile, isTabletOrMobile } = useResponsiveHelper();
    const avatarIcon = AvatarIcon || (isMobile ? UserProfileDefaultLight : UserProfileDefault);
    const avatarIconColor = pathColor || (isMobile ? "light-gray" : "gray");

    const classes = makeStyles();

    const _size = useMemo(() => {
      let calculatedSize = size || 40;

      if (isTabletOrMobile) {
        calculatedSize = NAVIGATION_ITEM_ICON_SIZE;
      }

      if (pictureUrl) {
        calculatedSize -= 2;
      }

      return calculatedSize;
    }, [isTabletOrMobile]);

    const _didPressDeleteButton = () => {
      if (didPressDeleteButton) {
        didPressDeleteButton();
      }
    };

    const renderDeleteButton = () => {
      if (!showDeleteButton) {
        return null;
      }
      return (
        <div
          style={{
            top: alternateDeleteButtonPlacing ? -35 : -15,
            right: alternateDeleteButtonPlacing ? -35 : -15,
            zIndex: 500,
            position: "absolute"
          }}
        >
          <IconButton onClick={_didPressDeleteButton}>
            <Icon
              Source={alternateDeleteButtonPlacing ? RedCircleXIcon : AddCloseCirclePinkIcon}
              width={alternateDeleteButtonPlacing ? 25 : 15}
              height={alternateDeleteButtonPlacing ? 25 : 15}
            />
          </IconButton>
        </div>
      );
    };

    const renderImageContent = () => {
      if (pictureUrl) {
        return null;
      }
      if (initialsLetters) {
        return (
          <View
            backgroundColor={"dark"}
            width={_size}
            height={_size}
            borderRadius={_size / 2}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"row"}
          >
            <Text color={"gray"} fontSize={14} fontWeight={700} textTransform={"uppercase"}>
              {initialsLetters}
            </Text>
          </View>
        );
      }

      return (
        <Icon
          Source={avatarIcon}
          width={_size}
          height={_size}
          pathColor={avatarIconColor}
          rectFill={!isMobile ? "gray" : undefined}
        />
      );
    };

    return (
      <View
        marginLeft={marginLeft || 0}
        marginRight={marginRight || 0}
        alignItems={"center"}
        width={"auto"}
        onClick={onPress}
        noWrap
      >
        <div
          className={classes.profileImage}
          style={{
            backgroundImage: pictureUrl ? "url(" + pictureUrl + ")" : "none",
            width: _size,
            height: _size,
            borderWidth: borderWidth || (pictureUrl ? 2 : 0),
            marginTop: marginTop || 0,
            borderColor
          }}
        >
          {renderDeleteButton()}
          {renderImageContent()}
        </div>
        {bottomText ? (
          <Text
            fontWeight={700}
            value={bottomText}
            marginTop={5}
            fontSize={10}
            color={"black"}
            align={"center"}
            fullWidth
          />
        ) : null}
      </View>
    );
  }
);

ProfileImage.displayName = "ProfileImage";

export default ProfileImage;
