import {
  RegionAndCurrencySettingsDto,
  currencyCodes,
  regionNames,
  SettingPermission,
  createValidator
} from "@doorloop/dto";
import { settingsApi } from "api/settingsApi";
import { CalendarIcon, CashIcon } from "assets/icons";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import { Select } from "DLUI/form";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, Formik, getIn } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import * as authActions from "store/auth/actions";
import { getLocaleData } from "utils/intlProvider";
import RegionAndCurrencyPreviewItem from "./regionAndCurrencyPreviewItem";
import { SettingsReadOnlyScope } from "DLUI/permissionScope/readOnlyScope";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";

interface ComponentProps {
  onClose: () => void;
  onBackdropClick: () => void;
  dialogTitle?: string;
}

let formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

const validateForm = createValidator(RegionAndCurrencySettingsDto);

const RegionAndCurrency: React.FC<ComponentProps> = ({ onClose, onBackdropClick, dialogTitle }: ComponentProps) => {
  const { t } = useTranslation();
  const { formatNumberToParts } = useIntl();
  const dispatch = useDispatch();
  const { dialogHorizontalPadding } = DialogsHelper();
  const [viewIndex, setViewIndex] = useState(0);
  const [regionAndCurrencyData, setRegionAndCurrencyData] = useState<RegionAndCurrencySettingsDto | undefined>();
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState<string>("");
  const [loadingDialogSuccessText, setLoadingDialogSuccessText] = useState<string>(
    t(AppStrings.Settings.GeneralSettings.RegionAndCurrency.LoadingRegionAndCurrencyDataSuccessText)
  );

  useEffect(() => {
    loadSettingsData();
  }, []);

  const showErrorMessage = (message: string) => {
    setLoadingDialogErrorText(message);
    setLoadingDialogState(DialogState.Error);
  };

  const loadSettingsData = async () => {
    setLoadingDialogLoadingText(t(AppStrings.Settings.GeneralSettings.RegionAndCurrency.LoadingRegionAndCurrencyData));
    setLoadingDialogState(DialogState.Show);
    const response = await settingsApi.getRegionAndCurrencyData().catch((e) => {
      showErrorMessage(e);
    });
    if (response && response.data) {
      setLoadingDialogSuccessText(
        t(AppStrings.Settings.GeneralSettings.RegionAndCurrency.LoadingRegionAndCurrencyDataSuccessText)
      );
      setLoadingDialogState(DialogState.Success);
      setRegionAndCurrencyData(response.data);
      setLoadingDialogState(DialogState.Hidden);
      return;
    }
    if (response) {
      showErrorMessage(response.message);
    }

    showErrorMessage(t(AppStrings.Common.NetworkErrorSubTitle));
  };

  const didPressDismissButton = () => {
    onBackdropClick();
  };

  const onUpdateRetryPress = () => {
    if (formikGlobalRef) {
      updateRegionAndCurrencySettings(formikGlobalRef.values);
    }
  };

  const renderView = ({ index }: any) => {
    if (index === 0) {
      if (loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={loadSettingsData}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }
      return renderForm();
    }
    if (index === 1) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          loadingText={loadingDialogLoadingText}
          errorText={loadingDialogErrorText}
          successText={t(AppStrings.Settings.GeneralSettings.RegionAndCurrency.LoadingRegionAndCurrencyDataSuccessText)}
          onRetryButtonPress={onUpdateRetryPress}
          didPressDismissButton={didPressDismissButton}
          showTitle={false}
        />
      );
    }

    return <div />;
  };

  const initFormvalues = (): RegionAndCurrencySettingsDto => {
    if (regionAndCurrencyData) {
      return regionAndCurrencyData;
    }
    return new RegionAndCurrencySettingsDto();
  };

  const renderForm = () => {
    const formInitialValues = initFormvalues();

    const renderDateFormats = () => {
      let regionSelection = "en_us";
      if (formikGlobalRef) {
        regionSelection = getIn(formikGlobalRef.values, "cultureName");
      }
      const shortDateDisplay = new Date().toLocaleString(regionSelection, {
        //@ts-ignore
        dateStyle: "short",
        timeStyle: "short"
      });
      const longDateDisplay = new Date().toLocaleString(regionSelection, {
        //@ts-ignore
        dateStyle: "long",
        timeStyle: "long"
      });
      return (
        <RegionAndCurrencyPreviewItem
          title={AppStrings.Settings.GeneralSettings.RegionAndCurrency.DateFormats}
          icon={CalendarIcon}
          previewStrings={[shortDateDisplay, longDateDisplay]}
        />
      );
    };
    const renderCurrencyFormats = () => {
      let regionSelection = "en_us";
      if (formikGlobalRef) {
        regionSelection = getIn(formikGlobalRef.values, "cultureName");
      }
      let currencySelection = "USD";
      if (formikGlobalRef) {
        currencySelection = getIn(formikGlobalRef.values, "currencyCode");
      }
      const longCurrencyDisplay = new Intl.NumberFormat(regionSelection, {
        style: "currency",
        currency: currencySelection
      }).format(45678.9);
      const shortCurrencyDisplay = new Intl.NumberFormat(regionSelection, {
        maximumSignificantDigits: 2
      }).format(12345.67);
      return (
        <RegionAndCurrencyPreviewItem
          title={AppStrings.Settings.GeneralSettings.RegionAndCurrency.NumberFormats}
          icon={CashIcon}
          previewStrings={[shortCurrencyDisplay, longCurrencyDisplay]}
        />
      );
    };

    return (
      <Formik initialValues={formInitialValues} onSubmit={(values, { setSubmitting }) => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;
          return (
            <View
              marginTop={20}
              paddingRight={dialogHorizontalPadding}
              paddingLeft={dialogHorizontalPadding}
              flex={1}
              width={"100%"}
            >
              <FastField
                component={Select}
                name={`cultureName`}
                label={AppStrings.Settings.GeneralSettings.RegionAndCurrency.Region}
                required
                uniqueKey={"cultureName"}
                jsonFile={regionNames}
                jsonkeys={["country", "language"]}
                jsonValue={"name"}
              />
              {renderDateFormats()}
              <FastField
                component={Select}
                name={`currencyCode`}
                label={AppStrings.Settings.GeneralSettings.RegionAndCurrency.Currency}
                required
                uniqueKey={"currencyCode"}
                jsonFile={currencyCodes}
                jsonkeys={["display"]}
                jsonValue={"code"}
                marginTop={20}
              />
              {renderCurrencyFormats()}
            </View>
          );
        }}
      </Formik>
    );
  };

  const _onBackdropClick = () => {
    if (viewIndex > 0) {
      setViewIndex(viewIndex - 1);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const currentTitle = useMemo(() => {
    if (viewIndex === 0) {
      return dialogTitle;
    }

    if (viewIndex === 2) {
      return AppStrings.Vendors.Screen.AddNewVendor;
    }

    if (viewIndex === 1) {
      return AppStrings.Vendors.VendorDetails.SelectVendor;
    }
    return "";
  }, [viewIndex]);

  const isValidForm = async (formikRef: FormikProps<RegionAndCurrencySettingsDto>) => {
    formikRef.setFieldTouched("cultureName");
    formikRef.setFieldTouched("currencyCode");

    const errors = (await formikRef.validateForm()) as any;
    return _.isEmpty(errors);
  };

  const didPressSaveButton = async () => {
    if (formikGlobalRef !== null) {
      const isValid = await isValidForm(formikGlobalRef);
      if (isValid) {
        await updateRegionAndCurrencySettings(formikGlobalRef.values);
      }
    }
  };

  const updateRegionAndCurrencySettings = async (values: RegionAndCurrencySettingsDto) => {
    setLoadingDialogLoadingText(t(AppStrings.Settings.GeneralSettings.RegionAndCurrency.UpdatingRegionAndCurrencyData));
    setLoadingDialogState(DialogState.Show);
    setViewIndex(1);

    const response = await settingsApi.updateRegionAndCurrencyData(values).catch((e) => {
      showErrorMessage(e);
    });
    if (response && response.data) {
      setLoadingDialogSuccessText(
        t(AppStrings.Settings.GeneralSettings.RegionAndCurrency.UpdateRegionAndCurrencySuccessText)
      );
      setLoadingDialogState(DialogState.Success);
      if (response.data.cultureName && response.data.currencyCode) {
        const nextLocaleData = getLocaleData(
          response.data.currencyCode,
          response.data.cultureName,
          //@ts-ignore
          formatNumberToParts
        );
        // save the updated data in redux store
        dispatch(authActions.updateLocaleData(nextLocaleData));
      }

      onClose();
    } else if (response) {
      showErrorMessage(response.message);
    }
  };

  const renderActionPanelButtons = () => (
    <FormActionButtons
      propsSubButton={{ onClick: _onBackdropClick }}
      propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
    />
  );

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      if (loadingDialogState !== DialogState.Hidden) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }
    if (viewIndex === 1) {
      return "contentOnly";
    }
    return "sectionTitleFrame";
  }, [viewIndex, loadingDialogState]);
  return (
    <SettingsReadOnlyScope permission={SettingPermission.regionAndCurrency}>
      <DialogFrame
        onCloseButtonClick={_onBackdropClick}
        title={currentTitle}
        width={getDialogFrameDimension("width", 760)}
        height={getDialogFrameDimension("height", 450)}
        renderView={renderView}
        numViews={2}
        activeView={viewIndex}
        RenderActionPanelButtons={renderActionPanelButtons}
        frameType={frameType}
        keepViewsMounted={false}
      />
    </SettingsReadOnlyScope>
  );
};

export default RegionAndCurrency;
