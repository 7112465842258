import { DataCy, ReportId, ReportPermission, SendTaxForms, TAX_YEAR_LAST, TaxForm1099ShowRows } from "@doorloop/dto";

import AppStrings from "locale/keys";
import { ownerPortalNavigation } from "utils/ownerPortalNavigation";
import type { ReportScreenItemType } from "DLUI/report/screen/reportScreenItemType";

export const OWNERS_REPORT_ITEMS: ReportScreenItemType[] = [
  {
    description: AppStrings.Reports.ReportsScreen.PropertyOwnership,
    onPress: () =>
      ownerPortalNavigation.runPropertyOwnershipReport({
        groupBy: "property"
      }),
    permission: ReportPermission.ownerTransactions,
    id: ReportId.PROPERTY_OWNERSHIP,
    dataCy: DataCy.reports.screenItems.owners.propertyOwnership
  },
  {
    description: AppStrings.Reports.ReportsScreen.Owners1099,
    onPress: () =>
      ownerPortalNavigation.runOwners1099Report({
        filter_year: TAX_YEAR_LAST.toString(),
        showRows: TaxForm1099ShowRows.ABOVE_MINIMUM,
        filter_sendForms: SendTaxForms.Yes
      }),
    permission: ReportPermission.owners1099,
    id: ReportId.OWNERS_1099,
    dataCy: DataCy.reports.screenItems.owners._1099MISC
  }
];
