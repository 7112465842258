import React from "react";
import { View } from "DLUI/view";
import type { ESignatureRequestDto } from "@doorloop/dto";
import { ESignatureRequestStatus } from "@doorloop/dto";
import SignersList from "screens/esignatures/viewSignatureRequest/signersList";
import DialogActionPanel from "screens/esignatures/viewSignatureRequest/dialogActionPanel";
import RequestByUser from "screens/esignatures/viewSignatureRequest/requestByUser";
import DialogHeader from "screens/esignatures/viewSignatureRequest/dialogHeader";

import RequestDocument from "screens/esignatures/viewSignatureRequest/requestDocument";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  signatureRequestData: ESignatureRequestDto;
  onBackdropClick: () => void;
  didPressDeleteButton: () => void;
  didPressCancelRequestButton: () => void;
}

const SignatureRequestPreview: React.FC<ComponentProps> = ({
  signatureRequestData,
  onBackdropClick,
  didPressDeleteButton,
  didPressCancelRequestButton
}: ComponentProps) => {
  const { title, createdAt, createdBy, signers, id, setSignersOrder, status } = signatureRequestData;
  const { screenContainerPadding } = useResponsiveHelper();
  return (
    <View paddingLeft={screenContainerPadding} paddingRight={screenContainerPadding} height={"100%"} noWrap>
      <DialogHeader title={title} createdAt={createdAt} />
      <RequestByUser createdBy={createdBy} />
      <SignersList setSignersOrder={setSignersOrder} createdAt={createdAt} signers={signers} requestStatus={status} />
      {id && status !== ESignatureRequestStatus.CANCELED ? (
        <RequestDocument title={title} id={id} />
      ) : (
        <View flex={1} />
      )}
      <DialogActionPanel
        didPressDeleteButton={didPressDeleteButton}
        didPressCancelRequestButton={didPressCancelRequestButton}
        onBackdropClick={onBackdropClick}
        status={status || ESignatureRequestStatus.OUT_FOR_SIGNATURE}
      />
    </View>
  );
};

export default SignatureRequestPreview;
