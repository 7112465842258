import React, { useRef, useState } from "react";
import InputBase from "@material-ui/core/InputBase";
import { DeleteXIcon, SearchIcon } from "assets/icons";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import makeStyles from "./styles";
import { View } from "DLUI/view";
import clsx from "clsx";
import { CircularProgress } from "@material-ui/core";
import { Icon } from "DLUI/icon";
import type { ViewComponentProps } from "DLUI/view/view";
import { useKeyboard } from "../../../../hooks/useKeyboard";

type ComponentProps = {
  /**
   * @deprecated Use onInputChange instead
   */
  onChange?: (value: string) => void;
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  marginTop?: number;
  borderRadius?: number;
  requestInProgress?: boolean;
  hideSearchIcon?: boolean;
  showDeleteTextIcon?: boolean;
  onDeleteIconPress?: () => void;
  persistDeleteIcon?: boolean;
  defaultValue?: string;
  height?: number;
} & ViewComponentProps;

const SearchPanel: React.FC<ComponentProps> = ({
  onChange,
  placeholder,
  marginTop,
  borderRadius,
  requestInProgress,
  hideSearchIcon,
  onInputChange,
  defaultValue,
  persistDeleteIcon,
  showDeleteTextIcon,
  height = 40,
  fullWidth,
  width,
  onDeleteIconPress,
  ...restViewProps
}: ComponentProps) => {
  const [searchQuery, setSearchQuery] = useState<string>(defaultValue ?? "");
  const [searchInProgress, setSearchInProgress] = useState<boolean>(false);
  const { t } = useTranslation();
  const classes = makeStyles();
  const inputRef = useRef<any>();

  const handleSearchInputFocus = () => {
    if (inputRef.current) {
      inputRef.current?.focus?.();
    }
  };

  useKeyboard("f", handleSearchInputFocus, [inputRef.current], { isCtrlCombination: true });

  const _onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = event.target.value;

    setSearchQuery(value);
    onChange?.(value);
    onInputChange?.(event as React.ChangeEvent<HTMLInputElement>);
  };

  const _onDeleteIconPress = () => {
    setSearchQuery("");
    onChange?.("");
    onDeleteIconPress?.();
    onInputChange?.({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>);
  };

  const renderEndAdornment = () => {
    if (requestInProgress) {
      return (
        <CircularProgress
          style={{
            width: 20,
            height: 20,
            marginRight: 20,
            color: "#7A8699"
          }}
        />
      );
    }
    if (showDeleteTextIcon && (persistDeleteIcon || searchQuery.length > 0)) {
      // we want to always show the delete icon if persistDeleteIcon is true
      return (
        <span onClick={_onDeleteIconPress} style={{ cursor: "pointer" }}>
          <Icon Source={DeleteXIcon} size={12} marginRight={18} />
        </span>
      );
    }
    if (!hideSearchIcon) {
      return <Icon Source={SearchIcon} size={25} marginRight={15} />;
    }
  };

  return (
    <View
      height={height}
      alignItems={"flex-start"}
      marginTop={marginTop !== undefined ? marginTop : 20}
      {...restViewProps}
    >
      <div
        className={clsx([classes.inputWrapper])}
        style={{
          borderRadius: borderRadius ?? "0 25px 25px 0",
          width: fullWidth ? "100%" : width
        }}
      >
        <InputBase
          data-cy={"dialogSearchPanelInput"}
          type={"text"}
          classes={{
            input: classes.inputLabel
          }}
          inputRef={(ref) => (inputRef.current = ref)}
          className={classes.searchInput}
          placeholder={placeholder ? t(placeholder) : t(AppStrings.Tenants.Screen.SearchPlaceHolder)}
          inputProps={{
            "aria-label": t(AppStrings.Tenants.Screen.SearchPlaceHolder)
          }}
          onChange={_onChange}
          value={searchQuery}
        />
        {renderEndAdornment()}
      </div>
    </View>
  );
};

export default SearchPanel;
