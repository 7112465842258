import moment from "moment";

import { DataCy, ReportId, ReportPermission, TaskStatus, TaskType } from "@doorloop/dto";

import AppStrings from "locale/keys";
import { ownerPortalNavigation } from "../../../../../utils/ownerPortalNavigation";
import type { ReportScreenItemType } from "DLUI/report/screen/reportScreenItemType";

const didPressTasksByPriority = (): void => {
  ownerPortalNavigation.runTasksReport({
    groupBy: "priority"
  });
};

const didPressTasksByProperty = (): void => {
  ownerPortalNavigation.runTasksReport({
    groupBy: "property"
  });
};

const didPressTasksByStatus = (): void => {
  ownerPortalNavigation.runTasksReport({
    groupBy: "status"
  });
};

const didPressCompletedTasks = (): void => {
  ownerPortalNavigation.runTasksReport({
    filter_status: TaskStatus.COMPLETED
  });
};

const didPressOpenTasks = (): void => {
  ownerPortalNavigation.runTasksReport({
    filter_status: TaskStatus.IN_PROGRESS
  });
};

const didPressOverdueTasks = (): void => {
  ownerPortalNavigation.runTasksReport({
    filter_dueBefore_from: moment().format("YYYY-MM-DD").toString(),
    filter_dueBefore_to: moment().add("90", "days").format("YYYY-MM-DD").toString()
  });
};

const didPressTenantRequestReport = (): void => {
  ownerPortalNavigation.runTasksReport({
    filter_type: TaskType.TENANT_REQUEST
  });
};

export const TASKS_REPORT_ITEMS: ReportScreenItemType[] = [
  {
    description: AppStrings.Reports.ReportsScreen.CompletedTasks,
    onPress: didPressCompletedTasks,
    permission: ReportPermission.tasks,
    id: ReportId.COMPLETE_TASKS,
    dataCy: DataCy.reports.screenItems.tasks.completedTasks
  },
  {
    description: AppStrings.Reports.ReportsScreen.OpenTasks,
    onPress: didPressOpenTasks,
    permission: ReportPermission.tasks,
    id: ReportId.OPEN_TASKS,
    dataCy: DataCy.reports.screenItems.tasks.openTasks
  },
  {
    description: AppStrings.Reports.ReportsScreen.OverdueTasks,
    onPress: didPressOverdueTasks,
    permission: ReportPermission.tasks,
    id: ReportId.OVERDUE_TASKS,
    dataCy: DataCy.reports.screenItems.tasks.overdueTasks
  },
  {
    description: AppStrings.Reports.ReportsScreen.TasksByPriority,
    onPress: didPressTasksByPriority,
    permission: ReportPermission.tasks,
    id: ReportId.TASKS_BY_PRIORITY,
    dataCy: DataCy.reports.screenItems.tasks.tasksByPriority
  },
  {
    description: AppStrings.Reports.ReportsScreen.TasksByProperty,
    onPress: didPressTasksByProperty,
    permission: ReportPermission.tasks,
    id: ReportId.TASKS_BY_PROPERTY,
    dataCy: DataCy.reports.screenItems.tasks.tasksByProperty
  },
  {
    description: AppStrings.Reports.ReportsScreen.TasksByStatus,
    onPress: didPressTasksByStatus,
    permission: ReportPermission.tasks,
    id: ReportId.TASKS_BY_STATUS,
    dataCy: DataCy.reports.screenItems.tasks.tasksByStatus
  },
  {
    description: AppStrings.Common.TenantRequestsSettingItemTitle,
    onPress: didPressTenantRequestReport,
    permission: ReportPermission.tasks,
    id: ReportId.TENANT_REQUESTS,
    dataCy: DataCy.reports.screenItems.tasks.tenantRequests
  }
];
