import MomentUtils from "@date-io/moment";
import type { LeaseDto, TenantDto } from "@doorloop/dto";
import {
  ConversationLinkedToType,
  createValidator,
  DisplayLeaseDraftTenantDto,
  LinkedResourceDto,
  LinkedResourceType,
  MoveInTenantDto,
  SegmentEventTypes
} from "@doorloop/dto";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { filesApi } from "api/filesApi";
import { leasesApi, leasesMoveInTenantApi } from "api/leasesApi";
import { tenantsApi } from "api/tenantsApi";
import { AddSquareButton, SelectionButton } from "DLUI/button";
import type { MenuItem } from "DLUI/dialogs";
import { DialogSearchPanel, TenantSelectionView } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { LoadingDialog } from "DLUI/dialogs/components/loading";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import { FormikDatePicker, FormikSwitchButton, ValidationIndicator } from "DLUI/form";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, Formik, getIn } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import LeasesList from "screens/leases/leases/leasesList/leasesList";
import SelectableLeaseListItem from "screens/leases/leases/leasesList/selectableLeaseListItem";
import {
  getTenantFormMenuItems,
  initFormValues as newTenantInitFormValues,
  validateForm as newTenantFormikValidation,
  validateTenantForm
} from "screens/tenants";
import AnimatedContent from "../components/animatedContent";
import DialogFrame, { getDialogFrameDimension } from "../components/dialogFrame";
import CurrentTermsItem from "./currentTermsItem";
import NoLeaseSelectionView from "./noLeaseSelectionView";
import MakeStyle from "./styles";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { ArticleIdsEnum, HelpTypeEnum, VideoUrlsEnum } from "DLUI/screen/helpPanel/types";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { useBasePersonValidation } from "screens/vendors/useBasePersonValidation";
import { analyticsService } from "../../../../services/analyticsService";
import { useCurrentPhoneNumber } from "screens/communicationsCenter/shared/useCurrentPhoneNumber";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { phoneNumbersApi } from "api/phoneNumbersApi";
import { useUserType } from "../../../../hooks/useUserType";
import { useAnalyticsService } from "../../../../hooks/useAnalyticsService";

interface ComponentProps {
  didFinishOperation: (values: MoveInTenantDto) => void;
  onBackdropClick: () => void;
  refreshEvent?: () => void;
  dialogTitle: string;
}

let formikGlobalRef: FormikProps<MoveInTenantDto> | null = null;
const DialogHeight = getDialogFrameDimension("height", 880);

export const getFormikRef = () => formikGlobalRef;

const helpObj: HelpPanelProps = {
  description: AppStrings.Tenants.MoveTenants.HelpPanel.In.Description,
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Tenants.MoveTenants.HelpPanel.In.Move,
      articleId: ArticleIdsEnum.MOVE_IN_TENANT
    },
    {
      type: HelpTypeEnum.WATCH_VIDEO,
      topic: AppStrings.Common.WatchExample,
      href: VideoUrlsEnum.MOVE_IN_TENANT,
      dialogTitle: AppStrings.Tenants.MoveTenants.HelpPanel.In.DialogTitle
    }
  ]
};

const validateForm = createValidator(MoveInTenantDto);

const MoveInTenants: React.FC<ComponentProps> = ({
  didFinishOperation,
  refreshEvent,
  onBackdropClick,
  dialogTitle
}: ComponentProps) => {
  const { dispatchAnalytics } = useAnalyticsService();
  const { isHOAUser } = useUserType();
  const { t } = useTranslation();
  const location = useLocation<{ leaseData?: LeaseDto }>();
  const classes = MakeStyle();
  const [renderTenantListPicker, setRenderTenantListPicker] = useState<boolean>(true);
  const { personValidateBase } = useBasePersonValidation();

  const [leaseData, setLeaseData] = useState<LeaseDto | undefined>(location.state?.leaseData);

  const [selectLeaseFilterObj, setSelectLeaseFilterObj] = useState({
    filter_text: ""
  });
  const [currentMenuIndex, setCurrentMenuIndex] = useState<number>();
  const [viewIndex, setViewIndex] = useState(leaseData ? 2 : 1);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);

  const [moveInTenantErrorText, setMoveInTenantErrorText] = useState<string>(
    t(AppStrings.Tenants.MoveTenants.MoveInCreateErrorText)
  );

  const [currentTenantsSelection, setCurrentTenantsSelection] = useState<DisplayLeaseDraftTenantDto[]>([]);

  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);

  const [currentMenuItemTitle, setCurrentMenuItemTitle] = useState<string>("");

  const [menuTitle, setMenuTitle] = useState<string>("");

  const [pendingFile, setPendingFile] = useState<File | undefined>();

  const [, setCurrentLeaseId] = useState<string | undefined>();

  const [onErrorReturnIndex, setOnErrorReturnIndex] = useState<number>(0);

  const [currentSavingTenantData, setCurrentSavingTenantData] = useState<TenantDto>();

  const { isCurrentPhoneNumberActive } = useCurrentPhoneNumber();

  useEffect(() => {
    if (isCurrentPhoneNumberActive) {
      formikGlobalRef?.setFieldValue("sendWelcomeSms", true);
    }
  }, [isCurrentPhoneNumberActive]);

  useEffect(
    () => () => {
      formikGlobalRef = null;
    },
    []
  );

  useEffect(() => {
    if (viewIndex === 0) {
      setMenuTitle(dialogTitle);
      return;
    }
    if (viewIndex === 1) {
      setMenuTitle(AppStrings.Leases.LeaseCharge.SelectLease);
      return;
    }
    if (viewIndex === 2) {
      setMenuTitle(AppStrings.Tenants.MoveTenants.SelectTenant);
      return;
    }
    if (viewIndex === 3) {
      setMenuTitle(AppStrings.Tenants.Screen.AddNewTenant);
    }
  }, [viewIndex]);

  const initFormValues = (): MoveInTenantDto => {
    if (formikGlobalRef && formikGlobalRef.values) {
      return formikGlobalRef.values;
    }
    const moveInTenantDto = new MoveInTenantDto();
    if (leaseData) {
      moveInTenantDto.leaseId = leaseData.id;
      moveInTenantDto.movedInAt = moment().format("YYYY-MM-DD").toString();
      moveInTenantDto.portalEnabled = true;
      moveInTenantDto.tenantIds = [];
      setCurrentLeaseId(moveInTenantDto.leaseId);
    }
    return moveInTenantDto;
  };

  const isValidForm = async (formikRef: FormikProps<MoveInTenantDto>) => {
    formikRef.setFieldTouched("leaseId");
    formikRef.setFieldTouched("movedInAt");
    formikRef.setFieldTouched("tenantIds");
    formikRef.setFieldTouched("portalEnabled");
    formikRef.setFieldTouched("leaseId");

    const errors = (await formikRef.validateForm()) as Record<string, string>;
    return _.isEmpty(errors);
  };

  const sendWelcomeSms = (tenants: string[]) => {
    tenants.forEach((tenantId) => {
      dispatchAnalytics(
        "communications_center_welcome_sms",
        { stakeholderType: ConversationLinkedToType.TENANT },
        { trackEventInIntercom: true }
      );
      phoneNumbersApi.sendWelcomeSms({ type: ConversationLinkedToType.TENANT, id: tenantId });
    });
  };

  const moveInTenant = async () => {
    if (formikGlobalRef) {
      const response = await leasesMoveInTenantApi.create(formikGlobalRef.values);

      if (!response.status) {
        setMoveInTenantErrorText(response.message);
        setLoadingDialogState(DialogState.Error);
      } else {
        formikGlobalRef.values.sendWelcomeSms && sendWelcomeSms(formikGlobalRef.values.tenantIds || []);

        if (formikGlobalRef.values.leaseId) {
          await filesApi
            .uploadFiles(attachments, formikGlobalRef.values.leaseId, LinkedResourceType.Lease)
            .catch((error: string) => {
              setLoadingDialogState(DialogState.Error);
              setMoveInTenantErrorText(error);
            });
        }
        setLoadingDialogState(DialogState.Success);
        await didFinishMoveTenant();
        formikGlobalRef = null;
      }
    }
  };

  const didPressSaveButton = async () => {
    if (formikGlobalRef) {
      const isValid = await isValidForm(formikGlobalRef);
      if (isValid) {
        setViewIndex(4);
        setLoadingDialogState(DialogState.Show);
        await moveInTenant();
      }
    }
  };

  const uploadFile = async (id: string, onClose: () => void) => {
    setLoadingDialogState(DialogState.Success);

    if (pendingFile) {
      const LinkedResource = new LinkedResourceDto(id, LinkedResourceType.Tenant);
      const response = await tenantsApi.uploadPicture(pendingFile, LinkedResource, id);

      if (response && response.status) {
        setLoadingDialogState(DialogState.Success);
        onClose();
      } else {
        setLoadingDialogState(DialogState.Error);
        setMoveInTenantErrorText(response.message || t(AppStrings.Common.GeneralError));
      }
    }
  };

  const didCreateNewTenant = (values: TenantDto) => {
    const newTenant = new DisplayLeaseDraftTenantDto();

    newTenant.id = values.id;
    newTenant.name = values.name;
    newTenant.pictureUrl = values.pictureUrl;
    currentTenantsSelection.push(newTenant);

    setCurrentTenantsSelection(currentTenantsSelection);
    setTenantsIds(currentTenantsSelection);

    setTimeout(() => {
      setViewIndex(0);
    }, 300);
  };

  const createNewTenant = async (formikRef: FormikProps<TenantDto>) => {
    if (formikRef) {
      setOnErrorReturnIndex(3);
      setCurrentSavingTenantData(formikRef.values);
      const { message, data, status } = await tenantsApi.create(formikRef.values);

      if (status && data) {
        setCurrentSavingTenantData(undefined);
        if (pendingFile && data.id) {
          await uploadFile(data.id, () => didCreateNewTenant(data));
        } else {
          setLoadingDialogState(DialogState.Success);
          didCreateNewTenant(data);
        }
      } else {
        setMoveInTenantErrorText(message || t(AppStrings.Common.GeneralError));
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const didPressSaveNewTenant = async (formikRef: FormikProps<TenantDto>, sectionItems: MenuItem[]) => {
    if (formikRef) {
      const validationResult = await personValidateBase(formikRef, validateTenantForm, sectionItems);

      if (validationResult.isValid) {
        setLoadingDialogState(DialogState.Show);
        setViewIndex(4);
        await createNewTenant(formikRef);
      } else if (validationResult.errorStepIndex !== undefined) {
        setCurrentMenuIndex(validationResult.errorStepIndex);
      }
    }
  };
  const didPressCancelNewTenant = () => {
    setViewIndex(1);
  };

  const renderActionPanelButtons = () => {
    if (viewIndex === 1) {
      return <div />;
    }

    return (
      <FormActionButtons
        propsSubButton={{ onClick: onBackdropClick }}
        propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
      />
    );
  };

  const didPressDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setRenderTenantListPicker(false);
    setViewIndex(onErrorReturnIndex || 0);
  };

  const didFinishMoveTenant = async () => {
    if (formikGlobalRef) {
      await leasesApi.getDictionary(true);
      analyticsService.track(
        SegmentEventTypes.MOVE_IN_TENANT_SAVED,
        {
          numOfCurrentTenants: leaseData?.id ? leasesApi.getItemFromDictionary(leaseData.id).tenants?.length : 0
        },
        { trackEventInIntercom: true }
      );
      didFinishOperation(formikGlobalRef.values);
      if (refreshEvent) {
        refreshEvent();
      }
      setLoadingDialogState(DialogState.Hidden);
    }
  };

  const didPressSelectTenant = () => {
    setViewIndex(2);
    setTimeout(() => {
      setRenderTenantListPicker(true);
    }, 300);
  };

  const didPressDeleteButton = (itemIndex: number) => {
    if (formikGlobalRef) {
      currentTenantsSelection.splice(itemIndex, 1);
      setCurrentTenantsSelection(currentTenantsSelection);
      setTenantsIds(currentTenantsSelection);
    }
  };

  const renderSelectionList = () => {
    let listItems: JSX.Element[] | undefined;

    if (currentTenantsSelection) {
      listItems = currentTenantsSelection.map((currentItem, index) => (
        <div key={"SB" + index}>
          <SelectionButton
            didPressDeleteButton={didPressDeleteButton}
            name={currentItem.name}
            pictureUrl={currentItem.pictureUrl}
            index={index}
          />
        </div>
      ));
    }
    return (
      <View marginTop={10} justifyContent={"center"} flexDirection={"row"}>
        {listItems}
        <AddSquareButton
          label={AppStrings.Leases.NewLease.LeaseTenants.AddTenant}
          onAddButtonPress={didPressSelectTenant}
        />
      </View>
    );
  };

  const onFileReceived = (files: FileListItemProps[]) => {
    setAttachments(files);
  };

  const renderAttachments = () => (
    <View justifyContent={"flex-end"} width={"100%"} flex={1} paddingLeft={20} paddingRight={20} marginBottom={20}>
      <FormAttachments onFileReceived={onFileReceived} files={attachments} />
    </View>
  );

  const renderValidationMessage = () => {
    if (formikGlobalRef) {
      let errorText = getIn(formikGlobalRef.errors, "tenantIds") || "";
      const touchedVal = getIn(formikGlobalRef.touched, "tenantIds");

      const hasError = touchedVal && errorText !== undefined;
      errorText = errorText && isHOAUser ? errorText.replaceAll("a tenant", "an owner") : errorText;

      return (
        <View alignItems={"center"} justifyContent={"center"}>
          <ValidationIndicator
            shouldShow={hasError || false}
            maxWidth={420}
            displayText={errorText}
            marginTop={"20px"}
          />
        </View>
      );
    }
  };

  const didPressSelectLease = () => {
    setViewIndex(1);
  };

  const renderForm = () => {
    const formInitialValues = initFormValues();
    return (
      <Formik initialValues={formInitialValues} onSubmit={() => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;

          return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <View justifyContent={"center"} alignItems={"center"} flex={1} width={"100%"}>
                <View height={"100%"} paddingLeft={20} paddingRight={20}>
                  {leaseData ? (
                    <CurrentTermsItem leaseData={leaseData} />
                  ) : (
                    <NoLeaseSelectionView didPressSelectLease={didPressSelectLease} />
                  )}

                  <View marginTop={20} justifyContent={"center"} alignItems={"center"}>
                    <Text value={AppStrings.Tenants.MoveTenants.WhoIsMovingIn} fontSize={20} color={"black"} />
                  </View>
                  {renderSelectionList()}
                  {renderValidationMessage()}
                  <View justifyContent={"center"} alignItems={"center"} marginTop={20} gap={16}>
                    <Grid className={classes.gridPadding} container justifyContent={"center"}>
                      <Grid item xs={12} sm={4}>
                        <FastField
                          component={FormikDatePicker}
                          uniqueKey={"movedInAt"}
                          label={AppStrings.Leases.DraftLease.MoveInDate}
                          name={"movedInAt"}
                          noMargin
                          required
                        />
                      </Grid>
                    </Grid>

                    <Grid className={classes.gridPadding} container justifyContent={"center"}>
                      <Grid item xs={12} sm={4}>
                        <FastField
                          component={FormikSwitchButton}
                          name={"portalEnabled"}
                          label={AppStrings.Tenants.MoveTenants.InviteToPortal}
                        />
                      </Grid>
                    </Grid>

                    <Grid className={classes.gridPadding} container justifyContent={"center"}>
                      <Grid item xs={12} sm={4}>
                        <FastFieldSafe
                          component={FormikSwitchButton}
                          name={"sendWelcomeSms"}
                          label={AppStrings.Common.SendWelcomeSmsDescription}
                          disabled={!isCurrentPhoneNumberActive}
                        />
                      </Grid>
                    </Grid>
                  </View>

                  {renderAttachments()}
                </View>
              </View>
            </MuiPickersUtilsProvider>
          );
        }}
      </Formik>
    );
  };

  const setTenantsIds = (tenantSelection: DisplayLeaseDraftTenantDto[]) => {
    if (formikGlobalRef) {
      const formikIdsArray: string[] = [];
      tenantSelection.forEach((currentTenant) => {
        formikIdsArray.push(currentTenant.id!);
      });
      formikGlobalRef.setFieldValue("tenantIds", formikIdsArray);
    }
  };

  const didSelectTenantItem = (selectedItem: TenantDto) => {
    if (selectedItem) {
      const newTenant = new DisplayLeaseDraftTenantDto();
      const previousSelectionIndex = currentTenantsSelection.findIndex((currentItem) => {
        if (selectedItem.id) {
          return currentItem.id === selectedItem.id;
        }
        return false;
      });
      if (previousSelectionIndex > -1) {
        setRenderTenantListPicker(false);
        setViewIndex(0);
        return;
      }
      newTenant.id = selectedItem.id;
      newTenant.name = selectedItem.name;
      newTenant.pictureUrl = selectedItem.pictureUrl;
      currentTenantsSelection.push(newTenant);

      setCurrentTenantsSelection(currentTenantsSelection);
      setTenantsIds(currentTenantsSelection);

      setTimeout(() => {
        setRenderTenantListPicker(false);
        setViewIndex(0);
      }, 300);
    }
  };

  const onMenuItemSelection = (menuItemTitle: string) => {
    setCurrentMenuItemTitle(menuItemTitle);
  };

  const onFileReceive = (receivedFile: File) => {
    setPendingFile(receivedFile);
  };

  const onFileDelete = () => {
    setPendingFile(undefined);
  };

  const didPressAddNewTenant = () => {
    setViewIndex(3);
  };

  const renderAddNewTenantActionPanelButtons = (formikRef: FormikProps<any>, sectionItems: MenuItem[]) => (
    <FormActionButtons
      propsSubButton={{ onClick: didPressCancelNewTenant }}
      propsMainButton={{
        type: "cta",
        props: { onClick: async () => await didPressSaveNewTenant(formikRef, sectionItems) }
      }}
    />
  );

  const renderLeaseSelectionList = () => {
    const didChangeSearchQuery = (value: string) => {
      setSelectLeaseFilterObj({
        filter_text: value
      });
    };

    const onListItemPress = (leaseId: string, selectedLease?: LeaseDto) => {
      setViewIndex(0);
      setCurrentLeaseId(leaseId);
      setLeaseData(selectedLease);
      formikGlobalRef !== null && formikGlobalRef.setFieldValue("leaseId", leaseId);
    };

    return (
      <View>
        <DialogSearchPanel placeholder={AppStrings.Leases.Screen.SearchPlaceHolder} onChange={didChangeSearchQuery} />
        <View paddingLeft={20} paddingRight={20}>
          <div id={"selectableLeaseListItem"} style={{ width: "100%" }}>
            <LeasesList
              ListItem={SelectableLeaseListItem}
              filterObj={selectLeaseFilterObj}
              didPressListItem={onListItemPress}
              stickyHeaderId={"selectableLeaseListItem"}
              selectableItem
            />
          </div>
        </View>
      </View>
    );
  };

  const renderView = ({ index }: any) => {
    if (index === 0) {
      return renderForm();
    }
    if (index === 1) {
      return renderLeaseSelectionList();
    }
    if (index === 2 && Boolean(renderTenantListPicker)) {
      return (
        <TenantSelectionView
          showAddNewTenant={true}
          didSelectTenantItem={didSelectTenantItem}
          height={DialogHeight - 80}
          didPressAddNewTenant={didPressAddNewTenant}
        />
      );
    }
    if (index === 3) {
      const sectionItems = getTenantFormMenuItems(true);
      if (sectionItems) {
        return (
          <AnimatedContent
            formikInitialValues={currentSavingTenantData ? () => currentSavingTenantData : newTenantInitFormValues}
            sectionItems={sectionItems}
            formikValidation={newTenantFormikValidation}
            dialogHeight={DialogHeight}
            selectedMenuIndex={currentMenuIndex}
            renderActionPanelButtons={(formikRef) => renderAddNewTenantActionPanelButtons(formikRef, sectionItems)}
            onMenuItemSelection={onMenuItemSelection}
            onFileReceive={onFileReceive}
            onFileDelete={onFileDelete}
          />
        );
      }
      return <div />;
    }
    if (index === 4) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            errorText={moveInTenantErrorText}
            hideRetryButton
            didPressDismissButton={didPressDismissButton}
          />
        </View>
      );
    }
    return <div />;
  };

  const _onBackdropClick = () => {
    if (viewIndex === 1) {
      setViewIndex(0);
      setRenderTenantListPicker(false);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      return "sectionTitleFrame";
    }
    if (viewIndex === 1) {
      return "topPanel";
    }
    if (viewIndex === 2) {
      return "topPanel";
    }
    if (viewIndex === 3) {
      return "sideMenu";
    }
    if (viewIndex === 4) {
      return "contentOnly";
    }
    return "topPanel";
  }, [viewIndex]);

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={menuTitle}
      width={962}
      height={DialogHeight}
      renderView={renderView}
      numViews={5}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={frameType}
      sectionTitle={currentMenuItemTitle}
      helpPanel={helpObj}
    />
  );
};

export default MoveInTenants;
