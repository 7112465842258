import { Icon } from "DLUI/icon";
import { Link } from "DLUI/link";
import Text from "DLUI/text";
import type { TextComponentProps } from "DLUI/text/text";
import { View } from "DLUI/view";
import type { SVGIconComponent } from "assets/.";
import { BlueRefreshIcon } from "assets/.";
import React from "react";

export type DialogTitleActionButtonProps = {
  onClick?: () => void;
  icon?: SVGIconComponent;
  text?: TextComponentProps["value"];
} & Omit<TextComponentProps, "value" | "onClick">;

export const DialogTitleActionButton: React.FC<DialogTitleActionButtonProps> = ({
  icon,
  onClick,
  text,
  ...restProps
}) => (
  <Link onClick={onClick} hoverColor={"lightBlue"}>
    <View flexDirection={"row"} alignItems="center">
      <Icon Source={icon ?? BlueRefreshIcon} height={18} marginRight={6} />
      <Text fontSize={14} color={"lightBlue"} value={text} {...restProps} />
    </View>
  </Link>
);
