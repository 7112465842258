import { RestApiBase } from "./restApiBase";
import type { GetAllLeasePaymentsQuery, LeasePaymentDto } from "@doorloop/dto";
import { DepositDto, DepositLinkedTransactionDto, ServerRoutes } from "@doorloop/dto";
import { depositsApi } from "./depositsApi";
import { generateDoorloopDefaultFileName, triggerDownload } from "api/utils/apiUtils";
import type { ApiResult } from "api/apiResult";
import { apiHelper } from "api/apiHelper";
import type { EmailIdAndSendToQuery } from "@doorloop/dto/dist/journalEntries/leasePayments/emailIdAndSendTo.query";
import AppStrings from "locale/keys";

export class LeasePaymentsApi extends RestApiBase<LeasePaymentDto, GetAllLeasePaymentsQuery> {
  constructor() {
    super(ServerRoutes.LEASE_PAYMENTS);
    this.restRoute = ServerRoutes.LEASE_PAYMENTS;
  }

  async downloadPaymentReceiptPdf(
    id: string,
    fileName = generateDoorloopDefaultFileName(id, "pdf")
  ): Promise<ApiResult<{}>> {
    const response = await apiHelper.axiosGet(
      ServerRoutes.LEASE_PAYMENTS_GET_DOWNLOAD_RECEIPT_PDF.replace(":id", id),
      {},
      {
        responseType: "blob"
      }
    );

    triggerDownload(response.data, fileName);
    return response;
  }

  async postSendReceiptEmail(data: EmailIdAndSendToQuery): Promise<ApiResult<{}>> {
    return await apiHelper.axiosPost({
      url: ServerRoutes.LEASE_PAYMENTS_POST_SEND_RECEIPT,
      data,
      toasts: {
        severity: "success",
        translationKey: AppStrings.Toasts.custom.leasePayments.receiptSent.POST
      },
      shouldShowErrorToast: true
    });
  }

  createAndDeposit = async (leasePaymentDto: LeasePaymentDto) => {
    try {
      const response = await this.create(leasePaymentDto);
      if (response && response.status) {
        const depositDto = new DepositDto();
        depositDto.depositToAccount = leasePaymentDto.depositToAccount;
        depositDto.linkedTransactions = [
          new DepositLinkedTransactionDto({
            linkedTransaction: response.data?.["id"]
          })
        ];
        depositDto.date = leasePaymentDto.date;
        await depositsApi.create(depositDto);
      }
      return response;
    } catch (e) {
      throw e;
    }
  };
}

export const leasePaymentsApi = new LeasePaymentsApi();
