"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilePurpose = void 0;
var FilePurpose;
(function (FilePurpose) {
    /**
     * Creates a file and adds it to the files tab of the object
     */
    FilePurpose["FILE"] = "file";
    FilePurpose["PROPERTY_PICTURE"] = "property-picture";
    FilePurpose["TENANT_PICTURE"] = "tenant-picture";
    FilePurpose["VENDOR_PICTURE"] = "vendor-picture";
    FilePurpose["OWNER_PICTURE"] = "owner-picture";
    FilePurpose["USER_PICTURE"] = "user-picture";
    FilePurpose["UNIT_PICTURE"] = "unit-picture";
    FilePurpose["COMPANY_LOGO"] = "company-logo";
    FilePurpose["COMPANY_PORTAL_LOGO"] = "company-portal-logo";
    FilePurpose["COMPANY_PORTAL_BACKGROUND"] = "company-portal-background";
    FilePurpose["PROPERTY_PORTAL_LOGO"] = "property-portal-logo";
    FilePurpose["PROPERTY_PORTAL_BACKGROUND"] = "property-portal-background";
})(FilePurpose || (exports.FilePurpose = FilePurpose = {}));
