import { makeStyles } from "@material-ui/core";
import * as Filters from "@/components/common/filters";
import clsx from "clsx";
import { useContextSelector } from "use-context-selector";
import { FilterPanelContext } from "../context";
import { useInlineFilterOptions } from "../hooks/useInlineFilterOptions";
import { DataCy } from "@doorloop/dto";

const useStyles = makeStyles({
  root: {
    display: "flex",
    gap: "6px"
  }
});

export function InlineFilters() {
  const classes = useStyles();
  const filterOptions = useContextSelector(FilterPanelContext, (context) => context.filterOptions);
  const inlineFilterOptions = useInlineFilterOptions(filterOptions);
  if (inlineFilterOptions.length === 0) {
    return null;
  }

  return (
    <Filters.Root filterOptions={filterOptions}>
      <ul className={clsx("list-reset", classes.root)} data-cy={DataCy.DLUI.filters.filterPanelInlineFilters}>
        {inlineFilterOptions.map((filterOption, index) => (
          <li key={index}>
            <Filters.Input filterOption={filterOption} />
          </li>
        ))}
      </ul>
    </Filters.Root>
  );
}
