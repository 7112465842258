import { PropertyType } from "@doorloop/dto";

export default {
  screen: {
    property: "Property",
    emptydataViewInstructionsText: "No Properties Found",
    emptydataAddNew: "Add your first property",
    newPropertyStep1label: "Type",
    newPropertyStep2label: "Address",
    newPropertyStep3label: "Units",
    newPropertyStep4label: "Bank Accounts",
    newPropertyStep5label: "Reserve Funds",
    newPropertyStep6label: "Ownership",
    choosePropertyTypelabel: "Let's add your property",
    whatKindOfProperty: "Which kind of property do you manage?",
    maintainReserveFunds: "Would you like to maintain a minimum balance (“Reserve”) for this property?",
    whoOwnsProperty: "Who owns this property?",
    setPropertyReserve: "Set property reserve",
    dontSetPropertyReserve: "Don't set property reserve",
    propertyOwnedByMe: "Owned by me",
    propertyOwnedBySomeoneElse: "Owned by someone else",
    choosePropertyOwnersText: "Add one or more owners and set their ownership percentage",
    choosePropertySubTypelabel: "Which type best describes your property?",
    loadingProperties: "Loading Properties",
    creatingNewProperty: "Please wait while we create your property...",
    creatingNewPropertyFailed:
      "Failed to create the property. Make sure you are connected to the internet and try again.",
    creatingNewPropertySuccess: "Your property was successfully added!",
    searchPaneltitle: "Manage your properties",
    searchPlaceHolder: "Search properties",
    addNewProperty: "New Property",
    vacantUnits: "Vacant Units",
    listedUnits: "Listed Units",
    deleteFileConfirmationText: "Are you sure you want to delete the property?",
    deleteFileLoadingText: "Deleting property",
    deleteFileSuccessText: "The property was deleted successfully",
    makeInactiveFileConfirmationText: "Are you sure you want to make the property inactive?",
    makeActiveFileConfirmationText: "Are you sure you want to activate the property?",
    invalidUnitsName: "units names must be unique. check the units names and try again",
    multiFamilyInfo:
      'For listing purposes, we recommend classifying each unit in a triplex or duplex as a separate property from the type "Condo"'
  },
  propertiesListItem: {
    unitslabel: "Units",
    unitlabel: "Unit",
    occupiedLabel: "Occupied",
    evictionLabel: "Eviction"
  },
  type: {
    residential: "Residential",
    commercial: "Commercial"
  },
  propertyTypeDescription: {
    [PropertyType.RESIDENTIAL_SINGLE_FAMILY]: {
      one: "Standalone residential structure",
      two: "Intended for one family"
    },
    [PropertyType.RESIDENTIAL_MULTI_FAMILY]: {
      one: "Residential building with multiple units",
      two: "Examples include duplexes, triplexes, and apartment buildings"
    },
    [PropertyType.RESIDENTIAL_CONDO]: {
      one: "Individual unit within a larger building or community",
      two: "Shared common areas with other units"
    },
    [PropertyType.RESIDENTIAL_TOWNHOME]: {
      one: "Multi-floor home sharing one or more walls with adjacent properties",
      two: "Has its own entrance"
    },
    [PropertyType.RESIDENTIAL_OTHER]: {
      one: "If none of the available categories fit your property, please select this option"
    },
    [PropertyType.COMMERCIAL_INDUSTRIAL]: {
      one: "Large-scale property for manufacturing, warehousing, or distribution",
      two: "Features include high ceilings, heavy-duty flooring, and loading docks"
    },
    [PropertyType.COMMERCIAL_OFFICE]: {
      one: "Designed for professional business operations",
      two: "Includes private offices, conference rooms, and administrative areas"
    },
    [PropertyType.COMMERCIAL_RETAIL]: {
      one: "Space designed for selling goods or services directly to consumers",
      two: "Features high-visibility storefronts, open floor plans for displays, and customer-friendly layouts"
    },
    [PropertyType.COMMERCIAL_SHOPPING_CENTER]: {
      one: "Complex of retail stores, restaurants, and entertainment venues",
      two: "Anchored by one or more major retailers or businesses"
    },
    [PropertyType.COMMERCIAL_STORAGE]: {
      one: "A facility providing secure space for storing goods or belongings",
      two: "Offers various unit sizes, often with climate control options"
    },
    [PropertyType.COMMERCIAL_PARKING]: {
      one: "Dedicated space for vehicles, either as open lots or structured parking garages",
      two: "Equipped with lighting, security measures, and possibly amenities like EV charging stations"
    },
    [PropertyType.COMMERCIAL_OTHER]: {
      one: "If none of the available categories fit your property, please select this option"
    },
    pleaseAddPropertyType: "Please add property type"
  },
  listHeader: {
    property: "Property",
    type: "Type",
    status: "Status",
    activeUnits: "Active Units",
    cashBasis: "Cash basis",
    accrualBasis: "Accrual basis",
    flatFee: "Flat fee",
    minimumFee: "Minimum fee",
    payoutAccounts: "Payout accounts"
  },
  availabilityForApplications: {
    available: "Available",
    unavailable: "Not Available"
  },
  status: {
    active: "Active",
    inactive: "Inactive"
  },
  subType: {
    industrial: "Industrial",
    office: "Office",
    retail: "Retail",
    shoppingCenter: "Shopping Center",
    storage: "Storage",
    parking: "Parking",
    singleFamily: "Single-Family",
    multiFamily: "Multi-Family",
    condo: "Condo",
    other: "Other",
    townhome: "Townhome",
    coWorking: "Co Working"
  },
  address: {
    titleText: "Property address",
    instructionsText: "What's the property address?",
    useThisAddressAnyway: "Use this address anyway",
    streetAddress: "Street Address",
    acceptInvalidAddressWarningTitle: "We couldn't verify the address",
    acceptInvalidAddressWarningDescription:
      "Please review the details or select one of the suggested options. Without verification, certain features may become unavailable.",
    labels: {
      street1: "Street 1",
      street2: "Street 2",
      city: "City",
      state: "State",
      country: "Country",
      zipCode: "Zip code"
    }
  },
  units: {
    instructionsText: "Add all your units below.",
    addUnitsText: "Let's add the units for the property",
    addUnitButtonLabel: "Add Another Unit"
  },
  bankAccounts: {
    title: "Property Bank Accounts",
    subtitle: "Which bank accounts should be used for this property?",
    companyDefault: "Use company default account",
    specify: "Specify bank account",
    chooseAccount: "Which account should we use as the main bank account for this property?",
    changeExplainer: "You can always change your company default accounts in your ",
    settings: "company settings",
    notificationTitleDefault: "Why do I need to select a bank account for this property?",
    notificationBodyDefault:
      "Each property must be associated with a bank account." +
      " This should be the bank account you will be depositing rent payments into and paying expenses from.",
    notificationTitleSpecify: "Why do I need to select a bank account for this property?",
    notificationBodySpecify:
      "Each property must be associated with a bank account. " +
      "This should be the bank account you will be depositing rent payments into and paying expenses from.",
    securityDeposit: "Security Deposits Account",
    doYouUseDifferentDepositAccounts: "Do you use a different account for security deposits?",
    differentSecurityDeposit: "I use a different account for security deposits",
    sameSecurityDeposit: "I use the same account for security deposits",
    defaultAccountStatus: "Default Account"
  },
  settings: {
    addressDescriptionTitle: "Property primary address settings",
    addressDescriptionSubTitle: "Press to edit your property address details",
    typeDescriptionTitle: "Property type settings",
    typeDescriptionSubTitle: "Edit the property name, address, and type.",
    ownersDescriptionTitle: "Property owner settings",
    ownersDescriptionSubTitle: "Update the owners for this property.",
    managerTitle: "Manager",
    managerDescriptionTitle: "Property managers settings",
    managerDescriptionSubTitle: "Select your property managers.",
    bankAccountTitle: "Bank Account",
    bankAccountsTitle: "Bank Accounts",
    bankAccountsDescriptionTitle: "Property bank accounts settings",
    bankAccountsDescriptionSubTitle: "Select the property bank accounts.",
    reserveFundsTitle: "Reserve Funds",
    reserveFundsDescriptionTitle: "Property reserve funds settings",
    reserveFundsDescriptionSubTitle: "Edit the amount of reserve funds.",
    managementFeesTitle: "Management Fees",
    managementFeesDescriptionTitle: "Property management fees settings",
    managementFeesDescriptionSubTitle: "Set the late fees for these leases.",
    lateFeesTitle: "Late Fees",
    convenienceFees: "Convenience Fees",
    convenienceFeesDescriptionCompanySubTitle: "Set extra convenience fees associated with tenant payments.",
    lateFeesDescriptionTitle: "Property management late fees",
    lateFeesDescriptionSubTitle: "Edit the late fees for your leases.",
    residentPortalDescriptionTitle: "Resident portal access settings",
    residentPortalDescriptionSubTitle: "Edit the tenant portal settings.",
    rentersInsuranceDescriptionTitle: "renters insurance settings",
    rentersInsuranceDescriptionSubTitle: "Set renters insurance as a requirement for this lease.",
    paymentsDescriptionTitle: "Property payment settings",
    paymentsDescriptionSubTitle: "Choose how to allocate payments.",
    rentalApplicationsTitle: "Rental Applications",
    rentalApplicationsDescriptionTitle: "Rental applications settings",
    rentalApplicationsDescriptionSubTitle: "Edit the property's rental applications",
    rentalApplicationsDescriptionCompanySubTitle: "Edit rental applications settings",
    propertySettings: "Property Settings",
    property: "Property",
    detailedDescriptionOfThisProperty: "Detailed description of this property",
    editProperty: "Edit Property",
    propertyName: "Property Name",
    propertyAddress: "Property Address",
    propertyType: "Property Type",
    propertyBankAccounts: "Property Bank Accounts",
    propertyBankAccountsDescription:
      "You can set different bank accounts for each property as needed, or use your default accounts.",
    useCompanyDefaultBankAccounts: "Use company default bank accounts",
    specifyBankAccountForProperty: "Specify a bank account for this property",
    whichOperatingAccount: "Which account should we use as the operating bank account?",
    whichEscrowAccount: "Which account should we use as the escrow bank account for lease deposits?",
    whichEscrowDescription: "(Can be the same as the operating bank account)",
    operatingAccount: "Operating Account",
    escrowAccount: "Escrow Account",
    propertyReserveFunds: "Property Reserve Funds",
    propertyReserveFundsDescription:
      "Reserve funds will make sure there is always enough money in the bank. It is used to calculate the available cash for this property.",
    reserveFundsAmountDescription: "How much money should be in your reserve funds?",
    reserveFundsAmount: "Reserve Funds Amount",
    whatIsReserveFundsToolTipTitle: "What is a property reserve?",
    whatIsPropertyOwnershipToolTipTitle: "What is property ownership?",
    whatIsPropertyOwnershipToolTipDescription:
      "If you own this property, you can skip this step. If you manage properties for 3rd parties, adding them as owners will automate your distributions and 1099 tax forms.",
    whatIsReserveFundsToolTipDescription:
      "Setting a property reserve tells DoorLoop to make sure there are always sufficient funds in the bank to cover expenses when making bill payments or distributing funds to owners.",
    propertyManagementFees: "Property Management Fees",
    propertyManagementFeesDescription:
      "If you manage this property for someone else, you can set your management fee structure.",
    useCompanyDefaultManagementFees: "Use the default management fee structure",
    specifyManagementFeesForProperty: "Use a custom management fee for this property",
    propertyLateFees: "Property Late Fees",
    propertyLateFeesDescription:
      "If you manage this property on behalf of a landlord, you can set up your late fee structure or use the company default setting",
    useCompanyDefaultLateFees: "Use company default late fee structure",
    usePropertyDefaultLateFees: "Use property default late fee structure",
    specifyLateFeesForProperty: "Specify a late fee structure for this property",
    propertyResidentPortal: "Property Resident Portal",
    propertyResidentPortalDescription:
      "Give your tenants access to the resident portal where they can make payments and create maintenance requests.",
    useCompanyDefaultResidentPortal: "Use company default resident portal options",
    specifyResidentPortalForProperty: "Select the resident portal options for this property",
    allowOnlinePayments: "Allow tenants to make online payments",
    allowOnlinePaymentsLessThenBalance: "Allow tenants to make online payments for less than the outstanding balance",
    allowMaintenanceRequests: "Allow tenants to submit maintenance requests",
    allowRenewalOfferResponse: "Allow tenants to respond to a renewal offer or let you know they are not renewing",
    paymentInstructionsDescription: "Do you have specific payment instructions to include on the lease statement?",
    paymentInstructionsLabel: "For example, send payment to Management Company...",
    allowResidentPortalAccess: "Allow Resident Portal Access",
    propertyRentersInsurance: "Property Renters Insurance",
    useCompanyDefaultRentersInsurance: "Use company default renters insurance options",
    specifyRentersInsuranceForProperty: "Select the renters insurance options for this property",
    propertyRentersInsuranceDescription:
      "Decide if you require renters insurance for this property. You can use your default settings or customize it for this property.",
    requireRentersInsurance: "Require Renters Insurance",
    propertyPaymentsAllocation: "Property Payments Allocation",
    propertyPaymentsAllocationDescription: "You can set a custom payment allocation or use the default allocation.",
    useCompanyDefaultPaymentAllocation: "Use the default payment allocation.",
    specifyPaymentAllocationForProperty: "Customize the payment allocation for this property.",
    sendEmailToTenantWhenChargesArePosted: "Email an account statement to the tenants once rent charges are posted?",
    numOfDaysBeforeDueDateToPostRecurringTransactions:
      "When should we post recurring transactions to the lease ledger?",
    numOfDaysBeforeDueDateToPostRecurringTransactionsLabel: "Number of days before due",
    showLeaseLedger: "Allow tenants to view their lease ledger"
  }
};
