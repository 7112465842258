import { mathUtils } from "@doorloop/dto";
import { owners1099Api, vendors1099Api } from "api/reportsApi";
import type { RestApiBase } from "api/restApiBase";
import { get } from "lodash";
import qs from "query-string";
import type { Path } from "react-hook-form";

const REPORTS = {
  MISC: {
    api: owners1099Api,
    columns: [
      { _: "Reference ID (Optional)" },
      { name: `"Recipient's Name"` },
      { taxpayerIdTypeNumber: `"Federal ID type (1=EIN, 2=SSN, 3=ITIN, 4=ATIN)"` },
      { formattedTaxpayerId: `"Recipient's Federal ID No."` },
      { _: `"Recipient's Second Name (optional)"` },
      { "address.street1": `"Street Address"` },
      { "address.street2": `"Street Address Line 2"` },
      { "address.city": "City" },
      { stateCode: `"State (2 letters)"` },
      { "address.zip": "Zip" },
      { _: `"Foreign City, Province/State, Postal Code, Country (leave blank if US)"` },
      { email: `"Recipient's Email"` },
      { _: `"Acc't No. (optional)"` },
      { _: `"Office Code (optional)"` },
      { rentalIncome: `"Box 1 Rents"` },
      { _: `"Box 2 Royalties"` },
      { otherIncome: `"Box 3 Other Income"` },
      { _: `"Box 4 Fed Income Tax withheld"` },
      { _: `"Box 5 Fishing Boat Proceeds"` },
      { _: `"Box 6 Medical & Health Care"` },
      {
        _: `"Box 7 (blank = false, 1=true) Payer made direct sales of $5,000 or more of consumer products to a buyer (recipient) for resale"`
      },
      { _: `"Box 8 Substitute Payments"` },
      { _: `"Box 9 Crop Insurance Proceeds"` },
      { _: `"Box 10 Gross Proceeds Paid to an Attorney"` },
      { _: `"Box 11 Fish purchased for resale"` },
      { _: `"Box 12 Section 409A deferrals"` },
      { _: `"Box 13 FATCA filing req't (1=yes, blank=no)"` },
      { _: `"Box 14 Excess golden parachute payments"` },
      { _: `"Box 15 Nonqualified deferred compensation"` },
      { _: `"Box 16a State Tax withheld"` },
      { _: `"Box 16b Local Tax Withheld"` },
      { _: `"Box 17a State (2-letters)"` },
      { _: `"Box 17 Payer State ID No."` },
      { _: `"Box 17b Locality"` },
      { _: `"Box 18a State Income"` },
      { _: `"Box 18b Local Income"` },
      { _: `"Second TIN Notice (1=true, else blank)"` }
    ]
  },
  NEC: {
    api: vendors1099Api,
    columns: [
      { _: "Reference ID (Optional)" },
      { name: `"Recipient's Name"` },
      { taxpayerIdTypeNumber: `"Federal ID type (1=EIN, 2=SSN, 3=ITIN, 4=ATIN)"` },
      { formattedTaxpayerId: `"Recipient's Federal ID No."` },
      { _: `"Recipient's Second Name (optional)"` },
      { "address.street1": `"Street Address"` },
      { "address.street2": `"Street Address Line 2"` },
      { "address.city": "City" },
      { stateCode: `"State (2 letters)"` },
      { "address.zip": "Zip" },
      { _: `"Foreign City, Province/State, Postal Code, Country (leave blank if US)"` },
      { email: `"Recipient's Email"` },
      { _: `"Acc't No. (optional)"` },
      { _: `"Office Code (optional)"` },
      { nonEmployeeCompensation: `"Box 1 Nonemployee compensation"` },
      {
        _: `"Box 2 (blank = false, 1=true) Payer made direct sales of $5,000 or more of consumer products to a buyer (recipient) for resale"`
      },
      { _: `"Box 4 Federal income tax withheld"` },
      { _: `"Box 5 State tax withheld"` },
      { _: `"Box 6 State"` },
      { _: `"Box 6 Payer's State no."` },
      { _: `"Box 7 State Income"` },
      { _: `"Second TIN Notice (1=true, else blank)"` },
      { _: `"Box 5b Local Tax Withheld"` },
      { _: `"Box 6b Locality"` },
      { _: `"Box 7b Local Income"` }
    ]
  }
};

type ExportType = keyof typeof REPORTS;
type ExportDefinitions = typeof REPORTS;

export type InferDtoFromApi<T> = T extends RestApiBase<infer D, unknown> ? D : never;
export type InferQueryFromApi<T> = T extends RestApiBase<unknown, infer Q> ? Q : never;

export function getSearchParams() {
  const { ids, type } = qs.parse(window.location.search);
  const idsArray = ids ? (Array.isArray(ids) ? ids : [ids]) : [];
  return { ids: idsArray, type: type?.toString().toUpperCase() as "MISC" | "NEC" };
}

function formatCsvValue(value: any) {
  switch (typeof value) {
    case "boolean":
      return value ? "Yes" : "No";
    case "number":
      return mathUtils.format(value);
    case "string":
      if (value.includes(",") || value.includes("#")) {
        return value.replace(/[,#]/g, "");
      }
      return value;
    default:
      return value;
  }
}

export function formatAsCSV<T extends any, K extends Path<T>>(
  data: readonly T[],
  columns: ReadonlyArray<Partial<{ [key in K]: string }>>
) {
  const headers = columns.map((prop) => Object.values(prop)[0]);
  const rows = data.map((data) =>
    columns.map((col) => {
      const prop = Object.keys(col)[0];
      return formatCsvValue(get(data, prop));
    })
  );
  return [headers, ...rows].map((row) => row.join(",")).join("\n");
}

export function downloadAsCSV<T, K extends Path<T>>(
  title: string,
  data: readonly T[],
  columns: ReadonlyArray<Partial<{ [key in K]: string }>>
) {
  const encodedUri = encodeURI(formatAsCSV(data, columns));
  const link = document.createElement("a");
  link.setAttribute("href", `data:text/csv;charset=utf-8,${encodedUri}`);
  link.setAttribute("download", `${title}.csv`);
  document.body.appendChild(link);
  link.click();
}

export async function downloadReportAsCsv<T extends ExportType>(
  idsOrData: Array<string | InferDtoFromApi<ExportDefinitions[T]["api"]>>,
  type: "MISC" | "NEC"
) {
  if (!idsOrData?.length) return;

  const { api, columns } = REPORTS[type];
  const searchParams = qs.parse(location.search);
  const year = searchParams["filter_year"];
  const title = `"1099-${type}-${year}"`;
  if (typeof idsOrData[0] === "object") {
    return downloadAsCSV(title, idsOrData, columns);
  }
  const query = { ...searchParams, filter_ids: idsOrData as string[] };
  const { data } = await api.getAll(query);
  if (!data) return;
  return downloadAsCSV(title, data.data, columns);
}
