import { propertiesApi } from "api/propertiesApi";
import type { AddressDto } from "@doorloop/dto";

export const getPropertyAndUnitValue = (propertyId: string, unitName) => {
  let propertyName = "";
  const propertyDictionary = propertiesApi.getItemFromDictionary(propertyId);
  if (propertyDictionary?.name) {
    propertyName = propertyDictionary.name;
  }
  return propertyName + " > " + unitName;
};
export const getStreetLocation = (address?: AddressDto) => {
  let streetLocation = "";
  if (address) {
    const { street1, city, state, country, zip } = address;
    streetLocation = streetLocation.concat(
      street1 || "",
      city ? ", " + city : "",
      state ? ", " + state : "",
      country ? ", " + country : "",
      zip ? ", " + zip : ""
    );
  }

  return streetLocation;
};
