import { useEffect } from "react";

/**
 * A hook to check which keys are currently pressed
 *
 * Only one event listener is added to the window regardless of instances
 *
 * @returns An object acting as a reference to the currently pressed key events
 * @example
 * ```tsx
 * const { isAnyPressed } = useKeydown();
 * const ref = ({addEventListener, removeEventListener}) => {
 *   addEventListener("focusout", (event) => {
 *     if(!isAnyPressed()) return; // User is navigating using mouse
 *   })
 * }
 * ```
 */
export function useKeydown() {
  useEffect(() => {
    listeners++;
    if (listeners === 1) {
      window.addEventListener("keydown", handleKeydown);
    }
    return () => {
      listeners--;
      if (listeners === 0) {
        window.removeEventListener("keydown", handleKeydown);
      }
    };
  }, []);

  return {
    get keys() {
      return getKeys();
    },
    isAnyPressed() {
      return events.length > 0;
    },
    isPressed(key: string, shift?: boolean) {
      const event = getKeys()[key];
      return typeof shift === "undefined" ? Boolean(event) : event?.shiftKey === shift;
    }
  } as const;
}

let events = [] as KeyboardEvent[];
let listeners = 0;

const handleKeydown: Exclude<GlobalEventHandlers["onkeydown"], null> = (downEvent) => {
  events.push(downEvent);
  const handleKeyup: Exclude<GlobalEventHandlers["onkeyup"], null> = (upEvent) => {
    events = events.filter(({ key }) => key !== upEvent.key);
    window.removeEventListener("keyup", handleKeyup);
  };
  window.addEventListener("keyup", handleKeyup);
};

const getKeys = (): Record<string, KeyboardEvent | null> =>
  events.reduce((map, event) => {
    return { ...map, [event.key]: event };
  }, {});
