import { useState } from "react";

type HookInterface = (
  key: string,
  defaultValue?: string | null | undefined
) => [string | null, (newValue: string) => void];

export const useLocalStorage: HookInterface = (key, defaultValue: string | null = null) => {
  const currentValue = localStorage.getItem(key) ?? defaultValue;
  return [currentValue, (newValue: string) => localStorage.setItem(key, newValue)];
};

export const useLocalStorageState: HookInterface = (key: string, defaultValue: string | null = null) => {
  const [currentValue, setCurrentValue] = useState(localStorage.getItem(key) ?? defaultValue);
  return [
    currentValue,
    (newValue: string) => {
      localStorage.setItem(key, newValue);
      setCurrentValue(newValue);
    }
  ];
};
