import type { PropsWithChildren } from "react";
import React from "react";
import { usePermission } from "screens/settings/userRoles/usePermission";
import { NoAccessDisplay } from "screens/settings/userRoles/NoAccessDisplay";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";

interface Props {
  clearance?: AnyPermissionClearance;
  showNoAccess?: boolean;
}

export const RestrictedPermissionAccess: React.FC<Props> = ({
  clearance,
  showNoAccess,
  children
}: PropsWithChildren<Props>) => {
  const { hasPermission } = usePermission();
  const { permission, field, requiredLevel } = clearance || {};

  return (
    <>
      {!clearance || hasPermission(permission, field, requiredLevel) ? children : showNoAccess && <NoAccessDisplay />}
    </>
  );
};
