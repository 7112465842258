"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EndLeaseScenarioType = void 0;
var EndLeaseScenarioType;
(function (EndLeaseScenarioType) {
    EndLeaseScenarioType["END_LEASE_ZERO_BALANCE"] = "end_lease_zero_balance";
    EndLeaseScenarioType["END_LEASE_LEASE_HAS_SECURITY_DEPOSIT_AND_NO_OB"] = "end_lease_lease_has_security_deposit_and_no_ob";
    EndLeaseScenarioType["END_LEASE_REFUND_GREATER_THAN_DEPOSIT"] = "end_lease_refund_greater_than_deposit";
    EndLeaseScenarioType["END_LEASE_HAS_OB_AND_NO_SECURITY_DEPOSIT"] = "end_lease_has_ob_and_no_security_deposit";
    EndLeaseScenarioType["END_LEASE_HAS_OB_AND_DEPOSIT_BUT_DEPOSIT_IS_GREATER"] = "end_lease_has_ob_and_deposit_but_deposit_is_greater";
    EndLeaseScenarioType["END_LEASE_HAS_OB_AND_DEPOSIT_BUT_DEPOSIT_IS_LESS"] = "end_lease_has_ob_and_deposit_but_deposit_is_less";
    EndLeaseScenarioType["END_LEASE_LEASE_HAS_OUTSTANDING_CREDIT"] = "end_lease_lease_has_outstanding_credit";
    EndLeaseScenarioType["END_LEASE_HAS_OUTSTANDING_CREDIT_WITH_SECURITY_DEPOSIT"] = "end_lease_has_outstanding_credit_with_security_deposit";
})(EndLeaseScenarioType || (exports.EndLeaseScenarioType = EndLeaseScenarioType = {}));
