import type { ConversationMessageDto, GetAllBaseQueryRequest } from "@doorloop/dto";
import { atom, useAtom } from "jotai";
import { useState } from "react";

export const messagesListDataSourceAtom = atom<ConversationMessageDto[]>([]);
export const useMessagesListDataSource = () => {
  const [dataSource, setDataSource] = useAtom(messagesListDataSourceAtom);
  const [filterObject, setFilterObject] = useState<GetAllBaseQueryRequest>({});

  const appendToMessagesList = (message: ConversationMessageDto) => {
    setDataSource((old) => [message, ...old]);
  };

  const updateMessageInDataSource = (message: ConversationMessageDto) => {
    const updatedDataSource = dataSource.map((item) => (item.id === message.id ? message : item));

    setDataSource(updatedDataSource);
  };

  return {
    dataSource,
    setDataSource,
    appendToMessagesList,
    filterObject,
    setFilterObject,
    updateMessageInDataSource
  };
};
