export const googleApiKey = "AIzaSyD_GNc8x-c98_Kkt-gmvt09JmECeABqMGg";

const googleMapsScriptIdTag = "google-maps";

class AutoCompleteDataSource {
  requestInProgess = false;
  requestDelay = 500;
  googlePlacesScriptLoaded = false;
  googleAutocompleteServiceInstance = null;
  googleAutocompleteInstance = null;
  googleMapsScriptElemnt?: HTMLScriptElement;
  sessionToken = "";
  selectionCallback?: (selectedPlace: any) => void;
  elementId = "";

  init = (selectionCallback: (selectedPlace: any) => void, elementId: string) => {
    this.injectGoogleApiScript();
    this.selectionCallback = selectionCallback;
    this.elementId = elementId;
  };

  private loadScript = (src: string, position: HTMLElement | null, id: string) => {
    if (!position) {
      return;
    }
    this.googleMapsScriptElemnt = document.createElement("script");
    this.googleMapsScriptElemnt.setAttribute("async", "");
    this.googleMapsScriptElemnt.setAttribute("id", id);
    this.googleMapsScriptElemnt.src = src;
    position.appendChild(this.googleMapsScriptElemnt);
  };

  private injectGoogleApiScript = () => {
    if (typeof window !== "undefined" && !this.googlePlacesScriptLoaded) {
      if (!document.querySelector("#google-maps")) {
        this.loadScript(
          "https://maps.googleapis.com/maps/api/js?key=" + googleApiKey + "&libraries=places",
          document.querySelector("head"),
          googleMapsScriptIdTag
        );
      }

      this.googlePlacesScriptLoaded = true;
    }
  };

  private setGeolocate = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        const circle = new (window as any).google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy
        });
        if (this.googleAutocompleteInstance !== null) {
          (this.googleAutocompleteInstance! as any).setBounds(circle.getBounds());
        }
      });
    }
  };

  private getSessionToken = () => {
    if (this.sessionToken === "") {
      this.sessionToken = new (window as any).google.maps.places.AutocompleteSessionToken();
    }
    return this.sessionToken;
  };

  private onAddressSelection = () => {
    this.sessionToken = "";
    const place = (this.googleAutocompleteInstance as any).getPlace();
    if (this.selectionCallback && place) {
      this.selectionCallback(place);
    }
  };

  private initScripts = () => {
    this.googleAutocompleteServiceInstance = new (window as any).google.maps.places.AutocompleteService();
    this.googleAutocompleteInstance = new (window as any).google.maps.places.Autocomplete(
      document.getElementById(this.elementId),
      {
        fields: ["address_component", "geometry.location"]
      }
    );
    (this.googleAutocompleteInstance! as any).addListener("place_changed", this.onAddressSelection);
    this.setGeolocate();
  };

  clean = () => {
    this.googleAutocompleteServiceInstance = null;
    this.googleAutocompleteInstance = null;
  };

  private fetchResultsUsingGoogle = async (value: string): Promise<any[]> => {
    if (!this.googlePlacesScriptLoaded || this.requestInProgess) {
      throw new Error("Google Places script not loaded");
    }
    if ((window as any).google && this.googleAutocompleteServiceInstance === null) {
      this.initScripts();
    }
    this.requestInProgess = true;

    if (!this.googleAutocompleteServiceInstance) {
      throw new Error("Google autocomplete service instance not loaded");
    }

    const results: any[] = await new Promise((resolve, reject) => {
      (this.googleAutocompleteServiceInstance as any).getPlacePredictions(
        { input: value, sessionToken: this.getSessionToken() },
        (results: any) => {
          setTimeout(() => {
            this.requestInProgess = false;
            resolve(results);
          }, this.requestDelay);
        }
      );
    });

    return results;
  };

  getOptions = async (value: string) => {
    if (this.requestInProgess || !value || value === "") {
      return;
    }
    return await this.fetchResultsUsingGoogle(value);
  };
}

export default AutoCompleteDataSource;
