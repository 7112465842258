"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TreeListItemAccount = void 0;
const treeListItemWithGroupByValues_1 = require("./treeListItemWithGroupByValues");
class TreeListItemAccount extends treeListItemWithGroupByValues_1.TreeListItemWithGroupByValues {
    constructor(init) {
        super(Object.assign(Object.assign({}, init), { type: (init === null || init === void 0 ? void 0 : init.type) || "account" }));
        this.accountType = init === null || init === void 0 ? void 0 : init.accountType;
        this.referenceData = init === null || init === void 0 ? void 0 : init.referenceData;
        this.footer = init === null || init === void 0 ? void 0 : init.footer;
        this.subItems = init === null || init === void 0 ? void 0 : init.subItems;
    }
}
exports.TreeListItemAccount = TreeListItemAccount;
