import React, { Fragment } from "react";

import type { FormikProps } from "formik";
import { FastField, getIn } from "formik";
import { useTranslation } from "react-i18next";

import AppStrings from "locale/keys";
import DoorLoopUrlItem from "screens/settings/companyInformation/components/doorLoopUrlItem";
import Text from "DLUI/text";
import { OccupiedIcon } from "assets/icons";
import type { Routes } from "components/appRouter";
import { TextField } from "DLUI/form";
import { View } from "DLUI/view";

interface ComponentProps {
  formikRef: FormikProps<any>;
  loginRoute: Routes;
  portalUrlText: string;
  portalUrlDescriptionText: string;
  formikSubdomainFieldName?: string;
  shouldShow?: boolean;
  renderForm?: boolean;
  renderSpecifyRadioButtons?: () => React.ReactNode;
}

export const PortalUrlForm: React.FC<ComponentProps> = ({
  formikRef,
  loginRoute,
  portalUrlText,
  portalUrlDescriptionText,
  shouldShow = true,
  renderForm = true,
  formikSubdomainFieldName = "subdomain",
  renderSpecifyRadioButtons
}: ComponentProps) => {
  const { t } = useTranslation();

  const renderUrls = (): JSX.Element | null => {
    const subDomain = getIn(formikRef.values, formikSubdomainFieldName);
    const baseUrl = ".app.doorloop.com";

    if (subDomain) {
      const loginUrl = `https://${subDomain}${baseUrl}${loginRoute}`;

      return (
        <View>
          <DoorLoopUrlItem url={loginUrl} title={portalUrlText} icon={OccupiedIcon} />
        </View>
      );
    }

    return null;
  };

  return (
    <View width={"100%"} flex={1}>
      <View>
        <Text fontSize={16} color={"black"} value={portalUrlDescriptionText} />
        {renderSpecifyRadioButtons?.()}
        <View shouldShow={shouldShow} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
          {renderForm && (
            <Fragment>
              <Text
                value={AppStrings.Common.UrlStructure}
                color={"black"}
                fontSize={20}
                fontWeight={700}
                marginTop={20}
              />
              <View height={"80px"} alignItems={"center"} flexDirection={"row"}>
                <FastField
                  component={TextField}
                  label={t(AppStrings.Settings.GeneralSettings.doorLoopURL.Subdomain)}
                  name={formikSubdomainFieldName}
                  marginTop={20}
                  size={260}
                  autoWidth
                />
                <Text marginTop={20} marginLeft={10} color={"black"} fontSize={16}>
                  .app.doorloop.com
                </Text>
              </View>
              {renderUrls()}
              <View height={40} />
            </Fragment>
          )}
        </View>
      </View>
    </View>
  );
};
