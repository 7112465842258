"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentTokenDto = void 0;
const merchantAccountPlatform_enum_1 = require("../../merchantAccounts/merchantAccountPlatform.enum");
const class_validator_1 = require("class-validator");
const ePayPaymentMethod_enum_1 = require("../../common/ePayPaymentMethod.enum");
class PaymentTokenDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.PaymentTokenDto = PaymentTokenDto;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(200),
    __metadata("design:type", String)
], PaymentTokenDto.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(ePayPaymentMethod_enum_1.EPayPaymentMethod),
    __metadata("design:type", String)
], PaymentTokenDto.prototype, "method", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.method === ePayPaymentMethod_enum_1.EPayPaymentMethod.ECHECK),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.Length)(4),
    __metadata("design:type", String)
], PaymentTokenDto.prototype, "routing", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.Length)(4),
    __metadata("design:type", String)
], PaymentTokenDto.prototype, "number", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], PaymentTokenDto.prototype, "token", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.method === ePayPaymentMethod_enum_1.EPayPaymentMethod.CARD),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.Length)(4),
    __metadata("design:type", String)
], PaymentTokenDto.prototype, "expiration", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(merchantAccountPlatform_enum_1.MerchantAccountPlatform),
    __metadata("design:type", String)
], PaymentTokenDto.prototype, "platform", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], PaymentTokenDto.prototype, "mandateId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], PaymentTokenDto.prototype, "created", void 0);
