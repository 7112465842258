import AppStrings from "../../../../locale/keys";
import {
  CalendarIconNew,
  DollarIcon,
  FolderIconNew,
  LeasingIcon,
  NoteIconNew,
  PeopleIconNew,
  PieChartIconNew,
  RentalsIconNew,
  ReportsIconNew,
  RocketIconNew,
  SettingsIconNew,
  TasksIcon
} from "../../../../assets";
import { DataCy, ObjectPermission, ReportPermission, TaskViewObjectAccess } from "@doorloop/dto";
import { Feature } from "../../../../config/featureFlags/featureFlags";
import Routes from "../../../appRouter/routes";
import { AnyTaskViewListPermissions } from "screens/settings/userRoles/clearanceTypes";
import { v4 as uuid } from "uuid";
import type { DrawerItemType, NavConfigProps } from "DLUI/drawer/types";
import { DrawerItemName, NavLevels, SpecialDrawerItemTypes } from "DLUI/drawer/types";
import { IndicationTag } from "DLUI/drawer/types";
import { ArrayUtils } from "../../../../utils/dataStructureUtils";
import { getUserReportsScreenSectionGroupItem } from "screens/reports/reportsScreen/reportsScreenSectionGroupItems";
import { CommunicationsMenuItemCustom } from "DLUI/drawer/items/communicationsMenuItemCustom/communicationsMenuItemCustom";
import NavigationProfileItem from "DLUI/drawer/navigationUserProfileItem";
import { HelpButtonItem } from "DLUI/drawer/helpButtonItem";

export const MainMenuLayersKeys = {
  Onboarding: uuid(),
  Main: uuid(),
  Calendar: uuid(),
  Rentals: uuid(),
  Leasing: uuid(),
  Tasks: uuid(),
  Accounting: uuid(),
  People: uuid(),
  Communications: uuid()
};
const overviewMenuItem: DrawerItemType = {
  label: AppStrings.Dashboards.overview,
  icon: PieChartIconNew,
  navPath: [{ name: Routes.HOME, matchMethod: "anyMatch" }],
  navKey: MainMenuLayersKeys.Main,
  dataCy: DataCy.sideMenu.main.overview
};
const calendarMenuItem: DrawerItemType = {
  label: AppStrings.DrawerItems.Calendar,
  icon: CalendarIconNew,
  subNavigationName: DrawerItemName.Calendar,
  navKey: MainMenuLayersKeys.Calendar,
  dataCy: DataCy.sideMenu.main.calendar,
  clearance: [{ permission: ObjectPermission.calendar, field: "viewList" }]
};
const rentalsMenuItem: DrawerItemType = {
  label: AppStrings.DrawerItems.Rentals,
  icon: RentalsIconNew,
  subNavigationName: DrawerItemName.Rentals,
  navKey: MainMenuLayersKeys.Rentals,
  dataCy: DataCy.sideMenu.main.rentals,
  clearance: [
    { permission: ObjectPermission.properties, field: "viewList" },
    { permission: ObjectPermission.units, field: "viewList" }
  ]
};
const leasingMenuItem: DrawerItemType = {
  label: AppStrings.DrawerItems.Leasing,
  icon: LeasingIcon,
  subNavigationName: DrawerItemName.Leasing,
  navKey: MainMenuLayersKeys.Leasing,
  dataCy: DataCy.sideMenu.main.leasing,
  clearance: [
    {
      permission: ObjectPermission.leases,
      field: "viewList"
    },
    {
      permission: ObjectPermission.rentalApplications,
      field: "viewList"
    }
  ]
};
const peopleMenuItem: DrawerItemType = {
  label: AppStrings.DrawerItems.People,
  icon: PeopleIconNew,
  subNavigationName: DrawerItemName.People,
  navKey: MainMenuLayersKeys.People,
  dataCy: DataCy.sideMenu.main.people,
  clearance: [
    { permission: ObjectPermission.tenants, field: "viewList" },
    { permission: ObjectPermission.owners, field: "viewList" },
    { permission: ObjectPermission.vendors, field: "viewList" },
    { permission: ObjectPermission.prospects, field: "viewList" }
  ]
};
const tasksMenuItem: DrawerItemType = {
  label: AppStrings.Common.CreateNewMenu.TasksAndMaintenance,
  icon: TasksIcon,
  subNavigationName: DrawerItemName.Tasks,
  navKey: MainMenuLayersKeys.Tasks,
  dataCy: DataCy.sideMenu.main.tasksAndMaintenance,
  clearance: AnyTaskViewListPermissions
};
const accountingMenuItem: DrawerItemType = {
  label: AppStrings.DrawerItems.Accounting,
  icon: DollarIcon,
  subNavigationName: DrawerItemName.Accounting,
  navKey: MainMenuLayersKeys.Accounting,
  dataCy: DataCy.sideMenu.main.accounting,
  clearance: [
    { permission: ReportPermission.accountsReceivable },
    { permission: ReportPermission.accountsPayable },
    { permission: ReportPermission.banking },
    { permission: ReportPermission.printChecks },
    { permission: ObjectPermission.accounts, field: "viewList" }
  ]
};
const communicationsMenuItem: DrawerItemType = {
  label: AppStrings.Announcements.communications,
  customComponent: CommunicationsMenuItemCustom,
  showTooltipWithCustomComponent: true,
  subNavigationName: DrawerItemName.Communications,
  navKey: MainMenuLayersKeys.Communications,
  dataCy: DataCy.sideMenu.main.communications,
  inactive: Feature.isInactive("CommunicationsCenter"),
  tag: IndicationTag.NEW,
  clearance: [
    {
      permission: ObjectPermission.communications,
      field: "viewList"
    },
    { permission: ObjectPermission.announcements, field: "viewList" },
    {
      permission: ObjectPermission.esignatureRequests,
      field: "viewList"
    },
    {
      permission: ObjectPermission.esignatureTemplates,
      field: "viewList"
    },
    {
      permission: ObjectPermission.conversations,
      field: "viewList",
      requiredLevel: TaskViewObjectAccess.ASSIGNED_ONLY
    },
    {
      permission: ObjectPermission.conversations,
      field: "viewList",
      requiredLevel: TaskViewObjectAccess.ALL
    }
  ]
};
const notesMenuItem: DrawerItemType = {
  label: AppStrings.DrawerItems.Notes,
  icon: NoteIconNew,
  navPath: [{ name: Routes.NOTES, matchMethod: "exact" }],
  dataCy: DataCy.sideMenu.main.notes,
  clearance: [{ permission: ObjectPermission.notes, field: "viewList" }],
  navKey: uuid()
};
const filesMenuItem: DrawerItemType = {
  label: AppStrings.DrawerItems.Files,
  icon: FolderIconNew,
  navPath: [{ name: Routes.FILES, matchMethod: "exact" }],
  dataCy: DataCy.sideMenu.main.files,
  clearance: [{ permission: ObjectPermission.files, field: "viewList" }],
  navKey: uuid()
};
export const reportsMenuItem: DrawerItemType = {
  label: AppStrings.DrawerItems.Reports,
  icon: ReportsIconNew,
  navPath: [{ name: Routes.REPORTS, matchMethod: "anyMatch" }],
  navKey: uuid(),
  dataCy: DataCy.sideMenu.main.reports,
  clearance: ArrayUtils.extractReportSectionPermissions(getUserReportsScreenSectionGroupItem())
};

const hoaReportsMenuItem: DrawerItemType = {
  label: AppStrings.DrawerItems.Reports,
  icon: ReportsIconNew,
  navPath: [{ name: Routes.REPORTS, matchMethod: "anyMatch" }],
  navKey: uuid(),
  dataCy: DataCy.sideMenu.main.reports,
  clearance: ArrayUtils.extractReportSectionPermissions(getUserReportsScreenSectionGroupItem(true))
};

export const MainMenuLayers: NavConfigProps = {
  showLayerTitle: false,
  level: NavLevels.MAIN_MENU,
  groups: [
    {
      groupName: "MainMenu",
      showGroupName: false,
      items: [
        overviewMenuItem,
        calendarMenuItem,
        rentalsMenuItem,
        leasingMenuItem,
        peopleMenuItem,
        tasksMenuItem,
        accountingMenuItem,
        communicationsMenuItem,
        notesMenuItem,
        filesMenuItem,
        reportsMenuItem
      ]
    }
  ]
};
export const HOAUserMainMenuLayers: NavConfigProps = {
  showLayerTitle: false,
  level: NavLevels.MAIN_MENU,
  groups: [
    {
      groupName: "MainMenu",
      showGroupName: false,
      items: [
        overviewMenuItem,
        rentalsMenuItem,
        peopleMenuItem,
        tasksMenuItem,
        accountingMenuItem,
        communicationsMenuItem,
        notesMenuItem,
        filesMenuItem,
        hoaReportsMenuItem
      ]
    }
  ]
};
export const MainMenuFooterLayers: NavConfigProps = {
  showLayerTitle: false,
  level: NavLevels.MAIN_MENU,
  groups: [
    {
      showGroupName: false,
      items: [
        {
          label: AppStrings.OnBoarding.OnBoardingDrawerTitle,
          icon: RocketIconNew,
          navKey: MainMenuLayersKeys.Onboarding,
          navPath: [{ name: Routes.ONBOARDING, matchMethod: "startWith" }],
          specialType: SpecialDrawerItemTypes.ONBOARDING,
          dataCy: DataCy.sideMenu.mainFooter.onBoardingDrawerTitle
        },
        {
          customComponent: ({ isMobile, isMinimized, ...restProps }) =>
            isMobile ? (
              <HelpButtonItem
                isMobile={isMobile}
                isMinimized={isMinimized}
                dataCy={DataCy.sideMenu.mainFooter.help}
                {...restProps}
              />
            ) : null
        },
        {
          label: AppStrings.DrawerItems.Settings,
          icon: SettingsIconNew,
          navKey: uuid(),
          navPath: [
            { name: Routes.SETTINGS_GENERAL_SETTINGS, matchMethod: "anyMatch" },
            { name: Routes.USER_ROLES, matchMethod: "anyMatch" },
            { name: Routes.SETTINGS_COMPANY_USERS, matchMethod: "anyMatch" },
            { name: Routes.SUBSCRIPTION, matchMethod: "anyMatch" },
            { name: Routes.SUBSCRIPTION_DETAILS, matchMethod: "anyMatch" },
            { name: Routes.SUBSCRIPTION_CHECKOUT, matchMethod: "anyMatch" }
          ],
          dataCy: DataCy.sideMenu.mainFooter.settings
        },
        {
          customComponent: ({ isMobile, ...restProps }) =>
            isMobile ? <NavigationProfileItem isMobile={isMobile} {...restProps} /> : null
        }
      ]
    }
  ]
};
