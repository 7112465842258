import AppStrings from "../../../../locale/keys";
import { ACCOUNTING_REPORT_ITEMS } from "./reportsItems/accountingItems";
import { BUSINESS_OVERVIEW_REPORT_ITEMS } from "./reportsItems/businessOverviewItems";
import { LEASING_REPORT_ITEMS } from "./reportsItems/leasingItems";
import { OWNERS_REPORT_ITEMS } from "./reportsItems/ownersItems";
import { PROSPECTS_REPORT_ITEMS } from "./reportsItems/prospectsItems";
import type { ReportScreenSectionGroupItemType } from "DLUI/report/screen/reportScreenSectionGroupItemType";
import { TASKS_REPORT_ITEMS } from "./reportsItems/tasksItems";
import { TENANTS_REPORT_ITEMS } from "./reportsItems/tenantsItems";
import { VENDORS_REPORT_ITEMS } from "./reportsItems/vendorsItems";
import { WHAT_YOU_OWE_REPORT_ITEMS } from "./reportsItems/whatYouOwe";
import { WHO_OWES_YOU_REPORT_ITEMS } from "./reportsItems/whoOwesYou";

export const REPORTS_SCREEN_SECTION_GROUP_ITEMS: ReportScreenSectionGroupItemType[] = [
  {
    items: BUSINESS_OVERVIEW_REPORT_ITEMS,
    title: AppStrings.Reports.ReportsScreen.BusinessOverview
  },
  {
    items: ACCOUNTING_REPORT_ITEMS,
    title: AppStrings.DrawerItems.Accounting
  },
  {
    items: WHO_OWES_YOU_REPORT_ITEMS,
    title: AppStrings.Reports.ReportsScreen.WhoOwesYou
  },
  {
    items: WHAT_YOU_OWE_REPORT_ITEMS,
    title: AppStrings.Reports.ReportsScreen.WhatYouOwe
  },
  {
    items: TENANTS_REPORT_ITEMS,
    title: AppStrings.DrawerItems.Tenants
  },
  {
    items: PROSPECTS_REPORT_ITEMS,
    title: AppStrings.DrawerItems.Prospects
  },
  {
    items: LEASING_REPORT_ITEMS,
    title: AppStrings.DrawerItems.Leasing
  },
  {
    items: VENDORS_REPORT_ITEMS,
    title: AppStrings.DrawerItems.Vendors
  },
  {
    items: TASKS_REPORT_ITEMS,
    title: AppStrings.DrawerItems.Tasks
  },
  {
    items: OWNERS_REPORT_ITEMS,
    title: AppStrings.DrawerItems.Owners
  }
];
