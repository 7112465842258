import type { FC } from "react";
import React from "react";
import { PetDto } from "@doorloop/dto";
import AppStrings from "locale/keys";
import DtoFieldArray from "DLUI/form/dto/DtoFieldArray";
import PetFields from "DLUI/person/pets/petFields";
import { View } from "DLUI/view";
import type { RentalApplicationFieldControlledSectionSettingsDto } from "@doorloop/dto";

interface PetListFormProps {
  name: string;
  tenantView?: boolean;
  stepSettingsDto?: RentalApplicationFieldControlledSectionSettingsDto;
  isControlled?: boolean;
}

export const PetsListForm: FC<PetListFormProps> = ({ name, tenantView, stepSettingsDto, isControlled }) => (
  <View flex={1}>
    <DtoFieldArray
      name={name}
      newItem={() => new PetDto()}
      newItemText={AppStrings.Common.AddAnotherPet}
      headerText={AppStrings.Common.PetInformation}
    >
      {(renderProps) => (
        <PetFields
          {...renderProps}
          tenantView={Boolean(tenantView)}
          stepSettingsDto={stepSettingsDto}
          isControlled={isControlled}
        />
      )}
    </DtoFieldArray>
  </View>
);
