import React, { useEffect, useState } from "react";
import { useReadOnlyContext } from "contexts/readOnlyContext";
import { ControlledSwitch } from "DLUI/form/switchButton/base/controlledSwitch";
import type { ToggleInputSize } from "DLUI/form/types/toggleInputBaseTypes";

interface ComponentProps {
  label?: string;
  onChange?: (nextSwitchState: boolean) => void;
  checked?: boolean;
  disabled?: boolean;
  marginTop?: string | number;
  fullWidth?: boolean;
  noWrap?: boolean;
  dataCy?: string;
  size?: ToggleInputSize;
}

const UnControlledSwitch: React.FC<ComponentProps> = ({
  checked,
  onChange,
  label,
  disabled,
  marginTop,
  fullWidth,
  dataCy,
  size
}: ComponentProps) => {
  const isReadOnly = useReadOnlyContext();
  const [switchState, setSwitchState] = useState<boolean>(false);

  useEffect(() => {
    setSwitchState(Boolean(checked));
  }, [checked]);

  const didPressSwitchButton = () => {
    if (disabled) {
      return;
    }
    const nextSwitchState = !switchState;
    if (onChange) {
      onChange(nextSwitchState);
    }
    setSwitchState(nextSwitchState);
  };

  return (
    <ControlledSwitch
      checked={switchState}
      size={size}
      disabled={isReadOnly || disabled}
      onChange={didPressSwitchButton}
      dataCy={dataCy}
      fullWidth={fullWidth}
      labelValueProps={{
        text: label,
        placement: "end"
      }}
      style={{
        marginTop
      }}
    />
  );
};

export default UnControlledSwitch;
