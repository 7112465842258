import type { ComponentType } from "react";
import React, { useState } from "react";
import { View } from "DLUI/view";
import { Link } from "DLUI/link";
import { FastField } from "formik";
import type { FastFieldSafeProps } from "DLUI/fastFieldSafe/fastFieldSafe";
import ColorsEnum from "../../../../utils/colorsEnum";
import { AnimatePresence, motion } from "framer-motion";
import { Icon } from "DLUI/icon";
import { CheckMarkOutlineIcon } from "../../../../assets";
import type { ViewComponentProps } from "DLUI/view/view";
import { useTranslation } from "react-i18next";
import AppStrings from "../../../../locale/keys";

type SetAsDefaultFieldProps<T, TDto extends any = null> = FastFieldSafeProps<T, TDto> & {
  onSetAsDefaultClick: () => void;
  viewProps?: Partial<ViewComponentProps>;
};

const SetAsDefaultField = <T extends ComponentType<any>, TDto extends any = null>(
  props: SetAsDefaultFieldProps<T, TDto>
) => {
  const { t } = useTranslation();
  const [isSetAsDefaultShown, setIsSetAsDefaultShown] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const { onSetAsDefaultClick, component, dto, onChange, ...rest } = props;

  const handleSetAsDefaultClick = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setIsSetAsDefaultShown(false);
      setIsAnimating(false);
    }, 1000);
    onSetAsDefaultClick();
  };

  const handleChange = (...args: any[]) => {
    setIsSetAsDefaultShown(true);
    onChange?.(args);
  };

  return (
    <View flexDirection={"row"} alignItems={"center"} {...props.viewProps}>
      <FastField {...rest} component={component} onChange={handleChange} />
      <AnimatePresence>
        {isSetAsDefaultShown && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Link
              onClick={handleSetAsDefaultClick}
              textColor={isAnimating ? ColorsEnum.DarkGreen : ColorsEnum.BrightBlue}
              hoverColor={isAnimating ? "green" : "lightBlue"}
              underline={"always"}
            >
              <View flexDirection={"row"} gap={3} alignItems={"center"}>
                {t(AppStrings.Common.SetAsDefault)}
                {isAnimating && <Icon Source={CheckMarkOutlineIcon} size={17} />}
              </View>
            </Link>
          </motion.div>
        )}
      </AnimatePresence>
    </View>
  );
};

export default SetAsDefaultField;
