import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    mediumButton: {
      textTransform: "none",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "160px"
    },
    mediumInlineButton: {
      width: "180px !important",
      border: "none"
    },
    smallButton: {
      textTransform: "none",
      width: "140px !important",
      borderRadius: "10px !important",
      boxShadow: "none",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center"
    },
    inlineTextButton: {
      border: "none",
      backgroundColor: "transparent",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
        backgroundColor: "transparent"
      }
    },
    whiteButton: {
      boxShadow: "none",
      backgroundColor: theme.palette.white.main,
      border: "1px solid rgba(57,56,117,0.2)"
    },
    grayButton: {
      backgroundColor: theme.palette.gray.main
    },
    navyBlueButton: {
      backgroundColor: "#2F3E83",
      "&:hover": {
        backgroundColor: theme.palette.blue.light
      }
    },
    blueButton: {
      color: theme.palette.blue.main
    },
    blueBGButton: {
      backgroundColor: theme.palette.blue.main,
      color: "white"
    },
    errorButton: {
      color: theme.palette.error.main
    },
    lightBlueButton: {
      color: theme.palette.blue.light
    },
    lightBluePrimaryButton: {
      "& *": {
        color: theme.palette.white.main
      },
      color: theme.palette.white.main,
      backgroundColor: theme.palette.blue.light,
      "&:hover": {
        "& *": {
          color: theme.palette.blue.light
        },
        backgroundColor: "transparent"
      }
    },
    pinkBGbutton: {
      boxShadow: "none",
      backgroundColor: "#FF4998",
      color: "white",
      "&:hover": {
        backgroundColor: "#FF4998",
        color: "white"
      }
    },
    blackBGbutton: {
      boxShadow: "none",
      backgroundColor: "#182C4C",
      color: "white",
      "&:hover": {
        backgroundColor: "white",
        color: "#182C4C"
      }
    },

    pinkButton: {
      "&:hover": {
        boxShadow: "none",
        backgroundColor: "transparent"
      },
      boxShadow: "none",
      backgroundColor: "transparent"
    },

    icon: {
      width: "24px",
      height: "24px",
      marginRight: "10px"
    },
    deleteIconContainer: {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 20,
      height: 20,
      right: 5,
      top: 5
    },
    selectedTenantButton: {
      "&:hover": {
        backgroundColor: theme.palette.screenBackground.light
      },
      display: "flex",
      flexDirection: "column",
      width: 125,
      height: 125,
      border: "2px solid",
      borderRadius: 7,
      borderColor: theme.palette.pink.main,
      justifyContent: "center",
      alignItems: "center",
      marginLeft: 20,
      position: "relative",
      backgroundColor: theme.palette.white.main,
      marginTop: 20,
      cursor: "pointer"
    },
    AddSquareButton: {
      "&:hover": {
        backgroundColor: theme.palette.screenBackground.light
      },
      display: "flex",
      width: 130,
      height: 130,
      border: "1px dashed",
      borderRadius: 7,
      borderColor: theme.palette.gray.dark,
      justifyContent: "center",
      alignItems: "center",
      marginLeft: 20,
      position: "relative",
      backgroundColor: theme.palette.white.main,
      marginTop: 20,
      overflow: "hidden"
    },
    AddSquareButtonLabel: {
      flexDirection: "column",
      height: "100%"
    }
  };
});
