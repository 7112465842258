import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import type { FC } from "react";
import React from "react";

export const ListEndIndicator: FC = () => (
  <View justifyContent="center" alignItems="center" marginBottom={20}>
    <Text color={"black"} variant={"listItemTitle"} value={AppStrings.Common.NoMoreResults} marginTop={20} />
  </View>
);
