import type { FC, PropsWithChildren } from "react";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { View } from "DLUI/view";
import { Icon } from "DLUI/icon";
import { ChainLinkIcon, CheckbookIOIcon } from "assets/icons";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import type { CheckbookSettingsDto } from "@doorloop/dto";
import { AccountType } from "@doorloop/dto";
import BasicGrid from "DLUI/listItems/basicGrid";
import FieldWrapper from "DLUI/form/textField/fieldWrapper";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import { getIn, useFormikContext } from "formik";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface AccountMappingProps {
  cbAccountName: string;
  index: number;
}

const rowContentHeight = 55;

export const AccountMappingRow: FC = ({ children }: PropsWithChildren<{}>) => (
  <View
    borderRadius={10}
    backgroundColor={"white"}
    justifyContent={"center"}
    minHeight={rowContentHeight + 15}
    marginBottom={20}
  >
    <View flexDirection={"row"} alignItems={"center"} minHeight={rowContentHeight}>
      {children}
    </View>
  </View>
);

export const AccountMapping: FC<AccountMappingProps> = ({ cbAccountName, index }: AccountMappingProps) => {
  const { t } = useTranslation();
  const formikRef = useFormikContext<CheckbookSettingsDto>();
  const { isMobile } = useResponsiveHelper();
  const fieldName = `accountMappings[${index}].doorloopAccountId`;
  const cbName = useMemo(
    () => cbAccountName.trim() || t(AppStrings.Common.OutgoingPayments.UnnamedBankAccount),
    [cbAccountName]
  );

  useEffect(() => {
    const fieldValue = getIn(formikRef.values, fieldName);
    if (fieldValue === "") {
      formikRef.setFieldValue(fieldName, undefined);
    }
  }, [formikRef.values]);

  return (
    <AccountMappingRow>
      <BasicGrid xs={12} sm={6}>
        <FieldWrapper type={"endElement"} marginTop={isMobile ? undefined : 0} noWrap>
          <Icon Source={CheckbookIOIcon} marginRight={20} marginLeft={20} />
          {!isMobile && <SeparationLine width={1} height={"80%"} marginRight={20} />}
          <Text fontSize={16}>{cbName}</Text>
        </FieldWrapper>
      </BasicGrid>
      <BasicGrid xs={12} sm={6}>
        <SeparationLine width={1} height={"80%"} marginRight={20} />
        <FieldWrapper type={"endElement"} marginRight={40} marginTop={isMobile ? undefined : 0} noWrap>
          <Icon Source={ChainLinkIcon} marginRight={20} marginLeft={20} />
          <BankAccountFormikAutoCompleteField
            addCreateOption
            uniqueIndex={"account" + index}
            name={fieldName}
            label={t(AppStrings.Common.OutgoingPayments.SelectDoorLoopAccount)}
            queryParams={{
              filter_type: AccountType.ASSET_BANK,
              filter_active: true
            }}
            defaultValue={getIn(formikRef.values, fieldName)}
          />
        </FieldWrapper>
      </BasicGrid>
    </AccountMappingRow>
  );
};
