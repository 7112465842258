"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyndicationV2UnitLeasedStatus = void 0;
var SyndicationV2UnitLeasedStatus;
(function (SyndicationV2UnitLeasedStatus) {
    SyndicationV2UnitLeasedStatus["LEASED"] = "leased";
    SyndicationV2UnitLeasedStatus["AVAILABLE"] = "available";
    SyndicationV2UnitLeasedStatus["APPROVED"] = "approved";
    SyndicationV2UnitLeasedStatus["RESERVED"] = "reserved";
    SyndicationV2UnitLeasedStatus["NOT_READY"] = "not_ready";
    SyndicationV2UnitLeasedStatus["ON_NOTICE"] = "on_notice";
    SyndicationV2UnitLeasedStatus["LEASED_ON_NOTICE"] = "leased_on_notice";
    SyndicationV2UnitLeasedStatus["LEASED_RESERVED"] = "leased_reserved";
    SyndicationV2UnitLeasedStatus["OTHER"] = "other";
})(SyndicationV2UnitLeasedStatus || (exports.SyndicationV2UnitLeasedStatus = SyndicationV2UnitLeasedStatus = {}));
