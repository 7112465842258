import type { ProfileImageProps } from "../profileImage";
import { ProfileImage } from "../profileImage";

interface ProfileImagesProps {
  items: ProfileImageProps[];
  size?: number;
  margin?: number;
  maxAmountOfImages?: number;
}

const ProfileImages = ({ items, size = 35, margin = 10, maxAmountOfImages = 3 }: ProfileImagesProps) => {
  const mapItemToProfileImage = (item, index) => <ProfileImage {...item} key={index} size={size} marginLeft={margin} />;

  if (!items.length) return <></>;

  if (items.length <= maxAmountOfImages) {
    return <>{items.map(mapItemToProfileImage)}</>;
  }

  const numberOfUndisplayedItems = items.length - maxAmountOfImages + 1;

  return (
    <>
      {...items.slice(0, maxAmountOfImages - 1).map(mapItemToProfileImage)}
      <ProfileImage initialsLetters={`+${numberOfUndisplayedItems}`} size={size} marginLeft={margin} />
    </>
  );
};

export default ProfileImages;
