import React, { useMemo, useState } from "react";

import type { FormikProps } from "formik";
import { getIn } from "formik";

import AppStrings from "locale/keys";
import Text from "DLUI/text";
import { ColorPicker_Deprecated } from "DLUI/colorPicker/colorPicker_Deprecated";
import { ImageUpload } from "DLUI/imageUpload";
import { SeparationLine } from "DLUI/separatorView";
import { View } from "DLUI/view";
import type { OwnerPortalSettingsDto } from "@doorloop/dto";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";

interface ComponentProps {
  formikRef: FormikProps<OwnerPortalSettingsDto>;
  onTenantPortalLogoFileReceive?: (receivedFile: File) => void;
  onTenantPortalLogoFileDelete?: () => void;
  onCoverImageFileReceive?: (receivedFile: File) => void;
  onCoverImageFileDelete?: () => void;
}

export const ThemeSettings: React.FC<ComponentProps> = ({
  onTenantPortalLogoFileReceive,
  onTenantPortalLogoFileDelete,
  onCoverImageFileReceive,
  onCoverImageFileDelete,
  formikRef
}: ComponentProps) => {
  const { isMobile } = useResponsiveHelper();

  const [themeColor, setThemeColor] = useState<string | undefined>(getIn(formikRef.values, "theme.color"));

  const selectedLogoImageUrl = useMemo(() => getIn(formikRef.values, "theme.logoUrl"), [formikRef.values]);

  const selectedCoverImageUrl = useMemo(() => getIn(formikRef.values, "theme.backgroundUrl"), [formikRef.values]);

  const didChangeThemeColor = (selectedColor: string): void => {
    formikRef.setFieldValue("theme.color", selectedColor);

    setThemeColor(selectedColor);
  };

  return (
    <View width={"100%"} flex={1}>
      <View>
        <Text value={AppStrings.Common.OwnerPortalThemeSettingsDescription} color={"black"} fontSize={16} />
        <View shouldShow={true} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
          <View width={isMobile ? window.innerWidth - 30 : "100%"} alignItems={"flex-start"} marginTop={20}>
            <Text value={AppStrings.Common.Logo} color={"black"} fontSize={20} fontWeight={700} marginBottom={20} />
            <ImageUpload
              marginLeft={"0px"}
              alignSelf={"flex-start"}
              didReceivedFile={(receivedFile) => onTenantPortalLogoFileReceive?.(receivedFile)}
              didDeleteFile={() => onTenantPortalLogoFileDelete?.()}
              selectedImageUrl={selectedLogoImageUrl}
              companyImage
            />
            <SeparationLine marginTop={20} width={"100%"} height={1} />
            <Text
              value={AppStrings.Common.ColorTheme}
              color={"black"}
              fontSize={20}
              fontWeight={700}
              marginBottom={20}
              marginTop={20}
            />
            <ColorPicker_Deprecated
              onSelectionChange={didChangeThemeColor}
              containerWidth={isMobile ? "100%" : "50%"}
              selectedColor={themeColor}
            />
            <SeparationLine marginTop={20} marginBottom={20} width={"100%"} height={1} />
            <Text
              value={AppStrings.Common.BackgroundAndCoverImage}
              color={"black"}
              fontSize={20}
              fontWeight={700}
              marginBottom={20}
              marginTop={20}
            />
            <ImageUpload
              marginLeft={"0px"}
              alignSelf={"flex-start"}
              didReceivedFile={(receivedFile) => onCoverImageFileReceive?.(receivedFile)}
              didDeleteFile={() => onCoverImageFileDelete?.()}
              selectedImageUrl={selectedCoverImageUrl}
              coverImage
              backgroundColor={themeColor}
            />
          </View>
        </View>
      </View>
    </View>
  );
};
