import React, { useEffect } from "react";
import { View } from "DLUI/view";
import PaymentWarningView from "DLUI/dialogs/vendor/bill/paymentWarningView";
import Grid from "@material-ui/core/Grid";
import { FastField, Field } from "formik";
import { FormikDatePicker } from "DLUI/form";
import AppStrings from "../../../../../locale/keys";
import type { RegisterItemDto } from "@doorloop/dto";
import { DataCy, RecurringTransactionType, PersonTypeEnum } from "@doorloop/dto";
import { RecurringDate } from "DLUI/recurringDateForm/recurringDate";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { ReferenceLabelByRecurring } from "DLUI/recurringDateForm/referenceLabelByRecurring";
import ReconciledNotificationView from "DLUI/dialogs/components/reconciledNotificationView";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";
import { PeopleAutoComplete } from "@/components/DLUI/form/autoComplete/peopleAutoComplete/peopleAutoComplete";
import { useTypedTranslation } from "@/locale";
import { usePeopleAutoComplete } from "@/components/DLUI/form/autoComplete/peopleAutoComplete/usePeopleAutoComplete";

interface FormTopSectionProps {
  hasLinkedPayments?: boolean;
  handleVendorChange: (id?: string) => void;
  setIsVendorIdle: (isReady: boolean) => void;
  showVendorSelectionError?: boolean;
  currentVendorId?: string;
  vendorSelectionErrorText?: string;
  register?: RegisterItemDto[];
}

const FormTopSection: React.FC<FormTopSectionProps> = ({
  currentVendorId,
  hasLinkedPayments,
  handleVendorChange,
  setIsVendorIdle,
  showVendorSelectionError,
  vendorSelectionErrorText,
  register
}) => {
  const { isMobile } = useResponsiveHelper();
  const { t } = useTypedTranslation();

  const {
    state: { isIdle: isVendorIdle },
    props: vendorFieldProps
  } = usePeopleAutoComplete({
    currentType: PersonTypeEnum.VENDOR,
    props: {
      entityId: currentVendorId,
      textFieldProps: {
        placeholder: t("vendors.vendorDetails.selectVendor"),
        error: showVendorSelectionError,
        helperText: vendorSelectionErrorText
      },
      onChange(_event, value) {
        handleVendorChange(value?.data?.id);
      },
      dataCy: DataCy.formFields.selectVendor,
      disabled: hasLinkedPayments
    },
    queryOptions: {
      typeFilter: [PersonTypeEnum.VENDOR]
    }
  });

  useEffect(() => {
    setIsVendorIdle(isVendorIdle);
  }, [isVendorIdle, setIsVendorIdle]);

  return (
    <View>
      <ReconciledNotificationView register={register} />
      <PaymentWarningView shouldShow={hasLinkedPayments} />
      <View flexDirection={"row"} noWrap={!isMobile} gap={20}>
        <Grid item xs={12} sm={6} md={9}>
          <Field name="vendor" component={PeopleAutoComplete} {...vendorFieldProps} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ReferenceLabelByRecurring referenceLabel={AppStrings.Common.ListHeader.Reference} />
        </Grid>
      </View>
      <View flexDirection={"row"}>
        <HorizontalSeparationLine marginTop={20} />
        <RecurringDate type={RecurringTransactionType.VENDOR_BILL}>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <View paddingRight={isMobile ? 0 : 10}>
              <FastField
                component={FormikDatePicker}
                uniqueKey={"billDate"}
                label={AppStrings.Vendors.VendorDetails.BillDate}
                name={"date"}
                noMargin
                required
                marginTop={20}
              />
            </View>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <View paddingLeft={isMobile ? 0 : 10}>
              <FastField
                component={FormikDatePicker}
                uniqueKey={"billDueDate"}
                label={AppStrings.Leases.NewLease.LeaseRent.DueDate}
                name={"dueDate"}
                noMargin
                required
                marginTop={20}
              />
            </View>
          </Grid>
        </RecurringDate>
      </View>
      <HorizontalSeparationLine marginTop={20} />
    </View>
  );
};

export default FormTopSection;
