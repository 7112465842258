"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseRecurringPaymentInfoDto = void 0;
const ePayReceivableInfo_dto_1 = require("../common/ePayReceivableInfo.dto");
const class_validator_1 = require("class-validator");
const paymentMethod_enum_1 = require("../journalEntries/paymentMethod.enum");
const recurringPaymentAmountType_enum_1 = require("./recurringPaymentAmountType.enum");
class LeaseRecurringPaymentInfoDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.LeaseRecurringPaymentInfoDto = LeaseRecurringPaymentInfoDto;
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.amountType !== recurringPaymentAmountType_enum_1.RecurringPaymentAmountType.FULL_OUTSTANDING_BALANCE),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsNumber)({ maxDecimalPlaces: 2 }),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], LeaseRecurringPaymentInfoDto.prototype, "amount", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], LeaseRecurringPaymentInfoDto.prototype, "receivedFromTenant", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], LeaseRecurringPaymentInfoDto.prototype, "depositAccount", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(paymentMethod_enum_1.PaymentMethod),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], LeaseRecurringPaymentInfoDto.prototype, "paymentMethod", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.paymentMethod === paymentMethod_enum_1.PaymentMethod.EPAY),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", ePayReceivableInfo_dto_1.EPayReceivableInfoDto)
], LeaseRecurringPaymentInfoDto.prototype, "ePayInfo", void 0);
