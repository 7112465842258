import { useState } from "react";
import { Dialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import type { VideoItem } from "DLUI/screen/helpPanel/types";
import { SupportVideoDialog } from "DLUI/screen/helpPanel/supportVideoDialog";
import { useAnalyticsService } from "@/hooks/useAnalyticsService";

const useHelpVideoDialog = () => {
  const [dialogState, setDialogState] = useState<number>(DialogState.Hidden);
  const [videoLinkHref, setVideoLinkHref] = useState<string>("");
  const [activeVideoTitle, setActiveVideoTitle] = useState<string>("");
  const [activeVideoSubTitle, setActiveVideoSubTitle] = useState<string>("");
  const [startTime, setStartTime] = useState<number>();
  const { dispatchAnalytics } = useAnalyticsService();

  const onCloseHelpVideo = () => {
    setVideoLinkHref("");
    setDialogState(DialogState.Hidden);
  };

  const onOpenHelpVideo = ({ href, dialogSubTitle = "", dialogTitle = "", startTime }: VideoItem) => {
    dispatchAnalytics("help_panel_video_click", { dialogTitle });
    setActiveVideoTitle(dialogTitle);
    setActiveVideoSubTitle(dialogSubTitle);
    setVideoLinkHref(href);
    setDialogState(DialogState.Show);
    setStartTime(startTime);
  };

  const DialogView = () => (
    <Dialog
      dialogState={dialogState}
      onClose={onCloseHelpVideo}
      Content={SupportVideoDialog}
      onBackdropClick={onCloseHelpVideo}
      disableBackdropClick
      href={videoLinkHref}
      dialogTitle={activeVideoTitle}
      dialogSubTitle={activeVideoSubTitle}
      startTime={startTime}
    />
  );

  return { onOpenHelpVideo, DialogView };
};

export { useHelpVideoDialog };
