import React from "react";
import type { StepPropsWithDto } from "screens/onBoarding/types";
import { OnBoardingPaymentIcon } from "assets/.";
import AppStrings from "locale/keys";
import Text from "DLUI/text";
import StepSection from "screens/onBoarding/components/stepSection";
import { Icon } from "DLUI/icon";
import { bankAccountsApi } from "api/bankAccountsApi";
import type { BankAccountDto } from "@doorloop/dto";
import { View } from "DLUI/view";
import { InfiniteList } from "DLUI/infiniteList";
import OnBoardingBankAccountListItem from "screens/onBoarding/components/onBoardingBankAccountListItem";
import { Grid } from "@material-ui/core";
import ActionButton from "screens/onBoarding/components/actionButton";
import { onBoardingApi } from "api/onBoardingApi";
import { VideoUrlsEnum } from "DLUI/screen/helpPanel/types";

type Props = StepPropsWithDto;

const AcceptRentPaymentsStep: React.FC<Props> = ({
  onInvalidate,
  onHeaderClick,
  isCollapsed,
  name,
  onBoardingDto,
  isCompleted,
  isSkipped
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const icon = OnBoardingPaymentIcon;
  const title = AppStrings.OnBoarding.StepTitles.AcceptRentPayments;
  const description = AppStrings.OnBoarding.StepDescriptions.AcceptRentPayments;

  const handleMarkAsComplete = async () => {
    setIsLoading(true);
    const response = await onBoardingApi.updateOnBoardingItem(name, { isSkipped: !isSkipped });

    const hasError = response.statusCode !== 200 ? response.message ?? true : undefined;
    await onInvalidate({ error: hasError });
    setIsLoading(false);
  };

  const LeftSideContent = () => (
    <View gap={15}>
      <Text whiteSpace={"pre-line"} value={description} />
      <View
        style={{
          maxHeight: 300,
          overflow: "auto"
        }}
      >
        <InfiniteList
          showStickyHeader={false}
          renderItem={async (currentItem: BankAccountDto, index) => (
            <OnBoardingBankAccountListItem marginTop={10} bankAccountDto={currentItem} key={index} />
          )}
          apiMethod={bankAccountsApi}
          filterObj={{}}
        />
      </View>
      <Grid container direction={"row"} alignItems={"center"} spacing={2}>
        <Grid item xs={12} sm={"auto"} md={"auto"}>
          {/* Don't show the mark as complete/incomplete button if the step is actually completed. show if skipped */}
          {(!isCompleted || isSkipped) && (
            <ActionButton
              onClick={handleMarkAsComplete}
              faded
              requestInProgress={isLoading}
              actionText={
                isCompleted || isSkipped
                  ? AppStrings.OnBoarding.MarkAsIncomplete
                  : AppStrings.OnBoarding.MarkAsCompleted
              }
              fullWidth
              width={"100%"}
            />
          )}
        </Grid>
      </Grid>
    </View>
  );

  return (
    <StepSection
      icon={<Icon size={24} Source={icon} />}
      title={title}
      leftSideContent={<LeftSideContent />}
      isCompleted={isCompleted}
      isSkipped={isSkipped}
      onHeaderClick={onHeaderClick}
      isCollapsed={isCollapsed}
      isLoading={isLoading}
      reactPlayerVideoUrl={VideoUrlsEnum.OB_SETTING_UP_RENT_PAYMENTS}
    />
  );
};

export default AcceptRentPaymentsStep;
