import React, { useEffect, useMemo, useState } from "react";
import { AddCirclePinkIcon, CloseCircleIcon } from "assets/icons";
import { getRgbaColor } from "DLUI/colorPicker/colorPicker";
import { IconButton } from "DLUI/form";
import { Icon } from "DLUI/icon";
import { Link } from "DLUI/link";
import Text from "DLUI/text";
import { BackgroundImageView, View } from "DLUI/view";
import AppStrings from "locale/keys";
import makeStyles from "./styles";

import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import { themeBackgroundImage } from "screens/tenantPortal/components/tenantPortalBackgroundView";

interface ComponentProps {
  selectedImageFile?: File;
  onFileDelete: () => void;
  selectedImageUrl?: string;
  backgroundColor?: string;
}

const DefaultCoverColor = "#2F3E83";

const CoverImageSelection: React.FC<ComponentProps> = ({
  selectedImageFile,
  onFileDelete,
  selectedImageUrl,
  backgroundColor
}: ComponentProps) => {
  const { isMobile } = useResponsiveHelper();
  const containerWidth = isMobile ? "100%" : "535";
  const classes = makeStyles();
  const [selectedImageData, setSelectedImageData] = useState<string | ArrayBuffer | null>(null);

  const rgbaColor = useMemo(() => getRgbaColor(backgroundColor || DefaultCoverColor, "0.85"), [backgroundColor]);

  useEffect(() => {
    if (selectedImageFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        //@ts-ignore
        if (e && e.target && e.target.result) {
          //@ts-ignore
          setSelectedImageData(e.target.result);
        }
      };
      reader.readAsDataURL(selectedImageFile);
    } else {
      setSelectedImageData(null);
    }
  }, [selectedImageFile]);

  const renderUnselectedState = () => (
    <View
      width={containerWidth}
      height={125}
      justifyContent={"center"}
      alignItems={"center"}
      backgroundColor={"dark"}
      borderRadius={10}
    >
      <View
        width={isMobile ? containerWidth : 515}
        height={105}
        border={"1px dashed #7A8699"}
        alignItems={"center"}
        justifyContent={"center"}
        borderRadius={10}
      >
        <BackgroundImageView
          height={105}
          publicFileName={themeBackgroundImage}
          backgroundSize={"cover"}
          borderRadius={10}
          width={isMobile ? window.innerWidth - 30 : undefined}
          backgroundPosition={"bottom -30px center"}
        >
          <View
            width={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
            flex={1}
            style={{ backgroundColor: rgbaColor }}
            borderRadius={10}
          >
            <Icon Source={AddCirclePinkIcon} width={20} height={20} />
            <Link hoverColor={"white"}>
              <Text
                value={AppStrings.Common.PressToAddCoverImage}
                color={"white"}
                fontSize={15}
                fontWeight={700}
                marginTop={10}
              />
            </Link>
            <Text value={AppStrings.Common.CoverImageRecommendedSize} color={"white"} fontSize={14} marginTop={10} />
          </View>
        </BackgroundImageView>
      </View>
    </View>
  );

  const renderSelectedState = () => {
    let imageUrl = selectedImageUrl;
    if (selectedImageData) {
      imageUrl = selectedImageData.toString();
    }
    return (
      <View
        width={535}
        height={135}
        backgroundColor={"dark"}
        justifyContent={"center"}
        alignItems={"center"}
        borderRadius={10}
      >
        <div className={classes.companyLogoDeleteImageContainer}>
          <IconButton circleFill={"pink"} Icon={CloseCircleIcon} width={20} height={20} onClick={onFileDelete} />
        </div>
        <View
          width={515}
          height={115}
          border={"1px dashed #7A8699"}
          justifyContent={"center"}
          alignItems={"center"}
          borderRadius={10}
        >
          <BackgroundImageView
            height={115}
            publicFileName={"url(" + imageUrl + ")"}
            backgroundSize={"cover"}
            borderRadius={10}
          >
            <View
              width={"100%"}
              alignItems={"center"}
              justifyContent={"center"}
              flex={1}
              style={{ backgroundColor: rgbaColor }}
              borderRadius={10}
            />
          </BackgroundImageView>
        </View>
      </View>
    );
  };

  if (selectedImageData !== null || selectedImageUrl !== undefined) {
    return renderSelectedState();
  }

  return renderUnselectedState();
};

export default CoverImageSelection;
