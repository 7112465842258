import React from "react";
import { View } from "DLUI/view";
import makeStyles from "./styles";

interface ComponentProps {
  progress: number;
  strokeWidth?: number;
  sqSize?: number;
}

const RadialProgressBar: React.FC<ComponentProps> = ({ progress, strokeWidth = 2.5, sqSize = 15 }) => {
  const classes = makeStyles();
  // minor adjustment on high percent for better ui visibility
  const _progress = progress > 70 && progress < 100 ? progress - 10 : progress;
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (sqSize - strokeWidth) / 2;
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * _progress) / 100;

  return (
    <View flexDirection={"row"} noWrap alignItems={"center"}>
      <svg width={sqSize} height={sqSize} viewBox={viewBox}>
        <circle
          className={classes.circleBackground}
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
        />
        <circle
          className={classes.circleProgress}
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          // Start progress marker at 12 O'Clock
          transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset
          }}
        />
      </svg>
      <div className={classes.circleText}>{`(${progress}%)`}</div>
    </View>
  );
};

export default RadialProgressBar;
