import { View } from "DLUI/view";
import { useMemo } from "react";
import type {
  FilterPanelDateRangePicker,
  FilterPanelRemoteFiltering,
  FilterPanelStaticOptionsFiltering,
  FilterPanelTextFieldFiltering
} from "DLUI/screen/filterPanel/filterPanel";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import qs from "qs";
import type { ApiResult } from "api/apiResult";
import Text from "DLUI/text";
import moment from "moment";
import type { FilterOption } from "./filterInputs/types";

export interface SelectedFilterChipsProps<TQuery> {
  filterOptions: Array<FilterOption<TQuery>>;
}

export function SelectedFilterChips<TQuery>({ filterOptions }: SelectedFilterChipsProps<TQuery>) {
  const location = useLocation();
  const { t } = useTranslation();
  const queryString = qs.parse(window.location.search.replace("?", ""));

  const getTextFieldFilterData = (filterOption: FilterPanelTextFieldFiltering<any>) => {
    if (queryString[filterOption.filterFieldName.toString()]) {
      return { name: filterOption.label, value: queryString[filterOption.filterFieldName.toString()] };
    }
  };

  const getDateRangePickerData = (filterOption: FilterPanelDateRangePicker<any>) => {
    const startDate = queryString[filterOption.startDateFieldKey.toString()];
    const endDate = queryString[filterOption.endDateFieldKey.toString()];

    if (startDate && endDate) {
      return {
        name: filterOption.label,
        value: `${moment(startDate.toString()).format("MM/DD/YYYY")} - ${moment(endDate.toString()).format(
          "MM/DD/YYYY"
        )}`
      };
    }
  };

  const getStaticFilterData = (filterOption: FilterPanelStaticOptionsFiltering<any>) => {
    const fieldName = filterOption.values[0].filterFieldName;

    if (queryString[fieldName.toString()]) {
      const value = filterOption.values.find((value) => value.value === queryString[fieldName.toString()]);
      return { name: filterOption.name, value: t(value?.displayName!) };
    }
  };

  const getRemoteFilteringData = (filterOption: FilterPanelRemoteFiltering<any>) => {
    const fieldName = filterOption.filterFieldName;

    if (queryString[fieldName.toString()]) {
      const value = filterOption.apiHandler.getItemFromDictionary(queryString[fieldName.toString()]) as ApiResult<any>;

      if (value) {
        return { name: filterOption.name, value: value[filterOption.displayNameKey] };
      }
    }
  };

  const activeFilterChips = useMemo(() => {
    const sidePanelFilterOptions = filterOptions.filter((filterOption) => !filterOption.alwaysVisible);

    return sidePanelFilterOptions
      ?.map((filterOption) => {
        switch (filterOption.type) {
          case "remoteFiltering":
            return getRemoteFilteringData(filterOption as FilterPanelRemoteFiltering<any>);
          case "staticFiltering":
            return getStaticFilterData(filterOption as FilterPanelStaticOptionsFiltering<any>);
          case "textFieldFiltering":
            return getTextFieldFilterData(filterOption as FilterPanelTextFieldFiltering<any>);
          case "dateRangePicker":
            return getDateRangePickerData(filterOption as FilterPanelDateRangePicker<any>);
        }
      })
      .filter((filterOption) => filterOption);
  }, [filterOptions, location.search]);

  return (
    <View marginTop={10} gap={5} flexDirection={"row"}>
      {activeFilterChips.map((chip, index) => (
        <View
          className={"chip"}
          key={index}
          flexDirection={"row"}
          borderRadius={5}
          border={"1px solid #182C4C"}
          paddingBottom={5}
          paddingTop={5}
          paddingRight={5}
          paddingLeft={5}
          gap={3}
          autoWidth
        >
          {chip?.name && (
            <>
              <Text value={`${t(chip.name)}: `} bold fontSize={12} /> <Text fontSize={12} value={chip?.value} />
            </>
          )}
        </View>
      ))}
    </View>
  );
}
