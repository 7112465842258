export default {
  metadata: {
    title: "Tenants",
    titleSingular: "Tenant"
  },
  creatable: {
    title: "Add New Tenant",
    buttonLabel: "Add New Tenant"
  },
  screen: {
    contactInfo: "Contact Info",
    sendingInvite: "Sending invite...",
    sendingReminder: "Sending Payment Reminder...",
    tenant: "Tenant",
    tenantPortal: "Tenant Portal",
    emptydataViewInstructionsText: "No Tenants Found",
    emptydataAddNew: "Add your first Tenant",
    searchPaneltitle: "Manage your tenants",
    searchPlaceHolder: "Search...",
    addNewTenant: "New Tenant",
    editTenant: "Edit Tenant",
    viewProfile: "View Profile",
    loginToPortal: "Login to Portal",
    residentPortalEnabled: "Resident portal enabled",
    residentPortalNotEnabled: "Resident portal not enabled",
    tenantRequestChooseLeaseAssociatedWithMultipleUnits:
      "This lease is associated with multiple units. Please select the unit you would like make a request for.",
    moveInTenant: "Move-in Tenant",
    moveOutTenant: "Move-out Tenant",
    portalEnabled: "Portal Enabled",
    portalDisabled: "Portal Disabled",
    portal: "Portal",
    deleteConfirmationText: "Are you sure you want to delete this tenant?",
    deleteLoadingText: "Deleting tenant",
    deleteSuccessText: "Tenant was deleted successfully",
    explainer: "In DoorLoop, tenants are added by creating a lease or moving someone into an existing lease.",
    helpPanel: {
      addToNewLease: "How do I add a tenant to a new lease?",
      addToExistingLease: "How do I add tenants to an existing lease?",
      findTenants: "How do I find and filter my tenants?",
      overview: "Tenants Overview",
      description: "Add tenants by creating a lease or moving someone into an existing lease.",
      dialogTitle: "Tenant Overview"
    }
  },
  moveTenants: {
    whoIsMovingIn: "Which tenant is moving in?",
    whoIsMovingOut: "Which tenant is moving out?",
    selectTenant: "Select Tenant",
    inviteToPortal: "Invite to the tenant portal",
    moveInCreateErrorText: "Failed to move-in tenant",
    moveOutCreateErrorText: "Failed to move-out tenant",
    moveInCreateSuccessText: "Move-in tenant successful",
    moveOutCreateSuccessText: "Move-out tenant successful",
    moveOutDate: "Move-out date",
    moveInDate: "Move-in date",
    noticeDate: "Notice date",
    helpPanel: {
      in: {
        description: "Add another tenant to this lease.",
        move: "How do I move-in a tenant?",
        dialogTitle: "Add another tenant to this lease."
      },
      out: {
        description: "Remove a tenant without ending a lease for everyone else.",
        move: "How do I move-out a tenant?",
        dialogTitle: "Move-Out a Tenant"
      }
    }
  },
  newTenant: {
    firstName: "First Name",
    lastName: "Last Name",
    middleNameInitials: "M.I",
    middleName: "Middle Name",
    company: "Company",
    jobTitle: "Job Title",
    notes: "Notes",
    contactInfo: "Contact Info",
    contactDetails: "Contact Details",
    phone: "Phone",
    mobilePhone: "Mobile Phone",
    primaryEmail: "Primary Email",
    email: "Email",
    address: "Address",
    addAnother: "Add Another",
    primaryAddress: "Primary Address",
    alternateAddress: "Alternate Address",
    emergencyContact: "Emergency Contacts",
    emergencyContactInformation: "Emergency Contact Information",
    emergencyContactName: "Name",
    emergencyContactRelationship: "Relationship",
    contact: "Contact",
    loadingText: "Creating tenant ...",
    errorText: "Something went wrong. Please try again later or contact us.",
    successText: "Tenant created successfully",
    createNewTenant: "Create New Tenant",
    uploadPhoto: "Upload Photo",
    personalInfo: "Personal Info",
    noAddresses: "No address on file",
    noContactInfo: "No contact info on file",
    noEmergencyContacts: "No emergency contacts on file",
    noNotes: "There are no notes available.",
    noContacts: "There are no contacts available.",
    viewContacts: "View Contacts",
    dateOfBirth: "Date Of Birth",
    deleteConfirmationText: "Are you sure you want to delete the tenant?",
    deleteLoadingText: "Deleting tenant",
    deleteProspectSuccessText: "The tenant was deleted successfully",
    tenantLoginEmailPhoneDescription:
      "These details are required for rent reminders, tenant communications and portal access.",
    pets: "Pets",
    vehicles: "Vehicles",
    dependents: "Dependents"
  },
  filterValues: {
    current: "Current",
    past: "Past",
    future: "Future",
    draft: "Draft",
    canceled: "Canceled",
    invited: "Invited",
    active: "Active",
    inactive: "Inactive",
    missingContactDetails: "Missing Contact Details",
    notInvited: "Not Invited",
    loggedIn: "Logged In",
    paymentSetup: "Payment Set up"
  },
  status: {
    current: "Current Tenant",
    past: "Past Tenant",
    future: "Future Tenant",
    draft: "Draft Tenant",
    active: "Active",
    inactive: "Inactive"
  }
};
