import React from "react";
import { View } from "DLUI/view";
import type { DialogState } from "DLUI/dialogs/loadingDialog";
import { LoadingDialog } from "DLUI/dialogs";

interface ComponentProps {
  loadingDialogState: DialogState;
  loadingDialogErrorText: string;
  onBackdropClick: () => void;
  didPressSubmit: () => void;
}

const InsurancePolicyLoadingView: React.FC<ComponentProps> = ({
  loadingDialogState,
  loadingDialogErrorText,
  onBackdropClick,
  didPressSubmit
}: ComponentProps) => {
  const frameWidth = Math.min(window.innerWidth - 80, 790);
  const frameHeight = Math.min(window.innerHeight, 666);

  return (
    <View width={frameWidth} height={frameHeight} alignItems={"center"} justifyContent={"center"}>
      <LoadingDialog
        dialogState={loadingDialogState}
        errorText={loadingDialogErrorText}
        didPressDismissButton={onBackdropClick}
        onRetryButtonPress={didPressSubmit}
        minHeight={frameHeight}
      />
    </View>
  );
};

export default InsurancePolicyLoadingView;
