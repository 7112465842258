import { lazy } from "react";
import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";

const TransactionsScreen = lazy(() => import("screens/transactions/transactionsScreen"));
const BankReconciliationReportScreen = lazy(
  () =>
    import(
      "screens/transactions/reconciliation/bankReconciliation/bankReconciliationReport/bankReconciliationReportScreen"
    )
);
const NewBankReconciliationScreen = lazy(
  () =>
    import("screens/transactions/reconciliation/bankReconciliation/newBankReconciliation/newBankReconciliationScreen")
);
const BudgetFormScreen = lazy(() => import("screens/budgets/budgetForm/budgetFormScreen"));
const BudgetsListScreen = lazy(() => import("screens/budgets/budgetsList/budgetsListScreen"));

export const BookkeepingRouter = () => (
  <PageRouter>
    <LazyRoute
      exact
      path={[
        Routes.BOOKKEEPING_TRANSACTIONS_CONNECT_ACCOUNT,
        Routes.BOOKKEEPING_BANK_ACCOUNTS_DISCONNECT_ACCOUNT,
        Routes.BOOKKEEPING_TRANSACTIONS,
        Routes.BOOKKEEPING_BANK_ACCOUNTS_BANK_CONNECT_REPAIR_CONNECTION,
        Routes.BOOKKEEPING_BANK_ACCOUNTS_BANK_CONNECT_TRANSACTIONS_EDIT_BILL,
        Routes.BOOKKEEPING_BANK_ACCOUNTS_BANK_CONNECT_TRANSACTIONS_EDIT_EXPENSE,
        Routes.BOOKKEEPING_BANK_ACCOUNTS_BANK_CONNECT_TRANSACTIONS_EDIT_DEPOSIT,
        Routes.BOOKKEEPING_BANK_ACCOUNTS_BANK_CONNECT_TRANSACTIONS_EDIT_GENERAL_ENTRY,
        Routes.BOOKKEEPING_BANK_ACCOUNTS_BANK_CONNECT_TRANSACTIONS_EDIT_REFUND,
        Routes.BOOKKEEPING_BANK_ACCOUNTS_BANK_CONNECT_TRANSACTIONS_EDIT_BILL_PAYMENT,
        Routes.BOOKKEEPING_BANK_ACCOUNTS_BANK_CONNECT_TRANSACTIONS_EDIT_BANK_TRANSFER
      ]}
      render={TransactionsScreen}
    />

    <LazyRoute
      path={[
        Routes.BOOKKEEPING_TRANSACTIONS_OVERVIEW_NEW_ACCOUNT,
        Routes.BOOKKEEPING_TRANSACTIONS_OVERVIEW_EDIT,
        Routes.BOOKKEEPING_BANK_CONNECT_GLOBAL_NEW,
        Routes.BOOKKEEPING_CONNECT_NEW_ACCOUNT,
        Routes.BOOKKEEPING_TRANSACTIONS_OVERVIEW,
        Routes.BOOKKEEPING_BANK_TRANSACTIONS_CONFIRM_DIALOG,
        Routes.BOOKKEEPING_BANK_ACCOUNTS_EDIT_ACCOUNT,
        Routes.BOOKKEEPING_CONNECT_EDIT_DEPOSIT,
        Routes.BOOKKEEPING_BANK_ACCOUNTS_BANK_CONNECT_TRANSACTIONS,
        Routes.BOOKKEEPING_INIT_BANK_ACCOUNTS_BANK_CONNECT_TRANSACTIONS,
        Routes.BOOKKEEPING_BANK_ACCOUNTS_BANK_CONNECT_TRANSACTIONS_GLOBAL_NEW,
        Routes.BOOKKEEPING_RECONCILIATION
      ]}
      render={TransactionsScreen}
    />

    <LazyRoute
      exact
      path={[
        Routes.BOOKKEEPING_RECONCILIATION_REPORT_INIT,
        Routes.BOOKKEEPING_RECONCILIATION_REPORT,
        Routes.BOOKKEEPING_RECONCILIATION_REPORT_EDIT_DEPOSIT,
        Routes.BOOKKEEPING_RECONCILIATION_REPORT_EDIT_LEASE_CHARGE,
        Routes.BOOKKEEPING_RECONCILIATION_REPORT_EDIT_EXPENSE,
        Routes.BOOKKEEPING_RECONCILIATION_REPORT_EDIT_LEASE_PAYMENT,
        Routes.BOOKKEEPING_RECONCILIATION_REPORT_EDIT_LEASE_CREDIT,
        Routes.BOOKKEEPING_RECONCILIATION_REPORT_EDIT_LEASE_REFUND,
        Routes.BOOKKEEPING_RECONCILIATION_REPORT_EDIT_VENDOR_BILL_PAYMENT,
        Routes.BOOKKEEPING_RECONCILIATION_REPORT_EDIT_GENERAL_ENTRY,
        Routes.BOOKKEEPING_RECONCILIATION_REPORT_EDIT_OWNER_CONTRIBUTION,
        Routes.BOOKKEEPING_RECONCILIATION_REPORT_EDIT_VENDOR_CREDIT,
        Routes.BOOKKEEPING_RECONCILIATION_REPORT_EDIT_TRANSFER,
        Routes.BOOKKEEPING_RECONCILIATION_REPORT_EDIT_VENDOR_BILL
      ]}
      render={BankReconciliationReportScreen}
    />

    <LazyRoute path={Routes.BOOKKEEPING_RECONCILIATION_REPORT_GLOBAL_NEW} render={BankReconciliationReportScreen} />

    <LazyRoute
      exact
      path={[
        Routes.BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_DEPOSIT,
        Routes.BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_LEASE_CHARGE,
        Routes.BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_EXPENSE,
        Routes.BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_LEASE_PAYMENT,
        Routes.BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_LEASE_CREDIT,
        Routes.BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_LEASE_REFUND,
        Routes.BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_VENDOR_BILL_PAYMENT,
        Routes.BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_GENERAL_ENTRY,
        Routes.BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_OWNER_CONTRIBUTION,
        Routes.BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_VENDOR_CREDIT,
        Routes.BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_TRANSFER,
        Routes.BOOKKEEPING_RECONCILIATION_MATCH_TRANSACTIONS_EDIT_VENDOR_BILL
      ]}
      render={NewBankReconciliationScreen}
    />

    <LazyRoute
      path={[Routes.BOOKKEEPING_NEW_BANK_RECONCILIATION, Routes.BOOKKEEPING_NEW_BANK_RECONCILIATION_GLOBAL_NEW]}
      render={NewBankReconciliationScreen}
    />

    <LazyRoute path={[Routes.BOOKKEEPING_BUDGET_NEW, Routes.BOOKKEEPING_BUDGET_EDIT]} render={BudgetFormScreen} />
    <LazyRoute path={[Routes.BOOKKEEPING_BUDGETS_LIST]} render={BudgetsListScreen} />
  </PageRouter>
);

export default BookkeepingRouter;
