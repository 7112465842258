import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    globalSearchInputContainer: {
      position: "relative",
      cursor: "pointer"
    },
    globalSearchInputIcon: {
      position: "absolute",
      left: 8
    },
    globalSearchInput: {
      width: "100%",

      "& input": {
        boxSizing: "border-box",
        backgroundColor: theme.palette.white.main,
        cursor: "pointer",
        caretColor: "transparent",
        height: 32,
        borderRadius: "4px",
        width: "100%",
        fontSize: "14px",
        padding: "0 10px 0 30px",
        border: "solid 2px rgba(24, 44, 76, 0.1)",

        "&::placeholder": {
          fontWeight: "bold",
          color: "rgb(24, 44, 76) !important",
          opacity: "0.6 !important"
        }
      }
    }
  })
);
