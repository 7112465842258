import React, { useEffect, useState } from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { View } from "DLUI/view";
import type { ViewBackgroundColor } from "DLUI/view/view";
import { useTranslation } from "react-i18next";
import makeStyles from "./styles";
import "./styles.css";
import { useWindowEvent } from "hooks/useWindowEvent";

interface TabItemProps {
  screenData?: {};
}

export interface TabItem {
  label: string;
  component: React.ComponentType<TabItemProps>;
  resultsCount?: number;
}

interface ComponentProps {
  tabsItems: TabItem[];
  screenData?: any;
  fullWidth?: boolean;
  tabsPanelBackgroundColor?: ViewBackgroundColor;
}

const GlobalSearchTabs: React.FC<ComponentProps> = ({
  tabsItems,
  screenData,
  tabsPanelBackgroundColor
}: ComponentProps) => {
  const { t } = useTranslation();
  const classes = makeStyles();
  const [screenContentWidth, setScreenContentWidth] = useState<number>(window.innerWidth);

  const [displayTabIndex, setDisplayTabIndex] = useState<number>(0);
  const [visitedTabs, setVisitedTabs] = useState<number[]>([0]);

  const updateSize = () => {
    setScreenContentWidth(window.innerWidth - 40);
  };

  useEffect(() => {
    setDisplayTabIndex(0);
    setVisitedTabs([0]);
    appendTabResultsCount();
  }, [tabsItems]);

  useWindowEvent("resize", updateSize, []);

  useEffect(() => {
    setTimeout(() => {
      updateSize();
    }, 0);
  }, []);

  const appendTabResultsCount = () => {
    if (tabsItems.length > 0) {
      tabsItems.forEach((currentTab: TabItem) => {
        const tabContainer = document.getElementById(currentTab.label + "TabLabel");

        if (tabContainer !== null && currentTab.resultsCount) {
          const resultsNode = tabContainer.getElementsByClassName("resultsCount");
          if (
            resultsNode !== null &&
            resultsNode[0] !== null &&
            resultsNode[0] !== undefined &&
            resultsNode[0].parentNode !== null &&
            resultsNode[0].parentNode !== undefined
          ) {
            resultsNode[0].parentNode.removeChild(resultsNode[0]);
          }
          const element = document.createElement("span");
          element.className = "resultsCount";
          element.appendChild(document.createTextNode(String(currentTab.resultsCount)));
          tabContainer.append(element);
        }
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setVisitedTabs([newValue]);
    setDisplayTabIndex(newValue);
  };

  const renderTabs = () =>
    tabsItems.map((currentItem, index) => (
      <Tab
        classes={{
          root: classes.tabButton,
          textColorPrimary: classes.tabTextColor,
          selected: classes.tabTextColorSelected
        }}
        id={currentItem.label + "TabLabel"}
        key={"TB" + index}
        label={t(currentItem.label)}
      />
    ));

  const renderTabPanels = () =>
    tabsItems.map((currentItem, index) => {
      const CurrentTab = currentItem.component;
      const shouldRender = visitedTabs.includes(index);
      const currentClass = displayTabIndex !== index ? classes.tabHidden : classes.tabVisible;
      return (
        <Typography
          component="div"
          role="tabpanel"
          hidden={displayTabIndex !== index}
          id={`full-width-tabpanel-${index}`}
          className={currentClass}
          key={"TP" + index}
        >
          <div className={classes.tabContainer}>{shouldRender ? <CurrentTab screenData={screenData} /> : <div />}</div>
        </Typography>
      );
    });

  return (
    <View>
      <View backgroundColor={tabsPanelBackgroundColor} alignItems={"center"} justifyContent={"center"}>
        <Tabs
          value={displayTabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="tabs"
          variant="scrollable"
          scrollButtons="auto"
          className={"tabsContainer"}
          style={{
            width: screenContentWidth,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {renderTabs()}
        </Tabs>
      </View>
      <View alignItems={"center"} justifyContent={"center"}>
        {renderTabPanels()}
      </View>
    </View>
  );
};

export default GlobalSearchTabs;
