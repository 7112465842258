import React, { Fragment } from "react";
import { VendorDto } from "@doorloop/dto";
import { MailIcon, PhoneIcon } from "assets/icons";
import { Icon } from "DLUI/icon";
import { Grid, ListItemContainer } from "DLUI/listItems";
import { ProfileImage } from "DLUI/profileImage";
import { View } from "DLUI/view";
import ListItemText from "DLUI/listItems/listItemText";
import AppStrings from "locale/keys";

interface ComponentProps extends VendorDto {
  didSelectVendor: (selectedItem: VendorDto) => void;
}

const SelectableVendorListItem: React.FC<ComponentProps> = ({
  didSelectVendor,
  name,
  fullName,
  phones,
  emails,
  companyName,
  jobTitle,
  pictureUrl,
  id
}: ComponentProps) => {
  const _didSelectVendor = () => {
    const selectedVendor = new VendorDto();
    selectedVendor.emails = emails;
    selectedVendor.name = name;
    selectedVendor.id = id;
    selectedVendor.pictureUrl = pictureUrl;
    if (didSelectVendor) {
      didSelectVendor(selectedVendor);
    }
  };

  const renderEmail = () => {
    if (emails && emails.length > 0) {
      return (
        <Fragment>
          <Icon width={16} Source={MailIcon} pathColor={"black"} />

          <View flex={1}>
            <ListItemText marginLeft={10} align={"left"} color={"black"}>
              {emails[0].address ? emails[0].address : "--"}
            </ListItemText>
          </View>
        </Fragment>
      );
    }
  };

  const renderPhone = () => {
    if (phones && phones.length > 0) {
      return (
        <Fragment>
          <Icon width={16} Source={PhoneIcon} pathColor={"black"} />
          <ListItemText marginLeft={10} align={"left"} color={"black"}>
            {phones[0].number ? phones[0].number : "--"}
          </ListItemText>
        </Fragment>
      );
    }
  };

  return (
    <View>
      <ListItemContainer>
        <Grid
          onClick={_didSelectVendor}
          showDivider
          xs={12}
          sm={4}
          md={4}
          lg={4}
          title={AppStrings.Common.FilterOptions.Name}
        >
          <View paddingLeft={10} alignItems={"center"} flexDirection={"row"}>
            <ProfileImage pictureUrl={pictureUrl} />
            <View flex={1}>
              <ListItemText bold marginLeft={10} align={"left"} color={"black"}>
                {fullName}
              </ListItemText>
              <View alignItems={"center"} marginLeft={10} marginTop={5} flexDirection={"row"}>
                <ListItemText color={"black"}>
                  {jobTitle}
                  {jobTitle ? ", " : ""}
                  {companyName}
                </ListItemText>
              </View>
            </View>
          </View>
        </Grid>
        <Grid
          onClick={_didSelectVendor}
          showDivider
          xs={12}
          sm={4}
          md={4}
          lg={4}
          title={AppStrings.Tenants.NewTenant.Phone}
        >
          <View noWrap paddingLeft={10} alignItems={"center"} flexDirection={"row"}>
            {renderPhone()}
          </View>
        </Grid>
        <Grid title={AppStrings.Tenants.NewTenant.Email} onClick={_didSelectVendor} xs={12} sm={4} md={4} lg={4}>
          <View paddingLeft={10} alignItems={"center"} flexDirection={"row"}>
            {renderEmail()}
          </View>
        </Grid>
      </ListItemContainer>
    </View>
  );
};

export default SelectableVendorListItem;
