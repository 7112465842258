const Bills = {
  PayBills: {
    Screen: {
      PayBills: "bills.payBills.screen.payBills",
      SelectBills: "bills.payBills.screen.selectBills",
      SelectBillsDescription: "bills.payBills.screen.selectBillsDescription",
      AllProperties: "bills.payBills.screen.allProperties",
      SomeProperties: "bills.payBills.screen.someProperties",
      AllVendors: "bills.payBills.screen.allVendors",
      SomeVendors: "bills.payBills.screen.someVendors",
      WhichProperties: "bills.payBills.screen.whichProperties",
      WhichVendors: "bills.payBills.screen.whichVendors",
      WhichBills: "bills.payBills.screen.whichBills",
      Selectvendors: "bills.payBills.screen.selectVendors",
      AllBills: "bills.payBills.screen.allBills",
      SomeBills: "bills.payBills.screen.someBills",
      DueBefore: "bills.payBills.screen.dueBefore",
      SelectProperties: "bills.payBills.screen.selectProperties",
      SelectPropertiesDescription: "bills.payBills.screen.selectPropertiesDescription",
      AvailableFunds: "bills.payBills.screen.availableFunds",
      OutstandingBills: "bills.payBills.screen.outstandingBills",
      OutstandingCredits: "bills.payBills.screen.outstandingCredits",
      TotalPayments: "bills.payBills.screen.totalPayments",
      AllocatePayments: "bills.payBills.screen.allocatePayments",
      PropertiesNoResultsText: "bills.payBills.screen.propertiesNoResultsText",
      BillDue: "bills.payBills.screen.billDue",
      TotalBills: "bills.payBills.screen.totalBills",
      TotalCredits: "bills.payBills.screen.totalCredits",
      Transaction: "bills.payBills.screen.transaction",
      BillsAllocationSummaryLineValidation: "bills.payBills.screen.billsAllocationSummaryLineValidation",
      BillsAllocationDialogSummaryValidation: "bills.payBills.screen.billsAllocationDialogSummaryValidation",
      BillsAllocationDialogSummaryValidationDescription:
        "bills.payBills.screen.billsAllocationDialogSummaryValidationDescription",
      NoOutstandingTransactionsFound: "bills.payBills.screen.noOutstandingTransactionsFound",
      CreatePayments: "bills.payBills.screen.createPayments",
      CreatePaymentsDescription: "bills.payBills.screen.createPaymentsDescription",
      Vendor: "bills.payBills.screen.vendor",
      ConsolidateChecks: "bills.payBills.screen.consolidateChecks",
      CreatingPayments: "bills.payBills.screen.creatingPayments",
      PaymentsCreated: "bills.payBills.screen.paymentsCreated",
      BillPayment: "bills.payBills.screen.billPayment",
      PaymentAccount: "bills.payBills.screen.paymentAccount",
      OnlyOpenBills: "bills.payBills.screen.onlyOpenBills",
      HelpPanel: {
        Description: "bills.payBills.screen.helpPanel.description",
        PayBills: "bills.payBills.screen.helpPanel.payBills",
        PrintChecks: "bills.payBills.screen.helpPanel.printChecks",
        InsufficientFunds: "bills.payBills.screen.helpPanel.insufficientFunds"
      },
      Portfolios: "bills.payBills.screen.portfolios"
    }
  },
  WizardSteps: {
    Bills: "bills.payBills.wizardSteps.bills",
    Properties: "bills.payBills.wizardSteps.properties",
    Payments: "bills.payBills.wizardSteps.payments"
  },
  OpenBills: {
    SearchPlaceHolder: "bills.openBills.searchPlaceHolder",
    AddNewbill: "bills.openBills.addNewBill",
    Bill: "bills.openBills.bill",
    EmptydataViewInstructionsText: "bills.openBills.emptydataViewInstructionsText"
  },
  Checks: {
    Void: "bills.checks.void",
    VoidActionConfirmation: "bills.checks.voidActionConfirmation",
    VoidActionConfirmationTitle: "bills.checks.voidActionConfirmationTitle"
  }
};

export default Bills;
