const BulkActions = {
  UpdateDefaultAccounts: "bulkActions.updateDefaultAccounts",
  XSelected: "bulkActions.xSelected",
  ExecutionComplete: "bulkActions.executionComplete",
  OneOperationFailed: "bulkActions.oneOperationFailed",
  OneOperationSkipped: "bulkActions.oneOperationSkipped",
  XOperationsFailed: "bulkActions.xOperationsFailed",
  XOperationsSkipped: "bulkActions.xOperationsSkipped",
  XOutOfTotalCompleted: "bulkActions.xOutOfTotalCompleted",
  Failed: "bulkActions.failed",
  Skipped: "bulkActions.skipped",
  Completed: "bulkActions.completed",
  UploadingPicture: "bulkActions.uploadingPicture",
  ReorderingPicture: "bulkActions.reorderingPicture",
  RemovingPicture: "bulkActions.removingPicture",
  UpdatingLeases: "bulkActions.updatingLeases",
  UpdatingProperties: "bulkActions.updatingProperties",
  DeletingTransactions: "bulkActions.deletingTransactions",
  ConfirmDeleteXTransactionsMessageOne: "bulkActions.confirmDeleteXTransactionsMessageOne",
  CannotDeleteTransaction: "bulkActions.cannotDeleteTransaction",
  DeletionNotSupported: "bulkActions.deletionNotSupported",
  DeletingXTransactionMessageOne: "bulkActions.deletingXTransactionMessageOne",
  ConfirmDeleteXTransactionsMessageMultiple: "bulkActions.confirmDeleteXTransactionsMessageMultiple",
  ConfirmDeleteXEntriesMessageMultiple: "bulkActions.confirmDeleteXEntriesMessageMultiple",
  ConfirmUpdateXPropertiesMessageMultiple: "bulkActions.confirmUpdateXPropertiesMessageMultiple",
  SendPortalInvitation: "bulkActions.sendPortalInvitation",
  SendInvitation: "bulkActions.sendInvitation",
  ConfirmActivateTenantPortalMessageOne: "bulkActions.confirmActivateTenantPortalMessageOne",
  UnitOpenForApplicationMessageOne: "bulkActions.unitOpenForApplicationMessageOne",
  UnitOpenForApplicationMessageMultiple: "bulkActions.unitOpenForApplicationMessageMultiple",
  UnitCloseForApplicationMessageOne: "bulkActions.unitCloseForApplicationMessageOne",
  UnitCloseForApplicationMessageMultiple: "bulkActions.unitCloseForApplicationMessageMultiple",
  OpenForApplicationConfirmation: "bulkActions.openForApplicationConfirmation",
  CloseForApplicationConfirmation: "bulkActions.closeForApplicationConfirmation",
  ConfirmActivateTenantPortalMessageMultiple: "bulkActions.confirmActivateTenantPortalMessageMultiple",
  ConfirmActivateOwnerPortalMessageOne: "bulkActions.confirmActivateOwnerPortalMessageOne",
  ConfirmActivateOwnerPortalMessageMultiple: "bulkActions.confirmActivateOwnerPortalMessageMultiple",
  ActivatePortalConfirmation: "bulkActions.activatePortalConfirmation",
  ActivateOwnerPortalOngoingMessage: "bulkActions.activateOwnerPortalOngoingMessage",
  ConfirmDeleteOneOwner: "bulkActions.confirmDeleteOneOwner",
  ConfirmDeleteMultipleOwners: "bulkActions.confirmDeleteMultipleOwners",
  ConfirmDeleteOneProspects: "bulkActions.confirmDeleteOneProspects",
  ConfirmDeleteMultipleProspects: "bulkActions.confirmDeleteMultipleProspects",
  EditManagementFeesDescription: "bulkActions.editManagementFeesDescription",
  ConfirmDeleteOneVendor: "bulkActions.confirmDeleteOneVendor",
  ConfirmDeleteMultipleVendors: "bulkActions.confirmDeleteMultipleVendors",
  EditLateFees: "bulkActions.editLateFees",
  EditManagementFees: "bulkActions.editManagementFees",
  LeasePrintAccountReceivable: "bulkActions.leasePrintAccountReceivable",
  LeasePrintAccountReceivableConfirmation: "bulkActions.leasePrintAccountReceivableConfirmation",
  LeasePrintAccountReceivableConfirmationOne: "bulkActions.leasePrintAccountReceivableConfirmationOne",
  LeasePrintAccountReceivableConfirmationMultiple: "bulkActions.leasePrintAccountReceivableConfirmationMultiple",
  LeasePrintAccountReceivableBatch: "bulkActions.leasePrintAccountReceivableBatch",
  ConfirmDeleteDisclaimer: "bulkActions.confirmDeleteDisclaimer",
  SendWelcomeSms: "bulkActions.sendWelcomeSms",
  SendWelcomeSmsHeader: "bulkActions.sendWelcomeSmsHeader",
  SendTenantWelcomeSmsConfirmMessageOne: "bulkActions.sendTenantWelcomeSmsConfirmMessageOne",
  SendTenantWelcomeSmsConfirmMessageMultiple: "bulkActions.sendTenantWelcomeSmsConfirmMessageMultiple",
  SendWelcomeSmsOngoingMessage: "bulkActions.sendWelcomeSmsOngoingMessage",
  SendProspectWelcomeSmsConfirmMessageOne: "bulkActions.sendProspectWelcomeSmsConfirmMessageOne",
  SendProspectWelcomeSmsConfirmMessageMultiple: "bulkActions.sendProspectWelcomeSmsConfirmMessageMultiple",
  SendOwnerWelcomeSmsConfirmMessageOne: "bulkActions.sendOwnerWelcomeSmsConfirmMessageOne",
  SendOwnerWelcomeSmsConfirmMessageMultiple: "bulkActions.sendOwnerWelcomeSmsConfirmMessageMultiple",
  SendVendorWelcomeSmsConfirmMessageOne: "bulkActions.sendVendorWelcomeSmsConfirmMessageOne",
  SendVendorWelcomeSmsConfirmMessageMultiple: "bulkActions.sendVendorWelcomeSmsConfirmMessageMultiple",
  SelectedItemsOnPage: "bulkActions.selectedItemsOnPage",
  SelectAllItems: "bulkActions.selectAllItems",
  ClearSelection: "bulkActions.clearSelection"
};

export default BulkActions;
