"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TreeListItem = void 0;
const uuid_1 = require("uuid");
class TreeListItem {
    constructor(init) {
        this.id = (init === null || init === void 0 ? void 0 : init.id) || (0, uuid_1.v4)();
        this.type = init === null || init === void 0 ? void 0 : init.type;
        this.typeId = init === null || init === void 0 ? void 0 : init.typeId;
        this.parentTypeId = init === null || init === void 0 ? void 0 : init.parentTypeId;
        this.name = init === null || init === void 0 ? void 0 : init.name;
    }
}
exports.TreeListItem = TreeListItem;
