import type { PutUpdateDecisionStatusDto } from "@doorloop/dto";
import { DataCy, RentalApplicationDecisionStatus } from "@doorloop/dto";
import { ApproveIcon, DeniedIcon, QuestionIcon, XBlackIcon } from "assets/icons";
import { FormikImageButtonGroup } from "DLUI/form";
import { View } from "DLUI/view";
import { FastField, useFormikContext } from "formik";
import AppStrings from "locale/keys";
import React, { useMemo } from "react";

const decisionTypes = () => [
  {
    label: AppStrings.Common.Enums.RentalApplicationDecisionStatus.UNDECIDED,
    icon: QuestionIcon,
    onPress: () => {},
    isSelected: false,
    key: RentalApplicationDecisionStatus.UNDECIDED,
    value: RentalApplicationDecisionStatus.UNDECIDED,
    dataCy: DataCy.rentalApplications.decisionDialog.noDecisionButton
  },
  {
    label: AppStrings.Common.Enums.RentalApplicationDecisionStatus.APPROVED,
    icon: ApproveIcon,
    onPress: () => {},
    isSelected: false,
    key: RentalApplicationDecisionStatus.APPROVED,
    value: RentalApplicationDecisionStatus.APPROVED,
    dataCy: DataCy.rentalApplications.decisionDialog.approvedButton
  },
  {
    label: AppStrings.Common.Enums.RentalApplicationDecisionStatus.REJECTED,
    icon: DeniedIcon,
    onPress: () => {},
    isSelected: false,
    key: RentalApplicationDecisionStatus.REJECTED,
    value: RentalApplicationDecisionStatus.REJECTED,
    dataCy: DataCy.rentalApplications.decisionDialog.rejectedButton
  },
  {
    label: AppStrings.Common.Enums.RentalApplicationDecisionStatus.IGNORED,
    icon: XBlackIcon,
    onPress: () => {},
    isSelected: false,
    key: RentalApplicationDecisionStatus.IGNORED,
    value: RentalApplicationDecisionStatus.IGNORED,
    dataCy: DataCy.rentalApplications.decisionDialog.ignoredButton
  }
];

const DecisionImageButtonGroup = () => {
  const formik = useFormikContext<PutUpdateDecisionStatusDto>();

  const decisionTypesSelection = useMemo(() => {
    const selectionDefaultValue = formik.values.newDecisionStatus;
    const currentDecisionTypesSelection = decisionTypes();
    if (selectionDefaultValue) {
      return currentDecisionTypesSelection.map((currentItem) => {
        return {
          ...currentItem,
          isSelected: currentItem.value === selectionDefaultValue
        };
      });
    }

    return currentDecisionTypesSelection;
  }, []);
  return (
    <View justifyContent={"center"}>
      <FastField
        component={FormikImageButtonGroup}
        uniqueId={"newDecisionStatus"}
        buttonItemsArray={decisionTypesSelection}
        size={120}
        name={"newDecisionStatus"}
        marginRight={0}
        flexDirection={"center"}
      />
    </View>
  );
};

export default DecisionImageButtonGroup;
