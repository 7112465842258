import type { FC, PropsWithChildren } from "react";
import type { DrawerItemType } from "DLUI/drawer/types";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: "100%",
    textDecoration: "none"
  }
});

interface NavigationItemContainerProps {
  navPath: DrawerItemType["navPath"];
  dataCy: DrawerItemType["dataCy"];
  isCustomComponent: boolean;
}

export const NavigationItemContainer: FC<PropsWithChildren<NavigationItemContainerProps>> = ({
  navPath,
  children,
  dataCy,
  isCustomComponent
}) => {
  const classes = useStyles();
  const path = navPath?.[0];
  if (!path || isCustomComponent) {
    return (
      <button data-cy={dataCy} className={clsx(classes.root, "button-reset")}>
        {children}
      </button>
    );
  }

  return (
    <NavLink
      to={{
        pathname: path.name,
        search: new URLSearchParams(path.queryParams || {}).toString()
      }}
      data-cy={dataCy}
      className={classes.root}
    >
      {children}
    </NavLink>
  );
};
