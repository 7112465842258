import { RestApiBase } from "api/restApiBase";
import type { GetAllInsurancePoliciesQuery, InsurancePolicyDto } from "@doorloop/dto";
import { ServerRoutes } from "@doorloop/dto";
import { unitsApi } from "api/unitsApi";
import { tenantsApi } from "api/tenantsApi";
import { propertiesApi } from "api/propertiesApi";
import { leasesApi } from "api/leasesApi";

export class InsurancePoliciesApi extends RestApiBase<InsurancePolicyDto, GetAllInsurancePoliciesQuery> {
  constructor() {
    super(ServerRoutes.INSURANCE_POLICIES, [propertiesApi, unitsApi, tenantsApi, leasesApi]);
    this.restRoute = ServerRoutes.INSURANCE_POLICIES;
  }
}

export const insurancePoliciesApi = new InsurancePoliciesApi();
