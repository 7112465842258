"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TreeListItemWithGroupByValues = void 0;
const treeListItem_1 = require("./treeListItem");
class TreeListItemWithGroupByValues extends treeListItem_1.TreeListItem {
    constructor(init) {
        super(init);
        this.total = init === null || init === void 0 ? void 0 : init.total;
        this.totalWithSubAccounts = init === null || init === void 0 ? void 0 : init.totalWithSubAccounts;
        this.absTotalWithSubAccounts = init === null || init === void 0 ? void 0 : init.absTotalWithSubAccounts;
        this.groupBy = init === null || init === void 0 ? void 0 : init.groupBy;
    }
}
exports.TreeListItemWithGroupByValues = TreeListItemWithGroupByValues;
