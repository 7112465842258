import React, { useEffect, useState } from "react";
import MomentUtils from "@date-io/moment";
import type { DisplayAccountDto } from "@doorloop/dto";
import { AccountClass, AccountOpeningBalanceByPropertyInfo, mathUtils, SegmentEventTypes } from "@doorloop/dto";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { AddIcon, BalanceIcon } from "assets/icons";
import { Button } from "DLUI/button";
import { FormikDatePicker, TextField, ValidationIndicator } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FieldArrayRenderProps, FormikProps } from "formik";
import { FastField, FieldArray, getIn } from "formik";
import AppStrings from "locale/keys";
import moment from "moment";
import { useTranslation } from "react-i18next";
import OpeningBalanceByPropertyLine from "./openingBalanceByPropertyLine";
import { Icon } from "DLUI/icon";
import { Link } from "DLUI/link";
import { Routes } from "components/appRouter";
import { LearnMoreText } from "DLUI/text/learnMoreText";
import { ArticleIdsEnum } from "DLUI/screen/helpPanel/types";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import { useBankConnectEvents } from "../../../../../../hooks/useBankConnectEvents";

interface ComponentProps {
  formikRef: FormikProps<DisplayAccountDto>;
}

const OpeningBalances: React.FC<ComponentProps> = ({ formikRef }: ComponentProps) => {
  const { t } = useTranslation();
  const [accountingStartDate, setAccountingStartDate] = useState<string | undefined>();
  const [showBalanceByProperty, setShowBalanceByProperty] = useState<boolean>(false);

  const [renderLines, setRenderLines] = useState<boolean>(true);
  const company = useSelector((state: IRootState) => state.auth.currentLoginResponse?.currentDbTenant);
  const openingBalanceEntry = getIn(formikRef.values, "openingBalanceEntry");
  const inValidAmounts = getIn(formikRef.values, "invalidOpeningBalanceByProperty");
  const { fireBankConnectEvent } = useBankConnectEvents();

  const didChangeOpeningBalanceDate = () => {
    setShowBalanceByProperty(true);
    formikRef.setFieldValue("openingBalanceInfo.openingBalanceByProperty", [new AccountOpeningBalanceByPropertyInfo()]);
    fireBankConnectEvent(SegmentEventTypes.BANK_CONNECT_DID_SET_OPENING_BALANCE, undefined, {
      trackEventInIntercom: true
    });
  };

  useEffect(() => {
    const accountingStartDate = getIn(formikRef.values, "openingBalanceInfo.openingBalanceDate");
    if (accountingStartDate !== undefined) {
      setAccountingStartDate(moment(accountingStartDate).add(-1, "days").format("YYYY-MM-DD").toString());
    }
  }, [formikRef.values]);

  useEffect(() => {
    if (company?.companyStartDate) {
      formikRef.setFieldValue("openingBalanceInfo.openingBalanceDate", company?.companyStartDate);
      didChangeOpeningBalanceDate();
    }
  }, [company?.companyStartDate]);

  const refreshLines = () => {
    setRenderLines(false);
    setTimeout(() => {
      setRenderLines(true);
    }, 0);
  };

  useEffect(() => {
    if (accountingStartDate !== undefined) {
      refreshLines();
    }
  }, [accountingStartDate]);

  const renderAddButton = (arrayHelpers: FieldArrayRenderProps) => {
    const didPressAddBalance = () => {
      const nextLine = new AccountOpeningBalanceByPropertyInfo();
      arrayHelpers.push(nextLine);
    };

    return (
      <View marginTop={10}>
        <Button
          color={"lightBlue"}
          type={"inlineText"}
          actionText={AppStrings.Prospects.NewProspect.AddAnotherProperty}
          onClick={didPressAddBalance}
          LeftIcon={AddIcon}
          applyColorForIcons
          iconSize={15}
        />
      </View>
    );
  };

  const renderRemainingBalanceIndicator = () => {
    const _remainingBalance = getIn(formikRef.values, "openingBalanceInfo.openingBalance");
    if (!showBalanceByProperty || _remainingBalance === undefined) {
      return null;
    }

    let remainingBalance = Number(_remainingBalance);

    const openingBalanceByProperty: AccountOpeningBalanceByPropertyInfo[] | undefined = getIn(
      formikRef.values,
      "openingBalanceInfo.openingBalanceByProperty"
    );
    if (openingBalanceByProperty) {
      openingBalanceByProperty.forEach((currentLine) => {
        remainingBalance = mathUtils.substract(remainingBalance, currentLine.openingBalance || 0);
      });
    }

    return (
      <View marginBottom={20} marginTop={20} height={50}>
        <View
          backgroundColor={"dark"}
          width={330}
          height={50}
          style={{ position: "absolute", right: 0, top: 0 }}
          borderRadius={25}
          alignItems={"center"}
          flexDirection={"row"}
        >
          <Text
            value={AppStrings.Accounts.ChartOfAccounts.RemainingBalance}
            fontSize={16}
            fontWeight={700}
            marginLeft={20}
          />

          <Text
            fontSize={16}
            fontWeight={700}
            marginLeft={20}
            marginRight={20}
            formatType={"currency"}
            fullWidth
            value={String(remainingBalance)}
          />
        </View>
      </View>
    );
  };

  const renderBalanceBypropertySection = () => {
    if (!renderLines) {
      return null;
    }
    return (
      <FieldArray
        name={"openingBalanceInfo.openingBalanceByProperty"}
        render={(arrayHelpers) => {
          if (
            formikRef.values &&
            formikRef.values.openingBalanceInfo &&
            formikRef.values.openingBalanceInfo.openingBalanceByProperty
          ) {
            const formLines = formikRef.values.openingBalanceInfo.openingBalanceByProperty;

            const didPressDeleteLine = (lineIndex: number) => {
              arrayHelpers.remove(lineIndex);
            };

            const lines = formLines.map((currentLine: AccountOpeningBalanceByPropertyInfo, lineIndex: number) => (
              <View key={"LTL" + lineIndex}>
                <OpeningBalanceByPropertyLine
                  name={`openingBalanceInfo.openingBalanceByProperty[${lineIndex}]`}
                  formikRef={formikRef}
                  didPressDeleteLine={lineIndex !== 0 ? didPressDeleteLine : undefined}
                  lineIndex={lineIndex}
                  accountingStartDate={accountingStartDate}
                  didChangeOpeningBalanceFiled={() => {}}
                />
              </View>
            ));

            return (
              <View shouldShow showAnimation={"fade-in"} hideAnimation={"fade-out"}>
                {lines}
                <View height={80}>{renderAddButton(arrayHelpers)}</View>
              </View>
            );
          }
          return <div />;
        }}
      />
    );
  };

  if (openingBalanceEntry) {
    const editLink =
      formikRef?.values?.class === AccountClass.LIABILITY
        ? `${Routes.REPORTS}/account-register-report/expense/edit/${openingBalanceEntry}`
        : `${Routes.CHART_OF_ACCOUNTS}/bank-deposit/edit/${openingBalanceEntry}`;

    return (
      <View justifyContent={"center"} alignItems={"center"} flex={1}>
        <View
          width={80}
          height={80}
          backgroundColor={"dark"}
          borderRadius={40}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Icon width={40} height={40} Source={BalanceIcon} pathColor={"black"} />
        </View>
        <View justifyContent={"center"} alignItems={"center"}>
          <Text
            value={AppStrings.Accounts.ChartOfAccounts.OpeningBalancesExists}
            color={"black"}
            fontSize={16}
            fontWeight={700}
            marginTop={20}
            align={"center"}
            maxWidth={300}
          />
          <View justifyContent={"center"} marginTop={10} flexDirection={"row"}>
            <Link hrefUrl={editLink} hoverColor={"gray"} type={"blank"}>
              <Text color={"lightBlue"} fontSize={16} value={AppStrings.Common.ClickHere} />
            </Link>
            <Text
              value={AppStrings.Accounts.ChartOfAccounts.OpeningBalancesExistsDescription}
              color={"gray"}
              fontSize={16}
              marginLeft={5}
            />
          </View>
        </View>
      </View>
    );
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <View flex={1}>
        <View>
          <Text
            color={"black"}
            value={AppStrings.Accounts.ChartOfAccounts.OpeningBalancesTitle}
            fontWeight={700}
            fontSize={16}
          />
          <View justifyContent={"flex-start"} alignItems={"flex-start"} marginTop={10}>
            <LearnMoreText
              value={AppStrings.Accounts.ChartOfAccounts.OpeningBalancesDescription}
              articleId={ArticleIdsEnum.CHOOSE_ACCOUNTING_START_DATE}
            />
          </View>
          <View alignItems={"flex-end"} flexDirection={"row"}>
            <Grid item lg={5} md={5} xs={12}>
              <FastField
                component={FormikDatePicker}
                uniqueKey={"billDate"}
                label={AppStrings.Accounts.ChartOfAccounts.AccountingStartDate}
                name={"openingBalanceInfo.openingBalanceDate"}
                noMargin
                required
                marginTop={20}
                onChange={didChangeOpeningBalanceDate}
              />
            </Grid>
          </View>
          <View shouldShow={showBalanceByProperty} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
            <SeparationLine marginTop={20} height={1} width={"100%"} />
            <View marginTop={20} flexDirection={"row"}>
              <Text
                color={"black"}
                fontSize={16}
                value={AppStrings.Accounts.ChartOfAccounts.AccountBalanceDescriptionPart1}
              />
              <Text
                value={accountingStartDate}
                marginLeft={5}
                color={"black"}
                formatType={"date"}
                fontWeight={700}
                fontSize={16}
              />
              <Text fontSize={16} color={"black"} fontWeight={700}>
                ?
              </Text>
            </View>
            <View alignItems={"flex-end"} flexDirection={"row"}>
              <Grid item lg={5} md={5} xs={12}>
                <FastField
                  component={TextField}
                  uniqueKey={"openingBalance"}
                  label={t(AppStrings.Leases.LeaseTransaction.Refund.Accountbalance)}
                  name={"openingBalanceInfo.openingBalance"}
                  noMargin
                  required
                  marginTop={20}
                  formatType={"currency"}
                />
              </Grid>
            </View>
            <SeparationLine marginTop={20} height={1} width={"100%"} />
            <View>
              <Text
                color={"black"}
                value={AppStrings.Accounts.ChartOfAccounts.BalanceByPropertyTitle}
                fontWeight={700}
                marginTop={20}
                fontSize={16}
              />
              <View justifyContent={"flex-start"} flexDirection={"row"} alignItems={"flex-end"} marginTop={20}>
                <LearnMoreText
                  color={"gray"}
                  align={"left"}
                  articleId={ArticleIdsEnum.SETTING_OPENING_BALANCES}
                  maxWidth={"100%"}
                  value={AppStrings.Accounts.ChartOfAccounts.BalanceByPropertyDescription}
                />
              </View>
              {renderBalanceBypropertySection()}
            </View>
          </View>
        </View>
        {renderRemainingBalanceIndicator()}
        <View alignItems={"flex-end"}>
          <ValidationIndicator
            shouldShow={Boolean(inValidAmounts)}
            displayText={t(AppStrings.Accounts.ChartOfAccounts.InvalidOpeningBalanceByProperty)}
            marginTop={"20px"}
            justifyContent={"center"}
            maxWidth={400}
            fullWidth
          />
        </View>
      </View>
    </MuiPickersUtilsProvider>
  );
};

export default OpeningBalances;
