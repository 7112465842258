"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyndicationPropertyContact = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const syndicationCompanyType_1 = require("./syndicationCompanyType");
const syndicationPersonType_1 = require("./syndicationPersonType");
class SyndicationPropertyContact {
    constructor() {
        this.Companies = [];
        this.Persons = [];
    }
}
exports.SyndicationPropertyContact = SyndicationPropertyContact;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => syndicationCompanyType_1.SyndicationCompanyType),
    __metadata("design:type", Array)
], SyndicationPropertyContact.prototype, "Companies", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => syndicationPersonType_1.SyndicationPersonType),
    __metadata("design:type", Array)
], SyndicationPropertyContact.prototype, "Persons", void 0);
