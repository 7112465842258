import { useMemo } from "react";
import range from "lodash/range";
import { DAYS_IN_MONTH, getChargeForRuleInDay } from "DLUI/dialogs/settings/common/lateFees/sampleCalculator/utils";
import sum from "lodash/sum";
import isNil from "lodash/isNil";
import type { LateFeeChargeDto, LateFeesPolicyDto } from "@doorloop/dto";

type UseSampleCalculatorHook = (props: { rent: number; delinquency: number; lateFeeSettings: LateFeesPolicyDto }) => {
  totalChargesBeforeMaxFees: number;
  chargesPerDay: number[];
  chargesPerMonth: number[];
  chargesPerDayWithMaxDailyFee: number[];
  chargesPerMonthWithMaxMonthlyFee: number[];
  chargesPerMaximumDayAndMonthFees: number[];
  totalChargesPerDayWithMaxDailyFees: number;
  totalChargesPerMonthWithMaxMonthlyFees: number;
  totalChargesPerMonthWithMaxMonthlyAndDailyFees: number;
};

export const useSampleCalculator: UseSampleCalculatorHook = ({ rent, delinquency, lateFeeSettings }) => {
  const { lateFeeCharges, maxDailyLateFeesAmount, maxMonthlyLateFeesAmount } = lateFeeSettings;

  const rules: LateFeeChargeDto[] = useMemo(() => lateFeeCharges ?? [], [lateFeeCharges]);

  const chargesPerDay: number[] = useMemo(
    () =>
      range(1, delinquency + 1).map((dayNumber) =>
        sum(rules.map((rule) => getChargeForRuleInDay(rule, dayNumber, rent)))
      ),
    [delinquency, rent, rules]
  );

  const chargesPerMonth: number[] = useMemo(
    () =>
      range(0, Math.ceil(delinquency / DAYS_IN_MONTH)).map((monthNumber) => {
        const startIndex = monthNumber * DAYS_IN_MONTH;
        const endIndex = (monthNumber + 1) * DAYS_IN_MONTH;
        return sum(chargesPerDay.slice(startIndex, endIndex));
      }),
    [chargesPerDay, delinquency]
  );

  const totalChargesBeforeMaxFees = sum(chargesPerDay);

  const chargesPerDayWithMaxDailyFee: number[] = useMemo(
    () =>
      chargesPerDay.map((charge) =>
        !isNil(maxDailyLateFeesAmount) ? Math.min(charge, maxDailyLateFeesAmount) : charge
      ),
    [chargesPerDay, maxDailyLateFeesAmount]
  );

  const chargesPerMonthWithMaxMonthlyFee: number[] = useMemo(
    () =>
      chargesPerMonth.map((charge) =>
        !isNil(maxMonthlyLateFeesAmount) ? Math.min(charge, maxMonthlyLateFeesAmount) : charge
      ),
    [chargesPerMonth, maxMonthlyLateFeesAmount]
  );

  const chargesPerMaximumDayAndMonthFees = useMemo(
    () =>
      range(0, Math.ceil(delinquency / DAYS_IN_MONTH)).map((monthNumber) => {
        const startIndex = monthNumber * DAYS_IN_MONTH;
        const endIndex = (monthNumber + 1) * DAYS_IN_MONTH;
        const monthlySum = sum(chargesPerDayWithMaxDailyFee.slice(startIndex, endIndex));
        return maxMonthlyLateFeesAmount ? Math.min(monthlySum, maxMonthlyLateFeesAmount) : monthlySum;
      }),
    [chargesPerDayWithMaxDailyFee, delinquency, maxMonthlyLateFeesAmount]
  );

  return {
    totalChargesBeforeMaxFees,
    chargesPerDay,
    chargesPerMonth,
    chargesPerDayWithMaxDailyFee,
    chargesPerMonthWithMaxMonthlyFee,
    chargesPerMaximumDayAndMonthFees,
    totalChargesPerDayWithMaxDailyFees: sum(chargesPerDayWithMaxDailyFee),
    totalChargesPerMonthWithMaxMonthlyFees: sum(chargesPerMonthWithMaxMonthlyFee),
    totalChargesPerMonthWithMaxMonthlyAndDailyFees: sum(chargesPerMaximumDayAndMonthFees)
  };
};
