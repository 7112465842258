import { useQuery } from "@tanstack/react-query";
import { createQueryStore } from "./createQueryStore";

import type { BaseDto, GetAllBaseQueryRequest } from "@doorloop/dto";
import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import type { RestApiBaseWithDictionary } from "api/restApiBaseWithDictionary";
import type { QueryStoreReturn } from "./createQueryStore.types";

interface QueryStoreWithDictionaryQueries<TDto> {
  useGetAllFromDictionary: (options?: UseQueryOptions<unknown, unknown, TDto[]>) => UseQueryResult<TDto[], unknown>;
  useGetOneFromDictionary: (
    id?: string,
    options?: UseQueryOptions<unknown, unknown, TDto>
  ) => UseQueryResult<TDto, unknown>;
  useGetDictionary: (
    options?: UseQueryOptions<unknown, unknown, Record<string, TDto>>
  ) => UseQueryResult<Record<string, TDto>, unknown>;
}

type QueryStoreWithDictionaryReturn<TDto extends BaseDto, TGetAllDto extends GetAllBaseQueryRequest> = QueryStoreReturn<
  TDto,
  TGetAllDto
> &
  QueryStoreWithDictionaryQueries<TDto>;

export function createQueryStoreWithDictionary<TDto extends BaseDto, TGetAllDto extends GetAllBaseQueryRequest>(
  baseKey: string,
  api: RestApiBaseWithDictionary<TDto, TGetAllDto>
): QueryStoreWithDictionaryReturn<TDto, TGetAllDto> {
  return {
    ...createQueryStore(baseKey, api),
    useGetAllFromDictionary: (options) =>
      useQuery({
        queryKey: [`${baseKey}-dictionary-query-get-all`],
        queryFn: async ({ signal }) => {
          const data = await api.getAllFromDictionary();
          if (!data || signal?.aborted) {
            return null;
          }
          return data;
        },
        ...options
      }),
    useGetOneFromDictionary: (id, options) =>
      useQuery({
        queryKey: [`${baseKey}-dictionary-query-get-one`, id],
        queryFn: async ({ signal }) => {
          if (!id) {
            return null;
          }

          const data = await api.getFromDictionaryOrFetch(id);
          if (!data || signal?.aborted) {
            return null;
          }

          return data;
        },
        ...options
      }),
    useGetDictionary: (options) =>
      useQuery({
        queryKey: [`${baseKey}-dictionary-query-get-dictionary`],
        queryFn: async ({ signal }) => {
          const data = await api.getDictionary();
          if (!data || signal?.aborted) {
            return null;
          }
          return data;
        },
        ...options
      })
  };
}
