import { BreakpointDown, isBreakpointDown } from "@/contexts/utils";

import type { PopperOptions } from "popper.js";

// Mui doesn't type this prop so we need to cast it manually; If it has to be
// dynamic through props, move it inside the component. Yes, we can place it
// in the global scope, but it hinders readability.
export const popperOptions: PopperOptions = {
  modifiers: {
    preventOverflow: {
      padding: 0
    },
    // Docs say this modifier is enabled by default, but it's not.
    // See https://popper.js.org/docs/v1/#modifiers..keepTogether.enabled
    keepTogether: {
      enabled: true
    },
    computeStyle: {
      fn: (data) => {
        if (isBreakpointDown(BreakpointDown.MD)) {
          data.styles = {
            // Position `fixed` ensure the dropdown fills the entire screen on mobile
            position: "fixed",
            width: "100%",
            // https://blog.logrocket.com/improving-mobile-design-latest-css-viewport-units/
            height: `calc(100dvh - ${data.instance.reference.clientHeight}px)`,
            top: `${data.instance.reference.clientHeight}px`,
            left: `${data.offsets.reference.left}px`,
            bottom: `${data.instance.reference.clientHeight}px`
          } as CSSStyleDeclaration;
        } else {
          data.styles = {
            width: `${data.offsets.reference.width}px`,
            top: `${data.offsets.reference.top + data.offsets.reference.height}px`,
            left: `${data.offsets.reference.left}px`
          } as CSSStyleDeclaration;
        }
        return data;
      }
    }
  }
};
