import React from "react";
import type { FormikProps } from "formik";
import { FastField } from "formik";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import Text from "DLUI/text";
import { FormikDatePicker, TextField } from "DLUI/form";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";

interface ComponentProps {
  formikRef: FormikProps<any>;
}

const Insurance: React.FC<ComponentProps> = ({ formikRef }: ComponentProps) => {
  const { t } = useTranslation();
  const { isTabletOrMobile } = useResponsiveHelper();
  const paddingRight = isTabletOrMobile ? 0 : 20;
  const marginTop = isTabletOrMobile ? 20 : 0;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <View flex={1}>
        <Text value={AppStrings.Vendors.NewVendor.InsuranceDescription} color={"black"} fontSize={16} />
        <View marginTop={20} marginBottom={20}>
          <FastField
            component={TextField}
            label={t(AppStrings.Vendors.NewVendor.Provider)}
            name={`insuranceInfo.provider`}
            required
          />
        </View>
        <Grid container>
          <Grid item xs={12} md={6}>
            <FastField
              component={TextField}
              label={t(AppStrings.Vendors.NewVendor.PolicyNumber)}
              name={`insuranceInfo.policyNumber`}
              required
              paddingRight={paddingRight}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FastField
              component={TextField}
              label={t(AppStrings.Vendors.NewVendor.Coverage)}
              name={`insuranceInfo.coverage`}
              formatType={"currency"}
              decimalScale={2}
              marginTop={marginTop}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <View marginTop={20}>
              <FastField
                component={FormikDatePicker}
                label={AppStrings.Vendors.NewVendor.EffectiveDate}
                name={`insuranceInfo.effectiveDate`}
                uniqueKey={"effectiveDate"}
                paddingRight={paddingRight}
                required
                noMargin
              />
            </View>
          </Grid>
          <Grid item xs={12} md={6}>
            <View marginTop={20}>
              <FastField
                component={FormikDatePicker}
                label={AppStrings.Vendors.NewVendor.ExpirationDate}
                name={`insuranceInfo.expirationDate`}
                uniqueKey={"expirationDate"}
                required
                noMargin
              />
            </View>
          </Grid>
        </Grid>
      </View>
    </MuiPickersUtilsProvider>
  );
};

export default Insurance;
