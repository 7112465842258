import React, { createRef, useState } from "react";
import clsx from "clsx";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import type { DropzoneProps, DropzoneRef } from "react-dropzone";
import Dropzone from "react-dropzone";
import type { FileListItemProps, FileRetryMethod } from "./fileListItem";
import FilesList from "./filesList";
import makeStyles from "./styles";
import DropZoneInstructionsView from "DLUI/dropZone/dropZoneInstructionsView";
import { FileFilterTypes, FileErrorTypes } from "@doorloop/dto";
import { fileExtensionsMap, generateUploadError } from "DLUI/dropZone/utils";
import WarningView from "DLUI/form/warningView/warningView";

const DefaultComponentHeight = "204px";
export const DefaultLottieHeight = 80;

interface ComponentProps extends DropzoneProps {
  onFileReceive: (acceptedFiles: any[]) => void;
  files: FileListItemProps[];
  onDeleteItem: (itemIndex: number) => void;
  onRetryPress?: (fileIndex: number, retryMethod: FileRetryMethod) => void;
  height?: number;
  loadingFiles?: boolean;
  isRequired?: boolean;
  allowDelete?: boolean;
  instructionsViewDirection?: "row" | "column";
  backgroundColor?: "dark" | "white";
  lottieHeight?: number;
  maxFiles?: number;
  error?: string;
  displayType?: "small" | "large";
  allowedFileExt?: FileFilterTypes;
  dataCy?: string;
}

const DropZone: React.FC<ComponentProps> = ({
  onFileReceive,
  files,
  onDeleteItem,
  height,
  loadingFiles,
  onRetryPress,
  instructionsViewDirection,
  backgroundColor,
  lottieHeight,
  displayType,
  maxFiles = 0,
  allowedFileExt = FileFilterTypes.GENERAL,
  error,
  isRequired = true,
  allowDelete = false,
  dataCy
}: ComponentProps) => {
  const [errorType, setErrorType] = useState<FileErrorTypes | undefined>();
  const [erroredFile, setErroredFile] = useState<string>("");
  const classes = makeStyles();
  const fileAllowedExtensions = fileExtensionsMap?.[allowedFileExt] ?? fileExtensionsMap[FileFilterTypes.GENERAL];
  const errorMessage = generateUploadError(errorType);
  const dropzoneRef = createRef<DropzoneRef>();

  const _onFileReceive = (acceptedFiles: any[], erroredFiles: any[]) => {
    clearErrorState();
    if (maxFiles === 1 && files?.length > 0) {
      setErrorType(FileErrorTypes.MULTIPLE_NOT_ALLOWED_WARNING);
      setErroredFile(acceptedFiles[0]?.name || erroredFiles[0]?.name);
    } else if (maxFiles === 1 && (acceptedFiles?.length > 1 || erroredFiles?.length > 1)) {
      setErrorType(FileErrorTypes.MULTIPLE);
    } else if (erroredFiles?.length > 1) {
      setErrorType(FileErrorTypes.MULTIPLE_TYPE_ERROR);
    } else if (erroredFiles?.length === 1) {
      setErrorType(erroredFiles?.[0]?.name);
      setErroredFile(erroredFiles?.[0]?.name);
    } else {
      onFileReceive(acceptedFiles);
    }
  };

  const clearErrorState = () => {
    setErrorType(undefined);
    setErroredFile("");
  };

  const handleDeleteFile = (itemIndex: number) => {
    clearErrorState();
    onDeleteItem?.(itemIndex);
  };

  const renderSelectedFiles = () => {
    if (shouldShowInstructions()) {
      return;
    }
    return (
      <FilesList
        onDeleteItem={handleDeleteFile}
        filesItemsArray={files}
        onRetryPress={onRetryPress}
        displayType={displayType}
        itemHeight={height}
        maxFiles={maxFiles}
        allowDelete={allowDelete}
      />
    );
  };

  const shouldShowInstructions = (): boolean => {
    let numberOfFilesToDisplay = 0;
    if (files.length === 0) {
      return true;
    }
    files.forEach((currentFile) => {
      if (
        !currentFile.fileUploadState ||
        currentFile.fileUploadState === "WaitingForUpload" ||
        currentFile.fileUploadState === "UploadInProgress" ||
        currentFile.fileUploadState === "UploadFailed" ||
        currentFile.fileUploadState === "WaitingForDelete"
      ) {
        numberOfFilesToDisplay++;
      }
    });
    return numberOfFilesToDisplay === 0;
  };

  return (
    <View>
      <Dropzone noDragEventsBubbling noClick onDrop={_onFileReceive} ref={dropzoneRef} accept={fileAllowedExtensions}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            onClick={() => {
              if (dropzoneRef.current && (maxFiles === 0 || files.length < maxFiles)) {
                dropzoneRef.current.open();
              }
            }}
            className={clsx([
              classes.componentContainer,
              error ? "errored" : "",
              backgroundColor && backgroundColor === "dark" ? classes.darkBackground : ""
            ])}
          >
            <div
              style={{ minHeight: height ? height : DefaultComponentHeight }}
              className={classes.dropzoneRootComponent}
              {...getRootProps()}
            >
              <input {...getInputProps()} accept={fileAllowedExtensions} />

              <DropZoneInstructionsView
                shouldShow={shouldShowInstructions()}
                isDragActive={isDragActive}
                loadingFiles={loadingFiles}
                height={lottieHeight}
                isRequired={isRequired}
                instructionsViewDirection={instructionsViewDirection}
              />

              {renderSelectedFiles()}
            </div>
          </div>
        )}
      </Dropzone>
      {errorType === FileErrorTypes.MULTIPLE_NOT_ALLOWED_WARNING ? (
        <WarningView iconSize={20} marginTop={10}>
          <Text value={errorMessage} color={"yellow"} fontSize={14} replaceObject={{ fileName: erroredFile }} />
        </WarningView>
      ) : (
        errorType && (
          <WarningView iconSize={20} marginTop={10} type={"danger"}>
            <Text value={errorMessage} replaceObject={{ fileName: erroredFile }} color={"error"} fontSize={14} />
          </WarningView>
        )
      )}
    </View>
  );
};

export default DropZone;
