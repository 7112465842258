import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { View } from "DLUI/view";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DialogFrame, { getDialogFrameDimension } from "../components/dialogFrame";
import AppStrings from "../../../../locale/keys";
import Text from "DLUI/text";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { NotificationSettingsContextWrapper } from "DLUI/dialogs/notifications/components/notificationSettingsContextWrapper";
import { LoginResponseType, UserNotificationSettingsDto } from "@doorloop/dto";
import { useEffectAsync } from "../../../../hooks/useEffectAsync";
import { usersApi } from "api/usersApi";
import type { FormikProps } from "formik";
import { Form, Formik } from "formik";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";

import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { useAnalyticsService } from "@/hooks/useAnalyticsService";

interface ComponentProps {
  onBackdropClick: () => void;
  dialogTitle?: string;
}

enum NotificationSettingsDialogViews {
  Done,
  Loading
}

let formikGlobalRef: FormikProps<UserNotificationSettingsDto> | null = null;

const NotificationSettingsDialog: React.FC<ComponentProps> = ({ onBackdropClick, dialogTitle }: ComponentProps) => {
  const { dispatchAnalytics } = useAnalyticsService();
  const { isMobile } = useResponsiveHelper();
  const { t } = useTranslation();
  const [loadingState, setLoadingState] = useState<DialogState>(DialogState.Show);
  const [viewIndex, setViewIndex] = useState(NotificationSettingsDialogViews.Loading);
  const [errorText, setErrorText] = useState<string>();
  const [settings, setSettings] = useState<UserNotificationSettingsDto>();

  useEffectAsync(async () => {
    await handleInitializeDialog();
  }, []);

  const handleInitializeDialog = async () => {
    const response = await usersApi.getUserNotificationsSettings();

    if (response?.status) {
      const data = new UserNotificationSettingsDto({ ...response.data });
      setSettings(data);
      setViewIndex(NotificationSettingsDialogViews.Done);
    } else {
      setErrorText(response.message);
      setLoadingState(DialogState.Error);
    }
  };

  const handleSaveClick = async () => {
    if (formikGlobalRef?.values) {
      setViewIndex(NotificationSettingsDialogViews.Loading);
      const res = await usersApi.updateUserNotificationsSettings(formikGlobalRef.values);

      if (res.status) {
        dispatchAnalytics("notification_center_settings_saved");
        onBackdropClick();
      } else {
        setErrorText(res.message || t(AppStrings.Common.GeneralError));
        setLoadingState(DialogState.Error);
      }
    }
  };

  const renderForm = () => (
    <View
      alignItems={"center"}
      paddingTop={5}
      gap={10}
      paddingBottom={10}
      paddingRight={20}
      paddingLeft={20}
      flex={1}
      width={"100%"}
      noWrap
    >
      {settings && (
        <Formik initialValues={settings} onSubmit={() => {}}>
          {(formik) => {
            formikGlobalRef = formik;

            return (
              <Form style={{ width: "100%", height: "100%" }}>
                <NotificationSettingsContextWrapper userType={LoginResponseType.USER} />
              </Form>
            );
          }}
        </Formik>
      )}
    </View>
  );

  const renderView = ({ index }: any) => {
    if (index === NotificationSettingsDialogViews.Done && loadingState === DialogState.Show) {
      return (
        <View>
          <View paddingBottom={10} paddingTop={10} paddingRight={20} paddingLeft={20}>
            <Text color={"gray"} value={AppStrings.Notifications.Settings.Subtitle} />

            <HorizontalSeparationLine height={1} marginTop={10} />
          </View>
          {renderForm()}
        </View>
      );
    }
    return (
      <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
        <LoadingDialog
          didPressDismissButton={_onBackdropClick}
          dialogState={loadingState}
          errorText={errorText || ""}
          successText={t(AppStrings.Notifications.Settings.SaveSuccess)}
        />
      </View>
    );
  };

  const _onBackdropClick = () => onBackdropClick?.();

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={dialogTitle}
      width={getDialogFrameDimension("width", 980)}
      height={getDialogFrameDimension("height", 721)}
      renderView={renderView}
      numViews={2}
      activeView={viewIndex}
      frameType={viewIndex === NotificationSettingsDialogViews.Done ? "sectionTitleFrame" : "contentOnly"}
      RenderActionPanelButtons={() => (
        <FormActionButtons
          propsSubButton={{ onClick: _onBackdropClick }}
          propsMainButton={{ type: "cta", props: { onClick: handleSaveClick } }}
        />
      )}
    />
  );
};

export default NotificationSettingsDialog;
