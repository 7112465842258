import _ from "lodash";
import { treeListSubItemsField } from "../../utils";
import type { TreeListItemAggregated, TreeListState } from "DLUI/lists/treeList/useTreeList";

interface GetTreeListItemsPathOfItemProps {
  initialData: TreeListItemAggregated[];
  item?: { typeId?: string; parentTypeId?: string };
}

const getTreeListItemsPathOfItem = ({ initialData, item }: GetTreeListItemsPathOfItemProps) => {
  const itemPath = initialData.find((data) => data.typeId === item?.typeId);

  if (!itemPath) {
    return [];
  }
  if (!item?.parentTypeId) {
    return [itemPath];
  }
  const parent = initialData.find((data) => data.typeId === item.parentTypeId);

  const path = getTreeListItemsPathOfItem({ initialData, item: parent });

  return [...path, itemPath];
};

interface BuildTreeListPathProps {
  itemsPath: TreeListItemAggregated[];
  dataSource?: TreeListItemAggregated[];
}

const buildTreeListPath = ({ itemsPath, dataSource }: BuildTreeListPathProps): string[] => {
  if (!itemsPath.length || !dataSource) {
    return [];
  }
  const itemPath = itemsPath[0];
  const index = dataSource.findIndex((data) => data.typeId === itemPath.typeId);
  const item = dataSource[index];

  if (itemsPath.length === 1) {
    return [index.toString()];
  }
  const subPath = buildTreeListPath({ itemsPath: itemsPath.slice(1), dataSource: item[treeListSubItemsField] });
  return [index.toString(), treeListSubItemsField, ...subPath];
};

interface GetTreeListItemPathProps {
  initialData: TreeListState["initialData"];
  dataSource: TreeListState["dataSource"];
  item?: GetTreeListItemsPathOfItemProps["item"];
}

const getTreeListItemPath = ({ initialData, dataSource, item }: GetTreeListItemPathProps) => {
  const itemsPath = getTreeListItemsPathOfItem({ initialData, item });

  return buildTreeListPath({ itemsPath, dataSource });
};

interface GetTreeListFocusItemProps {
  expanded: TreeListState["expanded"];
  dataSource: TreeListState["dataSource"];
  pathList: string[];
  isPrevious: boolean;
  isPreventDefault?: boolean;
}

const getTreeListFocusItem = ({
  expanded,
  dataSource,
  pathList,
  isPrevious,
  isPreventDefault
}: GetTreeListFocusItemProps): TreeListItemAggregated | undefined => {
  if (!pathList.length) {
    return;
  }

  const isCurrentItemFirstLevel = pathList.length === 1;
  const CurrentItemIndex = parseInt(pathList[pathList.length - 1]);
  const currentSiblingsList: TreeListItemAggregated[] = isCurrentItemFirstLevel
    ? dataSource
    : _.get(dataSource, pathList.slice(0, -1));
  const currentItem: TreeListItemAggregated | undefined = currentSiblingsList[CurrentItemIndex];

  if (!currentItem) {
    return;
  }

  const currentChildren = currentItem[treeListSubItemsField]?.filter(
    (child) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      !child.footer
  );
  const isCurrentItemRoot = currentItem.type === "category";
  // eslint-disable-next-line no-implicit-coercion
  const isCurrentItemExpanded = !!currentItem.typeId && expanded[currentItem.typeId];

  if (isPrevious) {
    if (isPreventDefault) {
      if (currentChildren?.length && isCurrentItemExpanded) {
        const lastChildPath = [...pathList, treeListSubItemsField, `${currentChildren.length - 1}`];

        return getTreeListFocusItem({
          expanded,
          dataSource,
          pathList: lastChildPath,
          isPrevious: true,
          isPreventDefault: true
        });
      }
      if (isCurrentItemRoot) {
        return getTreeListFocusItem({
          expanded,
          dataSource,
          pathList,
          isPrevious: true
        });
      }
      return currentItem;
    }
    const isFirstSibling = !CurrentItemIndex;

    if (isFirstSibling) {
      if (isCurrentItemFirstLevel) {
      } else {
        const currentParentPath = pathList.slice(0, -2);
        const currentParent = _.get(dataSource, currentParentPath);

        const isCurrentParentRoot = currentParent?.type === "category";

        if (isCurrentParentRoot) {
          return getTreeListFocusItem({
            expanded,
            dataSource,
            pathList: currentParentPath,
            isPrevious: true
          });
        }
        return _.get(dataSource, currentParentPath);
      }
    } else {
      const previousItemIndex = CurrentItemIndex - 1;
      const previousItemPath = [...pathList.slice(0, -1), previousItemIndex.toString()];

      return getTreeListFocusItem({
        expanded,
        dataSource,
        pathList: previousItemPath,
        isPrevious: true,
        isPreventDefault: true
      });
    }
  } else {
    if (currentChildren?.length && isCurrentItemExpanded && !isPreventDefault) {
      const currentFirstChild = currentChildren[0];

      if (currentFirstChild.type === "category") {
        return getTreeListFocusItem({
          expanded,
          dataSource,
          pathList: [...pathList, treeListSubItemsField, "0"],
          isPrevious: false
        });
      }
      return currentChildren[0];
    }
    const isLastInSibling = CurrentItemIndex === currentSiblingsList.filter((sibling) => !sibling.footer).length - 1;

    if (isLastInSibling) {
      if (isCurrentItemFirstLevel) {
      } else {
        const currentParentPath = pathList.slice(0, -2);

        return getTreeListFocusItem({
          expanded,
          dataSource,
          pathList: currentParentPath,
          isPrevious: false,
          isPreventDefault: true
        });
      }
    } else {
      const nextItemIndex = CurrentItemIndex + 1;

      if (isCurrentItemFirstLevel) {
        return getTreeListFocusItem({
          expanded,
          dataSource,
          pathList: [nextItemIndex.toString()],
          isPrevious: false
        });
      }
      if (isCurrentItemRoot) {
        const nextPathList = pathList.slice(0, -1);
        nextPathList.push(nextItemIndex.toString());

        return getTreeListFocusItem({
          expanded,
          dataSource,
          pathList: nextPathList,
          isPrevious: false
        });
      }
      return currentSiblingsList[nextItemIndex];
    }
  }
};

interface HandleTreeListFocusCellProps extends GetTreeListItemPathProps {
  expanded: TreeListState["expanded"];
  isPrevious: boolean;
}

const handleTreeListFocusCell = ({
  isPrevious,
  initialData,
  dataSource,
  item,
  expanded
}: HandleTreeListFocusCellProps) => {
  const pathList = getTreeListItemPath({ initialData, dataSource, item });

  return getTreeListFocusItem({ isPrevious, pathList, dataSource, expanded });
};

const getFixedNumber = (value: number) => Math.round((value + Number.EPSILON) * 100) / 100;

export { handleTreeListFocusCell, getTreeListItemPath, getFixedNumber };
