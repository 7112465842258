import type { LeaseTransactionWithUnitBaseDto, LeaseDto, UnitDto } from "@doorloop/dto";
import type { EditableTableColumn } from "DLUI/editableTable/editableTable";
import { TextField } from "DLUI/form";
import { leasesApi } from "api/leasesApi";
import { unitsApi } from "api/unitsApi";
import { FastField } from "formik";
import AppStrings from "locale/keys";
import React from "react";

/**
 * Columns of the form table
 * Unit, size, lease, memo, amount
 */
export const EditableTransactionTableColumns: ReadonlyArray<EditableTableColumn<LeaseTransactionWithUnitBaseDto>> = [
  { field: "checkbox", headerName: "", gridSize: "auto", isDisabled: (dto) => !dto.lease },
  {
    field: "unit",
    headerName: AppStrings.Common.Unit,
    gridSize: 2,
    valueGetter: (dto, t) =>
      (unitsApi.getItemFromDictionary(dto.unitId) as UnitDto)?.name ?? t(AppStrings.Common.NotAvailableAbbreviated),
    isDisabled: (dto) => !dto.lease
  },
  {
    field: "size",
    headerName: AppStrings.Units.NewUnitDialog.Size,
    gridSize: 1,
    valueGetter: (dto, t) => {
      const size = (unitsApi.getItemFromDictionary(dto.unitId) as UnitDto)?.size;
      return size ? `${size} ${t(AppStrings.Units.ListItem.Sqft)}` : "";
    },
    isDisabled: (dto) => !dto.lease
  },
  {
    field: "lease",
    headerName: AppStrings.Leases.ListHeader.Lease,
    gridSize: 3,
    valueGetter: (dto, t) => {
      if (!dto.lease) return t(AppStrings.Units.FilterValues.Vacant);
      return (leasesApi.getItemFromDictionary(dto.lease) as LeaseDto)?.name ?? "";
    },
    isDisabled: (dto) => !dto.lease
  },
  {
    field: "memo",
    headerName: AppStrings.Common.ListHeader.Description,
    gridSize: 3,
    hideIfUnselected: true,
    renderEditableCell: (dto, index, t, isDisabled) => (
      <FastField
        width={"100%"}
        name={`transactions[${index}].lines[0].memo`}
        component={TextField}
        hideErrorText
        label={t(AppStrings.Common.ListHeader.Description)}
      />
    )
  },
  {
    field: "amount",
    headerName: AppStrings.Leases.NewLease.LeaseRent.Amount,
    gridSize: true,
    hideIfUnselected: true,
    renderEditableCell: (dto, index, t, isDisabled) => (
      <FastField
        name={`transactions[${index}].lines[0].amount`}
        formatType={"currency"}
        component={TextField}
        hideErrorText
        label={t(AppStrings.Leases.NewLease.LeaseRent.Amount)}
      />
    )
  }
] as const;
