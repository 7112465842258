import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";
import { lazy } from "react";

const ManagementFeesScreen = lazy(() => import("screens/managementFees/managementFeesScreen"));

const ManagementFeesRouter = () => (
  <PageRouter>
    <LazyRoute path={Routes.MANAGEMENT_FEES_GLOBAL_NEW} render={ManagementFeesScreen} />
    <LazyRoute path={Routes.MANAGEMENT_FEES} render={ManagementFeesScreen} exact />
  </PageRouter>
);

export default ManagementFeesRouter;
