import React from "react";
import { View } from "DLUI/view";
import { PersonInfo } from "DLUI/person";
import type { FormikProps } from "formik";
import FieldWrapper from "DLUI/form/textField/fieldWrapper";
import { Select, TextField } from "DLUI/form";
import AppStrings from "../../../../locale/keys";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { AccountingExperienceLevel, Purposes } from "@doorloop/dto";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import Text from "DLUI/text";

interface ComponentProps {
  onFileReceive?: (receivedFile: File) => void;
  onFileDelete?: () => void;
  formikRef: FormikProps<any>;
  hideNotesSection?: boolean;
}

export const SettingsPersonalInfo = ({ onFileReceive, onFileDelete, formikRef, hideNotesSection }: ComponentProps) => {
  const { isMobile } = useResponsiveHelper();
  const { t } = useTranslation();
  return (
    <View flex={1} width={"100%"}>
      <PersonInfo
        formikRef={formikRef}
        onFileReceive={onFileReceive}
        onFileDelete={onFileDelete}
        hideNotesSection={hideNotesSection}
      />
      <Grid container>
        <Grid item xs={12} md={6} sm={6}>
          <FieldWrapper type={"endElement"}>
            <Text value={AppStrings.Common.PurposeTitle} fontSize={14} bold />
            <FastFieldSafe
              name={"selectedPurpose"}
              component={Select}
              displayEmpty
              label={AppStrings.Common.GoalsAndObjectives}
              selectionEnum={Purposes}
              translationKey={"purposes"}
              marginTop={10}
              uniqueKey={"purposesSelector"}
              useEnumValue
            />
          </FieldWrapper>
        </Grid>
        <Grid item xs={12} md={6} sm={6}>
          <View
            shouldShow={formikRef.values.selectedPurpose === Purposes.OTHER}
            showAnimation={"fade-in"}
            hideAnimation={"fade-out"}
            alignItems={"flex-end"}
            minHeight={80}
          >
            {!isMobile && <View height={10} />}
            <FieldWrapper type={"startElement"}>
              <FastFieldSafe
                component={TextField}
                label={t(AppStrings.Common.FillInYourGoals)}
                name={`purpose`}
                required
              />
            </FieldWrapper>
          </View>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FieldWrapper type={"endElement"}>
            <Text value={AppStrings.Common.ExperienceTitle} fontSize={14} bold />
            <FastFieldSafe
              name={"accountingExperienceLevel"}
              marginTop={10}
              component={Select}
              displayEmpty
              label={AppStrings.Common.RateYourExperience}
              selectionEnum={AccountingExperienceLevel}
              useEnumValue
              uniqueKey={"experienceSelector"}
            />
          </FieldWrapper>
        </Grid>
      </Grid>
    </View>
  );
};
