import React from "react";
import { ListEndIndicator } from "./listEndIndicator";
import { ListLoadingIndicator } from "./listLoadingIndicator";

interface EndOfListRowProps<TDto> {
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  rows: TDto[];
  hideEndOfList?: boolean;
}

export function EndOfListRow<TDto>({ hasNextPage, isFetchingNextPage, rows, hideEndOfList }: EndOfListRowProps<TDto>) {
  if (isFetchingNextPage) {
    return <ListLoadingIndicator />;
  }

  if (!hasNextPage && rows.length > 10 && !hideEndOfList) {
    return <ListEndIndicator />;
  }

  return null;
}
