import type { GetAllBaseQueryRequest, PersonDto } from "@doorloop/dto";
import { CommunicationLinkedToType, LinkedResourceType } from "@doorloop/dto";
import { usersApi } from "api/usersApi";
import { ownersApi } from "api/ownersApi";
import { vendorsApi } from "api/vendorsApi";
import { tenantsApi } from "api/tenantsApi";
import type { RestApiBaseWithDictionary } from "api/restApiBaseWithDictionary";
import AppStrings from "../locale/keys";

export const LinkedResourceTypeApiMap: Record<
  CommunicationLinkedToType,
  RestApiBaseWithDictionary<any, GetAllBaseQueryRequest> | undefined
> = {
  [CommunicationLinkedToType.USER]: usersApi,
  [CommunicationLinkedToType.OWNER]: ownersApi,
  [CommunicationLinkedToType.VENDOR]: vendorsApi,
  [CommunicationLinkedToType.TENANT]: tenantsApi,
  [CommunicationLinkedToType.SYSTEM]: undefined
};

export const buildRelatedTypeFoundName = (
  relatedApi?: RestApiBaseWithDictionary<PersonDto, GetAllBaseQueryRequest>,
  linkedId?: string
) => {
  const dictionaryName = linkedId ? relatedApi?.getItemFromDictionary(linkedId)?.name : "";

  return dictionaryName === linkedId ? "" : dictionaryName;
};

export const getApiByLinkedType = (linkedType: CommunicationLinkedToType) =>
  LinkedResourceTypeApiMap[linkedType] || LinkedResourceTypeApiMap[CommunicationLinkedToType.USER];

const LinkedResourceTypeText: Record<LinkedResourceType, string> = {
  [LinkedResourceType.LeaseCharge]: AppStrings.Common.Enums.LinkedResourceType.LeaseCharge,
  [LinkedResourceType.LeasePayment]: AppStrings.Common.Enums.LinkedResourceType.LeasePayment,
  [LinkedResourceType.LeaseReversedPayment]: AppStrings.Common.Enums.LinkedResourceType.LeaseReversedPayment,
  [LinkedResourceType.LeaseRefund]: AppStrings.Common.Enums.LinkedResourceType.LeaseRefund,
  [LinkedResourceType.LeaseCredit]: AppStrings.Common.Enums.LinkedResourceType.LeaseCredit,
  [LinkedResourceType.Expense]: AppStrings.Common.Enums.LinkedResourceType.Expense,
  [LinkedResourceType.VendorBill]: AppStrings.Common.Enums.LinkedResourceType.VendorBill,
  [LinkedResourceType.VendorBillPayment]: AppStrings.Common.Enums.LinkedResourceType.VendorBillPayment,
  [LinkedResourceType.Deposit]: AppStrings.Common.Enums.LinkedResourceType.Deposit,
  [LinkedResourceType.GeneralEntry]: AppStrings.Common.Enums.LinkedResourceType.GeneralEntry,
  [LinkedResourceType.Reconciliation]: AppStrings.Common.Enums.LinkedResourceType.Reconciliation,
  [LinkedResourceType.Transfer]: AppStrings.Common.Enums.LinkedResourceType.Transfer,
  [LinkedResourceType.ManagementFee]: AppStrings.Common.Enums.LinkedResourceType.ManagementFee,
  [LinkedResourceType.OwnerContribution]: AppStrings.Common.Enums.LinkedResourceType.OwnerContribution,
  [LinkedResourceType.OwnerDistribution]: AppStrings.Common.Enums.LinkedResourceType.OwnerDistribution,
  [LinkedResourceType.VendorCredit]: AppStrings.Common.Enums.LinkedResourceType.VendorCredit,
  [LinkedResourceType.Tenant]: AppStrings.Common.Enums.LinkedResourceType.Tenant,
  [LinkedResourceType.Prospect]: AppStrings.Common.Enums.LinkedResourceType.Prospect,
  [LinkedResourceType.Property]: AppStrings.Common.Enums.LinkedResourceType.Property,
  [LinkedResourceType.Unit]: AppStrings.Common.Enums.LinkedResourceType.Unit,
  [LinkedResourceType.Owner]: AppStrings.Common.Enums.LinkedResourceType.Owner,
  [LinkedResourceType.File]: AppStrings.Common.Enums.LinkedResourceType.File,
  [LinkedResourceType.Lease]: AppStrings.Common.Enums.LinkedResourceType.Lease,
  [LinkedResourceType.LeaseDraft]: AppStrings.Common.Enums.LinkedResourceType.LeaseDraft,
  [LinkedResourceType.Vendor]: AppStrings.Common.Enums.LinkedResourceType.Vendor,
  [LinkedResourceType.User]: AppStrings.Common.Enums.LinkedResourceType.User,
  [LinkedResourceType.LeaseRenewalOffer]: AppStrings.Common.Enums.LinkedResourceType.LeaseRenewalOffer,
  [LinkedResourceType.Note]: AppStrings.Common.Enums.LinkedResourceType.Note,
  [LinkedResourceType.RecurringTransaction]: AppStrings.Common.Enums.LinkedResourceType.RecurringTransaction,
  [LinkedResourceType.RecurringTransactionRent]: AppStrings.Common.Enums.LinkedResourceType.RecurringTransactionRent,
  [LinkedResourceType.RecurringTransactionCharge]:
    AppStrings.Common.Enums.LinkedResourceType.RecurringTransactionCharge,
  [LinkedResourceType.RecurringTransactionCredit]:
    AppStrings.Common.Enums.LinkedResourceType.RecurringTransactionCredit,
  [LinkedResourceType.RecurringTransactionPayment]:
    AppStrings.Common.Enums.LinkedResourceType.RecurringTransactionPayment,
  [LinkedResourceType.RecurringTransactionVendorBill]:
    AppStrings.Common.Enums.LinkedResourceType.RecurringTransactionVendorBill,
  [LinkedResourceType.RecurringTransactionExpense]:
    AppStrings.Common.Enums.LinkedResourceType.RecurringTransactionExpense,
  [LinkedResourceType.RecurringTransactionTask]: AppStrings.Common.Enums.LinkedResourceType.RecurringTransactionTask,
  [LinkedResourceType.RecurringTransactionGeneralEntry]: AppStrings.Common.Enums.LinkedResourceType.GeneralEntry,
  [LinkedResourceType.Task]: AppStrings.Common.Enums.LinkedResourceType.Task,
  [LinkedResourceType.RentalApplication]: AppStrings.Common.Enums.LinkedResourceType.RentalApplication,
  [LinkedResourceType.InsurancePolicy]: AppStrings.Common.Enums.LinkedResourceType.InsurancePolicy,
  [LinkedResourceType.Announcement]: AppStrings.Common.Enums.LinkedResourceType.Announcement,
  [LinkedResourceType.Communication]: AppStrings.Common.Enums.LinkedResourceType.Communication,
  [LinkedResourceType.Conversation]: AppStrings.Common.Enums.LinkedResourceType.Conversation,
  [LinkedResourceType.ESignatureRequest]: AppStrings.Common.Enums.LinkedResourceType.ESignatureRequest,
  [LinkedResourceType.ESignatureTemplate]: AppStrings.Common.Enums.LinkedResourceType.ESignatureTemplate
};

export const convertLinkedResourceTypeToTypeName = (linkedResourceType) => LinkedResourceTypeText?.[linkedResourceType];
