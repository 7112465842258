"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyndicationSpecificAmenityTypeEnum = void 0;
var SyndicationSpecificAmenityTypeEnum;
(function (SyndicationSpecificAmenityTypeEnum) {
    SyndicationSpecificAmenityTypeEnum["ADDITIONAL_STORAGE"] = "AdditionalStorage";
    SyndicationSpecificAmenityTypeEnum["AIR_CONDITIONER"] = "AirConditioner";
    SyndicationSpecificAmenityTypeEnum["ALARM"] = "Alarm";
    SyndicationSpecificAmenityTypeEnum["BALCONY"] = "Balcony";
    SyndicationSpecificAmenityTypeEnum["CABLE"] = "Cable";
    SyndicationSpecificAmenityTypeEnum["CARPET"] = "Carpet";
    SyndicationSpecificAmenityTypeEnum["CARPORT"] = "Carport";
    SyndicationSpecificAmenityTypeEnum["CEILING_FAN"] = "CeilingFan";
    SyndicationSpecificAmenityTypeEnum["CONTROLLED_ACCESS"] = "ControlledAccess";
    SyndicationSpecificAmenityTypeEnum["COURTYARD"] = "Courtyard";
    SyndicationSpecificAmenityTypeEnum["DISHWASHER"] = "Dishwasher";
    SyndicationSpecificAmenityTypeEnum["DISPOSAL"] = "Disposal";
    SyndicationSpecificAmenityTypeEnum["DOUBLE_SINK_VANITY"] = "DoubleSinkVanity";
    SyndicationSpecificAmenityTypeEnum["DRYER"] = "Dryer";
    SyndicationSpecificAmenityTypeEnum["FIREPLACE"] = "Fireplace";
    SyndicationSpecificAmenityTypeEnum["FRAMED_MIRRORS"] = "FramedMirrors";
    SyndicationSpecificAmenityTypeEnum["FURNISHED"] = "Furnished";
    SyndicationSpecificAmenityTypeEnum["GARAGE"] = "Garage";
    SyndicationSpecificAmenityTypeEnum["HANDRAILS"] = "Handrails";
    SyndicationSpecificAmenityTypeEnum["HARDWOOD_FLOORING"] = "HardwoodFlooring";
    SyndicationSpecificAmenityTypeEnum["HARD_SURFACE_COUNTER_TOPS"] = "HardSurfaceCounterTops";
    SyndicationSpecificAmenityTypeEnum["HEAT"] = "Heat";
    SyndicationSpecificAmenityTypeEnum["INDIVIDUAL_CLIMATE_CONTROL"] = "IndividualClimateControl";
    SyndicationSpecificAmenityTypeEnum["ISLAND_KITCHEN"] = "IslandKitchen";
    SyndicationSpecificAmenityTypeEnum["LAMINATE_COUNTER_TOPS"] = "LaminateCounterTops";
    SyndicationSpecificAmenityTypeEnum["VINYL_FLOORING"] = "VinylFlooring";
    SyndicationSpecificAmenityTypeEnum["LARGE_CLOSETS"] = "LargeClosets";
    SyndicationSpecificAmenityTypeEnum["LINEN_CLOSET"] = "LinenCloset";
    SyndicationSpecificAmenityTypeEnum["MICROWAVE"] = "Microwave";
    SyndicationSpecificAmenityTypeEnum["PANTRY"] = "Pantry";
    SyndicationSpecificAmenityTypeEnum["PATIO"] = "Patio";
    SyndicationSpecificAmenityTypeEnum["PRIVATE_BALCONY"] = "PrivateBalcony";
    SyndicationSpecificAmenityTypeEnum["PRIVATE_PATIO"] = "PrivatePatio";
    SyndicationSpecificAmenityTypeEnum["RANGE"] = "Range";
    SyndicationSpecificAmenityTypeEnum["REFRIGERATOR"] = "Refrigerator";
    SyndicationSpecificAmenityTypeEnum["SATELLITE"] = "Satellite";
    SyndicationSpecificAmenityTypeEnum["SKYLIGHT"] = "Skylight";
    SyndicationSpecificAmenityTypeEnum["TILE_FLOORING"] = "TileFlooring";
    SyndicationSpecificAmenityTypeEnum["VAULTED_CEILING"] = "VaultedCeiling";
    SyndicationSpecificAmenityTypeEnum["VIEW"] = "View";
    SyndicationSpecificAmenityTypeEnum["WASHER"] = "Washer";
    SyndicationSpecificAmenityTypeEnum["WHEEL_CHAIR"] = "WheelChair";
    SyndicationSpecificAmenityTypeEnum["WD_HOOKUP"] = "WD_Hookup";
    SyndicationSpecificAmenityTypeEnum["WINDOW_COVERINGS"] = "WindowCoverings";
    SyndicationSpecificAmenityTypeEnum["OTHER"] = "Other";
})(SyndicationSpecificAmenityTypeEnum || (exports.SyndicationSpecificAmenityTypeEnum = SyndicationSpecificAmenityTypeEnum = {}));
