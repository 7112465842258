import { UserStatus } from "@doorloop/dto";
import { Grid } from "@material-ui/core";
import { userRolesApi } from "api/userRolesApi";
import { FormikSwitchButton, TextField, ViewOnlyInput } from "DLUI/form";
import { Link } from "DLUI/link";
import { PersonInfo } from "DLUI/person";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, getIn } from "formik";
import AppStrings from "locale/keys";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import UserStatusDescription from "./userStatusDescription";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";
import { useParams } from "react-router";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";

interface ComponentProps {
  onFileReceive?: (receivedFile: File) => void;
  onFileDelete?: () => void;
  formikRef: FormikProps<any>;
}

const UserPersonalInfo: React.FC<ComponentProps> = ({ onFileReceive, onFileDelete, formikRef }: ComponentProps) => {
  const { t } = useTranslation();
  const [resetLogin, setResetLogin] = useState<boolean>(false);
  const userRoleDefaultValue = getIn(formikRef.values, "role");
  const ownerAccount = getIn(formikRef.values, "owner");
  const userStatus: UserStatus | undefined = getIn(formikRef.values, "status");
  const { isTabletOrMobile } = useResponsiveHelper();
  const renderUserRoleDescription = () => {
    const textElements: any[] = [];
    textElements.push(t(AppStrings.Users.NewUser.UserRoleDescription));
    textElements.push(" ");
    return textElements;
  };
  const { userId } = useParams<Record<string, string>>();
  const isEditing = Boolean(userId);

  const textPaddingLeft = isTabletOrMobile ? 0 : 20;
  const fieldFlexDirection = isTabletOrMobile ? "column" : "row";
  const fieldAlignItems = isTabletOrMobile ? "stretch" : "flex-start";

  const renderUserRoleField = () => {
    let field = (
      <FormikCachedAsyncAutoComplete
        uniqueIndex={"userRoleApi"}
        apiHandler={userRolesApi}
        displayNameKey={"name"}
        filterFieldName={"filter_text"}
        filterFieldValue={"name"}
        selectionFields={["id", "class"]}
        name={"role"}
        label={t(AppStrings.Users.NewUser.UserRole)}
        defaultValue={userRoleDefaultValue}
      />
    );

    if (ownerAccount) {
      field = (
        <ViewOnlyInput
          label={AppStrings.Users.NewUser.UserRole}
          value={t(AppStrings.Users.Screen.AccountOwner)}
          height={60}
          fullWidth
          paddingLeft={20}
        />
      );
    }
    return <View>{field}</View>;
  };

  const renderLoginEmailField = () => {
    let field: any = (
      <FastField
        component={TextField}
        label={t(AppStrings.Users.NewUser.LoginEmailAddress)}
        name={`loginEmail`}
        marginTop={20}
        required
      />
    );
    if (
      Boolean(ownerAccount) ||
      (userStatus === UserStatus.INVITED && !resetLogin) ||
      (userStatus === UserStatus.ACTIVE && !resetLogin)
    ) {
      const emailAddress = getIn(formikRef.values, "loginEmail");
      field = (
        <ViewOnlyInput
          label={AppStrings.Users.NewUser.LoginEmailAddress}
          value={emailAddress}
          fullWidth
          marginTop={20}
          height={60}
          alignItems={"flex-start"}
          paddingLeft={20}
        />
      );
    }
    return <View>{field}</View>;
  };

  const didPressResetLogin = () => {
    setResetLogin(true);
  };

  const renderLoginEmailDescriptionText = () => {
    const textElements: any[] = [t(AppStrings.Users.NewUser.LoginEmailAddressDescription)];
    if (!resetLogin && !ownerAccount && userStatus !== UserStatus.SUSPENDED && userStatus !== UserStatus.ACTIVE) {
      textElements.push(" ");
      textElements.push(
        <Link onClick={didPressResetLogin} hoverColor={"lightBlue"}>
          {t(AppStrings.Users.NewUser.ResetLogin)}
        </Link>
      );
    }
    return textElements;
  };

  return (
    <View flex={1} alignItems={"center"}>
      <View width={"100%"}>
        <PersonInfo formikRef={formikRef} onFileReceive={onFileReceive} onFileDelete={onFileDelete} hideNotesSection />
        <SeparationLine marginTop={20} width={"95%"} height={1} />

        <View flexDirection={fieldFlexDirection} noWrap={isTabletOrMobile} alignItems={fieldAlignItems}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            {renderLoginEmailField()}
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Text marginTop={isTabletOrMobile ? 20 : 30} paddingLeft={textPaddingLeft} fontSize={16} color={"gray"}>
              {renderLoginEmailDescriptionText()}
            </Text>
          </Grid>
        </View>

        <View flexDirection={fieldFlexDirection} noWrap={isTabletOrMobile} alignItems={fieldAlignItems} marginTop={20}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            {renderUserRoleField()}
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Text marginTop={15} fontSize={16} color={"gray"} lineHeight={"24px"} paddingLeft={textPaddingLeft}>
              {renderUserRoleDescription()}
            </Text>
          </Grid>
        </View>
        <SeparationLine marginTop={20} width={"95%"} height={1} />

        {ownerAccount || !isEditing ? null : (
          <FastField
            component={FormikSwitchButton}
            name={"active"}
            label={AppStrings.Users.NewUser.ActiveUser}
            marginTop={20}
          />
        )}
        <View marginTop={20} marginBottom={10}>
          <UserStatusDescription formikRef={formikRef} />
        </View>
      </View>
    </View>
  );
};

export default UserPersonalInfo;
