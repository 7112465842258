import type { FC } from "react";
import React, { useEffect, useRef } from "react";

interface Props {
  htmlString: string;
}

export const HTMLContent: FC<Props> = ({ htmlString }: Props) => {
  const wrapperEl = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const div = wrapperEl.current;
    if (div) {
      div.innerHTML = "";
      const shadowDOM = div.attachShadow({ mode: "closed" });
      shadowDOM.innerHTML = htmlString;
    }
  }, [wrapperEl]);
  return <div ref={wrapperEl} />;
};
