import { View } from "DLUI/view";
import type { FieldArrayRenderProps } from "formik";
import { FastField } from "formik";
import React from "react";
import { Grid } from "@material-ui/core";
import { FormikSwitchButton, IconButton, TextField } from "DLUI/form";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import { DeleteIcon } from "assets/icons";
import _ from "lodash";
import { useShakeEffect } from "hooks/useShakeEffect";

interface ComponentProps {
  itemIndex: number;
  arrayHelpers: FieldArrayRenderProps;
}

const AdditionalInformationAttachmentsItem: React.FC<ComponentProps> = ({
  itemIndex,
  arrayHelpers
}: ComponentProps) => {
  const { t } = useTranslation();
  const { runShakeEffect, ShakeEffectWrapper } = useShakeEffect();

  const handleDeleteClick = () => {
    if (_.size(arrayHelpers.form.values.filesAndDocuments?.files) === 1) {
      runShakeEffect();
      return;
    }
    arrayHelpers.remove(itemIndex);
  };

  return (
    <View
      justifyContent={"center"}
      flexDirection={"row"}
      marginTop={itemIndex > 0 ? 20 : 0}
      paddingLeft={20}
      paddingBottom={20}
      paddingTop={20}
    >
      <Grid container item md={11}>
        <View flexDirection={"row"}>
          <FastField
            component={TextField}
            name={`filesAndDocuments.files[${itemIndex}].label`}
            required
            label={t(AppStrings.Common.AttachmentName)}
            maxLength={"50"}
            paddingRight={20}
          />
        </View>
      </Grid>
      <Grid container item md={1}>
        <ShakeEffectWrapper>
          <IconButton marginTop={4} removePadding onClick={handleDeleteClick} Icon={DeleteIcon} />
        </ShakeEffectWrapper>
      </Grid>
      <Grid item md={12}>
        <View marginTop={20}>
          <FastField
            component={TextField}
            name={`filesAndDocuments.files[${itemIndex}].description`}
            multiline
            multiLineSize={"normal"}
            maxLength={"500"}
            rows={2}
            label={t(AppStrings.Common.Instructions)}
            paddingRight={20}
          />
        </View>
      </Grid>
      <Grid item md={12}>
        <View marginTop={10}>
          <FastField
            component={FormikSwitchButton}
            name={`filesAndDocuments.files[${itemIndex}].isRequired`}
            label={AppStrings.Common.MarkAsMandatory}
            marginTop={10}
          />
        </View>
      </Grid>
    </View>
  );
};

export default AdditionalInformationAttachmentsItem;
