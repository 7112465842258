"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPersonPhoneOrEmailArrayNotEmpty = void 0;
exports.IsPersonPhoneOrEmailArrayNotEmpty = IsPersonPhoneOrEmailArrayNotEmpty;
const class_validator_1 = require("class-validator");
let isPersonPhoneOrEmailArrayNotEmpty = class isPersonPhoneOrEmailArrayNotEmpty {
    validate(value, args) {
        var _a;
        const [relatedPropertyName] = args.constraints;
        const relatedValue = args.object[relatedPropertyName];
        const portalInfo = args.object["portalInfo"];
        if ((_a = args.object) === null || _a === void 0 ? void 0 : _a.disablePersonDetailsValidation)
            return true;
        return (!(this.isDtoArrayEmpty(value) && this.isDtoArrayEmpty(relatedValue)) ||
            Boolean(portalInfo === null || portalInfo === void 0 ? void 0 : portalInfo.loginEmail) ||
            Boolean(portalInfo === null || portalInfo === void 0 ? void 0 : portalInfo.mobilePhone));
    }
    isDtoArrayEmpty(dtoArray) {
        return !(dtoArray === null || dtoArray === void 0 ? void 0 : dtoArray.some((dto) => (dto === null || dto === void 0 ? void 0 : dto.number) || (dto === null || dto === void 0 ? void 0 : dto.address)));
    }
    defaultMessage(args) {
        const [relatedPropertyName] = args.constraints;
        return `${args.property} and ${relatedPropertyName} cannot both be empty`;
    }
};
exports.isPersonPhoneOrEmailArrayNotEmpty = isPersonPhoneOrEmailArrayNotEmpty;
exports.isPersonPhoneOrEmailArrayNotEmpty = isPersonPhoneOrEmailArrayNotEmpty = __decorate([
    (0, class_validator_1.ValidatorConstraint)({ name: "isPersonPhoneOrEmailArrayNotEmpty", async: false })
], isPersonPhoneOrEmailArrayNotEmpty);
function IsPersonPhoneOrEmailArrayNotEmpty(validationOptions) {
    return function (object, propertyName) {
        (0, class_validator_1.registerDecorator)({
            name: "isPersonPhoneOrEmailArrayNotEmpty",
            target: object.constructor,
            propertyName,
            options: validationOptions,
            validator: isPersonPhoneOrEmailArrayNotEmpty
        });
    };
}
