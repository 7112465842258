"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BusinessType = void 0;
var BusinessType;
(function (BusinessType) {
    BusinessType["SOLE_PROPIETOR"] = "0";
    BusinessType["CORPORATION"] = "1";
    BusinessType["LLC"] = "2";
    BusinessType["PARTNERHSHIP"] = "3";
    BusinessType["NON_PROFIT"] = "5";
    BusinessType["GOVERNMENT"] = "6";
})(BusinessType || (exports.BusinessType = BusinessType = {}));
