import type { FormikProps } from "formik";
import { CheckbookSettingsDto } from "@doorloop/dto";
import { createValidator } from "@doorloop/dto";

export const initCheckbookFormValues = (): CheckbookSettingsDto => new CheckbookSettingsDto();

export const validateForm = createValidator(CheckbookSettingsDto);

export const validateCheckbookForm =
  (hasError: boolean) =>
  async (formikRef: FormikProps<any>): Promise<{ isValid: boolean; errorStepIndex?: number }> => {
    await formikRef.submitForm();
    const errors = (await formikRef.validateForm()) as any;

    if (errors.accountMappings || hasError) {
      return { isValid: false, errorStepIndex: 0 };
    }
    return { isValid: true };
  };
