import React from "react";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import type { FieldArrayRenderProps } from "formik";
import { FieldArray, getIn, useFormikContext } from "formik";
import type { RentalApplicationCompanySettingsDto } from "@doorloop/dto";
import { RentalApplicationAdditionalQuestionSettingDto, RentalApplicationAdditionalQuestionType } from "@doorloop/dto";
import { View } from "DLUI/view";
import { SeparationLine } from "DLUI/separatorView";
import { Button } from "DLUI/button";
import { AddIcon } from "assets/icons";
import AdditionalInformationQuestionsItem from "./additionalInformationQuestionsItem";
import { v4 as uuid } from "uuid";
import _ from "lodash";

const AdditionalInformationQuestions: React.FC<{}> = () => {
  const {
    values: { additionalInfo }
  } = useFormikContext<RentalApplicationCompanySettingsDto>();

  const additionalInfoQuestionsArray = getIn(additionalInfo, "additionalInfoQuestions");
  const isOnlyOneElement = additionalInfoQuestionsArray?.length === 1;

  const renderAddNewButton = (arrayHelpers: FieldArrayRenderProps) => {
    const didPressAddElement = () => {
      const rentalApplicationAdditionalQuestionSetting = new RentalApplicationAdditionalQuestionSettingDto();
      rentalApplicationAdditionalQuestionSetting.questionType = RentalApplicationAdditionalQuestionType.YES_NO;
      rentalApplicationAdditionalQuestionSetting.uniqueIndex = uuid();
      arrayHelpers.push(rentalApplicationAdditionalQuestionSetting);
    };

    let buttonText = AppStrings.Tenants.NewTenant.AddAnother;
    if (_.isEmpty(additionalInfoQuestionsArray)) {
      buttonText = AppStrings.Common.AddQuestion;
    }
    return (
      <View paddingLeft={20}>
        <Button
          color={"lightBlue"}
          type={"inlineText"}
          actionText={buttonText}
          onClick={didPressAddElement}
          LeftIcon={AddIcon}
          iconSize={15}
          marginTop={10}
          applyColorForIcons
        />
      </View>
    );
  };

  const renderElements = () => (
    <FieldArray
      name={"additionalInfo.additionalInfoQuestions"}
      render={(arrayHelpers) =>
        additionalInfoQuestionsArray ? (
          <>
            {additionalInfoQuestionsArray.map((currentElement, index) => (
              <AdditionalInformationQuestionsItem
                itemIndex={index}
                key={"AIQ" + currentElement.uniqeIndex}
                arrayHelpers={arrayHelpers}
                isOnlyElement={isOnlyOneElement}
              />
            ))}
            {renderAddNewButton(arrayHelpers)}
          </>
        ) : (
          <>{renderAddNewButton(arrayHelpers)}</>
        )
      }
    />
  );

  return (
    <View marginTop={20}>
      <View alignItems={"center"} backgroundColor={"dark"} borderRadius={10}>
        <View justifyContent={"center"} height={50}>
          <Text
            color={"black"}
            fontSize={16}
            fontWeight={700}
            value={AppStrings.Common.AdditionalQuestions}
            marginLeft={20}
          />
        </View>
        <SeparationLine marginBottom={20} height={1} width={"95%"} />
        {renderElements()}
        <View height={20} />
      </View>
    </View>
  );
};

export default AdditionalInformationQuestions;
