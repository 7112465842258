/* eslint-disable @typescript-eslint/no-use-before-define */

import clsx from "clsx";
import React from "react";

import { useResponsiveHelper } from "@/contexts/responsiveContext";
import { MediaBreakpointDown } from "@/contexts/utils";
import { IconButton, ListSubheader, Paper, TextField } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { CloseGrayIcon } from "assets/icons/index";
import { useTypedTranslation } from "locale/useTypedTranslation";
import { matchSorter } from "match-sorter";
import { useInView } from "react-intersection-observer";
import { useContextSelector } from "use-context-selector";
import { AbstractAutoCompleteContext as AutoCompleteContext } from "../autoCompleteTyped/autoComplete.context";
import { MIN_FILTER_LENGTH } from "./autoComplete.constants";
import { SkeletonAvatar } from "./autoCompleteAvatar";

import type { TextFieldProps, Theme } from "@material-ui/core";
import type { AutocompleteRenderGroupParams, AutocompleteRenderInputParams } from "@material-ui/lab";
import type { FilterOptionsState } from "@material-ui/lab/useAutocomplete/useAutocomplete.js";
import type { CSSProperties } from "react";
import type { TranslateFn } from "../../../../../locale/useTypedTranslation";
import type { AutoCompleteContextProps } from "./autoComplete.context";
import type {
  AutoCompleteGetAvatar,
  AutoCompleteOption,
  AutoCompleteOptionRenderer,
  AutoCompleteValue,
  CustomPaperComponent,
  FilterOptionsAuxiliaryParams
} from "./autoComplete.types";

interface ThemeProps {
  isOpen: boolean;
}

export function PureCSSMuiCircularProgress({ size = 24, style }: { size?: number; style?: CSSProperties }) {
  return (
    <progress
      className="pure-mui-circular-progress"
      style={{
        width: size,
        height: size,
        ...style
      }}
    />
  );
}

export const PureCSSMuiCircularProgressMemo = React.memo(PureCSSMuiCircularProgress);

const avatarSize = 24;
const avatarMobileSize = 26;

export const inputStyles = makeStyles<Theme, ThemeProps>((_theme: Theme) =>
  createStyles({
    MuiTextFieldContainer: {
      display: "flex",
      alignItems: "start",
      gap: 8
      // This doesn't work (bug in MUI v4, fixed in v5), see
      // https://github.com/mui/material-ui/issues/20446. We apply responsive
      // style through the corresponding global class in
      // `autoComplete.styles.tsx`.
      // [MediaBreakpointDown.MD]: (props) =>
      //   props.isOpen && {
      //     backgroundColor: "#fff",
      //     padding: "12px 12px 0px 12px"
      //   }
    },
    closeButton: {
      display: "none",
      marginTop: 6,
      "& svg": {
        width: 14,
        height: 14
      },
      [MediaBreakpointDown.MD]: {
        display: "unset"
      }
    }
  })
);

export type CreateInputRendererProps = Omit<TextFieldProps, "inputRef"> & {
  inputContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  inputRef: React.RefObject<HTMLInputElement>;
};

const circularProgressStyle: CSSProperties = { position: "absolute", right: 12 };

export function defaultCreateInputRenderer({
  inputRef,
  inputContainerRef,
  InputProps,
  ...restProps
}: CreateInputRendererProps) {
  return function renderInput(muiParams: AutocompleteRenderInputParams) {
    // This gets called too many times for usage with dynamic components, so be
    // careful when making changes;
    // Even though it's a render function, it's natively rendered at all times
    // and therefore can use hooks. The function naming convention makes
    // `ESLint` think that is out of scope for React hooks, but it's not.
    const autoCompleteContext = {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      value: useContextSelector(AutoCompleteContext, (context) => context?.value),
      // eslint-disable-next-line react-hooks/rules-of-hooks
      isOpen: useContextSelector(AutoCompleteContext, (context) => context?.isOpen),
      // eslint-disable-next-line react-hooks/rules-of-hooks
      isLoading: useContextSelector(AutoCompleteContext, (context) => context?.isLoading),
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsOpen: useContextSelector(AutoCompleteContext, (context) => context?.setIsOpen),
      // eslint-disable-next-line react-hooks/rules-of-hooks
      dispatchClose: useContextSelector(AutoCompleteContext, (context) => context?.dispatchClose)
    } as AutoCompleteContextProps<unknown>;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTypedTranslation();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { isMobile } = useResponsiveHelper();

    const classes = inputStyles({ isOpen: autoCompleteContext.isOpen });

    const { InputProps: muiInputProps, ...restMuiProps } = muiParams;

    function handleMouseDown(event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) {
      const textField = event.target as HTMLElement;

      // Prevent default focus on mobile, when clicking the input.
      if (isMobile && !autoCompleteContext.isOpen) {
        event.preventDefault();
        return;
      }

      // Restore default implementation for all other cases.
      // See https://github.com/mui/material-ui/blob/v4.x/packages/material-ui-lab/src/useAutocomplete/useAutocomplete.js#L868
      if (textField.getAttribute("id") !== muiParams.id) {
        event.preventDefault();
      }
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
      if (event.key === "Enter") {
        // Temporarily disable this feature because it's not working as expected
        event.stopPropagation();
      }
    }

    const isInputEmpty = inputRef.current?.value === "";

    // Be careful when modifying this code as some props have to merged in a
    // specific way or there can be unexpected runtime errors.
    return (
      <div ref={inputContainerRef} className={clsx(classes.MuiTextFieldContainer, "MuiTextField-container")}>
        <TextField
          {...restMuiProps}
          inputRef={inputRef}
          onMouseDown={handleMouseDown}
          onKeyDown={handleKeyDown}
          placeholder={t("common.autoComplete.placeholder")}
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            ...muiInputProps,
            // Set the input to `readonly` when fetching options to prevent the user
            // from typing and triggering a request with a non-empty `filter_text`
            // that will cancel the initial request that (presumably) has an empty
            // `filter_text`. Otherwise, the user won't be able to go back to the
            // initial options when clearing the input.
            readOnly: autoCompleteContext.isLoading && isInputEmpty,
            startAdornment: (
              <SkeletonAvatar
                src={isInputEmpty ? undefined : autoCompleteContext.value?.avatarSrc}
                size={isMobile ? avatarMobileSize : avatarSize}
                label={autoCompleteContext.value?.label}
                asInitials={isInputEmpty ? false : Boolean(autoCompleteContext.value)}
                rootStyle={{ marginRight: 4 }}
                avatarStyle={{ fontSize: 10 }}
              />
            ),
            endAdornment: autoCompleteContext.isLoading ? (
              <PureCSSMuiCircularProgressMemo size={avatarSize} style={circularProgressStyle} />
            ) : (
              muiInputProps.endAdornment
            ),
            ...InputProps
          }}
          {...restProps}
        />
        {autoCompleteContext.isOpen && (
          <IconButton
            className={classes.closeButton}
            aria-label="close"
            onMouseDown={(event) => autoCompleteContext.dispatchClose(event, "escape")}
          >
            <CloseGrayIcon />
          </IconButton>
        )}
      </div>
    );
  };
}

export const defaultOptionRenderer: {
  <T>(...args: Parameters<AutoCompleteOptionRenderer<T>>);
} = (option) => (
  <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
    {option.avatarSrc && <SkeletonAvatar src={option.avatarSrc} />}
    {option.label}
  </div>
);

export const DefaultCustomPaperComponent: CustomPaperComponent = (props) => {
  const { children } = props;

  // Workaround to forward `ref` from `Paper` to `AutoComplete` component.
  const autoCompleteContext = {
    paperRef: useContextSelector(AutoCompleteContext, (context) => context?.paperRef)
  } as unknown as AutoCompleteContextProps<unknown>;

  return (
    <Paper ref={autoCompleteContext.paperRef} {...props} square>
      <div style={{ display: "flex", flexDirection: "column", height: "inherit" }}>{children}</div>
    </Paper>
  );
};

// https://github.com/mui/material-ui/issues/30249
// https://github.com/mui/material-ui/issues/29508
// https://github.com/mui/material-ui/issues/31073
// https://github.com/mui/material-ui/issues/40250
// https://github.com/mui/material-ui/pull/35735
// https://github.com/mui/material-ui/blob/v4.x/packages/material-ui-lab/src/useAutocomplete/useAutocomplete.js
export const DefaultCustomListboxComponent = React.forwardRef<HTMLUListElement, React.HTMLAttributes<HTMLUListElement>>(
  ({ children, onMouseDown: _onMouseDown, ...restParams }, ref) => {
    // Override `onMouseDown` because it calls `preventDefault` which prevents the
    // input from blurring, which is incorrect for interactive children within the
    // `Listbox` component.
    // See https://github.com/mui/material-ui/blob/v4.x/packages/material-ui-lab/src/useAutocomplete/useAutocomplete.js#L985
    const handleMouseDown = (_event: React.MouseEvent<HTMLUListElement>) => {
      // Explicitly do nothing (do _not_ remove this function).
    };

    const { isMobile } = useResponsiveHelper();

    // Initially, we used the `onScroll` event to blur the input on mobile, but
    // it didn't work well, because when the `Listbox` content changes (after
    // changing the filter) it will invoke a scroll event that will blur the
    // input unintentionally. This is easily reproducible by opening the
    // dropdown, immediately scrolling and then focusing the input.
    function handleTouchMove(_event: React.UIEvent<HTMLUListElement>) {
      // https://developer.mozilla.org/en-US/docs/Web/API/Document/activeElement
      if (
        isMobile &&
        (document.activeElement instanceof HTMLInputElement || document.activeElement instanceof HTMLTextAreaElement)
      ) {
        document.activeElement.blur();
      }
    }

    return (
      // Temporary workaround for an issue where it scrolls back to the selected option.
      // See https://github.com/mui/material-ui/issues/30249#issuecomment-1026131743.
      <ul ref={ref} {...restParams} role="list-box" onMouseDown={handleMouseDown} onTouchMove={handleTouchMove}>
        {children}
      </ul>
    );
  }
);

DefaultCustomListboxComponent.displayName = "DefaultCustomListboxComponent";

export type CustomListboxComponent = typeof DefaultCustomListboxComponent;

export const DefaultCustomListboxComponentMemo = React.memo(DefaultCustomListboxComponent);

export function AutoCompleteGroup({
  params,
  children,
  solo
}: {
  params: AutocompleteRenderGroupParams;
  children?: React.ReactNode;
  solo?: boolean;
}) {
  const { ref, inView: _inView } = useInView({ threshold: 0.5 });

  const soloRenderer = () => (
    <React.Fragment key={params.key}>
      {mapChildrenToUniqueChildren(params.children, "solo-group")}
      {children}
    </React.Fragment>
  );

  // Replicate the default renderer exactly,
  // See https://github.com/mui/material-ui/blob/v4.x/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L370
  const groupRenderer = () => (
    <li ref={ref}>
      <ListSubheader
        className="MuiAutocomplete-groupLabel"
        component="div"
        // TODO Fix this, it's not working as expected once the children list gets long.
        // style={{ position: inView ? "sticky" : "static" }}
      >
        {params.group}
      </ListSubheader>
      <ul className="MuiAutocomplete-groupUl">{mapChildrenToUniqueChildren(params.children, params.group)}</ul>
      {children}
    </li>
  );

  return solo ? soloRenderer() : groupRenderer();
}

// Mui AutoComplete v4 doesn't provide a way to set unique `key` prop for option, so we have to workaround it.
// See https://github.com/mui/material-ui/issues/26492
// See https://github.com/mui/material-ui/blob/v4.x/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L370
export function mapChildrenToUniqueChildren(children: React.ReactNode, groupName: string) {
  return React.Children.toArray(children).map((child) => {
    if (React.isValidElement(child)) {
      const optionItem = child.props.children;
      const optionId = optionItem.props["data-option-id"];

      if (!optionId && process.env.REACT_APP_ENV === "dev") {
        console.warn(
          `[AutoComplete] Unable to find option \`id\` for "${optionItem}". Please provide` +
            `a \`data-option-id\` "attribute on the root element in the return of \`renderOption\`.`
        );
      }

      return React.cloneElement(child, { key: `${groupName.toLowerCase()}-${optionId}` });
    }
    return child;
  });
}

// Replicate the default renderer exactly,
// See https://github.com/mui/material-ui/blob/v4.x/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L370
export function defaultCustomGroupRenderer(params: AutocompleteRenderGroupParams) {
  return <AutoCompleteGroup key={params.group.toLowerCase()} params={params} />;
}

// Fallback to the translated label set in `createOptionsGetter`, if the option
// doesn't have a label and at the worst-case, fallback to a non-translated
// label.
export function defaultCustomGetOptionLabel<T>(option: AutoCompleteOption<T>) {
  return option?.label || `Unlabeled Option ${option.index + 1}`;
}

// There's an issue where the label value is not read from the option `label`
// property when the option is selected, instead it invokes the `getOptionLabel`
// prop on the value and if the consumer didn't provide a fallback, it will
// resolve to `undefined` instead of a `string`.
export function createOptionLabelGetter<T>(getOptionLabel: (option: AutoCompleteOption<T>) => string) {
  return (option: AutoCompleteOption<T>) => getOptionLabel(option) || defaultCustomGetOptionLabel(option);
}

export function defaultCustomGetOptionSelected<T>(option: AutoCompleteOption<T>, value: AutoCompleteValue<T>) {
  // Even though we don't need to, we can't rely on `id` here because the
  // options are not guaranteed to have an id when created dynamically. We
  // currently can't create an `id` from the client-side because the server
  // doesn't support it yet.
  // TODO Patch `ID` to a newly created option or it won't appear selected
  // _twice_ for the reason that we display a new group called "Selected" with
  // the newly created option. Namely, we now have two identical option items
  // that have _different_ indices, so we have to fallback to comparing their
  // IDs, but we currently don't patch the query with the `ID` for the newly
  // created option, hence the comparison fails and we have only one item
  // selected (out of two identical ones). The current workaround for this is to
  // invalidate the queries (which we do only in `production`) which then
  // refreshes the list of options and the newly created option appears as
  // selected twice as it should).
  return (
    option.index === value?.index ||
    // TSC needs optional chaining in `value?.data`, otherwise the build fails,
    // despite the fact that it's not necessary and that it doesn't come up in
    // the IDE TS Server.
    (doesOptionDataHaveId(option.data) && doesOptionDataHaveId(value?.data) && option.data.id === value?.data.id)
  );
}

// The keys to search for in an option, based on `TAutoCompleteOption`.
const baseFilterKeys = ["label"];

// Use Kent C. Dodds `match-sorter` library to filter the options. While the
// filter should satisfy most cases, you can copy-paste it, modify it to your
// needs and pass it as a prop (`filterOptions`) to the AutoComplete component.
export function defaultCustomFilterOptions<T>(
  options: Array<AutoCompleteOption<T>>,
  state: FilterOptionsState<AutoCompleteOption<T>>,
  auxiliaryParams: FilterOptionsAuxiliaryParams<AutoCompleteOption<T>>
) {
  if (state.inputValue.length < MIN_FILTER_LENGTH) {
    return options;
  }

  const { matchSorterOptions, groupBy, t: translateFn } = auxiliaryParams;

  if (groupBy && !translateFn) {
    throw new Error(
      "You must provide a translation function `t` when using `groupBy` in `defaultCustomFilterOptions`."
    );
  }

  const keysBase = matchSorterOptions?.keys || [];
  const keys = baseFilterKeys.concat(keysBase.map((key) => `data.${key}`));
  const terms = state.inputValue.trim().split(" ");

  const matchedOptions = terms.reduceRight(
    (acc, term) =>
      matchSorter(acc, term, {
        ...matchSorterOptions,
        keys,
        threshold: matchSorter.rankings.CONTAINS,
        baseSort: (a, b) => (a.index < b.index ? -1 : 1),
        // There's no need to sort the matched items because we display groups
        // and sorting the items will disrupt the group order. We can
        // potentially keep the groups order and re-sort the items within the
        // groups.
        // TODO maybe add highlighting to matches?
        sorter: (matchItems) => matchItems
      }),
    options
  );

  return matchedOptions;
}

export type AutoCompleteGetOptions<T> = (params: {
  displayNameKey?: keyof T;
  getAvatarSrc?: AutoCompleteGetAvatar<T>;
  getOptionLabel?: (option: AutoCompleteOption<T>) => string;
  t: TranslateFn;
}) => Array<AutoCompleteOption<T>>;

export type MetadataGetter<T> = (option: T) => AutoCompleteOption<T>["meta"];

export interface GetOptionsParams<T> {
  t: TranslateFn;
  getOptionLabel?: (option: AutoCompleteOption<T>) => string;
  getAvatarSrc?: AutoCompleteGetAvatar<T>;
  getMetadata?: MetadataGetter<T>;
  displayNameKey?: keyof T;
}

export function getOption<T>(data: T, index: number, params: GetOptionsParams<T>) {
  const { t, getOptionLabel, getAvatarSrc, getMetadata, displayNameKey } = params;

  const option: AutoCompleteOption<T> = {
    label: `${t("common.autoComplete.creatable.unlabeledOption")} ${index + 1}`,
    avatarSrc: getAvatarSrc?.(data),
    index,
    data,
    meta: getMetadata?.(data)
  };

  option.label = getOptionLabel?.(option) || (displayNameKey && (data[displayNameKey] as string)) || option.label;

  return option;
}

export function createOptionsGetter<T>(options: T[], getMetadata?: MetadataGetter<T>) {
  const getOptions: AutoCompleteGetOptions<T> = (params) => {
    const autoCompleteOptions = options.map((optionData, optionIdx) => {
      const { t, displayNameKey, getAvatarSrc, getOptionLabel } = params;

      const option: AutoCompleteOption<T> = {
        label: `${t("common.autoComplete.creatable.unlabeledOption")} ${optionIdx + 1}`,
        avatarSrc: getAvatarSrc?.(optionData),
        index: optionIdx,
        data: optionData,
        meta: getMetadata?.(optionData)
      };

      option.label =
        getOptionLabel?.(option) || (displayNameKey && (optionData[displayNameKey] as string)) || option.label;

      return option;
    });
    return autoCompleteOptions;
  };
  return getOptions;
}

// Use this to set an empty change event when calling
// `autoCompleteContext.dispatchChange`. This is strictly a readability hack
// that helps developers distinguish between the `event` and `value` parameters
// when passing them to the signature.
export const nullEvent = null;

export function doesOptionDataHaveId<T extends AutoCompleteOption<T>["data"]>(
  optionData: T
): optionData is T & { id: string } {
  return optionData && Object.hasOwn(optionData, "id");
}

export function isValidIndex(index?: number | null): index is number {
  return Number.isFinite(index);
}

// TODO Move this to `types.utils.ts`
export function doesObjectHaveProp<TPropType, T extends object | null | void = object | null | void>(
  object: T,
  prop: string
): object is T & Record<typeof prop, TPropType> {
  return object && Object.hasOwn(object, prop);
}
