"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkReceiveSessionDto = void 0;
/**
 * Bulk Receive Session provides a "container" for a set of bulk receive payments.
 * It is used to group payments together and to provide a common set of metadata
 *
 * User is able to aggregate multiple Lease Payments into the session
 *
 * The lease payments are stored in a hashmap where the key is the lease id
 * and the value is the LeasePaymentDto
 */
const isDateStringWithoutTime_validator_1 = require("../common/validators/isDateStringWithoutTime.validator");
const class_validator_1 = require("class-validator");
class BulkReceiveSessionDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.BulkReceiveSessionDto = BulkReceiveSessionDto;
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", Boolean)
], BulkReceiveSessionDto.prototype, "autoDeposit", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", Boolean)
], BulkReceiveSessionDto.prototype, "sendPaymentReceipt", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", Boolean)
], BulkReceiveSessionDto.prototype, "autoApplyPaymentOnCharges", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], BulkReceiveSessionDto.prototype, "date", void 0);
