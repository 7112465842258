import { View } from "DLUI/view";
import React from "react";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { TermsOfServiceUrl, PrivacyPolicyUrl } from "utils/sharedVariables";
import { Link } from "DLUI/link";
import { useTranslation } from "react-i18next";

interface TermsAndPrivacyViewProps {
  tosLink?: string;
}

const TermsAndPrivacyView: React.FC<TermsAndPrivacyViewProps> = ({
  tosLink = TermsOfServiceUrl
}: TermsAndPrivacyViewProps) => {
  const { t } = useTranslation();

  return (
    <View justifyContent={"center"} marginTop={20} flexDirection={"row"}>
      <Text
        value={AppStrings.Common.ReturnedPaymentFee}
        color={"gray"}
        fontSize={14}
        marginBottom={15}
        overFlow={"ellipsis"}
        numberOfLines={2}
        align={"center"}
        lineBreak={"auto"}
      />
      <View alignItems={"center"}>
        <Text value={AppStrings.Common.ByMakingPayment} color={"gray"} fontSize={14} />
      </View>
      <Link hrefUrl={tosLink} underline={"always"} hoverColor={"gray"} type={"blank"}>
        <Text color={"gray"} fontSize={14} marginLeft={5} marginRight={3}>
          {t(AppStrings.Common.Terms)}
        </Text>
      </Link>
      <Text color={"gray"} fontSize={14}>
        {"&"}
      </Text>
      <Link hrefUrl={PrivacyPolicyUrl} underline={"always"} hoverColor={"gray"} type={"blank"}>
        <Text marginLeft={3} color={"gray"} fontSize={14}>
          {t(AppStrings.Common.PrivacyPolicy)}
        </Text>
      </Link>
    </View>
  );
};

export default TermsAndPrivacyView;
