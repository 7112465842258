import type { ObjectPermission } from "@doorloop/dto";
import type { ObjectPermissionField } from "screens/settings/userRoles/clearanceTypes";
import { makePermissionKey } from "screens/settings/userRoles/clearanceTypes";

type FieldDependencies = {
  [key in ObjectPermissionField]?: ObjectPermissionField[];
};

const fieldDependenciesMap: FieldDependencies = {
  viewOne: ["viewList"],
  edit: ["viewList", "viewOne"],
  delete: ["viewList", "viewOne", "edit"]
};

export const getRequiredFields = (permissionKey: string) => {
  const [section, permission, field] = permissionKey.split(".");
  if (section !== "objects") {
    return [];
  }
  return (fieldDependenciesMap[field] || []).map((dependantField) =>
    makePermissionKey(`${section}.${permission}` as ObjectPermission, dependantField)
  );
};

export const getRequiringFields = (permissionKey: string) => {
  const [section, permission, field] = permissionKey.split(".");
  if (section !== "objects") {
    return [];
  }
  return Object.entries(fieldDependenciesMap)
    .filter(([, requiredFields]) => requiredFields?.includes(field as ObjectPermissionField))
    .map(([requiringField]) =>
      makePermissionKey(`${section}.${permission}` as ObjectPermission, requiringField as ObjectPermissionField)
    );
};
