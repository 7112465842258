"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MerchantStatus = void 0;
var MerchantStatus;
(function (MerchantStatus) {
    MerchantStatus["NOT_READY"] = "0";
    MerchantStatus["READY"] = "1";
    MerchantStatus["BOARDED"] = "2";
    MerchantStatus["MANUAL"] = "3";
    MerchantStatus["CLOSED"] = "4";
    MerchantStatus["INCOMPLETE"] = "5";
})(MerchantStatus || (exports.MerchantStatus = MerchantStatus = {}));
