import { ObjectPermission } from "@doorloop/dto";
import DLButton from "DLUI/button/dlButton";
import EmptyDataView from "DLUI/emptyDataView";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import React from "react";

interface ComponentProps {
  didPressAddNewButton?: () => void;
}

const VendorsEmptyDataView: React.FC<ComponentProps> = ({ didPressAddNewButton }: ComponentProps) => (
  <EmptyDataView
    instructionsText={AppStrings.Vendors.Screen.EmptydataViewInstructionsText}
    actionButton={
      <RestrictedPermissionAccess clearance={{ permission: ObjectPermission.vendors, field: "create" }}>
        {didPressAddNewButton ? (
          <DLButton
            icons={{ start: { isHidden: false } }}
            actionText={AppStrings.Vendors.Screen.EmptydataAddNew}
            onClick={didPressAddNewButton}
            disableMobileCollapse
          />
        ) : (
          <Text value={AppStrings.Vendors.Screen.EmptydataAddNew} />
        )}
      </RestrictedPermissionAccess>
    }
  />
);

export default VendorsEmptyDataView;
