import clsx from "clsx";
import React, { useEffect, useState } from "react";
import "./styles.css";

interface ComponentProps {
  children: React.ReactNode;
  showEffect?: boolean;
  fullWidth?: boolean;
}

const ShakeEffectView = ({ children, showEffect, fullWidth }: ComponentProps) => {
  const [containerClass, setContainerClass] = useState("");
  useEffect(() => {
    if (showEffect && Boolean(showEffect)) {
      setContainerClass("shake-horizontal");
    } else {
      setContainerClass("");
    }
  }, [showEffect]);

  return <div className={clsx("DLUI_shake", fullWidth ? "fullWidth" : "", containerClass)}>{children}</div>;
};

export default ShakeEffectView;
