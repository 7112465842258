import type { DialogProps } from "./dialogsProvider.types";
import { DialogRoutes } from "./dialogsRoutes";
import {
  FileDialog,
  InternalTaskDialog,
  NoteDialog,
  OwnerRequestDialog,
  TaskUpdateDialog,
  TenantRequestDialog,
  WorkOrderDialog
} from "DLUI/dialogs";
import AppStrings from "locale/keys";
import { EditRecurringTask } from "DLUI/dialogs/tasks/internalTask/editRecurringTask";
import { EditRecurringWorkOrder } from "DLUI/dialogs/tasks/workOrder/editRecurringWorkOrder";

class TasksDialogsProvider {
  matchRoutes(pathname: string): DialogProps | undefined {
    if (pathname.indexOf(DialogRoutes.NEW_INTERNAL_TASK) > -1) {
      return {
        title: AppStrings.Tasks.InternalTaskDialog.NewTask,
        Content: InternalTaskDialog
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_INTERNAL_TASK) > -1) {
      return {
        title: AppStrings.Tasks.InternalTaskDialog.EditTask,
        Content: InternalTaskDialog
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_RECURRING_TASK) > -1) {
      return {
        title: AppStrings.Tasks.InternalTaskDialog.EditRecurringTask,
        Content: EditRecurringTask
      };
    }
    if (pathname.indexOf(DialogRoutes.NEW_OWNER_REQUEST) > -1) {
      return {
        title: AppStrings.Tasks.OwnerRequestDialog.NewOwnerRequest,
        Content: OwnerRequestDialog
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_OWNER_REQUEST) > -1) {
      return {
        title: AppStrings.Tasks.OwnerRequestDialog.EditOwnerRequest,
        Content: OwnerRequestDialog
      };
    }
    if (pathname.indexOf(DialogRoutes.NEW_TENANT_REQUEST) > -1) {
      return {
        title: AppStrings.Tasks.TenantRequestDialog.NewTenantRequest,
        Content: TenantRequestDialog
      };
    }
    if (pathname.indexOf(DialogRoutes.NEW_FILE) > -1) {
      return {
        title: AppStrings.Files.Tab.AddNewFile,
        Content: FileDialog
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_FILE) > -1) {
      return {
        title: AppStrings.Common.Dropzone.FileInfo,
        Content: FileDialog
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_NOTE) > -1) {
      return {
        title: AppStrings.Notes.Dialog.EditNote,
        Content: NoteDialog
      };
    }
    if (pathname.indexOf(DialogRoutes.NEW_NOTE) > -1) {
      return {
        title: AppStrings.Notes.Tab.AddNewNote,
        Content: NoteDialog
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_TENANT_REQUEST) > -1) {
      return {
        title: AppStrings.Tasks.TenantRequestDialog.EditTenantRequest,
        Content: TenantRequestDialog
      };
    }
    if (pathname.indexOf(DialogRoutes.NEW_WORK_ORDER) > -1) {
      return {
        title: AppStrings.Tasks.Screen.NewWorkOrder,
        Content: WorkOrderDialog
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_RECURRING_WORK_ORDER) > -1) {
      return {
        title: AppStrings.Tasks.WorkOrderDialog.EditRecurringWorkOrder,
        Content: EditRecurringWorkOrder
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_WORK_ORDER) > -1) {
      return {
        title: AppStrings.Tasks.WorkOrderDialog.EditWorkOrder,
        Content: WorkOrderDialog
      };
    }
    if (pathname.indexOf(DialogRoutes.POST_TASK_UPDATE) > -1) {
      return {
        title: AppStrings.Tasks.TaskDetails.PostUpdate,
        Content: TaskUpdateDialog
      };
    }
  }
}

export const tasksDialogsProvider = new TasksDialogsProvider();
