import React from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import { View } from "DLUI/view";
import "./styles.css";
import { RadioGroupLabels } from "DLUI/form/radioGroup/radioGroupLabels";
import type { FieldProps } from "formik";
import type { RadioGroupProps } from "DLUI/form/radioGroup/types";

type FormikRadioGroupProps = FieldProps & RadioGroupProps;

const FormikRadioGroup: React.FC<FormikRadioGroupProps> = ({
  marginTop,
  alignItems,
  field,
  dataCy,
  radioGroupItemsProps
}) => {
  if (!radioGroupItemsProps.radioGroupItems) {
    return null;
  }

  return (
    <View alignItems={alignItems} marginTop={marginTop} className={"DL_RadioGroup"}>
      <FormControl style={{ width: "100%" }} component="fieldset">
        <RadioGroup {...field} value={field.value || null} aria-label={field.name} data-cy={dataCy}>
          <RadioGroupLabels
            {...radioGroupItemsProps}
            groupValue={field.value}
            data-cy={radioGroupItemsProps.dataCy || dataCy}
          />
        </RadioGroup>
      </FormControl>
    </View>
  );
};

export { FormikRadioGroup };
