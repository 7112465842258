import React from "react";
import { ClickToComponent } from "click-to-react-component";
import AppScriptsLauncher from "utils/appScriptsLauncher";
import AppRouter from "./components/appRouter/appRouter";
import { IntlProvider } from "utils/intlProvider";
import StoreProvider from "utils/storeProvider";
import { ThemeProvider } from "@material-ui/styles";
import DLTheme from "styles/defaultTheme";
import MobileBridgeWrapper from "./utils/mobileBridgeWrapper";
import { ResponsiveProvider } from "./contexts/responsiveContext";
import { QueryClientProvider } from "utils/queryClientProvider";
import { DynamicStyle } from "styles/dynamicStyling/dynamicStyle";
import { AppInitializer } from "./utils/appInitializer";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ErrorBoundary } from "utils/errorBoundary/errorBoundary";
import { AppHelmet } from "./components/helmets/appHelmet";

export function App() {
  return (
    <AppHelmet>
      <QueryClientProvider>
        <ResponsiveProvider>
          <MobileBridgeWrapper>
            <AppScriptsLauncher>
              <StoreProvider>
                <IntlProvider>
                  <ThemeProvider theme={DLTheme}>
                    <ErrorBoundary>
                      <AppInitializer />
                      <DynamicStyle />
                      <AppRouter />
                    </ErrorBoundary>
                  </ThemeProvider>
                </IntlProvider>
              </StoreProvider>
            </AppScriptsLauncher>
          </MobileBridgeWrapper>
        </ResponsiveProvider>
        <Devtools />
      </QueryClientProvider>
    </AppHelmet>
  );
}

function Devtools() {
  return (
    <>
      {process.env.REACT_APP_REACT_QUERY_DEVELOPER_TOOLS === "true" && (
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      )}

      {process.env.REACT_APP_CLICK_TO_COMPONENT_ENABLED === "true" && (
        <ClickToComponent editor={process.env.REACT_APP_CLICK_TO_COMPONENT_EDITOR} />
      )}
    </>
  );
}
