import React, { Fragment, useEffect, useMemo, useState } from "react";
import MomentUtils from "@date-io/moment";
import {
  createValidator,
  LeaseCreditDto,
  LeaseTransactionLineBaseDto,
  LeaseWitholdDepositDto,
  LeaseWitholdDepositLineDto,
  LinkedResourceType,
  mathUtils
} from "@doorloop/dto";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { filesApi } from "api/filesApi";
import { leaseCreditApi } from "api/leaseCreditApi";
import { leasesApi } from "api/leasesApi";
import { leaseDepositsHeldByAccountApi } from "api/reportsApi";
import { NoOutstandingTransactionIcon } from "assets/icons";
import { DialogSearchPanel, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import { FormikDatePicker, FormikReferenceLabel } from "DLUI/form";
import { Icon } from "DLUI/icon";
import { Notes } from "DLUI/notes";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, FieldArray, Formik, getIn } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import LeasesList from "screens/leases/leases/leasesList/leasesList";
import SelectableLeaseListItem from "screens/leases/leases/leasesList/selectableLeaseListItem";
import DialogFrame, { getDialogFrameDimension } from "../components/dialogFrame";
import LeaseSelection from "../transactions/leaseSelection";
import DepositHeldLine from "./depositHeldLine";
import makeStyles from "./styles";
import DialogAmountView from "../components/totalAmountView";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { ListInputsContainer } from "DLUI/listItems";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
  dialogFrameWidth?: number;
  dialogFrameHeight?: number;
  transactionCreatedText: string;
  loadingTransactionDataText: string;
  loadingTransactionDataSuccessText: string;
  updatingTransactionText: string;
  transactionUpdatedText: string;
}

let formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

const validateForm = createValidator(LeaseWitholdDepositDto);

const AnimationDelay = 300;
const CreateDialogHeight = 750;

const WithholdDepositDialog: React.FC<ComponentProps> = ({
  onBackdropClick,
  onClose,
  dialogTitle,
  transactionCreatedText,
  loadingTransactionDataText,
  loadingTransactionDataSuccessText,
  updatingTransactionText,
  transactionUpdatedText
}: ComponentProps) => {
  const classes = makeStyles();
  const { isMobile } = useResponsiveHelper();
  const { t } = useTranslation();
  const { leaseId, transactionId } = useParams<any>();
  const editMode = transactionId !== undefined;
  const [viewIndex, setViewIndex] = useState(0);
  const [renderSelectionList, setRenderSelectionList] = useState<boolean>(false);
  const [currentLeaseId, setCurrentLeaseId] = useState<string | undefined>(leaseId);
  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);
  const [selectLeaseFilterObj, setSelectLeaseFilterObj] = useState({
    filter_text: ""
  });
  const [showLeaseSelectionError, setShowLeaseSelectionError] = useState<boolean>(false);
  const [leaseSelectionErrorText, setLeaseSelectionErrorText] = useState<string>("");
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const [loadingDialogSuccessText, setLoadingDialogSuccessText] = useState<string>(transactionCreatedText);
  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState<string>(loadingTransactionDataText);
  const [refundDepositData, setRefundDepositData] = useState<LeaseWitholdDepositDto | undefined>();
  const [shouldrenderForm, setShouldRenderForm] = useState<boolean>(!editMode);

  const [totalAmountHeld, setTotalAmountHeld] = useState<number>(0);

  const [accountBalance, setAccountBalance] = useState<undefined | number>();

  const [dialogFrameHeight, setDialogFrameHeight] = useState<number>(CreateDialogHeight);

  useEffect(() => {
    formikGlobalRef?.setFieldValue("lease", currentLeaseId);
    setTimeout(() => {
      if (formikGlobalRef) {
        const errorText = getIn(formikGlobalRef.errors, "lease");
        const touchedVal = getIn(formikGlobalRef.touched, "lease");

        setShowLeaseSelectionError(touchedVal && errorText !== undefined);
        if (errorText !== undefined) {
          setLeaseSelectionErrorText(t(AppStrings.Leases.LeaseCharge.PressToSelectLease));
        }
        if (currentLeaseId) {
          getDepositData();
        }
      }
    }, 0);
  }, [currentLeaseId]);

  useEffect(() => {
    // if (editMode) {
    //   loadPaymentData();
    // }
    if (currentLeaseId) {
      getDepositData();
    }
  }, []);

  useEffect(() => {
    if (formikGlobalRef && refundDepositData && refundDepositData.lines) {
      formikGlobalRef.setFieldValue("lines", refundDepositData.lines);
    }
  }, [refundDepositData]);

  const getAccountBalance = async () => {
    if (currentLeaseId) {
      const response = await leasesApi.get(currentLeaseId).catch(() => undefined);
      if (response && response.status && Boolean(response.status) && response.data) {
        return response.data.totalBalanceDue;
      }
      return undefined;
    }
  };

  const getDepositData = async () => {
    setLoadingDialogState(DialogState.Show);
    const response = await leaseDepositsHeldByAccountApi
      .getAll({
        filter_lease: currentLeaseId
      })
      .catch((e) => {
        setLoadingDialogState(DialogState.Error);
      });
    if (response && response.data && response.data.data) {
      setLoadingDialogState(DialogState.Success);

      const _accountBalance = await getAccountBalance();
      if (_accountBalance === undefined) {
        setLoadingDialogState(DialogState.Error);
        return;
      }
      const leaseWitholdDepositDto = new LeaseWitholdDepositDto();
      leaseWitholdDepositDto.populateLines(response.data.data);
      let _totalAmountHeld = 0;
      if (leaseWitholdDepositDto.lines) {
        _.forEach(leaseWitholdDepositDto.lines, (currentLine: LeaseWitholdDepositLineDto) => {
          _totalAmountHeld = mathUtils.add(_totalAmountHeld, currentLine.amountHeld || 0);
        });
      }
      setAccountBalance(_accountBalance);
      setTotalAmountHeld(_totalAmountHeld);
      setShouldRenderForm(true);
      setLoadingDialogState(DialogState.Hidden);
      setRefundDepositData(leaseWitholdDepositDto);
    }
  };

  const initFormvalues = (): LeaseWitholdDepositDto => {
    if (editMode && refundDepositData) {
      return refundDepositData;
    }

    const leaseWitholdDepositDto = new LeaseWitholdDepositDto();
    leaseWitholdDepositDto.lease = currentLeaseId;
    leaseWitholdDepositDto.lines = [new LeaseWitholdDepositLineDto()];
    leaseWitholdDepositDto.date = moment().format("YYYY-MM-DD").toString();

    return leaseWitholdDepositDto;
  };

  const isValidForm = async (formikRef: FormikProps<LeaseWitholdDepositDto>) => {
    formikRef.setFieldTouched("date");
    if (formikRef.values && formikRef.values.lines) {
      formikRef.values.lines.forEach((arrayItem: any, arrayIndex: number) => {
        formikRef.setFieldTouched(`lines[${arrayIndex}].amountToWithhold`);
      });
    }

    const errors = (await formikRef.validateForm()) as any;
    const inValidLease = Boolean(errors.lease);
    setShowLeaseSelectionError(inValidLease);
    if (inValidLease && formikGlobalRef) {
      const errorText = getIn(formikGlobalRef.errors, "lease");
      if (errorText !== undefined) {
        setLeaseSelectionErrorText(t(AppStrings.Leases.LeaseCharge.PressToSelectLease));
      }
    }
    return _.isEmpty(errors);
  };

  const loadPaymentData = async () => {
    if (editMode && transactionId) {
      setLoadingDialogState(DialogState.Show);

      const response = await leaseCreditApi.get(transactionId).catch((error) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });
      if (response && response.status && response.data) {
        // setRefundDepositData(response.data);

        // if (response.data.totalAmount && response.data.totalBalance) {
        //   setTotalpayments(
        //     response.data.totalAmount - response.data.totalBalance
        //   );
        // }
        setLoadingDialogSuccessText(loadingTransactionDataSuccessText);
        setLoadingDialogState(DialogState.Success);
        setTimeout(() => {
          setLoadingDialogState(DialogState.Hidden);
          setShouldRenderForm(true);
        }, 500);
      } else {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(response ? response.message : "");
      }
    }
  };

  const getLeaseCreditDto = (refundValues: LeaseWitholdDepositDto): LeaseCreditDto => {
    const leaseDto = new LeaseCreditDto({
      lease: refundValues.lease,
      memo: refundValues.memo,
      date: refundValues.date,
      reference: refundValues.reference,
      lines: []
    });

    _.forEach(refundValues.lines, (line) => {
      if (leaseDto.lines) {
        leaseDto.lines.push(
          new LeaseTransactionLineBaseDto({
            account: line.account,
            amount: line.amountToWithhold,
            memo: line.memo
          })
        );
      }
    });

    return leaseDto;
  };

  const refundDeposit = async () => {
    if (formikGlobalRef) {
      const refundValues = _.cloneDeep(formikGlobalRef.values) as LeaseWitholdDepositDto;
      const valuesToSend = getLeaseCreditDto(refundValues);

      setViewIndex(2);
      setLoadingDialogState(DialogState.Show);
      const results = await leaseCreditApi
        .create(valuesToSend, {
          idKey: "lease",
          translationKey: AppStrings.Toasts.custom.credit.withhold.POST
        })
        .catch((error) => {
          setLoadingDialogState(DialogState.Error);
          setLoadingDialogErrorText(error);
        });

      if (results && results.status && results.data) {
        await filesApi
          .uploadFiles(attachments, results.data.id!, LinkedResourceType.LeaseCharge)
          .catch((error: string) => {
            setLoadingDialogState(DialogState.Error);
            setLoadingDialogErrorText(error);
          });
        setLoadingDialogState(DialogState.Success);
        setTimeout(() => {
          onClose();
        }, 500);
      } else {
        setLoadingDialogErrorText(results ? results.message : "");
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const updateTransaction = async () => {
    if (formikGlobalRef && transactionId) {
      const chargeValues = _.cloneDeep(formikGlobalRef.values);
      setViewIndex(2);
      setShouldRenderForm(false);
      setLoadingDialogState(DialogState.Show);
      setLoadingDialogLoadingText(updatingTransactionText);
      const results = await leaseCreditApi.update(transactionId, chargeValues).catch((error) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });

      if (results && results.status && results.data) {
        await filesApi
          .uploadFiles(attachments, results.data.id!, LinkedResourceType.LeaseCharge)
          .catch((error: string) => {
            setLoadingDialogState(DialogState.Error);
            setLoadingDialogErrorText(error);
          });
        setLoadingDialogSuccessText(transactionUpdatedText);
        setLoadingDialogState(DialogState.Success);
        setTimeout(() => {
          onClose();
        }, 500);
      } else {
        setLoadingDialogErrorText(results ? results.message : "");
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const didPressSaveButton = async () => {
    if (formikGlobalRef !== null) {
      const isValid = await isValidForm(formikGlobalRef);
      if (isValid) {
        if (editMode) {
          updateTransaction();
        } else {
          refundDeposit();
        }
      }
    }
  };

  const getTotalAmount = () => {
    let amount = 0;

    if (formikGlobalRef && formikGlobalRef.values) {
      formikGlobalRef.values.lines.forEach((currentLine: LeaseWitholdDepositLineDto) => {
        if (currentLine.amountToWithhold && currentLine.amountToWithhold > 0) {
          amount = mathUtils.add(amount, currentLine.amountToWithhold);
        }
      });
    }
    return amount;
  };

  const renderEmptyDataView = () => (
    <View marginTop={100} justifyContent={"center"} alignItems={"center"} minHeight={200}>
      <Icon Source={NoOutstandingTransactionIcon} pathColor={"black"} />
      <Text
        marginTop={20}
        fontSize={24}
        fontWeight={800}
        color={"black"}
        value={
          currentLeaseId
            ? AppStrings.Leases.LeaseTransaction.Deposit.NoDepositHeld
            : AppStrings.Leases.LeaseCharge.SelectLease
        }
      />
    </View>
  );

  const renderLines = () => (
    <FieldArray
      name={"lines"}
      render={(arrayHelpers) => {
        if (formikGlobalRef && formikGlobalRef.values) {
          const formLines = formikGlobalRef.values.lines;

          const lines = formLines.map((currentLine: LeaseWitholdDepositLineDto, lineIndex: number) => (
            <div style={{ width: "100%" }} key={"LTL" + lineIndex}>
              <DepositHeldLine
                accountName={currentLine.accountName!}
                amountHeld={currentLine.amountHeld!}
                lineIndex={lineIndex}
              />
            </div>
          ));

          const depositsWithheldLines = getIn(formikGlobalRef.values, "lines");
          let totalDepositsWithheld = 0;
          if (depositsWithheldLines) {
            _.forEach(depositsWithheldLines, (currentItem: LeaseWitholdDepositLineDto) => {
              totalDepositsWithheld = mathUtils.add(totalDepositsWithheld, currentItem.amountToWithhold || 0);
            });
          }

          if (lines.length === 0) {
            return renderEmptyDataView();
          }

          return (
            <View paddingLeft={isMobile ? 20 : 40} paddingRight={isMobile ? 20 : 40}>
              <ListInputsContainer>{lines}</ListInputsContainer>
              <View alignItems={"flex-end"} flexDirection={"row"}>
                <View justifyContent={"flex-end"} flexDirection={"row"}>
                  <Text
                    color={"black"}
                    fontSize={16}
                    value={AppStrings.Leases.LeaseTransaction.Deposit.LeaseOutstandingBalance}
                  />
                  <View width={"auto"} alignItems={"flex-end"} minWidth={150}>
                    <Text
                      color={"black"}
                      fontSize={16}
                      paddingRight={20}
                      formatType={"currency"}
                      value={String(accountBalance)}
                      paddingLeft={20}
                    />
                  </View>
                </View>
                <View justifyContent={"flex-end"} marginTop={20} flexDirection={"row"}>
                  <Text
                    color={"lightBlue"}
                    fontSize={16}
                    value={AppStrings.Leases.LeaseTransaction.Deposit.TotalDepositsWithheld}
                  />
                  <View alignItems={"flex-end"} width={"auto"} minWidth={150}>
                    <Text
                      color={"lightBlue"}
                      fontSize={16}
                      paddingRight={20}
                      formatType={"currency"}
                      value={String(totalDepositsWithheld)}
                      paddingLeft={20}
                    />
                  </View>
                </View>
              </View>
            </View>
          );
        }
        return <div />;
      }}
    />
  );

  const renderSummaryLine = () => {
    if (formikGlobalRef) {
      const lines = getIn(formikGlobalRef.values, "lines");
      if (!lines || lines.length === 0) {
        return null;
      }
      const totalAmount = getTotalAmount();

      return (
        <View
          paddingRight={isMobile ? 20 : 40}
          paddingLeft={isMobile ? 20 : 40}
          marginTop={isMobile ? 20 : 0}
          flexDirection={"row"}
        >
          <DialogAmountView
            amount={mathUtils.substract(totalAmountHeld, totalAmount)}
            title={AppStrings.Leases.LeaseTransaction.Deposit.RemainingDeposits}
          />
        </View>
      );
    }
  };

  const onFileReceived = (files: FileListItemProps[]) => {
    setAttachments(files);
  };

  const renderAttachments = () => {
    if (formikGlobalRef) {
      const lines = getIn(formikGlobalRef.values, "lines");
      if (!lines || lines.length === 0) {
        return null;
      }
    }
    return (
      <View justifyContent={"flex-end"} width={"100%"} flex={1}>
        <View paddingLeft={isMobile ? 20 : 40} paddingRight={isMobile ? 20 : 40}>
          <FormAttachments
            editMode={editMode}
            resourceId={refundDepositData ? refundDepositData.id : undefined}
            resourceType={LinkedResourceType.LeaseCredit}
            onFileReceived={onFileReceived}
            files={attachments}
          />
          <View height={20} />
        </View>
      </View>
    );
  };

  const didPressSelectLease = () => {
    setDialogFrameHeight(window.innerHeight);
    setTimeout(() => {
      setViewIndex(1);
      setTimeout(() => {
        setRenderSelectionList(true);
      }, AnimationDelay + 200);
    }, AnimationDelay);
  };

  const didPressRemoveLease = () => {
    setCurrentLeaseId(undefined);
    if (formikGlobalRef) {
      setTotalAmountHeld(0);
      formikGlobalRef.setFieldValue("lines", []);
    }
  };

  const renderFormTopSection = () => (
    <View marginTop={20} marginBottom={20}>
      <View flexDirection={"row"} noWrap={!isMobile} gap={20}>
        <Grid item xs={12} md={6}>
          <LeaseSelection
            didPressSelectLease={didPressSelectLease}
            leaseId={currentLeaseId}
            errorTex={showLeaseSelectionError ? leaseSelectionErrorText : undefined}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FastField
            component={FormikDatePicker}
            uniqueKey={"chargeDueDate"}
            label={AppStrings.Leases.NewLease.LeaseRent.DueDate}
            name={"date"}
            noMargin
            required
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FastField component={FormikReferenceLabel} name={"reference"} backgroundColor={"dark"} />
        </Grid>
      </View>
    </View>
  );

  const renderItemsHeader = () => {
    if (isMobile) {
      return null;
    }
    if (formikGlobalRef) {
      const lines = getIn(formikGlobalRef.values, "lines");
      if (!lines || lines.length === 0) {
        return null;
      }

      return (
        <View marginTop={20} paddingLeft={isMobile ? 20 : 50} paddingRight={isMobile ? 20 : 40}>
          <View flexDirection="row" noWrap gap={20}>
            <Grid item xs={12} md={4}>
              <Text value={AppStrings.Leases.LeaseTransaction.Payment.DepositAccount} color={"black"} fontSize={16} />
            </Grid>
            <Grid item xs={10} md={2}>
              <View alignItems="flex-end">
                <Text value={AppStrings.Leases.LeaseTransaction.Deposit.AmountHeld} color={"black"} fontSize={16} />
              </View>
            </Grid>
            <Grid item xs={12} md={3}>
              <Text value={AppStrings.Leases.LeaseCharge.Description} color={"black"} fontSize={16} />
            </Grid>
            <Grid item xs={2} md={3}>
              <Text value={AppStrings.Leases.LeaseTransaction.Deposit.AmountToWithhold} color={"black"} fontSize={16} />
            </Grid>
          </View>
        </View>
      );
    }
  };

  const renderLeaseSelectionInstructions = () => (
    <View width={"100%"} marginTop={140} justifyContent={"center"} alignItems={"center"}>
      <Icon Source={NoOutstandingTransactionIcon} pathColor={"black"} />
      <Text
        fullWidth
        align={"center"}
        fontSize={22}
        color={"black"}
        marginTop={20}
        value={AppStrings.Leases.LeaseCharge.SelectLease}
      />
    </View>
  );

  const renderNotes = () => (
    <View
      paddingLeft={isMobile ? 20 : 40}
      paddingRight={isMobile ? 20 : 40}
      justifyContent={"center"}
      marginTop={isMobile ? 20 : 0}
      height={50}
    >
      <FastField component={Notes} height={30} name={"memo"} />
    </View>
  );

  const renderForm = () => {
    const formInitialValues = initFormvalues();
    return (
      <Formik initialValues={formInitialValues} onSubmit={(values, { setSubmitting }) => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;
          return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <View width={"100%"} flex={1}>
                <View paddingLeft={isMobile ? 20 : 40} paddingRight={isMobile ? 20 : 40} flexDirection={"column"}>
                  <View className={classes.sectionSeparator}>{renderFormTopSection()}</View>
                </View>
                {currentLeaseId ? (
                  <Fragment>
                    {renderItemsHeader()}
                    {renderLines()}
                    {renderNotes()}
                    {renderSummaryLine()}
                    {renderAttachments()}
                  </Fragment>
                ) : (
                  renderLeaseSelectionInstructions()
                )}
              </View>
            </MuiPickersUtilsProvider>
          );
        }}
      </Formik>
    );
  };

  const renderLeaseSelectionList = () => {
    const didChangeSearchQuery = (value: string) => {
      setSelectLeaseFilterObj({
        filter_text: value
      });
    };

    const onListItemPress = (leaseId: string) => {
      setDialogFrameHeight(CreateDialogHeight);
      setTimeout(() => {
        setViewIndex(0);
        setCurrentLeaseId(leaseId);
      }, AnimationDelay);
    };

    return (
      <View paddingLeft={20} paddingRight={20}>
        <View autoWidth>
          <DialogSearchPanel borderRadius={30} onChange={didChangeSearchQuery} />
        </View>
        <View>
          {renderSelectionList ? (
            <div id={"selectableLeaseListItem"} style={{ width: "100%" }}>
              <LeasesList
                ListItem={SelectableLeaseListItem}
                filterObj={selectLeaseFilterObj}
                didPressListItem={onListItemPress}
                stickyHeaderId={"selectableLeaseListItem"}
                selectableItem
              />
            </div>
          ) : null}
        </View>
      </View>
    );
  };

  const renderView = ({ index }: any) => {
    const onRetryButtonPress = async () => {
      await didPressSaveButton();
      //refundDeposit();
    };
    const didPressDismissButton = () => {
      setViewIndex(0);
    };

    if (index === 0) {
      if (loadingDialogState !== DialogState.Hidden) {
        return (
          <View width={"100%"} flex={1}>
            <LoadingDialog
              dialogState={loadingDialogState}
              loadingText={loadingDialogLoadingText}
              errorText={loadingDialogErrorText}
              successText={loadingDialogSuccessText}
              onRetryButtonPress={getDepositData}
              didPressDismissButton={didPressDismissButton}
            />
          </View>
        );
      }
      if (shouldrenderForm) {
        return renderForm();
      }
      return <div />;
    }
    if (index === 1) {
      return renderLeaseSelectionList();
    }
    if (index === 2) {
      return (
        <View alignItems={"center"} justifyContent={"center"} height={"100%"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
          />
        </View>
      );
    }
    return <div />;
  };

  const _onBackdropClick = () => {
    if (viewIndex === 1) {
      setViewIndex(0);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const renderActionPanelButtons = () => (
    <FormActionButtons
      propsSubButton={{ onClick: _onBackdropClick }}
      propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
    />
  );

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      if (loadingDialogState !== DialogState.Hidden) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }
    if (viewIndex === 1) {
      return "topPanel";
    }
    return "contentOnly";
  }, [viewIndex, loadingDialogState]);

  const currentTitle = useMemo(() => {
    if (viewIndex === 0) {
      return dialogTitle;
    }

    if (viewIndex === 1) {
      return AppStrings.Leases.LeaseCharge.SelectLease;
    }
    return "";
  }, [viewIndex]);

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={currentTitle}
      width={1000}
      height={getDialogFrameDimension("height", dialogFrameHeight)}
      renderView={renderView}
      numViews={3}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={frameType}
    />
  );
};

export default WithholdDepositDialog;
