import type { RentalApplicationCompanySettingsDto } from "@doorloop/dto";
import { RentalApplicationAttachmentSettingsDto } from "@doorloop/dto";
import { AddIcon } from "assets/icons";
import { Button } from "DLUI/button";
import { View } from "DLUI/view";
import { FieldArray, getIn, useFormikContext } from "formik";
import AppStrings from "locale/keys";
import React, { Fragment } from "react";
import AttachmentItem from "./attachmentItem";
import { v4 as uuid } from "uuid";
import _ from "lodash";

const AttachmentsList: React.FC<{}> = () => {
  const {
    values: { filesAndDocuments },
    setFieldValue
  } = useFormikContext<RentalApplicationCompanySettingsDto>();

  const renderElements = () => (
    <FieldArray
      name={"filesAndDocuments.files"}
      render={(arrayHelpers) => {
        const filesArray: RentalApplicationAttachmentSettingsDto[] = getIn(filesAndDocuments, "files");

        const didPressAddElement = () => {
          const rentalApplicationSettingsAttachmentDto = new RentalApplicationAttachmentSettingsDto();

          arrayHelpers.push(rentalApplicationSettingsAttachmentDto);
        };

        const renderAddNewButton = () => {
          let buttonText = AppStrings.Common.AddAnotherFile;

          if (_.isEmpty(filesArray)) {
            buttonText = AppStrings.Common.AddFile;
          }

          return (
            <View alignItems={"center"}>
              <Button
                color={"lightBlue"}
                type={"inlineText"}
                actionText={buttonText}
                onClick={didPressAddElement}
                LeftIcon={AddIcon}
                iconSize={15}
                marginTop={10}
                applyColorForIcons
              />
            </View>
          );
        };

        if (filesArray) {
          filesArray.forEach((element) => {
            if (!element.uniqueIndex) {
              element.uniqueIndex = uuid();
            }
          });
          return (
            <Fragment>
              {filesArray.map((currentElement, index) => (
                <Fragment key={"AIA" + currentElement.uniqueIndex}>
                  <AttachmentItem itemIndex={index} arrayHelpers={arrayHelpers} />
                </Fragment>
              ))}
              {renderAddNewButton()}
            </Fragment>
          );
        }

        return <Fragment>{renderAddNewButton()}</Fragment>;
      }}
    />
  );

  return (
    <View marginTop={20}>
      <View alignItems={"center"} backgroundColor={"dark"} borderRadius={10}>
        {renderElements()}
        <View height={20} />
      </View>
    </View>
  );
};

export default AttachmentsList;
