import type { UserRoleDto } from "@doorloop/dto";
import { ReportPermission, SettingPermission, SettingsAccessEnum } from "@doorloop/dto";
import type { FC } from "react";
import React, { useCallback } from "react";
import { getIn, useFormikContext } from "formik";
import { Grid } from "@material-ui/core";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { SeparationLine } from "DLUI/separatorView";
import { useTranslation } from "react-i18next";
import AppStrings from "locale/keys";
import { useResponsiveHelper } from "../../../../../../contexts/responsiveContext";
import UnControlledRadioGroup from "DLUI/form/radioGroup/unControlledRadioGroup";

interface ObjectPermissionProps {
  permission: SettingPermission;
  label: string;
  isLast: boolean;
}

interface MobileRadioProps {
  label: string;
}
const MobileRadioLabel: FC<MobileRadioProps> = ({ label }: MobileRadioProps) => {
  const { isMobile } = useResponsiveHelper();
  const { t } = useTranslation();
  return isMobile ? (
    <Grid item xs={4}>
      <Text lineHeight={"40px"}>{t(label)}</Text>
    </Grid>
  ) : null;
};

export const SettingsPermissionToggle: FC<ObjectPermissionProps> = ({
  permission,
  label,
  isLast
}: ObjectPermissionProps) => {
  const { isScreenSizeIn } = useResponsiveHelper();
  const formikRef = useFormikContext<UserRoleDto>();
  const currentValue = getIn(formikRef.values, permission);
  const onChange = useCallback(
    (newValue) => {
      if (permission === SettingPermission.managementFees) {
        if (newValue === "edit" || newValue === "view") {
          formikRef.setFieldValue(ReportPermission.managementFees, true);
        } else {
          formikRef.setFieldValue(ReportPermission.managementFees, false);
        }
      }
      formikRef.setFieldValue(permission, newValue);
    },
    [permission]
  );

  return (
    <View flexDirection="row" style={{ width: "100%" }}>
      <Grid container>
        <Grid item xs={12} md={6}>
          {label}
          <View height={10} />
        </Grid>
        <Grid item xs={12} md={6}>
          <UnControlledRadioGroup
            didChangeSelection={onChange}
            uniqueKey={permission}
            flexDirection={"row"}
            xs={12}
            md={4}
            defaultValue={currentValue || SettingsAccessEnum.NONE}
            radioGroupItems={[
              {
                label: AppStrings.UserRoles.NoAccess,
                value: SettingsAccessEnum.NONE
              },
              {
                label: AppStrings.UserRoles.ViewOnly,
                value: SettingsAccessEnum.VIEW
              },
              {
                label: AppStrings.UserRoles.Edit,
                value: SettingsAccessEnum.EDIT
              }
            ]}
          />
        </Grid>
        {!isLast && isScreenSizeIn(["xs", "sm"]) && (
          <SeparationLine width={"100%"} height={1} marginBottom={20} marginTop={10} />
        )}
      </Grid>
    </View>
  );
};
