import { createContext, useContext } from "react";

export interface PopupContextProps {
  onClose: () => void;
  open: boolean;
  hideBackdrop?: boolean;
}

export const PopupContext = createContext<PopupContextProps>({} as PopupContextProps);

export const usePopupContext = () => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error("PopupContext must be used within PopupProvider");
  }
  return context;
};
