import type { GetAllTenantsQuery, TenantDto } from "@doorloop/dto";
import { TenantPortalServerRoutes } from "@doorloop/dto";

import { RestApiBaseWithDictionary } from "api/restApiBaseWithDictionary";

export class TenantPortalTenantsApi extends RestApiBaseWithDictionary<TenantDto, GetAllTenantsQuery> {
  constructor() {
    super(TenantPortalServerRoutes.TENANTS);
    this.restRoute = TenantPortalServerRoutes.TENANTS;
  }
}

export const tenantPortalTenantsApi = new TenantPortalTenantsApi();
