import React from "react";
import { View } from "DLUI/view";
import DialogAmountView from "DLUI/dialogs/components/totalAmountView";
import ColorsEnum from "../../../../../utils/colorsEnum";
import AppStrings from "../../../../../locale/keys";
import type { ExpenseTransactionLineDto, LeaseChargeDto, VendorBillDto } from "@doorloop/dto";
import { mathUtils } from "@doorloop/dto";
import type { FormikProps } from "formik";

interface Props {
  editMode?: boolean;
  chargeData?: LeaseChargeDto;
  formikRef: FormikProps<VendorBillDto>;
  hasLinkedPayments?: boolean;
}

const SummaryLine: React.FC<Props> = ({ editMode, chargeData, formikRef, hasLinkedPayments }: Props) => {
  const getTotalAmount = () => {
    let amount = 0;
    formikRef.values?.lines?.forEach((currentLine: ExpenseTransactionLineDto) => {
      if (currentLine.amount) {
        amount = mathUtils.add(amount, currentLine.amount);
      }
    });
    return amount;
  };

  const totalAmount = getTotalAmount();

  const showTotalAmount =
    editMode &&
    chargeData?.totalBalance &&
    chargeData?.totalAmount &&
    chargeData?.totalBalance !== chargeData?.totalAmount;

  return (
    <View justifyContent={"flex-end"} width={"100%"} alignItems={"flex-end"} flex={1}>
      <View marginBottom={20} justifyContent={"flex-end"} flexDirection={"row"}>
        {Boolean(showTotalAmount) && (
          <DialogAmountView
            amount={totalAmount}
            title={AppStrings.Leases.LeaseCharge.TotalAmount}
            autoWidth
            underLineColor={ColorsEnum.Black}
            marginRight={40}
          />
        )}
        <DialogAmountView
          amount={editMode && hasLinkedPayments && chargeData?.totalBalance ? chargeData.totalBalance : totalAmount}
          title={AppStrings.Leases.LeaseCharge.BalanceDue}
          autoWidth
        />
      </View>
    </View>
  );
};

export default SummaryLine;
