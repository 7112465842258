import type { ChangeEvent } from "react";
import React from "react";
import Switch from "@material-ui/core/Switch";
import { View } from "DLUI/view";
import { useStylesSwitchButton } from "DLUI/form/switchButton/base/useStylesSwitchButton";
import { InputLabel } from "DLUI/form/label/inputLabel";
import type { ControlledToggleInputBaseTypes } from "DLUI/form/types/toggleInputBaseTypes";
import { useReadOnlyContext } from "../../../../../contexts/readOnlyContext";

export function ControlledSwitch<T>({
  labelValueProps,
  size,
  checked,
  onChange,
  disabled,
  fullWidth,
  required,
  dataCy,
  name,
  value,
  id,
  style
}: ControlledToggleInputBaseTypes<T>) {
  const isReadOnly = useReadOnlyContext();
  const { classNameContainer, classNameInput } = useStylesSwitchButton({
    size,
    labelPlacement: labelValueProps?.placement
  });

  const didClickLabel = () => {
    onChange?.(!checked);
  };

  const didChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.checked);
  };

  return (
    <View noWrap className={classNameContainer} width={fullWidth ? "100%" : "auto"} {...style}>
      <Switch
        value={value}
        id={id}
        name={name}
        data-cy={dataCy}
        checked={checked}
        onChange={didChangeInput}
        required={required}
        disabled={isReadOnly || disabled}
        classes={{
          root: classNameInput.root,
          switchBase: classNameInput.switchBase,
          thumb: classNameInput.thumb,
          track: classNameInput.track,
          checked: classNameInput.checked
        }}
      />
      {labelValueProps?.text && (
        <InputLabel
          spaceLabel={true}
          size={size}
          disabled={disabled}
          fullWidth={fullWidth}
          onClick={didClickLabel}
          text={labelValueProps?.text}
          placement={labelValueProps?.placement}
          clickable={labelValueProps?.clickable}
          color={labelValueProps?.color}
        />
      )}
    </View>
  );
}
