import type { FC } from "react";
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { View } from "DLUI/view";
import moment from "moment";
import EmailConfirmation from "./emailConfirmation";
import "./styles.css";

import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { notificationsContentVisibilityAtom } from "components/layouts/layoutAtom";
import { useSetAtom } from "jotai";
import { useSelector } from "react-redux";
import type { IRootState } from "store";
import { useLocation } from "react-router-dom";

export type NotificationsPanelType = "emailConfirmation";

interface ComponentProps {
  children: React.ReactNode;
  type: NotificationsPanelType;
}

type AnimationType = "show" | "showMobile" | "hide" | "";

const Storagekey = "email_confirmation_display_time";
const DisplayInterval = 60;
export const NOTIFICATIONS_CONTENT_DESKTOP_HEIGHT = 50;
export const NOTIFICATIONS_CONTENT_MOBILE_HEIGHT = 80;
export const NOTIFICATIONS_ANIMATION_TIME = 350;

const NotificationsPanel: React.FC<ComponentProps> = ({ children, type }: ComponentProps) => {
  const { isTabletOrMobile, isMobile } = useResponsiveHelper();
  const [animationClass, setAnimationClass] = useState<AnimationType>("hide");
  const [childrenContainerHeight, setChildrenContainerHeight] = useState<string>("100%");
  const setNotificationsContentVisibilityAtom = useSetAtom(notificationsContentVisibilityAtom);
  const [shouldRenderNotificationContent, setShouldRenderNotificationContent] = useState<boolean>(false);
  const isEmailConfirmed = useSelector((state: IRootState) => state.auth.currentLoginResponse?.isEmailConfirmed);
  const location = useLocation();

  const showIntervalValid = () => {
    let isValid: boolean;
    const lastDisplayTime = sessionStorage.getItem(Storagekey);

    if (lastDisplayTime === null) {
      isValid = true;
      sessionStorage.setItem(Storagekey, moment().toString());
    } else if (animationClass === "hide") {
      const _lastDisplayTime = new Date(lastDisplayTime).toISOString();
      isValid = moment() > moment(_lastDisplayTime).add(DisplayInterval, "s");

      if (isValid) {
        sessionStorage.setItem(Storagekey, moment().toString());
      }
    } else {
      isValid = true;
    }

    return isValid;
  };

  useEffect(() => {
    const isSubscriptionPage = location.pathname.includes("/subscription");

    if (
      shouldRenderNotificationContent &&
      (type !== "emailConfirmation" || !isSubscriptionPage) &&
      showIntervalValid()
    ) {
      setChildrenContainerHeight(
        `calc(100% - ${isMobile ? NOTIFICATIONS_CONTENT_MOBILE_HEIGHT : NOTIFICATIONS_CONTENT_DESKTOP_HEIGHT}px)`
      );

      setTimeout(() => {
        setAnimationClass(isMobile ? "showMobile" : "show");
      }, 0);
    } else {
      setAnimationClass("hide");
      if (childrenContainerHeight !== "100%") {
        setTimeout(() => {
          setChildrenContainerHeight("100%");
        }, NOTIFICATIONS_ANIMATION_TIME);
      }

      setShouldRenderNotificationContent(false);
    }
  }, [shouldRenderNotificationContent, location.pathname]);

  useEffect(() => {
    if (isEmailConfirmed === false) {
      setShouldRenderNotificationContent(true);
      setNotificationsContentVisibilityAtom(true);
    }
  }, [isEmailConfirmed]);

  const didPressDismissNotificationsPanel = () => {
    setNotificationsContentVisibilityAtom(false);

    setTimeout(() => {
      setShouldRenderNotificationContent(false);
    }, NOTIFICATIONS_ANIMATION_TIME);
  };

  const renderNotificationContent = () => {
    switch (type) {
      case "emailConfirmation":
        return (
          <NotificationContainer animationClass={animationClass}>
            <EmailConfirmation onDismissButtonPress={didPressDismissNotificationsPanel} />
          </NotificationContainer>
        );
    }
  };

  return (
    <View height={isTabletOrMobile ? "100%" : "100vh"} style={{ position: isTabletOrMobile ? "fixed" : undefined }}>
      {shouldRenderNotificationContent && renderNotificationContent()}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxHeight: childrenContainerHeight,
          width: "100%",
          height: "100%",
          overflowY: isMobile ? "hidden" : "inherit"
        }}
        className={"notificationsPanelChildrenContainer"}
      >
        {children}
      </div>
    </View>
  );
};

interface NotificationContainerProps {
  animationClass: AnimationType;
}

const NotificationContainer: FC<NotificationContainerProps> = ({ animationClass, children }) => (
  <div className={clsx("DLUI_NotificationsPanel", animationClass)}>{children}</div>
);

export default NotificationsPanel;
