import type { PropertyDto } from "@doorloop/dto";
import { propertiesApi } from "api/propertiesApi";
import type { DialogState } from "DLUI/dialogs/loadingDialog";
import { AnimatedContentDialog } from "DLUI/dialogs";
import AppStrings from "locale/keys";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ObjectPermission, PropertyClass, LinkedResourceType } from "@doorloop/dto";
import { getPropertyFormMenuItems } from "DLUI/dialogs/settings/properties/editProperty/editPropertyMenuItems";
import type { OperationsBuilder } from "engines/bulkOperationsEngine";
import {
  initFormValues,
  validateForm,
  validatePropertyForm
} from "DLUI/dialogs/settings/properties/editProperty/formikHelper";
import { useParams } from "react-router-dom";
import { RUGContext, useRUG } from "@/components/common/pictureGallery/pictureGallery.hooks";
import { getDialogFrameDimension } from "../../../components/dialogFrame";

interface ComponentProps {
  dialogState: DialogState;
  onClose: () => void;
  onBackdropClick: () => void;
}

const EditPropertyDialog: React.FC<ComponentProps> = ({ dialogState, onClose, onBackdropClick }: ComponentProps) => {
  const { t } = useTranslation();

  const [propertyClass, setPropertyClass] = useState("");
  const [operationsBuilder, setOperationsBuilder] = useState<OperationsBuilder>();

  const operationsBuilderSetter = useCallback(
    (builder: OperationsBuilder) => setOperationsBuilder(() => builder),
    [setOperationsBuilder]
  );

  const { propertyId } = useParams<{ propertyId?: string }>();

  const rugValue = useRUG({
    resourceId: propertyId,
    resourceType: LinkedResourceType.Property,
    onOperationsChange: operationsBuilderSetter
  });

  const editPropertyDialogItems = useMemo(
    () =>
      getPropertyFormMenuItems(setPropertyClass).filter(
        ({ category }) => propertyClass !== PropertyClass.COMMERCIAL || !category
      ),
    [propertyClass]
  );

  return (
    <RUGContext.Provider value={rugValue}>
      <AnimatedContentDialog
        dialogState={dialogState}
        onClose={onClose}
        sectionItems={editPropertyDialogItems}
        disableBackdropClick
        onBackdropClick={onBackdropClick}
        getformikInitialValues={initFormValues}
        formikValidation={validateForm}
        validationMethod={validatePropertyForm}
        loadingText={t(AppStrings.Users.NewUser.LoadingText)}
        errorText={t(AppStrings.Users.NewUser.ErrorText)}
        successText={t(AppStrings.Users.NewUser.SuccessText)}
        apiMethod={propertiesApi}
        dialogTitle={t(AppStrings.Properties.Settings.EditProperty)}
        dialogHeight={870}
        dialogWidth={getDialogFrameDimension("width", 1200)}
        permission={ObjectPermission.properties}
        onDataLoad={(propertyDto: PropertyDto) => propertyDto.class && setPropertyClass(propertyDto.class)}
        bulkOperationsBuilder={operationsBuilder}
      />
    </RUGContext.Provider>
  );
};

export default EditPropertyDialog;
