import { useEffect, useLayoutEffect, useRef } from "react";
import throttle from "lodash/throttle";

type ResizeCallback = (entries: ResizeObserverEntry[]) => void;

export interface UseResizeOptions {
  enabled?: boolean;
  element?: HTMLElement | null;
  debounceTime?: number;
}

type UseResize = (callback: ResizeCallback, options?: UseResizeOptions) => void;

export const useResize: UseResize = (
  callback: ResizeCallback,
  { enabled = true, element = document.body, debounceTime = 1000 } = {}
) => {
  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const memoizedCallback = useRef<ResizeCallback>(callback);
  useLayoutEffect(() => {
    memoizedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!element || !enabled) return;

    const throttledCallback = throttle(memoizedCallback.current, debounceTime);
    const resizeObserver = new ResizeObserver(throttledCallback);
    resizeObserver.observe(element);

    resizeObserverRef.current = resizeObserver;

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
        resizeObserverRef.current = null;
      }
    };
  }, [debounceTime, element, enabled]);
};
