import { ServerRoutes } from "@doorloop/dto";
import { RestApiBase } from "./restApiBase";
import { tenantsApi } from "api/tenantsApi";
import { vendorsApi } from "api/vendorsApi";
import { ownersApi } from "api/ownersApi";
import type { GetOutgoingPaymentsReportQuery } from "@doorloop/dto";
import type { OutgoingPaymentsReportItemDto } from "@doorloop/dto";
import { accountsApi } from "api/accounts";
import { usersApi } from "api/usersApi";

export class OutgoingPaymentsReportApi extends RestApiBase<
  OutgoingPaymentsReportItemDto,
  GetOutgoingPaymentsReportQuery
> {
  constructor() {
    super(ServerRoutes.REPORTS_OUTGOING_PAYMENTS, [tenantsApi, vendorsApi, ownersApi, usersApi, accountsApi]);
    this.restRoute = ServerRoutes.REPORTS_OUTGOING_PAYMENTS;
  }
}

export const outgoingPaymentsReportApi = new OutgoingPaymentsReportApi();
