import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => {
  return {
    mobileDialogContainer: {
      width: window.innerWidth,
      height: window.innerHeight,
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.screenBackground.main
    },
    dialogContainer: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.screenBackground.main,
      width: "100%",
      height: "100%"
    }
  };
});
