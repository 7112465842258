import { LeaseCreditDto } from "@doorloop/dto";
import { RecurringTransactionType } from "@doorloop/dto";
import AppStrings from "locale/keys";
import React from "react";
import Transaction from "../transaction";
import { leasesRecurringTransactionsApi } from "api/leasesApi";
import { LinkedResourceType } from "@doorloop/dto";
import { ObjectPermission } from "@doorloop/dto";

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
}

const RecurringCreditDialog: React.FC<ComponentProps> = ({ onBackdropClick, onClose }: ComponentProps) => (
  <Transaction
    onBackdropClick={onBackdropClick}
    onClose={onClose}
    dialogTitle={AppStrings.Leases.NewLease.LeaseRent.RecurringCredit}
    apiMethod={leasesRecurringTransactionsApi}
    transactionDto={LeaseCreditDto.prototype}
    transactionCreatedText={AppStrings.Leases.LeaseTransaction.Credit.CreatedText}
    loadingTransactionDataText={AppStrings.Leases.LeaseTransaction.Credit.LoadingData}
    loadingTransactionDataSuccessText={AppStrings.Leases.LeaseTransaction.Credit.LoadingDataSuccess}
    updatingTransactionText={AppStrings.Leases.LeaseTransaction.Credit.Updating}
    transactionUpdatedText={AppStrings.Leases.LeaseTransaction.Credit.Updated}
    type={RecurringTransactionType.LEASE_CREDIT}
    linkedResourceType={LinkedResourceType.RecurringTransaction}
    permission={ObjectPermission.leaseCredits}
    customDateLabel={AppStrings.Leases.NewLease.LeaseRent.DateIssued}
    accountFilterClass={"filter_leaseCreditItem"}
  />
);

export default RecurringCreditDialog;
