import { useCallback, useEffect, useState, useMemo } from "react";
import { Formik } from "formik";
import type { RecurringTransactionDto } from "@doorloop/dto";
import {
  CheckPayableInfoDto,
  createValidator,
  DateFormats,
  ExpenseDto,
  ExpenseTransactionLineDto,
  PaymentMethod
} from "@doorloop/dto";
import moment from "moment";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { useParams } from "react-router-dom";
import { LoadingDialog, VendorExpenseDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";

import { expensesApi } from "api/expensesApi";
import { View } from "DLUI/view";
import { v4 as uuid } from "uuid";
import { useRecurringState } from "hooks/useRecurringState";
import AppStrings from "locale/keys";
import { leasesRecurringTransactionsApi } from "api/leasesApi";
import { useTranslation } from "react-i18next";
import type { ApiResult } from "api/apiResult";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import { QueryParams } from "../../../../../utils/queryParams";
import { cleanExpenseDataForDuplicateMode, DUPLICATE_MODE_QUERY_PARAMS } from "DLUI/dialogs/duplicateModeHelper";
import { noop } from "lodash";

export interface ExpenseComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
  permission?: AnyPermissionClearance;
  editingRecurring?: boolean;
}

const validateForm = createValidator(ExpenseDto);

const ExpenseFormikContextWrapper: React.FC<ExpenseComponentProps> = ({
  onBackdropClick,
  onClose,
  dialogTitle,
  permission,
  editingRecurring: isEditingRecurring
}: ExpenseComponentProps) => {
  const expenseDialogFrameWidth = getDialogFrameDimension("width", 1250);
  const expenseDialogFrameHeight = getDialogFrameDimension("height", 1040);

  const { t } = useTranslation();
  const routeParams = useParams<{ transactionId: string }>();

  const queryParamsInstance = useMemo(() => new QueryParams(), []);
  const queryParams = {
    transactionId: queryParamsInstance.get(DUPLICATE_MODE_QUERY_PARAMS.transactionId) as string | undefined,
    paymentMethod: queryParamsInstance.get("paymentMethod") as PaymentMethod | undefined,
    payFromAccount: queryParamsInstance.get("filter_payableInfoPayFromAccount") as string | undefined
  };

  const transactionId = queryParams.transactionId || routeParams.transactionId;

  const isEditMode = Boolean(transactionId);
  const isDuplicateMode = Boolean(queryParams.transactionId);

  const { setRecurringData, RecurringContextWrapper } = useRecurringState(isEditMode, isEditingRecurring);
  const [expenseDto, setExpenseDto] = useState<ExpenseDto>();
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState("");
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    isEditMode ? DialogState.Show : DialogState.Hidden
  );

  const showErrorMessage = useCallback(
    (message: string = t(AppStrings.Common.NetworkErrorSubTitle)) => {
      setLoadingDialogState(DialogState.Error);
      setLoadingDialogErrorText(message);
    },
    [t]
  );

  const handleExpenseResponse = useCallback(
    (response: ApiResult<ExpenseDto | RecurringTransactionDto> | void) => {
      if (response?.status && response.data) {
        let expenseDto: ExpenseDto;
        if (isEditingRecurring) {
          const recurringTransactionDto = response.data as RecurringTransactionDto;
          expenseDto = new ExpenseDto(recurringTransactionDto.expenseInfo);
          setRecurringData(recurringTransactionDto);
          // eslint-disable-next-line arrow-body-style
          expenseDto.lines = expenseDto.lines?.map((line) => ({ ...line, uniqueKey: uuid() }));
        } else {
          expenseDto = response.data;
        }
        setExpenseDto(isDuplicateMode ? cleanExpenseDataForDuplicateMode(expenseDto) : expenseDto);
        setLoadingDialogState(DialogState.Hidden);
      } else {
        showErrorMessage(response?.message);
      }
    },
    [isDuplicateMode, isEditingRecurring, setRecurringData, showErrorMessage]
  );

  const fetchExpense = useCallback(async () => {
    if (isEditMode) {
      setLoadingDialogState(DialogState.Show);
      handleExpenseResponse(
        isEditingRecurring
          ? await leasesRecurringTransactionsApi.get(transactionId).catch(showErrorMessage)
          : await expensesApi.get(transactionId).catch(showErrorMessage)
      );
    }
  }, [isEditMode, isEditingRecurring, handleExpenseResponse, showErrorMessage, transactionId]);

  useEffect(() => {
    if (isEditMode) {
      fetchExpense();
    }
  }, [isEditMode, fetchExpense]);

  const initialValues = useMemo(() => {
    const paymentMethod = queryParams.paymentMethod;
    const payFromAccount = queryParams.payFromAccount;

    if (isEditMode && expenseDto) {
      return expenseDto;
    }

    const initialExpenseDto = new ExpenseDto({
      date: moment().format(DateFormats.ISO_DATE_SERVER_FORMAT).toString(),
      lines: [new ExpenseTransactionLineDto({ uniqueKey: uuid() })],
      payFromAccount,
      paymentMethod
    });

    if (paymentMethod === PaymentMethod.CHECK) {
      initialExpenseDto.checkInfo = new CheckPayableInfoDto();
      initialExpenseDto.checkInfo.printLater = true;
    }

    return initialExpenseDto;
  }, [expenseDto, isEditMode, queryParams.payFromAccount, queryParams.paymentMethod]);

  if (loadingDialogState !== DialogState.Hidden) {
    return (
      <View
        width={expenseDialogFrameWidth}
        height={expenseDialogFrameHeight}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <LoadingDialog
          dialogState={loadingDialogState}
          errorText={loadingDialogErrorText}
          didPressDismissButton={onBackdropClick}
          onRetryButtonPress={fetchExpense}
          minHeight={expenseDialogFrameHeight}
        />
      </View>
    );
  }

  return (
    <RecurringContextWrapper>
      <Formik initialValues={initialValues} onSubmit={noop} validate={validateForm}>
        <VendorExpenseDialog
          onClose={onClose}
          onBackdropClick={onBackdropClick}
          dialogTitle={dialogTitle}
          permission={permission}
          dialogFrameHeight={expenseDialogFrameHeight}
          dialogFrameWidth={expenseDialogFrameWidth}
        />
      </Formik>
    </RecurringContextWrapper>
  );
};

export default ExpenseFormikContextWrapper;
