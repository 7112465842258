import AppStrings from "locale/keys";
import ColorsEnum from "@/utils/colorsEnum";
import React, { type CSSProperties, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { entityApiStore } from "@/api/entityApiStore/entityApiStore";
import type { BaseDto } from "@doorloop/dto";

interface ActivityLabelProps<T extends BaseDto> {
  item?: T;
  style?: CSSProperties;
}

enum Formats {
  ACTIVITY_DATE = "MM/DD/YYYY",
  ACTIVITY_TIME = "HH:mm"
}

export const ActivityLabel = <T extends BaseDto>({ item, style = {}, ...rest }: ActivityLabelProps<T>) => {
  const { createdAt, updatedAt, updatedBy, createdBy } = item || {};
  const { t } = useTranslation();

  const { data: userDataCreatedBy } = entityApiStore.users.queries.useGetOneFromDictionary(createdBy, {
    enabled: Boolean(item?.createdBy)
  });
  const { data: userDataUpdatedBy } = entityApiStore.users.queries.useGetOneFromDictionary(updatedBy, {
    enabled: Boolean(item?.updatedBy)
  });

  const activity = useMemo(() => {
    let entityCreated = "";
    let entityUpdated = "";

    const dateCreated = moment(createdAt).format(Formats.ACTIVITY_DATE);
    const dateUpdated = moment(updatedAt).format(Formats.ACTIVITY_DATE);

    const timeCreated = moment(createdAt).format(Formats.ACTIVITY_TIME);
    const timeUpdated = moment(updatedAt).format(Formats.ACTIVITY_TIME);

    if (!createdBy) {
      entityCreated = t(AppStrings.Common.System);
    } else {
      entityCreated = userDataCreatedBy?.fullName || userDataCreatedBy?.name || "";
    }
    if (!updatedBy) {
      entityUpdated = t(AppStrings.Common.System);
    } else {
      entityUpdated = userDataUpdatedBy?.fullName || userDataUpdatedBy?.name || "";
    }

    return { entityCreated, entityUpdated, dateCreated, dateUpdated, timeCreated, timeUpdated };
  }, [createdAt, updatedAt, createdBy, updatedBy, userDataCreatedBy, userDataUpdatedBy, t]);

  const shouldShowUpdatedAt = createdAt !== updatedAt && item?.updatedAt;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        fontSize: "0.875rem",
        padding: "0.5rem 0",
        width: "100%",
        color: ColorsEnum.BrightGray,
        fontStyle: "italic",
        textAlign: "center",
        ...style
      }}
      aria-label="updated-at-label"
      {...rest}
    >
      <span style={{ display: "flex", justifyContent: "center", marginBottom: shouldShowUpdatedAt ? "0.5rem" : 0 }}>
        {t(AppStrings.Common.CreatedByOn, {
          who: activity.entityCreated,
          date: activity.dateCreated,
          time: activity.timeCreated
        })}
      </span>
      {shouldShowUpdatedAt && (
        <span style={{ display: "flex", justifyContent: "center" }}>
          {t(AppStrings.Common.UpdatedByOn, {
            who: activity.entityUpdated,
            date: activity.dateUpdated,
            time: activity.timeUpdated
          })}
        </span>
      )}
    </div>
  );
};
