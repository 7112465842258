import { differenceBy } from "lodash";

interface AnalysisInterface<T> {
  added: T[];
  removed: T[];
  changed: T[];
}

export class DifferenceEngine<T> {
  initialCollection: T[] = [];
  idKey: keyof T;

  constructor(idKey: keyof T) {
    this.idKey = idKey;
  }

  setInitialCollection(initialCollection: T[]) {
    this.initialCollection = initialCollection;
  }

  analyze(initialCollectionBase: T[], newCollection: T[]): AnalysisInterface<T> {
    const initialCollection = initialCollectionBase || this.initialCollection;
    return {
      // Filter all items from new that don't exist on initial
      added: newCollection.filter(
        (item) => !initialCollection.find((initialItem) => initialItem[this.idKey] === item[this.idKey])
      ),
      // Filter all items from initial that don't exist on new
      removed: differenceBy(initialCollection, newCollection, this.getByKey),
      // Filter all items from new that exist in initial but their current
      // `index` doesn't equal their initial `index`.
      changed: newCollection.filter((item, index) => {
        const oldIndex = initialCollection.findIndex((initialItem) => initialItem[this.idKey] === item[this.idKey]);
        return oldIndex !== index && ~oldIndex;
      })
    };
  }

  private getByKey = (item: T) => item[this.idKey];
}
