import { AutoComplete, TextField } from "DLUI/form";
import React, { useCallback, useEffect } from "react";
import type { FieldInputProps } from "formik";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import type { OptionType } from "DLUI/form/autoComplete/autoComplete";
import type { FilterOptionsState } from "@material-ui/lab/useAutocomplete/useAutocomplete";

interface AddressAutoCompleteProps {
  values: OptionType[];
  field: FieldInputProps<string>;
  label: string;
  uniqueId: string;
  defaultValue?: OptionType;
  marginTop?: number;
  isAddressDefined?: boolean;
  onTextFieldBlur?: (value: string) => void;
  filterOptions?: (options: any[], state: FilterOptionsState<any>) => typeof options;
  noOptionsText: string;
  dataCy?: string;
}

const AddressAutoComplete = ({
  values,
  uniqueId,
  field,
  defaultValue,
  isAddressDefined,
  onTextFieldBlur,
  filterOptions,
  dataCy,
  noOptionsText,
  ...rest
}: AddressAutoCompleteProps) => {
  const formik = useFormikContext();
  const { t } = useTranslation();

  const handleAddressSelected = useCallback((selectedValue?: OptionType, val?: string) => {
    formik.setFieldValue(field.name, selectedValue?.displayName || val);
  }, []);

  const handleAddressFieldTextChanged = useCallback((val: string) => {
    const country: OptionType | undefined = values.find(
      ({ displayName }) => displayName.toLowerCase() === val.toLowerCase()
    );

    handleAddressSelected(country, val);
    onTextFieldBlur?.(val);
  }, []);

  useEffect(() => {
    if (isAddressDefined && !get(formik.values, field.name) && defaultValue) {
      handleAddressSelected(defaultValue);
    }
  }, []);

  return (
    <AutoComplete
      uniqueIndex={uniqueId}
      values={values}
      selectedValue={field.value || ""}
      renderInput={(params) => (
        <TextField
          fullWidth
          id={uniqueId + field.name}
          field={field}
          onBlur={handleAddressFieldTextChanged}
          {...rest}
          {...params}
        />
      )}
      marginTop={0}
      paddingRight={0}
      paddingLeft={0}
      didSelectAutoComplete={handleAddressSelected}
      clearOnBlur={false}
      noOptionsText={t(noOptionsText)}
      data-cy={dataCy}
      filterOptions={filterOptions}
      {...rest}
    />
  );
};

export default AddressAutoComplete;
