import { View } from "DLUI/view";
import React from "react";
import { CircularProgress } from "@material-ui/core";

interface ComponentProps {}

export const FullScreenLoading: React.FC<React.PropsWithChildren<ComponentProps>> = ({ children }) => (
  <View
    style={{
      background: "rgba(255, 255, 255, .8)",
      position: "fixed",
      zIndex: 9999,
      top: 0,
      left: 0
    }}
    alignItems="center"
    justifyContent="center"
    noWrap
    width={"100vw"}
    height={"100vh"}
    className="hide-on-print"
    gap={10}
  >
    {children}
    <CircularProgress />
  </View>
);
