import { ownersApi } from "@/api/ownersApi";
import { tenantsApi } from "@/api/tenantsApi";
import { vendorsApi } from "@/api/vendorsApi";
import { OwnerDto, PersonTypeEnum, TenantDto, VendorDto } from "@doorloop/dto";

import type { RestApiBase } from "@/api/restApiBase";
import type { BaseDto, GetAllBaseQueryRequest } from "@doorloop/dto";

export const groupToPersonTypeMap: Record<string, PersonTypeEnum> = {
  Owners: PersonTypeEnum.OWNER,
  Tenants: PersonTypeEnum.TENANT,
  Vendors: PersonTypeEnum.VENDOR
};

export const personTypeToApiInstanceMap: Record<PersonTypeEnum, RestApiBase<BaseDto, GetAllBaseQueryRequest>> = {
  [PersonTypeEnum.OWNER]: ownersApi,
  [PersonTypeEnum.TENANT]: tenantsApi,
  [PersonTypeEnum.VENDOR]: vendorsApi
};

export const personTypeToDtoConstructorMap = {
  [PersonTypeEnum.OWNER]: OwnerDto,
  [PersonTypeEnum.TENANT]: TenantDto,
  [PersonTypeEnum.VENDOR]: VendorDto
};

export const NEXT_PAGE_SIZE = 50;
export const TEXT_FILTER_DEBOUNCE_MS = 500;
export const MIN_SEARCH_LENGTH = 2;
