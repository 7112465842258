import { Lottie } from "DLUI/lottie";
import spinnerAnimation from "../../../../assets/lottie/spinner.json";
import { IconButton } from "DLUI/form";
import { CheckIconCircle, CloseCircleIcon } from "../../../../assets";
import { View } from "DLUI/view";
import React from "react";

interface InputActionPanelProps {
  isLoading: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const InputActionPanel = ({ isLoading, onClose, onConfirm }: InputActionPanelProps) => (
  <View flexDirection={"row"} noWrap width={"45px"} gap={5} justifyContent={"center"}>
    {isLoading ? (
      <Lottie pathColor={"blue"} autoPlay={true} loop={true} animationData={spinnerAnimation} width={20} height={20} />
    ) : (
      <>
        <IconButton removePadding size={20} Icon={CloseCircleIcon} onClick={onClose} />
        <IconButton
          removePadding
          size={20}
          Icon={CheckIconCircle}
          pathColor={"white"}
          circleFill={"blue"}
          onClick={onConfirm}
        />
      </>
    )}
  </View>
);

export default InputActionPanel;
