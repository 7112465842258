import type { FormikProps } from "formik";

export const autoSelectAddress = (
  selectedPlace: any,
  formikRef: FormikProps<any>,
  formikFieldName: string,
  merchantAccountAddress?: "merchantAccountAddress"
) => {
  let streetAddress = "";
  if (selectedPlace.geometry?.location) {
    formikRef.setFieldValue(`${formikFieldName}.lat`, selectedPlace.geometry?.location.lat());
    formikRef.setFieldValue(`${formikFieldName}.lng`, selectedPlace.geometry?.location.lng());
  }
  selectedPlace.address_components.forEach((addressComponent: any) => {
    const type = addressComponent.types[0];
    switch (type) {
      case "street_number":
        streetAddress += addressComponent.long_name;
        break;
      case "route":
        streetAddress += " " + addressComponent.long_name;
        break;
      case "country":
        formikRef.setFieldValue(`${formikFieldName}.country`, addressComponent.long_name);
        break;
      case "postal_code":
        formikRef.setFieldValue(`${formikFieldName}.zip`, addressComponent.long_name);
        break;
      case "sublocality_level_1":
      case "locality":
        formikRef.setFieldValue(`${formikFieldName}.city`, addressComponent.long_name);
        break;
      case "administrative_area_level_1":
        formikRef.setFieldValue(
          `${formikFieldName}.state`,
          merchantAccountAddress ? addressComponent.short_name : addressComponent.long_name
        );
        break;
    }
  });

  formikRef.setFieldValue(`${formikFieldName}.street1`, streetAddress);
};
