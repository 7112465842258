"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportPropertyCsvItem = exports.RentImportItem = exports.LeaseImportItem = exports.UnitImportItem = exports.OwnershipImportItem = exports.PropertyImportItem = void 0;
const class_validator_1 = require("class-validator");
const common_1 = require("../../common");
const class_transformer_1 = require("class-transformer");
const validators_1 = require("../../common/validators");
const properties_1 = require("../../properties");
const TenantImportItem_dto_1 = require("../../import/properties/TenantImportItem.dto");
const recurringTransactionFrequency_enum_1 = require("../../recurringTransactions/recurringTransactionFrequency.enum");
class PropertyImportItem {
}
exports.PropertyImportItem = PropertyImportItem;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.MaxLength)(255),
    __metadata("design:type", String)
], PropertyImportItem.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => common_1.AddressDto),
    __metadata("design:type", common_1.AddressDto)
], PropertyImportItem.prototype, "address", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(properties_1.PropertyType),
    (0, class_validator_1.IsDefined)(),
    __metadata("design:type", String)
], PropertyImportItem.prototype, "type", void 0);
class OwnershipImportItem {
}
exports.OwnershipImportItem = OwnershipImportItem;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(0),
    (0, class_validator_1.Max)(100),
    __metadata("design:type", Number)
], OwnershipImportItem.prototype, "ownershipPercentage", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((o) => !o.companyName),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], OwnershipImportItem.prototype, "firstName", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], OwnershipImportItem.prototype, "middleName", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((o) => !o.companyName),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], OwnershipImportItem.prototype, "lastName", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((o) => !o.lastName && !o.firstName),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], OwnershipImportItem.prototype, "companyName", void 0);
class UnitImportItem {
}
exports.UnitImportItem = UnitImportItem;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.MaxLength)(255),
    __metadata("design:type", String)
], UnitImportItem.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(0),
    __metadata("design:type", Number)
], UnitImportItem.prototype, "beds", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(0),
    __metadata("design:type", Number)
], UnitImportItem.prototype, "baths", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(0),
    __metadata("design:type", Number)
], UnitImportItem.prototype, "size", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(0),
    __metadata("design:type", Number)
], UnitImportItem.prototype, "marketRent", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => common_1.AddressDto),
    __metadata("design:type", common_1.AddressDto)
], UnitImportItem.prototype, "address", void 0);
class LeaseImportItem {
}
exports.LeaseImportItem = LeaseImportItem;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, validators_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], LeaseImportItem.prototype, "start", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, validators_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], LeaseImportItem.prototype, "end", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(recurringTransactionFrequency_enum_1.RecurringTransactionFrequency),
    __metadata("design:type", String)
], LeaseImportItem.prototype, "rentFrequency", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ArrayMinSize)(1),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => RentImportItem),
    __metadata("design:type", Array)
], LeaseImportItem.prototype, "rent", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ArrayMinSize)(1),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => RentImportItem),
    __metadata("design:type", Array)
], LeaseImportItem.prototype, "deposits", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], LeaseImportItem.prototype, "lateFeesActive", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, validators_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], LeaseImportItem.prototype, "nextRent", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], LeaseImportItem.prototype, "rolloverToAtWill", void 0);
class RentImportItem {
}
exports.RentImportItem = RentImportItem;
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", Number)
], RentImportItem.prototype, "amount", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], RentImportItem.prototype, "accountName", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(255),
    __metadata("design:type", String)
], RentImportItem.prototype, "memo", void 0);
class ImportPropertyCsvItem {
}
exports.ImportPropertyCsvItem = ImportPropertyCsvItem;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => PropertyImportItem),
    __metadata("design:type", PropertyImportItem)
], ImportPropertyCsvItem.prototype, "property", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => UnitImportItem),
    __metadata("design:type", UnitImportItem)
], ImportPropertyCsvItem.prototype, "unit", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => LeaseImportItem),
    __metadata("design:type", LeaseImportItem)
], ImportPropertyCsvItem.prototype, "lease", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.lease),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => TenantImportItem_dto_1.TenantImportItem),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ArrayMinSize)(1),
    __metadata("design:type", Array)
], ImportPropertyCsvItem.prototype, "tenants", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => OwnershipImportItem),
    (0, class_validator_1.IsArray)(),
    __metadata("design:type", Array)
], ImportPropertyCsvItem.prototype, "owners", void 0);
