"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseTenantSortMapper = exports.LeaseTenantStepStatus = exports.FilterPortalStatus = exports.PortalStatus = void 0;
var PortalStatus;
(function (PortalStatus) {
    PortalStatus["ACTIVE"] = "ACTIVE";
    PortalStatus["INACTIVE"] = "INACTIVE";
    PortalStatus["INVITED"] = "INVITED";
})(PortalStatus || (exports.PortalStatus = PortalStatus = {}));
var FilterPortalStatus;
(function (FilterPortalStatus) {
    FilterPortalStatus["INACTIVE"] = "INACTIVE";
    FilterPortalStatus["MISSING_CONTACT_DETAILS"] = "MISSING_CONTACT_DETAILS";
    FilterPortalStatus["LOGGED_IN"] = "LOGGED_IN";
    FilterPortalStatus["PAYMENT_SETUP"] = "PAYMENT_SETUP";
})(FilterPortalStatus || (exports.FilterPortalStatus = FilterPortalStatus = {}));
var LeaseTenantStepStatus;
(function (LeaseTenantStepStatus) {
    LeaseTenantStepStatus["MISSING_CONTACT_DETAILS"] = "MISSING_CONTACT_DETAILS";
    LeaseTenantStepStatus["PENDING_INVITE"] = "PENDING_INVITE";
    LeaseTenantStepStatus["INVITED"] = "INVITED";
    LeaseTenantStepStatus["ACTIVE"] = "ACTIVE";
    LeaseTenantStepStatus["PAYMENT_SETUP"] = "PAYMENT_SETUP";
})(LeaseTenantStepStatus || (exports.LeaseTenantStepStatus = LeaseTenantStepStatus = {}));
exports.LeaseTenantSortMapper = {
    MISSING_CONTACT_DETAILS: 1,
    PENDING_INVITE: 2,
    INVITED: 3,
    ACTIVE: 4,
    PAYMENT_SETUP: 5
};
