"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpenseDtoWithProperties = exports.ExpenseDto = void 0;
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const checkPayableInfo_dto_1 = require("../../common/checkPayableInfo.dto");
const ePayPayableInfo_dto_1 = require("../../common/ePayPayableInfo.dto");
const paymentMethod_enum_1 = require("../paymentMethod.enum");
const expenseTransaction_dto_1 = require("../shared/expenseTransaction.dto");
const expensePayTo_enum_1 = require("./expensePayTo.enum");
const common_1 = require("../../common");
class ExpenseDto extends expenseTransaction_dto_1.ExpenseTransactionDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.ExpenseDto = ExpenseDto;
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], ExpenseDto.prototype, "payFromAccount", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(paymentMethod_enum_1.PaymentMethod),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], ExpenseDto.prototype, "paymentMethod", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(expensePayTo_enum_1.ExpensePayToResourceType),
    __metadata("design:type", String)
], ExpenseDto.prototype, "payToResourceType", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], ExpenseDto.prototype, "payToResourceId", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.paymentMethod === paymentMethod_enum_1.PaymentMethod.CHECK),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => checkPayableInfo_dto_1.CheckPayableInfoDto),
    __metadata("design:type", checkPayableInfo_dto_1.CheckPayableInfoDto)
], ExpenseDto.prototype, "checkInfo", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => ePayPayableInfo_dto_1.EPayPayableInfoDto),
    __metadata("design:type", ePayPayableInfo_dto_1.EPayPayableInfoDto)
], ExpenseDto.prototype, "ePayInfo", void 0);
__decorate([
    (0, common_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], ExpenseDto.prototype, "date", void 0);
class ExpenseDtoWithProperties extends ExpenseDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.ExpenseDtoWithProperties = ExpenseDtoWithProperties;
