import { createContext, useContext } from "react";
import { getInitialState, useResponsive } from "@/contexts/utils";

import type { UseResponsiveDataReturn } from "./utils";

const ResponsiveContext = createContext<UseResponsiveDataReturn>(getInitialState());

export const ResponsiveProvider: React.FC = ({ children }) => {
  const responsiveData = useResponsive();
  return <ResponsiveContext.Provider value={responsiveData}>{children}</ResponsiveContext.Provider>;
};

// TODO Rename to `useResponsiveContext`
export const useResponsiveHelper = () => useContext(ResponsiveContext);
