import { apiHelper } from "./apiHelper";
import { RestApiBase } from "./restApiBase";
import type {
  AccessValueEnabledStatus,
  CashPaymentDetailsDto,
  GetAllCashPaymentsQuery,
  GetCashPaymentDetailsQuery
} from "@doorloop/dto";
import { ServerRoutes } from "@doorloop/dto";
import type { ApiResult } from "api/apiResult";

export type CashPaymentLevels = "lease" | "property" | "company";

export class CashPaymentsApi extends RestApiBase<CashPaymentDetailsDto, GetAllCashPaymentsQuery> {
  constructor() {
    super(ServerRoutes.CASH_PAYMENTS);
    this.restRoute = ServerRoutes.CASH_PAYMENTS;
  }

  getCashPaymentDetails = async (query: GetCashPaymentDetailsQuery): Promise<ApiResult<CashPaymentDetailsDto[]>> =>
    await apiHelper.axiosPost({
      url: ServerRoutes.CASH_PAYMENTS_GET_PAYMENT_DETAILS,
      data: query
    });

  downloadCashPaymentPdf = async (leaseId: string, tenantId: string) => {
    const response = await apiHelper.axiosGetFile(
      ServerRoutes.CASH_PAYMENTS_GET_PDF.replace(":leaseId", leaseId).replace(":tenantId", tenantId)
    );

    if (!response.status || !response.data) {
      return response;
    }

    return response;
  };

  getIsCashPaymentsEnabled = async (
    idType: CashPaymentLevels,
    id: string
  ): Promise<ApiResult<AccessValueEnabledStatus>> =>
    await apiHelper.axiosGet(ServerRoutes.CASH_PAYMENTS_GET_IS_ENABLED.replace(":idType", idType).replace(":id", id));
}

export const cashPaymentsApi = new CashPaymentsApi();
