import { LargeKeyIcon, ListingContractIcon, PhotoIcon, SettingsIcon } from "assets/icons";
import type { MenuItem } from "DLUI/dialogs";
import AppStrings from "locale/keys";
import { EditUnitAmenities } from "./editUnitAmenities";
import { EditUnitGeneralInfo } from "./editUnitGeneralInfo";
import { EditUnitPhotos } from "./editUnitPhotos";
import { EditUnitListing } from "./editUnitListing";
import type { PropertyClass } from "@doorloop/dto";
import { SettingPermission, SettingsAccessEnum } from "@doorloop/dto";
import { filterObjectsByUserType } from "../../../../../utils/userTypesUtils";
import { EditUnitRentalApplicationSetup } from "DLUI/dialogs/components/editUnit/editUnitRentalApplicationSetup";

export const getUnitFormMenuItems = (
  propertyChange: (propertyId: string) => void,
  currentPropertyClass?: PropertyClass,
  isHOAUser?: boolean
): MenuItem[] => {
  const items: MenuItem[] = [
    {
      title: AppStrings.Common.GeneralInformation,
      icon: SettingsIcon,
      contentComponent: () => (
        <EditUnitGeneralInfo currentPropertyClass={currentPropertyClass} changeProperty={propertyChange} />
      )
    },
    {
      title: AppStrings.Listing.Photos,
      icon: PhotoIcon,
      contentComponent: () => <EditUnitPhotos />
    },
    {
      title: AppStrings.Listing.Amenities,
      icon: LargeKeyIcon,
      contentComponent: () => <EditUnitAmenities />,
      category: "RESIDENTIAL"
    },
    {
      title: AppStrings.Common.RentalApplication,
      icon: LargeKeyIcon,
      contentComponent: () => <EditUnitRentalApplicationSetup />,
      hiddenForUserTypes: ["HOAUser"]
    }
  ];
  items.push({
    title: AppStrings.Listing.Listing,
    icon: ListingContractIcon,
    contentComponent: () => <EditUnitListing />,
    category: "RESIDENTIAL",
    clearances: [
      {
        permission: SettingPermission.listings,
        requiredLevel: SettingsAccessEnum.EDIT
      }
    ],
    hiddenForUserTypes: ["HOAUser"]
  });

  return !isHOAUser ? items : filterObjectsByUserType(items, "HOAUser");
};
