"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalApplicationCoApplicantType = exports.RentalApplicationApplicantType = void 0;
var RentalApplicationApplicantType;
(function (RentalApplicationApplicantType) {
    RentalApplicationApplicantType["TENANT"] = "TENANT";
    RentalApplicationApplicantType["CO_SIGNER"] = "CO_SIGNER";
})(RentalApplicationApplicantType || (exports.RentalApplicationApplicantType = RentalApplicationApplicantType = {}));
var RentalApplicationCoApplicantType;
(function (RentalApplicationCoApplicantType) {
    RentalApplicationCoApplicantType["APPLYING_ALONE"] = "APPLYING_ALONE";
    RentalApplicationCoApplicantType["APPLYING_WITH_CO_APPLICANTS"] = "APPLYING_WITH_CO_APPLICANTS";
})(RentalApplicationCoApplicantType || (exports.RentalApplicationCoApplicantType = RentalApplicationCoApplicantType = {}));
