import React from "react";

import FormHelperText from "@material-ui/core/FormHelperText";
import TextInput from "DLUI/form/textField/textInput";
import { isDigits } from "utils/isDigits";
import { View } from "DLUI/view";

export const OTP_INPUT_DEFAULT_VALUE_LENGTH = 6;

export interface OtpInputProps {
  value?: string;
  valueLength?: number;
  errorMessage?: string | null;
  onChange?: (value: string) => void;
  onComplete?: (value: string) => void;
}

type InputsRefType = Map<number, HTMLInputElement>;

export const OtpInput = ({
  value = "",
  valueLength = OTP_INPUT_DEFAULT_VALUE_LENGTH,
  errorMessage = null,
  onChange,
  onComplete
}: OtpInputProps): JSX.Element => {
  const inputsRef = React.useRef<InputsRefType | null>(null);

  const getInputsRefMap = (): InputsRefType => {
    if (!inputsRef.current) {
      inputsRef.current = new Map();
    }

    return inputsRef.current;
  };

  const focusToPrevInput = (currentIndex: number): void => {
    const previousElement = getPrevInputElement(currentIndex);

    if (previousElement) {
      previousElement.focus();
    }
  };

  const focusToNextInput = (currentIndex: number): void => {
    const nextElement = getNextInputElement(currentIndex);

    if (nextElement) {
      nextElement.focus();
    }
  };

  const getPrevInputElement = (currentIndex: number): HTMLInputElement | null => {
    const inputElement = getInputsRefMap().get(currentIndex - 1);

    return inputElement || null;
  };

  const getNextInputElement = (currentIndex: number): HTMLInputElement | null => {
    const inputElement = getInputsRefMap().get(currentIndex + 1);

    return inputElement || null;
  };

  const handleInputOnInput = (
    { target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ): void => {
    let targetValue = target.value.trim();

    const isTargetValueDigits = isDigits(targetValue);

    if (!isTargetValueDigits && targetValue !== "") {
      return;
    }

    const nextInputElement = getNextInputElement(index);

    if (!isTargetValueDigits && nextInputElement && nextInputElement.value !== "") {
      return;
    }

    targetValue = isTargetValueDigits ? targetValue : " ";

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue = value.substring(0, index) + targetValue + value.substring(index + 1);

      onChange && onChange(newValue);

      if (!isTargetValueDigits) {
        return;
      }

      if (index === valueLength - 1) {
        tryToCallOnComplete(newValue, target);
      }

      focusToNextInput(index);
    } else if (targetValueLength === valueLength) {
      onChange && onChange(targetValue);

      tryToCallOnComplete(targetValue, target);
    }
  };

  const handleInputOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, index: number): void => {
    const { key } = event;
    const target = event.target as HTMLInputElement;

    if (key === "ArrowRight" || key === "ArrowDown") {
      event.preventDefault();

      return focusToNextInput(index);
    }

    if (key === "ArrowLeft" || key === "ArrowUp") {
      event.preventDefault();

      return focusToPrevInput(index);
    }

    const targetValue = target.value;

    target.setSelectionRange(0, targetValue.length);

    if (event.key !== "Backspace" || targetValue !== "") {
      return;
    }

    focusToPrevInput(index);
  };

  const handleInputOnFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, index: number): void => {
    const { target } = event;

    const prevInputEl = getPrevInputElement(index);

    if (prevInputEl && prevInputEl.value === "") {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
  };

  const tryToCallOnComplete = (newValue: string, target: HTMLInputElement | HTMLTextAreaElement): void => {
    if (newValue.length === valueLength && isDigits(newValue) && onComplete) {
      onComplete(newValue);

      target.blur();
    }
  };

  const valueItems: string[] = React.useMemo(() => {
    const valueArray = value.split("");
    const items: string[] = [];

    for (let index = 0; index < valueLength; index++) {
      const char = valueArray[index];

      if (isDigits(char)) {
        items.push(char);
      } else {
        items.push("");
      }
    }

    return items;
  }, [value, valueLength]);

  const hasErrorMessage = Boolean(errorMessage);

  return (
    <View>
      <View flexDirection="row" noWrap gap={5}>
        {valueItems.map((digit, index) => (
          <TextInput
            key={index}
            variant="outlined"
            inputProps={{
              type: "text",
              inputMode: "numeric",
              autoComplete: "one-time-code",
              pattern: "d{1}",
              maxLength: valueLength
            }}
            onInput={(event) =>
              handleInputOnInput(event as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index)
            }
            onKeyDown={(event) => handleInputOnKeyDown(event, index)}
            onFocus={(event) => handleInputOnFocus(event, index)}
            value={digit}
            placeholder="-"
            fullWidth
            ref={(node) => {
              const map = getInputsRefMap();

              if (node) {
                map.set(index, node);
              } else {
                map.delete(index);
              }
            }}
            error={hasErrorMessage}
          ></TextInput>
        ))}
      </View>
      {hasErrorMessage && <FormHelperText error={hasErrorMessage}>{errorMessage}</FormHelperText>}
    </View>
  );
};
