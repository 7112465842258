import { TextareaAutosize } from "@material-ui/core";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "../../../../../locale/keys";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { Icon } from "DLUI/icon";
import { CopyIcon } from "../../../../../assets";
import { getShareApplicationUrl } from "screens/tenantPortal/rentalApplication/components/beforeYouBegin/shareApplicationView";
import { useDispatch, useSelector } from "react-redux";
import type { IRootState } from "store/index";
import { copyToClipboard } from "../../../../../utils/clipboard";
import { handleToast } from "store/toast/actions";
import { SegmentEventTypes } from "@doorloop/dto";
import {
  rentalApplicationEventSource,
  useRentalApplicationEvents
} from "../../../../../hooks/useRentalApplicationEvents";
import { useRentalApplicationValidations } from "../../../../../hooks/useRentalApplicationLocale";
import { TextTooltip } from "DLUI/popover/textTooltip";
import { AnimatedView } from "DLUI/view/animatedView";
import AddressRequiredView from "screens/settings/rentalApplications/components/addressRequiredView";

enum LinkType {
  PropertyLink = "PropertyLink",
  CompanyLink = "CompanyLink"
}

enum LinkTypeTexts {
  PropertyLink = "property",
  CompanyLink = "company"
}

interface ComponentProps {
  landlordId?: number;
}

const ApplicationLink: React.FC<ComponentProps> = ({ landlordId }) => {
  const { propertyId } = useParams<any>();
  const { isTransunionPropertyDisabled, isTransunionCompanyDisabled } = useRentalApplicationValidations(propertyId);
  const dispatch = useDispatch();
  const linkType = propertyId ? LinkType.PropertyLink : LinkType.CompanyLink;
  const userData = useSelector((state: IRootState) => state.auth.currentLoginResponse);
  const { fireEvent } = useRentalApplicationEvents({ propertyId, useCompanySettings: !propertyId });

  const shareApplicationUrl = useMemo(
    () =>
      getShareApplicationUrl({
        applicationId: undefined,
        propertyId,
        unitId: undefined,
        groupId: undefined,
        dbTenant: userData?.currentDbTenant?.id,
        source: propertyId ? rentalApplicationEventSource.propertylink : rentalApplicationEventSource.companylink
      }),
    []
  );

  const didPressCopyLink = async () => {
    const isDisabled = linkType === LinkType.PropertyLink ? isTransunionPropertyDisabled : isTransunionCompanyDisabled;
    if (isDisabled) {
      dispatch(
        handleToast({
          severity: "error",
          translationKey: AppStrings.Common.RentalApplicationIsNotConfigured
        })
      );
      return;
    }
    copyToClipboard(shareApplicationUrl);

    fireEvent({
      eventName:
        linkType === LinkType.CompanyLink
          ? SegmentEventTypes.RentalApplicationUserCopyCompanyLink
          : SegmentEventTypes.RentalApplicationUserCopyPropertyLink,
      trackAnalyticsOptions: { trackEventInIntercom: true }
    });

    dispatch(
      handleToast({
        severity: "neutral",
        translationKey: AppStrings.Toasts.custom.copy.link
      })
    );
  };

  return (
    <>
      <AnimatedView
        shouldShow={linkType === LinkType.PropertyLink ? isTransunionPropertyDisabled : isTransunionCompanyDisabled}
      >
        <AddressRequiredView propertyId={propertyId} landlordId={landlordId} />
      </AnimatedView>
      <AnimatedView
        shouldShow={linkType === LinkType.PropertyLink ? !isTransunionPropertyDisabled : !isTransunionCompanyDisabled}
      >
        <Text value={AppStrings.Settings.GeneralSettings.RentalApplications[linkType]} bold />
        <HorizontalSeparationLine marginTop={16} />
        <View marginTop={16} paddingRight={12}>
          <Text
            value={AppStrings.Settings.GeneralSettings.RentalApplications.CopyLinkDescription}
            replaceObject={{ linkType: LinkTypeTexts[linkType] }}
            fontSize={14}
          />
        </View>
        <View flexDirection={"row"} noWrap alignItems={"center"} marginTop={16}>
          <TextareaAutosize
            maxRows={3}
            minRows={3}
            value={shareApplicationUrl}
            style={{
              width: "100%",
              borderRadius: 5,
              backgroundColor: "#ECEEF5",
              resize: "none",
              padding: 12,
              fontFamily: "Lato, sans-serif"
            }}
            disabled
          />
          <View autoWidth onClick={didPressCopyLink}>
            <TextTooltip
              show={linkType === LinkType.PropertyLink ? isTransunionPropertyDisabled : isTransunionCompanyDisabled}
              useDark
              value={AppStrings.Common.RentalApplicationIsNotConfigured}
              maxWidth={250}
            >
              <Icon Source={CopyIcon} size={20} marginLeft={16} />
            </TextTooltip>
          </View>
        </View>
      </AnimatedView>
    </>
  );
};

export default ApplicationLink;
