"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecurringTransactionStatusForQuery = void 0;
var RecurringTransactionStatusForQuery;
(function (RecurringTransactionStatusForQuery) {
    /**
     * The recurring charge is expired and will not post any additional charges
     */
    RecurringTransactionStatusForQuery["EXPIRED"] = "EXPIRED";
    /**
     * The recurring charge is current, is is actively posting new charges.
     */
    RecurringTransactionStatusForQuery["ACTIVE"] = "ACTIVE";
    /**
     * The recurring charge is active, but hasn't posted any charges yet.
     */
    RecurringTransactionStatusForQuery["FUTURE"] = "FUTURE";
})(RecurringTransactionStatusForQuery || (exports.RecurringTransactionStatusForQuery = RecurringTransactionStatusForQuery = {}));
