import UpdateRentalApplicationDecisionStatusDialog from "screens/rentalApplications/updateDecisionStatus/updateRentalApplicationDecisionStatusDialog";

import { DialogRoutes } from "./dialogsRoutes";
import type { DialogProps } from "./dialogsProvider.types";
import NewRentalApplicationFormikContextWrapper from "screens/rentalApplications/newRentalApplication/newRentalApplicationFormikContextWrapper";
import { Routes } from "../../../appRouter";
import AppStrings from "../../../../locale/keys";

class RentalApplicationsDialogsProvider {
  matchRoutes(pathname: string): DialogProps | undefined {
    if (
      (pathname.indexOf(DialogRoutes.NEW_RENTAL_APPLICATION) > -1 ||
        pathname.indexOf(DialogRoutes.EDIT_RENTAL_APPLICATION) > -1) &&
      pathname.indexOf(DialogRoutes.EDIT_RENTAL_APPLICATIONS_SETTINGS) === -1
    ) {
      return {
        type: "newRentalApplication",
        Content: NewRentalApplicationFormikContextWrapper
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_PROPERTY) > -1 && pathname.indexOf(Routes.RENTAL_APPLICATIONS) > -1) {
      return {
        type: "editProperty",
        title: AppStrings.Settings.GeneralSettings.CompanyInfo.GeneralInfo
      };
    }

    if (pathname.indexOf(DialogRoutes.UPDATE_RENTAL_APPLICATION_DECISION_STATUS) > -1) {
      return {
        type: "updateRentalApplicationDecision",
        Content: UpdateRentalApplicationDecisionStatusDialog
      };
    }

    return undefined;
  }
}

export const rentalApplicationsDialogsProvider = new RentalApplicationsDialogsProvider();
