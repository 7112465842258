"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyAmenity = void 0;
var PropertyAmenity;
(function (PropertyAmenity) {
    PropertyAmenity["Other"] = "Other";
    PropertyAmenity["Availability24Hours"] = "Availability24Hours";
    PropertyAmenity["AccentWalls"] = "AccentWalls";
    PropertyAmenity["BasketballCourt"] = "BasketballCourt";
    PropertyAmenity["Bilingual"] = "Bilingual";
    PropertyAmenity["BoatDocks"] = "BoatDocks";
    PropertyAmenity["BusinessCenter"] = "BusinessCenter";
    PropertyAmenity["CarWashArea"] = "CarWashArea";
    PropertyAmenity["ChildCare"] = "ChildCare";
    PropertyAmenity["ClubDiscount"] = "ClubDiscount";
    PropertyAmenity["ClubHouse"] = "ClubHouse";
    PropertyAmenity["ConferenceRoom"] = "ConferenceRoom";
    PropertyAmenity["Concierge"] = "Concierge";
    PropertyAmenity["CoverPark"] = "CoverPark";
    PropertyAmenity["DoorAttendant"] = "DoorAttendant";
    PropertyAmenity["FitnessCenter"] = "FitnessCenter";
    PropertyAmenity["Elevator"] = "Elevator";
    PropertyAmenity["FreeWeights"] = "FreeWeights";
    PropertyAmenity["FurnishedAvailable"] = "FurnishedAvailable";
    PropertyAmenity["GamingStations"] = "GamingStations";
    PropertyAmenity["Garage_Attached"] = "Garage_Attached";
    PropertyAmenity["Garage_Detached"] = "Garage_Detached";
    PropertyAmenity["Garage_SameLevelParking"] = "Garage_SameLevelParking";
    PropertyAmenity["Gate"] = "Gate";
    PropertyAmenity["GroceryService"] = "GroceryService";
    PropertyAmenity["GroupExercise"] = "GroupExercise";
    PropertyAmenity["GuestRoom"] = "GuestRoom";
    PropertyAmenity["HighSpeed"] = "HighSpeed";
    PropertyAmenity["Housekeeping"] = "Housekeeping";
    PropertyAmenity["HouseSitting"] = "HouseSitting";
    PropertyAmenity["JoggingWalkingTrails"] = "JoggingWalkingTrails";
    PropertyAmenity["LakeFront"] = "LakeFront";
    PropertyAmenity["LakeAccess"] = "LakeAccess";
    PropertyAmenity["Laundry"] = "Laundry";
    PropertyAmenity["Library"] = "Library";
    PropertyAmenity["MealService"] = "MealService";
    PropertyAmenity["MediaRoom"] = "MediaRoom";
    PropertyAmenity["MultiUseRoom"] = "MultiUseRoom";
    PropertyAmenity["NightPatrol"] = "NightPatrol";
    PropertyAmenity["OnSiteMaintenance"] = "OnSiteMaintenance";
    PropertyAmenity["OnSiteManagement"] = "OnSiteManagement";
    PropertyAmenity["PackageReceiving"] = "PackageReceiving";
    PropertyAmenity["PerDiemAccepted"] = "PerDiemAccepted";
    PropertyAmenity["Pool"] = "Pool";
    PropertyAmenity["PlayGround"] = "PlayGround";
    PropertyAmenity["Racquetball"] = "Racquetball";
    PropertyAmenity["RecRoom"] = "RecRoom";
    PropertyAmenity["Recycling"] = "Recycling";
    PropertyAmenity["Sauna"] = "Sauna";
    PropertyAmenity["ShortTermLease"] = "ShortTermLease";
    PropertyAmenity["SmokeFree"] = "SmokeFree";
    PropertyAmenity["Spa"] = "Spa";
    PropertyAmenity["StorageSpace"] = "StorageSpace";
    PropertyAmenity["Sundeck"] = "Sundeck";
    PropertyAmenity["TennisCourt"] = "TennisCourt";
    PropertyAmenity["Transportation"] = "Transportation";
    PropertyAmenity["TVLounge"] = "TVLounge";
    PropertyAmenity["ValetTrash"] = "ValetTrash";
    PropertyAmenity["Vintage"] = "Vintage";
    PropertyAmenity["VolleyballCourt"] = "VolleyballCourt";
    PropertyAmenity["WirelessInternet"] = "WirelessInternet";
})(PropertyAmenity || (exports.PropertyAmenity = PropertyAmenity = {}));
