import { useCallback } from "react";
import { useHistory } from "react-router-dom";

export type HistoryActionMethod = "push" | "replace";
type SetterCallback = (prevSearchParams: URLSearchParams) => URLSearchParams | undefined | void;

export function useSearchParamsSetter(): (callback: SetterCallback, method?: HistoryActionMethod) => void {
  const { push, replace } = useHistory();

  return useCallback(
    (callback: SetterCallback, method: HistoryActionMethod = "push") => {
      const newParams = callback(new URLSearchParams(location.search));
      if (!newParams) {
        return;
      }

      if (method === "push") {
        push({ search: newParams.toString() });
        return;
      }

      replace({ search: newParams.toString() });
    },
    [push, replace]
  );
}
