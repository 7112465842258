import React from "react";
import Text from "DLUI/text";
import { getIn, useFormikContext } from "formik";
import { vendorsApi } from "api/vendorsApi";
import type { DeepKeys, ManagementFeesCompanySettingsDto, ManagementFeesPropertySettingsDto } from "@doorloop/dto";
import { DataCy } from "@doorloop/dto";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";
import { SettingsSection } from "screens/settings/common/settingsSection";
import { useTypedTranslation } from "locale";
import { useUserType } from "hooks/useUserType";
import { ArticleIdsEnum } from "DLUI/screen/helpPanel/types";
import { InstructionsText } from "screens/settings/managementFees/formElements/instructionsText";
import { useParams } from "react-router-dom";
import { useGeneralStyles } from "styles/generalStyles";
import clsx from "clsx";

interface VendorSelectionProps {
  fieldName: DeepKeys<ManagementFeesCompanySettingsDto | ManagementFeesPropertySettingsDto>;
}

export const VendorSelection: React.FC<VendorSelectionProps> = ({ fieldName }: VendorSelectionProps) => {
  const formikRef = useFormikContext<ManagementFeesCompanySettingsDto>();
  const { isHOAUser } = useUserType();
  const { propertyId } = useParams<{ propertyId: string }>();
  const generalClasses = useGeneralStyles();

  const { t } = useTypedTranslation();
  return (
    <div className={clsx(generalClasses.flexColumn, generalClasses.largeGap)}>
      {!propertyId && (
        <InstructionsText
          articleId={!isHOAUser ? ArticleIdsEnum.MANAGEMENT_FEES : ArticleIdsEnum.HOA_MANAGEMENT_FEES}
        />
      )}
      <SettingsSection title={t("vendors.vendorDetails.paymentRecipient")}>
        <Text value={"settings.generalSettings.managementFees.whichVendorToPay"} color="secondary-gray" />
        <FormikCachedAsyncAutoComplete
          label={t("vendors.vendorDetails.selectAVendor")}
          uniqueIndex={"vendorAutoComplete"}
          apiHandler={vendorsApi}
          displayNameKey={"name"}
          filterFieldName={"filter_text"}
          filterFieldValue={"name"}
          selectionFields={["id", "class"]}
          name={fieldName}
          marginTop={20}
          required
          dataCy={DataCy.globalSettings.managementFees.vendorSelection}
          defaultValue={getIn(formikRef.values, fieldName)}
        />
      </SettingsSection>
    </div>
  );
};
