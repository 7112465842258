import React, { useEffect, useState } from "react";
import type { PlaidLinkOnSuccessMetadata, PlaidLinkOptions } from "react-plaid-link";
import { usePlaidLink } from "react-plaid-link";
import type { PlaidAccountListItemProps } from "screens/transactions/components/bankConnect/dialogs/bankConnect/plaidAccountListItem";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { bankConnectApi } from "api/bankAccountsApi";
import AppStrings from "locale/keys";
import qs from "query-string";
import type { AccountType, GetAllPlaidAccountsQuery } from "@doorloop/dto";
import { SegmentEventTypes } from "@doorloop/dto";
import { useTranslation } from "react-i18next";
import PlaidAccountsList from "screens/transactions/components/bankConnect/dialogs/bankConnect/plaidAccountsList";
import FrameScrollableContainer from "screens/transactions/components/bankConnect/dialogs/bankConnect/frameScrollableContainer";
import { plaidTransactionsApi } from "api/plaidTransactionsApi";
import { analyticsService } from "services/analyticsService";
import { QueryParams } from "utils/queryParams";
import { useBankConnectEvents } from "hooks/useBankConnectEvents";

interface ComponentProps {
  onClose: () => void;
  onBackPress: () => void;
  plaidToken: string;
  onAccountSelection: (accountId: string) => void;
}

interface RepairConnectionParams {
  publicToken: string;
  metadata: PlaidLinkOnSuccessMetadata;
  brokenPlaidItemId: string;
}

const PlaidIntegrationView: React.FC<ComponentProps> = ({
  onClose,
  plaidToken,
  onAccountSelection,
  onBackPress
}: ComponentProps) => {
  const { t } = useTranslation();
  const reconnectAccountParam = QueryParams.get("reconnect");
  const plaidItemParam = QueryParams.get("plaidItem");
  const reconnectAccountMode = reconnectAccountParam && plaidItemParam;
  const repairConnectionParam = QueryParams.get("repair");
  const repairConnectionMode = repairConnectionParam && plaidItemParam;
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const [plaidPublicToken, setPlaidPublicToken] = useState<string | undefined>();
  const [accounts, setAccounts] = useState<PlaidAccountListItemProps[]>();
  const [plaidOpenInProgress, setPlaidOpenInProgress] = useState<boolean>(false);
  const { fireBankConnectEvent } = useBankConnectEvents();
  let didConnectPlaidItem = false;

  const onPlaidSuccessEvent = async (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
    if (public_token && metadata.institution && metadata.institution.institution_id) {
      if (reconnectAccountMode && !didConnectPlaidItem) {
        didConnectPlaidItem = true;
        setLoadingDialogState(DialogState.Show);
        await plaidTransactionsApi.refreshPlaidTransactions(plaidItemParam!);
        onClose();
      } else if (repairConnectionMode && !didConnectPlaidItem) {
        didConnectPlaidItem = true;
        setPlaidPublicToken(public_token);
        await repairConnection({ publicToken: public_token, metadata, brokenPlaidItemId: plaidItemParam });
      } else if (!didConnectPlaidItem) {
        didConnectPlaidItem = true;
        setPlaidPublicToken(public_token);
        await connectPlaidItem(public_token, metadata);
      }
    } else {
      // should never happen
      onClose();
    }
  };
  const onPlaidExitEvent = (err, metadata) => {
    console.log("*********  onPlaidExitEvent *********");
    console.log("err", err);
    console.log("onPlaidExitEvent metadata = ", metadata);

    onBackPress();
  };
  const onPlaidEvent = (eventName, metadata) => {
    console.log("plaid_" + eventName, metadata);
    analyticsService.track("plaid_" + eventName, metadata, { trackEventInIntercom: true });
    if (eventName === "EXIT") {
      setPlaidOpenInProgress(false);
    }
  };

  const config: PlaidLinkOptions = {
    onSuccess: onPlaidSuccessEvent,
    onExit: onPlaidExitEvent,
    onEvent: onPlaidEvent,
    token: plaidToken
  };

  const { open, exit, ready } = usePlaidLink(config);

  const openPlaidSdkOnReconnect = reconnectAccountMode && plaidToken;
  const openPlaidSdkOnConnect = !reconnectAccountMode && !plaidPublicToken;

  useEffect(() => {
    setPlaidOpenInProgress(true);
    open();

    if (!plaidOpenInProgress && ready && (openPlaidSdkOnReconnect || openPlaidSdkOnConnect)) {
    }
  }, [plaidToken, ready, plaidPublicToken]);

  const repairConnection = async ({ publicToken, metadata, brokenPlaidItemId }: RepairConnectionParams) => {
    const institutionId = metadata?.institution?.institution_id;

    setLoadingDialogState(DialogState.Show);

    try {
      const repairConnectionResponse = await plaidTransactionsApi.repairConnection({
        newPlaidItemAccessToken: publicToken,
        linkSessionId: metadata.link_session_id,
        brokenPlaidItemId,
        institutionId
      });

      if (repairConnectionResponse?.data) {
        onClose();
        return;
      }

      if (repairConnectionResponse?.message) {
        showErrorMessage(repairConnectionResponse.message);
      }
    } catch (error: any) {
      showErrorMessage(error?.message || t(AppStrings.Common.GeneralError));
    }
  };

  const connectPlaidItem = async (publicToken?: string, metadata?: PlaidLinkOnSuccessMetadata) => {
    const _publicToken = publicToken || plaidToken;
    const _institutionId = metadata?.institution?.institution_id;
    if (_publicToken && _institutionId) {
      setLoadingDialogState(DialogState.Show);
      const connectPlaidItemResponse = await plaidTransactionsApi
        .connectPlaidItem({
          publicToken,
          institutionId: _institutionId,
          accounts: metadata?.accounts.map((x) => {
            return { name: x.name, mask: x.mask };
          }),
          link_session_id: metadata?.link_session_id
        })
        .catch((e) => {
          showErrorMessage(e);
        });
      if (connectPlaidItemResponse && connectPlaidItemResponse.data) {
        await getPlaidAccounts();
        fireBankConnectEvent(SegmentEventTypes.BANK_CONNECT_CONNECT_SUCCEEDED, undefined, {
          trackEventInIntercom: true
        });
      } else {
        showErrorMessage(
          connectPlaidItemResponse && connectPlaidItemResponse.message
            ? connectPlaidItemResponse.message
            : AppStrings.Common.GeneralError
        );
      }
    }
  };

  const showErrorMessage = (error: string) => {
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(error);
  };

  const getPlaidAccounts = async () => {
    setLoadingDialogState(DialogState.Show);
    const queryString = qs.parse(window.location.search);
    const accountType = queryString["accountType"] as AccountType | undefined;
    const queryObject: GetAllPlaidAccountsQuery = {
      filter_isLinkedToAccount: false
    };
    if (accountType) {
      queryObject["accountType"] = accountType;
    }
    const getPlaidAccountsResponse = await bankConnectApi.getAll(queryObject).catch((e) => {
      showErrorMessage(e);
    });

    if (getPlaidAccountsResponse && getPlaidAccountsResponse.data) {
      setLoadingDialogState(DialogState.Hidden);
      setAccounts(getPlaidAccountsResponse.data.data as PlaidAccountListItemProps[]);
    } else {
      showErrorMessage(
        getPlaidAccountsResponse && getPlaidAccountsResponse.message
          ? getPlaidAccountsResponse.message
          : t(AppStrings.Common.GeneralError)
      );
    }
  };

  const renderContent = () => {
    if (loadingDialogState !== DialogState.Hidden) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          errorText={loadingDialogErrorText}
          didPressDismissButton={onClose}
          showLoadingText
        />
      );
    }
    return <PlaidAccountsList accounts={accounts} onAccountSelection={onAccountSelection} />;
  };

  return <FrameScrollableContainer onClose={onClose}>{renderContent()}</FrameScrollableContainer>;
};

export default PlaidIntegrationView;
