import type { WorkOrderPartsAndLaborItemDto } from "@doorloop/dto";
import Grid from "@material-ui/core/Grid";
import { DeleteIcon } from "assets/icons";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import { IconButton, TextField, ViewOnlyInput } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FieldArrayRenderProps, FormikProps } from "formik";
import { FastField, getIn } from "formik";
import AppStrings from "locale/keys";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";

interface ComponentProps {
  formikRef: FormikProps<any>;
  itemIndex: number;
  arrayHelpers: FieldArrayRenderProps;
}

const PartsAndLaborItem: React.FC<ComponentProps> = ({ formikRef, itemIndex, arrayHelpers }: ComponentProps) => {
  const { t } = useTranslation();
  const { inputRightPadding } = DialogsHelper();
  const didPressDeleteItem = () => {
    arrayHelpers.remove(itemIndex);
  };

  const getTotalAmount = () => {
    let totalAmount = 0;
    const currentItem = getIn(formikRef.values, `workOrder.partsAndLaborInfo.items[${itemIndex}]`) as
      | WorkOrderPartsAndLaborItemDto
      | undefined;
    if (currentItem && currentItem.quantity && currentItem.price) {
      totalAmount = currentItem.quantity * currentItem.price;
    } else {
      totalAmount = 0;
    }
    return totalAmount;
  };

  const shouldShowItemheader = () => {
    const items = getIn(formikRef.values, `workOrder.partsAndLaborInfo.items`) as
      | WorkOrderPartsAndLaborItemDto[]
      | undefined;

    return items && items.length > 1;
  };

  const showItemHeader = shouldShowItemheader();

  const totalAmount = getTotalAmount();
  const accountDefaultValue = useMemo(() => {
    const currentItem = getIn(formikRef.values, `workOrder.partsAndLaborInfo.items[${itemIndex}]`) as
      | WorkOrderPartsAndLaborItemDto
      | undefined;
    if (currentItem) {
      return currentItem.account;
    }
    return undefined;
  }, []);

  const renderItemHeader = () => (
    <View shouldShow={showItemHeader} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
      <View justifyContent={"center"} alignItems={"center"} flexDirection={"row"} height={60}>
        <Text fontWeight={700} marginLeft={20} fontSize={16} color={"black"}>
          {t(AppStrings.Tasks.WorkOrderDialog.PartsAndLaborsItemTitle)}
        </Text>
        <Text fontWeight={700} marginLeft={5} fullWidth fontSize={16} color={"black"}>
          {itemIndex + 1}
        </Text>
        <IconButton onClick={didPressDeleteItem} aria-label="delete" Icon={DeleteIcon} marginRight={20} />
        <SeparationLine width={"95%"} height={1} />
      </View>
    </View>
  );

  return (
    <View marginTop={20} borderRadius={10} backgroundColor={"dark"}>
      {renderItemHeader()}
      <View paddingLeft={20} flexDirection={"row"}>
        <Grid item lg={4} md={6} xs={12}>
          <FastField
            component={TextField}
            label={t(AppStrings.Tasks.WorkOrderDialog.Quantity)}
            name={`workOrder.partsAndLaborInfo.items[${itemIndex}].quantity`}
            formatType={"number"}
            required
            decimalScale={2}
            marginTop={20}
            paddingRight={20}
          />
        </Grid>
        <Grid item lg={4} md={6} xs={6}>
          <FastField
            component={TextField}
            label={t(AppStrings.Tasks.WorkOrderDialog.Price)}
            name={`workOrder.partsAndLaborInfo.items[${itemIndex}].price`}
            required
            formatType={"currency"}
            marginTop={20}
            paddingRight={20}
          />
        </Grid>
        <Grid style={{ paddingRight: 20 }} item lg={4} md={12} xs={6}>
          <ViewOnlyInput
            label={AppStrings.Common.ListHeader.Total}
            formatType={"currency"}
            value={String(totalAmount)}
            fullWidth
            marginTop={20}
            alignItems={"flex-start"}
          />
        </Grid>
      </View>
      <View paddingLeft={20} flexDirection={"row"} marginBottom={20}>
        <Grid item lg={6} md={12} xs={12}>
          <BankAccountFormikAutoCompleteField
            addCreateOption
            uniqueIndex={"depositToAccount"}
            name={`workOrder.partsAndLaborInfo.items[${itemIndex}].account`}
            defaultValue={accountDefaultValue}
            label={t(AppStrings.Common.Settings.LateFees.Account)}
            paddingRight={20}
            marginTop={20}
          />
        </Grid>
        <Grid item lg={6} md={12} xs={12}>
          <FastField
            component={TextField}
            label={t(AppStrings.Common.ListHeader.Description)}
            name={`workOrder.partsAndLaborInfo.items[${itemIndex}].memo`}
            paddingRight={20}
            marginTop={20}
          />
        </Grid>
      </View>
    </View>
  );
};

export default PartsAndLaborItem;
