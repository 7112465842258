"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalApplicationCompanySettingsDto = void 0;
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const rentalApplicationAboutYouSettings_dto_1 = require("./rentalApplicationAboutYouSettings.dto");
const rentalApplicationAdditionalInfoSettings_dto_1 = require("./rentalApplicationAdditionalInfoSettings.dto");
const rentalApplicationFilesAndDocumentsSettings_dto_1 = require("./rentalApplicationFilesAndDocumentsSettings.dto");
const rentalApplicationGeneralInstructionsSettings_dto_1 = require("./rentalApplicationGeneralInstructionsSettings.dto");
const rentalApplicationNotificationsSettings_dto_1 = require("./rentalApplicationNotificationsSettings.dto");
const rentalApplicationTermsAndConditionsSettings_dto_1 = require("./rentalApplicationTermsAndConditionsSettings.dto");
const rentalApplicationFeeSettings_dto_1 = require("./rentalApplicationFeeSettings.dto");
const rentalApplicationFieldControlledSectionSettings_dto_1 = require("./rentalApplicationFieldControlledSectionSettings.dto");
class RentalApplicationCompanySettingsDto {
    constructor(init) {
        Object.assign(this, init);
        this.aboutYou = new rentalApplicationAboutYouSettings_dto_1.RentalApplicationAboutYouSettingsDto(init === null || init === void 0 ? void 0 : init.aboutYou);
        this.residentialHistory = new rentalApplicationFieldControlledSectionSettings_dto_1.RentalApplicationFieldControlledSectionSettingsDto(init === null || init === void 0 ? void 0 : init.residentialHistory);
        this.employment = new rentalApplicationFieldControlledSectionSettings_dto_1.RentalApplicationFieldControlledSectionSettingsDto(init === null || init === void 0 ? void 0 : init.employment);
    }
}
exports.RentalApplicationCompanySettingsDto = RentalApplicationCompanySettingsDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => rentalApplicationGeneralInstructionsSettings_dto_1.RentalApplicationGeneralInstructionsSettingsDto),
    __metadata("design:type", rentalApplicationGeneralInstructionsSettings_dto_1.RentalApplicationGeneralInstructionsSettingsDto)
], RentalApplicationCompanySettingsDto.prototype, "general", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => rentalApplicationAboutYouSettings_dto_1.RentalApplicationAboutYouSettingsDto),
    __metadata("design:type", rentalApplicationAboutYouSettings_dto_1.RentalApplicationAboutYouSettingsDto)
], RentalApplicationCompanySettingsDto.prototype, "aboutYou", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => rentalApplicationFieldControlledSectionSettings_dto_1.RentalApplicationFieldControlledSectionSettingsDto),
    __metadata("design:type", rentalApplicationFieldControlledSectionSettings_dto_1.RentalApplicationFieldControlledSectionSettingsDto)
], RentalApplicationCompanySettingsDto.prototype, "pets", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => rentalApplicationFeeSettings_dto_1.RentalApplicationFeeSettingsDto),
    __metadata("design:type", rentalApplicationFeeSettings_dto_1.RentalApplicationFeeSettingsDto)
], RentalApplicationCompanySettingsDto.prototype, "fees", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => rentalApplicationFieldControlledSectionSettings_dto_1.RentalApplicationFieldControlledSectionSettingsDto),
    __metadata("design:type", rentalApplicationFieldControlledSectionSettings_dto_1.RentalApplicationFieldControlledSectionSettingsDto)
], RentalApplicationCompanySettingsDto.prototype, "vehicles", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => rentalApplicationFieldControlledSectionSettings_dto_1.RentalApplicationFieldControlledSectionSettingsDto),
    __metadata("design:type", rentalApplicationFieldControlledSectionSettings_dto_1.RentalApplicationFieldControlledSectionSettingsDto)
], RentalApplicationCompanySettingsDto.prototype, "dependants", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => rentalApplicationFieldControlledSectionSettings_dto_1.RentalApplicationFieldControlledSectionSettingsDto),
    __metadata("design:type", rentalApplicationFieldControlledSectionSettings_dto_1.RentalApplicationFieldControlledSectionSettingsDto)
], RentalApplicationCompanySettingsDto.prototype, "employment", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => rentalApplicationFieldControlledSectionSettings_dto_1.RentalApplicationFieldControlledSectionSettingsDto),
    __metadata("design:type", rentalApplicationFieldControlledSectionSettings_dto_1.RentalApplicationFieldControlledSectionSettingsDto)
], RentalApplicationCompanySettingsDto.prototype, "emergencyContacts", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => rentalApplicationFieldControlledSectionSettings_dto_1.RentalApplicationFieldControlledSectionSettingsDto),
    __metadata("design:type", rentalApplicationFieldControlledSectionSettings_dto_1.RentalApplicationFieldControlledSectionSettingsDto)
], RentalApplicationCompanySettingsDto.prototype, "additionalIncome", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => rentalApplicationFieldControlledSectionSettings_dto_1.RentalApplicationFieldControlledSectionSettingsDto),
    __metadata("design:type", rentalApplicationFieldControlledSectionSettings_dto_1.RentalApplicationFieldControlledSectionSettingsDto)
], RentalApplicationCompanySettingsDto.prototype, "residentialHistory", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => rentalApplicationAdditionalInfoSettings_dto_1.RentalApplicationAdditionalInfoSettingsDto),
    __metadata("design:type", rentalApplicationAdditionalInfoSettings_dto_1.RentalApplicationAdditionalInfoSettingsDto)
], RentalApplicationCompanySettingsDto.prototype, "additionalInfo", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => rentalApplicationFilesAndDocumentsSettings_dto_1.RentalApplicationFilesAndDocumentsSettingsDto),
    __metadata("design:type", rentalApplicationFilesAndDocumentsSettings_dto_1.RentalApplicationFilesAndDocumentsSettingsDto)
], RentalApplicationCompanySettingsDto.prototype, "filesAndDocuments", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => rentalApplicationNotificationsSettings_dto_1.RentalApplicationNotificationsSettingsDto),
    __metadata("design:type", rentalApplicationNotificationsSettings_dto_1.RentalApplicationNotificationsSettingsDto)
], RentalApplicationCompanySettingsDto.prototype, "notifications", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => rentalApplicationTermsAndConditionsSettings_dto_1.RentalApplicationTermsAndConditionsSettingsDto),
    __metadata("design:type", rentalApplicationTermsAndConditionsSettings_dto_1.RentalApplicationTermsAndConditionsSettingsDto)
], RentalApplicationCompanySettingsDto.prototype, "landlordTermsAndConditions", void 0);
