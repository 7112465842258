import { CheckbookPaymentMethodEnum, CheckbookPaymentStatusEnum } from "@doorloop/dto";
import type { SVGIconComponent } from "assets/icons";
import { BigGreenCheckCircleIcon, BigRedWarningIcon, BigYellowWarningIcon, OutgoingIconLarge } from "assets/icons";
import AppStrings from "locale/keys";
import ColorsEnum from "../../../../utils/colorsEnum";

export type PaymentNumberLabelColors = "blue" | "green" | "yellow" | "red";

interface Settings {
  IconSource: SVGIconComponent;
  background: ColorsEnum;
  title: string;
  description: string;
  paymentNumberLabelColors: PaymentNumberLabelColors;
}

type StatusType = CheckbookPaymentStatusEnum | "Not Sent" | "Missing Info";
type SettingsGetter = (method?: CheckbookPaymentMethodEnum) => Settings | null;

const isDD = (method?: CheckbookPaymentMethodEnum): boolean => method === CheckbookPaymentMethodEnum.DIRECT_DEPOSIT;
const getMethodIndex = (method?: CheckbookPaymentMethodEnum): number =>
  method
    ? [
        CheckbookPaymentMethodEnum.DIGITAL_PAYMENT,
        CheckbookPaymentMethodEnum.PHYSICAL_CHECK,
        CheckbookPaymentMethodEnum.DIRECT_DEPOSIT
      ].indexOf(method)
    : 0;

/*
  Currently, DD notifications use the digital version
 */

const processingSettings: SettingsGetter = (method) => {
  return {
    IconSource: BigYellowWarningIcon,
    background: ColorsEnum.LightYellow,
    title: [
      AppStrings.Common.OutgoingPayments.DigitalPaymentSent,
      AppStrings.Common.OutgoingPayments.PhysicalPaymentProcessing,
      AppStrings.Common.OutgoingPayments.DigitalPaymentSent
    ][getMethodIndex(method)],
    description: [
      AppStrings.Common.OutgoingPayments.DigitalPaymentSentDesc,
      AppStrings.Common.OutgoingPayments.PhysicalPaymentProcessingDesc,
      AppStrings.Common.OutgoingPayments.DigitalPaymentSentDesc
    ][getMethodIndex(method)],
    paymentNumberLabelColors: "yellow"
  };
};

export const statusToSettings: Record<StatusType, SettingsGetter> = {
  [CheckbookPaymentStatusEnum.PAID]: (method) => {
    return {
      IconSource: BigGreenCheckCircleIcon,
      background: ColorsEnum.LightGreen,
      title: [
        AppStrings.Common.OutgoingPayments.DigitalPaymentPaid,
        AppStrings.Common.OutgoingPayments.PhysicalPaymentMailed,
        AppStrings.Common.OutgoingPayments.DigitalPaymentPaid
      ][getMethodIndex(method)],
      description: AppStrings.Common.OutgoingPayments.PaymentPaidDesc,
      paymentNumberLabelColors: "green"
    };
  },
  [CheckbookPaymentStatusEnum.IN_PROCESS]: processingSettings,
  [CheckbookPaymentStatusEnum.UNPAID]: processingSettings,
  [CheckbookPaymentStatusEnum.VOID]: (method) =>
    isDD(method)
      ? null
      : {
          IconSource: BigRedWarningIcon,
          background: ColorsEnum.LightRed,
          title: [
            AppStrings.Common.OutgoingPayments.DigitalPaymentVoided,
            AppStrings.Common.OutgoingPayments.PhysicalPaymentFailed,
            AppStrings.Common.OutgoingPayments.DigitalPaymentVoided
          ][getMethodIndex(method)],
          description: [
            AppStrings.Common.OutgoingPayments.DigitalPaymentVoidedDesc,
            AppStrings.Common.OutgoingPayments.PhysicalPaymentFailedDesc,
            AppStrings.Common.OutgoingPayments.DigitalPaymentVoidedDesc
          ][getMethodIndex(method)],
          paymentNumberLabelColors: "red"
        },
  [CheckbookPaymentStatusEnum.EXPIRED]: (method) => {
    return {
      IconSource: BigRedWarningIcon,
      background: ColorsEnum.LightRed,
      title: [
        AppStrings.Common.OutgoingPayments.DigitalPaymentExpired,
        AppStrings.Common.OutgoingPayments.PhysicalPaymentExpired,
        AppStrings.Common.OutgoingPayments.DigitalPaymentExpired
      ][getMethodIndex(method)],
      description: AppStrings.Common.OutgoingPayments.PaymentExpiredDesc,
      paymentNumberLabelColors: "red"
    };
  },
  [CheckbookPaymentStatusEnum.PRINTED]: processingSettings,
  [CheckbookPaymentStatusEnum.MAILED]: processingSettings,
  [CheckbookPaymentStatusEnum.FAILED]: (method) => {
    return {
      IconSource: BigRedWarningIcon,
      background: ColorsEnum.LightRed,
      title: [
        AppStrings.Common.OutgoingPayments.DigitalPaymentFailed,
        AppStrings.Common.OutgoingPayments.PhysicalPaymentFailed,
        AppStrings.Common.OutgoingPayments.DigitalPaymentFailed
      ][getMethodIndex(method)],
      description: AppStrings.Common.OutgoingPayments.PaymentFailedDesc,
      paymentNumberLabelColors: "red"
    };
  },
  [CheckbookPaymentStatusEnum.REFUNDED]: (method) => {
    return {
      IconSource: BigRedWarningIcon,
      background: ColorsEnum.LightRed,
      title: [
        AppStrings.Common.OutgoingPayments.DigitalPaymentRefunded,
        AppStrings.Common.OutgoingPayments.PhysicalPaymentRefunded,
        AppStrings.Common.OutgoingPayments.DigitalPaymentRefunded
      ][getMethodIndex(method)],
      description: AppStrings.Common.OutgoingPayments.PaymentRefundedDesc,
      paymentNumberLabelColors: "red"
    };
  },
  "Not Sent": () => {
    return {
      IconSource: OutgoingIconLarge,
      background: ColorsEnum.LightBlue,
      title: AppStrings.Common.OutgoingPayments.PaymentReadyToSend,
      description: AppStrings.Common.OutgoingPayments.PaymentReadyToSendDesc,
      paymentNumberLabelColors: "blue"
    };
  },
  "Missing Info": () => {
    return {
      IconSource: BigYellowWarningIcon,
      background: ColorsEnum.LightYellow,
      title: AppStrings.Common.OutgoingPayments.MissingPaymentInfo,
      description: AppStrings.Common.OutgoingPayments.PleaseSetUpPayeesOutgoingPayments,
      paymentNumberLabelColors: "yellow"
    };
  }
};
