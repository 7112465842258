import type { OnBoardingDto } from "@doorloop/dto";
import { usePermission } from "screens/settings/userRoles/usePermission";
import { OnBoardingSteps } from "./steps";
import type { OnBoardingStepDto } from "@doorloop/dto";

export interface OnBoardingProgress {
  completedCount: number;
  totalCount: number;
}

export const useOnBoarding = () => {
  const { hasAllPermissions } = usePermission();

  function getProgress(dto: OnBoardingDto): OnBoardingProgress {
    const { completedCount, totalCount } = OnBoardingSteps.reduce(
      (acc, step) => {
        const stepItem = dto[step.name] as OnBoardingStepDto;
        if (typeof stepItem !== "object" || stepItem.completed === undefined) {
          throw new Error(
            "Developer error: onBoardingDto is not valid. All steps should be instance of OnBoardingStepDto object containing { completed: boolean, skipped: boolean }"
          );
        }

        if (hasAllPermissions(step.permissions)) {
          acc.totalCount++;
          if (stepItem.completed || stepItem.skipped) {
            acc.completedCount++;
          }
        }
        return acc;
      },
      { completedCount: 0, totalCount: 0 }
    );

    return {
      completedCount,
      totalCount
    };
  }

  function hasPermissionForOnBoardingStep(stepName: keyof OnBoardingDto) {
    const step = OnBoardingSteps.find((step) => step.name === stepName);
    if (!step) {
      throw new Error(`Developer error: step ${stepName} is not a valid onboarding step`);
    }
    return hasAllPermissions(step.permissions);
  }

  function isOnBoardingCompleted(dto: OnBoardingDto, progress?: OnBoardingProgress): boolean {
    const { completedCount, totalCount } = progress ?? getProgress(dto);

    return dto.skipped || (completedCount > 0 && Math.abs(completedCount - totalCount) < 0.001);
  }

  return {
    getProgress,
    isOnBoardingCompleted,
    hasPermissionForOnBoardingStep
  };
};
