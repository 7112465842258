import type {
  ExcludePlaidTransactionRequestDto,
  GetAllPlaidTransactionsQuery,
  GetUpdateLinkTokenDto,
  GetUpdateLinkTokenResponseDto,
  MatchExistingTransactionsRequest,
  MatchNewExpenseRequestDto,
  MatchNewTransferRequestDto,
  PlaidTransactionDto,
  PostCreatePlaidItemDto,
  UndoPlaidTransactionRequestDto,
  RepairConnectionDto
} from "@doorloop/dto";
import {
  PostConnectPlaidAccountDto,
  PostDisconnectPlaidAccountDto,
  PostRefreshPlaidTransactionsDto,
  ServerRoutes
} from "@doorloop/dto";
import { RestApiBase } from "./restApiBase";
import { apiHelper } from "api/apiHelper";
import type { ApiResult } from "api/apiResult";
import { store } from "store/index";
import { updateIsFeatureEnabled } from "store/auth/actions";
import { bankAccountsApi } from "api/bankAccountsApi";

export class PlaidTransactionsApi extends RestApiBase<PlaidTransactionDto, GetAllPlaidTransactionsQuery> {
  constructor() {
    super(ServerRoutes.BANK_CONNECT_PLAID_TRANSACTIONS);
    this.restRoute = ServerRoutes.BANK_CONNECT_PLAID_TRANSACTIONS;
  }

  refreshPlaidTransactions = async (plaidItem: string): Promise<ApiResult<any>> => {
    const data = new PostRefreshPlaidTransactionsDto();
    data.plaidItem = plaidItem;

    return await apiHelper.axiosPost({ url: ServerRoutes.BANK_CONNECT_PLAID_TRANSACTIONS_POST_REFRESH, data });
  };

  repairConnection = async (data: RepairConnectionDto) =>
    await apiHelper.axiosPost({ url: ServerRoutes.BANK_CONNECT_PLAID_REPAIR_CONNECTION, data });

  resetLogin = async (plaidItem: string): Promise<ApiResult<any>> => {
    const data = new PostRefreshPlaidTransactionsDto();
    data.plaidItem = plaidItem;

    return await apiHelper.axiosPost({ url: ServerRoutes.BANK_CONNECT_PLAID_ITEMS_POST_RESET_LOGIN, data });
  };

  getPlaidToken = async () => await apiHelper.axiosGet(ServerRoutes.BANK_CONNECT_PLAID_ITEMS_GET_NEW_PLAID_LINK_TOKEN);

  getPlaidTokenForRepair = async (plaidItemId?: string) =>
    await apiHelper.axiosGet(ServerRoutes.BANK_CONNECT_PLAID_ITEMS_GET_NEW_PLAID_LINK_TOKEN_FOR_REPAIR, {
      plaidItemId
    });

  connectPlaidItem = async (data: PostCreatePlaidItemDto) =>
    await apiHelper.axiosPost({ url: ServerRoutes.BANK_CONNECT_PLAID_ITEMS, data });

  connectPlaidAccount = async (plaidAccount: string, account: string) => {
    const params = new PostConnectPlaidAccountDto();
    params.account = account;
    params.plaidAccount = plaidAccount;
    const response = await apiHelper.axiosPost({
      url: ServerRoutes.BANK_CONNECT_PLAID_ACCOUNTS_POST_CONNECT,
      data: params
    });

    if (response?.status) {
      store.dispatch(updateIsFeatureEnabled(true, "BankConnect"));
    }

    return response;
  };

  updatePlaidAccountLinkToken = async (updateLinkTokenParams?: GetUpdateLinkTokenDto) =>
    await apiHelper.axiosGet(ServerRoutes.BANK_CONNECT_PLAID_ITEMS_GET_UPDATE_PLAID_LINK_TOKEN, updateLinkTokenParams);

  disconnectPlaidAccount = async (accountId: string): Promise<ApiResult<GetUpdateLinkTokenResponseDto>> => {
    const params = new PostDisconnectPlaidAccountDto();
    params.account = accountId;

    const response = await apiHelper.axiosPost({
      url: ServerRoutes.BANK_CONNECT_PLAID_ACCOUNTS_POST_DISCONNECT,
      data: params,
      toasts: { severity: "error" }
    });

    if (response?.status) {
      const connectedAccounts = await bankAccountsApi.getAll({
        filter_bankConnectEnabled: true
      });

      if (connectedAccounts.data?.data.length === 0) {
        store.dispatch(updateIsFeatureEnabled(false, "BankConnect"));
      }
    }

    return response;
  };

  matchNewExpense = async (data: MatchNewExpenseRequestDto) =>
    await apiHelper.axiosPost({
      url: ServerRoutes.BANK_CONNECT_PLAID_TRANSACTIONS_POST_MATCH_NEW_EXPENSE,
      data
    });

  matchNewDeposit = async (data: MatchNewExpenseRequestDto) =>
    await apiHelper.axiosPost({
      url: ServerRoutes.BANK_CONNECT_PLAID_TRANSACTIONS_POST_MATCH_NEW_DEPOSIT,
      data
    });

  matchLeasePayment = async (data: MatchNewExpenseRequestDto) =>
    await apiHelper.axiosPost({
      url: ServerRoutes.BANK_CONNECT_PLAID_TRANSACTIONS_POST_MATCH_LEASE_PAYMENT,
      data
    });

  excludeTransaction = async (data: ExcludePlaidTransactionRequestDto) =>
    await apiHelper.axiosPost({
      url: ServerRoutes.BANK_CONNECT_PLAID_TRANSACTIONS_POST_EXCLUDE,
      data
    });

  undoPlaidTransaction = async (data: UndoPlaidTransactionRequestDto) =>
    await apiHelper.axiosPost({
      url: ServerRoutes.BANK_CONNECT_PLAID_TRANSACTIONS_POST_UNDO,
      data
    });

  matchExistingTransaction = async (data: MatchExistingTransactionsRequest) =>
    await apiHelper.axiosPost({
      url: ServerRoutes.BANK_CONNECT_PLAID_TRANSACTIONS_POST_MATCH_EXISTING,
      data
    });

  matchBankTransfer = async (data: MatchNewTransferRequestDto) =>
    await apiHelper.axiosPost({
      url: ServerRoutes.BANK_CONNECT_PLAID_TRANSACTIONS_POST_MATCH_NEW_TRANSFER,
      data
    });
}

export const plaidTransactionsApi = new PlaidTransactionsApi();
