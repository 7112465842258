import { useSelector } from "react-redux";
import { useSetAtom } from "jotai";

import { ObjectPermission } from "@doorloop/dto";

import type { IRootState } from "store/index";
import { conversationsApi } from "api/conversationsApi";
import { conversationsStatsAtom } from "screens/communicationsCenter/shared/conversationsStatsAtom";
import { useEffectAsync } from "../../../../hooks/useEffectAsync";
import { usePermission } from "screens/settings/userRoles/usePermission";

export const useLoadConversationsStats = () => {
  const currentLoginResponse = useSelector((state: IRootState) => state.auth.currentLoginResponse);
  const { hasPermission } = usePermission();

  const setConversationsStatsAtom = useSetAtom(conversationsStatsAtom);

  useEffectAsync(async () => {
    if (!currentLoginResponse) {
      return;
    }

    if (!hasPermission(ObjectPermission.conversations, "viewList")) {
      return;
    }

    const { data } = await conversationsApi.getStats();

    if (data) {
      setConversationsStatsAtom({
        lastDate: new Date(),
        conversationsStats: data
      });
    }
  }, [currentLoginResponse?.id]);
};
