import type { Dispatch, SetStateAction } from "react";
import _ from "lodash";

import { ProfitAndLossReportRootAccounts } from "@doorloop/dto";

import type {
  GetFooterGroupByValue,
  MapFooterGroupByFieldValue,
  TreeListBulkAction,
  TreeListColumn,
  TreeListItemAggregated,
  TreeListState
} from "DLUI/lists/treeList/useTreeList/types";
import type { MapTreeListDataSource } from "DLUI/lists/treeList/useTreeList/useTreeListState";
import { getFixedNumber } from "DLUI/lists/treeList/useTreeList/useTreeListState";

const treeListIsFlatDataField = "isFlatData";
const treeListExpandField = "isExpanded";
const treeListSelectedField = "isSelected";
const treeListEditField = "isEditing";
const treeListTotalField = "total";
const treeListTotalWithSubAccountsField = "totalWithSubAccounts";
const treeListNameField = "name";
const treeListSubItemsField = "subItems";
const treeListReferenceDataField = "referenceData";
const treeListTotalColumnIndex = 1;
const treeListContainerMinWidth = 800;
const treeListDefaultColumnWidth = 180;
const treeListSelectedColumnWidth = 42;

const calculateTreeListTotalWithSubAccounts: GetFooterGroupByValue = ({ dataItem, field }) => {
  if (dataItem.footer || !field) {
    return 0;
  }

  const subItems = dataItem[treeListSubItemsField];
  let total = dataItem.type === "category" && subItems?.length ? 0 : _.get(dataItem, field.split("."), 0);

  if (subItems) {
    subItems.forEach((subItem) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!subItem.footer) {
        total += calculateTreeListTotalWithSubAccounts({ dataItem: subItem, field });
      }
    });
  }

  return getFixedNumber(total);
};

const calculateTreeListTotalWithSubAccountsForGroupByColumns = (dataItem: TreeListItemAggregated, groupId: string) =>
  calculateTreeListTotalWithSubAccounts({ dataItem, field: `groupBy.${groupId}` });

interface SetTreeListItemTotalsProps {
  field: string;
  dataItem: TreeListItemAggregated;
}

const setTreeListItemTotals = ({ field, dataItem }: SetTreeListItemTotalsProps) => {
  if (dataItem.groupBy) {
    if (field !== treeListTotalField) {
      const values = Object.values(dataItem.groupBy);

      if (values) {
        dataItem[treeListTotalField] = _.sum(values);
      }
    }

    dataItem[treeListTotalWithSubAccountsField] = calculateTreeListTotalWithSubAccounts({
      dataItem,
      field: treeListTotalField
    });
  } else {
    dataItem[treeListTotalWithSubAccountsField] = calculateTreeListTotalWithSubAccounts({
      dataItem,
      field: treeListTotalField
    });
  }
};

const getTreeListGroupByColumns = (columns: TreeListColumn[]) =>
  columns.filter(
    (column) => column.field && ![treeListNameField, treeListTotalField, treeListSelectedField].includes(column.field)
  );

interface MapTreeListItemGroupByIndexProps {
  currentGroupBy?: Record<TreeListColumn["title"], any>;
  fromGroupByColumns: TreeListColumn[];
  toGroupByColumns: TreeListColumn[];
}

const mapTreeListItemGroupByIndex = ({
  currentGroupBy,
  fromGroupByColumns,
  toGroupByColumns
}: MapTreeListItemGroupByIndexProps) => {
  const groupBy = {};

  if (currentGroupBy) {
    Object.keys(currentGroupBy).forEach((title) => {
      const columnIndex = fromGroupByColumns.findIndex((column) => column.title === title);

      if (columnIndex !== undefined && columnIndex >= 0) {
        const newTitle = toGroupByColumns[columnIndex].title;

        if (newTitle) {
          groupBy[newTitle] = currentGroupBy[title];
        }
      }
    });
  }

  return groupBy;
};

interface MapTreeListItemGroupByProps extends MapTreeListItemGroupByIndexProps {
  mapBy: "date" | "index";
}

const mapTreeListItemGroupBy = ({
  currentGroupBy,
  fromGroupByColumns,
  toGroupByColumns,
  mapBy
}: MapTreeListItemGroupByProps) => {
  const groupBy = {};

  if (currentGroupBy) {
    if (mapBy === "index") {
      return mapTreeListItemGroupByIndex({
        currentGroupBy,
        fromGroupByColumns,
        toGroupByColumns
      });
    }
    if (mapBy === "date") {
      const currentGroupByKeys = Object.keys(currentGroupBy);
      const splitList = currentGroupByKeys[0]?.split("-");

      if (splitList) {
        if (splitList.length === 1) {
          // map years by index
          return mapTreeListItemGroupByIndex({
            currentGroupBy,
            fromGroupByColumns,
            toGroupByColumns
          });
        }
        currentGroupByKeys.forEach((title) => {
          const splitList = title.split("-");
          const lastString = splitList.pop();

          if (lastString) {
            const newTitle = toGroupByColumns.find((column) => column.title.split("-").pop() === lastString)?.title;

            if (newTitle) {
              groupBy[newTitle] = currentGroupBy[title];
            }
          }
        });
      }
    }
  }

  return groupBy;
};

interface UpdateTreeListBulkActionsProps {
  bulkActions: TreeListBulkAction[];
  setTreeListState: Dispatch<SetStateAction<TreeListState>>;
  mapFooterGroupByFieldValue?: MapFooterGroupByFieldValue;
}

const updateTreeListBulkActions = ({ bulkActions, setTreeListState }: UpdateTreeListBulkActionsProps) => {
  setTreeListState((state) => {
    return { ...state, bulkActions };
  });
};

const mapProfitAndLossTreeListDataSource: MapTreeListDataSource = ({
  dataSource,
  columns,
  mapFooterGroupByFieldValue
}) => {
  const groupedDataSource = _.groupBy(dataSource, "typeId");
  const income = groupedDataSource[ProfitAndLossReportRootAccounts.INCOME]?.[0];
  const costOfGoodsSold = groupedDataSource[ProfitAndLossReportRootAccounts.COST_OF_GOODS_SOLD]?.[0];
  const expenses = groupedDataSource[ProfitAndLossReportRootAccounts.EXPENSES]?.[0];
  const grossProfit = groupedDataSource[ProfitAndLossReportRootAccounts.GROSS_PROFIT]?.[0];
  const netOperatingIncome = groupedDataSource[ProfitAndLossReportRootAccounts.NET_OPERATING_INCOME]?.[0];
  const otherIncome = groupedDataSource[ProfitAndLossReportRootAccounts.OTHER_INCOME]?.[0];
  const otherExpenses = groupedDataSource[ProfitAndLossReportRootAccounts.OTHER_EXPENSES]?.[0];
  const otherNetIncome = groupedDataSource[ProfitAndLossReportRootAccounts.NET_OTHER_INCOME]?.[0];
  const netIncome = groupedDataSource[ProfitAndLossReportRootAccounts.NET_INCOME]?.[0];

  if (
    income &&
    costOfGoodsSold &&
    expenses &&
    otherIncome &&
    otherExpenses &&
    grossProfit &&
    netOperatingIncome &&
    otherNetIncome &&
    netIncome
  ) {
    columns.forEach((column) => {
      if (column.field && !mapFooterGroupByFieldValue?.[column.field]) {
        const field = column.field === treeListTotalField ? "totalWithSubAccounts" : column.field;
        const path = field?.split(".");

        if (path) {
          const valueIncome = _.get(_.last(income.subItems), path, 0);
          const valueCostOfGoodsSold = _.get(_.last(costOfGoodsSold.subItems), path, 0);
          const valueGrossProfit = valueIncome - valueCostOfGoodsSold;
          _.set(grossProfit, path, valueGrossProfit);

          const valueExpenses = _.get(_.last(expenses.subItems), path, 0);
          const valueNetOperatingIncome = valueGrossProfit - valueExpenses;
          _.set(netOperatingIncome, path, valueNetOperatingIncome);

          const valueOtherIncome = _.get(_.last(otherIncome.subItems), path, 0);
          const valueOtherExpenses = _.get(_.last(otherExpenses.subItems), path, 0);
          const valueOtherNetIncome = valueOtherIncome - valueOtherExpenses;
          _.set(otherNetIncome, path, valueOtherNetIncome);

          const valueNetIncome = valueNetOperatingIncome + valueOtherNetIncome;
          _.set(netIncome, path, valueNetIncome);
        }
      }
    });

    columns.forEach((column) => {
      const getValue = column.field && mapFooterGroupByFieldValue?.[column.field];
      if (getValue) {
        const path = column.field?.split(".");

        if (path) {
          const categories = [grossProfit, netOperatingIncome, otherIncome, otherExpenses, otherNetIncome, netIncome];

          categories.forEach((category) => {
            const value = getValue({
              dataItem: category,
              groupBy: category.groupBy
            });

            _.set(category, path, value);
          });
        }
      }
    });
  }

  return dataSource;
};

function getCountSelectedFromDataTree(selectedState: Record<string, boolean>, rootAccountIds: string[]): number {
  return Object.keys(selectedState).filter((typeId) => selectedState[typeId] && !rootAccountIds.includes(typeId))
    .length;
}

export {
  treeListIsFlatDataField,
  treeListContainerMinWidth,
  treeListDefaultColumnWidth,
  treeListSelectedColumnWidth,
  treeListTotalColumnIndex,
  treeListExpandField,
  treeListSelectedField,
  treeListEditField,
  treeListTotalField,
  treeListTotalWithSubAccountsField,
  treeListNameField,
  treeListSubItemsField,
  treeListReferenceDataField,
  calculateTreeListTotalWithSubAccounts,
  calculateTreeListTotalWithSubAccountsForGroupByColumns,
  setTreeListItemTotals,
  getTreeListGroupByColumns,
  mapTreeListItemGroupBy,
  updateTreeListBulkActions,
  mapProfitAndLossTreeListDataSource,
  getCountSelectedFromDataTree
};
