import React, { Fragment } from "react";
import Cohere from "cohere-js";
import { isProductionEnv } from "./environmentHelper";
import "./wootricStyles.css";
import parser from "ua-parser-js";
import { isString } from "lodash";
import { analyticsService } from "../services/analyticsService";
import { ProdHelmet } from "@/components/helmets/prodHelmet";
import { Helmet } from "react-helmet-async";

interface ComponentProps {
  children: React.ReactNode;
}

export const SESSION_STORAGE_COHERE_RECORDING_KEY = "techAccess";

const WOOTRIC_SDK_URL = "https://cdn.wootric.com/wootric-sdk.js";
const PRINT_OVERRIDE_STYLES_PATH = "/styles/printOverrideStyles.css";

const getAgentBrowserVersion = (version: string): number => {
  if (!isString(version) || !version.includes(".")) return -1;

  const [majorVersion] = version.split(".");
  return Number(majorVersion);
};

const { browser } = parser(navigator.userAgent);
const browserVersion = getAgentBrowserVersion(browser.version);
const shouldLoadPrintOverrideStyles = browserVersion >= 117 && browserVersion < 119 && browser?.name === "Chrome";

const AppScriptsLauncher: React.FC<ComponentProps> = ({ children }: ComponentProps) => {
  let newrelcScriptUrl = window.location.protocol + "//" + window.location.hostname;

  if (window.location.port) {
    newrelcScriptUrl += ":" + window.location.port;
  }

  newrelcScriptUrl += "/scripts/newrelc.js";

  if (isProductionEnv) {
    try {
      let disableRecording = true;
      if (sessionStorage.getItem(SESSION_STORAGE_COHERE_RECORDING_KEY)) {
        disableRecording = false;
      }
      Cohere.init("9jbbN3SSy7MuTEIHd3hpW2WR", { disableRecording });
    } catch (e) {
      console.error(JSON.stringify(e));
    }
  }

  return (
    <>
      <ProdHelmet>
        <script src={newrelcScriptUrl} async></script>
        <script src={WOOTRIC_SDK_URL} async></script>
      </ProdHelmet>
      <ProdHelmet
        scriptSrc={"/scripts/posthog.js"}
        onScriptLoad={() => {
          analyticsService.startSessionRecording();
        }}
      >
        <script src="/scripts/posthog.js" async></script>
      </ProdHelmet>
      <Helmet>
        {shouldLoadPrintOverrideStyles && <link rel="stylesheet" type="text/css" href={PRINT_OVERRIDE_STYLES_PATH} />}
      </Helmet>
      {children}
    </>
  );
};

export default AppScriptsLauncher;
