import { budgetsApi } from "api/budgetsApi";
import { expensesStore } from "api/entityApiStore/stores/expensesStore";
import { leasesApi } from "api/leasesApi";
import { ownerPortalBudgetsApi } from "api/ownerPortalBudgetsApi";
import { propertiesApi } from "api/propertiesApi";
import { rentalApplicationsApi } from "api/rentalApplicationsApi";
import { tagsApi } from "api/tagsApi";
import { tasksApi } from "api/tasksApi";
import { leaseTenantsApi } from "api/tenantsApi";
import { unitsApi } from "api/unitsApi";
import { ownersApi } from "../ownersApi";
import { vendorsApi } from "../vendorsApi";
import { createQueryStoreWithDictionary } from "./createDictionaryQueryStore";
import { createMutationStore } from "./createMutationStore";
import { createQueryStore } from "./createQueryStore";
import { tenantPortalPaymentsStore } from "./stores/tenantPortalPaymentsStore";
import { tenantsStore } from "./stores/tenantsStore";
import { usersStore } from "./stores/usersStore";
import { vendorBillPaymentsStore } from "./stores/vendorBillPaymentsStore";
import { tenantPortalRequestsStore } from "./stores/tenanPortalRequestsStore";
import { tenantPortalFilesApi } from "../filesApi";
import { tenantPortalLeasesApi } from "../tenantPortal/tenantPortalLeasesApi";
import { accountsApi } from "api/accounts";
import { globalSettingsStore } from "api/entityApiStore/stores/globalSettingsStore";
import { merchantAccountsStore } from "./stores/merchantAccountsStore";

/**
 * Notion Docs: https://www.notion.so/Api-Store-Documentation-ce1a1dfed2574603a3875d414f48b03e?pvs=4
 */
export const entityApiStore = {
  properties: {
    queries: createQueryStoreWithDictionary("properties", propertiesApi),
    mutations: createMutationStore("properties", propertiesApi)
  },
  units: {
    queries: createQueryStoreWithDictionary("units", unitsApi),
    mutations: createMutationStore("units", unitsApi)
  },
  leases: {
    queries: createQueryStoreWithDictionary("leases", leasesApi),
    mutations: createMutationStore("leases", propertiesApi)
  },
  leaseTenants: {
    queries: createQueryStore("lease-tenants", leaseTenantsApi),
    mutations: createMutationStore("lease-tenants", leaseTenantsApi)
  },
  tenants: tenantsStore,
  vendors: {
    queries: createQueryStoreWithDictionary("vendors", vendorsApi),
    mutations: createMutationStore("vendors", vendorsApi)
  },
  owners: {
    queries: createQueryStoreWithDictionary("owners", ownersApi),
    mutations: createMutationStore("owners", ownersApi)
  },
  tags: {
    queries: createQueryStoreWithDictionary("tags", tagsApi),
    mutations: createMutationStore("tags", tagsApi)
  },
  budgets: {
    queries: createQueryStore("budgets", budgetsApi),
    mutations: createMutationStore("budgets", budgetsApi)
  },
  ownerPortalBudgets: {
    queries: createQueryStore("owner-portal-budgets", ownerPortalBudgetsApi),
    mutations: createMutationStore("owner-portal-budgets", ownerPortalBudgetsApi)
  },
  users: usersStore,
  tenantPortalPayments: tenantPortalPaymentsStore,
  tenantPortalRequests: tenantPortalRequestsStore,
  tasks: {
    queries: createQueryStore("tasks", tasksApi),
    mutations: createMutationStore("tasks", tasksApi)
  },
  tenantPortalFiles: {
    queries: createQueryStore("tenant-portal-files", tenantPortalFilesApi),
    mutations: createMutationStore("tenant-portal-files", tenantPortalFilesApi)
  },
  expensesApi: expensesStore,
  tenantPortalLeases: {
    queries: createQueryStore("tenant-portal-leases", tenantPortalLeasesApi)
  },
  globalSettings: globalSettingsStore,
  expenses: expensesStore,
  vendorBillPayments: vendorBillPaymentsStore,
  rentalApplications: {
    queries: createQueryStore("rentalApplications", rentalApplicationsApi)
  },
  accounts: {
    queries: createQueryStoreWithDictionary("accounts", accountsApi)
  },
  merchantAccounts: merchantAccountsStore
} as const;
