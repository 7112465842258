import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { EnTranslationNamespace, TranslationNamespace, Translation } from "./types";
import type { StringMap, TOptions } from "i18next";

type TypedTFunction<TTranslation> = (key: TTranslation, options?: string | TOptions<StringMap>) => string;
interface UseTypedTranslationReturnParams<TTranslation> {
  t: TypedTFunction<TTranslation>;
}

export function useTypedTranslation<
  TNamespace extends TranslationNamespace = EnTranslationNamespace,
  TTranslation extends Translation = Translation
>(...ns: TNamespace[]): UseTypedTranslationReturnParams<TTranslation> {
  const { t } = useTranslation(ns as string[]);
  t;
  const wrappedReturn = useMemo(() => {
    return { t: (key: TTranslation, options?: string | TOptions<StringMap>) => t(key as never, options) };
  }, [t]);

  return wrappedReturn;
}

export type TranslateFn<TTranslation extends Translation = Translation> =
  UseTypedTranslationReturnParams<TTranslation>["t"];
