import type { FC } from "react";
import React, { useCallback, useMemo } from "react";
import { View } from "DLUI/view";
import { FastField, useFormikContext } from "formik";
import { FormikDatePicker, FormikSwitchButton, Select, TextField } from "DLUI/form";
import AppStrings from "locale/keys";
import type { RecurringTransactionDto, RecurringTransactionType } from "@doorloop/dto";
import { RecurringTransactionFrequency } from "@doorloop/dto";
import { getRecurringSettings } from "DLUI/recurringDateForm/recurringStringsMap";
import { useTranslation } from "react-i18next";
import { useRecurringState } from "hooks/useRecurringState";
import BasicGrid from "DLUI/listItems/basicGrid";
import FieldWrapper from "DLUI/form/textField/fieldWrapper";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import moment from "moment";

interface ComponentProps {
  type: RecurringTransactionType;
}

const fieldsToValidate = ["start", "end", "frequency", "numberOfDaysUntilDue"];

export const RecurringDateForm: FC<ComponentProps> = ({ type }: ComponentProps) => {
  const { t } = useTranslation();
  const formikRef = useFormikContext<RecurringTransactionDto>();
  const { verticalInputPadding } = useResponsiveHelper();
  const { isNumberOfDaysTillDueEnabled } = getRecurringSettings(type);
  const { setValidator, setRecurringData } = useRecurringState();
  const minDateLikeLastOccurrence = useMemo(() => {
    const occurrenceValue = formikRef.values?.lastOccurrence || formikRef.values?.nextOccurrence;
    return occurrenceValue ? moment(occurrenceValue).toDate() : undefined;
  }, [formikRef.values?.lastOccurrence, formikRef.values?.nextOccurrence]);

  const validateForm = useCallback(async (): Promise<boolean> => {
    fieldsToValidate.forEach((field) => formikRef.setFieldTouched(field));
    const errorsObject = await formikRef.validateForm();
    const hasErrors = Boolean(Object.keys(errorsObject).find((err) => fieldsToValidate.includes(err)));
    return !hasErrors;
  }, [formikRef.values]);

  setRecurringData(formikRef.values);

  useMemo(() => {
    setValidator(validateForm);
  }, [validateForm, setValidator]);

  return (
    <View alignItems="flex-start" flexDirection={"row"}>
      <BasicGrid autoHeight preset={"44612"}>
        <FieldWrapper type={"endElement"}>
          <FastField
            component={FormikDatePicker}
            uniqueKey={"start"}
            label={AppStrings.Leases.NewLease.StartDate}
            name={"start"}
            minDate={minDateLikeLastOccurrence}
            required
            noMargin
          />
        </FieldWrapper>
      </BasicGrid>
      <BasicGrid autoHeight preset={"44612"}>
        <FieldWrapper type={"middleElement"}>
          {formikRef.values.noEndDate ? (
            <FastField
              uniqueKey={"endDisabled"}
              name={"endDisabled"}
              component={FormikDatePicker}
              label={AppStrings.Leases.NewLease.EndDate}
              noMargin
              disabled
            />
          ) : (
            <FastField
              component={FormikDatePicker}
              uniqueKey={"end"}
              label={AppStrings.Leases.NewLease.EndDate}
              name={"end"}
              required
              noMargin
            />
          )}
        </FieldWrapper>
      </BasicGrid>
      <BasicGrid autoHeight preset={"44612"}>
        <FieldWrapper type={"startElement"}>
          <FastField
            component={Select}
            name={`frequency`}
            label={AppStrings.Leases.NewLease.LeaseRent.Frequency}
            required
            uniqueKey={"frequency"}
            selectionEnum={RecurringTransactionFrequency}
            translationKey={"recurringTransactionFrequency"}
            viewOnly={false}
          />
        </FieldWrapper>
      </BasicGrid>
      {isNumberOfDaysTillDueEnabled && (
        <BasicGrid autoHeight preset={"44612"}>
          <FieldWrapper type={"endElement"}>
            <FastField
              component={TextField}
              label={t(AppStrings.Common.Recurring.NumberOfDaysUntilDue)}
              name={"numberOfDaysUntilDue"}
              formatType={"number"}
              noMargin
            />
          </FieldWrapper>
        </BasicGrid>
      )}
      <BasicGrid autoHeight preset={"44612"}>
        <View
          paddingLeft={verticalInputPadding}
          paddingRight={verticalInputPadding}
          height="100%"
          justifyContent={"center"}
        >
          <FastField
            component={FormikSwitchButton}
            name={"noEndDate"}
            label={AppStrings.Common.Recurring.RepeatForever}
            paddingRight={verticalInputPadding}
            marginTop={20}
          />
        </View>
      </BasicGrid>
    </View>
  );
};
