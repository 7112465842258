import type { GetAllTenantsQuery, TenantDto } from "@doorloop/dto";
import { OwnerPortalServerRoutes } from "@doorloop/dto";

import { RestApiBaseWithDictionary } from "api/restApiBaseWithDictionary";

export class OwnerPortalTenantsApi extends RestApiBaseWithDictionary<TenantDto, GetAllTenantsQuery> {
  constructor() {
    super(OwnerPortalServerRoutes.TENANTS);
    this.restRoute = OwnerPortalServerRoutes.TENANTS;
  }
}

export const ownerPortalTenantsApi = new OwnerPortalTenantsApi();
