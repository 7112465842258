import { makeStyles } from "@material-ui/styles";
import type { Theme } from "styles/defaultTheme";

export const useGeneralStyles = makeStyles<Theme>((theme: Theme) => {
  return {
    flexColumn: {
      display: "flex",
      flexDirection: "column"
    },
    grid: { display: "grid" },
    flexRow: {
      display: "flex",
      flexDirection: "row"
    },
    spaceBetween: {
      justifyContent: "space-between"
    },
    xlGap: {
      gap: 32
    },
    largeGap: {
      gap: 16
    },
    mediumGap: {
      gap: 8
    },
    smallGap: {
      gap: 4
    },
    xlPadding: {
      padding: 32
    },
    largePadding: {
      padding: 16
    },
    mediumPadding: {
      padding: 8
    },
    smallPadding: {
      padding: 4
    },
    verticalAlign: {
      alignItems: "center"
    },
    verticalFlexStart: {
      alignItems: "flex-start"
    },
    basicFlex: {
      flex: 1
    },
    fullWidth: {
      width: "100%"
    },
    fitContent: {
      width: "fit-content"
    }
  };
});
