import { useTypedTranslation } from "@/locale";
import type { DateRangeFieldsProps } from "../types";
import { DatePickerField } from "./datePickerField";
import { DatePickerSelectInput } from "./datePickerSelectInput";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { DataCy } from "@doorloop/dto";

export function DateRangeFieldsFlat({
  selectionEnum,
  formattedSelectedPeriod,
  onChange,
  showPickerFields,
  hidePeriodField,
  uniqueKey,
  startDatePickerValue,
  startMinDate,
  startMaxDate,
  startDateOnChange,
  endDateOnChange,
  endDatePickerValue,
  endMinDate,
  endMaxDate
}: DateRangeFieldsProps) {
  const { t } = useTypedTranslation();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div
        style={{
          display: "flex",
          gap: 8
        }}
      >
        {!hidePeriodField && (
          <div>
            <DatePickerSelectInput
              selectionEnum={selectionEnum}
              formattedSelectedPeriod={formattedSelectedPeriod}
              onChange={onChange}
            />
          </div>
        )}
        {showPickerFields && (
          <>
            <DatePickerField
              label={t("common.filterPanelDatePicker.from")}
              uniqueKey={uniqueKey}
              onChange={startDateOnChange}
              pickerValue={startDatePickerValue}
              minDate={startMinDate}
              maxDate={startMaxDate}
              dataCy={DataCy.DLUI.filters.fromDateFilter}
            />
            <DatePickerField
              label={t("common.filterPanelDatePicker.to")}
              uniqueKey={uniqueKey}
              onChange={endDateOnChange}
              pickerValue={endDatePickerValue}
              minDate={endMinDate}
              maxDate={endMaxDate}
              dataCy={DataCy.DLUI.filters.toDateFilter}
            />
          </>
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
}
