import { ServerRoutes } from "@doorloop/dto";
import type { GetAllLeaseRefundsQuery, LeaseRefundDto } from "@doorloop/dto";
import { RestApiBase } from "./restApiBase";

export class LeaseRefundApi extends RestApiBase<LeaseRefundDto, GetAllLeaseRefundsQuery> {
  constructor() {
    super(ServerRoutes.LEASE_REFUNDS, []);
    this.restRoute = ServerRoutes.LEASE_REFUNDS;
  }
}

export const leaseRefundApi = new LeaseRefundApi();
