"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkedToWhat = void 0;
var LinkedToWhat;
(function (LinkedToWhat) {
    LinkedToWhat["PROPERTY"] = "property";
    LinkedToWhat["LEASE"] = "lease";
    LinkedToWhat["UNIT"] = "unit";
    LinkedToWhat["MANAGEMENT_COMPANY"] = "managementCompany";
})(LinkedToWhat || (exports.LinkedToWhat = LinkedToWhat = {}));
