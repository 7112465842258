export default {
  form: {
    tooltipBulkActionClearData: "Click to clear the values in the selected rows.",
    tooltipBulkActionFillReferenceData: "Click to fill selected rows with the reference data.",
    tooltipBulkActionFillTotal: "Click to set the total of all selected rows",
    disabledButtons: {
      fillWithReferenceData: "Use the checkboxes to select rows and click here to fill them with reference data.",
      customTotalAmount:
        "Use the checkboxes to select rows and click here to enter a value to be distributed across the selected rows.",
      clearData: "Use the checkboxes to select rows and click here to clear the values in the selected rows."
    },
    newBudget: "New Budget",
    editBudget: "Edit Budget",
    budgetName: "Budget Name",
    referenceData: "Reference Data",
    showReferenceData: "Show reference data",
    leavingFormPrompt: "You have unsaved changes. Are you sure you want to leave this page?",
    hideEmptyRows: "Hide Empty Rows",
    showEmptyRows: "Show Empty Rows",
    budgetTypes: {
      actual: "Actual Reports",
      budget: "Budgets"
    },
    settings: {
      budgetAttributes: "Budget Attributes",
      budgetType: "Budget Type",
      propertyType: "Property type",
      budgetPeriod: "Budget Period",
      startingMonth: "Starting Month",
      startingQuarter: "Starting Quarter",
      fiscalYear: "Fiscal Year",
      budgetBy: "Budget By"
    },
    errors: {
      nameExists: "Budget name already exists",
      referenceDataFetchError: "Error fetching the reference data"
    },
    periodType: {
      month: "Monthly",
      quarter: "Quarterly",
      year: "Yearly"
    },
    resetFormConfirmationTitle: "Are you sure?",
    resetFormConfirmationSubTitle: "This action will reset the table data",
    submitNotBalancedConfirmationTitle: "Are you sure?",
    submitNotBalancedConfirmationSubTitle: "Assets are not balanced with Liabilities and Equity"
  },
  screen: {
    title: "Budgeting",
    createNew: "New Budget",
    searchPlaceHolder: "Search",
    emptyDataViewInstructionsText: "No Active Budgets",
    emptyDataViewInstructionsSubText:
      "Create budgets to plan your finances, compare against actual performance, and maximize your company’s potential",
    helpPanel: {
      description:
        "Create budgets to plan your finances, compare against actual performance, and maximize your company’s potential.",
      budgetingOverview: "Budgeting Overview",
      howDoICreateANewBudget: "How Do I Create A New Budget?",
      howDoIRunABudgetVsActualReport: "How Do I Run a Budget vs Actual Report?"
    }
  },
  listItem: {
    columns: {
      name: "Budget Name",
      period: "Time Period",
      budgetType: "Budget Type",
      linkedEntity: "Property/Portfolio"
    },
    actions: {
      runReport: "Run Budget vs Actual Report",
      edit: "Edit budget",
      duplicate: "Duplicate Budget",
      delete: "Delete Budget"
    },
    deleteConfirmationDialog: {
      subTitle: "Are you sure you want to delete this Budget?",
      confirmButton: "Delete Budget"
    },
    linkedType: {
      property: "Property",
      portfolio: "Portfolio"
    }
  },
  budgetVsActualReport: {
    from: "From",
    to: "To",
    chooseBudgetType: "Budgeted By",
    chooseProperty: "Property",
    choosePortfolio: "Portfolio",
    chooseBudget: "Budget",
    runReport: "Run Report",
    reportSettings: "Report Settings",
    chooseReportSettings: "Choose Report Settings",
    initialViewDescription: "Select Budget and run it against the actual data report"
  }
};
