import React, { useEffect, useState } from "react";
import moment from "moment";

import { AccountingMethod } from "@doorloop/dto";

import { accountBalanceApi } from "api/accounts";
import AppStrings from "locale/keys";

import Text from "DLUI/text";
import { View } from "DLUI/view";
import { Link } from "DLUI/link";

import ViewOnlyInput from "../../form/viewOnlyInput/viewOnlyInput";

interface ComponentProps {
  propertyId?: string;
  depositAccountId?: string;
  marginTop?: number;
  paddingRight?: number;
  paddingLeft?: number;
  accountingMethod?: AccountingMethod;
  label?: string;
  onClick?: () => void;
}

const AccountBalanceLoader: React.FC<ComponentProps> = ({
  propertyId,
  depositAccountId,
  marginTop,
  paddingRight,
  paddingLeft,
  accountingMethod,
  label,
  onClick
}: ComponentProps) => {
  const [balanceAmount, setBalanceAmount] = useState<undefined | string>();

  const showViewReportButton = onClick !== undefined;

  useEffect(() => {
    if (depositAccountId) {
      setBalanceAmount(undefined);
      getbalance();
    } else if (balanceAmount) {
      setBalanceAmount(undefined);
    }
  }, [propertyId, depositAccountId]);

  const getbalance = async () => {
    const queryParams: any = {
      filter_account: depositAccountId,
      filter_accountingMethod: accountingMethod || AccountingMethod.CASH,
      filter_asOfDate: moment().format("YYYY-MM-DD")
    };
    if (propertyId) {
      queryParams["filter_property"] = propertyId;
    }
    const response = await accountBalanceApi.getAll(queryParams);
    if (response && response.data) {
      //@ts-ignore
      setBalanceAmount(response.data);
    }
  };

  const _paddingRight = paddingRight || (showViewReportButton ? 20 : 0);

  return (
    <View
      paddingRight={_paddingRight}
      paddingLeft={paddingLeft}
      marginTop={marginTop}
      onClick={onClick}
      flexDirection={"row"}
      alignItems={"center"}
      width={`calc(100% - ${(paddingLeft || 0) + _paddingRight}px)`}
      noWrap
      gap={10}
    >
      <ViewOnlyInput
        value={balanceAmount || "0"}
        label={label || AppStrings.Leases.LeaseTransaction.Refund.Accountbalance}
        formatType={"currency"}
        fullWidth
      />

      {showViewReportButton && (
        <View justifyContent={"center"} autoWidth>
          <Link hoverColor={"lightBlue"} onClick={onClick}>
            <Text fontSize={14} color={"lightBlue"} value={AppStrings.Common.View} />
          </Link>
        </View>
      )}
    </View>
  );
};

export default AccountBalanceLoader;
