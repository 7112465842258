export default {
  screen: {
    searchPaneltitle: "Manage your units",
    searchPlaceHolder: "Search units",
    addNewUnit: "New Unit",
    occupancyStatus: "Occupancy Status",
    unitStatus: "Unit Status",
    emptydataViewInstructionsText: "No Units Found",
    deleteConfirmationText: "Are you sure you want to delete this unit?",
    deleteUnitLoadingText: "Deleting unit",
    deleteUnitSuccessText: "Successfully deleted",
    activeUnits: "Active Units",
    makeInactiveFileConfirmationText: "Are you sure you want to make the unit inactive?",
    makeActiveFileConfirmationText: "Are you sure you want to activate the unit?"
  },
  filterValues: {
    listed: "Listed",
    notListed: "Not Listed",
    occupied: "Occupied",
    available: "Available",
    inEviction: "In Eviction",
    inContract: "In Contract",
    vacant: "Vacant"
  },
  listItem: {
    beds: "Beds",
    bed: "Bed",
    bath: "Bath",
    baths: "Baths",
    bedsAndBaths: "Beds / Baths",
    sqft: "sqft"
  },
  listHeader: {
    unit: "Unit",
    features: "Features",
    listing: "Listing",
    status: "Status"
  },
  rentRollUnitStatus: {
    pastDue: "Past Due",
    current: "Current"
  },
  newUnitDialog: {
    title: "Add New Unit",
    editTitle: "Edit Unit",
    subTitle: "Please define your unit's info",
    chooseProperty: "Choose property",
    property: "Property",
    unitName: "Unit Name",
    marketRent: "Market Rent",
    size: "Size",
    beds: "Beds",
    bathRooms: "Baths",
    saveUnit: "Save Unit",
    saveAndAdd: "Save & Add Another",
    loadingText: "Creating unit ...",
    errorText: "Something went wrong. Please try again later or contact us.",
    successText: "Unit was successfully created",
    loadingUnitInfo: "Loading unit info",
    infoLoaded: "Unit info was loaded successfully"
  }
};
