"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostTaskUpdateDto = void 0;
const class_validator_1 = require("class-validator");
const base_dto_1 = require("../base.dto");
const isDateStringWithoutTime_validator_1 = require("../common/validators/isDateStringWithoutTime.validator");
const taskDateType_enum_1 = require("./taskDateType.enum");
const taskPriority_enum_1 = require("./taskPriority.enum");
const taskStatus_enum_1 = require("./taskStatus.enum");
class PostTaskUpdateDto extends base_dto_1.BaseDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.PostTaskUpdateDto = PostTaskUpdateDto;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], PostTaskUpdateDto.prototype, "taskId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(10000),
    __metadata("design:type", String)
], PostTaskUpdateDto.prototype, "message", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], PostTaskUpdateDto.prototype, "dueDate", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsDate)(),
    __metadata("design:type", Date)
], PostTaskUpdateDto.prototype, "dueDateTime", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], PostTaskUpdateDto.prototype, "startDate", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsDate)(),
    __metadata("design:type", Date)
], PostTaskUpdateDto.prototype, "startDateTime", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(taskDateType_enum_1.TaskDateType),
    (0, class_validator_1.ValidateIf)((x) => x.dueDate || x.dueDateTime),
    __metadata("design:type", String)
], PostTaskUpdateDto.prototype, "dateType", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(taskPriority_enum_1.TaskPriority),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], PostTaskUpdateDto.prototype, "priority", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(taskStatus_enum_1.TaskStatus),
    __metadata("design:type", String)
], PostTaskUpdateDto.prototype, "status", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], PostTaskUpdateDto.prototype, "assignedToUsers", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], PostTaskUpdateDto.prototype, "notifyTenant", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], PostTaskUpdateDto.prototype, "notifyOwner", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], PostTaskUpdateDto.prototype, "notifyAssignees", void 0);
