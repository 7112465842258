import { DataCy, LeaseTenantStatus, ReportId, ReportPermission } from "@doorloop/dto";

import AppStrings from "../../../../../locale/keys";
import { ownerPortalNavigation } from "utils/ownerPortalNavigation";
import type { ReportScreenItemType } from "DLUI/report/screen/reportScreenItemType";

export const TENANTS_REPORT_ITEMS: ReportScreenItemType[] = [
  {
    description: AppStrings.Reports.ReportsScreen.CurrentTenants,
    onPress: () =>
      ownerPortalNavigation.runTenantsReport({
        filter_status: LeaseTenantStatus.CURRENT
      }),
    permission: ReportPermission.tenants,
    id: ReportId.CURRENT_TENANTS,
    dataCy: DataCy.reports.screenItems.tenants.currentTenants
  },
  {
    description: AppStrings.Reports.ReportsScreen.FutureTenants,
    onPress: () =>
      ownerPortalNavigation.runTenantsReport({
        filter_status: LeaseTenantStatus.FUTURE
      }),
    permission: ReportPermission.tenants,
    id: ReportId.FUTURE_TENANTS,
    dataCy: DataCy.reports.screenItems.tenants.futureTenants
  },
  {
    description: AppStrings.Reports.ReportsScreen.PreviousTenants,
    onPress: () =>
      ownerPortalNavigation.runTenantsReport({
        filter_status: LeaseTenantStatus.PAST
      }),
    permission: ReportPermission.tenants,
    id: ReportId.PREVIOUS_TENANTS,
    dataCy: DataCy.reports.screenItems.tenants.previousTenants
  },
  {
    description: AppStrings.Reports.ReportsScreen.TenantVehicles,
    onPress: () => ownerPortalNavigation.runTenantVehiclesReport(),
    permission: ReportPermission.tenants,
    id: ReportId.TENANT_VEHICLES,
    dataCy: DataCy.reports.screenItems.tenants.tenantVehiclesReport
  },
  {
    description: AppStrings.Reports.ReportsScreen.TenantPets,
    onPress: () => ownerPortalNavigation.runTenantsPetsReport(),
    permission: ReportPermission.tenants,
    id: ReportId.TENANT_PETS,
    dataCy: DataCy.reports.screenItems.tenants.tenantPetsReport
  }
];
