import React, { useEffect, useState } from "react";
import type { FieldProps } from "formik";
import { getIn } from "formik";
import { ControlledSwitch } from "DLUI/form/switchButton/base/controlledSwitch";
import type { ToggleInputSize } from "DLUI/form/types/toggleInputBaseTypes";
import type { TextColor } from "DLUI/text/text";

export interface FormikSwitchProps extends FieldProps<any> {
  label?: string;
  labelColor?: TextColor;
  onChange?: (nextSwitchState: boolean) => void;
  marginTop?: number;
  marginRight?: number;
  labelNoWrap?: boolean;
  flipValue?: boolean;
  dataCy?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  size?: ToggleInputSize;
  id?: string;
}

const FormikSwitch: React.FC<FormikSwitchProps> = ({
  label,
  labelColor,
  field,
  form,
  fullWidth,
  onChange,
  marginTop,
  dataCy,
  marginRight,
  size,
  flipValue = false,
  disabled = false,
  id
}) => {
  const [switchState, setSwitchState] = useState<boolean>(false);

  useEffect(() => {
    const fieldValue = getIn(form.values, field.name);
    setSwitchState(flipValue ? !fieldValue : fieldValue);
  }, [form.values]);

  const didPressSwitchButton = () => {
    const nextSwitchState = !switchState;
    onChange?.(nextSwitchState);
    form.setFieldValue(field.name, flipValue ? !nextSwitchState : nextSwitchState);
    setSwitchState(nextSwitchState);
  };

  return (
    <ControlledSwitch
      fullWidth={fullWidth}
      size={size}
      disabled={disabled}
      checked={switchState}
      onChange={didPressSwitchButton}
      dataCy={dataCy}
      labelValueProps={{
        text: label,
        placement: "end",
        clickable: true,
        color: labelColor
      }}
      style={{
        marginTop,
        marginRight
      }}
      id={id}
    />
  );
};

export default FormikSwitch;
