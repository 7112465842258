import { asyncUtils } from "@doorloop/utils";
import { useSetAtom } from "jotai";
import { useCallback, useState } from "react";
import { isPrintingAtom } from "utils/atoms";
import { LoadingStatus } from "common/native/types";
import { handleToast } from "store/toast/actions";
import AppStrings from "locale/keys";
import type { ApiResult } from "@doorloop/dto";
import { useDispatch } from "react-redux";
import fileDownload from "js-file-download";

export function usePrint() {
  const setIsPrinting = useSetAtom(isPrintingAtom);

  const onPrint = useCallback(async () => {
    setIsPrinting(true);
    await asyncUtils.sleep(2000);
    window.print();
    setIsPrinting(false);
  }, [setIsPrinting]);

  const [printLoadingStatus, setPrintLoadingStatus] = useState<LoadingStatus>(LoadingStatus.initial);
  const dispatch = useDispatch();

  const handleError = () => {
    setPrintLoadingStatus(LoadingStatus.error);

    dispatch(
      handleToast({
        severity: "error",
        translationKey: AppStrings.Common.DownloadFailed
      })
    );
  };

  const printDocumentFromServer = async (apiFunction: () => Promise<ApiResult<File>>) => {
    setPrintLoadingStatus(LoadingStatus.loading);

    try {
      const response = await apiFunction();

      if (!(response.status && response.data)) {
        handleError();
        return;
      }

      fileDownload(response.data, response.data.name);

      setPrintLoadingStatus(LoadingStatus.loaded);

      dispatch(
        handleToast({
          severity: "success",
          translationKey: AppStrings.Common.DownloadSucceeded
        })
      );
    } catch (error) {
      handleError();
    }
  };

  return {
    onPrint,
    printLoadingStatus,
    printDocumentFromServer
  };
}
