import { PropertyAmenity } from "@doorloop/dto";
import { UnitAmenity } from "@doorloop/dto";
import AppStrings from "locale/keys";

type AnyAmenity = PropertyAmenity | UnitAmenity;

const AmenityToLabelMap: Record<AnyAmenity, string> = {
  [PropertyAmenity.Other]: AppStrings.Listing.Other,
  [PropertyAmenity.Availability24Hours]: AppStrings.Listing.Availability24Hours,
  [PropertyAmenity.AccentWalls]: AppStrings.Listing.AccentWalls,
  [PropertyAmenity.BasketballCourt]: AppStrings.Listing.BasketballCourt,
  [PropertyAmenity.Bilingual]: AppStrings.Listing.Bilingual,
  [PropertyAmenity.BoatDocks]: AppStrings.Listing.BoatDocks,
  [PropertyAmenity.BusinessCenter]: AppStrings.Listing.BusinessCenter,
  [PropertyAmenity.CarWashArea]: AppStrings.Listing.CarWashArea,
  [PropertyAmenity.ChildCare]: AppStrings.Listing.ChildCare,
  [PropertyAmenity.ClubDiscount]: AppStrings.Listing.ClubDiscount,
  [PropertyAmenity.ClubHouse]: AppStrings.Listing.ClubHouse,
  [PropertyAmenity.ConferenceRoom]: AppStrings.Listing.ConferenceRoom,
  [PropertyAmenity.Concierge]: AppStrings.Listing.Concierge,
  [PropertyAmenity.CoverPark]: AppStrings.Listing.CoverPark,
  [PropertyAmenity.DoorAttendant]: AppStrings.Listing.DoorAttendant,
  [PropertyAmenity.FitnessCenter]: AppStrings.Listing.FitnessCenter,
  [PropertyAmenity.Elevator]: AppStrings.Listing.Elevator,
  [PropertyAmenity.FreeWeights]: AppStrings.Listing.FreeWeights,
  [PropertyAmenity.FurnishedAvailable]: AppStrings.Listing.FurnishedAvailable,
  [PropertyAmenity.GamingStations]: AppStrings.Listing.GamingStations,
  [PropertyAmenity.Garage_Attached]: AppStrings.Listing.Garage_Attached,
  [PropertyAmenity.Garage_Detached]: AppStrings.Listing.Garage_Detached,
  [PropertyAmenity.Garage_SameLevelParking]: AppStrings.Listing.Garage_SameLevelParking,
  [PropertyAmenity.Gate]: AppStrings.Listing.Gate,
  [PropertyAmenity.GroceryService]: AppStrings.Listing.GroceryService,
  [PropertyAmenity.GroupExercise]: AppStrings.Listing.GroupExercise,
  [PropertyAmenity.GuestRoom]: AppStrings.Listing.GuestRoom,
  [PropertyAmenity.HighSpeed]: AppStrings.Listing.HighSpeed,
  [PropertyAmenity.Housekeeping]: AppStrings.Listing.Housekeeping,
  [PropertyAmenity.HouseSitting]: AppStrings.Listing.HouseSitting,
  [PropertyAmenity.JoggingWalkingTrails]: AppStrings.Listing.JoggingWalkingTrails,
  [PropertyAmenity.LakeFront]: AppStrings.Listing.LakeFront,
  [PropertyAmenity.LakeAccess]: AppStrings.Listing.LakeAccess,
  [PropertyAmenity.Laundry]: AppStrings.Listing.Laundry,
  [PropertyAmenity.Library]: AppStrings.Listing.Library,
  [PropertyAmenity.MealService]: AppStrings.Listing.MealService,
  [PropertyAmenity.MediaRoom]: AppStrings.Listing.MediaRoom,
  [PropertyAmenity.MultiUseRoom]: AppStrings.Listing.MultiUseRoom,
  [PropertyAmenity.NightPatrol]: AppStrings.Listing.NightPatrol,
  [PropertyAmenity.OnSiteMaintenance]: AppStrings.Listing.OnSiteMaintenance,
  [PropertyAmenity.OnSiteManagement]: AppStrings.Listing.OnSiteManagement,
  [PropertyAmenity.PackageReceiving]: AppStrings.Listing.PackageReceiving,
  [PropertyAmenity.PerDiemAccepted]: AppStrings.Listing.PerDiemAccepted,
  [PropertyAmenity.Pool]: AppStrings.Listing.Pool,
  [PropertyAmenity.PlayGround]: AppStrings.Listing.PlayGround,
  [PropertyAmenity.Racquetball]: AppStrings.Listing.Racquetball,
  [PropertyAmenity.RecRoom]: AppStrings.Listing.RecRoom,
  [PropertyAmenity.Recycling]: AppStrings.Listing.Recycling,
  [PropertyAmenity.Sauna]: AppStrings.Listing.Sauna,
  [PropertyAmenity.ShortTermLease]: AppStrings.Listing.ShortTermLease,
  [PropertyAmenity.SmokeFree]: AppStrings.Listing.SmokeFree,
  [PropertyAmenity.Spa]: AppStrings.Listing.Spa,
  [PropertyAmenity.StorageSpace]: AppStrings.Listing.StorageSpace,
  [PropertyAmenity.Sundeck]: AppStrings.Listing.Sundeck,
  [PropertyAmenity.TennisCourt]: AppStrings.Listing.TennisCourt,
  [PropertyAmenity.Transportation]: AppStrings.Listing.Transportation,
  [PropertyAmenity.TVLounge]: AppStrings.Listing.TVLounge,
  [PropertyAmenity.ValetTrash]: AppStrings.Listing.ValetTrash,
  [PropertyAmenity.Vintage]: AppStrings.Listing.Vintage,
  [PropertyAmenity.VolleyballCourt]: AppStrings.Listing.VolleyballCourt,
  [PropertyAmenity.WirelessInternet]: AppStrings.Listing.WirelessInternet,
  [UnitAmenity.AdditionalStorage]: AppStrings.Listing.AdditionalStorage,
  [UnitAmenity.AirConditioner]: AppStrings.Listing.AirConditioner,
  [UnitAmenity.Alarm]: AppStrings.Listing.Alarm,
  [UnitAmenity.Balcony]: AppStrings.Listing.Balcony,
  [UnitAmenity.Cable]: AppStrings.Listing.Cable,
  [UnitAmenity.Carpet]: AppStrings.Listing.Carpet,
  [UnitAmenity.Carport]: AppStrings.Listing.Carport,
  [UnitAmenity.CeilingFan]: AppStrings.Listing.CeilingFan,
  [UnitAmenity.ControlledAccess]: AppStrings.Listing.ControlledAccess,
  [UnitAmenity.Courtyard]: AppStrings.Listing.Courtyard,
  [UnitAmenity.Dishwasher]: AppStrings.Listing.Dishwasher,
  [UnitAmenity.Disposal]: AppStrings.Listing.Disposal,
  [UnitAmenity.DoubleSinkVanity]: AppStrings.Listing.DoubleSinkVanity,
  [UnitAmenity.Dryer]: AppStrings.Listing.Dryer,
  [UnitAmenity.Fireplace]: AppStrings.Listing.Fireplace,
  [UnitAmenity.FramedMirrors]: AppStrings.Listing.FramedMirrors,
  [UnitAmenity.Furnished]: AppStrings.Listing.Furnished,
  [UnitAmenity.Garage]: AppStrings.Listing.Garage,
  [UnitAmenity.Handrails]: AppStrings.Listing.Handrails,
  [UnitAmenity.HardwoodFlooring]: AppStrings.Listing.HardwoodFlooring,
  [UnitAmenity.HardSurfaceCounterTops]: AppStrings.Listing.HardSurfaceCounterTops,
  [UnitAmenity.Heat]: AppStrings.Listing.Heat,
  [UnitAmenity.IndividualClimateControl]: AppStrings.Listing.IndividualClimateControl,
  [UnitAmenity.IslandKitchen]: AppStrings.Listing.IslandKitchen,
  [UnitAmenity.LaminateCounterTops]: AppStrings.Listing.LaminateCounterTops,
  [UnitAmenity.VinylFlooring]: AppStrings.Listing.VinylFlooring,
  [UnitAmenity.LargeClosets]: AppStrings.Listing.LargeClosets,
  [UnitAmenity.LinenCloset]: AppStrings.Listing.LinenCloset,
  [UnitAmenity.Microwave]: AppStrings.Listing.Microwave,
  [UnitAmenity.Pantry]: AppStrings.Listing.Pantry,
  [UnitAmenity.Patio]: AppStrings.Listing.Patio,
  [UnitAmenity.PrivateBalcony]: AppStrings.Listing.PrivateBalcony,
  [UnitAmenity.PrivatePatio]: AppStrings.Listing.PrivatePatio,
  [UnitAmenity.Range]: AppStrings.Listing.Range,
  [UnitAmenity.Refrigerator]: AppStrings.Listing.Refrigerator,
  [UnitAmenity.Satellite]: AppStrings.Listing.Satellite,
  [UnitAmenity.Skylight]: AppStrings.Listing.Skylight,
  [UnitAmenity.TileFlooring]: AppStrings.Listing.TileFlooring,
  [UnitAmenity.VaultedCeiling]: AppStrings.Listing.VaultedCeiling,
  [UnitAmenity.View]: AppStrings.Listing.View,
  [UnitAmenity.Washer]: AppStrings.Listing.Washer,
  [UnitAmenity.WheelChair]: AppStrings.Listing.WheelChair,
  [UnitAmenity.WD_Hookup]: AppStrings.Listing.WD_Hookup,
  [UnitAmenity.WindowCoverings]: AppStrings.Listing.WindowCoverings
};

export const getAmenityLabel = (amenity: AnyAmenity): string => AmenityToLabelMap[amenity];
