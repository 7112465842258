import type { FC } from "react";
import React, { useMemo } from "react";
import AppStrings from "locale/keys";
import { CheckbookPaymentMethodEnum } from "@doorloop/dto";
import { EmailInfo } from "screens/outgoingPayments/infoBoxes/emailInfo";
import { EmailSection } from "screens/outgoingPayments/subForms/emailSection";
import type { ImageButtonProps } from "DLUI/form";
import { EmailAtSignIcon, MailIcon } from "assets/icons";
import { PhysicalCheckInfo } from "screens/outgoingPayments/infoBoxes/physicalCheckInfo";
import type { PersonOutgoingEPayInfoDto } from "@doorloop/dto";
import { PhysicalCheckSection } from "screens/outgoingPayments/subForms/physicalCheckSection";
import { DirectDepositInfo } from "screens/outgoingPayments/infoBoxes/directDepositInfo";
import { DirectDepositSection } from "screens/outgoingPayments/subForms/directDepositSection";
import { SettingPermission } from "@doorloop/dto";

export type SupportedPersonTypes = "owner" | "vendor" | "tenant";

export const labelToPersonType: Record<SupportedPersonTypes, string> = {
  tenant: AppStrings.Common.OutgoingPayments.EnableOutgoingPaymentsForThisTenant,
  owner: AppStrings.Common.OutgoingPayments.EnableOutgoingPaymentsForThisOwner,
  vendor: AppStrings.Common.OutgoingPayments.EnableOutgoingPaymentsForThisVendor
};

export const readOnlyPermissionForPerson: Record<SupportedPersonTypes, SettingPermission> = {
  tenant: SettingPermission.tenantsEPayInfo,
  owner: SettingPermission.ownerEPayInfo,
  vendor: SettingPermission.vendorsEPayInfo
};

interface MethodSettings {
  InfoBox: FC;
  FormSection: FC;
}

export const getSettingsByMethod = (
  method?: CheckbookPaymentMethodEnum,
  personType?: SupportedPersonTypes
): MethodSettings =>
  useMemo(() => {
    const methodToInfoBoxMap: Record<CheckbookPaymentMethodEnum, MethodSettings> = {
      [CheckbookPaymentMethodEnum.DIGITAL_PAYMENT]: {
        InfoBox: () => <EmailInfo personType={personType} />,
        FormSection: EmailSection
      },
      [CheckbookPaymentMethodEnum.PHYSICAL_CHECK]: {
        InfoBox: PhysicalCheckInfo,
        FormSection: PhysicalCheckSection
      },
      [CheckbookPaymentMethodEnum.DIRECT_DEPOSIT]: {
        InfoBox: DirectDepositInfo,
        FormSection: DirectDepositSection
      }
    };
    return methodToInfoBoxMap[method || CheckbookPaymentMethodEnum.DIGITAL_PAYMENT];
  }, [method, personType]);

export const sendingMethodsItems: ImageButtonProps[] = [
  {
    label: AppStrings.Common.OutgoingPayments.EmailRecommended,
    icon: EmailAtSignIcon,
    onPress: () => {},
    isSelected: true,
    value: CheckbookPaymentMethodEnum.DIGITAL_PAYMENT,
    itemIndex: 0
  },
  {
    label: AppStrings.Common.OutgoingPayments.MailCheck,
    icon: MailIcon,
    onPress: () => {},
    isSelected: false,
    value: CheckbookPaymentMethodEnum.PHYSICAL_CHECK,
    itemIndex: 1
  }
  // {
  //   label: AppStrings.Common.OutgoingPayments.DirectDeposit,
  //   icon: PaymentIcon,
  //   onPress: () => {},
  //   isSelected: false,
  //   value: CheckbookPaymentMethodEnum.DIRECT_DEPOSIT,
  //   itemIndex: 1
  // }
];
export const ePayField = (fieldName: keyof PersonOutgoingEPayInfoDto): string => `outgoingEPay.${fieldName}`;
