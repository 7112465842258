"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaritalStatus = void 0;
var MaritalStatus;
(function (MaritalStatus) {
    MaritalStatus["SINGLE_NEVER_MARRIED"] = "SINGLE_NEVER_MARRIED";
    MaritalStatus["MARRIED_OR_DOMESTIC_PARTNERSHIP"] = "MARRIED_OR_DOMESTIC_PARTNERSHIP";
    MaritalStatus["DIVORCED"] = "DIVORCED";
    MaritalStatus["WIDOWED"] = "WIDOWED";
    MaritalStatus["SEPARATED"] = "SEPARATED";
})(MaritalStatus || (exports.MaritalStatus = MaritalStatus = {}));
