import type { NarrowedPersonDto, PersonDto, PersonDtoType } from "@doorloop/dto";
import { PersonTypeEnum, personUtils, PhoneType } from "@doorloop/dto";
import { createOptionLabelGetter } from "../autoCompleteTyped/autoComplete.utils";
import { SkeletonAvatar } from "../autoCompleteTyped/autoCompleteAvatar";
import { personTypeToApiInstanceMap, personTypeToDtoConstructorMap } from "./peopleAutoComplete.constants";
import type { AutocompleteRenderOptionState } from "@material-ui/lab";
import type { ApiToastsProps } from "../../../../../api/apiHelper";
import type { ApiResult } from "../../../../../api/apiResult";
import type { AutoCompleteOption, AutoCompleteOptionRenderer } from "../autoCompleteTyped/autoComplete.types";

export const personTypeToTranslationKeysMap = {
  [PersonTypeEnum.OWNER]: {
    title: "owners.metadata.title",
    titleSingular: "owners.metadata.titleSingular",
    creatableTitle: "owners.creatable.title"
  },
  [PersonTypeEnum.TENANT]: {
    title: "tenants.metadata.title",
    titleSingular: "tenants.metadata.titleSingular",
    creatableTitle: "tenants.creatable.title"
  },
  [PersonTypeEnum.VENDOR]: {
    title: "vendors.metadata.title",
    titleSingular: "vendors.metadata.titleSingular",
    creatableTitle: "vendors.creatable.title"
  }
} as const;

export const getPersonOptionLabel = createOptionLabelGetter<NarrowedPersonDto>(
  (option: AutoCompleteOption<NarrowedPersonDto>) => {
    const fullName = personUtils.getFullName(option.data?.firstName, option.data?.middleName, option.data?.lastName);
    return !fullName ? option.data?.companyName || "" : fullName;
  }
);

export function getPersonAvatarSrc(optionData: NarrowedPersonDto) {
  return optionData.pictureUrl;
}

export function groupByPersonType(option: AutoCompleteOption<NarrowedPersonDto>, { t }) {
  if (!option.data?.type) {
    console.error("Person type is missing, unable to group by `type`.");
    return "Unknown Type";
  }
  if (option.data?.groupName) {
    return option.data.groupName;
  }
  return t(personTypeToTranslationKeysMap[option.data.type].title);
}

export const renderPersonOption: AutoCompleteOptionRenderer<NarrowedPersonDto> = (
  option,
  _state: AutocompleteRenderOptionState,
  { t }
) => {
  const label =
    // Fallback to company name when full-name is missing
    (option.label === option.data?.companyName ? [option.label] : [option.label, option.data?.companyName])
      .filter(Boolean)
      .join(" | ");

  // Keep it lightweight to avoid rendering issues (use of plain HTML tags is intended)
  return (
    <div data-option-id={option.data?.id} style={{ display: "flex", gap: "16px", alignItems: "center" }}>
      <SkeletonAvatar label={label} src={option.data?.pictureUrl} forceInitials />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>{label}</div>
        <span style={{ color: "rgba(126, 137, 154, 1)", fontSize: 14 }}>
          {[
            option.data?.phone?.number || t("common.autoComplete.option.phonePlaceholder"),
            option.data?.email?.address || t("common.autoComplete.option.emailPlaceholder")
          ]
            .filter(Boolean)
            .join(" | ")}
        </span>
      </div>
    </div>
  );
};

// Even though we provide the full DTO object through the `original` prop, it was
// originally planned to have server API project (as in query projection) only
// the necessary fields per use-case.
export function narrowPersonDto(
  personDto: PersonDtoType,
  type: PersonTypeEnum,
  orderIndex?: number,
  groupName?: string
): NarrowedPersonDto {
  const PersonConstructor = personTypeToDtoConstructorMap[type];

  const personInstance = new PersonConstructor(personDto);
  const primaryEmail = personUtils.getEmailDto(personInstance);
  const primaryPhone = personDto.phones?.find((phone) => phone.type === PhoneType.MOBILE);

  return {
    type,
    orderIndex,
    groupName,
    original: personInstance,
    id: personDto.id as NonNullable<typeof personDto.id>,
    pictureUrl: personDto.pictureUrl,
    companyName: personDto.companyName,
    firstName: personDto.firstName,
    lastName: personDto.lastName,
    middleName: personDto.middleName,
    fullName: personDto.fullName,
    outgoingEPay: personDto.outgoingEPay,
    ...(primaryEmail && { email: primaryEmail }),
    ...(primaryPhone && { phone: primaryPhone })
  };
}

// We can't use the standard `create` method because its return is constrained
// to the `NarrowPersonDto` and its signature is also too constrained.
export async function createPerson(
  personType: PersonTypeEnum,
  personDto: PersonDtoType,
  options?: Partial<{ toasts: ApiToastsProps<PersonDto> }>
): Promise<ApiResult<PersonDtoType>> {
  const personApi = personTypeToApiInstanceMap[personType];
  // eslint-disable-next-line @typescript-eslint/return-await
  return personApi.create(personDto, options?.toasts);
}
