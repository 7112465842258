"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileFilterTypes = void 0;
var FileFilterTypes;
(function (FileFilterTypes) {
    FileFilterTypes["GENERAL"] = "GENERAL";
    FileFilterTypes["PICTURE"] = "PICTURE";
    FileFilterTypes["DOCUMENT"] = "DOCUMENT";
    FileFilterTypes["VIDEOS"] = "VIDEOS";
})(FileFilterTypes || (exports.FileFilterTypes = FileFilterTypes = {}));
