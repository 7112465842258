import type { Method as AxiosMethod } from "axios";

import { RecurringTransactionType, ServerRoutes, TagType, TaskType, TenantPortalServerRoutes } from "@doorloop/dto";

export type MethodRecord<Prefix extends string, IsTranslationKey extends boolean, Value> = {
  [Method in AxiosMethod]?: IsTranslationKey extends true ? `toasts.${Prefix}.${Method}` : Value;
};

export interface ToastsTranslation<IsTranslationKey extends boolean, Value = string> {
  api: { [Route in ServerRoutes | TenantPortalServerRoutes]?: MethodRecord<`api.${Route}`, IsTranslationKey, Value> };
  custom: {
    copy: {
      text: IsTranslationKey extends true ? `toasts.custom.copy.text` : Value;
      link: IsTranslationKey extends true ? `toasts.custom.copy.link` : Value;
      apiKey: IsTranslationKey extends true ? `toasts.custom.copy.apiKey` : Value;
    };
    upload: { file: MethodRecord<"custom.upload.file", IsTranslationKey, Value> };
    credit: { withhold: MethodRecord<"custom.credit.withhold", IsTranslationKey, Value> };
    tasks: { [Task in TaskType]?: MethodRecord<`custom.tasks.${Task}`, IsTranslationKey, Value> } & {
      complete: MethodRecord<`custom.tasks.complete`, IsTranslationKey, Value>;
    };
    lease: {
      activate: MethodRecord<`custom.lease.activate`, IsTranslationKey, Value>;
      cancel: MethodRecord<`custom.lease.cancel`, IsTranslationKey, Value>;
    };
    accounts: { cancel: MethodRecord<`custom.accounts.cancel`, IsTranslationKey, Value> };
    signatureRequest: { cancel: MethodRecord<`custom.signatureRequest.cancel`, IsTranslationKey, Value> };
    properties: {
      activate: MethodRecord<`custom.properties.activate`, IsTranslationKey, Value>;
      deactivate: MethodRecord<`custom.properties.deactivate`, IsTranslationKey, Value>;
    };
    units: {
      activate: MethodRecord<`custom.units.activate`, IsTranslationKey, Value>;
      deactivate: MethodRecord<`custom.units.deactivate`, IsTranslationKey, Value>;
    };
    reports: {
      addToFavorite: MethodRecord<`custom.reports.addToFavorite`, IsTranslationKey, Value>;
      removeFromFavorite: MethodRecord<`custom.reports.removeFromFavorite`, IsTranslationKey, Value>;
      downloadReport: MethodRecord<`custom.reports.downloadReport`, IsTranslationKey, Value>;
    };
    leasePayments: {
      receiptSent: MethodRecord<`custom.leasePayments.receiptSent`, IsTranslationKey, Value>;
    };
    tags: { [Key in TagType]?: MethodRecord<`custom.tags.${Key}`, IsTranslationKey, Value> };
    prospects: MethodRecord<"custom.prospects", IsTranslationKey, Value>;
    recurring: {
      [Type in RecurringTransactionType]?: MethodRecord<`custom.recurring.${Type}`, IsTranslationKey, Value>;
    } & {
      [RecurringTransactionType.TASK]?: {
        [Task in TaskType]?: MethodRecord<
          `custom.recurring.${RecurringTransactionType.TASK}.${Task}`,
          IsTranslationKey,
          Value
        >;
      };
    };
  };
}

const Toasts: ToastsTranslation<true> = {
  custom: {
    copy: {
      text: "toasts.custom.copy.text",
      link: "toasts.custom.copy.link",
      apiKey: "toasts.custom.copy.apiKey"
    },
    upload: { file: { POST: "toasts.custom.upload.file.POST" } },
    credit: { withhold: { POST: "toasts.custom.credit.withhold.POST" } },
    tasks: {
      [TaskType.WORK_ORDER]: { POST: `toasts.custom.tasks.${TaskType.WORK_ORDER}.POST` },
      [TaskType.TENANT_REQUEST]: { POST: `toasts.custom.tasks.${TaskType.TENANT_REQUEST}.POST` },
      [TaskType.OWNER_REQUEST]: { POST: `toasts.custom.tasks.${TaskType.OWNER_REQUEST}.POST` },
      complete: { POST: "toasts.custom.tasks.complete.POST" }
    },
    lease: {
      activate: { POST: "toasts.custom.lease.activate.POST" },
      cancel: { POST: "toasts.custom.lease.cancel.POST" }
    },
    accounts: { cancel: { POST: "toasts.custom.accounts.cancel.POST" } },
    signatureRequest: { cancel: { POST: "toasts.custom.signatureRequest.cancel.POST" } },
    properties: {
      activate: { PUT: "toasts.custom.properties.activate.PUT" },
      deactivate: { PUT: "toasts.custom.properties.deactivate.PUT" }
    },
    units: {
      activate: { PUT: "toasts.custom.units.activate.PUT" },
      deactivate: { PUT: "toasts.custom.units.deactivate.PUT" }
    },
    reports: {
      addToFavorite: { POST: "toasts.custom.reports.addToFavorite.POST" },
      removeFromFavorite: { POST: "toasts.custom.reports.removeFromFavorite.POST" },
      downloadReport: { POST: "toasts.custom.reports.downloadReport.POST" }
    },
    leasePayments: {
      receiptSent: { POST: "toasts.custom.leasePayments.receiptSent.POST" }
    },
    tags: {
      [TagType.NOTES_TAG]: {
        POST: `toasts.custom.tags.${TagType.NOTES_TAG}.POST`,
        PUT: `toasts.custom.tags.${TagType.NOTES_TAG}.PUT`,
        DELETE: `toasts.custom.tags.${TagType.NOTES_TAG}.DELETE`
      },
      [TagType.LEAD_SOURCE]: {
        POST: `toasts.custom.tags.${TagType.LEAD_SOURCE}.POST`,
        PUT: `toasts.custom.tags.${TagType.LEAD_SOURCE}.PUT`,
        DELETE: `toasts.custom.tags.${TagType.LEAD_SOURCE}.DELETE`
      },
      [TagType.FILES_TAG]: {
        POST: `toasts.custom.tags.${TagType.FILES_TAG}.POST`,
        PUT: `toasts.custom.tags.${TagType.FILES_TAG}.PUT`,
        DELETE: `toasts.custom.tags.${TagType.FILES_TAG}.DELETE`
      },
      [TagType.VENDOR_SERVICE]: {
        POST: `toasts.custom.tags.${TagType.VENDOR_SERVICE}.POST`,
        PUT: `toasts.custom.tags.${TagType.VENDOR_SERVICE}.PUT`,
        DELETE: `toasts.custom.tags.${TagType.VENDOR_SERVICE}.DELETE`
      }
    },
    prospects: {
      PUT: "toasts.custom.prospects.PUT",
      DELETE: "toasts.custom.prospects.DELETE"
    },
    recurring: {
      [RecurringTransactionType.GENERAL_ENTRY]: {
        POST: `toasts.custom.recurring.${RecurringTransactionType.GENERAL_ENTRY}.POST`,
        PUT: `toasts.custom.recurring.${RecurringTransactionType.GENERAL_ENTRY}.PUT`,
        DELETE: `toasts.custom.recurring.${RecurringTransactionType.GENERAL_ENTRY}.DELETE`
      },
      [RecurringTransactionType.LEASE_CHARGE]: {
        POST: `toasts.custom.recurring.${RecurringTransactionType.LEASE_CHARGE}.POST`,
        PUT: `toasts.custom.recurring.${RecurringTransactionType.LEASE_CHARGE}.PUT`,
        DELETE: `toasts.custom.recurring.${RecurringTransactionType.LEASE_CHARGE}.DELETE`
      },
      [RecurringTransactionType.LEASE_RENT]: {
        POST: `toasts.custom.recurring.${RecurringTransactionType.LEASE_RENT}.POST`,
        PUT: `toasts.custom.recurring.${RecurringTransactionType.LEASE_RENT}.PUT`,
        DELETE: `toasts.custom.recurring.${RecurringTransactionType.LEASE_RENT}.DELETE`
      },
      [RecurringTransactionType.LEASE_PAYMENT]: {
        POST: `toasts.custom.recurring.${RecurringTransactionType.LEASE_PAYMENT}.POST`,
        PUT: `toasts.custom.recurring.${RecurringTransactionType.LEASE_PAYMENT}.PUT`,
        DELETE: `toasts.custom.recurring.${RecurringTransactionType.LEASE_PAYMENT}.DELETE`
      },
      [RecurringTransactionType.LEASE_CREDIT]: {
        POST: `toasts.custom.recurring.${RecurringTransactionType.LEASE_CREDIT}.POST`,
        PUT: `toasts.custom.recurring.${RecurringTransactionType.LEASE_CREDIT}.PUT`,
        DELETE: `toasts.custom.recurring.${RecurringTransactionType.LEASE_CREDIT}.DELETE`
      },
      [RecurringTransactionType.VENDOR_BILL]: {
        POST: `toasts.custom.recurring.${RecurringTransactionType.VENDOR_BILL}.POST`,
        PUT: `toasts.custom.recurring.${RecurringTransactionType.VENDOR_BILL}.PUT`,
        DELETE: `toasts.custom.recurring.${RecurringTransactionType.VENDOR_BILL}.DELETE`
      },
      [RecurringTransactionType.EXPENSE]: {
        POST: `toasts.custom.recurring.${RecurringTransactionType.EXPENSE}.POST`,
        PUT: `toasts.custom.recurring.${RecurringTransactionType.EXPENSE}.PUT`,
        DELETE: `toasts.custom.recurring.${RecurringTransactionType.EXPENSE}.DELETE`
      },
      [RecurringTransactionType.TASK]: {
        [TaskType.INTERNAL_TASK]: {
          POST: `toasts.custom.recurring.${RecurringTransactionType.TASK}.${TaskType.INTERNAL_TASK}.POST`,
          PUT: `toasts.custom.recurring.${RecurringTransactionType.TASK}.${TaskType.INTERNAL_TASK}.PUT`,
          DELETE: `toasts.custom.recurring.${RecurringTransactionType.TASK}.${TaskType.INTERNAL_TASK}.DELETE`
        },
        [TaskType.WORK_ORDER]: {
          POST: `toasts.custom.recurring.${RecurringTransactionType.TASK}.${TaskType.WORK_ORDER}.POST`,
          PUT: `toasts.custom.recurring.${RecurringTransactionType.TASK}.${TaskType.WORK_ORDER}.PUT`,
          DELETE: `toasts.custom.recurring.${RecurringTransactionType.TASK}.${TaskType.WORK_ORDER}.DELETE`
        }
      }
    }
  },
  api: {
    [ServerRoutes.BUDGETS]: {
      POST: `toasts.api.${ServerRoutes.BUDGETS}.POST`,
      PUT: `toasts.api.${ServerRoutes.BUDGETS}.PUT`,
      DELETE: `toasts.api.${ServerRoutes.BUDGETS}.DELETE`
    },
    [TenantPortalServerRoutes.INSURANCE_POLICIES]: {
      POST: `toasts.api.${TenantPortalServerRoutes.INSURANCE_POLICIES}.POST`,
      PUT: `toasts.api.${TenantPortalServerRoutes.INSURANCE_POLICIES}.PUT`,
      DELETE: `toasts.api.${TenantPortalServerRoutes.INSURANCE_POLICIES}.DELETE`
    },
    [TenantPortalServerRoutes.TENANT_REQUESTS]: {
      POST: `toasts.api.${TenantPortalServerRoutes.TENANT_REQUESTS}.POST`,
      DELETE: `toasts.api.${TenantPortalServerRoutes.TENANT_REQUESTS}.DELETE`
    },
    [TenantPortalServerRoutes.TENANT_REQUESTS_POST_UPDATE]: {
      POST: `toasts.api.${TenantPortalServerRoutes.TENANT_REQUESTS_POST_UPDATE}.POST`
    },
    [TenantPortalServerRoutes.PAYMENTS]: {
      POST: `toasts.api.${TenantPortalServerRoutes.PAYMENTS}.POST`
    },
    [TenantPortalServerRoutes.RECURRING_PAYMENTS]: {
      POST: `toasts.api.${TenantPortalServerRoutes.RECURRING_PAYMENTS}.POST`
    },
    [ServerRoutes.REPORTS_OWNER_STATEMENT_PRINTABLE_PDF]: {
      GET: `toasts.api.${ServerRoutes.REPORTS_OWNER_STATEMENT_PRINTABLE_PDF}.GET`
    },
    [ServerRoutes.NOTES]: {
      POST: `toasts.api.${ServerRoutes.NOTES}.POST`,
      PUT: `toasts.api.${ServerRoutes.NOTES}.PUT`,
      DELETE: `toasts.api.${ServerRoutes.NOTES}.DELETE`
    },
    [ServerRoutes.BANK_CONNECT_PLAID_ACCOUNTS_POST_DISCONNECT]: {
      POST: `toasts.api.${ServerRoutes.BANK_CONNECT_PLAID_ACCOUNTS_POST_DISCONNECT}.POST`
    },
    [ServerRoutes.RENTAL_APPLICATIONS_PUT_UPDATE_DECISION_STATUS]: {
      PUT: `toasts.api.${ServerRoutes.RENTAL_APPLICATIONS_PUT_UPDATE_DECISION_STATUS}.PUT`
    },
    [ServerRoutes.LEASES_POST_NOT_RENEWING]: { POST: `toasts.api.${ServerRoutes.LEASES_POST_NOT_RENEWING}.POST` },
    [ServerRoutes.TASKS_POST_UPDATE]: { POST: `toasts.api.${ServerRoutes.TASKS_POST_UPDATE}.POST` },
    [ServerRoutes.LEASES_POST_END_LEASE]: { POST: `toasts.api.${ServerRoutes.LEASES_POST_END_LEASE}.POST` },
    [ServerRoutes.LEASES_POST_MOVEIN_TENANT]: { POST: `toasts.api.${ServerRoutes.LEASES_POST_MOVEIN_TENANT}.POST` },
    [ServerRoutes.LEASES_POST_MOVEOUT_TENANT]: { POST: `toasts.api.${ServerRoutes.LEASES_POST_MOVEOUT_TENANT}.POST` },
    [ServerRoutes.ESIGNATURE_REQUESTS]: {
      PUT: `toasts.api.${ServerRoutes.ESIGNATURE_REQUESTS}.PUT`,
      DELETE: `toasts.api.${ServerRoutes.ESIGNATURE_REQUESTS}.DELETE`
    },
    [ServerRoutes.GENERAL_ENTRIES]: { POST: `toasts.api.${ServerRoutes.GENERAL_ENTRIES}.POST` },
    [ServerRoutes.VENDOR_BILL_PAYMENTS_POST_CREATE_MANY]: {
      POST: `toasts.api.${ServerRoutes.VENDOR_BILL_PAYMENTS_POST_CREATE_MANY}.POST`
    },
    [ServerRoutes.VENDOR_CREDITS]: { POST: `toasts.api.${ServerRoutes.VENDOR_CREDITS}.POST` },
    [ServerRoutes.LEASE_CREDITS]: { POST: `toasts.api.${ServerRoutes.LEASE_CREDITS}.POST` },
    [ServerRoutes.ACCOUNTS]: { PUT: `toasts.api.${ServerRoutes.ACCOUNTS}.PUT` },
    [ServerRoutes.TASKS]: {
      POST: `toasts.api.${ServerRoutes.TASKS}.POST`,
      PUT: `toasts.api.${ServerRoutes.TASKS}.PUT`,
      DELETE: `toasts.api.${ServerRoutes.TASKS}.DELETE`
    },
    [ServerRoutes.ANNOUNCEMENTS]: {
      DELETE: `toasts.api.${ServerRoutes.ANNOUNCEMENTS}.DELETE`
    },
    [ServerRoutes.SETTINGS_PERSONAL_INFO]: { PUT: `toasts.api.${ServerRoutes.SETTINGS_PERSONAL_INFO}.PUT` },
    [ServerRoutes.SETTINGS_COMPANY_INFO]: { PUT: `toasts.api.${ServerRoutes.SETTINGS_COMPANY_INFO}.PUT` },
    [ServerRoutes.SETTINGS_LOGIN_AND_PASSWORD]: { PUT: `toasts.api.${ServerRoutes.SETTINGS_LOGIN_AND_PASSWORD}.PUT` },
    [ServerRoutes.SETTINGS_REGION_AND_CURRENCY]: { PUT: `toasts.api.${ServerRoutes.SETTINGS_REGION_AND_CURRENCY}.PUT` },
    [ServerRoutes.SETTINGS_DEFAULT_ACCOUNTS]: { PUT: `toasts.api.${ServerRoutes.SETTINGS_DEFAULT_ACCOUNTS}.PUT` },
    [ServerRoutes.SUBSCRIPTION_POST_UPDATE_SUBSCRIPTION]: {
      POST: `toasts.api.${ServerRoutes.SUBSCRIPTION_POST_UPDATE_SUBSCRIPTION}.POST`,
      PUT: `toasts.api.${ServerRoutes.SUBSCRIPTION_POST_UPDATE_SUBSCRIPTION}.PUT`
    },
    [ServerRoutes.SETTINGS_QUICKBOOKS]: { PUT: `toasts.api.${ServerRoutes.SETTINGS_QUICKBOOKS}.PUT` },
    [ServerRoutes.SETTINGS_LISTINGS]: { PUT: `toasts.api.${ServerRoutes.SETTINGS_LISTINGS}.PUT` },
    [ServerRoutes.SETTINGS_MANAGEMENT_FEES]: { PUT: `toasts.api.${ServerRoutes.SETTINGS_MANAGEMENT_FEES}.PUT` },
    [ServerRoutes.SETTINGS_RENTAL_APPLICATIONS]: { PUT: `toasts.api.${ServerRoutes.SETTINGS_RENTAL_APPLICATIONS}.PUT` },
    [ServerRoutes.SETTINGS_LATE_FEES]: { PUT: `toasts.api.${ServerRoutes.SETTINGS_LATE_FEES}.PUT` },
    [ServerRoutes.SETTINGS_TENANT_REQUESTS]: { PUT: `toasts.api.${ServerRoutes.SETTINGS_TENANT_REQUESTS}.PUT` },
    [ServerRoutes.SETTINGS_OWNER_REQUESTS]: { PUT: `toasts.api.${ServerRoutes.SETTINGS_OWNER_REQUESTS}.PUT` },
    [ServerRoutes.SETTINGS_PAYMENT_INSTRUCTIONS]: {
      PUT: `toasts.api.${ServerRoutes.SETTINGS_PAYMENT_INSTRUCTIONS}.PUT`
    },
    [ServerRoutes.SETTINGS_RENT_REMINDERS]: { PUT: `toasts.api.${ServerRoutes.SETTINGS_RENT_REMINDERS}.PUT` },
    [ServerRoutes.SETTINGS_TENANT_PORTAL]: { PUT: `toasts.api.${ServerRoutes.SETTINGS_TENANT_PORTAL}.PUT` },
    [ServerRoutes.SETTINGS_CHECKBOOK]: { PUT: `toasts.api.${ServerRoutes.SETTINGS_CHECKBOOK}.PUT` },
    [ServerRoutes.BANK_CONNECT_PLAID_TRANSACTIONS_POST_REFRESH]: {
      POST: `toasts.api.${ServerRoutes.BANK_CONNECT_PLAID_TRANSACTIONS_POST_REFRESH}.POST`
    },
    [ServerRoutes.API_KEYS]: {
      POST: `toasts.api.${ServerRoutes.API_KEYS}.POST`,
      PUT: `toasts.api.${ServerRoutes.API_KEYS}.PUT`,
      DELETE: `toasts.api.${ServerRoutes.API_KEYS}.DELETE`
    },
    [ServerRoutes.PROPERTY_GROUPS]: {
      POST: `toasts.api.${ServerRoutes.PROPERTY_GROUPS}.POST`,
      PUT: `toasts.api.${ServerRoutes.PROPERTY_GROUPS}.PUT`,
      DELETE: `toasts.api.${ServerRoutes.PROPERTY_GROUPS}.DELETE`
    },
    [ServerRoutes.USER_ROLES]: {
      POST: `toasts.api.${ServerRoutes.USER_ROLES}.POST`,
      PUT: `toasts.api.${ServerRoutes.USER_ROLES}.PUT`,
      DELETE: `toasts.api.${ServerRoutes.USER_ROLES}.DELETE`
    },
    [ServerRoutes.TENANTS]: {
      POST: `toasts.api.${ServerRoutes.TENANTS}.POST`,
      PUT: `toasts.api.${ServerRoutes.TENANTS}.PUT`,
      DELETE: `toasts.api.${ServerRoutes.TENANTS}.DELETE`
    },
    [ServerRoutes.OWNERS]: {
      POST: `toasts.api.${ServerRoutes.OWNERS}.POST`,
      PUT: `toasts.api.${ServerRoutes.OWNERS}.PUT`,
      DELETE: `toasts.api.${ServerRoutes.OWNERS}.DELETE`
    },
    [ServerRoutes.VENDORS]: {
      POST: `toasts.api.${ServerRoutes.VENDORS}.POST`,
      PUT: `toasts.api.${ServerRoutes.VENDORS}.PUT`,
      DELETE: `toasts.api.${ServerRoutes.VENDORS}.DELETE`
    },
    [ServerRoutes.USERS]: {
      POST: `toasts.api.${ServerRoutes.USERS}.POST`,
      PUT: `toasts.api.${ServerRoutes.USERS}.PUT`,
      DELETE: `toasts.api.${ServerRoutes.USERS}.DELETE`
    },
    [ServerRoutes.PROPERTIES]: {
      POST: `toasts.api.${ServerRoutes.PROPERTIES}.POST`,
      PUT: `toasts.api.${ServerRoutes.PROPERTIES}.PUT`,
      DELETE: `toasts.api.${ServerRoutes.PROPERTIES}.DELETE`
    },
    [ServerRoutes.PROPERTIES_POST_PROPERTY_WITH_UNITS]: {
      POST: `toasts.api.${ServerRoutes.PROPERTIES_POST_PROPERTY_WITH_UNITS}.POST`
    },
    [ServerRoutes.UNITS]: {
      POST: `toasts.api.${ServerRoutes.UNITS}.POST`,
      PUT: `toasts.api.${ServerRoutes.UNITS}.PUT`,
      DELETE: `toasts.api.${ServerRoutes.UNITS}.DELETE`
    },
    [ServerRoutes.LEASE_DRAFTS]: {
      POST: `toasts.api.${ServerRoutes.LEASE_DRAFTS}.POST`,
      PUT: `toasts.api.${ServerRoutes.LEASE_DRAFTS}.PUT`,
      DELETE: `toasts.api.${ServerRoutes.LEASE_DRAFTS}.DELETE`
    },
    [ServerRoutes.LEASES]: {
      POST: `toasts.api.${ServerRoutes.LEASES}.POST`,
      PUT: `toasts.api.${ServerRoutes.LEASES}.PUT`,
      DELETE: `toasts.api.${ServerRoutes.LEASES}.DELETE`
    },
    [ServerRoutes.RENTAL_APPLICATIONS]: { POST: `toasts.api.${ServerRoutes.RENTAL_APPLICATIONS}.POST` },
    [ServerRoutes.LEASE_CHARGES]: { POST: `toasts.api.${ServerRoutes.LEASE_CHARGES}.POST` },
    [ServerRoutes.LEASE_PAYMENTS]: { POST: `toasts.api.${ServerRoutes.LEASE_PAYMENTS}.POST` },
    [ServerRoutes.LEASE_REFUNDS]: { POST: `toasts.api.${ServerRoutes.LEASE_REFUNDS}.POST` },
    [ServerRoutes.VENDOR_BILLS]: { POST: `toasts.api.${ServerRoutes.VENDOR_BILLS}.POST` },
    [ServerRoutes.OWNER_CONTRIBUTIONS]: { POST: `toasts.api.${ServerRoutes.OWNER_CONTRIBUTIONS}.POST` },
    [ServerRoutes.TRANSFERS]: { POST: `toasts.api.${ServerRoutes.TRANSFERS}.POST` },
    [ServerRoutes.DEPOSITS]: { POST: `toasts.api.${ServerRoutes.DEPOSITS}.POST` },
    [ServerRoutes.EXPENSES]: {
      POST: `toasts.api.${ServerRoutes.EXPENSES}.POST`,
      PUT: `toasts.api.${ServerRoutes.EXPENSES}.PUT`,
      DELETE: `toasts.api.${ServerRoutes.EXPENSES}.DELETE`
    },
    [ServerRoutes.RECONCILIATIONS]: {
      POST: `toasts.api.${ServerRoutes.RECONCILIATIONS}.POST`,
      PUT: `toasts.api.${ServerRoutes.RECONCILIATIONS}.PUT`,
      DELETE: `toasts.api.${ServerRoutes.RECONCILIATIONS}.DELETE`
    },
    [ServerRoutes.FILES]: {
      PUT: `toasts.api.${ServerRoutes.FILES}.PUT`,
      DELETE: `toasts.api.${ServerRoutes.FILES}.DELETE`
    }
  }
};

export default Toasts;
