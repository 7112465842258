export type ProjectFeatureIds =
  | "IconBrowser"
  | "BulkCharges"
  | "BulkReceivePayments"
  | "BulkDownloadTenantStatement"
  | "AgeingAsOfDateDetails"
  | "SmartyAutocomplete"
  | "CashPayments"
  | "BulkUploadWithIntercom"
  | "NativeAppOAuthFlow"
  | "AutoPayPaymentFlow"
  | "CommunicationsCenter"
  | "DepositDetailReport";

interface Platform {
  web: boolean;
  ios: boolean;
  android: boolean;
}

export interface FeatureFlagSettings {
  localDev: Platform | boolean;
  dev: Platform | boolean;
  beta: Platform | boolean;
  production: Platform | boolean;
}

const webOnMobileOff: Platform = {
  web: true,
  ios: false,
  android: false
};

const featuresToFlagMap: Record<ProjectFeatureIds, FeatureFlagSettings> = {
  IconBrowser: {
    localDev: true,
    dev: true,
    beta: true,
    production: false
  },
  BulkCharges: {
    localDev: webOnMobileOff,
    dev: webOnMobileOff,
    beta: webOnMobileOff,
    production: webOnMobileOff
  },
  BulkReceivePayments: {
    localDev: webOnMobileOff,
    dev: webOnMobileOff,
    beta: webOnMobileOff,
    production: webOnMobileOff
  },
  BulkDownloadTenantStatement: {
    localDev: false,
    dev: false,
    beta: false,
    production: false
  },
  AgeingAsOfDateDetails: {
    localDev: true,
    dev: true,
    beta: true,
    production: false
  },
  SmartyAutocomplete: {
    localDev: false,
    dev: false,
    beta: true,
    production: true
  },
  AutoPayPaymentFlow: {
    localDev: false,
    dev: false,
    beta: true,
    production: true
  },
  CashPayments: {
    localDev: true,
    dev: true,
    beta: true,
    production: true
  },
  BulkUploadWithIntercom: {
    localDev: false,
    dev: false,
    beta: false,
    production: false
  },
  NativeAppOAuthFlow: {
    localDev: true,
    dev: true,
    beta: true,
    production: true
  },
  CommunicationsCenter: {
    localDev: true,
    dev: true,
    beta: true,
    production: true
  },
  DepositDetailReport: {
    localDev: true,
    dev: true,
    beta: true,
    production: true
  }
};

export const ProjectFeatures = (featureId: ProjectFeatureIds): FeatureFlagSettings => featuresToFlagMap[featureId];
