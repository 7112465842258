const OwnerPortal = {
  Requests: {
    DialogTitle: "ownerPortal.requests.dialogTitle",
    Dialog: {
      NewRequest: "ownerPortal.requests.dialog.newRequest",
      ViewRequest: "ownerPortal.requests.dialog.viewRequest",
      SubjectLabel: "ownerPortal.requests.dialog.subjectLabel",
      SubjectInput: "ownerPortal.requests.dialog.subjectInput",
      DescriptionLabel: "ownerPortal.requests.dialog.descriptionLabel",
      DescriptionInput: "ownerPortal.requests.dialog.descriptionInput",
      RequestSubject: "ownerPortal.requests.dialog.requestSubject",
      RequestDetails: "ownerPortal.requests.dialog.requestDetails",
      ActivityFeed: "ownerPortal.requests.dialog.activityFeed"
    }
  }
};

export default OwnerPortal;
