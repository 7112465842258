import type { DialogProps } from "./dialogsProvider.types";
import TaxForm1099ExportDialog from "screens/reports/1099Forms/exportDialog/taxForm1099ExportDialog";
import { DialogRoutes } from "./dialogsRoutes";

class TaxFormDialogsProvider {
  matchRoutes(pathname: string): DialogProps | undefined {
    if (pathname.endsWith(DialogRoutes.EXPORT_TAX_FORMS_1099_MISC)) {
      return {
        Content: TaxForm1099ExportDialog
      };
    }
    if (pathname.endsWith(DialogRoutes.EXPORT_TAX_FORMS_1099_NEC)) {
      return {
        Content: TaxForm1099ExportDialog
      };
    }
  }
}

export const taxFormDialogsProvider = new TaxFormDialogsProvider();
