import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { Icon } from "DLUI/icon";
import { PaymentCardFillIcon, PaymentECheckCardFillIcon } from "@/assets";
import { SeparationLine } from "DLUI/separatorView";

export interface PlaidAccountListItemProps {
  id?: string;
  name: string;
  official_name: string;
  type?: string;
  mask?: string;
  onAccountItemPress: (accountId: string) => void;
}

export const PlaidAccountListItemHeight = 70;

const PlaidAccountListItem: React.FC<PlaidAccountListItemProps> = ({
  id,
  name,
  official_name,
  type,
  mask,
  onAccountItemPress
}: PlaidAccountListItemProps) => {
  const listItemIcon = type === "depository" ? PaymentECheckCardFillIcon : PaymentCardFillIcon;

  const _onAccountItemPress = () => {
    if (id) {
      onAccountItemPress(id);
    }
  };
  return (
    <View marginTop={20} paddingRight={40}>
      <View
        alignItems={"center"}
        height={PlaidAccountListItemHeight}
        backgroundColor={"white"}
        borderRadius={10}
        flexDirection={"row"}
        onClick={_onAccountItemPress}
      >
        <Icon marginLeft={20} Source={listItemIcon} width={40} height={40} marginRight={20} />
        <SeparationLine marginRight={20} height={"50%"} width={1} />
        <View marginLeft={20} width={"auto"}>
          <View marginTop={5} flexDirection={"row"}>
            <Text fontSize={18}>{official_name}</Text>
            <Text fontSize={18} color={"gray"} marginLeft={20}>
              •••• {mask}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default PlaidAccountListItem;
