import clsx from "clsx";
import type { LabelPlacementPosition } from "DLUI/form/label/inputLabel";
import { useStylesContainer, useStylesInput } from "DLUI/form/checkBox/base/styles";
import type { ToggleInputSize } from "DLUI/form/types/toggleInputBaseTypes";
import type { CheckBoxColor } from "DLUI/form/checkBox/base/types";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";

interface Props {
  size?: ToggleInputSize;
  color?: CheckBoxColor;
  labelPlacement?: LabelPlacementPosition;
}

const useStylesCheckBox = ({ size: sizeProp, color = "blue", labelPlacement = "end" }: Props) => {
  const classesContainer = useStylesContainer();
  const classesInput = useStylesInput();

  const { isTabletOrMobile } = useResponsiveHelper();
  const calculatedSize = sizeProp || (isTabletOrMobile ? "large" : "medium");

  return {
    classNameContainer: clsx([
      classesContainer.root,
      classesContainer[calculatedSize],
      classesContainer[labelPlacement]
    ]),
    classesInput: {
      root: clsx([classesInput.root, classesInput[calculatedSize], classesInput[color]]),
      checked: classesInput.checked,
      disabled: classesInput.disabled,
      colorPrimary: classesInput.colorPrimary,
      colorSecondary: classesInput.colorSecondary
    }
  };
};

export { useStylesCheckBox };
