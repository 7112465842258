import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ListItemContainerMaxWidth } from "DLUI/infiniteList/utils";

const ListItemHeight = 100;
const SmallListItemHeight = 50;

export default makeStyles((theme: Theme) =>
  createStyles({
    propertyListItemContainer: {
      display: "flex",
      flexDirection: "row",
      boxSizing: "border-box",
      minHeight: "110px",
      borderRadius: "30px",
      backgroundColor: theme.palette.white.main,
      boxShadow: "0 1px 3px 0 rgba(0,0,0,0.04)",
      margin: "0px 20px 20px 20px",
      position: "relative",
      alignItems: "center"
    },
    ListItemIconContainer: {
      borderRadius: "10px",
      backgroundColor: "rgba(47, 62, 131, 0.1)",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      margin: "0px 20px 0px 0px"
    },
    listItemGridContainer: {
      backgroundColor: theme.palette.white.main,
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.7)  !important",
        boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.04) !important"
      }
    },
    propertyIcon: {
      width: "45px",
      height: "45px"
    },
    ListItemContentContainer: {
      display: "flex",
      flexDirection: "row",
      flex: 1,
      justifyContent: "flex-start",
      maxWidth: "1030px"
    },
    descriptionSection: {
      display: "flex",
      flexDirection: "column",
      flex: 1
    },
    titleContainer: {
      display: "flex",
      margin: "10px 0px",
      justifyContent: "flex-start"
    },
    addressText: {
      width: "100%"
    },
    ListItemStatusContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap"
    },
    statusItemContainer: {
      width: "200px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start"
    },
    divider: {
      width: "1px",
      height: "50%",
      alignSelf: "center",
      justifySelf: "center",
      marginLeft: "5px"
    },

    grayBackground: {
      backgroundColor: theme.palette.screenBackground.light
    },

    grayLightBackground: {
      backgroundColor: theme.palette.gray.light
    },
    whiteBackground: {
      backgroundColor: theme.palette.screenBackground.main
    },

    actionPanelContainer: {
      width: "180px",
      display: "flex",
      height: "100%",
      alignItems: "center",
      justifyContent: "flex-end",
      marginRight: "30px"
    },
    listItemContainer: {
      display: "flex",
      flexDirection: "row",
      boxSizing: "border-box",
      maxWidth: ListItemContainerMaxWidth + "px",
      height: ListItemHeight,
      borderRadius: "30px",
      backgroundColor: theme.palette.white.main,
      boxShadow: "0 1px 3px 0 rgba(0,0,0,0.04)",
      position: "relative",
      alignItems: "center"
    },
    listItemContainerSmall: {
      borderRadius: "10px",
      display: "flex",
      flexDirection: "row",
      boxSizing: "border-box",
      maxWidth: ListItemContainerMaxWidth + "px",
      backgroundColor: theme.palette.white.main,
      boxShadow: "0 1px 3px 0 rgba(0,0,0,0.04)",
      position: "relative",
      alignItems: "center",
      overflow: "hidden",
      height: SmallListItemHeight
    },
    darkContainer: {
      backgroundColor: theme.palette.screenBackground.light
    },
    listItemContainerMobile: {
      height: "auto",
      minHeight: ListItemHeight,
      paddingTop: "20px",
      paddingBottom: "20px"
    },
    gridPaddingLeftRight: {
      height: "100%"
    },
    gridContainer: {
      display: "flex",
      alignItems: "center",
      flexWrap: "nowrap"
    },
    leaseIcon: {
      width: "45px",
      height: "45px"
    }
  })
);
