import type { GridProps } from "@progress/kendo-react-grid";
import type { GridColumn } from "../types";
import type { AggregateDescriptor } from "@progress/kendo-data-query";

export function getGroupProp(groupBy: string, gridColumns: GridColumn[]): GridProps["group"] {
  if (!groupBy) {
    return [];
  }

  const aggregates: AggregateDescriptor[] = [];
  let groupByString = groupBy;

  gridColumns.forEach((column) => {
    if (column.aggregate) {
      aggregates.push({
        field: column.field || "",
        aggregate: column.aggregate
      });
    }

    if (column.field && column.field.indexOf(groupBy) > -1) {
      groupByString = column.field;
    }
  });

  return [
    {
      field: groupByString,
      aggregates
    }
  ];
}
