import {
  isDatePickerFilterItem,
  isDateRangePickerFilterItem,
  isMultiSelectFilterItem,
  isRemoteFilterItem,
  isStaticFilterItem,
  isTextFilteringFilterItem
} from "@/components/common/filters/utils";
import type { FilterOption } from "@/components/DLUI/screen/filterPanel/filterInputs/types";

export function getFilterOptionKeys<TQuery>(filterOption: FilterOption<TQuery>): string[] {
  if (isStaticFilterItem(filterOption)) {
    const key = filterOption?.filterFieldName || filterOption?.values?.[0]?.filterFieldName;
    if (!key) {
      return [];
    }

    return [key];
  }

  if (
    isRemoteFilterItem(filterOption) ||
    isMultiSelectFilterItem(filterOption) ||
    isTextFilteringFilterItem(filterOption) ||
    isDatePickerFilterItem(filterOption)
  ) {
    if (!filterOption?.filterFieldName) {
      return [];
    }

    return [filterOption.filterFieldName];
  }

  if (isDateRangePickerFilterItem(filterOption)) {
    return [filterOption.startDateFieldKey, filterOption.endDateFieldKey].filter(Boolean);
  }

  return [];
}
