import { ServerRoutes } from "@doorloop/dto";
import type { ReconciliationDto } from "@doorloop/dto";
import type { GetAllReconciliationsQuery } from "@doorloop/dto";
import { apiHelper } from "api/apiHelper";
import type { ApiResult } from "api/apiResult";
import type { GetNewReconciliationInfoForAccountResponseDto } from "@doorloop/dto";
import { accountsApi } from "api/accounts";
import { usersApi } from "api/usersApi";
import { RestApiBase } from "api/restApiBase";
import { vendorsApi } from "api/vendorsApi";
import { ownersApi } from "api/ownersApi";
import { tenantsApi } from "api/tenantsApi";

export class BankReconciliationApi extends RestApiBase<ReconciliationDto, GetAllReconciliationsQuery> {
  constructor() {
    super(ServerRoutes.RECONCILIATIONS, [accountsApi, usersApi, vendorsApi, ownersApi, tenantsApi]);
    this.restRoute = ServerRoutes.RECONCILIATIONS;
  }

  getAccountReconciliation = async (
    accountId: string
  ): Promise<ApiResult<GetNewReconciliationInfoForAccountResponseDto>> =>
    await apiHelper.axiosGet(ServerRoutes.RECONCILIATIONS_GET_NEW_RECONCILIATION, { account: accountId });
}

export const bankReconciliationApi = new BankReconciliationApi();
