import React, { Fragment, useEffect, useState } from "react";
import { AddCirclePinkIcon, CloseCircleIcon, ProfileAvatar } from "assets/icons";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import makeStyles from "./styles";
import { ProfileImage } from "DLUI/profileImage";
import { Icon } from "DLUI/icon";
import { IconButton } from "DLUI/form";
import { Link } from "DLUI/link";

interface ComponentProps {
  selectedImageFile?: File;
  onFileDelete: () => void;
  selectedImageUrl?: string;
}

const ProfileImageSelection: React.FC<ComponentProps> = ({
  selectedImageFile,
  onFileDelete,
  selectedImageUrl
}: ComponentProps) => {
  const classes = makeStyles();
  const [selectedImageData, setSelectedImageData] = useState<string | ArrayBuffer | null>(null);

  useEffect(() => {
    if (selectedImageFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        //@ts-ignore
        if (e && e.target && e.target.result) {
          //@ts-ignore
          setSelectedImageData(e.target.result);
        }
      };
      reader.readAsDataURL(selectedImageFile);
    } else {
      setSelectedImageData(null);
    }
  }, [selectedImageFile]);

  const renderUnselectedState = () => (
    <Fragment>
      <div className={classes.tenantPhotoContainer}>
        <ProfileAvatar className={classes.tenantPhotoAvatar} />
      </div>
      <div className={classes.uploadImageButtonContainer}>
        <Icon marginLeft={10} width={20} height={20} Source={AddCirclePinkIcon} />
        <Link hoverColor={"black"} underline={"always"}>
          <Text variant={"span"} color={"black"} value={AppStrings.Tenants.NewTenant.UploadPhoto} marginLeft={10} />
        </Link>
      </div>
    </Fragment>
  );

  const renderSelectedState = () => (
    <Fragment>
      <div className={classes.tenantPhotoContainer}>
        <div className={classes.deleteImageContainer}>
          <IconButton circleFill={"pink"} Icon={CloseCircleIcon} width={20} height={20} onClick={onFileDelete} />
        </div>
        {selectedImageUrl ? (
          <ProfileImage size={100} pictureUrl={selectedImageUrl} />
        ) : (
          <div
            className={classes.selectedImage}
            style={{
              backgroundImage: "url(" + selectedImageData!.toString() + ")"
            }}
          ></div>
        )}
      </div>
    </Fragment>
  );

  if (selectedImageData !== null || selectedImageUrl !== undefined) {
    return renderSelectedState();
  }

  return renderUnselectedState();
};

export default ProfileImageSelection;
