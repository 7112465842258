import type { SVGIconComponent } from "../../../assets";
import Text from "DLUI/text/text";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { Icon } from "DLUI/icon";
import { SpeedDial } from "@material-ui/lab";
import React from "react";
import type { PopoverItem } from "DLUI/popover";
import { Backdrop } from "@material-ui/core";
import makeStyles from "./styles";
import { usePermission } from "screens/settings/userRoles/usePermission";
import { View } from "DLUI/view";

interface ComponentProps {
  items: PopoverItem[];
  isOpen: boolean;
  onClick?: () => void;
  fabPlacement?: "left" | "right";
  icon: SVGIconComponent;
  showBackdrop?: boolean;
  offsetLeft?: number;
  offsetBottom?: number;
}

/**
 * @description FAB - Floating Action Button - used as a substitute for
 * inline action buttons specifically for mobile screens
 * @returns {JSX.Element}
 * @constructor
 */
const FAB = ({
  fabPlacement,
  icon,
  isOpen,
  items,
  offsetBottom,
  offsetLeft,
  onClick,
  showBackdrop = true
}: ComponentProps) => {
  const { hasPermission } = usePermission();

  const classes = makeStyles();

  const baseOffset = 16;

  const { bottom, left, right } = {
    bottom: baseOffset + (offsetBottom ?? 0) + "px",
    left: fabPlacement === "left" ? baseOffset + (offsetLeft ?? 0) : "unset",
    right: fabPlacement === "right" ? baseOffset + (offsetLeft ?? 0) : "unset"
  };

  return (
    <>
      {showBackdrop && <Backdrop onClick={onClick} classes={{ root: classes.fabBackdrop }} open={isOpen} />}

      <SpeedDial
        style={{ position: "fixed", bottom, left, right }}
        FabProps={{
          classes: {
            root: classes.fabButton
          }
        }}
        icon={<Icon size={22} Source={icon} />}
        open={isOpen}
        onClick={onClick}
        ariaLabel="SpeedDial_fab"
      >
        {items.map(
          (item, index) =>
            item.Icon &&
            hasPermission(item.clearance?.permission, item.clearance?.field, item.clearance?.requiredLevel) && (
              <SpeedDialAction
                key={"FAB_ITEM_" + index}
                onClick={item.onClick}
                icon={<Icon size={18} Source={item.Icon} />}
                tooltipTitle={
                  <View style={{ width: "max-content" }}>
                    <Text value={item.title} />
                  </View>
                }
                tooltipOpen
                tooltipPlacement={fabPlacement === "left" ? "right" : "left"}
              />
            )
        )}
      </SpeedDial>
    </>
  );
};

export default FAB;
