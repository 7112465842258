import type { ReportScreenSectionGroupItemType } from "DLUI/report/screen/reportScreenSectionGroupItemType";
import {
  AccountingItems,
  BusinessOverviewItems,
  LeasingItems,
  ProspectsItems,
  TasksItems,
  TenantsItems,
  VendorsItems
} from "screens/reports/reportsScreen/reportsItems";
import AppStrings from "../../../../locale/keys";
import WhoOwesYouItems from "screens/reports/reportsScreen/reportsItems/whoOwesYou";
import WhatYouOwe from "screens/reports/reportsScreen/reportsItems/whatYouOwe";
import OwnersItems from "screens/reports/reportsScreen/reportsItems/ownersItems";
import { filterObjectsByUserType } from "../../../../utils/userTypesUtils";

export const getUserReportsScreenSectionGroupItem = (isHOAUser = false): ReportScreenSectionGroupItemType[] => {
  const items: ReportScreenSectionGroupItemType[] = [
    {
      items: BusinessOverviewItems(isHOAUser),
      title: AppStrings.Reports.ReportsScreen.BusinessOverview
    },
    {
      items: AccountingItems(isHOAUser),
      title: AppStrings.DrawerItems.Accounting
    },
    {
      items: WhoOwesYouItems(isHOAUser),
      title: AppStrings.Reports.ReportsScreen.WhoOwesYou
    },
    {
      items: WhatYouOwe(isHOAUser),
      title: AppStrings.Reports.ReportsScreen.WhatYouOwe
    },
    {
      items: TenantsItems(isHOAUser),
      title: AppStrings.Reports.ReportsScreen.TenantsReport
    },
    {
      items: ProspectsItems(isHOAUser),
      title: AppStrings.DrawerItems.Prospects,
      hiddenForUserTypes: ["HOAUser"]
    },
    {
      items: LeasingItems(isHOAUser),
      title: AppStrings.DrawerItems.Leasing
    },
    {
      items: VendorsItems(isHOAUser),
      title: AppStrings.DrawerItems.Vendors
    },
    {
      items: TasksItems(isHOAUser),
      title: AppStrings.DrawerItems.Tasks
    },
    {
      items: OwnersItems(isHOAUser),
      title: AppStrings.DrawerItems.Owners,
      hiddenForUserTypes: ["HOAUser"]
    }
  ];

  return !isHOAUser ? items : filterObjectsByUserType(items, "HOAUser");
};
