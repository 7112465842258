import { CircularProgress, makeStyles, useTheme } from "@material-ui/core";
import type { Theme } from "@/styles/defaultTheme";
import { SearchIcon, XGrayIcon } from "@/assets";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import type { ChangeEvent } from "react";

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: "flex",
      alignItems: "center",
      gap: 4,
      flexGrow: 1,
      backgroundColor: theme.newPalette.background.paper,
      border: "1px solid",
      borderColor: theme.newPalette.other.divider,
      borderRadius: 4,
      padding: "0 8px",
      width: "100%",
      maxWidth: 220,
      height: 32,
      [theme.breakpoints.down("sm")]: {
        height: 48,
        maxWidth: "100%",
        width: "initial"
      }
    },
    input: {
      all: "unset",
      fontSize: 14,
      fontWeight: 700,
      height: "100%",
      flexBasis: "80%",
      color: theme.newPalette.text.primary,
      "&::placeholder": {
        color: `${theme.newPalette.text.secondary} !important`
      }
    },
    marginLeftAuto: {
      marginLeft: "auto"
    },
    iconContainer: {
      display: "flex",
      width: 24,
      alignItems: "center",
      justifyContent: "center"
    },
    clearIconButton: {
      width: "100%",
      height: "100%",
      display: "flex"
    }
  };
});

export interface FilterPanelSearchInputProps {
  query: string;
  placeholder: string;
  isLoading?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export function FilterPanelSearchInput({
  query,
  placeholder,
  onChange,
  isLoading = false
}: FilterPanelSearchInputProps) {
  const classes = useStyles();
  const handlerInputClear = () => {
    onChange?.({ target: { value: "" } } as ChangeEvent<HTMLInputElement>);
  };

  const { t } = useTranslation();

  return (
    <div className={clsx(classes.root, "border-box", "no-print")}>
      {query.length === 0 && (
        <div className={classes.iconContainer}>
          <InputIcon isLoading={isLoading} query={query} onClear={handlerInputClear} />
        </div>
      )}
      <input
        type="text"
        placeholder={t(placeholder)}
        className={classes.input}
        value={query}
        onChange={onChange}
        aria-label={t(placeholder)}
        maxLength={60}
      />
      {query.length > 0 && (
        <div className={clsx(classes.iconContainer, classes.marginLeftAuto)}>
          <InputIcon isLoading={isLoading} query={query} onClear={handlerInputClear} />
        </div>
      )}
    </div>
  );
}

interface InputIconProps {
  isLoading?: boolean;
  onClear?: VoidFunction;
  query?: string;
}

function InputIcon({ isLoading, query, onClear }: InputIconProps) {
  const classes = useStyles();
  const theme = useTheme<Theme>();

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          display: "flex",
          width: 14,
          height: 14,
          color: theme.newPalette.text.secondary
        }}
      />
    );
  }

  if (typeof query === "string" && query.length > 0) {
    return (
      <button className={clsx(classes.clearIconButton, "button-reset")} onClick={onClear}>
        <XGrayIcon width={26} height={26} />
      </button>
    );
  }

  return <SearchIcon />;
}
