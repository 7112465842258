"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsurancePolicyStatus = void 0;
var InsurancePolicyStatus;
(function (InsurancePolicyStatus) {
    InsurancePolicyStatus["NO_INSURANCE"] = "NO_INSURANCE";
    InsurancePolicyStatus["EXPIRED"] = "EXPIRED";
    InsurancePolicyStatus["ACTIVE"] = "ACTIVE";
    InsurancePolicyStatus["FUTURE"] = "FUTURE";
})(InsurancePolicyStatus || (exports.InsurancePolicyStatus = InsurancePolicyStatus = {}));
