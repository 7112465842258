import ColorsEnum from "@/utils/colorsEnum";
import type { RequestViewOnlyRenderMap } from "./requestViewOnly.types";
import { useTypedTranslation } from "@/locale";
import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import type { TaskDto } from "@doorloop/dto";
import { DateFormats, WorkOrderPermissionToEnter } from "@doorloop/dto";
import { useResponsiveHelper } from "contexts/responsiveContext";
import moment from "moment";
import { CheckmarkCircleIcon, CloseCircleIconRed, SimpleCalendarIcon } from "@/assets";
import { Icon } from "DLUI/icon";
import { useRequestStatusesMap } from "../../../requests/hooks/useRequestStatusesMap";
import { useUserType } from "@/hooks/useUserType";

interface ComponentProps {
  requestData: TaskDto;
}

export const TenantRequestInformation: React.FC<ComponentProps> = ({ requestData }) => {
  const { t } = useTypedTranslation();

  const { getTaskTypeLabel, statusLabelColorMap } = useRequestStatusesMap();
  const { tenantRequestType, entryPermission, subject, description, createdAt, entryNotes, status } = requestData || {};

  const { isHOAUser } = useUserType();

  const { isMobile } = useResponsiveHelper();

  const hasEntryPermission = entryPermission === WorkOrderPermissionToEnter.YES;
  const hasEntryNotes = entryNotes && entryNotes?.length > 0;

  const isAccessGranted = entryPermission === WorkOrderPermissionToEnter.YES;

  const mobileTitleLabelViewItemsMap: RequestViewOnlyRenderMap = {
    [t("common.requestType")]: {
      text: getTaskTypeLabel(tenantRequestType)
    },
    [t("common.requestSubjectDescriptionViewOnly")]: { text: subject },
    [t("common.requestStatus")]: {
      text: statusLabelColorMap[status || ""]?.label,
      color: statusLabelColorMap[status || ""]?.color,
      isBold: true
    }
  };

  const desktopTitleLabelViewItemsMap: RequestViewOnlyRenderMap = {
    ...mobileTitleLabelViewItemsMap,
    [isHOAUser ? t("common.accessToAssociation") : t("common.accessToProperty")]: {
      text: isAccessGranted ? t("common.yes") : t("common.no"),
      icon: isAccessGranted ? (
        <Icon Source={CheckmarkCircleIcon} size={18} />
      ) : (
        <Icon Source={CloseCircleIconRed} size={18} circleFill="error" circleStroke="error" />
      )
    },
    [t("common.creationDate")]: {
      text: moment(createdAt).format(DateFormats.AMERICAN_DATE),
      icon: <Icon Source={SimpleCalendarIcon} size={18} />
    }
  };

  const mapToRender = isMobile ? mobileTitleLabelViewItemsMap : desktopTitleLabelViewItemsMap;

  return (
    <View
      backgroundColorEnum={ColorsEnum.LightGray}
      paddingLeft={16}
      paddingRight={16}
      paddingTop={16}
      paddingBottom={16}
      marginTop={16}
    >
      <View flexDirection="row" justifyContent="flex-start" alignItems="center" width="100%" borderRadius={4}>
        {Object.entries(mapToRender).map(([title, value], index) => {
          const isLastItem = index === Object.entries(mapToRender).length - 1;
          const isFirstItem = index === 0;

          return (
            <View
              key={index}
              style={{
                borderRight: !isLastItem ? `1px solid ${ColorsEnum.LightBlackFadedMore}` : "unset"
              }}
              justifyContent="flex-start"
              height="100%"
              flex={1}
              paddingLeft={isFirstItem ? 0 : 8}
            >
              <Text value={title} color="gray" fontSize={10} whiteSpace="nowrap" bold />
              <View flexDirection="row" alignItems="center" marginTop={8}>
                {value?.icon && value?.text && value?.icon}
                {
                  <Text
                    value={value?.text || t("common.notSet")}
                    fontSize={12}
                    paddingLeft={value?.icon && value?.text ? 8 : 0}
                    style={{
                      color: value?.color || ColorsEnum.LightBlackFaded,
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                    bold={Boolean(value?.isBold)}
                    maxWidth={140}
                    noWrap
                  />
                }
              </View>
            </View>
          );
        })}
      </View>

      <View>
        <Text value={t("common.requestDescription")} marginTop={20} color="gray" fontSize={10} bold />
        <Text
          value={description || t("common.requestDescriptionTextNoDescription")}
          marginTop={4}
          color="light-black-faded"
          fontSize={12}
        />
      </View>

      {hasEntryPermission && (
        <View>
          <Text value={t("common.entryNotes")} marginTop={20} color="gray" fontSize={10} bold />
          <Text value={hasEntryNotes ? entryNotes : "--"} marginTop={4} color="light-black-faded" fontSize={12} />
        </View>
      )}
    </View>
  );
};
