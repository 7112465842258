import React, { useState } from "react";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { Formik } from "formik";
import InsurancePolicyDialog from "DLUI/dialogs/insurancePolicy/insurancePolicyDialog";
import { InsurancePolicyDto } from "@doorloop/dto";
import { useEffectAsync } from "../../../../hooks/useEffectAsync";
import { useParams } from "react-router-dom";
import AppStrings from "../../../../locale/keys";
import { useTranslation } from "react-i18next";
import { InsurancePolicyProvider } from "@doorloop/dto";
import { insurancePoliciesApi } from "api/insurancePoliciesApi";
import type { ExpenseDto } from "@doorloop/dto";
import InsurancePolicyLoadingView from "DLUI/dialogs/insurancePolicy/loadingDialog";
import type { FrameType } from "DLUI/dialogs/components/dialogFrame";
import { createValidator } from "@doorloop/dto";

export enum InsurancePolicyDialogViews {
  DialogForm,
  SelectLease,
  ConfirmDelete,
  LoadingView
}

export const frameTypeMap: Record<InsurancePolicyDialogViews, FrameType> = {
  [InsurancePolicyDialogViews.LoadingView]: "contentOnly",
  [InsurancePolicyDialogViews.SelectLease]: "topPanel",
  [InsurancePolicyDialogViews.DialogForm]: "sectionTitleFrame",
  [InsurancePolicyDialogViews.ConfirmDelete]: "contentOnly"
};

const validateForm = createValidator(InsurancePolicyDto);

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
}

const InsurancePolicyFormikContextWrapper: React.FC<ComponentProps> = ({
  onBackdropClick,
  onClose,
  dialogTitle
}: ComponentProps) => {
  const { t } = useTranslation();
  const { policyId } = useParams<any>();
  const isEditMode = policyId !== undefined;
  const [viewIndex, setViewIndex] = useState(InsurancePolicyDialogViews.DialogForm);
  const [policyData, setPolicyData] = useState<ExpenseDto | undefined>();
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    isEditMode ? DialogState.Show : DialogState.Hidden
  );
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");

  const initFormValues = (): InsurancePolicyDto => {
    if (isEditMode && policyData) {
      return policyData;
    }
    const newPolicy = new InsurancePolicyDto();
    newPolicy.providerType = InsurancePolicyProvider.OTHER;

    return newPolicy;
  };

  useEffectAsync(async () => {
    if (isEditMode) {
      await loadData();
    }
  }, [isEditMode]);

  const loadData = async () => {
    setLoadingDialogState(DialogState.Show);
    try {
      const { status, data } = await insurancePoliciesApi.get(policyId);
      if (status && data) {
        setPolicyData(data);
        setLoadingDialogState(DialogState.Hidden);
      }
    } catch (e) {
      setLoadingDialogState(DialogState.Error);
      setLoadingDialogErrorText(t(AppStrings.Common.NetworkErrorSubTitle));
    }
  };

  if (loadingDialogState !== DialogState.Hidden) {
    return (
      <InsurancePolicyLoadingView
        loadingDialogState={loadingDialogState}
        loadingDialogErrorText={loadingDialogErrorText}
        onBackdropClick={onBackdropClick}
        didPressSubmit={loadData}
      />
    );
  }

  return (
    <Formik initialValues={initFormValues()} onSubmit={() => {}} validate={validateForm}>
      <InsurancePolicyDialog
        setLoadingDialogErrorText={setLoadingDialogErrorText}
        loadingDialogErrorText={loadingDialogErrorText}
        setLoadingDialogState={setLoadingDialogState}
        loadingDialogState={loadingDialogState}
        viewIndex={viewIndex}
        setViewIndex={setViewIndex}
        dialogTitle={dialogTitle}
        onClose={onClose}
        onBackdropClick={onBackdropClick}
      />
    </Formik>
  );
};

export default InsurancePolicyFormikContextWrapper;
