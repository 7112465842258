import type { FC } from "react";
import React from "react";
import { VehicleDto } from "@doorloop/dto";
import AppStrings from "../../../../locale/keys";
import DtoFieldArray from "DLUI/form/dto/DtoFieldArray";
import { VehicleFields } from "DLUI/person/vehicles/vehicleFields";
import { View } from "DLUI/view";
import type { RentalApplicationFieldControlledSectionSettingsDto } from "@doorloop/dto";

interface VehicleListFormProps {
  name: string;
  stepSettingsDto?: RentalApplicationFieldControlledSectionSettingsDto;
  isControlled?: boolean;
}

export const VehicleListForm: FC<VehicleListFormProps> = ({ name, stepSettingsDto, isControlled }) => (
  <View flex={1}>
    <DtoFieldArray
      name={name}
      newItem={() => new VehicleDto()}
      newItemText={AppStrings.Common.AddAnotherVehicle}
      headerText={AppStrings.Settings.GeneralSettings.RentalApplications.VehicleInformation}
    >
      {(renderProps) => (
        <VehicleFields {...renderProps} stepSettingsDto={stepSettingsDto} isControlled={isControlled} />
      )}
    </DtoFieldArray>
  </View>
);
