import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { SettingsSection } from "screens/settings/common/settingsSection";
import { useStyles } from "../../settings/styles";
import type { LateFeeChargeDto, LateFeesPolicyDto } from "@doorloop/dto";
import { DataCy } from "@doorloop/dto";
import type { CSSProperties } from "react";
import { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import { useTypedTranslation } from "locale";
import { Reset } from "@/assets";
import type { TextColor } from "DLUI/text/text";
import { useGeneralStyles } from "styles/generalStyles";
import TextInput from "DLUI/form/textField/textInput";
import { RuleDisplay } from "./ruleDisplay";
import { SubtotalSection } from "DLUI/dialogs/settings/common/lateFees/sampleCalculator/subtotalSection";
import { SummarySection } from "./summarySelection";
import { useCalculatorStyles } from "DLUI/dialogs/settings/common/settings/calculatorStyles";
import Text from "DLUI/text/text";

const BUTTON_STYLE: CSSProperties = { height: 32 };
const EDIT_BUTTON: CSSProperties = { ...BUTTON_STYLE, minWidth: 90 };
const RESET_BUTTON: CSSProperties = { ...BUTTON_STYLE, minWidth: 80 };

interface SampleCalculatorProps {
  lateFeeSettings: LateFeesPolicyDto;
}

const DEFAULT_RENT = 1000;
const DEFAULT_DELINQUENCY = 10;

export const SampleCalculator = ({ lateFeeSettings }: SampleCalculatorProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const classes = useStyles();
  const generalClasses = useGeneralStyles();
  const calculatorClasses = useCalculatorStyles();
  const { t } = useTypedTranslation();
  const textColor: TextColor = isEditMode ? "black" : "secondary-gray";

  const [rent, setRent] = useState<number>(DEFAULT_RENT);
  const [delinquency, setDelinquency] = useState<number>(DEFAULT_DELINQUENCY);

  const reset = useCallback(() => {
    setIsEditMode(false);
    setRent(DEFAULT_RENT);
    setDelinquency(DEFAULT_DELINQUENCY);
  }, []);

  const rules: LateFeeChargeDto[] = useMemo(
    () => lateFeeSettings.lateFeeCharges ?? [],
    [lateFeeSettings.lateFeeCharges]
  );

  return (
    <SettingsSection
      dataCy={DataCy.globalSettings.lateFees.sampleCalculator.container}
      title={"settings.generalSettings.simulation"}
      end={
        <div className={clsx(generalClasses.flexRow, generalClasses.verticalAlign, generalClasses.mediumGap)}>
          {isEditMode && (
            <DLButton
              onClick={reset}
              actionText={t("common.reset")}
              variant={DLButtonVariantsEnum.TEXT}
              color={DLButtonColorsEnum.SECONDARY}
              size={DLButtonSizesEnum.MEDIUM}
              style={RESET_BUTTON}
              icons={{ start: { src: Reset } }}
              disableMobileCollapse
              dataCy={DataCy.globalSettings.lateFees.sampleCalculator.resetButton}
              containerClassName={calculatorClasses.resetButton}
            />
          )}
          <DLButton
            onClick={() => setIsEditMode(true)}
            actionText={t("lateFee.sampleCalculator.editInputs")}
            variant={DLButtonVariantsEnum.CONTAINED}
            color={DLButtonColorsEnum.SECONDARY}
            size={DLButtonSizesEnum.MEDIUM}
            style={EDIT_BUTTON}
            disableMobileCollapse
            dataCy={DataCy.globalSettings.lateFees.sampleCalculator.editButton}
          />
        </div>
      }
      backgroundColor={"newPalette.background.neutral1"}
    >
      <Text value={t("lateFee.sampleCalculator.intro")} color={"secondary-gray"} fontSize={14} />
      <HorizontalSeparationLine marginTop={16} marginBottom={16} />
      <div className={clsx(generalClasses.flexRow, generalClasses.mediumGap)}>
        <div className={generalClasses.basicFlex}>
          <TextInput
            label={t("common.rent")}
            formatType={"currency"}
            TextFieldType={"number"}
            disabled={!isEditMode}
            data-cy={DataCy.globalSettings.lateFees.sampleCalculator.rent}
            value={rent.toString()}
            onChange={({ target }) => setRent(Number(target.value))}
          />
        </div>
        <div className={generalClasses.basicFlex}>
          <TextInput
            label={t("common.delinquency")}
            formatType={"number"}
            TextFieldType={"number"}
            disabled={!isEditMode}
            data-cy={DataCy.globalSettings.lateFees.sampleCalculator.delinquency}
            value={delinquency.toString()}
            onChange={({ target }) => setDelinquency(Number(target.value))}
          />
        </div>
      </div>
      <div className={classes.rulesContainer}>
        {rules.map((rule, index) => (
          <RuleDisplay
            key={rule.uniqueKey}
            rule={rule}
            index={index}
            isEditable={isEditMode}
            textColor={textColor}
            rent={rent}
            delinquency={delinquency}
          />
        ))}
      </div>
      <HorizontalSeparationLine marginTop={16} marginBottom={16} />
      <SubtotalSection rent={rent} delinquency={delinquency} lateFeeSettings={lateFeeSettings} textColor={textColor} />
      <HorizontalSeparationLine marginTop={16} marginBottom={16} />
      <SummarySection rent={rent} delinquency={delinquency} lateFeeSettings={lateFeeSettings} textColor={textColor} />
    </SettingsSection>
  );
};
