import { useMemo } from "react";
import clsx from "clsx";

import { ToastSizesEnum } from "./enums";
import { makeStylesContentContainer, makeStylesIcon, makeStylesRoot, makeStylesText } from "./styles";

import type { ToastSeverity } from "store/toast/types";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";

export interface UseStylesToastProps {
  severity: ToastSeverity;
}

const useStylesToast = ({ severity }: UseStylesToastProps) => {
  const { isTabletOrMobile } = useResponsiveHelper();

  const size = useMemo(() => (isTabletOrMobile ? ToastSizesEnum.MOBILE : ToastSizesEnum.WEB), []);

  const classesRoot = makeStylesRoot();
  const classesContentContainer = makeStylesContentContainer();
  const classesText = makeStylesText();
  const classesIcon = makeStylesIcon();

  return {
    root: clsx([classesRoot.root], classesRoot[severity]),
    contentContainer: clsx([classesContentContainer[size]]),
    text: clsx([classesText[size], classesText[severity]]),
    icon: clsx([classesIcon[size], classesIcon[severity]]),
    close: classesIcon[severity],
    action: clsx([classesText[size], classesText[severity], classesText.action])
  };
};

export { useStylesToast };
