import { CommunicationLinkedToType } from "@doorloop/dto";
import AppStrings from "./keys";

export const CommunicationLinkedToTypeTranslations: { [key in CommunicationLinkedToType]: string } = {
  [CommunicationLinkedToType.USER]: AppStrings.Common.Enums.CommunicationLinkedToType.USER,
  [CommunicationLinkedToType.TENANT]: AppStrings.Common.Enums.CommunicationLinkedToType.TENANT,
  [CommunicationLinkedToType.VENDOR]: AppStrings.Common.Enums.CommunicationLinkedToType.VENDOR,
  [CommunicationLinkedToType.OWNER]: AppStrings.Common.Enums.CommunicationLinkedToType.OWNER,
  [CommunicationLinkedToType.SYSTEM]: AppStrings.Common.Enums.CommunicationLinkedToType.SYSTEM
};
