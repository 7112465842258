import React, { useState } from "react";
import { CloseIcon, EditBlue, ResidentPortalIcon, ViewBlueIcon } from "assets/icons";
import type { PopoverItem } from "DLUI/popover";
import type { OwnerDto } from "@doorloop/dto";
import { ConversationLinkedToType, ObjectPermission } from "@doorloop/dto";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { NavigationManager } from "utils/navigation";
import { usePermission } from "screens/settings/userRoles/usePermission";
import { Routes } from "../../../appRouter";
import ListItemContainer from "DLUI/listItems/listItemContainer";
import { Grid, ListItemSection } from "DLUI/listItems";
import { ListItemInfoCell } from "DLUI/listItems/listItemInfoCell";
import type { SubscribedActions } from "@/hooks/useListBulkActionsManager";
import { ownersApi } from "api/ownersApi";
import ListItemContactSection from "DLUI/listItems/listItemContactSection";
import ListItemText from "DLUI/listItems/listItemText";
import { OwnerListItemPortalStatus } from "screens/owners/ownersList/ownerListItemPortalStatus";
import { propertiesApi } from "api/propertiesApi";
import _ from "lodash";
import { useEffectAsync } from "@/hooks/useEffectAsync";
import { authApi } from "api/authApi";
import { ListItemSubTitle } from "screens/tenants/listItemSubTitle";
import { useFocusElement } from "hooks/useFocusElement";
import { useDisableCta } from "screens/tenants/tenantDetails/useDisableCta";
import { sendWelcomeSmsAction } from "@/utils/bulkOperationsUtils";
import { useConfirmationDialog } from "@/hooks/useConfirmationDialog";
import { DataCy } from "@doorloop/dto";

interface ComponentProps {
  onItemPress?: (ownerId: string) => void;
  disableListItemOptions?: boolean;
  owner: OwnerDto;
  resourceId?: string;
}

const OwnersListItem: React.FC<ComponentProps> = ({
  owner,
  onItemPress,
  disableListItemOptions,
  resourceId
}: ComponentProps) => {
  const { showConfirmationDialog } = useConfirmationDialog();
  const [ownerData, setOwnerData] = useState<OwnerDto>(owner);
  const { jobTitle, properties, pictureUrl, name, id } = ownerData;
  const { actionDisableObj, updateActionObj } = useDisableCta();

  const { hasPermission } = usePermission();
  const location = useLocation<any>();
  const { t } = useTranslation();

  const [propertiesText, setPropertiesText] = useState<string>("--");
  const { handleFocusElement, elementRef } = useFocusElement();

  useEffectAsync(async (): Promise<void> => {
    await loadPropertiesText();
  }, []);

  const loadPropertiesText = async (): Promise<void> => {
    if (_.isEmpty(properties)) {
      return;
    }

    const propertiesDictionary = await propertiesApi.getDictionary();

    const propertiesNames =
      properties
        ?.map((propertyId) => {
          const property = propertiesDictionary[propertyId];

          if (!property) {
            return "";
          }

          return property.name;
        })
        .join(", ") || "--";

    setPropertiesText(propertiesNames);
  };

  const didPressViewProfile = hasPermission(ObjectPermission.owners, "viewOne")
    ? () => {
        if (id) {
          if (onItemPress) {
            onItemPress(id);
          } else {
            NavigationManager.viewOwnerDetails(id);
          }
        }
      }
    : undefined;

  const didPressEditButton = () => {
    if (id) {
      NavigationManager.editOwner(location.pathname, id);
    }
  };

  const didPressDeleteButton = () => {
    if (id) {
      showConfirmationDialog(location.pathname, {
        dialogTitle: AppStrings.Common.DeleteConfirmation,
        dialogSubTitle: AppStrings.Owners.Details.DeleteConfirmationText,
        loadingText: t(AppStrings.Owners.Details.DeleteLoadingText),
        successText: t(AppStrings.Owners.Details.DeleteOwnerSuccessText),
        confirmButtonText: AppStrings.Common.Delete,
        dismissButtonText: AppStrings.Common.Dismiss,
        apiMethod: "ownersApi",
        confirmOperation: "delete",
        itemId: id
      });
    }
  };

  const didPressLoginAsOwnerButton = async (): Promise<void> => {
    if (owner.id) {
      await authApi.getImpersonationTokenForOwnerAndOpenNewTabToLogin(owner.id);
    }
  };

  const cardActionButtons: PopoverItem[] = [
    {
      Icon: EditBlue,
      title: AppStrings.Common.Edit,
      onClick: didPressEditButton,
      clearance: { permission: ObjectPermission.owners, field: "edit" },
      dataCy: DataCy.DLUI.listItem.listItemEdit
    },
    {
      Icon: ViewBlueIcon,
      title: AppStrings.Tenants.Screen.ViewProfile,
      onClick: didPressViewProfile,
      clearance: { permission: ObjectPermission.owners, field: "viewOne" },
      dataCy: DataCy.DLUI.listItem.listItemView
    }
  ];

  if (owner.id) {
    cardActionButtons.push({
      Icon: ResidentPortalIcon,
      title: AppStrings.Owners.Screen.LoginToPortal,
      onClick: didPressLoginAsOwnerButton,
      clearance: { permission: ObjectPermission.owners, field: "edit" },
      dataCy: DataCy.DLUI.listItem.listItemLoginToPortal
    });
  }

  cardActionButtons.push({
    Icon: CloseIcon,
    title: AppStrings.Common.Delete,
    onClick: didPressDeleteButton,
    iconPathColor: "error",
    textColor: "error",
    clearance: { permission: ObjectPermission.owners, field: "delete" },
    dataCy: DataCy.DLUI.listItem.listItemDelete
  });

  const bulkActions: SubscribedActions | undefined = resourceId
    ? {
        activateOwnerPortal: {
          [resourceId]: {
            action: async () => {
              const result = await ownersApi.activateOwnerPortal({ ownerId: owner.id });
              if (!result.status) {
                throw result.message;
              }
              return { message: t(AppStrings.Common.InvitationSent) };
            },
            ongoingMessage: t(AppStrings.BulkActions.ActivateOwnerPortalOngoingMessage)
          }
        },
        deleteOwners: {
          [resourceId]: {
            action: async () => {
              const result = await ownersApi.delete(id!);
              if (!result.status) {
                throw result.message;
              }
              return { message: t(AppStrings.Common.OwnerDeleted) };
            },
            ongoingMessage: t(AppStrings.Owners.Details.DeleteLoadingText)
          }
        },
        sendOwnerWelcomeSms: {
          [resourceId]: {
            action: sendWelcomeSmsAction(ConversationLinkedToType.OWNER, id),
            ongoingMessage: t(AppStrings.BulkActions.SendWelcomeSmsOngoingMessage)
          }
        }
      }
    : undefined;

  const handleOwnerUpdated = (owner: OwnerDto) => {
    setOwnerData(owner);
  };

  return (
    <ListItemContainer
      resourceId={resourceId}
      bulkActions={bulkActions}
      popoverItems={disableListItemOptions ? undefined : cardActionButtons}
    >
      <Grid
        showDivider
        xs={12}
        sm={4}
        md={4}
        lg={4}
        sortColumn={"name"}
        onClick={didPressViewProfile}
        title={AppStrings.Owners.Screen.Owner}
        paddingLeft={20}
      >
        <ListItemInfoCell
          marginLeft={0}
          pictureUrl={pictureUrl}
          secondaryText={jobTitle}
          primaryText={name}
          href={id ? `${Routes.OWNERS}/${id}/overview` : undefined}
          showProfileImage
        />
      </Grid>

      <Grid
        showDivider
        xs={12}
        sm={3}
        md={3}
        lg={3}
        onClick={didPressViewProfile}
        title={AppStrings.DrawerItems.Properties}
        paddingRight={15}
        paddingLeft={15}
      >
        <ListItemText numberOfLines={1}>{propertiesText}</ListItemText>
        {Boolean((properties || []).length > 1) && (
          <ListItemText numberOfLines={1}>
            ({properties?.length} {t(AppStrings.DrawerItems.Rentals)})
          </ListItemText>
        )}
      </Grid>

      <Grid
        showDivider
        xs={12}
        sm={3}
        md={3}
        lg={3}
        title={AppStrings.Users.Screen.ContactInformation}
        paddingRight={15}
        paddingLeft={15}
      >
        <ListItemContactSection
          disableEdit={disableListItemOptions}
          onSaveSuccess={handleOwnerUpdated}
          dto={ownerData}
          api={ownersApi}
          shouldUpdatePortalInfo
          domRef={elementRef}
        />
      </Grid>

      <Grid title={AppStrings.Owners.Screen.OwnerPortal} xs={12} sm={2} md={2} lg={2} sortColumn="portalInfo.status">
        <ListItemSection
          renderTitle={
            <OwnerListItemPortalStatus
              owner={ownerData}
              onInviteSent={handleOwnerUpdated}
              onClickMissingContactInfo={handleFocusElement}
              actionDisableObj={actionDisableObj}
              onClickCta={updateActionObj}
            />
          }
          renderSubTitle={<ListItemSubTitle person={ownerData} onClick={didPressEditButton} />}
        />
      </Grid>
    </ListItemContainer>
  );
};

export default OwnersListItem;
