import React, { useMemo, useState } from "react";
import MomentUtils from "@date-io/moment";
import { AddressDto, FederalTaxInfoDto, TaxpayerIdType } from "@doorloop/dto";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FormikSwitchButton, RadioGroup, Select, TextField } from "DLUI/form";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, getIn } from "formik";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import AddressAutoCompleteFormWrapper from "DLUI/form/autoComplete/addressAutoComplete/addressAutoCompleteFormWrapper";

enum ServerBoolean {
  TRUE = "true",
  FALSE = "false"
}

interface AddressObject {
  addressFor1099: boolean;
  addressFor1099SameAsPrimaryAddress: boolean;
}

interface Props {
  formikRef: FormikProps<any>;
  strings: {
    send1099Forms: string;
    dontSend1099Forms: string;
    sameAsPrimaryAddress: string;
  };
}

const FederalTaxes: React.FC<Props> = ({ formikRef, strings }: Props) => {
  const { isTabletOrMobile, verticalInputPadding } = useResponsiveHelper();
  const { t } = useTranslation();
  const { addressFor1099, addressFor1099SameAsPrimaryAddress } = useMemo<AddressObject>(() => {
    const federalTaxInfo: FederalTaxInfoDto | undefined = getIn(formikRef.values, "federalTaxInfo");
    if (federalTaxInfo) {
      return {
        addressFor1099: federalTaxInfo.addressFor1099 !== undefined,
        addressFor1099SameAsPrimaryAddress: Boolean(federalTaxInfo.addressFor1099SameAsPrimaryAddress)
      };
    }
    return {
      addressFor1099: false,
      addressFor1099SameAsPrimaryAddress: false
    };
  }, []);

  const [shouldSend1099Forms, setShouldSend1099Forms] = useState<boolean>(
    addressFor1099 || addressFor1099SameAsPrimaryAddress
  );

  const hideAddressForm = useMemo(() => {
    const addressFor1099SameAsPrimaryAddress = getIn(
      formikRef.values,
      "federalTaxInfo.addressFor1099SameAsPrimaryAddress"
    );
    return Boolean(addressFor1099SameAsPrimaryAddress);
  }, [formikRef.values.federalTaxInfo?.addressFor1099SameAsPrimaryAddress]);

  const onSend1099RadioSelection = (selectedValue: string) => {
    const toggleOn = selectedValue === ServerBoolean.TRUE;
    if (toggleOn) {
      formikRef.setFieldValue("federalTaxInfo", new FederalTaxInfoDto());
      formikRef.setFieldValue("federalTaxInfo.addressFor1099SameAsPrimaryAddress", true);
    } else {
      formikRef.setFieldValue("federalTaxInfo", undefined);
    }
    setShouldSend1099Forms(toggleOn);
  };

  const onAddressAsVendorAddressSwitchChange = (nextSwitchState: boolean) => {
    if (!nextSwitchState) {
      formikRef.setFieldValue("federalTaxInfo.addressFor1099", nextSwitchState ? undefined : new AddressDto());
    }
    formikRef.setFieldValue("federalTaxInfo.addressFor1099SameAsPrimaryAddress", nextSwitchState);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <View flex={1} width={"100%"}>
        <View>
          <FastField
            uniqueKey={"LF"}
            component={RadioGroup}
            name={""}
            defaultValue={
              Boolean(addressFor1099) || Boolean(addressFor1099SameAsPrimaryAddress)
                ? ServerBoolean.TRUE
                : ServerBoolean.FALSE
            }
            didChangeSelection={onSend1099RadioSelection}
            radioGroupItems={[
              {
                label: strings.dontSend1099Forms,
                value: ServerBoolean.FALSE
              },
              {
                label: strings.send1099Forms,
                value: ServerBoolean.TRUE
              }
            ]}
          />
        </View>
        <View shouldShow={shouldSend1099Forms} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
          <View flexDirection={"row"} alignItems={"flex-start"}>
            <Grid item lg={4} xs={12} sm={12} md={4}>
              <View paddingRight={verticalInputPadding}>
                <FastField
                  component={TextField}
                  label={t(AppStrings.Vendors.NewVendor.TaxpayerName)}
                  name={`federalTaxInfo.taxpayerName`}
                  required
                  marginTop={20}
                />
              </View>
            </Grid>
            <Grid item lg={4} xs={12} sm={12} md={4}>
              <View paddingTop={isTabletOrMobile ? verticalInputPadding : 0}>
                <FastField
                  component={Select}
                  label={t(AppStrings.Vendors.NewVendor.TaxpayerIDType)}
                  name={`federalTaxInfo.taxpayerIdType`}
                  selectionEnum={TaxpayerIdType}
                  marginTop={20}
                  required
                />
              </View>
            </Grid>
            <Grid item lg={4} xs={12} sm={12} md={4}>
              <View paddingLeft={verticalInputPadding}>
                <FastField
                  component={TextField}
                  label={t(AppStrings.Vendors.NewVendor.TaxpayerID)}
                  name={`federalTaxInfo.taxpayerId`}
                  marginTop={20}
                  required
                />
              </View>
            </Grid>
          </View>
          <FastField
            component={FormikSwitchButton}
            name={"federalTaxInfo.addressFor1099SameAsPrimaryAddress"}
            label={strings.sameAsPrimaryAddress}
            marginTop={20}
            onChange={onAddressAsVendorAddressSwitchChange}
          />
          <View shouldShow={!hideAddressForm} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
            <AddressAutoCompleteFormWrapper formikName={"federalTaxInfo.addressFor1099"} />
          </View>
        </View>
      </View>
    </MuiPickersUtilsProvider>
  );
};

export default FederalTaxes;
