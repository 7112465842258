import React, { useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { TenantPortalBlackDownChevronIcon } from "assets/icons";
import DLButton from "DLUI/button/dlButton/dlButton";
import { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import { Popup } from "@/components/screens/calendar/popup/popup";
import { ColorContainer } from "screens/settings/tenantPortal/components/colorContainer";
import { useResponsiveHelper } from "contexts/responsiveContext";
import ColorsEnum from "utils/colorsEnum";
import { ColorPickerPopup } from "screens/settings/tenantPortal/components/colorPickerPopup";
import type { Theme } from "styles/defaultTheme";

const useStyles = makeStyles((theme: Theme) => {
  return {
    button: {
      marginLeft: "auto",
      backgroundColor: "transparent",
      padding: 8,
      border: `1px solid ${ColorsEnum.LightBlackFadedMore}`,
      "&:hover": {
        backgroundColor: theme.newPalette.secondary.states.hover
      }
    },
    buttonMobile: {
      height: 56
    },
    buttonDesktop: {
      height: 42
    }
  };
});

interface ColorPickerButtonProps {
  themeColor: string;
  onThemeColorChange: (selectedColor: string) => void;
  onOpenChanged?: (isOpen: boolean) => void;
}

export const ColorPickerButton = ({ themeColor, onThemeColorChange, onOpenChanged }: ColorPickerButtonProps) => {
  const classes = useStyles();
  const triggerRef = useRef<HTMLButtonElement>(null);
  const { isMobile } = useResponsiveHelper();

  const SelectedColorIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> = () => (
    <ColorContainer selected={true} color={themeColor} />
  );

  return (
    <>
      <DLButton
        ref={triggerRef}
        color={DLButtonColorsEnum.NEUTRAL}
        variant={DLButtonVariantsEnum.CONTAINED}
        disableMobileCollapse
        size={DLButtonSizesEnum.LARGE}
        containerClassName={clsx(classes.button, {
          [classes.buttonMobile]: isMobile,
          [classes.buttonDesktop]: !isMobile
        })}
        icons={{
          end: { src: TenantPortalBlackDownChevronIcon },
          start: { src: SelectedColorIcon, keepColor: true }
        }}
      />
      <Popup
        triggerRef={triggerRef}
        onOpenChanged={onOpenChanged}
        hideBackdrop={true}
        popoverProps={{
          transformOrigin: {
            vertical: -10,
            horizontal: "right"
          }
        }}
      >
        <ColorPickerPopup onThemeColorChange={onThemeColorChange} themeColor={themeColor} />
      </Popup>
    </>
  );
};
