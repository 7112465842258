const normalizePhoneNumber = (phoneNumber: string): string =>
  phoneNumber.replace(/^[+\d{1,3}\-\s]*\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, "$1$2$3");

export const formatPhoneNumber = (
  phoneNumber?: string,
  formatPattern = "NNN-NNN-NNNN",
  normalizeNumber = true
): string => {
  if (!phoneNumber) {
    return "";
  }

  const phoneNumberToFormat = normalizeNumber ? normalizePhoneNumber(phoneNumber.trim()) : phoneNumber;

  return formatPattern
    .split("")
    .reduce((acc, _char, index) => acc.replace("N", phoneNumberToFormat[index]), formatPattern);
};
