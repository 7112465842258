"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.map = exports.MAP_BREAK_SYMBOL = void 0;
const sleep_1 = require("./sleep");
exports.MAP_BREAK_SYMBOL = Symbol();
const map = (arr_1, promiseCallback_1, ...args_1) => __awaiter(void 0, [arr_1, promiseCallback_1, ...args_1], void 0, function* (arr, promiseCallback, { sleepBetween } = {}) {
    let index = 0;
    const result = [];
    for (const item of arr) {
        const returnValue = yield promiseCallback(item, index++);
        if (returnValue === exports.MAP_BREAK_SYMBOL) {
            break;
        }
        else {
            result.push(returnValue);
        }
        if (sleepBetween) {
            yield (0, sleep_1.sleep)(sleepBetween);
        }
    }
    return result;
});
exports.map = map;
