import type { OwnerDto } from "@doorloop/dto";
import { DataCy } from "@doorloop/dto";
import type { ApiResult } from "api/apiResult";
import { ownersApi } from "api/ownersApi";
import AppStrings from "locale/keys";
import React, { useEffect, useMemo, useState } from "react";
import { EntitySelector, EntityType } from "DLUI/dialogs/components/entitySelector";
import { useTranslation } from "react-i18next";

interface ComponentProps {
  ownerId?: string;
  didPressSelectOwner: () => void;
  errorTex?: string;
  onOwnerSelection?: (vendorId: string) => void;
}

const OwnerSelection: React.FC<ComponentProps> = ({
  ownerId,
  didPressSelectOwner,
  errorTex,
  onOwnerSelection
}: ComponentProps) => {
  const { t } = useTranslation();
  const [loadingInProgress, setLoadingInProgress] = useState<boolean>(false);
  const [selectedOwner, setSelectedOwner] = useState<OwnerDto | undefined>();

  const { ownerName, ownerInfo } = useMemo(() => {
    let ownerName = "";
    let ownerInfo = "";

    if (selectedOwner) {
      ownerName = selectedOwner.name || "";
      ownerInfo += selectedOwner.jobTitle || "";
      if (selectedOwner.jobTitle) {
        ownerInfo += ", ";
      }
      ownerInfo += selectedOwner.companyName || "";
    }
    return { ownerName, ownerInfo };
  }, [selectedOwner, ownerId]);

  const getOwner = async () => {
    if (ownerId) {
      setLoadingInProgress(true);
      const results = (await ownersApi.getDetails(ownerId).catch(() => {
        // in case of failure loading unit data just hide the loading indicator
        setLoadingInProgress(false);
      })) as ApiResult<any>;
      if (results && results.status) {
        setSelectedOwner(results.data);
        setLoadingInProgress(false);
        if (onOwnerSelection) {
          if (results.data && results.data) {
            onOwnerSelection(results.data.id);
          }
        }
      } else {
        setLoadingInProgress(false);
      }
    }
  };

  useEffect(() => {
    getOwner();
  }, [ownerId]);

  return (
    <EntitySelector
      type={EntityType.USER}
      label={t(AppStrings.Owners.Screen.Owner)}
      onClick={didPressSelectOwner}
      isLoading={loadingInProgress}
      valueText={ownerName}
      error={errorTex}
      dataCy={DataCy.entitySelector.owner}
    />
  );
};

export default OwnerSelection;
