import React, { useState } from "react";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import AppStrings from "../../../../locale/keys";
import CompanyStartDateForm from "screens/settings/companyStartDate/companyStartDateForm";
import { settingsApi } from "api/settingsApi";
import type { FormikProps } from "formik";
import { useFormikContext } from "formik";
import type { CompanyStartDateDto } from "@doorloop/dto";
import _ from "lodash";
import { store } from "store/index";
import { updateCompanyStartDate } from "store/auth/actions";
import { useTranslation } from "react-i18next";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";

interface ComponentProps {
  dialogTitle?: string;
  onClose: (values?: CompanyStartDateDto) => void;
  onBackdropClick: () => void;
}

enum DialogViews {
  LoadingView,
  FormView
}

const companyStartDialogFrameWidth = getDialogFrameDimension("width", 600);
const companyStartDialogFrameHeight = getDialogFrameDimension("height", 512);

const isValidForm = async ({ setFieldTouched, validateForm }: FormikProps<CompanyStartDateDto>) => {
  setFieldTouched("startDate");
  const errors = await validateForm();

  return _.isEmpty(errors);
};

const CompanyStartDate: React.FC<ComponentProps> = ({ dialogTitle, onClose, onBackdropClick }: ComponentProps) => {
  const { t } = useTranslation();
  const formikRef = useFormikContext<CompanyStartDateDto>();
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState("");
  const [loadingDialogState, setLoadingDialogState] = useState(DialogState.Hidden);
  const [viewIndex, setViewIndex] = useState<number>(DialogViews.FormView);
  const isLoadingView = viewIndex === DialogViews.LoadingView;
  const frameType = isLoadingView ? "contentOnly" : "sectionTitleFrame";

  const onError = ({ message }) => {
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(message || t(AppStrings.Common.GeneralError));
  };

  const didPressSaveButton = async () => {
    const isValid = await isValidForm(formikRef);

    if (isValid) {
      const { values } = formikRef;
      setLoadingDialogState(DialogState.Show);
      setViewIndex(DialogViews.LoadingView);
      const { data, status, message } = await settingsApi.updateCompanyStartDate(values);
      if (status && data) {
        values.startDate && store.dispatch(updateCompanyStartDate(values.startDate));
        onClose(values);
      } else {
        onError({ message });
      }
    }
  };

  const renderView = ({ index }) =>
    index === DialogViews.FormView ? (
      <CompanyStartDateForm />
    ) : (
      <LoadingDialog
        dialogState={loadingDialogState}
        errorText={loadingDialogErrorText}
        didPressDismissButton={onBackdropClick}
        onRetryButtonPress={didPressSaveButton}
        minHeight={companyStartDialogFrameHeight}
      />
    );

  const renderActionPanelButtons = () =>
    viewIndex === DialogViews.LoadingView ? null : (
      <FormActionButtons
        propsSubButton={{ onClick: onBackdropClick }}
        propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
      />
    );

  return (
    <DialogFrame
      onCloseButtonClick={onBackdropClick}
      title={dialogTitle}
      width={companyStartDialogFrameWidth}
      height={companyStartDialogFrameHeight}
      renderView={renderView}
      numViews={2}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={frameType}
      useExperimentalDialogFrame
    />
  );
};

export default CompanyStartDate;
