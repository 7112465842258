import type { FieldArrayRenderProps } from "formik";
import { useFormikContext } from "formik";
import type { RecurringTransactionDto } from "@doorloop/dto";
import { DuplicateDialogButtonLabelValues } from "@doorloop/dto";
import { v4 as uuid } from "uuid";
import { useAnalyticsService } from "hooks/useAnalyticsService";
import type { FC } from "react";
import _ from "lodash";
import { ListItemInputsContainer } from "DLUI/listItems";

interface DuplicateLineItemButtonProps {
  index: number;
  arrayHelpers: FieldArrayRenderProps;
}

export const RecurringRentLineItemWrapper: FC<DuplicateLineItemButtonProps> = ({ index, arrayHelpers, children }) => {
  const { dispatchAnalytics } = useAnalyticsService();
  const formik = useFormikContext<RecurringTransactionDto>();

  const handleDuplicateTransactionButtonClick = () => {
    const line = formik.values.leaseRentInfo?.lines?.[index];
    if (!line) {
      return;
    }
    arrayHelpers.insert(index + 1, {
      ...line,
      uniqueKey: uuid()
    });
    dispatchAnalytics("button_click", {
      label: DuplicateDialogButtonLabelValues.DUPLICATE_LINE_ITEM
    });
  };

  const handleDeleteLineItemClick = () => {
    const lines = formik.values.leaseRentInfo?.lines;
    if (_.size(lines) === 1) {
      return;
    }
    arrayHelpers.remove(index);
  };

  return (
    <ListItemInputsContainer
      isDeleteButtonDisabled={index === 0 && _.size(formik.values.leaseRentInfo?.lines) === 1}
      onClickDeleteButton={handleDeleteLineItemClick}
      onClickDuplicateButton={handleDuplicateTransactionButtonClick}
    >
      {children}
    </ListItemInputsContainer>
  );
};
