import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    tenantInfoContainer: {
      display: "flex",
      flexDirection: "row"
    },

    tenantPhotoSection: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      margin: "0px 0px 0px 20px",
      width: "100%"
    },

    tenantDetailsContainer: {
      display: "flex",
      flexDirection: "column",
      flex: 1
    },

    tenantDetailsRow: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "flex-start"
    },

    tenantDetailsInput: {
      width: "100%",
      margin: "0px 20px"
    },
    rowMargin: {
      marginTop: 20
    },
    notesContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%"
    },
    tenantDetailsMultiLineInput: {
      height: 100
    },
    selectField: {
      width: 80
    },
    phoneRow: {
      display: "flex",
      flexDirection: "row",
      marginLeft: 20
    },
    addressContainer: {
      display: "flex",
      flexDirection: "column"
    },
    addressFormContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      flex: 1
    },

    addAnotherButton: {
      margin: "20px 0px 0px 20px"
    },
    contactInfoTitle: {
      marginLeft: "20px",
      paddingTop: "20px"
    },
    emergencyContactWrapper: {
      width: "100%",
      margin: "0px 0px"
    },
    fullWidth: {
      width: "100%"
    },
    emergencyContactContainer: {
      display: "flex",
      flexDirection: "row",
      marginTop: "10px",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      marginLeft: 20
    },
    emergencyContactsItemsContainer: {
      width: "100%"
    },
    emergencyContactTextField: {
      margin: "0px 20px 0px 0px"
    },
    sectionHeaderText: {
      marginTop: 20
    },
    infoContainer: {
      flexDirection: "column",
      marginLeft: "20px"
    },
    contactInfoContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "flex-start",
      margin: "10px 0px",
      flex: 1
    },
    personInfoContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      flex: 1
    },
    contactInfoSectionContainer: {
      display: "flex",
      flexDirection: "column"
    },
    contactInfoTitleContainer: {
      display: "flex",
      height: "60px",
      width: "86%",
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "row"
    },
    separatorView: {
      height: "1px",
      width: "100%",
      backgroundImage: "linear-gradient(to right, rgb(234, 237, 243), rgb(234, 237, 243) 0%)",
      backgroundPosition: "center",
      backgroundSize: "1px 1px",
      backgroundRepeat: "repeat-x"
    },
    viewHeaderContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end"
    },
    tenantsListItemContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      border: "1px solid #EAEDF3",
      boxSizing: "border-box",
      borderRadius: "10px",
      backgroundColor: theme.palette.white.main,
      boxShadow: "0 1px 3px 0 rgba(0,0,0,0.04)",
      maxWidth: "375px"
    },
    darkContainer: {
      backgroundColor: theme.palette.screenBackground.light
    },
    tenantInfoSection: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    infoSectionSmall: {
      width: "50px",
      margin: "20px"
    },
    tenantStatusContainer: {
      margin: "10px 0px 0px 10px",
      height: "30px",
      width: "50px",
      display: "flex",
      alignItems: "center"
    },
    tenantAvatarContainer: {
      maxWidth: "110px"
    },
    tenantAvatar: {
      marginTop: 30
    },
    topMargin: {
      marginTop: "10px"
    },
    separator: {
      width: "84px",
      height: "1px",
      backgroundColor: theme.palette.gray.main,
      alignSelf: "center",
      marginTop: "10px"
    },
    skeletonInfoContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      flex: 1,
      alignItems: "center",
      margin: "10px 0px"
    },

    viewProfileContainer: {
      borderTopWidth: "1px",
      borderTopColor: theme.palette.gray.main,
      borderTopStyle: "solid",
      height: "60px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    tenantNameText: {
      whiteSpace: "nowrap"
    },
    contactInfoRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: "14px"
    },
    mailOutlineIcon: {
      color: theme.palette.gray.light
    },
    profileImage: {
      width: 60,
      height: 60,
      borderRadius: "100%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      border: "3px solid",
      borderColor: theme.palette.gray.dark,
      marginTop: 30
    },
    emergencyContactsItemContainer: {
      backgroundColor: theme.palette.screenBackground.light,
      borderRadius: 10
    },
    gridPaddingLeft: {
      paddingLeft: "20px"
    },
    gridContainer: {
      padding: "0px 20px"
    },
    gridVerticalPadding: {
      marginTop: "20px"
    }
  })
);
