import type { FC } from "react";
import React, { useCallback, useMemo } from "react";
import { Grid, ListItemContainer, ListItemIcon, ListItemSection } from "DLUI/listItems";
import { View } from "DLUI/view";
import type { CommunicationDto } from "@doorloop/dto";
import type { SVGIconComponent } from "assets/icons";
import {
  AnnouncementEmailIcon,
  AnnouncementPhoneIcon,
  AnnouncementSmsIcon,
  CalendarIcon,
  CloseIcon,
  ViewBlueIcon
} from "assets/icons";
import { CommunicationType } from "@doorloop/dto";
import { FromToSummary } from "screens/communications/communicationListItem/fromToSummary";
import { Icon } from "DLUI/icon";
import type { PopoverItem } from "DLUI/popover";
import { CommunicationStatus } from "screens/communications/communicationStatus";
import AppStrings from "locale/keys";
import { ObjectPermission } from "@doorloop/dto";
import { NavigationManager } from "utils/navigation";
import { useTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import { DateFormats } from "@doorloop/dto";
import { DateLabel } from "DLUI/date/dateLabel";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { usePermission } from "screens/settings/userRoles/usePermission";
import ListItemText from "DLUI/listItems/listItemText";
import { Routes } from "../../../appRouter";
import type { ShowConfirmationHandler } from "@/hooks/useConfirmationDialog";
import { useConfirmationDialog } from "@/hooks/useConfirmationDialog";

interface Props {
  dto: CommunicationDto;
}

const typeToIconMap: { [key in CommunicationType]: SVGIconComponent } = {
  [CommunicationType.EMAIL]: AnnouncementEmailIcon,
  [CommunicationType.TEXT]: AnnouncementSmsIcon,
  [CommunicationType.PHONE]: AnnouncementPhoneIcon
};

const viewOneCommunication: AnyPermissionClearance = {
  permission: ObjectPermission.communications,
  field: "viewOne"
};
const buildPopoverItems: (
  id: string,
  t: TFunction,
  showConfirmationDialog?: ShowConfirmationHandler
) => PopoverItem[] = (communicationsId, t, showConfirmationDialog) => [
  {
    Icon: ViewBlueIcon,
    title: AppStrings.Common.View,
    onClick: () => NavigationManager.viewCommunication(communicationsId),
    clearance: viewOneCommunication
  },
  {
    Icon: CloseIcon,
    title: AppStrings.Common.Delete,
    onClick: () => {
      showConfirmationDialog &&
        showConfirmationDialog(window.location.pathname, {
          dialogTitle: AppStrings.Common.DeleteConfirmation,
          dialogSubTitle: AppStrings.Announcements.deleteCommunicationConfirmationText,
          loadingText: t(AppStrings.Announcements.deleteCommunicationLoadingText),
          successText: t(AppStrings.Announcements.deleteCommunicationSuccessText),
          confirmButtonText: AppStrings.Common.Delete,
          dismissButtonText: AppStrings.Common.Dismiss,
          apiMethod: "communicationsApi",
          confirmOperation: "delete",
          itemId: communicationsId
        });
    },
    iconPathColor: "error",
    textColor: "error",
    clearance: { permission: ObjectPermission.communications, field: "delete" }
  }
];

export const CommunicationListItem: FC<Props> = ({ dto }: Props) => {
  const { t } = useTranslation();
  const { hasClearance } = usePermission();
  const { type, subject, id } = dto;
  const { showConfirmationDialog } = useConfirmationDialog();
  const viewCommunication = useCallback(() => id && NavigationManager.viewCommunication(id), [id]);
  const popoverItems = useMemo(() => (id ? buildPopoverItems(id, t, showConfirmationDialog) : []), [id]);
  if (!(type && subject)) {
    return null;
  }
  return (
    <ListItemContainer popoverItems={popoverItems}>
      <Grid
        title={AppStrings.Announcements.subject}
        showDivider
        xs={12}
        md={6}
        lg={6}
        onClick={hasClearance(viewOneCommunication) ? viewCommunication : undefined}
        sortColumn={"subject"}
      >
        <View flexDirection={"row"} alignItems={"center"}>
          <ListItemIcon Icon={typeToIconMap[type]} />
          <ListItemSection
            renderTitle={
              <ListItemText
                color={"black"}
                align="left"
                overflow={"ellipsis"}
                numberOfLines={2}
                fontWeight={700}
                href={id ? `${Routes.COMMUNICATIONS}/view/${id}` : undefined}
              >
                {subject}
              </ListItemText>
            }
            renderSubTitle={<FromToSummary dto={dto} />}
          />
        </View>
      </Grid>
      <Grid title={AppStrings.Announcements.sentAt} showDivider xs={12} md={3} lg={3} sortColumn={"sentAt"}>
        <View flexDirection={"row"} alignItems={"center"} noWrap>
          <Icon Source={CalendarIcon} width={18} height={18} marginRight={5} pathColor={"black"} />
          <DateLabel
            date={dto.sentAt}
            format={DateFormats.THREE_LETTER_MONTH_DATE_AND_TIME}
            textProps={{ color: "black", fontSize: 14 }}
          />
        </View>
      </Grid>
      <Grid title={AppStrings.Announcements.status} xs={12} md={2} lg={2} sortColumn={"status"}>
        <CommunicationStatus dto={dto} />
      </Grid>
    </ListItemContainer>
  );
};
