export default {
  SignatureRequests: "esignature.signatureRequests",
  SignatureRequestNewLeaseAgreementTitle: "esignature.signatureRequestNewLeaseAgreementTitle",
  SignatureRequestLeaseRenewalAgreementTitle: "esignature.signatureRequestLeaseRenewalAgreementTitle",
  NewSignatureRequest: "esignature.newSignatureRequest",
  OutForSignature: "esignature.outForSignature",
  Declined: "esignature.declined",
  Completed: "esignature.completed",
  Canceled: "esignature.canceled",
  EmptyDataViewInstructionsText: "esignature.emptyDataViewInstructionsText",
  GeneralSetting: "esignature.generalSetting",
  NameAndDescription: "esignature.nameAndDescription",
  RelatedRecords: "esignature.relatedRecords",
  Documents: "esignature.documents",
  Document: "esignature.document",
  Signers: "esignature.signers",
  ReviewAndConfirm: "esignature.reviewAndConfirm",
  SignatureRequestTitle: "esignature.signatureRequestTitle",
  SignatureTemplateTitle: "esignature.signatureTemplateTitle",
  SendRequestTestMode: "esignature.sendRequestTestMode",
  RelatedToDescription: "esignature.relatedToDescription",
  SelectProperty: "esignature.selectProperty",
  SelectUnit: "esignature.selectUnit",
  SelectLease: "esignature.selectLease",
  SelectDraftLease: "esignature.selectDraftLease",
  WhichDocumentsToIncluded: "esignature.whichDocumentsToIncluded",
  UploadFiles: "esignature.uploadFiles",
  UseTemplate: "esignature.useTemplate",
  SelectFileToContinue: "esignature.selectFileToContinue",
  WhoNeedsToSign: "esignature.whoNeedsToSign",
  AddNewSigner: "esignature.addNewSigner",
  AddNewSignerRole: "esignature.addNewSignerRole",
  AddNewSignerDescription: "esignature.addNewSignerDescription",
  MissingFilesValidationMessage: "esignature.missingFilesValidationMessage",
  MissingSignersValidationMessage: "esignature.missingSignersValidationMessage",
  MissingSignerRoleValidationMessage: "esignature.missingSignerRoleValidationMessage",
  ReviewAndConfirmDescription: "esignature.reviewAndConfirmDescription",
  ChargeAndCredit: "esignature.chargeAndCredit",
  CommunicationCreditNeeded: "esignature.communicationCreditNeeded",
  YourCurrentCredit: "esignature.yourCurrentCredit",
  CurrentCreditUsageDescription: "esignature.currentCreditUsageDescription",
  CurrentCreditUsageIncludedInPlanDescription: "esignature.currentCreditUsageIncludedInPlanDescription",
  HelloSignErrorDescription: "esignature.helloSignErrorDescription",
  ChargeForCreditsDescription: "esignature.chargeForCreditsDescription",
  SignersToSendDescription: "esignature.signersToSendDescription",
  SignatureRequestsSent: "esignature.signatureRequestsSent",
  SignatureRequestsSentDescription: "esignature.signatureRequestsSentDescription",
  DocumentTitle: "esignature.documentTitle",
  Download: "esignature.download",
  CancelRequest: "esignature.cancelRequest",
  PreviewSignersListDescription: "esignature.previewSignersListDescription",
  SendReminder: "esignature.sendReminder",
  LastReminderSentAt: "esignature.lastReminderSentAt",
  SignedAt: "esignature.signedAt",
  ViewedAt: "esignature.viewedAt",
  SentAt: "esignature.sentAt",
  Signed: "esignature.signed",
  SetSignerOrder: "esignature.setSignerOrder",
  DeleteSignatureRequestConfirmationText: "esignature.deleteSignatureRequestConfirmationText",
  DeleteSignatureTemplateConfirmationText: "esignature.deleteSignatureTemplateConfirmationText",
  CancelSignatureRequestConfirmationText: "esignature.cancelSignatureRequestConfirmationText",
  SignatureTemplates: "esignature.signatureTemplates",
  NewSignatureTemplates: "esignature.newSignatureTemplates",
  TemplatesEmptyDataViewInstructionsText: "esignature.templatesEmptyDataViewInstructionsText",
  SignatureRequest: "esignature.signatureRequest",
  SignatureRequestGeneralSettingsDescription: "esignature.signatureRequestGeneralSettingsDescription",
  SignatureTemplateGeneralSettingsDescription: "esignature.signatureTemplateGeneralSettingsDescription",
  WhichDocumentsToIncludedForTemplate: "esignature.whichDocumentsToIncludedForTemplate",
  DropzoneInstructions: "esignature.dropzoneInstructions",
  SignatureTemplateCreated: "esignature.signatureTemplateCreated",
  SignatureTemplateCreatedDescription: "esignature.signatureTemplateCreatedDescription",
  Templates: "esignature.templates",
  AddTemplate: "esignature.addTemplate",
  AddTemplateDescription: "esignature.addTemplateDescription",
  MissingTemplatesValidationMessage: "esignature.missingTemplatesValidationMessage",
  TemplateSignerRoles: "esignature.templateSignerRoles",
  TemplateSignerRolesDescription: "esignature.templateSignerRolesDescription",
  Credit: "esignature.credit",
  Credits: "esignature.credits",
  LearnMore: "esignature.learnMore",
  LearnMoreESigTemplate: "esignature.learnMoreESigTemplate",
  SelectSignerValidationMessage: "esignature.selectSignerValidationMessage",
  DuplicateEmailAddressValidationMessage: "esignature.duplicateEmailAddressValidationMessage",
  EditSignatureTemplate: "esignature.editSignatureTemplate",
  EditSignatureTemplateNoIdFoundMessage: "esignature.editSignatureTemplateNoIdFoundMessage",
  DuplicateSelectionErrorMessage: "esignature.duplicateSelectionErrorMessage",
  ESigCredit: "esignature.eSigCredit",
  HelpPanel: {
    SetupTemplate: "esignature.helpPanel.setupTemplate",
    CreateSignature: "esignature.helpPanel.createSignature",
    TemplateDescription: "esignature.helpPanel.templateDescription",
    TemplateDialogTitle: "esignature.helpPanel.templateDialogTitle",
    RequestDescription: "esignature.helpPanel.requestDescription",
    RequestDialogTitle: "esignature.helpPanel.requestDialogTitle"
  }
};
