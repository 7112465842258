import AppStrings from "locale/keys";
import { DialogRoutes } from "./dialogsRoutes";
import type { DialogProps } from "./dialogsProvider.types";
import { isEditFromDetailScreen } from "./dialogsProvider.utils";
import { Routes } from "components/appRouter";

class ProspectsDialogsProvider {
  matchRoutes(pathname: string): DialogProps | undefined {
    if (pathname.indexOf(DialogRoutes.EDIT_PROSPECT) > -1) {
      return {
        type: "newProspect",
        title: AppStrings.Prospects.NewProspect.EditProspect
      };
    }
    if (isEditFromDetailScreen("prospects", 2, 2, Routes.PROSPECT_DETAILS_EDIT, pathname)) {
      return {
        type: "newProspect",
        title: AppStrings.Prospects.NewProspect.EditProspect
      };
    }
    if (pathname.indexOf(DialogRoutes.NEW_PROSPECT) > -1) {
      return {
        type: "newProspect",
        title: AppStrings.Prospects.Screen.AddNewProspect
      };
    }

    return undefined;
  }
}

export const prospectsDialogsProvider = new ProspectsDialogsProvider();
