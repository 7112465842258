import type { ESignaturesGetEmbeddedUrlResponseDto } from "@doorloop/dto";
import { ServerRoutes } from "@doorloop/dto";
import { RestApiBase } from "./restApiBase";
import type { ESignatureTemplateDto } from "@doorloop/dto";
import type { GetAllESignatureTemplatesQuery } from "@doorloop/dto";
import { apiHelper } from "api/apiHelper";
import type { ApiResult } from "./apiResult";

class SignatureTemplatesApi extends RestApiBase<ESignatureTemplateDto, GetAllESignatureTemplatesQuery> {
  constructor() {
    super(ServerRoutes.ESIGNATURE_TEMPLATES, []);
    this.restRoute = ServerRoutes.ESIGNATURE_TEMPLATES;
  }

  getEmbeddedUrl = async (signatureTemplateId: string): Promise<ApiResult<ESignaturesGetEmbeddedUrlResponseDto>> =>
    await apiHelper.axiosGet<ESignaturesGetEmbeddedUrlResponseDto>(
      `${ServerRoutes.ESIGNATURE_TEMPLATES}/${signatureTemplateId}/embedded-url`
    );
}

export const signatureTemplatesApi = new SignatureTemplatesApi();
