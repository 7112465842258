import type { PropsWithChildren } from "react";
import React from "react";
import Text from "DLUI/text/text";
import View from "DLUI/view/view";
import { IconButton } from "DLUI/form";
import { DeleteIcon } from "assets/icons";

import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface DtoFieldArrayItemProps {
  onDeleteItem?: () => void;
  deleteDisabled?: boolean;
  title?: string;
}
export default React.forwardRef<HTMLDivElement, PropsWithChildren<DtoFieldArrayItemProps>>(
  ({ children, title, onDeleteItem, deleteDisabled }, ref) => {
    const { isMobile } = useResponsiveHelper();
    return (
      <View
        domRef={ref}
        paddingTop={15}
        paddingBottom={25}
        paddingRight={25}
        paddingLeft={25}
        alignItems={"center"}
        flexDirection={"row"}
        borderRadius={isMobile ? 0 : 30}
        backgroundColor={"dark"}
      >
        <Text value={title} bold fontSize={18} />
        <View alignItems={"flex-end"} flex={1}>
          <IconButton onClick={onDeleteItem} aria-label="delete" Icon={DeleteIcon} disabled={deleteDisabled} />
        </View>
        {children}
      </View>
    );
  }
);
