import Text from "DLUI/text";
import type { TextColor } from "DLUI/text/text";
import { View } from "DLUI/view";
import React from "react";
interface ComponentProps {
  amount?: number;
  label?: string;
  labelfontWeight?: number;
  amountTextColor?: TextColor;
}

const ApplicationSettingsSummaryLine: React.FC<ComponentProps> = ({
  label,
  amount,
  labelfontWeight,
  amountTextColor
}: ComponentProps) => (
  <View justifyContent={"space-between"} flexDirection={"row"} alignItems={"center"} height={50}>
    <Text value={label} fontWeight={labelfontWeight} fontSize={16} color={"black"} />
    <Text formatType={"currency"} value={String(amount)} fontSize={16} color={amountTextColor || "black"} />
  </View>
);

export default ApplicationSettingsSummaryLine;
