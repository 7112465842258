import _ from "lodash";

import type {
  CommunicationCenterCreditsDto,
  ConversationDto,
  ConversationMessageDto,
  ConversationParticipantDto,
  ConversationsStatsDto,
  CreateConversationDto,
  CreateConversationMessageDto,
  GetAllConversationMessagesQuery,
  GetAllConversationsQuery,
  LeaseTenantDto,
  PersonDto,
  UpdateConversationDto
} from "@doorloop/dto";
import { ConversationLinkedToType, type GetAllBaseQueryResponse, SearchCollections, ServerRoutes } from "@doorloop/dto";

import type { ApiToastsProps } from "api/apiHelper";
import { apiHelper } from "api/apiHelper";
import { ApiResult } from "api/apiResult";
import { conversationRecipientInputUtils } from "screens/communicationsCenter/shared/conversationRecipientInputUtils";
import { globalSearchApi } from "api/globalSearchApi";
import { leasesApi } from "api/leasesApi";
import { ownersApi } from "api/ownersApi";
import { propertiesApi } from "api/propertiesApi";
import { RestApiBase } from "api/restApiBase";
import { tagsApi } from "api/tagsApi";
import { tenantsApi } from "api/tenantsApi";
import { usersApi } from "api/usersApi";
import { vendorsApi } from "api/vendorsApi";

class ConversationsApi extends RestApiBase<ConversationDto, GetAllConversationsQuery> {
  constructor() {
    super(ServerRoutes.CONVERSATIONS, [vendorsApi, tenantsApi, ownersApi, propertiesApi, usersApi, tagsApi, leasesApi]);
  }

  async getStats(): Promise<ApiResult<ConversationsStatsDto>> {
    return await apiHelper.axiosGet<ConversationsStatsDto>(ServerRoutes.CONVERSATIONS_STATS);
  }

  async updateConversation(
    id: string,
    data: UpdateConversationDto,
    toasts?: ApiToastsProps<ConversationDto>
  ): Promise<ApiResult<ConversationDto>> {
    return await apiHelper.axiosPut<ConversationDto>({
      url: `${this.restRoute}/${id}`,
      data,
      toasts,
      shouldShowErrorToast: true
    });
  }

  async fixUnknownRecipient(
    id: string,
    data: ConversationParticipantDto,
    toasts?: ApiToastsProps<ConversationDto>
  ): Promise<ApiResult<ConversationDto>> {
    return await apiHelper.axiosPut({
      url: ServerRoutes.CONVERSATIONS_PUT_FIX_UNKNOWN_RECIPIENT.replace(":id", id),
      data,
      toasts,
      shouldShowErrorToast: true
    });
  }

  async searchRecipients(query: string): Promise<ApiResult<ConversationParticipantDto[]>> {
    const searchResults = await globalSearchApi.getresults({
      filter_text: query,
      filter_collections: [
        SearchCollections.PROPERTIES,
        SearchCollections.UNITS,
        SearchCollections.OWNERS,
        SearchCollections.PROSPECT_TENANTS,
        SearchCollections.LEASE_TENANTS,
        SearchCollections.VENDORS
      ],
      filter_relatedToPropertiesAndUnits: true
    });

    if (!searchResults.status || !searchResults.data) {
      return searchResults;
    }

    const recipients: ConversationParticipantDto[] = [];
    const searchResultKeys = Object.keys(searchResults.data);

    searchResultKeys.forEach((key) => {
      const collection: unknown[] = _.uniqBy(searchResults.data?.[key], "id");
      if (collection) {
        const linkedToType = conversationRecipientInputUtils.mapSearchCollectionToRecipientType(
          key.toUpperCase() as SearchCollections
        );

        if (linkedToType) {
          const recipientsCollection = collection.map((item) => {
            if (linkedToType === ConversationLinkedToType.TENANT) {
              item = (item as LeaseTenantDto).tenant;
            }

            return conversationRecipientInputUtils.mapPersonToConversationParticipant(item as PersonDto, linkedToType);
          });
          recipients.push(...recipientsCollection);
        }
      }
    });

    return new ApiResult<ConversationParticipantDto[]>(recipients);
  }

  async sendMessage(
    conversationId: string,
    dto: CreateConversationMessageDto
  ): Promise<ApiResult<ConversationMessageDto>> {
    return await apiHelper.axiosPost({
      url: ServerRoutes.CONVERSATIONS_MESSAGES.replace(":id", conversationId),
      data: dto,
      shouldShowErrorToast: true
    });
  }

  async createConversation(dto: CreateConversationDto): Promise<ApiResult<ConversationDto>> {
    return await apiHelper.axiosPost({ url: ServerRoutes.CONVERSATIONS, data: dto, shouldShowErrorToast: true });
  }

  async getConversationMessages(
    conversationId: string,
    dto: GetAllConversationMessagesQuery
  ): Promise<ApiResult<GetAllBaseQueryResponse<ConversationMessageDto>>> {
    return await apiHelper.axiosGet(ServerRoutes.CONVERSATIONS_MESSAGES.replace(":id", conversationId), dto);
  }

  async getRecipientConversation(
    recipient: ConversationParticipantDto
  ): Promise<ApiResult<GetAllBaseQueryResponse<ConversationDto>>> {
    const filter = conversationRecipientInputUtils.getAllConversationsQueryFilterByRecipient(recipient);

    return await this.getAll(filter);
  }

  async getCommunicationsCredits(): Promise<ApiResult<CommunicationCenterCreditsDto>> {
    return await apiHelper.axiosGet<CommunicationCenterCreditsDto>(ServerRoutes.COMMUNICATIONS_CENTER_CREDITS);
  }

  async purchaseCommunicationCredits(): Promise<ApiResult<CommunicationCenterCreditsDto>> {
    return await apiHelper.axiosPost({
      url: ServerRoutes.COMMUNICATIONS_CENTER_POST_PURCHASE_CREDITS,
      shouldShowErrorToast: true
    });
  }

  async getConversationHistoryPdf(id: string): Promise<ApiResult<Blob>> {
    return await apiHelper.axiosGet(
      ServerRoutes.CONVERSATIONS_PDF.replace(":id", id),
      {},
      {
        responseType: "blob"
      }
    );
  }
}

export const conversationsApi = new ConversationsApi();

class ConversationMessagesApi extends RestApiBase<ConversationMessageDto, GetAllConversationMessagesQuery> {
  constructor() {
    super(ServerRoutes.CONVERSATIONS_MESSAGES);
  }
}

export const conversationMessagesApi = new ConversationMessagesApi();
