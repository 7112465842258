import React from "react";
import makeStyles from "./styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { Grid } from "@material-ui/core";
import { View } from "DLUI/view";

const TransactionsReportSkeletonItem: React.FC<{}> = () => {
  const classes = makeStyles();
  return (
    <View className={classes.lineRowBorder} alignItems={"center"} height={80} flexDirection={"row"}>
      <Grid container justify={"flex-start"} style={{ paddingLeft: 20 }} item xs={2} sm={2} md={2} lg={2}>
        <Skeleton width={"50%"} variant="text" />
      </Grid>
      <Grid container justify={"flex-start"} style={{ paddingLeft: 20 }} item xs={2} sm={2} md={2} lg={2}>
        <Skeleton width={"50%"} variant="text" />
      </Grid>
      <Grid container justify={"flex-start"} style={{ paddingLeft: 20 }} item xs={2} sm={2} md={2} lg={2}>
        <Skeleton width={"50%"} variant="text" />
      </Grid>
      <Grid container justify={"flex-start"} style={{ paddingLeft: 20 }} item xs={2} sm={2} md={2} lg={2}>
        <Skeleton width={"50%"} variant="text" />
      </Grid>
      <Grid container justify={"flex-start"} style={{ paddingLeft: 20 }} item xs={2} sm={2} md={2} lg={2}>
        <Skeleton width={"50%"} variant="text" />
      </Grid>
      <Grid container justify={"flex-start"} style={{ paddingLeft: 20 }} item xs={2} sm={2} md={2} lg={2}>
        <Skeleton width={"50%"} variant="text" />
      </Grid>
    </View>
  );
};

export default TransactionsReportSkeletonItem;
