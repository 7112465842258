import type { ScreenProps } from "@/components/DLUI/screen/screen";
import { useCallback, useState } from "react";
import { useEffectOnce } from "./useEffectOnce";
import { useSearchParamsSetter } from "./useSearchParamsSetter";

interface UseScreenFilterObjectReturnOptions<TQuery> {
  transform?: (query: TQuery) => TQuery;
  keysToAddToInitialQuery?: Array<keyof TQuery>;
}

interface UseScreenFilterObjectReturn<TQuery> {
  filterObject: TQuery;
  screenProps: {
    initialFilterSelection: NonNullable<ScreenProps["initialFilterSelection"]>;
    didChangeFilterOptions: NonNullable<ScreenProps["didChangeFilterOptions"]>;
  };
}

export function useScreenFilterObject<TQuery = Record<string, never>>(
  initialQuery: TQuery = {} as TQuery,
  consistentKeys: Array<keyof TQuery> | "all" | "none" = "all",
  { keysToAddToInitialQuery, transform }: UseScreenFilterObjectReturnOptions<TQuery> = {}
): UseScreenFilterObjectReturn<TQuery> {
  const [filterObject, setFilterObject] = useState<TQuery>(initialQuery);
  const transformWrap = useCallback<NonNullable<UseScreenFilterObjectReturnOptions<TQuery>["transform"]>>(
    (query) => {
      if (transform) {
        return transform(query);
      }

      return query;
    },
    [transform]
  );

  const searchParamsSetter = useSearchParamsSetter();
  useEffectOnce(() => {
    if (!keysToAddToInitialQuery || keysToAddToInitialQuery.length === 0) {
      return;
    }

    searchParamsSetter((searchParams) => {
      keysToAddToInitialQuery.forEach((key) => {
        if (initialQuery[key] !== undefined && !searchParams.has(key as string)) {
          searchParams.set(key as string, initialQuery[key] as string);
        }
      });

      return searchParams;
    }, "replace");
  });

  const handleFilterObjectChange = useCallback<
    NonNullable<UseScreenFilterObjectReturn<TQuery>["screenProps"]["didChangeFilterOptions"]>
  >(
    (newFilterObject) => {
      if (!consistentKeys || consistentKeys === "none" || !initialQuery || consistentKeys.length === 0) {
        setFilterObject(transformWrap(newFilterObject as TQuery));
        return;
      }

      if (consistentKeys === "all") {
        setFilterObject(
          transformWrap({
            ...initialQuery,
            ...newFilterObject
          })
        );
        return;
      }

      const consistentKeysObject = Object.keys(initialQuery).reduce((acc, key) => {
        if (!consistentKeys.includes(key as keyof TQuery & string)) {
          return acc;
        }

        acc[key] = initialQuery[key];
        return acc;
      }, {});

      setFilterObject(
        transformWrap({
          ...consistentKeysObject,
          ...newFilterObject
        } as TQuery)
      );
    },
    [initialQuery, consistentKeys, transformWrap]
  );

  return {
    filterObject,
    screenProps: {
      didChangeFilterOptions: handleFilterObjectChange,
      initialFilterSelection: initialQuery as object
    }
  };
}
