import type { CSSProperties } from "react";
import React from "react";
import type { FieldArrayRenderProps } from "formik";
import { getIn, useFormikContext } from "formik";
import { IconButton } from "DLUI/form";
import type { AccountDto, GetAllAccountsQuery } from "@doorloop/dto";
import { DataCy } from "@doorloop/dto";
import { AccountClass } from "@doorloop/dto";
import { TrashIcon } from "../../../../../assets";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useReadOnlyContext } from "@/contexts/readOnlyContext";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import { useManagementFeesStyles } from "screens/settings/managementFees/useStyles";
import clsx from "clsx";
import { useGeneralStyles } from "styles/generalStyles";
import { useResponsiveHelper } from "contexts/responsiveContext";

interface Props {
  itemIndex: number;
  arrayHelpers: FieldArrayRenderProps;
  fieldName: string;
  property?: string;
}

type RequiredPaddingCSSProperties = Omit<CSSProperties, "padding"> & { padding: number };

const BUTTON_STYLE: RequiredPaddingCSSProperties = { padding: 4 };

const INCOME_QUERY_PARAMS: GetAllAccountsQuery = {
  filter_classes: [AccountClass.REVENUE, AccountClass.EXPENSE],
  filter_active: true
};

const PayoutManagementAccountsListItem: React.FC<Props> = ({ itemIndex, arrayHelpers, fieldName, property }: Props) => {
  const isReadOnlyContext = useReadOnlyContext();
  const { isMobile } = useResponsiveHelper();
  const trashIconSize = isMobile ? 24 : 20;
  const trashIconContainerSize = trashIconSize + BUTTON_STYLE.padding * 2;
  const params = useParams<any>();
  const propertyId = property || params?.propertyId;
  const classes = useManagementFeesStyles({ trashIconContainerSize });
  const generalClasses = useGeneralStyles();
  const formikRef = useFormikContext();
  const managementAccountsFieldName = propertyId ? "fees.managementAccounts" : "managementAccounts";
  const { t } = useTranslation();

  const handleDeleteClick = () => {
    arrayHelpers.remove(itemIndex);
  };

  const handleAccountSelect = (_, value?: AccountDto) => {
    if (!value) return;
    const lines = getIn(formikRef.values, managementAccountsFieldName) || [];
    if (lines && lines[itemIndex]) {
      lines[itemIndex] = value.id;
    } else {
      lines.push(value.id);
    }
    formikRef.setFieldValue(managementAccountsFieldName, lines);
  };

  return (
    <div
      className={clsx(
        generalClasses.smallGap,
        generalClasses.flexRow,
        generalClasses.fullWidth,
        generalClasses.verticalAlign
      )}
      data-cy={DataCy.globalSettings.managementFees.payoutAccounts.accountRow}
    >
      <div className={generalClasses.basicFlex}>
        <BankAccountFormikAutoCompleteField
          addCreateOption
          uniqueIndex={"TS"}
          name={`${fieldName}.incomeAccount`}
          queryParams={INCOME_QUERY_PARAMS}
          label={t("settings.generalSettings.managementFees.selectIncomeAccount")}
          onChange={handleAccountSelect}
          dataCy={DataCy.globalSettings.managementFees.payoutAccounts.payoutAccountSelect}
          defaultValue={getIn(formikRef.values, `${fieldName}.incomeAccount`)}
        />
      </div>
      {arrayHelpers && !isReadOnlyContext && (
        <div className={classes.deleteIcon}>
          <IconButton
            onClick={handleDeleteClick}
            Icon={TrashIcon}
            pathColor={"light-gray"}
            disableRipple
            style={BUTTON_STYLE}
            size={trashIconSize}
            dataCy={DataCy.globalSettings.managementFees.payoutAccounts.removeAccountButton}
          />
        </div>
      )}
    </div>
  );
};

export default PayoutManagementAccountsListItem;
