import { ListItemContainerMaxWidth } from "DLUI/infiniteList/utils";
import React, { Fragment, useState } from "react";
import type { VendorDto } from "@doorloop/dto";
import { AnimateMarginTop } from "DLUI/animatableView";
import { View } from "DLUI/view";
import VendorsListItem from "screens/vendors/vendorsList/vendorsListItem";
import { NavigationManager } from "utils/navigation";
import { StickyHeader } from "DLUI/stickyHeader";

import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import type { ResultsSearchTabComponentProps } from "DLUI/screen/globalSearch/types";
import { globalSearchContstants } from "DLUI/screen/globalSearch/globalSearch.constants";
import { useTimeout } from "../../../../hooks/useTimeout";

const VendorsTab: React.FC<ResultsSearchTabComponentProps<VendorDto>> = ({ listItems, handleItemClicked }) => {
  const [shouldRender, setShouldRender] = useState(false);
  const { isMobile } = useResponsiveHelper();

  useTimeout(() => {
    setShouldRender(true);
  }, globalSearchContstants.tabsDisplayDelay);

  const items = listItems.map((currentItem: VendorDto, index) => {
    const didPressVendorItem = (vendorId: string) => {
      handleItemClicked("Vendors");

      setTimeout(() => {
        NavigationManager.viewVendorDetails(vendorId);
      }, globalSearchContstants.resultsItemHideDelay);
    };

    return (
      <Fragment key={"VSI" + index}>
        <VendorsListItem
          onItemPress={didPressVendorItem}
          disableListItemOptions
          categoriesText={""}
          vendor={currentItem}
        />
      </Fragment>
    );
  });

  return (
    <View
      alignItems={"center"}
      overflow={"scroll"}
      height={`calc(100vh - ${globalSearchContstants.searchPanelHeight}px)`}
    >
      {shouldRender && (
        <View maxWidth={ListItemContainerMaxWidth} style={{ display: "block" }}>
          {!isMobile && <StickyHeader id={"searchVendorsTab"} shouldShow marginTop={20} />}
          {isMobile && <AnimateMarginTop marginTop={20} />}
          <View id={"searchVendorsTab"}>
            {items}
            <View height={60} />
          </View>
        </View>
      )}
    </View>
  );
};

export default VendorsTab;
