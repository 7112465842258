import React, { useMemo, useState } from "react";

import _ from "lodash";
import { FastField, getIn, useFormikContext } from "formik";

import AppStrings from "locale/keys";
import { PortalContactInfoForm } from "DLUI/settings/portal/portalContactInfoForm";
import { RadioGroup } from "DLUI/form";

type ContactInfoSelectionType = "companyDefault" | "specifyContactInfo";

export const ContactInfo: React.FC = () => {
  const formik = useFormikContext();
  const [renderForm, setRenderForm] = useState<boolean>(true);

  const contactInfoSelectionDefaultValue = useMemo((): ContactInfoSelectionType => {
    const fieldValue = getIn(formik.values, "contactInfo");

    if (_.isEmpty(fieldValue)) {
      return "companyDefault";
    }
    return "specifyContactInfo";
  }, []);

  const [contactInfoSelectionType, setContactInfoSelectionType] = useState<ContactInfoSelectionType>(
    contactInfoSelectionDefaultValue
  );

  const refreshForm = (): void => {
    setRenderForm(false);

    formik.setFieldValue("contactInfo", undefined);

    setTimeout(() => {
      setRenderForm(true);
    }, 0);
  };

  const didChangeContactInfoSelection = (selectedValue: ContactInfoSelectionType): void => {
    setContactInfoSelectionType(selectedValue);

    if (selectedValue === "companyDefault") {
      setTimeout(() => {
        refreshForm();
      }, 500);
    }
  };

  const renderSpecifyRadioButtons = () => (
    <FastField
      uniqueKey={"LF"}
      component={RadioGroup}
      name={"automaticLateFees"}
      defaultValue={contactInfoSelectionType}
      didChangeSelection={didChangeContactInfoSelection}
      radioGroupItems={[
        {
          label: AppStrings.Common.HideOwnerPortalContactInfo,
          value: "companyDefault"
        },
        {
          label: AppStrings.Common.SpecifyOwnerPortalContactInfo,
          value: "specifyContactInfo"
        }
      ]}
      marginTop={10}
    />
  );

  return (
    <PortalContactInfoForm
      textTitle={AppStrings.Common.OwnerPortalContactInfoDescription}
      shouldShow={contactInfoSelectionType === "specifyContactInfo"}
      renderForm={renderForm}
      renderSpecifyRadioButtons={renderSpecifyRadioButtons}
    />
  );
};
