import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      "&:hover": {
        backgroundColor: theme.palette.screenBackground.light
      },
      backgroundColor: theme.palette.white.main,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "20px",
      borderWidth: 0.5,
      borderColor: theme.palette.gray.dark,
      borderStyle: "solid"
    },
    itemSelected: {
      borderColor: theme.palette.pink.main
    },
    buttonLabelContainer: {
      display: "flex",
      flexDirection: "column",
      textTransform: "none"
    },
    buttonLabel: {
      marginTop: 10
    },
    buttonGroupContainer: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      "& .MuiButton-root:hover": {
        backgroundColor: theme.palette.screenBackground.light
      }
    },
    buttonGroupItems: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      flexWrap: "wrap",

      "&.image-button-grid": {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, 135px)",
        width: "100%",
        gridGap: "20px",
        paddingTop: "20px",

        "& > div": {
          margin: "0 !important"
        }
      }
    },
    errorText: {},
    checkmarkIcon: {
      position: "absolute",
      top: 12,
      right: 12,
      maxWidth: 40,
      maxHeight: 40
    }
  })
);
