import type {
  AcceptInviteCheckLoginRequestDto,
  AcceptInviteExistingLoginDto,
  AcceptInviteNewLoginDto,
  AcceptInviteType,
  ApiError,
  ChangeMfaConfirmDto,
  ChangeMfaDto,
  DoorLoopLeadDto,
  DoorLoopLeadResponseDto,
  ForgotPasswordConfirmDto,
  ForgotPasswordRequestDto,
  GetLoginThemeBySubdomainRequest,
  LoginDto,
  LoginResponseDto,
  LoginThemeDto,
  OtpAuthDto,
  SignupConfirmDto,
  SignupDto,
  SSOProvidersEnum,
  ValidatePasswordDto,
  ValidatePasswordResponseDto
} from "@doorloop/dto";
import {
  AuthResponseDto,
  LoginResponseType,
  LoginWithOwnerPortalImpersonationTokenDto,
  LoginWithTenantPortalImpersonationTokenDto,
  SegmentEventTypes,
  ServerRoutes
} from "@doorloop/dto";
import { Routes } from "components/appRouter";
import * as authActions from "store/auth/actions";
import { history } from "store/history";
import { store } from "../store";
import { apiHelper } from "./apiHelper";
import type { ApiRedirectResult } from "./apiResult";
import { ApiResult } from "./apiResult";
import { tenantsApi } from "api/tenantsApi";
import { analyticsService } from "../services/analyticsService";
import { pushService } from "../services/pushService";
import qs from "query-string";
import _ from "lodash";
import { getIsOAuth2Login } from "../components/screens/authScreen/login/utils";
import { ownersApi } from "api/ownersApi";
import { getPortalHost } from "../utils/getPortalHost";

interface OAuth2LoginParams {
  email: string;
  password?: string;
  tokenId?: string;
}

class AuthApi {
  isSSOAuthenticating = false;
  logout = async (pushToLogin = true): Promise<boolean> => {
    this.logoutAnalytics();

    await apiHelper.axiosPost({ url: ServerRoutes.AUTH_POST_LOGOUT });

    await apiHelper.deleteAuthCookies();

    pushService.stop();

    store.dispatch(authActions.logout());

    this.clearDictionaries();

    pushToLogin && history.push(Routes.LOGIN);

    return true;
  };

  logoutAnalytics = () => {
    analyticsService.logout();
  };

  clearDictionaries = () => {
    try {
      if (window.sessionStorage) {
        const keys = Object.keys(window.sessionStorage).filter((x) => x.startsWith("/api"));
        for (const key of keys) {
          window.sessionStorage.removeItem(key);
        }
      }
    } catch (e) {}
  };

  resetPasswordConfirm = async (
    request: ForgotPasswordConfirmDto
  ): Promise<ApiResult<LoginResponseDto | ApiRedirectResult>> => {
    const res = await apiHelper.axiosPost<LoginResponseDto[]>({
      url: ServerRoutes.AUTH_POST_FORGOT_PASSWORD_CONFIRM,
      data: request
    });
    if (res.status && res.data) {
      // if user finished the welcome wizard we should return a redirect and then logout
      if (res.data.length === 1 && res.data[0].currentDbTenant.isWelcomeWizardComplete) {
        return new ApiResult<LoginResponseDto>(res.data[0]);
      }
      return await this.handleLoginResponses(res.data);
    }
    return new ApiResult<LoginResponseDto>(undefined, res.message);
  };

  async validatePassword(data: ValidatePasswordDto): Promise<ApiResult<ValidatePasswordResponseDto>> {
    return await apiHelper.axiosPost<ValidatePasswordResponseDto>({
      url: ServerRoutes.AUTH_POST_VALIDATE_PASSWORD,
      data
    });
  }

  async changeMfa(data: ChangeMfaDto): Promise<ApiResult<unknown>> {
    return await apiHelper.axiosPost<unknown>({ url: ServerRoutes.AUTH_POST_CHANGE_MFA, data });
  }

  async changeMfaConfirm(data: ChangeMfaConfirmDto): Promise<ApiResult<unknown>> {
    return await apiHelper.axiosPost<unknown>({ url: ServerRoutes.AUTH_POST_CHANGE_MFA_CONFIRM, data });
  }

  resendEmailConfirmation = async () => {
    const res = await apiHelper.axiosPost({ url: ServerRoutes.AUTH_POST_RESEND_CONFIRMATION_EMAIL });
    return Boolean(res.status);
  };

  resetPasswordRequest = async (request: ForgotPasswordRequestDto): Promise<ApiResult<boolean>> => {
    const res = await apiHelper.axiosPost<ApiError<boolean>>({
      url: ServerRoutes.AUTH_POST_FORGOT_PASSWORD_REQUEST,
      data: request
    });
    if (res.status) {
      // success
      return new ApiResult<boolean>(true);
    }
    return new ApiResult<boolean>(false, res.message);
  };

  getRedirectPathForLoginResponse(loginResponse?: LoginResponseDto, redirectPath?: string) {
    if (!redirectPath) {
      const urlParams = new URLSearchParams(window.location.search);

      let returnPath: string | undefined | null = urlParams.get("returnPath");

      if (
        loginResponse?.type === LoginResponseType.USER &&
        returnPath &&
        (returnPath.includes(Routes.TENANT_PORTAL_BASE_URL) || returnPath.includes(Routes.OWNER_PORTAL_BASE_URL))
      ) {
        returnPath = undefined;
      } else if (
        loginResponse?.type === LoginResponseType.TENANT &&
        returnPath &&
        !returnPath.includes(Routes.TENANT_PORTAL_BASE_URL)
      ) {
        returnPath = undefined;
      } else if (
        loginResponse?.type === LoginResponseType.OWNER &&
        returnPath &&
        !returnPath.includes(Routes.OWNER_PORTAL_BASE_URL)
      ) {
        returnPath = undefined;
      } else if (
        loginResponse?.type === LoginResponseType.PROSPECT &&
        returnPath &&
        !returnPath.includes(Routes.TENANT_PORTAL_RENTAL_APPLICATION)
      ) {
        returnPath = undefined;
      }

      if (returnPath) {
        redirectPath = returnPath;
      } else if (loginResponse) {
        switch (loginResponse.type) {
          case LoginResponseType.TENANT:
            redirectPath = `${Routes.TENANT_PORTAL_BASE_URL}/${loginResponse.currentLease?.id}/home`;
            break;
          case LoginResponseType.OWNER:
            redirectPath = Routes.OWNER_PORTAL_HOME;
            break;
          case LoginResponseType.PROSPECT:
            redirectPath = Routes.TENANT_PORTAL_RENTAL_APPLICATION + location.search;
            break;
          case LoginResponseType.USER:
            if (!loginResponse.currentDbTenant.isWelcomeWizardComplete) {
              redirectPath = Routes.WELCOME_WIZARD_STEP3;
            } else if (loginResponse.showOnBoardingOnLogin) {
              redirectPath = Routes.ONBOARDING;
            } else {
              redirectPath = Routes.HOME;
            }
            break;
        }
      }
    }

    if (loginResponse) {
      analyticsService.identify(loginResponse);
      analyticsService.track(
        SegmentEventTypes.LOGGED_IN,
        {
          id: loginResponse.id
        },
        { trackEventInIntercom: true }
      );
    }

    return redirectPath;
  }

  redirectAfterLogin = async (loginResponses: LoginResponseDto[], overrideDefaultRedirectPath?: string) => {
    const currentSubdomain = (
      window.location.host.split(".")[1] ? window.location.host.split(".")[0] : ""
    ).toLowerCase();
    if (loginResponses.length === 1) {
      // user has only one account. check if we need to redirect to a different subdomain
      const oneLoginResponse = loginResponses[0];

      const environmentSubdomain = apiHelper.getEnvironmentSubdomain();
      if (currentSubdomain === environmentSubdomain) {
        // need to redirect to subdomain
        const redirectUrl =
          window.location.protocol +
          "//" +
          oneLoginResponse.currentDbTenant.subdomain +
          "." +
          window.location.host +
          this.getRedirectPathForLoginResponse(oneLoginResponse, overrideDefaultRedirectPath);
        return new ApiResult<ApiRedirectResult>({ redirectUrl }, "Redirecting...", 200);
      }
      // we are already on a subdomain, does it match the login response ?
      if (currentSubdomain === oneLoginResponse.currentDbTenant.subdomain.toLowerCase()) {
        // we are all set and good to go, dispatch login
        store.dispatch(
          authActions.loginSuccess(
            new AuthResponseDto({
              currentLoginResponse: oneLoginResponse
            })
          )
        );

        const redirectUrl =
          window.location.protocol +
          "//" +
          window.location.host +
          this.getRedirectPathForLoginResponse(oneLoginResponse, overrideDefaultRedirectPath);

        return new ApiResult<ApiRedirectResult>({ redirectUrl }, "Redirecting...", 200);
      }
      const redirectUrl =
        window.location.protocol +
        "//" +
        oneLoginResponse.currentDbTenant.subdomain.toLowerCase() +
        "." +
        document.location.host.substr(document.location.host.toLowerCase().indexOf(environmentSubdomain)) +
        this.getRedirectPathForLoginResponse(oneLoginResponse, overrideDefaultRedirectPath);
      return new ApiResult<ApiRedirectResult>({ redirectUrl }, "Redirecting...", 200);
    }
    // more than one subdomain, maybe we are already on a matching subdomain?
    const matchingLoginResponses = loginResponses.filter(
      (x) => x.currentDbTenant.subdomain.toLowerCase() === currentSubdomain
    );
    if (matchingLoginResponses && matchingLoginResponses.length === 1) {
      // yes we are, let's log in!

      const loginResponse = matchingLoginResponses[0];

      store.dispatch(
        authActions.loginSuccess(
          new AuthResponseDto({
            currentLoginResponse: loginResponse
          })
        )
      );
      const redirectUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        this.getRedirectPathForLoginResponse(loginResponse, overrideDefaultRedirectPath);

      return new ApiResult<ApiRedirectResult>({ redirectUrl }, "Redirecting...", 200);
    }
    // we need to have the user select which account to log into, let's redirect to selection screen
    let redirectUrl = apiHelper.getHostURL() + Routes.SELECT_ACCOUNT;

    if (getIsOAuth2Login()) {
      // maintain the query for oauth2
      redirectUrl += window.location.search;
    } else {
      redirectUrl +=
        "?" +
        apiHelper.getReturnPathQueryParamName() +
        "=" +
        encodeURIComponent(this.getRedirectPathForLoginResponse(undefined, overrideDefaultRedirectPath) || "");
    }

    return new ApiResult<ApiRedirectResult>({ redirectUrl }, "Redirecting...", 200);
  };

  getImpersonationTokenForTenantAndLeaseAndOpenNewTabToLogin = async (tenantId: string, leaseId: string) => {
    analyticsService.track(SegmentEventTypes.USER_CLICKED_TENANT_PORTAL_LOGIN);

    const token = await tenantsApi.getTenantPortalImpersonationToken({
      tenantId,
      leaseId
    });
    if (token?.status && token?.data) {
      const host = getPortalHost();

      const url =
        window.location.protocol +
        "//" +
        host +
        Routes.LOGIN +
        "?&tenant_portal_impersonation_token=" +
        encodeURIComponent(token.data.tenant_portal_impersonation_token);
      window.open(url);
    }
  };

  loginWithTenantPortalImpersonationToken = async (tenant_portal_impersonation_token: string) => {
    const res = await apiHelper.axiosPost<LoginResponseDto[]>({
      url: ServerRoutes.AUTH_POST_LOGIN_TENANT_PORTAL_IMPERSONATION_TOKEN,
      data: new LoginWithTenantPortalImpersonationTokenDto({
        token: tenant_portal_impersonation_token
      })
    });

    if (res.status) {
      const loginResponses = res.data;
      return await this.handleLoginResponses(loginResponses, false);
    }
    return new ApiResult<ApiRedirectResult>(undefined, res.message, res.statusCode);
  };

  loginWithOAuth2 = async ({ email, password, tokenId }: OAuth2LoginParams) => {
    const queryString = qs.parse(window.location.search);
    const response = await apiHelper.axiosPost({
      url: ServerRoutes.OAUTH_AUTHORIZE,
      data: {
        email,
        password,
        client_id: queryString.client_id,
        redirect_uri: queryString.redirect_uri,
        scope: queryString.scope,
        state: queryString.state,
        response_type: queryString.response_type
      },
      tokenId
    });

    const redirectUrl = response.data?.redirectUrl;

    if (redirectUrl) {
      return new ApiResult<ApiRedirectResult>({ redirectUrl }, "Redirecting...", 200);
    }
    return new ApiResult<ApiRedirectResult>(undefined, response.message, response.statusCode);
  };

  async getImpersonationTokenForOwnerAndOpenNewTabToLogin(ownerId: string) {
    analyticsService.track(SegmentEventTypes.USER_CLICKED_OWNER_PORTAL_LOGIN);

    const { status, data } = await ownersApi.getOwnerPortalImpersonationToken({
      ownerId
    });

    const host = getPortalHost();

    if (status && data) {
      const url = `${window.location.protocol}//${host}${
        Routes.LOGIN
      }?ownerPortalImpersonationToken=${encodeURIComponent(data.ownerPortalImpersonationToken)}`;

      window.open(url);
    }
  }

  async loginWithOwnerPortalImpersonationToken(token: string) {
    const { status, data, message, statusCode } = await apiHelper.axiosPost<LoginResponseDto[]>({
      url: ServerRoutes.AUTH_POST_LOGIN_OWNER_PORTAL_IMPERSONATION_TOKEN,
      data: new LoginWithOwnerPortalImpersonationTokenDto({
        token
      })
    });

    if (status && data) {
      return await this.handleLoginResponses(data, false);
    }
    return new ApiResult<ApiRedirectResult>(undefined, message, statusCode);
  }

  loginWithSSO = async (providerType: SSOProvidersEnum) => {
    if (this.isSSOAuthenticating) return undefined;
    this.isSSOAuthenticating = true;

    const url = new URL(location.href);
    const res = await apiHelper.axiosGet(`/api/auth/${providerType}/redirect${url.search}`);

    if (res.status && res.data) {
      this.isSSOAuthenticating = false;
      const ssoQueryParams = url.searchParams.get("state") || undefined;
      const returnPath = ssoQueryParams && (qs.parse(ssoQueryParams)?.returnPath as string);

      return await this.handleLoginResponses(res.data, true, returnPath);
    }
    this.isSSOAuthenticating = false;
    return new ApiResult<LoginResponseDto>(undefined, res.message);
  };

  handleLoginResponses = async (
    loginResponses?: LoginResponseDto[] | undefined,
    rememberMe?: boolean,
    redirectPath?: string
  ): Promise<ApiResult<ApiRedirectResult>> => {
    if (loginResponses) {
      // save login responses to localstorage
      apiHelper.saveLoginResponsesToCookie(loginResponses, rememberMe);

      if (getIsOAuth2Login() && loginResponses?.length === 1) {
        const oneLoginResponse = loginResponses[0];
        if (oneLoginResponse.loginEmail) {
          return await this.loginWithOAuth2({
            email: oneLoginResponse.loginEmail,
            tokenId: oneLoginResponse.id
          });
        }
      }

      return await this.redirectAfterLogin(loginResponses, redirectPath);
    }
    return new ApiResult<ApiRedirectResult>(undefined, "User does not have any active accounts", 401);
  };

  login = async (loginDTO: LoginDto): Promise<ApiResult<ApiRedirectResult>> => {
    const res = await apiHelper.axiosPost<LoginResponseDto[]>({
      url: ServerRoutes.AUTH_POST_LOGIN,
      data: loginDTO
    });
    if (res.status) {
      const loginResponses = res.data;
      return await this.handleLoginResponses(loginResponses, loginDTO.rememberMe);
    }
    return new ApiResult<ApiRedirectResult>(undefined, res.message, res.statusCode, undefined, res.errorCode);
  };

  redirectToSSOLandingPage = (providerName: SSOProvidersEnum, isRedirectFromNative) => {
    analyticsService.track(SegmentEventTypes.GOOGLE_SSO_SIGN_IN_CTA_CLICKED);

    const querystring = qs.parse(location.search);
    const returnPath = querystring.returnPath ? `&returnPath=${querystring.returnPath}` : "";

    const redirectUrl = `${location.protocol}//${location.hostname}${location.port ? ":3001" : ""}${
      ServerRoutes.AUTH_SSO_LOGIN
    }?provider=${providerName}${returnPath}&isNative=${isRedirectFromNative ? 1 : 0}`;

    if (isRedirectFromNative) {
      window.open(redirectUrl);
    } else {
      location.href = redirectUrl;
    }
  };

  signup = async (signupDto: SignupDto, recaptchaToken: string): Promise<ApiResult<ApiRedirectResult>> => {
    const res = await apiHelper.axiosPost<LoginResponseDto[]>({
      url: ServerRoutes.AUTH_POST_SIGNUP,
      data: {
        ...signupDto,
        "g-recaptcha-response": recaptchaToken
      }
    });

    if (res.status) {
      const data = res.data;

      const redirectResult = await this.handleLoginResponses(data, true, Routes.WELCOME_WIZARD_STEP1);

      analyticsService.track(
        SegmentEventTypes.REGISTERED,
        {
          id: data?.[0].id
        },
        { trackEventInIntercom: true }
      );

      return redirectResult;
    }
    return new ApiResult<ApiRedirectResult>(undefined, res.message, res.statusCode, undefined, res.errorCode);
  };

  acceptInviteNewLogin = async (values: AcceptInviteNewLoginDto) => {
    const res = await apiHelper.axiosPost({ url: ServerRoutes.AUTH_POST_ACCEPT_INVITE_NEW_LOGIN, data: values });

    if (res.status) {
      const data = res.data as LoginResponseDto[];
      const redirectResult = await this.handleLoginResponses(data);

      values.type && this.trackAfterAcceptInvite(values.type, values.id);

      return redirectResult;
    }
    return new ApiResult<ApiRedirectResult>(undefined, res.message, res.statusCode);
  };

  checkLoginEmailExists = async (values: AcceptInviteCheckLoginRequestDto) =>
    await apiHelper.axiosGet(ServerRoutes.AUTH_GET_CHECK_LOGIN_EMAIL_EXISTS + "/", values);

  acceptInviteExistingLogin = async (values: AcceptInviteExistingLoginDto) => {
    const acceptInviteResponse = await apiHelper.axiosPost<LoginResponseDto[]>({
      url: ServerRoutes.AUTH_POST_ACCEPT_INVITE_EXISTING_LOGIN,
      data: values
    });

    if (acceptInviteResponse.status) {
      const redirectResult = await authApi.handleLoginResponses(acceptInviteResponse.data);

      values.type && this.trackAfterAcceptInvite(values.type, values.id);

      return redirectResult;
    }
    return acceptInviteResponse;
  };

  signupConfirm = async (request: SignupConfirmDto): Promise<ApiResult<LoginResponseDto | ApiRedirectResult>> => {
    const res = await apiHelper.axiosPost<LoginResponseDto[]>({
      url: ServerRoutes.AUTH_POST_SIGNUP_CONFIRM,
      data: request
    });
    if (res.status && res.data) {
      return await this.handleLoginResponses(res.data);
    }
    return new ApiResult<LoginResponseDto>(undefined, res.message);
  };

  getLoginTheme = async (request: GetLoginThemeBySubdomainRequest): Promise<ApiResult<LoginThemeDto>> =>
    await apiHelper.axiosGet<LoginThemeDto>(ServerRoutes.AUTH_GET_LOGIN_THEME, request);

  createOrUpdateDemoLead = async (
    values: DoorLoopLeadDto,
    recaptchaToken: string
  ): Promise<ApiResult<DoorLoopLeadResponseDto>> =>
    await apiHelper.axiosPost<any>({
      url: ServerRoutes.AUTH_POST_CREATE_OR_UPDATE_DEMO_LEAD,
      data: {
        ...values,
        "g-recaptcha-response": recaptchaToken
      }
    });

  createDemoBaseLead = async (
    values: DoorLoopLeadDto,
    recaptchaToken: string
  ): Promise<ApiResult<DoorLoopLeadResponseDto>> =>
    await apiHelper.axiosPost<any>({
      url: ServerRoutes.AUTH_CREATE_DEMO_BASE_LEAD,
      data: {
        ...values,
        "g-recaptcha-response": recaptchaToken
      }
    });

  storeProspectInState = (loginResponses: LoginResponseDto[]) => {
    const oneLoginResponse = _.first(loginResponses);
    if (oneLoginResponse) {
      store.dispatch(
        authActions.loginSuccess(
          new AuthResponseDto({
            currentLoginResponse: oneLoginResponse,
            loginResponses
          })
        )
      );
      return true;
    }
    return false;
  };

  otpAuth = async (data: OtpAuthDto): Promise<ApiResult<any>> => {
    const res = await apiHelper.axiosPost<LoginResponseDto[]>({
      url: ServerRoutes.OTP_AUTH,
      data
    });
    if (res.status && res.data) {
      const loginResponses = res.data;

      const redirectUrlApiResult = await this.handleLoginResponses(loginResponses, false);

      return new ApiResult({ redirectUrl: redirectUrlApiResult.data?.redirectUrl, loginResponses });
    }

    return new ApiResult<LoginResponseDto>(undefined, res.message, res.statusCode, undefined, res.errorCode);
  };

  trackAfterAcceptInvite(type: AcceptInviteType, id?: string | undefined): void {
    analyticsService.track(
      SegmentEventTypes.REGISTERED,
      {
        id
      },
      { trackEventInIntercom: true }
    );
  }
}

export const authApi = new AuthApi();
