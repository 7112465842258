import type {
  BankAccountDto,
  GetAllBankAccountsQuery,
  GetAllBaseQueryResponse,
  GetAllMerchantAccountsQuery,
  GetAllPlaidAccountsQuery,
  GetLimitsForMerchantAccountQuery,
  MerchantAccountDto,
  MerchantAccountRemainingLimitsDto,
  MerchantAccountStripeAccountDto,
  PlaidAccountDto
} from "@doorloop/dto";
import { ServerRoutes } from "@doorloop/dto";
import { RestApiBase } from "./restApiBase";
import { apiHelper } from "api/apiHelper";
import { accountsApi } from "./accounts";
import type { ApiResult } from "api/apiResult";
import moment from "moment";

export class MerchantAccountApi extends RestApiBase<MerchantAccountDto, GetAllMerchantAccountsQuery> {
  constructor() {
    super(ServerRoutes.MERCHANT_ACCOUNTS);
    this.restRoute = ServerRoutes.MERCHANT_ACCOUNTS;
  }

  getMerchantRemainingCredits = async () =>
    await apiHelper.axiosGet(ServerRoutes.MERCHANT_ACCOUNTS_GET_CREDITS_REMAINING);

  getStripeAccounts = async (): Promise<ApiResult<MerchantAccountStripeAccountDto[]>> =>
    await apiHelper.axiosGet(ServerRoutes.MERCHANT_ACCOUNTS_GET_STRIPE_ACCOUNTS);

  async getLimitsForMerchantAccount(
    query: GetLimitsForMerchantAccountQuery
  ): Promise<ApiResult<MerchantAccountRemainingLimitsDto>> {
    return await apiHelper.axiosGet(ServerRoutes.MERCHANT_ACCOUNTS_GET_LIMITS_FOR_MERCHANT_ACCOUNT + "/?", query);
  }
}

export const merchantAccountApi = new MerchantAccountApi();

export class BankConnectApi extends RestApiBase<PlaidAccountDto, GetAllPlaidAccountsQuery> {
  constructor() {
    super(ServerRoutes.BANK_CONNECT_PLAID_ACCOUNTS);
    this.restRoute = ServerRoutes.BANK_CONNECT_PLAID_ACCOUNTS;
  }
}

export const bankConnectApi = new BankConnectApi();

export class BankAccountsApi extends RestApiBase<BankAccountDto, GetAllBankAccountsQuery> {
  constructor() {
    super(ServerRoutes.ACCOUNTS_GET_BANK_ACCOUNTS, [accountsApi]);
    this.restRoute = ServerRoutes.ACCOUNTS_GET_BANK_ACCOUNTS;
  }

  async getAll(query: GetAllBankAccountsQuery): Promise<ApiResult<GetAllBaseQueryResponse<BankAccountDto>>> {
    if (!query.balanceAsOfDate) {
      query.balanceAsOfDate = moment().format("YYYY-MM-DD");
    }
    return await super.getAll(query);
  }

  getMerchantAccountIdentityStatus = async (merchantAccountId: string) =>
    await apiHelper.axiosGet(ServerRoutes.MERCHANT_ACCOUNTS_IDENTITY_VERIFICATION, {
      merchantAccountId
    });
}

export const bankAccountsApi = new BankAccountsApi();
