/* eslint-disable @typescript-eslint/no-use-before-define */

import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import type { Paper } from "@material-ui/core";
import type { AutocompleteRenderOptionState } from "@material-ui/lab/Autocomplete";
import type { FilterOptionsState } from "@material-ui/lab/useAutocomplete";
import type { AutoCompleteContextProps } from "./autoComplete.context";
import type {
  AutoCompleteCloseEventHandler,
  AutoCompleteMetadata,
  AutoCompleteOption,
  AutoCompleteProps,
  AutoCompleteValue,
  ErrorState
} from "./autoComplete.types";

import { useResponsiveHelper } from "@/contexts/responsiveContext";
import { useTypedTranslation } from "@/locale";
import { Popper } from "@material-ui/core";
import { createPortal } from "react-dom";
import { useIsMounted, useOnClickOutside } from "usehooks-ts";
import { AbstractAutoCompleteContext } from "./autoComplete.context";
import { popperOptions } from "./autoComplete.popper";
import {
  DefaultCustomListboxComponentMemo,
  DefaultCustomPaperComponent,
  defaultCreateInputRenderer,
  defaultCustomFilterOptions,
  defaultCustomGetOptionLabel,
  defaultCustomGetOptionSelected,
  defaultOptionRenderer,
  doesObjectHaveProp,
  doesOptionDataHaveId,
  isValidIndex
} from "./autoComplete.utils";

import "./autoComplete.styles.css";

// An attempt to workaround Mui Circular Progress animation issue, where it
// hiccups under load. See https://github.com/mui/material-ui/issues/25674.
import "./pure-mui-circular-progress.css";

const rootClassName = "CustomMuiAutocomplete-root";

function AutoCompleteTyped<T, TMetadata extends AutoCompleteMetadata = AutoCompleteMetadata>({
  label,
  autoHighlight = true,
  displayNameKey,
  defaultValue,
  filterKeys,
  filterOptions,
  options: staticOptions = [],
  entityId,
  dataCy,
  onBlur,
  onOpen,
  onClose,
  groupBy,
  getOptions,
  getAvatarSrc,
  textFieldProps,
  getOptionSelected = defaultCustomGetOptionSelected,
  PaperComponent = DefaultCustomPaperComponent,
  renderOption = defaultOptionRenderer,
  onInputChange,
  open = false,
  onChange,
  meta,
  ...muiProps
}: AutoCompleteProps<T, TMetadata, false, typeof muiProps.disableClearable, false>) {
  type ThisOption = AutoCompleteOption<T>;

  const { t } = useTypedTranslation();
  const isMounted = useIsMounted();

  // Allow controlled state with fallback to uncontrolled
  // if the `value` prop is not provided.
  const [currIndex, setCurrIndex] = useState<number | null>(null);

  const optionsBase = useMemo(
    () =>
      getOptions({
        displayNameKey,
        getAvatarSrc,
        getOptionLabel: muiProps.getOptionLabel,
        t
      }),
    [displayNameKey, getAvatarSrc, getOptions, muiProps.getOptionLabel, t]
  );

  const [isOpen, setIsOpen] = useState(open);
  const [options, setOptions] = useState<ThisOption[]>(staticOptions);
  const [isLoading, setIsLoading] = useState(muiProps.loading);

  useEffect(() => {
    setOptions(optionsBase);
  }, [optionsBase]);

  useEffect(() => {
    setIsLoading(muiProps.loading);
  }, [muiProps.loading]);

  const customGetSelectedOption = useCallback(
    (option: ThisOption, value: AutoCompleteValue<T>) => (value === null ? false : getOptionSelected(option, value)),
    [getOptionSelected]
  );

  const [inputValue, setInputValue] = useState<string>("");
  const [isInputTouched, setIsInputTouched] = useState(false);

  const currValue = currIndex === null ? null : options[currIndex];

  useEffect(() => {
    if ((muiProps.value || defaultValue || entityId) && options.length > 0) {
      const value = muiProps.value ?? defaultValue ?? null;

      let index: number;

      if (entityId) {
        index = options.findIndex((option) => {
          // Heuristic to find the option by the `data.id` property
          if (doesOptionDataHaveId(option.data)) {
            return option.data.id === entityId;
          }
          return false;
        });
      } else {
        if (value === null) {
          return;
        }

        index = options.findIndex((option) => customGetSelectedOption(option, value));
      }

      if (index === -1) {
        if (process.env.REACT_APP_ENV === "dev") {
          console.warn(`[AutoComplete]: Value "${value}" not found in options.`);
        }
        return;
      }

      const nextOption = options[index];
      const isDirty = isInputTouched && isInputDirty(nextOption, inputValue);

      if (isDirty) {
        // Do not update the current value when the filter is "dirty" because it
        // will reset the input state back to the currently selected option, which
        // is unexpected.
        return;
      }

      setInputValue(nextOption.label);
      setCurrIndex(nextOption.index);
    }
  }, [customGetSelectedOption, defaultValue, muiProps.value, entityId, inputValue, options, isInputTouched]);

  // Custom AutoComplete children can set state after async operations, so we
  // need to make sure the component is still mounted. In the future, we can
  // remove it since it's suppressed in React >= 18 (it is actually _noop_).
  // See https://github.com/reactwg/react-18/discussions/82 and
  // https://reacttraining.com/blog/setting-state-on-unmounted-component
  const setIsLoadingSafely = useCallback(
    (value?: boolean) => {
      if (isMounted()) {
        setIsLoading(value);
      }
    },
    [isMounted]
  );

  const [errorState, setErrorState] = useState<ErrorState>({
    hasError: false,
    errorText: ""
  });

  const { isMobile } = useResponsiveHelper();

  // When the dropdown is open, we want to handle the following cases for
  // closing it:
  // 1. When clicking outside the dropdown, taking into account interactive
  //    children that take the focus away from the input (not handled natively
  //    by Mui).
  // 2. When clicking on the input field (only when no option is selected yet).
  // 3. When clicking the close button when in `fullscreen` mode.
  // 4. When clearing the input and clicking outside (`reason` equals `blur`).
  // 5. When clearing the input and pressing Escape (`reason` equals `escape`).

  //    TODO Observe an issue where the dropdown doesn't get closed when
  //    clicking on interactive children and then on a blank spot within Paper.
  //    However, the user can still click outside to close the dropdown, so it's
  //    not a critical issue. It happens because the `relatedTarget` is set to
  //    the Paper component when clicking an interactive child first, which
  //    triggers the condition that leaves the dropdown open. The next click
  //    within Paper also has a `relatedTarget` set to the Paper component, so
  //    it doesn't trigger the condition that closes the dropdown.
  const handleClose = useCallback<AutoCompleteCloseEventHandler<T>>(
    // When reason equals `select-option`, `value` is the selected option.
    (event, reason, _value) => {
      onClose?.(event as React.ChangeEvent<object>, reason);
      // We want to keep the dropdown open when the user clicks on the input
      // _if_ the input is not focused yet. This is useful for providing
      // native-like mobile UI/UX.
      if (isMobile && isOpen) {
        if ((reason === "blur" || reason === "toggleInput") && event?.target instanceof HTMLInputElement) {
          // Stay open when clicking on the input field.
          return;
        }
      }

      // Manual inference of all returned `event.relatedTarget` types when
      // `relatedTarget` exists on the `event` type.
      type RelatedTarget = HTMLElement | EventTarget | (EventTarget & Element) | null;

      const hasRelatedTarget = doesObjectHaveProp<RelatedTarget>(event, "relatedTarget");

      // https://stackoverflow.com/a/6581728/4106263
      const isElement = hasRelatedTarget && event.relatedTarget instanceof HTMLElement;

      // Override the default behavior of closing the dropdown when the input
      // field is blurred since we want to keep the dropdown open when the user
      // clicks on a custom Paper component with clickable elements other than
      // the dropdown builtin elements.
      if (!["blur", "escape"].includes(reason) && !hasRelatedTarget && !isElement) {
        setIsOpen(false);
        return;
      }

      const isPaperOrRelated = isElement && event.relatedTarget.closest(".MuiPaper-root");

      if (isPaperOrRelated) {
        // Stay open when clicking on the Paper component or its children.
        return;
      }

      if (muiProps.debug && process.env.REACT_APP_ENV === "dev") {
        console.warn(`[AutoComplete]: Paper will remain open`);
        return;
      }

      // Do _not_ set the input value when clicking on interactive children
      if (reason === "blur" || reason === "escape") {
        if (currValue === null) {
          setInputValue("");
        } else {
          setInputValue(currValue?.label);
        }
      }

      setIsOpen(false);
    },
    [currValue, isMobile, isOpen, muiProps.debug, onClose]
  );

  const handleChange = useCallback<NonNullable<typeof onChange>>(
    async (event, value, reason, details, promise) => {
      if (details?.hasError) {
        setErrorState({ hasError: true, errorText: details.errorText });
      } else {
        setErrorState({ hasError: false, errorText: "" });
      }

      const isClearIndicator =
        event?.target instanceof Element && event.target.closest(".MuiAutocomplete-clearIndicator");

      // https://github.com/mui/material-ui/blob/v4.x/packages/material-ui-lab/src/useAutocomplete/useAutocomplete.js#L642
      // https://github.com/mui/material-ui/blob/v4.x/packages/material-ui-lab/src/useAutocomplete/useAutocomplete.js#L812
      // https://github.com/mui/material-ui/blob/v4.x/packages/material-ui-lab/src/useAutocomplete/useAutocomplete.js#L825
      // https://github.com/mui/material-ui/blob/v4.x/packages/material-ui-lab/src/useAutocomplete/useAutocomplete.js#L966
      // https://github.com/mui/material-ui/blob/v4.x/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L428
      if (reason === "clear" && !isClearIndicator) {
        return;
      }

      if (isValidIndex(value?.index) && inputRef.current) {
        setInputValue(value?.label ?? "");
        // TODO We don't need a fallback here, TS should infer `value.index` as
        // `number`, but for some reason it doesn't.
        setCurrIndex(value?.index ?? null);
      } else {
        setInputValue("");
        setCurrIndex(null);
      }

      // We exclude `blur` from the list of reasons that should close the
      // dropdown because it's already handled by Mui, so we want to avoid
      // multiple calls to `handleClose`.
      // See https://github.com/mui/material-ui/blob/v4.x/packages/material-ui-lab/src/useAutocomplete/useAutocomplete.js#L809C24-L809C30
      if (!muiProps.disableCloseOnSelect && reason === "select-option") {
        handleClose(event, reason, value);
      }

      onChange?.(event, value, reason, details, promise);
    },
    [handleClose, muiProps.disableCloseOnSelect, onChange]
  );

  const handleBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    (event) => {
      onBlur?.(event);
      // When non-AutoComplete children are clicked, they invoke a `blur` event
      // on the input, which triggers this callback. We want to keep the input
      // intact in such case. For example, when clicking on "show more results",
      // hence this custom handler.
      const isDirty = isInputDirty(currValue, inputValue);

      if ((!currValue || isDirty) && !event.relatedTarget?.closest(".MuiPaper-root") && !isMobile) {
        setInputValue(currValue?.label ?? "");
        onInputChange?.(event, "", "clear");
      }
    },
    [currValue, inputValue, isMobile, onBlur, onInputChange]
  );

  const handleOpen = useCallback(
    (event: React.ChangeEvent<object>) => {
      onOpen?.(event);
      setIsOpen(true);
    },
    [onOpen]
  );

  const customGroupBy = useMemo(
    () => (groupBy ? (option: ThisOption) => groupBy(option, { t }) : undefined),
    [groupBy, t]
  );

  const customFilterOptions = useCallback(
    (options: ThisOption[], state: FilterOptionsState<ThisOption>) => {
      const filterFn = filterOptions || defaultCustomFilterOptions;
      return filterFn(options, state, {
        matchSorterOptions: { keys: filterKeys },
        groupBy: customGroupBy,
        t
      });
    },
    [filterOptions, filterKeys, customGroupBy, t]
  );

  const handleInputChange = useCallback<NonNullable<AutoCompleteProps<T>["onInputChange"]>>(
    (event, value, reason) => {
      if (reason === "input") {
        if (!isInputTouched) {
          setIsInputTouched(true);
        }

        setInputValue(value);
        onInputChange?.(event, value, reason);
      }
    },
    [isInputTouched, onInputChange]
  );

  const inputContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const customRenderInput = useMemo(
    () =>
      defaultCreateInputRenderer({
        // We can't pass `textFieldProps` because it causes redundant re-renders.
        // If we have to support a prop, pass it individually. AutoComplete throws
        // an error when passing `inputProps`, pass the corresponding props
        // directly to AutoComplete instead.
        placeholder: textFieldProps?.placeholder,
        error: errorState.hasError || textFieldProps?.error,
        helperText: errorState.errorText || textFieldProps?.helperText,
        label,
        inputContainerRef,
        inputRef
      }),
    [
      errorState.errorText,
      errorState.hasError,
      label,
      textFieldProps?.error,
      textFieldProps?.helperText,
      textFieldProps?.placeholder
    ]
  );

  const customRenderOption = useCallback(
    (option: ThisOption, state: AutocompleteRenderOptionState) => renderOption(option, state, { t }),
    [renderOption, t]
  );

  const CustomPopperComponent = useCallback<typeof Popper>(
    (props) => <Popper {...props} anchorEl={inputContainerRef.current} popperOptions={popperOptions} />,
    []
  );

  const handleClickOutside = useCallback(
    (event: MouseEvent | TouchEvent | FocusEvent) => {
      const isRootTextField =
        event.target instanceof HTMLElement && event.target.closest(".MuiTextField-container .MuiTextField-root");

      if (isOpen && !isRootTextField) {
        handleClose(event, "blur");
      }
    },
    [handleClose, isOpen]
  );

  const paperRef = useRef<HTMLElement>(null);

  useOnClickOutside(
    [paperRef, inputContainerRef] as unknown as Array<React.RefObject<HTMLElement>>,
    handleClickOutside
  );

  // The standards methods of forwarding a `ref` (`forwardRef` and
  // 'useImperativeHandle`) don't work in this case, so we have to explicity
  // lift a `ref` from the Paper component to here. This helps us avoid passing
  // props that change too often despite being relatively meaningless to the
  // Paper component, which causes unnecessary re-renders.
  const CustomPaperComponent = useCallback<typeof Paper>(
    (props) => <PaperComponent {...props} elevation={0} />,
    [PaperComponent]
  );

  const contextValue = useMemo(() => {
    return {
      isLoading,
      value: currValue,
      isOpen,
      meta,
      getOptionLabel: muiProps.getOptionLabel,
      dispatchChange: handleChange,
      dispatchClose: handleClose,
      setIsLoading: setIsLoadingSafely,
      setIsOpen,
      setOptions,
      options,
      paperRef
    };
  }, [
    isLoading,
    currValue,
    isOpen,
    meta,
    muiProps.getOptionLabel,
    handleChange,
    handleClose,
    setIsLoadingSafely,
    options
  ]);

  const Context = AbstractAutoCompleteContext as unknown as React.Context<
    AutoCompleteContextProps<T, typeof meta, typeof muiProps.disableClearable>
  >;

  // We extract props that should be merged instead of completely overridden
  const { classes: muiClasses, ...restMuiProps } = muiProps;

  const AutoComplete = (
    <Context.Provider value={contextValue}>
      <MuiAutocomplete<ThisOption, false, false, false>
        // We apply the root CSS class to the Popper component so we can easily
        // override its style and its children styles, because by default, it's
        // rendered outside of the root component element.
        classes={{
          ...muiClasses,
          root: clsx(rootClassName, muiClasses?.root),
          popper: clsx(rootClassName, muiClasses?.popper)
        }}
        /* Controlled States */
        inputValue={inputValue}
        value={currValue}
        open={isOpen}
        /* Static or Dynamic Options */
        options={options}
        /* Options Filter */
        filterOptions={customFilterOptions}
        /* Dynamic Options Loading */
        loading={isLoading}
        /* Event Handlers */
        onBlur={handleBlur}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={handleChange}
        onInputChange={handleInputChange}
        /* Group Helpers */
        groupBy={customGroupBy}
        /* Render Helpers */
        renderInput={customRenderInput}
        renderOption={customRenderOption}
        /* Data Getter Helpers */
        getOptionLabel={defaultCustomGetOptionLabel}
        getOptionSelected={customGetSelectedOption}
        /* Custom Components */
        PopperComponent={CustomPopperComponent}
        PaperComponent={CustomPaperComponent}
        ListboxComponent={DefaultCustomListboxComponentMemo}
        /* Translations */
        noOptionsText={t("common.autoComplete.noOptions")}
        loadingText={t("common.autoComplete.loading")}
        /* Attributes */
        fullWidth
        // We handle closing the dropdown manually in order to workaround Mui
        // native code where it calls `handleClose` in its `change` event
        // handler without passing the new value, which causes a lifecycle bug
        // where the current value (`currValue`) is not synced yet with the
        // newly selected value in the `close` event handler.
        // See https://github.com/mui/material-ui/blob/v4.x/packages/material-ui-lab/src/useAutocomplete/useAutocomplete.js#L567
        disableCloseOnSelect
        autoHighlight={autoHighlight}
        data-cy={dataCy}
        /* Overrides */
        {...restMuiProps}
      />
    </Context.Provider>
  );

  // We have to render the AutoComplete component through a Portal when it's
  // opened in mobile view to prevent it from being overlaid by another element.
  // This is called the "context stack" problem, where an element is overlaid by
  // an element that has higher precedence in the context stack, despite having
  // a lower `z-index`. There's a corresponding comment in
  // `autoComplete.styles.css`.
  // See https://www.joshwcomeau.com/css/stacking-contexts/
  return isMobile && isOpen ? createPortal(AutoComplete, document.body) : AutoComplete;
}

export default AutoCompleteTyped;

function isInputDirty<T>(currValue: AutoCompleteValue<T> | undefined, inputValue: string) {
  return (currValue && currValue.label !== inputValue) || (currValue === null && inputValue !== "");
}
