import type { FC } from "react";
import React from "react";
import { View } from "DLUI/view";
import { Grid } from "@material-ui/core";
import { IconSquare } from "DLUI/icon/iconSquare";
import Text from "DLUI/text";
import { Icon } from "DLUI/icon";
import type { SVGIconComponent } from "assets/icons";
import { AnnouncementCalendarIcon, AnnouncementPlaneIcon } from "assets/icons";
import { SeparationLine } from "DLUI/separatorView";

interface ReviewRowProps {
  RowIcon: SVGIconComponent;
  title: string;
  summaryText: string;
  summaryAmount: number;
  isLast?: boolean;
  showPortalIcon?: boolean;
}

export const ReviewRow: FC<ReviewRowProps> = ({
  RowIcon,
  title,
  summaryText,
  summaryAmount,
  isLast,
  showPortalIcon
}: ReviewRowProps) => (
  <>
    <View flexDirection={"row"} alignItems={"center"} marginBottom={5} height={40} noWrap>
      <Grid container xs={2} md={1} lg={1} item>
        <IconSquare Source={RowIcon} />
      </Grid>
      <Grid container xs={3} md={4} lg={4} item>
        <Text value={title} fontSize={14} />
      </Grid>
      <Grid container xs={7} md={7} lg={7} item>
        <View flexDirection={"row"} alignItems={"center"} noWrap fullWidth>
          <View autoWidth>
            <Icon
              Source={showPortalIcon ? AnnouncementCalendarIcon : AnnouncementPlaneIcon}
              width={16}
              height={16}
              marginRight={10}
            />
          </View>
          <View flex={1}>
            <Text value={summaryText} replaceObject={{ x: summaryAmount }} fontSize={14} />
          </View>
        </View>
      </Grid>
    </View>
    {!isLast && <SeparationLine marginBottom={5} width={"100%"} height={1} />}
  </>
);
