import type { PropertyDto } from "@doorloop/dto";
import { ListItemContainerDefaultMinWidth, ListItemContainerMaxWidth } from "DLUI/infiniteList/utils";
import { StickyHeader } from "DLUI/stickyHeader";
import { View } from "DLUI/view";
import React, { useState } from "react";
import PropertyListItem from "screens/properties/propertiesList/propertyListItem";
import { NavigationManager } from "utils/navigation";
import { AnimateMarginTop } from "DLUI/animatableView";

import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import type { ResultsSearchTabComponentProps } from "DLUI/screen/globalSearch/types";
import { globalSearchContstants } from "DLUI/screen/globalSearch/globalSearch.constants";
import { useTimeout } from "../../../../hooks/useTimeout";

const PropertiesTab: React.FC<ResultsSearchTabComponentProps<PropertyDto>> = ({ listItems, handleItemClicked }) => {
  const { isMobile, screenContainerPadding } = useResponsiveHelper();
  const [shouldRender, setShouldRender] = useState(false);

  useTimeout(() => {
    setShouldRender(true);
  }, globalSearchContstants.tabsDisplayDelay);

  const items = listItems.map((currentItem, index) => {
    const didPressPropertyItem = (propertyId: string) => {
      handleItemClicked("Properties");

      setTimeout(() => {
        NavigationManager.viewPropertyDetails(propertyId);
      }, globalSearchContstants.resultsItemHideDelay);
    };
    return (
      <View
        minWidth={isMobile ? undefined : ListItemContainerDefaultMinWidth}
        key={"PSI" + index}
        paddingLeft={screenContainerPadding}
        paddingRight={screenContainerPadding}
      >
        <PropertyListItem propertyDto={currentItem} onItemPress={didPressPropertyItem} disableListItemOptions />
      </View>
    );
  });
  return (
    <View
      alignItems={"center"}
      overflow={"scroll"}
      height={`calc(100vh - ${globalSearchContstants.searchPanelHeight}px)`}
    >
      {shouldRender ? (
        <View maxWidth={isMobile ? ListItemContainerMaxWidth : undefined} style={{ display: "block" }}>
          {!isMobile && (
            <StickyHeader id={"searchPropertiesTab"} shouldShow uniqueKey={"searchPropertiesTab"} marginTop={20} />
          )}
          {isMobile && <AnimateMarginTop marginTop={20} />}
          <View noWrap id={"searchPropertiesTab"}>
            {items}
            <View height={60} />
          </View>
        </View>
      ) : (
        <div />
      )}
    </View>
  );
};

export default PropertiesTab;
