"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManagementFeesCompanySettingsClientDto = exports.ManagementFeesCompanySettingsDto = exports.PayOutManagementAccounts = exports.FixedBasedFeeDto = exports.AccountBasedFeeDto = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const managementFeeRule_dto_1 = require("./managementFeeRule.dto");
class AccountBasedFeeDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.AccountBasedFeeDto = AccountBasedFeeDto;
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", Number)
], AccountBasedFeeDto.prototype, "feePercent", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], AccountBasedFeeDto.prototype, "baseAccount", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], AccountBasedFeeDto.prototype, "expenseAccount", void 0);
class FixedBasedFeeDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.FixedBasedFeeDto = FixedBasedFeeDto;
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", Number)
], FixedBasedFeeDto.prototype, "feeAmount", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], FixedBasedFeeDto.prototype, "expenseAccount", void 0);
class PayOutManagementAccounts {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.PayOutManagementAccounts = PayOutManagementAccounts;
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], PayOutManagementAccounts.prototype, "incomeAccount", void 0);
class ManagementFeesCompanySettingsDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.ManagementFeesCompanySettingsDto = ManagementFeesCompanySettingsDto;
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], ManagementFeesCompanySettingsDto.prototype, "vendor", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], ManagementFeesCompanySettingsDto.prototype, "chargeAccrualAccountBasedFees", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], ManagementFeesCompanySettingsDto.prototype, "chargeCashAccountBasedFees", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], ManagementFeesCompanySettingsDto.prototype, "chargeFlatChargeFees", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => (x.feeRules ? x.feeRules.length > 0 : false)),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => managementFeeRule_dto_1.ManagementFeeRuleDto),
    __metadata("design:type", Array)
], ManagementFeesCompanySettingsDto.prototype, "feeRules", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], ManagementFeesCompanySettingsDto.prototype, "chargeMinChargeFee", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.chargeMinChargeFee),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => FixedBasedFeeDto),
    __metadata("design:type", FixedBasedFeeDto)
], ManagementFeesCompanySettingsDto.prototype, "minChargeFee", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], ManagementFeesCompanySettingsDto.prototype, "payoutManagementAccounts", void 0);
__decorate([
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], ManagementFeesCompanySettingsDto.prototype, "managementAccounts", void 0);
class ManagementFeesCompanySettingsClientDto extends ManagementFeesCompanySettingsDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.ManagementFeesCompanySettingsClientDto = ManagementFeesCompanySettingsClientDto;
__decorate([
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => PayOutManagementAccounts),
    __metadata("design:type", Array)
], ManagementFeesCompanySettingsClientDto.prototype, "payOutAccounts", void 0);
