import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { EPayPaymentStatus } from "@doorloop/dto";

import AppStrings from "../../../locale/keys";

const ePayStatusAppStringMap: Readonly<Record<EPayPaymentStatus, string | null>> = {
  [EPayPaymentStatus.SUCCEEDED]: AppStrings.Common.EPayStatus.Succeeded,
  [EPayPaymentStatus.PROCESSING]: AppStrings.Common.EPayStatus.Processing,
  [EPayPaymentStatus.DISPUTED]: AppStrings.Common.EPayStatus.Disputed,
  [EPayPaymentStatus.FAILED]: AppStrings.Common.EPayStatus.Failed,
  [EPayPaymentStatus.PENDING_REVIEW]: AppStrings.Common.BalancesSummary.PaymentsChip.PendingReview,
  [EPayPaymentStatus.REFUNDED]: null,
  [EPayPaymentStatus.IN_TRANSIT]: AppStrings.Common.BalancesSummary.PaymentsChip.InTransit,
  [EPayPaymentStatus.PAID]: AppStrings.Common.BalancesSummary.PaymentsChip.Paid
};

export const getEPayStatusAppString = (status?: EPayPaymentStatus): string =>
  (status && ePayStatusAppStringMap[status]) || "";

export const useEPayStatusText = (status?: EPayPaymentStatus): string => {
  const { t } = useTranslation();

  return useMemo(() => {
    const appString = getEPayStatusAppString(status);

    return t(appString) || "";
  }, [status]);
};
