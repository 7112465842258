import type { FC } from "react";
import { useState } from "react";
import { DeleteXIcon } from "@/assets";
import { IconButton, ControlledCheckBox } from "DLUI/form";
import makeStyles from "./style";
import Text from "DLUI/text";
import type { ConfirmationTextConfiguration, ConfirmationDialogTypes } from "./utils";
import { confirmButtonColorsMap } from "./utils";
import { View } from "DLUI/view";
import DLButton from "DLUI/button/dlButton";
import { DLButtonColorsEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import { toTypedKey } from "@/locale";
import { useResponsiveHelper } from "@/contexts/responsiveContext";

interface ConfirmationDialogContentProps {
  dialogType?: ConfirmationDialogTypes;
  handleClose: () => void;
  handleConfirm?: () => void;
  textConfiguration: ConfirmationTextConfiguration;
}

export const ConfirmationDialogContent: FC<ConfirmationDialogContentProps> = ({
  handleConfirm,
  handleClose,
  textConfiguration,
  dialogType = "info"
}) => {
  const { isMobile } = useResponsiveHelper();
  const {
    titleText,
    descriptionText,
    confirmBtnActionText = toTypedKey("common.confirm"),
    closeBtnActionText = toTypedKey("common.close"),
    checkboxText
  } = textConfiguration;

  const { xBtn } = makeStyles();
  const [isCheckboxConfirmed, setIsCheckboxConfirmed] = useState(false);

  return (
    <>
      {!isMobile && (
        <IconButton Icon={DeleteXIcon} className={xBtn} onClick={handleClose} pathColor={"light-gray"} size={12} />
      )}
      <Text value={titleText} bold marginBottom={20} align={isMobile ? "center" : "left"} />
      {descriptionText && (
        <Text value={descriptionText} align={isMobile ? "center" : "left"} marginBottom={20} color={"secondary-gray"} />
      )}
      <View
        flexDirection={isMobile ? "column-reverse" : "row"}
        gap={10}
        maxWidth={isMobile ? 270 : "100%"}
        alignItems={isMobile ? "stretch" : "flex-start"}
        justifyContent={"flex-end"}
      >
        {checkboxText && (
          <View flex={2} style={{ order: isMobile ? 2 : undefined }}>
            <ControlledCheckBox
              checked={isCheckboxConfirmed}
              style={{ transform: "translateX(-10px)" }}
              fontSize="small"
              onChange={setIsCheckboxConfirmed}
              labelValueProps={{
                text: checkboxText,
                color: "gray"
              }}
            />
          </View>
        )}
        <DLButton
          actionText={closeBtnActionText}
          onClick={handleClose}
          style={{ order: isMobile ? 0 : undefined }}
          variant={DLButtonVariantsEnum.TEXT}
          color={DLButtonColorsEnum.NEUTRAL}
        />
        <DLButton
          actionText={confirmBtnActionText}
          onClick={handleConfirm}
          style={{ order: isMobile ? 1 : undefined }}
          color={confirmButtonColorsMap[dialogType]}
          disabled={Boolean(checkboxText) && !isCheckboxConfirmed}
        />
      </View>
    </>
  );
};
