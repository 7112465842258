import { Grid, Hidden } from "@material-ui/core";
import { apiHelper } from "api/apiHelper";
import { authApi } from "api/authApi";
import { AuthLogoIcon } from "assets/icons";
import { Routes } from "components/appRouter";
import { getRgbaColor } from "DLUI/colorPicker/colorPicker";
import Copyright from "DLUI/copyright";
import { Icon } from "DLUI/icon";
import Screen from "DLUI/screen";
import { SeparationLine } from "DLUI/separatorView";
import { BackgroundImageView, View } from "DLUI/view";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as authActions from "store/auth/actions";
import type { IRootState } from "../../../../store";
import { store } from "../../../../store";
import MarketingView from "../marketingView";
import MobileAuthLayout from "./mobile.authLayout";
import "./styles.css";
import { LoadingAnimation } from "DLUI/animations/loadingAnimation";
import { loginBackgroundImage } from "screens/authScreen/layout/styles";
import { AppLayouts } from "../../../../contexts/appLayoutContext";
import { DataCy } from "@doorloop/dto";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  children: React.ReactNode;
  hideMarketingView?: boolean;
  documentTitle?: string;
}

export const FrameMaxHeight = 640;
export const FrameMaxWidth = 520;
export const FormMaxWidth = 320;

export const THEME_LOCAL_STORAGE_KEY = "theme";

const AuthLayout = ({ children, hideMarketingView, documentTitle }: ComponentProps) => {
  const { isMobile } = useResponsiveHelper();
  const theme = useSelector((state: IRootState) => state.auth.theme);
  const [opacityColor, setOpacityColor] = useState<string>("#000");
  const [backgroundUrl, setBackgroundUrl] = useState<string | undefined>();
  const [logoUrl, setLogoUrl] = useState<string | undefined>();
  const location = useLocation<any>();
  const [loadingInProgress, setLoadingInProgress] = useState<boolean>(true);

  let shouldHideMarketingView = hideMarketingView;
  if (!shouldHideMarketingView) {
    shouldHideMarketingView = Boolean(theme);
  }

  const containerAnimation = useMemo(() => {
    if (location.state && location.state.lastRoute) {
      if (location.pathname === location.state.lastRoute) {
        return "";
      }
      if (location.pathname === Routes.FORGOT_PASSWORD) {
        return "slide-in-right";
      }
      if (location.pathname === Routes.SELECT_ACCOUNT) {
        return "slide-in-right";
      }
      if (location.pathname.indexOf("new-login") > -1) {
        return "slide-in-right";
      }
      if (location.pathname === Routes.FORGOT_PASSWORD_SENT) {
        return "slide-in-right";
      }
      if (
        (location.pathname === Routes.LOGIN && location.state.lastRoute === Routes.FORGOT_PASSWORD) ||
        location.state.lastRoute === Routes.FORGOT_PASSWORD_SENT ||
        location.state.lastRoute === Routes.FORGOT_PASSWORD_CONFIRM
      ) {
        return "slide-in-left";
      }
    }
  }, [location.pathname]);

  const renderCopyright = useMemo(() => {
    let shouldRender = true;
    if (location.pathname.startsWith(Routes.FORGOT_PASSWORD) || location.pathname === Routes.SELECT_ACCOUNT) {
      shouldRender = false;
    }
    return shouldRender;
  }, [location.pathname]);

  useEffect(() => {
    if (!theme) {
      getLoginTheme();
    } else {
      setLoadingInProgress(false);
    }
  }, []);

  useEffect(() => {
    let _themeColor = "#2F3E83";
    if (theme && theme.color) {
      _themeColor = theme.color;
    }
    setOpacityColor(_themeColor);

    if (theme && theme.logoUrl) {
      setLogoUrl(theme.logoUrl);
    }
    if (theme && theme.backgroundUrl) {
      setBackgroundUrl(`url(${theme.backgroundUrl})`);
    } else {
      setBackgroundUrl(loginBackgroundImage);
    }
  }, [theme]);

  const getLoginTheme = async () => {
    const currentSubdomain = (
      window.location.host.split(".")[1] ? window.location.host.split(".")[0] : ""
    ).toLowerCase();

    localStorage.removeItem(THEME_LOCAL_STORAGE_KEY);
    if (currentSubdomain !== apiHelper.getEnvironmentSubdomain()) {
      setLoadingInProgress(true);
      const response = await authApi.getLoginTheme({
        subdomain: currentSubdomain
      });
      if (response && response.status && response.data) {
        localStorage.setItem(THEME_LOCAL_STORAGE_KEY, JSON.stringify(response.data));
        store.dispatch(authActions.updateLoginTheme(response.data));
      }
    }
    setLoadingInProgress(false);
  };

  const renderOpacityLayed = () => {
    if (opacityColor) {
      return (
        <View
          width={"100%"}
          height={"100%"}
          className={"loginOpacityLayed"}
          style={{ backgroundColor: getRgbaColor(opacityColor, "0.85") }}
        />
      );
    }
  };

  const renderBackgroundLayer = () => (
    <BackgroundImageView
      publicFileName={backgroundUrl || loginBackgroundImage}
      height={window.innerHeight}
      backgroundSize={"cover"}
    >
      {renderOpacityLayed()}
    </BackgroundImageView>
  );

  if (loadingInProgress) {
    return (
      <View height={"100%"} alignItems={"center"} justifyContent={"center"}>
        <LoadingAnimation />
      </View>
    );
  }

  if (isMobile) {
    return (
      <MobileAuthLayout
        renderBackgroundlayer={renderBackgroundLayer}
        logoUrl={logoUrl}
        containerAnimation={containerAnimation}
      >
        {children}
      </MobileAuthLayout>
    );
  }

  const renderLogo = () => {
    if (logoUrl) {
      return (
        <View height={100} backgroundColor={"white"}>
          <BackgroundImageView publicFileName={"url(" + logoUrl + ")"} height={80} marginTop={20} />
        </View>
      );
    }
    return <Icon Source={AuthLogoIcon} width={200} marginTop={30} dataCy={DataCy.login.doorloopIcon} />;
  };

  return (
    <Screen
      renderBackgroundLayer={renderBackgroundLayer}
      documentTitle={documentTitle}
      hideLeftNavBar
      removeScreenPaddings
      screenContentFullHeight
      screenLayout={AppLayouts.FullWidth}
    >
      <View height={"100%"} overflow={"hidden"}>
        <Grid direction={"row"} style={{ height: "100%" }} container>
          {!shouldHideMarketingView ? (
            <Hidden mdDown>
              <Grid item xs={false} sm={false} md={false} lg={6} xl={8}>
                <MarketingView hideMarketingView={shouldHideMarketingView} />
              </Grid>
            </Hidden>
          ) : null}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={shouldHideMarketingView ? 12 : 6}
            xl={shouldHideMarketingView ? 12 : 4}
          >
            <View justifyContent={"center"} alignItems={"center"} height={"100%"} paddingLeft={20} paddingRight={20}>
              <View
                backgroundColor={"white"}
                borderRadius={20}
                width={"95%"}
                maxWidth={FrameMaxWidth}
                overflow={"scroll"}
                noWrap
              >
                <View flex={1} width={"100%"} alignItems={"center"} minHeight={400}>
                  {renderLogo()}
                  <SeparationLine width={"100%"} height={1} marginTop={20} />
                  <View width={"100%"} className={containerAnimation} maxHeight={560}>
                    {children}
                  </View>
                  {renderCopyright ? <Copyright /> : null}
                </View>
              </View>
            </View>
          </Grid>
        </Grid>
      </View>
    </Screen>
  );
};

export default AuthLayout;
