import React from "react";

import { useTranslation } from "react-i18next";

import type { PortalInfoDto, OwnerDto, TenantDto } from "@doorloop/dto";
import { DataCy, LeaseTenantStepStatus } from "@doorloop/dto";

import AppStrings from "../../../locale/keys";
import spinnerAnimation from "../../../assets/lottie/spinner.json";
import Text from "DLUI/text";
import type { SVGIconComponent } from "../../../assets";
import { AtIcon, InviteIcon } from "../../../assets";
import { Icon } from "DLUI/icon";
import { Lottie } from "DLUI/lottie";
import { View } from "DLUI/view";
import type { TextColor } from "DLUI/text/text";
import { usePortalStatus } from "screens/tenants/tenantsList/usePortalStatus";

export interface ActiveCtaProps {
  textColor: TextColor;
  Icon: SVGIconComponent;
  text: string;
  iconSize?: number;
  onClick?: VoidFunction;
  dataCy?: string;
}

interface ComponentProps {
  onInviteToPortalClick: () => void;
  sendingInviteText: string;
  missingInfoTooltipText: string;
  portalInfo?: PortalInfoDto;
  renderOnlyInvitationLink?: boolean;
  isLoading?: boolean;
  onClickMissingContactInfo?: (e: React.MouseEvent) => void;
  activeCtaProps?: ActiveCtaProps;
  id?: string;
  onClickCta?: (id: string, value: boolean) => void;
  actionDisableObj?: Record<string, boolean>;
  dto: TenantDto | OwnerDto;
}

export const ListItemPortalStatusContent = ({
  onInviteToPortalClick,
  sendingInviteText,
  renderOnlyInvitationLink = false,
  isLoading = false,
  onClickMissingContactInfo,
  activeCtaProps,
  id,
  onClickCta,
  actionDisableObj,
  dto
}: ComponentProps) => {
  const { t } = useTranslation();
  const { currentStatus } = usePortalStatus(dto);

  const handleSendInvitationClick = () => {
    if (id) {
      onClickCta?.(id, true);
    }
    onInviteToPortalClick();
  };

  const handleActiveClick = () => {
    if (id) {
      onClickCta?.(id, true);
    }
    activeCtaProps?.onClick?.();
  };

  return (
    <View flexDirection={"row"} noWrap gap={5} style={{ flex: "0 1 auto" }}>
      {!isLoading && (
        <>
          {!renderOnlyInvitationLink &&
          currentStatus &&
          [LeaseTenantStepStatus.PAYMENT_SETUP, LeaseTenantStepStatus.ACTIVE].includes(currentStatus) &&
          activeCtaProps ? (
            <View
              gap={2}
              justifyContent={"flex-start"}
              alignItems={"center"}
              flexDirection={"row"}
              onClick={activeCtaProps?.onClick ? handleActiveClick : undefined}
              style={
                id && actionDisableObj?.[id]
                  ? {
                      pointerEvents: "none",
                      opacity: 0.7
                    }
                  : {}
              }
            >
              <Icon Source={activeCtaProps.Icon} size={activeCtaProps.iconSize ?? 16} />
              <Text fontSize={12} bold color={activeCtaProps.textColor} value={activeCtaProps.text} />
            </View>
          ) : null}

          {currentStatus &&
          (currentStatus === LeaseTenantStepStatus.PENDING_INVITE ||
            (!renderOnlyInvitationLink && LeaseTenantStepStatus.INVITED === currentStatus)) ? (
            <View
              onClick={handleSendInvitationClick}
              gap={2}
              justifyContent={"flex-start"}
              alignItems={"center"}
              flexDirection={"row"}
              style={
                id && actionDisableObj?.[id]
                  ? {
                      pointerEvents: "none",
                      opacity: 0.7
                    }
                  : {}
              }
            >
              <Icon Source={InviteIcon} width={16} height={16} />
              <Text
                fontSize={12}
                bold
                color={"lightBlue"}
                value={AppStrings.Common.SendPortalInvitation}
                dataCy={DataCy.DLUI.listItem.listItemSendPortalInvitation}
              />
            </View>
          ) : null}
          {!renderOnlyInvitationLink && currentStatus === LeaseTenantStepStatus.MISSING_CONTACT_DETAILS && (
            <View
              gap={2}
              justifyContent={"flex-start"}
              alignItems={"center"}
              flexDirection={"row"}
              onClick={onClickMissingContactInfo}
            >
              <Icon Source={AtIcon} width={16} height={16} />
              <Text
                fontSize={12}
                bold
                color={"lightBlue"}
                value={AppStrings.Common.AddContactInfo}
                dataCy={DataCy.DLUI.listItem.listItemAddContactInfo}
              />
            </View>
          )}
        </>
      )}

      {isLoading && (
        <View flexDirection={"row"} alignItems={"center"} gap={2}>
          <Text value={sendingInviteText} fontSize={12} bold color={"secondary-gray"} />
          <Lottie
            pathColor={"blue"}
            autoPlay={true}
            loop={true}
            animationData={spinnerAnimation}
            width={16}
            height={16}
          />
        </View>
      )}
    </View>
  );
};
