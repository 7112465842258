import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    sidePanelFilterPanel: {
      width: "100%",
      "& .DLUI_dateRangePicker": {
        "& .MuiGrid-item": {
          maxWidth: "50%",
          flexBasis: "50%"
        },
        "& .MuiGrid-item:first-child": {
          maxWidth: "100%",
          flexBasis: "100%"
        }
      },
      "& .DLUI_textFiltering, .DLUI_asyncAutoCompleteFilter": {
        maxWidth: "100%",
        flexBasis: "100%"
      }
    },
    componentContainer: {
      flexDirection: "row",
      overflow: "hidden",
      position: "relative",
      width: "100%"
    },
    mobileComponentContainer: {
      // paddingBottom: 20,
      minHeight: "auto"
    },
    contentContainer: {
      display: "flex",
      flexDirection: "row"
    },
    contentSection: {
      display: "flex",
      width: "100%",
      flexDirection: "row"
    },
    panelTitle: {
      margin: "20px 20px 0px 20px"
    },
    searchInputContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      maxWidth: "300px",
      width: "100%"
    },
    inputWrapper: {
      display: "flex",
      flexDirection: "row",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 30,
      borderBottomRightRadius: 30,
      backgroundColor: theme.palette.white.main,
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      paddingLeft: 20,
      boxSizing: "border-box",
      "-moz-box-sizing": "border-box",
      "-webkit-box-sizing": "border-box",
      border: `1px solid ${theme.newPalette.input.outlined.enabledBorder}`,
      "&:hover": {
        border: `1px solid ${theme.newPalette.secondary.main}`
      }
    },
    searchIcon: {
      width: "24px",
      height: "24px",
      marginRight: "30px"
    },
    searchInput: {
      flex: 1,
      fontSize: "14px"
    },
    iconButton: {
      width: 50,
      height: 50,
      padding: "0px"
    },
    filterButtonContainer: {
      display: "flex",
      width: "50px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 5,
      backgroundColor: theme.palette.screenBackground.light
    },
    propertiesIcon: {
      position: "absolute",
      zIndex: 10,
      bottom: -5,
      right: 20
    }
  })
);
