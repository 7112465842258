import type { UserLocale } from "@/hooks/useRentalApplicationLocale";
import type { ProjectFeatureIds } from "config/featureFlags/features";

const FeatureFlagByCountry: Partial<Record<ProjectFeatureIds, UserLocale>> = {
  CommunicationsCenter: {
    isUs: true,
    isCanada: true,
    isNonNorthAmerica: false
  },
  CashPayments: {
    isUs: true,
    isCanada: false,
    isNonNorthAmerica: false
  }
};

export const getIsFeatureActiveForCountry = (featureFlagId: ProjectFeatureIds, userLocale: UserLocale) => {
  const featureFlag = FeatureFlagByCountry[featureFlagId];
  if (!featureFlag) {
    return true;
  }

  return Object.keys(userLocale).some((key) => userLocale[key] && featureFlag[key]);
};
