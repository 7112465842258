import type { GeneralEntryDto, GetAllGeneralEntriesQuery } from "@doorloop/dto";
import { ServerRoutes } from "@doorloop/dto";
import { RestApiBase } from "./restApiBase";

export class GeneralEntriesApi extends RestApiBase<GeneralEntryDto, GetAllGeneralEntriesQuery> {
  constructor() {
    super(ServerRoutes.GENERAL_ENTRIES);
    this.restRoute = ServerRoutes.GENERAL_ENTRIES;
  }
}

export const generalEntriesApi = new GeneralEntriesApi();
