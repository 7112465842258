import React, { useEffect, useState } from "react";
import { AddCirclePinkIcon, CloseCircleIcon } from "assets/icons";
import { IconButton } from "DLUI/form";
import { Icon } from "DLUI/icon";
import { Link } from "DLUI/link";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import makeStyles from "./styles";
import { useReadOnlyContext } from "contexts/readOnlyContext";

import { useResponsiveHelper } from "../../../contexts/responsiveContext";

interface ComponentProps {
  selectedImageFile?: File;
  onFileDelete: () => void;
  selectedImageUrl?: string;
}

const CompanyImageSelection: React.FC<ComponentProps> = ({
  selectedImageFile,
  onFileDelete,
  selectedImageUrl
}: ComponentProps) => {
  const { isMobile } = useResponsiveHelper();
  const isReadOnlyContext = useReadOnlyContext();
  const classes = makeStyles();
  const [selectedImageData, setSelectedImageData] = useState<string | ArrayBuffer | null>(null);

  useEffect(() => {
    if (selectedImageFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        //@ts-ignore
        if (e && e.target && e.target.result) {
          //@ts-ignore
          setSelectedImageData(e.target.result);
        }
      };
      reader.readAsDataURL(selectedImageFile);
    } else {
      setSelectedImageData(null);
    }
  }, [selectedImageFile]);

  const containerWidth = isMobile ? "100%" : "auto";

  const renderUnselectedState = () => (
    <View
      width={containerWidth}
      height={120}
      backgroundColor={"dark"}
      justifyContent={"center"}
      alignItems={"center"}
      borderRadius={10}
    >
      <View
        width={containerWidth}
        height={"98%"}
        border={"1px dashed #7A8699"}
        justifyContent={"center"}
        alignItems={"center"}
        borderRadius={10}
      >
        <Icon Source={AddCirclePinkIcon} width={20} height={20} />
        <Link hoverColor={"black"} underline={"always"}>
          <Text
            value={AppStrings.Settings.GeneralSettings.CompanyInfo.AddCompanyLogo}
            color={"gray"}
            fontSize={15}
            fontWeight={700}
            marginTop={10}
          />
        </Link>
        <Text
          value={AppStrings.Settings.GeneralSettings.CompanyInfo.RecommendedSize}
          color={"gray"}
          fontSize={14}
          marginTop={10}
        />
      </View>
    </View>
  );

  const renderSelectedState = () => {
    let imageUrl = selectedImageUrl;
    if (selectedImageData) {
      imageUrl = selectedImageData.toString();
    }
    return (
      <View
        width={containerWidth}
        height={120}
        backgroundColor={"dark"}
        justifyContent={"center"}
        alignItems={"center"}
        borderRadius={10}
      >
        {!isReadOnlyContext && (
          <div className={classes.companyLogoDeleteImageContainer}>
            <IconButton circleFill={"pink"} Icon={CloseCircleIcon} width={20} height={20} onClick={onFileDelete} />
          </div>
        )}
        <View
          width={isMobile ? window.innerWidth - 30 : 220}
          height={100}
          border={"1px dashed #7A8699"}
          justifyContent={"center"}
          alignItems={"center"}
          borderRadius={10}
          style={{
            backgroundImage: "url(" + imageUrl + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center"
          }}
        />
      </View>
    );
  };

  if (selectedImageData !== null || selectedImageUrl !== undefined) {
    return renderSelectedState();
  }

  return renderUnselectedState();
};

export default CompanyImageSelection;
