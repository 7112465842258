import { Dialog, Slide, makeStyles } from "@material-ui/core";
import type { FC } from "react";
import React, { forwardRef } from "react";
import { usePopupContext } from "./context";
import type { TransitionProps } from "@material-ui/core/transitions";

const useStyles = makeStyles({
  root: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(0, 0, 0, 0.5) !important"
    },
    "& .MuiDialog-paper": {
      margin: 0
    },
    "& .MuiDialog-paperScrollPaper": {
      maxHeight: "90dvh",
      height: "100%"
    }
  }
});

const Transition = forwardRef((props: TransitionProps, ref) => <Slide direction="up" ref={ref} {...props} />);

export const DialogContent: FC = ({ children }) => {
  const { root } = useStyles();
  const { open, onClose, hideBackdrop } = usePopupContext();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      hideBackdrop={Boolean(hideBackdrop)}
      TransitionComponent={Transition}
      style={{
        width: "100%",
        bottom: 0,
        top: "initial"
      }}
      PaperProps={{
        style: {
          width: "100%",
          borderRadius: "8px"
        }
      }}
      classes={{
        root
      }}
    >
      {children}
    </Dialog>
  );
};
