import type { ChangeEvent } from "react";
import React, { useCallback, useState } from "react";
import "./styles.css";

import View from "DLUI/view/view";
import ActionsPanel from "DLUI/form/fieldsActionsPanel/actionPanels/actionsPanel";
import type { ActionsPanelInterface } from "./actionPanels/useActionsPanels";
import useActionsPanels from "./actionPanels/useActionsPanels";
import type { ActionPanelClick } from "DLUI/form/fieldsActionsPanel/actionPanels/actionPanelItem";
import { actionsPanelIconsTypesEnums, actionsPanelTypesEnums } from "DLUI/form/fieldsActionsPanel/actionPanels/enums";
import clsx from "clsx";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { Grid } from "@material-ui/core";

import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  data?: any;
  editFieldsValues?: any;
  AddFields?: React.FunctionComponent<any>;
  EditFields?: React.FunctionComponent<any>;
  ReadOnlyFields?: React.FunctionComponent<any>;
  actionsPanel?: ActionsPanelInterface;
  staticActionPanelType?: actionsPanelTypesEnums;
  addAction?: ActionPanelClick;
  confirmAction?: ActionPanelClick;
  removeAction?: ActionPanelClick;
  addClearanceAction?: AnyPermissionClearance;
  editClearanceAction?: AnyPermissionClearance;
  removeClearanceAction?: AnyPermissionClearance;
  abortNewLineAction?: ActionPanelClick;
  isAddLine?: boolean;
  newLineOnChange?: (ev: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  editLineOnChange?: (ev: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  validator?: (data: any) => boolean;
}

const FieldsActionsPanel: React.FC<ComponentProps> = ({
  data,
  AddFields,
  EditFields,
  editFieldsValues,
  ReadOnlyFields,
  actionsPanel,
  staticActionPanelType,
  addAction,
  confirmAction,
  removeAction,
  addClearanceAction,
  editClearanceAction,
  removeClearanceAction,
  abortNewLineAction,
  isAddLine,
  newLineOnChange,
  editLineOnChange,
  validator
}: ComponentProps) => {
  const {
    confirmActionPanel,
    removeActionPanel,
    addActionPanel,
    loadingActionPanel,
    actionPanelType,
    switchFieldModeAnimation,
    switchActionModeAnimation
  } = useActionsPanels({
    type: actionsPanel?.type || staticActionPanelType,
    actions: { removeAction, confirmAction, addAction, abortNewLineAction },
    clearances: { create: addClearanceAction, edit: editClearanceAction, remove: removeClearanceAction }
  });
  const { isMobile } = useResponsiveHelper();
  const [viewHideToggleIcon, setViewHideToggleIcon] = useState<actionsPanelIconsTypesEnums>(
    actionsPanelIconsTypesEnums.SHOW_ACTION_PANEL
  );
  const [isEditLineValid, setIsEditLineValid] = useState(true);
  const [isNewLineValid, setIsNewLineValid] = useState(true);
  const handleChangeLineBackground = useCallback(() => {
    switch (actionPanelType.type) {
      case actionsPanelTypesEnums.EDIT_ACTIONS_PANEL:
        return "dark";
      case actionsPanelTypesEnums.REMOVE_ACTIONS_PANEL:
        return "error-red";
      case actionsPanelTypesEnums.ADD_ACTIONS_PANEL:
        return "reminder-blue";
      default:
        return "white";
    }
  }, [actionPanelType.type]);

  const handleNewLineChange = useCallback((ev) => newLineOnChange && newLineOnChange(ev), []);
  const handleEditLineChange = useCallback((ev) => editLineOnChange && editLineOnChange(ev), []);
  const handleValidations = useCallback(
    (data) => {
      const setState = isAddLine ? setIsNewLineValid : setIsEditLineValid;
      const isValid = (validator && validator(data)) ?? true;

      setState(isValid);

      return isValid;
    },
    [isEditLineValid, isNewLineValid]
  );

  return (
    <View
      overflow={"hidden"}
      className={"DLUI_text-field-with-buttons"}
      borderRadius={10}
      backgroundColor={handleChangeLineBackground()}
      minHeight={60}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      paddingLeft={10}
      paddingRight={10}
      paddingBottom={10}
      paddingTop={10}
      marginBottom={20}
    >
      <Grid spacing={2} alignItems={"center"} justifyContent={"space-between"} container>
        {isAddLine ? (
          <Grid item xs={12} md={8} lg={6}>
            {AddFields && <AddFields {...data} onChange={handleNewLineChange} isValid={isNewLineValid} />}
          </Grid>
        ) : (
          <Grid
            container
            item
            xs={12}
            md={8}
            lg={8}
            className={clsx(["DLUI_filed-text-type", switchFieldModeAnimation?.mode])}
          >
            {actionPanelType?.type === actionsPanelTypesEnums.EDIT_ACTIONS_PANEL ? (
              <>
                {EditFields && (
                  <EditFields
                    {...data}
                    onChange={handleEditLineChange}
                    isValid={isEditLineValid}
                    setIsValid={setIsEditLineValid}
                  />
                )}
              </>
            ) : (
              <>{ReadOnlyFields && <ReadOnlyFields {...data} />}</>
            )}
          </Grid>
        )}
        <Grid
          lg={4}
          md={4}
          xs={12}
          className={clsx([`DLUI_actions-panel`, switchActionModeAnimation.mode])}
          justifyContent={isMobile ? "center" : "flex-end"}
          alignItems={"center"}
          container
          item
        >
          <ActionsPanel
            viewHideToggleIcon={viewHideToggleIcon}
            setViewHideToggleIcon={setViewHideToggleIcon}
            data={isAddLine ? data : editFieldsValues}
            actionPanelType={actionPanelType}
            loadingActionPanel={loadingActionPanel}
            switchFieldModeAnimation={switchFieldModeAnimation}
            switchActionModeAnimation={switchActionModeAnimation}
            validator={handleValidations}
            actionsPanel={actionsPanel}
            confirmActionPanel={confirmActionPanel}
            removeActionPanel={removeActionPanel}
            addActionPanel={addActionPanel}
          />
        </Grid>
      </Grid>
    </View>
  );
};

export default FieldsActionsPanel;
