import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";
import { lazy } from "react";
import "../screens/rentalApplications/rentalApplicationDetails/reports/styleImports";

const RentalApplicationsListScreen = lazy(
  () => import("screens/rentalApplications/rentalApplicationsList/rentalApplicationsListScreen")
);
const RentalApplicationDetailsScreen = lazy(
  () => import("screens/rentalApplications/rentalApplicationDetails/rentalApplicationDetails")
);

const RentalApplicationsRouter = () => (
  <PageRouter>
    <LazyRoute exact path={Routes.EDIT_PROSPECT_RENTAL_APPLICATION} render={RentalApplicationDetailsScreen} />
    <LazyRoute exact path={Routes.EDIT_PROSPECT_TENANT_RENTAL_APPLICATION} render={RentalApplicationDetailsScreen} />
    <LazyRoute exact path={Routes.EDIT_PROPERTY_RENTAL_APPLICATION} render={RentalApplicationDetailsScreen} />
    <LazyRoute exact path={Routes.EDIT_RENTAL_APPLICATION} render={RentalApplicationsListScreen} />
    <LazyRoute exact path={Routes.UPDATE_RENTAL_APPLICATION_DECISION_STATUS} render={RentalApplicationsListScreen} />
    <LazyRoute
      path={Routes.RENTAL_APPLICATION_DETAILS_EDIT_RENTAL_APPLICATION}
      render={RentalApplicationDetailsScreen}
      exact
    />
    <LazyRoute
      path={Routes.RENTAL_APPLICATION_DETAILS_EDIT_INTERNAL_TASK}
      render={RentalApplicationDetailsScreen}
      exact
    />
    <LazyRoute
      path={Routes.RENTAL_APPLICATION_DETAILS_UPDATE_DECISION_STATUS}
      render={RentalApplicationDetailsScreen}
      exact
    />
    <LazyRoute path={[Routes.RENTAL_APPLICATIONS_GLOBAL_NEW]} render={RentalApplicationsListScreen} />
    <LazyRoute
      path={[Routes.RENTAL_APPLICATION_DETAILS, Routes.RENTAL_APPLICATIONS_GLOBAL_NEW]}
      render={RentalApplicationDetailsScreen}
    />
    <LazyRoute
      path={[Routes.RENTAL_APPLICATIONS_GLOBAL_NEW, Routes.RENTAL_APPLICATIONS]}
      render={RentalApplicationsListScreen}
    />
  </PageRouter>
);

export default RentalApplicationsRouter;
