import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      position: "relative"
    },
    statusItemContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      margin: "0px 0px 20px 20px"
    },
    listItemStatusIcon: {
      width: "24px",
      height: "24px"
    },
    statusElementsContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap"
    },
    headerDrawing: {
      position: "absolute",
      right: 50,
      bottom: -59,
      zIndex: -1000
    },
    editButtonWrapper: {
      backgroundColor: theme.palette.blue.main
    }
  })
);
