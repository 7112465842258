import React, { useMemo } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import type { FillColors } from "DLUI/icon";
import { Icon } from "DLUI/icon";
import Text from "DLUI/text";
import type { TextColor } from "DLUI/text/text";

import makeStyles from "./styles";
import "./styles.css";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import type { SVGIconComponent } from "assets/icons/types";

import { useResponsiveHelper } from "../../../contexts/responsiveContext";

type ButtonSize = "small" | "medium";
export type ButtonType = "inlineText" | "inLineTextGray";
export type ButtonColor =
  | "gray"
  | "white"
  | "blue"
  | "lightBlue"
  | "lightBluePrimary"
  | "pink"
  | "pinkBG"
  | "blackBG"
  | "error"
  | "black"
  | "navyBlueButton"
  | "blueBG";

export interface ButtonComponentProps {
  onClick: () => void;
  actionText?: string;
  size?: ButtonSize;
  color?: ButtonColor;
  LeftIcon?: SVGIconComponent;
  RightIcon?: React.ComponentType<any>;
  selected?: boolean;
  marginRight?: number | string;
  marginLeft?: number | string;
  children?: React.ReactNode;
  inline?: boolean;
  marginTop?: number;
  marginBottom?: number;
  type?: ButtonType;
  applyColorForIcons?: boolean;
  disabled?: boolean;
  paddingLeft?: number;
  renderRightSideIcon?: () => void;
  iconSize?: number;
  textRightPadding?: number;
  textLeftPadding?: number;
  iconColor?: FillColors;
  requestInProgress?: boolean;
  width?: number | string;
  minWidth?: number;
  clearance?: AnyPermissionClearance;
  height?: number;
  iconCircleFill?: FillColors;
  noUnderline?: boolean;
  dataCy?: string;
}

/**
 * @deprecated use {@link DLButton} instead
 * @see {@link DLButton}
 * */
const DLUI_Button: React.FC<ButtonComponentProps> = ({
  onClick,
  actionText,
  size,
  color,
  LeftIcon,
  RightIcon,
  marginRight,
  marginLeft,
  marginTop,
  marginBottom,
  children,
  inline,
  type,
  disabled,
  renderRightSideIcon,
  paddingLeft,
  iconSize,
  textRightPadding,
  textLeftPadding,
  iconColor,
  requestInProgress,
  width,
  minWidth,
  clearance,
  height,
  iconCircleFill,
  noUnderline,
  dataCy
}) => {
  const classes = makeStyles();
  const defaultButtonHeight = 50;
  const defaultButtonMobileWidth = "40px";

  const { isMobile } = useResponsiveHelper();

  const buttonColorClass = useMemo(() => {
    switch (color) {
      case "gray":
        return classes.grayButton;
      case "white":
        return classes.whiteButton;
      case "pink":
        return classes.pinkButton;
      case "pinkBG":
        return classes.pinkBGbutton;
      case "blackBG":
        return classes.blackBGbutton;
      case "blueBG":
        return classes.blueBGButton;
      case "navyBlueButton":
        return classes.navyBlueButton;
      case "blue":
        return classes.blueButton;
      case "lightBlue":
        return classes.lightBlueButton;
      case "lightBluePrimary":
        return classes.lightBluePrimaryButton;
      case "error":
        return classes.errorButton;
    }
  }, []);

  const buttonSizeClass = useMemo(() => {
    switch (size) {
      case "medium":
        if (inline) {
          return classes.mediumInlineButton;
        }
        return classes.mediumButton;
      case "small":
        return classes.smallButton;
    }
  }, []);

  const renderLeftSideIcon = () => {
    if (LeftIcon) {
      return (
        <Icon
          pathColor={iconColor}
          circleFill={iconCircleFill}
          Source={LeftIcon}
          width={iconSize || 20}
          height={iconSize || 20}
          marginRight={10}
        />
      );
    }
  };
  const _renderRightSideIcon = () => {
    if (renderRightSideIcon) {
      return renderRightSideIcon();
    }
    if (RightIcon) {
      return <RightIcon className={classes.icon} />;
    }
    if (requestInProgress) {
      return <CircularProgress style={{ width: 20, height: 20, marginLeft: 20, color: "#fff" }} />;
    }
  };

  const buttonTypeClass = useMemo(() => {
    switch (type) {
      case "inlineText":
        return classes.inlineTextButton;
    }
  }, []);

  const buttonTextColor = useMemo((): TextColor => {
    switch (color) {
      case "blue":
        return "blue";
      case "pink":
        return "pink";
      case "white":
        return "gray";
      case "lightBlue":
        return "lightBlue";
      case "black":
        return "black";
      case "error":
        return "error";
      default:
        return "white";
    }
  }, []);

  let _peddingLeft = "0px";
  if (paddingLeft) {
    _peddingLeft = `${paddingLeft}px`;
  }

  const customBorderRadius = 10;

  const styleObject = {
    marginTop: marginTop ? marginTop : 0,
    marginRight: marginRight ? marginRight : "0px",
    marginLeft: marginLeft ? marginLeft : "0px",
    marginBottom: marginBottom || "0px",
    borderRadius: customBorderRadius,
    paddingLeft: _peddingLeft
  };
  if (minWidth) {
    styleObject["minWidth"] = isMobile ? (minWidth ? minWidth + "px" : defaultButtonMobileWidth) : minWidth + "px";
  }
  if (height) {
    styleObject["height"] = (height || defaultButtonHeight) + "px";
  }
  if (width === "100%") {
    styleObject["width"] = isMobile ? defaultButtonMobileWidth : "100%";
  }

  return (
    <RestrictedPermissionAccess clearance={clearance}>
      <Button
        classes={{
          root: clsx([
            "DLUI_Button",
            buttonColorClass,
            buttonSizeClass,
            color,
            buttonTypeClass,
            inline ? "inline" : "",
            type
          ])
        }}
        style={styleObject}
        disableFocusRipple
        variant="contained"
        onClick={onClick}
        disabled={disabled || false}
        data-cy={dataCy}
      >
        {renderLeftSideIcon()}
        {children}
        {!children ? (
          <Text
            underLine={type === "inlineText" && !noUnderline ? "solid" : undefined}
            color={buttonTextColor}
            value={actionText}
            paddingRight={textRightPadding}
            paddingLeft={textLeftPadding}
          />
        ) : null}
        {_renderRightSideIcon()}
      </Button>
    </RestrictedPermissionAccess>
  );
};
export default DLUI_Button;
