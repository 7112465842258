import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import { vendorsApi } from "api/vendorsApi";
import type { ExpenseDto, VendorBillDto, VendorCreditDto } from "@doorloop/dto";
import { ExpenseTransactionLineDto } from "@doorloop/dto";
import type { FormikContextType } from "formik";

type FormTypes = ExpenseDto | VendorCreditDto | VendorBillDto;

interface UseVendorAccountsPopulationProps<T extends FormTypes> {
  vendorId?: string;
  formik: FormikContextType<T>;
}

export function useVendorAccountsPopulation<T extends FormTypes>({
  vendorId,
  formik
}: UseVendorAccountsPopulationProps<T>) {
  const [shouldPopulatedPayeeLines, setShouldPopulatedPayeeLines] = useState(true);

  const vendorAccounts = useMemo(() => {
    if (vendorId) {
      const vendorData = vendorsApi.getItemFromDictionary(vendorId);
      return vendorData?.accounts || [];
    }
  }, [vendorId]);

  const isSingleVendorAccount = useMemo(() => _.size(vendorAccounts) === 1, [vendorAccounts]);

  const shouldOverrideFormLines = useMemo(
    () => formik.values.lines && _.size(formik.values.lines) === 1 && _.size(formik.values.lines[0]) === 1,
    [formik.values.lines]
  );

  const populateVendorAccounts = useCallback(() => {
    if (!vendorId || !shouldPopulatedPayeeLines || !_.size(vendorAccounts)) {
      return;
    }

    let formLines = formik.values.lines || [];
    const vendorLines = vendorAccounts.map(
      (account: string) => new ExpenseTransactionLineDto({ account, uniqueKey: uuid() })
    );

    if (shouldOverrideFormLines) {
      formLines = [];
    }
    formik.setFieldValue("lines", [...formLines, ...vendorLines]);

    setShouldPopulatedPayeeLines(false);
  }, [vendorId, shouldPopulatedPayeeLines, formik, vendorAccounts, shouldOverrideFormLines]);

  useEffect(() => {
    populateVendorAccounts();
  }, [vendorId, populateVendorAccounts, formik.values.lines]);

  return {
    shouldPopulatedPayeeLines,
    vendorAccounts,
    populateVendorAccounts,
    isSingleVendorAccount
  };
}
