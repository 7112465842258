import React, { useCallback, useEffect, useMemo, useState } from "react";
import { View } from "DLUI/view";
import { actionsPanelIconsTypesEnums, actionsPanelTypesEnums } from "DLUI/form/fieldsActionsPanel/actionPanels/enums";
import type { ActionsPanelInterface } from "DLUI/form/fieldsActionsPanel/actionPanels/useActionsPanels";
import ApiKeysFieldsItem from "./apiKeysFieldsItem";
import ActionsPanelFieldsNewLine from "DLUI/form/fieldsActionsPanel/actionsPanelFieldsNewLine";
import { copyToClipboard } from "../../../../utils/clipboard";
import ApiKeyLineItem from "screens/settings/apiKeys/apiKeyLineItem";
import { ObjectPermission } from "@doorloop/dto";
import BackgroundImageView from "DLUI/view/backgroundImageView";
import AppStrings from "../../../../locale/keys";
import { handleToast } from "store/toast/actions";
import { useDispatch } from "react-redux";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

export interface ApiKeysDialogViewProps {
  apiKeysData: any[];
  createApiKey: (name) => void;
  updateApiKey: (id: string, name: string) => void;
  deleteApiKey: (id: string) => void;
  toggleSecretsVisibility: (secret) => void;
}

const NewLineFields = ({ isValid, onChange, name }: any) => (
  <ApiKeysFieldsItem defaultValue={name} onChange={onChange} label={"Key Name"} isValid={isValid} />
);

const ApiKeysDialogView: React.FC<ApiKeysDialogViewProps> = ({
  apiKeysData,
  createApiKey,
  updateApiKey,
  deleteApiKey,
  toggleSecretsVisibility
}: ApiKeysDialogViewProps) => {
  const dispatch = useDispatch();

  const { screenContainerPadding } = useResponsiveHelper();
  const [addLineMode, setAddLineMode] = useState(false);
  const [newApiKeyName, setNewApiKeyName] = useState({ name: "" });

  useEffect(() => {
    if (!apiKeysData.length) {
      setAddLineMode(true);
    }
  }, [addLineMode, apiKeysData]);

  // FIELDS VALIDATION
  const apiKeyNameValidation = useCallback(
    (data): boolean => Boolean(data.name.trim().length) && data.name.trim().length <= 100,
    []
  );

  // EVENTS HANDLERS
  const handleNewLineChange = useCallback((ev) => setNewApiKeyName({ name: ev.target.value }), []);

  // ACTIONS HANDLERS
  const handleViewAction = useCallback(
    (_, { data, iconsConf, editMode }) => {
      iconsConf?.setViewHideToggleIcon((prevType: actionsPanelIconsTypesEnums) => {
        if (editMode) {
          return actionsPanelIconsTypesEnums.SHOW_ACTION_PANEL;
        }

        toggleSecretsVisibility(data?.id);

        return prevType === actionsPanelIconsTypesEnums.SHOW_ACTION_PANEL
          ? actionsPanelIconsTypesEnums.HIDE_ACTION_PANEL
          : actionsPanelIconsTypesEnums.SHOW_ACTION_PANEL;
      });
    },
    [apiKeysData]
  );

  const handleCopyAction = useCallback((_, { data }) => {
    copyToClipboard(data.secret);
    dispatch(
      handleToast({
        severity: "neutral",
        translationKey: AppStrings.Toasts.custom.copy.apiKey
      })
    );
  }, []);

  const handleCreateApiKey = useCallback(async (_, { data }) => {
    const { name } = data;

    await createApiKey(name);

    setAddLineMode(false);
    handleAbortNewLineApiKey();
  }, []);

  const handleAbortNewLineApiKey = useCallback(() => setNewApiKeyName({ name: "" }), []);

  const handleEditApiKey = useCallback(async (_, options) => {
    const { id, name } = options.data;

    await updateApiKey(id, name);

    handleViewAction(_, options);
  }, []);

  const handleRemoveApiKey = useCallback(async (_, { data }) => {
    const { id } = data;

    await deleteApiKey(id);
  }, []);

  // API KEYS ACTIONS PANEL STRUCTURE
  const apiKeysActionsPanel: ActionsPanelInterface = useMemo(() => {
    return {
      type: actionsPanelTypesEnums.READ_ONLY_ACTIONS_PANEL,
      actions: [
        {
          type: actionsPanelIconsTypesEnums.SHOW_ACTION_PANEL,
          clearance: { permission: ObjectPermission.apiKeys, field: "viewOne" },
          pathColor: "blue",
          onClick: handleViewAction
        },
        {
          type: actionsPanelIconsTypesEnums.COPY_ACTION_PANEL,
          clearance: { permission: ObjectPermission.apiKeys, field: "viewOne" },
          pathColor: "blue",
          onClick: handleCopyAction
        },
        {
          type: actionsPanelIconsTypesEnums.EDIT_ACTION_PANEL,
          clearance: { permission: ObjectPermission.apiKeys, field: "edit" },
          pathColor: "blue",
          useSwitchAnimation: true
        },
        {
          type: actionsPanelIconsTypesEnums.REMOVE_ACTION_PANEL,
          clearance: { permission: ObjectPermission.apiKeys, field: "delete" },
          useSwitchAnimation: true
        }
      ]
    };
  }, []);

  return (
    <View
      marginBottom={20}
      paddingRight={screenContainerPadding}
      paddingLeft={screenContainerPadding}
      height={"100%"}
      width={"100%"}
      flex={1}
    >
      <View>
        <BackgroundImageView publicFileName={"url(/images/zapier-api-keys.png)"} height={100} width={100} />
      </View>
      <View noWrap>
        {Boolean(apiKeysData?.length) &&
          apiKeysData.map((data) => (
            <ApiKeyLineItem
              validation={apiKeyNameValidation}
              key={data.id}
              apiKeyData={data}
              apiKeysActionsPanel={apiKeysActionsPanel}
              onRemove={handleRemoveApiKey}
              onEdit={handleEditApiKey}
            />
          ))}
      </View>

      <ActionsPanelFieldsNewLine
        addLineMode={addLineMode}
        setAddLineMode={setAddLineMode}
        newLineOnChange={handleNewLineChange}
        abortNewLineAction={handleAbortNewLineApiKey}
        fieldsValues={newApiKeyName}
        NewLineFields={NewLineFields}
        onCreate={handleCreateApiKey}
        validation={apiKeyNameValidation}
        clearance={{ permission: ObjectPermission.apiKeys, field: "create" }}
        staticNewLine={!apiKeysData.length}
      />
    </View>
  );
};

export default ApiKeysDialogView;
