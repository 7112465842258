import React, { Fragment } from "react";
import IconButton from "@material-ui/core/IconButton";
import { AddIcon, DeleteIcon } from "assets/icons";
import { AnimatableView } from "DLUI/animatableView";
import { Button } from "DLUI/button";
import { SelectWithInput } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { TenantDto } from "@doorloop/dto";
import { DisplayEmailDto, DisplayPhoneDto, EmailType, PhoneType } from "@doorloop/dto";
import type { FormikProps } from "formik";
import { FieldArray, getIn, useFormikContext } from "formik";
import AppStrings from "locale/keys";
import { v4 as uuid } from "uuid";
import type { SelectInputProps } from "../form/select/selectInput";

export enum ContactInfoType {
  Phone,
  Email
}

interface ComponentProps {
  formikRef?: FormikProps<any>;
  type: ContactInfoType;
  maxElementsToRender: number;
  fieldsArrayName: string;
  titleText: string;
  marginTop?: number;
}

export const MAX_PHONE_NUMBERS = 5;
export const MAX_EMAIL_NUMBERS = 3;

const ContactInfoElement: React.FC<ComponentProps> = ({
  type,
  maxElementsToRender,
  fieldsArrayName,
  titleText,
  marginTop
}: ComponentProps) => {
  const currentFormikRef = useFormikContext<TenantDto>();

  if (currentFormikRef === null) {
    return <div />;
  }
  const currentArray = type === ContactInfoType.Phone ? currentFormikRef.values.phones : currentFormikRef.values.emails;

  const renderElements = () => (
    <FieldArray
      name={fieldsArrayName}
      render={(arrayHelpers) => {
        const didPressAddElement = () => {
          let contactDto: DisplayPhoneDto | DisplayEmailDto | null = null;
          if (type === ContactInfoType.Phone) {
            contactDto = new DisplayPhoneDto();
            contactDto.uniqueIndex = uuid();
            contactDto.type = PhoneType.OTHER;
            arrayHelpers.push(contactDto);
          } else {
            contactDto = new DisplayEmailDto();
            contactDto.type = EmailType.SECONDARY;
            arrayHelpers.push(contactDto);
          }
        };

        const shouldShowAddNewButton = (): boolean => {
          let numOfAddedItems = 0;
          currentArray?.forEach((currentElement) => {
            if (currentElement.shouldShow) {
              numOfAddedItems += 1;
            }
          });
          return numOfAddedItems < maxElementsToRender;
        };

        const renderAddNewButton = () => {
          const renderButton = shouldShowAddNewButton();
          return (
            <AnimatableView shouldShow={renderButton}>
              <View paddingLeft={20}>
                <Button
                  color={"lightBlue"}
                  type={"inlineText"}
                  actionText={AppStrings.Tenants.NewTenant.AddAnother}
                  onClick={didPressAddElement}
                  LeftIcon={AddIcon}
                  iconSize={15}
                  marginTop={10}
                  applyColorForIcons
                />
              </View>
            </AnimatableView>
          );
        };

        const elements = currentArray?.map((value: any, index: number) => {
          let inputName = "address";
          let inputLabel = AppStrings.Tenants.NewTenant.Email;
          let selectKeysEnum: typeof PhoneType | typeof EmailType = EmailType;

          if (type === ContactInfoType.Phone) {
            inputName = "number";
            inputLabel = AppStrings.Tenants.NewTenant.Phone;
            selectKeysEnum = PhoneType;
          }

          const didPressDeleteContactButton = () => {
            arrayHelpers.remove(index);
          };

          const itemIndex = getIn(currentFormikRef.values, `${fieldsArrayName}[${index}].uniqueIndex`);
          const onTransformSelect: SelectInputProps["onTransform"] = (items) =>
            items.map(({ value, label }) => {
              return { label, value: selectKeysEnum[value] };
            });

          if (index === 0) {
            return (
              <View width={"95%"} flexDirection={"row"} key={itemIndex}>
                <View flex={1}>
                  <SelectWithInput
                    selectName={`${fieldsArrayName}[${index}].type`}
                    inputName={`${fieldsArrayName}[${index}].${inputName}`}
                    inputLabel={inputLabel}
                    selectKeysEnum={selectKeysEnum}
                    removeTopPadding
                    onTransformSelect={onTransformSelect}
                  />
                </View>
                <View width={50} />
              </View>
            );
          }
          return (
            <View width={"95%"} key={itemIndex} marginTop={10}>
              <View flexDirection={"row"} alignItems={"center"}>
                <View flex={1}>
                  <SelectWithInput
                    selectName={`${fieldsArrayName}[${index}].type`}
                    inputName={`${fieldsArrayName}[${index}].${inputName}`}
                    inputLabel={inputLabel}
                    selectKeysEnum={selectKeysEnum}
                    onTransformSelect={onTransformSelect}
                  />
                </View>
                <View width={50}>
                  <View justifyContent={"center"} alignItems={"center"}>
                    <IconButton onClick={didPressDeleteContactButton} aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </View>
                </View>
              </View>
            </View>
          );
        });

        return (
          <Fragment>
            {elements}
            {renderAddNewButton()}
          </Fragment>
        );
      }}
    />
  );

  return (
    <View marginTop={marginTop}>
      <View alignItems={"center"} backgroundColor={"dark"} borderRadius={10} paddingBottom={20}>
        <View justifyContent={"center"} height={50}>
          <Text color={"black"} fontSize={16} fontWeight={700} value={titleText} marginLeft={20} />
        </View>
        <SeparationLine marginBottom={20} height={1} width={"95%"} />
        {renderElements()}
      </View>
    </View>
  );
};

export default ContactInfoElement;
