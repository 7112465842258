import React from "react";
import type { BulkPaymentWrapperDto, LeaseTenantDto } from "@doorloop/dto";
import type { EditableTableColumn } from "DLUI/editableTable/editableTable";
import { accountsApi } from "api/accounts";
import { propertiesApi } from "api/propertiesApi";
import { unitsApi } from "api/unitsApi";
import AppStrings from "locale/keys";
import { Link } from "DLUI/link";
import { Icon } from "DLUI/icon";
import { DeleteIcon } from "assets/.";

export const getReviewTableColumns = ({
  leaseTenants,
  onDeleteClicked
}: {
  leaseTenants: LeaseTenantDto[];
  onDeleteClicked: (dto: BulkPaymentWrapperDto, index: number) => void;
}) => {
  // convert leaseTenants to a dictionary
  const leaseTenantsDictionary = leaseTenants.reduce(
    (acc, curr) => {
      acc[curr.id!] = curr;
      return acc;
    },
    {} as Record<string, LeaseTenantDto>
  );

  const columns: ReadonlyArray<EditableTableColumn<BulkPaymentWrapperDto>> = [
    {
      field: "property",
      headerName: AppStrings.Common.FilterOptions.Property,
      gridSize: 2,
      valueGetter: (dto, t) =>
        dto.property
          ? propertiesApi.getItemFromDictionary(dto.property!)?.name ?? t(AppStrings.Common.NotAvailableAbbreviated)
          : ""
    },
    {
      field: "unit",
      headerName: AppStrings.Common.FilterOptions.Unit,
      gridSize: 2,
      valueGetter: (dto, t) =>
        dto.unit ? unitsApi.getItemFromDictionary(dto.unit)?.name ?? t(AppStrings.Common.NotAvailableAbbreviated) : ""
    },
    {
      field: "receivedFrom",
      headerName: AppStrings.BulkPayments.ReceivedFrom,
      gridSize: 2,
      valueGetter: (dto, t) =>
        leaseTenantsDictionary[dto.receivedFromTenant!]?.name ?? t(AppStrings.Common.NotAvailableAbbreviated)
    },
    {
      field: "depositAccount",
      headerName: AppStrings.BulkPayments.DepositAccount,
      gridSize: 2,
      valueGetter: (dto, t) =>
        accountsApi.getItemFromDictionary(dto.depositToAccount!)?.name ?? t(AppStrings.Common.NotAvailableAbbreviated)
    },
    {
      field: "balance",
      headerName: AppStrings.BulkPayments.Balance,
      gridSize: 2,
      align: "right",
      formatType: "currency",
      valueGetter: (dto, t) => (dto.totalBalanceDue || 0).toString()
    },
    {
      field: "amountReceived",
      headerName: AppStrings.BulkPayments.AmountReceived,
      gridSize: true,
      align: "right",
      formatType: "currency",
      valueGetter: (dto, t) => (dto.amountReceived || 0).toString()
    },
    // action buttons column
    {
      field: "actionButtons",
      headerName: "",
      gridSize: "auto",
      align: "right",
      renderEditableCell: (dto, index, t) => (
        <Link onClick={() => onDeleteClicked(dto, index)}>
          <Icon size={18} Source={DeleteIcon} />
        </Link>
      )
    }
  ] as const;

  return columns;
};
