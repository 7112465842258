import type { FC } from "react";
import React, { useCallback, useState } from "react";
import { FastField, getIn, useFormikContext } from "formik";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { FormikDatePicker } from "DLUI/form";
import { AnimatedView } from "DLUI/view/animatedView";
import moment from "moment";
import { ControlledCheckBox } from "DLUI/form/checkBox/base/controlledCheckBox";

const pinToHomeKey = "portalInfo.pinToHome";
const expiresOnKey = "portalInfo.expiresOn";

export const PortalDescription: FC = () => {
  const formikRef = useFormikContext();

  const portalInfoShouldExpire = getIn(formikRef.values, "portalInfoShouldExpire");

  const [shouldExpire, setShouldExpire] = useState<boolean>(
    portalInfoShouldExpire !== undefined ? portalInfoShouldExpire : Boolean(getIn(formikRef.values, expiresOnKey))
  );
  const [pinToHome, setPinToHome] = useState<boolean>(Boolean(getIn(formikRef.values, pinToHomeKey)));

  const togglePinHome = useCallback(() => {
    setPinToHome((value) => {
      const newValue = !value;

      formikRef.setFieldValue(pinToHomeKey, newValue);

      return newValue;
    });
  }, []);

  const toggleShouldExpire = useCallback(() => {
    setShouldExpire((value) => {
      const newValue = !value;

      formikRef.setFieldValue("portalInfoShouldExpire", newValue);

      if (!newValue) {
        formikRef.setFieldValue(expiresOnKey, undefined);
      }

      return newValue;
    });
  }, []);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <View marginLeft={-8}>
        <Text value={AppStrings.Announcements.sendByTenantPortalDesc} marginLeft={9} marginBottom={10} />
        <ControlledCheckBox
          checked={pinToHome}
          onChange={togglePinHome}
          labelValueProps={{
            text: AppStrings.Announcements.pinToPortalHomeQuestion,
            color: "black"
          }}
        />
        <ControlledCheckBox
          checked={shouldExpire}
          onChange={toggleShouldExpire}
          labelValueProps={{
            text: AppStrings.Announcements.portalShouldExpireQuestion,
            color: "black"
          }}
        />
        <AnimatedView shouldShow={shouldExpire}>
          <View width={210} marginLeft={9}>
            <FastField
              component={FormikDatePicker}
              label={AppStrings.Vendors.NewVendor.ExpirationDate}
              name={expiresOnKey}
              uniqueKey={"expiresOn"}
              minDate={moment()}
              required
            />
          </View>
        </AnimatedView>
      </View>
    </MuiPickersUtilsProvider>
  );
};
