import { FileDto, LinkedResourceDto, LinkedResourceType, ServerRoutes } from "@doorloop/dto";
import { apiHelper } from "api/apiHelper";
import { FilesApi } from "api/filesApi";
import { RestApiBase } from "api/restApiBase";
import { rankSorter } from "utils/dataStructureUtils";
import { compressImage } from "utils/imageCompression";
import { ApiResult } from "./apiResult";

import type { GetAllBaseQueryResponse, PictureDto, PropertyDto, UnitDto } from "@doorloop/dto";
import type { GetAllBaseQueryRequest } from "../../../packages/dto/src/getAllBaseRequest.query";

export interface GetAllPicturesQuery extends GetAllBaseQueryRequest {
  resourceId?: string;
}

export class PicturesApi extends RestApiBase<PictureDto, GetAllPicturesQuery> {
  private filesApi: FilesApi;
  private readonly resourceType: LinkedResourceType;
  private readonly baseRoute: string;

  constructor(pictureRoute: ServerRoutes, baseRoute: ServerRoutes, resourceType: LinkedResourceType) {
    super(pictureRoute);

    this.resourceType = resourceType;
    this.filesApi = new FilesApi(pictureRoute);
    this.baseRoute = baseRoute;
  }

  async getAll(query: GetAllPicturesQuery): Promise<ApiResult<GetAllBaseQueryResponse<PictureDto>>> {
    const apiResult = await apiHelper.axiosGet<PropertyDto | UnitDto>(`${this.baseRoute}/${query.resourceId}`);

    apiResult.data?.pictures?.sort(rankSorter);

    return new ApiResult({
      data: apiResult.data?.pictures || [],
      total: apiResult.data?.pictures?.length || 0
    });
  }

  async uploadPicture(file: File, linkedResourceDto: LinkedResourceDto, id: string, rank = 1) {
    const data = new FormData();

    data.append("file", file);

    const fileDto = new FileDto({
      name: file.name,
      linkedResource: linkedResourceDto,
      rank
    });

    for (const key of Object.keys(fileDto)) {
      const value = fileDto[key];
      if (typeof value === "object" && value !== null) {
        for (const objectKey of Object.keys(value)) {
          data.append(key + "[" + objectKey + "]", value[objectKey]);
        }
      } else {
        data.append(key, value);
      }
    }

    return await apiHelper.axiosPost<FileDto>({ url: this.restRoute + "/pictures/" + id, data });
  }

  async addPicture(resourceId: string, file: File, fileDto: FileDto): Promise<void> {
    const compressedImage = await compressImage(file);
    const { status } = await this.filesApi.upload(
      compressedImage,
      new LinkedResourceDto(resourceId, this.resourceType),
      `/${resourceId}`,
      fileDto
    );
    if (!status) {
      throw "Picture Upload Error";
    }
  }

  async updatePicture(resourceId: string, fileId: string, fileDto: FileDto): Promise<void> {
    const { status } = await this.filesApi.update(fileId, fileDto, undefined, `/${resourceId}/`);

    if (!status) {
      throw "Picture Update Error";
    }
  }

  async deletePictureFromResource(resourceId: string, fileId: string): Promise<ApiResult<object>> {
    const apiResult = await this.filesApi.delete(fileId, undefined, `/${resourceId}/`);

    if (!apiResult.status) {
      throw "Picture Update Error";
    }

    return apiResult;
  }
}

export const resourceTypeToPicturesAPI: Record<LinkedResourceType.Property | LinkedResourceType.Unit, PicturesApi> = {
  [LinkedResourceType.Property]: new PicturesApi(
    ServerRoutes.PROPERTIES_PICTURE,
    ServerRoutes.PROPERTIES,
    LinkedResourceType.Property
  ),
  [LinkedResourceType.Unit]: new PicturesApi(ServerRoutes.UNITS_PICTURE, ServerRoutes.UNITS, LinkedResourceType.Unit)
};
