import React, { useEffect, useRef } from "react";
import { TextField } from "DLUI/form";
import AutoCompleteDataSource from "./dataSource";
import type { FieldProps } from "formik";
import "./styles.css";
import { useDispatch } from "react-redux";
import AppStrings from "../../../../../../locale/keys";
import { handleToast } from "store/toast/actions";

export const ElementID = "AutoCompleteStreetAddress";

interface ComponentProps extends FieldProps<any> {
  label: string;
  required?: boolean;
  className?: string;
  formikName: string;
  onAddressAutocomplete: (selectedPlace: any) => void;
}

const AddressAutoComplete = ({ onAddressAutocomplete, field, formikName, ...rest }: ComponentProps) => {
  const dispatch = useDispatch();
  const autoCompleteDataSourceRef = useRef<AutoCompleteDataSource>(new AutoCompleteDataSource());

  useEffect(() => {
    autoCompleteDataSourceRef.current.init(onAddressAutocomplete, ElementID + formikName);

    return () => {
      autoCompleteDataSourceRef.current.clean();
    };
  }, []);

  useEffect(() => {
    if (field.value && field.value != "") {
      try {
        autoCompleteDataSourceRef.current.getOptions(field.value);
      } catch (e) {
        console.error(e);
        dispatch(
          handleToast({
            severity: "error",
            translationKey: AppStrings.Common.AddressAutoCompleteError
          })
        );
      }
    }
  }, [field.value]);

  return <TextField id={ElementID + formikName} field={field} {...rest} />;
};

export default AddressAutoComplete;
