import type { FC } from "react";
import React from "react";
import { View } from "DLUI/view";
import { Icon } from "DLUI/icon";
import { InfoFullGrayIcon } from "assets/icons";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";

interface Props {}

export const PhysicalCheckInfo: FC<Props> = ({}: Props) => (
  <View backgroundColor={"secondary-gray-light"} borderRadius={10} marginBottom={20}>
    <View marginBottom={20} marginRight={20} marginTop={20} marginLeft={20} autoWidth>
      <View flexDirection={"row"} marginBottom={20}>
        <View autoWidth marginRight={10}>
          <Icon Source={InfoFullGrayIcon} />
        </View>
        <View flex={1}>
          <Text fontSize={18} bold marginBottom={5} value={AppStrings.Common.OutgoingPayments.MailCheck} />
          <Text fontSize={16} value={AppStrings.Common.OutgoingPayments.MailCheckInfoDescription} />
        </View>
      </View>
      <HorizontalSeparationLine marginBottom={20} />
      <Text fontSize={16} value={AppStrings.Common.PleaseNote} bold marginBottom={5} />
      <Text fontSize={16} value={AppStrings.Common.OutgoingPayments.DirectDepositInfoDescription2} />
    </View>
  </View>
);
