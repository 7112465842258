import React from "react";
import FormikMultiSelectAutoComplete from "../formikAsyncAutoComplete/formikMultiSelectAutoComplete";
import { FastField } from "formik";

type ComponentProps = Record<string, any>;

function FormikCachedMultiSelectAutoComplete({ ...restProps }: ComponentProps) {
  return <FastField component={FormikMultiSelectAutoComplete} {...restProps} />;
}

export default FormikCachedMultiSelectAutoComplete;
