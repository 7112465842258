import type { PropsWithChildren } from "react";
import type React from "react";
import { useEffect } from "react";
import { devicesMethods, MobileDeviceBridgeMessages } from "../services/mobileDeviceBridgeService";

const MobileBridgeWrapper: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const initBridgeMethods = () => {
    devicesMethods[MobileDeviceBridgeMessages.OnDeviceEnterForeground]();
    devicesMethods[MobileDeviceBridgeMessages.NavigateDeepLink]();
  };

  useEffect(() => {
    initBridgeMethods();
  }, []);

  return children;
};

export default MobileBridgeWrapper;
