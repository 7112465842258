import type { FC } from "react";
import React from "react";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import type { UserNotificationSettingsDto } from "@doorloop/dto";
import { LoginResponseType } from "@doorloop/dto";
import { View } from "DLUI/view";
import { AccordionItem } from "DLUI/accordionItem";
import upperFirst from "lodash/upperFirst";
import AppStrings from "../../../../../locale/keys";
import { FormikCheckBox } from "DLUI/form";
import Text from "DLUI/text";
import { tenantNotificationFieldNameToSettingOptions, userNotificationFieldNameToSettingOptions } from "../constants";

interface NotificationsSettingsMobileViewProps {
  allowedSettings: Array<keyof UserNotificationSettingsDto>;
  values: UserNotificationSettingsDto;
  userType: LoginResponseType;
}

export const NotificationsSettingsMobileView: FC<NotificationsSettingsMobileViewProps> = ({
  userType,
  values,
  allowedSettings
}) => {
  const settingsOptions =
    userType === LoginResponseType.TENANT
      ? tenantNotificationFieldNameToSettingOptions
      : userNotificationFieldNameToSettingOptions;

  return (
    <View>
      {allowedSettings.map((serviceName) => {
        const isPreferenceDisabled = values[serviceName] === undefined;
        const settings = settingsOptions[serviceName];

        return (
          <View key={serviceName} marginTop={10}>
            <AccordionItem hasIndependentCollapsed title={AppStrings.Notifications.Settings[upperFirst(serviceName)]}>
              {userType === LoginResponseType.TENANT && isPreferenceDisabled && (
                <View alignItems={"flex-start"} justifyContent={"center"} marginLeft={8} marginBottom={12}>
                  <Text color={"secondary-gray"} value={AppStrings.Notifications.Settings.DisabledByPropertyManager} />
                </View>
              )}
              {userType === LoginResponseType.TENANT && (
                <FastFieldSafe
                  component={FormikCheckBox}
                  labelText={AppStrings.Notifications.Settings.Text.Title}
                  name={`${serviceName}.text`}
                  disabled={isPreferenceDisabled || settings?.text?.disabled}
                />
              )}
              <FastFieldSafe
                component={FormikCheckBox}
                labelText={AppStrings.Notifications.Settings.Push.Title}
                name={`${serviceName}.web`}
                disabled={isPreferenceDisabled || settings?.push?.disabled}
              />
              <FastFieldSafe
                component={FormikCheckBox}
                labelText={AppStrings.Notifications.Settings.Email.Title}
                name={`${serviceName}.email`}
                disabled={isPreferenceDisabled || settings?.email?.disabled}
              />
            </AccordionItem>
          </View>
        );
      })}
    </View>
  );
};
