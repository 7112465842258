"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyndicationPersonType = void 0;
const class_validator_1 = require("class-validator");
const syndicationNameType_1 = require("./syndicationNameType");
const syndicationPhoneLine_1 = require("./syndicationPhoneLine");
const syndicationAddressType_1 = require("./syndicationAddressType");
const syndicationIdentifiable_1 = require("./syndicationIdentifiable");
const class_transformer_1 = require("class-transformer");
class SyndicationPersonType extends syndicationIdentifiable_1.SyndicationIdentifiable {
}
exports.SyndicationPersonType = SyndicationPersonType;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => syndicationNameType_1.SyndicationNameType),
    __metadata("design:type", syndicationNameType_1.SyndicationNameType)
], SyndicationPersonType.prototype, "Name", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => syndicationAddressType_1.SyndicationAddressType),
    __metadata("design:type", Array)
], SyndicationPersonType.prototype, "Address", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => syndicationPhoneLine_1.SyndicationPhoneLine),
    __metadata("design:type", Array)
], SyndicationPersonType.prototype, "Phone", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(80),
    __metadata("design:type", String)
], SyndicationPersonType.prototype, "Email", void 0);
