import type { DialogProps } from "./dialogsProvider.types";
import { isEditFromDetailScreen } from "./dialogsProvider.utils";
import { DialogRoutes } from "./dialogsRoutes";
import Routes from "../../../appRouter/routes";
import AppStrings from "locale/keys";
import { VendorBillDialog, VendorBillPaymentDialog, VendorCreaditDialog } from "DLUI/dialogs";
import { ObjectPermission } from "@doorloop/dto";
import ExpenseFormikContextWrapper from "DLUI/dialogs/vendor/expense/expenseFormikContextWrapper";
import { RecurringVendorBillDialog } from "DLUI/dialogs/vendor/bill/recurringVendorBillDialog";
import { RecurringExpense } from "DLUI/dialogs/vendor/expense/recurringExpense";

class VendorsDialogsProvider {
  matchRoutes(pathname: string): DialogProps | undefined {
    if (
      pathname.indexOf(DialogRoutes.NEW_VENDOR) > -1 ||
      pathname.indexOf(DialogRoutes.EDIT_VENDOR) > -1 ||
      isEditFromDetailScreen("vendors", 2, 2, Routes.VENDOR_DETAILS_EDIT, pathname)
    ) {
      return {
        type: "newVendor",
        title:
          pathname.indexOf(DialogRoutes.EDIT_VENDOR) > -1
            ? AppStrings.Vendors.Screen.EditVendor
            : AppStrings.Vendors.Screen.AddNewVendor
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_VENDOR_BILL_PAYMENT) > -1) {
      return {
        type: "vendorBillPayment",
        Content: VendorBillPaymentDialog,
        title: AppStrings.Vendors.VendorDetails.VendorBillPayment
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_RECURRING_VENDOR_BILL) > -1) {
      return {
        type: "newVendorBill",
        Content: RecurringVendorBillDialog,
        title: AppStrings.Vendors.VendorDetails.EditRecurringBill
      };
    }
    if (pathname.indexOf(DialogRoutes.NEW_VENDOR_BILL) > -1) {
      return {
        type: "newVendorBill",
        Content: VendorBillDialog,
        title: AppStrings.Vendors.VendorDetails.NewBill
      };
    }
    if (pathname.indexOf(DialogRoutes.EDIT_VENDOR_BILL) > -1) {
      return {
        type: "newVendorBill",
        Content: VendorBillDialog,
        title: AppStrings.Vendors.VendorDetails.EditBill
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_RECURRING_EXPENSE) > -1) {
      return {
        type: "newVendorExpense",
        Content: RecurringExpense,
        title: AppStrings.Vendors.VendorDetails.EditRecurringExpense,
        permission: { permission: ObjectPermission.expenses, field: "create" }
      };
    }

    if (pathname.indexOf(DialogRoutes.NEW_VENDOR_EXPENSE) > -1) {
      return {
        type: "newVendorExpense",
        Content: ExpenseFormikContextWrapper,
        title: AppStrings.Vendors.VendorDetails.NewExpense,
        permission: { permission: ObjectPermission.expenses, field: "create" }
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_EXPENSE) > -1) {
      return {
        type: "newVendorExpense",
        Content: ExpenseFormikContextWrapper,
        title: AppStrings.Vendors.VendorDetails.EditExpense,
        permission: { permission: ObjectPermission.expenses, field: "viewOne" }
      };
    }

    if (
      pathname.indexOf(DialogRoutes.NEW_VENDOR_CREDIT) > -1 ||
      pathname.indexOf(DialogRoutes.EDIT_VENDOR_CREDIT) > -1
    ) {
      return {
        type: "newVendorCredit",
        Content: VendorCreaditDialog,
        title: AppStrings.Vendors.VendorDetails.NewCredit
      };
    }
    return undefined;
  }
}

export const vendorsDialogsProvider = new VendorsDialogsProvider();
