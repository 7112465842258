import React, { Fragment } from "react";
import { View } from "DLUI/view";
import { propertiesApi } from "api/propertiesApi";
import { LocationIcon, TenantsIcon, UnitIcon } from "../../../../assets";
import { unitsApi } from "api/unitsApi";
import { Grid, ListItemSection } from "DLUI/listItems";
import AppStrings from "../../../../locale/keys";
import { Icon } from "DLUI/icon";
import { DefaultListItemFontSize, DefaultListItemIconSize } from "screens/leases/leases/leasesList/leaseListItem";
import ListItemText from "DLUI/listItems/listItemText";
import Text from "DLUI/text";
import { tenantsApi } from "api/tenantsApi";
import type { GridSpace } from "DLUI/listItems/grid";

interface ComponentProps {
  shouldShow?: boolean;
  onClick?: () => void;
  property?: string;
  unit?: string;
  tenant?: string;
  emptyDataView?: JSX.Element;
  sm?: GridSpace;
}

const RelatedToGridColumn: React.FC<ComponentProps> = ({
  shouldShow = true,
  onClick,
  property,
  unit,
  tenant,
  emptyDataView,
  sm
}: ComponentProps) => {
  let topRowIcon;
  let topRowText;
  let bottomRowIcon;
  let bottomRowText;

  if (emptyDataView) {
    return (
      <Grid
        title={AppStrings.Tasks.InternalTaskDialog.RelatedTo}
        showDivider
        xs={12}
        sm={sm}
        md={2}
        lg={2}
        onClick={onClick}
      >
        {emptyDataView}
      </Grid>
    );
  }

  if (shouldShow) {
    if (tenant) {
      const tenantsDictionary = tenantsApi.getItemFromDictionary(tenant);
      if (tenantsDictionary) {
        topRowIcon = TenantsIcon;
        topRowText = tenantsDictionary.name;
      }
    } else {
      if (property) {
        const propertyDictionary = propertiesApi.getItemFromDictionary(property);
        if (propertyDictionary) {
          topRowIcon = LocationIcon;
          topRowText = propertyDictionary?.name;
        }
      }
      if (unit) {
        const unitDictionary = unitsApi.getItemFromDictionary(unit);
        bottomRowIcon = UnitIcon;
        bottomRowText = unitDictionary?.name;
      }
    }
    if (topRowIcon && topRowText && (bottomRowIcon === undefined || bottomRowText === undefined)) {
      return (
        <Grid
          title={AppStrings.Tasks.InternalTaskDialog.RelatedTo}
          showDivider
          xs={12}
          sm={sm}
          md={2}
          lg={2}
          onClick={onClick}
        >
          <View flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} paddingRight={10} noWrap>
            <Icon
              Source={topRowIcon}
              marginRight={5}
              pathColor={"black"}
              width={DefaultListItemIconSize}
              height={DefaultListItemIconSize}
            />
            <ListItemText overflow={"ellipsis"}>{topRowText}</ListItemText>
          </View>
        </Grid>
      );
    }

    if (topRowIcon && topRowText && bottomRowIcon && bottomRowText) {
      return (
        <Grid
          title={AppStrings.Tasks.InternalTaskDialog.RelatedTo}
          showDivider
          xs={12}
          sm={sm}
          md={2}
          lg={2}
          onClick={onClick}
        >
          <ListItemSection
            renderTitle={
              <Fragment>
                {topRowIcon && topRowText ? (
                  <View
                    flexDirection={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    paddingRight={10}
                    noWrap
                  >
                    <Icon
                      Source={topRowIcon}
                      marginRight={5}
                      pathColor={"black"}
                      width={DefaultListItemIconSize}
                      height={DefaultListItemIconSize}
                    />
                    <ListItemText overflow={"ellipsis"}>{topRowText}</ListItemText>
                  </View>
                ) : null}
              </Fragment>
            }
            renderSubTitle={
              <View justifyContent={"flex-start"} alignItems={"center"} flexDirection={"row"} paddingRight={10} noWrap>
                {bottomRowIcon && bottomRowText ? (
                  <Fragment>
                    <Icon
                      pathColor={"black"}
                      Source={bottomRowIcon}
                      width={DefaultListItemIconSize}
                      height={DefaultListItemIconSize}
                    />
                    <ListItemText marginLeft={5} numberOfLines={1}>
                      {bottomRowText}
                    </ListItemText>
                  </Fragment>
                ) : null}
              </View>
            }
          />
        </Grid>
      );
    }

    return (
      <Grid
        title={AppStrings.Tasks.InternalTaskDialog.RelatedTo}
        showDivider
        xs={12}
        sm={sm}
        md={2}
        lg={2}
        onClick={onClick}
      >
        <Text fontSize={DefaultListItemFontSize} value={AppStrings.Tasks.TasksList.NotSet} />
      </Grid>
    );
  }

  return <View />;
};

export default RelatedToGridColumn;
