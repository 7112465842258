import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) =>
  createStyles({
    filterActiveIndicator: {
      backgroundColor: theme.palette.pink.main,
      height: 6,
      width: 6,
      position: "absolute",
      right: 4,
      top: 6,
      overflow: "visible",
      borderRadius: 6,
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    actionMenuHoverContainer: {
      "& .moreIconButton": {
        visibility: "hidden"
      },
      "&:hover .moreIconButton": {
        visibility: "visible"
      }
    },
    conversationListItemContainer: {
      "&:hover": {
        backgroundColor: "rgba(22, 101, 216, 0.04)"
      }
    },
    assigneeMultiSelect: {
      "& .MuiAutocomplete-clearIndicator": {
        display: "none"
      },
      "& .MuiAutocomplete-popupIndicator": {
        display: "none"
      },
      "& .MuiAutocomplete-inputRoot": {
        paddingTop: "8px !important",
        height: "30px !important",
        fontSize: "14px !important"
      }
    },
    recipientUnderline: {
      "& .MuiInput-underline:before": {
        borderBottom: "2px solid black",
        transition: "unset"
      },
      "& .MuiInput-underline:after": {
        borderBottom: "2px solid #1665D8",
        transition: "unset"
      },
      "& .MuiInputBase-input": {
        paddingLeft: "40px !important",
        paddingBottom: "15px !important",
        fontSize: "14px !important"
      },
      "& .MuiAutocomplete-endAdornment": {
        display: "none"
      }
    },
    recipientInputPopper: {
      left: "2px !important",
      [theme.breakpoints.up("sm")]: {
        left: "6px !important"
      }
    },
    recipientInputPaper: {
      borderRadius: "4px !important",
      marginTop: "0px !important",
      boxShadow: "unset"
    },
    conversationInputTextArea: {
      height: "100%",
      flex: 1,
      boxSizing: "border-box",
      paddingTop: 10,
      paddingBottom: 10,
      paddingRight: 68,
      paddingLeft: 12,
      border: "1px solid rgba(122,134,153,0.2)",
      borderRadius: 4,
      backgroundColor: "white",

      "& textarea": {
        color: theme.palette.text.primary
      },

      "& .MuiInput-underline:before .MuiInput-underline:after": {
        borderBottom: "none !important",
        transition: "unset"
      },

      "&:focus-visible": {
        outline: "none"
      },

      "& .MuiInputBase-input": {
        fontSize: "14px !important"
      }
    },
    messageTaskContainer: {
      "&:hover p": {
        fontWeight: "bold !important"
      }
    },
    conversationsList: {
      "&::-webkit-scrollbar-thumb": {
        border: "16px solid transparent"
      },
      "&:hover::-webkit-scrollbar-thumb": {
        border: "6px solid transparent"
      }
    },
    messageAttachmentItemImage: {
      width: "150px",
      height: "100px",
      objectFit: "cover",
      borderRadius: 8,
      cursor: "pointer"
    },
    messageAttachmentItemVideoPlayerContainer: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      height: "100%",
      width: "50%"
    },
    messageAttachmentItemVideoPlayer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      background: "black"
    },
    messageAttachmentItemVideoContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      width: "150px",
      height: "100px"
    },
    messageAttachmentItemVideo: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: 8,
      position: "relative",
      cursor: "pointer"
    },
    messageAttachmentItemVideoPlayIcon: {
      position: "absolute",
      display: "flex",
      justifyContent: "center"
    }
  })
);
