import { ServerRoutes } from "@doorloop/dto";
import { RestApiBase } from "./restApiBase";
import type { PushNotificationDto, GetAllNotificationsQuery } from "@doorloop/dto";
import { apiHelper } from "api/apiHelper";

export class NotificationApi extends RestApiBase<PushNotificationDto, GetAllNotificationsQuery> {
  constructor() {
    super(ServerRoutes.NOTIFICATIONS, []);
    this.restRoute = ServerRoutes.NOTIFICATIONS;
  }

  getTotalUnreadNotifications = async () => await apiHelper.axiosGet(ServerRoutes.TOTAL_UNREAD_NOTIFICATIONS);

  markAllUnreadNotificationsAsRead = async () =>
    await apiHelper.axiosPut({ url: ServerRoutes.MARK_ALL_UNREAD_NOTIFICATIONS });
}

export const notificationApi = new NotificationApi();
