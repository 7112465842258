"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESignatureRequestSignerStatus = void 0;
var ESignatureRequestSignerStatus;
(function (ESignatureRequestSignerStatus) {
    ESignatureRequestSignerStatus["SENT"] = "SENT";
    ESignatureRequestSignerStatus["VIEWED"] = "VIEWED";
    ESignatureRequestSignerStatus["DECLINED"] = "DECLINED";
    ESignatureRequestSignerStatus["SIGNED"] = "SIGNED";
})(ESignatureRequestSignerStatus || (exports.ESignatureRequestSignerStatus = ESignatureRequestSignerStatus = {}));
