"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentMethodNoEpay = exports.PaymentMethod = void 0;
var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["EPAY"] = "EPAY";
    PaymentMethod["CASH"] = "CASH";
    PaymentMethod["CHECK"] = "CHECK";
    PaymentMethod["CREDIT_CARD"] = "CREDIT_CARD";
    PaymentMethod["CASHIERS_CHECK"] = "CASHIERS_CHECK";
    PaymentMethod["MONEY_ORDER"] = "MONEY_ORDER";
    PaymentMethod["EFT"] = "EFT";
    PaymentMethod["DEBIT_CARD"] = "DEBIT_CARD";
    PaymentMethod["OTHER"] = "OTHER";
})(PaymentMethod || (exports.PaymentMethod = PaymentMethod = {}));
var PaymentMethodNoEpay;
(function (PaymentMethodNoEpay) {
    PaymentMethodNoEpay["CASH"] = "CASH";
    PaymentMethodNoEpay["CHECK"] = "CHECK";
    PaymentMethodNoEpay["CREDIT_CARD"] = "CREDIT_CARD";
    PaymentMethodNoEpay["CASHIERS_CHECK"] = "CASHIERS_CHECK";
    PaymentMethodNoEpay["MONEY_ORDER"] = "MONEY_ORDER";
    PaymentMethodNoEpay["EFT"] = "EFT";
    PaymentMethodNoEpay["DEBIT_CARD"] = "DEBIT_CARD";
    PaymentMethodNoEpay["OTHER"] = "OTHER";
})(PaymentMethodNoEpay || (exports.PaymentMethodNoEpay = PaymentMethodNoEpay = {}));
