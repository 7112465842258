import type { CommunicationDto } from "@doorloop/dto";
import { CommunicationType } from "@doorloop/dto";
import type { FC } from "react";
import React from "react";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import type { CommunicationParticipantDto } from "@doorloop/dto";
import { CommunicationLinkedToType } from "@doorloop/dto";
import { View } from "DLUI/view";
import { buildRelatedTypeFoundName, getApiByLinkedType } from "../../../../utils/linkedResourceHelper";
import { useTranslation } from "react-i18next";
import { CommunicationLinkedToTypeTranslations } from "../../../../locale/enumTranslations";

interface ParticipantString {
  name: string;
  type: string;
  tooltip?: string;
}

export const buildParticipantString = (
  { linkedToType = CommunicationLinkedToType.USER, linkedToId, displayName, address }: CommunicationParticipantDto,
  linkedToTypeLabel?: string
): ParticipantString => {
  const doorloopParticipant = {
    name: "DoorLoop",
    type: ""
  };

  const relatedTypeApi = getApiByLinkedType(linkedToType);
  const relatedTypeFoundName = buildRelatedTypeFoundName(relatedTypeApi, linkedToId);

  return linkedToType === CommunicationLinkedToType.SYSTEM
    ? doorloopParticipant
    : {
        name: relatedTypeFoundName || displayName || address,
        type: ` (${linkedToTypeLabel})`,
        tooltip: address
      };
};

const buildToFromSummaryObject = (dto: CommunicationDto) => {
  const { t } = useTranslation();
  const linkedFromTypeLabel = dto.from?.linkedToType && t(CommunicationLinkedToTypeTranslations[dto.from.linkedToType]);
  const linkedToTypeLabel =
    dto.to?.[0].linkedToType && t(CommunicationLinkedToTypeTranslations[dto.to[0].linkedToType]);

  const { name: fromName, type: fromType } = buildParticipantString(dto.from || {}, linkedFromTypeLabel);
  const { name: toName, type: toType } = buildParticipantString(dto.to?.[0] || {}, linkedToTypeLabel);

  let communicationType: string;
  switch (dto.type) {
    case CommunicationType.TEXT:
      communicationType = "a text message";
      break;
    case CommunicationType.PHONE:
      communicationType = "a voice message";
      break;
    default:
      communicationType = "an email";
  }

  return {
    fromName,
    fromType,
    toName,
    toType,
    communicationType
  };
};

interface Props {
  dto: CommunicationDto;
}

export const FromToSummary: FC<Props> = ({ dto }: Props) => (
  <View>
    <Text
      color={"black"}
      align="left"
      fontSize={12}
      value={AppStrings.Announcements.communicationFromToSummary}
      replaceObject={buildToFromSummaryObject(dto)}
    />
  </View>
);
