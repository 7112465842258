import type { CSSProperties, FC } from "react";
import React from "react";
import { View } from "DLUI/view";
import type { FillColors } from "DLUI/icon";
import { Icon } from "DLUI/icon";
import type { SVGIconComponent } from "assets/icons";
import Text from "DLUI/text";
import type { ViewBackgroundColor, ViewComponentProps } from "DLUI/view/view";
import { TextTooltip } from "DLUI/popover/textTooltip";
import { IconButton } from "DLUI/form";
import type { ReplaceObjectInterface } from "DLUI/text/text";
import _ from "lodash";
import type { UserTypes } from "@doorloop/dto";
import { useUserType } from "../../../hooks/useUserType";
import { filterObjectsByUserType } from "../../../utils/userTypesUtils";

const badgeStyle: CSSProperties = {
  position: "absolute",
  background: "white",
  top: "-10px",
  left: "-10px",
  zIndex: 2
};

interface BadgeProps {
  badgeValue: string | number;
}

const Badge: FC<BadgeProps> = ({ badgeValue }: BadgeProps) => (
  <View width={24} height={24} borderRadius={20} alignItems={"center"} justifyContent={"center"} style={badgeStyle}>
    <Text fontSize={11} color={"green"}>
      {badgeValue}
    </Text>
  </View>
);

export interface StatusStepInterface {
  IconSource: SVGIconComponent;
  badgeValue?: string | number;
  connectorColor?: ViewBackgroundColor;
  connectorColorRgbaColor?: string;
  tooltip?: string;
  rectFill?: FillColors;
  pathColor?: FillColors;
  showConnector?: boolean;
  isRawText?: boolean;
  categoryGroup?: string;
  replaceObject?: ReplaceObjectInterface;
  hiddenForUserTypes?: UserTypes[];
  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

interface Props extends ViewComponentProps {
  steps: StatusStepInterface[];
  useDarkTooltip?: boolean;
  dynamicConnectorWidth?: {
    fromSize: number;
    toSize: number;
    width: number;
  };
}

const DEFAULT_CONNECTOR_WIDTH = 10;

export const StepStatus: FC<Props> = ({ steps, useDarkTooltip, dynamicConnectorWidth, ...viewProps }: Props) => {
  const { isHOAUser } = useUserType();

  const filteredSteps = steps && isHOAUser ? filterObjectsByUserType(steps, "HOAUser") : steps;

  const connectorWidth = dynamicConnectorWidth
    ? _.inRange(window.innerWidth, dynamicConnectorWidth.fromSize, dynamicConnectorWidth.toSize)
      ? dynamicConnectorWidth.width
      : DEFAULT_CONNECTOR_WIDTH
    : DEFAULT_CONNECTOR_WIDTH;

  return (
    <View flexDirection={"row"} alignItems={"center"} noWrap {...viewProps}>
      {filteredSteps.map(
        (
          {
            IconSource,
            badgeValue,
            connectorColor,
            rectFill,
            pathColor,
            tooltip,
            showConnector = true,
            isRawText = true,
            replaceObject,
            onClick
          },
          index
        ) => (
          <View key={index} flexDirection={"row"} alignItems={"center"} autoWidth>
            {badgeValue !== undefined && <Badge badgeValue={badgeValue} />}
            {tooltip ? (
              <TextTooltip
                value={tooltip}
                rectFill={rectFill}
                pathColor={pathColor}
                IconSource={IconSource}
                rawText={isRawText}
                useButton={false}
                useDark={useDarkTooltip}
                replaceObject={replaceObject}
              >
                <IconButton
                  Icon={IconSource}
                  rectFill={rectFill}
                  circleFill={rectFill}
                  pathColor={pathColor}
                  removePadding
                  onClick={onClick}
                />
              </TextTooltip>
            ) : (
              <Icon rectFill={rectFill} circleFill={rectFill} Source={IconSource} pathColor={pathColor} />
            )}
            {showConnector && index < filteredSteps.length - 1 ? (
              <View height={5} width={connectorWidth} backgroundColor={connectorColor || "green"} />
            ) : (
              <View height={5} width={connectorWidth} backgroundColor={"white"} />
            )}
          </View>
        )
      )}
    </View>
  );
};
