import React from "react";

import Skeleton from "@material-ui/lab/Skeleton";
import { Grid } from "@material-ui/core";
import { View } from "DLUI/view";

const TransactionsListLoadingSkeletonItem: React.FC<{}> = () => (
  <View alignItems={"center"} height={50} flexDirection={"row"}>
    <Grid container justify={"flex-start"} style={{ paddingLeft: 20 }} item xs={3}>
      <Skeleton width={"50%"} variant="text" />
    </Grid>
    <Grid container justify={"flex-start"} style={{ paddingLeft: 20 }} item xs={3}>
      <Skeleton width={"50%"} variant="text" />
    </Grid>
    <Grid container justify={"flex-start"} style={{ paddingLeft: 20 }} item xs={3}>
      <Skeleton width={"50%"} variant="text" />
    </Grid>
    <Grid container justify={"flex-start"} style={{ paddingLeft: 20 }} item xs={3}>
      <Skeleton width={"50%"} variant="text" />
    </Grid>
  </View>
);

export default TransactionsListLoadingSkeletonItem;
