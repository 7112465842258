import React from "react";
import makeStyles from "./styles";
import type { FormikProps } from "formik";
import { FastField } from "formik";
import { TextField } from "DLUI/form";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Notes from "./notes";
import { ImageUpload } from "DLUI/imageUpload";
import Grid from "@material-ui/core/Grid";
import { View } from "DLUI/view";
import FieldWrapper from "DLUI/form/textField/fieldWrapper";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import type { PersonContactInfoRowPersonType } from "DLUI/person/personContactInfoRow";
import PersonContactInfoRow from "DLUI/person/personContactInfoRow";

interface ComponentProps {
  onFileReceive?: (receivedFile: File) => void;
  onFileDelete?: () => void;
  formikRef: FormikProps<any>;
  hideNotesSection?: boolean;
  showContactInfo?: boolean;
  personType?: PersonContactInfoRowPersonType;
}

const TenantInfo: React.FC<ComponentProps> = ({
  onFileReceive,
  onFileDelete,
  formikRef,
  hideNotesSection,
  showContactInfo,
  personType
}: ComponentProps) => {
  const classes = makeStyles();
  const { t } = useTranslation();

  const _onFileReceive = (receivedFile: File) => {
    if (onFileReceive) {
      onFileReceive(receivedFile);
    }
  };

  const _onFileDelete = () => {
    if (onFileDelete) {
      onFileDelete();
    }
  };

  return (
    <form style={{ width: "100%" }} autoComplete="off">
      <div className={classes.personInfoContainer}>
        <View paddingRight={20} flexDirection={"row"}>
          <ImageUpload
            didReceivedFile={_onFileReceive}
            didDeleteFile={_onFileDelete}
            selectedImageUrl={formikRef?.values ? formikRef.values.pictureUrl : undefined}
          />
        </View>

        <div className={clsx(classes.tenantDetailsContainer)}>
          <View flexDirection={"row"} alignItems={"flex-start"}>
            <Grid item container xs={12} md={6} sm={6}>
              <Grid item xs={12} md={9} sm={9}>
                <FieldWrapper type={"endElement"}>
                  <FastField
                    component={TextField}
                    label={t(AppStrings.Tenants.NewTenant.FirstName)}
                    name={`firstName`}
                  />
                </FieldWrapper>
              </Grid>
              <Grid item xs={12} md={3} sm={3}>
                <FieldWrapper type={"endElement"}>
                  <FastField
                    component={TextField}
                    label={t(AppStrings.Tenants.NewTenant.MiddleNameInitials)}
                    name={`middleName`}
                  />
                </FieldWrapper>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <FieldWrapper type={"startElement"}>
                <FastField component={TextField} label={t(AppStrings.Tenants.NewTenant.LastName)} name={`lastName`} />
              </FieldWrapper>
            </Grid>
          </View>
          <View flexDirection={"row"}>
            <Grid item xs={12} md={6} sm={6}>
              <FieldWrapper type={"endElement"}>
                <FastField component={TextField} label={t(AppStrings.Tenants.NewTenant.Company)} name={`companyName`} />
              </FieldWrapper>
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <FieldWrapper type={"startElement"}>
                <FastField component={TextField} label={t(AppStrings.Tenants.NewTenant.JobTitle)} name={`jobTitle`} />
              </FieldWrapper>
            </Grid>
          </View>
          <HorizontalSeparationLine marginTop={20} />

          {showContactInfo && <PersonContactInfoRow formikRef={formikRef} personType={personType} />}
          {hideNotesSection ? null : (
            <View flexDirection={"row"} marginTop={20}>
              <Notes />
            </View>
          )}
        </div>
      </div>
    </form>
  );
};

export default TenantInfo;
