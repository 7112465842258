import type { FC, PropsWithChildren } from "react";
import React from "react";
import ObjectRow from "./objectRow";
import { View } from "DLUI/view";
import { SeparationLine } from "DLUI/separatorView";
import { SectionHeader } from "screens/userRoles/newUserRole/components/objects/sectionHeader";
import { useTranslation } from "react-i18next";
import type { ObjectPermissionItemInterface } from "screens/userRoles/newUserRole/components/objects/objectPermissionItems";
import { useResponsiveHelper } from "../../../../../../contexts/responsiveContext";

const DynamicBox = ({ children }: PropsWithChildren<{}>) => {
  const { isMobile } = useResponsiveHelper();
  return isMobile ? (
    <>{children}</>
  ) : (
    <View borderRadius={10} backgroundColor={"white"} paddingRight={20} paddingLeft={20} paddingBottom={20}>
      {children}
    </View>
  );
};

interface Props {
  title: string;
  ternary?: boolean;
  permissions: ObjectPermissionItemInterface[];
}

export const ObjectSection: FC<Props> = ({ title, permissions, ternary }: Props) => {
  const { isMobile } = useResponsiveHelper();
  const { t } = useTranslation();
  return (
    <View flexDirection={"row"} marginBottom={20}>
      <DynamicBox>
        <SectionHeader title={t(title)} />
        {!isMobile && <SeparationLine width={"100%"} height={1} />}
        {permissions.map(({ permission, title, ignoredFields }, i) => (
          <ObjectRow permission={permission} title={t(title)} ternary={ternary} ignoredFields={ignoredFields} key={i} />
        ))}
      </DynamicBox>
    </View>
  );
};
