"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionReportGroupBy = void 0;
var TransactionReportGroupBy;
(function (TransactionReportGroupBy) {
    TransactionReportGroupBy["ACCOUNT"] = "account";
    TransactionReportGroupBy["TYPE"] = "type";
    TransactionReportGroupBy["VENDOR"] = "vendor";
    TransactionReportGroupBy["PROPERTY"] = "property";
    TransactionReportGroupBy["UNIT"] = "unit";
    TransactionReportGroupBy["TENANT"] = "tenant";
    TransactionReportGroupBy["LEASE"] = "lease";
    TransactionReportGroupBy["OWNER"] = "owner";
    TransactionReportGroupBy["PAYMENT_METHOD"] = "paymentMethod";
    TransactionReportGroupBy["DAY"] = "day";
    TransactionReportGroupBy["ISO_WEEK"] = "week";
    TransactionReportGroupBy["MONTH"] = "month";
    TransactionReportGroupBy["QUARTER"] = "quarter";
    TransactionReportGroupBy["YEAR"] = "year";
})(TransactionReportGroupBy || (exports.TransactionReportGroupBy = TransactionReportGroupBy = {}));
