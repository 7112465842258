import type { FC } from "react";
import React from "react";
import type { SeparationLineComponentProps } from "DLUI/separatorView/separationLine";
import { SeparationLine } from "DLUI/separatorView/index";

type Props = SeparationLineComponentProps;

export const HorizontalSeparationLine: FC<Props> = ({ ...rest }: Props) => (
  <SeparationLine width={"100%"} height={1} {...rest} />
);
