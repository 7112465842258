import type { LoginResponseDto, SubscriptionLoginResponseDto } from "@doorloop/dto";
import { DataCy, PusherChannelsEvent } from "@doorloop/dto";
import Hidden from "@material-ui/core/Hidden";
import { apiHelper } from "api/apiHelper";
import clsx from "clsx";
import type { BreadCrumbsText } from "DLUI/screenTitle";
import { BreadCrumbs } from "DLUI/screenTitle";
import { View } from "DLUI/view";
import type { CSSProperties, FC } from "react";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { analyticsService } from "services/analyticsService";
import * as authActions from "store/auth/actions";
import { history } from "store/history";
import { getLocaleData } from "utils/intlProvider";
import Routes from "../../appRouter/routes";
import DialogsProvider from "./dialogsProvider/dialogsProvider";
import type { AllFiltersAnyStringKeys } from "./filterPanel/filterPanel";
import Scroller from "./scroller/scroller";
import makeStyles from "./styles";
import qs from "query-string";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AppStrings from "locale/keys";
import { NavigationManager } from "utils/navigation";
import moment from "moment";
import type { SVGIconComponent } from "assets/icons/types";
import { authApi } from "api/authApi";
import type { PopoverItem } from "DLUI/popover";
import { LoadingAnimation } from "DLUI/animations/loadingAnimation";
import "./styles.css";
import type { RestrictedDeviceAccessTypes } from "DLUI/restrictedAccess/restrictedDeviceAccess";
import RestrictedDeviceAccess from "DLUI/restrictedAccess/restrictedDeviceAccess";
import RestrictedScreenAccess from "DLUI/restrictedAccess/restrictedScreenAccess";
import type { FormikProps } from "formik";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import useIntercomBubble from "hooks/useIntercomBubble";
import { Icon } from "DLUI/icon";
import { PrintIcon } from "assets/.";
import Text from "DLUI/text";
import { useAtom, useSetAtom } from "jotai";
import { isPrintingAtom } from "utils/atoms";
import { FullScreenLoading } from "DLUI/loading/fullScreenLoading";
import { AppLayouts, useNavigationContext } from "../../../contexts/appLayoutContext";
import { pushService } from "../../../services/pushService";
import { useEffectAsync } from "../../../hooks/useEffectAsync";
import type { IRootState } from "store/index";
import { SeparationLine } from "DLUI/separatorView";
import type { ScreenSize } from "../../../contexts/utils";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import { useConversationsPushEvent } from "screens/communicationsCenter/shared/useConversationsPushEvent";
import { desktopTopNavigationBarBackgroundColorAtom } from "components/layouts/layoutAtom";
import { useTheme } from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import SearchPanel from "./searchPanel/searchPanel";

export interface ScreenProps {
  documentTitle?: string;
  children: React.ReactNode;
  backgroundColor?: string;
  titleText?: BreadCrumbsText | BreadCrumbsText[];
  subTitleText?: BreadCrumbsText | BreadCrumbsText[];
  ActionPanel?: React.FC<any>;
  onScrollRef?: (scroller: Scroller) => void;
  hideLeftNavBar?: boolean;
  renderBackgroundLayer?: () => React.ReactNode;
  renderFrontLayer?: (formikRef?: FormikProps<any>) => React.ReactNode;
  searchPanelPlaceHolderText?: string;
  searchPanelFilterOptions?: AllFiltersAnyStringKeys[];
  initialFilterSelection?: {};
  SearchPanelIcon?: SVGIconComponent;
  didChangeFilterOptions?: (filterObj: Record<string, any>) => void;
  filterTriggers?: Record<string, any>;
  detailsScreenInstance?: boolean;
  detailsScreenInstanceShowRenderDialogProvider?: boolean;
  requireAuth?: boolean;
  requestInProgress?: boolean;
  showHeaderShadow?: boolean;
  onDialogRefreshEvent?: () => void;
  actionButtons?: PopoverItem[];
  excludedScreenSizes?: ScreenSize[];
  excludedDevices?: RestrictedDeviceAccessTypes[];
  removeScreenPaddings?: boolean;
  formikRef?: FormikProps<any>;
  helpPanel?: HelpPanelProps;
  hideSeparator?: boolean;
  screenContentFullHeight?: boolean;
  fullWidth?: boolean;
  hideSearchBar?: boolean;
  screenLayout?: AppLayouts;
  excludeDevicesView?: FC<any>;
  scrollWrapperStyle?: CSSProperties;
  screenContainerStyle?: CSSProperties;
  ignoreUserSubscriptionCheck?: boolean;
  showBackButton?: boolean;
  hideSearchPanel?: boolean;
}

let isPushRegistered = false;
const Screen = ({
  documentTitle,
  backgroundColor,
  showBackButton,
  titleText,
  subTitleText,
  children,
  ActionPanel,
  onScrollRef,
  renderBackgroundLayer,
  renderFrontLayer,
  searchPanelPlaceHolderText,
  searchPanelFilterOptions,
  initialFilterSelection,
  SearchPanelIcon,
  didChangeFilterOptions,
  detailsScreenInstance,
  detailsScreenInstanceShowRenderDialogProvider,
  requireAuth,
  requestInProgress,
  onDialogRefreshEvent,
  actionButtons,
  excludedScreenSizes,
  excludedDevices,
  removeScreenPaddings,
  formikRef,
  helpPanel,
  hideSeparator = true,
  screenContentFullHeight = false,
  fullWidth = false,
  screenLayout = AppLayouts.Sidebar,
  hideSearchBar,
  filterTriggers,
  screenContainerStyle,
  scrollWrapperStyle,
  excludeDevicesView,
  ignoreUserSubscriptionCheck = false,
  hideSearchPanel
}: ScreenProps) => {
  const { t } = useTranslation();
  const { setCurrentLayout } = useNavigationContext();
  const classes = makeStyles();
  const { isMobile, isTabletOrMobile, screenContainerPadding } = useResponsiveHelper();
  const { formatNumberToParts } = useIntl();
  const { hideIntercomBubble } = useIntercomBubble();
  const [isPrinting] = useAtom(isPrintingAtom);
  const setDesktopTopNavigationBarBackgroundColor = useSetAtom(desktopTopNavigationBarBackgroundColorAtom);
  const dispatch = useDispatch();
  const screenScroller = useRef(null);
  const [authInProgress, setAuthInProgress] = useState<boolean>(Boolean(requireAuth));
  const location = detailsScreenInstance ? window.location : useLocation<any>();
  const appTheme = useTheme();
  const _screenPadding = detailsScreenInstance || removeScreenPaddings ? 0 : screenContainerPadding;
  const currentLoginResponse = useSelector((state: IRootState) => state.auth.currentLoginResponse);
  const { handleConversationsStatsEvent, handleConversationEvent } = useConversationsPushEvent();

  const handleUserSubscription = (subscription?: SubscriptionLoginResponseDto) => {
    const arrivedFromSubscriptionRoute = location.pathname.includes("subscription");

    const hasSubscription = subscription?.status !== undefined && subscription?.status !== "canceled";

    const trialDate = moment(subscription?.trialEndsAt);

    const today = moment();

    const trialEnded = today.isAfter(trialDate);

    const paymentIssueWithSubscription =
      hasSubscription && subscription?.status !== "active" && subscription?.status !== "trialing";

    if (!arrivedFromSubscriptionRoute) {
      if (!hasSubscription && trialEnded) {
        NavigationManager.newSubscriptionPage();
      } else if (paymentIssueWithSubscription) {
        NavigationManager.viewSubscriptionDetails();
      }
    }
  };

  useEffectAsync(async () => {
    if (!isPushRegistered && currentLoginResponse?.id) {
      isPushRegistered = true;
      const isSubscribed = await pushService.handlePushNotificationSubscription(currentLoginResponse);

      if (!isSubscribed) {
        await pushService.subscribePrivateChannel(PusherChannelsEvent.PUSH_CONVERSATIONS_STATS_EVENT, (dto) =>
          handleConversationsStatsEvent(dto)
        );

        await pushService.subscribePrivateChannel(PusherChannelsEvent.PUSH_CONVERSATION_EVENT, (dto) =>
          handleConversationEvent(dto)
        );
      }
    }
  }, [currentLoginResponse?.id]);

  const handleAuthentication = async () => {
    if (requireAuth) {
      setAuthInProgress(true);
      const authenticatedResponse = await apiHelper.isUserAuthenticated();
      if (!authenticatedResponse) {
        await authApi.logout();
        // When redirecting to the login page, send the current URL as the return path to redirect back to after a successul login
        const returnPathParamName = apiHelper.getReturnPathQueryParamName();
        const searchObject: any = {};
        searchObject[returnPathParamName] = location.pathname + location.search;
        history.replace({
          pathname: Routes.LOGIN,
          search: qs.stringify(searchObject)
        });
      } else {
        const loginResponse = authenticatedResponse as LoginResponseDto;

        if (loginResponse) {
          analyticsService.identify(loginResponse);

          const localeData = getLocaleData(
            loginResponse.currentDbTenant.currency || "USD",
            loginResponse.currentDbTenant.language || "en-US",
            formatNumberToParts
          );
          dispatch(authActions.updateLocaleData(localeData));

          if (!ignoreUserSubscriptionCheck) {
            handleUserSubscription(loginResponse?.currentDbTenant?.subscriptionPlan);
          }
        }
      }

      setAuthInProgress(false);
    }
  };

  const handleScrollRef = () => {
    if (screenScroller !== null && screenScroller.current !== null) {
      if (onScrollRef) {
        setTimeout(() => {
          //@ts-ignore
          onScrollRef(new Scroller(screenScroller.current));
        }, 1000);
      }
    }
  };

  useEffectAsync(async () => {
    handleScrollRef();
    await handleAuthentication();

    analyticsService.page();
    setDesktopTopNavigationBarBackgroundColor(backgroundColor || appTheme.palette.screenBackground.main);
  }, []);

  useEffect(() => {
    handleScrollRef();

    if (!authInProgress) {
      setCurrentLayout(screenLayout);
    }
  }, [authInProgress]);

  useEffect(() => {
    isMobile && hideIntercomBubble();
  }, [isMobile]);

  const renderScreenTitle = () => {
    const marginBottom = isTabletOrMobile ? 10 : hideSeparator ? 0 : 20;

    if (!titleText && !subTitleText) {
      return null;
    }

    return (
      <View
        justifyContent={"center"}
        marginBottom={marginBottom}
        minHeight={60}
        className={"breadcrumbs-container"}
        overflow={"hidden"}
        noWrap
      >
        <BreadCrumbs
          ActionPanel={ActionPanel}
          helpPanel={helpPanel}
          subTitleText={subTitleText}
          titleText={titleText}
          screenPadding={_screenPadding}
          showBackButton={showBackButton}
        />
        {!hideSeparator && <SeparationLine width={"calc(100% - 40px)"} marginLeft={20} marginTop={10} height={1} />}
      </View>
    );
  };

  const _renderBackgroundLayer = () => {
    if (renderBackgroundLayer) {
      return renderBackgroundLayer();
    }
    return null;
  };

  const _renderFrontLayer = () => renderFrontLayer && renderFrontLayer(formikRef);

  const _didChangeFilterOptions = () => {};

  const renderSearchPanel = () => {
    if (!searchPanelFilterOptions || !searchPanelPlaceHolderText) {
      return null;
    }

    return (
      <SearchPanel
        searchPlaceHolderText={searchPanelPlaceHolderText}
        didChangeFilterOptions={didChangeFilterOptions || _didChangeFilterOptions}
        initialFilterSelection={initialFilterSelection}
        filterOptions={searchPanelFilterOptions}
        requestInProgress={requestInProgress}
        hideSearchBar={hideSearchBar}
        filterTriggers={filterTriggers}
        actionButtons={actionButtons}
        dataCy={DataCy.DLUI.searchBarContainer}
      />
    );
  };

  const renderPrintingLoading = () =>
    isPrinting && (
      <FullScreenLoading>
        <Icon Source={PrintIcon} size={40} />
        <Text value={AppStrings.Common.PrintPreparingMessage} />
      </FullScreenLoading>
    );

  const content = (
    <Fragment>
      {renderPrintingLoading()}
      <div className={clsx(["backgroundLayer", classes.screenLayer, classes.backgroundLayer])}>
        {_renderBackgroundLayer()}
      </div>
      <div
        className={clsx([
          classes.screenLayer,
          classes.contentLayer,
          isMobile && !screenContentFullHeight ? classes.contentLayerMobile : "",
          "contentLayer"
        ])}
      >
        <div
          ref={screenScroller}
          id={detailsScreenInstance ? "" : "screenScroll"}
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignContent: "flex-start",
            overflowX: isMobile ? "hidden" : undefined,
            ...scrollWrapperStyle
          }}
          className={clsx([
            "screen-component",
            detailsScreenInstance ? "detailsScreenContainer" : classes.overflowScroll
          ])}
        >
          <RestrictedDeviceAccess excludedDevices={excludedDevices} DisplayView={excludeDevicesView}>
            <RestrictedScreenAccess excludedScreenSizes={excludedScreenSizes}>
              {renderScreenTitle()}
              <View
                style={screenContainerStyle}
                fullWidth={fullWidth}
                paddingRight={_screenPadding}
                paddingLeft={_screenPadding}
                paddingTop={_screenPadding}
                height={screenContentFullHeight ? "100%" : undefined}
                className={"screen-container"}
                noWrap
              >
                {!hideSearchPanel && renderSearchPanel()}
                {SearchPanelIcon ? (
                  <Hidden mdDown>
                    <div className={classes.searchPanelIcon}>
                      <SearchPanelIcon />
                    </div>
                  </Hidden>
                ) : null}
                {children}
              </View>
            </RestrictedScreenAccess>
          </RestrictedDeviceAccess>
        </div>
      </div>
      <RestrictedScreenAccess
        displayView={<View autoWidth flexDirection={"row"} />}
        excludedScreenSizes={excludedScreenSizes}
      >
        <div className={clsx([classes.screenLayer, classes.frontLayer])}>{_renderFrontLayer()}</div>
      </RestrictedScreenAccess>
    </Fragment>
  );

  const renderScreenContent = () => {
    if (authInProgress) {
      return (
        <div
          style={{ height: `calc(100vh - 50px)` }}
          className={clsx([classes.screenContainer, classes.loadingIndicatorContainer])}
        >
          <LoadingAnimation />
        </div>
      );
    }

    if (detailsScreenInstance && !detailsScreenInstanceShowRenderDialogProvider) {
      return content;
    }

    return <DialogsProvider onRefreshEvent={onDialogRefreshEvent}>{content}</DialogsProvider>;
  };

  const shouldRemoveOverflowHiddenRole = useMemo(
    () => location.pathname.indexOf(Routes.REPORTS) !== -1 || location.pathname.indexOf(Routes.PRINT_CHECKS) !== -1,
    [location.pathname]
  );

  const finalDocumentTitle = documentTitle ? `${t(AppStrings.Common.DocumentTitle)}${t(documentTitle)}` : undefined;

  return (
    <div style={{ height: "100%", width: "100%" }} className={clsx([classes.screenContainer, "DLUI_screenContainer"])}>
      {finalDocumentTitle && (
        <Helmet>
          <title>{finalDocumentTitle}</title>
        </Helmet>
      )}
      <div className={classes.screenContent}>
        <div
          className={clsx(shouldRemoveOverflowHiddenRole ? "" : "pageContainer", classes.pageContainer)}
          style={{ backgroundColor }}
        >
          <div
            className={clsx([classes.screenContentContainer])}
            style={{ height: isTabletOrMobile ? "100%" : undefined }}
          >
            {renderScreenContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Screen;
