import { DataCy, type DeepKeys, LateFeeChargeDto, LateFeePolicyFeeType, LateFeePolicyFrequency } from "@doorloop/dto";
import { LateFeeChargeItem } from "DLUI/dialogs/settings/common/lateFees/lateFeeChargeItem";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import { AddIcon } from "@/assets";
import type { LateFeesPrefixes } from "DLUI/dialogs/settings/common/lateFees/types";
import { useLateFeeSettings } from "DLUI/dialogs/settings/common/lateFees/useLateFeeSettings";
import { useCallback, useState } from "react";
import { v4 as uuid } from "uuid";
import { type FieldArrayRenderProps } from "formik";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { useTypedTranslation } from "locale";
import { useStyles } from "../settings/styles";
import { LateFeeChargeItemFormMobile } from "DLUI/dialogs/settings/common/lateFees/mobile/lateFeeChargeItemFormMobile";
import { FeeItemFormMobileDialogWrapper } from "screens/settings/common/feeItemFormMobileDialogWrapper";
import { useGeneralStyles } from "styles/generalStyles";
import clsx from "clsx";
import isNil from "lodash/isNil";

interface LateFeeChargesListProps {
  arrayHelpers: FieldArrayRenderProps;
  formikPathName?: DeepKeys<LateFeesPrefixes>;
  defaultAccount: string;
}

export const LateFeeChargesList = ({ formikPathName, arrayHelpers, defaultAccount }: LateFeeChargesListProps) => {
  const { t } = useTypedTranslation();
  const { lateFeeSettings } = useLateFeeSettings({ formikPathName });
  const settingsClasses = useStyles();
  const generalClasses = useGeneralStyles();
  const { isMobile } = useResponsiveHelper();
  const [isMobileFormOpen, setIsMobileFormOpen] = useState(false);
  const [newAddedChargeKey, setNewAddedChargeKey] = useState<string>();

  const lateFeeCharges = lateFeeSettings?.lateFeeCharges ?? [];
  const feeRulesExist = lateFeeCharges.length > 0;

  const handleAddChargeClick = useCallback(
    (arrayHelpers: FieldArrayRenderProps) => {
      const lateFeeChargeDto = new LateFeeChargeDto({
        frequency: LateFeePolicyFrequency.ONCE,
        feeType: LateFeePolicyFeeType.FIXED_AMOUNT,
        account: defaultAccount,
        uniqueKey: uuid()
      });
      setNewAddedChargeKey(lateFeeChargeDto.uniqueKey);
      arrayHelpers.push(lateFeeChargeDto);
      setIsMobileFormOpen(true);
    },
    [defaultAccount]
  );

  return (
    <>
      <FeeItemFormMobileDialogWrapper
        isOpen={isMobileFormOpen}
        handleStateChange={setIsMobileFormOpen}
        hasItems={lateFeeCharges.length > 0}
        Form={
          <LateFeeChargeItemFormMobile
            formikPathName={formikPathName}
            onClose={() => setIsMobileFormOpen(false)}
            index={lateFeeCharges.length - 1}
            arrayHelpers={arrayHelpers}
            mode={"add"}
          />
        }
      />
      <div className={clsx(settingsClasses.rulesContainer, generalClasses.fullWidth)}>
        {lateFeeCharges?.map((currentRow: LateFeeChargeDto, index: number) => (
          <LateFeeChargeItem
            key={currentRow.uniqueKey}
            arrayHelpers={arrayHelpers}
            index={index}
            formikPathName={formikPathName}
            feeType={currentRow.feeType}
            isNewChargeAdded={!isNil(newAddedChargeKey) && currentRow.uniqueKey === newAddedChargeKey}
            setNewAddedChargeKey={setNewAddedChargeKey}
          />
        ))}
      </div>
      <DLButton
        onClick={() => handleAddChargeClick(arrayHelpers)}
        actionText={t("lateFee.addLateFee")}
        variant={feeRulesExist ? DLButtonVariantsEnum.TEXT : DLButtonVariantsEnum.CONTAINED}
        color={DLButtonColorsEnum.SECONDARY}
        size={isMobile ? DLButtonSizesEnum.LARGE : DLButtonSizesEnum.SMALL}
        disableMobileCollapse
        dataCy={DataCy.globalSettings.lateFees.rules.newRuleButton}
        icons={{ start: { src: AddIcon } }}
        containerClassName={feeRulesExist ? settingsClasses.addAnotherElement : settingsClasses.addElement}
      />
    </>
  );
};
