import {
  AccountingMethod,
  DataCy,
  DepositStatus,
  JournalEntryType,
  PaymentMethod,
  RecurringTransactionStatus,
  ReportId,
  ReportPermission
} from "@doorloop/dto";
import { NavigationManager } from "utils/navigation";
import type { ReportScreenItemType } from "DLUI/report/screen/reportScreenItemType";
import { filterObjectsByUserType } from "../../../../../utils/userTypesUtils";
import type { Translation } from "locale/types";
import { Feature } from "config/featureFlags/featureFlags";

const didPressTransactionsList = (propertyId?: string) => {
  const params: Record<string, string> = {
    period: "last-7-days"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runTransactionsListReport(params);
};

const didPressTransactionsDetails = (propertyId?: string) => {
  const params: Record<string, string> = {
    period: "last-7-days"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runAccountTransactionsReport(undefined, params);
};

const didPressTransactionsListByDate = (propertyId?: string) => {
  const params: Record<string, string> = {
    period: "last-7-days",
    groupBy: "date"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runTransactionsListReport(params);
};

const didPressTransactionListRecent = (propertyId?: string) => {
  const params: Record<string, string> = {
    period: "last-7-days"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runTransactionsListReport(params);
};

const didPressTransactionListByType = (propertyId?: string) => {
  const params: Record<string, string> = {
    period: "last-7-days",
    groupBy: "type"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runTransactionsListReport(params);
};

const didPressBudgetVsActual = (propertyId?: string) => {
  NavigationManager.runBudgetVsActualReport(
    propertyId
      ? {
          property: propertyId
        }
      : undefined
  );
};

const didPressDepositTransactions = (propertyId?: string) => {
  const params: Record<string, string> = {
    filter_type: JournalEntryType.DEPOSIT,
    period: "last-7-days"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runTransactionsListReport(params);
};

const didPressExpenseTransactions = (propertyId?: string) => {
  const params: Record<string, string> = {
    filter_type: JournalEntryType.EXPENSE,
    period: "last-7-days"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runTransactionsListReport(params);
};

const didPressRefundTransactions = (propertyId?: string) => {
  const params: Record<string, string> = {
    filter_type: JournalEntryType.LEASE_REFUND,
    period: "last-7-days"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runTransactionsListReport(params);
};

const didPressBankbalanceByProperty = (propertyId?: string) => {
  const params: Record<string, string> = {};
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runBankBalanceByPropertyReport(params);
};

const didPressGeneralLedger = (propertyId?: string) => {
  const params: Record<string, string> = {
    period: "last-7-days",
    groupBy: "account",
    isGeneralLedgerReport: "true"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runAccountTransactionsReport(undefined, params);
};

const didPressUndepositedFundsReport = (propertyId?: string) => {
  const params: Record<string, string> = {
    filter_depositStatus: DepositStatus.UNDEPOSITED,
    groupBy: "account"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runTransactionsListReport(params as Record<string, string>);
};

const didPressEPayDepositsReport = (propertyId?: string) => {
  const params: Record<string, string> = {
    period: "last-30-days",
    groupBy: "depositStatus",
    filter_paymentMethod: PaymentMethod.EPAY
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runTransactionsListReport(params as Record<string, string>);
};

const didPressTrialBalance = (propertyId?: string) => {
  const params: Record<string, string> = {
    filter_accountingMethod: AccountingMethod.CASH
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runTrialBalanceReport(params);
};

const didPressAccountRegister = () => {
  NavigationManager.runAccountRegisterReport();
};

function didPressPropertyBankAccounts() {
  NavigationManager.runPropertyBankAccountsReport();
}

const didPressRecurringTransactionsSummary = (propertyId?: string) => {
  const params: Record<string, string> = {
    filter_status: RecurringTransactionStatus.ACTIVE
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runRecurringTransactionsReport(params);
};

const items: Array<ReportScreenItemType<Translation>> = [
  {
    description: "reports.reportsScreen.recurringTransactionsSummary",
    onPress: didPressRecurringTransactionsSummary,
    permission: ReportPermission.recurringTransactions,
    id: ReportId.RECURRING_TRANSACTIONS_SUMMARY,
    dataCy: DataCy.reports.screenItems.accounting.recurringTransactionsSummary
  },
  {
    description: "reports.accountRegister.accountRegister",
    onPress: didPressAccountRegister,
    permission: ReportPermission.accountRegister,
    id: ReportId.ACCOUNT_REGISTER,
    dataCy: DataCy.reports.screenItems.accounting.accountRegister
  },
  {
    description: "reports.reportsScreen.transactionsList",
    onPress: didPressTransactionsList,
    permission: ReportPermission.transactionsList,
    id: ReportId.TRANSACTIONS_LIST,
    dataCy: DataCy.reports.screenItems.accounting.transactionsList
  },
  {
    description: "reports.reportsScreen.runBudgetVsActual",
    onPress: didPressBudgetVsActual,
    permission: ReportPermission.budgetVsActual,
    id: ReportId.BUDGET_VS_ACTUAL,
    dataCy: DataCy.reports.screenItems.accounting.budgetVsActual
  },
  // {
  //   description: AppStrings.Reports.ReportsScreen.TransactionsListByDate,
  //   onPress: didPressTransactionsListByDate,
  //   permission: ReportPermission.transactionsList,
  //   id: ReportId.TRANSACTIONS_LIST_BY_DATE
  // },
  {
    description: "reports.reportsScreen.transactionListByType",
    onPress: didPressTransactionListByType,
    permission: ReportPermission.transactionsList,
    id: ReportId.TRANSACTIONS_LIST_BY_TYPE,
    dataCy: DataCy.reports.screenItems.accounting.transactionsListByType
  },
  // {
  //   description: AppStrings.Reports.ReportsScreen.TransactionListRecent,
  //   onPress: didPressTransactionListRecent,
  //   permission: ReportPermission.transactionsList,
  //   id: ReportId.TRANSACTIONS_LIST_RECENT
  // },
  {
    description: "reports.reportsScreen.transactionsDetails",
    onPress: didPressTransactionsDetails,
    permission: ReportPermission.transactionsDetails,
    id: ReportId.TRANSACTIONS_DETAILS,
    dataCy: DataCy.reports.screenItems.accounting.transactionDetails
  },
  {
    description: "reports.reportsScreen.trialBalance",
    onPress: didPressTrialBalance,
    permission: ReportPermission.trialBalance,
    id: ReportId.TRIAL_BALANCE,
    dataCy: DataCy.reports.screenItems.accounting.trialBalance
  },
  {
    description: "reports.reportsScreen.bankbalanceByProperty",
    onPress: didPressBankbalanceByProperty,
    permission: ReportPermission.bankBalanceByProperty,
    id: ReportId.BANK_BALANCE_BY_PROPERTY,
    dataCy: DataCy.reports.screenItems.accounting.bankBalanceByProperty
  },
  {
    description: "reports.reportsScreen.depositTransactions",
    onPress: didPressDepositTransactions,
    permission: ReportPermission.transactionsList,
    id: ReportId.DEPOSIT_TRANSACTIONS,
    dataCy: DataCy.reports.screenItems.accounting.depositTransactions
  },
  {
    description: "reports.reportsScreen.expenseTransactions",
    onPress: didPressExpenseTransactions,
    permission: ReportPermission.transactionsList,
    id: ReportId.EXPENSE_TRANSACTIONS,
    dataCy: DataCy.reports.screenItems.accounting.expenseTransactions
  },
  {
    description: "reports.reportsScreen.generalLedger",
    onPress: didPressGeneralLedger,
    permission: ReportPermission.transactionsList,
    id: ReportId.GENERAL_LEDGER,
    dataCy: DataCy.reports.screenItems.accounting.generalLedger
  },
  {
    description: "reports.reportsScreen.refundTransactions",
    onPress: didPressRefundTransactions,
    permission: ReportPermission.transactionsList,
    id: ReportId.REFUND_TRANSACTIONS,
    dataCy: DataCy.reports.screenItems.accounting.refundTransactions
  },
  {
    description: "reports.reportsScreen.undepositedFunds",
    onPress: didPressUndepositedFundsReport,
    permission: ReportPermission.transactionsList,
    id: ReportId.UNDEPOSIT_FUNDS,
    dataCy: DataCy.reports.screenItems.accounting.undepositedFunds
  },
  {
    description: "reports.reportsScreen.ePayDepositsReport",
    onPress: didPressEPayDepositsReport,
    permission: ReportPermission.transactionsList,
    id: ReportId.EPAY_DEPOSIT,
    dataCy: DataCy.reports.screenItems.accounting.ePayDepositsReport
  },
  {
    description: "common.recurring.recurringExpenses",
    onPress: () => NavigationManager.runRecurringExpensesReport(),
    permission: ReportPermission.transactionsList,
    id: ReportId.RECURRING_EXPENSES,
    dataCy: DataCy.reports.screenItems.accounting.recurringExpenses
  },
  {
    description: "reports.reportsScreen.propertyBankAccounts",
    onPress: didPressPropertyBankAccounts,
    permission: ReportPermission.banking,
    id: ReportId.PROPERTY_BANK_ACCOUNTS,
    dataCy: DataCy.reports.screenItems.accounting.propertyBankAccounts
  },
  {
    description: "common.recurring.recurringVendorBills",
    onPress: () => NavigationManager.runRecurringVendorBillsReport(),
    permission: ReportPermission.vendors,
    id: ReportId.RECURRING_VENDOR_BILLS,
    dataCy: DataCy.reports.screenItems.accounting.recurringVendorBills
  },
  {
    description: "reports.reportsScreen.depositDetail",
    onPress: NavigationManager.runDepositDetailReport,
    permission: ReportPermission.depositDetail,
    id: ReportId.DEPOSIT_DETAIL,
    dataCy: DataCy.reports.screenItems.accounting.depositDetail,
    inactive: Feature.isInactive("DepositDetailReport")
  }
];

const getAccountingItems = (isHOAUser: boolean): ReportScreenItemType[] => {
  const activeItems = items.filter((item) => !item.inactive);

  if (isHOAUser) {
    return filterObjectsByUserType(activeItems, "HOAUser");
  }

  return activeItems;
};

export default getAccountingItems;
