import React, { useState } from "react";
import { useTypedTranslation } from "locale";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { ControlledCheckBox, TextField } from "DLUI/form";
import { Icon } from "DLUI/icon";
import { FormIcon } from "@/assets";
import AppStrings from "locale/keys";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { FastField, useFormikContext } from "formik";
import type { ApplicantPostChargePayment, RentalApplicationDto } from "@doorloop/dto";

interface PaymentSummaryProps {
  rentalApplicationData: {
    applicationId: string;
    email: string;
    submittedDate?: string;
  };
}

export const RentalApplicationPaymentSummary = ({ rentalApplicationData }: PaymentSummaryProps) => {
  const formik = useFormikContext<ApplicantPostChargePayment | RentalApplicationDto>();
  const [shouldShowShowScreeningCheckbox, setShouldShowShowScreeningCheckbox] = useState(false);
  const { t: tt } = useTypedTranslation();
  const { isMobile } = useResponsiveHelper();

  const handleShareScreeningCheckboxChange = (value) => {
    formik.setFieldValue("prospectScreeningShare.email", value ? rentalApplicationData.email : "");
    formik.setFieldValue("prospectScreeningShare.shouldShareScreening", value);
    setShouldShowShowScreeningCheckbox(value);
  };

  return (
    <View gap={14} marginTop={20}>
      <Text value={tt("common.getASharableCopyOfYourScreeningReport")} bold fontFamily={"Poppins"} fontSize={20} />
      <View flexDirection={"row"} noWrap alignItems={"flex-start"} marginTop={6} width={isMobile ? "70%" : undefined}>
        <ControlledCheckBox checked={shouldShowShowScreeningCheckbox} onChange={handleShareScreeningCheckboxChange} />
        <View noWrap flexDirection={"column"}>
          <View
            noWrap
            flexDirection={"row"}
            alignItems={rentalApplicationData.submittedDate ? undefined : "center"}
            gap={8}
          >
            <Icon Source={FormIcon} size={42} />
            <View flexDirection={"column"} noWrap>
              <Text
                value={tt("common.generateASharableLinkForMyBackgroundCheck")}
                fontSize={16}
                fontFamily={"Poppins"}
                fontWeight={500}
                overFlow={"ellipsis"}
              />
              {rentalApplicationData.submittedDate && (
                <Text
                  value={AppStrings.Common.SubmittedAt}
                  fontSize={12}
                  fontFamily={"Poppins"}
                  color={"secondary-gray"}
                  replaceObject={{ date: rentalApplicationData.submittedDate }}
                />
              )}
            </View>
          </View>
          {shouldShowShowScreeningCheckbox && (
            <View flexDirection={"row"} gap={3} style={{ width: isMobile ? "100%" : "45%" }}>
              <FastField
                component={TextField}
                label={tt("common.form.fields.email")}
                name={"prospectScreeningShare.email"}
                marginTop={20}
                required
                disabled
              />
              <Text
                value={tt("common.reportsWillBeSentToTheAboveEmail")}
                fontSize={12}
                color={"secondary-gray"}
                marginLeft={4}
              />
            </View>
          )}
        </View>
      </View>
      <HorizontalSeparationLine />
    </View>
  );
};
