import React, { useCallback, useEffect, useRef, useState } from "react";
import "./styles.css";
import makeStyles from "./styles";
import clsx from "clsx";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { IconButton } from "DLUI/form";
import { NotificationIcon } from "../../../assets";
import { DataCy, LoginResponseType } from "@doorloop/dto";
import type { FillColors } from "DLUI/icon";
import { Icon } from "DLUI/icon";
import { shallowEqual, useSelector } from "react-redux";
import type { IRootState } from "store/index";
import { store } from "store/index";
import UnreadNotificationsBubble from "DLUI/notificationCenter/components/notificationBubble";
import AppStrings from "../../../locale/keys";
import type { TenantPortalNotificationApi } from "api/tenantPortal/tenantPortalNotificationApi";
import type { NotificationApi } from "api/notificationApi";
import { updateTotalUnreadNotification } from "store/auth/actions";
import { TextTooltip } from "DLUI/popover/textTooltip";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import { notificationsLayoutVisibilityAtom } from "components/layouts/layoutAtom";
import { useAtom, useSetAtom } from "jotai";
import { useAnalyticsService } from "@/hooks/useAnalyticsService";

const maxTotalNotificationBubble = 99;

interface ComponentProps {
  apiMethod: NotificationApi | TenantPortalNotificationApi;
  top: number;
  marginRight?: number;
  pathColor?: FillColors;
  isSideBarMinimized?: boolean;
  dataCy?: string;
  iconSize?: number;
}

let checkInProgress = false;

const NotificationCenter = ({ apiMethod, marginRight, isSideBarMinimized, iconSize }: ComponentProps) => {
  const { dispatchAnalytics } = useAnalyticsService();
  const { isTabletOrMobile } = useResponsiveHelper();
  const { currentLoginResponse, isAuthenticated } = useSelector((state: IRootState) => state.auth, shallowEqual);
  const prevTotalUnread = useRef(currentLoginResponse?.totalUnreadNotifications || 0);
  const [isRing, setIsRing] = useState<boolean>(false);
  const { ringBell, notificationBubble, mobileNotificationBubble } = makeStyles({
    isRing,
    totalUnread: currentLoginResponse?.totalUnreadNotifications || 0
  });
  const [isNotificationsLayoutIsVisible] = useAtom(notificationsLayoutVisibilityAtom);
  const setNotificationsLayoutVisibilityAtom = useSetAtom(notificationsLayoutVisibilityAtom);
  const toggleButtonRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (isAuthenticated) {
      timerId = setTimeout(() => {
        checkTotalUnreadNotification(true);
      }, 2000);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [isAuthenticated]);

  useEffect(
    () => () => {
      checkInProgress = false;
    },
    []
  );

  useEffect(() => {
    if (
      currentLoginResponse?.totalUnreadNotifications &&
      currentLoginResponse.totalUnreadNotifications > prevTotalUnread.current
    ) {
      checkTotalUnreadNotification(false);
    }
  }, [currentLoginResponse?.totalUnreadNotifications]);

  const increaseTotalUnread = useCallback(
    (totalNum?: number) => {
      let total;

      if (totalNum) {
        total = totalNum;
      } else {
        total = currentLoginResponse?.totalUnreadNotifications && currentLoginResponse.totalUnreadNotifications + 1;
      }

      prevTotalUnread.current = total;
      store.dispatch(updateTotalUnreadNotification(total));
    },
    [currentLoginResponse?.totalUnreadNotifications]
  );

  const toggleNotificationCenter = useCallback(() => {
    setNotificationsLayoutVisibilityAtom(true);
    dispatchAnalytics("notification_center_bell_clicked", {
      totalUnreadNotifications: currentLoginResponse?.totalUnreadNotifications ?? 0
    });
  }, [currentLoginResponse?.totalUnreadNotifications]);

  const handleRingBell = () => {
    setIsRing(true);

    setTimeout(() => {
      setIsRing(false);
    }, 1000);
  };

  const checkTotalUnreadNotification = async (ring: boolean) => {
    if (!checkInProgress) {
      checkInProgress = true;
      const result = await apiMethod.getTotalUnreadNotifications();

      if (result.status) {
        increaseTotalUnread(result.data);
        result?.data && ring && handleRingBell();
      }

      checkInProgress = false;
    }
  };

  return (
    <>
      {currentLoginResponse?.type === LoginResponseType.TENANT || isTabletOrMobile ? (
        <View alignItems={"flex-end"} autoWidth justifyContent={"center"}>
          <IconButton
            domRef={toggleButtonRef}
            onClick={toggleNotificationCenter}
            size={iconSize ? iconSize : 25}
            pathColor={currentLoginResponse?.type === LoginResponseType.TENANT ? "blue" : "white"}
            className={clsx(ringBell)}
            Icon={NotificationIcon}
            dataCy={DataCy.tenantPortal.navigationBar.notificationBell}
          />
          {Boolean(currentLoginResponse?.totalUnreadNotifications) && (
            <UnreadNotificationsBubble
              classNames={[mobileNotificationBubble]}
              onTheBell
              isSmallScreen
              totalNotifications={currentLoginResponse?.totalUnreadNotifications ?? 0}
              maxTotalNotificationToShow={maxTotalNotificationBubble}
            />
          )}
        </View>
      ) : (
        <TextTooltip
          value={AppStrings.Notifications.Title}
          placement={"bottom"}
          show={isSideBarMinimized && !isNotificationsLayoutIsVisible}
          useButton={false}
        >
          <View
            domRef={toggleButtonRef}
            flexDirection={"row"}
            alignItems={"center"}
            onClick={toggleNotificationCenter}
            style={{ padding: 10 }}
            marginRight={marginRight}
            autoWidth
            noWrap
          >
            <Icon
              className={clsx(ringBell)}
              Source={NotificationIcon}
              size={iconSize ? iconSize : 22}
              pathColor={"gray"}
            />
            {!isSideBarMinimized && <Text fontSize={14} color={"white"} value={AppStrings.Notifications.Title} />}
            {Boolean(currentLoginResponse?.totalUnreadNotifications) && (
              <UnreadNotificationsBubble
                totalNotifications={currentLoginResponse?.totalUnreadNotifications ?? 0}
                maxTotalNotificationToShow={maxTotalNotificationBubble}
                classNames={[notificationBubble]}
                onTheBell={isSideBarMinimized}
              />
            )}
          </View>
        </TextTooltip>
      )}
    </>
  );
};

export default NotificationCenter;
