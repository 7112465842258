import {
  DataCy,
  ReportId,
  ReportPermission,
  SendTaxForms,
  TaskStatus,
  TAX_YEAR_LAST,
  TaxForm1099ShowRows
} from "@doorloop/dto";
import AppStrings from "locale/keys";
import { NavigationManager } from "utils/navigation";
import { Routes } from "components/appRouter";
import type { ReportScreenItemType } from "DLUI/report/screen/reportScreenItemType";
import { filterObjectsByUserType } from "../../../../../utils/userTypesUtils";

const didPressVendorsByInsuranceStatus = (propertyId?: string) => {
  const params: Record<string, string> = {
    groupBy: "insuranceInfo.expirationDate"
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runVendorsReport(params);
};

const didPressVendorLedger = () => {
  NavigationManager.runVendorsLedgerReport();
};

const didPressAllWorkOrdersByVendor = () => {
  NavigationManager.runWorkOrdersReport({
    groupBy: "workOrder.assignedToVendor"
  });
};

const didPressOpenWorkOrdersByVendor = () => {
  NavigationManager.runWorkOrdersReport({
    filter_status: TaskStatus.IN_PROGRESS,
    groupBy: "workOrder.assignedToVendor"
  });
};

const didPressVendorsDirectory = (propertyId?: string) => {
  const params: Record<string, string> = {};
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runVendorsReport(params);
};

const items: ReportScreenItemType[] = [
  {
    description: AppStrings.Reports.ReportsScreen.VendorsDirectory,
    onPress: didPressVendorsDirectory,
    permission: ReportPermission.vendors,
    id: ReportId.VENDOR_DIRECTORY,
    dataCy: DataCy.reports.screenItems.vendors.vendorsDirectory
  },
  {
    description: AppStrings.Reports.ReportsScreen.VendorsByInsuranceStatus,
    onPress: didPressVendorsByInsuranceStatus,
    permission: ReportPermission.vendors,
    id: ReportId.VENDOR_BY_INSURANCE_STATUS,
    dataCy: DataCy.reports.screenItems.vendors.vendorsByInsuranceStatus
  },
  {
    description: AppStrings.Reports.ReportsScreen.VendorLedger,
    onPress: didPressVendorLedger,
    permission: ReportPermission.vendorTransactions,
    id: ReportId.VENDOR_LEDGER,
    dataCy: DataCy.reports.screenItems.vendors.vendorLedger
  },
  {
    description: AppStrings.Reports.ReportsScreen.AllWorkOrdersByVendor,
    onPress: didPressAllWorkOrdersByVendor,
    permission: ReportPermission.workOrders,
    id: ReportId.ALL_WORK_ORDER_BY_VENDOR,
    dataCy: DataCy.reports.screenItems.vendors.allWorkOrdersByVendor
  },
  {
    description: AppStrings.Reports.ReportsScreen.OpenWorkOrdersByVendor,
    onPress: didPressOpenWorkOrdersByVendor,
    permission: ReportPermission.workOrders,
    id: ReportId.OPEN_WORK_ORDER_BY_VENDOR,
    dataCy: DataCy.reports.screenItems.vendors.openWorkOrdersByVendor
  },
  {
    description: AppStrings.Common.Recurring.RecurringWorkOrders,
    onPress: () => NavigationManager.runRecurringWorkOrdersReport(),
    permission: ReportPermission.workOrders,
    id: ReportId.RECURRING_WORK_ORDERS,
    dataCy: DataCy.reports.screenItems.vendors.recurringWorkOrders
  },
  {
    description: AppStrings.Reports.ReportsScreen.Vendors1099,
    onPress: () =>
      NavigationManager.goTo(Routes.VENDORS_1099_REPORT, {
        filter_year: TAX_YEAR_LAST.toString(),
        showRows: TaxForm1099ShowRows.ABOVE_MINIMUM,
        filter_sendForms: SendTaxForms.Yes
      }),
    permission: ReportPermission.vendors1099,
    id: ReportId.VENDORS_1099,
    dataCy: DataCy.reports.screenItems.vendors._1099NEC
  }
];

const getVendorsItems = (isHOAUser: boolean): ReportScreenItemType[] => {
  if (isHOAUser) {
    return filterObjectsByUserType(items, "HOAUser");
  }

  return items;
};

export default getVendorsItems;
