import React, { useMemo, useState } from "react";
import { CloseIcon, EditBlue, ViewBlueIcon } from "assets/icons";
import type { VendorDto } from "@doorloop/dto";
import { ConversationLinkedToType, DataCy, ObjectPermission } from "@doorloop/dto";
import type { PopoverItem } from "DLUI/popover";
import AppStrings from "locale/keys";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { NavigationManager } from "utils/navigation";
import { useTranslation } from "react-i18next";
import { usePermission } from "screens/settings/userRoles/usePermission";
import ListItemText from "DLUI/listItems/listItemText";
import { Routes } from "@/components/appRouter";
import ListItemContainer from "DLUI/listItems/listItemContainer";
import { Grid } from "DLUI/listItems";
import { ListItemInfoCell } from "DLUI/listItems/listItemInfoCell";
import type { SubscribedActions } from "@/hooks/useListBulkActionsManager";
import { vendorsApi } from "api/vendorsApi";
import ListItemContactSection from "DLUI/listItems/listItemContactSection";
import { sendWelcomeSmsAction } from "@/utils/bulkOperationsUtils";
import { useConfirmationDialog } from "@/hooks/useConfirmationDialog";

interface ComponentProps {
  categoriesText: string;
  onItemPress?: (vendorId: string) => void;
  disableListItemOptions?: boolean;
  vendor: VendorDto;
  resourceId?: string;
  dataCy?: string;
}

const VendorsListItem: React.FC<ComponentProps> = ({
  vendor,
  categoriesText,
  onItemPress,
  disableListItemOptions,
  resourceId,
  dataCy
}: ComponentProps) => {
  const [vendorData, setVendorData] = useState<VendorDto>(vendor);
  const { showConfirmationDialog } = useConfirmationDialog();

  const { jobTitle, name, pictureUrl, balance, insuranceInfo, id } = vendorData;

  const { hasPermission } = usePermission();
  const location = useLocation();
  const { t } = useTranslation();

  const didPressViewProfile = hasPermission(ObjectPermission.vendors, "viewOne")
    ? () => {
        if (id) {
          if (onItemPress) {
            onItemPress(id);
          } else {
            NavigationManager.viewVendorDetails(id);
          }
        }
      }
    : undefined;

  const didPressEditButton = () => {
    if (id) {
      NavigationManager.editVendor(location.pathname, id);
    }
  };

  const didPressDeleteButton = () => {
    if (id) {
      showConfirmationDialog(location.pathname, {
        dialogTitle: AppStrings.Common.DeleteConfirmation,
        dialogSubTitle: AppStrings.Vendors.NewVendor.DeleteConfirmationText,
        loadingText: t(AppStrings.Vendors.NewVendor.DeleteLoadingText),
        successText: t(AppStrings.Vendors.NewVendor.DeleteVendorSuccessText),
        confirmButtonText: AppStrings.Common.Delete,
        dismissButtonText: AppStrings.Common.Dismiss,
        apiMethod: "vendorsApi",
        confirmOperation: "delete",
        itemId: id
      });
    }
  };

  const expirationDays = useMemo(() => {
    if (insuranceInfo && insuranceInfo.expirationDate) {
      const duration = moment.duration(moment(insuranceInfo.expirationDate).diff(moment()));
      const days = duration.asDays();

      return days > 0 ? Math.ceil(duration.asDays()) : -1;
    }
  }, []);

  const cardTopButtons: PopoverItem[] = [
    {
      Icon: EditBlue,
      title: AppStrings.Common.Edit,
      onClick: didPressEditButton,
      clearance: { permission: ObjectPermission.vendors, field: "edit" },
      dataCy: DataCy.DLUI.listItem.listItemEdit
    },
    {
      Icon: ViewBlueIcon,
      title: AppStrings.Tenants.Screen.ViewProfile,
      onClick: didPressViewProfile,
      showSeparator: true,
      clearance: { permission: ObjectPermission.vendors, field: "viewOne" },
      dataCy: DataCy.DLUI.listItem.listItemView
    },
    {
      Icon: CloseIcon,
      title: AppStrings.Common.Delete,
      onClick: didPressDeleteButton,
      iconPathColor: "error",
      textColor: "error",
      clearance: { permission: ObjectPermission.vendors, field: "delete" },
      dataCy: DataCy.DLUI.listItem.listItemDelete
    }
  ];

  const bulkActions: SubscribedActions | undefined = resourceId
    ? {
        deleteVendors: {
          [resourceId]: {
            action: async () => {
              const result = await vendorsApi.delete(id!);
              if (!result.status) {
                throw result.message;
              }

              return { message: t(AppStrings.Common.VendorDeleted) };
            },
            ongoingMessage: t(AppStrings.Vendors.NewVendor.DeleteLoadingText)
          }
        },
        sendVendorWelcomeSms: {
          [resourceId]: {
            action: sendWelcomeSmsAction(ConversationLinkedToType.VENDOR, id),
            ongoingMessage: t(AppStrings.BulkActions.SendWelcomeSmsOngoingMessage)
          }
        }
      }
    : undefined;

  const handleContactInfoUpdated = (vendor: VendorDto) => {
    setVendorData(vendor);
  };

  return (
    <ListItemContainer
      popoverItems={disableListItemOptions ? undefined : cardTopButtons}
      bulkActions={bulkActions}
      resourceId={resourceId}
    >
      <Grid
        showDivider
        xs={12}
        sm={4}
        md={4}
        lg={4}
        sortColumn={""}
        onClick={didPressViewProfile}
        title={AppStrings.Vendors.Screen.Vendor}
        paddingLeft={20}
      >
        <ListItemInfoCell
          showProfileImage
          pictureUrl={pictureUrl}
          primaryText={name}
          secondaryText={jobTitle ? `${jobTitle} | ${categoriesText}` : categoriesText}
          onClick={didPressViewProfile}
          href={`${Routes.VENDORS}/${id}/overview`}
        />
      </Grid>

      <Grid
        showDivider
        xs={12}
        sm={4}
        md={4}
        lg={4}
        title={AppStrings.Users.Screen.ContactInformation}
        paddingLeft={20}
      >
        <ListItemContactSection
          disableEdit={disableListItemOptions}
          onSaveSuccess={handleContactInfoUpdated}
          dto={vendorData}
          api={vendorsApi}
          shouldUpdatePortalInfo={false}
        />
      </Grid>

      <Grid
        showDivider
        xs={12}
        sm={2}
        md={2}
        lg={2}
        onClick={didPressViewProfile}
        title={AppStrings.Common.FilterOptions.Insurance.InsuranceStatus}
        sortColumn={"insuranceInfo.expirationDate"}
        paddingLeft={20}
      >
        {expirationDays ? (
          expirationDays > -1 ? (
            <ListItemText value={AppStrings.Leases.DraftLease.Valid} color={"green"} fontWeight={700} />
          ) : (
            <ListItemText
              value={AppStrings.Leases.DraftLease.Expired}
              color={"error"}
              fontWeight={700}
              overflow={"visible"}
            />
          )
        ) : (
          <ListItemText value={"--"} color={"black"} fontWeight={700} />
        )}
      </Grid>

      <Grid
        xs={12}
        sm={2}
        md={2}
        lg={2}
        onClick={didPressViewProfile}
        title={AppStrings.Vendors.Screen.OpenBalance}
        paddingLeft={20}
      >
        <ListItemText value={String(balance)} color={"black"} formatType={"currency"} align={"right"} />
      </Grid>
    </ListItemContainer>
  );
};

export default VendorsListItem;
