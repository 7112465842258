"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetTransactionReportQuery = void 0;
const accountClass_enum_1 = require("../../accounts/accountClass.enum");
const accountType_enum_1 = require("../../accounts/accountType.enum");
const class_validator_1 = require("class-validator");
const validators_1 = require("../../common/validators");
const transactionReportGroupBy_enum_1 = require("./transactionReportGroupBy.enum");
const accountingMethod_enum_1 = require("../../journalEntries/accountingMethod.enum");
const journalEntryType_enum_1 = require("../../journalEntries/journalEntryType.enum");
const paymentMethod_enum_1 = require("../../journalEntries/paymentMethod.enum");
class GetTransactionReportQuery {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.GetTransactionReportQuery = GetTransactionReportQuery;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetTransactionReportQuery.prototype, "includeBeginningBalance", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(accountingMethod_enum_1.AccountingMethod),
    __metadata("design:type", String)
], GetTransactionReportQuery.prototype, "filter_accountingMethod", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(journalEntryType_enum_1.JournalEntryType),
    __metadata("design:type", String)
], GetTransactionReportQuery.prototype, "filter_type", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(paymentMethod_enum_1.PaymentMethod),
    __metadata("design:type", Object)
], GetTransactionReportQuery.prototype, "filter_paymentMethod", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(transactionReportGroupBy_enum_1.TransactionReportGroupBy),
    __metadata("design:type", String)
], GetTransactionReportQuery.prototype, "groupBy", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetTransactionReportQuery.prototype, "filter_account", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(accountClass_enum_1.AccountClass),
    __metadata("design:type", String)
], GetTransactionReportQuery.prototype, "filter_accountClass", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(accountType_enum_1.AccountType),
    __metadata("design:type", String)
], GetTransactionReportQuery.prototype, "filter_accountType", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], GetTransactionReportQuery.prototype, "filter_reference", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, validators_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetTransactionReportQuery.prototype, "filter_date_from", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, validators_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetTransactionReportQuery.prototype, "filter_date_to", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetTransactionReportQuery.prototype, "filter_property", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetTransactionReportQuery.prototype, "filter_unit", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetTransactionReportQuery.prototype, "filter_tenant", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetTransactionReportQuery.prototype, "filter_lease", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetTransactionReportQuery.prototype, "filter_propertyGroup", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetTransactionReportQuery.prototype, "filter_owner", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetTransactionReportQuery.prototype, "filter_vendor", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetTransactionReportQuery.prototype, "filter_propertyOwner", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], GetTransactionReportQuery.prototype, "period", void 0);
