import type { TFunction } from "react-i18next";
import type { TextFormatType } from "DLUI/text/text";
import type { DataResult, SortDescriptor } from "@progress/kendo-data-query";
import { orderBy } from "@progress/kendo-data-query";
import type { GridColumn, VisibleGridColumn } from "DLUI/lists/types";
import { SELECTION_IDENTIFIER } from "DLUI/lists/types";
import _ from "lodash";
import AppStrings from "locale/keys";
import type { LoginResponseDto } from "@doorloop/dto";
import { mathUtils } from "@doorloop/dto";
import { storage } from "utils/localStorage";

export const columnQueryKey = "columns";

export const omitColumnsKeyFromObject = (obj: object) => _.omit(obj, [columnQueryKey]);

export const parseFloatOrZero = (value: string | number): number => parseFloat(String(value)) || 0;

export const getHeaderClassForColumn = ({ field, formatType }: { field?: string; formatType?: TextFormatType }) => {
  if (field === SELECTION_IDENTIFIER) {
    return "k-grid-select-all-checkbox-cell";
  }

  if (formatType === "currency" || formatType === "number" || formatType === "percent") {
    return "currency-column-header";
  }
  return "";
};

export const getColumnComparer = (field: string | undefined) => (column) => column.field === field;

const naturallySortArray = (data: any[], sort: SortDescriptor[]) => {
  if (sort?.length && data?.length) {
    return data.sort((a, b) => {
      if (sort[0].dir === "desc") {
        return _.get(b, sort[0].field)?.toString().localeCompare(_.get(a, sort[0].field), undefined, {
          numeric: true,
          sensitivity: "base"
        });
      }
      return _.get(a, sort[0].field)?.toString().localeCompare(_.get(b, sort[0].field), undefined, {
        numeric: true,
        sensitivity: "base"
      });
    });
  }
  return data;
};

export const getSortedDataSource = (dataSource: DataResult, sort: SortDescriptor[]): DataResult => {
  if (dataSource.data) {
    if (sort.length) {
      dataSource.data = naturallySortArray(dataSource.data, sort);
      for (const item of dataSource.data) {
        if (item.items) {
          item.items = naturallySortArray(item.items, sort);
        }
      }
    } else {
      dataSource.data = orderBy(dataSource.data, sort);
      for (const item of dataSource.data) {
        if (item.items) {
          item.items = orderBy(item.items, sort);
        }
      }
    }
  }

  return dataSource;
};

export const columnQuerySeparator = "---";

export const buildColumnQueryString = (columns: VisibleGridColumn[]): string =>
  columns.map(({ field }) => field).join(columnQuerySeparator);

export const pickColumnsByQuery = (columns: GridColumn[], query: string): GridColumn[] | null => {
  if (!query) {
    return null;
  }

  const queryFields = query.split(columnQuerySeparator);
  return queryFields.map((queryField) => columns.find(getColumnComparer(queryField)) as GridColumn).filter(Boolean);
};

const getPickedColumnsLocalStorageKey = (key: string) => `${key}-picked-grid-columns`;

export const setColumnsLocalStorage = (key: string, columns: VisibleGridColumn[]): void => {
  storage.set(
    getPickedColumnsLocalStorageKey(key),
    columns.map(({ field }) => field)
  );
};

export const pickColumnsByLocalStorage = (columns: GridColumn[], key: string): GridColumn[] | null => {
  const lsColumns = storage.get<string[] | null>(getPickedColumnsLocalStorageKey(key), null);

  if (!lsColumns) {
    return null;
  }

  return lsColumns.map((lsColumn) => columns.find(getColumnComparer(lsColumn)) as GridColumn).filter(Boolean);
};

export const getExcelExportFileName = (
  t: TFunction<"translation">,
  exportFileName: string,
  userData: LoginResponseDto | undefined,
  filterObj: Record<string, any>
) => {
  const fileNameArray: string[] = [];
  let reportDates: string;

  const { filter_date_from, filter_date_to } = (filterObj as any) ?? {};

  if (!filter_date_from || !filter_date_to) {
    reportDates = t(AppStrings.Common.FilterPanelDatePicker.AllTime);
  } else {
    reportDates = `${filter_date_from}_${filter_date_to}`;
  }

  // add the company name
  if (userData?.currentDbTenant?.companyName) {
    fileNameArray.push(userData.currentDbTenant.companyName);
  }

  // add the desired export file name (if any)
  fileNameArray.push(exportFileName);

  // add the report dates
  fileNameArray.push(reportDates);

  // Only allow alphebetic characters, numbers, underscores and dashes
  const fileName = fileNameArray.join("_").replaceAll(/[^a-zA-Z0-9_\-]/g, "");

  return `${fileName}.xlsx`;
};

interface DataRow {
  items: any[];
}

export const getColumnTotals = (
  data: DataRow[],
  field: string,
  averageZeroValues?: boolean,
  averageGroupItemsIndividually?: boolean
) => {
  let transactionsSum = 0;
  let transactionsCount = 0;

  data.forEach((currentRow: any) => {
    if (currentRow.items) {
      let groupFieldsSum = 0;

      currentRow.items.forEach((currentItem: any) => {
        const fieldValue = _.get(currentItem, field) || 0;
        groupFieldsSum += parseFloatOrZero(fieldValue);

        if (averageGroupItemsIndividually && (averageZeroValues || fieldValue !== 0)) {
          transactionsCount++;
        }
      });

      transactionsSum += groupFieldsSum;

      if (!averageGroupItemsIndividually && (averageZeroValues || groupFieldsSum !== 0)) {
        transactionsCount++;
      }
    } else if (_.get(currentRow, field) !== undefined) {
      const fieldValue = _.get(currentRow, field) || 0;
      transactionsSum += parseFloatOrZero(fieldValue);

      if (averageZeroValues || fieldValue !== 0) {
        transactionsCount++;
      }
    }
  });

  return {
    sum: transactionsSum,
    average: Number(mathUtils.divide(transactionsSum, transactionsCount).toFixed(2)),
    count: transactionsCount
  };
};
