import React, { useState } from "react";
import { View } from "DLUI/view";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { filesApi } from "api/filesApi";
import { FilePdfIcon } from "assets/icons";
import { Icon } from "DLUI/icon";
import TextEllipsis from "DLUI/text/textEllipsis";
import downloadAnimation from "../../../../assets/lottie/download.json";
import { Lottie } from "DLUI/lottie";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  id: string;
  title?: string;
}

const RequestDocument: React.FC<ComponentProps> = ({ id, title }: ComponentProps) => {
  const { screenContainerPadding } = useResponsiveHelper();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const didPressDownloadDoc = async () => {
    if (id) {
      setIsDownloading(true);
      await filesApi.downloadSignatureRequestPdf(id, title || "");
      setIsDownloading(false);
    }
  };

  return (
    <View flex={1} width={"100%"} justifyContent={"flex-end"}>
      <SeparationLine width={"100%"} height={1} />
      <Text marginBottom={20} marginTop={20} value={AppStrings.Esignatures.Document} fontSize={18} bold />
      <View
        height={55}
        style={{ backgroundColor: "rgba(22, 101, 216, 0.1)" }}
        borderRadius={5}
        alignItems={"center"}
        paddingRight={screenContainerPadding}
        paddingLeft={screenContainerPadding}
        flexDirection={"row"}
        cursor={"pointer"}
        onClick={didPressDownloadDoc}
        marginBottom={20}
      >
        <Icon width={25} height={25} pathColor={"light-blue"} Source={FilePdfIcon} />
        <View flex={1}>
          <TextEllipsis marginLeft={10} numberOfLines={1} color={"lightBlue"} text={title + ".pdf" || ""} />
        </View>
        <View flexDirection={"row"} alignItems={"center"} justifyContent={"flex-end"} flex={1}>
          <SeparationLine marginRight={20} width={1} height={25} />
          <Lottie
            animationData={downloadAnimation}
            width={24}
            height={24}
            loop={isDownloading}
            autoPlay={isDownloading}
            pathColor={"light-blue"}
          />
        </View>
      </View>

      <SeparationLine marginBottom={20} width={"100%"} height={1} />
    </View>
  );
};

export default RequestDocument;
