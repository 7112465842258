"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EPayPaymentStatus = void 0;
var EPayPaymentStatus;
(function (EPayPaymentStatus) {
    EPayPaymentStatus["PROCESSING"] = "PROCESSING";
    EPayPaymentStatus["SUCCEEDED"] = "SUCCEEDED";
    EPayPaymentStatus["PENDING_REVIEW"] = "PENDING_REVIEW";
    EPayPaymentStatus["FAILED"] = "FAILED";
    EPayPaymentStatus["DISPUTED"] = "DISPUTED";
    EPayPaymentStatus["REFUNDED"] = "REFUNDED";
    EPayPaymentStatus["IN_TRANSIT"] = "IN_TRANSIT";
    EPayPaymentStatus["PAID"] = "PAID";
})(EPayPaymentStatus || (exports.EPayPaymentStatus = EPayPaymentStatus = {}));
