import { RecurringTransactionStatus } from "@doorloop/dto";
import { CheckmarkCircleIcon, ClockOrangeIcon, CloseGrayIcon, ExpiredCircleIcon } from "assets/icons";
import AppStrings from "locale/keys";
import type { IconTextAndColor } from "DLUI/text/text";
import moment from "moment";

const statusDataMap: Record<RecurringTransactionStatus, IconTextAndColor> = {
  [RecurringTransactionStatus.ACTIVE]: {
    StatusIcon: CheckmarkCircleIcon,
    statusTextColor: "green",
    statusText: AppStrings.Leases.NewLease.LeaseStatus.Active
  },
  [RecurringTransactionStatus.EXPIRED]: {
    StatusIcon: CloseGrayIcon,
    statusTextColor: "gray",
    statusText: AppStrings.Leases.DraftLease.Expired
  },
  [RecurringTransactionStatus.FUTURE]: {
    StatusIcon: ClockOrangeIcon,
    statusTextColor: "orange",
    statusText: AppStrings.Tenants.FilterValues.Future
  },
  [RecurringTransactionStatus.CANCELED]: {
    StatusIcon: ExpiredCircleIcon,
    statusTextColor: "gray",
    statusText: AppStrings.Tenants.FilterValues.Canceled
  }
};

export const getRecurringStatusData = (
  status: RecurringTransactionStatus = RecurringTransactionStatus.ACTIVE,
  start?: string,
  end?: string
): IconTextAndColor => {
  if (status !== RecurringTransactionStatus.CANCELED) {
    const today = moment().format("YYYY-MM-DD");
    if (start && today < start) {
      status = RecurringTransactionStatus.FUTURE;
    } else if (end && today > end) {
      status = RecurringTransactionStatus.EXPIRED;
    } else {
      status = RecurringTransactionStatus.ACTIVE;
    }
  }
  return statusDataMap[status];
};
