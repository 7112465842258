import type { ImageButtonProps } from "DLUI/form";
import { LeaseTermType } from "@doorloop/dto";
import { CalendarCheckIcon, CalendarFullIcon } from "assets/icons";
import AppStrings from "locale/keys";
import { DataCy } from "@doorloop/dto";

export const termTypes: ImageButtonProps[] = [
  {
    label: AppStrings.Leases.NewLease.LeaseOverview.TermsSection.ImageBoxes.Fixed,
    icon: CalendarCheckIcon,
    onPress: () => {},
    isSelected: false,
    value: LeaseTermType.Fixed,
    itemIndex: 0,
    dataCy: DataCy.newLease.leaseTerms.fixedTerm
  },
  {
    label: AppStrings.Leases.NewLease.LeaseOverview.TermsSection.ImageBoxes.Monthly,
    icon: CalendarFullIcon,
    onPress: () => {},
    isSelected: false,
    value: LeaseTermType.AtWill,
    itemIndex: 1,
    dataCy: DataCy.newLease.leaseTerms.monthToMonth
  }
];
