import React, { useState } from "react";

import { Grid } from "@material-ui/core";

import ActionButton from "screens/onBoarding/components/actionButton";
import AppStrings from "locale/keys";
import StepSection from "screens/onBoarding/components/stepSection";
import Text from "DLUI/text";
import type { StepPropsWithDto } from "screens/onBoarding/types";
import { Icon } from "DLUI/icon";
import { NavigationManager } from "utils/navigation";
import { onBoardingApi } from "api/onBoardingApi";
import { SmsIcon } from "assets/.";
import { VideoUrlsEnum } from "DLUI/screen/helpPanel/types";
import { View } from "DLUI/view";

export const SmsMessagingStep: React.FC<StepPropsWithDto> = ({
  isCollapsed,
  onHeaderClick,
  name,
  onInvalidate,
  isCompleted,
  isSkipped
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const title = AppStrings.OnBoarding.StepTitles.SmsMessaging;
  const description = AppStrings.OnBoarding.StepDescriptions.SmsMessaging;

  const handleMarkAsComplete = async (): Promise<void> => {
    setIsLoading(true);

    const { status } = await onBoardingApi.updateOnBoardingItem(name, { isSkipped: !isSkipped });

    await onInvalidate({ error: !status });

    setIsLoading(false);
  };

  const handleSetupSmsClick = (): void => {
    if (isCompleted) {
      NavigationManager.navigateToCommunicationsCenter();
    } else {
      NavigationManager.navigateToSetupSmsWizard("onboarding");
    }
  };

  const LeftSideContent = (): JSX.Element => (
    <View gap={15}>
      <Text whiteSpace={"pre-line"} value={description} />
      <Grid container direction={"row"} alignItems={"center"} spacing={2}>
        <Grid item xs={12} sm={"auto"} md={"auto"}>
          <ActionButton
            fullWidth
            width={"100%"}
            onClick={handleSetupSmsClick}
            actionText={
              isCompleted
                ? AppStrings.OnBoarding.ActionButtons.StartTexting
                : AppStrings.OnBoarding.ActionButtons.SetupSms
            }
          />
        </Grid>
        <Grid item xs={12} sm={"auto"} md={"auto"}>
          {(!isCompleted || isSkipped) && (
            <ActionButton
              onClick={handleMarkAsComplete}
              faded
              requestInProgress={isLoading}
              actionText={
                isCompleted || isSkipped
                  ? AppStrings.OnBoarding.MarkAsIncomplete
                  : AppStrings.OnBoarding.MarkAsCompleted
              }
              fullWidth
              width={"100%"}
            />
          )}
        </Grid>
      </Grid>
    </View>
  );

  return (
    <StepSection
      icon={<Icon size={24} Source={SmsIcon} />}
      title={title}
      leftSideContent={<LeftSideContent />}
      isCompleted={isCompleted}
      isSkipped={isSkipped}
      onHeaderClick={onHeaderClick}
      isCollapsed={isCollapsed}
      reactPlayerVideoUrl={VideoUrlsEnum.OB_SMS_MESSAGING}
      isLoading={isLoading}
    />
  );
};
