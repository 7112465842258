import React, { Fragment, useState, useMemo } from "react";
import { WorkOrderPartsAndLaborItemDto } from "@doorloop/dto";
import { AddIcon } from "assets/icons";
import { Button } from "DLUI/button";
import { SwitchButton } from "DLUI/form";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FieldArrayRenderProps, FormikProps } from "formik";
import { FieldArray, getIn } from "formik";
import AppStrings from "locale/keys";
import PartsAndLaborItem from "./partsAndLaborItem";

interface ComponentProps {
  formikRef: FormikProps<any>;
}

const PartsAndLabors: React.FC<ComponentProps> = ({ formikRef }: ComponentProps) => {
  const _showPartsAndLaborsSection = useMemo(() => {
    const partsAndLaborsItems = getIn(formikRef.values, "workOrder.partsAndLaborInfo.items");
    if (partsAndLaborsItems && partsAndLaborsItems.length > 0) {
      return true;
    }
    return false;
  }, []);

  const [showPartsAndLaborsSection, setShowPartsAndLaborsSection] = useState<boolean>(_showPartsAndLaborsSection);

  const didChangeSwitchButton = (nextValue: boolean) => {
    if (!nextValue) {
      formikRef.setFieldValue("workOrder.partsAndLaborInfo", undefined);
    }
    setShowPartsAndLaborsSection(nextValue);
  };

  const renderLines = (arrayHelpers: FieldArrayRenderProps) => {
    const partsAndLaborsItems = getIn(formikRef.values, "workOrder.partsAndLaborInfo.items");
    if (partsAndLaborsItems) {
      return partsAndLaborsItems.map((currentItem: WorkOrderPartsAndLaborItemDto, index: number) => (
        <Fragment key={"PLI" + index}>
          <PartsAndLaborItem arrayHelpers={arrayHelpers} itemIndex={index} formikRef={formikRef} />
        </Fragment>
      ));
    }
    return null;
  };

  const renderAddLineButton = (arrayHelpers: FieldArrayRenderProps) => {
    const didPressAddLine = () => {
      const workOrderPartsAndLaborItem = new WorkOrderPartsAndLaborItemDto();
      arrayHelpers.push(workOrderPartsAndLaborItem);
    };

    return (
      <View marginTop={20}>
        <Button
          color={"lightBlue"}
          type={"inlineText"}
          actionText={AppStrings.Leases.LeaseCharge.AddLineItem}
          onClick={didPressAddLine}
          LeftIcon={AddIcon}
          applyColorForIcons
          iconSize={15}
        />
      </View>
    );
  };

  return (
    <View marginTop={20}>
      <SwitchButton
        onChange={didChangeSwitchButton}
        label={AppStrings.Tasks.WorkOrderDialog.PartsAndLabors}
        checked={showPartsAndLaborsSection}
      />
      <View shouldShow={showPartsAndLaborsSection} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
        <View>
          <Text
            value={AppStrings.Tasks.WorkOrderDialog.PartsAndLaborsDescription}
            fontSize={16}
            color={"black"}
            paddingTop={20}
          />
          <FieldArray
            name={`workOrder.partsAndLaborInfo.items`}
            render={(arrayHelpers) => (
              <Fragment>
                {renderLines(arrayHelpers)}
                {renderAddLineButton(arrayHelpers)}
              </Fragment>
            )}
          />
        </View>
      </View>
    </View>
  );
};

export default PartsAndLabors;
