import type { FC } from "react";
import React from "react";
import { Icon } from "DLUI/icon/icon";
import type { SVGIconComponent } from "assets/icons";
import { View } from "DLUI/view";
import type { ViewBackgroundColor } from "DLUI/view/colorMappings";

interface Props {
  Source: SVGIconComponent;
  size?: number;
  backgroundColor?: ViewBackgroundColor;
}

export const IconSquare: FC<Props> = ({ Source, size = 40, backgroundColor = "secondary-gray-light" }: Props) => (
  <View
    height={size}
    width={size}
    borderRadius={5}
    backgroundColor={backgroundColor}
    alignItems={"center"}
    justifyContent={"center"}
  >
    <Icon Source={Source} height={size / 2} width={size / 2} />
  </View>
);
