import type { FC } from "react";
import React, { useEffect, useMemo, useState } from "react";
import type { UnitDto } from "@doorloop/dto";
import {
  createValidator,
  LinkedResourceDto,
  LinkedResourceType,
  OwnerDto,
  PropertyDto,
  PropertyOwnerDto
} from "@doorloop/dto";
import type { ApiResult } from "api/apiResult";
import { ownersApi } from "api/ownersApi";
import { propertiesApi } from "api/propertiesApi";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { Formik } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  initFormValues as ownerInitFormValues,
  validateForm as validateNewOwnerForm,
  validateOwnerForm
} from "screens/owners/newOwner/formikHelper";
import { getOwnerFormMenuItems } from "screens/owners/newOwner/menuItems";
import AnimatedContent from "../../components/animatedContent";
import OwnersSelectionView from "../common/owners//ownersSelectionView";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import OwnersView from "@/components/screens/properties/newProperty/components/propertyOwnership/ownersView";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { ArticleIdsEnum, HelpTypeEnum } from "DLUI/screen/helpPanel/types";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import type { Method } from "axios";
import type { ApiToastsProps } from "api/apiHelper";
import { useBasePersonValidation } from "screens/vendors/useBasePersonValidation";
import { isValidOwnershipDistribution } from "screens/properties/newProperty/newPropertyStepValidators";

interface ComponentProps {
  didFinishOperation?: (values: OwnerDto) => void;
  onBackdropClick: () => void;
  refreshEvent?: () => void;
  dialogTitle: string;
  isSelectionOnly?: boolean;
  propertyIdProp?: string;
  toasts?: Partial<Record<Method, ApiToastsProps<PropertyDto>>>;
}

let formikGlobalRef: FormikProps<any> | null = null;

const DialogViews = {
  LoadingView: 0,
  DialogForm: 1,
  OwnerSelectionView: 2,
  CreateNewOwner: 3,
  UpdatePropertyLoadingView: 4
};

const validateForm = createValidator(PropertyDto);

const helpObject: HelpPanelProps = {
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Owners.EditOwners.OwnerAddingHelpText,
      articleId: ArticleIdsEnum.EDIT_OWNER
    }
  ],
  description: AppStrings.Owners.EditOwners.Instructions
};

const EditPropertyOwners: FC<ComponentProps> = ({
  didFinishOperation,
  refreshEvent,
  onBackdropClick,
  dialogTitle,
  isSelectionOnly = false,
  propertyIdProp,
  toasts
}) => {
  const { t } = useTranslation();
  const { propertyId: paramPropertyId } = useParams<{ propertyId: string | undefined }>();
  const propertyId = propertyIdProp || paramPropertyId;
  const [propertyData, setPropertyData] = useState<PropertyDto | undefined>();
  const [viewIndex, setViewIndex] = useState(DialogViews.LoadingView);
  const [createOwnerTabIndex, setCreateOwnerTabIndex] = useState<number>(0);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>(AppStrings.Common.NetworkErrorSubTitle);
  const [currentOwnersSelection, setCurrentOwnersSelection] = useState<OwnerDto[]>([]);
  const [currentMenuItemTitle, setCurrentMenuItemTitle] = useState<string>("");
  const [pendingFile, setPendingFile] = useState<File | undefined>();
  const [invalidForm, setInvalidForm] = useState<boolean>(false);
  const [hideLoadingDialogActionButtons, setHideLoadingDialogActionButtons] = useState<boolean>(false);
  const { personValidateBase } = useBasePersonValidation();

  const dialogHeight = useMemo(
    () => getDialogFrameDimension("height", viewIndex === DialogViews.DialogForm ? 500 : 800),
    [viewIndex]
  );
  const dialogWidth = useMemo(
    () => getDialogFrameDimension("width", viewIndex === DialogViews.DialogForm ? 690 : 800),
    [viewIndex]
  );

  const loadPropertyData = async () => {
    if (propertyId) {
      setLoadingDialogState(DialogState.Show);
      const response = await propertiesApi.get(propertyId).catch((e: string) => {
        showDialogError(e);
      });
      if (response && response.status) {
        setPropertyData(response.data);
        setViewIndex(DialogViews.DialogForm);
      } else {
        showDialogError();
      }
    } else {
      showDialogError();
    }
  };

  const showDialogError = (errorMessage?: string) => {
    setHideLoadingDialogActionButtons(true);
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(errorMessage || AppStrings.Common.GeneralError);
  };

  const sectionItems = useMemo(() => getOwnerFormMenuItems(), []);

  useEffect(() => {
    if (isSelectionOnly) {
      setViewIndex(DialogViews.OwnerSelectionView);
    } else {
      loadPropertyData();
    }

    return () => {
      formikGlobalRef = null;
    };
  }, []);

  useEffect(() => {
    if (propertyData !== undefined) {
      loadOwnersSelection();
    }
  }, [propertyData]);

  const menuTitle = useMemo(() => {
    if (viewIndex === DialogViews.DialogForm) {
      return dialogTitle;
    }
    if (viewIndex === DialogViews.OwnerSelectionView) {
      return AppStrings.Owners.NewOwner.SelectOwner;
    }
    if (viewIndex === DialogViews.CreateNewOwner) {
      return AppStrings.Owners.Screen.AddNewOwner;
    }
  }, [viewIndex]);

  const loadOwnersSelection = async () => {
    if (propertyData && propertyData.owners && propertyData.owners.length > 0) {
      const _ownerSelection: OwnerDto[] = [];
      propertyData.owners.forEach((currentPropertyOwner: PropertyOwnerDto) => {
        const owner = new OwnerDto();
        owner.id = currentPropertyOwner.owner;
        const ownersDictionaryItem = ownersApi.getItemFromDictionary(owner.id!);

        if (ownersDictionaryItem) {
          owner.pictureUrl = ownersDictionaryItem.pictureUrl;
          owner.name = ownersDictionaryItem.name;
        }

        _ownerSelection.push(owner);
      });
      setCurrentOwnersSelection(_ownerSelection);
    }
  };

  const initFormValues = (): PropertyDto => {
    if (formikGlobalRef && formikGlobalRef.values) {
      return formikGlobalRef.values;
    }
    if (propertyData) {
      return propertyData;
    }
    return new PropertyDto();
  };

  const isValidForm = async (formikRef: FormikProps<PropertyDto>) => {
    if (formikRef.values && formikRef.values.owners) {
      formikRef.values.owners.forEach((arrayItem, index) => {
        formikRef.setFieldTouched(`owners[${index}].owner`);
        formikRef.setFieldTouched(`owners[${index}].ownershipPercentage`);
      });
    }
    if (!isValidOwnershipDistribution(formikRef.values.owners || [])) {
      setInvalidForm(true);
      return false;
    }
    setInvalidForm(false);
    const errors = (await formikRef.validateForm()) as any;

    return _.isEmpty(errors.owners);
  };

  const updateProperty = async (owners: PropertyOwnerDto[]) => {
    if (formikGlobalRef !== null && propertyData) {
      const requestParams = {
        ...propertyData
      };
      requestParams["owners"] = owners;

      if (propertyData.id) {
        const response = (await propertiesApi.update(propertyData.id, requestParams, toasts?.PUT).catch((error) => {
          setLoadingDialogErrorText(error);
          setLoadingDialogState(DialogState.Error);
        })) as ApiResult<any>;

        if (response.status !== undefined && !response.status) {
          setLoadingDialogErrorText(response.message);
          setLoadingDialogState(DialogState.Error);
        } else {
          setLoadingDialogState(DialogState.Hidden);
          didFinishUpdateProperty();
          formikGlobalRef = null;
        }
      } else {
        setLoadingDialogErrorText(AppStrings.Common.GeneralError);
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const didPressSaveButton = async () => {
    if (formikGlobalRef !== null && propertyData) {
      const isValid = await isValidForm(formikGlobalRef);

      if (isValid) {
        setLoadingDialogState(DialogState.Show);
        setViewIndex(DialogViews.UpdatePropertyLoadingView);
        await updateProperty(formikGlobalRef.values.owners);
      }
    }
  };

  const didCreateNewOwner = (values: OwnerDto) => {
    const newOwner = new OwnerDto();

    newOwner.id = values.id;
    newOwner.name = values.name;
    newOwner.pictureUrl = values.pictureUrl;

    if (isSelectionOnly) {
      didFinishOperation?.(newOwner as OwnerDto);
    }

    currentOwnersSelection.push(newOwner);

    setCurrentOwnersSelection(currentOwnersSelection);

    setViewIndex(DialogViews.DialogForm);
    setTimeout(() => {
      setOwnersIds(currentOwnersSelection);
    }, 300);
  };

  const showErrorMessage = (error: string) => {
    setLoadingDialogErrorText(error);
    setLoadingDialogState(DialogState.Error);
  };

  const uploadFile = async (formikRef: FormikProps<any>, id: string, onClose: (values?: any) => void) => {
    setLoadingDialogState(DialogState.Show);
    const linkedResourceType = LinkedResourceType.Owner;
    const LinkedResource = new LinkedResourceDto(id, linkedResourceType);
    const response = (await ownersApi.uploadPicture(pendingFile!, LinkedResource, id).catch((error: string) => {
      showErrorMessage(error || t(AppStrings.Common.GeneralError));
    })) as ApiResult<any>;
    if (response && response.status) {
      setLoadingDialogState(DialogState.Hidden);
      onClose(response.data);
    } else {
      showErrorMessage(response.message);
    }
  };

  const createNewOwner = async (formikRef: FormikProps<any>) => {
    if (formikRef !== null) {
      const response = (await ownersApi.create(formikRef.values).catch(() => {
        setLoadingDialogState(DialogState.Error);
      })) as ApiResult<any>;

      if (response && response.status) {
        if (pendingFile) {
          await uploadFile(formikRef, response.data.id, didCreateNewOwner);
        } else {
          setLoadingDialogState(DialogState.Hidden);
          didCreateNewOwner(response.data);
        }
      } else {
        setLoadingDialogErrorText(response.message);
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const didPressSaveNewOwner = async (formikRef: FormikProps<any>) => {
    if (formikRef !== null) {
      formikRef.setFieldTouched("firstName");
      formikRef.setFieldTouched("lastName");
      const validationResult = await personValidateBase(formikRef, validateOwnerForm, sectionItems);
      if (validationResult.errorStepIndex === undefined) {
        setLoadingDialogState(DialogState.Show);
        setViewIndex(DialogViews.LoadingView);
        await createNewOwner(formikRef);
      } else {
        setCreateOwnerTabIndex(validationResult.errorStepIndex);
      }
    }
  };

  const didPressCancelNewOwner = () => {
    setViewIndex(DialogViews.OwnerSelectionView);
  };

  const renderActionPanelButtons = () => (
    <FormActionButtons
      propsSubButton={{ onClick: onBackdropClick }}
      propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
    />
  );

  const didPressDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(DialogViews.DialogForm);
  };

  const onRetryButtonPress = () => {
    if (formikGlobalRef !== null) {
      setLoadingDialogState(DialogState.Hidden);
      setViewIndex(0);
    }
  };

  const didFinishUpdateProperty = () => {
    if (formikGlobalRef !== null) {
      didFinishOperation?.(formikGlobalRef.values);
      if (refreshEvent) {
        refreshEvent();
      }
      setLoadingDialogState(DialogState.Hidden);
    }
  };

  const didPressSelectOwner = () => {
    setViewIndex(DialogViews.OwnerSelectionView);
  };

  const didPressDeleteButton = (itemIndex: number) => {
    if (formikGlobalRef) {
      const nextSelectionArray = _.cloneDeep(currentOwnersSelection);
      nextSelectionArray.splice(itemIndex, 1);
      setCurrentOwnersSelection(nextSelectionArray);
      formikGlobalRef.setFieldValue("owners", nextSelectionArray.length === 0 ? undefined : nextSelectionArray);
      setOwnersIds(nextSelectionArray);
    }
  };

  const didChangeOwnerDistribution = () => {
    if (formikGlobalRef && !isValidOwnershipDistribution(formikGlobalRef.values.owners || [])) {
      setInvalidForm(true);
    } else {
      setInvalidForm(false);
    }
  };

  const setOwnersIds = (ownersSelection: OwnerDto[]) => {
    if (formikGlobalRef !== null) {
      const formikIdsArray: PropertyOwnerDto[] = [];
      ownersSelection.forEach((currentOwner) => {
        const propertyOwnerDto = new PropertyOwnerDto();
        if (formikGlobalRef !== null && formikGlobalRef.values && formikGlobalRef.values.owners) {
          const previousValueIndex = formikGlobalRef.values.owners.findIndex(
            (x: PropertyOwnerDto) => x.owner == currentOwner.id
          );
          if (previousValueIndex > -1) {
            propertyOwnerDto.ownershipPercentage =
              formikGlobalRef.values.owners[previousValueIndex].ownershipPercentage;
          }
        }
        propertyOwnerDto.owner = currentOwner.id;
        formikIdsArray.push(propertyOwnerDto);
      });
      formikGlobalRef.setFieldValue("owners", formikIdsArray);
    }
  };

  const didSelectOwnerItem = (selectedItem: OwnerDto) => {
    if (selectedItem) {
      const newOwner = new OwnerDto();
      const previousSelectionIndex = currentOwnersSelection.findIndex((currentItem) => {
        if (selectedItem.id) {
          return currentItem.id === selectedItem.id;
        }
        return false;
      });
      if (previousSelectionIndex > -1) {
        setViewIndex(DialogViews.DialogForm);
        return;
      }
      newOwner.id = selectedItem.id;
      newOwner.name = selectedItem.name;
      newOwner.pictureUrl = selectedItem.pictureUrl;

      if (isSelectionOnly) {
        didFinishOperation?.(newOwner as UnitDto);
      }

      currentOwnersSelection.push(newOwner);

      setViewIndex(DialogViews.DialogForm);
      setCurrentOwnersSelection(currentOwnersSelection);
      setTimeout(() => {
        setOwnersIds(currentOwnersSelection);
      }, 300);
    }
  };

  const onMenuItemSelection = (menuItemTitle: string, itemIndex?: number) => {
    setCreateOwnerTabIndex(itemIndex || 0);
    setCurrentMenuItemTitle(menuItemTitle);
  };

  const onFileReceive = (receivedFile: File) => {
    setPendingFile(receivedFile);
  };

  const onFileDelete = () => {
    setPendingFile(undefined);
  };

  const didPressAddNewOwner = () => {
    setViewIndex(DialogViews.CreateNewOwner);
  };

  const renderAddNewOwnerActionPanelButtons = (formikRef: FormikProps<any>) => {
    const _didPressSaveNewOwner = () => {
      didPressSaveNewOwner(formikRef);
    };

    return (
      <FormActionButtons
        propsSubButton={{ onClick: didPressCancelNewOwner }}
        propsMainButton={{ type: "cta", props: { onClick: _didPressSaveNewOwner } }}
      />
    );
  };

  const renderView = ({ index }: any) => {
    if (index === DialogViews.LoadingView) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          loadingText={t(AppStrings.Common.Loading)}
          errorText={loadingDialogErrorText}
          successText={t(AppStrings.Common.OperationCompleted)}
          onRetryButtonPress={loadPropertyData}
          didPressDismissButton={didPressDismissButton}
          hideButtons={hideLoadingDialogActionButtons}
        />
      );
    }
    if (index === DialogViews.DialogForm) {
      const formInitialValues = initFormValues();
      return (
        <Formik initialValues={formInitialValues} onSubmit={() => {}} validate={validateForm}>
          {(formik) => {
            formikGlobalRef = formik;

            return (
              <View paddingLeft={20} paddingRight={10}>
                <OwnersView
                  didPressDeleteButton={didPressDeleteButton}
                  didChangeOwnerDistribution={didChangeOwnerDistribution}
                  currentOwnersSelection={currentOwnersSelection}
                  didPressSelectOwner={didPressSelectOwner}
                  shouldShowError={invalidForm}
                />
              </View>
            );
          }}
        </Formik>
      );
    }
    if (index === DialogViews.OwnerSelectionView) {
      return (
        <OwnersSelectionView
          showAddNewOwner={true}
          didSelectOwnerItem={didSelectOwnerItem}
          height={dialogHeight - 100}
          didPressAddNewOwner={didPressAddNewOwner}
        />
      );
    }
    if (index === DialogViews.CreateNewOwner) {
      if (sectionItems) {
        return (
          <AnimatedContent
            formikInitialValues={ownerInitFormValues}
            sectionItems={sectionItems}
            formikValidation={validateNewOwnerForm}
            dialogHeight={dialogHeight}
            renderActionPanelButtons={renderAddNewOwnerActionPanelButtons}
            onMenuItemSelection={onMenuItemSelection}
            onFileReceive={onFileReceive}
            onFileDelete={onFileDelete}
            selectedMenuIndex={createOwnerTabIndex}
          />
        );
      }
      return <div />;
    }
    if (index === DialogViews.UpdatePropertyLoadingView) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            errorText={loadingDialogErrorText}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
            hideButtons={hideLoadingDialogActionButtons}
          />
        </View>
      );
    }
    return <div />;
  };

  const _onBackdropClick = () => {
    if (isSelectionOnly) {
      if (viewIndex === DialogViews.CreateNewOwner) {
        setViewIndex(DialogViews.OwnerSelectionView);
      } else {
        onBackdropClick();
      }
    } else if (viewIndex > 1) {
      setViewIndex(viewIndex - 1);
    } else if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const frameType = useMemo(() => {
    if (viewIndex === DialogViews.LoadingView || viewIndex === DialogViews.UpdatePropertyLoadingView) {
      return "contentOnly";
    }

    if (viewIndex === DialogViews.DialogForm) {
      return "sectionTitleFrame";
    }
    if (viewIndex === DialogViews.OwnerSelectionView) {
      return "topPanel";
    }
    if (viewIndex === DialogViews.CreateNewOwner) {
      return "sideMenu";
    }

    return "sectionTitleFrame";
  }, [viewIndex]);

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={menuTitle}
      width={dialogWidth}
      height={dialogHeight}
      renderView={renderView}
      numViews={5}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={frameType}
      sectionTitle={currentMenuItemTitle}
      useExperimentalDialogFrame
      helpPanel={helpObject}
    />
  );
};

export default EditPropertyOwners;
