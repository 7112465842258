"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usStates = exports.regionNames = exports.currencyCodes = exports.caProvinces = void 0;
exports.caProvinces = [
    {
        name: "Ontario",
        abbreviation: "ON"
    },
    {
        name: "Quebec",
        abbreviation: "QC"
    },
    {
        name: "Nova Scotia",
        abbreviation: "NS"
    },
    {
        name: "New Brunswick",
        abbreviation: "NB"
    },
    {
        name: "Manitoba",
        abbreviation: "MB"
    },
    {
        name: "British Columbia",
        abbreviation: "BC"
    },
    {
        name: "Prince Edward Island",
        abbreviation: "PE"
    },
    {
        name: "Saskatchewan",
        abbreviation: "SK"
    },
    {
        name: "Alberta",
        abbreviation: "AB"
    },
    {
        name: "Newfoundland and Labrador",
        abbreviation: "NL"
    }
];
exports.currencyCodes = [
    {
        code: "AED",
        display: "United Arab Emirates Dirham"
    },
    {
        code: "AFN",
        display: "Afghan Afghani"
    },
    {
        code: "ALL",
        display: "Albanian Lek"
    },
    {
        code: "AMD",
        display: "Armenian Dram"
    },
    {
        code: "ANG",
        display: "Netherlands Antillean Guilder"
    },
    {
        code: "AOA",
        display: "Angolan Kwanza"
    },
    {
        code: "ARS",
        display: "Argentine Peso"
    },
    {
        code: "AUD",
        display: "Australian Dollar"
    },
    {
        code: "AWG",
        display: "Aruban Florin"
    },
    {
        code: "AZN",
        display: "Azerbaijani Manat"
    },
    {
        code: "BAM",
        display: "Bosnia-Herzegovina Convertible Mark"
    },
    {
        code: "BBD",
        display: "Barbadian Dollar"
    },
    {
        code: "BDT",
        display: "Bangladeshi Taka"
    },
    {
        code: "BGN",
        display: "Bulgarian Lev"
    },
    {
        code: "BHD",
        display: "Bahraini Dinar"
    },
    {
        code: "BIF",
        display: "Burundian Franc"
    },
    {
        code: "BMD",
        display: "Bermudan Dollar"
    },
    {
        code: "BND",
        display: "Brunei Dollar"
    },
    {
        code: "BOB",
        display: "Bolivian Boliviano"
    },
    {
        code: "BRL",
        display: "Brazilian Real"
    },
    {
        code: "BSD",
        display: "Bahamian Dollar"
    },
    {
        code: "BTC",
        display: "Bitcoin"
    },
    {
        code: "BTN",
        display: "Bhutanese Ngultrum"
    },
    {
        code: "BWP",
        display: "Botswanan Pula"
    },
    {
        code: "BYN",
        display: "Belarusian Ruble"
    },
    {
        code: "BZD",
        display: "Belize Dollar"
    },
    {
        code: "CAD",
        display: "Canadian Dollar"
    },
    {
        code: "CDF",
        display: "Congolese Franc"
    },
    {
        code: "CHF",
        display: "Swiss Franc"
    },
    {
        code: "CLF",
        display: "Chilean Unit of Account (UF)"
    },
    {
        code: "CLP",
        display: "Chilean Peso"
    },
    {
        code: "CNH",
        display: "Chinese Yuan (Offshore)"
    },
    {
        code: "CNY",
        display: "Chinese Yuan"
    },
    {
        code: "COP",
        display: "Colombian Peso"
    },
    {
        code: "CRC",
        display: "Costa Rican Colón"
    },
    {
        code: "CUC",
        display: "Cuban Convertible Peso"
    },
    {
        code: "CUP",
        display: "Cuban Peso"
    },
    {
        code: "CVE",
        display: "Cape Verdean Escudo"
    },
    {
        code: "CZK",
        display: "Czech Republic Koruna"
    },
    {
        code: "DJF",
        display: "Djiboutian Franc"
    },
    {
        code: "DKK",
        display: "Danish Krone"
    },
    {
        code: "DOP",
        display: "Dominican Peso"
    },
    {
        code: "DZD",
        display: "Algerian Dinar"
    },
    {
        code: "EGP",
        display: "Egyptian Pound"
    },
    {
        code: "ERN",
        display: "Eritrean Nakfa"
    },
    {
        code: "ETB",
        display: "Ethiopian Birr"
    },
    {
        code: "EUR",
        display: "Euro"
    },
    {
        code: "FJD",
        display: "Fijian Dollar"
    },
    {
        code: "FKP",
        display: "Falkland Islands Pound"
    },
    {
        code: "GBP",
        display: "British Pound Sterling"
    },
    {
        code: "GEL",
        display: "Georgian Lari"
    },
    {
        code: "GGP",
        display: "Guernsey Pound"
    },
    {
        code: "GHS",
        display: "Ghanaian Cedi"
    },
    {
        code: "GIP",
        display: "Gibraltar Pound"
    },
    {
        code: "GMD",
        display: "Gambian Dalasi"
    },
    {
        code: "GNF",
        display: "Guinean Franc"
    },
    {
        code: "GTQ",
        display: "Guatemalan Quetzal"
    },
    {
        code: "GYD",
        display: "Guyanaese Dollar"
    },
    {
        code: "HKD",
        display: "Hong Kong Dollar"
    },
    {
        code: "HNL",
        display: "Honduran Lempira"
    },
    {
        code: "HRK",
        display: "Croatian Kuna"
    },
    {
        code: "HTG",
        display: "Haitian Gourde"
    },
    {
        code: "HUF",
        display: "Hungarian Forint"
    },
    {
        code: "IDR",
        display: "Indonesian Rupiah"
    },
    {
        code: "ILS",
        display: "Israeli New Sheqel"
    },
    {
        code: "IMP",
        display: "Manx pound"
    },
    {
        code: "INR",
        display: "Indian Rupee"
    },
    {
        code: "IQD",
        display: "Iraqi Dinar"
    },
    {
        code: "IRR",
        display: "Iranian Rial"
    },
    {
        code: "ISK",
        display: "Icelandic Króna"
    },
    {
        code: "JEP",
        display: "Jersey Pound"
    },
    {
        code: "JMD",
        display: "Jamaican Dollar"
    },
    {
        code: "JOD",
        display: "Jordanian Dinar"
    },
    {
        code: "JPY",
        display: "Japanese Yen"
    },
    {
        code: "KES",
        display: "Kenyan Shilling"
    },
    {
        code: "KGS",
        display: "Kyrgystani Som"
    },
    {
        code: "KHR",
        display: "Cambodian Riel"
    },
    {
        code: "KMF",
        display: "Comorian Franc"
    },
    {
        code: "KPW",
        display: "North Korean Won"
    },
    {
        code: "KRW",
        display: "South Korean Won"
    },
    {
        code: "KWD",
        display: "Kuwaiti Dinar"
    },
    {
        code: "KYD",
        display: "Cayman Islands Dollar"
    },
    {
        code: "KZT",
        display: "Kazakhstani Tenge"
    },
    {
        code: "LAK",
        display: "Laotian Kip"
    },
    {
        code: "LBP",
        display: "Lebanese Pound"
    },
    {
        code: "LKR",
        display: "Sri Lankan Rupee"
    },
    {
        code: "LRD",
        display: "Liberian Dollar"
    },
    {
        code: "LSL",
        display: "Lesotho Loti"
    },
    {
        code: "LYD",
        display: "Libyan Dinar"
    },
    {
        code: "MAD",
        display: "Moroccan Dirham"
    },
    {
        code: "MDL",
        display: "Moldovan Leu"
    },
    {
        code: "MGA",
        display: "Malagasy Ariary"
    },
    {
        code: "MKD",
        display: "Macedonian Denar"
    },
    {
        code: "MMK",
        display: "Myanma Kyat"
    },
    {
        code: "MNT",
        display: "Mongolian Tugrik"
    },
    {
        code: "MOP",
        display: "Macanese Pataca"
    },
    {
        code: "MRO",
        display: "Mauritanian Ouguiya (pre-2018)"
    },
    {
        code: "MRU",
        display: "Mauritanian Ouguiya"
    },
    {
        code: "MUR",
        display: "Mauritian Rupee"
    },
    {
        code: "MVR",
        display: "Maldivian Rufiyaa"
    },
    {
        code: "MWK",
        display: "Malawian Kwacha"
    },
    {
        code: "MXN",
        display: "Mexican Peso"
    },
    {
        code: "MYR",
        display: "Malaysian Ringgit"
    },
    {
        code: "MZN",
        display: "Mozambican Metical"
    },
    {
        code: "NAD",
        display: "Namibian Dollar"
    },
    {
        code: "NGN",
        display: "Nigerian Naira"
    },
    {
        code: "NIO",
        display: "Nicaraguan Córdoba"
    },
    {
        code: "NOK",
        display: "Norwegian Krone"
    },
    {
        code: "NPR",
        display: "Nepalese Rupee"
    },
    {
        code: "NZD",
        display: "New Zealand Dollar"
    },
    {
        code: "OMR",
        display: "Omani Rial"
    },
    {
        code: "PAB",
        display: "Panamanian Balboa"
    },
    {
        code: "PEN",
        display: "Peruvian Nuevo Sol"
    },
    {
        code: "PGK",
        display: "Papua New Guinean Kina"
    },
    {
        code: "PHP",
        display: "Philippine Peso"
    },
    {
        code: "PKR",
        display: "Pakistani Rupee"
    },
    {
        code: "PLN",
        display: "Polish Zloty"
    },
    {
        code: "PYG",
        display: "Paraguayan Guarani"
    },
    {
        code: "QAR",
        display: "Qatari Rial"
    },
    {
        code: "RON",
        display: "Romanian Leu"
    },
    {
        code: "RSD",
        display: "Serbian Dinar"
    },
    {
        code: "RUB",
        display: "Russian Ruble"
    },
    {
        code: "RWF",
        display: "Rwandan Franc"
    },
    {
        code: "SAR",
        display: "Saudi Riyal"
    },
    {
        code: "SBD",
        display: "Solomon Islands Dollar"
    },
    {
        code: "SCR",
        display: "Seychellois Rupee"
    },
    {
        code: "SDG",
        display: "Sudanese Pound"
    },
    {
        code: "SEK",
        display: "Swedish Krona"
    },
    {
        code: "SGD",
        display: "Singapore Dollar"
    },
    {
        code: "SHP",
        display: "Saint Helena Pound"
    },
    {
        code: "SLL",
        display: "Sierra Leonean Leone"
    },
    {
        code: "SOS",
        display: "Somali Shilling"
    },
    {
        code: "SRD",
        display: "Surinamese Dollar"
    },
    {
        code: "SSP",
        display: "South Sudanese Pound"
    },
    {
        code: "STD",
        display: "São Tomé and Príncipe Dobra (pre-2018)"
    },
    {
        code: "STN",
        display: "São Tomé and Príncipe Dobra"
    },
    {
        code: "SVC",
        display: "Salvadoran Colón"
    },
    {
        code: "SYP",
        display: "Syrian Pound"
    },
    {
        code: "SZL",
        display: "Swazi Lilangeni"
    },
    {
        code: "THB",
        display: "Thai Baht"
    },
    {
        code: "TJS",
        display: "Tajikistani Somoni"
    },
    {
        code: "TMT",
        display: "Turkmenistani Manat"
    },
    {
        code: "TND",
        display: "Tunisian Dinar"
    },
    {
        code: "TOP",
        display: "Tongan Pa'anga"
    },
    {
        code: "TRY",
        display: "Turkish Lira"
    },
    {
        code: "TTD",
        display: "Trinidad and Tobago Dollar"
    },
    {
        code: "TWD",
        display: "New Taiwan Dollar"
    },
    {
        code: "TZS",
        display: "Tanzanian Shilling"
    },
    {
        code: "UAH",
        display: "Ukrainian Hryvnia"
    },
    {
        code: "UGX",
        display: "Ugandan Shilling"
    },
    {
        code: "USD",
        display: "United States Dollar"
    },
    {
        code: "UYU",
        display: "Uruguayan Peso"
    },
    {
        code: "UZS",
        display: "Uzbekistan Som"
    },
    {
        code: "VEF",
        display: "Venezuelan Bolívar Fuerte"
    },
    {
        code: "VND",
        display: "Vietnamese Dong"
    },
    {
        code: "VUV",
        display: "Vanuatu Vatu"
    },
    {
        code: "WST",
        display: "Samoan Tala"
    },
    {
        code: "XAF",
        display: "CFA Franc BEAC"
    },
    {
        code: "XAG",
        display: "Silver Ounce"
    },
    {
        code: "XAU",
        display: "Gold Ounce"
    },
    {
        code: "XCD",
        display: "East Caribbean Dollar"
    },
    {
        code: "XDR",
        display: "Special Drawing Rights"
    },
    {
        code: "XOF",
        display: "CFA Franc BCEAO"
    },
    {
        code: "XPD",
        display: "Palladium Ounce"
    },
    {
        code: "XPF",
        display: "CFP Franc"
    },
    {
        code: "XPT",
        display: "Platinum Ounce"
    },
    {
        code: "YER",
        display: "Yemeni Rial"
    },
    {
        code: "ZAR",
        display: "South African Rand"
    },
    {
        code: "ZMW",
        display: "Zambian Kwacha"
    },
    {
        code: "ZWL",
        display: "Zimbabwean Dollar"
    }
];
exports.regionNames = [
    {
        country: "Afghanistan",
        language: "Pashto",
        name: "ps-AF"
    },
    {
        country: "Afghanistan",
        language: "Dari",
        name: "prs-AF"
    },
    {
        country: "Albania",
        language: "Albanian",
        name: "sq-AL",
        iso3: "ALB"
    },
    {
        country: "Algeria",
        language: "Arabic",
        name: "ar-DZ",
        iso3: "DZA"
    },
    {
        country: "Argentina",
        language: "Spanish",
        name: "es-AR",
        iso3: "ARG"
    },
    {
        country: "Armenia",
        language: "Armenian",
        name: "hy-AM"
    },
    {
        country: "Australia",
        language: "English",
        name: "en-AU",
        iso3: "AUS"
    },
    {
        country: "Austria",
        language: "German",
        name: "de-AT",
        iso3: "AUT"
    },
    {
        country: "Bahrain",
        language: "Arabic",
        name: "ar-BH",
        iso3: "BHR"
    },
    {
        country: "Bangladesh",
        language: "Bengali",
        name: "bn-BD"
    },
    {
        country: "Barbados",
        language: "English",
        name: "en-BB",
        iso3: "BRB"
    },
    {
        country: "Basque",
        language: "Basque",
        name: "eu-ES"
    },
    {
        country: "Belarus",
        language: "Belarusian",
        name: "be-BY",
        iso3: "BLR"
    },
    {
        country: "Belgium",
        language: "French",
        name: "fr-BE",
        iso3: "BEL"
    },
    {
        country: "Belgium",
        language: "Dutch",
        name: "nl-BE",
        iso3: "BEL"
    },
    {
        country: "Belize",
        language: "English",
        name: "en-BZ",
        iso3: "BLZ"
    },
    {
        country: "Bolivarian Republic of Venezuela",
        language: "Spanish",
        name: "es-VE"
    },
    {
        country: "Bolivia",
        language: "Quechua",
        name: "quz-BO",
        iso3: "BOL"
    },
    {
        country: "Bolivia",
        language: "Spanish",
        name: "es-BO",
        iso3: "BOL"
    },
    {
        country: "Botswana",
        language: "English",
        name: "tn-BW",
        iso3: "BWA"
    },
    {
        country: "Brazil",
        language: "Portuguese",
        name: "pt-BR",
        iso3: "BRA"
    },
    {
        country: "Brunei Darussalam",
        language: "Malay",
        name: "ms-BN",
        iso3: "BRN"
    },
    {
        country: "Bulgaria",
        language: "Bulgarian",
        name: "bg-BG",
        iso3: "BGR"
    },
    {
        country: "Cambodia",
        language: "Khmer",
        name: "km-KH"
    },
    {
        country: "Canada",
        language: "French",
        name: "fr-CA",
        iso3: "CAN"
    },
    {
        country: "Canada",
        language: "English",
        name: "en-CA",
        iso3: "CAN"
    },
    {
        country: "Caribbean",
        language: "English",
        name: "en-029"
    },
    {
        country: "Catalan",
        language: "Catalan",
        name: "ca-ES"
    },
    {
        country: "Chile",
        language: "Mapudungun",
        name: "arn-CL",
        iso3: "CHL"
    },
    {
        country: "Chile",
        language: "Spanish",
        name: "es-CL",
        iso3: "CHL"
    },
    {
        country: "Colombia",
        language: "Spanish",
        name: "es-CO",
        iso3: "COL"
    },
    {
        country: "Costa Rica",
        language: "Spanish",
        name: "es-CR",
        iso3: "CRI"
    },
    {
        country: "Croatia",
        language: "Croatian",
        name: "hr-HR",
        iso3: "HRV"
    },
    {
        country: "Cyrillic, Azerbaijan",
        language: "Azeri",
        name: "az-Cyrl-AZ"
    },
    {
        country: "Cyrillic, Bosnia and Herzegovina",
        language: "Serbian",
        name: "sr-Cyrl-BA",
        iso3: "BIH"
    },
    {
        country: "Cyrillic, Bosnia and Herzegovina",
        language: "Bosnian",
        name: "bs-Cyrl-BA",
        iso3: "BIH"
    },
    {
        country: "Cyrillic, Mongolia",
        language: "Mongolian",
        name: "mn-MN"
    },
    {
        country: "Cyrillic, Montenegro",
        language: "Serbian",
        name: "sr-Cyrl-ME"
    },
    {
        country: "Cyrillic, Serbia",
        language: "Serbian",
        name: "sr-Cyrl-RS"
    },
    {
        country: "Cyrillic, Serbia and Montenegro (Former",
        language: "Serbian )",
        name: "sr-Cyrl-CS"
    },
    {
        country: "Cyrillic, Tajikistan",
        language: "Tajik",
        name: "tg-Cyrl-TJ"
    },
    {
        country: "Cyrillic, Uzbekistan",
        language: "Uzbek",
        name: "uz-Cyrl-UZ"
    },
    {
        country: "Czech Republic",
        language: "Czech",
        name: "cs-CZ",
        iso3: "CZE"
    },
    {
        country: "Denmark",
        language: "Danish",
        name: "da-DK",
        iso3: "DNK"
    },
    {
        country: "Dominican Republic",
        language: "Spanish",
        name: "es-DO",
        iso3: "DOM"
    },
    {
        country: "Ecuador",
        language: "Quechua",
        name: "quz-EC",
        iso3: "ECU"
    },
    {
        country: "Ecuador",
        language: "Spanish",
        name: "es-EC",
        iso3: "ECU"
    },
    {
        country: "Egypt",
        language: "Arabic",
        name: "ar-EG",
        iso3: "EGY"
    },
    {
        country: "El Salvador",
        language: "Spanish",
        name: "es-SV",
        iso3: "SLV"
    },
    {
        country: "Estonia",
        language: "Estonian",
        name: "et-EE",
        iso3: "EST"
    },
    {
        country: "Ethiopia",
        language: "Amharic",
        name: "am-ET"
    },
    {
        country: "Faroe Islands",
        language: "Faroese",
        name: "fo-FO"
    },
    {
        country: "Finland",
        language: "Finnish",
        name: "fi-FI",
        iso3: "FIN"
    },
    {
        country: "Finland",
        language: "Swedish",
        name: "sv-FI",
        iso3: "FIN"
    },
    {
        country: "Finland",
        language: "Sami, Northern",
        name: "se-FI",
        iso3: "FIN"
    },
    {
        country: "Finland",
        language: "Sami, Skolt",
        name: "sms-FI",
        iso3: "FIN"
    },
    {
        country: "Finland",
        language: "Sami, Inari",
        name: "smn-FI",
        iso3: "FIN"
    },
    {
        country: "Former Yugoslav Republic of Macedonia",
        language: "Macedonian",
        name: "mk-MK"
    },
    {
        country: "France",
        language: "French",
        name: "fr-FR",
        iso3: "FRA"
    },
    {
        country: "France",
        language: "Breton",
        name: "br-FR",
        iso3: "FRA"
    },
    {
        country: "France",
        language: "Occitan",
        name: "oc-FR",
        iso3: "FRA"
    },
    {
        country: "France",
        language: "Corsican",
        name: "co-FR",
        iso3: "FRA"
    },
    {
        country: "France",
        language: "Alsatian",
        name: "gsw-FR",
        iso3: "FRA"
    },
    {
        country: "Galician",
        language: "Galician",
        name: "gl-ES"
    },
    {
        country: "Georgia",
        language: "Georgian",
        name: "ka-GE",
        iso3: "GEO"
    },
    {
        country: "Germany",
        language: "German",
        name: "de-DE",
        iso3: "DEU"
    },
    {
        country: "Germany",
        language: "Upper Sorbian",
        name: "hsb-DE",
        iso3: "DEU"
    },
    {
        country: "Germany",
        language: "Lower Sorbian",
        name: "dsb-DE",
        iso3: "DEU"
    },
    {
        country: "Greece",
        language: "Greek",
        name: "el-GR",
        iso3: "GRC"
    },
    {
        country: "Greenland",
        language: "Greenlandic",
        name: "kl-GL"
    },
    {
        country: "Guatemala",
        language: "K'iche",
        name: "qut-GT",
        iso3: "GTM"
    },
    {
        country: "Guatemala",
        language: "Spanish",
        name: "es-GT",
        iso3: "GTM"
    },
    {
        country: "Honduras",
        language: "Spanish",
        name: "es-HN",
        iso3: "HND"
    },
    {
        country: "Hungary",
        language: "Hungarian",
        name: "hu-HU",
        iso3: "HUN"
    },
    {
        country: "Iceland",
        language: "Icelandic",
        name: "is-IS",
        iso3: "ISL"
    },
    {
        country: "India",
        language: "Hindi",
        name: "hi-IN",
        iso3: "IND"
    },
    {
        country: "India",
        language: "Bengali",
        name: "bn-IN",
        iso3: "IND"
    },
    {
        country: "India",
        language: "Punjabi",
        name: "pa-IN",
        iso3: "IND"
    },
    {
        country: "India",
        language: "Gujarati",
        name: "gu-IN",
        iso3: "IND"
    },
    {
        country: "India",
        language: "Oriya",
        name: "or-IN",
        iso3: "IND"
    },
    {
        country: "India",
        language: "Tamil",
        name: "ta-IN",
        iso3: "IND"
    },
    {
        country: "India",
        language: "Telugu",
        name: "te-IN",
        iso3: "IND"
    },
    {
        country: "India",
        language: "Kannada",
        name: "kn-IN",
        iso3: "IND"
    },
    {
        country: "India",
        language: "Malayalam",
        name: "ml-IN",
        iso3: "IND"
    },
    {
        country: "India",
        language: "Assamese",
        name: "as-IN",
        iso3: "IND"
    },
    {
        country: "India",
        language: "Marathi",
        name: "mr-IN",
        iso3: "IND"
    },
    {
        country: "India",
        language: "Sanskrit",
        name: "sa-IN",
        iso3: "IND"
    },
    {
        country: "India",
        language: "Konkani",
        name: "kok-IN",
        iso3: "IND"
    },
    {
        country: "India",
        language: "English",
        name: "en-IN",
        iso3: "IND"
    },
    {
        country: "Indonesia",
        language: "Indonesian",
        name: "id-ID",
        iso3: "IDN"
    },
    {
        country: "Iran",
        language: "Persian",
        name: "fa-IR"
    },
    {
        country: "Iraq",
        language: "Arabic",
        name: "ar-IQ",
        iso3: "IRQ"
    },
    {
        country: "Ireland",
        language: "Irish",
        name: "ga-IE",
        iso3: "IRL"
    },
    {
        country: "Ireland",
        language: "English",
        name: "en-IE",
        iso3: "IRL"
    },
    {
        country: "Islamic Republic of Pakistan",
        language: "Urdu",
        name: "ur-PK"
    },
    {
        country: "Israel",
        language: "Hebrew",
        name: "he-IL",
        iso3: "ISR"
    },
    {
        country: "Italy",
        language: "Italian",
        name: "it-IT",
        iso3: "ITA"
    },
    {
        country: "Jamaica",
        language: "English",
        name: "en-JM",
        iso3: "JAM"
    },
    {
        country: "Japan",
        language: "Japanese",
        name: "ja-JP",
        iso3: "JPN"
    },
    {
        country: "Jordan",
        language: "Arabic",
        name: "ar-JO",
        iso3: "JOR"
    },
    {
        country: "Kazakhstan",
        language: "Kazakh",
        name: "kk-KZ",
        iso3: "KAZ"
    },
    {
        country: "Kenya",
        language: "Kiswahili",
        name: "sw-KE",
        iso3: "KEN"
    },
    {
        country: "Korea",
        language: "Korean",
        name: "ko-KR"
    },
    {
        country: "Kuwait",
        language: "Arabic",
        name: "ar-KW",
        iso3: "KWT"
    },
    {
        country: "Kyrgyzstan",
        language: "Kyrgyz",
        name: "ky-KG"
    },
    {
        country: "Lao P.D.R.",
        language: "Lao",
        name: "lo-LA"
    },
    {
        country: "Latin, Algeria",
        language: "Tamazight",
        name: "tzm-Latn-DZ"
    },
    {
        country: "Latin, Azerbaijan",
        language: "Azeri",
        name: "az-Latn-AZ"
    },
    {
        country: "Latin, Bosnia and Herzegovina",
        language: "Croatian",
        name: "hr-BA"
    },
    {
        country: "Latin, Bosnia and Herzegovina",
        language: "Bosnian",
        name: "bs-Latn-BA"
    },
    {
        country: "Latin, Bosnia and Herzegovina",
        language: "Serbian",
        name: "sr-Latn-BA"
    },
    {
        country: "Latin, Canada",
        language: "Inuktitut",
        name: "iu-Latn-CA"
    },
    {
        country: "Latin, Montenegro",
        language: "Serbian",
        name: "sr-Latn-ME"
    },
    {
        country: "Latin, Nigeria",
        language: "Hausa",
        name: "ha-Latn-NG"
    },
    {
        country: "Latin, Serbia",
        language: "Serbian",
        name: "sr-Latn-RS"
    },
    {
        country: "Latin, Serbia and Montenegro (Former",
        language: "Serbian )",
        name: "sr-Latn-CS"
    },
    {
        country: "Latin, Uzbekistan",
        language: "Uzbek",
        name: "uz-Latn-UZ"
    },
    {
        country: "Latvia",
        language: "Latvian",
        name: "lv-LV",
        iso3: "LVA"
    },
    {
        country: "Lebanon",
        language: "Arabic",
        name: "ar-LB",
        iso3: "LBN"
    },
    {
        country: "Libya",
        language: "Arabic",
        name: "ar-LY"
    },
    {
        country: "Liechtenstein",
        language: "German",
        name: "de-LI",
        iso3: "LIE"
    },
    {
        country: "Lithuania",
        language: "Lithuanian",
        name: "lt-LT",
        iso3: "LTU"
    },
    {
        country: "Luxembourg",
        language: "Luxembourgish",
        name: "lb-LU",
        iso3: "LUX"
    },
    {
        country: "Luxembourg",
        language: "German",
        name: "de-LU",
        iso3: "LUX"
    },
    {
        country: "Luxembourg",
        language: "French",
        name: "fr-LU",
        iso3: "LUX"
    },
    {
        country: "Malaysia",
        language: "Malay",
        name: "ms-MY",
        iso3: "MYS"
    },
    {
        country: "Malaysia",
        language: "English",
        name: "en-MY",
        iso3: "MYS"
    },
    {
        country: "Maldives",
        language: "Divehi",
        name: "dv-MV"
    },
    {
        country: "Malta",
        language: "Maltese",
        name: "mt-MT",
        iso3: "MLT"
    },
    {
        country: "Mexico",
        language: "Spanish",
        name: "es-MX",
        iso3: "MEX"
    },
    {
        country: "Mohawk",
        language: "Mohawk",
        name: "moh-CA"
    },
    {
        country: "Monaco",
        language: "French",
        name: "fr-MC"
    },
    {
        country: "Morocco",
        language: "Arabic",
        name: "ar-MA",
        iso3: "MAR"
    },
    {
        country: "Mozambique",
        language: "Portuguese",
        name: "pt-MZ",
        iso3: "MOZ"
    },
    {
        country: "Nepal",
        language: "Nepali",
        name: "ne-NP"
    },
    {
        country: "Netherlands",
        language: "Dutch",
        name: "nl-NL",
        iso3: "NLD"
    },
    {
        country: "Netherlands",
        language: "Frisian",
        name: "fy-NL",
        iso3: "NLD"
    },
    {
        country: "New Zealand",
        language: "Maori",
        name: "mi-NZ",
        iso3: "NZL"
    },
    {
        country: "New Zealand",
        language: "English",
        name: "en-NZ",
        iso3: "NZL"
    },
    {
        country: "Nicaragua",
        language: "Spanish",
        name: "es-NI",
        iso3: "NIC"
    },
    {
        country: "Nigeria",
        language: "Yoruba",
        name: "yo-NG",
        iso3: "NGA"
    },
    {
        country: "Nigeria",
        language: "Igbo",
        name: "ig-NG",
        iso3: "NGA"
    },
    {
        country: "Norway",
        language: "Norwegian, Bokmål",
        name: "nb-NO",
        iso3: "NOR"
    },
    {
        country: "Norway",
        language: "Sami, Northern",
        name: "se-NO",
        iso3: "NOR"
    },
    {
        country: "Norway",
        language: "Norwegian, Nynorsk",
        name: "nn-NO",
        iso3: "NOR"
    },
    {
        country: "Norway",
        language: "Sami, Lule",
        name: "smj-NO",
        iso3: "NOR"
    },
    {
        country: "Norway",
        language: "Sami, Southern",
        name: "sma-NO",
        iso3: "NOR"
    },
    {
        country: "Oman",
        language: "Arabic",
        name: "ar-OM",
        iso3: "OMN"
    },
    {
        country: "Panama",
        language: "Spanish",
        name: "es-PA",
        iso3: "PAN"
    },
    {
        country: "Paraguay",
        language: "Spanish",
        name: "es-PY",
        iso3: "PRY"
    },
    {
        country: "Peru",
        language: "Quechua",
        name: "quz-PE",
        iso3: "PER"
    },
    {
        country: "Peru",
        language: "Spanish",
        name: "es-PE",
        iso3: "PER"
    },
    {
        country: "Philippines",
        language: "Filipino",
        name: "fil-PH",
        iso3: "PHL"
    },
    {
        country: "Poland",
        language: "Polish",
        name: "pl-PL",
        iso3: "POL"
    },
    {
        country: "Portugal",
        language: "Portuguese",
        name: "pt-PT",
        iso3: "PRT"
    },
    {
        country: "PRC",
        language: "Tibetan",
        name: "bo-CN"
    },
    {
        country: "PRC",
        language: "Yi",
        name: "ii-CN"
    },
    {
        country: "PRC",
        language: "Uyghur",
        name: "ug-CN"
    },
    {
        country: "Puerto Rico",
        language: "Spanish",
        name: "es-PR"
    },
    {
        country: "Qatar",
        language: "Arabic",
        name: "ar-QA",
        iso3: "QAT"
    },
    {
        country: "Republic of the Philippines",
        language: "English",
        name: "en-PH"
    },
    {
        country: "Romania",
        language: "Romanian",
        name: "ro-RO",
        iso3: "ROU"
    },
    {
        country: "Russia",
        language: "Russian",
        name: "ru-RU",
        iso3: "RUS"
    },
    {
        country: "Russia",
        language: "Tatar",
        name: "tt-RU",
        iso3: "RUS"
    },
    {
        country: "Russia",
        language: "Bashkir",
        name: "ba-RU",
        iso3: "RUS"
    },
    {
        country: "Russia",
        language: "Yakut",
        name: "sah-RU",
        iso3: "RUS"
    },
    {
        country: "Rwanda",
        language: "Kinyarwanda",
        name: "rw-RW",
        iso3: "RWA"
    },
    {
        country: "Saudi Arabia",
        language: "Arabic",
        name: "ar-SA",
        iso3: "SAU"
    },
    {
        country: "Senegal",
        language: "Wolof",
        name: "wo-SN",
        iso3: "SEN"
    },
    {
        country: "Simplified, PRC",
        language: "Chinese",
        name: "zh-CN"
    },
    {
        country: "Simplified, Singapore",
        language: "Chinese",
        name: "zh-SG"
    },
    {
        country: "Singapore",
        language: "English",
        name: "en-SG",
        iso3: "SGP"
    },
    {
        country: "Slovakia",
        language: "Slovak",
        name: "sk-SK",
        iso3: "SVK"
    },
    {
        country: "Slovenia",
        language: "Slovenian",
        name: "sl-SI",
        iso3: "SVN"
    },
    {
        country: "South Africa",
        language: "Setswana",
        name: "tn-ZA",
        iso3: "ZAF"
    },
    {
        country: "South Africa",
        language: "isiXhosa",
        name: "xh-ZA",
        iso3: "ZAF"
    },
    {
        country: "South Africa",
        language: "isiZulu",
        name: "zu-ZA",
        iso3: "ZAF"
    },
    {
        country: "South Africa",
        language: "Afrikaans",
        name: "af-ZA",
        iso3: "ZAF"
    },
    {
        country: "South Africa",
        language: "Sesotho sa Leboa",
        name: "nso-ZA",
        iso3: "ZAF"
    },
    {
        country: "South Africa",
        language: "English",
        name: "en-ZA",
        iso3: "ZAF"
    },
    {
        country: "Spain, International Sort",
        language: "Spanish",
        name: "es-ES"
    },
    {
        country: "Sri Lanka",
        language: "Sinhala",
        name: "si-LK"
    },
    {
        country: "Sweden",
        language: "Swedish",
        name: "sv-SE",
        iso3: "SWE"
    },
    {
        country: "Sweden",
        language: "Sami, Northern",
        name: "se-SE",
        iso3: "SWE"
    },
    {
        country: "Sweden",
        language: "Sami, Lule",
        name: "smj-SE",
        iso3: "SWE"
    },
    {
        country: "Sweden",
        language: "Sami, Southern",
        name: "sma-SE",
        iso3: "SWE"
    },
    {
        country: "Switzerland",
        language: "Romansh",
        name: "rm-CH",
        iso3: "CHE"
    },
    {
        country: "Switzerland",
        language: "German",
        name: "de-CH",
        iso3: "CHE"
    },
    {
        country: "Switzerland",
        language: "Italian",
        name: "it-CH",
        iso3: "CHE"
    },
    {
        country: "Switzerland",
        language: "French",
        name: "fr-CH",
        iso3: "CHE"
    },
    {
        country: "Syllabics, Canada",
        language: "Inuktitut",
        name: "iu-Cans-CA"
    },
    {
        country: "Syria",
        language: "Syriac",
        name: "syr-SY"
    },
    {
        country: "Syria",
        language: "Arabic",
        name: "ar-SY"
    },
    {
        country: "Thailand",
        language: "Thai",
        name: "th-TH",
        iso3: "THA"
    },
    {
        country: "Traditional Mongolian, PRC",
        language: "Mongolian",
        name: "mn-Mong-CN"
    },
    {
        country: "Traditional, Hong Kong S.A.R.",
        language: "Chinese",
        name: "zh-HK"
    },
    {
        country: "Traditional, Macao S.A.R.",
        language: "Chinese",
        name: "zh-MO"
    },
    {
        country: "Traditional, Taiwan",
        language: "Chinese",
        name: "zh-TW"
    },
    {
        country: "Trinidad and Tobago",
        language: "English",
        name: "en-TT",
        iso3: "TTO"
    },
    {
        country: "Tunisia",
        language: "Arabic",
        name: "ar-TN",
        iso3: "TUN"
    },
    {
        country: "Turkey",
        language: "Turkish",
        name: "tr-TR",
        iso3: "TUR"
    },
    {
        country: "Turkmenistan",
        language: "Turkmen",
        name: "tk-TM"
    },
    {
        country: "UAE",
        language: "Arabic",
        name: "ar-AE"
    },
    {
        country: "Ukraine",
        language: "Ukrainian",
        name: "uk-UA",
        iso3: "UKR"
    },
    {
        country: "United Kingdom",
        language: "Welsh",
        name: "cy-GB",
        iso3: "GBR"
    },
    {
        country: "United Kingdom",
        language: "Scottish Gaelic",
        name: "gd-GB",
        iso3: "GBR"
    },
    {
        country: "United Kingdom",
        language: "English",
        name: "en-GB",
        iso3: "GBR"
    },
    {
        country: "United States",
        language: "English",
        name: "en-US",
        iso3: "USA"
    },
    {
        country: "United States",
        language: "Spanish",
        name: "es-US",
        iso3: "USA"
    },
    {
        country: "Uruguay",
        language: "Spanish",
        name: "es-UY",
        iso3: "URY"
    },
    {
        country: "Vietnam",
        language: "Vietnamese",
        name: "vi-VN"
    },
    {
        country: "Yemen",
        language: "Arabic",
        name: "ar-YE",
        iso3: "YEM"
    },
    {
        country: "Zimbabwe",
        language: "English",
        name: "en-ZW",
        iso3: "ZWE"
    }
];
exports.usStates = [
    {
        name: "Alabama",
        abbreviation: "AL"
    },
    {
        name: "Alaska",
        abbreviation: "AK"
    },
    {
        name: "American Samoa",
        abbreviation: "AS"
    },
    {
        name: "Arizona",
        abbreviation: "AZ"
    },
    {
        name: "Arkansas",
        abbreviation: "AR"
    },
    {
        name: "California",
        abbreviation: "CA"
    },
    {
        name: "Colorado",
        abbreviation: "CO"
    },
    {
        name: "Connecticut",
        abbreviation: "CT"
    },
    {
        name: "Delaware",
        abbreviation: "DE"
    },
    {
        name: "District Of Columbia",
        abbreviation: "DC"
    },
    {
        name: "Federated States Of Micronesia",
        abbreviation: "FM"
    },
    {
        name: "Florida",
        abbreviation: "FL"
    },
    {
        name: "Georgia",
        abbreviation: "GA"
    },
    {
        name: "Guam Gu",
        abbreviation: "GU"
    },
    {
        name: "Hawaii",
        abbreviation: "HI"
    },
    {
        name: "Idaho",
        abbreviation: "ID"
    },
    {
        name: "Illinois",
        abbreviation: "IL"
    },
    {
        name: "Indiana",
        abbreviation: "IN"
    },
    {
        name: "Iowa",
        abbreviation: "IA"
    },
    {
        name: "Kansas",
        abbreviation: "KS"
    },
    {
        name: "Kentucky",
        abbreviation: "KY"
    },
    {
        name: "Louisiana",
        abbreviation: "LA"
    },
    {
        name: "Maine",
        abbreviation: "ME"
    },
    {
        name: "Marshall Islands",
        abbreviation: "MH"
    },
    {
        name: "Maryland",
        abbreviation: "MD"
    },
    {
        name: "Massachusetts",
        abbreviation: "MA"
    },
    {
        name: "Michigan",
        abbreviation: "MI"
    },
    {
        name: "Minnesota",
        abbreviation: "MN"
    },
    {
        name: "Mississippi",
        abbreviation: "MS"
    },
    {
        name: "Missouri",
        abbreviation: "MO"
    },
    {
        name: "Montana",
        abbreviation: "MT"
    },
    {
        name: "Nebraska",
        abbreviation: "NE"
    },
    {
        name: "Nevada",
        abbreviation: "NV"
    },
    {
        name: "New Hampshire",
        abbreviation: "NH"
    },
    {
        name: "New Jersey",
        abbreviation: "NJ"
    },
    {
        name: "New Mexico",
        abbreviation: "NM"
    },
    {
        name: "New York",
        abbreviation: "NY"
    },
    {
        name: "North Carolina",
        abbreviation: "NC"
    },
    {
        name: "North Dakota",
        abbreviation: "ND"
    },
    {
        name: "Northern Mariana Islands",
        abbreviation: "MP"
    },
    {
        name: "Ohio",
        abbreviation: "OH"
    },
    {
        name: "Oklahoma",
        abbreviation: "OK"
    },
    {
        name: "Oregon",
        abbreviation: "OR"
    },
    {
        name: "Palau",
        abbreviation: "PW"
    },
    {
        name: "Pennsylvania",
        abbreviation: "PA"
    },
    {
        name: "Puerto Rico",
        abbreviation: "PR"
    },
    {
        name: "Rhode Island",
        abbreviation: "RI"
    },
    {
        name: "South Carolina",
        abbreviation: "SC"
    },
    {
        name: "South Dakota",
        abbreviation: "SD"
    },
    {
        name: "Tennessee",
        abbreviation: "TN"
    },
    {
        name: "Texas",
        abbreviation: "TX"
    },
    {
        name: "Utah",
        abbreviation: "UT"
    },
    {
        name: "Vermont",
        abbreviation: "VT"
    },
    {
        name: "Virgin Islands",
        abbreviation: "VI"
    },
    {
        name: "Virginia",
        abbreviation: "VA"
    },
    {
        name: "Washington",
        abbreviation: "WA"
    },
    {
        name: "West Virginia",
        abbreviation: "WV"
    },
    {
        name: "Wisconsin",
        abbreviation: "WI"
    },
    {
        name: "Wyoming",
        abbreviation: "WY"
    }
];
