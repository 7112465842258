import { lazy } from "react";

import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";

const UnitsListScreen = lazy(() => import("screens/units/unitsListScreen"));
const UnitDetailsScreen = lazy(() => import("screens/units/unitDetails/unitDetails"));

const UnitsRouter = () => (
  <PageRouter>
    <LazyRoute path={Routes.UNITS_GLOBAL_NEW} render={UnitsListScreen} />
    <LazyRoute exact path={Routes.UNITS_CONFIRM} render={UnitsListScreen} />
    <LazyRoute path={Routes.UNIT_DETAILS_GLOBAL_NEW} render={UnitDetailsScreen} />

    <LazyRoute exact path={Routes.UNIT_DETAILS_VIEW_SIGNATURE_REQUEST} render={UnitDetailsScreen} />
    <LazyRoute exact path={Routes.UNIT_DETAILS_EDIT} render={UnitDetailsScreen} />

    <LazyRoute exact path={Routes.UNIT_DETAILS_EDIT_TENANT} render={UnitDetailsScreen} />
    <LazyRoute exact path={Routes.UNIT_DETAILS_UPDATE_RENTAL_APPLICATION_DECISION_STATUS} render={UnitDetailsScreen} />
    <LazyRoute exact path={Routes.UNIT_DETAILS_EDIT_RENTAL_APPLICATION} render={UnitDetailsScreen} />

    <LazyRoute exact path={Routes.UNIT_DETAILS_EDIT_OWNER_REQUEST} render={UnitDetailsScreen} />

    <LazyRoute exact path={Routes.UNIT_DETAILS_EDIT_INTERNAL_TASK} render={UnitDetailsScreen} />

    <LazyRoute exact path={Routes.UNIT_DETAILS_EDIT_TENANT_REQUEST} render={UnitDetailsScreen} />

    <LazyRoute exact path={Routes.UNIT_DETAILS_EDIT_WORK_ORDER} render={UnitDetailsScreen} />

    <LazyRoute exact path={Routes.EDIT_UNIT} render={UnitsListScreen} />
    <LazyRoute exact path={Routes.NEW_UNIT} render={UnitsListScreen} />
    <LazyRoute path={Routes.UNIT_DETAILS} render={UnitDetailsScreen} />

    <LazyRoute path={Routes.UNITS} render={UnitsListScreen} />
  </PageRouter>
);

export default UnitsRouter;
