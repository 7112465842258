import React from "react";
import Link from "DLUI/link/link";
import { View } from "DLUI/view";
import type { FillColors } from "DLUI/icon";
import { Icon } from "DLUI/icon";
import Text from "DLUI/text";
import type { SVGIconComponent } from "../../../assets";
import type { TextColor } from "DLUI/text/text";
import type { ViewComponentProps } from "DLUI/view/view";

interface ComponentProps extends Omit<ViewComponentProps, "OnClick"> {
  linkText: string;
  onClick: () => void;
  showTextUnderline?: boolean;
  linkColor?: TextColor;
  iconPathColor?: FillColors;
  linkFontSize?: number;
  linkIcon?: SVGIconComponent;
  iconSize?: number;
  dataCy?: string;
  marginTop?: number;
  isStopPropagation?: boolean;
}

const ActionIconLink: React.FC<ComponentProps> = ({
  showTextUnderline = true,
  linkColor = "lightBlue",
  iconPathColor = "light-blue",
  linkFontSize = 16,
  marginTop = 10,
  linkText,
  linkIcon,
  onClick,
  iconSize,
  dataCy,
  gap = 10,
  isStopPropagation,
  ...viewProps
}: ComponentProps) => (
  <Link onClick={onClick} data-cy={dataCy} isStopPropagation={isStopPropagation}>
    <View marginTop={marginTop} flexDirection={"row"} alignItems={"center"} gap={gap} {...viewProps}>
      {linkIcon && <Icon Source={linkIcon} size={iconSize} pathColor={iconPathColor} />}
      <Text underLine={showTextUnderline} fontSize={linkFontSize} color={linkColor} value={linkText} />
    </View>
  </Link>
);

export default ActionIconLink;
