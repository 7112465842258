import MomentUtils from "@date-io/moment";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import type { AccountDto, LeaseDto } from "@doorloop/dto";
import {
  AccountType,
  CheckPayableInfoDto,
  createValidator,
  DataCy,
  LeaseRefundDepositDto,
  LeaseRefundDepositLineDto,
  LeaseRefundDto,
  LeaseTransactionLineBaseDto,
  LinkedResourceType,
  mathUtils,
  PaymentMethod
} from "@doorloop/dto";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { filesApi } from "api/filesApi";
import { leaseRefundApi } from "api/leaseRefundApi";
import { leaseDepositsHeldByAccountApi } from "api/reportsApi";
import { tenantsApi } from "api/tenantsApi";
import { NoOutstandingTransactionIcon } from "assets/icons";
import clsx from "clsx";
import { DialogSearchPanel, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import { FormikDatePicker, FormikReferenceLabel, FormikSwitchButton, Select, TextField } from "DLUI/form";
import { Icon } from "DLUI/icon";
import { Notes } from "DLUI/notes";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, Field, FieldArray, Formik, getIn } from "formik";
import AppStrings from "locale/keys";
import _, { size } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import LeasesList from "screens/leases/leases/leasesList/leasesList";
import SelectableLeaseListItem from "screens/leases/leases/leasesList/selectableLeaseListItem";
import DialogFrame from "../components/dialogFrame";
import DialogAmountView from "../components/totalAmountView";
import LeaseSelection from "../transactions/leaseSelection";
import AccountBalanceLoader from "./accountBalanceLoader";
import DepositHeldLine from "./depositHeldLine";
import makeStyles from "./styles";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { usePaymentMethodBySubscription } from "../../../../hooks/usePaymentMethodBySubscription";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import type { RefundDepositSummaryProps } from "./refundDepositSummary";
import { RefundDepositSummary } from "./refundDepositSummary";

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
  dialogFrameWidth?: number;
  dialogFrameHeight?: number;
  transactionCreatedText: string;
  loadingTransactionDataText: string;
  loadingTransactionDataSuccessText: string;
  updatingTransactionText: string;
  transactionUpdatedText: string;
}

enum RefundDepositDialogView {
  LOADING_OR_FORM,
  RENDER_LEASE_SELECTION_LIST,
  UPDATE_LOADING,
  SUMMARY
}

const DIALOG_VIEWS_NUM = size(RefundDepositDialogView);
const DIALOG_WIDTH = 1000;
const SUMMARY_VIEW_WIDTH = 375;
const SUMMARY_VIEW_HEIGHT = 470;

let formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

const validateForm = createValidator(LeaseRefundDepositDto);

const RefundDepositDialog: React.FC<ComponentProps> = ({
  onBackdropClick,
  onClose,
  dialogTitle,
  transactionCreatedText,
  loadingTransactionDataText
}: ComponentProps) => {
  const paymentMethods = usePaymentMethodBySubscription();

  const classes = makeStyles();
  const { t } = useTranslation();
  const { leaseId } = useParams<any>();
  const [viewIndex, setViewIndex] = useState<RefundDepositDialogView>(RefundDepositDialogView.LOADING_OR_FORM);
  const [renderSelectionList, setRenderSelectionList] = useState<boolean>(false);
  const [currentLeaseId, setCurrentLeaseId] = useState<string | undefined>(leaseId);
  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);
  const [selectLeaseFilterObj, setSelectLeaseFilterObj] = useState({
    filter_text: ""
  });
  const [showLeaseSelectionError, setShowLeaseSelectionError] = useState<boolean>(false);
  const [leaseSelectionErrorText, setLeaseSelectionErrorText] = useState<string>("");
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");

  const [refundDepositData, setRefundDepositData] = useState<LeaseRefundDepositDto | undefined>();
  const [shouldrenderForm, setShouldRenderForm] = useState<boolean>(true);
  const [totalAmountHeld, setTotalAmountHeld] = useState<number>(0);
  const [shouldRenderTopSection, setShouldRenderTopSection] = useState<boolean>(true);
  const { isMobile } = useResponsiveHelper();
  const inputPaddingRight = isMobile ? 0 : 20;
  const [depositAccount, setDepositAccount] = useState<AccountDto>();
  const [showCheckNumberInput, setShowCheckNumberInput] = useState<boolean>(false);
  const [printCheckLater, setPrintCheckLater] = useState<boolean>(false);
  const [leaseRefundSummaryData, setLeaseRefundSummaryData] = useState<RefundDepositSummaryProps>();
  const [selectedLease, setSelectedLease] = useState<LeaseDto>();

  //TODO edit mode is missing

  useEffect(() => {
    formikGlobalRef?.setFieldValue("lease", currentLeaseId);
    setTimeout(() => {
      if (formikGlobalRef) {
        const errorText = getIn(formikGlobalRef.errors, "lease");
        const touchedVal = getIn(formikGlobalRef.touched, "lease");

        setShowLeaseSelectionError(touchedVal && errorText !== undefined);
        if (errorText !== undefined) {
          setLeaseSelectionErrorText(t(AppStrings.Leases.LeaseCharge.PressToSelectLease));
        }
        if (refundDepositData && currentLeaseId) {
          getDepositData();
        }
      }
    }, 0);
  }, [currentLeaseId]);

  useEffect(() => {
    getDepositData();
  }, []);

  useEffect(() => {
    if (formikGlobalRef && refundDepositData && refundDepositData.lines) {
      formikGlobalRef.setFieldValue("lines", refundDepositData.lines);
    }
  }, [refundDepositData]);

  const getDepositData = async () => {
    setLoadingDialogState(DialogState.Show);
    const response = await leaseDepositsHeldByAccountApi
      .getAll({
        filter_lease: currentLeaseId
      })
      .catch(() => {
        setLoadingDialogState(DialogState.Error);
      });
    if (response && response.data && response.data.data) {
      setLoadingDialogState(DialogState.Success);

      const leaseRefundDepositDto = new LeaseRefundDepositDto();
      leaseRefundDepositDto.populateLines(response.data.data);
      let _totalAmountHeld = 0;
      if (leaseRefundDepositDto.lines) {
        _.forEach(leaseRefundDepositDto.lines, (currentLine: LeaseRefundDepositLineDto) => {
          _totalAmountHeld = mathUtils.add(_totalAmountHeld, currentLine.amountHeld || 0);
        });
      }
      setTotalAmountHeld(_totalAmountHeld);
      setShouldRenderForm(true);
      setLoadingDialogState(DialogState.Hidden);
      setRefundDepositData(leaseRefundDepositDto);
    }
  };

  const initFormvalues = (): LeaseRefundDepositDto => {
    const leaseWitholdDepositDto = new LeaseRefundDepositDto();
    leaseWitholdDepositDto.lease = currentLeaseId;
    leaseWitholdDepositDto.lines = [new LeaseRefundDepositLineDto()];
    leaseWitholdDepositDto.date = moment().format("YYYY-MM-DD").toString();
    leaseWitholdDepositDto.checkInfo = new CheckPayableInfoDto();
    return leaseWitholdDepositDto;
  };

  const isValidForm = async (formikRef: FormikProps<LeaseRefundDepositDto>) => {
    formikRef.setFieldTouched("date");
    formikRef.setFieldTouched("payFromAccount");
    formikRef.setFieldTouched("paymentMethod");
    formikRef.setFieldTouched("payToTenant");
    formikRef.setFieldTouched("checkInfo.checkNumber");

    if (formikRef.values && formikRef.values.lines) {
      formikRef.values.lines.forEach((arrayItem: any, arrayIndex: number) => {
        formikRef.setFieldTouched(`lines[${arrayIndex}].amountToWithhold`);
      });
    }

    const errors = (await formikRef.validateForm()) as any;
    const inValidLease = Boolean(errors.lease);
    setShowLeaseSelectionError(inValidLease);
    if (inValidLease && formikGlobalRef) {
      const errorText = getIn(formikGlobalRef.errors, "lease");
      if (errorText !== undefined) {
        setLeaseSelectionErrorText(t(AppStrings.Leases.LeaseCharge.PressToSelectLease));
      }
    }
    return _.isEmpty(errors);
  };

  const getLeaseRefundDto = (refundValues: LeaseRefundDepositDto): LeaseRefundDto => {
    const leaseDto = new LeaseRefundDto({
      lease: refundValues.lease,
      memo: refundValues.memo,
      date: refundValues.date,
      reference: refundValues.reference,
      payFromAccount: refundValues.payFromAccount,
      payToTenant: refundValues.payToTenant,
      paymentMethod: refundValues.paymentMethod,
      checkInfo: refundValues.checkInfo,
      ePayInfo: refundValues.ePayInfo,
      lines: []
    });

    _.forEach(refundValues.lines, (line) => {
      if (leaseDto.lines) {
        leaseDto.lines.push(
          new LeaseTransactionLineBaseDto({
            account: line.account,
            amount: line.amountToWithhold,
            memo: line.memo
          })
        );
      }
    });

    return leaseDto;
  };

  const refundDeposit = async () => {
    if (formikGlobalRef) {
      const refundValues = _.cloneDeep(formikGlobalRef.values) as LeaseRefundDepositDto;
      const valuesToSend = getLeaseRefundDto(refundValues);

      const depositsRefunded = getTotalAmount();

      setViewIndex(RefundDepositDialogView.UPDATE_LOADING);
      setLoadingDialogState(DialogState.Show);
      const results = await leaseRefundApi.create(valuesToSend, { idKey: "lease" }).catch((error) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });

      if (results && results.status && results.data) {
        await filesApi
          .uploadFiles(attachments, results.data.id!, LinkedResourceType.LeaseRefund)
          .catch((error: string) => {
            setLoadingDialogState(DialogState.Error);
            setLoadingDialogErrorText(error);
          });
        setLoadingDialogState(DialogState.Success);

        moveToSummaryView(results.data, depositsRefunded);
      } else {
        setLoadingDialogErrorText(results ? results.message : "");
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const moveToSummaryView = (leaseRefund: LeaseRefundDto, depositsRefunded?: number) => {
    setLeaseRefundSummaryData({
      leaseOutstandingBalance: selectedLease?.outstandingBalance,
      depositsRefunded,
      remainingDeposits: mathUtils.substract(totalAmountHeld, depositsRefunded),
      accountPrintChecks: leaseRefund.paymentMethod === PaymentMethod.CHECK ? leaseRefund.payFromAccount : undefined,
      paymentMethod: leaseRefund.paymentMethod,
      reference: leaseRefund.reference
    });

    setViewIndex(RefundDepositDialogView.SUMMARY);
  };

  const didPressSaveButton = async () => {
    if (formikGlobalRef !== null) {
      const isValid = await isValidForm(formikGlobalRef);
      if (isValid) {
        refundDeposit();
      }
    }
  };

  const getTotalAmount = () => {
    let amount = 0;

    if (formikGlobalRef && formikGlobalRef.values) {
      formikGlobalRef.values.lines.forEach((currentLine: LeaseRefundDepositLineDto) => {
        if (currentLine.amountToWithhold && currentLine.amountToWithhold > 0) {
          amount = mathUtils.add(amount, currentLine.amountToWithhold);
        }
      });
    }
    return amount;
  };

  const renderEmptyDataView = () => (
    <View justifyContent={"center"} alignItems={"center"}>
      <Icon Source={NoOutstandingTransactionIcon} />
      <Text
        marginTop={20}
        fontSize={24}
        fontWeight={700}
        color={"black"}
        value={
          currentLeaseId
            ? AppStrings.Leases.LeaseTransaction.Deposit.NoDepositHeld
            : AppStrings.Leases.LeaseCharge.SelectLease
        }
      />
    </View>
  );

  const renderLines = () => (
    <FieldArray
      name={"lines"}
      render={() => {
        if (formikGlobalRef && formikGlobalRef.values) {
          const formLines = formikGlobalRef.values.lines;

          const lines = formLines.map((currentLine: LeaseRefundDepositLineDto, lineIndex: number) => (
            <div style={{ width: "100%" }} key={"LTL" + lineIndex}>
              <DepositHeldLine
                accountName={currentLine.accountName!}
                amountHeld={currentLine.amountHeld!}
                lineIndex={lineIndex}
              />
            </div>
          ));

          const depositsWithheldLines = getIn(formikGlobalRef.values, "lines");
          let totalDepositsHeld = 0;
          let totalDepositsRefunded = 0;
          if (depositsWithheldLines) {
            _.forEach(depositsWithheldLines, (currentItem: LeaseRefundDepositLineDto) => {
              totalDepositsHeld = mathUtils.add(totalDepositsHeld, currentItem.amountHeld || 0);
              totalDepositsRefunded = mathUtils.add(totalDepositsRefunded, currentItem.amountToWithhold || 0);
            });
          }

          if (lines.length === 0) {
            return renderEmptyDataView();
          }

          return (
            <View>
              <View
                justifyContent={"center"}
                marginTop={20}
                className={classes.depositHeldContainer}
                paddingBottom={20}
                borderRadius={10}
              >
                {lines}
              </View>
              <View marginTop={20} alignItems={"flex-end"} flexDirection={"row"} paddingLeft={100}>
                <Grid item lg={6} md={6} xs={6} />
                <Grid item container direction={"column"} justify={"center"} alignItems={"center"} lg={6} md={6} xs={6}>
                  <View flexDirection={"row"}>
                    <Text
                      color={"black"}
                      fontSize={16}
                      value={AppStrings.Leases.LeaseDetails.DepositsHeld}
                      fullWidth
                      paddingLeft={80}
                    />

                    <Text
                      color={"black"}
                      fontSize={16}
                      paddingRight={20}
                      formatType={"currency"}
                      dataCy={DataCy.leaseDetails.deposits.refundDeposit.depositsHeld}
                      value={String(totalDepositsHeld)}
                    />
                  </View>
                  <View marginTop={20} flexDirection={"row"}>
                    <Text
                      color={"lightBlue"}
                      fontSize={16}
                      value={AppStrings.Leases.LeaseTransaction.Deposit.DepositsRefunded}
                      fullWidth
                      paddingLeft={80}
                    />

                    <Text
                      color={"lightBlue"}
                      fontSize={16}
                      paddingRight={20}
                      formatType={"currency"}
                      value={String(totalDepositsRefunded)}
                    />
                  </View>
                </Grid>
              </View>
            </View>
          );
        }
        return <div />;
      }}
    />
  );

  const renderSummaryLine = () => {
    if (formikGlobalRef) {
      const lines = getIn(formikGlobalRef.values, "lines");
      if (!lines || lines.length === 0) {
        return null;
      }
      const totalAmount = getTotalAmount();

      return (
        <View marginTop={20} flexDirection={"row"}>
          <DialogAmountView
            amount={mathUtils.substract(totalAmountHeld, totalAmount)}
            title={AppStrings.Leases.LeaseTransaction.Deposit.RemainingDeposits}
          />
        </View>
      );
    }
  };

  const onFileReceived = (files: FileListItemProps[]) => {
    setAttachments(files);
  };

  const renderAttachments = () => (
    <View justifyContent={"flex-end"} width={"100%"} flex={1} marginBottom={20}>
      <FormAttachments onFileReceived={onFileReceived} files={attachments} />
    </View>
  );

  const didPressSelectLease = () => {
    setViewIndex(RefundDepositDialogView.RENDER_LEASE_SELECTION_LIST);
    setTimeout(() => {
      setRenderSelectionList(true);
    }, 500);
  };

  const didPressRemoveLease = () => {
    setCurrentLeaseId(undefined);
    if (formikGlobalRef) {
      setTotalAmountHeld(0);
      formikGlobalRef.setFieldValue("lines", []);
    }
  };

  const onLeaseSelection = (lease: LeaseDto) => {
    setSelectedLease(lease);
  };

  const didSelectDepositAccount = (event: object, value: any) => {
    if (value) {
      setDepositAccount(value);
    } else {
      setDepositAccount(undefined);
    }
  };

  const didChangePaymentMethod = (nextValue: string) => {
    if (nextValue === PaymentMethod.CHECK) {
      setShowCheckNumberInput(true);
    } else {
      setShowCheckNumberInput(false);
      formikGlobalRef?.setFieldValue("checkInfo", undefined);
    }
  };

  const didChangePrintCheckLaterSwitch = (nextSwitchState: boolean) => {
    setPrintCheckLater(nextSwitchState);
  };

  const renderFormTopSection = () => {
    if (!shouldRenderTopSection) {
      return null;
    }

    return (
      <View>
        <View marginTop={20} flexDirection={"row"} noWrap={!isMobile} gap={20}>
          <Grid item xs={12} md={6}>
            <LeaseSelection
              didPressSelectLease={didPressSelectLease}
              leaseId={currentLeaseId}
              errorTex={showLeaseSelectionError ? leaseSelectionErrorText : undefined}
              onLeaseSelection={onLeaseSelection}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FastField
              component={FormikDatePicker}
              uniqueKey={"chargeDueDate"}
              label={AppStrings.Leases.NewLease.LeaseRent.DueDate}
              name={"date"}
              noMargin
              required
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FastField component={FormikReferenceLabel} name={"reference"} backgroundColor={"dark"} marginTop={20} />
          </Grid>
        </View>
        <SeparationLine marginTop={20} width={"100%"} height={1} />
        <View shouldShow={currentLeaseId !== undefined} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
          <View flexDirection={"row"}>
            <Grid item xs={12} md={4} lg={4}>
              <FormikCachedAsyncAutoComplete
                uniqueIndex={"TS"}
                apiHandler={tenantsApi}
                displayNameKey={"name"}
                filterFieldName={"filter_text"}
                filterFieldValue={"name"}
                selectionFields={["id", "class"]}
                name={"payToTenant"}
                queryParams={{ filter_lease: currentLeaseId }}
                label={t(AppStrings.Leases.LeaseTransaction.Refund.PayToTenant)}
                required
                marginTop={20}
                paddingRight={inputPaddingRight}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <BankAccountFormikAutoCompleteField
                addCreateOption
                uniqueIndex={"TS"}
                name={"payFromAccount"}
                queryParams={{
                  filter_type: AccountType.ASSET_BANK,
                  filter_active: true
                }}
                selectionFields={["outgoingEPayEnabled"]}
                label={t(AppStrings.Leases.LeaseTransaction.Refund.PayFromAccount)}
                onChange={didSelectDepositAccount}
                marginTop={20}
                paddingRight={inputPaddingRight}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <AccountBalanceLoader
                propertyId={selectedLease?.property}
                depositAccountId={depositAccount?.id}
                marginTop={20}
              />
            </Grid>
          </View>
          <View flexDirection={"row"}>
            <Grid item xs={12} md={4} lg={4}>
              <Field
                component={Select}
                name={`paymentMethod`}
                label={AppStrings.Leases.LeaseTransaction.Payment.PaymentMethod}
                required
                uniqueKey={"paymentMethod"}
                selectionEnum={paymentMethods}
                onChange={didChangePaymentMethod}
                translationKey={"paymentMethod"}
                marginTop={20}
                paddingRight={inputPaddingRight}
                enableEPay={Boolean(depositAccount?.outgoingEPayEnabled)}
              />
            </Grid>

            {showCheckNumberInput && !printCheckLater ? (
              <Grid item xs={12} md={4} lg={4}>
                <FastField
                  component={TextField}
                  label={t(AppStrings.Leases.LeaseTransaction.Payment.CheckNumber)}
                  name={"checkInfo.checkNumber"}
                  marginTop={20}
                  paddingRight={inputPaddingRight}
                />
              </Grid>
            ) : null}

            {showCheckNumberInput ? (
              <Grid
                alignItems={"center"}
                container
                className={clsx([classes.inputMargin, classes.inputHeight])}
                item
                xs={12}
                md={4}
                lg={4}
              >
                <FastField
                  component={FormikSwitchButton}
                  name={"checkInfo.printLater"}
                  label={AppStrings.Leases.LeaseTransaction.Refund.AddToPrintQueue}
                  onChange={didChangePrintCheckLaterSwitch}
                  marginTop={10}
                  paddingRight={inputPaddingRight}
                />
              </Grid>
            ) : null}
          </View>
        </View>
      </View>
    );
  };

  const renderItemsHeader = () => {
    if (isMobile) {
      return null;
    }
    if (formikGlobalRef) {
      const lines = getIn(formikGlobalRef.values, "lines");
      if (!lines || lines.length === 0) {
        return null;
      }

      return (
        <View marginTop={20}>
          <Grid container>
            <Grid item xs={12} md={3} lg={3}>
              <Text value={AppStrings.Leases.LeaseTransaction.Payment.DepositAccount} color={"black"} fontSize={16} />
            </Grid>
            <Grid item xs={10} md={2} lg={2}>
              <Text value={AppStrings.Leases.LeaseTransaction.Deposit.AmountHeld} color={"black"} fontSize={16} />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Text value={AppStrings.Leases.LeaseCharge.Description} color={"black"} fontSize={16} />
            </Grid>
            <Grid item xs={2} md={3} lg={3}>
              <Text value={AppStrings.Leases.LeaseTransaction.Deposit.AmountToRefund} color={"black"} fontSize={16} />
            </Grid>
          </Grid>
        </View>
      );
    }
  };

  const renderNotes = () => (
    <View justifyContent={"center"} height={50}>
      <FastField component={Notes} height={30} name={"memo"} />
    </View>
  );

  const renderForm = () => {
    const formInitialValues = initFormvalues();
    return (
      <Formik initialValues={formInitialValues} onSubmit={() => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;

          return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <View
                flex={1}
                width={"100%"}
                paddingLeft={isMobile ? 20 : 40}
                paddingRight={isMobile ? 20 : 40}
                flexDirection={"column"}
              >
                {renderFormTopSection()}
                <SeparationLine marginTop={20} width={"100%"} height={1} />
                {renderItemsHeader()}
                {renderLines()}
                {renderNotes()}
                {renderSummaryLine()}
                <View height={20} />
                {renderAttachments()}
              </View>
            </MuiPickersUtilsProvider>
          );
        }}
      </Formik>
    );
  };

  const renderLeaseSelectionList = () => {
    const didChangeSearchQuery = (value: string) => {
      setSelectLeaseFilterObj({
        filter_text: value
      });
    };

    const onListItemPress = (leaseId: string) => {
      setViewIndex(RefundDepositDialogView.LOADING_OR_FORM);
      setCurrentLeaseId(leaseId);
    };

    return (
      <View>
        <DialogSearchPanel onChange={didChangeSearchQuery} />
        <View paddingLeft={40} paddingRight={20}>
          {renderSelectionList ? (
            <div id={"selectableLeaseListItem"} style={{ width: "100%" }}>
              <LeasesList
                ListItem={SelectableLeaseListItem}
                filterObj={selectLeaseFilterObj}
                didPressListItem={onListItemPress}
                stickyHeaderId={"selectableLeaseListItem"}
                selectableItem
              />
            </div>
          ) : null}
        </View>
      </View>
    );
  };

  const renderView = ({ index }: any) => {
    const onRetryButtonPress = async () => {
      await didPressSaveButton();

      // refundDeposit();
    };
    const didPressDismissButton = () => {
      setViewIndex(RefundDepositDialogView.LOADING_OR_FORM);
    };

    if (index === RefundDepositDialogView.LOADING_OR_FORM) {
      if (loadingDialogState !== DialogState.Hidden) {
        return (
          <View height={"100%"}>
            <LoadingDialog
              dialogState={loadingDialogState}
              loadingText={loadingTransactionDataText}
              errorText={loadingDialogErrorText}
              successText={transactionCreatedText}
              onRetryButtonPress={getDepositData}
              didPressDismissButton={didPressDismissButton}
            />
          </View>
        );
      }
      if (shouldrenderForm) {
        return renderForm();
      }
      return <div />;
    }
    if (index === RefundDepositDialogView.RENDER_LEASE_SELECTION_LIST) {
      return renderLeaseSelectionList();
    }
    if (index === RefundDepositDialogView.UPDATE_LOADING) {
      return (
        <View alignItems={"center"} justifyContent={"center"} height={"100%"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingTransactionDataText}
            errorText={loadingDialogErrorText}
            successText={transactionCreatedText}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
          />
        </View>
      );
    }

    if (viewIndex === RefundDepositDialogView.SUMMARY && leaseRefundSummaryData) {
      return <RefundDepositSummary {...leaseRefundSummaryData} />;
    }
    return <div />;
  };

  const _onBackdropClick = () => {
    if (viewIndex === RefundDepositDialogView.RENDER_LEASE_SELECTION_LIST) {
      setViewIndex(RefundDepositDialogView.LOADING_OR_FORM);
      return;
    }

    if (viewIndex === RefundDepositDialogView.SUMMARY) {
      onClose();
      return;
    }

    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const renderActionPanelButtons = () => (
    <FormActionButtons
      propsSubButton={{ onClick: _onBackdropClick }}
      propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
    />
  );

  const frameType = useMemo(() => {
    if (viewIndex === RefundDepositDialogView.LOADING_OR_FORM) {
      if (loadingDialogState !== DialogState.Hidden) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }
    if (viewIndex === RefundDepositDialogView.UPDATE_LOADING) {
      return "contentOnly";
    }
    return "topPanel";
  }, [viewIndex, loadingDialogState]);

  const currentTitle = useMemo(() => {
    if (viewIndex === RefundDepositDialogView.LOADING_OR_FORM) {
      return dialogTitle;
    }

    if (viewIndex === RefundDepositDialogView.RENDER_LEASE_SELECTION_LIST) {
      return AppStrings.Leases.LeaseCharge.SelectLease;
    }
    return "";
  }, [viewIndex]);

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={currentTitle}
      width={
        viewIndex === RefundDepositDialogView.SUMMARY
          ? getDialogFrameDimension("width", SUMMARY_VIEW_WIDTH)
          : DIALOG_WIDTH
      }
      height={
        viewIndex === RefundDepositDialogView.SUMMARY
          ? getDialogFrameDimension("height", SUMMARY_VIEW_HEIGHT)
          : window.innerHeight
      }
      renderView={renderView}
      numViews={DIALOG_VIEWS_NUM}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={frameType}
    />
  );
};

export default RefundDepositDialog;
