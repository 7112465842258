import AppStrings from "../../../../locale/keys";
import { MainMenuLayersKeys } from "DLUI/drawer/items/mainMenuItems";
import Routes from "../../../appRouter/routes";
import { DataCy, ObjectPermission, TaskViewObjectAccess } from "@doorloop/dto";
import { v4 as uuid } from "uuid";
import type { NavConfigProps, NavGroupProps } from "DLUI/drawer/types";
import { DrawerItemName, NavLevels } from "DLUI/drawer/types";
import { IndicationTag } from "DLUI/drawer/types";
import { CommunicationCenterInboxMenuItemLabel } from "screens/communicationsCenter/components/communicationCenterInboxMenuItemLabel";
import { CommunicationCenterUnassignedMenuItemLabel } from "screens/communicationsCenter/components/communicationCenterUnassignedMenuItemLabel";
import _ from "lodash";

const signatureMenuItems: NavGroupProps = {
  groupName: AppStrings.DrawerItems.Signatures,
  parentNavigationKey: MainMenuLayersKeys.Communications,
  items: [
    {
      label: AppStrings.Esignatures.SignatureRequests,
      navKey: uuid(),
      navPath: [
        { name: Routes.SIGNATURE_REQUESTS, matchMethod: "anyMatch" },
        { name: Routes.NEW_SIGNATURE_REQUESTS, matchMethod: "anyMatch" }
      ],
      dataCy: DataCy.sideMenu.communications.signatureRequests,
      clearance: [{ permission: ObjectPermission.esignatureRequests, field: "viewList" }]
    },
    {
      excludedDevices: ["tabletOrMobile"],
      label: AppStrings.Esignatures.SignatureTemplates,
      navKey: uuid(),
      navPath: [{ name: Routes.SIGNATURE_TEMPLATES, matchMethod: "anyMatch" }],
      dataCy: DataCy.sideMenu.communications.signatureTemplates,
      clearance: [
        {
          permission: ObjectPermission.esignatureTemplates,
          field: "viewList"
        }
      ]
    }
  ]
};

const announcementsMenuItems: NavGroupProps = {
  groupName: AppStrings.Announcements.announcements,
  parentNavigationKey: MainMenuLayersKeys.Communications,
  items: [
    {
      label: AppStrings.Announcements.announcements,
      navKey: uuid(),
      navPath: [{ name: Routes.ANNOUNCEMENTS, matchMethod: "anyMatch" }],
      dataCy: DataCy.sideMenu.communications.announcements,
      clearance: [{ permission: ObjectPermission.announcements, field: "viewList" }]
    },
    {
      label: AppStrings.Announcements.CommunicationsLog,
      navKey: uuid(),
      navPath: [{ name: Routes.COMMUNICATIONS, matchMethod: "exact" }],
      dataCy: DataCy.sideMenu.communications.communicationsLog,
      clearance: [{ permission: ObjectPermission.communications, field: "viewList" }]
    }
  ]
};

const messagingMenuItems: NavGroupProps = {
  groupName: AppStrings.DrawerItems.Messaging,
  parentNavigationKey: MainMenuLayersKeys.Communications,
  featureFlag: "CommunicationsCenter",
  items: [
    {
      label: AppStrings.CommunicationsCenter.Screen.MyInboxTitle,
      labelComponent: CommunicationCenterInboxMenuItemLabel,
      navKey: uuid(),
      navPath: [{ name: Routes.COMMUNICATIONS_CENTER_INBOX, matchMethod: "anyMatch" }],
      dataCy: DataCy.sideMenu.communications.inbox,
      tag: IndicationTag.NEW,
      clearance: [
        {
          permission: ObjectPermission.conversations,
          field: "viewList",
          requiredLevel: TaskViewObjectAccess.ASSIGNED_ONLY
        },
        {
          permission: ObjectPermission.conversations,
          field: "viewList",
          requiredLevel: TaskViewObjectAccess.ALL
        }
      ],
      allClearance: [
        {
          permission: ObjectPermission.phoneNumbers,
          field: "viewOne"
        }
      ]
    },
    {
      label: AppStrings.CommunicationsCenter.Screen.AllMessagesTitle,
      navKey: uuid(),
      navPath: [{ name: Routes.COMMUNICATIONS_CENTER_ALL_MESSAGES, matchMethod: "anyMatch" }],
      dataCy: DataCy.sideMenu.communications.allMessages,
      tag: IndicationTag.NEW,
      clearance: [
        {
          permission: ObjectPermission.conversations,
          field: "viewList",
          requiredLevel: TaskViewObjectAccess.ALL
        }
      ],
      allClearance: [
        {
          permission: ObjectPermission.phoneNumbers,
          field: "viewOne"
        }
      ],
      customClearanceHandler: (loginResponse) =>
        !(!_.isEmpty(loginResponse?.properties) || !_.isEmpty(loginResponse?.propertyGroups))
    },
    {
      label: AppStrings.CommunicationsCenter.Screen.UnAssignedTitle,
      labelComponent: CommunicationCenterUnassignedMenuItemLabel,
      navKey: uuid(),
      navPath: [{ name: Routes.COMMUNICATIONS_CENTER_UNASSIGNED_MESSAGES, matchMethod: "anyMatch" }],
      dataCy: DataCy.sideMenu.communications.unassignedMessages,
      tag: IndicationTag.NEW,
      clearance: [
        {
          permission: ObjectPermission.conversations,
          field: "viewList",
          requiredLevel: TaskViewObjectAccess.ALL
        }
      ],
      allClearance: [
        {
          permission: ObjectPermission.phoneNumbers,
          field: "viewOne"
        }
      ]
    }
  ]
};
export const SubMenuCommunicationsLayers: NavConfigProps = {
  title: AppStrings.Announcements.communications,
  level: NavLevels.SUB_MENU,
  subMenuLayerKey: DrawerItemName.Communications,
  groups: [messagingMenuItems, announcementsMenuItems, signatureMenuItems]
};
