"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommunicationParticipantType = void 0;
var CommunicationParticipantType;
(function (CommunicationParticipantType) {
    CommunicationParticipantType["FROM"] = "FROM";
    CommunicationParticipantType["TO"] = "TO";
    CommunicationParticipantType["CC"] = "CC";
    CommunicationParticipantType["BCC"] = "BCC";
})(CommunicationParticipantType || (exports.CommunicationParticipantType = CommunicationParticipantType = {}));
