"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityFeedDto = void 0;
const base_dto_1 = require("../base.dto");
const linkedResource_dto_1 = require("../common/linkedResource.dto");
const activityFeedCreatedByType_enum_1 = require("./activityFeedCreatedByType.enum");
const activityFeedModifiedField_dto_1 = require("./activityFeedModifiedField.dto");
const activityFeedType_enum_1 = require("./activityFeedType.enum");
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
class ActivityFeedDto extends base_dto_1.BaseDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.ActivityFeedDto = ActivityFeedDto;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(activityFeedCreatedByType_enum_1.ActivityFeedCreatedByType),
    __metadata("design:type", String)
], ActivityFeedDto.prototype, "personType", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.personType !== activityFeedCreatedByType_enum_1.ActivityFeedCreatedByType.SYSTEM),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], ActivityFeedDto.prototype, "personId", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(activityFeedType_enum_1.ActivityFeedType),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], ActivityFeedDto.prototype, "type", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(10000),
    __metadata("design:type", String)
], ActivityFeedDto.prototype, "message", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => activityFeedModifiedField_dto_1.ActivityFeedModifiedFieldDto),
    __metadata("design:type", Array)
], ActivityFeedDto.prototype, "modifiedFields", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => linkedResource_dto_1.LinkedResourceDto),
    __metadata("design:type", Array)
], ActivityFeedDto.prototype, "linkedResources", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", linkedResource_dto_1.LinkedResourceDto)
], ActivityFeedDto.prototype, "affectedResource", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], ActivityFeedDto.prototype, "visibleToTenant", void 0);
