import { useEffectAsync } from "../../../../hooks/useEffectAsync";
import { phoneNumbersApi } from "api/phoneNumbersApi";
import _ from "lodash";
import { useState } from "react";
import type { LoginResponseType, PhoneNumberDto } from "@doorloop/dto";
import { PhoneNumberStatus } from "@doorloop/dto";
import { atom, useAtom } from "jotai";
import { tenantPortalPhoneNumbersApi } from "api/tenantPortal/tenantPortalPhoneNumbersApi";
import { ownerPortalPhoneNumbersApi } from "api/ownerPortal/ownerPortalPhoneNumbersApi";
import type { ApiResult } from "api/apiResult";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";

const userTypeToPhoneNumbersApiMap: Record<
  Exclude<LoginResponseType, "PROSPECT" | "API">,
  () => Promise<ApiResult<PhoneNumberDto>>
> = {
  USER: phoneNumbersApi.getCurrentPhoneNumber,
  TENANT: tenantPortalPhoneNumbersApi.getCurrentPhoneNumber,
  OWNER: ownerPortalPhoneNumbersApi.getCurrentPhoneNumber
};

export const phoneNumberAtom = atom<PhoneNumberDto | undefined | null>(undefined);

export const useCurrentPhoneNumber = () => {
  const [isCurrentPhoneNumberLoading, setIsCurrentPhoneNumberLoading] = useState<boolean>(false);
  const [currentPhoneNumber, setCurrentPhoneNumber] = useAtom(phoneNumberAtom);
  const { currentLoginResponse } = useSelector((state: IRootState) => state.auth);
  const isCurrentPhoneNumberActive = currentPhoneNumber?.status === PhoneNumberStatus.ACTIVE;

  useEffectAsync(async () => {
    if (!currentLoginResponse) {
      return;
    }

    const getPhoneNumber = userTypeToPhoneNumbersApiMap[currentLoginResponse.type];

    if (currentPhoneNumber === undefined) {
      setIsCurrentPhoneNumberLoading?.(true);
      const phoneNumberResult = await getPhoneNumber();

      if (!_.isEmpty(phoneNumberResult.data)) {
        setCurrentPhoneNumber(phoneNumberResult.data);
      } else {
        setCurrentPhoneNumber(null);
      }

      setIsCurrentPhoneNumberLoading?.(false);
    }
  }, [currentLoginResponse?.id]);

  return { currentPhoneNumber, setCurrentPhoneNumber, isCurrentPhoneNumberLoading, isCurrentPhoneNumberActive };
};
