import type { BulkPaymentWrapperDto, BulkReceiveSessionDto } from "@doorloop/dto";
import { SegmentEventTypes } from "@doorloop/dto";
import { View } from "DLUI/view";
import { useFormikContext } from "formik";
import React from "react";
import { analyticsService } from "services/analyticsService";
import { EditableReviewTable } from "../editableReviewTable/editableReviewTable";
interface ComponentProps {
  onGoBackRequested: () => void;
}

const Review = ({ onGoBackRequested }: ComponentProps) => {
  const formikContext = useFormikContext<BulkReceiveSessionDto>();
  const payments = formikContext.values.payments;

  const onDeletePaymentClicked = (dto: BulkPaymentWrapperDto, index: number) => {
    analyticsService.track(SegmentEventTypes.BUlK_RP_PAYMENT_REVIEW_DELETED);
    const newPayments = { ...payments };
    delete newPayments[dto.lease!];
    formikContext.setFieldValue("payments", newPayments, false);

    // if that was the last payment, go back to search view
    if (Object.keys(newPayments).length === 0) {
      onGoBackRequested();
    }
  };

  return (
    <View
      paddingLeft={20}
      paddingRight={20}
      marginTop={20}
      gap={5}
      justifyContent="space-between"
      height="calc(100% - 100px)"
    >
      <EditableReviewTable onDeleteClicked={onDeletePaymentClicked} />
    </View>
  );
};

export default Review;
