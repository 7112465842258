import { MultiSelectGridContainerMaxWidth } from "DLUI/infiniteList/utils";
import type { ReactElement, ReactNode } from "react";
import React, { useMemo, useState } from "react";
import type { GridCellProps, GridHeaderCellProps } from "@progress/kendo-react-grid";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import Text from "DLUI/text";
import _ from "lodash";
import "../styles.css";
import { View } from "DLUI/view";
import "./styles.css";
import type { PopoverItem } from "DLUI/popover";
import { Popover } from "DLUI/popover";
import { DotsIcon } from "assets/icons";
import type { TextFormatType } from "DLUI/text/text";
import type { Alignment } from "DLUI/text/types";
import type { RestApiBase } from "api/restApiBase";
import { AnimateMarginTop } from "DLUI/animatableView";
import { useTranslation } from "react-i18next";
import EmptyDataView from "DLUI/emptyDataView";
import type { GridSelectionChangeEvent } from "@progress/kendo-react-grid/dist/npm/interfaces/events";
import { useEffectAsync } from "hooks/useEffectAsync";
import { SELECTION_IDENTIFIER } from "DLUI/lists/types";
import NetworkError from "DLUI/lists/gridList/dataGrid/networkError";
import MultiSelectGridListHeaderCell from "./multiSelectGridListHeaderCell";

interface TextProps {
  value: string;
  formatType?: TextFormatType;
  align?: Alignment;
}

export interface MultiSelectGridColumn<ItemDto> {
  field: string;
  title: string;
  show: boolean;
  width?: number;
  translate?: (cellValue: string, currentItem: ItemDto) => string;
  getPopoverColumns?: (dataItem: ItemDto) => PopoverItem[];
  format?: string;
  defaultValue?: string;
  showZeroValues?: boolean;
  popoverWidth?: number;
  unselectOnPopoverItemClick?: boolean;
  align?: Alignment;
}

interface ComponentProps<ItemDto, ItemQuery> {
  api: RestApiBase<ItemDto, ItemQuery>;
  filterObj: ItemQuery;
  gridColumns: Array<MultiSelectGridColumn<ItemDto>>;
  dataItemKey: string;
  onGridRowPress?: (props: GridCellProps) => void;
  onSelectionUpdate: (items: ItemDto[], dataSource?: ItemDto[]) => void;
  initLoadingComponent?: ReactElement;
  popOverEditItemKey?: string;
  emptyListComponent?: ReactElement;
  showNetworkInProgress?: boolean;
  GridHeader?: ReactNode;
  preSelectedRows?: number;
  maxWidth?: number;
  listContainerPaddingRight?: number;
  listContainerPaddingLeft?: number;
  marginTop?: number;
  paddingBottom?: number;
  resizable?: boolean;
  preSelectedMethod?: (item: ItemDto) => boolean;
  textFontSize?: number;
}

/**
 * @deprecated Please use GridList with multiSelect prop instead
 */
const DLUI_MultiSelectGridList = <ItemDto, ItemQuery>({
  api,
  filterObj,
  gridColumns,
  dataItemKey,
  initLoadingComponent,
  showNetworkInProgress,
  emptyListComponent,
  GridHeader,
  preSelectedRows,
  onSelectionUpdate,
  listContainerPaddingRight,
  listContainerPaddingLeft,
  marginTop,
  paddingBottom,
  maxWidth,
  resizable,
  preSelectedMethod,
  textFontSize
}: ComponentProps<ItemDto, ItemQuery>) => {
  const [dataState, setDataState] = useState<any[] | null>(null);
  const [networkInProgress, setNetworkInProgress] = useState(false);
  const [networkError, setNetworkError] = useState<boolean>(false);
  const [showEmptyScreen, setShowEmptyScreen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { t } = useTranslation();
  const [headerSelectionValue, setHeaderSelectionValue] = useState<boolean>(false);

  useEffectAsync(async () => {
    await fetchDataSource(filterObj);
  }, [filterObj]);

  const renderGridCell = (tdElement: ReactElement<HTMLTableCellElement> | null, cellProps: GridCellProps) => {
    const didPressGridRow = (unselect?: boolean) => {
      if (dataState) {
        const nextState = dataState.map((dataItem, index) => {
          return {
            ...dataItem,
            [SELECTION_IDENTIFIER]:
              index === cellProps.dataIndex ? (unselect ? false : !dataItem.selected) : dataItem.selected
          };
        });
        setDataState(nextState);
        onSelectionUpdate(
          nextState.filter((x) => x.selected),
          nextState
        );
      }
    };

    const currentColumn = gridColumns.find((x) => x.field === cellProps.field);

    const columnProps: TextProps = {
      value: ""
    };

    if (cellProps.field === undefined || !currentColumn) {
      return tdElement;
    }

    if (currentColumn.format === "popover") {
      const popoverItems = currentColumn.getPopoverColumns ? currentColumn.getPopoverColumns(cellProps.dataItem) : [];
      return popoverItems.length > 0 ? (
        <td>
          <View flexDirection={"row"} justifyContent={"flex-end"}>
            <Popover
              topSectionItems={popoverItems}
              Icon={DotsIcon}
              width={currentColumn.popoverWidth || 260}
              onPopoverItemSelection={() => didPressGridRow(true)}
            />
          </View>
        </td>
      ) : null;
    }

    const currentValue = _.get(cellProps.dataItem, cellProps.field);

    if (currentColumn.format === "date") {
      columnProps.value = currentValue;
      columnProps.formatType = "date";
    }

    if (currentColumn.format === "text") {
      columnProps.value = currentColumn.translate
        ? currentColumn.translate(currentValue, cellProps.dataItem)
        : currentValue;
    }

    if (currentColumn.format === "currency") {
      columnProps.value = currentValue ? currentValue : currentColumn.showZeroValues ? "0" : "";
      columnProps.formatType = "currency";
      columnProps.align = currentColumn.align ?? "right";
    }

    if (!columnProps.value && currentColumn.defaultValue) {
      columnProps.value = currentColumn.defaultValue;
    }

    return (
      <td onClick={() => didPressGridRow()}>
        <Text fontSize={textFontSize} {...columnProps} />
      </td>
    );
  };

  const gridMaxWidth = useMemo(() => {
    if (maxWidth) {
      return maxWidth;
    }
    return window.innerWidth > MultiSelectGridContainerMaxWidth
      ? MultiSelectGridContainerMaxWidth
      : window.innerWidth - 40;
  }, [maxWidth]);

  const onGroupSelectionChange = (newValue: boolean) => {
    if (dataState) {
      const nextState = dataState.map((dataItem) => {
        return {
          ...dataItem,
          [SELECTION_IDENTIFIER]: newValue
        };
      });

      setDataState(nextState);
      setHeaderSelectionValue(newValue);
      onSelectionUpdate(newValue ? nextState.filter(({ selected }) => selected) : [], nextState);
    }
  };

  const onSelectionChange = (event: GridSelectionChangeEvent) => {
    const checkboxElement = event.syntheticEvent.target as HTMLInputElement;
    const checked = checkboxElement.checked;
    if (dataState) {
      const nextState = dataState.map((dataItem, index) => {
        let selected = dataItem.selected;
        if (index === event.endRowIndex) {
          selected = checked;
        }
        return {
          ...dataItem,
          [SELECTION_IDENTIFIER]: selected
        };
      });
      setDataState(nextState);
      onSelectionUpdate(
        nextState.filter((x) => x.selected),
        nextState
      );
    }
  };

  const fetchDataSource = async (_filterSelection) => {
    setNetworkError(false);
    setNetworkInProgress(true);
    if (_.isEmpty(_filterSelection)) {
      setNetworkInProgress(false);
      setShowEmptyScreen(true);
      return;
    }
    const res = await api.getAll(_filterSelection);
    if (res?.data?.data) {
      if (res.data.data.length === 0) {
        setDataState([]);
        setShowEmptyScreen(true);
        onSelectionUpdate([]);
      } else {
        const nextDataState = res.data.data.map((dataItem, index) => {
          return {
            selected: preSelectedMethod
              ? preSelectedMethod(dataItem)
              : preSelectedRows
                ? index < preSelectedRows
                : false,
            ...dataItem
          };
        });
        setDataState(nextDataState);
        if (preSelectedRows || preSelectedMethod) {
          onSelectionUpdate(
            nextDataState.filter((x) => x.selected),
            res.data.data
          );
        }
      }
      setNetworkInProgress(false);
    } else {
      setNetworkError(true);
      setErrorMessage(res.message);
    }
  };

  if (networkError) {
    return (
      <EmptyDataView>
        <NetworkError errorMessage={errorMessage} fetchData={async () => await fetchDataSource(filterObj)} />
      </EmptyDataView>
    );
  }

  const renderGridHeader = () => {
    if (!GridHeader || (dataState && dataState.length === 0)) {
      return null;
    }
    return (
      <View marginTop={20} marginBottom={20} flexDirection={"row"} noWrap>
        {GridHeader}
      </View>
    );
  };

  const renderGrid = () => {
    if (networkInProgress || showNetworkInProgress) {
      return (
        <View flexDirection={"row"} noWrap>
          {initLoadingComponent}
        </View>
      );
    }

    if (dataState && dataState.length > 0 && !showNetworkInProgress) {
      return (
        <View
          alignItems={"center"}
          className={"multiselect-grid"}
          maxWidth={gridMaxWidth}
          height={"100%"}
          noWrap
          paddingRight={listContainerPaddingRight}
          paddingLeft={listContainerPaddingLeft}
        >
          {marginTop && <AnimateMarginTop marginTop={marginTop} />}
          <View
            backgroundColor={"white"}
            alignItems={"center"}
            borderRadius={10}
            flexDirection={"column"}
            height={"95%"}
            noWrap
          >
            {renderGridHeader()}

            <View
              className={!GridHeader ? "hide-header-top-border" : ""}
              noWrap
              paddingRight={20}
              paddingLeft={20}
              overflow={"scroll"}
            >
              <Grid
                data={dataState}
                cellRender={renderGridCell}
                headerCellRender={(tdElement: ReactNode, cellProps: GridHeaderCellProps) =>
                  MultiSelectGridListHeaderCell({ tdElement, cellProps, dataState, onGroupSelectionChange })
                }
                dataItemKey={dataItemKey}
                resizable={resizable || false}
                selectedField={SELECTION_IDENTIFIER}
                selectable={{
                  enabled: false,
                  drag: false,
                  cell: false,
                  mode: "multiple"
                }}
                onSelectionChange={onSelectionChange}
              >
                <Column field={SELECTION_IDENTIFIER} width="50px" headerSelectionValue={headerSelectionValue} />
                {gridColumns.map((col, colIndex) => (
                  <Column width={col.width + "px"} key={colIndex} field={col.field} title={t(col.title)} />
                ))}
              </Grid>
              {paddingBottom && <View flexDirection={"row"} noWrap height={paddingBottom} />}
            </View>
          </View>
        </View>
      );
    }

    if (showEmptyScreen) {
      return <View>{emptyListComponent}</View>;
    }
  };

  return (
    <View height={"100%"} justifyContent={"center"} flexDirection={"row"} noWrap>
      {renderGrid()}
    </View>
  );
};

export default DLUI_MultiSelectGridList;
