import { View } from "DLUI/view";
import React from "react";
import Text from "DLUI/text";
import type { ConversationLinkedToType, PersonWithPortalInfoDto } from "@doorloop/dto";
import { PhoneType } from "@doorloop/dto";
import { conversationUtils } from "screens/communicationsCenter/shared/conversationUtils";
import AppStrings from "../../../locale/keys";
import { useCurrentPhoneNumber } from "screens/communicationsCenter/shared/useCurrentPhoneNumber";
import { Field, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { FormikSwitchButton } from "DLUI/form";
import { AnimatedView } from "DLUI/view/animatedView";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag";
import { useCommunicationsCenterPermissions } from "screens/communicationsCenter/shared/useCommunicationsCenterPermissions";

interface ComponentProps {
  personType: Exclude<ConversationLinkedToType, "SYSTEM" | "USER">;
}

export const SendWelcomeSms: React.FC<ComponentProps> = ({ personType }) => {
  const { t } = useTranslation();
  const { isCurrentPhoneNumberActive } = useCurrentPhoneNumber();
  const { values } = useFormikContext<PersonWithPortalInfoDto>();

  const { getIsFeatureActiveForDbTenant } = useFeatureFlag();
  const { hasViewPermission } = useCommunicationsCenterPermissions();

  if (!getIsFeatureActiveForDbTenant("CommunicationsCenter") || !hasViewPermission) {
    return null;
  }

  const personTypeLabel = conversationUtils
    .getParticipantLinkedToTypeLabel({ linkedToType: personType })
    ?.toLowerCase();

  const getPersonPhoneNumber = () =>
    values.portalInfo?.mobilePhone ||
    values.phones?.find((phone) => phone.type === PhoneType.MOBILE && phone.number)?.number;

  return (
    <View gap={20}>
      <Field
        component={FormikSwitchButton}
        name="shouldSendWelcomeSms"
        label={AppStrings.Common.SendWelcomeSms}
        marginTop={20}
        disabled={
          !isCurrentPhoneNumberActive || !getPersonPhoneNumber() || Boolean(values.conversationWelcomeSmsSentAt)
        }
      />
      <AnimatedView shouldShow={Boolean(values.shouldSendWelcomeSms) || Boolean(values.conversationWelcomeSmsSentAt)}>
        <View
          minHeight={40}
          backgroundColor="dark"
          borderRadius={10}
          paddingLeft={20}
          paddingRight={20}
          paddingBottom={20}
          gap={6}
        >
          <Text marginTop={10} value={AppStrings.Common.SendWelcomeSms} bold />

          {values.conversationWelcomeSmsSentAt ? (
            <Text marginTop={10} value={t(AppStrings.Common.SendWelcomeSmsInEditDialogSent, { personTypeLabel })} />
          ) : (
            <Text marginTop={10} value={AppStrings.Common.SendWelcomeSmsInEditDialog} />
          )}
        </View>
      </AnimatedView>
    </View>
  );
};
