import React, { useState } from "react";
import type { EditorState, LexicalEditor } from "lexical";
import { $getRoot, $insertNodes, ParagraphNode } from "lexical";

import { TRANSFORMERS } from "@lexical/markdown";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { TableNode, TableCellNode, TableRowNode } from "@lexical/table";
import { ListNode, ListItemNode } from "@lexical/list";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { MarkNode } from "@lexical/mark";
import { CodeNode } from "@lexical/code";
import { $generateNodesFromDOM } from "@lexical/html";
import { OverflowNode } from "@lexical/overflow";

import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import type { InitialConfigType } from "@lexical/react/LexicalComposer";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import LexicalClickableLinkPlugin from "@lexical/react/LexicalClickableLinkPlugin";

import { FloatingLinkEditorPlugin, FloatingTextFormatToolbarPlugin, LinkPlugin } from "./plugins";
import type { UseRichTextEditorThemeProps } from "./useRichTextEditorTheme";
import { useRichTextEditorTheme } from "./useRichTextEditorTheme";
import makeStyles from "./styles";

const EDITOR_NODES = [
  ParagraphNode,
  MarkNode,
  OverflowNode,
  AutoLinkNode,
  LinkNode,
  TableNode,
  TableCellNode,
  TableRowNode,
  ListNode,
  ListItemNode,
  HeadingNode,
  QuoteNode,
  CodeNode
];

export interface RichTextPluginsProps extends UseRichTextEditorThemeProps {
  name: string;
  value?: string;
  onChange: (editorState: EditorState, editor: LexicalEditor) => void;
  onError: InitialConfigType["onError"];
}

const RichTextEditor = ({ name, value, onChange, onError, theme }: RichTextPluginsProps) => {
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const initEditorState = (editor: LexicalEditor) => {
    if (value) {
      editor.update(() => {
        // In the browser you can use the native DOMParser API to parse the HTML string.
        const parser = new DOMParser();
        const dom = parser.parseFromString(value, "text/html");

        // Once you have the DOM instance it's easy to generate LexicalNodes.
        const nodes = $generateNodesFromDOM(editor, dom);

        // Select the root
        $getRoot().select();

        // Insert them at a selection.
        $insertNodes(nodes);
      });
    }
  };

  const _theme = useRichTextEditorTheme({ theme });

  const classes = makeStyles();

  return (
    <LexicalComposer
      initialConfig={{
        namespace: name,
        nodes: EDITOR_NODES,
        onError,
        theme: _theme,
        editorState: initEditorState
      }}
    >
      <RichTextPlugin
        contentEditable={
          <div className={classes.editorScroller}>
            <div className={classes.editorContainer} ref={onRef}>
              <ContentEditable id={name} className={classes.editor} />
            </div>
          </div>
        }
        placeholder={<div />}
        ErrorBoundary={LexicalErrorBoundary}
      />

      <OnChangePlugin onChange={onChange} />
      <LexicalClickableLinkPlugin />
      <LinkPlugin />
      <HistoryPlugin />
      <TablePlugin />
      <ListPlugin />
      <CheckListPlugin />
      <MarkdownShortcutPlugin transformers={TRANSFORMERS} />

      {floatingAnchorElem ? (
        <>
          <FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem} />
          <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
        </>
      ) : (
        <div />
      )}
    </LexicalComposer>
  );
};

export { RichTextEditor };
