"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrintChecksReportItemDto = exports.PrintChecksReportLineItem = void 0;
class PrintChecksReportLineItem {
    constructor(account, amount, property, memo, reference) {
        this.account = account;
        this.amount = amount;
        this.property = property;
        this.memo = memo;
        this.reference = reference;
    }
}
exports.PrintChecksReportLineItem = PrintChecksReportLineItem;
class PrintChecksReportItemDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.PrintChecksReportItemDto = PrintChecksReportItemDto;
