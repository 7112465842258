import { useState } from "react";

export default function useTimeoutTrigger(timeout: number) {
  const [triggered, setTriggered] = useState(false);
  return [
    triggered,
    () => {
      setTriggered(true);
      setTimeout(() => setTriggered(false), timeout);
    }
  ] as const;
}
