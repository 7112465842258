import type { FC } from "react";
import React, { useState } from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import type { TextColor } from "DLUI/text/text";
import { Icon } from "DLUI/icon";
import { ICloseCircleIcon } from "assets/icons";
import DLButton, { DLButtonColorsEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import AppStrings from "locale/keys";
import { ControlledCheckBox } from "DLUI/form";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

export interface BulkActionConfirmation {
  header: string;
  message: string;
  actionName: string;
  actionCount: number;
  color?: TextColor;
  confirmButtonVariant?: DLButtonColorsEnum;
  hasCheckboxConfirmation?: boolean;
}

interface Props extends BulkActionConfirmation {
  onConfirm: () => void;
  closeHandler: (refresh?: boolean) => void;
}

export const BulkConfirmation: FC<Props> = ({
  header,
  message,
  actionName,
  actionCount,
  closeHandler,
  onConfirm,
  hasCheckboxConfirmation,
  confirmButtonVariant = DLButtonColorsEnum.DANGER
}: Props) => {
  const { t } = useTranslation();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const classes = useStyles();

  return (
    <View noWrap height={hasCheckboxConfirmation ? 150 : undefined}>
      <View
        flex={1}
        alignItems={"flex-start"}
        fullWidth
        marginBottom={hasCheckboxConfirmation ? 0 : 15}
        flexDirection={"row"}
        noWrap
      >
        <View flexDirection={"row"} noWrap id={"haha"} justifyContent={"space-between"} flex={1}>
          <View noWrap autoWidth>
            <Text fontSize={16} bold value={header} />
          </View>
          <View flexDirection={"row"} autoWidth>
            <View autoWidth onClick={() => closeHandler(false)} flexDirection={"row"}>
              <Icon Source={ICloseCircleIcon} />
            </View>
          </View>
        </View>
      </View>
      <View flex={1} marginBottom={hasCheckboxConfirmation ? 0 : 15} noWrap>
        <Text fontSize={14} value={message} replaceObject={{ x: actionCount }} />
      </View>
      <View justifyContent="space-between" alignItems="flex-end" marginTop={5} flexDirection={"row"} gap={4} noWrap>
        {hasCheckboxConfirmation && (
          <View flex={2}>
            <ControlledCheckBox
              checked={isConfirmed}
              style={{ transform: "translateX(-10px)" }}
              className={classes.translateLeft}
              fontSize="small"
              onChange={setIsConfirmed}
              labelValueProps={{
                text: t(AppStrings.BulkActions.ConfirmDeleteDisclaimer),
                color: "gray"
              }}
            />
          </View>
        )}
        <View flexDirection="row" gap={4} flex={1} justifyContent="flex-end" alignItems="flex-end">
          {hasCheckboxConfirmation && (
            <DLButton
              variant={DLButtonVariantsEnum.TEXT}
              onClick={() => closeHandler(false)}
              actionText={AppStrings.Common.Cancel}
              style={{ minWidth: 60, marginRight: 0, color: "#182C4C" }}
            />
          )}
          <DLButton
            disabled={hasCheckboxConfirmation ? !isConfirmed : false}
            onClick={onConfirm}
            actionText={actionName}
            color={confirmButtonVariant}
          />
        </View>
      </View>
    </View>
  );
};
