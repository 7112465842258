"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AllocationRounding = exports.AllocationStrategyWithCustom = exports.AllocationStrategy = void 0;
var AllocationStrategy;
(function (AllocationStrategy) {
    AllocationStrategy["MANUAL"] = "MANUAL";
    AllocationStrategy["EVEN"] = "EVEN";
    AllocationStrategy["BY_SIZE"] = "BY_SIZE";
})(AllocationStrategy || (exports.AllocationStrategy = AllocationStrategy = {}));
var AllocationStrategyWithCustom;
(function (AllocationStrategyWithCustom) {
    AllocationStrategyWithCustom["MANUAL"] = "MANUAL";
    AllocationStrategyWithCustom["EVEN"] = "EVEN";
    AllocationStrategyWithCustom["BY_SIZE"] = "BY_SIZE";
    AllocationStrategyWithCustom["CUSTOM"] = "CUSTOM";
})(AllocationStrategyWithCustom || (exports.AllocationStrategyWithCustom = AllocationStrategyWithCustom = {}));
var AllocationRounding;
(function (AllocationRounding) {
    AllocationRounding["NEAREST_WHOLE_NUMBER"] = "NEAREST_WHOLE_NUMBER";
    AllocationRounding["NEAREST_TENTH"] = "NEAREST_TENTH";
    AllocationRounding["NEAREST_HUNDREDTH"] = "NEAREST_HUNDREDTH"; // 10.567 => 10.57
})(AllocationRounding || (exports.AllocationRounding = AllocationRounding = {}));
