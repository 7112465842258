"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DoorLoopLeadSources = void 0;
var DoorLoopLeadSources;
(function (DoorLoopLeadSources) {
    DoorLoopLeadSources["FREE_TRIAL"] = "Trial Signup";
    DoorLoopLeadSources["SCHEDULE_DEMO"] = "Scheduled Demo";
    DoorLoopLeadSources["SCHEDULE_CALL"] = "Scheduled Call";
    DoorLoopLeadSources["BLOG_SUBSCRIBER"] = "Blog Subscriber";
    DoorLoopLeadSources["AWAITING_CALLBACK"] = "Awaiting Callback";
})(DoorLoopLeadSources || (exports.DoorLoopLeadSources = DoorLoopLeadSources = {}));
