import React from "react";

import type { FastFieldProps } from "formik";
import { FastField } from "formik";
import type { Path } from "react-hook-form";

export type FastFieldSafeProps<ComponentType, TDto extends any = null> = Omit<
  ComponentType extends React.ComponentType<infer ComponentProps> ? ComponentProps : never,
  keyof FastFieldProps
> & {
  name: TDto extends { prototype: infer K } ? Path<K> : string;
  dto?: TDto;
  component: ComponentType;
  required?: boolean;
};

export const FastFieldSafe = <ComponentType extends React.ComponentType<any>, TDto extends any = null>(
  props: FastFieldSafeProps<ComponentType, TDto>
) => {
  const { component, dto, ...rest } = props;
  return <FastField {...rest} component={component} />;
};
