import { atom, useAtom } from "jotai";
import { CircularProgress } from "@material-ui/core";
import { globalSearchApi } from "api/globalSearchApi";
import { CloseGrayIcon, NoResultsIcon } from "assets/icons";
import clsx from "clsx";
import { IconButton } from "DLUI/form";
import { Icon } from "DLUI/icon";
import type { TabItem } from "DLUI/tabs";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import type { FC, MouseEvent } from "react";
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import SectionTitle from "../sectionTitle/sectiontitle";
import GlobalSearchTabs from "./globalSearchTabs";
import SearchInput from "./searchInput";
import "./styles.css";
import { DefaultDebounceDelays, useDebounce } from "@/hooks/useDebounce";
import { useLocalGlobalSearch } from "@/hooks/useLocalGlobalSearch";
import _ from "lodash";
import type { SearchResults, TabNamesTypes } from "DLUI/screen/globalSearch/types";
import { globalSearchContstants } from "DLUI/screen/globalSearch/globalSearch.constants";

import { GlobalSearchTabMap } from "DLUI/screen/globalSearch/globalSearchTabMap";
import { useAnalyticsService } from "@/hooks/useAnalyticsService";
import { useKeyboard } from "hooks/useKeyboard";

interface ComponentProps {
  children: React.ReactNode;
  shouldShow: boolean;
  onBackdropClick: () => void;
  onCloseCompleted: () => void;
}

export const globalSearchTextAtom = atom("");

const GlobalSearch: FC<ComponentProps> = ({ children, shouldShow, onCloseCompleted, onBackdropClick }) => {
  const [showSearchPanel, setShowSearchPanel] = useState<boolean>();
  const [removeContainer, setRemoveContainer] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [showEmptySearchView, setShowEmptySearchView] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResults>();
  const [_globalSearchText, setGlobalSearchText] = useAtom(globalSearchTextAtom);
  const shouldShowRef = useRef(shouldShow);

  const debouncedSearchQuery = useDebounce(searchQuery, DefaultDebounceDelays.GLOBAL_TEXT_SEARCH_CHANGED);
  const { getSearchedData, resetResults } = useLocalGlobalSearch();
  const { dispatchAnalytics } = useAnalyticsService();
  const localSearchResults = useMemo(() => getSearchedData(debouncedSearchQuery), [debouncedSearchQuery]);

  const executeSearch = async (query: string) => {
    if (requestInProgress) {
      return;
    }

    setRequestInProgress(true);

    try {
      setGlobalSearchText(query);

      const results = await globalSearchApi.getresults({
        filter_text: query
      });

      setRequestInProgress(false);
      dispatchAnalytics("global_search_user_got_results", {
        userSearchQuery: query
      });

      if (!results?.status) {
        return;
      }

      if (_.every(results.data, _.isEmpty) && shouldShowRef.current && !localSearchResults) {
        setShowEmptySearchView(true);
        return;
      }
      if (showEmptySearchView) {
        setSearchResults(undefined);
        setShowEmptySearchView(false);

        setTimeout(() => {
          setSearchResults(results.data);
        }, globalSearchContstants.animationDuration);
        return;
      }

      setSearchResults(results.data);
    } catch (error) {
      setRequestInProgress(false);
    }
  };

  const hideSearch = () => {
    setShowSearchPanel(false);
    onCloseCompleted();

    setTimeout(() => {
      setRemoveContainer(true);
    }, globalSearchContstants.animationDuration);
  };

  const cleanState = () => {
    setSearchQuery("");
    setSearchResults(undefined);
    setShowEmptySearchView(false);
    setRequestInProgress(false);
  };

  useEffect(() => {
    if (!debouncedSearchQuery) return;

    executeSearch(debouncedSearchQuery);
  }, [debouncedSearchQuery]);

  const onSearchPressed = (query: string) => {
    // use the actual value instead of the debounced value
    setSearchQuery(query);
    executeSearch(query);
  };

  useEffect(() => {
    shouldShowRef.current = shouldShow;
    cleanState();

    if (shouldShow) {
      setRemoveContainer(false);
      setShowSearchPanel(true);
      dispatchAnalytics("global_search_open");
      return;
    }
    hideSearch();
  }, [shouldShow]);

  const handleSearchQueryItemClicked = useCallback(
    (tabName: TabNamesTypes) => {
      didPressCloseButton();

      dispatchAnalytics("global_search_user_clicked_result", {
        userSearchQuery: searchQuery,
        tabName
      });
    },
    [debouncedSearchQuery, showEmptySearchView, searchResults]
  );

  const tabs: TabItem[] = useMemo(() => {
    const _tabs: TabItem[] = [];

    if (!searchResults && !localSearchResults) {
      return _tabs;
    }

    const searchResultsWithLocalResult = { ...searchResults, localSearchResults } as SearchResults;
    const sortedSearchResultsByOrder = _.entries(searchResultsWithLocalResult).sort((a, b) => {
      const tabA = GlobalSearchTabMap[_.first(a)];
      const tabB = GlobalSearchTabMap[_.first(b)];
      return (tabA.order || Number.MAX_SAFE_INTEGER) - (tabB.order || Number.MAX_SAFE_INTEGER);
    });

    _.forEach(sortedSearchResultsByOrder, ([_key, listItems]) => {
      const key = _key as keyof SearchResults;
      const tab = GlobalSearchTabMap[key];
      if (tab && listItems && !_.isEmpty(listItems)) {
        _tabs.push({
          label: tab.label,
          component: () => (
            <tab.Component
              listItems={listItems}
              handleItemClicked={(tabName) => {
                if (key === "localSearchResults") {
                  resetResults();
                }
                handleSearchQueryItemClicked(tabName);
              }}
            />
          ),
          resultsCount: listItems.length
        });
      }
    });

    return _tabs;
  }, [searchResults, localSearchResults]);

  useKeyboard(
    "Escape",
    () => {
      dispatchAnalytics("global_search_keybind_close");
      hideSearch();
    },
    []
  );

  let searchPanelAnimation = "";
  if (showSearchPanel !== undefined) {
    if (showSearchPanel) {
      searchPanelAnimation = "slide-in-top";
    }
    if (!showSearchPanel) {
      searchPanelAnimation = "slide-out-top";
    }
  }

  const stopPropagation = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const onSearchInputChange = (nextValue: string) => {
    if (nextValue === "") {
      cleanState();
    } else if (nextValue.length > 1) {
      setSearchQuery(nextValue);
    }
  };

  const didPressCloseButton = () => {
    if (searchResults || showEmptySearchView) {
      cleanState();
      onBackdropClick();
    } else {
      onBackdropClick();
    }
  };

  const renderSearchContent = () => (
    <View>
      <View
        height={globalSearchContstants.searchPanelHeight}
        className={clsx(["DLUI_searchPanelContainer", searchPanelAnimation])}
        onClick={stopPropagation}
        backgroundColor={"blue"}
      >
        <div style={{ position: "absolute", zIndex: 500, right: 20, top: 20 }}>
          <IconButton Icon={CloseGrayIcon} onClick={didPressCloseButton} pathColor={"white"} width={25} height={25} />
        </div>
        <View marginTop={20} paddingLeft={20} paddingRight={20}>
          <SectionTitle title={AppStrings.Common.GlobalSearch.SearchTitle} type={"underline"} textColor={"white"} />
          <Text
            color={"white"}
            fontSize={20}
            value={AppStrings.Common.GlobalSearch.SearchInstructions}
            marginTop={20}
          />

          <SearchInput
            searchQuery={searchQuery}
            onChange={onSearchInputChange}
            requestInProgress={requestInProgress}
            onSearchClick={onSearchPressed}
          />
        </View>
      </View>
      <SearchResultsContent
        onClick={stopPropagation}
        tabs={tabs}
        showEmptySearchView={showEmptySearchView}
        requestInProgress={requestInProgress}
      />
    </View>
  );

  const renderGlobalNew = () => (
    <div
      className={clsx([
        "globalSearchContainer",
        shouldShow ? "animateShow" : "animateHide",
        removeContainer ? "hideMenu" : ""
      ])}
      onClick={onBackdropClick}
    >
      {removeContainer ? null : <div>{renderSearchContent()}</div>}
    </div>
  );

  return (
    <Fragment>
      {renderGlobalNew()}
      {children}
    </Fragment>
  );
};

const SearchResultsContent = (props: {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  tabs: TabItem[];
  showEmptySearchView: boolean;
  requestInProgress: boolean;
}) => (
  <View height={`calc(100vh - ${globalSearchContstants.searchPanelHeight}px)`} overflow={"hidden"}>
    <View
      onClick={props.onClick}
      className={props.tabs.length > 0 ? "scale-in" : "scale-out"}
      height={"100%"}
      backgroundColor={"light"}
    >
      {props.tabs.length > 0 ? <GlobalSearchTabs tabsPanelBackgroundColor={"blue"} tabsItems={props.tabs} /> : <div />}
    </View>
    <View
      onClick={props.onClick}
      style={{ position: "absolute" }}
      className={props.showEmptySearchView ? "scale-in" : "scale-out"}
    >
      <View
        height={`calc(100vh - ${globalSearchContstants.searchPanelHeight}px)`}
        backgroundColor={"light"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {props.requestInProgress ? (
          <View justifyContent={"center"} alignItems={"center"}>
            <CircularProgress
              style={{
                width: 80,
                height: 80,
                color: "#2F3E83"
              }}
            />
            <Text value={AppStrings.Common.Loading} color={"black"} fontSize={20} marginTop={20} />
          </View>
        ) : (
          <View justifyContent={"center"} alignItems={"center"}>
            <Icon pathColor={"black"} width={100} height={100} Source={NoResultsIcon} />
            <Text
              color={"black"}
              value={AppStrings.Common.NoResultsFound}
              fontSize={24}
              marginTop={20}
              align={"center"}
            />
            <Text
              color={"gray"}
              value={AppStrings.Common.GlobalSearch.NoResultsFoundDescription}
              fontSize={18}
              marginTop={10}
              align={"center"}
            />
          </View>
        )}
      </View>
    </View>
  </View>
);

export default GlobalSearch;
