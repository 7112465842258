import React from "react";
import { accountsApi } from "api/accounts";
import type { DialogState } from "DLUI/dialogs/loadingDialog";
import { AnimatedContentDialog } from "DLUI/dialogs";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import { initFormValues, validateAccountForm, validateForm } from "./formikHelper";
import { getAccountFormMenuItems } from "./menuItems";
import type { Method } from "axios";
import type { ApiToastsProps } from "api/apiHelper";

interface ComponentProps<T> {
  dialogState: DialogState;
  onClose: () => void;
  onBackdropClick: () => void;
  title?: string;
  accountId?: string;
  selectedTabIndex?: number;
  toasts?: Partial<Record<Method, ApiToastsProps<T>>>;
}

function AccountDialog<T>({
  dialogState,
  onClose,
  onBackdropClick,
  title,
  accountId,
  selectedTabIndex,
  toasts
}: ComponentProps<T>) {
  const { t } = useTranslation();
  const newAccountDialogItems = getAccountFormMenuItems({ selectedTabIndex });

  return (
    <AnimatedContentDialog
      dialogState={dialogState}
      onClose={onClose}
      sectionItems={newAccountDialogItems}
      disableBackdropClick
      onBackdropClick={onBackdropClick}
      getformikInitialValues={initFormValues}
      formikValidation={validateForm}
      validationMethod={validateAccountForm}
      loadingText={t(AppStrings.Accounts.ChartOfAccounts.LoadingText)}
      errorText={t(AppStrings.Accounts.ChartOfAccounts.ErrorText)}
      successText={t(AppStrings.Accounts.ChartOfAccounts.SuccessText)}
      apiMethod={accountsApi}
      dialogTitle={title || AppStrings.Accounts.ChartOfAccounts.NewAccount}
      dialogHeight={850}
      type={"newAccount"}
      apiObjectId={accountId}
      toasts={toasts}
    />
  );
}

export default AccountDialog;
