import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { FastField } from "formik";
import { TextField } from "DLUI/form";
import { useTranslation } from "react-i18next";
import { useResponsiveHelper } from "../../../../../../contexts/responsiveContext";

interface Props {}

const EditLeaseNameForm: React.FC<Props> = ({}: Props) => {
  const { screenContainerPadding } = useResponsiveHelper();
  const { t } = useTranslation();

  return (
    <View paddingLeft={screenContainerPadding} paddingRight={screenContainerPadding}>
      <Text marginTop={20} value={AppStrings.Leases.Settings.NameAndNotesDialogDescription} />
      <FastField component={TextField} label={t(AppStrings.Leases.Settings.LeaseName)} name={"name"} marginTop={20} />
      <FastField
        component={TextField}
        label={t(AppStrings.Notes.Dialog.NoteBodyPlaceholder)}
        name={"notes"}
        multiline
        rows="4"
        marginTop={20}
        multiLineSize={"normal"}
        maxLength={1000}
      />
    </View>
  );
};

export default EditLeaseNameForm;
