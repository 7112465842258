"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecurringTransactionsDetailReportItem = void 0;
const recurringTransaction_dto_1 = require("../../recurringTransactions/recurringTransaction.dto");
class RecurringTransactionsDetailReportItem extends recurringTransaction_dto_1.RecurringTransactionDto {
    constructor(init) {
        super(init);
        Object.assign(this, init);
    }
}
exports.RecurringTransactionsDetailReportItem = RecurringTransactionsDetailReportItem;
