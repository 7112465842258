import React from "react";

import { makeStyles } from "@material-ui/styles";
import Text from "DLUI/text";
import type { TextColor } from "DLUI/text/text";
import type { Theme } from "styles/defaultTheme";

interface ItemLabelChipProps {
  value: number | string;
  textColor?: TextColor;
}

const useStyles = makeStyles<Theme>((theme) => {
  return {
    root: {
      backgroundColor: theme.newPalette.action.hover,
      width: 22,
      height: 22,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 4,
      marginInlineEnd: 8
    }
  };
});

export const ItemLabelChip = ({ value, textColor = "black" }: ItemLabelChipProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Text value={value.toString()} color={textColor} fontSize={14} fontWeight={700} />
    </div>
  );
};
