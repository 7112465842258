import { AlternateEmailIcon, DollarGrayCircle, LoginCircleIcon, SendCircleIcon } from "../../../../assets";
import type { StatusStepInterface } from "DLUI/stepStatus/stepStatus";
import AppStrings from "../../../../locale/keys";
import { LeaseTenantStepStatus } from "@doorloop/dto";

const gray = "secondary-gray-light";

export const NOT_STARTED_STEPS: StatusStepInterface[] = [
  {
    IconSource: AlternateEmailIcon,
    connectorColor: gray,
    tooltip: AppStrings.Common.AddContactInfo,
    isRawText: false
  },
  {
    IconSource: LoginCircleIcon,
    connectorColor: gray,
    tooltip: AppStrings.Common.SendTenantPortalInvitation,
    isRawText: false
  },
  {
    IconSource: SendCircleIcon,
    connectorColor: gray,
    tooltip: AppStrings.Common.TenantLoginToPortal,
    isRawText: false
  },
  {
    IconSource: DollarGrayCircle,
    connectorColor: gray,
    tooltip: AppStrings.Common.TenantSetupPaymentMethod,
    isRawText: false,
    showConnector: false,
    hiddenForUserTypes: ["HOAUser"]
  }
];

export const TENANTS_LIST_STATUS_STEPS_MAP: {
  [key in LeaseTenantStepStatus]?: StatusStepInterface[];
} = {
  [LeaseTenantStepStatus.MISSING_CONTACT_DETAILS]: [
    {
      IconSource: AlternateEmailIcon,
      rectFill: "error",
      connectorColor: gray,
      tooltip: AppStrings.Common.AddContactInfo,
      isRawText: false
    },
    {
      IconSource: LoginCircleIcon,
      connectorColor: gray,
      tooltip: AppStrings.Common.SendTenantPortalInvitation,
      isRawText: false
    },
    {
      IconSource: SendCircleIcon,
      connectorColor: gray,
      tooltip: AppStrings.Common.TenantLoginToPortal,
      isRawText: false
    },
    {
      IconSource: DollarGrayCircle,
      connectorColor: gray,
      tooltip: AppStrings.Common.TenantSetupPaymentMethod,
      isRawText: false,
      showConnector: false,
      hiddenForUserTypes: ["HOAUser"]
    }
  ],
  [LeaseTenantStepStatus.PENDING_INVITE]: [
    {
      IconSource: AlternateEmailIcon,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.AddContactInfo,
      isRawText: false
    },
    {
      IconSource: LoginCircleIcon,
      connectorColor: gray,
      tooltip: AppStrings.Common.SendTenantPortalInvitation,
      isRawText: false
    },
    {
      IconSource: SendCircleIcon,
      connectorColor: gray,
      tooltip: AppStrings.Common.TenantLoginToPortal,
      isRawText: false
    },
    {
      IconSource: DollarGrayCircle,
      connectorColor: gray,
      tooltip: AppStrings.Common.TenantSetupPaymentMethod,
      isRawText: false,
      showConnector: false,
      hiddenForUserTypes: ["HOAUser"]
    }
  ],
  [LeaseTenantStepStatus.INVITED]: [
    {
      IconSource: AlternateEmailIcon,
      connectorColor: "green",
      rectFill: "dark-green",
      tooltip: AppStrings.Common.AddContactInfo,
      isRawText: false
    },
    {
      IconSource: LoginCircleIcon,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.SendTenantPortalInvitation,
      isRawText: false
    },
    {
      IconSource: SendCircleIcon,
      tooltip: AppStrings.Common.TenantLoginToPortal,
      connectorColor: gray,
      isRawText: false
    },
    {
      IconSource: DollarGrayCircle,
      connectorColor: gray,
      tooltip: AppStrings.Common.TenantSetupPaymentMethod,
      isRawText: false,
      showConnector: false,
      hiddenForUserTypes: ["HOAUser"]
    }
  ],
  [LeaseTenantStepStatus.ACTIVE]: [
    {
      IconSource: AlternateEmailIcon,
      connectorColor: "green",
      rectFill: "dark-green",
      tooltip: AppStrings.Common.AddContactInfo,
      isRawText: false
    },
    {
      IconSource: LoginCircleIcon,
      rectFill: "dark-green",
      connectorColor: "green",
      tooltip: AppStrings.Common.SendTenantPortalInvitation,
      isRawText: false
    },
    {
      IconSource: SendCircleIcon,
      tooltip: AppStrings.Common.TenantLoginToPortal,
      rectFill: "dark-green",
      isRawText: false
    },
    {
      IconSource: DollarGrayCircle,
      tooltip: AppStrings.Common.TenantSetupPaymentMethod,
      isRawText: false,
      connectorColor: gray,
      showConnector: false,
      hiddenForUserTypes: ["HOAUser"]
    }
  ],
  [LeaseTenantStepStatus.PAYMENT_SETUP]: [
    {
      IconSource: AlternateEmailIcon,
      connectorColor: "green",
      rectFill: "dark-green",
      tooltip: AppStrings.Common.AddContactInfo,
      isRawText: false
    },
    {
      IconSource: LoginCircleIcon,
      rectFill: "dark-green",
      tooltip: AppStrings.Common.SendTenantPortalInvitation,
      isRawText: false
    },
    {
      IconSource: SendCircleIcon,
      tooltip: AppStrings.Common.TenantLoginToPortal,
      rectFill: "dark-green",
      connectorColor: "green",
      isRawText: false
    },
    {
      IconSource: DollarGrayCircle,
      tooltip: AppStrings.Common.TenantSetupPaymentMethod,
      isRawText: false,
      rectFill: "dark-green",
      connectorColor: "green",
      showConnector: false,
      hiddenForUserTypes: ["HOAUser"]
    }
  ]
};
