"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Owners1099ReportItem = void 0;
const taxForms_1 = require("../taxForms");
class Owners1099ReportItem extends taxForms_1.TaxForm1099Dto {
    constructor(...args) {
        super();
        Object.assign(this, ...args);
    }
}
exports.Owners1099ReportItem = Owners1099ReportItem;
