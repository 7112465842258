import clsx from "clsx";

import type { ChipColorsEnum, ChipVariantsEnum } from "./enums";
import { makeStylesContainer, makeStylesDivider, makeStylesIcon, makeStylesText } from "./styles";

const noHoverKey = "no_hover";

interface UseStylesChipClassNames {
  container?: string;
  text?: string;
  icon?: string;
  divider?: string;
}

export interface UseStylesChipProps {
  isLabel?: boolean;
  isRectangle?: boolean;
  noHover?: boolean;
  variant?: ChipVariantsEnum;
  color?: ChipColorsEnum;
  removeTextPadding?: boolean;
  classNames?: UseStylesChipClassNames;
  isMobile?: boolean;
}

const useStylesChip = ({
  isLabel,
  isRectangle,
  variant,
  color,
  classNames,
  noHover,
  removeTextPadding,
  isMobile = false
}: UseStylesChipProps) => {
  const classesContainer = makeStylesContainer();
  const classesText = makeStylesText();
  const classesIcon = makeStylesIcon();
  const classesDivider = makeStylesDivider();

  const classKey = `${color}_${variant}`;
  const noHoverClassKey = `${classKey}${noHover ? `_${noHoverKey}` : ""}`;

  return {
    container: clsx([
      classNames?.container,
      classesContainer.root,
      noHover ? classesContainer[noHoverClassKey] : classesContainer[classKey],
      !isLabel && classesContainer.noLabel,
      isRectangle && classesContainer.rectangle,
      removeTextPadding && classesContainer.noTextPadding
    ]),
    text: clsx([classNames?.text, classesText.root, classesText[classKey]]),
    icon: clsx([classNames?.icon, classesIcon[classKey]]),
    divider: clsx([
      classNames?.divider,
      classesDivider.root,
      classesDivider[classKey],
      isMobile ? classesDivider["mobile"] : ""
    ])
  };
};

export { useStylesChip };
