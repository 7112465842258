import React from "react";
import { Grid } from "@material-ui/core";

import { ArrayUtils } from "../../../../utils/dataStructureUtils";
import { SectionTitle } from "DLUI/screen";
import { View } from "DLUI/view";

import makeStyles from "../../../DLUI/report/screen/styles";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import type { LocalSearchItem } from "../../../../constants/localSearchData";
import { LocalSearchResultSectionItem } from "DLUI/screen/globalSearch/localSearchResultSectionItem";

interface ComponentProps {
  items: LocalSearchItem[];
  handleItemClicked: () => void;
  title: string;
}

export const LocalSearchResultSection = ({ items, title, handleItemClicked }: ComponentProps) => {
  const { isMobile } = useResponsiveHelper();
  const classes = makeStyles();

  return (
    <View className={classes.ReportsSectionUserView} noWrap>
      <SectionTitle type={"underline"} title={title} marginTop={20} />
      <View flexDirection={"row"} marginTop={10} gap={isMobile ? 0 : 16} noWrap={!isMobile}>
        <Grid item container sm={12} md={12} lg={6}>
          {ArrayUtils.getFirstHalf(items).map((item, index) => (
            <LocalSearchResultSectionItem handleItemClicked={handleItemClicked} key={index} item={item} />
          ))}
        </Grid>
        <Grid item container sm={12} md={12} lg={6}>
          {ArrayUtils.getSecondHalf(items).map((item, index) => (
            <LocalSearchResultSectionItem handleItemClicked={handleItemClicked} key={index} item={item} />
          ))}
        </Grid>
      </View>
    </View>
  );
};
