import React from "react";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import { Hidden } from "@material-ui/core";
import { NavigationManager } from "utils/navigation";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum } from "DLUI/button/dlButton";

interface ComponentProps {
  hideMarketingView?: boolean;
}

const MarketingView: React.FC<ComponentProps> = ({ hideMarketingView }: ComponentProps) => {
  const didPressVisitHelpCenter = () => {
    NavigationManager.visitHelpCenter();

    // if (window.Intercom) {
    //   window.Intercom("showNewMessage");
    // }
  };
  if (hideMarketingView) {
    return null;
  }
  return (
    <Hidden smDown>
      <View justifyContent={"center"} alignItems={"center"} height={"100%"}>
        <Text
          fontSize={70}
          color={"white"}
          value={AppStrings.Auth.PromoTextTitle}
          align={"center"}
          lineHeight={"inherit"}
          fontWeight={700}
        />
        <Text
          fontSize={70}
          color={"white"}
          value={AppStrings.Auth.PromoTextSubtitle}
          align={"center"}
          lineHeight={"inherit"}
        />
        <View width={80} height={10} backgroundColor={"pink"} marginTop={30} />
        <Text
          fontSize={24}
          color={"white"}
          value={AppStrings.Auth.PromoDescriptionText}
          maxWidth={550}
          align={"center"}
          lineHeight={"35px"}
          marginTop={30}
        />
        <DLButton
          onClick={didPressVisitHelpCenter}
          actionText={AppStrings.Auth.SendUsAMessage}
          color={DLButtonColorsEnum.NEUTRAL}
          size={DLButtonSizesEnum.LARGE}
          style={{ marginTop: 40 }}
        />
      </View>
    </Hidden>
  );
};

export default MarketingView;
