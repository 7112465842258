import type { TextColor } from "DLUI/text/text";
import Text from "DLUI/text/text";
import { Fragment, useMemo } from "react";
import { checkDaysLeft } from "@/utils/dateUtils";

interface DaysToProps {
  dueDate?: string;
  color?: TextColor;
  prefixText: string;
}

export function DaysCounter({ dueDate, color = "gray", prefixText }: DaysToProps) {
  const { daysCount } = useMemo(() => checkDaysLeft(dueDate), [dueDate]);

  return (
    <Fragment>
      <Text color={color} bold fontSize={14} marginTop={3} value={prefixText} />
      &nbsp;
      <Text color={color} bold fontSize={14} marginTop={3} formatType={"relative-time"} value={String(daysCount)} />
    </Fragment>
  );
}
