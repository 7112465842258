export default {
  signatureRequests: "Signature Requests",
  newSignatureRequest: "New Signature Request",
  outForSignature: "Out for signature",
  declined: "Declined",
  completed: "Completed",
  canceled: "Canceled",
  emptyDataViewInstructionsText: "No Signature Requests Found",
  generalSetting: "General Setting",
  nameAndDescription: "Name & Description",
  relatedRecords: "Related Records",
  documents: "Documents",
  document: "Document",
  signers: "Signers",
  reviewAndConfirm: "Review & Confirm",
  signatureRequestTitle: "Signature request title",
  signatureTemplateTitle: "Signature template title",
  sendRequestTestMode: "Send this request in test mode",
  relatedToDescription: "Which of the following is this signature request related to?",
  selectProperty: "Select Property",
  selectUnit: "Select Unit",
  selectLease: "Select Lease",
  selectDraftLease: "Select Draft Lease",
  whichDocumentsToIncluded: "Which documents should be included in the request?",
  uploadFiles: "Upload Files",
  useTemplate: "Use Template",
  selectFileToContinue: "Please select at least 1 delivery method to continue",
  whoNeedsToSign: "Who needs to sign?",
  addNewSigner: "Add New Signer",
  addNewSignerRole: "Add New Signer Role",
  addNewSignerDescription: "Please add one or more people who need to sign the documents",
  missingFilesValidationMessage: "You must upload a file before proceeding",
  missingTemplatesValidationMessage: "You must select a template before proceeding",
  missingSignersValidationMessage: "You must select signers before proceeding",
  missingSignerRoleValidationMessage: "At least one Signer Role is required",
  reviewAndConfirmDescription:
    "Please review the details bellow, press the next button once you are ready to send the Signature Request.",
  chargeAndCredit: "eSignature Credits",
  communicationCreditNeeded: "Credits Needed",
  yourCurrentCredit: "Credits Available",
  currentCreditUsageDescription: "{{x}} eSignature {{c}} will be used. You have {{y}} {{l}} left.",
  currentCreditUsageIncludedInPlanDescription: "Your plan benefits include no fees for this feature.",
  helloSignErrorDescription: "Something went wrong! Please try again. Error code",
  chargeForCreditsDescription: "You will be charged ${{x}} for {{y}} more credits to send this document.",
  signersToSendDescription: "This signature request will be sent for signature to the following people:",
  signatureRequestsSent: " Your eSignature request has been Sent",
  signatureRequestsSentDescription: "You will be notified once signed!",
  documentTitle: "Document Title",
  download: "Download",
  cancelRequest: "Cancel Request",
  previewSignersListDescription: "This signature request will be sent for signature to the following people:",
  sendReminder: "Send Reminder",
  lastReminderSentAt: "Last Reminder Sent At",
  signedAt: "Signed At {{x}}",
  viewedAt: "Last Viewed At {{x}}",
  sentAt: "Sent At {{x}}",
  signed: "Signed",
  setSignerOrder: "Set signer order",
  deleteSignatureRequestConfirmationText: "Are you sure you want to delete the signature request?",
  deleteSignatureTemplateConfirmationText: "Are you sure you want to delete the signature template?",
  cancelSignatureRequestConfirmationText: "Are you sure you want to cancel the signature request?",
  signatureRequest: "Signature Request",
  signatureRequestNewLeaseAgreementTitle: "New Lease Agreement",
  signatureRequestLeaseRenewalAgreementTitle: "Lease Renewal Agreement",
  credit: "credit",
  credits: "credits",
  learnMore: "Learn more",
  learnMoreESigTemplate: "Learn more about creating eSignature templates",
  signatureTemplates: "Signature Templates",
  newSignatureTemplates: "New Signature Template",
  templatesEmptyDataViewInstructionsText: "No Signature Templates Found ",
  signatureRequestGeneralSettingsDescription: "e-Signature requests are delivered in partnership with HelloSign.com",
  signatureTemplateGeneralSettingsDescription: "e-Signature templates are delivered in partnership with HelloSign.com",
  whichDocumentsToIncludedForTemplate: "Which documents should be included in this template?",
  dropzoneInstructions: "Explore our selection of templates for signatures and requests.",
  signatureTemplateCreated: "Your Signature Template has been Created",
  signatureTemplateCreatedDescription: "You can now use this template on your signature requests",
  templates: "Templates",
  addTemplate: "Add Template",
  addTemplateDescription: "Press the Add Template button in order to select templates",
  templateSignerRoles: "Template Signer Roles",
  templateSignerRolesDescription:
    "You selected one or more templates which require you to select the following signers",
  selectSignerValidationMessage: "Add a signer to this role",
  duplicateEmailAddressValidationMessage: "Signer emails must be unique. Please review and try again.",
  editSignatureTemplate: "Edit Signature Template",
  editSignatureTemplateNoIdFoundMessage: "Invalid Template Id",
  duplicateSelectionErrorMessage: "Signers list must be unique. Please select another signer",
  eSigCredit: "eSignature Credit",
  helpPanel: {
    setupTemplate: "Setting up your eSignature Templates",
    createSignature: "Creating eSignature requests",
    templateDescription: "Create document templates you always send out for signatures.",
    templateDialogTitle: " eSignature Templates",
    requestDescription: "Get all of your documents signed electronically, like lease agreements.",
    requestDialogTitle: "eSignature Requests"
  }
};
