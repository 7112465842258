import type { TextColor } from "DLUI/text/text";
import clsx from "clsx";
import { useTypedTranslation } from "locale";
import Text from "DLUI/text";
import { useGeneralStyles } from "styles/generalStyles";
import type { LateFeesPolicyDto } from "@doorloop/dto";
import { DataCy } from "@doorloop/dto";
import { useSampleCalculator } from "DLUI/dialogs/settings/common/lateFees/sampleCalculator/useSampleCalculator";
import sum from "lodash/sum";

interface SubtotalSectionProps {
  rent: number;
  delinquency: number;
  lateFeeSettings: LateFeesPolicyDto;
  textColor: TextColor;
}

export const SubtotalSection: React.FC<SubtotalSectionProps> = ({ rent, delinquency, lateFeeSettings, textColor }) => {
  const { t } = useTypedTranslation();
  const generalClasses = useGeneralStyles();

  const { maxDailyLateFees, maxMonthlyLateFees, maxDailyLateFeesAmount, maxMonthlyLateFeesAmount } = lateFeeSettings;

  const {
    totalChargesBeforeMaxFees,
    totalChargesPerDayWithMaxDailyFees,
    totalChargesPerMonthWithMaxMonthlyFees,
    chargesPerMaximumDayAndMonthFees
  } = useSampleCalculator({ rent, delinquency, lateFeeSettings });

  const dailyFeesReduction = Math.max(totalChargesBeforeMaxFees - totalChargesPerDayWithMaxDailyFees, 0) * -1;
  const monthlyFeesReduction =
    (maxDailyLateFees
      ? Math.max(totalChargesPerDayWithMaxDailyFees - sum(chargesPerMaximumDayAndMonthFees), 0)
      : Math.max(totalChargesBeforeMaxFees - totalChargesPerMonthWithMaxMonthlyFees, 0)) * -1;

  return (
    <div className={clsx(generalClasses.flexColumn, generalClasses.largeGap)}>
      <div className={clsx(generalClasses.flexRow, generalClasses.spaceBetween)}>
        <Text value={t("settings.generalSettings.subtotal")} fontSize={12} fontWeight={700} color={textColor} />
        <Text
          value={totalChargesBeforeMaxFees.toString()}
          formatType={"currency"}
          fontSize={12}
          fontWeight={700}
          color={textColor}
          dataCy={DataCy.globalSettings.lateFees.sampleCalculator.subtotal.amountBeforeReduction}
        />
      </div>
      {maxDailyLateFees && (
        <div className={clsx(generalClasses.flexRow, generalClasses.spaceBetween)}>
          <Text
            value={`Daily Maximum Fees ($${maxDailyLateFeesAmount ?? 0})`}
            fontSize={12}
            fontWeight={700}
            color={textColor}
          />
          <Text
            value={dailyFeesReduction.toString()}
            fontSize={12}
            formatType={"currency"}
            fontWeight={700}
            color={textColor}
            dataCy={DataCy.globalSettings.lateFees.sampleCalculator.subtotal.dailyReduction}
          />
        </div>
      )}
      {maxMonthlyLateFees && (
        <div className={clsx(generalClasses.flexRow, generalClasses.spaceBetween)}>
          <Text
            value={`Monthly Maximum Fees ($${maxMonthlyLateFeesAmount ?? 0})`}
            fontSize={12}
            fontWeight={700}
            color={textColor}
          />
          <Text
            value={monthlyFeesReduction.toString()}
            fontSize={12}
            formatType={"currency"}
            fontWeight={700}
            color={textColor}
            dataCy={DataCy.globalSettings.lateFees.sampleCalculator.subtotal.monthlyReduction}
          />
        </div>
      )}
    </div>
  );
};
