import { ServerRoutes } from "@doorloop/dto";
import type { GetAllLeaseCreditsQuery, LeaseCreditDto } from "@doorloop/dto";
import { RestApiBase } from "./restApiBase";

export class LeaseCreditApi extends RestApiBase<LeaseCreditDto, GetAllLeaseCreditsQuery> {
  constructor() {
    super(ServerRoutes.LEASE_CREDITS, []);
    this.restRoute = ServerRoutes.LEASE_CREDITS;
  }
}

export const leaseCreditApi = new LeaseCreditApi();
