import type { AddressDto, EmailDto, PhoneDto } from "@doorloop/dto";

import AppStrings from "locale/keys";
import type { GridColumn } from "DLUI/lists/gridList/gridList";

export const getAddressText = (address?: AddressDto): string => {
  const { city, state, zip, country, street1 } = address || {};

  return "".concat(
    street1 || "",
    city ? ", " + city : "",
    state ? ", " + state : "",
    country ? ", " + country : "",
    zip ? ", " + zip : ""
  );
};

export const getPersonGridColumns = (
  personObjectName?: "tenant" | undefined,
  footerCell?: any,
  groupFooterCell?: any
): GridColumn[] => [
  {
    field: personObjectName ? personObjectName + ".fullName" : "fullName",
    title: AppStrings.Tenants.NewTenant.EmergencyContactName,
    show: true,
    footerCell,
    groupFooterCell
  },
  {
    field: personObjectName ? personObjectName + ".firstName" : "firstName",
    title: AppStrings.Tenants.NewTenant.FirstName,
    show: false
  },
  {
    field: personObjectName ? personObjectName + ".lastName" : "lastName",
    title: AppStrings.Tenants.NewTenant.LastName,
    show: false
  },
  {
    field: personObjectName ? personObjectName + ".middleName" : "middleName",
    title: AppStrings.Tenants.NewTenant.MiddleName,
    show: false
  },
  {
    field: personObjectName ? personObjectName + ".companyName" : "companyName",
    title: AppStrings.Tenants.NewTenant.Company,
    show: true
  },
  {
    field: personObjectName ? personObjectName + ".jobTitle" : "jobTitle",
    title: AppStrings.Tenants.NewTenant.JobTitle,
    show: false
  },
  {
    field: personObjectName ? personObjectName + ".notes" : "notes",
    title: AppStrings.Common.ListHeader.Memo,
    show: false
  },
  {
    field: personObjectName ? personObjectName + ".dateOfBirth" : "dateOfBirth",
    title: AppStrings.Tenants.NewTenant.DateOfBirth,
    show: false
  },
  {
    field: personObjectName ? personObjectName + ".primaryAddress" : "primaryAddress",
    title: AppStrings.Tenants.NewTenant.Address,
    show: true,
    translate: (address: AddressDto) => getAddressText(address)
  },
  {
    field: personObjectName ? personObjectName + ".alternateAddress" : "alternateAddress",
    title: AppStrings.Tenants.NewTenant.AlternateAddress,
    show: false,
    translate: (address: AddressDto) => getAddressText(address)
  },
  {
    field: personObjectName ? personObjectName + ".emails" : "emails",
    title: AppStrings.Tenants.NewTenant.Email,
    show: true,
    translate: (cellValue: string[]) => {
      const _cellValue = cellValue as EmailDto[];
      const cellNextValue: string[] = [];
      _cellValue.forEach((currentMail) => {
        if (currentMail.address && currentMail.address !== "") {
          cellNextValue.push(currentMail.address);
        }
      });

      return cellNextValue.join(", ");
    }
  },
  {
    field: personObjectName ? personObjectName + ".phones" : "phones",
    title: AppStrings.Tenants.NewTenant.Phone,
    show: true,
    translate: (cellValue: string[]) => {
      const _cellValue = cellValue as PhoneDto[];
      const cellNextValue: string[] = [];
      _cellValue.forEach((currentPhone) => {
        if (currentPhone.number && currentPhone.number !== "") {
          cellNextValue.push(currentPhone.number);
        }
      });

      return cellNextValue.join(", ");
    }
  }
];
