import React from "react";
import { BackgroundImageView, View } from "DLUI/view";
import { Grid } from "@material-ui/core";
import { FastField } from "formik";
import { FormikSwitchButton } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { useTypedTranslation } from "locale";
import { DataCy } from "@doorloop/dto";
import type { Syndication } from "screens/settings/listingSyndication/listingSyndication.constant";

interface Props {
  onItemPress?: () => void;
  syndicationItem: Syndication;
}

const ListingSyndicationItemMobile: React.FC<Props> = ({ onItemPress, syndicationItem }: Props) => {
  const { images, key, title, imageSize } = syndicationItem;
  const { isMobile } = useResponsiveHelper();
  return (
    <View flexDirection={"row"}>
      <View minHeight={50} alignItems={"center"} flexDirection={"row"} onClick={onItemPress}>
        {images?.map((url, index) => (
          <View autoWidth alignItems={"center"} marginRight={20} key={index}>
            <BackgroundImageView
              publicFileName={`url(${url})`}
              height={40}
              width={imageSize ? imageSize : 80}
              backgroundPosition={"center"}
              backgroundSize={"contain"}
            />
          </View>
        ))}
        {!images && title ? <Text value={title || ""} /> : null}
      </View>
      <View flexDirection={"row"}>
        <View minHeight={50} flexDirection={"row"} onClick={onItemPress} alignItems={"center"} autoWidth>
          <FastField component={FormikSwitchButton} name={key} />
          {!isMobile && <SeparationLine marginRight={20} width={2} height={30} />}
        </View>
      </View>
    </View>
  );
};

const ListingSyndicationItem: React.FC<Props> = ({ onItemPress, syndicationItem }: Props) => {
  const { images, key, title, imageSize, comingSoon, description, partnerUrl } = syndicationItem;
  const { isMobile } = useResponsiveHelper();
  const { t } = useTypedTranslation();

  const didPressPartnerImage = () => {
    window.open(partnerUrl, "_blank");
  };
  return (
    <View flexDirection={"row"} alignItems={"center"}>
      <Grid container item xs={9}>
        <View alignItems={"center"} flexDirection={"row"} onClick={onItemPress} noWrap>
          {images?.map((url, index) => (
            <View marginRight={16} key={index} autoWidth>
              <BackgroundImageView
                publicFileName={`url(${url})`}
                height={32}
                width={imageSize || 32}
                onClick={didPressPartnerImage}
              />
            </View>
          ))}
          <View noWrap gap={3}>
            {title && (
              <Text
                bold
                fontSize={isMobile ? 16 : 14}
                value={t(title)}
                as={"label"}
                style={{ cursor: "pointer", flex: 1 }}
                htmlFor={key}
              />
            )}
            {description && <Text fontSize={12} color={"secondary-gray"} value={t(description)} htmlFor={key} />}
          </View>
        </View>
      </Grid>

      <Grid container item xs={3}>
        <View
          flexDirection={"row"}
          onClick={onItemPress}
          justifyContent={"flex-end"}
          fullWidth
          alignItems={"center"}
          autoWidth
        >
          <FastFieldSafe
            component={FormikSwitchButton}
            name={key}
            id={key}
            dataCy={DataCy.globalSettings.listingSyndication.switchButton}
          />
        </View>
      </Grid>
    </View>
  );
};

export default ListingSyndicationItem;
