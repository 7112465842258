import { ObjectPermission } from "@doorloop/dto";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import BulkTransaction from "DLUI/dialogs/transactions/bulkTransaction";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { ArticleIdsEnum, HelpTypeEnum, VideoUrlsEnum } from "DLUI/screen/helpPanel/types";
import AppStrings from "locale/keys";
import React from "react";
import { useUserType } from "../../../../../hooks/useUserType";

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitleActions?: React.ReactNode;
}

const helpObject: HelpPanelProps = {
  description: AppStrings.Leases.LeaseCharge.IssueCreditHelpPanel.Description,
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Leases.LeaseCharge.IssueCreditHelpPanel.Create,
      articleId: ArticleIdsEnum.ISSUE_LEASE_CREDIT
    },
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Leases.LeaseCharge.IssueCreditHelpPanel.EditDelete,
      articleId: ArticleIdsEnum.EDIT_DELETE_LEASE_TRANSACTIONS
    },
    {
      type: HelpTypeEnum.WATCH_VIDEO,
      topic: AppStrings.Common.WatchExample,
      href: VideoUrlsEnum.LEASE_OVERVIEW,
      dialogTitle: AppStrings.Leases.LeaseCharge.IssueCreditHelpPanel.DialogTitle,
      startTime: 224
    }
  ]
};

const helpObjectHOA: HelpPanelProps = {
  description: AppStrings.Leases.LeaseCharge.IssueCreditHelpPanel.Description,
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Leases.LeaseCharge.IssueCreditHelpPanel.Create,
      articleId: ArticleIdsEnum.HOA_ISSUE_CREDIT
    },
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Leases.LeaseCharge.IssueCreditHelpPanel.EditDelete,
      articleId: ArticleIdsEnum.HOA_EDIT_OR_DELETE_CREDIT
    },
    {
      type: HelpTypeEnum.WATCH_VIDEO,
      topic: AppStrings.Common.WatchExample,
      href: VideoUrlsEnum.LEASE_OVERVIEW,
      dialogTitle: AppStrings.Leases.LeaseCharge.IssueCreditHelpPanel.DialogTitle,
      startTime: 224
    }
  ]
};

const BulkCreditDialog: React.FC<ComponentProps> = ({
  onBackdropClick,
  onClose,
  dialogTitleActions
}: ComponentProps) => {
  const { isHOAUser } = useUserType();
  return (
    <BulkTransaction
      onBackdropClick={onBackdropClick}
      onClose={onClose}
      dialogTitle={AppStrings.Leases.LeaseCredit.BulkIssueCredits}
      dialogTitleActions={dialogTitleActions}
      type={"credit"}
      dialogFrameWidth={getDialogFrameDimension("width", 1540)}
      dialogFrameHeight={getDialogFrameDimension("height", 1040)}
      permission={ObjectPermission.leaseCredits}
      helpObject={!isHOAUser ? helpObject : helpObjectHOA}
    />
  );
};

export default BulkCreditDialog;
