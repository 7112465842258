import _ from "lodash";

type Handler = (p: any) => void;
type EventMap<EVENTS extends string = string> = Record<EVENTS, Handler[]>;

export class EventBus<EVENTS extends string = string> {
  _events: EventMap<EVENTS>;

  constructor() {
    this._events = {} as EventMap<EVENTS>;
  }

  on(eventName: string, handler: Handler) {
    if (!this._events[eventName]) {
      this._events[eventName] = [handler];
    } else {
      this._events[eventName] = [..._.without(this._events[eventName], handler), handler];
    }
  }

  off(eventName: string, handler: Handler) {
    this._events[eventName] = _.without(this._events[eventName], handler);
  }

  trigger(eventName: string, ...params: any[]) {
    if (this._events[eventName]) {
      this._events[eventName].forEach((handler) => {
        handler(...params);
      });
    }
  }
}
