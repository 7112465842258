import { lazy } from "react";
import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";

const UsersListScreen = lazy(() => import("screens/users/usersList/usersListScreen"));

const UsersRouter = () => (
  <PageRouter>
    <LazyRoute exact path={Routes.EDIT_USER} render={UsersListScreen} />
    <LazyRoute path={Routes.USERS} render={UsersListScreen} />
  </PageRouter>
);

export default UsersRouter;
