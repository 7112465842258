export default {
  requests: {
    dialogTitle: "Requests",
    dialog: {
      newRequest: "New Request",
      viewRequest: "View Open Request",
      subjectInput: "Subject",
      subjectLabel: "What is this request about?",
      descriptionLabel: "Please provide a detailed description of your request",
      descriptionInput: "Description"
    }
  }
};
