import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { TextField } from "DLUI/form";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import { useManagementFeesStyles } from "screens/settings/managementFees/useStyles";
import { useTypedTranslation } from "locale";
import clsx from "clsx";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { useGeneralStyles } from "styles/generalStyles";
import { EXPENSE_QUERY_PARAMS } from "screens/settings/managementFees/formElements/utils";
import { DataCy } from "@doorloop/dto";

export const MinimumFeeForm = ({ formikName, defaultExpenseAccount }) => {
  const classes = useManagementFeesStyles();
  const generalClasses = useGeneralStyles();
  const { t } = useTypedTranslation();
  const { isMobile } = useResponsiveHelper();

  return (
    <div className={classes.minimumFeeInputs} data-cy={DataCy.globalSettings.managementFees.minimumFee.container}>
      <div className={clsx(isMobile ? generalClasses.basicFlex : classes.feeInputWidth)}>
        <FastFieldSafe
          component={TextField}
          label={t("settings.generalSettings.managementFees.minimumFee")}
          name={`${formikName}.feeAmount`}
          required
          formatType={"currency"}
          dataCy={DataCy.globalSettings.managementFees.minimumFee.minimumFeeInput}
        />
      </div>
      <div className={generalClasses.basicFlex}>
        <BankAccountFormikAutoCompleteField
          addCreateOption
          uniqueIndex={"TS"}
          name={`${formikName}.expenseAccount`}
          queryParams={EXPENSE_QUERY_PARAMS}
          dataCy={DataCy.globalSettings.managementFees.minimumFee.expenseAccountSelect}
          label={t("settings.generalSettings.managementFees.expenseCategory")}
          defaultValue={defaultExpenseAccount}
        />
      </div>
    </div>
  );
};
