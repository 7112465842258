import React from "react";
import { CheckBoldIconCircle, InformationIcon, ScrollIcon, SettingsCogIcon } from "assets/icons";
import type { MenuItem } from "DLUI/dialogs";
import { UserRoleObjects } from "./components/objects/objects";
import { UserRoleReports } from "./components/reports/reports";
import { UserRoleSettings } from "./components/settings/settings";
import { GeneralInfo } from "./components/generalInfo/generalInfo";
import AppStrings from "locale/keys";

export const getUserRoleFormMenuItems = (): MenuItem[] => [
  {
    title: AppStrings.UserRoles.GeneralInfo,
    icon: InformationIcon,
    contentComponent: () => <GeneralInfo />
  },
  {
    title: AppStrings.UserRoles.Objects,
    icon: CheckBoldIconCircle,
    contentComponent: () => <UserRoleObjects />
  },
  {
    title: AppStrings.UserRoles.Reports,
    icon: ScrollIcon,
    contentComponent: () => <UserRoleReports />
  },
  {
    title: AppStrings.UserRoles.Settings,
    icon: SettingsCogIcon,
    contentComponent: () => <UserRoleSettings />
  }
];
