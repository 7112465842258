import type { CSSProperties } from "react";
import React, { useEffect, useMemo, useState } from "react";
import makeStyles from "../styles";
import clsx from "clsx";
import type { MenuItem } from "DLUI/dialogs";
import { animated, useSpring } from "react-spring";
import Text from "DLUI/text";
import { useTranslation } from "react-i18next";
import type { FormikProps } from "formik";
import { Formik, getIn } from "formik";
import { useLocation } from "react-router-dom";
import { Icon } from "DLUI/icon";
import type { HasTenantsAutoPayments, MerchantAccountDto } from "@doorloop/dto";
import { AccountType } from "@doorloop/dto";
import { View } from "DLUI/view";
import AnimatedContentMobileLayout from "DLUI/dialogs/components/animatedContent.mobile.layout";
import { Link } from "DLUI/link";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { TextTooltip } from "DLUI/popover/textTooltip";
import AppStrings from "../../../../locale/keys";
import { ExclamationBlueIcon } from "../../../../assets";
import { DIALOG_HEADER_HEIGHT } from "DLUI/dialogs/components/dialogFrame";
import _ from "lodash";
import { useAnalyticsService } from "../../../../hooks/useAnalyticsService";
import { DLTheme } from "styles/defaultTheme";

const MenuItemHeight = 39;

const stickyStyle: CSSProperties = {
  position: "sticky",
  bottom: 0,
  left: 0,
  backgroundColor: DLTheme.palette.screenBackground.main,
  height: 68,
  justifyContent: "center",
  zIndex: 2
};

export interface AnimatedContentProps {
  sectionItems: MenuItem[];
  formikInitialValues: () => any;
  formikValidation?: (values: any) => any;
  dialogHeight: number;
  renderActionPanelButtons: React.FC<FormikProps<any>>;
  onMenuItemSelection?: (menuItemTitle: string, currentSelectedMenuIndex?: number) => void;
  onFileReceive?: (receivedFile: File) => void;
  onFileDelete?: () => void;
  onTenantPortalLogoFileReceive?: (receivedFile: File) => void;
  onTenantPortalLogoFileDelete?: () => void;
  onCoverImageFileReceive?: (receivedFile: File) => void;
  onCoverImageFileDelete?: () => void;
  selectedMenuIndex?: number;
  type?: "newAccount";
  merchantAccountData?: MerchantAccountDto;
  transunionEligibilityMessage?: string;
  transunionFee?: number;
  landlordId?: number;
  transunionPropertyId?: number;
  hasTenantsAutoPayments?: HasTenantsAutoPayments;
  stickyActionButtons?: boolean;
  onChangeSelectedItem?: (index: number) => void;
  mobileScrollToSelectedMenuItem?: boolean;
  menuItemsStyle?: CSSProperties;
  removeScrollOnMobile?: boolean;
}

let formikGlobalRef: FormikProps<any> | null = null;

export const getAnimatedContentFormikRef = <DTO extends unknown = any>(): FormikProps<DTO> =>
  formikGlobalRef as FormikProps<DTO>;

const AnimatedContent: React.FC<AnimatedContentProps> = ({
  sectionItems,
  formikInitialValues,
  formikValidation,
  dialogHeight,
  renderActionPanelButtons,
  onMenuItemSelection,
  onFileReceive,
  onFileDelete,
  selectedMenuIndex,
  type,
  onTenantPortalLogoFileReceive,
  onTenantPortalLogoFileDelete,
  onCoverImageFileReceive,
  onCoverImageFileDelete,
  merchantAccountData,
  transunionEligibilityMessage,
  transunionFee,
  landlordId,
  transunionPropertyId,
  hasTenantsAutoPayments,
  stickyActionButtons,
  onChangeSelectedItem,
  mobileScrollToSelectedMenuItem,
  menuItemsStyle,
  removeScrollOnMobile
}: AnimatedContentProps) => {
  const classes = makeStyles();
  const { isMobile, screenContainerPadding } = useResponsiveHelper();
  const { t } = useTranslation();
  const location = useLocation<any>();

  const [menuItems, setMenuItems] = useState<MenuItem[]>(sectionItems);

  const getInitialSelectedTab = () => {
    let selectedTabIndex: number = menuItems.findIndex((item) => item.isSelected);

    if (selectedTabIndex === -1) {
      const currentPathParts = location.pathname.split("/");
      const selectedTabName = _.last(currentPathParts)?.replace(/\s/g, "") || "";

      menuItems.forEach((currentItem, index) => {
        if (t(currentItem.title).replace(/\s/g, "").toLocaleLowerCase() === selectedTabName.toLocaleLowerCase()) {
          selectedTabIndex = index;
        }
      });

      if (selectedTabIndex === -1) {
        selectedTabIndex = 0;
      }
    }
    return selectedTabIndex;
  };

  const [currentSelectedMenuIndex, setCurrentSelectedMenuIndex] = useState<number>(getInitialSelectedTab());

  const [lastSelectedMenuIndex, setLastSelectedMenuIndex] = useState<number>(0);
  const [pendingFile, setPendingFile] = useState<File | undefined>();

  const selectionIndicatorAnimationProps = useSpring({
    top: currentSelectedMenuIndex * MenuItemHeight,
    from: { top: lastSelectedMenuIndex * MenuItemHeight }
  });

  useEffect(() => {
    if (selectedMenuIndex !== undefined) {
      setCurrentSelectedMenuIndex(selectedMenuIndex);
    }
  }, [selectedMenuIndex]);

  useEffect(() => {
    if (onMenuItemSelection) {
      onMenuItemSelection(menuItems[currentSelectedMenuIndex].title, currentSelectedMenuIndex);
    }
  }, [currentSelectedMenuIndex]);

  const CurrentMenuItemIcon = menuItems[currentSelectedMenuIndex].icon;
  const currentMenuItemTitle = menuItems[currentSelectedMenuIndex].title;

  const indicatorContent = useMemo(() => {
    if (isMobile) {
      return <CurrentMenuItemIcon className={classes.menuItemIconSelectedMobile} />;
    }
  }, [CurrentMenuItemIcon, currentMenuItemTitle]);

  const renderMenuItemLabel = (currentItem: any, index: number) => {
    if (isMobile) {
      return null;
    }
    return (
      <div
        style={{
          paddingRight: currentItem?.isMandatory ? 0 : 40
        }}
      >
        <div style={{ display: "flex" }}>
          <Text
            removeDefaultPadding
            fontSize={14}
            color={"white"}
            value={currentItem.menuItemTitle || currentItem.title}
          />
          {currentItem?.isMandatory && (
            <View marginLeft={2} autoWidth>
              <TextTooltip value={AppStrings.Common.RentalApplicationMandatorySettingsSection} maxWidth={200}>
                <View flexDirection={"row"} alignItems={"center"} noWrap autoWidth>
                  <Text value={AppStrings.Common.Mandatory} fontSize={10} color={"secondary-yellow"} />
                  <Icon Source={ExclamationBlueIcon} pathColor={"secondary-yellow"} size={12} marginLeft={1.5} />
                </View>
              </TextTooltip>
            </View>
          )}
        </div>
      </div>
    );
  };

  const { dispatchAnalytics, formatAppStringsValue } = useAnalyticsService();

  const _didPressMenuItem = (_menuItems: MenuItem[], index: number) => {
    const nextMenuItems = [..._menuItems];
    let currentSelectedMenuIndex = 0;
    let lastSelectedMenuIndex = 0;
    const menuItem = nextMenuItems[index];

    dispatchAnalytics("dialog_menu_item_clicked", {
      label: formatAppStringsValue(menuItem.title)
    });

    _menuItems.forEach((currentItem, currentIndex) => {
      if (currentItem.isSelected) {
        lastSelectedMenuIndex = currentIndex;
      }
      if (currentIndex === index) {
        currentSelectedMenuIndex = currentIndex;
      }
      currentItem.isSelected = currentIndex === index;
    });

    setMenuItems(nextMenuItems);
    setCurrentSelectedMenuIndex(currentSelectedMenuIndex);
    setLastSelectedMenuIndex(lastSelectedMenuIndex);
    onChangeSelectedItem?.(currentSelectedMenuIndex);
  };

  const renderMenuItems = (formik: FormikProps<any>) => {
    const _menuItems = getMenuItems(formik);
    return _menuItems.map((currentItem: MenuItem, index: number) => (
      <div
        onClick={() => _didPressMenuItem(_menuItems, index)}
        className={clsx([classes.menuItemContainer])}
        key={"MI" + index}
      >
        <Link hoverColor={"white"} underline={"hover"} className={classes.menuItemLink} width={"100%"}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              position: "relative",
              zIndex: currentSelectedMenuIndex === index ? 9 : undefined
            }}
          >
            <Icon
              Source={currentItem.icon}
              width={16}
              height={16}
              marginLeft={10}
              marginRight={8}
              pathColor={"white"}
            />
            {renderMenuItemLabel(currentItem, index)}
          </div>
        </Link>
      </div>
    ));
  };

  const _onFileReceive = (receivedFile: File) => {
    setPendingFile(receivedFile);
    if (onFileReceive) {
      onFileReceive(receivedFile);
    }
  };

  const _onFileDelete = () => {
    setPendingFile(undefined);
    if (onFileDelete) {
      onFileDelete();
    }
  };

  const _onTenantPortalLogoFileReceive = (receivedFile: File) => {
    if (onTenantPortalLogoFileReceive) {
      onTenantPortalLogoFileReceive(receivedFile);
    }
  };
  const _onTenantPortalLogoFileDelete = () => {
    if (onTenantPortalLogoFileDelete) {
      onTenantPortalLogoFileDelete();
    }
  };
  const _onCoverImageFileReceive = (receivedFile: File) => {
    if (onCoverImageFileReceive) {
      onCoverImageFileReceive(receivedFile);
    }
  };
  const _onCoverImageFileDelete = () => {
    if (onCoverImageFileDelete) {
      onCoverImageFileDelete();
    }
  };

  const initialValues = formikInitialValues();

  const showOpeningBalance = (accountType) =>
    [
      AccountType.ASSET_BANK,
      AccountType.ASSET_ACCOUNTS_RECEIVABLE,
      AccountType.ASSET_FIXED_ASSETS,
      AccountType.ASSET_OTHER_ASSETS,
      AccountType.ASSET_OTHER_CURRENT_ASSETS,
      AccountType.LIABILITY_CREDIT_CARD,
      AccountType.LIABILITY_ACCOUNTS_PAYABLE,
      AccountType.LIABILITY_LONG_TERM_LIABILITY,
      AccountType.LIABILITY_OTHER_CURRENT_LIABILIY,
      AccountType.EQUITY_EQUITY
    ].includes(accountType);

  const getMenuItems = (formikRef: FormikProps<any>) => {
    if (type && type === "newAccount") {
      const accountType: AccountType | undefined = getIn(formikRef.values, "type");
      const _menuItems = [sectionItems[0]];
      if (accountType && showOpeningBalance(accountType)) {
        _menuItems.push(sectionItems[1]);
      }
      _menuItems.push(sectionItems[2]);
      _menuItems.push(sectionItems[3]);
      return _menuItems;
    }
    return sectionItems;
  };

  const stickyActionButtonsStyle: CSSProperties | undefined = stickyActionButtons ? stickyStyle : undefined;

  const renderMenuViews = (formik: FormikProps<any>) => {
    const _menuItems = getMenuItems(formik);
    return _menuItems.map((currentItem: MenuItem, index: number) => {
      let animationClass;
      if (currentSelectedMenuIndex === index) {
        animationClass = classes.enter;
      } else if (lastSelectedMenuIndex === index) {
        animationClass = classes.leave;
        if (currentSelectedMenuIndex < lastSelectedMenuIndex) {
          animationClass = classes.enter;
        }
      } else if (currentSelectedMenuIndex > index) {
        animationClass = classes.startBottom;
      } else {
        animationClass = classes.startTop;
      }

      const currentView = currentItem.contentComponent(
        formik,
        _onFileReceive,
        _onFileDelete,
        _onTenantPortalLogoFileReceive,
        _onTenantPortalLogoFileDelete,
        _onCoverImageFileReceive,
        _onCoverImageFileDelete,
        merchantAccountData,
        transunionEligibilityMessage,
        transunionFee,
        hasTenantsAutoPayments,
        landlordId,
        transunionPropertyId
      );

      return (
        <div
          className={clsx([classes.contentViewWrapper, animationClass])}
          key={"VI" + index}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000 - index,
            height: dialogHeight - DIALOG_HEADER_HEIGHT,
            ...menuItemsStyle
          }}
        >
          <div style={currentItem.contentComponentParentStyle}>
            {animationClass === classes.leave || (animationClass === classes.enter && currentView)}
          </div>
          <View style={stickyActionButtonsStyle} height={80}>
            {renderActionPanelButtons(formikGlobalRef!)}
          </View>
        </div>
      );
    });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={() => {}} validate={formikValidation}>
      {(formik) => {
        formikGlobalRef = formik;
        if (isMobile) {
          return (
            <AnimatedContentMobileLayout
              formik={formik}
              sectionItems={getMenuItems(formik)}
              onFileReceive={_onFileReceive}
              onTenantPortalLogoFileReceive={_onTenantPortalLogoFileReceive}
              onCoverImageFileReceive={_onCoverImageFileReceive}
              onFileDelete={_onFileDelete}
              onTenantPortalLogoFileDelete={_onTenantPortalLogoFileDelete}
              onCoverImageFileDelete={_onCoverImageFileDelete}
              merchantAccountData={merchantAccountData}
              transunionEligibilityMessage={transunionEligibilityMessage}
              transunionFee={transunionFee}
              hasTenantsAutoPayments={hasTenantsAutoPayments}
              renderActionPanelButtons={renderActionPanelButtons}
              landlordId={landlordId}
              transunionPropertyId={transunionPropertyId}
              selectedMenuIndex={selectedMenuIndex}
              stickyActionButtonsStyle={stickyActionButtonsStyle}
              onChangeSelectedItem={onChangeSelectedItem}
              mobileScrollToSelectedMenuItem={mobileScrollToSelectedMenuItem}
              menuItemsStyle={menuItemsStyle}
              removeScroll={removeScrollOnMobile}
            />
          );
        }
        return (
          <div className={classes.dialogContainer}>
            <div className={classes.dialogContentContainer}>
              <div className={classes.navigationBarContainer}>
                <View height={dialogHeight - 100} overflow={"scroll"}>
                  <div
                    className={classes.itemsWrapper}
                    style={{
                      height: (sectionItems.length + 1) * MenuItemHeight
                    }}
                  >
                    <animated.div className={classes.selectionIndicator} style={selectionIndicatorAnimationProps}>
                      <div className={clsx([classes.selectionIndicatorContainer])}>{indicatorContent}</div>
                    </animated.div>
                    {renderMenuItems(formik)}
                  </div>
                </View>
              </div>
              <div
                style={{
                  paddingRight: screenContainerPadding,
                  paddingLeft: screenContainerPadding
                }}
                className={classes.viewsContainer}
              >
                <div className={classes.animatedContentDialogWrapper}>{renderMenuViews(formik)}</div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default AnimatedContent;
