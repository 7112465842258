const Properties = {
  Screen: {
    Property: "properties.screen.property",
    EmptydataViewInstructionsText: "properties.screen.emptydataViewInstructionsText",
    EmptydataAddNew: "properties.screen.emptydataAddNew",
    NewPropertyStep1label: "properties.screen.newPropertyStep1label",
    NewPropertyStep2label: "properties.screen.newPropertyStep2label",
    NewPropertyStep3label: "properties.screen.newPropertyStep3label",
    NewPropertyStep4label: "properties.screen.newPropertyStep4label",
    NewPropertyStep5label: "properties.screen.newPropertyStep5label",
    NewPropertyStep6label: "properties.screen.newPropertyStep6label",
    ChoosePropertyTypelabel: "properties.screen.choosePropertyTypelabel",
    ChoosePropertySubTypelabel: "properties.screen.choosePropertySubTypelabel",
    LoadingProperties: "properties.screen.loadingProperties",
    CreatingNewProperty: "properties.screen.creatingNewProperty",
    CreatingNewPropertyFailed: "properties.screen.creatingNewPropertyFailed",
    CreatingNewPropertySuccess: "properties.screen.creatingNewPropertySuccess",
    SearchPaneltitle: "properties.screen.searchPaneltitle",
    SearchPlaceHolder: "properties.screen.searchPlaceHolder",
    AddNewProperty: "properties.screen.addNewProperty",
    WhatKindOfProperty: "properties.screen.whatKindOfProperty",
    MaintainReserveFunds: "properties.screen.maintainReserveFunds",
    WhoOwnsProperty: "properties.screen.whoOwnsProperty",
    SetPropertyReserve: "properties.screen.setPropertyReserve",
    DontSetPropertyReserve: "properties.screen.dontSetPropertyReserve",
    PropertyOwnedBySomeoneElse: "properties.screen.propertyOwnedBySomeoneElse",
    PropertyOwnedByMe: "properties.screen.propertyOwnedByMe",
    ChoosePropertyOwnersText: "properties.screen.choosePropertyOwnersText",
    VacantUnits: "properties.screen.vacantUnits",
    ListedUnits: "properties.screen.listedUnits",
    DeleteFileConfirmationText: "properties.screen.deleteFileConfirmationText",
    DeleteFileLoadingText: "properties.screen.deleteFileLoadingText",
    DeleteFileSuccessText: "properties.screen.deleteFileSuccessText",
    MakeInactiveFileConfirmationText: "properties.screen.makeInactiveFileConfirmationText",
    MakeActiveFileConfirmationText: "properties.screen.makeActiveFileConfirmationText",
    InvalidUnitsName: "properties.screen.invalidUnitsName",
    MultiFamilyInfo: "properties.screen.multiFamilyInfo"
  },
  PropertiesListItem: {
    Unitslabel: "properties.propertiesListItem.unitslabel",
    Unitlabel: "properties.propertiesListItem.unitlabel",
    OccupiedLabel: "properties.propertiesListItem.occupiedLabel",
    EvictionLabel: "properties.propertiesListItem.evictionLabel"
  },
  Type: {
    Residential: "properties.type.residential",
    Commercial: "properties.type.commercial"
  },
  ListHeader: {
    Property: "properties.listHeader.property",
    Type: "properties.listHeader.type",
    Status: "properties.listHeader.status",
    ActiveUnits: "properties.listHeader.activeUnits",
    CashBasis: "properties.listHeader.cashBasis",
    AccrualBasis: "properties.listHeader.accrualBasis",
    FlatFee: "properties.listHeader.flatFee",
    MinimumFee: "properties.listHeader.minimumFee",
    PayoutAccounts: "properties.listHeader.payoutAccounts"
  },
  AvailabilityForApplications: {
    Available: "properties.availabilityForApplications.available",
    Unavailable: "properties.availabilityForApplications.unavailable"
  },
  Status: {
    Active: "properties.status.active",
    Inactive: "properties.status.inactive"
  },
  Subtype: {
    Industrial: "properties.subType.industrial",
    Office: "properties.subType.office",
    Retail: "properties.subType.retail",
    ShoppingCenter: "properties.subType.shoppingCenter",
    Storage: "properties.subType.storage",
    Parking: "properties.subType.parking",
    SingleFamily: "properties.subType.singleFamily",
    MultiFamily: "properties.subType.multiFamily",
    Condo: "properties.subType.condo",
    Townhome: "properties.subType.townhome",
    Other: "properties.subType.other",
    CoWorking: "properties.subType.coWorking"
  },
  Address: {
    InstructionsText: "properties.address.instructionsText",
    TitleText: "properties.address.titleText",
    UseThisAddressAnyway: "properties.address.useThisAddressAnyway",
    StreetAddress: "properties.address.streetAddress",
    AcceptInvalidAddressWarningTitle: "properties.address.acceptInvalidAddressWarningTitle",
    AcceptInvalidAddressWarningDescription: "properties.address.acceptInvalidAddressWarningDescription",
    Labels: {
      Street1: "properties.address.labels.street1",
      Street2: "properties.address.labels.street2",
      City: "properties.address.labels.city",
      State: "properties.address.labels.state",
      Country: "properties.address.labels.country",
      ZipCode: "properties.address.labels.zipCode"
    }
  },
  Units: {
    InstructionsText: "properties.units.instructionsText",
    AddUnitsText: "properties.units.addUnitsText",
    AddUnitButtonLabel: "properties.units.addUnitButtonLabel"
  },
  BankAccounts: {
    Title: "properties.bankAccounts.title",
    Subtitle: "properties.bankAccounts.subtitle",
    CompanyDefault: "properties.bankAccounts.companyDefault",
    Specify: "properties.bankAccounts.specify",
    ChangeExplainer: "properties.bankAccounts.changeExplainer",
    ChooseAccount: "properties.bankAccounts.chooseAccount",
    Settings: "properties.bankAccounts.settings",
    NotificationTitleDefault: "properties.bankAccounts.notificationTitleDefault",
    NotificationBodyDefault: "properties.bankAccounts.notificationBodyDefault",
    NotificationTitleSpecify: "properties.bankAccounts.notificationTitleSpecify",
    NotificationBodySpecify: "properties.bankAccounts.notificationBodySpecify",
    SecurityDeposit: "properties.bankAccounts.securityDeposit",
    DoYouUseDifferentDepositAccounts: "properties.bankAccounts.doYouUseDifferentDepositAccounts",
    DifferentSecurityDeposit: "properties.bankAccounts.differentSecurityDeposit",
    SameSecurityDeposit: "properties.bankAccounts.sameSecurityDeposit",
    DefaultAccountStatus: "properties.bankAccounts.defaultAccountStatus"
  },
  Settings: {
    AddressDescriptionTitle: "properties.settings.addressDescriptionTitle",
    AddressDescriptionSubTitle: "properties.settings.addressDescriptionSubTitle",
    TypeDescriptionTitle: "properties.settings.typeDescriptionTitle",
    TypeDescriptionSubTitle: "properties.settings.typeDescriptionSubTitle",
    OwnersDescriptionTitle: "properties.settings.ownersDescriptionTitle",
    OwnersDescriptionSubTitle: "properties.settings.ownersDescriptionSubTitle",
    ManagerTitle: "properties.settings.managerTitle",
    ManagerDescriptionTitle: "properties.settings.managerDescriptionTitle",
    ManagerDescriptionSubTitle: "properties.settings.managerDescriptionSubTitle",
    BankAccountTitle: "properties.settings.bankAccountTitle",
    BankAccountsTitle: "properties.settings.bankAccountsTitle",
    BankAccountsDescriptionTitle: "properties.settings.bankAccountsDescriptionTitle",
    BankAccountsDescriptionSubTitle: "properties.settings.bankAccountsDescriptionSubTitle",
    ReserveFundsTitle: "properties.settings.reserveFundsTitle",
    ReserveFundsDescriptionTitle: "properties.settings.reserveFundsDescriptionTitle",
    ReserveFundsDescriptionSubTitle: "properties.settings.reserveFundsDescriptionSubTitle",
    ManagementFeesTitle: "properties.settings.managementFeesTitle",
    ManagementFeesDescriptionTitle: "properties.settings.managementFeesDescriptionTitle",
    ManagementFeesDescriptionSubTitle: "properties.settings.managementFeesDescriptionSubTitle",
    LateFeesTitle: "properties.settings.lateFeesTitle",
    ConvenienceFees: "properties.settings.convenienceFees",
    ConvenienceFeesDescriptionCompanySubTitle: "properties.settings.convenienceFeesDescriptionCompanySubTitle",
    LateFeesDescriptionTitle: "properties.settings.lateFeesDescriptionTitle",
    LateFeesDescriptionSubTitle: "properties.settings.managementFeesDescriptionSubTitle",
    ResidentPortalDescriptionTitle: "properties.settings.residentPortalDescriptionTitle",
    ResidentPortalDescriptionSubTitle: "properties.settings.residentPortalDescriptionSubTitle",
    RentersInsuranceDescriptionTitle: "properties.settings.rentersInsuranceDescriptionTitle",
    RentersInsuranceDescriptionSubTitle: "properties.settings.rentersInsuranceDescriptionSubTitle",
    PaymentsDescriptionTitle: "properties.settings.paymentsDescriptionTitle",
    PaymentsDescriptionSubTitle: "properties.settings.paymentsDescriptionSubTitle",
    RentalApplicationsTitle: "properties.settings.rentalApplicationsTitle",
    RentalApplicationsDescriptionTitle: "properties.settings.rentalApplicationsDescriptionTitle",
    RentalApplicationsDescriptionSubTitle: "properties.settings.rentalApplicationsDescriptionSubTitle",
    RentalApplicationsDescriptionCompanySubTitle: "properties.settings.rentalApplicationsDescriptionCompanySubTitle",
    PropertySettings: "properties.settings.propertySettings",
    Property: "properties.settings.property",
    DetailedDescriptionOfThisProperty: "properties.settings.detailedDescriptionOfThisProperty",
    EditProperty: "properties.settings.editProperty",
    PropertyName: "properties.settings.propertyName",
    PropertyAddress: "properties.settings.propertyAddress",
    PropertyType: "properties.settings.propertyType",
    PropertyBankAccounts: "properties.settings.propertyBankAccounts",
    PropertyBankAccountsDescription: "properties.settings.propertyBankAccountsDescription",
    UseCompanyDefaultBankAccounts: "properties.settings.useCompanyDefaultBankAccounts",
    SpecifyBankAccountForProperty: "properties.settings.specifyBankAccountForProperty",
    WhichOperatingAccount: "properties.settings.whichOperatingAccount",
    WhichEscrowAccount: "properties.settings.whichEscrowAccount",
    WhichEscrowDescription: "properties.settings.whichEscrowDescription",
    OperatingAccount: "properties.settings.operatingAccount",
    EscrowAccount: "properties.settings.escrowAccount",
    PropertyReserveFunds: "properties.settings.propertyReserveFunds",
    PropertyReserveFundsDescription: "properties.settings.propertyReserveFundsDescription",
    ReserveFundsAmountDescription: "properties.settings.reserveFundsAmountDescription",
    ReserveFundsAmount: "properties.settings.reserveFundsAmount",
    WhatIsReserveFundsToolTipTitle: "properties.settings.whatIsReserveFundsToolTipTitle",
    WhatIsReserveFundsToolTipDescription: "properties.settings.whatIsReserveFundsToolTipDescription",
    WhatIsPropertyOwnershipToolTipTitle: "properties.settings.whatIsPropertyOwnershipToolTipTitle",
    WhatIsPropertyOwnershipToolTipDescription: "properties.settings.whatIsPropertyOwnershipToolTipDescription",
    PropertyManagementFees: "properties.settings.propertyManagementFees",
    PropertyManagementFeesDescription: "properties.settings.propertyManagementFeesDescription",
    UseCompanyDefaultManagementFees: "properties.settings.useCompanyDefaultManagementFees",
    SpecifyManagementFeesForProperty: "properties.settings.specifyManagementFeesForProperty",
    PropertyLateFees: "properties.settings.propertyLateFees",
    PropertyLateFeesDescription: "properties.settings.propertyLateFeesDescription",
    UseCompanyDefaultLateFees: "properties.settings.useCompanyDefaultLateFees",
    UsePropertyDefaultLateFees: "properties.settings.usePropertyDefaultLateFees",
    SpecifyLateFeesForProperty: "properties.settings.specifyLateFeesForProperty",
    PropertyResidentPortal: "properties.settings.propertyResidentPortal",
    PropertyResidentPortalDescription: "properties.settings.propertyResidentPortalDescription",
    UseCompanyDefaultResidentPortal: "properties.settings.useCompanyDefaultResidentPortal",
    SpecifyResidentPortalForProperty: "properties.settings.specifyResidentPortalForProperty",
    AllowOnlinePayments: "properties.settings.allowOnlinePayments",
    AllowOnlinePaymentsLessThenBalance: "properties.settings.allowOnlinePaymentsLessThenBalance",
    AllowMaintenanceRequests: "properties.settings.allowMaintenanceRequests",
    AllowRenewalOfferResponse: "properties.settings.allowRenewalOfferResponse",
    PaymentInstructionsDescription: "properties.settings.paymentInstructionsDescription",
    PaymentInstructionsLabel: "properties.settings.paymentInstructionsLabel",
    AllowResidentPortalAccess: "properties.settings.allowResidentPortalAccess",
    PropertyRentersInsurance: "properties.settings.propertyRentersInsurance",
    UseCompanyDefaultRentersInsurance: "properties.settings.useCompanyDefaultRentersInsurance",
    SpecifyRentersInsuranceForProperty: "properties.settings.specifyRentersInsuranceForProperty",
    PropertyRentersInsuranceDescription: "properties.settings.propertyRentersInsuranceDescription",
    RequireRentersInsurance: "properties.settings.requireRentersInsurance",
    PropertyPaymentsAllocation: "properties.settings.propertyPaymentsAllocation",
    PropertyPaymentsAllocationDescription: "properties.settings.propertyPaymentsAllocationDescription",
    UseCompanyDefaultPaymentAllocation: "properties.settings.useCompanyDefaultPaymentAllocation",
    SpecifyPaymentAllocationForProperty: "properties.settings.specifyPaymentAllocationForProperty",
    SendEmailToTenantWhenChargesArePosted: "properties.settings.sendEmailToTenantWhenChargesArePosted",
    NumOfDaysBeforeDueDateToPostRecurringTransactions:
      "properties.settings.numOfDaysBeforeDueDateToPostRecurringTransactions",
    NumOfDaysBeforeDueDateToPostRecurringTransactionsLabel:
      "properties.settings.numOfDaysBeforeDueDateToPostRecurringTransactionsLabel",
    ShowLeaseLedger: "properties.settings.showLeaseLedger"
  }
};

export default Properties;
