"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOutstandingTransactionsForPayBillsQuery = void 0;
const class_validator_1 = require("class-validator");
const validators_1 = require("../../common/validators");
const getOutstandingTransactionsShared_query_1 = require("./getOutstandingTransactionsShared.query");
class GetOutstandingTransactionsForPayBillsQuery extends getOutstandingTransactionsShared_query_1.GetOutstandingTransactionsSharedQuery {
    constructor(vendors, properties, dueBefore, batch, propertyBankBalanceAsOfDate) {
        super(properties, propertyBankBalanceAsOfDate);
        this.vendors = vendors;
        this.dueBefore = dueBefore;
        this.batch = batch;
    }
}
exports.GetOutstandingTransactionsForPayBillsQuery = GetOutstandingTransactionsForPayBillsQuery;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], GetOutstandingTransactionsForPayBillsQuery.prototype, "vendors", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, validators_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetOutstandingTransactionsForPayBillsQuery.prototype, "dueBefore", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetOutstandingTransactionsForPayBillsQuery.prototype, "batch", void 0);
