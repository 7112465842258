import React from "react";
import type { FieldInputProps } from "formik";
import { useTranslation } from "react-i18next";
import AppStrings from "../../../../../../locale/keys";
import type { OptionType } from "DLUI/form/autoComplete/autoComplete";
import { DataCy } from "@doorloop/dto";
import { Feature } from "../../../../../../config/featureFlags/featureFlags";
import { smartySupportedCountriesOptions } from "DLUI/form/autoComplete/addressAutoComplete/smarty/addressAutoCompleteForm";
import AddressAutoComplete from "DLUI/form/autoComplete/addressAutoComplete/addressAutoComplete";

interface Props {
  values: OptionType[];
  field: FieldInputProps<string>;
  label: string;
  uniqueId: string;
  defaultValue?: OptionType;
  marginTop?: number;
  isAddressDefined?: boolean;
  handleChangeSearchAddressEngine?: (engineType: "google" | "smarty") => void;
}

const CountryAutoComplete: React.FC<Props> = ({
  values,
  uniqueId,
  field,
  defaultValue,
  isAddressDefined,
  handleChangeSearchAddressEngine,
  label,
  ...rest
}) => {
  const { t } = useTranslation();

  const handleChangeSearchAddressEngineBySelectedCountry = (selectedCountry: string) => {
    if (handleChangeSearchAddressEngine) {
      const isCountryNotExistInSmarty =
        !Feature.isActive("SmartyAutocomplete") ||
        !smartySupportedCountriesOptions.find(
          ({ displayName }) => displayName.toLowerCase() === selectedCountry.toLowerCase()
        );

      handleChangeSearchAddressEngine(isCountryNotExistInSmarty ? "google" : "smarty");
    }
  };

  return (
    <AddressAutoComplete
      uniqueId={uniqueId}
      values={values}
      noOptionsText={t(AppStrings.Common.NoCountriesFound)}
      dataCy={DataCy.addressInformation.country}
      label={label}
      field={field}
      defaultValue={defaultValue}
      isAddressDefined={isAddressDefined}
      onTextFieldBlur={handleChangeSearchAddressEngineBySelectedCountry}
      {...rest}
    />
  );
};

export default CountryAutoComplete;
