import type { EPayReceivableInfoDto } from "@doorloop/dto";
import { EPayPaymentMethod } from "@doorloop/dto";
import { Grid } from "@material-ui/core";
import { ViewOnlyInput } from "DLUI/form";
import AppStrings from "locale/keys";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Text from "DLUI/text";

interface ComponentProps {
  ePayInfo?: EPayReceivableInfoDto;
}

const EPayInfoView: React.FC<ComponentProps> = ({ ePayInfo }: ComponentProps) => {
  const { t } = useTranslation();
  if (!ePayInfo) {
    return null;
  }

  const isWUCash = ePayInfo.method === EPayPaymentMethod.WU_CASH;

  const numberPrefix = isWUCash
    ? AppStrings.Common.PaymentMethodIdPrefix.CashPayment
    : AppStrings.Common.PaymentMethodIdPrefix.CreditCard;

  return (
    <Fragment>
      {ePayInfo.txnId ? (
        <Grid container item xs={12} md={6}>
          <ViewOnlyInput
            value={ePayInfo.txnId}
            label={AppStrings.Common.TransactionId}
            backgroundColor={"transparent"}
            marginTop={20}
            paddingBottom={0}
          />
          {isWUCash && (
            <Text
              fontSize={14}
              marginLeft={10}
              value={AppStrings.Common.PaymentMethodIdPrefix.MTCN}
              replaceObject={{
                token: String(ePayInfo.token)
              }}
            />
          )}
        </Grid>
      ) : null}

      <Grid container item xs={12} md={2}>
        <ViewOnlyInput
          value={t(numberPrefix, { number: isWUCash ? String(ePayInfo.number).padStart(6, "0") : ePayInfo.number })}
          label={AppStrings.Common.CardNumber}
          backgroundColor={"transparent"}
          overFlow={"visible"}
          marginTop={20}
        />
      </Grid>
    </Fragment>
  );
};

export default EPayInfoView;
