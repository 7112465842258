"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userRolesUtils = void 0;
const taskViewObjectAccess_enum_1 = require("./taskViewObjectAccess.enum");
class UserRolesUtils {
    validateReportsAccess(dto, errors) { }
    validateObjectAccess(objects, errors) {
        this.validatePeopleObjectAccess(objects, errors);
        this.validateTasksAndMaintenanceObjectAccess(objects, errors);
        this.validateRentalsObjectAccess(objects, errors);
        this.validateLeasingObjectAccess(objects, errors);
        this.validateTenantTransactionsObjectAccess(objects, errors);
        this.validateVendorTransactionsObjectAccess(objects, errors);
        this.validateOwnerTransactionsObjectAccess(objects, errors);
        this.validateOtherTransactionsObjectAccess(objects, errors);
        // People
        this.validateGenericObjectAccess(errors, objects.prospects);
        this.validateGenericObjectAccess(errors, objects.owners);
        this.validateGenericObjectAccess(errors, objects.vendors);
        this.validateGenericObjectAccess(errors, objects.tenants);
        this.validateGenericObjectAccess(errors, objects.users);
        // Tasks & Maintenance
        this.validateGenericTaskObjectAccess(errors, objects.tasks);
        this.validateGenericTaskObjectAccess(errors, objects.workOrders);
        this.validateGenericTaskObjectAccess(errors, objects.ownerRequests);
        this.validateGenericTaskObjectAccess(errors, objects.tenantRequests);
        // Rentals
        this.validateGenericObjectAccess(errors, objects.properties);
        this.validateGenericObjectAccess(errors, objects.units);
        // Leasing
        this.validateGenericObjectAccess(errors, objects.leases);
        this.validateGenericObjectAccess(errors, objects.rentalApplications);
        // Tenant Transactions
        this.validateGenericObjectAccess(errors, objects.leaseCharges);
        this.validateGenericObjectAccess(errors, objects.leaseCredits);
        this.validateGenericObjectAccess(errors, objects.leasePayments);
        this.validateGenericObjectAccess(errors, objects.leaseRefunds);
        this.validateGenericObjectAccess(errors, objects.bulkReceivePayments);
        // Vendor Transactions
        this.validateGenericObjectAccess(errors, objects.vendorBills);
        this.validateGenericObjectAccess(errors, objects.vendorBillPayments);
        this.validateGenericObjectAccess(errors, objects.vendorCredits);
        // Owner Transactions
        this.validateGenericObjectAccess(errors, objects.ownerContributions);
        // Other transactions
        this.validateGenericObjectAccess(errors, objects.journalEntries);
        this.validateGenericObjectAccess(errors, objects.bankTransfers);
        this.validateGenericObjectAccess(errors, objects.bankDeposits);
        this.validateGenericObjectAccess(errors, objects.expenses);
        this.validateGenericObjectAccess(errors, objects.applicationFees);
        // General Objects
        this.validateGenericObjectAccess(errors, objects.files);
        this.validateGenericObjectAccess(errors, objects.notes);
        this.validateGenericObjectAccess(errors, objects.accounts);
        this.validateGenericObjectAccess(errors, objects.apiKeys);
        // Related Lists
        this.validateGenericObjectAccess(errors, objects.propertyGroups);
        this.validateGenericObjectAccess(errors, objects.tags);
    }
    validateGenericTaskObjectAccess(errors, dto) {
        if (!dto)
            return;
        if (dto.viewOne !== undefined && (dto.viewList !== undefined || dto.viewList === taskViewObjectAccess_enum_1.TaskViewObjectAccess.NONE)) {
            errors.push("View list access is required for view one access");
        }
        if (dto.edit && (!dto.viewOne !== undefined || dto.viewOne === taskViewObjectAccess_enum_1.TaskViewObjectAccess.NONE || !dto.viewList)) {
            errors.push("View one and view list access is required for edit access");
        }
        if (dto.delete && (!dto.viewOne || !dto.viewList || !dto.edit)) {
            errors.push("View one, view list and edit access is required for delete access");
        }
    }
    validateGenericObjectAccess(errors, dto) {
        if (!dto) {
        }
        else {
            if (dto.viewOne && !dto.viewList) {
                errors.push("View list access is required for view one access");
            }
            if (dto.edit && (!dto.viewOne || !dto.viewList)) {
                errors.push("View one and view list access is required for edit access");
            }
            if (dto.delete && (!dto.viewOne || !dto.viewList || !dto.edit)) {
                errors.push("View one, view list and edit access is required for delete access");
            }
        }
    }
    validatePeopleObjectAccess(objects, errors) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        // People
        if (objects) {
            if (((_a = objects.prospects) === null || _a === void 0 ? void 0 : _a.create) || ((_b = objects.prospects) === null || _b === void 0 ? void 0 : _b.edit)) {
                if (!((_c = objects.properties) === null || _c === void 0 ? void 0 : _c.viewList)) {
                    errors.push("View list access for properties is required for create or edit access for prospects.");
                }
            }
            if (((_d = objects.owners) === null || _d === void 0 ? void 0 : _d.create) || ((_e = objects.owners) === null || _e === void 0 ? void 0 : _e.edit)) {
                if (!((_f = objects.properties) === null || _f === void 0 ? void 0 : _f.viewList)) {
                    errors.push("View list access for properties is required for create or edit access for owners.");
                }
            }
            if (((_g = objects.vendors) === null || _g === void 0 ? void 0 : _g.create) || ((_h = objects.vendors) === null || _h === void 0 ? void 0 : _h.edit)) {
                if (!((_j = objects.properties) === null || _j === void 0 ? void 0 : _j.viewList)) {
                    errors.push("View list access for properties is required for create or edit access for vendors.");
                }
            }
            if (((_k = objects.tenants) === null || _k === void 0 ? void 0 : _k.create) || ((_l = objects.tenants) === null || _l === void 0 ? void 0 : _l.edit)) {
                if (!((_m = objects.properties) === null || _m === void 0 ? void 0 : _m.viewList)) {
                    errors.push("View list access for properties is required for create or edit access for tenants.");
                }
            }
            if (((_o = objects.users) === null || _o === void 0 ? void 0 : _o.create) || ((_p = objects.users) === null || _p === void 0 ? void 0 : _p.edit)) {
                if (!((_q = objects.properties) === null || _q === void 0 ? void 0 : _q.viewList)) {
                    errors.push("View list access for properties is required for create or edit access for users.");
                }
            }
        }
    }
    validateTasksAndMaintenanceObjectAccess(objects, errors) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        if (objects) {
            // Tasks & Maintenance
            if (((_a = objects.tasks) === null || _a === void 0 ? void 0 : _a.create) || ((_b = objects.tasks) === null || _b === void 0 ? void 0 : _b.edit)) {
                if (!((_c = objects.users) === null || _c === void 0 ? void 0 : _c.viewList)) {
                    errors.push("View list access for properties is required for create or edit access for tasks.");
                }
            }
            if (((_d = objects.workOrders) === null || _d === void 0 ? void 0 : _d.create) || ((_e = objects.workOrders) === null || _e === void 0 ? void 0 : _e.edit)) {
                if (!((_f = objects.users) === null || _f === void 0 ? void 0 : _f.viewList)) {
                    errors.push("View list access for users is required for create or edit access for tasks.");
                }
                if (!((_g = objects.vendors) === null || _g === void 0 ? void 0 : _g.viewList)) {
                    errors.push("View list access for vendors is required for create or edit access for tasks.");
                }
            }
            if (((_h = objects.ownerRequests) === null || _h === void 0 ? void 0 : _h.create) || ((_j = objects.ownerRequests) === null || _j === void 0 ? void 0 : _j.edit)) {
                if (!((_k = objects.users) === null || _k === void 0 ? void 0 : _k.viewList)) {
                    errors.push("View list access for users is required for create or edit access for tasks.");
                }
                if (!((_l = objects.owners) === null || _l === void 0 ? void 0 : _l.viewList)) {
                    errors.push("View list access for vendors is required for create or edit access for tasks.");
                }
            }
            if (((_m = objects.tenantRequests) === null || _m === void 0 ? void 0 : _m.create) || ((_o = objects.tenantRequests) === null || _o === void 0 ? void 0 : _o.edit)) {
                if (!((_p = objects.users) === null || _p === void 0 ? void 0 : _p.viewList)) {
                    errors.push("View list access for users is required for create or edit access for tasks.");
                }
                if (!((_q = objects.tenants) === null || _q === void 0 ? void 0 : _q.viewList)) {
                    errors.push("View list access for vendors is required for create or edit access for tasks.");
                }
            }
        }
    }
    validateRentalsObjectAccess(objects, errors) {
        var _a, _b;
        if (objects) {
            // Rentals
            if ((_a = objects.properties) === null || _a === void 0 ? void 0 : _a.create) {
                if (!((_b = objects.units) === null || _b === void 0 ? void 0 : _b.create)) {
                    errors.push("Create access for units is required for create access for properties.");
                }
            }
        }
    }
    validateLeasingObjectAccess(objects, errors) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (objects) {
            // Leasing
            if ((_a = objects.leases) === null || _a === void 0 ? void 0 : _a.create) {
                if (!((_b = objects.tenants) === null || _b === void 0 ? void 0 : _b.viewList)) {
                    errors.push("View list access for tenants is required for create access for leases.");
                }
                if (!((_c = objects.prospects) === null || _c === void 0 ? void 0 : _c.viewList)) {
                    errors.push("View list access for prospect is required for create access for leases.");
                }
                if (!((_d = objects.leaseCharges) === null || _d === void 0 ? void 0 : _d.create)) {
                    errors.push("Create access for lease charges is required for create access for leases.");
                }
                if (!((_e = objects.leaseCredits) === null || _e === void 0 ? void 0 : _e.create)) {
                    errors.push("Create access for lease credits is required for create access for leases.");
                }
            }
            if (objects.rentalApplications) {
                if (((_f = objects.rentalApplications) === null || _f === void 0 ? void 0 : _f.create) || ((_g = objects.rentalApplications) === null || _g === void 0 ? void 0 : _g.edit)) {
                    if (!((_h = objects.prospects) === null || _h === void 0 ? void 0 : _h.viewList)) {
                        errors.push("View list access for prospect is required for create access for rental applications.");
                    }
                }
            }
        }
    }
    validateTenantTransactionsObjectAccess(objects, errors) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
        if (objects) {
            // Tenant Transactions
            if (((_a = objects.leaseCharges) === null || _a === void 0 ? void 0 : _a.viewList) ||
                ((_b = objects.leaseCredits) === null || _b === void 0 ? void 0 : _b.viewList) ||
                ((_c = objects.leasePayments) === null || _c === void 0 ? void 0 : _c.viewList) ||
                ((_d = objects.leaseRefunds) === null || _d === void 0 ? void 0 : _d.viewList)) {
                if (!(((_e = objects.leaseCharges) === null || _e === void 0 ? void 0 : _e.viewList) &&
                    ((_f = objects.leaseCredits) === null || _f === void 0 ? void 0 : _f.viewList) &&
                    ((_g = objects.leasePayments) === null || _g === void 0 ? void 0 : _g.viewList) &&
                    ((_h = objects.leaseRefunds) === null || _h === void 0 ? void 0 : _h.viewList))) {
                    errors.push("View list access for one of the following: lease charges, credit, payments and refunds requires view list access for all of them.");
                }
            }
            if (((_j = objects.leaseCharges) === null || _j === void 0 ? void 0 : _j.viewOne) ||
                ((_k = objects.leaseCharges) === null || _k === void 0 ? void 0 : _k.create) ||
                ((_l = objects.leaseCredits) === null || _l === void 0 ? void 0 : _l.viewOne) ||
                ((_m = objects.leaseCredits) === null || _m === void 0 ? void 0 : _m.create) ||
                ((_o = objects.leasePayments) === null || _o === void 0 ? void 0 : _o.viewOne) ||
                ((_p = objects.leasePayments) === null || _p === void 0 ? void 0 : _p.create) ||
                ((_q = objects.leaseRefunds) === null || _q === void 0 ? void 0 : _q.viewOne) ||
                ((_r = objects.leaseRefunds) === null || _r === void 0 ? void 0 : _r.create)) {
                if (!((_s = objects.leases) === null || _s === void 0 ? void 0 : _s.viewList)) {
                    errors.push("View list access for leases is required for view access for tenant transactions.");
                }
            }
            if (((_t = objects.leasePayments) === null || _t === void 0 ? void 0 : _t.create) || ((_u = objects.leasePayments) === null || _u === void 0 ? void 0 : _u.edit)) {
                if (!((_v = objects.tenants) === null || _v === void 0 ? void 0 : _v.viewList)) {
                    errors.push("View list access for tenants is required for view access for lease payments.");
                }
            }
        }
    }
    validateVendorTransactionsObjectAccess(objects, errors) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        if (objects) {
            // Vendor Transactions
            if (((_a = objects.vendorBillPayments) === null || _a === void 0 ? void 0 : _a.viewList) || ((_b = objects.vendorBills) === null || _b === void 0 ? void 0 : _b.viewList) || ((_c = objects.vendorCredits) === null || _c === void 0 ? void 0 : _c.viewList)) {
                if (!(((_d = objects.vendorBillPayments) === null || _d === void 0 ? void 0 : _d.viewList) && ((_e = objects.vendorBills) === null || _e === void 0 ? void 0 : _e.viewList) && ((_f = objects.vendorCredits) === null || _f === void 0 ? void 0 : _f.viewList))) {
                    errors.push("View list access for one of the following: vendor bills, bill payments & credits requires view list access for all of them.");
                }
            }
            if (((_g = objects.vendorBills) === null || _g === void 0 ? void 0 : _g.viewOne) ||
                ((_h = objects.vendorBills) === null || _h === void 0 ? void 0 : _h.create) ||
                ((_j = objects.vendorBillPayments) === null || _j === void 0 ? void 0 : _j.viewOne) ||
                ((_k = objects.vendorBillPayments) === null || _k === void 0 ? void 0 : _k.create) ||
                ((_l = objects.vendorCredits) === null || _l === void 0 ? void 0 : _l.viewOne) ||
                ((_m = objects.vendorCredits) === null || _m === void 0 ? void 0 : _m.create)) {
                if (!((_o = objects.vendors) === null || _o === void 0 ? void 0 : _o.viewList)) {
                    errors.push("View list access for vendors is required for view access for vendor transactions.");
                }
            }
        }
    }
    validateOwnerTransactionsObjectAccess(objects, errors) {
        var _a, _b, _c, _d;
        if (objects) {
            // Owner Transactions
            if (((_a = objects.ownerContributions) === null || _a === void 0 ? void 0 : _a.viewOne) ||
                ((_b = objects.ownerContributions) === null || _b === void 0 ? void 0 : _b.create) ||
                ((_c = objects.ownerContributions) === null || _c === void 0 ? void 0 : _c.edit)) {
                if (!((_d = objects.owners) === null || _d === void 0 ? void 0 : _d.viewList)) {
                    errors.push("View list access for owners is required for view one access for owner contributions.");
                }
            }
        }
    }
    validateOtherTransactionsObjectAccess(objects, errors) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (objects) {
            // Other Transactions
            if (((_a = objects.expenses) === null || _a === void 0 ? void 0 : _a.viewOne) || ((_b = objects.expenses) === null || _b === void 0 ? void 0 : _b.create) || ((_c = objects.expenses) === null || _c === void 0 ? void 0 : _c.edit)) {
                if (!((_d = objects.vendors) === null || _d === void 0 ? void 0 : _d.viewList)) {
                    errors.push("View list access for vendors is required for view one access for expenses.");
                }
                if (!((_e = objects.tenants) === null || _e === void 0 ? void 0 : _e.viewList)) {
                    errors.push("View list access for tenants is required for view one access for expenses.");
                }
                if (!((_f = objects.owners) === null || _f === void 0 ? void 0 : _f.viewList)) {
                    errors.push("View list access for owners is required for view one access for expenses.");
                }
            }
            if ((_g = objects.applicationFees) === null || _g === void 0 ? void 0 : _g.viewOne) {
                if (!((_h = objects.rentalApplications) === null || _h === void 0 ? void 0 : _h.viewOne)) {
                    errors.push("View one access for rental applications is required for view one access for application fees.");
                }
            }
        }
    }
    validate(dto) {
        // validate objects
        const errors = [];
        if (dto.objects) {
            this.validateObjectAccess(dto.objects, errors);
        }
        if (dto.reports) {
            this.validateReportsAccess(dto, errors);
        }
        return errors;
    }
}
exports.userRolesUtils = new UserRolesUtils();
