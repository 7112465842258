import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    elementContainer: {
      background: "#FFFFFF",
      border: "1px solid #182C4C0D",
      transition: "background-color 0.2s ease-in-out",
      "&:hover": {
        backgroundColor: theme.newPalette.secondary.states.hover
      }
    },
    rightSideContentContainer: {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      height: "100%",

      backgroundColor: theme.palette.white.main,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    }
  })
);
