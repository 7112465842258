"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFixedBasedFee = isFixedBasedFee;
exports.isPercentageBasedFee = isPercentageBasedFee;
const managementFeeRuleTypeEnum_1 = require("./managementFeeRuleTypeEnum");
function isFixedBasedFee(ruleType) {
    return ruleType === managementFeeRuleTypeEnum_1.ManagementFeeRuleTypesEnum.FixedBasedFee;
}
function isPercentageBasedFee(ruleType) {
    if (!ruleType)
        return false;
    return [managementFeeRuleTypeEnum_1.ManagementFeeRuleTypesEnum.AccountBasedFeeCash, managementFeeRuleTypeEnum_1.ManagementFeeRuleTypesEnum.AccountBasedFeeAccrual].includes(ruleType);
}
