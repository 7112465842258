import React, { useEffect, useMemo, useState } from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useTranslation } from "react-i18next";
import type { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import type { FieldProps } from "formik";
import { getIn } from "formik";
import { CalendarIconLight } from "assets/icons";
import clsx from "clsx";
import "./styles.css";
import { useSelector } from "react-redux";
import type { IRootState } from "../../../../store";
import { Icon } from "DLUI/icon";
import AppStrings from "locale/keys";
import moment from "moment";
import { DataCy, DateFormats } from "@doorloop/dto";
import ViewOnlyInput from "../viewOnlyInput/viewOnlyInput";
import { fieldSizesMap, useStylesTextInput } from "DLUI/form/textField/textInput";
import { FieldSizes } from "DLUI/form/textField/types";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { getCleanUnicodeString } from "utils/stringsConverter";

interface ComponentProps extends FieldProps<any> {
  label: string;
  uniqueKey: string;
  style?: {};
  noMargin?: boolean;
  marginLeft?: number;
  marginRight?: number;
  hideErrorText?: boolean;
  marginTop?: number;
  onChange?: (selectedDate: string | null) => void;
  disabled?: boolean;
  maxDate?: Date;
  minDate?: Date;
  defaultValue?: string;
  maxWidth?: number;
  paddingRight?: number;
  paddingLeft?: number;
  size?: number | string;
  viewOnly?: boolean;
  shouldDisableDate?: (date: MaterialUiPickersDate) => boolean;
  dataCy?: string;
}

const FormikDatePicker: React.FC<ComponentProps> = ({
  label,
  uniqueKey,
  style,
  field,
  form,
  noMargin,
  marginLeft,
  marginRight,
  marginTop,
  hideErrorText,
  onChange,
  disabled,
  maxDate,
  minDate,
  defaultValue,
  maxWidth,
  paddingRight,
  paddingLeft,
  size,
  viewOnly,
  shouldDisableDate,
  dataCy,
  ...rest
}: ComponentProps) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();

  const classes = useStylesTextInput();
  const deviceClassName = clsx(["DL_DatePicker", isMobile ? "MOBILE" : "WEB"]);

  const _fieldSize: FieldSizes = useMemo(() => (isMobile ? FieldSizes.MOBILE : FieldSizes.WEB), [isMobile]);
  const inputPropsStyle = fieldSizesMap[_fieldSize].InputProps.style;
  const inputLabelPropsStyle = fieldSizesMap[_fieldSize].InputLabelProps.style;

  const [pickerValue, setPickerValue] = useState<Date | null>(null);
  const errorText = getIn(form.errors, field.name);
  const touchedVal = getIn(form.touched, field.name);
  const hasError = touchedVal && errorText !== undefined;
  const localeData = useSelector((state: IRootState) => state.auth.localeData);
  const dateFormat = getCleanUnicodeString(localeData.dateFormat);

  useEffect(() => {
    if (field.value !== undefined) {
      setPickerValue(field.value || null);
    }
  }, [field.value]);

  useEffect(() => {
    const fieldValue = getIn(form.values, field.name);
    setPickerValue(fieldValue || null);
  }, []);

  const setDate = (date: Date) => {
    form.setFieldValue(field.name, moment(date).format(DateFormats.ISO_DATE_SERVER_FORMAT).toString());
    setPickerValue(date);
  };

  const handleMinMaxDate = (selectedDate: MaterialUiPickersDate) => {
    const sameDate =
      selectedDate?.format(DateFormats.ISO_DATE_SERVER_FORMAT) ===
      moment(minDate).format(DateFormats.ISO_DATE_SERVER_FORMAT);

    if (minDate && selectedDate?.isBefore(minDate) && !sameDate) {
      setDate(minDate);
    }
    if (maxDate && selectedDate?.isAfter(maxDate) && !sameDate) {
      setDate(maxDate);
    }
  };

  const _onChange = (selectedDate: MaterialUiPickersDate | null) => {
    form.setFieldTouched(field.name, true);
    if (selectedDate && !selectedDate.isValid()) {
      form.setFieldValue(field.name, undefined);
      return;
    }
    if (!selectedDate) {
      form.setFieldValue(field.name, undefined);
      setPickerValue(null);
    } else {
      setDate(selectedDate.toDate());
    }
    if (onChange && selectedDate !== null) {
      onChange(selectedDate.format(DateFormats.ISO_DATE_SERVER_FORMAT).toString());
    }

    handleMinMaxDate(selectedDate);
  };

  useEffect(() => {
    if ((minDate || maxDate) && pickerValue) {
      handleMinMaxDate(moment(pickerValue));
    }
  }, [minDate, maxDate, pickerValue]);

  let nextSize = "100%";
  if (size) {
    nextSize = size.toString();
  } else {
    size = "100%";
  }
  if (paddingRight) {
    nextSize = "calc(" + size + " - " + paddingRight + "px)";
  }

  if (viewOnly) {
    return (
      <ViewOnlyInput
        value={moment(pickerValue).format(DateFormats.ISO_DATE_SERVER_FORMAT).toString()}
        label={label}
        backgroundColor={"transparent"}
        formatType={"date"}
        alignItems={"flex-start"}
        marginTop={marginTop}
      />
    );
  }

  return (
    <div
      style={{
        width: "100%",
        marginLeft: marginLeft || 0,
        marginRight: marginRight || 0,
        marginTop: marginTop || 0,
        maxWidth: maxWidth || "none"
      }}
      className={clsx(["DL_DatePicker", noMargin ? "noMargin" : "", field.value !== undefined ? "notEmpty" : ""])}
    >
      <KeyboardDatePicker
        allowKeyboardControl
        autoOk
        inputVariant={"outlined"}
        variant="inline"
        format={dateFormat}
        margin={isMobile ? undefined : "dense"}
        data-cy={dataCy || field.name}
        shouldDisableDate={shouldDisableDate}
        id={"date-picker" + uniqueKey}
        label={t(label)}
        value={pickerValue}
        onChange={_onChange}
        views={["year", "month", "date"]}
        KeyboardButtonProps={{
          "aria-label": t(label)
        }}
        className={classes.root}
        InputLabelProps={{ className: clsx([deviceClassName, classes.label]), style: inputLabelPropsStyle }}
        InputProps={{ className: deviceClassName, style: inputPropsStyle }}
        style={{
          ...style,
          width: nextSize,
          paddingRight: paddingRight || "0px",
          paddingLeft: paddingLeft || "0px"
        }}
        error={hasError}
        helperText={
          hasError && !hideErrorText ? (errorText ? errorText : t(AppStrings.Common.Validation.Required)) : ""
        }
        keyboardIcon={
          <Icon
            Source={CalendarIconLight}
            pathColor={"light-gray"}
            size={16}
            dataCy={DataCy.DLUI.datePicker.datePickerIcon}
          />
        }
        disabled={disabled}
        {...rest}
        maxDate={maxDate}
        minDate={minDate}
      />
    </div>
  );
};

export default FormikDatePicker;
