"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalApplicationFieldSettingsPropsDto = void 0;
const class_validator_1 = require("class-validator");
const rentalApplicationFieldSettingsBaseValue_dto_1 = require("./rentalApplicationFieldSettingsBaseValue.dto");
class RentalApplicationFieldSettingsPropsDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.RentalApplicationFieldSettingsPropsDto = RentalApplicationFieldSettingsPropsDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "firstName", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "middleName", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "lastName", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "ssn", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "itin", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "dateOfBirth", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "emailAddress", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "mobileNumber", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "gender", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "maritalStatus", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "driversLicenseState", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "driversLicenseNumber", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "bio", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "petType", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "breed", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "color", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "age", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "weight", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "isSpayedOrNeutered", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "isServiceAnimal", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "make", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "model", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "year", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "licensePlate", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "state", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "relationship", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "email", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "phone", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "jobType", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "annualIncome", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "position", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "companyName", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "currentlyWorkingHere", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "startDate", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "endDate", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "supervisorName", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "supervisorEmail", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "supervisorPhone", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "source", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "address", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "landlordName", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "landlordPhone", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "landlordEmail", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "moveInDate", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationFieldSettingsBaseValue_dto_1.RentalApplicationFieldSettingsBaseValueDto)
], RentalApplicationFieldSettingsPropsDto.prototype, "monthlyPayment", void 0);
