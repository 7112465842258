import type { FC } from "react";
import React from "react";
import { View } from "DLUI/view";
import { TaskPriority } from "@doorloop/dto";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import ColorsEnum from "../../../../utils/colorsEnum";

interface Settings {
  backgroundColor: ColorsEnum;
  color: ColorsEnum;
  label: string;
}

interface Props {
  value?: TaskPriority;
}

const settingsMap: Record<TaskPriority, Settings> = {
  [TaskPriority.LOW]: {
    backgroundColor: ColorsEnum.LightYellow,
    color: ColorsEnum.Yellow,
    label: AppStrings.Tasks.Screen.Priorities.Low
  },
  [TaskPriority.MEDIUM]: {
    backgroundColor: ColorsEnum.LightYellow,
    color: ColorsEnum.Yellow,
    label: AppStrings.Tasks.Screen.Priorities.Medium
  },
  [TaskPriority.HIGH]: {
    backgroundColor: ColorsEnum.LightRed,
    color: ColorsEnum.Red,
    label: AppStrings.Tasks.Screen.Priorities.High
  }
};

export const PriorityIndicator: FC<Props> = ({ value }: Props) => {
  const { color, backgroundColor, label } = settingsMap[value || TaskPriority.HIGH];
  return value ? (
    <View
      autoWidth
      borderRadius={40}
      backgroundColorEnum={backgroundColor}
      paddingRight={10}
      paddingLeft={10}
      minHeight={20}
      justifyContent={"center"}
    >
      <Text value={label} fontSize={10} colorEnum={color} />
    </View>
  ) : null;
};
