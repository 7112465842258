import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { Icon } from "DLUI/icon";
import { Link } from "DLUI/link";
import type { SVGIconComponent } from "assets/icons/types";

import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";

interface ComponentProps {
  url: string;
  icon: SVGIconComponent;
  title: string;
}
const DoorLoopUrlItem: React.FC<ComponentProps> = ({ title, icon, url }: ComponentProps) => {
  const { screenContainerPadding, isMobile } = useResponsiveHelper();
  const itemMarginTop = isMobile ? 20 : 0;
  return (
    <View
      minHeight={90}
      borderRadius={10}
      alignItems={"center"}
      backgroundColor={"dark"}
      flexDirection={"row"}
      marginTop={20}
      noWrap
    >
      <View height={"100%"} autoWidth>
        <View
          width={50}
          height={50}
          justifyContent={"center"}
          alignItems={"center"}
          borderRadius={25}
          backgroundColor={"secondary-gray-light"}
          marginTop={isMobile ? 20 : 0}
          marginLeft={20}
        >
          <Icon Source={icon} width={30} height={30} pathColor={"black"} />
        </View>
      </View>
      <View marginTop={itemMarginTop} autoWidth flex={1} flexDirection={"column"}>
        <View paddingRight={screenContainerPadding} paddingLeft={screenContainerPadding} overflowWrap={"anywhere"}>
          <Text fontSize={16} color={"black"} value={title} />
          <Link type={"blank"} hoverColor={"lightBlue"} underline={"always"} hrefUrl={url}>
            <Text marginTop={10} fontSize={16} color={"lightBlue"}>
              {url}
            </Text>
          </Link>
          {isMobile && <View height={20} />}
        </View>
      </View>
    </View>
  );
};

export default DoorLoopUrlItem;
