import type {
  ActivateOwnerPortalDto,
  GetAllBaseQueryResponse,
  GetAllOwnersQuery,
  GetOwnerPortalImpersonationTokenRequest,
  GetOwnerPortalImpersonationTokenResponseDto,
  OwnerDto,
  ResendPortalInvitationDto
} from "@doorloop/dto";
import { LoginResponseType, SegmentEventTypes, ServerRoutes } from "@doorloop/dto";

import { analyticsService } from "../services/analyticsService";
import { apiHelper } from "./apiHelper";
import { RestApiBaseWithDictionary } from "./restApiBaseWithDictionary";
import type { ApiResult } from "api/apiResult";

export class OwnersApi extends RestApiBaseWithDictionary<OwnerDto, GetAllOwnersQuery> {
  constructor() {
    super(ServerRoutes.OWNERS);
    this.restRoute = ServerRoutes.OWNERS;
  }

  async getDetails(ownerId: string) {
    return await apiHelper.axiosGet(`${ServerRoutes.OWNERS}/${ownerId}`);
  }

  async resendOwnerPortalInvitation(data: ResendPortalInvitationDto) {
    analyticsService.track(
      SegmentEventTypes.INVITATION_SENT,
      {
        sentToId: data.id,
        sentToType: LoginResponseType.OWNER
      },
      { trackEventInIntercom: true }
    );

    return await apiHelper.axiosPost({
      url: ServerRoutes.OWNERS_POST_RESEND_OWNER_PORTAL_INVITATION,
      data
    });
  }

  async activateOwnerPortal(data: ActivateOwnerPortalDto) {
    analyticsService.track(
      SegmentEventTypes.INVITATION_SENT,
      {
        sentToId: data.ownerId,
        sentToType: LoginResponseType.OWNER
      },
      { trackEventInIntercom: true }
    );

    return await apiHelper.axiosPost({
      url: ServerRoutes.OWNERS_POST_ACTIVATE_OWNER_PORTAL,
      data
    });
  }

  async getOwnerPortalImpersonationToken(data: GetOwnerPortalImpersonationTokenRequest) {
    return await apiHelper.axiosPost<GetOwnerPortalImpersonationTokenResponseDto>({
      url: ServerRoutes.OWNERS_POST_OWNER_PORTAL_IMPERSONATION_TOKEN,
      data
    });
  }

  // TODO This should be renamed to `getAllWithProperties` or similar. It is
  // currently overriding the base class method, which is wrong.
  async getAll(payload: GetAllOwnersQuery): Promise<ApiResult<GetAllBaseQueryResponse<OwnerDto>>> {
    await this.loadDictionariesRequiredForGet();
    return await apiHelper.axiosPost({
      url: ServerRoutes.OWNERS_ALL,
      data: payload
    });
  }
}

export const ownersApi = new OwnersApi();
