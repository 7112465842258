export default {
  customAllocation: "Custom Allocation",
  allocate: "Allocate",
  allocation: "Allocation",
  allocations: "Allocations",
  allocationName: "Allocation Name",
  allocationDescription: "Allocation Description",
  allocationType: "Allocation Type",
  allocationAmount: "Allocation Amount",
  allocationSettings: "Allocation Settings",
  noAllocations: "No Allocations",
  noAllocationsDescription: "You have not created any allocations yet.",
  newAllocation: "New Allocation",
  editAllocation: "Edit Allocation",
  allocationInfo: "Allocation Info",
  percentage: "Percentage",
  totalAllocation: "Total Allocation",
  useCommonAllocation: "Use Common Allocation",
  includeVacantUnits: "Include Vacant Units",
  deleteConfirmationText: "Are you sure you want to delete this allocation?",
  errors: {
    invalidPercentagesExist:
      "To proceed, please finish allocating percentages for all units, or switch the allocation method to Manual.",
    sumOfAllocationsMustBeGreaterThanZero: "Sum of allocations must be greater than zero.",
    sumOfAllocationsMustBeLessThanOrEqualToOneHundred: "Sum of allocations must be less than or equal to 100%."
  }
};
