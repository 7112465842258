"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenantRequestMaintenanceCategory = exports.TenantRequestType = void 0;
var TenantRequestType;
(function (TenantRequestType) {
    TenantRequestType["MAINTENANCE"] = "MAINTENANCE";
    TenantRequestType["GENERAL"] = "GENERAL";
})(TenantRequestType || (exports.TenantRequestType = TenantRequestType = {}));
var TenantRequestMaintenanceCategory;
(function (TenantRequestMaintenanceCategory) {
    TenantRequestMaintenanceCategory["APPLIANCES"] = "APPLIANCES";
    TenantRequestMaintenanceCategory["DOORS_AND_LOCKS"] = "DOORS_AND_LOCKS";
    TenantRequestMaintenanceCategory["ELECTRONICS_AND_LIGHTING"] = "ELECTRONICS_AND_LIGHTING";
    TenantRequestMaintenanceCategory["EXTERIORS"] = "EXTERIORS";
    TenantRequestMaintenanceCategory["FLOORING"] = "FLOORING";
    TenantRequestMaintenanceCategory["HEATING_AND_COOLING"] = "HEATING_AND_COOLING";
    TenantRequestMaintenanceCategory["PLUMBING_AND_BATHROOMS"] = "PLUMBING_AND_BATHROOMS";
    TenantRequestMaintenanceCategory["SAFETY_EQUIPMENT"] = "SAFETY_EQUIPMENT";
    TenantRequestMaintenanceCategory["PREVENTATIVE_MAINTENANCE"] = "PREVENTATIVE_MAINTENANCE";
    TenantRequestMaintenanceCategory["PEST_MANAGEMENT"] = "PEST_MANAGEMENT";
    TenantRequestMaintenanceCategory["RESIDENT_EXPERIENCE"] = "RESIDENT_EXPERIENCE";
    TenantRequestMaintenanceCategory["HVAC"] = "HVAC";
    TenantRequestMaintenanceCategory["ARCHITECTURAL"] = "ARCHITECTURAL";
    TenantRequestMaintenanceCategory["OTHER"] = "OTHER";
})(TenantRequestMaintenanceCategory || (exports.TenantRequestMaintenanceCategory = TenantRequestMaintenanceCategory = {}));
