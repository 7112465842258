import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { DefaultListItemFontSize } from "screens/leases/leases/leasesList/leaseListItem";

interface ComponentProps {
  label: string;
  labelValue?: string;
  autoWidth?: boolean;
}

const UnitListItemLabel: React.FC<ComponentProps> = ({ label, labelValue, autoWidth }: ComponentProps) => {
  if (labelValue) {
    return (
      <View autoWidth={autoWidth} flex={autoWidth ? undefined : 1} flexDirection={"row"} noWrap>
        <Text
          color={"black"}
          fontSize={DefaultListItemFontSize}
          formatType={"number"}
          value={labelValue}
          marginLeft={10}
          marginRight={5}
        />
        <Text color={"black"} fontSize={DefaultListItemFontSize} value={label} />
      </View>
    );
  }
  return (
    <Text marginLeft={10} color={"black"} fontSize={DefaultListItemFontSize} formatType={"number"}>
      ---
    </Text>
  );
};

export default UnitListItemLabel;
