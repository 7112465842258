import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { MerchantAccountPlatform } from "@doorloop/dto";

import { stripeApi } from "api/stripeApi";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { View } from "DLUI/view";
import Text from "DLUI/text";

import AppStrings from "../../../../../../locale/keys";
import { IMessageBubble } from "../../../../../../assets";

import { BankAccountNumber } from "./components/bankAccountNumber";
import { StripeAlertNoActionRequired, DialogHeightNoAction } from "./components/stripeAlertNoActionRequired";
import { StripeDialogContainer } from "./components/stripeDialogContainer";
import { ElementPrefixedText } from "./components/elementPrefixedText";
import { StripeNeedAssistance } from "./components/StripeNeedAssistance";

import { useDialogStripeOnboarding } from "./hooks/useDialogStripeOnboarding";

const DialogHeightStripe = Math.min(window.innerHeight, 560);
const DialogHeightPayrix = Math.min(window.innerHeight, 560);
const DialogWidth = Math.min(window.innerWidth, 450);

const StripeActionRequiredOther = () => {
  const { t } = useTranslation();

  const {
    DialogViews,
    bankAccount,
    loadingDialogState,
    loadingDialogErrorText,
    viewIndex,
    setLoadingDialogState,
    showErrorMessage,
    onBackdropClick
  } = useDialogStripeOnboarding();

  const dimension = useMemo(
    () =>
      bankAccount?.merchantAccountPlatform === MerchantAccountPlatform.STRIPE
        ? { dialogHeight: DialogHeightStripe, dialogWidth: DialogWidth, backgroundHeight: 260 }
        : { dialogHeight: DialogHeightPayrix, dialogWidth: DialogWidth, backgroundHeight: 280 },
    [bankAccount?.merchantAccountPlatform]
  );

  const isActionRequired = useMemo(() => bankAccount && bankAccount.merchantAccountActionRequired, [bankAccount]);

  const handleSubmit = async () => {
    setLoadingDialogState(DialogState.Show);

    if (bankAccount?.id) {
      const response = await stripeApi.postStripeAccountLink(bankAccount.id);

      if (response?.data?.url) {
        window.open(response?.data?.url, "_self");
      } else {
        showErrorMessage(response.message || t(AppStrings.Common.GeneralError));
      }
    } else {
      showErrorMessage(t(AppStrings.Accounts.MerchantAccountNotFound));
    }
  };

  const renderView = ({ index }: any) => {
    if (index === DialogViews.LoadingView) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          errorText={loadingDialogErrorText}
          onRetryButtonPress={handleSubmit}
          didPressDismissButton={onBackdropClick}
        />
      );
    }
    if (index === DialogViews.ContentView && bankAccount) {
      if (isActionRequired) {
        return (
          <StripeDialogContainer
            onBackdropClick={onBackdropClick}
            onSubmit={handleSubmit}
            ctaActionText={AppStrings.Accounts.StripeVerifyInformation}
            dialogHeight={dimension.dialogHeight}
            backgroundHeight={dimension.backgroundHeight}
            backgroundMobileOffset={20}
            isLoading={loadingDialogState === DialogState.Show}
          >
            <View marginTop={20} paddingRight={20} paddingLeft={20} gap={10}>
              <Text align={"center"} color={"white"}>
                {t(
                  bankAccount.merchantAccountPlatform === MerchantAccountPlatform.STRIPE
                    ? AppStrings.Accounts.StripeActionRequiredOtherStripeContentI
                    : AppStrings.Accounts.StripeActionRequiredOtherPayrixContentI
                )}
              </Text>

              <Text align={"center"} color={"white"}>
                {t(
                  bankAccount.merchantAccountPlatform === MerchantAccountPlatform.STRIPE
                    ? AppStrings.Accounts.StripeActionRequiredOtherStripeContentII
                    : AppStrings.Accounts.StripeActionRequiredOtherPayrixContentII
                )}
              </Text>
            </View>

            <View marginTop={20} paddingLeft={20} paddingRight={40}>
              <BankAccountNumber
                accountName={bankAccount.name}
                last4digits={bankAccount.merchantAccountExternalAccountRequiredLast4Digits}
                isActionRequired
              />
            </View>

            <View marginTop={20} paddingRight={20} paddingLeft={20} gap={10}>
              <ElementPrefixedText
                text={t(AppStrings.Accounts.StripeActionRequiredOtherBulletI, {
                  firstName: bankAccount.merchantAccountRepresentativeFirstName,
                  lastName: bankAccount.merchantAccountRepresentativeLastName
                })}
              />
              <ElementPrefixedText
                text={t(AppStrings.Accounts.StripeActionRequiredOtherBulletII, {
                  firstName: bankAccount.merchantAccountRepresentativeFirstName
                })}
              />
              <ElementPrefixedText iconSrc={IMessageBubble} text={<StripeNeedAssistance />} />
            </View>
          </StripeDialogContainer>
        );
      }
      return <StripeAlertNoActionRequired dialogWidth={DialogWidth} onBackdropClick={onBackdropClick} />;
    }

    return <View />;
  };

  return (
    <DialogFrame
      useExperimentalDialogFrame
      onCloseButtonClick={onBackdropClick}
      renderView={renderView}
      height={isActionRequired ? dimension.dialogHeight : DialogHeightNoAction}
      width={dimension.dialogWidth}
      numViews={2}
      activeView={viewIndex}
      frameType={"contentOnly"}
    />
  );
};

export { StripeActionRequiredOther };
