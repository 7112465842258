import type { FC, PropsWithChildren, RefObject } from "react";
import React, { useCallback, useEffect, useState } from "react";
import { PopoverContent } from "./popoverContent";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { DialogContent } from "./dialogContent";
import { PopupContext } from "./context";
import type { PopoverProps } from "@material-ui/core";
import useIntercomBubble from "hooks/useIntercomBubble";

export interface PopupProps<TTrigger extends HTMLElement = HTMLElement> {
  triggerRef?: RefObject<TTrigger>;
  popoverProps?: Partial<PopoverProps>;
  onOpenChanged?: (open: boolean) => void;
  hideBackdrop?: boolean;
}

const PopupContent: FC<Omit<PopupProps, "onOpenChanged">> = ({ children, ...rest }) => {
  const { isMobile } = useResponsiveHelper();

  if (isMobile) return <DialogContent>{children}</DialogContent>;

  return <PopoverContent {...rest}>{children}</PopoverContent>;
};

export function Popup<TTrigger extends HTMLElement = HTMLElement>(props: PropsWithChildren<PopupProps<TTrigger>>) {
  const { triggerRef, onOpenChanged, hideBackdrop } = props;
  const [open, setOpen] = useState(false);
  const { hideIntercomBubble, showIntercomBubble } = useIntercomBubble();

  const closePopup = useCallback(() => {
    setOpen(false);
    onOpenChanged?.(false);
    showIntercomBubble();
  }, [onOpenChanged]);

  useEffect(() => {
    if (!triggerRef) return;
    const { current: triggerElement } = triggerRef;
    if (!triggerElement) return;

    const handleClick = () => {
      setOpen(true);
      onOpenChanged?.(true);
      hideIntercomBubble();
    };

    triggerElement.addEventListener("click", handleClick);

    return () => {
      triggerElement.removeEventListener("click", handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpenChanged]);

  return (
    <PopupContext.Provider value={{ onClose: closePopup, open, hideBackdrop }}>
      <PopupContent {...props} />
    </PopupContext.Provider>
  );
}
