import React, { useCallback, useEffect, useMemo, useState } from "react";
import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { filesApi } from "api/filesApi";
import { leaseRefundApi } from "api/leaseRefundApi";
import { tenantsApi } from "api/tenantsApi";
import { AddIcon } from "assets/icons";
import { Button } from "DLUI/button";
import { DialogSearchPanel, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import { FormikDatePicker, FormikReferenceLabel, FormikSwitchButton, Select, SwitchButton, TextField } from "DLUI/form";
import { Notes } from "DLUI/notes";
import { View } from "DLUI/view";
import type { GetAllAccountsQuery, LeaseDto } from "@doorloop/dto";
import { DuplicateDialogButtonLabelValues } from "@doorloop/dto";
import {
  AccountType,
  createValidator,
  ExpensePayToResourceType,
  LeaseChargeDto,
  LeaseRefundDto,
  LeaseTransactionLineBaseDto,
  LinkedResourceType,
  ObjectPermission,
  PaymentMethod,
  PrintChecksReportItemDto
} from "@doorloop/dto";
import type { FormikProps } from "formik";
import { FastField, FieldArray, Formik, getIn } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import LeasesList from "screens/leases/leases/leasesList/leasesList";
import SelectableLeaseListItem from "screens/leases/leases/leasesList/selectableLeaseListItem";
import DialogFrame, { getDialogFrameDimension, getDialogSelectionHeight } from "../components/dialogFrame";
import LeaseSelection from "../transactions/leaseSelection";
import TransactionLine from "../transactions/transactionLine";
import AccountBalanceLoader from "./accountBalanceLoader";
import { SeparationLine } from "DLUI/separatorView";
import DialogAmountView from "../components/totalAmountView";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { leaseChargeApi } from "api/leaseChargeApi";
import ReconciledNotificationView from "DLUI/dialogs/components/reconciledNotificationView";
import { OutgoingPaymentNotification } from "screens/outgoingPayments/paymentDialogNotification/outgoingPaymentNotification";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { ArticleIdsEnum, HelpTypeEnum, VideoUrlsEnum } from "DLUI/screen/helpPanel/types";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { useLastArrayItemRef } from "hooks/useLastArrayItemRef";
import { ListInputsContainer } from "DLUI/listItems";
import { usePaymentMethodBySubscription } from "../../../../hooks/usePaymentMethodBySubscription";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { useUserType } from "../../../../hooks/useUserType";
import { ActivityLabel } from "../../activityLabel/activityLabel";
import { QueryParams } from "utils/queryParams";
import { cleanLeaseRefundDataForDuplicateMode, DUPLICATE_MODE_QUERY_PARAMS } from "DLUI/dialogs/duplicateModeHelper";
import { DialogHeaderActionButtons } from "DLUI/actionButtons/dialogHeaderActionButtons";
import { useAnalyticsService } from "hooks/useAnalyticsService";

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
  dialogFrameWidth?: number;
  dialogFrameHeight?: number;
  transactionCreatedText: string;
  loadingTransactionDataText: string;
  loadingTransactionDataSuccessText: string;
  updatingTransactionText: string;
  transactionUpdatedText: string;
}

let formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

const helpObject: HelpPanelProps = {
  description: AppStrings.Leases.LeaseCharge.RefundHelpPanel.Description,
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Leases.LeaseCharge.RefundHelpPanel.GiveRefund,
      articleId: ArticleIdsEnum.LEASE_GIVE_REFUND
    },
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Leases.LeaseCharge.RefundHelpPanel.EditDeleteRefund,
      articleId: ArticleIdsEnum.EDIT_DELETE_LEASE_TRANSACTIONS
    },
    {
      type: HelpTypeEnum.WATCH_VIDEO,
      topic: AppStrings.Common.WatchExample,
      href: VideoUrlsEnum.LEASE_OVERVIEW,
      dialogTitle: AppStrings.Leases.LeaseCharge.RefundHelpPanel.DialogTitle,
      startTime: 239
    }
  ]
};

const helpObjectHOA: HelpPanelProps = {
  description: AppStrings.Leases.LeaseCharge.RefundHelpPanel.Description,
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Leases.LeaseCharge.RefundHelpPanel.GiveRefund,
      articleId: ArticleIdsEnum.HOA_GIVE_REFUND
    },
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Leases.LeaseCharge.RefundHelpPanel.EditDeleteRefund,
      articleId: ArticleIdsEnum.HOA_EDIT_OR_DELETE_REFUND
    },
    {
      type: HelpTypeEnum.WATCH_VIDEO,
      topic: AppStrings.Common.WatchExample,
      href: VideoUrlsEnum.LEASE_OVERVIEW,
      dialogTitle: AppStrings.Leases.LeaseCharge.RefundHelpPanel.DialogTitle,
      startTime: 239
    }
  ]
};

const RefundDialog: React.FC<ComponentProps> = ({
  onBackdropClick,
  onClose,
  dialogTitle,
  transactionCreatedText,
  loadingTransactionDataText,
  loadingTransactionDataSuccessText,
  updatingTransactionText,
  transactionUpdatedText
}: ComponentProps) => {
  const { isHOAUser } = useUserType();

  const { t } = useTranslation();

  const paymentMethods = usePaymentMethodBySubscription();

  const { leaseId, transactionId: paramsTransactionId } = useParams<any>();
  const queryParams = new QueryParams();
  const queryTransactionId = queryParams.get(DUPLICATE_MODE_QUERY_PARAMS.transactionId) as string | undefined;
  const transactionId = queryTransactionId || paramsTransactionId;
  const editMode = Boolean(transactionId);
  const duplicateMode = Boolean(queryTransactionId);
  const permission: AnyPermissionClearance = {
    permission: ObjectPermission.leaseRefunds,
    field: editMode ? "edit" : "create"
  };

  const { dispatchAnalytics } = useAnalyticsService();

  const { isMobile, screenContainerPadding, verticalInputPadding } = useResponsiveHelper();

  const [viewIndex, setViewIndex] = useState(0);
  const [renderSelectionList, setRenderSelectionList] = useState<boolean>(false);
  const [currentLeaseId, setCurrentLeaseId] = useState<string | undefined>(leaseId);
  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);
  const [selectLeaseFilterObj, setSelectLeaseFilterObj] = useState({
    filter_text: ""
  });
  const [showLeaseSelectionError, setShowLeaseSelectionError] = useState<boolean>(false);
  const [leaseSelectionErrorText, setLeaseSelectionErrorText] = useState<string>("");
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    editMode ? DialogState.Show : DialogState.Hidden
  );
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const [loadingDialogSuccessText, setLoadingDialogSuccessText] = useState<string>(transactionCreatedText);
  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState<string>(loadingTransactionDataText);
  const [chargeData, setChargeData] = useState<LeaseChargeDto | undefined>();
  const [shouldrenderForm, setShouldRenderForm] = useState<boolean>(!editMode);
  const [shouldRenderTopSection, setShouldRenderTopSection] = useState<boolean>(true);
  const [propertyId, setPropertyId] = useState<string | undefined>();
  const [depositAccountId, setDepositAccountId] = useState<string | undefined>();

  const [showCheckNumberInput, setShowCheckNumberInput] = useState<boolean>(false);
  const [printCheckLater, setPrintCheckLater] = useState<boolean>(false);
  const [payFromAccountDefaultValue, setPayFromAccountDefaultValue] = useState<string | undefined>();
  const [payToTenantDefaultValue, setPayToTenantDefaultValue] = useState<string | undefined>();

  const [payToTenantQueryParams, setPayToTenantQueryParams] = useState<Record<string, string>>({});
  const [depositAccountQueryParams, setDepositAccountQueryParams] = useState<GetAllAccountsQuery>(
    editMode
      ? {}
      : {
          filter_type: AccountType.ASSET_BANK,
          filter_active: true
        }
  );

  const [automaticallyCreateLeaseCharge, setAutomaticallyCreateLeaseCharge] = useState<boolean>(true);

  const [ePayBankAccountSelected, setEPayBankAccountSelected] = useState<boolean>(false);

  const [payeeSupportsEPay, setPayeeSupportsEPay] = useState<boolean | null>(null);

  useEffect(() => {
    if (currentLeaseId && !editMode) {
      setDepositAccountQueryParams({
        filter_type: AccountType.ASSET_BANK,
        filter_lease: currentLeaseId
      });
      setPayToTenantQueryParams({
        filter_lease: currentLeaseId
      });

      formikGlobalRef?.setFieldValue("payToTenant", undefined);
    }

    formikGlobalRef?.setFieldValue("lease", currentLeaseId);
    setTimeout(() => {
      if (formikGlobalRef) {
        const errorText = getIn(formikGlobalRef.errors, "lease");
        const touchedVal = getIn(formikGlobalRef.touched, "lease");

        setShowLeaseSelectionError(touchedVal && errorText !== undefined);
        if (errorText !== undefined) {
          setLeaseSelectionErrorText(t(AppStrings.Leases.LeaseCharge.PressToSelectLease));
        }
      }
    }, 0);
  }, [currentLeaseId]);

  useEffect(() => {
    if (editMode) {
      loadRefundData();
    }
  }, [duplicateMode]);

  const initFormvalues = (): LeaseRefundDto => {
    if (editMode && chargeData) {
      return chargeData;
    }
    const _leaseRefundDto = new LeaseRefundDto();
    _leaseRefundDto.date = moment().format("YYYY-MM-DD").toString();
    _leaseRefundDto.lines = [new LeaseTransactionLineBaseDto()];
    return _leaseRefundDto;
  };

  const isValidForm = async (formikRef: FormikProps<LeaseRefundDto>) => {
    formikRef.setFieldTouched("payFromAccount");
    formikRef.setFieldTouched("paymentMethod");
    formikRef.setFieldTouched("payToTenant");
    formikRef.setFieldTouched("checkInfo.checkNumber");

    if (formikRef.values && formikRef.values.lines) {
      formikRef.values.lines.forEach((arrayItem: any, arrayIndex: number) => {
        formikRef.setFieldTouched(`lines[${arrayIndex}].account`);
        formikRef.setFieldTouched(`lines[${arrayIndex}].amount`);
      });
    }

    const errors = (await formikRef.validateForm()) as any;
    const inValidLease = Boolean(errors.lease);
    setShowLeaseSelectionError(inValidLease);
    if (inValidLease && formikGlobalRef) {
      const errorText = getIn(formikGlobalRef.errors, "lease");
      if (errorText !== undefined) {
        setLeaseSelectionErrorText(t(AppStrings.Leases.LeaseCharge.PressToSelectLease));
      }
    }
    return _.isEmpty(errors);
  };

  const validateForm = createValidator(LeaseRefundDto);

  const loadRefundData = async () => {
    if (editMode && transactionId) {
      setLoadingDialogState(DialogState.Show);

      const response = await leaseRefundApi.get(transactionId).catch((error) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });
      if (response && response.status && response.data) {
        setCurrentLeaseId(response.data.lease);
        setDepositAccountId(response.data.payFromAccount);
        setPayFromAccountDefaultValue(response.data.payFromAccount);
        setPayToTenantDefaultValue(response.data.payToTenant);
        if (duplicateMode) {
          setAutomaticallyCreateLeaseCharge(false);
          setChargeData(cleanLeaseRefundDataForDuplicateMode(response.data));
        } else {
          setChargeData(response.data);
        }
        if (response.data.checkInfo) {
          setShowCheckNumberInput(response.data.paymentMethod === PaymentMethod.CHECK);
          setPrintCheckLater(response.data.checkInfo?.printLater || false);
        }
        setLoadingDialogSuccessText(loadingTransactionDataSuccessText);
        setLoadingDialogState(DialogState.Success);
        setTimeout(() => {
          setLoadingDialogState(DialogState.Hidden);
          setShouldRenderForm(true);
        }, 500);
      } else {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(response ? response.message : "");
      }
    }
  };

  const createTransaction = async () => {
    if (formikGlobalRef) {
      const chargeValues = _.cloneDeep(formikGlobalRef.values);
      setViewIndex(2);
      setLoadingDialogState(DialogState.Show);
      const results = await leaseRefundApi.create(chargeValues, { idKey: "lease" }).catch((error) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });
      if (results && results.status && results.data) {
        await filesApi
          .uploadFiles(attachments, results.data.id!, LinkedResourceType.LeaseRefund)
          .catch((error: string) => {
            setLoadingDialogState(DialogState.Error);
            setLoadingDialogErrorText(error);
          });
        if (automaticallyCreateLeaseCharge) {
          const charge = new LeaseChargeDto({
            lines: results.data.lines,
            lease: results.data.lease,
            totalAmount: results.data.totalAmount,
            memo: results.data.memo,
            date: results.data.date
          });
          const { status, message } = await leaseChargeApi.create(charge, { idKey: "lease" });
          if (!status) {
            setLoadingDialogErrorText(message || t(AppStrings.Common.GeneralError));
            setLoadingDialogState(DialogState.Error);
            return;
          }
        }
        setLoadingDialogState(DialogState.Success);
        setTimeout(() => {
          onClose();
        }, 500);
      } else {
        setLoadingDialogErrorText(results ? results.message : "");
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const updateTransaction = async () => {
    if (formikGlobalRef && transactionId) {
      const chargeValues = _.cloneDeep(formikGlobalRef.values);
      setViewIndex(2);
      //setShouldRenderForm(false);
      setLoadingDialogState(DialogState.Show);
      setLoadingDialogLoadingText(updatingTransactionText);
      const results = await leaseRefundApi.update(transactionId, chargeValues).catch((error) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });

      if (results && results.status && results.data) {
        await filesApi
          .uploadFiles(attachments, results.data.id!, LinkedResourceType.LeaseRefund)
          .catch((error: string) => {
            setLoadingDialogState(DialogState.Error);
            setLoadingDialogErrorText(error);
          });
        setLoadingDialogSuccessText(transactionUpdatedText);
        setLoadingDialogState(DialogState.Success);
        setTimeout(() => {
          onClose();
        }, 500);
      } else {
        setLoadingDialogErrorText(results ? results.message : "");
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const didPressSaveButton = async () => {
    if (formikGlobalRef !== null) {
      const isValid = await isValidForm(formikGlobalRef);
      if (isValid) {
        if (editMode && !duplicateMode) {
          await updateTransaction();
        } else {
          await createTransaction();
        }
      }
    }
  };

  const getTotalAmount = () => {
    let amount = 0;

    if (formikGlobalRef && formikGlobalRef.values) {
      formikGlobalRef.values.lines.forEach((currentLine: LeaseTransactionLineBaseDto) => {
        if (currentLine.amount && currentLine.amount > 0) {
          amount += currentLine.amount;
        }
      });
    }
    return amount;
  };

  const fieldArrayName = "lines";

  const addLine = useCallback(() => {
    const lines = formikGlobalRef?.values.lines || [];
    formikGlobalRef?.setFieldValue("lines", [...lines, new LeaseTransactionLineBaseDto()]);
  }, []);

  const lastLineRef = useLastArrayItemRef<HTMLDivElement>(addLine, []);

  const renderLines = () => (
    <FieldArray
      name={fieldArrayName}
      render={(arrayHelpers) => {
        if (formikGlobalRef && formikGlobalRef.values) {
          const formLines = formikGlobalRef.values.lines;

          const lines = formLines.map((currentLine: LeaseTransactionLineBaseDto, lineIndex: number) => (
            <div style={{ width: "100%" }} key={"LTL" + lineIndex}>
              <View shouldShow showAnimation={"fade-in"} hideAnimation={"fade-out"}>
                <TransactionLine
                  domRef={lineIndex === formLines.length - 1 ? lastLineRef : undefined}
                  transactionItem={currentLine}
                  accountFilterTypes={"filter_leaseChargeItem"}
                  lineIndex={lineIndex}
                  key={lineIndex}
                  arrayHelpers={arrayHelpers}
                  name={fieldArrayName}
                />
              </View>
            </div>
          ));

          return (
            <View>
              <ListInputsContainer>{lines}</ListInputsContainer>
              <View alignItems={"flex-end"} flexDirection={"row"}>
                <Grid item lg={8} md={8} xs={8}>
                  <Button
                    color={"lightBlue"}
                    type={"inlineText"}
                    actionText={AppStrings.Leases.LeaseCharge.AddLineItem}
                    onClick={addLine}
                    LeftIcon={AddIcon}
                    applyColorForIcons
                    iconSize={15}
                  />
                </Grid>
              </View>
            </View>
          );
        }
        return <div />;
      }}
    />
  );

  const renderSummaryLine = () => {
    const totalAmount = getTotalAmount();
    return (
      <View flexDirection={"row"}>
        <DialogAmountView amount={totalAmount} title={AppStrings.Leases.LeaseCharge.TotalRefund} />
      </View>
    );
  };

  const onFileReceived = (files: FileListItemProps[]) => {
    setAttachments(files);
  };

  const renderAttachments = () => (
    <View marginTop={20} marginBottom={20}>
      <FormAttachments
        editMode={editMode}
        resourceId={chargeData ? chargeData.id! : undefined}
        resourceType={LinkedResourceType.LeaseRefund}
        onFileReceived={onFileReceived}
        files={attachments}
      />
    </View>
  );

  const didPressSelectLease = () => {
    setViewIndex(1);
    setTimeout(() => {
      setRenderSelectionList(true);
    }, 500);
  };

  const refreshFormTopSection = () => {
    if (formikGlobalRef) {
      setShouldRenderTopSection(false);
      setShowCheckNumberInput(false);
      formikGlobalRef.setFieldValue("payFromAccount", undefined);
      formikGlobalRef.setFieldValue("paymentMethod", undefined);
      formikGlobalRef.setFieldValue("payToTenant", undefined);
      formikGlobalRef.setFieldValue("checkInfo.checkNumber", undefined);

      setTimeout(() => {
        setShouldRenderTopSection(true);
      }, 0);
    }
  };

  const didPressRemoveLease = () => {
    setCurrentLeaseId(undefined);
    setPropertyId(undefined);
    setDepositAccountId(undefined);
    refreshFormTopSection();
  };

  const didChangePaymentMethod = (nextValue: string) => {
    if (nextValue === PaymentMethod.CHECK) {
      setShowCheckNumberInput(true);
    } else {
      setShowCheckNumberInput(false);
      formikGlobalRef?.setFieldValue("checkInfo", undefined);
    }
  };

  const onLeaseSelection = (lease: LeaseDto) => {
    setPropertyId(lease.property);
  };

  const didSelectDepositAccount = (event: object, value: any) => {
    if (value && value.id) {
      setDepositAccountId(value.id);
      setEPayBankAccountSelected(Boolean(value.outgoingEPayEnabled));
    } else {
      setDepositAccountId(undefined);
      setEPayBankAccountSelected(false);
    }
  };

  const payToTenantOnChange = (event: object, value: any) => {
    const ePaySupport = Boolean(value?.outgoingEPay?.enabled);
    if (ePaySupport && ePayBankAccountSelected) {
      formikGlobalRef?.setFieldValue("paymentMethod", PaymentMethod.EPAY);
    }
    setPayeeSupportsEPay(value === null ? null : ePaySupport);
  };

  const didChangePrintCheckLaterSwitch = (nextSwitchState: boolean) => {
    setPrintCheckLater(nextSwitchState);
  };

  const renderFormTopSection = () => {
    if (!shouldRenderTopSection) {
      return null;
    }
    const vals = formikGlobalRef?.values as LeaseRefundDto;
    const infoDto = new PrintChecksReportItemDto({
      amount: vals.ePayInfo?.amount || vals.totalAmount,
      payToResourceId: vals.payToTenant,
      payToResourceType: ExpensePayToResourceType.TENANT,
      journalEntry: transactionId,
      payFromAccount: vals.payFromAccount
    });
    return (
      <View>
        {transactionId && vals.paymentMethod === PaymentMethod.EPAY && (
          <OutgoingPaymentNotification
            dto={infoDto}
            payeeSupportsEPay={payeeSupportsEPay}
            closeCurrentDialog={onBackdropClick}
            journalEntryId={transactionId}
            status={vals.ePayInfo?.status}
            method={vals.ePayInfo?.method}
            paymentNumber={vals.ePayInfo?.number}
          />
        )}
        <ReconciledNotificationView register={chargeData?.register} />
        <View noWrap={!isMobile} marginTop={20} flexDirection={"row"} gap={20}>
          <Grid zeroMinWidth item xs={12} sm={6}>
            <LeaseSelection
              didPressSelectLease={didPressSelectLease}
              leaseId={currentLeaseId}
              errorTex={showLeaseSelectionError ? leaseSelectionErrorText : undefined}
              onLeaseSelection={onLeaseSelection}
            />
          </Grid>
          <Grid zeroMinWidth item xs={12} sm={3}>
            <FastField
              component={FormikDatePicker}
              uniqueKey={"chargeDueDate"}
              label={AppStrings.Leases.NewLease.LeaseRent.DueDate}
              name={"date"}
              noMargin
              required
            />
          </Grid>
          <Grid zeroMinWidth item xs={12} sm={3}>
            <FastField
              component={FormikReferenceLabel}
              name={"reference"}
              backgroundColor={"dark"}
              valueTextTransform={"uppercase"}
            />
          </Grid>
        </View>
        <SeparationLine width={"100%"} height={1} marginTop={20} />
        <View flexDirection={"row"}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <View paddingRight={verticalInputPadding} marginTop={20}>
              <FormikCachedAsyncAutoComplete
                uniqueIndex={"TS"}
                apiHandler={tenantsApi}
                displayNameKey={"name"}
                filterFieldName={"filter_text"}
                filterFieldValue={"name"}
                selectionFields={["id", "class", "outgoingEPay"]}
                name={"payToTenant"}
                queryParams={payToTenantQueryParams}
                label={t(AppStrings.Leases.LeaseTransaction.Refund.PayToTenant)}
                required
                onChange={payToTenantOnChange}
                triggerOnChangeOnInitialLoad
              />
            </View>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <View paddingRight={verticalInputPadding} paddingLeft={verticalInputPadding} marginTop={20}>
              <BankAccountFormikAutoCompleteField
                uniqueIndex={"TS"}
                selectionFields={["id", "class", "outgoingEPayEnabled"]}
                name={"payFromAccount"}
                queryParams={depositAccountQueryParams}
                label={t(AppStrings.Leases.LeaseTransaction.Refund.PayFromAccount)}
                onChange={didSelectDepositAccount}
                defaultValue={payFromAccountDefaultValue}
                triggerOnChangeOnInitialLoad
              />
            </View>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <View marginTop={20} paddingLeft={verticalInputPadding}>
              <AccountBalanceLoader propertyId={propertyId} depositAccountId={depositAccountId} />
            </View>
          </Grid>
        </View>
        <View flexDirection={"row"}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <View marginTop={20}>
              {ePayBankAccountSelected ? (
                <FastField
                  component={Select}
                  name={`paymentMethod`}
                  label={AppStrings.Leases.LeaseTransaction.Payment.PaymentMethod}
                  required
                  uniqueKey={"paymentMethod"}
                  selectionEnum={paymentMethods}
                  onChange={didChangePaymentMethod}
                  translationKey={"paymentMethod"}
                  paddingRight={verticalInputPadding}
                  viewOnly={Boolean(formikGlobalRef?.values.ePayInfo?.status)}
                  enableEPay
                  fullWidthViewOnly
                />
              ) : (
                <FastField
                  component={Select}
                  name={`paymentMethod`}
                  label={AppStrings.Leases.LeaseTransaction.Payment.PaymentMethod}
                  required
                  uniqueKey={"paymentMethod"}
                  selectionEnum={paymentMethods}
                  onChange={didChangePaymentMethod}
                  translationKey={"paymentMethod"}
                  paddingRight={verticalInputPadding}
                />
              )}
            </View>
          </Grid>

          {showCheckNumberInput && !printCheckLater ? (
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <FastField
                component={TextField}
                label={t(AppStrings.Leases.LeaseTransaction.Payment.CheckNumber)}
                name={"checkInfo.checkNumber"}
                paddingRight={verticalInputPadding}
                marginTop={20}
              />
            </Grid>
          ) : null}

          {showCheckNumberInput ? (
            <Grid alignItems={"center"} container item xs={12} sm={4} md={4} lg={4}>
              <View marginTop={10}>
                <FastField
                  component={FormikSwitchButton}
                  name={"checkInfo.printLater"}
                  label={AppStrings.Leases.LeaseTransaction.Refund.AddToPrintQueue}
                  onChange={didChangePrintCheckLaterSwitch}
                  paddingRight={verticalInputPadding}
                  marginTop={20}
                />
              </View>
            </Grid>
          ) : null}
        </View>
      </View>
    );
  };

  const renderNotes = () => (
    <View marginTop={20}>
      <FastField component={Notes} height={30} name={"memo"} />
    </View>
  );

  const renderAutomaticallyCreateLeaseCharge = () => {
    if (!editMode || duplicateMode) {
      return (
        <SwitchButton
          label={AppStrings.Leases.LeaseTransaction.Refund.OverpaymentRefund}
          checked={automaticallyCreateLeaseCharge}
          onChange={(nextSwitchState) => setAutomaticallyCreateLeaseCharge(nextSwitchState)}
          marginTop={isMobile ? 24 : 16}
        />
      );
    }
  };

  const renderForm = () => {
    const formInitialValues = initFormvalues();
    return (
      <Formik initialValues={formInitialValues} onSubmit={() => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;
          return (
            <View flex={1} width={"100%"}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <View
                  paddingLeft={screenContainerPadding}
                  paddingRight={screenContainerPadding}
                  flexDirection={"column"}
                >
                  <RestrictedPermissionAccess clearance={permission} showNoAccess>
                    <View>
                      {renderFormTopSection()}
                      <SeparationLine marginTop={20} width={"100%"} height={1} />
                    </View>
                    {renderLines()}
                    {renderNotes()}
                    {renderAutomaticallyCreateLeaseCharge()}
                    {renderSummaryLine()}
                    {renderAttachments()}
                  </RestrictedPermissionAccess>
                  {editMode && <ActivityLabel item={chargeData} />}
                </View>
              </MuiPickersUtilsProvider>
            </View>
          );
        }}
      </Formik>
    );
  };

  const dialogHeight = getDialogFrameDimension("height", 920);

  const renderLeaseSelectionList = () => {
    const didChangeSearchQuery = (value: string) => {
      setSelectLeaseFilterObj({
        filter_text: value
      });
    };

    const onListItemPress = (leaseId: string) => {
      setViewIndex(0);
      setCurrentLeaseId(leaseId);
    };

    return (
      <View paddingLeft={screenContainerPadding} paddingRight={screenContainerPadding}>
        <View autoWidth>
          <DialogSearchPanel borderRadius={30} onChange={didChangeSearchQuery} />
        </View>
        <View id={"selectableLeaseContainer"} height={getDialogSelectionHeight(dialogHeight, true)} overflow={"scroll"}>
          {renderSelectionList ? (
            <LeasesList
              ListItem={SelectableLeaseListItem}
              filterObj={selectLeaseFilterObj}
              didPressListItem={onListItemPress}
              stickyHeaderId={"selectableLeaseContainer"}
              scrollableTarget={"selectableLeaseContainer"}
              removeDefaultBottomPadding
              selectableItem
            />
          ) : null}
        </View>
      </View>
    );
  };

  const renderView = ({ index }: any) => {
    const onRetryButtonPress = async () => {
      await didPressSaveButton();

      //createTransaction();
    };
    const didPressDismissButton = () => {
      if (onBackdropClick) {
        onBackdropClick();
      } else {
        onClose();
      }
    };

    if (index === 0) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={loadRefundData}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }
      if (shouldrenderForm) {
        return renderForm();
      }
      return <div />;
    }
    if (index === 1) {
      return renderLeaseSelectionList();
    }
    if (index === 2) {
      return (
        <View alignItems={"center"} justifyContent={"center"} height={"100%"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
          />
        </View>
      );
    }
    return <div />;
  };

  const _onBackdropClick = () => {
    if (viewIndex === 1) {
      setViewIndex(0);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const handleDuplicateClick = () => {
    queryParams.set(DUPLICATE_MODE_QUERY_PARAMS.transactionId, transactionId || "");
    queryParams.historyPush();
    dispatchAnalytics("button_click", {
      label: DuplicateDialogButtonLabelValues.DUPLICATE_JOURNAL_ENTRY
    });
  };
  const renderHeaderActionButtons = () => (
    <DialogHeaderActionButtons
      onDuplicateClick={handleDuplicateClick}
      clearance={permission}
      hideDeleteButton
      hideDuplicateButton={duplicateMode || !editMode}
    />
  );

  const renderActionPanelButtons = () => (
    <FormActionButtons
      clearance={permission}
      propsSubButton={{ onClick: _onBackdropClick }}
      propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
    />
  );

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }
    if (viewIndex === 2) {
      return "contentOnly";
    }
    return "sectionTitleFrame";
  }, [viewIndex, loadingDialogState]);

  const currentTitle = useMemo(() => {
    if (viewIndex === 0) {
      return dialogTitle;
    }

    if (viewIndex === 1) {
      return AppStrings.Leases.LeaseCharge.SelectLease;
    }
    return "";
  }, [viewIndex]);

  const duplicateModeTitle = useMemo(
    () =>
      duplicateMode
        ? `${t(AppStrings.Leases.LeaseTransaction.Refund.LeaseRefund)} (${t(AppStrings.Common.Duplicated)})`
        : "",
    [duplicateMode, t]
  );

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={duplicateModeTitle || currentTitle}
      width={getDialogFrameDimension("width", 1000)}
      height={dialogHeight}
      renderView={renderView}
      numViews={3}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      RenderHeaderActionButtons={renderHeaderActionButtons}
      frameType={frameType}
      helpPanel={!isHOAUser ? helpObject : helpObjectHOA}
    />
  );
};

export default RefundDialog;
