import moment from "moment";

import { DataCy, ReportId, ReportPermission } from "@doorloop/dto";

import { ownerPortalNavigation } from "utils/ownerPortalNavigation";
import type { ReportScreenItemType } from "DLUI/report/screen/reportScreenItemType";
import type { Translation } from "locale/types";

const didPressTransactionsDetails = (propertyId?: string): void => {
  const params = {
    period: "last-7-days"
  };

  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  ownerPortalNavigation.runAccountTransactionsReport(undefined, params);
};

const didPressBankBalanceByProperty = (propertyId?: string): void => {
  const params = {
    filter_asOfDate: moment().format("YYYY-MM-DD").toString(),
    groupBy: "property"
  };

  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  ownerPortalNavigation.runBankBalanceByPropertyReport(params);
};

const didPressGeneralLedger = (propertyId?: string): void => {
  const params = {
    period: "last-7-days"
  };

  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  ownerPortalNavigation.runAccountTransactionsReport(undefined, params);
};

const didPressBudgetVsActual = (propertyId?: string) => {
  ownerPortalNavigation.runBudgetVsActualReport(
    propertyId
      ? {
          property: propertyId
        }
      : undefined
  );
};

export const ACCOUNTING_REPORT_ITEMS: Array<ReportScreenItemType<Translation>> = [
  {
    description: "reports.reportsScreen.transactionDetails",
    onPress: didPressTransactionsDetails,
    permission: ReportPermission.transactionsDetails,
    id: ReportId.TRANSACTIONS_DETAILS,
    dataCy: DataCy.reports.screenItems.accounting.transactionDetails
  },
  {
    description: "reports.reportsScreen.bankbalanceByProperty",
    onPress: didPressBankBalanceByProperty,
    permission: ReportPermission.bankBalanceByProperty,
    id: ReportId.BANK_BALANCE_BY_PROPERTY,
    dataCy: DataCy.reports.screenItems.accounting.bankBalanceByProperty
  },
  {
    description: "reports.reportsScreen.generalLedger",
    onPress: didPressGeneralLedger,
    permission: ReportPermission.transactionsDetails,
    id: ReportId.GENERAL_LEDGER,
    dataCy: DataCy.reports.screenItems.accounting.generalLedger
  },
  {
    description: "reports.reportsScreen.runBudgetVsActual",
    onPress: didPressBudgetVsActual,
    permission: ReportPermission.budgetVsActual,
    id: ReportId.BUDGET_VS_ACTUAL,
    dataCy: DataCy.reports.screenItems.accounting.budgetVsActual
  }
];
