import type { FC } from "react";
import React, { useEffect, useState } from "react";
import AppStrings from "locale/keys";
import { View } from "DLUI/view";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import type { PrintedCheck } from "@doorloop/dto";

import { checksApi } from "api/checksApi";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "hooks/useSearchParams";

interface ComponentsProps {
  onClose: () => void;
}

const numOfViews = 1;
const dialogWidth = 584;
const dialogHeight = 636;

const RemoveCheckDialog: FC<ComponentsProps> = ({ onClose }: ComponentsProps) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");

  useEffect(() => {
    removeCheck();
  }, []);

  const showErrorMessage = (error: string) => {
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(error);
  };

  const removeCheck = async () => {
    setLoadingDialogState(DialogState.Show);
    const journalEntry = searchParams.get("journalEntry") || undefined;
    const printedChecks: PrintedCheck[] = [
      {
        journalEntry
      }
    ];

    const res = await checksApi.postPrintedChecks({ printedChecks }).catch((e) => showErrorMessage(e));
    if (res && res.data) {
      onClose();
    } else {
      showErrorMessage(res && res.message ? res.message : t(AppStrings.Common.GeneralError));
    }
  };

  const renderReviewDialogView = () => (
    <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
      <LoadingDialog
        errorText={loadingDialogErrorText}
        dialogState={loadingDialogState}
        onRetryButtonPress={removeCheck}
        didPressDismissButton={onClose}
      />
    </View>
  );

  return (
    <DialogFrame
      onCloseButtonClick={onClose}
      width={dialogWidth}
      height={dialogHeight}
      renderView={renderReviewDialogView}
      numViews={numOfViews}
      activeView={0}
      frameType={"contentOnly"}
      title={AppStrings.Common.PrintChecks.Preview.Dialog.Header}
    />
  );
};

export default RemoveCheckDialog;
