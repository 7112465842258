import { useMutation } from "@tanstack/react-query";

import { createMutationStore } from "../createMutationStore";
import { createQueryStore } from "../createQueryStore";
import { expensesApi } from "api/expensesApi";

export const expensesStore = {
  queries: createQueryStore("expenses", expensesApi),
  mutations: {
    ...createMutationStore("expenses", expensesApi),
    useVoidCheck: () =>
      useMutation({
        mutationKey: ["expenses-void-check"],
        mutationFn: async (transactionId: string) => await expensesApi.voidCheck(transactionId)
      })
  }
};
