import { IconButton } from "DLUI/form";
import { DuplicateIconAlt, PrintIcon, TrashIconOutline } from "../../../assets";
import { View } from "DLUI/view";
import ColorsEnum from "../../../utils/colorsEnum";
import { SeparationLine } from "DLUI/separatorView";
import type { FC } from "react";
import React from "react";
import { useResponsiveHelper } from "contexts/responsiveContext";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { usePermission } from "screens/settings/userRoles/usePermission";
import { MobileDialogHeaderActionButtons } from "DLUI/actionButtons/mobileDialogHeaderActionButtons";
import { TextTooltip } from "DLUI/popover/textTooltip";
import AppStrings from "../../../locale/keys";
import { useTheme } from "@material-ui/core/styles";
import { DataCy } from "@doorloop/dto";
import { LoadingStatus } from "common/native/types";

interface DialogHeaderActionButtonsProps {
  onDeleteClick?: VoidFunction;
  onDuplicateClick: VoidFunction;
  clearance?: AnyPermissionClearance;
  hideDeleteButton?: boolean;
  hideDuplicateButton?: boolean;
  onPrintClick?: VoidFunction;
  printLoadingStatus?: LoadingStatus;
}

export const DialogHeaderActionButtons: FC<DialogHeaderActionButtonsProps> = ({
  onDeleteClick,
  onDuplicateClick,
  clearance,
  hideDeleteButton,
  hideDuplicateButton,
  onPrintClick,
  printLoadingStatus
}) => {
  const { isMobile } = useResponsiveHelper();
  const theme = useTheme();
  const { hasPermission } = usePermission();
  const { permission, requiredLevel } = clearance || {};
  const hasDeleteClearance = !clearance || hasPermission(permission, "delete", requiredLevel);
  const hasCreateClearance = !clearance || hasPermission(permission, "create", requiredLevel);

  return isMobile ? (
    <MobileDialogHeaderActionButtons
      hasDeleteClearance={hasDeleteClearance}
      hasCreateClearance={hasCreateClearance}
      onDeleteClick={onDeleteClick}
      onDuplicateClick={onDuplicateClick}
      hideDeleteButton={hideDeleteButton}
      hideDuplicateButton={hideDuplicateButton}
    />
  ) : (
    <View flexDirection={"row"} alignItems={"center"} width={"auto"} gap={10} marginRight={10}>
      {hasDeleteClearance && !hideDeleteButton && (
        <>
          <IconButton
            size={20}
            Icon={TrashIconOutline}
            pathColor={"error"}
            onClick={onDeleteClick}
            dataCy={DataCy.dialogActionButtons.delete}
          />
          <SeparationLine color={ColorsEnum.LightBlackFadedMore} height={16} />
        </>
      )}
      {hasCreateClearance && !hideDuplicateButton && (
        <TextTooltip value={AppStrings.Common.Duplicate} useButton={false}>
          <IconButton
            size={24}
            pathColor={"current-color"}
            style={{ color: theme.newPalette.text.secondary }}
            Icon={DuplicateIconAlt}
            onClick={onDuplicateClick}
            dataCy={DataCy.dialogActionButtons.duplicate}
          />
        </TextTooltip>
      )}
      {onPrintClick && (
        <TextTooltip value={AppStrings.Common.DownloadPrintable} useButton={false}>
          <IconButton
            size={24}
            pathColor={"current-color"}
            style={{ color: theme.newPalette.text.secondary }}
            Icon={PrintIcon}
            onClick={() => onPrintClick()}
            dataCy={DataCy.dialogActionButtons.print}
            loadingInProgress={printLoadingStatus === LoadingStatus.loading}
          />
        </TextTooltip>
      )}
    </View>
  );
};
