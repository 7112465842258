import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";
import { lazy } from "react";

const TenantsListScreen = lazy(() => import("screens/tenants/tenantsListScreen"));
const TenantDetails = lazy(() => import("screens/tenants/tenantDetails/tenantDetails"));

const TenantsRouter = () => (
  <PageRouter>
    <LazyRoute path={Routes.TENANTS_GLOBAL_NEW} render={TenantsListScreen} />
    <LazyRoute path={Routes.TENANT_DETAILS_GLOBAL_NEW} render={TenantDetails} />
    <LazyRoute exact path={Routes.EDIT_TENANT} render={TenantsListScreen} />
    <LazyRoute exact path={Routes.TENANT_DETAILS_EDIT_TENANT_REQUEST} render={TenantDetails} />
    <LazyRoute exact path={Routes.TENANT_DETAILS_EDIT_RENTAL_APPLICATION} render={TenantDetails} />
    <LazyRoute exact path={Routes.TENANT_DETAILS_UPDATE_RENTAL_APPLICATION_DECISION_STATUS} render={TenantDetails} />
    <LazyRoute exact path={Routes.TENANT_DETAILS_EDIT_INTERNAL_TASK} render={TenantDetails} />
    <LazyRoute path={Routes.TENANT_DETAILS_COMMUNICATION} render={TenantDetails} />
    <LazyRoute path={Routes.TENANT_DETAILS} render={TenantDetails} />
    <LazyRoute path={Routes.TENANTS} render={TenantsListScreen} />
  </PageRouter>
);

export default TenantsRouter;
