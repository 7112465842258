"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAllReconciliationsQuery = void 0;
const class_validator_1 = require("class-validator");
const getAllBaseRequest_query_1 = require("../getAllBaseRequest.query");
const reconciliationStatus_enum_1 = require("../reconciliations/reconciliationStatus.enum");
const isDateStringWithoutTime_validator_1 = require("../common/validators/isDateStringWithoutTime.validator");
class GetAllReconciliationsQuery extends getAllBaseRequest_query_1.GetAllBaseQueryRequest {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.GetAllReconciliationsQuery = GetAllReconciliationsQuery;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(reconciliationStatus_enum_1.ReconciliationStatusEnum),
    __metadata("design:type", String)
], GetAllReconciliationsQuery.prototype, "filter_status", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllReconciliationsQuery.prototype, "filter_account", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllReconciliationsQuery.prototype, "filter_reconciled_from", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllReconciliationsQuery.prototype, "filter_reconciled_to", void 0);
