import React from "react";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import makeStyles from "./styles";

interface ComponentProps {
  totalPayments: number;
}

const OutstandingBillsAllocationDialogSummary: React.FC<ComponentProps> = ({ totalPayments }: ComponentProps) => {
  const classes = makeStyles();

  return (
    <View paddingRight={20} alignItems={"flex-end"}>
      <View height={50} alignItems={"center"} flexDirection={"row"} marginTop={20} maxWidth={250}>
        <Text color={"black"} fontSize={16} fontWeight={700} value={AppStrings.Bills.PayBills.Screen.TotalPayments} />
        <View alignItems={"flex-end"} justifyContent={"center"} flex={1}>
          <Text color={"black"} fontSize={20} formatType={"currency"} value={String(totalPayments)} fontWeight={700} />
        </View>
        <div className={classes.totalPaymentsContainer}></div>
      </View>

      <View height={40} />
    </View>
  );
};

export default OutstandingBillsAllocationDialogSummary;
