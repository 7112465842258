import type { ComponentType } from "react";
import React, { useEffect } from "react";
import makeStyles from "./styles";
import AppStrings from "locale/keys";
import type { FormikProps } from "formik";
import { history } from "store/history";
import { useShakeEffect } from "hooks/useShakeEffect";
import { NavigationManager } from "../../../../utils/navigation";
import { View } from "DLUI/view";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import type { JustifyContentFlexType } from "DLUI/text/types";
import { DataCy } from "@doorloop/dto/src/automation/dataCy";

interface ComponentProps {
  didPressBackButton: () => void;
  didPressNextButton: (formikRef?: FormikProps<any> | null) => void;
  LeftComponent?: ComponentType;
  activeStep: number;
  numberOfSteps: number;
  formikRef?: FormikProps<any> | null;
  requestInProgress?: boolean;
  disableFinish?: boolean;
  hideBackButton?: boolean;
  nextButtonLabel?: string;
  backButtonLabel?: string;
  TopComponent?: ComponentType;
  showShakeEffect?: boolean;
  actionButtonsJustifyContent?: JustifyContentFlexType;
}

const WizardNavigationPanel = ({
  didPressBackButton,
  didPressNextButton,
  LeftComponent,
  activeStep,
  numberOfSteps,
  formikRef,
  requestInProgress,
  hideBackButton,
  disableFinish,
  nextButtonLabel,
  backButtonLabel,
  TopComponent,
  showShakeEffect,
  actionButtonsJustifyContent
}: ComponentProps) => {
  const { isTabletOrMobile } = useResponsiveHelper();
  const _nextButtonLabel =
    nextButtonLabel ?? (activeStep === numberOfSteps ? AppStrings.Navigation.Finish : AppStrings.Navigation.Next);
  const classes = makeStyles();

  const { ShakeEffectWrapper, runShakeEffect } = useShakeEffect();

  useEffect(() => {
    if (showShakeEffect) {
      runShakeEffect();
    }
  }, [showShakeEffect]);
  const onNextButtonPress = () => {
    didPressNextButton(formikRef);
  };

  const _didPressBackButton = () => {
    if (activeStep === 0) {
      const redirectUrl = NavigationManager.getRedirectUrlIfAny();
      if (redirectUrl) {
        NavigationManager.goToRedirectUrl(redirectUrl);
        return;
      }

      history.goBack();
    } else {
      didPressBackButton();
    }
  };

  const actionText =
    activeStep === 0 ? AppStrings.Common.Cancel : backButtonLabel ? backButtonLabel : AppStrings.Navigation.Back;

  return (
    <View
      className={classes.componentContainer}
      alignItems={"center"}
      flexDirection={"row"}
      paddingLeft={!isTabletOrMobile ? 20 : 0}
      paddingRight={!isTabletOrMobile ? 20 : 0}
      paddingTop={10}
      paddingBottom={10}
    >
      {TopComponent && <TopComponent />}
      <View
        flexDirection={"row"}
        justifyContent={
          actionButtonsJustifyContent || (!isTabletOrMobile ? (LeftComponent ? "space-between" : "flex-end") : "center")
        }
        noWrap
        alignItems={"center"}
      >
        {LeftComponent && <LeftComponent />}
        <View autoWidth flexDirection={"row"}>
          {hideBackButton ? null : (
            <DLButton
              actionText={backButtonLabel || (activeStep === 0 ? AppStrings.Common.Cancel : AppStrings.Navigation.Back)}
              variant={DLButtonVariantsEnum.OUTLINED}
              color={DLButtonColorsEnum.NEUTRAL}
              size={DLButtonSizesEnum.LARGE}
              onClick={_didPressBackButton}
              style={{ marginRight: 20, minWidth: 150 }}
              dataCy={DataCy.bottomNavigationPanel.cancelButton}
            />
          )}
          <ShakeEffectWrapper>
            <DLButton
              actionText={_nextButtonLabel}
              size={DLButtonSizesEnum.LARGE}
              onClick={requestInProgress ? runShakeEffect : onNextButtonPress}
              isLoading={requestInProgress}
              disabled={disableFinish}
              style={{ minWidth: 150 }}
              dataCy={DataCy.bottomNavigationPanel.nextButton}
            />
          </ShakeEffectWrapper>
        </View>
      </View>
    </View>
  );
};

export default WizardNavigationPanel;
