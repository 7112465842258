import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    tenantPhotoContainer: {
      width: 100,
      height: 100,
      borderRadius: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(47,62,131,0.05)",
      position: "relative",
      border: "3px solid #7C84AC"
    },
    leaseIcon: {
      height: 50,
      width: 50
    },
    uploadImageButtonContainer: {
      height: 40,
      borderColor: theme.palette.grey[400],
      backgroundColor: "rgba(47,62,131,0.05)",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      marginLeft: -3,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    uploadIcon: {
      marginLeft: 20
    },
    deleteImageContainer: {
      position: "absolute",
      right: -5,
      top: -5,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    referenceContainer: {
      backgroundColor: theme.palette.screenBackground.light,
      borderRadius: "20px"
    },
    sectionSeparator: {
      borderBottom: "1px solid rgba(124, 132, 172, 0.2)"
    },
    inputMargin: {
      marginTop: 20,
      paddingRight: 20,
      paddingLeft: 20
    },
    deleteButton: {
      marginRight: 20,
      marginTop: 20,
      width: 50,
      height: 50,
      backgroundColor: theme.palette.screenBackground.light
    },

    iconButtonContainer: {
      borderRadius: 10
    },

    transactionNumberContainer: {
      height: "50px",
      width: "80px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRight: "1px solid rgba(118,127,144,0.4)",
      backgroundColor: "#fff",
      paddingLeft: "0px"
    },
    floatingElement: {
      position: "absolute",
      right: -40,
      top: 0,
      backgroundColor: theme.palette.screenBackground.light,
      width: 310,
      height: 50,
      borderTopLeftRadius: 25,
      borderBottomLeftRadius: 25,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start"
    },
    totalPaymentsContainer: {
      position: "absolute",
      right: "-20px",
      top: 0,
      left: -40,
      borderTopLeftRadius: 25,
      borderBottomLeftRadius: 25,
      height: 50,
      backgroundColor: theme.palette.screenBackground.light,
      zIndex: -1
    }
  })
);
