import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";
import { lazy } from "react";

const NotesListScreen = lazy(() => import("screens/notes/notesListScreen"));

const NotesRouter = () => (
  <PageRouter>
    <LazyRoute path={Routes.NOTES_GLOBAL_NEW} render={NotesListScreen} />
    <LazyRoute path={Routes.NOTES} exact render={NotesListScreen} />
    <LazyRoute path={Routes.NOTES_EDIT} exact render={NotesListScreen} />
    <LazyRoute path={Routes.NOTES_CONFIRM_DIALOG} render={NotesListScreen} />
  </PageRouter>
);

export default NotesRouter;
