import React, { useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { FormikSwitchButton, SwitchButton, TextField } from "DLUI/form";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, getIn } from "formik";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import { isLeaseDepositAccountTypeAllow } from "screens/accounts/chartOfAccounts/newAccount/components/utils";
import { useResponsiveHelper } from "../../../../../../contexts/responsiveContext";

interface ComponentProps {
  formikRef: FormikProps<any>;
}

const LeaseOptions: React.FC<ComponentProps> = ({ formikRef }: ComponentProps) => {
  const { t } = useTranslation();
  const { isScreenSizeIn } = useResponsiveHelper();
  const [leaseChargeItem, setLeaseChargeItem] = useState<boolean>(getIn(formikRef.values, "leaseChargeItem") || false);
  const [leaseStatementMemoSwitch, setLeaseStatementMemoSwitch] = useState<boolean>(
    getIn(formikRef.values, "leaseChargeMemo") || false
  );
  const [showLeaseStatementMemo, setShowLeaseStatementMemo] = useState(
    false //getIn(formikRef.values, "leaseChargeMemo") || false
  );

  const isSmallScreen = isScreenSizeIn(["xs", "sm"]);

  const isLeaseDepositsAllowed = useMemo(
    () => isLeaseDepositAccountTypeAllow(formikRef.values.type),
    [formikRef.values.type]
  );

  const didChangeLeaseChargeItem = (nextValue: boolean) => {
    setLeaseChargeItem(nextValue);
  };

  const didChangeLeaseStatementMemo = (nextValue: boolean) => {
    setShowLeaseStatementMemo(nextValue);
    setLeaseStatementMemoSwitch(nextValue);
  };

  return (
    <View flex={1}>
      <View>
        <FastField
          component={FormikSwitchButton}
          marginTop={20}
          label={AppStrings.Accounts.ChartOfAccounts.LeaseChargeItem}
          name={"leaseChargeItem"}
          onChange={didChangeLeaseChargeItem}
        />
        <Text
          value={AppStrings.Accounts.ChartOfAccounts.LeaseChargeItemDescription}
          fontSize={14}
          marginLeft={60}
          lineHeight={"22px"}
          color={"black"}
        />
        <View shouldShow={leaseChargeItem} hideAnimation={"fade-out"} showAnimation={"fade-in"}>
          {isLeaseDepositsAllowed && (
            <View>
              <FastField
                component={FormikSwitchButton}
                marginTop={20}
                label={AppStrings.Accounts.ChartOfAccounts.LeaseDeposits}
                name={"leaseDepositItem"}
              />
              <Text
                value={AppStrings.Accounts.ChartOfAccounts.LeaseDepositsDescription}
                fontSize={14}
                marginLeft={60}
                lineHeight={"22px"}
                color={"black"}
              />
            </View>
          )}
          <SwitchButton
            checked={leaseStatementMemoSwitch}
            label={AppStrings.Accounts.ChartOfAccounts.LeaseStatementMemo}
            marginTop={20}
            onChange={didChangeLeaseStatementMemo}
          />
          <View>
            <Text
              value={AppStrings.Accounts.ChartOfAccounts.LeaseDepositsDescription}
              fontSize={14}
              lineHeight={"22px"}
              color={"black"}
              paddingLeft={60}
            />
            <View height={130}>
              <View
                shouldShow={showLeaseStatementMemo}
                showAnimation={"fade-in"}
                hideAnimation={"fade-out"}
                paddingLeft={isSmallScreen ? 0 : 60}
                paddingRight={isSmallScreen ? 0 : 4}
              >
                <Grid container item lg={6} md={6} xs={12}>
                  <FastField
                    component={TextField}
                    uniqueKey={"leaseChargeMemo"}
                    label={t(AppStrings.Accounts.ChartOfAccounts.DefaultLeaseMemo)}
                    name={"leaseChargeMemo"}
                    marginTop={20}
                  />
                </Grid>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default LeaseOptions;
