import type { FC, PropsWithChildren } from "react";
import React, { useCallback, useMemo, useState } from "react";
import Screen from "DLUI/screen/screen";
import { useTranslation } from "react-i18next";
import { WizardNavigationPanel, WizardStep, WizardStepper } from "DLUI/wizard";
import { RestrictedPermissionAccess } from "screens/settings/userRoles";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { View } from "DLUI/view";
import type { FormikContextType, FormikValues } from "formik";
import { Formik } from "formik";
import type { Scroller } from "DLUI/screen";
import type { Routes } from "components/appRouter";
import { history } from "store/history";
import type { ClientAnnouncementDto } from "@doorloop/dto";
import StepContainer from "DLUI/screen/wizard/stepContainer";
import type { RestrictedDeviceAccessTypes } from "DLUI/restrictedAccess/restrictedDeviceAccess";
import RestrictedDeviceAccess from "DLUI/restrictedAccess/restrictedDeviceAccess";
import { AppLayouts } from "../../../../contexts/appLayoutContext";

export type WizardStepValidator = (formik: FormikContextType<ClientAnnouncementDto>) => Promise<boolean>;

type StepsInterface<DTO> = Array<{
  label: string;
  Component: FC<{
    scrollerRef: Scroller | null;
    onBackPress: () => void;
  }>;
  maxWidth?: number;
  validator?: (partial: FormikContextType<DTO>) => Promise<boolean>;
  beforeNext?: (partial: FormikContextType<DTO>) => Promise<boolean>;
}>;

interface Props<DTO> {
  steps: StepsInterface<DTO>;
  title: {
    text: string;
    href?: string;
  };
  subTitle: string;
  formInitialValues: DTO;
  validateForm: (values: any) => any;
  onFinish: (formikRef: FormikContextType<DTO>) => Promise<Routes | void>;
  clearance?: AnyPermissionClearance;
  initialActiveStep?: number;
  excludedDevices?: RestrictedDeviceAccessTypes[];
  screenLayout?: AppLayouts;
}

export const WizardScreen = <DTO extends FormikValues>({
  steps,
  title,
  subTitle,
  clearance,
  formInitialValues,
  onFinish,
  validateForm,
  children,
  initialActiveStep,
  excludedDevices,
  screenLayout = AppLayouts.Sidebar
}: PropsWithChildren<Props<DTO>>) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(initialActiveStep || 0);
  const [loadingNextStep, setLoadingNextStep] = useState<boolean>(false);
  const [scrollerRef, setScrollerRef] = useState<Scroller | null>(null);
  const stepLabels = useMemo(() => steps.map(({ label }) => label), [steps]);
  const didPressNextButton = useCallback(
    (formikContext: FormikContextType<DTO>) => async () => {
      const { validator, beforeNext } = steps[activeStep];
      if (!validator || (await validator(formikContext))) {
        setLoadingNextStep(true);
        if (activeStep === steps.length - 1) {
          const redirectRoute = await onFinish(formikContext);
          setLoadingNextStep(false);
          redirectRoute && history.push(redirectRoute);
        } else if (!beforeNext || (await beforeNext(formikContext))) {
          setLoadingNextStep(false);
          setActiveStep(activeStep + 1);
        } else {
          setLoadingNextStep(false);
        }
      }
    },
    [activeStep]
  );

  const renderFrontLayer = (formik) => (
    <RestrictedDeviceAccess hideDisplayView excludedDevices={excludedDevices}>
      <RestrictedPermissionAccess clearance={clearance}>
        <View>
          <WizardNavigationPanel
            didPressBackButton={didPressBackButton()}
            didPressNextButton={didPressNextButton(formik)}
            activeStep={activeStep}
            numberOfSteps={steps.length - 1}
            requestInProgress={loadingNextStep}
          />
        </View>
      </RestrictedPermissionAccess>
    </RestrictedDeviceAccess>
  );

  const didPressBackButton = useCallback(
    () => () =>
      initialActiveStep && initialActiveStep > activeStep - 1
        ? history.goBack()
        : setActiveStep((activeStep) => activeStep - 1),
    [activeStep]
  );
  return (
    <Formik initialValues={formInitialValues} onSubmit={() => {}} validate={validateForm}>
      {(formik) => (
        <Screen
          excludedDevices={excludedDevices}
          subTitleText={{ text: t(subTitle) }}
          renderFrontLayer={() => renderFrontLayer(formik)}
          onScrollRef={setScrollerRef}
          hideSeparator
          requireAuth
          screenLayout={screenLayout}
        >
          <RestrictedPermissionAccess clearance={clearance} showNoAccess>
            <WizardStepper activeStep={activeStep} wizardSteps={stepLabels} setActiveStep={setActiveStep} />

            {steps.map(({ maxWidth, Component }, index) => (
              <WizardStep key={index} isActive={activeStep === index} fullWidth>
                <StepContainer maxWidth={maxWidth}>
                  <Component onBackPress={didPressBackButton()} scrollerRef={scrollerRef} />
                </StepContainer>
              </WizardStep>
            ))}
            {children}
          </RestrictedPermissionAccess>
        </Screen>
      )}
    </Formik>
  );
};
