import Link from "@material-ui/core/Link";
import clsx from "clsx";
import type { TextColor } from "DLUI/text/text";
import type { CSSProperties } from "react";
import React, { useMemo } from "react";
import { history } from "store/history";
import "./styles.css";
import type { LinkURLsEnum } from "DLUI/link/linkURLsEnum";
import type ColorsEnum from "../../../utils/colorsEnum";
import { useTextColor } from "DLUI/text/useTextColor";
import { useAnalyticsService } from "../../../hooks/useAnalyticsService";
import { isIOS, isMacOs } from "react-device-detect";
import { mapsURLsConstants } from "@/constants/mapsURLs.constants";

export type LinkType = "tel" | "mail" | "sms" | "maps" | "blank" | "tab";
export type LinkUnderLineType = "none" | "hover" | "always";

export interface LinkComponentProps {
  underline?: LinkUnderLineType;
  type?: LinkType;
  hrefUrl?: string;
  hrefEnum?: LinkURLsEnum;
  hoverColor?: TextColor;
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  isStopPropagation?: boolean;
  width?: string | number;
  textColor?: ColorsEnum | TextColor;
  fontSize?: number;
  marginLeft?: number;
  fontWeight?: number;
  alignSelf?: CSSProperties["alignSelf"];
  dataCy?: string;
  className?: string;
  label?: string;
}

const DLUI_Link: React.FC<LinkComponentProps> = ({
  children,
  underline = "hover",
  type,
  hrefUrl,
  hrefEnum,
  hoverColor = "black",
  onClick,
  width = "auto",
  fontSize,
  textColor,
  marginLeft,
  dataCy,
  className,
  alignSelf,
  fontWeight,
  isStopPropagation = false,
  label
}) => {
  const { textColor: textColorValue } = useTextColor(textColor);
  const { dispatchAnalytics } = useAnalyticsService();

  const hrefString = useMemo(() => {
    const targetHref = hrefEnum || hrefUrl;

    if (type === "tel") {
      return `tel:${hrefUrl}`;
    }

    if (type === "mail") {
      return `mailto:${hrefUrl}`;
    }

    if (type === "sms") {
      return `sms:${hrefUrl}`;
    }

    if (type === "maps" && hrefUrl) {
      const mapsAddressSearchUrl =
        isIOS || isMacOs
          ? mapsURLsConstants.ADDRESS_SEARCH_URLS.APPLE_MAPS
          : mapsURLsConstants.ADDRESS_SEARCH_URLS.GOOGLE_MAPS;

      return `${mapsAddressSearchUrl}${encodeURIComponent(hrefUrl)}`;
    }

    return targetHref;
  }, [type, hrefUrl, hrefEnum]);

  const handleClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (isStopPropagation) {
      event.stopPropagation();
    }

    dispatchAnalytics("link_click", {
      link: hrefString || "no_link",
      label: label || "no_label"
    });

    if (onClick) {
      onClick(event);
    } else {
      event.preventDefault();

      if (
        type &&
        (type === "mail" || type === "tel" || type === "sms" || type === "maps" || type === "tab" || type === "blank")
      ) {
        window.open(hrefString, "_blank");
      } else if (hrefString) {
        history.push(hrefString);
      }
    }
  };

  if (onClick) {
    return (
      <span style={{ width, marginLeft, alignSelf }}>
        <Link
          onClick={handleClick}
          underline={underline}
          className={clsx("DLUI_Link", className, hoverColor, textColorValue.className)}
          style={{ width, fontSize, color: textColorValue.value, fontWeight }}
          href={hrefString}
          data-cy={dataCy}
          rel={"nofollow"}
        >
          {children}
        </Link>
      </span>
    );
  }

  return (
    <Link
      onClick={handleClick}
      underline={underline}
      href={hrefString}
      rel={"nofollow"}
      data-cy={dataCy}
      className={clsx("DLUI_Link", className, hoverColor, textColorValue.className)}
      style={{
        width,
        color: textColorValue.value,
        marginLeft,
        fontSize,
        alignSelf,
        fontWeight
      }}
    >
      {children}
    </Link>
  );
};

export default DLUI_Link;
