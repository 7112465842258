import AppStrings from "locale/keys";
import { NavigationManager } from "utils/navigation";
import { DataCy, ReportId, ReportPermission, SendTaxForms, TAX_YEAR_LAST, TaxForm1099ShowRows } from "@doorloop/dto";
import { Routes } from "components/appRouter";
import type { ReportScreenItemType } from "DLUI/report/screen/reportScreenItemType";
import { filterObjectsByUserType } from "utils/userTypesUtils";

const items: ReportScreenItemType[] = [
  {
    description: AppStrings.Reports.ReportsScreen.OwnersDirectory,
    onPress: () => NavigationManager.goTo(Routes.OWNERS_REPORT),
    permission: ReportPermission.ownerTransactions,
    id: ReportId.OWNERS_DIRECTORY,
    dataCy: DataCy.reports.screenItems.owners.ownersDirectory
  },
  {
    description: AppStrings.Reports.ReportsScreen.PropertyOwnership,
    onPress: () =>
      NavigationManager.goTo(Routes.PROPERTY_OWNERSHIP_REPORT, {
        groupBy: "property"
      }),
    permission: ReportPermission.ownerTransactions,
    id: ReportId.PROPERTY_OWNERSHIP,
    dataCy: DataCy.reports.screenItems.owners.propertyOwnership
  },
  {
    description: AppStrings.Reports.ReportsScreen.Owners1099,
    onPress: () =>
      NavigationManager.goTo(Routes.OWNERS_1099_REPORT, {
        filter_year: TAX_YEAR_LAST.toString(),
        showRows: TaxForm1099ShowRows.ABOVE_MINIMUM,
        filter_sendForms: SendTaxForms.Yes
      }),
    permission: ReportPermission.owners1099,
    id: ReportId.OWNERS_1099,
    dataCy: DataCy.reports.screenItems.owners._1099MISC
  }
];

const getOwnersItems = (isHOAUser: boolean): ReportScreenItemType[] => {
  if (isHOAUser) {
    return filterObjectsByUserType(items, "HOAUser");
  }

  return items;
};

export default getOwnersItems;
