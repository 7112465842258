import React, { useEffect, useMemo, useState } from "react";
import { Grid, ListItemContainer } from "DLUI/listItems";

import { Icon } from "DLUI/icon";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import type { EsignatureRequestSignerClientDto } from "@doorloop/dto";
import { ESignatureSignerType } from "@doorloop/dto";
import type { SVGIconComponent } from "../../../../../assets";
import {
  DraggableIcon,
  OwnersIcon,
  TenantCurrentIcon,
  TenantsIcon,
  UsersIcon,
  VendorsIcon
} from "../../../../../assets";
import { Field, getIn, useFormikContext } from "formik";
import { TextField, ViewOnlyInput } from "DLUI/form";
import { useTranslation } from "react-i18next";
import AppStrings from "locale/keys";
import SignerProfileImage from "DLUI/profileImage/signerProfileImage";
import TextEllipsis from "DLUI/text/textEllipsis";

const InputRefreshDelay = 600;

export const getSignersIconAndText = (signerType?: ESignatureSignerType) => {
  let SignerTypeIcon: SVGIconComponent;
  switch (signerType) {
    case ESignatureSignerType.TENANT:
    case ESignatureSignerType.PROSPECT:
      SignerTypeIcon = TenantsIcon;
      break;
    case ESignatureSignerType.VENDOR:
      SignerTypeIcon = VendorsIcon;
      break;
    case ESignatureSignerType.OWNER:
      SignerTypeIcon = OwnersIcon;
      break;
    case ESignatureSignerType.USER:
      SignerTypeIcon = UsersIcon;
      break;
    default:
      SignerTypeIcon = TenantCurrentIcon;
  }
  return {
    SignerTypeIcon,
    signerTypeText: `common.enums.eSignatureSignerType.${signerType}`
  };
};

const DraggableSignerListItemComponent: React.FC<EsignatureRequestSignerClientDto> = ({
  signerType,
  name,
  itemIndex,
  isDragging,
  signerId
}: EsignatureRequestSignerClientDto) => {
  const formikRef = useFormikContext();
  const { t } = useTranslation();

  const [refreshInProgress, setRefreshInProgress] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const runInputRefresh = () => {
    setRefreshInProgress(true);
    setTimeout(() => {
      setRefreshInProgress(false);
    }, InputRefreshDelay);
  };

  useEffect(() => {
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    !isFirstLoad && runInputRefresh();
  }, [itemIndex]);

  const _didPressDeleteIcon = () => {
    const currentSignersList = getIn(formikRef.values, "signers");
    if (itemIndex !== undefined && currentSignersList[itemIndex]) {
      currentSignersList.splice(itemIndex, 1);
      formikRef.setFieldValue("signers", currentSignersList);
    }
  };

  const { signerTypeText } = useMemo(() => getSignersIconAndText(signerType), [signerType]);
  return (
    <ListItemContainer deleteProps={{ onClick: _didPressDeleteIcon }} backgroundColor={"dark"}>
      <Grid showDivider lg={6} md={6}>
        <View height={"100%"} alignItems={"center"} flexDirection={"row"}>
          <Icon marginLeft={20} Source={DraggableIcon} />
          <SignerProfileImage size={35} signerId={signerId} signerType={signerType} marginRight={10} marginLeft={20} />
          <View flex={1}>
            <TextEllipsis numberOfLines={2} fontSize={14} bold color={"gray"} text={name || ""} />
            <Text value={signerTypeText} marginTop={5} fontSize={12} color={"gray"} />
          </View>
        </View>
      </Grid>
      <Grid lg={6} md={6}>
        {isDragging || refreshInProgress ? (
          <ViewOnlyInput
            label={t(AppStrings.Tenants.NewTenant.Email)}
            value={getIn(formikRef.values, `signers[${itemIndex}].email_address`)}
          />
        ) : (
          <Field
            component={TextField}
            label={t(AppStrings.Tenants.NewTenant.Email)}
            name={`signers[${itemIndex}].email_address`}
            required
          />
        )}
      </Grid>
    </ListItemContainer>
  );
};

export default DraggableSignerListItemComponent;
