import { useMemo } from "react";
import styles from "DLUI/text/styles";
import type { TextColor } from "DLUI/text/text";
import ColorsEnum from "../../../utils/colorsEnum";
import _ from "lodash";

export const useTextColor = (color?: TextColor | ColorsEnum, hoverColor?: TextColor | ColorsEnum) => {
  const classes = styles();

  const getFontColor = useMemo(() => {
    if (color && _.values(ColorsEnum).includes(color as ColorsEnum)) {
      return {
        value: color
      };
    }

    const map = {
      lightBlue: classes.lightBlueColor,
      blue: classes.blueColor,
      boldBlue: classes.boldBlueColor,
      white: classes.whiteColor,
      semiWhite: classes.semiWhiteColor,
      gray: classes.spanColor,
      "secondary-gray": classes.secondaryGray,
      lightPurple: classes.lightPurpleColor,
      purple: classes.purpleColor,
      error: classes.errorColor,
      pink: classes.pinkColor,
      black: classes.blackColor,
      green: classes.greenColor,
      orange: classes.orangeColor,
      yellow: classes.yellowColor,
      currentColor: classes.currentColor,
      "secondary-yellow": classes.secondaryYellowColor,
      "light-black-faded": classes.lightBlackFaded
    };

    const colorClass = (color && map[color]) || classes.blackColor;

    return {
      className: colorClass
    };
  }, [color]);

  const getHoverColor = useMemo(() => {
    if (hoverColor && hoverColor in ColorsEnum) {
      return {
        value: ColorsEnum[hoverColor]
      };
    }

    const map = {
      blue: classes.blueOverColor,
      white: classes.whiteOverColor,
      lightBlue: classes.lightBlueOverColor
    };

    return {
      className: (hoverColor && map[hoverColor]) || ""
    };
  }, [hoverColor]);

  return {
    textColor: getFontColor,
    hoverColor: getHoverColor
  };
};
