import React, { useMemo, useState } from "react";

import { Field, getIn, useFormikContext } from "formik";

import type { PersonDto, ResendPortalInvitationDto } from "@doorloop/dto";
import { SegmentEventTypes } from "@doorloop/dto";

import AppStrings from "locale/keys";
import { analyticsService } from "../../../services/analyticsService";
import type { ApiResult } from "api/apiResult";
import { FormikSwitchButton } from "DLUI/form";
import { ownersApi } from "api/ownersApi";
import type { PersonContactInfoRowPersonType } from "DLUI/person/personContactInfoRow";
import { PortalInviteStatus } from "DLUI/portal/portalInviteStatus";
import { tenantsApi } from "api/tenantsApi";
import { View } from "DLUI/view";

const personTypeResendPortalInviteApiMap: Record<
  PersonContactInfoRowPersonType,
  (dto: ResendPortalInvitationDto) => Promise<ApiResult<any>>
> = {
  tenant: tenantsApi.resendTenantPortalInvite,
  owner: ownersApi.resendOwnerPortalInvitation
};

interface ComponentProps {
  personType: PersonContactInfoRowPersonType;
}

export const PortalInvite: React.FC<ComponentProps> = ({ personType }: ComponentProps) => {
  const formikRef = useFormikContext<PersonDto>();

  const [showPortalInvite, setShowPortalInvite] = useState<boolean>(
    getIn(formikRef.values, "portalInfo.allowAccess") || false
  );

  const allowAccess = useMemo(() => getIn(formikRef.values, "portalInfo.allowAccess"), []);

  const didChangeAllowAccessButton = (nextState: boolean) => {
    analyticsService.track(
      SegmentEventTypes.INVITATION_CLICKED,
      {
        id: formikRef.values?.id,
        status: nextState ? "ON" : "OFF",
        source: "Edit Dialog",
        destination: personType === "tenant" ? "Tenant" : "Owner"
      },
      { trackEventInIntercom: true }
    );

    const portalInfo = getIn(formikRef.values, "portalInfo");

    portalInfo.allowAccess = nextState;
    setShowPortalInvite(nextState);
    formikRef.setFieldValue("portalInfo", portalInfo);
  };

  const resendPortalInviteApi = personTypeResendPortalInviteApiMap[personType];

  const label = personType === "tenant" ? AppStrings.Common.TenantPortalAccess : AppStrings.Common.OwnerPortalAccess;

  return (
    <View>
      <Field
        component={FormikSwitchButton}
        name="portalInfo.allowAccess"
        label={label}
        marginTop={20}
        defaultValue={allowAccess}
        onChange={didChangeAllowAccessButton}
      />
      <View shouldShow={showPortalInvite} showAnimation="fade-in" hideAnimation="fade-out">
        <View>
          <PortalInviteStatus
            formikRef={formikRef}
            resendPortalInviteApi={resendPortalInviteApi}
            personType={personType}
          />
        </View>
      </View>
    </View>
  );
};
