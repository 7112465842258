import { ListItemContainerMaxWidth } from "DLUI/infiniteList/utils";
import React, { useState } from "react";
import type { TaskDto } from "@doorloop/dto";
import { TaskType } from "@doorloop/dto";
import { AnimateMarginTop } from "DLUI/animatableView";
import { View } from "DLUI/view";
import TaskListItem from "screens/tasks/tasksList/taskListItem";
import { NavigationManager } from "utils/navigation";
import { StickyHeader } from "DLUI/stickyHeader";
import SearchResultsListItemWrapper from "DLUI/screen/globalSearch/searchResultsListItemWrapper";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import type { ResultsSearchTabComponentProps } from "DLUI/screen/globalSearch/types";
import { globalSearchContstants } from "DLUI/screen/globalSearch/globalSearch.constants";
import { useTimeout } from "../../../../hooks/useTimeout";

const GlobalSearchTasksTab: React.FC<ResultsSearchTabComponentProps<TaskDto>> = ({
  listItems,
  handleItemClicked
}: ResultsSearchTabComponentProps<TaskDto>) => {
  const [shouldRender, setShouldRender] = useState(false);
  const { isMobile } = useResponsiveHelper();

  useTimeout(() => {
    setShouldRender(true);
  }, globalSearchContstants.tabsDisplayDelay);

  const items = listItems.map((currentItem: TaskDto, index) => {
    const didPressTaskItem = (taskId: string) => {
      handleItemClicked("Tasks");

      setTimeout(() => {
        NavigationManager.viewTaskDetails(taskId);
      }, globalSearchContstants.resultsItemHideDelay);
    };
    if (currentItem.type) {
      let requestedBy = "";
      if (currentItem.type) {
        switch (currentItem.type) {
          case TaskType.INTERNAL_TASK:
          case TaskType.WORK_ORDER:
            requestedBy = currentItem.requestedByUser || "";
            break;
          case TaskType.OWNER_REQUEST:
            requestedBy = currentItem.requestedByOwner || "";
            break;
          case TaskType.TENANT_REQUEST:
            requestedBy = currentItem.requestedByTenant || "";
            break;
        }
        return (
          <SearchResultsListItemWrapper key={"OSI" + index}>
            <TaskListItem taskDto={currentItem} onItemPress={didPressTaskItem} disableListItemOptions />
          </SearchResultsListItemWrapper>
        );
      }
    }
    return <div key={"DIV"} />;
  });

  return (
    <View
      alignItems={"center"}
      overflow={"scroll"}
      height={`calc(100vh - ${globalSearchContstants.searchPanelHeight}px)`}
    >
      {shouldRender ? (
        <View maxWidth={ListItemContainerMaxWidth} style={{ display: "block" }}>
          {!isMobile && <StickyHeader id={"searchTasksTab"} shouldShow uniqueKey={"searchTasksTab"} marginTop={20} />}
          {isMobile && <AnimateMarginTop marginTop={20} />}
          <View noWrap id={"searchTasksTab"}>
            {items}
            <View height={60} />
          </View>
        </View>
      ) : (
        <div />
      )}
    </View>
  );
};

export default GlobalSearchTasksTab;
