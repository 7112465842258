import type { FieldSizesMapType } from "DLUI/form/textField/types";
import { FieldSizes } from "DLUI/form/textField/types";

const fieldSizesMap: Record<FieldSizes, FieldSizesMapType> = {
  [FieldSizes.WEB]: {
    InputProps: {
      style: {
        height: "40px",
        fontSize: "14px"
      },
      classes: {
        input: "WEB"
      }
    },
    InputLabelProps: {
      style: {
        fontSize: "14px"
      }
    }
  },
  [FieldSizes.MOBILE]: {
    InputProps: {
      style: {
        height: "50px",
        fontSize: "1rem"
      },
      classes: {
        input: "MOBILE"
      }
    },
    InputLabelProps: {
      style: {
        fontSize: "1rem"
      }
    }
  }
};

const fontSizeMap = {
  small: "14px",
  regular: "inherit"
};

export { fieldSizesMap, fontSizeMap };
