import type { FC, PropsWithChildren } from "react";
import React, { useState } from "react";
import type { TooltipProps } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import "./validationTooltip.css";

import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

export enum TooltipType {
  Error = "error",
  Warning = "warning",
  Info = "info",
  Success = "success",
  General = "general"
}

export interface ValidationTooltipProps extends TooltipProps {
  variant: TooltipType;
  className?: string;
}

const ValidationTooltip: FC<PropsWithChildren<ValidationTooltipProps>> = ({
  variant,
  children,
  title,
  className = "",
  ...rest
}: ValidationTooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useResponsiveHelper();

  return (
    <Tooltip
      placement={"top-start"}
      arrow
      open={isOpen && Boolean(title)}
      title={title}
      {...rest}
      classes={{
        tooltip: `validation-tooltip ${variant} ${className}`,
        arrow: `validation-arrow ${variant}`
      }}
    >
      <div
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        onClick={() => isMobile && setIsOpen(true)}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default ValidationTooltip;
