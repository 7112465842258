import type { FC } from "react";
import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import type { PersonOutgoingEPayInfoDto } from "@doorloop/dto";
import AppStrings from "locale/keys";
import TextEllipsis from "DLUI/text/textEllipsis";
import { useTranslation } from "react-i18next";
import ColorsEnum from "../../../../utils/colorsEnum";
import { getStreetLocation } from "screens/units/unitsList/utils";

interface KeyValueRowProps {
  keyText: string;
  valueText?: string | number;
  isCurrency?: boolean;
}

export const KeyValueRow: FC<KeyValueRowProps> = ({ keyText, valueText = "", isCurrency }: KeyValueRowProps) => {
  const { t } = useTranslation();
  return (
    <View flexDirection={"row"} alignItems={"center"} marginBottom={20}>
      <View width={"40%"}>
        <Text value={keyText} fontSize={16} />
      </View>
      <View width={"60%"} alignItems={"flex-end"}>
        {isCurrency ? (
          <Text colorEnum={ColorsEnum.BrightGray} value={valueText.toString()} formatType={"currency"} />
        ) : (
          <TextEllipsis
            fontSize={16}
            lines={2}
            colorEnum={ColorsEnum.BrightGray}
            text={t(valueText.toString())}
            align={"right"}
            lineBreak={"anywhere"}
            lineHeight={"22px"}
          />
        )}
      </View>
    </View>
  );
};

interface DestinationRowProps {
  personEPayInfo: PersonOutgoingEPayInfoDto;
  isDigitalPayment: boolean;
}

export const DestinationRow: FC<DestinationRowProps> = ({ personEPayInfo, isDigitalPayment }: DestinationRowProps) => (
  <KeyValueRow
    keyText={isDigitalPayment ? AppStrings.Tenants.NewTenant.Email : AppStrings.Tenants.NewTenant.Address}
    valueText={isDigitalPayment ? personEPayInfo?.digitalEmail : getStreetLocation(personEPayInfo?.physicalAddress)}
  />
);
