import React, { useMemo } from "react";
import { View } from "DLUI/view";
import AppStrings from "../../../../locale/keys";
import Text from "DLUI/text";
import { LearnMoreText } from "DLUI/text/learnMoreText";
import { ArticleIdsEnum } from "DLUI/screen/helpPanel/types";

interface ComponentProps {
  boxType?: "pendingSync" | "alreadySynced";
}

const SyncNotificationBox: React.FC<ComponentProps> = ({ boxType }: ComponentProps) => {
  const pendingSyncBox = useMemo(
    () => (
      <View
        marginTop={20}
        borderRadius={10}
        backgroundColor={"yellow-light"}
        paddingBottom={20}
        paddingTop={20}
        paddingLeft={20}
        paddingRight={20}
        overflowWrap={"anywhere"}
      >
        <Text value={AppStrings.Common.PleaseNote} bold />
        <LearnMoreText
          marginTop={5}
          lineHeight={"24px"}
          hoverColor={"black"}
          articleId={ArticleIdsEnum.QUICKBOOKS_INTEGRATION}
          value={AppStrings.Common.BeforeTurningOnIntegration}
        />
      </View>
    ),
    []
  );

  const alreadySyncedBox = useMemo(
    () => (
      <View
        marginRight={20}
        marginTop={20}
        borderRadius={10}
        style={{ backgroundColor: "rgba(1, 204, 116, 0.1)" }}
        paddingLeft={20}
        paddingRight={20}
        paddingTop={20}
        paddingBottom={20}
        overflowWrap={"anywhere"}
      >
        <Text value={AppStrings.Common.QuickbooksIntegrationEnabled} bold />
        <Text value={AppStrings.Common.QuickbooksIntegrationEnabledDescription} marginTop={5} />
        <LearnMoreText
          value={AppStrings.Common.LearnQuickbooksSync}
          articleId={ArticleIdsEnum.QUICKBOOKS_INTEGRATION}
          lineHeight={"24px"}
          marginTop={5}
          fullWidth
        />
      </View>
    ),
    []
  );

  return boxType === "alreadySynced" ? alreadySyncedBox : pendingSyncBox;
};

export default SyncNotificationBox;
