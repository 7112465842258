import React from "react";
import { View } from "DLUI/view";
import { DialogSearchPanel } from "DLUI/dialogs";
import LeasesList from "screens/leases/leases/leasesList/leasesList";
import SelectableLeaseListItem from "screens/leases/leases/leasesList/selectableLeaseListItem";
import { getDialogSelectionHeight } from "../components/dialogFrame";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  onLeaseListItemPress: (leaseId: string) => void;
  didChangeSearchQuery: (boolean) => void;
  selectLeaseFilterObj: Record<string, string>;
}

const InsurancePolicyLeaseSelection: React.FC<ComponentProps> = ({
  onLeaseListItemPress,
  didChangeSearchQuery,
  selectLeaseFilterObj
}: ComponentProps) => {
  const frameHeight = Math.min(window.innerHeight, 666);
  const { screenContainerPadding } = useResponsiveHelper();

  return (
    <View>
      <DialogSearchPanel onChange={didChangeSearchQuery} />
      <View
        paddingLeft={screenContainerPadding}
        paddingRight={screenContainerPadding}
        id={"selectableLeaseContainer"}
        marginTop={20}
        height={getDialogSelectionHeight(frameHeight)}
        overflow={"scroll"}
      >
        <LeasesList
          ListItem={SelectableLeaseListItem}
          filterObj={selectLeaseFilterObj}
          didPressListItem={onLeaseListItemPress}
          removeDefaultBottomPadding
          stickyHeaderId={"selectableLeaseContainer"}
          scrollableTarget={"selectableLeaseContainer"}
          selectableItem
        />
      </View>
    </View>
  );
};

export default InsurancePolicyLeaseSelection;
