"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankAccountType = void 0;
var BankAccountType;
(function (BankAccountType) {
    BankAccountType["CHECKING"] = "8";
    BankAccountType["SAVINGS"] = "9";
    BankAccountType["BUSINESS_CHECKING"] = "10";
    BankAccountType["BUSINESS_SAVINGS"] = "11";
})(BankAccountType || (exports.BankAccountType = BankAccountType = {}));
