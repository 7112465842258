import React, { useMemo } from "react";
import Radio from "@material-ui/core/Radio";
import { useStylesRadio } from "DLUI/form/radioGroup/radio/useStylesRadio";
import { View } from "DLUI/view";
import { InputLabel } from "DLUI/form/label/inputLabel";
import { v4 } from "uuid";
import type { UnControlledToggleInputBaseTypes } from "DLUI/form/types/toggleInputBaseTypes";

export function UnControlledRadio<T>({
  style,
  size,
  dataCy,
  fullWidth,
  labelValueProps,
  disabled,
  value,
  defaultChecked
}: UnControlledToggleInputBaseTypes<T>) {
  const { classNameContainer, classesInput } = useStylesRadio({
    labelPlacement: labelValueProps?.placement,
    disabled,
    size
  });

  const id = useMemo(v4, []);

  return (
    <View className={classNameContainer} noWrap width={fullWidth ? "100%" : "auto"} {...style}>
      <Radio
        id={id}
        value={value}
        defaultChecked={defaultChecked}
        data-cy={dataCy}
        classes={{
          root: classesInput.root,
          checked: classesInput.checked,
          disabled: classesInput.disabled
        }}
        color={"primary"}
        disabled={disabled}
      />

      {labelValueProps?.component
        ? labelValueProps?.component
        : labelValueProps?.text && (
            <InputLabel
              htmlFor={id}
              fullWidth={fullWidth}
              disabled={disabled}
              color={labelValueProps.color}
              text={labelValueProps.text}
              size={size}
              placement={labelValueProps.placement}
            />
          )}
    </View>
  );
}
