"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsPersonName = void 0;
exports.ValidatorComposer = ValidatorComposer;
const class_validator_1 = require("class-validator");
function ValidatorComposer(validators, name) {
    return function ({ required }) {
        return function (target, propertyKey) {
            validators.forEach((validator) => validator(target, propertyKey));
            if (required)
                (0, class_validator_1.IsDefined)({ message: "Must specify a " + name })(target, propertyKey);
            else
                (0, class_validator_1.IsOptional)()(target, propertyKey);
        };
    };
}
exports.IsPersonName = ValidatorComposer([(0, class_validator_1.ValidateIf)((o) => !o.companyName), (0, class_validator_1.IsString)(), (0, class_validator_1.MaxLength)(100), (0, class_validator_1.IsNotEmpty)()], "First Name");
