import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    customTooltip: {
      backgroundColor: "#182C4C",
      padding: 10,
      borderRadius: 5
    },
    customPopper: {
      zIndex: 1
    },
    customArrow: {
      color: "#182C4C"
    },
    progressBarInner: {
      position: "relative",
      overflow: "hidden"
    },
    shinyProgressBar: {
      "&::after": {
        //  shine the progress bar
        content: '""',
        position: "absolute",
        borderRadius: 30,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        animation: "$progress-bar-shine 3s ease-out",
        animationIterationCount: "4",
        background: `linear-gradient(90deg,rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.8) 100%)`,
        backgroundSize: "200% 100%",
        transform: "translateX(-100%)",
        opacity: 0.8
      }
    },
    "@keyframes progress-bar-shine": {
      "45%": {
        transform: "translateX(-100%)",
        opacity: 1
      },
      "100%": {
        transform: "translateX(14%)",
        opacity: 0
      }
    },
    circleBackground: {
      fill: "none",
      stroke: theme.palette.white.main
    },
    circleProgress: {
      fill: "none",
      stroke: theme.palette.darkGreen.main,
      strokeLinecap: "round",
      strokeLinejoin: "round"
    },
    circleText: {
      fontSize: "10px",
      fontWeight: 700,
      fill: theme.palette.darkGreen.main,
      marginLeft: 3.5,
      paddingTop: 1
    }
  })
);
