import type { FC } from "react";
import React, { useMemo } from "react";
import { View } from "DLUI/view";
import { FormikDatePicker, FormikSwitchButton, Select, TextField } from "DLUI/form";
import type { FormikProps } from "formik";
import { FastField, getIn } from "formik";
import { useTranslation } from "react-i18next";
import type {
  FieldSettingsType,
  RentalAppGroups,
  RentalAppGroupSettingsProps,
  RentalAppHandlerFunctions,
  RentalApplicationDto
} from "@doorloop/dto";
import { Grid } from "@material-ui/core";
import Text from "DLUI/text";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import AppStrings from "../../locale/keys";
import { useRentalApplicationLocale } from "../../hooks/useRentalApplicationLocale";
import AddressAutoCompleteFormWrapper from "DLUI/form/autoComplete/addressAutoComplete/addressAutoCompleteFormWrapper";

export interface ControlledFieldsWrapperProps {
  initFieldsSettings: FieldSettingsType;
  fieldsSettings?: FieldSettingsType;
  formName: string;
  groupSettings: Partial<Record<RentalAppGroups, RentalAppGroupSettingsProps>>;
  formikRef?: FormikProps<RentalApplicationDto>;
  handlers?: Record<RentalAppHandlerFunctions, (props: any) => void>;
  isControlled?: boolean;
}

type FieldTypes = "text" | "select" | "datePicker" | "switch" | "addressAutoComplete";

const fieldsList: Record<FieldTypes, React.ReactNode> = {
  text: TextField,
  select: Select,
  datePicker: FormikDatePicker,
  switch: FormikSwitchButton,
  addressAutoComplete: AddressAutoCompleteFormWrapper
};

export const mergeRentalAppFieldsListsByGroup = (
  initFieldsSettings: FieldSettingsType,
  fieldsSettings?: FieldSettingsType,
  isControlled?: boolean
) => {
  const allFieldsList = {};

  for (const fieldName in initFieldsSettings) {
    const fieldGroup = initFieldsSettings[fieldName].groupName;
    if (!allFieldsList[fieldGroup]) {
      allFieldsList[fieldGroup] = {};
    }

    allFieldsList[fieldGroup][fieldName] = {
      ...initFieldsSettings[fieldName],
      isVisible: !isControlled,
      ...(fieldsSettings && fieldsSettings?.[fieldName] ? fieldsSettings[fieldName] : {})
    };
  }

  return allFieldsList;
};

const FieldComponent = ({ allFieldsSettings, groupName, fieldName, formValues, formName, handlers }) => {
  const { t } = useTranslation();
  const {
    type,
    isVisible,
    isMandatory,
    label,
    rawLabelText,
    gridItemSize = 4,
    selectionEnum,
    translationKey,
    multiline,
    multiLineSize,
    rows,
    maxLength,
    maxDate,
    formatType,
    visibilityHandler,
    additionalProps,
    className,
    textFieldType
  } = allFieldsSettings[groupName][fieldName];

  const labelText: string = useMemo(() => {
    if (rawLabelText) {
      return rawLabelText;
    }
    if (label) {
      return t(getIn(AppStrings, label));
    }
    return undefined;
  }, [rawLabelText, label]);

  return (
    <>
      {type && fieldsList?.[type] && (isVisible || isMandatory) && visibilityHandler?.(formValues) !== false && (
        <Grid item xs={12} md={gridItemSize} key={fieldName}>
          <FastField
            component={fieldsList[type]}
            name={`${formName}.${fieldName}`}
            label={labelText}
            required={isMandatory}
            selectionEnum={type === "select" && selectionEnum}
            translationKey={type === "select" && translationKey}
            multiline={type === "text" && multiline}
            multiLineSize={type === "text" && multiLineSize}
            rows={type === "text" && rows}
            maxLength={type === "text" && maxLength}
            maxDate={type === "datePicker" && maxDate}
            formatType={type === "text" && formatType}
            TextFieldType={textFieldType}
            className={className}
            {...(additionalProps ? additionalProps(formValues, formName, handlers) : {})}
          />
        </Grid>
      )}
    </>
  );
};

const ControlledRentalAppFieldsWrapper: FC<ControlledFieldsWrapperProps> = ({
  initFieldsSettings,
  fieldsSettings,
  formName,
  groupSettings,
  formikRef,
  handlers,
  isControlled = true
}) => {
  const { getLocaleConfiguredRentalAppSectionSettings } = useRentalApplicationLocale();
  const { allFieldsSettings, allFieldsGroupNames } = useMemo(() => {
    const localeConfiguredInitFieldsSettings = getLocaleConfiguredRentalAppSectionSettings(initFieldsSettings);

    const allFieldsSettings = mergeRentalAppFieldsListsByGroup(
      localeConfiguredInitFieldsSettings,
      fieldsSettings,
      isControlled
    );
    const allFieldsGroupNames = Object.keys(allFieldsSettings);
    return { allFieldsSettings, allFieldsGroupNames };
  }, [initFieldsSettings, fieldsSettings]);

  const formValues = getIn(formikRef?.values, formName);

  return (
    <View id={"controlled-fields-wrapper"} flexDirection={"row"} marginTop={20}>
      {allFieldsGroupNames.map((groupName) => {
        const allFieldsNames = Object.keys(allFieldsSettings[groupName]);
        const { title, withBottomSeparator, withBottomSpacing, groupVisibilityHandler } = groupSettings[groupName];

        if (groupVisibilityHandler && !groupVisibilityHandler?.(formValues, groupName, allFieldsSettings)) {
          return null;
        }

        return (
          <View key={groupName}>
            {title && (
              <Text value={getIn(AppStrings, title)} fontSize={18} color={"black"} fontWeight={700} marginBottom={20} />
            )}
            <Grid container spacing={3}>
              {allFieldsNames.map((fieldName) => (
                <FieldComponent
                  allFieldsSettings={allFieldsSettings}
                  fieldName={fieldName}
                  formName={formName}
                  formValues={formValues}
                  groupName={groupName}
                  handlers={handlers}
                  key={fieldName}
                />
              ))}
            </Grid>
            {withBottomSeparator && <HorizontalSeparationLine marginTop={20} marginBottom={20} />}
            {withBottomSpacing && <View height={30} flexDirection={"row"} />}
          </View>
        );
      })}
    </View>
  );
};

export default ControlledRentalAppFieldsWrapper;
