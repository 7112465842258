import type { FC } from "react";
import React, { useState } from "react";
import { DialogFrame } from "DLUI/dialogs";
import AppStrings from "locale/keys";
import { useParams } from "react-router-dom";
import { NavigationManager } from "utils/navigation";
import { SimpleLoading } from "DLUI/loading/simpleLoading";
import { announcementsApi } from "api/announcementsApi";
import type { AnnouncementDto } from "@doorloop/dto";
import { useEffectAsync } from "hooks/useEffectAsync";
import DeleteConfirmation from "DLUI/dialogs/components/deleteConfirmation";
import { buildRenderView } from "DLUI/dialogs/components/dialogFrame";
import { EditAnnouncementForm } from "screens/announcements/announcementDetails/editAnnouncementForm";

const dialogSize = 640;

interface Props {
  onClose: () => void;
}

export const EditAnnouncementDialog: FC<Props> = ({ onClose }: Props) => {
  const { announcementId } = useParams<{ announcementId: string }>();
  const [announcementData, setAnnouncementData] = useState<AnnouncementDto | null>(null);
  const [errorText, setErrorText] = useState<string>("");
  const [currentView, setCurrentView] = useState<number>(0);

  const backToAnnouncement = () => NavigationManager.viewAnnouncementDetails(announcementId);

  useEffectAsync(async () => {
    const { data } = await announcementsApi.get(announcementId);
    if (data) {
      setAnnouncementData(data);
      setCurrentView(1);
    }
  }, [announcementId]);

  const updateAnnouncement = async (dto: AnnouncementDto) => {
    const { data, message } = await announcementsApi.update(announcementId, dto);
    if (!data) {
      setAnnouncementData(null);
      setErrorText(message);
    } else {
      onClose();
    }
  };

  const { renderView } = buildRenderView([
    () => <SimpleLoading errorText={errorText} dismissHandler={backToAnnouncement} />,
    () =>
      announcementData ? (
        <EditAnnouncementForm
          dto={announcementData}
          deleteHandler={() => setCurrentView(2)}
          dismissHandler={backToAnnouncement}
          updateHandler={updateAnnouncement}
        />
      ) : null,
    () => (
      <DeleteConfirmation
        apiMethod={announcementsApi}
        didPressDismissButton={() => setCurrentView(1)}
        didFinishOperation={() => NavigationManager.viewAnnouncements()}
        transactionId={announcementId}
        deleteConfirmationText={AppStrings.Announcements.deleteCommunicationConfirmationText}
        attachments={[]}
      />
    )
  ]);

  return (
    <DialogFrame
      frameType={announcementData ? "sectionTitleFrame" : "contentOnlyNoFrame"}
      title={AppStrings.Announcements.announcementPreview}
      numViews={3}
      activeView={currentView}
      onCloseButtonClick={backToAnnouncement}
      width={dialogSize}
      height={dialogSize}
      renderView={renderView}
      useExperimentalDialogFrame
    />
  );
};
