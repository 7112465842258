import React, { useCallback, useMemo, useState } from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import { View } from "DLUI/view";
import { CaretDownIcon, CaretRightIcon } from "../../../assets";
import Text from "DLUI/text";
import { AnimatePresence, motion } from "framer-motion";
import { SeparationLine } from "DLUI/separatorView";
import type { ViewBackgroundColor, ViewComponentProps } from "DLUI/view/view";

import { useResponsiveHelper } from "../../../contexts/responsiveContext";

interface AccordionItemProps extends React.PropsWithChildren<ViewComponentProps> {
  isCollapsed?: boolean;
  hasIndependentCollapsed?: boolean; // this prop create internal state
  defaultIndependentCollapsedVal?: boolean;
  backgroundColor?: ViewBackgroundColor;
  onHeaderClick?: () => void; // this prop will support only if isCollapsed prop used
  headerIconElement?: React.ReactElement;
  children?: React.ReactNode;
  title: string;
  headerRightSide?: React.ReactElement;
  padding?: string;
  isLoading?: boolean;
  loadingIndicatorSize?: number;
}

/**
 * @deprecated use {@link Accordion} instead
 * @see {@link Accordion}
 * */
const AccordionItem: React.FC<AccordionItemProps> = ({
  isCollapsed,
  hasIndependentCollapsed = false,
  defaultIndependentCollapsedVal = true,
  onHeaderClick,
  children,
  headerIconElement,
  headerRightSide,
  title,
  backgroundColor,
  padding,
  style,
  isLoading,
  loadingIndicatorSize = 40,
  ...viewProps
}: AccordionItemProps) => {
  const { isMobile } = useResponsiveHelper();
  const [independentCollapsed, setIndependentCollapsed] = useState<boolean>(defaultIndependentCollapsedVal);
  const collapse = useMemo(
    () => (hasIndependentCollapsed ? independentCollapsed : isCollapsed),
    [isCollapsed, independentCollapsed, hasIndependentCollapsed]
  );

  const handleHeaderClicked = useCallback(() => {
    if (isCollapsed === undefined && hasIndependentCollapsed) {
      setIndependentCollapsed(!independentCollapsed);
    } else {
      onHeaderClick?.();
    }
  }, [independentCollapsed, onHeaderClick]);

  return (
    <View
      borderRadius={10}
      style={{
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.03)",
        border: "1px solid rgba(122, 134, 153, 0.2)",
        boxSizing: "border-box",
        padding: padding ?? "20px",
        transition: "background-color 0.2s ease-in-out",
        ...style
      }}
      backgroundColor={backgroundColor}
      {...viewProps}
    >
      {/* Header */}
      <Grid
        onClick={handleHeaderClicked}
        container
        direction={"row"}
        alignItems={"center"}
        spacing={1}
        style={{ cursor: "pointer", overflow: "hidden" }}
        wrap={"nowrap"}
      >
        <Grid item xs={"auto"} sm={"auto"} md={"auto"}>
          <View
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={15}
            minWidth={headerIconElement ? 80 : 30}
          >
            <View width={"8px"}>{collapse ? <CaretRightIcon /> : <CaretDownIcon />}</View>

            {isLoading ? <CircularProgress size={loadingIndicatorSize} /> : headerIconElement}
          </View>
        </Grid>

        <Grid item xs={9} sm={10} md>
          <Grid container alignItems={"center"} spacing={1}>
            <Grid item md sm={12} xs={12}>
              {/* Title of the step */}
              <Text hoverColor={"lightBlue"} value={title} fontSize={isMobile ? 16 : 18} bold />
            </Grid>
            {headerRightSide && (
              <Grid item md={"auto"} sm={12} xs={12}>
                {headerRightSide}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <AnimatePresence initial={false}>
        {/*accordion */}
        {!collapse && (
          <motion.div
            initial={{ height: 0, opacity: 0, y: -10 }}
            animate={{ height: "auto", opacity: 1, y: 0 }}
            exit={{ height: 0, opacity: 0 }}
            style={{ overflow: "hidden", width: "100%" }}
            transition={{ duration: 0.2, bounce: 0.7, ease: "easeInOut" }}
          >
            {/* Separator */}
            <SeparationLine height={2} width={"100%"} marginTop={20} marginBottom={20} />

            {/* Children */}
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </View>
  );
};

export default AccordionItem;
