"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankAccountClientDto = exports.BankAccountPlaidInfoDto = exports.OutgoingPaymentsInfoDto = exports.BankAccountDto = void 0;
class BankAccountDto {
}
exports.BankAccountDto = BankAccountDto;
class OutgoingPaymentsInfoDto {
}
exports.OutgoingPaymentsInfoDto = OutgoingPaymentsInfoDto;
class BankAccountPlaidInfoDto {
}
exports.BankAccountPlaidInfoDto = BankAccountPlaidInfoDto;
class BankAccountClientDto extends BankAccountDto {
}
exports.BankAccountClientDto = BankAccountClientDto;
