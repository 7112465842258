import type { DefaultAccountType } from "@doorloop/dto";
import type { SyntheticEvent } from "react";
import { useCallback, useEffect, useReducer, useRef } from "react";
import { useEffectAsync } from "@/hooks/useEffectAsync";
import { flatten, get } from "lodash";
import AutoCompleteCache from "@/caches/autoCompleteCache/autoCompleteCache";
import AppStrings from "@/locale/keys";
import { useDebounce } from "@/hooks/useDebounce";
import {
  filter,
  findMultiSelectedOptions,
  findSelectedOptionsIndex
} from "DLUI/form/autoComplete/formikAsyncAutoComplete/autoCompleteHandlers";

interface AutoCompleteProps {
  avoidDefaultsAccountsTypes?: DefaultAccountType[];
  multiple?: boolean;
  onChangeCallback?: (event: any, values: string | null | string[]) => void;
  onChangeCallbackDelay?: number;
  defaultValue?: string | string[];
  defaultMode?: "cacheMode" | "serverMode";

  [key: string]: any;
}

interface stateProps {
  selectedMode: undefined | "cacheMode" | "serverMode" | "enumMode";
  isOpen: boolean;
  isLoading: boolean;
  showCreateOption: boolean;
  currentPage: number;
  totalOptionsAmount: number;
  options: any[];
  valueFilter: string;
  createError: string;
  multiSelectInnerValue: any[];
  singleSelectedIndex: number | undefined;
  selectionEnum?: Record<string, string>;
  translationKey?: string;
}

interface autoCompleteHandlersProps {
  handleScroll: (event: SyntheticEvent) => void;
  onCreate: (newOption) => void;
  onCreateError: (error: string) => void;
  setShowCreateOption: (show: boolean) => void;
  _onChange: (event: any, values: any) => void;
}

const initialState: stateProps = {
  selectedMode: "cacheMode",
  isOpen: false,
  isLoading: false,
  currentPage: 1,
  totalOptionsAmount: 0,
  options: [],
  valueFilter: "",
  createError: "",
  multiSelectInnerValue: [],
  singleSelectedIndex: undefined,
  showCreateOption: false
};

export const NO_RESULTS_FOUND_ID = "no-results-found";
export const SELECT_ALL_ID = "select-all";

const useAutoComplete = ({
  apiHandler,
  queryParams,
  selectionFields,
  displayNameKey,
  filterFieldValue,
  defaultMode = "cacheMode",
  filterFieldName,
  avoidDefaultsAccountsTypes,
  componentId,
  t,
  pageSize,
  selectionEnum,
  translationKey,
  multiple,
  onChangeCallback,
  onChangeCallbackDelay,
  form,
  field,
  defaultValue
}: AutoCompleteProps): [Partial<stateProps>, Partial<autoCompleteHandlersProps>, (newState) => void] => {
  const [
    {
      isOpen,
      isLoading,
      options,
      selectedMode,
      currentPage,
      singleSelectedIndex,
      multiSelectInnerValue,
      totalOptionsAmount,
      valueFilter,
      showCreateOption,
      createError
    },
    setState
  ] = useReducer(
    (state: stateProps, newState: Partial<stateProps>) => {
      return {
        ...state,
        ...newState
      };
    },
    { ...initialState, selectedMode: selectionEnum ? "enumMode" : defaultMode }
  );
  const debouncedValueFilter = useDebounce(valueFilter, 500);
  const valueFilterCurrentRef = useRef(valueFilter);
  const currentDefaultValueCurrentRef = useRef();
  const hasReachedServerOnceRef = useRef(false);
  const defaultValueRef = useRef(defaultValue);
  const { current: permanentDefaultValue } = defaultValueRef;

  // This hook is used to handle the selected values from the "defaultValue" field that changes,
  // when the autocomplete is not managed by formik, so the values come from the field "defaultValue".
  // should be refactored
  useEffectAsync(async () => {
    const isNotManagedByFormik = !form && !field;
    if (isNotManagedByFormik) {
      await handleDefaultValues(options, defaultValue);
    }
  }, [defaultValue, options]);

  useEffect(() => {
    if (isOpen && showCreateOption) {
      document.body.addEventListener("click", handleClickAway);
    } else {
      document.body.removeEventListener("click", handleClickAway);
    }

    return () => document.body.removeEventListener("click", handleClickAway);
  }, [showCreateOption, isOpen]);

  useEffectAsync(
    async () => {
      if (selectionEnum) {
        setState({ selectedMode: "enumMode" });
      } else if (selectedMode === "cacheMode") {
        await getCacheOptions(null);
      }
    },
    flatten(Object.values(queryParams || {}))
  );

  useEffectAsync(async () => {
    const isManagedByFormik = form && field;
    if (isManagedByFormik) {
      const newDefaultValue = get(form.values, field.name);

      await handleDefaultValues(options, newDefaultValue);
      currentDefaultValueCurrentRef.current = newDefaultValue;
    }
  }, [get(form?.values, field?.name), selectedMode]);

  useEffect(() => {
    if (selectedMode === "enumMode") {
      setState({ isLoading: true });
      const nextOptions = Object.keys(selectionEnum).map((currentKey, index: number) => {
        const name = translationKey ? t(`common.enums.${translationKey}.${currentKey}`) : selectionEnum[currentKey];

        return {
          name,
          filterFieldValue: selectionEnum[currentKey],
          id: selectionEnum[currentKey],
          itemIndex: index
        };
      });

      setState({ options: nextOptions, isLoading: false });
    }
  }, [selectedMode]);

  useEffectAsync(async () => {
    if (selectedMode === "serverMode" && !isLoading) {
      const _options = options.filter((opt) => opt.id !== NO_RESULTS_FOUND_ID);
      if (!isOpen && debouncedValueFilter !== "" && debouncedValueFilter !== undefined) {
        // closed and filter value not empty!
        setState({ currentPage: 1, valueFilter: "" });
        valueFilterCurrentRef.current = "";
        await loadOptions(1, "");
      } else if (isOpen && debouncedValueFilter !== "" && debouncedValueFilter !== undefined) {
        // is open and filter is inputted!
        setState({ currentPage: 1 });
        await loadOptions(1, debouncedValueFilter);

        valueFilterCurrentRef.current = debouncedValueFilter;
      } else if (
        isOpen &&
        debouncedValueFilter !== undefined &&
        debouncedValueFilter !== valueFilterCurrentRef.current
      ) {
        // not first init - clear options on filter emptying
        setState({ currentPage: 1 });
        await loadOptions(1, debouncedValueFilter);

        valueFilterCurrentRef.current = debouncedValueFilter;
      } else if (
        isOpen &&
        (!_options.length ||
          (options.length === 1 && totalOptionsAmount !== _options.length) ||
          !hasReachedServerOnceRef.current)
      ) {
        // load first time options if opened and options not exist, or preloadOption enabled
        await loadOptions(currentPage, debouncedValueFilter);
      }
    } else if (!isOpen && debouncedValueFilter !== "" && debouncedValueFilter !== undefined) {
      setState({ valueFilter: "" });
    }
  }, [isOpen, debouncedValueFilter]);

  const handleSingleChange = (event, value) => {
    if (form) {
      form.setFieldTouched(field.name);
      if (value === null) {
        form.setFieldValue(field.name, undefined);
      } else if (form && field) {
        form.setFieldValue(field.name, value.id);
      }
    }

    if (onChangeCallback) {
      if (onChangeCallbackDelay) {
        setTimeout(() => {
          onChangeCallback(event, value);
        }, onChangeCallbackDelay);
      } else {
        onChangeCallback(event, value);
      }
    }
  };

  const handleMultiChange = (event, values) => {
    let _values = values;

    if (values.find((option) => option.id === SELECT_ALL_ID)) {
      const optionsWithoutNothingFound = options.filter((option) => option.id !== NO_RESULTS_FOUND_ID);
      const filteredOptions = filter(optionsWithoutNothingFound, {
        inputValue: valueFilter,
        getOptionLabel: (option) => option.name
      });
      _values = filteredOptions?.length !== multiSelectInnerValue?.length ? filteredOptions : [];
    }

    if (form && field) {
      if (values === null || (Array.isArray(values) && !values?.length)) {
        form.setFieldValue(field.name, undefined);
      } else {
        const idsArray: string[] = [];

        _values.forEach((element: any) => {
          if (element.id) {
            idsArray.push(element.id);
          } else if (selectionEnum) {
            idsArray.push(element.filterFieldValue);
          }
        });

        form.setFieldValue(field.name, idsArray);
      }

      setTimeout(() => {
        form.setFieldTouched(field.name);
      }, 0);
    }
    onChangeCallback?.(event, _values);
  };

  const _onChange = (event: object, _values: any) => {
    if (multiple) {
      const values = _values.filter((val) => val.id !== NO_RESULTS_FOUND_ID);
      handleMultiChange(event, values);
    } else {
      const value = _values?.id === NO_RESULTS_FOUND_ID ? null : _values;
      handleSingleChange(event, value);
    }
  };

  const loadDefaultOptions = async () => {
    setState({ isLoading: true });

    if (permanentDefaultValue) {
      const response = await apiHandler.get(permanentDefaultValue);

      if (response && response.data) {
        const nextOptions: any[] = makeOptionsList([response.data]);
        setState({ isLoading: false, options: nextOptions });

        findSelectedOption(nextOptions, permanentDefaultValue);
      } else {
        handleSingleChange({}, null);
      }
    }

    setState({ isLoading: false });
  };

  const loadMultipleDefaultOptions = async (_defaultValue?: string | string[]) => {
    const defaultValues = _defaultValue ?? permanentDefaultValue;
    const hasDefaultValues = Array.isArray(defaultValues) && defaultValues.length > 0;
    setState({ isLoading: true });

    if (hasDefaultValues) {
      const defaultValuesList: any[] = [];

      for (const defaultOption of defaultValues) {
        const response = await apiHandler.get(defaultOption);

        if (response && response.data) {
          defaultValuesList.push(response.data);
        }
      }

      const nextOptions: any[] = makeOptionsList(defaultValuesList);
      setState({ isLoading: false, options: nextOptions, multiSelectInnerValue: defaultValuesList });
    }

    setState({ isLoading: false });
  };

  const findSelectedOption = (options, _defaultValue) => {
    const selectedIndex = findSelectedOptionsIndex(options, _defaultValue);

    if (typeof selectedIndex === "number" && selectedIndex >= 0) {
      setState({ singleSelectedIndex: selectedIndex });
      return true;
    }

    return false;
  };

  const setMultiSelectValues = (options, _defaultValue) => {
    const defaultMultiSelection = findMultiSelectedOptions(options, _defaultValue);
    if (Array.isArray(defaultMultiSelection)) {
      setState({ multiSelectInnerValue: defaultMultiSelection });
    }
  };

  function setSingleSelectValues(options, _defaultValue) {
    const isFound = findSelectedOption(options, _defaultValue);
    if (!isFound && options?.length && singleSelectedIndex !== undefined) {
      handleSingleChange({}, options[singleSelectedIndex]);
    }
  }

  const handleDefaultValues = async (options?: any[], _defaultValue?: string | any[]) => {
    const isOptionsEmpty = !options || !options.length;

    async function handleMultiple() {
      if (selectedMode === "cacheMode") {
        if (isOptionsEmpty) {
          await getCacheOptions(_defaultValue);
        } else {
          setMultiSelectValues(options, _defaultValue);
        }
      } else if (isOptionsEmpty) {
        await loadMultipleDefaultOptions(_defaultValue);
      } else {
        setMultiSelectValues(options, _defaultValue);
      }
    }

    async function handleSingle() {
      if (_defaultValue === null || (_defaultValue === undefined && singleSelectedIndex !== undefined)) {
        setState({ singleSelectedIndex: undefined });
        return;
      }
      if (selectedMode === "cacheMode") {
        if (isOptionsEmpty) {
          await getCacheOptions(_defaultValue);
        } else {
          setSingleSelectValues(options, _defaultValue);
        }
      } else if (isOptionsEmpty) {
        await loadDefaultOptions();
      } else {
        setSingleSelectValues(options, _defaultValue);
      }
    }

    if (multiple) {
      await handleMultiple();
    } else {
      await handleSingle();
    }
  };

  const getCacheOptions = async (_defaultValue) => {
    setState({ isLoading: true });
    const cachedOptions = await AutoCompleteCache.getCachedOptions(apiHandler, queryParams);

    if (cachedOptions) {
      const nextOptions = makeOptionsList(cachedOptions);
      setState({ options: nextOptions, isLoading: false });

      if (_defaultValue) {
        await handleDefaultValues(nextOptions, _defaultValue);
      }
    } else if (selectionEnum) {
      setState({ isLoading: false, selectedMode: "enumMode" });
    } else {
      setState({ options: [], isLoading: false, selectedMode: "serverMode" });
    }
  };

  const findCurrentSelectedOptionsFixed = (options) => {
    if (multiple) {
      return findMultiSelectedOptions(options, multiSelectInnerValue);
    }
    if (currentDefaultValueCurrentRef.current) {
      const selectedIndex = findSelectedOptionsIndex(options, currentDefaultValueCurrentRef.current);

      if (typeof selectedIndex === "number" && selectedIndex >= 0) {
        return options?.[selectedIndex] ? [options?.[selectedIndex]] : [];
      }

      return [];
    }
    return [];
  };

  const loadOptions = async (pageNumber: number, filter: string) => {
    setState({ isLoading: true });
    const response = await apiHandler.getAll({
      ...queryParams,
      page_size: pageSize,
      page_number: pageNumber,
      filter_text: filter || undefined
    } as any);

    if (response?.status && response?.data && response.data?.data) {
      hasReachedServerOnceRef.current = true;
      if (response.data?.total !== totalOptionsAmount) {
        setState({ totalOptionsAmount: response.data.total });
      }

      const _options = options.filter((op) => op.name !== t(AppStrings.Common.NoResultsFound));

      if (pageNumber === 1 && _options.length !== 1) {
        const currentlySelectedValuesOptions = findCurrentSelectedOptionsFixed(options);
        const uniqueOptions = response.data.data.filter(
          (opt) => !currentlySelectedValuesOptions.some((op) => op.id === opt.id)
        );
        const nextOptions = makeOptionsList([...currentlySelectedValuesOptions, ...uniqueOptions]);
        setState({ options: nextOptions, isLoading: false });
        if (currentlySelectedValuesOptions?.length) {
          findSelectedOption(
            nextOptions,
            multiple ? currentlySelectedValuesOptions : currentlySelectedValuesOptions[0]
          );
        }
      } else {
        const nextOptions: any[] = makeOptionsList(response.data.data, false);
        const uniqueOptions = nextOptions.filter((opt) => !options.some((op) => op.id === opt.id));
        setState({ options: [...options, ...uniqueOptions], isLoading: false });
      }
    } else {
      setState({ isLoading: false });
    }
  };

  const handleAvoidDefaultsAccounts = (options) =>
    options.filter(
      (item) =>
        !(
          item.defaultAccountFor?.length &&
          item.defaultAccountFor.find((defaultAccountType) => avoidDefaultsAccountsTypes?.includes(defaultAccountType))
        )
    );

  const makeOptionsList = (optionsData, reset = true) => {
    let currentOptionsIndex = reset ? 0 : options.length;
    let nextOptions: any[] = [];

    for (const opt of optionsData) {
      const customFields: Record<string, string> = {};
      if (selectionFields) {
        selectionFields.forEach((currentField) => {
          customFields[`${currentField}`] = opt[currentField];
        });
      }

      nextOptions.push({
        id: opt.id,
        name: opt[displayNameKey] ? opt[displayNameKey] : opt?.name,
        filterFieldValue: opt?.filterFieldValue ?? opt[filterFieldValue],
        filterFieldName,
        type: opt?.type || null,
        itemIndex: currentOptionsIndex++,
        ...customFields
      });
    }

    if (nextOptions.length === 0) {
      nextOptions.push({
        name: t(AppStrings.Common.NoResultsFound),
        id: NO_RESULTS_FOUND_ID
      });
    }

    avoidDefaultsAccountsTypes?.length && (nextOptions = handleAvoidDefaultsAccounts(nextOptions));
    return nextOptions;
  };

  const handleClickAway = useCallback(
    (ev) => {
      const isFocus =
        ev.target.closest(`#${componentId}`) ||
        ev.target.closest(`.MuiAutocomplete-popper`) ||
        ev.target.closest(`.MuiPopover-root`) ||
        ev.target.className === "DoorLoop";

      if (!isFocus && showCreateOption) {
        setState({ isOpen: false, showCreateOption: false });
      }
    },
    [showCreateOption]
  );

  const loadMoreResults = async () => {
    const totalPages = Math.ceil(totalOptionsAmount / pageSize) + 1;
    const nextPage = currentPage + 1;

    if (nextPage < totalPages) {
      // load same filter next page
      await loadOptions(nextPage, debouncedValueFilter);
      setState({ currentPage: nextPage });
    }
  };

  const handleScroll = async (event: SyntheticEvent) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
    const bufferValue = 80;
    const maxScroll = scrollHeight - clientHeight;
    const isElementScrolledToTheBottom = scrollTop + bufferValue >= maxScroll;

    if (isElementScrolledToTheBottom && selectedMode === "serverMode" && !isLoading) {
      await loadMoreResults();
    }
  };

  const onCreate = useCallback(
    (newOption) => {
      if (newOption.id) {
        const newOptions: any[] = makeOptionsList([newOption], false);
        const nextOptions = [...options, ...newOptions];

        setState({ options: nextOptions, isOpen: false });
        handleDefaultValues(nextOptions, multiple ? [newOption] : newOption);

        if (multiple) {
          handleMultiChange({}, [...multiSelectInnerValue, newOption]);
        } else {
          handleSingleChange({}, newOption);
        }
      }
    },
    [options, multiple, multiSelectInnerValue, singleSelectedIndex]
  );
  const onCreateError = useCallback((error: string) => setState({ createError: error }), []);

  const setShowCreateOption = (show: boolean) => {
    setState({ showCreateOption: show });
  };

  return [
    {
      isOpen,
      isLoading,
      options,
      singleSelectedIndex,
      multiSelectInnerValue,
      showCreateOption,
      createError,
      valueFilter,
      selectedMode
    },
    {
      handleScroll,
      onCreate,
      onCreateError,
      setShowCreateOption,
      _onChange
    },
    setState
  ];
};

export default useAutoComplete;
