import type { FilterOption } from "@/components/DLUI/screen/filterPanel/filterInputs/types";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "./useSearchParams";
import { useEffectOnce } from "./useEffectOnce";
import isEqualWith from "lodash/isEqualWith";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import { useSelector } from "react-redux";
import type { IRootState } from "@/store";
import { getFilterOptionKeys } from "@/utils/getFilterOptionKeys";
import { buildQueryFilter } from "@/utils/buildQueryFilter";
import { isMultiSelectFilterItem } from "@/components/common/filters/utils";

export interface UseQueryFilterOptions {
  extraKeys?: string[];
  disableInitialValuesUpdate?: boolean;
  makeInitialQueryConsistent?: boolean;
}

const UPDATE_DEBOUNCE_TIME_MS = 100;
const DEFAULT_OPTIONS: UseQueryFilterOptions = { extraKeys: [], disableInitialValuesUpdate: false };

export function useQueryFilter<TQuery>(
  filterOptions: Array<FilterOption<TQuery>>,
  initialQuery: TQuery = {} as TQuery,
  options = DEFAULT_OPTIONS
): TQuery {
  const {
    extraKeys = DEFAULT_OPTIONS.extraKeys,
    disableInitialValuesUpdate = DEFAULT_OPTIONS.disableInitialValuesUpdate,
    makeInitialQueryConsistent = DEFAULT_OPTIONS.makeInitialQueryConsistent
  } = options ?? DEFAULT_OPTIONS;

  const [cleanInitialQuery] = useState(() => omitBy(initialQuery as object, isNil) as TQuery);
  const [initialQueryExtraKeys] = useState(() => {
    const initialQueryKeys = Object.keys(cleanInitialQuery as object);
    return initialQueryKeys.filter((key) => filterOptions.some((option) => !getFilterOptionKeys(option).includes(key)));
  });
  const userData = useSelector((state: IRootState) => state.auth.currentLoginResponse);
  const filterOptionsRef = useRef(filterOptions);
  const [isInitialUpdate, setIsInitialUpdate] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const [queryFilter, setQueryFilter] = useState<TQuery>(() => {
    const defaultQuery = buildQueryFilter(filterOptions, searchParams, userData);
    const extraKeysObject = extraKeys?.reduce((acc, key) => {
      const queryValue = searchParams.get(key);
      if (!queryValue) {
        return acc;
      }

      return {
        ...acc,
        [key]: queryValue
      };
    }, {});

    return {
      ...defaultQuery,
      ...cleanInitialQuery,
      ...extraKeysObject
    };
  });

  useEffectOnce(() => {
    if (disableInitialValuesUpdate) {
      setIsInitialUpdate(false);
      return;
    }

    const initialQueryKeys = Object.keys(cleanInitialQuery ?? {});
    if (initialQueryKeys.length) {
      setSearchParams((searchParams) => {
        initialQueryKeys.forEach((key) => {
          const value = cleanInitialQuery[key];
          searchParams.set(key, value);
        });

        return searchParams;
      }, "replace");
    }

    setIsInitialUpdate(false);
  });

  useLayoutEffect(() => {
    filterOptionsRef.current = filterOptions;
  }, [filterOptions]);

  useEffect(() => {
    if (isInitialUpdate) return;

    const timeout = setTimeout(() => {
      const searchParamsKeys = Array.from(new Set(searchParams.keys()));
      const newFilterObject = searchParamsKeys.reduce((acc, key) => {
        const matchesFilterOption = filterOptionsRef.current.some(
          (option) =>
            getFilterOptionKeys(option).includes(key) || extraKeys?.includes(key) || initialQueryExtraKeys.includes(key)
        );
        if (!matchesFilterOption) {
          return acc;
        }

        const isMultiValue = filterOptionsRef.current.some(
          (option) => isMultiSelectFilterItem(option) && option.filterFieldName === key
        );

        if (isMultiValue) {
          return {
            ...acc,
            [key]: searchParams.getAll(key)
          };
        }

        return {
          ...acc,
          [key]: searchParams.get(key)
        };
      }, {} as TQuery);

      if (!isEqualWith(queryFilter, newFilterObject, customEqualityCheck)) {
        setQueryFilter(newFilterObject);
      }
    }, UPDATE_DEBOUNCE_TIME_MS);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return useMemo(() => {
    if (makeInitialQueryConsistent) {
      return {
        ...queryFilter,
        ...cleanInitialQuery
      };
    }

    return queryFilter ?? cleanInitialQuery;
  }, [cleanInitialQuery, makeInitialQueryConsistent, queryFilter]);
}

function customEqualityCheck(value: unknown, other: unknown): boolean | undefined {
  if (typeof value === "boolean" && typeof other === "string") {
    return value ? other === "true" : other === "false";
  }

  if (typeof value === "string" && typeof other === "boolean") {
    return other ? value === "true" : value === "false";
  }

  if (typeof value === "number" && typeof other === "string") {
    return value === parseInt(other);
  }

  if (typeof value === "string" && typeof other === "number") {
    return parseInt(value) === other;
  }
}
