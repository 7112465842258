import Grid from "@material-ui/core/Grid";
import { ListItemContainerMaxWidth } from "DLUI/infiniteList/utils";
import type { GridSpace } from "DLUI/listItems/grid";
import { View } from "DLUI/view";
import React, { useEffect, useMemo, useState } from "react";
import makeStyles from "./styles";
import type { ViewBackgroundColor } from "DLUI/view/view";
import { useWindowEvent } from "hooks/useWindowEvent";
import { SortIconAsc, SortIconDesc, SortIconNone } from "assets/icons";
import { Icon } from "DLUI/icon";
import { AnimatableView } from "DLUI/animatableView";
import { getCurrentSort } from "DLUI/infiniteList/sortManager";
import TextEllipsis from "DLUI/text/textEllipsis";
import { useTranslation } from "react-i18next";
import type { TextColor } from "DLUI/text/text";
import Text from "DLUI/text/text";
import type { BulkActionDefinition } from "DLUI/dataList/types";
import { useAnalyticsService } from "../../../hooks/useAnalyticsService";
import type { ListColumnSortedMetadata } from "@doorloop/dto";
import { usePermission } from "screens/settings/userRoles/usePermission";
import HeaderCheckBox from "./headerCheckBox";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import SeparationLine from "../separatorView/separationLine";
import AppStrings from "locale/keys";

interface StickyHeaderElement {
  title: string;
  xs: GridSpace;
  sm: GridSpace;
  md: GridSpace;
  lg: GridSpace;
  xl: GridSpace;
  sortColumn?: string;
  alignTitle?: "start" | "end";
}

type SortHandler = (field: string, isDesc?: boolean) => void;

export interface StickyHeaderProps {
  shouldShow: boolean;
  id?: string;
  removeVerticalPadding?: boolean;
  uniqueKey?: string;
  backgroundColor?: ViewBackgroundColor;
  stickyHeaderFullWidth?: boolean;
  stickHeaderMaxWidth?: number | string;
  marginTop?: number;
  bulkActions?: {
    toggleAll: (on?: boolean) => void;
    isAllChecked: boolean;
    isAnyChecked: boolean;
    totalChecked: number;
    totalViewable: number;
    totalAll: number;
    isTotalAllChecked: boolean;
    selectTotalAll: VoidFunction;
    clearTotalAll: VoidFunction;
    bulkActionDefinitions: BulkActionDefinition[];
    fireAction: (actionName: string) => void;
    hideBulkSelectAll?: boolean;
  };
  sortHandler?: SortHandler;
  position?: "grid" | "outside";
  headerPaddingLeft?: number;
  textFontSize?: number;
  textColor?: TextColor;
  popoverContainerWidth?: number;
}

const StickyHeader: React.FC<StickyHeaderProps> = ({
  shouldShow,
  id,
  removeVerticalPadding,
  uniqueKey,
  backgroundColor,
  stickyHeaderFullWidth,
  stickHeaderMaxWidth,
  marginTop,
  bulkActions,
  sortHandler,
  position = "outside",
  headerPaddingLeft,
  textFontSize = 14,
  textColor = "black",
  popoverContainerWidth = 50
}) => {
  const { t } = useTranslation();
  const backgroundColorStyle = useMemo(() => {
    let _backgroundColor = "rgba(245, 246, 250, 0.90)";
    switch (backgroundColor) {
      case "white":
        _backgroundColor = "rgba(256, 256, 256, 0.90)";
        break;
    }
    return _backgroundColor;
  }, []);

  const classes = makeStyles();

  const [hasPopoverOptions, setHasPopoverOptions] = useState<boolean>(false);
  const [hasDeleteButton, setHasDeleteButton] = useState<boolean>(false);

  const [headerElements, setHeaderElements] = useState<StickyHeaderElement[]>([]);
  const { hasPermission } = usePermission();

  const hasBulkExecutionRequest = useMemo(
    () =>
      bulkActions?.bulkActionDefinitions.some(
        (bulkAction) =>
          !bulkAction.isHidden && hasPermission(bulkAction.permission) && bulkAction.totalAllExecutionRequestType
      ),
    [bulkActions?.bulkActionDefinitions]
  );

  useEffect(() => {
    setTimeout(() => {
      onResize();
    }, 0);
  }, []);

  const onResize = () => {
    setListTitleData();
    setGridPopoverContainer();
  };

  const { dispatchAnalytics } = useAnalyticsService();

  const onSortClick = (field: string) => {
    sortHandler?.(field);

    const currentSort = getCurrentSort();
    const newSortOrder: ListColumnSortedMetadata["order"] =
      currentSort.sort_by === field ? (currentSort.sort_descending ? "ASC" : "DESC") : "NONE";

    dispatchAnalytics("list_column_sorted", {
      column: field,
      order: newSortOrder
    });
  };

  useWindowEvent("resize", onResize, []);

  const getListItemsContainer = (className?: string): HTMLElement | null => {
    let listItemsContainer;
    if (id) {
      listItemsContainer = document.getElementById(id);
      if (listItemsContainer !== null) {
        listItemsContainer = listItemsContainer.getElementsByClassName(className || "listItemGrid");
      }
    } else {
      listItemsContainer = document.getElementsByClassName(className || "listItemGrid");
    }
    return listItemsContainer;
  };

  const setGridPopoverContainer = () => {
    let listItemsContainer = getListItemsContainer("listItemGridContainer");
    if (listItemsContainer !== null) {
      listItemsContainer = listItemsContainer[0];
    }
    const listItemPopover =
      listItemsContainer &&
      listItemsContainer.getElementsByClassName &&
      listItemsContainer.getElementsByClassName("listItemPopover")[0];

    const listItemDeleteButton =
      listItemsContainer &&
      listItemsContainer.getElementsByClassName &&
      listItemsContainer.getElementsByClassName("delete-button")[0];

    setHasPopoverOptions(Boolean(listItemPopover));
    setHasDeleteButton(Boolean(listItemDeleteButton));
  };

  const setListTitleData = () => {
    if (headerElements.length > 0) {
      return;
    }

    const listItemsContainer = getListItemsContainer();

    if (listItemsContainer !== null && listItemsContainer[0] && listItemsContainer[0].children) {
      const listItem = listItemsContainer[0];
      const nextHeaderElements: StickyHeaderElement[] = [];

      for (const item of listItem.children) {
        if (item.children && item.children.length > 0) {
          const itemDataElement = item.children[0];
          const xs = itemDataElement.attributes["data-xs"]
            ? (Number(itemDataElement.attributes["data-xs"].value) as GridSpace)
            : undefined;
          const sm = itemDataElement.attributes["data-sm"]
            ? (Number(itemDataElement.attributes["data-sm"].value) as GridSpace)
            : undefined;
          const md = itemDataElement.attributes["data-md"]
            ? (Number(itemDataElement.attributes["data-md"].value) as GridSpace)
            : undefined;
          const lg = itemDataElement.attributes["data-lg"]
            ? (Number(itemDataElement.attributes["data-lg"].value) as GridSpace)
            : undefined;
          const xl = itemDataElement.attributes["data-xl"]
            ? (Number(itemDataElement.attributes["data-xl"].value) as GridSpace)
            : undefined;
          const title = itemDataElement.attributes["data-title"]
            ? itemDataElement.attributes["data-title"].value
            : undefined;
          const sortColumn = itemDataElement.attributes["data-sort-col"]
            ? itemDataElement.attributes["data-sort-col"].value
            : undefined;
          const alignTitle = itemDataElement.attributes["data-align-title"]?.value;

          if (md && lg && title && title !== "no-title") {
            nextHeaderElements.push({
              title,
              xs,
              sm,
              md,
              lg,
              xl,
              sortColumn,
              alignTitle
            });
          }
        }
      }
      setHeaderElements(nextHeaderElements);
    }
  };

  const getClassName = (itemIndex: number) => {
    let _className = "";
    if (itemIndex == 0) {
      _className = removeVerticalPadding ? classes.removeVerticalPaddingIconGrid : classes.iconGrid;
    }
    return _className;
  };

  const renderListTitle = () => (
    <Grid container justifyContent={"flex-start"} alignItems={"center"}>
      {headerElements.map((currentItem, index) => {
        const className = getClassName(index);
        const { sortColumn } = currentItem;
        const { sort_by, sort_descending } = getCurrentSort();
        return (
          <Grid
            className={className}
            key={index}
            item
            xs={currentItem.xs}
            sm={currentItem.sm}
            md={currentItem.md}
            lg={currentItem.lg}
            xl={currentItem.xl}
            style={sortColumn ? { cursor: "pointer" } : undefined}
            onClick={() => sortColumn && onSortClick(sortColumn)}
          >
            {currentItem.title !== "no-title" ? (
              <View
                noWrap
                flex={1}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={currentItem.alignTitle === "start" ? "flex-start" : "flex-end"}
              >
                {index === 0 && bulkActions && !bulkActions.hideBulkSelectAll && position === "grid" && (
                  <HeaderCheckBox
                    allChecked={bulkActions.isAllChecked}
                    anyChecked={bulkActions.isAnyChecked}
                    toggleAll={bulkActions.toggleAll}
                  />
                )}
                <TextEllipsis
                  fontSize={textFontSize}
                  autoWidth
                  color={textColor}
                  lines={2}
                  bold
                  paddingLeft={10}
                  text={t(currentItem.title)}
                  paddingRight={10}
                  lineBreak={"auto"}
                />
                {sortColumn && sortHandler && (
                  <Icon
                    Source={sort_by === sortColumn ? (sort_descending ? SortIconDesc : SortIconAsc) : SortIconNone}
                    size={13}
                  />
                )}
              </View>
            ) : null}
          </Grid>
        );
      })}
    </Grid>
  );

  if (!shouldShow) {
    return null;
  }

  return (
    <div
      key={uniqueKey}
      style={{
        backgroundColor: backgroundColorStyle,
        width: "100%",
        top: 0,
        position: "sticky"
      }}
      className={classes.componentContainer}
    >
      <View
        maxWidth={stickyHeaderFullWidth ? "none" : stickHeaderMaxWidth || ListItemContainerMaxWidth}
        marginBottom={5}
        noWrap
      >
        <View marginTop={marginTop} justifyContent={"center"} alignItems={"center"} flexDirection={"row"} noWrap>
          {bulkActions && !bulkActions.hideBulkSelectAll && position === "outside" && (
            <HeaderCheckBox
              allChecked={bulkActions.isAllChecked}
              anyChecked={bulkActions.isAnyChecked}
              toggleAll={bulkActions.toggleAll}
            />
          )}
          <View flex={1} alignItems={"center"} flexDirection={"row"}>
            <View
              paddingBottom={10}
              paddingTop={10}
              flexDirection={"row"}
              paddingLeft={headerPaddingLeft ?? (!bulkActions?.hideBulkSelectAll ? 0 : bulkActions ? 30 : 0)}
            >
              <View flexDirection={"row"} flex={1}>
                <View flexDirection={"row"} flex={1} alignItems={"center"}>
                  {renderListTitle()}
                </View>
                {(hasPopoverOptions || hasDeleteButton) && (
                  <View
                    justifyContent={"center"}
                    alignItems={"center"}
                    width={popoverContainerWidth}
                    className={"popoverContainer"}
                    height={20}
                    flexDirection={"row"}
                  />
                )}
              </View>
            </View>
          </View>
        </View>
        {bulkActions && (
          <AnimatableView shouldShow={bulkActions.isAnyChecked} duration={400}>
            <View
              paddingTop={8}
              paddingRight={16}
              paddingBottom={8}
              paddingLeft={16}
              borderRadius={4}
              flexDirection={"row"}
              alignItems={"center"}
            >
              <Text fontSize={12} fontWeight={700} marginRight={8}>
                {bulkActions.isTotalAllChecked ? bulkActions.totalAll : bulkActions.totalChecked}/
                <Text fontSize={12} fontWeight={700} as={"span"} color={"secondary-gray"}>
                  {bulkActions.totalAll}
                </Text>{" "}
                {t(AppStrings.BulkActions.SelectedItemsOnPage)}
              </Text>
              {hasBulkExecutionRequest && bulkActions.totalAll > bulkActions.totalViewable && (
                <DLButton
                  actionText={
                    bulkActions.isTotalAllChecked
                      ? t(AppStrings.BulkActions.ClearSelection)
                      : t(AppStrings.BulkActions.SelectAllItems, {
                          totalItems: bulkActions.totalAll
                        })
                  }
                  variant={DLButtonVariantsEnum.TEXT}
                  color={DLButtonColorsEnum.SECONDARY}
                  size={DLButtonSizesEnum.SMALL}
                  style={{
                    textDecoration: "underline"
                  }}
                  onClick={() =>
                    bulkActions.isTotalAllChecked ? bulkActions.clearTotalAll() : bulkActions.selectTotalAll()
                  }
                />
              )}
              <SeparationLine width={1} marginLeft={8} marginRight={8} height={30} />
              {bulkActions.bulkActionDefinitions.map(
                (
                  {
                    icon: IconSource,
                    actionName,
                    actionNameTranslated,
                    actionButtonColor = DLButtonColorsEnum.SECONDARY,
                    totalAllExecutionRequestType,
                    isHidden,
                    permission
                  },
                  index
                ) =>
                  !isHidden && hasPermission(permission) ? (
                    <DLButton
                      key={index}
                      actionText={actionNameTranslated}
                      variant={DLButtonVariantsEnum.TEXT}
                      color={actionButtonColor}
                      size={DLButtonSizesEnum.SMALL}
                      onClick={() => bulkActions.fireAction(actionName)}
                      icons={IconSource ? { start: { src: IconSource } } : undefined}
                      disabled={
                        hasBulkExecutionRequest && bulkActions.isTotalAllChecked && !totalAllExecutionRequestType
                      }
                    />
                  ) : null
              )}
            </View>
          </AnimatableView>
        )}
      </View>
    </div>
  );
};

export default StickyHeader;
