import React from "react";
import type { DialogState } from "DLUI/dialogs/loadingDialog";
import { AnimatedContentDialog } from "DLUI/dialogs";
import { initFormValues, validateForm, validateProspectForm } from "./formikHelper";
import AppStrings from "locale/keys";
import { tenantsApi } from "api/tenantsApi";
import { useTranslation } from "react-i18next";
import { PROSPECT_MENU_ITEMS } from "screens/prospects/newProspect/menuItems";
import type { TenantDto } from "@doorloop/dto";
import { ObjectPermission } from "@doorloop/dto";

interface ComponentProps {
  dialogState: DialogState;
  onClose: (values?: TenantDto, shouldRefresh?: boolean) => void;
  onBackdropClick: () => void;
  title?: string;
  apiObjectId?: string;
}

const ProspectDialog: React.FC<ComponentProps> = ({
  dialogState,
  onClose,
  onBackdropClick,
  title,
  apiObjectId
}: ComponentProps) => {
  const { t } = useTranslation();

  const DialogHeight = Math.min(window.innerHeight - 80, 900);

  return (
    <AnimatedContentDialog
      dialogState={dialogState}
      onClose={onClose}
      sectionItems={PROSPECT_MENU_ITEMS}
      disableBackdropClick
      onBackdropClick={onBackdropClick}
      getformikInitialValues={initFormValues}
      formikValidation={validateForm}
      validationMethod={validateProspectForm}
      loadingText={t(AppStrings.Prospects.NewProspect.LoadingText)}
      errorText={t(AppStrings.Prospects.NewProspect.ErrorText)}
      successText={t(AppStrings.Prospects.NewProspect.SuccessText)}
      apiMethod={tenantsApi}
      toasts={{
        POST: { translationKey: AppStrings.Toasts.custom.prospects.POST },
        PUT: { translationKey: AppStrings.Toasts.custom.prospects.PUT }
      }}
      dialogTitle={title || AppStrings.Prospects.Screen.AddNewProspect}
      dialogHeight={DialogHeight}
      permission={ObjectPermission.prospects}
      apiObjectId={apiObjectId}
    />
  );
};

export default ProspectDialog;
