import type { FC } from "react";
import React from "react";
import Drawer from "@material-ui/core/Drawer";
import DlButton, { DLButtonColorsEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import AppStrings from "../../../locale/keys";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { View } from "DLUI/view";
import "./mobile-bottom-drawer-styles.css";

interface MobileBottomDrawerProps {
  showDrawer?: boolean;
  onCloseClick?: VoidFunction;
}

export const MobileBottomDrawer: FC<MobileBottomDrawerProps> = ({ children, showDrawer, onCloseClick }) => (
  <Drawer
    open={showDrawer}
    anchor={"bottom"}
    classes={{ root: "mobile-bottom-drawer", paperAnchorBottom: "mobile-bottom-drawer-paper" }}
    BackdropProps={{ className: "mobile-bottom-drawer-backdrop" }}
    variant={"temporary"}
  >
    <View noWrap paddingRight={20} paddingLeft={20} paddingTop={20}>
      {children}
      <HorizontalSeparationLine marginTop={16} />
      <View noWrap height={80} alignItems={"center"}>
        <DlButton
          actionText={AppStrings.Common.Close}
          variant={DLButtonVariantsEnum.TEXT}
          color={DLButtonColorsEnum.SECONDARY}
          onClick={onCloseClick}
          textProps={{ fontSize: 16 }}
          style={{ paddingBottom: 24, paddingTop: 24, height: "100%" }}
        />
      </View>
    </View>
  </Drawer>
);
