"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckbookPaymentStatusEnum = void 0;
var CheckbookPaymentStatusEnum;
(function (CheckbookPaymentStatusEnum) {
    CheckbookPaymentStatusEnum["PAID"] = "PAID";
    CheckbookPaymentStatusEnum["IN_PROCESS"] = "IN_PROCESS";
    CheckbookPaymentStatusEnum["UNPAID"] = "UNPAID";
    CheckbookPaymentStatusEnum["VOID"] = "VOID";
    CheckbookPaymentStatusEnum["EXPIRED"] = "EXPIRED";
    CheckbookPaymentStatusEnum["PRINTED"] = "PRINTED";
    CheckbookPaymentStatusEnum["MAILED"] = "MAILED";
    CheckbookPaymentStatusEnum["FAILED"] = "FAILED";
    CheckbookPaymentStatusEnum["REFUNDED"] = "REFUNDED";
})(CheckbookPaymentStatusEnum || (exports.CheckbookPaymentStatusEnum = CheckbookPaymentStatusEnum = {}));
