import React from "react";
import type { EsignatureRequestSignerDto } from "@doorloop/dto";
import type { StatusStepInterface } from "DLUI/stepStatus/stepStatus";
import { StepStatus } from "DLUI/stepStatus/stepStatus";
import { ESignatureRequestSignerStatus } from "@doorloop/dto";
import type { TFunction } from "i18next";
import {
  RoundGrayAttachment,
  RoundGrayEye,
  RoundGreenEye,
  RoundGreenPlane,
  SignatureCircleGrayIcon,
  SignatureCircleGreenIcon
} from "../../../../assets";
import { useTranslation } from "react-i18next";
import AppStrings from "locale/keys";
import moment from "moment";
import { DateFormats } from "@doorloop/dto";

interface ComponentProps {
  signer: EsignatureRequestSignerDto;
  createdAt?: Date;
}

type StepsMapInterface = {
  [key in ESignatureRequestSignerStatus]: (
    t: TFunction,
    dto: EsignatureRequestSignerDto,
    createdAt?: Date
  ) => StatusStepInterface[];
};

const StepsPerStatus: StepsMapInterface = {
  [ESignatureRequestSignerStatus.SENT]: (t, dto, createdAt) => [
    {
      IconSource: RoundGreenPlane,
      connectorColor: "secondary-gray-light",
      tooltip:
        createdAt &&
        t(AppStrings.Esignatures.SentAt, {
          x: moment(createdAt).format(DateFormats.THREE_LETTER_MONTH_DATE_AND_TIME)
        })
    },
    {
      IconSource: RoundGrayEye,
      connectorColor: "secondary-gray-light",
      rectFill: "light-gray"
    },
    {
      IconSource: SignatureCircleGrayIcon
    }
  ],
  [ESignatureRequestSignerStatus.VIEWED]: (t, dto, createdAt) => [
    {
      IconSource: RoundGreenPlane,
      connectorColor: "secondary-gray-light",
      tooltip:
        createdAt &&
        t(AppStrings.Esignatures.SentAt, {
          x: moment(createdAt).format(DateFormats.THREE_LETTER_MONTH_DATE_AND_TIME)
        })
    },
    {
      IconSource: RoundGreenEye,
      connectorColor: "secondary-gray-light",
      tooltip:
        dto.lastViewedAt &&
        t(AppStrings.Esignatures.ViewedAt, {
          x: moment(dto.lastViewedAt).format(DateFormats.THREE_LETTER_MONTH_DATE_AND_TIME)
        })
    },
    {
      IconSource: SignatureCircleGrayIcon
    }
  ],
  [ESignatureRequestSignerStatus.DECLINED]: () => [
    {
      IconSource: RoundGreenPlane,
      connectorColor: "secondary-gray-light",
      tooltip: "t(sent)"
    },
    {
      IconSource: RoundGreenEye,
      connectorColor: "secondary-gray-light",
      tooltip: "t(opened)"
    },
    {
      IconSource: RoundGrayAttachment,
      tooltip: "t(clicked)"
    }
  ],
  [ESignatureRequestSignerStatus.SIGNED]: (t, dto, createdAt) => [
    {
      IconSource: RoundGreenPlane,
      connectorColor: "secondary-gray-light",
      tooltip:
        createdAt &&
        t(AppStrings.Esignatures.SentAt, {
          x: moment(createdAt).format(DateFormats.THREE_LETTER_MONTH_DATE_AND_TIME)
        })
    },
    {
      IconSource: RoundGreenEye,
      connectorColor: "secondary-gray-light",
      tooltip:
        dto.lastViewedAt &&
        t(AppStrings.Esignatures.ViewedAt, {
          x: moment(dto.lastViewedAt).format(DateFormats.THREE_LETTER_MONTH_DATE_AND_TIME)
        })
    },
    {
      IconSource: SignatureCircleGreenIcon,
      tooltip:
        dto.lastViewedAt &&
        t(AppStrings.Esignatures.SignedAt, {
          x: moment(dto.signedAt).format(DateFormats.THREE_LETTER_MONTH_DATE_AND_TIME)
        })
    }
  ]
};

const SignatureRequestStatus: React.FC<ComponentProps> = ({ signer, createdAt }: ComponentProps) => {
  const { t } = useTranslation();
  const { status } = signer;

  const steps = StepsPerStatus[status || ESignatureRequestSignerStatus.SENT];
  return steps ? <StepStatus steps={steps(t, signer, createdAt)} /> : null;
};

export default SignatureRequestStatus;
