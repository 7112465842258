import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import type { ClipboardEvent, HTMLAttributes, KeyboardEvent, PropsWithChildren } from "react";
import type { Theme } from "styles/defaultTheme";

export interface EditableContentProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "contentEditable" | "onPaste" | "onKeyDown"> {
  editable?: boolean;
}

export const EditableContent = ({
  children,
  className,
  editable = true,
  ...rest
}: PropsWithChildren<EditableContentProps>) => {
  const classes = useStyles();

  return (
    <div
      suppressContentEditableWarning
      className={clsx(classes.root, className)}
      contentEditable={editable}
      onPaste={handlePaste}
      onKeyDown={handleKeyDown}
      {...rest}
    >
      {children}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: "flex",
      overflowWrap: "anywhere",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: "100%",
      padding: "2px 4px",
      cursor: "text",
      boxSizing: "border-box",
      borderRadius: 4,
      minWidth: 20,
      "&:empty, &:has(>:empty)": {
        border: `2px dashed ${theme.newPalette.secondary.states.outlinedBorder}`
      }
    }
  };
});

function handlePaste(e: ClipboardEvent<HTMLDivElement>) {
  e.preventDefault();
}

function handleKeyDown(e: KeyboardEvent<HTMLDivElement>) {
  if (e.key !== "Enter") {
    return;
  }
  e.preventDefault();
}
