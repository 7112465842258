import React from "react";
import { View } from "DLUI/view";
import type { GridRenderProps } from "DLUI/lists/gridList/dataGrid/gridRender";
import { GridRender } from "DLUI/lists/gridList/dataGrid/gridRender";
import ReportFooterText from "DLUI/lists/gridList/dataGrid/reportFooterText";
import type { GridProps as KendoGridProps, GridColumnReorderEvent } from "@progress/kendo-react-grid";
import type { TFunction } from "react-i18next";
import type { GridHeaderProps } from "DLUI/lists/gridList/dataGrid/gridHeaderWrapper";
import { GridHeaderWrapper } from "DLUI/lists/gridList/dataGrid/gridHeaderWrapper";
import type { InferReactComponentProps, Queryable } from "DLUI/lists/types";
import { SelectedFilterChips } from "@/components/DLUI/screen/filterPanel/selectedFilterChips";
import type { FilterOption } from "@/components/DLUI/screen/filterPanel/filterInputs/types";

export interface GridContainerProps<TDto extends object, TGetAllDto extends Queryable>
  extends GridRenderProps<TDto, TGetAllDto> {
  GridFooter?: React.FC<any>;
  actionButtons?: React.ReactNode;
  didPressExportExcel: () => void;
  filterObj: TGetAllDto;
  filterOptions?: Array<FilterOption<TGetAllDto>>;
  header?: React.FC<GridHeaderProps>;
  listPadding?: number;
  hideActionButtons?: boolean;
  isTabletOrMobile: boolean;
  isPrinting: boolean;
  reportName: string;
  selectionLength?: number;
  renderGridCell?: KendoGridProps["cellRender"];
  t: TFunction<"translation">;
  onColumnReorder?: (event: GridColumnReorderEvent) => void;
  dataItemKey?: string;
  dataCy?: string;
}

const GridContainer = <TDto extends object, GetAllTDto extends Queryable>({
  requestInProgress,
  dataSource,
  getSortedDataSource,
  sort,
  resizable,
  reorderable,
  onGridRowPress,
  groupProp,
  excelExporterRef,
  _exportFileName,
  allGridColumns,
  visibleGridColumns,
  currencySymbol,
  t,
  dataTotalLine,
  onColumnsSubmit,
  onColumnsReset,
  setSort,
  reportName,
  header,
  didPressExportExcel,
  filterOptions,
  isTabletOrMobile,
  isPrinting,
  GridFooter,
  filterObj,
  rowHeight,
  isMultiSelect,
  resetSelection,
  actionButtons,
  hideActionButtons,
  listPadding = 16,
  selectionLength,
  onExpandChange,
  overrideGridType,
  renderGridCell,
  renderGridHeaderCell,
  onSelectionChange,
  originalDataHashMap,
  dto,
  onColumnReorder,
  dataItemKey,
  hideColumnMenu,
  columnMenuItemsToHide,
  dataCy
}: GridContainerProps<TDto, GetAllTDto>) => {
  const gridRenderProps: GridRenderProps<TDto, GetAllTDto> = {
    requestInProgress,
    dataSource,
    getSortedDataSource,
    sort,
    setSort,
    resizable,
    reorderable,
    onGridRowPress,
    rowHeight,
    groupProp,
    excelExporterRef,
    _exportFileName,
    allGridColumns,
    visibleGridColumns,
    currencySymbol,
    t,
    dataTotalLine,
    onColumnsSubmit,
    onColumnsReset,
    isMultiSelect,
    onExpandChange,
    overrideGridType,
    resetSelection,
    onSelectionChange,
    renderGridCell,
    isTabletOrMobile,
    isPrinting,
    originalDataHashMap,
    renderGridHeaderCell,
    dto,
    onColumnReorder,
    dataItemKey,
    hideColumnMenu,
    columnMenuItemsToHide
  };

  const gridHeaderProps: InferReactComponentProps<typeof GridHeaderWrapper> = {
    reportName,
    selectionLength,
    Header: header,
    requestInProgress,
    didPressExportExcel,
    hideActionButtons,
    actionButtons,
    filterObj
  };

  return (
    <div
      className={"DLUI_ReportList GridList gridWrapper"}
      style={{
        width: `calc(100% - ${listPadding}px)`,
        paddingLeft: listPadding,
        paddingBottom: 20,
        marginBottom: 20,
        backgroundColor: "white",
        borderRadius: 10
      }}
      data-cy={dataCy}
    >
      <GridHeaderWrapper {...gridHeaderProps} />
      <View className={"grid-header-chips print-show"} marginTop={20} marginBottom={20} flexDirection={"row"}>
        {filterOptions && <SelectedFilterChips filterOptions={filterOptions} />}
      </View>
      <GridRender {...gridRenderProps} />
      <ReportFooterText
        GridFooter={GridFooter}
        requestInProgress={requestInProgress}
        dataSource={dataSource}
        filterObj={filterObj}
      />
    </div>
  );
};

export default GridContainer;
