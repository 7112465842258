import { useState, useEffect, useMemo } from "react";

import { QueryParams } from "../utils/queryParams";

const useDebounceInit = (timeoutMillis: number) => {
  const [isRender, setIsRender] = useState(false);

  const queryParams = useMemo(() => new QueryParams<"isDebounceInit">(), []);

  useEffect(() => {
    const { isDebounceInit } = queryParams.getAll();

    if (isDebounceInit === "true") {
      setTimeout(() => {
        queryParams.clear("isDebounceInit");
        queryParams.historyReplace();

        setIsRender(true);
      }, timeoutMillis);
    } else {
      setIsRender(true);
    }
  }, []);

  return {
    isRender
  };
};

export { useDebounceInit };
