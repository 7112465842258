import React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import Routes from "../routes";
import { DepositDetailReport } from "screens/reports/depositDetailReport/depositDetail";

export const DepositDetailReportRouter: React.FC<RouteComponentProps> = () => (
  <Switch>
    <Route exact path={Routes.DEPOSIT_DETAIL_REPORT_EDIT_BANK_DEPOSIT} component={DepositDetailReport} />
    <Route path={Routes.DEPOSIT_DETAIL_REPORT} component={DepositDetailReport} />
  </Switch>
);
