import React from "react";
import { View } from "DLUI/view";

export interface StatusElementProps {
  renderTitle: React.ReactNode;
  renderSubTitle?: React.ReactNode;
  fullWidth?: boolean;
}

const ListItemSection: React.FC<StatusElementProps> = ({ renderTitle, renderSubTitle, fullWidth = true }) => (
  <View flexDirection={"row"} width={fullWidth ? undefined : "auto"} flex={fullWidth ? 1 : undefined}>
    <View noWrap justifyContent={"center"} height={55}>
      <View justifyContent={"flex-start"} flexDirection={"row"}>
        {renderTitle}
      </View>
      {renderSubTitle && (
        <View flexDirection={"row"} justifyContent={"flex-start"} marginTop={5}>
          {renderSubTitle}
        </View>
      )}
    </View>
  </View>
);

export default ListItemSection;
