import type { FC } from "react";
import React from "react";
import { View } from "DLUI/view";
import { Icon } from "DLUI/icon";
import { EnvelopePicture } from "assets/icons";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import ColorsEnum from "../../../../utils/colorsEnum";
import { Link } from "DLUI/link";
import { LinkURLsEnum } from "DLUI/link/linkURLsEnum";
import { Trans, useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";

const sectionGap = 30; // According to figma

const SEND_PAYMENTS_FAILED_OPTIONS_ARTICLE =
  "https://support.doorloop.com/en/articles/7257444-checkbook-io-common-errors-when-sending-payments-and-how-to-fix-them";

interface Props {
  errorText?: string;
}

export const EPaySentMessage: FC<Props> = ({ errorText }: Props) => {
  const { t } = useTranslation();
  const textValue = errorText || t(AppStrings.Common.OutgoingPayments.PaymentHasBeenSent);

  const renderLink = (key: string, hrefUrl: string) => (
    <Link type={"blank"} textColor={ColorsEnum.BrightBlue} underline={"always"} key={key} hrefUrl={hrefUrl}>
      {" "}
    </Link>
  );

  return (
    <View
      marginTop={sectionGap}
      paddingLeft={20}
      paddingRight={20}
      alignItems={"center"}
      justifyContent={"center"}
      width={"100%"}
      flex={1}
    >
      {!errorText && (
        <View
          backgroundColorEnum={ColorsEnum.LightBlueFadedMore}
          borderRadius={15}
          alignItems={"center"}
          paddingTop={20}
          paddingBottom={20}
          marginBottom={sectionGap}
        >
          <Icon Source={EnvelopePicture} />
        </View>
      )}
      <Text fontSize={16} bold marginBottom={sectionGap}>
        {textValue}
      </Text>
      {errorText && (
        <span>
          <Typography style={{ fontSize: "14px", color: ColorsEnum.LightBlack }}>
            <Trans
              i18nKey={AppStrings.Common.HandleSendPaymentText}
              values={{
                ArticleValue: t(AppStrings.Common.Article),
                CheckbookIoValue: t(AppStrings.Common.CheckbookIo)
              }}
              components={[
                renderLink("ArticleKey", SEND_PAYMENTS_FAILED_OPTIONS_ARTICLE),
                renderLink("CheckbookIoKey", LinkURLsEnum.CB_ACCOUNT_SETTINGS)
              ]}
            />
          </Typography>
        </span>
      )}
    </View>
  );
};
