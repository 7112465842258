"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsBefore = IsBefore;
const class_validator_1 = require("class-validator");
function IsBefore(property, validationOptions) {
    return function (object, propertyName) {
        (0, class_validator_1.registerDecorator)({
            name: "isBefore",
            target: object.constructor,
            propertyName,
            constraints: [property],
            options: validationOptions,
            validator: {
                validate(value, args) {
                    const [relatedPropertyName] = args.constraints;
                    const relatedValue = args.object[relatedPropertyName];
                    if (relatedValue) {
                        return value <= relatedValue; // you can return a Promise<boolean> here as well, if you want to make async validation
                    }
                    return true;
                }
            }
        });
    };
}
