import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Grid, ListItemContainer, ListItemSection } from "DLUI/listItems";
import { View } from "DLUI/view";

const DefaultSkeletonItem: React.FC<{}> = () => (
  <ListItemContainer skipDataCy>
    <Grid showDivider xs={12} md={3} lg={3}>
      <View flex={1} alignItems={"center"} flexDirection={"row"}>
        <Skeleton style={{ marginLeft: 20 }} width={"60px"} height={"60px"} variant="circle" />
        <ListItemSection
          renderTitle={
            <View alignItems={"center"} justifyContent={"center"} flexDirection={"row"}>
              <Skeleton width={"70%"} variant="text" />
            </View>
          }
          renderSubTitle={
            <View justifyContent={"center"} alignItems={"center"} flexDirection={"row"}>
              <Skeleton width={"70%"} variant="text" />
            </View>
          }
        />
      </View>
    </Grid>
    <Grid showDivider xs={12} md={3} lg={3}>
      <ListItemSection
        renderTitle={
          <View alignItems={"center"} justifyContent={"center"} flexDirection={"row"}>
            <Skeleton width={"70%"} variant="text" />
          </View>
        }
        renderSubTitle={
          <View justifyContent={"center"} alignItems={"center"} flexDirection={"row"}>
            <Skeleton width={"70%"} variant="text" />
          </View>
        }
      />
    </Grid>
    <Grid showDivider xs={12} md={3} lg={3}>
      <ListItemSection
        renderTitle={
          <View alignItems={"center"} justifyContent={"center"} flexDirection={"row"}>
            <Skeleton width={"70%"} variant="text" />
          </View>
        }
        renderSubTitle={
          <View justifyContent={"center"} alignItems={"center"} flexDirection={"row"}>
            <Skeleton width={"70%"} variant="text" />
          </View>
        }
      />
    </Grid>
    <Grid showDivider xs={12} md={3} lg={3}>
      <ListItemSection
        renderTitle={
          <View alignItems={"center"} justifyContent={"center"} flexDirection={"row"}>
            <Skeleton width={"70%"} variant="text" />
          </View>
        }
        renderSubTitle={
          <View justifyContent={"center"} alignItems={"center"} flexDirection={"row"}>
            <Skeleton width={"70%"} variant="text" />
          </View>
        }
      />
    </Grid>
  </ListItemContainer>
);

export default DefaultSkeletonItem;
