"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecurringVendorBillInfoDto = void 0;
const vendorTransaction_dto_1 = require("../journalEntries/shared/vendorTransaction.dto");
class RecurringVendorBillInfoDto extends vendorTransaction_dto_1.VendorTransactionDto {
    constructor(init) {
        super(init);
    }
}
exports.RecurringVendorBillInfoDto = RecurringVendorBillInfoDto;
