import type { FC } from "react";
import { getIn, useFormikContext } from "formik";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { SeparationLine } from "DLUI/separatorView";
import { RadioGroup } from "DLUI/form";
import { useTranslation } from "react-i18next";
import AppStrings from "locale/keys";
import { CheckType } from "@doorloop/dto";
import { SetupStep } from "./setupStep";

const LoadPaperDescription: FC = () => (
  <Text value={AppStrings.Common.PrintChecks.Setup.Sample.LoadPaper.SubHeader}></Text>
);

const PrintSampleDescription: FC = () => (
  <Text value={AppStrings.Common.PrintChecks.Setup.Sample.PrintSample.SubHeader}></Text>
);

const PrintAlignDescription: FC = () => (
  <Text value={AppStrings.Common.PrintChecks.Setup.Sample.PrintAlign.SubHeader}></Text>
);

export const PrintSample: FC<{}> = () => {
  const { t } = useTranslation();
  const formikRef = useFormikContext();
  const defaultcheckType = getIn(formikRef.values, `checkType`);
  return (
    <View noWrap flex={1} paddingLeft={40} paddingRight={40}>
      <View>
        <Text value={AppStrings.Common.PrintChecks.Setup.Sample.Description}></Text>
      </View>
      <View marginTop={20}>
        <Text fontSize={18} fontWeight={700} value={AppStrings.Common.PrintChecks.Setup.Sample.Header}></Text>
        <Text
          marginTop={10}
          fontSize={16}
          fontWeight={400}
          value={AppStrings.Common.PrintChecks.Setup.Sample.SubHeader}
        ></Text>
        <RadioGroup
          flexDirection={"row"}
          didChangeSelection={(val) => formikRef.setFieldValue("checkType", val)}
          uniqueKey={"FRB"}
          defaultValue={defaultcheckType ? defaultcheckType : "Standard"}
          marginTop={20}
          radioGroupItems={[
            {
              label: AppStrings.Common.PrintChecks.Setup.Sample.Voucher,
              value: CheckType.Voucher
            },
            {
              label: AppStrings.Common.PrintChecks.Setup.Sample.Standard,
              value: CheckType.Standard
            }
          ]}
        />
      </View>
      <View marginTop={20} marginBottom={20}>
        <SeparationLine width={"100%"} height={1} dividerColor={"gray"}></SeparationLine>
      </View>
      <View>
        <SetupStep
          imgUrl={"/images/load-paper.png"}
          stepHeader={t(AppStrings.Common.PrintChecks.Setup.Sample.LoadPaper.Header)}
          StepDescription={LoadPaperDescription}
        />
      </View>
      <View marginTop={20} marginBottom={20}>
        <SeparationLine width={"100%"} height={1} dividerColor={"gray"}></SeparationLine>
      </View>

      <View>
        <SetupStep
          imgUrl={"/images/print-paper.png"}
          stepHeader={t(AppStrings.Common.PrintChecks.Setup.Sample.PrintSample.Header)}
          StepDescription={PrintSampleDescription}
        />
      </View>
      <View marginTop={20} marginBottom={20}>
        <SeparationLine width={"100%"} height={1} dividerColor={"gray"}></SeparationLine>
      </View>

      <View>
        <SetupStep
          imgUrl={"/images/align-pdf.png"}
          stepHeader={t(AppStrings.Common.PrintChecks.Setup.Sample.PrintAlign.Header)}
          StepDescription={PrintAlignDescription}
        />
      </View>
      <View marginTop={20} marginBottom={20}>
        <SeparationLine width={"100%"} height={1} dividerColor={"gray"}></SeparationLine>
      </View>
    </View>
  );
};
