"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsRoutingNumber = IsRoutingNumber;
const class_validator_1 = require("class-validator");
const valid = __importStar(require("us-bank-account-validator"));
const additionalRoutingNumbers = [
    "084009519", // WISE Bank https://doorloop.atlassian.net/browse/DL-3302
    "053112916", // https://doorloop.atlassian.net/browse/DL-3302
    "125109006", // One Finance Bank Routing Number https://app.intercom.com/a/apps/njbci459/inbox/inbox/mentions/conversations/23959
    "125109019", // Coastal Community Bank https://app.intercom.com/a/apps/njbci459/inbox/inbox/conversation/63733
    "124303201", // Varo Bank
    "031101334", // Sofi Bank
    "125108997", // Aspiration bank
    "051504597", // MVB Bank
    "064103707", // US Bank national association
    "124303243", // American Bankers Association
    "064009487", // American Bankers Association
    "101015347", // Nbkc Bank
    "064209588", //  Relay bank
    "091311229", //  Mercury bank,
    "103013376", // Watermark Bank,
    "054001767", // Founders Bank,
    "122245235", // Endeavor Bank",
    "026015053", // Piermont Bank
    "044015941", // Ohio State Bank
    "062006806" // Commerceone Bank
];
function IsRoutingNumber(validationOptions) {
    return function (object, propertyName) {
        (0, class_validator_1.registerDecorator)({
            name: "isRoutingNumber",
            target: object.constructor,
            propertyName,
            constraints: [],
            options: validationOptions,
            validator: {
                validate(value) {
                    if (!value) {
                        return false;
                    }
                    const isValid = valid.routingNumber(value);
                    return isValid.isValid || isValid.isPotentiallyValid || additionalRoutingNumbers.indexOf(value) > -1;
                }
            }
        });
    };
}
