import React from "react";
import { View } from "DLUI/view";
import type { FieldArrayRenderProps } from "formik";
import { FieldArray, getIn, useFormikContext } from "formik";
import { v4 as uuid } from "uuid";
import type { ManagementFeesCompanySettingsClientDto } from "@doorloop/dto";
import { DataCy } from "@doorloop/dto";
import { type DeepKeys, PayOutManagementAccounts } from "@doorloop/dto";
import PayoutManagementAccountsListItem from "screens/settings/managementFees/formElements/payoutManagementAccountsListItem";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import { AddIcon } from "@/assets";
import { useManagementFeesStyles } from "screens/settings/managementFees/useStyles";
import clsx from "clsx";
import { useGeneralStyles } from "styles/generalStyles";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { useTypedTranslation } from "locale";
import { useStyles } from "DLUI/dialogs/settings/common/settings/styles";

interface Props {
  propertyId?: string;
}

const PayoutManagementAccounts: React.FC<Props> = ({ propertyId }: Props) => {
  const formikRef = useFormikContext();
  const classes = useManagementFeesStyles();
  const generalClasses = useGeneralStyles();
  const settingsClasses = useStyles();
  const { isMobile } = useResponsiveHelper();
  const { t } = useTypedTranslation();

  const managementAccountsFieldName: DeepKeys<ManagementFeesCompanySettingsClientDto> = "payOutAccounts";

  const handleAddLineClick = (arrayHelpers: FieldArrayRenderProps) => {
    arrayHelpers.push(
      new PayOutManagementAccounts({
        uniqueKey: uuid()
      })
    );
  };

  const lines = getIn(formikRef.values, managementAccountsFieldName) || [];
  const hasLines = lines.length > 0;

  return (
    <View marginTop={16}>
      <FieldArray
        name={managementAccountsFieldName}
        render={(arrayHelpers) => (
          <div className={clsx(generalClasses.fullWidth)}>
            <div className={clsx(classes.columnContainer, generalClasses.fullWidth)}>
              {lines.map(({ uniqueKey }, index) => (
                <PayoutManagementAccountsListItem
                  itemIndex={index}
                  arrayHelpers={arrayHelpers}
                  key={uniqueKey}
                  fieldName={`${managementAccountsFieldName}[${index}]`}
                  property={propertyId}
                />
              ))}
            </div>
            <DLButton
              onClick={() => handleAddLineClick(arrayHelpers)}
              actionText={t("settings.generalSettings.managementFees.addAccount")}
              variant={hasLines ? DLButtonVariantsEnum.TEXT : DLButtonVariantsEnum.CONTAINED}
              color={DLButtonColorsEnum.SECONDARY}
              size={isMobile ? DLButtonSizesEnum.LARGE : DLButtonSizesEnum.SMALL}
              disableMobileCollapse
              icons={{ start: { src: AddIcon } }}
              style={{ gap: 2 }}
              dataCy={DataCy.globalSettings.managementFees.payoutAccounts.newPayoutAccountButton}
              containerClassName={hasLines ? settingsClasses.addAnotherElement : settingsClasses.addElement}
            />
          </div>
        )}
      />
    </View>
  );
};

export default PayoutManagementAccounts;
