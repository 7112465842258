import AppStrings from "locale/keys";
import _ from "lodash";

import { DataCy, PropertyType } from "@doorloop/dto";
import { CondoIcon, MultiFamilyIcon, RentalsIcon, SingleFamilyIcon, TownHomeIcon } from "assets/icons";
import { PropertyTypeKey } from "./propertyType.types";

import type { PropertyTypeItemType } from "./propertyTypes";

const residentialOtherType: PropertyTypeItemType = {
  label: AppStrings.Properties.Subtype.Other,
  icon: RentalsIcon,
  value: PropertyType.RESIDENTIAL_OTHER,
  dataCy: DataCy.newProperty.propertySelectionButtonGroup.subTypes.residential.other,
  onPress: _.noop,
  isSelected: false,
  key: PropertyTypeKey.Residential,
  descriptionBullets: [`properties.propertyTypeDescription.${PropertyType.RESIDENTIAL_OTHER}.one`]
};

export const residentialTypes: PropertyTypeItemType[] = [
  {
    label: AppStrings.Properties.Subtype.SingleFamily,
    icon: SingleFamilyIcon,
    value: PropertyType.RESIDENTIAL_SINGLE_FAMILY,
    dataCy: DataCy.newProperty.propertySelectionButtonGroup.subTypes.residential.singleFamily,
    onPress: _.noop,
    isSelected: false,
    key: PropertyTypeKey.Residential,
    descriptionBullets: [
      `properties.propertyTypeDescription.${PropertyType.RESIDENTIAL_SINGLE_FAMILY}.one`,
      `properties.propertyTypeDescription.${PropertyType.RESIDENTIAL_SINGLE_FAMILY}.two`
    ]
  },
  {
    label: AppStrings.Properties.Subtype.MultiFamily,
    icon: MultiFamilyIcon,
    value: PropertyType.RESIDENTIAL_MULTI_FAMILY,
    dataCy: DataCy.newProperty.propertySelectionButtonGroup.subTypes.residential.multiFamily,
    onPress: _.noop,
    isSelected: false,
    key: PropertyTypeKey.Residential,
    descriptionBullets: [
      `properties.propertyTypeDescription.${PropertyType.RESIDENTIAL_MULTI_FAMILY}.one`,
      `properties.propertyTypeDescription.${PropertyType.RESIDENTIAL_MULTI_FAMILY}.two`
    ]
  },
  {
    label: AppStrings.Properties.Subtype.Condo,
    icon: CondoIcon,
    value: PropertyType.RESIDENTIAL_CONDO,
    dataCy: DataCy.newProperty.propertySelectionButtonGroup.subTypes.residential.condo,
    onPress: _.noop,
    isSelected: false,
    key: PropertyTypeKey.Residential,
    descriptionBullets: [
      `properties.propertyTypeDescription.${PropertyType.RESIDENTIAL_CONDO}.one`,
      `properties.propertyTypeDescription.${PropertyType.RESIDENTIAL_CONDO}.two`
    ]
  },
  {
    label: AppStrings.Properties.Subtype.Townhome,
    icon: TownHomeIcon,
    value: PropertyType.RESIDENTIAL_TOWNHOME,
    dataCy: DataCy.newProperty.propertySelectionButtonGroup.subTypes.residential.townhome,
    onPress: _.noop,
    isSelected: false,
    key: PropertyTypeKey.Residential,
    descriptionBullets: [
      `properties.propertyTypeDescription.${PropertyType.RESIDENTIAL_TOWNHOME}.one`,
      `properties.propertyTypeDescription.${PropertyType.RESIDENTIAL_TOWNHOME}.two`
    ]
  },
  residentialOtherType
];
