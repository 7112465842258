const Common = {
  LoginResponseType: {
    HoaAccount: "common.loginResponseType.hoaAccount",
    RentalsAccount: "common.loginResponseType.rentalsAccount",
    TenantPortal: "common.loginResponseType.tenantPortal",
    OwnerPortal: "common.loginResponseType.ownerPortal"
  },
  To: "common.to",
  For: "common.for",
  RunningFrom: "common.runningFrom",
  OpenInApp: "common.openInApp",
  New: "common.new",
  ExpiredRentalApplication: "common.expiredRentalApplication",
  TheApplicationForThisApplicationHasExpired: "common.theApplicationForThisApplicationHasExpired",
  RequestNewApplication: "common.requestNewApplication",
  Used: "common.used",
  Spent: "common.spent",
  Remaining: "common.remaining",
  SendPortalInvitation: "common.sendPortalInvitation",
  NoApplicationIdError: "common.noApplicationIdError",
  RequestPaymentFromApplicant: "common.requestPaymentFromApplicant",
  IWillCoverScreeningMyself: "common.iWillCoverScreeningMyself",
  HaveTheApplicantCoverTheirScreeningCosts: "common.haveTheApplicantCoverTheirScreeningCosts",
  PayForTheSelectedReportsYourself: "common.payForTheSelectedReportsYourself",
  DoorloopsScreeningFee: "common.doorloopsScreeningFee",
  SecureYourRental: "common.secureYourRental",
  AfterPaymentWellConduct: "common.afterPaymentWellConduct",
  LetsMakeYourDreamAReality: "common.letsMakeYourDreamAReality",
  BackgroundCheckPaymentPage: "common.backgroundCheckPaymentPage",
  ScreeningReportsForApplication: "common.screeningReportsForApplication",
  SubmittedAt: "common.submittedAt",
  WaitingForTheApplicantToPay: "common.waitingForTheApplicantToPay",
  AwaitingReports: "common.awaitingReports",
  RemindersWillGoOutAutomatically: "common.remindersWillGoOutAutomatically",
  PaymentSuccessful: "common.paymentSuccessful",
  ConfirmationNumberObject: "common.confirmationNumberObject",
  YouCanNowCloseTheWindow: "common.youCanNowCloseTheWindow",
  WeWillNotifyYouWhenTheApplicantHasPaid: "common.weWillNotifyYouWhenTheApplicantHasPaid",
  SendReminderToTheApplicant: "common.sendReminderToTheApplicant",
  CancelPaymentRequest: "common.cancelPaymentRequest",
  CancelApplicantPayRequest: "common.cancelApplicantPayRequest",
  ThePaymentLinkWillNotBeValid: "common.thePaymentLinkWillNotBeValid",
  SendPaymentRequest: "common.sendPaymentRequest",
  SendPaymentRequestLowerCase: "common.sendPaymentRequestLowerCase",
  WeWillSendAnEmailToTheApplicant: "common.weWillSendAnEmailToTheApplicant",
  GlobalSearchPlaceholder: "common.globalSearchPlaceholder",
  Months: {
    Full: {
      January: "common.months.full.january",
      February: "common.months.full.february",
      March: "common.months.full.march",
      April: "common.months.full.april",
      May: "common.months.full.may",
      June: "common.months.full.june",
      July: "common.months.full.july",
      August: "common.months.full.august",
      September: "common.months.full.september",
      October: "common.months.full.october",
      November: "common.months.full.november",
      December: "common.months.full.december"
    },
    Partial: {
      January: "common.months.partial.january",
      February: "common.months.partial.february",
      March: "common.months.partial.march",
      April: "common.months.partial.april",
      May: "common.months.partial.may",
      June: "common.months.partial.june",
      July: "common.months.partial.july",
      August: "common.months.partial.august",
      September: "common.months.partial.september",
      October: "common.months.partial.october",
      November: "common.months.partial.november",
      December: "common.months.partial.december"
    }
  },
  CreatedByOn: "common.createdByOn",
  UpdatedByOn: "common.updatedByOn",
  System: "common.system",
  Reset: "common.reset",
  Profile: "common.profile",
  EveryApplicantWillBeCharged: "common.everyApplicantWillBeCharged",
  CouldNotLoadDataError: "common.couldNotLoadDataError",
  SaveAndClose: "common.saveAndClose",
  Duplicate: "common.duplicate",
  Duplicated: "common.duplicated",
  LoadingRentalApplicationGroupFailed: "common.loadingRentalApplicationGroupFailed",
  AddressAutoCompleteError: "common.addressAutoCompleteError",
  SearchingAddress: "common.searchingAddress",
  NoCountriesFound: "common.noCountriesFound",
  NoStatesFound: "common.noStatesFound",
  IdentityNumber: "common.identityNumber",
  IdentityNumberUS: "common.identityNumberUS",
  IdentityNumberCA: "common.identityNumberCA",
  Payee: "common.payee",
  Filter: "common.filter",
  DrawerCollapse: "common.drawerCollapse",
  DrawerExpand: "common.drawerExpand",
  SaveYourRentalApplicationBeforeSaving: "common.saveYourRentalApplicationBeforeSaving",
  SavingItAllowsYouToResume: "common.savingItAllowsYouToResume",
  BulkUpload: "common.bulkUpload",
  Balance: "common.balance",
  TenantLoginToPortal: "common.tenantLoginToPortal",
  TenantSetupPaymentMethod: "common.tenantSetupPaymentMethod",
  Action: "common.action",
  TenantPortalStatus: "common.tenantPortalStatus",
  Current: "common.current",
  Past: "common.past",
  Future: "common.future",
  AddContactInfo: "common.addContactInfo",
  PaymentReminderSent: "common.paymentReminderSent",
  PortalInvitationSent: "common.portalInvitationSent",
  AllSetForNow: "common.allSetForNow",
  CreateNewLease: "common.createNewLease",
  SendRentReminder: "common.sendRentReminder",
  SendTenantPortalInvitation: "common.sendTenantPortalInvitation",
  AvailableOnlyOnDesktop: "common.availableOnlyOnDesktop",
  ScreeningStatus: "common.screeningStatus",
  ConfirmAndPay: "common.confirmAndPay",
  NoFeeBrackets: "common.noFeeBrackets",
  FeeAmount: "common.feeAmount",
  NotAvailableAbbreviated: "common.notAvailableAbbreviated",
  Widget: "common.widget",
  TenantPageWidgets: {
    Titles: {
      BalanceDue: "common.tenantPageWidgets.titles.balanceDue",
      MissingContactInfo: "common.tenantPageWidgets.titles.missingContactInfo",
      NotSignedToThePortal: "common.tenantPageWidgets.titles.notSignedToThePortal"
    },
    Tooltips: {
      BalanceDue: "common.tenantPageWidgets.tooltips.balanceDue",
      MissingContactInfo: "common.tenantPageWidgets.tooltips.missingContactInfo",
      NotSignedToThePortal: "common.tenantPageWidgets.tooltips.notSignedToThePortal"
    },
    Tenants: "common.tenantPageWidgets.tenants",
    SeeTenants: "common.tenantPageWidgets.seeTenants"
  },
  Unknown: "common.unknown",
  ByUsingRentalApp: "common.byUsingRentalApp",
  ScreeningReportsAutomaticRequest: "common.screeningReportsAutomaticRequest",
  DeductScreeningCostText: "common.deductScreeningCostText",
  Open: "common.open",
  DownloadFailed: "common.downloadFailed",
  DownloadSucceeded: "common.downloadSucceeded",
  CashPayments: {
    Title: "common.cashPayments.title",
    Warnings: {
      AmountLimit: "common.cashPayments.warnings.amountLimit",
      AmountLimitWithPartialPayments: "common.cashPayments.warnings.amountLimitWithPartialPayments"
    },
    OverviewList: {
      FormerTenantTooltip: "common.cashPayments.overviewList.formerTenantTooltip",
      FormerTenant: "common.cashPayments.overviewList.formerTenant",
      CopyDetails: "common.cashPayments.overviewList.copyDetails",
      DownloadPdf: "common.cashPayments.overviewList.downloadPdf",
      Amount: "common.cashPayments.overviewList.amount",
      BillerName: "common.cashPayments.overviewList.billerName",
      AccountNumber: "common.cashPayments.overviewList.accountNumber"
    },
    TenantPortal: {
      DialogTitle: "common.cashPayments.tenantPortal.dialogTitle",
      DialogSubtitle: "common.cashPayments.tenantPortal.dialogSubtitle",
      YourPaymentInformation: "common.cashPayments.tenantPortal.yourPaymentInformation",
      LookForQuickCollectServiceLocations: "common.cashPayments.tenantPortal.lookForQuickCollectServiceLocations",
      UtilizeTheQuickCollect: "common.cashPayments.tenantPortal.utilizeTheQuickCollect",
      FindTheNearestLocation: "common.cashPayments.tenantPortal.findTheNearestLocation",
      PartialPaymentAvailable: "common.cashPayments.tenantPortal.partialPaymentAvailable",
      PartialPaymentUnavailable: "common.cashPayments.tenantPortal.partialPaymentUnavailable",
      ServiceFee: "common.cashPayments.tenantPortal.serviceFee",
      Instructions: {
        Title: "common.cashPayments.tenantPortal.instructions.title",
        One: "common.cashPayments.tenantPortal.instructions.one",
        Two: "common.cashPayments.tenantPortal.instructions.two",
        Three: "common.cashPayments.tenantPortal.instructions.three",
        Four: "common.cashPayments.tenantPortal.instructions.four"
      },
      PaymentDetails: {
        Amount: "common.cashPayments.tenantPortal.paymentDetails.amount",
        BillerName: "common.cashPayments.tenantPortal.paymentDetails.billerName",
        AccountNumber: "common.cashPayments.tenantPortal.paymentDetails.accountNumber"
      }
    }
  },
  PropertyAddressNotValid: "common.propertyAddressNotValid",
  RentalApplicationListingTitle: "common.rentalApplicationListingTitle",
  RentalApplicationListingDescription: "common.rentalApplicationListingDescription",
  RentalApplicationListingHandlerText: "common.rentalApplicationListingHandlerText",
  MissingQueryParamsErrorText: "common.missingQueryParamsErrorText",
  RentalApplicationCouldHandleOpenForApplicationError: "common.rentalApplicationCouldHandleOpenForApplicationError",
  Closed: "common.closed",
  RentalApplicationMandatorySettingsSection: "common.rentalApplicationMandatorySettingsSection",
  OpenUnitForRentalApplication: "common.openUnitForRentalApplication",
  ReceivingApplications: "common.receivingApplications",
  CloseUnitForRentalApplication: "common.closeUnitForRentalApplication",
  OpenForApplication: "common.openForApplication",
  CloseForApplication: "common.closeForApplication",
  ApplicationWasAlready: "common.applicationWasAlready",
  Opened: "common.opened",
  RentalApplicationMissingMerchantAccountError: "common.rentalApplicationMissingMerchantAccountError",
  InternalNotes: "common.internalNotes",
  ImportantLegalNote: "common.importantLegalNote",
  AddInternalNotesHere: "common.addInternalNotesHere",
  Reasons: "common.reasons",
  ProvideAReason: "common.provideAReason",
  PleaseAttachAnyFilesSupportingYourDecision: "common.pleaseAttachAnyFilesSupportingYourDecision",
  SendAutomatedEmail: "common.sendAutomatedEmail",
  LastDecisionEmailSend: "common.lastDecisionEmailSend",
  Approved: "common.approved",
  Rejected: "common.rejected",
  NoSetupNeeded: "common.noSetupNeeded",
  ApplicantsWillPay: "common.applicantsWillPay",
  ChooseWhenToRequest: "common.chooseWhenToRequest",
  MerchantAccountNeeded: "common.merchantAccountNeeded",
  SetYourPreferredFee: "common.setYourPreferredFee",
  GetTheScreeningReportsInstantly: "common.getTheScreeningReportsInstantly",
  FreeForApplicants: "common.freeForApplicants",
  ScreeningUponPostFeePayment: "common.screeningUponPostFeePayment",
  SetACustomFeeToGenerate: "common.setACustomFeeToGenerate",
  UseTheFeeAsAFilter: "common.useTheFeeAsAFilter",
  SelectTenantsBasedOnHowSuitableTheyAreForYourProperty: "common.selectTenantsBasedOnHowSuitableTheyAreForYourProperty",
  FeeSettings: "common.feeSettings",
  RentalApplicationEnums: {
    PaymentOptions: {
      ApplicantBoldStart: "common.rentalApplicationEnums.paymentOptions.applicantBoldStart",
      ApplicantEnd: "common.rentalApplicationEnums.paymentOptions.applicantEnd",
      UserBoldStart: "common.rentalApplicationEnums.paymentOptions.userBoldStart",
      UserEnd: "common.rentalApplicationEnums.paymentOptions.userEnd",
      CustomBoldStart: "common.rentalApplicationEnums.paymentOptions.customBoldStart",
      CustomEnd: "common.rentalApplicationEnums.paymentOptions.customEnd",
      NoFeeBoldStart: "common.rentalApplicationEnums.paymentOptions.noFee",
      NoFeeEnd: "common.rentalApplicationEnums.paymentOptions.noFeeEnd",
      CustomYourFeeBoldStart: "common.rentalApplicationEnums.paymentOptions.customYourFeeBoldStart",
      CustomYourFeeEnd: "common.rentalApplicationEnums.paymentOptions.customYourFeeEnd",
      ApplicationOnlyPlanStart: "common.rentalApplicationEnums.paymentOptions.applicationOnlyPlanStart"
    }
  },
  FillRequiredFields: "common.fillRequiredFields",
  RentalApplicationSettingsEnabledFieldTextRequired: "common.rentalApplicationSettingsEnabledFieldTextRequired",
  RentalApplicationSettingsSectionFieldsRequired: "common.rentalApplicationSettingsSectionFieldsRequired",
  RentalApplicationCustomFeeMerchantAccountNote: "common.rentalApplicationCustomFeeMerchantAccountNote",
  RentalApplicationPropertyLevelCustomizeTitle: "common.rentalApplicationPropertyLevelCustomizeTitle",
  RentalApplicationManageApplication: "common.rentalApplicationManageApplication",
  RentalApplicationManageApplicationDescription: "common.rentalApplicationManageApplicationDescription",
  RentalApplicationSent: "common.rentalApplicationSent",
  PostChargeReminderSend: "common.postChargeReminderSend",
  CreditCardFee: "common.creditCardFee",
  TotalChargeForApplicant: "common.totalChargeForApplicant",
  TotalChargeForApplicantCustomFee: "common.totalChargeForApplicantCustomFee",
  RentalApplicationCustomChargeDescription: "common.rentalApplicationCustomChargeDescription",
  TransunionNoteBasedOnPlan: "common.transunionNoteBasedOnPlan",
  VerifyLocalFeeRegulations: "common.verifyLocalFeeRegulations",
  ApplicationFeeIsNotApplicable: "common.applicationFeeIsNotApplicable",
  ApplicationFeeApplyMerchantAccount: "common.applicationFeeApplyMerchantAccount",
  PropertyAccountBankSettings: "common.propertyAccountBankSettings",
  TransunionReportDescription: "common.transunionReportDescription",
  ManageFee: "common.manageFee",
  DownloadPrintable: "common.downloadPrintable",
  SendReportViaEmail: "common.sendReportViaEmail",
  DownloadReport: "common.downloadReport",
  Mandatory: "common.mandatory",
  NoDataFound: "common.noDataFound",
  VerifyCompanyAddress: "common.verifyCompanyAddress",
  MissingCompanyAddressDescription: "common.missingCompanyAddressDescription",
  VerifyCompanyAddressButton: "common.verifyCompanyAddressButton",
  VerifyPropertyAddress: "common.verifyPropertyAddress",
  MissingPropertyAddressDescription: "common.missingPropertyAddressDescription",
  VerifyPropertyAddressButton: "common.verifyPropertyAddressButton",
  RecordAsLeasePayment: "common.recordAsLeasePayment",
  ReportsWillBeRequested: "common.reportsWillBeRequested",
  ReportsWillNotBeRequested: "common.reportsWillNotBeRequested",
  ApplicationResent: "common.applicationResent",
  BankTransferAch: "common.bankTransferAch",
  PaginationStatus: "common.paginationStatus",
  PaymentMethodLearMoreContent: "common.paymentMethodLearMoreContent",
  Lessee: "common.lessee",
  Applicant: "common.applicant",
  InvalidPhoneNumber: "common.invalidPhoneNumber",
  SelectAll: "common.selectAll",
  InvalidEmail: "common.invalidEmail",
  InviteToPortal: "common.inviteToPortal",
  MissingInfo: "common.missingInfo",
  TenantPortalMissingInfoTooltip: "common.tenantPortalMissingInfoTooltip",
  OwnerPortalMissingInfoTooltip: "common.ownerPortalMissingInfoTooltip",
  AddEmail: "common.addEmail",
  AddPhone: "common.addPhone",
  SearchProperties: "common.searchProperties",
  Properties: "common.properties",
  CreatedBy: "common.createdBy",
  CreatedAt: "common.createdAt",
  UpdatedBy: "common.updatedBy",
  UpdatedAt: "common.updatedAt",
  Account: "common.account",
  RestrictedOnMobile: "common.restrictedOnMobile",
  RestrictedOnMobileSubscription: "common.restrictedOnMobileSubscription",
  RestrictedOnMobileHeader: "common.restrictedOnMobileHeader",
  RestrictedOnMobileSubscriptionHeader: "common.restrictedOnMobileSubscriptionHeader",
  ScheduleADemo: "common.scheduleADemo",
  ChooseAPlan: "common.chooseAPlan",
  Allocate: "common.allocate",
  Unit: "common.unit",
  Help: "common.help",
  Units: "common.units",
  OneUnit: "common.oneUnit",
  XUnits: "common.xUnits",
  ZeroBalance: "common.zeroBalance",
  Assignee: "common.assignee",
  YourTrialExpiresIn: "common.yourTrialExpiresIn",
  YourTrialExpired: "common.yourTrialExpired",
  Days: "common.days",
  Day: "common.day",
  Note: "common.note",
  Description: "common.description",
  Title: "common.title",
  CreatedOrEditedBy: "common.createdOrEditedBy",
  UploadedOrEditedBy: "common.uploadedOrEditedBy",
  DocumentTitle: "common.documentTitle",
  PlatformName: "common.platformName",
  Copyright: "common.copyright",
  CompanyName: "common.companyName",
  Dismiss: "common.dismiss",
  Discard: "common.discard",
  KeepEditing: "common.keepEditing",
  Retry: "common.retry",
  XResultFound: "common.xResultFound",
  XResultsFound: "common.xResultsFound",
  NoMatchesFound: "common.noMatchesFound",
  NoResultsFoundDescription: "common.noResultsFoundDescription",
  NoResultsFoundSubText: "common.noResultsFoundSubText",
  NoResultsFoundMainText: "common.noResultsFoundMainText",
  NoResultsFound: "common.noResultsFound",
  NoResultsYet: "common.noResultsYet",
  InputNewSearchAndTryAgain: "common.inputNewSearchAndTryAgain",
  BusinessTitle: "common.businessTitle",
  Save: "common.save",
  ExportStatement: "common.exportStatement",
  GenerateInvoice: "common.generateInvoice",
  SendReceipt: "common.sendReceipt",
  SaveReceipt: "common.saveReceipt",
  MakeActive: "common.makeActive",
  Cancel: "common.cancel",
  OpenNewTab: "common.openNewTab",
  AddNew: "common.addNew",
  Send: "common.send",
  SendNow: "common.sendNow",
  Edit: "common.edit",
  Resume: "common.resume",
  View: "common.view",
  ViewRequest: "common.viewRequest",
  TenantPortalSettingsSections: {
    AllowTenantToAccessTheTenantPortal: "common.tenantPortalSettingsSections.allowTenantToAccessTheTenantPortal",
    Next: "common.tenantPortalSettingsSections.next",
    Continue: "common.tenantPortalSettingsSections.continue",
    SaveAndClose: "common.tenantPortalSettingsSections.saveAndClose",
    TenantPortalSettings: "common.tenantPortalSettingsSections.tenantPortalSettings",
    Permissions: "common.tenantPortalSettingsSections.permissions",
    ContactInfo: "common.tenantPortalSettingsSections.contactInfo",
    PortalInvites: "common.tenantPortalSettingsSections.portalInvites",
    Instructions: "common.tenantPortalSettingsSections.instructions",
    TenantCanPayWithCreditCard: "common.tenantPortalSettingsSections.tenantCanPayWithCreditCard",
    AddInstructions: "common.tenantPortalSettingsSections.addInstructions"
  },
  DeleteRequest: "common.deleteRequest",
  Delete: "common.delete",
  Reverse: "common.reverse",
  Close: "common.close",
  Back: "common.back",
  Submit: "common.submit",
  Next: "common.next",
  SupportedFileTypesSuffix: "common.supportedFileTypesSuffix",
  NoFilesFound: "common.noFilesFound",
  ThisFolderIsEmpty: "common.thisFolderIsEmpty",
  DeleteConfirmation: "common.deleteConfirmation",
  CancelConfirmation: "common.cancelConfirmation",
  UpdateConfirmation: "common.updateConfirmation",
  VoidConfirmation: "common.voidConfirmation",
  InactiveConfirmation: "common.inactiveConfirmation",
  ActiveConfirmation: "common.activeConfirmation",
  Activate: "common.activate",
  ExpandOptionsLinkText: "common.expandOptionsLinkText",
  NoMoreResults: "common.noMoreResults",
  TextNotificationsWillUseCommunicationCredit: "common.textNotificationsWillUseCommunicationCredit",
  NetworkErrorSubTitle: "common.networkErrorSubTitle",
  GeneralError: "common.generalError",
  CredentialsError: "common.credentialsError",
  NotSupportedFileError: "common.notSupportedFileError",
  MultipleNotSupportedFileError: "common.multipleNotSupportedFileError",
  FileAlreadyExistError: "common.fileAlreadyExistError",
  MultipleNotSupportedTypesError: "common.multipleNotSupportedTypesError",
  NetworkErrorTitle: "common.networkErrorTitle",
  OperationCompleted: "common.operationCompleted",
  OperationInprogress: "common.operationInprogress",
  OperationFailed: "common.operationFailed",
  OperationSucceeded: "common.operationSucceeded",
  Loading: "common.loading",
  UploadLoadingText: "common.uploadLoadingText",
  Enabled: "common.enabled",
  Disabled: "common.disabled",
  Required: "common.required",
  IfRented: "common.ifRented",
  Field: "common.field",
  NotRequired: "common.notRequired",
  CreateNew: "common.createNew",
  GoBack: "common.goBack",
  SplitValues: "common.splitValues",
  Yes: "common.yes",
  No: "common.no",
  None: "common.none",
  Custom: "common.custom",
  ViewAllOptions: "common.viewAllOptions",
  ClickHere: "common.clickHere",
  LearnMore: "common.learnMore",
  GetShareableLink: "common.getShareableLink",
  ToLearnMore: "common.toLearnMore",
  ComingSoon: "common.comingSoon",
  Period: "common.period",
  Search: "common.search",
  LogOut: "common.logOut",
  NotSet: "common.notSet",
  PersonalSettings: "common.personalSettings",
  EditSubscription: "common.editSubscription",
  ChangeYourPassword: "common.changeYourPassword",
  GettingStarted: "common.gettingStarted",
  ScheduleDemo: "common.scheduleDemo",
  Show: "common.show",
  Hide: "common.hide",
  ScheduleDemoAdditionalinfoDocumentTitle: "common.scheduleDemoAdditionalinfoDocumentTitle",
  AskUsAnything: "common.askUsAnything",
  DoorloopUniversity: "common.doorloopUniversity",
  KnowledgeBase: "common.knowledgeBase",
  FormsAndTemplates: "common.formsAndTemplates",
  PrintPreparingMessage: "common.printPreparingMessage",
  xItemNameSelected: "common.xItemNameSelected",
  PrintChecks: {
    Alert: {
      Title: "common.printChecks.alert.title",
      SubTitle: "common.printChecks.alert.subTitle"
    },
    HelpPanel: {
      LearnMorePrintChecks: "common.printChecks.helpPanel.learnMorePrintChecks",
      ReprintChecks: "common.printChecks.helpPanel.reprintChecks",
      Description: "common.printChecks.helpPanel.description",
      DialogTitle: "common.printChecks.helpPanel.dialogTitle"
    },
    SelectedTotal: "common.printChecks.selectedTotal",
    Preview: {
      Header: "common.printChecks.preview.header",
      Mode: "common.printChecks.preview.mode",
      Dialog: {
        Action: "common.printChecks.preview.dialog.action",
        Reminder: {
          Header: "common.printChecks.preview.dialog.reminder.header",
          Body: "common.printChecks.preview.dialog.reminder.body"
        },
        Question: "common.printChecks.preview.dialog.question",
        Header: "common.printChecks.preview.dialog.header",
        Done: "common.printChecks.preview.dialog.done",
        NotDone: "common.printChecks.preview.dialog.notDone",
        CheckNumber: "common.printChecks.preview.dialog.checkNumber",
        Error: "common.printChecks.preview.dialog.error",
        FirstCheckValidation: "common.printChecks.preview.dialog.firstCheckValidation"
      }
    },
    Setup: {
      MenuItems: {
        Step1: "common.printChecks.setup.menuItems.step1",
        Step2: "common.printChecks.setup.menuItems.step2"
      },
      Action: "common.printChecks.setup.action",
      Cancel: "common.printChecks.setup.cancel",
      Sample: {
        LoadPaper: {
          Header: "common.printChecks.setup.sample.loadPaper.header",
          SubHeader: "common.printChecks.setup.sample.loadPaper.subHeader"
        },
        PrintSample: {
          Header: "common.printChecks.setup.sample.printSample.header",
          SubHeader: "common.printChecks.setup.sample.printSample.subHeader"
        },
        PrintAlign: {
          Header: "common.printChecks.setup.sample.printAlign.header",
          SubHeader: "common.printChecks.setup.sample.printAlign.subHeader"
        },
        Header: "common.printChecks.setup.sample.header",
        SubHeader: "common.printChecks.setup.sample.subHeader",
        Voucher: "common.printChecks.setup.sample.voucher",
        Standard: "common.printChecks.setup.sample.standard",
        Description: "common.printChecks.setup.sample.description"
      },
      Reader: {
        Description: {
          Part1: "common.printChecks.setup.reader.description.part1",
          Part2: "common.printChecks.setup.reader.description.part2",
          Part3: "common.printChecks.setup.reader.description.part3"
        },
        Download: {
          Header: "common.printChecks.setup.reader.download.header",
          SubHeader: "common.printChecks.setup.reader.download.subHeader"
        },
        Default: {
          Header: "common.printChecks.setup.reader.default.header",
          SubHeader: "common.printChecks.setup.reader.default.subHeader",
          NeedHelp: "common.printChecks.setup.reader.default.needHelp"
        }
      },
      Adjust: {
        Description: {
          Part1: "common.printChecks.setup.adjust.description.part1",
          Bullet1: "common.printChecks.setup.adjust.description.bullet1",
          Bullet2: "common.printChecks.setup.adjust.description.bullet2",
          Bullet3: "common.printChecks.setup.adjust.description.bullet3",
          Part2: "common.printChecks.setup.adjust.description.part2"
        },
        Settings: {
          Header: "common.printChecks.setup.adjust.settings.header",
          Text: "common.printChecks.setup.adjust.settings.text",
          GridPreset: "common.printChecks.setup.adjust.settings.gridPreset",
          Horizontal: "common.printChecks.setup.adjust.settings.horizontal",
          Vertical: "common.printChecks.setup.adjust.settings.vertical",
          CheckInnerVertical: "common.printChecks.setup.adjust.settings.checkInnerVertical",
          PrintTest: "common.printChecks.setup.adjust.settings.printTest"
        }
      }
    },
    ActionPanel: {
      Save: "common.printChecks.actionPanel.save",
      Order: "common.printChecks.actionPanel.order",
      Setup: "common.printChecks.actionPanel.setup",
      Action: "common.printChecks.actionPanel.action",
      Cancel: "common.printChecks.actionPanel.cancel",
      Print: "common.printChecks.actionPanel.print"
    },
    EmptyScreen: {
      Title: "common.printChecks.emptyscreen.title",
      Text: "common.printChecks.emptyscreen.text",
      Button: "common.printChecks.emptyscreen.button"
    },
    NoSelection: {
      Title: "common.printChecks.noSelection.title",
      Text: "common.printChecks.noSelection.text"
    },
    Balance: "common.printChecks.balance",
    ChecksSelected: "common.printChecks.checksSelected",
    LexicalAmount: "common.printChecks.lexicalAmount",
    RemoveFromQueue: "common.printChecks.removeFromQueue",
    LearnMore: "common.printChecks.learnMore"
  },
  Subscription: {
    OnboardingDiscountDescription: "common.subscription.onboardingDiscountDescription",
    SaveUpTo: "common.subscription.saveUpTo",
    UpdateCreditCard: "common.subscription.updateCreditCard",
    UpdateCard: "common.subscription.updateCard",
    Settings: {
      Text: "common.subscription.settings.text",
      Header: "common.subscription.settings.header"
    },
    UnitsError: {
      Text1: "common.subscription.unitsError.text1",
      Text2: "common.subscription.unitsError.text2"
    },
    ErrorMessage: "common.subscription.errorMessage",
    LoadingMessage: "common.subscription.loadingMessage",
    Title: "common.subscription.title",
    SubTitle: "common.subscription.subTitle",
    FreeUntil: "common.subscription.freeUntil",
    HeaderDescriptionText: "common.subscription.headerDescriptionText",
    UnitCountHeader: "common.subscription.unitCountHeader",
    UnitCountSubHeader: "common.subscription.unitCountSubHeader",
    StartFreeHeader: "common.subscription.startFreeHeader",
    StartFreeSubHeader: "common.subscription.startFreeSubHeader",
    GeneralInfo: "common.subscription.generalInfo",
    StartPaying: "common.subscription.startPaying",
    PayPerMonth: "common.subscription.payPerMonth",
    PayPerYear: "common.subscription.payPerYear",
    FaqHeader: "common.subscription.faqHeader",
    CurrentPlan: "common.subscription.currentPlan",
    ComparePlans: "common.subscription.comparePlans",
    MaxUnitsMessage: "common.subscription.maxUnitsMessage",
    Plans: {
      Basic: "common.subscription.plans.basic",
      Standard: "common.subscription.plans.standard",
      Pro: "common.subscription.plans.pro"
    },
    View: {
      SubTitle: "common.subscription.view.subTitle",
      Payment: {
        Error: {
          GeneralError: "common.subscription.view.payment.error.generalError",
          Header: "common.subscription.view.payment.error.header",
          Body: "common.subscription.view.payment.error.body"
        },
        UpdateCard: "common.subscription.view.payment.updateCard",
        Expiry: "common.subscription.view.payment.expiry",
        Header: "common.subscription.view.payment.header",
        Last: "common.subscription.view.payment.last",
        Next: "common.subscription.view.payment.next",
        On: "common.subscription.view.payment.on"
      },
      Details: {
        Plan: "common.subscription.view.details.plan",
        PlanPrice: "common.subscription.view.details.planPrice",
        PlanUnits: "common.subscription.view.details.planUnits",
        ActiveUnits: "common.subscription.view.details.activeUnits",
        UpdatePlan: "common.subscription.view.details.updatePlan"
      }
    },
    Checkout: {
      Errors: {
        Success: "common.subscription.checkout.errors.success",
        Loading: "common.subscription.checkout.errors.loading",
        Failed: "common.subscription.checkout.errors.failed"
      },
      Footer: {
        AcceptPayment: "common.subscription.checkout.footer.acceptpayment",
        MoneyBack: "common.subscription.checkout.footer.moneyback",
        MoneyBackInfo: "common.subscription.checkout.footer.moneybackinfo",
        Secure: "common.subscription.checkout.footer.secure",
        SecureInfo: "common.subscription.checkout.footer.secureinfo"
      },
      Summary: {
        Header: "common.subscription.checkout.summary.header",
        PerMonth: "common.subscription.checkout.summary.permonth",
        PerYear: "common.subscription.checkout.summary.peryear",
        Onboarding: "common.subscription.checkout.summary.onboarding",
        DueOnMonthly: "common.subscription.checkout.summary.dueOnMonthly",
        DueOnAnually: "common.subscription.checkout.summary.dueOnAnually",
        DueToday: "common.subscription.checkout.summary.dueToday",
        ProRataCredit: "common.subscription.checkout.summary.proRataCredit",
        CreditCardError: "common.subscription.checkout.summary.creditCardError",
        Create: "common.subscription.checkout.summary.create",
        Update: "common.subscription.checkout.summary.update",
        SafeAndSecure: "common.subscription.checkout.summary.safeAndSecure",
        MoneyBack: "common.subscription.checkout.summary.moneyBack",
        DiscountAppliedAmount: "common.subscription.checkout.summary.discountAppliedAmount",
        DiscountApplied: "common.subscription.checkout.summary.discountApplied",
        AgreeToTerms: {
          By: "common.subscription.checkout.summary.agreetoterms.by",
          And: "common.subscription.checkout.summary.agreetoterms.and",
          Policies: "common.subscription.checkout.summary.agreetoterms.policies",
          Privacy: "common.subscription.checkout.summary.agreetoterms.privacy",
          Terms: "common.subscription.checkout.summary.agreetoterms.terms"
        }
      },
      Details: {
        YourPlan: "common.subscription.checkout.details.yourPlan",
        Billed: "common.subscription.checkout.details.billed",
        Plan: "common.subscription.checkout.details.plan",
        Change: "common.subscription.checkout.details.change",
        Units: "common.subscription.checkout.details.units"
      },
      Payment: {
        Header: "common.subscription.checkout.payment.header",
        SubHeader: "common.subscription.checkout.payment.subHeader",
        Button: "common.subscription.checkout.payment.button",
        SaveForLater: "common.subscription.checkout.payment.saveForLater"
      },
      Coupon: {
        Apply: "common.subscription.checkout.coupon.apply",
        Header: "common.subscription.checkout.coupon.header",
        Error: {
          Header: "common.subscription.checkout.coupon.error.header",
          Message: "common.subscription.checkout.coupon.error.message"
        }
      },
      Title: "common.subscription.checkout.title",
      SubTitle: "common.subscription.checkout.subTitle"
    },
    Button: {
      GetStarted: "common.subscription.button.getStarted",
      UpdatePlan: "common.subscription.button.update",
      UpgradePlan: "common.subscription.button.upgrade",
      DowngradePlan: "common.subscription.button.downgrade"
    },
    Faq: {
      Questions: {
        WhatIsDoorloop: "common.subscription.faq.questions.whatIsDoorloop",
        WhatProperty: "common.subscription.faq.questions.whatProperty",
        WhoFor: "common.subscription.faq.questions.whoFor",
        MostPopular: "common.subscription.faq.questions.mostPopular",
        IsEasy: "common.subscription.faq.questions.isEasy",
        FromPhone: "common.subscription.faq.questions.fromPhone",
        WhatProcess: "common.subscription.faq.questions.whatProcess",
        MoreQuestions: "common.subscription.faq.questions.moreQuestions",
        UpgradePlan: "common.subscription.faq.questions.upgradePlan",
        DowngradePlan: "common.subscription.faq.questions.downgradePlan",
        MoreUsers: "common.subscription.faq.questions.moreUsers",
        MoreUnits: "common.subscription.faq.questions.moreUnits",
        PrevPayments: "common.subscription.faq.questions.prevPayments",
        ExportInfo: "common.subscription.faq.questions.exportInfo",
        AccountOnHold: "common.subscription.faq.questions.accountOnHold",
        IfCancel: "common.subscription.faq.questions.ifCancel",
        CancelPlan: "common.subscription.faq.questions.cancelPlan",
        MoreQuestionsAlt: "common.subscription.faq.questions.moreQuestionsAlt"
      },
      Answers: {
        UpgradePlan: {
          Text: "common.subscription.faq.answers.upgradePlan.text"
        },
        DowngradePlan: {
          Text1: "common.subscription.faq.answers.downgradePlan.text1",
          Text2: "common.subscription.faq.answers.downgradePlan.text2"
        },
        MoreUsers: {
          Text: "common.subscription.faq.answers.moreUsers.text"
        },
        PrevPayments: {
          Text: "common.subscription.faq.answers.prevPayments.text"
        },
        ExportInfo: {
          Text: "common.subscription.faq.answers.exportInfo.text"
        },
        MoreUnits: {
          Text: "common.subscription.faq.answers.moreUnits.text"
        },
        IfCancel: {
          Text: "common.subscription.faq.answers.ifCancel.text"
        },
        CancelPlan: {
          Text: "common.subscription.faq.answers.cancelPlan.text",
          Email: "common.subscription.faq.answers.cancelPlan.email"
        },
        MoreQuestionsAlt: {
          Text: "common.subscription.faq.answers.moreQuestionsAlt.text",
          Email: "common.subscription.faq.answers.moreQuestionsAlt.email"
        },
        AccountOnHold: {
          Text: "common.subscription.faq.answers.accountOnHold.text",
          Email: "common.subscription.faq.answers.accountOnHold.email"
        },
        WhatIsDoorloop: {
          Text1: "common.subscription.faq.answers.whatIsDoorloop.text1",
          Text2: "common.subscription.faq.answers.whatIsDoorloop.text2"
        },
        WhoIsDoorloopFor: {
          Text1: "common.subscription.faq.answers.whoIsDoorloopFor.text1",
          Text2: "common.subscription.faq.answers.whoIsDoorloopFor.text2",
          Text3: "common.subscription.faq.answers.whoIsDoorloopFor.text3"
        },
        IsEasy: {
          Text1: "common.subscription.faq.answers.isEasy.text1",
          Text2: "common.subscription.faq.answers.isEasy.text2",
          Text3: "common.subscription.faq.answers.isEasy.text3"
        },
        UseFromPhone: {
          Text1: "common.subscription.faq.answers.useFromPhone.text1",
          Text2: "common.subscription.faq.answers.useFromPhone.text2",
          Text3: "common.subscription.faq.answers.useFromPhone.text3"
        },
        WhatIsTheProcess: {
          Text1: "common.subscription.faq.answers.whatIsTheProcess.text1",
          List: {
            L1: {
              Link: {
                Text1: "common.subscription.faq.answers.whatIsTheProcess.list.l1.link.text1",
                Href: "common.subscription.faq.answers.whatIsTheProcess.list.l1.link.href",
                Text2: "common.subscription.faq.answers.whatIsTheProcess.list.l1.link.text2"
              }
            },
            L2: {
              Content: "common.subscription.faq.answers.whatIsTheProcess.list.l2.content"
            },
            L3: {
              Content: "common.subscription.faq.answers.whatIsTheProcess.list.l3.content"
            },
            L4: {
              Content: "common.subscription.faq.answers.whatIsTheProcess.list.l4.content"
            }
          },
          Link: {
            Text1: "common.subscription.faq.answers.whatIsTheProcess.link.text1",
            Href: "common.subscription.faq.answers.whatIsTheProcess.link.href",
            Text2: "common.subscription.faq.answers.whatIsTheProcess.link.text2"
          }
        },
        WhatProperty: {
          Text1: "common.subscription.faq.answers.whatProperty.text1",
          Text2: "common.subscription.faq.answers.whatProperty.text2",
          List: {
            L1: {
              Header: "common.subscription.faq.answers.whatProperty.list.l1.header",
              Content: "common.subscription.faq.answers.whatProperty.list.l1.content"
            },
            L2: {
              Header: "common.subscription.faq.answers.whatProperty.list.l2.header",
              Content: "common.subscription.faq.answers.whatProperty.list.l2.content"
            },
            L3: {
              Header: "common.subscription.faq.answers.whatProperty.list.l3.header",
              Content: "common.subscription.faq.answers.whatProperty.list.l3.content"
            },
            L4: {
              Header: "common.subscription.faq.answers.whatProperty.list.l4.header",
              Content: "common.subscription.faq.answers.whatProperty.list.l4.content"
            },
            L5: {
              Header: "common.subscription.faq.answers.whatProperty.list.l5.header",
              Content: "common.subscription.faq.answers.whatProperty.list.l5.content"
            },
            L6: {
              Header: "common.subscription.faq.answers.whatProperty.list.l6.header",
              Content: "common.subscription.faq.answers.whatProperty.list.l6.content"
            }
          }
        },
        WhatFeatures: {
          Text1: "common.subscription.faq.answers.whatFeatures.text1",
          List: {
            L1: {
              Content: "common.subscription.faq.answers.whatFeatures.list.l1.content"
            },
            L2: {
              Content: "common.subscription.faq.answers.whatFeatures.list.l2.content"
            },
            L3: {
              Content: "common.subscription.faq.answers.whatFeatures.list.l3.content"
            },
            L4: {
              Content: "common.subscription.faq.answers.whatFeatures.list.l4.content"
            },
            L5: {
              Content: "common.subscription.faq.answers.whatFeatures.list.l5.content"
            },
            L6: {
              Content: "common.subscription.faq.answers.whatFeatures.list.l6.content"
            },
            L7: {
              Content: "common.subscription.faq.answers.whatFeatures.list.l7.content"
            },
            L8: {
              Content: "common.subscription.faq.answers.whatFeatures.list.l8.content"
            },
            L9: {
              Content: "common.subscription.faq.answers.whatFeatures.list.l9.content"
            },
            L10: {
              Content: "common.subscription.faq.answers.whatFeatures.list.l10.content"
            },
            L11: {
              Content: "common.subscription.faq.answers.whatFeatures.list.l11.content"
            },
            L12: {
              Content: "common.subscription.faq.answers.whatFeatures.list.l12.content"
            },
            L13: {
              Content: "common.subscription.faq.answers.whatFeatures.list.l13.content"
            },
            L14: {
              Content: "common.subscription.faq.answers.whatFeatures.list.l14.content"
            }
          },
          Link: {
            Text1: "common.subscription.faq.answers.whatFeatures.link.text1",
            Href: "common.subscription.faq.answers.whatFeatures.link.href",
            Text2: "common.subscription.faq.answers.whatFeatures.link.text2"
          }
        },
        MoreQuestions: {
          Link1: {
            Text1: "common.subscription.faq.answers.moreQuestions.link1.text1",
            Href: "common.subscription.faq.answers.moreQuestions.link1.href",
            Text2: "common.subscription.faq.answers.moreQuestions.link1.text2"
          },
          Link2: {
            Text1: "common.subscription.faq.answers.moreQuestions.link2.text1",
            Href: "common.subscription.faq.answers.moreQuestions.link2.href",
            Text2: "common.subscription.faq.answers.moreQuestions.link2.text2"
          }
        }
      }
    }
  },
  Validation: {
    Required: "common.validation.required",
    Invalid: "common.validation.invalid"
  },
  AcceptTOS: "common.acceptTOS",
  MustAcceptTOS: "common.mustAcceptTOS",
  BySigningUp: "common.bySigningUp",
  TermsOfService: "common.termsOfService",
  RapidRent: "common.rapidRent",
  RapidRentTermsOfService: "common.rapidRentTermsOfService",
  PrivacyPolicy: "common.privacyPolicy",
  Average: "common.average",
  PageNotFound: {
    Header: "common.pageNotFound.header",
    SubHeader: "common.pageNotFound.subHeader",
    ErrorHeader: "common.pageNotFound.errorHeader",
    ErrorSubHeader: "common.pageNotFound.errorSubHeader",
    ButtonText: "common.pageNotFound.buttonText"
  },
  AllLeases: "common.allLeases",
  FilterOptions: {
    AccountName: "common.filterOptions.accountName",
    Name: "common.filterOptions.name",
    Balance: "common.filterOptions.balance",
    Group: "common.filterOptions.group",
    Property: "common.filterOptions.property",
    Unit: "common.filterOptions.unit",
    Lease: "common.filterOptions.lease",
    Listed: "common.filterOptions.listed",
    Class: "common.filterOptions.class",
    Type: "common.filterOptions.type",
    Status: "common.filterOptions.status",
    TenantPortalStatus: "common.filterOptions.tenantPortalStatus",
    AvailabilityForApplications: "common.filterOptions.availabilityForApplications",
    LeaseStatus: "common.filterOptions.leaseStatus",
    UserRole: "common.filterOptions.userRole",
    Owner: "common.filterOptions.owner",
    BudgetType: "common.filterOptions.budgetType",
    Occupied: "common.filterOptions.occupied",
    InEviction: "common.filterOptions.inEviction",
    Active: "common.filterOptions.active",
    Portfolio: "common.filterOptions.portfolio",
    Filterby: "common.filterOptions.filterBy",
    PaymentMethod: "common.filterOptions.paymentMethod",
    DeletedItems: "common.filterOptions.deletedItems",
    ReportTypeAll: "common.filterOptions.reportTypeAll",
    ReportTypeSummary: "common.filterOptions.reportTypeSummary",
    ReportTypeDetailed: "common.filterOptions.reportTypeDetailed",
    Insurance: {
      InsuranceProvided: "common.filterOptions.insurance.insuranceProvided",
      InsuranceRequired: "common.filterOptions.insurance.insuranceRequired",
      InsuranceStatus: "common.filterOptions.insurance.insuranceStatus",
      NoInsurance: "common.filterOptions.insurance.noInsurance",
      Expired: "common.filterOptions.insurance.expired",
      Active: "common.filterOptions.insurance.active",
      Future: "common.filterOptions.insurance.future"
    },
    ActionRequired: "common.filterOptions.actionRequired",
    TransactionType: "common.filterOptions.transactionType",
    Issued: "common.filterOptions.issued",
    Unprinted: "common.filterOptions.unprinted"
  },
  ListHeader: {
    CurrentBalance: "common.listHeader.currentBalance",
    Balance: "common.listHeader.balance",
    Date: "common.listHeader.date",
    DepositAndPaymentDate: "common.listHeader.depositAndPaymentDate",
    DueDate: "common.listHeader.dueDate",
    Category: "common.listHeader.category",
    RunningBalance: "common.listHeader.runningBalance",
    Tenant: "common.listHeader.tenant",
    Vendor: "common.listHeader.vendor",
    Owner: "common.listHeader.owner",
    Memo: "common.listHeader.memo",
    ReceivedFrom: "common.listHeader.receivedFrom",
    Description: "common.listHeader.description",
    Notes: "common.listHeader.notes",
    Total: "common.listHeader.total",
    TotalCredit: "common.listHeader.totalCredit",
    TotalDebit: "common.listHeader.totalDebit",
    Actions: "common.listHeader.actions",
    Reference: "common.listHeader.reference",
    EPayStatus: "common.listHeader.ePayStatus",
    Status: "common.listHeader.status",
    Amount: "common.listHeader.amount",
    Subject: "common.listHeader.subject",
    CreatedDate: "common.listHeader.createdDate",
    Property: "common.listHeader.property"
  },
  Dropzone: {
    InstructionsTextPart1: "common.dropzone.instructionsTextPart1",
    InstructionsTextPart2: "common.dropzone.instructionsTextPart2",
    InstructionsDragActiveText: "common.dropzone.instructionsDragActiveText",
    FailedToUpload: "common.dropzone.failedToUpload",
    FailedToDelete: "common.dropzone.failedToDelete",
    ErrorInstructionsText: "common.dropzone.errorInstructionsText",
    Attachments: "common.dropzone.attachments",
    DownloadFile: "common.dropzone.downloadFile",
    Download: "common.dropzone.download",
    EditFileInfo: "common.dropzone.editFileInfo",
    FileInfo: "common.dropzone.fileInfo",
    UploadFile: "common.dropzone.uploadFile",
    DeleteFile: "common.dropzone.deleteFile",
    EditFile: "common.dropzone.editFile",
    FileName: "common.dropzone.fileName",
    UpdatingFile: "common.dropzone.updatingFile",
    LoadingFile: "common.dropzone.loadingFile",
    FileDescription: "common.dropzone.fileDescription",
    Tags: "common.dropzone.tags",
    DeleteFileConfirmationText: "common.dropzone.deleteFileConfirmationText",
    DeleteFileLoadingText: "common.dropzone.deleteFileLoadingText",
    DeleteFileSuccessText: "common.dropzone.deleteFileSuccessText",
    FailedToLoadFiles: "common.dropzone.failedToLoadFiles",
    Addfile: "common.dropzone.addfile",
    ReloadFiles: "common.dropzone.reloadFiles",
    DeletingFile: "common.dropzone.deletingFile",
    UploadingFile: "common.dropzone.uploadingFile",
    SharedAllFilesWithTenants: "common.dropzone.sharedAllFilesWithTenants"
  },
  SettingsText: "common.settingsText",
  Settings: {
    LateFees: {
      ChargeTime: "common.settings.lateFees.chargeTime",
      DaysAfterRentDue: "common.settings.lateFees.daysAfterRentDue",
      ChargeFrequency: "common.settings.lateFees.chargeFrequency",
      LateFeePolicyFrequency: "common.settings.lateFees.lateFeePolicyFrequency",
      LateFeesAccount: "common.settings.lateFees.lateFeesAccount",
      LateFeePolicyFeeTypeLabel: "common.settings.lateFees.lateFeePolicyFeeTypeLabel",
      ChargeAmount: "common.settings.lateFees.chargeAmount",
      BalancePercentage: "common.settings.lateFees.balancePercentage",
      LateFeeIncome: "common.settings.lateFees.lateFeeIncome",
      LateFee: "common.settings.lateFees.lateFee",
      Account: "common.settings.lateFees.account",
      SetMaxMonthlyLateFeesAmount: "common.settings.lateFees.setMaxMonthlyLateFeesAmount",
      SetMaxDailyLateFeesAmount: "common.settings.lateFees.setMaxDailyLateFeesAmount",
      SetMinBalanceToApplyLateFeeAmount: "common.settings.lateFees.setMinBalanceToApplyLateFeeAmount",
      LateFeesOptions: "common.settings.lateFees.lateFeesOptions"
    }
  },
  Enums: {
    PropertyType: {
      RESIDENTIAL_SINGLE_FAMILY: "common.enums.PropertyType.RESIDENTIAL_SINGLE_FAMILY",
      RESIDENTIAL_MULTI_FAMILY: "common.enums.PropertyType.RESIDENTIAL_MULTI_FAMILY",
      RESIDENTIAL_CONDO: "common.enums.PropertyType.RESIDENTIAL_CONDO",
      RESIDENTIAL_TOWNHOME: "common.enums.PropertyType.RESIDENTIAL_TOWNHOME",
      RESIDENTIAL_OTHER: "common.enums.PropertyType.RESIDENTIAL_OTHER",
      COMMERCIAL_OTHER: "common.enums.PropertyType.COMMERCIAL_OTHER",
      COMMERCIAL_INDUSTRIAL: "common.enums.PropertyType.COMMERCIAL_INDUSTRIAL",
      COMMERCIAL_OFFICE: "common.enums.PropertyType.COMMERCIAL_OFFICE",
      COMMERCIAL_RETAIL: "common.enums.PropertyType.COMMERCIAL_RETAIL",
      COMMERCIAL_SHOPPING_CENTER: "common.enums.PropertyType.COMMERCIAL_SHOPPING_CENTER",
      COMMERCIAL_STORAGE: "common.enums.PropertyType.COMMERCIAL_STORAGE",
      COMMERCIAL_PARKING: "common.enums.PropertyType.COMMERCIAL_PARKING",
      COMMERCIAL_CO_WORKING: "common.enums.PropertyType.COMMERCIAL_CO_WORKING"
    },
    ESignatureSignerType: {
      TENANT: "common.enums.eSignatureSignerType.TENANT",
      PROSPECT: "common.enums.eSignatureSignerType.PROSPECT",
      VENDOR: "common.enums.eSignatureSignerType.VENDOR",
      OWNER: "common.enums.eSignatureSignerType.OWNER",
      USER: "common.enums.eSignatureSignerType.USER"
    },
    ConversationsSort: {
      NEWEST: "common.enums.conversationsSort.NEWEST",
      OLDEST: "common.enums.conversationsSort.OLDEST"
    },
    CommunicationLinkedToType: {
      USER: "common.enums.communicationLinkedToType.USER",
      TENANT: "common.enums.communicationLinkedToType.TENANT",
      OWNER: "common.enums.communicationLinkedToType.OWNER",
      VENDOR: "common.enums.communicationLinkedToType.VENDOR",
      SYSTEM: "common.enums.communicationLinkedToType.SYSTEM"
    },
    ScreeningRequestReportsStatus: {
      IDENTITY_VERIFICATION_PENDING: "common.enums.screeningRequestReportsStatus.IDENTITY_VERIFICATION_PENDING",
      READY_FOR_REPORT_REQUEST: "common.enums.screeningRequestReportsStatus.READY_FOR_REPORT_REQUEST",
      REPORTS_DELIVERY_IN_PROGRESS: "common.enums.screeningRequestReportsStatus.REPORTS_DELIVERY_IN_PROGRESS",
      REPORTS_DELIVERY_FAILED: "common.enums.screeningRequestReportsStatus.REPORTS_DELIVERY_FAILED"
    },
    Purposes: {
      FINANCIAL_MANAGEMENT: "common.enums.purposes.FINANCIAL_MANAGEMENT",
      AUTOMATE_RENT_COLLECTION: "common.enums.purposes.AUTOMATE_RENT_COLLECTION",
      MARKETING: "common.enums.purposes.MARKETING",
      MAINTENANCE: "common.enums.purposes.MAINTENANCE",
      STREAMLINE_MY_WORKFLOW: "common.enums.purposes.STREAMLINE_MY_WORKFLOW",
      IMPROVE_COMMUNICATION: "common.enums.purposes.IMPROVE_COMMUNICATION",
      OTHER: "common.enums.purposes.OTHER"
    },
    JobTitles: {
      PROPERTY_MANAGER: "common.enums.jobTitles.PROPERTY_MANAGER",
      PROPERTY_MANAGER_AND_COMPANY_OWNER: "common.enums.jobTitles.PROPERTY_MANAGER_AND_COMPANY_OWNER",
      PROPERTY_OWNER: "common.enums.jobTitles.PROPERTY_OWNER",
      ACCOUNTANT: "common.enums.jobTitles.ACCOUNTANT",
      HOA_MEMBER_OR_COMMUNITY_MANAGER: "common.enums.jobTitles.HOA_MEMBER_OR_COMMUNITY_MANAGER",
      OTHER: "common.enums.jobTitles.OTHER"
    },
    Positions: {
      PRESIDENT: "common.enums.positions.PRESIDENT",
      VICE_PRESIDENT: "common.enums.positions.VICE_PRESIDENT",
      SECRETARY: "common.enums.positions.SECRETARY",
      TREASURY: "common.enums.positions.TREASURY",
      BOARD_MEMBER: "common.enums.positions.BOARD_MEMBER"
    },
    RentalApplicationJobType: {
      EMPLOYED: "common.enums.rentalApplicationJobType.EMPLOYED",
      SELF_EMPLOYED: "common.enums.rentalApplicationJobType.SELF_EMPLOYED",
      UNEMPLOYED: "common.enums.rentalApplicationJobType.UNEMPLOYED",
      STUDENT: "common.enums.rentalApplicationJobType.STUDENT"
    },
    PortalStatus: {
      INVITED: "common.enums.portalStatus.INVITED",
      ACTIVE: "common.enums.portalStatus.ACTIVE",
      INACTIVE: "common.enums.portalStatus.INACTIVE"
    },
    TaskStatus: {
      NOT_STARTED: "common.enums.taskStatus.NOT_STARTED",
      IN_PROGRESS: "common.enums.taskStatus.IN_PROGRESS",
      COMPLETED: "common.enums.taskStatus.COMPLETED",
      RECEIVED: "common.enums.taskStatus.RECEIVED",
      ARCHIVED: "common.enums.taskStatus.ARCHIVED"
    },
    TenantRequestType: {
      GENERAL: "common.enums.tenantRequestType.GENERAL",
      MAINTENANCE: "common.enums.tenantRequestType.MAINTENANCE"
    },
    TenantRequestMaintenanceCategory: {
      APPLIANCES: "common.enums.tenantRequestMaintenanceCategory.APPLIANCES",
      DOORS_AND_LOCKS: "common.enums.tenantRequestMaintenanceCategory.DOORS_AND_LOCKS",
      ELECTRONICS_AND_LIGHTING: "common.enums.tenantRequestMaintenanceCategory.ELECTRONICS_AND_LIGHTING",
      EXTERIORS: "common.enums.tenantRequestMaintenanceCategory.EXTERIORS",
      FLOORING: "common.enums.tenantRequestMaintenanceCategory.FLOORING",
      HEATING_AND_COOLING: "common.enums.tenantRequestMaintenanceCategory.HEATING_AND_COOLING",
      PLUMBING_AND_BATHROOMS: "common.enums.tenantRequestMaintenanceCategory.PLUMBING_AND_BATHROOMS",
      SAFETY_EQUIPMENT: "common.enums.tenantRequestMaintenanceCategory.SAFETY_EQUIPMENT",
      PREVENTATIVE_MAINTENANCE: "common.enums.tenantRequestMaintenanceCategory.PREVENTATIVE_MAINTENANCE",
      PEST_MANAGEMENT: "common.enums.tenantRequestMaintenanceCategory.PEST_MANAGEMENT",
      RESIDENT_EXPERIENCE: "common.enums.tenantRequestMaintenanceCategory.RESIDENT_EXPERIENCE",
      HVAC: "common.enums.tenantRequestMaintenanceCategory.HVAC",
      ARCHITECTURAL: "common.enums.tenantRequestMaintenanceCategory.ARCHITECTURAL",
      OTHER: "common.enums.tenantRequestMaintenanceCategory.OTHER"
    },
    TaskType: {
      INTERNAL_TASK: "common.enums.taskType.INTERNAL_TASK",
      TENANT_REQUEST: "common.enums.taskType.TENANT_REQUEST",
      OWNER_REQUEST: "common.enums.taskType.OWNER_REQUEST",
      WORK_ORDER: "common.enums.taskType.WORK_ORDER"
    },
    ReceivedFrom: {
      TENANT: "common.enums.receivedFrom.TENANT",
      OWNER: "common.enums.receivedFrom.OWNER",
      VENDOR: "common.enums.receivedFrom.VENDOR"
    },
    DepositStatus: {
      DEPOSITED: "common.enums.depositStatus.DEPOSITED",
      UNDEPOSITED: "common.enums.depositStatus.UNDEPOSITED"
    },
    RentalApplicationDecisionStatus: {
      NOT_STARTED: "common.enums.rentalApplicationDecisionStatus.NOT_STARTED",
      UNDECIDED: "common.enums.rentalApplicationDecisionStatus.UNDECIDED",
      APPROVED: "common.enums.rentalApplicationDecisionStatus.APPROVED",
      REJECTED: "common.enums.rentalApplicationDecisionStatus.REJECTED",
      IGNORED: "common.enums.rentalApplicationDecisionStatus.IGNORED"
    },
    RentalApplicationStatus: {
      NOT_SENT: "common.enums.rentalApplicationStatus.NOT_SENT",
      SENT: "common.enums.rentalApplicationStatus.SENT",
      SENDING_FAILED: "common.enums.rentalApplicationStatus.SENDING_FAILED",
      SUBMITTED: "common.enums.rentalApplicationStatus.SUBMITTED"
    },
    RentalApplicationReportStatus: {
      PENDING_REPORT: "common.enums.rentalApplicationReportStatus.PENDING_REPORT",
      IDENTITY_VERIFICATION_FAILED: "common.enums.rentalApplicationReportStatus.IDENTITY_VERIFICATION_FAILED",
      REPORT_AVAILABLE: "common.enums.rentalApplicationReportStatus.REPORT_AVAILABLE"
    },
    RentalApplicationOverviewDecisionStatus: {
      NOT_STARTED: "common.enums.rentalApplicationOverviewDecisionStatus.NOT_STARTED",
      UNDECIDED: "common.enums.rentalApplicationOverviewDecisionStatus.UNDECIDED",
      APPROVED: "common.enums.rentalApplicationOverviewDecisionStatus.APPROVED",
      REJECTED: "common.enums.rentalApplicationOverviewDecisionStatus.REJECTED",
      IGNORED: "common.enums.rentalApplicationOverviewDecisionStatus.IGNORED"
    },
    JournalEntryType: {
      BeginningBalance: "common.enums.journalEntryType.beginningBalance",
      LeaseCharge: "common.enums.journalEntryType.leaseCharge",
      LeasePayment: "common.enums.journalEntryType.leasePayment",
      LeaseRefund: "common.enums.journalEntryType.leaseRefund",
      LeaseCredit: "common.enums.journalEntryType.leaseCredit",
      VendorBill: "common.enums.journalEntryType.vendorBill",
      VendorBillPayment: "common.enums.journalEntryType.vendorBillPayment",
      VendorCredit: "common.enums.journalEntryType.vendorCredit",
      Expense: "common.enums.journalEntryType.expense",
      LineItem: "common.enums.journalEntryType.lineItem",
      Deposit: "common.enums.journalEntryType.deposit",
      GeneralEntry: "common.enums.journalEntryType.generalEntry",
      Transfer: "common.enums.journalEntryType.transfer",
      OwnerContribution: "common.enums.journalEntryType.ownerContribution",
      OwnerDistribution: "common.enums.journalEntryType.ownerDistribution",
      ApplicationFee: "common.enums.journalEntryType.applicationFee",
      ReversedPayments: "common.enums.journalEntryType.leaseReversedPayment",
      AppliedCredit: "common.enums.journalEntryType.appliedCredit"
    },
    PaymentMethod: {
      CASH: "common.enums.paymentMethod.CASH",
      CHECK: "common.enums.paymentMethod.CHECK",
      OTHER: "common.enums.paymentMethod.OTHER",
      EPAY: "common.enums.paymentMethod.EPAY",
      CREDIT_CARD: "common.enums.paymentMethod.CREDIT_CARD",
      CASHIERS_CHECK: "common.enums.paymentMethod.CASHIERS_CHECK",
      MONEY_ORDER: "common.enums.paymentMethod.MONEY_ORDER",
      EFT: "common.enums.paymentMethod.EFT",
      DEBIT_CARD: "common.enums.paymentMethod.DEBIT_CARD",
      WESTERN_UNION: "common.enums.paymentMethod.WESTERN_UNION_CASH"
    },
    InsurancePolicyProvider: {
      OTHER: "common.enums.insurancePolicyProvider.OTHER",
      LEMONADE: "common.enums.insurancePolicyProvider.LEMONADE",
      SURE: "common.enums.insurancePolicyProvider.SURE"
    },
    LinkedResourceType: {
      LeaseCharge: "common.enums.linkedResourceType.leaseCharge",
      LeasePayment: "common.enums.linkedResourceType.leasePayment",
      LeaseReversedPayment: "common.enums.linkedResourceType.leaseReversedPayment",
      LeaseRefund: "common.enums.linkedResourceType.leaseRefund",
      LeaseCredit: "common.enums.linkedResourceType.leaseCredit",
      Expense: "common.enums.linkedResourceType.expense",
      VendorBill: "common.enums.linkedResourceType.vendorBill",
      VendorBillPayment: "common.enums.linkedResourceType.vendorBillPayment",
      Deposit: "common.enums.linkedResourceType.deposit",
      GeneralEntry: "common.enums.linkedResourceType.generalEntry",
      Reconciliation: "common.enums.linkedResourceType.reconciliation",
      Transfer: "common.enums.linkedResourceType.transfer",
      ManagementFee: "common.enums.linkedResourceType.managementFee",
      OwnerContribution: "common.enums.linkedResourceType.ownerContribution",
      OwnerDistribution: "common.enums.linkedResourceType.ownerDistribution",
      VendorCredit: "common.enums.linkedResourceType.vendorCredit",
      Tenant: "common.enums.linkedResourceType.tenant",
      Prospect: "common.enums.linkedResourceType.prospect",
      Property: "common.enums.linkedResourceType.property",
      Unit: "common.enums.linkedResourceType.unit",
      Owner: "common.enums.linkedResourceType.owner",
      File: "common.enums.linkedResourceType.file",
      Lease: "common.enums.linkedResourceType.lease",
      LeaseDraft: "common.enums.linkedResourceType.leaseDraft",
      Vendor: "common.enums.linkedResourceType.vendor",
      User: "common.enums.linkedResourceType.user",
      LeaseRenewalOffer: "common.enums.linkedResourceType.leaseRenewalOffer",
      Note: "common.enums.linkedResourceType.note",
      RecurringTransaction: "common.enums.linkedResourceType.recurringTransaction",
      RecurringTransactionRent: "common.enums.linkedResourceType.recurringTransactionRent",
      RecurringTransactionCharge: "common.enums.linkedResourceType.recurringTransactionCharge",
      RecurringTransactionCredit: "common.enums.linkedResourceType.recurringTransactionCredit",
      RecurringTransactionPayment: "common.enums.linkedResourceType.recurringTransactionPayment",
      RecurringTransactionVendorBill: "common.enums.linkedResourceType.recurringTransactionVendorBill",
      RecurringTransactionExpense: "common.enums.linkedResourceType.recurringTransactionExpense",
      RecurringTransactionTask: "common.enums.linkedResourceType.recurringTransactionTask",
      Task: "common.enums.linkedResourceType.task",
      RentalApplication: "common.enums.linkedResourceType.rentalApplication",
      InsurancePolicy: "common.enums.linkedResourceType.insurancePolicy",
      Announcement: "common.enums.linkedResourceType.announcement",
      Communication: "common.enums.linkedResourceType.communication",
      Conversation: "common.enums.linkedResourceType.conversation",
      ESignatureRequest: "common.enums.linkedResourceType.eSignatureRequest",
      ESignatureTemplate: "common.enums.linkedResourceType.eSignatureTemplate",
      Request: "common.enums.linkedResourceType.request"
    }
  },
  CreateNewMenu: {
    TasksAndMaintenance: "common.createNewMenu.tasksAndMaintenance",
    user: "common.createNewMenu.user",
    Lease: "common.createNewMenu.lease",
    TenantTransactions: "common.createNewMenu.tenantTransactions",
    VendorTransactions: "common.createNewMenu.vendorTransactions",
    OwnerTransactions: "common.createNewMenu.ownerTransactions",
    OtherTransactions: "common.createNewMenu.otherTransactions",
    OwnerContribution: "common.createNewMenu.ownerContribution",
    OwnerDistribution: "common.createNewMenu.ownerDistribution",
    JournalEntry: "common.createNewMenu.journalEntry",
    BankTransfer: "common.createNewMenu.bankTransfer",
    BankDeposit: "common.createNewMenu.bankDeposit",
    PrintChecks: "common.createNewMenu.printChecks",
    BillsAndExpenses: "common.createNewMenu.billsAndExpenses",
    ChargesAndPayment: "common.createNewMenu.chargesAndPayment",
    Check: "common.createNewMenu.check"
  },
  GlobalSearch: {
    SearchTitle: "common.globalSearch.searchTitle",
    SearchEverywhere: "common.globalSearch.searchEverywhere",
    SearchInstructions: "common.globalSearch.searchInstructions",
    NoResultsFoundDescription: "common.globalSearch.noResultsFoundDescription"
  },
  ReceivedFrom: {
    VENDOR: "common.receivedFrom.VENDOR",
    TENANT: "common.enums.receivedFrom.TENANT",
    OWNER: "common.receivedFrom.OWNER",
    NoResultsFoundDescription: "common.globalSearch.noResultsFoundDescription"
  },
  NotificationsPanel: {
    EmailConfirm: {
      ConfirmMessage: "common.notificationsPanel.emailConfirm.confirmMessage",
      ResendEmail: "common.notificationsPanel.emailConfirm.resendEmail"
    }
  },
  FilterPanelDatePicker: {
    AllTime: "common.filterPanelDatePicker.allTime",
    From: "common.filterPanelDatePicker.from",
    To: "common.filterPanelDatePicker.to",
    Custom: "common.filterPanelDatePicker.custom",
    Today: "common.filterPanelDatePicker.today",
    ThisWeek: "common.filterPanelDatePicker.thisWeek",
    ThisWeekToDate: "common.filterPanelDatePicker.thisWeekToDate",
    ThisMonth: "common.filterPanelDatePicker.thisMonth",
    ThisMonthToDate: "common.filterPanelDatePicker.thisMonthToDate",
    ThisQuarter: "common.filterPanelDatePicker.thisQuarter",
    ThisQuarterToDate: "common.filterPanelDatePicker.thisQuarterToDate",
    ThisYear: "common.filterPanelDatePicker.thisYear",
    ThisYearToDate: "common.filterPanelDatePicker.thisYearToDate",
    ThisYearTolastMonth: "common.filterPanelDatePicker.thisYearTolastMonth",
    Yesterday: "common.filterPanelDatePicker.yesterday",
    LastWeek: "common.filterPanelDatePicker.lastWeek",
    LastWeekToDate: "common.filterPanelDatePicker.lastWeekToDate",
    LastMonth: "common.filterPanelDatePicker.lastMonth",
    LastMonthToDate: "common.filterPanelDatePicker.lastMonthToDate",
    LastQuarter: "common.filterPanelDatePicker.lastQuarter",
    LastQuarterToDate: "common.filterPanelDatePicker.lastQuarterToDate",
    LastYear: "common.filterPanelDatePicker.lastYear",
    Last7Days: "common.filterPanelDatePicker.last7Days",
    Last30Days: "common.filterPanelDatePicker.last30Days",
    Last60Days: "common.filterPanelDatePicker.last60Days",
    Last90Days: "common.filterPanelDatePicker.last90Days",
    Last120Days: "common.filterPanelDatePicker.last120Days",
    Last180Days: "common.filterPanelDatePicker.last180Days",
    Next7Days: "common.filterPanelDatePicker.next7Days",
    Next30Days: "common.filterPanelDatePicker.next30Days",
    Next60Days: "common.filterPanelDatePicker.next60Days",
    Next90Days: "common.filterPanelDatePicker.next90Days",
    Next120Days: "common.filterPanelDatePicker.next120Days"
  },
  BankAccount: "common.bankAccount",
  OnlinePayments: "common.onlinePayments",
  ResumeApplication: "common.resumeApplication",
  ApplyNow: "common.applyNow",
  VerifyIdentity: "common.verifyIdentity",
  AcceptingOnlinePayments: "common.acceptingOnlinePayments",
  ApplicationDeclined: "common.applicationDeclined",
  ApplicationSubmitted: "common.applicationSubmitted",
  AccountClosed: "common.accountClosed",
  UndepositedFunds: "common.undepositedFunds",
  PaymentsInProcess: "common.paymentsInProcess",
  EPayInTransit: "common.ePayInTransit",
  DoorLoopBalance: "common.doorLoopBalance",
  MakeInactiveAccountConfirmationText: "common.makeInactiveAccountConfirmationText",
  MakeActiveAccountConfirmationText: "common.makeActiveAccountConfirmationText",
  BankAccountsEmptyDataViewInstructionsText: "common.bankAccountsEmptyDataViewInstructionsText",
  OnlinePaymentsNotSupportedTitle: "common.onlinePaymentsNotSupportedTitle",
  OnlinePaymentsNotSupportedSubTitle: "common.onlinePaymentsNotSupportedSubTitle",
  SendUsMessage: "common.sendUsMessage",
  BeforeYouBegin: "common.beforeYouBegin",
  BeforeYouBeginDescription: "common.beforeYouBeginDescription",
  AboutTheBusiness: "common.aboutTheBusiness",
  AboutTheOwners: "common.aboutTheOwners",
  AboutTheOwnersDescription: "common.aboutTheOwnersDescription",
  PayoutInfo: "common.payoutInfo",
  Summary: "common.summary",
  Requirement: "common.requirement",
  MerchantAccountRequirements: "common.merchantAccountRequirements",
  RequirementInstructions: "common.requirementInstructions",
  BusinessTax: "common.businessTax",
  SocialSecurityAndDriverLicense: "common.socialSecurityAndDriverLicense",
  RoutingAndAccountNumber: "common.routingAndAccountNumber",
  NewMerchantAccount: "common.newMerchantAccount",
  PayWithRapidrent: "common.payWithRapidrent",
  PayWithRapidrentDescription: "common.payWithRapidrentDescription",
  AboutTheBusinessDescription: "common.aboutTheBusinessDescription",
  GeneralBusinessInformation: "common.generalBusinessInformation",
  GeneralBusinessExplainer: "common.generalBusinessExplainer",
  BusinessType: "common.businessType",
  BusinessName: "common.businessName",
  EinPlaceholder: "common.einPlaceholder",
  YearEstablished: "common.yearEstablished",
  DoingBusinessAs: "common.doingBusinessAs",
  BusinessPhone: "common.businessPhone",
  CustomerServicePhone: "common.customerServicePhone",
  Website: "common.website",
  AddressInformation: "common.addressInformation",
  AddressInformationDescriptionForBusiness: "common.addressInformationDescriptionForBusiness",
  AddressInformationDescriptionForOwner: "common.addressInformationDescriptionForOwner",
  AdditionalInformation: "common.additionalInformation",
  AdditionalInformationDescription: "common.additionalInformationDescription",
  NoWebsiteHelpText: "common.noWebsiteHelpText",
  CurrentlyAcceptingCreditCards: "common.currentlyAcceptingCreditCards",
  ThisBusinessIsPubliclyTraded: "common.thisBusinessIsPubliclyTraded",
  AnnualVolume: "common.annualVolume",
  AverageTicket: "common.averageTicket",
  BusinessOwners: "common.businessOwners",
  PrimaryBusinessRepresentative: "common.primaryBusinessRepresentative",
  GeneralInformation: "common.generalInformation",
  SSN: "common.ssn",
  SSNorITIN: "common.ssnOrItin",
  ITIN: "common.itin",
  SocialIdNumber: "common.socialIdNumber",
  DriversLicenseNumber: "common.driversLicenseNumber",
  DriverLicenseState: "common.driverLicenseState",
  UseBusinessAddress: "common.useBusinessAddress",
  OwnershipInformation: "common.ownershipInformation",
  SignificantReponsibility: "common.significantReponsibility",
  AuthorizedEnter: "common.authorizedEnter",
  OwnershipPercent: "common.ownershipPercent",
  PayoutInfoSubTitle: "common.payoutInfoSubTitle",
  Important: "common.important",
  EndLeaseWarning: "common.endLeaseWarning",
  PayoutWarningText: "common.payoutWarningText",
  AccountDetails: "common.accountDetails",
  AccountType: "common.accountType",
  BankRoutingNumber: "common.bankRoutingNumber",
  BankRoutingNumberDescription: "common.bankRoutingNumberDescription",
  BankAccountNumber: "common.bankAccountNumber",
  ConfirmBankAccountNumber: "common.confirmBankAccountNumber",
  ConfirmBankAccountNumberDescription: "common.confirmBankAccountNumberDescription",
  SummaryDescription: "common.summaryDescription",
  RapidRentTermsAndConditions: "common.rapidRentTermsAndConditions",
  RapidRentVerifyDays: "common.rapidRentVerifyDays",
  RapidRentReview: "common.rapidRentReview",
  RapidRentCharge: "common.rapidRentCharge",
  AcceptRapidRentTerms: "common.acceptRapidRentTerms",
  AutoPrefillInformation: "common.autoPrefillInformation",
  AutoPrefillInformationDescription: "common.autoPrefillInformationDescription",
  NewBusinessEntity: "common.newBusinessEntity",
  ExistingBusinessEntity: "common.existingBusinessEntity",
  Application: "common.application",
  ApplicationReference: "common.applicationReference",
  ApplicationSelectionValidationText: "common.applicationSelectionValidationText",
  OwnersSelectionValidationText: "common.ownersSelectionValidationText",
  CreatingMerchantApplication: "common.creatingMerchantApplication",
  TermsAndConditionsValidationMessage: "common.termsAndConditionsValidationMessage",
  TransunionAmountError: "common.transunionAmountError",
  BusinessInfoValidationMessage: "common.businessInfoValidationMessage",
  NoRecurringTransactionsFound: "common.noRecurringTransactionsFound",
  NoRecurringRentFound: "common.noRecurringRentFound",
  PortalContactInfo: "common.portalContactInfo",
  TenantPortalContactInfoDescription: "common.tenantPortalContactInfoDescription",
  OwnerPortalContactInfoDescription: "common.ownerPortalContactInfoDescription",
  HideTenantPortalContactInfo: "common.hideTenantPortalContactInfo",
  SpecifyTenantPortalContactInfo: "common.specifyTenantPortalContactInfo",
  HideOwnerPortalContactInfo: "common.hideOwnerPortalContactInfo",
  SpecifyOwnerPortalContactInfo: "common.specifyOwnerPortalContactInfo",
  HidePortalContactInfoForProperty: "common.hidePortalContactInfoForProperty",
  SpecifyPortalContactInfoForProperty: "common.specifyPortalContactInfoForProperty",
  HideThemeForProperty: "common.hideThemeForProperty",
  SpecifyThemeForProperty: "common.specifyThemeForProperty",
  HideFeaturesForProperty: "common.hideFeaturesForProperty",
  SpecifyFeaturesForProperty: "common.specifyFeaturesForProperty",
  HideFeaturesForLease: "common.hideFeaturesForLease",
  SpecifyFeaturesForLease: "common.specifyFeaturesForLease",
  HideInvitesForProperty: "common.hideInvitesForProperty",
  SpecifyInvitesForProperty: "common.specifyInvitesForProperty",
  ThemeSettings: "common.themeSettings",
  CountryAddress: "common.countryAddress",
  CompanyAddress: "common.companyAddress",
  OwnerPortalThemeSettingsDescription: "common.ownerPortalThemeSettingsDescription",
  Logo: "common.logo",
  ColorTheme: "common.colorTheme",
  BackgroundAndCoverImage: "common.backgroundAndCoverImage",
  PressToAddCoverImage: "common.pressToAddCoverImage",
  CoverImageRecommendedSize: "common.coverImageRecommendedSize",
  PortalInvites: "common.portalInvites",
  TenantsPortalSettingsDescription: "common.tenantsPortalSettingsDescription",
  OwnersPortalFeaturesDescription: "common.ownersPortalFeaturesDescription",
  Permissions: "common.permissions",
  TenantPortalFeaturesAccess: "common.tenantPortalFeaturesAccess",
  OwnerPortalFeaturesAccess: "common.ownerPortalFeaturesAccess",
  EnableOnlinePayments: "common.enableOnlinePayments",
  TenantInsurance: "common.tenantInsurance",
  AllowPartialPayments: "common.allowPartialPayments",
  LeaseLedger: "common.leaseLedger",
  ViewLeaseLedger: "common.viewLeaseLedger",
  UploadProofOfInsurance: "common.uploadProofOfInsurance",
  RequireInsuranceDocuments: "common.requireInsuranceDocuments",
  PurchaseRentersInsurance: "common.purchaseRentersInsurance",
  RequestsAndCommunication: "common.requestsAndCommunication",
  TenantPortalRequestsSettingsDescription: "common.tenantPortalRequestsSettingsDescription",
  Requests: "common.requests",
  AccessToRequests: "common.accessToRequests",
  PaymentPreferences: "common.paymentPreferences",
  AllowTenantOnlineRequests: "common.allowTenantOnlineRequests",
  SubmitNewOnlineRequests: "common.submitNewOnlineRequests",
  ViewOpenRequests: "common.viewOpenRequests",
  ViewClosedRequests: "common.viewClosedRequests",
  UpdateOpenRequests: "common.updateOpenRequests",
  TenantPortalInvitesDescription: "common.tenantPortalInvitesDescription",
  OwnerPortalInvitesDescription: "common.ownerPortalInvitesDescription",
  PortalInviteTitle: "common.portalInviteTitle",
  PortalInviteSubTitle: "common.portalInviteSubTitle",
  EmailBody: "common.emailBody",
  PortalUrl: "common.portalUrl",
  EmailTemplate: "common.emailTemplate",
  UrlStructure: "common.urlStructure",
  HidePortalUrlForProperty: "common.hidePortalUrlForProperty",
  SpecifyPortalUrlForProperty: "common.specifyPortalUrlForProperty",
  HideTenantRequestSettingsForProperty: "common.hideTenantRequestSettingsForProperty",
  SpecifyTenantRequestSettingsForProperty: "common.specifyTenantRequestSettingsForProperty",
  PortalAccess: "common.portalAccess",
  TenantPortalAccess: "common.tenantPortalAccess",
  OwnerPortalAccess: "common.ownerPortalAccess",
  NewPortalInvite: "common.newPortalInvite",
  NewPortalInviteDescription: "common.newPortalInviteDescription",
  TenantPortalAccessActive: "common.tenantPortalAccessActive",
  TenantPortalAccessActiveDescription: "common.tenantPortalAccessActiveDescription",
  OwnerPortalInvitedDescription: "common.ownerPortalInvitedDescription",
  OwnerPortalAccessActive: "common.ownerPortalAccessActive",
  OwnerPortalAccessActiveDescription: "common.ownerPortalAccessActiveDescription",
  SentAt: "common.sentAt",
  LastSent: "common.lastSent",
  InvitationSent: "common.invitationSent",
  SendingInvitation: "common.sendingInvitation",
  TenantPortalInvitedDescription: "common.tenantPortalInvitedDescription",
  DoorloopDemo: "common.doorloopDemo",
  AsSeenOn: "common.asSeenOn",
  DoorloopDemoDescription: "common.doorloopDemoDescription",
  DoorloopDemoAdditionalInfoDescription: "common.doorloopDemoAdditionalInfoDescription",
  Home: "common.home",
  Details: "common.details",
  MyLease: "common.myLease",
  LeaseDetails: "common.leaseDetails",
  Welcome: "common.welcome",
  AmountDue: "common.amountDue",
  CreditAvailable: "common.creditAvailable",
  AutoPay: "common.autoPay",
  SinglePay: "common.singlePay",
  OutstandingBalanceDue: "common.outstandingBalance",
  AvailableBalance: "common.availableBalance",
  NoBalance: "common.noBalance",
  MakeAPayment: "common.makeAPayment",
  SetupAutoPay: "common.setupAutoPay",
  InsuranceIsRequired: "common.insuranceIsRequired",
  InsuranceIsRequiredDescription: "common.insuranceIsRequiredDescription",
  NoActiveAutoPay: "common.noActiveAutoPay",
  NoActiveAutoPayDescription: "common.noActiveAutoPayDescription",
  GetInsurance: "common.getInsurance",
  NoActiveInsurance: "common.noActiveInsurance",
  NoActiveInsuranceDescription: "common.noActiveInsuranceDescription",
  NoActiveRequests: "common.noActiveRequests",
  NoActiveRequestsDescription: "common.noActiveRequestsDescription",
  AddNewRequest: "common.addNewRequest",
  PaymentsHistory: "common.paymentsHistory",
  DownloadAccountStatement: "common.downloadAccountStatement",
  NoPaymentHistoryFound: "common.noPaymentHistoryFound",
  NoPayments: "common.noPayments",
  NoPaymentsDescription: "common.noPaymentsDescription",
  HowCanWeHelp: "common.howCanWeHelp",
  RequestsScreenSubTitle: "common.requestsScreenSubTitle",
  LeaseAddress: "common.leaseAddress",
  BillingAndPayment: "common.billingAndPayment",
  LastPayment: "common.lastPayment",
  AutoPayInstructions: "common.autoPayInstructions",
  PayWithAutoPay: "common.payWithAutoPay",
  PayWithAutoPayDescription: "common.payWithAutoPayDescription",
  WhyUseAutoPay: "common.whyUseAutoPay",
  SinglePayment: "common.singlePayment",
  SinglePayDescription: "common.singlePayDescription",
  NameOnCard: "common.nameOnCard",
  CardNumber: "common.cardNumber",
  ExDate: "common.exDate",
  Cvv: "common.cvv",
  NewCreditCard: "common.newCreditCard",
  NewPaymentMethod: "common.newPaymentMethod",
  PaymentOptions: "common.paymentOptions",
  Change: "common.change",
  AmountToPay: "common.amountToPay",
  RentPaymentDue: "common.rentPaymentDue",
  PaymentMethod: "common.paymentMethod",
  PaymentMethods: "common.paymentMethods",
  TenantScreeningReportAvailable: "common.tenantScreeningReportAvailable",
  CreditCardPlatformFee: "common.creditCardPlatformFee",
  SavePaymentInformation: "common.savePaymentInformation",
  PaymentOptionsDescription: "common.paymentOptionsDescription",
  Card: "common.card",
  Ach: "common.ach",
  ECheck: "common.eCheck",
  AddCard: "common.addCard",
  AddECheck: "common.addECheck",
  RecurringPayment: "common.recurringPayment",
  OneTimePayment: "common.oneTimePayment",
  PaymentSummary: "common.paymentSummary",
  ConfirmPaymentTitle: "common.confirmPaymentTitle",
  ConfirmPaymentSubTitle: "common.confirmPaymentSubTitle",
  AppliedTowardsRent: "common.appliedTowardsRent",
  PlatformFee: "common.platformFee",
  PlatformFeeWithPercentageFee: "common.platformFeeWithPercentageFee",
  Minimum: "common.minimum",
  SwitchToECheck: "common.switchToECheck",
  NoFee: "common.noFee",
  NewBankAccount: "common.newBankAccount",
  SwitchToCreditCard: "common.switchToCreditCard",
  AddBankAccount: "common.addBankAccount",
  BankAccountPlatformFee: "common.bankAccountPlatformFee",
  PaymentOptionsAmountDescription: "common.paymentOptionsAmountDescription",
  PaymentOptionsPaymentDate: "common.paymentOptionsPaymentDate",
  PaymentOptionsFrequencyDescription: "common.paymentOptionsFrequencyDescription",
  SetupPayemntOptionsDescription: "common.setupPayemntOptionsDescription",
  NumberOfPayments: "common.numberOfPayments",
  InvalidPaymentMethod: "common.invalidPaymentMethod",
  Update: "common.update",
  SelectPaymentMethod: "common.selectPaymentMethod",
  ExistingPaymentMethod: "common.existingPaymentMethod",
  RecurrenceHints: {
    OnTheRecurringDayOfEachMonth: "common.recurrenceHints.onTheRecurringDayOfEachMonth",
    PaymentDayMayDiffer: "common.recurrenceHints.paymentDayMayDiffer"
  },
  Every2Weeks: "common.enums.recurringTransactionFrequency.Every2Weeks",
  Daily: "common.enums.recurringTransactionFrequency.Daily",
  Weekly: "common.enums.recurringTransactionFrequency.Weekly",
  Monthly: "common.enums.recurringTransactionFrequency.Monthly",
  Every2Months: "common.enums.recurringTransactionFrequency.Every2Months",
  Quarterly: "common.enums.recurringTransactionFrequency.Quarterly",
  Every6Months: "common.enums.recurringTransactionFrequency.Every6Months",
  Annually: "common.enums.recurringTransactionFrequency.Annually",
  Annual: "common.enums.recurringTransactionFrequency.Annual",
  OneTime: "common.enums.recurringTransactionFrequency.OneTime",
  Every7DaysLowercase: "common.enums.recurringTransactionFrequency.Every7DaysLowercase",
  Every14DaysLowercase: "common.enums.recurringTransactionFrequency.Every14DaysLowercase",
  ReturnedPaymentFee: "common.returnedPaymentFee",
  ByMakingPayment: "common.byMakingPayment",
  Terms: "common.terms",
  Privacy: "common.privacy",
  Cookie: "common.cookie",
  Policies: "common.policies",
  YourLastPaymentWillBeOn: "common.yourLastPaymentWillBeOn",
  SchedulePayment: "common.schedulePayment",
  MonthlyAutoPay: "common.monthlyAutoPay",
  WeeklyAutoPay: "common.weeklyAutoPay",
  Every2WeeksAutoPay: "common.every2WeeksAutoPay",
  CreditCardAutoPay: "common.creditCardAutoPay",
  ECheckAutoPay: "common.eCheckAutoPay",
  NextPayment: "common.nextPayment",
  ConvenienceFee: "common.convenienceFee",
  ConvenienceFees: "common.convenienceFees",
  CancelAutoPay: "common.cancelAutoPay",
  EditAutoPay: "common.editAutoPay",
  ScheduledPayment: "common.scheduledPayment",
  AutoPayDeleteConfirmationText: "common.autoPayDeleteConfirmationText",
  AutoPayCancelConfirmationText: "common.autoPayCancelConfirmationText",
  AutoPayCancelLoadingText: "common.autoPayCancelLoadingText",
  AutoPayCancelSuccessText: "common.autoPayCancelSuccessText",
  AutoPayDeleteLoadingText: "common.autoPayDeleteLoadingText",
  AutoPayDeleteSuccessText: "common.autoPayDeleteSuccessText",
  AutoPayIsActive: "common.autoPayIsActive",
  ActiveAutoPayDescriptionForThisLease: "common.activeAutoPayDescriptionForThisLease",
  PaymentSuccessfully: "common.paymentSuccessfully",
  ConfirmationNumber: "common.confirmationNumber",
  TotalCharged: "common.totalCharged",
  Done: "common.done",
  PaymentInstructions: "common.paymentInstructions",
  NewRequest: "common.newRequest",
  EditRequest: "common.editRequest",
  ViewOpenRequest: "common.viewOpenRequest",
  RequestSubjectDescription: "common.requestSubjectDescription",
  RequestSubjectDescriptionViewOnly: "common.requestSubjectDescriptionViewOnly",
  RequestDescriptionText: "common.requestDescriptionText",
  RequestDescriptionTextViewOnly: "common.requestDescriptionTextViewOnly",
  RequestDescriptionTextNoDescription: "common.requestDescriptionTextNoDescription",
  FilesAndDocuments: "common.filesAndDocuments",
  FilesDescription: "common.filesDescription",
  RequestAccessNeeded: "common.requestAccessNeeded",
  SubmitRequest: "common.submitRequest",
  RequestDeleteConfirmationText: "common.requestDeleteConfirmationText",
  InsurancePolicyDeleteConfirmationText: "common.insurancePolicyDeleteConfirmationText",
  InsurancePolicyDeleteLoadingText: "common.insurancePolicyDeleteLoadingText",
  RequestDeleteLoadingText: "common.requestDeleteLoadingText",
  RequestDeleteSuccessText: "common.requestDeleteSuccessText",
  InsurancePolicyDeleteSuccessText: "common.insurancePolicyDeleteSuccessText",
  ActiveRequestDescriptionYouHave: "common.activeRequestDescriptionYouHave",
  ActiveRequestDescriptionForThisLease: "common.activeRequestDescriptionForThisLease",
  DefaultProperty: "common.defaultProperty",
  DefaultPropertyDescription: "common.defaultPropertyDescription",
  MyNewString: "common.myNewString",
  DeleteTransactionConfirmationText: "common.deleteTransactionConfirmationText",
  DeletingTransaction: "common.deletingTransaction",
  VendorDeleted: "common.vendorDeleted",
  ProspectDeleted: "common.prospectDeleted",
  OwnerDeleted: "common.ownerDeleted",
  TransactionDeleted: "common.transactionDeleted",
  InsurancePolicy: "common.insurancePolicy",
  InsurancePolicies: "common.insurancePolicies",
  Budgets: "common.budgets",
  InsuranceProviderDescription: "common.insuranceProviderDescription",
  ProviderName: "common.providerName",
  LiabilityCoverageDescription: "common.liabilityCoverageDescription",
  LiabilityCoverage: "common.liabilityCoverage",
  InsurancePeriodDescription: "common.insurancePeriodDescription",
  ProofOfInsurance: "common.proofOfInsurance",
  ProofOfInsuranceDescription: "common.proofOfInsuranceDescription",
  ActivePolicy: "common.activePolicy",
  InactivePolicy: "common.inactivePolicy",
  InsuranceActive: "common.insuranceActive",
  LastSeen: "common.lastSeen",
  LoginEmail: "common.loginEmail",
  SendInvite: "common.sendInvite",
  BankVerificationStatusPart1: "common.bankVerificationStatusPart1",
  BankVerificationStatusPart2: "common.bankVerificationStatusPart2",
  BankVerificationRequestedDate: "common.bankVerificationRequestedDate",
  BankVerificationTestExpectedAt: "common.bankVerificationTestExpectedAt",
  CouldNotGetTenantPaymentTokens: "common.couldNotGetTenantPaymentTokens",
  ViewOnlyRecurringPaymentTitle: "common.viewOnlyRecurringPaymentTitle",
  ViewOnlyRecurringPaymentTitleDescription: "common.viewOnlyRecurringPaymentTitleDescription",
  ReturnedPaymentChargesNegativeLines: "common.returnedPaymentChargesNegativeLines",
  ViewOnlyPaymentTitle: "common.viewOnlyPaymentTitle",
  ViewOnlyPaymentDescription: "common.viewOnlyPaymentDescription",
  TransactionId: "common.transactionId",
  AccountInactiveMessage: "common.accountInactiveMessage",
  RentRemindersTitle: "common.rentRemindersTitle",
  RentRemindersTitleDescription: "common.rentRemindersTitleDescription",
  UseCompanyDefaultRentReminders: "common.useCompanyDefaultRentReminders",
  SpecifyRentRemindersForProperty: "common.specifyRentRemindersForProperty",
  RentRemindersDialogInstructions: "common.rentRemindersDialogInstructions",
  PaymentInstructionsDialogInstructions: "common.paymentInstructionsDialogInstructions",
  RentChargeNotifications: "common.rentChargeNotifications",
  RentReminders: "common.rentReminders",
  UpcomingRent: "common.upcomingRent",
  RentChargeNotificationsDescription: "common.rentChargeNotificationsDescription",
  PostRecurringChargesBeforeDueDate: "common.postRecurringChargesBeforeDueDate",
  RentChargeTextMessageNotificationsDescription: "common.rentChargeTextMessageNotificationsDescription",
  RentChargeDoNotSendReminderIfTenantAlreadyPaid: "common.rentChargeDoNotSendReminderIfTenantAlreadyPaid",
  RentNotificationSendEmailWhenRentIsCharged: "common.rentNotificationSendEmailWhenRentIsCharged",
  RentNotificationSendTextMessageWhenRentIsCharged: "common.rentNotificationSendTextMessageWhenRentIsCharged",
  RentNotificationSendEmailWhenRentIsChargedAttachStatement:
    "common.rentNotificationSendEmailWhenRentIsChargedAttachStatement",
  RentNotificationSendEmailWhenRentIsChargedAttachInvoice:
    "common.rentNotificationSendEmailWhenRentIsChargedAttachInvoice",
  RentNotificationSendEmailWhenRentIsChargedAttachCashPaymentSlip:
    "common.rentNotificationSendEmailWhenRentIsChargedAttachCashPaymentSlip",
  DaysBeforeRentIsDue: "common.daysBeforeRentIsDue",

  PaymentReceivedNotifyUsersByEmail: "common.paymentReceivedNotifyUsersByEmail",
  PaymentReceivedNotifications: "common.paymentReceivedNotifications",
  PaymentReceivedNotifyByEmail: "common.paymentReceivedNotifyByEmail",
  PaymentInstructionsSettingsDescription: "common.paymentInstructionsSettingsDescription",
  PaymentMethodIdPrefix: {
    CreditCard: "common.paymentMethodIdPrefix.creditCard",
    CashPayment: "common.paymentMethodIdPrefix.cashPayment",
    MTCN: "common.paymentMethodIdPrefix.mtcn"
  },
  UseCompanyDefaultPaymentInstructions: "common.useCompanyDefaultPaymentInstructions",
  SpecifyPaymentInstructionsForProperty: "common.specifyPaymentInstructionsForProperty",
  PaymentInstructionsLabel: "common.paymentInstructionsLabel",
  SelectUsers: "common.selectUsers",
  SelectUser: "common.selectUser",
  TenantRequestsSettingItemTitle: "common.tenantRequestsSettingItemTitle",
  TenantRequestsSettingItemDescription: "common.tenantRequestsSettingItemDescription",
  OwnerRequestsSettingItemTitle: "common.ownerRequestsSettingItemTitle",
  OwnerRequestsSettingItemDescription: "common.ownerRequestsSettingItemDescription",
  TenantPortalNotificationsDialogInstructions: "common.tenantPortalNotificationsDialogInstructions",
  UseCompanyDefaultTenantPortalNotifications: "common.useCompanyDefaultTenantPortalNotifications",
  SpecifyTenantPortalNotificationsForProperty: "common.specifyTenantPortalNotificationsForProperty",
  TenantRequestsSettings: "common.tenantRequestsSettings",
  OwnerRequestsSettings: "common.ownerRequestsSettings",
  NotifyTenantOnReceived: "common.notifyTenantOnReceived",
  NotifyOwnerOnReceived: "common.notifyOwnerOnReceived",
  NotifyAssignedOnReceived: "common.notifyAssignedOnReceived",
  AutomaticallyAssignUsers: "common.automaticallyAssignUsers",
  OwnerRequestNotifyAssignedOnReceived: "common.ownerRequestNotifyAssignedOnReceived",
  OwnerRequestAutomaticallyAssignUsers: "common.ownerRequestAutomaticallyAssignUsers",
  PaymentReversedNotifications: "common.paymentReversedNotifications",
  TextMessages: "common.textMessages",
  PaymentReversedNotifyByEmail: "common.paymentReversedNotifyByEmail",
  DoNotSentNotificationsAsTextMessages: "common.doNotSentNotificationsAsTextMessages",
  DoNotSentNotificationsAsTextMessagesRentReminders: "common.doNotSentNotificationsAsTextMessagesRentReminders",
  SendCashPaymentDetailsUponMoveIn: "common.sendCashPaymentDetailsUponMoveIn",
  CashPaymentMethodNotSelected: "common.cashPaymentMethodNotSelected",
  PaymentReversedNotifyByTextMessage: "common.paymentReversedNotifyByTextMessage",
  PaymentReversedNotifyUsersByEmail: "common.paymentReversedNotifyUsersByEmail",
  ResendInvitation: "common.resendInvitation",
  CreditDate: "common.creditDate",
  LeaseLateFeesSwitchLabel: "common.leaseLateFeesSwitchLabel",
  SelectAccounts: "common.selectAccounts",
  PaymentHasBeenReversed: "common.paymentHasBeenReversed",
  On: "common.on",
  PaymentReversedDescription: "common.paymentReversedDescription",
  PaymentHasBeenDeposited: "common.paymentHasBeenDeposited",
  DepositedPaymentDescription: "common.depositedPaymentDescription",
  ViewDeposit: "common.viewDeposit",
  AdditionalQuestions: "common.additionalQuestions",
  AnswerType: "common.answerType",
  AddQuestion: "common.addQuestion",
  Question: "common.question",
  AdditionalAttachments: "common.additionalAttachments",
  AttachmentIsRequired: "common.attachmentIsRequired",
  AddAttachment: "common.addAttachment",
  AddAnotherFile: "common.addAnotherFile",
  AddFile: "common.addFile",
  RequiredToSubmit: "common.requiredToSubmit",
  MarkAsMandatory: "common.markAsMandatory",
  AttachmentName: "common.attachmentName",
  Instructions: "common.instructions",
  HideSectionForProperty: "common.hideSectionForProperty",
  SpecifySettingsForProperty: "common.specifySettingsForProperty",
  FullName: "common.fullName",
  FreeTrialOfferDescription: "common.freeTrialOfferDescription",
  StartUsingTheSoftware: "common.startUsingTheSoftware",
  NoThanks: "common.noThanks",
  RentalApplicationSettings: "common.rentalApplicationSettings",
  RentalApplicationSettingsDescription: "common.rentalApplicationSettingsDescription",
  MerchantAccountRequired: "common.merchantAccountRequired",
  MerchantAccountMissing: "common.merchantAccountMissing",
  SetPropertyMerchantAccount: "common.setPropertyMerchantAccount",
  MissingMerchantAccount: "common.missingMerchantAccount",
  MissingMerchantAccountDescription: "common.missingMerchantAccountDescription",
  ApplyForMerchantAccount: "common.applyForMerchantAccount",
  ChargeApplicantsApplicationFee: "common.chargeApplicantsApplicationFee",
  ApplicationFeeAmountDescription: "common.applicationFeeAmountDescription",
  ApplicationFeeAmountLabel: "common.applicationFeeAmountLabel",
  ApplicationFeeAmountError: "common.applicationFeeAmountError",
  RequirePayRentalApplicationFeeOnline: "common.requirePayRentalApplicationFeeOnline",
  OnlinePaymentCreditCardFee: "common.onlinePaymentCreditCardFee",
  TransunionReport: "common.transunionReport",
  MoreInformationRequired: "common.moreInformationRequired",
  ApplicationFeeSummary: "common.applicationFeeSummary",
  OnlineCreditCardProcesingLabel: "common.onlineCreditCardProcesingLabel",
  FinalCost: "common.finalCost",
  YourProfit: "common.yourProfit",
  TransunionReportsCost: "common.transunionReportsCost",
  RequestTransunionReport: "common.requestTransunionReport",
  IAccept: "common.iAccept",
  And: "common.and",
  TransunionName: "common.transunionName",
  TermsAndConditions: "common.termsAndConditions",
  TransunionInvalidLocale: "common.transunionInvalidLocale",
  SearchApplications: "common.searchApplications",
  RentalApplicationsEmptyDataViewInstructionsText: "common.rentalApplicationsEmptyDataViewInstructionsText",
  RentalApplication: "common.rentalApplication",
  IdentityNotVerified: "common.identityNotVerified",
  InvalidAddress: "common.invalidAddress",
  SelectProspect: "common.selectProspect",
  PropertyRentalApplicationsNotSet: "common.propertyRentalApplicationsNotSet",
  PropertyRentalApplicationsNotSetDescription: "common.propertyRentalApplicationsNotSetDescription",
  ReviewPropertyRentalApplicationSettings: "common.reviewPropertyRentalApplicationSettings",
  AutomaticallyUpdateProspect: "common.automaticallyUpdateProspect",
  PendingVerification: "common.pendingVerification",
  ReportsAvailable: "common.reportsAvailable",
  ReadyForReportsRequest: "common.readyForReportsRequest",
  ReportExpired: "common.reportExpired",
  ExpectedMoveIn: "common.expectedMoveIn",
  RelatedApplications: "common.relatedApplications",
  Join: "common.join",
  Decision: "common.decision",
  DecisionNotes: "common.decisionNotes",
  DecisionUpdatedBy: "common.decisionUpdatedBy",
  DecisionUpdatedAt: "common.decisionUpdatedAt",
  UpdateDecision: "common.updateDecision",
  SafeguardYourInvestmentWithDoorLoop: "common.safeguardYourInvestmentWithDoorLoop",
  RentalApplicationDeleteConfirmationText: "common.rentalApplicationDeleteConfirmationText",
  RentalApplicationDeleteLoadingText: "common.rentalApplicationDeleteLoadingText",
  RentalApplicationDeleteSuccessText: "common.rentalApplicationDeleteSuccessText",
  RentalApplicationSendRentalApplication: "common.rentalApplicationSendRentalApplication",
  RentalApplicationSendFailure: "common.rentalApplicationSendFailure",
  RentalApplicationReminderSendFailure: "common.rentalApplicationReminderSendFailure",
  UnitCurrentlyListedError: "common.unitCurrentlyListedError",
  RentalApplicationReminderSent: "common.rentalApplicationReminderSent",
  RentalApplicationReportStatus: "common.rentalApplicationReportStatus",
  RentalApplicationStatus: "common.rentalApplicationStatus",
  RentalApplicationAvoidLossesAndHeadaches: "common.rentalApplicationAvoidLossesAndHeadaches",
  RentalApplicationLearnMoreAboutDLScreeningProcess: "common.rentalApplicationLearnMoreAboutDLScreeningProcess",
  RentalApplicationMadeEasyWithDoorLoop: "common.rentalApplicationMadeEasyWithDoorLoop",
  RentalApplicationStreamlinedProcess: "common.rentalApplicationStreamlinedProcess",
  RentalApplicationEffortlessly: "common.rentalApplicationEffortlessly",
  CriminalHistory: "common.criminalHistory",
  SummarizedCreditRisk: "common.summarizedCreditRisk",
  EvictionRecord: "common.evictionRecord",
  BrowseReportSamples: "common.browseReportSamples",
  InviteTenantsAndProspectsToApply: "common.inviteTenantsAndProspectsToApply",
  DecisionStatusDialogDescription: "common.decisionStatusDialogDescription",
  DecisionStatusDialogWarningText: "common.decisionStatusDialogWarningText",
  DecisionStatusDialogWarningTitleText: "common.decisionStatusDialogWarningTitleText",
  DesiredMoveInDate: "common.desiredMoveInDate",
  ApplyAsTenant: "common.applyAsTenant",
  ApplyAsCoSigner: "common.applyAsCoSigner",
  applyingAlone: "common.applyingAlone",
  ApplyingWidthCoApplicants: "common.applyingWidthCoApplicants",
  WhoAreYouMovingInWith: "common.whoAreYouMovingInWith",
  ApplyingAsTenantOrCoSigner: "common.applyingAsTenantOrCoSigner",
  ShareApplication: "common.shareApplication",
  ShareApplicationDescription: "common.shareApplicationDescription",
  InviteCoApplicants: "common.inviteCoApplicants",
  PublicLink: "common.publicLink",
  PublicLinkDescription: "common.publicLinkDescription",
  CopyLink: "common.copyLink",
  TextCopied: "common.textCopied",
  Identification: "common.identification",
  Gender: "common.gender",
  BioLabel: "common.bioLabel",
  OneOrMorePets: "common.oneOrMorePets",
  NoPets: "common.noPets",
  PetInformation: "common.petInformation",
  DependentInformation: "common.dependentInformation",
  PetName: "common.petName",
  PetType: "common.petType",
  PetBreed: "common.petBreed",
  PetColor: "common.petColor",
  PetAge: "common.petAge",
  PetWeight: "common.petWeight",
  ServiceAnimal: "common.serviceAnimal",
  SpayedOrNeutered: "common.spayedOrNeutered",
  MyPetIsSpayedOrNeutered: "common.myPetIsSpayedOrNeutered",
  MyPetIsServiceAnimal: "common.myPetIsServiceAnimal",
  AddAnotherPet: "common.addAnotherPet",
  OneOrMoreVehicles: "common.oneOrMoreVehicles",
  NoVehicles: "common.noVehicles",
  Vehicles: "common.vehicles",
  VehicleMake: "common.vehicleMake",
  VehicleModel: "common.vehicleModel",
  VehicleYear: "common.vehicleYear",
  VehicleColor: "common.vehicleColor",
  LicensePlate: "common.licensePlate",
  AddAnotherVehicle: "common.addAnotherVehicle",
  DoYouHaveAnyPets: "common.doYouHaveAnyPets",
  DoYouHaveAnyVehicles: "common.doYouHaveAnyVehicles",
  DoYouHaveAnyDependants: "common.doYouHaveAnyDependants",
  OneOrMoreDependants: "common.oneOrMoredependants",
  NoDependants: "common.noDependants",
  AddAnotherDependent: "common.addAnotherDependent",
  DependantInformation: "common.dependantInformation",
  EmploymentInformation: "common.employmentInformation",
  TitleOrPosition: "common.titleOrPosition",
  JobType: "common.jobType",
  AnnualIncome: "common.annualIncome",
  CurrentlyWorkHere: "common.currentlyWorkHere",
  SupervisorInformation: "common.supervisorInformation",
  SupervisorName: "common.supervisorName",
  SupervisorEmail: "common.supervisorEmail",
  SupervisorPhone: "common.supervisorPhone",
  IncomeInformation: "common.incomeInformation",
  Source: "common.source",
  PreviousAddress: "common.previousAddress",
  YourCurrentAddress: "common.yourCurrentAddress",
  OwnProperty: "common.ownProperty",
  RentProperty: "common.rentProperty",
  MonthlyPayment: "common.monthlyPayment",
  LandlordOrCompanyName: "common.landlordOrCompanyName",
  ContactPhone: "common.contactPhone",
  ContactEmail: "common.contactEmail",
  ContactPerson: "common.contactPerson",
  ContactName: "common.contactName",
  CurrentlyLiveHere: "common.currentlyLiveHere",
  ReasonForLeaving: "common.reasonForLeaving",
  MaritalStatus: "common.maritalStatus",
  ReviewAndPayment: "common.reviewAndPayment",
  CreditCard: "common.creditCard",
  CreditCardOrDebitCard: "common.creditCardOrDebitCard",
  ConfirmYourIdentity: "common.confirmYourIdentity",
  ExamQuestionsDescription: "common.examQuestionsDescription",
  ExamAnswerValidationText: "common.examAnswerValidationText",
  SubmitExamFailed: "common.submitExamFailed",
  ViewReports: "common.viewReports",
  UnFilledApplication: "common.unFilledApplication",
  UnFilledApplicationDescription: "common.unFilledApplicationDescription",
  UnFilledRentalApplication: "common.unFilledRentalApplication",
  SendRentalApplicationEmptyDescription: "common.sendRentalApplicationEmptyDescription",
  UnFilledRentalApplicationDescription: "common.unFilledRentalApplicationDescription",
  RentalApplicationIsNotConfigured: "common.rentalApplicationIsNotConfigured",
  RentalApplicationUnitIsNotOpenForListingTooltip: "common.rentalApplicationUnitIsNotOpenForListingTooltip",
  RentalApplicationInProgressTooltip: "common.rentalApplicationInProgressTooltip",
  RentalApplicationSubmitted: "common.rentalApplicationSubmitted",
  RentalApplicationApplicantIdentityIsNotValidText: "common.rentalApplicationApplicantIdentityIsNotValidText",
  RentalApplicationApplicantAddressIsNotValidText: "common.rentalApplicationApplicantAddressIsNotValidText",
  PhoneNumber: "common.phoneNumber",
  TotalIncome: "common.totalIncome",
  Spayed: "common.spayed",
  NotSpayed: "common.notSpayed",
  Residence: "common.residence",
  Own: "common.own",
  Rent: "common.rent",
  MoveOut: "common.moveOut",
  MoveInMoveOut: "common.moveInMoveOut",
  Present: "common.present",
  Year: "common.year",
  ReportsWereNotRequested: "common.reportsWereNotRequested",
  RentalApplicationApplicantAddressNotValidTitle: "common.rentalApplicationApplicantAddressNotValidTitle",
  RentalApplicationApplicantAddressNotValidDescription: "common.rentalApplicationApplicantAddressNotValidDescription",
  ReportsWereNotRequestedDescription: "common.reportsWereNotRequestedDescription",
  OpenRentalApplicationSettings: "common.openRentalApplicationSettings",
  UnableToVerifyIdentity: "common.unableToVerifyIdentity",
  ReportsCouldNotVerifiedDescription: "common.reportsCouldNotVerifiedDescription",
  OnceTheyReviewRequestSubmit: "common.onceTheyReviewRequestSubmit",
  ReportsCouldNotVerified: "common.reportsCouldNotVerified",
  ReportsDeliveryFailed: "common.reportsDeliveryFailed",
  ReportsDeliveryFailedDescription: "common.reportsDeliveryFailedDescription",
  UnableToVerifyIdentityDescription: "common.unableToVerifyIdentityDescription",
  UnableToVerifyIdentityDescriptionForProspect: "common.unableToVerifyIdentityDescriptionForProspect",
  IdentityVerified: "common.identityVerified",
  IdentityVerifiedDescription: "common.identityVerifiedDescription",
  RequestReports: "common.requestReports",
  CanRequestScreeningReports: "common.canRequestScreeningReports",
  ChooseAProspectWhoWillReceiveTheApplication: "common.chooseAProspectWhoWillReceiveTheApplication",
  AccessReportsAfterPayment: "common.accessReportsAfterPayment",
  TransunionReportFee: "common.transunionReportFee",
  TransunionScreeningReports: "common.transunionScreeningReports",
  ReportsProcessedIn2Days: "common.reportsProcessedIn2Days",
  PayAndRequestReports: "common.payAndRequestReports",
  CardInformation: "common.cardInformation",
  IdentityVerifiedSuccessfully: "common.identityVerifiedSuccessfully",
  ReportsGeneratedInProgress: "common.reportsGeneratedInProgress",
  ReportsGeneratedInProgressDescription: "common.reportsGeneratedInProgressDescription",
  ReportExpiredOn: "common.reportExpiredOn",
  ReportExpiredOnDescription: "common.reportExpiredOnDescriptiOn",
  CriminalReport: "common.criminalReport",
  CreditReport: "common.creditReport",
  EvictionReport: "common.evictionReport",
  CriminalReportDescription: "common.criminalReportDescription",
  CreditReportDescription: "common.creditReportDescription",
  EvictionReportDescription: "common.evictionReportDescription",
  ExpiresIn: "common.expiresIn",
  ExpiresAt: "common.expiresAt",
  ApplicationHasBeenSubmitted: "common.applicationHasBeenSubmitted",
  ApplicationHasBeenSubmittedDescription: "common.applicationHasBeenSubmittedDescription",
  ViewCompanyInformation: "common.viewCompanyInformation",
  ViewRentalApplicationSettings: "common.viewRentalApplicationSettings",
  LandlordInformation: "common.landlordInformation",
  Report: "common.report",
  DeletePolicy: "common.deletePolicy",
  RecommendedWithIcon: "common.recommendedWithIcon",
  Cleared: "common.cleared",
  ShowBeginningBalance: "common.showBeginningBalance",
  NotCleared: "common.notCleared",
  ConnectNow: "common.connectNow",
  BankConnectDialogHeaderDescription: "common.bankConnectDialogHeaderDescription",
  BankConnectRepairConnection: "common.bankConnectRepairConnection",
  BankConnectRefreshTransactions: "common.bankConnectRefreshTransactions",
  YouHave: "common.youHave",
  Refresh: "common.refresh",
  ConnectYourAccounts: "common.connectYourAccounts",
  ConnectYourAccountsDescription: "common.connectYourAccountsDescription",
  ConnectAccount: "common.connectAccount",
  ConnectAnotherAccount: "common.connectAnotherAccount",
  Manual: "common.manual",
  ManualEntries: "common.manualEntries",
  AsOf: "common.asOf",
  Updating: "common.updating",
  Repairing: "common.repairing",
  Reauthenticate: "common.reauthenticate",
  Review: "common.review",
  DisconnectBankAccount: "common.disconnectBankAccount",
  Reconnect: "common.reconnect",
  RepairConnection: "common.repairConnection",
  ConfirmRepairConnectionPart1: "common.confirmRepairConnectionPart1",
  ConfirmRepairConnectionPart2: "common.confirmRepairConnectionPart2",
  SyncError: "common.syncError",
  BankConnectionNeedsRepair: "common.bankConnectionNeedsRepair",
  BankConnectionNeedsReauthentication: "common.bankConnectionNeedsReauthentication",
  UnreconciledTransactions: "common.unreconciledTransactions",
  CheckListScreenHeaderDescription: "common.checkListScreenHeaderDescription",
  BalanceUpdated: "common.balanceUpdated",
  LastUpdated: "common.lastUpdated",
  MatchedTransaction: "common.matchedTransaction",
  RecordNewExpense: "common.recordNewExpense",
  RecordNewDeposit: "common.recordNewDeposit",
  RecordNewTransfer: "common.recordNewTransfer",
  MatchExistingTransaction: "common.matchExistingTransaction",
  MatchExistingTransactionExplainer: "common.matchExistingTransactionExplainer",
  NewExpenseTotal: "common.newExpenseTotal",
  NewDepositTotal: "common.newDepositTotal",
  Difference: "common.difference",
  ExcludeTooltip: {
    Header: "common.excludeTooltip.header",
    Description: "common.excludeTooltip.description"
  },
  Exclude: "common.exclude",
  Excluded: "common.excluded",
  MatchExpenseValidationMessage: "common.matchExpenseValidationMessage",
  MatchDepositValidationMessage: "common.matchDepositValidationMessage",
  Match: "common.match",
  Categorized: "common.categorized",
  Undo: "common.undo",
  MatchTransactions: "common.matchTransactions",
  MatchSummary: "common.matchSummary",
  BankTransaction: "common.bankTransaction",
  MatchedTransactions: "common.matchedTransactions",
  MatchedTransactionsValidationMessage: "common.matchedTransactionsValidationMessage",
  Pending: "common.pending",
  Expired: "common.expired",
  FailedToUploadAttachments: "common.failedToUploadAttachments",
  OpeningBalanceRequired: "common.openingBalanceRequired",
  OpeningBalance: "common.openingBalance",
  OpeningBalanceDateChangeInfo: "common.openingBalanceDateChangeInfo",
  OpeningBalanceRequiredDescription: "common.openingBalanceRequiredDescription",
  SetOpeningBalance: "common.setOpeningBalance",
  QuickbooksSyncToggleTitle: "common.quickbooksSyncToggleTitle",
  QuickbooksSyncToggleDescription: "common.quickbooksSyncToggleDescription",
  SyncStartDate: "common.syncStartDate",
  SyncStartDateDescription: "common.syncStartDateDescription",
  PleaseNote: "common.pleaseNote",
  Attention: "common.attention",
  WeRecommendYou: "common.weRecommendYou",
  LearnQuickbooksSync: "common.learnQuickbooksSync",
  BeforeTurningOnIntegration: "common.beforeTurningOnIntegration",
  ReadOurKnowledgeBaseArticle: "common.readOurKnowledgeBaseArticle",
  QuickbooksIntegrationEnabled: "common.quickbooksIntegrationEnabled",
  QuickbooksIntegrationEnabledDescription: "common.quickbooksIntegrationEnabledDescription",
  Confirm: "common.confirm",
  NeedsReview: "common.needsReview",
  AutoMatched: "common.autoMatched",
  DeletePaymentTokenConfirmationText: "common.deletePaymentTokenConfirmationText",
  ActiveAutoPay: "common.activeAutoPay",
  MakeAnotherPaymentQuestion: "common.makeAnotherPaymentQuestion",
  MakeAnotherAutoPayQuestion: "common.makeAnotherAutoPayQuestion",
  ActiveAutoPayDescription: "common.activeAutoPayDescription",
  MakeAnotherPayment: "common.makeAnotherPayment",
  SetupAnotherAutoPay: "common.setupAnotherAutoPay",
  ViewAutoPayDetails: "common.viewAutoPayDetails",
  ViewAutoPaySettings: "common.viewAutoPaySettings",
  YourNextRecurringChargeOf: "common.yourNextRecurringChargeOf",
  IsDuOn: "common.isDuOn",
  DueOn: "common.dueOn",
  ViewPolicy: "common.viewPolicy",
  DragFilesOrClickHereToUpload: "common.dragFilesOrClickHereToUpload",
  Select: "common.select",
  CardBenefit1: "common.cardBenefit1",
  CardBenefit2: "common.cardBenefit2",
  CardBenefit3: "common.cardBenefit3",
  BankBenefit1: "common.bankBenefit1",
  BankBenefit2: "common.bankBenefit2",
  PayWithCard: "common.payWithCard",
  PayWithBank: "common.payWithBank",
  PayWithCash: "common.payWithCash",
  PaymentMethodStep: {
    StripeDisclaimer: {
      Title: "common.paymentMethodStep.stripeDisclaimer.title",
      Description: "common.paymentMethodStep.stripeDisclaimer.description",
      PoweredBy: "common.paymentMethodStep.stripeDisclaimer.poweredBy"
    },
    BankAccountLearnMoreDialog: {
      LearnMore: "common.learnMore",
      Title: "common.paymentMethodStep.bankAccountLearnMoreDialog.title",
      Description: {
        Part1: "common.paymentMethodStep.bankAccountLearnMoreDialog.description.part1",
        Part2: "common.paymentMethodStep.bankAccountLearnMoreDialog.description.part2"
      }
    },
    CreditCardBanner: {
      Title: "common.paymentMethodStep.creditCardBanner.title",
      Points: {
        Point1: "common.paymentMethodStep.creditCardBanner.points.point1",
        Point2: "common.paymentMethodStep.creditCardBanner.points.point2",
        Point3: "common.paymentMethodStep.creditCardBanner.points.point3"
      }
    }
  },
  TransactionDate: "common.transactionDate",
  AmountMustBeGreaterThanZero: "common.amountMustBeGreaterThanZero",
  TenantRequestSettingsInstructionsDescription: "common.tenantRequestSettingsInstructionsDescription",
  TenantRequestSettingsInstructions: "common.tenantRequestSettingsInstructions",
  OwnerRequestSettingsInstructions: "common.ownerRequestSettingsInstructions",
  OwnerRequestSettingsInstructionsDescription: "common.ownerRequestSettingsInstructionsDescription",
  OwnerReportsInstructionsDescription: "common.ownerReportsInstructionsDescription",
  ActivityFeed: {
    ActivityFeed: "common.activityFeed.activityFeed",
    CreatedBy: "common.activityFeed.createdBy",
    CreatedByPersonType: "common.activityFeed.createdByPersonType",
    UpdateBy: "common.activityFeed.updateBy",
    DeletedBy: "common.activityFeed.updateBy",
    Request: "common.activityFeed.request",
    ViewAll: "common.activityFeed.viewAll",
    You: "common.activityFeed.you",
    PropertyManager: "common.activityFeed.propertyManager",
    VisibleToTenant: "common.activityFeed.visibleToTenant",
    DeletedField: "common.activityFeed.deletedField",
    ChangedFromTo: "common.activityFeed.changedFromTo",
    ChangedTo: "common.activityFeed.changedTo",
    CreatedBySystem: "common.activityFeed.createdBySystem"
  },
  TimeZone: "common.timeZone",
  TimeZoneDescription: "common.timeZoneDescription",
  Recurring: {
    OneTimeGeneralEntry: "common.recurring.oneTimeGeneralEntry",
    RecurringGeneralEntry: "common.recurring.recurringGeneralEntry",
    OneTimeTask: "common.recurring.oneTimeTask",
    RecurringTask: "common.recurring.recurringTask",
    RecurringTasks: "common.recurring.recurringTasks",
    OneTimeWorkOrder: "common.recurring.oneTimeWorkOrder",
    RecurringWorkOrder: "common.recurring.recurringWorkOrder",
    RecurringWorkOrders: "common.recurring.recurringWorkOrders",
    OneTimeBill: "common.recurring.oneTimeBill",
    RecurringBill: "common.recurring.recurringBill",
    OneTimeVendorBill: "common.recurring.oneTimeVendorBill",
    RecurringVendorBill: "common.recurring.recurringVendorBill",
    RecurringVendorBills: "common.recurring.recurringVendorBills",
    OneTimeExpense: "common.recurring.oneTimeExpense",
    RecurringExpense: "common.recurring.recurringExpense",
    RecurringExpenses: "common.recurring.recurringExpenses",
    NumberOfDaysUntilDue: "common.recurring.numberOfDaysUntilDue",
    RepeatForever: "common.recurring.repeatForever",
    Forever: "common.recurring.forever",
    RecurringBills: "common.recurring.recurringBills",
    RecurringTransactions: "common.recurring.recurringTransactions",
    EmptyDataViewInstructionsTextBillsExpenses: "common.recurring.emptyDataViewInstructionsTextBillsExpenses",
    EmptyDataViewInstructionsTextTasks: "common.recurring.emptyDataViewInstructionsTextTasks",
    ByX: "common.recurring.byX",
    ByXFromCreation: "common.recurring.byXFromCreation",
    Frequencies: {
      Daily: "common.recurring.frequencies.daily",
      Weekly: "common.recurring.frequencies.weekly",
      EveryTwoWeeks: "common.recurring.frequencies.everyTwoWeeks",
      Monthly: "common.recurring.frequencies.monthly",
      EveryTwoMonths: "common.recurring.frequencies.everyTwoMonths",
      Quarterly: "common.recurring.frequencies.quarterly",
      EverySixMonths: "common.recurring.frequencies.everySixMonths",
      Annually: "common.recurring.frequencies.annually"
    },
    HelpPanel: {
      Description: "common.recurring.helpPanel.description",
      CreateRecurringBill: "common.recurring.helpPanel.createRecurringBill",
      CreateRecurringExpense: "common.recurring.helpPanel.createRecurringExpense",
      EditDeleteRecurringBill: "common.recurring.helpPanel.editDeleteRecurringBill"
    }
  },
  OutgoingPayments: {
    NavTitle: "common.outgoingPayments.navTitle",
    Title: "common.outgoingPayments.title",
    OutgoingPayments: "common.outgoingPayments.outgoingPayments",
    EmailRecommended: "common.outgoingPayments.emailRecommended",
    MailCheck: "common.outgoingPayments.mailCheck",
    DirectDeposit: "common.outgoingPayments.directDeposit",
    PaymentSendingMethod: "common.outgoingPayments.paymentSendingMethod",
    EnableOutgoingPaymentsForThisTenant: "common.outgoingPayments.enableOutgoingPaymentsForThisTenant",
    EnableOutgoingPaymentsForThisVendor: "common.outgoingPayments.enableOutgoingPaymentsForThisVendor",
    EnableOutgoingPaymentsForThisOwner: "common.outgoingPayments.enableOutgoingPaymentsForThisOwner",
    EmailInfoDescription: "common.outgoingPayments.emailInfoDescription",
    EmailInfoDescription2: "common.outgoingPayments.emailInfoDescription2",
    TheyCanChooseBetween: "common.outgoingPayments.theyCanChooseBetween",
    DirectDepositSummary: "common.outgoingPayments.directDepositSummary",
    PrintAndDeposit: "common.outgoingPayments.printAndDeposit",
    PrintAndDepositDesc: "common.outgoingPayments.printAndDepositDesc",
    EmailSettings: "common.outgoingPayments.emailSettings",
    BillingEmail: "common.outgoingPayments.billingEmail",
    AskThePayeeForAPIN: "common.outgoingPayments.askThePayeeForAPIN",
    PINCode: "common.outgoingPayments.PINCode",
    HintWillBeVisible: "common.outgoingPayments.hintWillBeVisible",
    LastUpdatedByOnFrom: "common.outgoingPayments.lastUpdatedByOnFrom",
    MailCheckInfoDescription: "common.outgoingPayments.mailCheckInfoDescription",
    DirectDepositInfoDescription: "common.outgoingPayments.directDepositInfoDescription",
    DirectDepositInfoDescription2: "common.outgoingPayments.directDepositInfoDescription2",
    AccountSettings: "common.outgoingPayments.accountSettings",
    HelpHint: "common.outgoingPayments.helpHint",
    EnableOutgoingPayments: "common.outgoingPayments.enableOutgoingPayments",
    ConnectToCheckbookDesc1: "common.outgoingPayments.connectToCheckbookDesc1",
    ConnectToCheckbookDesc2: "common.outgoingPayments.connectToCheckbookDesc2",
    ConnectToCheckbookDesc3: "common.outgoingPayments.connectToCheckbookDesc3",
    ConnectToCheckbook: "common.outgoingPayments.connectToCheckbook",
    LearnMoreAboutCheckbook: "common.outgoingPayments.learnMoreAboutCheckbook",
    SendingLimits: "common.outgoingPayments.sendingLimits",
    EnableOutgoingPaymentsWithCheckbookIO: "common.outgoingPayments.enableOutgoingPaymentsWithCheckbookIO",
    MapAccounts: "common.outgoingPayments.mapAccounts",
    MapAccountsDesc: "common.outgoingPayments.mapAccountsDesc",
    SelectDoorLoopAccount: "common.outgoingPayments.selectDoorLoopAccount",
    CreateNewCheckbookAccount: "common.outgoingPayments.createNewCheckbookAccount",
    DidYouKnow: "common.outgoingPayments.didYouKnow",
    OutgoingPaymentsInfoDidYouKnow: "common.outgoingPayments.outgoingPaymentsInfoDidYouKnow",
    DuplicateAccountMappingErrorMessage: "common.outgoingPayments.duplicateAccountMappingErrorMessage",
    CheckbookApiSecret: "common.outgoingPayments.checkbookApiSecret",
    CheckbookApiKey: "common.outgoingPayments.checkbookApiKey",
    GetCheckbookAPIkeys: "common.outgoingPayments.getCheckbookAPIkeys",
    GetCheckbookAPIkeysDescription: "common.outgoingPayments.getCheckbookAPIkeysDescription",
    CreateCheckbookAccount: "common.outgoingPayments.createCheckbookAccount",
    CreateCheckbookAccountDescription: "common.outgoingPayments.createCheckbookAccountDescription",
    SearchPayments: "common.outgoingPayments.searchPayments",
    ViaPhysicalCheck: "common.outgoingPayments.viaPhysicalCheck",
    ViaDirectDeposit: "common.outgoingPayments.viaDirectDeposit",
    AddressSettings: "common.outgoingPayments.addressSettings",
    MissingPaymentInfo: "common.outgoingPayments.missingPaymentInfo",
    Recipient: "common.outgoingPayments.recipient",
    Paid: "common.outgoingPayments.paid",
    Sent: "common.outgoingPayments.sent",
    InProcess: "common.outgoingPayments.inProcess",
    Unpaid: "common.outgoingPayments.unpaid",
    Void: "common.outgoingPayments.void",
    Expired: "common.outgoingPayments.expired",
    Printed: "common.outgoingPayments.printed",
    Mailed: "common.outgoingPayments.mailed",
    Failed: "common.outgoingPayments.failed",
    Refunded: "common.outgoingPayments.refunded",
    Send: "common.outgoingPayments.send",
    Resend: "common.outgoingPayments.resend",
    Incoming: "common.outgoingPayments.incoming",
    Outgoing: "common.outgoingPayments.outgoing",
    OwnerOutgoingPaymentInfo: "common.outgoingPayments.ownerOutgoingPaymentInfo",
    TenantOutgoingPaymentInfo: "common.outgoingPayments.tenantOutgoingPaymentInfo",
    VendorOutgoingPaymentInfo: "common.outgoingPayments.vendorOutgoingPaymentInfo",
    OutgoingPaymentsSettingsDescription: "common.outgoingPayments.outgoingPaymentsSettingsDescription",
    AreYouSureYouWantToSendThisPayment: "common.outgoingPayments.areYouSureYouWantToSendThisPayment",
    PaymentHasBeenSent: "common.outgoingPayments.paymentHasBeenSent",
    VoidConfirmationText: "common.outgoingPayments.voidConfirmationText",
    VoidPaymentLoadingText: "common.outgoingPayments.voidPaymentLoadingText",
    AddInfo: "common.outgoingPayments.addInfo",
    UnverifiedAccount: "common.outgoingPayments.unverifiedAccount",
    EnsureBankVerified: "common.outgoingPayments.ensureBankVerified",
    UnnamedBankAccount: "common.outgoingPayments.unnamedBankAccount",
    PaymentReadyToSend: "common.outgoingPayments.paymentReadyToSend",
    PaymentReadyToSendDesc: "common.outgoingPayments.paymentReadyToSendDesc",
    SendPayment: "common.outgoingPayments.sendPayment",
    DigitalPaymentSent: "common.outgoingPayments.digitalPaymentSent",
    DigitalPaymentSentDesc: "common.outgoingPayments.digitalPaymentSentDesc",
    PhysicalPaymentProcessing: "common.outgoingPayments.physicalPaymentProcessing",
    PhysicalPaymentProcessingDesc: "common.outgoingPayments.physicalPaymentProcessingDesc",
    DigitalPaymentVoided: "common.outgoingPayments.digitalPaymentVoided",
    DigitalPaymentVoidedDesc: "common.outgoingPayments.digitalPaymentVoidedDesc",
    PhysicalPaymentFailed: "common.outgoingPayments.physicalPaymentFailed",
    PhysicalPaymentFailedDesc: "common.outgoingPayments.physicalPaymentFailedDesc",
    ResendPayment: "common.outgoingPayments.resendPayment",
    PleaseSetUpPayeesOutgoingPayments: "common.outgoingPayments.pleaseSetUpPayeesOutgoingPayments",
    Voided: "common.outgoingPayments.voided",
    DigitalPaymentPaid: "common.outgoingPayments.digitalPaymentPaid",
    PhysicalPaymentMailed: "common.outgoingPayments.physicalPaymentMailed",
    PaymentPaidDesc: "common.outgoingPayments.paymentPaidDesc",
    DigitalPaymentExpired: "common.outgoingPayments.digitalPaymentExpired",
    PhysicalPaymentExpired: "common.outgoingPayments.physicalPaymentExpired",
    PaymentExpiredDesc: "common.outgoingPayments.paymentExpiredDesc",
    DigitalPaymentRefunded: "common.outgoingPayments.digitalPaymentRefunded",
    PhysicalPaymentRefunded: "common.outgoingPayments.physicalPaymentRefunded",
    PaymentRefundedDesc: "common.outgoingPayments.paymentRefundedDesc",
    DigitalPaymentFailed: "common.outgoingPayments.digitalPaymentFailed",
    PaymentFailedDesc: "common.outgoingPayments.paymentFailedDesc",
    ModifyLimit: "common.outgoingPayments.modifyLimit",
    ModifyLimitDesc: "common.outgoingPayments.modifyLimitDesc",
    FindOutHowToIncreaseYouLimits: "common.outgoingPayments.findOutHowToIncreaseYouLimits",
    SubmitARequest: "common.outgoingPayments.submitARequest"
  },
  IncomingPayments: {
    NavTitle: "common.incomingPayments.navTitle"
  },
  fromDASHto: "common.fromDASHto",
  CreatedAtDate: "common.createdAtDate",
  UpdatedAtDate: "common.updatedAtDate",
  ApproveByOwner: "common.approveByOwner",
  PropertyAccountBalance: "common.propertyAccountBalance",
  PropertyAvailableFounds: "common.propertyAvailableFounds",
  VendorPayment: "common.vendorPayment",
  LineItems: "common.lineItems",
  PaymentWarningTitle: "common.paymentWarningTitle",
  PaymentWarningDescription: "common.paymentWarningDescription",
  SaveAndNew: "common.saveAndNew",
  RevenueAccount: "common.revenueAccount",
  SpecifyFeesForApplication: "common.specifyFeesForApplication",
  BankReconciliation: {
    Title: "common.bankReconciliation.title",
    Reconciled: "common.bankReconciliation.reconciled",
    NotReconciled: "common.bankReconciliation.notReconciled",
    NewBankReconciliation: "common.bankReconciliation.newBankReconciliation",
    BankReconciliationEmptyDataViewInstructionsText:
      "common.bankReconciliation.bankReconciliationEmptyDataViewInstructionsText",
    ReconcileAccount: "common.bankReconciliation.reconcileAccount",
    ReconcileAccountDescription: "common.bankReconciliation.reconcileAccountDescription",
    ReconciledAt: "common.bankReconciliation.reconciledAt",
    LearnMoreAboutReconciliation: "common.bankReconciliation.helpPanel.learnMoreAboutReconciliation",
    HelpPanel: {
      Description: "common.bankReconciliation.helpPanel.description",
      PreformReconciliation: "common.bankReconciliation.helpPanel.preformReconciliation",
      FixProblems: "common.bankReconciliation.helpPanel.fixProblems",
      Undo: "common.bankReconciliation.helpPanel.undo"
    }
  },
  NoOpeningBalance: "common.noOpeningBalance",
  NoOpeningBalanceDescription: "common.noOpeningBalanceDescription",
  AddBeginningBalance: "common.addBeginningBalance",
  StatementBeginningBalance: "common.statementBeginningBalance",
  LastStatementEndingDate: "common.lastStatementEndingDate",
  StatementEndingBalance: "common.statementEndingBalance",
  StatementEndingDate: "common.statementEndingDate",
  EndingBalance: "common.endingBalance",
  BeginningBalance: "common.beginningBalance",
  ClearedBalance: "common.clearedBalance",
  SaveForLater: "common.saveForLater",
  FinishReconciliation: "common.finishReconciliation",
  ResumeReconciliationNotificationTitle: "common.resumeReconciliationNotificationTitle",
  ResumeReconciliationNotificationDescription: "common.resumeReconciliationNotificationDescription",
  CreatedByUser: "common.createdByUser",
  UndoReconciliation: "common.undoReconciliation",
  ReconciliationReport: "common.reconciliationReport",
  NumberOfChecksAndPayments: "common.numberOfChecksAndPayments",
  NumberOfDepositsAndCredits: "common.numberOfDepositsAndCredits",
  BankStatementEndingBalance: "common.bankStatementEndingBalance",
  UnclearedTransactionsAsOf: "common.unclearedTransactionsAsOf",
  RegisterBalanceAsOf: "common.registerBalanceAsOf",
  ChangesNotIncluded: "common.changesNotIncluded",
  PeriodEnding: "common.periodEnding",
  ReconciledOn: "common.reconciledOn",
  ReconciledBy: "common.reconciledBy",
  DeleteReconciliationConfirmationText: "common.deleteReconciliationConfirmationText",
  ClearedTransactions: "common.clearedTransactions",
  ResumeReconciliation: "common.resumeReconciliation",
  TransactionReconciled: "common.transactionReconciled",
  TransactionReconciledDescription: "common.transactionReconciledDescription",
  ViewReconciliation: "common.viewReconciliation",
  UnclearedTransactions: "common.unclearedTransactions",
  WatchVideo: "common.watchVideo",
  TakeTour: "common.takeTour",
  LearnMoreAbout: "common.learnMoreAbout",
  WatchExample: "common.watchExample",
  ApiKeys: {
    ApiKeysDialogExplain: "common.apiKeys.apiKeysDialogExplain"
  },
  MerchantAccount: {
    MerchantAccount: "common.merchantAccount.merchantAccount",
    HelpPanel: {
      Description: "common.merchantAccount.helpPanel.description",
      Application: "common.merchantAccount.helpPanel.application",
      OnlinePayments: "common.merchantAccount.helpPanel.receivePayments",
      HowLong: "common.merchantAccount.helpPanel.howLong",
      DialogTitle: "common.merchantAccount.helpPanel.dialogTitle"
    }
  },
  BankConnect: {
    Title: "common.bankConnect.title",
    HelpPanel: {
      Description: "common.bankConnect.helpPanel.description",
      LearnMoreBankConnect: "common.bankConnect.helpPanel.establishConnect",
      ReconciliationDifference: "common.bankConnect.helpPanel.reconciliationDifference",
      PlaidCorrection: "common.bankConnect.helpPanel.plaidCorrection",
      DialogTitle: "common.bankConnect.helpPanel.dialogTitle"
    },
    Errors: {
      LoginRequired: "common.bankConnect.errors.loginRequired",
      RefreshTransactionsError: "common.bankConnect.errors.refreshTransactionsError",
      UnableToSync: "common.bankConnect.errors.unableToSync"
    }
  },
  Banking: {
    HelpPanel: {
      Description: "common.banking.helpPanel.description",
      CreateAccount: "common.banking.helpPanel.createAccount",
      PropertyConnect: "common.banking.helpPanel.propertyConnect",
      DialogTitle: "common.banking.helpPanel.dialogTitle"
    }
  },
  ManagementFees: {
    HelpPanel: {
      Description: "common.managementFees.helpPanel.description",
      CreateBills: "common.managementFees.helpPanel.createBills",
      HowToRedo: "common.managementFees.helpPanel.howToRedo",
      DialogTitle: "common.managementFees.helpPanel.dialogTitle"
    }
  },
  ProgressBar: "common.progressBar",
  WelcomeDialog: {
    DialogTitle: "common.welcomeDialog.dialogTitle",
    DialogDescription: "common.welcomeDialog.dialogDescription",
    DialogLetsStart: "common.welcomeDialog.dialogLetsStart",
    DismissDialog: "common.welcomeDialog.dismissDialog",
    AutoPay: "common.welcomeDialog.autoPay",
    RentersInsurance: "common.welcomeDialog.rentersInsurance",
    GetQuote: "common.welcomeDialog.getQuote"
  },
  LemonadePromoDialog: {
    DialogTitle: "common.lemonadePromoDialog.dialogTitle",
    DialogDescription: "common.lemonadePromoDialog.dialogDescription",
    GetAQuoteNow: "common.lemonadePromoDialog.getAQuoteNow",
    IAlreadyHaveInsurance: "common.lemonadePromoDialog.iAlreadyHaveInsurance",
    Promo1: {
      Title: "common.lemonadePromoDialog.promo1.title",
      SubTitle: "common.lemonadePromoDialog.promo1.subTitle"
    },
    Promo2: {
      Title: "common.lemonadePromoDialog.promo2.title",
      SubTitle: "common.lemonadePromoDialog.promo2.subTitle"
    },
    Promo3: {
      Title: "common.lemonadePromoDialog.promo3.title",
      SubTitle: "common.lemonadePromoDialog.promo3.subTitle"
    },
    Promo4: {
      Title: "common.lemonadePromoDialog.promo4.title",
      SubTitle: "common.lemonadePromoDialog.promo4.subTitle"
    }
  },
  NoActivePayments: "common.noActivePayments",
  NoActivePaymentsDescription: "common.noActivePaymentsDescription",
  SetupOutgoingPayments: "common.setupOutgoingPayments",
  NoPaymentRecords: "common.noPaymentRecords",
  PaymentInfoPlacement: "common.paymentInfoPlacement",
  SurePromoDialog: {
    DialogTitle: "common.surePromoDialog.dialogTitle",
    DialogDescription: "common.surePromoDialog.dialogDescription",
    GetAQuoteNow: "common.surePromoDialog.getAQuoteNow",
    IAlreadyHaveInsurance: "common.surePromoDialog.iAlreadyHaveInsurance",
    Promo1: {
      Title: "common.surePromoDialog.promo1.title",
      SubTitle: "common.surePromoDialog.promo1.subTitle"
    },
    Promo2: {
      Title: "common.surePromoDialog.promo2.title",
      SubTitle: "common.surePromoDialog.promo2.subTitle"
    },
    Promo3: {
      Title: "common.surePromoDialog.promo3.title",
      SubTitle: "common.surePromoDialog.promo3.subTitle"
    },
    Promo4: {
      Title: "common.surePromoDialog.promo4.title",
      SubTitle: "common.surePromoDialog.promo4.subTitle"
    }
  },
  CopyToClipboard: "common.copyToClipboard",
  StateSelectionDialog: {
    DialogTitle: "common.stateSelectionDialog.dialogTitle",
    SelectAState: "common.stateSelectionDialog.selectAState",
    ILiveOutsideUs: "common.stateSelectionDialog.iLiveOutsideUs"
  },
  InsuranceExpiresInDays: "common.insuranceExpiresInDays",
  RentalApplicationPreview: "common.rentalApplicationPreview",
  CancelPaymentDialog: {
    Title: "common.cancelPaymentDialog.title",
    AreYouSure: "common.cancelPaymentDialog.areYouSure",
    Warning: "common.cancelPaymentDialog.warning",
    Description: "common.cancelPaymentDialog.description"
  },
  GetDoorloopAppBanner: {
    Title: "common.getDoorloopAppBanner.title",
    Description: "common.getDoorloopAppBanner.description",
    GetApp: "common.getDoorloopAppBanner.getApp",
    ContinueToSite: "common.getDoorloopAppBanner.continueToSite"
  },
  ConfirmUnderstand: "common.confirmUnderstand",
  TryAgain: "common.tryAgain",
  Connected: "common.connected",
  NotConnected: "common.notConnected",
  BankNotConnected: "common.bankNotConnected",
  SendPayment: "common.sendPayment",
  SendPayments: "common.sendPayments",
  AcceptPayments: "common.acceptPayments",
  Enable: "common.enable",
  OnlinePaymentsAccountsList: {
    IdentityVerificationFailed: "common.onlinePaymentsAccountsList.identityVerificationFailed",
    IdentityVerificationNeeded: "common.onlinePaymentsAccountsList.identityVerificationNeeded",
    Apply: "common.onlinePaymentsAccountsList.apply",
    ApplicationWaitingForReview: "common.onlinePaymentsAccountsList.applicationWaitingForReview",
    InactiveMessage: "common.onlinePaymentsAccountsList.inactiveMessage",
    InactivePermissionMessage: "common.onlinePaymentsAccountsList.inactivePermissionMessage",
    AcceptPaymentStatus: "common.onlinePaymentsAccountsList.acceptPaymentStatus",
    ReceivePaymentStatus: "common.onlinePaymentsAccountsList.receivePaymentStatus",
    SendPaymentStatus: "common.onlinePaymentsAccountsList.sendPaymentStatus",
    CreditCardUnsupported: "common.onlinePaymentsAccountsList.creditCardUnsupported"
  },
  BookKeeping: "common.bookKeeping",
  ReceivePayments: "common.receivePayments",
  Unsupported: "common.unsupported",
  Updated: "common.updated",
  DueInXDays: "common.dueInXDays",
  ExpiredXDaysAgo: "common.expiredXDaysAgo",
  SetAsDefault: "common.setAsDefault",
  NeedHelp: "common.needHelp",
  PartialPaymentsNotAllowedDescription: "common.partialPaymentsNotAllowedDescription",
  Frequency: "common.frequency",
  PaymentFrequency: "common.paymentFrequency",
  SendInviteNow: "common.sendInviteNow",
  YourBalanceDueIs: "common.yourBalanceDueIs",
  Amount: "common.amount",
  HowWouldYouLikeToPay: "common.howWouldYouLikeToPay",
  PleaseContactYourPropertyManager: "common.pleaseContactYourPropertyManager",
  OnlinePaymentsUnavailable: "common.onlinePaymentsUnavailable",
  Unavailable: "common.unavailable",
  PaymentSent: "common.paymentSent",
  PaymentScheduled: "common.paymentScheduled",
  ECheckWarning: "common.eCheckWarning",
  InPersonOther: "common.inPersonOther",
  PaymentMethodUnavailableMessage: "common.paymentMethodUnavailableMessage",
  PaymentMethodOverLimitMessage: "common.paymentMethodOverLimitMessage",
  PaymentMethodInvalidSelectionMessage: "common.paymentMethodInvalidSelectionMessage",
  InsufficientFundsMessage: "common.insufficientFundsMessage",
  PayNow: "common.payNow",
  NewPayment: "common.newPayment",
  Or: "common.or",
  Active: "common.active",
  Inactive: "common.inactive",
  ManualBankDialogTitle: "common.manualBankDialogTitle",
  ManualBankDialogDescription: "common.manualBankDialogDescription",
  AcceptAndClose: "common.acceptAndClose",
  ChangePaymentMethod: "common.changePaymentMethod",
  PaymentUnsuccessfulDialogTitle: "common.paymentUnsuccessfulDialogTitle",
  PaymentUnsuccessfulDialogDescription: "common.paymentUnsuccessfulDialogDescription",
  PaymentMethodAdded: "common.paymentMethodAdded",
  PaymentMethodAddedInstructions: "common.paymentMethodAddedInstructions",
  BackToMobileApp: "common.backToMobileApp",
  Recommended: "common.recommended",
  Sec: "common.sec",
  UpdateInfo: "common.updateInfo",
  AppliedCredit: "common.appliedCredit",
  BankAccountVerificationInProgressVerify: "common.bankAccountVerificationInProgressVerify",
  Processing: "common.processing",
  VerifyYourAccount: "common.verifyYourAccount",
  AccountBeingVerifiedTitle: "common.accountBeingVerifiedTitle",
  AccountBeingVerifiedDescription: "common.accountBeingVerifiedDescription",
  BankAccountVerificationInProgress: "common.bankAccountVerificationInProgress",
  PleaseCompleteRequiredFields: "common.pleaseCompleteRequiredFields",
  MakeSureToFillAllFields: "common.makeSureToFillAllFields",
  EPayStatus: {
    Succeeded: "common.ePayStatus.succeeded",
    Processing: "common.ePayStatus.processing",
    Disputed: "common.ePayStatus.disputed",
    Failed: "common.ePayStatus.failed",
    PendingReview: "common.ePayStatus.pendingReview",
    Refunded: "common.ePayStatus.refunded",
    Paid: "common.ePayStatus.paid",
    InTransit: "common.ePayStatus.inTransit"
  },
  Complete: "common.complete",
  Completed: "common.completed",
  ExperienceTitle: "common.experienceTitle",
  PurposeTitle: "common.purposeTitle",
  EstimateNumberOfTenantsTitle: "common.estimateNumberOfTenantsTitle",
  GoalsAndObjectives: "common.goalsAndObjectives",
  EstimateNumberOfTenants: "common.estimatedNumberOfTenants",
  RateYourExperience: "common.rateYourExperience",
  WhatManagementSoftware: "common.whatManagementSoftware",
  PreviousToolOrSoftware: "common.previousToolOrSoftware",
  ReasonForSelectingOther: "common.reasonForSelectingOther",
  BalancesSummary: {
    Title: "common.balancesSummary.title",
    Overview: "common.balancesSummary.overview",
    FilterTitle: "common.balancesSummary.filterTitle",
    PendingAndProcessingAvailableSoon: {
      Title: "common.balancesSummary.pendingAndProcessingAvailableSoon.title",
      SubTitle: "common.balancesSummary.pendingAndProcessingAvailableSoon.subTitle"
    },
    SucceededAvailableSoon: {
      Title: "common.balancesSummary.succeededAvailableSoon.title",
      SubTitle: "common.balancesSummary.succeededAvailableSoon.subTitle"
    },
    RecentlyCompleted: {
      Title: "common.balancesSummary.recentlyCompleted.title",
      AmountOfDays: "common.balancesSummary.recentlyCompleted.amountOfDays",
      SubTitle: "common.balancesSummary.recentlyCompleted.subTitle"
    },
    PaymentsStatus: {
      TransactionsPendingReview: "common.balancesSummary.paymentsStatus.transactionsPendingReview",
      EstimatedPayoutOn: "common.balancesSummary.paymentsStatus.estimatedPayoutOn",
      DepositExpectedToArriveBy: "common.balancesSummary.paymentsStatus.depositExpectedToArriveBy",
      Deposited: "common.balancesSummary.paymentsStatus.deposited"
    },
    PaymentsChip: {
      PendingReview: "common.balancesSummary.paymentsChip.pendingReview",
      Processing: "common.balancesSummary.paymentsChip.processing",
      Succeeded: "common.balancesSummary.paymentsChip.succeeded",
      Disputed: "common.balancesSummary.paymentsChip.disputed",
      Failed: "common.balancesSummary.paymentsChip.failed",
      Refunded: "common.balancesSummary.paymentsChip.refunded",
      Paid: "common.balancesSummary.paymentsChip.paid",
      InTransit: "common.balancesSummary.paymentsChip.inTransit"
    },
    NumberOfPayments: "common.balancesSummary.numberOfPayments",
    ViewAll: "common.balancesSummary.viewAll",
    Total: "common.balancesSummary.total"
  },
  TransactionsTab: {
    Title: "common.transactionsTab.title",
    AvailableOnSucceededHintText: "common.transactionsTab.availableOnSucceededHintText",
    AvailableOnDepositedHintText: "common.transactionsTab.availableOnDepositedHintText",
    ListHeader: {
      PaymentDate: "common.transactionsTab.listHeader.paymentDate",
      Amount: "common.transactionsTab.listHeader.amount",
      Type: "common.transactionsTab.listHeader.type",
      Status: "common.transactionsTab.listHeader.status",
      Name: "common.transactionsTab.listHeader.name",
      EstArrival: "common.transactionsTab.listHeader.estArrival"
    },
    NoDateTooltipTitle: "common.transactionsTab.noDateTooltipTitle",
    AvailableOnExplanationTooltip: "common.transactionsTab.availableOnExplanationTooltip"
  },
  ArrivalDate: "common.arrivalDate",
  UsageAndLimitsInfoTab: {
    TabTitle: "common.usageAndLimitsInfoTab.tabTitle",
    Title: "common.usageAndLimitsInfoTab.title",
    Subtitle: "common.usageAndLimitsInfoTab.subtitle",
    ListTitle: "common.usageAndLimitsInfoTab.listTitle",
    PopoverItems: {
      UpdateInformation: "common.usageAndLimitsInfoTab.popoverItems.updateInformation",
      RequestToUpdateLimits: "common.usageAndLimitsInfoTab.popoverItems.requestToUpdateLimits"
    },
    emptyListText: "common.usageAndLimitsInfoTab.emptyListText",
    RequestLimitIncrease: "common.usageAndLimitsInfoTab.requestLimitIncrease",
    amountUsed: "common.usageAndLimitsInfoTab.amountUsed"
  },
  DepositsTab: {
    Title: "common.depositsTab.title",
    ListHeader: {
      Bank: "common.depositsTab.listHeader.bank",
      Amount: "common.depositsTab.listHeader.amount",
      Transactions: "common.depositsTab.listHeader.transactions",
      Status: "common.depositsTab.listHeader.status",
      ExpectedArrivalDate: "common.depositsTab.listHeader.expectedArrivalDate"
    }
  },
  TBD: "common.TBD",
  NoDate: "common.noDate",
  ReceivePaymentsHelpPanelDescription: "common.receivePaymentsHelpPanelDescription",
  ReceivePaymentsHelpPanelArticleTopic: "common.receivePaymentsHelpPanelArticleTopic",
  OnlinePaymentsSetup: "common.onlinePaymentsSetup",
  SetupDescription: "common.setupDescription",
  Setup: "common.setup",
  TenantPortalFeaturesOnlinePayments: {
    CannotBeTurnedOffTitle: "common.tenantPortalFeaturesOnlinePayments.cannotBeTurnedOffTitle",
    CannotBeTurnedOffDescriptionCompany:
      "common.tenantPortalFeaturesOnlinePayments.cannotBeTurnedOffDescriptionCompany",
    CannotBeTurnedOffDescriptionProperty:
      "common.tenantPortalFeaturesOnlinePayments.cannotBeTurnedOffDescriptionProperty",
    Lease: {
      CancelWarning: "common.tenantPortalFeaturesOnlinePayments.lease.cancelWarning",
      ConfirmationCancelTitle: "common.tenantPortalFeaturesOnlinePayments.lease.confirmationCancelTitle",
      ConfirmationCancelDescription: "common.tenantPortalFeaturesOnlinePayments.lease.confirmationCancelDescription"
    },
    DefaultSettingsCancelWarning: "common.tenantPortalFeaturesOnlinePayments.defaultSettingsCancelWarning"
  },
  ChooseStatus: "common.chooseStatus",
  CancelAll: "common.cancelAll",
  MobileDownloadVersion: {
    MandatoryTitle: "common.mobileDownloadVersion.mandatoryTitle",
    MandatoryText: "common.mobileDownloadVersion.mandatoryText",
    RecommendedTitle: "common.mobileDownloadVersion.recommendedTitle",
    RecommendedText: "common.mobileDownloadVersion.recommendedText"
  },
  RentOverdueSettingTitle: "common.rentOverdueSettingTitle",
  DaysAfterRentIsDue: "common.daysAfterRentIsDue",
  HowOftenShouldWeSendReminders: "common.howOftenShouldWeSendReminders",
  WhenShouldWeSendThisNotification: "common.whenShouldWeSendThisNotification",
  OneDayBeforeLateFeeChargedSettingTitle: "common.oneDayBeforeLateFeeChargedSettingTitle",
  OneDayBeforeLateFeeChargedSettingDescription: "common.oneDayBeforeLateFeeChargedSettingDescription",
  FillInYourRole: "common.fillInYourRole",
  WhatIsYourRole: "common.whatIsYourRole",
  FillInYourGoals: "common.fillInYourGoals",
  RentalApplicationListing: {
    RentalApplications: "common.rentalApplicationListing.rentalApplications",
    ChooseUnitToApply: "common.rentalApplicationListing.chooseUnitToApply"
  },
  ChooseRentalApplicationDialog: {
    ChooseApplication: "common.chooseRentalApplicationDialog.chooseApplication",
    WeNoticed: "common.chooseRentalApplicationDialog.weNoticed",
    ApplicationCreatedAt: "common.chooseRentalApplicationDialog.applicationCreatedAt",
    ApplicationSubmittedAt: "common.chooseRentalApplicationDialog.applicationSubmittedAt",
    StartNewApplication: "common.chooseRentalApplicationDialog.startNewApplication"
  },
  RentalApplicationListStatusTooltip: {
    NotSent: "common.rentalApplicationListStatusTooltip.notSent",
    ApplicationSent: "common.rentalApplicationListStatusTooltip.applicationSent",
    SendingFailed: "common.rentalApplicationListStatusTooltip.sendingFailed",
    NotSubmitted: "common.rentalApplicationListStatusTooltip.notSubmitted",
    Submitted: "common.rentalApplicationListStatusTooltip.submitted",
    RequestReports: "common.rentalApplicationListStatusTooltip.requestReports",
    PendingReports: "common.rentalApplicationListStatusTooltip.pendingReports",
    InProgress: "common.rentalApplicationListStatusTooltip.inProgress",
    InProgressStep: "common.rentalApplicationListStatusTooltip.inProgressStep",
    IdentityUnverified: "common.rentalApplicationListStatusTooltip.identityUnverified",
    Expired: "common.rentalApplicationListStatusTooltip.expired",
    ReportsFailed: "common.rentalApplicationListStatusTooltip.reportsFailed",
    ReportsAvailable: "common.rentalApplicationListStatusTooltip.reportsAvailable",
    NoDecision: "common.rentalApplicationListStatusTooltip.noDecision",
    DecisionApproved: "common.rentalApplicationListStatusTooltip.decisionApproved",
    DecisionIgnored: "common.rentalApplicationListStatusTooltip.decisionIgnored",
    DecisionRejected: "common.rentalApplicationListStatusTooltip.decisionRejected"
  },
  NotSubmitted: "common.notSubmitted",
  RentalApplicationsOverview: {
    ApplicationNotSubmitted: "common.applicationNotSubmitted",
    JoinHelpText: "common.rentalApplicationsOverview.joinHelpText",
    SelectApplications: "common.rentalApplicationsOverview.selectApplications",
    ApplicantTypes: {
      Tenant: "common.rentalApplicationsOverview.applicantTypes.tenant",
      TenantWithCoApplicants: "common.rentalApplicationsOverview.applicantTypes.tenantWithCoApplicants",
      TenantWithCoApplicantsAndCoSigners:
        "common.rentalApplicationsOverview.applicantTypes.tenantWithCoApplicantsAndCoSigners",
      TenantWithCoSigner: "common.rentalApplicationsOverview.applicantTypes.tenantWithCoSigner",
      Signer: "common.rentalApplicationsOverview.applicantTypes.signer",
      CoApplicant: "common.rentalApplicationsOverview.applicantTypes.coApplicant"
    },
    RentalApplicationInfo: "common.rentalApplicationsOverview.rentalApplicationInfo",
    QuickActions: {
      RequestReportsTooltip: "common.rentalApplicationsOverview.quickActions.requestReportsTooltip",
      SendApplication: "common.rentalApplicationsOverview.quickActions.sendApplication",
      ResendApplication: "common.rentalApplicationsOverview.quickActions.resendApplication",
      RequestReports: "common.rentalApplicationsOverview.quickActions.requestReports",
      ViewReports: "common.rentalApplicationsOverview.quickActions.viewReports",
      SendReminders: "common.rentalApplicationsOverview.quickActions.sendReminders"
    },
    Tiles: {
      PendingApplication: "common.rentalApplicationsOverview.tiles.pendingApplication",
      SendApplication: "common.rentalApplicationsOverview.tiles.sendApplication",
      ApplicantsInfo: "common.rentalApplicationsOverview.tiles.applicantsInfo",
      RequestReport: "common.rentalApplicationsOverview.tiles.requestReport",
      ApplicationDecision: "common.rentalApplicationsOverview.tiles.applicationDecision",
      SendReminders: "common.rentalApplicationsOverview.tiles.sendReminders",
      LastSentAt: "common.rentalApplicationsOverview.tiles.lastSentAt",
      ResendApplication: "common.rentalApplicationsOverview.tiles.resendApplication",
      ResendApplicationTooltip: "common.rentalApplicationsOverview.tiles.resendApplicationTooltip",
      SentAt: "common.rentalApplicationsOverview.tiles.sentAt",
      NotSent: "common.rentalApplicationsOverview.tiles.notSent",
      Unavailable: "common.rentalApplicationsOverview.tiles.unavailable",
      Submitted: "common.rentalApplicationsOverview.tiles.submitted",
      PendingVerification: "common.rentalApplicationsOverview.tiles.pendingVerification",
      ViewReports: "common.rentalApplicationsOverview.tiles.viewReports",
      IdentityVerified: "common.rentalApplicationsOverview.tiles.identityVerified",
      ReportsAvailable: "common.rentalApplicationsOverview.tiles.reportsAvailable",
      NotRequested: "common.rentalApplicationsOverview.tiles.notRequested",
      UnableToVerifyIdentity: "common.rentalApplicationsOverview.tiles.unableToVerifyIdentity",
      FailedToRequestReports: "common.rentalApplicationsOverview.tiles.failedToRequestReports",
      ReportsExpired: "common.rentalApplicationsOverview.tiles.reportsExpired",
      RequestReportSuccessesToast: "common.rentalApplicationsOverview.tiles.requestReportSuccessesToast",
      SentByLink: "common.rentalApplicationsOverview.tiles.sentByLink"
    }
  },
  RentalApplicationAuth: {
    Title: "common.rentalApplicationAuth.title",
    PostChargeTitle: "common.rentalApplicationAuth.postChargeTitle",
    ButtonTxt: "common.rentalApplicationAuth.buttonTxt",
    PostChargeButtonTxt: "common.rentalApplicationAuth.postChargeButtonTxt",
    EmailView: {
      Description: "common.rentalApplicationAuth.emailView.description",
      PostChargeDescription: "common.rentalApplicationAuth.emailView.postChargeDescription",
      EnterYourEmail: "common.rentalApplicationAuth.emailView.enterYourEmail"
    },
    TotpView: {
      Description: "common.rentalApplicationAuth.totpView.description",
      ResendCode: "common.rentalApplicationAuth.totpView.resendCode",
      Sec: "common.rentalApplicationAuth.totpView.sec"
    },
    TermsAndConditions: "common.rentalApplicationAuth.termsAndConditions",
    TermsOfUse: "common.rentalApplicationAuth.termsOfUse",
    PrivacyPolicy: "common.rentalApplicationAuth.privacyPolicy",
    And: "common.rentalApplicationAuth.and"
  },
  ContactInfo: "common.contactInfo",
  PrimaryEmail: "common.primaryEmail",
  MobilePhone: "common.mobilePhone",
  Financials: "common.financials",
  Transactions: "common.transactions",
  Reports: "common.reports",
  TabPermissions: "common.tabPermissions",
  Overview: "common.overview",
  Contributions: "common.contributions",
  MakeContributions: "common.makeContributions",
  Features: "common.features",
  OwnerPortal: {
    HelloUser: "common.ownerPortal.helloUser",
    WelcomeBack: "common.ownerPortal.welcomeBack",
    TransactionsScreenTitle: "common.ownerPortal.transactionsScreenTitle",
    TransactionsScreenSubtitle: "common.ownerPortal.transactionsScreenSubtitle",
    TransactionsEmptyDataViewTitle: "common.ownerPortal.transactionsEmptyDataViewTitle",
    TransactionsEmptyDataViewSubtitle: "common.ownerPortal.transactionsEmptyDataViewSubtitle",
    RequestsEmptyDataViewTitle: "common.ownerPortal.requestsEmptyDataViewTitle",
    RequestsEmptyDataViewSubtitle: "common.ownerPortal.requestsEmptyDataViewSubtitle",
    RequestsScreenTitle: "common.ownerPortal.requestsScreenTitle",
    RequestsScreenSubtitle: "common.ownerPortal.requestsScreenSubtitle",
    ReportsScreenTitle: "common.ownerPortal.reportsScreenTitle"
  },
  TransactionTypes: {
    OwnerDistribution: "common.transactionTypes.ownerDistribution",
    OwnerContribution: "common.transactionTypes.ownerContribution"
  },
  CompletePropertyDialog: {
    YourPropertyIsReady: "common.completePropertyDialog.yourPropertyIsReady",
    HereAreTheRecommendedNextSteps: "common.completePropertyDialog.hereAreTheRecommendedNextSteps",
    SetUpARentalApplication: "common.completePropertyDialog.setUpARentalApplication",
    AddPhotosAndAmenities: "common.completePropertyDialog.addPhotosAndAmenities",
    AddNewLease: "common.completePropertyDialog.addNewLease"
  },
  RentOverdue: "common.rentOverdue",
  Hoa: {
    Board: "common.hoa.board",
    Position: "common.hoa.position",
    NewBoardMember: "common.hoa.newBoardMember",
    NewBoardAnnouncement: "common.hoa.newBoardAnnouncement",
    BoardMemberEmptyDataViewInstructionsText: "common.hoa.boardMemberEmptyDataViewInstructionsText",
    BoardMembership: "common.hoa.boardMembership",
    TermStart: "common.hoa.termStart",
    TermEnd: "common.hoa.termEnd",
    FillBoardMemberDetails: "common.hoa.fillBoardMemberDetails",
    BoardMemberName: "common.hoa.boardMemberName",
    BoardMember: "common.hoa.boardMember",
    EditBoardMember: "common.hoa.editBoardMember",
    FillInBoardMemberDetails: "common.hoa.fillInBoardMemberDetails",
    RemoveTerm: "common.hoa.removeTerm",
    RemoveTermDialogSubtitle: "common.hoa.removeTermDialogSubtitle",
    UnitAddressIsTheSame: "common.hoa.unitAddressIsTheSame",
    Associations: "common.hoa.associations",
    ReplaceOwnerWarning: "common.hoa.replaceOwnerWarning",
    ReplaceOwnerWarningSubtitle: "common.hoa.replaceOwnerWarningSubtitle",
    ConfirmReplaceOwner: "common.hoa.confirmReplaceOwner",
    ConfirmReplaceOwnerError: "common.hoa.confirmReplaceOwnerError",
    EndHomeownerAccount: "common.hoa.endHomeownerAccount",
    HomeownerAccountOutstandingBalance: "common.hoa.homeownerAccountOutstandingBalance",
    HomeownerAccountRemainingCredit: "common.hoa.homeownerAccountRemainingCredit"
  },
  AuthorizeToUseInformation: "common.authorizeToUseInformation",
  VerifyAccountOwnership: "common.verifyAccountOwnership",
  RentalApplicationValidationFailedMsg: "common.rentalApplicationValidationFailedMsg",
  CopyPropertyLink: "common.copyPropertyLink",
  CopyUnitLink: "common.copyUnitLink",
  CopyCompanyLink: "common.copyCompanyLink",
  CopyProspectLink: "common.copyProspectLink",
  DeleteRelatedFiles: "common.deleteRelatedFiles",
  Preview: "common.preview",
  FilePreview: "common.filePreview",
  FileNotSupported: "common.fileNotSupported",
  RentalApplicationSettingErrorMsg: "common.rentalApplicationSettingErrorMsg",
  PortalInfoError: "common.portalInfoError",
  DescriptionPayment: "common.descriptionPayment",
  PoweredBy: "common.poweredBy",
  DescriptionScreening: "common.descriptionScreening",
  SecurePayments: "common.securePayments",
  HandleSendPaymentText: "common.handleSendPaymentText",
  CheckbookIo: "common.checkbookIo",
  HandleSendPaymentLimitCompleteText: "common.handleSendPaymentLimitCompleteText",
  Article: "common.article",
  BillingEmailError: "common.billingEmailError",
  YouveGotUnsavedChanges: "common.youveGotUnsavedChanges",
  UntitledListing: "common.untitledListing",
  AvailableUnits: "common.availableUnits",
  AtLeastOneTenantNeedsToBeAssociatedWithTheLease: "common.atLeastOneTenantNeedsToBeAssociatedWithTheLease",
  OpenUnitForApplicationOngoingMessage: "common.openUnitForApplicationOngoingMessage",
  CloseUnitForApplicationOngoingMessage: "common.closeUnitForApplicationOngoingMessage",
  RemoveAndClose: "common.removeAndClose",
  YouAreSkipTenantScreening: "common.youAreSkipTenantScreening",
  DescriptionScreeningAlert: "common.descriptionScreeningAlert",
  ScreenProspect: "common.screenProspect",
  SkipAndApprove: "common.skipAndApprove",
  PaymentMethodInvalid: "common.paymentMethodInvalid",
  DiscardUnsavedConfirmationDialog: {
    Title: "common.discardUnsavedConfirmationDialog.title",
    Description: "common.discardUnsavedConfirmationDialog.description",
    Cancel: "common.discardUnsavedConfirmationDialog.cancel",
    Ok: "common.discardUnsavedConfirmationDialog.ok"
  },
  DiscardChanges: "common.discardChanges",
  ChecksStatus: "common.checksStatus",
  PlatformFeeCalculation: "common.platformFeeCalculation",
  UnknownContact: "common.unknownContact",
  Yesterday: "common.yesterday",
  JustNow: "common.justNow",
  MinutesAgo: "common.minutesAgo",
  Archive: "common.archive",
  MarkAsUnread: "common.markAsUnread",
  AddContact: "common.addContact",
  TypeAMessage: "common.typeAMessage",
  TypeAMessageMobile: "common.typeAMessageMobile",
  NoConversationSelected: "common.noConversationSelected",
  NoConversationSelectedDescription: "common.noConversationSelectedDescription",
  StartAConversation: "common.startAConversation",
  ContactInfoIsMissing: "common.contactInfoIsMissing",
  ContactInfoIsMissingDescription: "common.contactInfoIsMissingDescription",
  UpdateContactDetails: "common.updateContactDetails",
  ViaSmsMessage: "common.viaSmsMessage",
  FailedToSendMessage: "common.failedToSendMessage",
  ConversationArchivedSuccessfully: "common.conversationArchivedSuccessfully",
  ConversationUnArchivedSuccessfully: "common.conversationUnArchivedSuccessfully",
  MoveToInbox: "common.moveToInbox",
  CommunicationCenter: "common.communicationCenter",
  NoChats: "common.noChats",
  TextMessaging: "common.textMessaging",
  SendWelcomeSmsDescription: "common.sendWelcomeSmsDescription",
  InsufficientCredits: "common.insufficientCredits",
  InsufficientCreditsMessage: "common.insufficientCreditsMessage",
  PurchaseSmsCredits: "common.purchaseSmsCredits",
  AddXCreditsForX: "common.addXCreditsForX",
  SendWelcomeSms: "common.sendWelcomeSms",
  SendWelcomeSmsInEditDialog: "common.sendWelcomeSmsInEditDialog",
  SendWelcomeSmsInEditDialogSent: "common.sendWelcomeSmsInEditDialogSent",
  WelcomeSmsSent: "common.welcomeSmsSent",
  HasBeenCreated: "common.hasBeenCreated",
  TaskStatus: "common.taskStatus",
  Unsubscribed: "common.unsubscribed",
  SmsNumber: "common.smsNumber",
  Email: "common.email",
  Address: "common.address",
  ContactLinkedSuccessfully: "common.contactLinkedSuccessfully",
  TenantPortal: {
    HelpPanel: {
      PaymentWizard: {
        Description: "common.tenantPortal.helpPanel.paymentWizard.description",
        Articles: {
          PaymentInPortal: "common.tenantPortal.helpPanel.paymentWizard.articles.paymentInPortal",
          InstantBankVerification: "common.tenantPortal.helpPanel.paymentWizard.articles.instantBankVerification",
          ManualBankVerification: "common.tenantPortal.helpPanel.paymentWizard.articles.manualBankVerification"
        }
      }
    }
  },
  RequestType: "common.requestType",
  MaintenanceType: "common.maintenanceType",
  RequestStatus: "common.requestStatus",
  CreationDate: "common.creationDate",
  RequestDescription: "common.requestDescription",
  NotStarted: "common.notStarted",
  InProgress: "common.inProgress",
  Archived: "common.archived",
  Received: "common.received",
  NotApplicable: "common.notApplicable",
  RequestNameAndDescription: "common.requestNameAndDescription",
  Attachment: "common.attachment",
  YourRequests: "common.yourRequests",
  DeleteRequestConfirmation: "common.deleteRequestConfirmation",
  EntryNotes: "common.entryNotes"
};
export default Common;
