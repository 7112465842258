"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DepositAccountTypes = void 0;
const accountType_enum_1 = require("../../accounts/accountType.enum");
exports.DepositAccountTypes = [
    accountType_enum_1.AccountType.ASSET_OTHER_CURRENT_ASSETS,
    accountType_enum_1.AccountType.ASSET_BANK,
    accountType_enum_1.AccountType.ASSET_FIXED_ASSETS,
    accountType_enum_1.AccountType.ASSET_OTHER_ASSETS,
    accountType_enum_1.AccountType.LIABILITY_OTHER_CURRENT_LIABILIY,
    accountType_enum_1.AccountType.LIABILITY_LONG_TERM_LIABILITY,
    accountType_enum_1.AccountType.EQUITY_EQUITY,
    accountType_enum_1.AccountType.LIABILITY_CREDIT_CARD
];
