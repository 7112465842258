import React, { useEffect, useMemo, useState } from "react";
import type { LateFeeChargeDto, UnitDto } from "@doorloop/dto";
import { createValidator, DataCy, PropertyDto, PropertySettingsDto } from "@doorloop/dto";
import type { ApiResult } from "api/apiResult";
import { propertiesApi } from "api/propertiesApi";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { RadioGroup } from "DLUI/form";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, Formik } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import LateFeeSettings from "../common/lateFees/lateFeesSettings";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import { settingsApi } from "api/settingsApi";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { trackLateFeesSettings } from "DLUI/dialogs/settings/common/analytics/trackLateFeesSettings";
import { getChargesWithUniqueKeys, getCurrentSettings } from "DLUI/dialogs/settings/common/lateFees/utils";
import { entityApiStore } from "api/entityApiStore/entityApiStore";
import { editLateFeesFrameWidth } from "DLUI/dialogs/settings/common/lateFees/constants";

interface ComponentProps {
  didFinishOperation: (values: UnitDto) => void;
  onBackdropClick: () => void;
  refreshEvent?: () => void;
  dialogTitle: string;
  dataCy?: string;
}

let formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

const validateForm = createValidator(PropertyDto);

const EditLateFees: React.FC<ComponentProps> = ({
  didFinishOperation,
  refreshEvent,
  onBackdropClick,
  dialogTitle,
  dataCy
}: ComponentProps) => {
  const { t } = useTranslation();
  const { dialogHorizontalPadding } = DialogsHelper();
  const { propertyId } = useParams<{ propertyId?: string }>();
  const [propertyData, setPropertyData] = useState<PropertyDto | undefined>();

  const [lateFeesDefaultAccount, setLateFeesDefaultAccount] = useState<string | undefined>();
  const [viewIndex, setViewIndex] = useState(0);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);

  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>(AppStrings.Common.NetworkErrorSubTitle);

  const [hideLoadingDialogActionButtons, setHideLoadingDialogActionButtons] = useState<boolean>(false);

  const [lateFeesSelectionType, setLateFeesSelectionType] = useState<"companyDefault" | "specifyFees">(
    "companyDefault"
  );

  const { data: companyLateFeesData } = entityApiStore.globalSettings.queries.useLateFeesSettings();

  const loadPropertyData = async () => {
    if (propertyId) {
      const response = await propertiesApi.get(propertyId).catch((e: string) => {
        showDialogError(e);
      });
      if (response && response.status) {
        if (response.data && response.data.settings && response.data.settings.lateFeesPolicy) {
          const defaultLateFees = response.data.settings.lateFeesPolicy;
          if (defaultLateFees && defaultLateFees.lateFeeCharges && defaultLateFees.lateFeeCharges.length > 0) {
            setLateFeesSelectionType("specifyFees");
          }
        }

        const { data } = await settingsApi.getDefaultAccountsData();
        if (data?.income_lateFees) {
          setLateFeesDefaultAccount(data.income_lateFees);
        }
        setPropertyData(response.data);
        setLoadingDialogState(DialogState.Success);
      } else {
        showDialogError();
      }
    } else {
      showDialogError();
    }
  };

  const showDialogError = (errorMessage?: string) => {
    setHideLoadingDialogActionButtons(true);
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(errorMessage || AppStrings.Common.GeneralError);
  };

  useEffect(() => {
    loadPropertyData();
    return () => {
      formikGlobalRef = null;
    };
  }, []);

  const initFormValues = (): PropertyDto => {
    if (formikGlobalRef && formikGlobalRef.values) {
      return formikGlobalRef.values;
    }
    if (propertyData) {
      if (!propertyData.settings) {
        propertyData.settings = new PropertySettingsDto();
      }
      if (propertyData.settings.lateFeesPolicy) {
        propertyData.settings.lateFeesPolicy.lateFeeCharges = getChargesWithUniqueKeys(
          propertyData.settings.lateFeesPolicy.lateFeeCharges
        );
      }
      return propertyData;
    }
    return new PropertyDto();
  };

  const isValidForm = async (formikRef: FormikProps<PropertyDto>) => {
    const settingsObject =
      formikRef.values && formikRef.values.settings && formikRef.values.settings.lateFeesPolicy
        ? formikRef.values.settings.lateFeesPolicy
        : undefined;

    if (settingsObject) {
      if (settingsObject.lateFeeCharges) {
        settingsObject.lateFeeCharges.forEach((currentItem: LateFeeChargeDto, index: number) => {
          formikRef.setFieldTouched(`settings.lateFeesPolicy.lateFeeCharges[${index}].frequency`);
          formikRef.setFieldTouched(`settings.lateFeesPolicy.lateFeeCharges[${index}].amount`);
          formikRef.setFieldTouched(`settings.lateFeesPolicy.lateFeeCharges[${index}].percentage`);
          formikRef.setFieldTouched(
            `settings.lateFeesPolicy.lateFeeCharges[${index}].daysAfterDueDateToChargeLateFees`
          );
          formikRef.setFieldTouched(`settings.lateFeesPolicy.lateFeeCharges[${index}].account`);
          formikRef.setFieldTouched(`settings.lateFeesPolicy.lateFeeCharges[${index}].feeType`);
        });
      }
      formikRef.setFieldTouched("settings.lateFeesPolicy.applyOnlyToSpecificItemsOutstandingBalance");
      formikRef.setFieldTouched("settings.lateFeesPolicy.minBalanceToApplyLateFeeAmount");
      formikRef.setFieldTouched("settings.lateFeesPolicy.maxMonthlyLateFeesAmount");
      formikRef.setFieldTouched("settings.lateFeesPolicy.lateFeeSettingsAccountsToConsiderSpecificAccounts");
    }
    const errors = (await formikRef.validateForm()) as any;
    return _.isEmpty(errors.settings?.lateFeesPolicy);
  };

  const updateProperty = async (values: PropertyDto) => {
    const response = (await propertiesApi.update(values.id!, values).catch((error) => {
      setLoadingDialogErrorText(error);
      setLoadingDialogState(DialogState.Error);
    })) as ApiResult<any>;

    if (response.status !== undefined && !response.status) {
      setLoadingDialogErrorText(response.message);
      setLoadingDialogState(DialogState.Error);
    } else {
      setLoadingDialogState(DialogState.Success);
      didFinishUpdateProperty();
      formikGlobalRef = null;

      if (values.settings?.lateFeesPolicy) {
        trackLateFeesSettings("Property Settings", values.settings.lateFeesPolicy);
      }
    }
  };

  const didPressSaveButton = async () => {
    if (formikGlobalRef !== null && propertyData) {
      await formikGlobalRef.submitForm();
      const isValid = await isValidForm(formikGlobalRef);
      if (isValid) {
        setLoadingDialogState(DialogState.Show);
        setViewIndex(1);
        await updateProperty(formikGlobalRef.values);
      }
    }
  };

  const renderActionPanelButtons = () => {
    if (viewIndex === 1) {
      return <div />;
    }

    return (
      <FormActionButtons
        propsSubButton={{ onClick: onBackdropClick }}
        propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
      />
    );
  };

  const didPressDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(0);
  };

  const onRetryButtonPress = () => {
    if (formikGlobalRef !== null) {
      setLoadingDialogState(DialogState.Hidden);
      setViewIndex(0);
    }
  };

  const didFinishUpdateProperty = () => {
    if (formikGlobalRef !== null) {
      didFinishOperation(formikGlobalRef.values);
      if (refreshEvent) {
        refreshEvent();
      }
      setLoadingDialogState(DialogState.Hidden);
    }
  };

  const handleLateFeeSelectionChange = async (selectedValue: string) => {
    if (formikGlobalRef !== null) {
      const isCompanySettingsChosen = selectedValue === "false";
      formikGlobalRef.setFieldValue(
        "settings.lateFeesPolicy",
        getCurrentSettings({
          isCompanySettingsChosen,
          companySettings: companyLateFeesData?.data,
          defaultAccountId: lateFeesDefaultAccount,
          additionalSettings: { onlyChargeLateFeesOnMostRecentCharge: true }
        })
      );
    }
    setLateFeesSelectionType(selectedValue === "false" ? "companyDefault" : "specifyFees");
  };

  const renderForm = () => {
    const formInitialValues = initFormValues();
    return (
      <Formik initialValues={formInitialValues} onSubmit={() => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;

          return (
            <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding}>
              <View alignItems={"flex-start"}>
                <Text
                  value={AppStrings.Properties.Settings.PropertyLateFeesDescription}
                  fontSize={16}
                  color={"black"}
                  lineHeight={"26px"}
                  marginTop={20}
                />
                <FastField
                  uniqueKey={"LF"}
                  component={RadioGroup}
                  name={"automaticLateFees"}
                  defaultValue={lateFeesSelectionType === "companyDefault" ? "false" : "true"}
                  didChangeSelection={handleLateFeeSelectionChange}
                  radioGroupItems={[
                    {
                      label: AppStrings.Properties.Settings.UseCompanyDefaultLateFees,
                      value: "false",
                      dataCy: DataCy.propertyDetails.settings.editPropertyLateFees.useDefaultLateFees
                    },
                    {
                      label: AppStrings.Properties.Settings.SpecifyLateFeesForProperty,
                      value: "true",
                      dataCy: DataCy.propertyDetails.settings.editPropertyLateFees.specifyLateFees
                    }
                  ]}
                  marginTop={10}
                />
                <View
                  shouldShow={lateFeesSelectionType === "specifyFees"}
                  showAnimation={"fade-in"}
                  hideAnimation={"fade-out"}
                >
                  <View>
                    <LateFeeSettings name={"settings.lateFeesPolicy"} />
                  </View>
                </View>
              </View>
            </View>
          );
        }}
      </Formik>
    );
  };

  const renderView = ({ index }: any) => {
    if (index === 0 && propertyData) {
      return renderForm();
    }
    if (index === 1 || !propertyData) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={t(AppStrings.Common.Loading)}
            errorText={loadingDialogErrorText}
            successText={t(AppStrings.Common.OperationCompleted)}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
            hideButtons={hideLoadingDialogActionButtons}
          />
        </View>
      );
    }
    return <div />;
  };

  const _onBackdropClick = () => {
    if (viewIndex === 1) {
      setViewIndex(0);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const frameType = useMemo(() => {
    if (propertyData === undefined) {
      return "contentOnly";
    }

    if (viewIndex === 0) {
      return "sectionTitleFrame";
    }
    if (viewIndex === 1) {
      return "contentOnly";
    }

    return "sectionTitleFrame";
  }, [viewIndex, propertyData]);

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={dialogTitle}
      width={editLateFeesFrameWidth}
      height={window.innerHeight > 1000 ? 1000 : window.innerHeight}
      renderView={renderView}
      numViews={2}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={frameType}
    />
  );
};

export default EditLateFees;
