import React from "react";
import { ConnectedToCheckbookView } from "screens/settings/outgoingPayments/connectedToCheckbookView";
import type { FormikContextType } from "formik";
import { Formik } from "formik";
import { CheckbookApiKeysSettingsDto } from "@doorloop/dto";
import { createValidator } from "@doorloop/dto";

interface Props {
  onBackdropClick: () => void;
  didPressConnectButton: (formikRef: FormikContextType<CheckbookApiKeysSettingsDto>) => void;
}

const ConnectedToCheckbookFormikWrapper: React.FC<Props> = ({ onBackdropClick, didPressConnectButton }: Props) => {
  const validateForm = createValidator(CheckbookApiKeysSettingsDto);
  const initFormValues = (): CheckbookApiKeysSettingsDto => new CheckbookApiKeysSettingsDto();

  return (
    <Formik initialValues={initFormValues()} onSubmit={() => {}} validate={validateForm}>
      <ConnectedToCheckbookView didPressConnectButton={didPressConnectButton} onBackdropClick={onBackdropClick} />
    </Formik>
  );
};

export default ConnectedToCheckbookFormikWrapper;
