import * as Filters from "@/components/common/filters";
import { FilterPanelSearchInput, type FilterPanelSearchInputProps } from "./filterPanelSearchInput";
import { useResponsiveHelper } from "@/contexts/responsiveContext";
import type { FilterOption } from "../filterPanel/filterInputs/types";

export interface SearchPanelFilterSectionProps<TQuery> {
  inputProps: FilterPanelSearchInputProps;
  filterButtonAlignment?: "left" | "right";
  hideSearchBar?: boolean;
  filterOptions?: Array<FilterOption<TQuery>>;
  hideFilterButton?: boolean;
}

export function SearchPanelFilterSection<TQuery>({
  inputProps,
  hideSearchBar,
  filterButtonAlignment,
  hideFilterButton = false,
  filterOptions = [],
  ...rest
}: SearchPanelFilterSectionProps<TQuery>) {
  const { isMobile } = useResponsiveHelper();
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        gap: 8,
        justifyContent: filterButtonAlignment === "right" ? "flex-end" : undefined,
        flexGrow: isMobile ? 1 : undefined
      }}
    >
      <div
        style={{
          flexGrow: isMobile ? 1 : undefined
        }}
        {...rest}
      >
        {!hideSearchBar && Boolean(inputProps.placeholder) && <FilterPanelSearchInput {...inputProps} />}
      </div>
      <Filters.Root filterOptions={filterOptions}>
        <Filters.Content>
          {hideFilterButton ? null : <Filters.Toggle />}
          <Filters.Chips />
          <Filters.SidePanel />
        </Filters.Content>
      </Filters.Root>
    </div>
  );
}
