import type { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import DatePicker from "../../datePicker";

export interface DatePickerFieldProps {
  uniqueKey: string;
  onChange: (selectedDate: MaterialUiPickersDate | null) => void;
  label: string;
  pickerValue: moment.Moment | null;
  minDate?: string;
  maxDate?: string;
  dataCy?: string;
}

export function DatePickerField({
  onChange,
  pickerValue,
  minDate,
  maxDate,
  label,
  uniqueKey,
  dataCy
}: DatePickerFieldProps) {
  return (
    <DatePicker
      uniqueKey={`${uniqueKey}filter_form`}
      label={label}
      onChange={onChange}
      pickerValue={pickerValue}
      minDate={minDate ? new Date(minDate) : undefined}
      maxDate={maxDate ? new Date(maxDate) : undefined}
      dataCy={dataCy}
      inputProps={{
        style: {
          paddingRight: 0
        }
      }}
    />
  );
}
