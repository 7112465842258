import React, { Fragment, useEffect, useState } from "react";
import { DeleteIcon, RefreshIcon } from "assets/icons";
import spinnerErrorAnimation from "assets/lottie/spinner-fail.json";
import spinnerAnimation from "assets/lottie/spinner.json";
import { DownloadButton, IconButton } from "DLUI/form";
import { Icon } from "DLUI/icon";
import { Lottie } from "DLUI/lottie";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import makeStyles from "./styles";
import type { SVGIconComponent } from "assets/icons/types";

export type FileUploadState =
  | "WaitingForUpload"
  | "UploadInProgress"
  | "UploadSuccessful"
  | "UploadFailed"
  | "DeleteFailed"
  | "WaitingForDelete"
  | "FileDeleted"
  | "DeleteInProgress";

export type FileRetryMethod = "Delete" | "Upload";

export type SingleFileListProps = Record<string, FileListItemProps>;

export type SingleFileListPropsErrors = Record<string, string>;

export interface FileListItemProps {
  name: string;
  icon: SVGIconComponent;
  didPressDeleteIcon: (itemIndex: number, id?: string) => void;
  didPressRetry?: (fileIndex: number, retryMethod: FileRetryMethod) => void;
  itemIndex: number;
  fileUploadState?: FileUploadState;
  fileUploadErrorMessage?: string;
  fileData?: File;
  id?: string;
  metadata?: Record<string, any>;
  displayType?: "small" | "large";
  createdBy?: string;
  createdAt?: Date;
  baseUrl?: string;
  label?: string;
  allowDelete?: boolean;
  isSharedWithTenant?: boolean;
}

export const FilesListItem: React.FC<FileListItemProps> = ({
  name,
  icon,
  didPressDeleteIcon,
  itemIndex,
  fileUploadState,
  fileUploadErrorMessage,
  didPressRetry,
  displayType,
  id,
  baseUrl,
  allowDelete
}: FileListItemProps) => {
  const classes = makeStyles();
  const [uploadState, setUploadState] = useState(fileUploadState);
  const [showRetry, setShowRetry] = useState(false);
  const _didPressDeleteIcon = () => {
    didPressDeleteIcon(itemIndex, id);
  };

  const _didPressFileItem = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // event.stopPropagation();
  };

  const didPressRetryButton = () => {
    const retryMethod = fileUploadState === "DeleteFailed" ? "Delete" : "Upload";
    setShowRetry(false);

    didPressRetry?.(itemIndex, retryMethod);
  };

  useEffect(() => {
    setUploadState(fileUploadState);
  }, [fileUploadState]);

  const didFinishErrorAnimation = () => {
    setShowRetry(true);
  };

  const renderRetryDisplay = () => (
    <View height={"100%"} alignItems={"center"} flexDirection={"row"}>
      <View width={"auto"}>
        <Text
          color={"gray"}
          fontWeight={700}
          align={"center"}
          marginRight={10}
          value={
            fileUploadState === "UploadFailed"
              ? fileUploadErrorMessage
                ? fileUploadErrorMessage
                : AppStrings.Common.Dropzone.FailedToUpload
              : AppStrings.Common.Dropzone.FailedToDelete
          }
          removeDefaultPadding
        />
        <Text color={"gray"} fontWeight={700} align={"center"} marginRight={10} marginTop={5} fontSize={12}>
          {name}
        </Text>
      </View>
      <IconButton width={25} height={25} onClick={didPressRetryButton} Icon={RefreshIcon} pathColor={"gray"} />
    </View>
  );

  const renderItemContent = () => {
    if (showRetry) {
      return renderRetryDisplay();
    }

    switch (uploadState) {
      case "UploadSuccessful":
        return (
          <Fragment>
            {allowDelete && (
              <div className={classes.deleteIconContainer}>
                <IconButton Icon={DeleteIcon} onClick={_didPressDeleteIcon} />
              </div>
            )}

            {icon}
            <Text
              color={"gray"}
              fontWeight={700}
              className={classes.buttonLabel}
              marginTop={20}
              align={"center"}
              removeDefaultPadding
            >
              {name}
            </Text>
          </Fragment>
        );
      case "UploadInProgress":
        return (
          <View
            borderRadius={10}
            backgroundColor={"dark"}
            height={"80%"}
            flexDirection={"row"}
            width={"auto"}
            alignItems={"center"}
            marginLeft={20}
          >
            <View height={"100%"} width={60} justifyContent={"center"} alignItems={"center"}>
              <Lottie loop animationData={spinnerAnimation} width={30} height={30} />
            </View>

            <SeparationLine marginRight={10} width={1} height={"80%"} />

            <Text
              value={AppStrings.Common.Dropzone.UploadingFile}
              color={"gray"}
              fontSize={14}
              marginLeft={10}
              marginRight={20}
            />
          </View>
        );
      case "DeleteInProgress":
        return (
          <View
            borderRadius={10}
            backgroundColor={"dark"}
            height={"80%"}
            flexDirection={"row"}
            width={"auto"}
            alignItems={"center"}
            marginLeft={20}
          >
            <View height={"100%"} width={60} justifyContent={"center"} alignItems={"center"}>
              <Lottie loop animationData={spinnerAnimation} width={30} height={30} />
            </View>

            <SeparationLine marginRight={10} width={1} height={"80%"} />

            <Text
              value={AppStrings.Common.Dropzone.DeletingFile}
              color={"gray"}
              fontSize={14}
              marginLeft={10}
              marginRight={20}
            />
          </View>
        );
      case "UploadFailed":
      case "DeleteFailed":
        return (
          <div className={classes.loadingIndicatorContainer}>
            <Lottie
              loop={false}
              animationData={spinnerErrorAnimation}
              width={40}
              height={40}
              onAnimationCompleted={didFinishErrorAnimation}
            />
          </div>
        );
    }

    if (displayType && displayType === "small") {
      return (
        <View
          onClick={_didPressFileItem}
          alignItems={"center"}
          width={"auto"}
          flexDirection={"row"}
          height={"80%"}
          justifyContent={"center"}
          backgroundColor={"dark"}
          marginLeft={10}
          marginRight={10}
          borderRadius={10}
        >
          {allowDelete && (
            <IconButton
              loadingInProgress={uploadState === "WaitingForDelete"}
              paddingLeft={6}
              paddingRight={6}
              paddingTop={6}
              paddingBottom={6}
              width={15}
              height={15}
              onClick={_didPressDeleteIcon}
              Icon={DeleteIcon}
              marginRight={5}
              marginLeft={5}
            />
          )}

          <DownloadButton name={name} fileId={id} baseUrl={baseUrl} />

          <SeparationLine width={1} height={"80%"} />
          <Icon pathColor={"black"} marginLeft={10} width={25} height={25} Source={icon} />

          <View maxWidth={150} width={"auto"}>
            <Text
              lineHeight={"18px"}
              marginLeft={10}
              color={"gray"}
              fullWidth
              fontSize={12}
              paddingRight={10}
              overFlow={"ellipsis"}
              maxLines={"2"}
            >
              {name}
            </Text>
          </View>
        </View>
      );
    }
  };

  if (fileUploadState === "FileDeleted") {
    return null;
  }

  if (uploadState === "UploadSuccessful") {
    return null;
  }

  return (
    <div
      style={{ height: "100%", display: "flex", alignItems: "center" }}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      {renderItemContent()}
    </div>
  );
};
