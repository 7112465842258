import AppStrings from "locale/keys";
import type { PeriodType } from "./datePicker.types";

interface PeriodSelectionOptionItem {
  name: string;
  key: PeriodType;
}

export const PeriodSelectionOptions: PeriodSelectionOptionItem[] = [
  {
    name: AppStrings.Common.FilterPanelDatePicker.Custom,
    key: "custom"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.AllTime,
    key: "all-time"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.Today,
    key: "today"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.ThisWeek,
    key: "this-week"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.ThisMonth,
    key: "this-month"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.ThisWeekToDate,
    key: "this-week-to-date"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.ThisMonthToDate,
    key: "this-month-to-date"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.ThisQuarter,
    key: "this-quarter"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.ThisQuarterToDate,
    key: "this-quarter-to-date"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.ThisYearToDate,
    key: "this-year-to-date"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.ThisYearTolastMonth,
    key: "this-year-to-last-month"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.Yesterday,
    key: "yesterday"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.LastWeek,
    key: "last-week"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.LastWeekToDate,
    key: "last-week-to-date"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.LastMonth,
    key: "last-month"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.LastMonthToDate,
    key: "last-month-to-date"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.LastQuarter,
    key: "last-quarter"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.LastQuarterToDate,
    key: "last-quarter-to-date"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.LastYear,
    key: "last-year"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.Next7Days,
    key: "next-7-days"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.Next30Days,
    key: "next-30-days"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.Next60Days,
    key: "next-60-days"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.Next90Days,
    key: "next-90-days"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.Next120Days,
    key: "next-120-days"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.Last7Days,
    key: "last-7-days"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.Last30Days,
    key: "last-30-days"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.Last60Days,
    key: "last-60-days"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.Last90Days,
    key: "last-90-days"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.Last120Days,
    key: "last-120-days"
  },
  {
    name: AppStrings.Common.FilterPanelDatePicker.Last180Days,
    key: "last-180-days"
  }
];
