import type { InputLabelProps, InputProps } from "@material-ui/core";
import clsx from "clsx";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import { getIn } from "formik";
import { TextField } from "formik-material-ui";
import type { ChangeEvent, ChangeEventHandler } from "react";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import ViewOnlyInput from "../viewOnlyInput/viewOnlyInput";
import type { IRootState } from "store/index";
import NumberTextField from "./numberTextField";
import { fieldSizesMap, useStylesTextInput } from "./textInput";
import "./textInput/styles.css";
import { useReadOnlyContext } from "contexts/readOnlyContext";
import AppStrings from "locale/keys";
import { FieldSizes } from "DLUI/form/textField/types";
import type { ComponentProps } from "./types";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { useDebounce } from "../../../../hooks/useDebounce";
import _ from "lodash";

interface State {
  event?: ChangeEvent<HTMLInputElement>;
  value: string;
}

const DLTextField: React.FC<ComponentProps> = ({
  field,
  id,
  variant,
  label,
  size,
  InputProps,
  InputLabelProps,
  startAdornment,
  backgroundColor,
  marginLeft,
  EndAdornmentIcon,
  style,
  marginTop,
  paddingRight,
  paddingLeft,
  endAdornmentSize,
  TextFieldType,
  onBlur,
  onBlurUnconditionally,
  form,
  formatType,
  required,
  allowNegative,
  textAlign,
  multiLineSize,
  autoWidth,
  onChange,
  onClick,
  decimalScale,
  autoComplete,
  onFocus,
  viewOnly,
  onSubmit,
  ignoreSpaces,
  asyncAutoCompleteInstance,
  maxLength,
  textTransform,
  hideErrorText,
  fieldSize,
  multiline,
  dataCy,
  warning = false,
  disabled = false,
  shouldDebounce = false,
  autoFocus = false,
  format,
  placeholder,
  maxNumber,
  minNumber,
  maxLengthStyle,
  sliceOnMaxLength,
  useFormikOnBlur = true,
  textFieldClasses = {},
  ...rest
}: ComponentProps) => {
  const { isMobile } = useResponsiveHelper();

  const [state, setState] = useState<State>(() => {
    return { value: field.value || field.value === 0 ? field.value : "" };
  });
  const debouncedState = shouldDebounce ? useDebounce(state, 300) : state;

  const removeAllFromString = (value: string, valueToRemove: string) => {
    if (value) {
      return value.split(valueToRemove).join("");
    }
    return value;
  };
  const classes = useStylesTextInput();
  const isReadOnlyContext = useReadOnlyContext();

  const localeData = useSelector((state: IRootState) => state.auth.localeData);
  const { thousandSeparator, currencySymbol, percentageSymbol, decimalSeparator } = localeData;

  const _fieldSize: FieldSizes = fieldSize ?? (isMobile ? FieldSizes.MOBILE : FieldSizes.WEB);

  const deviceClassName = clsx(["DLUI_TextField", _fieldSize]);

  const _InputProps: InputProps = useMemo(() => {
    return {
      ...InputProps,
      ...(!multiline ? fieldSizesMap[_fieldSize].InputProps : {}),
      ...(variant === "filled" ? { disableUnderline: true } : {}),
      error: InputProps?.error || warning || undefined
    };
  }, [InputProps, warning]);

  const _InputLabelProps: InputLabelProps = useMemo(() => {
    return {
      margin: _fieldSize === FieldSizes.MOBILE ? undefined : "dense",
      ...InputLabelProps,
      ...(!multiline ? fieldSizesMap[_fieldSize].InputLabelProps : {})
    };
  }, [InputLabelProps]);

  const _onBlurText = (e: React.ChangeEvent<any>) => {
    let nextValue = e.target.value;
    if (formatType === "currency" && nextValue !== "") {
      nextValue = removeAllFromString(nextValue, currencySymbol);
      nextValue = removeAllFromString(nextValue, thousandSeparator);
      nextValue = parseFloat(decimalSeparator === "," ? nextValue.replace(",", ".") : nextValue);
    }
    if ((formatType === "number" || formatType === "plain-number") && nextValue !== "") {
      try {
        nextValue = removeAllFromString(nextValue, thousandSeparator);
        if (decimalScale && decimalScale > 0) {
          nextValue = parseFloat(nextValue);
        } else {
          nextValue = parseInt(nextValue);
        }
      } catch (e) {
        nextValue = 0;
      }
    }
    if (formatType === "percent" && nextValue !== "") {
      nextValue = removeAllFromString(nextValue, percentageSymbol);
      nextValue = parseFloat(nextValue);
    }

    if (ignoreSpaces) {
      nextValue = removeAllFromString(nextValue, " ");
    }

    setTimeout(() => {
      if (useFormikOnBlur) {
        form.setFieldValue(field.name, nextValue === "" ? undefined : nextValue);
        form.setFieldTouched(field.name);
        form.validateField(field.name);
      }

      if (onBlur) {
        onBlur(nextValue);
      }
    }, 0);

    onBlurUnconditionally?.();
  };

  const _onBlurNumber = (value: string) => {
    let parseValue = Number(value);

    if (value) {
      if (formatType === "currency") {
        let nextValue = removeAllFromString(value, currencySymbol);
        nextValue = removeAllFromString(nextValue, thousandSeparator);
        parseValue = parseFloat(decimalSeparator === "," ? nextValue.replace(",", ".") : nextValue);
      }
      if (formatType === "number" || formatType === "plain-number") {
        try {
          const nextValue = removeAllFromString(value, thousandSeparator);
          if (decimalScale && decimalScale > 0) {
            parseValue = parseFloat(nextValue);
          } else {
            parseValue = parseInt(nextValue);
          }
        } catch (e) {
          parseValue = 0;
        }
      }
      if (formatType === "percent") {
        const nextValue = removeAllFromString(value, percentageSymbol);
        parseValue = parseFloat(nextValue);
      }

      if (ignoreSpaces) {
        const nextValue = removeAllFromString(value, " ");
        parseValue = parseFloat(nextValue);
      }

      setTimeout(() => {
        if (useFormikOnBlur) {
          form.setFieldValue(field.name, parseValue ?? "");
          form.setFieldTouched(field.name);
          form.validateField(field.name);
        }

        if (onBlur) {
          onBlur(parseValue.toString());
        }
      }, 0);
    }

    onBlurUnconditionally?.();
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    event.persist();
    let value = event.target.value;
    if (sliceOnMaxLength && maxLength) {
      const slicedValue = value.slice(0, maxLength);
      event.target.value = slicedValue;
      value = slicedValue;
    }

    setState({ event, value });

    if (!shouldDebounce) {
      field.onChange(event);
      onChange?.(value);
    }
  };

  useEffect(() => {
    if (field.value !== debouncedState.value) {
      setState({ value: field.value });
    }
  }, [field.value]);

  useEffect(() => {
    if (!formatType && shouldDebounce) {
      if (debouncedState.event) {
        field.onChange(debouncedState.event);
      }

      onChange?.(debouncedState.value);
    }
  }, [debouncedState]);

  const currentCharCount = Number(state.value ? state.value.length : 0);
  const remainingCharCount = maxLength ? maxLength - currentCharCount : 0;

  const defaultStyle = {
    width: size ? size : "auto",
    marginLeft: marginLeft ? marginLeft : 0
  };

  let nextSize = "100%";
  if (size) {
    nextSize = size.toString();
  } else {
    size = "100%";
  }
  if (paddingRight) {
    nextSize = "calc(" + size + " - " + paddingRight + "px)";
  }

  const inputStyle = style
    ? { ...style, ...defaultStyle }
    : {
        width: nextSize,
        marginLeft: marginLeft ? marginLeft : 0,
        flex: size ? "none" : 1,
        marginTop: marginTop || "0px",
        paddingRight: paddingRight || "0px",
        paddingLeft: paddingLeft || "0px"
      };

  const _textAlign = useMemo(() => {
    if (textAlign) {
      return textAlign;
    }
    if (formatType === "currency" || formatType === "percent") {
      return "right";
    }
    return "left";
  }, [textAlign]);

  const _onKeyUp = (e: any) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (onSubmit) {
        onSubmit(e);
      }
    }
  };

  const _onClick = (ev: any) => {
    if (onClick) {
      onClick(ev);
    }
  };

  const onBlurCallback = useMemo(() => {
    let onBlurCallback: any = _onBlurText;
    if (asyncAutoCompleteInstance) {
      onBlurCallback = undefined;
    }
    // if (ignoreSpaces === undefined && onBlur === undefined) {
    //   onBlurCallback = undefined;
    // }
    return onBlurCallback;
  }, []);

  const renderFieldAccordingToType = () => {
    if (formatType) {
      return (
        <NumberTextField
          id={id}
          required={required}
          variant={variant || "outlined"}
          label={label}
          onFocus={onFocus}
          onBlur={_onBlurNumber}
          onChange={onChange}
          form={form}
          field={field}
          style={inputStyle}
          dataCy={dataCy || field.name}
          formatType={formatType}
          allowNegative={allowNegative}
          textAlign={_textAlign}
          decimalScale={decimalScale}
          InputProps={_InputProps}
          InputLabelProps={_InputLabelProps}
          fieldSize={fieldSize}
          disabled={disabled}
          maxNumber={maxNumber}
          minNumber={minNumber}
          format={format}
          warning={warning}
          placeholder={placeholder}
          shouldDebounce={shouldDebounce}
          autoFocus={autoFocus}
        />
      );
    }

    return (
      <TextField
        InputProps={{
          ..._InputProps,
          onFocus,
          onKeyUp: _onKeyUp,
          onClick: _onClick,
          onBlur: onBlurCallback,
          autoComplete: autoComplete || "off",
          startAdornment,
          endAdornment: EndAdornmentIcon ? (
            <EndAdornmentIcon
              style={{
                width: endAdornmentSize || "auto",
                height: endAdornmentSize || "auto"
              }}
            />
          ) : null
        }}
        placeholder={placeholder}
        autoFocus={autoFocus}
        InputLabelProps={_InputLabelProps}
        id={id || field.name}
        data-cy={dataCy || field.name}
        variant={variant || "outlined"}
        label={label}
        style={inputStyle}
        classes={{
          root: clsx([
            classes.root,
            warning ? classes.warning : "",
            deviceClassName,
            fieldSize,
            multiline ? "DLUI_Multiline" : "",
            multiLineSize ? "DLUI_MultiLine_" + multiLineSize : "",
            state.value !== "" ? "notEmpty" : "",
            ...Object.values(textFieldClasses)
          ])
        }}
        type={TextFieldType || "string"}
        field={{ ...field, value: state.value, onChange: handleChange }}
        form={form}
        required={required}
        multiline={multiline}
        disabled={disabled}
        {...rest}
      />
    );
  };

  const renderError = () => {
    if (!formatType || hideErrorText) {
      // we don't need to manually show the error since
      // typeless input uses formik's input
      return null;
    }

    const errorText = getIn(form.errors, field.name);
    const touchedVal = getIn(form.touched, field.name);
    const hasError = touchedVal && errorText !== undefined;

    return (
      <View
        shouldShow={hasError !== undefined && Boolean(hasError)}
        showAnimation={"fade-in"}
        hideAnimation={"fade-out"}
        marginTop={4}
        marginLeft={4}
        marginRight={4}
        height={18}
        style={{ lineHeight: "14px" }}
      >
        <Text fontSize={12} color={warning ? "yellow" : "error"} variant={"span"}>
          {errorText}
        </Text>
      </View>
    );
  };

  if (viewOnly || isReadOnlyContext) {
    return (
      <ViewOnlyInput
        value={_.isString(state.value) || _.isNumber(state.value) ? state.value : state.value}
        label={label || ""}
        backgroundColor={"transparent"}
        formatType={formatType}
        marginTop={marginTop}
        alignItems={!viewOnly ? "flex-start" : undefined}
        fullWidth={formatType !== undefined}
      />
    );
  }

  return (
    <View
      className={clsx(
        [variant ? "textField_" + variant : ""],
        textTransform && textTransform === "uppercase" ? "uppercase-input" : ""
      )}
      width={autoWidth ? "auto" : "100%"}
    >
      {renderFieldAccordingToType()}
      {renderError()}
      {maxLength && (
        <Text
          value={AppStrings.Announcements.xCharacters}
          replaceObject={{ currentCharCount, maxCharCount: maxLength }}
          fontSize={12}
          marginTop={10}
          color={remainingCharCount < 0 ? "error" : "secondary-gray"}
          bold
          style={maxLengthStyle}
        />
      )}
    </View>
  );
};

export default DLTextField;
