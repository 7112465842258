import _ from "lodash";
import { asyncUtils } from "@doorloop/utils";

export interface DispatchPayloadResultType {
  message: string;
  data?: any;
  fileName?: string;
}

export interface Operation {
  action: () => Promise<DispatchPayloadResultType>;
  ongoingMessage: string;
}

export type OperationsBuilder = (resourceId: string) => Operation[];

interface FailedOperation extends Operation {
  failReason: string;
}

interface SkippedOperation extends Operation {
  skipReason: string;
}

interface DispatchPayload {
  ongoingMessage?: string;
  progress?: number;
  finish?: ExecutionResult;
  result?: DispatchPayloadResultType;
  type?: symbol;
}
export const BulkOperationSkipped = Symbol("BulkOperationSkipped");

export type Dispatcher = (error: string | null, payload: DispatchPayload) => void;

export interface ExecutionResult {
  allSucceeded: boolean;
  failedOperations: FailedOperation[];
  skippedOperations?: SkippedOperation[];
}

const debugPreActionDelay = 0;

export class BulkOperationsEngine {
  private failedOperations: FailedOperation[] = [];
  private skippedOperations: SkippedOperation[] = [];
  private dispatcher?: Dispatcher;
  private completedCount = 0;

  async execute(
    operations: Operation[],
    dispatcher: Dispatcher,
    asynchronous?: boolean,
    resumingExecution?: boolean
  ): Promise<void> {
    if (!resumingExecution) {
      this.completedCount = 0;
    }
    const failedOperations: FailedOperation[] = [];
    const skippedOperations: SkippedOperation[] = [];
    const executeOperation = async (operation: Operation) => {
      const { action, ongoingMessage } = operation;
      dispatcher(null, {
        ongoingMessage
      });
      if (debugPreActionDelay) {
        await asyncUtils.sleep(debugPreActionDelay);
      }
      await action()
        .then((result) => {
          dispatcher(null, { progress: ++this.completedCount, result });
        })
        .catch((failReason) => {
          if (failReason?.type === BulkOperationSkipped) {
            dispatcher(failReason.message, {
              type: BulkOperationSkipped
            });
            skippedOperations.push({ ...operation, skipReason: failReason.message });
          } else {
            dispatcher(failReason, {});
            failedOperations.push({ ...operation, failReason });
          }
        });
    };
    if (asynchronous) {
      await Promise.all(operations.map(executeOperation));
    } else {
      await asyncUtils.map(operations, executeOperation);
    }
    dispatcher(null, {
      finish: {
        allSucceeded: _.isEmpty(failedOperations) && _.isEmpty(skippedOperations),
        failedOperations,
        skippedOperations
      }
    });
    this.failedOperations = failedOperations;
    this.skippedOperations = skippedOperations;
    this.dispatcher = dispatcher;
  }

  async retryFailed(newDispatcher?: Dispatcher, asynchronous?: boolean) {
    this.dispatcher &&
      (await this.execute(this.failedOperations, newDispatcher || this.dispatcher, asynchronous, true));
  }
}
