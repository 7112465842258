import React, { useMemo } from "react";
import { View } from "DLUI/view";
import type { EsignatureRequestSignerDto } from "@doorloop/dto";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import SignersListItem from "screens/esignatures/viewSignatureRequest/signersListItem";
import { ESignatureRequestSignerStatus } from "@doorloop/dto";
import type { ESignatureRequestStatus } from "@doorloop/dto";

interface ComponentProps {
  signers?: EsignatureRequestSignerDto[];
  createdAt?: Date;
  setSignersOrder?: boolean;
  requestStatus?: ESignatureRequestStatus;
}

const SignersList: React.FC<ComponentProps> = ({
  signers,
  createdAt,
  setSignersOrder,
  requestStatus
}: ComponentProps) => {
  const nextSignerIndex = useMemo(() => {
    if (!setSignersOrder) {
      return undefined;
    }
    return signers?.findIndex(
      (x) => x.status !== ESignatureRequestSignerStatus.SIGNED && x.status !== ESignatureRequestSignerStatus.DECLINED
    );
  }, [signers]);
  return (
    <View>
      <Text value={AppStrings.Esignatures.Signers} fontSize={18} marginTop={20} bold />
      <Text value={AppStrings.Esignatures.PreviewSignersListDescription} fontSize={16} marginTop={20} color={"gray"} />
      <View marginTop={20}>
        {signers?.map((signer, index) => (
          <SignersListItem
            nextSignerIndex={!setSignersOrder ? index : nextSignerIndex}
            index={index}
            createdAt={createdAt}
            signer={signer}
            key={index}
            requestStatus={requestStatus}
          />
        ))}
      </View>
    </View>
  );
};

export default SignersList;
