"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommunicationSubjectType = void 0;
var CommunicationSubjectType;
(function (CommunicationSubjectType) {
    CommunicationSubjectType["RENT_REMINDER"] = "RENT_REMINDER";
    CommunicationSubjectType["RENT_REMINDER_HOA"] = "RENT_REMINDER_HOA";
    CommunicationSubjectType["WELCOME_MOVE_IN_TENANT"] = "WELCOME_MOVE_IN_TENANT";
    CommunicationSubjectType["PAYMENT_RECEIVED"] = "PAYMENT_RECEIVED";
    CommunicationSubjectType["RENT_OVERDUE"] = "RENT_OVERDUE";
    CommunicationSubjectType["RENT_OVERDUE_HOA"] = "RENT_OVERDUE_HOA";
    CommunicationSubjectType["TENANT_PORTAL_INVITE"] = "TENANT_PORTAL_INVITE";
    CommunicationSubjectType["TENANT_PORTAL_INVITE_HOA"] = "TENANT_PORTAL_INVITE_HOA";
    CommunicationSubjectType["OWNER_PORTAL_INVITE"] = "OWNER_PORTAL_INVITE";
    CommunicationSubjectType["RENTAL_APPLICATION_INVITE"] = "RENTAL_APPLICATION_INVITE";
    CommunicationSubjectType["RENT_CHARGED"] = "RENT_CHARGED";
    CommunicationSubjectType["RENT_CHARGED_HOA"] = "RENT_CHARGED_HOA";
    CommunicationSubjectType["TENANT_LEASE_REVERSED_PAYMENT"] = "TENANT_LEASE_REVERSED_PAYMENT";
    CommunicationSubjectType["LATE_FEE"] = "LATE_FEE";
    CommunicationSubjectType["LATE_FEE_HOA"] = "LATE_FEE_HOA";
    CommunicationSubjectType["BEFORE_LATE_FEE"] = "BEFORE_LATE_FEE";
    CommunicationSubjectType["BEFORE_LATE_FEE_HOA"] = "BEFORE_LATE_FEE_HOA";
    CommunicationSubjectType["ANNOUNCEMENT"] = "ANNOUNCEMENT";
    CommunicationSubjectType["WELCOME_TEXT_MESSAGE"] = "WELCOME_TEXT_MESSAGE";
})(CommunicationSubjectType || (exports.CommunicationSubjectType = CommunicationSubjectType = {}));
