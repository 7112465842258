import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import type { ManagementFeesCompanySettingsClientDto, ManagementFeesPropertySettingsDto } from "@doorloop/dto";
import {
  createValidator,
  DateFormats,
  ManagementFeesPropertySettingsClientDto,
  PayOutManagementAccounts,
  SettingPermission
} from "@doorloop/dto";
import { v4 as uuid } from "uuid";
import { ManagementFeesSettingsDialog } from "screens/settings/managementFees/managementFeesSettingsDialog";
import {
  ManagementFeesDialogFrameHeight,
  ManagementFeesDialogFrameWidth
} from "screens/settings/managementFees/managementFeesFormikContextWrapper";
import { useParams } from "react-router-dom";
import { propertiesApi } from "api/propertiesApi";
import { validateManagementFeeForm } from "screens/settings/managementFees/formElements/utils";
import moment from "moment";
import { settingsApi } from "api/settingsApi";
import AppStrings from "../../../../../locale/keys";
import _ from "lodash";
import { usePermission } from "screens/settings/userRoles/usePermission";
import { buildViewOrEditSettingsClearances } from "screens/settings/userRoles/clearanceTypes";
import { RestrictedAccessHasAny } from "screens/settings/userRoles/restrictedAccessHasAny";
import { prepareManagementFeesValuesForSubmit } from "DLUI/dialogs/settings/properties/utils";

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
  property?: string;
  onSaveButtonPress?: (values) => void;
}

const formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

const validateForm = createValidator(ManagementFeesPropertySettingsClientDto);

export const FormDefaultValues: ManagementFeesPropertySettingsClientDto = {
  fees: {
    feeRules: [],
    chargeMinChargeFee: false,
    vendor: undefined,
    managementAccounts: []
  },
  payOutAccounts: [],
  collectManagementFees: true,
  nextBillStartDate: moment().format(DateFormats.ISO_DATE_SERVER_FORMAT).toString()
};

const ManagementFeesPropertyFormikContextWrapper: React.FC<ComponentProps> = ({
  onClose,
  onBackdropClick,
  dialogTitle,
  property,
  onSaveButtonPress
}: ComponentProps) => {
  const { hasAnyPermission } = usePermission();
  const params = useParams<any>();
  const propertyId = property || params?.propertyId;
  const { t } = useTranslation();
  const [settingsData, setSettingsData] = useState<ManagementFeesPropertySettingsClientDto | undefined>();
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.Show);
  const [errorText, setErrorText] = useState<string>("");

  const hasViewOrEditPermission = hasAnyPermission(buildViewOrEditSettingsClearances(SettingPermission.managementFees));

  const showErrorMessage = (error: string) => {
    setDialogState(DialogState.Error);
    setErrorText(error);
  };

  const loadData = async () => {
    if (propertyId && hasViewOrEditPermission) {
      let managementFeesResponseStatus: boolean | undefined;
      let managementFeesResponseMessage: string | undefined;

      const propertyManagementFeesResponse = await propertiesApi.getManagementFees(propertyId);
      managementFeesResponseStatus = propertyManagementFeesResponse.status;
      const managementFeesData: ManagementFeesPropertySettingsDto = propertyManagementFeesResponse.data;
      managementFeesResponseMessage = propertyManagementFeesResponse.message;
      const feesForPropertyExists = propertyManagementFeesResponse.data.fees?.vendor !== undefined;

      if (managementFeesResponseStatus && !feesForPropertyExists) {
        const companyManagementFeesResponse = await settingsApi.getManagementFeesSettings();
        managementFeesResponseStatus = companyManagementFeesResponse.status;
        managementFeesData.fees = _.isEmpty(companyManagementFeesResponse.data)
          ? FormDefaultValues.fees
          : companyManagementFeesResponse.data;
        managementFeesResponseMessage = companyManagementFeesResponse.message;
      }

      if (managementFeesResponseStatus) {
        const payOutAccounts = managementFeesData?.fees?.managementAccounts?.map(
          (account) =>
            new PayOutManagementAccounts({
              incomeAccount: account,
              uniqueKey: uuid()
            })
        );
        setSettingsData(
          new ManagementFeesPropertySettingsClientDto({
            ...managementFeesData,
            payOutAccounts,
            useCompanyDefaultManagementFees: !feesForPropertyExists,
            nextBillStartDate: moment().format(DateFormats.ISO_DATE_SERVER_FORMAT).toString()
          })
        );
        setDialogState(DialogState.Hidden);
      } else {
        showErrorMessage(managementFeesResponseMessage || t(AppStrings.Common.GeneralError));
      }
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  if (dialogState !== DialogState.Hidden) {
    return (
      <View width={ManagementFeesDialogFrameWidth} height={ManagementFeesDialogFrameHeight} backgroundColor={"light"}>
        <RestrictedAccessHasAny
          showNoAccess
          permissions={buildViewOrEditSettingsClearances(SettingPermission.managementFees)}
        >
          <View
            width={ManagementFeesDialogFrameWidth}
            height={ManagementFeesDialogFrameHeight}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <LoadingDialog
              dialogState={dialogState}
              errorText={errorText}
              didPressDismissButton={onBackdropClick}
              onRetryButtonPress={loadData}
              minHeight={ManagementFeesDialogFrameHeight}
            />
          </View>
        </RestrictedAccessHasAny>
      </View>
    );
  }

  const createManagementFeesForProperty = async (
    values: ManagementFeesPropertySettingsDto | ManagementFeesCompanySettingsClientDto
  ) => {
    const _values = prepareManagementFeesValuesForSubmit(values);

    return await propertiesApi.updateManagementFees(propertyId, _values as ManagementFeesPropertySettingsDto);
  };

  if (!settingsData) {
    return null;
  }

  return (
    <Formik initialValues={settingsData} onSubmit={() => {}} validate={validateForm}>
      <ManagementFeesSettingsDialog
        onBackdropClick={onBackdropClick}
        dialogTitle={dialogTitle}
        onClose={onClose}
        validateForm={async (formikRef) => await validateManagementFeeForm(formikRef, "fees")}
        apiMethod={createManagementFeesForProperty}
        propertyId={propertyId}
        onSaveButtonPress={onSaveButtonPress}
      />
    </Formik>
  );
};

export default ManagementFeesPropertyFormikContextWrapper;
