import type { ParsedQuery } from "query-string";
import qs from "query-string";
import { history } from "store/history";

export class QueryParams<T extends string = string> {
  queryObject: ParsedQuery;

  constructor(location?: string) {
    this.queryObject = qs.parse(location || window.location.search);
  }

  get(field: T) {
    return this.queryObject[field];
  }

  set(field: T, value: string): void {
    this.queryObject[field] = value;
  }

  setMultiple(fields: Record<T, string>) {
    this.queryObject = {
      ...this.queryObject,
      ...fields
    };
  }

  clear(field: T): void {
    delete this.queryObject[field];
  }

  getAll(): Record<T, string | string[]> {
    this.updateFromUrl();
    return this.queryObject as Record<T, string | string[]>;
  }

  getQueryString(): string {
    return qs.stringify(this.queryObject);
  }

  historyPush(): void {
    history.push({
      search: this.getQueryString()
    });
  }

  historyReplace(): void {
    history.replace({
      search: this.getQueryString()
    });
  }

  private updateFromUrl(location?: string) {
    this.queryObject = qs.parse(location || window.location.search);
  }

  static get(field: string) {
    return qs.parse(window.location.search)[field] as string | undefined;
  }
}
