import { useTypedTranslation } from "@/locale";
import type { CurrentTenantPortalLeaseDto } from "@doorloop/dto";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import type { IRootState } from "store";

const MAX_UNITS_TO_DISPLAY_WITH_DETAILS = 3;

export const useAddressToDisplay = () => {
  const { t } = useTypedTranslation();
  const { loginResponses } = useSelector((state: IRootState) => state.auth);

  const getUnitName = (currentLease?: CurrentTenantPortalLeaseDto) => {
    if (!currentLease || !currentLease?.units || !currentLease.units.length) return "";

    const { units } = currentLease;

    const concatenatedUnits = units.reduce((unitName, unit, i) => (unitName += `${!i ? " / " : ", "}${unit.name}`), "");
    const isNotOverMaxUnitsToDisplay = units?.length <= MAX_UNITS_TO_DISPLAY_WITH_DETAILS;

    return isNotOverMaxUnitsToDisplay ? concatenatedUnits : t("common.xUnits", { x: units.length });
  };

  const getAddressToDisplay = (currentLease?: CurrentTenantPortalLeaseDto) => {
    if (!currentLease) return "";

    const unitStreet1 = currentLease?.units?.[0]?.address?.street1;
    const propertyName = currentLease?.property?.name;
    const isAnyUnitHasDifferentAddressThanProperty = currentLease?.units?.some(
      (u) => u?.address && u?.address?.street1 !== currentLease?.property?.name
    );

    return isAnyUnitHasDifferentAddressThanProperty
      ? `${unitStreet1} ${getUnitName(currentLease)}`
      : `${propertyName} ${getUnitName(currentLease)}`;
  };

  const addressesToDisplay = useMemo(
    (): Array<[CurrentTenantPortalLeaseDto | undefined, string]> =>
      loginResponses?.map(({ currentLease }) => [currentLease, getAddressToDisplay(currentLease)]) || [],
    [loginResponses]
  );

  return { getAddressToDisplay, getUnitName, addressesToDisplay };
};
