import { ObjectPermission } from "@doorloop/dto";
import DLButton from "DLUI/button/dlButton";
import EmptyDataView from "DLUI/emptyDataView";
import AppStrings from "locale/keys";
import React from "react";
import { history } from "store/history";
import Routes from "../../../appRouter/routes";

const PropertiesEmptyDataView = () => {
  const didPressAddNewProperty = () => {
    history.push(Routes.NEW_PROPERTY);
  };

  return (
    <EmptyDataView
      instructionsText={AppStrings.Properties.Screen.EmptydataViewInstructionsText}
      actionButton={
        <DLButton
          clearance={{
            permission: ObjectPermission.properties,
            field: "create"
          }}
          actionText={AppStrings.Properties.Screen.AddNewProperty}
          onClick={didPressAddNewProperty}
          disableMobileCollapse
          icons={{ start: { isHidden: false } }}
        />
      }
    />
  );
};

export default PropertiesEmptyDataView;
