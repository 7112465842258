"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BudgetDto = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const base_dto_1 = require("../base.dto");
const common_1 = require("../common");
const budgetPeriodType_enum_1 = require("./enums/budgetPeriodType.enum");
const budgetType_enum_1 = require("./enums/budgetType.enum");
const budgetReportType_1 = require("./enums/budgetReportType");
const reportRepsonseBase_dto_1 = require("../reports/reportRepsonseBase.dto");
const budgetReference_dto_1 = require("./budgetReference.dto");
class BudgetDto extends base_dto_1.BaseDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.BudgetDto = BudgetDto;
__decorate([
    (0, class_validator_1.ValidateIf)((dto) => !dto.portfolio),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], BudgetDto.prototype, "property", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((dto) => !dto.property),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], BudgetDto.prototype, "portfolio", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, common_1.IsDateStringWithoutTime)()
    /** must be start of month */
    ,
    __metadata("design:type", String)
], BudgetDto.prototype, "fiscalYearStartDate", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, common_1.IsDateStringWithoutTime)()
    /** will be auto calc by the client as moment(this.fiscalYearStartDate).add(1, "y").subtract(1, "d").format(DateFormats.ISO_DATE_SERVER_FORMAT) */
    ,
    __metadata("design:type", String)
], BudgetDto.prototype, "fiscalYearEndDate", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(1000)
    /** must be unique for each dbTenent */
    ,
    __metadata("design:type", String)
], BudgetDto.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(budgetPeriodType_enum_1.BudgetPeriodType)
    /** client will set the default to BudgetPeriodType.MONTH */
    ,
    __metadata("design:type", String)
], BudgetDto.prototype, "periodType", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(budgetReportType_1.BudgetReportType)
    /** client will set the default to BudgetReportType.PROFIT_AND_LOSS */
    ,
    __metadata("design:type", String)
], BudgetDto.prototype, "reportType", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(budgetType_enum_1.BudgetType)
    /** used by the BudgetReferenceInput component */
    ,
    __metadata("design:type", String)
], BudgetDto.prototype, "type", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => budgetReference_dto_1.BudgetReferenceDto)
    /** used by the BudgetReferenceInput component */
    ,
    __metadata("design:type", budgetReference_dto_1.BudgetReferenceDto)
], BudgetDto.prototype, "reference", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => reportRepsonseBase_dto_1.ReportResponseBase)
    /** BudgetItemDto.accountId should be unique within the list */
    ,
    __metadata("design:type", reportRepsonseBase_dto_1.ReportResponseBase)
], BudgetDto.prototype, "report", void 0);
