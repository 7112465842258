import { ServerRoutes } from "@doorloop/dto";
import type { GetAllLeaseDraftsQuery, LeaseDraftDto } from "@doorloop/dto";
import { RestApiBase } from "./restApiBase";
import { apiHelper } from "./apiHelper";
import { propertiesApi } from "./propertiesApi";
import { unitsApi } from "./unitsApi";
import AppStrings from "../locale/keys";

class LeaseDraftApi extends RestApiBase<LeaseDraftDto, GetAllLeaseDraftsQuery> {
  constructor() {
    super(ServerRoutes.LEASE_DRAFTS, [propertiesApi, unitsApi]);
    this.restRoute = ServerRoutes.LEASE_DRAFTS;
  }

  getLeaseDetails = async (leaseId: string) => await apiHelper.axiosGet(ServerRoutes.LEASE_DRAFTS + "/" + leaseId);
  cancelLease = async (leaseId: string) =>
    await apiHelper.axiosPost({
      url: ServerRoutes.LEASE_DRAFTS + "/" + leaseId + "/cancel",
      toasts: { translationKey: AppStrings.Toasts.custom.lease.cancel?.POST }
    });
  activateLease = async (leaseId: string) =>
    await apiHelper.axiosPost({
      url: ServerRoutes.LEASE_DRAFTS + "/" + leaseId + "/activate",
      toasts: { translationKey: AppStrings.Toasts.custom.lease.activate?.POST }
    });
}

export const leaseDraftApi = new LeaseDraftApi();
