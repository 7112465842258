import DLButton, { DLButtonColorsEnum } from "DLUI/button/dlButton";
import { Link } from "DLUI/link";
import { LinkURLsEnum } from "DLUI/link/linkURLsEnum";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import type { FC } from "react";
import React from "react";
import ReactPlayer from "react-player/lazy";
import { openNewTabAndFocus } from "../../../../utils/navigation";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface Props {}

export const SendingLimitsTab: FC<Props> = ({}: Props) => {
  const { isTabletOrMobile } = useResponsiveHelper();
  const didPressSubmitRequest = () => {
    openNewTabAndFocus(`https://app.checkbook.io/account/settings/profile`);
  };
  return (
    <View flex={1}>
      <View>
        <Text fontSize={18} bold value={AppStrings.Common.OutgoingPayments.ModifyLimit} />
        <Text fontSize={16} value={AppStrings.Common.OutgoingPayments.ModifyLimitDesc} marginTop={10} />
        <DLButton
          color={DLButtonColorsEnum.SECONDARY}
          onClick={didPressSubmitRequest}
          actionText={AppStrings.Common.OutgoingPayments.SubmitARequest}
          icons={{ start: { isHidden: false } }}
          style={{ marginTop: 20 }}
        />
        <HorizontalSeparationLine marginTop={20} />
        <Text fontSize={18} bold value={AppStrings.Common.PrintChecks.Setup.Reader.Default.NeedHelp} marginTop={20} />
        <View flexDirection={"row"} marginTop={10}>
          <Text
            fontSize={16}
            value={AppStrings.Common.OutgoingPayments.FindOutHowToIncreaseYouLimits}
            marginRight={5}
          />
          <Link type={"tab"} hoverColor={"lightBlue"} hrefUrl={LinkURLsEnum.CB_LEARN_MORE}>
            <Text color={"lightBlue"} value={AppStrings.Accounts.AccountInfo.LearnMore} />
          </Link>
        </View>
        <View marginTop={10} alignItems={"center"}>
          <ReactPlayer
            url="https://www.youtube.com/watch?v=FB1U14QVV5U"
            controls={true}
            config={{
              wistia: {
                options: {
                  silentAutoPlay: "false",
                  videoFoam: true,
                  playsInline: false,
                  copyLinkAndThumbnailEnabled: false,
                  endVideoBehavior: "reset",
                  fullscreenButton: true
                }
              }
            }}
            style={{ border: 0 }}
            width={isTabletOrMobile ? 400 : window.innerWidth < 1240 ? 600 : 800}
            height={398}
          />
        </View>
      </View>
    </View>
  );
};
