import type { CSSProperties, FC } from "react";
import { Chip as ChipMui } from "@material-ui/core";
import { Icon } from "DLUI/icon";
import type { SVGIconComponent } from "../../../assets";
import Text from "../text";
import type { UseStylesChipProps } from "./useStylesChip";
import { useStylesChip } from "./useStylesChip";
import { ChipColorsEnum, ChipSizesEnum, ChipVariantsEnum } from "./enums";
import clsx from "clsx";

export interface ChipProps extends Omit<UseStylesChipProps, "isLabel"> {
  isDisabled?: boolean;
  size?: ChipSizesEnum;
  isClickable?: boolean;
  removeTextPadding?: boolean;
  noHover?: boolean;
  labelProps?: {
    translationKey: string;
    size?: number;
    isBold?: boolean;
  };
  iconProps?: {
    src: SVGIconComponent;
    size?: number;
  };
  deleteIcon?: React.ReactElement<any, any>;
  style?: CSSProperties;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onDelete?: () => void;
  withDivider?: boolean;
  isMobile?: boolean;
}

const Chip: FC<ChipProps> = ({
  labelProps,
  iconProps,
  isRectangle = false,
  isDisabled = false,
  isClickable = false,
  removeTextPadding,
  size = ChipSizesEnum.MEDIUM,
  color = ChipColorsEnum.PRIMARY,
  variant = ChipVariantsEnum.DEFAULT,
  noHover,
  classNames = {},
  style,
  className,
  onClick,
  onDelete,
  deleteIcon,
  withDivider = false,
  isMobile = false
}) => {
  const classes = useStylesChip({
    isLabel: Boolean(labelProps),
    isRectangle,
    variant,
    color,
    classNames,
    noHover,
    removeTextPadding,
    isMobile
  });

  return (
    <ChipMui
      className={clsx([classes.container, className])}
      clickable={isClickable}
      size={size}
      variant={variant}
      disabled={isDisabled}
      label={
        labelProps && (
          <Text
            bold={labelProps.isBold}
            className={classes.text}
            value={labelProps.translationKey}
            fontSize={labelProps.size}
          />
        )
      }
      icon={
        iconProps?.src && (
          <>
            <Icon
              Source={iconProps.src}
              size={iconProps.size || (size === ChipSizesEnum.MEDIUM ? 18 : 14)}
              className={classes.icon}
              marginLeft={5}
              marginRight={-6}
            />
            {withDivider && <div className={classes.divider} />}
          </>
        )
      }
      style={style}
      onClick={onClick}
      onDelete={onDelete}
      deleteIcon={deleteIcon}
    />
  );
};

export { Chip };
