"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CaProvinces = void 0;
var CaProvinces;
(function (CaProvinces) {
    CaProvinces["ON"] = "ON";
    CaProvinces["QC"] = "QC";
    CaProvinces["NS"] = "NS";
    CaProvinces["NB"] = "NB";
    CaProvinces["MB"] = "MB";
    CaProvinces["BC"] = "BC";
    CaProvinces["PE"] = "PE";
    CaProvinces["SK"] = "SK";
    CaProvinces["AB"] = "AB";
    CaProvinces["NL"] = "NL";
})(CaProvinces || (exports.CaProvinces = CaProvinces = {}));
