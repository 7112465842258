import type { CurrentDbTenantDto } from "@doorloop/dto";
import type { TenantPortalLeaseDto } from "@doorloop/dto";
import type { TenantPortalAction } from "./tenantPortalActions";
import * as Types from "./types";

export interface ITenantPortalState {
  tenantPortalLease: TenantPortalLeaseDto | null;
  dbTenant: CurrentDbTenantDto | null;
}

const tenantPortalReducer = (
  state: ITenantPortalState = {
    tenantPortalLease: null,
    dbTenant: null
  },
  action: TenantPortalAction
) => {
  switch (action.type) {
    case Types.UPDATE_TENANT_PORTAL_LEASE: {
      return {
        ...state,
        tenantPortalLease: action.tenantPortalLease
      };
    }
    case Types.UPDATE_TENANT_PORTAL_DB_TENANT: {
      return {
        ...state,
        dbTenant: action.dbTenant
      };
    }
  }
  return state;
};

export default tenantPortalReducer;
