import React from "react";
import { propertiesApi } from "api/propertiesApi";
import PropertyListItem from "./propertyListItem";
import { ObjectPermission } from "@doorloop/dto";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import { DataList } from "DLUI/dataList";
import type { GetAllPropertiesQuery, PropertyDto } from "@doorloop/dto";
import PropertiesEmptyDataView from "./propertiesEmptyDataView";

interface PropertiesListProps {
  didChangeLoadingDataState?: (loadingInProgress: boolean) => void;
  filterObject: GetAllPropertiesQuery;
  disableListItemOptions?: boolean;
  onItemPress?: (propertyId: string, propertyDto?: PropertyDto) => void;
  scrollableTarget?: string;
  stickyHeaderId?: string;
  removeDefaultBottomPadding?: boolean;
}

const PropertiesList: React.FC<PropertiesListProps> = ({
  didChangeLoadingDataState,
  filterObject,
  disableListItemOptions,
  onItemPress,
  scrollableTarget,
  stickyHeaderId,
  removeDefaultBottomPadding
}) => (
  <RestrictedPermissionAccess
    clearance={{
      permission: ObjectPermission.properties,
      field: "viewList"
    }}
    showNoAccess
  >
    <DataList
      infinite
      restApi={propertiesApi}
      queryFilter={filterObject}
      onLoadingDataStateChange={didChangeLoadingDataState}
      emptyListView={PropertiesEmptyDataView}
      paddingEnd={removeDefaultBottomPadding ? 0 : undefined}
      stickyHeaderProps={{
        id: stickyHeaderId
      }}
      scrollElement={scrollableTarget ? document.getElementById(scrollableTarget) : undefined}
    >
      {(item) => (
        <div style={{ width: "100%" }}>
          <PropertyListItem
            disableListItemOptions={disableListItemOptions}
            propertyDto={item}
            onItemPress={onItemPress}
          />
        </div>
      )}
    </DataList>
  </RestrictedPermissionAccess>
);

export default PropertiesList;
