import type { FC } from "react";
import React, { useEffect } from "react";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import type { PropertyDto } from "@doorloop/dto";
import { ObjectPermission } from "@doorloop/dto";
import { View } from "DLUI/view";
import { useFormikContext } from "formik";
import PropertyType from "@/components/screens/properties/newProperty/components/propertyType/propertyType";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";

import { useResponsiveHelper } from "../../../../../../contexts/responsiveContext";

export type PropertyTypeChange = (newType: string) => void;

interface Props {
  onTypeChange: PropertyTypeChange;
}

export const EditPropertyClassAndType: FC<Props> = ({ onTypeChange }: Props) => {
  const { size } = useResponsiveHelper();
  const formikRef = useFormikContext<PropertyDto>();

  useEffect(() => {
    formikRef.values.class && onTypeChange(formikRef.values.class);
  }, [formikRef.values.class]);

  return (
    <View flex={1} alignItems={"center"} width={"100%"}>
      <RestrictedPermissionAccess
        clearance={{
          permission: ObjectPermission.properties,
          field: "edit"
        }}
        showNoAccess
      >
        <HorizontalSeparationLine />
        {formikRef && (
          <View maxWidth={700} noWrap>
            <PropertyType
              scrollerRef={null}
              name={"type"}
              typeDescriptionName={"typeDescription"}
              hideTitle
              flexDirection={"center"}
              validationMaxWidth={375}
              onMainTypeChange={onTypeChange}
              customSize={size === "xs" ? 130 : 160}
            />
          </View>
        )}
      </RestrictedPermissionAccess>
    </View>
  );
};
