import type { Action } from "redux";
import type { Method } from "axios";

import type { Navigation } from "../../utils/navigation";

import { ACTION_TYPE_CLEAR_TOAST, ACTION_TYPE_SET_TOAST, EXCLUDED_URLS_WITH_NO_ID } from "./utils";
import type { ToastSeverity } from "./types";
import type { NavigationActionType } from "./enums";

export interface NavigationActionPropsDetails {
  id: string;
}

export interface NavigationActionPropsTab extends NavigationActionPropsDetails {
  tab: string;
}

export interface NavigationActionPropsReport {
  params: Record<string, string>;
}

export type NavigationActionProps =
  | NavigationActionPropsDetails
  | NavigationActionPropsTab
  | NavigationActionPropsReport;

export type NavigationActionName = keyof Navigation;

export interface NavigationActionBase {
  isHide: boolean;
  translationKey: string;
  type: NavigationActionType;
  name: NavigationActionName;
  props?: NavigationActionProps;
}

export interface NavigationActionEmpty extends NavigationActionBase {
  type: NavigationActionType.EMPTY;
}

export interface NavigationActionDetails extends NavigationActionBase {
  type: NavigationActionType.DETAILS;
  props: NavigationActionPropsDetails;
}

export interface NavigationActionTab extends NavigationActionBase {
  type: NavigationActionType.TAB;
  props: NavigationActionPropsTab;
}

export interface NavigationActionReport extends NavigationActionBase {
  type: NavigationActionType.REPORT;
  props: NavigationActionPropsReport;
}

export type NavigationAction =
  | NavigationActionEmpty
  | NavigationActionDetails
  | NavigationActionTab
  | NavigationActionReport;

export type ToastStateAction = NavigationActionBase & {
  props: Partial<NavigationActionPropsDetails & NavigationActionPropsTab & NavigationActionPropsReport>;
};

export interface ToastStateValid {
  severity: ToastSeverity;
  keepMounted?: boolean;
  endpoint?: string;
  method?: Method;
  translationKey?: string;
  action?: Partial<ToastStateAction>;
  verticalOffset?: number;
}

// Action Definition
export interface SetToastReducerAction extends Action {
  type: typeof ACTION_TYPE_SET_TOAST;
  state: ToastStateValid;
}

export interface ClearToastReducerAction extends Action {
  type: typeof ACTION_TYPE_CLEAR_TOAST;
}

// Union Action Types
export type ToastStateObject = SetToastReducerAction;

export type ToastReducerAction = ToastStateObject | ClearToastReducerAction;

// Action Creators
export function handleToast(state: ToastStateValid): SetToastReducerAction {
  if (state.endpoint) {
    let endpoint = state.endpoint.at(-1) === "/" ? state.endpoint.slice(0, -1) : state.endpoint;

    if (state.method) {
      const isPost = ["POST", "post"].includes(state.method);
      const isExcluded = EXCLUDED_URLS_WITH_NO_ID.some((url) => Boolean(state.endpoint?.includes(url)));

      if (!isPost && !isExcluded) {
        const list = endpoint.split("/").slice(0, -1);

        endpoint = "";

        list.forEach((item, index) => {
          endpoint += `${item}${index === list.length - 1 ? "" : "/"}`;
        });
      }
    }

    return {
      type: ACTION_TYPE_SET_TOAST,
      state: { ...state, endpoint }
    };
  }

  return {
    type: ACTION_TYPE_SET_TOAST,
    state
  };
}

export const handleClearToast = (): ClearToastReducerAction => {
  return { type: ACTION_TYPE_CLEAR_TOAST };
};
