import type { FC } from "react";
import React from "react";
import { BlueCircleWhiteIIcon, BlueCircleWhiteXIcon, BluePlaneIcon, ICircleRefreshIcon } from "assets/icons";
import AppStrings from "locale/keys";
import { SendOutgoingPayment } from "screens/outgoingPayments/actions/sendOutgoingPayment";
import type { OutgoingPaymentsReportItemDto, ExpensePayToResourceType } from "@doorloop/dto";
import { NavigationManager, openNewTabAndFocus } from "utils/navigation";
import { GenericActionComponent } from "DLUI/button/genericActionComponent";
import { LinkURLsEnum } from "DLUI/link/linkURLsEnum";
import type { ShowConfirmationHandler } from "@/hooks/useConfirmationDialog";

export interface ActionComponentProps {
  paymentDto?: OutgoingPaymentsReportItemDto;
  clickOverride?: () => void;
  showConfirmationDialog?: ShowConfirmationHandler;
}

export const voidOutgoingPayment = (journalEntryId: string, showConfirmationDialog?: ShowConfirmationHandler) => {
  showConfirmationDialog?.(location.pathname, {
    dialogTitle: AppStrings.Common.VoidConfirmation,
    confirmButtonText: AppStrings.Common.OutgoingPayments.Void,
    dismissButtonText: AppStrings.Common.Dismiss,
    apiMethod: "outgoingPaymentsApi",
    dialogSubTitle: AppStrings.Common.OutgoingPayments.VoidConfirmationText,
    loadingText: AppStrings.Common.OutgoingPayments.VoidPaymentLoadingText,
    confirmOperation: "delete",
    itemId: journalEntryId
  });
};

const tabName = "outgoingpayments";
export const goToPayeesOutgoingPaymentsTab = (
  payToResourceId?: string,
  payToResourceType?: ExpensePayToResourceType,
  locationOverride?: string
) => {
  if (payToResourceId) {
    const locationBase = locationOverride || location.pathname;
    if (payToResourceType === "TENANT") {
      NavigationManager.editTenant(locationBase, payToResourceId, tabName);
    } else if (payToResourceType === "VENDOR") {
      NavigationManager.editVendor(locationBase, payToResourceId, tabName);
    } else if (payToResourceType === "OWNER") {
      NavigationManager.editOwner(locationBase, payToResourceId, tabName);
    }
  }
};

export type Actions = "send" | "resend" | "void" | "addInfo" | "notVerifiedBank";

export const outgoingPaymentActionByStatus: Record<Actions, FC<ActionComponentProps>> = {
  send: ({ paymentDto }: ActionComponentProps) => (
    <GenericActionComponent
      label={AppStrings.Common.OutgoingPayments.Send}
      IconSource={BluePlaneIcon}
      ActionDialog={({ onClose }) => <SendOutgoingPayment paymentDto={paymentDto} onClose={onClose} />}
    />
  ),
  resend: ({ paymentDto }: ActionComponentProps) => (
    <GenericActionComponent
      label={AppStrings.Common.OutgoingPayments.Resend}
      IconSource={ICircleRefreshIcon}
      ActionDialog={({ onClose }) => <SendOutgoingPayment paymentDto={paymentDto} onClose={onClose} />}
    />
  ),
  void: ({ paymentDto, showConfirmationDialog }: ActionComponentProps) => (
    <GenericActionComponent
      label={AppStrings.Common.OutgoingPayments.Void}
      IconSource={BlueCircleWhiteXIcon}
      actionHandler={() =>
        paymentDto?.journalEntry && voidOutgoingPayment(paymentDto.journalEntry, showConfirmationDialog)
      }
    />
  ),
  addInfo: ({ paymentDto, clickOverride }: ActionComponentProps) => (
    <GenericActionComponent
      label={AppStrings.Common.OutgoingPayments.AddInfo}
      IconSource={BlueCircleWhiteIIcon}
      actionHandler={
        clickOverride ||
        (() => goToPayeesOutgoingPaymentsTab(paymentDto?.payToResourceId, paymentDto?.payToResourceType))
      }
    />
  ),
  notVerifiedBank: () => (
    <GenericActionComponent
      label={AppStrings.Common.OutgoingPayments.UnverifiedAccount}
      IconSource={BlueCircleWhiteIIcon}
      iconTooltipText={AppStrings.Common.OutgoingPayments.EnsureBankVerified}
      actionHandler={() => openNewTabAndFocus(LinkURLsEnum.CB_ACCOUNT_SETTINGS)}
    />
  )
};
