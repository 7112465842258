import React, { useEffect, useRef, useState } from "react";
import { AutoComplete, TextField } from "DLUI/form";
import type { FieldInputProps, FieldProps } from "formik";
import "./styles.css";
import type {
  InternationalAndUS_SuggestionsType,
  US_SuggestionType
} from "DLUI/form/autoComplete/addressAutoComplete/smarty/dataSource";
import type DataSource from "DLUI/form/autoComplete/addressAutoComplete/smarty/dataSource";
import { useDebounce } from "../../../../../../hooks/useDebounce";
import SuggestionOption from "DLUI/form/autoComplete/addressAutoComplete/smarty/suggestionOption";
import { useEffectAsync } from "../../../../../../hooks/useEffectAsync";
import { TYPING_DEBOUNCE_AMOUNT } from "DLUI/form/autoComplete/addressAutoComplete/smarty/addressAutoCompleteForm";
import { get } from "lodash";
import type { AddressDisplayDto } from "@doorloop/dto";

export const serializeFullAddressOption = (option: InternationalAndUS_SuggestionsType) => {
  const label = `${option.streetLine || option.street} ${option.secondary || ""} ${option.city || option.locality}${
    option.state || option.administrative_area || option.administrativeArea
      ? `, ${option.state || option.administrative_area || option.administrativeArea}`
      : ""
  }${option.zipcode || option.postalCode ? `, ${option.zipcode || option.postalCode}` : ""}`;

  return label;
};

const ElementID = "AutoCompleteStreetAddress";

interface ComponentProps extends FieldProps<any> {
  label: string;
  className: string;
  serviceApi: DataSource;
  mainObjFormikName: string;
  onAddressAutocomplete: (selectedPlace: any) => void;
  required?: boolean;
  showSelectedOptionFullAddress?: boolean;
  field: FieldInputProps<string>;
}

const AddressAutoComplete = ({
  onAddressAutocomplete,
  serviceApi,
  showSelectedOptionFullAddress,
  field,
  mainObjFormikName,
  ...rest
}: ComponentProps) => {
  const hasSelectedAddress = useRef(false);
  const formValues: AddressDisplayDto = get(rest.form.values, mainObjFormikName);
  const debounceAddressField = useDebounce(field.value, TYPING_DEBOUNCE_AMOUNT + 200);
  const [suggestions, setSuggestions] = useState<InternationalAndUS_SuggestionsType[]>([]);
  const [preventClosePopper, setPreventClosePopper] = useState<boolean>(false);

  const onAutoCompleteSuggestions = (options: US_SuggestionType[] | InternationalAndUS_SuggestionsType[]) => {
    setSuggestions(
      options.map((suggestion) => {
        return {
          ...suggestion,
          displayName: showSelectedOptionFullAddress
            ? serializeFullAddressOption(suggestion)
            : suggestion.streetLine || suggestion.street
        };
      })
    );
  };

  const onAddressSelected = (selectedPlace: InternationalAndUS_SuggestionsType) => {
    if (selectedPlace && typeof selectedPlace !== "string") {
      onAddressAutocomplete(selectedPlace);

      if ((!selectedPlace.entries || selectedPlace.entries === 1) && !selectedPlace.addressId) {
        hasSelectedAddress.current = true;
      }

      if (serviceApi.locationIso3Code === "USA" && !formValues?.displayUS_Selected) {
        setSuggestions([]);
      } else {
        rest.form.setFieldValue(`${mainObjFormikName}.displayUS_Selected`, undefined);
      }
    }
  };

  useEffect(() => {
    serviceApi.init(onAutoCompleteSuggestions);

    setSuggestions([]);
  }, [serviceApi]);

  useEffectAsync(async () => {
    const addressValue = debounceAddressField?.trim();
    const allowSearching = !hasSelectedAddress.current && addressValue?.length;

    if (allowSearching) {
      await serviceApi.getOptions(
        {
          search: debounceAddressField,
          addressId: formValues.addressId,
          selected: formValues.displayUS_Selected?.toLowerCase().startsWith(debounceAddressField.toLowerCase())
            ? formValues.displayUS_Selected
            : undefined
        },
        onAddressSelected
      );

      rest.form.setFieldValue(`${mainObjFormikName}.addressId`, undefined);
    } else {
      hasSelectedAddress.current = false;
    }
  }, [debounceAddressField]);

  useEffect(() => {
    const keepPopperOpen = Boolean(formValues?.entries && formValues.entries > 1);

    setPreventClosePopper(!keepPopperOpen);
  }, [formValues?.entries, formValues?.zip]);

  return (
    <AutoComplete
      classes={{
        root: "AutoComplete",
        popper: !suggestions.length ? "AutoComplete-HidePopper" : ""
      }}
      uniqueIndex={ElementID}
      values={suggestions}
      selectedValue={field.value || ""}
      renderInput={(params) => <TextField {...params} id={ElementID + field.name} field={field} {...rest} />}
      renderOption={SuggestionOption}
      didSelectAutoComplete={onAddressSelected}
      clearOnBlur={false}
      freeSolo
      filterOptions={(options) => options}
      disableCloseOnSelect={preventClosePopper}
      {...rest}
    />
  );
};

export default AddressAutoComplete;
