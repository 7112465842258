import type { FC } from "react";
import React from "react";
import { View } from "DLUI/view";
import type { PropertyAmenity, UnitAmenity } from "@doorloop/dto";
import { CheckBoldIconCircle } from "assets/icons";
import { getAmenityLabel } from "DLUI/amenity/amenityToLabelMap";
import Text from "DLUI/text";
import { Icon } from "DLUI/icon";
import { ControlledCheckBox } from "DLUI/form/checkBox/base/controlledCheckBox";

type AnyAmenity = PropertyAmenity | UnitAmenity;

interface Props {
  value: AnyAmenity;
  showCheckbox?: boolean;
  checked?: boolean;
  onToggle?: () => void;
}

export const Amenity: FC<Props> = ({ value, showCheckbox, checked, onToggle }: Props) =>
  showCheckbox && onToggle ? (
    <ControlledCheckBox
      checked={Boolean(checked)}
      onChange={onToggle}
      labelValueProps={{
        text: getAmenityLabel(value),
        color: "black"
      }}
    />
  ) : (
    <View flexDirection={"row"} autoWidth alignItems={"center"} noWrap>
      <Icon Source={CheckBoldIconCircle} size={20} pathColor={"black"} marginRight={10} />
      <Text value={getAmenityLabel(value)} fontSize={14} />
    </View>
  );
