"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyAccountsFundsReportItem = void 0;
class PropertyAccountsFundsReportItem {
    constructor(property, cashBalance, propertyReserve, undepositedFunds, depositsHeld, defaultOperatingAccountId, cashBalanceByAccount, availableFunds) {
        this.property = property;
        this.cashBalance = cashBalance;
        this.propertyReserve = propertyReserve;
        this.undepositedFunds = undepositedFunds;
        this.depositsHeld = depositsHeld;
        this.defaultOperatingAccountId = defaultOperatingAccountId;
        this.cashBalanceByAccount = cashBalanceByAccount;
        this.availableFunds = availableFunds;
    }
}
exports.PropertyAccountsFundsReportItem = PropertyAccountsFundsReportItem;
