import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    gridContainerPadding: {
      padding: "20px 20px"
    },
    gridItemPadding: {
      padding: "0px 20px"
    }
  })
);
