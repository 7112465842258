import React from "react";
import AppStrings from "locale/keys";
import Transaction from "../transaction";
import { leaseChargeApi } from "api/leaseChargeApi";
import { LeaseChargeDto, LinkedResourceType } from "@doorloop/dto";

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
}

const DepositDialog: React.FC<ComponentProps> = ({ onBackdropClick, onClose }: ComponentProps) => (
  <Transaction
    onBackdropClick={onBackdropClick}
    onClose={onClose}
    dialogTitle={AppStrings.Leases.LeaseTransaction.Deposit.ChargeDeposit}
    apiMethod={leaseChargeApi}
    transactionDto={LeaseChargeDto.prototype}
    transactionCreatedText={AppStrings.Leases.LeaseTransaction.Deposit.CreatedText}
    loadingTransactionDataText={AppStrings.Leases.LeaseTransaction.Deposit.LoadingData}
    loadingTransactionDataSuccessText={AppStrings.Leases.LeaseTransaction.Deposit.LoadingDataSuccess}
    updatingTransactionText={AppStrings.Leases.LeaseTransaction.Deposit.Updating}
    transactionUpdatedText={AppStrings.Leases.LeaseTransaction.Deposit.Updated}
    accountFilterClass={"filter_leaseDepositItem"}
    type={"oneTime"}
    dialogFrameHeight={780}
    linkedResourceType={LinkedResourceType.Deposit}
  />
);

export default DepositDialog;
