import type { BulkPaymentWrapperDto, BulkReceiveSessionDto, LeaseTenantDto } from "@doorloop/dto";
import { DEFAULT_PAGE_SIZE } from "@doorloop/dto";
import { LoadingDialog } from "DLUI/dialogs/components/loading";
import type { EditableTableColumn } from "DLUI/editableTable/editableTable";
import { EditableTable } from "DLUI/editableTable/editableTable";
import { View } from "DLUI/view";
import { leaseTenantsApi } from "api/tenantsApi";
import { useFormikContext } from "formik";
import { useEffectAsync } from "hooks/useEffectAsync";
import AppStrings from "locale/keys";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getReviewTableColumns } from "./tableColumns";
import { DialogState } from "DLUI/dialogs/loadingDialog";

interface EditableReviewTableProps {
  onDeleteClicked: (dto: BulkPaymentWrapperDto, index: number) => void;
}

export const EditableReviewTable = ({ onDeleteClicked }: EditableReviewTableProps) => {
  const { t } = useTranslation();
  const formikContext = useFormikContext<BulkReceiveSessionDto>();
  const [columns, setColumns] = React.useState<ReadonlyArray<EditableTableColumn<BulkPaymentWrapperDto>>>();
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [leaseTenants, setLeaseTenants] = React.useState<LeaseTenantDto[] | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(!columns);

  const fetchTenants = useCallback(async () => {
    setIsLoading(true);
    setError(undefined);

    const { data: leaseTenants, message: errorMessage } = await leaseTenantsApi.getAll({
      page_size: DEFAULT_PAGE_SIZE
    });

    if (!leaseTenants?.data) {
      setError(errorMessage || t(AppStrings.BulkPayments.Errors.CouldNotFetchTenants));
      setIsLoading(false);
      return;
    }

    setLeaseTenants(leaseTenants.data);
    setIsLoading(false);
  }, []);

  /**
   * Every time the leaseTenants or formikContext payments changes,
   * we need to update the columns to reflect the new data
   * and also to set new onDeleteClicked callbacks for each row
   */
  useEffect(() => {
    if (!leaseTenants) {
      return;
    }

    setColumns(
      getReviewTableColumns({
        leaseTenants,
        onDeleteClicked
      })
    );
  }, [leaseTenants, formikContext.values.payments]);

  /**
   * We only want to fetch the tenants once
   */
  useEffectAsync(async () => {
    await fetchTenants();
  }, []);

  if (error) {
    return (
      <LoadingDialog
        dialogState={DialogState.Error}
        errorText={error}
        showRetryButton
        onRetryButtonPress={fetchTenants}
      />
    );
  }

  if (!columns) {
    return <View />;
  }

  return (
    <EditableTable
      isLoading={isLoading}
      data={isLoading ? [] : Object.values(formikContext.values.payments)}
      columns={columns}
      rowHeight={45}
      headerRowHeight={45}
      fontSize={14}
      checkboxSize={25}
      hideFooter
      displayMode="table"
      singularItemName={"Payment"}
      pluralItemName={"Payments"}
      rowHoverColor="#F9FAFC"
      onSelectionChanged={() => {}}
      selectedIndices={[]}
      noDataComponent={<></>}
      totalDisplayFormatType="currency"
    />
  );
};
