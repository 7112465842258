"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseDraftStatusForQuery = void 0;
var LeaseDraftStatusForQuery;
(function (LeaseDraftStatusForQuery) {
    LeaseDraftStatusForQuery["NotSent"] = "NotSent";
    LeaseDraftStatusForQuery["Sent"] = "Sent";
    LeaseDraftStatusForQuery["Viewed"] = "Viewed";
    LeaseDraftStatusForQuery["PartiallySigned"] = "PartiallySigned";
    LeaseDraftStatusForQuery["Countersigned"] = "CounterSigned";
    LeaseDraftStatusForQuery["Activated"] = "Activated";
    LeaseDraftStatusForQuery["Cancelled"] = "Cancelled";
    LeaseDraftStatusForQuery["All"] = "All";
    LeaseDraftStatusForQuery["ActiveOnly"] = "ActiveOnly";
    LeaseDraftStatusForQuery["InactiveOnly"] = "InactiveOnly";
})(LeaseDraftStatusForQuery || (exports.LeaseDraftStatusForQuery = LeaseDraftStatusForQuery = {}));
