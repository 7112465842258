import AppStrings from "../locale/keys";
import { MobileIcon } from "../assets";
import { DLButtonColorsEnum } from "DLUI/button/dlButton";
import type { ConversationLinkedToType } from "@doorloop/dto";
import { ObjectPermission, ServerResponseErrorCode } from "@doorloop/dto";
import { phoneNumbersApi } from "api/phoneNumbersApi";
import { BulkOperationSkipped } from "../engines/bulkOperationsEngine";
import i18n from "i18next";
import type { BulkActionDefinition } from "DLUI/dataList/types";

export const sendWelcomeSmsBulkActionDefinition: BulkActionDefinition = {
  actionName: "",
  actionNameTranslated: AppStrings.BulkActions.SendWelcomeSms,
  icon: MobileIcon,
  dialogTextColor: "lightBlue",
  confirmHeader: AppStrings.BulkActions.SendWelcomeSmsHeader,
  confirmButtonVariant: DLButtonColorsEnum.SECONDARY,
  permission: ObjectPermission.conversations
};

export const sendWelcomeSmsAction = (type: ConversationLinkedToType, id?: string) => async () => {
  const result = await phoneNumbersApi.sendWelcomeSms({
    type,
    id
  });

  if (result.errorCode === ServerResponseErrorCode.OPERATION_SKIPPED) {
    throw {
      type: BulkOperationSkipped,
      message: result.message
    };
  }

  if (!result.status) {
    throw result.message;
  }

  return { message: i18n.t(AppStrings.Common.WelcomeSmsSent) };
};
