import React, { useEffect, useMemo, useState } from "react";
import { AccountType, CompanyDefaultAccountsSettingsDto, createValidator, SettingPermission } from "@doorloop/dto";
import { settingsApi } from "api/settingsApi";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { SectionTitle } from "DLUI/screen";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { Formik } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import DefaultAccountItem from "./defaultAccountItem";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import { SettingsReadOnlyScope } from "DLUI/permissionScope/readOnlyScope";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import { accountsApi } from "api/accounts";
import { useUserType } from "../../../../hooks/useUserType";

interface ComponentProps {
  onClose: () => void;
  onBackdropClick: () => void;
  dialogTitle?: string;
}

let formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

const DefaultAccounts: React.FC<ComponentProps> = ({ onClose, onBackdropClick, dialogTitle }: ComponentProps) => {
  const { t } = useTranslation();
  const { isHOAUser } = useUserType();
  const { dialogHorizontalPadding, inputRightPadding } = DialogsHelper();
  const [viewIndex, setViewIndex] = useState(0);
  const [showOperatingAccountChangedNotificationPanel, setShowOperatingAccountChangedNotificationPanel] =
    useState<boolean>(false);
  const [defaultAccountsData, setDefaultAccountsData] = useState<CompanyDefaultAccountsSettingsDto | undefined>();
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState<string>("");
  const [loadingDialogSuccessText, setLoadingDialogSuccessText] = useState<string>(
    t(AppStrings.Settings.GeneralSettings.DefaultAccounts.UpdateDefaultAccountsSuccessText)
  );

  useEffect(() => {
    loadSettingsData();
  }, []);

  const showErrorMessage = (message: string) => {
    setLoadingDialogErrorText(message);
    setLoadingDialogState(DialogState.Error);
  };

  const loadSettingsData = async () => {
    setLoadingDialogLoadingText(t(AppStrings.Settings.GeneralSettings.DefaultAccounts.LoadingDefaultAccountsData));
    setLoadingDialogState(DialogState.Show);
    const response = await settingsApi.getDefaultAccountsData().catch((e) => {
      showErrorMessage(e);
    });
    if (response && response.data) {
      const accountDictionary = await accountsApi.getDictionary();
      if (!accountDictionary[response.data.id]) {
        await accountsApi.getDictionary(true);
      }
      setLoadingDialogSuccessText(
        t(AppStrings.Settings.GeneralSettings.DefaultAccounts.LoadingDefaultAccountsDataSuccessText)
      );
      setLoadingDialogState(DialogState.Success);
      setDefaultAccountsData(response.data);
      setLoadingDialogState(DialogState.Hidden);
      return;
    }
    if (response) {
      showErrorMessage(response.message);
    }

    showErrorMessage(t(AppStrings.Common.NetworkErrorSubTitle));
  };

  const didPressDismissButton = () => {
    onBackdropClick();
  };

  const onUpdateRetryPress = () => {
    if (formikGlobalRef) {
      updateDefaultAccountsSettings(formikGlobalRef.values);
    }
  };

  const renderView = ({ index }: any) => {
    if (index === 0) {
      if (loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={loadSettingsData}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }
      return renderForm();
    }
    if (index === 1) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          loadingText={loadingDialogLoadingText}
          errorText={loadingDialogErrorText}
          successText={t(AppStrings.Settings.GeneralSettings.DefaultAccounts.UpdateDefaultAccountsSuccessText)}
          onRetryButtonPress={onUpdateRetryPress}
          didPressDismissButton={didPressDismissButton}
        />
      );
    }

    return <div />;
  };

  const initFormvalues = (): CompanyDefaultAccountsSettingsDto => {
    if (defaultAccountsData) {
      return defaultAccountsData;
    }
    return new CompanyDefaultAccountsSettingsDto();
  };

  const validateForm = createValidator(CompanyDefaultAccountsSettingsDto);

  const dialogDescriptionText = useMemo(
    () => [
      t(AppStrings.Settings.GeneralSettings.DefaultAccounts.DialogDescription)
      //,
      // " ",
      // <Link key={"DZ"} hoverColor={"lightBlue"/* Hey, this leads nowhere, maybe remove for now? */}>
      //   {t(AppStrings.Common.ClickHere)}
      // </Link>,
      // " ",
      // t(AppStrings.Common.ToLearnMore)
    ],
    []
  );

  const renderForm = () => {
    const formInitialValues = initFormvalues();

    return (
      <Formik initialValues={formInitialValues} onSubmit={(values, { setSubmitting }) => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;

          return (
            <View
              marginTop={10}
              paddingRight={dialogHorizontalPadding}
              paddingLeft={dialogHorizontalPadding}
              height={"100%"}
              flex={1}
            >
              <Text lineHeight={"26px"}>{dialogDescriptionText}</Text>
              <SectionTitle
                title={AppStrings.Settings.GeneralSettings.DefaultAccounts.Bank}
                type={"underline"}
                marginTop={20}
              />
              <DefaultAccountItem
                accountTypes={[AccountType.ASSET_BANK]}
                title={AppStrings.Settings.GeneralSettings.DefaultAccounts.OperatingBankAccount}
                name={"bank_operating"}
                onChange={() => {
                  setShowOperatingAccountChangedNotificationPanel(true);
                }}
                formikRef={formik}
              />
              <DefaultAccountItem
                marginTop={20}
                accountTypes={[AccountType.ASSET_BANK]}
                title={AppStrings.Settings.GeneralSettings.DefaultAccounts.TrustBankAccount}
                name={"bank_trust"}
                formikRef={formik}
              />

              <DefaultAccountItem
                marginTop={20}
                accountTypes={[AccountType.EQUITY_EQUITY]}
                title={AppStrings.OtherTransactions.BankTransfer.ClearingAccount}
                name={"bank_clearing"}
                formikRef={formik}
              />

              <SectionTitle
                title={AppStrings.Settings.GeneralSettings.DefaultAccounts.Income}
                type={"underline"}
                marginTop={20}
              />
              <DefaultAccountItem
                accountTypes={[AccountType.REVENUE_INCOME]}
                title={AppStrings.Settings.GeneralSettings.DefaultAccounts.RentalIncome}
                name={"income_rental"}
                leaseChargeItem
                formikRef={formik}
              />
              {!isHOAUser && (
                <DefaultAccountItem
                  marginTop={20}
                  accountTypes={[AccountType.REVENUE_INCOME]}
                  title={AppStrings.Settings.GeneralSettings.DefaultAccounts.ApplicationFee}
                  name={"income_applicationFee"}
                  formikRef={formik}
                />
              )}
              <DefaultAccountItem
                marginTop={20}
                accountTypes={[AccountType.REVENUE_INCOME]}
                isSystemAccountOrLeaseChargeItem
                title={AppStrings.Leases.NewLease.LeaseRent.LateFees}
                name={"income_lateFees"}
                formikRef={formik}
              />
              <DefaultAccountItem
                marginTop={20}
                accountTypes={[AccountType.REVENUE_INCOME]}
                title={AppStrings.Settings.GeneralSettings.DefaultAccounts.ConvenienceFeeIncome}
                name={"income_convenienceFee"}
                formikRef={formik}
              />
              {!isHOAUser && (
                <SectionTitle
                  title={AppStrings.Settings.GeneralSettings.DefaultAccounts.Liability}
                  type={"underline"}
                  marginTop={20}
                />
              )}
              {!isHOAUser && (
                <DefaultAccountItem
                  accountTypes={[
                    AccountType.LIABILITY_OTHER_CURRENT_LIABILIY,
                    AccountType.LIABILITY_LONG_TERM_LIABILITY
                  ]}
                  title={AppStrings.Settings.GeneralSettings.DefaultAccounts.SecurityDeposit}
                  name={"liability_securityDeposit"}
                  leaseChargeItem
                  formikRef={formik}
                />
              )}
              <SectionTitle
                title={AppStrings.Settings.GeneralSettings.DefaultAccounts.Expense}
                type={"underline"}
                marginTop={20}
              />
              <DefaultAccountItem
                accountTypes={[AccountType.EXPENSE_EXPENSE, AccountType.REVENUE_INCOME]}
                title={AppStrings.Settings.GeneralSettings.DefaultAccounts.ManagementFees}
                name={"expense_managementFee"}
                formikRef={formik}
              />
              {!isHOAUser && (
                <SectionTitle
                  title={AppStrings.Settings.GeneralSettings.DefaultAccounts.Equity}
                  type={"underline"}
                  marginTop={20}
                />
              )}
              {!isHOAUser && (
                <DefaultAccountItem
                  accountTypes={[AccountType.EQUITY_EQUITY]}
                  title={AppStrings.Settings.GeneralSettings.DefaultAccounts.OwnerContribution}
                  name={"equity_ownerContribution"}
                  formikRef={formik}
                />
              )}
              {!isHOAUser && (
                <DefaultAccountItem
                  marginTop={20}
                  accountTypes={[AccountType.EQUITY_EQUITY]}
                  title={AppStrings.Settings.GeneralSettings.DefaultAccounts.OwnerDistribution}
                  name={"equity_ownerDistribution"}
                  formikRef={formik}
                />
              )}
              <View height={20} />
            </View>
          );
        }}
      </Formik>
    );
  };

  const _onBackdropClick = () => {
    if (viewIndex > 0) {
      setViewIndex(viewIndex - 1);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const currentTitle = useMemo(() => {
    if (viewIndex === 0) {
      return dialogTitle;
    }

    if (viewIndex === 2) {
      return AppStrings.Vendors.Screen.AddNewVendor;
    }

    if (viewIndex === 1) {
      return AppStrings.Vendors.VendorDetails.SelectVendor;
    }
    return "";
  }, [viewIndex]);

  const isValidForm = async (formikRef: FormikProps<CompanyDefaultAccountsSettingsDto>) => {
    formikRef.setFieldTouched("bank_operating");
    formikRef.setFieldTouched("bank_trust");
    formikRef.setFieldTouched("income_rental");
    formikRef.setFieldTouched("liability_securityDeposit");
    formikRef.setFieldTouched("income_applicationFee");
    formikRef.setFieldTouched("income_convenienceFee");
    formikRef.setFieldTouched("expense_managementFee");
    formikRef.setFieldTouched("equity_ownerContribution");
    formikRef.setFieldTouched("equity_ownerDistribution");

    const errors = (await formikRef.validateForm()) as any;
    return _.isEmpty(errors);
  };

  const didPressSaveButton = async () => {
    if (formikGlobalRef !== null) {
      const isValid = await isValidForm(formikGlobalRef);
      if (isValid) {
        await updateDefaultAccountsSettings(formikGlobalRef.values);
      }
    }
  };

  const updateDefaultAccountsSettings = async (values: CompanyDefaultAccountsSettingsDto) => {
    setLoadingDialogLoadingText(t(AppStrings.Settings.GeneralSettings.DefaultAccounts.UpdatingDefaultAccountsData));
    setLoadingDialogState(DialogState.Show);
    setViewIndex(1);

    const response = await settingsApi.updateDefaultAccountsData(values).catch((e) => {
      showErrorMessage(e);
    });
    if (response && response.data) {
      setLoadingDialogSuccessText(
        t(AppStrings.Settings.GeneralSettings.DefaultAccounts.UpdateDefaultAccountsSuccessText)
      );
      setLoadingDialogState(DialogState.Success);
      onClose();
    } else if (response) {
      showErrorMessage(response.message);
    }
  };

  const renderActionPanelButtons = () => (
    <FormActionButtons
      propsSubButton={{ onClick: _onBackdropClick }}
      propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
    />
  );

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      if (!defaultAccountsData) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }
    if (viewIndex === 1) {
      return "contentOnly";
    }
    return "sectionTitleFrame";
  }, [viewIndex, defaultAccountsData]);

  return (
    <SettingsReadOnlyScope permission={SettingPermission.defaultAccounts}>
      <DialogFrame
        onCloseButtonClick={_onBackdropClick}
        title={currentTitle}
        width={getDialogFrameDimension("width", 760)}
        height={getDialogFrameDimension("height", 900)}
        renderView={renderView}
        numViews={2}
        activeView={viewIndex}
        RenderActionPanelButtons={renderActionPanelButtons}
        frameType={frameType}
        keepViewsMounted={false}
      />
    </SettingsReadOnlyScope>
  );
};

export default DefaultAccounts;
