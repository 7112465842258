import React from "react";

import type { FormikProps } from "formik";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";

import { createValidator, ValidatePasswordDto } from "@doorloop/dto";

import _ from "lodash";
import AppStrings from "../../../../locale/keys";
import DLButton, { DLButtonColorsEnum } from "DLUI/button/dlButton";
import Text from "DLUI/text";
import { authApi } from "api/authApi";
import { FieldSafe } from "DLUI/fieldSafe/fieldSafe";
import { Icon } from "DLUI/icon";
import { LockIcon } from "../../../../assets";
import { SeparationLine } from "DLUI/separatorView";
import { TextField } from "DLUI/form";
import { View } from "DLUI/view";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

export interface UnlockSettingsFormProps {
  isStepActive: boolean;
  onClose: () => void;
  onUnlocked: (currentPassword: string) => void;
}

let formikGlobalRef: FormikProps<ValidatePasswordDto> | null = null;

export const getFormikRef = () => formikGlobalRef;

const UnlockSettingsForm = ({ isStepActive, onClose, onUnlocked }: UnlockSettingsFormProps): JSX.Element => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const validateForm = createValidator(ValidatePasswordDto);

  const handleUnlock = async (): Promise<void> => {
    if (!isStepActive || !formikGlobalRef || isLoading) {
      return;
    }

    if (!(await isValidForm(formikGlobalRef))) {
      return;
    }

    setIsLoading(true);

    const { currentPassword } = formikGlobalRef.values;

    const validatePasswordResult = await authApi.validatePassword({
      currentPassword
    });

    if (validatePasswordResult.status) {
      const hasMatch = validatePasswordResult.data?.hasMatch;

      if (hasMatch) {
        onUnlocked(currentPassword);
      } else {
        formikGlobalRef.setFieldError("currentPassword", t(AppStrings.Auth.PasswordIsIncorrect));
      }
    } else {
      formikGlobalRef.setFieldError("currentPassword", validatePasswordResult.message);
    }

    setIsLoading(false);
  };

  const isValidForm = async (formikRef: FormikProps<ValidatePasswordDto>): Promise<boolean> => {
    formikRef.setFieldTouched("currentPassword");

    const errors = await formikRef.validateForm();

    return _.isEmpty(errors);
  };

  return (
    <Formik onSubmit={handleUnlock} initialValues={{ currentPassword: "" }} validate={validateForm}>
      {(formik) => {
        formikGlobalRef = formik;

        return (
          <Form style={{ height: isMobile ? "100%" : "auto" }}>
            <View justifyContent={"space-between"} height={"100%"}>
              <View
                alignItems={"center"}
                paddingTop={40}
                paddingRight={isMobile ? 20 : 130}
                paddingBottom={isMobile ? 0 : 40}
                paddingLeft={isMobile ? 20 : 130}
              >
                <Icon width={50} height={50} Source={LockIcon} />
                <Text
                  marginTop={20}
                  marginBottom={5}
                  fontSize={16}
                  fontWeight={700}
                  value={AppStrings.Auth.UnlockSettings}
                />
                <Text
                  marginBottom={20}
                  fontSize={14}
                  fontWeight={400}
                  color={"gray"}
                  align={"center"}
                  value={AppStrings.Auth.UnlockSettingsDescription}
                />
                <FieldSafe
                  onSubmit={handleUnlock}
                  required
                  name={"currentPassword"}
                  variant={"outlined"}
                  TextFieldType={"password"}
                  label={t(AppStrings.Auth.CurrentPassword)}
                  component={TextField}
                />
              </View>
              <View>
                <SeparationLine width={"100%"} height={1} />
                <View
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                  gap={5}
                  paddingLeft={20}
                  paddingRight={20}
                  paddingBottom={20}
                  paddingTop={20}
                >
                  <DLButton
                    onClick={onClose}
                    color={DLButtonColorsEnum.NEUTRAL}
                    actionText={t(AppStrings.Common.Close)}
                  />
                  <DLButton
                    isLoading={isLoading}
                    onClick={handleUnlock}
                    color={DLButtonColorsEnum.PRIMARY}
                    actionText={t(AppStrings.Auth.Unlock)}
                  />
                </View>
              </View>
            </View>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UnlockSettingsForm;
