import { View } from "DLUI/view";
import React, { useState } from "react";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { Popover } from "DLUI/popover";
import { GuideStartIcon, HelpIcon, KnowlegeIcon, UniversityIcon, ViewDocsIcon } from "@/assets";
import { FormAndTemplatesUrl, GettingStartedUrl, HelpCenterUrl } from "utils/sharedVariables";
import { LinkURLsEnum } from "DLUI/link/linkURLsEnum";
import { Icon } from "DLUI/icon";

const didPressDLUniversity = () => {
  window.open(LinkURLsEnum.DOORLOOP_UNIVERSITY, "_blank", "noopener");
};

const visitHelpCenter = () => {
  window.open(HelpCenterUrl, "_blank", "noopener");
};

export const helpPopoverItems = [
  {
    Icon: GuideStartIcon,
    title: AppStrings.Common.GettingStarted,
    onClick: () => window.open(GettingStartedUrl, "_blank")
  },
  {
    Icon: UniversityIcon,
    title: AppStrings.Common.DoorloopUniversity,
    onClick: didPressDLUniversity
  },
  {
    Icon: KnowlegeIcon,
    title: AppStrings.Common.KnowledgeBase,
    onClick: visitHelpCenter
  },
  {
    Icon: ViewDocsIcon,
    title: AppStrings.Common.FormsAndTemplates,
    onClick: () => window.open(FormAndTemplatesUrl, "_blank")
  }
];

export const HelpButtonItem = ({ isMinimized, isMobile, dataCy }) => {
  const [popOverOpen, setPopOverOpen] = useState<boolean>(false);

  const handlePopOver = () => {
    setPopOverOpen(true);
  };

  const onUserPopoverStateChange = (nextState: "open" | "close") => {
    setPopOverOpen(nextState === "open");
  };

  return (
    <View
      style={{ padding: 10 }}
      noWrap
      onClick={handlePopOver}
      dataCy={dataCy}
      flexDirection={"row"}
      alignItems={"center"}
      width={!isMinimized ? "calc(100% - 20px)" : "auto"}
    >
      <Icon Source={HelpIcon} pathColor={"white"} size={20} />
      <Text color={"white"} value={AppStrings.Common.Help} fontSize={isMobile ? 16 : 14} marginLeft={5} />
      <Popover
        hideDotsIcon
        iconPathColor={"white"}
        backgroundColor={"white"}
        width={245}
        topSectionItems={helpPopoverItems || []}
        anchorPositionTop={-1}
        onStateChange={onUserPopoverStateChange}
        iconSize={16}
        shouldShow={popOverOpen}
        disableHover
      />
    </View>
  );
};
