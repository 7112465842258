import { conversationsDataSourceAtom } from "screens/communicationsCenter/shared/useConversationsListDataSource";
import { conversationUtils } from "screens/communicationsCenter/shared/conversationUtils";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import type { ConversationParticipantDto } from "@doorloop/dto";
import { ConversationLinkedToType } from "@doorloop/dto";
import { useAtomValue, useSetAtom } from "jotai";
import { currentRecipientAtom } from "./currentRecipientAtom";
import _ from "lodash";

const PARAMS_TO_LINKED_TO_TYPE_MAP: Record<string, ConversationLinkedToType> = {
  vendorId: ConversationLinkedToType.VENDOR,
  tenantId: ConversationLinkedToType.TENANT,
  prospectId: ConversationLinkedToType.PROSPECT,
  ownerId: ConversationLinkedToType.OWNER
};

export const useAutoSetRecipient = () => {
  const conversationsDataSource = useAtomValue(conversationsDataSourceAtom);
  const setCurrentRecipient = useSetAtom(currentRecipientAtom);
  const isTabView = conversationUtils.isTabView();
  const params = useParams();

  useEffect(() => {
    if (!isTabView || _.size(conversationsDataSource) > 0) {
      return;
    }

    let participant: ConversationParticipantDto | undefined;

    const paramKey = Object.keys(params).find((param) => PARAMS_TO_LINKED_TO_TYPE_MAP[param]);

    if (paramKey) {
      participant = {
        linkedToId: params[paramKey],
        linkedToType: PARAMS_TO_LINKED_TO_TYPE_MAP[paramKey]
      };
      participant.phoneNumber = conversationUtils.getParticipantPhoneNumber(participant);
      participant.email = conversationUtils.getParticipantEmail(participant);
      setCurrentRecipient(participant);
    }

    participant && setCurrentRecipient(participant);
  }, []);
};
