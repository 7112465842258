import type { PropsWithChildren } from "react";
import React, { useEffect, useRef, useState } from "react";
import Popover from "@material-ui/core/Popover";
import { View } from "DLUI/view";
import makeStyles from "./styles";
import type { SVGIconComponent } from "../../../assets";
import { Icon } from "DLUI/icon";

interface ComponentProps {
  width?: number | string;
  contentView?: JSX.Element;
  IconSource?: SVGIconComponent;
  position?: "top" | "bottom";
  containerWidth?: number | string;
  automaticCloseDelayTIme?: number;
  anchorTopMargin?: number;
}

const Tooltip: React.FC<ComponentProps> = ({
  children,
  width,
  IconSource,
  contentView,
  position,
  containerWidth,
  automaticCloseDelayTIme,
  anchorTopMargin
}: PropsWithChildren<ComponentProps>) => {
  const classes = makeStyles();
  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;

  useEffect(() => {
    document.body.addEventListener("click", handleClose);
    return () => {
      document.body.removeEventListener("click", handleClose);
    };
  }, []);

  const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(anchorRef.current);

    if (automaticCloseDelayTIme) {
      setTimeout(() => handleClose(), automaticCloseDelayTIme);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <View width={containerWidth} autoWidth={!containerWidth} onClick={onClick}>
        <div
          className={position === "top" ? classes.tooltipAnchorTop : classes.tooltipAnchorBottom}
          ref={anchorRef}
          style={{ top: anchorTopMargin, left: 0 }}
        />
        {children ? children : IconSource ? <Icon Source={IconSource} /> : null}

        <Popover
          classes={{ paper: classes.popoverContainer }}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: position === "top" ? "bottom" : "top",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: position === "top" ? "bottom" : "top",
            horizontal: "center"
          }}
        >
          <View width={width}>
            {contentView ? (
              <View paddingLeft={10} paddingRight={10}>
                {contentView}
              </View>
            ) : (
              <View />
            )}
          </View>
        </Popover>
      </View>
    </>
  );
};

export default Tooltip;
