import type { ChangeEvent } from "react";
import { useCallback, useState } from "react";

export function useInputState(defaultValue = "") {
  const [value, setValue] = useState(defaultValue);
  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  return [value, handleChange] as const;
}
