import React from "react";
import { useMemo } from "react";
import Text from "DLUI/text";
import { ReactComponent as Checkmark } from "assets/icons/checkmark_circle.svg";
import { Icon } from "DLUI/icon";
import { View } from "DLUI/view";
import type { PropertyTypeItemType } from "screens/properties/newProperty/components/propertyType/propertyTypes";
import { useTranslation } from "react-i18next";
import ColorsEnum from "utils/colorsEnum";
import { useFormikContext } from "formik";
import { getIn } from "formik";
import { PropertyDto, type DisplayPropertyWithUnitsDto } from "@doorloop/dto";
import { EmptyCircle } from "@/assets";
import { FieldSafe } from "DLUI/fieldSafe/fieldSafe";
import { TextField } from "DLUI/form";
import AppStrings from "locale/keys";
import {
  ElementPrefixedTextList,
  ElementType
} from "screens/incomingPayments/tabs/merchantAccountSetup/stripeOnbording/components/elementPrefixedText";

const footerElementsMargin = 28;

export interface PropertySubTypeItemProps {
  itemIndex: number;
  onItemPress: (itemIndex: number) => void;
  item: PropertyTypeItemType;
  name: string;
  typeDescriptionName: string;
}

export function PropertySubTypeItem({
  itemIndex,
  onItemPress,
  item: { descriptionBullets, icon, label, isSelected: _isSelected, value, dataCy },
  name,
  typeDescriptionName
}: PropertySubTypeItemProps) {
  const { values } = useFormikContext<DisplayPropertyWithUnitsDto>();
  const { t } = useTranslation();
  const isSelected = _isSelected || getIn(values, name) === value;

  const _onClick = () => {
    onItemPress(itemIndex);
  };

  const translatedDescriptionBullets = useMemo(
    () => descriptionBullets && descriptionBullets.map((text) => t(text)),
    [descriptionBullets, t]
  );

  return (
    <View
      dataCy={dataCy}
      outline={isSelected ? `2px solid ${ColorsEnum.Pink}` : undefined}
      noWrap
      onClick={_onClick}
      backgroundColorEnum={ColorsEnum.White}
      paddingLeft={8}
      paddingBottom={16}
      paddingRight={24}
      paddingTop={16}
      maxWidth={490}
    >
      <View flexDirection={"row"}>
        <View width={"auto"} marginRight={8}>
          {isSelected ? (
            <Icon Source={Checkmark} width={20} height={20} pathColor={"pink"} />
          ) : (
            <Icon Source={EmptyCircle} width={20} height={20} pathColor={"light-gray"} />
          )}
        </View>

        <View noWrap flex={1} gap={4}>
          <Text bold fontSize={14} value={label} />
          {translatedDescriptionBullets && (
            <ElementPrefixedTextList
              useFlex
              textProps={{
                style: {
                  color: ColorsEnum.LightBlack,
                  fontSize: 12
                }
              }}
              items={translatedDescriptionBullets.map((text) => t(text))}
              gap={4}
              elementType={ElementType.BULLET}
            />
          )}
        </View>

        <View width={"auto"} marginLeft={16} noWrap>
          <Icon size={45} Source={icon} />
        </View>
      </View>

      {isSelected && PropertyDto.isOtherType(value) && (
        <View marginLeft={footerElementsMargin} width={"-webkit-fill-available"} flexDirection={"row"} marginTop={16}>
          <FieldSafe
            name={typeDescriptionName}
            component={TextField}
            label={t(AppStrings.Properties.Settings.PropertyType)}
            required
          />
        </View>
      )}
    </View>
  );
}
