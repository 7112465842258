import MomentUtils from "@date-io/moment";
import type { DepositDto, LinkedResourceType, ObjectPermission } from "@doorloop/dto";
import {
  createValidator,
  DuplicateDialogButtonLabelValues,
  GetAllLeaseReversedPaymentsQuery,
  LeaseChargeDto,
  LeaseCreditDto,
  LeaseRecurringChargeInfoDto,
  LeaseRecurringCreditInfoDto,
  LeaseTransactionLineBaseDto,
  mathUtils,
  RecurringTransactionDto,
  RecurringTransactionFrequency,
  RecurringTransactionType
} from "@doorloop/dto";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { filesApi } from "api/filesApi";
import type { LeaseChargeApi } from "api/leaseChargeApi";
import { AddIcon } from "assets/icons";
import { Button } from "DLUI/button";
import { DialogSearchPanel, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import { FormikDatePicker, FormikReferenceLabel, Select, ValidationIndicator } from "DLUI/form";
import { Notes } from "DLUI/notes";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, Field, FieldArray, Formik, getIn } from "formik";
import AppStrings from "locale/keys";
import _, { get } from "lodash";
import moment from "moment";
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import LeasesList from "screens/leases/leases/leasesList/leasesList";
import SelectableLeaseListItem from "screens/leases/leases/leasesList/selectableLeaseListItem";
import DialogFrame from "../components/dialogFrame";
import DialogAmountView from "../components/totalAmountView";
import LeaseSelection from "./leaseSelection";
import makeStyles from "./styles";
import TransactionLine from "./transactionLine";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import DeleteConfirmation from "DLUI/dialogs/components/deleteConfirmation";
import ReconciledNotificationView from "DLUI/dialogs/components/reconciledNotificationView";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { useLastArrayItemRef } from "hooks/useLastArrayItemRef";
import { ListInputsContainer } from "DLUI/listItems";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { ControlledCheckBox } from "DLUI/form/checkBox/base/controlledCheckBox";
import type { ApiToastsProps } from "api/apiHelper";
import type { ApiResult } from "api/apiResult";
import { useAnalyticsService } from "hooks/useAnalyticsService";
import { ReversePaymentNotification } from "DLUI/dialogs/transactions/reversePaymentNotification";
import { leaseReversedPaymentsApi } from "api/leaseReversedPaymentsApi";
import { QueryParams } from "utils/queryParams";
import { DialogHeaderActionButtons } from "DLUI/actionButtons/dialogHeaderActionButtons";
import {
  cleanLeaseChargeDataForDuplicateMode,
  cleanRecurringTransactionDataForDuplicateMode,
  DUPLICATE_MODE_QUERY_PARAMS
} from "DLUI/dialogs/duplicateModeHelper";
import { v4 as uuid } from "uuid";
import { ActivityLabel } from "../../activityLabel/activityLabel";
import { useJournalEntryPrinting } from "hooks/useJournalEntryPrinting";

export type accountFilterClassType = "filter_leaseChargeItem" | "filter_leaseDepositItem" | "filter_leaseCreditItem";
export type accountFilterTypes = "filter_leaseChargeItem";

interface transactionPayload {
  chargeValues: LeaseChargeDto;
  toast: ApiToastsProps<LeaseChargeDto>;
}

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
  dialogTitleActions?: React.ReactNode;
  dialogFrameWidth?: number;
  dialogFrameHeight?: number;
  apiMethod: LeaseChargeApi;
  transactionDto: LeaseChargeDto | LeaseCreditDto | DepositDto;
  transactionCreatedText: string;
  loadingTransactionDataText: string;
  loadingTransactionDataSuccessText: string;
  updatingTransactionText: string;
  transactionUpdatedText: string;
  accountFilterClass?: accountFilterClassType;
  accountFilterTypes?: accountFilterTypes;
  type:
    | "oneTime"
    | RecurringTransactionType.LEASE_CHARGE
    | RecurringTransactionType.LEASE_CREDIT
    | RecurringTransactionType.LEASE_PAYMENT;
  linkedResourceType: LinkedResourceType;
  permission?: ObjectPermission;
  customDateLabel?: string;
  helpObject?: HelpPanelProps;
  filesSharedFormikFieldName?: string;
  source?: string;
  showPrintButton?: boolean;
}

let formikGlobalRef: FormikProps<LeaseChargeDto> | null = null;

export const getFormikRef = () => formikGlobalRef;

const initUniqueKey = (current) => (current.uniqueKey = uuid());

const validateLease = createValidator(LeaseChargeDto);
const validateRecurring = createValidator(RecurringTransactionDto);

const fieldNames = {
  [RecurringTransactionType.LEASE_CHARGE]: "leaseChargeInfo.lines",
  [RecurringTransactionType.LEASE_CREDIT]: "leaseCreditInfo.lines"
} as const;

const getFieldName = (type: string) => fieldNames[type] || "lines";

const Transaction: React.FC<ComponentProps> = ({
  onBackdropClick,
  onClose,
  dialogTitle,
  dialogTitleActions,
  apiMethod,
  transactionDto,
  transactionCreatedText,
  loadingTransactionDataText,
  loadingTransactionDataSuccessText,
  updatingTransactionText,
  transactionUpdatedText,
  accountFilterClass,
  accountFilterTypes,
  type,
  dialogFrameHeight,
  dialogFrameWidth,
  linkedResourceType,
  permission,
  customDateLabel,
  helpObject,
  filesSharedFormikFieldName,
  source,
  showPrintButton
}: ComponentProps) => {
  const classes = makeStyles();
  const { t } = useTranslation();
  const { leaseId, transactionId: paramsTransactionId } = useParams<any>();
  const queryParams = new QueryParams();
  const queryTransactionId = queryParams.get(DUPLICATE_MODE_QUERY_PARAMS.transactionId) as string | undefined;
  const transactionId = queryTransactionId || paramsTransactionId;
  const editMode = Boolean(transactionId);
  const duplicateMode = Boolean(queryTransactionId);
  const dialogWidth = dialogFrameWidth || Math.min(window.innerWidth, 1000);
  const clearance: AnyPermissionClearance | undefined = permission
    ? {
        permission,
        field: editMode ? "edit" : "create"
      }
    : undefined;
  const { isMobile, screenContainerPadding } = useResponsiveHelper();
  const { dispatchAnalytics } = useAnalyticsService();
  const [viewIndex, setViewIndex] = useState(0);
  const [renderSelectionList, setRenderSelectionList] = useState<boolean>(false);
  const [currentLeaseId, setCurrentLeaseId] = useState<string | undefined>(leaseId);
  const [files, setFiles] = useState<FileListItemProps[]>([]);
  const [selectLeaseFilterObj, setSelectLeaseFilterObj] = useState({
    filter_text: ""
  });
  const [showLeaseSelectionError, setShowLeaseSelectionError] = useState<boolean>(false);
  const [leaseSelectionErrorText, setLeaseSelectionErrorText] = useState<string>("");
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    editMode ? DialogState.Show : DialogState.Hidden
  );
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const [loadingDialogSuccessText, setLoadingDialogSuccessText] = useState<string>(t(transactionCreatedText));
  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState<string>(t(loadingTransactionDataText));
  const [chargeData, setChargeData] = useState<LeaseChargeDto | RecurringTransactionDto | undefined>();
  const [isReversedPayment, setIsReversedPayment] = useState(false);
  const [shouldrenderForm, setShouldRenderForm] = useState<boolean>(!editMode);
  const [totalPayments, setTotalpayments] = useState<number>(0);
  const [repeatUntilEndLease, setRepeatUntilEndLease] = useState<boolean>(true);
  const inputPaddingRight = isMobile ? 0 : 20;

  useEffect(() => {
    if (isRecurringTransaction && formikGlobalRef) {
      const defaultValue = getIn(formikGlobalRef.values, "noEndDate") as boolean;
      formikGlobalRef.setFieldValue("noEndDate", defaultValue || true);
    }
  }, []);

  useEffect(() => {
    formikGlobalRef?.setFieldValue("lease", currentLeaseId);

    setTimeout(() => {
      if (formikGlobalRef) {
        const errorText = getIn(formikGlobalRef.errors, "lease");
        const touchedVal = getIn(formikGlobalRef.touched, "lease");

        setShowLeaseSelectionError(touchedVal && errorText !== undefined);
        if (errorText !== undefined) {
          setLeaseSelectionErrorText(t(AppStrings.Leases.LeaseCharge.PressToSelectLease));
        }
      }
    }, 0);
  }, [currentLeaseId]);

  useEffect(() => {
    if (editMode) {
      loadData();
    }
  }, [duplicateMode]);

  const { printJournalEntry, printLoadingStatus } = useJournalEntryPrinting({ journalEntryId: transactionId });

  const initFormvalues = (): LeaseChargeDto | LeaseCreditDto | DepositDto => {
    if (editMode && chargeData) {
      if (isRecurringTransaction) {
        const recurringTransaction = chargeData as RecurringTransactionDto;
        setRepeatUntilEndLease(recurringTransaction.noEndDate || false);
      }
      return chargeData;
    }
    if (isRecurringTransaction) {
      const _transactionDto = new RecurringTransactionDto();
      _transactionDto.start = moment().format("YYYY-MM-DD");
      _transactionDto.noEndDate = true;

      //_transactionDto.end = moment().add(1, "year").format("YYYY-MM-DD");
      _transactionDto.frequency = RecurringTransactionFrequency.Monthly;
      if (type === RecurringTransactionType.LEASE_CHARGE) {
        _transactionDto.type = RecurringTransactionType.LEASE_CHARGE;
        _transactionDto.leaseChargeInfo = new LeaseRecurringChargeInfoDto();
        _transactionDto.leaseChargeInfo.lines = [
          new LeaseTransactionLineBaseDto({
            uniqueKey: uuid()
          })
        ];
        return _transactionDto;
      }
      if (type === RecurringTransactionType.LEASE_CREDIT) {
        _transactionDto.type = RecurringTransactionType.LEASE_CREDIT;
        _transactionDto.leaseCreditInfo = new LeaseRecurringCreditInfoDto();
        _transactionDto.leaseCreditInfo.lines = [
          new LeaseTransactionLineBaseDto({
            uniqueKey: uuid()
          })
        ];
        return _transactionDto;
      }
    }
    let _transactionDto = new LeaseChargeDto();
    if (transactionDto === LeaseCreditDto.prototype) {
      _transactionDto = new LeaseCreditDto();
    }

    _transactionDto.date = moment().format("YYYY-MM-DD").toString();
    _transactionDto.lines = [
      new LeaseTransactionLineBaseDto({
        uniqueKey: uuid()
      })
    ];
    return _transactionDto;
  };

  const isRecurringTransaction = useMemo(
    () =>
      type === RecurringTransactionType.LEASE_CHARGE ||
      type === RecurringTransactionType.LEASE_CREDIT ||
      type === RecurringTransactionType.LEASE_PAYMENT,
    [type]
  );

  const isValidForm = async (formikRef: FormikProps<LeaseChargeDto | RecurringTransactionDto>) => {
    formikRef.setFieldTouched("lease");
    if (isRecurringTransaction) {
      formikRef.setFieldTouched("start");
      formikRef.setFieldTouched("end");
      formikRef.setFieldTouched("noEndDate");
    }
    const fieldName = getFieldName(type);
    const lines = get(formikRef.values, fieldName);
    if (lines) {
      lines.forEach((arrayItem: any, arrayIndex: number) => {
        formikRef.setFieldTouched(`${fieldName}[${arrayIndex}].account`);
        formikRef.setFieldTouched(`${fieldName}[${arrayIndex}].amount`);
      });
    }
    const errors = (await formikRef.validateForm()) as any;

    const inValidLease = Boolean(errors.lease);
    setShowLeaseSelectionError(inValidLease);
    if (inValidLease && formikGlobalRef) {
      const errorText = getIn(formikGlobalRef.errors, "lease");
      if (errorText !== undefined) {
        setLeaseSelectionErrorText(t(AppStrings.Leases.LeaseCharge.PressToSelectLease));
      }
    }
    return _.isEmpty(errors);
  };

  const validateForm = async (values: LeaseChargeDto | RecurringTransactionDto) => {
    if (isRecurringTransaction) {
      return validateRecurring(values);
    }
    return validateLease(values as LeaseChargeDto);
  };

  const handleIsReversedPayment = async (leaseCharge: LeaseChargeDto) => {
    if (!leaseCharge || _.isEmpty(leaseCharge.reference)) {
      return;
    }

    const reversed = await leaseReversedPaymentsApi.getAll(
      new GetAllLeaseReversedPaymentsQuery({ filter_txnIdFailedPayment: leaseCharge.reference })
    );

    const checkIfReversedPayment = Boolean(reversed.data && reversed.data.data.length > 0);

    setIsReversedPayment(checkIfReversedPayment);
  };

  const loadData = async () => {
    if (editMode && transactionId) {
      setLoadingDialogState(DialogState.Show);

      const response = await apiMethod.get(transactionId).catch((error) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });
      if (response && response.status && response.data) {
        setCurrentLeaseId(response.data.lease);
        response.data.lines?.forEach(initUniqueKey);
        setChargeData(
          duplicateMode
            ? isRecurringTransaction
              ? cleanRecurringTransactionDataForDuplicateMode(response.data)
              : cleanLeaseChargeDataForDuplicateMode(response.data)
            : response.data
        );

        if (response.data.totalAmount && response.data.totalBalance) {
          setTotalpayments(mathUtils.substract(response.data.totalAmount, response.data.totalBalance));
        }

        handleIsReversedPayment(response.data);
        setLoadingDialogSuccessText(t(loadingTransactionDataSuccessText));
        setLoadingDialogState(DialogState.Success);
        setTimeout(() => {
          setLoadingDialogState(DialogState.Hidden);
          setShouldRenderForm(true);
        }, 500);
      } else {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(response ? response.message : "");
      }
    }
  };

  const uploadFiles = async (id: string, isFilesSharedWithTenant: boolean) => {
    if (isFilesSharedWithTenant !== undefined) {
      files.forEach((file) => {
        file.isSharedWithTenant = isFilesSharedWithTenant;
      });
    }

    await filesApi.uploadFiles(files, id, linkedResourceType).catch((error: string) => {
      setLoadingDialogState(DialogState.Error);
      setLoadingDialogErrorText(error);
    });
  };

  const upsetTransaction = async (
    values: LeaseChargeDto,
    isUpdate?: boolean
  ): Promise<{ results: void | ApiResult<LeaseChargeDto>; values: LeaseChargeDto }> => {
    const chargeValues = _.cloneDeep(values);

    if (!files.length) {
      chargeValues.isFilesSharedWithTenant = false;
    }

    setViewIndex(2);
    setLoadingDialogState(DialogState.Show);

    const payload: transactionPayload = {
      chargeValues,
      toast: {
        idKey: "lease",
        translationKey:
          type === "oneTime" ? undefined : AppStrings.Toasts.custom.recurring[type]?.[isUpdate ? "PUT" : "POST"]
      }
    };

    const results = await (
      isUpdate
        ? apiMethod.update(transactionId, payload.chargeValues, payload.toast)
        : apiMethod.create(payload.chargeValues, payload.toast)
    ).catch((error) => {
      setLoadingDialogState(DialogState.Error);
      setLoadingDialogErrorText(error);
    });

    return { results, values: chargeValues };
  };

  const createTransaction = async (): Promise<boolean> => {
    let transactionCreated = false;

    if (formikGlobalRef) {
      const { results, values } = await upsetTransaction(formikGlobalRef.values);

      if (results && results.status && results.data?.id) {
        await uploadFiles(results.data.id, Boolean(values.isFilesSharedWithTenant));

        setLoadingDialogState(DialogState.Success);
        setTimeout(() => {
          onClose();
        }, 500);

        transactionCreated = true;
      } else {
        setLoadingDialogErrorText(results ? results.message : "");
        setLoadingDialogState(DialogState.Error);
      }
    }

    return transactionCreated;
  };

  const updateTransaction = async (): Promise<boolean> => {
    let transactionUpdated = false;

    if (formikGlobalRef && transactionId) {
      const { results, values } = await upsetTransaction(formikGlobalRef.values, true);

      if (results && results.status && results.data?.id) {
        await uploadFiles(results.data.id, Boolean(values.isFilesSharedWithTenant));

        setLoadingDialogSuccessText(t(transactionUpdatedText));
        setLoadingDialogState(DialogState.Success);
        setTimeout(() => {
          onClose();
        }, 500);

        transactionUpdated = true;
      } else {
        setLoadingDialogErrorText(results ? results.message : "");
        setLoadingDialogState(DialogState.Error);
      }
    }

    return transactionUpdated;
  };

  const didPressSaveButton = async () => {
    if (formikGlobalRef !== null) {
      const isValid = await isValidForm(formikGlobalRef);
      if (isValid) {
        const trackSaveEvent = editMode && !duplicateMode ? await updateTransaction() : await createTransaction();

        if (trackSaveEvent) {
          dispatchAnalytics("lease_transaction_dialog_saved", {
            transactionType: type,
            isFileAdded: Boolean(files.length)
          });
        }
      }
    }
  };

  const getTotalAmount = () =>
    get(formikGlobalRef?.values, getFieldName(type), []).reduce(
      (acc: number, { amount = 0 }: any = {}) => mathUtils.add(acc, amount),
      0
    );

  const addLine = useCallback(() => {
    const fieldName = getFieldName(type);
    const lines = get(formikGlobalRef?.values, fieldName, []);
    formikGlobalRef?.setFieldValue(fieldName, [
      ...lines,
      new LeaseTransactionLineBaseDto({
        uniqueKey: uuid()
      })
    ]);
  }, [type]);

  const lastLineRef = useLastArrayItemRef<HTMLDivElement>(addLine, []);

  const renderLines = () => {
    const fieldArrayName = getFieldName(type);
    return (
      <FieldArray
        name={fieldArrayName}
        render={(arrayHelpers) => {
          if (formikGlobalRef && formikGlobalRef.values) {
            const formLines = get(formikGlobalRef.values, fieldArrayName, []);
            const lines = formLines?.map((currentLine: LeaseTransactionLineBaseDto, lineIndex: number) => (
              <div style={{ width: "100%" }} key={currentLine?.uniqueKey}>
                <View>
                  <TransactionLine
                    domRef={lineIndex === formLines.length - 1 ? lastLineRef : undefined}
                    transactionItem={currentLine}
                    lineIndex={lineIndex}
                    arrayHelpers={arrayHelpers}
                    key={lineIndex}
                    name={fieldArrayName}
                    accountFilterClass={accountFilterClass}
                    accountFilterTypes={accountFilterTypes}
                    source={source}
                  />
                </View>
              </div>
            ));

            return (
              <View>
                <ListInputsContainer>{lines}</ListInputsContainer>
                <View alignItems={"flex-end"} flexDirection={"row"}>
                  <Button
                    color={"lightBlue"}
                    type={"inlineText"}
                    actionText={AppStrings.Leases.LeaseCharge.AddLineItem}
                    onClick={addLine}
                    LeftIcon={AddIcon}
                    applyColorForIcons
                    iconSize={15}
                  />
                </View>
              </View>
            );
          }
          return <div />;
        }}
      />
    );
  };

  const renderLinesValidation = () => {
    if (formikGlobalRef?.values?.lines?.length !== 0) {
      return null;
    }

    return (
      <ValidationIndicator
        shouldShow
        maxWidth={400}
        displayText={t(AppStrings.Leases.LeaseCharge.EmptyChargeValidationMessage)}
        marginTop={"20px"}
        justifyContent={"flex-start"}
      />
    );
  };

  const renderSummaryLine = () => {
    const totalAmount = getTotalAmount();
    return (
      <View marginTop={20}>
        <FastField component={Notes} height={50} name={"memo"} />
        <View justifyContent={"flex-end"}>
          <DialogAmountView amount={totalAmount} title={AppStrings.Common.ListHeader.Total} />
        </View>
      </View>
    );
  };

  const onFileReceive = (receiveFiles: FileListItemProps[]) => {
    setFiles(receiveFiles);
  };

  const renderAttachments = () => (
    <View justifyContent={"flex-end"} width={"100%"} flex={1} marginTop={20} marginBottom={20}>
      <FormAttachments
        editMode={editMode}
        resourceId={chargeData ? chargeData.id : undefined}
        resourceType={linkedResourceType}
        onFileReceived={onFileReceive}
        files={files}
        filesSharedFormikFieldName={filesSharedFormikFieldName}
      />
    </View>
  );

  const didPressSelectLease = () => {
    setViewIndex(1);
    setTimeout(() => {
      setRenderSelectionList(true);
    }, 500);
  };

  const didChangeCheckboxSelection = () => {
    if (formikGlobalRef) {
      formikGlobalRef.setFieldValue("noEndDate", !repeatUntilEndLease);

      if (isRecurringTransaction && chargeData) {
        const recurringTransaction = chargeData as RecurringTransactionDto;
        recurringTransaction.noEndDate = !repeatUntilEndLease;
      }
      setRepeatUntilEndLease(!repeatUntilEndLease);
    }
  };

  const renderFormTopSection = () => (
    <View marginBottom={20} marginTop={20} flexDirection={"row"}>
      <View flexDirection={"row"} noWrap={!isMobile} gap={20}>
        <Grid item xs={12} sm={6}>
          <LeaseSelection
            didPressSelectLease={didPressSelectLease}
            leaseId={currentLeaseId}
            errorTex={showLeaseSelectionError ? leaseSelectionErrorText : undefined}
          />
        </Grid>
        {!isRecurringTransaction && (
          <Grid item xs={12} sm={3}>
            <FastField
              component={FormikDatePicker}
              uniqueKey={"chargeDueDate"}
              label={customDateLabel || AppStrings.Leases.NewLease.LeaseRent.DueDate}
              name={"date"}
              noMargin
              required
            />
          </Grid>
        )}
        <Grid item xs={12} sm={!isRecurringTransaction ? 3 : 6}>
          <FastField
            component={FormikReferenceLabel}
            name={"reference"}
            backgroundColor={"dark"}
            valueTextTransform={"uppercase"}
          />
        </Grid>
      </View>
      <View flexDirection={"row"}>
        {isRecurringTransaction && (
          <Fragment>
            <Grid container item xs={12} sm={4}>
              <FastField
                component={FormikDatePicker}
                //@ts-ignore
                minDate={chargeData?.lastOccurrence}
                uniqueKey={"start"}
                label={AppStrings.Leases.NewLease.StartDate}
                name={"start"}
                noMargin
                required
                marginTop={20}
                paddingRight={inputPaddingRight}
              />
            </Grid>
            <Grid container item xs={12} sm={4}>
              <Field
                component={FormikDatePicker}
                uniqueKey={"end"}
                label={AppStrings.Leases.NewLease.EndDate}
                name={"end"}
                noMargin
                required
                disabled={repeatUntilEndLease}
                marginTop={20}
                paddingRight={inputPaddingRight}
              />
            </Grid>
            <Grid container item xs={12} sm={4}>
              <FastField
                component={Select}
                name={`frequency`}
                label={AppStrings.Leases.NewLease.LeaseRent.Frequency}
                required
                uniqueKey={"paymentMethod"}
                selectionEnum={RecurringTransactionFrequency}
                translationKey={"recurringTransactionFrequency"}
                marginTop={20}
              />
            </Grid>
            <Grid container item xs={12} sm={4}>
              <ControlledCheckBox
                checked={repeatUntilEndLease}
                onChange={didChangeCheckboxSelection}
                labelValueProps={{
                  text: AppStrings.Leases.NewLease.LeaseRent.RepeatUntilEndOfLease,
                  color: "black"
                }}
              />
            </Grid>
          </Fragment>
        )}
      </View>
    </View>
  );

  const renderForm = () => {
    const formInitialValues = initFormvalues();
    return (
      <Formik initialValues={formInitialValues} onSubmit={() => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;

          return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <View
                flex={1}
                width={"100%"}
                paddingLeft={screenContainerPadding}
                paddingRight={screenContainerPadding}
                flexDirection={"column"}
              >
                <RestrictedPermissionAccess clearance={clearance} showNoAccess>
                  {isReversedPayment && <ReversePaymentNotification />}
                  <ReconciledNotificationView
                    register={chargeData instanceof LeaseChargeDto ? chargeData.register : undefined}
                  />
                  <View className={classes.sectionSeparator}>{renderFormTopSection()}</View>
                  {renderLines()}
                  {renderLinesValidation()}
                  {renderSummaryLine()}
                  {renderAttachments()}
                </RestrictedPermissionAccess>

                {editMode && <ActivityLabel item={chargeData} />}
              </View>
            </MuiPickersUtilsProvider>
          );
        }}
      </Formik>
    );
  };

  const renderLeaseSelectionList = () => {
    const didChangeSearchQuery = (value: string) => {
      setSelectLeaseFilterObj({
        filter_text: value
      });
    };

    const onListItemPress = (leaseId: string) => {
      setViewIndex(0);
      setCurrentLeaseId(leaseId);
    };

    return (
      <View>
        <View paddingLeft={20} paddingRight={20}>
          <DialogSearchPanel onChange={didChangeSearchQuery} borderRadius={30} />

          {renderSelectionList ? (
            <div id={"selectableLeaseListItem"} style={{ width: "100%" }}>
              <LeasesList
                ListItem={SelectableLeaseListItem}
                filterObj={selectLeaseFilterObj}
                didPressListItem={onListItemPress}
                stickyHeaderId={"selectableLeaseListItem"}
                selectableItem
              />
            </div>
          ) : null}
        </View>
      </View>
    );
  };

  const renderView = ({ index }: any) => {
    const onRetryButtonPress = async () => {
      await didPressSaveButton();
    };
    const didPressDismissButton = () => {
      setViewIndex(0);
    };

    if (index === 0) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={loadData}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }
      if (shouldrenderForm) {
        return renderForm();
      }
      return <div />;
    }
    if (index === 1) {
      return renderLeaseSelectionList();
    }
    if (index === 2) {
      return (
        <View alignItems={"center"} justifyContent={"center"} height={"100%"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
          />
        </View>
      );
    }
    if (index === 3) {
      return (
        <DeleteConfirmation
          apiMethod={apiMethod}
          apiToasts={{
            translationKey: type === "oneTime" ? undefined : AppStrings.Toasts.custom.recurring[type]?.DELETE
          }}
          didPressDismissButton={() => setViewIndex(0)}
          didFinishOperation={onClose}
          transactionId={transactionId}
          attachments={files}
        />
      );
    }
    return <div />;
  };

  const _onBackdropClick = () => {
    if (viewIndex === 1) {
      setViewIndex(0);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const handleDeleteClick = () => {
    setViewIndex(3);
  };
  const handleDuplicateClick = () => {
    queryParams.set(DUPLICATE_MODE_QUERY_PARAMS.transactionId, transactionId);
    queryParams.historyPush();

    dispatchAnalytics("button_click", {
      label: DuplicateDialogButtonLabelValues.DUPLICATE_JOURNAL_ENTRY
    });
  };
  const renderHeaderActionButtons = () => (
    <DialogHeaderActionButtons
      onDeleteClick={handleDeleteClick}
      onDuplicateClick={handleDuplicateClick}
      clearance={clearance}
      hideDeleteButton={duplicateMode || !editMode}
      hideDuplicateButton={duplicateMode || !editMode}
      onPrintClick={editMode && showPrintButton ? printJournalEntry : undefined}
      printLoadingStatus={printLoadingStatus}
    />
  );

  const renderActionPanelButtons = () => (
    <FormActionButtons
      clearance={clearance}
      propsActionPanel={{
        editMode
      }}
      propsSubButton={{ onClick: _onBackdropClick }}
      propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
    />
  );

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }
    if (viewIndex === 2 || viewIndex === 3) {
      return "contentOnly";
    }
    return "sectionTitleFrame";
  }, [viewIndex, loadingDialogState]);

  const currentTitle = useMemo(() => {
    if (viewIndex === 0) {
      return dialogTitle;
    }

    if (viewIndex === 1) {
      return AppStrings.Leases.LeaseCharge.SelectLease;
    }
    return "";
  }, [viewIndex]);

  const duplicateModeTitle = useMemo(
    () => (duplicateMode ? `${t(currentTitle)} (${t(AppStrings.Common.Duplicated)})` : ""),
    [currentTitle, duplicateMode, t]
  );

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={duplicateModeTitle || currentTitle}
      titleActions={dialogTitleActions}
      width={dialogWidth}
      height={dialogFrameHeight || 900}
      renderView={renderView}
      numViews={4}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      RenderHeaderActionButtons={renderHeaderActionButtons}
      frameType={frameType}
      helpPanel={helpObject}
    />
  );
};

export default Transaction;
