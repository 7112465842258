import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    componentContainer: {
      backgroundColor: "rgba(245, 246, 250, 0.90)",
      top: 0,
      left: 0,
      zIndex: 500,
      display: "flex",
      justifyContent: "center"
    },
    iconGrid: {
      paddingLeft: 10
    },

    removeVerticalPaddingIconGrid: {
      paddingLeft: 30
    }
  })
);
