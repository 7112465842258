import type { SidePanelProps } from "DLUI/sidePanel/sidePanel";
import SidePanel from "DLUI/sidePanel/sidePanel";
import { View } from "DLUI/view";
import { DialogTitle } from "DLUI/screen";
import { IconButton } from "DLUI/form";
import { CloseCircleIcon } from "../../../../assets";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import type { PropsWithChildren, ReactNode } from "react";
import BaseButton from "DLUI/button/baseButton";
import ColorsEnum from "../../../../utils/colorsEnum";
import AppStrings from "../../../../locale/keys";
import { useTranslation } from "react-i18next";
import DLButton, { DLButtonColorsEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import { isWebView } from "common/native/utils";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { DataCy } from "@doorloop/dto";
import { createPortal } from "react-dom";

export interface SidePanelFilterPanelProps extends SidePanelProps {
  onCloseClick: () => void;
  filterTriggers?: Record<string, any>;
  endAdornment?: ReactNode;
  hideCloseButton?: boolean;
  portalElement?: HTMLElement;
}

const SidePanelFilterPanel = ({
  onCloseClick,
  children,
  filterTriggers,
  endAdornment,
  hideCloseButton = false,
  portalElement = document.body,
  ...sidePanelProps
}: PropsWithChildren<SidePanelFilterPanelProps>) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();

  const handleTriggers = (triggerFunc: () => void) => {
    onCloseClick?.();
    triggerFunc?.();
  };

  return createPortal(
    <SidePanel
      width={350}
      paddingRight={20}
      paddingLeft={20}
      noWrap
      overflow={"hidden"}
      justifyContent={"space-between"}
      zIndex={9999}
      id={"SidePanel"}
      {...sidePanelProps}
    >
      <View noWrap paddingTop={20} style={{ flex: "0 1 auto", overflow: "hidden" }}>
        <View flexDirection={"row"} justifyContent={"space-between"}>
          <DialogTitle title={t(AppStrings.Common.Filter)} textTransform={"uppercase"} fontSize={16} />
          <View width={"auto"} flexDirection={"row"} alignItems={"center"} gap={15}>
            <IconButton
              Icon={CloseCircleIcon}
              width={30}
              height={30}
              onClick={onCloseClick}
              circleFill={"black"}
              removePadding
              dataCy={DataCy.DLUI.filters.closeIconButton}
            />
          </View>

          <HorizontalSeparationLine marginTop={10} />
        </View>

        <View
          style={{ flex: "1", overflow: "hidden auto" }}
          paddingTop={10}
          paddingBottom={10}
          marginBottom={10}
          noWrap
        >
          {children}
        </View>
      </View>

      <View style={{ zIndex: 10 }} paddingBottom={20}>
        {isMobile && filterTriggers && (
          <View flexDirection={"row"} justifyContent={"space-between"} noWrap>
            {!isWebView() && (
              <DLButton
                onClick={() => handleTriggers(filterTriggers?.downloadPdf?.trigger)}
                isLoading={filterTriggers?.downloadPdf?.status}
                actionText={isMobile ? AppStrings.Common.DownloadReport : AppStrings.Common.DownloadPrintable}
                color={DLButtonColorsEnum.NEUTRAL}
                variant={DLButtonVariantsEnum.OUTLINED}
                style={{ marginRight: 10, width: "100%" }}
              />
            )}
            <DLButton
              onClick={() => handleTriggers(filterTriggers?.run?.trigger)}
              isLoading={filterTriggers?.run?.status}
              actionText={AppStrings.Reports.ReportsScreen.RunReport}
              color={DLButtonColorsEnum.PRIMARY}
              style={{ width: "100%" }}
            />
          </View>
        )}
        {endAdornment && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%"
            }}
          >
            {endAdornment}
          </div>
        )}
        {!hideCloseButton && (
          <BaseButton
            fullWidth
            width={"100%"}
            borderColor={ColorsEnum.LightGray}
            backgroundColor={ColorsEnum.White}
            borderRadius={5}
            marginTop={10}
            onClick={onCloseClick}
            actionText={AppStrings.Common.Close}
            dataCy={DataCy.DLUI.searchPanel.close}
          />
        )}
      </View>
    </SidePanel>,
    portalElement
  );
};

export default SidePanelFilterPanel;
