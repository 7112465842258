import type {
  FieldSettingsType,
  RentalApplicationCompanySettingsDto,
  RentalApplicationFieldControlledSectionSettingsDto
} from "@doorloop/dto";
import { RentalApplicationFieldSettings, RentalApplicationOptionalControlledSections } from "@doorloop/dto";
import { View } from "DLUI/view";
import { FastField, getIn, useFormikContext } from "formik";
import React, { useMemo, useState } from "react";
import Text from "DLUI/text";
import AppStrings from "../../../../../../locale/keys";
import { FormikSwitchButton, RadioGroup } from "DLUI/form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { SeparationLine } from "DLUI/separatorView";
import { RichTextField } from "DLUI/form/richTextField/richTextField";
import { RentalAppFieldsGenerator } from "screens/settings/rentalApplications/rentalAppFieldsGenerator";
import { RentalApplicationWizardSteps } from "screens/tenantPortal/rentalApplication/types";
import { didChangeShowContentSelection } from "screens/settings/rentalApplications/formikHelper";
import WarningView from "DLUI/form/warningView/warningView";

export const renderFieldsCannotBeEmptyError = (
  sectionFormValues?: RentalApplicationFieldControlledSectionSettingsDto,
  sectionName?: string
) => {
  let hasMandatoryFields = false;

  if (sectionName) {
    const initialSectionFieldsObject = RentalApplicationFieldSettings?.[sectionName]?.fields as FieldSettingsType;
    hasMandatoryFields = initialSectionFieldsObject
      ? Object.values(initialSectionFieldsObject).some((field) => field?.isMandatory)
      : false;
  }

  if (
    sectionFormValues?.isShown &&
    !hasMandatoryFields &&
    (!sectionFormValues?.fieldsSettings ||
      _.isEmpty(sectionFormValues?.fieldsSettings) ||
      !_.some(sectionFormValues?.fieldsSettings, (item) => Boolean(item?.isVisible)))
  ) {
    return (
      <View marginBottom={20} marginTop={10}>
        <WarningView
          iconSize={20}
          type={"danger"}
          subTitle={AppStrings.Common.RentalApplicationSettingsSectionFieldsRequired}
        />
      </View>
    );
  }
};

const VehicleInformation: React.FC<{}> = () => {
  const { t } = useTranslation();
  const formik = useFormikContext<RentalApplicationCompanySettingsDto>();
  const {
    values: { vehicles },
    setFieldValue
  } = formik;
  const [showContent, setShowContent] = useState<boolean>(Boolean(getIn(vehicles, "isShown")) || false);

  const { propertyId } = useParams<any>();
  const [renderForm, setRenderForm] = useState<boolean>(true);

  const vehicleInformationSelectionDefaultValue = useMemo(() => {
    const emptyObject = _.values(vehicles).every((x) => x === undefined);
    if (emptyObject) {
      return "companyDefault";
    }
    return "specifyVehicleInformation";
  }, []);

  const [vehicleInformationSelectionType, setVehicleInformationSelectionType] = useState<
    "companyDefault" | "specifyVehicleInformation"
  >(vehicleInformationSelectionDefaultValue);

  const didChangeVehicleInformation = (selectedValue: string) => {
    if (selectedValue === "false") {
      setVehicleInformationSelectionType("companyDefault");

      setTimeout(() => {
        refreshForm();
      }, 500);
    } else {
      setVehicleInformationSelectionType("specifyVehicleInformation");
      setFieldValue("vehicles.isShown", false);
    }
  };

  const refreshForm = () => {
    setRenderForm(false);
    setShowContent(false);
    setFieldValue("vehicles", undefined);
    setTimeout(() => {
      setRenderForm(true);
    }, 0);
  };

  const renderSpecifyRadioButtons = () => {
    if (propertyId) {
      return (
        <View>
          <RadioGroup
            didChangeSelection={didChangeVehicleInformation}
            uniqueKey={"FRB"}
            defaultValue={vehicleInformationSelectionType === "companyDefault" ? "false" : "true"}
            marginTop={20}
            radioGroupItems={[
              {
                label: AppStrings.Common.HideSectionForProperty,
                value: "false"
              },
              {
                label: AppStrings.Common.SpecifySettingsForProperty,
                value: "true"
              }
            ]}
          />
          <SeparationLine marginTop={20} width={"100%"} height={1} />
        </View>
      );
    }
  };

  return (
    <>
      <Text
        value={AppStrings.Settings.GeneralSettings.RentalApplications.VehicleSectionDescription}
        lineHeight={"26px"}
      />
      {renderSpecifyRadioButtons()}
      <View
        shouldShow={vehicleInformationSelectionType === "specifyVehicleInformation"}
        showAnimation={"fade-in"}
        hideAnimation={"fade-out"}
      >
        <View height={16} />
        <FastField
          component={FormikSwitchButton}
          name={"vehicles.isShown"}
          label={AppStrings.Settings.GeneralSettings.RentalApplications.AskAboutVehicles}
          onChange={(value: boolean) =>
            didChangeShowContentSelection({
              value,
              setFunction: setShowContent,
              formik,
              formName: "vehicles",
              fieldsObj: RentalApplicationFieldSettings.vehicles.fields
            })
          }
        />
        <View shouldShow={showContent} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
          <View>
            <Text
              value={AppStrings.Tasks.WorkOrdersScreen.Instructions}
              fontSize={20}
              fontWeight={700}
              marginTop={20}
            />
            <FastField
              component={RichTextField}
              label={t(AppStrings.Tasks.WorkOrdersScreen.Instructions)}
              name={`vehicles.instructions`}
              styles={{ container: { marginTop: 20 } }}
              maxLength={5000}
              html={{ name: "vehicles.instructionsHTML", value: vehicles?.instructionsHTML }}
            />
            <View flexDirection={"row"} justifyContent={"space-between"} noWrap marginTop={30} marginBottom={12}>
              <Text value={AppStrings.Common.Field} fontSize={12} color={"secondary-gray"} />
              <Text value={AppStrings.Common.Required} fontSize={12} color={"secondary-gray"} />
            </View>
            <View noWrap>
              <RentalAppFieldsGenerator
                fieldsConfigObject={RentalApplicationFieldSettings.vehicles.fields}
                formik={formik}
                formName={RentalApplicationWizardSteps.Vehicles}
              />
              {renderFieldsCannotBeEmptyError(vehicles, RentalApplicationOptionalControlledSections.Vehicles)}
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

export default VehicleInformation;
