"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskType = void 0;
var TaskType;
(function (TaskType) {
    TaskType["INTERNAL_TASK"] = "INTERNAL_TASK";
    TaskType["TENANT_REQUEST"] = "TENANT_REQUEST";
    TaskType["OWNER_REQUEST"] = "OWNER_REQUEST";
    TaskType["WORK_ORDER"] = "WORK_ORDER";
})(TaskType || (exports.TaskType = TaskType = {}));
