"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseDraftClientDto = exports.LeaseDraftDto = void 0;
const isDateStringWithoutTime_validator_1 = require("../common/validators/isDateStringWithoutTime.validator");
const leaseBase_dto_1 = require("../leases/leaseBase.dto");
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const lateFeePolicy_1 = require("../settings/shared/lateFeePolicy");
const leaseDraftOneTimeCharge_dto_1 = require("./leaseDraftOneTimeCharge.dto");
const leaseDraftRentCharge_dto_1 = require("./leaseDraftRentCharge.dto");
const leaseDraftTenant_dto_1 = require("./leaseDraftTenant.dto");
const leaseDraftType_enum_1 = require("./leaseDraftType.enum");
const leaseDraftRecurringCharge_dto_1 = require("./leaseDraftRecurringCharge.dto");
const leaseDraftStatus_enum_1 = require("./leaseDraftStatus.enum");
const properties_1 = require("../properties");
class LeaseDraftDto extends leaseBase_dto_1.LeaseBaseDto {
    constructor(init) {
        super();
        this.units = new Array();
        this.tenants = new Array();
        this.rentCharges = new Array();
        this.otherOneTimeCharges = new Array();
        this.securityDeposits = new Array();
        this.otherRecurringCharges = new Array();
        this.otherRecurringCredits = new Array();
        this.otherOneTimeCredits = new Array();
        Object.assign(this, init);
    }
}
exports.LeaseDraftDto = LeaseDraftDto;
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.type === leaseDraftType_enum_1.LeaseDraftType.NEW_LEASE),
    (0, class_validator_1.IsArray)({ message: "This field is required" }),
    (0, class_validator_1.IsMongoId)({ each: true }),
    (0, class_validator_1.ArrayMinSize)(1),
    __metadata("design:type", Array)
], LeaseDraftDto.prototype, "units", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.type === leaseDraftType_enum_1.LeaseDraftType.NEW_LEASE),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], LeaseDraftDto.prototype, "property", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.type === leaseDraftType_enum_1.LeaseDraftType.NEW_LEASE),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], LeaseDraftDto.prototype, "start", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(leaseDraftStatus_enum_1.LeaseDraftStatus),
    __metadata("design:type", String)
], LeaseDraftDto.prototype, "status", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => leaseDraftOneTimeCharge_dto_1.LeaseDraftOneTimeChargeDto),
    __metadata("design:type", Array)
], LeaseDraftDto.prototype, "otherOneTimeCharges", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => leaseDraftOneTimeCharge_dto_1.LeaseDraftOneTimeChargeDto),
    __metadata("design:type", Array)
], LeaseDraftDto.prototype, "securityDeposits", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(leaseDraftType_enum_1.LeaseDraftType),
    __metadata("design:type", String)
], LeaseDraftDto.prototype, "type", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.type === leaseDraftType_enum_1.LeaseDraftType.RENEWAL_LEASE),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], LeaseDraftDto.prototype, "renewalForLease", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.type === leaseDraftType_enum_1.LeaseDraftType.NEW_LEASE),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], LeaseDraftDto.prototype, "residentPortalAccess", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.type === leaseDraftType_enum_1.LeaseDraftType.NEW_LEASE),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], LeaseDraftDto.prototype, "requireRentersInsurance", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.type === leaseDraftType_enum_1.LeaseDraftType.NEW_LEASE),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], LeaseDraftDto.prototype, "automaticLateFees", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateIf)((x) => x.type === leaseDraftType_enum_1.LeaseDraftType.NEW_LEASE),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => lateFeePolicy_1.LateFeesSettingsDto),
    __metadata("design:type", lateFeePolicy_1.LateFeesSettingsDto)
], LeaseDraftDto.prototype, "lateFeesSettings", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.type === leaseDraftType_enum_1.LeaseDraftType.NEW_LEASE),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ArrayMinSize)(1),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => leaseDraftTenant_dto_1.LeaseDraftTenantDto),
    __metadata("design:type", Array)
], LeaseDraftDto.prototype, "tenants", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => leaseDraftRentCharge_dto_1.LeaseDraftRentChargeDto),
    __metadata("design:type", Array)
], LeaseDraftDto.prototype, "rentCharges", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => leaseDraftRecurringCharge_dto_1.LeaseDraftRecurringChargeDto),
    __metadata("design:type", Array)
], LeaseDraftDto.prototype, "otherRecurringCharges", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => leaseDraftRecurringCharge_dto_1.LeaseDraftRecurringChargeDto),
    __metadata("design:type", Array)
], LeaseDraftDto.prototype, "otherRecurringCredits", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => leaseDraftOneTimeCharge_dto_1.LeaseDraftOneTimeChargeDto),
    __metadata("design:type", Array)
], LeaseDraftDto.prototype, "otherOneTimeCredits", void 0);
class LeaseDraftClientDto extends LeaseDraftDto {
    constructor(init) {
        super();
    }
}
exports.LeaseDraftClientDto = LeaseDraftClientDto;
__decorate([
    (0, class_validator_1.ValidateIf)((x) => { var _a; return x.type === leaseDraftType_enum_1.LeaseDraftType.NEW_LEASE && ((_a = x.property) === null || _a === void 0 ? void 0 : _a.class) === properties_1.PropertyClass.COMMERCIAL; }),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], LeaseDraftClientDto.prototype, "unit", void 0);
