import React, { useCallback, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import type { SVGIconComponent } from "@/assets";
import { TrashIconOutline, UploadIcon } from "@/assets";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import { Icon } from "DLUI/icon";
import { useDropzone } from "react-dropzone";
import { IconButton } from "DLUI/form";
import { useHover } from "usehooks-ts";
import ColorsEnum from "utils/colorsEnum";
import type { Translation } from "locale";
import type { Theme } from "styles/defaultTheme";
import { useResponsiveHelper } from "contexts/responsiveContext";
import clsx from "clsx";

export interface UploadVisualContentBaseProps {
  className?: string;
  currentImageUrl?: string;
  onContentReceive: <T extends File>(acceptedFiles: T[]) => void;
  onRemoveContent: () => void;
}

export interface UploadVisualContentProps extends UploadVisualContentBaseProps {
  buttonText: Translation;
  FallbackIcon: SVGIconComponent;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      gap: 8
    },
    dropzone: ({ isHovered, currentImageUrl }: { isHovered: boolean; currentImageUrl?: string }) => {
      return {
        borderRadius: 4,
        border: `1px dashed ${ColorsEnum.BrightBlueFaded}`,
        backgroundColor: isHovered ? "rgba(66, 147, 241, 0.1)" : "rgba(66, 147, 241, 0.04)",
        display: "flex",
        justifyContent: "center",
        ...(currentImageUrl
          ? {
              backgroundImage: `url(${currentImageUrl})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              //a trick to set a background color with opacity without affecting the image & creating a new layer
              boxShadow: `inset 0px 0px 0px 1000px ${isHovered ? "rgba(66, 147, 241, 0.3)" : "rgba(66, 147, 241, 0.2)"}`
            }
          : {})
      };
    },
    iconContainer: {
      paddingBottom: 4,
      marginTop: "auto",
      paddingRight: 4,
      paddingLeft: 4,
      display: "flex",
      flexDirection: "row-reverse",
      width: "100%"
    },
    uploadButton: {
      padding: 0,
      "&:hover": {
        backgroundColor: theme.newPalette.secondary.states.hover
      }
    }
  };
});

const TEXT_OVERRIDE_STYLE = { fontSize: 16 };
const TRASH_ICON_STYLE = { backgroundColor: "transparent" };

export const UploadVisualContent = ({
  buttonText,
  className,
  currentImageUrl,
  onContentReceive,
  onRemoveContent,
  FallbackIcon
}: UploadVisualContentProps) => {
  const { isMobile } = useResponsiveHelper();
  const dropContainer = useRef<HTMLDivElement>(null);
  const isHovered = useHover(dropContainer);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onContentReceive,
    noDragEventsBubbling: true,
    noClick: true,
    multiple: false,
    accept: ["image/*"]
  });

  const onDeleteContentClick = useCallback((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event?.stopPropagation();
    onRemoveContent();
  }, []);

  const classes = useStyles({ isHovered, currentImageUrl });

  return (
    <div {...getRootProps()} className={classes.container}>
      <input {...getInputProps()} />
      <div className={clsx(classes.dropzone, className)} ref={dropContainer} onClick={open}>
        {!currentImageUrl ? (
          <Icon Source={FallbackIcon} size={24} />
        ) : (
          <div className={classes.iconContainer}>
            <IconButton
              Icon={TrashIconOutline}
              pathColor={"light-gray"}
              onClick={onDeleteContentClick}
              size={14}
              disableRipple
              paddingBottom={5}
              paddingTop={5}
              paddingRight={5}
              paddingLeft={5}
              borderRadius={4}
              backgroundColor={"white"}
              style={TRASH_ICON_STYLE}
            />
          </div>
        )}
      </div>
      <DLButton
        actionText={buttonText}
        onClick={open}
        icons={{ start: { src: UploadIcon } }}
        variant={DLButtonVariantsEnum.TEXT}
        color={DLButtonColorsEnum.SECONDARY}
        size={isMobile ? DLButtonSizesEnum.LARGE : DLButtonSizesEnum.SMALL}
        disableMobileCollapse
        textProps={{ style: isMobile ? TEXT_OVERRIDE_STYLE : {} }}
        containerClassName={classes.uploadButton}
      />
    </div>
  );
};
