import { RecurringTransactionType, ServerRoutes, TagType, TaskType, TenantPortalServerRoutes } from "@doorloop/dto";

import type { ToastsTranslation } from "../keys/toasts";

const toasts: ToastsTranslation<false> = {
  custom: {
    copy: {
      text: "Text has been copied",
      link: "Link has been copied",
      apiKey: "API Key has been copied to clipboard"
    },
    upload: { file: { POST: "File has been added" } },
    credit: { withhold: { POST: "A deposit has been withheld" } },
    tasks: {
      [TaskType.WORK_ORDER]: { POST: "New work order has been created" },
      [TaskType.TENANT_REQUEST]: { POST: "New tenant request has been created" },
      [TaskType.OWNER_REQUEST]: { POST: "New owner request has been created" },
      complete: { POST: "Task has been Completed" }
    },
    lease: { activate: { POST: "Lease has been activated" }, cancel: { POST: "Lease has been canceled" } },
    accounts: { cancel: { POST: "Account has been deactivated" } },
    signatureRequest: { cancel: { POST: "Signature request has been canceled" } },
    properties: {
      activate: { PUT: "Property has been activated" },
      deactivate: { PUT: "Property has been deactivated" }
    },
    units: {
      activate: { PUT: "Unit has been activated" },
      deactivate: { PUT: "Unit has been deactivated" }
    },
    reports: {
      addToFavorite: { POST: "Report has been added to favorites" },
      removeFromFavorite: { POST: "Report has been removed from favorites" },
      downloadReport: { POST: "The report has been downloaded" }
    },
    leasePayments: {
      receiptSent: { POST: "A receipt has been sent successfully" }
    },
    tags: {
      [TagType.NOTES_TAG]: {
        POST: "New tag has been added",
        PUT: "Tag has been saved",
        DELETE: "Tag has been removed"
      },
      [TagType.LEAD_SOURCE]: {
        POST: "New lead source has been added",
        PUT: "lead source has been saved",
        DELETE: "lead source has been removed"
      },
      [TagType.FILES_TAG]: {
        POST: "New File tag has been added",
        PUT: "File tag has been saved",
        DELETE: "File tag  has been removed"
      },
      [TagType.VENDOR_SERVICE]: {
        POST: "New vendor category has been added",
        PUT: "vendor category has been saved",
        DELETE: "vendor category has been removed"
      }
    },
    prospects: {
      PUT: "Prospect information has been saved",
      DELETE: "Prospect has been deleted"
    },
    recurring: {
      [RecurringTransactionType.GENERAL_ENTRY]: {
        POST: "New recurring journal entry has been recorded",
        PUT: "Recurring journal entry has been saved",
        DELETE: "Recurring journal entry has been deleted"
      },
      [RecurringTransactionType.LEASE_CHARGE]: {
        POST: "New recurring charge has been recorded",
        PUT: "Recurring charge has been saved",
        DELETE: "Recurring charge has been deleted"
      },
      [RecurringTransactionType.LEASE_RENT]: {
        POST: "New recurring rent has been recorded",
        PUT: "Recurring rent has been saved",
        DELETE: "Recurring rent has been deleted"
      },
      [RecurringTransactionType.LEASE_PAYMENT]: {
        POST: "New recurring payment has been recorded",
        PUT: "Recurring payment has been saved",
        DELETE: "Recurring payment has been deleted"
      },
      [RecurringTransactionType.LEASE_CREDIT]: {
        POST: "New recurring credit has been recorded",
        PUT: "Recurring credit has been saved",
        DELETE: "Recurring credit has been deleted"
      },
      [RecurringTransactionType.VENDOR_BILL]: {
        POST: "New recurring bill has been recorded",
        PUT: "Recurring bill has been saved",
        DELETE: "Recurring bill has been deleted"
      },
      [RecurringTransactionType.EXPENSE]: {
        POST: "New recurring expense has been recorded",
        PUT: "Recurring expense has been saved",
        DELETE: "Recurring expense has been deleted"
      },
      [RecurringTransactionType.TASK]: {
        [TaskType.INTERNAL_TASK]: {
          POST: "New recurring task has been recorded",
          PUT: "Recurring task has been saved",
          DELETE: "Recurring task has been deleted"
        },
        [TaskType.WORK_ORDER]: {
          POST: "New recurring work order has been recorded",
          PUT: "Recurring work order has been saved",
          DELETE: "Recurring work order has been deleted"
        }
      }
    }
  },
  api: {
    [ServerRoutes.BUDGETS]: {
      POST: `Budget successfully saved`,
      PUT: `Budget successfully saved`,
      DELETE: `Budget successfully deleted`
    },
    [TenantPortalServerRoutes.INSURANCE_POLICIES]: {
      POST: "Insurance has been successfully added",
      PUT: "Insurance has been successfully saved",
      DELETE: "Insurance has been successfully removed"
    },
    [TenantPortalServerRoutes.TENANT_REQUESTS]: {
      POST: "New request has been added",
      DELETE: "Request has been deleted"
    },
    [TenantPortalServerRoutes.TENANT_REQUESTS_POST_UPDATE]: {
      POST: "Update has been posted"
    },
    [TenantPortalServerRoutes.PAYMENTS]: {
      POST: "Payment completed successfully"
    },
    [TenantPortalServerRoutes.RECURRING_PAYMENTS]: {
      POST: "Auto pay has been successfully added"
    },
    [ServerRoutes.REPORTS_OWNER_STATEMENT_PRINTABLE_PDF]: { GET: "The report has been downloaded" },
    [ServerRoutes.NOTES]: {
      POST: "Note has been created",
      PUT: "Note has been saved",
      DELETE: "Note has been deleted"
    },
    [ServerRoutes.BANK_CONNECT_PLAID_ACCOUNTS_POST_DISCONNECT]: { POST: "Account has been disconnected" },
    [ServerRoutes.RENTAL_APPLICATIONS_PUT_UPDATE_DECISION_STATUS]: { PUT: "Decision has been saved" },
    [ServerRoutes.LEASES_POST_NOT_RENEWING]: { POST: "Lease set to not renewing" },
    [ServerRoutes.TASKS_POST_UPDATE]: { POST: "Update has been posted" },
    [ServerRoutes.LEASES_POST_END_LEASE]: { POST: "Lease has been ended" },
    [ServerRoutes.LEASE_RECURRING_TRANSACTIONS]: {
      POST: "New recurring expense has been recorded",
      PUT: "Expense has been saved",
      DELETE: "Expense has been deleted"
    },
    [ServerRoutes.LEASES_POST_MOVEIN_TENANT]: { POST: "Tenant has been successfully moved-in" },
    [ServerRoutes.LEASES_POST_MOVEOUT_TENANT]: { POST: "Tenant has been successfully moved-out" },
    [ServerRoutes.ESIGNATURE_REQUESTS]: {
      PUT: "Signature Template has been saved",
      DELETE: "Signature request has been deleted"
    },
    [ServerRoutes.GENERAL_ENTRIES]: { POST: "New journal entry has been created" },
    [ServerRoutes.VENDOR_BILL_PAYMENTS_POST_CREATE_MANY]: { POST: "Bill has been paid" },
    [ServerRoutes.VENDOR_CREDITS]: { POST: "Credit has been added" },
    [ServerRoutes.LEASE_CREDITS]: { POST: "Credit has been issued" },
    [ServerRoutes.ACCOUNTS]: { PUT: "Account information has been saved" },
    [ServerRoutes.TASKS]: {
      POST: "New task has been created",
      PUT: "Task information has been saved",
      DELETE: "Task has been deleted"
    },
    [ServerRoutes.SETTINGS_PERSONAL_INFO]: { PUT: "Personal information has been saved" },
    [ServerRoutes.SETTINGS_COMPANY_INFO]: { PUT: "Company information has been saved" },
    [ServerRoutes.SETTINGS_LOGIN_AND_PASSWORD]: { PUT: "Login & password settings has been saved" },
    [ServerRoutes.SETTINGS_REGION_AND_CURRENCY]: { PUT: "Region & currency settings has been saved" },
    [ServerRoutes.SETTINGS_DEFAULT_ACCOUNTS]: { PUT: "Default accounts settings has been saved" },
    [ServerRoutes.SUBSCRIPTION_POST_UPDATE_SUBSCRIPTION]: {
      POST: "Subscription has been updated",
      PUT: "Subscription has been updated"
    },
    [ServerRoutes.SETTINGS_QUICKBOOKS]: { PUT: "Quickbooks Sync settings has been saved" },
    [ServerRoutes.SETTINGS_LISTINGS]: { PUT: "Listing syndication settings has been saved" },
    [ServerRoutes.SETTINGS_MANAGEMENT_FEES]: { PUT: "Management Fees settings has been updated" },
    [ServerRoutes.SETTINGS_RENTAL_APPLICATIONS]: { PUT: "Rental Applications settings has been updated" },
    [ServerRoutes.SETTINGS_LATE_FEES]: { PUT: "Late Fees settings has been updated" },
    [ServerRoutes.SETTINGS_TENANT_REQUESTS]: { PUT: "Tenant Requests settings has been updated" },
    [ServerRoutes.SETTINGS_OWNER_REQUESTS]: { PUT: "Owner Requests settings has been updated" },
    [ServerRoutes.SETTINGS_PAYMENT_INSTRUCTIONS]: { PUT: "Payment Instructions has been updated" },
    [ServerRoutes.SETTINGS_RENT_REMINDERS]: { PUT: "Rent & Payment Notifications settings has been updated" },
    [ServerRoutes.SETTINGS_TENANT_PORTAL]: { PUT: "Tenant Portal settings has been updated" },
    [ServerRoutes.SETTINGS_CHECKBOOK]: { PUT: "Outgoing Payments settings has been saved" },
    [ServerRoutes.BANK_CONNECT_PLAID_TRANSACTIONS_POST_REFRESH]: { POST: "Account has been refreshed" },
    [ServerRoutes.API_KEYS]: {
      POST: "New API Key has been added",
      PUT: "API Key has been saved",
      DELETE: "API Key has been removed"
    },
    [ServerRoutes.PROPERTY_GROUPS]: {
      POST: "New Portfolios has been added",
      PUT: "Portfolios has been saved",
      DELETE: "Portfolios has been removed"
    },
    [ServerRoutes.USER_ROLES]: {
      POST: "New user Role has been created",
      PUT: "User role settings has been saved",
      DELETE: "Role has been deleted"
    },
    [ServerRoutes.ANNOUNCEMENTS]: {
      POST: "New announcement has been created",
      DELETE: "Announcement has been deleted"
    },
    [ServerRoutes.TENANTS]: {
      POST: "New prospect has been added",
      PUT: "Tenant information has been saved",
      DELETE: "Tenant has been deleted"
    },
    [ServerRoutes.OWNERS]: {
      POST: "New owner has been added",
      PUT: "Owner information has been saved",
      DELETE: "Owner has been deleted"
    },
    [ServerRoutes.VENDORS]: {
      POST: "New vendor has been added",
      PUT: "Vendor information has been saved",
      DELETE: "Vendor has been deleted"
    },
    [ServerRoutes.USERS]: {
      POST: "New user has been added",
      PUT: "User information has been saved",
      DELETE: "User has been deleted"
    },
    [ServerRoutes.PROPERTIES]: {
      POST: "New property has been added",
      PUT: "Property information has been saved",
      DELETE: "Property has been Deleted"
    },
    [ServerRoutes.PROPERTIES_POST_PROPERTY_WITH_UNITS]: { POST: "New property has been added" },
    [ServerRoutes.UNITS]: {
      POST: "New unit has been added",
      PUT: "Unit information has been saved",
      DELETE: "Unit has been deleted"
    },
    [ServerRoutes.LEASE_DRAFTS]: {
      POST: "New lease has been created",
      PUT: "Lease term has been saved",
      DELETE: "Lease has been deleted"
    },
    [ServerRoutes.LEASES]: {
      POST: "New lease has been created",
      PUT: "Lease term has been saved",
      DELETE: "Lease has been deleted"
    },
    [ServerRoutes.RENTAL_APPLICATIONS]: { POST: "New rental application has been created" },
    [ServerRoutes.LEASE_CHARGES]: { POST: "New charge has been posted" },
    [ServerRoutes.LEASE_PAYMENTS]: { POST: "New payment has been received" },
    [ServerRoutes.LEASE_REFUNDS]: { POST: "A refund has been given" },
    [ServerRoutes.VENDOR_BILLS]: { POST: "Bill has been created" },
    [ServerRoutes.OWNER_CONTRIBUTIONS]: { POST: "Owner contribution has been recorded" },
    [ServerRoutes.TRANSFERS]: { POST: "Bank transfer has been issued" },
    [ServerRoutes.DEPOSITS]: { POST: "Bank deposit has been recorded" },
    [ServerRoutes.EXPENSES]: {
      POST: "New expense has been recorded",
      PUT: "Expense has been saved",
      DELETE: "Expense has been deleted"
    },
    [ServerRoutes.RECONCILIATIONS]: {
      POST: "Reconciliation has been completed",
      PUT: "Reconciliation has been completed",
      DELETE: "Reconciliation has been canceled"
    },
    [ServerRoutes.FILES]: {
      POST: "File has been added",
      PUT: "File has been saved",
      DELETE: "File has been deleted"
    },
    [ServerRoutes.CONVERSATIONS_PUT_FIX_UNKNOWN_RECIPIENT]: {
      PUT: "Contact has been linked to conversation"
    }
  }
};

export default toasts;
