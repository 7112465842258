import React from "react";

import type { OwnerPortalSettingsDto } from "@doorloop/dto";

import type { FormikProps } from "formik";

import AppStrings from "locale/keys";
import Text from "DLUI/text";
import { ReportSection } from "./reportSection";
import { reportSections } from "./reportItems";
import { View } from "DLUI/view";

interface ComponentProps {
  formikRef: FormikProps<OwnerPortalSettingsDto>;
}

export const Reports: React.FC<ComponentProps> = () => (
  <View width={"100%"} flex={1}>
    <View>
      <Text
        color={"black"}
        fontSize={16}
        marginBottom={20}
        value={AppStrings.Common.OwnerReportsInstructionsDescription}
      />

      {reportSections.map(({ titleAppString, items }, index) => (
        <ReportSection items={items} titleAppString={titleAppString} key={index} />
      ))}
    </View>
  </View>
);
