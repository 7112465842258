import { makeStyles } from "@material-ui/styles";

import type { Theme } from "styles/defaultTheme";

import type { ChipColorsEnum, ChipVariantsEnum } from "./enums";

type ClassKey = `${ChipColorsEnum}_${ChipVariantsEnum}`;

const makeStylesContainer = makeStyles<Theme, {}, ClassKey | "root" | "noLabel" | "rectangle" | "noTextPadding">(
  (theme) => {
    return {
      root: {
        paddingLeft: 4,
        paddingRight: 4,
        gap: 4
      },
      noLabel: {
        paddingLeft: 0,
        gap: "0 !important",

        "& .MuiChip-labelSmall": {
          paddingLeft: 0
        }
      },
      noTextPadding: {
        "& .MuiChip-label": {
          padding: "0"
        }
      },
      rectangle: {
        borderRadius: 5
      },
      primary_default: {
        backgroundColor: theme.newPalette.primary.light,

        "&:hover": {
          backgroundColor: theme.newPalette.primary.states.hover
        },
        "&:focus": {
          backgroundColor: theme.newPalette.primary.states.focusVisible
        }
      },
      primary_outlined: {
        border: `1px solid ${theme.newPalette.primary.main}`,

        "&:hover": {
          backgroundColor: theme.newPalette.primary.states.hover
        },
        "&:focus": {
          backgroundColor: theme.newPalette.primary.states.focusVisible
        }
      },
      secondary_default: {
        backgroundColor: theme.newPalette.secondary.light,

        "&:hover": {
          backgroundColor: theme.newPalette.secondary.states.hover
        },
        "&:focus": {
          backgroundColor: theme.newPalette.secondary.states.focusVisible
        }
      },
      secondary_outlined: {
        border: `1px solid ${theme.newPalette.secondary.main}`,

        "&:hover": {
          backgroundColor: theme.newPalette.secondary.states.hover
        },
        "&:focus": {
          backgroundColor: theme.newPalette.secondary.states.focusVisible
        }
      },
      neutral_default: {
        backgroundColor: theme.newPalette.neutral.light,

        "&:hover": {
          backgroundColor: theme.newPalette.action.hover
        },
        "&:focus": {
          backgroundColor: theme.newPalette.action.focus
        }
      },
      neutral_outlined: {
        border: `1px solid ${theme.newPalette.action.active}`,

        "&:hover": {
          backgroundColor: theme.newPalette.action.hover
        },
        "&:focus": {
          backgroundColor: theme.newPalette.action.focus
        }
      },
      error_default: {
        backgroundColor: theme.newPalette.error.light,

        "&:hover": {
          backgroundColor: theme.newPalette.error.states.hover
        },
        "&:focus": {
          backgroundColor: theme.newPalette.error.states.focusVisible
        }
      },
      error_outlined: {
        border: `1px solid ${theme.newPalette.error.main}`,

        "&:hover": {
          backgroundColor: theme.newPalette.error.states.hover
        },
        "&:focus": {
          backgroundColor: theme.newPalette.error.states.focusVisible
        }
      },
      warning_default: {
        backgroundColor: theme.newPalette.warning.light,

        "&:hover": {
          backgroundColor: theme.newPalette.warning.states.hover
        },
        "&:focus": {
          backgroundColor: theme.newPalette.warning.states.focusVisible
        }
      },
      warning_outlined: {
        border: `1px solid ${theme.newPalette.warning.main}`,

        "&:hover": {
          backgroundColor: theme.newPalette.warning.states.hover
        },
        "&:focus": {
          backgroundColor: theme.newPalette.warning.states.focusVisible
        }
      },
      info_default: {
        backgroundColor: theme.newPalette.info.light,

        "&:hover": {
          backgroundColor: theme.newPalette.info.states.hover
        },
        "&:focus": {
          backgroundColor: theme.newPalette.info.states.focusVisible
        }
      },
      info_outlined: {
        border: `1px solid ${theme.newPalette.info.main}`,

        "&:hover": {
          backgroundColor: theme.newPalette.info.states.hover
        },
        "&:focus": {
          backgroundColor: theme.newPalette.info.states.focusVisible
        }
      },
      success_default: {
        backgroundColor: theme.newPalette.success.light,

        "&:hover": {
          backgroundColor: theme.newPalette.success.states.hover
        },
        "&:focus": {
          backgroundColor: theme.newPalette.success.states.focusVisible
        }
      },
      success_outlined: {
        border: `1px solid ${theme.newPalette.success.main}`,

        "&:hover": {
          backgroundColor: theme.newPalette.success.states.hover
        },
        "&:focus": {
          backgroundColor: theme.newPalette.success.states.focusVisible
        }
      },
      success_default_no_hover: {
        backgroundColor: theme.newPalette.success.light
      },
      serious_error_default: {
        backgroundColor: theme.newPalette.error.main,

        "&:hover": {
          backgroundColor: theme.newPalette.error.states.outlinedBorder
        },
        "&:focus": {
          backgroundColor: theme.newPalette.error.states.focusVisible
        }
      },
      serious_error_outlined: {
        border: `1px solid ${theme.newPalette.error.main}`,

        "&:hover": {
          backgroundColor: theme.newPalette.error.states.hover
        },
        "&:focus": {
          backgroundColor: theme.newPalette.error.states.focusVisible
        }
      },
      warning_default_no_hover: {
        backgroundColor: theme.newPalette.warning.light
      }
    };
  }
);

const makeStylesText = makeStyles<Theme, {}, ClassKey | "root">((theme) => {
  return {
    root: {
      fontSize: 12,
      fontWeight: 400
    },
    primary_default: {
      color: theme.newPalette.primary.main
    },
    primary_outlined: {
      color: theme.newPalette.primary.main
    },
    secondary_default: {
      color: theme.newPalette.secondary.main
    },
    secondary_outlined: {
      color: theme.newPalette.secondary.main
    },
    neutral_default: {
      color: theme.newPalette.text.secondary
    },
    neutral_outlined: {
      color: theme.newPalette.action.active
    },
    error_default: {
      color: theme.newPalette.error.main
    },
    error_outlined: {
      color: theme.newPalette.error.main
    },
    serious_error_default: {
      color: theme.newPalette.text.white
    },
    serious_error_outlined: {
      color: theme.newPalette.error.main
    },
    warning_default: {
      color: theme.newPalette.warning.dark
    },
    warning_outlined: {
      color: theme.newPalette.warning.main
    },
    info_default: {
      color: theme.newPalette.info.main
    },
    info_outlined: {
      color: theme.newPalette.info.main
    },
    success_default: {
      color: theme.newPalette.success.main
    },
    success_outlined: {
      color: theme.newPalette.success.main
    }
  };
});

const makeStylesDivider = makeStyles<Theme, {}, ClassKey | "root">((theme) => {
  return {
    root: {
      width: 1,
      height: 20,
      opacity: 0.5,
      marginLeft: 10,
      marginRight: -8
    },
    mobile: {
      height: 50
    },
    primary_default: {
      backgroundColor: theme.newPalette.primary.main
    },
    primary_outlined: {
      backgroundColor: theme.newPalette.primary.main
    },
    secondary_default: {
      backgroundColor: theme.newPalette.secondary.main
    },
    secondary_outlined: {
      backgroundColor: theme.newPalette.secondary.main
    },
    neutral_default: {
      backgroundColor: theme.newPalette.text.secondary
    },
    neutral_outlined: {
      backgroundColor: theme.newPalette.action.active
    },
    error_default: {
      backgroundColor: theme.newPalette.error.main
    },
    error_outlined: {
      backgroundColor: theme.newPalette.error.main
    },
    serious_error_default: {
      backgroundColor: theme.newPalette.error.main
    },
    serious_error_outlined: {
      backgroundColor: theme.newPalette.error.main
    },
    warning_default: {
      backgroundColor: theme.newPalette.warning.dark
    },
    warning_outlined: {
      backgroundColor: theme.newPalette.warning.main
    },
    info_default: {
      backgroundColor: theme.newPalette.info.main
    },
    info_outlined: {
      backgroundColor: theme.newPalette.info.main
    },
    success_default: {
      backgroundColor: theme.newPalette.success.main
    },
    success_outlined: {
      backgroundColor: theme.newPalette.success.main
    }
  };
});

const makeStylesIcon = makeStyles<Theme, {}, ClassKey>((theme) => {
  return {
    primary_default: {
      color: theme.newPalette.primary.main,

      "& path": {
        fill: `${theme.newPalette.primary.main} !important`
      }
    },
    primary_outlined: {
      color: theme.newPalette.primary.main,

      "& path": {
        fill: `${theme.newPalette.primary.main} !important`
      }
    },
    secondary_default: {
      color: theme.newPalette.secondary.main,

      "& path": {
        fill: `${theme.newPalette.secondary.main} !important`
      }
    },
    secondary_outlined: {
      color: theme.newPalette.secondary.main,

      "& path": {
        fill: `${theme.newPalette.secondary.main} !important`
      }
    },
    neutral_default: {
      color: theme.newPalette.text.secondary,

      "& path": {
        fill: `${theme.newPalette.text.secondary} !important`
      }
    },
    neutral_outlined: {
      color: theme.newPalette.action.active,

      "& path": {
        fill: `${theme.newPalette.action.active} !important`
      }
    },
    error_default: {
      color: theme.newPalette.error.main,

      "& path": {
        fill: `${theme.newPalette.error.main} !important`
      }
    },
    error_outlined: {
      color: theme.newPalette.error.main,

      "& path": {
        fill: `${theme.newPalette.error.main} !important`
      }
    },
    serious_error_default: {
      color: theme.newPalette.error.main,

      "& path": {
        fill: `${theme.newPalette.text.white} !important`
      }
    },
    serious_error_outlined: {
      color: theme.newPalette.error.main,

      "& path": {
        fill: `${theme.newPalette.error.main} !important`
      }
    },
    warning_default: {
      color: theme.newPalette.warning.dark,

      "& path": {
        fill: `${theme.newPalette.warning.dark} !important`
      }
    },
    warning_outlined: {
      color: theme.newPalette.warning.main,

      "& path": {
        fill: `${theme.newPalette.warning.main} !important`
      }
    },
    info_default: {
      color: theme.newPalette.info.main,

      "& path": {
        fill: `${theme.newPalette.info.main} !important`
      }
    },
    info_outlined: {
      color: theme.newPalette.info.main,

      "& path": {
        fill: `${theme.newPalette.info.main} !important`
      }
    },
    success_default: {
      color: theme.newPalette.success.main,

      "& path": {
        fill: `${theme.newPalette.success.main} !important`
      }
    },
    success_outlined: {
      color: theme.newPalette.success.main,

      "& path": {
        fill: `${theme.newPalette.success.main} !important`
      }
    }
  };
});

export { makeStylesContainer, makeStylesText, makeStylesIcon, makeStylesDivider };
