import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { View } from "DLUI/view";
import { useLocation } from "react-router-dom";
import { useNavigationMenuLayers } from "./drawerItems";
import useStyles from "./styles";
import "./styles.css";
import useIntercomBubble from "../../../hooks/useIntercomBubble";
import NavigationList from "DLUI/drawer/navigationList";
import DrawerMainHeader from "DLUI/drawer/DrawerHeader";
import { NavLevels } from "DLUI/drawer/types";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import { getMainMenuLayersFiltered } from "DLUI/drawer/getMainMenuLayersFiltered";
import type { ViewComponentProps } from "DLUI/view/view";
import { useSelectedMainItemKey } from "./hooks/useSelectedMainItemKey";
import { useSelectedSubItem } from "./hooks/useSelectedSubItem";

interface ComponentProps {
  onGlobalNewPress: () => void;
  onGlobalSearchPress: () => void;
  showDrawer?: boolean;
  onDrawerClosePress?: () => void;
  viewProps?: ViewComponentProps;
}

const DLMobileDrawer: React.FC<ComponentProps> = ({
  onGlobalNewPress,
  onGlobalSearchPress,
  showDrawer,
  onDrawerClosePress,
  viewProps
}) => {
  const classes = useStyles();
  const { isMobile, isTabletOrMobile } = useResponsiveHelper();
  const location = useLocation();
  const [selectedMenuItem, setSelectedMenuItem] = useState<string | undefined>();
  const { showIntercomBubble, hideIntercomBubble } = useIntercomBubble();
  const [runSlideLeftAnimation, setRunSlideLeftAnimation] = useState(false);
  const [forceNoSubNavigation, setForceNoSubNavigation] = useState<boolean>(false);
  const navigationMenuLayers = useNavigationMenuLayers();
  const selectedMainMenuItem = useSelectedMainItemKey({
    menuLayers: navigationMenuLayers,
    pathToMatch: location.pathname
  });
  const { navigationItem: selectedSubMenuItem, group: selectedGroup } = useSelectedSubItem({
    menuLayers: navigationMenuLayers,
    pathToMatch: location.pathname
  });
  const [innerSubNavigationName, setInnerSubNavigationName] = useState<string>();
  const subNavigationName = useMemo(() => {
    if (forceNoSubNavigation) {
      return;
    }

    if (innerSubNavigationName || !selectedGroup) {
      return innerSubNavigationName;
    }

    return navigationMenuLayers.MainMenuLayers.groups
      .flatMap((group) => group.items)
      .find((item) => item.navKey === selectedGroup.parentNavigationKey)?.subNavigationName;
  }, [innerSubNavigationName, navigationMenuLayers, selectedGroup, forceNoSubNavigation]);

  useEffect(() => {
    setInnerSubNavigationName(undefined);
    setForceNoSubNavigation(false);
    setSelectedMenuItem(undefined);
  }, [location.pathname]);

  const _onDrawerClosePress = () => {
    onDrawerClosePress?.();
  };

  const _onGlobalNewPress = () => {
    onGlobalNewPress?.();
    onDrawerClosePress?.();
  };

  const handleNavClick = ({
    subMenuSelected,
    menuItemSelection,
    level
  }: {
    subMenuSelected?: string;
    menuItemSelection?: string;
    level?: NavLevels;
  }) => {
    if (!subMenuSelected && menuItemSelection) {
      setSelectedMenuItem(menuItemSelection);

      _onDrawerClosePress();
    }

    if (level === NavLevels.MAIN_MENU) {
      setSelectedMenuItem(menuItemSelection);
      setInnerSubNavigationName(subMenuSelected);
      setForceNoSubNavigation(!subMenuSelected);
    }
  };

  useEffect(() => {
    if (showDrawer) {
      setRunSlideLeftAnimation(true);
      isMobile && showIntercomBubble();
    }
    return () => {
      isMobile && hideIntercomBubble();
    };
  }, [showDrawer, isMobile]);

  const { containerClasses, mainMenuClasses } = useMemo(() => {
    const containerClasses: string[] = [];
    const mainMenuClasses: string[] = [];
    if (isTabletOrMobile) {
      containerClasses.push(classes.drawerMobilePosition);
      mainMenuClasses.push(classes.mainDrawerMobile);
      if (showDrawer) {
        containerClasses.push("drawer-slide-in-left");
      } else if (runSlideLeftAnimation) {
        containerClasses.push("drawer-slide-out-left");
      }
    } else {
      containerClasses.push("DLUI_drawer");
    }
    return { containerClasses, mainMenuClasses };
  }, [showDrawer, runSlideLeftAnimation]);

  const mainMenuLayers = getMainMenuLayersFiltered(navigationMenuLayers);

  return (
    <View
      width={"100%"}
      height={"100%"}
      noWrap
      className={clsx([classes.mainDrawerMobile, mainMenuClasses, containerClasses])}
      {...viewProps}
    >
      <DrawerMainHeader
        didPressGlobalSearch={onGlobalSearchPress}
        didPressGlobalNew={_onGlobalNewPress}
        didPressCloseDrawer={_onDrawerClosePress}
        handleNavClick={handleNavClick}
      />
      <View noWrap width={"100%"} height={"calc(100% - 135px)"} flexDirection={"row"}>
        <View
          width={"100vw"}
          height={"100%"}
          noWrap
          className={clsx(["inner-menu-slider", subNavigationName ? "slide-left" : ""])}
        >
          <View>
            <NavigationList
              navConfig={mainMenuLayers}
              subMenuSelection={subNavigationName}
              handleNavClick={handleNavClick}
              selectedMenuItem={selectedMainMenuItem || selectedMenuItem}
              openedMenuItem={selectedMenuItem}
            />
          </View>
          <View marginTop={"auto"}>
            <NavigationList
              isMobile
              navConfig={navigationMenuLayers.MainMenuFooterLayers}
              subMenuSelection={subNavigationName}
              handleNavClick={handleNavClick}
              selectedMenuItem={selectedMainMenuItem}
              handleDrawerClose={_onDrawerClosePress}
              groupMarginBottom={0}
            />
          </View>
        </View>
        <View width={"100%"} noWrap className={clsx(["inner-menu-slider", subNavigationName ? "slide-left" : ""])}>
          {subNavigationName && (
            <View>
              <NavigationList
                navConfig={navigationMenuLayers[subNavigationName]}
                handleNavClick={handleNavClick}
                selectedMenuItem={selectedSubMenuItem?.navKey}
                withActiveSideIndicator
                isMobile
              />
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default DLMobileDrawer;
