import React, { useCallback, useState } from "react";
import FieldsActionsPanel from "DLUI/form/fieldsActionsPanel/fieldsActionsPanel";
import type { ApiKeysFieldsItemInterface } from "screens/settings/apiKeys/apiKeysFieldsItem";
import ApiKeysFieldsItem from "screens/settings/apiKeys/apiKeysFieldsItem";
import { Grid } from "@material-ui/core";
import Text from "DLUI/text";
import SeparationLine from "../../../DLUI/separatorView/separationLine";
import type { ApiKeyDto } from "@doorloop/dto";
import { ObjectPermission } from "@doorloop/dto";
import type { ActionsPanelInterface } from "DLUI/form/fieldsActionsPanel/actionPanels/useActionsPanels";
import type { ActionPanelClick } from "DLUI/form/fieldsActionsPanel/actionPanels/actionPanelItem";

import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

export interface EditFieldsInterface extends ApiKeysFieldsItemInterface {
  name: string;
}

const EditFields: React.FC<EditFieldsInterface> = ({ name, onChange, isValid, setIsValid }: EditFieldsInterface) => (
  <ApiKeysFieldsItem
    defaultValue={name}
    label={"Key Name"}
    onChange={onChange}
    isValid={isValid}
    setIsValid={setIsValid}
  />
);

export interface ReadOnlyFieldsInterface {
  name: string;
  secret: string;
  hideSecret: boolean;
}

const ReadOnlyFields: React.FC<ReadOnlyFieldsInterface> = ({ name, secret, hideSecret }: ReadOnlyFieldsInterface) => {
  const { isMobile } = useResponsiveHelper();
  const parsedSecret = secret ? secret.toString() : "";

  return (
    <Grid item container xs={12} spacing={2} alignItems={"center"} style={{ wordBreak: "break-word" }}>
      <Grid item lg={4} md={4} xs={12}>
        <Text bold align={isMobile ? "center" : "left"}>
          {name}
        </Text>
      </Grid>
      {!isMobile && (
        <Grid item lg={1} md={1} xs={12}>
          <SeparationLine height={30} dividerColor={"gray"} />
        </Grid>
      )}
      <Grid item lg={7} md={7} xs={12}>
        <Text align={isMobile ? "center" : "left"} color={"gray"} fontSize={12}>
          {hideSecret && parsedSecret ? `****************${parsedSecret && parsedSecret?.slice(-4)}` : parsedSecret}
        </Text>
      </Grid>
    </Grid>
  );
};

interface Props {
  apiKeyData: ApiKeyDto;
  apiKeysActionsPanel: ActionsPanelInterface;
  onRemove: ActionPanelClick;
  onEdit: ActionPanelClick;
  validation?: (data: ApiKeyDto) => boolean;
}
const ApiKeyLineItem: React.FC<Props> = ({ apiKeyData, apiKeysActionsPanel, onRemove, onEdit, validation }: Props) => {
  const [apiKey, setApiKey] = useState(apiKeyData);

  const handleChange = useCallback(
    (ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setApiKey({ ...apiKey, name: ev.target.value }),
    []
  );

  return (
    <FieldsActionsPanel
      editLineOnChange={handleChange}
      validator={validation}
      actionsPanel={apiKeysActionsPanel}
      editFieldsValues={apiKey}
      data={apiKeyData}
      EditFields={EditFields}
      ReadOnlyFields={ReadOnlyFields}
      removeAction={onRemove}
      confirmAction={onEdit}
      removeClearanceAction={{ permission: ObjectPermission.apiKeys, field: "delete" }}
      editClearanceAction={{ permission: ObjectPermission.apiKeys, field: "edit" }}
    />
  );
};

export default ApiKeyLineItem;
