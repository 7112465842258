import React, { useMemo } from "react";

import { FastField } from "formik";
import { useTranslation } from "react-i18next";

import AppStrings from "locale/keys";
import { TextField } from "DLUI/form";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import type { SettingsLevels } from "screens/settings/tenantPortal/components/getTenantPortalDynamicSettingLevelFieldsPath";
import { getTenantPortalDynamicSettingLevelFieldsPath } from "screens/settings/tenantPortal/components/getTenantPortalDynamicSettingLevelFieldsPath";
import type { TextComponentProps } from "DLUI/text/text";
import type { ViewComponentProps } from "DLUI/view/view";
import { useResponsiveHelper } from "contexts/responsiveContext";

interface ComponentProps {
  settingLevel: SettingsLevels;
  textProps?: Partial<TextComponentProps>;
  viewProps?: Partial<ViewComponentProps>;
  label?: string;
}

const CompanyTenantRequests: React.FC<ComponentProps> = ({
  settingLevel,
  textProps,
  viewProps,
  label = AppStrings.Tasks.WorkOrdersScreen.Instructions
}) => {
  const { isMobile } = useResponsiveHelper();
  const { instructionsFeatureKey } = useMemo(() => {
    return {
      instructionsFeatureKey: getTenantPortalDynamicSettingLevelFieldsPath({
        settingLevel,
        dtoKey: "PortalRequestSettingsDto",
        field: "instructions"
      })
    };
  }, [settingLevel]);

  const { t } = useTranslation();

  return (
    <View height={"100%"} gap={16} noWrap {...viewProps}>
      <Text
        fontSize={14}
        color={"black"}
        value={AppStrings.Common.TenantRequestSettingsInstructionsDescription}
        {...textProps}
      />
      <FastField
        component={TextField}
        label={t(label)}
        name={instructionsFeatureKey}
        multiline
        rows="3"
        multiLineSize={isMobile ? "large" : "medium"}
        maxLength={255}
        maxLengthStyle={{ position: "absolute", right: isMobile ? 12 : 16, bottom: 16 }}
        sliceOnMaxLength
      />
    </View>
  );
};

export default CompanyTenantRequests;
