import type { FC } from "react";
import React, { useMemo } from "react";
import type { CommunicationDto } from "@doorloop/dto";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { SeparationLine } from "DLUI/separatorView";
import { buildParticipantString } from "screens/communications/communicationListItem/fromToSummary";
import { TextTooltip } from "DLUI/popover/textTooltip";

interface Props {
  dto: CommunicationDto;
}

interface ParticipantProps {
  name: string;
  tooltip?: string;
}

const Participant: FC<ParticipantProps> = ({ name, tooltip }: ParticipantProps) =>
  tooltip ? (
    <TextTooltip value={tooltip}>
      <Text fontSize={12} color={"secondary-gray"}>
        {name}
      </Text>
    </TextTooltip>
  ) : (
    <Text fontSize={12} color={"secondary-gray"}>
      {name}
    </Text>
  );

export const SubjectAndParticipants: FC<Props> = ({ dto }: Props) => {
  const recipientsList = useMemo(() => dto.to?.map((item) => buildParticipantString(item)) || [], [dto.to]);
  const from = dto.from && buildParticipantString(dto.from);
  return (
    <View marginBottom={20}>
      <Text fontSize={16} marginBottom={10} bold>
        {dto.subject}
      </Text>
      <View flexDirection={"row"} alignItems={"center"}>
        <Text value={AppStrings.Announcements.fromColon} fontSize={12} color={"gray"} marginRight={5} />
        {from && <Participant name={from.name} tooltip={from.tooltip} />}
      </View>
      <View flexDirection={"row"} alignItems={"center"} marginBottom={20}>
        <Text value={AppStrings.Announcements.toColon} fontSize={12} color={"gray"} marginRight={5} />
        {recipientsList.map(({ name, tooltip }, index) => (
          <Participant name={name} tooltip={tooltip} key={index} />
        ))}
      </View>
      <SeparationLine width={"100%"} height={2} />
    </View>
  );
};
