"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentRollSummaryByPropertyReportItem = void 0;
const rentRollSummaryReportItem_dto_1 = require("../../reports/rentRollSummaryByProperty/rentRollSummaryReportItem.dto");
class RentRollSummaryByPropertyReportItem extends rentRollSummaryReportItem_dto_1.RentRollSummaryReportItem {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.RentRollSummaryByPropertyReportItem = RentRollSummaryByPropertyReportItem;
