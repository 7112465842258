import React from "react";
import type { GridColumn } from "DLUI/lists/types";
import type { GridRowProps } from "@progress/kendo-react-grid";
import { Formik } from "formik";
import { createValidator } from "@doorloop/dto";
import type { ClassConstructor } from "class-transformer";

interface ComponentProps {
  allGridColumns: GridColumn[];
  originalDataHashMap?: Map<string, object>;
  dto?: ClassConstructor<object>;
}

/**
 * The purpose of the Grid Row (for now) is to be a functional wrapper for editable rows.
 * If the row is not editable, this component will serve as a pass through with no additional functionality.
 * @param allGridColumns - All of the columns that are currently being displayed in the grid.
 * @param originalDataHashMap - A map of the original data that is being displayed in the grid.
 * @param dto - The dto that is being used to validate the data.
 * @constructor
 */
export const GridRow = ({ allGridColumns, originalDataHashMap, dto }: ComponentProps) => {
  /**
   * If no columns are editable, we're done here.
   */
  if (!allGridColumns.some((column) => column.editOnClick)) return;

  // sanity check
  if (!dto) {
    throw new Error("Your grid contains columns with `editOnClick` enabled, but you didn't pass a `dto` prop");
  }

  return (row: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => (
    <tr>
      <Formik
        initialValues={originalDataHashMap?.get(props.dataItem?.id) ?? {}}
        onSubmit={() => {}}
        validate={createValidator(dto)}
        validateOnChange
        validateOnBlur
        validateOnMount
      >
        {() => row.props.children}
      </Formik>
    </tr>
  );
};
