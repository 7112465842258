import type { FC } from "react";
import React from "react";
import { BackgroundImageView, View } from "DLUI/view";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { ePayField } from "screens/outgoingPayments/mappings";
import { TextField } from "DLUI/form";
import { FastField } from "formik";
import { useTranslation } from "react-i18next";
import { ImageURLsEnum } from "DLUI/link/linkURLsEnum";
import FieldWrapper from "DLUI/form/textField/fieldWrapper";
import BasicGrid from "DLUI/listItems/basicGrid";

interface Props {}

export const DirectDepositSection: FC<Props> = ({}: Props) => {
  const { t } = useTranslation();
  return (
    <View>
      <Text fontSize={18} bold value={AppStrings.Common.OutgoingPayments.AccountSettings} />
      <View flexDirection={"row"}>
        <BasicGrid sm={6}>
          <FieldWrapper type={"endElement"}>
            <FastField
              component={TextField}
              label={t(AppStrings.Common.BankRoutingNumber)}
              name={ePayField("directRouting")}
            />
          </FieldWrapper>
        </BasicGrid>
        <BasicGrid sm={6}>
          <FieldWrapper type={"startElement"}>
            <FastField
              component={TextField}
              label={t(AppStrings.Common.CardNumber)}
              name={ePayField("directAccount")}
            />
          </FieldWrapper>
        </BasicGrid>
      </View>
      <Text fontSize={16} bold value={AppStrings.Common.OutgoingPayments.HelpHint} marginTop={20} />
      <BackgroundImageView
        marginTop={20}
        width={"100%"}
        height={100}
        publicFileName={`url(${ImageURLsEnum.PAYOUT_INSTRUCTIONS})`}
        backgroundSize={"cover"}
        backgroundPosition={"center"}
      />
    </View>
  );
};
