import { View } from "DLUI/view";
import type { PropsWithChildren } from "react";
import React from "react";
import DialogFrameHeader, {
  DialogFrameHeaderHeight
} from "screens/transactions/components/bankConnect/dialogs/bankConnect/dialogFrameHeader";
import { BankConnectDialogHeight } from "screens/transactions/components/bankConnect/dialogs/bankConnect/bankConnect";

interface ComponentProps {
  onClose: () => void;
}

const FrameScrollableContainer: React.FC<ComponentProps> = ({
  children,
  onClose
}: PropsWithChildren<ComponentProps>) => (
  <View>
    <DialogFrameHeader onCloseButtonPress={onClose} />
    <View
      height={BankConnectDialogHeight - DialogFrameHeaderHeight}
      width={"100%"}
      alignItems={"center"}
      overflow={"scroll"}
      noWrap
    >
      {children}
      <View height={20} />
    </View>
  </View>
);

export default FrameScrollableContainer;
