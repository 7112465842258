import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    componentContainer: {
      minWidth: 300,
      height: 60,
      display: "flex",
      backgroundColor: theme.palette.blue.main,
      borderTopRightRadius: 30,
      borderBottomRightRadius: 30,
      justifyContent: "flex-start",
      alignItems: "center",
      paddingRight: 20
    },
    indicatorView: {
      backgroundColor: theme.palette.pink.main,
      height: 5,
      width: "50px",
      marginTop: 5
    },
    detailsScreenInfoContainer: {
      backgroundColor: theme.palette.screenBackground.light,
      borderRadius: 10,
      display: "flex",
      flexDirection: "row"
    },
    detailsScreenInfoItemWrapper: {
      width: "100%"
    },
    separatorView: {
      height: "25px",
      width: 1,
      backgroundColor: theme.palette.screenBackground.main
    },
    headerDrawing: {
      position: "absolute",
      right: 20,
      bottom: -59,
      zIndex: -1000
    }
  })
);
