import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles(() =>
  createStyles({
    componentContainer: {
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      alignItems: "flex-start",
      width: "100%"
    },
    lineRowBorder: {
      borderBottom: "1px solid #ECEEF5"
    }
  })
);
