import type { FC } from "react";
import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { SeparationLine } from "DLUI/separatorView";
import AppStrings from "locale/keys";
import { MethodComponent } from "screens/announcements/newAnnouncement/deliveryMethod/methodComponent";
import { PortalDescription } from "screens/announcements/newAnnouncement/deliveryMethod/portalDescription";
import { ValidationIndicator } from "DLUI/form";
import { useTranslation } from "react-i18next";
import { QueryParams } from "../../../../../utils/queryParams";
import { useFormikContext } from "formik";
import { unitsApi } from "api/unitsApi";
import { propertiesApi } from "api/propertiesApi";
import { useEffectAsync } from "../../../../../hooks/useEffectAsync";
import type { ClientAnnouncementDto } from "@doorloop/dto";
import { SelectPropertiesType } from "@doorloop/dto";
import { SelectUnitsType } from "screens/bills/payBills/types";
import { NavigationManager } from "../../../../../utils/navigation";

interface DeliveryMethodProps {
  errorMessageAppString?: string;
}

export const DeliveryMethod: FC<DeliveryMethodProps> = ({ errorMessageAppString }: DeliveryMethodProps) => {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<ClientAnnouncementDto>();

  useEffectAsync(async () => {
    const tenantId = QueryParams.get("tenantId");

    if (tenantId && values.recipientPropertiesType === SelectPropertiesType.ALL_PROPERTIES) {
      const [relatedUnitsResult, relatedPropertiesResult] = await Promise.all([
        unitsApi.getAll({ filter_tenant: tenantId }),
        propertiesApi.getAll({ filter_tenant: tenantId })
      ]);

      if (!relatedUnitsResult.status || !relatedPropertiesResult.status) {
        NavigationManager.showAlertDialog(window.location.pathname, {
          dialogTitle: AppStrings.Common.NetworkErrorTitle,
          dialogSubTitle: AppStrings.Common.GeneralError,
          dismissButtonText: AppStrings.Common.Dismiss
        });
        return;
      }

      const relatedUnits = relatedUnitsResult.data?.data?.map((unit) => unit.id) || [];
      const relatedProperties = relatedPropertiesResult.data?.data?.map((property) => property.id) || [];

      setFieldValue("properties", relatedProperties);
      setFieldValue("units", relatedUnits);
      setFieldValue("recipientPropertiesType", SelectPropertiesType.SOME_PROPERTIES);
      setFieldValue("recipientUnitsType", SelectUnitsType.SomeUnits);
    }
  }, []);

  return (
    <View alignItems={"center"} maxWidth={774}>
      <Text fontSize={18} value={AppStrings.Announcements.howWouldYouLikeToDeliver} marginBottom={30} bold />
      <SeparationLine width={"100%"} height={1} marginBottom={10} />
      <MethodComponent
        name={"emailInfo.send"}
        mainText={AppStrings.Announcements.sendByEmail}
        DescriptionView={() => <Text value={AppStrings.Announcements.sendByEmailDesc} />}
      />
      <MethodComponent
        name={"textInfo.send"}
        mainText={AppStrings.Announcements.sendByTextMessage}
        DescriptionView={() => <Text whiteSpace={"pre-line"} value={AppStrings.Announcements.sendByTextMessageDesc} />}
      />
      <MethodComponent
        name={"voiceInfo.send"}
        mainText={AppStrings.Announcements.sendByVoiceMessage}
        DescriptionView={() => <Text whiteSpace={"pre-line"} value={AppStrings.Announcements.sendByVoiceMessageDesc} />}
      />
      <MethodComponent
        name={"portalInfo.send"}
        mainText={AppStrings.Announcements.sendByTenantPortal}
        DescriptionView={PortalDescription}
      />
      <ValidationIndicator
        fullWidth
        shouldShow={Boolean(errorMessageAppString)}
        displayText={t(errorMessageAppString || "")}
        justifyContent="center"
      />
    </View>
  );
};
