import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { SeparationLine } from "DLUI/separatorView";
import React, { memo } from "react";
import makeStyles from "./styles";
import Text from "DLUI/text";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import type { DividerColor } from "DLUI/separatorView/separationLine";

export type GridSpace = boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;

export interface GridLayout {
  xs?: GridSpace;
  sm?: GridSpace;
  md: GridSpace;
  lg: GridSpace;
  xl?: GridSpace;
}

type GridLayoutOrSize = GridLayout | { columnSize: GridSpace };

type ComponentProps = GridLayoutOrSize & {
  children: React.ReactNode;
  container?: boolean;
  showDivider?: boolean;
  title?: string;
  sortColumn?: string;
  onClick?: () => void;
  dividerColor?: "gray" | "white";
  size?: "small" | "normal";
  paddingRight?: number;
  paddingLeft?: number;
  justifyContent?: "flex-start" | "flex-end" | "center";
  height?: number | string;
  alignTitle?: "start" | "end";
  style?: React.CSSProperties;
  mobileMarginBottom?: number;
  isMobileTitleInlineWithContent?: boolean;
};

const DLUI_Grid: React.FC<ComponentProps> = memo(
  ({
    container,
    children,
    showDivider,
    title,
    sortColumn,
    onClick,
    dividerColor,
    paddingRight,
    paddingLeft,
    justifyContent = "flex-start",
    height,
    alignTitle = "start",
    style,
    isMobileTitleInlineWithContent,
    mobileMarginBottom = 10,
    ...rest
  }) => {
    const classes = makeStyles();
    const { isMobile } = useResponsiveHelper();
    const GridDefaultMobilePadding = 20;
    const _paddingRight = isMobile ? GridDefaultMobilePadding : paddingRight || paddingRight === 0 ? paddingRight : 10;
    const _paddingLeft = isMobile ? GridDefaultMobilePadding : paddingLeft || paddingLeft === 0 ? paddingLeft : 10;
    const isColumnSize = "columnSize" in rest;

    const { xs, sm, md, lg, xl } = isColumnSize
      ? { xs: rest.columnSize, sm: rest.columnSize, md: rest.columnSize, lg: rest.columnSize, xl: rest.columnSize }
      : rest;

    const hideColumn = isColumnSize && (rest.columnSize === undefined || rest.columnSize === false);

    if (hideColumn) {
      return <></>;
    }

    return (
      <>
        <Grid
          className={clsx([classes.gridContainer, classes.gridPaddingLeftRight])}
          container={container || false}
          item
          xs={xs || 12}
          sm={isColumnSize ? sm : isMobile ? 12 : sm || md}
          md={md}
          lg={lg}
          xl={xl}
          onClick={onClick}
          style={{
            marginTop: isMobile ? 20 : 0,
            marginBottom: isMobile ? mobileMarginBottom : 0,
            height,
            ...style
          }}
        >
          <div
            data-title={title || "no-title"}
            data-xs={xs || 12}
            data-sm={sm}
            data-md={md}
            data-lg={lg}
            data-xl={xl}
            data-sort-col={sortColumn}
            data-align-title={alignTitle}
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              height: height || "100%",
              flexDirection: "row",
              cursor: onClick ? "pointer" : "inherit",
              flexWrap: "nowrap"
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent,
                flex: 1,
                height: height || "100%",
                flexWrap: "wrap"
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: isMobile && isMobileTitleInlineWithContent ? "center" : undefined,
                  justifyContent: isMobile && isMobileTitleInlineWithContent ? "space-between" : undefined,
                  paddingLeft: _paddingLeft,
                  paddingRight: _paddingRight
                }}
              >
                {isMobile && title && (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      width: isMobileTitleInlineWithContent ? "auto" : "100%",
                      height: isMobileTitleInlineWithContent ? undefined : 30,
                      flexDirection: "row"
                    }}
                  >
                    <Text value={title} bold />
                  </div>
                )}
                {children}
              </div>
            </div>
            {!isMobile && showDivider && <Divider dividerColor={dividerColor} />}
          </div>
        </Grid>
        {isMobile && showDivider && <Divider dividerColor={dividerColor} />}
      </>
    );
  }
);

interface DividerProps {
  dividerColor?: DividerColor;
}

const Divider = memo(({ dividerColor }: DividerProps) => {
  const { isMobile } = useResponsiveHelper();

  return (
    <div
      style={{
        height: "100%",
        alignItems: "center",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        width: isMobile ? "100%" : 1
      }}
    >
      <SeparationLine dividerColor={dividerColor} width={"100%"} height={isMobile ? 1 : "50%"} />
    </div>
  );
});

export default DLUI_Grid;
