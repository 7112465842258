import React, { useCallback, useEffect, useState } from "react";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { vendorsApi } from "api/vendorsApi";
import { history } from "store/history";
import { apiMethodNameToAPIMap } from "DLUI/dialogs/confirm/apiMethodNameMap";
import { ConfirmDialogUi } from "DLUI/dialogs/confirm/confirmDialogUi";
import AppStrings from "@/locale/keys";
import type { FileDto } from "@doorloop/dto";
import { useEffectAsync } from "@/hooks/useEffectAsync";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import { loginTypeToFilesApiMap } from "DLUI/dialogs/confirm/loginTypeToFilesApiMap";
import { useConfirmationDialog } from "@/hooks/useConfirmationDialog";
import { DialogRoutes } from "DLUI/screen/dialogsProvider";

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
}

export type ConfirmOperation =
  | "delete"
  | "makeInactive"
  | "makeActive"
  | "cancelSignatureRequest"
  | "cancelRecurringPayment";

export const ConfirmDialog: React.FC<ComponentProps> = ({ onBackdropClick, onClose }: ComponentProps) => {
  const { t } = useTranslation();
  const { confirmationData, clearConfirmationDialogData } = useConfirmationDialog();
  const {
    apiMethod: apiMethodName,
    checkboxText,
    confirmButtonText,
    confirmOperation,
    dialogHeight,
    dialogSubTitle: initialDialogSubtitle = "",
    dialogSubTitleDisclaimer,
    dialogTitle,
    dialogVariant,
    dismissButtonText,
    dismissRouterPath,
    ignoreFiles,
    itemDto,
    itemId,
    loadingText,
    nextRouterPath,
    openInNewTab,
    showXButton,
    successText,
    toasts
  } = confirmationData || {};

  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const [attachments, setAttachments] = useState<FileDto[]>([]);
  const [dialogSubTitle, setDialogSubTitle] = useState<string>(initialDialogSubtitle);
  const currentLoginResponse = useSelector((state: IRootState) => state.auth.currentLoginResponse);
  const isGetRelatedFiles = confirmOperation === "delete" && currentLoginResponse?.type && !ignoreFiles;
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    isGetRelatedFiles ? DialogState.Show : DialogState.Hidden
  );

  useEffect(() => {
    if (!confirmationData && location.pathname.endsWith(DialogRoutes.CONFIRM)) {
      console.warn(`No dialog data, ${location.pathname} to ${location.pathname.replace(DialogRoutes.CONFIRM, "")}`);
      history.push(location.pathname.replace(DialogRoutes.CONFIRM, ""));
    }
  }, [confirmationData]);

  useEffectAsync(async () => {
    if (isGetRelatedFiles) {
      const filesApi = loginTypeToFilesApiMap[currentLoginResponse?.type];
      setLoadingDialogState(DialogState.Show);
      const filesResponse = await filesApi.getAll({ filter_resourceId: itemId });
      setLoadingDialogState(DialogState.Hidden);
      if (filesResponse.status && filesResponse.data?.data) {
        if (filesResponse.data.data.length > 0) {
          setDialogSubTitle(`${t(dialogSubTitle)} ${t(AppStrings.Common.DeleteRelatedFiles)}`);
          setAttachments(filesResponse.data.data);
        }
      } else {
        setLoadingDialogErrorText(filesResponse.message || AppStrings.Common.GeneralError);
        setLoadingDialogState(DialogState.Error);
      }
    }
  }, [currentLoginResponse]);

  const _onBackdropClick = () => {
    onBackdropClick?.();
  };

  const didPressDismissButton = () => {
    if (dismissRouterPath) {
      history.replace(dismissRouterPath);
    } else {
      _onBackdropClick();
    }

    // setTimeout for delaying this one to avoid bouncing text in ui.
    setTimeout(() => {
      clearConfirmationDialogData();
    }, 100);
  };

  const deleteRelatedFiles = useCallback(async () => {
    if (currentLoginResponse?.type) {
      const filesApi = loginTypeToFilesApiMap[currentLoginResponse?.type];

      const deletePromises = attachments.map(async (file) => (file.id ? await filesApi.delete(file.id) : undefined));

      const deleteResponses = await Promise.all(deletePromises);
      const failedResponses = deleteResponses.filter((response) => response && !response.status);

      if (failedResponses.length > 0) {
        setLoadingDialogErrorText(failedResponses[0]?.message || AppStrings.Common.GeneralError);
        setLoadingDialogState(DialogState.Error);
      }
    }
  }, [currentLoginResponse]);

  const didPressConfirmButton = async () => {
    let apiMethod;

    if (openInNewTab) {
      window.open(nextRouterPath, "blank");
    }

    if (apiMethodName && itemId) {
      apiMethod = apiMethodNameToAPIMap[apiMethodName] || vendorsApi;

      setLoadingDialogState(DialogState.Show);
      if (confirmOperation === "delete") {
        const response = await apiMethod.delete(itemId, toasts).catch((error: string) => {
          setLoadingDialogErrorText(error);
          setLoadingDialogState(DialogState.Error);
        });
        if (response && response.status) {
          if (attachments.length > 0) {
            await deleteRelatedFiles();
          }

          setLoadingDialogState(DialogState.Success);
          setTimeout(() => {
            setLoadingDialogState(DialogState.Hidden);
            if (nextRouterPath) {
              history.replace(nextRouterPath);
            } else {
              onClose();
            }
          }, 0);
        } else {
          setLoadingDialogState(DialogState.Error);
          setLoadingDialogErrorText(response?.message || "");
        }
      }

      if ((confirmOperation === "makeInactive" || confirmOperation === "makeActive") && itemDto) {
        const itemDtoPreviousActive = itemDto.active;

        itemDto.active = confirmOperation !== "makeInactive";
        const response = await apiMethod.update(itemId, itemDto, toasts);

        if (response && response.status) {
          setLoadingDialogState(DialogState.Success);
          setTimeout(() => {
            setLoadingDialogState(DialogState.Hidden);
            if (nextRouterPath) {
              history.replace(nextRouterPath);
            } else {
              onClose();
            }
          }, 500);
        } else {
          itemDto.active = itemDtoPreviousActive;
          setLoadingDialogState(DialogState.Error);
          setLoadingDialogErrorText(response ? response.message : "");
        }
      }
      if (confirmOperation === "cancelSignatureRequest") {
        const response = await apiMethod.cancelRequest(itemId);
        if (response && response.status) {
          setLoadingDialogState(DialogState.Hidden);
          if (nextRouterPath) {
            history.replace(nextRouterPath);
          } else {
            onClose();
          }
        } else {
          setLoadingDialogState(DialogState.Error);
          setLoadingDialogErrorText(response ? response.message : "");
        }
      }
      if (confirmOperation === "cancelRecurringPayment") {
        setLoadingDialogState(DialogState.Show);
        const response = await apiMethod.cancelRecurringPayment(itemId, toasts);
        if (response?.status) {
          setLoadingDialogState(DialogState.Hidden);
          if (nextRouterPath) {
            history.replace(nextRouterPath);
          } else {
            onClose();
          }
        } else {
          setLoadingDialogState(DialogState.Error);
          setLoadingDialogErrorText(response?.message || AppStrings.Common.GeneralError);
        }
      }
    } else if (nextRouterPath) {
      history.replace(nextRouterPath);
    } else {
      onClose();
    }
  };

  return (
    <ConfirmDialogUi
      dialogVariant={dialogVariant}
      checkboxText={checkboxText}
      dialogTitle={dialogTitle}
      dialogSubTitle={dialogSubTitle}
      dialogSubTitleDisclaimer={dialogSubTitleDisclaimer}
      confirmButtonText={confirmButtonText}
      dismissButtonText={dismissButtonText}
      loadingText={loadingText}
      successText={successText}
      dialogHeight={dialogHeight}
      showXButton={showXButton}
      onBackdropClick={_onBackdropClick}
      didPressDismissButton={didPressDismissButton}
      didPressConfirmButton={didPressConfirmButton}
      loadingDialogErrorText={loadingDialogErrorText}
      loadingDialogState={loadingDialogState}
    />
  );
};
