import type { FunctionComponent, PropsWithChildren } from "react";
import React from "react";
import type { SettingPermission } from "@doorloop/dto";
import { ReadOnlyContext } from "contexts/readOnlyContext";
import { usePermission } from "screens/settings/userRoles/usePermission";

interface Props {
  isReadOnly: boolean;
}

export const ReadOnlyScope: FunctionComponent<Props> = ({ isReadOnly, children }: PropsWithChildren<Props>) => (
  <ReadOnlyContext.Provider value={isReadOnly}>{children}</ReadOnlyContext.Provider>
);

interface SettingsProps {
  permission?: SettingPermission;
}

export const SettingsReadOnlyScope: FunctionComponent<SettingsProps> = ({
  permission,
  children
}: PropsWithChildren<SettingsProps>) => {
  const { hasSettingsEditPermission } = usePermission();

  return <ReadOnlyScope isReadOnly={!hasSettingsEditPermission(permission)}>{children}</ReadOnlyScope>;
};
