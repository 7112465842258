import type { BulkExecutionRequestType } from "@doorloop/dto";

export default {
  updateDefaultAccounts: "Update Default Accounts",
  xSelected: "({{count}} Selected)",
  executionComplete: "Execution Complete",
  oneOperationFailed: "One Operation Failed",
  oneOperationSkipped: "One Operation Skipped",
  xOperationsFailed: "{{count}} Operations Failed",
  xOperationsSkipped: "{{skippedCount}} Operations Skipped",
  xOutOfTotalCompleted: "{{count}}/{{total}} Completed",
  failed: "Failed",
  skipped: "Skipped",
  completed: "Completed",
  uploadingPicture: "Uploading Picture",
  reorderingPicture: "Reordering Picture",
  removingPicture: "Removing Picture",
  updatingLeases: "Updating Leases",
  updatingProperties: "Updating Properties",
  confirmDeleteXTransactionsMessageOne: "Are you sure you want to delete one transaction?",
  deletingXTransactionMessageOne: "Deleting Transaction",
  deletingTransactions: "Deleting Transactions",
  cannotDeleteTransaction:
    "We cannot allow the deletion of that item at this time. Please remove it from your selection and try again.",
  deletionNotSupported: "Deletion of one or more entries in your selection is not yet supported.",
  confirmDeleteXTransactionsMessageMultiple: "Are you sure you want to delete {{x}} transactions?",
  confirmDeleteXEntriesMessageMultiple: "Are you sure you want to delete {{x}} entries?",
  confirmUpdateXPropertiesMessageMultiple: "Are you sure you want to update {{x}} properties?",
  sendPortalInvitation: "Send Portal Invitation",
  sendInvitation: "Send Invitation",
  confirmActivateTenantPortalMessageOne: "Are you sure you want to activate the tenant portal for the tenant?",
  unitOpenForApplicationMessageOne: "Are you sure you want to open this unit for application?",
  unitOpenForApplicationMessageMultiple: "Are you sure you want to open {{x}} units for applications?",
  unitCloseForApplicationMessageOne: "Are you sure you want to close this unit for application?",
  unitCloseForApplicationMessageMultiple: "Are you sure you want to close {{x}} units for applications?",
  confirmActivateTenantPortalMessageMultiple: "Are you sure you want to activate the tenant portal for {{x}} tenants?",
  confirmActivateOwnerPortalMessageOne: "Are you sure you want to activate the owner portal for the owner?",
  confirmActivateOwnerPortalMessageMultiple: "Are you sure you want to activate the owner portal for {{x}} owners?",
  openForApplicationConfirmation: "Open unit for applications",
  closeForApplicationConfirmation: "Close unit for applications",
  activatePortalConfirmation: "Activate Portal Confirmation",
  activateOwnerPortalOngoingMessage: "Activating owner portal",
  confirmDeleteOneOwner: "Are you sure you want to delete one owner?",
  confirmDeleteMultipleOwners: "Are you sure you want to delete {{x}} owners?",
  confirmDeleteOneProspects: "Are you sure you want to delete one prospect?",
  confirmDeleteMultipleProspects: "Are you sure you want to delete {{x}} prospects?",
  editManagementFeesDescription:
    "The selected properties have different management fee settings. Are you sure you want to edit their settings to be the same?",
  confirmDeleteOneVendor: "Are you sure you want to delete one vendor?",
  confirmDeleteMultipleVendors: "Are you sure you want to delete {{x}} vendors?",
  editLateFees: "Edit Late Fees",
  editManagementFees: "Edit Management Fees",
  leasePrintAccountReceivable: "Download account statement(s)",
  leasePrintAccountReceivableConfirmation: "Download account statement Confirmation",
  leasePrintAccountReceivableConfirmationOne: "Are you sure you want to download the account statement for one lease?",
  leasePrintAccountReceivableConfirmationMultiple:
    "Are you sure you want to download the account statements for {{x}} leases?",
  leasePrintAccountReceivableBatch: "Download combined account statement(s)",
  confirmDeleteDisclaimer: "This action will permanently remove the selected data.",
  sendWelcomeSms: "Send Welcome SMS",
  sendWelcomeSmsHeader: "Send First Time Message",
  sendTenantWelcomeSmsConfirmMessageOne: "Are you sure you want to send a welcome message to one tenant?",
  sendTenantWelcomeSmsConfirmMessageMultiple: "Are you sure you want to send a welcome message to {{x}} tenants?",
  sendWelcomeSmsOngoingMessage: "Sending welcome message",
  sendProspectWelcomeSmsConfirmMessageOne: "Are you sure you want to send a welcome message to one prospect?",
  sendProspectWelcomeSmsConfirmMessageMultiple: "Are you sure you want to send a welcome message to {{x}} prospects?",
  sendOwnerWelcomeSmsConfirmMessageOne: "Are you sure you want to send a welcome message to one owner?",
  sendOwnerWelcomeSmsConfirmMessageMultiple: "Are you sure you want to send a welcome message to {{x}} owners?",
  sendVendorWelcomeSmsConfirmMessageOne: "Are you sure you want to send a welcome message to one vendor?",
  sendVendorWelcomeSmsConfirmMessageMultiple: "Are you sure you want to send a welcome message to {{x}} vendors?",
  selectedItemsOnPage: "Items on this page selected",
  selectAllItems: "Select all {{totalItems}} items",
  clearSelection: "Clear Selection",
  requestBulkExecutionSuccess: {
    SEND_TENANT_PORTAL_INVITATION: "All invitations will be sent shortly"
  } as Record<BulkExecutionRequestType, string>
};
