import useStyles from "./styles";
import { Icon } from "DLUI/icon";
import Text from "DLUI/text";
import React, { useMemo, useState } from "react";
import { View } from "DLUI/view";
import clsx from "clsx";
import { TextTooltip } from "DLUI/popover/textTooltip";
import { Popover } from "DLUI/popover";
import { AlertWarningIcon, type SVGIconComponent } from "../../../assets";
import OnBoardingDrawerItem from "DLUI/drawer/onBoardingDrawerItem";
import RestrictedDeviceAccess from "DLUI/restrictedAccess/restrictedDeviceAccess";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import AppStrings from "../../../locale/keys";
import { usePermission } from "screens/settings/userRoles/usePermission";
import type { DrawerItemType } from "DLUI/drawer/types";
import { SpecialDrawerItemTypes } from "DLUI/drawer/types";
import { NavigationItemContainer } from "./navigationItemContainer";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import { DRAWER_WIDTH, MINIMIZED_DRAWER_WIDTH } from "DLUI/drawer/drawer";
import { Tag } from "DLUI/tag/tag";

const MENU_ITEM_PADDING = 20;
export const NAVIGATION_ITEM_ICON_SIZE = 20;

export interface CustomNavComponentProps {
  isMinimized?: boolean;
  handleDrawerClose?: () => void;
  isMobile?: boolean;
  handleLinkClick?: VoidFunction;
  isActive?: boolean;
  iconSize?: number;
  AvatarIcon?: SVGIconComponent;
}

export interface ComponentProps
  extends Pick<
    DrawerItemType,
    | "label"
    | "labelComponent"
    | "icon"
    | "navPath"
    | "inactiveTag"
    | "specialType"
    | "navKey"
    | "dataCy"
    | "tag"
    | "subNavigationName"
    | "popOverItems"
    | "customComponent"
    | "excludedDevices"
    | "showTooltipWithCustomComponent"
  > {
  handleNavClick: ({
    subMenuSelected,
    menuItemSelection
  }: {
    subMenuSelected?: string;
    menuItemSelection?: string;
  }) => void;
  isActive: boolean;
  isOpen?: boolean;
  isMinimized: boolean;
  withActiveSideIndicator: boolean;
  handleDrawerClose?: () => void;
  isMobile?: boolean;
}

const NavigationItem: React.FC<ComponentProps> = (props) => {
  const {
    navPath,
    label,
    labelComponent: LabelComponent,
    icon,
    subNavigationName,
    dataCy,
    tag,
    handleNavClick,
    isActive,
    isOpen,
    isMinimized,
    navKey,
    popOverItems,
    specialType,
    customComponent: CustomComponent,
    excludedDevices,
    withActiveSideIndicator,
    handleDrawerClose,
    isMobile,
    inactiveTag,
    showTooltipWithCustomComponent = false
  } = props;
  const classes = useStyles();
  const { isTabletOrMobile } = useResponsiveHelper();
  const [popOverOpen, setPopOverOpen] = useState<boolean>(false);
  const userFeatures = useSelector(
    (state: IRootState) => state.auth.currentLoginResponse?.currentDbTenant.userFeatures
  );
  const permissions = usePermission();

  const handleLinkClick = () => {
    if (navPath) {
      handleNavClick({ menuItemSelection: navKey });
    } else {
      handleNavClick({ subMenuSelected: subNavigationName, menuItemSelection: navKey });
    }
  };

  const handlePopOver = () => {
    setPopOverOpen(true);
  };

  const onUserPopoverStateChange = (nextState: "open" | "close") => {
    setPopOverOpen(nextState === "open");
  };

  const isCurrentItemOnBoarding = specialType == SpecialDrawerItemTypes.ONBOARDING;
  if (isCurrentItemOnBoarding) {
    return <OnBoardingDrawerItem {...props} />;
  }

  const isFeatureInactive = useMemo(() => {
    if (inactiveTag) {
      return !userFeatures?.[inactiveTag.featureName] && permissions.hasPermission(inactiveTag.permission);
    }
    return false;
  }, [inactiveTag, userFeatures]);

  if (CustomComponent?.({ isMobile }) === null) {
    return null;
  }

  return (
    <RestrictedDeviceAccess excludedDevices={excludedDevices} hideDisplayView>
      <View
        className={clsx([classes.menuItem, isActive && !isMinimized ? "active" : "", isMinimized ? "minimized" : ""])}
        width={`calc(100% - ${isTabletOrMobile && !isMinimized ? 40 : 18}px)`}
        minWidth={DRAWER_WIDTH - MINIMIZED_DRAWER_WIDTH - MENU_ITEM_PADDING}
        justifyContent={"center"}
        height={isTabletOrMobile ? 44 : 40}
        marginBottom={2}
        marginLeft={isTabletOrMobile && !isMinimized ? 20 : 10}
        borderRadius={5}
        marginRight={isTabletOrMobile && !isMinimized ? 20 : 0}
        onClick={!popOverItems && !CustomComponent ? handleLinkClick : undefined}
      >
        <TextTooltip
          value={label}
          placement={"right"}
          show={isMinimized && (!CustomComponent || showTooltipWithCustomComponent)}
          useButton={false}
          className={classes.tooltip}
        >
          <NavigationItemContainer dataCy={dataCy} navPath={navPath} isCustomComponent={Boolean(CustomComponent)}>
            <View
              className={clsx([
                classes.menuLink,
                isActive ? "active" : "",
                isMinimized ? "minimized" : "",
                isOpen ? "open" : "",
                "menuLink",
                "border-box"
              ])}
              flexDirection={"row"}
              alignItems={"center"}
              noWrap
              width={!isMinimized ? "100%" : "auto"}
              style={{ padding: CustomComponent ? 0 : 10 }}
              borderRadius={5}
              onClick={popOverItems ? handlePopOver : undefined}
            >
              <View flexDirection={"row"} alignItems={"center"} noWrap>
                {icon && !popOverItems && (
                  <View autoWidth>
                    <Icon Source={icon} pathColor={"white"} size={NAVIGATION_ITEM_ICON_SIZE} />
                  </View>
                )}
                {popOverItems && (
                  <View flexDirection={"row"} alignItems={"center"} autoWidth>
                    {icon && <Icon Source={icon} pathColor={"white"} size={NAVIGATION_ITEM_ICON_SIZE} />}
                  </View>
                )}
                {CustomComponent && (
                  <CustomComponent
                    isMinimized={isMinimized}
                    isMobile={isMobile}
                    handleDrawerClose={handleDrawerClose}
                    handleLinkClick={handleLinkClick}
                    isActive={isActive}
                  />
                )}
                <View
                  width={isMinimized ? "0" : `calc(100% - ${icon ? 25 : 0}px)`}
                  style={{ transition: "width ease-in-out 150ms" }}
                  overflow={"hidden"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  noWrap
                >
                  {!CustomComponent && (
                    <View autoWidth={!LabelComponent} noWrap>
                      {LabelComponent ? (
                        <LabelComponent isActive={isActive} tag={tag} />
                      ) : (
                        <Text
                          color={"white"}
                          bold={isActive}
                          value={label}
                          fontSize={isTabletOrMobile ? 16 : 14}
                          marginLeft={5}
                          as={"span"}
                        />
                      )}
                    </View>
                  )}
                  {tag && <Tag value={tag} marginLeft={2} />}
                  {isFeatureInactive && (
                    <View marginLeft={2} autoWidth>
                      {isMobile ? (
                        <View flexDirection={"row"} gap={2}>
                          <Text fontSize={10} color={"yellow"} bold value={AppStrings.Overview.Inactive} as="span" />
                          <Icon Source={AlertWarningIcon} size={10} />
                        </View>
                      ) : (
                        <TextTooltip value={AppStrings.Overview.Inactive}>
                          <View paddingBottom={7} paddingRight={7}>
                            <Icon Source={AlertWarningIcon} size={12} />
                          </View>
                        </TextTooltip>
                      )}
                    </View>
                  )}
                </View>
              </View>
            </View>
          </NavigationItemContainer>
        </TextTooltip>
        <Popover
          hideDotsIcon
          iconPathColor={"white"}
          backgroundColor={"white"}
          width={245}
          topSectionItems={popOverItems || []}
          anchorPositionTop={-1}
          onStateChange={onUserPopoverStateChange}
          iconSize={16}
          shouldShow={popOverOpen}
          disableHover
        />
      </View>
    </RestrictedDeviceAccess>
  );
};

export default NavigationItem;
