import ColorsEnum from "@/utils/colorsEnum";
import { useTypedTranslation } from "@/locale";
import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import type { OwnerPortalRequestDto } from "@doorloop/dto";
import { DateFormats } from "@doorloop/dto";
import moment from "moment";
import { SimpleCalendarIcon } from "@/assets";
import { Icon } from "DLUI/icon";
import { useRequestStatusesMap } from "@/components/screens/tenantPortal/requests/hooks/useRequestStatusesMap";
import type { RequestViewOnlyRenderMap } from "@/components/screens/tenantPortal/components/dialogs/request/requestViewOnly.types";
import { useResponsiveHelper } from "@/contexts/responsiveContext";

interface ComponentProps {
  requestData: OwnerPortalRequestDto | undefined;
}

export const OwnerRequestInformation: React.FC<ComponentProps> = ({ requestData }) => {
  const { t } = useTypedTranslation();

  const { statusLabelColorMap } = useRequestStatusesMap();
  const { subject, description, createdAt, status } = requestData || {};
  const { isMobile } = useResponsiveHelper();

  const mobileTitleLabelViewItemsMap: RequestViewOnlyRenderMap = {
    [t("common.requestSubjectDescriptionViewOnly")]: { text: subject },
    [t("common.requestStatus")]: {
      text: statusLabelColorMap[status || ""]?.label,
      color: statusLabelColorMap[status || ""]?.color,
      isBold: true
    },
    [t("common.creationDate")]: {
      text: moment(createdAt).format(DateFormats.AMERICAN_DATE),
      icon: <Icon Source={SimpleCalendarIcon} size={18} />
    }
  };

  return (
    <View
      backgroundColorEnum={ColorsEnum.LightGray}
      paddingLeft={16}
      paddingRight={16}
      paddingTop={16}
      paddingBottom={16}
      marginTop={16}
    >
      <View flexDirection="row" alignItems="center" borderRadius={4}>
        {Object.entries(mobileTitleLabelViewItemsMap).map(([title, value], index) => {
          const isLastItem = index === Object.entries(mobileTitleLabelViewItemsMap).length - 1;
          const isFirstItem = index === 0;

          return (
            <View
              key={index}
              style={{
                borderRight: !isLastItem ? `1px solid ${ColorsEnum.LightBlackFadedMore}` : "unset"
              }}
              justifyContent="flex-start"
              height="100%"
              flex={1}
              paddingLeft={isFirstItem ? 0 : 8}
            >
              <Text value={title} color="gray" fontSize={10} whiteSpace="nowrap" bold />
              <View flexDirection="row" alignItems="center" marginTop={8}>
                {value?.icon && value?.text && value?.icon}
                {
                  <Text
                    value={value?.text || "--"}
                    fontSize={12}
                    paddingLeft={value?.icon && value?.text ? 8 : 0}
                    style={{
                      color: value?.color || ColorsEnum.LightBlackFaded,
                      overflow: isMobile ? "hidden" : "visible",
                      textOverflow: "ellipsis"
                    }}
                    bold={Boolean(value?.isBold)}
                    maxWidth={140}
                    whiteSpace="nowrap"
                  />
                }
              </View>
            </View>
          );
        })}
      </View>

      <View>
        <Text value={t("common.requestDescription")} marginTop={20} color="gray" fontSize={10} bold />
        <Text
          value={description || t("common.requestDescriptionTextNoDescription")}
          marginTop={4}
          color="light-black-faded"
          fontSize={12}
        />
      </View>
    </View>
  );
};
