import React from "react";
import { View } from "DLUI/view";
import AppStrings from "../../../../locale/keys";
import { DeleteIcon, CloseCircleIconRed } from "../../../../assets";
import Text from "DLUI/text";
import { IconButton } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import { ESignatureRequestStatus, ObjectPermission } from "@doorloop/dto";
import { usePermission } from "screens/settings/userRoles/usePermission";
import { Grid } from "@material-ui/core";
import DLButton from "DLUI/button/dlButton";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  onBackdropClick: () => void;
  didPressDeleteButton: () => void;
  didPressCancelRequestButton: () => void;
  status: ESignatureRequestStatus;
}

const DialogActionPanel: React.FC<ComponentProps> = ({
  onBackdropClick,
  didPressDeleteButton,
  didPressCancelRequestButton,
  status
}: ComponentProps) => {
  const { isMobile, screenContainerPadding } = useResponsiveHelper();
  const { hasPermission } = usePermission();
  const hasDeletePermission = hasPermission(ObjectPermission.esignatureRequests, "delete");

  const hasEditPermission = hasPermission(ObjectPermission.esignatureRequests, "edit");

  return (
    <View marginBottom={20} alignItems={"center"} flexDirection={"row"}>
      <Grid container item xs={12} sm={7}>
        <View justifyContent={isMobile ? "center" : "flex-start"} alignItems={"center"} flexDirection={"row"}>
          {status !== ESignatureRequestStatus.COMPLETED &&
            status !== ESignatureRequestStatus.CANCELED &&
            hasEditPermission && (
              <>
                <View
                  cursor={"pointer"}
                  alignItems={"center"}
                  flexDirection={"row"}
                  width={"auto"}
                  onClick={didPressCancelRequestButton}
                >
                  <IconButton Icon={CloseCircleIconRed} />
                  <Text value={AppStrings.Esignatures.CancelRequest} color={"error"} fontSize={16} marginRight={20} />
                </View>
                <SeparationLine marginRight={20} width={1} height={35} />
              </>
            )}
          {hasDeletePermission && (
            <View
              cursor={"pointer"}
              alignItems={"center"}
              flexDirection={"row"}
              width={"auto"}
              onClick={didPressDeleteButton}
            >
              <IconButton width={25} height={25} Icon={DeleteIcon} />
              <Text value={AppStrings.Common.Delete} color={"error"} fontSize={16} />
            </View>
          )}
        </View>
      </Grid>
      <Grid container justify={isMobile ? "center" : "flex-end"} item xs={12} sm={5}>
        <DLButton
          onClick={onBackdropClick}
          actionText={AppStrings.Common.Close}
          style={{
            minWidth: isMobile ? window.innerWidth - screenContainerPadding * 4 : 160,
            marginTop: isMobile ? 20 : 0
          }}
        />
        {isMobile && <View height={20} />}
      </Grid>
    </View>
  );
};

export default DialogActionPanel;
