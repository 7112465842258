import React from "react";
import { AgendaIcon, BadgeIcon, LocationIcon, UsersIcon } from "assets/icons";
import type { MenuItem } from "DLUI/dialogs";
import { ContactInfo, PersonAddress, PersonInfo } from "DLUI/person";
import AppStrings from "locale/keys";
import TimeZone from "DLUI/person/timeZone";
import { SettingsPersonalInfo } from "screens/settings/personalInformation/settingsPersonalInfo";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";

export const getPersonalInfoFormMenuItems = (): MenuItem[] => {
  const isAccountOwner = useSelector((state: IRootState) => state?.auth?.currentLoginResponse?.owner);

  return [
    {
      title: AppStrings.Tenants.NewTenant.PersonalInfo,
      icon: BadgeIcon,
      contentComponent: (formikRef, onFileReceive, onFileDelete) =>
        isAccountOwner ? (
          <SettingsPersonalInfo
            formikRef={formikRef}
            onFileReceive={onFileReceive}
            onFileDelete={onFileDelete}
            hideNotesSection
          />
        ) : (
          <PersonInfo
            formikRef={formikRef}
            onFileReceive={onFileReceive}
            onFileDelete={onFileDelete}
            hideNotesSection
          />
        ),
      uploadFile: true
    },
    {
      title: AppStrings.Tenants.NewTenant.ContactInfo,
      icon: AgendaIcon,
      contentComponent: (formikRef) => <ContactInfo formikRef={formikRef} />
    },
    {
      title: AppStrings.Tenants.NewTenant.Address,
      icon: LocationIcon,
      contentComponent: (formikRef) => (
        <PersonAddress
          formikName={"primaryAddress"}
          title={AppStrings.Tenants.NewTenant.PrimaryAddress}
          formikRef={formikRef}
        />
      )
    },
    {
      title: AppStrings.Common.TimeZone,
      icon: UsersIcon,
      contentComponent: (formikRef) => <TimeZone formikRef={formikRef} />
    }
  ];
};
