const Auth = {
  SignIn: "auth.signIn",
  SignUp: "auth.signUp",
  ForgotPassword: "auth.forgotPassword",
  ForgotPasswordEmailSent: "auth.forgotPasswordEmailSent",
  EnterYourEmail: "auth.enterYourEmail",
  GetFreeAccess: "auth.getFreeAccess",
  thanksForSingup: "auth.thanksForSingup",
  EmailConfirmationTitle: "auth.emailConfirmationTitle",
  SingupInstructions: "auth.singupInstructions",
  EmailConfirmation: "auth.emailConfirmation",
  EmailConfirmationButtonText: "auth.emailConfirmationButtonText",
  confirmEmailInProgressText: "auth.confirmEmailInProgressText",
  PleaseWaitCreateAccount: "auth.pleaseWaitCreateAccount",
  WelcomeMessage: "auth.welcomeMessage",
  WelcomeMessageTo: "auth.welcomeMessageTo",
  Thanks: "auth.thanks",
  WelcomeWizardAboutyou: "auth.welcomeWizardAboutyou",
  ReesetPasswordInstructions: "auth.resetPasswordInstructions",
  ResetPasswordButton: "auth.resetPasswordButton",
  SelectAccount: "auth.selectAccount",
  PasswordResetSuccessfully: "auth.passwordResetSuccessfully",
  WizardStep2About: "auth.wizardStep2About",
  WizardStep2NumberOfUnits: "auth.wizardStep2NumberOfUnits",
  WizardStep2HowDidYouHear: "auth.wizardStep2HowDidYouHear",
  WizardStep2HowDidYouHearOther: "auth.wizardStep2HowDidYouHearOther",
  PromoTextTitle: "auth.promoTextTitle",
  PromoTextSubtitle: "auth.promoTextSubtitle",
  PromoDescriptionText: "auth.promoDescriptionText",
  VisitHelpCenter: "auth.visitHelpCenter",
  SendUsAMessage: "auth.sendUsAMessage",
  CredentialsInstructionsText: "auth.credentialsInstructionsText",
  ContinueWithGoogle: "auth.continueWithGoogle",
  Or: "auth.or",
  EmailAddress: "auth.emailAddress",
  Password: "auth.password",
  ConfirmPassword: "auth.confirmPassword",
  RememberMe: "auth.rememberMe",
  ResetPasswordEmailSentTitle: "auth.resetPasswordEmailSentTitle",
  ResetPasswordEmailSentSubTitle: "auth.resetPasswordEmailSentSubTitle",
  Register: "auth.register",
  NewPassword: "auth.newPassword",
  ConfirmNewPassword: "auth.confirmNewPassword",
  MissingTokenKey: "auth.missingTokenKey",
  PasswordsNoMatch: "auth.passwordsNoMatch",
  SignUpWelcomeMessage: "auth.signUpWelcomeMessage",
  SignUpWelcomeMessageSubtitle: "auth.signUpWelcomeMessageSubtitle",
  RecaptchaFailed: "auth.recaptchaFailed",
  CreateAccountTitle: "auth.createAccountTitle",
  CreateAccountSubTitle: "auth.createAccountSubTitle",
  CreateAccountStep2Title: "auth.createAccountStep2Title",
  createAccountStep2SubTitle: "auth.createAccountStep2SubTitle",
  Step2AddressChooseOptionError: "auth.step2AddressChooseOptionError",
  CreateAccountStep3Title: "auth.createAccountStep3Title",
  CreateAccountStep3SubTitle: "auth.createAccountStep3SubTitle",
  CreateAccountStep4Title: "auth.createAccountStep4Title",
  TermsConfirmationText: "auth.termsConfirmationText",
  TermsOfUse: "auth.termsOfUse",
  And: "auth.and",
  PrivacyPolicy: "auth.privacyPolicy",
  CompanyName: "auth.companyName",
  PortfolioType: "auth.portfolioType",
  HowManyUnits: "auth.howManyUnits",
  SkipCreateAccount: "auth.skipCreateAccount",
  CantFindInvitationToken: "auth.cantFindInvitationToken",
  JoinAccount: "auth.joinAccount",
  CompleteDetails: "auth.completeDetails",
  ActiveUserDescription: "auth.activeUserDescription",
  LoginWithExistingAccount: "auth.loginWithExistingAccount",
  SelectLoginAccount: "auth.selectLoginAccount",
  Login: "auth.login",
  AccountInfo: "auth.accountInfo",
  CreateYourAccount: "auth.createYourAccount",
  TwoStepAuthentication: "auth.twoStepAuthentication",
  TwoStepAuthenticationToggleDescription: "auth.twoStepAuthenticationToggleDescription",
  TwoStepAuthenticationEmailDescription: "auth.twoStepAuthenticationEmailDescription",
  TwoStepAuthenticationMobileNumberDescription: "auth.twoStepAuthenticationMobileNumberDescription",
  OneTimePassword: "auth.oneTimePassword",
  InvalidTotp: "auth.invalidTotp",
  LoginToPortal: "auth.loginToPortal",
  LoginToPortalDescription: "auth.loginToPortalDescription",
  InvalidEmailAddress: "auth.invalidEmailAddress",
  LoginInformation: "auth.loginInformation",
  Unlock: "auth.unlock",
  UnlockSettings: "auth.unlockSettings",
  UnlockSettingsDescription: "auth.unlockSettingsDescription",
  CurrentPassword: "auth.currentPassword",
  TwoFactorAuthentication: "auth.twoFactorAuthentication",
  TwoFactorAuthenticationDescription: "auth.twoFactorAuthenticationDescription",
  EmailAuthentication: "auth.emailAuthentication",
  EmailAuthenticationIsActivated: "auth.emailAuthenticationIsActivated",
  SmsAuthenticationIsActivated: "auth.smsAuthenticationIsActivated",
  SmsAuthentication: "auth.smsAuthentication",
  VerifyYourPhoneNumber: "auth.verifyYourPhoneNumber",
  EnterYourPhoneNumber: "auth.enterYourPhoneNumber",
  EnterYourPhoneNumberDescription: "auth.enterYourPhoneNumberDescription",
  SendCode: "auth.sendCode",
  EnterYourConfirmationCode: "auth.enterYourConfirmationCode",
  EnterYourConfirmationCodeDescription: "auth.enterYourConfirmationCodeDescription",
  VerifyCode: "auth.verifyCode",
  ResendCode: "auth.resendCode",
  InvalidVerificationCode: "auth.invalidVerificationCode",
  PhoneVerification: "auth.phoneVerification",
  ChangeNumber: "auth.changeNumber",
  DoorLoopTechnicianAccess: "auth.doorLoopTechnicianAccess",
  DoorLoopTechnicianAccessDays: "auth.doorLoopTechnicianAccessDays",
  DoorLoopTechnicianAccessDescription: "auth.doorLoopTechnicianAccessDescription",
  PasswordIsIncorrect: "auth.passwordIsIncorrect"
};

export default Auth;
