import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { drawerOpenWidth } from "../../drawer/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    barContainer: {
      backgroundColor: theme.palette.screenBackground.light
    },
    searchIconContainer: {
      position: "fixed",
      left: drawerOpenWidth - 30,
      top: 0
    },
    sidebarInnerContainer: {
      overflowY: "scroll",
      overflowX: "hidden"
    },
    topBarContainer: {
      display: "grid",
      gridTemplateColumns: "minmax(20%, 1fr) minmax(500px, 1fr) minmax(20%, 1fr)",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.screenBackground.light
    }
  })
);
