import type { ChangeEvent } from "react";
import React from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import { View } from "DLUI/view";
import "./styles.css";
import type { GridSpace } from "DLUI/listItems/grid";
import type { RadioGroupItem } from "DLUI/form/radioGroup/types";
import { RadioGroupLabels } from "DLUI/form/radioGroup/radioGroupLabels";

export interface RadioGroupComponentProps {
  defaultValue?: string;
  uniqueKey: string;
  radioGroupItems: RadioGroupItem[];
  didChangeSelection: (selectedValue: string, e: ChangeEvent) => void;
  marginTop?: number | string;
  textMaxWidth?: number;
  flexDirection?: "row" | "column";
  alignItems?: "flex-start" | "flex-end";
  xs?: GridSpace;
  md?: GridSpace;
  lg?: GridSpace;
  xl?: GridSpace;
  dataCy?: string;
  noWrap?: boolean;
  fullWidth?: boolean;
}

const UnControlledRadioGroup: React.FC<RadioGroupComponentProps> = ({
  uniqueKey,
  radioGroupItems,
  defaultValue,
  didChangeSelection,
  marginTop,
  textMaxWidth,
  flexDirection,
  alignItems,
  xs,
  md,
  lg,
  xl,
  dataCy,
  fullWidth
}) => {
  const [value, setValue] = React.useState(defaultValue || "");

  const handleChange = (event: any) => {
    const selectedValue = event.target.value;
    setValue(event.target.value);
    if (didChangeSelection) {
      didChangeSelection(selectedValue, event);
    }
  };

  if (!radioGroupItems) {
    return null;
  }

  return (
    <View alignItems={alignItems} marginTop={marginTop} className={"DL_RadioGroup"}>
      <FormControl style={{ width: "100%" }} component="fieldset">
        <RadioGroup aria-label={uniqueKey} name={uniqueKey} value={value} onChange={handleChange} data-cy={dataCy}>
          <RadioGroupLabels
            radioGroupItems={radioGroupItems}
            textMaxWidth={textMaxWidth}
            xs={xs}
            md={md}
            lg={lg}
            xl={xl}
            flexDirection={flexDirection}
            dataCy={dataCy}
            fullWidth={fullWidth}
            groupValue={value}
          />
        </RadioGroup>
      </FormControl>
    </View>
  );
};

export default UnControlledRadioGroup;
