import { FilterEmpty } from "assets/icons";
import { View } from "DLUI/view";
import EmptyDataView from "DLUI/emptyDataView";
import AppStrings from "locale/keys";
import type { FC } from "react";
import React from "react";

export const ListEmptyIndicator: FC = () => (
  <View justifyContent={"center"} alignItems={"center"} flexDirection={"row"}>
    <EmptyDataView
      instructionsText={AppStrings.Common.NoResultsFoundMainText}
      instructionsSubText={AppStrings.Common.NoResultsFoundSubText}
      displayIcon={FilterEmpty}
    />
  </View>
);
