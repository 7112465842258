import { ObjectPermission } from "@doorloop/dto";
import { checkbookApi } from "api/checkbookApi";
import { LoadingAnimation } from "DLUI/animations/loadingAnimation";
import DLButton from "DLUI/button/dlButton";
import EmptyDataView from "DLUI/emptyDataView";
import { Icon } from "DLUI/icon";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import React, { useMemo, useState } from "react";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { EmptyPaymentsIcon } from "../../../assets";
import { useEffectAsync } from "hooks/useEffectAsync";
import AppStrings from "../../../locale/keys";
import { NavigationManager } from "utils/navigation";
import { OutgoingPaymentsEmptyState } from "screens/outgoingPayments/outgoingPaymentsTransactions/outgoingPaymentsEmptyState";
import ColorsEnum from "utils/colorsEnum";

interface Props {}

interface emptyViewConfigProps {
  title: string;
  subTitle: string;
  actionClearance: AnyPermissionClearance;
  actionText: string;
  onClick: () => void;
}

enum paymentStatuses {
  "notEnabled",
  "noPayments"
}

const paymentStatusesViewMap: Record<paymentStatuses, emptyViewConfigProps> = {
  [paymentStatuses.notEnabled]: {
    title: AppStrings.Common.NoActivePayments,
    subTitle: AppStrings.Common.NoActivePaymentsDescription,
    actionText: AppStrings.Common.SetupOutgoingPayments,
    actionClearance: {
      permission: ObjectPermission.outgoingPayments,
      field: "create"
    },
    onClick: () => NavigationManager.editOutgoingPaymentsSettings(location.pathname)
  },
  [paymentStatuses.noPayments]: {
    title: AppStrings.Common.NoPaymentRecords,
    subTitle: AppStrings.Common.PaymentInfoPlacement,
    actionText: AppStrings.Common.RecordNewExpense,
    actionClearance: {
      permission: ObjectPermission.outgoingPayments,
      field: "create"
    },
    onClick: () => NavigationManager.createNewVendorExpense(location.pathname)
  }
};

const EmptyListView: React.FC<Props> = ({}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [checkbookStatus, setCheckbookStatus] = useState<paymentStatuses>(paymentStatuses.notEnabled);

  const getUserCheckbookStatus = async () => {
    try {
      setIsLoading(true);
      const response = await checkbookApi.getDetails();
      return response.data?.enabled ? paymentStatuses.noPayments : paymentStatuses.notEnabled;
    } catch {
      return paymentStatuses.notEnabled;
    } finally {
      setIsLoading(false);
    }
  };

  useEffectAsync(async () => {
    const checkbookStatus = await getUserCheckbookStatus();
    setCheckbookStatus(checkbookStatus);
  }, []);

  const { title, subTitle, actionClearance, actionText, onClick }: emptyViewConfigProps = useMemo(
    () => paymentStatusesViewMap[checkbookStatus],
    [checkbookStatus]
  );

  if (isLoading) {
    return (
      <EmptyDataView>
        <LoadingAnimation />
      </EmptyDataView>
    );
  }

  if (!checkbookStatus) {
    return <OutgoingPaymentsEmptyState />;
  }

  return (
    <EmptyDataView>
      <View justifyContent={"center"} alignItems={"center"} width={"100%"}>
        <Icon Source={EmptyPaymentsIcon} />
        <Text color={"black"} value={title} fontSize={18} bold marginBottom={10} marginTop={10} />
        <Text
          maxWidth={400}
          align={"center"}
          value={subTitle}
          colorEnum={ColorsEnum.LightBlack}
          fontWeight={400}
          fontSize={14}
        />
        <DLButton
          style={{ marginTop: 20 }}
          actionText={actionText}
          onClick={onClick}
          disabled={isLoading}
          clearance={actionClearance}
          icons={{ start: { isHidden: checkbookStatus !== paymentStatuses.noPayments } }}
        />
      </View>
    </EmptyDataView>
  );
};

export default EmptyListView;
