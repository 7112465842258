import { ListItemContainerMaxWidth } from "DLUI/infiniteList/utils";
import React, { useState } from "react";
import type { LeaseDto } from "@doorloop/dto";
import { StickyHeader } from "DLUI/stickyHeader";
import { View } from "DLUI/view";
import { LeaseListItem } from "screens/leases/leases/leasesList/leaseListItem";
import { NavigationManager } from "utils/navigation";
import { AnimateMarginTop } from "DLUI/animatableView";
import SearchResultsListItemWrapper from "DLUI/screen/globalSearch/searchResultsListItemWrapper";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import type { ResultsSearchTabComponentProps } from "DLUI/screen/globalSearch/types";
import { globalSearchContstants } from "DLUI/screen/globalSearch/globalSearch.constants";
import { useTimeout } from "../../../../hooks/useTimeout";

const LeasesTab: React.FC<ResultsSearchTabComponentProps<LeaseDto>> = ({
  listItems,
  handleItemClicked
}: ResultsSearchTabComponentProps<LeaseDto>) => {
  const [shouldRender, setShouldRender] = useState(false);
  const { isMobile } = useResponsiveHelper();

  useTimeout(() => {
    setShouldRender(true);
  }, globalSearchContstants.tabsDisplayDelay);

  const items = listItems.map((currentItem: LeaseDto, index) => {
    const didPressLeaseItem = (leaseId: string) => {
      handleItemClicked("Leases");

      setTimeout(() => {
        NavigationManager.viewLeaseDetails(leaseId);
      }, globalSearchContstants.resultsItemHideDelay);
    };

    return (
      <SearchResultsListItemWrapper key={"ALI" + index}>
        <LeaseListItem disableListItemOptions leaseDto={currentItem} onItemPress={didPressLeaseItem} />
      </SearchResultsListItemWrapper>
    );
  });

  return (
    <View
      alignItems={"center"}
      overflow={"scroll"}
      height={`calc(100vh - ${globalSearchContstants.searchPanelHeight}px)`}
    >
      {shouldRender ? (
        <View maxWidth={ListItemContainerMaxWidth} style={{ display: "block" }}>
          {!isMobile && <StickyHeader id={"searchLeasesTab"} shouldShow marginTop={20} />}
          {isMobile && <AnimateMarginTop marginTop={20} />}
          <View id={"searchLeasesTab"}>
            {items}
            <View height={60} />
          </View>
        </View>
      ) : (
        <div />
      )}
    </View>
  );
};

export default LeasesTab;
