"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountCashFlowActivity = void 0;
var AccountCashFlowActivity;
(function (AccountCashFlowActivity) {
    AccountCashFlowActivity["CASH"] = "CASH";
    AccountCashFlowActivity["OPERATING"] = "OPERATING";
    AccountCashFlowActivity["FINANCING"] = "FINANCING";
    AccountCashFlowActivity["INVESTMENT"] = "INVESTMENT";
})(AccountCashFlowActivity || (exports.AccountCashFlowActivity = AccountCashFlowActivity = {}));
