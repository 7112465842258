import type { ReactElement } from "react";
import React, { useEffect, useMemo, useState } from "react";
import { FilterEmpty } from "assets/icons";
import type { FillColors } from "DLUI/icon";
import { Icon } from "DLUI/icon";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import { v4 as uuid } from "uuid";
import makeStyles from "./styles";
import "./styles.css";
import type { SVGIconComponent } from "assets/icons/types";
import type { TextColor, TextFonts } from "DLUI/text/text";
import { DataCy } from "@doorloop/dto";

interface ComponentProps {
  instructionsText?: string;
  InstructionsTextComponent?: ReactElement;
  instructionsSubText?: string;
  displayIcon?: SVGIconComponent;
  actionButton?: JSX.Element;
  BackgroundDrawing?: SVGIconComponent;
  instructionsTextMaxWidth?: number;
  parentContainerName?: string;
  marginTop?: number;
  children?: React.ReactNode;
  iconPathColor?: FillColors | "none";
  iconHeight?: number;
  iconWidth?: number;
  textColor?: TextColor;
  textSize?: number;
  textWeight?: number;
  textFamily?: TextFonts;
  defaultHeight?: number;
}

const EmptyDataView = ({
  instructionsText,
  InstructionsTextComponent,
  instructionsSubText,
  actionButton,
  BackgroundDrawing,
  displayIcon,
  instructionsTextMaxWidth,
  parentContainerName,
  children,
  iconPathColor = "none",
  iconHeight,
  iconWidth,
  textColor,
  textSize,
  textWeight,
  textFamily,
  defaultHeight
}: ComponentProps) => {
  const classes = makeStyles();
  const [containerHeight, setContainerHeight] = useState<number | undefined>(defaultHeight);
  const viewKey = useMemo(() => uuid(), []);

  useEffect(() => {
    defaultHeight ||
      setTimeout(() => {
        setContainerHeight(getContainerHeight());
      }, 300);
  }, [defaultHeight]);

  const getContainerHeight = () => {
    const filterPanel = document.getElementsByClassName(parentContainerName || viewKey);
    let containerHeight: number | undefined;
    if (filterPanel && filterPanel[0]) {
      const filterPanelPosition = filterPanel[0].getBoundingClientRect();
      if (filterPanelPosition.top > 0) {
        containerHeight = window.innerHeight - 80 - filterPanelPosition.top - filterPanelPosition.height;
      }
    }
    return containerHeight;
  };

  const renderContent = () => {
    if (!containerHeight) {
      return null;
    }

    return children ? (
      <View
        justifyContent={"center"}
        alignItems={"center"}
        minHeight={containerHeight}
        className={viewKey}
        flexDirection={"column"}
        noWrap
      >
        {children}
      </View>
    ) : (
      <View
        justifyContent={"center"}
        alignItems={"center"}
        minHeight={containerHeight}
        className={viewKey}
        flexDirection={"column"}
        noWrap
      >
        {BackgroundDrawing ? <Icon Source={BackgroundDrawing} pathColor={"black"} /> : null}
        <View
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          dataCy={DataCy.DLUI.noResultsFoundMessage}
          noWrap
        >
          <Icon
            pathColor={iconPathColor === "none" ? undefined : iconPathColor || "black"}
            width={iconWidth ? iconWidth : 200}
            height={iconHeight ? iconHeight : 170}
            Source={displayIcon || FilterEmpty}
          />
          <View style={!displayIcon ? { translate: "0 -30px" } : {}} alignItems={"center"} marginTop={40} noWrap>
            {InstructionsTextComponent ? (
              InstructionsTextComponent
            ) : (
              <Text
                color={textColor || "black"}
                className={classes.instructionsText}
                value={instructionsText || AppStrings.Common.NoResultsYet}
                fontSize={textSize || 16}
                fontWeight={textWeight || 700}
                fontFamily={textFamily || undefined}
                maxWidth={instructionsTextMaxWidth || 400}
                align={"center"}
              />
            )}
            {instructionsSubText && (
              <Text
                color={"gray"}
                className={classes.instructionsText}
                value={instructionsSubText}
                fontSize={14}
                fontWeight={400}
                maxWidth={instructionsTextMaxWidth || 400}
                align={"center"}
                marginTop={10}
              />
            )}
            <View marginTop={20} justifyContent={"center"} flexDirection={"row"}>
              {actionButton}
            </View>
          </View>
        </View>
      </View>
    );
  };

  return (
    <View className={viewKey} justifyContent={"center"} flexDirection={"row"} noWrap>
      {renderContent()}
    </View>
  );
};

export default EmptyDataView;
