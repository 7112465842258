"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportAgingPeriod = void 0;
class ReportAgingPeriod {
    constructor(daysFrom, daysTo, amount) {
        this.daysFrom = daysFrom;
        this.daysTo = daysTo;
        this.amount = amount;
        this.setPeriodLabel();
    }
    setPeriodLabel() {
        if (this.daysTo) {
            this.label = this.daysFrom + "-" + this.daysTo;
        }
        else {
            this.label = this.daysFrom + "+";
        }
    }
    isInPeriod(pastDueDays) {
        if (pastDueDays >= this.daysFrom) {
            if (this.daysTo) {
                if (pastDueDays <= this.daysTo) {
                    return true;
                }
                return false;
            }
            return true;
        }
        return false;
    }
}
exports.ReportAgingPeriod = ReportAgingPeriod;
