import type {
  GetSearchResultsQuery,
  GetLeaseSearchResultsQuery,
  GetLeaseSearchResultsResponseDto
} from "@doorloop/dto";
import { ServerRoutes, SearchCollections } from "@doorloop/dto";
import { apiHelper } from "./apiHelper";
import { leasesApi } from "./leasesApi";
import { ownersApi } from "./ownersApi";
import { propertiesApi } from "./propertiesApi";
import { tenantsApi } from "./tenantsApi";
import { unitsApi } from "./unitsApi";
import { vendorsApi } from "./vendorsApi";

export class GlobalSearchApi {
  constructor() {}

  getresults = async (resultsQuery: GetSearchResultsQuery) => {
    const defaultSearchParams = {
      filter_collections: [
        SearchCollections.PROPERTIES,
        SearchCollections.UNITS,
        SearchCollections.LEASES,
        SearchCollections.DRAFT_LEASES,
        SearchCollections.LEASE_TENANTS,
        SearchCollections.OWNERS,
        SearchCollections.VENDORS,
        SearchCollections.PROSPECT_TENANTS,
        SearchCollections.TASKS,
        SearchCollections.FILES,
        SearchCollections.NOTES
      ]
    };

    await apiHelper.loadDictionariesRequiredForGet([
      propertiesApi,
      unitsApi,
      leasesApi,
      tenantsApi,
      ownersApi,
      vendorsApi
    ]);

    return await apiHelper.axiosGet(ServerRoutes.SEARCH_GET, {
      ...defaultSearchParams,
      ...resultsQuery
    });
  };

  searchLeaseByLeasePropertyTenant = async (query: GetLeaseSearchResultsQuery) =>
    await apiHelper.axiosGet<GetLeaseSearchResultsResponseDto>(ServerRoutes.SEARCH_LEASES, query);
}

export const globalSearchApi = new GlobalSearchApi();
