import type { FC } from "react";
import React, { useEffect, useState } from "react";
import type {
  SmartyAddressAutoCompleteFormProps,
  CountriesListInterface
} from "DLUI/form/autoComplete/addressAutoComplete/smarty/addressAutoCompleteForm";
import SmartyAddressAutoCompleteForm from "DLUI/form/autoComplete/addressAutoComplete/smarty/addressAutoCompleteForm";
import type { GoogleAddressAutoCompleteFormProps } from "DLUI/form/autoComplete/addressAutoComplete/googleMaps/addressAutoCompleteForm";
import GoogleAddressAutoCompleteForm from "DLUI/form/autoComplete/addressAutoComplete/googleMaps/addressAutoCompleteForm";
import { Feature } from "../../../../../config/featureFlags/featureFlags";
import AutoCompleteDataSource from "DLUI/form/autoComplete/addressAutoComplete/googleMaps/dataSource";
import _, { noop } from "lodash";
import { ElementID } from "DLUI/form/autoComplete/addressAutoComplete/googleMaps/addressAutoComplete";
import type { OptionType } from "DLUI/form/autoComplete/autoComplete";
import { caProvinces, regionNames, usStates } from "@doorloop/dto";

type ComponentProps = SmartyAddressAutoCompleteFormProps | GoogleAddressAutoCompleteFormProps;

const googleMapsEngine = new AutoCompleteDataSource();

export const countriesOptions: OptionType[] = (_.uniqBy(regionNames, "country") as CountriesListInterface[]).map(
  ({ country, iso3 }) => {
    return {
      displayName: country,
      value: iso3 || country
    };
  }
);

export const usStateOptions: OptionType[] = usStates.map(({ abbreviation, name }) => {
  return {
    name,
    displayName: abbreviation,
    value: abbreviation
  };
});

export const caProvinceOptions: OptionType[] = caProvinces.map(({ abbreviation, name }) => {
  return {
    name,
    displayName: abbreviation,
    value: abbreviation
  };
});

const AddressAutoCompleteFormWrapper: FC<ComponentProps> = (props) => {
  const [engineType, setSearchAddressEngineType] = useState<"google" | "smarty">(
    Feature.isActive("SmartyAutocomplete") ? "smarty" : "google"
  );

  useEffect(() => {
    googleMapsEngine.init(noop, ElementID);

    return () => {
      googleMapsEngine.clean();
    };
  }, []);

  return !Feature.isActive("SmartyAutocomplete") || engineType === "google" ? (
    <GoogleAddressAutoCompleteForm {...props} handleChangeSearchAddressEngine={setSearchAddressEngineType} />
  ) : (
    <SmartyAddressAutoCompleteForm {...props} handleChangeSearchAddressEngine={setSearchAddressEngineType} />
  );
};

export default AddressAutoCompleteFormWrapper;
