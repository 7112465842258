import type { FC } from "react";
import React, { useCallback } from "react";
import moment from "moment";

import { DateFormats, JournalEntryType, SegmentEventTypes } from "@doorloop/dto";

import { View } from "DLUI/view";
import Text from "DLUI/text";
import { NavigationManager } from "utils/navigation";

import AppStrings from "../../../../../../../locale/keys";
import { analyticsService } from "../../../../../../../services/analyticsService";

import type { SuccessDialogOwnerDistributionFilterProps } from "../utils";
import { SummaryLink } from "DLUI/dialogs/successDialog/summaryLink";
import type { TextFormatType, TextTransformProp } from "@/components/DLUI/text/text";

interface Props extends SuccessDialogOwnerDistributionFilterProps {
  totalProperties: number;
  totalOwners: number;
  totalPayments: number;
}

const SuccessDialogSummary: FC<Props> = ({
  totalPayments,
  totalProperties,
  totalOwners,
  filterBatch,
  filterOwnerIds,
  filterDatePaymentDistribution
}) => {
  const handleNavigateToTransactionReport = useCallback(() => {
    if (filterBatch) {
      analyticsService.track(
        SegmentEventTypes.OWNER_DISTRIBUTION_SUCCESS_DIALOG_TRANSACTIONS_REPORT_CLICKED,
        undefined,
        { trackEventInIntercom: true }
      );

      NavigationManager.runTransactionsListReport(
        {
          filter_type: JournalEntryType.EXPENSE,
          filter_batch: filterBatch
        },
        true
      );
    }
  }, [filterBatch]);

  const handleNavigateToOwnerStatement = useCallback(() => {
    if (filterOwnerIds?.length && filterDatePaymentDistribution) {
      const momentAsOfDate = moment(filterDatePaymentDistribution);

      analyticsService.track(
        SegmentEventTypes.OWNER_DISTRIBUTION_SUCCESS_DIALOG_OWNER_STATEMENT_REPORT_CLICKED,
        undefined,
        { trackEventInIntercom: true }
      );

      NavigationManager.runOwnerStatementReport(
        {
          filter_propertyOwners: filterOwnerIds,
          filter_date_from: momentAsOfDate.startOf("month").format(DateFormats.ISO_DATE_SERVER_FORMAT),
          filter_date_to: momentAsOfDate.endOf("month").format(DateFormats.ISO_DATE_SERVER_FORMAT)
        },
        true
      );
    }
  }, [filterOwnerIds, filterDatePaymentDistribution]);

  return (
    <View paddingLeft={16} paddingRight={16} paddingTop={16} paddingBottom={16} gap={16} backgroundColor={"white"}>
      <SummaryItem translationKey={AppStrings.Common.Properties} value={totalProperties} />
      <SummaryItem translationKey={AppStrings.Owners.Screen.Owners} value={totalOwners} />
      <SummaryItem translationKey={AppStrings.Owners.OwnerDistributions.NumberOfPayments} value={totalPayments} />

      <View fullWidth height={1} flexDirection={"row"} noWrap backgroundColor={"secondary-gray-light"} />

      {filterBatch && (
        <SummaryLink
          translationKey={AppStrings.Owners.OwnerDistributions.ViewTransactionsReport}
          onClick={handleNavigateToTransactionReport}
        />
      )}
      {Boolean(filterOwnerIds?.length) && filterDatePaymentDistribution && (
        <SummaryLink
          translationKey={AppStrings.Owners.OwnerDistributions.ViewOwnerStatement}
          onClick={handleNavigateToOwnerStatement}
        />
      )}
    </View>
  );
};

interface SummaryRowProps {
  translationKey: string;
  value: number;
  valueTextFormatType?: TextFormatType;
  valueDataCy?: string;
  textTransform?: TextTransformProp;
}

export const SummaryItem: FC<SummaryRowProps> = ({
  translationKey,
  value,
  textTransform,
  valueTextFormatType,
  valueDataCy
}) => (
  <View fullWidth flexDirection={"row"} noWrap justifyContent={"space-between"}>
    <Text textTransform={textTransform} value={translationKey} fontSize={14} />

    <Text value={value.toString()} fontSize={14} formatType={valueTextFormatType} dataCy={valueDataCy} />
  </View>
);

export { SuccessDialogSummary };
