import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export const DROP_ZONE_WEB_HEIGHT = 40;
export const DROP_ZONE_MOBILE_HEIGHT = 60;

export default makeStyles((theme: Theme) =>
  createStyles({
    componentContainer: {
      width: "100%",
      border: "1px dashed #ADB3CE",
      borderRadius: "5px",
      backgroundColor: theme.palette.white.main,
      alignSelf: "center",

      "&.errored": {
        border: "1px dashed #E44258"
      }
    },
    darkBackground: {
      backgroundColor: theme.palette.screenBackground.main
    },
    transparentBackground: {
      backgroundColor: "transparent"
    },
    selectedFilesContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center"
    },
    fileContainer: {
      width: "100px",
      height: "100px",
      backgroundColor: theme.palette.purple.light,
      borderRadius: "10px",
      display: "flex"
    },
    buttonLabelContainer: {
      display: "flex",
      flexDirection: "column",
      textTransform: "none"
    },
    buttonContainer: {
      backgroundColor: theme.palette.white.main,
      margin: "0px 20px 20px 20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 20,
      borderWidth: 0.5,
      borderColor: theme.palette.gray.dark,
      borderStyle: "solid",
      position: "relative"
    },
    itemSelected: {
      borderColor: theme.palette.pink.main
    },
    checkmarkIcon: {
      position: "absolute",
      top: 12,
      right: 12,
      maxWidth: 40,
      maxHeight: 40
    },
    buttonLabel: {
      marginTop: 10,
      width: "110px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    dropzoneRootComponent: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      minHeight: DROP_ZONE_WEB_HEIGHT,
      height: "auto",
      overflow: "auto"
    },
    dropzoneRootComponentWeb: {
      minHeight: DROP_ZONE_WEB_HEIGHT
    },
    dropzoneRootComponentMobile: {
      minHeight: DROP_ZONE_MOBILE_HEIGHT
    },
    fileItemWrapper: {
      justifyContent: "center",
      alignItems: "flex-start",
      display: "flex"
    },
    deleteIconContainer: {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 20,
      height: 20,
      right: 10,
      top: 10
    },
    deleteIcon: {
      "&:hover": {
        color: theme.palette.error.main
      },
      color: theme.palette.purple.light
    },
    retryIcon: {
      "&:hover": {
        color: theme.palette.darkGreen.main
      },
      width: 40,
      height: 40
    },
    loadingIndicatorContainer: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      height: "100%"
    }
  })
);
