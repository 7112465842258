import type { ReportPermission } from "@doorloop/dto";
import type { FC } from "react";
import React from "react";
import { View } from "DLUI/view";
import { ReportPermissionToggle } from "./reportPermissionToggle";
import { useTranslation } from "react-i18next";

interface Props {
  permission: {
    permission: ReportPermission;
    title: string;
  };
}

export const SinglePermissionSection: FC<Props> = ({ permission: { permission, title } }: Props) => {
  const { t } = useTranslation();
  return (
    <View flexDirection={"row"}>
      <View height={60} justifyContent={"center"}>
        <ReportPermissionToggle permission={permission} label={t(title)} fontWeight={700} />
      </View>
    </View>
  );
};
