import type { GridProps } from "@progress/kendo-react-grid";
import type { DataResult } from "@progress/kendo-data-query";
import { SELECTION_IDENTIFIER } from "DLUI/lists/types";
import { ControlledCheckBox } from "DLUI/form";

interface BuildCellParameters {
  isAllRowsSelected: (sortedDataSource: DataResult) => boolean;
  isSomeRowsSelected: (sortedDataSource: DataResult) => boolean;
  dataSource: DataResult;
  onHeaderSelectionChange?: (newValue: boolean) => void;
  overrideCheckbox?: boolean;
}

export const BuildGridHeaderCell = ({
  isAllRowsSelected,
  isSomeRowsSelected,
  dataSource,
  onHeaderSelectionChange,
  overrideCheckbox = true
}: BuildCellParameters): GridProps["headerCellRender"] =>
  function Renderer(tdElement, cellProps) {
    const isAllSelected = isAllRowsSelected(dataSource);
    const isPartiallySelected = !isAllSelected && isSomeRowsSelected(dataSource);
    const newValue = !(isAllSelected || isPartiallySelected);
    if (overrideCheckbox && cellProps.field === SELECTION_IDENTIFIER) {
      return (
        <ControlledCheckBox
          style={{
            marginBottom: -8
          }}
          checked={isAllSelected}
          indeterminate={isPartiallySelected}
          onChange={(checked, value, e) => {
            if (!e) return;

            onHeaderSelectionChange?.(newValue);
          }}
        />
      );
    }

    return tdElement;
  };
