import React from "react";
import type { FileDto } from "@doorloop/dto";
import type { FilesApi } from "api/filesApi";
import { filesApi } from "api/filesApi";
import { FileDownloadIcon } from "assets/icons";
import { Icon } from "DLUI/icon";
import { View } from "DLUI/view";
import Text from "DLUI/text";

interface ComponentProps {
  didPressEditFile?: (itemData: FileDto) => void;
  itemData: FileDto;
  hideRelatedToColumn?: boolean;
  filesApiToUse?: FilesApi;
}

const filenameMaxLength = 63;

const makeShortFilename = (filename = ""): string => {
  const [, name, extension] = filename.match(/^(.*)\.(\w{2,4})$/) || [];
  return name && name.length > filenameMaxLength
    ? `${name.substr(0, filenameMaxLength)}...${name.substr(-5)}.${extension}`
    : filename;
};

const FileMinimalListItem: React.FC<ComponentProps> = ({ itemData, filesApiToUse = filesApi }: ComponentProps) => {
  const { name } = itemData;

  const didPressDownloadFile = async () => {
    if (itemData.downloadUrl && itemData.id) {
      await filesApiToUse.downloadFile(itemData.id);
    }
  };

  return (
    <View
      onClick={didPressDownloadFile}
      flexDirection={"row"}
      noWrap
      alignItems={"center"}
      height={40}
      overflow={"hidden"}
      backgroundColor={"secondary-gray-light"}
      borderRadius={5}
      autoWidth
      paddingRight={12}
      paddingLeft={5}
      maxWidth={300}
      hoverColor={"secondary-gray-hover"}
    >
      <View
        minWidth={30}
        minHeight={30}
        maxWidth={40}
        maxHeight={40}
        autoWidth
        borderRadius={5}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Icon Source={FileDownloadIcon} size={20} pathColor={"black"} />
      </View>
      <Text fontSize={14} overFlow={"ellipsis"} numberOfLines={2}>
        {makeShortFilename(name)}
      </Text>
    </View>
  );
};

export default FileMinimalListItem;
