import { DateFormats } from "@doorloop/dto";
import type { DatePickerProps } from "DLUI/form/datePicker/datePicker";
import type { FilterPanelDatePicker } from "DLUI/screen/filterPanel/filterPanel";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import { useSyncWithSearchParams } from "hooks/useSyncWithSearchParams";

export function useDatePickerFilterInput<TQuery>({
  label,
  filterFieldName,
  disabled,
  value,
  minDate,
  maxDate,
  required,
  dataCy
}: Pick<
  FilterPanelDatePicker<TQuery>,
  "label" | "filterFieldName" | "disabled" | "value" | "minDate" | "maxDate" | "required" | "dataCy"
>): DatePickerProps {
  const { t } = useTranslation();

  const formattedValue = value?.format(DateFormats.ISO_DATE_SERVER_FORMAT);
  const { onValueChange, queryValue } = useSyncWithSearchParams(filterFieldName as string, formattedValue);
  const [uniqueKey] = useState(() => uuid());

  const onDatePickerChange = useCallback<NonNullable<DatePickerProps["onChange"]>>(
    (selectedDate) => {
      onValueChange(selectedDate?.format(DateFormats.ISO_DATE_SERVER_FORMAT));
    },
    [onValueChange]
  );

  return useMemo<DatePickerProps>(() => {
    return {
      label: t(label),
      uniqueKey,
      onChange: onDatePickerChange,
      pickerValue: queryValue ? moment(queryValue) : null,
      disabled,
      minDate,
      maxDate,
      required,
      dataCy
    };
  }, [t, label, uniqueKey, onDatePickerChange, disabled, minDate, maxDate, required, queryValue, dataCy]);
}
