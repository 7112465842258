import { View } from "DLUI/view";
import React from "react";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import type { DtoListFormRenderProps } from "DLUI/form/dto/DtoFieldArray";
import type { PetDto, RentalApplicationFieldControlledSectionSettingsDto } from "@doorloop/dto";
import { RentalApplicationFieldSettings } from "@doorloop/dto";
import ControlledFieldsWrapper from "shared/controlledFieldsWrapper";

type PetFieldsProps = DtoListFormRenderProps<PetDto> & {
  tenantView: boolean;
  stepSettingsDto?: RentalApplicationFieldControlledSectionSettingsDto;
  isControlled?: boolean;
};

function PetFields({ name, stepSettingsDto, isControlled }: PetFieldsProps) {
  const { inputRightPadding } = DialogsHelper();

  return (
    <View flexDirection={"row"} gap="20px 0" marginRight={inputRightPadding * -1}>
      <ControlledFieldsWrapper
        initFieldsSettings={RentalApplicationFieldSettings.pets.fields}
        fieldsSettings={stepSettingsDto?.fieldsSettings}
        groupSettings={RentalApplicationFieldSettings.pets.groups}
        formName={name}
        isControlled={isControlled}
      />
    </View>
  );
}

export default PetFields;
