import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { lazy } from "react";
import { LazyRoute } from "./lazyRoute";

const SignatureRequestsListScreen = lazy(
  () => import("screens/esignatures/signatureRequests/signatureRequestsListScreen")
);
const NewSignatureRequestScreen = lazy(
  () => import("screens/esignatures/newSignatureRequest/newSignatureRequestScreen")
);

const SignatureRequestsRouter = () => (
  <PageRouter>
    <LazyRoute path={Routes.SIGNATURE_REQUESTS_VIEW} render={SignatureRequestsListScreen} exact />
    <LazyRoute path={Routes.NEW_SIGNATURE_REQUESTS} render={NewSignatureRequestScreen} />
    <LazyRoute path={Routes.SIGNATURE_REQUESTS} render={SignatureRequestsListScreen} />
  </PageRouter>
);

export default SignatureRequestsRouter;
