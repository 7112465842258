/* eslint-disable no-implicit-coercion */
import MomentUtils from "@date-io/moment";

import Grid from "@material-ui/core/Grid";
import DeleteConfirmation from "DLUI/dialogs/components/deleteConfirmation";
import ReconciledNotificationView from "DLUI/dialogs/components/reconciledNotificationView";
import DialogAmountView from "DLUI/dialogs/components/totalAmountView";
import AppStrings from "locale/keys";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import DialogFrame, { getDialogFrameDimension } from "../../components/dialogFrame";
import ExpenseTransactionLine from "../expenseTransactionLine";

import { PeopleAutoComplete } from "@/components/DLUI/form/autoComplete/peopleAutoComplete/peopleAutoComplete";
import { usePeopleAutoComplete } from "@/components/DLUI/form/autoComplete/peopleAutoComplete/usePeopleAutoComplete";
import { useSearchParams } from "@/hooks/useSearchParams";
import {
  DateFormats,
  DuplicateDialogButtonLabelValues,
  ExpenseTransactionLineDto,
  LinkedResourceType,
  ObjectPermission,
  PersonTypeEnum,
  SegmentEventTypes,
  VendorCreditDto,
  createValidator,
  DataCy
} from "@doorloop/dto";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DialogHeaderActionButtons } from "DLUI/actionButtons/dialogHeaderActionButtons";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { Button } from "DLUI/button";
import { LoadingDialog } from "DLUI/dialogs";
import { DUPLICATE_MODE_QUERY_PARAMS, cleanVendorCreditDataForDuplicateMode } from "DLUI/dialogs/duplicateModeHelper";
import { useVendorAccountsPopulation } from "DLUI/dialogs/vendor/useVendorAccountsPopulation";
import { FormAttachments } from "DLUI/dropZone";
import { FormikDatePicker, FormikReferenceLabel } from "DLUI/form";
import { ListInputsContainer } from "DLUI/listItems";
import { Notes } from "DLUI/notes";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import { SeparationLine } from "DLUI/separatorView";
import { View } from "DLUI/view";
import { filesApi } from "api/filesApi";
import { vendorCreditsApi } from "api/vendorsApi";
import { AddIcon } from "assets/icons";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { FastField, FieldArray, FormikContext, useFormik } from "formik";
import { useAnalyticsService } from "hooks/useAnalyticsService";
import { useEffectAsync } from "hooks/useEffectAsync";
import { useLastArrayItemRef } from "hooks/useLastArrayItemRef";
import { cloneDeep, isEmpty, noop, size, sumBy } from "lodash";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { analyticsService } from "services/analyticsService";
import { v4 as uuid } from "uuid";
import { DialogState } from "../../loadingDialog";

import type { LeaseChargeDto } from "@doorloop/dto";
import type { FileListItemProps } from "DLUI/dropZone";
import type { FormikProps } from "formik";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import type { FrameType } from "../../components/dialogFrame";

export interface VendorCreditDialogProps {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
  transactionCreatedText: string;
  loadingTransactionDataText: string;
  loadingTransactionDataSuccessText: string;
  updatingTransactionText: string;
  transactionUpdatedText: string;
}

const validateForm = createValidator(VendorCreditDto);

enum DialogView {
  FORM,
  LOADING,
  CONFIRM_DELETE
}

const DIALOG_VIEWS_NUM = size(DialogView);

const dialogHeight = getDialogFrameDimension("height", 700);
const dialogWidth = getDialogFrameDimension("width", 1000);

const VendorCreditDialog: React.FC<VendorCreditDialogProps> = ({
  onBackdropClick,
  onClose,
  dialogTitle,
  transactionCreatedText,
  loadingTransactionDataText,
  loadingTransactionDataSuccessText,
  updatingTransactionText,
  transactionUpdatedText
}) => {
  // TODO: Fix transaction lines refresh when changing vendor
  const { isMobile, screenContainerPadding } = useResponsiveHelper();

  const { t } = useTranslation();

  const routeParams = useParams<{ vendorId?: string; transactionId?: string }>();
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = {
    transactionId: searchParams.get(DUPLICATE_MODE_QUERY_PARAMS.transactionId)
  };
  const transactionId = queryParams.transactionId || routeParams.transactionId;

  const isEditMode = !!transactionId;
  const isDuplicateMode = !!queryParams.transactionId;

  const permission: AnyPermissionClearance = {
    permission: ObjectPermission.vendorCredits,
    field: isEditMode ? "edit" : "create"
  };

  const [viewIndex, setViewIndex] = useState(DialogView.FORM);
  const [currentVendorId, setCurrentVendorId] = useState(routeParams.vendorId);

  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);

  const [showVendorSelectionError, setShowVendorSelectionError] = useState(false);
  const [vendorSelectionErrorText, setVendorSelectionErrorText] = useState("");

  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    isEditMode ? DialogState.Show : DialogState.Hidden
  );
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState("");
  const [loadingDialogSuccessText, setLoadingDialogSuccessText] = useState(transactionCreatedText);
  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState(loadingTransactionDataText);

  const [chargeData, setChargeData] = useState<LeaseChargeDto>();
  const { dispatchAnalytics } = useAnalyticsService();
  const [shouldRenderForm, setShouldRenderForm] = useState(!isEditMode);
  const [currentMenuItemTitle] = useState("");

  const initFormValues = () =>
    new VendorCreditDto({
      date: moment().format(DateFormats.ISO_DATE_SERVER_FORMAT).toString(),
      lines: [new ExpenseTransactionLineDto({ uniqueKey: uuid() })]
    });

  const formik = useFormik({
    initialValues: initFormValues(),
    onSubmit: noop,
    validate: validateForm
  });

  useEffectAsync(async () => {
    await formik.setFieldValue("vendor", currentVendorId);
    const touchedValue = Boolean(formik.touched.vendor);

    setShowVendorSelectionError(touchedValue && !currentVendorId);
    setVendorSelectionErrorText(
      touchedValue && !currentVendorId ? t(AppStrings.Vendors.Screen.PressToSelectVendor) : ""
    );
  }, [currentVendorId]);

  const { setFieldValue, setValues } = formik;

  const fetchVendorCredit = useCallback(async () => {
    if (isEditMode) {
      setLoadingDialogState(DialogState.Show);

      const response = await vendorCreditsApi.get(transactionId).catch((error) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });

      if (response?.data?.vendor) {
        const chargeData = isDuplicateMode ? cleanVendorCreditDataForDuplicateMode(response.data) : response.data;

        setCurrentVendorId(response.data.vendor);
        setChargeData(chargeData);
        setValues(chargeData);
        setLoadingDialogSuccessText(loadingTransactionDataSuccessText);
        setLoadingDialogState(DialogState.Success);

        window.setTimeout(() => {
          setLoadingDialogState(DialogState.Hidden);
          setShouldRenderForm(true);
        });
        return;
      }

      setLoadingDialogState(DialogState.Error);
      setLoadingDialogErrorText(response ? response.message : "");
    }
  }, [isDuplicateMode, isEditMode, loadingTransactionDataSuccessText, setValues, transactionId]);

  useEffect(() => {
    if (isEditMode) {
      fetchVendorCredit();
    }
  }, [isDuplicateMode, isEditMode, fetchVendorCredit]);

  const { vendorAccounts, isSingleVendorAccount } = useVendorAccountsPopulation<VendorCreditDto>({
    vendorId: !isEditMode && currentVendorId ? currentVendorId : undefined,
    formik
  });

  const isValidForm = async (formikRef: FormikProps<VendorCreditDto>) => {
    formikRef.setFieldTouched("dueDate");
    formikRef.setFieldTouched("date");

    formikRef.values.lines?.forEach((_item, index) => {
      formikRef.setFieldTouched(`lines[${index}].property`);
      formikRef.setFieldTouched(`lines[${index}].amount`);
      formikRef.setFieldTouched(`lines[${index}].account`);
    });

    const errors = await formikRef.validateForm();
    const inValidVendor = Boolean(errors.vendor);

    setShowVendorSelectionError(inValidVendor);

    if (inValidVendor) {
      const errorText = formik.errors.vendor;
      if (errorText) {
        setVendorSelectionErrorText(t(AppStrings.Vendors.Screen.PressToSelectVendor));
      }
    }

    return isEmpty(errors);
  };

  const createTransaction = async () => {
    const vendorBillsValues = cloneDeep(formik.values);

    setViewIndex(DialogView.LOADING);
    setLoadingDialogState(DialogState.Show);

    const results = await vendorCreditsApi.create(vendorBillsValues, { idKey: "vendor" }).catch((error) => {
      setLoadingDialogState(DialogState.Error);
      setLoadingDialogErrorText(error);
    });

    if (results?.data?.id) {
      await filesApi
        .uploadFiles(attachments, results.data.id, LinkedResourceType.VendorCredit)
        .catch((error: string) => {
          setLoadingDialogState(DialogState.Error);
          setLoadingDialogErrorText(error);
        });
      setLoadingDialogState(DialogState.Success);
      analyticsService.track(SegmentEventTypes.PAYABLE_TRANSACTION_CREATED, {
        transactionType: "Vendor Credit",
        payeeType: "Vendor",
        isRecurring: false,
        isUnitEmpty: !formik.values.lines?.some(({ unit }) => unit),
        totalAmount: formik.values.totalAmount || 0,
        numOfLineItems: size(formik.values.lines)
      });
      window.setTimeout(onClose);
    } else {
      setLoadingDialogErrorText(results ? results.message : "");
      setLoadingDialogState(DialogState.Error);
    }
  };

  const updateTransaction = async () => {
    if (!transactionId) {
      setLoadingDialogErrorText(t("common.generalError"));
      setLoadingDialogState(DialogState.Error);
      return;
    }

    const chargeValues = cloneDeep(formik.values);

    setViewIndex(DialogView.LOADING);
    setShouldRenderForm(false);
    setLoadingDialogState(DialogState.Show);
    setLoadingDialogLoadingText(updatingTransactionText);

    const results = await vendorCreditsApi.update(transactionId, chargeValues).catch((error) => {
      setLoadingDialogState(DialogState.Error);
      setLoadingDialogErrorText(error);
    });

    if (results?.data?.id) {
      await filesApi
        .uploadFiles(attachments, results.data.id, LinkedResourceType.VendorCredit)
        .catch((error: string) => {
          setLoadingDialogState(DialogState.Error);
          setLoadingDialogErrorText(error);
        });
      setLoadingDialogSuccessText(transactionUpdatedText);
      setLoadingDialogState(DialogState.Success);
      window.setTimeout(onClose);
    } else {
      setLoadingDialogErrorText(results ? results.message : "");
      setLoadingDialogState(DialogState.Error);
    }
  };

  const addLine = useCallback(() => {
    const lines = formik.values.lines || [];
    setFieldValue("lines", [
      ...lines,
      new ExpenseTransactionLineDto({
        uniqueKey: uuid(),
        account: isSingleVendorAccount && vendorAccounts && vendorAccounts[0]
      })
    ]);
  }, [formik.values.lines, isSingleVendorAccount, setFieldValue, vendorAccounts]);

  const lastLineRef = useLastArrayItemRef<HTMLDivElement>(addLine, []);

  const renderAddButton = () => (
    <Button
      color={"lightBlue"}
      type={"inlineText"}
      actionText={AppStrings.Leases.LeaseCharge.AddLineItem}
      onClick={addLine}
      LeftIcon={AddIcon}
      iconSize={15}
      applyColorForIcons
    />
  );

  function renderLines() {
    return (
      <FieldArray
        name={"lines"}
        render={(arrayHelpers) => {
          const lines = formik.values.lines;
          const linesJSX = lines?.map((currentLine, lineIndex) => (
            <div style={{ width: "100%" }} key={currentLine.uniqueKey}>
              <View shouldShow showAnimation={"fade-in"} hideAnimation={"fade-out"}>
                <ExpenseTransactionLine
                  domRef={lineIndex === lines.length - 1 ? lastLineRef : undefined}
                  transactionItem={currentLine}
                  lineIndex={lineIndex}
                  key={lineIndex}
                  arrayHelpers={arrayHelpers}
                  name={"lines"}
                  formikRef={formik}
                />
              </View>
            </div>
          ));

          return (
            <View>
              <ListInputsContainer>{linesJSX}</ListInputsContainer>
              <View alignItems={"flex-end"} flexDirection={"row"}>
                {renderAddButton()}
              </View>
            </View>
          );
        }}
      />
    );
  }

  const renderSummaryLine = () => {
    const totalAmount = sumBy(formik.values.lines, (line: ExpenseTransactionLineDto) => line.amount || 0);

    return (
      <View marginTop={isMobile ? 20 : 0} flexDirection={"row"}>
        <DialogAmountView amount={totalAmount} title={AppStrings.Leases.LeaseCharge.TotalCredit} />
      </View>
    );
  };

  const handleFileReceived = (files: FileListItemProps[]) => {
    setAttachments(files);
  };

  const renderAttachments = () => (
    <View justifyContent={"flex-end"} width={"100%"} flex={1} marginBottom={20}>
      <FormAttachments
        onFileReceived={handleFileReceived}
        files={attachments}
        editMode={isEditMode}
        resourceId={chargeData ? chargeData.id : undefined}
        resourceType={LinkedResourceType.VendorCredit}
      />
    </View>
  );

  const {
    state: { isIdle: isVendorIdle },
    props: vendorFieldProps
  } = usePeopleAutoComplete({
    currentType: PersonTypeEnum.VENDOR,
    props: {
      entityId: currentVendorId,
      textFieldProps: {
        placeholder: t("vendors.vendorDetails.selectVendor"),
        error: showVendorSelectionError,
        helperText: vendorSelectionErrorText
      },
      onChange(_event, value) {
        setCurrentVendorId(value?.data?.id);
        formik.setFieldTouched("vendor");
      }
    },
    queryOptions: {
      typeFilter: [PersonTypeEnum.VENDOR]
    }
  });

  const handleSubmit = async () => {
    const isValid = await isValidForm(formik);

    if (!isVendorIdle || !isValid) {
      return;
    }

    if (isEditMode && !isDuplicateMode) {
      await updateTransaction();
    } else {
      await createTransaction();
    }
  };

  const renderFormTopSection = () => (
    <View noWrap>
      <ReconciledNotificationView register={chargeData?.register} />
      <View marginTop={20} flexDirection={"row"} noWrap={!isMobile} gap={20}>
        <Grid item xs={12} sm={6}>
          <PeopleAutoComplete {...vendorFieldProps} dataCy={DataCy.formFields.selectVendor} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FastField
            component={FormikDatePicker}
            uniqueKey={"date"}
            label={AppStrings.Common.CreditDate}
            name={"date"}
            noMargin
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FastField
            component={FormikReferenceLabel}
            name={"reference"}
            label={AppStrings.Leases.NewLease.LeaseRent.Credit}
            backgroundColor={"dark"}
            valueTextTransform={"uppercase"}
          />
        </Grid>
      </View>
    </View>
  );

  const renderNotes = () => (
    <View marginTop={20} justifyContent={"center"} height={50}>
      <FastField component={Notes} height={30} name={"memo"} />
    </View>
  );

  const renderForm = () => (
    <FormikContext.Provider value={formik}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <View flex={1} width={"100%"} flexDirection={"column"}>
          <RestrictedPermissionAccess clearance={permission} showNoAccess>
            <View paddingLeft={screenContainerPadding} paddingRight={screenContainerPadding} height={"100%"}>
              {renderFormTopSection()}
              <SeparationLine marginTop={20} width={"100%"} height={1} />
              {renderLines()}
              {renderNotes()}
              {renderSummaryLine()}
              {renderAttachments()}
            </View>
          </RestrictedPermissionAccess>
        </View>
      </MuiPickersUtilsProvider>
    </FormikContext.Provider>
  );

  const handleBackdropClick = () => {
    if (viewIndex > DialogView.FORM) {
      setViewIndex(viewIndex - 1);
      return;
    }
    onBackdropClick();
  };

  const renderView = ({ index }: { index: number }) => {
    const handleRetry = async () => {
      await handleSubmit();
    };
    const handleDismiss = () => {
      setViewIndex(DialogView.FORM);
    };

    if (index === DialogView.FORM) {
      if (isEditMode && loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={fetchVendorCredit}
            didPressDismissButton={handleDismiss}
          />
        );
      }
      if (shouldRenderForm) {
        return renderForm();
      }
      return <div />;
    }
    if (index === DialogView.LOADING) {
      return (
        <View flex={1} alignItems={"center"} justifyContent={"center"} width={"100%"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={handleRetry}
            didPressDismissButton={handleDismiss}
          />
        </View>
      );
    }
    if (index === DialogView.CONFIRM_DELETE) {
      return (
        <DeleteConfirmation
          apiMethod={vendorCreditsApi}
          didPressDismissButton={handleDismiss}
          didFinishOperation={onClose}
          transactionId={transactionId}
          attachments={attachments}
        />
      );
    }
    return <div />;
  };

  const handleDeleteClick = () => {
    setViewIndex(DialogView.CONFIRM_DELETE);
  };

  const handleDuplicateClick = () => {
    setSearchParams((prevSearchParams) => {
      prevSearchParams.set(DUPLICATE_MODE_QUERY_PARAMS.transactionId, transactionId || "");
      return prevSearchParams;
    });

    dispatchAnalytics("button_click", {
      label: DuplicateDialogButtonLabelValues.DUPLICATE_JOURNAL_ENTRY
    });
  };

  const renderHeaderActionButtons = () => (
    <DialogHeaderActionButtons
      onDeleteClick={handleDeleteClick}
      onDuplicateClick={handleDuplicateClick}
      clearance={permission}
      hideDeleteButton={isDuplicateMode || !isEditMode}
      hideDuplicateButton={isDuplicateMode || !isEditMode}
    />
  );

  const renderActionPanelButtons = () => (
    <FormActionButtons
      clearance={permission}
      propsActionPanel={{ editMode: isEditMode }}
      propsSubButton={{ onClick: handleBackdropClick }}
      propsMainButton={{ type: "cta", props: { onClick: handleSubmit } }}
    />
  );

  const frameType: FrameType = useMemo(() => {
    if (viewIndex === DialogView.FORM) {
      if (isEditMode && loadingDialogState !== DialogState.Hidden) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }
    if ([DialogView.LOADING, DialogView.CONFIRM_DELETE].includes(viewIndex)) {
      return "contentOnly";
    }
    return "sectionTitleFrame";
  }, [viewIndex, isEditMode, loadingDialogState]);

  const currentTitle = useMemo(() => {
    if (viewIndex === DialogView.FORM) {
      return dialogTitle;
    }
    return "";
  }, [dialogTitle, viewIndex]);

  const duplicateModeTitle = useMemo(
    () =>
      isDuplicateMode ? `${t(AppStrings.Vendors.VendorDetails.NewCredit)} (${t(AppStrings.Common.Duplicated)})` : "",
    [isDuplicateMode, t]
  );

  return (
    <DialogFrame
      onCloseButtonClick={handleBackdropClick}
      title={duplicateModeTitle || currentTitle}
      width={dialogWidth}
      height={dialogHeight}
      renderView={renderView}
      numViews={DIALOG_VIEWS_NUM}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      RenderHeaderActionButtons={renderHeaderActionButtons}
      frameType={frameType}
      keepViewsMounted={false}
      sectionTitle={currentMenuItemTitle}
    />
  );
};

export default VendorCreditDialog;
