import type { BaseDto, GetAllBaseQueryResponse } from "@doorloop/dto";
import {
  ClientJournalEntryType,
  ExpensePayToResourceType,
  JournalEntryType,
  LeaseRenewalStage,
  PropertyType,
  RentalApplicationDecisionStatus,
  RentRollUnitStatus
} from "@doorloop/dto";
import { accountsApi } from "api/accounts";
import { leasesApi } from "api/leasesApi";
import { leaseChargeApi } from "api/leaseChargeApi";
import { leaseCreditApi } from "api/leaseCreditApi";
import { generalEntriesApi } from "api/generalEntriesApi";
import { ownersApi } from "api/ownersApi";
import type { PropertiesApi } from "api/propertiesApi";
import { propertiesApi } from "api/propertiesApi";
import { tenantsApi } from "api/tenantsApi";
import type { UnitsApi } from "api/unitsApi";
import { unitsApi } from "api/unitsApi";
import { vendorBillsApi, vendorCreditsApi, vendorsApi } from "api/vendorsApi";
import { depositsApi } from "api/depositsApi";
import { leaseRefundApi } from "api/leaseRefundApi";
import { expensesApi } from "api/expensesApi";
import { leasePaymentsApi } from "api/leasePaymentsApi";
import { transferApi } from "api/transferApi";
import { leaseReversedPaymentsApi } from "api/leaseReversedPaymentsApi";
import { ownerContributionsApi } from "api/ownerContributionsApi";
import type { WorkbookOptions } from "@progress/kendo-ooxml";

import AppStrings from "locale/keys";

import moment from "moment";
import { NavigationManager } from "utils/navigation";
import { vendorBillPaymentsApi } from "api/outstandingTransactionsApi";
import type { RestApiBase } from "api/restApiBase";
import type { ApiResult } from "api/apiResult";
import _ from "lodash";
import type { TFunction } from "react-i18next";
import type { GridColumn, GridSubColumn } from "DLUI/lists/gridList/gridList";
import type { State as KendoProcessDataState } from "@progress/kendo-data-query/dist/npm/state";
import type { DataResult } from "@progress/kendo-data-query";
import { process } from "@progress/kendo-data-query";
import type { RestApiBaseWithDictionary } from "api/restApiBaseWithDictionary";
import type { WellKnownTranslatableField } from "DLUI/lists/types";
import { ownerPortalPropertiesApi } from "api/ownerPortal/ownerPortalPropertiesApi";
import { ownerPortalUnitsApi } from "api/ownerPortal/ownerPortalUnitsApi";
import { ownerPortalTenantsApi } from "api/ownerPortal/ownerPortalTenantsApi";
import { ownerPortalLeasesApi } from "api/ownerPortal/ownerPortalLeasesApi";
import { tenantPortalPropertiesApi } from "api/tenantPortal/tenantPortalPropertiesApi";
import { tenantPortalUnitsApi } from "api/tenantPortal/tenantPortalUnitsApi";
import { tenantPortalTenantsApi } from "api/tenantPortal/tenantPortalTenantsApi";
import { tenantPortalLeasesApi } from "api/tenantPortal/tenantPortalLeasesApi";
import type { ParsedQs } from "qs";
import type { MutableRefObject } from "react";

export const currencyCellWidth = 120;
export const smallCellWidth = 100;

export const getNameFromDictionary = (mongoId: string, apiMethod: RestApiBaseWithDictionary<any, any>) => {
  let _cellValue = mongoId;
  if (_cellValue === undefined) {
    return _cellValue;
  }

  if (typeof apiMethod?.getItemFromDictionary === "function") {
    const accountsDictionary = apiMethod.getItemFromDictionary(mongoId);
    if (accountsDictionary) {
      _cellValue = accountsDictionary.name;
    }
  }

  return _cellValue;
};

export const getAddressFromDictionary = (mongoIds: string[], apiMethod: UnitsApi | PropertiesApi): string => {
  const mongoId = mongoIds?.[0];

  const unit = apiMethod?.getItemFromDictionary?.(mongoId);

  const unitStreet1 = unit?.address?.street1;

  return unitStreet1 ?? "";
};

export type WellKnownTranslationsMap = Record<WellKnownTranslatableField, (value: string) => string>;

export const WELL_KNOWN_TRANSLATIONS_MAP: WellKnownTranslationsMap = {
  unit: (id) => getNameFromDictionary(id, unitsApi),
  account: (id) => getNameFromDictionary(id, accountsApi),
  category: (id) => getNameFromDictionary(id, accountsApi),
  property: (id) => getNameFromDictionary(id, propertiesApi),
  lease: (id) => getNameFromDictionary(id, leasesApi),
  vendor: (id) => getNameFromDictionary(id, vendorsApi),
  tenant: (id) => getNameFromDictionary(id, tenantsApi),
  owner: (id) => getNameFromDictionary(id, ownersApi),
  journalEntryType: (type) => translateTypeField(type)
};

export const WELL_KNOWN_TRANSLATIONS_OWNER_PORTAL_MAP: WellKnownTranslationsMap = {
  unit: (id) => getNameFromDictionary(id, ownerPortalUnitsApi),
  account: (_id) => "",
  category: (_id) => "",
  property: (id) => getNameFromDictionary(id, ownerPortalPropertiesApi),
  lease: (id) => getNameFromDictionary(id, ownerPortalLeasesApi),
  vendor: (_id) => "",
  tenant: (id) => getNameFromDictionary(id, ownerPortalTenantsApi),
  owner: (_id) => "",
  journalEntryType: (type) => translateTypeField(type)
};

export const WELL_KNOWN_TRANSLATIONS_TENANT_PORTAL_MAP: WellKnownTranslationsMap = {
  unit: (id) => getNameFromDictionary(id, tenantPortalUnitsApi),
  account: (_id) => "",
  category: (_id) => "",
  property: (id) => getNameFromDictionary(id, tenantPortalPropertiesApi),
  lease: (id) => getNameFromDictionary(id, tenantPortalLeasesApi),
  vendor: (_id) => "",
  tenant: (id) => getNameFromDictionary(id, tenantPortalTenantsApi),
  owner: (_id) => "",
  journalEntryType: (type) => translateTypeField(type)
};

export const translateFieldValue = <DtoType extends BaseDto>(
  fieldName: string,
  currentRow: DtoType,
  correspondingColumn: GridColumn<DtoType>,
  t: TFunction,
  { wellKnownTranslationsMap }: ProcessDataOptions
) => {
  const originalValue = _.get(currentRow, fieldName);

  if (wellKnownTranslationsMap?.[fieldName]) {
    return t(wellKnownTranslationsMap[fieldName](originalValue));
  }

  let translatedValue: string | undefined;

  if (correspondingColumn.translate) {
    if (originalValue || correspondingColumn.translateEmptyCells) {
      if (correspondingColumn.translateIgnoreLocaleStrings) {
        translatedValue = correspondingColumn.translate(originalValue as any, currentRow);
      } else {
        translatedValue = t(correspondingColumn.translate(originalValue as any, currentRow));
      }
    }
  } else if (correspondingColumn.formatType && originalValue === undefined) {
    // If we got here, we don't have a way to translate the value and it's undefined
    translatedValue = "";
  }

  return translatedValue ?? originalValue;
};

/**
 * Translates a row in-place by calling the corresponding column's translate function
 * @param t18nFn - internationalization function (useTranslation().t)
 * @param currentItemRef - reference to the current row
 * @param allGridColumns - all columns of the grid
 */
export const translateItem = <DtoType extends BaseDto>(
  t18nFn: TFunction<"translation">,
  currentItemRef: DtoType,
  allGridColumns: GridColumn[] | GridSubColumn[],
  options: ProcessDataOptions
) => {
  /**
   * The server injects a Beginning Balance item in a very hacky way to the start of the report.
   * We catch this behaviour and adjust the type to say "Beginning Balance"
   */
  if ("beginningBalance" in (currentItemRef as unknown as Record<string, unknown>)) {
    const castedItem = currentItemRef as unknown as {
      type: string;
      _type?: string;
      beginningBalance: unknown;
      amount?: unknown;
    };

    castedItem._type = castedItem.type ?? "beginningBalance";
    castedItem.type = t18nFn(AppStrings.Reports.ReportsScreen.BeginningBalance);
    castedItem.amount ??= 0;
    return;
  }

  allGridColumns.forEach((column) => {
    const fieldName = column.field;

    /**
     * Check for sub columns to translate
     * We do this before the fieldName check because sometimes columns that have sub columns don't have a field name
     */
    if (column.subColumns?.length) {
      translateItem(t18nFn, currentItemRef, column.subColumns, options);
    }

    if (!fieldName) {
      return;
    }

    const cellValue = fieldName ? _.get(currentItemRef, fieldName) : undefined;
    const translatedValue = translateFieldValue(
      fieldName,
      currentItemRef,
      column as GridColumn<DtoType>,
      t18nFn,
      options
    );
    if (translatedValue !== cellValue) {
      // first, set the original value as a hidden field
      _.set(currentItemRef, "_" + fieldName, cellValue);
      // then, set the translated value
      _.set(currentItemRef, fieldName, translatedValue);
    }
  });
};

export interface ProcessDataOptions {
  wellKnownTranslationsMap: WellKnownTranslationsMap;
}

export const processData = <DtoType extends BaseDto>(
  t18nFn: TFunction<"translation">,
  data: DtoType[],
  state: KendoProcessDataState,
  allGridColumns: GridColumn[],
  options: ProcessDataOptions
): DataResult => {
  let dataClone = _.cloneDeep(data) as DtoType[];
  let areSpecialElementsPresent = false;
  dataClone.forEach((currentDataItem) => {
    if (!areSpecialElementsPresent && "beginningBalance" in currentDataItem) {
      areSpecialElementsPresent = true;
    }

    translateItem(t18nFn, currentDataItem, allGridColumns, options);
  });

  /**
   * We're not allowed to have special items in grouped data, so we filter them out
   */
  if (areSpecialElementsPresent && state.group?.length) {
    dataClone = dataClone.filter((item) => !("beginningBalance" in item));
  }

  const kendoProcessedDataResult = process(dataClone, state);
  kendoProcessedDataResult.data.forEach((currentItem) => {
    if (currentItem.value === undefined || currentItem.value === null) {
      currentItem.value = "N/A";
    }
  });

  return kendoProcessedDataResult;
};

export const getAdditionalColumnsFromApiData = <DtoType extends BaseDto>(
  apiResult: GetAllBaseQueryResponse<DtoType> & { columns?: Array<{ field?: string; title?: string }> }
): GridColumn[] => {
  const { columns: dynamicColumns } = apiResult;
  if (!dynamicColumns) {
    return [];
  }

  const additionalColumns: GridColumn[] = [];

  dynamicColumns.forEach((column) => {
    additionalColumns.push({
      field: String(column.field ?? ""),
      title: String(column.title ?? ""),
      show: true,
      showZeroValues: true,
      formatType: "currency",
      aggregate: "sum",
      footerCell: {
        sum: true
      },
      groupFooterCell: {
        sum: true
      }
    });
  });

  if (dynamicColumns[dynamicColumns.length - 1].field !== "total") {
    additionalColumns.push({
      field: "total",
      title: AppStrings.Common.ListHeader.Total,
      show: true,
      formatType: "currency",
      aggregate: "sum",
      footerCell: {
        sum: true
      },
      groupFooterCell: {
        sum: true
      }
    });
  }

  return additionalColumns;
};

export const payeeTypeToApiMethod: Record<ExpensePayToResourceType, any> = {
  [ExpensePayToResourceType.TENANT]: tenantsApi,
  [ExpensePayToResourceType.OWNER]: ownersApi,
  [ExpensePayToResourceType.VENDOR]: vendorsApi
};

export const getNameFromDictionaryByType = (id?: string, type?: ExpensePayToResourceType): string =>
  id && type ? getNameFromDictionary(id, payeeTypeToApiMethod[type]) : "";

const typeToLabel: Record<ExpensePayToResourceType, string> = {
  [ExpensePayToResourceType.TENANT]: AppStrings.Tenants.Screen.Tenant,
  [ExpensePayToResourceType.OWNER]: AppStrings.Owners.Screen.Owner,
  [ExpensePayToResourceType.VENDOR]: AppStrings.Vendors.Screen.Vendor
};

export const getPayToResourceTypeLabel = (type?: ExpensePayToResourceType): string => (type ? typeToLabel[type] : "");

export type GroupByApiMap = Record<string, RestApiBaseWithDictionary<any, any>>;

export const GROUP_BY_API_MAP: GroupByApiMap = {
  vendor: vendorsApi,
  account: accountsApi,
  property: propertiesApi,
  unit: unitsApi,
  tenant: tenantsApi,
  lease: leasesApi,
  owner: ownersApi
} as const;

export const GROUP_BY_API_OWNER_PORTAL_MAP: GroupByApiMap = {
  property: ownerPortalPropertiesApi,
  unit: ownerPortalUnitsApi,
  tenant: ownerPortalTenantsApi,
  lease: ownerPortalLeasesApi
} as const;

export const GROUP_BY_API_TENANT_PORTAL_MAP: GroupByApiMap = {
  property: tenantPortalPropertiesApi,
  unit: tenantPortalUnitsApi,
  tenant: tenantPortalTenantsApi,
  lease: tenantPortalUnitsApi
} as const;

export interface TranslateGroupTitleOptions {
  groupBy?: ParsedQs["groupBy"];
  groupByApiMap: GroupByApiMap;
}

export const translateGroupTitle = (value: string, { groupBy, groupByApiMap }: TranslateGroupTitleOptions) => {
  if (typeof groupBy === "string" && groupByApiMap?.[groupBy] !== undefined) {
    return getNameFromDictionary(value, groupByApiMap[groupBy]);
  }

  return value;
};

export const translatePaymentMethodField = (fieldValue: string) => {
  const transactionTypeText = "";

  if (fieldValue) {
    return "common.enums.paymentMethod." + fieldValue;
  }
  return transactionTypeText;
};

export const translatePropertyTypeField: Record<PropertyType, string> = {
  [PropertyType.RESIDENTIAL_SINGLE_FAMILY]: AppStrings.Properties.Subtype.SingleFamily,
  [PropertyType.RESIDENTIAL_MULTI_FAMILY]: AppStrings.Properties.Subtype.MultiFamily,
  [PropertyType.RESIDENTIAL_CONDO]: AppStrings.Properties.Subtype.Condo,
  [PropertyType.RESIDENTIAL_TOWNHOME]: AppStrings.Properties.Subtype.Townhome,
  [PropertyType.COMMERCIAL_INDUSTRIAL]: AppStrings.Properties.Subtype.Industrial,
  [PropertyType.COMMERCIAL_OFFICE]: AppStrings.Properties.Subtype.Office,
  [PropertyType.COMMERCIAL_RETAIL]: AppStrings.Properties.Subtype.Retail,
  [PropertyType.COMMERCIAL_SHOPPING_CENTER]: AppStrings.Properties.Subtype.ShoppingCenter,
  [PropertyType.COMMERCIAL_STORAGE]: AppStrings.Properties.Subtype.Storage,
  [PropertyType.COMMERCIAL_PARKING]: AppStrings.Properties.Subtype.Parking,
  [PropertyType.RESIDENTIAL_OTHER]: AppStrings.Properties.Subtype.Other,
  [PropertyType.COMMERCIAL_OTHER]: AppStrings.Properties.Subtype.Other,
  [PropertyType.COMMERCIAL_CO_WORKING]: AppStrings.Properties.Subtype.CoWorking
} as const;

const journalEntryTypeText: Record<JournalEntryType & ClientJournalEntryType, string> = {
  [JournalEntryType.APPLICATION_FEE]: AppStrings.Common.Enums.JournalEntryType.ApplicationFee,
  [JournalEntryType.DEPOSIT]: AppStrings.Common.Enums.JournalEntryType.Deposit,
  [JournalEntryType.GENERAL_ENTRY]: AppStrings.Common.Enums.JournalEntryType.GeneralEntry,
  [JournalEntryType.LEASE_CHARGE]: AppStrings.Common.Enums.JournalEntryType.LeaseCharge,
  [JournalEntryType.LEASE_CREDIT]: AppStrings.Common.Enums.JournalEntryType.LeaseCredit,
  [JournalEntryType.LEASE_PAYMENT]: AppStrings.Common.Enums.JournalEntryType.LeasePayment,
  [JournalEntryType.LEASE_REVERSED_PAYMENT]: AppStrings.Common.Enums.JournalEntryType.ReversedPayments,
  [JournalEntryType.LEASE_REFUND]: AppStrings.Common.Enums.JournalEntryType.LeaseRefund,
  [JournalEntryType.OWNER_CONTRIBUTION]: AppStrings.Common.Enums.JournalEntryType.OwnerContribution,
  [JournalEntryType.OWNER_DISTRIBUTION]: AppStrings.Common.Enums.JournalEntryType.OwnerDistribution,
  [JournalEntryType.TRANSFER]: AppStrings.Common.Enums.JournalEntryType.Transfer,
  [JournalEntryType.VENDOR_BILL]: AppStrings.Common.Enums.JournalEntryType.VendorBill,
  [JournalEntryType.VENDOR_BILL_PAYMENT]: AppStrings.Common.Enums.JournalEntryType.VendorBillPayment,
  [JournalEntryType.VENDOR_CREDIT]: AppStrings.Common.Enums.JournalEntryType.VendorCredit,
  [JournalEntryType.EXPENSE]: AppStrings.Common.Enums.JournalEntryType.Expense,
  [ClientJournalEntryType.LINE_ITEM]: AppStrings.Common.Enums.JournalEntryType.LineItem,
  [ClientJournalEntryType.APPLIED_CREDIT]: AppStrings.Common.Enums.JournalEntryType.AppliedCredit
} as const;

export const translateTypeField = (FieldValue: string) =>
  journalEntryTypeText?.[FieldValue as JournalEntryType] ?? AppStrings.Common.Unknown;

export const getRenewalStageText = (renewalStage: LeaseRenewalStage) => {
  switch (renewalStage) {
    case LeaseRenewalStage.NOT_RENEWING:
      return AppStrings.Leases.LeaseRenewals.NotRenewing;
    case LeaseRenewalStage.NOT_STARTED:
      return AppStrings.Leases.LeaseRenewals.NotStarted;
    case LeaseRenewalStage.RENEWAL_DRAFT:
      return AppStrings.Leases.LeaseRenewals.DraftPending;
    case LeaseRenewalStage.RENEWAL_OFFER:
      return AppStrings.Leases.LeaseRenewals.OfferPending;
  }
};

export const getRentRollUnitStatus = (rentRollUnitStatus: RentRollUnitStatus) => {
  switch (rentRollUnitStatus) {
    case RentRollUnitStatus.CURRENT:
      return AppStrings.Units.RentRollUnitStatus.Current;
    case RentRollUnitStatus.PAST_DUE:
      return AppStrings.Units.RentRollUnitStatus.PastDue;
    case RentRollUnitStatus.VACANT:
      return AppStrings.Units.FilterValues.Vacant;
    default:
      return "";
  }
};

interface ExcelExportParams {
  excelExporterRef: MutableRefObject<any>;
  reportDates: string;
  reportName: string;
  workBook: WorkbookOptions;
  accountingMethod?: string;
  treeColumns?: any;
  hideExcelGroupHeaderPrefix?: boolean;
}

export const excelExport = ({
  excelExporterRef,
  reportDates,
  reportName,
  workBook,
  accountingMethod,
  treeColumns,
  hideExcelGroupHeaderPrefix
}: ExcelExportParams) => {
  if (workBook && excelExporterRef.current) {
    if (workBook.sheets && workBook.sheets[0]) {
      const sheet = workBook.sheets[0];

      let footerText = "";
      if (accountingMethod) {
        footerText += accountingMethod + " ";
        footerText += moment().toDate();
      }

      sheet.rows?.splice(0, 0, {
        cells: [
          {
            value: ""
          }
        ],
        type: "data"
      });
      sheet.rows?.splice(1, 0, {
        cells: [
          {
            value: reportName + " - " + reportDates,
            fontSize: 18
          }
        ],
        type: "data"
      });
      sheet.rows?.splice(2, 0, {
        cells: [
          {
            value: ""
          }
        ],
        type: "data"
      });

      sheet.rows?.push({
        cells: [{ value: "" }],
        type: "data"
      });
      sheet.rows?.push({
        cells: [{ value: footerText }],
        type: "data"
      });

      sheet.rows?.forEach((row: any) => {
        let isFooterRow = false;
        const isGroupHeader = row.type === "group-header";
        row.cells.forEach((cell: any) => {
          if (isFooterRow) {
            cell.borderTop = {
              color: "#000000",
              size: 1
            };
            cell.bold = true;
          }
          if (cell.value && cell.value.indexOf && cell.value.indexOf("Total for") > -1) {
            isFooterRow = true;
          }
          if (isGroupHeader && hideExcelGroupHeaderPrefix) {
            // satisfy TS - we know it'll be a string as it's the group-header
            if (typeof cell.value === "string") {
              // removes prefixed value - matches string up to the first colon and space
              cell.value = cell.value?.replace(/^.+?:\s*/, "");
            }
          }
        });
      });
      excelExporterRef.current.save(workBook, treeColumns);
    }
  }
};

export const translateReference = (cellValue: string) => `#${cellValue.toUpperCase()}`;

export const makeArrayFilter = (queryString, filterName): string[] | undefined =>
  queryString[filterName]
    ? Array.isArray(queryString[filterName])
      ? queryString[filterName]
      : [queryString[filterName]]
    : undefined;

export const viewTransactionDetails = (
  typeText: string,
  pathName: string,
  transactionId: string,
  locationSearch: string
) => {
  switch (typeText) {
    case JournalEntryType.APPLICATION_FEE:
      //TODO add support
      break;
    case JournalEntryType.DEPOSIT:
      NavigationManager.editBankDeposit(pathName, transactionId, locationSearch);
      break;
    case JournalEntryType.LEASE_CHARGE:
      NavigationManager.editCharge(pathName, transactionId, undefined, undefined, locationSearch);
      break;
    case JournalEntryType.EXPENSE:
      NavigationManager.editExpense(pathName, transactionId, locationSearch);
      break;
    case JournalEntryType.LEASE_PAYMENT:
      NavigationManager.editPayment(pathName, transactionId, locationSearch);
      break;
    case ClientJournalEntryType.APPLIED_CREDIT:
      NavigationManager.editAppliedCredit(pathName, transactionId, locationSearch);
      break;
    case JournalEntryType.LEASE_REVERSED_PAYMENT:
      NavigationManager.editReversedPayment(pathName, transactionId, locationSearch);

      break;

    case JournalEntryType.LEASE_CREDIT:
      NavigationManager.editCredit(pathName, transactionId, undefined, undefined, locationSearch);
      break;
    case JournalEntryType.LEASE_REFUND:
      NavigationManager.editRefund(pathName, transactionId, locationSearch);
      break;
    case JournalEntryType.VENDOR_BILL_PAYMENT:
      NavigationManager.editVendorBillPayment(pathName, transactionId, locationSearch);
      break;
    case JournalEntryType.GENERAL_ENTRY:
      NavigationManager.editGeneralEntry(pathName, transactionId, locationSearch);
      break;
    case JournalEntryType.OWNER_CONTRIBUTION:
      NavigationManager.editOwnerContribution(pathName, transactionId, locationSearch);
      break;
    case JournalEntryType.VENDOR_CREDIT:
      NavigationManager.editVendorCredit(pathName, transactionId, locationSearch);
      break;
    case JournalEntryType.TRANSFER:
      NavigationManager.editBankTransfer(pathName, transactionId, locationSearch);
      break;
    case JournalEntryType.VENDOR_BILL:
      NavigationManager.editVendorBill(pathName, transactionId, locationSearch);
      break;
  }
};

export const getApplicationDecisionStatus = (status: RentalApplicationDecisionStatus): string => {
  switch (status) {
    case RentalApplicationDecisionStatus.APPROVED:
      return AppStrings.Common.Enums.RentalApplicationDecisionStatus.APPROVED;
    case RentalApplicationDecisionStatus.IGNORED:
      return AppStrings.Common.Enums.RentalApplicationDecisionStatus.IGNORED;
    case RentalApplicationDecisionStatus.REJECTED:
      return AppStrings.Common.Enums.RentalApplicationDecisionStatus.REJECTED;
    case RentalApplicationDecisionStatus.UNDECIDED:
      return AppStrings.Common.Enums.RentalApplicationDecisionStatus.UNDECIDED;
    case RentalApplicationDecisionStatus.NOT_STARTED:
      return AppStrings.Common.Enums.RentalApplicationDecisionStatus.NOT_STARTED;
    default:
      return "";
  }
};

const journalEntryToAPIMap: Record<JournalEntryType & ClientJournalEntryType, RestApiBase<any, any> | undefined> = {
  [JournalEntryType.DEPOSIT]: depositsApi,
  [JournalEntryType.LEASE_CHARGE]: leaseChargeApi,
  [JournalEntryType.VENDOR_BILL]: vendorBillsApi,
  [JournalEntryType.VENDOR_BILL_PAYMENT]: vendorBillPaymentsApi,
  [JournalEntryType.LEASE_CREDIT]: leaseCreditApi,
  [JournalEntryType.LEASE_PAYMENT]: leasePaymentsApi,
  [JournalEntryType.LEASE_REFUND]: leaseRefundApi,
  [JournalEntryType.TRANSFER]: transferApi,
  [JournalEntryType.EXPENSE]: expensesApi,
  [JournalEntryType.GENERAL_ENTRY]: generalEntriesApi,
  [JournalEntryType.LEASE_REVERSED_PAYMENT]: leaseReversedPaymentsApi,
  [JournalEntryType.OWNER_CONTRIBUTION]: ownerContributionsApi,
  [JournalEntryType.VENDOR_CREDIT]: vendorCreditsApi,
  [JournalEntryType.OWNER_DISTRIBUTION]: expensesApi,
  [JournalEntryType.APPLICATION_FEE]: undefined
};

const defaultApi = () => {
  return {
    delete: async (...args: any[]): Promise<ApiResult<any>> => {
      return {
        statusCode: 400,
        message: AppStrings.BulkActions.DeletionNotSupported,
        data: undefined,
        status: false
      };
    }
  };
};

export const translateTypeIntoAPI = (type: JournalEntryType): RestApiBase<any, any> => {
  const api = journalEntryToAPIMap[type];

  if (!api) {
    console.warn(`We do not have an appropriate way to delete the type of "${type}" so we will perform a noop`);

    return defaultApi() as any as RestApiBase<any, any>;
  }

  return api;
};
