import React from "react";
import { BackgroundImageView, View } from "DLUI/view";
import Text from "DLUI/text";
import AppStrings from "../../../../../locale/keys";
import { NavigationManager } from "../../../../../utils/navigation";
import DLButton, { DLButtonSizesEnum } from "DLUI/button/dlButton";
import { history } from "store/history";
import { Routes } from "../../../../appRouter";
import { DialogRoutes } from "DLUI/screen/dialogsProvider";

interface Props {
  propertyId?: string;
  landlordId?: number;
}

const generateCorrectTextByLevel = (propertyId?: string, hasLandlordId?: boolean) => {
  const addressRequiredViewTextsList = {
    title: AppStrings.Common.VerifyCompanyAddress,
    description: AppStrings.Common.MissingCompanyAddressDescription,
    actionButton: AppStrings.Common.VerifyCompanyAddressButton
  };

  if (propertyId && hasLandlordId) {
    addressRequiredViewTextsList.title = AppStrings.Common.VerifyPropertyAddress;
    addressRequiredViewTextsList.description = AppStrings.Common.MissingPropertyAddressDescription;
    addressRequiredViewTextsList.actionButton = AppStrings.Common.VerifyPropertyAddressButton;
  }

  return addressRequiredViewTextsList;
};

const AddressRequiredView: React.FC<Props> = ({ propertyId, landlordId }: Props) => {
  const { title, description, actionButton } = generateCorrectTextByLevel(propertyId, Boolean(landlordId));

  const didPressVerifyAddress = () => {
    if (propertyId && landlordId) {
      NavigationManager.viewPropertyDetails(propertyId, "/settings/edit/property");
    } else {
      history.push(Routes.SETTINGS_GENERAL_SETTINGS + "/" + DialogRoutes.EDIT_COMPANY_INFORMATION);
    }
  };

  return (
    <View alignItems={"center"} justifyContent={"center"} flex={1} width={"100%"} marginTop={100} minHeight={500}>
      <BackgroundImageView
        publicFileName={`url(/images/World.png)`}
        backgroundSize={"contain"}
        height={96}
        width={96}
        backgroundPosition={"center center"}
      />
      <Text value={title} marginTop={20} fontSize={20} fontWeight={700} />
      <Text
        value={description}
        marginTop={8}
        fontSize={16}
        align={"center"}
        lineHeight={"19px"}
        maxWidth={400}
        color={"secondary-gray"}
      />
      <DLButton
        onClick={didPressVerifyAddress}
        actionText={actionButton}
        size={DLButtonSizesEnum.LARGE}
        style={{ minWidth: 330, marginTop: 20 }}
      />
      <View height={20} />
    </View>
  );
};

export default AddressRequiredView;
