import ColorsEnum from "@/utils/colorsEnum";
import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    componentContainer: {
      borderRadius: 5,
      backgroundColor: "rgba(237, 85, 94, 0.1)",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "10px",
      height: "100%"
    },
    whiteThemeComponentContainer: {
      borderRadius: 5,
      padding: "10px",
      backgroundColor: "#FCECEE",
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    errorSignIcon: {
      margin: "0px 10px 0px 0px",
      minWidth: "25px",

      "& path": {
        fill: ColorsEnum.ErrorDark
      }
    }
  })
);
