import type { ReactNode } from "react";
import React, { Fragment, useMemo } from "react";
import Text from "DLUI/text";
import clsx from "clsx";
import { LabelIndicator } from "DLUI/labelIndicator";
import { View } from "DLUI/view";
import { Link } from "DLUI/link";
import { useTranslation } from "react-i18next";
import type { SVGIconComponent } from "assets/icons/types";
import { DrawerMaxWidth } from "DLUI/drawer/drawer";
import { HelpPanel } from "DLUI/screen/helpPanel/helpPanel";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { IconButton } from "DLUI/form";
import { BackIcon, ChevronRightGray } from "../../../assets";
import { isArray } from "lodash";
import { Icon } from "DLUI/icon";
import { DataCy } from "@doorloop/dto";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import { useHistory } from "react-router-dom";
import { baseRouteHistoryLength } from "@/utils/atoms";
import { useAtomValue } from "jotai";

export interface BreadCrumbsProps {
  titleText?: BreadCrumbsText[] | BreadCrumbsText;
  subTitleText?: BreadCrumbsText[] | BreadCrumbsText;
  ActionPanel?: React.FC<any>;
  labelIndicatorText?: string;
  LabelIndicatorIcon?: SVGIconComponent;
  subTitleElement?: ReactNode;
  helpPanel?: HelpPanelProps;
  screenPadding?: number;
  labelLeadingElement?: ReactNode;
  dataCy?: string;
  noWrapActionItem?: boolean;
  showBackButton?: boolean;
}

export interface BreadCrumbsText {
  text: string;
  href?: string;
  goBackCallback?: () => void;
  dataCy?: string;
}

const BREAD_CRUMBS_FONT_SIZE = 12;
const SUBTITLE_FONT_SIZE = 20;
const MORE_BUTTON_WIDTH = 40;

export const BreadCrumbs = ({
  titleText,
  ActionPanel,
  labelIndicatorText,
  subTitleText,
  LabelIndicatorIcon,
  subTitleElement,
  screenPadding,
  helpPanel,
  dataCy,
  labelLeadingElement,
  noWrapActionItem,
  showBackButton
}: BreadCrumbsProps) => {
  const { isTabletOrMobile } = useResponsiveHelper();
  const { t } = useTranslation();

  const history = useHistory();
  const baseHistoryLength = useAtomValue(baseRouteHistoryLength);

  const translateIfNeeded = (title: string): string => (title.includes(".") ? t(title) : title);

  const updateTitleText = (text: string) => {
    if (!document.title.includes(translateIfNeeded(text))) {
      document.title = document.title + " - " + translateIfNeeded(text);
    }
  };

  const isBackButtonDisabled = baseHistoryLength >= history.length;

  const BackButton = () => (
    <View autoWidth marginRight={5} flexDirection={"row"}>
      <IconButton
        pathColor={"black"}
        Icon={BackIcon}
        paddingLeft={4}
        paddingRight={4}
        paddingTop={6}
        paddingBottom={6}
        onClick={() => {
          titleText?.[0]?.goBackCallback ? titleText?.[0]?.goBackCallback() : window.history.back();
        }}
        dataCy={DataCy.detailScreens.backButton}
        disabled={isBackButtonDisabled}
      />
    </View>
  );

  const screenTitleText = useMemo(() => {
    document.title = "DoorLoop";
    if (Array.isArray(titleText) && titleText && titleText.length > 1) {
      const path: any = [];
      for (let i = 0; i < titleText.length; i++) {
        if (titleText[i].href) {
          updateTitleText(titleText[i].text);
          path.push(
            <Fragment key={"BLI" + i}>
              <Link hrefUrl={titleText[i].href}>
                <Text key={"STT" + i} color={"gray"} fontSize={BREAD_CRUMBS_FONT_SIZE} value={titleText[i].text} />
              </Link>
            </Fragment>
          );
        } else {
          path.push(
            <Fragment key={"BLD" + i}>
              <Text key={"STT" + i} color={"gray"} fontSize={BREAD_CRUMBS_FONT_SIZE}>
                {titleText[i].text}
              </Text>
            </Fragment>
          );
        }
        if (i < titleText.length - 1) {
          path.push(
            <Fragment key={"BLs" + i}>
              <Icon Source={ChevronRightGray} height={9} marginRight={8} marginLeft={8} key={"TS" + i} />
            </Fragment>
          );
        }
      }
      return path;
    }
  }, [titleText]);

  const screenSubTitleText = useMemo(() => {
    if (subTitleText) {
      if (!Array.isArray(subTitleText)) {
        updateTitleText(subTitleText.text);
        if (subTitleText.href) {
          return (
            <View
              flexDirection={"row"}
              alignItems={"center"}
              maxWidth={isTabletOrMobile ? window.innerWidth - 160 : window.innerWidth - DrawerMaxWidth - 160}
            >
              <Link hrefUrl={subTitleText.href}>
                <Text
                  overFlow={"ellipsis"}
                  numberOfLines={1}
                  color={"black"}
                  fontSize={SUBTITLE_FONT_SIZE}
                  fontWeight={700}
                  lineHeight={"20px"}
                  value={subTitleText.text}
                />
              </Link>
            </View>
          );
        }
        return (
          <Text
            maxWidth={isTabletOrMobile ? window.innerWidth - 120 : window.innerWidth - DrawerMaxWidth - 20}
            numberOfLines={1}
            color={"black"}
            fontSize={SUBTITLE_FONT_SIZE}
            fontWeight={700}
            value={subTitleText.text}
            lineHeight={"20px"}
            dataCy={dataCy}
          />
        );
      }
      const path: any = [];
      for (let i = 0; i < subTitleText.length; i++) {
        let fontWeight = 400;
        if (i === subTitleText.length - 1) {
          fontWeight = 600;
        }
        updateTitleText(subTitleText[i].text);
        path.push(
          <Text key={"MTT" + i} color={"black"} fontWeight={fontWeight} fontSize={SUBTITLE_FONT_SIZE}>
            {subTitleText[i].text}
          </Text>
        );
        if (i < subTitleText.length - 1) {
          path.push(
            <Text key={"TS" + i} color={"black"} fontSize={14}>
              {">"}
            </Text>
          );
        }
      }
      return path;
    }
    return null;
  }, [subTitleText]);

  const renderLabelIndicator = () => {
    if (labelIndicatorText || LabelIndicatorIcon) {
      return (
        <View marginLeft={10} autoWidth flexDirection={"row"}>
          {LabelIndicatorIcon ? <LabelIndicatorIcon /> : <LabelIndicator labelText={labelIndicatorText || ""} />}
        </View>
      );
    }
    return null;
  };

  const shouldShowBackButton = useMemo(() => {
    if (showBackButton !== undefined) {
      return showBackButton;
    }

    return isArray(titleText) || titleText?.href || titleText?.goBackCallback;
  }, [showBackButton, titleText]);

  return (
    <View
      alignItems={"center"}
      flexDirection={"row"}
      className={clsx(["screenTitle"])}
      marginTop={10}
      paddingRight={screenPadding}
      paddingLeft={screenPadding}
      justifyContent={screenTitleText ? "flex-start" : "center"}
    >
      <View flex={1} flexDirection={"row"} alignItems={"center"} noWrap>
        {shouldShowBackButton && <BackButton />}
        <View
          flexDirection={"row"}
          alignItems={"center"}
          style={{ borderLeft: shouldShowBackButton ? "1px solid lightgray" : undefined }}
          autoWidth
          flex={1}
          paddingLeft={10}
        >
          {labelLeadingElement}
          <View flex={1} minHeight={20} fullWidth noWrap>
            <View alignItems={"center"} flexDirection={"row"} paddingRight={MORE_BUTTON_WIDTH}>
              {screenTitleText}
              {renderLabelIndicator()}
            </View>

            <View alignItems={"center"} flexDirection={"row"} dataCy={DataCy.detailScreens.screenTitle}>
              {screenSubTitleText}
              {subTitleElement}
            </View>
            {helpPanel && (
              <HelpPanel
                actionText={helpPanel.actionText}
                actionItems={helpPanel.actionItems}
                description={helpPanel.description}
                noWrapActionItem={noWrapActionItem}
              />
            )}
          </View>
        </View>
      </View>
      {ActionPanel && (
        <View height={"100%"} width={"auto"} noWrap justifyContent={isTabletOrMobile ? "flex-start" : "flex-end"}>
          {ActionPanel ? <ActionPanel /> : null}
        </View>
      )}
    </View>
  );
};
