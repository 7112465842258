"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TreeListItemTransaction = void 0;
const treeListItemWithGroupByValues_1 = require("./treeListItemWithGroupByValues");
class TreeListItemTransaction extends treeListItemWithGroupByValues_1.TreeListItemWithGroupByValues {
    constructor(init) {
        super(Object.assign(Object.assign({}, init), { type: "transaction" }));
        this.data = init === null || init === void 0 ? void 0 : init.data;
    }
}
exports.TreeListItemTransaction = TreeListItemTransaction;
