import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import type { TextColor, TextTransformProp } from "DLUI/text/text";
import { DataCy } from "@doorloop/dto";

interface ComponentProps {
  title?: string;
  marginLeft?: number;
  marginTop?: number;
  textColor?: TextColor;
  fontSize?: number;
  textTransform?: TextTransformProp;
  marginRight?: number;
  replaceObject?: Record<string, string | number>;
  dataCy?: string;
}

const DialogTitle: React.FC<ComponentProps> = ({
  title,
  marginLeft,
  marginTop,
  textColor,
  fontSize,
  textTransform,
  marginRight,
  replaceObject
}: ComponentProps) => (
  <View marginTop={marginTop} paddingLeft={marginLeft} paddingRight={marginRight} flexDirection={"row"} width={"auto"}>
    <View backgroundColor={"pink"} width={10} height={30} marginRight={10} borderRadius={10} />
    <Text
      removeDefaultPadding
      fontSize={fontSize || 18}
      fontWeight={700}
      color={textColor || "black"}
      value={title}
      lineHeight={"28px"}
      textTransform={textTransform}
      replaceObject={replaceObject}
      dataCy={DataCy.dialog.title}
    />
  </View>
);

export default DialogTitle;
