import React, { useCallback, useEffect, useState } from "react";
import { DialogFrame } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { View } from "DLUI/view";
import clsx from "clsx";
import Dialog from "DLUI/dialogs/dialog";
import AppStrings from "../../../../locale/keys";
import { SearchPanel } from "DLUI/screen";
import type { ObjectWithAnyStringKey, StaticRemoteTextOrDateFilter } from "DLUI/screen/filterPanel/filterPanel";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  onClose: (selectedItem) => void;
  onBackdropClick: () => void;
  dialogState: DialogState;
  selectionList: (onItemPress, filterObject, setRequestInProgress) => JSX.Element;
  dialogTitle: string;
  filterOptions?: Array<StaticRemoteTextOrDateFilter<ObjectWithAnyStringKey>>;
  defaultFilter?: Record<string, any>;
  hideFilterButton?: boolean;
}

export const DialogFrameHeight = Math.min(window.innerHeight, 980);
export const DialogFrameWidth = Math.min(window.innerWidth, 1100);
export const StickyHeaderId = "selectableListContainer";
export const SelectableListContainer = "selectableListContainer";

const SelectionDialog = ({
  onBackdropClick,
  onClose,
  dialogState,
  selectionList,
  dialogTitle,
  filterOptions,
  defaultFilter,
  hideFilterButton
}: ComponentProps) => {
  const { screenContainerPadding } = useResponsiveHelper();
  const [filterObject, setFilterObject] = useState(defaultFilter);
  const [requestInProgress, setRequestInProgress] = useState<boolean>(false);

  useEffect(() => {
    setFilterObject(defaultFilter);
  }, [defaultFilter]);

  const didChangeFilterOptions = (filterObj: Record<string, any>) => {
    setFilterObject({ ...filterObject, ...filterObj });
  };

  const renderView = useCallback(
    ({}: any) => (
      <View paddingLeft={screenContainerPadding} paddingRight={screenContainerPadding} marginTop={20}>
        <SearchPanel
          searchPlaceHolderText={AppStrings.Common.Search}
          filterOptions={filterOptions}
          didChangeFilterOptions={didChangeFilterOptions}
          requestInProgress={requestInProgress}
          preventUrlChange
          hideFilterButton={hideFilterButton}
        />
        <View height={DialogFrameHeight - 180} overflow={"scroll"} id={SelectableListContainer}>
          {selectionList(onClose, filterObject, setRequestInProgress)}
        </View>
      </View>
    ),
    [filterObject, requestInProgress]
  );

  return (
    <Dialog
      open={dialogState === DialogState.Show}
      className={clsx(["DLUI_Dialog"])}
      onClose={onBackdropClick}
      disableBackdropClick
    >
      <DialogFrame
        onCloseButtonClick={onBackdropClick}
        title={dialogTitle}
        width={DialogFrameWidth}
        height={DialogFrameHeight}
        renderView={renderView}
        numViews={1}
        activeView={0}
        frameType={"sectionTitleFrame"}
        keepViewsMounted={false}
      />
    </Dialog>
  );
};

export default SelectionDialog;
