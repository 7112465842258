import type { CSSProperties, ForwardedRef, KeyboardEventHandler, MouseEventHandler } from "react";
import React, { forwardRef, useCallback, useMemo } from "react";
import type { InputBaseProps, InputLabelProps, InputProps, TextFieldProps } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import { useSelector } from "react-redux";

import type { IRootState } from "store/index";
import type { TextFieldType } from "DLUI/form/textField/types";
import { FieldSizes } from "DLUI/form/textField/types";
import type { TextFormatType } from "DLUI/text/text";
import type { PopoverHoverProps } from "DLUI/popover/popoverHover";
import { PopoverHover } from "DLUI/popover/popoverHover";

import type { SVGIconComponent } from "../../../../../assets";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";

import useStylesTextInput from "./useStylesTextInput";
import { fieldSizesMap } from "./utils";
import "./styles.css";

export interface TextInputIconProps {
  Icon: SVGIconComponent;
  onClick?: MouseEventHandler;
  size?: number;
  style?: CSSProperties;
  popoverHoverProps?: PopoverHoverProps;
}

interface TextInputIcons {
  start?: TextInputIconProps;
  end?: TextInputIconProps;
}

export type TextInputProps = Omit<TextFieldProps, "value" | "onBlur" | "size"> & {
  onBlur?: (value: string | number) => void;
  icons?: TextInputIcons;
  TextFieldType?: TextFieldType;
  formatType?: TextFormatType;
  multiLineSize?: "small" | "normal" | "large";
  decimalScale?: number;
  ignoreSpaces?: boolean;
  asyncAutoCompleteInstance?: boolean;
  value?: string;
  disabled?: boolean;
  warning?: boolean;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  characterLimit?: number;
  dataCy?: string;
};

const TextInput = forwardRef(
  (
    {
      id,
      variant = "outlined",
      label,
      icons,
      InputProps = {},
      InputLabelProps = {},
      style,
      TextFieldType,
      onBlur,
      formatType,
      required,
      multiLineSize,
      onChange,
      onClick,
      decimalScale,
      autoComplete,
      onFocus,
      onSubmit,
      ignoreSpaces,
      asyncAutoCompleteInstance,
      multiline,
      name,
      characterLimit,
      value,
      warning = false,
      disabled = false,
      dataCy,
      ...rest
    }: TextInputProps,
    ref: ForwardedRef<any>
  ) => {
    const { isMobile } = useResponsiveHelper();

    const classes = useStylesTextInput();

    const removeAllFromString = (value: string, valueToRemove: string) => {
      if (value) {
        return value.split(valueToRemove).join("");
      }
      return value;
    };

    const localeData = useSelector((state: IRootState) => state.auth.localeData);
    const { thousandSeparator, currencySymbol, percentageSymbol, decimalSeparator } = localeData;

    const _fieldSize: FieldSizes = useMemo(() => (isMobile ? FieldSizes.MOBILE : FieldSizes.WEB), [isMobile]);

    const deviceClassName = clsx(["DLUI_TextField", isMobile ? "MOBILE" : "WEB"]);

    const _InputProps: InputProps = useMemo(() => {
      const props: Partial<InputProps> = !multiline
        ? { ...fieldSizesMap[_fieldSize].InputProps, className: deviceClassName }
        : {};

      return { ...InputProps, ...props };
    }, [InputProps, deviceClassName]);

    const _InputLabelProps: InputLabelProps = useMemo(() => {
      return {
        margin: _fieldSize === FieldSizes.MOBILE ? undefined : "dense",
        ...InputLabelProps,
        ...(!multiline ? { ...fieldSizesMap[_fieldSize].InputLabelProps } : {}),
        className: clsx([!multiline ? deviceClassName : null, InputLabelProps.className])
      };
    }, [InputLabelProps, warning]);

    const _onBlur = (e: React.ChangeEvent<any>) => {
      let nextValue = e.target.value;
      if (formatType === "currency" && nextValue !== "") {
        nextValue = removeAllFromString(nextValue, currencySymbol);
        nextValue = removeAllFromString(nextValue, thousandSeparator);
        nextValue = parseFloat(decimalSeparator === "," ? nextValue.replace(",", ".") : nextValue);
      }
      if ((formatType === "number" || formatType === "plain-number") && nextValue !== "") {
        try {
          nextValue = removeAllFromString(nextValue, thousandSeparator);
          if (decimalScale && decimalScale > 0) {
            nextValue = parseFloat(nextValue);
          } else {
            nextValue = parseInt(nextValue);
          }
        } catch (e) {
          nextValue = 0;
        }
      }
      if (formatType === "percent" && nextValue !== "") {
        nextValue = removeAllFromString(nextValue, percentageSymbol);
        nextValue = parseFloat(nextValue);
      }

      if (ignoreSpaces) {
        nextValue = removeAllFromString(nextValue, " ");
      }

      setTimeout(() => {
        onBlur?.(nextValue);
      }, 0);
    };

    const _onKeyUp = (e: any) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        if (onSubmit) {
          onSubmit(e);
        }
      }
    };

    const onBlurCallback = useMemo(() => {
      let onBlurCallback: any = _onBlur;
      if (asyncAutoCompleteInstance) {
        onBlurCallback = undefined;
      }

      return onBlurCallback;
    }, []);

    const handleInput = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (typeof characterLimit !== "number" || characterLimit < 0 || e.target.value.length < characterLimit) {
          return;
        }

        e.target.value = e.target.value.slice(0, characterLimit);
      },
      [characterLimit]
    );

    const adornmentInputProps = useMemo(() => {
      if (icons) {
        const props: Partial<InputBaseProps> = {};

        if (icons.start) {
          const Icon = icons.start.Icon;

          const IconStartAdornment = (
            <Icon
              onMouseDown={icons.start.onClick}
              style={{
                ...icons.start.style,
                cursor: icons.start.onClick ? "pointer" : "default",
                width: icons.start.size || "auto",
                height: icons.start.size || "auto"
              }}
            />
          );

          props.startAdornment = icons.start.popoverHoverProps ? (
            <PopoverHover {...icons.start.popoverHoverProps}>{IconStartAdornment}</PopoverHover>
          ) : (
            IconStartAdornment
          );
        }

        if (icons.end) {
          const Icon = icons.end.Icon;

          const IconEndAdornment = (
            <Icon
              onMouseDown={icons.end.onClick}
              style={{
                ...icons.end.style,
                cursor: icons.end.onClick ? "pointer" : "default",
                width: icons.end.size || "auto",
                height: icons.end.size || "auto"
              }}
            />
          );

          props.endAdornment = icons.end.popoverHoverProps ? (
            <PopoverHover {...icons.end.popoverHoverProps}>{IconEndAdornment}</PopoverHover>
          ) : (
            IconEndAdornment
          );
        }

        return props;
      }
    }, [icons]);

    return (
      <TextField
        data-cy={dataCy}
        {...rest}
        error={Boolean(rest.error) || warning}
        InputProps={{
          onFocus,
          onKeyUp: _onKeyUp,
          onClick,
          onChange,
          onInput: handleInput,
          onBlur: onBlurCallback,
          autoComplete: autoComplete || "off",
          ..._InputProps,
          ...adornmentInputProps
        }}
        value={value}
        InputLabelProps={_InputLabelProps}
        id={id || name}
        variant={variant}
        label={label}
        // Setting inline width is not recommended, as it creates an unnecessary
        // override while we can simply use the `fullWidth` prop.
        style={{ width: "100%", ...style }}
        classes={{
          root: clsx([
            classes.root,
            warning ? classes.warning : "",
            deviceClassName,
            multiline ? "DLUI_Multiline" : "",
            multiLineSize ? "DLUI_MultiLine_" + multiLineSize : "",
            value !== "" ? "notEmpty" : "",
            rest?.classes?.root
          ])
        }}
        type={TextFieldType || "string"}
        required={required}
        multiline={multiline}
        disabled={disabled}
        inputRef={ref}
      />
    );
  }
);
TextInput.displayName = "TextInput";

export default TextInput;
