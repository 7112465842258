import { makeStyles } from "@material-ui/core";
import type { CSSProperties, PropsWithChildren } from "react";
import type { FilterOption } from "../../../filterPanel/filterInputs/types";
import { FilterPanelContext } from "../context";
import clsx from "clsx";
import { DataCy } from "@doorloop/dto";

export interface RootProps<TQuery> {
  filterOptions: Array<FilterOption<TQuery>>;
  style?: CSSProperties;
  omitInternalStyle?: boolean;
}

const useStyles = makeStyles({
  root: {
    width: "100%",
    padding: "16px 0 16px",
    display: "flex",
    flexDirection: "column",
    gap: 16
  }
});

export function Root<TQuery>({
  filterOptions,
  children,
  style,
  omitInternalStyle = false
}: PropsWithChildren<RootProps<TQuery>>) {
  const classes = useStyles();
  return (
    <div
      className={clsx("border-box", "no-print", {
        [classes.root]: !omitInternalStyle
      })}
      style={style}
      data-cy={DataCy.DLUI.filters.filterPanel}
    >
      <FilterPanelContext.Provider value={{ filterOptions }}>{children}</FilterPanelContext.Provider>
    </div>
  );
}
