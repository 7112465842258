import type { UnitAllocationWithPercentageDto, UnitDto } from "@doorloop/dto";
import { AllocationStrategy } from "@doorloop/dto";

const equalizeAllocationsInPlace = (allocations: UnitAllocationWithPercentageDto[]): void => {
  const totalSum = allocations.reduce((acc, allocation) => acc + (allocation.percentage || 0), 0);
  if (totalSum > 100) {
    const percentageDiff = totalSum - 100;
    // find the first allocation that is bigger than the difference
    const allocationToReduce = allocations.find(
      (allocation) => allocation.percentage && allocation.percentage > percentageDiff
    );
    if (allocationToReduce && allocationToReduce.percentage) {
      allocationToReduce.percentage = roundTo2Places(allocationToReduce.percentage - percentageDiff);
    }
  }
};

const roundTo2Places = (num: number): number => Math.round(num * 100) / 100;

export const customAllocateUsingStrategy = ({
  strategy,
  blankAllocations,
  includeVacantUnits,
  unitMap
}: {
  strategy: AllocationStrategy;
  blankAllocations: UnitAllocationWithPercentageDto[];
  includeVacantUnits: boolean;
  unitMap: Map<string, UnitDto>;
}): UnitAllocationWithPercentageDto[] => {
  let totalUnits = blankAllocations.length;
  if (!includeVacantUnits) {
    totalUnits = blankAllocations.filter((allocation) => allocation.leaseId).length;
  }

  const totalPercentage = 100.0;
  const percentagePerUnit = roundTo2Places(totalPercentage / totalUnits);

  const totalSqft = blankAllocations.reduce((acc, allocation) => {
    const unit = unitMap.get(allocation.unitId);
    if (!unit) return acc;
    if (!includeVacantUnits && !allocation.leaseId) return acc;

    return acc + (unit.size ?? 0);
  }, 0);

  let newAllocations: UnitAllocationWithPercentageDto[] = [];

  if (strategy === AllocationStrategy.MANUAL) {
    console.error("Manual allocation strategy should not be used here");
    return blankAllocations;
  }

  if (strategy === AllocationStrategy.EVEN) {
    newAllocations = blankAllocations.map((allocation) => {
      if (!includeVacantUnits && !allocation.leaseId) return { ...allocation, percentage: 0.0 };
      return {
        ...allocation,
        percentage: percentagePerUnit
      };
    });
  }

  if (strategy === AllocationStrategy.BY_SIZE) {
    // if totalSqft is 0, we don't want to divide by 0
    if (totalSqft === 0) return blankAllocations;

    newAllocations = blankAllocations.map((allocation) => {
      if (!includeVacantUnits && !allocation.leaseId) return { ...allocation, percentage: 0.0 };
      const unitSize = unitMap.get(allocation.unitId)?.size ?? 0;
      const percentage = roundTo2Places(Math.max(0, Math.min(100, (unitSize / totalSqft) * totalPercentage)));

      return {
        ...allocation,
        percentage
      };
    });
  }

  equalizeAllocationsInPlace(newAllocations);
  return newAllocations;
};
