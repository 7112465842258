import { atom, useAtomValue } from "jotai";
import type { FC } from "react";
import React, { useEffect, useRef } from "react";
import { getCSSVarDefinition } from "./dynamicStylesConfig";
import type { DynamicStyles } from "styles/dynamicStyling/dynamicStyles.types";

export const dynamicStyleAtom = atom<DynamicStyles>("default");

export const DynamicStyle: FC = ({}) => {
  const style = useAtomValue(dynamicStyleAtom);
  const stylesheet = useRef<HTMLStyleElement>();
  useEffect(() => {
    if (!stylesheet.current) {
      stylesheet.current = document.createElement("style");
      document.head.appendChild(stylesheet.current);
    }
    stylesheet.current.innerHTML = getCSSVarDefinition(style);
  }, [style]);
  return <></>;
};
