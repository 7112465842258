import type { FC } from "react";
import React from "react";
import { BackgroundImageView, View } from "DLUI/view";
import Text from "DLUI/text";
import { useTranslation } from "react-i18next";

interface ComponentProps {
  imgUrl: string;
  imgSize?: number;
  stepHeader?: string;
  StepDescription?: FC;
}

const DefaultImageSize = 125;

export const SetupStep: React.FC<ComponentProps> = ({
  imgSize,
  stepHeader,
  StepDescription,
  imgUrl
}: ComponentProps) => {
  const { t } = useTranslation();
  return (
    <View flexDirection={"row"}>
      <BackgroundImageView
        publicFileName={`url(${imgUrl})`}
        backgroundSize={"contain"}
        width={imgSize || DefaultImageSize}
        height={imgSize || DefaultImageSize}
      />

      <View flex={1} height={"100%"} justifyContent={"center"} paddingLeft={20}>
        <View>
          <Text fontSize={18} fontWeight={700} value={stepHeader}></Text>
        </View>
        {StepDescription ? <StepDescription /> : null}
      </View>
    </View>
  );
};
