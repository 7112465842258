import type { CSSProperties } from "react";
import React from "react";
import clsx from "clsx";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@material-ui/core";

import TextEllipsis from "DLUI/text/textEllipsis";
import { Icon } from "DLUI/icon";
import { View } from "DLUI/view";
import Text from "DLUI/text";

import type { UseEntitySelectorProps } from "./useEntitySelector";
import { useEntitySelector } from "./useEntitySelector";
import "./styles.css";
import { DataCy } from "@doorloop/dto";

export interface EntitySelectorProps extends UseEntitySelectorProps {
  error?: string;
  styleContainer?: CSSProperties;
  disabled?: boolean;
  dataCy?: string;
  required?: boolean;
}

const EntitySelector = ({
  type,
  label,
  valueText,
  onClick,
  error,
  styleContainer,
  isLoading,
  disabled,
  dataCy,
  required = true
}: EntitySelectorProps) => {
  const {
    marginFormControl,
    deviceClassName,
    classesTextInput,
    classesEntitySelector,
    labelId,
    propsText,
    propsType,
    isOpenMenu
  } = useEntitySelector({ type, label, valueText, isLoading, disabled, onClick });

  return (
    <FormControl
      className={clsx([deviceClassName, classesTextInput.root])}
      variant={"outlined"}
      style={{ width: "100%", ...styleContainer }}
      error={Boolean(error)}
      onClick={isLoading || disabled ? undefined : propsType.onClick}
      margin={marginFormControl}
      required={required}
      data-cy={dataCy}
    >
      <InputLabel id={labelId} shrink={Boolean(valueText)} className={clsx([deviceClassName, classesTextInput.label])}>
        {propsType.label}
      </InputLabel>

      <Select
        id={"EntitySelect" + type}
        open={isOpenMenu}
        classes={{ select: deviceClassName }}
        labelId={labelId}
        label={valueText ? propsType.label : undefined}
        value={valueText ?? ""}
        renderValue={() => (
          <TextEllipsis
            {...propsText}
            className={disabled ? classesEntitySelector.valueTextDisabled : classesEntitySelector.valueText}
            text={valueText ?? ""}
          />
        )}
        startAdornment={<Icon {...propsType.propsIconStart} marginLeft={12} />}
        IconComponent={propsType.IconComponent}
        disabled={disabled}
      >
        {propsType.items.map((item) => (
          <MenuItem
            key={item.id}
            value={item.id}
            onClick={() => {
              item.onClick(item.id);
            }}
            data-cy={DataCy.DLUI.listItem.listItem}
          >
            <View flexDirection={"row"} gap={12} alignItems={"center"} autoWidth>
              <Icon
                Source={item.Icon}
                size={propsType.propsIconStart.size}
                className={propsType.propsIconStart.className}
              />

              <Text {...propsText} className={classesEntitySelector.valueText}>
                {item.title}
              </Text>
            </View>
          </MenuItem>
        ))}
      </Select>

      {error ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  );
};

export { EntitySelector };
