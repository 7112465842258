import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      marginRight: 20,
      marginTop: 20,
      width: 50,
      height: 50,
      backgroundColor: theme.palette.screenBackground.light
    },
    ownerWrapper: {
      marginRight: 10,
      marginBottom: 10
    },
    iconButtonContainer: {
      borderRadius: 10
    },
    inputMargin: {
      marginTop: 20,
      paddingRight: 20,
      paddingLeft: 20
    },
    renewalOfferLineContainer: {
      backgroundColor: theme.palette.screenBackground.light,
      borderRadius: 10
    },
    separationLine: {
      borderTop: "1px solid rgba(124, 132, 172, 0.2);",
      height: 1,
      width: "97%"
    },
    gridPadding: {
      padding: "0px 10px"
    },
    selectedOwnerButtons: {
      display: "flex",
      justifyContent: "flex-start"
    },
    selectedOwnerButtonWrapper: {
      marginLeft: "10px",
      marginBottom: 10
    },
    selectedTenantButton: {
      display: "flex",
      flexDirection: "column",
      width: 320,
      minHeight: 170,
      borderRadius: 10,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginLeft: 35,
      position: "relative",
      backgroundColor: theme.palette.screenBackground.light,
      marginTop: 20,
      overflow: "hidden"
    },
    selectedOwnerButton: {
      display: "flex",
      flexDirection: "column",
      width: 200,
      minHeight: 175,
      borderRadius: 10,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      position: "relative",
      backgroundColor: theme.palette.white.main,
      borderColor: theme.palette.screenBackground.light,
      border: "solid 2px",
      overflow: "hidden"
    },
    AddSquareBigButton: {
      "&:hover": {
        backgroundColor: theme.palette.white.main
      },
      display: "flex",
      width: 134,
      height: 179,
      border: "1px solid",
      borderRadius: 10,
      borderColor: theme.palette.white.dark,
      justifyContent: "space-evenly",
      alignItems: "center",
      position: "relative",
      backgroundColor: "transparent",
      overflow: "hidden",
      marginBottom: 10
    },
    deleteIconContainer: {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 30,
      height: 30,
      right: 5,
      top: 5,
      zIndex: 9
    }
  })
);
