import { CriminalReportBlueIcon } from "@/assets";
import { StatsGreenIcon } from "@/assets";
import { AlertYellowIcon } from "@/assets";
import type { SVGIconComponent } from "@/assets";
import AppStrings from "locale/keys";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { rentalApplicationsApi } from "api/rentalApplicationsApi";
import { DateFormats, TransunionReportTypes } from "@doorloop/dto";

interface ReportLine {
  icon: SVGIconComponent;
  title: string;
  description?: string;
  expiresText?: string;
  type: TransunionReportTypes;
}

interface ReportData {
  reportIcon: SVGIconComponent;
  reportTitle: string;
  reportDescription: string;
}

const REPORT_TYPE_TO_DATA_MAP: Record<TransunionReportTypes, ReportData> = {
  [TransunionReportTypes.Credit]: {
    reportIcon: StatsGreenIcon,
    reportTitle: AppStrings.Common.CreditReport,
    reportDescription: AppStrings.Common.CreditReportDescription
  },
  [TransunionReportTypes.Eviction]: {
    reportIcon: AlertYellowIcon,
    reportTitle: AppStrings.Common.EvictionReport,
    reportDescription: AppStrings.Common.EvictionReportDescription
  },
  [TransunionReportTypes.Criminal]: {
    reportIcon: CriminalReportBlueIcon,
    reportTitle: AppStrings.Common.CriminalReport,
    reportDescription: AppStrings.Common.CriminalReportDescription
  }
};

const EXPIRATION_DAYS = 10;

export const useRentalApplicationReports = () => {
  const { t } = useTranslation();

  const getReportDetails = (report?: TransunionReportTypes) => REPORT_TYPE_TO_DATA_MAP[report || "Criminal"];

  const getExpiresText = (screeningRequestReportsExpireAt?: Date) => {
    const duration = moment.duration(moment(screeningRequestReportsExpireAt).diff(moment()));
    const days = duration.asDays();

    if (days < EXPIRATION_DAYS) {
      return `${t(AppStrings.Common.ExpiresIn)} ${days} ${t(AppStrings.Common.Days)}`;
    }
    return `${t(AppStrings.Common.ExpiresAt)}: ${moment(screeningRequestReportsExpireAt).format(DateFormats.AMERICAN_DATE).toString()}`;
  };

  const getReportLinesForRentalApplication = async (
    applicationId: string,
    screeningRequestReportsExpireAt?: Date
  ): Promise<ReportLine[]> => {
    try {
      const response = await rentalApplicationsApi.getAvailableReports(applicationId);

      if (!response.status || !response.data) {
        return [];
      }

      return response.data.map((report): ReportLine => {
        const reportData = getReportDetails(report);

        return {
          title: reportData.reportTitle,
          icon: reportData.reportIcon,
          description: reportData.reportDescription,
          expiresText: getExpiresText(screeningRequestReportsExpireAt),
          type: report
        };
      });
    } catch (e) {
      return [];
    }
  };

  return {
    getReportDetails,
    getExpiresText,
    getReportLinesForRentalApplication
  };
};
