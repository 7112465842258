import type { UnitAllocationWithPercentageDto } from "@doorloop/dto";
import { AllocationStrategy, CustomAllocationDto, createValidator } from "@doorloop/dto";
import { DialogFrame } from "DLUI/dialogs";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { ArticleIdsEnum, HelpTypeEnum } from "DLUI/screen/helpPanel/types";
import type { ApiResult } from "api/apiResult";
import { customAllocationsApi } from "api/customAllocationsApi";
import { propertiesApi } from "api/propertiesApi";
import type { FormikContextType, FormikHelpers, FormikProps } from "formik";
import { Formik } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router";
import type { FrameType } from "../components/dialogFrame";
import { getDialogFrameDimension } from "../components/dialogFrame";
import { AllCustomAllocationsView } from "./allCustomAllocationsView";
import { EditCreateCustomAllocationView } from "./editCreateCustomAllocationView";
import { DeleteCustomAllocationConfirmationView } from "./deleteCustomAllocationConfirmationView";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";

interface ComponentProps {
  onBackdropClick: () => void;
}

enum DialogStage {
  ALL_CUSTOM_ALLOCATIONS = 0,
  EDIT_CREATE_CUSTOM_ALLOCATION = 1,
  DELETE_CUSTOM_ALLOCATION = 2
}

const HelpPanel: HelpPanelProps = {
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Settings.GeneralSettings.CustomAllocations.HelpPanel.LearnMore,
      articleId: ArticleIdsEnum.CUSTOM_ALLOCATIONS
    }
  ],
  description: AppStrings.Settings.GeneralSettings.CustomAllocations.CustomAllocationsSubTitle
};

const CustomAllocationsDialog: React.FC<ComponentProps> = ({ onBackdropClick }) => {
  const [viewIndex, setViewIndex] = useState<DialogStage>(DialogStage.ALL_CUSTOM_ALLOCATIONS);
  const { propertyId } = useParams<{ propertyId: string }>();
  const [errors, setErrors] = useState<string[]>([]);
  const [noUnitsRemaining, setNoUnitsRemaining] = useState<boolean>(false);
  const [hasTriedToSubmit, setHasTriedToSubmit] = useState<boolean>(false);

  const defaultInitialValues = new CustomAllocationDto({
    allocationDescription: "",
    allocationName: "",
    allocations: [],
    propertyId,
    strategy: AllocationStrategy.MANUAL
  });

  /**
   * The initial values are for editing a single custom allocation.
   * They serve no purpose for the "All Custom Allocations" view.
   */
  const [initialValues, setInitialValues] = useState<CustomAllocationDto>(defaultInitialValues);

  const propertyName = useMemo(() => propertiesApi.getItemFromDictionary(propertyId)?.name ?? "", [propertyId]);

  const onCloseRequested = () => {
    if (!noUnitsRemaining && viewIndex === DialogStage.EDIT_CREATE_CUSTOM_ALLOCATION) {
      setViewIndex(DialogStage.ALL_CUSTOM_ALLOCATIONS);
      return;
    }
    onBackdropClick();
  };

  const resetFormAndGoToEdit = (formikContext: FormikContextType<CustomAllocationDto>) => {
    setHasTriedToSubmit(false);
    setInitialValues(defaultInitialValues);
    formikContext.resetForm();
    setErrors([]);

    // Formik takes a while to reset the form, so we need to wait a bit before changing the view.
    setTimeout(() => {
      setViewIndex(DialogStage.EDIT_CREATE_CUSTOM_ALLOCATION);
    }, 100);
  };

  const onNoUnitsFound = (formikContext: FormikContextType<CustomAllocationDto>) => {
    setNoUnitsRemaining(true);
    resetFormAndGoToEdit(formikContext);
  };

  const onCreateNewClicked = (formikContext: FormikContextType<CustomAllocationDto>) => {
    setNoUnitsRemaining(false);
    resetFormAndGoToEdit(formikContext);
  };

  const onSaveButtonClicked = async (
    values: CustomAllocationDto,
    formikHelpers: FormikHelpers<CustomAllocationDto>
  ) => {
    try {
      setHasTriedToSubmit(true);

      // go through the values and make sure each allocation has a percentage
      // if the percentage is undefined, set it to 0
      const validValues = {
        ...values,
        allocations: values.allocations.map((allocation) => {
          if (allocation.percentage === undefined) {
            return { ...allocation, percentage: 0 };
          }
          return allocation;
        })
      };

      let response: ApiResult<CustomAllocationDto>;
      if (validValues.id?.length) {
        response = await customAllocationsApi.update(validValues.id, validValues);
      } else {
        response = await customAllocationsApi.create(validValues);
      }
      if (response.statusCode === 200) {
        setViewIndex(DialogStage.ALL_CUSTOM_ALLOCATIONS);
      } else {
        setErrors((prev) => [...prev, response.message]);
      }
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  const onUnitAllocationsDataReceived = (unitAllocations: UnitAllocationWithPercentageDto[]) => {
    setInitialValues((prev) => {
      return {
        ...prev,
        allocations: unitAllocations
      };
    });
  };

  const onEditAllocationClicked = (customAllocation: CustomAllocationDto) => {
    setHasTriedToSubmit(false);
    setInitialValues(customAllocation);
    setViewIndex(DialogStage.EDIT_CREATE_CUSTOM_ALLOCATION);
  };

  const onAllocationDeleted = () => {
    setInitialValues(defaultInitialValues);
    setErrors([]);
    setViewIndex(DialogStage.ALL_CUSTOM_ALLOCATIONS);
  };

  const onAllocationDeleteDismissed = () => {
    setViewIndex(DialogStage.EDIT_CREATE_CUSTOM_ALLOCATION);
  };

  const renderActionPanelButtons = (formikProps: FormikProps<CustomAllocationDto>) => (
    <FormActionButtons
      propsActionPanel={{
        editMode: viewIndex === DialogStage.EDIT_CREATE_CUSTOM_ALLOCATION && Boolean(formikProps.values.id),
        customDeleteText: AppStrings.Settings.GeneralSettings.CustomAllocations.DeleteAllocation,
        onDeleteButtonPress: () => {
          setViewIndex(DialogStage.DELETE_CUSTOM_ALLOCATION);
        }
      }}
      propsSubButton={{ onClick: onCloseRequested, isLoading: formikProps.isSubmitting }}
      propsMainButton={{
        type: "cta",
        props: {
          onClick: () => {
            setHasTriedToSubmit(true);
            formikProps.submitForm();
          },
          isLoading: formikProps.isSubmitting
        }
      }}
    />
  );

  const frameType = useMemo((): FrameType => {
    if (viewIndex === DialogStage.ALL_CUSTOM_ALLOCATIONS || viewIndex === DialogStage.DELETE_CUSTOM_ALLOCATION) {
      return "topPanel";
    }
    return "sectionTitleFrame";
  }, [viewIndex]);

  const viewMap: Record<DialogStage, JSX.Element> = {
    [DialogStage.ALL_CUSTOM_ALLOCATIONS]: (
      <AllCustomAllocationsView
        onEditAllocationClicked={onEditAllocationClicked}
        propertyName={propertyName}
        propertyId={propertyId}
        onCreateNewClicked={onCreateNewClicked}
        onNoUnitsFound={onNoUnitsFound}
      />
    ),
    [DialogStage.EDIT_CREATE_CUSTOM_ALLOCATION]: (
      <EditCreateCustomAllocationView
        onUnitAllocationsDataReceived={onUnitAllocationsDataReceived}
        propertyId={propertyId}
        errors={hasTriedToSubmit ? errors : []}
      />
    ),
    [DialogStage.DELETE_CUSTOM_ALLOCATION]: (
      <DeleteCustomAllocationConfirmationView
        didFinishOperation={onAllocationDeleted}
        didPressDismissButton={onAllocationDeleteDismissed}
      />
    )
  };

  const validateForm = (values: CustomAllocationDto) => {
    // clear errors
    setErrors([]);
    const errors = createValidator(CustomAllocationDto)(values);
    if (!_.isEmpty(errors)) {
      return errors;
    }

    const to2DecimalPlaces = (num: number): number => Math.round(num * 100) / 100;
    const sumOfAllocations = to2DecimalPlaces(
      values.allocations.reduce((acc, curr) => acc + to2DecimalPlaces(Number(curr?.percentage ?? 0)), 0)
    );

    if (sumOfAllocations <= 0) {
      setErrors([AppStrings.Allocations.Errors.SumOfAllocationsMustBeGreaterThanZero]);
      return { errors };
    }

    if (
      Number.isNaN(sumOfAllocations) ||
      sumOfAllocations === Infinity ||
      sumOfAllocations === -Infinity ||
      sumOfAllocations > 100
    ) {
      setErrors([AppStrings.Allocations.Errors.SumOfAllocationsMustBeLessThanOrEqualToOneHundred]);
      return { errors };
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSaveButtonClicked} enableReinitialize validate={validateForm}>
      {(formikProps: FormikProps<CustomAllocationDto>) => (
        <DialogFrame
          onCloseButtonClick={onBackdropClick}
          renderView={() => viewMap[viewIndex]}
          title={AppStrings.Settings.GeneralSettings.CustomAllocations.CustomAllocationsTitle}
          width={getDialogFrameDimension("width", 1000)}
          height={getDialogFrameDimension("height", 1000)}
          numViews={Object.keys(viewMap).length}
          activeView={viewIndex}
          RenderActionPanelButtons={() => renderActionPanelButtons(formikProps)}
          frameType={frameType}
          helpPanel={HelpPanel}
        />
      )}
    </Formik>
  );
};

export default CustomAllocationsDialog;
