import type { ElementType } from "react";
import React, { useMemo, useState } from "react";
import clsx from "clsx";
import type { PropTypes } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { useStylesTextInput } from "DLUI/form/textField/textInput";
import type { TextComponentProps } from "DLUI/text/text";
import Text from "DLUI/text/text";
import type { IconProps } from "DLUI/icon";
import { Icon } from "DLUI/icon";

import { useUniqueId } from "../../../../../hooks/useUniqueId";
import type { SVGIconComponent } from "../../../../../assets";
import { IconDown, IconRight } from "../../../../../assets";
import AppStrings from "../../../../../locale/keys";

import { stylesEntitySelector } from "./styles";
import { EntityType } from "./enums";
import { MAP_BY_TYPE, PAYEE_ITEMS } from "./utils";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";
import { useUserType } from "../../../../../hooks/useUserType";
import { filterObjectsByUserType } from "../../../../../utils/userTypesUtils";
import type { PersonType } from "@doorloop/dto";

interface MenuItem {
  id: PersonType;
  title: string;
  Icon: SVGIconComponent;
  onClick: (id: PersonType) => void;
}

interface PropsType {
  label: string;
  propsIconStart: IconProps;
  IconComponent: ElementType;
  onClick?: () => void;
  items: MenuItem[];
}

export interface UseEntitySelectorProps {
  type: EntityType;
  valueText?: string;
  label?: string;
  onClick?: (payeeType?: PersonType) => void;
  isLoading?: boolean;
  disabled?: boolean;
}

const useEntitySelector = ({ type, valueText, label, onClick, isLoading, disabled }: UseEntitySelectorProps) => {
  const { t } = useTranslation();
  const { isHOAUser } = useUserType();

  const { isMobile } = useResponsiveHelper();

  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const classesTextInput = useStylesTextInput();
  const classesEntitySelector = stylesEntitySelector();

  const labelId = useUniqueId();

  const { deviceClassName, propsText, marginFormControl } = useMemo(() => {
    let marginFormControl: PropTypes.Margin | undefined;
    let className: string;
    const propsText: TextComponentProps = {
      fontWeight: 400,
      marginRight: 12
    };

    if (isMobile) {
      marginFormControl = undefined;
      className = "MOBILE";

      propsText.fontSize = 16;
    } else {
      marginFormControl = "dense";
      className = "WEB";

      propsText.fontSize = 14;
    }

    const deviceClassName = clsx(["DLUI_EntitySelector", className]);

    return { deviceClassName, propsText, marginFormControl };
  }, [isMobile]);

  const propsType: PropsType = useMemo(() => {
    const propsIconStart: Partial<IconProps> = {
      size: 20,
      className: disabled ? classesEntitySelector.valueIconDisabled : classesEntitySelector.valueIcon
    };

    let prefixLabel: string;
    let IconComponent: ElementType;

    if (valueText) {
      prefixLabel = "";
      IconComponent = () =>
        isLoading ? (
          <CircularProgress
            size={propsIconStart.size}
            style={{ marginRight: 12 }}
            className={propsIconStart.className}
          />
        ) : disabled ? (
          <div />
        ) : (
          <Text {...propsText} className={classesEntitySelector.buttonText}>
            {t(AppStrings.Common.Change)}
          </Text>
        );
    } else {
      prefixLabel = t(AppStrings.Common.Select) + " ";
      IconComponent = () =>
        isLoading ? (
          <CircularProgress
            size={propsIconStart.size}
            style={{ marginRight: 12 }}
            className={propsIconStart.className}
          />
        ) : (
          <Icon {...propsIconStart} marginRight={12} Source={type === EntityType.PAYEE ? IconDown : IconRight} />
        );
    }

    const { labelTranslationKey, IconStartSource } = MAP_BY_TYPE[type];

    const _label = prefixLabel + (label ?? t(labelTranslationKey));
    let _onClick = onClick;
    let items: MenuItem[] = [];

    if (type === EntityType.PAYEE) {
      const handleClick: MenuItem["onClick"] = (id) => {
        setIsOpenMenu(false);
        onClick?.(id);
      };

      _onClick = () => setIsOpenMenu((prevState) => !prevState);
      const allowedPayeeItems = !isHOAUser ? PAYEE_ITEMS : filterObjectsByUserType(PAYEE_ITEMS, "HOAUser");
      items = allowedPayeeItems.map<MenuItem>((item) => {
        return {
          id: item.id,
          Icon: item.Icon,
          title: t(item.titleTranslationKey),
          onClick: handleClick
        };
      });
    }

    return {
      label: _label,
      IconComponent,
      items,
      onClick: _onClick,
      propsIconStart: {
        ...propsIconStart,
        Source: IconStartSource
      }
    };
  }, [isLoading, disabled, type, label, valueText, propsText, onClick]);

  return {
    marginFormControl,
    deviceClassName,
    classesTextInput,
    classesEntitySelector,
    labelId,
    propsText,
    propsType,
    isOpenMenu
  };
};

export { useEntitySelector };
