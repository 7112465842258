"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentLinkedTransactionDto = void 0;
const class_validator_1 = require("class-validator");
const isSmallerThan_validator_1 = require("../../common/validators/isSmallerThan.validator");
class PaymentLinkedTransactionDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.PaymentLinkedTransactionDto = PaymentLinkedTransactionDto;
__decorate([
    (0, class_validator_1.IsNumber)({ maxDecimalPlaces: 2 }),
    (0, class_validator_1.Min)(0),
    (0, isSmallerThan_validator_1.IsSmallerThan)("balance", {
        message: "amount must be smaller than balance"
    }),
    __metadata("design:type", Number)
], PaymentLinkedTransactionDto.prototype, "amount", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], PaymentLinkedTransactionDto.prototype, "linkedTransaction", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], PaymentLinkedTransactionDto.prototype, "linkedTransactionLine", void 0);
