import Routes from "./routes";
import AppStrings from "../../locale/keys";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";
import { lazy } from "react";

const WelcomeWizard_Step1 = lazy(() => import("screens/authScreen/welcomeWizard/welcomeWizard_Step1"));
const WelcomeWizard_Step2 = lazy(() => import("screens/authScreen/welcomeWizard/welcomeWizard_Step2"));
const WelcomeWizard_Step3 = lazy(() => import("screens/authScreen/welcomeWizard/welcomeWizard_Step3"));
const WelcomeWizard_Step4 = lazy(() => import("screens/authScreen/welcomeWizard/welcomeWizard_Step4"));

const WelcomeRouter = () => (
  <PageRouter>
    <LazyRoute
      path={Routes.WELCOME_WIZARD_STEP1}
      render={(routeProps) => <WelcomeWizard_Step1 {...routeProps} documentTitle={AppStrings.Auth.CreateYourAccount} />}
      exact
    />
    <LazyRoute
      path={Routes.WELCOME_WIZARD_STEP2}
      render={(routeProps) => <WelcomeWizard_Step2 {...routeProps} documentTitle={AppStrings.Auth.AccountInfo} />}
      exact
    />
    <LazyRoute
      path={Routes.WELCOME_WIZARD_STEP3}
      render={(routeProps) => <WelcomeWizard_Step3 {...routeProps} documentTitle={AppStrings.Auth.AccountInfo} />}
      exact
    />
    <LazyRoute
      path={Routes.WELCOME_WIZARD_STEP4}
      render={(routeProps) => <WelcomeWizard_Step4 {...routeProps} documentTitle={AppStrings.Auth.AccountInfo} />}
      exact
    />
  </PageRouter>
);

export default WelcomeRouter;
