import React from "react";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import { View } from "DLUI/view";
import InputBase from "@material-ui/core/InputBase";
import { CircularProgress } from "@material-ui/core";
import { isMacOs } from "react-device-detect";

import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import Text from "DLUI/text";

interface ComponentProps {
  onChange: (nextValue: string) => void;
  searchQuery: string;
  requestInProgress?: boolean;
  onSearchClick: (query: string) => void;
}

const SearchInput: React.FC<ComponentProps> = ({
  onChange,
  searchQuery,
  requestInProgress,
  onSearchClick
}: ComponentProps) => {
  const { t } = useTranslation();
  const { isTabletOrMobile, isMobile } = useResponsiveHelper();

  const _onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <View maxWidth={700} height={"80px"} justifyContent={"center"} className={"DLUI_searchInput"}>
      <View height={"100%"} alignItems={"center"} flexDirection={"row"} noWrap>
        <View width={isTabletOrMobile ? "80%" : "90%"}>
          <InputBase
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                onSearchClick(searchQuery);
              }
            }}
            placeholder={t(AppStrings.Common.GlobalSearch.SearchEverywhere)}
            inputProps={{ "aria-label": "GlobalSearch" }}
            onChange={_onChange}
            autoFocus
          />
        </View>
        {requestInProgress ? (
          <CircularProgress style={{ width: 20, height: 20, marginLeft: 20, color: "#fff" }} />
        ) : (
          !isMobile && (
            <Text
              marginLeft={isMacOs ? 0 : -30}
              fontSize={26}
              color={"secondary-gray"}
              value={isMacOs ? "⌘K" : "CTRL+K"}
            />
          )
        )}
      </View>
      <View height={3} className={searchQuery !== "" ? "focus_separator" : "white_separator"} />

      <View height={3} className={"separator"} />
    </View>
  );
};

export default SearchInput;
