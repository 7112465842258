import type { AccountIdentityType, LeaseTenantDto } from "@doorloop/dto";
import {
  createValidator,
  DuplicateDialogButtonLabelValues,
  LinkedResourceDto,
  LinkedResourceType,
  TaskDateType,
  TaskDto,
  TaskStatus,
  TaskType,
  TenantRequestType,
  WorkOrderPermissionToEnter
} from "@doorloop/dto";
import { filesApi } from "api/filesApi";
import { tasksApi } from "api/tasksApi";
import { DialogFrame, DialogSearchPanel, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import { renderReferenceString } from "utils/strings";
import { SeparationLine } from "DLUI/separatorView";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { Formik, getIn } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LeaseTenantsList } from "screens/leases/leases/leaseDetails/tenants";
import SelectableLeaseTenantsListItem from "screens/tenants/tenantsList/selectableLeaseTenantsListItem";
import type { IRootState } from "store";
import EntryPermission from "../entryPermission";
import TaskInfo from "../taskInfo";
import TenantSelection from "./tenantSelection";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { getDialogFrameDimension, getDialogSelectionHeight } from "../../components/dialogFrame";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { cleanTaskDataForDuplicateMode, DUPLICATE_MODE_QUERY_PARAMS } from "DLUI/dialogs/duplicateModeHelper";
import { QueryParams } from "utils/queryParams";
import { useAnalyticsService } from "hooks/useAnalyticsService";
import { DialogHeaderActionButtons } from "DLUI/actionButtons/dialogHeaderActionButtons";
import DeleteConfirmation from "../../components/deleteConfirmation";

interface ComponentProps {
  onClose: (task?: TaskDto) => void;
  onBackdropClick: () => void;
  dialogTitle: string;
  apiObjectId?: string;
  initialTaskData?: TaskDto;
}

let formikGlobalRef: FormikProps<any> | null = null;

const dialogFrameHeight = 980;

export const getFormikRef = () => formikGlobalRef;

const DialogFrameDefaultWidth = Math.min(window.innerWidth, 880);
const SelectionDialogDefaultWidth = Math.min(window.innerWidth, 1100);

const TenantRequestDialog: React.FC<ComponentProps> = ({
  onClose,
  onBackdropClick,
  dialogTitle,
  apiObjectId,
  initialTaskData
}: ComponentProps) => {
  const queryParams = new QueryParams();
  const { t } = useTranslation();
  const queryTaskId = queryParams.get(DUPLICATE_MODE_QUERY_PARAMS.taskId) as string | undefined;
  const { taskId: taskIdParam, tenantId } = useParams<any>();
  const { screenContainerPadding } = useResponsiveHelper();
  const [dialogFrameWidth, setDialogFrameWidth] = useState<number>(DialogFrameDefaultWidth);
  const taskId = apiObjectId || taskIdParam || queryTaskId;
  const editMode = Boolean(taskId);
  const duplicateMode = Boolean(queryTaskId);

  const { dispatchAnalytics } = useAnalyticsService();

  const [viewIndex, setViewIndex] = useState(0);
  const userData = useSelector((state: IRootState) => state.auth.currentLoginResponse);
  const [taskData, setTaskData] = useState<TaskDto | undefined>(initialTaskData);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    editMode ? DialogState.Show : DialogState.Hidden
  );
  const [renderSelectionList, setRenderSelectionList] = useState<boolean>(false);

  const [selectTenantFilterObj, setSelectTenantFilterObj] = useState({
    filter_text: ""
  });

  const [currentTenantId, setCurrentTenantId] = useState<string | undefined>(
    initialTaskData?.requestedByTenant || tenantId
  );
  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);

  const [showTenantSelectionError, setShowOwnerSelectionError] = useState<boolean>(false);
  const [tenantSelectionErrorText, setOwnerSelectionErrorText] = useState<string>("");

  const [loadingDialogSuccessText, setLoadingDialogSuccessText] = useState<string>("");
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState<string>("");

  const isTaskTypeTenantRequest = formikGlobalRef?.values?.type === TaskType.TENANT_REQUEST;
  const isTaskCreatedByATenant = (formikGlobalRef?.values?.createdByType as AccountIdentityType) === "TENANT";

  const displayTenantRequestViewOnlyDetails = isTaskCreatedByATenant && isTaskTypeTenantRequest;

  useEffect(() => {
    if (editMode) {
      loadTaskData();
    }
  }, [duplicateMode]);

  const showErrorMessage = (message: string) => {
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(message);
  };

  const loadTaskData = async () => {
    if (taskId) {
      setLoadingDialogLoadingText(t(AppStrings.Tasks.InternalTaskDialog.LoadingTaskData));
      setLoadingDialogState(DialogState.Show);
      const response = await tasksApi.get(taskId).catch((e) => {
        showErrorMessage(e);
      });
      if (response && response.data) {
        const data = duplicateMode ? cleanTaskDataForDuplicateMode(response.data) : response.data;
        setCurrentTenantId(data.requestedByTenant);
        setLoadingDialogState(DialogState.Success);
        setTaskData(data);
        setLoadingDialogState(DialogState.Hidden);

        const shouldSetTenantRequestTypeTouchedForOldRequests = !formikGlobalRef?.values?.tenantRequestType;

        if (shouldSetTenantRequestTypeTouchedForOldRequests) {
          formikGlobalRef?.setFieldTouched("tenantRequestType");
        }

        return;
      }
      if (response) {
        showErrorMessage(response.message);
      }

      showErrorMessage(t(AppStrings.Common.NetworkErrorSubTitle));
    }
  };

  const didPressDismissButton = () => {
    onBackdropClick();
  };

  const renderTenantSelectionList = () => {
    const didChangeSearchQuery = (value: string) => {
      setSelectTenantFilterObj({
        filter_text: value
      });
    };

    const didSelectTenant = (selectedTenant: LeaseTenantDto) => {
      if (selectedTenant) {
        setDialogFrameWidth(DialogFrameDefaultWidth);
        setTimeout(() => {
          setViewIndex(0);

          setCurrentTenantId(selectedTenant.tenant?.id);
          if (formikGlobalRef) {
            let linkedResource;
            if (selectedTenant.lease) {
              linkedResource = new LinkedResourceDto(selectedTenant.lease, LinkedResourceType.Lease);
            }

            formikGlobalRef.setFieldValue("linkedResource", linkedResource);
            formikGlobalRef.setFieldValue("requestedByTenant", selectedTenant.tenant?.id);
            formikGlobalRef.setFieldValue("property", selectedTenant.property);
            formikGlobalRef.setFieldValue("units", selectedTenant.units);
            formikGlobalRef.setFieldValue("units", selectedTenant.units);

            isValidForm(formikGlobalRef);
          }
        }, 300);
      }
    };

    const _didSelectTenant = (tenant) => {
      didSelectTenant(tenant);
    };

    return (
      <View paddingLeft={screenContainerPadding} paddingRight={screenContainerPadding}>
        <View flexDirection={"row"}>
          <View autoWidth>
            <DialogSearchPanel
              placeholder={AppStrings.Tenants.Screen.SearchPlaceHolder}
              onChange={didChangeSearchQuery}
              borderRadius={30}
            />
          </View>
        </View>

        {renderSelectionList ? (
          <View
            marginTop={20}
            alignItems={"center"}
            id={"selectableTenantListContainer"}
            height={getDialogSelectionHeight(dialogFrameHeight)}
            overflow={"scroll"}
          >
            <LeaseTenantsList
              filterObj={selectTenantFilterObj}
              ListItem={SelectableLeaseTenantsListItem}
              fullWidth
              stickyHeaderId={"selectableTenantListContainer"}
              scrollableTarget={"selectableTenantListContainer"}
              didSelectTenantItem={_didSelectTenant}
              selectableItem
              removeDefaultBottomPadding
            />
          </View>
        ) : null}
      </View>
    );
  };

  const renderView = ({ index }: any) => {
    if (index === 0) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={t(AppStrings.Tasks.InternalTaskDialog.LoadingTaskDataSuccessText)}
            onRetryButtonPress={loadTaskData}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }
      return renderForm();
    }
    if (index === 1) {
      return renderTenantSelectionList();
    }

    if (index === 2) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          loadingText={loadingDialogLoadingText}
          errorText={loadingDialogErrorText}
          successText={loadingDialogSuccessText}
          onRetryButtonPress={didPressSaveButton}
          didPressDismissButton={didPressDismissButton}
        />
      );
    }

    if (index === 3) {
      return (
        <DeleteConfirmation
          deleteConfirmationText={t(AppStrings.Common.DeleteRequestConfirmation)}
          apiMethod={tasksApi}
          didPressDismissButton={() => {
            setViewIndex(0);
          }}
          didFinishOperation={onClose}
          transactionId={taskId}
          attachments={attachments}
        />
      );
    }

    return <div />;
  };

  const initFormValues = (): TaskDto => {
    if (taskData) {
      return taskData;
    }
    const taskDto = new TaskDto();

    if (userData) {
      taskDto.assignedToUsers = [userData.id];
    }
    taskDto.type = TaskType.TENANT_REQUEST;
    taskDto.dateType = TaskDateType.DATE;
    taskDto.reference = renderReferenceString();
    taskDto.status = TaskStatus.NOT_STARTED;
    taskDto.notifyAssignees = true;
    taskDto.notifyTenant = true;
    taskDto.entryPermission = WorkOrderPermissionToEnter.NOT_APPLICABLE;
    taskDto.requestedByTenant = tenantId;
    taskDto.tenantRequestType = TenantRequestType.GENERAL;

    return taskDto;
  };

  const validateForm = createValidator(TaskDto);

  const didPressSelectTenant = () => {
    setDialogFrameWidth(SelectionDialogDefaultWidth);
    setTimeout(() => {
      setViewIndex(1);
      setRenderSelectionList(true);
    }, 500);
  };

  const onFileReceived = (files: FileListItemProps[]) => {
    setAttachments(files);
  };

  const renderForm = () => {
    const formInitialValues = initFormValues();
    return (
      <Formik initialValues={formInitialValues} onSubmit={() => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;
          return (
            <View paddingLeft={screenContainerPadding} paddingRight={screenContainerPadding} noWrap>
              {!displayTenantRequestViewOnlyDetails && (
                <View marginTop={20}>
                  <TenantSelection
                    didPressSelectTenant={didPressSelectTenant}
                    tenantId={currentTenantId}
                    errorTex={showTenantSelectionError ? tenantSelectionErrorText : undefined}
                  />
                </View>
              )}
              <View>
                <TaskInfo
                  showNotifyTenant
                  formikRef={formik}
                  disableRecurring
                  displayRequestViewOnlyDetails={displayTenantRequestViewOnlyDetails}
                />
              </View>
              <View>
                <SeparationLine width={"100%"} height={1} marginTop={20} />
                <EntryPermission />
                <FormAttachments
                  onFileReceived={onFileReceived}
                  files={attachments}
                  marginTop={20}
                  editMode={editMode}
                  resourceId={taskData ? taskData.id! : undefined}
                  resourceType={LinkedResourceType.Task}
                  isRequired={false}
                />
                <View height={20} />
              </View>
            </View>
          );
        }}
      </Formik>
    );
  };

  const _onBackdropClick = () => {
    if (viewIndex > 0) {
      setDialogFrameWidth(DialogFrameDefaultWidth);
      setTimeout(() => {
        setViewIndex(viewIndex - 1);
      }, 300);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const currentTitle = useMemo(() => {
    if (viewIndex === 0) {
      return dialogTitle;
    }
    if (viewIndex === 1) {
      return AppStrings.Tenants.MoveTenants.SelectTenant;
    }

    if (viewIndex === 2) {
      return AppStrings.Owners.Screen.AddNewOwner;
    }
    return "";
  }, [viewIndex]);

  const duplicateModeTitle = useMemo(
    () => (duplicateMode ? `${t(AppStrings.Tasks.Screen.NewTenantRequest)} (${t(AppStrings.Common.Duplicated)})` : ""),
    [duplicateMode, t]
  );

  const isValidForm = async (formikRef: FormikProps<TaskDto>) => {
    formikRef.setFieldTouched("subject");
    formikRef.setFieldTouched("status");
    formikRef.setFieldTouched("requestedByTenant");
    formikRef.setFieldTouched("linkedResource.resourceId");

    const errors = (await formikRef.validateForm()) as any;
    const tenantId = getIn(formikRef.values, "requestedByTenant");
    if (tenantId === undefined) {
      setOwnerSelectionErrorText(t(AppStrings.Tasks.TenantRequestDialog.SelectTenantValidationText));
      setShowOwnerSelectionError(true);
      return false;
    }
    setShowOwnerSelectionError(false);

    return _.isEmpty(errors);
  };

  const didPressSaveButton = async () => {
    if (formikGlobalRef !== null) {
      const isValid = await isValidForm(formikGlobalRef);
      if (isValid) {
        if (editMode && !duplicateMode) {
          await updateTask(formikGlobalRef.values);
        } else {
          await createTask(formikGlobalRef.values);
        }
      }
    }
  };

  const createTask = async (values: TaskDto) => {
    setViewIndex(2);
    setLoadingDialogState(DialogState.Show);
    const response = await tasksApi
      .create(values, {
        translationKey: AppStrings.Toasts.custom.tasks?.[TaskType.TENANT_REQUEST]?.POST
      })
      .catch(() => {
        setLoadingDialogErrorText(AppStrings.Common.NetworkErrorTitle);
        setLoadingDialogState(DialogState.Error);
      });
    if (response && response.data) {
      await filesApi.uploadFiles(attachments, response.data.id!, LinkedResourceType.Task).catch((error: string) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });
      onClose(response.data);
    } else if (response && response.message) {
      setLoadingDialogErrorText(response.message);
      setLoadingDialogState(DialogState.Error);
    }
  };

  const updateTask = async (values: TaskDto) => {
    setLoadingDialogLoadingText(t(AppStrings.Tasks.InternalTaskDialog.UpdatingTask));
    setViewIndex(2);
    setLoadingDialogState(DialogState.Show);
    const response = await tasksApi.update(taskId!, values).catch((e) => {
      setLoadingDialogErrorText(e);
      setLoadingDialogState(DialogState.Error);
    });
    if (response && response.data) {
      await filesApi.uploadFiles(attachments, response.data.id!, LinkedResourceType.Task).catch((error: string) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });
      setLoadingDialogSuccessText(t(AppStrings.Tasks.InternalTaskDialog.UpdateTaskSuccessText));
      setLoadingDialogState(DialogState.Success);
      onClose(response.data);
    } else if (response) {
      setLoadingDialogErrorText(response.message);
      setLoadingDialogState(DialogState.Error);
    }
  };

  const handleDuplicateClick = () => {
    queryParams.set(DUPLICATE_MODE_QUERY_PARAMS.taskId, taskId);
    queryParams.historyPush();

    dispatchAnalytics("button_click", {
      label: DuplicateDialogButtonLabelValues.DUPLICATE_TENANT_REQUEST
    });
  };
  const renderHeaderActionButtons = () => (
    <DialogHeaderActionButtons
      onDuplicateClick={handleDuplicateClick}
      hideDeleteButton
      hideDuplicateButton={duplicateMode || !editMode}
    />
  );

  const renderActionPanelButtons = () => (
    <FormActionButtons
      propsSubButton={{ onClick: _onBackdropClick }}
      propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
      propsActionPanel={{
        onDeleteButtonPress: () => {
          setViewIndex(3);
        },
        editMode
      }}
    />
  );

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      if (loadingDialogState !== DialogState.Hidden) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }
    if (viewIndex === 1) {
      return "topPanel";
    }
    if (viewIndex === 2) {
      return "contentOnly";
    }
    if (viewIndex === 3) {
      return "contentOnly";
    }

    return "contentOnly";
  }, [viewIndex, loadingDialogState]);

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={duplicateModeTitle || currentTitle}
      width={getDialogFrameDimension("width", dialogFrameWidth)}
      height={getDialogFrameDimension("height", dialogFrameHeight)}
      renderView={renderView}
      numViews={4}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      RenderHeaderActionButtons={renderHeaderActionButtons}
      frameType={frameType}
      keepViewsMounted={false}
    />
  );
};

export default TenantRequestDialog;
