"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FederalTaxInfoDto = void 0;
const class_validator_1 = require("class-validator");
const address_dto_1 = require("./address.dto");
const class_transformer_1 = require("class-transformer");
const federalIdTypes_1 = require("./federalIdTypes");
class FederalTaxInfoDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.FederalTaxInfoDto = FederalTaxInfoDto;
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], FederalTaxInfoDto.prototype, "addressFor1099SameAsPrimaryAddress", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => !x.addressFor1099SameAsPrimaryAddress),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => address_dto_1.AddressDto),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", address_dto_1.AddressDto)
], FederalTaxInfoDto.prototype, "addressFor1099", void 0);
__decorate([
    (0, class_validator_1.IsDefined)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], FederalTaxInfoDto.prototype, "taxpayerName", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], FederalTaxInfoDto.prototype, "taxpayerId", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(federalIdTypes_1.TaxpayerIdType),
    __metadata("design:type", String)
], FederalTaxInfoDto.prototype, "taxpayerIdType", void 0);
