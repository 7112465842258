import { useMutation } from "@tanstack/react-query";

import { createMutationStore } from "../createMutationStore";
import { createQueryStore } from "../createQueryStore";
import { vendorBillPaymentsApi } from "api/outstandingTransactionsApi";

export const vendorBillPaymentsStore = {
  queries: createQueryStore("vendor-bill-payments", vendorBillPaymentsApi),
  mutations: {
    ...createMutationStore("vendor-bill-payments", vendorBillPaymentsApi),
    useVoidCheck: () =>
      useMutation({
        mutationKey: ["vendor-bill-payments-void-check"],
        mutationFn: async (transactionId: string) => await vendorBillPaymentsApi.voidCheck(transactionId)
      })
  }
};
