import type { GetAllBaseQueryResponse } from "@doorloop/dto";
import type { TenantPortalGetAllRecurringPaymentsQuery } from "@doorloop/dto";
import { RestApiBase } from "api/restApiBase";
import type { TenantPortalRecurringPaymentDto } from "@doorloop/dto";
import { TenantPortalServerRoutes } from "@doorloop/dto";
import { apiHelper, type ApiToastsProps } from "../apiHelper";
import type { ApiResult } from "../apiResult";

export class TenantPortalRecurringPaymentsApi extends RestApiBase<
  TenantPortalRecurringPaymentDto,
  TenantPortalRecurringPaymentDto
> {
  constructor() {
    super(TenantPortalServerRoutes.RECURRING_PAYMENTS);
    this.restRoute = TenantPortalServerRoutes.RECURRING_PAYMENTS;
  }

  async create(data: TenantPortalRecurringPaymentDto): Promise<ApiResult<TenantPortalRecurringPaymentDto>> {
    return await apiHelper.axiosPost<TenantPortalRecurringPaymentDto>({ url: this.restRoute + "/", data });
  }

  async getAll(
    query: TenantPortalGetAllRecurringPaymentsQuery
  ): Promise<ApiResult<GetAllBaseQueryResponse<TenantPortalRecurringPaymentDto>>> {
    return await apiHelper.axiosGet<GetAllBaseQueryResponse<TenantPortalGetAllRecurringPaymentsQuery>>(
      this.restRoute + "/?",
      query
    );
  }

  async cancelRecurringPayment(
    id: string,
    toasts?: ApiToastsProps<TenantPortalRecurringPaymentDto>
  ): Promise<ApiResult<TenantPortalRecurringPaymentDto>> {
    return await apiHelper.axiosPost<TenantPortalRecurringPaymentDto>({
      url: `${this.restRoute}/${id}/cancel`,
      toasts
    });
  }
}

export const tenantPortalRecurringPaymentsApi = new TenantPortalRecurringPaymentsApi();
