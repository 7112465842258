import { IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { DeleteIcon } from "assets/icons";
import { TextField } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FieldArrayRenderProps, FormikProps } from "formik";
import { FastField } from "formik";
import AppStrings from "locale/keys";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import MakeStyles from "./styles";
import FieldWrapper from "DLUI/form/textField/fieldWrapper";

import { useResponsiveHelper } from "../../../contexts/responsiveContext";

interface ComponentProps {
  index: number;
  arrayHelpers: FieldArrayRenderProps;
  formikRef: FormikProps<any>;
}

const EmergencyContactItem: React.FC<ComponentProps> = ({ index, formikRef, arrayHelpers }: ComponentProps) => {
  const classes = MakeStyles();
  const { t } = useTranslation();
  const { screenContainerPadding } = useResponsiveHelper();
  const didPressDeletePhoneButton = () => {
    formikRef.setFieldValue(`emergencyContacts[${index}].shouldShow`, false, false);
  };
  const renderDeleteButton = () => {
    if (index === 0) {
      return null;
    }
    return (
      <Fragment>
        <IconButton onClick={didPressDeletePhoneButton}>
          <DeleteIcon />
        </IconButton>
        <Text value={AppStrings.Common.Delete} color={"error"} fontSize={16} />
      </Fragment>
    );
  };

  const getCurrentItemNumber = (): number => {
    let numOfContacts = 1;
    for (let i = 0; i < index; i++) {
      if (formikRef.values.emergencyContacts[i].shouldShow) {
        numOfContacts++;
      }
    }

    return numOfContacts;
  };

  return (
    <View
      width={"100%"}
      showAnimation={"fade-in"}
      hideAnimation={"fade-out"}
      shouldShow={formikRef.values.emergencyContacts[index].shouldShow}
    >
      <View
        paddingLeft={screenContainerPadding}
        paddingRight={screenContainerPadding}
        flex={1}
        alignItems={"center"}
        className={classes.emergencyContactsItemContainer}
        borderRadius={10}
      >
        <View flexDirection={"row"}>
          <View height={50} justifyContent={"center"} width={"auto"}>
            <Text marginLeft={20} color={"black"} fontSize={16} fontWeight={700}>
              {t(AppStrings.Tenants.NewTenant.EmergencyContact)} {getCurrentItemNumber()}
            </Text>
          </View>
          <View
            alignItems={"center"}
            paddingRight={20}
            justifyContent={"flex-end"}
            flexDirection={"row"}
            flex={1}
            height={50}
          >
            {renderDeleteButton()}
          </View>
        </View>
        <SeparationLine width={"95%"} height={1} />
        <Grid container>
          <Grid item xs={12} md={6} lg={6}>
            <FieldWrapper type={"endElement"}>
              <FastField
                component={TextField}
                label={t(AppStrings.Tenants.NewTenant.EmergencyContactName)}
                name={`emergencyContacts[${index}].name`}
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <FieldWrapper type={"startElement"}>
              <FastField
                component={TextField}
                label={t(AppStrings.Tenants.NewTenant.EmergencyContactRelationship)}
                name={`emergencyContacts[${index}].relationship`}
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <FieldWrapper type={"endElement"}>
              <FastField
                component={TextField}
                label={t(AppStrings.Tenants.NewTenant.Email)}
                name={`emergencyContacts[${index}].email`}
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <FieldWrapper type={"startElement"}>
              <FastField
                component={TextField}
                label={t(AppStrings.Tenants.NewTenant.Phone)}
                name={`emergencyContacts[${index}].phone`}
              />
            </FieldWrapper>
          </Grid>
        </Grid>
        <View height={20} />
      </View>
    </View>
  );
};

export default EmergencyContactItem;
