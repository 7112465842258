import type { FC } from "react";
import React, { useState } from "react";
import { Notification } from "DLUI/notification/notification";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { SendOutgoingPayment } from "screens/outgoingPayments/actions/sendOutgoingPayment";
import type { PaymentNumberLabelColors } from "screens/outgoingPayments/paymentDialogNotification/settings";
import { statusToSettings } from "screens/outgoingPayments/paymentDialogNotification/settings";
import type { PrintChecksReportItemDto } from "@doorloop/dto";
import type { CheckbookPaymentStatusEnum } from "@doorloop/dto";
import type { CheckbookPaymentMethodEnum } from "@doorloop/dto";
import ColorsEnum from "../../../../utils/colorsEnum";

const labelColorsMap: Record<PaymentNumberLabelColors, { textColor: ColorsEnum; backgroundColor: ColorsEnum }> = {
  blue: {
    textColor: ColorsEnum.Black,
    backgroundColor: ColorsEnum.BrightBlueFaded
  },
  green: {
    textColor: ColorsEnum.DarkGreen,
    backgroundColor: ColorsEnum.BrightGreen
  },
  yellow: {
    textColor: ColorsEnum.Black,
    backgroundColor: ColorsEnum.YellowFaded
  },
  red: {
    textColor: ColorsEnum.Red,
    backgroundColor: ColorsEnum.RedBright
  }
};

interface Props {
  dto: PrintChecksReportItemDto;
  payeeSupportsEPay: boolean | null;
  closeCurrentDialog: () => void;
  journalEntryId: string;
  status?: CheckbookPaymentStatusEnum;
  method?: CheckbookPaymentMethodEnum;
  paymentNumber?: string;
}

export const OutgoingPaymentNotification: FC<Props> = ({
  dto,
  payeeSupportsEPay,
  closeCurrentDialog,
  journalEntryId,
  method,
  status,
  paymentNumber
}: Props) => {
  const [showSendPayment, setShowSendPayment] = useState<boolean>(false);
  const settings = statusToSettings[!payeeSupportsEPay ? "Missing Info" : status || "Not Sent"](method);
  if (!settings) {
    return null;
  }

  const { IconSource, background, title, description, paymentNumberLabelColors } = settings;

  const labelColors = labelColorsMap[paymentNumberLabelColors];

  return payeeSupportsEPay !== null ? (
    <View marginTop={20}>
      <Notification
        LabelComponent={() => (
          <View flex={1}>
            <View flexDirection={"row"} noWrap alignItems={"center"}>
              <Text bold value={title} />
              {paymentNumber && (
                <View
                  autoWidth
                  marginLeft={20}
                  paddingLeft={15}
                  paddingRight={15}
                  borderRadius={40}
                  justifyContent={"center"}
                  backgroundColorEnum={labelColors.backgroundColor}
                  height={26}
                >
                  <Text colorEnum={labelColors.textColor}>#{paymentNumber}</Text>
                </View>
              )}
            </View>
            <Text value={description} />
          </View>
        )}
        IconComponent={IconSource}
        // AppendComponent={() =>
        //   dto.payToResourceId && dto.payToResourceType ? (
        //     <ActionButton
        //       closeCurrentDialog={closeCurrentDialog}
        //       triggerSend={() => setShowSendPayment(true)}
        //       journalEntryId={journalEntryId}
        //       payToResourceId={dto.payToResourceId}
        //       payToResourceType={dto.payToResourceType}
        //     />
        //   ) : null
        // }
        background={background}
        iconSize={70}
        minHeight={110}
      />
      {showSendPayment && <SendOutgoingPayment paymentDto={dto} onClose={() => setShowSendPayment(false)} />}
    </View>
  ) : null;
};
