import { View } from "DLUI/view";
import React from "react";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import type { DtoListFormRenderProps } from "DLUI/form/dto/DtoFieldArray";
import type { DependantDto } from "@doorloop/dto";
import { RentalApplicationFieldSettings } from "@doorloop/dto";
import ControlledFieldsWrapper from "shared/controlledFieldsWrapper";

export function DependentFields({ name, stepSettingsDto, isControlled }: DtoListFormRenderProps<DependantDto>) {
  const { inputRightPadding } = DialogsHelper();

  return (
    <View flexDirection={"row"} gap="20px 0" marginRight={inputRightPadding * -1}>
      <ControlledFieldsWrapper
        initFieldsSettings={RentalApplicationFieldSettings.dependants.fields}
        fieldsSettings={stepSettingsDto?.fieldsSettings}
        groupSettings={RentalApplicationFieldSettings.dependants.groups}
        formName={name}
        isControlled={isControlled}
      />
    </View>
  );
}
