import type {
  ConversationParticipantDto,
  PhoneNumberDto,
  PhoneNumberSubscriptionStatusDto,
  PhoneNumberSuggestedAreaCodesDto,
  PhoneNumberValidateAreaCodeDto,
  WelcomeSmsSelectionDto
} from "@doorloop/dto";
import { ServerRoutes } from "@doorloop/dto";

import type { ApiResult } from "api/apiResult";
import type { ApiToastsProps } from "api/apiHelper";
import { apiHelper } from "api/apiHelper";

class PhoneNumbersApi {
  async getCurrentPhoneNumber(): Promise<ApiResult<PhoneNumberDto>> {
    return await apiHelper.axiosGet(ServerRoutes.PHONE_NUMBERS_CURRENT_PHONE_NUMBER);
  }

  async sendWelcomeSms(data: WelcomeSmsSelectionDto, toasts?: ApiToastsProps<unknown>): Promise<ApiResult<unknown>> {
    return await apiHelper.axiosPost({
      url: ServerRoutes.PHONE_NUMBERS_POST_SEND_WELCOME_SMS,
      data,
      toasts
    });
  }

  async getConversationParticipantSubscriptionStatus(
    data: ConversationParticipantDto
  ): Promise<ApiResult<PhoneNumberSubscriptionStatusDto>> {
    return await apiHelper.axiosPost({
      url: ServerRoutes.PHONE_NUMBERS_POST_CONVERSATION_PARTICIPANT_SUBSCRIPTION_STATUS,
      data
    });
  }

  async getSuggestedAreaCodes(): Promise<ApiResult<PhoneNumberSuggestedAreaCodesDto>> {
    return await apiHelper.axiosGet(ServerRoutes.PHONE_NUMBERS_SUGGESTED_AREA_CODES);
  }

  async postValidateAreaCode(data: PhoneNumberValidateAreaCodeDto): Promise<ApiResult<unknown>> {
    return await apiHelper.axiosPost({
      url: ServerRoutes.PHONE_NUMBERS_POST_VALIDATE_AREA_CODE,
      data
    });
  }
}

export const phoneNumbersApi = new PhoneNumbersApi();
