import type { FC } from "react";
import React, { useMemo } from "react";
import { View } from "DLUI/view";
import { CheckbookPaymentStatusEnum } from "@doorloop/dto";
import type { SVGIconComponent } from "assets/icons";
import { GreenCheckIcon, OverdueCircleIcon, RedCircleXIcon, YellowCircleExclamationIcon } from "assets/icons";
import { Icon } from "DLUI/icon";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import ColorsEnum from "../../../utils/colorsEnum";

interface Props {
  status?: CheckbookPaymentStatusEnum;
}

interface Settings {
  IconSource: SVGIconComponent;
  color: ColorsEnum;
  label: string;
}
const defaultSettings: Settings = {
  label: AppStrings.Leases.NewLease.LeaseStatus.NotSent,
  IconSource: YellowCircleExclamationIcon,
  color: ColorsEnum.Yellow
};
const settingsMap: Record<CheckbookPaymentStatusEnum, Settings> = {
  [CheckbookPaymentStatusEnum.PAID]: {
    IconSource: GreenCheckIcon,
    color: ColorsEnum.DarkGreen,
    label: AppStrings.Common.OutgoingPayments.Paid
  },
  [CheckbookPaymentStatusEnum.IN_PROCESS]: {
    IconSource: GreenCheckIcon,
    color: ColorsEnum.DarkGreen,
    label: AppStrings.Common.OutgoingPayments.Sent
  },
  [CheckbookPaymentStatusEnum.UNPAID]: {
    IconSource: GreenCheckIcon,
    color: ColorsEnum.DarkGreen,
    label: AppStrings.Common.OutgoingPayments.Sent
  },
  [CheckbookPaymentStatusEnum.VOID]: {
    IconSource: RedCircleXIcon,
    color: ColorsEnum.Red,
    label: AppStrings.Common.OutgoingPayments.Voided
  },
  [CheckbookPaymentStatusEnum.EXPIRED]: {
    IconSource: OverdueCircleIcon,
    color: ColorsEnum.Red,
    label: AppStrings.Common.OutgoingPayments.Expired
  },
  [CheckbookPaymentStatusEnum.PRINTED]: {
    IconSource: GreenCheckIcon,
    color: ColorsEnum.DarkGreen,
    label: AppStrings.Common.OutgoingPayments.Printed
  },
  [CheckbookPaymentStatusEnum.MAILED]: {
    IconSource: GreenCheckIcon,
    color: ColorsEnum.DarkGreen,
    label: AppStrings.Common.OutgoingPayments.Mailed
  },
  [CheckbookPaymentStatusEnum.FAILED]: {
    IconSource: OverdueCircleIcon,
    color: ColorsEnum.Red,
    label: AppStrings.Common.OutgoingPayments.Failed
  },
  [CheckbookPaymentStatusEnum.REFUNDED]: {
    IconSource: OverdueCircleIcon,
    color: ColorsEnum.Red,
    label: AppStrings.Common.OutgoingPayments.Refunded
  }
};

export const OutgoingPaymentStatus: FC<Props> = ({ status }: Props) => {
  const { IconSource, color, label } = useMemo(() => (status ? settingsMap[status] : defaultSettings), [status]);
  return (
    <View flexDirection={"row"} noWrap alignItems={"center"}>
      <Icon Source={IconSource} size={25} marginRight={5} />
      <Text value={label} colorEnum={color} fontSize={16} bold />
    </View>
  );
};
