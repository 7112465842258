"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.conversationMessageCreditsMap = void 0;
const conversationMessageType_1 = require("./conversationMessageType");
exports.conversationMessageCreditsMap = {
    [conversationMessageType_1.ConversationMessageType.SMS]: 1,
    [conversationMessageType_1.ConversationMessageType.MMS]: 5,
    [conversationMessageType_1.ConversationMessageType.EMAIL]: 1,
    [conversationMessageType_1.ConversationMessageType.NOTE]: 0,
    [conversationMessageType_1.ConversationMessageType.VOICE]: 1,
    [conversationMessageType_1.ConversationMessageType.SYSTEM]: 0
};
