import React from "react";
import { View } from "DLUI/view";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDraggableInPortal } from "../../../hooks/useDraggableInPortal";

export interface DraggableListItemBase {
  uniqueId: string;
}

interface ComponentProps<DraggableListItem extends DraggableListItemBase> {
  dataSource: DraggableListItem[];
  ListItemComponent: React.FC<DraggableListItem>;
  width?: number | string;
  onDataSourceChange: (nextDataSource: DraggableListItem[]) => void;
  itemMarginTop?: number;
}

const DraggableList = <DraggableListItem extends DraggableListItemBase>({
  dataSource,
  ListItemComponent,
  width = "100%",
  onDataSourceChange,
  itemMarginTop = 20
}: ComponentProps<DraggableListItem>) => {
  const getListStyle = (isDraggingOver) => {
    return {
      background: "#F5F6FA",
      padding: 0,
      width
    };
  };

  const getItemStyle = (isDragging, draggableStyle) => {
    return {
      userSelect: "none",
      padding: 0,
      margin: `${itemMarginTop}px 0 0 0`,
      background: "#F5F6FA",
      ...draggableStyle
    };
  };

  const reorder = (list: DraggableListItem[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const nextDataSource = reorder(dataSource, result.source.index, result.destination.index);

    onDataSourceChange(nextDataSource);
  };

  const onDeleteItem = (itemIndex: number) => {
    if (dataSource[itemIndex]) {
      const nextDataSource = [...dataSource];
      nextDataSource.splice(itemIndex, 1);
      onDataSourceChange(nextDataSource);
    }
  };

  if (dataSource && dataSource.length > 0) {
    const renderDraggable = useDraggableInPortal();

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              {dataSource.map((currentItem, index) => (
                <Draggable key={currentItem.uniqueId} draggableId={currentItem.uniqueId} index={index}>
                  {renderDraggable((provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <ListItemComponent
                        {...currentItem}
                        didPressDeleteIcon={onDeleteItem}
                        itemIndex={index}
                        isDragging={snapshot.isDragging}
                      />
                    </div>
                  ))}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  return <View />;
};

export default DraggableList;
