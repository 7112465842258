import React from "react";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import ColorsEnum from "utils/colorsEnum";
import { SeparationLine } from "DLUI/separatorView";
import { Icon } from "DLUI/icon";
import { BlankLogoIcon, NotificationIcon, UserAccountIcon } from "@/assets";
import { BackgroundImageView } from "DLUI/view";
import { themeBackgroundImage } from "screens/tenantPortal/components/tenantPortalBackgroundView";
import Text from "DLUI/text";
import { useTypedTranslation } from "locale";
import { IMAGE_HEIGHT_IN_MOBILE } from "DLUI/preview/constants";
import { useResponsiveHelper } from "contexts/responsiveContext";
import type { Theme } from "styles/defaultTheme";

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column"
    },
    paddingUrlContainer: {
      paddingTop: ({ websiteVerticalPadding }: { websiteVerticalPadding: number }) => websiteVerticalPadding,
      paddingBottom: ({ websiteVerticalPadding }: { websiteVerticalPadding: number }) => websiteVerticalPadding,
      paddingRight: 8,
      paddingLeft: 8
    },
    paddingLogoContainer: {
      padding: 8
    },
    flexRow: {
      display: "flex",
      flexDirection: "row"
    },
    iconsOpacity: {
      opacity: 0.1
    },
    urlContainer: {
      backgroundColor: ColorsEnum.LightGray
    },
    urlSpan: {
      backgroundColor: ColorsEnum.White,
      paddingTop: 4,
      paddingBottom: 4,
      paddingRight: 8,
      paddingLeft: 8,
      borderRadius: 360,
      overflow: "hidden"
    },
    iconContainer: {
      backgroundColor: ColorsEnum.LightGray,
      gap: 4,
      justifyContent: "space-between"
    },
    logoContainer: {
      backgroundColor: ColorsEnum.White,
      borderRadius: 4,
      justifyContent: "center",
      gap: 5,
      height: 20,
      width: 60,
      [theme.breakpoints.down("sm")]: {
        height: 24,
        width: 72
      }
    },
    iconSpan: {
      gap: 4
    },
    coverContainer: {
      width: "100%",
      height: "100%"
    },
    backgroundCover: {
      color: "transparent",
      opacity: 0.5,
      width: "100%"
    },
    overlay: {
      margin: 16,
      display: "flex",
      flexDirection: "column",
      gap: 8,
      opacity: 0.5
    },
    overlayLine: {
      width: 66,
      height: 9,
      borderRadius: 4,
      backgroundColor: ColorsEnum.White
    },
    overlayFullLine: {
      width: "100%",
      height: 9,
      borderRadius: 4,
      backgroundColor: ColorsEnum.White
    },
    logoText: {
      marginTop: "auto",
      marginBottom: "auto"
    }
  };
});

export interface PreviewThemeForDeviceProps {
  url: string;
  logoUrl: string;
  coverUrl: string;
  backgroundColor: string | undefined;
  backgroundImageDimensions?: { width?: number | string; height?: number | string };
  websiteVerticalPadding?: number;
}

export const PreviewThemeForDevice: React.FC<PreviewThemeForDeviceProps> = ({
  url,
  logoUrl,
  coverUrl,
  backgroundColor,
  backgroundImageDimensions,
  websiteVerticalPadding = 6
}) => {
  const classes = useStyles({ websiteVerticalPadding });
  const isLogoUploaded = Boolean(logoUrl);
  const { t } = useTypedTranslation();
  const { isMobile } = useResponsiveHelper();
  const finalHeight = backgroundImageDimensions?.height ?? IMAGE_HEIGHT_IN_MOBILE;
  const finalWidth = backgroundImageDimensions?.width ?? "100%";

  return (
    <div className={classes.container}>
      <div className={clsx(classes.urlContainer, classes.paddingUrlContainer)}>
        <span className={clsx(classes.urlSpan, classes.flexRow)}>
          <Text
            as="span"
            fontSize={10}
            fontWeight={700}
            value={t("common.tenantPortalPreview.addressPrefix")}
            color="light-black-faded"
            fullWidth={false}
          />
          <Text as="span" fontSize={10} fontWeight={700} value={url} color="secondary-gray" fullWidth={false} />
          <Text
            as="span"
            fullWidth={false}
            fontSize={10}
            fontWeight={700}
            value={t("common.tenantPortalPreview.addressSuffix")}
            color="light-black-faded"
          />
        </span>
      </div>
      <SeparationLine color={ColorsEnum.LightBlackFadedMore} height={1} width={"100%"} />
      <div className={clsx(classes.iconContainer, classes.paddingLogoContainer, classes.flexRow)}>
        {isLogoUploaded ? (
          <BackgroundImageView
            publicFileName={`url(${logoUrl})`}
            height={isMobile ? 24 : 20}
            width={isMobile ? 72 : 60}
            backgroundSize={"cover"}
          />
        ) : (
          <div className={clsx(classes.logoContainer, classes.flexRow)}>
            <Icon Source={BlankLogoIcon} size={14} />
            <Text
              fontSize={isMobile ? 12 : 10}
              fontWeight={700}
              value="LOGO"
              alignSelf="center"
              color="light-black-faded"
              className={classes.logoText}
            />
          </div>
        )}
        <span className={clsx(classes.iconSpan, classes.flexRow, classes.iconsOpacity)}>
          <Icon pathColor="black" Source={NotificationIcon} size={16} marginRight={4} marginLeft={4} />
          <Icon pathColor="black" Source={UserAccountIcon} size={16} marginRight={4} marginLeft={4} />
        </span>
      </div>
      <div className={classes.coverContainer}>
        <BackgroundImageView
          publicFileName={coverUrl ? `url(${coverUrl})` : themeBackgroundImage}
          height={finalHeight}
          width={finalWidth}
          backgroundSize={"cover"}
        >
          <div className={clsx(classes.backgroundCover)} style={{ backgroundColor, height: finalHeight }}>
            <div className={classes.overlay}>
              <div className={classes.overlayLine} />
              <div className={classes.overlayFullLine} />
            </div>
          </div>
        </BackgroundImageView>
      </div>
    </div>
  );
};
