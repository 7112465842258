import type { MerchantAccountIdentityVerificationDto } from "@doorloop/dto";
import CopyToClipboardButton from "DLUI/button/copyToClipboardButton";
import DLButton from "DLUI/button/dlButton";
import { ActionPanel } from "DLUI/dialogs";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import React from "react";
import { useResponsiveHelper } from "contexts/responsiveContext";
import SendLinkMessage from "screens/incomingPayments/identityVerification/sendLinkMessage";
import AppStrings from "locale/keys";
import VerifyIdentityListItem from "screens/incomingPayments/identityVerification/verifyIdentityListItem";

interface Props {
  identityVerificationData?: MerchantAccountIdentityVerificationDto;
  onBackdropClick: () => void;
}

const VerifyIdentity: React.FC<Props> = ({ identityVerificationData, onBackdropClick }: Props) => {
  const { screenContainerPadding } = useResponsiveHelper();

  const didPressVerifyIdentity = () => {
    identityVerificationData?.verification_url && window.open(identityVerificationData?.verification_url);
    onBackdropClick();
  };

  return (
    <View alignItems={"center"} height={"100%"}>
      <View paddingLeft={screenContainerPadding} paddingRight={screenContainerPadding}>
        {identityVerificationData && <SendLinkMessage />}
        {/*<Icon size={100} marginTop={20} Source={IdentityVerificationIcon} />*/}
        <View>
          <Text
            value={AppStrings.Accounts.IdentityVerificationDescription}
            color={"gray"}
            maxWidth={580}
            marginTop={20}
            lineHeight={"24px"}
          />
        </View>
        {identityVerificationData && (
          <>
            <HorizontalSeparationLine marginTop={20} />
            <View>
              <Text bold value={AppStrings.Accounts.OwnerListDescription} marginTop={20} />
            </View>
            <VerifyIdentityListItem identityVerificationData={identityVerificationData} />
          </>
        )}
      </View>
      <View width={"100%"} flex={1} justifyContent={"flex-end"}>
        <View alignItems={"flex-end"} marginBottom={20}>
          <ActionPanel>
            {identityVerificationData?.verification_url && (
              <CopyToClipboardButton stringToCopy={identityVerificationData?.verification_url} marginRight={20} />
            )}
            <DLButton
              onClick={didPressVerifyIdentity}
              actionText={AppStrings.Accounts.VerifyIdentity}
              style={{ minWidth: 185 }}
            />
          </ActionPanel>
        </View>
      </View>
    </View>
  );
};

export default VerifyIdentity;
