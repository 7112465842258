import React, { useState, useLayoutEffect, useRef } from "react";
import { useSpring, animated } from "react-spring";
import Measure from "react-measure";

/**
 * DLUI AnimatableView - gives the abillity to show and hide the component's children with smooth animation
 * @param {AnimatableProp} animateProp - defines the property which going to be animated width / height .
 * @param {boolean} shouldShow - should the component ignore the user clicks and always stay open.
 * @param {ReactNode} children - react node to display with animation.
 */

export enum AnimatableProp {
  Width = "Width",
  Height = "Height"
}

interface ComponentProps {
  shouldShow: boolean;
  children: React.ReactNode;
  animateProp?: AnimatableProp;
  duration?: number;
}

export const AnimatableView: React.FC<ComponentProps> = ({
  children,
  shouldShow,
  animateProp,
  duration
}: ComponentProps) => {
  const ref = useRef(null);
  const [height, setFullHeight] = useState(0);
  const [width, setFullWidth] = useState(0);

  const AnimationConfig = {
    mass: 5,
    tension: 2000,
    friction: 200,
    duration: duration || 300
  };

  useLayoutEffect(() => {
    //@ts-ignore
    setFullHeight(ref.current === null ? 0 : ref.current.clientHeight);
    //@ts-ignore
    setFullWidth(ref.current === null ? 0 : ref.current.clientWidth);
  }, []);

  const heightSpringObject = {
    height: shouldShow ? height : 0,
    opacity: shouldShow ? 1 : 0,
    config: AnimationConfig,
    width: "100%"
  };
  const widthSpringObject = {
    opacity: shouldShow ? 1 : 0,
    config: AnimationConfig,
    width: shouldShow ? width : 0,
    height
  };

  const spring = useSpring(
    animateProp && animateProp === AnimatableProp.Width ? widthSpringObject : heightSpringObject
  );

  const widthStyleObject = {
    overflow: "hidden",
    opacity: spring.opacity,
    height: spring.height,
    //@ts-ignore
    width: !ref.current ? "auto" : spring.width
  };
  const HeightStyleObject = {
    overflow: "hidden",
    opacity: spring.opacity,
    height: !ref.current ? "auto" : spring.height,
    width: "100%"
  };

  return (
    <animated.div
      ref={ref}
      style={animateProp && animateProp === AnimatableProp.Width ? widthStyleObject : HeightStyleObject}
    >
      <Measure
        bounds
        onResize={(contentRect: any) => {
          if (height !== contentRect.bounds.height) {
            setFullHeight(contentRect.bounds.height);
          }
        }}
      >
        {({ measureRef }: any) => <div ref={measureRef}>{children}</div>}
      </Measure>
    </animated.div>
  );
};
