import React, { useEffect, useState } from "react";
import { usersApi } from "api/usersApi";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { SeparationLine } from "DLUI/separatorView";
import AppStrings from "../../../../locale/keys";
import { Grid } from "@material-ui/core";
import { ProfileImage } from "DLUI/profileImage";
import TextEllipsis from "DLUI/text/textEllipsis";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  createdBy?: string;
}

const RequestByUser: React.FC<ComponentProps> = ({ createdBy }: ComponentProps) => {
  const [pictureUrl, setPictureUrl] = useState<string | undefined>();
  const [userName, setUserName] = useState<string | undefined>();
  const [userEmail, setUserEmail] = useState<string | undefined>();
  const { screenContainerPadding, isMobile } = useResponsiveHelper();

  const setUserInfo = async () => {
    if (createdBy) {
      const usersDictionary = await usersApi.getItemFromDictionary(createdBy);
      setPictureUrl(usersDictionary?.pictureUrl);
      setUserName(usersDictionary.name);
      setUserEmail(usersDictionary?.loginEmail);
    }
  };

  useEffect(() => {
    setUserInfo();
  }, []);

  return (
    <View noWrap>
      <SeparationLine width={"100%"} height={1} />
      <Text marginTop={20} value={AppStrings.Tasks.Screen.RequestedBy} fontSize={16} bold />
      <Grid item container xs={12} md={8}>
        <View
          marginTop={20}
          borderRadius={10}
          justifyContent={"center"}
          backgroundColor={"dark"}
          minHeight={isMobile ? 100 : 80}
        >
          <View paddingLeft={screenContainerPadding} paddingRight={screenContainerPadding}>
            <View alignItems={"center"} flexDirection={"row"}>
              <Grid xs={12} sm={6}>
                <View alignItems={"center"} noWrap flexDirection={"row"}>
                  <ProfileImage pictureUrl={pictureUrl} size={35} />
                  <TextEllipsis bold lines={2} marginLeft={10} text={userName || ""} marginRight={20} />

                  {!isMobile && <SeparationLine height={35} width={1} marginRight={20} />}
                </View>
              </Grid>
              <Grid xs={12} sm={6}>
                <Text marginTop={isMobile ? 10 : 0} marginLeft={10} marginRight={20}>
                  {userEmail}
                </Text>
              </Grid>
            </View>
          </View>
        </View>
      </Grid>
      <SeparationLine height={1} width={"100%"} marginTop={20} />
    </View>
  );
};

export default RequestByUser;
