import React, { useMemo, useState } from "react";
import type { GetAllVendorsQuery, VendorDto } from "@doorloop/dto";
import { LinkedResourceDto, LinkedResourceType } from "@doorloop/dto";
import type { FormikProps } from "formik";
import AppStrings from "locale/keys";
import AnimatedContent from "../components/animatedContent";
import DialogFrame, { getDialogFrameDimension } from "../components/dialogFrame";
import { LoadingDialog } from "../index";
import { DialogState } from "../loadingDialog";
import { useTranslation } from "react-i18next";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { vendorsApi } from "api/vendorsApi";
import { VendorSelectionView } from "DLUI/dialogs/vendor/vendorSelectionView";
import type { MenuItem } from "screens/settings/settingsAnimatedContentDialog";

enum DialogViews {
  VendorSelection,
  Loading,
  AddNewVendor
}

export interface ComponentProps {
  didFinishOperation?: (values?: VendorDto) => void;
  dialogState?: DialogState;
  addViewTitle?: string;
  selectViewTitle?: string;
  filterObj?: GetAllVendorsQuery;
  addNewVendorButtonText?: string;
  getFormikInitialValues?: () => VendorDto;
  formikValidation?: (values: VendorDto) => Record<string, string | undefined>;
  sectionItems?: MenuItem[];
  validationMethod?: (formikRef: FormikProps<VendorDto>) => Promise<{ isValid: boolean; errorStepIndex?: number }>;
  onCreateNewVendor?: (values?: VendorDto) => void;
}

export const SelectVendorDialog: React.FC<ComponentProps> = ({
  didFinishOperation,
  dialogState,
  getFormikInitialValues,
  sectionItems,
  formikValidation,
  validationMethod,
  onCreateNewVendor,
  addViewTitle = AppStrings.Vendors.Screen.AddNewVendor,
  selectViewTitle = AppStrings.Vendors.VendorDetails.SelectVendor,
  filterObj
}: ComponentProps) => {
  const { isTabletOrMobile } = useResponsiveHelper();
  const dialogHeight = useMemo(() => getDialogFrameDimension("height", 885), []);
  const dialogWidth = useMemo(() => getDialogFrameDimension("width", 962), []);
  const { t } = useTranslation();
  const [activeView, setActiveView] = useState<number>(DialogViews.VendorSelection);
  const didSelectVendorItem = (selectedItem: VendorDto) => {
    didFinishOperation?.(selectedItem);
  };
  const [currentSelectedMenuIndex, setCurrentSelectedMenuIndex] = useState<number | undefined>();
  const [currentMenuItemTitle, setCurrentMenuItemTitle] = useState<string>("");
  const [pendingFile, setPendingFile] = useState<File | undefined>();
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>(t(AppStrings.Common.GeneralError));

  const didPressAddNewVendor = () => {
    setActiveView(DialogViews.AddNewVendor);
  };

  const uploadFile = async (formikRef: FormikProps<VendorDto>, id: string, onClose: (values?: VendorDto) => void) => {
    setLoadingDialogState(DialogState.Success);
    const linkedResource = new LinkedResourceDto(id, LinkedResourceType.Vendor);

    if (pendingFile) {
      const response = await vendorsApi.uploadPicture(pendingFile, linkedResource, id);

      if (response && response.status) {
        setLoadingDialogState(DialogState.Success);
        onClose(response.data);
      } else {
        formikRef.setSubmitting(false);
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const didCreateNewVendor = (values?: VendorDto) => {
    setActiveView(DialogViews.VendorSelection);

    didFinishOperation?.(values);
    onCreateNewVendor?.(values);
  };

  const create = async (formikRef: FormikProps<VendorDto>) => {
    if (formikRef) {
      const { status, data, message } = await vendorsApi.create(formikRef.values);
      formikRef.setSubmitting(false);

      if (status && data?.id) {
        if (pendingFile) {
          await uploadFile(formikRef, data?.id, didCreateNewVendor);
        } else {
          setLoadingDialogState(DialogState.Success);
          didCreateNewVendor(data);
        }
      } else {
        setLoadingDialogErrorText(message || t(AppStrings.Common.GeneralError));
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const didPressCreateNewVendor = async (formikRef: FormikProps<VendorDto>) => {
    if (formikRef) {
      const validationResult = await validationMethod?.(formikRef);

      if (validationResult?.isValid) {
        formikRef.setSubmitting(true);
        setLoadingDialogState(DialogState.Show);
        setActiveView(DialogViews.Loading);
        await create(formikRef);
      } else if (validationResult?.errorStepIndex) {
        setCurrentSelectedMenuIndex(validationResult.errorStepIndex);
      }
    }
  };

  const renderActionPanelButtons = (formikRef: FormikProps<VendorDto>) => (
    <FormActionButtons
      propsSubButton={{ onClick: onCloseButtonClick }}
      propsMainButton={{ type: "cta", props: { onClick: async () => await didPressCreateNewVendor(formikRef) } }}
    />
  );

  const didPressLoadingDialogDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setActiveView(DialogViews.AddNewVendor);
  };

  const onFileReceive = (receivedFile: File) => {
    setPendingFile(receivedFile);
  };

  const onFileDelete = () => {
    setPendingFile(undefined);
  };

  const renderView = ({ index, active }) => {
    if (index === DialogViews.VendorSelection && active && dialogState === DialogState.Show) {
      return (
        <VendorSelectionView
          didPressAddNewVendor={didPressAddNewVendor}
          didSelectVendorItem={didSelectVendorItem}
          filterObj={filterObj || {}}
          height={isTabletOrMobile ? window.innerHeight : dialogHeight}
        />
      );
    }
    if (index === DialogViews.AddNewVendor && active) {
      if (getFormikInitialValues && sectionItems && formikValidation) {
        return (
          <AnimatedContent
            formikInitialValues={getFormikInitialValues}
            sectionItems={sectionItems}
            formikValidation={formikValidation}
            dialogHeight={dialogHeight}
            renderActionPanelButtons={renderActionPanelButtons}
            onMenuItemSelection={onMenuItemSelection}
            onFileReceive={onFileReceive}
            onFileDelete={onFileDelete}
            selectedMenuIndex={currentSelectedMenuIndex}
          />
        );
      }
      return <div />;
    }
    if (index === DialogViews.Loading && active) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          errorText={loadingDialogErrorText}
          didPressDismissButton={didPressLoadingDialogDismissButton}
        />
      );
    }

    return <div />;
  };

  const onMenuItemSelection = (menuItemTitle: string, currentSelectedMenuIndex?: number) => {
    setCurrentMenuItemTitle(menuItemTitle);
    setCurrentSelectedMenuIndex(currentSelectedMenuIndex);
  };

  const onCloseButtonClick = () => {
    if (activeView === DialogViews.AddNewVendor) {
      setActiveView(DialogViews.VendorSelection);

      return;
    }
    didFinishOperation?.();
  };

  const frameType = useMemo(() => {
    if (activeView === DialogViews.VendorSelection) {
      return "topPanel";
    }
    if (activeView === DialogViews.AddNewVendor) {
      return "sideMenu";
    }
    if (activeView === DialogViews.Loading) {
      return "contentOnly";
    }
    return "topPanel";
  }, [activeView]);

  return (
    <DialogFrame
      onCloseButtonClick={onCloseButtonClick}
      title={activeView === DialogViews.AddNewVendor ? addViewTitle : selectViewTitle}
      width={dialogWidth}
      height={dialogHeight}
      activeView={activeView}
      numViews={3}
      renderView={renderView}
      frameType={frameType}
      sectionTitle={currentMenuItemTitle}
    />
  );
};
