import { ReportPermission } from "@doorloop/dto";
import AppStrings from "locale/keys";

type ReportItems = Array<{
  sectionTitle?: string;
  permissions: Array<{
    permission: ReportPermission;
    title: string;
  }>;
}>;

export const reportItems: ReportItems = [
  {
    sectionTitle: AppStrings.UserRoles.BusinessOverview,
    permissions: [
      {
        permission: ReportPermission.balanceSheet,
        title: AppStrings.UserRoles.BalanceSheet
      },
      {
        permission: ReportPermission.cashFlowStatement,
        title: AppStrings.UserRoles.CashFlowStatement
      },
      {
        permission: ReportPermission.profitAndLoss,
        title: AppStrings.UserRoles.ProfitAndLoss
      },
      {
        permission: ReportPermission.rentRoll,
        title: AppStrings.UserRoles.RentRoll
      },
      {
        permission: ReportPermission.ownerStatement,
        title: AppStrings.UserRoles.OwnerStatement
      },
      {
        permission: ReportPermission.propertyReserveFunds,
        title: AppStrings.UserRoles.PropertyReserveFunds
      },
      {
        permission: ReportPermission.managementFees,
        title: AppStrings.UserRoles.ManagementFees
      }
    ]
  },
  {
    sectionTitle: AppStrings.UserRoles.Accounting,
    permissions: [
      {
        permission: ReportPermission.accountRegister,
        title: AppStrings.Reports.AccountRegister.AccountRegister
      },
      {
        permission: ReportPermission.chartOfAccounts,
        title: AppStrings.UserRoles.ChartOfAccounts
      },
      {
        permission: ReportPermission.banking,
        title: AppStrings.UserRoles.Banking
      },
      {
        permission: ReportPermission.bankBalanceByProperty,
        title: AppStrings.UserRoles.BankingBalanceByProperty
      },
      {
        permission: ReportPermission.depositDetail,
        title: AppStrings.UserRoles.DepositDetail
      },
      {
        permission: ReportPermission.propertyAvailableFunds,
        title: AppStrings.UserRoles.PropertyAvailableFunds
      },
      {
        permission: ReportPermission.ownerAvailableFunds,
        title: AppStrings.UserRoles.OwnerAvailableFunds
      },
      {
        permission: ReportPermission.leaseTransactions,
        title: AppStrings.UserRoles.LeaseTransactions
      },
      {
        permission: ReportPermission.leaseDeposits,
        title: AppStrings.UserRoles.LeaseDeposits
      },
      {
        permission: ReportPermission.budgetVsActual,
        title: AppStrings.UserRoles.BudgetVsActual
      },
      {
        permission: ReportPermission.ownerTransactions,
        title: AppStrings.UserRoles.OwnerTransactions
      },
      {
        permission: ReportPermission.vendorTransactions,
        title: AppStrings.UserRoles.VendorTransactions
      },
      {
        permission: ReportPermission.transactionsList,
        title: AppStrings.UserRoles.TransactionsList
      },
      {
        permission: ReportPermission.transactionsDetails,
        title: AppStrings.UserRoles.TransactionsDetails
      },
      {
        permission: ReportPermission.accountsReceivable,
        title: AppStrings.UserRoles.ARReports
      },
      {
        permission: ReportPermission.accountsPayable,
        title: AppStrings.UserRoles.APReports
      },
      {
        permission: ReportPermission.recurringTransactions,
        title: AppStrings.UserRoles.RecurringTransactions
      },
      {
        permission: ReportPermission.trialBalance,
        title: AppStrings.UserRoles.TrialBalance
      },
      {
        permission: ReportPermission.printChecks,
        title: AppStrings.UserRoles.PrintChecks
      },
      {
        permission: ReportPermission.owners1099,
        title: AppStrings.UserRoles.Owners1099
      },
      {
        permission: ReportPermission.vendors1099,
        title: AppStrings.UserRoles.Vendors1099
      }
    ]
  },
  {
    permissions: [
      {
        title: AppStrings.UserRoles.TenantReports,
        permission: ReportPermission.tenants
      }
    ]
  },
  {
    permissions: [
      {
        title: AppStrings.UserRoles.ProspectReports,
        permission: ReportPermission.prospect
      }
    ]
  },
  {
    permissions: [
      {
        title: AppStrings.UserRoles.LeasingReports,
        permission: ReportPermission.leasing
      }
    ]
  },
  {
    permissions: [
      {
        title: AppStrings.UserRoles.VendorReports,
        permission: ReportPermission.vendors
      }
    ]
  },
  {
    permissions: [
      {
        title: AppStrings.UserRoles.TaskReports,
        permission: ReportPermission.tasks
      }
    ]
  },
  {
    permissions: [
      {
        title: AppStrings.UserRoles.WorkOrderReports,
        permission: ReportPermission.workOrders
      }
    ]
  },
  {
    permissions: [
      {
        title: AppStrings.UserRoles.TenantRequestReports,
        permission: ReportPermission.tenantRequests
      }
    ]
  }
];
