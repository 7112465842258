import React from "react";
import type { FieldProps } from "formik";
import { getIn } from "formik";

import type { RichTextInputBaseProps, RichTextInputProps } from "./richTextInput/richTextInput";
import { RichTextInput } from "./richTextInput/richTextInput";

export interface RichTextFieldProps extends RichTextInputBaseProps, FieldProps<any> {
  html: { name: string; value: string };
}

const RichTextField = ({
  label,
  maxLength,
  required,
  form,
  field: { name, onBlur },
  html: { name: nameHTML, value: valueHTML },
  styles
}: RichTextFieldProps) => {
  const errorText = getIn(form.errors, name);
  const touchedVal = getIn(form.touched, name);

  const handleChange: RichTextInputProps["onChange"] = (value) => {
    form.setFieldValue(name, value.stringSimple, true);

    if (nameHTML) {
      form.setFieldValue(nameHTML, value.stringSimple ? value.stringHTML : "", false);
    }
  };

  const handleError: RichTextInputProps["onError"] = (error, editor) => {
    console.log("handleError");
    console.log({ error, editor });
  };

  return (
    <RichTextInput
      name={name}
      value={valueHTML}
      label={label}
      required={required}
      onChange={handleChange}
      onBlur={onBlur}
      onError={handleError}
      error={touchedVal && errorText}
      maxLength={maxLength}
      styles={styles}
    />
  );
};

export { RichTextField };
