import type { FC } from "react";
import React, { useState } from "react";
import { ArchiveIconNew, ExportPdfIcon } from "../../../../assets";
import type { PopoverItem } from "DLUI/popover";
import AppStrings from "../../../../locale/keys";
import type { ListItemOptionsProps } from "../../../DLUI/listItems/listItemOptions";
import ListItemOptions from "../../../DLUI/listItems/listItemOptions";
import MailOutlineRoundedIcon from "@material-ui/icons/MailOutlineRounded";
import {
  initFormValues as prospectInitFormValues,
  validateForm as prospectValidateForm,
  validateProspectForm
} from "screens/prospects/newProspect/formikHelper";
import { Dialog, SelectTenantDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { EditPropertyOwnersDialog } from "DLUI/dialogs/settings";
import type {
  CommunicationsCenterConversationActionMetadata,
  ConversationDto,
  OwnerDto,
  TenantDto,
  VendorDto
} from "@doorloop/dto";
import {
  ConversationLinkedToType,
  ConversationParticipantDto,
  ConversationStatus,
  DataCy,
  ObjectPermission,
  TenantType,
  UpdateConversationDto
} from "@doorloop/dto";
import { PROSPECT_MENU_ITEMS } from "screens/prospects/newProspect/menuItems";
import { getVendorFormMenuItems } from "screens/vendors/newVendor/menuItems";
import { SelectVendorDialog } from "DLUI/dialogs/vendor/selectVendorDialog";
import {
  initFormValues as vendorInitFormValues,
  validateForm as validateNewVendorForm,
  validateVendorForm
} from "screens/vendors/newVendor/formikHelper";
import { conversationsApi } from "api/conversationsApi";
import { useConversationsListDataSource } from "screens/communicationsCenter/shared/useConversationsListDataSource";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { useAnalyticsService } from "../../../../hooks/useAnalyticsService";
import { useUserType } from "hooks/useUserType";
import { filterObjectsByUserType } from "../../../../utils/userTypesUtils";
import { Routes } from "../../../appRouter";
import { useCommunicationsCenterPermissions } from "screens/communicationsCenter/shared/useCommunicationsCenterPermissions";
import { useTypedTranslation } from "@/locale";
import fileDownload from "js-file-download";

const clearance: AnyPermissionClearance = { permission: ObjectPermission.conversations, field: "edit" };

interface ComponentProps extends Partial<ListItemOptionsProps> {
  showOnHover?: boolean;
  conversationDto: ConversationDto;
  addContactDialogStateChange?: (dialogState: DialogState) => void;
  analyticsMetadata: CommunicationsCenterConversationActionMetadata;
  dataCy?: string;
}

export const ConversationActionsMenu: FC<ComponentProps> = ({
  conversationDto,
  addContactDialogStateChange,
  showOnHover,
  marginTop = 0,
  marginRight = 0,
  iconContainerSize = 22,
  iconPathColor = "black",
  popoverWidth = 230,
  analyticsMetadata,
  dataCy,
  ...listItemOptionsProps
}) => {
  const { t } = useTypedTranslation();
  const { dispatchAnalytics } = useAnalyticsService();
  const { isHOAUser } = useUserType();
  const [addContactDialogState, setAddContactDialogState] = useState<DialogState>(DialogState.Hidden);
  const [dialogType, setDialogType] = useState<ConversationLinkedToType>(ConversationLinkedToType.TENANT);
  const { hasEditPermission } = useCommunicationsCenterPermissions();

  const { updateConversationInDataSource, removeConversationFromList } = useConversationsListDataSource();

  const handleMarkAsUnreadClick = async () => {
    if (conversationDto.lastMessage.isRead) {
      dispatchAnalytics("communications_center_mark_as_unread_clicked", analyticsMetadata);

      updateConversationInDataSource(
        {
          ...conversationDto,
          lastMessage: { ...conversationDto.lastMessage, isRead: false }
        },
        false
      );

      const updatedConversation = await conversationsApi.updateConversation(
        conversationDto.id,
        new UpdateConversationDto({ markAsRead: false })
      );

      if (!updatedConversation.status) {
        updateConversationInDataSource(
          {
            ...conversationDto,
            lastMessage: { ...conversationDto.lastMessage, isRead: true }
          },
          false
        );
      }
    }
  };

  const handleArchiveClick = async () => {
    dispatchAnalytics("communications_center_archive_clicked", analyticsMetadata);

    removeConversationFromList(conversationDto);

    const response = await conversationsApi.updateConversation(
      conversationDto.id,
      new UpdateConversationDto({ status: ConversationStatus.ARCHIVE }),
      {
        translationKey: AppStrings.Common.ConversationArchivedSuccessfully
      }
    );

    if (!response.status) {
      updateConversationInDataSource(conversationDto, false);
    }
  };

  const handleUnArchiveClick = async () => {
    dispatchAnalytics("communications_center_unarchive_clicked", analyticsMetadata);

    await conversationsApi.updateConversation(
      conversationDto.id,
      new UpdateConversationDto({ status: ConversationStatus.ACTIVE }),
      {
        translationKey: AppStrings.Common.ConversationUnArchivedSuccessfully
      }
    );
  };

  const handleAddToContact = async (linkedToType: ConversationLinkedToType, linkedToId?: string) => {
    setAddContactDialogState(DialogState.Hidden);
    addContactDialogStateChange?.(DialogState.Hidden);

    if (linkedToId && conversationDto?.id) {
      const conversationRecipient = new ConversationParticipantDto({ linkedToType, linkedToId });

      updateConversationInDataSource(
        {
          ...conversationDto,
          recipient: conversationRecipient
        },
        false
      );

      const response = await conversationsApi.fixUnknownRecipient(conversationDto.id, conversationRecipient, {
        translationKey: t("common.contactLinkedSuccessfully")
      });

      if (!response.status) {
        updateConversationInDataSource(conversationDto, false);
      }
    }
  };

  const showAddContactDialog = (linkedToType: ConversationLinkedToType) => {
    setDialogType(linkedToType);
    setAddContactDialogState(DialogState.Show);
    addContactDialogStateChange?.(DialogState.Show);
  };

  const hideAddContactDialog = () => {
    setAddContactDialogState(DialogState.Hidden);
    addContactDialogStateChange?.(DialogState.Hidden);
  };

  const exportConversation = async () => {
    const conversationId = conversationDto?.id;

    if (conversationId) {
      dispatchAnalytics("communications_center_export_conversation_clicked");

      const { data: pdfBlob } = await conversationsApi.getConversationHistoryPdf(conversationId);

      if (pdfBlob) {
        fileDownload(pdfBlob, `conversation_history_${conversationId}.pdf`);
      }
    }
  };

  const archiveActionItem: PopoverItem = {
    Icon: ArchiveIconNew,
    title: AppStrings.Common.Archive,
    onClick: handleArchiveClick,
    clearance,
    dataCy: DataCy.communicationsCenter.messagesPanel.archive
  };

  const unArchiveActionItem: PopoverItem = {
    Icon: () => <UnarchiveOutlinedIcon style={{ marginLeft: 10, height: 20, width: 20 }} />,
    title: AppStrings.Common.MoveToInbox,
    onClick: handleUnArchiveClick,
    clearance,
    dataCy: DataCy.communicationsCenter.messagesPanel.unarchive
  };

  const markAsUnreadActionItem: PopoverItem = {
    Icon: () => <MailOutlineRoundedIcon style={{ width: 20, height: 20, marginLeft: 10 }} />,
    title: AppStrings.Common.MarkAsUnread,
    onClick: handleMarkAsUnreadClick,
    clearance,
    dataCy: DataCy.communicationsCenter.messagesPanel.markAsUnread
  };

  const exportConversationActionItem: PopoverItem = {
    Icon: () => <ExportPdfIcon style={{ width: 20, height: 20, marginLeft: 10 }} />,
    title: t("common.exportConversation"),
    onClick: exportConversation,
    showSeparator: true,
    clearance
  };

  const addToContactItems: PopoverItem[] = [
    {
      groupLabel: AppStrings.Common.AddContact,
      title: AppStrings.Common.Enums.ESignatureSignerType.OWNER,
      onClick: () => {
        showAddContactDialog(ConversationLinkedToType.OWNER);
      },
      hiddenForUserTypes: ["HOAUser"],
      clearance
    },
    {
      title: AppStrings.Common.Enums.ESignatureSignerType.TENANT,
      onClick: () => {
        showAddContactDialog(ConversationLinkedToType.TENANT);
      },
      clearance
    },
    {
      title: AppStrings.Common.Enums.ESignatureSignerType.PROSPECT,
      onClick: () => {
        showAddContactDialog(ConversationLinkedToType.PROSPECT);
      },
      clearance
    },
    {
      title: AppStrings.Common.Enums.ESignatureSignerType.VENDOR,
      onClick: () => {
        showAddContactDialog(ConversationLinkedToType.VENDOR);
      },
      clearance
    }
  ];

  const getPopoverItems = () => {
    const conversationActionsItems = [markAsUnreadActionItem];
    const filteredAddToContactItems = !isHOAUser
      ? addToContactItems
      : filterObjectsByUserType(addToContactItems, "HOAUser");

    const canArchive = location.pathname.includes(Routes.COMMUNICATIONS_CENTER_INBOX) && hasEditPermission;
    const isArchived = conversationDto.status === ConversationStatus.ARCHIVE;

    if (isArchived) {
      conversationActionsItems.unshift(unArchiveActionItem);
    } else if (canArchive) {
      conversationActionsItems.unshift(archiveActionItem);
    }

    conversationActionsItems.push(exportConversationActionItem);

    const isUnknownContact = conversationDto && !conversationDto.recipient.linkedToId;

    if (isUnknownContact) {
      return [...conversationActionsItems, ...filteredAddToContactItems];
    }
    return conversationActionsItems;
  };

  const renderSelectProspectDialog = () => (
    <Dialog
      onClose={hideAddContactDialog}
      Content={SelectTenantDialog}
      dialogState={addContactDialogState}
      animatedContentProps={{
        formikInitialValues: prospectInitFormValues,
        sectionItems: PROSPECT_MENU_ITEMS,
        formikValidation: prospectValidateForm
      }}
      validationMethod={validateProspectForm}
      didFinishOperation={async (prospect?: TenantDto) =>
        await handleAddToContact(ConversationLinkedToType.PROSPECT, prospect?.id)
      }
      tenantSelectionViewProps={{
        filterObj: { filter_type: TenantType.PROSPECT_TENANT },
        addNewTenantButtonText: AppStrings.Prospects.Screen.AddNewProspect
      }}
      tenantSelectionTitle={AppStrings.Common.SelectProspect}
      addTenantTitle={AppStrings.Prospects.Screen.AddNewProspect}
    />
  );

  const renderSelectTenantDialog = () => (
    <Dialog
      onClose={hideAddContactDialog}
      Content={SelectTenantDialog}
      dialogState={addContactDialogState}
      tenantSelectionViewProps={{
        showAddNewTenant: false,
        filterObj: { filter_type: TenantType.LEASE_TENANT }
      }}
      tenantSelectionTitle={AppStrings.Tenants.MoveTenants.SelectTenant}
    />
  );

  const renderSelectOwnerDialog = () => (
    <Dialog
      dialogState={addContactDialogState}
      Content={EditPropertyOwnersDialog}
      dialogTitle={AppStrings.Owners.NewOwner.SelectOwner}
      isSelectionOnly
      onBackdropClick={() => setAddContactDialogState(DialogState.Hidden)}
      onClose={hideAddContactDialog}
      didFinishOperation={async (owner: OwnerDto) => await handleAddToContact(ConversationLinkedToType.OWNER, owner.id)}
    />
  );

  const renderSelectVendorDialog = () => {
    const menuItems = getVendorFormMenuItems();
    return (
      <Dialog
        onClose={hideAddContactDialog}
        Content={SelectVendorDialog}
        dialogState={addContactDialogState}
        getFormikInitialValues={vendorInitFormValues}
        sectionItems={menuItems}
        formikValidation={validateNewVendorForm}
        validationMethod={validateVendorForm}
        didFinishOperation={async (vendor?: VendorDto) =>
          await handleAddToContact(ConversationLinkedToType.VENDOR, vendor?.id)
        }
      />
    );
  };

  const selectionDialog = () => {
    switch (dialogType) {
      case ConversationLinkedToType.OWNER:
        return renderSelectOwnerDialog();
      case ConversationLinkedToType.PROSPECT:
        return renderSelectProspectDialog();
      case ConversationLinkedToType.TENANT:
        return renderSelectTenantDialog();
      case ConversationLinkedToType.VENDOR:
        return renderSelectVendorDialog();
      default:
        return null;
    }
  };

  return (
    <>
      <div
        className={showOnHover ? "moreIconButton" : ""}
        style={{ position: "absolute", top: 0, right: 4 }}
        data-cy={dataCy}
      >
        <ListItemOptions
          popoverTopSectionItems={getPopoverItems()}
          marginTop={marginTop}
          marginRight={marginRight}
          iconContainerSize={iconContainerSize}
          iconPathColor={iconPathColor}
          popoverWidth={popoverWidth}
          {...listItemOptionsProps}
        />
      </div>
      {selectionDialog()}
    </>
  );
};
