const Reports = {
  ReportsScreen: {
    Favorites: "reports.reportsScreen.favorites",
    PreparingYourReportFullText: "reports.reportsScreen.preparingYourReportFullText",
    ReportIsReadyToDownload: "reports.reportsScreen.reportIsReadyToDownload",
    DownloadReport: "reports.reportsScreen.downloadReport",
    ReportFiltersAndDataNotInSync: "reports.reportsScreen.reportFiltersAndDataNotInSync",
    ReportIsReadyToDownloadDescription: "reports.reportsScreen.reportIsReadyToDownloadDescription",
    RunReportSubTitle: "reports.reportsScreen.runReportSubTitle",
    ReportSettingsMissingDescription: "reports.reportsScreen.reportSettingsMissingDescription",
    ReportSettingsMissingDescriptionOwnerPortal: "reports.reportsScreen.reportSettingsMissingDescriptionOwnerPortal",
    ReportSettingsMissing: "reports.reportsScreen.reportSettingsMissing",
    ChooseReportSettings: "reports.reportsScreen.chooseReportSettings",
    PreparingYourReport: "reports.reportsScreen.preparingYourReport",
    PreparingYourReportForPrint: "reports.reportsScreen.preparingYourReportForPrint",
    PreparingYourReportDescription: "reports.reportsScreen.preparingYourReportDescription",
    PreparingYourReportForPrintDescription: "reports.reportsScreen.preparingYourReportForPrintDescription",
    LoadingReports: "reports.reportsScreen.loadingReports",
    MoreReportsAvailableViaPrint: "reports.reportsScreen.moreReportsAvailableViaPrint",
    LoadingReportsPrint: "reports.reportsScreen.loadingReportsPrint",
    EmptyReportDataMessage: "reports.reportsScreen.emptyReportDataMessage",
    SearchForReports: "reports.reportsScreen.searchForReports",
    NoReportsFound: "reports.reportsScreen.noReportsFound",
    PropertyBankAccounts: "reports.reportsScreen.propertyBankAccounts",
    ProfitAndLoss: "reports.reportsScreen.profitAndLoss",
    ProfitAndLossDetails: "reports.reportsScreen.profitAndLossDetails",
    BalanceSheetDetails: "reports.reportsScreen.balanceSheetDetails",
    ProfitAndLossByProperty: "reports.reportsScreen.profitAndLossByProperty",
    ProfitAndLossByMonth: "reports.reportsScreen.profitAndLossByMonth",
    ProfitAndLossByYear: "reports.reportsScreen.profitAndLossByYear",
    ProfitAndLossByQuarter: "reports.reportsScreen.profitAndLossByQuarter",
    LeaseLateFeesReport: "reports.reportsScreen.leaseLateFeesReport",
    LeaseAccountStatementsReport: "reports.reportsScreen.leaseAccountStatementsReport",
    Owners1099: "reports.reportsScreen.owners1099",
    Vendors1099: "reports.reportsScreen.vendors1099",
    BusinessOverview: "reports.reportsScreen.businessOverview",
    BalanceSheet: "reports.reportsScreen.balanceSheet",
    BalanceSheetByProperty: "reports.reportsScreen.balanceSheetByProperty",
    BalanceSheetByMonth: "reports.reportsScreen.balanceSheetByMonth",
    BalanceSheetByYear: "reports.reportsScreen.balanceSheetByYear",
    BalanceSheetByQuarter: "reports.reportsScreen.balanceSheetByQuarter",
    CashFlowStatement: "reports.reportsScreen.cashFlowStatement",
    CashFlowStatementByMonth: "reports.reportsScreen.cashFlowStatementByMonth",
    OwnerStatement: "reports.reportsScreen.ownerStatement",
    ReportSettings: "reports.reportsScreen.reportSettings",
    ShowAdvancedSettings: "reports.reportsScreen.showAdvancedSettings",
    RunReport: "reports.reportsScreen.runReport",
    ShowNonZeroRows: "reports.reportsScreen.showNonZeroRows",
    RunReportBy: "reports.reportsScreen.runReportBy",
    HideAdvancedSettings: "reports.reportsScreen.hideAdvancedSettings",
    PropertyReserveFunds: "reports.reportsScreen.propertyReserveFunds",
    ManagementFeesReport: "reports.reportsScreen.managementFeesReport",
    OwnerStatementDetailed: "reports.reportsScreen.ownerStatementDetailed",
    OwnerStatementSummary: "reports.reportsScreen.ownerStatementSummary",
    StatementSummary: "reports.reportsScreen.statementSummary",
    TransactionDetails: "reports.reportsScreen.transactionDetails",
    OwnerStatementExportFileName: "reports.reportsScreen.ownerStatementExportFileName",
    DepositDetailReportFileName: "reports.reportsScreen.depositDetailReportFileName",
    CashFlowStatementByProperty: "reports.reportsScreen.cashFlowStatementByProperty",
    CurrentTenants: "reports.reportsScreen.currentTenants",
    PreviousTenants: "reports.reportsScreen.previousTenants",
    FutureTenants: "reports.reportsScreen.futureTenants",
    TenantVehicles: "reports.reportsScreen.tenantVehicles",
    TenantPets: "reports.reportsScreen.tenantPets",
    BeginningBalance: "reports.reportsScreen.beginningBalance",
    TenantStatement: "reports.reportsScreen.tenantStatement",
    DraftLeasesbyStatus: "reports.reportsScreen.draftLeasesbyStatus",
    DraftLeasesbyProperty: "reports.reportsScreen.draftLeasesbyProperty",
    CancelledDraftLeases: "reports.reportsScreen.cancelledDraftLeases",
    ActivatedDraftLeases: "reports.reportsScreen.activatedDraftLeases",
    RenewalStatusbyProperty: "reports.reportsScreen.renewalStatusbyProperty",
    LeaseInsuranceSummaryReport: "reports.reportsScreen.leaseInsuranceSummaryReport",
    LeaseInsuranceDetailsReport: "reports.reportsScreen.leaseInsuranceDetailsReport",
    PolicyNumber: "reports.reportsScreen.policyNumber",
    Status: "reports.reportsScreen.status",
    NotRequired: "reports.reportsScreen.notRequired",
    Required: "reports.reportsScreen.required",
    Provided: "reports.reportsScreen.provided",
    ProvidedDate: "reports.reportsScreen.providedDate",
    ExpirationDate: "reports.reportsScreen.expirationDate",
    EffectiveDate: "reports.reportsScreen.effectiveDate",
    LeaseInsuranceSummaryFileName: "reports.reportsScreen.leaseInsuranceSummaryFileName",
    LeaseInsuranceDetailsFileName: "reports.reportsScreen.leaseInsuranceDetailsFileName",
    UpcomingMoveOuts: "reports.reportsScreen.upcomingMoveOuts",
    UpcomingMoveIns: "reports.reportsScreen.upcomingMoveIns",
    RentRoll: "reports.reportsScreen.rentRoll",
    RentRollSummaryByBedsAndBaths: "reports.reportsScreen.rentRollSummaryByBedsAndBaths",
    RentRollSummaryByProperty: "reports.reportsScreen.rentRollSummaryByProperty",
    Debit: "reports.reportsScreen.debit",
    Credit: "reports.reportsScreen.credit",
    TransactionsReportFileName: "reports.reportsScreen.transactionsReportFileName",
    BillsAndExpensesReportFileName: "reports.reportsScreen.billsAndExpensesReportFileName",
    ChargesAndPaymentReportFileName: "reports.reportsScreen.chargesAndPaymentReportFileName",
    RentRollFileName: "reports.reportsScreen.rentRollFileName",
    ApplicationsReportFileName: "reports.reportsScreen.applicationsReportFileName",
    TenantsReport: "reports.reportsScreen.tenantsReport",
    ActiveProspects: "reports.reportsScreen.activeProspects",
    MovedInProspects: "reports.reportsScreen.movedInProspects",
    LostProspects: "reports.reportsScreen.lostProspects",
    ProspectsbySource: "reports.reportsScreen.prospectsbySource",
    ProspectsbyLeasingAgent: "reports.reportsScreen.prospectsbyLeasingAgent",
    RentalApplicationsReport: "reports.reportsScreen.rentalApplicationsReport",
    ProspectsReport: "reports.reportsScreen.prospectsReport",
    ProspectsFileName: "reports.reportsScreen.prospectsFileName",
    LeasingReport: "reports.reportsScreen.leasingReport",
    LeasingReportFileName: "reports.reportsScreen.leasingReportFileName",
    LeaseLateFeesReportFileName: "reports.reportsScreen.leaseLateFeesReportFileName",
    VendorsDirectory: "reports.reportsScreen.vendorsDirectory",
    VendorsByProperty: "reports.reportsScreen.vendorsByProperty",
    VendorByService: "reports.reportsScreen.vendorByService",
    VendorsByInsuranceStatus: "reports.reportsScreen.vendorsByInsuranceStatus",
    AllWorkOrdersByVendor: "reports.reportsScreen.allWorkOrdersByVendor",
    OpenWorkOrdersByVendor: "reports.reportsScreen.openWorkOrdersByVendor",
    VendorLedger: "reports.reportsScreen.vendorLedger",
    TransactionsByVendor: "reports.reportsScreen.transactionsByVendor",
    VendorsReportFileName: "reports.reportsScreen.vendorsReportFileName",
    SelectVendorInstructions: "reports.reportsScreen.selectVendorInstructions",
    WorkOrdersReport: "reports.reportsScreen.workOrdersReport",
    WorkOrdersReportFileName: "reports.reportsScreen.workOrdersReportFileName",
    TasksReport: "reports.reportsScreen.tasksReport",
    TasksReportFileName: "reports.reportsScreen.tasksReportFileName",
    CompletedTasks: "reports.reportsScreen.completedTasks",
    OpenTasks: "reports.reportsScreen.openTasks",
    OverdueTasks: "reports.reportsScreen.overdueTasks",
    TasksByProperty: "reports.reportsScreen.tasksByProperty",
    TasksByStatus: "reports.reportsScreen.tasksByStatus",
    TasksByPriority: "reports.reportsScreen.tasksByPriority",
    TrialBalance: "reports.reportsScreen.trialBalance",
    GeneralLedger: "reports.reportsScreen.generalLedger",
    TransactionsDetails: "reports.reportsScreen.transactionsDetails",
    OwnerStatementNReports: "reports.reportsScreen.ownerStatementNReports",
    OwnerStatementNReportsOutOfNReports: "reports.reportsScreen.ownerStatementNReportsOutOfNReports",
    TransactionsList: "reports.reportsScreen.transactionsList",
    TransactionListRecent: "reports.reportsScreen.transactionListRecent",
    TransactionsListByDate: "reports.reportsScreen.transactionsListByDate",
    TransactionListByType: "reports.reportsScreen.transactionListByType",
    DepositTransactions: "reports.reportsScreen.depositTransactions",
    CheckTransactions: "reports.reportsScreen.checkTransactions",
    ExpenseTransactions: "reports.reportsScreen.expenseTransactions",
    RefundTransactions: "reports.reportsScreen.refundTransactions",
    BankbalanceByProperty: "reports.reportsScreen.bankbalanceByProperty",
    BankbalanceByPropertyFileName: "reports.reportsScreen.BankbalanceByPropertyFileName",
    BankbalanceByPropertyReport: "reports.reportsScreen.bankbalanceByPropertyReport",
    TrialBalanceFileName: "reports.reportsScreen.trialBalanceFileName",
    TransactionsListReport: "reports.reportsScreen.transactionsListReport",
    DepositDetail: "reports.reportsScreen.depositDetail",
    OwnersDirectory: "reports.reportsScreen.ownersDirectory",
    PropertyOwnership: "reports.reportsScreen.propertyOwnership",
    OwnersByProperty: "reports.reportsScreen.ownersByProperty",
    OwnersReportFileName: "reports.reportsScreen.ownersReportFileName",
    OwnersReport: "reports.reportsScreen.ownersReport",
    WhoOwesYou: "reports.reportsScreen.whoOwesYou",
    AccountsReceivableAgingDetail: "reports.reportsScreen.accountsReceivableAgingDetail",
    AccountsReceivableAgingDetailByAccount: "reports.reportsScreen.accountsReceivableAgingDetailByAccount",
    AccountsReceivableAgingSummary: "reports.reportsScreen.accountsReceivableAgingSummary",
    AccountsPayableAgingDetail: "reports.reportsScreen.accountsPayableAgingDetail",
    AccountsPayableAgingDetailByAccount: "reports.reportsScreen.accountsPayableAgingDetailByAccount",
    AccountsPayableAgingSummary: "reports.reportsScreen.accountsPayableAgingSummary",
    AgingPeriod: "reports.reportsScreen.agingPeriod",
    AsOfDate: "reports.reportsScreen.asOfDate",
    MinDaysPastDue: "reports.reportsScreen.minDaysPastDue",
    Credits: "reports.reportsScreen.credits",
    Show: "reports.reportsScreen.show",
    Hide: "reports.reportsScreen.hide",
    AgingPeriodDays: "reports.reportsScreen.agingPeriodDays",
    NumberOfAgingPeriods: "reports.reportsScreen.numberOfAgingPeriods",
    AgingDetailFileName: "reports.reportsScreen.agingDetailFileName",
    AgingSummaryReportFileName: "reports.reportsScreen.agingSummaryReportFileName",
    RecurringPayments: "reports.reportsScreen.recurringPayments",
    RecurringPaymentsReport: "reports.reportsScreen.recurringPaymentsReport",
    RecurringPaymentsReportFileName: "reports.reportsScreen.recurringPaymentsReportFileName",
    RecurringCharges: "reports.reportsScreen.recurringCharges",
    RecurringChargesReport: "reports.reportsScreen.recurringChargesReport",
    RecurringChargesReportFileName: "reports.reportsScreen.recurringChargesReportFileName",
    RecurringCredits: "reports.reportsScreen.recurringCredits",
    RecurringCreditsReport: "reports.reportsScreen.recurringCreditsReport",
    RecurringCreditsReportFileName: "reports.reportsScreen.recurringCreditsReportFileName",
    RecurringTransactionsReport: "reports.reportsScreen.recurringTransactionsReport",
    RecurringTransactions: "reports.reportsScreen.recurringTransactions",
    RecurringTransactionsDetail: "reports.reportsScreen.recurringRentTransactions",
    RecurringRentTransactions: "reports.reportsScreen.recurringRentTransactions",
    RecurringTransactionsByType: "reports.reportsScreen.recurringTransactionsByType",
    RecurringTransactionsSummary: "reports.reportsScreen.recurringTransactionsSummary",
    WhatYouOwe: "reports.reportsScreen.whatYouOwe",
    BillPaymentsList: "reports.reportsScreen.billPaymentsList",
    UnpaidBills: "reports.reportsScreen.unpaidBills",
    AccountsReceivable: "reports.reportsScreen.accountsReceivable",
    AccountsPayable: "reports.reportsScreen.accountsPayable",
    UndepositedFundsReport: "reports.reportsScreen.undepositedFunds",
    EPayDepositsReport: "reports.reportsScreen.ePayDepositsReport",
    AddToFavorites: "reports.reportsScreen.addToFavorites",
    ViewReport: "reports.reportsScreen.viewReport",
    RemoveFromFavorites: "reports.reportsScreen.removeFromFavorites",
    EfileWithTrack1099: "reports.reportsScreen.efileWithTrack1099",
    ChargesAndPayment: "reports.reportsScreen.chargesAndPayment",
    BillsAndExpenses: "reports.reportsScreen.billsAndExpenses",
    OwnerStatementSentSuccessfully: "reports.reportsScreen.ownerStatementSentSuccessfully"
  },
  ProfitAndLoss: {
    ShowRows: "reports.profitAndLoss.showRows",
    AllRows: "reports.profitAndLoss.allRows",
    NonZero: "reports.profitAndLoss.nonZero",
    RowType: "reports.profitAndLoss.rowType"
  },
  TaxForms1099: {
    ShowRows: "reports.taxForms1099.showRows",
    NonZero: "reports.taxForms1099.nonZero",
    AllRows: "reports.taxForms1099.allRows",
    AboveMinimum: "reports.taxForms1099.aboveMinimum",
    RowType: "reports.taxForms1099.rowType",
    SendForms: "reports.taxForms1099.sendForms",
    SendFormsYes: "reports.taxForms1099.sendFormsYes",
    SendFormsNo: "reports.taxForms1099.sendFormsNo",
    DownloadCsv: "reports.taxForms1099.downloadCsv",
    DialogTitle: "reports.taxForms1099.dialogTitle",
    DialogSubtitle1: "reports.taxForms1099.dialogSubtitle1",
    DialogSubtitle2: "reports.taxForms1099.dialogSubtitle2",
    DialogSubtitle3: "reports.taxForms1099.dialogSubtitle3",
    InfoLink: "reports.taxForms1099.infoLink",
    CreateAccountInstruction: "reports.taxForms1099.createAccountInstruction",
    CreateAccountLink: "reports.taxForms1099.createAccountLink",
    ExportInstruction: "reports.taxForms1099.exportInstruction",
    ExportLink: "reports.taxForms1099.exportLink"
  },
  Vendors1099: {
    ShowRows: "reports.vendors1099.showRows",
    AllRows: "reports.vendors1099.allRows",
    AboveMinimum: "reports.vendors1099.aboveMinimum",
    RowType: "reports.vendors1099.rowType"
  },

  TransactionsReport: {
    TransactionReportGroupBy: {
      Account: "reports.transactionsReport.transactionReportGroupBy.account",
      Type: "reports.transactionsReport.transactionReportGroupBy.type",
      Vendor: "reports.transactionsReport.transactionReportGroupBy.vendor",
      Property: "reports.transactionsReport.transactionReportGroupBy.property",
      Unit: "reports.transactionsReport.transactionReportGroupBy.unit",
      Tenant: "reports.transactionsReport.transactionReportGroupBy.tenant",
      Lease: "reports.transactionsReport.transactionReportGroupBy.lease",
      Owner: "reports.transactionsReport.transactionReportGroupBy.owner",
      Day: "reports.transactionsReport.transactionReportGroupBy.day",
      Week: "reports.transactionsReport.transactionReportGroupBy.week",
      Month: "reports.transactionsReport.transactionReportGroupBy.month",
      Quarter: "reports.transactionsReport.transactionReportGroupBy.quarter",
      Year: "reports.transactionsReport.transactionReportGroupBy.year"
    },
    AccountingMethod: {
      AccountingMethod: "reports.transactionsReport.accountingMethod.accountingMethod",
      Both: "reports.transactionsReport.accountingMethod.both",
      Cash: "reports.transactionsReport.accountingMethod.cash",
      Accrual: "reports.transactionsReport.accountingMethod.accrual",
      Basis: "reports.transactionsReport.accountingMethod.basis"
    },
    TransactionsReport: "reports.transactionsReport.transactionsReport",
    ReportPeriod: "reports.transactionsReport.reportPeriod",
    GroupBy: "reports.transactionsReport.groupBy",
    TotalFor: "reports.transactionsReport.totalFor",
    EmptyDataText: "reports.transactionsReport.emptyDataText",
    RunningBalance: "reports.transactionsReport.runningBalance",
    BeginningBalance: "reports.transactionsReport.beginningBalance"
  },
  AccountRegister: {
    AccountRegister: "reports.accountRegister.accountRegister",
    SelectAccountInstructions: "reports.accountRegister.selectAccountInstructions",
    AccountRegisterReport: "reports.accountRegister.accountRegisterReport",
    AccountRegisterReportFileName: "reports.accountRegister.accountRegisterReportFileName",
    ViewRegister: "reports.accountRegister.viewRegister"
  },
  RentRollReport: {
    SummaryByBedAndBath: "reports.rentRoll.summaryByBedAndBath",
    TotalsAndAverages: "reports.rentRoll.totalsAndAverages",
    NoOfUnits: "reports.rentRoll.noOfUnits",
    Occupancy: "reports.rentRoll.occupancy",
    Vacant: "reports.rentRoll.vacant",
    Occupied: "reports.rentRoll.occupied",
    OccupiedPercent: "reports.rentRoll.occupiedPercent",
    UnitSize: "reports.rentRoll.unitSize",
    SummaryByProperty: "reports.rentRoll.summaryByProperty",
    AvgPerSize: "reports.rentRoll.avgPerSize"
  },
  DepositDetail: {
    DepositAccount: "reports.depositDetail.depositAccount"
  },
  TreeList: {
    Accounts: {},
    xSelected: "reports.treeList.xSelected",
    BulkActions: {
      ClearData: "reports.treeList.bulkActions.clearData",
      FillCustomTotalAmount: "reports.treeList.bulkActions.fillCustomTotalAmount",
      FillWithReferenceData: "reports.treeList.bulkActions.fillWithReferenceData"
    },
    ClearDataConfirmationTitle: "reports.treeList.clearDataConfirmationTitle",
    OverwriteConfirmationTitle: "reports.treeList.overwriteConfirmationTitle",
    OverwriteConfirmationSubmit: "reports.treeList.overwriteConfirmationSubmit",
    FillTotalInputTitle: "reports.treeList.fillTotalInputTitle",
    FillTotalInputLabel: "reports.treeList.fillTotalInputLabel",
    FillTotalSubmitButton: "reports.treeList.fillTotalSubmitButton",
    TooltipInlineActionCopy: "reports.treeList.tooltipInlineActionCopy",
    TooltipInlineActionDistribute: "reports.treeList.tooltipInlineActionDistribute",
    Headers: {
      Account: "reports.treeList.headers.account",
      Property: "reports.treeList.headers.property",
      Tenant: "reports.treeList.headers.tenant",
      Vendor: "reports.treeList.headers.vendor",
      Owner: "reports.treeList.headers.owner",
      Lease: "reports.treeList.headers.lease",
      Unit: "reports.treeList.headers.unit",
      JournalEntryType: "reports.treeList.headers.journalEntryType",
      PaymentMethod: "reports.treeList.headers.paymentMethod",
      Reference: "reports.treeList.headers.reference",
      Memo: "reports.treeList.headers.memo",
      Date: "reports.treeList.headers.date",
      Amount: "reports.treeList.headers.amount",
      Total: "reports.treeList.headers.total",
      RunningBalance: "reports.treeList.headers.runningBalance",
      Debit: "reports.treeList.headers.debit",
      Credit: "reports.treeList.headers.credit",
      Name: "reports.treeList.headers.name"
    }
  }
};

export default Reports;
