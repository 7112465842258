import React, { useMemo, useState } from "react";

import _ from "lodash";
import { FastField, useFormikContext } from "formik";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import type { RequestsSettingsDto, SettingPermission } from "@doorloop/dto";
import { UserStatus } from "@doorloop/dto";

import AppStrings from "../../../../locale/keys";
import FormikCachedMultiSelectAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedMultiSelectAutoComplete";
import Text from "DLUI/text";
import type { ApiResult } from "api/apiResult";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { FormikSwitchButton } from "DLUI/form";
import type { FrameType } from "DLUI/dialogs/components/dialogFrame";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import { Icon } from "DLUI/icon";
import { RequestMaintenanceIcon } from "../../../../assets/icons";
import { SettingsReadOnlyScope } from "DLUI/permissionScope/readOnlyScope";
import { usersApi } from "api/usersApi";
import { View } from "DLUI/view";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { AnimatedView } from "DLUI/view/animatedView";

export interface RequestsSettingsDialogProps {
  didFinishOperation: (values: RequestsSettingsDto) => void;
  onBackdropClick: () => void;
  dialogTitle: string;
  textTitle: string;
  notifyRecipientFormikFieldName: Extract<
    keyof RequestsSettingsDto,
    "sendEmailToTenantWhenRequestIsReceived" | "sendEmailToOwnerWhenRequestIsReceived"
  >;
  notifyRecipientFormikFieldLabel: string;
  updateRequestsSettingsApi: (data: RequestsSettingsDto) => Promise<ApiResult<unknown>>;
  settingPermission: SettingPermission;
  notifyAssignedOnReceivedLabel?: string;
  automaticallyAssignUsersLabel?: string;
}

export const REQUESTS_SETTINGS_DIALOG_WIDTH = 750;
export const REQUESTS_SETTINGS_DIALOG_HEIGHT = 440;

enum DialogViews {
  DialogForm,
  LoadingView
}

export const RequestsSettingsDialog: React.FC<RequestsSettingsDialogProps> = ({
  didFinishOperation,
  onBackdropClick,
  dialogTitle,
  textTitle,
  notifyRecipientFormikFieldName,
  notifyRecipientFormikFieldLabel,
  updateRequestsSettingsApi,
  settingPermission,
  notifyAssignedOnReceivedLabel = AppStrings.Common.NotifyAssignedOnReceived,
  automaticallyAssignUsersLabel = AppStrings.Common.AutomaticallyAssignUsers
}: RequestsSettingsDialogProps) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();
  const { dialogHorizontalPadding } = DialogsHelper();

  const [viewIndex, setViewIndex] = useState<DialogViews>(DialogViews.DialogForm);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>(AppStrings.Common.NetworkErrorSubTitle);

  const formikRef = useFormikContext<RequestsSettingsDto>();

  const showAssignUsersWhenRequestIsReceived = Boolean(formikRef.values?.assignUsersWhenRequestIsReceived);

  const isValidForm = async (): Promise<boolean> => {
    formikRef.setFieldTouched("assignUsersWhenRequestIsReceivedUsers");

    const errors = await formikRef.validateForm();

    return _.isEmpty(errors);
  };

  const updateSettings = async (): Promise<void> => {
    const values = formikRef.values;

    const { status, message } = await updateRequestsSettingsApi(values);

    if (status) {
      setLoadingDialogState(DialogState.Success);
      didFinishOperation(values);
      setLoadingDialogState(DialogState.Hidden);
    } else {
      setLoadingDialogErrorText(message);
      setLoadingDialogState(DialogState.Error);
    }
  };

  const didPressSaveButton = async (): Promise<void> => {
    const isValid = await isValidForm();

    if (isValid) {
      setLoadingDialogState(DialogState.Show);
      setViewIndex(DialogViews.LoadingView);

      await updateSettings();
    }
  };

  const renderActionPanelButtons = (): JSX.Element | null => {
    if (viewIndex === DialogViews.LoadingView) {
      return <div />;
    }

    return (
      <FormActionButtons
        propsSubButton={{ onClick: onBackdropClick }}
        propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
      />
    );
  };

  const didPressDismissButton = (): void => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(DialogViews.DialogForm);
  };

  const didChangeAutomaticallyAssignUsers = (nextSwitchState: boolean): void => {
    formikRef.setFieldValue("assignUsersWhenRequestIsReceivedUsers", undefined);
    formikRef.setFieldValue("assignUsersWhenRequestIsReceived", nextSwitchState);
  };

  const renderForm = () => (
    <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding}>
      <View alignItems={"flex-start"}>
        <View marginTop={20} flexDirection={"row"}>
          <Grid item container justifyContent={"center"} alignItems={"center"} xs={12} md={2} lg={2}>
            {isMobile ? null : <Icon Source={RequestMaintenanceIcon} marginRight={20} />}
          </Grid>

          <Grid item container alignItems={"center"} xs={12} md={10} lg={10}>
            <View noWrap>
              <Text fontSize={20} fontWeight={700} value={textTitle} />

              <FastFieldSafe
                component={FormikSwitchButton}
                name={notifyRecipientFormikFieldName}
                label={notifyRecipientFormikFieldLabel}
                marginTop={16}
              />
              <FastField
                component={FormikSwitchButton}
                name={"sendEmailToUsersWhenRequestIsReceived"}
                label={notifyAssignedOnReceivedLabel}
                marginTop={16}
              />
              <FastField
                component={FormikSwitchButton}
                name={"assignUsersWhenRequestIsReceived"}
                label={automaticallyAssignUsersLabel}
                marginTop={16}
                onChange={didChangeAutomaticallyAssignUsers}
              />
              <AnimatedView shouldShow={showAssignUsersWhenRequestIsReceived}>
                <Grid item xs={12} md={12} lg={12}>
                  <FormikCachedMultiSelectAutoComplete
                    uniqueIndex={"assignUsersWhenRequestIsReceivedUsers"}
                    apiHandler={usersApi}
                    displayNameKey={"name"}
                    filterFieldName={"filter_text"}
                    filterFieldValue={"name"}
                    selectionFields={["id", "class"]}
                    queryParams={{ filter_status: UserStatus.ACTIVE }}
                    name={"assignUsersWhenRequestIsReceivedUsers"}
                    label={t(AppStrings.Common.SelectUsers)}
                    marginTop={20}
                    errorLabelPaddingLeft={15}
                  />
                </Grid>
              </AnimatedView>
            </View>
          </Grid>
          {isMobile ? <View height={20} /> : null}
        </View>
      </View>
    </View>
  );

  const renderView = ({ index }: any) => {
    if (index === DialogViews.DialogForm) {
      return renderForm();
    }
    if (index === DialogViews.LoadingView) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            errorText={loadingDialogErrorText}
            onRetryButtonPress={didPressSaveButton}
            didPressDismissButton={didPressDismissButton}
          />
        </View>
      );
    }
    return <div />;
  };

  const handleCloseButtonClick = (): void => {
    if (viewIndex === DialogViews.LoadingView) {
      setViewIndex(DialogViews.DialogForm);

      return;
    }

    onBackdropClick?.();
  };

  const frameType = useMemo((): FrameType => {
    if (viewIndex === DialogViews.DialogForm) {
      return "sectionTitleFrame";
    }

    return "contentOnly";
  }, [viewIndex]);

  return (
    <SettingsReadOnlyScope permission={settingPermission}>
      <DialogFrame
        onCloseButtonClick={handleCloseButtonClick}
        title={dialogTitle}
        width={getDialogFrameDimension("width", REQUESTS_SETTINGS_DIALOG_WIDTH)}
        height={getDialogFrameDimension("height", REQUESTS_SETTINGS_DIALOG_HEIGHT)}
        renderView={renderView}
        numViews={2}
        activeView={viewIndex}
        RenderActionPanelButtons={renderActionPanelButtons}
        frameType={frameType}
      />
    </SettingsReadOnlyScope>
  );
};
