import type { ComponentProps } from "react";
import React, { useState } from "react";
import type { DepositDto, MatchNewDepositRequestDto } from "@doorloop/dto";
import { ExpensePayToResourceType, HOAExpensePayToResourceType, PaymentMethod } from "@doorloop/dto";
import { Grid } from "@material-ui/core";
import { ownersApi } from "api/ownersApi";
import { propertiesApi } from "api/propertiesApi";
import { tenantsApi } from "api/tenantsApi";
import { vendorsApi } from "api/vendorsApi";
import { DeleteIcon } from "assets/icons";
import { IconButton, Select, TextField } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import { FastField, getIn, useFormikContext } from "formik";
import AppStrings from "locale/keys";

import { useTranslation } from "react-i18next";
import { ShakeEffectView } from "DLUI/animatableView";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";
import { useUserType } from "../../../../../hooks/useUserType";
import { unitsApi } from "api/unitsApi";

interface DepositsLineProps extends Pick<ComponentProps<typeof View>, "domRef"> {
  lineIndex: number;
  didPressDeleteDepositLine: (lineIndex: number) => void;
  matchDepositInstance?: boolean;
  showDeleteShakeEffect?: boolean;
}

const depositLinePadding = 10;

const DepositsLine: React.FC<DepositsLineProps> = ({
  domRef,
  lineIndex,
  didPressDeleteDepositLine,
  matchDepositInstance,
  showDeleteShakeEffect
}: DepositsLineProps) => {
  const formikRef = useFormikContext<MatchNewDepositRequestDto | DepositDto>();
  const { isHOAUser } = useUserType();
  const { t } = useTranslation();
  const [selectedPropertyId, setSelectedPropertyId] = useState<string | undefined>();
  const [receivedFromResourceType, setReceivedFromResourceType] = useState<string | undefined>(
    getIn(formikRef.values, `lines[${lineIndex}].receivedFromResourceType`)
  );

  const receivedFromResourceId = getIn(formikRef.values, `lines[${lineIndex}].receivedFromResourceId`);

  const propertyDefaultValue = getIn(formikRef.values, `lines[${lineIndex}].property`);
  const unitDefaultValue = getIn(formikRef.values, `lines[${lineIndex}].unit`);
  const accountDefaultValue = getIn(formikRef.values, `lines[${lineIndex}].account`);

  const containerMarginTop = matchDepositInstance ? 0 : 20;

  const didSelectProperty = (event: object, value: any) => {
    formikRef.setFieldValue(`lines[${lineIndex}].unit`, undefined);
    if (value !== null) {
      setSelectedPropertyId(value.id);
    } else {
      setSelectedPropertyId(undefined);
    }
  };

  const FloatingDeleteButton = () => (
    <View
      width={"auto"}
      style={{
        position: "absolute",
        right: -35,
        top: 20
      }}
    >
      <ShakeEffectView showEffect={showDeleteShakeEffect}>
        <IconButton onClick={_didPressDeleteDepositLine} aria-label="delete" Icon={DeleteIcon} />
      </ShakeEffectView>
    </View>
  );

  const renderReceivedFromResourceField = () => {
    if (!receivedFromResourceType) {
      return null;
    }
    let apiHandler: any = tenantsApi;
    let label = t(AppStrings.Tenants.MoveTenants.SelectTenant);
    switch (receivedFromResourceType) {
      case ExpensePayToResourceType.OWNER:
        apiHandler = ownersApi;
        label = t(AppStrings.Owners.NewOwner.SelectOwner);
        break;
      case ExpensePayToResourceType.VENDOR:
        apiHandler = vendorsApi;
        label = t(AppStrings.Vendors.VendorDetails.SelectVendor);
        break;
    }
    return (
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <View paddingRight={depositLinePadding} paddingLeft={depositLinePadding}>
          <FormikCachedAsyncAutoComplete
            uniqueIndex={"receivedFromResourceId" + lineIndex}
            apiHandler={apiHandler}
            displayNameKey={"name"}
            filterFieldName={"filter_text"}
            filterFieldValue={"name"}
            selectionFields={["id", "class"]}
            name={`lines[${lineIndex}].receivedFromResourceId`}
            label={label}
            marginTop={20}
            defaultValue={receivedFromResourceId}
            required
          />
        </View>
      </Grid>
    );
  };

  const didChangeReceivedFromResourceType = (nextValue: string) => {
    setReceivedFromResourceType(undefined);
    setTimeout(() => {
      setReceivedFromResourceType(nextValue);
    }, 0);
  };

  const _didPressDeleteDepositLine = () => {
    didPressDeleteDepositLine(lineIndex);
  };

  const renderItemHeader = () => {
    if (formikRef !== null && !matchDepositInstance) {
      return (
        <View showAnimation={"fade-in"} hideAnimation={"fade-out"} shouldShow>
          <View justifyContent={"center"} alignItems={"center"} flexDirection={"row"} height={60}>
            <Text fontWeight={700} marginLeft={20} fontSize={16} color={"black"}>
              {t(AppStrings.Common.Enums.JournalEntryType.Deposit)}
            </Text>
            <Text fontWeight={700} marginLeft={5} fullWidth fontSize={16} color={"black"}>
              {lineIndex + 1}
            </Text>
            <IconButton onClick={_didPressDeleteDepositLine} aria-label="delete" Icon={DeleteIcon} marginRight={20} />
            <SeparationLine width={"95%"} height={1} />
          </View>
        </View>
      );
    }
  };

  const matchDepositLineSeparationLine = () =>
    matchDepositInstance && lineIndex > 0 ? <SeparationLine width={"100%"} height={1} /> : null;

  return (
    <View backgroundColor={"dark"} borderRadius={10} marginTop={containerMarginTop} domRef={domRef}>
      {renderItemHeader()}
      {matchDepositLineSeparationLine()}
      <View paddingLeft={depositLinePadding} paddingRight={depositLinePadding} flexDirection={"row"}>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <View paddingRight={depositLinePadding} paddingLeft={depositLinePadding}>
            <FormikCachedAsyncAutoComplete
              label={t(AppStrings.Common.FilterOptions.Property)}
              uniqueIndex={"property" + lineIndex}
              apiHandler={propertiesApi}
              displayNameKey={"name"}
              filterFieldName={"filter_text"}
              filterFieldValue={"name"}
              selectionFields={["id", "class"]}
              name={`lines[${lineIndex}].property`}
              marginTop={20}
              onChange={didSelectProperty}
              required
              defaultValue={propertyDefaultValue}
              queryParams={{ filter_active: true }}
            />
          </View>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <View paddingRight={depositLinePadding} paddingLeft={depositLinePadding}>
            <FormikCachedAsyncAutoComplete
              label={t(AppStrings.Common.FilterOptions.Unit)}
              uniqueIndex={"unit" + lineIndex}
              apiHandler={unitsApi}
              displayNameKey={"name"}
              filterFieldName={"filter_text"}
              filterFieldValue={"name"}
              selectionFields={["id", "class"]}
              name={`lines[${lineIndex}].unit`}
              marginTop={20}
              disabled={!selectedPropertyId && !propertyDefaultValue}
              defaultValue={unitDefaultValue}
              queryParams={{ filter_active: true, filter_property: selectedPropertyId || propertyDefaultValue }}
            />
          </View>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <View paddingRight={depositLinePadding} paddingLeft={depositLinePadding}>
            <BankAccountFormikAutoCompleteField
              addCreateOption
              uniqueIndex={"account" + lineIndex}
              name={`lines[${lineIndex}].account`}
              required
              label={t(AppStrings.Common.ListHeader.Category)}
              marginTop={20}
              defaultValue={accountDefaultValue}
            />
          </View>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <View paddingRight={depositLinePadding} paddingLeft={depositLinePadding}>
            <FastField
              marginTop={20}
              component={TextField}
              name={`lines[${lineIndex}].memo`}
              label={t(AppStrings.Leases.LeaseCharge.Description)}
            />
          </View>
        </Grid>
      </View>
      <View marginBottom={20} paddingLeft={depositLinePadding} paddingRight={depositLinePadding} flexDirection={"row"}>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <View paddingRight={depositLinePadding} paddingLeft={depositLinePadding}>
            <FastField
              marginTop={20}
              component={TextField}
              formatType={"currency"}
              name={`lines[${lineIndex}].amount`}
              label={t(AppStrings.Leases.NewLease.LeaseRent.Amount)}
              shouldDebounce
              required
              allowNegative
            />
          </View>

          {matchDepositInstance && <FloatingDeleteButton />}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <View paddingRight={depositLinePadding} paddingLeft={depositLinePadding}>
            <FastField
              component={Select}
              name={`lines[${lineIndex}].paymentMethod`}
              label={AppStrings.Leases.LeaseTransaction.Payment.PaymentMethod}
              uniqueKey={"paymentMethod"}
              selectionEnum={PaymentMethod}
              marginTop={20}
              translationKey={"paymentMethod"}
            />
          </View>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <View paddingRight={depositLinePadding} paddingLeft={depositLinePadding}>
            <FastField
              marginTop={20}
              component={TextField}
              name={`lines[${lineIndex}].reference`}
              label={t(AppStrings.Common.ListHeader.Reference)}
            />
          </View>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <FastField
            component={Select}
            name={`lines[${lineIndex}].receivedFromResourceType`}
            label={AppStrings.Leases.LeaseTransaction.Payment.ReceivedFrom}
            uniqueKey={"receivedFromResourceType" + lineIndex}
            selectionEnum={isHOAUser ? HOAExpensePayToResourceType : ExpensePayToResourceType}
            marginTop={20}
            paddingRight={depositLinePadding}
            paddingLeft={depositLinePadding}
            onChange={didChangeReceivedFromResourceType}
            translationKey={"receivedFrom"}
          />
        </Grid>
        {renderReceivedFromResourceField()}
      </View>
    </View>
  );
};

export default DepositsLine;
