import type { UseQueryFilterOptions } from "@/hooks/useQueryFilter";
import { useQueryFilter } from "@/hooks/useQueryFilter";
import { useDispatchFilterAnalytics } from "./useDispatchFilterAnalytics";
import { SEARCH_QUERY_KEY } from "../filterPanel.constants";

interface UseFilterPanelQueryFilterOptions<TQuery> {
  queryFilterOptions?: UseQueryFilterOptions;
  initialQuery?: Parameters<typeof useQueryFilter<TQuery>>[1];
  onInputChange?: (key: string, value: string) => void;
}

export function useFilterPanelQueryFilter<TQuery>(
  filterOptions: Parameters<typeof useQueryFilter<TQuery>>[0],
  { initialQuery, ...options }: UseFilterPanelQueryFilterOptions<TQuery> = {}
): ReturnType<typeof useQueryFilter<TQuery>> {
  const optionsExtraKeys = options.queryFilterOptions?.extraKeys || [];
  const extraKeys = [SEARCH_QUERY_KEY, ...optionsExtraKeys];

  const queryFilter = useQueryFilter<TQuery>(filterOptions, initialQuery, {
    ...options.queryFilterOptions,
    extraKeys
  });

  useDispatchFilterAnalytics(queryFilter, {
    onInputChange: options.onInputChange
  });

  return queryFilter;
}
