import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) =>
  createStyles({
    root: {
      "& .MuiFormHelperText-root": {
        color: `${theme.newPalette.error.main} !important`
      },
      "& .MuiFormHelperText-contained": {
        whiteSpace: "pre-wrap"
      },
      "& fieldset": {
        borderColor: `${theme.newPalette.input.outlined.enabledBorder} !important`
      },
      "&:hover fieldset": {
        borderColor: `${theme.newPalette.input.outlined.hoverBorder} !important`
      },
      "& .Mui-focused fieldset": {
        borderColor: `${theme.newPalette.secondary.main} !important`
      },
      "& .Mui-error fieldset": {
        borderColor: `${theme.newPalette.error.main} !important`
      },
      "& .Mui-disabled fieldset": {
        color: `${theme.newPalette.text.disabled} !important`,
        borderColor: `${theme.newPalette.input.outlined.disabledBorder} !important`
      },

      "& .MuiInputBase-formControl": {
        "&.Mui-disabled": {
          backgroundColor: `${theme.newPalette.background.neutral1} !important`
        }
      },
      "& .MuiInputBase-input": {
        "&:not(.Mui-disabled)": {
          color: `${theme.newPalette.text.primary} !important`
        },
        "&.Mui-disabled": {
          backgroundColor: `transparent !important`,
          color: theme.newPalette.text.disabled
        }
      },
      "& .MuiInputLabel-": {
        "&asterisk": {
          color: theme.newPalette.error.main
        },
        "&shrink": {
          color: `${theme.newPalette.text.secondary} !important`,

          "&.Mui-focused": {
            color: `${theme.newPalette.secondary.main} !important`
          },

          "&.Mui-error": {
            color: `${theme.newPalette.error.main} !important`
          }
        },
        "&root": {
          color: theme.newPalette.text.secondary,

          "& .Mui-focused fieldset": {
            color: `${theme.newPalette.text.primary} !important`
          },

          "& .Mui-error fieldset": {
            color: `${theme.newPalette.error.main} !important`
          }
        }
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: theme.newPalette.secondary.main
      }
    },
    warning: {
      "& .MuiFormHelperText-root": {
        color: `${theme.newPalette.warning.main} !important`
      },
      "& .Mui-error fieldset": {
        borderColor: `${theme.newPalette.warning.main} !important`
      },
      "& .MuiInputLabel-": {
        "&shrink": {
          color: `${theme.newPalette.warning.main} !important`,

          "&.Mui-focused": {
            color: `${theme.newPalette.warning.main} !important`
          },

          "&.Mui-error": {
            color: `${theme.newPalette.warning.main} !important`
          }
        },
        "&root": {
          "&.Mui-error fieldset": {
            color: `${theme.newPalette.warning.main} !important`
          }
        }
      }
    },
    label: {
      "& .MuiInputLabel-": {
        "&asterisk": {
          color: theme.newPalette.error.main
        }
      }
    }
  })
);
