import React from "react";
import { View } from "DLUI/view";
import TextEllipsis from "DLUI/text/textEllipsis";

interface ComponentProps {
  value?: string;
  marginRight?: number;
  marginLeft?: number | "auto";
  backgroundColor?: "dark" | "secondary-gray";
  dataCy?: string;
}

const MaxWidth = 85;

const ReferenceLabel: React.FC<ComponentProps> = ({
  value,
  marginRight,
  backgroundColor,
  marginLeft,
  dataCy
}: ComponentProps) => {
  if (!value) {
    return null;
  }

  const className = backgroundColor === "secondary-gray" ? "backgroundOpacity" : "";

  return (
    <View
      className={className}
      justifyContent={"center"}
      alignItems={"center"}
      height={25}
      borderRadius={30}
      backgroundColor={backgroundColor}
      width={"auto"}
      maxWidth={MaxWidth}
      marginRight={marginRight}
      marginLeft={marginLeft}
      noWrap
      style={{
        backgroundColor: backgroundColor === "secondary-gray" ? "rgba(213,217,227,0.8)" : "none"
      }}
    >
      <TextEllipsis
        textTransform={"uppercase"}
        data-cy={dataCy}
        color={"gray"}
        fontSize={12}
        paddingRight={10}
        marginLeft={10}
        text={`# ${value}`}
      />
    </View>
  );
};

export default ReferenceLabel;
