import type { CSSProperties } from "react";
import React, { createRef, useEffect, useMemo, useState } from "react";
import { View } from "DLUI/view";
import type { MenuItem } from "DLUI/dialogs";
import { Icon } from "DLUI/icon";
import Text from "DLUI/text";
import type { FormikProps } from "formik";
import type { HasTenantsAutoPayments, MerchantAccountDto } from "@doorloop/dto";
import { MOBILE_TOP_NAVIGATION_BAR_HEIGHT } from "DLUI/screen/mobile/mobileTopNavigationBar";
import "./styles.css";

import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  sectionItems: MenuItem[];
  formik: FormikProps<any>;
  onFileReceive: (receivedFile: File) => void;
  onTenantPortalLogoFileReceive: (receivedFile: File) => void;
  onCoverImageFileReceive: (receivedFile: File) => void;
  onFileDelete: () => void;
  onTenantPortalLogoFileDelete: () => void;
  onCoverImageFileDelete: () => void;
  merchantAccountData: MerchantAccountDto | undefined;
  transunionEligibilityMessage: string | undefined;
  transunionFee: number | undefined;
  hasTenantsAutoPayments: HasTenantsAutoPayments | undefined;
  renderActionPanelButtons: React.FC<FormikProps<any>>;
  dialogHeight?: number;
  landlordId?: number;
  transunionPropertyId?: number;
  selectedMenuIndex?: number;
  stickyActionButtonsStyle?: CSSProperties;
  onChangeSelectedItem?: (index: number) => void;
  mobileScrollToSelectedMenuItem?: boolean;
  menuItemsStyle?: CSSProperties;
  removeScroll?: boolean;
}

const MenuContainerWidth = 60;
const MenuSelectionIndicatorWidth = 170;

const AnimatedContentMobileLayout: React.FC<ComponentProps> = ({
  sectionItems,
  formik,
  onFileReceive,
  onTenantPortalLogoFileReceive,
  onCoverImageFileReceive,
  onFileDelete,
  onTenantPortalLogoFileDelete,
  onCoverImageFileDelete,
  merchantAccountData,
  transunionEligibilityMessage,
  transunionFee,
  hasTenantsAutoPayments,
  renderActionPanelButtons,
  dialogHeight,
  landlordId,
  transunionPropertyId,
  selectedMenuIndex = 0,
  stickyActionButtonsStyle,
  onChangeSelectedItem,
  mobileScrollToSelectedMenuItem,
  menuItemsStyle,
  removeScroll
}) => {
  const { screenContainerPadding } = useResponsiveHelper();
  const [selectedViewIndex, setSelectedViewIndex] = useState<number>(selectedMenuIndex);
  const itemsRef = useMemo(() => sectionItems.map(() => createRef<HTMLDivElement>()), [sectionItems]);

  useEffect(() => {
    setSelectedViewIndex(selectedMenuIndex);
    if (selectedMenuIndex && mobileScrollToSelectedMenuItem) {
      const currentMenuTab = itemsRef[selectedMenuIndex].current;
      currentMenuTab?.scrollIntoView({
        inline: menuItems.length - 1 === selectedMenuIndex ? "end" : "center",
        block: "end",
        behavior: "smooth"
      });
    }
  }, [selectedMenuIndex]);

  const menuItems = useMemo(
    () =>
      sectionItems.map((currentItem: MenuItem, index: number) => {
        const didPressSectionItem = () => {
          setSelectedViewIndex(index);
          onChangeSelectedItem?.(index);
        };

        return (
          <View
            key={"MI" + index}
            domRef={itemsRef[index]}
            flexDirection={"row"}
            width={MenuSelectionIndicatorWidth}
            alignItems={"center"}
            justifyContent={"center"}
            onClick={didPressSectionItem}
            marginRight={10}
            marginLeft={10}
          >
            <Icon pathColor={"white"} width={15} height={15} Source={currentItem.icon} />
            <Text marginLeft={10} color={"white"} value={currentItem.menuItemTitle || currentItem.title} />
          </View>
        );
      }),
    [sectionItems]
  );

  const renderCurrentView = () =>
    sectionItems.map((currentItem: MenuItem, index: number) => {
      if (selectedViewIndex === index) {
        return currentItem.contentComponent(
          formik,
          onFileReceive,
          onFileDelete,
          onTenantPortalLogoFileReceive,
          onTenantPortalLogoFileDelete,
          onCoverImageFileReceive,
          onCoverImageFileDelete,
          merchantAccountData,
          transunionEligibilityMessage,
          transunionFee,
          hasTenantsAutoPayments,
          landlordId,
          transunionPropertyId
        );
      }
    });

  const renderSelectionIndicator = () => (
    <View
      backgroundColor={"green"}
      width={MenuSelectionIndicatorWidth}
      height={10}
      borderRadius={5}
      className={"menuSelectionIndicator"}
      style={{
        left: 20 + selectedViewIndex * MenuSelectionIndicatorWidth + 20 * selectedViewIndex,
        position: "absolute"
      }}
    />
  );

  return (
    <View>
      <View
        overflow={"scroll"}
        flexDirection={"row"}
        height={MenuContainerWidth}
        alignItems={"center"}
        backgroundColor={"blue"}
        noWrap
        className={removeScroll ? "hideScroll" : undefined}
      >
        {menuItems}
        {renderSelectionIndicator()}
      </View>
      <View
        paddingRight={screenContainerPadding}
        paddingLeft={screenContainerPadding}
        overflow={"scroll"}
        className={removeScroll ? "hideScroll" : undefined}
        height={
          dialogHeight
            ? dialogHeight - MOBILE_TOP_NAVIGATION_BAR_HEIGHT
            : `calc(100dvh - ${MenuContainerWidth + MOBILE_TOP_NAVIGATION_BAR_HEIGHT}px)`
        }
        noWrap
        style={menuItemsStyle}
      >
        <View flex={1} width={"100%"} marginTop={20}>
          {renderCurrentView()}
        </View>
        <View style={stickyActionButtonsStyle} height={80}>
          {renderActionPanelButtons(formik)}
        </View>
      </View>
    </View>
  );
};

export default AnimatedContentMobileLayout;
