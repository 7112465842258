import type { GetAllLeasesQuery, LeaseDto } from "@doorloop/dto";
import { OwnerPortalServerRoutes } from "@doorloop/dto";

import { RestApiBaseWithDictionary } from "api/restApiBaseWithDictionary";

export class OwnerPortalLeasesApi extends RestApiBaseWithDictionary<LeaseDto, GetAllLeasesQuery> {
  constructor() {
    super(OwnerPortalServerRoutes.LEASES);
    this.restRoute = OwnerPortalServerRoutes.LEASES;
  }
}

export const ownerPortalLeasesApi = new OwnerPortalLeasesApi();
