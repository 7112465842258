import type { DialogProps } from "./dialogsProvider.types";
import { isEditFromDetailScreen } from "./dialogsProvider.utils";
import { DialogRoutes } from "./dialogsRoutes";
import Routes from "../../../appRouter/routes";
import EditUnitDialog from "DLUI/dialogs/components/editUnit/editUnitDialog";
import AppStrings from "locale/keys";

class UnitsDialogsProvider {
  isEditTenantDialog(pathname: string) {
    return /.*tenants.*edit\/[0-9a-fA-F]{24}$/.test(pathname);
  }

  matchRoutes(pathname: string): DialogProps | undefined {
    if (pathname.indexOf(DialogRoutes.NEW_UNIT) > -1) {
      return {
        type: "newUnit",
        Content: EditUnitDialog,
        title: AppStrings.Units.NewUnitDialog.Title
      };
    }
    if (
      !this.isEditTenantDialog(pathname) &&
      (pathname.indexOf(DialogRoutes.EDIT_UNIT) > -1 ||
        isEditFromDetailScreen("units", 2, 1, Routes.UNIT_DETAILS_EDIT, pathname) ||
        isEditFromDetailScreen("units", 2, 2, Routes.UNIT_DETAILS_EDIT_DIALOG_TAB, pathname))
    ) {
      return {
        type: "newUnit",
        Content: EditUnitDialog,
        title: AppStrings.Units.NewUnitDialog.EditTitle
      };
    }
    return undefined;
  }
}

export const unitsDialogsProvider = new UnitsDialogsProvider();
