"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkBaseAllocationDto = void 0;
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const base_dto_1 = require("../base.dto");
const leaseTransactionWithUnitBase_dto_1 = require("../journalEntries/shared/leaseTransactionWithUnitBase.dto");
const allocation_enum_1 = require("./allocation.enum");
class BulkBaseAllocationDto extends base_dto_1.BaseDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.BulkBaseAllocationDto = BulkBaseAllocationDto;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], BulkBaseAllocationDto.prototype, "property", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], BulkBaseAllocationDto.prototype, "account", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsNumber)({ maxDecimalPlaces: 2 }),
    (0, class_validator_1.Min)(0.01),
    (0, class_validator_1.ValidateIf)((o) => o.strategy !== allocation_enum_1.AllocationStrategyWithCustom.MANUAL),
    __metadata("design:type", Object)
], BulkBaseAllocationDto.prototype, "total", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(allocation_enum_1.AllocationStrategyWithCustom),
    __metadata("design:type", String)
], BulkBaseAllocationDto.prototype, "strategy", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(allocation_enum_1.AllocationRounding),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateIf)((o) => o.strategy === allocation_enum_1.AllocationStrategyWithCustom.MANUAL),
    __metadata("design:type", String)
], BulkBaseAllocationDto.prototype, "rounding", void 0);
__decorate([
    (0, class_validator_1.IsArray)(),
    (0, class_transformer_1.Type)(() => leaseTransactionWithUnitBase_dto_1.LeaseTransactionWithUnitBaseDto),
    (0, class_validator_1.ValidateNested)({ each: true }),
    __metadata("design:type", Array)
], BulkBaseAllocationDto.prototype, "transactions", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((o) => o.strategy === allocation_enum_1.AllocationStrategyWithCustom.CUSTOM),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], BulkBaseAllocationDto.prototype, "customAllocation", void 0);
