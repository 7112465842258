"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecurringTransactionType = void 0;
var RecurringTransactionType;
(function (RecurringTransactionType) {
    RecurringTransactionType["GENERAL_ENTRY"] = "GENERAL_ENTRY";
    RecurringTransactionType["LEASE_CHARGE"] = "LEASE_CHARGE";
    RecurringTransactionType["LEASE_RENT"] = "LEASE_RENT";
    RecurringTransactionType["LEASE_PAYMENT"] = "LEASE_PAYMENT";
    RecurringTransactionType["LEASE_CREDIT"] = "LEASE_CREDIT";
    RecurringTransactionType["VENDOR_BILL"] = "VENDOR_BILL";
    RecurringTransactionType["EXPENSE"] = "EXPENSE";
    RecurringTransactionType["TASK"] = "TASK";
})(RecurringTransactionType || (exports.RecurringTransactionType = RecurringTransactionType = {}));
