import BankDepositDialog from "DLUI/dialogs/otherTransactions/bankDeposit/bankDeposit";
import AppStrings from "locale/keys";
import { DialogRoutes } from "./dialogsRoutes";
import type { DialogProps } from "./dialogsProvider.types";
import { ObjectPermission } from "@doorloop/dto";
import IdentityVerificationDialog from "screens/incomingPayments/identityVerification/identityVerificationDialog";
import { StripeApplyNow } from "screens/incomingPayments/tabs/merchantAccountSetup/stripeOnbording/stripeApplyNow";
import { StripeActionRequiredEIN } from "screens/incomingPayments/tabs/merchantAccountSetup/stripeOnbording/stripeActionRequiredEIN";
import { StripeActionRequiredBankAccount } from "screens/incomingPayments/tabs/merchantAccountSetup/stripeOnbording/stripeActionRequiredBankAccount";
import { StripeActionRequiredOther } from "screens/incomingPayments/tabs/merchantAccountSetup/stripeOnbording/stripeActionRequiredOther";
import { StripeUpdateMerchantAccount } from "screens/incomingPayments/tabs/merchantAccountSetup/stripeOnbording/stripeUpdateMerchantAccount";
import { BankAccountConnectDialog } from "screens/transactions/components/bankConnect/dialogs/bankConnect/bankConnect";
import { BankDisconnectDialog } from "screens/transactions/components/bankConnect/dialogs/bankDisconnect";

interface AccountDialogRoutes {
  path: DialogRoutes;
  props: DialogProps;
}

const routes: AccountDialogRoutes[] = [
  {
    path: DialogRoutes.NEW_CHART_OF_ACCOUNT,
    props: { type: "newAccount", title: AppStrings.Accounts.ChartOfAccounts.NewAccount }
  },
  { path: DialogRoutes.BANK_ACCOUNT_DISCONNECT, props: { Content: BankDisconnectDialog } },
  {
    path: DialogRoutes.EDIT_CHART_OF_ACCOUNT,
    props: { type: "newAccount", title: AppStrings.Accounts.ChartOfAccounts.EditAccount }
  },
  {
    path: DialogRoutes.EDIT_BANK_DEPOSIT,
    props: {
      type: "bankDeposit",
      title: AppStrings.OtherTransactions.BankDeposit.EditBankDeposit,
      Content: BankDepositDialog,
      permission: { permission: ObjectPermission.bankDeposits, field: "viewOne" }
    }
  },
  {
    path: DialogRoutes.BANK_ACCOUNT_REPAIR_CONNECTION,
    props: { title: AppStrings.OtherTransactions.BankDeposit.EditBankDeposit, Content: BankAccountConnectDialog }
  },
  {
    path: DialogRoutes.BANK_ACCOUNT_CONNECT,
    props: { title: AppStrings.OtherTransactions.BankDeposit.EditBankDeposit, Content: BankAccountConnectDialog }
  },
  { path: DialogRoutes.VERIFY_IDENTITY, props: { Content: IdentityVerificationDialog } },
  { path: DialogRoutes.STRIPE_MERCHANT_ACCOUNT, props: { Content: StripeApplyNow } },
  { path: DialogRoutes.STRIPE_ACTION_REQUIRED_EIN, props: { Content: StripeActionRequiredEIN } },
  { path: DialogRoutes.STRIPE_ACTION_REQUIRED_BANK_ACCOUNT, props: { Content: StripeActionRequiredBankAccount } },
  { path: DialogRoutes.STRIPE_ACTION_REQUIRED_OTHER, props: { Content: StripeActionRequiredOther } },
  { path: DialogRoutes.STRIPE_UPDATE_MERCHANT_ACCOUNT, props: { Content: StripeUpdateMerchantAccount } }
];

export const accountsDialogsProvider = {
  matchRoutes: (pathname: string): DialogProps | undefined =>
    routes.find((route) => pathname.includes(route.path))?.props
};
