import React from "react";
import MomentUtils from "@date-io/moment";
import { PropertyAndUnitDto } from "@doorloop/dto";
import { ProspectStatus } from "@doorloop/dto";
import { TagType } from "@doorloop/dto";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { tagsApi } from "api/tagsApi";
import { usersApi } from "api/usersApi";
import { AddIcon } from "assets/icons";
import { Button } from "DLUI/button";
import { FormikDatePicker, Select, TextField } from "DLUI/form";
import { SectionTitle } from "DLUI/screen";
import { SeparationLine } from "DLUI/separatorView";
import { View } from "DLUI/view";
import type { FieldArrayRenderProps, FormikProps } from "formik";
import { FastField, FieldArray, getIn } from "formik";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import InterestLine from "./interestLine";
import FieldWrapper from "DLUI/form/textField/fieldWrapper";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import type { SelectInputProps } from "DLUI/form/select/selectInput";

interface ComponentProps {
  formikRef: FormikProps<any>;
}

const MarketingProfile: React.FC<ComponentProps> = ({ formikRef }: ComponentProps) => {
  const { t } = useTranslation();
  const assignedToDefaultValue = getIn(formikRef.values, "prospectInfo.assignedToUser");

  const leadSourceDefaultValue = getIn(formikRef.values, "prospectInfo.leadSource");

  const renderAddButton = (arrayHelpers: FieldArrayRenderProps) => {
    const didPressAddInterest = () => {
      const nextLine = new PropertyAndUnitDto();
      arrayHelpers.push(nextLine);
    };

    return (
      <View marginTop={10}>
        <Button
          color={"lightBlue"}
          type={"inlineText"}
          actionText={AppStrings.Prospects.NewProspect.AddAnotherProperty}
          onClick={didPressAddInterest}
          LeftIcon={AddIcon}
          applyColorForIcons
          iconSize={15}
        />
      </View>
    );
  };

  const renderRelatedToSection = () => (
    <FieldArray
      name={"prospectInfo.interests"}
      render={(arrayHelpers) => {
        if (formikRef.values && formikRef.values.prospectInfo && formikRef.values.prospectInfo.interests) {
          const formLines = formikRef.values.prospectInfo.interests;

          const didPressDeleteLine = (lineIndex: number) => {
            arrayHelpers.remove(lineIndex);
          };

          const lines = formLines.map((currentLine: PropertyAndUnitDto, lineIndex: number) => (
            <View key={"LTL" + lineIndex}>
              <InterestLine
                name={`prospectInfo.interests[${lineIndex}]`}
                formikRef={formikRef}
                didPressDeleteLine={didPressDeleteLine}
                lineIndex={lineIndex}
              />
            </View>
          ));

          return (
            <View shouldShow showAnimation={"fade-in"} hideAnimation={"fade-out"}>
              {lines}
              <View>{renderAddButton(arrayHelpers)}</View>
            </View>
          );
        }
        return <div />;
      }}
    />
  );

  const onTransformProspectStatus: SelectInputProps["onTransform"] = (items) =>
    items.map(({ value, label }) => {
      return { label, value: ProspectStatus[value] };
    });

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <View flex={1}>
        <View width={"100%"}>
          <View alignItems={"flex-end"} flexDirection={"row"}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FieldWrapper type={"endElement"}>
                <FormikCachedAsyncAutoComplete
                  uniqueIndex={"usersApi"}
                  apiHandler={usersApi}
                  displayNameKey={"name"}
                  filterFieldName={"filter_text"}
                  filterFieldValue={"name"}
                  selectionFields={["id", "class"]}
                  name={"prospectInfo.assignedToUser"}
                  label={t(AppStrings.Tasks.TasksList.assignedTo)}
                  defaultValue={assignedToDefaultValue}
                />
              </FieldWrapper>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FieldWrapper type={"startElement"}>
                <FastFieldSafe
                  component={Select}
                  name={`prospectInfo.status`}
                  label={AppStrings.Prospects.NewProspect.LeadStatus}
                  uniqueKey={"taskPriority"}
                  selectionEnum={ProspectStatus}
                  translationKey={"prospectStatus"}
                  onTransform={onTransformProspectStatus}
                  marginTop={20}
                />
              </FieldWrapper>
            </Grid>
          </View>
          <SeparationLine marginTop={20} height={1} width={"95%"} />
          <View alignItems={"flex-end"} flexDirection={"row"}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FieldWrapper type={"endElement"}>
                <FormikCachedAsyncAutoComplete
                  label={t(AppStrings.Prospects.NewProspect.LeadSource)}
                  uniqueIndex={"leadSource"}
                  apiHandler={tagsApi}
                  displayNameKey={"name"}
                  filterFieldName={"filter_text"}
                  filterFieldValue={"name"}
                  selectionFields={["id", "class"]}
                  name={"prospectInfo.leadSource"}
                  queryParams={{ filter_type: TagType.LEAD_SOURCE }}
                  marginTop={20}
                  defaultValue={leadSourceDefaultValue}
                />
              </FieldWrapper>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FieldWrapper type={"startElement"}>
                <FastField
                  component={TextField}
                  label={t(AppStrings.Prospects.NewProspect.LeadMedium)}
                  name={`prospectInfo.campaignMedium`}
                  marginTop={20}
                />
              </FieldWrapper>
            </Grid>
          </View>
          <View alignItems={"flex-end"} flexDirection={"row"}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FieldWrapper type={"endElement"}>
                <FastField
                  component={TextField}
                  label={t(AppStrings.Prospects.NewProspect.CreditScore)}
                  name={`prospectInfo.creditScore`}
                  formatType={"number"}
                  marginTop={20}
                />
              </FieldWrapper>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FieldWrapper type={"startElement"}>
                <FastField
                  component={TextField}
                  label={t(AppStrings.Prospects.NewProspect.MonthlyIncome)}
                  name={`prospectInfo.monthlyIncome`}
                  formatType={"currency"}
                  marginTop={20}
                />
              </FieldWrapper>
            </Grid>
          </View>
          <SectionTitle type={"underline"} title={AppStrings.Prospects.NewProspect.SearchCriteria} marginTop={20} />
          <View alignItems={"flex-end"} flexDirection={"row"}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FieldWrapper type={"endElement"}>
                <FastField
                  component={TextField}
                  label={t(AppStrings.Prospects.NewProspect.MinBedrooms)}
                  name={`prospectInfo.minBedrooms`}
                  marginTop={20}
                  formatType={"number"}
                  decimalScale={1}
                />
              </FieldWrapper>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FieldWrapper type={"startElement"}>
                <FastField
                  component={TextField}
                  label={t(AppStrings.Prospects.NewProspect.MinBathrooms)}
                  name={`prospectInfo.minBathrooms`}
                  formatType={"number"}
                  decimalScale={1}
                  marginTop={20}
                />
              </FieldWrapper>
            </Grid>
          </View>
          <View alignItems={"flex-end"} flexDirection={"row"}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FieldWrapper type={"endElement"}>
                <FastField
                  component={TextField}
                  label={t(AppStrings.Prospects.NewProspect.MaxRent)}
                  name={`prospectInfo.maxRent`}
                  formatType={"currency"}
                  marginTop={20}
                />
              </FieldWrapper>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FieldWrapper type={"startElement"}>
                <FastField
                  component={FormikDatePicker}
                  label={t(AppStrings.Prospects.NewProspect.MoveInDate)}
                  name={`prospectInfo.expectedMoveInDate`}
                  noMargin
                  marginTop={20}
                />
              </FieldWrapper>
            </Grid>
          </View>
          <SectionTitle title={t(AppStrings.Tasks.InternalTaskDialog.RelatedTo)} type={"underline"} marginTop={20} />
          {renderRelatedToSection()}
        </View>
      </View>
    </MuiPickersUtilsProvider>
  );
};

export default MarketingProfile;
