"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsTIN = IsTIN;
const class_validator_1 = require("class-validator");
const tinValidator = require("tin-validator");
function IsTIN(validationOptions) {
    return function (object, propertyName) {
        (0, class_validator_1.registerDecorator)({
            name: "isTIN",
            target: object.constructor,
            propertyName,
            constraints: [],
            options: validationOptions,
            validator: {
                validate(value, args) {
                    if (!value)
                        return false;
                    return tinValidator.isValid(value);
                }
            }
        });
    };
}
