import React, { useState } from "react";
import { View } from "DLUI/view";
import { Grid, Hidden } from "@material-ui/core";
import { CheckmarkCircleIcon } from "assets/.";
import Text from "DLUI/text";
import { Icon } from "DLUI/icon";
import { ProgressBar } from "DLUI/progressBar/progressBar";
import AppStrings from "locale/keys";
import { AccordionItem } from "DLUI/accordionItem";
import { Dialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { SupportVideoDialog } from "DLUI/screen/helpPanel/supportVideoDialog";
import Image from "DLUI/image/image";
import { StepImageFileNames } from "screens/onBoarding/imageFileNames";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface StepSectionProps {
  icon: React.ReactElement;
  title: string;
  leftSideContent: React.ReactElement;
  mediaContent?: React.ReactElement;
  isCompleted?: boolean;
  isSkipped?: boolean;
  isCollapsed?: boolean;
  progress?: number;
  total?: number;
  progressText?: string;
  onHeaderClick?: () => void;
  isLoading?: boolean;
  reactPlayerVideoUrl?: string;
}

const StepSection: React.FC<StepSectionProps> = ({
  icon,
  isCollapsed = false,
  isCompleted,
  isSkipped,
  mediaContent,
  leftSideContent,
  progressText,
  progress,
  title,
  total,
  onHeaderClick,
  isLoading,
  reactPlayerVideoUrl
}) => {
  const { isTabletOrMobile, isMobile } = useResponsiveHelper();

  const [helpDialogState, setHelpDialogState] = useState<DialogState>(DialogState.Hidden);
  const [videoLinkHref, setVideoLinkHref] = useState<string>("");
  const [activeVideoTitle, setActiveVideoTitle] = useState<string>("");

  /**
   * We define `isStepCompleted` to be true if `isCompleted` is true or `isSkipped` is true.
   * OR
   * if `progress` is equal to `total` AND `total` is a valid number greater than 0
   */
  const isStepCompleted =
    isCompleted || isSkipped || (progress !== undefined && total !== undefined && total > 0 && progress === total);

  /**
   * Should show the right side status (progress bar/completion text)
   */
  const shouldShowStepStatus = isCompleted || isSkipped || (progress !== undefined && total !== undefined);

  const onCloseHelpVideo = () => {
    setVideoLinkHref("");
    setHelpDialogState(DialogState.Hidden);
  };

  const openHelpVideo = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!reactPlayerVideoUrl) {
      console.error("Developer error: No video url provided");
      return;
    }

    setActiveVideoTitle(title);
    setVideoLinkHref(reactPlayerVideoUrl);
    setHelpDialogState(DialogState.Show);
  };

  const headerIconElement = (
    <View
      backgroundColor={"secondary-gray-light"}
      width={40}
      height={40}
      alignItems={"center"}
      justifyContent={"center"}
    >
      {icon}
    </View>
  );

  const headerRightSide = shouldShowStepStatus ? (
    <View
      flexDirection="row"
      alignItems={"center"}
      justifyContent={isTabletOrMobile ? "flex-start" : "flex-end"}
      gap={5}
      minWidth={300}
    >
      {isStepCompleted || (total && progress && total - progress < 0.001) ? (
        <>
          {/* Simple Completed text if completed */}
          <Icon Source={CheckmarkCircleIcon} size={16} />
          <Text bold value={AppStrings.Dashboards.completed} fontSize={16} color={"green"} />
        </>
      ) : (
        <Grid container alignItems={"center"} spacing={1}>
          {progressText && (
            <Grid item xs={12} sm={12} md={6}>
              <Text align={isTabletOrMobile ? "left" : "right"} value={progressText} fontSize={14} color={"green"} />
            </Grid>
          )}
          {/* Hide the progress bar on mobile */}
          <Hidden smDown>
            <Grid item xs={12} sm={12} md={6}>
              {progress !== undefined && total != undefined && total != 0 && (
                <ProgressBar progress={progress} total={total} />
              )}
            </Grid>
          </Hidden>
        </Grid>
      )}
    </View>
  ) : undefined;

  const videoThumbnail = (
    <Image
      height={"200px"}
      style={{ cursor: "pointer", borderRadius: 5 }}
      onClick={openHelpVideo}
      src={StepImageFileNames.VIDEO_PLACEHOLDER}
    />
  );

  const sectionBody = (
    <Grid container direction={isMobile ? "column-reverse" : "row"} spacing={2}>
      {/*Left side content*/}
      <Grid item md={8} sm={12} xs={12}>
        {leftSideContent}
      </Grid>

      {/*Right side content (media - video/image) */}
      <Grid item md={4} sm={12} xs={12} container alignItems={"center"}>
        <View
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={isTabletOrMobile ? "center" : "flex-end"}
          minHeight={200}
        >
          {mediaContent}
          {reactPlayerVideoUrl && videoThumbnail}
        </View>
      </Grid>
    </Grid>
  );

  const dialogView = helpDialogState === DialogState.Show && (
    <Dialog
      dialogState={helpDialogState}
      onClose={onCloseHelpVideo}
      Content={SupportVideoDialog}
      onBackdropClick={onCloseHelpVideo}
      href={videoLinkHref}
      dialogTitle={activeVideoTitle}
    />
  );

  return (
    <>
      {dialogView}
      <AccordionItem
        isCollapsed={isCollapsed}
        style={{ zIndex: 10 }}
        headerIconElement={headerIconElement}
        headerRightSide={headerRightSide}
        title={title}
        onHeaderClick={onHeaderClick}
        backgroundColor="white"
        isLoading={isLoading}
      >
        {sectionBody}
      </AccordionItem>
    </>
  );
};

export default StepSection;
