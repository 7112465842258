/* eslint-disable no-implicit-coercion */

import AppStrings from "locale/keys";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import EntryPermission from "../entryPermission";
import RelatedToSection from "../relatedToSection";
import TaskInfo from "../taskInfo";
import PartsAndLabors from "./partsAndLabors";

import {
  createValidator,
  DateFormats,
  DuplicateDialogButtonLabelValues,
  LinkedResourceType,
  OnetimeToRecurringMapper,
  TaskDateType,
  TaskDto,
  TaskStatus,
  TaskType,
  WorkOrderDto,
  WorkOrderPermissionToEnter
} from "@doorloop/dto";

import { DialogHeaderActionButtons } from "@/components/DLUI/actionButtons/dialogHeaderActionButtons";
import { PeopleAutoComplete } from "@/components/DLUI/form/autoComplete/peopleAutoComplete/peopleAutoComplete";
import { usePeopleAutoComplete } from "@/components/DLUI/form/autoComplete/peopleAutoComplete/usePeopleAutoComplete";
import { useAnalyticsService } from "@/hooks/useAnalyticsService";
import { useTypedTranslation } from "@/locale";
import { DataCy, PersonTypeEnum } from "@doorloop/dto";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { FormAttachments } from "DLUI/dropZone";
import { SwitchButton } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import { View } from "DLUI/view";
import { filesApi } from "api/filesApi";
import { leasesRecurringTransactionsApi } from "api/leasesApi";
import { tasksApi } from "api/tasksApi";
import { Routes } from "components/appRouter";
import { FormikProvider, useFormik } from "formik";
import { useRecurringState } from "hooks/useRecurringState";
import { isEmpty, noop, size } from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";
import { renderReferenceString } from "../../../../../utils/strings";
import { getDialogFrameDimension } from "../../components/dialogFrame";
import {
  cleanRecurringTransactionDataForDuplicateMode,
  cleanTaskDataForDuplicateMode,
  DUPLICATE_MODE_QUERY_PARAMS
} from "../../duplicateModeHelper";
import { DialogState } from "../../loadingDialog";
import { useSearchParams } from "@/hooks/useSearchParams";

import type { ApiResult, BaseDto, RecurringTransactionDto } from "@doorloop/dto";
import type { FileListItemProps } from "DLUI/dropZone";
import type { IRootState } from "store";
import type { FrameType } from "../../components/dialogFrame";

export interface WorkOrderDialogProps {
  onClose: (task?: TaskDto) => void;
  onBackdropClick: () => void;
  dialogTitle: string;
  editingRecurring?: boolean;
  apiObjectId?: string;
  initialTaskData?: TaskDto;
}

export type WorkOrderDialogRouteParams = Partial<{
  propertyId: string;
  unitId: string;
  leaseId: string;
  vendorId: string;
  parentTaskId: string;
  taskId: string;
}>;

enum DialogView {
  FORM,
  LOADING
}

const DIALOG_VIEWS_NUM = size(DialogView);

const validateForm = createValidator(TaskDto);

const dialogWidth = getDialogFrameDimension("width", 880);
const dialogHeight = getDialogFrameDimension("height", 1320);

const WorkOrderDialog: React.FC<WorkOrderDialogProps> = ({
  onClose,
  onBackdropClick,
  editingRecurring: isEditingRecurring,
  dialogTitle,
  apiObjectId,
  initialTaskData: initialTaskDto
}) => {
  const { screenContainerPadding } = useResponsiveHelper();

  const { t } = useTranslation();
  const { t: tt } = useTypedTranslation();

  const location = useLocation();
  const routeParams = useParams<WorkOrderDialogRouteParams>();

  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = {
    taskId: searchParams.get(DUPLICATE_MODE_QUERY_PARAMS.taskId)
  };

  const taskId = apiObjectId || routeParams.taskId || queryParams.taskId;

  const isEditMode = !!taskId;
  const isDuplicateMode = !!queryParams.taskId;

  const { getValidator, getIsRecurring, getRecurringData, setRecurringData, RecurringContextWrapper } =
    useRecurringState(isEditMode, isEditingRecurring);

  const linkedResourceType = getIsRecurring() ? LinkedResourceType.RecurringTransactionTask : LinkedResourceType.Task;

  const userDto = useSelector((state: IRootState) => state.auth.currentLoginResponse);

  const [viewIndex, setViewIndex] = useState(DialogView.FORM);
  const [taskDto, setTaskDto] = useState(initialTaskDto);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    isEditMode ? DialogState.Show : DialogState.Hidden
  );

  const { dispatchAnalytics } = useAnalyticsService();

  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState("");
  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState("");
  const [showRelatedToSection, setShowRelatedToSection] = useState(true);

  const initialValues: TaskDto = useMemo(() => {
    if (taskDto) {
      return taskDto;
    }

    const initialTaskDto = new TaskDto({
      reference: renderReferenceString(),
      dateType: TaskDateType.DATE,
      status: TaskStatus.NOT_STARTED,
      type: TaskType.WORK_ORDER,
      workOrder: new WorkOrderDto(),
      entryPermission: WorkOrderPermissionToEnter.NOT_APPLICABLE,
      notifyAssignees: true
    });

    if (initialTaskDto.workOrder && routeParams.vendorId) {
      initialTaskDto.workOrder.assignedToVendor = routeParams.vendorId;
    }

    if (userDto) {
      initialTaskDto.requestedByUser = userDto.id;
      initialTaskDto.assignedToUsers = [userDto.id];
    }

    initialTaskDto.workOrder = new WorkOrderDto({
      assignedToVendor: initialTaskDto.workOrder?.assignedToVendor
    });

    return initialTaskDto;
  }, [taskDto, userDto, routeParams.vendorId]);

  const formik = useFormik<TaskDto>({
    initialValues,
    onSubmit: noop,
    validate: validateForm,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false
  });
  const { setFieldValue } = formik;

  // When the dialog is opened, auto select the "Related To" field based on the route params
  const autoSelectRelatedToField = useCallback(() => {
    if (routeParams.propertyId) {
      setFieldValue("linkedResource.resourceId", routeParams.propertyId);
      setFieldValue("linkedResource.resourceType", LinkedResourceType.Property);
      setShowRelatedToSection(false);
      return;
    }

    if (routeParams.unitId) {
      setFieldValue("linkedResource.resourceId", routeParams.unitId);
      setFieldValue("linkedResource.resourceType", LinkedResourceType.Unit);
      setShowRelatedToSection(false);
      return;
    }

    if (routeParams.leaseId) {
      const linkedResourceType = location.pathname.includes(Routes.DRAFT_LEASES)
        ? LinkedResourceType.LeaseDraft
        : LinkedResourceType.Lease;
      setFieldValue("linkedResource.resourceId", routeParams.leaseId);
      setFieldValue("linkedResource.resourceType", linkedResourceType);
      setShowRelatedToSection(false);
      return;
    }

    if (routeParams.vendorId) {
      setFieldValue("linkedResource.resourceId", routeParams.vendorId);
      setFieldValue("linkedResource.resourceType", LinkedResourceType.Vendor);
      setShowRelatedToSection(false);
      return;
    }

    if (routeParams.parentTaskId) {
      setFieldValue("linkedResource.resourceId", routeParams.parentTaskId);
      setFieldValue("linkedResource.resourceType", linkedResourceType);
      setShowRelatedToSection(false);
      return;
    }

    setShowRelatedToSection(true);
  }, [
    linkedResourceType,
    location.pathname,
    routeParams.leaseId,
    routeParams.parentTaskId,
    routeParams.propertyId,
    routeParams.unitId,
    routeParams.vendorId,
    setFieldValue
  ]);

  useEffect(() => {
    if (loadingDialogState === DialogState.Hidden) {
      autoSelectRelatedToField();
    }
  }, [autoSelectRelatedToField, loadingDialogState]);

  const showErrorMessage = useCallback(
    (message: string = t(AppStrings.Common.NetworkErrorSubTitle)) => {
      setLoadingDialogState(DialogState.Error);
      setLoadingDialogErrorText(message);
    },
    [t]
  );

  const fetchTask = useCallback(async () => {
    if (!taskId) {
      return;
    }

    setLoadingDialogState(DialogState.Show);

    const response = isEditingRecurring
      ? await leasesRecurringTransactionsApi.get(taskId).catch(showErrorMessage)
      : await tasksApi.get(taskId).catch(showErrorMessage);

    if (!response) {
      showErrorMessage(t("common.generalError"));
      return;
    }

    if (!response.data) {
      showErrorMessage(response.message);
      return;
    }

    let taskDto: TaskDto;

    if (isEditingRecurring) {
      const recurringData = response.data as RecurringTransactionDto;
      taskDto = OnetimeToRecurringMapper.buildOnetime.task(recurringData);
      if (isDuplicateMode) {
        setRecurringData(cleanRecurringTransactionDataForDuplicateMode(recurringData));
        taskDto.dueDate = moment().format(DateFormats.ISO_DATE_SERVER_FORMAT).toString();
      } else {
        setRecurringData(recurringData);
      }
    } else {
      const responseData = response.data as TaskDto;
      taskDto = isDuplicateMode ? cleanTaskDataForDuplicateMode(responseData) : responseData;
    }

    setTaskDto(taskDto);
    setLoadingDialogState(DialogState.Hidden);
  }, [isDuplicateMode, isEditingRecurring, setRecurringData, showErrorMessage, t, taskId]);

  useEffect(() => {
    if (isEditMode) {
      fetchTask();
    }
  }, [isEditMode, fetchTask]);

  const handleDismiss = () => {
    onBackdropClick();
  };

  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);

  const {
    state: { isIdle: isVendorIdle },
    props: vendorFieldProps
  } = usePeopleAutoComplete({
    currentType: PersonTypeEnum.VENDOR,
    props: {
      entityId: formik.values.workOrder?.assignedToVendor,
      textFieldProps: {
        placeholder: t("vendors.vendorDetails.selectVendor"),
        // @ts-expect-error Formik `errors` type is incorrect
        error: Boolean(formik.errors.workOrder?.assignedToVendor),
        // @ts-expect-error Formik `errors` type is incorrect
        helperText: formik.errors.workOrder?.assignedToVendor
      },
      onChange(_event, value) {
        formik.setFieldValue("workOrder.assignedToVendor", value?.data?.id, false);
        formik.setFieldError(
          "workOrder.assignedToVendor",
          value?.data?.id ? "" : tt("tasks.workOrderDialog.selectVendorValidationText")
        );
      },
      dataCy: DataCy.formFields.selectVendor
    },
    queryOptions: {
      typeFilter: [PersonTypeEnum.VENDOR]
    }
  });

  const renderForm = () => {
    if (!userDto) {
      return <div />;
    }

    const handleFileReceived = (files: FileListItemProps[]) => {
      setAttachments(files);
    };

    const handleApprovalChange = (value: boolean) => {
      formik.setFieldValue("workOrder.approvedByOwner", value);
    };

    return (
      <FormikProvider value={formik}>
        <View paddingLeft={screenContainerPadding} paddingRight={screenContainerPadding} noWrap>
          <View marginTop={20}>
            <PeopleAutoComplete {...vendorFieldProps} />
          </View>
          <View>
            <RecurringContextWrapper>
              <TaskInfo descriptionFieldLabel={t(AppStrings.Tasks.WorkOrderDialog.DescriptionFieldLabel)} isWorkOrder />
            </RecurringContextWrapper>
          </View>
          <View>
            <SeparationLine width={"100%"} height={1} marginTop={20} />
            <EntryPermission />
            <SwitchButton
              onChange={handleApprovalChange}
              label={AppStrings.Tasks.WorkOrderDialog.ApprovedByOwner}
              checked={formik.values.workOrder?.approvedByOwner}
              marginTop={20}
            />
            <SeparationLine width={"100%"} height={1} marginTop={20} />
            <PartsAndLabors formikRef={formik} />
            {showRelatedToSection && <SeparationLine width={"100%"} height={1} marginTop={20} />}
            <RelatedToSection shouldShow={showRelatedToSection} formikRef={formik} />
            <View marginBottom={20} marginTop={20}>
              <FormAttachments
                onFileReceived={handleFileReceived}
                files={attachments}
                editMode={isEditMode}
                resourceId={formik.values.id}
                resourceType={linkedResourceType}
              />
            </View>
          </View>
        </View>
      </FormikProvider>
    );
  };

  const handleBackdropClick = () => {
    if (viewIndex > DialogView.FORM) {
      setViewIndex(viewIndex - 1);
      return;
    }
    onBackdropClick();
  };

  const currentTitle = useMemo(() => {
    if (viewIndex === DialogView.FORM) {
      return dialogTitle;
    }
    return "";
  }, [dialogTitle, viewIndex]);

  const duplicateModeTitle = useMemo(
    () => (isDuplicateMode ? `${t(AppStrings.Tasks.Screen.NewWorkOrder)} (${t(AppStrings.Common.Duplicated)})` : ""),
    [isDuplicateMode, t]
  );

  const isValidForm = async () => {
    formik.setFieldTouched("workOrder.assignedToVendor");
    formik.setFieldTouched("subject");
    formik.setFieldTouched("status");
    formik.setFieldTouched("requestedByTenant");
    formik.setFieldTouched("linkedResource.resourceId");

    formik.values.workOrder?.partsAndLaborInfo?.items?.forEach((_item, index) => {
      formik.setFieldTouched(`workOrder.partsAndLaborInfo.items[${index}].quantity`);
      formik.setFieldTouched(`workOrder.partsAndLaborInfo.items[${index}].price`);
      formik.setFieldTouched(`workOrder.partsAndLaborInfo.items[${index}].account`);
    });

    const errors = await formik.validateForm();

    let recurringIsValid = true;

    // @ts-expect-error Formik `errors` type is incorrect
    errors.workOrder?.assignedToVendor &&
      formik.setFieldError("workOrder.assignedToVendor", tt("tasks.workOrderDialog.selectVendorValidationText"));

    const recurringValidator = getValidator();
    const isRecurring = getIsRecurring();

    if (isRecurring && recurringValidator) {
      recurringIsValid = await recurringValidator();
    }

    return isEmpty(errors) && recurringIsValid;
  };

  const upsertTask = async (values: TaskDto) => {
    if (isEditMode) {
      setLoadingDialogLoadingText(t(AppStrings.Tasks.InternalTaskDialog.UpdatingTask));
    }

    setViewIndex(DialogView.LOADING);
    setLoadingDialogState(DialogState.Show);

    let response: ApiResult<BaseDto> | void;

    const recurringTransactionDto = getRecurringData();
    const isRecurring = getIsRecurring();

    if (isEditMode && !isDuplicateMode) {
      if (isRecurring && recurringTransactionDto) {
        const recurringTask = OnetimeToRecurringMapper.buildRecurring.task(values, recurringTransactionDto);
        response = await leasesRecurringTransactionsApi.update(taskId, recurringTask).catch(showErrorMessage);
      } else {
        response = await tasksApi.update(taskId, values).catch(showErrorMessage);
      }
    } else if (isRecurring && recurringTransactionDto) {
      const recurringTask = OnetimeToRecurringMapper.buildRecurring.task(values, recurringTransactionDto);
      response = await leasesRecurringTransactionsApi.create(recurringTask).catch(showErrorMessage);
    } else {
      response = await tasksApi
        .create(values, {
          translationKey: AppStrings.Toasts.custom.tasks?.[TaskType.WORK_ORDER]?.POST
        })
        .catch(showErrorMessage);
    }

    if (response?.data?.id) {
      await filesApi.uploadFiles(attachments, response.data.id, linkedResourceType).catch((error: string) => {
        showErrorMessage(error);
      });
      onClose(response.data);
    } else if (response?.message) {
      showErrorMessage(response.message);
    }
  };

  const handleSubmit = async () => {
    const isValid = await isValidForm();

    if (isVendorIdle && isValid) {
      upsertTask(formik.values);
    }
  };

  const renderView = ({ index }: { index: number }) => {
    if (index === 0) {
      if (isEditMode && loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            onRetryButtonPress={fetchTask}
            didPressDismissButton={handleDismiss}
          />
        );
      }
      return renderForm();
    }

    if (index === DialogView.LOADING) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          loadingText={loadingDialogLoadingText}
          errorText={loadingDialogErrorText}
          onRetryButtonPress={handleSubmit}
          didPressDismissButton={handleDismiss}
        />
      );
    }

    return <div />;
  };

  const handleDuplicateClick = () => {
    if (!taskId) {
      return;
    }

    setSearchParams((prevSearchParams) => {
      prevSearchParams.set(DUPLICATE_MODE_QUERY_PARAMS.taskId, taskId);
      return prevSearchParams;
    });

    dispatchAnalytics("button_click", {
      label: DuplicateDialogButtonLabelValues.DUPLICATE_WORK_ORDER
    });
  };

  const renderHeaderActionButtons = () => (
    <DialogHeaderActionButtons
      onDuplicateClick={handleDuplicateClick}
      hideDeleteButton
      hideDuplicateButton={isDuplicateMode || !isEditMode}
    />
  );

  const renderActionPanelButtons = () => (
    <FormActionButtons
      propsSubButton={{ onClick: handleBackdropClick }}
      propsMainButton={{ type: "cta", props: { onClick: handleSubmit } }}
    />
  );

  const frameType: FrameType = useMemo(() => {
    if (viewIndex === DialogView.FORM) {
      if (loadingDialogState !== DialogState.Hidden) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }
    return "contentOnly";
  }, [viewIndex, loadingDialogState]);

  return (
    <DialogFrame
      onCloseButtonClick={handleBackdropClick}
      title={duplicateModeTitle || currentTitle}
      width={dialogWidth}
      height={dialogHeight}
      renderView={renderView}
      numViews={DIALOG_VIEWS_NUM}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      RenderHeaderActionButtons={renderHeaderActionButtons}
      frameType={frameType}
      keepViewsMounted={false}
    />
  );
};

export default WorkOrderDialog;
