import React, { useMemo } from "react";

import { useAtomValue } from "jotai";
import { useParams } from "react-router-dom";
import { HighlightedText } from "DLUI/text/highlightedText";
import { globalSearchTextAtom } from "./globalSearch";
import { View } from "DLUI/view";

import "./styles.css";
import type { LocalSearchItem } from "../../../../constants/localSearchData";
import { useLocalGlobalSearch } from "../../../../hooks/useLocalGlobalSearch";
import { useReportScreenItemStyles } from "../../report/screen/styles";

interface ComponentProps {
  item: LocalSearchItem;
  handleItemClicked: (propertyId?: string, leaseId?: string) => void;
}

interface Params {
  propertyId?: string;
  leaseId?: string;
}

export const LocalSearchResultSectionItem: React.FC<ComponentProps> = ({ item, handleItemClicked }) => {
  const globalSearchString = useAtomValue(globalSearchTextAtom);
  const { propertyId, leaseId } = useParams<Params>();
  const { getItemTitle, selectItem } = useLocalGlobalSearch();
  const classes = useReportScreenItemStyles();
  const title = useMemo(() => getItemTitle(item.title, item.type), [item, getItemTitle]);

  const handleClick = (): void => {
    handleItemClicked(propertyId, leaseId);
    item.onPress(propertyId, leaseId);
    selectItem(item);
  };

  return (
    <View
      cursor={"pointer"}
      alignItems={"center"}
      flexDirection={"row"}
      height={40}
      paddingLeft={10}
      paddingRight={10}
      className={classes.root}
    >
      <View onClick={handleClick} flex={1} noWrap>
        <HighlightedText highlightedSubstring={globalSearchString} value={title} style={{ fontSize: 16 }} />
      </View>
    </View>
  );
};
