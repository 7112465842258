import React from "react";

import type { FormikProps } from "formik";

import type { OwnerPortalSettingsDto } from "@doorloop/dto";

import AppStrings from "../../../../../locale/keys";
import { PortalInvitesForm } from "DLUI/settings/portal/portalInvitesForm";

interface ComponentProps {
  formikRef: FormikProps<OwnerPortalSettingsDto>;
}

export const PortalInvites: React.FC<ComponentProps> = () => (
  <PortalInvitesForm titleText={AppStrings.Common.OwnerPortalInvitesDescription} />
);
