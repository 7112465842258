import type { PropsWithChildren } from "react";
import React from "react";

import type { GridProps as KendoGridProps } from "@progress/kendo-react-grid";
import ScrollableGrid from "./scrollableGrid";
import StaticGrid from "./staticGrid";
import type { GridListType } from "DLUI/lists/types";
import { useAtom } from "jotai";
import { isPrintingAtom } from "utils/atoms";
import type { DataResult } from "@progress/kendo-data-query";
import VirtualGrid from "DLUI/lists/gridList/dataGrid/virtualGrid";

interface GridProps {
  kendoProps: KendoGridProps;
  overrideGridType?: GridListType;
}

/**
 * NativeGrid is a low-level wrapper around the kendo grid.
 * The purpose is to discriminate between different types of grid (scrollable, fixed)
 */
const NativeGrid = (props: PropsWithChildren<GridProps>) => {
  const [isPrinting] = useAtom(isPrintingAtom);
  const { overrideGridType } = props;

  /**
   * We want to catch grid type changes and delay it for 500ms to give time for the print pending screen to render.
   */
  const [gridType, setGridType] = React.useState<GridListType>(overrideGridType || "scrollable");

  React.useEffect(() => {
    const timeoutId = setTimeout(
      () => {
        setGridType(overrideGridType ?? "scrollable");
      },
      isPrinting ? 300 : 0
    );
    return () => {
      clearTimeout(timeoutId);
    };
  }, [overrideGridType]);

  const Grid = React.useMemo(() => {
    switch (gridType) {
      case "scrollable":
        return ScrollableGrid;
      case "fixed":
        return StaticGrid;
      case "virtual":
        return VirtualGrid;
      default:
        return ScrollableGrid;
    }
  }, [gridType]);

  return (
    <Grid {...props.kendoProps} data={props.kendoProps.data as DataResult}>
      {props.children}
    </Grid>
  );
};

export default NativeGrid;
