import type { StaticRemoteOrDateFilter } from "DLUI/screen/filterPanel/filterPanel";
import type { GetAllConversationsQuery } from "@doorloop/dto";
import AppStrings from "../../../../locale/keys";
import { propertiesApi } from "api/propertiesApi";
import { leasesApi } from "api/leasesApi";
import { unitsApi } from "api/unitsApi";

const FilterOptions: Array<StaticRemoteOrDateFilter<GetAllConversationsQuery>> = [
  {
    label: AppStrings.CommunicationsCenter.FilterPanel.InboxFilters,
    type: "staticFiltering",
    name: AppStrings.CommunicationsCenter.Screen.AllMessagesTitle,
    values: [
      {
        displayName: AppStrings.CommunicationsCenter.Read,
        value: "true",
        filterFieldName: "filter_read"
      },
      {
        displayName: AppStrings.CommunicationsCenter.UnRead,
        value: "false",
        filterFieldName: "filter_read"
      }
    ]
  },
  {
    label: AppStrings.Calendar.FilterPanel.LeaseFilters,
    type: "remoteFiltering",
    name: AppStrings.Common.FilterOptions.Property,
    apiHandler: propertiesApi,
    displayNameKey: "name",
    filterFieldName: "filter_property",
    filterFieldValue: "id"
  },
  {
    name: AppStrings.Units.ListHeader.Unit,
    apiHandler: unitsApi,
    displayNameKey: "name",
    filterFieldName: "filter_unit",
    filterFieldValue: "id",
    type: "remoteFiltering"
  },
  {
    name: AppStrings.Common.FilterOptions.Lease,
    filterFieldName: "filter_lease",
    type: "remoteFiltering",
    apiHandler: leasesApi,
    displayNameKey: "name",
    filterFieldValue: "id"
  }
];

export default FilterOptions;
