import React from "react";
import type { ReactNode } from "react";
import type { FC } from "react";
import { DeleteIcon } from "assets/icons";
import { IconButton } from "DLUI/form";
import { ProfileImage } from "DLUI/profileImage";
import Text from "DLUI/text";
import makeStyles from "./styles";
import { View } from "DLUI/view";

interface SelectionButtonProps {
  pictureUrl?: string;
  name?: string;
  index: number;
  didPressDeleteButton: (itemIndex: number) => void;
  onClick?: (buttonIndex: number) => void;
  dataCy?: string;
  profileImageIndicatorIcon?: ReactNode;
}

const SelectionButton: FC<SelectionButtonProps> = ({
  index,
  pictureUrl,
  name,
  didPressDeleteButton,
  onClick,
  dataCy,
  profileImageIndicatorIcon
}) => {
  const classes = makeStyles();
  const _didPressDeleteButton = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    didPressDeleteButton(index);
  };

  const _onClick = () => {
    onClick?.(index);
  };

  return (
    <div onClick={_onClick} key={"TS" + index}>
      <div className={classes.selectedTenantButton} data-cy={dataCy}>
        <div className={classes.deleteIconContainer}>
          <IconButton Icon={DeleteIcon} width={15} height={15} onClick={_didPressDeleteButton} />
        </div>
        <div style={{ position: "relative" }}>
          <ProfileImage pictureUrl={pictureUrl} />
          {profileImageIndicatorIcon && (
            <View
              autoWidth
              style={{
                position: "absolute",
                bottom: 0,
                right: 0
              }}
            >
              {profileImageIndicatorIcon}
            </View>
          )}
        </div>
        <Text align={"center"} marginTop={10} color={"gray"} overFlow={"ellipsis"} maxLines={"2"}>
          {name}
        </Text>
      </div>
    </div>
  );
};

export default SelectionButton;
