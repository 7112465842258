import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";
import { lazy } from "react";

const OnBoardingScreen = lazy(() => import("screens/onBoarding/onBoardingScreen"));

const OnBoardingRouter = () => (
  <PageRouter>
    <LazyRoute path={Routes.ONBOARDING} render={OnBoardingScreen} exact />
    <LazyRoute
      path={[Routes.ONBOARDING_GLOBAL_NEW, Routes.ONBOARDING_EDIT_GENERAL_SETTINGS, Routes.ONBOARDING_EDIT_SETTINGS]}
      render={OnBoardingScreen}
    />
  </PageRouter>
);

export default OnBoardingRouter;
