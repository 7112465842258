import type { DialogProps } from "./dialogsProvider.types";
import { DialogRoutes } from "./dialogsRoutes";
import { ViewSignatureRequestDialog } from "screens/esignatures/viewSignatureRequest/viewSignatureRequestDialog";

class SignatureRequestsDialogsProvider {
  matchRoutes(pathname: string): DialogProps | undefined {
    if (pathname.includes(DialogRoutes.VIEW_SIGNATURE_REQUEST)) {
      return {
        Content: ViewSignatureRequestDialog
      };
    }
  }
}

export const signatureRequestsDialogsProvider = new SignatureRequestsDialogsProvider();
