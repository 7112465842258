import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      "& .MuiInputBase-root": {
        padding: "0px !important",
        height: "22px !important",
        borderRadius: "2px !important",
        backgroundColor: "#F5F6FA !important",
        "& .MuiInputBase-input": {
          padding: "0px !important",
          height: "22px !important",
          backgroundColor: "#F5F6FA !important"
        },
        "& fieldset": {
          borderColor: "transparent !important"
        }
      },
      "& .MuiInputBase-input": {
        padding: "0px",
        height: "22px"
      },
      "& .MuiInput-formControl": {
        backgroundColor: "#F5F6FA",
        fontSize: "14px",
        borderRadius: "2px"
      },
      "& .Mui-error": {
        backgroundColor: "#FCEBEE"
      },
      "& p": {
        display: "none"
      }
    }
  })
);
