import React, { Fragment, useEffect, useMemo, useState } from "react";
import type { TagDto } from "@doorloop/dto";
import { DEFAULT_PAGE_SIZE, ObjectPermission, PropertyGroupDto } from "@doorloop/dto";
import { propertyGroupsApi } from "api/propertyGroupsApi";
import { AddIcon } from "assets/icons";
import { Button } from "DLUI/button";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import PropertyGroupsListItem from "./propertyGroupsListItem";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import { usePermission } from "screens/settings/userRoles/usePermission";

interface ComponentProps {
  onClose: () => void;
  onBackdropClick: () => void;
  dialogTitle?: string;
}

const PropertyGroups: React.FC<ComponentProps> = ({ onBackdropClick, dialogTitle }: ComponentProps) => {
  const { hasPermission } = usePermission();
  const { t } = useTranslation();
  const [viewIndex, setViewIndex] = useState(0);
  const { dialogHorizontalPadding } = DialogsHelper();
  const [propertyGroupData, setPropertyGroupData] = useState<PropertyGroupDto[] | undefined>();
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState<string>("");
  const [loadingDialogSuccessText, setLoadingDialogSuccessText] = useState<string>(
    t(AppStrings.Settings.GeneralSettings.RegionAndCurrency.LoadingRegionAndCurrencyDataSuccessText)
  );

  useEffect(() => {
    loadTagsData();
  }, []);

  const showErrorMessage = (message: string) => {
    setLoadingDialogErrorText(message);
    setLoadingDialogState(DialogState.Error);
  };

  const loadTagsData = async () => {
    setLoadingDialogLoadingText(t(AppStrings.Settings.GeneralSettings.PropertyGroups.LoadingPropertyGroups));
    setLoadingDialogState(DialogState.Show);
    const response = await propertyGroupsApi
      .getAll({
        page_size: DEFAULT_PAGE_SIZE
      })
      .catch((e) => {
        showErrorMessage(e);
      });
    if (response && response.data) {
      setLoadingDialogSuccessText(
        AppStrings.Settings.GeneralSettings.PropertyGroups.LoadingPropertyGroupsDataSuccessText
      );
      setLoadingDialogState(DialogState.Success);
      const propertyGroupData = response.data.data as PropertyGroupDto[];
      if (
        propertyGroupData &&
        propertyGroupData.length === 0 &&
        hasPermission(ObjectPermission.propertyGroups, "create")
      ) {
        const propertyGroupItem = new PropertyGroupDto();
        propertyGroupData.push(propertyGroupItem);
      }
      setPropertyGroupData(propertyGroupData);
      setLoadingDialogState(DialogState.Hidden);
      return;
    }
    if (response) {
      showErrorMessage(response.message);
    }

    showErrorMessage(t(AppStrings.Common.NetworkErrorSubTitle));
  };

  const didPressDismissButton = () => {
    onBackdropClick();
  };

  const renderView = ({ index }: any) => {
    if (index === 0) {
      if (loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={loadTagsData}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }
      return renderForm();
    }

    return <div />;
  };

  const renderTagsList = () =>
    propertyGroupData
      ? propertyGroupData.map((currentTagItem: PropertyGroupDto, index: number) => (
          <Fragment key={"Tag" + currentTagItem.id || uuid()}>
            <PropertyGroupsListItem
              id={currentTagItem.id}
              name={currentTagItem.name}
              properties={currentTagItem.properties}
              onDeleteEvent={didDeleteTagItem}
              index={index}
            />
          </Fragment>
        ))
      : null;

  const didDeleteTagItem = (id?: string) => {
    if (propertyGroupData && propertyGroupData.length > 0) {
      const nextProperty: TagDto[] = [...propertyGroupData];
      let deleteTagIndex = -1;
      if (id) {
        deleteTagIndex = nextProperty.findIndex((x) => x.id === id);
      }
      if (deleteTagIndex === -1) {
        deleteTagIndex = nextProperty.length - 1;
      }

      nextProperty.splice(deleteTagIndex, 1);
      setPropertyGroupData(nextProperty);
    }
  };

  const didPressAddTag = () => {
    const newPropertyGroup = new PropertyGroupDto();
    newPropertyGroup.name = undefined;
    newPropertyGroup.properties = [];

    let nextPropertyGroups: PropertyGroupDto[] = [];
    if (propertyGroupData) {
      nextPropertyGroups = [...propertyGroupData];
    }
    nextPropertyGroups.push(newPropertyGroup);
    setPropertyGroupData(nextPropertyGroups);
  };

  const renderForm = () => (
    <View
      marginTop={10}
      paddingRight={dialogHorizontalPadding}
      paddingLeft={dialogHorizontalPadding}
      height={"100%"}
      flex={1}
    >
      <Text fontSize={16} value={AppStrings.Settings.GeneralSettings.PropertyGroups.DialogDescription} />

      {renderTagsList()}
      <Button
        color={"lightBlue"}
        type={"inlineText"}
        actionText={AppStrings.Leases.LeaseCharge.AddLineItem}
        onClick={didPressAddTag}
        marginLeft={10}
        LeftIcon={AddIcon}
        iconSize={15}
        applyColorForIcons
        marginTop={20}
        clearance={{
          permission: ObjectPermission.propertyGroups,
          field: "create"
        }}
      />
      <View height={20} />
    </View>
  );

  const _onBackdropClick = () => {
    if (viewIndex > 0) {
      setViewIndex(viewIndex - 1);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const currentTitle = useMemo(() => {
    if (viewIndex === 0) {
      return dialogTitle;
    }

    if (viewIndex === 2) {
      return AppStrings.Vendors.Screen.AddNewVendor;
    }

    if (viewIndex === 1) {
      return AppStrings.Vendors.VendorDetails.SelectVendor;
    }
    return "";
  }, [viewIndex]);

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      if (loadingDialogState === DialogState.Hidden) {
        return "sectionTitleFrame";
      }
      return "contentOnly";
    }
    if (viewIndex === 1) {
      return "contentOnly";
    }
    return "contentOnly";
  }, [viewIndex, loadingDialogState]);

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={currentTitle}
      width={760}
      height={760}
      renderView={renderView}
      numViews={2}
      activeView={viewIndex}
      frameType={frameType}
      keepViewsMounted={false}
    />
  );
};

export default PropertyGroups;
