"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BalanceSummaryComponentType = exports.RecentlyDepositedStatus = void 0;
var RecentlyDepositedStatus;
(function (RecentlyDepositedStatus) {
    RecentlyDepositedStatus["IN_TRANSIT"] = "IN_TRANSIT";
    RecentlyDepositedStatus["PAID"] = "PAID";
    RecentlyDepositedStatus["FAILED"] = "FAILED";
})(RecentlyDepositedStatus || (exports.RecentlyDepositedStatus = RecentlyDepositedStatus = {}));
var BalanceSummaryComponentType;
(function (BalanceSummaryComponentType) {
    BalanceSummaryComponentType["AVAILABLE_SOON"] = "AVAILABLE_SOON";
    BalanceSummaryComponentType["RECENTLY_DEPOSITED_IN_TRANSIT"] = "RECENTLY_DEPOSITED_IN_TRANSIT";
    BalanceSummaryComponentType["RECENTLY_DEPOSITED_PAID"] = "RECENTLY_DEPOSITED_PAID";
})(BalanceSummaryComponentType || (exports.BalanceSummaryComponentType = BalanceSummaryComponentType = {}));
