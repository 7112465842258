import type { GetAllBaseQueryResponse } from "@doorloop/dto";
import { SegmentEventTypes, ServerRoutes } from "@doorloop/dto";
import type { ApiToastsProps } from "./apiHelper";
import { apiHelper } from "./apiHelper";
import type { ApiResult } from "./apiResult";
import { RestApiBase } from "./restApiBase";
import { analyticsService } from "../services/analyticsService";

export abstract class RestApiBaseWithDictionary<DtoType, GetAllDtoType> extends RestApiBase<DtoType, GetAllDtoType> {
  restRoute = "";
  isUseDictionary = false;

  constructor(restRoute: string, dictionariesRequiredForGet?: Array<RestApiBaseWithDictionary<any, any>>) {
    super(restRoute, dictionariesRequiredForGet);
  }

  async get(id: string, forceDictionaryRefresh = false): Promise<ApiResult<DtoType>> {
    const result = await super.get(id);
    if (result.status && result.data) {
      await this.addOrUpdateDictionaryItem(result.data, forceDictionaryRefresh);
    }

    return result;
  }

  async getFromDictionaryOrFetch(id: string): Promise<DtoType | undefined> {
    const item = this.getItemFromDictionary(id);
    if (item) {
      return item;
    }

    const response = await super.get(id);
    return response.data;
  }

  async getAll(
    query: GetAllDtoType,
    forceDictionaryRefresh = false
  ): Promise<ApiResult<GetAllBaseQueryResponse<DtoType>>> {
    const result = await super.getAll(query);
    if (result.status && result.data) {
      await this.addOrUpdateDictionaryItems(result.data.data, forceDictionaryRefresh);
    }
    return result;
  }

  async update(id: string, dto: DtoType, toasts?: ApiToastsProps<DtoType>): Promise<ApiResult<DtoType>> {
    const result = await super.update(id, dto, toasts);
    if (result.status && result.data) {
      await this.addOrUpdateDictionaryItem(result.data, true);
    }
    return result;
  }

  async create(dto: DtoType, toasts?: ApiToastsProps<DtoType>): Promise<ApiResult<DtoType>> {
    const result = await super.create(dto, toasts);
    switch (this.restRoute) {
      case ServerRoutes.LEASE_DRAFTS: {
        analyticsService.track(SegmentEventTypes.USER_CREATED_LEASE, null, { trackEventInIntercom: true });
      }
    }
    if (result.status) {
      await this.addOrUpdateDictionaryItem(result.data, true);
    }
    return result;
  }

  async delete(id: string, toasts?: ApiToastsProps<DtoType>): Promise<ApiResult<DtoType>> {
    const result = await super.delete(id, toasts);
    if (result.status && result.data) {
      await this.removeDictionaryItem(result.data, false);
    }
    return result;
  }

  /**
   * Gets ine item from dictionary. Assumes the dictionary has already been prefetched from the server and that it exists
   * @param id the id of the item you want to get the dictionary item for
   * @returns
   */
  getItemFromDictionary(id: string) {
    const dictionary = this.getDictionaryFromSessionStorage();
    if (dictionary && dictionary[id]) {
      return dictionary[id];
    }
    return undefined;
  }

  /**
   * Gets ine item from dictionary. Will try to get it from the cache, if it's not there, will return the id as the name and id
   * and will set cacheHit to false.
   * @param id the id of the item you want to get the dictionary item for
   * @returns {name: string, id: string, cacheHit: boolean}
   */
  tryGetItemFromDictionary<T extends string>(
    id: T
  ):
    | {
        cacheHit: true;
        [key: string]: unknown;
      }
    | {
        name: T;
        id: T;
        cacheHit: false;
      } {
    const dictionary = this.getDictionaryFromSessionStorage();
    if (dictionary && dictionary[id]) {
      return { ...dictionary[id], cacheHit: true };
    }
    return { name: id, id, cacheHit: false };
  }

  getDictionaryFromSessionStorage() {
    const dictionary = sessionStorage.getItem(this.getDictionaryKey());
    if (dictionary) {
      return JSON.parse(dictionary) as Record<string, any>;
    }
    return null;
  }

  /**
   * Returns the dictionary of this resource from the cache. If the cache is empty, will load it from the server and save it in the cache.
   * @param [forceDictionaryRefresh] If true, we will refetch the data from the server and update the cache.
   * @returns dictionary
   */
  async getDictionary(forceDictionaryRefresh = false): Promise<Record<string, DtoType>> {
    const dictionary = this.getDictionaryFromSessionStorage();
    if (dictionary && !forceDictionaryRefresh) {
      return dictionary;
    }
    const result = await apiHelper.axiosGet<Record<string, any>>(this.restRoute + "/dictionary");
    if (result.status && result.data) {
      this.setDictionary(result.data);
    }
    return result.data || {};
  }

  async getAllFromDictionary(): Promise<DtoType[]> {
    const dictionary = await this.getDictionary();
    return Object.keys(dictionary).map((key) => {
      return { ...dictionary[key], id: key };
    });
  }

  private async addOrUpdateDictionaryItems(data: any[] = [], forceDictionaryRefresh = false) {
    const dictionary = await this.getDictionary(forceDictionaryRefresh);
    data.forEach((x) => {
      dictionary[x.id] = { ...x, ...dictionary[x.id] };
    });
    this.setDictionary(dictionary);
  }

  protected async addOrUpdateDictionaryItem(data: any, forceDictionaryRefresh = false) {
    const dictionary = await this.getDictionary(forceDictionaryRefresh);
    dictionary[data.id] = { ...data, ...dictionary[data.id] };
    this.setDictionary(dictionary);
  }

  private async removeDictionaryItem(data: any, forceDictionaryRefresh = false) {
    const dictionary = await this.getDictionary(forceDictionaryRefresh);
    const existingItem = dictionary[data.id];
    if (existingItem) {
      delete dictionary[data.id];
      this.setDictionary(dictionary);
    }
  }

  private setDictionary(dictionary: Record<string, any>) {
    sessionStorage.setItem(this.getDictionaryKey(), JSON.stringify(dictionary));
  }

  private getDictionaryKey() {
    return this.restRoute;
  }
}
