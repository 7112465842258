import type { FC } from "react";
import React, { useEffect, useMemo, useState } from "react";
import { View } from "DLUI/view";
import type { Dispatcher } from "engines/bulkOperationsEngine";
import { BulkOperationsEngine, BulkOperationSkipped } from "engines/bulkOperationsEngine";
import _ from "lodash";
import { useEffectAsync } from "hooks/useEffectAsync";
import Text from "DLUI/text";
import { ProgressBar } from "DLUI/progressBar/progressBar";
import { Icon } from "DLUI/icon";
import {
  CheckIconNew,
  CheckmarkCircleIcon,
  ICircleRefreshIcon,
  ICloseCircleIcon,
  WarningOutlinedIcon
} from "assets/icons";
import AppStrings from "locale/keys";
import type { ReplaceObjectInterface } from "DLUI/text/text";
import ColorsEnum from "../../../utils/colorsEnum";
import type { BulkExecutionDialogProps } from "DLUI/bulkExecution/bulkExecutionDialog";
import { useTranslation } from "react-i18next";
import { BulkOperationExecutionDetails } from "DLUI/bulkExecution/bulkOperationExecutionDetails";
import DLButton, { DLButtonColorsEnum } from "DLUI/button/dlButton";

const reRenderPreventionDelay = 100;

interface Props extends Pick<BulkExecutionDialogProps, "operations" | "onFinish" | "closeHandler"> {
  updateWrapperHeight: () => void;
  bulkExecutionDialogTimeout?: number;
}

export const BulkExecution: FC<Props> = ({
  operations,
  onFinish,
  updateWrapperHeight,
  closeHandler,
  bulkExecutionDialogTimeout = 1000
}) => {
  const [executionOngoing, setExecutionOngoing] = useState<boolean>(false);
  const [showDismissButton, setShowDismissButton] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [hasSkipped, setHasSkipped] = useState<boolean>(false);
  const [completedCount, setCompletedCount] = useState<number>(0);
  const resultAggregate: any[] = [];
  const [header, setHeader] = useState<{
    text: string;
    replace?: ReplaceObjectInterface;
  } | null>(null);
  const operationsEngine = useMemo(() => new BulkOperationsEngine(), []);
  const [completedOps, setCompletedOps] = useState<string[]>([]);
  const [failedOps, setFailedOps] = useState<string[]>([]);
  const [skippedOps, setSkippedOps] = useState<string[]>([]);
  const stoppedWithErrors = !executionOngoing && hasErrors;
  const [delayHasPassed, setDelayHasPassed] = useState<boolean>(false);
  const { t } = useTranslation();

  /**
   * We want to delay starting the execution until the dialog has been fully rendered.
   * This also prevents operations duplicating because a rerender has been triggered
   */
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDelayHasPassed(true);
    }, reRenderPreventionDelay);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  const dispatcher: Dispatcher = (err, { ongoingMessage, progress, finish, result, type }) => {
    setExecutionOngoing(true);
    if (err) {
      if (type === BulkOperationSkipped) {
        setHasSkipped(true);
        setSkippedOps((prevSkippedOps) => [...prevSkippedOps, err]);
      } else {
        setHasErrors(true);
        setFailedOps((prevFailedOps) => [...prevFailedOps, err]);
      }
    }
    if (ongoingMessage) {
      setHeader({ text: ongoingMessage });
    }
    if (progress !== undefined) {
      setCompletedCount(progress);
      setCompletedOps((prevCompletedOps) => [
        ...prevCompletedOps,
        result?.message || t(AppStrings.Common.OperationCompleted)
      ]);
      resultAggregate.push(result);
    }
    if (finish) {
      onFinish?.(resultAggregate, finish);
      setShowDismissButton(true);
      if (!finish.allSucceeded) {
        const failedCount = _.size(finish.failedOperations);
        if (failedCount === 1) {
          setHeader({ text: AppStrings.BulkActions.OneOperationFailed });
        } else if (failedCount > 1) {
          setHeader({
            text: AppStrings.BulkActions.XOperationsFailed,
            replace: { failedCount }
          });
        }
        const skippedCount = _.size(finish.skippedOperations);
        if (failedCount === 0 && skippedCount > 0) {
          if (skippedCount === 1) {
            setHeader({ text: AppStrings.BulkActions.OneOperationSkipped });
          } else {
            setHeader({
              text: AppStrings.BulkActions.XOperationsSkipped,
              replace: { skippedCount }
            });
          }
        }
      } else {
        setTimeout(() => closeHandler(true), bulkExecutionDialogTimeout);
      }
    }
  };

  const retryFailed = async () => {
    setFailedOps([]);
    setHasErrors(false);
    updateWrapperHeight();
    await operationsEngine.retryFailed(dispatcher);
  };

  useEffectAsync(async () => {
    if (!delayHasPassed) return;
    setHasErrors(false);
    setCompletedCount(0);
    await operationsEngine.execute(operations, dispatcher);
  }, [delayHasPassed]);

  useEffect(() => {
    if (failedOps.length || skippedOps.length || completedOps.length) {
      updateWrapperHeight();
    }
  }, [failedOps.length, skippedOps.length, completedOps.length]);

  return (
    <>
      <View flexDirection={"row"} marginBottom={15} noWrap>
        <View flex={1} alignItems={"flex-start"} noWrap>
          <Text value={header?.text} replaceObject={header?.replace} fontSize={16} bold />
        </View>
        {(stoppedWithErrors || !_.isEmpty([...skippedOps, ...failedOps])) && (
          <View autoWidth onClick={() => closeHandler(true)} flexDirection={"row"}>
            <Icon Source={ICloseCircleIcon} />
          </View>
        )}
      </View>
      <View marginBottom={15} flexDirection={"row"}>
        <ProgressBar
          progress={completedCount}
          total={operations.length}
          color={hasErrors || hasSkipped ? ColorsEnum.Yellow : undefined}
        />
      </View>
      <View flexDirection={"row"} alignItems={"center"} noWrap>
        {completedCount === operations.length && (
          <View marginRight={5} autoWidth flexDirection={"row"}>
            <Icon size={15} Source={CheckmarkCircleIcon} />
          </View>
        )}
        <View flex={1} noWrap>
          <Text
            fontSize={14}
            value={AppStrings.BulkActions.XOutOfTotalCompleted}
            replaceObject={{
              count: completedCount,
              total: _.size(operations)
            }}
          />
        </View>
        {stoppedWithErrors && (
          <View autoWidth onClick={retryFailed} flexDirection={"row"}>
            <Icon size={20} Source={ICircleRefreshIcon} />
          </View>
        )}
      </View>
      <View
        paddingLeft={20}
        paddingRight={20}
        marginTop={16}
        borderRadius={5}
        style={{ background: ColorsEnum.LightGray }}
        flexDirection={"column"}
        justifyContent={"center"}
        height={"auto"}
      >
        <BulkOperationExecutionDetails
          completedOperation={completedOps}
          operationStatus={AppStrings.BulkActions.Completed}
          icon={CheckIconNew}
          showSeparator={!_.isEmpty([...failedOps, ...skippedOps])}
        />
        <BulkOperationExecutionDetails
          completedOperation={failedOps}
          operationStatus={AppStrings.BulkActions.Failed}
          icon={WarningOutlinedIcon}
          showSeparator={!_.isEmpty(skippedOps)}
        />
        <BulkOperationExecutionDetails
          completedOperation={skippedOps}
          operationStatus={AppStrings.BulkActions.Skipped}
          icon={WarningOutlinedIcon}
        />
      </View>
      {showDismissButton && (
        <View alignItems={"flex-end"} marginTop={16} borderRadius={4}>
          <DLButton
            actionText={AppStrings.Common.Dismiss}
            color={DLButtonColorsEnum.SECONDARY}
            onClick={() => closeHandler(true)}
          />
        </View>
      )}
    </>
  );
};
