import { Route, Switch } from "react-router";
import PageNotFound from "../screens/pageNotFound";
import type { PropsWithChildren } from "react";

const PageNotFoundWithNoParameters = () => <PageNotFound />;

export function PageRouter(props: PropsWithChildren<unknown>) {
  const { children } = props;

  return (
    <Switch>
      {children}
      <Route render={PageNotFoundWithNoParameters} />
    </Switch>
  );
}
