import { ListItemContainerDefaultMinWidth } from "DLUI/infiniteList/utils";
import type { FC } from "react";
import React, { useMemo } from "react";
import { BackgroundImageView, View } from "DLUI/view";
import { AuthLogoIcon, PageNotFoundBackground } from "assets/icons";
import Screen from "DLUI/screen";
import { Icon } from "DLUI/icon";
import { useTranslation } from "react-i18next";
import AppStrings from "locale/keys";
import Text from "DLUI/text";
import { NavigationManager } from "utils/navigation";
import { AppLayouts } from "../../../contexts/appLayoutContext";
import DLButton, { DLButtonSizesEnum } from "DLUI/button/dlButton";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";

interface ComponentProps {
  showGoHomeButton?: boolean;
  errorView?: boolean;
}
const fileName = "url(/images/404_background_drawing.jpg)";

const PageNotFound: FC<ComponentProps> = ({ showGoHomeButton = false, errorView = false }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();
  const headerFontSize = isMobile || errorView ? 50 : 80;
  const subHeaderFontSize = isMobile || errorView ? 14 : 20;

  const renderBackgroundLayer = () => (
    <BackgroundImageView
      height={window.innerHeight}
      width={window.innerWidth}
      publicFileName={fileName}
      backgroundSize={"cover"}
    />
  );

  const didPressHomepage = () => {
    NavigationManager.viewHome(true);
  };

  const iconSize = useMemo(() => {
    let _iconSize = window.innerWidth - 40;
    if (_iconSize > ListItemContainerDefaultMinWidth) {
      _iconSize = ListItemContainerDefaultMinWidth;
    }
    return _iconSize;
  }, []);

  return (
    <Screen
      screenLayout={AppLayouts.FullWidth}
      renderBackgroundLayer={renderBackgroundLayer}
      screenContentFullHeight
      detailsScreenInstance={errorView}
      fullWidth={errorView}
    >
      <View height={"100%"} width={"100%"} flexDirection={"column"}>
        <View width={"100%"} flex={1} alignItems={"center"} justifyContent={"center"}>
          <Icon Source={AuthLogoIcon} width={200} />
        </View>
        <View width={"100%"} flex={1} alignItems={"center"} justifyContent={"center"}>
          <Text align={"center"} fontSize={headerFontSize} color={"black"}>
            {t(errorView ? AppStrings.Common.PageNotFound.ErrorHeader : AppStrings.Common.PageNotFound.Header)}
          </Text>
          <Text
            marginTop={20}
            paddingRight={20}
            paddingLeft={20}
            align={"center"}
            fontSize={subHeaderFontSize}
            color={"black"}
          >
            {t(errorView ? AppStrings.Common.PageNotFound.ErrorSubHeader : AppStrings.Common.PageNotFound.SubHeader)}
          </Text>
          {(showGoHomeButton || errorView) && (
            <DLButton
              actionText={t(AppStrings.Common.PageNotFound.ButtonText)}
              size={DLButtonSizesEnum.LARGE}
              onClick={didPressHomepage}
              style={{ marginTop: 30 }}
            />
          )}
        </View>
        <View alignItems={"center"} justifyContent={"flex-end"} width={"100%"} flex={1}>
          <Icon width={iconSize} Source={PageNotFoundBackground} />
        </View>
      </View>
    </Screen>
  );
};

export default PageNotFound;
