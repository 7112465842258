"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AcceptInviteNewLoginDisplayDto = exports.AcceptInviteNewLoginDto = void 0;
const isStrongPassword_validator_1 = require("../common/validators/isStrongPassword.validator");
const class_validator_1 = require("class-validator");
const isEqualTo_validator_1 = require("../common/validators/isEqualTo.validator");
const acceptInviteType_enum_1 = require("./acceptInviteType.enum");
class AcceptInviteNewLoginDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.AcceptInviteNewLoginDto = AcceptInviteNewLoginDto;
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], AcceptInviteNewLoginDto.prototype, "invitationToken", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(acceptInviteType_enum_1.AcceptInviteType),
    __metadata("design:type", String)
], AcceptInviteNewLoginDto.prototype, "type", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], AcceptInviteNewLoginDto.prototype, "id", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, isStrongPassword_validator_1.IsComplexPassword)(),
    __metadata("design:type", String)
], AcceptInviteNewLoginDto.prototype, "loginPassword", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEmail)(),
    __metadata("design:type", String)
], AcceptInviteNewLoginDto.prototype, "loginEmail", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsDate)(),
    __metadata("design:type", Date)
], AcceptInviteNewLoginDto.prototype, "acceptedOnTOS", void 0);
class AcceptInviteNewLoginDisplayDto extends AcceptInviteNewLoginDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.AcceptInviteNewLoginDisplayDto = AcceptInviteNewLoginDisplayDto;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, isEqualTo_validator_1.IsEqualTo)("loginPassword", {
        message: "Password confirmation does not match your new password."
    }),
    __metadata("design:type", String)
], AcceptInviteNewLoginDisplayDto.prototype, "loginPasswordConfirmation", void 0);
