import type { CSSProperties, ReactNode } from "react";
import React, { useEffect } from "react";
import { EndOfListRow } from "./endOfListRow";
import { useInView } from "react-intersection-observer";
import type { InfiniteProps } from "../types";

type DataListScrollProps<TDto> = InfiniteProps & {
  rows: TDto[];
  renderRow: (item: TDto, index: number, resourceId?: string) => ReactNode;
  resourceIdGetter?: (item: TDto, index: number) => string;
  paddingEnd?: number;
  scrollElement?: HTMLElement | null;
  hideEndOfListIndicator?: boolean;
  style?: CSSProperties;
};

export function DataListScroll<TDto>(props: DataListScrollProps<TDto>) {
  const {
    renderRow,
    rows,
    resourceIdGetter,
    paddingEnd,
    hideEndOfListIndicator = false,
    scrollElement = document.getElementById("screenScroll"),
    style
  } = props;

  const { ref, inView } = useInView({ skip: !props.infinite, threshold: 0, root: scrollElement });
  const { hasNextPage, isFetchingNextPage, fetchNextPage } = props.infinite ? props : ({} as never);

  useEffect(() => {
    if (!props.infinite || !inView || !hasNextPage || !fetchNextPage) {
      return;
    }

    fetchNextPage();
  }, [inView, hasNextPage, fetchNextPage, props.infinite]);

  return (
    <>
      {rows.map((item, index) => (
        <div
          key={index}
          ref={props.infinite && index === rows.length - 1 ? ref : undefined}
          role="listitem"
          style={{
            width: "100%",
            ...style
          }}
        >
          {renderRow(item, index, resourceIdGetter ? resourceIdGetter(item, index) : undefined)}
        </div>
      ))}
      <EndOfListRow
        rows={rows}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        hideEndOfList={hideEndOfListIndicator}
      />
      <div
        style={{
          height: paddingEnd
        }}
      />
    </>
  );
}
