import type { UnitAllocationWithPercentageDto, UnitDto } from "@doorloop/dto";
import type { EditableTableColumn } from "DLUI/editableTable/editableTable";
import { TextField } from "DLUI/form";
import { leasesApi } from "api/leasesApi";
import { unitsApi } from "api/unitsApi";
import AppStrings from "locale/keys";
import React from "react";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";

interface PseudoDictionaryItem {
  name: string;
  id: string;
}

export const AllocationPercentageTableColumns: ReadonlyArray<EditableTableColumn<UnitAllocationWithPercentageDto>> = [
  {
    field: "unitName",
    headerName: AppStrings.Common.Unit,
    gridSize: 3,
    valueGetter(dto, t) {
      return (unitsApi.getItemFromDictionary(dto.unitId) as PseudoDictionaryItem).name;
    }
  },
  {
    field: "size",
    headerName: AppStrings.Units.NewUnitDialog.Size,
    gridSize: 2,
    valueGetter: (dto, t) => {
      const size = (unitsApi.getItemFromDictionary(dto.unitId) as UnitDto)?.size;
      return size ? `${size} ${t(AppStrings.Units.ListItem.Sqft)}` : "";
    }
  },
  {
    field: "leaseName",
    headerName: AppStrings.Common.FilterOptions.Lease,
    gridSize: 4,
    valueGetter(dto, t) {
      if (!dto.leaseId) return t(AppStrings.Units.FilterValues.Vacant);
      return (leasesApi.getItemFromDictionary(dto.leaseId) as PseudoDictionaryItem)?.name ?? "";
    },
    isDisabled: (dto) => !dto.leaseId
  },
  {
    field: "allocation",
    headerName: AppStrings.Allocations.Allocation,
    gridSize: 3,
    renderEditableCell(dto, index, t, isDisabled) {
      return (
        <FastFieldSafe
          name={`allocations[${index}].percentage`}
          formatType={"percent"}
          component={TextField}
          hideErrorText
          label={t(AppStrings.Allocations.Percentage)}
        />
      );
    }
  }
];

export const AllocationPercentageTableColumnsWithDisabledInputs: ReadonlyArray<
  EditableTableColumn<UnitAllocationWithPercentageDto>
> = [
  ...AllocationPercentageTableColumns.filter((column) => column.field !== "allocation"),
  {
    field: "allocation",
    headerName: AppStrings.Allocations.Allocation,
    gridSize: 3,
    align: "right",

    valueGetter(dto, t) {
      const value = dto?.percentage;
      if (value === undefined) return "";

      if (value < 0 || isNaN(value)) {
        return "--";
      }

      return value.toFixed(2) + "%";
    }
  }
] as const;
