import type { FC } from "react";
import React, { useState } from "react";
import { View } from "DLUI/view";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { SettingPermission } from "@doorloop/dto";
import { SettingsAccessEnum } from "@doorloop/dto";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { ListingSyndicationForm } from "screens/settings/listingSyndication/listingSyndicationForm";
import {
  ListingSyndicationDialogFrameHeight,
  ListingSyndicationDialogFrameWidth
} from "screens/settings/listingSyndication/listingSyndicationFormikContextWrapper";
import { settingsApi } from "api/settingsApi";
import { useFormikContext } from "formik";
import type { ListingsSettingsDto } from "@doorloop/dto";
import AppStrings from "../../../../locale/keys";
import { useTranslation } from "react-i18next";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { ArticleIdsEnum, HelpTypeEnum } from "DLUI/screen/helpPanel/types";

interface Props {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
}

const editPermission: AnyPermissionClearance = {
  permission: SettingPermission.listings,
  requiredLevel: SettingsAccessEnum.EDIT
};
const viewPermission: AnyPermissionClearance = {
  permission: SettingPermission.listings,
  requiredLevel: SettingsAccessEnum.VIEW
};

enum DialogViews {
  LOADING,
  FORM
}

const helpObject: HelpPanelProps = {
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Listing.LearnMoreSyndication,
      articleId: ArticleIdsEnum.LISTING_SYNC
    }
  ],
  description: AppStrings.Listing.SyndicationDescription
};

export const ListingSyndicationSettingsDialog: FC<Props> = ({ onBackdropClick, onClose, dialogTitle }: Props) => {
  const { t } = useTranslation();
  const formikRef = useFormikContext<ListingsSettingsDto>();
  const [dialogView, setDialogView] = useState<DialogViews>(DialogViews.FORM);
  const [errorText, setErrorText] = useState<string>("");
  const [dialogState, setDialogState] = useState(DialogState.Show);

  const renderView = ({ index }) => {
    if (index === DialogViews.FORM) {
      return <ListingSyndicationForm />;
    }
    if (index === DialogViews.LOADING) {
      return (
        <LoadingDialog
          dialogState={dialogState}
          errorText={errorText}
          didPressDismissButton={() => setDialogView(DialogViews.FORM)}
          onRetryButtonPress={didPressSaveButton}
          minHeight={ListingSyndicationDialogFrameHeight}
        />
      );
    }

    return <View />;
  };

  const showErrorMessage = (error: string) => {
    setDialogState(DialogState.Error);
    setErrorText(error);
  };

  const didPressSaveButton = async () => {
    setDialogView(DialogViews.LOADING);
    const { status, data, message } = await settingsApi.updateListingSettings(formikRef.values);
    if (status && data) {
      onClose();
    } else {
      showErrorMessage(message || t(AppStrings.Common.GeneralError));
    }
  };

  return (
    <DialogFrame
      onCloseButtonClick={onBackdropClick}
      title={dialogTitle}
      width={ListingSyndicationDialogFrameWidth}
      height={ListingSyndicationDialogFrameHeight}
      renderView={renderView}
      numViews={2}
      activeView={dialogView}
      helpPanel={helpObject}
      RenderActionPanelButtons={() => (
        <FormActionButtons
          propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
          propsSubButton={{ onClick: onBackdropClick }}
          clearance={editPermission}
        />
      )}
      frameType={DialogViews.LOADING === dialogView ? "contentOnly" : "sectionTitleFrame"}
    />
  );
};
