"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyndicationV2Management = void 0;
const syndicationIdentifiable_1 = require("../syndicationIdentifiable");
const syndicationFileType_1 = require("../syndicationFileType");
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const syndicationV2PropertyContact_1 = require("./syndicationV2PropertyContact");
class SyndicationV2Management extends syndicationIdentifiable_1.SyndicationIdentifiable {
}
exports.SyndicationV2Management = SyndicationV2Management;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => syndicationV2PropertyContact_1.SyndicationV2PropertyContact),
    __metadata("design:type", syndicationV2PropertyContact_1.SyndicationV2PropertyContact)
], SyndicationV2Management.prototype, "PropertyContacts", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => syndicationFileType_1.SyndicationFileType),
    __metadata("design:type", Array)
], SyndicationV2Management.prototype, "File", void 0);
