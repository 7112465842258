import type { FC } from "react";
import React from "react";
import { Lottie } from "DLUI/lottie";
import loadingLogo from "assets/lottie/loading_logo.json";
import spinnerAnimation from "assets/lottie/spinner.json";
import loadingDotsLottie from "assets/lottie/loadingDotsLottie.json";

type AnimationType = "logo" | "spinner" | "dots";

const animationMap: Record<AnimationType, unknown> = {
  logo: loadingLogo,
  spinner: spinnerAnimation,
  dots: loadingDotsLottie
};

interface LoadingAnimationProps {
  width?: number;
  height?: number;
  size?: number;
  type?: AnimationType;
}

export const LoadingAnimation: FC<LoadingAnimationProps> = ({ width = 60, height = 60, size, type = "logo" }) => (
  <Lottie autoPlay loop animationData={animationMap[type]} width={size ?? width} height={size ?? height} />
);
