import React, { useCallback, useEffect, useMemo, useState } from "react";
import type { RecurringTransactionDto } from "@doorloop/dto";
import { DuplicateDialogButtonLabelValues } from "@doorloop/dto";
import {
  createValidator,
  LinkedResourceType,
  OnetimeToRecurringMapper,
  TaskDateType,
  TaskDto,
  TaskStatus,
  TaskType
} from "@doorloop/dto";
import { filesApi } from "api/filesApi";
import { tasksApi } from "api/tasksApi";
import { Routes } from "components/appRouter";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import { renderReferenceString } from "../../../../../utils/strings";
import { SeparationLine } from "DLUI/separatorView";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { Formik } from "formik";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import type { IRootState } from "store";
import RelatedToSection from "../relatedToSection";
import TaskInfo from "../taskInfo";
import { useRecurringState } from "hooks/useRecurringState";
import { leasesRecurringTransactionsApi } from "api/leasesApi";
import type { ApiResult } from "api/apiResult";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";
import {
  cleanRecurringTransactionDataForDuplicateMode,
  cleanTaskDataForDuplicateMode,
  DUPLICATE_MODE_QUERY_PARAMS
} from "DLUI/dialogs/duplicateModeHelper";
import { QueryParams } from "utils/queryParams";
import { useAnalyticsService } from "hooks/useAnalyticsService";
import moment from "moment";
import { DialogHeaderActionButtons } from "DLUI/actionButtons/dialogHeaderActionButtons";
import { atom, useAtom } from "jotai";
import _ from "lodash";
import { DialogRoutes } from "@/components/DLUI/screen/dialogsProvider";

export const internalTaskDisableRecurringAtom = atom(false);

export interface TaskDialogComponentProps {
  onClose: (task?: TaskDto) => void;
  onBackdropClick: () => void;
  dialogTitle: string;
  editingRecurring?: boolean;
  apiObjectId?: string;
  initialTaskData?: TaskDto;
}

let formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

const validateForm = createValidator(TaskDto);

const dialogWidth = Math.min(window.innerWidth, 800);

const TaskDialog: React.FC<TaskDialogComponentProps> = ({
  onClose,
  onBackdropClick,
  editingRecurring,
  dialogTitle,
  apiObjectId,
  initialTaskData
}: TaskDialogComponentProps) => {
  const { t } = useTranslation();
  const {
    propertyId,
    taskId: taskIdParam,
    unitId,
    leaseId,
    ownerId,
    tenantId,
    vendorId,
    parentTaskId,
    prospectId,
    applicationId
  } = useParams<any>();
  const { screenContainerPadding } = useResponsiveHelper();
  const location = useLocation<any>();
  const queryParams = new QueryParams();
  const queryTaskId = queryParams.get(DUPLICATE_MODE_QUERY_PARAMS.taskId) as string | undefined;
  const taskId = apiObjectId || taskIdParam || queryTaskId;
  const editMode = Boolean(taskId);
  const duplicateMode = Boolean(queryTaskId);
  const { getValidator, getIsRecurring, getRecurringData, setRecurringData, RecurringContextWrapper } =
    useRecurringState(editMode, editingRecurring);
  const linkedResourceType = getIsRecurring() ? LinkedResourceType.RecurringTransactionTask : LinkedResourceType.Task;
  const recurringTaskId = editMode ? getRecurringData()?.id : undefined;
  const { dispatchAnalytics } = useAnalyticsService();
  const [viewIndex, setViewIndex] = useState(0);
  const userData = useSelector((state: IRootState) => state.auth.currentLoginResponse);
  const [taskData, setTaskData] = useState<TaskDto | undefined>(initialTaskData);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    editMode ? DialogState.Show : DialogState.Hidden
  );
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");

  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);

  const [showRelatedToSection, setShowRelatedToSection] = useState<boolean>(true);

  const isGlobalNewRoute = document.location.pathname.includes(DialogRoutes.GLOBAL_NEW);
  const [internalTaskDisableRecurring] = useAtom(internalTaskDisableRecurringAtom);
  const internalTaskDisableRecurringOption = internalTaskDisableRecurring && !isGlobalNewRoute;

  const autoSelectRelatedToField = () => {
    if (formikGlobalRef !== null) {
      if (editMode) {
        if (
          !taskData?.linkedResource ||
          taskData?.linkedResource?.resourceType === LinkedResourceType.Property ||
          taskData?.linkedResource?.resourceType === LinkedResourceType.Unit
        ) {
          setShowRelatedToSection(true);
        } else {
          setShowRelatedToSection(false);
        }
        return;
      }
      if (isGlobalNewRoute) {
        return;
      }
      if (!editMode) {
        if (propertyId) {
          formikGlobalRef.setFieldValue("linkedResource.resourceId", propertyId);
          formikGlobalRef.setFieldValue("linkedResource.resourceType", LinkedResourceType.Property);
          setShowRelatedToSection(false);
          return;
        }
        if (unitId) {
          formikGlobalRef.setFieldValue("linkedResource.resourceId", unitId);
          formikGlobalRef.setFieldValue("linkedResource.resourceType", LinkedResourceType.Unit);
          setShowRelatedToSection(false);
          return;
        }
        if (leaseId) {
          const linkedResourceType =
            location.pathname.indexOf(Routes.DRAFT_LEASES) > -1
              ? LinkedResourceType.LeaseDraft
              : LinkedResourceType.Lease;
          formikGlobalRef.setFieldValue("linkedResource.resourceId", leaseId);
          formikGlobalRef.setFieldValue("linkedResource.resourceType", linkedResourceType);
          setShowRelatedToSection(false);
          return;
        }
        if (ownerId) {
          formikGlobalRef.setFieldValue("linkedResource.resourceId", ownerId);
          formikGlobalRef.setFieldValue("linkedResource.resourceType", LinkedResourceType.Owner);
          setShowRelatedToSection(false);
          return;
        }
        if (tenantId || prospectId) {
          formikGlobalRef.setFieldValue("linkedResource.resourceId", tenantId || prospectId);
          formikGlobalRef.setFieldValue("linkedResource.resourceType", LinkedResourceType.Tenant);
          setShowRelatedToSection(false);
          return;
        }
        if (vendorId) {
          formikGlobalRef.setFieldValue("linkedResource.resourceId", vendorId);
          formikGlobalRef.setFieldValue("linkedResource.resourceType", LinkedResourceType.Vendor);
          setShowRelatedToSection(false);
          return;
        }
        if (parentTaskId) {
          formikGlobalRef.setFieldValue("linkedResource.resourceId", parentTaskId);
          formikGlobalRef.setFieldValue("linkedResource.resourceType", LinkedResourceType.Task);
          setShowRelatedToSection(false);
          return;
        }
        if (applicationId) {
          formikGlobalRef.setFieldValue("linkedResource.resourceId", applicationId);
          formikGlobalRef.setFieldValue("linkedResource.resourceType", LinkedResourceType.RentalApplication);
          setShowRelatedToSection(false);
          return;
        }
      }
    }
    setShowRelatedToSection(true);
  };

  useEffect(() => {
    if (
      userData?.id &&
      formikGlobalRef?.values &&
      (formikGlobalRef.values.requestedByUser === undefined || formikGlobalRef.values.requestedByUser === null)
    ) {
      formikGlobalRef.setFieldValue("requestedByUser", userData.id);
    }
  }, [setShowRelatedToSection, userData?.id]);

  useEffect(() => {
    if (loadingDialogState === DialogState.Hidden) {
      autoSelectRelatedToField();
    }
  }, [loadingDialogState]);

  useEffect(() => {
    if (editMode) {
      loadTaskData();
    }
  }, [duplicateMode]);

  const showErrorMessage = (message: string = t(AppStrings.Common.NetworkErrorSubTitle)) => {
    setLoadingDialogErrorText(message);
    setLoadingDialogState(DialogState.Error);
  };

  const handleLoadDataResponse = (response: ApiResult<TaskDto | RecurringTransactionDto> | void) => {
    if (response && response.data) {
      if (editingRecurring) {
        const recurringData = duplicateMode
          ? cleanRecurringTransactionDataForDuplicateMode(response.data as RecurringTransactionDto)
          : (response.data as RecurringTransactionDto);
        const taskData = OnetimeToRecurringMapper.buildOnetime.task(recurringData);
        if (duplicateMode) {
          taskData.dueDate = moment().format("YYYY-MM-DD").toString();
        }
        setRecurringData(recurringData);
        setTaskData(OnetimeToRecurringMapper.buildOnetime.task(recurringData));
      } else {
        const taskData = duplicateMode
          ? cleanTaskDataForDuplicateMode(response.data as TaskDto)
          : (response.data as TaskDto);
        setTaskData(taskData);
      }
      setLoadingDialogState(DialogState.Hidden);
    } else {
      showErrorMessage(response ? response.message : undefined);
    }
  };

  const loadTaskData = async () => {
    if (taskId) {
      setLoadingDialogState(DialogState.Show);
      handleLoadDataResponse(
        editingRecurring
          ? await leasesRecurringTransactionsApi.get(taskId).catch(showErrorMessage)
          : await tasksApi.get(taskId).catch(showErrorMessage)
      );
    } else {
      showErrorMessage();
    }
  };

  const didPressDismissButton = () => {
    onBackdropClick();
  };

  const renderView = ({ index }: any) => {
    if (index === 0) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            errorText={loadingDialogErrorText}
            onRetryButtonPress={loadTaskData}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }
      return renderForm();
    }
    if (index === 1) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          errorText={loadingDialogErrorText}
          onRetryButtonPress={didPressSaveButton}
          didPressDismissButton={didPressDismissButton}
        />
      );
    }

    return <div />;
  };
  const initFormValues = (): TaskDto => {
    if (taskData) {
      return taskData;
    }
    const taskDto = new TaskDto();
    taskDto.type = TaskType.INTERNAL_TASK;
    taskDto.dateType = TaskDateType.DATE;
    taskDto.reference = renderReferenceString();
    taskDto.status = TaskStatus.NOT_STARTED;
    taskDto.notifyAssignees = true;

    return taskDto;
  };

  const onFileReceived = (files: FileListItemProps[]) => {
    setAttachments(files);
  };

  const renderForm = () => {
    const formInitialValues = initFormValues();
    return (
      <Formik initialValues={formInitialValues} onSubmit={() => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;
          return (
            <View noWrap>
              <View paddingLeft={screenContainerPadding} paddingRight={screenContainerPadding}>
                <View>
                  <RecurringContextWrapper>
                    <TaskInfo />
                  </RecurringContextWrapper>
                </View>
                <SeparationLine width={"100%"} height={1} marginTop={20} />
                <RelatedToSection shouldShow={showRelatedToSection} formikRef={formik} />
                <View marginTop={20} marginBottom={20}>
                  <FormAttachments
                    onFileReceived={onFileReceived}
                    files={attachments}
                    editMode={editMode}
                    resourceId={recurringTaskId || (taskData ? taskData.id : undefined)}
                    resourceType={linkedResourceType}
                  />
                </View>
              </View>
            </View>
          );
        }}
      </Formik>
    );
  };

  const _onBackdropClick = () => {
    if (viewIndex > 0) {
      setViewIndex(viewIndex - 1);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const currentTitle = useMemo(() => {
    if (viewIndex === 0) {
      return dialogTitle;
    }

    if (viewIndex === 2) {
      return AppStrings.Vendors.Screen.AddNewVendor;
    }

    if (viewIndex === 1) {
      return AppStrings.Vendors.VendorDetails.SelectVendor;
    }
    return "";
  }, [viewIndex]);

  const duplicateModeTitle = useMemo(
    () => (duplicateMode ? `${t(AppStrings.Tasks.Screen.AddNewTask)} (${t(AppStrings.Common.Duplicated)})` : ""),
    [duplicateMode, t]
  );

  const isValidForm = async (formikRef: FormikProps<TaskDto>): Promise<boolean> => {
    const isRecurring = getIsRecurring();
    formikRef.setFieldTouched("tenantRequestType");
    formikRef.setFieldTouched("tenantRequestMaintenanceCategory");
    formikRef.setFieldTouched("subject");
    formikRef.setFieldTouched("status");
    const errors = (await formikRef.validateForm()) as any;
    let recurringIsValid = true;
    const recurringValidator = getValidator();
    if (isRecurring && recurringValidator) {
      recurringIsValid = await recurringValidator();
    }
    return _.isEmpty(errors) && recurringIsValid;
  };

  const didPressSaveButton = async () => {
    if (formikGlobalRef !== null) {
      const isValid = await isValidForm(formikGlobalRef);
      if (isValid) {
        if (editMode && !duplicateMode) {
          await updateTask(formikGlobalRef.values);
        } else {
          await createTask(formikGlobalRef.values);
        }
      }
    }
  };

  const showNetworkError = useCallback(() => {
    showErrorMessage(t(AppStrings.Common.NetworkErrorTitle));
  }, [showErrorMessage]);

  const createTask = async (values: TaskDto) => {
    const recurringData = getRecurringData();
    const isRecurring = getIsRecurring();

    setViewIndex(1);
    setLoadingDialogState(DialogState.Show);
    let response;
    if (isRecurring && recurringData) {
      const recurringTask = OnetimeToRecurringMapper.buildRecurring.task(values, recurringData);
      response = await leasesRecurringTransactionsApi.create(recurringTask).catch(showNetworkError);
    } else {
      response = await tasksApi.create(values).catch(showNetworkError);
    }

    if (response && response.data) {
      await filesApi
        .uploadFiles(attachments, response.data.id, linkedResourceType)
        .catch((error: string) => showErrorMessage(error));
      onClose(response.data);
    } else if (response) {
      showErrorMessage(response.message);
    }
  };

  const updateTask = async (values: TaskDto) => {
    const isRecurring = getIsRecurring();
    const recurringData = getRecurringData();
    setLoadingDialogState(DialogState.Show);
    setViewIndex(1);

    let response;
    if (isRecurring && recurringData) {
      const recurringTask = OnetimeToRecurringMapper.buildRecurring.task(values, recurringData);
      response = await leasesRecurringTransactionsApi.update(taskId, recurringTask).catch(showErrorMessage);
    } else {
      response = await tasksApi.update(taskId, values).catch(showErrorMessage);
    }
    if (response && response.data) {
      await filesApi.uploadFiles(attachments, response.data.id, linkedResourceType).catch(showErrorMessage);
      onClose(response.data);
    } else if (response) {
      showErrorMessage(response.message);
    }
  };

  const handleDuplicateClick = () => {
    queryParams.set(DUPLICATE_MODE_QUERY_PARAMS.taskId, taskId);
    queryParams.historyPush();

    dispatchAnalytics("button_click", {
      label: DuplicateDialogButtonLabelValues.DUPLICATE_TASK
    });
  };
  const renderHeaderActionButtons = () => (
    <DialogHeaderActionButtons
      onDuplicateClick={handleDuplicateClick}
      hideDeleteButton
      hideDuplicateButton={duplicateMode || !editMode}
    />
  );

  const renderActionPanelButtons = () => (
    <FormActionButtons
      propsSubButton={{ onClick: _onBackdropClick }}
      propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
    />
  );

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      if (loadingDialogState !== DialogState.Hidden) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }
    if (viewIndex === 1) {
      return "contentOnly";
    }
    return "contentOnly";
  }, [viewIndex, loadingDialogState]);

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={duplicateModeTitle || currentTitle}
      width={dialogWidth}
      height={getDialogFrameDimension("height", 950)}
      renderView={renderView}
      numViews={2}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      RenderHeaderActionButtons={renderHeaderActionButtons}
      frameType={frameType}
      keepViewsMounted={false}
    />
  );
};

export default TaskDialog;
