import type { CSSProperties } from "react";
import React, { useMemo } from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import { PencilIcon } from "assets/icons";
import { Icon } from "DLUI/icon";
import type { FieldProps } from "formik";
import { getIn } from "formik";
import "./styles.css";
import type { ViewBackgroundColor } from "DLUI/view/view";

import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import type { Theme } from "styles/defaultTheme";
import { useTheme } from "@material-ui/styles";

interface ComponentProps extends FieldProps<any> {
  rowsMax?: number;
  height?: number;
  disabled?: boolean;
  viewOnly?: boolean;
  marginTop?: number;
  backgroundColor?: ViewBackgroundColor;
}

const BACKGROUND_COLOR_MAP: Partial<Record<ViewBackgroundColor, CSSProperties["color"]>> = {
  transparent: "transparent"
};

const Notes: React.FC<ComponentProps> = ({
  rowsMax,
  height,
  form,
  field,
  disabled,
  viewOnly,
  marginTop,
  backgroundColor
}) => {
  const initialValue = getIn(form.values, field.name);
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();
  const {
    newPalette: {
      background: { neutral2: defaultBackgroundColor }
    }
  } = useTheme<Theme>();

  const _backgroundColor = useMemo(
    () => (backgroundColor && BACKGROUND_COLOR_MAP[backgroundColor]) || defaultBackgroundColor,
    [backgroundColor, defaultBackgroundColor]
  );

  if (viewOnly && initialValue === undefined) {
    return null;
  }

  return (
    <View
      marginTop={marginTop}
      flexDirection={"row"}
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
      className={"DLUI_Notes"}
    >
      <Icon marginTop={5} marginRight={10} Source={PencilIcon} width={16} height={16} pathColor={"black"} />
      <TextareaAutosize
        {...field}
        disabled={disabled}
        placeholder={t(AppStrings.Leases.LeaseCharge.AddMemo)}
        maxRows={rowsMax || 2}
        maxLength={80}
        style={{
          border: "none",
          backgroundColor: _backgroundColor,
          width: "80%",
          height: height || "auto",
          minHeight: 20,
          color: "#333333",
          fontSize: isMobile ? 16 : 14
        }}
      />
    </View>
  );
};

export default Notes;
