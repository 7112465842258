import type { RentalApplicationCompanySettingsDto } from "@doorloop/dto";
import { RentalApplicationFieldSettings, RentalApplicationOptionalControlledSections } from "@doorloop/dto";
import { View } from "DLUI/view";
import { FastField, getIn, useFormikContext } from "formik";
import React, { Fragment, useMemo, useState } from "react";
import Text from "DLUI/text";
import AppStrings from "../../../../../../locale/keys";
import { FormikSwitchButton, RadioGroup } from "DLUI/form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { SeparationLine } from "DLUI/separatorView";
import { RichTextField } from "DLUI/form/richTextField/richTextField";
import { RentalAppFieldsGenerator } from "screens/settings/rentalApplications/rentalAppFieldsGenerator";
import { RentalApplicationWizardSteps } from "screens/tenantPortal/rentalApplication/types";
import { didChangeShowContentSelection } from "screens/settings/rentalApplications/formikHelper";
import { renderFieldsCannotBeEmptyError } from "screens/settings/rentalApplications/components/vehicleInformation/vehicleInformation";

const Pets: React.FC<{}> = () => {
  const { t } = useTranslation();
  const formik = useFormikContext<RentalApplicationCompanySettingsDto>();
  const {
    values: { pets },
    setFieldValue
  } = formik;
  const [showContent, setShowContent] = useState<boolean>(Boolean(getIn(pets, "isShown")) || false);
  const { propertyId } = useParams<any>();
  const [renderForm, setRenderForm] = useState<boolean>(true);

  const petsSelectionDefaultValue = useMemo(() => {
    const emptyObject = _.values(pets).every((x) => x === undefined);
    if (emptyObject) {
      return "companyDefault";
    }
    return "specifyPetsInfo";
  }, []);

  const [petsSelectionType, setPetsSelectionType] = useState<"companyDefault" | "specifyPetsInfo">(
    petsSelectionDefaultValue
  );

  const didChangeSpecifyPetsSelection = (selectedValue: string) => {
    if (selectedValue === "false") {
      setPetsSelectionType("companyDefault");

      setTimeout(() => {
        refreshForm();
      }, 500);
    } else {
      setPetsSelectionType("specifyPetsInfo");
      setFieldValue("pets.isShown", false);
    }
  };

  const refreshForm = () => {
    setRenderForm(false);
    setShowContent(false);
    setFieldValue("pets", undefined);

    setTimeout(() => {
      setRenderForm(true);
    }, 0);
  };

  const renderSpecifyRadioButtons = () => {
    if (propertyId) {
      return (
        <View>
          <RadioGroup
            didChangeSelection={didChangeSpecifyPetsSelection}
            uniqueKey={"FRB"}
            defaultValue={petsSelectionType === "companyDefault" ? "false" : "true"}
            marginTop={20}
            radioGroupItems={[
              {
                label: AppStrings.Common.HideSectionForProperty,
                value: "false"
              },
              {
                label: AppStrings.Common.SpecifySettingsForProperty,
                value: "true"
              }
            ]}
          />
          <SeparationLine marginTop={20} width={"100%"} height={1} />
        </View>
      );
    }
  };

  return (
    <>
      <Text value={AppStrings.Settings.GeneralSettings.RentalApplications.PetsSectionDescription} lineHeight={"26px"} />
      {renderSpecifyRadioButtons()}
      <View shouldShow={petsSelectionType === "specifyPetsInfo"} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
        {renderForm ? (
          <Fragment>
            <View height={20} />
            <FastField
              component={FormikSwitchButton}
              name={"pets.isShown"}
              label={AppStrings.Settings.GeneralSettings.RentalApplications.AskAboutPets}
              onChange={(value: boolean) =>
                didChangeShowContentSelection({
                  value,
                  setFunction: setShowContent,
                  formik,
                  formName: "pets",
                  fieldsObj: RentalApplicationFieldSettings.pets.fields
                })
              }
            />
            <View shouldShow={showContent} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
              <Text
                value={AppStrings.Tasks.WorkOrdersScreen.Instructions}
                fontSize={20}
                fontWeight={700}
                marginTop={20}
              />
              <FastField
                component={RichTextField}
                label={t(AppStrings.Tasks.WorkOrdersScreen.Instructions)}
                name={`pets.instructions`}
                styles={{ container: { marginTop: 20 } }}
                maxLength={5000}
                html={{ name: "pets.instructionsHTML", value: pets?.instructionsHTML }}
              />
              <View flexDirection={"row"} justifyContent={"space-between"} noWrap marginTop={30} marginBottom={12}>
                <Text value={AppStrings.Common.Field} fontSize={12} color={"secondary-gray"} />
                <Text value={AppStrings.Common.Required} fontSize={12} color={"secondary-gray"} />
              </View>
              <View noWrap>
                <RentalAppFieldsGenerator
                  fieldsConfigObject={RentalApplicationFieldSettings.pets.fields}
                  formik={formik}
                  formName={RentalApplicationWizardSteps.Pets}
                />
                {renderFieldsCannotBeEmptyError(pets, RentalApplicationOptionalControlledSections.Pets)}
              </View>
              <View height={50} />
            </View>
          </Fragment>
        ) : null}
      </View>
    </>
  );
};

export default Pets;
