import React, { Fragment, useEffect, useState } from "react";
import makeStyles from "./styles";
import type { FileListItemProps, FileRetryMethod } from "./fileListItem";
import { FilesListItem } from "./fileListItem";
import { animated, config, useTrail } from "react-spring";
import { Button } from "DLUI/button";
import { AddIcon } from "assets/icons";
import AppStrings from "locale/keys";

interface ComponentProps {
  filesItemsArray: FileListItemProps[];
  onDeleteItem: (itemIndex: number) => void;
  onRetryPress?: (fileIndex: number, retryMethod: FileRetryMethod) => void;
  displayType?: "small" | "large";
  itemHeight?: number;
  baseUrl?: string;
  maxFiles?: number;
  allowDelete?: boolean;
}

const FileItemSize = 60;

const FilesList: React.FC<ComponentProps> = ({
  filesItemsArray,
  onDeleteItem,
  onRetryPress,
  displayType,
  itemHeight,
  baseUrl,
  maxFiles = 0,
  allowDelete = false
}: ComponentProps) => {
  const classes = makeStyles();
  const [currentFilesArray, setCurrentFilesArray] = useState(filesItemsArray);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const fileItemTransition = useTrail(currentFilesArray.length, {
    config: { ...config.default },
    from: {
      opacity: isFirstLoad ? 0 : 1,
      height: isFirstLoad ? 0 : itemHeight || FileItemSize
    },
    to: { opacity: 1, height: itemHeight || FileItemSize }
  });

  useEffect(() => {
    setCurrentFilesArray(filesItemsArray);
  }, [filesItemsArray]);

  useEffect(() => {
    setIsFirstLoad(false);
  }, []);

  const didPressAddButton = () => {};

  const imageButtons = fileItemTransition.map(({ ...rest }, index) => (
    <Fragment key={"FLI" + index}>
      <animated.div className={classes.fileItemWrapper} key={"FI" + index} style={{ ...rest }}>
        <FilesListItem
          name={currentFilesArray[index].name}
          icon={currentFilesArray[index].icon}
          allowDelete={allowDelete}
          didPressDeleteIcon={onDeleteItem}
          itemIndex={index}
          fileUploadState={currentFilesArray[index].fileUploadState || "UploadSuccessful"}
          fileUploadErrorMessage={currentFilesArray[index].fileUploadErrorMessage}
          didPressRetry={onRetryPress}
          displayType={displayType}
          fileData={currentFilesArray[index].fileData}
          id={currentFilesArray[index].id}
          baseUrl={baseUrl}
        />
      </animated.div>
    </Fragment>
  ));

  return (
    <div className={classes.selectedFilesContainer}>
      {imageButtons}
      {(maxFiles === 0 || currentFilesArray.length < maxFiles) && (
        <Button
          color={"lightBlue"}
          type={"inlineText"}
          actionText={AppStrings.Common.Dropzone.Addfile}
          onClick={didPressAddButton}
          LeftIcon={AddIcon}
          iconSize={15}
          marginLeft={20}
          applyColorForIcons
        />
      )}
    </div>
  );
};

export default FilesList;
