"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyGlobalSettingsDto = void 0;
const accountingMethod_enum_1 = require("../journalEntries/accountingMethod.enum");
const class_validator_1 = require("class-validator");
const lateFeesPolicy_dto_1 = require("./shared/lateFeePolicy/lateFeesPolicy.dto");
const companyStartDate_dto_1 = require("../settings/companyStartDate.dto");
const rentalApplicationPropertySettings_dto_1 = require("./shared/rentalApplication/rentalApplicationPropertySettings.dto");
const mailingTemplatesSettings_dto_1 = require("./shared/mailingTemplatesSettings.dto");
const managementFees_1 = require("./shared/managementFees");
const paymentAllocationsSettings_dto_1 = require("./shared/paymentAllocationsSettings.dto");
const paymentInstructionsSettings_dto_1 = require("./shared/paymentInstructionsSettings.dto");
const propertyReserveSettings_dto_1 = require("./shared/propertyReserveSettings.dto");
const recurringTransactionsSettings_dto_1 = require("./shared/recurringTransactionsSettings.dto");
const rentersInsuranceSettings_dto_1 = require("./shared/rentersInsuranceSettings.dto");
const rentRemindersSettings_dto_1 = require("./shared/rentRemindersSettings.dto");
const requestsSettings_dto_1 = require("./shared/requestsSettings.dto");
const tenantPortalSettings_dto_1 = require("./shared/tenantPortal/tenantPortalSettings.dto");
const communicationCenterSettings_dto_1 = require("./shared/communicationCenterSettings.dto");
class CompanyGlobalSettingsDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.CompanyGlobalSettingsDto = CompanyGlobalSettingsDto;
__decorate([
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", tenantPortalSettings_dto_1.TenantPortalSettingsDto)
], CompanyGlobalSettingsDto.prototype, "tenantPortal", void 0);
__decorate([
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", lateFeesPolicy_dto_1.LateFeesPolicyDto)
], CompanyGlobalSettingsDto.prototype, "lateFeesPolicy", void 0);
__decorate([
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentersInsuranceSettings_dto_1.RentersInsuranceSettingsDto)
], CompanyGlobalSettingsDto.prototype, "rentersInsurance", void 0);
__decorate([
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", paymentAllocationsSettings_dto_1.PaymentAllocationsSettingsDto)
], CompanyGlobalSettingsDto.prototype, "paymentAllocations", void 0);
__decorate([
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", recurringTransactionsSettings_dto_1.RecurringTransactionsSettingsDto)
], CompanyGlobalSettingsDto.prototype, "recurringTransactions", void 0);
__decorate([
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], CompanyGlobalSettingsDto.prototype, "defaultManagementOperatingAccount", void 0);
__decorate([
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", propertyReserveSettings_dto_1.PropertyReserveSettingsDto)
], CompanyGlobalSettingsDto.prototype, "propertyReserve", void 0);
__decorate([
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", managementFees_1.ManagementFeesCompanySettingsDto)
], CompanyGlobalSettingsDto.prototype, "managementFees", void 0);
__decorate([
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", mailingTemplatesSettings_dto_1.MailingTemplatesSettingsDto)
], CompanyGlobalSettingsDto.prototype, "mailingTemplates", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(accountingMethod_enum_1.AccountingMethod),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], CompanyGlobalSettingsDto.prototype, "accountingMethod", void 0);
__decorate([
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentalApplicationPropertySettings_dto_1.RentalApplicationPropertySettingsDto)
], CompanyGlobalSettingsDto.prototype, "rentalApplications", void 0);
__decorate([
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", paymentInstructionsSettings_dto_1.PaymentInstructionsSettingsDto)
], CompanyGlobalSettingsDto.prototype, "paymentInstructions", void 0);
__decorate([
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", rentRemindersSettings_dto_1.RentRemindersSettingsDto)
], CompanyGlobalSettingsDto.prototype, "rentReminders", void 0);
__decorate([
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", requestsSettings_dto_1.RequestsSettingsDto)
], CompanyGlobalSettingsDto.prototype, "tenantRequests", void 0);
__decorate([
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", requestsSettings_dto_1.RequestsSettingsDto)
], CompanyGlobalSettingsDto.prototype, "ownerRequests", void 0);
__decorate([
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", companyStartDate_dto_1.CompanyStartDateDto)
], CompanyGlobalSettingsDto.prototype, "companyStartDate", void 0);
__decorate([
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", communicationCenterSettings_dto_1.CommunicationCenterSettingsDto)
], CompanyGlobalSettingsDto.prototype, "communicationCenter", void 0);
