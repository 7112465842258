"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientAnnouncementDto = exports.AnnouncementDto = void 0;
const class_validator_1 = require("class-validator");
const base_dto_1 = require("../base.dto");
const announcementEmailInfo_dto_1 = require("../announcements/announcementEmailInfo.dto");
const announcementTextInfo_dto_1 = require("../announcements/announcementTextInfo.dto");
const announcementPortalInfo_dto_1 = require("../announcements/announcementPortalInfo.dto");
const announcementVoiceInfo_dto_1 = require("../announcements/announcementVoiceInfo.dto");
const constants_1 = require("../journalEntries/payBills/constants");
class AnnouncementDto extends base_dto_1.BaseDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.AnnouncementDto = AnnouncementDto;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(50),
    __metadata("design:type", String)
], AnnouncementDto.prototype, "subject", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(10000),
    __metadata("design:type", String)
], AnnouncementDto.prototype, "body", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(10000),
    __metadata("design:type", String)
], AnnouncementDto.prototype, "bodyHTML", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    (0, class_validator_1.ArrayMinSize)(1),
    __metadata("design:type", Array)
], AnnouncementDto.prototype, "properties", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    (0, class_validator_1.ArrayMinSize)(1),
    __metadata("design:type", Array)
], AnnouncementDto.prototype, "propertyGroups", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    (0, class_validator_1.ArrayMinSize)(1),
    __metadata("design:type", Array)
], AnnouncementDto.prototype, "units", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    (0, class_validator_1.ArrayMinSize)(1),
    __metadata("design:type", Array)
], AnnouncementDto.prototype, "tenants", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", announcementEmailInfo_dto_1.AnnouncementEmailInfoDto)
], AnnouncementDto.prototype, "emailInfo", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", announcementTextInfo_dto_1.AnnouncementTextInfoDto)
], AnnouncementDto.prototype, "textInfo", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", announcementVoiceInfo_dto_1.AnnouncementVoiceInfoDto)
], AnnouncementDto.prototype, "voiceInfo", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", announcementPortalInfo_dto_1.AnnouncementPortalInfoDto)
], AnnouncementDto.prototype, "portalInfo", void 0);
class ClientAnnouncementDto extends AnnouncementDto {
    constructor(init) {
        super();
        this.recipientPropertiesType = constants_1.SelectPropertiesType.ALL_PROPERTIES;
        Object.assign(this, init);
    }
}
exports.ClientAnnouncementDto = ClientAnnouncementDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], ClientAnnouncementDto.prototype, "portalInfoShouldExpire", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(constants_1.SelectPropertiesType),
    __metadata("design:type", String)
], ClientAnnouncementDto.prototype, "recipientPropertiesType", void 0);
