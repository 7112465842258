import { createValidator, ObjectPermission, PropertyGroupDto } from "@doorloop/dto";
import { Grid } from "@material-ui/core";
import { propertiesApi } from "api/propertiesApi";
import { propertyGroupsApi } from "api/propertyGroupsApi";
import { CloseIcon, DeleteIcon, EditBlue, VSignIcon } from "assets/icons";
import { ShakeEffectView } from "DLUI/animatableView";
import { TextField, ViewOnlyInput } from "DLUI/form";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, Formik, getIn } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PropertyGroupsListItemActionPanel from "./propertyGroupsListItemActionPanel";
import "./styles.css";
import Text from "DLUI/text";
import FormikCachedMultiSelectAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedMultiSelectAutoComplete";
import type { PropertyGroupItemDisplayState } from "./propertyGroups.types";

let formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

const validateForm = createValidator(PropertyGroupDto);

interface ComponentProps {
  name?: string;
  id?: string;
  properties?: string[];
  index?: number;

  onDeleteEvent: (tagId?: string) => void;
}
const PropertyGroupsListItem: React.FC<ComponentProps> = ({
  name,
  id,
  properties,
  onDeleteEvent,
  index
}: ComponentProps) => {
  const [itemDisplayState, setItemDisplayState] = useState<PropertyGroupItemDisplayState>(name ? "readOnly" : "edit");
  const [currentValidationMessage, setCurrentValidationMessage] = useState<string | undefined>();

  const { t } = useTranslation();
  const [tagDefaultValue, setTagDefaultValue] = useState<string | undefined>(name || undefined);
  const [tagName, setTagName] = useState<string | undefined>(tagDefaultValue);
  const [tagId, setTagId] = useState<string | undefined>(id);
  const [propertiesDefaultValue, setPropertiesDefaultValue] = useState<string[] | undefined>(properties);
  const [tagProperties, setTagProperties] = useState<string[] | undefined>(propertiesDefaultValue);
  const [showErrorShakeEffect, setShowErrorShakeEffect] = useState(false);

  const didChangeTagName = (nextValue: string) => {
    setTagName(nextValue);
  };

  const initFormvalues = (): PropertyGroupDto => {
    const propertyGroupDto = new PropertyGroupDto();
    propertyGroupDto.id = id;
    propertyGroupDto.name = tagName;
    propertyGroupDto.properties = tagProperties;
    return propertyGroupDto;
  };

  const validateForm = createValidator(PropertyGroupDto);

  const isValidForm = async (formikRef: FormikProps<PropertyGroupDto>) => {
    formikRef.setFieldTouched("name");
    formikRef.setFieldTouched("properties");
    const errors = (await formikRef.validateForm()) as any;
    return _.isEmpty(errors);
  };

  const propertiesNames = useMemo(() => {
    if (tagProperties) {
      return tagProperties
        .map((propertyId: string) => {
          const dictionary = propertiesApi.getItemFromDictionary(propertyId);
          if (dictionary) {
            return dictionary.name + " " || "";
          }
        })
        .filter((name) => name);
    }
    return [""];
  }, [tagProperties]);

  const didChangePropertiesSelection = (event: any, value: string[]) => {
    setTagProperties(value);
  };

  const renderError = () => (
    <View shouldShow={currentValidationMessage !== undefined} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
      <View marginTop={5} height={25}>
        <Text paddingLeft={15} fontSize={14} color={"error"}>
          {currentValidationMessage}
        </Text>
      </View>
    </View>
  );

  const renderInput = () => {
    if (itemDisplayState === "edit" || itemDisplayState === "error") {
      const formInitialValues = initFormvalues();
      return (
        <Formik initialValues={formInitialValues} onSubmit={(values, { setSubmitting }) => {}} validate={validateForm}>
          {(formik) => {
            formikGlobalRef = formik;
            return (
              <View paddingRight={20}>
                <View backgroundColor={"dark"} borderRadius={10} className={"DLUI_tagListItemInput"}>
                  <View paddingLeft={20}>
                    <ShakeEffectView fullWidth showEffect={showErrorShakeEffect}>
                      <FastField
                        component={TextField}
                        label={t(AppStrings.Settings.GeneralSettings.TagName)}
                        name={"name"}
                        size={"100%"}
                        autoFocus
                        marginTop={20}
                        onChange={didChangeTagName}
                        paddingRight={20}
                      />
                      {renderError()}
                      <FormikCachedMultiSelectAutoComplete
                        uniqueIndex={"propertiesApi" + index}
                        apiHandler={propertiesApi}
                        displayNameKey={"name"}
                        filterFieldName={"filter_text"}
                        filterFieldValue={"name"}
                        selectionFields={["id", "class"]}
                        name={"properties"}
                        label={t(AppStrings.DrawerItems.Properties)}
                        marginTop={20}
                        errorLabelPaddingLeft={15}
                        paddingRight={20}
                        onChange={didChangePropertiesSelection}
                        queryParams={{ filter_active: true }}
                      />
                    </ShakeEffectView>
                    <View height={20} />
                  </View>
                </View>
              </View>
            );
          }}
        </Formik>
      );
    }
    if (itemDisplayState === "readOnly" || itemDisplayState === "confirmDelete" || itemDisplayState === "loading") {
      return (
        <View paddingRight={20} noWrap>
          <ShakeEffectView fullWidth showEffect={showErrorShakeEffect}>
            <ViewOnlyInput
              fullWidth
              label={AppStrings.Settings.GeneralSettings.PropertyGroups.PortfolioName}
              height={60}
              value={tagName}
              marginLeft={20}
            />
            <ViewOnlyInput
              fullWidth
              label={AppStrings.Settings.GeneralSettings.PropertyGroups.PropertiesNames}
              value={propertiesNames.join(", ")}
              marginTop={-3}
              height={"auto"}
              marginLeft={20}
            />
          </ShakeEffectView>
        </View>
      );
    }
    return null;
  };

  const updateTag = async (tagId: string) => {
    if (formikGlobalRef) {
      setItemDisplayState("loading");

      const response = await propertyGroupsApi.update(tagId, formikGlobalRef.values).catch((e) => {
        showErrorMessage(e);
      });
      if (response && response.status && response.data) {
        setTagName(response.data.name || "");
        setTagDefaultValue(response.data.name || "");
        setTagId(response.data.id!);
        setTagProperties(response.data.properties);
        setPropertiesDefaultValue(response.data.properties);
        setItemDisplayState("readOnly");
      } else if (response) {
        showErrorMessage(response.message);
      } else {
        showErrorMessage(t(AppStrings.Common.GeneralError));
      }
    }
  };

  const createTag = async () => {
    if (formikGlobalRef) {
      setItemDisplayState("loading");

      const response = await propertyGroupsApi.create(formikGlobalRef.values).catch((e) => {
        showErrorMessage(e);
      });
      if (response && response.status && response.data) {
        setTagName(response.data.name || "");
        setTagId(response.data.id!);
        setTagProperties(response.data.properties);
        setItemDisplayState("readOnly");
      } else if (response) {
        showErrorMessage(response.message);
      } else {
        showErrorMessage(t(AppStrings.Common.GeneralError));
      }
    }
  };

  const didPressSaveTag = async () => {
    if (formikGlobalRef) {
      if (itemDisplayState === "loading") {
        return;
      }

      const isValidFrom = await isValidForm(formikGlobalRef);

      if (!isValidFrom) {
        runShakeEffect();
        return;
      }

      if (tagName === tagDefaultValue && _.isEqual(properties, getIn(formikGlobalRef.values, "properties"))) {
        setItemDisplayState("readOnly");
        return;
      }
      if (tagId) {
        await updateTag(tagId);
      } else {
        await createTag();
      }
    } else {
      runShakeEffect();
    }
  };

  const didPressCancelButton = () => {
    if (tagName === "" || currentValidationMessage !== undefined) {
      onDeleteEvent(tagId);
    } else {
      setItemDisplayState("readOnly");
    }
  };
  const didPressEditTag = () => {
    setCurrentValidationMessage(undefined);
    setItemDisplayState("edit");
  };

  const didPressConfirmDeleteButton = () => {
    setItemDisplayState("confirmDelete");
  };

  const runShakeEffect = () => {
    setShowErrorShakeEffect(true);
    setTimeout(() => {
      setShowErrorShakeEffect(false);
    }, 300);
  };

  const showErrorMessage = (error: string) => {
    setItemDisplayState("error");
    setCurrentValidationMessage(error);
    runShakeEffect();
  };

  const didPressDeleteButton = async () => {
    if (tagId) {
      setItemDisplayState("loading");
      const response = await propertyGroupsApi.delete(tagId).catch(() => {
        setItemDisplayState("error");
      });
      if (response && response.status) {
        setItemDisplayState("readOnly");
        onDeleteEvent(id);
      } else {
        setItemDisplayState("error");
        if (response) {
          showErrorMessage(response.message);
        } else {
          showErrorMessage(t(AppStrings.Common.GeneralError));
        }
      }
    } else {
      onDeleteEvent();
    }
  };

  const renderItemActionsPanel = () => (
    <View>
      <PropertyGroupsListItemActionPanel
        type={"edit"}
        itemDisplayState={itemDisplayState}
        actions={[
          {
            icon: VSignIcon,
            onClick: didPressSaveTag
          },
          {
            icon: CloseIcon,
            onClick: didPressCancelButton
          }
        ]}
      />
      <PropertyGroupsListItemActionPanel
        type={"error"}
        itemDisplayState={itemDisplayState}
        actions={[
          {
            icon: VSignIcon,
            onClick: didPressSaveTag
          },
          {
            icon: CloseIcon,
            onClick: didPressCancelButton
          }
        ]}
      />
      <PropertyGroupsListItemActionPanel
        itemDisplayState={itemDisplayState}
        type={"readOnly"}
        actions={[
          {
            icon: EditBlue,
            clearance: {
              permission: ObjectPermission.propertyGroups,
              field: "edit"
            },
            onClick: didPressEditTag
          },
          {
            icon: DeleteIcon,
            clearance: {
              permission: ObjectPermission.propertyGroups,
              field: "delete"
            },
            onClick: didPressConfirmDeleteButton
          }
        ]}
      />
      <PropertyGroupsListItemActionPanel type={"loading"} itemDisplayState={itemDisplayState} />
      <PropertyGroupsListItemActionPanel
        type={"confirmDelete"}
        itemDisplayState={itemDisplayState}
        actions={[
          {
            icon: DeleteIcon,
            onClick: didPressDeleteButton
          },
          {
            icon: CloseIcon,
            onClick: didPressCancelButton,
            iconPathColor: "gray"
          }
        ]}
      />
    </View>
  );

  return (
    <View
      marginTop={20}
      flexDirection={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      minHeight={55}
      borderRadius={10}
      overflow={"hidden"}
    >
      <Grid container item xs={9}>
        {renderInput()}
      </Grid>
      <Grid container item xs={3}>
        {renderItemActionsPanel()}
      </Grid>
    </View>
  );
};
export default PropertyGroupsListItem;
