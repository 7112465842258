import type { FC } from "react";
import React, { useMemo } from "react";
import { View } from "DLUI/view";
import { CommunicationStatus } from "screens/communications/communicationStatus";
import { Icon } from "DLUI/icon";
import type { SVGIconComponent } from "assets/icons";
import { AnnouncementEmailIcon, SimpleCalendarIcon } from "assets/icons";
import Text from "DLUI/text";
import { SeparationLine } from "DLUI/separatorView";
import { CommunicationType } from "@doorloop/dto";
import AppStrings from "locale/keys";
import type { CommunicationDto } from "@doorloop/dto";
import { DateFormats } from "@doorloop/dto";
import { DateLabel } from "DLUI/date/dateLabel";

const typeToViaString: {
  [key in CommunicationType]: { label: string; IconSource: SVGIconComponent };
} = {
  [CommunicationType.EMAIL]: {
    label: AppStrings.Announcements.viaEmail,
    IconSource: AnnouncementEmailIcon
  },
  [CommunicationType.TEXT]: {
    label: AppStrings.Announcements.viaTextMessage,
    IconSource: AnnouncementEmailIcon
  },
  [CommunicationType.PHONE]: {
    label: AppStrings.Announcements.viaPhoneCall,
    IconSource: AnnouncementEmailIcon
  }
};

interface Props {
  dto: CommunicationDto;
  type: CommunicationType;
}

export const StatusAndSummary: FC<Props> = ({ dto, type }: Props) => {
  const { label, IconSource } = useMemo(() => typeToViaString[type], [type]);

  return (
    <View height={35} marginTop={30} marginBottom={20} flexDirection={"row"} alignItems={"center"}>
      <View justifyContent={"center"} autoWidth>
        <CommunicationStatus dto={dto} />
      </View>
      <View alignItems={"flex-end"} flex={1} justifyContent={"center"}>
        <View flexDirection={"row"} alignItems={"center"} height={25} autoWidth noWrap>
          <Icon Source={IconSource} pathColor={"light-gray"} marginRight={10} width={18} />
          <Text value={label} fontSize={14} color={"gray"} marginRight={10} />
          <SeparationLine marginRight={10} width={2} height={"100%"} />
          <Icon Source={SimpleCalendarIcon} pathColor={"light-gray"} width={15} marginRight={10} />
          <DateLabel
            date={dto.sentAt}
            format={DateFormats.THREE_LETTER_MONTH_AND_DATE}
            textProps={{ fontSize: 14, color: "gray" }}
          />
        </View>
      </View>
    </View>
  );
};
