import { makeStyles } from "@material-ui/styles";
import type { Theme } from "styles/defaultTheme";

export const useMobileStyles = makeStyles<Theme>((theme) => {
  return {
    ruleItemMobileContainer: {
      backgroundColor: theme.palette.white.main,
      width: "100%",
      height: "100%"
    },
    ruleItemMobileHeader: {
      height: 78,
      borderBottom: `1px solid ${theme.newPalette.other.divider}`,
      paddingLeft: 8,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: 16
    },
    ruleItemMobileContent: ({ screenContainerPadding = 0 }: Partial<{ screenContainerPadding: number }>) => {
      return {
        paddingLeft: screenContainerPadding,
        paddingRight: screenContainerPadding,
        width: `calc(100% - ${2 * screenContainerPadding}px)`
      };
    },
    ruleItemMobileButtons: {
      height: 84,
      marginTop: "auto",
      backgroundColor: theme.newPalette.background.neutral2,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: 8
    }
  };
});
