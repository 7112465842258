import type { CompanyInfoSettingsDto, DeepKeys, TenantPortalSettingsDto } from "@doorloop/dto";
import {
  createValidator,
  PortalContactInfoDto,
  PortalEmailInviteDto,
  PortalRequestSettingsDto,
  PortalThemeDto,
  TenantPortalAccessDto,
  TenantPortalGeneralSettingsDto
} from "@doorloop/dto";
import type { FormikProps } from "formik";
import isEmpty from "lodash/isEmpty";

export const initFormValues = (): TenantPortalGeneralSettingsDto => {
  const tenantPortalSettings = new TenantPortalGeneralSettingsDto();
  tenantPortalSettings.access = new TenantPortalAccessDto();
  tenantPortalSettings.theme = new PortalThemeDto();
  tenantPortalSettings.emailInvite = new PortalEmailInviteDto();
  tenantPortalSettings.contactInfo = new PortalContactInfoDto();
  tenantPortalSettings.requestSettings = new PortalRequestSettingsDto();

  return tenantPortalSettings;
};

export const validateForm = createValidator(TenantPortalGeneralSettingsDto);

export enum TenantPortalFormSteps {
  General = 0,
  ContactInfo = 1,
  EmailInvite = 3,
  Subdomain = 4
}

export const validateTenantPortalForm = async (
  formikRef: FormikProps<CompanyInfoSettingsDto>
): Promise<{ isValid: boolean; errorStepIndex?: number }> => {
  // validate the form
  formikRef.setFieldTouched("emailInvite.emailBody");
  formikRef.setFieldTouched("contactInfo.address.street1");
  formikRef.setFieldTouched("contactInfo.address.city");
  formikRef.setFieldTouched("contactInfo.address.zip");
  formikRef.setFieldTouched("contactInfo.address.country");

  const errors = (await formikRef.validateForm()) as any;
  if (isEmpty(errors)) {
    return { isValid: true };
  }
  let errorStepIndex = TenantPortalFormSteps.General;

  if (errors.contactInfo) {
    errorStepIndex = TenantPortalFormSteps.ContactInfo;
  }
  if (errors.emailInvite) {
    errorStepIndex = TenantPortalFormSteps.EmailInvite;
  }
  if (errors.subdomain) {
    errorStepIndex = TenantPortalFormSteps.Subdomain;
  }

  return { isValid: false, errorStepIndex };
};

export function getFormikPortalSettingsProperty(field: DeepKeys<TenantPortalSettingsDto>, propertyId?: string): string {
  return !isEmpty(propertyId) ? `settings.tenantPortal.${field}` : field;
}
