import type { PropertyOwnerDto, UnitDto } from "@doorloop/dto";
import { createValidator, PaymentInstructionsSettingsDto, PropertyDto, PropertySettingsDto } from "@doorloop/dto";
import type { ApiResult } from "api/apiResult";
import { propertiesApi } from "api/propertiesApi";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import { RadioGroup, TextField } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, Formik } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";

interface ComponentProps {
  didFinishOperation: (values: UnitDto) => void;
  onBackdropClick: () => void;
  refreshEvent?: () => void;
  dialogTitle: string;
}

let formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

const DialogFrameMinHeight = 640;

const EditPaymentInstructions: React.FC<ComponentProps> = ({
  didFinishOperation,
  refreshEvent,
  onBackdropClick,
  dialogTitle
}: ComponentProps) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();
  const { dialogHorizontalPadding } = DialogsHelper();
  const { propertyId } = useParams<any>();
  const [propertyData, setPropertyData] = useState<PropertyDto | undefined>();
  const [viewIndex, setViewIndex] = useState(0);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);

  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>(AppStrings.Common.NetworkErrorSubTitle);

  const [hideLoadingDialogActionButtons, setHideLoadingDialogActionButtons] = useState<boolean>(false);

  const [paymentInstructionsSelectionType, setPaymentInstructionsSelectionType] = useState<
    "companyDefault" | "specifyPaymentInstructions"
  >("companyDefault");

  const loadPropertyData = async () => {
    if (propertyId) {
      const response = await propertiesApi.get(propertyId).catch((e: string) => {
        showDialogError(e);
      });
      if (response && response.status) {
        if (response.data && response.data.settings && response.data.settings.paymentInstructions) {
          const paymentInstructions = response.data.settings.paymentInstructions;
          if (paymentInstructions) {
            setPaymentInstructionsSelectionType("specifyPaymentInstructions");
          }
        }
        setPropertyData(response.data);
        setLoadingDialogState(DialogState.Success);
      } else {
        showDialogError();
      }
    } else {
      showDialogError();
    }
  };

  const showDialogError = (errorMessage?: string) => {
    setHideLoadingDialogActionButtons(true);
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(errorMessage || AppStrings.Common.GeneralError);
  };

  useEffect(() => {
    loadPropertyData();
    return () => {
      formikGlobalRef = null;
    };
  }, []);

  const initFormvalues = (): PropertyDto => {
    if (formikGlobalRef && formikGlobalRef.values) {
      return formikGlobalRef.values;
    }
    if (propertyData) {
      if (!propertyData.settings) {
        propertyData.settings = new PropertySettingsDto();
      }
      if (!propertyData.settings?.paymentInstructions) {
        propertyData.settings.paymentInstructions = new PaymentInstructionsSettingsDto();
      }

      return propertyData;
    }
    return new PropertyDto();
  };

  const validateForm = createValidator(PropertyDto);

  const isValidForm = async (formikRef: FormikProps<PropertyDto>) => {
    formikRef.setFieldTouched("settings.paymentInstructions.instructions");

    const errors = (await formikRef.validateForm()) as any;
    return _.isEmpty(errors?.settings?.paymentInstructions);
  };

  const updateProperty = async (values: PropertyDto) => {
    const response = (await propertiesApi.update(values.id!, values).catch((error) => {
      setLoadingDialogErrorText(error);
      setLoadingDialogState(DialogState.Error);
    })) as ApiResult<any>;

    if (response.status !== undefined && !response.status) {
      setLoadingDialogErrorText(response.message);
      setLoadingDialogState(DialogState.Error);
    } else {
      setLoadingDialogState(DialogState.Success);
      didFinishUpdateProperty();
      formikGlobalRef = null;
    }
  };

  const didPressSaveButton = async (owners?: PropertyOwnerDto[]) => {
    if (formikGlobalRef !== null && propertyData) {
      const isValid = await isValidForm(formikGlobalRef);
      if (isValid) {
        setLoadingDialogState(DialogState.Show);
        setViewIndex(1);
        updateProperty(formikGlobalRef.values);
      }
    }
  };

  const renderActionPanelButtons = () => {
    if (viewIndex === 1) {
      return <div />;
    }

    return (
      <FormActionButtons
        propsSubButton={{ onClick: onBackdropClick }}
        propsMainButton={{ type: "cta", props: { onClick: async () => await didPressSaveButton() } }}
      />
    );
  };

  const didPressDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(0);
  };

  const onRetryButtonPress = () => {
    if (formikGlobalRef !== null) {
      setLoadingDialogState(DialogState.Hidden);
      setViewIndex(0);
    }
  };

  const didFinishUpdateProperty = () => {
    if (formikGlobalRef !== null) {
      didFinishOperation(formikGlobalRef.values);
      if (refreshEvent) {
        refreshEvent();
      }
      setLoadingDialogState(DialogState.Hidden);
    }
  };

  const didChangepaymentInstructionsSelection = (selectedValue: string) => {
    if (formikGlobalRef !== null) {
      if (selectedValue === "false") {
        formikGlobalRef.setFieldValue("settings.paymentInstructions", undefined);
      } else {
        formikGlobalRef.setFieldValue("settings.paymentInstructions", new PaymentInstructionsSettingsDto());
      }
    }

    setPaymentInstructionsSelectionType(selectedValue === "false" ? "companyDefault" : "specifyPaymentInstructions");
  };

  const renderForm = () => {
    const formInitialValues = initFormvalues();
    return (
      <Formik initialValues={formInitialValues} onSubmit={(values, { setSubmitting }) => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;

          return (
            <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding}>
              <View alignItems={"flex-start"}>
                <Text
                  value={AppStrings.Common.PaymentInstructionsDialogInstructions}
                  fontSize={16}
                  color={"black"}
                  maxWidth={550}
                  lineHeight={"26px"}
                  marginTop={20}
                />
                <FastField
                  uniqueKey={"LF"}
                  component={RadioGroup}
                  name={""}
                  defaultValue={paymentInstructionsSelectionType === "companyDefault" ? "false" : "true"}
                  didChangeSelection={didChangepaymentInstructionsSelection}
                  radioGroupItems={[
                    {
                      label: AppStrings.Common.UseCompanyDefaultPaymentInstructions,
                      value: "false"
                    },
                    {
                      label: AppStrings.Common.SpecifyPaymentInstructionsForProperty,
                      value: "true"
                    }
                  ]}
                  marginTop={10}
                />
                <View
                  shouldShow={paymentInstructionsSelectionType === "specifyPaymentInstructions"}
                  showAnimation={"fade-in"}
                  hideAnimation={"fade-out"}
                >
                  <View>
                    <SeparationLine marginTop={20} width={"100%"} height={1} />
                    <View marginTop={20}>
                      <Text value={AppStrings.Tasks.WorkOrdersScreen.Instructions} fontSize={20} fontWeight={800} />
                      <FastField
                        component={TextField}
                        label={t(AppStrings.Common.PaymentInstructionsLabel)}
                        name={`settings.paymentInstructions.instructions`}
                        multiline
                        multiLineSize={"large"}
                        rows="4"
                        marginTop={20}
                        maxLength={1500}
                      />
                    </View>
                    {isMobile ? <View height={20} /> : null}
                  </View>
                </View>
              </View>
            </View>
          );
        }}
      </Formik>
    );
  };

  const renderView = ({ index }: any) => {
    if (index === 0 && propertyData) {
      return renderForm();
    }
    if (index === 1 || !propertyData) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={t(AppStrings.Common.Loading)}
            errorText={loadingDialogErrorText}
            successText={t(AppStrings.Common.OperationCompleted)}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
            hideButtons={hideLoadingDialogActionButtons}
          />
        </View>
      );
    }
    return <div />;
  };

  const _onBackdropClick = () => {
    if (viewIndex === 1) {
      setViewIndex(0);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const frameType = useMemo(() => {
    if (propertyData === undefined) {
      return "contentOnly";
    }

    if (viewIndex === 0) {
      return "sectionTitleFrame";
    }
    if (viewIndex === 1) {
      return "contentOnly";
    }

    return "topPanel";
  }, [viewIndex, propertyData]);

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={dialogTitle}
      width={750}
      height={DialogFrameMinHeight}
      renderView={renderView}
      numViews={2}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={frameType}
    />
  );
};

export default EditPaymentInstructions;
