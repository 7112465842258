import type { FC } from "react";
import React from "react";
import { DependantDto } from "@doorloop/dto";
import AppStrings from "locale/keys";
import DtoFieldArray from "DLUI/form/dto/DtoFieldArray";
import { DependentFields } from "DLUI/person/dependents/dependentFields";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { View } from "DLUI/view";
import type { RentalApplicationFieldControlledSectionSettingsDto } from "@doorloop/dto";

interface DependentListFormProps {
  name: string;
  stepSettingsDto?: RentalApplicationFieldControlledSectionSettingsDto;
  isControlled?: boolean;
}

export const DependentListForm: FC<DependentListFormProps> = ({ name, stepSettingsDto, isControlled }) => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <View flex={1}>
      <DtoFieldArray
        name={name}
        newItem={() => new DependantDto()}
        newItemText={AppStrings.Common.AddAnotherDependent}
        headerText={AppStrings.Common.DependentInformation}
      >
        {(renderProps) => (
          <DependentFields {...renderProps} stepSettingsDto={stepSettingsDto} isControlled={isControlled} />
        )}
      </DtoFieldArray>
    </View>
  </MuiPickersUtilsProvider>
);
