import type { FC } from "react";
import React from "react";
import { View } from "DLUI/view";
import type { CommunicationDto, FileDto } from "@doorloop/dto";
import { CommunicationType } from "@doorloop/dto";
import { StatusAndSummary } from "screens/communications/communicationDialog/statusAndSummary";
import { SubjectAndParticipants } from "screens/communications/communicationDialog/subjectAndParticipants";
import { SeparationLine } from "DLUI/separatorView";
import AppStrings from "locale/keys";
import { DeleteIcon } from "assets/icons";
import { HTMLContent } from "DLUI/htmlContent/htmlContent";
import { ReadOnlyFileList } from "shared/files/readOnlyFileList";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import { ScreenContainerDefaultPadding } from "../../../../contexts/utils";

interface Props {
  dto: CommunicationDto;
  files: FileDto[];
  onBackdropClick: () => void;
  deleteHandler: () => void;
}

export const CommunicationPreview: FC<Props> = ({ dto, files, deleteHandler, onBackdropClick }: Props) => (
  <View
    height={"100%"}
    alignItems={"center"}
    paddingRight={ScreenContainerDefaultPadding}
    paddingLeft={ScreenContainerDefaultPadding}
  >
    <View alignItems={"center"} height={"100%"}>
      <View height={192} noWrap>
        {dto.type && <StatusAndSummary dto={dto} type={dto.type} />}
        <SubjectAndParticipants dto={dto} />
      </View>
      <View
        flex={1}
        overflow={"auto"}
        marginBottom={20}
        alignItems={dto.type === CommunicationType.EMAIL ? "center" : "flex-start"}
        fullWidth
      >
        <HTMLContent htmlString={dto.bodyHtml || ""} />
      </View>
      <View
        fullWidth
        style={{
          position: "static",
          bottom: 0
        }}
      >
        <ReadOnlyFileList files={files} />
        <SeparationLine width={"100%"} height={2} />
        <View
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginTop={10}
          marginBottom={10}
        >
          <DLButton
            actionText={AppStrings.Common.Delete}
            onClick={deleteHandler}
            variant={DLButtonVariantsEnum.TEXT}
            color={DLButtonColorsEnum.DANGER}
            size={DLButtonSizesEnum.LARGE}
            icons={{ start: { src: DeleteIcon } }}
          />
          <DLButton actionText={AppStrings.Common.Done} onClick={onBackdropClick} style={{ minWidth: 160 }} />
        </View>
      </View>
    </View>
  </View>
);
