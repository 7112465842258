import type { CustomAllocationDto, LeaseDto } from "@doorloop/dto";
import { Grid } from "@material-ui/core";
import { LoadingAnimation } from "DLUI/animations/loadingAnimation";
import { Icon } from "DLUI/icon";
import { InfiniteList } from "DLUI/infiniteList";
import { ListItemIcon } from "DLUI/listItems";
import Text from "DLUI/text/text";
import { View } from "DLUI/view";
import { customAllocationsApi } from "api/customAllocationsApi";
import { leasesApi } from "api/leasesApi";
import { AllocationsIcon, ChevronRightGray } from "assets/.";
import type { FormikContextType } from "formik";
import { useFormikContext } from "formik";
import { useEffectAsync } from "hooks/useEffectAsync";
import AppStrings from "locale/keys";
import React from "react";
import DLButton, { DLButtonSizesEnum } from "DLUI/button/dlButton";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  onCreateNewClicked: (formikProps: FormikContextType<CustomAllocationDto>) => void;
  propertyName: string;
  propertyId: string;
  onEditAllocationClicked: (allocation: CustomAllocationDto) => void;
  onNoUnitsFound: (formikProps: FormikContextType<CustomAllocationDto>) => void;
}

export const AllCustomAllocationsView = ({
  onCreateNewClicked,
  propertyId,
  propertyName,
  onNoUnitsFound,
  onEditAllocationClicked
}: ComponentProps) => {
  const { screenContainerPadding } = useResponsiveHelper();
  const formikContext = useFormikContext<CustomAllocationDto>();

  const [leases, setLeases] = React.useState<LeaseDto[] | undefined>(undefined);

  useEffectAsync(async () => {
    const { data: leases } = await leasesApi.getAll({ filter_property: propertyId, page_size: 100 });
    setLeases(leases?.data ?? []);
  }, []);

  const getLeaseIdForUnit = (unitId: string) => {
    const leaseId = leases?.find(
      (lease) => lease.status === "ACTIVE" && lease.units?.some((_unitId) => _unitId === unitId)
    );
    return leaseId;
  };

  const _onEditAllocationClicked = (allocation: CustomAllocationDto) => () => {
    allocation = {
      ...allocation,
      allocations: allocation.allocations?.map((allocation) => {
        return {
          ...allocation,
          leaseId: getLeaseIdForUnit(allocation.unitId)?.id
        };
      })
    };
    formikContext.resetForm();
    formikContext.setValues(allocation);
    onEditAllocationClicked(allocation);
  };

  const renderItem = async (currentItem: CustomAllocationDto, index: number) => (
    <View
      key={"cav" + index}
      paddingBottom={10}
      paddingTop={10}
      paddingLeft={20}
      paddingRight={20}
      borderRadius={10}
      marginBottom={10}
      backgroundColor="white"
      flexDirection="row"
      alignItems="center"
      cursor="pointer"
      onClick={_onEditAllocationClicked(currentItem)}
    >
      <Grid container alignItems="center">
        <Grid item xs={1}>
          <ListItemIcon Icon={AllocationsIcon} size={60} />
        </Grid>
        <Grid item xs={10} container alignItems="center">
          <View flexDirection="column" height="100%">
            <Text bold>{currentItem.allocationName}</Text>
            {currentItem.allocationDescription && (
              <Text color="secondary-gray">{currentItem.allocationDescription}</Text>
            )}
          </View>
        </Grid>
        <Grid item xs={1} container justifyContent="flex-end">
          <Icon Source={ChevronRightGray} size={14} />
        </Grid>
      </Grid>
    </View>
  );

  const onDataChanged = (data: CustomAllocationDto[]) => {
    if (data.length === 0) {
      onNoUnitsFound(formikContext);
    }
  };

  return (
    <View
      marginBottom={20}
      marginTop={10}
      paddingRight={screenContainerPadding}
      paddingLeft={screenContainerPadding}
      height={"100%"}
      width={"100%"}
      flex={1}
    >
      {!leases ? (
        <View justifyContent="center" alignItems="center" flexDirection="column" height="100%">
          <LoadingAnimation />
        </View>
      ) : (
        <>
          <Grid container style={{ padding: "15px 0 15px 0" }} alignItems="center">
            <Grid item sm xs={12}>
              <Text
                value={AppStrings.Settings.GeneralSettings.CustomAllocations.PropertyNameCustomAllocations}
                bold
                fontSize={18}
                replaceObject={{
                  propertyName
                }}
              />
            </Grid>
            <Grid item xs={12} sm="auto">
              <DLButton
                onClick={() => onCreateNewClicked(formikContext)}
                actionText={AppStrings.Common.CreateNew}
                size={DLButtonSizesEnum.LARGE}
                icons={{ start: { isHidden: false } }}
                style={{ minWidth: "100%" }}
              />
            </Grid>
          </Grid>

          <InfiniteList
            showStickyHeader={false}
            apiMethod={customAllocationsApi}
            filterObj={{ filter_property: propertyId }}
            renderItem={renderItem}
            onDataChange={onDataChanged}
          />
        </>
      )}
    </View>
  );
};
