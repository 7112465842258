import en_strings from "./en_us/strings";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import _ from "lodash";
import type { TranslationOverride } from "./types";

class AppLocale {
  static classinstance: AppLocale | null = null;

  constructor(translationOverrides: TranslationOverride[] = []) {
    const allOverrides = !_.isEmpty(translationOverrides)
      ? translationOverrides.reduce<TranslationOverride>(_.defaultsDeep, {})
      : null;

    i18n
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        interpolation: { escapeValue: false },
        fallbackLng: "en-US",
        // TODO change according to env
        debug: false,
        react: {
          wait: true
        },
        resources: {
          "en-US": {
            translation: allOverrides ? _.defaultsDeep(allOverrides, en_strings) : en_strings
          }
        }
      });
  }

  static getInstance = (translationOverrides: TranslationOverride[] = []) => {
    if (AppLocale.classinstance === null) {
      AppLocale.classinstance = new AppLocale(translationOverrides);
    }
    return AppLocale.classinstance;
  };
}

export default AppLocale;
