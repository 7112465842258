export enum LinkURLsEnum {
  DEMO = "https://www.doorloop.com/demo",
  PATRIOT_ACT_PDF = "https://www.congress.gov/107/plaws/publ56/PLAW-107publ56.pdf",
  CB_ACCOUNT_SETTINGS = "https://app.checkbook.io/account/settings/accounts",
  CB_LEARN_MORE = "https://docs.checkbook.io/docs/web-profile-info",
  CB_CREATE_ACCOUNT = "https://app.checkbook.io/register?referral_code=T1TE56",
  CB_CREATE_ACCOUNT_SANDBOX = "https://sandbox.app.checkbook.io/register?referral_code=T1TE56",
  CB_ACCOUNT_KEYS = "https://app.checkbook.io/account/settings/developer",
  CB_ACCOUNT_KEYS_SANDBOX = "https://sandbox.app.checkbook.io/account/settings/developer",
  DOORLOOP_UNIVERSITY = "https://university.doorloop.com",
  CREATE_LISTING_DOCS = "https://support.doorloop.com/en/articles/6066925-creating-online-listings-for-your-units",
  TROUBLESHOOTING_ZILLOW = "https://support.doorloop.com/en/articles/8336954-troubleshooting-zillow-listings",
  RENTAL_APPLICATION_LEARN_MORE = "https://doorloopcrm.webflow.io/blog/tenant-screening-guide",
  LEARN_MORE_STRIPE_FAILED_CHARGE_FEE = "https://support.doorloop.com/en/articles/7177500-why-does-stripe-debit-our-bank-account-a-return-payment-fee",
  RENTAL_SCREENING_GUIDE = "https://www.doorloop.com/blog/tenant-screening-guide"
}

export enum ImageURLsEnum {
  PAYOUT_INSTRUCTIONS = "/images/payout_Instructions.png"
}
