import React, { useMemo } from "react";
import { View } from "DLUI/view";
import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import type ColorsEnum from "../../../utils/colorsEnum";

export type DividerColor = "gray" | "white" | "pink";

export interface SeparationLineComponentProps {
  marginBottom?: number;
  marginTop?: number | string;
  marginRight?: number;
  marginLeft?: number;
  height?: number | string;
  width?: number | string;
  dividerColor?: DividerColor;
  color?: ColorsEnum;
  style?: React.CSSProperties;
  className?: string;
}

const styles = makeStyles((theme: Theme) =>
  createStyles({
    graySeparatorLine: {
      backgroundColor: "rgba(122,134,153,0.2)"
    },
    whiteSeparatorLine: {
      backgroundColor: theme.palette.screenBackground.main
    },
    pinkSeparatorLine: {
      backgroundColor: theme.palette.pink.main
    }
  })
);

const SeparationLine: React.FC<SeparationLineComponentProps> = ({
  marginBottom,
  marginTop,
  height,
  width,
  dividerColor,
  marginRight,
  marginLeft,
  color,
  style,
  className: propsClassName
}: SeparationLineComponentProps) => {
  const classes = styles();

  const className = useMemo(() => {
    let _className = classes.graySeparatorLine;
    if (dividerColor && dividerColor === "white") {
      _className = classes.whiteSeparatorLine;
    }
    if (dividerColor && dividerColor === "pink") {
      _className = classes.pinkSeparatorLine;
    }
    return `${_className} ${propsClassName}`;
  }, []);
  return (
    <View
      marginTop={marginTop || 0}
      marginBottom={marginBottom || 0}
      height={height || 0}
      marginRight={marginRight}
      marginLeft={marginLeft}
      className={className}
      width={width || 1}
      flexDirection={"row"}
      style={color ? { backgroundColor: color, ...style } : style}
    />
  );
};

export default SeparationLine;
