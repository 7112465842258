const Units = {
  Screen: {
    SearchPaneltitle: "units.screen.searchPaneltitle",
    SearchPlaceHolder: "units.screen.searchPlaceHolder",
    AddNewUnit: "units.screen.addNewUnit",
    OccupancyStatus: "units.screen.occupancyStatus",
    UnitStatus: "units.screen.unitStatus",
    EmptydataViewInstructionsText: "units.screen.emptydataViewInstructionsText",
    DeleteConfirmationText: "units.screen.deleteConfirmationText",
    DeleteUnitLoadingText: "units.screen.deleteUnitLoadingText",
    DeleteUnitSuccessText: "units.screen.deleteUnitSuccessText",
    ActiveUnits: "units.screen.activeUnits",
    MakeInactiveFileConfirmationText: "units.screen.makeInactiveFileConfirmationText",
    MakeActiveFileConfirmationText: "units.screen.makeActiveFileConfirmationText"
  },
  FilterValues: {
    Listed: "units.filterValues.listed",
    NotListed: "units.filterValues.notListed",
    Occupied: "units.filterValues.occupied",
    Available: "units.filterValues.available",
    InEviction: "units.filterValues.inEviction",
    InContract: "units.filterValues.inContract",
    Vacant: "units.filterValues.vacant"
  },
  ListItem: {
    Beds: "units.listItem.beds",
    Bed: "units.listItem.bed",
    Bath: "units.listItem.bath",
    Baths: "units.listItem.baths",
    Sqft: "units.listItem.sqft",
    BedsAndBaths: "units.listItem.bedsAndBaths"
  },
  RentRollUnitStatus: {
    PastDue: "units.rentRollUnitStatus.pastDue",
    Current: "units.rentRollUnitStatus.current"
  },
  ListHeader: {
    Unit: "units.listHeader.unit",
    Features: "units.listHeader.features",
    Listing: "units.listHeader.listing",
    Status: "units.listHeader.status"
  },
  NewUnitDialog: {
    Title: "units.newUnitDialog.title",
    SubTitle: "units.newUnitDialog.subTitle",
    ChooseProperty: "units.newUnitDialog.chooseProperty",
    Property: "units.newUnitDialog.property",
    UnitName: "units.newUnitDialog.unitName",
    MarketRent: "units.newUnitDialog.marketRent",
    Size: "units.newUnitDialog.size",
    Beds: "units.newUnitDialog.beds",
    BathRooms: "units.newUnitDialog.bathRooms",
    SaveUnit: "units.newUnitDialog.saveUnit",
    SaveAndAdd: "units.newUnitDialog.saveAndAdd",
    LoadingText: "units.newUnitDialog.loadingText",
    ErrorText: "units.newUnitDialog.errorText",
    SuccessText: "units.newUnitDialog.successText",
    LoadingUnitInfo: "units.newUnitDialog.loadingUnitInfo",
    InfoLoaded: "units.newUnitDialog.infoLoaded",
    EditTitle: "units.newUnitDialog.editTitle"
  }
};

export default Units;
