import type { GetAllPropertiesQuery, PropertyDto } from "@doorloop/dto";
import { OwnerPortalServerRoutes } from "@doorloop/dto";

import { RestApiBaseWithDictionary } from "api/restApiBaseWithDictionary";

export class OwnerPortalPropertiesApi extends RestApiBaseWithDictionary<PropertyDto, GetAllPropertiesQuery> {
  constructor() {
    super(OwnerPortalServerRoutes.PROPERTIES);
    this.restRoute = OwnerPortalServerRoutes.PROPERTIES;
  }
}

export const ownerPortalPropertiesApi = new OwnerPortalPropertiesApi();
