"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseTransactionLineBaseDto = void 0;
const baseTransactionLine_dto_1 = require("./baseTransactionLine.dto");
class LeaseTransactionLineBaseDto extends baseTransactionLine_dto_1.BaseTransactionLineDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.LeaseTransactionLineBaseDto = LeaseTransactionLineBaseDto;
