import type { MutableRefObject } from "react";
import { isWebView } from "common/native/utils";

export const MobileDeviceBridgeMessages = {
  Register: "register",
  Unregister: "unregister",
  OnDeviceEnterForeground: "onDeviceEnterForeground",
  NavigateDeepLink: "navigateDeepLink",
  GetAppBuildNumber: "getAppBuildNumber",
  DidReceiveBuildNumber: "didReceiveBuildNumber"
} as const;

const bridgeName = "mobileBridge";

const AvoidReloadPaths: string[] = [
  "payment-wizard",
  "auth",
  "internal-task",
  "owner-request",
  "tenant-request",
  "work-order",
  "notes",
  "files",
  "new-lease",
  "new-request",
  "requests/edit",
  "new-insurance-policy",
  "edit/insurance-policy",
  "tenant-portal/rental-application",
  "conversation-task"
];

const AvoidNavigationPaths: string[] = ["payment-wizard", "oauth-redirect"];

const shouldReloadPageForCurrentPath = (): boolean => {
  if (!isWebView()) return false;

  const { pathname } = window.location;
  if (pathname.includes("/new") || pathname.includes("/edit")) return false;
  if (AvoidReloadPaths.some((path) => pathname.includes(path))) return false;

  return true;
};

export const handleMobileNavigation = (urlParam?: string, isAddSchemeURL = false) => {
  const url = new URL(urlParam || location.href);
  const nextHopURL = `${isAddSchemeURL ? "doorloop://" : ""}${url.href}`;

  window.open(nextHopURL, "_self");
};

export const devicesMethods = {
  [MobileDeviceBridgeMessages.Register]: {
    ios: (data?) => {
      window?.["webkit"]?.messageHandlers?.[bridgeName]?.postMessage(
        JSON.stringify({
          message: MobileDeviceBridgeMessages.Register,
          data
        })
      );
    },
    android: (data?) => {
      window?.["Android"]?.postMessage(MobileDeviceBridgeMessages.Register, JSON.stringify(data));
    }
  },
  [MobileDeviceBridgeMessages.Unregister]: {
    ios: () => {
      window?.["webkit"]?.messageHandlers?.[bridgeName]?.postMessage(
        JSON.stringify({
          message: MobileDeviceBridgeMessages.Unregister
        })
      );
    },
    android: () => {
      window?.["Android"]?.postMessage(MobileDeviceBridgeMessages.Unregister);
    }
  },
  [MobileDeviceBridgeMessages.OnDeviceEnterForeground]: () => {
    window[MobileDeviceBridgeMessages.OnDeviceEnterForeground] = () => {
      if (shouldReloadPageForCurrentPath()) {
        window.location.reload();
      }
    };
  },
  [MobileDeviceBridgeMessages.DidReceiveBuildNumber]: (currentBuildNumber: MutableRefObject<number | undefined>) => {
    window[MobileDeviceBridgeMessages.DidReceiveBuildNumber] = (buildNumber: string) => {
      currentBuildNumber.current = Number(buildNumber);
    };
  },
  [MobileDeviceBridgeMessages.GetAppBuildNumber]: () => {
    window?.["webkit"]?.messageHandlers?.[bridgeName]?.postMessage(
      JSON.stringify({
        message: MobileDeviceBridgeMessages.GetAppBuildNumber
      })
    );

    window?.["Android"]?.postMessage(MobileDeviceBridgeMessages.GetAppBuildNumber, {});
  },
  [MobileDeviceBridgeMessages.NavigateDeepLink]: () => {
    window[MobileDeviceBridgeMessages.NavigateDeepLink] = (urlParam, isAddSchemeURL = false) => {
      const avoidNavigation = AvoidNavigationPaths.some((path) => urlParam.includes(path));
      if (urlParam && !avoidNavigation) {
        handleMobileNavigation(urlParam, isAddSchemeURL);
      }
    };
  }
};
