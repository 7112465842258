import { View } from "DLUI/view";
import { Icon } from "DLUI/icon";
import Text from "DLUI/text";
import React, { useState } from "react";
import type { BaseDto, TenantDto } from "@doorloop/dto";
import { getIn } from "formik";
import ErrorDialog from "screens/managementFees/errorDialog";
import type { ApiResult } from "api/apiResult";
import type { EditableTextFieldProps } from "DLUI/form/editableInput/editableTextField";
import EditableTextField from "DLUI/form/editableInput/editableTextField";
import { Lottie } from "DLUI/lottie";
import spinnerAnimation from "../../../assets/lottie/spinner.json";

interface EditableContactInfoProps<T> extends Omit<EditableTextFieldProps<T>, "renderChildren"> {
  addValueText: string;
  isMobilePhone?: boolean;
  domRef?: React.RefObject<HTMLElement | null>;
}

const EditableContactInfo = <T extends BaseDto>({
  type = "text",
  onError,
  formikName,
  disableEdit,
  icon,
  addValueText,
  isMobilePhone,
  domRef,
  ...rest
}: EditableContactInfoProps<T>) => {
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = useState("Error");

  const handleError = (result: ApiResult<TenantDto>) => {
    setShowErrorDialog(true);
    setDialogErrorMessage(result.message);

    onError?.(result as ApiResult<T & BaseDto>);
  };

  return (
    <>
      <EditableTextField
        icon={icon}
        formikName={formikName}
        disableEdit={disableEdit}
        onError={handleError}
        isMobilePhone={isMobilePhone}
        {...rest}
        renderChildren={(dto, isLoading: boolean) => {
          const currentValue = getIn(dto, formikName);

          return (
            (currentValue || !disableEdit) && (
              <View width={"auto"} flexDirection={"row"} alignItems={"center"} noWrap gap={6}>
                <Icon
                  width={13}
                  height={13}
                  Source={icon}
                  pathColor={getIn(dto, formikName) ? "black" : "light-blue"}
                />
                <Text
                  noWrap
                  numberOfLines={1}
                  overFlow={"ellipsis"}
                  color={getIn(dto, formikName) ? "black" : "lightBlue"}
                  fontSize={14}
                  domRef={domRef}
                >
                  {currentValue ?? addValueText}
                </Text>
                {isLoading && (
                  <Lottie
                    pathColor={"blue"}
                    autoPlay={true}
                    loop={true}
                    animationData={spinnerAnimation}
                    width={20}
                    height={20}
                  />
                )}
              </View>
            )
          );
        }}
      />
      {showErrorDialog && <ErrorDialog onCLose={() => setShowErrorDialog(false)} errorMessage={dialogErrorMessage} />}
    </>
  );
};

export default EditableContactInfo;
