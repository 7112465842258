import React, { useEffect, useState } from "react";
import { GridColumnMenuItem, GridColumnMenuItemContent, GridColumnMenuItemGroup } from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";
import type { GridColumn, VisibleGridColumn } from "DLUI/lists/gridList/gridList";
import "./columnsMenu.css";
import { ControlledCheckBox } from "DLUI/form/checkBox/base/controlledCheckBox";
import { getColumnComparer } from "./utils";

interface ColumnsMenuProps {
  visibleColumns: VisibleGridColumn[];
  defaultColumns: GridColumn[];
  [propName: string]: any | undefined;
  onColumnsSubmit: (nextColumns: GridColumn[]) => void;
  onColumnsReset: () => void;
}

const ColumnsMenu = ({
  visibleColumns,
  defaultColumns,
  onColumnsSubmit,
  onColumnsReset,
  ...rest
}: ColumnsMenuProps) => {
  const { t } = useTranslation();
  const [gridColumns, setGridColumns] = useState<GridColumn[]>(defaultColumns);
  const [filterExpanded, setFilterExpanded] = useState(true);

  useEffect(() => {
    setGridColumns(
      defaultColumns.map((defaultColumn) => {
        return {
          ...defaultColumn,
          show: Boolean(visibleColumns.find(getColumnComparer(defaultColumn.field)))
        };
      })
    );
  }, []);

  const { onCloseMenu } = rest;
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }

    onColumnsSubmit(gridColumns);

    onCloseMenu?.();
  };

  const onReset = (event: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }

    onColumnsReset();

    onCloseMenu?.();
  };

  const onToggleColumn = (id: number) => {
    setGridColumns(gridColumns.map((column, idx) => (idx === id ? { ...column, show: !column.show } : column)));
  };

  const onMenuItemClick = () => {
    setFilterExpanded(!filterExpanded);
  };

  const oneVisibleColumn = gridColumns.filter((c) => c.show).length === 1;
  return (
    <div>
      <GridColumnMenuItemGroup>
        <GridColumnMenuItem title={"Columns"} iconClass={"k-i-columns"} onClick={onMenuItemClick} />
        <GridColumnMenuItemContent show={filterExpanded}>
          <div className={"k-column-list-wrapper"}>
            <form onSubmit={onSubmit} onReset={onReset}>
              <div className={"k-column-list"}>
                {gridColumns.map((column, idx) => (
                  <div key={idx} className={"k-column-list-item"}>
                    <span>
                      <ControlledCheckBox
                        id={`column-visiblity-show-${idx}`}
                        checked={column.show}
                        onChange={() => {
                          onToggleColumn(idx);
                        }}
                        disabled={column.show && oneVisibleColumn}
                        labelValueProps={{
                          text: t(column.title)
                        }}
                      />
                    </span>
                  </div>
                ))}
              </div>
              <div className={"k-columnmenu-actions"}>
                <button type={"reset"} className={"k-button"}>
                  Reset
                </button>
                <button className={"k-button k-primary"}>Save</button>
              </div>
            </form>
          </div>
        </GridColumnMenuItemContent>
      </GridColumnMenuItemGroup>
    </div>
  );
};

export default ColumnsMenu;
