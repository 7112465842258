import { useState } from "react";

type HookInterface = () => {
  shouldRender: boolean;
  triggerRemount: () => void;
};

export const useRemount: HookInterface = () => {
  const [shouldRender, setShouldRender] = useState<boolean>(true);

  const triggerRemount = () => {
    setShouldRender(false);
    setTimeout(() => {
      setShouldRender(true);
    }, 0);
  };

  return {
    shouldRender,
    triggerRemount
  };
};
