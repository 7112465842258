/* eslint-disable @typescript-eslint/no-use-before-define */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import NewRentalApplicationDialog from "./newRentalApplicationDialog";

import { getDialogFrameDimension } from "@/components/DLUI/dialogs/components/dialogFrame";
import { CreateOrUpdateRentalApplicationDto, createValidator } from "@doorloop/dto";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { DialogRoutes } from "DLUI/screen/dialogsProvider";
import { View } from "DLUI/view";
import { rentalApplicationsApi } from "api/rentalApplicationsApi";
import { unitsApi } from "api/unitsApi";
import { FormikProvider, useFormik } from "formik";
import { noop } from "lodash";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";

import type { UnitDto } from "@doorloop/dto";
import { handleApiResult } from "@/api/entityApiStore/entityApiStore.utils";
import type { NewRentalApplicationRouteParams } from "./newRentalApplicationFormikContextWrapper.types";

const validateForm = createValidator(CreateOrUpdateRentalApplicationDto);

const dialogFrameWidth = getDialogFrameDimension("width", 1100);
const dialogFrameHeight = getDialogFrameDimension("height", 800);

export interface NewRentalApplicationFormikContextWrapperProps {
  onBackdropClick: () => void;
  onClose: () => void;
}

const NewRentalApplicationFormikContextWrapper: React.FC<NewRentalApplicationFormikContextWrapperProps> = ({
  onBackdropClick,
  onClose
}: NewRentalApplicationFormikContextWrapperProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const routeParams = useParams<NewRentalApplicationRouteParams>();

  const applicationId = routeParams.applicationId;
  const prospectId = routeParams.prospectId;
  const isEditMode = applicationId && location.pathname.indexOf(DialogRoutes.GLOBAL_NEW) === -1;

  const [rentalApplicationDto, setRentalApplicationDto] = useState<CreateOrUpdateRentalApplicationDto>();
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    isEditMode ? DialogState.Show : DialogState.Hidden
  );
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState("");

  const showErrorView = useCallback(
    (message: string = t("common.generalError")) => {
      setLoadingDialogState(DialogState.Error);
      setLoadingDialogErrorText(message);
    },
    [t]
  );

  const fetchRentalApplication = useCallback(async () => {
    if (!applicationId) {
      return;
    }

    setLoadingDialogState(DialogState.Show);

    const [error, response, { isEmptyResponse }] = await rentalApplicationsApi.get(applicationId).then(handleApiResult);

    if (error || isEmptyResponse) {
      showErrorView(response.message);
      return;
    }

    setRentalApplicationDto(response.data);
    setLoadingDialogState(DialogState.Hidden);
  }, [applicationId, showErrorView]);

  useEffect(() => {
    if (isEditMode) {
      fetchRentalApplication();
    }
  }, [isEditMode, fetchRentalApplication]);

  const getInitialPropertyId = useCallback(() => {
    let propertyId: UnitDto["property"];

    const relatedUnitId = routeParams.unitId;

    if (relatedUnitId) {
      const unitDto: UnitDto = unitsApi.getItemFromDictionary(relatedUnitId);
      propertyId = unitDto.property;
    } else {
      propertyId = routeParams.propertyId;
    }

    return propertyId;
  }, [routeParams.propertyId, routeParams.unitId]);

  const initialValues = useMemo(() => {
    if (rentalApplicationDto) {
      return rentalApplicationDto;
    }

    const mutableRentalApplicationDto = new CreateOrUpdateRentalApplicationDto({
      applicationGroupId: uuid().split("-")[0]
    });

    if (prospectId) {
      mutableRentalApplicationDto.tenant = prospectId;
    }

    const propertyId = getInitialPropertyId();

    if (propertyId) {
      mutableRentalApplicationDto.property = propertyId;
    }

    return mutableRentalApplicationDto;
  }, [getInitialPropertyId, prospectId, rentalApplicationDto]);

  const formik = useFormik({
    initialValues,
    onSubmit: noop,
    validate: validateForm,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false
  });

  if (loadingDialogState !== DialogState.Hidden) {
    return (
      <View width={dialogFrameWidth} height={dialogFrameHeight} alignItems={"center"} justifyContent={"center"}>
        <LoadingDialog
          dialogState={loadingDialogState}
          errorText={loadingDialogErrorText}
          didPressDismissButton={onBackdropClick}
          onRetryButtonPress={fetchRentalApplication}
          minHeight={dialogFrameHeight}
        />
      </View>
    );
  }

  return (
    <FormikProvider value={formik}>
      <NewRentalApplicationDialog onClose={onClose} onBackdropClick={onBackdropClick} />
    </FormikProvider>
  );
};

export default NewRentalApplicationFormikContextWrapper;
