import { Select, TextField } from "DLUI/form";
import type { DeepKeys, LateFeeChargeDto } from "@doorloop/dto";
import { DataCy, DefaultAccountType, LateFeePolicyFeeType, LateFeePolicyFrequency } from "@doorloop/dto";
import { LateFeesAmountField } from "DLUI/dialogs/settings/common/lateFees/lateFeesAmountField";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import { useGeneralStyles } from "styles/generalStyles";
import clsx from "clsx";
import type { FieldArrayRenderProps } from "formik";
import { FastField, getIn, useFormikContext } from "formik";
import { useTypedTranslation } from "locale";
import type { SelectInputProps } from "DLUI/form/select/selectInput";
import { useStyles } from "../settings/styles";
import { useResponsiveHelper } from "@/contexts/responsiveContext";
import Text from "DLUI/text";
import { useLateFeeSettings } from "DLUI/dialogs/settings/common/lateFees/useLateFeeSettings";
import { useMemo } from "react";
import type { LateFeesFormTypes, LateFeesPrefixes } from "DLUI/dialogs/settings/common/lateFees/types";

export interface LateFeeChargeItemFormProps {
  index: number;
  arrayHelpers: FieldArrayRenderProps;
  formikPathName?: DeepKeys<LateFeesPrefixes>;
}

export const LateFeeChargeItemForm = ({ formikPathName, index }: LateFeeChargeItemFormProps) => {
  const generalClasses = useGeneralStyles();
  const settingsClasses = useStyles();
  const { t } = useTypedTranslation();
  const formikRef = useFormikContext<LateFeesFormTypes>();
  const { isDesktop } = useResponsiveHelper();
  const { getFieldPath } = useLateFeeSettings({ formikPathName });
  const lateFeeChargePrefix: DeepKeys<LateFeesFormTypes> = getFieldPath("lateFeeCharges");
  const chargesPathWithIndex = `${lateFeeChargePrefix}[${index}]`;

  const accountFieldName = `${chargesPathWithIndex}.account`;
  const accountDefaultValue = useMemo(
    () => getIn(formikRef.values, accountFieldName),
    [formikRef.values, accountFieldName]
  );
  const rule: LateFeeChargeDto | undefined = useMemo(
    () => getIn(formikRef.values, chargesPathWithIndex),
    [formikRef.values, chargesPathWithIndex]
  );

  const onTransformFeeType: SelectInputProps["onTransform"] = (items) =>
    items.map(({ value, label }) => {
      return { label, value: LateFeePolicyFeeType[value] };
    });

  return (
    <div className={clsx(generalClasses.flexColumn, generalClasses.largeGap)}>
      <div className={settingsClasses.feeItemForm}>
        <FastField
          component={Select}
          name={`${chargesPathWithIndex}.feeType`}
          uniqueKey={`${chargesPathWithIndex}.feeType`}
          label={t("common.settings.lateFees.lateFeePolicyFeeTypeLabel")}
          selectionEnum={LateFeePolicyFeeType}
          onTransform={onTransformFeeType}
          translationKey={"lateFeePolicyFeeType"}
          dataCy={DataCy.globalSettings.lateFees.rules.rule.feeTypeSelect}
          required
        />
        {isDesktop ? (
          <>
            <LateFeesAmountField feeType={rule?.feeType} fieldNamePrefix={chargesPathWithIndex} />
            <FastField
              translationKey={"lateFeePolicyFrequency"}
              component={Select}
              name={`${chargesPathWithIndex}.frequency`}
              uniqueKey={`${chargesPathWithIndex}.frequency`}
              label={t("leases.newLease.leaseRent.frequency")}
              selectionEnum={LateFeePolicyFrequency}
              dataCy={DataCy.globalSettings.lateFees.rules.rule.frequency}
              required
            />
          </>
        ) : (
          <div className={settingsClasses.amountAndFrequencyMobileContainer}>
            <Text value={t("lateFee.selectFeeAndFrequency")} fontSize={16} />
            <div className={settingsClasses.amountAndFrequencyFields}>
              <div className={settingsClasses.amountField}>
                <LateFeesAmountField feeType={rule?.feeType} fieldNamePrefix={chargesPathWithIndex} />
              </div>
              <div className={generalClasses.basicFlex}>
                <FastField
                  translationKey={"lateFeePolicyFrequency"}
                  component={Select}
                  name={`${chargesPathWithIndex}.frequency`}
                  uniqueKey={`${chargesPathWithIndex}.frequency`}
                  label={t("leases.newLease.leaseRent.frequency")}
                  selectionEnum={LateFeePolicyFrequency}
                  dataCy={DataCy.globalSettings.lateFees.rules.rule.frequency}
                  required
                />
              </div>
            </div>
          </div>
        )}

        <FastField
          component={TextField}
          label={t("lateFee.gracePeriod")}
          name={`${chargesPathWithIndex}.daysAfterDueDateToChargeLateFees`}
          dataCy={DataCy.globalSettings.lateFees.rules.rule.gracePeriod}
          formatType={"number"}
        />
      </div>
      <BankAccountFormikAutoCompleteField
        addCreateOption
        label={t("common.revenueAccount")}
        uniqueIndex={"lateFeeOptionsAccount"}
        queryParams={{ filter_leaseChargeItem: true }}
        name={accountFieldName}
        defaultValue={accountDefaultValue}
        selectionFields={["defaultAccountFor"]}
        avoidDefaultsAccountsTypes={[DefaultAccountType.RENTAL_INCOME]}
        dataCy={DataCy.globalSettings.lateFees.rules.rule.revenueAccount}
      />
      <FastField
        component={TextField}
        label={t("common.listHeader.description")}
        name={`${chargesPathWithIndex}.memo`}
        maxLength={80}
        shouldDebounce
        dataCy={DataCy.globalSettings.lateFees.rules.rule.description}
      />
    </div>
  );
};
