import type { FC } from "react";
import React, { useCallback, useMemo } from "react";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import type { PropertyDto } from "@doorloop/dto";
import { DataCy, ObjectPermission, PropertyPetPolicyDto, PropertyPetPolicyOptionsDto } from "@doorloop/dto";
import { View } from "DLUI/view";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import Text from "DLUI/text";
import { FastField, useFormikContext } from "formik";
import type { ImageButtonGroupProps } from "DLUI/form";
import { FormikImageButtonGroup, TextField } from "DLUI/form";
import { NoPetsIcon, PetsDrawingIcon } from "assets/icons";
import { useTranslation } from "react-i18next";
import { reduceArrayToObjectKeys } from "utils/dataStructureUtils";
import AppStrings from "locale/keys";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { ControlledCheckBox } from "DLUI/form/checkBox/base/controlledCheckBox";

enum Allowed {
  YES = "YES",
  NO = "NO"
}

const buttonItemsArray: (allowed: boolean) => ImageButtonGroupProps[] = (allowed) => [
  {
    label: AppStrings.Listing.PetsAllowed,
    icon: PetsDrawingIcon,
    value: Allowed.YES,
    isSelected: allowed,
    dataCy: DataCy.propertyDetails.settings.editPropertyPetPolicy.petsAllowed
  },
  {
    label: AppStrings.Listing.PetsNotAllowed,
    icon: NoPetsIcon,
    value: Allowed.NO,
    isSelected: !allowed,
    dataCy: DataCy.propertyDetails.settings.editPropertyPetPolicy.petsNotAllowed
  }
];

const animalGroupsLabelMap: Record<keyof PropertyPetPolicyDto, string> = {
  largeDogs: AppStrings.Listing.Dogs,
  smallDogs: AppStrings.Listing.SmallDogs,
  cats: AppStrings.Listing.Cats
};

const animalGroups: Array<keyof PropertyPetPolicyDto> = ["smallDogs", "largeDogs", "cats"];

interface Props {}

export const EditPropertyPetPolicy: FC<Props> = ({}: Props) => {
  const { t } = useTranslation();
  const { dialogHorizontalPadding } = DialogsHelper();
  const formikRef = useFormikContext<PropertyDto>();
  const petPolicy = formikRef.values.petsPolicy;
  const imageGroupItems = useMemo(() => buttonItemsArray(petPolicy !== undefined), [petPolicy]);

  const toggleAnimalGroup = useCallback(
    (group: keyof PropertyPetPolicyDto, allowed: boolean) => {
      if (petPolicy) {
        const currentGroup = petPolicy[group];
        if (currentGroup) {
          currentGroup.allowed = allowed;
        }
        formikRef.setFieldValue(`petsPolicy.${group}`, currentGroup);
      }
    },
    [petPolicy, formikRef]
  );

  const applyDescription = useCallback(
    (restrictionsText: string) => {
      if (petPolicy) {
        animalGroups.forEach((group) => {
          const currentGroup = petPolicy[group];
          if (currentGroup) {
            currentGroup.restrictions = restrictionsText;
          }
        });
      }
      formikRef.setFieldValue(`petsPolicy`, petPolicy);
    },
    [petPolicy, formikRef]
  );

  const initBaseObject = useCallback(
    (allowed: Allowed) => {
      formikRef.setFieldValue(
        "petsPolicy",
        allowed === Allowed.YES
          ? new PropertyPetPolicyDto(reduceArrayToObjectKeys(animalGroups, () => new PropertyPetPolicyOptionsDto()))
          : undefined
      );
    },
    [formikRef]
  );

  return (
    <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding} flex={1}>
      <RestrictedPermissionAccess
        clearance={{
          permission: ObjectPermission.properties,
          field: "edit"
        }}
        showNoAccess
      >
        <View>
          <View>
            <Text fontSize={16} value={AppStrings.Listing.PetsPolicyDescription} />
          </View>
          <HorizontalSeparationLine marginTop={20} />
          <View>
            <FastField
              component={FormikImageButtonGroup}
              buttonItemsArray={imageGroupItems}
              flexDirection={"flex-start"}
              onSelect={initBaseObject}
              size={120}
              noWrap
              dataCy
            />
          </View>
          {petPolicy !== undefined && (
            <View>
              <View marginTop={20}>
                <Text fontSize={20} bold value={AppStrings.Listing.PetsDetails} />
              </View>
              <View marginTop={20}>
                <Text fontSize={16} value={AppStrings.Listing.SelectWhichPetsLabel} />
              </View>
              <View marginTop={20} flexDirection={"row"}>
                {animalGroups.map((group, index) => (
                  <View flex={1} key={index}>
                    <ControlledCheckBox
                      checked={Boolean(petPolicy[group]?.allowed)}
                      onChange={(checked) => toggleAnimalGroup(group, checked)}
                      labelValueProps={{
                        text: animalGroupsLabelMap[group]
                      }}
                    />
                  </View>
                ))}
              </View>
              <View marginTop={20}>
                <FastField
                  component={TextField}
                  name={"petsPolicy.smallDogs.restrictions"}
                  label={t(AppStrings.Listing.PetRestrictionsLabel)}
                  onChange={applyDescription}
                  variant="outlined"
                  rowsMax={6}
                  rows={6}
                  multiline
                  maxLength={500}
                />
              </View>
            </View>
          )}
        </View>
      </RestrictedPermissionAccess>
    </View>
  );
};
