import React, { createContext, useContext, useMemo, useState } from "react";
import DownloadMobileVersionsBanner from "../components/layouts/downloadMobileVersions/downloadMobileVersionsBanner";
import { Routes } from "../components/appRouter";
import _ from "lodash";
import { useLocation } from "react-router-dom";

interface NavigationProps {
  currentLayout: AppLayouts;
  setCurrentLayout: (newLayout: AppLayouts) => void;
}

export enum AppLayouts {
  Sidebar = "SideBarLayout",
  FullWidth = "FullWidthLayout"
}

const routeToDefaultLayoutMap: Partial<
  Record<
    Routes,
    | AppLayouts
    | {
        layout: AppLayouts;
        isPrefix?: boolean;
      }
  >
> = {
  [Routes.TENANT_PORTAL_RENTAL_APPLICATION]: AppLayouts.FullWidth,
  [Routes.TENANT_PORTAL_RENTAL_APPLICATION_AUTH]: AppLayouts.FullWidth,
  [Routes.LOGIN]: AppLayouts.FullWidth,
  [Routes.FORGOT_PASSWORD_CONFIRM]: AppLayouts.FullWidth,
  [Routes.ACCEPT_INVITE_NEW_LOGIN]: AppLayouts.FullWidth,
  [Routes.ACCEPT_INVITE_EXISTING_LOGIN]: AppLayouts.FullWidth,
  [Routes.ACCEPT_INVITE_TOKEN]: AppLayouts.FullWidth,
  [Routes.ACCEPT_INVITE]: AppLayouts.FullWidth,
  [Routes.FORGOT_PASSWORD_SENT]: AppLayouts.FullWidth,
  [Routes.FORGOT_PASSWORD]: AppLayouts.FullWidth,
  [Routes.SELECT_ACCOUNT]: AppLayouts.FullWidth,
  [Routes.SIGNUP_THANKS]: AppLayouts.FullWidth,
  [Routes.DEMO_ADDITIONAL_INFO]: AppLayouts.FullWidth,
  [Routes.DEMO_SCHEDULE]: AppLayouts.FullWidth,
  [Routes.DEMO_SIGNUP]: AppLayouts.FullWidth,
  [Routes.TENANT_PORTAL_LISTING_RENTAL_APPLICATION]: AppLayouts.FullWidth,
  [Routes.LISTINGS]: AppLayouts.FullWidth,
  [Routes.SIGNUP_EMAIL]: AppLayouts.FullWidth,
  [Routes.SIGNUP_CONFIRM]: AppLayouts.FullWidth,
  [Routes.SIGNUP]: AppLayouts.FullWidth,
  [Routes.WELCOME_WIZARD_STEP1]: AppLayouts.FullWidth,
  [Routes.WELCOME_WIZARD_STEP2]: AppLayouts.FullWidth,
  [Routes.WELCOME_WIZARD_STEP3]: AppLayouts.FullWidth,
  [Routes.WELCOME_WIZARD_STEP4]: AppLayouts.FullWidth,
  [Routes.OAUTH_REDIRECT]: AppLayouts.FullWidth,
  [Routes.OWNER_PORTAL_BASE_URL]: { layout: AppLayouts.FullWidth, isPrefix: true },
  [Routes.TENANT_PORTAL_BASE_URL]: { layout: AppLayouts.FullWidth, isPrefix: true }
};
const DEFAULT_LAYOUT = AppLayouts.Sidebar;

const AppLayoutContext = createContext<NavigationProps>({
  currentLayout: DEFAULT_LAYOUT,
  setCurrentLayout: () => {}
});

export const AppLayoutContextProvider = ({ children }) => {
  const location = useLocation();

  const layout = useMemo(() => {
    const fullMatch = routeToDefaultLayoutMap[location.pathname];

    if (fullMatch) {
      return _.isString(fullMatch) ? fullMatch : fullMatch.layout;
    }
    for (const routeToDefaultLayoutMapKey in routeToDefaultLayoutMap) {
      const defaultRouteSettings = routeToDefaultLayoutMap[routeToDefaultLayoutMapKey];

      if (defaultRouteSettings.isPrefix && location.pathname.startsWith(routeToDefaultLayoutMapKey)) {
        return _.isString(defaultRouteSettings) ? defaultRouteSettings : defaultRouteSettings.layout;
      }
    }

    return DEFAULT_LAYOUT;
  }, [location.pathname]);

  const [currentLayout, setCurrentLayout] = useState(layout);

  const handleLayoutChange = (newLayout: AppLayouts) => {
    if (newLayout !== currentLayout) {
      setCurrentLayout(newLayout);
    }
  };

  return (
    <AppLayoutContext.Provider value={{ currentLayout, setCurrentLayout: handleLayoutChange }}>
      <DownloadMobileVersionsBanner />
      {children}
    </AppLayoutContext.Provider>
  );
};

export const useNavigationContext = () => useContext(AppLayoutContext);
