import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import makeStyles from "../../styles";
import DLButton from "DLUI/button/dlButton";

interface Props {
  errorMessage?: string;
  fetchData: () => Promise<void>;
}

const NetworkError = ({ errorMessage, fetchData }: Props) => {
  const classes = makeStyles();

  return (
    <View justifyContent={"center"} alignItems={"center"} flexDirection={"row"} noWrap>
      <div className={classes.networkErrorContainer}>
        <div className={classes.networkErrorTextWrapper}>
          <Text variant={"title"} color={"black"} value={AppStrings.Common.NetworkErrorTitle} align={"center"} />
          <Text
            variant={"subTitle"}
            color={"black"}
            value={errorMessage || AppStrings.Common.NetworkErrorSubTitle}
            marginTop={20}
            align={"center"}
            marginBottom={20}
          />
        </div>
        <DLButton onClick={fetchData} actionText={AppStrings.Common.Retry} />
      </div>
    </View>
  );
};

export default NetworkError;
