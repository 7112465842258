import type {
  BudgetDto,
  GetAllBaseQueryResponse,
  GetAllBudgetsQuery,
  GetBudgetEmptyReport,
  ReportResponseBase,
  TreeListItemAccount
} from "@doorloop/dto";
import { ServerRoutes } from "@doorloop/dto";

import { RestApiBase } from "api/restApiBase";
import { apiHelper } from "api/apiHelper";
import { propertiesApi } from "api/propertiesApi";
import { propertyGroupsApi } from "api/propertyGroupsApi";

class BudgetsApi extends RestApiBase<BudgetDto, GetAllBudgetsQuery> {
  constructor() {
    super(ServerRoutes.BUDGETS, [propertiesApi, propertyGroupsApi]);
  }

  async getBudgetEmptyReport(query: GetBudgetEmptyReport) {
    await this.loadDictionariesRequiredForGet();
    return await apiHelper.axiosGet<GetAllBaseQueryResponse<ReportResponseBase<TreeListItemAccount>>>(
      ServerRoutes.BUDGET_EMPTY_REPORT + "/?",
      query
    );
  }
}

const budgetsApi = new BudgetsApi();

export { BudgetsApi, budgetsApi };
