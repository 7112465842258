import type { SVGIconComponent } from "assets/icons";
import { FlagLightBlueOutlineIcon, InfoOutlineIcon, PlayCircleOutlineIcon } from "assets/icons";

export enum ArticleIdsEnum {
  PAYMENT_IN_PORTAL = "7985010",
  INSTANT_BANK_VERIFICATION = "8880826",
  MANUAL_BANK_VERIFICATION = "8103002",
  END_A_LEASE = "6067246",
  CUSTOM_ALLOCATIONS = "6931003",
  PAYMENT_ALLOCATIONS = "6821737",
  ADD_A_LEASE = "6115751",
  EDIT_A_LEASE = "6326017",
  SIGN_A_LEASE = "6065723",
  RENEW_A_LEASE = "6325197",
  LEASE_END_OF_TERM = "6326803",
  MOVE_IN_TENANT = "6187408",
  FIND_TENANTS = "6187715",
  CALENDAR_HOW_TO_USE = "8613058",
  CALENDAR_HOW_TO_SUBSCRIBE = "8646191",
  CALENDAR_OCCUPANCY_OVERVIEW_HOW_TO_USE = "8646132",
  PRINT_CHECKS = "6076148",
  WORKING_WITH_NOTES = "6307960",
  WORKING_WITH_FILES = "6308361",
  PROPERTY_FUNDS_INSUFFICIENT = "6350195",
  CHART_OF_ACCOUNTS = "6170414",
  COMMUNICATIONS = "6193605",
  REQUEST_ESIGNATURE = "6065723",
  SINGLE_TENANT_MESSAGE = "6229675",
  CREATE_ESIGNATURE_TEMPLATES = "6065556",
  MERCHANT_ACCOUNT_APPLICATION = "7932446",
  TRACK_PORTAL_INVITES = "6194271",
  CREATE_MANAGEMENT_FEES_BILLS = "6156495",
  REDO_MANAGEMENT_FEES_BILLS = "6355443",
  MERCHANT_ACCOUNT_PAYMENTS = "6222287",
  PAYMENT_DURATION = "6222125",
  CREATE_BANK_ACCOUNT = "6106500",
  PROPERTY_CONNECT = "6088168",
  PLAID_BANK_RECONCILIATION = "6076958",
  DIFFERENCE_RECONCILIATION_PLAID = "6273146",
  LEASE_RECEIVE_PAY = "6162667",
  LEASE_GIVE_REFUND = "6184359",
  EDIT_DELETE_LEASE_TRANSACTIONS = "6176454",
  POSTING_A_CHARGE = "6162147",
  CREATING_TASKS = "6093760",
  EDITING_TASKS = "6098083",
  CREATE_WORK_ORDER = "6322778",
  LATE_FEES = "6191191",
  CREATE_AN_EXPENSE = "6076135",
  CREATE_A_BILL = "6226354",
  LISTING_SYNC = "6066925",
  QUICKBOOKS_INTEGRATION = "6076532",
  CHOOSE_ACCOUNTING_START_DATE = "6118570",
  SETTING_OPENING_BALANCES = "6206586",
  INCREASE_ONLINE_PAYMENT_LIMIT = "6221632",
  MERCHANT_ACCOUNT_PAYOUT_DETAILS = "6087527",
  ISSUE_LEASE_CREDIT = "6169805",
  REQUESTING_ESIGNATURE = "6065723",
  RECONCILIATION_SUPPORT = "6247012",
  RECONCILIATION_ISSUES = "6278227",
  MANAGEMENT_FEES = "6156495",
  ANNOUNCEMENTS_DOCS = "6075940",
  OWNER_DISTRIBUTION = "6313723",
  CREATE_OWNER = "6311472",
  EDIT_OWNER = "6746388",
  ADDING_PROPERTY_OWNERS = "6313576",
  LEARN_MORE_ABOUT_CHECKBOOK = "6482894",
  USING_ZAPIER_WITH_DOORLOOP = "6457764",
  BUDGETING_OVERVIEW = "9086434",
  CREATE_NEW_BUDGET = "9130709",
  RUN_BUDGET_VS_ACTUAL_REPORT = "9146557",
  EDIT_LEASE_AFTER_END = "6187026",
  ENDED_LEASE_DEPOSIT = "6185304",
  ADD_PREVIOUS_LEASES = "6115750",
  SETUP_CHARTS_OF_ACCOUNTS = "6170976",
  CHANGE_DEFAULT_CHART_OF_ACCOUNTS = "6231942",
  CREATE_VENDOR = "6295184",
  VENDOR_MANAGEMENT = "6362223",
  VENDOR_CATEGORY = "6318488",
  CREATE_PROSPECT = "6300791",
  TRACKING_PROSPECTS = "6301158",
  SCREEN_PROSPECTS = "6263176",
  SET_UP_RENTAL_APPLICATIONS = "8494476",
  SEND_RENTAL_APPLICATION = "6283517",
  REVIEW_RENTAL_APPLICATION = "6284916",
  CREATE_RECURRING_BILL = "6226278",
  EDIT_DELETE_RECURRING_BILL = "6250370",
  RECORD_DISTRIBUTION = "8421185",
  EDIT_DELETE_DISTRIBUTION = "6314603",
  PAY_BILLS = "6226358",
  MOVE_OUT_TENANT = "6187642",
  CREATE_RECURRING_TASK = "6228949",
  EDIT_DELETE_RECURRING_TASK = "6322721",
  MERCHANT_ACCOUNT_OVERVIEW = "8174404",
  CONVENIENCE_FEES = "8751594",

  HOA_CHECKBOOK = "8500566",
  HOA_LATE_FEES = "8464860",
  HOA_MANAGEMENT_FEES = "8538562",
  HOA_PAYMENT_ALLOCATIONS = "8538631",
  HOA_CREATE_A_TASK = "8487597",
  HOA_DELETE_A_TASK = "8500024",
  HOA_CREATE_RECURRING_TASK = "8487597",
  HOA_DELETE_A_RECURRING_TASK = "8500024",
  HOA_CREATE_WORK_ORDER = "8500191",
  HOA_DELETE_A_WORK_ORDER = "8500423",
  HOA_UNDERSTAND_CHART_ACCOUNTS = "8538659",
  HOA_SET_UP_CHART_ACCOUNTS = "8538748",
  HOA_CHANGE_DEFAULT_ACCOUNTS = "8490520",
  HOA_ESTABLISH_BANK_CONNECT = "8539132",
  HOA_DIFFERENCE_CONNECTING_PLAID = "8539150",
  HOA_BANK_RECONCILIATION = "8541067",
  HOA_RECONCILIATION_ISSUES = "8541157",
  HOA_CREATE_RECURRING_BILL = "8500639",
  HOA_CREATE_RECURRING_EXPENSE = "8500946",
  HOA_EDIT_BILL_OR_EXPENSE = "8538941",
  HOA_PRINT_CHECKS = "8538772",
  HOA_REPRINT_CHECKS = "8582241",
  HOA_ONLINE_PAYMENTS_STATUS = "8507125",
  HOA_SETUP_MERCHANT_ACCOUNT = "8464802",
  HOA_SEND_ANNOUNCEMENTS = "8490286",
  HOA_MESSAGE_SINGLE_OWNER = "8539067",
  HOA_COMMUNICATIONS_LOG = "8505961",
  HOA_TRACK_INVITATIONS = "8539058",
  HOA_RECORD_PAYMENTS = "8538813",
  HOA_EDIT_OR_DELETE_PAYMENTS = "8538840",
  HOA_ISSUE_CREDIT = "8538882",
  HOA_EDIT_OR_DELETE_CREDIT = "8538911",
  HOA_GIVE_REFUND = "8538984",
  HOA_EDIT_OR_DELETE_REFUND = "8539026",
  HOA_CREATE_EXPENSE = "8500946",
  HOA_CREATE_BILL = "8500639",
  HOA_ADD_HOMEOWNER_ACCOUNT = "8464896",
  HOA_REQUEST_SIGNATURE = "8562633",
  HOA_SIGNATURE_TEMPLATE = "8562761",
  HOA_PAY_BILL = "8500807",
  HOA_INSUFFICIENT_FUNDS = "8562457",
  HOA_REDO_MANAGEMENT_FEES = "8562545",
  HOA_ADD_OWNER_TO_HOMEOWNER_ACCOUNT = "8474543",
  HOA_FIND_OWNERS = "8487242",
  HOA_ADD_VENDOR = "8475424",
  HOA_VENDOR_MANAGEMENT = "8486965",
  HOA_CATEGORIZE_VENDORS = "8565750",
  HOA_CONVENIENCE_FEES = "8951328",
  COMMUNICATIONS_CENTER = "9061551",
  SETUP_SMS = "9106126",
  PENDING_BANK_VERIFICATION = "8250825",
  AREA_CODES = "9558430",
  SETUP_SMS_FAQ = "9703728"
}

export enum VideoUrlsEnum {
  USING_ZAPIER_WITH_DOORLOOP = "https://doorloop.wistia.com/medias/zgs6bihw0p",
  END_A_LEASE = "https://doorloop.wistia.com/medias/5gg0c463ai",
  CREATE_A_LEASE = "https://doorloop.wistia.com/medias/jmato77l1b ",
  PRINT_CHECKS = "https://doorloop.wistia.com/medias/5fcyg84jwk",
  CHART_OF_ACCOUNTS = "https://doorloop.wistia.com/medias/5vkpkix62r",
  MERCHANT_ACCOUNT = "https://doorloop.wistia.com/medias/gv86z438fq",
  BANK_CONNECT = "https://doorloop.wistia.com/medias/8honndx734 ",
  BANK_ACCOUNT = "https://doorloop.wistia.com/medias/igcxtq5yb7",
  MANAGEMENT_FEE_BILLS = "https://doorloop.wistia.com/medias/o3bjkydg1y",
  COMMUNICATIONS_LOG = "https://doorloop.wistia.com/medias/j9247jwhpu",
  ANNOUNCEMENT = "https://doorloop.wistia.com/medias/j9247jwhpu",
  ESIGNATURE_REQUEST = "https://doorloop.wistia.com/medias/rs6yxq4k63",
  ESIGNATURE_TEMPLATES = "https://doorloop.wistia.com/medias/tzvtq0vx9t",
  TENANTS_OVERVIEW = "https://doorloop.wistia.com/medias/200xqndmeb",
  VENDORS_OVERVIEW = "https://doorloop.wistia.com/medias/hb3kmiaame",
  PROSPECTS_OVERVIEW = "https://doorloop.wistia.com/medias/jwgdvnymur",
  DRAFT_LEASES = "https://doorloop.wistia.com/medias/qte6ndk5mi",
  LEASE_RENEWALS = "https://doorloop.wistia.com/medias/r7nu49khpv",
  CREATE_A_TASK = "https://doorloop.wistia.com/medias/3vy6jvso9m",
  TASK_OVERVIEW = "https://doorloop.wistia.com/medias/h69wezpb7r",
  CREATE_WORK_ORDER = "https://doorloop.wistia.com/medias/jblnf5er5r",
  BANK_RECONCILIATION = "https://doorloop.wistia.com/medias/hv3pcgza1d",
  RECURRING_BILLS = "https://doorloop.wistia.com/medias/mkon9gqns7",
  OWNER_DISTRIBUTION = "https://doorloop.wistia.com/medias/ljud18q46r",
  PAY_BILLS = "https://doorloop.wistia.com/medias/igcxtq5yb7",
  MOVE_IN_TENANT = "https://doorloop.wistia.com/medias/bywu30fyb6",
  MOVE_OUT_TENANT = "https://doorloop.wistia.com/medias/tfa3yu70ea",

  //  ONBOARDING PAGE
  OB_SUBSCRIBE_TO_DOORLOOP = "https://doorloop.wistia.com/medias/eqkm2tlh1t",
  OB_COMPANY_SETTINGS = "https://doorloop.wistia.com/medias/9uuglb8zcq",
  OB_CREATING_A_PROPERTY = "https://doorloop.wistia.com/medias/diwu9w20s0",
  OB_SETTING_UP_RENT_PAYMENTS = "https://doorloop.wistia.com/medias/woiwzqqdcq",
  OB_LATE_FEES = "https://doorloop.wistia.com/medias/tvh06j7xjl",
  OB_TENANT_PORTAL_CUSTOMIZATIONS = "https://doorloop.wistia.com/medias/mhmwvwio0t",
  OB_CREATING_A_LEASE = "https://doorloop.wistia.com/medias/syel2khpty",
  OB_ANNOUNCEMENTS = "https://doorloop.wistia.com/medias/alkh9167qv",
  LEASE_OVERVIEW = "https://doorloop.wistia.com/medias/uzwsy7zmzq",
  OWNERS = "https://doorloop.wistia.com/medias/6dtrfxx6mi",
  LEASE_RENTAL_APPLICATIONS = "https://doorloop.wistia.com/medias/8875ryuz1w",
  OB_SMS_MESSAGING = ""
}

export interface ArticleItem<TTranslation extends string = string> {
  type: HelpTypeEnum.INTERCOM_ARTICLE;
  topic: TTranslation;
  articleId: ArticleIdsEnum;
}

export interface VideoItem<TTranslation extends string = string> {
  type: HelpTypeEnum.WATCH_VIDEO;
  href: string;
  topic: TTranslation;
  dialogTitle?: TTranslation;
  dialogSubTitle?: TTranslation;
  startTime?: number;
}

export type ActionItem<TTranslation extends string = string> = VideoItem<TTranslation> | ArticleItem<TTranslation>;

export interface HelpPanelProps<TTranslation extends string = string> {
  actionText?: TTranslation;
  actionItems?: Array<ActionItem<TTranslation>>;
  description?: TTranslation;
  noWrapActionItem?: boolean;
}

export enum HelpTypeEnum {
  "INTERCOM_ARTICLE",
  "WATCH_VIDEO",
  "TAKE_A_TOUR"
}

export const ActionItemIconMap: Record<HelpTypeEnum, SVGIconComponent> = {
  [HelpTypeEnum.INTERCOM_ARTICLE]: InfoOutlineIcon,
  [HelpTypeEnum.WATCH_VIDEO]: PlayCircleOutlineIcon,
  [HelpTypeEnum.TAKE_A_TOUR]: FlagLightBlueOutlineIcon
};
