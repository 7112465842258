const Listing = {
  Errors: {
    Title: "listing.errors.title",
    UnitPhotos: "listing.errors.unitPhotos",
    PropertyPhotos: "listing.errors.propertyPhotos",
    UnitDescription: "listing.errors.unitDescription",
    PropertyDescription: "listing.errors.propertyDescription",
    UnitAmenities: "listing.errors.unitAmenities",
    PropertyAmenities: "listing.errors.propertyAmenities",
    UnitBedsRooms: "listing.errors.unitBedsRooms",
    UnitBathsRooms: "listing.errors.unitBathsRooms"
  },
  ForMoreInformation: "listing.forMoreInformation",
  ZillowSyndication: "listing.zillowSyndication",
  RealEstateAndHomes: "listing.realEstateAndHomes",
  NoListingsFound: "listing.noListingsFound",
  ConfirmCloseContactForm: "listing.confirmCloseContactForm",
  PageTitle: "listing.pageTitle",
  AlreadySubmittedContactForm: "listing.alreadySubmittedContactForm",
  HowCanWeHelp: "listing.howCanWeHelp",
  ThankYou: "listing.thankYou",
  Sorry: "listing.sorry",
  NoActiveListing: "listing.noActiveListing",
  GetInTouch: "listing.getInTouch",
  YourPropertyManager: "listing.yourPropertyManager",
  SubmissionSucceeded: "listing.submissionSucceeded",
  SubmissionFailed: "listing.submissionFailed",
  GeneralInformationSettingsDescription: "listing.generalInformationSettingsDescription",
  PhotosSettingsDescription: "listing.photosSettingsDescription",
  ClassAndTypeSettingsDescription: "listing.classAndTypeSettingsDescription",
  AmenitiesSettingsDescription: "listing.amenitiesSettingsDescription",
  PetPolicySettingsDescription: "listing.petPolicySettingsDescription",
  LeaseTerms: "listing.leaseTerms",
  Deposit: "listing.deposit",
  AskingRent: "listing.askingRent",
  ListingContact: "listing.listingContact",
  DateAvailable: "listing.dateAvailable",
  ListingDetails: "listing.listingDetails",
  ListingPartners: "listing.listingPartners",
  ThisUnitIsActive: "listing.thisUnitIsActive",
  PetsNotAllowed: "listing.petsNotAllowed",
  UnitDetails: "listing.unitDetails",
  PetsAllowed: "listing.petsAllowed",
  PetRestrictionsLabel: "listing.petRestrictionsLabel",
  SmallDogs: "listing.smallDogs",
  Dogs: "listing.dogs",
  Cats: "listing.cats",
  SelectWhichPetsLabel: "listing.selectWhichPetsLabel",
  PetsDetails: "listing.petsDetails",
  PetsPolicyDescription: "listing.petsPolicyDescription",
  PetsPolicy: "listing.petsPolicy",
  PartnerWebsitesListing: "listing.partnerWebsitesListing",
  UnitListingActiveDescription: "listing.unitListingActiveDescription",
  PropertyNoOwnersDescription: "listing.propertyNoOwnersDescription",
  PropertyNoAmenitiesDescription: "listing.propertyNoAmenitiesDescription",
  UnitNoAmenitiesDescription: "listing.unitNoAmenitiesDescription",
  UnitAmenitiesInfoDescription: "listing.unitAmenitiesInfoDescription",
  PropertyAmenitiesInfoDescription: "listing.propertyAmenitiesInfoDescription",
  GeneralPropertyInfoDescription: "listing.generalPropertyInfoDescription",
  GeneralUnitInfoDescription: "listing.generalUnitInfoDescription",
  PhotosDescription: "listing.photosDescription",
  CurrentStatus: "listing.currentStatus",
  FutureStatus: "listing.futureStatus",
  MoveOutInXDays: "listing.moveOutInXDays",
  MoveInInXDays: "listing.moveInInXDays",
  NotRented: "listing.notRented",
  Rented: "listing.rented",
  Photos: "listing.photos",
  NoPhotos: "listing.noPhotos",
  UploadPhotosLabel: "listing.uploadPhotosLabel",
  AddNewPhotos: "listing.addNewPhotos",
  BedBathSqftAbbreviated: "listing.bedBathSqftAbbreviated",
  MonthSuffixAbbreviated: "listing.monthSuffixAbbreviated",
  Amenities: "listing.amenities",
  UnitAmenities: "listing.unitAmenities",
  NoAmenities: "listing.noAmenities",
  AddAmenities: "listing.addAmenities",
  PropertyAmenities: "listing.propertyAmenities",
  PropertyDetails: "listing.propertyDetails",
  FeaturesAndAmenities: "listing.featuresAndAmenities",
  UnitDescription: "listing.unitDescription",
  PropertyDescription: "listing.propertyDescription",
  NoOwners: "listing.noOwners",
  AddOwners: "listing.addOwners",
  Description: "listing.description",
  ListingStatus: "listing.listingStatus",
  ActivateListing: "listing.activateListing",
  Listed: "listing.listed",
  Listing: "listing.listing",
  ListingSyndication: "listing.listingSyndication",
  ListAndMarketYourVacancies: "listing.listAndMarketYourVacancies",
  ListingSyncOptions: "listing.listingSyncOptions",
  ChooseSyndicationsDesc: "listing.chooseSyndicationsDesc",
  LearnMoreSyndication: "listing.learnMoreSyndication",
  SyndicationDescription: "listing.syndicationDescription",
  NotListed: "listing.notListed",
  Available: "listing.available",
  AvailableNow: "listing.availableNow",
  Asking: "listing.asking",
  UpdateUnitInformation: "listing.updateUnitInformation",
  UpdatePropertyInformation: "listing.updatePropertyInformation",
  ListingDoesntAcceptOnlineApplications: "listing.listingDoesntAcceptOnlineApplications",
  RequestInformation: "listing.requestInformation",
  ApplyOnline: "listing.applyOnline",
  Other: "listing.other",
  Bedrooms: "listing.bedrooms",
  Bathrooms: "listing.bathrooms",
  SquareFeet: "listing.squareFeet",
  MonthlyRent: "listing.monthlyRent",
  NumberBedroomsAbbreviated: "listing.numberBedroomsAbbreviated",
  NumberBathroomsAbbreviated: "listing.numberBathroomsAbbreviated",
  Beds: "listing.beds",
  Baths: "listing.baths",
  Price: "listing.price",
  Type: "listing.type",
  Property: "listing.property",
  Unit: "listing.unit",
  SearchTextFieldPlaceholder: "listing.searchTextFieldPlaceholder",
  SaveAndClose: "listing.saveAndClose",
  PriceRange: "listing.priceRange",
  UseExactMatch: "listing.useExactMatch",
  // Property Amenities
  Availability24Hours: "listing.availability24Hours",
  AccentWalls: "listing.accentWalls",
  BasketballCourt: "listing.basketballCourt",
  Bilingual: "listing.bilingual",
  BoatDocks: "listing.boatDocks",
  BusinessCenter: "listing.businessCenter",
  CarWashArea: "listing.carWashArea",
  ChildCare: "listing.childCare",
  ClubDiscount: "listing.clubDiscount",
  ClubHouse: "listing.clubHouse",
  ConferenceRoom: "listing.conferenceRoom",
  Concierge: "listing.concierge",
  CoverPark: "listing.coverPark",
  DoorAttendant: "listing.doorAttendant",
  FitnessCenter: "listing.fitnessCenter",
  Elevator: "listing.elevator",
  FreeWeights: "listing.freeWeights",
  FurnishedAvailable: "listing.furnishedAvailable",
  GamingStations: "listing.gamingStations",
  Garage_Attached: "listing.garage_Attached",
  Garage_Detached: "listing.garage_Detached",
  Garage_SameLevelParking: "listing.garage_SameLevelParking",
  Gate: "listing.gate",
  GroceryService: "listing.groceryService",
  GroupExercise: "listing.groupExercise",
  GuestRoom: "listing.guestRoom",
  HighSpeed: "listing.highSpeed",
  Housekeeping: "listing.housekeeping",
  HouseSitting: "listing.houseSitting",
  JoggingWalkingTrails: "listing.joggingWalkingTrails",
  LakeFront: "listing.lakeFront",
  LakeAccess: "listing.lakeAccess",
  Laundry: "listing.laundry",
  Library: "listing.library",
  MealService: "listing.mealService",
  MediaRoom: "listing.mediaRoom",
  MultiUseRoom: "listing.multiUseRoom",
  NightPatrol: "listing.nightPatrol",
  OnSiteMaintenance: "listing.onSiteMaintenance",
  OnSiteManagement: "listing.onSiteManagement",
  PackageReceiving: "listing.packageReceiving",
  PerDiemAccepted: "listing.perDiemAccepted",
  Pool: "listing.pool",
  PlayGround: "listing.playGround",
  Racquetball: "listing.racquetball",
  RecRoom: "listing.recRoom",
  Recycling: "listing.recycling",
  Sauna: "listing.sauna",
  ShortTermLease: "listing.shortTermLease",
  SmokeFree: "listing.smokeFree",
  Spa: "listing.spa",
  StorageSpace: "listing.storageSpace",
  Sundeck: "listing.sundeck",
  TennisCourt: "listing.tennisCourt",
  Transportation: "listing.transportation",
  TVLounge: "listing.tVLounge",
  ValetTrash: "listing.valetTrash",
  Vintage: "listing.vintage",
  VolleyballCourt: "listing.volleyballCourt",
  WirelessInternet: "listing.wirelessInternet",
  //Unit amenities
  AdditionalStorage: "listing.additionalStorage",
  AirConditioner: "listing.airConditioner",
  Alarm: "listing.alarm",
  Balcony: "listing.balcony",
  Cable: "listing.cable",
  Carpet: "listing.carpet",
  Carport: "listing.carport",
  CeilingFan: "listing.ceilingFan",
  ControlledAccess: "listing.controlledAccess",
  Courtyard: "listing.courtyard",
  Dishwasher: "listing.dishwasher",
  Disposal: "listing.disposal",
  DoubleSinkVanity: "listing.doubleSinkVanity",
  Dryer: "listing.dryer",
  Fireplace: "listing.fireplace",
  FramedMirrors: "listing.framedMirrors",
  Furnished: "listing.furnished",
  Garage: "listing.garage",
  Handrails: "listing.handrails",
  HardwoodFlooring: "listing.hardwoodFlooring",
  HardSurfaceCounterTops: "listing.hardSurfaceCounterTops",
  Heat: "listing.heat",
  IndividualClimateControl: "listing.individualClimateControl",
  IslandKitchen: "listing.islandKitchen",
  LaminateCounterTops: "listing.laminateCounterTops",
  VinylFlooring: "listing.vinylFlooring",
  LargeClosets: "listing.largeClosets",
  LinenCloset: "listing.linenCloset",
  Microwave: "listing.microwave",
  Pantry: "listing.pantry",
  Patio: "listing.patio",
  PrivateBalcony: "listing.privateBalcony",
  PrivatePatio: "listing.privatePatio",
  Range: "listing.range",
  Refrigerator: "listing.refrigerator",
  Satellite: "listing.satellite",
  Skylight: "listing.skylight",
  TileFlooring: "listing.tileFlooring",
  VaultedCeiling: "listing.vaultedCeiling",
  View: "listing.view",
  Washer: "listing.washer",
  WheelChair: "listing.wheelChair",
  WD_Hookup: "listing.wD_Hookup",
  WindowCoverings: "listing.windowCoverings",
  RentalListingsURL: "listing.rentalListingsURL",
  CompanyId: "listing.companyId",
  Paid: "listing.paid",
  Free: "listing.free",
  AutoSyncEnabled: "listing.autoSyncEnabled",
  OtherInstructionDescription: "listing.otherInstructionDescription",
  ReachApartmentsCom: "listing.reachApartmentsCom",
  ReachApartmentsComDescription: "listing.reachApartmentsComDescription",
  ReachAZillow: "listing.reachAZillow",
  ReachAZillowDescription: "listing.reachAZillowDescription"
};

export default Listing;
