import { useComposedRef } from "./useComposedRef";
import { useRef } from "react";
import { useEventEffect } from "./useEventEffect";
import { useRefEffect } from "./useRefEffect";
import { getKeyboardFocusableElements } from "utils/dom";
import { useKeydown } from "./useKeydown";

/**
 * Creates a ref to be attached to the last item in an array of elements
 *
 * Handles
 * - Adding a new item when the use tabs out of the last item
 * - Moving focus to the first element in the new item
 * @param addItem A function to add a new item to the array
 * @param deps Dependencies for the addItem function
 * @returns A ref which should be attached to the last item in the array
 */

export function useLastArrayItemRef<T extends HTMLElement>(addItem: () => void, deps: any[]) {
  const { isPressed } = useKeydown();
  const { current: state } = useRef({ initialized: false, needsFocus: false });
  return useComposedRef<T>(
    useEventEffect(
      "focusout",
      (event, element) => {
        if (isPressed("Tab", false) && !element.contains(event.relatedTarget as Node)) {
          state.needsFocus = true;
          addItem();
        }
      },
      [addItem, ...deps]
    ),
    useRefEffect<T>((element, prevElement) => {
      // Skip the first render and let the focus be handled by the browser
      if (!state.initialized) {
        state.initialized = true;
      } else if (state.needsFocus && !element.isSameNode(prevElement) && !element?.contains(document.activeElement)) {
        state.needsFocus = false;
        const [firstChild] = getKeyboardFocusableElements(element) || [];
        firstChild?.focus();
      }
    }, [])
  );
}
