import { usersApi } from "api/usersApi";
import type { StaticRemoteOrDateFilter } from "DLUI/screen/filterPanel/filterPanel";
import AppStrings from "locale/keys";
import type { GetAllFilesQuery } from "@doorloop/dto";
import { TagType } from "@doorloop/dto";
import { tagsApi } from "api/tagsApi";
import { propertyGroupsApi } from "api/propertyGroupsApi";
import { propertiesApi } from "api/propertiesApi";

const FilterOptions: Array<StaticRemoteOrDateFilter<GetAllFilesQuery>> = [
  {
    label: AppStrings.Tasks.Screen.CreatedAt,
    type: "dateRangePicker",
    period: "all-time",
    startDateFieldKey: "filter_createdAt_from",
    endDateFieldKey: "filter_createdAt_to"
  },
  {
    type: "remoteFiltering",
    name: AppStrings.Common.UpdatedBy,
    apiHandler: usersApi,
    displayNameKey: "name",
    filterFieldName: "filter_updatedBy",
    filterFieldValue: "id"
  },
  {
    type: "remoteFiltering",
    name: AppStrings.Common.FilterOptions.Portfolio,
    apiHandler: propertyGroupsApi,
    displayNameKey: "name",
    filterFieldName: "filter_portfolio",
    filterFieldValue: "id"
  },
  {
    type: "remoteFiltering",
    name: AppStrings.Common.FilterOptions.Property,
    apiHandler: propertiesApi,
    displayNameKey: "name",
    filterFieldName: "filter_property",
    filterFieldValue: "id"
  },
  {
    name: AppStrings.Common.Dropzone.Tags,
    type: "remoteFiltering",
    apiHandler: tagsApi,
    displayNameKey: "name",
    filterFieldName: "filter_tags",
    filterFieldValue: "id",
    queryParams: {
      filter_type: TagType.FILES_TAG
    }
  }
];

export default FilterOptions;
