import _ from "lodash";

export type DynamicStyles = "default" | "barely";
export type CSSVars =
  | "primary-bg"
  | "primary-text"
  | "primary-border-radius"
  | "primary-hsl"
  | "background-neutral-hsl"
  | "background-neutral-light-hsl"
  | "background-neutral-lightest-hsl"
  | "primary"
  | "background-neutral"
  | "background-neutral-light"
  | "background-neutral-lightest"
  | "border-primary-color"
  | "border-primary-color-light"
  | "border-primary-color-lightest"
  | "border-primary-style"
  | "border-primary"
  | "border-primary-light"
  | "border-primary-lightest"
  | "reports-border-color"
  | "reports-border";

export type StylesVariables = Record<DynamicStyles, StyleVariables>;
export type StyleVariables = Partial<Record<CSSVars, string>>;
export type CSSVarValue = `var(--${CSSVars})`;
export const cssVar = _.memoize((variable: CSSVars): CSSVarValue => `var(--${variable})`);
