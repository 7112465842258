import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    fabBackdrop: {
      zIndex: theme.zIndex.speedDial - 1
    },
    fabButton: {
      backgroundColor: theme.newPalette.primary.main,
      "&:hover": {
        backgroundColor: theme.newPalette.primary.dark
      }
    }
  })
);
