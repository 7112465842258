export default {
  OnBoardingDrawerTitle: "Get Started",
  GetStartedWithDoorLoop: "Get Started with DoorLoop",
  HeroSubtitle:
    "Let us help you get the most out of DoorLoop. Just follow the steps and you will be up & running in no time.",
  Skip: "Skip",
  WelcomeToDoorLoop: "Welcome to DoorLoop",
  ThoughtBubbleText: "If you need any help, we’re only a click away.",
  ScheduleASupportCall: "Ask us anything",
  MarkAsCompleted: "Mark as completed",
  MarkAsIncomplete: "Mark as incomplete",
  Schedule1On1PersonalTraining: "Schedule 1-on-1 Personal Training",
  ScheduleNow: "Schedule Now",
  DontShowAtStartAgain: "Don't show this page at start again",
  AlwaysShowAtStart: "Always show this page at start",
  XoutofYUnits: "{{progress}} out of {{total}} units",
  XoutofYLeases: "{{progress}} out of {{total}} leases",
  XoutofYProperties: "{{progress}} out of {{total}} properties",
  footer: {
    Title: "Want to learn more about DoorLoop?",
    Links: {
      RentalApplications: {
        title: "Rental Applications and Tenant Screening",
        description:
          "Automate and streamline your rental application process by having prospects apply online via their computer or phone."
      },
      Announcements: {
        title: "Announcements",
        description:
          "Communicate directly with your tenants via email, text, voice message, and announcements through the tenant portal."
      },
      MarketingAndListings: {
        title: "Marketing & Listings",
        description:
          "List your units for rent online and use DoorLoop’s CRM features to manage prospects and find better tenants faster."
      }
    }
  },
  stepTitles: {
    subscribeToDoorLoop: "Subscribe to DoorLoop",
    scheduleAnOnBoardingSession: "Schedule an Onboarding Session",
    addYourCompanyInfo: "Add Your Company Info",
    addYourProperties: "Add Your Properties",
    acceptRentPayments: "Accept Rent Payments",
    setupYourAutoLateFees: "Setup Your Auto Late Fees",
    customizeYourTenantPortal: "Customize Your Tenant Portal",
    addYourLeases: "Add Your Leases",
    sendAnnouncement: "Send an Announcement to Your Tenants",
    smsMessaging: "Setup SMS Messaging"
  },
  stepDescriptions: {
    subscribeToDoorLoop: `Manage, grow, and automate your rental portfolio.

    Collect rent, screen tenants and manage maintenance requests, all in one place.
    `,
    scheduleAnOnBoardingSession:
      "Get a free training call on Zoom and learn the best tips and tricks to get the most out of DoorLoop.",
    addYourCompanyInfo: "Customize your company settings and add your company name, contact info, logos, and more.",
    addYourProperties:
      "Creating your properties is the first step towards managing them better than ever. Soon you’ll be automating rent collection, seamlessly filling vacancies, and managing your tenants.",
    acceptRentPayments: `Say goodbye to chasing tenants for rent payments. Now you can get paid faster and automate rent collection on the same day each month.

Tenants can pay by credit card or ACH at no cost to you and you can disable it for certain tenants or properties.
`,
    setupYourAutoLateFees: `You can customize your automatic late fees by choosing:

- How many days after rent is due to charge the fee
- How often to charge the fee (once, daily, weekly, or monthly)
- To charge a fixed amount or a percentage of the balance due
- Which income account the late fees should go into`,
    customizeYourTenantPortal: `The Tenant Portal is where your tenants can make online payments, submit maintenance requests, view their previous payments, and more.

Customize the design and features of the portal now.`,
    addYourLeases: `Add all your lease agreements and terms for every rental.

See which leases are expiring soon, send renewal notices, and even schedule automated rent increases.`,
    sendAnnouncement: `Once everything is ready to go, it’s time to WOW your tenants and show them why you’re the most professional and modern property manager ever!

Tell your Tenants they can now use DoorLoop to make rent payments, submit maintenance requests, and much more.`,
    smsMessaging:
      "By using DoorLoop's SMS tool, you don't need a separate SMS account. The solution includes compliance and consent management out of the box without messy integrations. Plus, set-up is super fast with an easy onboarding flow."
  },
  actionButtons: {
    viewSubscription: "View Subscription",
    subscribeToDoorLoop: "Subscribe now",
    scheduleACall: "Schedule a call",
    addYourCompanyInfo: "Add Company Info",
    addYourProperties: "Add a Property",
    acceptRentPayments: "Accept Rent Payments",
    setupYourAutoLateFees: "Setup Auto Late Fees",
    customizeYourTenantPortal: "Customize the Tenant Portal",
    addYourLeases: "Add a Lease",
    sendAnnouncement: "Send an Announcement",
    setupSms: "Setup SMS",
    startTexting: "Start Texting"
  }
};
