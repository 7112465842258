/**
 * Gets keyboard-focusable elements within a specified element
 * @param element The element to search within. Defaults to document
 */
export function getKeyboardFocusableElements(element: HTMLElement | Document = document) {
  return [
    ...element.querySelectorAll('a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])')
  ].filter((el) => !el.hasAttribute("disabled") && !el.getAttribute("aria-hidden")) as HTMLElement[];
}

export function getElementDistanceFromTop(element: HTMLElement, targetElement?: HTMLElement) {
  if (!element) {
    return 0;
  }

  let nextElement = element;
  let distance = 0;

  while (nextElement && nextElement !== targetElement) {
    distance += nextElement.offsetTop;
    nextElement = nextElement.offsetParent as HTMLElement;

    if (!nextElement) {
      break;
    }
  }

  return distance;
}
