import type { LeaseDto } from "@doorloop/dto";
import { propertiesApi } from "api/propertiesApi";
import { unitsApi } from "api/unitsApi";
import { CalendarIconBlue, LeaseListIcon } from "assets/icons";
import { Icon } from "DLUI/icon";
import { Grid, ListItemContainer, ListItemIcon, ListItemSection } from "DLUI/listItems";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { Fragment, useMemo } from "react";
import ListItemText from "DLUI/listItems/listItemText";
import TextEllipsis from "DLUI/text/textEllipsis";
import { useUserType } from "../../../../hooks/useUserType";

interface ComponentProps {
  leaseData: LeaseDto;
}

const CurrentTermsItem: React.FC<ComponentProps> = ({ leaseData }: ComponentProps) => {
  if (!leaseData) {
    return null;
  }
  const { name, end, property, units } = leaseData;
  const { isHOAUser } = useUserType();

  const propertyName = useMemo(() => {
    let _propertyName = "";
    const unitNames: string[] = [];
    if (property) {
      const propertiesNameDictionary = propertiesApi.getItemFromDictionary(property);
      if (propertiesNameDictionary) {
        _propertyName += propertiesNameDictionary.name;
        if (units) {
          _.forEach(units, (currentUnitId) => {
            const unitNameDictionary = unitsApi.getItemFromDictionary(currentUnitId);
            if (unitNameDictionary?.name) {
              unitNames.push(unitNameDictionary.name);
            }
          });
          _propertyName += ", ";
          _propertyName += unitNames.join(" ");
        }
      }
    }
    return _propertyName;
  }, []);

  const renderEndDate = () => (
    <Fragment>
      <Text marginLeft={5} marginRight={5} color={"black"} align="left" fontSize={14} fontWeight={700}>
        -
      </Text>
      <Text
        color={"black"}
        align="left"
        fontSize={14}
        fontWeight={700}
        value={end || AppStrings.Leases.NewLease.Atwill}
        formatType={end ? "date" : undefined}
      />
    </Fragment>
  );

  return (
    <View marginTop={20}>
      <ListItemContainer
        removeVerticalPadding
        borderRadius={10}
        backgroundColor={"dark"}
        width={isHOAUser ? "auto" : "100%"}
      >
        <Grid
          title={AppStrings.Leases.ListHeader.Lease}
          showDivider={!isHOAUser}
          dividerColor={"gray"}
          xs={12}
          md={isHOAUser ? 12 : 6}
          lg={isHOAUser ? 12 : 6}
        >
          <View height={"100%"} alignItems={"center"} flexDirection={"row"} justifyContent={"center"}>
            <ListItemIcon Icon={LeaseListIcon} />
            <ListItemSection
              renderTitle={
                <ListItemText color={"black"} align="center" fontWeight={700} overflow={"ellipsis"} numberOfLines={2}>
                  {name}
                </ListItemText>
              }
              renderSubTitle={
                <View justifyContent={"center"} alignItems={"center"} flexDirection={"row"} noWrap>
                  <TextEllipsis align={"center"} color={"gray"} text={propertyName} numberOfLines={2} />
                </View>
              }
            />
          </View>
        </Grid>
        {!isHOAUser && (
          <Grid dividerColor={"gray"} xs={12} md={6} lg={6}>
            <ListItemSection
              renderTitle={
                <View flexDirection={"row"} height={"100%"}>
                  <View flexDirection={"row"} justifyContent={"center"} alignItems={"center"} height={"100%"}>
                    <Icon marginRight={5} Source={CalendarIconBlue} pathColor={"black"} />

                    <Text
                      color={"black"}
                      align="left"
                      fontSize={14}
                      fontWeight={700}
                      value={leaseData.start}
                      formatType={"date"}
                    />
                    {renderEndDate()}
                  </View>
                </View>
              }
              renderSubTitle={
                <View justifyContent={"center"} alignItems={"center"} flexDirection={"row"}>
                  {end ? (
                    <Fragment>
                      <Text
                        color={"gray"}
                        variant={"span"}
                        value={AppStrings.Leases.DraftLease.MoveOut}
                        marginRight={5}
                        fontSize={16}
                      />
                      <Text color={"gray"} fontSize={16} formatType={"date"} value={end} />
                    </Fragment>
                  ) : null}
                </View>
              }
            />
          </Grid>
        )}
      </ListItemContainer>
    </View>
  );
};

export default CurrentTermsItem;
