import has from "lodash/has";
import type { AndroidMetadata } from "./types";
import type { DevicePlatform, DevicePlatformType } from "@doorloop/dto";
import { isAndroid, isIOS, isTablet } from "react-device-detect";

export function isAndroidWebView(): boolean {
  return has(window.__DL_ANDROID__, "version") && has(window.__DL_ANDROID__, "buildNumber");
}

export function getAndroidMetadata(): AndroidMetadata | undefined {
  if (!isAndroidWebView()) {
    return;
  }

  return window.__DL_ANDROID__;
}

export function isIOSWebView(): boolean {
  return navigator.userAgent.includes("DL_webView");
}

export function isWebView() {
  return isAndroidWebView() || isIOSWebView();
}

export function getCurrentDevicePlatform(): DevicePlatform {
  if (isAndroidWebView()) return "android";
  if (isIOSWebView()) return "ios";

  return "web";
}

export function getCurrentDevicePlatformType(): DevicePlatformType {
  if (isIOSWebView()) return isTablet ? "iOS_native_tablet" : "iOS_native";
  if (isAndroidWebView()) return isTablet ? "android_native_tablet" : "android_native";
  if (isAndroid) return isTablet ? "android_web_tablet" : "android_web";
  if (isIOS) return isTablet ? "iOS_web_tablet" : "iOS_web";
  return "desktop";
}
