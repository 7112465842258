"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOwnerStatementReportRequestDto = void 0;
const class_validator_1 = require("class-validator");
const runReportsBy_enum_1 = require("./runReportsBy.enum");
const reportType_enum_1 = require("./reportType.enum");
const transactionReport_1 = require("../transactionReport");
const profitAndLoss_1 = require("../profitAndLoss");
const common_1 = require("../../common");
const accountingMethod_enum_1 = require("../../journalEntries/accountingMethod.enum");
class GetOwnerStatementReportRequestDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.GetOwnerStatementReportRequestDto = GetOwnerStatementReportRequestDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(transactionReport_1.TransactionReportGroupBy),
    __metadata("design:type", String)
], GetOwnerStatementReportRequestDto.prototype, "groupBy", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(profitAndLoss_1.ShowNonZeroOrActiveOnly),
    __metadata("design:type", String)
], GetOwnerStatementReportRequestDto.prototype, "showRows", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, common_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetOwnerStatementReportRequestDto.prototype, "filter_date_from", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, common_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetOwnerStatementReportRequestDto.prototype, "filter_date_to", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(accountingMethod_enum_1.AccountingMethod),
    __metadata("design:type", String)
], GetOwnerStatementReportRequestDto.prototype, "filter_accountingMethod", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetOwnerStatementReportRequestDto.prototype, "filter_property", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], GetOwnerStatementReportRequestDto.prototype, "filter_properties", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetOwnerStatementReportRequestDto.prototype, "filter_unit", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetOwnerStatementReportRequestDto.prototype, "filter_propertyGroup", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetOwnerStatementReportRequestDto.prototype, "filter_tenant", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetOwnerStatementReportRequestDto.prototype, "filter_propertyOwner", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], GetOwnerStatementReportRequestDto.prototype, "filter_propertyOwners", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetOwnerStatementReportRequestDto.prototype, "filter_vendor", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)({ maxDecimalPlaces: 0 }),
    __metadata("design:type", Number)
], GetOwnerStatementReportRequestDto.prototype, "filter_page", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)({ maxDecimalPlaces: 0 }),
    __metadata("design:type", Number)
], GetOwnerStatementReportRequestDto.prototype, "filter_perPage", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(runReportsBy_enum_1.RunReportsByEnum),
    __metadata("design:type", String)
], GetOwnerStatementReportRequestDto.prototype, "runReportsBy", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(reportType_enum_1.ReportType),
    __metadata("design:type", String)
], GetOwnerStatementReportRequestDto.prototype, "filter_report_type", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Object)
], GetOwnerStatementReportRequestDto.prototype, "ownerMessages", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    __metadata("design:type", Array)
], GetOwnerStatementReportRequestDto.prototype, "columns", void 0);
