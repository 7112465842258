import React from "react";
import { bankAccountsApi } from "api/bankAccountsApi";
import BankAccountsListEmptyDataView from "screens/transactions/transactionsOverview/bankAccountsListEmptyDataView";
import { useDebounceInit } from "hooks/useDebounceInit";
import { LoadingAnimation } from "DLUI/animations/loadingAnimation";
import { View } from "DLUI/view";
import { DataList } from "@/components/DLUI/dataList";
import { OutgoingPaymentsAccountSetupAccountListItem } from "screens/outgoingPayments/outgoingPaymentAccountSetup/outgoingPaymentsAccountSetupAccountListItem";

interface PaymentAccountSetupAccountListProps {
  filterObj?: Record<string, any>;
  onLoadingDataStateChange?: (loadingInProgress: boolean) => void;
  stickyHeaderId: string;
}

export const OutgoingPaymentsAccountSetupAccountList = ({
  filterObj,
  onLoadingDataStateChange,
  stickyHeaderId
}: PaymentAccountSetupAccountListProps) => {
  const { isRender } = useDebounceInit(5000);

  return isRender ? (
    <DataList
      infinite
      restApi={bankAccountsApi}
      emptyListView={BankAccountsListEmptyDataView}
      queryFilter={filterObj}
      onLoadingDataStateChange={onLoadingDataStateChange}
      stickyHeaderProps={{
        id: stickyHeaderId
      }}
    >
      {(currentItem, index) => (
        <div style={{ width: "100%" }} key={currentItem.id || index}>
          <OutgoingPaymentsAccountSetupAccountListItem bankAccountDto={currentItem} stickyHeaderId={stickyHeaderId} />
        </div>
      )}
    </DataList>
  ) : (
    <View alignSelf={"center"} marginTop={200} justifyContent={"center"} alignItems={"center"} flexDirection={"row"}>
      <LoadingAnimation />
    </View>
  );
};
