import React from "react";

import type { PhoneNumberDto } from "@doorloop/dto";
import { PhoneNumberStatus } from "@doorloop/dto";

import AppStrings from "../../../../locale/keys";
import type { MenuItem } from "DLUI/dialogs";
import { EmptyPhoneNumberView } from "screens/communicationsCenter/components/emptyPhoneNumberView";
import { LeadsIcon, OwnersIcon, SmsIcon, TenantsIcon, VendorsIcon } from "assets/icons";
import { MessagesSettings } from "./components/messagesSettings";
import { SmsSettings } from "screens/settings/communicationCenter/components/smsSettings";
import { View } from "DLUI/view";
import { filterObjectsByUserType } from "../../../../utils/userTypesUtils";

export const getCommunicationCenterFormMenuItems = (
  phoneNumberDto?: PhoneNumberDto | null,
  isHOAUser?: boolean
): MenuItem[] => {
  const menuItems: MenuItem[] = [
    {
      title: AppStrings.Settings.GeneralSettings.CommunicationCenter.SmsSettings,
      icon: SmsIcon,
      contentComponent: () => (phoneNumberDto ? <SmsSettings phoneNumberDto={phoneNumberDto} /> : null)
    },
    {
      title: AppStrings.UserRoles.Tenants,
      icon: TenantsIcon,
      contentComponent: () => (
        <MessagesSettings
          messagesSettingsKey={"tenantsMessagesSettings"}
          messagesSettingsTitle={AppStrings.Settings.GeneralSettings.CommunicationCenter.MessagesSettingsTenantsTitle}
          assignToUsersLabel={
            AppStrings.Settings.GeneralSettings.CommunicationCenter.MessagesSettingsTenantsAssignToUsersLabel
          }
        />
      )
    },
    {
      title: AppStrings.UserRoles.Owners,
      icon: OwnersIcon,
      hiddenForUserTypes: ["HOAUser"],
      contentComponent: () => (
        <MessagesSettings
          messagesSettingsKey={"ownersMessagesSettings"}
          messagesSettingsTitle={AppStrings.Settings.GeneralSettings.CommunicationCenter.MessagesSettingsOwnersTitle}
          assignToUsersLabel={
            AppStrings.Settings.GeneralSettings.CommunicationCenter.MessagesSettingsOwnersAssignToUsersLabel
          }
        />
      )
    },
    {
      title: AppStrings.UserRoles.Vendors,
      icon: VendorsIcon,
      contentComponent: () => (
        <MessagesSettings
          messagesSettingsKey={"vendorsMessagesSettings"}
          messagesSettingsTitle={AppStrings.Settings.GeneralSettings.CommunicationCenter.MessagesSettingsVendorsTitle}
          assignToUsersLabel={
            AppStrings.Settings.GeneralSettings.CommunicationCenter.MessagesSettingsVendorsAssignToUsersLabel
          }
        />
      )
    },
    {
      title: AppStrings.UserRoles.Prospects,
      icon: LeadsIcon,
      hiddenForUserTypes: ["HOAUser"],
      contentComponent: () => (
        <MessagesSettings
          messagesSettingsKey={"prospectsMessagesSettings"}
          messagesSettingsTitle={AppStrings.Settings.GeneralSettings.CommunicationCenter.MessagesSettingsProspectsTitle}
          assignToUsersLabel={
            AppStrings.Settings.GeneralSettings.CommunicationCenter.MessagesSettingsProspectsAssignToUsersLabel
          }
        />
      )
    }
  ];

  if (phoneNumberDto?.status !== PhoneNumberStatus.ACTIVE) {
    return menuItems.map((menuItem) => {
      return {
        ...menuItem,
        contentComponent: () => (
          <View width={"100%"} height={"100%"} justifyContent={"center"}>
            <EmptyPhoneNumberView currentPhoneNumber={phoneNumberDto} defaultHeight={316} origin="settings" />
          </View>
        ),
        contentComponentParentStyle: {
          height: "100%"
        }
      };
    });
  }

  return !isHOAUser ? menuItems : filterObjectsByUserType(menuItems, "HOAUser");
};
