"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecurringTransactionStatus = void 0;
var RecurringTransactionStatus;
(function (RecurringTransactionStatus) {
    /**
     * The recurring charge is expired and will not post any additional charges
     */
    RecurringTransactionStatus["EXPIRED"] = "EXPIRED";
    /**
     * The recurring charge is current, it is actively posting new charges.
     */
    RecurringTransactionStatus["ACTIVE"] = "ACTIVE";
    /**
     * The recurring charge is active, but hasn't posted any charges yet.
     */
    RecurringTransactionStatus["FUTURE"] = "FUTURE";
    /**
     * The recurring charge is active, but hasn't posted any charges yet.
     */
    RecurringTransactionStatus["CANCELED"] = "CANCELED";
})(RecurringTransactionStatus || (exports.RecurringTransactionStatus = RecurringTransactionStatus = {}));
