import type {
  FieldSettingsType,
  MerchantAccountDto,
  RentalApplicationDto,
  RentalApplicationSettingsProps
} from "@doorloop/dto";
import {
  CANADA,
  ProspectStatus,
  regionNames,
  RentalApplicationChargeOptions,
  RentalApplicationFieldSettings,
  ScreeningRequestReportsStatus,
  UNITED_STATES
} from "@doorloop/dto";
import AppStrings from "../locale/keys";
import { useMemo } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import { propertiesApi } from "api/propertiesApi";
import { useTranslation } from "react-i18next";
import { ChipColorsEnum } from "DLUI/chip";
import type { SVGIconComponent } from "@/assets";
import {
  BankAccountIcon,
  DollarBillIcon,
  DollarStrikeIcon,
  MarkedBankAcoount,
  SearchShieldIcon,
  ShieldExclamationIcon
} from "@/assets";

const DEFAULT_TRANSUNION_FEE = "49.90";
const NOT_US_MANDATORY_FIELDS = ["firstName", "lastName", "emailAddress", "jobType"];
export const DEFAULT_REGION = "en-US";
const DEFAULT_CURRENCY = "$";

interface Bullet {
  icon: SVGIconComponent;
  translationKey: string;
}

export interface RadioButtonMap {
  title: string;
  subtitle: string;
  chipProps?: {
    translationKey: string;
    color?: ChipColorsEnum;
  };
  bullets: Bullet[];
  subTitleReplace?: Record<string, string>;
  value: string;
  disabled?: boolean;
  toolTipText?: string;
}

export const noActionsShowScreeningStatuses = [
  ScreeningRequestReportsStatus.READY_FOR_REPORT_REQUEST,
  ScreeningRequestReportsStatus.REPORTS_DELIVERY_IN_PROGRESS,
  ScreeningRequestReportsStatus.REPORTS_DELIVERY_FAILED,
  ScreeningRequestReportsStatus.REPORTS_DELIVERY_SUCCESS,
  ScreeningRequestReportsStatus.IDENTITY_VERIFICATION_PENDING,
  ScreeningRequestReportsStatus.ADDRESS_NOT_VALID
];

export const prospectStatusesBeforeInProgress = [ProspectStatus.NEW, ProspectStatus.APPLICATION_SENT];

const getUsRadioButtonsStrings = (
  currencySymbol?: string,
  shouldDisableCustom?: boolean
): Record<string, RadioButtonMap> => {
  return {
    applicant: {
      title: AppStrings.Common.RentalApplicationEnums.PaymentOptions.ApplicantBoldStart,
      chipProps: {
        translationKey: AppStrings.Common.RecommendedWithIcon,
        color: ChipColorsEnum.INFO
      },
      bullets: [
        {
          icon: MarkedBankAcoount,
          translationKey: AppStrings.Common.NoSetupNeeded
        },
        {
          icon: DollarBillIcon,
          translationKey: AppStrings.Common.ApplicantsWillPay
        },
        {
          icon: SearchShieldIcon,
          translationKey: AppStrings.Common.GetTheScreeningReportsInstantly
        }
      ],
      subtitle: AppStrings.Common.RentalApplicationEnums.PaymentOptions.ApplicantEnd,
      subTitleReplace: {
        screeningFeeAmount: DEFAULT_TRANSUNION_FEE,
        currencySymbol: currencySymbol || DEFAULT_CURRENCY
      },
      value: RentalApplicationChargeOptions.CHARGE_APPLICANT
    },
    custom: {
      title: AppStrings.Common.RentalApplicationEnums.PaymentOptions.CustomBoldStart,
      bullets: [
        {
          icon: BankAccountIcon,
          translationKey: AppStrings.Common.MerchantAccountNeeded
        },
        {
          icon: DollarBillIcon,
          translationKey: AppStrings.Common.SetYourPreferredFee
        },
        {
          icon: SearchShieldIcon,
          translationKey: AppStrings.Common.ChooseWhenToRequest
        }
      ],
      subtitle: AppStrings.Common.RentalApplicationEnums.PaymentOptions.CustomEnd,
      value: RentalApplicationChargeOptions.CHARGE_CUSTOM_FEE,
      disabled: shouldDisableCustom,
      toolTipText: shouldDisableCustom ? AppStrings.Common.RentalApplicationCustomFeeMerchantAccountNote : undefined
    },
    user: {
      title: AppStrings.Common.RentalApplicationEnums.PaymentOptions.UserBoldStart,
      subtitle: AppStrings.Common.RentalApplicationEnums.PaymentOptions.UserEnd,
      value: RentalApplicationChargeOptions.CHARGE_USER,
      bullets: [
        {
          icon: DollarStrikeIcon,
          translationKey: AppStrings.Common.FreeForApplicants
        },
        {
          icon: ShieldExclamationIcon,
          translationKey: AppStrings.Common.ScreeningUponPostFeePayment
        }
      ]
    }
  };
};

const getCanadaRadioButtonsStrings = (shouldDisableCustom?: boolean): Record<string, RadioButtonMap> => {
  return {
    user: {
      title: AppStrings.Common.RentalApplicationEnums.PaymentOptions.ApplicationOnlyPlanStart,
      subtitle: AppStrings.Common.RentalApplicationEnums.PaymentOptions.NoFeeEnd,
      value: RentalApplicationChargeOptions.CHARGE_USER,
      bullets: [
        {
          icon: DollarStrikeIcon,
          translationKey: AppStrings.Common.FreeForApplicants
        },
        {
          icon: ShieldExclamationIcon,
          translationKey: AppStrings.Common.SelectTenantsBasedOnHowSuitableTheyAreForYourProperty
        }
      ]
    },
    custom: {
      title: AppStrings.Common.RentalApplicationEnums.PaymentOptions.CustomBoldStart,
      subtitle: AppStrings.Common.RentalApplicationEnums.PaymentOptions.CustomYourFeeEnd,
      value: RentalApplicationChargeOptions.CHARGE_CUSTOM_FEE,
      disabled: shouldDisableCustom,
      toolTipText: shouldDisableCustom ? AppStrings.Common.RentalApplicationCustomFeeMerchantAccountNote : undefined,
      bullets: [
        {
          icon: BankAccountIcon,
          translationKey: AppStrings.Common.MerchantAccountNeeded
        },
        {
          icon: DollarBillIcon,
          translationKey: AppStrings.Common.SetACustomFeeToGenerate
        },
        {
          icon: SearchShieldIcon,
          translationKey: AppStrings.Common.UseTheFeeAsAFilter
        }
      ]
    }
  };
};

const worldwideRadioButtonsStrings: Record<string, RadioButtonMap> = {
  user: {
    title: AppStrings.Common.RentalApplicationEnums.PaymentOptions.ApplicationOnlyPlanStart,
    subtitle: AppStrings.Common.RentalApplicationEnums.PaymentOptions.NoFeeEnd,
    value: RentalApplicationChargeOptions.CHARGE_USER,
    bullets: [
      {
        icon: DollarStrikeIcon,
        translationKey: AppStrings.Common.FreeForApplicants
      },
      {
        icon: ShieldExclamationIcon,
        translationKey: AppStrings.Common.SelectTenantsBasedOnHowSuitableTheyAreForYourProperty
      }
    ]
  },
  custom: {
    title: AppStrings.Common.RentalApplicationEnums.PaymentOptions.CustomBoldStart,
    subtitle: AppStrings.Common.RentalApplicationEnums.PaymentOptions.CustomYourFeeEnd,
    value: RentalApplicationChargeOptions.CHARGE_CUSTOM_FEE,
    disabled: true,
    bullets: [
      {
        icon: BankAccountIcon,
        translationKey: AppStrings.Common.MerchantAccountNeeded
      },
      {
        icon: DollarBillIcon,
        translationKey: AppStrings.Common.SetACustomFeeToGenerate
      },
      {
        icon: SearchShieldIcon,
        translationKey: AppStrings.Common.UseTheFeeAsAFilter
      }
    ]
  }
};

interface GetLocaleConfiguredRentalAppFieldsSettingsArgs {
  isUs: boolean;
  identityText: string;
}

const getLocaleConfiguredRentalAppFieldsSettings = ({
  isUs,
  identityText
}: GetLocaleConfiguredRentalAppFieldsSettingsArgs) => {
  let RentalApplicationFieldSettingsConfigured = RentalApplicationFieldSettings;

  if (!isUs) {
    RentalApplicationFieldSettingsConfigured = _.cloneDeep(RentalApplicationFieldSettings);

    _.forEach(RentalApplicationFieldSettingsConfigured, (value) => {
      const fieldSection: FieldSettingsType = value?.fields;
      generateLocaleConfiguredRentalAppSectionSettings({ isUs, fieldSection });
    });

    delete RentalApplicationFieldSettingsConfigured.aboutYou.fields.itin;
  }

  if (RentalApplicationFieldSettingsConfigured.aboutYou.fields.ssn) {
    RentalApplicationFieldSettingsConfigured.aboutYou.fields.ssn.label = identityText;
    RentalApplicationFieldSettingsConfigured.aboutYou.fields.ssn.rawLabelText = identityText;
  }

  return RentalApplicationFieldSettingsConfigured;
};

export const generateLocaleConfiguredRentalAppSectionSettings = ({
  isUs,
  fieldSection,
  getAsNewObj
}: {
  isUs: boolean;
  fieldSection: FieldSettingsType;
  getAsNewObj?: boolean;
}) => {
  const fieldSectionConfigured = getAsNewObj ? _.cloneDeep(fieldSection) : fieldSection;
  if (!isUs) {
    _.forEach(fieldSectionConfigured, (field, fieldString) => {
      if (!NOT_US_MANDATORY_FIELDS.includes(fieldString)) {
        if (field) {
          field.isMandatory = false;
        }
      }
    });
  }

  return fieldSectionConfigured;
};

export interface UserLocale {
  isUs: boolean;
  isCanada: boolean;
  isNonNorthAmerica: boolean;
}

export const getUserLocale = (locale?: string): UserLocale => {
  const region = regionNames.find((region) => region.name === locale);
  const isUs = region?.country === UNITED_STATES;
  const isCanada = region?.country === CANADA;
  const isNonNorthAmerica = !isUs && !isCanada;

  return {
    isUs,
    isCanada,
    isNonNorthAmerica
  };
};

export const useRentalApplicationLocale = (merchantAccount?: MerchantAccountDto[]) => {
  const authState = useSelector((state: IRootState) => state.auth);
  const { localeData, currentLoginResponse } = authState;
  const { locale, currencySymbol } = localeData;
  const rentalApplicationOverrideToInternational =
    currentLoginResponse?.currentDbTenant?.rentalApplicationOverrideToInternational;
  const rentalApplicationOverrideToCanada = currentLoginResponse?.currentDbTenant?.rentalApplicationOverrideToCanada;

  const { isCanada: isInCanada, isUs: isInUs, isNonNorthAmerica } = getUserLocale(locale);
  const isUs = isInUs && !rentalApplicationOverrideToInternational && !rentalApplicationOverrideToCanada;
  const isCanada = isInCanada || rentalApplicationOverrideToCanada;

  const { t } = useTranslation();
  const radioButtonsStrings = useMemo(() => {
    if (isUs) {
      return getUsRadioButtonsStrings(currencySymbol, !merchantAccount?.length);
    }
    if (isCanada) {
      return getCanadaRadioButtonsStrings(!merchantAccount?.length);
    }
    return worldwideRadioButtonsStrings;
  }, [locale, merchantAccount?.length, rentalApplicationOverrideToInternational, rentalApplicationOverrideToCanada]);

  const identityText = useMemo(() => {
    if (isUs) {
      return t(AppStrings.Common.IdentityNumberUS);
    }
    if (isCanada) {
      return t(AppStrings.Common.IdentityNumberCA);
    }
    return t(AppStrings.Common.IdentityNumber);
  }, [locale]);

  const rentalApplicationFieldSettings = useMemo<RentalApplicationSettingsProps>(
    () => getLocaleConfiguredRentalAppFieldsSettings({ isUs, identityText }),
    [locale, identityText, rentalApplicationOverrideToInternational, rentalApplicationOverrideToCanada]
  );
  const getLocaleConfiguredRentalAppSectionSettings = (fieldSection: FieldSettingsType, getAsNewObj?: boolean) =>
    generateLocaleConfiguredRentalAppSectionSettings({
      isUs,
      fieldSection,
      getAsNewObj
    });

  const hideActionsBasedOnStatus = (rentalApplicationDto: RentalApplicationDto, prospectStatus) => {
    const prospectPassedNotPassedInProgress = prospectStatusesBeforeInProgress.includes(prospectStatus);
    const dontShowCta = isUs
      ? !prospectPassedNotPassedInProgress ||
        noActionsShowScreeningStatuses.includes(
          rentalApplicationDto.transunionInfo?.screeningRequestReportsStatus as ScreeningRequestReportsStatus
        )
      : !prospectPassedNotPassedInProgress;

    return dontShowCta;
  };

  return {
    identityText,
    radioButtonsStrings,
    isUs,
    isCanada,
    isNonNorthAmerica,
    rentalApplicationFieldSettings,
    getLocaleConfiguredRentalAppSectionSettings,
    hideActionsBasedOnStatus
  };
};

export const useRentalApplicationValidations = (propertyId?: string, tenantId?: string) => {
  const authState = useSelector((state: IRootState) => state.auth);
  const { localeData } = authState;
  const { locale } = localeData;
  const { isUs } = getUserLocale(locale);

  const { transunionCompanyId, transunionPropertyId } = useMemo(() => {
    let transunionPropertyId;

    if (propertyId) {
      const propertyDictionary = propertiesApi.getItemFromDictionary(propertyId);
      if (propertyDictionary?.transunionPropertyId) {
        transunionPropertyId = propertyDictionary?.transunionPropertyId;
      }
    }

    const transunionCompanyId = authState.currentLoginResponse?.currentDbTenant.transunionLandlordId;

    return { transunionCompanyId, transunionPropertyId };
  }, [propertyId, authState]);

  const isTransunionCompanyDisabled = isUs && !transunionCompanyId;
  const isTransunionPropertyDisabled = isUs && !transunionPropertyId && !tenantId;

  return {
    isTransunionCompanyDisabled,
    isTransunionPropertyDisabled
  };
};
