import { useTheme } from "@material-ui/core";
import { LoadingAnimation } from "../DLUI/animations/loadingAnimation";

export const ScreenLoader = () => {
  const theme = useTheme();

  return (
    <div
      style={{
        width: "100dvw",
        height: "100dvh",
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.screenBackground.main
      }}
    >
      <LoadingAnimation />
    </div>
  );
};
