import React from "react";
import type { PersonalInfoSettingsDto } from "@doorloop/dto";
import { settingsApi } from "api/settingsApi";
import type { DialogState } from "DLUI/dialogs/loadingDialog";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import * as authActions from "store/auth/actions";
import { store } from "../../../../store";
import { SettingsAnimatedContentDialog, SettingsContentTypes } from "../settingsAnimatedContentDialog";
import { initFormValues, validateForm, validatePersonalInfoForm } from "./formikHelper";
import { getPersonalInfoFormMenuItems } from "./menuItems";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";

interface ComponentProps {
  dialogState: DialogState;
  onClose: () => void;
  onBackdropClick: () => void;
  title?: string;
}

const PersonalInformationDialog: React.FC<ComponentProps> = ({
  dialogState,
  onClose,
  onBackdropClick,
  title
}: ComponentProps) => {
  const { t } = useTranslation();
  const personalInfoDialogItems = getPersonalInfoFormMenuItems();
  const _onClose = (values: PersonalInfoSettingsDto) => {
    store.dispatch(authActions.updateUserInformation(values));
    onClose();
  };

  return (
    <SettingsAnimatedContentDialog
      dialogState={dialogState}
      onClose={_onClose}
      sectionItems={personalInfoDialogItems}
      disableBackdropClick
      onBackdropClick={onBackdropClick}
      getformikInitialValues={initFormValues}
      formikValidation={validateForm}
      validationMethod={validatePersonalInfoForm}
      loadingText={t(AppStrings.Settings.GeneralSettings.UpdatingUserInformation)}
      errorText={t(AppStrings.Settings.GeneralSettings.FailedToLoadUserInformation)}
      successText={t(AppStrings.Settings.GeneralSettings.UpdatingUserInformationSuccessText)}
      getApiMethod={settingsApi.getPersonalInformation}
      updateApiMethod={settingsApi.updatePersonalInformation}
      dialogTitle={title || ""}
      dialogHeight={getDialogFrameDimension("height", 640)}
      type={SettingsContentTypes.PersonalInformation}
    />
  );
};

export default PersonalInformationDialog;
