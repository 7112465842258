import React, { Fragment, useEffect, useMemo, useState } from "react";
import type { ObjectPermission, RecurringTransactionDto, TaskDto } from "@doorloop/dto";
import {
  DataCy,
  LinkedResourceType,
  PostTaskUpdateDto,
  TaskStatus,
  TaskType,
  TaskViewObjectAccess
} from "@doorloop/dto";
import { ownersApi } from "api/ownersApi";
import { tenantsApi } from "api/tenantsApi";
import { usersApi } from "api/usersApi";
import {
  CalendarIconBlue,
  ClockOrangeIcon,
  CloseIcon,
  EditBlue,
  MaintenanceIcon,
  OwnersIcon,
  TenantsIcon,
  ViewBlueIcon,
  WorkOrderIcon
} from "assets/icons";
import { ReferenceLabel } from "DLUI/form";
import { Icon } from "DLUI/icon";
import { Grid, ListItemContainer, ListItemIcon, ListItemSection } from "DLUI/listItems";
import type { PopoverItem } from "DLUI/popover";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { NavigationManager } from "utils/navigation";
import { usePermission } from "screens/settings/userRoles/usePermission";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { getTaskPermissionByType } from "screens/settings/userRoles/clearanceTypes";
import type { SVGIconComponent } from "assets/icons/types";

import {
  DefaultListItemFontSize,
  DefaultListItemIconSize,
  DefaultListStatusIconSize
} from "screens/leases/leases/leasesList/leaseListItem";

import { getTaskStatusUI } from "screens/tasks/tasksList/getTaskStatusUI";
import ListItemText from "DLUI/listItems/listItemText";
import RelatedToGridColumn from "screens/tasks/tasksList/relatedToGridColumn";
import { Routes } from "../../../appRouter";
import { ProgressDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { tasksApi } from "api/tasksApi";
import { DateFormats } from "@doorloop/dto";
import { getMetaDataByTransactionType } from "screens/leases/leases/leaseDetails/leaseRent/textsData.map";
import { getRecurringStatusData } from "screens/leases/leases/leaseDetails/leaseRent/statusData.map";
import { PriorityIndicator } from "screens/tasks/tasksList/priorityIndicator";
import { useIntl } from "react-intl";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { TaskAssignees } from "./taskAssignees";
import { useRequestStatusesMap } from "../../tenantPortal/requests/hooks/useRequestStatusesMap";
import { useConfirmationDialog } from "@/hooks/useConfirmationDialog";

interface ComponentProps {
  taskDto: TaskDto;
  hideRelatedToColumn?: boolean;
  onItemPress?: (taskId: string) => void;
  stickyHeaderId?: string;
  disableListItemOptions?: boolean;
  refreshScreen?: () => void;
  recurring?: RecurringTransactionDto;
  variant?: string;
}

const getViewOneClearances = (taskPermission: ObjectPermission): AnyPermissionClearance[] => [
  {
    permission: taskPermission,
    field: "viewOne",
    requiredLevel: TaskViewObjectAccess.ASSIGNED_ONLY
  },
  {
    permission: taskPermission,
    field: "viewOne",
    requiredLevel: TaskViewObjectAccess.ALL
  }
];

const TaskListItem: React.FC<ComponentProps> = ({
  taskDto,
  hideRelatedToColumn,
  onItemPress,
  stickyHeaderId,
  disableListItemOptions,
  refreshScreen,
  recurring,
  variant
}: ComponentProps) => {
  const { isOnlyMobile, isSize, isDesktop, isOnlyTablet } = useResponsiveHelper();
  const { showConfirmationDialog } = useConfirmationDialog();
  const location = useLocation();
  const [listIcon, setListIcon] = useState<SVGIconComponent | undefined>();
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const [itemTypeText, setItemTypeText] = useState<string | undefined>();
  const [requestedByName, setRequestedByName] = useState<string | undefined>();
  const { t } = useTranslation();
  const { hasAnyPermission } = usePermission();
  const taskPermission = getTaskPermissionByType(taskDto.type);
  const viewOneClearances = getViewOneClearances(taskPermission);
  const { isMobile } = useResponsiveHelper();
  const { formatRelativeTime } = useIntl();
  const { getMaintenanceTypeLabel, getTaskTypeLabel } = useRequestStatusesMap();

  useEffect(() => {
    initItemData();
  }, []);

  const daysFromCreation = useMemo(() => {
    const taskCreationDate = moment(recurring ? recurring.start : taskDto.createdAt);
    const duration = moment.duration(taskCreationDate.diff(moment()));
    return formatRelativeTime(Math.ceil(duration.asDays()), "day", {
      numeric: "auto"
    });
  }, []);

  const { StatusIcon, statusTextColor, statusText } = recurring
    ? getRecurringStatusData(recurring.status, recurring.start, recurring.end)
    : getTaskStatusUI(taskDto.status);

  const frequency = recurring ? getMetaDataByTransactionType(recurring).frequency : "";

  const didPressEditButton = () => {
    if (recurring && recurring.id) {
      if (taskDto.type === TaskType.INTERNAL_TASK) {
        NavigationManager.editRecurringTask(location.pathname, recurring.id);
      } else if (taskDto.type === TaskType.WORK_ORDER) {
        NavigationManager.editRecurringWorkOrder(location.pathname, recurring.id);
      }
    } else if (taskDto.type === TaskType.INTERNAL_TASK) {
      NavigationManager.editInternalTask(location.pathname, taskDto.id!);
    } else if (taskDto.type === TaskType.OWNER_REQUEST) {
      NavigationManager.editOwnerRequest(location.pathname, taskDto.id!);
    } else if (taskDto.type === TaskType.TENANT_REQUEST) {
      NavigationManager.editTenantRequest(location.pathname, taskDto.id!);
    } else if (taskDto.type === TaskType.WORK_ORDER) {
      NavigationManager.editWorkOrder(location.pathname, taskDto.id!);
    }
  };

  const showRelatedToColumn = hideRelatedToColumn === undefined || !hideRelatedToColumn;

  const didPressDeleteButton = () => {
    showConfirmationDialog(location.pathname, {
      dialogTitle: AppStrings.Common.DeleteConfirmation,
      dialogSubTitle: AppStrings.Tasks.TasksList.DeleteConfirmationText,
      loadingText: t(AppStrings.Tasks.TasksList.DeleteTaskLoadingText),
      successText: t(AppStrings.Tasks.TasksList.DeleteTaskSuccessText),
      confirmButtonText: AppStrings.Common.Delete,
      dismissButtonText: AppStrings.Common.Dismiss,
      apiMethod: recurring ? "leasesRecurringTransactionsApi" : "tasksApi",
      confirmOperation: "delete",
      itemId: recurring ? recurring.id : taskDto.id
    });
  };

  const didPressCompleteTask = async () => {
    setLoadingDialogState(DialogState.Show);
    const postTaskUpdateDto = new PostTaskUpdateDto({
      ...taskDto,
      taskId: taskDto.id,
      status: TaskStatus.COMPLETED
    });
    const { status, message } = await tasksApi.postUpdate(postTaskUpdateDto, {
      translationKey: AppStrings.Toasts.custom.tasks.complete?.POST
    });
    if (status && refreshScreen) {
      refreshScreen();
    } else {
      setLoadingDialogState(DialogState.Error);
      setLoadingDialogErrorText(message || t(AppStrings.Common.GeneralError));
    }
  };

  const didPressViewTask = hasAnyPermission(viewOneClearances)
    ? () => {
        if (recurring) {
          didPressEditButton();
        } else if (onItemPress) {
          onItemPress(taskDto.id!);
        } else {
          NavigationManager.viewTaskDetails(taskDto.id!);
        }
      }
    : undefined;

  const itemOptionsButtons: PopoverItem[] = [
    {
      Icon: EditBlue,
      title: AppStrings.Tasks.TasksList.EditTask,
      onClick: didPressEditButton,
      clearance: { permission: taskPermission, field: "edit" },
      dataCy: DataCy.DLUI.listItem.listItemEdit
    }
  ];
  if (hasAnyPermission(viewOneClearances) && !recurring) {
    itemOptionsButtons.push({
      Icon: ViewBlueIcon,
      title: AppStrings.Tasks.TasksList.ViewTask,
      onClick: didPressViewTask,
      showSeparator: taskDto.status === TaskStatus.COMPLETED,
      dataCy: DataCy.DLUI.listItem.listItemView
    });
  }
  if (taskDto.status !== TaskStatus.COMPLETED && !recurring) {
    itemOptionsButtons.push({
      Icon: MaintenanceIcon,
      title: AppStrings.Tasks.Screen.CompleteTask,
      onClick: didPressCompleteTask,
      clearance: { permission: taskPermission, field: "edit" },
      showSeparator: true,
      dataCy: DataCy.DLUI.listItem.listItemCompleteTask
    });
  }
  itemOptionsButtons.push({
    Icon: CloseIcon,
    title: AppStrings.Tasks.TasksList.DeleteTask,
    onClick: didPressDeleteButton,
    textColor: "error",
    iconPathColor: "error",
    clearance: { permission: taskPermission, field: "delete" },
    dataCy: DataCy.DLUI.listItem.listItemDelete
  });

  const getTenantNameFromDictionary = () => {
    let apiMethod = tenantsApi;
    switch (taskDto.type) {
      case TaskType.INTERNAL_TASK:
      case TaskType.WORK_ORDER: {
        return usersApi.getItemFromDictionary(taskDto.requestedByUser!)?.name;
      }
      case TaskType.TENANT_REQUEST:
        apiMethod = tenantsApi;
        return tenantsApi.getItemFromDictionary(taskDto.requestedByTenant!)?.name;
      case TaskType.OWNER_REQUEST:
        return ownersApi.getItemFromDictionary(taskDto.requestedByOwner!)?.name;
    }
    return "";
  };

  const initItemData = () => {
    let listIcon = TenantsIcon;
    let itemTypeText = AppStrings.Tasks.TasksList.TenantRequest;
    const requestedByName = getTenantNameFromDictionary();
    switch (taskDto.type) {
      case TaskType.INTERNAL_TASK:
        listIcon = MaintenanceIcon;
        itemTypeText = AppStrings.Tasks.TasksList.InternalTask;

        break;
      case TaskType.OWNER_REQUEST:
        listIcon = OwnersIcon;
        itemTypeText = AppStrings.Tasks.TasksList.OwnerRequest;
        break;
      case TaskType.WORK_ORDER:
        listIcon = WorkOrderIcon;
        itemTypeText = AppStrings.Tasks.InternalTaskDialog.WorkOrder;
        break;
    }

    setListIcon(listIcon);
    setItemTypeText(itemTypeText);
    setRequestedByName(requestedByName);
  };

  if (!listIcon) {
    return null;
  }

  const smValue = isOnlyMobile ? 12 : undefined;
  const hideLabels = (isSize("md") && isDesktop) || (isSize("sm") && isOnlyTablet);

  const isRenderedOnTenantRequestPage = taskDto.type === TaskType.TENANT_REQUEST && variant === "tenantRequests";

  return (
    <>
      <ListItemContainer
        popoverWidth={200}
        popoverItems={disableListItemOptions ? undefined : itemOptionsButtons}
        id={stickyHeaderId}
      >
        <Grid
          title={AppStrings.Tasks.TasksList.Task}
          showDivider
          xs={12}
          sm={smValue}
          md={isRenderedOnTenantRequestPage ? 2 : 4}
          lg={isRenderedOnTenantRequestPage ? 2 : 4}
          onClick={didPressViewTask}
          sortColumn={"subject"}
        >
          <View alignItems={"center"} flexDirection={"row"} noWrap>
            {!isRenderedOnTenantRequestPage && <ListItemIcon Icon={listIcon} />}
            <View flex={1} alignItems={"center"} noWrap>
              <View flexDirection={"row"} alignItems={"center"} noWrap>
                <ListItemText
                  lineHeight={"18px"}
                  value={itemTypeText}
                  color={"green"}
                  size={"sm"}
                  overflow={"visible"}
                  marginRight={20}
                />
                {!hideLabels && <PriorityIndicator value={taskDto.priority} />}
              </View>
              <View flexDirection={"row"} alignItems={"center"}>
                <View flex={1} flexDirection={"row"}>
                  <ListItemText
                    lineHeight={"18px"}
                    href={taskDto.id ? `${Routes.TASKS}/${taskDto.id}/overview` : undefined}
                    bold
                    color={"black"}
                  >
                    {taskDto.subject}
                  </ListItemText>
                </View>
                {showRelatedToColumn && !hideLabels && (
                  <View
                    justifyContent={"center"}
                    alignItems={isMobile ? "center" : "flex-end"}
                    height={15}
                    autoWidth
                    flex={1}
                    noWrap
                    // flexDirection={"row"}
                  >
                    <ReferenceLabel backgroundColor={"dark"} value={taskDto.reference} />
                  </View>
                )}
              </View>
              {requestedByName && (
                <View flexDirection={"row"}>
                  <ListItemText
                    lineHeight={"18px"}
                    color={"gray"}
                    overFlow={"ellipsis"}
                    numberOfLines={1}
                    value={AppStrings.Common.Recurring.ByXFromCreation}
                    replaceObject={{ x: requestedByName || "", daysFromCreation: !recurring ? daysFromCreation : "" }}
                  />
                </View>
              )}
            </View>
            {!showRelatedToColumn && (
              <ReferenceLabel backgroundColor={"dark"} marginRight={20} value={taskDto.reference} />
            )}
          </View>
        </Grid>
        {isRenderedOnTenantRequestPage && (
          <Grid
            title={AppStrings.Common.RequestType}
            showDivider
            xs={12}
            sm={smValue}
            md={2}
            lg={2}
            onClick={didPressViewTask}
          >
            <View>
              <Text fontSize={14} marginBottom={4}>
                {getTaskTypeLabel(taskDto?.tenantRequestType) || t(AppStrings.Common.NotApplicable)}
              </Text>
              {Boolean(taskDto?.tenantRequestMaintenanceCategory) && (
                <Text fontSize={14} color="secondary-gray">
                  {getMaintenanceTypeLabel(taskDto?.tenantRequestMaintenanceCategory)}
                </Text>
              )}
            </View>
          </Grid>
        )}
        <Grid
          title={AppStrings.Tasks.TasksList.assignedTo}
          showDivider
          xs={12}
          sm={smValue}
          md={2}
          lg={2}
          onClick={didPressViewTask}
        >
          <View flexDirection={"row"}>
            <TaskAssignees taskDto={taskDto} />
          </View>
        </Grid>
        {recurring ? (
          <Grid
            title={AppStrings.Leases.NewLease.LeaseRent.Frequency}
            showDivider
            xs={12}
            sm={smValue}
            md={2}
            lg={2}
            onClick={didPressViewTask}
          >
            <ListItemSection
              renderTitle={
                <Fragment>
                  <View flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} noWrap>
                    <Icon
                      Source={CalendarIconBlue}
                      marginRight={10}
                      pathColor={"black"}
                      height={DefaultListItemIconSize}
                      width={DefaultListItemIconSize}
                    />

                    <Text color={"black"} align="left" fontSize={DefaultListItemFontSize} value={frequency} />
                  </View>
                </Fragment>
              }
              renderSubTitle={
                <View justifyContent={"flex-start"} alignItems={"center"} flexDirection={"row"} noWrap>
                  <Icon
                    circleFill={"white"}
                    pathColor={"black"}
                    Source={ClockOrangeIcon}
                    marginLeft={-5}
                    width={30}
                    height={30}
                  />
                  <View noWrap>
                    <Text
                      marginLeft={5}
                      fontSize={DefaultListItemFontSize}
                      color={"black"}
                      value={AppStrings.Common.fromDASHto}
                      replaceObject={{
                        from: moment(recurring.start).format(DateFormats.SHORT_DATE),
                        to: recurring.noEndDate
                          ? t(AppStrings.Common.Recurring.Forever).toString()
                          : moment(recurring.end).format(DateFormats.SHORT_DATE)
                      }}
                    />
                  </View>
                </View>
              }
            />
          </Grid>
        ) : (
          <Grid
            title={AppStrings.Tasks.TasksList.DueAt}
            showDivider
            xs={12}
            sm={smValue}
            md={2}
            lg={2}
            onClick={didPressViewTask}
            sortColumn={"dueDate"}
          >
            {taskDto.dueDate ? (
              <ListItemSection
                renderTitle={
                  <Fragment>
                    <View flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"}>
                      <Icon
                        Source={CalendarIconBlue}
                        marginRight={10}
                        pathColor={"black"}
                        height={DefaultListItemIconSize}
                        width={DefaultListItemIconSize}
                      />

                      <Text
                        color={"black"}
                        align="left"
                        fontSize={DefaultListItemFontSize}
                        value={taskDto.dueDate}
                        formatType={"date"}
                      />
                    </View>
                  </Fragment>
                }
                renderSubTitle={
                  <View justifyContent={"flex-start"} alignItems={"center"} flexDirection={"row"}>
                    <Icon
                      circleFill={"white"}
                      pathColor={"black"}
                      Source={ClockOrangeIcon}
                      marginLeft={-5}
                      width={30}
                      height={30}
                    />
                    <Text marginLeft={5} fontSize={DefaultListItemFontSize} color={"black"}>
                      {moment(taskDto.dueDate).format(DateFormats.DAY_OF_WEEK)}
                    </Text>
                  </View>
                }
              />
            ) : (
              <Text fontSize={DefaultListItemFontSize} value={AppStrings.Tasks.TasksList.NotSet} />
            )}
          </Grid>
        )}
        {showRelatedToColumn && (
          <RelatedToGridColumn
            onClick={didPressViewTask}
            shouldShow={showRelatedToColumn}
            unit={taskDto.unit}
            property={taskDto.property}
            tenant={
              taskDto.linkedResource?.resourceType === LinkedResourceType.Tenant
                ? taskDto.linkedResource.resourceId
                : undefined
            }
            sm={smValue}
          />
        )}

        {recurring ? (
          <Grid title={AppStrings.Leases.WizardSteps.Status} xs={12} sm={smValue} md={2} lg={2}>
            <View alignItems={"center"} flexDirection={"row"}>
              <StatusIcon width={DefaultListStatusIconSize} />
              <Text
                value={statusText}
                color={statusTextColor}
                fontWeight={700}
                fontSize={DefaultListItemFontSize}
                marginLeft={5}
              />
            </View>
          </Grid>
        ) : (
          <Grid title={AppStrings.Common.FilterOptions.Status} xs={12} sm={smValue} md={2} lg={2} sortColumn={"status"}>
            {taskDto.status ? (
              <View height={"100%"} flexDirection={"row"} alignItems={"center"}>
                <View height={"100%"} alignItems={"center"} flexDirection={"row"} onClick={didPressViewTask} flex={1}>
                  <Icon width={DefaultListStatusIconSize} Source={StatusIcon} />
                  <Text
                    marginLeft={10}
                    marginRight={10}
                    color={statusTextColor}
                    fontSize={DefaultListItemFontSize}
                    value={statusText}
                    fullWidth
                  />
                </View>
              </View>
            ) : null}
          </Grid>
        )}
      </ListItemContainer>
      {loadingDialogState !== DialogState.Hidden && (
        <ProgressDialog
          dialogState={loadingDialogState}
          errorText={loadingDialogErrorText}
          onRetryButtonPress={didPressCompleteTask}
        />
      )}
    </>
  );
};

export default TaskListItem;
