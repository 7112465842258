import { propertiesApi } from "api/propertiesApi";
import type { StaticRemoteOrDateFilter } from "DLUI/screen/filterPanel/filterPanel";
import AppStrings from "locale/keys";
import { PropertyStatus } from "@doorloop/dto";
import type { GetAllBankAccountsQuery } from "@doorloop/dto";

const FilterOptions: Array<StaticRemoteOrDateFilter<GetAllBankAccountsQuery>> = [
  {
    name: AppStrings.Common.FilterOptions.Property,
    apiHandler: propertiesApi,
    displayNameKey: "name",
    filterFieldName: "filter_property",
    filterFieldValue: "id",
    type: "remoteFiltering"
  },
  {
    type: "staticFiltering",
    name: AppStrings.Common.FilterOptions.Status,
    values: [
      {
        displayName: AppStrings.Properties.Status.Active,
        value: PropertyStatus.ACTIVE,
        filterFieldName: "filter_active"
      },
      {
        displayName: AppStrings.Properties.Status.Inactive,
        value: PropertyStatus.InACTIVE,
        filterFieldName: "filter_active"
      }
    ]
  },
  {
    type: "staticFiltering",
    name: AppStrings.Common.FilterOptions.ActionRequired,
    alwaysVisible: false,
    values: [
      {
        displayName: AppStrings.Common.Active,
        value: "true",
        filterFieldName: "filter_actionRequired"
      },
      {
        displayName: AppStrings.Common.Inactive,
        value: "false",
        filterFieldName: "filter_actionRequired"
      }
    ]
  }
];

export default FilterOptions;
