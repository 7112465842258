import React, { useMemo } from "react";

import moment from "moment";

import type { PushNotificationDto } from "@doorloop/dto";
import { DataCy } from "@doorloop/dto";

import ColorsEnum from "../../../../utils/colorsEnum";
import makeStyles from "../styles";
import Text from "DLUI/text";
import type { NotificationItemDto, NotificationItemsServiceType } from "utils/notifications";
import { pushNotificationTypesMap } from "utils/notifications";
import { Icon } from "DLUI/icon";
import { View } from "DLUI/view";

interface ComponentProps {
  data: PushNotificationDto;
  onClick: (dto: PushNotificationDto) => void;
}

const NotificationItem = ({ data, onClick }: ComponentProps) => {
  const { notificationItemWrapper, unopenedIndicator, title, description, placeholder, timestamp } = makeStyles({});
  const { isOpened, serviceType } = data;

  const itemData: NotificationItemDto | undefined = useMemo(() => {
    if (!serviceType) {
      return;
    }

    const getItem = pushNotificationTypesMap[serviceType as NotificationItemsServiceType];

    if (!getItem) {
      return;
    }

    return getItem(data);
  }, []);

  return (
    <View paddingLeft={10} paddingRight={10} onClick={() => onClick(data)}>
      <View
        flexDirection={"row"}
        noWrap
        gap={10}
        className={notificationItemWrapper}
        paddingLeft={10}
        paddingRight={10}
        paddingBottom={10}
        paddingTop={10}
        borderRadius={5}
      >
        <View
          autoWidth
          alignSelf={"flex-start"}
          backgroundColorEnum={itemData?.iconBgColor || ColorsEnum.LightGray}
          style={{ padding: 5 }}
          borderRadius={5}
        >
          {itemData?.icon && (
            <Icon
              width={"auto"}
              size={25}
              Source={itemData.icon}
              pathColor={itemData?.iconPathColor ?? "light-gray"}
              className={itemData?.className ?? ""}
            />
          )}
        </View>

        <View flex={1} gap={3}>
          <View flexDirection={"row"} justifyContent={"space-between"} gap={5} noWrap>
            <Text
              className={title}
              bold={!isOpened}
              fontSize={14}
              value={itemData?.title}
              overFlow={"ellipsis"}
              lineBreak={"auto"}
              numberOfLines={2}
              dataCy={DataCy.DLUI.notifications.title}
            />

            <View flexDirection={"row"} noWrap autoWidth alignItems={"center"} gap={3}>
              <Text className={timestamp} fontSize={12} value={moment(data.createdAt).fromNow()} />
              {!isOpened && <div className={unopenedIndicator} data-cy={DataCy.DLUI.notifications.unopenedIndicator} />}
            </View>
          </View>
          {itemData?.placeholder && (
            <Text
              className={placeholder}
              fontSize={12}
              value={itemData.placeholder}
              overFlow={"ellipsis"}
              numberOfLines={1}
            />
          )}
          {itemData?.description && (
            <Text
              className={description}
              fontSize={14}
              overFlow={"ellipsis"}
              numberOfLines={2}
              lineBreak={"auto"}
              value={itemData.description}
            />
          )}
        </View>
      </View>
    </View>
  );
};

export default NotificationItem;
