import React, { useEffect, useMemo, useRef, useState } from "react";
import { View } from "DLUI/view";
import { FastField, getIn, useFormikContext } from "formik";
import { UserStatus } from "@doorloop/dto";
import AppStrings from "../../../../../locale/keys";
import Text from "DLUI/text";
import { FormikSwitchButton, RadioGroup } from "DLUI/form";
import { AnimatedView } from "DLUI/view/animatedView";
import { usersApi } from "api/usersApi";
import { useTranslation } from "react-i18next";
import { SeparationLine } from "DLUI/separatorView";
import { useParams } from "react-router-dom";
import FormikCachedMultiSelectAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedMultiSelectAutoComplete";
import { store } from "store/index";

const FORMIK_KEY_NOTIFY_SUBMITTED_USERS = "notifications.notifyWhenApplicationSubmittedUsers";
const FORMIK_KEY_NOTIFY_WHEN_SUBMITTED = "notifications.notifyWhenApplicationSubmitted";

const Notifications: React.FC = () => {
  const { t } = useTranslation();
  const { propertyId } = useParams<any>();
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const [notificationsSelectionType, setNotificationsSelectionType] = useState<
    "companyDefault" | "specifyNotificationsInfo"
  >(() =>
    getIn(values, FORMIK_KEY_NOTIFY_SUBMITTED_USERS) || !propertyId ? "specifyNotificationsInfo" : "companyDefault"
  );

  const shouldRenderForm = useMemo(
    () => notificationsSelectionType === "specifyNotificationsInfo" || !propertyId,
    [notificationsSelectionType]
  );
  const showUserSelection = useMemo(() => Boolean(getIn(values, FORMIK_KEY_NOTIFY_WHEN_SUBMITTED)), [values]);
  const shouldSetDefaultNotificationUsersRef = useRef(true);

  const didChangeSpecifyNotificationsSelection = (selectedValue: string) => {
    setFieldValue("notifications.isShown", selectedValue === "true");

    if (selectedValue === "false") {
      setNotificationsSelectionType("companyDefault");
      setFieldValue(FORMIK_KEY_NOTIFY_SUBMITTED_USERS, undefined);
      setTimeout(() => {
        setFieldValue("notifications", undefined);
      }, 500);
    } else {
      setNotificationsSelectionType("specifyNotificationsInfo");

      if (propertyId) {
        setFieldValue(FORMIK_KEY_NOTIFY_WHEN_SUBMITTED, false);
      }
    }

    shouldSetDefaultNotificationUsersRef.current = true;
  };

  useEffect(() => {
    if (showUserSelection) {
      const currentUserId = store.getState().auth.currentLoginResponse?.id;
      const users = getIn(values, FORMIK_KEY_NOTIFY_SUBMITTED_USERS);

      if (users?.length) {
        setFieldValue(FORMIK_KEY_NOTIFY_SUBMITTED_USERS, users);
      } else if (currentUserId && shouldSetDefaultNotificationUsersRef.current) {
        setFieldValue(FORMIK_KEY_NOTIFY_SUBMITTED_USERS, [currentUserId]);
      }

      shouldSetDefaultNotificationUsersRef.current = false;
    } else {
      setFieldTouched(FORMIK_KEY_NOTIFY_SUBMITTED_USERS, false);
      setFieldValue(FORMIK_KEY_NOTIFY_SUBMITTED_USERS, undefined);
    }
  }, [showUserSelection]);

  const renderSpecifyRadioButtons = () => {
    if (propertyId) {
      return (
        <View>
          <RadioGroup
            didChangeSelection={didChangeSpecifyNotificationsSelection}
            uniqueKey={"FRB"}
            defaultValue={notificationsSelectionType === "companyDefault" ? "false" : "true"}
            marginTop={20}
            radioGroupItems={[
              {
                label: AppStrings.Common.HideSectionForProperty,
                value: "false"
              },
              {
                label: AppStrings.Common.SpecifySettingsForProperty,
                value: "true"
              }
            ]}
          />
          <SeparationLine marginTop={20} width={"100%"} height={1} />
        </View>
      );
    }
  };

  return (
    <View width={"100"} flex={1}>
      <Text
        value={AppStrings.Settings.GeneralSettings.RentalApplications.NotificationsDescription}
        lineHeight={"26px"}
      />
      {renderSpecifyRadioButtons()}
      <AnimatedView shouldShow={notificationsSelectionType === "specifyNotificationsInfo"}>
        {shouldRenderForm && (
          <>
            <FastField
              component={FormikSwitchButton}
              name={FORMIK_KEY_NOTIFY_WHEN_SUBMITTED}
              label={AppStrings.Settings.GeneralSettings.RentalApplications.NotifyUsersWhenSubmitted}
              marginTop={20}
            />
            <View minHeight={60}>
              <AnimatedView shouldShow={showUserSelection}>
                <FormikCachedMultiSelectAutoComplete
                  uniqueIndex={"notifyWhenApplicationSubmittedUsers"}
                  apiHandler={usersApi}
                  displayNameKey={"name"}
                  filterFieldName={"filter_text"}
                  filterFieldValue={"name"}
                  selectionFields={["id", "class"]}
                  queryParams={{ filter_status: UserStatus.ACTIVE }}
                  name={FORMIK_KEY_NOTIFY_SUBMITTED_USERS}
                  label={t(AppStrings.Common.SelectUsers)}
                  marginTop={20}
                  errorLabelPaddingLeft={15}
                />
                <View height={20} />
              </AnimatedView>
            </View>
          </>
        )}
      </AnimatedView>
    </View>
  );
};

export default Notifications;
