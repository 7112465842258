import React, { useState } from "react";
import type { PropertyDto } from "@doorloop/dto";
import { propertiesApi } from "api/propertiesApi";
import { useEffectAsync } from "../../../../hooks/useEffectAsync";
import { EntitySelector, EntityType } from "DLUI/dialogs/components/entitySelector";

interface ComponentProps {
  propertyId?: string;
  didPressSelectProperty?: () => void;
  errorText?: string;
}

const PropertySelection: React.FC<ComponentProps> = ({
  propertyId,
  didPressSelectProperty,
  errorText
}: ComponentProps) => {
  const [loadingInProgress, setLoadingInProgress] = useState<boolean>(false);
  const [selectedProperty, setSelectedProperty] = useState<PropertyDto | undefined>();

  const onSelectPropertyClick = () => {
    didPressSelectProperty?.();
  };

  useEffectAsync(async () => {
    if (!propertyId) return;

    setLoadingInProgress(true);
    const propertyCached = (await propertiesApi.getItemFromDictionary(propertyId)) as PropertyDto;
    setLoadingInProgress(false);

    if (!propertyCached.address) {
      return;
    }

    setSelectedProperty(propertyCached);
  }, [propertyId]);

  return (
    <EntitySelector
      type={EntityType.PROPERTY}
      onClick={onSelectPropertyClick}
      isLoading={loadingInProgress}
      valueText={selectedProperty?.name ?? ""}
      error={errorText}
    />
  );
};

export default PropertySelection;
