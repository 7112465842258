import type { FC } from "react";
import React, { useState } from "react";
import { DialogFrame } from "DLUI/dialogs";
import AppStrings from "locale/keys";
import { communicationsApi } from "api/communicationsApi";
import { useParams } from "react-router-dom";
import type { CommunicationDto } from "@doorloop/dto";
import { CommunicationPreview } from "screens/communications/communicationDialog/communicationPreview";
import { filesApi } from "api/filesApi";
import { LinkedResourceType } from "@doorloop/dto";
import type { FileDto } from "@doorloop/dto";
import DeleteConfirmation from "DLUI/dialogs/components/deleteConfirmation";
import { SimpleLoading } from "DLUI/loading/simpleLoading";
import { buildRenderView } from "DLUI/dialogs/components/dialogFrame";
import { useTranslation } from "react-i18next";
import { useEffectAsync } from "../../../../hooks/useEffectAsync";

interface Props {
  onBackdropClick: () => void;
}

export const ViewCommunicationDialog: FC<Props> = ({ onBackdropClick }: Props) => {
  const { t } = useTranslation();
  const [errorText, setErrorText] = useState<string>("");
  const [communicationData, setCommunicationData] = useState<CommunicationDto>();
  const [communicationFiles, setCommunicationFiles] = useState<FileDto[]>([]);
  const [currentView, setCurrentView] = useState<number>(0);
  const { communicationId } = useParams<{
    communicationId: string;
  }>();
  const fetchData = async () => {
    const { status, data, message } =
      (await communicationsApi.get(communicationId).catch((err) => setErrorText(err))) || {};
    if (status && data) {
      if (data.announcement) {
        const { data: files } =
          (await filesApi
            .getAll({
              filter_resourceId: data.announcement,
              filter_resourceType: LinkedResourceType.Announcement
            })
            .catch(() => setErrorText("Failed fetching attached files"))) || {};
        if (files?.data) {
          setCommunicationFiles(files.data);
        }
      }
      setCurrentView(1);
      setCommunicationData(data);
    } else {
      setErrorText(message || t(AppStrings.Common.GeneralError));
    }
  };

  const { renderView } = buildRenderView([
    () => <SimpleLoading errorText={errorText} retryHandler={fetchData} dismissHandler={onBackdropClick} />,
    () =>
      communicationData ? (
        <CommunicationPreview
          dto={communicationData}
          files={communicationFiles}
          deleteHandler={() => setCurrentView(2)}
          onBackdropClick={onBackdropClick}
        />
      ) : null,
    () => (
      <DeleteConfirmation
        apiMethod={communicationsApi}
        didPressDismissButton={() => setCurrentView(1)}
        didFinishOperation={onBackdropClick}
        transactionId={communicationData?.id}
        deleteConfirmationText={AppStrings.Announcements.deleteCommunicationConfirmationText}
        attachments={communicationFiles}
      />
    )
  ]);
  useEffectAsync(async () => {
    await fetchData();
  }, []);

  return (
    <DialogFrame
      frameType={"contentOnlyNoFrame"}
      numViews={2}
      activeView={currentView}
      onCloseButtonClick={onBackdropClick}
      width={Math.min(window.innerWidth - 80, 1200)}
      height={window.innerHeight - 80}
      renderView={renderView}
      useExperimentalDialogFrame
    />
  );
};
