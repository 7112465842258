"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScreeningReportPaymentDto = exports.PayedType = exports.ReportPaymentOptions = void 0;
const class_validator_1 = require("class-validator");
var ReportPaymentOptions;
(function (ReportPaymentOptions) {
    ReportPaymentOptions["SUBSCRIPTION_CARD"] = "SUBSCRIPTION_CARD";
    ReportPaymentOptions["MERCHANT_ACCOUNT"] = "MERCHANT_ACCOUNT";
})(ReportPaymentOptions || (exports.ReportPaymentOptions = ReportPaymentOptions = {}));
var PayedType;
(function (PayedType) {
    PayedType["APPLICANT_POST_SUBMIT"] = "applicantPostSubmit";
    PayedType["APPLICANT_PRE_SUBMIT"] = "APPLICANT_PRE_SUBMIT";
    PayedType["USER_POST_SUBMIT"] = "USER_POST_SUBMIT";
})(PayedType || (exports.PayedType = PayedType = {}));
class ScreeningReportPaymentDto {
}
exports.ScreeningReportPaymentDto = ScreeningReportPaymentDto;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], ScreeningReportPaymentDto.prototype, "rentalApplicationId", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(ReportPaymentOptions),
    __metadata("design:type", String)
], ScreeningReportPaymentDto.prototype, "selectedPaymentMethod", void 0);
