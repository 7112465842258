import React, { useEffect, useState } from "react";

import type { FormikProps } from "formik";
import { getIn } from "formik";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import AnimatedContent, { getAnimatedContentFormikRef } from "DLUI/dialogs/components/animatedContent";
import AppStrings from "locale/keys";
import Dialog from "DLUI/dialogs/dialog";
import DialogFrame from "DLUI/dialogs/components/dialogFrame";
import makeStyles from "./styles";
import View from "DLUI/view/view";
import type { ApiResult } from "api/apiResult";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { ExclamationAlert } from "DLUI/dialogs/alert/alert";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { leasesApi } from "api/leasesApi";
import { propertiesApi } from "api/propertiesApi";
import { Scroller } from "DLUI/screen";
import { settingsApi } from "api/settingsApi";
import type { SVGIconComponent } from "assets/icons/types";
import { useCancelLeaseAutoPaymentsTexts } from "./hooks/useCancelLeaseAutoPaymentsTexts";
import { useTenantPortalAccessObjectPath } from "./hooks/useTenantPortalAccessObjectPath";
import { loadHasTenantsAutoPayments } from "./loadHasTenantsAutoPayments";
import type { HasTenantsAutoPayments } from "@doorloop/dto";
import { useResponsiveHelper } from "@/contexts/responsiveContext";
import _ from "lodash";
import { getFormikPortalSettingsProperty } from "screens/settings/tenantPortal/formikHelper";
import { DLButtonColorsEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";

const TENANT_PORTAL_DIALOG_WIDTH = 962;

const TENANT_PORTAL_DIALOG_HEIGHT = 700;

enum TenantPortalAnimatedContentDialogViewIndex {
  AnimatedContent,
  LoadingResult
}

const tenantPortalAnimatedContentDialogViewIndexLength = Object.values(
  TenantPortalAnimatedContentDialogViewIndex
).length;

interface MenuItem {
  title: string;
  isSelected?: boolean;
  icon: SVGIconComponent;
  contentComponent: (
    formikRef: FormikProps<any>,
    onFileReceive?: (receivedFile: File) => void,
    onFileDelete?: () => void
  ) => any;
  uploadFile?: boolean;
}

interface ComponentProps {
  onClose: (values?: any, shouldRefresh?: boolean) => void;
  className?: string;
  disableBackdropClick?: boolean;
  dialogState: DialogState;
  loadingText?: string;
  errorText?: string;
  successText?: string;
  onRetryButtonPress?: (formikRef?: FormikProps<any>) => void;
  onBackdropClick?: () => void;
  showCloseIcon?: boolean;
  refreshEvent?: () => void;
  sectionItems: MenuItem[];
  getformikInitialValues: () => any;
  formikValidation?: (values: any) => any;
  validationMethod: (formikRef: FormikProps<any>) => Promise<{ isValid: boolean; errorStepIndex?: number }>;
  dialogTitle: string;
  dialogHeight?: number;
  dialogWidth?: number;
  propertyId?: string;
  leaseId?: string;
}

let formikGlobalRef: FormikProps<any> | null = null;

export const TenantPortalAnimatedContentDialog: React.FC<ComponentProps> = ({
  dialogState,
  onClose,
  className,
  loadingText,
  errorText,
  successText,
  onBackdropClick,
  refreshEvent,
  sectionItems,
  getformikInitialValues,
  formikValidation,
  validationMethod,
  dialogTitle,
  dialogHeight,
  dialogWidth,
  propertyId,
  leaseId
}: ComponentProps) => {
  const classes = makeStyles();
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();
  const [scroller, setScroller] = useState<Scroller | null>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const [currentMenuItemTitle, setCurrentMenuItemTitle] = useState<string>("");
  const [viewIndex, setViewIndex] = useState(TenantPortalAnimatedContentDialogViewIndex.AnimatedContent);

  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);

  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>(
    errorText || t(AppStrings.Common.GeneralError)
  );

  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState<string>(
    loadingText || t(AppStrings.Common.Loading)
  );

  const [loadingDialogSuccessText, setLoadingDialogSuccessText] = useState<string>(
    successText || t(AppStrings.Common.OperationCompleted)
  );

  const [currentSelectedMenuIndex, setCurrentSelectedMenuIndex] = useState<number | undefined>();

  const [tenantPortalLogoPendingFile, setTenantPortalLogoPendingFile] = useState<File | undefined>();

  const [tenantPortalCoverPendingFile, setTenantPortalCoverPendingFile] = useState<File | undefined>();

  const [settingsData, setSettingsData] = useState<undefined | any>();
  const [hasTenantsAutoPayments, setHasTenantsAutoPayments] = useState<HasTenantsAutoPayments>({
    hasAutoPayments: false
  });

  const [deleteTenantPortalCoverImageOnSave, setDeleteTenantPortalCoverImageOnSave] = useState<boolean>(false);
  const [deleteCompanyLogoImageOnSave, setDeleteCompanyLogoImageOnSave] = useState<boolean>(false);

  const [showCancelAutoPaymentsConfirmation, setShowCancelAutoPaymentsConfirmation] = useState<boolean>(false);
  const [showOnlinePaymentsCannotBeTurnedOff, setShowOnlinePaymentsCannotBeTurnedOff] = useState<boolean>(false);

  const tenantPortalAccessObjectPath = useTenantPortalAccessObjectPath({
    propertyId,
    leaseId
  });

  const cancelAutoPaymentsTexts = useCancelLeaseAutoPaymentsTexts();

  const isLastTabActive = (currentSelectedMenuIndex ?? 0) === sectionItems.length - 1;

  const formikThemeFieldName = getFormikPortalSettingsProperty("theme", propertyId);

  useEffect(() => {
    if (dialogState === DialogState.Show) {
      setShowDialog(true);
      if (scroller == null) {
        setScroller(new Scroller(document.getElementsByClassName("MuiDialog-scrollBody")[0] as HTMLDivElement));
      }
    } else if (dialogState === DialogState.Hidden) {
      setShowDialog(false);
    }
  }, [dialogState]);

  useEffect(() => {
    loadSettingsData();

    return () => {
      formikGlobalRef = null;
    };
  }, []);

  const handleError = (e: string) => {
    setLoadingDialogErrorText(e);
    setLoadingDialogState(DialogState.Hidden);
  };

  const loadSettingsData = async () => {
    setLoadingDialogLoadingText(t(AppStrings.Common.Loading));
    setLoadingDialogState(DialogState.Show);
    let results: any;
    if (leaseId) {
      results = await leasesApi.getLeaseDetails(leaseId).catch(handleError);
    } else if (propertyId) {
      results = await propertiesApi.getPropertyDetails(propertyId).catch(handleError);
    } else {
      results = await settingsApi.getTenantPortalSettings().catch(handleError);
    }

    if (results && results.status) {
      setSettingsData(results.data);
    } else {
      setLoadingDialogErrorText(results.message ? results.message : "");
      setLoadingDialogState(DialogState.Error);

      return;
    }

    const hasTenantsAutoPaymentsApiResult = await loadHasTenantsAutoPayments({
      leaseId,
      propertyId
    });

    if (hasTenantsAutoPaymentsApiResult.status && hasTenantsAutoPaymentsApiResult.data) {
      setHasTenantsAutoPayments(hasTenantsAutoPaymentsApiResult.data);
    } else {
      setLoadingDialogErrorText(results.message ? results.message : "");
      setLoadingDialogState(DialogState.Error);

      return;
    }

    setLoadingDialogSuccessText(t(AppStrings.Common.OperationCompleted));
    setLoadingDialogState(DialogState.Success);
    setTimeout(() => {
      setLoadingDialogState(DialogState.Hidden);
    }, 0);
  };

  const didFinishOperation = (values?: any) => {
    onClose(values);
    setTimeout(() => {
      setViewIndex(TenantPortalAnimatedContentDialogViewIndex.AnimatedContent);
      if (refreshEvent) {
        refreshEvent();
      }
    }, 500);
  };

  const _onBackdropClick = () => {
    if (onBackdropClick) {
      onBackdropClick();
    }
  };
  const handleClickNext = () => {
    setCurrentSelectedMenuIndex((prev = 0) => prev + 1);
  };

  const handleSelectedIndexChange = (index: number) => {
    setCurrentSelectedMenuIndex(index);
  };

  const renderActionPanelButtons = (formik: FormikProps<any>) => {
    formikGlobalRef = formik;
    return (
      <FormActionButtons
        propsSubButton={
          isLastTabActive
            ? undefined
            : {
                variant: DLButtonVariantsEnum.OUTLINED,
                color: DLButtonColorsEnum.PRIMARY,
                onClick: handleClickNext,
                actionText: AppStrings.Common.TenantPortalSettingsSections.Continue,
                style: {
                  minWidth: 142
                }
              }
        }
        propsMainButton={{
          type: "cta",
          props: {
            onClick: didPressSaveButton,
            actionText: AppStrings.Common.TenantPortalSettingsSections.SaveAndClose,
            style: {
              minWidth: 142
            }
          }
        }}
        propsActionPanel={{
          paddingRight: 0
        }}
      />
    );
  };

  const onMenuItemSelection = (menuItemTitle: string) => {
    setCurrentMenuItemTitle(menuItemTitle);
  };

  const onRetryLoadInfoButtonPress = () => {
    loadSettingsData();
  };

  const didPressDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(TenantPortalAnimatedContentDialogViewIndex.AnimatedContent);
  };

  const getFormikInitialValues = () => {
    if (formikGlobalRef && formikGlobalRef.values && formikGlobalRef.values) {
      return formikGlobalRef.values;
    }
    if (settingsData) {
      return settingsData;
    }
    return getformikInitialValues();
  };

  const handleBackAutoPaymentsConfirmation = (): void => {
    setShowCancelAutoPaymentsConfirmation(false);
  };

  const handleCancelAllAutoPaymentsConfirmation = async (): Promise<void> => {
    setShowCancelAutoPaymentsConfirmation(false);

    await updateSettingsData(true);
  };

  const handleBackOnlinePaymentsCannotBeTurnedOff = (): void => {
    setShowOnlinePaymentsCannotBeTurnedOff(false);
  };

  const renderView = ({ index }: any) => {
    if (index === TenantPortalAnimatedContentDialogViewIndex.AnimatedContent) {
      if (loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={onRetryLoadInfoButtonPress}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }

      if (showCancelAutoPaymentsConfirmation) {
        return (
          <ExclamationAlert
            title={cancelAutoPaymentsTexts.confirmationCancelTitle}
            subTitle={cancelAutoPaymentsTexts.confirmationCancelDescription}
            dismissButtonText={t(AppStrings.Common.Back)}
            confirmButtonText={t(AppStrings.Common.CancelAll)}
            onDismissButton={handleBackAutoPaymentsConfirmation}
            onConfirmButton={handleCancelAllAutoPaymentsConfirmation}
          >
            <View marginBottom={60} />
          </ExclamationAlert>
        );
      }

      if (showOnlinePaymentsCannotBeTurnedOff) {
        return (
          <ExclamationAlert
            title={t(AppStrings.Common.TenantPortalFeaturesOnlinePayments.CannotBeTurnedOffTitle)}
            subTitle={t(
              propertyId
                ? AppStrings.Common.TenantPortalFeaturesOnlinePayments.CannotBeTurnedOffDescriptionProperty
                : AppStrings.Common.TenantPortalFeaturesOnlinePayments.CannotBeTurnedOffDescriptionCompany
            )}
            dismissButtonText={t(AppStrings.Common.Back)}
            onDismissButton={handleBackOnlinePaymentsCannotBeTurnedOff}
          >
            <View marginBottom={60} />
          </ExclamationAlert>
        );
      }

      return (
        <AnimatedContent
          formikInitialValues={getFormikInitialValues}
          sectionItems={sectionItems}
          formikValidation={formikValidation}
          dialogHeight={dialogHeight || TENANT_PORTAL_DIALOG_HEIGHT}
          renderActionPanelButtons={renderActionPanelButtons}
          onMenuItemSelection={onMenuItemSelection}
          selectedMenuIndex={currentSelectedMenuIndex}
          onTenantPortalLogoFileReceive={_onTenantPortalLogoFileReceive}
          onTenantPortalLogoFileDelete={_onTenantPortalLogoFileDelete}
          onCoverImageFileReceive={_onCoverImageFileReceive}
          onCoverImageFileDelete={_onCoverImageFileDelete}
          hasTenantsAutoPayments={hasTenantsAutoPayments}
          stickyActionButtons
          onChangeSelectedItem={handleSelectedIndexChange}
          mobileScrollToSelectedMenuItem
          menuItemsStyle={{ overflowY: "scroll" }}
          removeScrollOnMobile
        />
      );
    }

    if (index === TenantPortalAnimatedContentDialogViewIndex.LoadingResult) {
      return (
        <div className={clsx([isMobile ? classes.mobileDialogContainer : classes.dialogContainer])}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={onLoadingDialogRetryButtonPress}
            didPressDismissButton={didPressLoadingDialogDismissButton}
          />
        </div>
      );
    }

    return <div />;
  };

  const deleteTenantPortalLogo = async () => {
    if (propertyId) {
      await propertiesApi.deleteTenantPortalLogo(propertyId);
    } else {
      await settingsApi.deleteTenantPortalLogo();
    }
  };

  const deleteTenantPortalCoverImage = async () => {
    if (propertyId) {
      await propertiesApi.deleteTenantPortalCover(propertyId);
    } else {
      await settingsApi.deleteTenantPortalCover();
    }
  };

  const handleCancelTenantsAutoPayments = async (): Promise<boolean> => {
    if (!leaseId) {
      return true;
    }

    const result = await leasesApi.cancelTenantsAutoPayments(leaseId);

    if (!result.status) {
      setLoadingDialogErrorText(result.message);
      setLoadingDialogState(DialogState.Error);

      return false;
    }

    return true;
  };

  const updateSettingsData = async (isNeedToCancelTenantsAutoPayments = false) => {
    const formikRef = getAnimatedContentFormikRef();
    if (formikRef !== null) {
      setLoadingDialogLoadingText(loadingText || t(AppStrings.Common.Loading));
      setLoadingDialogState(DialogState.Show);
      setViewIndex(TenantPortalAnimatedContentDialogViewIndex.LoadingResult);
      let response: any;
      if (leaseId) {
        response = (await leasesApi.update(formikRef.values.id, formikRef.values).catch(handleError)) as ApiResult<any>;
      } else if (propertyId) {
        response = (await propertiesApi
          .update(formikRef.values.id, formikRef.values)
          .catch(handleError)) as ApiResult<any>;
      } else {
        response = (await settingsApi
          .updateTenantPortalSettings(formikRef.values)
          .catch(handleError)) as ApiResult<any>;
      }

      if (response && response.status) {
        if (tenantPortalLogoPendingFile) {
          await uploadTenantPortalLogoFile(tenantPortalLogoPendingFile);
        }
        if (tenantPortalCoverPendingFile) {
          await uploadTenantPortalCoverFile(tenantPortalCoverPendingFile);
        }
        if (deleteCompanyLogoImageOnSave) {
          await deleteTenantPortalLogo();
        }
        if (deleteTenantPortalCoverImageOnSave) {
          await deleteTenantPortalCoverImage();
        }

        if (isNeedToCancelTenantsAutoPayments) {
          const hasSuccess = await handleCancelTenantsAutoPayments();

          if (!hasSuccess) {
            return;
          }
        }

        didFinishOperation(response.data);
        setLoadingDialogSuccessText(successText || t(AppStrings.Common.OperationCompleted));
        setLoadingDialogState(DialogState.Hidden);
        formikGlobalRef = null;
      } else {
        setLoadingDialogErrorText(response.message);
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const didPressSaveButton = async () => {
    if (!formikGlobalRef) {
      return;
    }

    const validationResult = await validationMethod(formikGlobalRef);

    if (formikGlobalRef.values.contactInfo?.selectionType === "companyDefault") {
      formikGlobalRef.setFieldValue("contactInfo", undefined);
      formikGlobalRef.setFieldValue("settings.tenantPortal.contactInfo", undefined);
    }
    if (validationResult.isValid) {
      const onlinePayments = getIn(formikGlobalRef.values, `${tenantPortalAccessObjectPath}.onlinePayments`);
      const showAutoPaymentsWarning =
        (hasTenantsAutoPayments.hasAutoPayments && onlinePayments === false) ||
        (onlinePayments === undefined &&
          hasTenantsAutoPayments.hasAutoPayments &&
          hasTenantsAutoPayments.hasParentOnlinePayments === false);

      if (showAutoPaymentsWarning) {
        if (leaseId) {
          setShowCancelAutoPaymentsConfirmation(true);

          return;
        }

        const oldOnlinePayments = getIn(settingsData, `${tenantPortalAccessObjectPath}.onlinePayments`);

        if (oldOnlinePayments !== onlinePayments) {
          setShowOnlinePaymentsCannotBeTurnedOff(true);

          return;
        }
      }

      setTimeout(() => {
        updateSettingsData();
      }, 0);
    } else if (validationResult.errorStepIndex !== undefined) {
      setCurrentSelectedMenuIndex(validationResult.errorStepIndex);
      setTimeout(() => {
        setCurrentSelectedMenuIndex(undefined);
      }, 0);
    }
  };

  const onLoadingDialogRetryButtonPress = () => {
    setLoadingDialogState(DialogState.Show);

    updateSettingsData();
  };

  const didPressLoadingDialogDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(TenantPortalAnimatedContentDialogViewIndex.AnimatedContent);
  };

  const uploadTenantPortalLogoFile = async (tenantPortalLogoPendingFile: File) => {
    setLoadingDialogState(DialogState.Show);
    let response: any;
    if (propertyId) {
      response = await propertiesApi.uploadTenantPortalLogo(tenantPortalLogoPendingFile!, settingsData.id);
    } else {
      response = await settingsApi.uploadTenantPortalLogo(tenantPortalLogoPendingFile!);
    }

    if (response && response.status) {
      setLoadingDialogSuccessText(successText || t(AppStrings.Common.OperationCompleted));
      setLoadingDialogState(DialogState.Success);
      setTimeout(() => {
        setLoadingDialogState(DialogState.Hidden);
      }, 0);
      formikGlobalRef = null;
    } else {
      formikGlobalRef!.setSubmitting(false);
      setLoadingDialogErrorText(response.message);
      setLoadingDialogState(DialogState.Error);
    }
  };

  const uploadTenantPortalCoverFile = async (tenantPortalCoverPendingFile: File) => {
    setLoadingDialogState(DialogState.Show);

    let response: any;
    if (propertyId) {
      response = await propertiesApi.uploadTenantPortalCoverImage(tenantPortalCoverPendingFile, settingsData.id);
    } else {
      response = await settingsApi.uploadTenantPortalCoverImage(tenantPortalCoverPendingFile);
    }

    if (response && response.status) {
      setLoadingDialogSuccessText(successText || t(AppStrings.Common.OperationCompleted));
      setLoadingDialogState(DialogState.Success);
      setTimeout(() => {
        setLoadingDialogState(DialogState.Hidden);
      }, 0);
      formikGlobalRef = null;
    } else {
      formikGlobalRef!.setSubmitting(false);
      setLoadingDialogErrorText(response.message);
      setLoadingDialogState(DialogState.Error);
    }
  };

  const _onTenantPortalLogoFileReceive = (receivedFile: File) => {
    formikGlobalRef?.setFieldValue(`${formikThemeFieldName}.logoUrl`, URL.createObjectURL(receivedFile));

    setTenantPortalLogoPendingFile(receivedFile);
    setDeleteCompanyLogoImageOnSave(false);
  };

  const _onTenantPortalLogoFileDelete = () => {
    formikGlobalRef?.setFieldValue(`${formikThemeFieldName}.logoUrl`, undefined);

    setTenantPortalLogoPendingFile(undefined);
    setDeleteCompanyLogoImageOnSave(true);
  };

  const _onCoverImageFileReceive = (receivedFile: File) => {
    formikGlobalRef?.setFieldValue(`${formikThemeFieldName}.backgroundUrl`, URL.createObjectURL(receivedFile));

    setTenantPortalCoverPendingFile(receivedFile);
    setDeleteTenantPortalCoverImageOnSave(false);
  };

  const _onCoverImageFileDelete = () => {
    formikGlobalRef?.setFieldValue(`${formikThemeFieldName}.backgroundUrl`, undefined);

    setTenantPortalCoverPendingFile(undefined);
    setDeleteTenantPortalCoverImageOnSave(true);
  };

  const getFrameType = () => {
    if (
      loadingDialogState !== DialogState.Hidden ||
      showCancelAutoPaymentsConfirmation ||
      showOnlinePaymentsCannotBeTurnedOff
    ) {
      return "contentOnly";
    }

    return "sideMenu";
  };

  const frameType = getFrameType();

  return (
    <Dialog className={className} open={showDialog} onClose={_.noop} disableBackdropClick>
      <DialogFrame
        onCloseButtonClick={_onBackdropClick}
        width={dialogWidth || TENANT_PORTAL_DIALOG_WIDTH}
        height={
          showCancelAutoPaymentsConfirmation || showOnlinePaymentsCannotBeTurnedOff
            ? "auto"
            : dialogHeight || TENANT_PORTAL_DIALOG_HEIGHT
        }
        renderView={renderView}
        numViews={tenantPortalAnimatedContentDialogViewIndexLength}
        activeView={viewIndex}
        frameType={frameType}
        title={dialogTitle}
        sectionTitle={currentMenuItemTitle}
        titleProps={{
          marginLeft: 0,
          fontSize: 18
        }}
        onMobileBackButtonClick={_onBackdropClick}
      />
    </Dialog>
  );
};
