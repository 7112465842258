import React, { useEffect, useState } from "react";
import { View } from "DLUI/view";

import "./styles.css";
import clsx from "clsx";

interface ComponentProps {
  activeView: number;
  renderView: (data: { index: number; active: boolean }) => JSX.Element;
  hideAnimation?: boolean;
}

const ViewSlider: React.FC<ComponentProps> = ({ activeView, renderView, hideAnimation }: ComponentProps) => {
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    toggleAnimationClass();
  }, [activeView]);

  const toggleAnimationClass = () => {
    setShowAnimation(false);
    setTimeout(() => {
      setShowAnimation(true);
    }, 0);
  };

  return (
    <View height={"100%"} flexDirection={"row"} noWrap>
      <div
        className={clsx([
          hideAnimation ? "slider-frame-no-animation" : "slider-frame",
          showAnimation && "slider-frame-show"
        ])}
      >
        {renderView({
          index: activeView,
          active: true
        })}
      </div>
    </View>
  );
};

export default ViewSlider;
