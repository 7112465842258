import type { FC } from "react";
import React from "react";
import type { SettingPermission } from "@doorloop/dto";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { Grid } from "@material-ui/core";
import { SeparationLine } from "DLUI/separatorView";
import { SettingsPermissionToggle } from "screens/userRoles/newUserRole/components/settings/settingsPermissionToggle";
import { useTranslation } from "react-i18next";
import AppStrings from "locale/keys";
import { useResponsiveHelper } from "../../../../../../contexts/responsiveContext";

interface Props {
  title: string;
  permissions: Array<{
    permission: SettingPermission;
    title: string;
  }>;
}

export const SettingsSection: FC<Props> = ({ title, permissions }: Props) => {
  const { isScreenSizeIn } = useResponsiveHelper();
  const { t } = useTranslation();
  return (
    <View
      borderRadius={10}
      marginBottom={20}
      paddingLeft={20}
      paddingRight={20}
      backgroundColor={"white"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid container>
        <Grid item xs={12} sm={6}>
          <View height={60} justifyContent={"center"}>
            <Text fontWeight={700} lineHeight={"20px"} value={title} />
          </View>
        </Grid>
        {!isScreenSizeIn(["xs", "sm"]) && (
          <>
            <Grid item xs={12} sm={2}>
              <View height={60} alignItems={"center"} justifyContent={"center"}>
                <Text color={"gray"} value={AppStrings.UserRoles.NoAccess} />
              </View>
            </Grid>
            <Grid item xs={12} sm={2}>
              <View height={60} alignItems={"center"} justifyContent={"center"}>
                <Text color={"gray"} value={AppStrings.UserRoles.ViewOnly} />
              </View>
            </Grid>
            <Grid item xs={12} sm={2}>
              <View height={60} alignItems={"center"} justifyContent={"center"}>
                <Text color={"gray"} value={AppStrings.UserRoles.Edit} />
              </View>
            </Grid>
          </>
        )}
      </Grid>
      <SeparationLine width={"100%"} height={1} />
      <View height={20} />
      {permissions.map(({ permission, title }, i) => (
        <SettingsPermissionToggle
          permission={permission}
          label={t(title)}
          key={i}
          isLast={i + 1 === permissions.length}
        />
      ))}
      <View height={20} />
    </View>
  );
};
