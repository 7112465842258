import type { GetAllLeaseTenantsQuery, LeaseDto, LeaseTenantDto } from "@doorloop/dto";
import { ObjectPermission } from "@doorloop/dto";
import { leaseTenantsApi } from "api/tenantsApi";
import React, { useRef } from "react";
import TenantsEmptyDataView from "screens/tenants/tenantsList/tenantsEmptyDataView";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import { DataList } from "DLUI/dataList";
import {
  useLeaseTenantsBulkProps,
  useLeaseTenantsDataList
} from "screens/leases/leases/leaseDetails/tenants/useLeaseTenantsDataList";
import type { DataListLeaseTenantsRefType } from "screens/leases/leases/leaseDetails/tenants/leaseTenantsFiltersWidgetsList";

interface ComponentProps {
  didSelectTenantItem?: (id: string, selectedItem?: LeaseTenantDto) => void;
  ListItem: React.ComponentType<any>;
  filterObj: GetAllLeaseTenantsQuery;
  fullWidth?: boolean;
  scrollableTarget?: string;
  listDirection?: "row" | "column";
  skeletonItem?: React.FC;
  leaseData?: LeaseDto;
  showListHeader?: boolean;
  onLoadingDataStateChange?: (loadingInProgress: boolean) => void;
  minWidth?: number;
  stickyHeaderId?: string;
  selectableItem?: boolean;
  removeDefaultBottomPadding?: boolean;
  columnToChange?: any;
  isMultipleTenantsLease?: boolean;
}

const screenPadding = 40;

const LeaseTenantsList: React.FC<ComponentProps> = ({
  didSelectTenantItem,
  ListItem,
  filterObj,
  fullWidth,
  scrollableTarget,
  listDirection,
  onLoadingDataStateChange,
  minWidth,
  stickyHeaderId,
  selectableItem,
  removeDefaultBottomPadding,
  leaseData,
  isMultipleTenantsLease
}: ComponentProps) => {
  const queryKeyRef = useRef<DataListLeaseTenantsRefType>(null);
  const { leaseIdRentReminderMap, updateLastRentSendDate, hideSkeleton, handleTenantUpdated } =
    useLeaseTenantsDataList(queryKeyRef);
  const { bulkProps } = useLeaseTenantsBulkProps(selectableItem);

  return (
    <RestrictedPermissionAccess clearance={{ permission: ObjectPermission.tenants, field: "viewList" }} showNoAccess>
      <DataList
        emptyListView={TenantsEmptyDataView}
        scrollElement={scrollableTarget ? document.getElementById(scrollableTarget) : undefined}
        queryFilter={filterObj}
        onLoadingDataStateChange={onLoadingDataStateChange}
        stickyHeaderProps={{
          id: stickyHeaderId
        }}
        bulkOperationResourceIdGetter={(item, index) => {
          const _item = item as LeaseTenantDto;
          return `${_item.lease} - ${_item.property} - ${index}`;
        }}
        bulkOperationsProps={bulkProps}
        paddingEnd={removeDefaultBottomPadding ? 0 : undefined}
        restApi={leaseTenantsApi}
        infinite
        hideSkeleton={hideSkeleton}
        listRef={queryKeyRef}
      >
        {(currentItem, index, resourceId) => (
          <div style={{ width: fullWidth ? "100%" : "auto" }} key={"TL" + index}>
            <ListItem
              onItemPress={didSelectTenantItem}
              tenant={currentItem}
              enableMoveOutOption={isMultipleTenantsLease}
              leaseData={leaseData}
              stickyHeaderId={stickyHeaderId}
              selectableItem={selectableItem}
              resourceId={resourceId}
              onSendRentReminder={updateLastRentSendDate}
              onTenantUpdated={handleTenantUpdated}
              lastSentReminder={currentItem.lease ? leaseIdRentReminderMap?.[currentItem.lease] : undefined}
            />
          </div>
        )}
      </DataList>
    </RestrictedPermissionAccess>
  );
};

export default LeaseTenantsList;
