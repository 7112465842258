import _ from "lodash";
import type { FormikProps } from "formik";
import { getIn } from "formik";
import type {
  DeepKeys,
  GetAllAccountsQuery,
  ManagementFeesCompanySettingsClientDto,
  ManagementFeesCompanySettingsDto,
  ManagementFeesPropertySettingsClientDto,
  ManagementFeesPropertySettingsDto
} from "@doorloop/dto";
import { AccountClass } from "@doorloop/dto";

export const validateManagementFeeForm = async (
  formikRef: FormikProps<ManagementFeesCompanySettingsClientDto | ManagementFeesPropertySettingsClientDto>,
  settingsFieldName?: DeepKeys<ManagementFeesCompanySettingsDto | ManagementFeesPropertySettingsDto>
): Promise<boolean> => {
  await formikRef.submitForm();

  const formikValues: ManagementFeesCompanySettingsDto = settingsFieldName
    ? getIn(formikRef.values, settingsFieldName)
    : formikRef.values;

  formikValues?.feeRules?.forEach(({}, index) => {
    const baseFieldName = settingsFieldName ? `${settingsFieldName}.feeRules` : "feeRules";

    formikRef.setFieldTouched(`${baseFieldName}[${index}].ruleType`);
    formikRef.setFieldTouched(`${baseFieldName}[${index}].fee`);
    formikRef.setFieldTouched(`${baseFieldName}[${index}].baseAccount`);
    formikRef.setFieldTouched(`${baseFieldName}[${index}].expenseAccount`);
  });

  formikRef.values.payOutAccounts?.forEach(({}, index) => {
    formikRef.setFieldTouched(`payOutAccounts[${index}].incomeAccount`);
  });

  const minChargeFeeBaseFieldName = settingsFieldName ? `${settingsFieldName}.minChargeFee` : "minChargeFee";
  formikRef.setFieldTouched(`${minChargeFeeBaseFieldName}.feeAmount`);
  formikRef.setFieldTouched(`${minChargeFeeBaseFieldName}.expenseAccount`);

  const vendorFieldName = settingsFieldName ? `${settingsFieldName}.vendor` : "vendor";

  formikRef.setFieldTouched(vendorFieldName);

  const errors = await formikRef.validateForm();

  return _.isEmpty(errors);
};

export const EXPENSE_QUERY_PARAMS: GetAllAccountsQuery = {
  filter_class: AccountClass.EXPENSE,
  filter_active: true
};

export const REVENUE_QUERY_PARAMS: GetAllAccountsQuery = {
  filter_class: AccountClass.REVENUE,
  filter_active: true
};
