import MomentUtils from "@date-io/moment";
import type { LeaseRenewalOfferTermDto } from "@doorloop/dto";
import { LeaseTermType } from "@doorloop/dto";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DeleteIcon } from "assets/icons";
import { Button } from "DLUI/button";
import { FormikDatePicker, Select, TextField } from "DLUI/form";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FieldArrayRenderProps, FormikProps } from "formik";
import { FastField, getIn } from "formik";
import AppStrings from "locale/keys";
import moment from "moment";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import makeStyles from "./styles";

interface ComponentProps {
  lineIndex: number;
  transactionItem: LeaseRenewalOfferTermDto;
  arrayHelpers: FieldArrayRenderProps;
  formikRef: FormikProps<any>;
  leaseEndDate?: string;
}

const RenewalOfferLine: React.FC<ComponentProps> = ({
  lineIndex,
  arrayHelpers,
  formikRef,
  leaseEndDate
}: ComponentProps) => {
  const classes = makeStyles();
  const { t } = useTranslation();

  const numberOfLines = useMemo(() => {
    const proposedTermsArray = formikRef.values.proposedTerms;
    return proposedTermsArray ? proposedTermsArray.length : 1;
  }, [formikRef.values]);

  const didChangeEndDate = () => {};

  const renderEndDateField = () => {
    const termType = getIn(formikRef.values, `proposedTerms[${lineIndex}].newTerm`);

    if (!termType || (termType && termType === LeaseTermType.AtWill)) {
      return null;
    }

    return (
      <Grid className={classes.inputMargin} item xs={12} md={4} lg={3}>
        <FastField
          component={FormikDatePicker}
          uniqueKey={"termStartDate" + lineIndex}
          label={AppStrings.Leases.NewLease.EndDate}
          name={`proposedTerms[${lineIndex}].newEndDate`}
          noMargin
          onChange={didChangeEndDate}
          minDate={leaseEndDate || moment()}
        />
      </Grid>
    );
  };

  const renderLineHeader = () => {
    const didPressAddChargeLine = () => {
      arrayHelpers.remove(lineIndex);
    };
    return (
      <View shouldShow={numberOfLines > 1} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
        <View height={60} flexDirection={"row"} paddingLeft={20} paddingRight={20} alignItems={"center"}>
          <Text fullWidth color={"black"} fontSize={16}>
            {t(AppStrings.Leases.LeaseRenewals.ProposedTerms)} {lineIndex + 1}
          </Text>
          <Button
            color={"error"}
            type={"inlineText"}
            actionText={AppStrings.Common.Delete}
            onClick={didPressAddChargeLine}
            LeftIcon={DeleteIcon}
            marginLeft={20}
            iconSize={18}
            applyColorForIcons
          />
        </View>
      </View>
    );
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <View
        minHeight={numberOfLines > 1 ? 170 : 100}
        className={classes.renewalOfferLineContainer}
        paddingTop={10}
        borderRadius={10}
      >
        {renderLineHeader()}
        <Grid container>
          <Grid className={classes.inputMargin} item xs={12} md={4} lg={3}>
            <FastField
              component={Select}
              name={`proposedTerms[${lineIndex}].newTerm`}
              label={AppStrings.Leases.LeaseRenewals.TermOffer}
              required
              uniqueKey={"paymentMethod"}
              selectionEnum={LeaseTermType}
              translationKey={"leaseTermType"}
            />
          </Grid>
          {renderEndDateField()}

          <Grid className={classes.inputMargin} item xs={12} md={4} lg={3}>
            <FastField
              component={TextField}
              name={`proposedTerms[${lineIndex}].newRent`}
              type={"number"}
              label={t(AppStrings.Leases.LeaseRenewals.ProposedRent)}
              required
              formatType={"currency"}
            />
          </Grid>
        </Grid>
      </View>
    </MuiPickersUtilsProvider>
  );
};

export default RenewalOfferLine;
