export const ConversionFunctions = {
  stringToBool: (filterItem: string) => Boolean(filterItem),
  numberToBool: (filterItem: number) => Boolean(filterItem)
};

export const CacheFilterItems = {
  filter_property: {
    name: "property",
    type: "string"
  },
  filter_type: {
    name: "type",
    type: "string"
  },
  filter_class: {
    name: "class",
    type: "string"
  },
  filter_classes: {
    name: "class",
    type: "array"
  },
  filter_text: {
    name: "text",
    type: "string"
  },
  filter_status: {
    name: "status",
    type: "string"
  },
  filter_active: {
    name: "active",
    type: "boolean",
    convertFunc: ConversionFunctions.stringToBool
  },
  filter_leaseChargeItem: {
    name: "leaseChargeItem",
    type: "boolean",
    convertFunc: ConversionFunctions.stringToBool
  },
  filter_isSystemAccountOrLeaseChargeItem: {
    name: "isSystemAccountOrLeaseChargeItem",
    type: "boolean",
    convertFunc: ConversionFunctions.stringToBool
  },
  filter_leaseDepositItem: {
    name: "leaseDepositItem",
    type: "boolean",
    convertFunc: ConversionFunctions.stringToBool
  },
  filter_types: {
    name: "type",
    type: "array"
  }
};
