import common from "./common";
import navigation from "./navigation";
import auth from "./auth";
import bulkPayments from "./bulkPayments";
import drawerItems from "./drawerItems";
import properties from "./properties";
import units from "./units";
import tenants from "./tenants";
import toasts from "./toasts";
import leases from "./leases";
import overview from "./overview";
import owners from "./owners";
import vendors from "./vendors";
import lateFee from "./lateFee";
import bills from "./bills";
import notes from "./notes";
import files from "./files";
import tasks from "./tasks";
import prospects from "./prospects";
import otherTransactions from "./otherTransactions";
import accounts from "./accounts";
import users from "./users";
import settings from "./settings";
import tags from "./tags";
import reports from "./reports";
import userRoles from "./UserRoles";
import dashboards from "./dashboards";
import announcements from "./announcements";
import bulkActions from "./bulkActions";
import calendar from "./calendar";
import esignature from "./esignature";
import listing from "./listing";
import onBoarding from "./onBoarding";
import allocations from "./allocations";
import notifications from "./notifications";
import ownerPortal from "./ownerPortal";
import budgets from "./budgets";
import communicationsCenter from "./communicationsCenter";
import filters from "./filters";

export default {
  announcements,
  allocations,
  calendar,
  common,
  navigation,
  bulkPayments,
  onBoarding,
  auth,
  drawerItems,
  properties,
  units,
  tenants,
  leases,
  overview,
  owners,
  filters,
  vendors,
  lateFee,
  bills,
  notes,
  files,
  tasks,
  prospects,
  otherTransactions,
  accounts,
  users,
  userRoles,
  settings,
  tags,
  reports,
  esignature,
  dashboards,
  bulkActions,
  listing,
  notifications,
  toasts,
  ownerPortal,
  budgets,
  communicationsCenter
};
