export default {
  metadata: {
    title: "Owners",
    titleSingular: "Owner"
  },
  creatable: {
    title: "Add New Owner",
    buttonLabel: "Add New Owner"
  },
  screen: {
    owner: "Owner",
    ownerPortal: "Owner Portal",
    addNewOwner: "New Owner",
    searchPlaceHolder: "Search owners",
    emptydataAddNew: "Add your first Owner",
    emptydataViewInstructionsText: "No Owners Found",
    agreement: "Agreement",
    editOwner: "Edit Owner",
    owners: "Owners",
    ownership: "Ownership",
    agreementExpires: "Agreement Expires",
    address: "Address",
    helpPanel: {
      dialogTitle: "Owners Overview",
      connectProperties: "Connecting owners to their properties",
      description: "Add owners so you can create reports for their properties and help track their returns.",
      createOwner: "Creating owners",
      distributeFunds: "Distributing money to an owner"
    },
    sendingInvite: "Sending invite...",
    loginToPortal: "Login to Portal",
    editOwnerPortalSettings: "Edit the owner portal settings."
  },
  details: {
    deleteConfirmationText: "Are you sure you want to delete the owner?",
    deleteLoadingText: "Deleting owner",
    deleteOwnerSuccessText: "Owner was deleted successfully",
    transactions: {
      newOwnerContribution: "New Contribution",
      newOwnerDistribution: "New Distribution"
    },
    propertyOwners: "Property Owners"
  },
  newOwner: {
    sameAsOwnerAddress: "Same as owner’s address",
    createNewOwner: "Create New Owner",
    loadingText: "Creating owner ...",
    errorText: "Something went wrong. Please try again later or contact us.",
    successText: "Owner created successfully",
    selectOwner: "Select Owner",
    menegmentAgreement: "Agreement",
    menegmentAgreementDescription: "What is the term of your management agreement?",
    agreementStartDate: "Agreement Start Date",
    agreementEndDate: "Agreement End Date",
    useElectronicPayments: "I want to pay this owner using Electronic Funds Transfer",
    dontUseElectronicPayments: "I don’t want to pay this owner electronically, I will pay by check or another method.",
    dontSend1099Forms: "Do not send 1099 forms to this owner",
    send1099Forms: "I want to send 1099 forms to this owner",
    taxpayerIDType: "Taxpayer ID Type",
    ownerLoginEmailPhoneDescription:
      "These details are required for owner portal access, communications, and notifications."
  },
  editOwners: {
    instructions: "Add one or more owners and set their ownership percentage.",
    addOwner: "Add Owner",
    ownershipInputlabel: "Ownership (%)",
    ownerPortalEnabled: "Owner portal enabled",
    ownershipValidationText: "Ownership must add up to exactly 100%",
    ownershipValidationNoOwnerSelected: "At least 1 owner must be selected",
    ownerAddingHelpText: "Learn about adding an Owner to a Property"
  },
  ownerContribution: {
    newOwnerContribution: "New Owner Contribution",
    contributionDate: "Contribution Date",
    contributionAmount: "Contribution Amount",
    ownerContributions: "Owner Contributions",
    amountReceived: "Amount Received",
    multipleProperties: "Multiple Properties",
    deleteContributionConfirmationText: "Are you sure you want to delete the owner contribution?",
    deleteContributionLoadingText: "Deleting owner contribution",
    deleteContributionSuccessText: "Successfully deleted"
  },
  ownerDistributions: {
    helpPanel: {
      description: "Pay out rental profit to your owners",
      recordDistribution: "How do I record an owner distribution?",
      editDeleteDistribution: "How do I edit or delete an owner distribution?"
    },
    wizardSteps: {
      recipients: "Recipients",
      accountReview: "Account Review",
      allocate: "Fund Allocation",
      distribute: "Distribution Method"
    },
    accountReviewStep: {
      title: "Properties that require your attention",
      description:
        "To proceed with distributions, all properties require assigned owners and opening balances set in their respective accounts.",
      listHeader: {
        property: "Property",
        owners: "Owners",
        openingBalance: "Opening Balance",
        undepositedFunds: "Undeposited funds"
      },
      depositFunds: "Deposit funds",
      missingInfo: "Missing info",
      ownersAssigned: "Owners assigned",
      openingBalanceSet: "Opening balance set",
      notAllOwnersFilledError: "To proceed, ensure that all properties have owners set for them.",
      openingBalanceToastText: "Opening balance has been set",
      setOwnersToastText: "Owner assigned to property",
      allDoneToastText: "All Done",
      confirmationDialogTitle: "Are you sure you want to continue?",
      confirmationDialogDescription:
        "Some of your accounts do not have an opening balance logged in the system. We recommend ensuring all accounts have an opening balance before continuing.",
      continue: "Continue",
      reviewAccounts: "Review Accounts"
    },
    distribution: "Distribution",
    selectOwnersAndProperties: "Properties",
    selectOwners: "Select owners",
    whichProperties: "Select the properties you would like to include",
    whichOwners: "Select the owners you wish to receive funds?",
    allOwners: "All Owners",
    someOwners: "Some Owners",
    noOutstandingBalanceFound:
      "Can't find any open outstanding balance to distribute. Redefine your search and try again.",
    enterDistributionAmounts: "Enter Distribution Amounts",
    cashBalance: "Cash Balance",
    reserve: "Reserve",
    maxDistribution: "Max Distribution",
    automaticDistribution: "Automatic Distribution",
    distributionAmount: "Distribution Amount",
    distributeMaxAmount: "Distribute Max Amount",
    availableDistributeForProperty: "Available to distribute for property",
    availableDistributeToOwners: "Available to distribute to owners",
    distributeFounds: "Distribute Funds",
    distributeAmount: "Distribution amount",
    distributeMethod: "Distribution method",
    distributeFoundsDescription: "Choose your payment method and create the payments",
    creatingExpenses: "Creating owner distributions",
    expensesCreated: "Owner distributions were created successfully",
    ownerPropertiesNoResults: "This owner is not associated with any property",
    ownerDistribution: "Owner Distribution",
    deleteDistributionConfirmationText:
      "Are you sure you want to delete this owner distribution? This will increase the available funds for the property. This may cause an incorrect future distribution if there is more than one owner for this property.",
    deleteDistributionLoadingText: "Deleting owner distribution",
    deleteDistributionSuccessText: "Successfully deleted",
    editOwnerDistribution: "Edit Owner Distribution",
    accountAvailableCash: "Account available cash",
    accountAvailableCashToDistribute: "Account available cash to distribute",
    remainsToDistribute: "Remaining distribution amount",
    propertyAccountsSummary: "Property Accounts Summary",
    undepositedFunds: "Undeposited funds",
    securityDepositLiabilities: "Security deposit liabilities",
    propertyReserve: "Property reserve",
    cashAvailableToDistributeForProperty: "Cash available to distribute for property",
    insufficientFundsWarning: "Funds in this account are insufficient to cover the amount allocated to this property",
    numberOfPayments: "Number of payments",
    warningTotalDistributeAmount:
      "The funds allocated to some properties exceed their current available account balance.",
    selectPortfolios: "Select Portfolios",
    paymentsDistributeDate: "Payments distribute date",
    totalDistributeAmount: "Total distribution amount",
    accounts: "Accounts",
    addMemo: "Add memo",
    addToPrintQueue: "Add to Print Queue",
    accountReviewEmptyStateTitle: "You're all set to move on to the next step!",
    accountReviewEmptyStateSubTitle: "All properties are ready for owner distributions.",
    checkNumber: "Check No.",
    consolidated: "Consolidated",
    unableToRemoveProperty: "Unable to remove this property. You must have at least one property in order to continue.",
    mustSelectOneProperty: "You must select at least one property in order to continue with the distribution",
    mustSelectOneOwner: "You must select at least one owner in order to continue with the distribution",
    completed: "Owners distribution completed",
    viewTransactionsReport: "View transactions report",
    viewOwnerStatement: "View owner statement",
    printChecks: "Print Checks",
    completeEpay: "Complete ePay",
    tooltipResetOwnerDistributionAmount: "Set the amount to match the original ownership percentage",
    tooltipRemoveFromDistribution: "Remove from distribution"
  }
};
