"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalApplicationsChargesTypes = void 0;
var RentalApplicationsChargesTypes;
(function (RentalApplicationsChargesTypes) {
    RentalApplicationsChargesTypes["CHARGE_APPLICANT_CONNECT"] = "CHARGE_APPLICANT_CONNECT";
    RentalApplicationsChargesTypes["CHARGE_CUSTOM_FEE_CONNECT"] = "CHARGE_CUSTOM_FEE_CONNECT";
    RentalApplicationsChargesTypes["CHARGE_USER_CONNECT"] = "CHARGE_USER_CONNECT";
    RentalApplicationsChargesTypes["CHARGE_USER_BILLING"] = "CHARGE_USER_BILLING";
})(RentalApplicationsChargesTypes || (exports.RentalApplicationsChargesTypes = RentalApplicationsChargesTypes = {}));
