"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAllConversationMessagesQuery = void 0;
const getAllBaseRequest_query_1 = require("../getAllBaseRequest.query");
class GetAllConversationMessagesQuery extends getAllBaseRequest_query_1.GetAllBaseQueryRequest {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.GetAllConversationMessagesQuery = GetAllConversationMessagesQuery;
