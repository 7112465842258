"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HOAWelcomePropertyTypeClient = exports.WelcomePropertyTypeClient = exports.WelcomePropertyType = void 0;
var WelcomePropertyType;
(function (WelcomePropertyType) {
    WelcomePropertyType["RESIDENTIAL"] = "Residential";
    WelcomePropertyType["COMMERCIAL"] = "Commercial";
    WelcomePropertyType["INDUSTRIAL"] = "Industrial";
    WelcomePropertyType["AFFORDABLE"] = "Affordable Housing";
    WelcomePropertyType["STUDENT"] = "Student Housing";
    WelcomePropertyType["ASSOCIATIONS"] = "Community Associations";
})(WelcomePropertyType || (exports.WelcomePropertyType = WelcomePropertyType = {}));
var WelcomePropertyTypeClient;
(function (WelcomePropertyTypeClient) {
    WelcomePropertyTypeClient["RESIDENTIAL"] = "Residential";
    WelcomePropertyTypeClient["COMMERCIAL"] = "Commercial";
    WelcomePropertyTypeClient["INDUSTRIAL"] = "Industrial";
    WelcomePropertyTypeClient["AFFORDABLE"] = "Affordable Housing";
    WelcomePropertyTypeClient["STUDENT"] = "Student Housing";
})(WelcomePropertyTypeClient || (exports.WelcomePropertyTypeClient = WelcomePropertyTypeClient = {}));
var HOAWelcomePropertyTypeClient;
(function (HOAWelcomePropertyTypeClient) {
    HOAWelcomePropertyTypeClient["RESIDENTIAL"] = "Residential";
    HOAWelcomePropertyTypeClient["COMMERCIAL"] = "Commercial";
})(HOAWelcomePropertyTypeClient || (exports.HOAWelcomePropertyTypeClient = HOAWelcomePropertyTypeClient = {}));
