import React, { useCallback, useEffect, useState } from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { AnimatedView } from "DLUI/view/animatedView";
import { Icon } from "DLUI/icon";
import { PlusCircleIcon, XIcon } from "../../../../../assets";
import { IconButton } from "DLUI/form";
import { useTranslation } from "react-i18next";
import TextEllipsis from "DLUI/text/textEllipsis";

interface CreateOptionType {
  CreateNewOptionComponent: React.FC<any>;
  name: string;
  setShowCreateOption: (bool: boolean) => void;

  // Custom props for CreateNewOptionComponent
  [prop: string]: any;
}

interface Props {
  option: CreateOptionType;
  inputValue: string;
  plusIconProps?: any;
}

export const CreateOptionItem: React.FC<Props> = ({ option, inputValue, plusIconProps }: Props) => {
  const { t } = useTranslation();
  const { CreateNewOptionComponent, name, setShowCreateOption } = option;
  const [showCreateNewForm, setShowCreateNewForm] = useState<boolean>(false);
  const onOpen = useCallback(
    (ev) => {
      ev.stopPropagation();

      // this Event fires on every click on the whole area of the createNew form
      // to allow correct use case of the input inside - this trick allows
      // on single click to set the cursor in the end of the value as wanted
      // on double click skip the trick which will select the whole value of the field
      if (ev.target.tagName.toUpperCase() === "INPUT" && ev.detail === 1) {
        ev.target.focus();
        const currentValue = ev.target.value;
        ev.target.value = "";
        ev.target.value = currentValue;
      }

      if (!showCreateNewForm) {
        setShowCreateNewForm(true);
        setShowCreateOption(true);
      }
    },
    [showCreateNewForm]
  );

  const onClose = useCallback(
    (ev) => {
      ev.stopPropagation();

      setShowCreateNewForm(false);
    },
    [showCreateNewForm]
  );

  useEffect(
    () => () => {
      setShowCreateOption(false);
    },
    []
  );

  return (
    <View className={"DLUI-AutoComplete-option create-option"} onClick={onOpen} onKeyDown={onOpen}>
      <AnimatedView flexDirection={"row"} noWrap shouldShow={!showCreateNewForm}>
        <View flexDirection={"row"} alignItems={"center"}>
          <Icon
            marginRight={10}
            Source={PlusCircleIcon}
            pathColor={"white"}
            circleFill={"light-blue"}
            width={20}
            {...plusIconProps}
          />
          <View flex={1}>
            <TextEllipsis text={t(name)} lines={1} ellipsisPosition={"end"} />
          </View>
        </View>
      </AnimatedView>
      <AnimatedView shouldShow={showCreateNewForm}>
        <View flexDirection={"row"} alignItems={"center"}>
          <View flex={1}>
            <TextEllipsis bold text={t(name)} lines={1} ellipsisPosition={"end"} fontSize={15} />
          </View>
          <IconButton Icon={XIcon} pathColor={"light-gray"} onClick={onClose} />
        </View>
        <CreateNewOptionComponent {...option} inputValue={inputValue} />
      </AnimatedView>
    </View>
  );
};

const OptionItem: React.FC = (option: Partial<Record<string, any>>) => (
  <View className={"DLUI-AutoComplete-option"}>
    <Text>{option.name}</Text>
  </View>
);

export default OptionItem;
