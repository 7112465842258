import type { PaymentAllocationAccountDraggableItemDto, PaymentAllocationsSettingsDto, UnitDto } from "@doorloop/dto";
import { createValidator, PaymentAllocationAllocateByEnum, PaymentAllocationsSettingsDisplayDto } from "@doorloop/dto";
import type { ApiResult } from "api/apiResult";
import { propertiesApi } from "api/propertiesApi";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum } from "DLUI/button/dlButton";
import { ActionPanel, DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import { RadioGroup, Select } from "DLUI/form";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, Formik } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import DraggableList from "DLUI/draggableList/draggableList";
import PaymentAccountInputItem from "DLUI/dialogs/settings/properties/editPaymentAllocations/paymentAccountOrderItem";
import { useParams } from "react-router-dom";
import { Link } from "DLUI/link";
import { Icon } from "DLUI/icon";
import { AddIcon } from "../../../../../../assets";
import { useEffectAsync } from "../../../../../../hooks/useEffectAsync";
import { settingsApi } from "api/settingsApi";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { ArticleIdsEnum, HelpTypeEnum } from "DLUI/screen/helpPanel/types";
import ValidationIndicator from "DLUI/form/validationIndicator/validationIndicator";
import { AnimatedView } from "DLUI/view/animatedView";
import { v4 as uuid } from "uuid";
import { useResponsiveHelper } from "../../../../../../contexts/responsiveContext";
import { useUserType } from "../../../../../../hooks/useUserType";

interface ComponentProps {
  didFinishOperation: (values: UnitDto) => void;
  onBackdropClick: () => void;
  dialogTitle: string;
}

let formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

const helpObj: HelpPanelProps = {
  description: AppStrings.Settings.GeneralSettings.PaymentAllocation.PaymentAllocationsSettingsDialogSubTitle,
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Settings.GeneralSettings.PaymentAllocation.HelpText,
      articleId: ArticleIdsEnum.PAYMENT_ALLOCATIONS
    }
  ]
};

const helpObjHOA: HelpPanelProps = {
  description: AppStrings.Settings.GeneralSettings.PaymentAllocation.PaymentAllocationsSettingsDialogSubTitle,
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Settings.GeneralSettings.PaymentAllocation.HelpText,
      articleId: ArticleIdsEnum.HOA_PAYMENT_ALLOCATIONS
    }
  ]
};

const validateForm = createValidator(PaymentAllocationsSettingsDisplayDto);

const EditPaymentAllocations: React.FC<ComponentProps> = ({
  didFinishOperation,
  onBackdropClick,
  dialogTitle
}: ComponentProps) => {
  const { isHOAUser } = useUserType();
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();
  const { dialogHorizontalPadding } = DialogsHelper();
  const { propertyId } = useParams<any>();
  const [viewIndex, setViewIndex] = useState<number>(1);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>(AppStrings.Common.NetworkErrorSubTitle);
  const [hideLoadingDialogActionButtons, setHideLoadingDialogActionButtons] = useState<boolean>(false);
  const [noAccountsToSubmitError, setNoAccountsToSubmitError] = useState<boolean>(false);
  const [hasCriticalError, setHasCriticalError] = useState(false);
  const [defaultSettingsSelection, setDefaultSettingsSelection] = useState<"companyDefault" | "specifyAdditionalInfo">(
    "companyDefault"
  );

  const handleLoadedData = (paymentAllocations: PaymentAllocationsSettingsDisplayDto) => {
    if (paymentAllocations) {
      const allocateBy = paymentAllocations?.allocateBy;

      if (formikGlobalRef && allocateBy !== undefined) {
        formikGlobalRef.setFieldValue("allocateBy", allocateBy);

        const paymentAccountsFromDB = paymentAllocations?.accounts?.map((acc, idx) => {
          return {
            uniqueId: uuid(),
            account: acc
          };
        });

        if (paymentAccountsFromDB) {
          formikGlobalRef.setFieldValue("accounts", paymentAccountsFromDB);

          if (defaultSettingsSelection !== "specifyAdditionalInfo") {
            setDefaultSettingsSelection("specifyAdditionalInfo");
          }
        }
      }
    }

    if (!propertyId && defaultSettingsSelection !== "specifyAdditionalInfo") {
      setDefaultSettingsSelection("specifyAdditionalInfo");
    }

    setViewIndex(0);
  };

  const showDialogError = (errorMessage?: string, isCritical = false) => {
    setHideLoadingDialogActionButtons(false);
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(errorMessage || t(AppStrings.Common.GeneralError));
    setHasCriticalError(isCritical);
  };

  useEffectAsync(async () => {
    let responseData;
    if (propertyId) {
      const response = await propertiesApi.get(propertyId).catch((e: string) => {
        showDialogError(e);
      });

      if (response && response.status) {
        responseData = response.data?.settings?.paymentAllocations;
      } else {
        showDialogError(response?.message, true);
      }
    } else {
      const response = await settingsApi.getPaymentAllocations().catch((e: string) => {
        showDialogError(e);
      });

      if (response && response.status) {
        responseData = response.data;
      } else {
        showDialogError(response?.message, true);
      }
    }

    handleLoadedData(responseData);
  }, []);

  useEffect(
    () => () => {
      formikGlobalRef = null;
    },
    []
  );

  const initFormvalues = (): PaymentAllocationsSettingsDisplayDto => {
    if (formikGlobalRef && formikGlobalRef.values) {
      return formikGlobalRef.values;
    }

    return new PaymentAllocationsSettingsDisplayDto({
      allocateBy: propertyId ? "" : PaymentAllocationAllocateByEnum.OLDEST_CHARGE_FIRST
    });
  };

  const isValidForm = async (formikRef: FormikProps<PaymentAllocationsSettingsDisplayDto>) => {
    formikRef.setFieldTouched(`allocateBy`);

    for (const accIdx in formikRef.values.accounts) {
      const acc = formikRef.values.accounts[accIdx];
      if (!acc?.account) {
        formikRef.setFieldTouched(`accounts[${accIdx}].account`);
      }
    }

    const errors = (await formikRef.validateForm()) as any;

    if (
      formikRef.values?.allocateBy === PaymentAllocationAllocateByEnum.ALLOCATE_MANUALLY &&
      !formikRef.values?.accounts?.length
    ) {
      setNoAccountsToSubmitError(true);
      return false;
    }
    setNoAccountsToSubmitError(false);

    if (defaultSettingsSelection === "specifyAdditionalInfo" && errors.allocateBy) {
      return false;
    }

    return _.isEmpty(errors.accounts);
  };

  const updateAccountsAllocation = async (values: PaymentAllocationsSettingsDisplayDto) => {
    const accountsData = _.cloneDeep(values) as PaymentAllocationsSettingsDto;
    accountsData.allocateBy = (values?.allocateBy || undefined) as PaymentAllocationAllocateByEnum | undefined;
    if (values.accounts) {
      accountsData.accounts = values.accounts.filter((acc) => acc.account).map((acc) => acc.account!);
    }

    let response;

    if (propertyId) {
      response = (await propertiesApi.updatePaymentAllocations(propertyId, accountsData).catch((error) => {
        showDialogError(error);
      })) as ApiResult<any>;
    } else {
      response = (await settingsApi.updatePaymentAllocations(accountsData).catch((error) => {
        showDialogError(error);
      })) as ApiResult<any>;
    }

    if (response.status !== undefined && !response.status) {
      showDialogError(response.message || t(AppStrings.Common.GeneralError), true);
    } else {
      setLoadingDialogState(DialogState.Success);
      didFinishUpdateProperty();
      formikGlobalRef = null;
    }
  };

  const didPressSaveButton = async () => {
    if (formikGlobalRef !== null) {
      const isValid = await isValidForm(formikGlobalRef);
      if (isValid) {
        setLoadingDialogState(DialogState.Show);
        setViewIndex(1);
        await updateAccountsAllocation(formikGlobalRef.values);
      }
    }
  };

  const renderActionPanelButtons = () => {
    if (viewIndex === 1) {
      return <div />;
    }

    return (
      <ActionPanel paddingRight={isMobile ? 0 : 20}>
        <DLButton
          actionText={AppStrings.Common.Cancel}
          size={DLButtonSizesEnum.MEDIUM}
          color={DLButtonColorsEnum.NEUTRAL}
          onClick={onBackdropClick}
          style={{ marginRight: 20 }}
        />
        <DLButton
          actionText={AppStrings.Common.Save}
          size={DLButtonSizesEnum.MEDIUM}
          color={DLButtonColorsEnum.PRIMARY}
          onClick={didPressSaveButton}
        />
      </ActionPanel>
    );
  };

  const didPressDismissButton = () => {
    if (hasCriticalError) {
      onBackdropClick();
    } else {
      setLoadingDialogState(DialogState.Hidden);
      setViewIndex(0);
    }
  };

  const onRetryButtonPress = () => {
    if (formikGlobalRef !== null) {
      setLoadingDialogState(DialogState.Hidden);
      setViewIndex(0);
    }
  };

  const didFinishUpdateProperty = () => {
    if (formikGlobalRef !== null) {
      didFinishOperation(formikGlobalRef.values);
      setLoadingDialogState(DialogState.Hidden);
    }
  };

  const didChangeAllocateBy = (selectedValue: string) => {
    if (formikGlobalRef !== null) {
      formikGlobalRef.setFieldValue("allocateBy", selectedValue);

      if (selectedValue === PaymentAllocationAllocateByEnum.OLDEST_CHARGE_FIRST) {
        formikGlobalRef.setFieldValue("accounts", []);
        resetErrors();
      }
    }
  };

  const resetErrors = () => {
    if (formikGlobalRef !== null && formikGlobalRef.errors?.accounts) {
      formikGlobalRef.setFieldError("accounts", undefined);
      formikGlobalRef.setFieldTouched("accounts", false, false);
    }

    if (noAccountsToSubmitError) {
      setNoAccountsToSubmitError(false);
    }
  };

  const addNewPaymentAccount = () => {
    if (formikGlobalRef) {
      const currentAccountsList = formikGlobalRef.values.accounts || [];
      const newAccounts = [
        ...currentAccountsList,
        {
          uniqueId: uuid()
        }
      ];

      formikGlobalRef.setFieldValue("accounts", newAccounts);
    }

    if (noAccountsToSubmitError) {
      setNoAccountsToSubmitError(false);
    }
  };

  const handleDataSourceReorder = (newDataSource) => {
    if (formikGlobalRef) {
      formikGlobalRef.setFieldValue("accounts", newDataSource);
    }
  };

  const didChangeSettingsSelection = (selectedValue: string) => {
    if (selectedValue === "false" && formikGlobalRef) {
      formikGlobalRef.setFieldValue("allocateBy", "");
      formikGlobalRef.setFieldValue("accounts", undefined);

      formikGlobalRef.setFieldTouched("allocateBy", false, false);
      resetErrors();
    } else if (formikGlobalRef && selectedValue === "true") {
      formikGlobalRef.setFieldValue("allocateBy", PaymentAllocationAllocateByEnum.OLDEST_CHARGE_FIRST);
    }
    setDefaultSettingsSelection(selectedValue === "false" ? "companyDefault" : "specifyAdditionalInfo");
  };

  const renderForm = () => {
    const formInitialValues = initFormvalues();
    return (
      <Formik initialValues={formInitialValues} onSubmit={(values, { setSubmitting }) => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;

          return (
            <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding} height={"auto"}>
              <View alignItems={"flex-start"}>
                {propertyId && (
                  <>
                    <RadioGroup
                      didChangeSelection={didChangeSettingsSelection}
                      uniqueKey={"FRB"}
                      defaultValue={defaultSettingsSelection === "companyDefault" ? "false" : "true"}
                      marginTop={10}
                      radioGroupItems={[
                        {
                          label: AppStrings.Common.HideSectionForProperty,
                          value: "false"
                        },
                        {
                          label: AppStrings.Common.SpecifySettingsForProperty,
                          value: "true"
                        }
                      ]}
                    />
                    <HorizontalSeparationLine height={2} marginTop={10} />
                  </>
                )}
                <AnimatedView shouldShow={defaultSettingsSelection === "specifyAdditionalInfo"} paddingBottom={50}>
                  <Text
                    value={
                      AppStrings.Settings.GeneralSettings.PaymentAllocation
                        .PaymentAllocationsSettingsDialogHowToAllocate
                    }
                    fontSize={14}
                    color={"secondary-gray"}
                    marginTop={20}
                    marginBottom={10}
                  />
                  <FastField
                    component={Select}
                    name={`allocateBy`}
                    label={AppStrings.Settings.GeneralSettings.PaymentAllocation.AllocateBy}
                    uniqueKey={"AllocateBy"}
                    selectionEnum={PaymentAllocationAllocateByEnum}
                    marginTop={20}
                    translationKey={"paymentAllocationAllocateByEnum"}
                    onChange={didChangeAllocateBy}
                    required
                  />
                  {formik.values?.allocateBy === PaymentAllocationAllocateByEnum.ALLOCATE_MANUALLY && (
                    <View>
                      <Text
                        value={AppStrings.Settings.GeneralSettings.PaymentAllocation.Categories}
                        fontSize={14}
                        fontWeight={700}
                        marginTop={20}
                      />
                      <Text
                        value={AppStrings.Settings.GeneralSettings.PaymentAllocation.CategoriesReOrderDescription}
                        fontSize={14}
                        lineHeight={"19px"}
                        color={"secondary-gray"}
                        marginTop={10}
                        marginBottom={10}
                      />
                      <View>
                        <View>
                          <DraggableList<PaymentAllocationAccountDraggableItemDto>
                            dataSource={formik.values?.accounts!}
                            ListItemComponent={PaymentAccountInputItem}
                            onDataSourceChange={handleDataSourceReorder}
                            itemMarginTop={5}
                          />
                        </View>
                        <Link
                          onClick={addNewPaymentAccount}
                          underline={"hover"}
                          hoverColor={"lightBlue"}
                          marginLeft={5}
                        >
                          <View flexDirection={"row"} noWrap alignItems={"center"} marginTop={10} marginBottom={10}>
                            <Icon Source={AddIcon} size={14} marginRight={5} />
                            <Text
                              fontSize={14}
                              color={"lightBlue"}
                              value={AppStrings.Settings.GeneralSettings.PaymentAllocation.AddACategory}
                            />
                          </View>
                        </Link>
                        <ValidationIndicator
                          shouldShow={noAccountsToSubmitError}
                          fullWidth
                          displayText={t(
                            AppStrings.Settings.GeneralSettings.PaymentAllocation.NoAccountsSelectedForAllocation
                          )}
                          justifyContent={"center"}
                        />
                      </View>
                    </View>
                  )}
                </AnimatedView>
              </View>
            </View>
          );
        }}
      </Formik>
    );
  };

  const renderView = ({ index }: any) => {
    if (index === 0) {
      return renderForm();
    }
    if (index === 1) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={t(AppStrings.Common.Loading)}
            errorText={loadingDialogErrorText}
            successText={t(AppStrings.Common.OperationCompleted)}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
            hideButtons={hideLoadingDialogActionButtons}
          />
        </View>
      );
    }
    return <div />;
  };

  const _onBackdropClick = () => {
    if (viewIndex === 1) {
      setViewIndex(0);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      return "sectionTitleFrame";
    }
    if (viewIndex === 1) {
      return "contentOnly";
    }

    return "topPanel";
  }, [viewIndex]);

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={dialogTitle}
      width={getDialogFrameDimension("width", 620)}
      height={getDialogFrameDimension("height", 660)}
      renderView={renderView}
      numViews={2}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={frameType}
      helpPanel={!isHOAUser ? helpObj : helpObjHOA}
    />
  );
};

export default EditPaymentAllocations;
