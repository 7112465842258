import type { LocaleData } from "store/auth/actions";

interface SupportedLocale {
  locale: string;
  currency: string;
}

const getSupportedLocales = (): SupportedLocale[] => [
  {
    locale: "en-US",
    currency: "USD"
  },
  {
    locale: "en-CA",
    currency: "CAD"
  }
];

export const isLocaleSupportedToReceivePayments = ({ locale, currency }: LocaleData): boolean => {
  const supportedLocales = getSupportedLocales();

  const supportedLocalesByLocale = supportedLocales.filter(
    (supportedLocale) => supportedLocale.locale === locale && supportedLocale.currency === currency
  );

  return supportedLocalesByLocale.length > 0;
};
