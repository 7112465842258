import type React from "react";
import type { StepPropsWithDto } from "screens/onBoarding/types";
import type { OnBoardingDto } from "@doorloop/dto";
import { ObjectPermission, SettingPermission, SettingsAccessEnum } from "@doorloop/dto";
import SubscribeToDoorLoopStep from "screens/onBoarding/steps/subscribeToDoorloopStep";
import ScheduleOnBoardingStep from "screens/onBoarding/steps/scheduleOnBoardingStep";
import AddCompanyInfoStep from "screens/onBoarding/steps/addCompanyInfoStep";
import AddPropertiesStep from "screens/onBoarding/steps/addPropertiesStep";
import AcceptRentPaymentsStep from "screens/onBoarding/steps/acceptRentPaymentsStep";
import SetupLateFeesStep from "screens/onBoarding/steps/setupLateFeesStep";
import CustomizeTenantPortalStep from "screens/onBoarding/steps/customizeTenantPortalStep";
import AddYourLeasesStep from "screens/onBoarding/steps/addYourLeasesStep";
import SendAnnouncementStep from "screens/onBoarding/steps/sendAnnouncementStep";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { SmsMessagingStep } from "screens/onBoarding/steps/smsMessagingStep";
import type { ProjectFeatureIds } from "../../../../config/featureFlags/features";

export const OnBoardingSteps: Array<{
  element: React.ElementType<StepPropsWithDto>;
  name: keyof OnBoardingDto;
  permissions: AnyPermissionClearance[];
  featureFlag?: ProjectFeatureIds;
}> = [
  { name: "subscribed", element: SubscribeToDoorLoopStep, permissions: [] },
  { name: "scheduledOnBoarding", element: ScheduleOnBoardingStep, permissions: [] },
  {
    name: "addedCompanyInfo",
    element: AddCompanyInfoStep,
    permissions: [
      { permission: SettingPermission.companyInformation, requiredLevel: SettingsAccessEnum.VIEW },
      { permission: SettingPermission.companyInformation, requiredLevel: SettingsAccessEnum.EDIT }
    ]
  },
  { name: "units", element: AddPropertiesStep, permissions: [] },
  {
    name: "acceptsRentPayments",
    element: AcceptRentPaymentsStep,
    permissions: [
      { permission: ObjectPermission.accounts, field: "edit" },
      { permission: ObjectPermission.accounts, field: "viewList" }
    ]
  },
  {
    name: "addedLateFees",
    element: SetupLateFeesStep,
    permissions: [
      { permission: SettingPermission.lateFees, requiredLevel: SettingsAccessEnum.VIEW },
      { permission: SettingPermission.lateFees, requiredLevel: SettingsAccessEnum.EDIT }
    ]
  },
  {
    name: "customizedTenantPortal",
    element: CustomizeTenantPortalStep,
    permissions: [
      { permission: SettingPermission.tenantPortal, requiredLevel: SettingsAccessEnum.VIEW },
      { permission: SettingPermission.tenantPortal, requiredLevel: SettingsAccessEnum.EDIT }
    ]
  },
  { name: "leases", element: AddYourLeasesStep, permissions: [] },
  {
    name: "sentAnnouncement",
    element: SendAnnouncementStep,
    permissions: [{ permission: ObjectPermission.announcements, field: "create" }]
  },
  {
    name: "smsMessaging",
    element: SmsMessagingStep,
    permissions: [{ permission: ObjectPermission.phoneNumbers, field: "create" }],
    featureFlag: "CommunicationsCenter"
  }
];
