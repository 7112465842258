import React, { Fragment, useMemo } from "react";
import { TenantDto, TenantType } from "@doorloop/dto";
import type { SVGIconComponent } from "assets/icons";
import { CheckmarkCircleIcon, ClockOrangeIcon, MailIcon, PhoneIcon } from "assets/icons";
import { Icon } from "DLUI/icon";
import { Grid, ListItemContainer } from "DLUI/listItems";
import { ProfileImage } from "DLUI/profileImage";
import type { TextColor } from "DLUI/text/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import { DefaultListItemIconSize } from "screens/leases/leases/leasesList/leaseListItem";
import ListItemText from "DLUI/listItems/listItemText";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  didSelectTenantItem: (selectedItem: TenantDto) => void;
  tenant?: TenantDto;
  selectableListItem?: boolean;
}

const SelectableTenantsListItem: React.FC<ComponentProps> = ({ didSelectTenantItem, tenant }: ComponentProps) => {
  const { isTabletOrMobile } = useResponsiveHelper();

  const _didSelectTenantItem = () => {
    if (tenant) {
      const selectedTenant = new TenantDto(tenant);
      if (didSelectTenantItem) {
        didSelectTenantItem(selectedTenant);
      }
    }
  };

  const renderEmail = () => {
    if (tenant) {
      const { emails } = tenant;
      if (emails && emails.length > 0) {
        return (
          <Fragment>
            <Icon width={DefaultListItemIconSize} Source={MailIcon} pathColor={"black"} />

            <View flex={1}>
              <ListItemText marginLeft={10} align={"left"} color={"black"}>
                {emails[0].address ? emails[0].address : "--"}
              </ListItemText>
            </View>
          </Fragment>
        );
      }
    }
  };

  const renderPhone = () => {
    if (tenant) {
      const { phones } = tenant;
      if (phones && phones.length > 0) {
        return (
          <Fragment>
            <Icon width={DefaultListItemIconSize} Source={PhoneIcon} pathColor={"black"} />
            <ListItemText marginLeft={10} align={"left"} color={"black"}>
              {phones[0].number ? phones[0].number : "--"}
            </ListItemText>
          </Fragment>
        );
      }
    }
  };

  const pictureUrl = tenant ? tenant.pictureUrl : undefined;
  const name = tenant ? tenant.name : undefined;

  const { StatusIcon, statusTextColor, statusText } = useMemo(() => {
    let StatusIcon: SVGIconComponent = CheckmarkCircleIcon;
    let statusTextColor: TextColor = "black";
    let statusText = "Prospect";

    const type = tenant?.type;

    if (type && type === TenantType.LEASE_TENANT) {
      StatusIcon = CheckmarkCircleIcon;
      statusTextColor = "green";
      statusText = AppStrings.Tenants.Screen.Tenant;
      return { StatusIcon, statusTextColor, statusText };
    }
    StatusIcon = ClockOrangeIcon;
    statusTextColor = "orange";
    statusText = AppStrings.Prospects.Screen.Prospect;
    return { StatusIcon, statusTextColor, statusText };
  }, []);

  return (
    <ListItemContainer>
      <Grid title={AppStrings.Common.FilterOptions.Name} onClick={_didSelectTenantItem} showDivider md={4} lg={4}>
        <View alignItems={"center"} flexDirection={"row"}>
          <ProfileImage marginLeft={isTabletOrMobile ? 0 : 10} pictureUrl={pictureUrl} size={40} />
          <View flex={1}>
            <ListItemText marginLeft={10} align={"left"} color={"black"}>
              {name}
            </ListItemText>
            <View alignItems={"center"} marginLeft={10} flexDirection={"row"}>
              <StatusIcon width={DefaultListItemIconSize} />
              <View flex={1}>
                <ListItemText marginLeft={5} color={statusTextColor} value={statusText} />
              </View>
            </View>
          </View>
        </View>
      </Grid>
      <Grid title={AppStrings.Tenants.NewTenant.Phone} onClick={_didSelectTenantItem} showDivider xs={12} md={4} lg={4}>
        <View paddingLeft={isTabletOrMobile ? 10 : 20} alignItems={"center"} flexDirection={"row"} noWrap>
          {renderPhone()}
        </View>
      </Grid>
      <Grid title={AppStrings.Tenants.NewTenant.Email} onClick={_didSelectTenantItem} xs={4} md={4} lg={4}>
        <View paddingLeft={isTabletOrMobile ? 10 : 20} alignItems={"center"} flexDirection={"row"}>
          {renderEmail()}
        </View>
      </Grid>
    </ListItemContainer>
  );
};

export default SelectableTenantsListItem;
