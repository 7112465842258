export enum DialogRoutes {
  NEW_UNIT = "units/new",
  EDIT_UNIT = "units/edit",
  NEW_TENANT = "tenants/new",
  EDIT_TENANT = "tenants/edit",
  EDIT_VENDOR = "vendors/edit",
  NEW_OWNER = "owners/new",
  EDIT_OWNER = "owners/edit",
  NEW_VENDOR = "vendors/new",
  NEW_PROSPECT = "prospects/new",
  NEW_USER = "company-users/new",
  EDIT_USER = "company-users/edit",
  EDIT_USER_ROLE = "user-roles/edit/",
  EDIT_PROSPECT = "prospects/edit",
  NEW_CHARGE = "charge/new",
  EDIT_CHARGE = "charge/edit",
  NEW_BULK_CHARGE = "bulk-charge/bulk-new",
  NEW_BULK_CREDIT = "bulk-credit/bulk-new",
  EDIT_VENDOR_BILL = "bill/edit",
  EDIT_RECURRING_VENDOR_BILL = "edit-recurring-bill",
  EDIT_VENDOR_BILL_PAYMENT = "bill-payment/edit",
  NEW_FILE = "files/new",
  EDIT_FILE = "edit/file",
  NEW_NOTE = "notes/new",
  EDIT_NOTE = "edit/note",

  WORK_ORDERS_REPORT_EDIT_USER = "work-orders/users/edit",

  PRINT_SETUP = "print-setup",
  PRINT_REMOVE_CHECK = "remove-check",
  PRINT_CHECKS_REVIEW_PRINT = "print-checks/preview/review-printing",

  EXPORT_TAX_FORMS_1099_MISC = "export/1099-misc",
  EXPORT_TAX_FORMS_1099_NEC = "export/1099-nec",

  CONFIRM = "/confirm",
  ALERT = "/alert",
  NEW_CREDIT = "credit/new",
  EDIT_CREDIT = "credit/edit",
  NEW_LEASE_DEPOSIT = "lease-deposit/new",
  NEW_INSURANCE_POLICY = "insurance-policy/new",
  EDIT_INSURANCE_POLICY = "insurance-policy/edit",
  NEW_RECURRING_CHARGE = "recurring-charge/new",
  NEW_RECURRING_PAYMENT = "recurring-payment/new",
  NEW_OWNER_CONTRIBUTION = "owner-contribution/new",
  EDIT_OWNER_CONTRIBUTION = "owner-contribution/edit",
  EDIT_RECURRING_GENERAL_ENTRY = "recurring-general-entry/edit",
  EDIT_RECURRING_PAYMENT = "recurring-payment/edit",
  EDIT_RECURRING_RENT = "recurring-rent/edit",
  EDIT_RECURRING_CHARGE = "recurring-charge/edit",
  NEW_RECURRING_CREDIT = "recurring-credit/new",
  EDIT_RECURRING_CREDIT = "recurring-credit/edit",
  NEW_RECURRING_RENT = "recurring-rent/new",
  NEW_PAYMENT = "payment/new",
  EDIT_PAYMENT = "payment/edit",
  EDIT_APPLIED_CREDIT = "applied-credit/edit",
  NEW_REFUND = "refund/new",
  EDIT_REFUND = "refund/edit",
  NEW_VENDOR_BILL = "bill/new",
  NEW_VENDOR_EXPENSE = "expense/new",
  EDIT_EXPENSE = "expense/edit",
  EDIT_RECURRING_EXPENSE = "edit-recurring-expense",
  NEW_VENDOR_CREDIT = "vendor-credit/new",
  EDIT_VENDOR_CREDIT = "vendor-credit/edit",
  EDIT_PROPERTY_OWNERS = "edit/owners",
  EDIT_PROPERTY = "edit/property",
  EDIT_BANK_ACCOUNTS = "edit/bank-accounts",
  EDIT_RESERVE_FUNDS = "edit/reserve-funds",
  EDIT_MANAGEMENT_FEES = "edit/management-fees",
  EDIT_PAYMENT_ALLOCATIONS = "edit/payment-allocations",
  EDIT_LATE_FEES = "edit/late-fees",
  EDIT_TENANT_PORTAL = "edit/tenant-portal",
  EDIT_OWNER_PORTAL = "edit/owner-portal",
  EDIT_LEASE_NAME = "edit/lease-name",
  EDIT_LEASE_TENANT_PORTAL = "edit/lease-tenant-portal",
  EDIT_RENTERS_INSURANCE = "edit/renters-insurance",
  EDIT_PAYMENTS_ALLOCATION = "edit/payments-allocation",
  NEW_RENTAL_APPLICATION = "rental-applications/new",
  EDIT_RENTAL_APPLICATION = "edit/rental-application",
  EDIT_RENTAL_APPLICATIONS_SETTINGS = "edit/rental-applications-settings",
  EDIT_CONVENIENCE_FEES_SETTINGS = "edit/convenience-fees",
  UPDATE_RENTAL_APPLICATION_DECISION_STATUS = "edit/decision-status",
  EDIT_RENT_REMINDERS = "edit/rent-reminders",
  EDIT_PAYMENT_INSTRUCTIONS = "edit/payment-instructions",
  EDIT_SERVICES = "edit/services",
  EDIT_TENANT_REQUEST_NOTIFICATIONS = "edit/tenant-request-notifications",
  EDIT_OWNER_REQUEST_NOTIFICATIONS = "edit/owner-request-notifications",
  EDIT_PERSONAL_INFORMATION = "general-settings/edit/personal-information",
  EDIT_LOGIN_AND_PASSWORD = "general-settings/edit/login-and-password",
  EDIT_COMPANY_INFORMATION = "general-settings/edit/company-information",
  EDIT_COMPANY_START_DATE = "general-settings/edit/company-start-date",
  EDIT_COMPANY_START_DATE_FROM_LEASE_WIZARD = "new-lease/edit/company-start-date",
  EDIT_REGION_AND_CURRENCY = "general-settings/edit/region-and-currency",
  EDIT_DEFAULT_ACCOUNTS = "general-settings/edit/default-accounts",
  EDIT_TASK_CATEGORIES = "general-settings/edit/task-categories",
  EDIT_NOTE_CATEGORIES = "general-settings/edit/note-categories",
  EDIT_FILE_CATEGORIES = "general-settings/edit/file-categories",
  EDIT_VENDOR_CATEGORIES = "general-settings/edit/vendor-categories",
  EDIT_LEAD_SOURCE = "general-settings/edit/lead-source",
  EDIT_PORTFOLIOS = "general-settings/edit/portfolios",
  EDIT_COMMUNICATION_CENTER_SETTINGS = "general-settings/edit/communication-center",
  EDIT_OUTGOING_PAYMENTS = "outgoing-payments-settings/edit",
  EDIT_CUSTOM_ALLOCATIONS = "custom-allocations/edit",
  EDIT_LEASE = "edit/lease-details",
  EDIT_EVICTION_STATUS = "edit/eviction-status",
  REFUND_DEPOSIT = "refund-deposit/new",
  WITHHOLD_DEPOSIT = "withhold-deposit/new",
  RENEWAL_OFFER = "renewal-offer",
  NOT_RENEWING_OFFER = "not-renewing",
  MOVE_IN_TENANTS = "tenants/move-in",
  MOVE_OUT_TENANTS = "tenants/move-out",
  NEW_INTERNAL_TASK = "internal-task/new",
  EDIT_INTERNAL_TASK = "internal-task/edit",
  EDIT_RECURRING_TASK = "edit-recurring-task",
  NEW_OWNER_REQUEST = "owner-request/new",
  EDIT_OWNER_REQUEST = "owner-request/edit",
  NEW_TENANT_REQUEST = "tenant-request/new",
  EDIT_TENANT_REQUEST = "tenant-request/edit",
  NEW_WORK_ORDER = "work-order/new",
  EDIT_WORK_ORDER = "work-order/edit",
  EDIT_RECURRING_WORK_ORDER = "edit-recurring-work-order",
  POST_TASK_UPDATE = "task-update/post",
  CREATE_NEW = "create-new",
  GLOBAL_NEW = "global-new",
  NEW_BANK_TRANSFER = "bank-transfer/new",
  EDIT_BANK_TRANSFER = "bank-transfer/edit",
  NEW_GENERAL_ENTRY = "general-entry/new",
  EDIT_GENERAL_ENTRY = "general-entry/edit",
  NEW_BANK_DEPOSIT = "bank-deposit/new",
  EDIT_BANK_DEPOSIT = "bank-deposit/edit",
  NEW_CHART_OF_ACCOUNT = "chart-of-accounts/new",
  EDIT_CHART_OF_ACCOUNT = "chart-of-accounts/edit",
  BANK_ACCOUNT_TRANSACTIONS = "bank-transactions",
  BANK_ACCOUNT_CONNECT = "connect",
  BANK_ACCOUNT_REPAIR_CONNECTION = "repair-connection",
  BANK_ACCOUNT_DISCONNECT = "bank-disconnect",
  SYNC_QUICKBOOKS_ACCOUNT = "quickbooks-account/sync",
  VIEW_COMMUNICATION = "communications/view",
  VIEW_SIGNATURE_REQUEST = "signature-requests/view",
  EDIT_SIGNATURE_TEMPLATE = "signature-templates/edit",
  LISTING_SETTINGS = "listing-settings",
  API_KEYS = "api-keys",
  VERIFY_IDENTITY = "verify-identity",
  STRIPE_ACTION_REQUIRED_EIN = "stripe-required-ein",
  STRIPE_ACTION_REQUIRED_BANK_ACCOUNT = "stripe-required-bank-account",
  STRIPE_ACTION_REQUIRED_OTHER = "stripe-required-other",
  STRIPE_MERCHANT_ACCOUNT = "stripe-merchant-account",
  STRIPE_UPDATE_MERCHANT_ACCOUNT = "stripe-update-merchant-account",
  NEW_BOARD_MEMBER = "board-membership/new",
  EDIT_BOARD_MEMBER = "board-membership/edit",
  REMOVE_BOARD_TERM = "board-membership/remove-term",
  FILE_PREVIEW = "file-preview"
}
