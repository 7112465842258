import { useToggle } from "usehooks-ts";
import { Collapsible } from "DLUI/collapsible/collapsible";
import { SwitchButton } from "DLUI/form";
import type { PropsWithChildren, ReactNode } from "react";
import { useCallback } from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      gap: 16
    },
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      gap: 24
    }
  };
});

export interface CollapsibleWithSwitchButtonProps {
  title: ReactNode;
  onToggleChange?: (isOpen: boolean) => void;
  defaultState?: boolean;
  dataCyButton?: string;
}

export const CollapsibleWithSwitchButton = ({
  children,
  title,
  onToggleChange,
  defaultState = false,
  dataCyButton
}: PropsWithChildren<CollapsibleWithSwitchButtonProps>) => {
  const [isOpen, toggle] = useToggle(defaultState);
  const classes = useStyles();

  const onToggle = useCallback(
    (isOpen) => {
      toggle();
      onToggleChange?.(isOpen);
    },
    [onToggleChange, toggle]
  );

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div>{title}</div>
        <SwitchButton dataCy={dataCyButton} checked={isOpen} onChange={onToggle} />
      </div>
      <Collapsible isOpen={isOpen}>{children}</Collapsible>
    </div>
  );
};
