import type { ComponentProps } from "react";
import React, { useEffect, useState } from "react";
import { TextField } from "DLUI/form";
import { propertiesApi } from "api/propertiesApi";
import type { ExpenseTransactionLineDto } from "@doorloop/dto";
import { DuplicateDialogButtonLabelValues } from "@doorloop/dto";
import type { FieldArrayRenderProps, FormikProps } from "formik";
import { FastField, getIn } from "formik";
import { useTranslation } from "react-i18next";
import AppStrings from "locale/keys";
import { ListItemInputsContainer } from "DLUI/listItems";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";
import type { View } from "DLUI/view";
import Grid from "@material-ui/core/Grid";
import { unitsApi } from "api/unitsApi";
import { v4 as uuid } from "uuid";
import { useAnalyticsService } from "hooks/useAnalyticsService";

interface ExpenseTransactionLineProps extends Pick<ComponentProps<typeof View>, "domRef"> {
  lineIndex: number;
  transactionItem: ExpenseTransactionLineDto;
  arrayHelpers: FieldArrayRenderProps;
  name: string;
  accountFilterClass?: "filter_leaseChargeItem" | "filter_leaseDepositItem";
  formikRef: FormikProps<any>;
  viewOnly?: boolean;
}

const ExpenseTransactionLine: React.FC<ExpenseTransactionLineProps> = ({
  domRef,
  lineIndex,
  arrayHelpers,
  name,
  accountFilterClass,
  formikRef,
  viewOnly
}: ExpenseTransactionLineProps) => {
  const { t } = useTranslation();
  const [selectedPropertyId, setSelectedPropertyId] = useState<string | undefined>();
  const [selectedUnitId, setSelectedUnitId] = useState<string | undefined>();
  const [loadingDefaultValues, setLoadingDefaultValues] = useState<boolean>(true);
  const [propertyDefaultValue, setPropertyDefaultValue] = useState<string | undefined>();
  const [unitDefaultValue, setUnitDefaultValue] = useState<string | undefined>();
  const { dispatchAnalytics } = useAnalyticsService();

  const [accountDefaultValue, setAccountDefaultValue] = useState<string | undefined>();

  const unitFieldKey = `${name}[${lineIndex}].unit`;
  const propertyFieldKey = `${name}[${lineIndex}].property`;

  const loadDefaultValues = async () => {
    const propertyDefaultValue = getIn(formikRef.values, propertyFieldKey);
    const unitDefaultValue = getIn(formikRef.values, `${name}[${lineIndex}].unit`);
    const accountDefaultValue = getIn(formikRef.values, `${name}[${lineIndex}].account`);

    setAccountDefaultValue(accountDefaultValue);
    setPropertyDefaultValue(propertyDefaultValue);
    setUnitDefaultValue(unitDefaultValue);
    setSelectedPropertyId(propertyDefaultValue);
    setLoadingDefaultValues(false);
  };

  useEffect(() => {
    loadDefaultValues();
  }, []);

  const didPressDeleteTransactionButton = () => {
    if (viewOnly) {
      return;
    }
    const lines = getIn(formikRef.values, "lines");
    if (lines && lines.length === 1) {
      return;
    }
    arrayHelpers.remove(lineIndex);
  };

  const didPressDuplicateTransactionButton = () => {
    if (viewOnly) {
      return;
    }
    const line = formikRef.values?.lines?.[lineIndex];
    if (!line) {
      return;
    }

    arrayHelpers.insert(lineIndex + 1, {
      ...line,
      uniqueKey: uuid()
    });

    dispatchAnalytics("button_click", {
      label: DuplicateDialogButtonLabelValues.DUPLICATE_LINE_ITEM
    });
  };

  const queryParams: any = {};
  if (accountFilterClass === "filter_leaseChargeItem") {
    queryParams["filter_leaseChargeItem"] = true;
  }
  if (accountFilterClass === "filter_leaseDepositItem") {
    queryParams["filter_leaseDepositItem"] = true;
  }

  const didSelectProperty = (event: object, value: any) => {
    formikRef.setFieldValue(unitFieldKey, undefined);
    setSelectedUnitId(undefined);
    if (value != null) {
      setSelectedPropertyId(value.id);
    } else {
      setSelectedPropertyId(undefined);
    }
  };

  const didSelectUnit = (event: object, value: any) => {
    setSelectedUnitId(value ? value.id : undefined);
  };

  if (loadingDefaultValues) {
    return <div />;
  }

  return (
    <ListItemInputsContainer
      domRef={domRef}
      onClickDeleteButton={didPressDeleteTransactionButton}
      onClickDuplicateButton={didPressDuplicateTransactionButton}
      isDeleteButtonDisabled={lineIndex === 0}
      id={`ETL${lineIndex}`}
    >
      <Grid item xs={12} sm={3}>
        <FormikCachedAsyncAutoComplete
          uniqueIndex={lineIndex + 2}
          apiHandler={propertiesApi}
          onChange={didSelectProperty}
          displayNameKey={"name"}
          filterFieldName={"filter_text"}
          filterFieldValue={"name"}
          selectionFields={["id", "class"]}
          name={propertyFieldKey}
          queryParams={{ ...queryParams, filter_active: true }}
          required
          defaultValue={propertyDefaultValue}
          viewOnly={viewOnly}
          label={t(AppStrings.Common.FilterOptions.Property)}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormikCachedAsyncAutoComplete
          uniqueIndex={lineIndex + 2}
          apiHandler={unitsApi}
          onChange={didSelectUnit}
          displayNameKey={"name"}
          filterFieldName={"filter_text"}
          filterFieldValue={"name"}
          selectionFields={["id", "class"]}
          name={`${name}[${lineIndex}].unit`}
          queryParams={{ ...queryParams, filter_active: true, filter_property: selectedPropertyId }}
          defaultValue={unitDefaultValue}
          disabled={!selectedPropertyId}
          label={t(AppStrings.Common.FilterOptions.Unit)}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <BankAccountFormikAutoCompleteField
          addCreateOption
          uniqueIndex={lineIndex + 2}
          onChange={() => {}}
          name={`${name}[${lineIndex}].account`}
          queryParams={{ filter_active: true }}
          defaultValue={accountDefaultValue}
          viewOnly={viewOnly}
          label={t(AppStrings.Common.ListHeader.Category)}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FastField
          label={t(AppStrings.Common.ListHeader.Description)}
          component={TextField}
          name={`${name}[${lineIndex}].memo` || ""}
          viewOnly={viewOnly}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FastField
          component={TextField}
          name={`${name}[${lineIndex}].amount`}
          required
          formatType={"currency"}
          allowNegative
          viewOnly={viewOnly}
          label={t(AppStrings.Leases.NewLease.LeaseRent.Amount)}
        />
      </Grid>
    </ListItemInputsContainer>
  );
};

export default ExpenseTransactionLine;
