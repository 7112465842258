import React from "react";
import { View } from "DLUI/view";

interface ComponentProps {
  publicFileName: string;
  height: number | string;
  minHeight?: number;
  marginTop?: number;
  backgroundSize?: "contain" | "cover";
  children?: React.ReactNode;
  borderRadius?: number;
  width?: number | string;
  backgroundPosition?: string;
  onClick?: () => void;
  backgroundImageCssRules?: string[];
}

const BackgroundImageView: React.FC<ComponentProps> = React.memo(
  ({
    publicFileName,
    height,
    minHeight,
    marginTop,
    children,
    borderRadius,
    backgroundSize,
    width,
    backgroundPosition,
    onClick,
    backgroundImageCssRules = []
  }: ComponentProps) => {
    const backgroundImage = [...backgroundImageCssRules, publicFileName].join(", ");
    return (
      <View
        onClick={onClick}
        height={height}
        borderRadius={borderRadius || 0}
        marginTop={marginTop}
        width={width}
        style={{
          backgroundRepeat: "no-repeat",
          backgroundSize: backgroundSize || "contain",
          backgroundPosition: backgroundPosition || "center center",
          backgroundImage,
          minHeight
        }}
      >
        {children || null}
      </View>
    );
  }
);

export default BackgroundImageView;
