import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    profileImage: {
      borderRadius: "100%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      borderStyle: "solid",
      borderColor: theme.palette.gray.light,
      position: "relative"
    },
    deleteButton: {
      position: "absolute",
      top: -15,
      right: -15,
      zIndex: 500
    }
  })
);
