import { View } from "DLUI/view";
import React from "react";
import { Icon } from "DLUI/icon";
import { NotificationEmptyIcon } from "../../../../assets";
import Text from "DLUI/text";

export const NotificationsEmptyListView = () => (
  <View justifyContent={"center"} alignItems={"center"} height={"100%"}>
    <Icon Source={NotificationEmptyIcon} className={"empty-notifications-icon"} height={150} width={150} />
    <View alignItems={"center"}>
      <Text value={"No New Notifications"} fontSize={16} bold></Text>
      <Text value={"Notifications you receive will show up here"}></Text>
    </View>
  </View>
);
