import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface ComponentProps extends React.HTMLAttributes<HTMLSpanElement> {
  value: string;
  highlightedSubstring: string;
  highlightColor?: React.CSSProperties["backgroundColor"];
}
export const HighlightedText = ({
  highlightedSubstring,
  value,
  highlightColor = "#F9E3ED",
  ...spanProps
}: ComponentProps) => {
  const { t } = useTranslation();
  const translatedValue = t(value);

  const highlightedText = useMemo(() => {
    const lowerCaseHighlighted = highlightedSubstring.toLowerCase();
    const lowerCaseValue = translatedValue.toLowerCase();
    const index = lowerCaseValue.indexOf(lowerCaseHighlighted);
    if (index === -1) {
      return translatedValue;
    }
    const before = translatedValue.substring(0, index);
    const after = translatedValue.substring(index + highlightedSubstring.length);

    return (
      <>
        {before}
        <span style={{ backgroundColor: highlightColor }}>
          {translatedValue.substring(index, index + highlightedSubstring.length)}
        </span>
        {after}
      </>
    );
  }, [highlightedSubstring, highlightColor, translatedValue]);

  return <span {...spanProps}>{highlightedText}</span>;
};
