export default {
  AmountPerMonth: "lateFee.amountPerMonth",
  AmountPerDay: "lateFee.amountPerDay",
  HowOften: "lateFee.howOften",
  FeeType: "lateFee.feeType",
  AmountOrPercentage: "lateFee.amountOrPercentage",
  Account: "lateFee.account",
  AddAnotherCharge: "lateFee.addAnotherCharge",
  DontChargeMoreThen: "lateFee.dontChargeMoreThen",
  HideAdvancedOptions: "lateFee.hideAdvancedOptions",
  ShowAdvancedOptions: "lateFee.showAdvancedOptions",
  PerMonth: "lateFee.perMonth",
  OutstandingBalanceExceeds: "lateFee.outstandingBalanceExceeds",
  NotifyTenantsOnCharge: {
    Title: "lateFee.notifyTenantsOnCharge.title",
    Description: "lateFee.notifyTenantsOnCharge.description"
  },
  OnlyChargeLateFeesOnMostRecentCharge: {
    Title: "lateFee.onlyChargeLateFeesOnMostRecentCharge.title",
    Description: "lateFee.onlyChargeLateFeesOnMostRecentCharge.description"
  },
  SetMaxMonthlyLateFeesAmount: {
    Title: "lateFee.setMaxMonthlyLateFeesAmount.title",
    Description: "lateFee.setMaxMonthlyLateFeesAmount.description"
  },
  SetMaxDailyLateFeesAmount: {
    Title: "lateFee.setMaxDailyLateFeesAmount.title",
    Description: "lateFee.setMaxDailyLateFeesAmount.description"
  },
  SetMinBalanceToApplyLateFeeAmount: {
    Title: "lateFee.setMinBalanceToApplyLateFeeAmount.title",
    Description: "lateFee.setMinBalanceToApplyLateFeeAmount.description"
  },
  SpecifyAccountsToConsider: {
    Title: "lateFee.specifyAccountsToConsider.title",
    Description: "lateFee.specifyAccountsToConsider.description"
  },
  TextMessageOnChange: "lateFee.textMessageOnChange",
  AdvancedOptions: "lateFee.advancedOptions",
  OutstandingBalanceAccount: "lateFee.outstandingBalanceAccount",
  DaysAfterRentDue: "lateFee.daysAfterRentDue",
  DescriptionLateFees: "lateFee.descriptionLateFees",
  LearnMoreLateFees: "lateFee.learnMoreLateFees"
};
