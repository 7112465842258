"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportId = void 0;
var ReportId;
(function (ReportId) {
    ReportId["CASH_FLOW"] = "CASH_FLOW";
    ReportId["CASH_FLOW_STATEMENT"] = "CASH_FLOW_STATEMENT";
    ReportId["CASH_FLOW_STATEMENT_BY_MONTH"] = "CASH_FLOW_STATEMENT_BY_MONTH";
    ReportId["OWNER_STATEMENT"] = "OWNER_STATEMENT";
    ReportId["PROPERTY_RESERVE_FUNDS"] = "PROPERTY_RESERVE_FUNDS";
    ReportId["MANAGEMENT_FEES"] = "MANAGEMENT_FEES";
    ReportId["PROFIT_AND_LOST"] = "PROFIT_AND_LOST";
    ReportId["PROFIT_AND_LOST_DETAILS"] = "PROFIT_AND_LOST_DETAILS";
    ReportId["PROFIT_AND_LOST_BY_MONTH"] = "PROFIT_AND_LOST_BY_MONTH";
    ReportId["PROFIT_AND_LOST_BY_PROPERTY"] = "PROFIT_AND_LOST_BY_PROPERTY";
    ReportId["PROFIT_AND_LOST_BY_QUARTER"] = "PROFIT_AND_LOST_BY_QUARTER";
    ReportId["PROFIT_AND_LOST_BY_YEAR"] = "PROFIT_AND_LOST_BY_YEAR";
    ReportId["DEPOSIT_DETAIL"] = "DEPOSIT_DETAIL";
    ReportId["RENT_ROLL"] = "RENT_ROLL";
    ReportId["RENT_ROLL_SUMMARY_BY_BEDS_BATHS"] = "RENT_ROLL_SUMMARY_BY_BEDS_BATHS";
    ReportId["RENT_ROLL_SUMMARY_BY_PROPERTY"] = "RENT_ROLL_SUMMARY_BY_PROPERTY";
    ReportId["BALANCE_SHEET"] = "BALANCE_SHEET";
    ReportId["BALANCE_SHEET_DETAILS"] = "BALANCE_SHEET_DETAILS";
    ReportId["BALANCE_SHEET_BY_MONTH"] = "BALANCE_SHEET_BY_MONTH";
    ReportId["BALANCE_SHEET_BY_PROPERTY"] = "BALANCE_SHEET_BY_PROPERTY";
    ReportId["BALANCE_SHEET_BY_QUARTER"] = "BALANCE_SHEET_BY_QUARTER";
    ReportId["BALANCE_SHEET_BY_YEAR"] = "BALANCE_SHEET_BY_YEAR";
    ReportId["BUDGET_VS_ACTUAL"] = "BUDGET_VS_ACTUAL";
    ReportId["ACCOUNT_REGISTER"] = "ACCOUNT_REGISTER";
    ReportId["TRANSACTIONS_DETAILS"] = "TRANSACTIONS_DETAILS";
    ReportId["TRANSACTIONS_LIST"] = "TRANSACTIONS_LIST";
    ReportId["TRANSACTIONS_LIST_BY_DATE"] = "TRANSACTIONS_LIST_BY_DATE";
    ReportId["TRANSACTIONS_LIST_BY_TYPE"] = "TRANSACTIONS_LIST_BY_TYPE";
    ReportId["TRANSACTIONS_LIST_RECENT"] = "TRANSACTIONS_LIST_RECENT";
    ReportId["TRIAL_BALANCE"] = "TRIAL_BALANCE";
    ReportId["BANK_BALANCE_BY_PROPERTY"] = "BALANCE_BY_PROPERTY";
    ReportId["DEPOSIT_TRANSACTIONS"] = "DEPOSIT_TRANSACTIONS";
    ReportId["EXPENSE_TRANSACTIONS"] = "EXPENSE_TRANSACTIONS";
    ReportId["GENERAL_LEDGER"] = "GENERAL_LEDGER";
    ReportId["REFUND_TRANSACTIONS"] = "REFUND_TRANSACTIONS";
    ReportId["UNDEPOSIT_FUNDS"] = "UNDEPOSIT_FUNDS";
    ReportId["EPAY_DEPOSIT"] = "EPAY_DEPOSIT";
    ReportId["ACCOUNT_RECEIVABLE_AGING_DETAILS"] = "ACCOUNT_RECEIVABLE_AGING_DETAILS";
    ReportId["ACCOUNT_RECEIVABLE_AGING_DETAILS_BY_ACCOUNT"] = "ACCOUNT_RECEIVABLE_AGING_DETAILS_BY_ACCOUNT";
    ReportId["ACCOUNT_RECEIVABLE_AGING_SUMMARY"] = "ACCOUNT_RECEIVABLE_AGING_SUMMARY";
    ReportId["RECURRING_TRANSACTIONS"] = "RECURRING_TRANSACTIONS";
    ReportId["RECURRING_TRANSACTIONS_BY_TYPE"] = "RECURRING_TRANSACTIONS_BY_TYPE";
    ReportId["RECURRING_TRANSACTIONS_SUMMARY"] = "RECURRING_TRANSACTIONS_SUMMARY";
    ReportId["RECURRING_TRANSACTIONS_DETAIL"] = "RECURRING_TRANSACTIONS_DETAIL";
    ReportId["RECURRING_CREDITS"] = "RECURRING_CREDITS";
    ReportId["RECURRING_PAYMENTS"] = "RECURRING_PAYMENTS";
    ReportId["RECURRING_TASKS"] = "RECURRING_TASKS";
    ReportId["RECURRING_WORK_ORDERS"] = "RECURRING_WORK_ORDERS";
    ReportId["RECURRING_EXPENSES"] = "RECURRING_EXPENSES";
    ReportId["RECURRING_VENDOR_BILLS"] = "RECURRING_VENDOR_BILLS";
    ReportId["TRANSACTIONS_LIST_EDITABLE"] = "TRANSACTIONS_LIST_EDITABLE";
    ReportId["REVERSED_PAYMENTS"] = "REVERSED_PAYMENTS";
    ReportId["ACCOUNT_PAYABLE_AGING_DETAILS"] = "ACCOUNT_PAYABLE_AGING_DETAILS";
    ReportId["ACCOUNT_PAYABLE_AGING_DETAILS_BY_ACCOUNT"] = "ACCOUNT_PAYABLE_AGING_DETAILS_BY_ACCOUNT";
    ReportId["ACCOUNT_PAYABLE_AGING_SUMMARY"] = "ACCOUNT_PAYABLE_AGING_SUMMARY";
    ReportId["BILL_PAYMENTS"] = "BILL_PAYMENTS";
    ReportId["UNPAID_BILLS"] = "UNPAID_BILLS";
    ReportId["CURRENT_TENANTS"] = "CURRENT_TENANTS";
    ReportId["FUTURE_TENANTS"] = "FUTURE_TENANTS";
    ReportId["PREVIOUS_TENANTS"] = "PREVIOUS_TENANTS";
    ReportId["TENANT_VEHICLES"] = "TENANT_VEHICLES";
    ReportId["TENANT_PETS"] = "TENANT_PETS";
    ReportId["ACTIVE_PROSPECTS"] = "ACTIVE_PROSPECTS";
    ReportId["LOST_PROSPECTS"] = "LOST_PROSPECTS";
    ReportId["MOVED_IN_PROSPECTS"] = "MOVED_IN_PROSPECTS";
    ReportId["PROSPECTS_BY_LEASING_AGENT"] = "PROSPECTS_BY_LEASING_AGENT";
    ReportId["PROSPECTS_BY_SOURCE"] = "PROSPECTS_BY_SOURCE";
    ReportId["RENTAL_APPLICATIONS"] = "RENTAL_APPLICATIONS";
    ReportId["ACTIVE_DRAFT_LEASE"] = "ACTIVE_DRAFT_LEASE";
    ReportId["CANCEL_DRAFT_LEASE"] = "CANCEL_DRAFT_LEASE";
    ReportId["LEASE_INSURANCE_SUMMARY"] = "LEASE_INSURANCE_SUMMARY";
    ReportId["LEASE_INSURANCE_DETAILS"] = "LEASE_INSURANCE_DETAILS";
    ReportId["DRAFT_LEASE_BY_STATUS"] = "DRAFT_LEASE_BY_STATUS";
    ReportId["DRAFT_LEASE_BY_PROPERTY"] = "DRAFT_LEASE_BY_PROPERTY";
    ReportId["LEASE_LATE_FEES"] = "LEASE_LATE_FEES";
    ReportId["LEASE_ACCOUNT_STATEMENTS"] = "LEASE_ACCOUNT_STATEMENTS";
    ReportId["RENEWAL_STATUS_BY_PROPERTY"] = "RENEWAL_STATUS_BY_PROPERTY";
    ReportId["UPCOMING_MOVE_INS"] = "UPCOMING_MOVE_INS";
    ReportId["UPCOMING_MOVE_OUTS"] = "UPCOMING_MOVE_OUTS";
    ReportId["VENDOR_DIRECTORY"] = "VENDOR_DIRECTORY";
    ReportId["VENDOR_BY_INSURANCE_STATUS"] = "VENDOR_BY_INSURANCE_STATUS";
    ReportId["VENDOR_LEDGER"] = "VENDOR_LEDGER";
    ReportId["ALL_WORK_ORDER_BY_VENDOR"] = "ALL_WORK_ORDER_BY_VENDOR";
    ReportId["OPEN_WORK_ORDER_BY_VENDOR"] = "OPEN_WORK_ORDER_BY_VENDOR";
    ReportId["COMPLETE_TASKS"] = "COMPLETE_TASKS";
    ReportId["OPEN_TASKS"] = "OPEN_TASKS";
    ReportId["OVERDUE_TASKS"] = "OVERDUE_TASKS";
    ReportId["TASKS_BY_PRIORITY"] = "TASKS_BY_PRIORITY";
    ReportId["TASKS_BY_PROPERTY"] = "TASKS_BY_PROPERTY";
    ReportId["TASKS_BY_STATUS"] = "TASKS_BY_STATUS";
    ReportId["WORK_ORDERS"] = "WORK_ORDERS";
    ReportId["OWNERS_1099"] = "OWNERS_1099";
    ReportId["VENDORS_1099"] = "VENDORS_1099";
    ReportId["OWNERS_DIRECTORY"] = "OWNERS_DIRECTORY";
    ReportId["PROPERTY_OWNERSHIP"] = "PROPERTY_OWNERSHIP";
    ReportId["PROPERTY_BANK_ACCOUNTS"] = "PROPERTY_BANK_ACCOUNTS";
    ReportId["TENANT_REQUESTS"] = "TENANT_REQUESTS";
})(ReportId || (exports.ReportId = ReportId = {}));
