import AppStrings from "locale/keys";
import Routes from "../../../appRouter/routes";
import { DataCy, ObjectPermission } from "@doorloop/dto";
import { MainMenuLayersKeys } from "DLUI/drawer/items/mainMenuItems";
import { v4 as uuid } from "uuid";
import type { DrawerItemType, NavConfigProps } from "DLUI/drawer/types";
import { NavLevels } from "DLUI/drawer/types";

const calendarViewMenuItem: DrawerItemType = {
  label: AppStrings.DrawerItems.Calendar,
  navKey: uuid(),
  navPath: [{ name: Routes.CALENDAR, matchMethod: "exact" }],
  dataCy: DataCy.sideMenu.calendar.calendarView,
  clearance: [{ permission: ObjectPermission.calendar, field: "viewList" }]
};

const occupancyOverviewMenuItem: DrawerItemType = {
  label: AppStrings.DrawerItems.OccupancyOverview,
  navKey: uuid(),
  navPath: [{ name: Routes.CALENDAR_OCCUPANCY_OVERVIEW, matchMethod: "exact" }],
  dataCy: DataCy.sideMenu.calendar.occupancyOverview,
  clearance: [{ permission: ObjectPermission.calendar, field: "viewList" }]
};

export const SubMenuCalendarLayers: NavConfigProps = {
  title: AppStrings.DrawerItems.Calendar,
  level: NavLevels.SUB_MENU,
  groups: [
    {
      groupName: AppStrings.DrawerItems.Calendar,
      parentNavigationKey: MainMenuLayersKeys.Calendar,
      clearance: [{ permission: ObjectPermission.calendar, field: "viewList" }],
      items: [calendarViewMenuItem, occupancyOverviewMenuItem]
    }
  ]
};
