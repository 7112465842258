import type { FC } from "react";
import React from "react";
import type { TaskDialogComponentProps } from "DLUI/dialogs/tasks/internalTask/internalTask";
import WorkOrderDialog from "components/DLUI/dialogs/tasks/workOrder/workOrder";

type ComponentProps = TaskDialogComponentProps;

export const EditRecurringWorkOrder: FC<ComponentProps> = ({ ...props }) => (
  <WorkOrderDialog editingRecurring {...props} />
);
