import { createStyles, makeStyles } from "@material-ui/styles";

import { treeListDefaultColumnWidth } from "../utils";

export default makeStyles(() =>
  createStyles({
    editableRoot: {
      minWidth: treeListDefaultColumnWidth
    },

    input: {
      width: "100%"
    }
  })
);
