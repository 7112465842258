import type { Ref } from "react";
import { useCallback } from "react";
import { composeRefs } from "utils/react";

/**
 * Composes multiple refs into a single ref
 *
 * @param refs Refs to compose
 * @returns A ref which calls all the provided refs
 *
 * @example
 * ```tsx
 * const ref = useComposedRef(ref1, ref2, ref3);
 *
 * // ref1, ref2, and ref3 will all be called with the same value
 * return <div ref={ref}>...</div>
 */
export function useComposedRef<T extends HTMLElement = HTMLElement>(...refs: Array<React.Ref<T>>): Ref<T> {
  return useCallback(composeRefs(...refs), refs);
}
