import { View } from "DLUI/view";
import type { ReactNode } from "react";
import React from "react";
import { Grid } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import "./styles.css";
import type { RadioGroupItem, RadioGroupLabelsProps } from "DLUI/form/radioGroup/types";
import { UnControlledRadio } from "DLUI/form/radioGroup/radio/unControlledRadio";

import _ from "lodash";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import type { ViewComponentProps } from "DLUI/view/view";

const RadioGroupLabels: React.FC<RadioGroupLabelsProps> = ({
  radioGroupItems,
  textMaxWidth,
  groupValue,
  xs,
  md,
  lg,
  xl,
  flexDirection,
  dataCy,
  fullWidth,
  radioOptionViewWrapperProps
}) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();

  const shouldUseGridLayout = xs || md || lg || xl;
  const radioWidth = fullWidth ? "100%" : "auto";

  const items = radioGroupItems.map((currentItem: RadioGroupItem, index: number) => {
    let labelText = "";
    let labelComponent: ReactNode;

    if (_.isString(currentItem.label)) {
      labelText = t(currentItem.label);
    } else {
      labelComponent = currentItem.label();
    }

    const shouldShowLabelContent =
      currentItem.alwaysDisplayLabelContent ??
      (currentItem.value === groupValue && Boolean(currentItem.renderLabelContent));

    const viewProps: ViewComponentProps | undefined = currentItem.alwaysDisplayLabelContent
      ? undefined
      : {
          shouldShow: shouldShowLabelContent,
          showAnimation: "fade-in",
          hideAnimation: "fade-out"
        };

    const labelContent = (
      <View {...viewProps}>{currentItem.renderLabelContent && currentItem.renderLabelContent()}</View>
    );

    if (shouldUseGridLayout) {
      return (
        <Grid key={"BT" + index} item xs={xs} md={md} lg={lg} xl={xl}>
          <UnControlledRadio
            disabled={currentItem.disabled}
            value={currentItem.value}
            dataCy={currentItem.dataCy}
            labelValueProps={{ text: labelText, component: labelComponent }}
          />
          {labelContent}
        </Grid>
      );
    }

    return (
      <div
        style={{
          maxWidth: textMaxWidth,
          width: radioWidth,
          ...currentItem.style
        }}
        key={index}
      >
        <UnControlledRadio
          disabled={currentItem.disabled}
          value={currentItem.value}
          dataCy={currentItem.dataCy}
          labelValueProps={{ text: labelText, component: labelComponent }}
        />
        {labelContent}
      </div>
    );
  });

  return (
    <View flexDirection={flexDirection} dataCy={dataCy} gap={isMobile ? 8 : 0} {...radioOptionViewWrapperProps}>
      {items}
    </View>
  );
};

export { RadioGroupLabels };
