import { RecurringTransactionType } from "@doorloop/dto";
import AppStrings from "locale/keys";

interface SettingsInterface {
  strings: StringsInterface;
  isNumberOfDaysTillDueEnabled: boolean;
}

interface StringsInterface {
  oneTime: string;
  recurring: string;
}

const leaseStrings = {
  oneTime: "",
  recurring: ""
};

const recurringStrings: Record<RecurringTransactionType, StringsInterface> = {
  [RecurringTransactionType.GENERAL_ENTRY]: {
    oneTime: AppStrings.Common.Recurring.OneTimeGeneralEntry,
    recurring: AppStrings.Common.Recurring.RecurringGeneralEntry
  },
  [RecurringTransactionType.TASK]: {
    oneTime: AppStrings.Common.Recurring.OneTimeTask,
    recurring: AppStrings.Common.Recurring.RecurringTask
  },
  [RecurringTransactionType.EXPENSE]: {
    oneTime: AppStrings.Common.Recurring.OneTimeExpense,
    recurring: AppStrings.Common.Recurring.RecurringExpense
  },
  [RecurringTransactionType.VENDOR_BILL]: {
    oneTime: AppStrings.Common.Recurring.OneTimeBill,
    recurring: AppStrings.Common.Recurring.RecurringBill
  },
  [RecurringTransactionType.LEASE_RENT]: leaseStrings,
  [RecurringTransactionType.LEASE_CREDIT]: leaseStrings,
  [RecurringTransactionType.LEASE_PAYMENT]: leaseStrings,
  [RecurringTransactionType.LEASE_CHARGE]: leaseStrings
};

const workOrderStrings = {
  oneTime: AppStrings.Common.Recurring.OneTimeWorkOrder,
  recurring: AppStrings.Common.Recurring.RecurringWorkOrder
};

export const getRecurringSettings = (type: RecurringTransactionType, isWorkOrder = false): SettingsInterface => {
  return {
    strings: isWorkOrder ? workOrderStrings : recurringStrings[type],
    isNumberOfDaysTillDueEnabled: isNumberOfDaysTillDueEnabled(type)
  };
};

export const isNumberOfDaysTillDueEnabled = (type: RecurringTransactionType): boolean =>
  type !== RecurringTransactionType.EXPENSE && type !== RecurringTransactionType.GENERAL_ENTRY;
