import { usersApi } from "api/usersApi";
import { UserPrintSetupConfigDto } from "@doorloop/dto";
import { PrintSetupLocalStorageKey } from "../dialogs/printSetup/printSetupDialog.constants";

export const getInitialPrintConfig: () => Promise<UserPrintSetupConfigDto> = async () => {
  const printSetupFromLocalStorage = localStorage.getItem(PrintSetupLocalStorageKey);

  if (printSetupFromLocalStorage) {
    return new UserPrintSetupConfigDto(JSON.parse(printSetupFromLocalStorage));
  }

  const printSetupResponse = await usersApi.getUserPrintSetup();
  return new UserPrintSetupConfigDto({ ...printSetupResponse.data });
};
