import type {
  DefaultAccountType,
  GetAccountBalanceQuery,
  GetAllAccountsQuery,
  GetDefaultAccountsForPropertyRequestDto,
  PropertyDefaultAccountsSettingsDto
} from "@doorloop/dto";
import { AccountDto, ServerRoutes } from "@doorloop/dto";
import { RestApiBaseWithDictionary } from "./restApiBaseWithDictionary";
import { RestApiBase } from "./restApiBase";
import { apiHelper } from "./apiHelper";
import type { ApiResult } from "../api/apiResult";

export class AccountsApi extends RestApiBaseWithDictionary<AccountDto, GetAllAccountsQuery> {
  constructor() {
    super(ServerRoutes.ACCOUNTS);
    this.restRoute = ServerRoutes.ACCOUNTS;
  }

  createDefaultAccounts() {
    this.restRoute = ServerRoutes.ACCOUNTS_POST_DEFAULT_ACCOUNTS;
    this.create(new AccountDto());
  }

  getDetails = async (accountId: string) => await apiHelper.axiosGet(ServerRoutes.ACCOUNTS + "/" + accountId);

  async getDefaultAccountFor(defaultAccountFor: DefaultAccountType) {
    // TODO return value from the server once possible
    const res = await this.getAll({
      filter_DefaultAccountFor: defaultAccountFor
    });
    if (res.status && res.data && res.data.data && res.data.data.length > 0) {
      const account = res.data.data[0];
      return account;
    }
    return null;
  }

  async getDefaultAccountsForProperty(
    query: GetDefaultAccountsForPropertyRequestDto
  ): Promise<ApiResult<PropertyDefaultAccountsSettingsDto>> {
    return await apiHelper.axiosGet<PropertyDefaultAccountsSettingsDto>(
      ServerRoutes.ACCOUNTS_GET_DEFAULT_ACCOUNTS_FOR_PROPERTY,
      query
    );
  }

  async getDefaultClearingAccount(): Promise<ApiResult<AccountDto>> {
    return await apiHelper.axiosGet<AccountDto>(ServerRoutes.ACCOUNTS_GET_DEFAULT_CLEARING_ACCOUNT);
  }
}

export const accountsApi = new AccountsApi();

export class AccountBalanceApi extends RestApiBase<AccountDto, GetAccountBalanceQuery> {
  constructor() {
    super(ServerRoutes.ACCOUNTS_GET_ACCOUNT_BALANCE);
    this.restRoute = ServerRoutes.ACCOUNTS_GET_ACCOUNT_BALANCE;
  }
}

export const accountBalanceApi = new AccountBalanceApi();

export class ChartOfAccountsApi extends RestApiBase<AccountDto, GetAllAccountsQuery> {
  constructor() {
    super(ServerRoutes.ACCOUNTS_GET_CHART_OF_ACCOUNTS);
    this.restRoute = ServerRoutes.ACCOUNTS_GET_CHART_OF_ACCOUNTS;
  }
}

export const chartOfAccountsApi = new ChartOfAccountsApi();
