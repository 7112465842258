import _ from "lodash";
import type { FormikProps } from "formik";

import {
  createValidator,
  OwnerPortalAccessDto,
  OwnerPortalSettingsDto,
  PortalContactInfoDto,
  PortalEmailInviteDto,
  PortalRequestSettingsDto,
  PortalThemeDto
} from "@doorloop/dto";

export const initFormValues = (): OwnerPortalSettingsDto => {
  const dto = new OwnerPortalSettingsDto();

  dto.contactInfo = new PortalContactInfoDto();
  dto.theme = new PortalThemeDto();
  dto.access = new OwnerPortalAccessDto();
  dto.requestSettings = new PortalRequestSettingsDto();
  dto.emailInvite = new PortalEmailInviteDto();

  return dto;
};

export const validateForm = createValidator(OwnerPortalSettingsDto);

export interface ValidateOwnerPortalFormResponse {
  isValid: boolean;
  errorStepIndex?: number;
}

export const validateOwnerPortalForm = async (
  formikRef: FormikProps<OwnerPortalSettingsDto>
): Promise<ValidateOwnerPortalFormResponse> => {
  formikRef.setFieldTouched("contactInfo.address.street1");
  formikRef.setFieldTouched("contactInfo.address.city");
  formikRef.setFieldTouched("contactInfo.address.zip");
  formikRef.setFieldTouched("contactInfo.address.country");
  formikRef.setFieldTouched("emailInvite.emailBody");

  const errors = await formikRef.validateForm();

  if (_.isEmpty(errors)) {
    return { isValid: true };
  }
  let errorStepIndex = 0;

  if (errors.emailInvite) {
    errorStepIndex = 5;
  }

  if (errors.subdomain) {
    errorStepIndex = 6;
  }

  return { isValid: false, errorStepIndex };
};
