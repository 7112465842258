import React from "react";
import { Icon } from "DLUI/icon";
import { LocationIcon } from "../../../../../../assets";
import TextEllipsis from "DLUI/text/textEllipsis";
import Grid from "@material-ui/core/Grid";
import { serializeFullAddressOption } from "DLUI/form/autoComplete/addressAutoComplete/smarty/addressAutoComplete";
import type { InternationalAndUS_SuggestionsType } from "DLUI/form/autoComplete/addressAutoComplete/smarty/dataSource";
import type { OptionType } from "DLUI/form/autoComplete/autoComplete";
import { View } from "DLUI/view";
import Text from "DLUI/text";

const SuggestionOption = (addressObj: OptionType<InternationalAndUS_SuggestionsType>) => (
  <Grid container alignItems={"center"}>
    <Grid item xs={1}>
      <Icon Source={LocationIcon} size={20} />
    </Grid>
    <Grid item xs={11}>
      <View flexDirection={"row"} noWrap>
        {addressObj.addressText && <TextEllipsis text={addressObj.addressText} />}
        {addressObj.streetLine && <TextEllipsis text={serializeFullAddressOption(addressObj)} />}
        {addressObj.entries && addressObj.entries > 1 ? <Text fontSize={14}>+{addressObj.entries} Addresses</Text> : ""}
      </View>
    </Grid>
  </Grid>
);

export default SuggestionOption;
