import { CommunicationType } from "@doorloop/dto";
import { CommunicationDeliveryStatus } from "@doorloop/dto";
import type { TFunction } from "i18next";
import type { CommunicationDto } from "@doorloop/dto";
import type { StatusStepInterface } from "DLUI/stepStatus/stepStatus";
import _ from "lodash";
import moment from "moment";
import {
  RoundGrayAttachment,
  RoundGrayEye,
  RoundGrayPlane,
  RoundGreenAttachment,
  RoundGreenEye,
  RoundGreenPlane,
  RoundRedPlane
} from "assets/icons";
import AppStrings from "locale/keys";

const gray = "secondary-gray-light";
const buildReplaceObject = (array) => {
  return {
    x: _.size(array),
    date: moment(array[0]).format("MMM Do"),
    time: moment(array[0]).format("h:mm A")
  };
};

type StepsMapInterface = {
  [key in CommunicationType]: {
    [key in CommunicationDeliveryStatus]?: (t: TFunction, dto: CommunicationDto) => StatusStepInterface[];
  };
};

const {
  sent,
  Delivered,
  Opened,
  Clicked,
  failed,
  failedWithReason,
  complaint,
  draft,
  openedXTimesSinceDateTime,
  openedOneTimeSinceDateTime,
  clickedXTimesSinceDateTime,
  clickedOneTimeSinceDateTime
} = AppStrings.Announcements;

export const stepsPerTypePerStatus: StepsMapInterface = {
  [CommunicationType.EMAIL]: {
    [CommunicationDeliveryStatus.SENT]: (t) => [
      {
        IconSource: RoundGrayPlane,
        connectorColor: gray,
        tooltip: t(Delivered)
      },
      {
        IconSource: RoundGrayEye,
        connectorColor: gray,
        tooltip: t(Opened)
      },
      {
        IconSource: RoundGrayAttachment,
        tooltip: t(Clicked)
      }
    ],
    [CommunicationDeliveryStatus.DELIVERED]: (t) => [
      {
        IconSource: RoundGreenPlane,
        connectorColor: gray,
        tooltip: t(Delivered)
      },
      {
        IconSource: RoundGrayEye,
        connectorColor: gray,
        tooltip: t(Opened)
      },
      {
        IconSource: RoundGrayAttachment,
        tooltip: t(Clicked)
      }
    ],
    [CommunicationDeliveryStatus.OPENED]: (t, { openedAt }) => [
      {
        IconSource: RoundGreenPlane,
        tooltip: t(Delivered)
      },
      {
        IconSource: RoundGreenEye,
        badgeValue: _.size(openedAt),
        tooltip: openedAt
          ? t(
              _.size(openedAt) > 1 ? openedXTimesSinceDateTime : openedOneTimeSinceDateTime,
              buildReplaceObject(openedAt)
            )
          : undefined,
        connectorColor: gray
      },
      {
        IconSource: RoundGrayAttachment,
        tooltip: t(Clicked)
      }
    ],
    [CommunicationDeliveryStatus.CLICKED]: (t, { openedAt, clickedAt }) => [
      {
        IconSource: RoundGreenPlane,
        tooltip: t(Delivered)
      },
      {
        IconSource: RoundGreenEye,
        badgeValue: _.size(openedAt),
        tooltip: openedAt
          ? t(
              _.size(openedAt) > 1 ? openedXTimesSinceDateTime : openedOneTimeSinceDateTime,
              buildReplaceObject(openedAt)
            )
          : undefined
      },
      {
        IconSource: RoundGreenAttachment,
        badgeValue: _.size(clickedAt),
        tooltip: clickedAt
          ? t(
              _.size(clickedAt) > 1 ? clickedXTimesSinceDateTime : clickedOneTimeSinceDateTime,
              buildReplaceObject(clickedAt)
            )
          : undefined
      }
    ],
    [CommunicationDeliveryStatus.BOUNCED]: (t, { failedReason }) => [
      {
        IconSource: RoundRedPlane,
        connectorColor: gray,
        tooltip: t(failedReason ? failedWithReason : failed, { failedReason })
      },
      {
        IconSource: RoundGrayEye,
        connectorColor: gray,
        tooltip: t(Opened)
      },
      {
        IconSource: RoundGrayAttachment,
        tooltip: t(Clicked)
      }
    ],
    [CommunicationDeliveryStatus.COMPLAINT]: (t, { failedReason }) => [
      {
        IconSource: RoundRedPlane,
        connectorColor: gray,
        tooltip: t(failedReason ? failedWithReason : complaint, { failedReason })
      },
      {
        IconSource: RoundGrayEye,
        connectorColor: gray,
        tooltip: t(Opened)
      },
      {
        IconSource: RoundGrayAttachment,
        tooltip: t(Clicked)
      }
    ],
    [CommunicationDeliveryStatus.FAILED]: (t, { failedReason }) => [
      {
        IconSource: RoundRedPlane,
        connectorColor: gray,
        tooltip: t(failedReason ? failedWithReason : failed, { failedReason })
      },
      {
        IconSource: RoundGrayEye,
        connectorColor: gray,
        tooltip: t(Opened)
      },
      {
        IconSource: RoundGrayAttachment,
        tooltip: t(Clicked)
      }
    ]
  },
  [CommunicationType.TEXT]: {
    [CommunicationDeliveryStatus.SENT]: (t) => [
      {
        IconSource: RoundGrayPlane,
        tooltip: t(draft)
      }
    ],
    [CommunicationDeliveryStatus.DELIVERED]: (t) => [
      {
        IconSource: RoundGreenPlane,
        tooltip: t(sent)
      }
    ],
    [CommunicationDeliveryStatus.FAILED]: (t, { failedReason }) => [
      {
        IconSource: RoundRedPlane,
        tooltip: t(failedReason ? failedWithReason : failed, { failedReason })
      }
    ]
  },
  [CommunicationType.PHONE]: {
    [CommunicationDeliveryStatus.SENT]: (t) => [
      {
        IconSource: RoundGrayPlane,
        tooltip: t(draft)
      }
    ],
    [CommunicationDeliveryStatus.DELIVERED]: (t) => [
      {
        IconSource: RoundGreenPlane,
        tooltip: t(sent)
      }
    ],
    [CommunicationDeliveryStatus.FAILED]: (t, { failedReason }) => [
      {
        IconSource: RoundRedPlane,
        tooltip: t(failedReason ? failedWithReason : failed, { failedReason })
      }
    ]
  }
};
