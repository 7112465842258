import { createStyles, makeStyles } from "@material-ui/styles";

import type { Theme } from "styles/defaultTheme";

const stylesEntitySelector = makeStyles((theme: Theme) =>
  createStyles({
    valueIcon: {
      cursor: "pointer",
      color: theme.newPalette.text.primary,
      "& path": {
        fill: `${theme.newPalette.text.primary} !important`
      }
    },
    valueIconDisabled: {
      color: theme.newPalette.text.disabled,
      "& path": {
        fill: `${theme.newPalette.text.disabled} !important`
      }
    },
    valueText: {
      color: theme.newPalette.text.primary
    },
    valueTextDisabled: {
      color: theme.newPalette.text.disabled
    },
    buttonText: {
      cursor: "pointer !important",
      color: theme.newPalette.secondary.main
    }
  })
);

export { stylesEntitySelector };
