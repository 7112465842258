"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApAgingDetailByAccountReportItem = void 0;
const apAgingDetailReportItem_dto_1 = require("./apAgingDetailReportItem.dto");
class ApAgingDetailByAccountReportItem extends apAgingDetailReportItem_dto_1.ApAgingDetailReportItem {
    constructor(init) {
        super(init);
        Object.assign(this, init);
    }
}
exports.ApAgingDetailByAccountReportItem = ApAgingDetailByAccountReportItem;
