"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayrixPaymentTokenCardRequest = exports.PayrixPaymentTokenCardPayment = exports.PayrixPaymentTokenCardCustomer = void 0;
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
class PayrixPaymentTokenCardCustomer {
}
exports.PayrixPaymentTokenCardCustomer = PayrixPaymentTokenCardCustomer;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(50),
    __metadata("design:type", String)
], PayrixPaymentTokenCardCustomer.prototype, "first", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(50),
    __metadata("design:type", String)
], PayrixPaymentTokenCardCustomer.prototype, "last", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(50),
    __metadata("design:type", String)
], PayrixPaymentTokenCardCustomer.prototype, "merchant", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.Length)(5, 7),
    __metadata("design:type", String)
], PayrixPaymentTokenCardCustomer.prototype, "zip", void 0);
class PayrixPaymentTokenCardPayment {
}
exports.PayrixPaymentTokenCardPayment = PayrixPaymentTokenCardPayment;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsCreditCard)(),
    __metadata("design:type", String)
], PayrixPaymentTokenCardPayment.prototype, "number", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.Length)(3, 4),
    __metadata("design:type", String)
], PayrixPaymentTokenCardPayment.prototype, "cvv", void 0);
class PayrixPaymentTokenCardRequest {
}
exports.PayrixPaymentTokenCardRequest = PayrixPaymentTokenCardRequest;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", PayrixPaymentTokenCardCustomer)
], PayrixPaymentTokenCardRequest.prototype, "customer", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => PayrixPaymentTokenCardPayment),
    __metadata("design:type", PayrixPaymentTokenCardPayment)
], PayrixPaymentTokenCardRequest.prototype, "payment", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.Length)(4, 4),
    __metadata("design:type", String)
], PayrixPaymentTokenCardRequest.prototype, "expiration", void 0);
