import ClientCompress from "client-compress";

interface InfoInterface {
  startType: string; // "image/jpeg"
  endType: string; // "image/jpeg
  start: number; // 3572.8999999992084
  quality: number; // 0.75
  startWidth: number; // 4976
  startHeight: number; // 2710
  endWidth: number; // 800
  endHeight: number; // 435.6913183279743
  iterations: number; // 1
  startSizeMB: number; // 3.11684
  endSizeMB: number; // 0.05547299270072992
  sizeReducedInPercent: number; // 98.22021686385153
  end: number; // 4180.400000004738
  elapsedTimeInSeconds: number; // 0.6075000000055297
}

interface PhotoInterface {
  name: string; // "photo-1234.jpg",
  type: string; // "image/jpeg",
  data: string; // "[object Blob]",
  size: number; // 55472.99270072992,
  orientation: number; // -1,
  width: number; // 800,
  height: number; // 435.6913183279743
}

interface Compression {
  photo: PhotoInterface;
  info: InfoInterface;
}

export const compressImage = async (image: File): Promise<File> => {
  const options = {
    quality: 0.75,
    maxWidth: 1920,
    maxHeight: 1080
  };

  const compressor = new ClientCompress(options);
  const [{ photo, info }]: Compression[] = await compressor.compress([image]);
  return new File([photo.data], photo.name, {
    type: info.endType
  });
};
