import React, { useEffect } from "react";

import clsx from "clsx";
import { FastField, useField, useFormikContext } from "formik";
import { InputAdornment } from "@material-ui/core";
import { TextField } from "formik-material-ui";

import makeStyles from "./styles";
import type { SVGIconComponent } from "../../../../assets";
import type { TextFieldProps } from "formik-material-ui/dist/TextField";
import ValidationTooltip, { TooltipType } from "DLUI/popover/validationTooltip/validationTooltip";
import { Icon } from "DLUI/icon";
import { PhoneNumberInputField } from "DLUI/form/phoneNumberInput/phoneNumberInputField";
import { DataCy } from "@doorloop/dto";

interface TextFieldInputProps extends Partial<TextFieldProps> {
  icon: SVGIconComponent;
  formikName: string;
  isMobilePhone?: boolean;
}

const TextFieldInput = ({ icon, formikName, isMobilePhone, ...rest }: TextFieldInputProps) => {
  const [, meta, helpers] = useField(formikName);
  const { submitCount } = useFormikContext();

  const classes = makeStyles();

  useEffect(() => {
    if (submitCount > 0) helpers.setTouched(true);
  }, []);

  return (
    <FastField
      name={formikName}
      component={isMobilePhone ? PhoneNumberInputField : TextField}
      data-cy={DataCy.DLUI.editableContactInfo.input}
      classes={{
        root: clsx([classes.root])
      }}
      InputProps={{
        ...rest,
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <ValidationTooltip
              variant={TooltipType.Error}
              open={meta.touched && meta.error !== null}
              title={meta.error ?? ""}
            >
              <Icon
                marginLeft={2}
                height={14}
                Source={icon}
                pathColor={meta.error && meta.touched ? "error" : "black"}
              />
            </ValidationTooltip>
          </InputAdornment>
        )
      }}
    ></FastField>
  );
};

export default TextFieldInput;
