import { useSelector } from "react-redux";
import type { IRootState } from "store/index";

export const useUserType = (): { isHOAUser: boolean; isBoardMember: boolean } => {
  const { currentLoginResponse } = useSelector((state: IRootState) => state.auth);

  return {
    isHOAUser: Boolean(currentLoginResponse?.currentDbTenant.isHOAUser),
    isBoardMember: Boolean(currentLoginResponse?.isBoardMember)
  };
};
