import type { PhoneNumberSubscriptionStatusDto } from "@doorloop/dto";
import { atom, useAtom, useAtomValue } from "jotai";
import { currentConversationAtom } from "screens/communicationsCenter/shared/currentConversationAtom";
import { useEffectAsync } from "../../../../hooks/useEffectAsync";
import { phoneNumbersApi } from "api/phoneNumbersApi";

export const currentConversationSubscriptionStatusAtom = atom<PhoneNumberSubscriptionStatusDto | null>(null);
export const useCurrentConversationSubscriptionStatus = () => {
  const currentConversation = useAtomValue(currentConversationAtom);
  const [currentConversationSubscriptionStatus, setCurrentConversationSubscriptionStatus] = useAtom(
    currentConversationSubscriptionStatusAtom
  );

  useEffectAsync(async () => {
    const isUnknownContact = !currentConversation?.recipient.linkedToId;

    if (currentConversation && !isUnknownContact) {
      setCurrentConversationSubscriptionStatus(null);
      const response = await phoneNumbersApi.getConversationParticipantSubscriptionStatus(
        currentConversation.recipient
      );

      if (response.status && response.data) {
        setCurrentConversationSubscriptionStatus(response.data);
      }
    }
  }, [currentConversation?.id]);

  return { currentConversationSubscriptionStatus };
};
