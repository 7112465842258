export enum actionsPanelTypesEnums {
  READ_ONLY_ACTIONS_PANEL = 1,
  ADD_ACTIONS_PANEL,
  REMOVE_ACTIONS_PANEL,
  EDIT_ACTIONS_PANEL,
  LOADING_ACTIONS_PANEL
}

export enum actionsPanelIconsTypesEnums {
  REMOVE_ACTION_PANEL = 1,
  EDIT_ACTION_PANEL,
  SHOW_ACTION_PANEL,
  HIDE_ACTION_PANEL,
  COPY_ACTION_PANEL,
  CONFIRM_ACTION_PANEL,
  CLOSE_ACTION_PANEL,
  ADD_ACTION_PANEL,
  LOADING_ACTION_PANEL
}
