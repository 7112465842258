import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import Text from "DLUI/text";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { SettingsSection } from "screens/settings/common/settingsSection";
import { useManagementFeesStyles } from "screens/settings/managementFees/useStyles";
import { getIn, useFormikContext } from "formik";
import type { ManagementFeeRuleDto, ManagementFeesCompanySettingsDto, PayOutManagementAccounts } from "@doorloop/dto";
import { DataCy, isPercentageBasedFee } from "@doorloop/dto";
import type { CSSProperties } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { useTypedTranslation } from "locale";
import { Reset } from "@/assets";
import type { TextColor } from "DLUI/text/text";
import { RuleDisplay } from "screens/settings/managementFees/sampleCalculator/ruleDisplay";
import { SubtotalSection } from "screens/settings/managementFees/sampleCalculator/subtotalSection";
import { PayoutAccountsDisplay } from "screens/settings/managementFees/sampleCalculator/payoutAccountsDisplay";
import { SummarySection } from "screens/settings/managementFees/sampleCalculator/summarySelection";
import { useGeneralStyles } from "styles/generalStyles";
import type { GetFormikManagementFeesKey } from "screens/settings/managementFees/managementFeesForm";
import { useCalculatorStyles } from "DLUI/dialogs/settings/common/settings/calculatorStyles";

const BUTTON_STYLE: CSSProperties = { height: 32 };
const EDIT_BUTTON: CSSProperties = { ...BUTTON_STYLE, minWidth: 90 };
const RESET_BUTTON: CSSProperties = { ...BUTTON_STYLE, minWidth: 80 };

export type AmountsForKey<T = string> = Record<string, T>;

interface SampleCalculatorProps {
  getFormikManagementFeesKey: GetFormikManagementFeesKey;
}

export const SampleCalculator = ({ getFormikManagementFeesKey }: SampleCalculatorProps) => {
  const { values } = useFormikContext<ManagementFeesCompanySettingsDto>();
  const [payoutAccountsAmounts, setPayoutAccountsAmounts] = useState<AmountsForKey>({});
  const [isEditMode, setIsEditMode] = useState(false);
  const classes = useManagementFeesStyles();
  const calculatorClasses = useCalculatorStyles();
  const generalClasses = useGeneralStyles();
  const { t } = useTypedTranslation();
  const textColor: TextColor = isEditMode ? "black" : "secondary-gray";

  const rules: ManagementFeeRuleDto[] | undefined = getIn(values, getFormikManagementFeesKey("feeRules"));
  const isChargeFeeAmount = Boolean(getIn(values, getFormikManagementFeesKey("chargeMinChargeFee")));
  const payOutAccountsKeys: PayOutManagementAccounts[] | undefined = getIn(values, "payOutAccounts");
  const minimumFeeAmount: number = getIn(values, getFormikManagementFeesKey("minChargeFee.feeAmount")) ?? 0;

  const isEditButtonDisabled = useMemo(() => {
    const percentBasedFeeRules = rules?.filter(({ ruleType }) => isPercentageBasedFee(ruleType)) ?? [];
    const accounts = payOutAccountsKeys ?? [];
    return Boolean(percentBasedFeeRules.length === 0 && accounts.length === 0);
  }, [payOutAccountsKeys, rules]);

  const [amounts, setAmounts] = useState<AmountsForKey>({});

  useEffect(() => {
    setAmounts((prev) => {
      const _rules = rules ?? [];
      const currentAmounts = _rules.reduce((acc, { _id, uniqueKey }) => {
        const key = _id ?? uniqueKey!;
        acc[key] = prev[key] ?? "1000";
        return acc;
      }, {} as AmountsForKey);
      return { ...currentAmounts };
    });
  }, [rules, isEditMode]);

  useEffect(() => {
    setPayoutAccountsAmounts((prev) => {
      const keys = payOutAccountsKeys ?? [];
      const currentPayOutAccountsKeys = keys.reduce((acc, { uniqueKey }) => {
        const key = uniqueKey!;
        acc[key] = prev[key] ?? "1000";
        return acc;
      }, {} as AmountsForKey);
      return { ...currentPayOutAccountsKeys };
    });
  }, [payOutAccountsKeys, isEditMode]);

  const results: AmountsForKey<number> = useMemo(() => {
    const _rules = rules ?? [];
    return _rules.reduce((acc, { ruleType, _id, uniqueKey, fee }) => {
      const formattedFee = fee ?? 0;
      const key = _id ?? uniqueKey!;
      acc[key] = isPercentageBasedFee(ruleType) ? (formattedFee * Number(amounts[key])) / 100 : formattedFee;
      return acc;
    }, {} as AmountsForKey<number>);
  }, [amounts, rules, isEditMode]);

  const rulesSubtotal = Object.values(results).reduce((acc, value) => acc + value, 0);
  const payoutAccountsSubtotal = Object.values(payoutAccountsAmounts).reduce((acc, value) => acc + Number(value), 0);

  const total =
    rulesSubtotal +
    (isChargeFeeAmount && minimumFeeAmount - rulesSubtotal >= 0 ? minimumFeeAmount - rulesSubtotal : 0) +
    payoutAccountsSubtotal;

  const reset = useCallback(() => {
    setIsEditMode(false);
    setAmounts((prevAmounts) => {
      const updated = { ...prevAmounts };
      Object.keys(updated).forEach((key) => {
        updated[key] = "1000";
      });
      return updated;
    });
    setPayoutAccountsAmounts((prevAmounts) => {
      const updated = { ...prevAmounts };
      Object.keys(updated).forEach((key) => {
        updated[key] = "1000";
      });
      return updated;
    });
  }, []);

  useEffect(() => {
    if (isEditButtonDisabled) {
      reset();
    }
  }, [isEditButtonDisabled, reset]);

  return (
    <SettingsSection
      dataCy={DataCy.globalSettings.managementFees.sampleCalculator.container}
      title={"settings.generalSettings.simulation"}
      end={
        <div className={clsx(generalClasses.flexRow, generalClasses.verticalAlign, generalClasses.mediumGap)}>
          {isEditMode && (
            <DLButton
              onClick={reset}
              actionText={t("common.reset")}
              variant={DLButtonVariantsEnum.TEXT}
              color={DLButtonColorsEnum.SECONDARY}
              size={DLButtonSizesEnum.MEDIUM}
              style={RESET_BUTTON}
              icons={{ start: { src: Reset } }}
              disableMobileCollapse
              dataCy={DataCy.globalSettings.managementFees.sampleCalculator.resetButton}
              containerClassName={calculatorClasses.resetButton}
            />
          )}
          <DLButton
            onClick={() => setIsEditMode(true)}
            actionText={t("settings.generalSettings.managementFees.sampleCalculator.editInputs")}
            variant={DLButtonVariantsEnum.CONTAINED}
            color={DLButtonColorsEnum.SECONDARY}
            size={DLButtonSizesEnum.MEDIUM}
            style={EDIT_BUTTON}
            disableMobileCollapse
            disabled={isEditButtonDisabled}
            dataCy={DataCy.globalSettings.managementFees.sampleCalculator.editButton}
          />
        </div>
      }
      backgroundColor={"newPalette.background.neutral1"}
    >
      <Text
        value={t("settings.generalSettings.managementFees.sampleCalculator.intro")}
        fontSize={14}
        fontWeight={400}
        color={"secondary-gray"}
      />
      {rules && rules.length > 0 && <HorizontalSeparationLine marginTop={16} marginBottom={16} />}
      <div className={classes.columnContainer}>
        {rules &&
          rules.map((rule, index) => (
            <RuleDisplay
              key={rule._id ?? rule.uniqueKey}
              rule={rule}
              index={index}
              isEditable={isEditMode}
              textColor={textColor}
              amounts={amounts}
              setAmounts={setAmounts}
            />
          ))}
      </div>
      <HorizontalSeparationLine marginTop={16} marginBottom={16} />
      <SubtotalSection
        rulesSubtotal={rulesSubtotal}
        minimumFeeAmount={minimumFeeAmount}
        isChargeFeeAmount={isChargeFeeAmount}
        textColor={textColor}
      />
      <PayoutAccountsDisplay
        payOutAccountsKeys={payOutAccountsKeys}
        isEditable={isEditMode}
        payoutAccountsAmounts={payoutAccountsAmounts}
        setPayoutAccountsAmounts={setPayoutAccountsAmounts}
        textColor={textColor}
      />
      <HorizontalSeparationLine marginTop={16} marginBottom={16} />
      <SummarySection total={total} textColor={textColor} />
    </SettingsSection>
  );
};
