import React, { useState, useCallback } from "react";
import { View } from "DLUI/view";
import { Button } from "DLUI/button";
import { AddIcon } from "../../../../assets";
import type { ActionPanelClick } from "DLUI/form/fieldsActionsPanel/actionPanels/actionPanelItem";
import FieldsActionsPanel from "DLUI/form/fieldsActionsPanel/fieldsActionsPanel";
import { actionsPanelTypesEnums } from "DLUI/form/fieldsActionsPanel/actionPanels/enums";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import ShakeEffectView from "DLUI/animatableView/shakeEffectView";

interface ComponentProps {
  addLineMode?: boolean;
  setAddLineMode?: (bool: boolean) => void;
  addText?: string;
  NewLineFields: any;
  onCreate: ActionPanelClick;
  abortNewLineAction?: ActionPanelClick;
  fieldsValues: any;
  newLineOnChange?: any;
  validation?: (data: any) => boolean;
  clearance?: AnyPermissionClearance;
  staticNewLine?: boolean;
}
const ActionsPanelFieldsNewLine: React.FC<ComponentProps> = ({
  addLineMode,
  setAddLineMode,
  addText,
  NewLineFields,
  onCreate,
  abortNewLineAction,
  fieldsValues,
  newLineOnChange,
  validation,
  staticNewLine,
  clearance
}: ComponentProps) => {
  const [shake, setShake] = useState(false);

  const onAbortAction = useCallback(() => {
    if (staticNewLine) {
      setAddLineMode && setAddLineMode(true);
      clickedShake();
    }

    setAddLineMode && setAddLineMode(false);
    abortNewLineAction && abortNewLineAction();
  }, [staticNewLine]);

  const clickedShake = useCallback(() => {
    setShake(true);
    setTimeout(() => {
      setShake(false);
    }, 300);
  }, []);

  const handleNewLineClick = useCallback(() => {
    if (addLineMode) {
      clickedShake();
    } else {
      setAddLineMode && setAddLineMode(true);
    }
  }, [addLineMode]);

  return (
    <RestrictedPermissionAccess clearance={clearance}>
      <View>
        {addLineMode && (
          <ShakeEffectView fullWidth showEffect={shake}>
            <FieldsActionsPanel
              newLineOnChange={newLineOnChange}
              isAddLine
              data={fieldsValues}
              addAction={onCreate}
              staticActionPanelType={actionsPanelTypesEnums.ADD_ACTIONS_PANEL}
              AddFields={NewLineFields}
              abortNewLineAction={onAbortAction}
              validator={validation}
              addClearanceAction={clearance}
            />
          </ShakeEffectView>
        )}

        <Button
          color={"lightBlue"}
          type={"inlineText"}
          actionText={addText || "Add Line Item"}
          onClick={handleNewLineClick}
          LeftIcon={AddIcon}
          applyColorForIcons
          iconSize={15}
        />
      </View>
    </RestrictedPermissionAccess>
  );
};

export default ActionsPanelFieldsNewLine;
