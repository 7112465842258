import { useQuery } from "@tanstack/react-query";
import { createMutationStore } from "../createMutationStore";
import { createQueryStore } from "../createQueryStore";
import { merchantAccountApi } from "@/api/bankAccountsApi";
import type { GetLimitsForMerchantAccountQuery } from "@doorloop/dto";

export const merchantAccountsStore = {
  queries: {
    ...createQueryStore("merchantAccounts", merchantAccountApi),
    useGetLimitsForMerchantAccount: (query: GetLimitsForMerchantAccountQuery) =>
      useQuery({
        enabled: Boolean(query.propertyId),
        queryKey: [`limits-for-merchant-account`, query.propertyId],
        queryFn: async ({ signal }) => {
          const result = await merchantAccountApi.getLimitsForMerchantAccount(query);
          if (!result || signal?.aborted) {
            return null;
          }
          if (!result.status) {
            throw new Error(result.message);
          }
          return result?.data;
        },
        onError: (error: Error) => error,
        refetchOnWindowFocus: false
      })
  },
  mutations: createMutationStore("merchantAccounts", merchantAccountApi)
};
