import { AllocationRounding, AllocationStrategyWithCustom } from "@doorloop/dto";
import type { BulkChargeAllocationDto } from "@doorloop/dto";
import type { BulkCreditAllocationDto } from "@doorloop/dto";
import type { LeaseTransactionWithUnitBaseDto } from "@doorloop/dto";
import type { UnitDto } from "@doorloop/dto";
import { customAllocationsApi } from "api/customAllocationsApi";
import type { FormikContextType } from "formik";
export type BulkTransactionDto = BulkChargeAllocationDto | BulkCreditAllocationDto;

const getRoundedValue = (value: number, rounding: AllocationRounding) => {
  if (rounding === AllocationRounding.NEAREST_HUNDREDTH) {
    value = Math.round(value * 100) / 100;
  } else if (rounding === AllocationRounding.NEAREST_TENTH) {
    value = Math.round(value * 10) / 10;
  } else if (rounding === AllocationRounding.NEAREST_WHOLE_NUMBER) {
    value = Math.round(value);
  }
  return value;
};

const getSanitizedAmount = (amount: number) => {
  if (amount === Infinity || amount === -Infinity || isNaN(amount) || amount <= 0) {
    return 0;
  }
  return amount;
};

type AllocationProps = {
  total: number;
  rounding: AllocationRounding;
  selectedChargeCount: number;
  leaseToUnitMap: Map<string, UnitDto>;
  transactions: LeaseTransactionWithUnitBaseDto[];
  formikContext: FormikContextType<BulkTransactionDto>;
  totalSelectedSqft?: number;
  customAllocationId?: string;
} & (
  | {
      strategy: AllocationStrategyWithCustom.BY_SIZE;
      totalSelectedSqft: number;
    }
  | {
      strategy: AllocationStrategyWithCustom.EVEN;
    }
  | {
      strategy: AllocationStrategyWithCustom.CUSTOM;
      customAllocationId: string;
    }
);

export const allocateUsingStrategy = async ({
  strategy,
  total,
  rounding,
  selectedChargeCount,
  leaseToUnitMap,
  transactions,
  formikContext,
  totalSelectedSqft,
  customAllocationId
}: AllocationProps) => {
  let actualAllocatedAmount = 0;
  if (strategy === AllocationStrategyWithCustom.EVEN) {
    // Get all the transactions and set the amount to be the same for each charge based on the total charge.
    const amount = getRoundedValue(total / selectedChargeCount, rounding);
    for (let i = 0; i < transactions.length; i++) {
      if (!transactions[i].lease) continue;
      const isChargeSelected = Boolean(formikContext.values.transactions[i]?.isSelected);
      let chargeToAllocate = isChargeSelected ? amount : 0;
      const fieldName = `transactions[${i}].lines[0].amount`;

      chargeToAllocate = getSanitizedAmount(chargeToAllocate);

      formikContext.setFieldValue(fieldName, chargeToAllocate, false);
      actualAllocatedAmount += chargeToAllocate;
    }
  } else if (strategy === AllocationStrategyWithCustom.BY_SIZE) {
    if (!totalSelectedSqft) {
      // This shouldn't be required because of discriminated unions,
      // but typescript is complaining because of version mismatch.
      throw new Error("totalSelectedSqft is required");
    }

    // Allocate the transactions based on the size of the unit (unit.sqft)
    // similarly, we only need to allocate the transactions that are selected.

    for (let i = 0; i < transactions.length; i++) {
      if (!transactions[i].lease) continue;
      const isChargeSelected = Boolean(formikContext.values.transactions?.[i]?.isSelected);
      const unit = leaseToUnitMap.get(transactions[i].lease!);
      const sqft = unit?.size ?? 0;
      const fieldName = `transactions[${i}].lines[0].amount`;
      let amount = sqft > 0 && isChargeSelected ? getRoundedValue((sqft / totalSelectedSqft) * total, rounding) : 0;

      amount = getSanitizedAmount(amount);

      formikContext.setFieldValue(fieldName, amount, false);
      actualAllocatedAmount += amount;
    }
  } else if (strategy === AllocationStrategyWithCustom.CUSTOM) {
    if (!customAllocationId) {
      // this shouldn't happen
      throw new Error("customAllocationId is required");
    }

    const { data: customAllocation, status } = await customAllocationsApi.get(customAllocationId);
    if (!status || !customAllocation) {
      return;
    }

    const { allocations } = customAllocation;

    /**
     * Allocate the transactions based on the custom allocation, each of our transactions in the formik has a unitId
     * we can use that to find the allocation for that unit, and set the amount to be the same for each charge
     * based on the total charge. The allocation looks like {unitId: "123", percentage: 40}
     * so we need to find the unitId in the transaction and then find the allocation for that unitId,
     * and then set the amount to be the percentage of the total charge.
     */

    for (let i = 0; i < transactions.length; i++) {
      if (!transactions[i].lease) continue;
      const isChargeSelected = Boolean(formikContext.values.transactions?.[i]?.isSelected);
      const { unitId } = transactions[i];
      const allocation = allocations.find((a) => a.unitId === unitId);

      const fieldName = `transactions[${i}].lines[0].amount`;
      let amount = 0;
      // the percentage is optional, and it also can be 0
      if (allocation?.percentage && allocation.percentage > 0) {
        amount = isChargeSelected ? getRoundedValue((allocation.percentage / 100) * total, rounding) : 0;
      }

      amount = getSanitizedAmount(amount);

      formikContext.setFieldValue(fieldName, amount, false);
      actualAllocatedAmount += amount;
    }
  }

  // when the allocation is done, we return the total amount that was allocated
  // so that we can show it in the UI.
  return actualAllocatedAmount;
};
