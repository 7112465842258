import { makeStyles } from "@material-ui/styles";
import type { Theme } from "styles/defaultTheme";

export const useCalculatorStyles = makeStyles<Theme>((theme) => {
  return {
    resetButton: {
      "&:hover": {
        backgroundColor: theme.newPalette.secondary.states.hover
      },
      width: 75
    },
    calculatorRuleRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      minHeight: 40
    },
    calculatorInputLabel: {
      [theme.breakpoints.up("sm")]: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "100%"
      }
    }
  };
});
