"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESignatureSignerType = void 0;
var ESignatureSignerType;
(function (ESignatureSignerType) {
    ESignatureSignerType["TENANT"] = "TENANT";
    ESignatureSignerType["VENDOR"] = "VENDOR";
    ESignatureSignerType["OWNER"] = "OWNER";
    ESignatureSignerType["USER"] = "USER";
    ESignatureSignerType["PROSPECT"] = "PROSPECT";
})(ESignatureSignerType || (exports.ESignatureSignerType = ESignatureSignerType = {}));
