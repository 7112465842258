"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Position = void 0;
var Position;
(function (Position) {
    Position["PRESIDENT"] = "PRESIDENT";
    Position["VICE_PRESIDENT"] = "VICE_PRESIDENT";
    Position["SECRETARY"] = "SECRETARY";
    Position["TREASURY"] = "TREASURY";
    Position["BOARD_MEMBER"] = "BOARD_MEMBER";
})(Position || (exports.Position = Position = {}));
