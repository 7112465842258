import React, { useMemo } from "react";
import type { PropertyDto } from "@doorloop/dto";
import { DataCy, ObjectPermission } from "@doorloop/dto";
import { CloseIcon, EditBlue, LocationIcon, NotRenewingLeaseIcon, UnitBlueIcon, ViewBlueIcon } from "assets/icons";
import { Icon } from "DLUI/icon";
import { Grid, ListItemContainer, ListItemSection } from "DLUI/listItems";
import type { PopoverItem } from "DLUI/popover";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import { useLocation } from "react-router-dom";
import { getPropertyIcon } from "utils/iconsMapper";
import { NavigationManager } from "utils/navigation";
import { getPropertyType } from "utils/stringsConverter";
import { useTranslation } from "react-i18next";
import { usePermission } from "screens/settings/userRoles/usePermission";
import {
  DefaultCardItemFontSize,
  DefaultListItemFontSize,
  DefaultListItemIconSize
} from "screens/leases/leases/leasesList/leaseListItem";
import ListItemText from "DLUI/listItems/listItemText";
import { PictureThumbnail } from "screens/properties/propertiesList/pictureThumbnail";
import { Routes } from "../../../appRouter";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { useConfirmationDialog } from "@/hooks/useConfirmationDialog";

interface ComponentProps {
  propertyDto: PropertyDto;
  onItemPress?: (propertyId: string, propertyDto?: PropertyDto) => void;
  disableListItemOptions?: boolean;
}

const PropertyListItem: React.FC<ComponentProps> = ({
  propertyDto,
  onItemPress,
  disableListItemOptions
}: ComponentProps) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();
  const { hasPermission } = usePermission();
  const { showConfirmationDialog } = useConfirmationDialog();
  const hasViewOnePermission = hasPermission(ObjectPermission.properties, "viewOne");
  const didPressPropertyItem = hasViewOnePermission
    ? () => {
        if (onItemPress) {
          onItemPress(propertyDto.id!, propertyDto);
        } else {
          NavigationManager.viewPropertyDetails(propertyDto.id!);
        }
      }
    : undefined;

  const streetLocation = useMemo(() => {
    let streetLocation = "";
    streetLocation = streetLocation.concat(
      propertyDto.address?.city || "",
      propertyDto.address?.state ? ", " + propertyDto.address?.state : "",
      propertyDto.address?.zip ? ", " + propertyDto.address?.zip : ""
    );
    return streetLocation;
  }, []);

  const propertyType = useMemo(() => getPropertyType(propertyDto.type!), []);
  const PropertyIcon = useMemo(() => getPropertyIcon(propertyDto.type!), []);

  const didPressViewProperty = () => {
    if (propertyDto.id) {
      NavigationManager.viewPropertyDetails(propertyDto.id);
    }
  };

  const didPressEditProperty = () => {
    propertyDto.id && NavigationManager.editPropertyDialog(propertyDto.id);
  };

  const didPressDeleteProperty = () => {
    showConfirmationDialog(location.pathname, {
      dialogTitle: AppStrings.Common.DeleteConfirmation,
      dialogSubTitle: AppStrings.Properties.Screen.DeleteFileConfirmationText,
      loadingText: t(AppStrings.Properties.Screen.DeleteFileLoadingText),
      successText: t(AppStrings.Properties.Screen.DeleteFileSuccessText),
      confirmButtonText: AppStrings.Common.Delete,
      dismissButtonText: AppStrings.Common.Dismiss,
      apiMethod: "propertiesApi",
      confirmOperation: "delete",
      itemId: propertyDto.id
    });
  };

  const didPressMakeInactive = () => {
    showConfirmationDialog(location.pathname, {
      dialogTitle: AppStrings.Common.InactiveConfirmation,
      dialogSubTitle: AppStrings.Properties.Screen.MakeInactiveFileConfirmationText,
      loadingText: t(AppStrings.Common.OperationInprogress),
      successText: t(AppStrings.Common.OperationSucceeded),
      confirmButtonText: AppStrings.Accounts.ChartOfAccounts.MakeInactive,
      dismissButtonText: AppStrings.Common.Dismiss,
      apiMethod: "propertiesApi",
      confirmOperation: "makeInactive",
      itemId: propertyDto.id,
      itemDto: propertyDto,
      toasts: { severity: "error", translationKey: AppStrings.Toasts.custom.properties.deactivate?.PUT }
    });
  };

  const didPressMakeActive = () => {
    showConfirmationDialog(location.pathname, {
      dialogTitle: AppStrings.Common.ActiveConfirmation,
      dialogSubTitle: AppStrings.Properties.Screen.MakeActiveFileConfirmationText,
      loadingText: t(AppStrings.Common.OperationInprogress),
      successText: t(AppStrings.Common.OperationSucceeded),
      confirmButtonText: AppStrings.Common.Activate,
      dismissButtonText: AppStrings.Common.Dismiss,
      apiMethod: "propertiesApi",
      confirmOperation: "makeActive",
      itemId: propertyDto.id,
      itemDto: propertyDto,
      toasts: { translationKey: AppStrings.Toasts.custom.properties.activate?.PUT }
    });
  };

  const popoverTopSectionItems: PopoverItem[] = useMemo(() => {
    const popOverItems: PopoverItem[] = [
      {
        Icon: ViewBlueIcon,
        title: AppStrings.Common.View,
        onClick: didPressViewProperty,
        showSeparator: true,
        clearance: { permission: ObjectPermission.properties, field: "viewOne" },
        dataCy: DataCy.DLUI.listItem.listItemView
      },
      {
        Icon: EditBlue,
        title: AppStrings.Common.Edit,
        onClick: didPressEditProperty,
        showSeparator: true,
        clearance: { permission: ObjectPermission.properties, field: "edit" },
        dataCy: DataCy.DLUI.listItem.listItemEdit
      }
    ];
    if (propertyDto.active) {
      popOverItems.push({
        Icon: NotRenewingLeaseIcon,
        title: AppStrings.Accounts.ChartOfAccounts.MakeInactive,
        onClick: didPressMakeInactive,
        iconPathColor: "error",
        textColor: "error",
        showSeparator: true,
        clearance: { permission: ObjectPermission.properties, field: "edit" },
        dataCy: DataCy.DLUI.listItem.listItemMakeInactive
      });
    } else {
      popOverItems.push({
        Icon: CloseIcon,
        title: AppStrings.Common.MakeActive,
        onClick: didPressMakeActive,
        showSeparator: true,
        clearance: { permission: ObjectPermission.properties, field: "edit" },
        dataCy: DataCy.DLUI.listItem.listItemMakeActive
      });
    }
    popOverItems.push({
      Icon: CloseIcon,
      title: AppStrings.Common.Delete,
      onClick: didPressDeleteProperty,
      iconPathColor: "error",
      textColor: "error",
      clearance: { permission: ObjectPermission.properties, field: "delete" },
      dataCy: DataCy.DLUI.listItem.listItemDelete
    });
    return popOverItems;
  }, []);

  const iconsSize = isMobile ? DefaultCardItemFontSize : DefaultListItemIconSize;

  return (
    <ListItemContainer
      backgroundColor={propertyDto.active ? "light" : "dark"}
      popoverItems={disableListItemOptions ? undefined : popoverTopSectionItems}
    >
      <Grid
        title={isMobile ? undefined : AppStrings.Properties.ListHeader.Property}
        showDivider
        xs={12}
        sm={5}
        md={5}
        lg={7}
        onClick={didPressPropertyItem}
        sortColumn={"name"}
      >
        <View alignItems={"center"} height={"100%"} flexDirection={"row"}>
          <PictureThumbnail pictures={propertyDto.pictures} alternateIcon={PropertyIcon} />
          <ListItemSection
            renderTitle={
              <ListItemText
                href={propertyDto.id ? `${Routes.PROPERTIES}/${propertyDto.id}/overview` : undefined}
                color={"black"}
                fontWeight={700}
                overflow={"ellipsis"}
                numberOfLines={2}
              >
                {propertyDto.name ? propertyDto.name : propertyDto.address?.street1}
              </ListItemText>
            }
            renderSubTitle={
              <View justifyContent={"flex-start"} alignItems={"center"} flexDirection={"row"}>
                <Icon Source={LocationIcon} width={15} height={15} marginRight={5} pathColor={"gray"} />

                <Text fullWidth fontWeight={500} color={"gray"} fontSize={DefaultListItemFontSize}>
                  {streetLocation}
                </Text>
              </View>
            }
          />
        </View>
      </Grid>
      <Grid
        title={AppStrings.Properties.ListHeader.Type}
        showDivider
        xs={12}
        sm={3}
        md={3}
        lg={2}
        onClick={didPressPropertyItem}
        sortColumn={"type"}
      >
        <View flexDirection={"row"} alignItems={"center"} flex={1}>
          <Icon Source={PropertyIcon} pathColor={"black"} width={iconsSize} />
          <Text marginLeft={5} color={"black"} fontSize={DefaultListItemFontSize} value={propertyType} />
        </View>
      </Grid>
      <Grid
        title={AppStrings.Properties.ListHeader.ActiveUnits}
        xs={12}
        sm={3}
        md={3}
        lg={2}
        onClick={didPressPropertyItem}
        sortColumn={"numActiveUnits"}
      >
        <View flexDirection={"row"} alignItems={"center"} flex={1}>
          <Icon Source={UnitBlueIcon} pathColor={"black"} width={iconsSize} />

          <Text marginLeft={5} fontSize={DefaultListItemFontSize} color={"black"}>
            {propertyDto.numActiveUnits}
          </Text>
          <Text
            fontSize={DefaultListItemFontSize}
            color={"black"}
            value={
              propertyDto.numActiveUnits === 1
                ? AppStrings.Common.Unit
                : AppStrings.Properties.PropertiesListItem.Unitslabel
            }
            marginLeft={5}
          />
        </View>
      </Grid>
    </ListItemContainer>
  );
};

export default PropertyListItem;
