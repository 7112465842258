import React, { Fragment, useMemo } from "react";
import { CheckBoldIcon } from "assets/icons";
import { Icon } from "DLUI/icon";
import { View } from "DLUI/view";
import { useReadOnlyContext } from "contexts/readOnlyContext";

interface ComponentProps {
  containerWidth?: string | number;
  selectedColor?: string;
  onSelectionChange: (selectedColor: string) => void;
  defaultValue?: string;
}

const ThemeColors = [
  "#2F3E83",
  "#182C4C",
  "#333333",
  "#7A8699",
  "#EB5757",
  "#F2C94C",
  "#219653",
  "#2F80ED",
  "#9B51E0",
  "#F2994A"
];

/**
 * @deprecated use {@link ColorPicker} instead
 * @see {@link ColorPicker}
 * */
export const ColorPicker_Deprecated: React.FC<ComponentProps> = ({
  containerWidth,
  onSelectionChange,
  selectedColor,
  defaultValue
}: ComponentProps) => {
  const isReadOnly = useReadOnlyContext();
  const colorSelectionViews = useMemo(
    () =>
      ThemeColors.map((currentColor: string, index: number) => {
        const didPressColorItem = () => {
          onSelectionChange(ThemeColors[index]);
        };

        const renderSelectionIcon = () => {
          if (
            (selectedColor === undefined && defaultValue === undefined && index === 0) ||
            currentColor === selectedColor
          ) {
            return <Icon className={"fadeIn"} Source={CheckBoldIcon} />;
          }
        };

        return (
          <Fragment key={"CPI" + index}>
            <View
              width={50}
              height={50}
              marginLeft={10}
              marginTop={20}
              style={{ backgroundColor: currentColor }}
              borderRadius={25}
              cursor={"pointer"}
              onClick={isReadOnly ? undefined : didPressColorItem}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {renderSelectionIcon()}
            </View>
          </Fragment>
        );
      }),
    [selectedColor]
  );

  return (
    <View width={containerWidth} flexDirection={"row"}>
      {colorSelectionViews}
    </View>
  );
};
