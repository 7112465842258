import type { FC } from "react";
import React, { useCallback, useEffect } from "react";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import type { UnitDto } from "@doorloop/dto";
import { ObjectPermission, PropertyClass, UnitRentalAppListingInfoDto } from "@doorloop/dto";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import { FastField, useFormikContext } from "formik";
import { AddressAutoCompleteForm, FormikSwitchButton, TextField } from "DLUI/form";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { useParams } from "react-router-dom";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { propertiesApi } from "api/propertiesApi";
import Text from "DLUI/text";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import FieldWrapper from "DLUI/form/textField/fieldWrapper";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";
import { ControlledCheckBox } from "DLUI/form/checkBox/base/controlledCheckBox";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { useUserType } from "../../../../../hooks/useUserType";
import { unitsApi } from "api/unitsApi";

interface Props {
  changeProperty: (propertyId: string) => void;
  currentPropertyClass?: PropertyClass;
}

export const getTransunionPropertyId = (propertyId?: string, unitId?: string) => {
  if (propertyId) {
    return propertiesApi.getItemFromDictionary(propertyId)?.transunionPropertyId;
  }
  if (unitId) {
    const unit = unitsApi.getItemFromDictionary(unitId);
    if (unit?.property) {
      const property = propertiesApi.getItemFromDictionary(unit.property);
      if (property?.transunionPropertyId) {
        return property?.transunionPropertyId;
      }
    }
  }
};

export const EditUnitGeneralInfo: FC<Props> = ({ changeProperty, currentPropertyClass }: Props) => {
  const { dialogHorizontalPadding } = DialogsHelper();
  const formikRef = useFormikContext<UnitDto>();
  const { t } = useTranslation();
  const { unitId, propertyId } = useParams<any>();
  const propertyIdValue = propertyId || formikRef.values?.property;
  const editMode = unitId !== undefined;
  const clearance: AnyPermissionClearance = {
    permission: ObjectPermission.units,
    field: editMode ? "edit" : "create"
  };
  const { isHOAUser } = useUserType();

  const propertyFieldChange = useCallback(
    (params, values) => {
      if (values !== null) {
        formikRef.setFieldValue("property", values.id);
        changeProperty(values.id);
      }
    },
    [formikRef]
  );

  const setRentalListingValue = (value: boolean) => {
    if (!formikRef.values?.rentalApplicationListing) {
      formikRef.setFieldValue(
        "rentalApplicationListing",
        new UnitRentalAppListingInfoDto({
          activeListing: value
        })
      );
    } else {
      formikRef.setFieldValue("rentalApplicationListing.activeListing", value);
    }
  };

  useEffect(() => {
    if (propertyIdValue) {
      const transunionPropertyIdFound = getTransunionPropertyId(propertyIdValue);

      if (transunionPropertyIdFound && formikRef?.values?.rentalApplicationListing?.activeListing === undefined) {
        setRentalListingValue(true);
      } else if (!transunionPropertyIdFound) {
        setRentalListingValue(false);
      }
    }
  }, [propertyIdValue]);

  const isResidential = currentPropertyClass !== PropertyClass.COMMERCIAL;

  return (
    <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding}>
      <RestrictedPermissionAccess clearance={clearance} showNoAccess>
        <View fullWidth>
          <View>
            <Text fontSize={16} value={AppStrings.Listing.GeneralUnitInfoDescription} />
          </View>
          <HorizontalSeparationLine marginTop={20} />
          <View marginTop={20}>
            <Text fontSize={18} value={AppStrings.Properties.Settings.Property} bold />
          </View>
          <View flexDirection={"row"}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <View>
                <FormikCachedAsyncAutoComplete
                  label={t(AppStrings.Common.FilterOptions.Property)}
                  uniqueIndex={0}
                  apiHandler={propertiesApi}
                  onChange={propertyFieldChange}
                  displayNameKey={"name"}
                  filterFieldName={"filter_text"}
                  filterFieldValue={"name"}
                  selectionFields={["id", "class"]}
                  name={"property"}
                  marginTop={20}
                  size={"100%"}
                  defaultValue={formikRef.values.property || propertyId}
                  queryParams={{ filter_active: true }}
                />
              </View>
            </Grid>
          </View>
          <View marginTop={20}>
            <Text fontSize={18} value={AppStrings.Listing.UnitDetails} bold />
          </View>
          <View flexDirection={"row"}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FastField
                component={TextField}
                label={t(AppStrings.Units.NewUnitDialog.UnitName)}
                name={"name"}
                size={"100%"}
                marginTop={20}
              />
            </Grid>
          </View>
          <View flexDirection={"row"}>
            {!isHOAUser && (
              <Grid item xs={12} sm={isResidential ? 3 : 6} md={isResidential ? 3 : 6} lg={isResidential ? 3 : 6}>
                <FieldWrapper type={"endElement"}>
                  <FastField
                    component={TextField}
                    label={t(AppStrings.Units.NewUnitDialog.MarketRent)}
                    name={"marketRent"}
                    formatType={"currency"}
                  />
                </FieldWrapper>
              </Grid>
            )}
            <Grid item xs={12} sm={isResidential ? 3 : 6} md={isResidential ? 3 : 6} lg={isResidential ? 3 : 6}>
              <FieldWrapper type={isResidential && !isHOAUser ? "middleElement" : "endElement"}>
                <FastField
                  component={TextField}
                  label={t(AppStrings.Units.NewUnitDialog.Size)}
                  name={"size"}
                  size={"100%"}
                  formatType={"number"}
                  decimalScale={2}
                />
              </FieldWrapper>
            </Grid>
            {isResidential && (
              <>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <FieldWrapper type={"middleElement"}>
                    <FastField
                      component={TextField}
                      label={t(AppStrings.Units.NewUnitDialog.Beds)}
                      name={"beds"}
                      size={"100%"}
                      formatType={"number"}
                      decimalScale={1}
                    />
                  </FieldWrapper>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <FieldWrapper type={"singleField"}>
                    <FastField
                      component={TextField}
                      label={t(AppStrings.Units.NewUnitDialog.BathRooms)}
                      name={"baths"}
                      size={"100%"}
                      formatType={"number"}
                      decimalScale={1}
                    />
                  </FieldWrapper>
                </Grid>
              </>
            )}
          </View>
          <View marginTop={20}>
            <FastField
              component={TextField}
              label={t(AppStrings.Properties.Settings.DetailedDescriptionOfThisProperty)}
              name={`description`}
              variant="outlined"
              rowsMax={6}
              rows={6}
              multiline
              maxLength={1000}
            />
          </View>

          <View marginTop={20}>
            <FastFieldSafe
              component={FormikSwitchButton}
              name={`addressSameAsProperty`}
              label={AppStrings.Common.Hoa.UnitAddressIsTheSame}
            />
            {!formikRef.values.addressSameAsProperty && <AddressAutoCompleteForm formikName={`address`} />}
          </View>

          <View marginTop={20}>
            <ControlledCheckBox
              checked={formikRef.values.active}
              onChange={(value) => formikRef.setFieldValue("active", value)}
              labelValueProps={{
                text: t(AppStrings.Listing.ThisUnitIsActive)
              }}
            />
          </View>
        </View>
      </RestrictedPermissionAccess>
    </View>
  );
};
