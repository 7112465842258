import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    tabContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      overflowX: "scroll"
    },
    tabVisible: {
      display: "flex",
      flex: 1,
      width: "100%"
    },
    tabHidden: {
      position: "absolute",
      top: "1000px"
    },
    tabButton: {
      minWidth: "140px"
    },
    tabTextColor: {
      color: theme.palette.black.main,
      textTransform: "none",
      fontFamily: "Lato",
      fontSize: "14px",
      lineHeight: "24px",
      minHeight: "30px"
    },
    tabTextColorSelected: {
      color: theme.palette.darkGreen.main
    },
    listItemsContainer: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      justifyContent: "center",
      width: "100%"
    }
  })
);
