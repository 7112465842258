import React from "react";
import type { StepPropsWithDto } from "screens/onBoarding/types";
import { OnBoardingSuitcaseIcon } from "assets/.";
import AppStrings from "locale/keys";
import Text from "DLUI/text";
import StepSection from "screens/onBoarding/components/stepSection";
import { Icon } from "DLUI/icon";
import { View } from "DLUI/view";
import ActionButton from "screens/onBoarding/components/actionButton";
import { NavigationManager } from "utils/navigation";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { onBoardingApi } from "api/onBoardingApi";
import { VideoUrlsEnum } from "DLUI/screen/helpPanel/types";

type Props = StepPropsWithDto;

const AddCompanyInfoStep: React.FC<Props> = ({
  onHeaderClick,
  isCollapsed,
  name,
  onBoardingDto,
  onInvalidate,
  isCompleted,
  isSkipped
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = React.useState(false);

  const icon = OnBoardingSuitcaseIcon;
  const title = AppStrings.OnBoarding.StepTitles.AddYourCompanyInfo;
  const description = AppStrings.OnBoarding.StepDescriptions.AddYourCompanyInfo;

  const handleAddCompanyInfoClick = async () => {
    if (!isCompleted) {
      setIsLoading(true);
      const response = await onBoardingApi.updateOnBoardingItem(name, { isCompleted: true });

      const hasError = response.statusCode !== 200 ? response.message ?? true : undefined;
      await onInvalidate({ error: hasError });
      setIsLoading(false);

      if (hasError) return;
    }

    NavigationManager.editCompanyInformation(location.pathname);
  };

  const LeftSideContent = () => (
    <View gap={15}>
      <Text whiteSpace={"pre-line"} value={description} />
      <Grid container direction={"row"} alignItems={"center"} spacing={2}>
        <Grid item xs={12} sm={"auto"} md={"auto"}>
          <ActionButton
            onClick={handleAddCompanyInfoClick}
            actionText={AppStrings.OnBoarding.ActionButtons.AddYourCompanyInfo}
            fullWidth
            width={"100%"}
          />
        </Grid>
      </Grid>
    </View>
  );

  return (
    <StepSection
      icon={<Icon size={24} Source={icon} />}
      title={title}
      leftSideContent={<LeftSideContent />}
      isCompleted={isCompleted}
      isSkipped={isSkipped}
      onHeaderClick={onHeaderClick}
      isCollapsed={isCollapsed}
      isLoading={isLoading}
      reactPlayerVideoUrl={VideoUrlsEnum.OB_COMPANY_SETTINGS}
    />
  );
};

export default AddCompanyInfoStep;
