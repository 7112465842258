"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetTaxForm1099ReportQuery = exports.TAX_YEAR_REGEX = exports.TAX_YEAR_FIRST = exports.TAX_YEAR_LAST = void 0;
const class_validator_1 = require("class-validator");
const filterSendTaxForms_enum_1 = require("./filterSendTaxForms.enum");
const taxForm1099ShowRows_enum_1 = require("./taxForm1099ShowRows.enum");
const CURRENT_YEAR = new Date().getFullYear();
exports.TAX_YEAR_LAST = CURRENT_YEAR - 1;
exports.TAX_YEAR_FIRST = 2017;
// TODO: This will work until 2029, or until we start using class-transformer to deserialize our query
// objects on the backend, at which time we can have numerical validation
exports.TAX_YEAR_REGEX = new RegExp(`^201[7-9]|202[0-${exports.TAX_YEAR_LAST % 2020}]$`);
class GetTaxForm1099ReportQuery {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.GetTaxForm1099ReportQuery = GetTaxForm1099ReportQuery;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], GetTaxForm1099ReportQuery.prototype, "filter_ids", void 0);
__decorate([
    (0, class_validator_1.IsNumberString)(),
    (0, class_validator_1.Matches)(exports.TAX_YEAR_REGEX),
    __metadata("design:type", String)
], GetTaxForm1099ReportQuery.prototype, "filter_year", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetTaxForm1099ReportQuery.prototype, "filter_property", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetTaxForm1099ReportQuery.prototype, "filter_propertyGroup", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(taxForm1099ShowRows_enum_1.TaxForm1099ShowRows),
    __metadata("design:type", String)
], GetTaxForm1099ReportQuery.prototype, "showRows", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(filterSendTaxForms_enum_1.SendTaxForms),
    __metadata("design:type", String)
], GetTaxForm1099ReportQuery.prototype, "filter_sendForms", void 0);
