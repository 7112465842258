import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ColorsEnum from "utils/colorsEnum";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    screenPaddingView: {
      width: "100%",
      height: "140px"
    },
    inputTopMargin: {
      paddingTop: 20,
      paddingRight: 20,
      paddingLeft: 20
    },
    addElement: {
      height: 32,
      marginTop: 16,
      [theme.breakpoints.down("sm")]: {
        height: 48,
        fontSize: 16
      }
    },
    addAnotherElement: {
      "&:hover": {
        backgroundColor: theme.newPalette.secondary.states.hover
      },
      height: 32,
      marginTop: 16,
      [theme.breakpoints.down("sm")]: {
        height: 48,
        fontSize: 16
      }
    },
    settingContainer: {
      borderRadius: 8,
      border: `1px solid ${theme.newPalette.other.divider}`,
      padding: 16
    },
    feeItemForm: {
      display: "grid",
      gap: 8,
      gridTemplateColumns: "repeat(4,1fr)",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        gap: 16
      }
    },
    amountField: {
      [theme.breakpoints.down("sm")]: {
        width: 120
      }
    },
    amountAndFrequencyFields: {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        gap: 8
      }
    },
    amountAndFrequencyMobileContainer: {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        gap: 16,
        paddingTop: 8,
        paddingBottom: 8
      }
    },
    limitationsInputSmall: {
      width: 220,
      [theme.breakpoints.down("sm")]: {
        width: "inherit"
      }
    },
    limitationsInputLarge: {
      width: 450,
      [theme.breakpoints.down("sm")]: {
        width: "inherit"
      }
    },
    settingInputs: {
      paddingTop: 16,
      paddingBottom: 4,
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      }
    },
    reminderTypeContainer: {
      display: "flex",
      gap: 24,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: 0
      }
    },
    lateFeeChargeSeparator: {
      height: 22,
      width: 1,
      backgroundColor: ColorsEnum.Black,
      opacity: 0.1,
      marginRight: 8,
      marginLeft: 8
    },
    lateFeeChargeTitle: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: 8,
        alignItems: "unset"
      }
    },
    sampleCalculator: {
      width: 380,
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    rulesContainer: {
      paddingTop: 16,
      display: "flex",
      flexDirection: "column",
      gap: 16
    },
    lateFeesContainer: {
      width: "100%",
      marginBottom: 32
    }
  })
);
