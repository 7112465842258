import { createContext } from "use-context-selector";
import type { FilterOption } from "@/components/DLUI/screen/filterPanel/filterInputs/types";

export interface FilterPanelContextProps<TQuery> {
  filterOptions: Array<FilterOption<TQuery>>;
}

export const FilterPanelContext = createContext<FilterPanelContextProps<never>>({
  filterOptions: []
});
