import { View } from "DLUI/view";
import React from "react";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import type { DtoListFormRenderProps } from "DLUI/form/dto/DtoFieldArray";
import type { VehicleDto } from "@doorloop/dto";
import { RentalApplicationFieldSettings } from "@doorloop/dto";
import ControlledFieldsWrapper from "shared/controlledFieldsWrapper";

export function VehicleFields({ name, stepSettingsDto, isControlled }: DtoListFormRenderProps<VehicleDto>) {
  const { inputRightPadding } = DialogsHelper();
  return (
    <View gap="20px 0" flexDirection={"row"} marginRight={inputRightPadding * -1}>
      <ControlledFieldsWrapper
        initFieldsSettings={RentalApplicationFieldSettings.vehicles.fields}
        fieldsSettings={stepSettingsDto?.fieldsSettings}
        groupSettings={RentalApplicationFieldSettings.vehicles.groups}
        formName={name}
        isControlled={isControlled}
      />
    </View>
  );
}
