import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { View } from "DLUI/view";
import ReactDOM from "react-dom";
import MobileDialog from "../mobileDialog";
import makeStyles from "../styles";
import { Transition } from "../../Transition";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  dialogState: DialogState;
  loadingText?: string;
  errorText?: string;
  successText?: string;
  onRetryButtonPress?: () => void;
  hideRetryButton?: boolean;
  onDismissButtonPress?: () => void;
  height?: number;
}

const ProgressDialogHeight = 600;

const ProgressDialog: React.FC<ComponentProps> = ({
  dialogState,
  loadingText,
  errorText,
  successText,
  onRetryButtonPress,
  hideRetryButton,
  onDismissButtonPress,
  height
}: ComponentProps) => {
  const classes = makeStyles();
  const { isMobile } = useResponsiveHelper();
  const [showDialog, setShowDialog] = useState<boolean>(dialogState !== DialogState.Hidden);
  const [dialogContainer, setDialogContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (dialogContainer === null) {
      setDialogContainer(document.getElementById("dialogContainer"));
    }
  }, [dialogContainer]);

  useEffect(() => {
    setShowDialog(dialogState !== DialogState.Hidden);
  }, [dialogState]);

  if (dialogState === DialogState.Hidden) {
    return <div />;
  }

  const onClose = () => {
    setShowDialog(false);
  };

  if (isMobile) {
    if (dialogContainer === null) {
      return <div />;
    }

    const element = (
      <MobileDialog open={showDialog} onClose={onClose}>
        <div />
      </MobileDialog>
    );
    return ReactDOM.createPortal(element, dialogContainer);
  }

  const didPressDismissButton = () => {
    setShowDialog(false);
    onDismissButtonPress && onDismissButtonPress();
  };

  return (
    <Dialog
      disableEnforceFocus
      TransitionComponent={Transition}
      keepMounted
      open={showDialog}
      scroll={"body"}
      maxWidth={false}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      classes={{
        paperScrollBody: classes.dialogWrapper,
        root: classes.dialogBackground
      }}
    >
      <View width={800} height={ProgressDialogHeight} overflow={"scroll"} flexDirection={"row"} noWrap>
        <LoadingDialog
          dialogState={dialogState}
          loadingText={loadingText}
          errorText={errorText}
          successText={successText}
          onRetryButtonPress={onRetryButtonPress}
          didPressDismissButton={didPressDismissButton}
          minHeight={ProgressDialogHeight}
          hideRetryButton={hideRetryButton}
          height={height || ProgressDialogHeight}
        />
      </View>
    </Dialog>
  );
};

export default ProgressDialog;
