import { View } from "DLUI/view";
import React from "react";
import { DataCy, PaymentMethod } from "@doorloop/dto";
import { ExpenseBillCtaIcon } from "assets/icons";
import { SuccessDialogHeader } from "DLUI/dialogs/successDialog/successDialogHeader";
import { SuccessDialogTitle } from "DLUI/dialogs/successDialog/successDialogTitle";
import { SuccessDialogActions } from "screens/owners/ownerDistributions/components/successDialogOwnerDistribution/components/successDialogActions";
import { useTypedTranslation } from "@/locale";
import { useResponsiveHelper } from "@/contexts/responsiveContext";
import { SummaryItem } from "@/components/screens/owners/ownerDistributions/components/successDialogOwnerDistribution/components/successDialogSummary";
import { HorizontalSeparationLine } from "../../separatorView/horizontalSeparationLine";
import { SummaryLink } from "../successDialog/summaryLink";
import { NavigationManager } from "@/utils/navigation";
import ColorsEnum from "@/utils/colorsEnum";

export interface RefundDepositSummaryProps {
  accountPrintChecks?: string;
  depositsRefunded?: number;
  paymentMethod?: PaymentMethod | "both";
  remainingDeposits: number;
  leaseOutstandingBalance?: number;
  reference?: string;
}

export const RefundDepositSummary: React.FC<RefundDepositSummaryProps> = ({
  accountPrintChecks,
  depositsRefunded = 0,
  paymentMethod,
  remainingDeposits,
  leaseOutstandingBalance,
  reference
}) => {
  const { t } = useTypedTranslation();
  const { isMobile } = useResponsiveHelper();

  const handleViewTransactions = () => {
    NavigationManager.runTransactionsListReport(reference ? { filter_reference: reference } : {}, true);
  };

  return (
    <View
      paddingLeft={16}
      paddingRight={16}
      gap={16}
      backgroundColor="light"
      justifyContent="center"
      alignItems="center"
    >
      <SuccessDialogHeader icon={ExpenseBillCtaIcon} />
      <SuccessDialogTitle
        titleProps={{
          text: t("leases.leaseTransaction.deposit.depositRefunded"),
          fontSize: 14
        }}
        subTitleProps={{
          text: depositsRefunded.toString(),
          fontSize: 36,
          formatType: "currency",
          dataCy: DataCy.leaseDetails.deposits.refundDeposit.depositRefunded
        }}
      />
      <View
        border={`1px solid ${ColorsEnum.LightBlackFadedMore}`}
        backgroundColor="white"
        alignItems="stretch"
        paddingTop={16}
        paddingBottom={16}
        paddingLeft={16}
        paddingRight={16}
        gap={16}
      >
        <SummaryItem
          translationKey={t("leases.leaseTransaction.deposit.remainingDeposits")}
          valueTextFormatType="currency"
          value={remainingDeposits}
          valueDataCy={DataCy.leaseDetails.deposits.refundDeposit.remainingDeposits}
        />
        <SummaryItem
          translationKey={t("leases.draftLease.outstandingBalance")}
          valueTextFormatType="currency"
          value={leaseOutstandingBalance || 0}
        />
        <HorizontalSeparationLine marginTop={4} marginBottom={4} />
        <SummaryLink
          translationKey={t("owners.ownerDistributions.viewTransactionsReport")}
          onClick={handleViewTransactions}
        />
      </View>
      <SuccessDialogActions
        disableMobileCollapse
        accountPrintChecks={isMobile ? undefined : accountPrintChecks}
        isEpay={!isMobile && paymentMethod === PaymentMethod.EPAY}
      />
    </View>
  );
};
