import type { FC } from "react";
import React, { useMemo } from "react";
import MobileNotSupportedView from "DLUI/restrictedAccess/mobileNotSupportedView";
import type { ScreenSize } from "../../../contexts/utils";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";

interface Props {
  excludedScreenSizes?: ScreenSize[];
  children: React.ReactNode;
  displayView?: JSX.Element;
}

const RestrictedScreenAccess: FC<Props> = ({ excludedScreenSizes, children, displayView }: Props) => {
  const { size } = useResponsiveHelper();
  const { hasAccess } = useMemo(() => {
    let hasAccess = true;
    excludedScreenSizes?.forEach((screenSize) => {
      if (screenSize === size) {
        hasAccess = false;
      }
    });
    return { hasAccess };
  }, [size]);

  return hasAccess ? <>{children}</> : displayView || <MobileNotSupportedView />;
};

export default RestrictedScreenAccess;
