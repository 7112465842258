import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import { plaidTransactionsApi } from "api/plaidTransactionsApi";

interface ComponentProps {
  onClose: () => void;
  onBackdropClick: () => void;
}

export const BankDisconnectDialog: React.FC<ComponentProps> = ({ onBackdropClick, onClose }: ComponentProps) => {
  const { t } = useTranslation();
  const { accountId } = useParams<any>();
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");

  useEffect(() => {
    disconnectBankAccount();
  }, []);

  const showErrorMessage = (error: string) => {
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(error);
  };
  const disconnectBankAccount = async () => {
    if (accountId) {
      setLoadingDialogState(DialogState.Show);
      const disconnectPlaidAccountResponse = await plaidTransactionsApi
        .disconnectPlaidAccount(accountId)
        .catch((e) => showErrorMessage(e));
      if (disconnectPlaidAccountResponse && disconnectPlaidAccountResponse.data) {
        onClose();
      } else {
        showErrorMessage(
          disconnectPlaidAccountResponse && disconnectPlaidAccountResponse.message
            ? disconnectPlaidAccountResponse.message
            : t(AppStrings.Common.GeneralError)
        );
      }
    } else {
      showErrorMessage(t(AppStrings.Common.GeneralError));
    }
  };

  const renderView = ({ index }: any) => (
    <LoadingDialog
      dialogState={loadingDialogState}
      errorText={loadingDialogErrorText}
      onRetryButtonPress={disconnectBankAccount}
      didPressDismissButton={onClose}
    />
  );

  return (
    <DialogFrame
      useExperimentalDialogFrame
      onCloseButtonClick={onBackdropClick}
      width={500}
      height={500}
      renderView={renderView}
      numViews={5}
      activeView={0}
      frameType={"contentOnlyNoFrame"}
      hideActionPanel
    />
  );
};
