import type { OwnerPortalGetAllRequestsQuery, OwnerPortalRequestDto } from "@doorloop/dto";
import { OwnerPortalServerRoutes } from "@doorloop/dto";

import { apiHelper } from "api/apiHelper";
import { ownerPortalPropertiesApi } from "api/ownerPortal/ownerPortalPropertiesApi";
import { ownerPortalUnitsApi } from "api/ownerPortal/ownerPortalUnitsApi";
import { RestApiBase } from "api/restApiBase";

export class OwnerPortalRequestsApi extends RestApiBase<OwnerPortalRequestDto, OwnerPortalGetAllRequestsQuery> {
  constructor() {
    super(OwnerPortalServerRoutes.OWNER_REQUESTS, [ownerPortalPropertiesApi, ownerPortalUnitsApi]);
    this.restRoute = OwnerPortalServerRoutes.OWNER_REQUESTS;
  }

  async postUpdate(taskId: string, message: string) {
    return await apiHelper.axiosPost({
      url: OwnerPortalServerRoutes.OWNER_REQUESTS_POST_UPDATE,
      data: {
        taskId,
        message
      }
    });
  }
}

export const ownerPortalRequestsApi = new OwnerPortalRequestsApi();
