import { AccountType } from "@doorloop/dto";
import Grid from "@material-ui/core/Grid";
import { FormikSwitchButton, Select, SwitchButton, TextField } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, getIn } from "formik";
import AppStrings from "locale/keys";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import { isLeaseDepositAccountTypeAllow } from "screens/accounts/chartOfAccounts/newAccount/components/utils";

interface ComponentProps {
  formikRef: FormikProps<any>;
}

const AccountInfo: React.FC<ComponentProps> = ({ formikRef }: ComponentProps) => {
  const { t } = useTranslation();
  const parentAccountDefaultValue = getIn(formikRef.values, "parentAccount") || false;
  const [subAccount, setSubAccount] = useState(parentAccountDefaultValue || false);
  const [renderParentAccount, setRenderParentAccount] = useState<boolean>(true);

  useEffect(() => {
    if (formikRef.values.type !== undefined) {
      setRenderParentAccount(false);
      setTimeout(() => {
        setRenderParentAccount(true);
      }, 0);
    }
  }, [formikRef.values.type]);

  const didChangeSubAccountToggle = () => {
    const nextToggleState = !subAccount;
    if (!nextToggleState) {
      formikRef.setFieldValue("parentAccount", undefined);
    }
    setSubAccount(nextToggleState);
  };

  const handleLeaseOptionsByType = (newFieldValue) => {
    // set leaseDepositItem to false if accountType changed to non liability type
    // because the field is hidden now and should be nullified
    if (!isLeaseDepositAccountTypeAllow(newFieldValue)) {
      formikRef.setFieldValue("leaseDepositItem", false);
    }
  };

  return (
    <View flex={1}>
      <View>
        <View alignItems={"flex-end"} flexDirection={"row"}>
          <Grid item lg={12} md={12} xs={12}>
            <FastField
              component={Select}
              name={`type`}
              label={AppStrings.Common.FilterOptions.Type}
              uniqueKey={"accountType"}
              selectionEnum={AccountType}
              translationKey={"accountType"}
              paddingRight={20}
              onChange={handleLeaseOptionsByType}
              marginTop={20}
              required
            />
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <FastField
              component={TextField}
              label={t(AppStrings.Accounts.ChartOfAccounts.AccountName)}
              name={`name`}
              paddingRight={20}
              marginTop={20}
              required
            />
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <FastField
              component={TextField}
              label={t(AppStrings.Leases.LeaseCharge.Description)}
              name={`description`}
              paddingRight={20}
              marginTop={20}
            />
          </Grid>
        </View>
        <SeparationLine marginTop={20} height={1} width={"100%"} />
        <FastField
          component={FormikSwitchButton}
          marginTop={20}
          label={AppStrings.Accounts.ChartOfAccounts.ActiveAccount}
          name={"active"}
        />
        <SwitchButton
          checked={subAccount}
          label={AppStrings.Accounts.ChartOfAccounts.SubAccountDescription}
          onChange={didChangeSubAccountToggle}
          marginTop={20}
        />

        <View shouldShow={subAccount} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
          {renderParentAccount ? (
            <BankAccountFormikAutoCompleteField
              addCreateOption
              label={t(AppStrings.Accounts.ChartOfAccounts.ParentAccount)}
              uniqueIndex={"parentAccount"}
              name={"parentAccount"}
              marginTop={20}
              paddingRight={20}
              size={"100%"}
              queryParams={{ filter_type: formikRef.values.type }} // TODO: @itay this re-renders here correctly when the accountType changes
              defaultValue={parentAccountDefaultValue}
            />
          ) : null}
        </View>
      </View>
    </View>
  );
};

export default AccountInfo;
