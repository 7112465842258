"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenantPortalRequestDisplayDto = void 0;
const class_validator_1 = require("class-validator");
const tenantPortalRequest_dto_1 = require("./tenantPortalRequest.dto");
const tenantPortalRequestType_enum_1 = require("./tenantPortalRequestType.enum");
class TenantPortalRequestDisplayDto extends tenantPortalRequest_dto_1.TenantPortalRequestDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.TenantPortalRequestDisplayDto = TenantPortalRequestDisplayDto;
__decorate([
    (0, class_validator_1.ValidateIf)((dto) => dto.tenantRequestType === tenantPortalRequestType_enum_1.TenantRequestType.MAINTENANCE),
    (0, class_validator_1.IsNotEmpty)({ message: "If maintenance is chosen as the request type, a file, photo, or reference must be included" }),
    __metadata("design:type", Boolean)
], TenantPortalRequestDisplayDto.prototype, "hasFiles", void 0);
