import React, { useContext } from "react";
import type { SubscribedActions } from "hooks/useListBulkActionsManager";
import noop from "lodash/noop";

export interface ListBulkActionsContextInterface {
  enabled: boolean;
  toggleItem: (id: string, on?: boolean) => void;
  subscribe: (actions: SubscribedActions) => void;
  isItemChecked: (id: string) => boolean;
}

export const ListBulkActionsContext = React.createContext<ListBulkActionsContextInterface>({
  enabled: false,
  toggleItem: noop,
  subscribe: noop,
  isItemChecked: () => false
});

export const useListBulkActionsContext = () => useContext(ListBulkActionsContext);
