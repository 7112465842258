import React, { useState } from "react";

import type {
  PaymentMethod,
  StripeElementsOptions,
  StripeError,
  StripeExpressCheckoutElementReadyEvent
} from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AddressElement, Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";

import CreditCardBottomForm from "./creditCardBottomForm";
import { EPayPaymentMethod, EPayReceivableInfoDto, mathUtils } from "@doorloop/dto";
import type { CreditCardFormProps } from "./payment.types";
import { ExpressCheckout } from "@/components/common/stripe/expressCheckout";
import { View } from "@/components/DLUI/view";
import { SeparationLine } from "@/components/DLUI/separatorView";
import Text from "@/components/DLUI/text";
import { useTypedTranslation } from "@/locale";

const DEFAULT_CURRENCY = "usd";

interface CreditCardFormStripeFormProps extends CreditCardFormProps {
  currency?: string;
  tosLink?: string;
}

const CreditCardFormStripeForm: React.FC<CreditCardFormStripeFormProps> = ({
  currency,
  applicationFee,
  onPaymentCompleted,
  PaymentSummaryDetailsComponent,
  tosLink,
  showTitle
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isStripeCardLoaded, setIsStripeCardLoaded] = React.useState<boolean>(false);
  const [isStripeAddressLoaded, setIsStripeAddressLoaded] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [showErrorShakeEffect, setShowErrorShakeEffect] = useState(false);
  const [areGooglePayOrApplePayAvailable, setAreGooglePayOrApplePayAvailable] = useState(false);

  const { t } = useTypedTranslation();

  const isStripeLoaded = isStripeCardLoaded && isStripeAddressLoaded;

  const runShakeEffect = (): void => {
    setShowErrorShakeEffect(true);

    setTimeout(() => {
      setShowErrorShakeEffect(false);
    }, 300);
  };

  const handleStripeError = (error: StripeError): void => {
    setIsLoading(false);
    setErrorMessage(error.message || "");
    runShakeEffect();
  };

  const handleSubmit = async (event?: React.FormEvent): Promise<void> => {
    event?.preventDefault();

    if (isLoading || !isStripeLoaded || !stripe || !elements) {
      return;
    }

    setIsLoading(true);

    // Trigger form validation and wallet collection.
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleStripeError(submitError);
      return;
    }

    // Create the PaymentMethod using the details collected by the Payment Element.
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      elements
    });

    if (error) {
      /**
       * This point is only reached if there's an immediate error when,
       * creating the PaymentMethod. Show the error to your customer (for example, payment details incomplete).
       */
      handleStripeError(error);
      return;
    }

    handlePaymentMethod(paymentMethod);

    setIsLoading(false);
    setErrorMessage(null);
  };

  const handlePaymentMethod = (paymentMethod: PaymentMethod): void => {
    const { id, card } = paymentMethod;

    if (!id || !card) {
      return;
    }

    const cardInfo = new EPayReceivableInfoDto();

    cardInfo.token = id;
    cardInfo.method = EPayPaymentMethod.CARD;
    cardInfo.number = String(card.last4);
    cardInfo.expiration = `${String(card.exp_month).padStart(2, "0")}${String(card.exp_year).slice(-2)}`;
    cardInfo.savePaymentInfo = false;

    onPaymentCompleted(cardInfo);
  };

  const handleExpressCheckoutReady = ({ availablePaymentMethods }: StripeExpressCheckoutElementReadyEvent) => {
    if (availablePaymentMethods?.googlePay || availablePaymentMethods?.applePay) {
      setAreGooglePayOrApplePayAvailable(true);
    }
  };

  return (
    <form style={{ width: "100%", marginTop: "20px" }} onSubmit={handleSubmit}>
      <ExpressCheckout handleConfirm={async () => await handleSubmit()} handleOnReady={handleExpressCheckoutReady} />
      {areGooglePayOrApplePayAvailable && (
        <View
          flexDirection={"row"}
          noWrap
          gap={30}
          alignItems={"center"}
          justifyContent={"center"}
          marginBottom={20}
          marginTop={20}
        >
          <SeparationLine height={2} style={{ flex: 1 }} />
          <Text value={t("common.orPayUsingCreditCard")} color={"secondary-gray"} fontSize={14} bold />
          <SeparationLine height={2} style={{ flex: 1 }} />
        </View>
      )}
      <AddressElement
        options={{ mode: "billing", fields: { phone: "always" }, validation: { phone: { required: "always" } } }}
        onReady={() => setIsStripeAddressLoaded(true)}
      />
      <PaymentElement onReady={() => setIsStripeCardLoaded(true)} />

      <CreditCardBottomForm
        currency={currency}
        totalAmount={applicationFee}
        showErrorShakeEffect={showErrorShakeEffect}
        isLoading={!stripe || isLoading}
        errorText={errorMessage}
        isButtonDisabled={!isStripeLoaded}
        PaymentSummaryDetailsComponent={PaymentSummaryDetailsComponent}
        tosLink={tosLink}
        showTitle={showTitle}
      />
    </form>
  );
};

const CreditCardFormStripe = ({
  merchantAccountData,
  applicationFee,
  onPaymentCompleted,
  stripePaymentKey,
  PaymentSummaryDetailsComponent,
  tosLink,
  showTitle
}: CreditCardFormProps) => {
  const stripePublishableKey = merchantAccountData?.publicKey || stripePaymentKey || "";

  const stripePromise = loadStripe(stripePublishableKey);

  const stripeOptions: StripeElementsOptions = {
    mode: "payment",
    amount: mathUtils.multiply(applicationFee || 0, 100), // Stripe amount is in cents instead of dollars.
    currency: merchantAccountData?.currency || DEFAULT_CURRENCY,
    paymentMethodCreation: "manual",
    appearance: {},
    paymentMethodTypes: ["card"]
  };

  return (
    <Elements stripe={stripePromise} options={stripeOptions}>
      <CreditCardFormStripeForm
        currency={stripeOptions.currency}
        applicationFee={applicationFee}
        onPaymentCompleted={onPaymentCompleted}
        PaymentSummaryDetailsComponent={PaymentSummaryDetailsComponent}
        tosLink={tosLink}
        showTitle={showTitle}
      />
    </Elements>
  );
};

export default CreditCardFormStripe;
