import React, { useState } from "react";
import { View } from "DLUI/view";
import type { TextFormatType } from "DLUI/text/text";
import Text from "DLUI/text/text";
import { TextTooltip } from "DLUI/popover/textTooltip";
import { Icon } from "DLUI/icon";
import { Link } from "DLUI/link";
import type { ScreenWidgets, ApiResult, ScreenWidgetDto } from "@doorloop/dto";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import type { SVGIconComponent } from "../../../assets";
import { LoadingAnimation } from "DLUI/animations/loadingAnimation";
import { DataCy } from "@doorloop/dto";
import { useQuery } from "@tanstack/react-query";
import { RefreshIcon } from "../../../assets";

interface TextAction {
  text: string;
  onClick?: (widgetType: ScreenWidgets) => void;
}

interface SubtitleProps {
  value?: string;
  label?: string;
  formatType?: TextFormatType;
  showDecimalPoint?: boolean;
}

interface TooltipProps {
  text: string;
}

export interface ScreenWidgetProps {
  type: ScreenWidgets;
  title: string;
  IconSource?: SVGIconComponent;
  subtitle?: SubtitleProps;
  link: TextAction;
  tooltip?: TooltipProps;
  restApi: {
    getWidgets?: (widgetType: ScreenWidgets) => Promise<ApiResult<ScreenWidgetDto>>;
  };
}

export const SCREEN_WIDGET_KEY = "screen-widget";

export const ScreenWidget = ({ type, title, IconSource, subtitle, link, tooltip, restApi }: ScreenWidgetProps) => {
  const [isHover, setIsHover] = useState(false);
  const { isMobile } = useResponsiveHelper();

  const {
    data: widgetValue,
    isFetching,
    isError,
    refetch
  } = useQuery({
    queryKey: [SCREEN_WIDGET_KEY, restApi, type],
    queryFn: async ({ signal }) => {
      try {
        if (!restApi) return null;
        const result = await restApi?.getWidgets?.(type);
        if (!result || signal?.aborted) {
          return null;
        }
        return result.data?.value;
      } catch (e: any) {
        console.log({ e: e.message });
      }
    },
    refetchOnWindowFocus: false
  });

  if (widgetValue === 0) {
    return null;
  }

  const handleChangeTooltipState = (hoverStatus: boolean) => {
    setIsHover(hoverStatus);
  };
  const hoverStyling = isHover ? { boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.15)" } : {};

  return (
    <TextTooltip
      maxWidth={220}
      textColor={"gray"}
      value={tooltip?.text}
      show={Boolean(tooltip?.text && !isMobile)}
      useButton={false}
      placement={"top"}
    >
      <View
        minWidth={170}
        autoWidth={!isMobile}
        backgroundColor={"white"}
        paddingTop={16}
        paddingRight={16}
        paddingBottom={16}
        paddingLeft={16}
        gap={isMobile ? 24 : 16}
        marginBottom={16}
        borderRadius={4}
        onMouseEnter={() => handleChangeTooltipState(true)}
        onMouseLeave={() => handleChangeTooltipState(false)}
        style={hoverStyling}
      >
        <View flexDirection={"row"} gap={isMobile ? 4 : 3} alignItems={"center"}>
          {IconSource && <Icon size={14} Source={IconSource} pathColor={"black"} />}
          <Text value={title} fontSize={isMobile ? 14 : 12} bold />
        </View>
        <View flexDirection={"row"} justifyContent={"space-between"} alignItems={"flex-end"} gap={30}>
          <View autoWidth flexDirection={"row"} alignItems={"flex-end"} gap={4}>
            {isFetching || isError ? (
              <View autoWidth marginRight={4} onClick={isError ? async () => await refetch() : undefined}>
                {isError ? (
                  <Icon Source={RefreshIcon} size={20} />
                ) : (
                  <LoadingAnimation width={24} height={24} type={"spinner"} />
                )}
              </View>
            ) : (
              <Text
                value={widgetValue ? String(widgetValue) : ""}
                fontSize={20}
                bold
                lineHeight={"22px"}
                formatType={subtitle?.formatType}
              />
            )}
            <Text value={subtitle?.label} fontSize={12} bold lineHeight={"14px"} color={"gray"} />
          </View>
          <View autoWidth noWrap>
            <Link onClick={() => link?.onClick?.(type)} dataCy={DataCy.DLUI.screenWidgets.link}>
              <Text value={link.text} bold fontSize={isMobile ? 16 : 12} lineHeight={"14px"} underLine={"solid"} />
            </Link>
          </View>
        </View>
      </View>
    </TextTooltip>
  );
};
