import AppStrings from "locale/keys";
import { DialogRoutes } from "./dialogsRoutes";
import type { DialogProps } from "./dialogsProvider.types";

class UsersDialogsProvider {
  matchRoutes(pathname: string): DialogProps | undefined {
    if (
      pathname.indexOf(DialogRoutes.EDIT_USER) > -1 ||
      pathname.indexOf(DialogRoutes.WORK_ORDERS_REPORT_EDIT_USER) > -1
    ) {
      return {
        type: "newUser",
        title: AppStrings.Users.NewUser.EditUser
      };
    }
    if (pathname.indexOf(DialogRoutes.NEW_USER) > -1) {
      return {
        type: "newUser",
        title: AppStrings.Users.Screen.AddNewUser
      };
    }

    return undefined;
  }
}

export const usersDialogsProvider = new UsersDialogsProvider();
