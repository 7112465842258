import { createStyles, makeStyles } from "@material-ui/core";

interface StylesProps {
  count: number;
}

const sideNotificationBubble = (props: StylesProps) => {
  if (!props.count) {
    return "unset";
  }

  if (props.count < 10) {
    return 21;
  }
  if (props.count <= 99) {
    return 19;
  }
  return 13;
};

export const communicationsMenuItemCustomStyles = makeStyles(() =>
  createStyles({
    notificationBubble: {
      left: sideNotificationBubble
    }
  })
);
