import { CompanyInfoSettingsDto, createValidator } from "@doorloop/dto";
import type { FormikProps } from "formik";
import _ from "lodash";
import type { AnimatedContentDialogValidationProps } from "screens/settings/personalInformation/formikHelper";

export const initFormValues = (settingsData: CompanyInfoSettingsDto): CompanyInfoSettingsDto =>
  new CompanyInfoSettingsDto(settingsData);

export const validateForm = createValidator(CompanyInfoSettingsDto);

export const validateCompanyInfoForm = async ({
  formikRef
}: {
  formikRef: FormikProps<CompanyInfoSettingsDto>;
}): Promise<AnimatedContentDialogValidationProps> => {
  formikRef.setFieldTouched("billingEmail");
  formikRef.setFieldTouched("companyName");
  formikRef.setFieldTouched("companyPhone");
  formikRef.setFieldTouched("subdomain");
  formikRef.setFieldTouched("owner");
  formikRef.setFieldTouched("billingAddress.street1");
  formikRef.setFieldTouched("billingAddress.city");
  formikRef.setFieldTouched("billingAddress.zip");
  formikRef.setFieldTouched("billingAddress.country");

  // validate the form
  const errors = (await formikRef.validateForm()) as any;
  if (_.isEmpty(errors)) {
    return { isValid: true };
  }
  let errorStepIndex = 0;
  if (errors.subdomain) {
    errorStepIndex = 1;
  }
  if (errors.billingAddress) {
    errorStepIndex = 0;
  }
  return { isValid: false, errorStepIndex };
};
