import type { VisibleGridColumn } from "DLUI/lists/gridList/gridList";
import Text from "DLUI/text";
import React from "react";
import { getColumnTotals } from "../utils";

const renderGridFooterCell = (
  props: any,
  currentColumn: VisibleGridColumn,
  data: any[],
  index?: number,
  dataTotalLine?: Record<string, number>
) => {
  const fieldName = props.field || currentColumn.field;

  if (dataTotalLine && dataTotalLine[fieldName]) {
    return (
      <td className={"summaryLine"} key={index + currentColumn.title}>
        <Text
          formatType={currentColumn.footerCell?.formatType || "currency"}
          fontSize={14}
          fontWeight={700}
          value={String(dataTotalLine[fieldName])}
          color={"black"}
        />
      </td>
    );
  }

  if (currentColumn.footerCell) {
    if (currentColumn.footerCell.text) {
      return (
        <td key={index + currentColumn.title}>
          <Text fontSize={14} fontWeight={700} value={currentColumn.footerCell.text} color={"black"} />
        </td>
      );
    }

    if (currentColumn.footerCell.sum) {
      const totals = getColumnTotals(data, fieldName, currentColumn.showZeroValues);

      return (
        <td key={index + currentColumn.title}>
          <Text
            formatType={currentColumn.footerCell.formatType || "currency"}
            fontSize={14}
            fontWeight={700}
            value={String(totals.sum)}
            color={"black"}
          />
        </td>
      );
    }

    if (currentColumn.footerCell.average) {
      const totals = getColumnTotals(data, fieldName, currentColumn.showZeroValues);

      return (
        <td key={index}>
          <Text
            formatType={currentColumn.footerCell.formatType || "currency"}
            fontSize={14}
            fontWeight={700}
            value={String(totals.average)}
            color={"black"}
          />
        </td>
      );
    }
  }

  return <td key={index} />;
};

export const renderGridFooter = (
  props: any,
  currentColumn: VisibleGridColumn,
  data: any[],
  dataTotalLine?: Record<string, number>
) => {
  let cells: JSX.Element | JSX.Element[];
  if (currentColumn.subColumns) {
    cells = currentColumn.subColumns.map((column, index) =>
      renderGridFooterCell(props, column, data, index, dataTotalLine)
    );
  } else {
    cells = renderGridFooterCell(props, currentColumn, data, 1, dataTotalLine);
  }

  return <>{cells}</>;
};
