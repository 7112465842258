import { RadioGroup, TextField } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, getIn } from "formik";
import AppStrings from "locale/keys";
import _, { isEmpty } from "lodash";
import type { PropsWithChildren } from "react";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

import { useResponsiveHelper } from "@/contexts/responsiveContext";
import { useTypedTranslation } from "locale";
import { PreviewForDesktop } from "DLUI/preview/previewForDesktop";
import { PreviewForMobile } from "DLUI/preview/previewForMobile";
import { UploadLogo } from "DLUI/button/uploadButtons/uploadLogo";
import { UploadImage } from "DLUI/button/uploadButtons/uploadImage";
import { ColorPickerButton } from "screens/settings/tenantPortal/components/colorPickerButton";
import ColorsEnum from "utils/colorsEnum";
import clsx from "clsx";
import { getFormikPortalSettingsProperty } from "screens/settings/tenantPortal/formikHelper";
import type { Theme } from "styles/defaultTheme";
import { settingsApi } from "@/api/settingsApi";
import type { ApiResult, TenantPortalSettingsDto } from "@doorloop/dto";

const useStyles = makeStyles((theme: Theme) => {
  return {
    settingsBlock: {
      boxSizing: "border-box",
      backgroundColor: "white",
      borderRadius: 8,
      border: "1px solid rgba(24, 44, 76, 0.1)",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: window.innerWidth - 30
      }
    },
    columnGap: {
      display: "flex",
      flexDirection: "column",
      gap: 30,
      paddingBottom: 24
    },
    settingsHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      padding: 16
    },
    settingsRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      padding: 16,
      boxSizing: "border-box",
      gap: 24
    },
    urlRow: {
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        alignItems: "start",
        gap: 8,
        paddingBottom: 12,
        flexDirection: "column"
      }
    },
    editUrlText: {
      paddingBottom: "unset",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: 12
      }
    },
    previewRow: {
      display: "flex",
      gap: 16,
      width: "100%",
      justifyContent: "center"
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column"
    },
    settingsColumn: {
      gap: 12
    },
    addressRow: { display: "flex", alignItems: "center", gap: 4 }
  };
});

export const SettingsBlock = ({ children }: PropsWithChildren<{}>) => {
  const classes = useStyles();

  return <div className={classes.settingsBlock}>{children}</div>;
};

interface ThemeSettingsProps {
  onTenantPortalLogoFileReceive?: (receivedFile: File) => void;
  onTenantPortalLogoFileDelete?: () => void;
  onCoverImageFileReceive?: (receivedFile: File) => void;
  onCoverImageFileDelete?: () => void;
  formikRef: FormikProps<any>;
}

const ThemeSettings = ({
  onTenantPortalLogoFileReceive,
  onTenantPortalLogoFileDelete,
  onCoverImageFileReceive,
  onCoverImageFileDelete,
  formikRef
}: ThemeSettingsProps) => {
  const { propertyId } = useParams<any>();
  const { isMobile } = useResponsiveHelper();
  const { t } = useTypedTranslation();
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const classes = useStyles();
  const formikThemeFieldName = getFormikPortalSettingsProperty("theme", propertyId);
  const formikSubdomainFieldName = getFormikPortalSettingsProperty("subdomain", propertyId);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isColorPickerOpen && isMobile) {
      containerRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [isMobile, isColorPickerOpen]);

  const themeSettingsSelectionDefaultValue = useMemo(() => {
    if (propertyId === undefined) {
      return "specifyThemeInfo";
    }
    const fieldValue = getIn(formikRef.values, formikThemeFieldName);
    const emptyObject = _.values(fieldValue).every((x) => x === undefined);
    if (emptyObject) {
      return "companyDefault";
    }
    return "specifyThemeInfo";
  }, []);

  const tenantPortalUrl = getIn(formikRef.values, formikSubdomainFieldName);

  const [themeSettingsSelectionType, setThemeSettingsSelectionType] = useState<"companyDefault" | "specifyThemeInfo">(
    themeSettingsSelectionDefaultValue
  );

  const [renderForm, setRenderForm] = useState<boolean>(true);

  const [themeColor, setThemeColor] = useState<string | undefined>(
    getIn(formikRef.values, formikThemeFieldName + ".color")
  );

  const selectedLogoImageUrl = useMemo(() => {
    const fieldValue = getIn(formikRef.values, formikThemeFieldName);
    if (fieldValue) {
      return fieldValue.logoUrl;
    }
    return undefined;
  }, [formikThemeFieldName, formikRef.values]);

  const selectedCoverImageUrl = useMemo(() => {
    const fieldValue = getIn(formikRef.values, formikThemeFieldName);
    if (fieldValue) {
      return fieldValue.backgroundUrl;
    }
    return undefined;
  }, [formikThemeFieldName, formikRef.values]);

  const _onTenantPortalLogoFileReceive = (receivedFile: File) => {
    if (onTenantPortalLogoFileReceive) {
      onTenantPortalLogoFileReceive(receivedFile);
    }
  };

  const _onTenantPortalLogoFileDelete = () => {
    if (onTenantPortalLogoFileDelete) {
      onTenantPortalLogoFileDelete();
    }
  };

  const _onCoverImageFileReceive = (receivedFile: File) => {
    if (onCoverImageFileReceive) {
      onCoverImageFileReceive(receivedFile);
    }
  };

  const _onCoverImageFileDelete = () => {
    if (onCoverImageFileDelete) {
      onCoverImageFileDelete();
    }
  };

  const onThemeColorChange = (selectedColor: string) => {
    formikRef.setFieldValue(formikThemeFieldName + ".color", selectedColor);
    setThemeColor(selectedColor);
  };

  const refreshForm = () => {
    setRenderForm(false);
    formikRef.setFieldValue(formikThemeFieldName, undefined);
    setTimeout(() => {
      setRenderForm(true);
    }, 0);
  };

  function isPropertyHasSettings() {
    const propertyThemeSettings = getIn(formikRef.values, formikThemeFieldName);
    const propertySubdomain = getIn(formikRef.values, formikSubdomainFieldName);
    const isPropertyThemeSettingsEmpty =
      isEmpty(propertyThemeSettings) || _.values(propertyThemeSettings).every((x) => x === undefined);

    return !isPropertyThemeSettingsEmpty || propertySubdomain;
  }

  async function loadCompanyDefaultsForProperty() {
    const { data }: ApiResult<TenantPortalSettingsDto> = await settingsApi.getTenantPortalSettings();
    if (!data) return;

    const { theme, subdomain } = data;

    formikRef.setFieldValue(`${formikThemeFieldName}.backgroundUrl`, theme?.backgroundUrl);
    formikRef.setFieldValue(`${formikThemeFieldName}.logoUrl`, theme?.logoUrl);
    onThemeColorChange(theme?.color!);
    formikRef.setFieldValue(formikSubdomainFieldName, subdomain);
  }

  const didChangeSpecifyThemeSelection = async (selectedValue: string) => {
    if (selectedValue === "false") {
      setThemeSettingsSelectionType("companyDefault");

      setTimeout(() => {
        refreshForm();
      }, 500);
    } else {
      setThemeSettingsSelectionType("specifyThemeInfo");
      const isPropertyWithoutSettings = propertyId && !isPropertyHasSettings();
      if (isPropertyWithoutSettings) {
        await loadCompanyDefaultsForProperty();
      }
    }
  };
  const renderSpecifyRadioButtons = () => {
    if (propertyId) {
      return (
        <Fragment>
          <RadioGroup
            didChangeSelection={didChangeSpecifyThemeSelection}
            uniqueKey={"TSR"}
            defaultValue={themeSettingsSelectionType === "companyDefault" ? "false" : "true"}
            marginTop={20}
            radioGroupItems={[
              {
                label: AppStrings.Common.HideThemeForProperty,
                value: "false"
              },
              {
                label: AppStrings.Common.SpecifyThemeForProperty,
                value: "true"
              }
            ]}
          />
          <SeparationLine marginTop={20} marginBottom={20} width={"100%"} height={1} />
        </Fragment>
      );
    }
  };

  return (
    <View flex={1}>
      <View domRef={containerRef} paddingBottom={isColorPickerOpen && isMobile ? 200 : 0}>
        <Text value={t("common.tenantPortalThemeSettingsDescription")} color={"black"} fontSize={16} />
        {renderSpecifyRadioButtons()}
        {!propertyId && <SeparationLine width="100%" height={1} marginBottom={16} marginTop={16} />}
        <View
          shouldShow={themeSettingsSelectionType === "specifyThemeInfo"}
          showAnimation={"fade-in"}
          hideAnimation={"fade-out"}
        >
          {renderForm ? (
            <div className={classes.columnGap}>
              <SettingsBlock>
                <div className={classes.settingsHeader}>
                  <Text
                    value={t("common.tenantPortalSettingsSections.customization.title")}
                    fontSize={16}
                    fontWeight={700}
                  />
                </div>
                <SeparationLine width={"100%"} height={1} />
                <div className={classes.settingsRow}>
                  <div className={clsx(classes.settingsColumn, classes.flexColumn)}>
                    <Text
                      value={t("common.tenantPortalSettingsSections.customization.logo.upload")}
                      fontSize={14}
                      fontWeight={700}
                    />
                    <div className={clsx(classes.flexColumn)}>
                      <Text
                        value={t("common.tenantPortalSettingsSections.customization.logo.size")}
                        fontSize={14}
                        fontWeight={400}
                        colorEnum={ColorsEnum.SecondaryGrey}
                      />
                      {!isMobile && (
                        <Text
                          value={t("common.tenantPortalSettingsSections.customization.logo.fallback")}
                          fontSize={14}
                          fontWeight={400}
                          colorEnum={ColorsEnum.SecondaryGrey}
                        />
                      )}
                    </div>
                  </div>
                  <UploadLogo
                    onContentReceive={(files: File[]) => _onTenantPortalLogoFileReceive(files[0])}
                    currentImageUrl={selectedLogoImageUrl}
                    onRemoveContent={_onTenantPortalLogoFileDelete}
                  />
                </div>
                <SeparationLine width={"100%"} height={1} />
                <div className={classes.settingsRow}>
                  <div className={classes.settingsColumn}>
                    <Text
                      value={t("common.tenantPortalSettingsSections.customization.background.upload")}
                      fontSize={14}
                      fontWeight={700}
                    />
                    <Text
                      value={t("common.tenantPortalSettingsSections.customization.background.size")}
                      fontSize={14}
                      fontWeight={400}
                      colorEnum={ColorsEnum.SecondaryGrey}
                    />
                  </div>
                  <UploadImage
                    onContentReceive={(files: File[]) => _onCoverImageFileReceive(files[0])}
                    currentImageUrl={selectedCoverImageUrl}
                    onRemoveContent={_onCoverImageFileDelete}
                  />
                </div>
                <SeparationLine width={"100%"} height={1} />
                <div className={classes.settingsRow}>
                  <Text
                    value={t("common.tenantPortalSettingsSections.customization.color.choose")}
                    fontSize={14}
                    fontWeight={700}
                  />
                  <ColorPickerButton
                    themeColor={themeColor!}
                    onThemeColorChange={onThemeColorChange}
                    onOpenChanged={setIsColorPickerOpen}
                  ></ColorPickerButton>
                </div>
                <SeparationLine width={"100%"} height={1} />
                <div className={clsx(classes.settingsRow, classes.urlRow)}>
                  <Text
                    value={t("common.tenantPortalSettingsSections.customization.url.edit")}
                    fontSize={14}
                    fontWeight={700}
                    paddingTop={isMobile ? 12 : 0}
                    className={classes.editUrlText}
                  />
                  <div className={classes.addressRow}>
                    <FastField
                      component={TextField}
                      name={formikSubdomainFieldName}
                      width={isMobile ? 150 : 200}
                      autoWidth
                    />
                    <Text fontSize={14} colorEnum={ColorsEnum.SecondaryGrey}>
                      {t("common.tenantPortalSettingsSections.customization.url.suffix")}
                    </Text>
                  </div>
                </div>
              </SettingsBlock>
              <SettingsBlock>
                <div className={classes.settingsHeader}>
                  <Text
                    value={t("common.tenantPortalSettingsSections.customization.preview")}
                    fontSize={16}
                    fontWeight={700}
                  />
                </div>
                <SeparationLine width={"100%"} height={1} />
                <div className={classes.settingsRow}>
                  <div className={classes.previewRow}>
                    {!isMobile && (
                      <PreviewForDesktop
                        url={tenantPortalUrl}
                        coverUrl={selectedCoverImageUrl}
                        logoUrl={selectedLogoImageUrl}
                        backgroundColor={themeColor}
                      />
                    )}
                    <PreviewForMobile
                      url={tenantPortalUrl}
                      coverUrl={selectedCoverImageUrl}
                      logoUrl={selectedLogoImageUrl}
                      backgroundColor={themeColor}
                    />
                  </div>
                </div>
              </SettingsBlock>
            </div>
          ) : null}
        </View>
      </View>
    </View>
  );
};

export default ThemeSettings;
