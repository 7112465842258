import type {
  ConversationPushEventDto,
  ConversationsStatsPushEventDto,
  LoginResponseDto,
  PushNotificationEventDto
} from "@doorloop/dto";
import { LoginResponseType, PusherChannelsEvent, ServerRoutes, TenantPortalServerRoutes } from "@doorloop/dto";

import * as PusherPushNotifications from "@pusher/push-notifications-web";
import Pusher from "pusher-js";

import { devicesMethods, MobileDeviceBridgeMessages } from "./mobileDeviceBridgeService";
import { getDeviceType } from "../contexts/utils";
import { isLocalEnv, isProductionEnv } from "utils/environmentHelper";
import { store } from "store/index";
import { updateTotalUnreadNotification } from "store/auth/actions";

const generateTokensServerRoutesByLoginResponseTypeMap = {
  native: {
    [LoginResponseType.USER]: ServerRoutes.NOTIFICATIONS_GENERATE_PUSH_TOKEN,
    [LoginResponseType.TENANT]: TenantPortalServerRoutes.GENERATE_PUSH_TOKEN
  },
  privateChannel: {
    [LoginResponseType.USER]: ServerRoutes.NOTIFICATIONS_GENERATE_CHANNEL_TOKEN,
    [LoginResponseType.TENANT]: TenantPortalServerRoutes.GENERATE_CHANNEL_TOKEN
  }
};

interface PushServiceSubscribeEventsCallbacks {
  [PusherChannelsEvent.PUSH_NOTIFICATION_EVENT]: (dto: PushNotificationEventDto) => void;
  [PusherChannelsEvent.PUSH_CONVERSATION_EVENT]: (dto: ConversationPushEventDto) => void;
  [PusherChannelsEvent.PUSH_CONVERSATIONS_STATS_EVENT]: (dto: ConversationsStatsPushEventDto) => void;
}

class PushService {
  nativePushClient: PusherPushNotifications.Client;
  privateChannel: Pusher;
  isNativePushInitialized = false;

  private privateChannelName?: string;
  private PrivateChannelsSubscriptionStatusMap: Record<string, boolean> = {};
  private inProgressOpeningPrivateChannel = false;

  constructor() {
    if (!isLocalEnv) {
      try {
        const instanceId = isProductionEnv
          ? process.env.REACT_APP_PUSHER_BEAMS_INSTANCE_ID_PROD
          : process.env.REACT_APP_PUSHER_BEAMS_INSTANCE_ID_DEV;

        if (!instanceId) {
          console.error("We failed to initialize the Native Push: instanceId is not defined");
          return;
        }

        this.nativePushClient = new PusherPushNotifications.Client({
          instanceId
        });
        this.isNativePushInitialized = true;
      } catch (err: unknown) {
        this.isNativePushInitialized = false;
        console.error("We failed to initialize the Native Push: ", err);
      }
    }
  }

  // CHANNEL/IN-SITES PUSH HANDLERS

  async openChannel(userId: string, loginResponseType: LoginResponseType): Promise<boolean> {
    if (
      this.inProgressOpeningPrivateChannel ||
      (this.privateChannelName && this.PrivateChannelsSubscriptionStatusMap[this.privateChannelName])
    ) {
      return true;
    }

    this.inProgressOpeningPrivateChannel = true;
    const endpoint = generateTokensServerRoutesByLoginResponseTypeMap.privateChannel[loginResponseType];

    const appKey = isProductionEnv
      ? process.env.REACT_APP_PUSHER_CHANNEL_APP_KEY_PROD
      : process.env.REACT_APP_PUSHER_CHANNEL_APP_KEY_DEV;

    if (!appKey) {
      return false;
    }

    try {
      this.privateChannelName = `private-${userId}`;
      this.privateChannel = new Pusher(appKey, {
        channelAuthorization: {
          endpoint,
          transport: "ajax",
          headers: {
            tokenid: userId
          }
        }
      });

      return false;
    } catch (err) {
      this.inProgressOpeningPrivateChannel = false;
      this.privateChannelName = undefined;

      return false;
    }
  }

  async subscribePrivateChannel<TEvent extends PusherChannelsEvent>(
    eventName: TEvent,
    callback: PushServiceSubscribeEventsCallbacks[TEvent]
  ): Promise<void> {
    if (!eventName) {
      throw new Error(`Event name not passed`);
    }

    if (!this.privateChannelName) {
      throw new Error(`no channel for subscription`);
    }

    try {
      const channel = this.privateChannel.subscribe(this.privateChannelName);

      channel.bind(eventName, callback);

      channel.bind("pusher:subscription_succeeded", () => {
        this.privateChannelName && (this.PrivateChannelsSubscriptionStatusMap[this.privateChannelName] = true);
        this.inProgressOpeningPrivateChannel = false;
      });
    } catch (err) {
      console.error(err);
    }
  }

  async stopPrivateChannelPush(): Promise<void> {
    this.privateChannelName && this.privateChannel.unsubscribe(`private-${this.privateChannelName}`);

    this.privateChannel?.disconnect();

    this.privateChannelName = undefined;
  }

  async startNativePush(
    userId: string,
    loginResponseType: LoginResponseType,
    pusherToken: string | undefined
  ): Promise<void> {
    if (!this.isNativePushInitialized) {
      this.logUninitializedError();

      return;
    }

    await this.startServiceWorker(userId, loginResponseType, pusherToken);
  }

  // NATIVE PUSH HANDLERS

  async stopNativePush(): Promise<void> {
    await this.nativePushClient?.clearAllState();
    await this.nativePushClient?.stop();
  }

  async stop(): Promise<void> {
    this.unregisterDeviceForNotifications();

    await this.stopNativePush();
    await this.stopPrivateChannelPush();
  }

  registerDeviceForNotifications = (userId: string, token: string | undefined): void => {
    try {
      const deviceType = getDeviceType();
      const authURL = `${location.origin}/api/${
        location.pathname.startsWith("tenant-portal") ? "tenant-portal/" : ""
      }notifications/generate-push-token`;

      devicesMethods?.[MobileDeviceBridgeMessages.Register]?.[deviceType]?.({ authURL, userId, token });
    } catch (err) {
      console.log(err);
    }
  };

  // GENERAL HANDLERS

  unregisterDeviceForNotifications = (): void => {
    const deviceType = getDeviceType();

    try {
      devicesMethods?.[MobileDeviceBridgeMessages.Unregister]?.[deviceType]?.();
    } catch (err) {
      console.log(err);
    }
  };

  protected async startServiceWorker(
    userId: string,
    loginResponseType: LoginResponseType,
    pusherToken: string | undefined
  ): Promise<void> {
    await this.stop();
    const endpoint = generateTokensServerRoutesByLoginResponseTypeMap.native[loginResponseType];

    try {
      const accessTokenEndpoint = new URL(endpoint, location.origin).toString();
      const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
        url: accessTokenEndpoint,
        queryParams: {
          token: pusherToken
        }
      });

      await this.nativePushClient.start();
      await this.nativePushClient.setUserId(userId, beamsTokenProvider);
    } catch (err) {
      console.log(`Native Beams failed to connect: ${err}`);
    }
  }

  private logUninitializedError(): void {
    console.warn("Pusher Beams client blocked.");
  }

  private updateTotalUnreadNotificationDispatcher = ({ unreadNotificationCount }): void => {
    store.dispatch(updateTotalUnreadNotification(unreadNotificationCount));
  };

  async handlePushNotificationSubscription(loginResponse: LoginResponseDto): Promise<boolean> {
    try {
      await this.startNativePush(loginResponse.id, loginResponse.type, loginResponse.pusherToken);
    } catch (error) {
      console.error("Failed startNativePush", error);
    }

    this.registerDeviceForNotifications(loginResponse.id, loginResponse.pusherToken);

    const isOpenedChannelAlready = await this.openChannel(loginResponse.id, loginResponse.type);

    if (!isOpenedChannelAlready) {
      await pushService.subscribePrivateChannel(
        PusherChannelsEvent.PUSH_NOTIFICATION_EVENT,
        this.updateTotalUnreadNotificationDispatcher
      );
    }

    return isOpenedChannelAlready;
  }
}

export const pushService = new PushService();
