import _ from "lodash";

import type { ProjectFeatureIds } from "./features";

const debuggingDbTenants = [
  "606ee13bfbac6a11677090ff", // DEV: itay+dev@doorloop.com
  "651d09f9a3895d22c843074a", // DEV: nrosner@doorlop.com
  "632091c05e71360e4eb29921", // DEV: Nolan
  "6063ea5be3f71757f0130144", // nhofstee+ori@doorloop.com
  "63a20efc0f2e152a3a3df1ab", // nhofstee+testacc@doorloop.com
  "6063ea5be3f71757f0130144", // ori+livepayrix@doorloop.com
  "65f6fb0e29a0484303f3ef58", // nrosner+cashpay@doorloop.com
  "64535e8f25764d17b524a27f", // ella@doorloop.com
  "63eb5625af97b517558e8826", // gal+2@doorloop.com
  "65a82ced5be47a02462ea4f2" // Nolan - production
];

const FeatureFlagsForSpecificDbTenants: Partial<Record<ProjectFeatureIds, string[]>> = {};

export const getDbTenantsByFeatureFlag = (featureId: ProjectFeatureIds) => {
  const featureFlagDbTenantsList = FeatureFlagsForSpecificDbTenants[featureId];

  if (!featureFlagDbTenantsList) {
    return undefined;
  }

  return _.concat(featureFlagDbTenantsList, debuggingDbTenants);
};
