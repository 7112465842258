import type { GetAllBaseQueryResponse, LinkedResourceDto } from "@doorloop/dto";
import { DEFAULT_PAGE_SIZE, FileDto } from "@doorloop/dto";
import type { ApiToastsProps } from "./apiHelper";
import { apiHelper } from "./apiHelper";
import type { ApiResult } from "./apiResult";
import type { RestApiBaseWithDictionary } from "./restApiBaseWithDictionary";

export abstract class RestApiBase<DtoType, GetAllDtoType, GetAllDtoTypeResponse = GetAllBaseQueryResponse<DtoType>> {
  restRoute = "";
  dictionariesRequiredForGet: Array<RestApiBaseWithDictionary<any, any>> = [];

  /**
   * Creates an instance of rest api base.
   * @param restRoute
   * @param [dictionariesRequiredForGet] If the get and getAll calls of this client API, rely on dictionaries from other APIs to be fetched from the server, pass them as an array here.
   */
  constructor(restRoute: string, dictionariesRequiredForGet?: Array<RestApiBaseWithDictionary<any, any>>) {
    this.restRoute = restRoute;
    if (dictionariesRequiredForGet) {
      this.dictionariesRequiredForGet = dictionariesRequiredForGet;
    }
  }

  /**
   * Verifies that all the dictionaries needed for get calls are loaded
   * @returns
   */
  async loadDictionariesRequiredForGet() {
    return await apiHelper.loadDictionariesRequiredForGet(this.dictionariesRequiredForGet);
  }

  async get(id: string): Promise<ApiResult<DtoType>> {
    await this.loadDictionariesRequiredForGet();
    return await apiHelper.axiosGet<DtoType>(this.restRoute + "/" + id);
  }

  async update(id: string, data: DtoType, toasts?: ApiToastsProps<DtoType>): Promise<ApiResult<DtoType>> {
    return await apiHelper.axiosPut<DtoType>({ url: this.restRoute + "/" + id, data, toasts });
  }

  async create(data: DtoType, toasts?: ApiToastsProps<DtoType>): Promise<ApiResult<DtoType>> {
    return await apiHelper.axiosPost<DtoType>({ url: this.restRoute + "/", data, toasts });
  }

  async delete(id: string, toasts?: ApiToastsProps<DtoType>): Promise<ApiResult<DtoType>> {
    return await apiHelper.axiosDelete<DtoType>({ url: this.restRoute + "/" + id, toasts });
  }

  async duplicate(id: string, toasts?: ApiToastsProps<DtoType>): Promise<ApiResult<DtoType>> {
    return await apiHelper.axiosPost<DtoType>({
      url: `${this.restRoute}/duplicate/${id}`,
      toasts
    });
  }

  async getAll(query: GetAllDtoType): Promise<ApiResult<GetAllDtoTypeResponse>> {
    await this.loadDictionariesRequiredForGet();
    return await apiHelper.axiosGet<GetAllDtoTypeResponse>(this.restRoute + "/?", {
      page_size: DEFAULT_PAGE_SIZE,
      ...query
    });
  }

  async uploadPicture(file: File, linkedResourceDto: LinkedResourceDto, id: string, rank = 1) {
    const data = new FormData();
    data.append("file", file);
    const fileDto = new FileDto({
      name: file.name,
      linkedResource: linkedResourceDto,
      rank
    });

    for (const key of Object.keys(fileDto)) {
      // @ts-ignore
      const value = fileDto[key];
      if (typeof value === "object" && value !== null) {
        for (const objectKey of Object.keys(value)) {
          data.append(key + "[" + objectKey + "]", value[objectKey]);
        }
      } else {
        // @ts-ignore
        data.append(key, fileDto[key]);
      }
    }
    return await apiHelper.axiosPost<FileDto>({ url: this.restRoute + "/pictures/" + id, data });
  }

  async deletePicture(id: string) {
    return await apiHelper.axiosDelete({ url: this.restRoute + "/pictures/" + id });
  }
}
