import type { GetAllUserRolesQuery, UserRoleDto } from "@doorloop/dto";
import { ServerRoutes } from "@doorloop/dto";
import { RestApiBaseWithDictionary } from "./restApiBaseWithDictionary";
import { apiHelper } from "api/apiHelper";

export class UserRolesApi extends RestApiBaseWithDictionary<UserRoleDto, GetAllUserRolesQuery> {
  constructor() {
    super(ServerRoutes.USER_ROLES, []);
    this.restRoute = ServerRoutes.USER_ROLES;
  }

  getDetails = async (userId: string) => await apiHelper.axiosGet(ServerRoutes.USER_ROLES + "/" + userId);
}

export const userRolesApi = new UserRolesApi();
