import { ProfileImage } from "DLUI/profileImage/index";
import React from "react";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import type { SVGIconComponent } from "../../../assets";
import type { FillColors } from "DLUI/icon";

interface ComponentProps {
  onPress?: () => void;
  marginLeft?: number;
  size?: number;
  borderWidth?: number;
  borderColor?: string;
  AvatarIcon?: SVGIconComponent;
  pathColor?: FillColors | undefined;
}

const UserProfileImage: React.FC<ComponentProps> = ({
  onPress,
  marginLeft,
  size,
  AvatarIcon,
  borderWidth,
  borderColor,
  pathColor
}: ComponentProps) => {
  const localeData = useSelector((state: IRootState) => state.auth.currentLoginResponse);

  return (
    <ProfileImage
      onPress={onPress}
      pictureUrl={localeData?.pictureUrl}
      marginLeft={marginLeft}
      size={size || 30}
      AvatarIcon={AvatarIcon}
      borderWidth={borderWidth}
      borderColor={borderColor}
      pathColor={pathColor}
    />
  );
};

export default UserProfileImage;
