"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DuplicateDialogButtonLabelValues = void 0;
var DuplicateDialogButtonLabelValues;
(function (DuplicateDialogButtonLabelValues) {
    DuplicateDialogButtonLabelValues["DUPLICATE_LINE_ITEM"] = "Duplicate row";
    DuplicateDialogButtonLabelValues["DUPLICATE_JOURNAL_ENTRY"] = "Duplicate journal entry";
    DuplicateDialogButtonLabelValues["DUPLICATE_OWNER_REQUEST"] = "Duplicate owner request";
    DuplicateDialogButtonLabelValues["DUPLICATE_TASK"] = "Duplicate task";
    DuplicateDialogButtonLabelValues["DUPLICATE_TENANT_REQUEST"] = "Duplicate tenant request";
    DuplicateDialogButtonLabelValues["DUPLICATE_WORK_ORDER"] = "Duplicate work order";
})(DuplicateDialogButtonLabelValues || (exports.DuplicateDialogButtonLabelValues = DuplicateDialogButtonLabelValues = {}));
