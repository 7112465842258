"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisplayLeaseDraftTenantDto = exports.LeaseDraftTenantDto = void 0;
const class_validator_1 = require("class-validator");
const identifiable_1 = require("../identifiable");
const tenant_dto_1 = require("../tenants/tenant.dto");
class LeaseDraftTenantDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.LeaseDraftTenantDto = LeaseDraftTenantDto;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], LeaseDraftTenantDto.prototype, "tenant", void 0);
class DisplayLeaseDraftTenantDto extends (0, identifiable_1.Identifiable)(tenant_dto_1.TenantDto) {
    constructor() {
        super();
        this.loading = false;
        this.error = false;
    }
}
exports.DisplayLeaseDraftTenantDto = DisplayLeaseDraftTenantDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], DisplayLeaseDraftTenantDto.prototype, "isNotScreened", void 0);
