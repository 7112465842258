import type { PropertyDto } from "@doorloop/dto";
import { createValidator, LeaseDto } from "@doorloop/dto";
import { leasesApi } from "api/leasesApi";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { LeaseSettingsForm } from "../common/settings/leaseSettingsForm";
import type { FormikProps } from "formik";
import { Formik } from "formik";
import { GenericConfirmation } from "DLUI/dialogs/components/genericConfirmation";
import { propertiesApi } from "api/propertiesApi";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import { useUserType } from "../../../../../hooks/useUserType";
import HomeownerAccountSettingsForm from "DLUI/dialogs/settings/common/settings/homeownerAccountSettingsForm";

interface ComponentProps {
  didFinishOperation: (values: LeaseDto) => void;
  onBackdropClick: () => void;
  dialogTitle: string;
}

enum DialogView {
  LeaseSettings = 0,
  SaveConfirmation = 1,
  Loading = 2,
  ChangesConfirmation = 3
}

const ConfirmationDialogHeight = 220;
const FormDialogHeight = 900;

const EditLease: React.FC<ComponentProps> = ({ didFinishOperation, onBackdropClick, dialogTitle }: ComponentProps) => {
  const { t } = useTranslation();
  const { leaseId } = useParams<any>();
  const [leaseData, setLeaseData] = useState<LeaseDto | undefined>();
  const [propertyData, setPropertyData] = useState<PropertyDto | undefined>();
  const [termHasConflict, setTermHasConflict] = useState<boolean>(false);
  const [viewIndex, setViewIndex] = useState<DialogView>(DialogView.LeaseSettings);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>(AppStrings.Common.NetworkErrorSubTitle);
  const [hideLoadingDialogActionButtons, setHideLoadingDialogActionButtons] = useState<boolean>(false);
  const [confirmationText, setConfirmationText] = useState<string>("");
  const { isHOAUser } = useUserType();

  const loadLeaseData = async () => {
    if (!leaseId) {
      showDialogError();
      return;
    }

    const leaseResponse = await leasesApi.get(leaseId);
    if (!leaseResponse?.status || !leaseResponse.data?.property) {
      showDialogError(leaseResponse.message);
      return;
    }

    const propertyResponse = await propertiesApi.get(leaseResponse.data.property);
    if (!propertyResponse?.status) {
      showDialogError(propertyResponse.message);
      return;
    }

    setPropertyData(propertyResponse.data);
    setLeaseData(leaseResponse.data);
    setLoadingDialogState(DialogState.Success);
  };

  const showDialogError = (errorMessage?: string) => {
    setHideLoadingDialogActionButtons(true);
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(errorMessage || AppStrings.Common.GeneralError);
  };

  useEffect(() => {
    loadLeaseData();
  }, []);

  const initialValues = useMemo(() => {
    if (!leaseData) {
      return new LeaseDto();
    }

    return leaseData;
  }, [leaseData]);

  const validateForm = async (values: LeaseDto) => {
    const errors: Record<string, unknown> = createValidator(LeaseDto)(values);

    if (termHasConflict) {
      errors.termConflict = true;
    }
    return errors;
  };

  const updateLease = async (values: LeaseDto, showChangesConfirmationDialog?: boolean) => {
    const { status, message } = await leasesApi.update(values.id!, values);

    if (!status) {
      setLoadingDialogState(DialogState.Error);
      setLoadingDialogErrorText(message || t(AppStrings.Common.GeneralError));
      return;
    }

    const addCount = values.units.filter((unit) => !leaseData?.units.find((unitId) => unitId === unit)).length;
    const removeCount = leaseData?.units.filter((unitId) => !values.units.find((unit) => unitId === unit)).length || 0;
    if (showChangesConfirmationDialog && (addCount > 0 || removeCount > 0)) {
      setViewIndex(DialogView.ChangesConfirmation);
    } else {
      didFinishUpdateProperty(values);
    }
  };

  const didPressSaveButton = async (values: LeaseDto) => {
    if (!leaseData) {
      return;
    }

    setLoadingDialogState(DialogState.Show);
    const needsConfirmation = !_.isEqual(leaseData.units, values.units);
    if (needsConfirmation) {
      const addCount = values.units.filter((unit) => !leaseData.units.find((unitId) => unitId === unit)).length;
      const removeCount = leaseData.units.filter((unitId) => !values.units.find((unit) => unitId === unit)).length;

      let confirmationText = "";
      if (addCount > 0 && removeCount > 0) {
        confirmationText = t(AppStrings.Leases.NewLease.UnitEditConfirmations.ConfirmAddRemove, {
          add: addCount,
          remove: removeCount
        });
      } else if (addCount > 0) {
        confirmationText = t(AppStrings.Leases.NewLease.UnitEditConfirmations.ConfirmAdd, { add: addCount });
      } else if (removeCount > 0) {
        confirmationText = t(AppStrings.Leases.NewLease.UnitEditConfirmations.ConfirmRemove, { remove: removeCount });
      }

      setConfirmationText(confirmationText);
      setViewIndex(DialogView.SaveConfirmation);
      return;
    }
    setViewIndex(DialogView.Loading);
    await updateLease(values);
  };

  const renderActionPanelButtons = (formikProps: FormikProps<LeaseDto>) => () => {
    if (viewIndex === DialogView.Loading) {
      return <div />;
    }

    return (
      <FormActionButtons
        propsSubButton={{ onClick: onBackdropClick }}
        propsMainButton={{ type: "cta", props: { onClick: formikProps.submitForm } }}
      />
    );
  };

  const didPressDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(DialogView.LeaseSettings);
  };

  const onRetryButtonPress = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(DialogView.LeaseSettings);
  };

  const didFinishUpdateProperty = (values: LeaseDto) => {
    didFinishOperation(values);
    setLoadingDialogState(DialogState.Hidden);
  };

  function onLeaseConflict(hasConflict: boolean) {
    setTermHasConflict(hasConflict);
  }

  async function onConfirmationApproved(values: LeaseDto) {
    setViewIndex(DialogView.Loading);
    await updateLease(values, true);
  }

  const renderView =
    (formik: FormikProps<LeaseDto>) =>
    ({ index }: { index: DialogView }) => {
      if (index === DialogView.Loading || !leaseData || !propertyData) {
        return (
          <View flex={1} width={"100%"} minHeight={200} justifyContent={"center"} alignItems={"center"}>
            <LoadingDialog
              dialogState={loadingDialogState}
              loadingText={t(AppStrings.Common.Loading)}
              errorText={loadingDialogErrorText}
              successText={t(AppStrings.Common.OperationCompleted)}
              onRetryButtonPress={onRetryButtonPress}
              didPressDismissButton={didPressDismissButton}
              hideButtons={hideLoadingDialogActionButtons}
            />
          </View>
        );
      }

      if (index === DialogView.LeaseSettings) {
        return !isHOAUser ? (
          <LeaseSettingsForm property={propertyData} onLeaseConflict={onLeaseConflict} />
        ) : (
          <HomeownerAccountSettingsForm />
        );
      }
      if (index === DialogView.SaveConfirmation) {
        return (
          <GenericConfirmation
            title={AppStrings.Common.Attention}
            subTitle={confirmationText}
            actionButtons={{
              propsSubButton: { actionText: AppStrings.Common.Dismiss, onClick: didPressDismissButton },
              propsMainButton: {
                type: "cta",
                props: {
                  actionText: AppStrings.Common.Confirm,
                  onClick: async () => await onConfirmationApproved(formik.values)
                }
              }
            }}
          />
        );
      }
      if (index === DialogView.ChangesConfirmation) {
        return (
          <GenericConfirmation
            title={AppStrings.Common.Attention}
            subTitle={t(AppStrings.Leases.LeaseDetails.ChangeUnitsForLeaseConfirmation)}
            actionButtons={{
              propsMainButton: {
                type: "cta",
                props: {
                  actionText: AppStrings.Common.ConfirmUnderstand,
                  onClick: () => didFinishUpdateProperty(formik.values)
                }
              }
            }}
          />
        );
      }

      return <div />;
    };

  const _onBackdropClick = () => {
    if (viewIndex === DialogView.Loading) {
      setViewIndex(DialogView.LeaseSettings);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const frameType = useMemo(() => {
    if (leaseData === undefined) {
      return "contentOnly";
    }

    if (viewIndex === DialogView.LeaseSettings) {
      return "sectionTitleFrame";
    }

    if (
      viewIndex === DialogView.SaveConfirmation ||
      viewIndex === DialogView.Loading ||
      viewIndex === DialogView.ChangesConfirmation
    ) {
      return "contentOnly";
    }

    return "topPanel";
  }, [viewIndex, leaseData]);

  const DialogHeight = useMemo(
    () => (viewIndex === DialogView.LeaseSettings ? FormDialogHeight : ConfirmationDialogHeight),
    [viewIndex]
  );

  return (
    <Formik initialValues={initialValues} onSubmit={didPressSaveButton} validate={validateForm} enableReinitialize>
      {(formik) => (
        <DialogFrame
          onCloseButtonClick={_onBackdropClick}
          title={dialogTitle}
          width={getDialogFrameDimension("width", 600)}
          height={getDialogFrameDimension("height", DialogHeight)}
          renderView={renderView(formik)}
          numViews={Object.keys(DialogView).length}
          activeView={viewIndex}
          RenderActionPanelButtons={renderActionPanelButtons(formik)}
          frameType={frameType}
        />
      )}
    </Formik>
  );
};

export default EditLease;
