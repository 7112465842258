import type { RentRemindersSettingsDto } from "@doorloop/dto";
import { SegmentEventTypes } from "@doorloop/dto";

import type { AnalyticsSettingsSource } from "../../../../../../services/analyticsService";
import { analyticsService } from "../../../../../../services/analyticsService";

export const trackRentReminderSettings = (source: AnalyticsSettingsSource, settings?: RentRemindersSettingsDto) => {
  analyticsService.track(SegmentEventTypes.NOTIFICATION_SETTINGS_RENT_REMINDERS_SAVE_CLICKED, {
    Source: source,
    "Upcoming rent reminder": Boolean(settings?.sendRentReminders),
    "Overdue rent reminder": Boolean(settings?.sendRecurringNotificationsForOverdueRent),
    "Post charges to account": Boolean(settings?.postRecurringChargesBeforeDueDate),
    "Notify tenant for charges": Boolean(settings?.sendEmailWhenRentIsCharged),
    "Attach statement": Boolean(settings?.attachAccountStatementPdfWhenRentIsCharged),
    "Attach invoice": Boolean(settings?.attachInvoicePdfWhenRentIsCharged),
    "Notify tenant on online payment": Boolean(settings?.sendPaymentReceiptsWhenOnlinePaymentIsReceived),
    "Notify users on online payment": Boolean(settings?.sendEmailToUsersWhenOnlinePaymentIsReceivedUsers),
    "Notify tenant on payment returned": Boolean(settings?.sendEmailToTenantWhenPaymentIsReturned),
    "Notify users on payment returned": Boolean(settings?.sendEmailToUsersWhenPaymentIsReturnedUsers)
  });
};
