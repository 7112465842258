import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import type { RentalApplicationCompanySettingsDto, SegmentEventTypes } from "@doorloop/dto";
import { RentalApplicationChargeOptions, RentalApplicationsChargesTypes, ReportPaymentOptions } from "@doorloop/dto";
import type { AnalyticsTrackMethodProps } from "../services/analyticsService";
import { analyticsService } from "../services/analyticsService";
import { unitsApi } from "api/unitsApi";
import { rentalApplicationsApi } from "api/rentalApplicationsApi";
import { settingsApi } from "api/settingsApi";
import type { ParsedQuery } from "query-string";
import _ from "lodash";

export enum rentalApplicationEventsType {
  FirstInvoiceDate = "FirstInvoiceDate",
  NumberOfUnits = "NumberOfUnits",
  UserPaymentSelection = "UserPaymentSelection",
  ApplicationFee = "ApplicationFee",
  ChargeOption = "ChargeOption",
  ChargeType = "ChargeType",
  DeductApplicationFee = "DeductApplicationFee",
  ReportFee = "ReportFee",
  DecisionStatus = "DecisionStatus",
  TransunionStatus = "TransunionStatus",
  ProspectAutoCreated = "ProspectAutoCreated",
  SentBy = "SentBy",
  ApplicationSource = "ApplicationSource",
  SectionName = "SectionName"
}

export enum rentalApplicationEventSource {
  companylink = "CompanyLink",
  propertylink = "PropertyLink",
  unitlink = "UnitLink",
  personallink = "PersonalLink",
  email = "Email",
  sms = "SMS",
  legacy = "Legacy"
}

interface GenerateChargeTypeProps {
  chargeOption?: RentalApplicationChargeOptions;
  userSelectedPayment?: ReportPaymentOptions;
}

interface RentalApplicationsSettingsRequestProps {
  propertyId?: string;
  rentalApplicationId?: string;
  unitId?: string;
  useCompanySettings?: boolean;
  useAllAvailableSettings?: boolean;
}

interface RentalApplicationFireEventProps {
  eventName: SegmentEventTypes;
  properties?: Partial<Record<rentalApplicationEventsType, any>>;
  ignoreDefaultProperties?: boolean;
  trackAnalyticsOptions?: AnalyticsTrackMethodProps;
}

export const generateChargeType = ({
  chargeOption,
  userSelectedPayment
}: GenerateChargeTypeProps): RentalApplicationsChargesTypes | undefined => {
  let chargeType;

  if (chargeOption === RentalApplicationChargeOptions.CHARGE_APPLICANT) {
    chargeType = RentalApplicationsChargesTypes.CHARGE_APPLICANT_CONNECT;
  } else if (chargeOption === RentalApplicationChargeOptions.CHARGE_CUSTOM_FEE) {
    chargeType = RentalApplicationsChargesTypes.CHARGE_CUSTOM_FEE_CONNECT;
  } else if (userSelectedPayment === ReportPaymentOptions.MERCHANT_ACCOUNT) {
    chargeType = RentalApplicationsChargesTypes.CHARGE_USER_CONNECT;
  } else if (userSelectedPayment === ReportPaymentOptions.SUBSCRIPTION_CARD) {
    chargeType = RentalApplicationsChargesTypes.CHARGE_USER_BILLING;
  }

  return chargeType;
};

export const getRentalApplicationLinkSource = (queryString: ParsedQuery<string>) => {
  const { source } = queryString;

  if (!_.isString(source)) {
    return rentalApplicationEventSource.legacy;
  }

  return rentalApplicationEventSource?.[source.toLowerCase()] || rentalApplicationEventSource.legacy;
};

const getRentalApplicationSettings = async ({
  propertyId,
  rentalApplicationId,
  useCompanySettings,
  useAllAvailableSettings,
  unitId
}: RentalApplicationsSettingsRequestProps) => {
  let rentalAppSettings;

  if (!useCompanySettings || useAllAvailableSettings) {
    const directSettings = await rentalApplicationsApi.getApplicationSettings(
      propertyId,
      rentalApplicationId,
      undefined,
      unitId
    );
    rentalAppSettings = directSettings?.data?.settings;
  }

  if (useCompanySettings || useAllAvailableSettings) {
    const companySettings = await settingsApi.getRentalApplications();
    rentalAppSettings = companySettings?.data;
  }

  return rentalAppSettings;
};

export const useRentalApplicationEvents = (settingsRequestProperties?: RentalApplicationsSettingsRequestProps) => {
  const userData = useSelector((state: IRootState) => state.auth.currentLoginResponse);

  const fireEvent = async ({
    eventName,
    properties,
    ignoreDefaultProperties = false,
    trackAnalyticsOptions
  }: RentalApplicationFireEventProps) => {
    const trackEventInIntercom = trackAnalyticsOptions?.trackEventInIntercom ?? false;
    const defaultProperties = ignoreDefaultProperties ? {} : await generateDefaultProperties();
    analyticsService.track(eventName, { ...defaultProperties, ...properties }, { trackEventInIntercom });
  };

  const generateDefaultProperties = async () => {
    const eventProperties: Partial<Record<rentalApplicationEventsType, any>> = {};

    eventProperties[rentalApplicationEventsType.FirstInvoiceDate] =
      userData?.currentDbTenant?.subscriptionPlan?.firstInvoiceDate;
    const unitsDictionary = await unitsApi.getDictionary();
    eventProperties[rentalApplicationEventsType.NumberOfUnits] = Object.keys(unitsDictionary).length;

    if (!settingsRequestProperties) {
      return eventProperties;
    }

    const rentalApplicationSettings: RentalApplicationCompanySettingsDto =
      await getRentalApplicationSettings(settingsRequestProperties);

    if (!rentalApplicationSettings?.fees) {
      return eventProperties;
    }

    const chargeOption = rentalApplicationSettings.fees.rentalApplicationChargeOption;
    if (chargeOption) {
      eventProperties[rentalApplicationEventsType.ChargeOption] = chargeOption;
    }

    const applicationFee = rentalApplicationSettings.fees.applicationFee;
    if (applicationFee) {
      eventProperties[rentalApplicationEventsType.ApplicationFee] = applicationFee;
    }

    const chargeType = generateChargeType({ chargeOption });
    if (chargeType) {
      eventProperties[rentalApplicationEventsType.ChargeType] = chargeType;
    }

    const deductApplicationFee = rentalApplicationSettings.fees.deductScreeningCostFromApplication;
    if (chargeOption === RentalApplicationChargeOptions.CHARGE_CUSTOM_FEE && deductApplicationFee) {
      eventProperties[rentalApplicationEventsType.DeductApplicationFee] = deductApplicationFee;
    }

    return eventProperties;
  };

  return {
    fireEvent
  };
};
