import _ from "lodash";
import { useCallback, useState } from "react";

function validateItem(value: unknown): boolean {
  if (_.isNil(value)) {
    return true;
  }

  if (typeof value === "string" && value.trim() === "") {
    return true;
  }

  return false;
}

export const useAggregatedFilterObject = <FilterObjectType>(
  initialValue: FilterObjectType = {} as FilterObjectType
) => {
  const [filterObject, setFilterObject] = useState<FilterObjectType>(initialValue);

  const handleFilterChange = useCallback((filterObject: FilterObjectType) => {
    const keys = Object.keys(filterObject as object);
    const values = Object.values(filterObject as object);

    const newFilterObject = keys.reduce((acc, key, index) => {
      return {
        ...acc,
        [key]: values[index]
      };
    }, {});

    setFilterObject((prevFilterObject) => {
      const filterObject = _.chain({
        ...(prevFilterObject as object),
        ...(newFilterObject as object)
      })
        .omitBy(validateItem)
        .value();

      return filterObject as FilterObjectType;
    });
  }, []);

  const handleFilterRemove = useCallback((selectionKeys: string[]) => {
    setFilterObject((prevFilterObject) =>
      selectionKeys.reduce((acc, key) => {
        delete acc[key];

        return acc;
      }, _.clone(prevFilterObject))
    );
  }, []);

  return {
    filterObject,
    onFilterChange: handleFilterChange,
    onFilterRemove: handleFilterRemove
  };
};
