import AppStrings from "locale/keys";
import type { CSSProperties } from "react";
import React from "react";
import type { SVGIconComponent } from "../../../assets";
import "./styles.css";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum } from "DLUI/button/dlButton";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import type { TextTooltipProps } from "DLUI/popover/textTooltip";
import { TextTooltip } from "DLUI/popover/textTooltip";
import { useCopyText } from "DLUI/button/useCopyText";

interface ComponentProps {
  stringToCopy: string;
  marginLeft?: number;
  LeftIcon?: SVGIconComponent;
  marginTop?: number;
  marginRight?: number;
  minWidth?: CSSProperties["minWidth"];
  actionText?: string | null;
  onFinish?: () => void;
  buttonColor?: DLButtonColorsEnum;
  disabled?: boolean;
  tooltipText?: string;
  tooltipMaxWidth?: number;
  renderButton?: (props: { didPressCopyLink: () => void }) => JSX.Element;
  tooltipProps?: Partial<TextTooltipProps>;
}

const CopyToClipboardButton: React.FC<ComponentProps> = ({
  stringToCopy,
  marginLeft,
  marginTop,
  LeftIcon,
  marginRight,
  minWidth,
  actionText = AppStrings.Common.CopyLink,
  onFinish,
  buttonColor = DLButtonColorsEnum.SECONDARY,
  disabled,
  tooltipText,
  tooltipMaxWidth,
  renderButton,
  tooltipProps
}: ComponentProps) => {
  const { isMobile } = useResponsiveHelper();
  const didPressCopyLink = useCopyText(stringToCopy, onFinish);

  const styleProps = {
    minWidth: minWidth ? minWidth : isMobile ? 300 : 150,
    marginTop,
    marginLeft
  };

  return (
    <TextTooltip
      useDark
      show={tooltipText ? disabled : false}
      value={tooltipText}
      maxWidth={tooltipMaxWidth}
      {...tooltipProps}
    >
      {!renderButton ? (
        <DLButton
          onClick={didPressCopyLink}
          actionText={actionText || undefined}
          color={buttonColor}
          style={{ marginRight, ...styleProps }}
          size={DLButtonSizesEnum.SMALL}
          icons={LeftIcon ? { start: { src: LeftIcon } } : undefined}
          disabled={disabled}
        />
      ) : (
        renderButton({ didPressCopyLink })
      )}
    </TextTooltip>
  );
};
export default CopyToClipboardButton;
