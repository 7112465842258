import { useMutation, useQuery } from "@tanstack/react-query";
import type { TenantPortalGetLeaseAccountsReceivableReportQuery } from "@doorloop/dto";
import { tenantPortalPaymentsApi } from "api/tenantPortal/tenantPortalPaymentsApi";

interface DownloadPaymentHistoryParams {
  query: TenantPortalGetLeaseAccountsReceivableReportQuery;
}

export const tenantPortalPaymentsStore = {
  queries: {
    useGetPaymentHistory: (query: TenantPortalGetLeaseAccountsReceivableReportQuery) =>
      useQuery({
        queryKey: ["tenant-portal-payments-get-payment-history", query],
        queryFn: async ({ signal }) => {
          const response = await tenantPortalPaymentsApi.getPaymentHistory(query);
          if (signal?.aborted || !response) {
            return;
          }

          return response;
        },
        refetchOnWindowFocus: false
      })
  },
  mutations: {
    usePaymentHistoryPdfToken: () =>
      useMutation({
        mutationKey: ["tenant-portal-payments-store-download-payment-history"],
        mutationFn: async ({ query }: DownloadPaymentHistoryParams) =>
          await tenantPortalPaymentsApi.getPaymentHistoryPdfToken(query),
        networkMode: "always"
      })
  }
};
