import type { TextColor } from "DLUI/text/text";
import clsx from "clsx";
import { useTypedTranslation } from "locale";
import Text from "DLUI/text";
import { useGeneralStyles } from "styles/generalStyles";
import { DataCy, type LateFeesPolicyDto } from "@doorloop/dto";
import { useMemo } from "react";
import { useSampleCalculator } from "DLUI/dialogs/settings/common/lateFees/sampleCalculator/useSampleCalculator";
import ColorsEnum from "utils/colorsEnum";
import isEmpty from "lodash/isEmpty";

interface SummarySectionProps {
  rent: number;
  delinquency: number;
  lateFeeSettings: LateFeesPolicyDto;
  textColor: TextColor;
}

export const SummarySection: React.FC<SummarySectionProps> = ({ rent, delinquency, lateFeeSettings, textColor }) => {
  const { t } = useTypedTranslation();
  const generalClasses = useGeneralStyles();
  const {
    totalChargesBeforeMaxFees,
    totalChargesPerDayWithMaxDailyFees,
    totalChargesPerMonthWithMaxMonthlyFees,
    totalChargesPerMonthWithMaxMonthlyAndDailyFees
  } = useSampleCalculator({ rent, delinquency, lateFeeSettings });
  const {
    maxDailyLateFees,
    maxMonthlyLateFees,
    minBalanceToApplyLateFee,
    minBalanceToApplyLateFeeAmount,
    onlyChargeLateFeesOnMostRecentCharge,
    lateFeeSettingsAccountsToConsiderSpecificAccounts
  } = lateFeeSettings;

  const total = useMemo(() => {
    if (!maxMonthlyLateFees && !maxDailyLateFees) {
      return totalChargesBeforeMaxFees;
    }
    if (maxDailyLateFees && !maxMonthlyLateFees) {
      return Math.max(totalChargesPerDayWithMaxDailyFees, 0);
    }
    if (!maxDailyLateFees && maxMonthlyLateFees) {
      return Math.max(totalChargesPerMonthWithMaxMonthlyFees, 0);
    }
    return Math.max(totalChargesPerMonthWithMaxMonthlyAndDailyFees, 0);
  }, [
    maxDailyLateFees,
    maxMonthlyLateFees,
    totalChargesBeforeMaxFees,
    totalChargesPerDayWithMaxDailyFees,
    totalChargesPerMonthWithMaxMonthlyAndDailyFees,
    totalChargesPerMonthWithMaxMonthlyFees
  ]);

  return (
    <div className={clsx(generalClasses.flexRow, generalClasses.spaceBetween)}>
      <div className={clsx(generalClasses.flexColumn, generalClasses.smallGap)}>
        <Text
          value={t("lateFee.sampleCalculator.totalSection.title")}
          fontSize={16}
          fontWeight={700}
          color={textColor}
        />
        {minBalanceToApplyLateFee && minBalanceToApplyLateFeeAmount && (
          <Text
            value={`${t("lateFee.sampleCalculator.totalSection.minBalance")} $${minBalanceToApplyLateFeeAmount}`}
            fontSize={12}
            fontWeight={700}
            colorEnum={ColorsEnum.BrightBlue}
          />
        )}
        {onlyChargeLateFeesOnMostRecentCharge && (
          <Text
            value={t("lateFee.sampleCalculator.totalSection.recentCharge")}
            fontSize={12}
            fontWeight={700}
            colorEnum={ColorsEnum.BrightBlue}
          />
        )}
        {!isEmpty(lateFeeSettingsAccountsToConsiderSpecificAccounts) && (
          <Text
            value={t("lateFee.sampleCalculator.totalSection.specificAccountsCharge")}
            fontSize={12}
            fontWeight={700}
            colorEnum={ColorsEnum.BrightBlue}
          />
        )}
      </div>
      <Text
        value={total.toString()}
        formatType={"currency"}
        fontSize={16}
        fontWeight={700}
        color={textColor}
        dataCy={DataCy.globalSettings.lateFees.sampleCalculator.total.amount}
      />
    </div>
  );
};
