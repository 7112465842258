import type { ChangeEvent } from "react";
import React, { memo } from "react";
import clsx from "clsx";
import { View } from "DLUI/view";
import { useReadOnlyContext } from "contexts/readOnlyContext";
import { InputLabel } from "DLUI/form/label/inputLabel";
import { useStylesCheckBox } from "DLUI/form/checkBox/base/useStylesCheckBox";
import { Icon } from "DLUI/icon";
import { NewCheckIndeterminateIcon, NewCheckOffIcon, NewCheckOnIcon } from "../../../../../assets";
import type { ControlledToggleInputBaseTypes, ToggleInputSize } from "DLUI/form/types/toggleInputBaseTypes";
import type { CheckBoxColor } from "DLUI/form/checkBox/base/types";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";
import { Checkbox } from "@material-ui/core";

export interface ControlledCheckboxProps<T> extends ControlledToggleInputBaseTypes<T> {
  indeterminate?: boolean;
  color?: CheckBoxColor;
  fontSize?: ToggleInputSize;
  className?: string;
}
export const ControlledCheckBox = memo(
  <T,>({
    checked = false,
    onChange: didChangeCheckBoxSelection,
    disabled: disabledProp,
    style,
    size,
    fontSize,
    color,
    indeterminate,
    dataCy,
    fullWidth,
    labelValueProps,
    name,
    value,
    id,
    className
  }: ControlledCheckboxProps<T>) => {
    const isReadOnly = useReadOnlyContext();
    const isDisabled = disabledProp || isReadOnly;
    const { isTabletOrMobile } = useResponsiveHelper();

    const { classNameContainer, classesInput } = useStylesCheckBox({
      labelPlacement: labelValueProps?.placement,
      color,
      size
    });

    const onClickLabel = () => didChangeCheckBoxSelection?.(!checked);

    const didCheckboxChange = (e: ChangeEvent, newCheckedState: boolean) => {
      didChangeCheckBoxSelection?.(newCheckedState, value, e);
    };

    return (
      <View
        className={clsx([classNameContainer, "DoorLoop", className])}
        width={fullWidth ? "100%" : "auto"}
        alignItems={"center"}
        noWrap
        {...style}
      >
        <Checkbox
          value={value}
          id={id}
          name={name}
          onChange={didCheckboxChange}
          disabled={isDisabled}
          data-cy={dataCy}
          classes={classesInput}
          indeterminate={indeterminate}
          checked={checked}
          icon={<Icon Source={NewCheckOffIcon} size={"revert-layer"} />}
          checkedIcon={<Icon Source={NewCheckOnIcon} size={"revert-layer"} />}
          indeterminateIcon={<Icon Source={NewCheckIndeterminateIcon} size={"revert-layer"} />}
        />

        {labelValueProps?.text ? (
          <InputLabel
            fullWidth={fullWidth}
            disabled={isDisabled}
            color={labelValueProps.color}
            text={labelValueProps.text}
            clickable={labelValueProps.clickable}
            placement={labelValueProps.placement}
            size={fontSize || size}
            onClick={onClickLabel}
            maxWidth={isTabletOrMobile ? 280 : "100%"}
          />
        ) : null}
      </View>
    );
  }
);
