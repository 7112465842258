import { useCallback, useEffect } from "react";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import type { UnitDto } from "@doorloop/dto";
import { ObjectPermission, UnitListingInfoDto } from "@doorloop/dto";
import { View } from "DLUI/view";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import { FastField, useFormikContext } from "formik";
import { FormikDatePicker, SwitchButton, TextField } from "DLUI/form";
import { Trans, useTranslation } from "react-i18next";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { usersApi } from "api/usersApi";
import AppStrings from "locale/keys";
import Text from "DLUI/text";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { Grid } from "@material-ui/core";
import FieldWrapper from "DLUI/form/textField/fieldWrapper";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";
import { useListingValidation } from "DLUI/dialogs/components/editUnit/useListingValidation";
import ColorsEnum from "../../../../../utils/colorsEnum";
import { Link } from "DLUI/link";
import { LinkURLsEnum } from "DLUI/link/linkURLsEnum";
import { useContextSelector } from "use-context-selector";
import { RUGContext } from "../../../../common/pictureGallery/pictureGallery.hooks";

export const EditUnitListing = () => {
  const picturesState = useContextSelector(RUGContext, (context) => context?.state || null);
  const { dialogHorizontalPadding } = DialogsHelper();
  const formikRef = useFormikContext<UnitDto>();
  const { t } = useTranslation();
  const { errors, shouldShowValidationErrors } = useListingValidation({ transientPictures: picturesState });
  const listing = formikRef.values.listing;
  const isActiveListing = Boolean(listing?.activeListing);

  useEffect(() => {
    if (listing === undefined) {
      formikRef.setFieldValue("listing", new UnitListingInfoDto());
    }
  }, []);

  const toggleListing = useCallback(
    (value) => {
      if (value) {
        formikRef.setFieldValue("listing", new UnitListingInfoDto({ activeListing: true }));
      } else {
        formikRef.setFieldValue("listing", undefined);
      }
    },
    [formikRef]
  );

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding} flex={1}>
        <RestrictedPermissionAccess
          clearance={{
            permission: ObjectPermission.properties,
            field: "edit"
          }}
          showNoAccess
        >
          <View alignItems={"flex-start"}>
            <Text fontSize={16} value={AppStrings.Listing.ActivateListing} />

            <View marginTop={20}>
              <Text>
                <Trans
                  i18nKey={AppStrings.Listing.ZillowSyndication}
                  components={{
                    bold: <strong />,
                    CustomLink: (
                      <Link underline={"always"} type={"blank"} hrefUrl={LinkURLsEnum.CREATE_LISTING_DOCS}>
                        {""}
                      </Link>
                    )
                  }}
                />
              </Text>
            </View>
            <HorizontalSeparationLine marginTop={20} />
            <Text fontSize={18} value={AppStrings.Listing.PartnerWebsitesListing} marginTop={20} bold />
            <SwitchButton
              marginTop={20}
              label={AppStrings.Listing.UnitListingActiveDescription}
              checked={listing?.activeListing}
              onChange={toggleListing}
              noWrap
            />
            {isActiveListing && shouldShowValidationErrors && (
              <>
                <View marginTop={10}>
                  <Text color={"error"} value={AppStrings.Listing.Errors.Title} />
                  <ul style={{ marginTop: 5, marginBottom: 0 }}>
                    {errors.map((error, i) => (
                      <li key={i} style={{ color: ColorsEnum.Error }}>
                        <Text value={error} color={"error"} />
                      </li>
                    ))}
                  </ul>
                </View>

                <View noWrap marginTop={20}>
                  <Text>
                    <Trans
                      i18nKey={AppStrings.Listing.ForMoreInformation}
                      components={{
                        CustomLink: (
                          <Link underline={"always"} type={"blank"} hrefUrl={LinkURLsEnum.TROUBLESHOOTING_ZILLOW}>
                            {""}
                          </Link>
                        )
                      }}
                    />
                  </Text>
                </View>
              </>
            )}
          </View>
          {listing && isActiveListing && (
            <>
              <HorizontalSeparationLine marginTop={20} />
              <Text fontSize={18} value={AppStrings.Listing.ListingDetails} marginTop={20} bold />
              <View flexDirection={"row"}>
                <Grid xs={12} sm={12} md={6} lg={6} item>
                  <FieldWrapper type={"endElement"}>
                    <FastField
                      component={FormikDatePicker}
                      label={AppStrings.Listing.DateAvailable}
                      name={"listing.dateAvailable"}
                      size={"100%"}
                      required
                      noMargin
                    />
                  </FieldWrapper>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} item>
                  <FieldWrapper type={"startElement"}>
                    <FormikCachedAsyncAutoComplete
                      uniqueIndex={"TS"}
                      apiHandler={usersApi}
                      label={t(AppStrings.Listing.ListingContact)}
                      name={"listing.contact"}
                      displayNameKey={"name"}
                      filterFieldName={"filter_text"}
                      filterFieldValue={"name"}
                      selectionFields={["id", "class"]}
                      defaultValue={listing.contact}
                      required
                    />
                  </FieldWrapper>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} item>
                  <FieldWrapper type={"endElement"}>
                    <FastField
                      component={TextField}
                      label={t(AppStrings.Listing.AskingRent)}
                      name={"listing.rent"}
                      size={"100%"}
                      formatType={"currency"}
                      required
                    />
                  </FieldWrapper>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} item>
                  <FieldWrapper type={"startElement"}>
                    <FastField
                      component={TextField}
                      label={t(AppStrings.Listing.Deposit)}
                      name={"listing.deposit"}
                      size={"100%"}
                      formatType={"currency"}
                    />
                  </FieldWrapper>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} item>
                  <FastField
                    component={TextField}
                    label={t(AppStrings.Listing.LeaseTerms)}
                    name={"listing.leaseLength"}
                    marginTop={20}
                    size={"100%"}
                    maxLength={100}
                  />
                </Grid>
              </View>
            </>
          )}
        </RestrictedPermissionAccess>
      </View>
    </MuiPickersUtilsProvider>
  );
};
