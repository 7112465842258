"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyndicationIdentifiable = exports.SyndicationIDType = void 0;
const class_validator_1 = require("class-validator");
var SyndicationIDType;
(function (SyndicationIDType) {
    SyndicationIDType["MANAGEMENT"] = "Management";
    SyndicationIDType["COMPANY"] = "Company";
    SyndicationIDType["PROPERTY"] = "Property";
    SyndicationIDType["UNIT"] = "UnitID";
    SyndicationIDType["FLOORPLAN"] = "FloorplanID";
})(SyndicationIDType || (exports.SyndicationIDType = SyndicationIDType = {}));
class SyndicationIdentifiable {
}
exports.SyndicationIdentifiable = SyndicationIdentifiable;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], SyndicationIdentifiable.prototype, "_IDValue", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(50),
    (0, class_validator_1.IsEnum)(SyndicationIDType),
    __metadata("design:type", String)
], SyndicationIdentifiable.prototype, "_IDType", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(50),
    __metadata("design:type", String)
], SyndicationIdentifiable.prototype, "_IDRank", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], SyndicationIdentifiable.prototype, "_OrganizationName", void 0);
