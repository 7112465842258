import { BankConnectNoTransactionsIcon } from "assets/icons";
import EmptyDataView from "DLUI/emptyDataView";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import type { FC } from "react";
import React from "react";

interface ComponentProps {
  actionButton?: JSX.Element;
}

export const TransactionsEmptyDataView: FC<ComponentProps> = ({ actionButton }) => (
  <View justifyContent={"center"} alignItems={"center"}>
    <EmptyDataView
      instructionsText={AppStrings.Leases.LeaseTransaction.NoTransactionsFound}
      displayIcon={BankConnectNoTransactionsIcon}
      instructionsTextMaxWidth={450}
      actionButton={actionButton}
    />
  </View>
);
