import type { FilterOption } from "@/components/DLUI/screen/filterPanel/filterInputs/types";
import type { GetAllRentalApplicationsQuery } from "@doorloop/dto";
import {
  RentalApplicationDecisionStatus,
  RentalApplicationReportStatus,
  RentalApplicationStatus,
  ScreeningRequestReportsStatus
} from "@doorloop/dto";
import { propertiesApi } from "api/propertiesApi";
import { propertyGroupsApi } from "api/propertyGroupsApi";
import { usersApi } from "api/usersApi";
import AppStrings from "locale/keys";

export const filterOptions: Array<FilterOption<GetAllRentalApplicationsQuery>> = [
  {
    label: AppStrings.Tasks.Screen.CreatedAt,
    type: "dateRangePicker",
    period: "all-time",
    startDateFieldKey: "filter_date_from",
    endDateFieldKey: "filter_date_to",
    size: "2-rows"
  },

  {
    name: AppStrings.Common.FilterOptions.Property,
    apiHandler: propertiesApi,
    displayNameKey: "name",
    filterFieldName: "filter_property",
    filterFieldValue: "id",
    type: "remoteFiltering"
  },
  {
    name: AppStrings.Common.FilterOptions.Portfolio,
    apiHandler: propertyGroupsApi,
    displayNameKey: "name",
    filterFieldName: "filter_group",
    filterFieldValue: "id",
    type: "remoteFiltering"
  },
  {
    type: "remoteFiltering",
    name: AppStrings.Tasks.TasksList.assignedTo,
    apiHandler: usersApi,
    displayNameKey: "name",
    filterFieldName: "filter_assignedToUser",
    filterFieldValue: "id"
  },
  {
    type: "staticFiltering",
    name: AppStrings.Common.Decision,
    values: [
      {
        displayName: AppStrings.Common.Enums.RentalApplicationDecisionStatus.APPROVED,
        value: RentalApplicationDecisionStatus.APPROVED,
        filterFieldName: "filter_decisionStatuses"
      },
      {
        displayName: AppStrings.Common.Enums.RentalApplicationDecisionStatus.IGNORED,
        value: RentalApplicationDecisionStatus.IGNORED,
        filterFieldName: "filter_decisionStatuses"
      },
      {
        displayName: AppStrings.Common.Enums.RentalApplicationDecisionStatus.NOT_STARTED,
        value: RentalApplicationDecisionStatus.NOT_STARTED,
        filterFieldName: "filter_decisionStatuses"
      },
      {
        displayName: AppStrings.Common.Enums.RentalApplicationDecisionStatus.REJECTED,
        value: RentalApplicationDecisionStatus.REJECTED,
        filterFieldName: "filter_decisionStatuses"
      },
      {
        displayName: AppStrings.Common.Enums.RentalApplicationDecisionStatus.UNDECIDED,
        value: RentalApplicationDecisionStatus.UNDECIDED,
        filterFieldName: "filter_decisionStatuses"
      }
    ]
  },
  {
    type: "staticFiltering",
    name: AppStrings.Common.RentalApplicationStatus,
    values: [
      {
        displayName: AppStrings.Common.Enums.RentalApplicationStatus.NOT_SENT,
        value: RentalApplicationStatus.NOT_SENT,
        filterFieldName: "filter_status"
      },
      {
        displayName: AppStrings.Common.Enums.RentalApplicationStatus.SENT,
        value: RentalApplicationStatus.SENT,
        filterFieldName: "filter_status"
      },
      {
        displayName: AppStrings.Common.Enums.RentalApplicationStatus.SUBMITTED,
        value: RentalApplicationStatus.SUBMITTED,
        filterFieldName: "filter_status"
      }
    ]
  },
  {
    type: "staticFiltering",
    name: AppStrings.Common.RentalApplicationReportStatus,
    values: [
      {
        displayName: AppStrings.Common.Enums.RentalApplicationReportStatus.PENDING_REPORT,
        value: RentalApplicationReportStatus.PENDING_REPORT,
        filterFieldName: "filter_reportStatus"
      },
      {
        displayName: AppStrings.Common.Enums.RentalApplicationReportStatus.IDENTITY_VERIFICATION_FAILED,
        value: RentalApplicationReportStatus.IDENTITY_VERIFICATION_FAILED,
        filterFieldName: "filter_reportStatus"
      },
      {
        displayName: AppStrings.Common.Enums.RentalApplicationReportStatus.REPORT_AVAILABLE,
        value: RentalApplicationReportStatus.REPORT_AVAILABLE,
        filterFieldName: "filter_reportStatus"
      },
      {
        displayName: AppStrings.Common.Enums.ScreeningRequestReportsStatus.READY_FOR_REPORT_REQUEST,
        value: ScreeningRequestReportsStatus.READY_FOR_REPORT_REQUEST,
        filterFieldName: "filter_reportStatus"
      }
    ]
  }
];
