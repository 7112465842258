import React, { useMemo } from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { SeparationLine } from "DLUI/separatorView";
import AppStrings from "locale/keys";
import { useFormikContext } from "formik";
import { BulkPaymentWrapperDto, BulkReceiveSessionDto, SegmentEventTypes } from "@doorloop/dto";
import { useTranslation } from "react-i18next";
import { FieldSafe } from "DLUI/fieldSafe/fieldSafe";
import { FormikCheckBox, FormikDatePicker, ValidationIndicator } from "DLUI/form";
import { analyticsService } from "services/analyticsService";

interface BulkPaymentFooterProps {
  isReviewMode?: boolean;
}

export const BulkPaymentFooter = ({ isReviewMode }: BulkPaymentFooterProps) => {
  const { t } = useTranslation();
  const formikContext = useFormikContext<BulkReceiveSessionDto>();
  const paymentCount = useMemo(
    () => Object.values(formikContext.values.payments).length,
    [formikContext.values.payments]
  );

  const totalPaymentAmount = useMemo(
    () => Object.values(formikContext.values.payments).reduce((acc, curr) => acc + (curr.amountReceived ?? 0), 0),
    [formikContext.values.payments]
  );

  const errors = useMemo(() => {
    if (formikContext.errors.payments && Object.values(formikContext.values.payments).length === 0) {
      return (
        <View width="400px">
          <ValidationIndicator
            marginTop={"0"}
            shouldShow={true}
            displayText={formikContext.errors.payments.toString()}
          />
        </View>
      );
    }
    return null;
  }, [formikContext.errors.payments, formikContext.values.payments]);

  const verticalMargin = isReviewMode ? 8 : 20;
  const horizontalMargin = 20;

  return (
    <View
      flexDirection="row"
      justifyContent={"space-between"}
      height="auto"
      paddingRight={horizontalMargin}
      paddingLeft={horizontalMargin}
      paddingBottom={verticalMargin}
      paddingTop={verticalMargin}
    >
      <View width="auto" flexDirection="row" alignItems="center" height="100%">
        {isReviewMode ? (
          <>
            <FieldSafe
              maxWidth={200}
              component={FormikDatePicker}
              dto={BulkReceiveSessionDto}
              uniqueKey={"bulkChargeDueDate"}
              required
              hideErrorText
              label={AppStrings.Tasks.Screen.DueDate}
              name={"date"}
              noMargin
            />
            <View gap={40} flexDirection="row" width="auto">
              <div
                onClick={() => analyticsService.track(SegmentEventTypes.BULK_RP_AUTOMATICALLY_DEPOSIT_CHECKBOX_CLICKED)}
              >
                <FieldSafe
                  component={FormikCheckBox}
                  dto={BulkPaymentWrapperDto}
                  textColor="black"
                  name={"autoDeposit"}
                  labelText={AppStrings.Leases.LeaseTransaction.Payment.AutomaticDepositCreation}
                />
              </div>
              <div
                onClick={() => analyticsService.track(SegmentEventTypes.BULK_RP_SEND_PAYMENT_RECEIPT_CHECKBOX_CLICKED)}
              >
                <FieldSafe
                  component={FormikCheckBox}
                  textColor="black"
                  dto={BulkPaymentWrapperDto}
                  name={"sendPaymentReceipt"}
                  labelText={AppStrings.Leases.LeaseTransaction.Payment.SendPaymentReceipts}
                />
              </div>
            </View>
          </>
        ) : (
          errors
        )}
      </View>

      <View gap={5} width="auto" flexDirection="row" alignItems="center" height="100%">
        <Text value={AppStrings.BulkPayments.PaymentsAdded} />
        <Text bold>{paymentCount}</Text>
        <SeparationLine marginLeft={5} marginRight={5} width={1} height={20} />
        <Text value={AppStrings.BulkPayments.TotalAmountReceived} />
        <Text value={totalPaymentAmount.toString()} formatType="currency" bold />
      </View>
    </View>
  );
};
