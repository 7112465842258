import { useResponsiveHelper } from "@/contexts/responsiveContext";
import { FilterPanelSearchInput } from "../../filterPanelSearchInput";
import type { FilterPanelSearchInputProps } from "../../filterPanelSearchInput";
import { useDebouncedInputQuery } from "../hooks/useDebouncedInputQuery";
import { useSideFilterOptions } from "../hooks/useSideFilterOptions";
import { useContextSelector } from "use-context-selector";
import { FilterPanelContext } from "../context";
import * as Filters from "@/components/common/filters";
import { makeStyles } from "@material-ui/core";
import type { SidePanelProps } from "@/components/common/filters/components/sidePanel";

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  rootMobile: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 8
  },
  contentWrapper: {
    width: "100%",
    display: "flex",
    gap: 8
  },
  contentWrapperMobile: {
    width: "100%",
    display: "flex",
    gap: 8,
    flexGrow: 1
  },
  inputWrapperMobile: {
    flexGrow: 1
  }
});

export type SearchAndFiltersProps = Pick<FilterPanelSearchInputProps, "isLoading" | "placeholder"> & {
  sidePanelProps?: SidePanelProps;
};

export function SearchAndFilters({ isLoading, placeholder, sidePanelProps }: SearchAndFiltersProps) {
  const { isTabletOrMobile } = useResponsiveHelper();
  const [search, setSearch] = useDebouncedInputQuery();
  const filterOptions = useContextSelector(FilterPanelContext, (context) => context.filterOptions);
  const sidePanelFilterOptions = useSideFilterOptions(filterOptions);
  const classes = useStyles();

  if (isTabletOrMobile) {
    return (
      <Filters.Root filterOptions={sidePanelFilterOptions}>
        <div className={classes.rootMobile}>
          <div className={classes.contentWrapperMobile}>
            <div className={classes.inputWrapperMobile}>
              <FilterPanelSearchInput
                query={search}
                onChange={setSearch}
                isLoading={isLoading}
                placeholder={placeholder}
              />
            </div>
            <Filters.Toggle />
          </div>
          <Filters.Chips hiddenOnMobile={false} />
          <Filters.SidePanel {...sidePanelProps} />
        </div>
      </Filters.Root>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.contentWrapper}>
        <div>
          <FilterPanelSearchInput query={search} onChange={setSearch} isLoading={isLoading} placeholder={placeholder} />
        </div>
        <Filters.Root filterOptions={sidePanelFilterOptions}>
          <Filters.Content>
            <Filters.Toggle />
            <Filters.Chips hiddenOnMobile={false} />
            <Filters.SidePanel {...sidePanelProps} />
          </Filters.Content>
        </Filters.Root>
      </div>
    </div>
  );
}
