import React, { useEffect, useState } from "react";
import { View } from "DLUI/view";

interface ComponentProps {
  children?: React.ReactNode;
  marginTop: number;
  flex?: boolean;
  animationDelay?: number;
  alignItems?: "center" | "flex-start";
}

const AnimationDelay = 300;

const AnimateMarginTop = ({ children, marginTop, flex, animationDelay, alignItems }: ComponentProps) => {
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShouldShow(true);
    }, animationDelay || AnimationDelay);
  });
  return (
    <View width={"100%"} flex={flex ? 1 : 0} flexDirection={"row"}>
      <View showAnimation={"fade-in"} hideAnimation={"fade-out"} noWrap shouldShow={shouldShow} overflow={"hidden"}>
        <View flexDirection={"row"} height={marginTop} width={"100%"} noWrap />
      </View>
      {shouldShow ? (
        <View alignItems={alignItems} noWrap>
          {children}
        </View>
      ) : null}
    </View>
  );
};

export default AnimateMarginTop;
