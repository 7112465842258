import type { DialogProps } from "./dialogsProvider.types";
import { isEditFromDetailScreen } from "./dialogsProvider.utils";
import { DialogRoutes } from "./dialogsRoutes";
import Routes from "../../../appRouter/routes";
import AppStrings from "locale/keys";
import { OwnerContributionDialog } from "DLUI/dialogs";
import { ObjectPermission } from "@doorloop/dto";
import ExpenseFormikContextWrapper from "DLUI/dialogs/vendor/expense/expenseFormikContextWrapper";

class OwnersDialogsProvider {
  matchRoutes(pathname: string): DialogProps | undefined {
    if (pathname.indexOf(DialogRoutes.NEW_OWNER) > -1) {
      return {
        type: "newOwner",
        title: AppStrings.Owners.Screen.AddNewOwner
      };
    }
    if (
      pathname.indexOf(DialogRoutes.EDIT_OWNER) > -1 ||
      isEditFromDetailScreen("owners", 2, 2, Routes.OWNER_DETAILS_EDIT, pathname)
    ) {
      return {
        type: "editOwner",
        title: AppStrings.Owners.Screen.EditOwner
      };
    }
    if (
      pathname.indexOf(DialogRoutes.NEW_OWNER_CONTRIBUTION) > -1 ||
      pathname.indexOf(DialogRoutes.EDIT_OWNER_CONTRIBUTION) > -1
    ) {
      return {
        type: "newOwnerContribution",
        Content: OwnerContributionDialog,
        title: AppStrings.Owners.OwnerContribution.NewOwnerContribution
      };
    }

    if (pathname.indexOf(Routes.OWNERS) > -1 && pathname.indexOf(DialogRoutes.EDIT_EXPENSE) > -1) {
      return {
        type: "newVendorExpense",
        Content: ExpenseFormikContextWrapper,
        title: AppStrings.Owners.OwnerDistributions.EditOwnerDistribution,
        permission: { permission: ObjectPermission.expenses, field: "edit" }
      };
    }

    return undefined;
  }
}

export const ownersDialogsProvider = new OwnersDialogsProvider();
