import type { FC } from "react";
import React, { useEffect, useState } from "react";
import { View } from "DLUI/view";
import { Icon } from "DLUI/icon";
import { BlueInfoFullIcon, BluePlusCircleIcon, CheckbookIOLogo } from "assets/icons";
import { FormikSwitchButton, ValidationIndicator } from "DLUI/form";
import { FastField, useFormikContext } from "formik";
import AppStrings from "locale/keys";
import { AnimatableView } from "DLUI/animatableView";
import type { CheckbookSettingsDto } from "@doorloop/dto";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import Text from "DLUI/text";
import { AccountMapping, AccountMappingRow } from "screens/settings/outgoingPayments/accountMappingListItem";
import { SeparationLine } from "DLUI/separatorView";
import { LinkURLsEnum } from "DLUI/link/linkURLsEnum";
import _ from "lodash";
import BasicGrid from "DLUI/listItems/basicGrid";
import { useTranslation } from "react-i18next";
import ColorsEnum from "../../../../utils/colorsEnum";

interface Props {
  setError: (hasError: boolean) => void;
}

export const CheckbookSettingsTab: FC<Props> = ({ setError }: Props) => {
  const { t } = useTranslation();
  const formikRef = useFormikContext<CheckbookSettingsDto>();
  const values = formikRef?.values || {};
  const enabled = values.enabled;

  const [duplicateMappings, setDuplicateMappings] = useState<number[]>([]);

  const validateUserSelection = () => {
    const duplicateIndices: number[] = [];
    formikRef.values?.accountMappings?.forEach((dlId, index) => {
      const nextIndex = _.findIndex(
        formikRef.values?.accountMappings,
        (x) => x.doorloopAccountId === dlId.doorloopAccountId,
        index + 1
      );
      if (nextIndex && nextIndex !== -1 && dlId.doorloopAccountId) {
        duplicateIndices.push(index, nextIndex);
        setDuplicateMappings(duplicateIndices);
      }
    });
    setDuplicateMappings(duplicateIndices);
  };

  useEffect(() => {
    validateUserSelection();
  }, [formikRef.values?.accountMappings]);

  useEffect(() => {
    setError(!_.isEmpty(duplicateMappings));
  }, [duplicateMappings.length]);

  const unnamed = t(AppStrings.Common.OutgoingPayments.UnnamedBankAccount);
  return (
    <View flex={1} marginBottom={10}>
      <View
        backgroundColorEnum={ColorsEnum.LightBlueFadedMore}
        height={90}
        flexDirection={"row"}
        alignItems={"center"}
        borderRadius={10}
        marginBottom={20}
        noWrap
      >
        <Icon Source={BlueInfoFullIcon} marginLeft={20} marginRight={20} />
        <SeparationLine width={1} height={50} marginRight={20} color={ColorsEnum.LightBlueFaded} />
        <View flex={1}>
          <Text fontSize={14} bold value={AppStrings.Common.OutgoingPayments.DidYouKnow} marginBottom={5} />
          <Text fontSize={16} value={AppStrings.Common.OutgoingPayments.OutgoingPaymentsInfoDidYouKnow} />
        </View>
      </View>
      <SeparationLine width={"100%"} height={1} marginBottom={20} />
      <View
        backgroundColor={"white"}
        alignItems={"center"}
        paddingRight={30}
        paddingLeft={30}
        marginBottom={20}
        flexDirection={"row"}
      >
        <View flexDirection={"row"}>
          <BasicGrid sm={6}>
            <View height={70} justifyContent={"center"}>
              <Icon Source={CheckbookIOLogo} />
            </View>
          </BasicGrid>
          <BasicGrid sm={6}>
            <View height={70} justifyContent={"center"}>
              <FastField
                component={FormikSwitchButton}
                name={"enabled"}
                label={AppStrings.Common.OutgoingPayments.EnableOutgoingPaymentsWithCheckbookIO}
              />
            </View>
          </BasicGrid>
        </View>
      </View>
      <AnimatableView shouldShow={Boolean(enabled)}>
        <HorizontalSeparationLine marginBottom={20} />
        <Text fontSize={18} bold value={AppStrings.Common.OutgoingPayments.MapAccounts} marginBottom={10} />
        <Text fontSize={16} value={AppStrings.Common.OutgoingPayments.MapAccountsDesc} marginBottom={20} />
        {values.accountMappings?.map(({ checkbookBankName, uniqueIndex }, index) => (
          <AccountMapping cbAccountName={checkbookBankName || ""} key={uniqueIndex} index={index} />
        ))}
        <View onClick={() => (window.location.href = LinkURLsEnum.CB_ACCOUNT_SETTINGS)}>
          <AccountMappingRow>
            <Icon Source={BluePlusCircleIcon} marginRight={20} marginLeft={20} />
            <SeparationLine width={1} height={"80%"} marginRight={20} />
            <Text fontSize={16} value={AppStrings.Common.OutgoingPayments.CreateNewCheckbookAccount} />
          </AccountMappingRow>
        </View>
        <ValidationIndicator
          shouldShow={_.size(duplicateMappings) > 1}
          displayString={AppStrings.Common.OutgoingPayments.DuplicateAccountMappingErrorMessage}
          replaceObject={{
            account1: values.checkbookBankAccounts?.[duplicateMappings[0]]?.name || unnamed,
            account2: values.checkbookBankAccounts?.[duplicateMappings[1]]?.name || unnamed
          }}
          marginTop={"0"}
        />
      </AnimatableView>
    </View>
  );
};
