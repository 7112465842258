import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import type { FillColors } from "DLUI/icon";
import { Icon } from "DLUI/icon";
import type { RestrictedDeviceAccessTypes } from "DLUI/restrictedAccess/restrictedDeviceAccess";
import RestrictedDeviceAccess from "DLUI/restrictedAccess/restrictedDeviceAccess";
import Text from "DLUI/text";
import type { TextColor } from "DLUI/text/text";
import { View } from "DLUI/view";
import type { ViewComponentProps } from "DLUI/view/view";
import React, { useMemo } from "react";

import { RestrictedPermissionAccess } from "screens/settings/userRoles";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";

import type { SVGIconComponent } from "../../../assets";
import { ReactComponent as AddImage } from "../../../assets/icons/add.svg";
import ColorsEnum from "../../../utils/colorsEnum";
import { useShakeEffect } from "../../../hooks/useShakeEffect";

import "./styles.css";

export type AllowedColors = Exclude<ColorsEnum, ColorsEnum.DarkGreen | ColorsEnum.LightGreen>;

export interface BaseButtonComponentProps {
  backgroundColor?: AllowedColors;
  borderColor?: AllowedColors;
  onClick: () => void;
  disabled?: boolean;
  actionText: string;
  height?: number | string;
  fontSize?: number;
  textColor?: TextColor;
  marginRight?: number;
  boxShadow?: string;
  marginTop?: number;
  width?: string | number;
  minWidth?: string | number;
  leftIcon?: SVGIconComponent;
  rightIcon?: SVGIconComponent;
  iconPathColor?: FillColors;
  compactMode?: boolean;
  clearance?: AnyPermissionClearance;
  iconCircleFill?: FillColors;
  excludedDevices?: RestrictedDeviceAccessTypes[];
  bold?: boolean;
  iconSize?: number;
  borderRadius?: number;
  requestInProgress?: boolean;
  ref?: React.Ref<HTMLButtonElement>;
  fullWidth?: boolean;
  padding?: string | number;
  id?: string;
  noWrap?: ViewComponentProps["noWrap"];
  overflow?: ViewComponentProps["overflow"];
  transition?: React.CSSProperties["transition"];
  dataCy?: string;
}

export const DefaultProps = {
  height: 50,
  borderRadius: 25,
  minWidth: 120,
  fontSize: 14,
  defaultButtonMinWidth: 145,
  compactModeHeight: 40,
  compactModeWidth: 40
};

/**
 * @deprecated use {@link DLButton} instead
 * @see {@link DLButton}
 * */
const BaseButton: React.FC<BaseButtonComponentProps> = React.forwardRef(
  (
    {
      actionText,
      backgroundColor,
      onClick,
      disabled,
      height,
      fontSize,
      textColor,
      marginRight,
      boxShadow,
      borderColor,
      marginTop,
      width,
      minWidth,
      leftIcon,
      rightIcon,
      iconPathColor,
      compactMode,
      clearance,
      iconCircleFill,
      excludedDevices,
      bold = true,
      iconSize,
      borderRadius,
      requestInProgress,
      fullWidth,
      padding,
      noWrap,
      transition,
      overflow,
      id,
      dataCy
    }: BaseButtonComponentProps,
    ref
  ) => {
    const { className: shakeClassName, runShakeEffect } = useShakeEffect({ fullWidth });
    const hoverClass = useMemo(() => {
      switch (backgroundColor) {
        case ColorsEnum.Transparent:
          return "hover_transparent";
        case ColorsEnum.BrightBlue:
          return "hover_lightBlue";
        case ColorsEnum.Blue:
          return "hover_blue";
        case ColorsEnum.Black:
          return "hover_black";
        case ColorsEnum.LightGray:
        case ColorsEnum.White:
          return "hover_lightGray";
        case ColorsEnum.LightBlueFaded:
          return "hover_lightBlueFaded";
        case ColorsEnum.Red:
          return "hover_red";
        case ColorsEnum.Pink:
          return "hover_darkPink";
      }
      return "hover_darkGreen";
    }, [backgroundColor]);

    const renderButtonContent = () => {
      if (compactMode) {
        return (
          <Icon
            Source={leftIcon || rightIcon || AddImage}
            width={iconSize || 20}
            height={iconSize || 20}
            pathColor={iconPathColor}
          />
        );
      }
      return (
        <View alignItems={"center"} justifyContent={"center"} flexDirection={"row"} noWrap={noWrap} overflow={overflow}>
          {leftIcon && (
            <Icon
              marginLeft={10}
              Source={leftIcon}
              width={iconSize || 20}
              height={iconSize || 20}
              marginRight={10}
              pathColor={iconPathColor}
              circleFill={iconCircleFill}
            />
          )}
          <Text
            fontSize={fontSize || DefaultProps.fontSize}
            color={textColor}
            value={actionText}
            bold={bold}
            paddingRight={leftIcon ? 20 : 0}
          />
          {requestInProgress && <CircularProgress style={{ width: 20, height: 20, marginLeft: 20, color: "#fff" }} />}
        </View>
      );
    };

    const _onClick = () => (requestInProgress ? runShakeEffect() : onClick());

    return (
      <RestrictedDeviceAccess excludedDevices={excludedDevices} hideDisplayView>
        <RestrictedPermissionAccess clearance={clearance}>
          <Button
            ref={ref}
            classes={{
              root: clsx(["DLUI_Button", hoverClass, shakeClassName])
            }}
            data-cy={dataCy}
            disableFocusRipple
            variant="contained"
            onClick={_onClick}
            id={id}
            disabled={disabled || false}
            style={{
              height: compactMode
                ? DefaultProps.compactModeHeight
                : (typeof height === "number" ? height + "px" : height) || DefaultProps.height,
              borderRadius: borderRadius ?? DefaultProps.borderRadius,
              background: backgroundColor,
              marginRight,
              boxShadow: boxShadow || "none",
              border: `1px solid ${borderColor || ColorsEnum.Black}`,
              marginTop,
              minWidth: minWidth ?? (compactMode ? "auto" : DefaultProps.minWidth),
              width: compactMode
                ? DefaultProps.compactModeWidth
                : width
                  ? typeof width === "number"
                    ? width + "px"
                    : width
                  : "auto",
              padding
            }}
          >
            {renderButtonContent()}
          </Button>
        </RestrictedPermissionAccess>
      </RestrictedDeviceAccess>
    );
  }
);

export default BaseButton;
