"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserNotificationSettingsDto = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const base_dto_1 = require("../base.dto");
const userNotificationChannels_dto_1 = require("./userNotificationChannels.dto");
class UserNotificationSettingsDto extends base_dto_1.BaseDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.UserNotificationSettingsDto = UserNotificationSettingsDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => userNotificationChannels_dto_1.UserNotificationChannelsDto),
    __metadata("design:type", userNotificationChannels_dto_1.UserNotificationChannelsDto)
], UserNotificationSettingsDto.prototype, "merchantApplicationStatusUpdated", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => userNotificationChannels_dto_1.UserNotificationChannelsDto),
    __metadata("design:type", userNotificationChannels_dto_1.UserNotificationChannelsDto)
], UserNotificationSettingsDto.prototype, "paymentReceived", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => userNotificationChannels_dto_1.UserNotificationChannelsDto),
    __metadata("design:type", userNotificationChannels_dto_1.UserNotificationChannelsDto)
], UserNotificationSettingsDto.prototype, "paymentReturned", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => userNotificationChannels_dto_1.UserNotificationChannelsDto),
    __metadata("design:type", userNotificationChannels_dto_1.UserNotificationChannelsDto)
], UserNotificationSettingsDto.prototype, "taskAssigned", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => userNotificationChannels_dto_1.UserNotificationChannelsDto),
    __metadata("design:type", userNotificationChannels_dto_1.UserNotificationChannelsDto)
], UserNotificationSettingsDto.prototype, "taskUpdated", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => userNotificationChannels_dto_1.UserNotificationChannelsDto),
    __metadata("design:type", userNotificationChannels_dto_1.UserNotificationChannelsDto)
], UserNotificationSettingsDto.prototype, "rentalApplicationSubmitted", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => userNotificationChannels_dto_1.UserNotificationChannelsDto),
    __metadata("design:type", userNotificationChannels_dto_1.UserNotificationChannelsDto)
], UserNotificationSettingsDto.prototype, "rentalApplicationReportsReady", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => userNotificationChannels_dto_1.UserNotificationChannelsDto),
    __metadata("design:type", userNotificationChannels_dto_1.UserNotificationChannelsDto)
], UserNotificationSettingsDto.prototype, "upcomingWeekAgenda", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => userNotificationChannels_dto_1.UserNotificationChannelsDto),
    __metadata("design:type", userNotificationChannels_dto_1.UserNotificationChannelsDto)
], UserNotificationSettingsDto.prototype, "conversationIncomingMessage", void 0);
