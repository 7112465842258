import { useTypedTranslation } from "@/locale";
import type { SelectInputProps } from "DLUI/form/select/selectInput";
import SelectInput from "DLUI/form/select/selectInput";
import type { PeriodType } from "../../datePicker.types";
import { DataCy } from "@doorloop/dto";

export interface DateRangePickerProps {
  selectionEnum: Record<string, string>;
  formattedSelectedPeriod: PeriodType;
  onChange: SelectInputProps["onChange"];
}
export function DatePickerSelectInput({ selectionEnum, onChange, formattedSelectedPeriod }: DateRangePickerProps) {
  const { t } = useTypedTranslation();

  return (
    <SelectInput
      label={t("common.period")}
      uniqueKey={"datePickerFilter"}
      selectionEnum={selectionEnum}
      value={formattedSelectedPeriod ?? ""}
      onChange={onChange}
      style={{
        minWidth: 180
      }}
      dataCy={DataCy.DLUI.filters.periodDateFilter}
    />
  );
}
