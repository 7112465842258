import type { DeepKeys, LateFeeChargeDto } from "@doorloop/dto";
import { DataCy, LateFeePolicyFeeType } from "@doorloop/dto";
import { TextField } from "DLUI/form";
import { FastField } from "formik";
import React from "react";
import { useTypedTranslation } from "locale";
import type { TextFormatType } from "DLUI/text/text";

interface LateFeesAmountFieldProps {
  feeType: LateFeePolicyFeeType | undefined;
  fieldNamePrefix: string;
}

export const LateFeesAmountField = ({ feeType, fieldNamePrefix }: LateFeesAmountFieldProps) => {
  const { t } = useTypedTranslation();

  if (!feeType) {
    return null;
  }
  const isFixedAmount: boolean = feeType === LateFeePolicyFeeType.FIXED_AMOUNT;
  const fieldNameSuffix: DeepKeys<LateFeeChargeDto> = isFixedAmount ? "amount" : "percentage";
  const fieldName = `${fieldNamePrefix}.${fieldNameSuffix}`;
  const formatType: TextFormatType = isFixedAmount ? "currency" : "percent";
  const fieldLabel = isFixedAmount
    ? t("common.settings.lateFees.feeCurrency")
    : t("common.settings.lateFees.feePercent");

  return (
    <FastField
      component={TextField}
      label={fieldLabel}
      name={fieldName}
      formatType={formatType}
      decimalScale={2}
      required
      dataCy={DataCy.globalSettings.lateFees.rules.rule.amount}
    />
  );
};
