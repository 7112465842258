import type { FC } from "react";
import React, { useState } from "react";
import { View } from "DLUI/view";
import { FastField, getIn, useFormikContext } from "formik";
import { FormikSwitchButton } from "DLUI/form";
import Text from "DLUI/text";
import { AnimatedView } from "DLUI/view/animatedView";

interface Props {
  name: string;
  mainText: string;
  DescriptionView: FC;
}

export const MethodComponent: FC<Props> = ({ name, mainText, DescriptionView }: Props) => {
  const formikRef = useFormikContext();
  const [isEnabled, setIsEnabled] = useState<boolean>(Boolean(getIn(formikRef.values, name)));
  return (
    <View flexDirection={"row"} marginBottom={20} noWrap>
      <View alignItems={"flex-start"} autoWidth marginRight={8}>
        <FastField component={FormikSwitchButton} name={name} onChange={setIsEnabled} />
      </View>
      <View flex={1} alignItems={"flex-start"}>
        <View justifyContent={"center"} marginBottom={10}>
          <Text value={mainText} bold />
        </View>
        <AnimatedView shouldShow={isEnabled}>
          <DescriptionView />
        </AnimatedView>
      </View>
    </View>
  );
};
