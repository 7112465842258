import React from "react";
import { SettingsIcon } from "assets/icons";
import type { MenuItem } from "DLUI/dialogs";
import AppStrings from "locale/keys";
import TenantPortalSettingsSection from "./components/tenantPortalSettingsSection";

export const getLeaseResidentPortalFormMenuItems = (): MenuItem[] => [
  {
    title: AppStrings.Common.TenantPortalSettingsSections.Permissions,
    icon: SettingsIcon,
    contentComponent: (
      formikRef,
      onFileReceive,
      onFileDelete,
      onCompanyLogoReceive,
      onCompanyLogoDelete,
      onCoverImageFileReceive,
      onCoverImageFileDelete,
      merchantAccount,
      transunionEligibilityMessage,
      transunionFee,
      hasTenantsAutoPayments
    ) => (
      <TenantPortalSettingsSection
        formikRef={formikRef}
        hasTenantsAutoPayments={hasTenantsAutoPayments || { hasAutoPayments: false }}
      />
    )
  }
];
