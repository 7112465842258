import React from "react";
import { BackgroundImageView, View } from "DLUI/view";
import { useResponsiveHelper } from "contexts/responsiveContext";
import Text from "DLUI/text";
import { useTypedTranslation } from "locale";
import ColorsEnum from "utils/colorsEnum";
import { ElementPrefixedText } from "screens/incomingPayments/tabs/merchantAccountSetup/stripeOnbording/components/elementPrefixedText";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { Close } from "@/assets";
import { AcceptTOS } from "screens/authScreen/acceptInvite/acceptTOS";
import { RapidRentUrl } from "utils/sharedVariables";
import { useSelector } from "react-redux";
import type { IRootState } from "store";
import type { BankAccountDto, GetStripeAccountLinkRequestDto } from "@doorloop/dto";
import { DataCy } from "@doorloop/dto";
import { StripeCountryAndCurrencyBullet } from "screens/incomingPayments/tabs/merchantAccountSetup/stripeOnbording/components/stripeCountryAndCurrencyBullet";
import { DLButtonVariantsEnum } from "DLUI/button/dlButton";
import { BankAccountNumber } from "screens/incomingPayments/tabs/merchantAccountSetup/stripeOnbording/components/bankAccountNumber";
import { IconButton } from "DLUI/form";
import type { FormikProps } from "formik";
import type { StripeConnectPoints } from "screens/incomingPayments/tabs/merchantAccountSetup/stripeOnboarding.types";
import { TosRequiredTooltip } from "./TosRequiredTooltip";

interface StripeApplyNowProps {
  onBackdropClick: () => void;
  onSubmit: () => void;
  isLoading: boolean;
  account: BankAccountDto;
  points?: StripeConnectPoints[];
  mode: "apply" | "update";
  formik?: FormikProps<GetStripeAccountLinkRequestDto>;
}

const INCOMING_PAYMENTS_IMG = "/images/incoming-payments-summary-empty-state.svg";
const POWERED_BY_STRIPE_IMG = "/images/powered-by-stripe.svg";

export const StripeConnectDialogContent = ({
  onBackdropClick,
  onSubmit,
  isLoading,
  account,
  mode,
  points,
  formik // passing as prop because component is out of Formik context when in update mode
}: StripeApplyNowProps) => {
  const { isMobile } = useResponsiveHelper();
  const { currentLoginResponse } = useSelector((state: IRootState) => state.auth);
  const { t } = useTypedTranslation();

  const isApplyMode = mode === "apply";

  const handleAcceptRapidRentTOSClick = (checked: boolean) => {
    formik?.setFieldValue("acceptedOnRapidRentTOS", checked ? new Date() : undefined);
  };

  return (
    <View noWrap fullWidth flexDirection={isMobile ? "column" : "row"} height={"100%"} style={{ position: "relative" }}>
      <View style={{ position: "absolute", top: 0, right: 0, zIndex: 10 }}>
        <IconButton
          Icon={Close}
          width={30}
          height={30}
          onClick={onBackdropClick}
          dataCy={DataCy.dialog.xButton}
          alignSelf={"flex-end"}
          marginTop={22}
          marginRight={22}
          removePadding
        />
      </View>
      <View
        noWrap
        width={isMobile ? "100%" : "50%"}
        paddingBottom={20}
        paddingTop={20}
        paddingLeft={20}
        paddingRight={20}
        backgroundColor={"var(--background-neutral-light)"}
        alignSelf={"center"}
        style={{
          position: "relative"
        }}
      >
        <BackgroundImageView
          height={250}
          publicFileName={`url(${INCOMING_PAYMENTS_IMG})`}
          backgroundSize={"contain"}
          width={"100%"}
        />
        <View gap={10} noWrap alignItems={"center"} marginTop={10}>
          <Text align={"center"} fontSize={18} bold value={t("common.incomingPayments.emptyState.titleLeftSide")} />
          <Text
            align={"center"}
            fontSize={14}
            colorEnum={ColorsEnum.SecondaryGrey}
            value={t("common.incomingPayments.emptyState.subtitleLeftSide")}
            marginBottom={10}
          />
          <img alt={"Stripe"} src={POWERED_BY_STRIPE_IMG} height={20} width={"auto"} />
        </View>
      </View>

      <View
        noWrap
        width={isMobile ? "100%" : "50%"}
        height={isMobile ? "auto" : "100%"}
        flex={isMobile ? 1 : "none"}
        backgroundColor={"white"}
        justifyContent={isMobile ? "flex-start" : "center"}
      >
        <View
          paddingTop={20}
          paddingRight={20}
          paddingLeft={20}
          paddingBottom={20}
          noWrap
          marginBottom={isMobile ? 60 : 0}
        >
          <View noWrap gap={16} height={"100%"} flex={"none"}>
            <View noWrap gap={5}>
              <Text fontSize={18} bold value={t("common.incomingPayments.stripeConnectDialog.title")} />
              <Text
                fontSize={14}
                colorEnum={ColorsEnum.SecondaryGrey}
                value={t("common.incomingPayments.stripeConnectDialog.subtitle")}
              />
            </View>

            <View noWrap>
              <Text
                fontSize={12}
                colorEnum={ColorsEnum.LightBlackFaded}
                value={t("common.incomingPayments.stripeConnectDialog.account")}
                marginBottom={5}
                bold
              />
              <View
                noWrap
                gap={8}
                paddingTop={5}
                paddingBottom={5}
                paddingLeft={5}
                paddingRight={5}
                alignItems={"center"}
                border={`1px solid ${ColorsEnum.LightBlackFadedMore}`}
                borderRadius={4}
              >
                <BankAccountNumber
                  accountName={account.name}
                  last4digits={account.merchantAccountExternalAccountRequiredLast4Digits}
                  iconProps={{
                    pathColor: "light-gray"
                  }}
                  textProps={{
                    accountText: {
                      fontSize: 14,
                      colorEnum: ColorsEnum.LightBlack
                    },
                    last4digitsText: {
                      fontSize: 10,
                      colorEnum: ColorsEnum.LightBlack
                    }
                  }}
                  containerStyle={{ padding: 0 }}
                />
              </View>
            </View>

            <View noWrap gap={10}>
              <Text
                colorEnum={ColorsEnum.LightBlackFaded}
                bold
                fontSize={12}
                value={t("common.incomingPayments.stripeConnectDialog.beforeWeStart")}
              />
              {points?.map((point, index) => (
                <ElementPrefixedText
                  key={index}
                  text={point.translation ? t(point.translation, { ...point.translationOptions }) : point.component}
                  iconSrc={point.iconSrc}
                  iconProps={{ size: 14 }}
                  textProps={{
                    fullWidth: false,
                    fontSize: 14
                  }}
                />
              ))}
              <StripeCountryAndCurrencyBullet />
            </View>
            {isApplyMode && (
              <View noWrap paddingTop={10} paddingBottom={10} gap={10} fullWidth>
                {!currentLoginResponse?.acceptedOnTOS && <AcceptTOS style={{ maxWidth: "100%" }} />}
                <AcceptTOS
                  id={"acceptRapidRentTOS"}
                  onClick={handleAcceptRapidRentTOSClick}
                  checked={Boolean(formik?.values.acceptedOnRapidRentTOS)}
                  tosProps={{ tosUrl: RapidRentUrl, tosLinkTranslation: "common.rapidRent" }}
                  privacyPolicyProps={{ privacyPolicyUrl: "" }}
                  i18nKey={"common.rapidRentTermsOfService"}
                  style={{ maxWidth: "100%" }}
                />
              </View>
            )}
          </View>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 0,
            borderTop: isMobile ? `1px solid ${ColorsEnum.LightBlackFadedMore}` : ""
          }}
          noWrap
        >
          <FormActionButtons
            propsMainButton={{
              type: "cta",
              props: {
                onClick: onSubmit,
                actionText: isApplyMode ? t("common.applyNow") : t("accounts.stripeUpdateInformation"),
                isLoading,
                disabled: isApplyMode
                  ? (!currentLoginResponse?.acceptedOnTOS && !formik?.values.acceptedOnTOS) ||
                    !formik?.values.acceptedOnRapidRentTOS
                  : false
              },
              Wrapper: isApplyMode ? (props) => <TosRequiredTooltip {...props} /> : undefined
            }}
            propsSubButton={{
              actionText: t("common.cancel"),
              onClick: onBackdropClick,
              variant: DLButtonVariantsEnum.TEXT
            }}
          />
        </View>
      </View>
    </View>
  );
};
