import { DotsIcon, DuplicateIconAlt, EditMui, TrashIcon } from "@/assets";
import { Popover, type PopoverItem } from "DLUI/popover";
import React, { useMemo } from "react";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { DataCy, ObjectPermission } from "@doorloop/dto";
import { useTypedTranslation } from "locale";

interface FeeItemPopoverProps {
  handleEdit: () => void;
  handleDelete: () => void;
  handleDuplicate: () => void;
}

export const FeeItemPopover = ({ handleDelete, handleDuplicate, handleEdit }: FeeItemPopoverProps) => {
  const { isMobile } = useResponsiveHelper();
  const { t } = useTypedTranslation();

  const popoverTopSectionItems: PopoverItem[] = useMemo(() => {
    const editMenuItem: PopoverItem = {
      Icon: EditMui,
      title: t("common.edit"),
      onClick: handleEdit,
      dataCy: DataCy.DLUI.listItem.listItemEdit
    };

    return [
      ...(isMobile ? [editMenuItem] : []),
      {
        Icon: DuplicateIconAlt,
        title: t("common.duplicate"),
        onClick: handleDuplicate,
        dataCy: DataCy.DLUI.listItem.listItemDuplicate
      }
    ];
  }, [isMobile, handleDuplicate]);

  const popoverBottomSectionItems: PopoverItem[] = useMemo(
    () => [
      {
        Icon: TrashIcon,
        title: t("common.delete"),
        onClick: handleDelete,
        iconPathColor: "error",
        textColor: "error",
        clearance: { permission: ObjectPermission.properties, field: "delete" },
        dataCy: DataCy.DLUI.listItem.listItemDelete
      }
    ],
    [handleDelete]
  );

  return (
    <Popover
      topSectionItems={popoverTopSectionItems}
      bottomSectionItems={popoverBottomSectionItems}
      Icon={DotsIcon}
      width={200}
      iconContainerSize={28}
    />
  );
};
