import type { TabItem } from "DLUI/tabs";
import type { TranslateFn } from "locale";
import { OutgoingPaymentsTransactionsTab } from "screens/outgoingPayments/outgoingPaymentsTransactions/outgoingPaymentsTransactionsTab";
import { OutgoingPaymentsAccountSetupTab } from "screens/outgoingPayments/outgoingPaymentAccountSetup/outgoingPaymentsAccountSetupTab";

interface OutgoingPaymentsScreenTabItemsParams {
  t: TranslateFn;
}

export const getOutgoingPaymentsScreenTabItems = ({ t }: OutgoingPaymentsScreenTabItemsParams): TabItem[] => [
  {
    label: t("drawerItems.transactions"),
    component: OutgoingPaymentsTransactionsTab
  },
  {
    label: t("common.outgoingPayments.paymentAccountSetup"),
    component: OutgoingPaymentsAccountSetupTab
  }
];
