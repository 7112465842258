import type { GetDepositDetailReportQuery } from "@doorloop/dto";
import { DepositAccountTypes } from "@doorloop/dto";
import { PaymentMethod } from "@doorloop/dto";
import { unitsApi } from "api/unitsApi";
import { propertiesApi } from "api/propertiesApi";
import AppStrings from "locale/keys";
import { ownersApi } from "api/ownersApi";
import { propertyGroupsApi } from "api/propertyGroupsApi";
import { vendorsApi } from "api/vendorsApi";
import { leasesApi } from "api/leasesApi";
import { accountsApi } from "api/accounts";
import { tenantsApi } from "api/tenantsApi";
import type { FilterOption } from "@/components/DLUI/screen/filterPanel/filterInputs/types";

export const depositDetailFilterOptions: Array<FilterOption<GetDepositDetailReportQuery>> = [
  {
    label: AppStrings.Reports.TransactionsReport.ReportPeriod,
    type: "dateRangePicker",
    period: "all-time",
    startDateFieldKey: "filter_date_from",
    endDateFieldKey: "filter_date_to",
    alwaysVisible: true
  },
  {
    name: AppStrings.Reports.DepositDetail.DepositAccount,
    apiHandler: accountsApi,
    queryParams: {
      filter_types: Object.values(DepositAccountTypes)
    },
    displayNameKey: "fullyQualifiedName",
    filterFieldName: "filter_account",
    filterFieldValue: "id",
    type: "remoteFiltering"
  },
  {
    name: AppStrings.Common.ListHeader.Category,
    type: "remoteFiltering",
    apiHandler: accountsApi,
    displayNameKey: "fullyQualifiedName",
    filterFieldName: "filter_category",
    filterFieldValue: "id"
  },
  {
    type: "textFieldFiltering",
    alwaysVisible: true,
    label: AppStrings.Common.ListHeader.Reference,
    name: AppStrings.Common.ListHeader.Reference,
    filterFieldName: "filter_reference",
    formatType: "text"
  },
  {
    name: AppStrings.Common.FilterOptions.Property,
    apiHandler: propertiesApi,
    displayNameKey: "name",
    filterFieldName: "filter_property",
    filterFieldValue: "id",
    type: "remoteFiltering"
  },
  {
    name: AppStrings.Units.ListHeader.Unit,
    apiHandler: unitsApi,
    displayNameKey: "name",
    filterFieldName: "filter_unit",
    filterFieldValue: "id",
    values: [],
    type: "remoteFiltering"
  },
  {
    name: AppStrings.Leases.ListHeader.Lease,
    apiHandler: leasesApi,
    displayNameKey: "name",
    filterFieldName: "filter_lease",
    filterFieldValue: "id",
    type: "remoteFiltering",
    hiddenForUserTypes: ["HOAUser"]
  },
  {
    name: AppStrings.Common.FilterOptions.Portfolio,
    apiHandler: propertyGroupsApi,
    displayNameKey: "name",
    filterFieldName: "filter_propertyGroup",
    filterFieldValue: "id",
    type: "remoteFiltering"
  },
  {
    type: "remoteFiltering",
    name: AppStrings.Common.FilterOptions.Owner,
    apiHandler: ownersApi,
    displayNameKey: "name",
    filterFieldName: "filter_owner",
    filterFieldValue: "id",
    hiddenForUserTypes: ["HOAUser"]
  },

  {
    type: "remoteFiltering",
    name: AppStrings.Bills.PayBills.Screen.Vendor,
    apiHandler: vendorsApi,
    displayNameKey: "name",
    filterFieldName: "filter_vendor",
    filterFieldValue: "id"
  },
  {
    type: "remoteFiltering",
    name: AppStrings.Tenants.Screen.Tenant,
    apiHandler: tenantsApi,
    displayNameKey: "name",
    filterFieldName: "filter_tenant",
    filterFieldValue: "id"
  },
  {
    type: "staticFiltering",
    name: AppStrings.Common.FilterOptions.PaymentMethod,
    alwaysVisible: false,
    marginTop: 20,
    values: [
      {
        displayName: AppStrings.Common.Enums.PaymentMethod.CASH,
        value: PaymentMethod.CASH,
        filterFieldName: "filter_paymentMethod"
      },
      {
        displayName: AppStrings.Common.Enums.PaymentMethod.CHECK,
        value: PaymentMethod.CHECK,
        filterFieldName: "filter_paymentMethod"
      },
      {
        displayName: AppStrings.Common.Enums.PaymentMethod.EPAY,
        value: PaymentMethod.EPAY,
        filterFieldName: "filter_paymentMethod"
      },
      {
        displayName: AppStrings.Common.Enums.PaymentMethod.CREDIT_CARD,
        value: PaymentMethod.CREDIT_CARD,
        filterFieldName: "filter_paymentMethod"
      },
      {
        displayName: AppStrings.Common.Enums.PaymentMethod.CASHIERS_CHECK,
        value: PaymentMethod.CASHIERS_CHECK,
        filterFieldName: "filter_paymentMethod"
      },
      {
        displayName: AppStrings.Common.Enums.PaymentMethod.MONEY_ORDER,
        value: PaymentMethod.MONEY_ORDER,
        filterFieldName: "filter_paymentMethod"
      },
      {
        displayName: AppStrings.Common.Enums.PaymentMethod.EFT,
        value: PaymentMethod.EFT,
        filterFieldName: "filter_paymentMethod"
      },
      {
        displayName: AppStrings.Common.Enums.PaymentMethod.DEBIT_CARD,
        value: PaymentMethod.DEBIT_CARD,
        filterFieldName: "filter_paymentMethod"
      },
      {
        displayName: AppStrings.Common.Enums.PaymentMethod.OTHER,
        value: PaymentMethod.OTHER,
        filterFieldName: "filter_paymentMethod"
      }
    ]
  }
];
