import type { UploadVisualContentBaseProps } from "DLUI/button/uploadVisualContent";
import { UploadVisualContent } from "DLUI/button/uploadVisualContent";
import { BlankImageIcon } from "@/assets";
import type { Theme } from "styles/defaultTheme";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => {
  return {
    imageDimensions: {
      height: 50,
      width: 100,
      [theme.breakpoints.down("sm")]: {
        width: 134
      }
    }
  };
});

export const UploadImage = (props: UploadVisualContentBaseProps) => {
  const classes = useStyles();

  return (
    <UploadVisualContent
      {...props}
      buttonText={"common.dropzone.uploadImage"}
      FallbackIcon={BlankImageIcon}
      className={classes.imageDimensions}
    />
  );
};
