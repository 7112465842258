"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VendorCreditDto = void 0;
const vendorTransaction_dto_1 = require("../shared/vendorTransaction.dto");
class VendorCreditDto extends vendorTransaction_dto_1.VendorTransactionDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.VendorCreditDto = VendorCreditDto;
