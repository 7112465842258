"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalApplicationPostChargeOptions = exports.RentalApplicationChargeOptions = void 0;
var RentalApplicationChargeOptions;
(function (RentalApplicationChargeOptions) {
    RentalApplicationChargeOptions["CHARGE_APPLICANT"] = "chargeApplicant";
    RentalApplicationChargeOptions["CHARGE_USER"] = "chargeUser";
    RentalApplicationChargeOptions["CHARGE_CUSTOM_FEE"] = "chargeCustomFee";
})(RentalApplicationChargeOptions || (exports.RentalApplicationChargeOptions = RentalApplicationChargeOptions = {}));
var RentalApplicationPostChargeOptions;
(function (RentalApplicationPostChargeOptions) {
    RentalApplicationPostChargeOptions["CHARGE_APPLICANT"] = "chargeApplicant";
    RentalApplicationPostChargeOptions["CHARGE_CUSTOM_FEE"] = "chargeCustomFee";
})(RentalApplicationPostChargeOptions || (exports.RentalApplicationPostChargeOptions = RentalApplicationPostChargeOptions = {}));
