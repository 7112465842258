import React, { type ReactNode } from "react";
import { View } from "DLUI/view";
import { DialogTitle } from "DLUI/screen";
import { IconButton } from "DLUI/form";
import { Close } from "assets/icons";
import { HelpPanel } from "DLUI/screen/helpPanel/helpPanel";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import ExportPdfButton from "DLUI/exportPdfButton/exportPdfButton";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { SeparationLine } from "DLUI/separatorView";
import { DataCy } from "@doorloop/dto";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { useTheme } from "@material-ui/core/styles";

interface ComponentProps {
  title: string;
  titleActions?: ReactNode;
  onClose: () => void;
  hideCloseButton?: boolean;
  showPrintButton?: boolean;
  showExportToPdfButton?: boolean;
  renderActionButtons?: () => ReactNode;
  exportFileName?: string;
  onExportPdfButtonPress?: () => void;
  exportPdfInProgress?: boolean;
  helpPanel?: HelpPanelProps;
}

const DefaultExportFileName = "DL_PDF";

const DialogHeader: React.FC<ComponentProps> = ({
  title,
  titleActions,
  onClose,
  hideCloseButton,
  showExportToPdfButton,
  renderActionButtons,
  exportFileName,
  onExportPdfButtonPress,
  exportPdfInProgress,
  helpPanel
}: ComponentProps) => {
  const { screenContainerPadding } = useResponsiveHelper();
  const theme = useTheme();
  return (
    <View paddingLeft={screenContainerPadding} paddingRight={screenContainerPadding} noWrap>
      <View paddingTop={15} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <View flexDirection={"row"} width={"auto"} alignItems={"center"} gap={10}>
          {title && <DialogTitle title={title} textTransform={"uppercase"} />}
          {titleActions && (
            <>
              <SeparationLine height={30} width={2} />
              {titleActions}
            </>
          )}
        </View>
        <View
          alignItems={"center"}
          justifyContent={"flex-end"}
          flexDirection={"row"}
          width={"auto"}
          marginLeft={"auto"}
        >
          {showExportToPdfButton && (
            <ExportPdfButton
              width={25}
              height={25}
              fileName={exportFileName || DefaultExportFileName}
              onExportPdfButtonPress={onExportPdfButtonPress}
              exportPdfInProgress={exportPdfInProgress}
            />
          )}
          {renderActionButtons?.()}
          {!hideCloseButton && (
            <IconButton
              Icon={Close}
              pathColor={"current-color"}
              style={{ color: theme.newPalette.text.secondary, backgroundColor: "transparent", padding: 0 }}
              size={28}
              onClick={onClose}
              dataCy={DataCy.dialog.xButton}
            />
          )}
        </View>
      </View>
      {helpPanel && (
        <>
          <HelpPanel actionItems={helpPanel.actionItems} description={helpPanel.description} />
          <HorizontalSeparationLine marginTop={20} />
        </>
      )}
    </View>
  );
};

export default DialogHeader;
