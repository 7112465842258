import type { ApiResult, BulkExecutionRequestDto } from "@doorloop/dto";
import { ServerRoutes } from "@doorloop/dto";

import { apiHelper } from "api/apiHelper";

class BulkExecutionApi {
  async requestBulkExecution(dto: BulkExecutionRequestDto): Promise<ApiResult<unknown>> {
    return await apiHelper.axiosPost({
      url: ServerRoutes.BULK_EXECUTION,
      data: dto,
      toasts: {
        severity: "success",
        translationKey: `bulkActions.requestBulkExecutionSuccess.${dto.requestType}`
      },
      shouldShowErrorToast: true
    });
  }
}

export const bulkExecutionApi = new BulkExecutionApi();
