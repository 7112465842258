import Routes from "./routes";
import { lazy } from "react";
import { LazyRoute } from "./lazyRoute";
import { PageRouter } from "./pageRouter";

const OwnerPortalHomeScreen = lazy(() => import("screens/ownerPortal/home/homeScreen"));
const OwnerPortalReportsRouter = lazy(() => import("./ownerPortalReportsRouter"));
const OwnerPortalReportsScreen = lazy(() => import("screens/ownerPortal/reports/reportsScreen"));
const OwnerPortalRequestsScreen = lazy(() => import("@/components/screens/ownerPortal/requests/requestsScreen"));
const OwnerPortalTransactionsScreen = lazy(() => import("screens/ownerPortal/transactions/transactionsScreen"));

export const OwnerPortalRouter = () => (
  <PageRouter>
    <LazyRoute path={Routes.OWNER_PORTAL_HOME} render={OwnerPortalHomeScreen} />

    <LazyRoute path={Routes.OWNER_PORTAL_TRANSACTIONS} render={OwnerPortalTransactionsScreen} />

    <LazyRoute
      path={[
        Routes.OWNER_PORTAL_REQUESTS,
        Routes.OWNER_PORTAL_REQUESTS_NEW,
        Routes.OWNER_PORTAL_REQUESTS_DELETE_CONFIRM,
        Routes.OWNER_PORTAL_REQUESTS_VIEW,
        Routes.OWNER_PORTAL_REQUESTS_EDIT_LOGIN_AND_PASSWORD
      ]}
      render={OwnerPortalRequestsScreen}
      exact
    />
    <LazyRoute path={Routes.OWNER_PORTAL_REQUESTS} render={OwnerPortalRequestsScreen} />

    <LazyRoute
      path={[
        Routes.OWNER_PORTAL_REPORTS,
        Routes.OWNER_PORTAL_REPORTS_CONTACT_INFO,
        Routes.OWNER_PORTAL_REPORTS_EDIT_LOGIN_AND_PASSWORD
      ]}
      render={OwnerPortalReportsScreen}
      exact
    />

    <LazyRoute path={Routes.OWNER_PORTAL_REPORTS} render={OwnerPortalReportsRouter} />
    <LazyRoute path={Routes.OWNER_PORTAL_REPORTS} render={OwnerPortalReportsScreen} />
  </PageRouter>
);
