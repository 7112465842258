import React, { useEffect, useMemo, useState } from "react";

import _ from "lodash";
import { FastField, getIn, useFormikContext } from "formik";
import { useParams } from "react-router-dom";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { RadioGroup } from "DLUI/form";
import { settingsApi } from "api/settingsApi";
import { useEffectAsync } from "hooks/useEffectAsync";
import { PortalContactInfoDto } from "@doorloop/dto";
import { TenantPortalContactInfoForm } from "DLUI/settings/portal/tenant/tenantPortalContactInfoForm";
import { useCurrentPhoneNumber } from "screens/communicationsCenter/shared/useCurrentPhoneNumber";
import { useResponsiveHelper } from "contexts/responsiveContext";

type ContactInfoSelectionType = "companyDefault" | "specifyContactInfo";

const ContactInfo: React.FC = () => {
  const formik = useFormikContext();
  const { isMobile } = useResponsiveHelper();
  const { propertyId } = useParams<any>();
  const { currentPhoneNumber } = useCurrentPhoneNumber();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const formikFieldName = useMemo(() => (propertyId ? "settings.tenantPortal.contactInfo" : "contactInfo"), []);
  const [companyContactInfo, setCompanyContactInfo] = useState<PortalContactInfoDto>();
  const [customContactInfo, setCustomContactInfo] = useState<PortalContactInfoDto>();
  const contactInfoSelectionDefaultValue = useMemo(() => {
    const fieldValue = getIn(formik.values, formikFieldName);
    const emptyObject = _.values(fieldValue).every((x) => x === undefined);
    if (emptyObject) {
      return "companyDefault";
    }

    const info = new PortalContactInfoDto(fieldValue);

    // In order to not break existing behavior we set these as empty strings here and not in the dto
    if (_.isUndefined(info.phone)) {
      info.phone = "";
    }
    if (_.isUndefined(info.email)) {
      info.email = "";
    }
    if (_.isUndefined(info.companyName)) {
      info.companyName = "";
    }
    if (info.address && _.isUndefined(info.address.street2)) {
      info.address.street2 = "";
    }
    setCustomContactInfo(info);
    return "specifyContactInfo";
  }, [companyContactInfo]);

  useEffectAsync(async () => {
    if (currentPhoneNumber) {
      formik.setFieldValue(`${formikFieldName}.phone`, currentPhoneNumber.phoneNumber);
    }
  }, [currentPhoneNumber]);

  const { radioGroupItemHideText, radioGroupItemShowText } = useMemo(() => {
    let radioGroupItemHideText = AppStrings.Common.HideTenantPortalContactInfo;
    let radioGroupItemShowText = AppStrings.Common.SpecifyTenantPortalContactInfo;
    if (propertyId) {
      radioGroupItemShowText = AppStrings.Common.SpecifyPortalContactInfoForProperty;
      radioGroupItemHideText = AppStrings.Common.HidePortalContactInfoForProperty;
    }

    return {
      radioGroupItemHideText,
      radioGroupItemShowText
    };
  }, []);

  const [contactInfoSelectionType, setContactInfoSelectionType] = useState<ContactInfoSelectionType>(
    contactInfoSelectionDefaultValue
  );

  // handle clearing the email value when toggle is off
  // we do it here since the dto is used elsewhere and will break existing behavior
  const isEmailHidden = getIn(formik.values, `${formikFieldName}.isEmailShownInPortal`);
  useEffect(() => {
    if (contactInfoSelectionType === "specifyContactInfo" && !isEmailHidden) {
      formik.setFieldValue(`${formikFieldName}.email`, "");
    }
  }, [isEmailHidden]);

  useEffectAsync(async () => {
    await handleChange();
  }, [contactInfoSelectionType, currentPhoneNumber]);

  const handleChange = async () => {
    if (contactInfoSelectionType === "companyDefault") {
      if (!companyContactInfo) {
        await fetchCompanyData();
      } else {
        formik.setFieldValue(formikFieldName, companyContactInfo);
      }
      formik.setFieldValue("contactInfo.selectionType", "companyDefault");
      formik.setFieldValue(`${formikFieldName}.showAll`, true);
      formik.setFieldValue(`${formikFieldName}.isEmailShownInPortal`, true);
      formik.setFieldValue(`${formikFieldName}.isPhoneShownInPortal`, true);
      formik.setFieldValue(`${formikFieldName}.isAddressShownInPortal`, true);
    } else if (contactInfoSelectionType === "specifyContactInfo") {
      if (customContactInfo) {
        formik.setFieldValue(formikFieldName, customContactInfo);
        if (currentPhoneNumber?.phoneNumber) {
          formik.setFieldValue(`${formikFieldName}.phone`, currentPhoneNumber.phoneNumber);
        }
      }
      formik.setFieldValue(`${formikFieldName}.showAll`, false);
      formik.setFieldValue("contactInfo.selectionType", undefined);
    }
  };

  const fetchCompanyData = async () => {
    setIsLoading(true);
    const result = await settingsApi.getCompanyInformation();

    if (result?.data) {
      const mappedData = {
        address: result.data.billingAddress,
        email: result.data.billingEmail,
        phone: result.data.companyPhone,
        website: result.data.companyWebsite,
        companyName: result.data.companyName
      };
      if (currentPhoneNumber?.phoneNumber && currentPhoneNumber.status === "ACTIVE") {
        mappedData.phone = currentPhoneNumber.phoneNumber;
      }

      setCompanyContactInfo(mappedData);
      formik.setFieldValue(formikFieldName, mappedData);
      setIsLoading(false);
    }
  };

  const didChangeContactInfoSelection = (selectedValue: ContactInfoSelectionType): void => {
    setContactInfoSelectionType(selectedValue);
    formik.setFieldValue(`${formikFieldName}.selectionType`, selectedValue);
  };

  const renderSpecifyRadioButtons = () => (
    <FastField
      uniqueKey={"LF"}
      component={RadioGroup}
      name={"automaticLateFees"}
      defaultValue={contactInfoSelectionType}
      didChangeSelection={didChangeContactInfoSelection}
      radioGroupItems={[
        {
          label: () => (
            <Text
              value={radioGroupItemHideText}
              bold={true}
              fontSize={16}
              marginBottom={5}
              maxWidth={isMobile ? "90%" : "95%"}
            />
          ),
          value: "companyDefault"
        },
        {
          label: () => (
            <Text
              value={radioGroupItemShowText}
              bold={true}
              fontSize={16}
              maxWidth={isMobile ? "90%" : "95%"}
              style={{ flex: "auto" }}
            />
          ),
          value: "specifyContactInfo"
        }
      ]}
      marginTop={10}
    />
  );

  return (
    <TenantPortalContactInfoForm
      textTitle={AppStrings.Common.TenantPortalContactInfoDescription}
      formikFieldNamePrefix={formikFieldName}
      allowEdit={contactInfoSelectionType === "specifyContactInfo"}
      SpecifyRadioButtons={renderSpecifyRadioButtons}
      isLoading={isLoading}
      doorLoopPhoneNumber={currentPhoneNumber}
    />
  );
};

export default ContactInfo;
