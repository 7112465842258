import type { DialogProps } from "./dialogsProvider.types";
import { DialogRoutes } from "./dialogsRoutes";
import { PrintSetupDialog } from "screens/printChecks/dialogs/printSetup/printSetupDialog";
import AppStrings from "locale/keys";
import { ObjectPermission } from "@doorloop/dto";
import ChecksReviewPrintDialog from "screens/printChecks/dialogs/checksReview/checksReviewPrintDialog";
import RemoveCheckDialog from "screens/printChecks/dialogs/removeFromQueue/removeCheckDialog";
import ExpenseFormikContextWrapper from "DLUI/dialogs/vendor/expense/expenseFormikContextWrapper";

class PrintChecksDilogsProvider {
  matchRoutes(pathname: string): DialogProps | undefined {
    if (pathname.indexOf(DialogRoutes.PRINT_SETUP) > -1) {
      return {
        type: "printSetup",
        Content: PrintSetupDialog
      };
    }
    if (pathname.indexOf(DialogRoutes.PRINT_REMOVE_CHECK) > -1) {
      return {
        type: "removeCheck",
        Content: RemoveCheckDialog
      };
    }
    if (pathname.indexOf(DialogRoutes.PRINT_CHECKS_REVIEW_PRINT) > -1) {
      return {
        type: "printReview",
        Content: ChecksReviewPrintDialog
      };
    }
    if (pathname.indexOf(DialogRoutes.NEW_VENDOR_EXPENSE) > -1) {
      return {
        type: "newVendorExpense",
        Content: ExpenseFormikContextWrapper,
        title: AppStrings.Vendors.VendorDetails.NewExpense,
        permission: { permission: ObjectPermission.expenses, field: "create" }
      };
    }
    return undefined;
  }
}

export const printChecksDilogsProvider = new PrintChecksDilogsProvider();
