import { useMemo } from "react";
import type { FilterOption } from "../../../filterPanel/filterInputs/types";
import { useResponsiveHelper } from "@/contexts/responsiveContext";

export function useInlineFilterOptions<TQuery>(filterOptions: Array<FilterOption<TQuery>>) {
  const { isMobile } = useResponsiveHelper();

  return useMemo(
    () => (isMobile ? [] : filterOptions.filter((option) => Boolean(option.alwaysVisible))),
    [filterOptions, isMobile]
  );
}
