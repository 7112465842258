"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnitEviction = exports.UnitOccupied = exports.UnitsListed = void 0;
var UnitsListed;
(function (UnitsListed) {
    UnitsListed["LISTED"] = "true";
    UnitsListed["NOT_LISTED"] = "false";
})(UnitsListed || (exports.UnitsListed = UnitsListed = {}));
var UnitOccupied;
(function (UnitOccupied) {
    UnitOccupied["UNIT_OCCUPIED"] = "true";
    UnitOccupied["UNIT_AVAILABLE"] = "false";
})(UnitOccupied || (exports.UnitOccupied = UnitOccupied = {}));
var UnitEviction;
(function (UnitEviction) {
    UnitEviction["UNIT_IN_EVICTION"] = "true";
    UnitEviction["UNIT_IN_CONTRACT"] = "false";
})(UnitEviction || (exports.UnitEviction = UnitEviction = {}));
