import { actionsPanelIconsTypesEnums, actionsPanelTypesEnums } from "DLUI/form/fieldsActionsPanel/actionPanels/enums";
import type { ActionPanelItemInterface } from "DLUI/form/fieldsActionsPanel/actionPanels/actionPanelItem";
import { useMemo, useState } from "react";

export interface ActionsPanelInterface {
  type: actionsPanelTypesEnums;
  actions?: ActionPanelItemInterface[];
}

const toggleMode = (val: string, setter: (val: string) => void) => setter(val);

const useActionsPanels = ({ type, actions, clearances }) => {
  const [switchActionModeAnimation, setSwitchActionModeAnimation] = useState("show");
  const [switchFieldModeAnimation, setSwitchFieldModeAnimation] = useState("show");
  const [actionsPanelType, setActionPanelType] = useState(type);

  const confirmActionPanel: ActionsPanelInterface = useMemo(
    () =>
      actions.confirmAction && {
        type: actionsPanelTypesEnums.EDIT_ACTIONS_PANEL,
        actions: [
          {
            type: actionsPanelIconsTypesEnums.CONFIRM_ACTION_PANEL,
            onClick: actions.confirmAction,
            useSwitchAnimation: true,
            isAsync: true,
            clearance: clearances?.edit
          },
          {
            type: actionsPanelIconsTypesEnums.CLOSE_ACTION_PANEL,
            useSwitchAnimation: true,
            clearance: clearances?.edit
          }
        ]
      },
    [actions.confirmAction, clearances.edit]
  );

  const removeActionPanel: ActionsPanelInterface = useMemo(
    () =>
      actions.removeAction && {
        type: actionsPanelTypesEnums.REMOVE_ACTIONS_PANEL,
        actions: [
          {
            type: actionsPanelIconsTypesEnums.REMOVE_ACTION_PANEL,
            onClick: actions.removeAction,
            useSwitchAnimation: true,
            isAsync: true,
            clearance: clearances?.remove
          },
          {
            type: actionsPanelIconsTypesEnums.CLOSE_ACTION_PANEL,
            pathColor: "black",
            useSwitchAnimation: true,
            clearance: clearances?.remove
          }
        ]
      },
    [actions.removeAction, clearances.remove]
  );

  const addActionPanel: ActionsPanelInterface = useMemo(
    () =>
      actions.addAction && {
        type: actionsPanelTypesEnums.ADD_ACTIONS_PANEL,
        actions: [
          {
            type: actionsPanelIconsTypesEnums.ADD_ACTION_PANEL,
            onClick: actions.addAction,
            isAsync: true,
            clearance: clearances?.create
          },
          {
            type: actionsPanelIconsTypesEnums.CLOSE_ACTION_PANEL,
            onClick: actions?.abortNewLineAction,
            clearance: clearances?.create
          }
        ]
      },
    [actions.addAction, actions.abortNewLineAction]
  );

  const loadingActionPanel: ActionsPanelInterface = useMemo(() => {
    return {
      type: actionsPanelTypesEnums.LOADING_ACTIONS_PANEL,
      actions: [
        {
          type: actionsPanelIconsTypesEnums.LOADING_ACTION_PANEL,
          useSwitchAnimation: true
        }
      ]
    };
  }, []);

  return {
    confirmActionPanel,
    removeActionPanel,
    addActionPanel,
    loadingActionPanel,
    switchFieldModeAnimation: {
      get mode() {
        return switchFieldModeAnimation;
      },
      set mode(val) {
        toggleMode(val, setSwitchFieldModeAnimation);
      }
    },
    switchActionModeAnimation: {
      get mode() {
        return switchActionModeAnimation;
      },
      set mode(val) {
        toggleMode(val, setSwitchActionModeAnimation);
      }
    },
    actionPanelType: {
      get type(): actionsPanelTypesEnums {
        return actionsPanelType;
      },
      set type(val) {
        setActionPanelType(val);
      }
    }
  };
};

export default useActionsPanels;
