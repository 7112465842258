import React, { useRef, useState } from "react";
import { View } from "DLUI/view";
import Dialog from "DLUI/dialogs/dialog";
import clsx from "clsx";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import TenantPortalDialogHeader from "DLUI/dialogs/components/tenantPortalDialogHeader";
import AppStrings from "locale/keys";
import FilterOptions from "./filterOptions";
import type { AccountRegisterReportItem, GetAccountRegisterReportQuery } from "@doorloop/dto";
import { PlaidMatchedRegisterItemDto } from "@doorloop/dto";
import matchTransactionsColumns from "./gridColums";
import MultiSelectGridList from "DLUI/lists/multiSelect/multiSelectGridList";
import { accountRegisterReportApi } from "api/reportsApi";
import MatchTransactionDialogSummaryPanel from "screens/transactions/components/bankConnect/dialogs/matchTransactions/matchTransactionDialogSummaryPanel";
import "./styles.css";
import { TransactionsEmptyDataView } from "./transactionsEmptyDataView";
import TransactionsListLoadingSkeletonItem from "screens/transactions/components/bankConnect/TransactionsListLoadingSkeletonItem";
import TransactionsListLoadingSkeleton from "screens/transactions/components/bankConnect/transactionsListLoadingSkeleton";
import { useFilterPanelQueryFilter } from "DLUI/screen/searchPanel/filterPanel/hooks/useFilterPanelQueryFilter";
import * as FilterPanel from "DLUI/screen/searchPanel/filterPanel";
import { toTypedKey } from "locale";
import { QueryParams } from "utils/queryParams";

export enum DialogState {
  Hidden,
  Show,
  Success,
  Error
}

interface ComponentProps {
  onClose: () => void;
  onSave: (selectedTransactions: AccountRegisterReportItem[]) => void;
  permission?: AnyPermissionClearance;
  transactionAmount?: number;
  transactionDate?: string;
}

export const MatchTransactionsDialogWidth = Math.min(window.innerWidth, 1300);
export const MatchTransactionsDialogHeight = window.innerHeight;

const MatchTransactionsDialog: React.FC<ComponentProps> = ({
  onClose,
  onSave,
  permission,
  transactionAmount,
  transactionDate
}: ComponentProps) => {
  const accountId = new QueryParams().get("account") as string;
  const defaultSearchQuery = {
    filter_registerCleared: false,
    filter_registerAccount: accountId,
    period: "all-time"
  };
  const filterPanelPortalRef = useRef<HTMLDivElement>(null);
  const queryFilter = useFilterPanelQueryFilter(FilterOptions, {
    initialQuery: defaultSearchQuery
  });
  const [selectedTransactions, setSelectedTransactions] = useState<AccountRegisterReportItem[]>([]);

  const onSelectionUpdate = (items: AccountRegisterReportItem[]) => {
    setSelectedTransactions(items);
  };

  const onSaveButtonPress = () => {
    const plaidMatchedRegisterItemDto: PlaidMatchedRegisterItemDto[] = [];
    selectedTransactions.forEach((currentTransaction) => {
      const plaidMatchedRegisterItem = new PlaidMatchedRegisterItemDto();
      plaidMatchedRegisterItem.memo = currentTransaction.memo;
      plaidMatchedRegisterItem.amount = currentTransaction.amount;
      plaidMatchedRegisterItem.journalEntry = currentTransaction.journalEntry;
      plaidMatchedRegisterItem.journalEntryType = currentTransaction.journalEntryType;
      plaidMatchedRegisterItem.reference = currentTransaction.reference;
      plaidMatchedRegisterItem.registerItem = currentTransaction.id;
      plaidMatchedRegisterItemDto.push(plaidMatchedRegisterItem);
    });
    onSave(plaidMatchedRegisterItemDto);
  };

  return (
    <Dialog open={true} className={clsx(["DLUI_Dialog"])} onClose={onClose} disableBackdropClick>
      <View
        width={MatchTransactionsDialogWidth}
        height={MatchTransactionsDialogHeight}
        backgroundColor={"light"}
        noWrap
        domRef={filterPanelPortalRef}
      >
        <TenantPortalDialogHeader onClose={onClose} title={AppStrings.Common.MatchTransactions} />
        <FilterPanel.Root filterOptions={FilterOptions}>
          <FilterPanel.InlineFilters />
          <FilterPanel.SearchAndFilters
            placeholder={toTypedKey("common.search")}
            sidePanelProps={{
              portalElement: filterPanelPortalRef.current ?? undefined
            }}
          />
        </FilterPanel.Root>
        <View height={MatchTransactionsDialogHeight - 300} overflow={"scroll"} flexDirection={"row"} noWrap>
          <View className={"matchTransactionDialog"} flexDirection={"row"} noWrap>
            <MultiSelectGridList<AccountRegisterReportItem, GetAccountRegisterReportQuery>
              listContainerPaddingRight={20}
              listContainerPaddingLeft={20}
              maxWidth={MatchTransactionsDialogWidth - 40}
              api={accountRegisterReportApi}
              gridColumns={matchTransactionsColumns}
              dataItemKey={"DataItemKey"}
              filterObj={queryFilter}
              onSelectionUpdate={onSelectionUpdate}
              initLoadingComponent={
                <TransactionsListLoadingSkeleton fullWidth SkeletonItem={TransactionsListLoadingSkeletonItem} />
              }
              emptyListComponent={<TransactionsEmptyDataView />}
              paddingBottom={250}
            />
          </View>
        </View>

        <MatchTransactionDialogSummaryPanel
          didPressCancelButton={onClose}
          didPressSaveButton={onSaveButtonPress}
          transactionAmount={transactionAmount}
          transactionDate={transactionDate}
          selectedTransactions={selectedTransactions}
        />
      </View>
    </Dialog>
  );
};

export default MatchTransactionsDialog;
