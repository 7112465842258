import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((_theme) => {
  return {
    discardUnsavedDialog: {
      zIndex: 99999999,
      position: "fixed",
      top: 0,
      left: 0,
      opacity: 0,
      animation: "$confirmationDialogFadeInAnimation .3s .4s forwards"
    },
    discardUnsavedOverlay: {
      content: "",
      position: "absolute",
      width: "100%",
      height: "100%",
      background: "black",
      opacity: 0.3
    },
    discardUnsavedContainer: {
      padding: "24px !important",
      boxShadow: "0px 5px 6px 0px rgba(0,0,0, 0.2)"
    },
    xBtn: {
      position: "absolute",
      right: 10,
      top: 10
    },
    "@keyframes confirmationDialogFadeInAnimation": {
      "0%": {
        opacity: 0,
        transform: "translateY(-10px)"
      },
      "100%": {
        opacity: 1,
        transform: "translateY(0)"
      }
    }
  };
});
