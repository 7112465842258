import { ObjectPermission } from "@doorloop/dto";
import AppStrings from "locale/keys";
import type { ObjectPermissionField } from "screens/settings/userRoles/clearanceTypes";
import type { ObjectWithHiddenForUserTypes } from "../../../../../../utils/userTypesUtils";
import { filterObjectsByUserType } from "../../../../../../utils/userTypesUtils";

export interface ObjectPermissionItemInterface extends ObjectWithHiddenForUserTypes {
  permission: ObjectPermission;
  title: string;
  ignoredFields?: ObjectPermissionField[];
  inactive?: boolean;
}

export interface ObjectPermissionItemsInterface extends ObjectWithHiddenForUserTypes {
  sectionTitle: string;
  ternary?: boolean;
  permissions: ObjectPermissionItemInterface[];
}

const PeopleSectionPermissions: ObjectPermissionItemInterface[] = [
  {
    permission: ObjectPermission.prospects,
    title: AppStrings.UserRoles.Prospects,
    hiddenForUserTypes: ["HOAUser"]
  },
  {
    permission: ObjectPermission.owners,
    title: AppStrings.UserRoles.Owners,
    hiddenForUserTypes: ["HOAUser"]
  },
  {
    permission: ObjectPermission.vendors,
    title: AppStrings.UserRoles.Vendors
  },
  {
    permission: ObjectPermission.tenants,
    title: AppStrings.UserRoles.Tenants
  },
  {
    permission: ObjectPermission.users,
    title: AppStrings.UserRoles.Users
  },
  {
    permission: ObjectPermission.userRoles,
    title: AppStrings.UserRoles.UserRoles
  },
  {
    permission: ObjectPermission.apiKeys,
    title: AppStrings.Settings.GeneralSettings.ApiKeys.ApiKeys
  }
];

const TasksSectionPermissions: ObjectPermissionItemInterface[] = [
  {
    permission: ObjectPermission.tasks,
    title: AppStrings.UserRoles.Tasks
  },
  {
    permission: ObjectPermission.workOrders,
    title: AppStrings.UserRoles.WorkOrders
  },
  {
    permission: ObjectPermission.tenantRequests,
    title: AppStrings.UserRoles.TenantRequests
  },
  {
    permission: ObjectPermission.ownerRequests,
    title: AppStrings.UserRoles.OwnerRequests,
    hiddenForUserTypes: ["HOAUser"]
  }
];

const RentalsSectionPermissions: ObjectPermissionItemInterface[] = [
  {
    permission: ObjectPermission.properties,
    title: AppStrings.UserRoles.Properties
  },
  {
    permission: ObjectPermission.units,
    title: AppStrings.UserRoles.Units
  }
];

const LeasingSectionPermissions: ObjectPermissionItemInterface[] = [
  {
    permission: ObjectPermission.leases,
    title: AppStrings.UserRoles.Leases
  },
  {
    permission: ObjectPermission.rentalApplications,
    title: AppStrings.UserRoles.RentalApplications,
    hiddenForUserTypes: ["HOAUser"]
  }
];

const TenantTransactionsSectionPermissions: ObjectPermissionItemInterface[] = [
  {
    permission: ObjectPermission.leaseCharges,
    title: AppStrings.UserRoles.LeaseCharges
  },
  {
    permission: ObjectPermission.leaseCredits,
    title: AppStrings.UserRoles.LeaseCredits
  },
  {
    permission: ObjectPermission.leasePayments,
    title: AppStrings.UserRoles.LeasePayments
  },
  {
    permission: ObjectPermission.leaseRefunds,
    title: AppStrings.UserRoles.LeaseRefunds
  }
];

const VendorTransactionsSectionPermissions: ObjectPermissionItemInterface[] = [
  {
    permission: ObjectPermission.vendorBills,
    title: AppStrings.UserRoles.VendorBills
  },
  {
    permission: ObjectPermission.vendorBillPayments,
    title: AppStrings.UserRoles.VendorBillPayments
  },
  {
    permission: ObjectPermission.vendorCredits,
    title: AppStrings.UserRoles.VendorCredits
  }
];

const OwnerTransactionsSectionPermissions: ObjectPermissionItemInterface[] = [
  {
    permission: ObjectPermission.ownerContributions,
    title: AppStrings.UserRoles.OwnerContributions
  }
];

const OtherTransactionsSectionPermissions: ObjectPermissionItemInterface[] = [
  {
    permission: ObjectPermission.journalEntries,
    title: AppStrings.UserRoles.JournalEntries
  },
  {
    permission: ObjectPermission.bankTransfers,
    title: AppStrings.UserRoles.BankTransfers
  },
  {
    permission: ObjectPermission.bankDeposits,
    title: AppStrings.UserRoles.BankDeposits
  },
  {
    permission: ObjectPermission.expenses,
    title: AppStrings.UserRoles.Expenses
  },
  {
    permission: ObjectPermission.applicationFees,
    title: AppStrings.UserRoles.ApplicationFees
  },
  {
    permission: ObjectPermission.managementFees,
    title: AppStrings.UserRoles.ManagementFees,
    ignoredFields: ["delete", "edit", "viewOne", "viewList"]
  },
  {
    permission: ObjectPermission.outgoingPayments,
    title: AppStrings.Common.OutgoingPayments.OutgoingPayments
  }
];

const GeneralObjectsSectionPermissions: ObjectPermissionItemInterface[] = [
  {
    permission: ObjectPermission.accounts,
    title: AppStrings.UserRoles.Accounts
  },
  {
    permission: ObjectPermission.plaidAccounts,
    title: AppStrings.UserRoles.BankConnectAccounts,
    ignoredFields: ["viewOne", "edit"]
  },
  {
    permission: ObjectPermission.plaidTransactions,
    title: AppStrings.UserRoles.BankConnectTransactions
  },
  {
    permission: ObjectPermission.calendar,
    title: AppStrings.UserRoles.Calendar,
    ignoredFields: ["viewOne", "edit", "delete", "create"]
  },
  {
    permission: ObjectPermission.merchantAccounts,
    title: AppStrings.UserRoles.MerchantAccounts,
    ignoredFields: ["viewOne", "edit", "delete"]
  },
  {
    permission: ObjectPermission.propertyGroups,
    title: AppStrings.UserRoles.Portfolios
  },
  {
    permission: ObjectPermission.tags,
    title: AppStrings.UserRoles.Tags
  },
  {
    permission: ObjectPermission.files,
    title: AppStrings.UserRoles.Files
  },
  {
    permission: ObjectPermission.notes,
    title: AppStrings.UserRoles.Notes
  },
  {
    permission: ObjectPermission.announcements,
    title: AppStrings.UserRoles.Announcements
  },
  {
    permission: ObjectPermission.communications,
    title: AppStrings.UserRoles.Communications
  },
  {
    permission: ObjectPermission.esignatureRequests,
    title: AppStrings.UserRoles.EsignatureRequests,
    hiddenForUserTypes: ["HOAUser"]
  },
  {
    permission: ObjectPermission.esignatureTemplates,
    title: AppStrings.UserRoles.EsignatureTemplates,
    hiddenForUserTypes: ["HOAUser"]
  },
  {
    permission: ObjectPermission.bankReconciliation,
    title: AppStrings.UserRoles.BankReconciliation
  },
  {
    permission: ObjectPermission.insurancePolicies,
    title: AppStrings.Common.InsurancePolicies,
    ignoredFields: ["viewList"],
    hiddenForUserTypes: ["HOAUser"]
  },
  {
    permission: ObjectPermission.budgets,
    title: AppStrings.Common.Budgets,
    ignoredFields: ["viewOne"]
  },
  {
    permission: ObjectPermission.phoneNumbers,
    title: AppStrings.Common.PhoneNumber,
    ignoredFields: ["viewList", "edit", "delete"]
  }
];

const CommunicationsCenterPermissions: ObjectPermissionItemInterface[] = [
  {
    permission: ObjectPermission.conversations,
    title: AppStrings.Common.CommunicationCenter,
    ignoredFields: ["delete", "create", "viewOne"]
  }
];

export const getObjectPermissionItems = (
  isHOAUser: boolean,
  isCommunicationsCenterFeatureFlagEnabled?: boolean
): ObjectPermissionItemsInterface[] => {
  const filterPermissionByUserType = <T extends ObjectWithHiddenForUserTypes>(permissions: T[]): T[] =>
    !isHOAUser ? permissions : filterObjectsByUserType(permissions, "HOAUser");

  const items: ObjectPermissionItemsInterface[] = [
    {
      sectionTitle: AppStrings.UserRoles.People,
      permissions: filterPermissionByUserType(PeopleSectionPermissions)
    },
    {
      sectionTitle: AppStrings.UserRoles.TasksAndMaintenance,
      ternary: true,
      permissions: filterPermissionByUserType(TasksSectionPermissions)
    },
    {
      sectionTitle: AppStrings.UserRoles.Rentals,
      permissions: filterPermissionByUserType(RentalsSectionPermissions)
    },
    {
      sectionTitle: AppStrings.UserRoles.Leasing,
      permissions: filterPermissionByUserType(LeasingSectionPermissions)
    },
    {
      sectionTitle: AppStrings.UserRoles.TenantTransactions,
      permissions: filterPermissionByUserType(TenantTransactionsSectionPermissions)
    },
    {
      sectionTitle: AppStrings.UserRoles.VendorTransactions,
      permissions: filterPermissionByUserType(VendorTransactionsSectionPermissions)
    },
    {
      sectionTitle: AppStrings.UserRoles.OwnerTransactions,
      permissions: filterPermissionByUserType(OwnerTransactionsSectionPermissions),
      hiddenForUserTypes: ["HOAUser"]
    },
    {
      sectionTitle: AppStrings.UserRoles.OtherTransactions,
      permissions: filterPermissionByUserType(OtherTransactionsSectionPermissions)
    },
    {
      sectionTitle: AppStrings.UserRoles.GeneralObjects,
      permissions: filterPermissionByUserType(GeneralObjectsSectionPermissions)
    }
  ];

  if (isCommunicationsCenterFeatureFlagEnabled) {
    items.push({
      sectionTitle: AppStrings.Common.CommunicationCenter,
      permissions: CommunicationsCenterPermissions,
      ternary: true
    });
  }

  return filterPermissionByUserType(items);
};
