import React from "react";
import type { HelpPanelProps, VideoItem } from "./types";
import { View } from "DLUI/view";
import { HelpPanelActionItem } from "./helpPanelActionItem";

type Props = Pick<HelpPanelProps, "actionItems" | "noWrapActionItem"> & {
  onOpenHelpVideo: (video: VideoItem) => void;
};

export const HelpPanelActionItems: React.FC<Props> = ({ actionItems, noWrapActionItem, onOpenHelpVideo }) =>
  actionItems?.length ? (
    <View flexDirection={"row"}>
      {actionItems.map((actionItem, i) => (
        <HelpPanelActionItem
          key={i}
          actionItem={actionItem}
          noWrapActionItem={noWrapActionItem}
          onOpenHelpVideo={onOpenHelpVideo}
        />
      ))}
    </View>
  ) : null;
