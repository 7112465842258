"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalApplicationPostChargeDto = exports.RentalApplicationPostChargeBaseDto = void 0;
const class_validator_1 = require("class-validator");
const rentalApplicationFeeSettingsPostCharge_dto_1 = require("./rentalApplicationFeeSettingsPostCharge.dto");
class RentalApplicationPostChargeBaseDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.RentalApplicationPostChargeBaseDto = RentalApplicationPostChargeBaseDto;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], RentalApplicationPostChargeBaseDto.prototype, "id", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], RentalApplicationPostChargeBaseDto.prototype, "applicationUrl", void 0);
class RentalApplicationPostChargeDto extends RentalApplicationPostChargeBaseDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.RentalApplicationPostChargeDto = RentalApplicationPostChargeDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    __metadata("design:type", rentalApplicationFeeSettingsPostCharge_dto_1.RentalApplicationFeeSettingsPostChargeDto)
], RentalApplicationPostChargeDto.prototype, "postCharge", void 0);
