import type { FC, ReactElement } from "react";
import React, { useEffect, useState } from "react";
import { View } from "DLUI/view";
import type { SVGIconComponent } from "../../../assets";
import { GrayInfoIcon, QuestionMarkIcon } from "../../../assets";
import { Icon } from "DLUI/icon";
import Text from "DLUI/text";
import type { TooltipProps, TooltipProps as MaterialUITooltipProps } from "@material-ui/core";
import { IconButton, Tooltip as MaterialUITooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "./learnMoreTooltip.css";

type TooltipPlacement = TooltipProps["placement"];

interface LearnMoreTooltipProps {
  contentView: JSX.Element;
  iconSource?: SVGIconComponent;
  placement?: TooltipPlacement;
  marginRight?: number;
  marginLeft?: number;
  dataCy?: string;
  tooltipClasses?: string;
  arrowClasses?: string;
  classes?: MaterialUITooltipProps["classes"];
  hideIcon?: boolean;
  children?: ReactElement;
  isToggleableByHover?: boolean;
}

interface DefaultContentProps {
  description: string;
  header?: string;
}

const LightTooltip = withStyles((theme) => {
  return {
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1))",
      fontSize: 12,
      padding: "20px",
      borderRadius: "5px",
      pointerEvents: "auto"
    },
    arrow: {
      color: theme.palette.common.white
    }
  };
})(MaterialUITooltip);

export const DefaultLearnMoreTooltipContent: FC<DefaultContentProps> = ({
  header,
  description
}: DefaultContentProps) => (
  <View flexDirection={"row"} alignItems={"flex-start"}>
    <Icon Source={GrayInfoIcon} size={30} marginRight={20} />
    <View flex={1} minWidth={80} noWrap>
      {header && <Text bold fontSize={14} marginBottom={5} lineHeight={"17px"} value={header} />}
      {description && <Text fontSize={14} lineHeight={"17px"} value={description} />}
    </View>
  </View>
);

export const LearnMoreTooltip: React.FC<LearnMoreTooltipProps> = ({
  contentView,
  iconSource = QuestionMarkIcon,
  marginRight = 0,
  marginLeft = 0,
  placement = "top",
  dataCy,
  tooltipClasses,
  arrowClasses,
  hideIcon = false,
  children,
  classes,
  isToggleableByHover
}: LearnMoreTooltipProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    document.body.addEventListener("click", handleClose);
    return () => {
      document.body.removeEventListener("click", handleClose);
    };
  }, []);

  const handleClose = () => {
    setShowTooltip(false);
  };

  const onTooltipPress = (e) => {
    if (isToggleableByHover) {
      return;
    }

    e.stopPropagation();
    setShowTooltip(!showTooltip);
  };

  return (
    <View
      autoWidth
      className={"DLUI_Learn_Tooltip"}
      marginRight={marginRight}
      marginLeft={marginLeft}
      flexDirection={"row"}
      onMouseEnter={() => isToggleableByHover && setShowTooltip(true)}
      onMouseLeave={() => isToggleableByHover && setShowTooltip(false)}
    >
      <LightTooltip
        title={contentView}
        placement={placement}
        arrow
        open={showTooltip}
        classes={{ tooltip: tooltipClasses, arrow: arrowClasses, ...classes }}
      >
        <div onClick={onTooltipPress} style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 5 }}>
          {children}
          {!hideIcon && <IconButton component={iconSource} data-cy={dataCy} />}
        </div>
      </LightTooltip>
    </View>
  );
};
