import MomentUtils from "@date-io/moment";
import type { LeasePaymentDto } from "@doorloop/dto";
import { DuplicateDialogButtonLabelValues } from "@doorloop/dto";
import {
  AccountType,
  createValidator,
  LeaseRecurringPaymentInfoDto,
  LinkedResourceType,
  mathUtils,
  ObjectPermission,
  PaymentMethod,
  RecurringTransactionDto,
  RecurringTransactionFrequency,
  RecurringTransactionType
} from "@doorloop/dto";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { filesApi } from "api/filesApi";
import { leasesRecurringTransactionsApi } from "api/leasesApi";
import { tenantsApi } from "api/tenantsApi";
import { ErrorSignIcon } from "assets/icons";
import { DialogSearchPanel, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import { FormikDatePicker, FormikReferenceLabel, Select, TextField, ViewOnlyInput } from "DLUI/form";
import { Notes } from "DLUI/notes";
import { SeparationLine } from "DLUI/separatorView";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, Field, Formik, getIn } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import LeasesList from "screens/leases/leases/leasesList/leasesList";
import SelectableLeaseListItem from "screens/leases/leases/leasesList/selectableLeaseListItem";
import TenantPortalNotificationPanel from "screens/tenantPortal/components/tenantPortalNotificationPanel";
import DialogFrame from "../components/dialogFrame";
import EPayInfoView from "../components/ePayInfoView";
import DialogAmountView from "../components/totalAmountView";
import LeaseSelection from "../transactions/leaseSelection";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { ControlledCheckBox } from "DLUI/form/checkBox/base/controlledCheckBox";
import { QueryParams } from "utils/queryParams";
import {
  cleanRecurringTransactionDataForDuplicateMode,
  DUPLICATE_MODE_QUERY_PARAMS
} from "DLUI/dialogs/duplicateModeHelper";
import { DialogHeaderActionButtons } from "DLUI/actionButtons/dialogHeaderActionButtons";
import { useAnalyticsService } from "hooks/useAnalyticsService";

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
}

let formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

const validateForm = createValidator(RecurringTransactionDto);

const RecurringPaymentDialog: React.FC<ComponentProps> = ({
  onBackdropClick,
  onClose,
  dialogTitle
}: ComponentProps) => {
  const { t } = useTranslation();
  const { leaseId, transactionId: paramsTransactionId } = useParams<any>();
  const queryParams = new QueryParams();
  const queryTransactionId = queryParams.get(DUPLICATE_MODE_QUERY_PARAMS.transactionId) as string | undefined;
  const transactionId = queryTransactionId || paramsTransactionId;
  const editMode = Boolean(transactionId);
  const duplicateMode = Boolean(queryTransactionId);
  const clearance: AnyPermissionClearance = {
    permission: ObjectPermission.leasePayments,
    field: editMode ? "edit" : "create"
  };
  const { isMobile, screenContainerPadding } = useResponsiveHelper();
  const [viewIndex, setViewIndex] = useState(0);
  const [renderSelectionList, setRenderSelectionList] = useState<boolean>(false);
  const [currentLeaseId, setCurrentLeaseId] = useState<string | undefined>(leaseId);
  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);
  const [selectLeaseFilterObj, setSelectLeaseFilterObj] = useState({
    filter_text: ""
  });
  const [showLeaseSelectionError, setShowLeaseSelectionError] = useState<boolean>(false);
  const [leaseSelectionErrorText, setLeaseSelectionErrorText] = useState<string>("");
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    editMode ? DialogState.Show : DialogState.Hidden
  );
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const [loadingDialogSuccessText, setLoadingDialogSuccessText] = useState<string>(
    AppStrings.Leases.LeaseTransaction.Payment.CreatedText
  );
  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState<string>(
    AppStrings.Leases.LeaseTransaction.Payment.LoadingData
  );
  const [chargeData, setChargeData] = useState<RecurringTransactionDto | undefined>();
  const [shouldrenderForm, setShouldRenderForm] = useState<boolean>(!editMode);
  const [totalPayments, setTotalpayments] = useState<number>(0);
  const [repeatUntilEndLease, setRepeatUntilEndLease] = useState<boolean>(true);
  const [showCheckNumberInput, setShowCheckNumberInput] = useState<boolean>(false);
  const [isEpayment, setIsEpayment] = useState<boolean>(false);
  const [viewOnlyMode, setViewOnlyMode] = useState<boolean>(false);

  const { dispatchAnalytics } = useAnalyticsService();

  const [receivedFromTenantDefaultValue, setReceivedFromTenantDefaultValue] = useState<string | undefined>();

  const [depositToAccountDefaultValue, setDepositToAccountDefaultValue] = useState<string | undefined>();

  const inputPaddingRight = isMobile ? 0 : 20;

  useEffect(() => {
    formikGlobalRef?.setFieldValue("lease", currentLeaseId);
    setTimeout(() => {
      if (formikGlobalRef) {
        const errorText = getIn(formikGlobalRef.errors, "lease");
        const touchedVal = getIn(formikGlobalRef.touched, "lease");

        setShowLeaseSelectionError(touchedVal && errorText !== undefined);
        if (errorText !== undefined) {
          setLeaseSelectionErrorText(t(AppStrings.Leases.LeaseCharge.PressToSelectLease));
        }
      }
    }, 0);
  }, [currentLeaseId]);

  useEffect(() => {
    if (editMode) {
      loadPaymentData();
    }
  }, [duplicateMode]);

  const initFormvalues = (): LeasePaymentDto => {
    if (editMode && chargeData) {
      return chargeData;
    }
    const _leaseRecurringPaymentDto = new RecurringTransactionDto();
    _leaseRecurringPaymentDto.type = RecurringTransactionType.LEASE_PAYMENT;
    _leaseRecurringPaymentDto.start = moment().format("YYYY-MM-DD");
    _leaseRecurringPaymentDto.end = moment().add(1, "year").add(-1, "day").format("YYYY-MM-DD");
    _leaseRecurringPaymentDto.noEndDate = true;
    _leaseRecurringPaymentDto.frequency = RecurringTransactionFrequency.Monthly;
    _leaseRecurringPaymentDto.leasePaymentInfo = new LeaseRecurringPaymentInfoDto();
    return _leaseRecurringPaymentDto;
  };

  const isValidForm = async (formikRef: FormikProps<LeasePaymentDto>) => {
    formikRef.setFieldTouched("lease");
    formikRef.setFieldTouched("depositToAccount");
    formikRef.setFieldTouched("leasePaymentInfo.amount");
    formikRef.setFieldTouched("leasePaymentInfo.paymentMethod");
    formikRef.setFieldTouched("leasePaymentInfo.depositAccount");
    formikRef.setFieldTouched("checkInfo.checkNumber");
    formikRef.setFieldTouched("start");
    formikRef.setFieldTouched("end");
    formikRef.setFieldTouched("noEndDate");

    const errors = (await formikRef.validateForm()) as any;
    const inValidLease = Boolean(errors.lease);
    setShowLeaseSelectionError(inValidLease);
    if (inValidLease && formikGlobalRef) {
      const errorText = getIn(formikGlobalRef.errors, "lease");
      if (errorText !== undefined) {
        setLeaseSelectionErrorText(t(AppStrings.Leases.LeaseCharge.PressToSelectLease));
      }
    }
    return _.isEmpty(errors);
  };

  const loadPaymentData = async () => {
    if (editMode && transactionId) {
      setLoadingDialogState(DialogState.Show);

      const response = await leasesRecurringTransactionsApi.get(transactionId).catch((error) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });
      if (response && response.status && response.data) {
        if (response.data.createdByType === "TENANT") {
          setViewOnlyMode(true);
        }
        const isEpayment = !_.isEmpty(response.data?.leasePaymentInfo?.ePayInfo);
        setIsEpayment(isEpayment);
        setChargeData(
          duplicateMode && !isEpayment ? cleanRecurringTransactionDataForDuplicateMode(response.data) : response.data
        );

        if (response.data.leasePaymentInfo) {
          setDepositToAccountDefaultValue(response.data.leasePaymentInfo.depositAccount);
          setReceivedFromTenantDefaultValue(response.data.leasePaymentInfo.receivedFromTenant);
        }

        // if (response.data.totalAmount && response.data.totalBalance) {
        //   setTotalpayments(
        //     response.data.totalAmount - response.data.totalBalance
        //   );
        // }
        setLoadingDialogSuccessText(AppStrings.Leases.LeaseTransaction.Payment.LoadingDataSuccess);
        setLoadingDialogState(DialogState.Success);
        setTimeout(() => {
          setLoadingDialogState(DialogState.Hidden);
          if (
            response.data &&
            response.data.leasePaymentInfo &&
            response.data.leasePaymentInfo.paymentMethod == PaymentMethod.CHECK
          ) {
            setShowCheckNumberInput(true);
          }
          setShouldRenderForm(true);
        }, 500);
      } else {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(response ? response.message : "");
      }
    }
  };

  const createTransaction = async () => {
    if (formikGlobalRef) {
      const chargeValues = _.cloneDeep(formikGlobalRef.values);
      setViewIndex(2);
      setLoadingDialogState(DialogState.Show);
      const results = await leasesRecurringTransactionsApi.create(chargeValues).catch((error) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });

      if (results && results.status && results.data) {
        await filesApi
          .uploadFiles(attachments, results.data.id!, LinkedResourceType.RecurringTransaction)
          .catch((error: string) => {
            setLoadingDialogState(DialogState.Error);
            setLoadingDialogErrorText(error);
          });

        setLoadingDialogState(DialogState.Success);
        setTimeout(() => {
          onClose();
        }, 500);
      } else {
        setLoadingDialogErrorText(results ? results.message : "");
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const updateTransaction = async () => {
    if (formikGlobalRef && transactionId) {
      const chargeValues = _.cloneDeep(formikGlobalRef.values);
      setViewIndex(2);
      setShouldRenderForm(false);
      setLoadingDialogState(DialogState.Show);
      setLoadingDialogLoadingText(AppStrings.Leases.LeaseTransaction.Payment.Updating);
      const results = await leasesRecurringTransactionsApi.update(transactionId, chargeValues).catch((error) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });

      if (results && results.status && results.data) {
        await filesApi
          .uploadFiles(attachments, transactionId, LinkedResourceType.RecurringTransaction)
          .catch((error: string) => {
            setLoadingDialogState(DialogState.Error);
            setLoadingDialogErrorText(error);
          });
        setLoadingDialogSuccessText(AppStrings.Leases.LeaseTransaction.Payment.Updated);
        setLoadingDialogState(DialogState.Success);
        setTimeout(() => {
          onClose();
        }, 500);
      } else {
        setLoadingDialogErrorText(results ? results.message : "");
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const didPressSaveButton = async () => {
    if (formikGlobalRef !== null) {
      const isValid = await isValidForm(formikGlobalRef);
      if (isValid) {
        if (editMode && !duplicateMode) {
          updateTransaction();
        } else {
          createTransaction();
        }
      }
    }
  };

  const getTotalAmount = () => {
    let amount = 0;
    if (formikGlobalRef && formikGlobalRef.values) {
      amount = getIn(formikGlobalRef.values, "leasePaymentInfo.amount");
    }
    return amount || 0;
  };

  const didChangePaymentMethod = (nextValue: string) => {
    if (nextValue === PaymentMethod.CHECK) {
      setShowCheckNumberInput(true);
    } else {
      setShowCheckNumberInput(false);
    }
  };

  const renderEPayInfo = () => {
    if (viewOnlyMode && chargeData?.leasePaymentInfo && chargeData?.leasePaymentInfo.ePayInfo) {
      return <EPayInfoView ePayInfo={chargeData?.leasePaymentInfo.ePayInfo} />;
    }
  };

  const renderLines = () => (
    <View shouldShow showAnimation={"fade-in"} hideAnimation={"fade-out"}>
      <View flexDirection={"row"}>
        <Grid container alignItems={"flex-start"} item xs={viewOnlyMode ? 6 : 12} md={4} lg={4}>
          <FormikCachedAsyncAutoComplete
            uniqueIndex={"TS"}
            apiHandler={tenantsApi}
            displayNameKey={"name"}
            filterFieldName={"filter_text"}
            filterFieldValue={"name"}
            selectionFields={["id", "class"]}
            name={"leasePaymentInfo.receivedFromTenant"}
            queryParams={{ filter_lease: leaseId }}
            label={t(AppStrings.Leases.LeaseTransaction.Payment.ReceivedFrom)}
            defaultValue={receivedFromTenantDefaultValue}
            viewOnly={viewOnlyMode}
            paddingRight={inputPaddingRight}
            marginTop={20}
          />
        </Grid>
        <Grid container alignItems={"flex-start"} item xs={viewOnlyMode ? 6 : 12} md={viewOnlyMode ? 6 : 4}>
          <BankAccountFormikAutoCompleteField
            uniqueIndex={"TS"}
            name={"leasePaymentInfo.depositAccount"}
            queryParams={{
              filter_type: AccountType.ASSET_BANK,
              filter_lease: currentLeaseId,
              filter_active: true
            }} // TODO: @itay we need to get this to re-render every time the lease changes
            label={t(AppStrings.Leases.LeaseTransaction.Payment.DepositAccount)}
            defaultValue={depositToAccountDefaultValue}
            viewOnly={viewOnlyMode}
            paddingRight={inputPaddingRight}
            marginTop={20}
          />
        </Grid>
        <Grid container alignItems={"flex-start"} item xs={viewOnlyMode ? 6 : 12} md={viewOnlyMode ? 2 : 4}>
          <FastField
            component={TextField}
            name={"leasePaymentInfo.amount"}
            type={"number"}
            required
            formatType={"currency"}
            label={t(AppStrings.Leases.LeaseTransaction.Payment.PaymentAmount)}
            viewOnly={viewOnlyMode}
            marginTop={viewOnlyMode ? 0 : 20}
          />
        </Grid>
        <Grid container alignItems={"flex-start"} item xs={viewOnlyMode ? 6 : 12} md={4} lg={4}>
          <FastField
            component={Select}
            name={`leasePaymentInfo.paymentMethod`}
            label={AppStrings.Leases.LeaseTransaction.Payment.PaymentMethod}
            required
            uniqueKey={"paymentMethod"}
            selectionEnum={PaymentMethod}
            onChange={didChangePaymentMethod}
            translationKey={"paymentMethod"}
            viewOnly={viewOnlyMode}
            paddingRight={inputPaddingRight}
            marginTop={20}
          />
        </Grid>
        {renderEPayInfo()}
      </View>
    </View>
  );

  const renderSummaryLine = () => {
    const totalAmount = getTotalAmount();
    return (
      <DialogAmountView
        amount={mathUtils.substract(totalAmount - totalPayments)}
        title={AppStrings.Leases.LeaseCharge.TotalPayment}
      />
    );
  };

  const onFileReceived = (files: FileListItemProps[]) => {
    setAttachments(files);
  };

  const renderAttachments = () => (
    <View justifyContent={"flex-end"} width={"100%"} marginBottom={20} marginTop={20}>
      <FormAttachments
        editMode={editMode}
        resourceId={chargeData ? chargeData.id! : undefined}
        resourceType={LinkedResourceType.RecurringTransaction}
        onFileReceived={onFileReceived}
        files={attachments}
      />
    </View>
  );

  const didPressSelectLease = () => {
    setViewIndex(1);
    setTimeout(() => {
      setRenderSelectionList(true);
    }, 500);
  };

  const didchangeStartDate = (nextStartDateValue: string) => {
    if (formikGlobalRef) {
      formikGlobalRef.setFieldValue("end", moment(nextStartDateValue).add(1, "days").format("YYYY-MM-DD"));
    }
  };

  const didChangeCheckboxSelection = (checked: boolean) => {
    if (formikGlobalRef) {
      formikGlobalRef.setFieldValue("noEndDate", checked);
      setRepeatUntilEndLease(checked);
    }
  };

  const renderEndDate = (formikRef: FormikProps<any>) => {
    const oneTimeFuturePayment =
      chargeData?.numOfOccurrences === 1 && chargeData?.frequency === RecurringTransactionFrequency.Monthly;

    const _repeatUntilEndLease = getIn(formikRef.values, "noEndDate");

    if (viewOnlyMode) {
      if (oneTimeFuturePayment) {
        return (
          <ViewOnlyInput
            value={getIn(formikRef.values, "end")}
            label={AppStrings.Leases.NewLease.EndDate}
            backgroundColor={"transparent"}
            marginTop={20}
          />
        );
      }
      if (_repeatUntilEndLease) {
        return (
          <ViewOnlyInput
            value={t(AppStrings.Leases.NewLease.LeaseRent.RepeatUntilEndOfLease)}
            label={AppStrings.Leases.NewLease.EndDate}
            backgroundColor={"transparent"}
            marginTop={20}
          />
        );
      }
    }

    return (
      <Field
        component={FormikDatePicker}
        uniqueKey={"end"}
        label={AppStrings.Leases.NewLease.EndDate}
        name={"end"}
        noMargin
        required
        marginTop={20}
        viewOnly={viewOnlyMode}
        disabled={_repeatUntilEndLease}
      />
    );
  };

  const renderFrequency = () => {
    const oneTimeFuturePayment =
      chargeData?.occurrencesRemaining === 1 && chargeData?.frequency === RecurringTransactionFrequency.Monthly;

    if (oneTimeFuturePayment && viewOnlyMode) {
      return (
        <ViewOnlyInput
          value={t(AppStrings.Common.OneTime)}
          label={AppStrings.Leases.NewLease.LeaseRent.Frequency}
          backgroundColor={"transparent"}
          marginTop={20}
        />
      );
    }

    return (
      <FastField
        component={Select}
        name={`frequency`}
        label={AppStrings.Leases.NewLease.LeaseRent.Frequency}
        required
        uniqueKey={"paymentMethod"}
        selectionEnum={RecurringTransactionFrequency}
        translationKey={"recurringTransactionFrequency"}
        viewOnly={viewOnlyMode}
        marginTop={5}
      />
    );
  };

  const renderFormTopSection = (formikRef: FormikProps<any>) => (
    <View flexDirection={"row"}>
      <View flexDirection={"row"} noWrap={!isMobile} gap={isMobile ? undefined : 12}>
        <Grid item xs={12} md={viewOnlyMode ? 10 : 8}>
          <View marginTop={viewOnlyMode || isMobile ? 20 : 0}>
            <LeaseSelection
              didPressSelectLease={didPressSelectLease}
              leaseId={currentLeaseId}
              errorTex={showLeaseSelectionError ? leaseSelectionErrorText : undefined}
              viewOnly={viewOnlyMode}
            />
          </View>
        </Grid>
        <Grid container item xs={12} md={viewOnlyMode ? 2 : 4}>
          <View flexDirection={"row"} alignItems={"flex-start"}>
            <FastField
              component={FormikReferenceLabel}
              name={"reference"}
              backgroundColor={"dark"}
              viewOnly={viewOnlyMode}
              valueTextTransform={"uppercase"}
            />
          </View>
        </Grid>
      </View>

      <Grid container item xs={viewOnlyMode ? 4 : 12} md={4}>
        <View paddingRight={inputPaddingRight}>
          <FastField
            component={FormikDatePicker}
            uniqueKey={"start"}
            label={AppStrings.Leases.NewLease.StartDate}
            name={"start"}
            noMargin
            required
            onChange={didchangeStartDate}
            viewOnly={viewOnlyMode}
            marginRight={inputPaddingRight}
            marginTop={20}
          />
        </View>
      </Grid>
      <Grid container item xs={viewOnlyMode ? 4 : 12} md={viewOnlyMode ? 6 : 4}>
        <View paddingRight={inputPaddingRight}>{renderEndDate(formikRef)}</View>
      </Grid>
      <Grid container item xs={viewOnlyMode ? 4 : 12} md={viewOnlyMode ? 2 : 4}>
        <View marginTop={15}>{renderFrequency()}</View>
      </Grid>
      <View marginTop={10} marginBottom={10}>
        {viewOnlyMode ? null : (
          <ControlledCheckBox
            checked={repeatUntilEndLease}
            onChange={didChangeCheckboxSelection}
            disabled={viewOnlyMode}
            labelValueProps={{
              text: AppStrings.Leases.NewLease.LeaseRent.RepeatUntilEndOfLease,
              color: "black"
            }}
          />
        )}
      </View>
    </View>
  );

  const renderNotes = () => (
    <View height={50} marginTop={20} paddingLeft={20} paddingRight={20} justifyContent={"center"}>
      <FastField component={Notes} disabled={viewOnlyMode} height={30} name={"memo"} />
    </View>
  );

  const renderViewOnlyNotification = () => {
    if (viewOnlyMode) {
      return (
        <TenantPortalNotificationPanel
          title={AppStrings.Common.ViewOnlyRecurringPaymentTitle}
          notificationText={AppStrings.Common.ViewOnlyRecurringPaymentTitleDescription}
          icon={ErrorSignIcon}
          hideBoxShadow
          marginTop={20}
          borderRadius={10}
          border={"1px solid #F2C94C"}
          minHeight={100}
        />
      );
    }
  };

  const renderForm = () => {
    const formInitialValues = initFormvalues();
    return (
      <Formik initialValues={formInitialValues} onSubmit={() => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;
          return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <View
                paddingLeft={screenContainerPadding}
                paddingRight={screenContainerPadding}
                flexDirection={"column"}
                width={"100%"}
              >
                <RestrictedPermissionAccess clearance={clearance} showNoAccess>
                  {renderViewOnlyNotification()}

                  <View marginTop={viewOnlyMode ? 0 : 20} flexDirection={"row"}>
                    {renderFormTopSection(formik)}
                  </View>

                  <SeparationLine width={"100%"} height={1} />
                  {renderLines()}
                  <SeparationLine marginTop={20} width={"100%"} height={1} />
                  {renderNotes()}
                  {renderSummaryLine()}
                  {renderAttachments()}
                </RestrictedPermissionAccess>
              </View>
            </MuiPickersUtilsProvider>
          );
        }}
      </Formik>
    );
  };

  const renderLeaseSelectionList = () => {
    const didChangeSearchQuery = (value: string) => {
      setSelectLeaseFilterObj({
        filter_text: value
      });
    };

    const onListItemPress = (leaseId: string) => {
      setViewIndex(0);
      setCurrentLeaseId(leaseId);
    };

    return (
      <View>
        <DialogSearchPanel onChange={didChangeSearchQuery} />
        <View>
          {renderSelectionList ? (
            <View id={"selectableLeaseContainer"} height={630} overflow={"scroll"}>
              <View paddingRight={screenContainerPadding} paddingLeft={screenContainerPadding}>
                <LeasesList
                  ListItem={SelectableLeaseListItem}
                  filterObj={selectLeaseFilterObj}
                  didPressListItem={onListItemPress}
                  stickyHeaderId={"selectableLeaseContainer"}
                  scrollableTarget={"selectableLeaseContainer"}
                  removeDefaultBottomPadding
                  selectableItem
                />
              </View>
            </View>
          ) : null}
        </View>
      </View>
    );
  };

  const renderView = ({ index }: any) => {
    const onRetryButtonPress = async () => {
      await didPressSaveButton();

      //createTransaction();
    };
    const didPressDismissButton = () => {
      setViewIndex(0);
    };

    if (index === 0) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={t(loadingDialogLoadingText)}
            errorText={t(loadingDialogErrorText)}
            successText={t(loadingDialogSuccessText)}
            onRetryButtonPress={loadPaymentData}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }
      if (shouldrenderForm) {
        return renderForm();
      }
      return <div />;
    }
    if (index === 1) {
      return renderLeaseSelectionList();
    }
    if (index === 2) {
      return (
        <View alignItems={"center"} justifyContent={"center"} height={"100%"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={t(loadingDialogLoadingText)}
            errorText={t(loadingDialogErrorText)}
            successText={t(loadingDialogSuccessText)}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
          />
        </View>
      );
    }
    return <div />;
  };

  const _onBackdropClick = () => {
    if (viewIndex === 1) {
      setViewIndex(0);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const handleDuplicateClick = () => {
    queryParams.set(DUPLICATE_MODE_QUERY_PARAMS.transactionId, transactionId);
    queryParams.historyPush();

    dispatchAnalytics("button_click", {
      label: DuplicateDialogButtonLabelValues.DUPLICATE_JOURNAL_ENTRY
    });
  };
  const renderHeaderActionButtons = () => (
    <DialogHeaderActionButtons
      onDuplicateClick={handleDuplicateClick}
      clearance={clearance}
      hideDeleteButton
      hideDuplicateButton={isEpayment || duplicateMode || !editMode}
    />
  );

  const renderActionPanelButtons = () => (
    <FormActionButtons
      clearance={clearance}
      propsSubButton={{ onClick: _onBackdropClick, actionText: AppStrings.Common.Dismiss }}
      propsMainButton={viewOnlyMode ? undefined : { type: "cta", props: { onClick: didPressSaveButton } }}
    />
  );

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }
    if (viewIndex === 2) {
      return "contentOnly";
    }
    return "sectionTitleFrame";
  }, [viewIndex, loadingDialogState]);

  const currentTitle = useMemo(() => {
    if (viewIndex === 0) {
      return dialogTitle;
    }

    if (viewIndex === 1) {
      return AppStrings.Leases.LeaseCharge.SelectLease;
    }
    return "";
  }, [viewIndex]);

  const duplicateModeTitle = useMemo(
    () => (duplicateMode ? `${t(currentTitle)} (${t(AppStrings.Common.Duplicated)})` : ""),
    [currentTitle, duplicateMode, t]
  );

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={duplicateModeTitle || currentTitle}
      width={1000}
      height={viewOnlyMode ? 870 : 850}
      renderView={renderView}
      numViews={3}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      RenderHeaderActionButtons={renderHeaderActionButtons}
      frameType={frameType}
    />
  );
};

export default RecurringPaymentDialog;
