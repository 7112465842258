import { View } from "DLUI/view";
import React from "react";
import { PaymentMethod } from "@doorloop/dto";
import { ExpenseBillCtaIcon } from "assets/icons";
import { SuccessDialogHeader } from "DLUI/dialogs/successDialog/successDialogHeader";
import { SuccessDialogTitle } from "DLUI/dialogs/successDialog/successDialogTitle";
import { SuccessDialogActions } from "screens/owners/ownerDistributions/components/successDialogOwnerDistribution/components/successDialogActions";
import { ExpenseCtaSummary } from "./expenseCtaSummary";
import { useTypedTranslation } from "@/locale";
import { useResponsiveHelper } from "@/contexts/responsiveContext";

export interface IExpenseCtaProps {
  accountId?: string;
  expense?: number;
  paymentMethod?: PaymentMethod | "both";
  propertiesUniqueCount: number;
  reference?: string;
  payeeType?: string;
}

export const ExpenseCta: React.FC<IExpenseCtaProps> = ({
  accountId,
  expense = 0,
  paymentMethod,
  propertiesUniqueCount,
  reference,
  payeeType
}) => {
  const { t } = useTypedTranslation();
  const { isMobile } = useResponsiveHelper();

  return (
    <View
      paddingTop={16}
      paddingBottom={16}
      paddingLeft={16}
      paddingRight={16}
      gap={10}
      width={isMobile ? "100%" : 375}
      minHeight={400}
      backgroundColor="light"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      noWrap
    >
      <SuccessDialogHeader icon={ExpenseBillCtaIcon} />
      <SuccessDialogTitle
        titleProps={{
          text: t("common.expenseRecorded"),
          fontSize: 14
        }}
        subTitleProps={{
          text: expense.toString(),
          fontSize: 36,
          formatType: "currency"
        }}
      />
      <ExpenseCtaSummary reference={reference} propertiesUniqueCount={propertiesUniqueCount} payeeType={payeeType} />
      <SuccessDialogActions
        disableMobileCollapse
        accountPrintChecks={isMobile ? undefined : accountId}
        isEpay={paymentMethod === PaymentMethod.EPAY}
      />
    </View>
  );
};
