"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NameAndId = void 0;
class NameAndId {
    constructor(id, name) {
        this.id = id;
        this.name = name;
    }
}
exports.NameAndId = NameAndId;
