"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseBaseDto = void 0;
const base_dto_1 = require("../base.dto");
const isDateStringWithoutTime_validator_1 = require("../common/validators/isDateStringWithoutTime.validator");
const isAfter_validator_1 = require("../common/validators/isAfter.validator");
const class_validator_1 = require("class-validator");
const leaseTermType_enum_1 = require("./leaseTermType.enum");
class LeaseBaseDto extends base_dto_1.BaseDto {
    constructor() {
        super();
    }
}
exports.LeaseBaseDto = LeaseBaseDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(1000),
    __metadata("design:type", String)
], LeaseBaseDto.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(1000),
    __metadata("design:type", String)
], LeaseBaseDto.prototype, "notes", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], LeaseBaseDto.prototype, "property", void 0);
__decorate([
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], LeaseBaseDto.prototype, "start", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.term !== leaseTermType_enum_1.LeaseTermType.AtWill),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    (0, isAfter_validator_1.IsAfter)("start"),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], LeaseBaseDto.prototype, "end", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(leaseTermType_enum_1.LeaseTermType),
    __metadata("design:type", String)
], LeaseBaseDto.prototype, "term", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.Term === leaseTermType_enum_1.LeaseTermType.Fixed),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], LeaseBaseDto.prototype, "rolloverToAtWill", void 0);
