"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isGreaterThan = isGreaterThan;
const class_validator_1 = require("class-validator");
function isGreaterThan(property, validationOptions) {
    return function (object, propertyName) {
        (0, class_validator_1.registerDecorator)({
            name: "IsGreaterThan",
            target: object.constructor,
            propertyName,
            constraints: [property],
            options: validationOptions,
            validator: {
                validate(value, args) {
                    const [relatedPropertyName] = args.constraints;
                    const relatedValue = args.object[relatedPropertyName];
                    if (!relatedValue || !value || value == 0) {
                        // ignore this if there is no balance provided don't break the server :)
                        return true;
                    }
                    return Number(value) >= Number(relatedValue);
                }
            }
        });
    };
}
