import type { PropertyDto, UnitDto } from "@doorloop/dto";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useEffectAsync } from "../../../../../hooks/useEffectAsync";
import { propertiesApi } from "api/propertiesApi";
import AppStrings from "../../../../../locale/keys";
import _ from "lodash";
import type { RUGFileState } from "shared/pictureGallery/types";

export const useListingValidation = ({ transientPictures }: { transientPictures: RUGFileState[] | null }) => {
  const formikRef = useFormikContext<UnitDto>();
  const [errors, setErrors] = React.useState<string[]>([]);
  const propertyId = formikRef.values.property;
  const [property, setProperty] = React.useState<PropertyDto>();

  const unitValues = formikRef.values;

  useEffectAsync(async () => {
    if (propertyId) {
      const property = await propertiesApi.get(propertyId);

      setProperty(property.data);
    }
  }, [propertyId]);

  useEffect(() => {
    const newErrors: string[] = [];

    if (_.isEmpty(unitValues.pictures) && _.isEmpty(transientPictures)) {
      newErrors.push(AppStrings.Listing.Errors.UnitPhotos);
    }

    if (property && _.isEmpty(property.pictures)) {
      newErrors.push(AppStrings.Listing.Errors.PropertyPhotos);
    }

    if (_.isEmpty(unitValues.amenities)) {
      newErrors.push(AppStrings.Listing.Errors.UnitAmenities);
    }

    if (property && _.isEmpty(property.amenities)) {
      newErrors.push(AppStrings.Listing.Errors.PropertyAmenities);
    }

    if (!unitValues.beds) {
      newErrors.push(AppStrings.Listing.Errors.UnitBedsRooms);
    }

    if (!unitValues.baths) {
      newErrors.push(AppStrings.Listing.Errors.UnitBathsRooms);
    }

    setErrors((currentErrors) => (_.isEmpty(currentErrors) && _.isEmpty(newErrors) ? currentErrors : newErrors));
  }, [unitValues, property, transientPictures]);

  return {
    shouldShowValidationErrors: Boolean(property) && !_.isEmpty(errors),
    errors
  };
};
