import { useResponsiveHelper } from "../contexts/responsiveContext";
import { useCallback, useMemo } from "react";
import type { GridSpace } from "DLUI/listItems/grid";

/*
 * SIZES ORDER: mobile, tablet, md, lg, xl
 * mobile replaces "xs"
 * tablet replaces "sm"
 * will always take the smallest size as long as there is no accurate alternative
 */
type SizingSystem = "mobile" | "tablet" | "md" | "lg" | "xl";

type GridColumnSizeValue = "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type GridColumnSize = Partial<Record<SizingSystem, GridColumnSizeValue>>;

interface GridColumn {
  size: GridColumnSize | number;
  hideIn?: SizingSystem[];
}

type Columns<T> = Record<keyof T, GridColumn>;

type SizeSuffixType<T> = {
  [K in keyof T as `${string & K}Size`]: GridSpace;
};

const DEFAULT_GRID_SIZE = 12;

export function useGrid<T>(columns: Columns<T>) {
  const { isScreenSizeIn, isMobile, isOnlyTablet } = useResponsiveHelper();

  const getColumnSize = useCallback(
    (columnId: string): GridSpace => {
      const column = columns[columnId];

      if (!column) {
        return undefined;
      }

      const hideMobile = column.hideIn?.includes("mobile") && isMobile;
      const hideTablet = column.hideIn?.includes("tablet") && isOnlyTablet;

      if (column.hideIn && (isScreenSizeIn(column.hideIn) || hideMobile || hideTablet)) {
        return false;
      }

      const size = column.size;

      if (typeof size !== "object") {
        return size;
      }

      const { mobile = DEFAULT_GRID_SIZE, tablet, md, lg, xl } = size;

      if (isMobile) {
        return mobile;
      }

      const tabletSize = tablet || mobile;
      if (isOnlyTablet) {
        return tabletSize;
      }

      const mdSize = md || tabletSize;
      if (isScreenSizeIn(["md"])) {
        return mdSize;
      }

      const lgSize = lg || mdSize;
      if (isScreenSizeIn(["lg"])) {
        return lgSize;
      }

      return xl || lgSize;
    },
    [columns, isMobile, isOnlyTablet, isScreenSizeIn]
  );

  const responsiveSizes = useMemo(() => {
    let sizeCount = 0;

    const sizes = Object.keys(columns).reduce<SizeSuffixType<T>>((acc, column) => {
      const columnSize = getColumnSize(column);

      if (typeof columnSize === "number") {
        sizeCount += columnSize;
      }

      return {
        ...acc,
        [`${column}Size`]: columnSize
      };
    }, {} as any);

    if (sizeCount > DEFAULT_GRID_SIZE) {
      console.warn("The sum of all column sizes should not exceed 12");
    }

    return sizes;
  }, [columns, getColumnSize]);

  return { responsiveSizes };
}
