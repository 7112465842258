import React, { useMemo, useState } from "react";

import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import { currencyCodes, regionNames } from "@doorloop/dto";

import AppStrings from "../../../../../../../locale/keys";
import type { IRootState } from "store/index";
import { merchantAccountApi } from "api/bankAccountsApi";
import { useEffectAsync } from "hooks/useEffectAsync";
import { ElementPrefixedText } from "./elementPrefixedText";
import { Link } from "DLUI/link";
import ColorsEnum from "utils/colorsEnum";
import { NavigationManager } from "utils/navigation";

export function StripeCountryAndCurrencyBullet() {
  const [hasAtLeastOneMerchantAccount, setHasAtLeastOneMerchantAccount] = useState<boolean | null>(null);
  const { t } = useTranslation();
  const localeData = useSelector((state: IRootState) => state.auth.localeData);
  const { locale, currency } = localeData;

  useEffectAsync(async (): Promise<void> => {
    const merchantAccounts = await merchantAccountApi.getAll({
      page_size: 1,
      page_number: 1
    });

    const hasMerchantAccount = Boolean(
      merchantAccounts.status && merchantAccounts.data && merchantAccounts.data?.data.length > 0
    );

    setHasAtLeastOneMerchantAccount(hasMerchantAccount);
  }, []);

  const country = useMemo(() => regionNames.find((region) => region.name === locale)?.country || locale, [locale]);
  const currencyName = useMemo(
    () => currencyCodes.find((currencyCode) => currencyCode.code === currency)?.display || currency,
    [currency]
  );

  if (hasAtLeastOneMerchantAccount === null || hasAtLeastOneMerchantAccount) {
    return null;
  }

  return (
    <ElementPrefixedText
      iconProps={{ size: 14 }}
      text={
        <Trans
          i18nKey={AppStrings.Accounts.StripeApplyNowContentCountryAndCurrency}
          values={{
            country,
            currency: currencyName
          }}
          components={{
            bold: <span style={{ fontWeight: "bold" }} />
          }}
        />
      }
      textProps={{
        fullWidth: false,
        fontSize: 14
      }}
    >
      <Link
        textColor={ColorsEnum.BrightBlue}
        underline="always"
        onClick={NavigationManager.editRegionAndCurrencyWithAbsolute}
      >
        {t(AppStrings.Common.UpdateInfo)}
      </Link>
    </ElementPrefixedText>
  );
}
