"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAllJournalEntriesQuery = void 0;
const class_validator_1 = require("class-validator");
const getAllBaseRequest_query_1 = require("../getAllBaseRequest.query");
const accounts_1 = require("../accounts");
const leasePaymentDepositStatus_enum_1 = require("./leasePayments/leasePaymentDepositStatus.enum");
const isDateStringWithoutTime_validator_1 = require("../common/validators/isDateStringWithoutTime.validator");
const expensePayTo_enum_1 = require("./expenses/expensePayTo.enum");
const paymentMethod_enum_1 = require("./paymentMethod.enum");
const checkbookPaymentStatus_enum_1 = require("../outgoingPayments/checkbookPaymentStatus.enum");
const accountingMethod_enum_1 = require("./accountingMethod.enum");
const journalEntryType_enum_1 = require("./journalEntryType.enum");
const common_1 = require("../common");
class GetAllJournalEntriesQuery extends getAllBaseRequest_query_1.GetAllBaseQueryRequest {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.GetAllJournalEntriesQuery = GetAllJournalEntriesQuery;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_account", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_batch", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_reference", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllJournalEntriesQuery.prototype, "filter_account_include_subaccounts", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], GetAllJournalEntriesQuery.prototype, "filter_accounts", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(journalEntryType_enum_1.JournalEntryType),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_type", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(journalEntryType_enum_1.JournalEntryType, { each: true }),
    (0, class_validator_1.IsArray)(),
    __metadata("design:type", Array)
], GetAllJournalEntriesQuery.prototype, "filter_types", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(accounts_1.AccountType),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_accountType", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(accounts_1.AccountClass),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_accountClass", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(accounts_1.AccountClass, { each: true }),
    (0, class_validator_1.IsArray)(),
    __metadata("design:type", Array)
], GetAllJournalEntriesQuery.prototype, "filter_accountClasses", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(accountingMethod_enum_1.AccountingMethod),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_accountingMethod", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(leasePaymentDepositStatus_enum_1.DepositStatus),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_depositStatus", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_depositToAccount", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_property", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], GetAllJournalEntriesQuery.prototype, "filter_properties", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], GetAllJournalEntriesQuery.prototype, "filter_propertyOwners", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_propertyGroup", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_propertyOwner", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllJournalEntriesQuery.prototype, "filter_managementCompany", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_unit", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_lease", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_tenant", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_owner", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "normalize_owner", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_vendor", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], GetAllJournalEntriesQuery.prototype, "filter_vendors", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_ne_journalEntry", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_date_from", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_date_to", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_createdAt_from", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_createdAt_to", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_dueDate_to", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_dueDate_from", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_asOfDate", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllJournalEntriesQuery.prototype, "expand_references", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(200),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_ePay_TxnId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_plaidTransaction", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllJournalEntriesQuery.prototype, "filter_leaseDepositItemAccounts", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_registerAccount", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], GetAllJournalEntriesQuery.prototype, "filter_registerAmount", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllJournalEntriesQuery.prototype, "filter_registerCleared", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_registerPlaidTransaction", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_registerReconciliation", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllJournalEntriesQuery.prototype, "filter_registerReconciled", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_payableInfoPayFromAccount", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(expensePayTo_enum_1.ExpensePayToResourceType),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_payableInfoPayToResourceType", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_payableInfoPayToResourceId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(paymentMethod_enum_1.PaymentMethod),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_receivableInfoPaymentMethod", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(paymentMethod_enum_1.PaymentMethod),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_ne_receivableInfoPaymentMethod", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(common_1.EPayPaymentMethod),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_receivableInfoEPayInfoPaymentMethod", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(common_1.EPayPaymentStatus, { each: true }),
    (0, class_validator_1.IsArray)(),
    __metadata("design:type", Array)
], GetAllJournalEntriesQuery.prototype, "filter_receivableInfoEPayInfoPaymentStatuses", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(paymentMethod_enum_1.PaymentMethod),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_payableInfoPaymentMethod", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(paymentMethod_enum_1.PaymentMethod),
    __metadata("design:type", Object)
], GetAllJournalEntriesQuery.prototype, "filter_paymentMethod", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllJournalEntriesQuery.prototype, "filter_payableInfoCheckInfoPrintLater", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(checkbookPaymentStatus_enum_1.CheckbookPaymentStatusEnum),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_payableInfoEPayInfoStatus", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllJournalEntriesQuery.prototype, "filter_deleted", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "sort_by", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], GetAllJournalEntriesQuery.prototype, "sort_descending", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(common_1.EPayPaymentStatus),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_ePayStatus", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_merchant_account", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_availableOn_from", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_availableOn_to", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], GetAllJournalEntriesQuery.prototype, "filter_depositor_name", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllJournalEntriesQuery.prototype, "filter_has_availableOn_date", void 0);
