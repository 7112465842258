const localEnvs: RegExp[] = [/localhost/, /dev.localhost:3000/, /lvh.me:3000/];

export const prodEnvs: RegExp[] = [
  /app.doorloop.com/,
  /beta.doorloop.com/,
  /app-portal.doorloop.com/,
  /beta-portal.doorloop.com/
];

const devEnvs: RegExp[] = [/doorloop.info/, /dev.doorloop.com/, /dev-portal.doorloop.com/];

export const isLocalEnv = Boolean(localEnvs.find((url) => url.test(location.host)));
export const isDevelopmentEnv = Boolean(devEnvs.find((url) => url.test(location.host)));
export const isProductionEnv = Boolean(prodEnvs.find((url) => url.test(location.host)));

type Environment = "localhost" | "development" | "production";

const getEnv = (): Environment => {
  if (isLocalEnv) {
    return "localhost";
  }
  if (isDevelopmentEnv) {
    return "development";
  }
  return "production";
};
export const clientEnvironment = getEnv();
