"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenantPortalGetLeaseAccountsReceivableReportQuery = void 0;
const getLeaseAccountsReceivableReport_query_1 = require("../../../reports/leaseAccountsReceiveable/getLeaseAccountsReceivableReport.query");
class TenantPortalGetLeaseAccountsReceivableReportQuery extends getLeaseAccountsReceivableReport_query_1.GetLeaseAccountsReceivableReportQuery {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.TenantPortalGetLeaseAccountsReceivableReportQuery = TenantPortalGetLeaseAccountsReceivableReportQuery;
