import React from "react";
import { CompanyStartDateDto } from "@doorloop/dto";
import { Formik } from "formik";
import CompanyStartDate from "screens/settings/companyStartDate/companyStartDate";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import { createValidator } from "@doorloop/dto";

interface ComponentProps {
  onClose: () => void;
  onBackdropClick: () => void;
  dialogTitle?: string;
}

const validateForm = createValidator(CompanyStartDateDto);

const CompanyStartDateFormikWrapper: React.FC<ComponentProps> = ({
  dialogTitle,
  onBackdropClick,
  onClose
}: ComponentProps) => {
  const startDate: string | undefined = useSelector(
    (state: IRootState) => state.auth.currentLoginResponse?.currentDbTenant?.companyStartDate
  );
  const initFormValues = (): CompanyStartDateDto => new CompanyStartDateDto({ startDate });

  return (
    <Formik initialValues={initFormValues()} onSubmit={() => {}} validate={validateForm}>
      <CompanyStartDate onBackdropClick={onBackdropClick} onClose={onClose} dialogTitle={dialogTitle} />
    </Formik>
  );
};

export default CompanyStartDateFormikWrapper;
