import React from "react";
import { View } from "DLUI/view";
import { SectionTitle } from "DLUI/screen";
import { IconButton } from "DLUI/form";
import { CloseCircleIcon } from "assets/icons";
import ExportPdfButton from "DLUI/exportPdfButton/exportPdfButton";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  title: string;
  onClose: () => void;
  hideCloseButton?: boolean;
  showPrintButton?: boolean;
  showExportToPdfButton?: boolean;
  exportFileName?: string;
  onExportPdfButtonPress?: () => void;
  exportPdfInProgress?: boolean;
}

const DefaultExportFileName = "DL_PDF";

const TenantPortalDialogHeader: React.FC<ComponentProps> = ({
  title,
  onClose,
  hideCloseButton,
  onExportPdfButtonPress,
  showExportToPdfButton,
  exportFileName,
  exportPdfInProgress
}: ComponentProps) => {
  const { screenContainerPadding } = useResponsiveHelper();
  return (
    <View paddingLeft={screenContainerPadding} paddingRight={screenContainerPadding} flexDirection={"row"}>
      <View autoWidth>
        <SectionTitle
          type={"underline"}
          title={title}
          marginTop={20}
          textTransform={"uppercase"}
          alignItems={"flex-start"}
        />
      </View>
      {showExportToPdfButton && (
        <View marginTop={12} marginRight={40} flex={1} alignItems={"flex-end"}>
          <ExportPdfButton
            width={25}
            height={25}
            fileName={exportFileName || DefaultExportFileName}
            onExportPdfButtonPress={onExportPdfButtonPress}
            exportPdfInProgress={exportPdfInProgress}
          />
        </View>
      )}
      {!hideCloseButton && (
        <div
          style={{
            position: "absolute",
            right: screenContainerPadding,
            top: 20
          }}
        >
          <IconButton
            Icon={CloseCircleIcon}
            width={30}
            height={30}
            onClick={onClose}
            circleFill={"black"}
            removePadding
          />
        </div>
      )}
    </View>
  );
};

export default TenantPortalDialogHeader;
