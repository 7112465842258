import { FieldArray, useFormikContext } from "formik";
import DtoFieldArrayItem from "./DtoFieldArrayItem";
import { AddNewFormikLineButton } from "DLUI/form";
import type { ReactNode } from "react";
import React, { useEffect } from "react";
import { isArray, isEmpty } from "lodash";
import type { IIdentifiable } from "@doorloop/dto";
import { getUniqueIndex } from "@doorloop/dto";
import { View } from "DLUI/view";
import { useLastArrayItemRef } from "hooks/useLastArrayItemRef";
import type { RentalApplicationFieldControlledSectionSettingsDto } from "@doorloop/dto";

export interface DtoListFormRenderProps<T extends IIdentifiable> {
  item: T;
  name: string;
  stepSettingsDto?: RentalApplicationFieldControlledSectionSettingsDto;
  isControlled?: boolean;
}

export interface DtoListFormProps<T extends IIdentifiable> {
  /**
   * Render method, called for each child
   */
  children: (props: DtoListFormRenderProps<T>) => ReactNode;
  /**
   * String key for the heading of each list item
   */
  headerText?: string;
  /**
   * Path to the dto array on the form data
   * Same usage as Formik's FieldArray.name
   */
  name: string;
  /**
   * Callback to create a new dto when the user clicks the add new button
   */
  newItem: () => T;
  /**
   * Text key for the add new item button
   */
  newItemText: string;
}

export default function DtoFieldArray<T extends IIdentifiable>({
  children: renderChild,
  headerText,
  name,
  newItem,
  newItemText
}: DtoListFormProps<T>) {
  const { setFieldValue, getFieldMeta, values } = useFormikContext<Record<typeof name, T[]>>();
  const { value: list } = getFieldMeta<T[]>(name);
  // Guarantee at least one item in array on first render
  const initialValue = values?.[name];

  useEffect(() => {
    if (isEmpty(initialValue)) {
      setFieldValue(name, [newItem()]);
      return () => {
        // If the user hasn't entered any data, remove the item that we added
        if (list?.length === 1 && isEmpty(list[0])) {
          setFieldValue(name, undefined);
        }
      };
    }
  }, [initialValue, name]);

  if (list && !isArray(list)) {
    console.error(`Expected form field ${name} to be array`);
    return null;
  }

  const lastItemRef = useLastArrayItemRef<HTMLDivElement>(
    () => setFieldValue(name, [...list, newItem()]),
    [list, name, newItem]
  );

  return (
    <FieldArray
      name={name}
      render={({ push, remove, replace }) => (
        <>
          <View gap={20}>
            {list?.map((item, index) => (
              <DtoFieldArrayItem
                ref={index === list.length - 1 ? lastItemRef : undefined}
                key={getUniqueIndex(item)}
                title={headerText}
                onDeleteItem={() => (list.length !== 1 ? remove(index) : replace(index, newItem()))}
                deleteDisabled={list.length === 1 && isEmpty(item)}
              >
                {renderChild({ item, name: `${name}[${index}]` })}
              </DtoFieldArrayItem>
            ))}
          </View>
          <AddNewFormikLineButton onClick={() => push(newItem())} buttonText={newItemText} />
        </>
      )}
    />
  );
}
