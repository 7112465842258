import MomentUtils from "@date-io/moment";
import {
  RecurringTransactionType,
  TaskPriority,
  TaskStatus,
  TaskType,
  TenantRequestMaintenanceCategory,
  TenantRequestType,
  UserStatus
} from "@doorloop/dto";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { usersApi } from "api/usersApi";
import { FormikCheckBox, FormikDatePicker, Select, TextField } from "DLUI/form";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField } from "formik";
import AppStrings from "locale/keys";
import React from "react";
import { useTranslation } from "react-i18next";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { RecurringDate } from "DLUI/recurringDateForm/recurringDate";
import BasicGrid from "DLUI/listItems/basicGrid";
import FormikCachedMultiSelectAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedMultiSelectAutoComplete";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { TenantRequestInformation } from "@/components/screens/tenantPortal/components/dialogs/request/tenantRequestInformation";
import { OwnerRequestInformation } from "@/components/screens/ownerPortal/components/dialogs/request/ownerRequestInformation";

interface ComponentProps {
  formikRef?: FormikProps<any>;
  descriptionFieldLabel?: string;
  showNotifyTenant?: boolean;
  showNotifyOwner?: boolean;
  isWorkOrder?: boolean;
  disableRecurring?: boolean;
  userHasPermissionToUpdateTenantRequest?: boolean;
  displayRequestViewOnlyDetails?: boolean;
}

const TaskInfo: React.FC<ComponentProps> = ({
  formikRef,
  descriptionFieldLabel,
  showNotifyTenant,
  showNotifyOwner,
  isWorkOrder,
  disableRecurring,
  displayRequestViewOnlyDetails
}: ComponentProps) => {
  const { t } = useTranslation();
  const { verticalInputPadding } = useResponsiveHelper();
  const { isMobile } = useResponsiveHelper();

  const isMaintenanceFieldEnabled = formikRef?.values?.tenantRequestType === TenantRequestType.MAINTENANCE;

  const renderNotifyTenant = () => (
    <Grid justifyContent={"flex-start"} item lg={3} md={3} sm={3} xs={12}>
      <FastFieldSafe
        component={FormikCheckBox}
        labelText={AppStrings.Tasks.TaskDetails.NotifyTenant}
        name={"notifyTenant"}
      />
    </Grid>
  );

  const renderNotifyOwner = () => (
    <Grid justifyContent={"flex-start"} item lg={3} md={3} sm={3} xs={12}>
      <FastFieldSafe
        component={FormikCheckBox}
        labelText={AppStrings.Tasks.TaskDetails.NotifyOwner}
        name={"notifyOwner"}
      />
    </Grid>
  );

  const renderTaskNotificationsRow = () => (
    <View marginTop={20} flexDirection={"row"}>
      <Grid justifyContent={"flex-start"} item lg={4} md={4} sm={4} xs={12}>
        <FastFieldSafe
          component={FormikCheckBox}
          labelText={AppStrings.Tasks.TaskDetails.NotifyAssignees}
          name={"notifyAssignees"}
        />
      </Grid>
      {showNotifyTenant && renderNotifyTenant()}
      {showNotifyOwner && renderNotifyOwner()}
    </View>
  );

  const onTenantRequestTypeChange = (nextValue: string) => {
    if (nextValue === TenantRequestType.MAINTENANCE) {
      formikRef?.setFieldTouched("tenantRequestMaintenanceCategory", true);
    }
    if (nextValue === TenantRequestType.GENERAL) {
      formikRef?.setFieldValue("tenantRequestMaintenanceCategory", undefined);
    }
  };

  const isTaskTypeTenantRequest = formikRef?.values?.type === TaskType.TENANT_REQUEST;
  const isTaskTypeOwnerRequest = formikRef?.values?.type === TaskType.OWNER_REQUEST;

  const requestTypeFieldsSection = (
    <View
      flexDirection={isMobile ? "column" : "row"}
      justifyContent={isMobile ? undefined : "space-between"}
      gap={isMobile ? "unset" : 16}
      noWrap={!isMobile}
      marginBottom={20}
    >
      <FastField
        component={Select}
        label={t(AppStrings.Common.RequestType)}
        name={"tenantRequestType"}
        uniqueKey={"requestType"}
        selectionEnum={TenantRequestType}
        translationKey={"tenantRequestType"}
        required
        viewProps={{ flex: 1, width: isMobile ? "100%" : "unset" }}
        onChange={onTenantRequestTypeChange}
      />
      <FastField
        component={Select}
        label={t(AppStrings.Common.MaintenanceType)}
        name={"tenantRequestMaintenanceCategory"}
        uniqueKey={"tenantRequestMaintenanceCategory"}
        selectionEnum={TenantRequestMaintenanceCategory}
        translationKey={"tenantRequestMaintenanceCategory"}
        viewProps={{ flex: 1, width: isMobile ? "100%" : "unset" }}
        disabled={!isMaintenanceFieldEnabled}
        key={isMaintenanceFieldEnabled.toString()}
      />
    </View>
  );

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <View marginTop={20} alignItems={"center"} height={"100%"} width={"100%"}>
        {displayRequestViewOnlyDetails ? (
          <>
            {isTaskTypeTenantRequest && (
              <View>
                <TenantRequestInformation requestData={formikRef?.values} />
                <View marginTop={20}>{requestTypeFieldsSection}</View>
              </View>
            )}
            {isTaskTypeOwnerRequest && <OwnerRequestInformation requestData={formikRef?.values} />}
          </>
        ) : (
          <>
            {isTaskTypeTenantRequest && requestTypeFieldsSection}
            <FastField
              component={TextField}
              label={t(AppStrings.Tasks.InternalTaskDialog.Subject)}
              name={`subject`}
              required
            />
            <FastField
              component={TextField}
              label={descriptionFieldLabel || t(AppStrings.Leases.LeaseCharge.Description)}
              name={`description`}
              multiline
              rows="4"
              marginTop={20}
              maxLength={10000}
            />
          </>
        )}
        <HorizontalSeparationLine marginTop={20} />
        <RecurringDate
          type={RecurringTransactionType.TASK}
          isWorkOrder={isWorkOrder}
          disableRecurring={disableRecurring}
        >
          <View flexDirection={"row"}>
            <BasicGrid preset={"66612"}>
              <View paddingRight={verticalInputPadding}>
                <FastField
                  component={FormikDatePicker}
                  uniqueKey={"dueDate"}
                  label={AppStrings.Leases.NewLease.LeaseRent.DueDate}
                  name={"dueDate"}
                  noMargin
                  marginTop={20}
                />
              </View>
            </BasicGrid>
          </View>
        </RecurringDate>
        <HorizontalSeparationLine marginTop={20} />
        <View flexDirection={"row"}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <View paddingRight={verticalInputPadding}>
              <FastField
                component={Select}
                name={`status`}
                label={AppStrings.Overview.Status}
                required
                uniqueKey={"taskStatus"}
                selectionEnum={TaskStatus}
                translationKey={"taskStatus"}
                marginTop={20}
              />
            </View>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <View paddingLeft={verticalInputPadding}>
              <FastField
                component={Select}
                name={`priority`}
                label={AppStrings.Tasks.InternalTaskDialog.Priority}
                uniqueKey={"taskPriority"}
                selectionEnum={TaskPriority}
                translationKey={"taskPriority"}
                marginTop={20}
              />
            </View>
          </Grid>
        </View>
        <FormikCachedMultiSelectAutoComplete
          uniqueIndex={"usersApi"}
          apiHandler={usersApi}
          displayNameKey={"name"}
          filterFieldName={"filter_text"}
          filterFieldValue={"name"}
          selectionFields={["id", "class"]}
          name={"assignedToUsers"}
          queryParams={{ filter_status: UserStatus.ACTIVE }}
          label={t(AppStrings.Tasks.InternalTaskDialog.Assignees)}
          marginTop={20}
        />
        {renderTaskNotificationsRow()}
      </View>
    </MuiPickersUtilsProvider>
  );
};

export default TaskInfo;
