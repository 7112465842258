import { atom, useAtom, useSetAtom } from "jotai";

import type { ConversationClientDto } from "@doorloop/dto";

import { currentConversationAtom } from "screens/communicationsCenter/shared/currentConversationAtom";
import { NavigationManager } from "../../../../utils/navigation";

export const conversationsDataSourceAtom = atom<ConversationClientDto[]>([]);

export const useConversationsListDataSource = () => {
  const [dataSource, setDataSource] = useAtom(conversationsDataSourceAtom);

  const setCurrentConversation = useSetAtom(currentConversationAtom);

  const updateConversationInDataSource = (
    conversation: ConversationClientDto,
    shouldSelect = true,
    moveToTop = false
  ): void => {
    let updatedDataSource: ConversationClientDto[];

    if (moveToTop) {
      updatedDataSource = [
        { ...conversation, isSelected: shouldSelect ? true : conversation.isSelected },
        ...dataSource.filter((row) => row.id !== conversation.id)
      ];
    } else {
      updatedDataSource = dataSource.map((row) =>
        conversation.id === row.id
          ? { ...conversation, isSelected: shouldSelect ? true : row.isSelected }
          : { ...row, isSelected: shouldSelect ? false : row.isSelected }
      );
    }

    setDataSource(updatedDataSource);
  };

  const appendToConversationsList = (conversation: ConversationClientDto): void => {
    const updatedDataSource = [{ ...conversation, isSelected: true }, ...dataSource];
    setDataSource(updatedDataSource);
  };

  const unSelectConversationInDataSource = (): void => {
    const overrideDataSource = dataSource.map((row) => {
      return { ...row, isSelected: false };
    });
    setDataSource(overrideDataSource);
  };

  const removeConversationFromList = (conversation: ConversationClientDto): void => {
    const conversationIndex = dataSource.findIndex((row) => row.id === conversation.id);
    const updatedDataSource = dataSource.map((row, index) => {
      if (index === conversationIndex + 1 && conversation.isSelected) {
        NavigationManager.viewConversation(row.id);
        setCurrentConversation(row);
        return { ...row, isSelected: true };
      }
      return conversation.id === row.id ? { ...row, isHidden: true } : row;
    });

    setDataSource(updatedDataSource);
  };

  return {
    dataSource,
    setDataSource,
    updateConversationInDataSource,
    unSelectConversationInDataSource,
    appendToConversationsList,
    removeConversationFromList
  };
};
