import React from "react";
import { accountsApi } from "api/accounts";
import AppStrings from "locale/keys";

import type { DepositLinkedTransactionDto, GetAllBaseQueryResponse } from "@doorloop/dto";

import { View } from "DLUI/view";
import { SectionTitle } from "DLUI/screen";
import { ControlledCheckBox } from "DLUI/form";
import { StickyHeader } from "DLUI/stickyHeader";
import { DataListScroll } from "DLUI/dataList/components/dataListScroll";
import { useResponsiveHelper } from "contexts/responsiveContext";

import UndepositsEntryLine from "./undepositsEntryLine";
import type { UseInfiniteQueryResult } from "@tanstack/react-query";
import type { ApiResult } from "api/apiResult";
import { ListEmptyIndicator } from "DLUI/dataList/components/listEmptyIndicator";
import type { UndepositsSearchProps } from "DLUI/dialogs/otherTransactions/bankDeposit/undepositsSearchInput";
import { UndepositsSearchInput } from "DLUI/dialogs/otherTransactions/bankDeposit/undepositsSearchInput";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { useTypedTranslation } from "locale";
import _ from "lodash";

const CONTAINER_ID = "undepositsEntriesLines_container";
const HEADERS_ID = "undepositsEntriesLines_headers";

interface Props
  extends Pick<
    UseInfiniteQueryResult<ApiResult<GetAllBaseQueryResponse<DepositLinkedTransactionDto>>>,
    "hasNextPage" | "isFetchingNextPage" | "fetchNextPage"
  > {
  accountId?: string;
  allRows?: DepositLinkedTransactionDto[];
  areAllLinesChecked: boolean;
  areAnyLinesChecked: boolean;
  onToggleLine: (index: number) => void;
  onToggleAllLines: () => void;
  loadingDialogState: DialogState;
  searchProps?: UndepositsSearchProps;
  totalAllData: number;
  totalChecked: number;
}

const UndepositsEntryList = ({
  accountId,
  allRows,
  onToggleLine,
  onToggleAllLines,
  areAllLinesChecked,
  areAnyLinesChecked,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  loadingDialogState,
  searchProps,
  totalAllData,
  totalChecked
}: Props) => {
  const { t } = useTypedTranslation();
  const { isTabletOrMobile } = useResponsiveHelper();

  if (!accountId || allRows === undefined) {
    return null;
  }

  const accountDictionary = accountsApi.getItemFromDictionary(accountId);
  let accountName = "";
  if (accountDictionary?.name) {
    accountName = accountDictionary.name;
  }

  return (
    <View marginTop={20}>
      <SectionTitle
        fontSize={16}
        title={t("otherTransactions.bankDeposit.depositDetailsFor", { accountName })}
        type={"underline"}
      />

      {searchProps && (
        <View marginTop={20}>
          <UndepositsSearchInput {...searchProps} />
        </View>
      )}

      <View marginTop={20}>
        {isTabletOrMobile ? (
          <View style={{ transform: "translateX(-9px)" }}>
            <ControlledCheckBox
              checked={areAllLinesChecked}
              onChange={onToggleAllLines}
              labelValueProps={{
                text: AppStrings.Common.SelectAll
              }}
            />
          </View>
        ) : (
          <StickyHeader
            id={HEADERS_ID}
            headerPaddingLeft={0}
            shouldShow
            uniqueKey={"UDL"}
            position={"grid"}
            bulkActions={{
              isAllChecked: areAllLinesChecked,
              isAnyChecked: areAnyLinesChecked,
              totalChecked,
              totalViewable: allRows.length,
              totalAll: totalAllData,
              isTotalAllChecked: false,
              selectTotalAll: _.noop,
              clearTotalAll: _.noop,
              bulkActionDefinitions: [],
              fireAction: _.noop,
              toggleAll: onToggleAllLines
            }}
          />
        )}

        {loadingDialogState !== DialogState.Hidden ? (
          <View fullWidth alignItems={"center"}>
            <LoadingDialog dialogState={DialogState.Show} hideDismissButton />
          </View>
        ) : (
          !allRows.length && <ListEmptyIndicator />
        )}

        <DataListScroll
          infinite={true}
          rows={allRows}
          renderRow={(item, index) => (
            <View key={"UDL" + index} flexDirection={"row"} noWrap>
              <UndepositsEntryLine
                headerId={HEADERS_ID}
                depositLinkedTransactionDto={item}
                onChangeSelected={() => onToggleLine(index)}
              />
            </View>
          )}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
          scrollElement={document.getElementById(CONTAINER_ID)}
          paddingEnd={40}
        />
      </View>
    </View>
  );
};

export { UndepositsEntryList };
