import React from "react";
import { View } from "DLUI/view";
import type { Syndication } from "screens/settings/listingSyndication/listingSyndication.constant";
import ListingSyndicationItem from "screens/settings/listingSyndication/listingSyndicationItem";

import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface Props {
  syndicationItem: Syndication;
}

const ListingSyndicationAutoSyncItem: React.FC<Props> = ({ syndicationItem }: Props) => {
  const { screenContainerPadding } = useResponsiveHelper();
  return (
    <View
      flexDirection={"row"}
      backgroundColor={"white"}
      paddingRight={screenContainerPadding}
      paddingLeft={screenContainerPadding}
    >
      <ListingSyndicationItem syndicationItem={syndicationItem} />
    </View>
  );
};

export default ListingSyndicationAutoSyncItem;
