import type { RegisterItemDto } from "@doorloop/dto";
import { Icon } from "DLUI/icon";
import BasicGrid from "DLUI/listItems/basicGrid";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import { AnimatedView } from "DLUI/view/animatedView";
import React, { useEffect, useState } from "react";
import { ErrorSignIcon } from "../../../../assets";
import AppStrings from "../../../../locale/keys";
import { NavigationManager } from "../../../../utils/navigation";
import DLButton, { DLButtonColorsEnum } from "DLUI/button/dlButton";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface Props {
  register?: RegisterItemDto[];
}

const ReconciledNotificationView: React.FC<Props> = ({ register }: Props) => {
  const { isMobile, screenContainerPadding } = useResponsiveHelper();

  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [reconciliationId, setReconciliationId] = useState<string | undefined>();

  useEffect(() => {
    register?.forEach((registerItem) => {
      if (registerItem.reconciled) {
        setShowNotification(true);
        setReconciliationId(registerItem.reconciliation);
      }
    });
  });

  const didPressViewReconciliation = () => {
    NavigationManager.viewBankReconciliationReport(reconciliationId);
  };

  return (
    <AnimatedView shouldShow={showNotification}>
      <View height={20} />
      <View flexDirection={"row"} height={isMobile ? undefined : 80} borderRadius={10} backgroundColor={"light-green"}>
        <BasicGrid justify={isMobile ? "center" : "flex-start"} sm={1}>
          <Icon
            Source={ErrorSignIcon}
            pathColor={"green"}
            size={40}
            marginTop={isMobile ? 20 : 0}
            marginLeft={isMobile ? 0 : 20}
          />
        </BasicGrid>
        <BasicGrid sm={9}>
          <View
            alignItems={isMobile ? "center" : "flex-start"}
            marginTop={isMobile ? 20 : 0}
            paddingLeft={screenContainerPadding}
            marginRight={screenContainerPadding}
          >
            <Text bold value={AppStrings.Common.TransactionReconciled} />
            <Text
              value={AppStrings.Common.TransactionReconciledDescription}
              marginTop={5}
              align={isMobile ? "center" : "left"}
              fontSize={14}
            />
          </View>
        </BasicGrid>
        {!isMobile && (
          <BasicGrid justify={isMobile ? "center" : "flex-end"} sm={2}>
            {reconciliationId && (
              <DLButton
                onClick={didPressViewReconciliation}
                actionText={AppStrings.Common.ViewReconciliation}
                color={DLButtonColorsEnum.SECONDARY}
                style={{ minWidth: 200, marginTop: isMobile ? 20 : 0, marginRight: isMobile ? 20 : 0 }}
              />
            )}
          </BasicGrid>
        )}
        {isMobile && <View height={20} />}
      </View>
    </AnimatedView>
  );
};

export default ReconciledNotificationView;
