export const Overview = {
  Status: "overview.status",
  Occupied: "overview.occupied",
  Available: "overview.available",
  Issues: "overview.issues",
  Eviction: "overview.eviction",
  MarketRent: "overview.marketRent",
  ListingStatus: "overview.listingStatus",
  Open: "overview.open",
  Tasks: "overview.tasks",
  Active: "overview.active",
  Inactive: "overview.inactive",
  USDollar: "overview.usDollar",
  Listed: "overview.listed",
  NotListed: "overview.notListed",
  QuickActions: "overview.quickActions",
  QuickActionsInstructions: "overview.quickActionsInstructions",
  RenewalProcess: "overview.renewalProcess",
  CashPaymentDetails: "overview.cashPaymentDetails",
  CashPaymentDetailsInstructions: "overview.cashPaymentDetailsInstructions"
};

export default Overview;
