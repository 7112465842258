/* eslint-disable @typescript-eslint/no-use-before-define */

import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import AppStrings from "locale/keys";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import DeleteConfirmation from "DLUI/dialogs/components/deleteConfirmation";
import ReconciledNotificationView from "DLUI/dialogs/components/reconciledNotificationView";
import DialogAmountView from "DLUI/dialogs/components/totalAmountView";
import AccountBalanceLoader from "DLUI/dialogs/refund/accountBalanceLoader";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import DialogFrame from "../../components/dialogFrame";
import ExpenseTransactionLine from "../expenseTransactionLine";

import { ActivityLabel } from "@/components/DLUI/activityLabel/activityLabel";
import { PeopleAutoComplete } from "@/components/DLUI/form/autoComplete/peopleAutoComplete/peopleAutoComplete";
import { usePeopleAutoComplete } from "@/components/DLUI/form/autoComplete/peopleAutoComplete/usePeopleAutoComplete";
import { useAnalyticsService } from "@/hooks/useAnalyticsService";
import { useSearchParams } from "@/hooks/useSearchParams";
import type {
  AccountDto,
  BaseDto,
  ExpenseDto,
  PersonTypeEnum,
  RecurringTransactionDto,
  VendorDto
} from "@doorloop/dto";
import {
  AccountType,
  DataCy,
  DuplicateDialogButtonLabelValues,
  ExpensePayToResourceType,
  ExpenseTransactionLineDto,
  LinkedResourceType,
  OnetimeToRecurringMapper,
  PaymentMethod,
  PaymentMethodNoEpay,
  PrintChecksReportItemDto,
  RecurringTransactionType,
  ReportPermission,
  SegmentEventTypes,
  TenantDto,
  TenantType
} from "@doorloop/dto";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DialogHeaderActionButtons } from "DLUI/actionButtons/dialogHeaderActionButtons";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { Button } from "DLUI/button";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import { VoidConfirmation } from "DLUI/dialogs/components/voidConfirmation";
import { DUPLICATE_MODE_QUERY_PARAMS } from "DLUI/dialogs/duplicateModeHelper";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import { FormikDatePicker, FormikSwitchButton, Select, TextField, ValidationIndicator } from "DLUI/form";
import { ListInputsContainer } from "DLUI/listItems";
import { Notes } from "DLUI/notes";
import { RecurringDate } from "DLUI/recurringDateForm/recurringDate";
import { ReferenceLabelByRecurring } from "DLUI/recurringDateForm/referenceLabelByRecurring";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { ArticleIdsEnum, HelpTypeEnum } from "DLUI/screen/helpPanel/types";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { View } from "DLUI/view";
import { entityApiStore } from "api/entityApiStore/entityApiStore";
import { expensesApi } from "api/expensesApi";
import { filesApi } from "api/filesApi";
import { leasesRecurringTransactionsApi } from "api/leasesApi";
import { vendorsApi } from "api/vendorsApi";
import { AddIcon } from "assets/icons";
import { ExpenseCta } from "components/DLUI/dialogs/vendor/expense/cta/expenseCta";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { FastField, Field, FieldArray, useFormikContext } from "formik";
import { useLastArrayItemRef } from "hooks/useLastArrayItemRef";
import { useRecurringState } from "hooks/useRecurringState";
import { useUserType } from "hooks/useUserType";
import { cloneDeep, isEmpty, last, omit, size, sumBy } from "lodash";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { OutgoingPaymentNotification } from "screens/outgoingPayments/paymentDialogNotification/outgoingPaymentNotification";
import { usePermission } from "screens/settings/userRoles/usePermission";
import { analyticsService } from "services/analyticsService";
import { v4 as uuid } from "uuid";
import { payeeTypeToResourceTypeMap } from "./utils";
import type { DLButtonProps } from "DLUI/button/dlButton";
import type { ApiResult } from "api/apiResult";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { useJournalEntryPrinting } from "hooks/useJournalEntryPrinting";

export interface VendorExpenseDialogProps {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
  dialogFrameHeight: number;
  dialogFrameWidth: number;
  permission?: AnyPermissionClearance;
  editingRecurring?: boolean;
}

enum DialogView {
  Loading,
  Form,
  UpdateLoading,
  DeleteConfirmation,
  VoidConfirmation,
  CallToAction
}

const DIALOG_VIEWS_NUM = size(DialogView);

interface RouteParams {
  payeeId?: string;
  transactionId?: string;
  vendorId?: string;
}

const helpObject: HelpPanelProps = {
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Vendors.LearnMoreExpense,
      articleId: ArticleIdsEnum.CREATE_AN_EXPENSE
    }
  ],
  description: AppStrings.Vendors.ExpenseDescription
};

const helpObjectHOA: HelpPanelProps = {
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Vendors.LearnMoreExpense,
      articleId: ArticleIdsEnum.HOA_CREATE_EXPENSE
    }
  ],
  description: AppStrings.Vendors.ExpenseDescription
};

const VendorExpenseDialog: React.FC<VendorExpenseDialogProps> = ({
  onBackdropClick,
  onClose,
  dialogTitle,
  permission,
  editingRecurring,
  dialogFrameHeight,
  dialogFrameWidth
}) => {
  const { t } = useTranslation();
  const { isHOAUser } = useUserType();
  const { isMobile, screenContainerPadding } = useResponsiveHelper();

  const inputPadding = isMobile ? 0 : 10;

  const routeParams = useParams<RouteParams>();
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = {
    payeeId: searchParams.get("payeeId"),
    payeeType: searchParams.get("payeeType"),
    transactionId: searchParams.get(DUPLICATE_MODE_QUERY_PARAMS.transactionId)
  };

  const vendorId = routeParams.vendorId;
  const transactionId = queryParams.transactionId || routeParams.transactionId;

  const isEditMode = Boolean(transactionId);
  const isDuplicateMode = Boolean(queryParams.transactionId);

  const { getValidator, getIsRecurring, getRecurringData } = useRecurringState(isEditMode, editingRecurring);
  const expenseLinkedResourceType = getIsRecurring()
    ? LinkedResourceType.RecurringTransactionExpense
    : LinkedResourceType.Expense;

  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [viewIndex, setViewIndex] = useState(DialogView.Form);

  const formik = useFormikContext<ExpenseDto>();
  const { setFieldValue } = formik;

  const setPayeeFields = useCallback(
    (vendorId?: string, payeeIdBase?: string, payeeTypeBase?: ExpensePayToResourceType) => {
      const payeeId = payeeIdBase || formik.values.payToResourceId || queryParams.payeeId || vendorId;
      const payeeTypeFromQueryParam: ExpensePayToResourceType | undefined = queryParams.payeeType
        ? payeeTypeToResourceTypeMap[queryParams.payeeType]
        : undefined;
      const payeeType: ExpensePayToResourceType | undefined =
        payeeTypeBase ||
        (vendorId ? ExpensePayToResourceType.VENDOR : payeeTypeFromQueryParam || formik.values.payToResourceType);

      if (payeeId && payeeType) {
        setFieldValue("payToResourceId", payeeId);
        setFieldValue("payToResourceType", payeeType);
      }

      return { payeeId, payeeType };
    },
    [
      formik.values.payToResourceId,
      formik.values.payToResourceType,
      queryParams.payeeId,
      queryParams.payeeType,
      setFieldValue
    ]
  );

  const { payeeId: initialPayeeId, payeeType: initialPayeeType } = useMemo(
    () => setPayeeFields(routeParams.vendorId, routeParams.payeeId),
    [routeParams.payeeId, routeParams.vendorId, setPayeeFields]
  );

  const [payeeId, setPayeeId] = useState<string | undefined>(initialPayeeId);
  const [payeeType, setPayeeType] = useState(initialPayeeType);
  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);

  const [invalidAmountError, setInvalidAmountError] = useState<boolean>(false);

  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    isEditMode ? DialogState.Show : DialogState.Hidden
  );
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");

  const [ePayBankAccountSelected, setEPayBankAccountSelected] = useState<boolean>(
    formik.values.paymentMethod === PaymentMethod.EPAY
  );
  const [depositAccountId, setDepositAccountId] = useState<string | undefined>(formik.values.payFromAccount);
  const [isPaymentMethodCheck, setIsPaymentMethodCheck] = useState<boolean>(
    formik.values.paymentMethod === PaymentMethod.CHECK || false
  );

  const isInitializedWithPaymentMethodCheckRef = useRef<boolean>(isPaymentMethodCheck);
  const lastSaveButtonSelectedIndexRef = useRef(0);

  const [printCheckLater, setPrintCheckLater] = useState<boolean>(formik.values.checkInfo?.printLater || false);
  const [payeeSupportsEPay, setPayeeSupportsEPay] = useState<boolean | null>(null);
  const [renderPaymentMethodField, setRenderPaymentMethodField] = useState<boolean>(true);

  const { mutateAsync: voidCheck } = entityApiStore.expenses.mutations.useVoidCheck();
  const { dispatchAnalytics } = useAnalyticsService();
  const isVoidedCheck = formik.values.isVoidedCheck ?? false;
  const isReconciled = useMemo(
    () => formik.values.register?.some((item) => item.reconciled) ?? false,
    [formik.values.register]
  );

  const duplicateModeTitle = useMemo(
    () =>
      isDuplicateMode ? `${t(AppStrings.Vendors.VendorDetails.NewExpense)} (${t(AppStrings.Common.Duplicated)})` : "",
    [isDuplicateMode, t]
  );

  useEffect(() => {
    isEditMode && setDepositAccountId(formik.values.payFromAccount);
  }, [isEditMode, formik.values.payFromAccount]);

  const [vendorDto, setVendorDto] = useState<VendorDto>();

  const setLines = useCallback(async () => {
    if (formik.values.payToResourceId && !isEditMode) {
      const vendorDto = await vendorsApi.get(formik.values.payToResourceId);
      const transactionLines = vendorDto?.data?.accounts?.map(
        (account) => new ExpenseTransactionLineDto({ account, uniqueKey: uuid() })
      );
      if (transactionLines?.length) {
        setFieldValue("lines", transactionLines);
      } else {
        setFieldValue("lines", [
          new ExpenseTransactionLineDto({
            uniqueKey: uuid()
          })
        ]);
      }
      setVendorDto(vendorDto?.data);
    }
  }, [formik.values.payToResourceId, isEditMode, setFieldValue]);

  useEffect(() => {
    setLines();
  }, [setLines]);

  const isValidForm = async () => {
    formik.setFieldTouched("dueDate");
    formik.setFieldTouched("date");
    formik.setFieldTouched("checkInfo.checkNumber");
    formik.setFieldTouched("paymentMethod");
    formik.setFieldTouched("payFromAccount");
    formik.setFieldTouched("payToResourceId");

    let totalAmount = 0;
    formik.values.lines?.forEach((line, index) => {
      formik.setFieldTouched(`lines[${index}].linkedToResourceType`);
      formik.setFieldTouched(`lines[${index}].linkedToResourceId`);
      formik.setFieldTouched(`lines[${index}].amount`);
      formik.setFieldTouched(`lines[${index}].property`);
      formik.setFieldTouched(`lines[${index}].account`);
      totalAmount += line.amount ?? 0;
    });
    setInvalidAmountError(totalAmount < 0);

    const errorsBase = await formik.validateForm();
    const isRecurring = getIsRecurring();
    const errors = isRecurring ? omit(errorsBase, ["date"]) : errorsBase;

    return isEmpty(errors) && totalAmount >= 0 && (await isRecurringValid());
  };

  async function isRecurringValid() {
    const isRecurring = getIsRecurring();

    let isValid = true;

    const validator = getValidator();

    if (isRecurring && validator) {
      isValid = await validator();
    }

    return isValid;
  }

  const showErrorMessage = (message: string = t(AppStrings.Common.NetworkErrorSubTitle)) => {
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(message);
  };

  const createTransaction = async (isAddNew = false) => {
    const recurringData = getRecurringData();
    const isRecurring = getIsRecurring();
    const expenseValues = formik.values;

    setViewIndex(DialogView.UpdateLoading);
    setLoadingDialogState(DialogState.Show);

    let response: ApiResult<ExpenseDto | RecurringTransactionDto> | void;

    if (isRecurring && recurringData) {
      const recurringExpense = OnetimeToRecurringMapper.buildRecurring.expense(expenseValues, recurringData);
      response = await leasesRecurringTransactionsApi.create(recurringExpense).catch(showErrorMessage);
    } else {
      response = await expensesApi.create(expenseValues).catch(showErrorMessage);
    }

    if (response?.status && response.data) {
      if (attachments.length > 0 && response.data.id) {
        const fileUploadResponse = await filesApi
          .uploadFiles(attachments, response.data.id, expenseLinkedResourceType)
          .catch(showErrorMessage);

        if (!fileUploadResponse || !fileUploadResponse.data) {
          showErrorMessage(fileUploadResponse?.message);
        }
      }

      if (expenseValues.payToResourceType) {
        dispatchAnalytics(SegmentEventTypes.PAYABLE_TRANSACTION_CREATED, {
          transactionType: RecurringTransactionType.EXPENSE,
          payeeType: expenseValues.payToResourceType,
          isRecurring: Boolean(isRecurring),
          isUnitEmpty: !expenseValues.lines?.some(({ unit }) => unit),
          totalAmount: sumBy(expenseValues.lines, (line: ExpenseTransactionLineDto) => line.amount || 0),
          numOfLineItems: size(expenseValues.lines)
        });
      } else {
        console.warn("Parameter `payToResourceType` is missing, skipping Analytics event.");
      }

      if (isAddNew || isRecurring) {
        handleClose(isAddNew);
      } else {
        setViewIndex(DialogView.CallToAction);
      }
    } else {
      showErrorMessage(response?.message);
    }
  };

  const handleClose = (isAddNew = false) => {
    if (isAddNew) {
      formik.resetForm();
      setRefreshOnClose(true);
      setDepositAccountId(undefined);
      setIsPaymentMethodCheck(false);
      setPrintCheckLater(false);
      setAttachments([]);
      setPayeeFields(vendorId, payeeId, payeeType);
      setViewIndex(DialogView.Form);
    } else {
      onClose();
    }
  };

  const updateTransaction = async () => {
    if (!transactionId) {
      return;
    }

    const isRecurring = getIsRecurring();
    const recurringData = getRecurringData();
    const expenseValues = cloneDeep(formik.values);

    if (expenseValues.paymentMethod !== PaymentMethod.CHECK) {
      delete expenseValues.checkInfo;
    }

    setViewIndex(DialogView.UpdateLoading);

    let response: ApiResult<BaseDto> | void;

    if (isRecurring && recurringData) {
      const recurringExpense = OnetimeToRecurringMapper.buildRecurring.expense(expenseValues, recurringData);
      response = await leasesRecurringTransactionsApi.update(transactionId, recurringExpense).catch(showErrorMessage);
    } else {
      response = await expensesApi.update(transactionId, expenseValues).catch(showErrorMessage);
    }

    if (response?.data?.id) {
      if (attachments.length) {
        await filesApi.uploadFiles(attachments, response.data.id, expenseLinkedResourceType).catch(showErrorMessage);
      }

      setLoadingDialogState(DialogState.Hidden);
      onClose();
      return;
    }

    showErrorMessage(response?.message);
  };

  const handleSave = async (saveButtonIndex?: number, isAddNew?: boolean) => {
    const isValid = await isValidForm();

    if (isPayeeIdle && isValid) {
      if (isEditMode && !isDuplicateMode) {
        await updateTransaction();
      } else {
        lastSaveButtonSelectedIndexRef.current = saveButtonIndex || 0;
        await createTransaction(isAddNew);
      }
    }
  };

  const isOneVendorAccount = vendorDto?.accounts?.length === 1;

  const addLine = () => {
    const previousLine = last(formik.values.lines);
    const lines = formik.values.lines || [];

    formik.setFieldValue("lines", [
      ...lines,
      new ExpenseTransactionLineDto({
        property: previousLine?.property,
        unit: previousLine?.unit,
        uniqueKey: uuid(),
        ...(payeeType === ExpensePayToResourceType.VENDOR &&
          isOneVendorAccount &&
          vendorDto.accounts?.[0] && {
            account: vendorDto.accounts[0]
          })
      })
    ]);
  };

  const lastLineRef = useLastArrayItemRef<HTMLDivElement>(addLine, []);

  const renderAddButton = () => (
    <Button
      color={"lightBlue"}
      type={"inlineText"}
      actionText={AppStrings.Leases.LeaseCharge.AddLineItem}
      onClick={addLine}
      LeftIcon={AddIcon}
      iconSize={15}
      applyColorForIcons
    />
  );

  const renderLines = () => (
    <FieldArray
      name={"lines"}
      render={(arrayHelpers) => {
        const lines = formik.values.lines;
        const linesJSX = lines?.map((currentLine, lineIndex) => (
          <View style={{ width: "100%" }} key={currentLine.uniqueKey}>
            <View noWrap shouldShow showAnimation={"fade-in"} hideAnimation={"fade-out"}>
              <ExpenseTransactionLine
                domRef={lineIndex === lines.length - 1 ? lastLineRef : undefined}
                transactionItem={currentLine}
                key={lineIndex}
                lineIndex={lineIndex}
                arrayHelpers={arrayHelpers}
                name={"lines"}
                formikRef={formik}
              />
            </View>
          </View>
        ));

        return (
          <View>
            <ListInputsContainer>{linesJSX}</ListInputsContainer>
            <View height={40} alignItems={"flex-end"} flexDirection={"row"}>
              {renderAddButton()}
            </View>
          </View>
        );
      }}
    />
  );

  const renderSummaryLine = () => {
    const totalAmount = sumBy(formik.values.lines, (currentLine: ExpenseTransactionLineDto) => currentLine.amount || 0);
    return (
      <View alignItems={"flex-end"} marginTop={20}>
        <DialogAmountView amount={totalAmount} title={AppStrings.Vendors.VendorDetails.TotalExpense} />
        <ValidationIndicator
          shouldShow={invalidAmountError || false}
          maxWidth={320}
          displayText={t(AppStrings.Common.AmountMustBeGreaterThanZero)}
          marginTop={"20px"}
        />
      </View>
    );
  };

  const onFileReceived = (files: FileListItemProps[]) => {
    setAttachments(files);
  };

  const renderAttachments = () => (
    <View justifyContent={"flex-end"} width={"100%"} flex={1} marginBottom={20}>
      <FormAttachments
        editMode={isEditMode}
        resourceType={expenseLinkedResourceType}
        resourceId={transactionId || formik.values.id}
        onFileReceived={onFileReceived}
        files={attachments}
      />
    </View>
  );

  const didSelectDepositAccount = (event: object, value?: AccountDto) => {
    formik.setFieldValue("paymentMethod", undefined);
    formik.setFieldTouched("paymentMethod", false);

    if (value?.id) {
      setDepositAccountId(value.id);
      refreshPaymentMethodField();
      setEPayBankAccountSelected(Boolean(value.outgoingEPayEnabled));

      if (value.outgoingEPayEnabled) {
        formik.setFieldValue("paymentMethod", PaymentMethod.EPAY);
      }

      formik.setFieldTouched("payFromAccount", false, false);
    } else {
      formik.setFieldValue("paymentMethod", undefined);
      setDepositAccountId(undefined);
      setEPayBankAccountSelected(false);
    }
  };

  const didChangePaymentMethod = (nextValue: string) => {
    if (nextValue === PaymentMethod.CHECK) {
      setIsPaymentMethodCheck(true);
    } else {
      setIsPaymentMethodCheck(false);
    }
  };

  const didChangePrintCheckLaterSwitch = (nextSwitchState: boolean) => {
    setPrintCheckLater(nextSwitchState);
  };

  const renderNotes = () => (
    <View marginTop={20} justifyContent={"center"} height={50}>
      <FastField component={Notes} height={30} name={"memo"} />
    </View>
  );

  const { hasPermission } = usePermission();

  const createPrintChecksPermission = hasPermission(ReportPermission.printChecks);

  const resetPayee = () => {
    setPayeeId(undefined);
    setPayeeType(undefined);
    setPayeeSupportsEPay(null);
    formik.setFieldValue("payToResourceId", undefined);
    formik.setFieldValue("payToResourceType", undefined);
  };

  // The Payee field is optional, because users can create an anonymous expense
  // which they update later. If we create a new payee, it should temporarily
  // disable the `idle` state of the Payee field in order to ensure the form
  // will be submitted with the newly created _and_ selected payee.
  const {
    state: { isIdle: isPayeeIdle },
    props: payeeFieldProps
  } = usePeopleAutoComplete({
    currentType: payeeType?.toLowerCase() as PersonTypeEnum,
    props: {
      entityId: payeeId,
      textFieldProps: {
        placeholder: t("vendors.vendorDetails.selectPayee")
      },
      onChange(_event, value) {
        if (value === null) {
          resetPayee();
          return;
        }

        const valueData = value?.data;
        const personType = valueData?.type;
        const personDto = valueData?.original;

        if (personDto) {
          // Creatable option should derive its ID from the API response.
          personDto.id = valueData.id;
        }

        const isLeaseTenant = personDto instanceof TenantDto && personDto.type === TenantType.LEASE_TENANT;
        const hasEPaySupport = Boolean(personDto?.outgoingEPay?.enabled);

        // TODO This logic seems inoperable, but it follows the same logic as
        // before, E-Pay is not included the list of payment methods.
        if (!isLeaseTenant && hasEPaySupport && ePayBankAccountSelected) {
          formik.setFieldValue("paymentMethod", PaymentMethod.EPAY);
        }

        setPayeeSupportsEPay(hasEPaySupport);
        setPayeeId(personDto?.id);

        formik.setFieldValue("payToResourceId", personDto?.id);

        if (personType) {
          const payeeType = payeeTypeToResourceTypeMap[personType];
          setPayeeType(payeeType);
          formik.setFieldValue("payToResourceType", payeeType);
        }
      },
      dataCy: DataCy.formFields.selectPerson
    }
  });

  const renderFormTopSection = () => (
    <View>
      <View flexDirection={"row"}>
        <Grid container item xs={12} sm={8} md={8}>
          <View marginTop={20} flex={1} justifyContent={"flex-start"} alignItems={"center"}>
            <Field name="payee" component={PeopleAutoComplete} {...payeeFieldProps} />
          </View>
        </Grid>
        <Grid container item xs={12} sm={4} md={4}>
          <View flexDirection={"row"} flex={1}>
            <Grid item xs={12} sm={12} md={12}>
              <View marginTop={20} marginBottom={10} paddingLeft={isMobile ? 0 : 20}>
                <ReferenceLabelByRecurring referenceLabel={AppStrings.Common.ListHeader.Reference} />
              </View>
            </Grid>
          </View>
        </Grid>
        <HorizontalSeparationLine marginTop={20} />
        <RecurringDate type={RecurringTransactionType.EXPENSE}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <FastField
              component={FormikDatePicker}
              uniqueKey={"expenseDate"}
              label={AppStrings.Vendors.VendorDetails.ExpenseDate}
              name={"date"}
              required
            />
          </Grid>
        </RecurringDate>
      </View>
      <View flexDirection={"row"}>
        <HorizontalSeparationLine marginTop={20} />
        <View flexDirection={"row"}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <View>
              <BankAccountFormikAutoCompleteField
                fullWidth
                uniqueIndex={"TS"}
                queryParams={{
                  filter_types: [AccountType.ASSET_BANK, AccountType.LIABILITY_CREDIT_CARD],
                  filter_active: true
                }}
                label={t(AppStrings.Leases.LeaseTransaction.Refund.PayFromAccount)}
                selectionFields={["outgoingEPayEnabled"]}
                name={"payFromAccount"}
                onChange={didSelectDepositAccount}
                marginTop={20}
                paddingRight={inputPadding}
                triggerOnChangeOnInitialLoad
                addCreateOption
                viewOnly={isEditMode && formik.values.paymentMethod === PaymentMethod.EPAY}
                fullWidthViewOnlyView
              />
            </View>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <AccountBalanceLoader depositAccountId={depositAccountId} marginTop={20} paddingLeft={inputPadding} />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            {renderPaymentMethodField && (
              <FastField
                component={Select}
                name={`paymentMethod`}
                label={AppStrings.Leases.LeaseTransaction.Payment.PaymentMethod}
                required
                uniqueKey={"paymentMethod"}
                selectionEnum={ePayBankAccountSelected ? PaymentMethod : PaymentMethodNoEpay}
                onChange={didChangePaymentMethod}
                translationKey={"paymentMethod"}
                marginTop={20}
                paddingLeft={inputPadding}
                viewOnly={Boolean(formik.values.ePayInfo?.status)}
                enableEPay
                fullWidthViewOnly
              />
            )}
          </Grid>
          {isPaymentMethodCheck && !printCheckLater ? (
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <FastField
                component={TextField}
                label={t(AppStrings.Leases.LeaseTransaction.Payment.CheckNumber)}
                name={"checkInfo.checkNumber"}
                marginTop={20}
                paddingRight={inputPadding}
              />
            </Grid>
          ) : null}
          {isPaymentMethodCheck && createPrintChecksPermission ? (
            <Grid alignItems={"center"} item xs={12} sm={4} md={4} lg={4}>
              <View alignItems={"flex-start"} justifyContent={"center"} height={"100%"} marginTop={25}>
                <FastField
                  component={FormikSwitchButton}
                  name={"checkInfo.printLater"}
                  label={AppStrings.Leases.LeaseTransaction.Refund.AddToPrintQueue}
                  onChange={didChangePrintCheckLaterSwitch}
                />
              </View>
            </Grid>
          ) : null}
        </View>
      </View>
    </View>
  );

  const renderForm = () => {
    const formValues = formik.values;
    const infoDto = new PrintChecksReportItemDto({
      amount: formValues.ePayInfo?.amount || formValues.totalAmount,
      payToResourceId: formValues.payToResourceId,
      payToResourceType: formValues.payToResourceType,
      journalEntry: transactionId,
      payFromAccount: formValues.payFromAccount
    });
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <View
          flex={1}
          paddingLeft={screenContainerPadding}
          paddingRight={screenContainerPadding}
          flexDirection={"column"}
          width={"100%"}
        >
          <RestrictedPermissionAccess clearance={permission} showNoAccess>
            {transactionId && formValues.paymentMethod === PaymentMethod.EPAY && (
              <OutgoingPaymentNotification
                dto={infoDto}
                payeeSupportsEPay={payeeSupportsEPay}
                closeCurrentDialog={onBackdropClick}
                journalEntryId={transactionId}
                status={formValues.ePayInfo?.status}
                method={formValues.ePayInfo?.method}
                paymentNumber={formValues.ePayInfo?.number}
              />
            )}
            <ReconciledNotificationView register={formik.values.register} />
            {renderFormTopSection()}
            <HorizontalSeparationLine marginTop={20} />
            {renderLines()}
            {renderNotes()}
            {renderSummaryLine()}
            {renderAttachments()}
          </RestrictedPermissionAccess>
          {isEditMode && <ActivityLabel item={formik.values} />}
        </View>
      </MuiPickersUtilsProvider>
    );
  };

  const handleCancelButtonClick = (): void => {
    setViewIndex(DialogView.Form);
  };

  const handleVoidButtonClick = (): void => {
    setViewIndex(DialogView.VoidConfirmation);
  };

  const handleVoidSubmitButtonClick = async (): Promise<void> => {
    if (!transactionId) {
      return;
    }

    setViewIndex(DialogView.UpdateLoading);

    const voidCheckResult = await voidCheck(transactionId);

    if (voidCheckResult.status) {
      analyticsService.track(SegmentEventTypes.CHECK_VOIDED, {
        transactionType: "expense",
        payeeType,
        isUnitEmpty: formik.values.lines?.some((line) => line.unit),
        totalAmount: sumBy(formik.values.lines, (line) => line.amount ?? 0),
        numOfLineItems: size(formik.values.lines)
      });

      onClose();
    } else {
      showErrorMessage(voidCheckResult.message);
    }
  };

  const renderView = ({ index }: { index: number }) => {
    const onRetryButtonPress = async () => {
      await handleSave();
    };
    const didPressDismissButton = () => {
      setViewIndex(DialogView.Form);
    };

    if (index === DialogView.Loading) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          errorText={loadingDialogErrorText}
          didPressDismissButton={didPressDismissButton}
        />
      );
    }
    if (index === DialogView.Form) {
      return renderForm();
    }
    if (index === DialogView.UpdateLoading) {
      return (
        <View flex={1} alignItems={"center"} justifyContent={"center"} width={"100%"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            errorText={loadingDialogErrorText}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
          />
        </View>
      );
    }
    if (viewIndex === DialogView.DeleteConfirmation) {
      return (
        <DeleteConfirmation
          apiMethod={getIsRecurring() ? leasesRecurringTransactionsApi : expensesApi}
          apiToasts={
            getIsRecurring()
              ? { translationKey: AppStrings.Toasts.custom.recurring[RecurringTransactionType.EXPENSE]?.DELETE }
              : undefined
          }
          didPressDismissButton={handleCancelButtonClick}
          didFinishOperation={onClose}
          transactionId={transactionId}
          attachments={attachments}
        />
      );
    }

    if (viewIndex === DialogView.VoidConfirmation) {
      return <VoidConfirmation onCancel={handleCancelButtonClick} onSubmit={handleVoidSubmitButtonClick} />;
    }

    if (viewIndex === DialogView.CallToAction) {
      const { paymentMethod, payFromAccount, lines, reference } = formik.values;
      const propertiesUniqueCount = new Set(lines?.map((line) => line.property)).size;
      const expenseTotal = sumBy(lines, (line) => line.amount ?? 0);

      return (
        <ExpenseCta
          accountId={paymentMethod === PaymentMethod.CHECK ? payFromAccount : undefined}
          paymentMethod={paymentMethod}
          reference={reference}
          expense={expenseTotal}
          propertiesUniqueCount={propertiesUniqueCount}
          payeeType={payeeType}
        />
      );
    }

    return <div />;
  };

  const handleBackdropClick = () => {
    if (viewIndex === DialogView.CallToAction) {
      onClose();
      return;
    }

    if (viewIndex > 1) {
      setViewIndex(viewIndex - 1);
      return;
    }

    if (refreshOnClose) {
      onClose();
      return;
    }

    onBackdropClick();
  };

  const handleDeleteClick = () => {
    setViewIndex(DialogView.DeleteConfirmation);
  };

  const handleDuplicateClick = () => {
    if (!transactionId) {
      return;
    }

    setSearchParams((prevSearchParams) => {
      prevSearchParams.set(DUPLICATE_MODE_QUERY_PARAMS.transactionId, transactionId);
      return prevSearchParams;
    });
    dispatchAnalytics("button_click", {
      label: DuplicateDialogButtonLabelValues.DUPLICATE_JOURNAL_ENTRY
    });
  };

  const { printJournalEntry, printLoadingStatus } = useJournalEntryPrinting({ journalEntryId: transactionId });

  const renderHeaderActionButtons = () => (
    <DialogHeaderActionButtons
      onDeleteClick={handleDeleteClick}
      onDuplicateClick={handleDuplicateClick}
      onPrintClick={isEditMode ? printJournalEntry : undefined}
      printLoadingStatus={printLoadingStatus}
      clearance={permission}
      hideDeleteButton={isDuplicateMode || !isEditMode}
      hideDuplicateButton={isDuplicateMode || !isEditMode}
    />
  );

  const renderActionPanelButtons = () => {
    const customButtons: DLButtonProps[] = [];

    if (
      isEditMode &&
      isPaymentMethodCheck &&
      isInitializedWithPaymentMethodCheckRef.current &&
      !isVoidedCheck &&
      !isReconciled &&
      !getIsRecurring()
    ) {
      customButtons.push({
        actionText: AppStrings.Bills.Checks.Void,
        onClick: handleVoidButtonClick
      });
    }

    return (
      <FormActionButtons
        clearance={permission}
        propsActionPanel={{
          editMode: isEditMode,
          customButtons
        }}
        propsSubButton={{ onClick: handleBackdropClick }}
        propsMainButton={
          isEditMode && !isDuplicateMode
            ? { type: "cta", props: { onClick: async () => await handleSave() } }
            : {
                type: "split",
                props: {
                  lastSaveButtonIndex: lastSaveButtonSelectedIndexRef.current,
                  options: [
                    { text: AppStrings.Common.Save, onClick: async () => await handleSave() },
                    {
                      text: AppStrings.Common.SaveAndNew,
                      onClick: async (saveButtonIndex) => await handleSave(saveButtonIndex, true)
                    }
                  ]
                }
              }
        }
      />
    );
  };

  const frameType = useMemo(() => {
    if (
      viewIndex === DialogView.UpdateLoading ||
      viewIndex === DialogView.Loading ||
      viewIndex === DialogView.DeleteConfirmation ||
      viewIndex === DialogView.VoidConfirmation
    ) {
      return "contentOnly";
    }

    if (viewIndex === DialogView.Form) {
      return "sectionTitleFrame";
    }

    return "topPanel";
  }, [viewIndex]);

  const currentTitle = useMemo(() => {
    if (viewIndex === DialogView.Form) {
      return dialogTitle;
    }

    return "";
  }, [dialogTitle, viewIndex]);

  const refreshPaymentMethodField = () => {
    setRenderPaymentMethodField(false);
    setTimeout(() => {
      setRenderPaymentMethodField(true);
    });
  };

  const helpPanelProp = useMemo(() => {
    const helpPanelPropBase = !isHOAUser ? helpObject : helpObjectHOA;
    return viewIndex === DialogView.CallToAction ? undefined : helpPanelPropBase;
  }, [viewIndex, isHOAUser]);

  return (
    <DialogFrame
      onCloseButtonClick={handleBackdropClick}
      title={duplicateModeTitle || currentTitle}
      width={viewIndex === DialogView.CallToAction ? getDialogFrameDimension("width", 375) : dialogFrameWidth}
      height={viewIndex === DialogView.CallToAction ? getDialogFrameDimension("height", 490) : dialogFrameHeight}
      renderView={renderView}
      numViews={DIALOG_VIEWS_NUM}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      RenderHeaderActionButtons={renderHeaderActionButtons}
      frameType={frameType}
      useExperimentalDialogFrame
      helpPanel={helpPanelProp}
    />
  );
};

export default VendorExpenseDialog;
