import React from "react";
import { View } from "DLUI/view";
import clsx from "clsx";
import Dialog from "DLUI/dialogs/dialog";
import Text from "DLUI/text";
import ColorsEnum from "../../../utils/colorsEnum";
import AppStrings from "../../../locale/keys";
import { Icon } from "DLUI/icon";
import { ExclamationMarkIconPink } from "../../../assets";
import BaseButton from "DLUI/button/baseButton";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";

interface ComponentProps {
  errorMessage?: string;
  onCLose: () => void;
}

const DialogWidth = 540;
const DialogHeight = 360;

const ErrorDialog: React.FC<ComponentProps> = ({ errorMessage, onCLose }: ComponentProps) => {
  const { screenContainerPadding, isMobile } = useResponsiveHelper();

  return (
    <Dialog open className={clsx(["DLUI_Dialog"])} onClose={onCLose}>
      <View
        alignItems={"center"}
        width={isMobile ? "100%" : DialogWidth}
        height={getDialogFrameDimension("height", DialogHeight)}
        backgroundColor={"light"}
        overflow={"scroll"}
      >
        <View
          paddingLeft={screenContainerPadding}
          paddingRight={screenContainerPadding}
          alignItems={"center"}
          height={"100%"}
          noWrap
        >
          <View justifyContent={"center"} alignItems={"center"} marginTop={40}>
            <Icon Source={ExclamationMarkIconPink} width={50} height={50} />
            <Text bold fontSize={22} value={AppStrings.Common.NetworkErrorTitle} align={"center"} marginTop={20} />
          </View>
          <Text marginTop={20} marginBottom={20} align={"center"} fullWidth>
            {errorMessage}
          </Text>
          <View flex={1} width={"100%"} alignItems={"center"} justifyContent={"flex-end"}>
            <BaseButton
              onClick={onCLose}
              actionText={AppStrings.Common.Dismiss}
              backgroundColor={ColorsEnum.Transparent}
              width={180}
            />
            <View height={40} />
          </View>
        </View>
      </View>
    </Dialog>
  );
};

export default ErrorDialog;
