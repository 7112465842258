import qs from "query-string";

import type { GetProfitAndLossReportQuery } from "@doorloop/dto";
import { AccountingMethod, LeaseTenantStatus, SegmentEventTypes, TaskStatusForQuery } from "@doorloop/dto";

import { history } from "store/history";
import { OwnerPortalDialogRoutes } from "DLUI/screen/dialogsProvider/ownerPortal/ownerPortalDialogsRoutes";
import { Routes } from "components/appRouter";
import { analyticsService } from "../services/analyticsService";
import type { BudgetVsActualReportForm } from "screens/reports/budgetVsActual/types";

class OwnerPortalNavigation {
  viewHome(): void {
    history.push({
      pathname: Routes.OWNER_PORTAL_HOME
    });
  }

  viewTransactions(): void {
    analyticsService.track(SegmentEventTypes.OWNER_PORTAL_TRANSACTIONS_PAGE_VIEWED);

    history.push({
      pathname: Routes.OWNER_PORTAL_TRANSACTIONS
    });
  }

  viewRequests(): void {
    analyticsService.track(SegmentEventTypes.OWNER_PORTAL_REQUESTS_PAGE_VIEWED);

    history.push({
      pathname: Routes.OWNER_PORTAL_REQUESTS
    });
  }

  viewReports(): void {
    history.push({
      pathname: Routes.OWNER_PORTAL_REPORTS
    });
  }

  editLoginAndPassword(pathname: string): void {
    history.push({
      pathname: `${pathname}${OwnerPortalDialogRoutes.EDIT_LOGIN_AND_PASSWORD}`
    });
  }

  addNewRequest(): void {
    history.push({
      pathname: `${Routes.OWNER_PORTAL_BASE_URL}/requests${OwnerPortalDialogRoutes.NEW_REQUEST}`
    });
  }

  viewRequest(requestId: string): void {
    history.push({
      pathname: `${Routes.OWNER_PORTAL_BASE_URL}/requests${OwnerPortalDialogRoutes.EDIT_REQUEST}/${requestId}`
    });
  }

  runAccountTransactionsReport(accountId?: string, query: Record<string, string> = {}): void {
    const extraQuery = {
      filter_accountingMethod: AccountingMethod.CASH
    };

    if (accountId) {
      extraQuery["filter_account"] = accountId;
    }

    const searchQuery = qs.stringify({ ...extraQuery, ...query });

    history.push({
      pathname: Routes.OWNER_PORTAL_TRANSACTION_DETAILS_REPORT,
      search: searchQuery
    });
  }

  runBankBalanceByPropertyReport(query: Record<string, string> = {}, newWindow = false): void {
    const searchQuery = qs.stringify(query);

    if (newWindow) {
      const win = window.open(`${Routes.OWNER_PORTAL_BANK_BALANCE_BY_PROPERTY}/?${searchQuery}`, "_blank");
      if (win !== null) {
        win.focus();
      }
    } else {
      history.push({
        pathname: Routes.OWNER_PORTAL_BANK_BALANCE_BY_PROPERTY,
        search: searchQuery
      });
    }
  }

  runProfitAndLossReport(query: GetProfitAndLossReportQuery = {}): void {
    const searchQuery = qs.stringify({
      filter_accountingMethod: "CASH",
      ...query
    });

    history.push({
      pathname: Routes.OWNER_PORTAL_PROFIT_AND_LOSS_REPORT,
      search: searchQuery
    });
  }

  runBalanceSheetReport(query: Record<string, string> = {}): void {
    const searchQuery = qs.stringify({
      filter_accountingMethod: "CASH",
      ...query
    });

    history.push({
      pathname: Routes.OWNER_PORTAL_BALANCE_SHEET_REPORT,
      search: searchQuery
    });
  }

  runCashFlowStatementReport(query: Record<string, string> = {}): void {
    const searchQuery = qs.stringify({
      filter_accountingMethod: "CASH",
      ...query
    });

    history.push({
      pathname: Routes.OWNER_PORTAL_CASH_FLOW_STATEMENT_REPORT,
      search: searchQuery
    });
  }

  runOwnerStatementReport = (query: Record<string, string> = {}): void => {
    const searchQuery = qs.stringify({
      filter_accountingMethod: "CASH",
      ...query
    });

    history.push({
      pathname: Routes.OWNER_PORTAL_OWNER_STATEMENT_REPORT,
      search: searchQuery
    });
  };

  runPropertyReserveFundsReport(query: Record<string, string> = {}): void {
    history.push({
      pathname: Routes.OWNER_PORTAL_PROPERTY_RESERVE_FUNDS_REPORT,
      search: qs.stringify(query)
    });
  }

  runRentRollReport(query: Record<string, string> = {}): void {
    history.push({
      pathname: Routes.OWNER_PORTAL_RENT_ROLL_REPORT,
      search: qs.stringify(query)
    });
  }

  runRentRollSummaryByBedsAndBathsReport(query: Record<string, string> = {}): void {
    history.push({
      pathname: Routes.OWNER_PORTAL_REPORTS_RENT_ROLL_SUMMARY_BY_BEDS_AND_BATHS,
      search: qs.stringify(query)
    });
  }

  runRentRollSummaryByPropertyReport(query: Record<string, string> = {}): void {
    history.push({
      pathname: Routes.OWNER_PORTAL_REPORTS_RENT_ROLL_SUMMARY_BY_PROPERTY,
      search: qs.stringify(query)
    });
  }

  runPropertyOwnershipReport(query: Record<string, string> = {}): void {
    history.push({
      pathname: Routes.OWNER_PORTAL_PROPERTY_OWNERSHIP_REPORT,
      search: qs.stringify(query)
    });
  }

  runOwners1099Report(query: Record<string, string> = {}): void {
    history.push({
      pathname: Routes.OWNER_PORTAL_OWNERS_1099_REPORT,
      search: qs.stringify(query)
    });
  }

  runProspectsReport(query: Record<string, string> = {}): void {
    history.push({
      pathname: Routes.OWNER_PORTAL_PROSPECTS_REPORT,
      search: qs.stringify(query)
    });
  }

  runRentalApplicationsReport(query: Record<string, string> = {}): void {
    history.push({
      pathname: Routes.OWNER_PORTAL_RENTAL_APPLICATIONS_REPORT,
      search: qs.stringify(query)
    });
  }

  runTasksReport(query: Record<string, string | undefined> = {}): void {
    const searchQuery = qs.stringify({
      filter_status: TaskStatusForQuery.NOT_COMPLETED,
      ...query
    });

    history.push({
      pathname: Routes.OWNER_PORTAL_TASKS_REPORT,
      search: searchQuery
    });
  }

  runTenantsReport(query: Record<string, string> = {}): void {
    history.push({
      pathname: Routes.OWNER_PORTAL_TENANTS_REPORT,
      search: qs.stringify(query)
    });
  }

  runTenantVehiclesReport(query: Record<string, string> = {}): void {
    const searchQuery = qs.stringify({
      filter_status: LeaseTenantStatus.CURRENT,
      ...query
    });

    history.push({
      pathname: Routes.OWNER_PORTAL_TENANT_VEHICLES_REPORT,
      search: searchQuery
    });
  }

  runTenantsPetsReport(query: Record<string, string> = {}): void {
    const searchQuery = qs.stringify({
      filter_status: LeaseTenantStatus.CURRENT,
      ...query
    });

    history.push({
      pathname: Routes.OWNER_PORTAL_TENANTS_PETS_REPORT,
      search: searchQuery
    });
  }

  runAccountPayableAgingDetailReport(query: Record<string, string> = {}): void {
    history.push({
      pathname: Routes.OWNER_PORTAL_ACCOUNTS_PAYABLE_AGING_DETAIL,
      search: qs.stringify(query)
    });
  }

  runAccountPayableAgingDetailByAccountReport(query: Record<string, string> = {}): void {
    history.push({
      pathname: Routes.OWNER_PORTAL_ACCOUNTS_PAYABLE_AGING_DETAIL_BY_ACCOUNT,
      search: qs.stringify(query)
    });
  }

  runAccountReceivableAgingDetailReport(query: Record<string, string> = {}): void {
    history.push({
      pathname: Routes.OWNER_PORTAL_ACCOUNTS_RECEIVABLE_AGING_DETAIL,
      search: qs.stringify(query)
    });
  }

  runBudgetVsActualReport = (searchQuery?: Partial<BudgetVsActualReportForm>) =>
    history.push({
      pathname: Routes.OWNER_PORTAL_BUDGET_VS_ACTUAL_REPORT,
      search: searchQuery ? qs.stringify(searchQuery) : undefined
    });

  runAccountReceivableAgingDetailByAccountReport(query: Record<string, string> = {}): void {
    history.push({
      pathname: Routes.OWNER_PORTAL_ACCOUNTS_RECEIVABLE_AGING_DETAIL_BY_ACCOUNT,
      search: qs.stringify(query)
    });
  }

  runAccountReceivableAgingSummaryReport(query: Record<string, string> = {}): void {
    history.push({
      pathname: Routes.OWNER_PORTAL_ACCOUNTS_RECEIVABLE_AGING_SUMMARY,
      search: qs.stringify(query)
    });
  }

  runRecurringTransactionsDetailReport(query: Record<string, string> = {}): void {
    history.push({
      pathname: Routes.OWNER_PORTAL_RECURRING_TRANSACTIONS_DETAIL_REPORT,
      search: qs.stringify(query)
    });
  }

  showConfirmDialog(locationPath: string): void {
    const nextPath =
      locationPath.indexOf(OwnerPortalDialogRoutes.CONFIRM) === -1
        ? locationPath + OwnerPortalDialogRoutes.CONFIRM
        : locationPath;

    history.push(nextPath, {});
  }
}

export const ownerPortalNavigation = new OwnerPortalNavigation();
