import { SegmentEventTypes } from "@doorloop/dto";
import { CloseCircleIcon } from "assets/icons";
import { Routes } from "components/appRouter";
import { ShakeEffectView } from "DLUI/animatableView";
import { DialogFrame } from "DLUI/dialogs";
import { IconButton } from "DLUI/form";
import { Link } from "DLUI/link";
import Text from "DLUI/text";
import { BackgroundImageView, View } from "DLUI/view";
import AppStrings from "locale/keys";
import React, { useEffect, useState } from "react";
import { analyticsService } from "services/analyticsService";
import { NavigationManager } from "utils/navigation";
import DLButton, { DLButtonSizesEnum } from "DLUI/button/dlButton";

interface ComponentProps {
  onClose: () => void;
  onBackdropClick: () => void;
}

const RegisterAccountOfferDialog: React.FC<ComponentProps> = ({ onClose, onBackdropClick }: ComponentProps) => {
  const [showErrorShakeEffect, setShowErrorShakeEffect] = useState(false);
  const didPressRegister = () => {
    analyticsService.track(SegmentEventTypes.EXIT_INTENT_ACTION_TAKEN, {
      ab_button_text: "Get Free Access",
      action: "Trial",
      ab_header_text: "Want to get a free trial instead?",
      ab_subheader_text: "Start using the software right away",
      ab_background_color: "white",
      category: "Leads",
      label: "Demo"
    });

    NavigationManager.signUp(Routes.DEMO_ADDITIONAL_INFO, location.search);
  };

  useEffect(() => {
    setTimeout(() => {
      runShakeEffect();
    }, 500);
  }, []);

  const runShakeEffect = () => {
    setShowErrorShakeEffect(true);
    setTimeout(() => {
      setShowErrorShakeEffect(false);
    }, 300);
  };
  const renderView = () => (
    <ShakeEffectView showEffect={showErrorShakeEffect}>
      <View alignItems={"center"} paddingRight={20} paddingLeft={20}>
        <View marginTop={20} alignItems={"flex-end"}>
          <IconButton Icon={CloseCircleIcon} onClick={onBackdropClick} width={30} height={30} />
        </View>
        <BackgroundImageView height={150} publicFileName={`url(/images/trialPreviewDrawing.png)`} />

        <Text
          maxWidth={325}
          fontSize={30}
          fontWeight={700}
          value={AppStrings.Common.FreeTrialOfferDescription}
          marginTop={20}
          align={"center"}
        />
        <Text
          maxWidth={325}
          fontSize={18}
          value={AppStrings.Common.StartUsingTheSoftware}
          marginTop={20}
          align={"center"}
        />

        <DLButton
          onClick={didPressRegister}
          actionText={AppStrings.Auth.GetFreeAccess}
          size={DLButtonSizesEnum.LARGE}
          style={{ minWidth: 345, marginTop: 50 }}
        />

        <Link onClick={onBackdropClick} hoverColor={"gray"}>
          <Text marginTop={20} value={AppStrings.Common.NoThanks} color={"gray"} />
        </Link>
      </View>
    </ShakeEffectView>
  );
  return (
    <View>
      <DialogFrame
        onCloseButtonClick={onBackdropClick}
        width={415}
        height={550}
        renderView={renderView}
        numViews={1}
        activeView={0}
        frameType={"contentOnly"}
        keepViewsMounted={false}
      />
    </View>
  );
};

export default RegisterAccountOfferDialog;
