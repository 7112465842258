import type { GetAllConversationsQuery, PersonDto, PortalInfoDto } from "@doorloop/dto";
import {
  ConversationLinkedToType,
  ConversationMethod,
  ConversationParticipantDto,
  EmailType,
  PhoneType,
  SearchCollections
} from "@doorloop/dto";
import { conversationUtils } from "screens/communicationsCenter/shared/conversationUtils";
import _ from "lodash";

class ConversationRecipientInputUtils {
  mapSearchCollectionToRecipientType(from: SearchCollections): ConversationLinkedToType | null {
    switch (from) {
      case SearchCollections.OWNERS:
        return ConversationLinkedToType.OWNER;
      case SearchCollections.VENDORS:
        return ConversationLinkedToType.VENDOR;
      case SearchCollections.PROSPECT_TENANTS:
        return ConversationLinkedToType.PROSPECT;
      case SearchCollections.LEASE_TENANTS:
        return ConversationLinkedToType.TENANT;
      default:
        return null;
    }
  }

  mapPersonToConversationParticipant(
    from: PersonDto & { portalInfo?: PortalInfoDto },
    linkedToType: ConversationLinkedToType
  ): ConversationParticipantDto {
    const isTypeWithPortalInfo = [ConversationLinkedToType.OWNER, ConversationLinkedToType.TENANT].includes(
      linkedToType
    );

    const email =
      (isTypeWithPortalInfo && from.portalInfo?.loginEmail) ||
      from.emails?.find((email) => email?.type === EmailType.PRIMARY)?.address;
    const phoneNumber =
      from.e164PhoneMobileNumber ||
      (isTypeWithPortalInfo && from.portalInfo?.mobilePhone) ||
      from.phones?.find((phone) => phone?.type === PhoneType.MOBILE)?.number;

    return new ConversationParticipantDto({
      linkedToId: from.id,
      linkedToType,
      email,
      phoneNumber
    });
  }

  getRecipientInputAdditionalDetails(recipient: ConversationParticipantDto, method: ConversationMethod) {
    const recipientAdditionalInfo = conversationUtils.getConversationRecipientAdditionalInfo(recipient, method, true);

    if (recipient.email) {
      recipientAdditionalInfo.push({ label: recipient.email });
    }

    return recipientAdditionalInfo;
  }

  isRecipientValid(recipient?: ConversationParticipantDto, method = ConversationMethod.PHONE) {
    if (!recipient) {
      return false;
    }

    if (method === ConversationMethod.PHONE) {
      const recipientDto = conversationUtils.getParticipantFromDictionary(recipient);
      return Boolean(recipientDto?.e164PhoneMobileNumber);
    }
    const contactDetails = conversationUtils.getParticipantContactDetails(recipient, method);
    return !_.isEmpty(contactDetails);
  }

  getAllConversationsQueryFilterByRecipient: (recipient: ConversationParticipantDto) => GetAllConversationsQuery = (
    recipient
  ) => {
    const map: Record<
      Exclude<ConversationLinkedToType, "SYSTEM" | "USER">,
      Pick<GetAllConversationsQuery, "filter_vendor" | "filter_prospect" | "filter_tenant" | "filter_owner">
    > = {
      [ConversationLinkedToType.VENDOR]: { filter_vendor: recipient.linkedToId },
      [ConversationLinkedToType.PROSPECT]: { filter_prospect: recipient.linkedToId },
      [ConversationLinkedToType.TENANT]: { filter_tenant: recipient.linkedToId },
      [ConversationLinkedToType.OWNER]: { filter_owner: recipient.linkedToId }
    };

    return recipient.linkedToType ? { ...map[recipient.linkedToType], filter_method: ConversationMethod.PHONE } : {};
  };
}

export const conversationRecipientInputUtils = new ConversationRecipientInputUtils();
