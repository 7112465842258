import type { GetAllNotesQuery, NoteDto } from "@doorloop/dto";
import { ServerRoutes } from "@doorloop/dto";
import { apiHelper } from "./apiHelper";
import type { ApiResult } from "./apiResult";
import { tagsApi } from "./tagsApi";
import { usersApi } from "./usersApi";
import { RestApiBaseWithDictionary } from "api/restApiBaseWithDictionary";
import { leasesApi } from "api/leasesApi";
import { propertiesApi } from "api/propertiesApi";
import { unitsApi } from "api/unitsApi";
import { tenantsApi } from "api/tenantsApi";
import { vendorsApi } from "api/vendorsApi";
import { ownersApi } from "api/ownersApi";

export class NotesApi extends RestApiBaseWithDictionary<NoteDto, GetAllNotesQuery> {
  constructor() {
    super(ServerRoutes.NOTES, [
      tagsApi,
      usersApi,
      leasesApi,
      propertiesApi,
      unitsApi,
      tenantsApi,
      vendorsApi,
      ownersApi
    ]);
    this.restRoute = ServerRoutes.NOTES;
  }

  async update(id: string, data: NoteDto): Promise<ApiResult<NoteDto>> {
    return await apiHelper.axiosPut<NoteDto>({ url: this.restRoute + "/" + id, data });
  }

  async delete(id: string): Promise<ApiResult<{}>> {
    return await apiHelper.axiosDelete<{}>({ url: this.restRoute + "/" + id });
  }
}

export const notesApi = new NotesApi();
