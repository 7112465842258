import { RestApiBase } from "api/restApiBase";
import type { InsurancePolicyDto } from "@doorloop/dto";
import { ServerRoutes } from "@doorloop/dto";
import { apiHelper } from "api/apiHelper";

export class JournalEntryApi extends RestApiBase<InsurancePolicyDto, void> {
  constructor() {
    super(ServerRoutes.JOURNAL_ENTRIES);
    this.restRoute = ServerRoutes.JOURNAL_ENTRIES;
  }

  getJournalEntryPdf = async (id: string) =>
    await apiHelper.axiosGetFile(ServerRoutes.JOURNAL_ENTRIES_GET_PDF.replace(":id", id));
}

export const journalEntryApi = new JournalEntryApi();
