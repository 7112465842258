import type {
  GetStripeAccountLinkResponseDto,
  PostUpdateStripeExternalAccountRequestDto,
  PostUpdateStripeEINRequestDto,
  GetStripeAccountLinkRequestDto
} from "@doorloop/dto";
import { ServerRoutes } from "@doorloop/dto";

import { apiHelper } from "./apiHelper";
import type { ApiResult } from "./apiResult";

class StripeApi {
  async postStripeAccountLinkWithAcceptedTOS(
    dto: GetStripeAccountLinkRequestDto
  ): Promise<ApiResult<GetStripeAccountLinkResponseDto>> {
    return await apiHelper.axiosPost({
      url: ServerRoutes.MERCHANT_ACCOUNTS_POST_STRIPE_ACCOUNT_LINK,
      data: dto
    });
  }

  async postStripeAccountLink(accountId: string): Promise<ApiResult<GetStripeAccountLinkResponseDto>> {
    return await apiHelper.axiosPost({
      url: ServerRoutes.MERCHANT_ACCOUNTS_POST_STRIPE_ACCOUNT_LINK,
      data: { accountId }
    });
  }

  async postStripeExternalAccount(data: PostUpdateStripeExternalAccountRequestDto): Promise<ApiResult<void>> {
    return await apiHelper.axiosPost({ url: ServerRoutes.MERCHANT_ACCOUNTS_STRIPE_POST_UPDATE_EXTERNAL_ACCOUNT, data });
  }

  async postStripeEIN(data: PostUpdateStripeEINRequestDto): Promise<ApiResult<void>> {
    return await apiHelper.axiosPost({ url: ServerRoutes.MERCHANT_ACCOUNTS_STRIPE_POST_UPDATE_EIN, data });
  }
}

export const stripeApi = new StripeApi();
