import type { UploadVisualContentBaseProps } from "DLUI/button/uploadVisualContent";
import { UploadVisualContent } from "DLUI/button/uploadVisualContent";
import { BlankLogoIcon } from "@/assets";
import type { Theme } from "styles/defaultTheme";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => {
  return {
    logoDimensions: {
      width: 120,
      height: 40,
      [theme.breakpoints.down("sm")]: {
        width: 130,
        height: 36
      }
    }
  };
});

export const UploadLogo = (props: UploadVisualContentBaseProps) => {
  const classes = useStyles();

  return (
    <UploadVisualContent
      {...props}
      buttonText={"common.dropzone.uploadLogo"}
      FallbackIcon={BlankLogoIcon}
      className={classes.logoDimensions}
    />
  );
};
