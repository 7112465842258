import { LateFeeChargeDto, LateFeePolicyFeeType, LateFeePolicyFrequency, LateFeesPolicyDto } from "@doorloop/dto";
import { v4 as uuid } from "uuid";

export function createBlankLateFeesSettings(
  lateFeesDefaultAccount?: string,
  additionalSettings: Partial<LateFeesPolicyDto> = {}
): LateFeesPolicyDto {
  const lateFeeChargeDto = new LateFeeChargeDto();
  lateFeeChargeDto.frequency = LateFeePolicyFrequency.ONCE;
  lateFeeChargeDto.feeType = LateFeePolicyFeeType.FIXED_AMOUNT;
  lateFeeChargeDto.uniqueKey = uuid();
  if (lateFeesDefaultAccount) {
    lateFeeChargeDto.account = lateFeesDefaultAccount;
  }
  return new LateFeesPolicyDto({
    minBalanceToApplyLateFee: false,
    maxMonthlyLateFees: false,
    reminderTypesAfterCharge: {
      email: true,
      text: true,
      push: true
    },
    reminderTypesBeforeCharge: {
      email: false,
      text: true,
      push: false
    },
    lateFeeCharges: [lateFeeChargeDto],
    ...additionalSettings
  });
}

interface GetCurrentSettings {
  isCompanySettingsChosen: boolean;
  companySettings?: LateFeesPolicyDto;
  defaultAccountId?: string;
  propertySettings?: LateFeesPolicyDto;
  additionalSettings?: Partial<LateFeesPolicyDto>;
}

function getCurrentSettingsWithoutUniqueKeys({
  isCompanySettingsChosen,
  companySettings,
  defaultAccountId,
  propertySettings,
  additionalSettings
}: GetCurrentSettings) {
  if (isCompanySettingsChosen) {
    return undefined;
  }

  if (propertySettings) {
    return propertySettings;
  }

  if (companySettings) {
    return companySettings;
  }

  return createBlankLateFeesSettings(defaultAccountId, additionalSettings);
}

export function getChargesWithUniqueKeys(charges?: LateFeeChargeDto[]) {
  if (!charges) return [];
  return charges.map((charge) => {
    return {
      ...charge,
      uniqueKey: charge.uniqueKey ?? uuid()
    };
  });
}

export function getCurrentSettings(props: GetCurrentSettings) {
  const settings = getCurrentSettingsWithoutUniqueKeys(props);

  if (!settings) {
    return undefined;
  }

  const lateFeeCharges = getChargesWithUniqueKeys(settings.lateFeeCharges ?? []);

  return {
    ...settings,
    lateFeeCharges
  };
}
