import type { FC } from "react";
import React from "react";
import Alert from "@material-ui/lab/Alert";

import { Close } from "../../../assets";

import Text from "../text";
import { View } from "../view";
import { IconButton } from "../form";

import type { UseStylesToastProps } from "./useStylesToast";
import { useStylesToast } from "./useStylesToast";
import { SeverityIconMap } from "./utils";
import type { ToastSeverity } from "store/toast/types";
import { DataCy } from "@doorloop/dto/src/automation/dataCy";

export interface ToastAction {
  isHidden?: boolean;
  value?: string;
  onClick?: () => void;
}

export interface ToastValues extends UseStylesToastProps {
  severity: ToastSeverity;
  value?: string;
  action?: ToastAction;
}

export interface ToastProps extends ToastValues {
  onClose?: () => void;
}

const Toast: FC<ToastProps> = ({ value, severity, onClose, action }) => {
  const classes = useStylesToast({ severity });

  const IconSrc = SeverityIconMap[severity];

  const handleClickAction = () => {
    action?.onClick?.();
    onClose?.();
  };

  return (
    <Alert className={classes.root} icon={<IconSrc className={classes.icon} />}>
      <View flexDirection={"row"} gap={18} justifyContent={"space-between"} dataCy={DataCy.toast.message} noWrap>
        <div className={classes.contentContainer}>
          <Text fullWidth className={classes.text} value={value} />

          {Boolean(action && !action.isHidden && Boolean(action.value)) && (
            <View autoWidth noWrap flexDirection={"row"} alignItems={"center"} gap={12}>
              <div onClick={handleClickAction}>
                <Text className={classes.action} value={action?.value} />
              </div>
            </View>
          )}
        </div>

        <IconButton
          className={classes.close}
          Icon={Close}
          width={18}
          height={18}
          onClick={onClose}
          circleFill={"black"}
          removePadding
          dataCy={DataCy.toast.closeButton}
        />
      </View>
    </Alert>
  );
};

export { Toast };
