import type { VirtualizerOptions } from "@tanstack/react-virtual";
import { useVirtualizer } from "@tanstack/react-virtual";
import { VirtualScroll } from "DLUI/virtualScroll/virtualScroll";
import type { ReactNode } from "react";
import React, { useCallback, useEffect, useRef } from "react";
import { useResize } from "@/hooks/useResize";
import { MediumListItemHeight } from "DLUI/listItems/listItemContainer";
import { getElementDistanceFromTop } from "utils/dom";
import { EndOfListRow } from "./endOfListRow";
import type { InfiniteProps } from "../types";

type DataListVirtualScrollProps<TDto> = InfiniteProps & {
  rows: TDto[];
  renderRow: (item: TDto, index: number, resourceId?: string) => ReactNode;
  resourceIdGetter?: (item: TDto, index: number) => string;
  virtualizerProps?: Partial<VirtualizerOptions<HTMLElement, Element>>;
};

export function DataListVirtualScroll<TDto>(props: DataListVirtualScrollProps<TDto>) {
  const { renderRow, rows, resourceIdGetter, virtualizerProps } = props;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const scrollMarginRef = useRef<number>(0);

  const { hasNextPage, isFetchingNextPage, fetchNextPage } = props.infinite ? props : ({} as never);

  const virtualizer = useVirtualizer({
    count: rows.length + 1,
    estimateSize: useCallback(() => MediumListItemHeight, []),
    getScrollElement: useCallback(() => document.getElementById("screenScroll"), []),
    scrollMargin: scrollMarginRef.current,
    overscan: 5,
    ...virtualizerProps
  });

  useResize(
    () => {
      if (!containerRef.current || !virtualizer.scrollElement) {
        return;
      }

      scrollMarginRef.current = getElementDistanceFromTop(containerRef.current, virtualizer.scrollElement);
    },
    { enabled: Boolean(containerRef.current) && Boolean(virtualizer.scrollElement) }
  );

  const virtualItems = virtualizer.getVirtualItems();
  useEffect(() => {
    if (!props.infinite) {
      return;
    }

    const [lastItem] = [...virtualItems].reverse();
    if (!lastItem) {
      return;
    }

    if (lastItem.index >= rows.length - 1 && hasNextPage && !isFetchingNextPage && fetchNextPage) {
      fetchNextPage();
    }
  }, [rows.length, virtualItems, props.infinite, hasNextPage, isFetchingNextPage, fetchNextPage]);

  return (
    <VirtualScroll virtualizer={virtualizer} containerRef={containerRef}>
      {(index) => {
        const item = rows[index];
        const isRequestStateRow = index > rows.length - 1;

        if (isRequestStateRow) {
          return <EndOfListRow rows={rows} hasNextPage={hasNextPage} isFetchingNextPage={isFetchingNextPage} />;
        }

        return renderRow(item, index, resourceIdGetter ? resourceIdGetter(item, index) : undefined);
      }}
    </VirtualScroll>
  );
}
