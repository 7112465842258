import type { GetAllUnitsQuery, UnitDto } from "@doorloop/dto";
import { OwnerPortalServerRoutes } from "@doorloop/dto";

import { ownerPortalPropertiesApi } from "api/ownerPortal/ownerPortalPropertiesApi";
import { RestApiBaseWithDictionary } from "api/restApiBaseWithDictionary";

export class OwnerPortalUnitsApi extends RestApiBaseWithDictionary<UnitDto, GetAllUnitsQuery> {
  constructor() {
    super(OwnerPortalServerRoutes.UNITS, [ownerPortalPropertiesApi]);
    this.restRoute = OwnerPortalServerRoutes.UNITS;
  }
}

export const ownerPortalUnitsApi = new OwnerPortalUnitsApi();
