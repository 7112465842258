import Routes from "./routes";
import AppStrings from "locale/keys";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";
import { lazy } from "react";
import { RentalApplicationPostSubmissionPaymentScreen } from "screens/tenantPortal/rentalApplication/rentalApplicationPostSubmissionPaymentScreen";

const RentalApplicationAuthLayout = lazy(
  () => import("screens/tenantPortal/rentalApplication/rentalApplicationAuthLayout")
);
const TenantPortalScreen = lazy(() => import("DLUI/screen/tenantPortalScreen"));
const TenantPortalFinancialScreen = lazy(() => import("screens/tenantPortal/financials/financialsScreen"));
const TenantPortalInsuranceScreen = lazy(() => import("screens/tenantPortal/insurance/insuranceScreen"));
const TenantPortalPaymentsScreen = lazy(() => import("screens/tenantPortal/payments/paymentsScreen"));
const TenantPortalRequestsScreen = lazy(() => import("screens/tenantPortal/requests/requestsScreen"));
const TenantPortalHomeScreen = lazy(() => import("screens/tenantPortal/home/homeScreen"));
const TenantPortalAnnouncements = lazy(() => import("screens/tenantPortal/announcements/tenantPortalAnnouncements"));
const RentalApplicationAuthScreen = lazy(
  () => import("screens/tenantPortal/rentalApplication/rentalApplicationAuth/rentalApplicationAuth")
);
const RentalApplicationScreen = lazy(() => import("screens/tenantPortal/rentalApplication/rentalApplicationScreen"));
const TenantPortalPaymentWizardScreen = lazy(
  () => import("screens/tenantPortal/payments/paymentWizard/paymentWizardScreen")
);
const ListingRentalApplication = lazy(
  () => import("screens/rentalApplications/listingRentalApplication/listingRentalApplication")
);
const TenantPortalFinancialRouter = lazy(() => import("./tenantPortalReportsRouter"));

const rentalApplicationApplicantScreeningShareScreen = lazy(
  () => import("screens/tenantPortal/rentalApplication/rentalApplicationApplicantScreeningShareScreen")
);

const TenantPortalHome = () => (
  <TenantPortalScreen documentTitle={AppStrings.Common.Home} requireAuth>
    <TenantPortalHomeScreen />
  </TenantPortalScreen>
);

const TenantPortalPayments = () => (
  <TenantPortalScreen documentTitle={AppStrings.Bills.WizardSteps.Payments} requireAuth>
    <TenantPortalPaymentsScreen />
  </TenantPortalScreen>
);

const TenantPortalInsurance = () => (
  <TenantPortalScreen documentTitle={AppStrings.Vendors.NewVendor.Insurance} requireAuth>
    <TenantPortalInsuranceScreen />
  </TenantPortalScreen>
);

const TenantPortalRequests = () => (
  <TenantPortalScreen documentTitle={AppStrings.Common.Requests} requireAuth>
    <TenantPortalRequestsScreen />
  </TenantPortalScreen>
);

const TenantPortalAnnouncementsScreen = () => (
  <TenantPortalScreen documentTitle={AppStrings.Announcements.announcements} requireAuth>
    <TenantPortalAnnouncements />
  </TenantPortalScreen>
);

const RentalApplicationScreenWithAuthLayout = () => (
  <RentalApplicationAuthLayout>
    <RentalApplicationScreen />
  </RentalApplicationAuthLayout>
);

const RentalApplicationPostSubmissionPayment = () => (
  <RentalApplicationAuthLayout>
    <RentalApplicationPostSubmissionPaymentScreen />
  </RentalApplicationAuthLayout>
);

const TenantPortalFinancials = () => (
  <TenantPortalScreen documentTitle={AppStrings.Common.Financials} requireAuth>
    <TenantPortalFinancialScreen />
  </TenantPortalScreen>
);

const TenantPortalRouter = () => (
  <PageRouter>
    <LazyRoute path={[Routes.TENANT_PORTAL_HOME, Routes.TENANT_PORTAL_MY_LEASE_HOME]} render={TenantPortalHome} />

    <LazyRoute
      path={[
        Routes.TENANT_PORTAL_NEW_PAYMENT_WIZARD,
        Routes.TENANT_PORTAL_EDIT_PAYMENT_WIZARD,
        Routes.TENANT_PORTAL_NEW_RECURRING_PAYMENT_WIZARD
      ]}
      render={TenantPortalPaymentWizardScreen}
    />

    <LazyRoute
      path={[
        Routes.TENANT_PORTAL_PAYMENTS_EDIT_RECURRING_PAYMENT,
        Routes.TENANT_PORTAL_EDIT_PAYMENT_WIZARD,
        Routes.TENANT_PORTAL_PAYMENTS,
        Routes.TENANT_PORTAL_MY_LEASE_PAYMENTS
      ]}
      render={TenantPortalPayments}
    />
    <LazyRoute
      path={[
        Routes.TENANT_PORTAL_INSURANCE_CONFIRM,
        Routes.EDIT_TENANT_PORTAL_INSURANCE,
        Routes.TENANT_PORTAL_NEW_INSURANCE_POLICY,
        Routes.TENANT_PORTAL_MY_LEASE_INSURANCE,
        Routes.TENANT_PORTAL_INSURANCE
      ]}
      render={TenantPortalInsurance}
    />

    <LazyRoute
      path={[
        Routes.TENANT_PORTAL_EDIT_REQUEST,
        Routes.TENANT_PORTAL_REQUESTS,
        Routes.TENANT_PORTAL_REQUESTS_NEW,
        Routes.TENANT_PORTAL_REQUESTS_EDIT_LOGIN_AND_PASSWORD,
        Routes.TENANT_PORTAL_MY_LEASE_REQUESTS
      ]}
      exact
      render={TenantPortalRequests}
    />

    <LazyRoute path={[Routes.TENANT_PORTAL_REQUESTS]} render={TenantPortalRequests} />

    <LazyRoute
      path={[
        Routes.TENANT_PORTAL_ANNOUNCEMENTS,
        Routes.TENANT_PORTAL_ANNOUNCEMENT_DETAILS,
        Routes.TENANT_PORTAL_MY_LEASE_ANNOUNCEMENTS
      ]}
      render={TenantPortalAnnouncementsScreen}
    />

    <LazyRoute exact path={Routes.TENANT_PORTAL_RENTAL_APPLICATION_AUTH} render={RentalApplicationAuthScreen} />
    <LazyRoute path={Routes.TENANT_PORTAL_LISTING_RENTAL_APPLICATION} exact render={ListingRentalApplication} />

    <LazyRoute exact path={Routes.TENANT_PORTAL_RENTAL_APPLICATION} render={RentalApplicationScreenWithAuthLayout} />

    <LazyRoute
      exact
      path={Routes.TENANT_PORTAL_RENTAL_APPLICATION_POST_SUBMISSION_PAYMENT}
      render={RentalApplicationPostSubmissionPayment}
    />

    <LazyRoute
      exact
      path={Routes.TENANT_PORTAL_RENTAL_APPLICATION_APPLICANT_SCREENING_SHARE}
      render={rentalApplicationApplicantScreeningShareScreen}
    />

    <LazyRoute
      path={[
        Routes.TENANT_PORTAL_FINANCIALS,
        Routes.TENANT_PORTAL_FINANCIALS_CONTACT_INFO,
        Routes.TENANT_PORTAL_FINANCIALS_MY_LEASE
      ]}
      exact
      render={TenantPortalFinancials}
    />

    <LazyRoute path={Routes.TENANT_PORTAL_FINANCIALS} render={TenantPortalFinancialRouter} />
  </PageRouter>
);

export default TenantPortalRouter;
