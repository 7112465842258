"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalAppGroups = exports.RentalAppHandlerFunctions = exports.RentalApplicationOptionalControlledSections = void 0;
var RentalApplicationOptionalControlledSections;
(function (RentalApplicationOptionalControlledSections) {
    RentalApplicationOptionalControlledSections["Pets"] = "pets";
    RentalApplicationOptionalControlledSections["Vehicles"] = "vehicles";
    RentalApplicationOptionalControlledSections["Dependants"] = "dependants";
    RentalApplicationOptionalControlledSections["AdditionalIncome"] = "additionalIncome";
    RentalApplicationOptionalControlledSections["EmergencyContacts"] = "emergencyContacts";
})(RentalApplicationOptionalControlledSections || (exports.RentalApplicationOptionalControlledSections = RentalApplicationOptionalControlledSections = {}));
var RentalAppHandlerFunctions;
(function (RentalAppHandlerFunctions) {
    RentalAppHandlerFunctions["didSelectAutoCompleteAddress"] = "didSelectAutoCompleteAddress";
})(RentalAppHandlerFunctions || (exports.RentalAppHandlerFunctions = RentalAppHandlerFunctions = {}));
var RentalAppGroups;
(function (RentalAppGroups) {
    RentalAppGroups["ContactInformation"] = "ContactInformation";
    RentalAppGroups["Identification"] = "Identification";
    RentalAppGroups["AdditionalInformation"] = "AdditionalInformation";
    RentalAppGroups["General"] = "General";
    RentalAppGroups["EmploymentInformation"] = "EmploymentInformation";
    RentalAppGroups["SupervisorInformation"] = "SupervisorInformation";
    RentalAppGroups["Address"] = "Address";
    RentalAppGroups["LandlordInformation"] = "LandlordInformation";
    RentalAppGroups["ResidentialAdditional"] = "ResidentialAdditional";
})(RentalAppGroups || (exports.RentalAppGroups = RentalAppGroups = {}));
