import React from "react";
import { View } from "DLUI/view";
import { IconButton, TextField } from "DLUI/form";
import { CheckbookIOLogo, CloseCircleIcon, InformationIcon } from "../../../../assets";
import { LinkURLsEnum } from "DLUI/link/linkURLsEnum";
import { Icon } from "DLUI/icon";
import Text from "DLUI/text";
import AppStrings from "../../../../locale/keys";
import { Link } from "DLUI/link";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import BackgroundImageView from "DLUI/view/backgroundImageView";
import ConnectStepRow from "screens/settings/outgoingPayments/connectStepRow";
import { useTranslation } from "react-i18next";
import BasicGrid from "DLUI/listItems/basicGrid";
import FieldWrapper from "DLUI/form/textField/fieldWrapper";
import type { FormikContextType } from "formik";
import { Field, useFormikContext } from "formik";
import type { CheckbookApiKeysSettingsDto } from "@doorloop/dto";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import { ArticleIdsEnum } from "DLUI/screen/helpPanel/types";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { openIntercomArticle } from "DLUI/screen/helpPanel/utils";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { useUserType } from "../../../../hooks/useUserType";
import { useAnalyticsService } from "@/hooks/useAnalyticsService";

interface Props {
  onBackdropClick: () => void;
  didPressConnectButton: (formikRef: FormikContextType<CheckbookApiKeysSettingsDto>) => void;
}

export const ConnectedToCheckbookView: React.FC<Props> = ({ onBackdropClick, didPressConnectButton }: Props) => {
  const { isHOAUser } = useUserType();
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();
  const formikRef = useFormikContext<CheckbookApiKeysSettingsDto>();
  const { screenContainerPadding } = useResponsiveHelper();
  const { dispatchAnalytics } = useAnalyticsService();
  const userData = useSelector((state: IRootState) => state.auth.currentLoginResponse);

  return (
    <View>
      <View style={{ position: "absolute", right: 5, top: 5, zIndex: 10 }} autoWidth>
        <IconButton onClick={onBackdropClick} Icon={CloseCircleIcon} size={30} />
      </View>
      <View
        marginTop={20}
        paddingLeft={screenContainerPadding}
        paddingRight={screenContainerPadding}
        alignItems={"center"}
      >
        <BackgroundImageView
          publicFileName={`url(/images/connect-to-checkbook-io.png)`}
          backgroundSize={"cover"}
          height={250}
          borderRadius={15}
        />

        <Icon marginTop={20} Source={CheckbookIOLogo} />
        <Text
          value={AppStrings.Common.OutgoingPayments.ConnectToCheckbookDesc1}
          fontSize={24}
          marginTop={20}
          align={"center"}
          bold
          textTransform={"uppercase"}
        />
        <Text
          value={AppStrings.Common.OutgoingPayments.ConnectToCheckbookDesc2}
          fontSize={16}
          marginTop={20}
          align={"center"}
          color={"gray"}
        />
        <View justifyContent={"center"} marginTop={10} alignItems={"center"} flexDirection={"row"}>
          <Icon Source={InformationIcon} pathColor={"light-blue"} />
          <Link
            onClick={() => {
              const articleId = !isHOAUser ? ArticleIdsEnum.LEARN_MORE_ABOUT_CHECKBOOK : ArticleIdsEnum.HOA_CHECKBOOK;

              dispatchAnalytics("help_panel_article_click", { articleId });
              openIntercomArticle(articleId);
            }}
            hoverColor={"blue"}
          >
            <Text
              fontSize={16}
              paddingLeft={5}
              paddingRight={5}
              color={"lightBlue"}
              value={AppStrings.Common.OutgoingPayments.LearnMoreAboutCheckbook}
            />
          </Link>
        </View>
        <HorizontalSeparationLine marginTop={20} />
        <ConnectStepRow
          actionButtonText={AppStrings.Common.OutgoingPayments.CreateCheckbookAccount}
          stepNumber={1}
          onStepActionButtonPress={() =>
            userData?.currentDbTenant?.testAccount
              ? window.open(LinkURLsEnum.CB_CREATE_ACCOUNT_SANDBOX, "_blank")
              : window.open(LinkURLsEnum.CB_CREATE_ACCOUNT, "_blank")
          }
          actionDescription={AppStrings.Common.OutgoingPayments.CreateCheckbookAccountDescription}
        />
        <HorizontalSeparationLine marginTop={30} />
        <ConnectStepRow
          stepNumber={2}
          actionButtonText={AppStrings.Common.OutgoingPayments.GetCheckbookAPIkeys}
          onStepActionButtonPress={() => {
            userData?.currentDbTenant?.testAccount
              ? window.open(LinkURLsEnum.CB_ACCOUNT_KEYS_SANDBOX, "_blank")
              : window.open(LinkURLsEnum.CB_ACCOUNT_KEYS, "_blank");
          }}
          actionDescription={AppStrings.Common.OutgoingPayments.GetCheckbookAPIkeysDescription}
        />
        <View height={isMobile ? "auto" : 100} marginTop={20} flexDirection={"row"}>
          <BasicGrid autoHeight md={6}>
            <FieldWrapper type={"endElement"}>
              <Field
                component={TextField}
                name={`apiKey`}
                label={t(AppStrings.Common.OutgoingPayments.CheckbookApiKey)}
                required
              />
            </FieldWrapper>
          </BasicGrid>
          <BasicGrid autoHeight md={6}>
            <FieldWrapper type={"startElement"}>
              <Field
                component={TextField}
                name={`apiSecret`}
                label={t(AppStrings.Common.OutgoingPayments.CheckbookApiSecret)}
                required
              />
            </FieldWrapper>
          </BasicGrid>
        </View>
        <View marginTop={20}>
          <FormActionButtons
            propsActionPanel={{ paddingRight: 0 }}
            propsSubButton={{ onClick: onBackdropClick }}
            propsMainButton={{ type: "cta", props: { onClick: () => didPressConnectButton(formikRef) } }}
          />
        </View>
      </View>
      {isMobile && <View height={20} />}
    </View>
  );
};
