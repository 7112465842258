import { useEffect } from "react";

export const useOnlyEnter = (callback: () => void, disable = false) => {
  useEffect(() => {
    const eventHandler = (e: KeyboardEvent) => {
      if (disable) {
        return;
      }

      const { key, shiftKey, metaKey, ctrlKey } = e;
      if (key === "Enter") {
        if (!shiftKey && !metaKey && !ctrlKey) {
          e.preventDefault();
          callback();
        }
      }
    };
    window.document.addEventListener("keydown", eventHandler);
    return () => window.document.removeEventListener("keydown", eventHandler);
  }, [callback]);
};
