import type { RentRemindersSettingsDto } from "@doorloop/dto";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { FormikSwitchButton, SwitchButton, TextField } from "DLUI/form";
import { AnimatedView } from "DLUI/view/animatedView";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { PopoverHover } from "DLUI/popover/popoverHover";
import { FastField, getIn, useFormikContext } from "formik";
import { type FC } from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import type { SettingsLevels } from "screens/settings/rentReminders/getRentRemindersDynamicSettingLevelFieldsPath";
import { getRentRemindersDynamicSettingLevelFieldsPath } from "screens/settings/rentReminders/getRentRemindersDynamicSettingLevelFieldsPath";

interface RentChargeNotificationsProps {
  setShowDaysBeforeDueDateToPostRecurringCharges: (value: boolean) => void;
  showDaysBeforeDueDateToPostRecurringCharges: boolean;
  isCashPaymentEnabled: boolean;
  settingsLevel: SettingsLevels;
}
export const RentChargeNotifications: FC<RentChargeNotificationsProps> = ({
  setShowDaysBeforeDueDateToPostRecurringCharges,
  showDaysBeforeDueDateToPostRecurringCharges,
  isCashPaymentEnabled,
  settingsLevel
}) => {
  const formik = useFormikContext<RentRemindersSettingsDto>();
  const { t } = useTranslation();
  const { isFeatureFlagActive } = useFeatureFlag("CashPayments");
  const { isMobile } = useResponsiveHelper();

  const postRecurringChargesBeforeDueDateFieldPath = getRentRemindersDynamicSettingLevelFieldsPath({
    settingLevel: settingsLevel,
    dtoKey: "RentReminders",
    field: "postRecurringChargesBeforeDueDate"
  });
  const attachAccountStatementPdfWhenRentIsChargedFieldPath = getRentRemindersDynamicSettingLevelFieldsPath({
    settingLevel: settingsLevel,
    dtoKey: "RentReminders",
    field: "attachAccountStatementPdfWhenRentIsCharged"
  });
  const attachInvoicePdfWhenRentIsChargedFieldPath = getRentRemindersDynamicSettingLevelFieldsPath({
    settingLevel: settingsLevel,
    dtoKey: "RentReminders",
    field: "attachInvoicePdfWhenRentIsCharged"
  });
  const attachCashPaymentSlipPdfWhenRentIsChargedFieldPath = getRentRemindersDynamicSettingLevelFieldsPath({
    settingLevel: settingsLevel,
    dtoKey: "RentReminders",
    field: "attachCashPaymentSlipPdfWhenRentIsCharged"
  });
  const daysBeforeDueDateToPostRecurringChargesFieldPath = getRentRemindersDynamicSettingLevelFieldsPath({
    settingLevel: settingsLevel,
    dtoKey: "RentReminders",
    field: "daysBeforeDueDateToPostRecurringCharges"
  });
  const sendEmailWhenRentIsChargedFieldPath = getRentRemindersDynamicSettingLevelFieldsPath({
    settingLevel: settingsLevel,
    dtoKey: "RentReminders",
    field: "sendEmailWhenRentIsCharged"
  });

  const sendEmailWhenRentIsCharged = getIn(formik?.values, sendEmailWhenRentIsChargedFieldPath);

  const didChangePostRecurringChargesBeforeDueDate = (nextSwitchState: boolean) => {
    const postRecurringChargesBeforeDueDateToggle = getIn(formik?.values, "postRecurringChargesBeforeDueDate") === true;

    if (!nextSwitchState && !postRecurringChargesBeforeDueDateToggle && formik) {
      formik.setFieldValue(postRecurringChargesBeforeDueDateFieldPath, undefined);
    }

    setTimeout(() => {
      setShowDaysBeforeDueDateToPostRecurringCharges(nextSwitchState);
    }, 300);
  };

  const didChangeSendEmailWhenRentCharged = (nextSwitchState: boolean) => {
    if (nextSwitchState) {
      return;
    }

    formik.setFieldValue(attachAccountStatementPdfWhenRentIsChargedFieldPath, false);
    formik.setFieldValue(attachInvoicePdfWhenRentIsChargedFieldPath, false);
    formik.setFieldValue(attachCashPaymentSlipPdfWhenRentIsChargedFieldPath, false);
  };

  const didChangeAttachInvoicePdfWhenRentIsCharged = (nextSwitchState: boolean) => {
    if (nextSwitchState) {
      return;
    }

    formik.setFieldValue(attachCashPaymentSlipPdfWhenRentIsChargedFieldPath, false);
  };

  const attachInvoicePdfWhenRentIsCharged = getIn(formik?.values, attachInvoicePdfWhenRentIsChargedFieldPath);

  return (
    <View marginTop={20} flexDirection={"row"}>
      <Grid item container alignItems={"center"} xs={12} md={12} lg={12}>
        <Text fontSize={16} fontWeight={700} value={AppStrings.Common.RentChargeNotifications} />
        <View noWrap>
          <FastField
            component={FormikSwitchButton}
            name={postRecurringChargesBeforeDueDateFieldPath}
            label={AppStrings.Common.PostRecurringChargesBeforeDueDate}
            onChange={didChangePostRecurringChargesBeforeDueDate}
            marginTop={16}
          />
          <AnimatedView shouldShow={showDaysBeforeDueDateToPostRecurringCharges}>
            <Grid item xs={12} md={5} lg={5}>
              <FastField
                component={TextField}
                label={t(AppStrings.Common.DaysBeforeRentIsDue)}
                name={daysBeforeDueDateToPostRecurringChargesFieldPath}
                marginTop={20}
                formatType={"number"}
                decimalScale={1}
              />
            </Grid>
          </AnimatedView>
        </View>
        <View noWrap>
          <FastField
            component={FormikSwitchButton}
            name={sendEmailWhenRentIsChargedFieldPath}
            label={AppStrings.Common.RentNotificationSendEmailWhenRentIsCharged}
            onChange={didChangeSendEmailWhenRentCharged}
            marginTop={16}
          />
        </View>
        <AnimatedView flexDirection={"row"} shouldShow={sendEmailWhenRentIsCharged} style={{ overflow: "visible" }}>
          <FastField
            component={FormikSwitchButton}
            name={attachAccountStatementPdfWhenRentIsChargedFieldPath}
            label={AppStrings.Common.RentNotificationSendEmailWhenRentIsChargedAttachStatement}
            marginTop={16}
          />
        </AnimatedView>
        <AnimatedView flexDirection={"row"} shouldShow={sendEmailWhenRentIsCharged}>
          <FastField
            component={FormikSwitchButton}
            name={attachInvoicePdfWhenRentIsChargedFieldPath}
            onChange={didChangeAttachInvoicePdfWhenRentIsCharged}
            label={AppStrings.Common.RentNotificationSendEmailWhenRentIsChargedAttachInvoice}
            marginTop={16}
          />
        </AnimatedView>
        {isFeatureFlagActive && (
          <AnimatedView
            flexDirection={"row"}
            minHeight={isMobile ? 50 : 40}
            marginTop={16}
            shouldShow={sendEmailWhenRentIsCharged}
          >
            {isCashPaymentEnabled ? (
              <FastFieldSafe
                key={attachInvoicePdfWhenRentIsCharged ? "enabled" : "disabled"}
                disabled={!attachInvoicePdfWhenRentIsCharged}
                component={FormikSwitchButton}
                name={attachCashPaymentSlipPdfWhenRentIsChargedFieldPath}
                label={AppStrings.Common.RentNotificationSendEmailWhenRentIsChargedAttachCashPaymentSlip}
              />
            ) : (
              <PopoverHover
                tooltipProps={{
                  value: AppStrings.Common.CashPaymentMethodNotSelected,
                  containerStyle: {
                    width: "fit-content"
                  }
                }}
              >
                <SwitchButton
                  disabled
                  checked={false}
                  label={AppStrings.Common.RentNotificationSendEmailWhenRentIsChargedAttachCashPaymentSlip}
                />
              </PopoverHover>
            )}
          </AnimatedView>
        )}
      </Grid>
    </View>
  );
};
