import type { TagType } from "@doorloop/dto";
import { ObjectPermission, TagDto } from "@doorloop/dto";
import { Grid } from "@material-ui/core";
import { tagsApi } from "api/tagsApi";
import { CloseIcon, DeleteIcon, EditBlue, VSignIcon } from "assets/icons";
import { ShakeEffectView } from "DLUI/animatableView";
import { ViewOnlyInput } from "DLUI/form";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import type { ChangeEvent } from "react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./styles.css";
import TagListItemActionPanel from "./tagListItemActionPanel";
import TextInput from "DLUI/form/textField/textInput/textInput";
import type { TagItemDisplayState } from "./editTags.types";

interface ComponentProps {
  name?: string;
  id?: string;
  tagType: TagType;
  onDeleteEvent: (tagId?: string) => void;
}

const TagListItem: React.FC<ComponentProps> = ({ name, id, tagType, onDeleteEvent }: ComponentProps) => {
  const [itemDisplayState, setItemDisplayState] = useState<TagItemDisplayState>(name ? "readOnly" : "edit");
  const [currentValidationMessage, setCurrentValidationMessage] = useState<string | undefined>();

  const { t } = useTranslation();
  const [tagDefaultValue, setTagDefaultValue] = useState<string>(name || "");
  const [tagName, setTagName] = useState<string>(tagDefaultValue);
  const [tagId, setTagId] = useState<string | undefined>(id);
  const [showErrorShakeEffect, setShowErrorShakeEffect] = useState(false);

  const didChangeTagName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTagName(event.target.value);
  };

  const renderValidationMessage = () => {
    if (currentValidationMessage) {
      return (
        <Text color={"error"} fontSize={14} marginTop={5} marginLeft={15}>
          {currentValidationMessage}
        </Text>
      );
    }
  };

  const renderInput = () => {
    if (itemDisplayState === "edit" || itemDisplayState === "error") {
      return (
        <View className={"DLUI_tagListItemInput"} paddingRight={20}>
          <ShakeEffectView fullWidth showEffect={showErrorShakeEffect}>
            <TextInput
              label={t(AppStrings.Settings.GeneralSettings.TagName)}
              onChange={didChangeTagName}
              defaultValue={tagName}
              error={currentValidationMessage !== undefined}
              autoFocus
            />
            {renderValidationMessage()}
          </ShakeEffectView>
        </View>
      );
    }
    if (itemDisplayState === "readOnly" || itemDisplayState === "confirmDelete" || itemDisplayState === "loading") {
      return (
        <View paddingRight={20}>
          <ShakeEffectView fullWidth showEffect={showErrorShakeEffect}>
            <ViewOnlyInput
              fullWidth
              label={AppStrings.Settings.GeneralSettings.TagName}
              value={tagName}
              width={"100%"}
            />
          </ShakeEffectView>
        </View>
      );
    }
    return null;
  };

  const updateTag = async (tagId: string) => {
    setItemDisplayState("loading");
    const currentTag = new TagDto();
    currentTag.id = tagId;
    currentTag.name = tagName;
    currentTag.type = tagType;

    const response = await tagsApi
      .update(tagId, currentTag, { translationKey: AppStrings.Toasts.custom.tags?.[tagType]?.PUT })
      .catch((e) => {
        showErrorMessage(e);
      });
    if (response && response.status && response.data) {
      setTagName(response.data.name || "");
      setTagDefaultValue(response.data.name || "");
      setTagId(response.data.id!);
      setItemDisplayState("readOnly");
    } else if (response) {
      showErrorMessage(response.message);
    } else {
      showErrorMessage(t(AppStrings.Common.GeneralError));
    }
  };

  const createTag = async () => {
    setItemDisplayState("loading");
    const currentTag = new TagDto();
    currentTag.name = tagName;
    currentTag.type = tagType;

    const response = await tagsApi
      .create(currentTag, { translationKey: AppStrings.Toasts.custom.tags?.[tagType]?.POST })
      .catch((e) => {
        showErrorMessage(e);
      });
    if (response && response.status && response.data) {
      setTagName(response.data.name || "");
      setTagId(response.data.id!);
      setItemDisplayState("readOnly");
    } else if (response) {
      showErrorMessage(response.message);
    } else {
      showErrorMessage(t(AppStrings.Common.GeneralError));
    }
  };

  const didPressSaveTag = async () => {
    if (itemDisplayState === "loading") {
      return;
    }
    if (!tagName || tagName === "") {
      showErrorMessage(t(AppStrings.Tags.InvalidTagName));
      return;
    }
    if (tagName === tagDefaultValue) {
      setItemDisplayState("readOnly");
      return;
    }
    if (tagId) {
      await updateTag(tagId);
    } else {
      await createTag();
    }
  };

  const didPressCancelButton = () => {
    if (tagName === "" || currentValidationMessage !== undefined) {
      onDeleteEvent(tagId);
    } else {
      setItemDisplayState("readOnly");
    }
  };
  const didPressEditTag = () => {
    setCurrentValidationMessage(undefined);
    setItemDisplayState("edit");
  };

  const didPressConfirmDeleteButton = () => {
    setItemDisplayState("confirmDelete");
  };

  const runShakeEffect = () => {
    setShowErrorShakeEffect(true);
    setTimeout(() => {
      setShowErrorShakeEffect(false);
    }, 300);
  };

  const showErrorMessage = (error: string) => {
    setItemDisplayState("error");
    setCurrentValidationMessage(error);
    runShakeEffect();
  };

  const didPressDeleteButton = async () => {
    if (tagId) {
      setItemDisplayState("loading");
      const response = await tagsApi
        .delete(tagId, { translationKey: AppStrings.Toasts.custom.tags?.[tagType]?.DELETE })
        .catch(() => {
          setItemDisplayState("error");
        });
      if (response && response.status) {
        setItemDisplayState("readOnly");
        onDeleteEvent(id);
      } else {
        setItemDisplayState("error");
        if (response) {
          showErrorMessage(response.message);
        } else {
          showErrorMessage(t(AppStrings.Common.GeneralError));
        }
      }
    } else {
      onDeleteEvent();
    }
  };

  const renderItemActionsPanel = () => (
    <View>
      <TagListItemActionPanel
        type={"edit"}
        itemDisplayState={itemDisplayState}
        actions={[
          {
            icon: VSignIcon,
            onClick: didPressSaveTag
          },
          {
            icon: CloseIcon,
            onClick: didPressCancelButton
          }
        ]}
      />
      <TagListItemActionPanel
        type={"error"}
        itemDisplayState={itemDisplayState}
        actions={[
          {
            icon: VSignIcon,
            onClick: didPressSaveTag
          },
          {
            icon: CloseIcon,
            onClick: didPressCancelButton
          }
        ]}
      />
      <TagListItemActionPanel
        itemDisplayState={itemDisplayState}
        type={"readOnly"}
        actions={[
          {
            icon: EditBlue,
            clearance: { permission: ObjectPermission.tags, field: "edit" },
            onClick: didPressEditTag
          },
          {
            icon: DeleteIcon,
            clearance: { permission: ObjectPermission.tags, field: "delete" },
            onClick: didPressConfirmDeleteButton
          }
        ]}
      />
      <TagListItemActionPanel type={"loading"} itemDisplayState={itemDisplayState} />
      <TagListItemActionPanel
        type={"confirmDelete"}
        itemDisplayState={itemDisplayState}
        actions={[
          {
            icon: DeleteIcon,
            onClick: didPressDeleteButton
          },
          {
            icon: CloseIcon,
            onClick: didPressCancelButton,
            iconPathColor: "gray"
          }
        ]}
      />
    </View>
  );

  return (
    <View
      marginTop={20}
      flexDirection={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      minHeight={40}
      borderRadius={10}
    >
      <Grid container item xs={8} md={10} lg={10}>
        {renderInput()}
      </Grid>
      <Grid container item xs={4} md={2} lg={2}>
        {renderItemActionsPanel()}
      </Grid>
    </View>
  );
};
export default TagListItem;
