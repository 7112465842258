import type { FC } from "react";
import React from "react";
import { View } from "DLUI/view";
import type { SVGIconComponent } from "../../../assets";
import { Icon } from "DLUI/icon";
import Text from "DLUI/text";
import ColorsEnum from "../../../utils/colorsEnum";
import _ from "lodash";

interface Props {
  completedOperation: string[];
  operationStatus: string;
  showSeparator?: boolean;
  icon: SVGIconComponent;
}

export const BulkOperationExecutionDetails: FC<Props> = React.memo(
  // eslint-disable-next-line prefer-arrow-callback
  function BulkOperationExecutionDetails({ completedOperation, operationStatus, icon, showSeparator = false }: Props) {
    const uniqueCount = _.countBy(completedOperation);

    return (
      <>
        {_.size(completedOperation)
          ? Object.entries(uniqueCount).map(([message, count], index) => (
              <React.Fragment key={index}>
                <View
                  minHeight={45}
                  flexDirection={"row"}
                  gap={16}
                  paddingTop={12}
                  paddingBottom={12}
                  noWrap
                  alignItems={"center"}
                >
                  <View flexDirection={"row"} gap={4} alignItems={"center"} maxWidth={150} noWrap>
                    <Icon Source={icon} pathColor={"light-gray"} size={20} />
                    <View width={30}>
                      <Text alignSelf={"flex-start"} color={"secondary-gray"} value={_.toString(count)} />
                    </View>
                    <View maxWidth={80}>
                      <Text alignSelf={"flex-start"} color={"secondary-gray"} value={operationStatus} />
                    </View>
                  </View>
                  <View maxWidth={260}>
                    <Text alignSelf={"flex-start"} color={"secondary-gray"} value={message} />
                  </View>
                </View>
                {_.size(uniqueCount) > 1 && index < _.size(uniqueCount) - 1 && (
                  <View height={1} style={{ background: ColorsEnum.LightGrayFaded }} />
                )}
                {showSeparator && <View height={1} style={{ background: ColorsEnum.LightGrayFaded }} />}
              </React.Fragment>
            ))
          : null}
      </>
    );
  }
);
