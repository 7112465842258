"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.blockedFeaturesByPriceId = exports.getPlanNameByPriceId = exports.shouldBlockFeatureForSubscriptionPriceId = void 0;
const permissionTypes_1 = require("../../userRoles/shared/permissionTypes");
const starterPriceIds = [
    "price_1IOmVmGAmubIrsVxfk5rIDcp",
    "price_1IOmThGAmubIrsVxx8usBko7",
    "price_1IOnAFGAmubIrsVxaVioZUji",
    "price_1IOnAFGAmubIrsVx953lZILc",
    "price_1JZgeYGAmubIrsVx4A0pka3q",
    "price_1JZgeVGAmubIrsVxSGzCsD5A",
    "price_1JZgCpGAmubIrsVxC9iKyeDZ",
    "price_1OXrcDGAmubIrsVxY2rIADxp",
    "price_1OXrh3GAmubIrsVxXFfCVhXB"
];
const proPriceIds = [
    "price_1KCPzDGAmubIrsVxbYvjxQkB",
    "price_1Jhwi4GAmubIrsVxibaoCkQr",
    "price_1JZgeSGAmubIrsVxeh8cOsXr",
    "price_1KCQ85GAmubIrsVxhdcuhboC",
    "price_1JoaDgGAmubIrsVxva6Ujcdp",
    "price_1JZgePGAmubIrsVxFnpKqrFZ",
    "price_1JPDSTGAmubIrsVx2gxeIvjA",
    "price_1JPDQ0GAmubIrsVxVF4c67nv",
    "price_1IOnA2GAmubIrsVxf0mMrN3o",
    "price_1IOnA2GAmubIrsVxRsxnpYgK",
    "price_1JZgR7GAmubIrsVxkzYz8SXO",
    "price_1MHxGEGAmubIrsVx8QajLYid",
    "price_1OXrqOGAmubIrsVxoNOfRj2Y",
    "price_1MHWnVGAmubIrsVx3gIQDIKy",
    "price_1OXrxKGAmubIrsVxGMgJ4E4K",
    "price_1MHWtNGAmubIrsVxKxIB5KYK"
];
const shouldBlockFeatureForSubscriptionPriceId = (permission, subscriptionPriceId) => {
    if (!subscriptionPriceId) {
        return false;
    }
    return Boolean(exports.blockedFeaturesByPriceId.find((x) => x.priceIds.includes(subscriptionPriceId) && x.blockedFeatures.includes(permission)));
};
exports.shouldBlockFeatureForSubscriptionPriceId = shouldBlockFeatureForSubscriptionPriceId;
const getPlanNameByPriceId = (priceId) => {
    if (!priceId) {
        return undefined;
    }
    if (starterPriceIds.includes(priceId)) {
        return "Starter";
    }
    if (proPriceIds.includes(priceId)) {
        return "Pro";
    }
    return "Premium";
};
exports.getPlanNameByPriceId = getPlanNameByPriceId;
exports.blockedFeaturesByPriceId = [
    {
        planName: "Starter",
        priceIds: starterPriceIds,
        blockedFeatures: [
            permissionTypes_1.ObjectPermission.customizeDashboard,
            permissionTypes_1.ObjectPermission.unitEditListing,
            permissionTypes_1.ObjectPermission.announcements,
            permissionTypes_1.ObjectPermission.userRoles,
            permissionTypes_1.ObjectPermission.plaidAccounts,
            permissionTypes_1.ObjectPermission.calendar,
            permissionTypes_1.ObjectPermission.plaidTransactions,
            permissionTypes_1.ObjectPermission.vendorBills,
            permissionTypes_1.ObjectPermission.vendorBillPayments,
            permissionTypes_1.ObjectPermission.vendorCredits,
            permissionTypes_1.ObjectPermission.workOrders,
            permissionTypes_1.ObjectPermission.propertyGroups,
            permissionTypes_1.ObjectPermission.tags,
            permissionTypes_1.ObjectPermission.managementFees,
            permissionTypes_1.ObjectPermission.ownerRequests,
            permissionTypes_1.ObjectPermission.ownerContributions,
            permissionTypes_1.ObjectPermission.owners,
            permissionTypes_1.ObjectPermission.apiKeys,
            permissionTypes_1.ObjectPermission.outgoingPayments,
            permissionTypes_1.ObjectPermission.bulkOperations,
            permissionTypes_1.ObjectPermission.bulkReceivePayments,
            permissionTypes_1.ObjectPermission.esignatureTemplates,
            permissionTypes_1.ReportPermission.accountsPayable,
            permissionTypes_1.ReportPermission.ownerTransactions,
            permissionTypes_1.ReportPermission.ownerRequests,
            permissionTypes_1.ReportPermission.ownerAvailableFunds,
            permissionTypes_1.ReportPermission.vendorTransactions,
            permissionTypes_1.ReportPermission.printChecks,
            permissionTypes_1.ReportPermission.workOrders,
            permissionTypes_1.ReportPermission.owners1099,
            permissionTypes_1.ReportPermission.vendors1099,
            permissionTypes_1.ReportPermission.managementFees,
            permissionTypes_1.SettingPermission.quickbooks,
            permissionTypes_1.SettingPermission.listings,
            permissionTypes_1.SettingPermission.managementFees,
            permissionTypes_1.SettingPermission.outgoingPayments,
            permissionTypes_1.SettingPermission.vendorsEPayInfo,
            permissionTypes_1.SettingPermission.customAllocations,
            permissionTypes_1.ObjectPermission.ePayPayments,
            permissionTypes_1.SettingPermission.ownerPortal,
            permissionTypes_1.SettingPermission.ownerRequests,
            permissionTypes_1.SettingPermission.convenienceFees,
            permissionTypes_1.ObjectPermission.budgets,
            permissionTypes_1.ReportPermission.budgetVsActual,
            permissionTypes_1.ObjectPermission.conversations,
            permissionTypes_1.ObjectPermission.phoneNumbers,
            permissionTypes_1.SettingPermission.communicationCenter
        ]
    },
    {
        planName: "Pro",
        priceIds: proPriceIds,
        blockedFeatures: [permissionTypes_1.ObjectPermission.apiKeys, permissionTypes_1.SettingPermission.convenienceFees]
    }
];
