import React from "react";
import makeStyles from "./styles";
import type { FillColors } from "DLUI/icon";
import { Icon as DLUI_Icon } from "DLUI/icon";
import type { SVGIconComponent } from "assets/icons/types";

import { useResponsiveHelper } from "../../../contexts/responsiveContext";

interface ComponentProps {
  Icon: SVGIconComponent;
  size?: number | "small";
  marginLeft?: number | string;
  marginRight?: number | string;
  pathColor?: FillColors;
  containerStyle?: React.CSSProperties;
}

const ListItemIcon: React.FC<ComponentProps> = ({
  Icon,
  size,
  marginLeft,
  marginRight,
  pathColor = "black",
  containerStyle
}) => {
  const classes = makeStyles();
  const { isMobile } = useResponsiveHelper();
  const iconWidth = size === "small" ? 20 : 25;
  const iconHeight = size === "small" ? 20 : 30;
  const iconPadding = size === "small" ? 5 : 10;
  const containerStyleObject: React.CSSProperties = {
    marginLeft: isMobile ? 0 : 10,
    marginRight: 10,
    ...containerStyle
  };

  containerStyleObject.width = iconWidth + 5;
  containerStyleObject.height = iconHeight + 5;
  containerStyleObject.padding = iconPadding;

  if (marginLeft !== undefined) {
    containerStyleObject.marginLeft = marginLeft;
  }

  if (marginRight !== undefined) {
    containerStyleObject.marginRight = marginRight;
  }

  return (
    <div className={classes.ListItemIconContainer} style={containerStyleObject}>
      <DLUI_Icon pathColor={pathColor} width={iconWidth} height={iconHeight} Source={Icon} />
    </div>
  );
};

export default ListItemIcon;
