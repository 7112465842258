"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAllBaseQueryResponse = void 0;
class GetAllBaseQueryResponse {
    constructor(data, total
    // eslint-disable-next-line no-empty-function
    ) {
        this.data = data;
        this.total = total;
    }
}
exports.GetAllBaseQueryResponse = GetAllBaseQueryResponse;
