import { lazy } from "react";
import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";

const ChecksListScreen = lazy(() => import("screens/printChecks/checksListScreen"));
const ChecksPreviewScreen = lazy(() => import("screens/printChecks/preview/checkPreviewScreen"));

const BankingRouter = () => (
  <PageRouter>
    <LazyRoute
      path={[
        Routes.PRINT_CHECKS_EDIT_EXPENSE,
        Routes.PRINT_CHECKS_BILL_PAYMENT,
        Routes.PRINT_CHECKS_EDIT_REFUND,
        Routes.PRINT_CHECKS_REMOVE_CHECK,
        Routes.PRINT_CHECKS_NEW_EXPENSE
      ]}
      render={ChecksListScreen}
      exact
    />
    <LazyRoute path={[Routes.PRINT_CHECKS_PREVIEW, Routes.PRINT_CHECKS_PREVIEW_GLOBAL]} render={ChecksPreviewScreen} />
    <LazyRoute path={Routes.PRINT_CHECKS_GLOBAL} render={ChecksListScreen} />
    <LazyRoute exact path={Routes.PRINT_CHECKS} render={ChecksListScreen} />
  </PageRouter>
);

export default BankingRouter;
