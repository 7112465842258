import { createStyles, makeStyles } from "@material-ui/styles";
import type { Theme } from "styles/defaultTheme";

const makeStylesAccordion = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      margin: "0 !important",
      padding: 0,
      boxShadow: "none !important",
      backgroundColor: theme.newPalette.background.paper
    },
    rootBorder: {
      borderRadius: 8,
      border: `1px solid ${theme.newPalette.other.divider}`
    },
    summary: {
      height: 70,
      display: "flex",
      alignItems: "center"
    },
    flipIcon: {
      transition: "transform 0.3s ease"
    },
    flipIconExpanded: {
      transform: "rotate(180deg)"
    }
  })
);

export { makeStylesAccordion };
