import { useCallback, useEffect, useRef } from "react";
import isNil from "lodash/isNil";
import { useSearchParams } from "./useSearchParams";
import { useLocation } from "react-router";

const DEFAULT_FALLBACK_TIMEOUT_MS = 400;

export const useSyncWithSearchParams = (
  queryParamsKey: string,
  defaultValue: string | null = null,
  fallToDefault = false
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const currentValue = searchParams.get(queryParamsKey);
  const previousValueRef = useRef<string | null>();
  const shouldFallbackToDefaultValueRef = useRef<boolean>(true);

  const onValueChange = useCallback(
    (newValue?: string | null) => {
      setSearchParams((currentSearchParams) => {
        const currentParamValue = currentSearchParams.get(queryParamsKey);
        previousValueRef.current = currentParamValue;
        if (newValue === currentParamValue) {
          return currentSearchParams;
        }

        if (isNil(newValue)) {
          currentSearchParams.delete(queryParamsKey);
        } else {
          currentSearchParams.set(queryParamsKey, newValue);
        }

        return currentSearchParams;
      }, "replace");
    },
    [queryParamsKey, setSearchParams]
  );

  useEffect(() => {
    if (!shouldFallbackToDefaultValueRef.current || fallToDefault) {
      return;
    }

    const timeout = setTimeout(() => {
      shouldFallbackToDefaultValueRef.current = false;
    }, DEFAULT_FALLBACK_TIMEOUT_MS);

    return () => {
      clearTimeout(timeout);
    };
  }, [fallToDefault]);

  useEffect(() => {
    if (previousValueRef.current === currentValue || location.pathname.includes("/confirm")) {
      return;
    }

    if (!currentValue && defaultValue && shouldFallbackToDefaultValueRef.current) {
      onValueChange(defaultValue);
      return;
    }

    onValueChange(currentValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  return { onValueChange, queryValue: currentValue };
};
