import React, { Fragment, useMemo } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import { IconButton } from "DLUI/form";
import type { FillColors } from "DLUI/icon";
import { SeparationLine } from "DLUI/separatorView";
import { View } from "DLUI/view";
import "./styles.css";
import type { SVGIconComponent } from "assets/icons/types";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { RestrictedPermissionAccess } from "screens/settings/userRoles";
import type { PropertyGroupItemDisplayState } from "./propertyGroups.types";

interface TagListItemAction {
  icon: SVGIconComponent;
  onClick: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => void;
  iconPathColor?: FillColors;
  clearance?: AnyPermissionClearance;
}

interface ComponentProps {
  actions?: TagListItemAction[];
  type: PropertyGroupItemDisplayState;
  itemDisplayState: PropertyGroupItemDisplayState;
}

const PropertyGroupsListItemActionPanel: React.FC<ComponentProps> = ({
  actions,
  type,
  itemDisplayState
}: ComponentProps) => {
  const itemDisplayStateClass = useMemo(() => (itemDisplayState === type ? "show" : "hide"), [itemDisplayState]);

  if (itemDisplayState === "loading" && type === "loading") {
    return (
      <View flexDirection={"row"}>
        <CircularProgress
          style={{
            width: 15,
            height: 15,
            color: "#182C4C",
            marginLeft: 20
          }}
        />
      </View>
    );
  }

  return (
    <div className={clsx(["DLUI_actionPanel", type, itemDisplayStateClass])}>
      {actions
        ? actions.map((currentAction, index) => (
            <Fragment key={type + index}>
              <RestrictedPermissionAccess clearance={currentAction.clearance}>
                <IconButton
                  Icon={currentAction.icon}
                  onClick={currentAction.onClick}
                  width={20}
                  height={20}
                  marginRight={index + 1 < actions.length ? 10 : 0}
                  pathColor={currentAction.iconPathColor}
                />
                {index + 1 < actions.length ? <SeparationLine marginRight={10} width={1} height={20} /> : null}
              </RestrictedPermissionAccess>
            </Fragment>
          ))
        : null}
    </div>
  );
};
export default PropertyGroupsListItemActionPanel;
