export default {
  screen: {
    moveIn: "Move-in",
    expiringSoon: "Expiring Soon",
    lease: "Lease",
    emptydataViewInstructionsText: "No Leases Found",
    emptydataAddNew: "Add your first lease",
    searchPaneltitle: "Manage your leases",
    searchPlaceHolder: "Search leases",
    addNewLease: "New Lease",
    viewRentalApplication: "View rental application",
    createLease: "Create Lease",
    makeActive: "Activate Lease",
    cancelLease: "Cancel Lease",
    cancelLeaseLoadingText: "Canceling draft lease",
    cancelLeaseSuccessText: "Lease was canceled",
    activateLeaseLoadingText: "Activating lease",
    activateLeaseSuccessText: "Lease was activated",
    leaseType: "Lease Type",
    termduring: "Term During:",
    termStarts: "Term Starts:",
    termFrom: "Term From",
    termTo: "Term To",
    newLease: "New Lease",
    draftLease: "Draft Lease",
    existingLease: "Existing Lease",
    leaseRenewal: "Lease Renewal",
    renewalLease: "Renewal Lease",
    deleteConfirmationText: "Are you sure you want to delete this lease?",
    deleteLoadingText: "Deleting lease",
    deleteSuccessText: "Lease was deleted successfully",
    noLeaseIdFoundError: "No lease ID found",
    manageLease: "Manage Lease",
    helpPanel: {
      draftLease: {
        description: "Manage and edit your draft leases.",
        createLease: "How do I create a new draft lease?",
        editLease: "How do I edit or activate a draft lease?",
        signLease: "How do I send out the draft lease for an eSignature?"
      },
      renewals: {
        description: "Renew or end leases that are expiring soon.",
        renewLease: "How to renew a lease",
        endLease: "How do I end the lease instead of renewing?",
        endOfTerm: "What happens to a lease when it reaches the end of its term?"
      },
      rentalApplications: {
        description:
          "Allow for online applications and find better tenants with background checks powered by TransUnion.",
        setUp: "Set up your rental applications",
        howToSend: "How do I send rental application to prospect?",
        reviewApplications: "How do I review submitted rental applications?",
        dialogTitle: "Turning on Rental Applications"
      }
    }
  },
  insurancePolicy: {
    insurancePolicy: "Insurance Policy",
    insuranceDetails: "Insurance Details",
    newInsurancePolicy: "New Insurance Policy",
    selectLease: "Select Lease",
    policyOwner: "Policy Owner",
    policyNumber: "Policy Number",
    providerName: "Provider Name",
    effectiveDate: "Effective Date",
    expirationDate: "Expiration Date",
    liabilityCoverage: "Liability Coverage",
    insuranceRequired: "Insurance Required",
    insuranceNotRequired: "Insurance Not Required",
    insuranceNotProvided: "Not Provided",
    deletePolicyConfirm: "Are you sure you want to delete the policy?",
    status: {
      provided: "Provided",
      expired: "Expired",
      future: "Future",
      addPolicy: "Add Policy"
    }
  },
  editLease: {
    selectUnitsAddRemoveFromXLease: 'Select units to add or remove from "{{x}}" lease'
  },
  newLease: {
    ifNewLeaseDatesCorrectHelperText: "If the new dates are correct, please adjust the existing lease dates.",
    leaseTermsTitle: "Lease Terms",
    navigationHelperTitle: "Need help adding your existing data?",
    learnMoreNewLease: "Learn more about adding a new lease",
    helpPanel: {
      newLease: "How to add a new lease",
      subtitle: "Tell us your lease terms, tenants, and charges.",
      addPreviousLeases: "Need to add leases you had before starting DoorLoop?",
      dialogTitle: "Create a Lease"
    },
    unitEditConfirmations: {
      confirmAddRemove:
        'Are you sure you want to add $t(leases.newLease.unitEditConfirmations.unit, {"count": {{add}} }) and remove $t(leases.newLease.unitEditConfirmations.unit, {"count": {{remove}} }) from this lease?',
      confirmAdd:
        'Are you sure you want to add $t(leases.newLease.unitEditConfirmations.unit, {"count": {{add}} }) to this lease?',
      confirmRemove:
        'Are you sure you want to remove $t(leases.newLease.unitEditConfirmations.unit, {"count": {{remove}} }) from this lease?',
      unit: "{{count}} unit",
      unit_plural: "{{count}} units"
    },
    pleaseNote: "Please note",
    multipleUnitsLeaseTotalsInfo:
      "When adding multiple units to one lease, lease totals will be divided equally between all units.",
    screenTitle: "Lease Terms",
    whichProperty: "Which property is this lease for?",
    chooseProperty: "Choose Property",
    unit: "Unit",
    optionalUnit: "Unit (Optional)",
    chooseUnit: "Choose Unit",
    whichUnit: "Which unit is the lease for?",
    leaseTerm: "What is the term of this lease?",
    leaseDates: "What are the lease dates?",
    fixed: "Fixed Term",
    atwill: "Month-to-Month",
    rollover: "Rollover",
    totalBalanceDue: "Total Balance Due",
    totalDepositsHeld: "Total Deposits Held",
    totalRecurringRent: "Total Recurring Rent",
    totalRecurringCharges: "Total Recurring Charges",
    totalRecurringCredits: "Total Recurring Credits",
    totalRecurringPayments: "Total Recurring Payments",
    startDate: "Start date",
    endDate: "End date",
    leaseCharges: "Charges & Credits",
    unscreenedTenant: "Attention, unscreened tenant",
    learnMoreAboutScreening: "Learn more about the DoorLoop screening process",
    unscreenedTenantWarningBanner:
      "Some of the tenants you are about to add to this lease have not been screened.\nTo reduce the risk of costly evictions and property damage, we recommend conducting screenings for all your tenants.",
    conflictErrorMessage:
      "An active lease already exists for the selected unit and dates. If the dates for the new lease are correct, fix the start or end dates of the existing lease for this unit.",
    multipleConflictsErrorMessage:
      "An active lease currently exists for the selected units below. If the dates for the new lease are correct, please adjust the dates or terms of the existing leases for those units.",
    newLeaseConflictErrorMessage: "The selected date range overlaps with an existing lease at",
    leaseChargesDescription: "Add your rental charges, lease deposits, credits, and enable late fees.",
    switchToAtWill: "Rollover to “Month to Month” at the end of the term",
    leaseOverview: {
      propertySection: {
        title: "Property",
        subtitle: "Which property is this lease for?",
        propertyPlaceholder: "Choose Property",
        unitsPlaceholder: "Choose Units"
      },
      termsSection: {
        title: "Lease Terms",
        subtitle: "What is the term of this lease?",
        imageBoxes: {
          fixed: "Fixed Term",
          monthly: "Month-to-Month"
        },
        dates: {
          startDate: "Start Date",
          endDate: "End Date"
        },
        rollover: "Rollover to “Month to Month” at the end of the term"
      }
    },
    leaseTenants: {
      leaseTenantsTitle: "Lease Tenants",
      selectTenants: "Who are the tenants?",
      addTenant: "Add Tenant",
      createNewTenant: "Create new tenant",
      selectExistingTenant: "Select an existing tenant or applicant",
      addNewTenantToLease: "Add tenant to lease",
      residentPortalAccess: "Tenant Portal",
      residentPortalAccessDescription:
        "If turned on, tenants will receive an invitation by email to access their online portal.",
      requireRentersInsurance: "Renters Insurance",
      requireRentersInsuranceDescription:
        "If turned on, we will require the tenant to purchase renters insurance to protect your property and provide proof of coverage.",
      learnMoreResidentPortal: "[Click here] to learn more about the resident portal.",
      learnMoreRentersInsurance: "[Click here] to learn more about renters insurance.",
      chooseFromList: "Choose from list"
    },
    leaseRent: {
      occurrencesRemaining: "Occurrences Remaining",
      occurrences: "Occurrences",
      nextOccurrence: "Next Occurrence",
      lastOccurrence: "Last Occurrence",
      rentCharges: "Rent Charges",
      charges: "Charges",
      charge: "Charge",
      recurringCreditDescriptionHeader: "How often do you want to post this credit?",
      addSecurityDepositCharge: "Add deposit charge",

      addAnotherSecurityDepositCharge: "Add another deposit charge",
      firstRentDate: "When do you want to start charging rent?",
      firstRentPlaceholder: "First Rent Date",
      rentFrequency: "How often do you charge rent?",
      rentChargesItemDescription: "What are your rent charges?",
      rentChargesDescription: "These are the scheduled charges this lease will incur for rent going forward.",
      addFutureRent: "Add Future Rent",
      scheduleRentIncrease: "Schedule Rent Increase",
      amount: "Amount",
      whicAccount: "What is this for?",
      whichRentAccount: "What is this charge for?",
      description: "Add description",
      addAnotherCharge: "Add another charge",
      addAnotherChargeOneTime: "Add another one-time charge",

      prorateCharges: {
        notification: "Your first rent date is different than your lease start date. Should we prorate the charges?",
        prorateCharges: "Prorate Charges",
        prorateDate: "When are these charges due?",
        addAdditionalCharge: "Add additional charge",
        recalculateCharges: "Recalculate Charges"
      },
      addAnotherRentSchedule: "Add another rent schedule",
      otherCharges: "Prorations",
      otherChargesDescription:
        "A proration is a one-time lease charge for the partial month’s rent prior to the recurring rent charge taking over at the start of the next full month.",
      recurringCharges: "New Recurring Charges",
      recurringCredit: "Recurring Credit",
      recurringPayment: "Recurring Payment",
      addAnotherRecurringCharge: "Add another recurring charge",
      addAnotherRecurringCredit: "Add another recurring credit",
      addAnotherOneTimeCharge: "Add another one-time charge",
      addAnotherOneTimeCredit: "Add another one-time credit",
      addRecurringCharge: "Add recurring charge",
      addRecurringCredit: "Add recurring credit",
      addOneTimeCharge: "Add one-time charge",
      addOneTimeCredit: "Add one-time credit",
      oneTimeCharges: "One-time charges",
      oneTimeChargesDescription: "When is this charge due?",
      oneTimeCreditsDescription: "When is this credit due?",
      credits: "Credits",
      credit: "Credit",
      creditsDescription:
        "Use this section to add one-time or recurring concessions or credits to this lease. This is for things like monthly rent discounts, a free month of rent signing incentive, or to record that you’ve already collected a lease deposit.",
      dueDate: "Due Date",
      dateIssued: "Date Issued",
      securityDeposit: "Security Deposit",
      securityDepositDescription:
        "This toggle is for lease deposit charges. You need to add a lease deposit charge even if you’ve already collected payment for the deposit.",
      untilEndOfLease: "End of Lease",
      repeatUntilEndOfLease: "Repeat until the end of the lease",
      frequency: "Frequency",
      lateFees: "Late Fees",
      defaultPolicy: "Default Policy",
      lateFeesDescription:
        "Late fees need to be turned on for every lease, even if you are using the same late fee schedule as you do for the rest of your properties.",
      usePropertyLateFeesPolicy: "Use the property late fee policy",
      specifyLateFeesPolicy: "Use a custom late fee policy for this lease",
      startChargingLateFee: "Start charging a late fee",
      days: "Days",
      daysAfterRentDue: "days after the rent is due.",
      chargeAmountOf: "Charge an amount of",
      continueCharging: "and continue charging it",
      untilRentPaid: "until the rent is paid off.",
      feeIncomeAccount: "Charge the fee to this income account",
      recurringRent: "Recurring Rent",
      otherRecurringTransactions: "Other Recurring Transactions",
      nextCharge: "Next charge"
    },
    leaseDeposits: {
      securityDepositTitle: "Security Deposit",
      securityDepositSubTitle: "Will you be holding a security deposit on this lease?",
      securityDepositAmount: "What will be the deposit amount?",
      securityDepositTellUsMore: "Tell us more about the security deposits you are holding",
      securityDepositDatePlaceholder: "Deposit Received Date"
    },
    leaseLateFees: {
      title: "Late Fees",
      subtitle: "Do you charge late fees for unpaid rent?",
      policySettingsDescription: "You can always change your property’s late fee policy in your {{link}}",
      lateFeesActivateOptions: {
        active: "Yes, we charge",
        disable: "We don’t charge"
      },
      policyOptions: {
        propertyLateFees: "Use property late fees policy",
        customLateFees: "Specify a late fees policy for this lease"
      },
      policyType: {
        disabled: "Late Fees Disabled",
        property: "Property Policy Activated",
        company: "Company Policy Activated"
      },
      notificationAlert: {
        title: "Attention",
        description:
          "Always make sure your late fee policy is the same as in your actual lease agreement and that it is compliant with your state and local laws."
      }
    },
    leaseStatus: {
      sendESignature: "Send eSignature",
      defineYourLeaseTitle: "Define Your Lease",
      defineYourLeaseSubtitle: "Is the lease signed?",
      uploadFileInstructions: "You can upload a copy of your signed lease below to save it for your records:",
      eSignatureSentDescription: "Which status best matches this draft lease?",
      eSignatureChooseDescription:
        "When you click finish, we will create this lease as a draft and take you to the eSignature wizard.",
      inactive: "Inactive",
      currentStatus: "Current status of your lease",
      screenTitle: "Lease Status",
      typeOfLease: "What is the current status of your lease?",
      active: "Active",
      draft: "Draft",
      notSent: "Not Sent",
      sent: "Sent",
      signed: "Signed",
      saveAsDraft: "Save as Draft",
      notSigned: "Not Signed",
      partiallySigned: "Partially Signed",
      counterSigned: "Counter-Signed",
      activated: "Activated",
      cancelled: "Canceled",
      draftStatus: "Draft Status",
      viewed: "Viewed",
      created: "Created",
      draftStatusDescription: "We will save this lease as a draft. You can activate it once the lease is executed.",
      activeLeaseTooltipDescription:
        "An active lease is one that has been executed by all parties involved, regardless of the start date.",
      draftLeaseTooltipDescription: "A draft lease is still in the process of being signed by the parties involved.",
      accepted: "Accepted",
      rejected: "Rejected"
    }
  },
  existingLease: {
    subtitle: "You chose to renew the following lease:",
    rolloverToAtWill: "Switch this lease to “Month to Month” at the end of the term",
    yes: "Yes",
    no: "No",
    monthToMonth: "Month To Month",
    rentCharges: "Rent Charges",
    willIncrease: "Will the rent increase upon renewal?",
    whenIncrease: "When should the new charges start?",
    willBeChargingDeposit: "Will you be charging an additional security deposit?",
    newDeposits: "New Deposits",
    existingDeposits: "Existing Deposits",
    howMuchAdditionalDeposit: "How much more would you like to charge?",
    currentlyHeld: "Currently Held",
    additionalDeposit: "Additional Deposit",
    expiring: "Expiring",
    leaseTenants: {
      notification: {
        title: "When will my tenants be invited to the Tenant Portal?",
        description:
          "Tenants will receive a rent reminder to login to their tenant portal and pay their rent automatically before their first rent is charged in DoorLoop. You can always customize it in your company settings."
      }
    },
    leaseRent: {
      firstRentDate: "When should DoorLoop start charging rent?",
      notification:
        "It looks like you are adding a lease that has already started. We recommend you start charging rent in DoorLoop on the 1st of the following month (if needed: after your DoorLoop start date)."
    },
    leaseDeposits: {
      securityDepositSubTitle: "Are you currently holding a security deposit for this lease?",
      securityDepositAmount: "What is the deposit amount?",
      notification:
        "It looks like you are adding a lease that has already started. DoorLoop will do some accounting magic on the back end to mark these deposits as already collected."
    },
    leaseStatus: {
      originalLeaseCopy: "Original Lease Copy",
      description: {
        firstLine: "Drag & drop your lease copy below.",
        secondLine:
          "If you don’t have your lease copy on you right now, you can always add it later under the files tab."
      },
      smallDirectionTip: "Please upload only DOC or PDF files"
    }
  },
  wizardSteps: {
    overview: "Overview",
    tenants: "Tenants",
    rent: "Rent",
    deposit: "Deposit",
    lateFees: "Late Fees",
    status: "Status",
    review: "Review"
  },
  futureCharges: {
    futureChargeChip: "Future charge",
    upcomingCharges: "Upcoming charges:"
  },
  reviewStepItems: {
    propertyNameTitle: "Property Name",
    unitNameTitle: "Unit Name",
    leaseTermsTitle: "Lease Terms",
    datesTitle: "Dates",
    tenantsTitle: "Tenants",
    rentChargesTitle: "Rent Charges",
    depositTitle: "Deposit",
    lateFeesTitle: "Late Fees",
    stepTitle: "Review",
    stepSubtitle:
      "Before completing the new lease, please conduct a thorough review to ensure all details are in order.",
    reviewTitle: "Your Lease Summary",
    fixedTerm: "Fixed Term",
    monthToMonth: "Month-to-Month",
    paymentLimit:
      "By default, there is a limit on the amount you can receive from tenant payments via credit card or ACH. To increase this limit, please submit a request."
  },

  draftLease: {
    terms: "Lease Term",
    days: "days",
    daysAgo: "days ago",
    moveIn: "Move-in",
    moveInDate: "Move-in date",
    moveTomorrow: "Move-in tomorrow",
    moveToday: "Move-in today",
    moveOut: "Move out",
    moveOutTomorrow: "Move-out tomorrow",
    moveOutToday: "Move-out today",
    movedInFrom: "Moved in from",
    movedInTo: "Moved in to",
    movedOutFrom: "Moved out from",
    movedOutTo: "Moved out to",
    expiring: "Expiring",
    withRollover: "with Rollover",
    overdue: "Past Due",
    expired: "Expired",
    started: "Started",
    starts: "Starts",
    valid: "Valid",
    receivePayment: "Receive Payment",
    createNewReceivePayment: "Receive New Payment",
    addCharge: "Add Charge",
    giveRefund: "Give Refund",
    issueCredit: "Issue Credit",
    outstandingBalance: "Outstanding Balance",
    upcomingCharges: "Upcoming Charges",
    deleteDraftLeasConfirmationText: "Are you sure you want to delete draft lease?",
    deleteDraftLeaseLoadingText: "Deleting draft lease",
    deleteDraftLeaseSuccessText: "Successfully deleted",
    noRenewalDraftLeaseIdFoundError: "No renewal draft lease ID found"
  },
  leaseDetails: {
    monthlyRent: "Monthly Rent",
    depositsHeld: "Deposits Held",
    transactions: "Transactions",
    rent: "Rent",
    deposits: "Deposits",
    leaseCharge: "Charge",
    leasePayment: "Payment",
    leaseRefund: "Refund",
    leaseCredit: "Credit",
    vedorBill: "Bill",
    vedorBillPayment: "Bill Payment",
    vendorCredit: "Credit",
    expense: "Expense",
    generalEntery: "Journal Entry",
    transfer: "Transfer",
    currentBalance: "Current Balance",
    lastPaymentDate: "Last Payment Date",
    changeUnitsForLeaseConfirmation:
      "Note, After removing or adding a unit from a lease, you need to update the deposit, tenant portal permissions, rent price, and lease agreement for each unit."
  },
  leaseUnits: {
    linkedUnits: "Linked Units",
    editUnitsTitle: "Lease",
    numOfLinkedUnits: "{{numOfUnits}} Units",
    leaseUnits: "Lease Units",
    unitName: "{{propertyName}} -> {{unitName}}",
    unitSize: "{{size}} {{sizeMeasureUnit}}",
    editOwnerButtonText: "Edit",
    leaseSubTitle: "Select the units you would like to add or remove from {{address}} lease",
    depositHeld: "Deposit Held",
    units: "Units",
    property: "Property"
  },
  listHeader: {
    lease: "Lease",
    leaseNotes: "Lease Notes",
    term: "Term",
    status: "Status",
    rent: "Rent",
    rentPerSize: "Rent/Size",
    deposits: "Deposits",
    balance: "Balance",
    renewalStatus: "Renewal Status",
    lastPaymentDate: "Last Payment Date",
    lastPaymentAmount: "Last Payment Amount",
    lastReturnedPaymentDate: "Last Returned Payment Date",
    lastReturnedPaymentAmount: "Last Returned Payment Amount",
    numOfReturnedPayments: "Returned Payments",
    numOfLateFeeCharges: "Late Fee Charges",
    lastLateFeeChargeDate: "Last Late Fee Date",
    nextLease: "Next Lease",
    nextLeaseStart: "Next Lease Start",
    tenant: "Tenant",
    provider: "Provider",
    coverage: "Coverage"
  },
  bulkActionErrors: {
    noUnitsWithValidSizes: "No units with valid sizes were found",
    noCustomAllocationSelected: "Please select a custom allocation from the list",
    totalChargeMustBeGreaterThanZero: "Total charge must be greater than 0",
    totalCreditMustBeGreaterThanZero: "Total credit must be greater than 0",
    noLeasesFoundForThisProperty: "No leases found for this property",
    noLeaseSelected: "Please select at least one lease to allocate"
  },
  leaseCredit: {
    processingCreditForLeaseX: "Processing credit for {{leaseName}}",
    bulkIssueCredits: "Bulk Issue Credits",
    perUnitCredits: "Per Unit Credits",
    commonCreditAllocation: "Common Credit Allocation",
    totalCredit: "Total Credit"
  },
  leaseCharge: {
    currentBalanceDue: "Current Balance Due",
    noUnitsSelected: "Please select at least one unit to allocate",
    greater: "greater",
    less: "less",
    chargeTotalDeltaConflict:
      "The entered total {{type}} is {{op}} than the sum of the transactions by {{delta}}. You can still continue.",
    noPropertySelected: "Please select a property with active leases to continue.",
    processingChargeForLeaseX: "Processing charge for {{leaseName}}",
    commonChargeAllocation: "Common Charge Allocation",
    rounding: "Rounding",
    totalCharge: "Total Charge",
    bulkPostCharges: "Bulk Post Charges",
    perUnitCharges: "Per Unit Charges",
    oneTimeCharge: "Post Charge",
    createNewOneTimeCharge: "Post New Charge",
    oneTimeCredit: "Issue Credit",
    createNewOneTimeCredit: "Issue New Credit",
    oneTimePayment: "Receive Payment",
    oneTimeRefund: "Give Refund",
    createNewOneTimeRefund: "Give New Refund",
    recurringCharge: "Recurring Charge",
    selectLease: "Select Lease",
    selectProperty: "Select Property",
    reference: "Reference #",
    referenceAuto: "AUTO",
    description: "Description",
    addLineItem: "Add Line Item",
    balanceDue: "Balance Due",
    yourOutstandingBalance: "Your Outstanding Balance",
    totalRefund: "Total Refund",
    totalCredit: "Total Credit",
    totalPayment: "Total Payment",
    totalAmount: "Total Amount",
    addNote: "Add note",
    addMemo: "Add memo",
    emptyChargeValidationMessage: "Charge must contain at least one line",
    pressToSelectLease: "Click here to select a lease",
    learnMoreOneTimePayment: "Learn more about receiving a payment",
    descriptionOneTimeRefund: "Record a refund issued to a tenant",
    depositAmount: "Deposit Amount",
    depositAccount: "Deposit Account",
    issueCreditHelpPanel: {
      description: "Issue a discount to reduce the amount owed",
      create: "How do I issue a credit?",
      editDelete: "How do I edit or delete a credit?",
      dialogTitle: "Issuing a Credit"
    },
    oneTimePaymentHelpPanel: {
      description: "Create a payment to show a tenant has paid",
      record: "How do I record a payment?",
      editDelete: "How do I edit or delete a payment?",
      dialogTitle: "Receiving a Payment"
    },
    oneTimeChargeHelpPanel: {
      description: "Create charges for your tenants to pay.",
      create: "How do I create a charge?",
      editDelete: "How do I edit or delete a charge?",
      dialogTitle: "Posting a Charge"
    },
    refundHelpPanel: {
      description: "Refund tenants for overpayments",
      giveRefund: "How do I give a refund?",
      editDeleteRefund: "How do I edit or delete a refund?",
      dialogTitle: "Giving a Refund"
    }
  },
  renewLease: {
    newTerms: "What is the new term of this lease?",
    newTermsValidation: "You must select the terms type before proceeding.",
    statusValidation: "You must select a status before proceeding.",
    tabInstructions: "If the terms of the lease have changed, please enter them below.",
    rentChargesInstructions:
      "If your rent changes on renewal, add the new rent charges, and we will update the lease automatically.",
    otherChargesInstructions: "If you have any additional renewal charges, add them here.",
    depositsInstructions:
      "You can add a charge for an additional lease deposit or other liabilities such as last month’s rent.",
    createLease: "creating lease",
    createLeaseSuccessText: "lease was created",
    wizardSteps: {
      overview: "Overview",
      chargesAndCredits: "Charges And Credit",
      deposits: "Status"
    }
  },
  leaseRenewals: {
    termEnds: "Term ends",
    leasesExpiring: "Leases Expiring:",
    notRenewing: "Not Renewing",
    started: "Started",
    notStarted: "Not Started",
    offerPending: "Offer Pending",
    draftPending: "Draft Pending",
    nextSteps: "Next Steps",
    createOffer: "Create Offer",
    renewLease: "Renew Lease",
    endLease: "End Lease",
    activateLease: "Activate Lease",
    newRenewalOffer: "New Renewal Offer",
    currentTerms: "Current Terms",
    proposedNewTerms: "Proposed New Terms",
    proposedNewTermsDescription: "You can propose one or more renewal offers for the tenant(s) to choose from:",
    addAnotherOffer: "Add Another Offer",
    termOffer: "Term Offer",
    proposedRent: "Proposed Rent",
    proposedTerms: "Proposed Terms",
    notifyTenantsDescription: "Email this renewal offer to the tenant(s) and allow them to give notice electronically.",
    viewLease: "View Lease",
    viewDraft: "View Draft",
    editDraft: "Edit Draft",
    editLease: "Edit Lease",
    viewOffer: "View Offer",
    loadingOfferData: "Loading offer data",
    loadingOfferDataErrorText: "Can't load offer data",
    loadingOfferDataSuccessText: "Data was loaded",
    creatingOffer: "Creating offer",
    offerStatus: "Offer Status",
    nonRenewalNotice: "Non-Renewal Notice",
    noticeDate: "When was the notice given?",
    noticeNotes: "Would you like to add any notes?"
  },
  endLease: {
    helpPanel: {
      subtitle:
        "Terminate a lease within DoorLoop and deactivate tenant access. You'll still retain the ability to manually input transactions.",
      endLease: "How to end a lease",
      endedLeaseEdit: "How to edit a lease after ending it",
      endedLeaseDeposit: "How to withhold or refund a lease deposit after ending the lease",
      videoEndLease: "Ending a lease"
    },
    leaseBalance: "End Lease",
    owedToYou: "Owed to you",
    refundTenant: "Refund Tenant",
    zeroBalanceNotificationLabel: "This lease has no outstanding balance.\nYou can go ahead and end the lease!",
    leaseHasSecurityDepositAndNoOB: "This lease has a security deposit and no outstanding balance.",
    leaseHasOBAndNoSecurityDeposit: "Your lease has an outstanding balance and no security deposit.",
    refundTitle: "Refund the security deposit to the tenant",
    refundDescription: "The tenant should receive a refund of the security deposit",
    actionsLabelOneAction: "In order to zero out the balances you will need to perform the following action:",
    actionsLabelExactlyOneAction: "In order to end the lease please choose one of the following options:",
    actionsLabelTwoActions: "In order to fix this issue, you will need to perform the following actions:",
    actionsLabelOneOrMoreActions:
      "In order to end the lease you will need to perform one or more of the following actions:",
    deleteLeaseAnywayDescription: "End Lease Now And Adjust Balance Later",
    endLeaseAnyway: "End Lease Anyway",
    deleteLeaseAnywayDescriptionSecondary: "Alternatively, terminate the lease and adjust balances at a later time.",
    moneyOwedToYou: "Money Owed to You",
    moneyOwedToTenant: "Money owed to tenant",
    endingLeaseTitle: "Quick Guide on How to Terminate a Lease",
    endingLeaseDescription: "To clear the balances, please follow these steps.",
    refundGreaterThanDepositDescription:
      "You've refunded your tenant an amount exceeding the security deposit, leading to a negative balance. To ensure accurate records, this must be corrected.",
    hasOBAndDepositButDepositGreater:
      "Your lease has an outstanding balance and a security deposit.\n\nThe security deposit is enough to cover the outstanding balance.",
    hasOBAndDepositButDepositLessThan:
      "Your lease has an outstanding balance and a security deposit.\n\nThe security deposit is not enough to cover the outstanding balance on the lease.",
    issueCreditToZeroOutDescription:
      "Post a credit to the security deposit account to zero out the security deposit balance.",
    issueCreditToReduceAmountOwedTitle: "Add a credit to reduce the amount owed to you",
    issueCreditToReduceAmountOwedDescription: "Reduce the amount owed to you by issuing a credit to the tenant.",
    postChargeToZeroOutDescription: "Post a charge to the security deposit account to zero out the lease balance.",
    postChargeToIncreaseDepositBalanceDescriptionTitle: "Charge the Security Deposit",
    postChargeToIncreaseDepositBalanceDescription:
      "Increase the outstanding balance by posting a charge to the security deposit account.",
    postChargeToReduceAmountOwedToTenantTitle: "Post a charge to reduce the amount owed to the tenant",
    postChargeToReduceAmountOwedToTenantDescription: "Create charges for your tenants to pay",
    postChargeToReduceCreditAndDepositTitle: "Post a charge to reduce the amount owed to the tenant",
    postChargeToReduceCreditAndDepositDescription: "Create charges for your tenants to pay",
    receivePaymentToZeroOutTitle: "Record Received Payments",
    receivePaymentToZeroOutDescription: "Reduce the outstanding balance by recording received payments.",
    addACreditToReduceAmountOwedTitle: "Issue a credit to the tenant to reduce the amount owed to you",
    addACreditToReduceAmountOwedDescription: "Reduce the amount owed with a discount",
    withholdDepositToApplyToLeaseTitle: "Withhold the security deposit to apply it to charges on the lease",
    withholdDepositToApplyToLease: "Withhold the security deposit",
    withholdDepositToPartiallyCoverAmountOwnedTitle: "Withhold the deposit to partially cover the amount owed",
    withholdDepositToPartiallyCoverAmountOwnedDescription: "Withhold the security deposit.",
    leaseHasOutstandingCredit:
      "This lease has an outstanding credit. This can happen when the tenant overpaid their rent.",
    leaseHasOutstandingCreditWithSecurityDeposit:
      "This lease has an outstanding credit. This can happen when the tenant overpaid their rent.\n\nThere is also a security deposit balance.",
    leaseOutstandingCredit: "Lease Outstanding Credit",
    refundTheAmountOwedTheTenantTitle: "Refund Overpaid Amounts to Tenants",
    refundTheAmountOwedTheTenant: "Issue refunds to tenants for any overpayments.",
    refundTheCreditAndDepositToTenantTitle: "Refund the security deposit to the tenant",
    refundTheCreditAndDepositToTenant: "The tenant should receive a refund of the security deposit",
    wizardSteps: {
      overview: "Overview",
      chargesAndCredits: "Charges And Credit",
      deposits: "Deposits",
      refunds: "Refunds"
    },
    currentLeaseTerms: "Terminate the lease agreement",
    moveOutDate: "Move-out date",
    moveOutDateDescription: "What is the move-out date?",
    noticeDate: "Notice Date",
    moveOutChargesAndCredits: "Add Move-Out Charges and Credits",
    moveOutChargesAndCreditsDescription: `Use the buttons below to add move-out charges or credits as needed.`,
    moveOutChargesAndCreditsAction: `Click “Continue” once you’re done or if you have no move-out charges or credits.`,
    refundDueToTenant: "Refund Due to Tenant",
    loadingLeaseData: "Loading lease data",
    leaseDataLoaded: "Lease data was loaded",
    addMoveOutCharge: "Add Move-Out Charge",
    addMoveOutCredit: "Add Move-Out Credit",
    insufficientOutstandingBalance:
      "The deposits held on the lease are not enough to cover the outstanding balance. Please add a move-out credit or receive a payment from the tenant before proceeding.",
    refundDepositTabDescription:
      "Please click the button below to refund the deposit. Click “End Lease” once you are done.",
    withholdDepositTabDescription: `Click the button below to withhold the deposit. This will cover
    the deposit held amount. Click "Next" when you are done.`,
    withholdDepositValidationMessage: "Please withhold the deposits held before continuing",
    refundBalance: "Refund balance",
    refundBalanceDescription: "Please refund the remaining balance to the tenant(s)",
    refundsValidationMessage: "Please refund the tenants before proceeding",
    endingLease: "Ending Lease ...",
    leaseEnded: "Lease was ended"
  },
  leaseTransaction: {
    noTransactionsFound: "No Transactions Found",
    reconcileWithoutTransactions: "Reconcile Without Transactions",
    reconciliationReportForZeroTransactionsDescription:
      "No transactions seem to be recorded for the period ending on {{endDate}}. Do you still want to create a reconciliation report for 0 transactions?",
    reconciliationReportForZeroTransactions: "Reconciliation report for zero transactions",
    createReconciliation: "Create reconciliation",
    noDepositsFound: "No Deposits Found",
    transactionType: "Transaction Type",
    receivedFrom: "Received From",
    charge: {
      switchToBulkPostCharges: "Switch to Bulk Post Charges",
      switchToPostCharge: "Switch to Post Charge",
      createdText: "Charge created",
      loadingData: "Loading charge data",
      loadingDataSuccess: "Charge data was loaded",
      deleteConfirmationText: "Are you sure you want to delete the charge?",
      deleteChargeLoadingText: "Deleting charge",
      deleteChargeSuccessText: "Charge was deleted successfully",
      creating: "Creating charge",
      updating: "Updating charge",
      updated: "Charge updated"
    },
    credit: {
      switchToBulkIssueCredits: "Switch to Bulk Issue Credits",
      switchToIssueCredit: "Switch to Issue Credit",
      createdText: "Credit created",
      loadingData: "Loading credit data",
      loadingDataSuccess: "Credit data was loaded",
      deleteConfirmationText: "Are you sure you want to delete the credit?",
      deleteChargeLoadingText: "Deleting credit",
      deleteChargeSuccessText: "Credit was deleted successfully",
      creating: "Creating credit",
      updating: "Updating credit",
      updated: "Credit updated"
    },
    deposit: {
      chargeDeposit: "Charge Deposit",
      createdText: "Deposit created",
      loadingData: "Loading deposit data",
      loadingDataSuccess: "Deposit data was loaded",
      deleteConfirmationText: "Are you sure you want to delete the deposit?",
      deleteChargeLoadingText: "Deleting deposit",
      deleteChargeSuccessText: "Deposit was deleted successfully",
      creating: "Creating deposit",
      updating: "Updating deposit",
      updated: "Deposit updated",
      amountHeld: "Amount Held",
      depositAccount: "Deposit Account",
      amountToWithhold: "Amount to Withhold",
      amountToRefund: "Amount to Refund",
      leaseOutstandingBalance: "Lease Outstanding Balance",
      totalDepositsWithheld: "Total Deposits Withheld",
      remainingDeposits: "Remaining Deposits",
      noDepositHeld: "There are no deposits held by the account.",
      depositsRefunded: "Deposits Refunded",
      depositRefunded: "Deposit Refunded"
    },
    payment: {
      switchToBulkReceivePayments: "Switch to Bulk Receive Payments",
      switchToSinglePaymentReceive: "Switch to Single Payment Receive",
      bulkReceivePayments: "Bulk Receive Payments",
      receivedFrom: "Received From",
      depositAccount: "Deposit Account",
      paymentAmount: "Payment Amount",
      amountReceived: "Amount Received",
      paymentMethod: "Payment Method",
      checkNumber: "Check Number",
      checkNumberAbbreviated: "Check #",
      automaticPaymentAllocation: "Automatically Apply Payments",
      automaticDepositCreation: "Automatically Deposit Funds",
      sendPaymentReceipts: "Email Payment Receipt to the Tenant",
      outstandingTransactions: "Outstanding Transactions",
      loadingOutstandingTransactions: "Loading outstanding transactions",
      charge: "Charge",
      noOutstandingTransactions: "No outstanding transactions",
      deleteConfirmationText: "Are you sure you want to delete the payment?",
      deletePaymentLoadingText: "Deleting payment",
      deletePaymentSuccessText: "Payment was deleted successfully",
      amountToApply: "Amount to Apply",
      amountToCredit: "Amount to Credit",
      invalidAmountReceived:
        "The Amount Received (plus credits) cannot be less than the selected charges. Also, the selected charges cannot be less than the selected credits.",
      createdText: "Payment Created",
      loadingData: "Loading Payment",
      loadingDataSuccess: "Payment was loaded",
      creating: "Creating Payment",
      updating: "Updating Payment",
      updated: "Payment updated",
      appliedCreditHelpPanel: {
        description: "Review how this credit was applied"
      }
    },
    refund: {
      payToTenant: "Paid To Tenant",
      payFromAccount: "Pay From Account",
      addToPrintQueue: "Add to Print Queue",
      leaseRefund: "Lease Refund",
      overpaymentRefund: "This is a refund for an overpayment (creates a charge to offset the overpayment)",
      refundDeposit: "Refund Deposit",
      withholdDeposit: "Withhold Deposit",
      createNewWithholdDeposit: "Withhold New Deposit",
      accountbalance: "Account Balance",
      deleteRefundLoadingText: "Deleting refund",
      deleteRefundSuccessText: "Refund was deleted successfully",
      deleteConfirmationText: "Are you sure you want to delete the refund?"
    },
    rent: {
      createdText: "Rent created",
      loadingData: "Loading rent data",
      loadingDataSuccess: "Rent data was loaded",
      creating: "Creating rent",
      updating: "Updating rent",
      updated: "Rent updated",
      deleteRentLoadingText: "Deleting rent",
      deleteRentSuccessText: "Rent was deleted successfully",
      deleteConfirmationText: "Are you sure you want to delete the rent?"
    },
    reversedPayment: {
      deleteReversedPaymentLoadingText: "Deleting reversed payment",
      deleteReversedPaymentSuccessText: "Reversed payment was deleted successfully",
      deleteConfirmationText: "Are you sure you want to delete the reversed payment?"
    },
    dialog: {
      titleText: "Reverse a payment",
      loadingText: "Reversing payment",
      reverseConfirmationText: "Are you sure you want to reverse the payment?",
      reversedPaymentSuccessText: "Payment was reversed",
      explainerText:
        "If a payment previously deposited in your bank has been returned for example, for insufficient funds, you can reverse the payment to increase the balance owed on this lease.",
      originalPayment: "Original Payment",
      returnedPayment: "Returned Payment",
      returnDate: "Returned Payment Date"
    }
  },
  settings: {
    leaseTerm: "Term",
    leaseSettings: "Lease Settings",
    termsDescriptionTitle: "Lease terms option",
    termsDescriptionSubTitle: "Set the lease start and end dates.",
    lateFeesDescriptionTitle: "Lease management late fees",
    paymentsDescriptionTitle: "lease payments settings",
    lateFees: "Lease Late Fees",
    lateFeesDescription: "If you manage this lease for someone else, you can set your late fee structure.",
    specifyLateFeesForLease: "Select a late fee structure for this lease",
    residentPortal: "Lease Resident Portal",
    specifyResidentPortalForLease: "Select resident portal options for this lease",
    rentersInsurance: "Lease Renters Insurance",
    specifyRentersInsurance: "Select renters insurance options for this lease",
    rentersInsuranceDescription: "Do you need renters insurance for this lease?",
    paymentsAllocation: "Lease Payment Allocation",
    specifyPaymentAllocation: "Specify payment allocation options for this lease",
    evictionStatus: "Eviction status",
    evictionStatusDescription: "Update the eviction status for this lease.",
    leaseInEviction: "This lease is undergoing eviction proceedings.",
    evictionDialogDescription: "You can keep track of leases that are in eviction proceedings",
    evictionRecommend: "We also recommend the following:",
    evictionNote:
      "Please note, Online payments are currently not permitted for this lease, and any recurring payments will be disabled.",
    evictionNoteBefore:
      "Please note, Online payments will not be permitted for this lease, and any recurring payments will be disabled.",
    cancelEvictionNote: "Please note, by clicking 'save' you are agreeing to enable tenant online payments.",
    evictionConfirmation:
      "By checking this box, you agree to disable online payments for tenants. However, you can still manually enable online payments through the Tenant Portal settings.",
    evictionNotificationDisableOnlinePayments: "Disable future online payments for this lease",
    evictionNotificationStopRecurringPayments: "Stop all active recurring payments",
    nameAndNotes: "Lease Name & Notes",
    nameAndNotesDialogDescription: "Update the name of this lease and add notes as needed",
    leaseName: "Lease Name",
    startDate: "Start Date",
    endDate: "End Date",
    unit: "Unit",
    address: "Address",
    rentCharges: "Rent Charges"
  }
};
