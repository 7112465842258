import React, { useEffect, useState } from "react";
import makeStyles from "./styles";
import clsx from "clsx";

interface ComponentProps {
  isActive: boolean;
  children: React.ReactNode;
  containerClassName?: string;
  fullWidth?: boolean;
}

const Step: React.FC<ComponentProps> = ({ isActive, children, containerClassName, fullWidth }: ComponentProps) => {
  const classes = makeStyles();
  const [renderContent, setRenderContent] = useState(false);

  useEffect(() => {
    setRenderContent(isActive);
  }, [isActive]);

  if (!renderContent) {
    return null;
  }

  return (
    <div
      style={
        fullWidth
          ? {
              width: "100%",
              justifyContent: "center",
              display: "flex"
            }
          : {}
      }
      className={clsx([isActive ? classes.show : classes.hide, containerClassName])}
    >
      {children}
    </div>
  );
};

export default Step;
