import type { ReactNode } from "react";
import type { GridHeaderCellProps } from "@progress/kendo-react-grid";
import { ControlledCheckBox } from "DLUI/form";
import { SELECTION_IDENTIFIER } from "DLUI/lists/types";

interface MultiSelectGridListHeaderCellProps {
  tdElement: ReactNode;
  cellProps: GridHeaderCellProps;
  dataState: any[] | null;
  onGroupSelectionChange: (newValue: boolean) => void;
}
const MultiSelectGridListHeaderCell = ({
  tdElement,
  cellProps,
  dataState,
  onGroupSelectionChange
}: MultiSelectGridListHeaderCellProps) => {
  const isAllSelected = Boolean(dataState?.every((item) => item[SELECTION_IDENTIFIER]));
  const isPartiallySelected = !isAllSelected && dataState?.some((item) => item[SELECTION_IDENTIFIER]);
  const newValue = !(isAllSelected || isPartiallySelected);

  if (cellProps.field !== SELECTION_IDENTIFIER) {
    return tdElement;
  }

  return (
    <ControlledCheckBox
      style={{ marginLeft: -13, marginBottom: -4 }}
      checked={isAllSelected}
      indeterminate={isPartiallySelected}
      onChange={() => onGroupSelectionChange(newValue)}
    />
  );
};

export default MultiSelectGridListHeaderCell;
