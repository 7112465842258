import { View } from "DLUI/view";
import NavigationProfileItem from "DLUI/drawer/navigationUserProfileItem";
import { notificationApi } from "api/notificationApi";
import NotificationCenter from "DLUI/notificationCenter/notificationCenter";
import type { FC } from "react";
import React, { useState } from "react";
import { HelpBoldIcon, UserPlaceholder } from "@/assets";
import AppStrings from "locale/keys";
import { Popover } from "DLUI/popover";
import { Icon } from "DLUI/icon";
import makeStyles from "./styles";
import clsx from "clsx";
import { TextTooltip } from "DLUI/popover/textTooltip";
import { helpPopoverItems } from "DLUI/drawer/helpButtonItem";
import { desktopTopNavigationBarBackgroundColorAtom } from "components/layouts/layoutAtom";
import { useAtom } from "jotai";
import { GlobalSearchInput } from "DLUI/screen/globalSearch/globalSearchInput";
import { useSelector } from "react-redux";
import type { IRootState } from "store";

export const DESKTOP_TOP_BAR_HEIGHT = 48;

interface TopBarComponentProps {
  onGlobalSearchPress: () => void;
}

export const TopNavigationBar: FC<TopBarComponentProps> = ({ onGlobalSearchPress }) => {
  const [popOverOpen, setPopOverOpen] = useState<boolean>(false);
  const [desktopTopNavigationBarBackgroundColor] = useAtom(desktopTopNavigationBarBackgroundColorAtom);
  const classes = makeStyles();
  const isUserAuthenticated = useSelector((state: IRootState) => state.auth?.isAuthenticated);

  const handlePopOver = () => {
    setPopOverOpen(true);
  };

  const onUserPopoverStateChange = (nextState: "open" | "close") => {
    setPopOverOpen(nextState === "open");
  };

  return (
    <View
      overflow={"hidden"}
      style={{
        backgroundColor: desktopTopNavigationBarBackgroundColor
      }}
      className={"hide-on-print"}
    >
      <View
        flexDirection={"row"}
        alignItems={"center"}
        noWrap
        className={clsx(["top-navigation-bar", classes.topBarContainer])}
        height={DESKTOP_TOP_BAR_HEIGHT}
        overflow={"hidden"}
        style={{
          visibility: isUserAuthenticated ? "visible" : "hidden"
        }}
      >
        <View></View>
        <GlobalSearchInput onGlobalSearchPress={onGlobalSearchPress} />
        <View flexDirection={"row"} noWrap justifyContent={"flex-end"} paddingRight={20}>
          <NotificationCenter apiMethod={notificationApi} top={0} isSideBarMinimized={true} iconSize={24} />
          <View
            width={40}
            height={40}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"flex-end"}
            overflow={"hidden"}
          >
            <TextTooltip value={AppStrings.Common.Help} placement={"bottom"} useButton={false}>
              <View
                flexDirection={"row"}
                justifyContent={"center"}
                autoWidth
                alignItems={"center"}
                noWrap
                onClick={handlePopOver}
                paddingRight={10}
                paddingLeft={10}
                paddingBottom={10}
                paddingTop={10}
                style={{ height: "auto" }}
                className={"userPopoverContainer"}
              >
                <Icon Source={HelpBoldIcon} size={24} />
              </View>
            </TextTooltip>
            <Popover
              hideDotsIcon
              iconPathColor={"white"}
              backgroundColor={"white"}
              width={245}
              topSectionItems={helpPopoverItems || []}
              anchorPositionTop={-1}
              onStateChange={onUserPopoverStateChange}
              iconSize={16}
              shouldShow={popOverOpen}
              disableHover
            />
          </View>
          <View width={40} height={40} justifyContent={"center"} alignItems={"center"}>
            <NavigationProfileItem iconSize={24} AvatarIcon={UserPlaceholder} />
          </View>
        </View>
      </View>
    </View>
  );
};
