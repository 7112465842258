"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addressCountryAndPhoneNumberCountryMap = void 0;
const phoneNumberCountry_1 = require("./phoneNumberCountry");
exports.addressCountryAndPhoneNumberCountryMap = {
    US: phoneNumberCountry_1.PhoneNumberCountry.US,
    "United States": phoneNumberCountry_1.PhoneNumberCountry.US,
    CA: phoneNumberCountry_1.PhoneNumberCountry.CA,
    Canada: phoneNumberCountry_1.PhoneNumberCountry.CA
};
