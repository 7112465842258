import React from "react";
import { BadgeIcon, LinkIcon, UsersIcon } from "assets/icons";
import type { MenuItem } from "DLUI/dialogs";
import AppStrings from "locale/keys";
import AccountOwner from "./components/accountOwner";
import CompanyInfo from "./components/companyInfo";
import DoorLoopUrl from "./components/doorLoopUrl";

export const getCompanyInfoFormMenuItems = (): MenuItem[] => [
  {
    title: AppStrings.Settings.GeneralSettings.CompanyInfo.GeneralInfo,
    icon: BadgeIcon,
    contentComponent: (formikRef, onFileReceive, onFileDelete) => (
      <CompanyInfo formikRef={formikRef} onFileReceive={onFileReceive} onFileDelete={onFileDelete} hideNotesSection />
    ),
    uploadFile: true
  },
  {
    title: AppStrings.Settings.GeneralSettings.doorLoopURL.DoorLoopURL,
    icon: LinkIcon,
    contentComponent: (formikRef) => <DoorLoopUrl formikRef={formikRef} />
  },
  {
    title: AppStrings.Users.Screen.AccountOwner,
    icon: UsersIcon,
    contentComponent: (formikRef) => <AccountOwner formikRef={formikRef} />
  }
];
