import React, { useMemo, useState } from "react";
import { FastField, getIn, useFormikContext } from "formik";
import { View } from "DLUI/view";
import { TextField, SwitchButton } from "DLUI/form";
import { WorkOrderPermissionToEnter } from "@doorloop/dto";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import type { TenantPortalRequestDto } from "@doorloop/dto";
import { DataCy } from "@doorloop/dto";
import { useTypedTranslation } from "@/locale";
import Text from "DLUI/text/text";
import { useSelector } from "react-redux";
import type { IRootState } from "store";
import ColorsEnum from "@/utils/colorsEnum";
import { Icon } from "DLUI/icon";
import { CheckmarkCircleIcon, CloseCircleIconRed } from "@/assets";
import makeStyles from "./styles";
import { useUserType } from "../../../../hooks/useUserType";

const EntryPermission: React.FC = () => {
  const formikRef = useFormikContext<TenantPortalRequestDto>();
  const { isHOAUser } = useUserType();
  const { t } = useTypedTranslation();
  const classes = makeStyles();
  const userData = useSelector((state: IRootState) => state.auth.currentLoginResponse);
  const entryPermissionDefaultValue = getIn(formikRef.values, "entryPermission");
  const [isEntryPermission, setIsEntryPermission] = useState<boolean>(
    Boolean(entryPermissionDefaultValue && entryPermissionDefaultValue === WorkOrderPermissionToEnter.YES)
  );
  const onChangeSwitchButton = (nextValue: boolean) => {
    formikRef.setFieldValue(
      "entryPermission",
      nextValue ? WorkOrderPermissionToEnter.YES : WorkOrderPermissionToEnter.NOT_APPLICABLE
    );
    setIsEntryPermission(nextValue);
  };

  const isCreatedByCurrentLogin = useMemo(
    () => formikRef.values?.createdBy === userData?.id,
    [formikRef.values?.createdBy, userData]
  );

  return (
    <View gap={8} marginTop={20}>
      <Text bold value={isHOAUser ? t("common.accessToAssociation") : t("common.accessToProperty")} />
      {isCreatedByCurrentLogin || !formikRef.values?.createdBy ? (
        <SwitchButton
          onChange={onChangeSwitchButton}
          label={isHOAUser ? t("common.requestAccessNeededFromOwner") : t("common.requestAccessNeededFromTenant")}
          checked={isEntryPermission}
          dataCy={DataCy.tasks.entryPermissionSwitch}
        />
      ) : (
        <View gap={4} flexDirection="row">
          <Icon Source={isEntryPermission ? CheckmarkCircleIcon : CloseCircleIconRed} size={18} />
          <Text fontSize={14} value={isEntryPermission ? t("common.accessGranted") : t("common.noAccessToProperty")} />
        </View>
      )}

      {isCreatedByCurrentLogin || !formikRef.values?.createdBy ? (
        <View shouldShow={isEntryPermission} showAnimation="fade-in" hideAnimation="fade-out" width="100%">
          <FastField
            component={TextField}
            label={t("tasks.tenantRequestDialog.entryNotes")}
            name="entryNotes"
            multiline
            rows="4"
            marginTop={10}
            maxLength={10000}
          />
        </View>
      ) : (
        <>
          {isEntryPermission && formikRef.values?.entryNotes?.length ? (
            <Text
              paddingTop={10}
              paddingLeft={12}
              paddingRight={12}
              fontSize={14}
              numberOfLines={4}
              colorEnum={ColorsEnum.LightBlack}
              className={classes.notCurrentLoginText}
              value={formikRef.values?.entryNotes}
            />
          ) : null}
        </>
      )}
      <HorizontalSeparationLine marginTop={20} />
    </View>
  );
};

export default EntryPermission;
