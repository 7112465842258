import type { HasTenantsAutoPayments } from "@doorloop/dto";

import type { ApiResult } from "api/apiResult";
import type { HasTenantsAutoPaymentParams } from "./hooks/hasTenantsAutoPaymentParams";
import { leasesApi } from "api/leasesApi";
import { propertiesApi } from "api/propertiesApi";
import { settingsApi } from "api/settingsApi";

export const loadHasTenantsAutoPayments = async ({
  leaseId,
  propertyId
}: HasTenantsAutoPaymentParams): Promise<ApiResult<HasTenantsAutoPayments>> => {
  let hasAutoPaymentsApiResult: ApiResult<HasTenantsAutoPayments>;

  if (leaseId) {
    hasAutoPaymentsApiResult = await leasesApi.hasTenantsAutoPayments(leaseId);
  } else if (propertyId) {
    hasAutoPaymentsApiResult = await propertiesApi.hasTenantsAutoPayments(propertyId);
  } else {
    hasAutoPaymentsApiResult = await settingsApi.hasTenantsAutoPayments();
  }

  return hasAutoPaymentsApiResult;
};
