import type { Theme } from "@material-ui/core/styles/createTheme";
import { makeStyles } from "@material-ui/styles";
import type { ToggleInputSize } from "DLUI/form/types/toggleInputBaseTypes";
import type { LabelPlacementPosition } from "DLUI/form/label/inputLabel";
import type { SwitchClassKey } from "@material-ui/core";

type StylesContainerClassKeys = LabelPlacementPosition | "root";

const useStylesContainer = makeStyles<Theme, NonNullable<unknown>, StylesContainerClassKeys>((theme) => {
  return {
    root: {
      display: "inline-flex",
      alignItems: "center !important" as "center"
    },
    start: {
      flexDirection: "row-reverse !important" as "row-reverse"
    },
    end: {
      flexDirection: "row !important" as "row"
    }
  };
});

type StylesInputClassKeys =
  | ToggleInputSize
  | Exclude<SwitchClassKey, "disabled" | "input" | "colorPrimary" | "colorSecondary" | "sizeSmall">;

const useStylesInput = makeStyles<Theme, NonNullable<unknown>, StylesInputClassKeys>((theme) => {
  return {
    root: {
      width: 32,
      height: 20,
      padding: 0
    },
    switchBase: {
      padding: 0,
      "&$checked": {
        transform: "translateX(14px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: theme.newPalette.secondary.main,
          opacity: 1,
          borderColor: "transparent"
        },
        "&:hover + $track": {
          backgroundColor: "#114FAB"
        },
        "&:has( :disabled) + $track": {
          backgroundColor: theme.newPalette.secondary.main,
          opacity: 0.5
        }
      },

      "& + $track": {
        backgroundColor: "#CBD0DB",
        opacity: 1,
        borderColor: "transparent"
      },
      "&:hover + $track": {
        backgroundColor: "#B2B8C3"
      },
      "&:has( :disabled)": {
        "& + $track": {
          backgroundColor: "#CBD0DB",
          opacity: 0.5
        },
        "& $thumb": {
          backgroundColor: theme.newPalette.secondary.contrastText
        }
      }
    },
    thumb: {
      width: 24,
      height: 24,
      boxShadow: "none"
    },
    track: {
      borderRadius: 100,
      backgroundColor: "#CBD0DB",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"])
    },
    checked: {},
    focusVisible: {},
    small: {
      width: 24,
      height: 16,
      "& $switchBase": {
        height: 16,
        width: 16
      },

      "& $checked": {
        transform: "translateX(8px)"
      },
      "& $thumb": {
        width: 12,
        height: 12
      }
    },
    medium: {
      width: 32,
      height: 20,
      "& $switchBase": {
        height: 20,
        width: 20
      },

      "& $checked": {
        transform: "translateX(12px)"
      },
      "& $thumb": {
        width: 14,
        height: 14
      }
    },
    large: {
      width: 52,
      height: 32,
      "& $switchBase": {
        height: 32,
        width: 32
      },

      "& $checked": {
        transform: "translateX(20px)"
      },
      "& $thumb": {
        width: 26,
        height: 26
      }
    },
    color: {
      color: "inherit !important"
    }
  };
});

export { useStylesContainer, useStylesInput };
