import { useEffect, useState } from "react";
import _ from "lodash";

export const useDisableCta = () => {
  const [actionDisableObj, setActionDisableObj] = useState<Record<string, boolean>>({});

  const updateActionObj = (id: string, value: boolean) => {
    if (actionDisableObj?.[id]) {
    } else if (setActionDisableObj) {
      setActionDisableObj({
        ...actionDisableObj,
        [id]: value
      });
    }
  };

  useEffect(
    () => () => {
      if (!_.isEmpty(actionDisableObj)) {
        const resetDisabledCta = () => {
          setActionDisableObj({});
        };
        resetDisabledCta();
      }
    },
    []
  );

  return { actionDisableObj, setActionDisableObj, updateActionObj };
};
