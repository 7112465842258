import type { CSSProperties, FC } from "react";
import React from "react";
import { View } from "DLUI/view";
import type { ViewComponentProps } from "DLUI/view/view";

interface Props extends ViewComponentProps {
  url: string;
  style?: CSSProperties;
  contain?: boolean;
  backgroundSize?: number;
}

export const Picture: FC<Props> = ({ url, contain, backgroundSize, style, children, ...rest }: Props) => (
  <View
    style={{
      backgroundImage: `url("${url}")`,
      backgroundSize: backgroundSize || (contain ? "contain" : "cover"),
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat"
    }}
    borderRadius={5}
    height={"100%"}
    {...rest}
  >
    {children}
  </View>
);
