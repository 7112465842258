import type { LateFeeChargeDto } from "@doorloop/dto";
import { DataCy, LateFeePolicyFeeType } from "@doorloop/dto";
import { ItemLabelChip } from "screens/settings/common/itemLabelChip";
import clsx from "clsx";
import Text from "DLUI/text";
import { useTypedTranslation } from "locale";
import type { TextColor } from "DLUI/text/text";
import { useGeneralStyles } from "styles/generalStyles";
import { formatNumberToFractionWithCommas } from "utils/formatNumberToFractionWithCommas";
import {
  getRuleDelinquencyDescription,
  getTotalChargesForRule
} from "DLUI/dialogs/settings/common/lateFees/sampleCalculator/utils";
import { useCalculatorStyles } from "DLUI/dialogs/settings/common/settings/calculatorStyles";
import { TextTooltip } from "DLUI/popover/textTooltip";
import { HelpIcon } from "@/assets";
import { Icon } from "DLUI/icon";
import type { CSSProperties } from "react";
import { useMemo } from "react";

interface DelinquencyInfoProps {
  isEditMode: boolean;
}

const DelinquencyInfo = ({ isEditMode }: DelinquencyInfoProps) => (
  <Icon Source={HelpIcon} size={16} pathColor={isEditMode ? "black" : "light-gray"} />
);

const DELINQUENCY_INFO_CONTAINER: CSSProperties = { width: 24, height: 24 };

interface RuleDisplayProps {
  rule: LateFeeChargeDto;
  index: number;
  textColor: TextColor;
  isEditable: boolean;
  rent: number;
  delinquency: number;
}

export const RuleDisplay: React.FC<RuleDisplayProps> = ({ rule, index, textColor, rent, delinquency, isEditable }) => {
  const { t } = useTypedTranslation();
  const { amount, percentage, feeType } = rule;
  const formattedFee =
    feeType === LateFeePolicyFeeType.FIXED_AMOUNT
      ? `$${formatNumberToFractionWithCommas(amount)}`
      : `${formatNumberToFractionWithCommas(percentage)}%`;
  const calculatorClasses = useCalculatorStyles();
  const generalClasses = useGeneralStyles();

  const totalChangesForRule = useMemo(() => getTotalChargesForRule(rule, delinquency, rent), [rule, delinquency, rent]);

  return (
    <div
      className={calculatorClasses.calculatorRuleRow}
      data-cy={DataCy.globalSettings.lateFees.sampleCalculator.rule.container}
    >
      <div
        className={clsx(
          generalClasses.flexRow,
          generalClasses.verticalAlign,
          generalClasses.mediumGap,
          generalClasses.fullWidth
        )}
      >
        <ItemLabelChip value={index + 1} textColor={textColor} />
        <Text
          value={feeType === LateFeePolicyFeeType.FIXED_AMOUNT ? t("lateFee.sampleCalculator.flat") : formattedFee}
          data-cy={DataCy.globalSettings.lateFees.sampleCalculator.rule.type}
          color={textColor}
          fontSize={14}
          style={{ width: 44 }}
        />
        <Text
          value={
            feeType === LateFeePolicyFeeType.FIXED_AMOUNT
              ? formattedFee
              : `* $${formatNumberToFractionWithCommas(rent)}`
          }
          fontSize={14}
          data-cy={DataCy.globalSettings.lateFees.sampleCalculator.rule.rent}
          color={textColor}
          style={{ width: 80 }}
        />
        {rule.daysAfterDueDateToChargeLateFees && (
          <div className={clsx(generalClasses.flexRow, generalClasses.verticalAlign)} style={{ width: 95 }}>
            <Text
              value={
                rule.daysAfterDueDateToChargeLateFees ? `* ${getRuleDelinquencyDescription(rule, delinquency)}` : ""
              }
              fontSize={14}
              data-cy={DataCy.globalSettings.lateFees.sampleCalculator.rule.frequencyDescription}
              color={textColor}
            />
            <TextTooltip
              IconSource={() => <DelinquencyInfo isEditMode={isEditable} />}
              containerStyle={DELINQUENCY_INFO_CONTAINER}
              value={`${delinquency} day delinquency - ${rule.daysAfterDueDateToChargeLateFees} day grace period`}
            />
          </div>
        )}
        <div className={generalClasses.basicFlex}>
          <Text
            value={totalChangesForRule.toString()}
            fontSize={12}
            formatType={"currency"}
            fontWeight={700}
            color={textColor}
            fullWidth
            paddingRight={0}
            paddingLeft={0}
            alignSelf={"flex-end"}
            dataCy={DataCy.globalSettings.lateFees.sampleCalculator.rule.result}
          />
        </div>
      </div>
    </div>
  );
};
