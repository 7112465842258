// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

interface Config {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
}

export function register(config?: Config) {
  if ("serviceWorker" in navigator) {
    const splittedHost = window.location.host.split(".");

    if (
      splittedHost?.length > 2 &&
      splittedHost?.[2]?.includes("localhost:3000" || "doorloop") &&
      !window.location.pathname.includes("/auth")
    ) {
      return;
    }

    window.addEventListener("load", () => {
      const swUrl = `${window.location.origin}/service-worker.js`;

      checkValidServiceWorker(swUrl, config);
    });
  }

  // required for smart banner
  addEventListener("fetch", (event) => {});
}

function registerValidSW(swUrl: string, config?: Config) {
  try {
    navigator.serviceWorker.register(swUrl).catch((error) => {
      unregister();
      console.error("Error during service worker registration:", error);
    });
  } catch (e) {
    console.log("can't register push notifications due to permissions error");
  }
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  fetch(swUrl)
    .then((response) => {
      const contentType = response.headers.get("content-type");
      if (response.status === 404 || (contentType != null && contentType.indexOf("javascript") === -1)) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        registerValidSW(swUrl);
      }
    })
    .catch(() => {
      console.log("No internet connection found. App is running in offline mode.");
    });
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
}
