import React, { useCallback, useState } from "react";
import { SectionTitle } from "DLUI/screen";
import { View } from "DLUI/view";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import AppStrings from "locale/keys";
import type { FormikProps } from "formik";
import { getIn } from "formik";
import { propertiesApi } from "api/propertiesApi";
import { LinkedResourceType } from "@doorloop/dto";
import { unitsApi } from "api/unitsApi";
import { DialogsHelper } from "../dialogsHelper";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";

interface ComponentProps {
  formikRef: FormikProps<any>;
  shouldShow?: boolean;
  filterByOwner?: string;
}

const RelatedToSection = ({ formikRef, shouldShow, filterByOwner }: ComponentProps) => {
  const { t } = useTranslation();
  const { inputRightPadding } = DialogsHelper();
  const [renderUnitField, setRenderUnitField] = useState<boolean>(formikRef.values.property);

  const renderUnitAutoComplete = useCallback(() => {
    const propertySelection = getIn(formikRef.values, "property");
    if (!renderUnitField || !propertySelection) {
      return null;
    }

    return (
      <FormikCachedAsyncAutoComplete
        uniqueIndex={"unit"}
        apiHandler={unitsApi}
        displayNameKey={"name"}
        filterFieldName={"filter_text"}
        filterFieldValue={"name"}
        selectionFields={["id", "class"]}
        name={"unit"}
        label={t(AppStrings.Leases.NewLease.OptionalUnit)}
        queryParams={{
          filter_property: propertySelection
        }}
        onChange={didSelectUnit}
        defaultValue={formikRef.values.unit}
        marginTop={20}
      />
    );
  }, [formikRef.values.unit, formikRef.values.property, renderUnitField]);

  const refreshUnitField = () => {
    setRenderUnitField(false);
    setTimeout(() => {
      setRenderUnitField(true);
    }, 0);
  };

  const didSelectProperty = (_, selectedValue: any) => {
    if (selectedValue && selectedValue.id !== formikRef.values.property) {
      formikRef.setFieldValue("linkedResource.resourceId", selectedValue?.id);
      formikRef.setFieldValue("linkedResource.resourceType", LinkedResourceType.Property);
      formikRef.setFieldValue("property", selectedValue?.id);
      formikRef?.setFieldValue("unit", null);

      formikRef?.setFieldTouched("property", false, false);
    } else if (!selectedValue) {
      formikRef.setFieldValue("linkedResource", null);
      formikRef.setFieldValue("property", null);
      formikRef?.setFieldValue("unit", null);
    }

    refreshUnitField();
  };

  const didSelectUnit = (_, selectedValue: any) => {
    formikRef.setFieldValue("linkedResource.resourceId", selectedValue?.id ?? formikRef.values.property);
    formikRef.setFieldValue(
      "linkedResource.resourceType",
      selectedValue ? LinkedResourceType.Unit : LinkedResourceType.Property
    );
    formikRef.setFieldValue("unit", selectedValue?.id);
  };

  if (shouldShow !== undefined && !shouldShow) {
    return null;
  }

  return (
    <View>
      <SectionTitle title={t(AppStrings.Tasks.InternalTaskDialog.RelatedTo)} type={"underline"} marginTop={20} />
      <View flexDirection={"row"}>
        <Grid item lg={6} md={6} xs={12}>
          <FormikCachedAsyncAutoComplete
            uniqueIndex={"property"}
            apiHandler={propertiesApi}
            displayNameKey={"name"}
            filterFieldName={"filter_text"}
            filterFieldValue={"name"}
            selectionFields={["id", "class"]}
            name={"property"}
            label={t(AppStrings.Common.FilterOptions.Property)}
            onChange={didSelectProperty}
            defaultValue={formikRef.values.property}
            marginTop={20}
            paddingRight={inputRightPadding}
            queryParams={{ filter_active: true, filter_owner: filterByOwner }}
          />
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          {renderUnitAutoComplete()}
        </Grid>
      </View>
    </View>
  );
};

export default RelatedToSection;
