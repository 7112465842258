import { ServerRoutes } from "@doorloop/dto";
import type { CommunicationDto } from "@doorloop/dto";
import type { GetAllCommunicationsQuery } from "@doorloop/dto";
import { tenantsApi } from "api/tenantsApi";
import { RestApiBase } from "api/restApiBase";
import { usersApi } from "api/usersApi";
import { vendorsApi } from "api/vendorsApi";
import { ownersApi } from "api/ownersApi";

export class CommunicationsApi extends RestApiBase<CommunicationDto, GetAllCommunicationsQuery> {
  constructor() {
    super(ServerRoutes.COMMUNICATIONS, [tenantsApi, usersApi, vendorsApi, ownersApi]);
    this.restRoute = ServerRoutes.COMMUNICATIONS;
  }
}

export const communicationsApi = new CommunicationsApi();
