import { copyToClipboard } from "../../../utils/clipboard";
import type { ToastStateValid } from "store/toast/actions";
import { handleToast } from "store/toast/actions";
import AppStrings from "../../../locale/keys";
import { useDispatch } from "react-redux";

export const useCopyText = (
  text: string,
  onFinish?: () => void,
  toastProps: ToastStateValid = {
    severity: "neutral",
    translationKey: AppStrings.Toasts.custom.copy.text
  }
) => {
  const dispatch = useDispatch();

  return () => {
    copyToClipboard(text);
    dispatch(handleToast(toastProps));
    onFinish?.();
  };
};
