import type { DepositDto, GetAllDepositsQuery } from "@doorloop/dto";
import { ServerRoutes } from "@doorloop/dto";
import { RestApiBase } from "./restApiBase";
import { apiHelper } from "./apiHelper";
import type { ApiResult } from "./apiResult";
import { tenantsApi } from "./tenantsApi";
import { vendorsApi } from "./vendorsApi";
import { ownersApi } from "./ownersApi";

export class DepositsApi extends RestApiBase<DepositDto, GetAllDepositsQuery> {
  constructor() {
    super(ServerRoutes.DEPOSITS, [tenantsApi, vendorsApi, ownersApi]);
    this.restRoute = ServerRoutes.DEPOSITS;
  }

  async update(id: string, data: DepositDto): Promise<ApiResult<DepositDto>> {
    return await apiHelper.axiosPut<DepositDto>({ url: this.restRoute + "/" + id, data });
  }

  async getIsAccountReconciled(id: string): Promise<ApiResult<boolean>> {
    return await apiHelper.axiosGet(`/api/deposits/${id}/is-account-reconciled`);
  }

  getUndepositedEntriesForAccount = async (query: GetAllDepositsQuery) => {
    await super.loadDictionariesRequiredForGet();
    return await apiHelper.axiosGet(ServerRoutes.DEPOSITS_GET_UNDEPOSITED_ENTRIES_FOR_ACCOUNT, query);
  };
}

export const depositsApi = new DepositsApi();
