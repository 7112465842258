import type { FC } from "react";
import React, { useMemo } from "react";
import { View } from "DLUI/view";
import type { NotificationSettingsRowInterface } from "DLUI/dialogs/notifications/components/notificationSettingsContextWrapper";
import { ColumnGridSizeEnum } from "DLUI/dialogs/notifications/components/notificationSettingsContextWrapper";
import Text from "DLUI/text";
import AppStrings from "../../../../../locale/keys";
import upperFirst from "lodash/upperFirst";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { FormikCheckBox } from "DLUI/form";
import type { TenantNotificationSettingsDto, UserNotificationSettingsDto } from "@doorloop/dto";
import { LoginResponseType } from "@doorloop/dto";
import WarningView from "DLUI/form/warningView/warningView";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ColorsEnum from "../../../../../utils/colorsEnum";
import { Icon } from "DLUI/icon";
import { NotificationSettingsInformation } from "../../../../../assets";
import { TextTooltip } from "DLUI/popover/textTooltip";
import ActionIconLink from "../../../link/actionIconLink";
import { tenantNotificationFieldNameToSettingOptions, userNotificationFieldNameToSettingOptions } from "../constants";
import type { NotificationSettingsTableRowProps } from "./notificationSettingsTableRow";
import { NotificationSettingsTableRow } from "./notificationSettingsTableRow";

interface NotificationSettingsViewProps {
  headerRows: NotificationSettingsTableRowProps["row"];
  userType: LoginResponseType;
  allowedSettings: Array<keyof UserNotificationSettingsDto> | Array<keyof TenantNotificationSettingsDto>;
  values: UserNotificationSettingsDto | TenantNotificationSettingsDto;
}

const RowIndexTitle: FC<{ value: string; isDisabled?: boolean }> = ({ value, isDisabled = false }) => {
  const { t } = useTranslation();

  if (isDisabled) {
    return (
      <View flexDirection={"row"} gap={4} alignItems={"center"}>
        <Text value={value} />
        <TextTooltip value={t(AppStrings.Notifications.Settings.DisabledByAdmin)} pathColor={"blue"} placement={"top"}>
          <Icon Source={NotificationSettingsInformation} size={18} pathColor={"gray"} marginTop={2} />
        </TextTooltip>
      </View>
    );
  }

  return <Text value={value} />;
};

export const NotificationSettingsView = ({
  headerRows,
  userType,
  values,
  allowedSettings
}: NotificationSettingsViewProps) => {
  const { t } = useTranslation();
  const rowsData = useMemo(
    () =>
      allowedSettings.map((prefName) => {
        const isPrefDisabled = values[prefName] === undefined;
        const settings =
          userType === LoginResponseType.TENANT
            ? tenantNotificationFieldNameToSettingOptions[prefName]
            : userNotificationFieldNameToSettingOptions[prefName];

        const methodsValues: NotificationSettingsRowInterface[] = [
          {
            Component: () => (
              <RowIndexTitle
                value={AppStrings.Notifications.Settings[upperFirst(prefName)]}
                isDisabled={isPrefDisabled}
              />
            ),
            sm: userType === LoginResponseType.USER ? ColumnGridSizeEnum.COLUMN_KEY_XL : ColumnGridSizeEnum.COLUMN_KEY
          },
          {
            Component: () => (
              <FastFieldSafe
                component={FormikCheckBox}
                name={`${prefName}.text`}
                disabled={isPrefDisabled || settings?.text?.disabled}
              />
            ),
            sm: ColumnGridSizeEnum.COLUMN_VALUE,
            type: "text"
          },
          {
            Component: () => (
              <FastFieldSafe
                component={FormikCheckBox}
                name={`${prefName}.web`}
                disabled={isPrefDisabled || settings?.push?.disabled}
              />
            ),
            sm: ColumnGridSizeEnum.COLUMN_VALUE,
            type: "push"
          },
          {
            Component: () => (
              <FastFieldSafe
                component={FormikCheckBox}
                name={`${prefName}.email`}
                disabled={isPrefDisabled || settings?.email?.disabled}
              />
            ),
            sm: ColumnGridSizeEnum.COLUMN_VALUE,
            type: "email"
          }
        ];

        return userType === LoginResponseType.USER
          ? methodsValues.filter((item) => item.type !== "text")
          : methodsValues;
      }),
    [userType, values, allowedSettings]
  );

  return (
    <View
      flex={1}
      borderRadius={9}
      paddingRight={20}
      paddingLeft={20}
      paddingTop={20}
      paddingBottom={20}
      flexDirection={"row"}
      gap={19}
    >
      {userType === LoginResponseType.USER && (
        <WarningView
          type={"info"}
          iconSize={20}
          containerWidth={700}
          minHeight={30}
          justifyContent={"center"}
          separationLineHeight={30}
          separationLineColor={ColorsEnum.InfoDark}
          borderRadius={0}
        >
          <View width={"100%"} flexDirection={"row"} alignItems={"center"}>
            <Typography style={{ lineBreak: "auto", fontSize: "14px", color: " #0F4697", flexDirection: "row" }}>
              {t(AppStrings.Notifications.AdditionalInformation)}
            </Typography>
            <ActionIconLink
              linkText={AppStrings.Notifications.LearnMore}
              marginTop={0}
              marginLeft={2}
              onClick={() =>
                window.open(
                  "https://support.doorloop.com/en/articles/6076042-set-property-rent-payment-notifications",
                  "_blank"
                )
              }
            />
          </View>
        </WarningView>
      )}
      <NotificationSettingsTableRow row={headerRows} />

      {rowsData?.map((row, index) => <NotificationSettingsTableRow key={index} row={row} />)}
    </View>
  );
};
