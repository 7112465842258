import React from "react";
import type { AutocompleteClassKey } from "@material-ui/lab/Autocomplete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { View } from "DLUI/view";
import { useTranslation } from "react-i18next";
import type { ClassNameMap } from "@material-ui/styles";
import TextInput from "DLUI/form/textField/textInput";
import type { FilterOptionsState } from "@material-ui/lab/useAutocomplete/useAutocomplete";

export type OptionType<T = {}> = T & {
  displayName: string;
  value?: any;
};

const rootClassName = "AutoComplete";

interface ComponentProps {
  values: any[];
  defaultValue?: OptionType;
  uniqueIndex?: string;
  label: string;
  valueField?: string;
  didSelectAutoComplete?: (selectedValue: any) => void;
  renderInput?: (params: any) => JSX.Element;
  renderOption?: (option: OptionType<any>, state) => JSX.Element;
  freeSolo?: boolean;
  marginTop?: number;
  paddingRight?: number;
  paddingLeft?: number;
  clearOnBlur?: boolean;
  classes?: Partial<ClassNameMap<AutocompleteClassKey>>;
  noOptionsText?: string;
  selectedValue?: string;
  disabled?: boolean;
  loadingText?: string;
  loading?: boolean;
  filterOptions?: (options: any[], state: FilterOptionsState<any>) => typeof options;
  disableCloseOnSelect?: boolean;
}

const DLUI_AutoComplete: React.FC<ComponentProps> = ({
  values,
  uniqueIndex,
  label,
  valueField,
  didSelectAutoComplete,
  renderOption,
  renderInput,
  freeSolo,
  paddingRight,
  paddingLeft,
  marginTop,
  clearOnBlur = true,
  classes,
  noOptionsText,
  selectedValue,
  disabled,
  defaultValue,
  loading,
  loadingText,
  filterOptions,
  disableCloseOnSelect
}: ComponentProps) => {
  const { t } = useTranslation();
  const _didSelectAutoComplete = (event: object, value: any) => {
    if (didSelectAutoComplete) {
      didSelectAutoComplete(value);
    }
  };

  const setPaddingPx = (paddingDirection?: number) => paddingDirection ?? 20;

  return (
    <View marginTop={marginTop ?? 20} paddingRight={setPaddingPx(paddingRight)} paddingLeft={setPaddingPx(paddingLeft)}>
      <Autocomplete
        classes={{
          ...classes,
          root: rootClassName,
          popper: rootClassName
        }}
        id={"filter-combo-box" + uniqueIndex}
        options={values}
        getOptionLabel={(option) => t(option.displayName)}
        renderOption={renderOption}
        inputValue={selectedValue}
        defaultValue={defaultValue}
        getOptionSelected={(option, selectedItem) => {
          if (valueField) {
            return option[valueField] === selectedItem[valueField];
          }
          return option.value === selectedItem.value;
        }}
        onChange={_didSelectAutoComplete}
        renderInput={(params) => renderInput?.(params) ?? <TextInput {...params} fullWidth label={t(label)} />}
        clearOnBlur={clearOnBlur}
        freeSolo={freeSolo}
        noOptionsText={noOptionsText}
        disabled={disabled}
        filterOptions={filterOptions}
        disableCloseOnSelect={disableCloseOnSelect}
        loading={loading}
        loadingText={loadingText}
      />
    </View>
  );
};

export default DLUI_AutoComplete;
