import { analyticsService } from "../services/analyticsService";
import { unitsApi } from "api/unitsApi";
import _ from "lodash";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import type { AccountType, SegmentEventTypes } from "@doorloop/dto";
import type { BankConnectErrorStatus } from "screens/transactions/components/bankConnect/bankConnectStatusConfig";
import { useState } from "react";
import { useEffectAsync } from "./useEffectAsync";

interface BankConnectProductDefaultEventData {
  numberOfUnits: number;
  firstInvoiceDate?: Date;
}

interface BankConnectProductEventData {
  connectedInstitutions?: number;
  error?: BankConnectErrorStatus;
  accountType?: AccountType;
}

interface BankConnectAnalyticsOptions {
  trackEventInIntercom?: boolean;
}

const getNumberOfUnits = async () => _.size(_.keys(await unitsApi.getDictionary()));

export const useBankConnectEvents = () => {
  const userData = useSelector((state: IRootState) => state.auth.currentLoginResponse);
  const [defaultProperties, setDefaultProperties] = useState<BankConnectProductDefaultEventData>();

  const generateDefaultProperties = async (): Promise<BankConnectProductDefaultEventData> => {
    return {
      numberOfUnits: await getNumberOfUnits(),
      firstInvoiceDate: userData?.currentDbTenant?.subscriptionPlan?.firstInvoiceDate
    };
  };

  useEffectAsync(async () => {
    setDefaultProperties(await generateDefaultProperties());
  }, []);

  const fireBankConnectEvent = (
    name: SegmentEventTypes,
    properties?: BankConnectProductEventData,
    options?: BankConnectAnalyticsOptions
  ) => {
    const trackEventInIntercom = options?.trackEventInIntercom ?? false;
    analyticsService.track(name, { ...defaultProperties, ...properties }, { trackEventInIntercom });
  };

  return { fireBankConnectEvent };
};
