"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OnBoardingUnitsDto = void 0;
const onBoardingStepDto_1 = require("../onBoarding/onBoardingStepDto");
class OnBoardingUnitsDto extends onBoardingStepDto_1.OnBoardingStepDto {
    constructor(init) {
        super(init);
        Object.assign(this, init);
    }
}
exports.OnBoardingUnitsDto = OnBoardingUnitsDto;
