import { Icon } from "DLUI/icon";
import { FormikAsyncAutoComplete } from "DLUI/form";
import { accountsApi } from "api/accounts";
import AppStrings from "../../../../../../locale/keys";
import React from "react";
import { DeleteIcon, DraggableIcon } from "../../../../../../assets";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import type {
  AccountDto,
  GetAllAccountsQuery,
  PaymentAllocationAccountDraggableItemDto,
  PaymentAllocationsSettingsDisplayDto
} from "@doorloop/dto";
import { AccountClass } from "@doorloop/dto";
import { View } from "DLUI/view";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";

const PaymentAccountInputItem: React.FC<PaymentAllocationAccountDraggableItemDto> = ({ itemIndex, account }) => {
  const { t } = useTranslation();
  const formikRef = useFormikContext<PaymentAllocationsSettingsDisplayDto>();

  const didPressDeleteIcon = () => {
    const accountsList = [...(formikRef.values?.accounts || [])];
    if (itemIndex !== undefined && accountsList?.[itemIndex]) {
      accountsList.splice(itemIndex, 1);
      formikRef.setFieldValue("accounts", accountsList);

      formikRef.setFieldTouched(`accounts[${itemIndex}]`, false, false);
    }
  };

  return (
    <View width={"100%"} flexDirection={"row"} noWrap paddingLeft={5} paddingRight={5} paddingTop={5} paddingBottom={5}>
      <Icon Source={DraggableIcon} marginRight={11} width={14} height={12} marginTop={20} />
      <FastFieldSafe<typeof FormikAsyncAutoComplete<AccountDto, GetAllAccountsQuery>>
        component={FormikAsyncAutoComplete}
        name={`accounts[${itemIndex}].account`}
        uniqueIndex={`account-${itemIndex}`}
        apiHandler={accountsApi}
        displayNameKey={"name"}
        filterFieldName={"filter_text"}
        filterFieldValue={"name"}
        selectionFields={["id", "class"]}
        label={t(AppStrings.Settings.GeneralSettings.DefaultAccounts.SelectCategory)}
        queryParams={{
          filter_classes: [AccountClass.LIABILITY, AccountClass.REVENUE, AccountClass.EXPENSE]
        }}
        defaultValue={account}
      />
      <View onClick={didPressDeleteIcon} autoWidth>
        <Icon Source={DeleteIcon} marginLeft={11} width={18} height={20} marginTop={17} />
      </View>
    </View>
  );
};

export default PaymentAccountInputItem;
