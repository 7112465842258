import type { ReactNode } from "react";
import React, { useMemo } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ViewOnlyInput } from "DLUI/form";
import type { FieldProps } from "formik";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import "../styles.css";
import { useReadOnlyContext } from "contexts/readOnlyContext";
import { View } from "DLUI/view";
import type { RestApiBaseWithDictionary } from "api/restApiBaseWithDictionary";
import type { AccountDto, BaseDto, DefaultAccountType, GetAllBaseQueryRequest } from "@doorloop/dto";
import type { ComponentProps as TextFieldComponentProps } from "DLUI/form/textField/types";
import useAutoComplete from "DLUI/form/autoComplete/formikAsyncAutoComplete/useAutoComplete";
import {
  handleFilterOptions,
  handleRenderInput,
  handleRenderOption
} from "DLUI/form/autoComplete/formikAsyncAutoComplete/autoCompleteHandlers";

import "./formikAsyncAutoComplete.css";

interface BaseComponentProps<TDto extends BaseDto, TQuery extends GetAllBaseQueryRequest> {
  classes?: Record<string, string>;
  label: string;
  uniqueIndex: number | string;
  apiHandler: RestApiBaseWithDictionary<TDto, TQuery>;
  displayNameKey: string;
  filterFieldName: string;
  filterFieldValue: string;
  onChange?: (event: object, value: any) => void;
  queryParams?: TQuery;
  selectionFields?: string[];
  multiple?: boolean;
  size?: number | string;
  marginTop?: number;
  onInputChange?: (inputValue: string) => {};
  defaultValue?: string;
  selectionEnum?: Record<string, string>;
  translationKey?: string;
  selectedValue?: string;
  disabled?: boolean;
  viewOnly?: boolean;
  fullWidthViewOnlyView?: boolean;
  triggerOnChangeOnInitialLoad?: boolean;
  onChangeDelay?: number;
  CreateNewOptionComponent?: ReactNode;
  createNewOptionComponentProps?: any;
  createNewOptionTitle?: string;
  groupNameProp?: string;
  pageSize?: number;
  createNewOptionType?: string;
  avoidDefaultsAccountsTypes?: DefaultAccountType[];
  /**
   * If provided, the options will be grouped under the returned string.
   * The groupBy value is also used as the text for group headings when `renderGroup` is not provided.
   *
   * @param {any} options The option to group.
   * @returns {string}
   */
  groupByCustomFunction?: (option: any) => string;

  /**
   * If custom is provided, groupByCustomFunction will be required as well
   */
  groupBy?: "accountClass" | "custom";
  dataCy?: string;
}

export interface FormikAsyncAutoCompleteComponentProps<TDto extends BaseDto, TQuery extends GetAllBaseQueryRequest>
  extends BaseComponentProps<TDto, TQuery>,
    FieldProps,
    Omit<TextFieldComponentProps, keyof BaseComponentProps<TDto, TQuery>> {
  shouldHideClearIcon?: boolean;
}

const FormikAsyncAutoComplete = <TDto extends BaseDto, TQuery extends GetAllBaseQueryRequest>({
  classes,
  label,
  uniqueIndex,
  apiHandler,
  displayNameKey,
  filterFieldName,
  onChange,
  filterFieldValue,
  queryParams,
  selectionFields,
  field,
  form,
  size,
  onInputChange,
  defaultValue,
  selectedValue,
  disabled,
  groupBy,
  groupByCustomFunction,
  viewOnly,
  fullWidthViewOnlyView,
  triggerOnChangeOnInitialLoad = true,
  onChangeDelay,
  CreateNewOptionComponent,
  createNewOptionComponentProps,
  createNewOptionTitle,
  groupNameProp,
  marginTop,
  pageSize = 10,
  avoidDefaultsAccountsTypes,
  createNewOptionType,
  shouldHideClearIcon = false,
  dataCy,
  selectionEnum,
  translationKey,
  ...restProps
}: FormikAsyncAutoCompleteComponentProps<TDto, TQuery>) => {
  const { t } = useTranslation();
  const componentId = "AsyncAutoComplete" + uniqueIndex;

  const [
    { isLoading, options, showCreateOption, isOpen, singleSelectedIndex, createError, valueFilter, selectedMode },
    { handleScroll, onCreateError, onCreate, setShowCreateOption, _onChange },
    setState
  ] = useAutoComplete({
    translationKey,
    selectionEnum,
    defaultValue,
    apiHandler,
    queryParams,
    selectionFields,
    displayNameKey,
    filterFieldValue,
    filterFieldName,
    avoidDefaultsAccountsTypes,
    componentId,
    t,
    onChangeCallback: onChange,
    onChangeCallbackDelay: onChangeDelay,
    triggerOnChangeOnInitialLoad,
    pageSize,
    createNewOptionTitle,
    groupNameProp,
    field,
    form,
    label,
    marginTop,
    size,
    restProps
  });
  const isReadOnly = useReadOnlyContext();

  const _onInputChange = (event: React.ChangeEvent<{}>, value: string) => {
    if (value !== valueFilter && event?.type === "change") {
      setState({ valueFilter: value });
    }

    if (onInputChange) {
      onInputChange(value);
    }
  };

  const groupByFunction: ((option: any) => string) | undefined = useMemo(() => {
    if (groupBy) {
      switch (groupBy) {
        case "custom": {
          if (groupByCustomFunction) {
            return groupByCustomFunction!;
          }
          break;
        }
        case "accountClass": {
          return (x: AccountDto) => t(AppStrings.Accounts.AccountClass[x.class!]);
        }
      }
    }
    return undefined;
  }, []);

  if (viewOnly || isReadOnly) {
    if (singleSelectedIndex !== undefined && options![singleSelectedIndex] !== undefined) {
      return fullWidthViewOnlyView ? (
        <ViewOnlyInput fullWidth marginTop={marginTop} value={options![singleSelectedIndex].name} label={label || ""} />
      ) : (
        <ViewOnlyInput value={options![singleSelectedIndex].name} label={label || ""} backgroundColor={"transparent"} />
      );
    }
    return (
      <View height={45}>
        <CircularProgress color="inherit" size={20} />
      </View>
    );
  }

  return (
    <Autocomplete
      className={disabled ? "disabled" : ""}
      groupBy={groupByFunction}
      disabled={disabled || false}
      id={componentId}
      classes={{
        root: "FormikAsyncAutoComplete AutoComplete",
        popper: "FormikAsyncAutoComplete AutoComplete"
      }}
      open={isOpen}
      onOpen={() => {
        setState({ isOpen: true });
      }}
      onClose={() => {
        if (showCreateOption) return;

        setState({ isOpen: false });
      }}
      clearOnBlur={!showCreateOption}
      onChange={_onChange}
      value={singleSelectedIndex !== undefined && options![singleSelectedIndex] ? options![singleSelectedIndex] : null}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name ?? ""}
      options={options!}
      data-cy={dataCy || field.name}
      loading={isLoading}
      disableClearable={shouldHideClearIcon}
      ListboxProps={{
        onScroll: handleScroll
      }}
      onInputChange={_onInputChange}
      renderOption={(props) =>
        handleRenderOption({ ...props, ...createNewOptionComponentProps }, queryParams, valueFilter)
      }
      filterOptions={(options, params) =>
        handleFilterOptions(options, params, {
          CreateNewOptionComponent,
          createNewOptionComponentProps,
          isLoading,
          groupNameProp,
          createNewOptionTitle,
          createError,
          onCreateError,
          queryParams,
          createNewOptionType,
          onCreate,
          valueFilter,
          setShowCreateOption,
          selectedMode,
          t
        })
      }
      renderInput={(params) =>
        handleRenderInput(params, { marginTop, field, form, label, size, isLoading, valueFilter, restProps, setState })
      }
      fullWidth
    />
  );
};

export default FormikAsyncAutoComplete;
