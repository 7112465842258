import type { FC } from "react";
import React from "react";
import { View } from "DLUI/view";
import type { FileDto } from "@doorloop/dto";
import FileMinimalListItem from "shared/files/fileMinimalListItem";
import type { FilesApi } from "api/filesApi";

interface Props {
  files: FileDto[];
  filesApiToUse?: FilesApi;
}

export const ReadOnlyFileList: FC<Props> = ({ files, filesApiToUse }: Props) => (
  <View flexDirection={"row"}>
    {files.map((file, index) => (
      <FileMinimalListItem key={index} itemData={file} filesApiToUse={filesApiToUse} />
    ))}
  </View>
);
