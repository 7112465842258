import { Feature } from "../config/featureFlags/featureFlags";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import type { ProjectFeatureIds } from "../config/featureFlags/features";
import { useGetUserLocale } from "hooks/useGetUserLocale";
import { getIsFeatureActiveForCountry } from "config/featureFlags/featureFlagByCountry";
import type { UserLocale } from "hooks/useRentalApplicationLocale";

export const useFeatureFlag = (featureId?: ProjectFeatureIds) => {
  const dbTenantId = useSelector((state: IRootState) => state.auth.currentLoginResponse?.currentDbTenant?.id);
  const userLocale: UserLocale = useGetUserLocale();
  const isFeatureFlagActive = useMemo(() => featureId && Feature.isActive(featureId), [featureId]);
  const isFeatureFlagActiveForDbTenant = useMemo(
    () =>
      Boolean(
        dbTenantId &&
          featureId &&
          Feature.isEnabledForDbTenant(featureId, dbTenantId) &&
          getIsFeatureActiveForCountry(featureId, userLocale)
      ),
    [featureId, dbTenantId]
  );

  const getIsFeatureActiveForDbTenant = (featureFlagId: ProjectFeatureIds) => {
    if (!dbTenantId) {
      return false;
    }

    return (
      Feature.isEnabledForDbTenant(featureFlagId, dbTenantId) && getIsFeatureActiveForCountry(featureFlagId, userLocale)
    );
  };

  return {
    isFeatureFlagActive: isFeatureFlagActive && isFeatureFlagActiveForDbTenant,
    getIsFeatureActiveForDbTenant
  };
};
