import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useReadOnlyContext } from "contexts/readOnlyContext";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { ColorContainer } from "screens/settings/tenantPortal/components/colorContainer";
import clsx from "clsx";

interface ColorPickerProps {
  containerWidth?: string | number;
  selectedColor?: string;
  onSelectionChange: (selectedColor: string) => void;
  defaultValue?: string;
}

const ThemeColors = [
  "#2F3E83",
  "#182C4C",
  "#333333",
  "#7A8699",
  "#EB5757",
  "#F2C94C",
  "#219653",
  "#2F80ED",
  "#9B51E0",
  "#F2994A"
];

export const getRgbaColor = (hexColor: string, opacity?: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
  if (result !== null) {
    return `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)}${
      opacity ? `,${opacity}` : ""
    })`;
  }
};

const useStyles = makeStyles(() => {
  return {
    gridContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(5, 1fr)"
    },
    mobile: {
      rowGap: 32,
      columnGap: 24,
      padding: 20
    },
    desktop: {
      rowGap: 16,
      columnGap: 8,
      padding: 12
    }
  };
});

export const ColorPicker = ({ onSelectionChange, selectedColor, defaultValue }: ColorPickerProps) => {
  const isReadOnly = useReadOnlyContext();
  const { isMobile } = useResponsiveHelper();
  const classes = useStyles();

  const onColorItemPress = (index: number) => {
    onSelectionChange(ThemeColors[index]);
  };

  const isSelected = (currentColor: string, index: number) =>
    (selectedColor === undefined && defaultValue === undefined && index === 0) || currentColor === selectedColor;

  return (
    <div
      className={clsx(classes.gridContainer, {
        [classes.mobile]: isMobile,
        [classes.desktop]: !isMobile
      })}
    >
      {ThemeColors.map((currentColor: string, index: number) => (
        <ColorContainer
          key={"CPI" + currentColor}
          selected={isSelected(currentColor, index)}
          color={currentColor}
          onClick={isReadOnly ? undefined : () => onColorItemPress(index)}
        />
      ))}
    </div>
  );
};
