import { View } from "DLUI/view";
import { FastField } from "formik";
import React from "react";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { TextField } from "DLUI/form";
import { useTranslation } from "react-i18next";

const GeneralInstructions: React.FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <>
      <View>
        <Text
          value={AppStrings.Settings.GeneralSettings.RentalApplications.GeneralInstructionsSectionDescription}
          lineHeight={"26px"}
        />
        <Text value={AppStrings.Tasks.WorkOrdersScreen.Instructions} fontSize={20} fontWeight={700} marginTop={20} />
        <FastField
          component={TextField}
          label={t(AppStrings.Tasks.WorkOrdersScreen.Instructions)}
          name={`general.instructions`}
          multiline
          multiLineSize={"large"}
          rows="4"
          marginTop={20}
          maxLength={5000}
        />
        <Text
          value={AppStrings.Settings.GeneralSettings.RentalApplications.GeneralInstructionsExample}
          color={"gray"}
          marginTop={20}
          lineHeight={"26px"}
          maxWidth={450}
        />
      </View>
    </>
  );
};

export default GeneralInstructions;
