"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToDoEnum = void 0;
var ToDoEnum;
(function (ToDoEnum) {
    ToDoEnum[ToDoEnum["tasksUnassigned"] = 0] = "tasksUnassigned";
    ToDoEnum[ToDoEnum["tasksOverdue"] = 1] = "tasksOverdue";
    ToDoEnum[ToDoEnum["leaseDrafts"] = 2] = "leaseDrafts";
    ToDoEnum[ToDoEnum["leasesStarting"] = 3] = "leasesStarting";
    ToDoEnum[ToDoEnum["leasesExpiring"] = 4] = "leasesExpiring";
    ToDoEnum[ToDoEnum["prospectsUnassigned"] = 5] = "prospectsUnassigned";
    ToDoEnum[ToDoEnum["rentalApplicationsPending"] = 6] = "rentalApplicationsPending";
    ToDoEnum[ToDoEnum["leasesPastDue"] = 7] = "leasesPastDue";
    ToDoEnum[ToDoEnum["billsPastDue"] = 8] = "billsPastDue";
    ToDoEnum[ToDoEnum["bankConnectPendingReview"] = 9] = "bankConnectPendingReview";
})(ToDoEnum || (exports.ToDoEnum = ToDoEnum = {}));
