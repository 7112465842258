"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOwnerStatementReportQuery = void 0;
const getCashFlowStatementReport_query_1 = require("../cashFlowStatement/getCashFlowStatementReport.query");
const class_validator_1 = require("class-validator");
const runReportsBy_enum_1 = require("./runReportsBy.enum");
const reportType_enum_1 = require("./reportType.enum");
class GetOwnerStatementReportQuery extends getCashFlowStatementReport_query_1.GetCashFlowStatementReportQuery {
}
exports.GetOwnerStatementReportQuery = GetOwnerStatementReportQuery;
__decorate([
    (0, class_validator_1.IsNumber)({ maxDecimalPlaces: 0 }),
    __metadata("design:type", Number)
], GetOwnerStatementReportQuery.prototype, "filter_page", void 0);
__decorate([
    (0, class_validator_1.IsNumber)({ maxDecimalPlaces: 0 }),
    __metadata("design:type", Number)
], GetOwnerStatementReportQuery.prototype, "filter_perPage", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(runReportsBy_enum_1.RunReportsByEnum),
    __metadata("design:type", String)
], GetOwnerStatementReportQuery.prototype, "runReportsBy", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(reportType_enum_1.ReportType),
    __metadata("design:type", String)
], GetOwnerStatementReportQuery.prototype, "filter_report_type", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Object)
], GetOwnerStatementReportQuery.prototype, "ownerMessages", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetOwnerStatementReportQuery.prototype, "isRunOnInit", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    __metadata("design:type", Array)
], GetOwnerStatementReportQuery.prototype, "columns", void 0);
