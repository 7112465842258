enum ChipSizesEnum {
  MEDIUM = "medium",
  SMALL = "small"
}

enum ChipColorsEnum {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  NEUTRAL = "neutral",
  DANGER = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success",
  ERROR = "serious_error"
}

enum ChipVariantsEnum {
  DEFAULT = "default",
  OUTLINED = "outlined"
}

export { ChipSizesEnum, ChipColorsEnum, ChipVariantsEnum };
