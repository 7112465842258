import React from "react";
import StepSection from "screens/onBoarding/components/stepSection";
import { Icon } from "DLUI/icon";
import { OnBoardingSubscribeIcon } from "assets/.";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import type { StepPropsWithDto } from "screens/onBoarding/types";
import { View } from "DLUI/view";
import ActionButton from "screens/onBoarding/components/actionButton";
import { NavigationManager } from "utils/navigation";
import { Grid } from "@material-ui/core";
import { VideoUrlsEnum } from "DLUI/screen/helpPanel/types";
import { analyticsService } from "../../../../services/analyticsService";
import { SegmentEventTypes } from "@doorloop/dto";

type Props = StepPropsWithDto;

const SubscribeToDoorLoopStep: React.FC<Props> = ({
  isCollapsed,
  onHeaderClick,
  name,
  onBoardingDto,
  isCompleted,
  isSkipped
}) => {
  const icon = OnBoardingSubscribeIcon;
  const title = AppStrings.OnBoarding.StepTitles.SubscribeToDoorLoop;
  const description = AppStrings.OnBoarding.StepDescriptions.SubscribeToDoorLoop;

  const handleSubscribeClick = async () => {
    if (isCompleted) {
      // user is already subscribed.
      // redirect them to view subscriptions page.
      NavigationManager.viewSubscriptionDetails();
      return;
    }

    analyticsService.track(SegmentEventTypes.ONBOARDING_SUBSCRIBENOW_CLICKED);

    const redirectUrl = window.location.pathname;
    NavigationManager.newSubscriptionPage(undefined, redirectUrl);
  };

  const LeftSideContent = () => (
    <View gap={15}>
      <Text whiteSpace={"pre-line"} value={description} />
      <Grid container direction={"row"} alignItems={"center"} spacing={2}>
        <Grid item xs={12} sm={"auto"} md={"auto"}>
          <ActionButton
            onClick={handleSubscribeClick}
            actionText={
              isCompleted
                ? AppStrings.OnBoarding.ActionButtons.ViewSubscription
                : AppStrings.OnBoarding.ActionButtons.SubscribeToDoorLoop
            }
            fullWidth
            width={"100%"}
          />
        </Grid>
      </Grid>
    </View>
  );

  return (
    <StepSection
      icon={<Icon size={24} Source={icon} />}
      title={title}
      leftSideContent={<LeftSideContent />}
      isCompleted={isCompleted}
      isSkipped={isSkipped}
      onHeaderClick={onHeaderClick}
      isCollapsed={isCollapsed}
      reactPlayerVideoUrl={VideoUrlsEnum.OB_SUBSCRIBE_TO_DOORLOOP}
    />
  );
};

export default SubscribeToDoorLoopStep;
