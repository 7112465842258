import React, { useMemo } from "react";
import type { SVGIconComponent } from "assets/icons";
import { BigYellowWarningIcon, CheckmarkCircleIcon, ExclamationMarkIconPink, InformationIIcon } from "assets/icons";
import { Icon } from "DLUI/icon";
import { Link } from "DLUI/link";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import { useLocation } from "react-router-dom";
import DialogFrame from "../components/dialogFrame";
import type { AlertDialogProps } from "utils/navigation";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import AppStrings from "../../../../locale/keys";
import { useTranslation } from "react-i18next";
import { history } from "store/history";

export interface ExclamationAlertProps extends React.PropsWithChildren<unknown> {
  title: string;
  subTitle: string;
  textMaxWidth?: string | number;
  dismissButtonText?: string;
  confirmButtonText?: string;
  onDismissButton: () => void;
  onConfirmButton?: () => void;
  icon?: SVGIconComponent;
}

export const ExclamationAlert = ({
  title,
  subTitle,
  textMaxWidth,
  dismissButtonText,
  confirmButtonText,
  onDismissButton = () => {},
  onConfirmButton = () => {},
  children,
  icon = ExclamationMarkIconPink
}: ExclamationAlertProps): JSX.Element => {
  const { t } = useTranslation();

  const dismissButtonTextUpdated = useMemo(() => {
    if (dismissButtonText) {
      return dismissButtonText;
    }

    return t(AppStrings.Common.Dismiss);
  }, [dismissButtonText]);

  return (
    <View paddingLeft={20} paddingRight={20} height={"100%"} alignItems={"center"}>
      <Icon marginTop={50} width={80} height={80} Source={icon} />
      <Text
        color={"black"}
        fontSize={24}
        fontWeight={700}
        value={title}
        marginTop={30}
        align={"center"}
        maxWidth={textMaxWidth}
      />
      <Text
        color={"gray"}
        fontSize={24}
        value={subTitle}
        marginTop={40}
        lineHeight={"28px"}
        align={"center"}
        maxWidth={textMaxWidth}
        whiteSpace={"pre-line"}
      />
      {children}
      <View
        flex={1}
        justifyContent={"center"}
        alignItems={"flex-end"}
        flexDirection={"row"}
        marginBottom={50}
        width={"100%"}
        gap={20}
      >
        <DLButton
          onClick={onDismissButton}
          actionText={dismissButtonTextUpdated}
          variant={DLButtonVariantsEnum.OUTLINED}
          color={DLButtonColorsEnum.NEUTRAL}
          size={DLButtonSizesEnum.LARGE}
        />
        {confirmButtonText && (
          <DLButton onClick={onConfirmButton} actionText={confirmButtonText} size={DLButtonSizesEnum.LARGE} />
        )}
      </View>
    </View>
  );
};

export interface AlertDialogComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
}

const AlertDialog = ({ onBackdropClick, onClose }: AlertDialogComponentProps): JSX.Element => {
  const location = useLocation<any>();
  const { dialogTitle, dialogSubTitle, textMaxWidth, dismissButtonText, contactSupportText, backToRoute, severity } =
    useMemo((): AlertDialogProps => {
      let dialogTitle;
      let dialogSubTitle;
      let dismissButtonText;
      let textMaxWidth;
      let contactSupportText;
      let backToRoute;
      let severity;
      if (location.state && location.state.alertData) {
        dialogTitle = location.state.alertData.dialogTitle;
        dialogSubTitle = location.state.alertData.dialogSubTitle;
        textMaxWidth = location.state.alertData.textMaxWidth;
        dismissButtonText = location.state.alertData.dismissButtonText;
        contactSupportText = location.state.alertData.contactSupportText;
        backToRoute = location.state.alertData.backToRoute;
        severity = location.state.alertData.severity;
      } else {
        // TODO add default values !
      }
      return {
        dialogTitle,
        dialogSubTitle,
        dismissButtonText,
        textMaxWidth,
        contactSupportText,
        backToRoute,
        severity
      };
    }, []);

  const icon = useMemo(() => {
    switch (severity) {
      case "error":
        return ExclamationMarkIconPink;
      case "warning":
        return BigYellowWarningIcon;
      case "info":
        return InformationIIcon;
      case "success":
        return CheckmarkCircleIcon;
      default:
        return ExclamationMarkIconPink;
    }
  }, [severity]);

  const _onBackdropClick = () => {
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const didPressDismissButton = () => {
    if (backToRoute) {
      history.replace(backToRoute);
    } else {
      _onBackdropClick();
    }
  };

  const didPressContactUs = () => {
    if (window.Intercom) {
      window.Intercom("showNewMessage");
    }
    didPressDismissButton();
  };

  const renderContactSupportText = () => {
    if (contactSupportText) {
      return (
        <View alignItems={"center"} marginTop={20}>
          <Link hoverColor={"lightBlue"} onClick={didPressContactUs}>
            <Text color={"lightBlue"} fontSize={20} value={contactSupportText} />
          </Link>
        </View>
      );
    }
  };

  const renderView = ({ index }: { index: number }) => {
    if (index === 0) {
      return (
        <ExclamationAlert
          title={dialogTitle}
          subTitle={dialogSubTitle}
          onDismissButton={didPressDismissButton}
          textMaxWidth={textMaxWidth}
          dismissButtonText={dismissButtonText}
          icon={icon}
        >
          {renderContactSupportText()}
        </ExclamationAlert>
      );
    }

    return <div />;
  };

  const renderActionPanelButtons = () => <div />;

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      width={700}
      height={550}
      renderView={renderView}
      numViews={1}
      activeView={0}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={"contentOnly"}
    />
  );
};

export default AlertDialog;
