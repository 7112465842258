"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VendorTransactionReportLine = void 0;
class VendorTransactionReportLine {
    constructor(account, amount, property, unit, memo) {
        this.account = account;
        this.property = property;
        this.unit = unit;
        this.amount = amount;
        this.memo = memo;
    }
}
exports.VendorTransactionReportLine = VendorTransactionReportLine;
