import type { FC } from "react";
import React, { useCallback, useMemo } from "react";
import { Grid, ListItemContainer, ListItemIcon, ListItemSection } from "DLUI/listItems";
import AppStrings from "locale/keys";
import { View } from "DLUI/view";
import type { SVGIconComponent } from "assets/icons";
import { BankIcon, CalendarIcon, CloseIcon, EditBlue, EmailAtSignIcon, MailIcon, PaymentIcon } from "assets/icons";
import ListItemText from "DLUI/listItems/listItemText";
import type { PopoverItem } from "DLUI/popover";
import type { OutgoingPaymentsReportItemDto } from "@doorloop/dto";
import { CheckbookPaymentMethodEnum } from "@doorloop/dto";
import { getNameFromDictionaryByType, getPayToResourceTypeLabel } from "DLUI/lists/utils";
import { Icon } from "DLUI/icon";
import { DefaultListItemFontSize, DefaultListItemIconSize } from "screens/leases/leases/leasesList/leaseListItem";
import { OutgoingPaymentStatus } from "screens/outgoingPayments/outgoingPaymentStatus";
import { OutgoingPaymentAction } from "screens/outgoingPayments/actions/outgoingPaymentAction";
import { accountsApi } from "api/accounts";
import { JournalEntryType } from "@doorloop/dto";
import { NavigationManager } from "utils/navigation";
import { ObjectPermission } from "@doorloop/dto";
import { useTranslation } from "react-i18next";
import { usePermission } from "screens/settings/userRoles/usePermission";
import ColorsEnum from "utils/colorsEnum";
import { TextTooltip } from "DLUI/popover/textTooltip";
import { useConfirmationDialog } from "hooks/useConfirmationDialog";

interface Props {
  dto: OutgoingPaymentsReportItemDto;
}

interface MethodSettings {
  MethodIcon: SVGIconComponent;
  label: string;
  color?: ColorsEnum;
}

const typeToNavigation: Partial<Record<JournalEntryType, (id: string) => void>> = {
  [JournalEntryType.EXPENSE]: (id) => {
    NavigationManager.editExpense(location.pathname, id);
  },
  [JournalEntryType.LEASE_REFUND]: (id) => {
    NavigationManager.editRefund(location.pathname, id);
  },
  [JournalEntryType.OWNER_DISTRIBUTION]: (id) => {
    NavigationManager.editExpense(location.pathname, id);
  },
  [JournalEntryType.VENDOR_BILL_PAYMENT]: (id) => {
    NavigationManager.editVendorBillPayment(location.pathname, id);
  }
};

export const methodSettingsMap: Record<CheckbookPaymentMethodEnum | "default", MethodSettings> = {
  [CheckbookPaymentMethodEnum.DIGITAL_PAYMENT]: {
    MethodIcon: EmailAtSignIcon,
    label: AppStrings.Announcements.viaEmail
  },
  [CheckbookPaymentMethodEnum.PHYSICAL_CHECK]: {
    MethodIcon: MailIcon,
    label: AppStrings.Common.OutgoingPayments.ViaPhysicalCheck
  },
  [CheckbookPaymentMethodEnum.DIRECT_DEPOSIT]: {
    MethodIcon: PaymentIcon,
    label: AppStrings.Common.OutgoingPayments.ViaDirectDeposit
  },
  default: {
    MethodIcon: PaymentIcon,
    label: AppStrings.Common.OutgoingPayments.MissingPaymentInfo,
    color: ColorsEnum.Red
  }
};

export const OutgoingPaymentsListItem: FC<Props> = ({ dto }: Props) => {
  const { t } = useTranslation();
  const { MethodIcon, label, color } = methodSettingsMap[dto.personOutgoingEPayInfoMethod || "default"];
  const { showConfirmationDialog } = useConfirmationDialog();

  const { payeeName, typeLabel } = useMemo(() => {
    return {
      payeeName: getNameFromDictionaryByType(dto.payToResourceId, dto.payToResourceType),
      typeLabel: getPayToResourceTypeLabel(dto.payToResourceType)
    };
  }, [dto.payToResourceType, dto.payToResourceId]);

  const rowClickHandler = useCallback(() => {
    const navigator = dto.journalEntryType && typeToNavigation[dto.journalEntryType];
    navigator && dto.journalEntry && navigator(dto.journalEntry);
  }, [dto.journalEntry, dto.journalEntryType]);

  const popoverItems: PopoverItem[] = useMemo(() => {
    const result: PopoverItem[] = [
      {
        Icon: EditBlue,
        title: AppStrings.Common.Edit,
        onClick: rowClickHandler,
        clearance: {
          permission: ObjectPermission.outgoingPayments,
          field: "edit"
        }
      }
    ];
    if (!dto.ePayInformation?.status) {
      result.push({
        Icon: CloseIcon,
        title: AppStrings.Common.Delete,
        iconPathColor: "error",
        textColor: "error",
        onClick: () => {
          if (!dto.journalEntry) {
            return;
          }

          showConfirmationDialog(location.pathname, {
            dialogTitle: AppStrings.Common.DeleteConfirmation,
            dialogSubTitle: AppStrings.Common.DeleteTransactionConfirmationText,
            loadingText: t(AppStrings.Common.DeletingTransaction),
            successText: t(AppStrings.Common.TransactionDeleted),
            confirmButtonText: AppStrings.Common.Delete,
            dismissButtonText: AppStrings.Common.Dismiss,
            apiMethod:
              dto.journalEntryType === JournalEntryType.LEASE_REFUND
                ? "leaseRefundApi"
                : dto.journalEntryType === JournalEntryType.VENDOR_BILL_PAYMENT
                  ? "vendorBillPaymentApi"
                  : "expensesApi",
            confirmOperation: "delete",
            itemId: dto.journalEntry
          });
        },
        clearance: {
          permission: ObjectPermission.outgoingPayments,
          field: "delete"
        }
      });
    }
    return result;
  }, [rowClickHandler, dto.journalEntry]);

  // const personHref = useMemo(() => {
  //   if (dto.payToResourceType === ExpensePayToResourceType.TENANT) {
  //     return `${Routes.TENANTS}/${dto.payToResourceId}/overview`;
  //   } else if (dto.payToResourceType === ExpensePayToResourceType.OWNER) {
  //     return `${Routes.OWNERS}/${dto.payToResourceId}/overview`;
  //   } else if (dto.payToResourceType === ExpensePayToResourceType.VENDOR) {
  //     return `${Routes.VENDORS}/${dto.payToResourceId}/overview`;
  //   }
  // }, [dto.payToResourceId, dto.payToResourceType]);

  const account = useMemo(
    () => (dto.payFromAccount ? accountsApi.getItemFromDictionary(dto.payFromAccount) : {}),
    [dto.payFromAccount]
  );

  const { hasAllPermissions } = usePermission();
  const hasActionsPermission = hasAllPermissions([
    {
      permission: ObjectPermission.outgoingPayments,
      field: "edit"
    }
  ]);

  const statusGridSize = hasActionsPermission ? 2 : 4;

  return (
    <ListItemContainer popoverItems={hasActionsPermission ? popoverItems : undefined} popoverWidth={220}>
      <Grid
        title={AppStrings.Common.OutgoingPayments.Recipient}
        showDivider
        xs={12}
        sm={3}
        md={3}
        lg={3}
        xl={3}
        onClick={rowClickHandler}
      >
        <View alignItems={"center"} flexDirection={"row"} noWrap>
          <ListItemIcon Icon={MethodIcon} />
          <View flex={1}>
            <View flexDirection={"row"} alignItems={"center"} noWrap>
              <ListItemText value={typeLabel} color={"green"} marginRight={20} fontSize={12} bold />
            </View>
            <View flexDirection={"row"} alignItems={"center"}>
              <View flex={1}>
                <ListItemText bold color={"black"}>
                  {payeeName}
                </ListItemText>
              </View>
            </View>
            <View flexDirection={"row"}>
              <View autoWidth>
                <ListItemText color={"gray"} colorEnum={color} value={label} fontSize={14} />
              </View>
            </View>
          </View>
        </View>
      </Grid>
      <Grid
        title={AppStrings.Common.Settings.LateFees.Account}
        showDivider
        xs={12}
        sm={2}
        md={2}
        lg={2}
        xl={2}
        onClick={rowClickHandler}
      >
        <ListItemSection
          renderTitle={
            <View flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"}>
              <Icon
                Source={CalendarIcon}
                width={DefaultListItemIconSize}
                height={DefaultListItemIconSize}
                marginRight={5}
                pathColor={"black"}
                size={15}
              />

              <ListItemText
                color={"black"}
                align="left"
                fontSize={DefaultListItemFontSize}
                value={dto.date}
                formatType={"date"}
              />
            </View>
          }
          renderSubTitle={
            <View flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} noWrap>
              <Icon
                Source={BankIcon}
                width={DefaultListItemIconSize}
                height={DefaultListItemIconSize}
                marginRight={5}
                pathColor={"black"}
                size={15}
              />

              <ListItemText color={"black"} align="left" fontSize={DefaultListItemFontSize}>
                {account?.name}
              </ListItemText>
            </View>
          }
        />
      </Grid>
      <Grid
        title={AppStrings.Leases.NewLease.LeaseRent.Amount}
        showDivider
        xs={12}
        sm={1}
        md={1}
        lg={1}
        xl={1}
        onClick={rowClickHandler}
      >
        <View marginRight={10} alignItems={"flex-end"} flex={1}>
          <ListItemText
            color={"black"}
            fontSize={DefaultListItemFontSize}
            value={`${dto.amount}`}
            formatType={"currency"}
          />
        </View>
      </Grid>
      <Grid
        title={AppStrings.Common.ListHeader.Memo}
        showDivider
        xs={12}
        sm={2}
        md={2}
        lg={2}
        xl={2}
        onClick={dto.memo ? rowClickHandler : undefined}
      >
        <View marginRight={10} alignItems={"flex-start"} flex={1}>
          {Boolean(dto.memo) && (
            <TextTooltip value={dto.memo} useDark>
              <ListItemText
                color={"black"}
                fontSize={DefaultListItemFontSize}
                maxLines={"2"}
                overflow={"ellipsis"}
                value={dto.memo ?? ""}
              />
            </TextTooltip>
          )}
        </View>
      </Grid>

      <Grid
        title={AppStrings.Leases.ListHeader.Status}
        showDivider={hasActionsPermission}
        xs={12}
        sm={statusGridSize}
        md={statusGridSize}
        lg={statusGridSize}
        xl={statusGridSize}
        onClick={rowClickHandler}
      >
        <View height={"100%"} flexDirection={"row"} alignItems={"center"}>
          <View height={"100%"} alignItems={"center"} flexDirection={"row"} flex={1}>
            <OutgoingPaymentStatus status={dto.ePayInformation?.status} />
          </View>
        </View>
      </Grid>
      {hasActionsPermission && (
        <Grid title={AppStrings.Common.ListHeader.Actions} showDivider xs={12} sm={2} md={2} lg={2} xl={2}>
          <View height={"100%"} flexDirection={"row"} alignItems={"center"}>
            <View height={"100%"} alignItems={"center"} flexDirection={"row"} flex={1}>
              <OutgoingPaymentAction
                paymentDto={dto}
                clickOverride={!dto.payToResourceId ? rowClickHandler : undefined}
                showConfirmationDialog={showConfirmationDialog}
              />
            </View>
          </View>
        </Grid>
      )}
    </ListItemContainer>
  );
};
