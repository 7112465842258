import React, { useState } from "react";
import type { LeaseReversedPaymentDto } from "@doorloop/dto";
import { ObjectPermission } from "@doorloop/dto";
import DialogFrame from "components/DLUI/dialogs/components/dialogFrame";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { View } from "DLUI/view";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import ReversePaymentForm from "DLUI/dialogs/reversePayment/reversePaymentForm";
import { useFormikContext } from "formik";
import _ from "lodash";
import { leaseReversedPaymentsApi } from "api/leaseReversedPaymentsApi";
import type { DialogData } from "screens/leases/leases/leaseDetails/transactions/transactionListItem";

interface ComponentProps {
  didFinishOperation: () => void;
  onBackdropClick: () => void;
  dialogData: DialogData;
}

const DialogHeight = 640;
const DialogWidth = 790;
const DialogViews = {
  DialogForm: 0,
  LoadingView: 1
};
const clearance: AnyPermissionClearance = {
  permission: ObjectPermission.leasePayments,
  field: "edit"
};

const ReversePaymentDialog: React.FC<ComponentProps> = ({
  didFinishOperation,
  onBackdropClick,
  dialogData
}: ComponentProps) => {
  const { t } = useTranslation();
  const formikRef = useFormikContext<LeaseReversedPaymentDto>();
  const [reversePaymentErrorText, setReversePaymentErrorText] = useState<string>("");
  const [viewIndex, setViewIndex] = useState(0);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);
  const didPressDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(DialogViews.DialogForm);
  };
  const _onBackdropClick = () => {
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const createReversePayment = async () => {
    setLoadingDialogState(DialogState.Show);
    setViewIndex(DialogViews.LoadingView);
    const { status, message, data } = await leaseReversedPaymentsApi.create(formikRef.values);
    if (status && data) {
      didFinishOperation();
    } else {
      setReversePaymentErrorText(message || t(AppStrings.Common.GeneralError));
      setLoadingDialogState(DialogState.Error);
    }
  };

  const didPressSaveButton = async () => {
    formikRef.setFieldTouched("date");
    const errors = await formikRef.validateForm();
    if (_.isEmpty(errors)) {
      await createReversePayment();
    }
  };

  const renderActionPanelButtons = () => {
    if (viewIndex === DialogViews.LoadingView) {
      return <View />;
    }
    return (
      <RestrictedPermissionAccess clearance={clearance}>
        <FormActionButtons
          propsSubButton={{ onClick: onBackdropClick }}
          propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
        />
      </RestrictedPermissionAccess>
    );
  };
  const renderView = ({ index }: any) => {
    if (index === DialogViews.DialogForm) {
      return <ReversePaymentForm dialogData={dialogData} />;
    }
    if (index === DialogViews.LoadingView) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"} flexDirection={"row"} noWrap>
          <LoadingDialog
            dialogState={loadingDialogState}
            errorText={reversePaymentErrorText}
            didPressDismissButton={didPressDismissButton}
            onRetryButtonPress={createReversePayment}
          />
        </View>
      );
    }
    return <div />;
  };

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={t(AppStrings.Leases.LeaseTransaction.Dialog.TitleText)}
      width={DialogWidth}
      height={DialogHeight}
      renderView={renderView}
      numViews={2}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={viewIndex === DialogViews.DialogForm ? "sectionTitleFrame" : "contentOnly"}
    />
  );
};

export default ReversePaymentDialog;
