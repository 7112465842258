import type { TextColor } from "DLUI/text/text";
import clsx from "clsx";
import { useTypedTranslation } from "locale";
import Text from "DLUI/text";
import { useGeneralStyles } from "styles/generalStyles";
import { formatNumberToFractionWithCommas } from "utils/formatNumberToFractionWithCommas";
import { DataCy } from "@doorloop/dto";

interface SummarySectionProps {
  total: number;
  textColor: TextColor;
}

export const SummarySection: React.FC<SummarySectionProps> = ({ total, textColor }) => {
  const { t } = useTypedTranslation();
  const generalClasses = useGeneralStyles();

  return (
    <div className={clsx(generalClasses.flexRow, generalClasses.spaceBetween)}>
      <div className={clsx(generalClasses.flexColumn, generalClasses.smallGap)}>
        <Text
          value={t("settings.generalSettings.managementFees.sampleCalculator.totalFeesSample")}
          fontSize={16}
          fontWeight={700}
          color={textColor}
        />
        {
          //TODO: Uncomment this when the feature is ready
          /*<div className={clsx(generalClasses.flexRow, generalClasses.verticalAlign)}>
          <Icon Source={LockDotIcon} size={14} pathColor={"light-blue"} />
          <Text
            value={t("settings.generalSettings.managementFees.sampleCalculator.visibleToYou")}
            fontSize={12}
            fontWeight={700}
            colorEnum={ColorsEnum.BrightBlue}
          />
        </div>*/
        }
      </div>
      <Text
        value={`$${formatNumberToFractionWithCommas(total)}`}
        fontSize={16}
        fontWeight={700}
        color={textColor}
        dataCy={DataCy.globalSettings.managementFees.sampleCalculator.total.amount}
      />
    </div>
  );
};
