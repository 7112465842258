import { matchPath } from "react-router";
import type { NavPathItem } from "../types";

export function matchNavPath(path: string, navPath: NavPathItem) {
  return Boolean(
    matchPath(path, {
      exact: navPath.matchMethod === "exact",
      path: navPath.name
    })
  );
}
