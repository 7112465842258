import React from "react";
import { useTranslation } from "react-i18next";

import AppStrings from "locale/keys";

import Text from "DLUI/text";
import type { TextColor, TextFormatType, TextTransformProp, WhiteSpaceProps } from "DLUI/text/text";
import { View } from "DLUI/view";
import type { ViewBackgroundColor } from "DLUI/view/view";

import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

export interface ViewOnlyInputProps {
  value?: string;
  label: string;
  labelColor?: TextColor;
  labelFontSize?: number;
  labelFontWeight?: number;
  formatType?: TextFormatType;
  width?: number | string;
  marginLeft?: number;
  height?: number | string;
  marginTop?: number;
  backgroundColor?: ViewBackgroundColor;
  alignItems?: "flex-end" | "flex-start";
  fullWidth?: boolean;
  removeTopBorderRadius?: boolean;
  textTransform?: TextTransformProp;
  valueFontWeight?: number;
  valueFontSize?: number;
  paddingLeft?: number;
  paddingBottom?: number;
  renderValue?: React.ReactNode;
  lineHeight?: string;
  overFlow?: "ellipsis" | "visible";
  whiteSpace?: WhiteSpaceProps;
  dataCy?: string;
  valueMarginTop?: number;
}

const ViewOnlyInput: React.FC<ViewOnlyInputProps> = ({
  label,
  value,
  formatType,
  height,
  marginLeft,
  marginTop,
  paddingLeft,
  backgroundColor,
  alignItems,
  fullWidth,
  textTransform,
  valueFontWeight,
  labelColor,
  labelFontSize,
  labelFontWeight,
  valueFontSize,
  dataCy,
  renderValue,
  lineHeight,
  paddingBottom = 5,
  overFlow = "ellipsis",
  whiteSpace,
  valueMarginTop
}) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();

  return (
    <View
      height={`calc(${height || (isMobile ? "50px" : "40px")} - 10px)`}
      width={fullWidth ? "100%" : "auto"}
      backgroundColor={backgroundColor || "dark"}
      borderRadius={5}
      justifyContent={"center"}
      alignItems={alignItems}
      marginTop={marginTop}
      marginLeft={marginLeft}
      paddingTop={5}
      paddingLeft={paddingLeft || 10}
      paddingRight={10}
      paddingBottom={paddingBottom}
      noWrap
      dataCy={dataCy}
    >
      {Boolean(label) && (
        <View height={"40%"} justifyContent={"center"}>
          <Text
            className={"viewOnlyInputValue"}
            value={label}
            fontSize={labelFontSize || 12}
            lineHeight={lineHeight ?? (valueFontSize ? "auto" : "17px")}
            fontWeight={labelFontWeight || 700}
            color={labelColor}
            whiteSpace={whiteSpace}
          />
        </View>
      )}
      {renderValue ?? (
        <View height={"60%"} justifyContent={"center"}>
          {formatType ? (
            <Text
              className={"viewOnlyInputValue"}
              fontSize={valueFontSize || 14}
              lineHeight={lineHeight ?? (valueFontSize ? "auto" : "17px")}
              value={value}
              formatType={formatType}
              textTransform={textTransform}
              fontWeight={valueFontWeight || 400}
              whiteSpace={whiteSpace}
            />
          ) : (
            <Text
              className={"viewOnlyInputValue"}
              fontSize={valueFontSize || 14}
              lineHeight={lineHeight ?? (valueFontSize ? "auto" : "17px")}
              textTransform={textTransform}
              fontWeight={valueFontWeight || 400}
              overFlow={overFlow}
              whiteSpace={whiteSpace}
              marginTop={valueMarginTop}
            >
              {value || t(AppStrings.Common.NotSet)}
            </Text>
          )}
        </View>
      )}
    </View>
  );
};

export default ViewOnlyInput;
