import type { TagType } from "@doorloop/dto";
import { createValidator, TagDto } from "@doorloop/dto";
import { tagsApi } from "api/tagsApi";
import { TextField, ValidationIndicator } from "DLUI/form";
import FieldWrapper from "DLUI/form/textField/fieldWrapper";
import View from "DLUI/view/view";
import { FastField, Formik, useFormikContext } from "formik";
import type { MouseEventHandler } from "react";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AppStrings from "../../../../../locale/keys";
import DLButton from "DLUI/button/dlButton";

interface CreateNewTagOptionFormInterface {
  onCreate: (data: any) => void;
  type: TagType;
  inputValue: string;
  createError: string;
  onCreateError: (error: string) => void;
}

const CreateNewTagOptionForm: React.FC<CreateNewTagOptionFormInterface> = ({
  onCreate,
  type,
  inputValue,
  createError,
  onCreateError
}: CreateNewTagOptionFormInterface) => {
  const onSubmit = useCallback(async (_values, { setSubmitting }) => {
    const values = new TagDto(_values);
    const response = await tagsApi.create(values);

    if (response.status && response?.data?.id) {
      onCreate(response.data);
    } else {
      setSubmitting(false);
      onCreateError(response?.message);
    }
  }, []);

  const handleSelect = useCallback((ev) => ev.target.select(), []);

  const initialValues: TagDto = new TagDto({
    type
  });

  const validateForm = createValidator(TagDto);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validateForm}>
      <CreateTagForm
        inputValue={inputValue}
        handleSelect={handleSelect}
        onCreateError={onCreateError}
        createError={createError}
      />
    </Formik>
  );
};

interface CreateTagFormInterface {
  inputValue?: string;
  createError?: string;
  handleSelect: (ev: MouseEventHandler<any>) => void;
  onCreateError: (error: string) => void;
}

const CreateTagForm: React.FC<CreateTagFormInterface> = ({
  inputValue,
  handleSelect,
  onCreateError,
  createError
}: CreateTagFormInterface) => {
  const { t } = useTranslation();
  const { handleSubmit, isValid, isSubmitting, setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue("name", inputValue);

    if (createError !== "") {
      onCreateError("");
    }
  }, [inputValue]);

  return (
    <View alignItems={"center"}>
      <FieldWrapper type={"singleField"} marginTop={10}>
        <FastField
          disabled={false}
          required
          component={TextField}
          name={"name"}
          onClick={handleSelect}
          label={t(AppStrings.Tags.TagName)}
        />
      </FieldWrapper>
      <ValidationIndicator shouldShow={Boolean(createError)} displayText={createError} />
      <DLButton
        actionText={AppStrings.Tags.CreateTag}
        onClick={() => handleSubmit()}
        disabled={!isValid}
        isLoading={isSubmitting}
        icons={{ start: { isHidden: false } }}
        style={{ marginTop: 15 }}
      />
    </View>
  );
};

export default CreateNewTagOptionForm;
