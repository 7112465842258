import Grid from "@material-ui/core/Grid";
import { TextField } from "DLUI/form";
import type { View } from "DLUI/view";
import type { GetAllAccountsQuery, LeaseTransactionLineBaseDto } from "@doorloop/dto";
import { DuplicateDialogButtonLabelValues } from "@doorloop/dto";
import { AccountClass, AccountsTypesByClass, AccountType } from "@doorloop/dto";
import type { ComponentProps } from "react";
import React, { useMemo } from "react";
import type { FieldArrayRenderProps } from "formik";
import { useFormikContext } from "formik";
import { FastField } from "formik";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import type { accountFilterClassType, accountFilterTypes } from "DLUI/dialogs/transactions/transaction";
import { ListItemInputsContainer } from "DLUI/listItems";
import { v4 as uuid } from "uuid";
import { useAnalyticsService } from "hooks/useAnalyticsService";

interface TransactionLineProps extends Pick<ComponentProps<typeof View>, "domRef"> {
  lineIndex: number;
  transactionItem: LeaseTransactionLineBaseDto;
  arrayHelpers: FieldArrayRenderProps;
  name: string;
  accountFilterClass?: accountFilterClassType;
  accountFilterTypes?: accountFilterTypes;
  source?: string;
}

const TransactionLine: React.FC<TransactionLineProps> = ({
  domRef,
  lineIndex,
  arrayHelpers,
  name,
  accountFilterClass,
  accountFilterTypes,
  source
}: TransactionLineProps) => {
  const { t } = useTranslation();
  const formik = useFormikContext<LeaseTransactionLineBaseDto>();
  const { dispatchAnalytics } = useAnalyticsService();

  const didPressDeleteTransactionButton = () => {
    arrayHelpers.remove(lineIndex);
  };
  const didPressDuplicateTransactionButton = () => {
    const fieldName = name.split(".")[0];
    const fieldNameNested = name.split(".")[1];
    const newLine = formik.values?.[fieldName]?.[fieldNameNested]?.[lineIndex] || formik.values?.[name]?.[lineIndex];

    arrayHelpers.insert(lineIndex + 1, { ...newLine, uniqueKey: uuid() });

    dispatchAnalytics("button_click", {
      label: DuplicateDialogButtonLabelValues.DUPLICATE_LINE_ITEM
    });
  };

  const queryParams = useMemo(() => {
    const params: GetAllAccountsQuery = {
      filter_active: true
    };

    if (accountFilterTypes) {
      if (accountFilterTypes === "filter_leaseChargeItem") {
        params["filter_types"] = [
          ...AccountsTypesByClass[AccountClass.LIABILITY],
          ...AccountsTypesByClass[AccountClass.REVENUE],
          ...AccountsTypesByClass[AccountClass.EXPENSE],
          ...AccountsTypesByClass[AccountClass.ASSET].filter((type) => type !== AccountType.ASSET_ACCOUNTS_RECEIVABLE)
        ];
        params.filter_isSystemAccountOrLeaseChargeItem = true;
      }
    } else {
      if (accountFilterClass === "filter_leaseChargeItem") {
        params["filter_classes"] = [
          AccountClass.LIABILITY,
          AccountClass.REVENUE,
          AccountClass.EXPENSE,
          AccountClass.ASSET
        ];
        params.filter_isSystemAccountOrLeaseChargeItem = true;
      }

      if (accountFilterClass === "filter_leaseCreditItem") {
        params["filter_classes"] = [
          AccountClass.LIABILITY,
          AccountClass.EQUITY,
          AccountClass.EXPENSE,
          AccountClass.REVENUE
        ];
      }

      if (accountFilterClass === "filter_leaseDepositItem") {
        params["filter_leaseDepositItem"] = true;
      }
    }

    return params;
  }, [accountFilterClass, accountFilterTypes]);

  return (
    <ListItemInputsContainer
      onClickDuplicateButton={didPressDuplicateTransactionButton}
      domRef={domRef}
      onClickDeleteButton={didPressDeleteTransactionButton}
    >
      <Grid item xs={12} sm={4}>
        <BankAccountFormikAutoCompleteField
          addCreateOption
          createNewOptionComponentProps={{ source }}
          uniqueIndex={lineIndex + 2}
          name={`${name}[${lineIndex}].account`}
          queryParams={queryParams}
          label={t(AppStrings.Common.ListHeader.Category)}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FastField
          component={TextField}
          name={`${name}[${lineIndex}].memo`}
          label={t(AppStrings.Leases.LeaseCharge.Description)}
        />
      </Grid>
      <Grid container item xs={12} sm={4}>
        <FastField
          component={TextField}
          name={`${name}[${lineIndex}].amount`}
          type={"number"}
          required
          formatType={"currency"}
          label={t(AppStrings.Leases.NewLease.LeaseRent.Amount)}
        />
      </Grid>
    </ListItemInputsContainer>
  );
};

export default TransactionLine;
