/* eslint-disable no-implicit-coercion */
import MomentUtils from "@date-io/moment";
import type { LeaseChargeDto, RecurringTransactionDto, VendorBillPaymentDto } from "@doorloop/dto";
import {
  createValidator,
  DateFormats,
  DuplicateDialogButtonLabelValues,
  ExpenseTransactionLineDto,
  LinkedResourceType,
  ObjectPermission,
  OnetimeToRecurringMapper,
  RecurringTransactionType,
  SegmentEventTypes,
  VendorBillDto
} from "@doorloop/dto";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import type { ApiResult } from "api/apiResult";
import { filesApi } from "api/filesApi";
import { leasesRecurringTransactionsApi } from "api/leasesApi";
import { vendorBillPaymentsApi } from "api/outstandingTransactionsApi";
import DeleteConfirmation from "DLUI/dialogs/components/deleteConfirmation";
import BillLinesList from "DLUI/dialogs/vendor/bill/billLinesList";
import FormTopSection from "DLUI/dialogs/vendor/bill/formTopSection";
import PaymentsList from "DLUI/dialogs/vendor/bill/paymentsList";
import SummaryLine from "DLUI/dialogs/vendor/bill/summaryLine";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import { Notes } from "DLUI/notes";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { ArticleIdsEnum, HelpTypeEnum } from "DLUI/screen/helpPanel/types";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, FormikContext, useFormik } from "formik";
import { useRecurringState } from "hooks/useRecurringState";
import AppStrings from "locale/keys";
import { cloneDeep, isEmpty, noop, omit, size, sumBy } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { v4 as uuid } from "uuid";
import DialogFrame, { getDialogFrameDimension } from "../../components/dialogFrame";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { useUserType } from "hooks/useUserType";
import { analyticsService } from "services/analyticsService";
import { QueryParams } from "utils/queryParams";
import { DialogHeaderActionButtons } from "DLUI/actionButtons/dialogHeaderActionButtons";
import {
  cleanRecurringTransactionDataForDuplicateMode,
  cleanVendorBillDataForDuplicateMode,
  DUPLICATE_MODE_QUERY_PARAMS
} from "DLUI/dialogs/duplicateModeHelper";
import { ActivityLabel } from "DLUI/activityLabel/activityLabel";
import { useAnalyticsService } from "hooks/useAnalyticsService";
import { vendorBillsApi, vendorsApi } from "@/api/vendorsApi";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { useJournalEntryPrinting } from "hooks/useJournalEntryPrinting";

export interface VendorBillDialogComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
  dialogFrameWidth?: number;
  dialogFrameHeight?: number;
  editingRecurring?: boolean;
}

const validateForm = createValidator(VendorBillDto);

const helpObject: HelpPanelProps = {
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Vendors.LearnMoreBills,
      articleId: ArticleIdsEnum.CREATE_A_BILL
    }
  ],
  description: AppStrings.Vendors.BillsDescription
};
const helpObjectHOA: HelpPanelProps = {
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Vendors.LearnMoreBills,
      articleId: ArticleIdsEnum.HOA_CREATE_BILL
    }
  ],
  description: AppStrings.Vendors.BillsDescription
};

interface RouteParams {
  transactionId?: string;
  vendorId?: string;
}

enum DialogView {
  Form,
  Loading,
  ConfirmDelete
}

const DIALOG_VIEWS_NUM = size(DialogView);

const VendorBillDialog: React.FC<VendorBillDialogComponentProps> = ({
  onBackdropClick,
  onClose,
  dialogTitle,
  editingRecurring
}: VendorBillDialogComponentProps) => {
  const dialogHeight = getDialogFrameDimension("height", 900);

  const { isHOAUser } = useUserType();
  const { screenContainerPadding } = useResponsiveHelper();
  const { t } = useTranslation();

  const queryParams = new QueryParams();
  const queryTransactionId = queryParams.get(DUPLICATE_MODE_QUERY_PARAMS.transactionId) as string | undefined;
  const queryVendorId = queryParams.get(DUPLICATE_MODE_QUERY_PARAMS.vendorId) as string | undefined;

  const routeParams = useParams<RouteParams>();
  const vendorId = routeParams.vendorId || queryVendorId;
  const transactionId = routeParams.transactionId || queryTransactionId;

  const isEditMode = !!transactionId;
  const isDuplicateMode = !!queryTransactionId;
  const [refreshOnClose, setRefreshOnClose] = useState<boolean>(false);

  const { getValidator, getIsRecurring, getRecurringData, setRecurringData, RecurringContextWrapper } =
    useRecurringState(isEditMode, editingRecurring);

  const linkedResourceType = getIsRecurring()
    ? LinkedResourceType.RecurringTransactionVendorBill
    : LinkedResourceType.VendorBill;
  const permission: AnyPermissionClearance = {
    permission: ObjectPermission.vendorBills,
    field: isEditMode ? "edit" : "create"
  };

  const [viewIndex, setViewIndex] = useState(0);
  const [currentVendorId, setCurrentVendorId] = useState(vendorId);
  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    isEditMode ? DialogState.Show : DialogState.Hidden
  );
  const [showVendorSelectionError, setShowVendorSelectionError] = useState(false);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState("");
  const [chargeData, setChargeData] = useState<LeaseChargeDto | undefined>();
  const [shouldRenderForm, setShouldRenderForm] = useState(!isEditMode);
  const [billPaymentsData, setBillPaymentsData] = useState<VendorBillPaymentDto[]>([]);
  const lastSaveButtonSelectedIndex = useRef(0);
  const hasLinkedPayments = useMemo(() => billPaymentsData.length > 0, [billPaymentsData]);

  const { dispatchAnalytics } = useAnalyticsService();

  const initFormValues = (): VendorBillDto =>
    new VendorBillDto({
      date: moment().format(DateFormats.ISO_DATE_SERVER_FORMAT).toString(),
      dueDate: moment().format(DateFormats.ISO_DATE_SERVER_FORMAT).toString(),
      lines: [new ExpenseTransactionLineDto({ uniqueKey: uuid() })]
    });

  const formik = useFormik({
    initialValues: initFormValues(),
    onSubmit: noop,
    validate: validateForm
  });
  const { setValues, setFieldValue } = formik;

  useEffect(() => {
    setFieldValue("vendor", currentVendorId);
    const hasError = formik.touched.vendor && !currentVendorId;
    hasError && setShowVendorSelectionError(hasError);
  }, [currentVendorId, formik.touched.vendor, setFieldValue]);

  const showErrorMessage = useCallback(
    (message: string = t(AppStrings.Common.NetworkErrorSubTitle)) => {
      setLoadingDialogState(DialogState.Error);
      setLoadingDialogErrorText(message);
    },
    [t]
  );

  const handleFetchVendorBill = useCallback(
    async (response: ApiResult<VendorBillDto | RecurringTransactionDto> | void) => {
      if (response?.data) {
        let vendorBillDtoBase: VendorBillDto;
        if (editingRecurring) {
          const recurringData = response.data as RecurringTransactionDto;
          vendorBillDtoBase = OnetimeToRecurringMapper.buildOnetime.vendorBill(recurringData);
          if (isDuplicateMode) {
            setRecurringData(cleanRecurringTransactionDataForDuplicateMode(recurringData));
            vendorBillDtoBase.date = moment().format(DateFormats.ISO_DATE_SERVER_FORMAT).toString();
            vendorBillDtoBase.dueDate = moment().format(DateFormats.ISO_DATE_SERVER_FORMAT).toString();
          } else {
            setRecurringData(recurringData);
          }
          vendorBillDtoBase.lines?.forEach((line) => {
            line.uniqueKey = uuid();
          });
        } else {
          vendorBillDtoBase = response.data as VendorBillDto;
          if (vendorBillDtoBase.id && !isDuplicateMode) {
            const { data, status, message } = await vendorBillPaymentsApi.getAll({
              filter_vendorBill: response.data.id
            });
            if (status) {
              setBillPaymentsData(data?.data || []);
            } else {
              showErrorMessage(message);
            }
          } else {
            setBillPaymentsData([]);
          }
        }
        const vendorBillDto = isDuplicateMode
          ? cleanVendorBillDataForDuplicateMode(vendorBillDtoBase)
          : vendorBillDtoBase;

        setCurrentVendorId(vendorBillDto.vendor);
        setChargeData(vendorBillDto);
        setValues(vendorBillDto);
        setLoadingDialogState(DialogState.Hidden);
        setShouldRenderForm(true);
      } else {
        showErrorMessage(response ? response.message : undefined);
      }
    },
    [isDuplicateMode, editingRecurring, setRecurringData, setValues, showErrorMessage]
  );

  const fetchVendorBill = useCallback(async () => {
    if (transactionId && isEditMode) {
      setLoadingDialogState(DialogState.Show);
      await handleFetchVendorBill(
        editingRecurring
          ? await leasesRecurringTransactionsApi.get(transactionId).catch(showErrorMessage)
          : await vendorBillsApi.get(transactionId).catch(showErrorMessage)
      );
    }
  }, [isEditMode, editingRecurring, handleFetchVendorBill, showErrorMessage, transactionId]);

  useEffect(() => {
    if (isEditMode) {
      fetchVendorBill();
    }
  }, [isDuplicateMode, isEditMode, fetchVendorBill]);

  const setLines = useCallback(async () => {
    if (currentVendorId && !isEditMode) {
      const vendorDto = await vendorsApi.get(currentVendorId);
      const transactionLines = vendorDto?.data?.accounts?.map(
        (account) => new ExpenseTransactionLineDto({ account, uniqueKey: uuid() })
      );
      if (transactionLines?.length) {
        setFieldValue("lines", transactionLines);
      } else {
        setFieldValue("lines", [
          new ExpenseTransactionLineDto({
            uniqueKey: uuid()
          })
        ]);
      }
    }
  }, [currentVendorId, isEditMode, setFieldValue]);

  useEffect(() => {
    setLines();
  }, [setLines]);

  const isValidForm = async (formikRef: FormikProps<VendorBillDto>) => {
    formikRef.setFieldTouched("dueDate");
    formikRef.setFieldTouched("date");

    formikRef.values.lines?.forEach((_line, index) => {
      formikRef.setFieldTouched(`lines[${index}].property`);
      formikRef.setFieldTouched(`lines[${index}].amount`);
      formikRef.setFieldTouched(`lines[${index}].account`);
    });

    const recurringValidator = getValidator();
    const isRecurring = getIsRecurring();

    let recurringIsValid = true;
    if (isRecurring && recurringValidator) {
      recurringIsValid = await recurringValidator();
    }

    const errorsBase = await formikRef.validateForm();
    const errors = isRecurring ? omit(errorsBase, ["dueDate", "date"]) : errorsBase;

    recurringIsValid && errors.vendor && setShowVendorSelectionError(true);

    return isEmpty(errors) && recurringIsValid;
  };

  const handleClose = (isAddNew?: boolean) => {
    if (isAddNew) {
      setRefreshOnClose(true);
      formik.resetForm();
      setLoadingDialogState(DialogState.Hidden);
      setAttachments([]);
      setViewIndex(DialogView.Form);

      window.setTimeout(() => {
        formik.setFieldValue("vendor", currentVendorId);
      });
    } else {
      onClose();
    }
  };

  const createTransaction = async (isAddNew?: boolean) => {
    const recurringData = getRecurringData();
    const isRecurring = getIsRecurring();
    const vendorBillsValues = cloneDeep(formik.values);
    let response: ApiResult<VendorBillDto | RecurringTransactionDto> | void;

    setViewIndex(DialogView.Loading);
    setLoadingDialogState(DialogState.Show);

    if (isRecurring && recurringData) {
      const recurringVendorBill = OnetimeToRecurringMapper.buildRecurring.vendorBill(vendorBillsValues, recurringData);
      response = await leasesRecurringTransactionsApi.create(recurringVendorBill).catch(showErrorMessage);
    } else {
      response = await vendorBillsApi.create(vendorBillsValues, { idKey: "vendor" }).catch(showErrorMessage);
    }

    if (response?.status && response.data?.id) {
      await filesApi.uploadFiles(attachments, response.data.id, linkedResourceType).catch(showErrorMessage);
      analyticsService.track(SegmentEventTypes.PAYABLE_TRANSACTION_CREATED, {
        transactionType: "Create Bill",
        payeeType: "Vendor",
        isRecurring: false,
        isUnitEmpty: !vendorBillsValues.lines?.some(({ unit }) => unit),
        totalAmount: vendorBillsValues.totalAmount
          ? vendorBillsValues.totalAmount
          : sumBy(vendorBillsValues.lines, (line: ExpenseTransactionLineDto) => line.amount || 0),
        numOfLineItems: size(vendorBillsValues.lines)
      });

      handleClose(isAddNew);
    } else {
      showErrorMessage(response ? response.message : undefined);
    }
  };

  const updateTransaction = async () => {
    if (transactionId) {
      const isRecurring = getIsRecurring();
      const recurringData = getRecurringData();
      const chargeValues = cloneDeep(formik.values);

      setViewIndex(DialogView.Loading);
      setShouldRenderForm(false);
      setLoadingDialogState(DialogState.Show);

      let response;
      if (isRecurring && recurringData) {
        const recurringVendorBill = OnetimeToRecurringMapper.buildRecurring.vendorBill(chargeValues, recurringData);
        response = await leasesRecurringTransactionsApi
          .update(transactionId, recurringVendorBill)
          .catch(showErrorMessage);
      } else {
        response = await vendorBillsApi.update(transactionId, chargeValues).catch(showErrorMessage);
      }

      if (response && response.status && response.data) {
        await filesApi.uploadFiles(attachments, response.data.id, linkedResourceType).catch(showErrorMessage);
        onClose();
      } else {
        showErrorMessage(response ? response.message : undefined);
      }
    }
  };

  const [isVendorIdle, setIsVendorIdle] = useState<boolean>(false);

  const handleSubmit = async (saveButtonIndex?: number, isAddNew?: boolean) => {
    const isValid = await isValidForm(formik);

    if (isVendorIdle && isValid) {
      if (isEditMode && !isDuplicateMode) {
        await updateTransaction();
      } else {
        lastSaveButtonSelectedIndex.current = saveButtonIndex || 0;
        await createTransaction(isAddNew);
      }
    }
  };

  const onFileReceived = (files: FileListItemProps[]) => {
    setAttachments(files);
  };

  const handleVendorChange = (value?: string) => {
    setCurrentVendorId(value);
    formik.setFieldValue("vendor", value);
    formik.setFieldTouched("vendor");
  };

  const accounts = useMemo(() => formik.values.lines?.map((line) => line.account || ""), [formik.values.lines]);

  const renderForm = () => (
    <FormikContext.Provider value={formik}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <View flex={1} flexDirection={"column"} width={"100%"}>
          <View paddingRight={screenContainerPadding} paddingLeft={screenContainerPadding} height={"100%"}>
            <RestrictedPermissionAccess clearance={permission} showNoAccess>
              <RecurringContextWrapper>
                <FormTopSection
                  currentVendorId={currentVendorId}
                  setIsVendorIdle={setIsVendorIdle}
                  handleVendorChange={handleVendorChange}
                  hasLinkedPayments={hasLinkedPayments}
                  register={formik.values.register}
                  showVendorSelectionError={showVendorSelectionError}
                  vendorSelectionErrorText={
                    showVendorSelectionError ? t(AppStrings.Vendors.Screen.PressToSelectVendor) : ""
                  }
                />
              </RecurringContextWrapper>
              <BillLinesList hasLinkedPayments={hasLinkedPayments} accounts={accounts} editMode={isEditMode} />
              <PaymentsList vendorBillPayment={billPaymentsData} />
              <View marginTop={10} justifyContent={"center"} height={50}>
                <FastField component={Notes} height={30} name={"memo"} />
              </View>
              <SummaryLine
                formikRef={formik}
                chargeData={chargeData}
                editMode={isEditMode}
                hasLinkedPayments={hasLinkedPayments}
              />
              <View justifyContent={"flex-end"} width={"100%"} marginBottom={10}>
                <FormAttachments
                  onFileReceived={onFileReceived}
                  files={attachments}
                  editMode={isEditMode}
                  resourceType={linkedResourceType}
                  resourceId={chargeData?.id || transactionId}
                />
              </View>
            </RestrictedPermissionAccess>
            {isEditMode && <ActivityLabel item={formik.values} />}
          </View>
        </View>
      </MuiPickersUtilsProvider>
    </FormikContext.Provider>
  );

  const renderView = ({ index }: { index: number }) => {
    const onRetryButtonPress = async () => {
      await handleSubmit();
    };
    const didPressDismissButton = () => {
      onClose();
    };

    if (index === DialogView.Form) {
      if (isEditMode && loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            errorText={loadingDialogErrorText}
            onRetryButtonPress={fetchVendorBill}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }
      if (shouldRenderForm) {
        return renderForm();
      }
      return <div />;
    }
    if (index === DialogView.Loading) {
      return (
        <View flex={1} alignItems={"center"} justifyContent={"center"} width={"100%"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            errorText={loadingDialogErrorText}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
          />
        </View>
      );
    }
    if (index === DialogView.ConfirmDelete) {
      const isRecurring = getIsRecurring();
      const didPressDismissDeleteButton = () => {
        setViewIndex(DialogView.Form);
      };
      return (
        <DeleteConfirmation
          apiMethod={isRecurring ? leasesRecurringTransactionsApi : vendorBillsApi}
          apiToasts={
            isRecurring
              ? { translationKey: AppStrings.Toasts.custom.recurring[RecurringTransactionType.VENDOR_BILL]?.DELETE }
              : undefined
          }
          didPressDismissButton={didPressDismissDeleteButton}
          didFinishOperation={onClose}
          transactionId={transactionId}
          attachments={attachments}
        />
      );
    }

    return <div />;
  };

  const handleBackdropClick = () => {
    if (viewIndex > DialogView.Form) {
      setViewIndex(viewIndex - 1);
      return;
    }
    if (refreshOnClose) {
      onClose();
      return;
    }
    onBackdropClick();
  };

  const handleDeleteClick = () => {
    setViewIndex(DialogView.ConfirmDelete);
  };

  const handleDuplicateClick = () => {
    transactionId &&
      queryParams.setMultiple({
        [DUPLICATE_MODE_QUERY_PARAMS.transactionId]: transactionId,
        [DUPLICATE_MODE_QUERY_PARAMS.vendorId]: currentVendorId || ""
      });
    queryParams.historyPush();

    dispatchAnalytics("button_click", {
      label: DuplicateDialogButtonLabelValues.DUPLICATE_JOURNAL_ENTRY
    });
  };

  const { printJournalEntry, printLoadingStatus } = useJournalEntryPrinting({ journalEntryId: transactionId });

  const renderHeaderActionButtons = () => (
    <DialogHeaderActionButtons
      onDeleteClick={handleDeleteClick}
      onDuplicateClick={handleDuplicateClick}
      clearance={permission}
      hideDeleteButton={isDuplicateMode || !isEditMode}
      hideDuplicateButton={isDuplicateMode || !isEditMode}
      onPrintClick={isEditMode ? printJournalEntry : undefined}
      printLoadingStatus={printLoadingStatus}
    />
  );

  const renderActionPanelButtons = () => (
    <FormActionButtons
      clearance={permission}
      propsActionPanel={{
        editMode: isEditMode
      }}
      propsSubButton={{ onClick: handleBackdropClick }}
      propsMainButton={
        isEditMode
          ? { type: "cta", props: { onClick: async () => await handleSubmit() } }
          : {
              type: "split",
              props: {
                lastSaveButtonIndex: lastSaveButtonSelectedIndex.current,
                options: [
                  { text: AppStrings.Common.Save, onClick: async () => await handleSubmit() },
                  {
                    text: AppStrings.Common.SaveAndNew,
                    onClick: async (saveButtonIndex) => await handleSubmit(saveButtonIndex, true)
                  }
                ]
              }
            }
      }
    />
  );

  const frameType = useMemo(() => {
    if (viewIndex === DialogView.Form) {
      if (isEditMode && loadingDialogState !== DialogState.Hidden) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }
    if (viewIndex === 2) {
      return "sideMenu";
    }
    if ([DialogView.Loading, DialogView.ConfirmDelete].includes(viewIndex) || viewIndex === 5) {
      return "contentOnly";
    }
    return "sectionTitleFrame";
  }, [viewIndex, isEditMode, loadingDialogState]);

  const currentTitle = useMemo(() => {
    if (viewIndex === 0) {
      return dialogTitle;
    }
    return "";
  }, [dialogTitle, viewIndex]);

  const duplicateModeTitle = useMemo(
    () =>
      isDuplicateMode ? `${t(AppStrings.Vendors.VendorDetails.NewBill)} (${t(AppStrings.Common.Duplicated)})` : "",
    [isDuplicateMode, t]
  );

  return (
    <DialogFrame
      onCloseButtonClick={handleBackdropClick}
      title={duplicateModeTitle || currentTitle}
      width={getDialogFrameDimension("width", 1250)}
      height={dialogHeight}
      renderView={renderView}
      numViews={DIALOG_VIEWS_NUM}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      RenderHeaderActionButtons={renderHeaderActionButtons}
      frameType={frameType}
      keepViewsMounted={false}
      helpPanel={!isHOAUser ? helpObject : helpObjectHOA}
    />
  );
};

export default VendorBillDialog;
