import type { PropsWithChildren } from "react";
import React from "react";
import { usePermission } from "screens/settings/userRoles/usePermission";
import { NoAccessDisplay } from "screens/settings/userRoles/NoAccessDisplay";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";

interface Props {
  showNoAccess?: boolean;
  permissions: AnyPermissionClearance[];
}

export const RestrictedAccessHasAny: React.FC<Props> = ({
  permissions,
  showNoAccess,
  children
}: PropsWithChildren<Props>) => {
  const { hasAnyPermission } = usePermission();

  return <>{hasAnyPermission(permissions) ? children : showNoAccess && <NoAccessDisplay />}</>;
};
