export default {
  Screen: {
    AddNewVendor: "vendors.screen.addNewVendor",
    EditVendor: "vendors.screen.editVendor",
    SearchPlaceHolder: "vendors.screen.searchPlaceHolder",
    EmptydataAddNew: "vendors.screen.emptydataAddNew",
    EmptydataViewInstructionsText: "vendors.screen.emptydataViewInstructionsText",
    OpenBalance: "vendors.screen.openBalance",
    InsuranceStatus: "vendors.screen.insuranceStatus",
    Vendor: "vendors.screen.vendor",
    InsuranceExpires: "vendors.screen.insuranceExpires",
    Balance: "vendors.screen.balance",
    HasOpenBalance: "vendors.screen.hasOpenBalance",
    NoOpenBalance: "vendors.screen.noOpenBalance",
    PressToSelectVendor: "vendors.screen.pressToSelectVendor",
    PressToSelectPayee: "vendors.screen.pressToSelectPayee",
    HelpPanel: {
      Overview: "vendors.screen.helpPanel.overview",
      VendorCategory: "vendors.screen.helpPanel.vendorCategory",
      VendorManage: "vendors.screen.helpPanel.vendorManage",
      CreateVendor: "vendors.screen.helpPanel.createVendor",
      Description: "vendors.screen.helpPanel.description"
    }
  },
  NewVendor: {
    CreateNewVendor: "vendors.newVendor.createNewVendor",
    LoadingText: "vendors.newVendor.loadingText",
    ErrorText: "vendors.newVendor.errorText",
    SuccessText: "vendors.newVendor.successText",
    Services: "vendors.newVendor.services",
    Classification: "vendors.newVendor.classification",
    Insurance: "vendors.newVendor.insurance",
    Properties: "vendors.newVendor.properties",
    FederalTaxes: "vendors.newVendor.federalTaxes",
    ElectronicPayments: "vendors.newVendor.electronicPayments",
    VendorCategoriesDescription: "vendors.newVendor.vendorCategoriesDescription",
    VendorClassificationsDescription: "vendors.newVendor.vendorClassificationsDescription",
    SelectCategories: "vendors.newVendor.selectCategories",
    SelectClassifications: "vendors.newVendor.selectClassifications",
    InsuranceDescription: "vendors.newVendor.insuranceDescription",
    Provider: "vendors.newVendor.provider",
    PolicyNumber: "vendors.newVendor.policyNumber",
    Coverage: "vendors.newVendor.coverage",
    EffectiveDate: "vendors.newVendor.effectiveDate",
    ExpirationDate: "vendors.newVendor.expirationDate",
    SinglePropertyDescription: "vendors.newVendor.singlePropertyDescription",
    SpecificPropertyDescription: "vendors.newVendor.specificPropertyDescription",
    SelectPropertiesDescription: "vendors.newVendor.selectPropertiesDescription",
    SelectProperties: "vendors.newVendor.selectProperties",
    DontSend1099Forms: "vendors.newVendor.dontSend1099Forms",
    Send1099Forms: "vendors.newVendor.send1099Forms",
    SameAsVendorAddress: "vendors.newVendor.sameAsVendorAddress",
    TaxpayerName: "vendors.newVendor.taxpayerName",
    TaxpayerID: "vendors.newVendor.taxpayerID",
    TaxpayerIDType: "vendors.newVendor.taxpayerIDType",
    UseElectronicPayments: "vendors.newVendor.useElectronicPayments",
    DontUseElectronicPayments: "vendors.newVendor.dontUseElectronicPayments",
    DeleteConfirmationText: "vendors.newVendor.deleteConfirmationText",
    DeleteLoadingText: "vendors.newVendor.deleteLoadingText",
    DeleteVendorSuccessText: "vendors.newVendor.deleteVendorSuccessText",
    Insured: "vendors.newVendor.insured",
    NotInsured: "vendors.newVendor.notInsured"
  },
  VendorDetails: {
    CreateBill: "vendors.vendorDetails.createBill",
    CreateNewBill: "vendors.vendorDetails.createNewBill",
    AddExpense: "vendors.vendorDetails.addExpense",
    PayBill: "vendors.vendorDetails.payBill",
    CreateNewPayBill: "vendors.vendorDetails.createNewPayBill",
    AddCredit: "vendors.vendorDetails.addCredit",
    CreateNewAddCredit: "vendors.vendorDetails.createNewAddCredit",
    NewBill: "vendors.vendorDetails.newBill",
    EditBill: "vendors.vendorDetails.editBill",
    EditJustBill: "vendors.vendorDetails.editJustBill",
    EditRecurringBill: "vendors.vendorDetails.editRecurringBill",
    VendorBillPayment: "vendors.vendorDetails.vendorBillPayment",
    NewCredit: "vendors.vendorDetails.newCredit",
    NewExpense: "vendors.vendorDetails.newExpense",
    TotalExpense: "vendors.vendorDetails.totalExpense",
    EditExpense: "vendors.vendorDetails.editExpense",
    EditJournalEntry: "vendors.vendorDetails.editJournalEntry",
    EditRecurringExpense: "vendors.vendorDetails.editRecurringExpense",
    BillReferenceLabel: "vendors.vendorDetails.billReferenceLabel",
    ExpenseReferenceLabel: "vendors.vendorDetails.expenseReferenceLabel",
    DeleteBill: "vendors.vendorDetails.deleteBill",
    DeleteExpense: "vendors.vendorDetails.deleteExpense",
    DeleteJournalEntry: "vendors.vendorDetails.deleteJournalEntry",
    BillDate: "vendors.vendorDetails.billDate",
    SelectVendor: "vendors.vendorDetails.selectVendor",
    SearchVendors: "vendors.vendorDetails.searchVendors",
    Bill: "vendors.vendorDetails.bill",
    DeleteBillConfirmationText: "vendors.vendorDetails.deleteBillConfirmationText",
    DeleteJournalEntryConfirmationText: "vendors.vendorDetails.deleteJournalEntryConfirmationText",
    DeleteBillLoadingText: "vendors.vendorDetails.deleteBillLoadingText",
    DeleteBillSuccessText: "vendors.vendorDetails.deleteBillSuccessText",
    DeleteCreditConfirmationText: "vendors.vendorDetails.deleteCreditConfirmationText",
    DeleteCreditLoadingText: "vendors.vendorDetails.deleteCreditLoadingText",
    DeleteCreditSuccessText: "vendors.vendorDetails.deleteCreditSuccessText",
    DeleteExpenseConfirmationText: "vendors.vendorDetails.deleteExpenseConfirmationText",
    DeleteExpenseLoadingText: "vendors.vendorDetails.deleteExpenseLoadingText",
    DeleteExpenseSuccessText: "vendors.vendorDetails.deleteExpenseSuccessText",
    DeleteVendorBillPaymentConfirmationText: "vendors.vendorDetails.deleteVendorBillPaymentConfirmationText",
    DeleteVendorBillPaymentLoadingText: "vendors.vendorDetails.deleteVendorBillPaymentLoadingText",
    DeleteVendorBillPaymentSuccessText: "vendors.vendorDetails.deleteVendorBillPaymentSuccessText",
    ExpenseDate: "vendors.vendorDetails.expenseDate",
    SelectPayee: "vendors.vendorDetails.selectPayee",
    Payee: "vendors.vendorDetails.payee",
    AddVendor: "vendors.vendorDetails.addVendor",
    AddOwner: "vendors.vendorDetails.addOwner",
    AddTenant: "vendors.vendorDetails.addTenant",
    PaymentDate: "vendors.vendorDetails.paymentDate",
    NoInsurance: "vendors.vendorDetails.noInsurance"
  },
  LearnMoreExpense: "vendors.learnMoreExpense",
  ExpenseDescription: "vendors.expenseDescription",
  LearnMoreBills: "vendors.learnMoreBills",
  BillsDescription: "vendors.billDescription"
};
