import type {
  ExamResponseDto,
  GetAllRentalApplicationsQuery,
  GetRentalApplicationMaskedDataQuery,
  PostExamAnswersRequestDto,
  PutUpdateDecisionStatusDto,
  RentalApplicationDto,
  RentalApplicationSaveForLaterResponse,
  RentalApplicationSendCommunicationDto,
  ScreeningReportPaymentDto,
  RentalApplicationTransunionInfoDto,
  RentalApplicationApplicantGetAllReportsShareScreeningDataDto,
  TransunionReportTypes,
  RentalApplicationReportResponseDto
} from "@doorloop/dto";
import type { RentalApplicationSaveForLaterDto } from "@doorloop/dto";
import {
  GetRentalApplicationApplicantAvailableReportsQuery,
  GetRentalApplicationApplicantReportDataQuery,
  GetRentalApplicationSettingsRequestDto,
  ServerRoutes
} from "@doorloop/dto";
import { apiHelper } from "./apiHelper";
import { propertiesApi } from "./propertiesApi";
import { tenantsApi } from "./tenantsApi";
import { unitsApi } from "./unitsApi";
import { usersApi } from "./usersApi";
import type { ApiResult } from "api/apiResult";
import AppStrings from "../locale/keys";
import { RestApiBase } from "api/restApiBase";
import type { RentalApplicationPostChargeDto } from "@doorloop/dto/dist/settings/shared/rentalApplication/rentalApplicationPostChargeDto.dto";
import type { ApplicantPostChargePaymentDataDto } from "@doorloop/dto/dist/rentalApplications/getProspectPostCharge.dto";
import type { ApplicantPostChargePayment } from "@doorloop/dto/dist/rentalApplications/getProspectPostCharge.dto";
import type { RentalApplicationPostChargeBaseDto } from "@doorloop/dto/dist/settings/shared/rentalApplication/rentalApplicationPostChargeDto.dto";

export class RentalApplicationsApi extends RestApiBase<RentalApplicationDto, GetAllRentalApplicationsQuery> {
  constructor() {
    super(ServerRoutes.RENTAL_APPLICATIONS, [tenantsApi, propertiesApi, unitsApi, usersApi]);
    this.restRoute = ServerRoutes.RENTAL_APPLICATIONS;
  }

  getAllWithStatusFilter = async (query: GetAllRentalApplicationsQuery) =>
    await apiHelper.axiosGet(ServerRoutes.RENTAL_APPLICATIONS_GET_ALL_WITH_STATUS_FILTER, query);

  updateRentalApplicationDecisionStatus = async (data: PutUpdateDecisionStatusDto) =>
    await apiHelper.axiosPut<PutUpdateDecisionStatusDto>({
      url: ServerRoutes.RENTAL_APPLICATIONS_PUT_UPDATE_DECISION_STATUS,
      data
    });

  checkTransunionEligibility = async (propertyId: string) =>
    await apiHelper.axiosPost({
      url: ServerRoutes.RENTAL_APPLICATIONS_POST_CHECK_TRANSUNION_ELIGIBILITY,
      data: {
        propertyId
      }
    });

  getApplicationSettings = async (
    propertyId?: string,
    rentalApplicationId?: string,
    applicationGroupId?: string,
    unitId?: string
  ) => {
    const queryObj = new GetRentalApplicationSettingsRequestDto();
    queryObj.property = propertyId;
    queryObj.rentalApplication = rentalApplicationId;
    queryObj.applicationGroupId = applicationGroupId;
    queryObj.unit = unitId;

    return await apiHelper.axiosGet(ServerRoutes.RENTAL_APPLICATIONS_GET_SETTINGS, queryObj);
  };

  submitApplication = async (data: RentalApplicationDto): Promise<ApiResult<RentalApplicationDto>> =>
    await apiHelper.axiosPost({
      url: ServerRoutes.RENTAL_APPLICATIONS_POST_SUBMIT,
      data
    });

  getExamQuestions = async (rentalApplicationId: string): Promise<ApiResult<ExamResponseDto>> =>
    await apiHelper.axiosGet(ServerRoutes.RENTAL_APPLICATIONS_GET_EXAM_QUESTIONS, { rentalApplicationId });

  postExamAnswers = async (data: PostExamAnswersRequestDto) =>
    await apiHelper.axiosPost({ url: ServerRoutes.RENTAL_APPLICATIONS_POST_EXAM_ANSWERS, data });

  getDetails = async (applicationId: string) => {
    await this.loadDictionariesRequiredForGet();
    return await apiHelper.axiosGet(ServerRoutes.RENTAL_APPLICATIONS + "/" + applicationId);
  };

  postRequestReports = async (rentalApplicationId: string) =>
    await apiHelper.axiosPost({
      url: ServerRoutes.RENTAL_APPLICATIONS_POST_REQUEST_REPORTS,
      data: {
        rentalApplicationId
      }
    });

  getAvailableReports = async (rentalApplicationId: string): Promise<ApiResult<TransunionReportTypes[] | null>> =>
    await apiHelper.axiosGet(ServerRoutes.RENTAL_APPLICATIONS_GET_AVAILABLE_REPORTS, {
      rentalApplicationId
    });

  getReportData = async (rentalApplicationId: string, reportName: string) =>
    await apiHelper.axiosGet(ServerRoutes.RENTAL_APPLICATIONS_GET_REPORT_DATA, {
      rentalApplicationId,
      reportName
    });

  chargeScreeningReports = async (dto: ScreeningReportPaymentDto) =>
    await apiHelper.axiosPost({
      url: ServerRoutes.RENTAL_APPLICATIONS_CHARGE_REPORTS,
      data: dto
    });

  async getMaskedData(id: string, queryParams: GetRentalApplicationMaskedDataQuery) {
    return await apiHelper.axiosGet(`${ServerRoutes.RENTAL_APPLICATIONS}/${id}/masked-data`, queryParams);
  }

  async getProspectApplication(tenantId: string, unitId: string) {
    return await apiHelper.axiosGet(`${ServerRoutes.RENTAL_APPLICATIONS}/${tenantId}/${unitId}/prospect-applications`);
  }

  sendRentalApplicationInvite = async (dto: RentalApplicationSendCommunicationDto) => {
    const result = await apiHelper.axiosPost({
      url: ServerRoutes.RENTAL_APPLICATIONS_POST_SEND_APPLICATION_COMMUNICATION,
      data: dto,
      toasts: {
        severity: "success",
        translationKey: AppStrings.Common.RentalApplicationSent
      }
    });

    await tenantsApi.getDictionary(true);
    return result;
  };

  sendRentalApplicationReminder = async (dto: RentalApplicationSendCommunicationDto) =>
    await apiHelper.axiosPost({
      url: ServerRoutes.RENTAL_APPLICATIONS_POST_SEND_APPLICATION_REMINDERS,
      data: dto,
      toasts: {
        severity: "success",
        translationKey: AppStrings.Common.RentalApplicationReminderSent
      }
    });

  getRentalApplicationValues = async (id: string): Promise<ApiResult<RentalApplicationDto>> =>
    await apiHelper.axiosGet(`${ServerRoutes.RENTAL_APPLICATIONS_GET_APPLICATION_VALUES}/${id}`);

  postRentalApplicationSaveForLater = async (
    dto: RentalApplicationSaveForLaterDto
  ): Promise<ApiResult<RentalApplicationSaveForLaterResponse>> =>
    await apiHelper.axiosPost({
      url: ServerRoutes.RENTAL_APPLICATIONS_SAVE_FOR_LATER,
      data: dto
    });

  putRentalApplicationSaveForLater = async (
    dto: RentalApplicationSaveForLaterDto
  ): Promise<ApiResult<RentalApplicationSaveForLaterResponse>> =>
    await apiHelper.axiosPut({
      url: ServerRoutes.RENTAL_APPLICATIONS_SAVE_FOR_LATER,
      data: dto
    });

  putApplicantPostCharge = async (
    data: RentalApplicationPostChargeDto
  ): Promise<ApiResult<{ success?: boolean; email?: boolean }>> =>
    await apiHelper.axiosPut({
      url: ServerRoutes.RENTAL_APPLICATIONS_APPLICANT_POST_CHARGE,
      data,
      shouldShowErrorToast: true
    });

  putApplicantPostChargeReminder = async (
    dto: RentalApplicationPostChargeBaseDto
  ): Promise<ApiResult<{ success?: boolean; email?: boolean }>> =>
    await apiHelper.axiosPut({
      url: ServerRoutes.RENTAL_APPLICATIONS_APPLICANT_POST_CHARGE_SEND_REMINDER,
      data: dto,
      shouldShowErrorToast: true,
      toasts: {
        severity: "success",
        translationKey: AppStrings.Common.PostChargeReminderSend
      }
    });

  postApplicantPostChargePayment = async (data: ApplicantPostChargePayment): Promise<ApiResult<RentalApplicationDto>> =>
    await apiHelper.axiosPost({
      url: ServerRoutes.RENTAL_APPLICATIONS_POST_APPLICANT_POST_CHARGE_PAYMENT,
      data,
      shouldShowErrorToast: true
    });

  getRentalApplicationPostChargeData = async (
    rentalApplicationId
  ): Promise<ApiResult<ApplicantPostChargePaymentDataDto>> =>
    await apiHelper.axiosGet(ServerRoutes.RENTAL_APPLICATIONS_GET_APPLICANT_POST_CHARGE_DATA, { rentalApplicationId });

  getRentalApplicationTransunionInfo = async (id): Promise<ApiResult<RentalApplicationTransunionInfoDto>> =>
    await apiHelper.axiosGet(ServerRoutes.RENTAL_APPLICATIONS_GET_REPORTS_DATA.replace(":id", id));

  getAllReportsShareScreeningDataForApplicant = async (token: string) =>
    await apiHelper.axiosGet<RentalApplicationApplicantGetAllReportsShareScreeningDataDto>(
      ServerRoutes.RENTAL_APPLICATIONS_APPLICANT_GET_ALL_REPORTS_SHARE_SCREENING_DATA,
      new GetRentalApplicationApplicantAvailableReportsQuery({
        token
      })
    );

  getShareScreeningReportDataForApplicant = async (token: string, reportName: string) =>
    await apiHelper.axiosGet<RentalApplicationReportResponseDto>(
      ServerRoutes.RENTAL_APPLICATIONS_APPLICANT_GET_REPORT_DATA,
      new GetRentalApplicationApplicantReportDataQuery({
        token,
        reportName
      })
    );
}

export const rentalApplicationsApi = new RentalApplicationsApi();
