"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EstimatedNumberOfTenants = void 0;
var EstimatedNumberOfTenants;
(function (EstimatedNumberOfTenants) {
    EstimatedNumberOfTenants["ZERO"] = "0";
    EstimatedNumberOfTenants["ONE_TO_TWENTY"] = "1 - 20";
    EstimatedNumberOfTenants["TWENTY_TO_FIFTY"] = "20 - 50";
    EstimatedNumberOfTenants["FIFTY_ONE_TO_HUNDRED"] = "51 - 100";
    EstimatedNumberOfTenants["OVER_HUNDRED"] = "Over 100";
})(EstimatedNumberOfTenants || (exports.EstimatedNumberOfTenants = EstimatedNumberOfTenants = {}));
