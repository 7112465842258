import type { PopoverProps } from "@material-ui/core";
import { Popover } from "@material-ui/core";
import type { FC, RefObject } from "react";
import React, { useEffect, useState } from "react";
import { usePopupContext } from "./context";

interface PopoverContentProps<TTrigger extends HTMLElement = HTMLElement> {
  triggerRef?: RefObject<TTrigger>;
  popoverProps?: Partial<PopoverProps>;
}

export const PopoverContent: FC<PopoverContentProps> = ({ triggerRef, popoverProps, children }) => {
  const { onClose, open } = usePopupContext();
  const [contentElement, setContentElement] = useState<HTMLDivElement | null>(null);
  const [resizeCount, setResizeCount] = useState<number>(0);

  useEffect(() => {
    if (!contentElement) return;

    const resizeObserver = new ResizeObserver(() => {
      setResizeCount((prev) => prev + 1);
    });

    resizeObserver.observe(contentElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [contentElement]);

  return (
    <Popover
      PaperProps={{
        style: {
          borderRadius: "8px",
          zIndex: resizeCount % 2 === 0 ? 1 : 2
        }
      }}
      anchorEl={triggerRef?.current}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left"
      }}
      {...popoverProps}
    >
      <div
        ref={(element) => {
          if (!element || Boolean(contentElement)) return;

          setContentElement(element);
        }}
      >
        {children}
      </div>
    </Popover>
  );
};
