import { Formik } from "formik";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import React, { useEffect, useState } from "react";
import { QuickbooksSettingsDto, SettingPermission, SettingsAccessEnum, createValidator } from "@doorloop/dto";
import { settingsApi } from "api/settingsApi";
import { View } from "DLUI/view";

import QuickbooksSyncDialog, {
  QuickbooksSyncDialogFrameHeight,
  QuickbooksSyncDialogFrameWidth
} from "screens/settings/quickbooksSync/quickbooksSync";
import _ from "lodash";
import { RestrictedPermissionAccess } from "screens/settings/userRoles";
import { QueryParams } from "../../../../utils/queryParams";
import TenantPortalDialogHeader from "DLUI/dialogs/components/tenantPortalDialogHeader";
import { ErrorSignIcon } from "../../../../assets";
import { Icon } from "DLUI/icon";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { history } from "store/history";
import { Routes } from "../../../appRouter";
import DLButton, { DLButtonVariantsEnum, DLButtonColorsEnum } from "DLUI/button/dlButton";

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
}

const validateForm = createValidator(QuickbooksSettingsDto);

const QuickbooksSyncFormikWrapper: React.FC<ComponentProps> = ({
  onBackdropClick,
  onClose,
  dialogTitle
}: ComponentProps) => {
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);
  const [quickbooksSettingsData, setQuickbooksSettingsData] = useState<QuickbooksSettingsDto | undefined>();
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");

  const syncErrorMessage = QueryParams.get("error");
  const errorMode = syncErrorMessage !== undefined;

  useEffect(() => {
    !errorMode && loadData();
  }, []);

  const loadData = async () => {
    setLoadingDialogState(DialogState.Show);
    const response = await settingsApi.getQuickbooksSettings().catch((error) => {
      setLoadingDialogState(DialogState.Error);
      setLoadingDialogErrorText(error);
    });
    if (response && response.status && response.data) {
      if (_.isEmpty(response.data)) {
        setQuickbooksSettingsData(new QuickbooksSettingsDto());
      } else {
        setQuickbooksSettingsData(response.data);
      }
      setLoadingDialogState(DialogState.Hidden);
    } else {
      setLoadingDialogState(DialogState.Error);
      setLoadingDialogErrorText(response ? response.message : "");
    }
  };

  const initFormValues = (): QuickbooksSettingsDto => quickbooksSettingsData || new QuickbooksSettingsDto();

  const didPressCloseButton = () => {
    history.push(Routes.SETTINGS_GENERAL_SETTINGS);
  };
  if (errorMode) {
    return (
      <View width={QuickbooksSyncDialogFrameWidth} height={QuickbooksSyncDialogFrameHeight} backgroundColor={"light"}>
        <TenantPortalDialogHeader onClose={onBackdropClick} title={dialogTitle || ""} />
        <View justifyContent={"center"} width={"100%"} flex={1}>
          <View justifyContent={"center"} marginTop={40} paddingLeft={40} paddingRight={40}>
            <View
              style={{
                backgroundColor: "#FCECEE"
              }}
              maxHeight={320}
              borderRadius={10}
              overflow={"scroll"}
            >
              <View paddingLeft={20} paddingRight={20} alignItems={"center"}>
                <Icon Source={ErrorSignIcon} width={45} height={45} marginLeft={20} marginRight={20} marginTop={20} />
                <Text marginTop={20} color={"error"} fontSize={20} bold>
                  Unable to Sync Accounts
                </Text>
                <Text lineHeight={"26px"} fullWidth marginTop={20} color={"error"}>
                  {syncErrorMessage}
                </Text>
                <View height={40} />
              </View>
            </View>
          </View>
          <View width={"100%"} justifyContent={"flex-end"} alignItems={"center"} marginBottom={20}>
            <DLButton
              onClick={didPressCloseButton}
              actionText={AppStrings.Common.Close}
              variant={DLButtonVariantsEnum.OUTLINED}
              color={DLButtonColorsEnum.NEUTRAL}
              style={{ minWidth: 220, marginTop: 40 }}
            />
          </View>
        </View>
      </View>
    );
  }

  if (loadingDialogState !== DialogState.Hidden) {
    return (
      <View
        width={QuickbooksSyncDialogFrameWidth}
        height={QuickbooksSyncDialogFrameHeight}
        alignItems={"center"}
        justifyContent={"center"}
        backgroundColor={"light"}
      >
        <RestrictedPermissionAccess
          clearance={{
            permission: SettingPermission.quickbooks,
            requiredLevel: SettingsAccessEnum.VIEW
          }}
          showNoAccess
        >
          <LoadingDialog
            dialogState={loadingDialogState}
            errorText={loadingDialogErrorText}
            didPressDismissButton={onBackdropClick}
            onRetryButtonPress={loadData}
            minHeight={QuickbooksSyncDialogFrameHeight}
          />
        </RestrictedPermissionAccess>
      </View>
    );
  }

  return (
    <Formik initialValues={initFormValues()} onSubmit={() => {}} validate={validateForm}>
      {() => (
        <QuickbooksSyncDialog
          onBackdropClick={onBackdropClick}
          dialogTitle={dialogTitle}
          didFinishOperation={onClose}
        />
      )}
    </Formik>
  );
};

export default QuickbooksSyncFormikWrapper;
