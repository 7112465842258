import React from "react";
import Text from "DLUI/text";
import makeStyles from "./styles";
interface ComponentProps {
  labelText: string;
}

export const LabelIndicator: React.FC<ComponentProps> = ({ labelText }: ComponentProps) => {
  const classes = makeStyles();
  return (
    <div className={classes.componentContainer}>
      <Text textTransform={"uppercase"} fontWeight={700} fontSize={16} color={"white"} value={labelText} />
    </div>
  );
};
