import type { PhoneNumberDto } from "@doorloop/dto";
import { OwnerPortalServerRoutes } from "@doorloop/dto";

import type { ApiResult } from "api/apiResult";
import { apiHelper } from "api/apiHelper";

class OwnerPortalPhoneNumbersApi {
  async getCurrentPhoneNumber(): Promise<ApiResult<PhoneNumberDto>> {
    return await apiHelper.axiosGet(OwnerPortalServerRoutes.PHONE_NUMBERS_COMMUNICATION_PHONE_NUMBER);
  }
}

export const ownerPortalPhoneNumbersApi = new OwnerPortalPhoneNumbersApi();
