import MomentUtils from "@date-io/moment";
import type { GetAllAccountsQuery, OwnerDto } from "@doorloop/dto";
import {
  AccountType,
  CheckReceivableInfoDto,
  createValidator,
  DataCy,
  DuplicateDialogButtonLabelValues,
  LinkedResourceDto,
  LinkedResourceType,
  ObjectPermission,
  OwnerContributionDto,
  PaymentMethod
} from "@doorloop/dto";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import type { ApiResult } from "api/apiResult";
import { filesApi } from "api/filesApi";
import { ownerContributionsApi } from "api/ownerContributionsApi";
import { ownersApi } from "api/ownersApi";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum } from "DLUI/button/dlButton";
import { DialogSearchPanel, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import AnimatedContent from "DLUI/dialogs/components/animatedContent";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import { FormikDatePicker, FormikReferenceLabel, FormikSwitchButton, SetAsDefaultField } from "DLUI/form";
import { Notes } from "DLUI/notes";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import { SeparationLine } from "DLUI/separatorView";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, FormikContext, getIn, useFormik } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { OwnersList, SelectableOwnersListItem } from "screens/owners";
import {
  initFormValues as ownerInitFormValues,
  validateForm as validateNewOwnerForm
} from "screens/owners/newOwner/formikHelper";
import { getOwnerFormMenuItems } from "screens/owners/newOwner/menuItems";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import DeleteConfirmation from "../components/deleteConfirmation";
import DialogFrame, { getDialogFrameDimension, getDialogSelectionHeight } from "../components/dialogFrame";
import DialogAmountView from "../components/totalAmountView";
import OwnerSelection from "./ownerSelection";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { useLocalStorage } from "hooks/useLocalStorage";
import { usePermission } from "screens/settings/userRoles/usePermission";
import TenantPortalNotificationPanel from "screens/tenantPortal/components/tenantPortalNotificationPanel";
import { ErrorSignIcon } from "@/assets";
import { DialogRoutes } from "DLUI/screen/dialogsProvider";
import { NavigationManager } from "utils/navigation";
import { useShakeEffect } from "hooks/useShakeEffect";
import { ShakeEffectView } from "DLUI/animatableView";
import { OwnerContributionFormFields } from "DLUI/dialogs/ownerContribution/ownerContributionFormFields";
import qs from "qs";
import { QueryParams } from "utils/queryParams";
import {
  cleanOwnerContributionDataForDuplicateMode,
  DUPLICATE_MODE_QUERY_PARAMS
} from "DLUI/dialogs/duplicateModeHelper";
import { DialogHeaderActionButtons } from "DLUI/actionButtons/dialogHeaderActionButtons";
import { useAnalyticsService } from "hooks/useAnalyticsService";
import { useTranslation } from "react-i18next";
import { generalUtils } from "@doorloop/utils";

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
  dialogFrameWidth?: number;
  dialogFrameHeight?: number;
  transactionCreatedText: string;
  loadingTransactionDataText: string;
  loadingTransactionDataSuccessText: string;
  updatingTransactionText: string;
  transactionUpdatedText: string;
}

enum DialogViews {
  OWNER_SELECTION = 0,
  OWNER_FORM = 1,
  ADD_NEW_OWNER = 2,
  CREATING_UPDATING = 3,
  DELETING = 4
}

const validateForm = createValidator(OwnerContributionDto);

const dialogWidth = 1000;

const OwnerContribution: React.FC<ComponentProps> = ({
  onBackdropClick,
  onClose,
  dialogTitle,
  transactionCreatedText,
  loadingTransactionDataText,
  loadingTransactionDataSuccessText,
  updatingTransactionText,
  transactionUpdatedText
}) => {
  const { t } = useTranslation();
  const { isMobile, screenContainerPadding } = useResponsiveHelper();
  const { ownerId, transactionId: paramsTransactionId } = useParams<any>();
  const queryParams = new QueryParams();
  const queryTransactionId = queryParams.get(DUPLICATE_MODE_QUERY_PARAMS.transactionId) as string | undefined;
  const transactionId = queryTransactionId || paramsTransactionId;
  const editMode = Boolean(transactionId);
  const duplicateMode = Boolean(queryTransactionId);
  const permission: AnyPermissionClearance = {
    permission: ObjectPermission.ownerContributions,
    field: editMode ? "edit" : "create"
  };

  const [pendingFile, setPendingFile] = useState<File | undefined>();
  const [viewIndex, setViewIndex] = useState(DialogViews.OWNER_SELECTION);
  const [renderSelectionList, setRenderSelectionList] = useState<boolean>(false);
  const [currentOwnerId, setCurrentOwnerId] = useState<string | undefined>(ownerId);
  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);
  const [selectLeaseFilterObj, setSelectLeaseFilterObj] = useState({
    filter_text: ""
  });

  const [propertyQueryParams, setPropertyQueryParams] = useState({});

  const [depositAccountQueryParams, setDepositAccountQueryParams] = useState<GetAllAccountsQuery>({
    filter_type: AccountType.ASSET_BANK,
    filter_active: true
  });

  const [showOwnerSelectionError, setShowOwnerSelectionError] = useState<boolean>(false);
  const [ownerSelectionErrorText, setOwnerSelectionErrorText] = useState<string>("");
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    editMode ? DialogState.Show : DialogState.Hidden
  );
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const [loadingDialogSuccessText, setLoadingDialogSuccessText] = useState<string>(transactionCreatedText);
  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState<string>(loadingTransactionDataText);

  const [transactionData, setTransactionData] = useState<OwnerContributionDto | undefined>();
  const [shouldrenderForm, setShouldRenderForm] = useState<boolean>(!editMode);

  const [currentMenuItemTitle, setCurrentMenuItemTitle] = useState<string>("");

  const [accountDefaultValue, setAccountDefaultValue] = useState<string | undefined>();
  const [propertyDefaultValue, setPropertyDefaultValue] = useState<string | undefined>();

  const [showCheckNumberInput, setShowCheckNumberInput] = useState<boolean>(false);
  const [autoDepositDefaultValue, setAutoDepositDefaultValue] = useLocalStorage(
    "autoDepositDefaultValue-OwnerContribution",
    "true"
  );
  const createDepositsPermission: AnyPermissionClearance = {
    permission: ObjectPermission.bankDeposits,
    field: "create"
  };
  const { hasPermission } = usePermission();
  const hasCreateDepositsPermission = hasPermission(
    createDepositsPermission.permission,
    createDepositsPermission.field
  );
  const { dispatchAnalytics } = useAnalyticsService();

  const initFormValues = (): OwnerContributionDto => {
    if (editMode && transactionData) {
      return transactionData;
    }
    const _ownerContributionDto = new OwnerContributionDto();
    _ownerContributionDto.date = moment().format("YYYY-MM-DD").toString();
    _ownerContributionDto.owner = currentOwnerId;
    _ownerContributionDto.autoDeposit = hasCreateDepositsPermission ? autoDepositDefaultValue === "true" : false;
    return _ownerContributionDto;
  };

  const formik = useFormik({
    initialValues: initFormValues(),
    onSubmit: _.noop,
    validate: validateForm
  });

  const { showShakeEffect, runShakeEffect } = useShakeEffect();
  const depositEntry = useMemo(
    () => formik.values.depositEntry || (qs.parse(window.location.search)["depositEntry"] as string),
    [formik.values.depositEntry, window.location.search]
  );
  const shouldShowDepositNotification = editMode && !duplicateMode && depositEntry;

  const dialogHeight = 650 + (shouldShowDepositNotification ? 50 : 0);

  useEffect(() => {
    if (currentOwnerId) {
      setPropertyQueryParams({
        filter_owner: currentOwnerId
      });
    }

    formik.setFieldValue("owner", currentOwnerId);
    const errorText = getIn(formik.errors, "owner");
    const touchedVal = getIn(formik.touched, "owner");

    setShowOwnerSelectionError(touchedVal && errorText !== undefined);
    if (errorText !== undefined) {
      setOwnerSelectionErrorText(errorText);
    }
  }, [currentOwnerId]);

  useEffect(() => {
    if (editMode) {
      loadTransactionData();
    }
  }, [duplicateMode]);

  const isValidForm = async (formikRef: FormikProps<OwnerContributionDto>) => {
    formikRef.setFieldTouched("date");
    formikRef.setFieldTouched("depositToAccount");
    formikRef.setFieldTouched("amountReceived");
    formikRef.setFieldTouched("paymentMethod");
    formikRef.setFieldTouched("property");

    const errors = (await formikRef.validateForm()) as any;
    const inValidOwner = Boolean(errors.owner);
    setShowOwnerSelectionError(inValidOwner);
    if (inValidOwner) {
      const errorText = getIn(formik.errors, "owner");
      if (errorText !== undefined) {
        setOwnerSelectionErrorText(errorText);
      }
    }
    return _.isEmpty(errors);
  };

  const loadTransactionData = async () => {
    if (editMode && transactionId) {
      setLoadingDialogState(DialogState.Show);

      const response = await ownerContributionsApi.get(transactionId).catch((error) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });
      if (response && response.status && response.data) {
        const data = duplicateMode
          ? cleanOwnerContributionDataForDuplicateMode({
              ownerContribution: response.data,
              autoDeposit: hasCreateDepositsPermission && generalUtils.isTrue(autoDepositDefaultValue || "")
            })
          : response.data;
        setAccountDefaultValue(data.depositToAccount);
        setPropertyDefaultValue(data.property);
        setTransactionData(data);
        setCurrentOwnerId(data.owner);
        setLoadingDialogSuccessText(loadingTransactionDataSuccessText);
        setLoadingDialogState(DialogState.Success);
        setLoadingDialogState(DialogState.Hidden);
        setShouldRenderForm(true);
        setShowCheckNumberInput(data.paymentMethod === PaymentMethod.CHECK);

        formik.setValues(data);
      } else {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(response ? response.message : "");
      }
    }
  };

  const createTransaction = async () => {
    const ownerContributionsValues = _.cloneDeep(formik.values);
    setViewIndex(DialogViews.CREATING_UPDATING);
    setLoadingDialogState(DialogState.Show);
    const results = await ownerContributionsApi.create(ownerContributionsValues, { idKey: "owner" }).catch((error) => {
      setLoadingDialogState(DialogState.Error);
      setLoadingDialogErrorText(error);
    });

    if (results && results.status && results.data) {
      await filesApi
        .uploadFiles(attachments, results.data.id!, LinkedResourceType.OwnerContribution)
        .catch((error: string) => {
          setLoadingDialogState(DialogState.Error);
          setLoadingDialogErrorText(error);
        });

      setLoadingDialogState(DialogState.Success);
      setTimeout(() => {
        onClose();
      }, 500);
    } else {
      setLoadingDialogErrorText(results ? results.message : "");
      setLoadingDialogState(DialogState.Error);
    }
  };

  const updateTransaction = async () => {
    if (!transactionId) {
      return;
    }

    const chargeValues = _.cloneDeep(formik.values);
    setViewIndex(DialogViews.CREATING_UPDATING);
    setShouldRenderForm(false);
    setLoadingDialogState(DialogState.Show);
    setLoadingDialogLoadingText(updatingTransactionText);
    const results = await ownerContributionsApi.update(transactionId, chargeValues).catch((error) => {
      setLoadingDialogState(DialogState.Error);
      setLoadingDialogErrorText(error);
    });
    if (results && results.status && results.data) {
      await filesApi
        .uploadFiles(attachments, results.data.id!, LinkedResourceType.OwnerContribution)
        .catch((error: string) => {
          setLoadingDialogState(DialogState.Error);
          setLoadingDialogErrorText(error);
        });
      setLoadingDialogSuccessText(transactionUpdatedText);
      setLoadingDialogState(DialogState.Success);
      onClose();
    } else {
      setLoadingDialogErrorText(results ? results.message : "");
      setLoadingDialogState(DialogState.Error);
    }
  };

  const didPressSaveButton = async () => {
    const isValid = await isValidForm(formik);
    if (isValid) {
      if (editMode && !duplicateMode) {
        updateTransaction();
      } else {
        createTransaction();
      }
    }
  };

  const amountReceived = getIn(formik.values, "amountReceived") || 0;

  const onFileReceived = (files: FileListItemProps[]) => {
    setAttachments(files);
  };

  const didPressSelectOwner = () => {
    setViewIndex(DialogViews.OWNER_FORM);
    setTimeout(() => {
      setRenderSelectionList(true);
    }, 500);
  };

  const didChangePaymentMethod = (nextValue: string) => {
    if (nextValue === PaymentMethod.CHECK) {
      formik.setFieldValue("checkInfo", new CheckReceivableInfoDto());
      setShowCheckNumberInput(true);
    } else {
      setShowCheckNumberInput(false);
    }
  };

  const didChangeProperty = (e: any, obj: any) => {
    if (obj && obj.id) {
      setDepositAccountQueryParams({
        filter_type: AccountType.ASSET_BANK,
        filter_property: obj.id
      });
    }
  };

  const handleSetAutoDepositDefaultValue = () => {
    setAutoDepositDefaultValue(formik.values.autoDeposit ? "true" : "false");
  };

  const didPressViewDeposit = () => {
    if (shouldShowDepositNotification) {
      NavigationManager.viewDepositFromDialog(depositEntry, DialogRoutes.EDIT_OWNER_CONTRIBUTION);
    } else {
      runShakeEffect();
    }
  };
  const renderForm = () => (
    <FormikContext.Provider value={formik}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <View noWrap flex={1} width={"100%"}>
          <RestrictedPermissionAccess clearance={permission} showNoAccess>
            <View
              noWrap
              height={"100%"}
              paddingLeft={screenContainerPadding}
              paddingRight={screenContainerPadding}
              flexDirection={"column"}
            >
              {shouldShowDepositNotification && (
                <TenantPortalNotificationPanel
                  title={AppStrings.Common.PaymentHasBeenDeposited}
                  notificationText={""}
                  icon={ErrorSignIcon}
                  hideBoxShadow
                  marginTop={20}
                  borderRadius={10}
                  border={"1px solid #F2C94C"}
                  minHeight={50}
                  renderActionPanel={() => (
                    <ShakeEffectView showEffect={showShakeEffect}>
                      <View noWrap alignItems={"center"}>
                        <DLButton
                          actionText={AppStrings.Common.ViewDeposit}
                          onClick={didPressViewDeposit}
                          color={DLButtonColorsEnum.SECONDARY}
                          size={DLButtonSizesEnum.LARGE}
                          style={{ minWidth: 180 }}
                        />
                      </View>
                    </ShakeEffectView>
                  )}
                />
              )}
              <View marginTop={20} flexDirection={"row"} noWrap={!isMobile} gap={20}>
                <Grid container justify={"center"} item xs={12} sm={6}>
                  <OwnerSelection
                    didPressSelectOwner={didPressSelectOwner}
                    ownerId={currentOwnerId}
                    errorTex={showOwnerSelectionError ? ownerSelectionErrorText : undefined}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FastField
                    component={FormikDatePicker}
                    uniqueKey={"billDate"}
                    label={AppStrings.Owners.OwnerContribution.ContributionDate}
                    name={"date"}
                    noMargin
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FastField component={FormikReferenceLabel} name={"reference"} backgroundColor={"dark"} />
                </Grid>
              </View>
              <SeparationLine marginTop={20} width={"100%"} height={1} />
              <OwnerContributionFormFields
                didChangeProperty={didChangeProperty}
                propertyQueryParams={propertyQueryParams}
                propertyDefaultValue={propertyDefaultValue}
                queryParams={depositAccountQueryParams}
                accountDefaultValue={accountDefaultValue}
                didChangePaymentMethod={didChangePaymentMethod}
                showCheckNumberInput={showCheckNumberInput}
              />
              {(!editMode || duplicateMode) && (
                <>
                  <SeparationLine marginTop={20} width={"100%"} height={1} />
                  <View marginTop={20}>
                    <SetAsDefaultField
                      component={FormikSwitchButton}
                      viewProps={{ gap: 12 }}
                      onSetAsDefaultClick={handleSetAutoDepositDefaultValue}
                      dto={OwnerContributionDto}
                      name={"autoDeposit"}
                      label={AppStrings.Leases.LeaseTransaction.Payment.AutomaticDepositCreation}
                      dataCy={DataCy.transactions.autoDepositFundsSwitch}
                    />
                  </View>
                  <SeparationLine marginTop={20} width={"100%"} height={1} />
                </>
              )}
              <View marginTop={20} justifyContent={"center"} height={50}>
                <FastField component={Notes} height={30} name={"memo"} />
              </View>
              <View marginTop={isMobile ? 20 : 0} flexDirection={"row"}>
                <DialogAmountView amount={amountReceived} title={AppStrings.Owners.OwnerContribution.AmountReceived} />
              </View>
              <View justifyContent={"flex-end"} width={"100%"} flex={1} marginBottom={20}>
                <FormAttachments
                  editMode={editMode}
                  resourceId={transactionData ? transactionData.id! : undefined}
                  resourceType={LinkedResourceType.OwnerContribution}
                  onFileReceived={onFileReceived}
                  files={attachments}
                />
              </View>
            </View>
          </RestrictedPermissionAccess>
        </View>
      </MuiPickersUtilsProvider>
    </FormikContext.Provider>
  );

  const renderOwnerSelectionList = () => {
    const didChangeSearchQuery = (value: string) => {
      setSelectLeaseFilterObj({
        filter_text: value
      });
    };

    const onListItemPress = (selectedItem: OwnerDto, itemIndex?: number) => {
      if (selectedItem.id) {
        setViewIndex(DialogViews.OWNER_SELECTION);
        setCurrentOwnerId(selectedItem.id);
      }
    };

    const didPressAddNewOwner = () => {
      setViewIndex(DialogViews.ADD_NEW_OWNER);
    };

    return (
      <View>
        <View paddingLeft={20} paddingRight={20} flexDirection={"row"}>
          <View autoWidth>
            <DialogSearchPanel
              placeholder={AppStrings.Owners.Screen.SearchPlaceHolder}
              onChange={didChangeSearchQuery}
              borderRadius={30}
            />
          </View>
          <View flex={1} justifyContent={"center"} alignItems={"flex-end"} marginTop={20}>
            <DLButton
              clearance={{
                permission: ObjectPermission.owners,
                field: "create"
              }}
              actionText={AppStrings.Owners.Screen.AddNewOwner}
              onClick={didPressAddNewOwner}
              icons={{ start: { isHidden: false } }}
            />
          </View>
        </View>
        <View>
          {renderSelectionList ? (
            <View
              id={"selectableOwnerListItem"}
              height={getDialogSelectionHeight(dialogHeight)}
              overflow={"scroll"}
              marginTop={20}
            >
              <OwnersList
                filterObj={selectLeaseFilterObj}
                ListItem={SelectableOwnersListItem}
                fullWidth
                didSelectOwnerItem={onListItemPress}
                listDirection={"column"}
                selectableListItem
                stickyHeaderId={"selectableOwnerListItem"}
                scrollableTarget={"selectableOwnerListItem"}
                removeDefaultBottomPadding
                didPressAddNewButton={didPressAddNewOwner}
              />
            </View>
          ) : null}
        </View>
      </View>
    );
  };

  const renderView = ({ index }: any) => {
    const onRetryButtonPress = async () => {
      await didPressSaveButton();

      // createTransaction();
    };
    const didPressDismissButton = () => {
      setLoadingDialogState(DialogState.Hidden);
      setShouldRenderForm(true);
      setViewIndex(DialogViews.OWNER_SELECTION);
    };

    if (index === 0) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={loadTransactionData}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }
      if (shouldrenderForm) {
        return renderForm();
      }
      return <div />;
    }
    if (index === 1) {
      return renderOwnerSelectionList();
    }
    if (index === 2) {
      const onFileReceive = (receivedFile: File) => {
        setPendingFile(receivedFile);
      };

      const onFileDelete = () => {
        setPendingFile(undefined);
      };

      const onMenuItemSelection = (menuItemTitle: string) => {
        setCurrentMenuItemTitle(menuItemTitle);
      };

      const uploadFile = async (formikRef: FormikProps<any>, id: string, onClose: (values?: any) => void) => {
        setLoadingDialogState(DialogState.Success);
        const LinkedResource = new LinkedResourceDto(id, LinkedResourceType.Tenant);

        const response = (await ownersApi.uploadPicture(pendingFile!, LinkedResource, id).catch((error: string) => {
          formikRef!.setSubmitting(false);
          setLoadingDialogState(DialogState.Error);
        })) as ApiResult<any>;

        if (response && response.status) {
          setLoadingDialogState(DialogState.Success);
          onClose(response.data);
        } else {
          formikRef!.setSubmitting(false);
          // setCreateUnitErrorText(response.message);
          setLoadingDialogState(DialogState.Error);
        }
      };

      const didCreateNewOwner = (values?: any) => {
        setViewIndex(DialogViews.OWNER_SELECTION);
        if (values && values.id) {
          setCurrentOwnerId(values.id);
        }
      };

      const createNewOwner = async (formikRef: FormikProps<any>) => {
        if (formikRef !== null) {
          const response = (await ownersApi.create(formikRef.values).catch((error: string) => {
            formikRef!.setSubmitting(false);
            setLoadingDialogState(DialogState.Error);
          })) as ApiResult<any>;

          if (response && response.status) {
            if (pendingFile) {
              uploadFile(formikRef, response.data.id, didCreateNewOwner);
            } else {
              setLoadingDialogState(DialogState.Success);
              didCreateNewOwner(response.data);
            }
          } else {
            formikRef!.setSubmitting(false);
            // setCreateUnitErrorText(response.message);
            setLoadingDialogState(DialogState.Error);
          }
        }
      };

      const renderNewVendorActionPanel = (formikRef: FormikProps<any>) => {
        const didPressCreateNewOwner = async () => {
          if (formikRef !== null) {
            const validationResult = await validateNewOwnerForm(formikRef.values);
            if (_.isEmpty(validationResult)) {
              formikRef.setSubmitting(true);
              setLoadingDialogState(DialogState.Show);
              setViewIndex(DialogViews.CREATING_UPDATING);
              createNewOwner(formikRef);
            } else if (validationResult.errorStepIndex !== undefined) {
            }
          }
        };

        return (
          <FormActionButtons
            propsSubButton={{ onClick: _onBackdropClick }}
            propsMainButton={{ type: "cta", props: { onClick: didPressCreateNewOwner } }}
          />
        );
      };

      const sectionsItems = getOwnerFormMenuItems();

      return (
        <AnimatedContent
          formikInitialValues={ownerInitFormValues}
          sectionItems={sectionsItems}
          formikValidation={validateForm}
          renderActionPanelButtons={renderNewVendorActionPanel}
          dialogHeight={dialogHeight}
          onFileDelete={onFileDelete}
          onFileReceive={onFileReceive}
          onMenuItemSelection={onMenuItemSelection}
        />
      );
    }
    if (index === 3) {
      return (
        <View flex={1} alignItems={"center"} justifyContent={"center"} width={"100%"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
          />
        </View>
      );
    }
    if (index === 4) {
      return (
        <DeleteConfirmation
          apiMethod={ownerContributionsApi}
          didPressDismissButton={didPressDismissButton}
          didFinishOperation={onClose}
          transactionId={transactionId}
          attachments={attachments}
        />
      );
    }
    return <div />;
  };

  const _onBackdropClick = () => {
    if (viewIndex > 0) {
      setViewIndex(viewIndex - 1);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const handleDeleteClick = () => {
    setViewIndex(DialogViews.DELETING);
  };
  const handleDuplicateClick = () => {
    queryParams.set(DUPLICATE_MODE_QUERY_PARAMS.transactionId, transactionId || "");
    queryParams.historyPush();
    dispatchAnalytics("button_click", {
      label: DuplicateDialogButtonLabelValues.DUPLICATE_JOURNAL_ENTRY
    });
  };
  const renderHeaderActionButtons = () => (
    <DialogHeaderActionButtons
      onDeleteClick={handleDeleteClick}
      onDuplicateClick={handleDuplicateClick}
      clearance={permission}
      hideDeleteButton={duplicateMode || !editMode}
      hideDuplicateButton={duplicateMode || !editMode}
    />
  );

  const renderActionPanelButtons = () => (
    <FormActionButtons
      clearance={permission}
      propsActionPanel={{
        editMode
      }}
      propsSubButton={{ onClick: _onBackdropClick }}
      propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
    />
  );

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }
    if (viewIndex === 2) {
      return "sideMenu";
    }
    if (viewIndex === 3 || viewIndex === 4) {
      return "contentOnly";
    }
    return "topPanel";
  }, [viewIndex, loadingDialogState]);

  const currentTitle = useMemo(() => {
    if (viewIndex === 0) {
      return dialogTitle;
    }

    if (viewIndex === 2) {
      return AppStrings.Owners.Screen.AddNewOwner;
    }

    if (viewIndex === 1) {
      return AppStrings.Owners.NewOwner.SelectOwner;
    }
    return "";
  }, [viewIndex]);

  const duplicateModeTitle = useMemo(
    () =>
      duplicateMode
        ? `${t(AppStrings.Owners.OwnerContribution.NewOwnerContribution)} (${t(AppStrings.Common.Duplicated)})`
        : "",
    [duplicateMode, t]
  );

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={duplicateModeTitle || currentTitle}
      width={getDialogFrameDimension("width", dialogWidth)}
      height={getDialogFrameDimension("height", dialogHeight)}
      renderView={renderView}
      numViews={5}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      RenderHeaderActionButtons={renderHeaderActionButtons}
      frameType={frameType}
      keepViewsMounted={false}
      sectionTitle={currentMenuItemTitle}
    />
  );
};

export default OwnerContribution;
