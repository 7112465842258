"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateFormats = void 0;
var DateFormats;
(function (DateFormats) {
    DateFormats["ISO_DATE_SERVER_FORMAT"] = "YYYY-MM-DD";
    DateFormats["THREE_LETTER_MONTH_AND_YEAR"] = "MMM YYYY";
    DateFormats["YEAR_MONTH_DAY_DOTS"] = "YYYY.MM.DD";
    DateFormats["SHORT_DATE"] = "l";
    DateFormats["SHORT_DATE_TIME"] = "l LT";
    DateFormats["THREE_LETTER_MONTH_AND_DATE"] = "MMM D";
    DateFormats["MONTH_AND_DATE_AND_YEAR"] = "MMMM D, YYYY";
    DateFormats["THREE_LETTER_MONTH_DATE_AND_TIME"] = "MMM Do LT";
    DateFormats["DAY_OF_WEEK"] = "dddd";
    DateFormats["YEAR_AND_MONTH"] = "YYYY-MM";
    DateFormats["YEAR_AND_QUARTER"] = "YYYY-Q";
    DateFormats["YEAR"] = "YYYY";
    DateFormats["AMERICAN_DATE"] = "MM/DD/YYYY";
    DateFormats["FULL_MONTH"] = "MMMM";
    DateFormats["SHORT_TIME"] = "h:mm A";
})(DateFormats || (exports.DateFormats = DateFormats = {}));
