import { makeStyles } from "@material-ui/styles";
import type { CSSProperties } from "react";
import React from "react";

import type { Theme } from "styles/defaultTheme";
import { TabButton } from "./tabButton";

export interface TabItem<T> {
  label: string;
  value: T;
  isDisabled?: boolean;
  dataCy?: string;
}

export type ControlledTabsGroupOnSelect<T> = (selectedValue: T) => void;

export interface ControlledTabsGroupProps<T> {
  items: Array<TabItem<T>>;
  selectedValue?: T;
  onSelect: ControlledTabsGroupOnSelect<T>;
  style?: CSSProperties;
}

function ControlledTabsGroup<T>({ items, selectedValue, onSelect, style }: ControlledTabsGroupProps<T>) {
  const { buttonGroup } = useStyles();

  return (
    <div className={buttonGroup} style={style}>
      {items.map((item, index) => (
        <TabButton
          key={`${item.value}_${index}`}
          isSelected={selectedValue === item.value}
          onClick={() => onSelect(item.value)}
          data-cy={item.dataCy}
        >
          {item.label}
        </TabButton>
      ))}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    buttonGroup: {
      display: "inline-flex",
      gap: 4,
      padding: 4,
      border: "1px solid",
      borderColor: theme.newPalette.other.divider,
      borderRadius: 4,
      flexGrow: 1
    }
  };
});

export { ControlledTabsGroup };
