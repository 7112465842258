import { useMemo } from "react";
import type { NavConfigProps } from "../types";
import { matchNavPath } from "../utils/matchNavPath";

interface UseSelectedMainItemKeyParams {
  menuLayers: Record<string, NavConfigProps>;
  pathToMatch: string;
}

export function useSelectedMainItemKey({ menuLayers, pathToMatch }: UseSelectedMainItemKeyParams) {
  return useMemo(() => {
    const matchedGroup = Object.values(menuLayers)
      .flatMap((config) => config.groups)
      .find((group) =>
        group.items.some((item) => item.navPath?.some((navPath) => matchNavPath(pathToMatch, navPath) && item.navKey))
      );

    if (matchedGroup?.parentNavigationKey) {
      return matchedGroup.parentNavigationKey;
    }

    return matchedGroup?.items.find((item) =>
      item.navPath?.some((navPath) => matchNavPath(pathToMatch, navPath) && item.navKey)
    )?.navKey;
  }, [menuLayers, pathToMatch]);
}
