import type { StyleVariables } from "styles/dynamicStyling/dynamicStyles.types";
import { cssVar } from "styles/dynamicStyling/dynamicStyles.types";

export const kendoStyleVariables: StyleVariables = {
  "primary-hsl": "216, 42%, 25%",
  "background-neutral-hsl": "227, 31%, 94%",
  "background-neutral-light-hsl": "228, 33%, 97%",
  "background-neutral-lightest-hsl": "0, 0%, 100%",
  primary: "hsl(var(--primary-hsl))",
  "background-neutral": "hsl(var(--background-neutral-hsl))",
  "background-neutral-light": "hsl(var(--background-neutral-light-hsl))",
  "background-neutral-lightest": "hsl(var(--background-neutral-lightest-hsl))",
  "border-primary-color": "hsl(var(--primary-hsl))",
  "border-primary-color-light": "hsl(var(--primary-hsl), 0.2)",
  "border-primary-color-lightest": "hsl(var(--primary-hsl), 0.1)",
  "border-primary-style": "1px solid",
  "border-primary": "var(--border-primary-style) var(--border-primary-color)",
  "border-primary-light": "var(--border-primary-style) var(--border-primary-color-light)",
  "border-primary-lightest": "var(--border-primary-style) var(--border-primary-color-lightest)",
  "reports-border-color": "hsla(217, 52%, 20%, 0.1)",
  "reports-border": "var(--border-primary-style) var(--reports-border-color)"
};

// Example with nesting support:

const kendoStyleVariablesWithNesting: StyleVariables = {
  "primary-hsl": "216, 42%, 25%",
  "background-neutral-hsl": "227, 31%, 94%",
  "background-neutral-light-hsl": "228, 33%, 97%",
  "background-neutral-lightest-hsl": "0, 0%, 100%",
  primary: `hsl(${cssVar("primary-hsl")})`,
  "background-neutral": `hsl(${cssVar("background-neutral-hsl")})`,
  "background-neutral-light": `hsl(${cssVar("background-neutral-light-hsl")})`,
  "background-neutral-lightest": `hsl(${cssVar("background-neutral-lightest-hsl")})`,
  "border-primary-color": `hsl(${cssVar("primary-hsl")})`,
  "border-primary-color-light": `hsl(${cssVar("primary-hsl")}, 0.2)`,
  "border-primary-color-lightest": `hsl(${cssVar("primary-hsl")}, 0.1)`,
  "border-primary-style": "1px solid",
  "border-primary": `${cssVar("border-primary-style")} ${cssVar("border-primary-color")}`,
  "border-primary-light": `${cssVar("border-primary-style")} ${cssVar("border-primary-color-light")}`,
  "border-primary-lightest": `${cssVar("border-primary-style")} ${cssVar("border-primary-color-lightest")}`,
  "reports-border-color": "hsla(217, 52%, 20%, 0.1)",
  "reports-border": `${cssVar("border-primary-style")} ${cssVar("reports-border-color")}`
};
