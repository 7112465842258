import { vendorBillsApi, vendorCreditsApi, vendorsApi } from "api/vendorsApi";
import { tenantsApi } from "api/tenantsApi";
import { ownersApi } from "api/ownersApi";
import { leaseChargeApi } from "api/leaseChargeApi";
import { leaseCreditApi } from "api/leaseCreditApi";
import { leasePaymentsApi } from "api/leasePaymentsApi";
import { leasesApi, leasesRecurringTransactionsApi } from "api/leasesApi";
import { expensesApi } from "api/expensesApi";
import { vendorBillPaymentsApi } from "api/outstandingTransactionsApi";
import { ownerContributionsApi } from "api/ownerContributionsApi";
import { notesApi } from "api/notesApi";
import { tasksApi } from "api/tasksApi";
import { unitsApi } from "api/unitsApi";
import { filesApi } from "api/filesApi";
import { propertiesApi } from "api/propertiesApi";
import { leaseRefundApi } from "api/leaseRefundApi";
import { leaseDraftApi } from "api/leasesDraftApi";
import { accountsApi } from "api/accounts";
import { tenantPortalRecurringPaymentsApi } from "api/tenantPortal/tenantPortalRecurringPaymentsApi";
import { tenantPortalRequestsApi } from "api/tenantPortal/tenantPortalRequestsApi";
import { tenantPortalInsuranceApi } from "api/tenantPortal/tenantPortalInsuranceApi";
import { leaseReversedPaymentsApi } from "api/leaseReversedPaymentsApi";
import { userRolesApi } from "api/userRolesApi";
import { rentalApplicationsApi } from "api/rentalApplicationsApi";
import { communicationsApi } from "api/communicationsApi";
import { announcementsApi } from "api/announcementsApi";
import { signatureRequestsApi } from "api/signatureRequestsApi";
import { signatureTemplatesApi } from "api/signatureTemplatesApi";
import { bankReconciliationApi } from "api/bankReconciliationApi";
import { outgoingPaymentsApi } from "api/outgoingPaymentsApi";
import { tenantPortalPaymentsApi } from "api/tenantPortal/tenantPortalPaymentsApi";
import { ownerPortalRequestsApi } from "api/ownerPortal/ownerPortalRequestsApi";
import { budgetsApi } from "api/budgetsApi";

export const apiMethodNameToAPIMap = {
  vendorsApi,
  tenantsApi,
  ownersApi,
  leaseChargeApi,
  leaseCreditApi,
  leasePaymentsApi,
  leasesRecurringTransactionsApi,
  vendorBillsApi,
  vendorCreditApi: vendorCreditsApi,
  expensesApi,
  vendorBillPaymentApi: vendorBillPaymentsApi,
  ownerContributionsApi,
  notesApi,
  tasksApi,
  unitsApi,
  filesApi,
  propertiesApi,
  leaseRefundApi,
  leaseDraftApi,
  accountsApi,
  tenantPortalRecurringPaymentsApi,
  tenantPortalRequestApi: tenantPortalRequestsApi,
  tenantPortalInsuranceApi,
  leaseReversedPaymentApi: leaseReversedPaymentsApi,
  leasesApi,
  userRolesApi,
  rentalApplicationsApi,
  announcementsApi,
  communicationsApi,
  signatureRequestsApi,
  signatureTemplatesApi,
  bankReconciliationApi,
  outgoingPaymentsApi,
  tenantPortalPaymentsApi,
  ownerPortalRequestsApi,
  budgetsApi
};

export type SupportedAPIMethods = keyof typeof apiMethodNameToAPIMap;
