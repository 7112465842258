import type { NavConfigProps } from "DLUI/drawer/types";

export const getMainMenuLayersFiltered = (navigationMenuLayers: Record<string, NavConfigProps>): NavConfigProps => {
  const mainMenuLayersGroups = navigationMenuLayers.MainMenuLayers.groups.map((group) => {
    const items = group.items.filter(({ subNavigationName }) => {
      if (!subNavigationName) {
        return true;
      }

      const navigationMenuLayer: NavConfigProps = navigationMenuLayers[subNavigationName];

      return navigationMenuLayer.groups.reduce((total, group) => group.items.length + total, 0) > 0;
    });

    return {
      ...group,
      items
    };
  });

  return {
    ...navigationMenuLayers.MainMenuLayers,
    groups: mainMenuLayersGroups
  };
};
