"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentReminderOverdueNotificationsFrequency = void 0;
var RentReminderOverdueNotificationsFrequency;
(function (RentReminderOverdueNotificationsFrequency) {
    RentReminderOverdueNotificationsFrequency["ONCE"] = "ONCE";
    RentReminderOverdueNotificationsFrequency["DAILY"] = "DAILY";
    RentReminderOverdueNotificationsFrequency["EVERY_3_DAYS"] = "EVERY_3_DAYS";
    RentReminderOverdueNotificationsFrequency["WEEKLY"] = "WEEKLY";
})(RentReminderOverdueNotificationsFrequency || (exports.RentReminderOverdueNotificationsFrequency = RentReminderOverdueNotificationsFrequency = {}));
