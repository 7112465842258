import type { AccountOpeningBalanceByPropertyInfo } from "@doorloop/dto";
import { AccountType, DisplayAccountDto } from "@doorloop/dto";
import { mathUtils } from "@doorloop/dto";
import type { FormikProps } from "formik";
import { getIn } from "formik";
import _ from "lodash";
import { createValidator } from "@doorloop/dto";

export const initFormValues = (): DisplayAccountDto => {
  const accountDto = new DisplayAccountDto();
  accountDto.type = AccountType.ASSET_BANK;

  return accountDto;
};

export const validateForm = createValidator(DisplayAccountDto);

export const validateAccountForm = async (
  formikRef: FormikProps<DisplayAccountDto>
): Promise<{ isValid: boolean; errorStepIndex?: number }> => {
  formikRef.setFieldTouched("bankRoutingNumber");
  formikRef.setFieldTouched("nextCheckNumber");
  formikRef.setFieldTouched("openingBalanceInfo.openingBalance");
  formikRef.setFieldTouched("name");

  const openingBalance = getIn(formikRef.values, "openingBalanceInfo.openingBalance");
  const openingBalanceDate = getIn(formikRef.values, "openingBalanceInfo.openingBalanceDate");

  if (openingBalance || openingBalanceDate) {
    let remainingBalance = openingBalance;
    const openingBalanceByProperty = getIn(formikRef.values, "openingBalanceInfo.openingBalanceByProperty");
    if (openingBalanceByProperty && openingBalanceByProperty.length > 0) {
      openingBalanceByProperty.forEach((currentItem: AccountOpeningBalanceByPropertyInfo, index) => {
        formikRef.setFieldTouched(`openingBalanceInfo.openingBalanceByProperty[${index}].openingBalance`);
        formikRef.setFieldTouched(`openingBalanceInfo.openingBalanceByProperty[${index}].property`);
        remainingBalance = mathUtils.substract(remainingBalance, currentItem.openingBalance || 0);
      });
    }

    if (remainingBalance !== 0 || openingBalanceByProperty.length === 0) {
      formikRef.setFieldValue("invalidOpeningBalanceByProperty", true);
      return { isValid: false, errorStepIndex: 1 };
    }
    formikRef.setFieldValue("invalidOpeningBalanceByProperty", false);
  }

  const errors = (await formikRef.validateForm()) as any;
  if (errors.name) {
    return { isValid: false, errorStepIndex: 0 };
  }

  if (errors.bankRoutingNumber || errors.nextCheckNumber) {
    return { isValid: false, errorStepIndex: 2 };
  }

  return { isValid: _.isEmpty(errors) };
};
