import { SearchPanel } from "DLUI/screen";
import FilterOptions from "../filterOptions";
import { ObjectPermission, type OutgoingPaymentsReportItemDto } from "@doorloop/dto";
import { View } from "DLUI/view";
import { DataList } from "DLUI/dataList";
import { outgoingPaymentsReportApi } from "api/outgoingPaymentsReportApi";
import { OutgoingPaymentsListItem } from "screens/outgoingPayments/outgoingPaymentsTransactions/outgoingPaymentsListItem";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import React from "react";
import { useScreenFilterObject } from "hooks/useScreenFilterObject";
import { useTypedTranslation } from "locale";
import EmptyListView from "screens/outgoingPayments/emptyListView";

export const OutgoingPaymentsTransactionsTab = () => {
  const { t } = useTypedTranslation();
  const { filterObject, screenProps } = useScreenFilterObject();

  return (
    <View noWrap fullWidth>
      <SearchPanel
        filterOptions={FilterOptions}
        searchPlaceHolderText={t("common.outgoingPayments.searchPayments")}
        {...screenProps}
      />
      <RestrictedPermissionAccess
        clearance={{
          permission: ObjectPermission.outgoingPayments,
          field: "viewList"
        }}
        showNoAccess
      >
        <DataList restApi={outgoingPaymentsReportApi} queryFilter={filterObject} emptyListView={EmptyListView} infinite>
          {(dto: OutgoingPaymentsReportItemDto, index) => <OutgoingPaymentsListItem dto={dto} key={index} />}
        </DataList>
      </RestrictedPermissionAccess>
    </View>
  );
};
