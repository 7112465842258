import React from "react";
import type { FormikProps } from "formik";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import Text from "DLUI/text";
import { tagsApi } from "api/tagsApi";
import { useTranslation } from "react-i18next";
import FormikCachedMultiSelectAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedMultiSelectAutoComplete";
import { TagType } from "@doorloop/dto";
import { accountsApi } from "api/accounts";

interface ComponentProps {
  formikRef: FormikProps<any>;
}

const Services: React.FC<ComponentProps> = ({ formikRef }: ComponentProps) => {
  const { t } = useTranslation();
  return (
    <View flex={1} width={"100%"} gap={20}>
      <Text value={AppStrings.Vendors.NewVendor.VendorClassificationsDescription} color={"black"} fontSize={16} />
      <FormikCachedMultiSelectAutoComplete
        uniqueIndex={"selectClassifications"}
        apiHandler={tagsApi}
        displayNameKey={"name"}
        filterFieldName={"filter_text"}
        filterFieldValue={"name"}
        selectionFields={["id", "class"]}
        name={"services"}
        label={t(AppStrings.Vendors.NewVendor.SelectClassifications)}
        errorLabelPaddingLeft={15}
        queryParams={{ filter_type: TagType.VENDOR_SERVICE }}
      />

      <Text value={AppStrings.Vendors.NewVendor.VendorCategoriesDescription} color={"black"} fontSize={16} />
      <FormikCachedMultiSelectAutoComplete
        uniqueIndex={"selectCategories"}
        apiHandler={accountsApi}
        displayNameKey={"name"}
        filterFieldName={"filter_text"}
        filterFieldValue={"name"}
        selectionFields={["id", "class", "type"]}
        name={"accounts"}
        defaultMode={"serverMode"}
        groupNameProp={"type"}
        queryParams={{
          filter_active: true
        }}
        label={t(AppStrings.Vendors.NewVendor.SelectCategories)}
      />
    </View>
  );
};

export default Services;
