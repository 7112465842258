"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyndicationILS_Unit = void 0;
const class_validator_1 = require("class-validator");
const syndicationUnitsType_1 = require("./syndicationUnitsType");
const syndicationDepositType_1 = require("./syndicationDepositType");
const syndicationAvailability_1 = require("./syndicationAvailability");
class SyndicationILS_Unit {
}
exports.SyndicationILS_Unit = SyndicationILS_Unit;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", syndicationUnitsType_1.SyndicationUnitsType)
], SyndicationILS_Unit.prototype, "Units", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", syndicationDepositType_1.SyndicationDepositType)
], SyndicationILS_Unit.prototype, "Deposit", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", syndicationAvailability_1.SyndicationAvailability)
], SyndicationILS_Unit.prototype, "Availability", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    __metadata("design:type", Array)
], SyndicationILS_Unit.prototype, "Amenity", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    __metadata("design:type", Array)
], SyndicationILS_Unit.prototype, "File", void 0);
