"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankAccountMerchantStatus = void 0;
var BankAccountMerchantStatus;
(function (BankAccountMerchantStatus) {
    BankAccountMerchantStatus["IDENTITY_VERIFICATION_REQUIRED"] = "IDENTITY_VERIFICATION_REQUIRED";
    BankAccountMerchantStatus["IDENTITY_VERIFICATION_FAILED"] = "IDENTITY_VERIFICATION_FAILED";
    BankAccountMerchantStatus["MERCHANT_ONBOARDING_INCOMPLETE"] = "MERCHANT_ONBOARDING_INCOMPLETE";
    BankAccountMerchantStatus["PENDING_UNDERWRITING_APPROVAL"] = "PENDING_UNDERWRITING_APPROVAL";
    BankAccountMerchantStatus["ACCEPTING_ONLINE_PAYMENTS"] = "ACCEPTING_ONLINE_PAYMENTS";
    BankAccountMerchantStatus["ACCOUNT_CLOSED"] = "ACCOUNT_CLOSED";
    BankAccountMerchantStatus["ACCOUNT_NOT_APPROVED"] = "ACCOUNT_NOT_APPROVED";
})(BankAccountMerchantStatus || (exports.BankAccountMerchantStatus = BankAccountMerchantStatus = {}));
