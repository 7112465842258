import { Routes } from "components/appRouter";
import { DialogRoutes } from "DLUI/screen/dialogsProvider/tenantPortal/dialogsRoutes";
import { historyPushPersistentSearch } from "./navigation";
import type {
  GetProfitAndLossReportQuery,
  InsuranceDialogOrigin,
  TenantPortalMerchantAccountDto,
  TenantPortalRecurringPaymentDto
} from "@doorloop/dto";
import { MerchantAccountPlatform, SegmentEventTypes } from "@doorloop/dto";
import { analyticsService, PosthogFeatureFlagKeysEnum } from "../services/analyticsService";
import AppStrings from "../locale/keys";
import qs from "query-string";
import type { TenantPortalScreens } from "@/types/Screens";
import type { ShowConfirmationHandler } from "@/hooks/useConfirmationDialog";
import { history } from "@/store/history";

interface TriggerPaymentInterface {
  leaseId: string;
  merchantAccountData?: TenantPortalMerchantAccountDto;
  autoPayData?: TenantPortalRecurringPaymentDto[];
  impersonationToken?: string;
  onLoading?: (boolean) => void;
  isNativeApp?: boolean;
  analyticsMetadata?: {
    ctaLocation?: "Card" | "Panel";
  };
  showConfirmationDialog?: ShowConfirmationHandler;
}

class TenantPortalNavigation {
  viewHome = (leaseId: string) => {
    history.push({
      pathname: Routes.TENANT_PORTAL_BASE_URL + "/" + leaseId + "/home"
    });
  };
  viewPayments = (leaseId: string) => {
    history.push({
      pathname: Routes.TENANT_PORTAL_BASE_URL + "/" + leaseId + "/payments"
    });
  };
  viewAnnouncements = (leaseId: string) => {
    history.push({
      pathname: `${Routes.TENANT_PORTAL_BASE_URL}/${leaseId}/announcements`
    });
  };
  viewAnnouncementDetails = (leaseId: string, announcementId: string) => {
    historyPushPersistentSearch({
      pathname: `${Routes.TENANT_PORTAL_BASE_URL}/${leaseId}/${DialogRoutes.VIEW_ANNOUNCEMENT}/${announcementId}`
    });
  };

  getImpersonationUrl = (leaseId: string, impersonationToken: string, returnRoute: string, transactionId?: string) => {
    const tenantPortalIndex = window.location.href.indexOf("/tenant-portal/");
    const protocolAndHost = window.location.href.substring(0, tenantPortalIndex);
    const _returnRoute = returnRoute.replace(":leaseId", leaseId);
    if (transactionId) {
      _returnRoute.replace(":transactionId", transactionId);
    }
    return `${protocolAndHost}/auth/login?tenant_portal_impersonation_token=${impersonationToken}&returnPath=${_returnRoute}`;
  };

  addNewPayment = ({
    leaseId,
    merchantAccountData,
    autoPayData,
    impersonationToken,
    onLoading,
    isNativeApp,
    analyticsMetadata,
    showConfirmationDialog
  }: TriggerPaymentInterface) => {
    onLoading?.(true);
    let stripeURL;

    if (impersonationToken) {
      stripeURL = this.getImpersonationUrl(leaseId, impersonationToken, Routes.TENANT_PORTAL_NEW_PAYMENT_WIZARD);
    }

    if (
      !merchantAccountData?.platform ||
      (!merchantAccountData.fees?.cardEnabled && !merchantAccountData.fees?.eCheckEnabled)
    ) {
      this.showMerchantAccountNotFound(leaseId);
    } else {
      let origin = location.pathname.split("/").pop();

      if (!origin) {
        origin = location.pathname.split("/").at(-2);
      }

      analyticsService.track(
        SegmentEventTypes.TENANTPORTAL_MAKE_A_PAYMENT_CLICKED,
        {
          origin,
          currentTestFlow: analyticsService.triggerAbTestingExperiment(
            PosthogFeatureFlagKeysEnum.TENANT_AUTO_PAY_PAYMENT_FLOW
          ),
          ...analyticsMetadata
        },
        { trackEventInIntercom: true }
      );

      if (autoPayData && Array.isArray(autoPayData) && autoPayData?.length) {
        const locationPath = Routes.TENANT_PORTAL_BASE_URL + "/" + leaseId + "/payments";
        const nextPath =
          locationPath.indexOf(DialogRoutes.CONFIRM) === -1 ? locationPath + DialogRoutes.CONFIRM : locationPath;
        const nextButtonPath =
          merchantAccountData.platform === MerchantAccountPlatform.STRIPE
            ? Routes.TENANT_PORTAL_NEW_PAYMENT_WIZARD.replace(":leaseId", leaseId)
            : Routes.TENANT_PORTAL_BASE_URL + "/" + leaseId + "/payments/" + DialogRoutes.PAYMENT;

        showConfirmationDialog?.(nextPath, {
          dialogTitle: AppStrings.Common.ActiveAutoPay,
          dialogSubTitle: AppStrings.Common.MakeAnotherPaymentQuestion,
          confirmButtonText: AppStrings.Common.MakeAnotherPayment,
          dismissButtonText: AppStrings.Common.ViewAutoPaySettings,
          nextRouterPath: stripeURL || nextButtonPath,
          dismissRouterPath: Routes.TENANT_PORTAL_BASE_URL + "/" + leaseId + "/payments/",
          showXButton: true,
          openInNewTab: Boolean(isNativeApp)
        });
      } else if (merchantAccountData.platform === MerchantAccountPlatform.PAYRIX_USD) {
        historyPushPersistentSearch({
          pathname: Routes.TENANT_PORTAL_BASE_URL + "/" + leaseId + "/payments/" + DialogRoutes.PAYMENT
        });
      } else if (merchantAccountData.platform === MerchantAccountPlatform.STRIPE) {
        if (stripeURL) {
          window.open(stripeURL, "_blank");
        } else {
          historyPushPersistentSearch({
            pathname: Routes.TENANT_PORTAL_NEW_PAYMENT_WIZARD.replace(":leaseId", leaseId)
          });
        }
      }
    }

    onLoading?.(false);
  };

  showMerchantAccountNotFound = (leaseId: string) => {
    historyPushPersistentSearch({
      pathname: Routes.TENANT_PORTAL_BASE_URL + "/" + leaseId + "/payments/" + DialogRoutes.MERCHANT_ACCOUNT_NOT_FOUND
    });
    analyticsService.track(SegmentEventTypes.TENANTPAYMENT_PAYMENT_UNAVAILABLE_POP_UP, undefined, {
      trackEventInIntercom: true
    });
  };

  addNewRequest = (leaseId: string, locationPath?: string, isHOAUser?: boolean) => {
    locationPath
      ? historyPushPersistentSearch({
          pathname: `${locationPath}/${DialogRoutes.REQUEST}`
        })
      : historyPushPersistentSearch({
          pathname: Routes.TENANT_PORTAL_BASE_URL + "/" + leaseId + "/requests/" + DialogRoutes.REQUEST
        });
    analyticsService.track(
      SegmentEventTypes.TENANT_PORTAL_CREATE_NEW_REQUEST_CLICKED,
      {
        userType: isHOAUser ? "Association Owner" : "Tenant"
      },
      { trackEventInIntercom: true }
    );
  };

  viewCashPaymentDetails = (
    leaseId: string,
    currentScreen: TenantPortalScreens.PAYMENTS | TenantPortalScreens.HOME
  ) => {
    historyPushPersistentSearch({
      pathname: `${Routes.TENANT_PORTAL_BASE_URL}/${leaseId}/${currentScreen}/${DialogRoutes.CASH_PAYMENTS_VIEW_PAYMENT_DETAILS}`
    });
  };

  addNewRecurringPayment = ({
    leaseId,
    merchantAccountData,
    autoPayData,
    impersonationToken,
    onLoading,
    isNativeApp,
    analyticsMetadata = {},
    showConfirmationDialog
  }: TriggerPaymentInterface) => {
    onLoading?.(true);
    let stripeURL;

    if (impersonationToken) {
      stripeURL = this.getImpersonationUrl(
        leaseId,
        impersonationToken,
        Routes.TENANT_PORTAL_NEW_RECURRING_PAYMENT_WIZARD
      );
    }

    if (
      !merchantAccountData?.platform ||
      (!merchantAccountData.fees?.cardEnabled && !merchantAccountData.fees?.eCheckEnabled)
    ) {
      this.showMerchantAccountNotFound(leaseId);
    } else {
      let origin = location.pathname.split("/").pop();

      if (!origin) {
        origin = location.pathname.split("/").at(-2);
      }

      analyticsService.track(
        SegmentEventTypes.TENANTPORTAL_MAKE_A_PAYMENT_CLICKED,
        {
          currentTestFlow: analyticsService.triggerAbTestingExperiment(
            PosthogFeatureFlagKeysEnum.TENANT_AUTO_PAY_PAYMENT_FLOW
          ),
          origin,
          ...analyticsMetadata
        },
        { trackEventInIntercom: true }
      );

      if (autoPayData && Array.isArray(autoPayData) && autoPayData?.length) {
        const locationPath = Routes.TENANT_PORTAL_BASE_URL + "/" + leaseId + "/payments";
        const nextPath =
          locationPath.indexOf(DialogRoutes.CONFIRM) === -1 ? locationPath + DialogRoutes.CONFIRM : locationPath;

        const nextButtonPath =
          merchantAccountData.platform === MerchantAccountPlatform.STRIPE
            ? Routes.TENANT_PORTAL_NEW_RECURRING_PAYMENT_WIZARD.replace(":leaseId", leaseId)
            : Routes.TENANT_PORTAL_BASE_URL + "/" + leaseId + "/payments/" + DialogRoutes.RECURRING_PAYMENT;

        showConfirmationDialog?.(nextPath, {
          dialogTitle: AppStrings.Common.ActiveAutoPay,
          dialogSubTitle: AppStrings.Common.MakeAnotherAutoPayQuestion,
          confirmButtonText: AppStrings.Common.SetupAnotherAutoPay,
          dismissButtonText: AppStrings.Common.ViewAutoPaySettings,
          nextRouterPath: stripeURL || nextButtonPath,
          dismissRouterPath: Routes.TENANT_PORTAL_BASE_URL + "/" + leaseId + "/payments/",
          showXButton: true,
          openInNewTab: Boolean(isNativeApp)
        });
      } else if (merchantAccountData.platform === MerchantAccountPlatform.PAYRIX_USD) {
        historyPushPersistentSearch({
          pathname: Routes.TENANT_PORTAL_BASE_URL + "/" + leaseId + "/payments/" + DialogRoutes.RECURRING_PAYMENT
        });
      } else if (merchantAccountData.platform === MerchantAccountPlatform.STRIPE) {
        if (stripeURL) {
          window.open(stripeURL, "_blank");
        } else {
          historyPushPersistentSearch({
            pathname: Routes.TENANT_PORTAL_NEW_RECURRING_PAYMENT_WIZARD.replace(":leaseId", leaseId)
          });
        }
      }
    }

    onLoading?.(false);
  };

  editRecurringPayment = (
    leaseId: string,
    transactionId: string,
    merchantAccountData?: TenantPortalMerchantAccountDto,
    impersonationToken?: string,
    onLoading?: (loading: boolean) => void,
    isNativeApp?: boolean
  ) => {
    onLoading?.(true);

    if (
      !merchantAccountData?.platform ||
      (!merchantAccountData.fees?.cardEnabled && !merchantAccountData.fees?.eCheckEnabled)
    ) {
      this.showMerchantAccountNotFound(leaseId);
    } else if (merchantAccountData.platform === MerchantAccountPlatform.PAYRIX_USD) {
      historyPushPersistentSearch({
        pathname:
          Routes.TENANT_PORTAL_BASE_URL +
          "/" +
          leaseId +
          "/payments/" +
          DialogRoutes.EDIT_RECURRING_PAYMENT +
          "/" +
          transactionId
      });
    } else if (merchantAccountData.platform === MerchantAccountPlatform.STRIPE) {
      if (isNativeApp) {
        let stripeURL;
        if (impersonationToken) {
          stripeURL = this.getImpersonationUrl(
            leaseId,
            impersonationToken,
            Routes.TENANT_PORTAL_EDIT_PAYMENT_WIZARD.replace(":leaseId", leaseId).replace(
              ":transactionId",
              transactionId
            ),
            transactionId
          );
        }
        window.open(stripeURL, "_blank");
      } else {
        historyPushPersistentSearch({
          pathname: Routes.TENANT_PORTAL_EDIT_PAYMENT_WIZARD.replace(":leaseId", leaseId).replace(
            ":transactionId",
            transactionId
          )
        });
      }
    }

    onLoading?.(false);
  };

  editRequest = (leaseId: string, requestId: string) => {
    historyPushPersistentSearch({
      pathname: Routes.TENANT_PORTAL_BASE_URL + "/" + leaseId + "/" + DialogRoutes.EDIT_REQUEST + "/" + requestId
    });
  };

  addNewInsurancePolicy = (
    leaseId: string,
    origin?: InsuranceDialogOrigin,
    showPromo = false,
    isUploadActivePolicy = false
  ) => {
    const path = Routes.TENANT_PORTAL_BASE_URL + "/" + leaseId + "/insurance/" + DialogRoutes.INSURANCE_POLICY;

    history.push(path, { origin, showPromo, isUploadActivePolicy });
  };

  editInsurancePolicy = (leaseId: string, policyId: string, origin?: InsuranceDialogOrigin) => {
    historyPushPersistentSearch({
      pathname:
        Routes.TENANT_PORTAL_BASE_URL +
        "/" +
        leaseId +
        "/insurance/" +
        DialogRoutes.EDIT_INSURANCE_POLICY +
        "/" +
        policyId,
      state: { origin }
    });
  };

  viewInsurance = (leaseId: string) => {
    historyPushPersistentSearch({
      pathname: Routes.TENANT_PORTAL_BASE_URL + "/" + leaseId + "/insurance"
    });
  };
  viewRequests = (leaseId: string) => {
    historyPushPersistentSearch({
      pathname: Routes.TENANT_PORTAL_BASE_URL + "/" + leaseId + "/requests"
    });
  };
  viewMyLease = () => {
    historyPushPersistentSearch({
      pathname: location.pathname + "/my-lease"
    });
  };

  editLoginAndPassword = (pathname: string) => {
    historyPushPersistentSearch({
      pathname: pathname + DialogRoutes.EDIT_LOGIN_AND_PASSWORD
    });
  };

  viewFinancials = (leaseId: string) => {
    history.push({
      pathname: Routes.TENANT_PORTAL_BASE_URL + "/" + leaseId + "/" + DialogRoutes.FINANCIALS
    });
  };

  viewInsurances = (leaseId: string) => {
    history.push({
      pathname: `${Routes.TENANT_PORTAL_BASE_URL}/${leaseId}/insurance`
    });
  };

  runAccountReceivableAgingSummaryReport(query: Record<string, string> = {}, leaseId?: string): void {
    leaseId &&
      historyPushPersistentSearch({
        pathname: Routes.TENANT_PORTAL_ACCOUNTS_RECEIVABLE_AGING_SUMMARY.replace(":leaseId", leaseId),
        search: qs.stringify(query)
      });
  }

  runProfitAndLossReport(query: GetProfitAndLossReportQuery = {}, leaseId?: string): void {
    const searchQuery = qs.stringify({
      filter_accountingMethod: "CASH",
      ...query
    });

    leaseId &&
      historyPushPersistentSearch({
        pathname: Routes.TENANT_PORTAL_PROFIT_AND_LOSS_REPORT.replace(":leaseId", leaseId),
        search: searchQuery
      });
  }

  runBalanceSheetReport(query: Record<string, string> = {}, leaseId?: string): void {
    const searchQuery = qs.stringify({
      filter_accountingMethod: "CASH",
      ...query
    });

    leaseId &&
      historyPushPersistentSearch({
        pathname: Routes.TENANT_PORTAL_BALANCE_SHEET_REPORT.replace(":leaseId", leaseId),
        search: searchQuery
      });
  }

  runCashFlowStatementReport(query: Record<string, string> = {}, leaseId?: string): void {
    const searchQuery = qs.stringify({
      filter_accountingMethod: "CASH",
      ...query
    });

    leaseId &&
      historyPushPersistentSearch({
        pathname: Routes.TENANT_PORTAL_CASH_FLOW_STATEMENT_REPORT.replace(":leaseId", leaseId),
        search: searchQuery
      });
  }

  viewContactInfo = (pathname: string) => {
    historyPushPersistentSearch({
      pathname: pathname + DialogRoutes.CONTACT_INFO
    });
  };

  showConfirmDialog = (locationPath: string) => {
    const nextPath =
      locationPath.indexOf(DialogRoutes.CONFIRM) === -1 ? locationPath + DialogRoutes.CONFIRM : locationPath;

    historyPushPersistentSearch(nextPath, {});
  };
}

export const tenantPortalNavigation = new TenantPortalNavigation();
