import React from "react";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, getIn } from "formik";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { TextField } from "DLUI/form";
import { useTranslation } from "react-i18next";
import { Routes } from "components/appRouter";
import DoorLoopUrlItem from "./doorLoopUrlItem";
import { OccupiedIcon, OwnersIcon, TenantsIcon } from "assets/icons";

interface ComponentProps {
  formikRef: FormikProps<any>;
}

const DoorLoopUrl: React.FC<ComponentProps> = ({ formikRef }: ComponentProps) => {
  const { t } = useTranslation();

  const renderUrls = () => {
    const subDomain = getIn(formikRef.values, "subdomain");
    const baseUrl = ".app.doorloop.com";
    if (subDomain) {
      const loginUrl = "https://" + subDomain + baseUrl + Routes.LOGIN;
      const tenantsLoginUrl = "https://" + subDomain + baseUrl + Routes.TENANTS_LOGIN;
      const ownersLoginUrl = "https://" + subDomain + baseUrl + Routes.OWNERS_LOGIN;
      return (
        <View>
          <DoorLoopUrlItem
            url={loginUrl}
            title={AppStrings.Settings.GeneralSettings.doorLoopURL.UsersLoginURL}
            icon={TenantsIcon}
          />
          <DoorLoopUrlItem
            url={tenantsLoginUrl}
            title={AppStrings.Settings.GeneralSettings.doorLoopURL.ClientPortalURL}
            icon={OccupiedIcon}
          />
          <DoorLoopUrlItem
            url={ownersLoginUrl}
            title={AppStrings.Settings.GeneralSettings.doorLoopURL.OwnerPortalURL}
            icon={OwnersIcon}
          />
        </View>
      );
    }
    return null;
  };
  return (
    <View flex={1}>
      <View>
        <Text
          fontSize={16}
          color={"black"}
          value={AppStrings.Settings.GeneralSettings.doorLoopURL.DoorLoopURLDescription}
          lineHeight={"26px"}
        />
        <View flexDirection={"row"}>
          <FastField
            component={TextField}
            label={t(AppStrings.Settings.GeneralSettings.doorLoopURL.Subdomain)}
            name={`subdomain`}
            marginTop={20}
            size={260}
            autoWidth
          />
          <Text marginTop={35} marginLeft={10} color={"black"} fontSize={16}>
            .app.doorloop.com
          </Text>
        </View>
        {renderUrls()}
      </View>
    </View>
  );
};

export default DoorLoopUrl;
