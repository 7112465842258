import DLButton from "DLUI/button/dlButton";
import { Icon } from "DLUI/icon";
import { SectionTitle } from "DLUI/screen";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import React from "react";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { VerificationFailedIcon } from "@/assets";
import AppStrings from "locale/keys";

interface Props {
  onBackdropClick: () => void;
}

const VerificationFailed: React.FC<Props> = ({ onBackdropClick }: Props) => {
  const { screenContainerPadding } = useResponsiveHelper();
  return (
    <View
      paddingLeft={screenContainerPadding}
      paddingRight={screenContainerPadding}
      alignItems={"center"}
      height={"100%"}
    >
      <Icon marginTop={30} Source={VerificationFailedIcon} />
      <SectionTitle
        type={"underline"}
        alignItems={"center"}
        title={AppStrings.Accounts.IdentityVerificationFailed}
        marginTop={15}
      />
      <Text
        value={AppStrings.Accounts.IdentityVerificationFailedDescription}
        lineHeight={"22px"}
        align={"center"}
        marginTop={20}
        maxWidth={450}
      />
      <Text value={AppStrings.Accounts.PleaseContactCustomerSupport} lineHeight={"22px"} marginTop={20} />
      <View flex={1} />
      <View alignItems={"center"} marginBottom={20}>
        <DLButton onClick={onBackdropClick} actionText={AppStrings.Common.Done} style={{ minWidth: 180 }} />
      </View>
    </View>
  );
};

export default VerificationFailed;
