import { useQueryClient, useInfiniteQuery } from "@tanstack/react-query";
import { DATA_LIST_QUERY_KEY } from "../constants";
import type { RefObject } from "react";
import { useCallback, useMemo } from "react";
import compact from "lodash/compact";
import type { GetAllBaseQueryResponse } from "@doorloop/dto";
import type { ApiResult } from "api/apiResult";

export function useDataListCurrentQueryRefetch<TData>() {
  const queryClient = useQueryClient();

  return {
    refetch: useCallback(async () => {
      await queryClient.refetchQueries([DATA_LIST_QUERY_KEY]);
    }, [queryClient])
  } as const;
}

export function useDataListCurrentQueryData<TData>(queryKeyRef: RefObject<{ queryKey: unknown[] }>) {
  const { data } = useInfiniteQuery<ApiResult<GetAllBaseQueryResponse<TData>>>({
    queryKey: queryKeyRef.current?.queryKey ?? [],
    refetchOnWindowFocus: false
  });

  return {
    allRows: useMemo(() => (data ? compact(data.pages.flatMap((d) => d.data?.data)) : []), [data])
  } as const;
}
