import type { FC } from "react";
import React, { useMemo, useState } from "react";
import AppStrings from "locale/keys";
import { View } from "DLUI/view";
import { TextField } from "@material-ui/core";
import { AlertIconCircle, AlertIconCircleBlue, CheckIconCircle } from "assets/icons";
import Text from "DLUI/text";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import type { PrintedCheck } from "@doorloop/dto";
import type { ImageButtonGroupProps } from "DLUI/form";
import { ImageButtonGroup } from "DLUI/form";
import { checksApi } from "api/checksApi";
import { NavigationManager } from "utils/navigation";
import { useTranslation } from "react-i18next";
import { Icon } from "DLUI/icon";
import qs from "query-string";
import { useLocation } from "react-router-dom";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";

interface ComponentsProps {
  onClose: () => void;
}

const numOfViews = 2;
const dialogWidth = 600;
const dialogHeight = 600;

const ChecksReviewPrintDialog: FC<ComponentsProps> = ({ onClose }: ComponentsProps) => {
  const { t } = useTranslation();
  const location = useLocation<any>();
  const [reviewDialogIndex, setReviewDialogIndex] = useState<number>(0);
  const [printSuccessful, setPrintSuccessful] = useState<boolean>(true);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);
  const [firstCheckNumber, setFirstCheckNumber] = useState<number>();
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const accountId = useMemo(() => {
    const { filter_payableInfoPayFromAccount } = qs.parse(window.location.search);
    return filter_payableInfoPayFromAccount && !Array.isArray(filter_payableInfoPayFromAccount)
      ? filter_payableInfoPayFromAccount
      : undefined;
  }, []);

  const printDoneSelection: ImageButtonGroupProps[] = [
    {
      label: t(AppStrings.Common.PrintChecks.Preview.Dialog.Done),
      icon: CheckIconCircle,
      value: "yes",
      isSelected: true
    },
    {
      label: t(AppStrings.Common.PrintChecks.Preview.Dialog.NotDone),
      icon: AlertIconCircle,
      value: "no",
      isSelected: false
    }
  ];

  const onClosePreviewDialog = () => {
    onClose();
  };

  const showErrorMessage = (message: string) => {
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(message);
  };

  const didPressPrintDone = async () => {
    if (!printSuccessful) {
      NavigationManager.printAccountChecks(accountId);
      return;
    }
    setReviewDialogIndex(1);
    setLoadingDialogState(DialogState.Show);
    let lastUnprintedCheckNumber = 0;
    const printedChecks: PrintedCheck[] = [];

    if (shouldShowFirstCheckNumberCheckbox()) {
      location.state?.checks.forEach((check, index) => {
        printedChecks.push({
          checkNumber: firstCheckNumber ? firstCheckNumber + index : index,
          journalEntry: check.journalEntry
        });
      });
    } else {
      location.state.checks.forEach((check, i) => {
        if (check.checkNumber) {
          location.state.checks[i].checkInformation.nextCheckNumber = parseInt(check.checkNumber);
        } else if (check.checkInformation.nextCheckNumber > lastUnprintedCheckNumber) {
          lastUnprintedCheckNumber = check.checkInformation.nextCheckNumber;
        }

        printedChecks.push({
          checkNumber: check.checkInformation ? check.checkInformation.nextCheckNumber : null,
          journalEntry: check.journalEntry
        });
      });
    }

    const postChecksResponse = await checksApi.postPrintedChecks({ printedChecks }).catch((e) => showErrorMessage(e));
    if (postChecksResponse && postChecksResponse.data) {
      if (lastUnprintedCheckNumber) {
        const putCheckNumberResponse = await checksApi
          .postPrintedChecksLastCheckNumber({
            account: accountId,
            nextCheckNumber: lastUnprintedCheckNumber + 1
          })
          .catch((e) => showErrorMessage(e));
        if (putCheckNumberResponse && putCheckNumberResponse.data) {
          NavigationManager.printAccountChecks(accountId);
        }
      }
      NavigationManager.printAccountChecks(accountId);
    } else {
      showErrorMessage(
        postChecksResponse && postChecksResponse.message
          ? postChecksResponse.message
          : t(AppStrings.Common.GeneralError)
      );
    }
  };

  const renderReviewDialogActionPanel = () => (
    <FormActionButtons
      propsSubButton={{ actionText: AppStrings.Common.PrintChecks.Preview.Dialog.Action, onClick: didPressPrintDone }}
    />
  );

  const shouldShowFirstCheckNumberCheckbox = () => {
    if (location.state.checks && location.state.checks.length > 0) {
      const lastCheckNumber = location.state.checks[0].checkInformation
        ? location.state.checks[0].checkInformation.nextCheckNumber +
          location.state.checks.filter((check) => !check.checkNumber).length
        : null;

      return !lastCheckNumber;
    }
    return true;
  };

  const didEnterFirstCheckNumber = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (e.target.value) {
      setFirstCheckNumber(Number(e.target.value));
    }
  };

  const didChooseOption = (val: string) => {
    setPrintSuccessful(val === "yes");
  };

  const frameType = useMemo(() => {
    if (reviewDialogIndex === 0) {
      return "sectionTitleFrame";
    }
    return "contentOnly";
  }, [reviewDialogIndex]);

  const renderReviewDialogView = ({ index }) => {
    if (index === 0) {
      return (
        <View marginTop={20}>
          <View paddingLeft={40}>
            <Text value={AppStrings.Common.PrintChecks.Preview.Dialog.Question} />
          </View>
          <View alignItems={"center"}>
            <View width={300}>
              {printDoneSelection && printDoneSelection.length > 0 ? (
                <ImageButtonGroup
                  uniqueId={"dependentsSelection"}
                  buttonItemsArray={printDoneSelection}
                  size={140}
                  marginRight={0}
                  flexDirection={"center"}
                  onSelect={didChooseOption}
                />
              ) : null}
            </View>
          </View>
          <View marginTop={20} paddingRight={40} paddingLeft={40}>
            {shouldShowFirstCheckNumberCheckbox() ? (
              <>
                <Text
                  marginBottom={20}
                  value={AppStrings.Common.PrintChecks.Preview.Dialog.CheckNumber}
                  fontSize={18}
                  fontWeight={700}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  onChange={didEnterFirstCheckNumber}
                  value={firstCheckNumber}
                  type={"number"}
                />
              </>
            ) : null}
            <View
              borderRadius={15}
              marginTop={20}
              flexDirection={"row"}
              paddingLeft={20}
              paddingRight={20}
              paddingTop={20}
              paddingBottom={20}
              backgroundColor={"reminder-blue"}
            >
              <View width={50}>
                <Icon Source={AlertIconCircleBlue} />
              </View>
              <View flex={1} paddingLeft={10}>
                <Text
                  textTransform={"uppercase"}
                  color={"lightBlue"}
                  value={AppStrings.Common.PrintChecks.Preview.Dialog.Reminder.Header}
                  fontSize={20}
                  fontWeight={700}
                />
                <Text color={"lightBlue"} value={AppStrings.Common.PrintChecks.Preview.Dialog.Reminder.Body} />
              </View>
            </View>
          </View>
        </View>
      );
    }
    if (index === 1) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            errorText={loadingDialogErrorText}
            dialogState={loadingDialogState}
            onRetryButtonPress={didPressPrintDone}
            didPressDismissButton={onClosePreviewDialog}
          />
        </View>
      );
    }

    return <div />;
  };

  return (
    <DialogFrame
      onCloseButtonClick={onClosePreviewDialog}
      width={dialogWidth}
      height={dialogHeight}
      renderView={renderReviewDialogView}
      numViews={numOfViews}
      activeView={reviewDialogIndex}
      RenderActionPanelButtons={renderReviewDialogActionPanel}
      frameType={frameType}
      title={AppStrings.Common.PrintChecks.Preview.Dialog.Header}
    />
  );
};

export default ChecksReviewPrintDialog;
