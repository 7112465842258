import type { PicturesApi } from "api/picturesApi";
import type { PictureDtoWithFile } from "shared/pictureGallery/types";
import type { OperationsBuilder } from "engines/bulkOperationsEngine";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

export const useOperationsBuilder = (
  picturesApi: PicturesApi,
  added: PictureDtoWithFile[],
  removed: PictureDtoWithFile[],
  changed: PictureDtoWithFile[]
): OperationsBuilder => {
  const { t } = useTranslation();

  return useCallback(
    (resourceId: string) => [
      ...added.map(({ file, rank }) => {
        return {
          ongoingMessage: AppStrings.BulkActions.UploadingPicture,
          action: async () => {
            if (file) {
              await picturesApi.addPicture(resourceId, file, { rank });
            }
            return { message: t(AppStrings.Common.OperationCompleted) };
          }
        };
      }),
      ...changed.map(({ fileId, rank }) => {
        return {
          ongoingMessage: AppStrings.BulkActions.ReorderingPicture,
          action: async () => {
            await picturesApi.updatePicture(resourceId, fileId || "", { rank });
            return { message: t(AppStrings.Common.OperationCompleted) };
          }
        };
      }),
      ...removed.map(({ fileId }) => {
        return {
          ongoingMessage: AppStrings.BulkActions.RemovingPicture,
          action: async () => {
            await picturesApi.deletePictureFromResource(resourceId, fileId || "");
            return { message: t(AppStrings.Common.OperationCompleted) };
          }
        };
      })
    ],
    [picturesApi, added, removed, changed, t]
  );
};
