import type { LeaseDto, LeaseTenantDto, TenantDto } from "@doorloop/dto";
import {
  ConversationLinkedToType,
  DataCy,
  DisplayLeaseDraftTenantDto,
  LeaseTenantStatus,
  ObjectPermission,
  PhoneType,
  PortalInfoDto
} from "@doorloop/dto";
import { propertiesApi } from "api/propertiesApi";
import { unitsApi } from "api/unitsApi";
import { CloseIcon, EditBlue, MoveOutBlueIcon, ResidentPortalIcon, ViewBlueIcon } from "assets/icons";
import _ from "lodash";
import { Grid, ListItemContainer, ListItemSection } from "DLUI/listItems";
import type { PopoverItem } from "DLUI/popover";
import { ProfileImage } from "DLUI/profileImage";
import Text from "DLUI/text";
import type { TextColor } from "DLUI/text/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { NavigationManager } from "utils/navigation";
import { usePermission } from "screens/settings/userRoles/usePermission";
import { leaseTenantsApi, tenantsApi } from "api/tenantsApi";
import { authApi } from "api/authApi";
import ListItemText from "DLUI/listItems/listItemText";
import { Routes } from "@/components/appRouter";
import type { SubscribedActions } from "hooks/useListBulkActionsManager";
import TenantListItemPortalStatus from "screens/tenants/tenantsList/tenantListItemPortalStatus";
import ListItemContactSection from "DLUI/listItems/listItemContactSection";
import { useResponsiveHelper } from "@/contexts/responsiveContext";
import { useUserType } from "@/hooks/useUserType";
import { BulkOperationSkipped } from "@/engines/bulkOperationsEngine";
import { useGrid } from "@/hooks/useGrid";
import { Chip, ChipColorsEnum, ChipVariantsEnum } from "DLUI/chip";
import { StepStatus } from "DLUI/stepStatus/stepStatus";
import { NOT_STARTED_STEPS, TENANTS_LIST_STATUS_STEPS_MAP } from "screens/tenants/tenantsList/tenantsListStatusSteps";
import { usePortalStatus } from "screens/tenants/tenantsList/usePortalStatus";
import { ListItemSubTitle } from "screens/tenants/listItemSubTitle";
import { useFocusElement } from "hooks/useFocusElement";
import { useDisableCta } from "screens/tenants/tenantDetails/useDisableCta";
import { sendWelcomeSmsAction } from "@/utils/bulkOperationsUtils";
import { useConfirmationDialog } from "@/hooks/useConfirmationDialog";

interface ComponentProps {
  tenant: LeaseTenantDto;
  leaseData?: LeaseDto;
  resourceId?: string;
  onItemPress?: (tenantId: string, selectedTenant?: LeaseTenantDto) => void;
  stickyHeaderId?: string;
  selectableItem?: boolean;
  disableListItemOptions?: boolean;
  disableListItemContactInfoEdit?: boolean;
  onTenantUpdated?: (tenant: TenantDto) => void;
  onSendRentReminder?: (leaseId: string) => void;
  lastSentReminder?: string;
  enableMoveOutOption?: boolean;
}

const LeaseTenantsListItem: React.FC<ComponentProps> = ({
  tenant,
  onItemPress,
  stickyHeaderId,
  selectableItem,
  disableListItemOptions,
  resourceId,
  disableListItemContactInfoEdit = false,
  onTenantUpdated,
  lastSentReminder,
  leaseData,
  onSendRentReminder,
  enableMoveOutOption
}: ComponentProps) => {
  const { pictureUrl, id, name, portalInfo, phones, emails } = tenant.tenant!;
  const { lease, units, property, balanceDue } = tenant;
  const { isMobile, isTabletOrMobile, isScreenSizeIn } = useResponsiveHelper();
  const location = useLocation<any>();
  const { hasPermission } = usePermission();
  const [propertyName, setPropertyName] = useState<string | undefined>();
  const { t } = useTranslation();
  const { isHOAUser } = useUserType();
  const { currentStatus } = usePortalStatus(tenant.tenant as TenantDto);
  const { actionDisableObj, updateActionObj } = useDisableCta();
  const { handleFocusElement, elementRef } = useFocusElement();
  const { showConfirmationDialog } = useConfirmationDialog();

  const isBalanceDue = Boolean(balanceDue && balanceDue > 0);

  useEffect(() => {
    getPropertyName();
  }, []);

  useEffect(() => {
    if (!tenant.tenant!.portalInfo) {
      tenant.tenant!.portalInfo = new PortalInfoDto();
    }

    if (!tenant.tenant!.portalInfo?.mobilePhone) {
      const mobilePhone = phones?.find((phone) => phone.type === PhoneType.MOBILE && phone.number);
      tenant.tenant!.portalInfo!.mobilePhone = mobilePhone?.number;
    }

    if (!tenant.tenant!.portalInfo?.loginEmail) {
      const loginEmail = emails?.find((email) => email.address);
      tenant.tenant!.portalInfo!.loginEmail = loginEmail?.address;
    }
  }, [tenant.tenant]);

  const getPropertyName = async () => {
    const propertiesDictonary = await propertiesApi.getDictionary();
    if (tenant.property) {
      let propertyName = propertiesDictonary[tenant.property].name;
      if (tenant.units && tenant.units.length == 1) {
        const unitDictionary = unitsApi.getItemFromDictionary(tenant.units[0]);
        if (unitDictionary) {
          propertyName += " > " + unitDictionary.name;
        }
      } else if (tenant.units && tenant.units.length > 1) {
        propertyName += " > " + t(AppStrings.Common.XUnits, { x: tenant.units.length });
      }
      setPropertyName(propertyName);
    }
  };

  const { statusTextColor, statusText } = useMemo(() => {
    let statusTextColor: ChipColorsEnum = ChipColorsEnum.INFO;
    let statusText = "";
    switch (tenant.status) {
      case LeaseTenantStatus.CURRENT:
        statusTextColor = ChipColorsEnum.SUCCESS;
        statusText = AppStrings.Common.Current;
        break;
      case LeaseTenantStatus.FUTURE:
        statusTextColor = ChipColorsEnum.WARNING;
        statusText = AppStrings.Common.Future;
        break;
      case LeaseTenantStatus.PAST:
        statusTextColor = ChipColorsEnum.NEUTRAL;
        statusText = AppStrings.Common.Past;
        break;
    }

    return { statusTextColor, statusText };
  }, []);

  const { balanceDueText, balanceDueColor } = useMemo(() => {
    let balanceDueText = "";
    let balanceDueColor: TextColor = "green";

    if (_.isNumber(balanceDue)) {
      balanceDueText = Math.abs(balanceDue).toString();
      balanceDueColor = isBalanceDue ? "error" : "green";
    }
    return {
      balanceDueText,
      balanceDueColor
    };
  }, [balanceDue]);

  const address = useMemo(() => {
    let address = "";
    if (property) {
      const propertyDictionaryObject = propertiesApi.getItemFromDictionary(property);
      address += propertyDictionaryObject?.name ?? "";
    }
    if (units && units?.length) {
      const unitDictionaryObject = unitsApi.getItemFromDictionary(units[0]);
      address += `${property ? " | " : ""}${unitDictionaryObject.name}`;
    }

    return address;
  }, [property, units]);

  const didPressViewProfile = hasPermission(ObjectPermission.tenants, "viewOne")
    ? () => {
        if (id) {
          if (onItemPress) {
            onItemPress(id, tenant);
          } else {
            NavigationManager.viewTenantDetails(id);
          }
        }
      }
    : undefined;

  const didPressMoveOutButton = () => {
    if (tenant?.tenant && leaseData) {
      const { id, name, pictureUrl } = tenant.tenant;
      const displayLeaseDraftTenantDto = new DisplayLeaseDraftTenantDto();
      displayLeaseDraftTenantDto.id = id;
      displayLeaseDraftTenantDto.name = name;
      displayLeaseDraftTenantDto.pictureUrl = pictureUrl;

      NavigationManager.moveOutTenant(location.pathname, {
        leaseData,
        defaultCurrentTenantsSelection: [displayLeaseDraftTenantDto]
      });
    }
  };

  const didPressDeleteButton = () => {
    if (id) {
      showConfirmationDialog(location.pathname, {
        dialogTitle: AppStrings.Common.DeleteConfirmation,
        dialogSubTitle: AppStrings.Tenants.Screen.DeleteConfirmationText,
        loadingText: t(AppStrings.Tenants.Screen.DeleteLoadingText),
        successText: t(AppStrings.Tenants.Screen.DeleteSuccessText),
        confirmButtonText: AppStrings.Common.Delete,
        dismissButtonText: AppStrings.Common.Dismiss,
        apiMethod: "tenantsApi",
        confirmOperation: "delete",
        itemId: id
      });
    }
  };

  const didPressViewTenantLease = hasPermission(ObjectPermission.leases, "viewOne")
    ? () => {
        if (lease) {
          NavigationManager.viewLeaseDetails(lease);
        }
      }
    : undefined;

  const didPressViewTenantDetails = hasPermission(ObjectPermission.tenants, "viewOne")
    ? () => {
        if (id) {
          if (onItemPress) {
            onItemPress(id, tenant);
          } else {
            NavigationManager.viewTenantDetails(id);
          }
        }
      }
    : undefined;

  const didPressEditButton = hasPermission(ObjectPermission.tenants, "edit")
    ? () => {
        if (id) {
          NavigationManager.editTenant(location.pathname.replace("owners", "tenants"), id);
        }
      }
    : undefined;

  const didPressLoginAsTenantButton = hasPermission(ObjectPermission.tenants, "edit")
    ? async () => {
        if (id && lease) {
          await authApi.getImpersonationTokenForTenantAndLeaseAndOpenNewTabToLogin(id, lease);
        }
      }
    : undefined;

  const deleteCardButton: PopoverItem = {
    Icon: CloseIcon,
    title: AppStrings.Common.Delete,
    onClick: didPressDeleteButton,
    iconPathColor: "error",
    textColor: "error",
    clearance: { permission: ObjectPermission.tenants, field: "delete" },
    dataCy: DataCy.DLUI.listItem.listItemDelete
  };

  const moveOutButton: PopoverItem = {
    Icon: MoveOutBlueIcon,
    title: AppStrings.Common.MoveOut,
    onClick: didPressMoveOutButton,
    clearance: { permission: ObjectPermission.tenants, field: "delete" }
  };

  const cardTopButtons: PopoverItem[] = [
    {
      Icon: EditBlue,
      title: AppStrings.Common.Edit,
      onClick: didPressEditButton,
      clearance: { permission: ObjectPermission.tenants, field: "edit" }
    },
    {
      Icon: ViewBlueIcon,
      title: AppStrings.Tenants.Screen.ViewProfile,
      onClick: didPressViewProfile,
      clearance: { permission: ObjectPermission.tenants, field: "viewOne" }
    },
    {
      Icon: ResidentPortalIcon,
      title: AppStrings.Tenants.Screen.LoginToPortal,
      onClick: didPressLoginAsTenantButton,
      clearance: { permission: ObjectPermission.tenants, field: "edit" }
    }
  ];

  const enableMoveOutButton = leaseData && !isHOAUser && enableMoveOutOption;
  if (enableMoveOutButton) {
    cardTopButtons.push(moveOutButton);
  }

  const enableDeleteCardButton = !leaseData || isHOAUser;
  if (enableDeleteCardButton) {
    cardTopButtons.push(deleteCardButton);
  }

  const bulkActions: SubscribedActions | undefined = resourceId
    ? {
        activateTenantPortal: {
          [resourceId]: {
            action: async () => {
              const result = await leaseTenantsApi.activateTenantPortal(tenant.tenant?.id!, true);
              if (result.statusCode === 503) {
                throw {
                  type: BulkOperationSkipped,
                  message: result.message
                };
              }
              if (!result.status) {
                throw t(AppStrings.Common.MissingInfo);
              }

              return { message: t(AppStrings.Common.InvitationSent) };
            },
            ongoingMessage: "Activating tenant portal "
          }
        },
        sendTenantWelcomeSms: {
          [resourceId]: {
            action: sendWelcomeSmsAction(ConversationLinkedToType.TENANT, id),
            ongoingMessage: t(AppStrings.BulkActions.SendWelcomeSmsOngoingMessage)
          }
        }
      }
    : undefined;

  const handleTenantUpdated = (data: TenantDto) => {
    onTenantUpdated?.(data);
  };

  const handleClickBalanceDue = () => {
    if (lease) {
      NavigationManager.viewLeaseTransactions(lease);
    }
  };

  const steps = TENANTS_LIST_STATUS_STEPS_MAP[currentStatus] || NOT_STARTED_STEPS;

  const {
    responsiveSizes: { balanceSize, actionSize, tenantSize, contactInfoSize, tenantPortalStatusSize }
  } = useGrid({
    tenant: { size: { tablet: 4, lg: 3, xl: 4, md: 4 } },
    contactInfo: { size: { xl: 2, lg: 2, md: 2, tablet: 3 } },
    balance: { size: { xl: 2, lg: 1, md: 2, tablet: 2 } },
    tenantPortalStatus: { size: { xl: 2, lg: 3 }, hideIn: ["tablet", "md"] },
    action: { size: { xl: 2, lg: 3, md: 4, tablet: 3 } }
  });

  return (
    <ListItemContainer
      popoverItems={disableListItemOptions ? undefined : cardTopButtons}
      id={stickyHeaderId}
      bulkActions={bulkActions}
      resourceId={resourceId}
      removePopoverMarginTop
    >
      <Grid
        onClick={didPressViewTenantDetails}
        title={AppStrings.Common.ReceivedFrom.TENANT}
        showDivider
        columnSize={tenantSize}
        sortColumn={"tenants.tenant.name"}
        paddingRight={isScreenSizeIn(["xl"]) ? 8 : 0}
        paddingLeft={isScreenSizeIn(["xl"]) ? 8 : 0}
      >
        <View flex={1} alignItems={"center"} flexDirection={"row"}>
          {(isTabletOrMobile || window.innerWidth > 1100) && (
            <ProfileImage marginLeft={isMobile ? 0 : 8} pictureUrl={pictureUrl} marginRight={8} />
          )}
          <ListItemSection
            renderTitle={
              <View
                alignItems={"center"}
                justifyContent={"flex-start"}
                noWrap
                paddingRight={5}
                flexDirection={"row"}
                gap={5}
              >
                <ListItemText
                  overflow={"ellipsis"}
                  autoWidth
                  numberOfLines={1}
                  href={tenant.tenant?.id ? `${Routes.TENANTS}/${tenant.tenant?.id}/overview` : undefined}
                >
                  {name}
                </ListItemText>
                <Chip
                  isRectangle
                  style={{ height: 22, padding: "0 8px" }}
                  variant={ChipVariantsEnum.DEFAULT}
                  color={statusTextColor}
                  labelProps={{
                    isBold: true,
                    translationKey: statusText
                  }}
                  removeTextPadding
                />
              </View>
            }
            renderSubTitle={
              <View justifyContent={"flex-start"} alignItems={"center"} flexDirection={"row"}>
                <Text overFlow={"ellipsis"} value={address} bold fontSize={14} />
              </View>
            }
          />
        </View>
      </Grid>
      <Grid
        paddingRight={isScreenSizeIn(["xl"]) ? 8 : 4}
        paddingLeft={isScreenSizeIn(["xl"]) ? 8 : 4}
        columnSize={contactInfoSize}
        title={AppStrings.Tenants.Screen.ContactInfo}
        showDivider
      >
        <ListItemContactSection
          disableEdit={disableListItemContactInfoEdit}
          onSaveSuccess={handleTenantUpdated}
          dto={tenant.tenant!}
          api={tenantsApi}
          shouldUpdatePortalInfo
          domRef={elementRef}
        />
      </Grid>
      <Grid
        paddingRight={isScreenSizeIn(["xl"]) ? 8 : 4}
        paddingLeft={isScreenSizeIn(["xl"]) ? 8 : 4}
        columnSize={balanceSize}
        title={isMobile ? "" : AppStrings.Common.Balance}
        showDivider
        sortColumn={"totalBalanceDue"}
        mobileMarginBottom={16}
        alignTitle={"end"}
        style={{
          marginTop: isMobile ? 16 : undefined
        }}
      >
        <View
          flexDirection={"row"}
          justifyContent={isMobile ? "space-between" : "flex-end"}
          onClick={handleClickBalanceDue}
        >
          {isMobile && <Text bold fontSize={14} value={AppStrings.Common.Balance} />}
          <Text value={balanceDueText} formatType={"currency"} color={balanceDueColor} fontSize={14} bold />
        </View>
      </Grid>

      <Grid
        paddingRight={8}
        paddingLeft={8}
        columnSize={tenantPortalStatusSize}
        title={AppStrings.Common.TenantPortalStatus}
        showDivider
        mobileMarginBottom={16}
        sortColumn={"tenantsPortalStatus"}
      >
        <StepStatus
          steps={steps}
          autoWidth
          useDarkTooltip
          dynamicConnectorWidth={{
            fromSize: 1280,
            toSize: 1919,
            width: 8
          }}
        />
      </Grid>

      <Grid
        paddingRight={8}
        paddingLeft={8}
        columnSize={actionSize}
        title={AppStrings.Common.Action}
        mobileMarginBottom={20}
      >
        <ListItemSection
          renderTitle={
            <TenantListItemPortalStatus
              tenant={tenant.tenant!}
              onInviteSent={handleTenantUpdated}
              onSendRentReminder={onSendRentReminder}
              onClickMissingContactInfo={handleFocusElement}
              isBalanceDue={isBalanceDue}
              ctaId={tenant.lease}
              onClickCta={updateActionObj}
              actionDisableObj={actionDisableObj}
              leaseId={lease}
            />
          }
          renderSubTitle={
            <ListItemSubTitle
              person={tenant.tenant!}
              onClick={selectableItem ? undefined : didPressEditButton}
              lastSentReminder={lastSentReminder}
              isBalanceDue={isBalanceDue}
            />
          }
        />
      </Grid>
    </ListItemContainer>
  );
};

export default LeaseTenantsListItem;
