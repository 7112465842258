const Notifications = {
  Title: "notifications.title",
  AdditionalInformation: "notifications.additionalInformation",
  LearnMore: "notifications.learnMore",
  Settings: {
    Title: "notifications.settings.title",
    Subtitle: "notifications.settings.subtitle",
    SaveSuccess: "notifications.settings.saveSuccess",
    Latest: "notifications.settings.latest",
    UnRead: "notifications.settings.unread",
    MarkAll: "notifications.settings.markAll",
    ActivitiesHeader: "notifications.settings.activitiesHeader",
    Tasks: "notifications.settings.tasks",
    Text: {
      Title: "notifications.settings.text.title",
      Description: "notifications.settings.text.description"
    },
    Push: {
      Title: "notifications.settings.push.title",
      Description: "notifications.settings.push.description"
    },
    Email: {
      Title: "notifications.settings.email.title",
      Description: "notifications.settings.email.description"
    },
    MerchantApplicationStatusUpdated: "notifications.settings.merchantApplicationStatusUpdated",
    PaymentReceived: "notifications.settings.paymentReceived",
    PaymentReturned: "notifications.settings.paymentReturned",
    UpcomingWeekAgenda: "notifications.settings.upcomingWeekAgenda",
    TaskAssigned: "notifications.settings.taskAssigned",
    TaskUpdated: "notifications.settings.taskUpdated",
    RentalApplicationSubmitted: "notifications.settings.rentalApplicationSubmitted",
    RentalApplicationReportsReady: "notifications.settings.rentalApplicationReportsReady",
    RentReminders: "notifications.settings.rentReminders",
    RentCharged: "notifications.settings.rentCharged",
    PaymentReceipt: "notifications.settings.paymentReceipt",
    AutopayCanceled: "notifications.settings.autopayCanceled",
    LateFeeCharged: "notifications.settings.lateFeeCharged",
    RequestReceived: "notifications.settings.requestReceived",
    RequestUpdated: "notifications.settings.requestUpdated",
    Announcement: "notifications.settings.announcement",
    BeforeLateFeeCharged: "notifications.settings.beforeLateFeeCharged",
    RentOverdueReminders: "notifications.settings.rentOverdueReminders",
    DisabledByAdmin: "notifications.settings.disabledByAdmin",
    DisabledByPropertyManager: "notifications.settings.disabledByPropertyManager",
    CommunicationCenterSetupSmsConfirmed: "notifications.settings.communicationCenterSetupSmsConfirmed",
    ConversationIncomingMessage: "notifications.settings.conversationIncomingMessage"
  }
};

export default Notifications;
