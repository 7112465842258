import { ServerRoutes } from "@doorloop/dto";
import type { GetAllLeaseChargesQuery, LeaseChargeDto } from "@doorloop/dto";
import { RestApiBase } from "./restApiBase";

export class LeaseChargeApi extends RestApiBase<LeaseChargeDto, GetAllLeaseChargesQuery> {
  constructor() {
    super(ServerRoutes.LEASE_CHARGES, []);
    this.restRoute = ServerRoutes.LEASE_CHARGES;
  }
}

export const leaseChargeApi = new LeaseChargeApi();
