import React from "react";
import { leasesRecurringTransactionsApi } from "api/leasesApi";
import { LeaseChargeDto } from "@doorloop/dto";
import { RecurringTransactionType } from "@doorloop/dto";
import AppStrings from "locale/keys";
import Transaction from "../transaction";
import { LinkedResourceType } from "@doorloop/dto";
import { ObjectPermission } from "@doorloop/dto";

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
}

const RecurringChargeDialog: React.FC<ComponentProps> = ({ onBackdropClick, onClose }: ComponentProps) => (
  <Transaction
    onBackdropClick={onBackdropClick}
    onClose={onClose}
    dialogTitle={AppStrings.Leases.LeaseCharge.RecurringCharge}
    apiMethod={leasesRecurringTransactionsApi}
    transactionDto={LeaseChargeDto.prototype}
    transactionCreatedText={AppStrings.Leases.LeaseTransaction.Charge.CreatedText}
    loadingTransactionDataText={AppStrings.Leases.LeaseTransaction.Charge.LoadingData}
    loadingTransactionDataSuccessText={AppStrings.Leases.LeaseTransaction.Charge.LoadingDataSuccess}
    updatingTransactionText={AppStrings.Leases.LeaseTransaction.Charge.Updating}
    transactionUpdatedText={AppStrings.Leases.LeaseTransaction.Charge.Updated}
    type={RecurringTransactionType.LEASE_CHARGE}
    linkedResourceType={LinkedResourceType.RecurringTransaction}
    permission={ObjectPermission.leaseCharges}
    accountFilterClass={"filter_leaseChargeItem"}
  />
);

export default RecurringChargeDialog;
