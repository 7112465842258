const Tasks = {
  Screen: {
    SearchPaneltitle: "tasks.screen.searchPaneltitle",
    SearchPlaceHolder: "tasks.screen.searchPlaceHolder",
    EmptydataViewInstructionsText: "tasks.screen.emptydataViewInstructionsText",
    EmptydataAddNew: "tasks.screen.emptydataAddNew",
    AddNewTask: "tasks.screen.addNewTask",
    Subtask: "tasks.screen.subtask",
    ViewProfile: "tasks.screen.viewProfile",
    ResidentPortalEnabled: "tasks.screen.residentPortalEnabled",
    ResidentPortalNotEnabled: "tasks.screen.residentPortalNotEnabled",
    MoveInTenant: "tasks.screen.moveInTenant",
    MoveOutTenant: "tasks.screen.moveOutTenant",
    Priorities: {
      Low: "tasks.screen.priorities.low",
      Medium: "tasks.screen.priorities.medium",
      High: "tasks.screen.priorities.high"
    },
    FilterOptions: {
      Completed: "tasks.screen.filterOptions.completed",
      NotCompleted: "tasks.screen.filterOptions.notCompleted",
      NotStarted: "tasks.screen.filterOptions.notStarted",
      InProgress: "tasks.screen.filterOptions.inProgress",
      Low: "tasks.screen.filterOptions.low",
      Medium: "tasks.screen.filterOptions.medium",
      High: "tasks.screen.filterOptions.high",
      AssignedToVendor: "tasks.screen.filterOptions.assignedToVendor",
      ApprovedByOwner: "tasks.screen.filterOptions.approvedByOwner",
      BillCreated: "tasks.screen.filterOptions.billCreated",
      NotApproved: "tasks.screen.filterOptions.notApproved",
      Approved: "tasks.screen.filterOptions.approved",
      Created: "tasks.screen.filterOptions.created",
      NotCreated: "tasks.screen.filterOptions.notCreated"
    },
    NewInternalTask: "tasks.screen.newInternalTask",
    NewTenantRequest: "tasks.screen.newTenantRequest",
    NewOwnerRequest: "tasks.screen.newOwnerRequest",
    NewWorkOrder: "tasks.screen.newWorkOrder",
    CompleteTask: "tasks.screen.completeTask",
    ReopenTask: "tasks.screen.reopenTask",
    CreatedAt: "tasks.screen.createdAt",
    CompletedAt: "tasks.screen.completedAt",
    RequestedByTenant: "tasks.screen.requestedByTenant",
    RequestedByOwner: "tasks.screen.requestedByOwner",
    RequestedBy: "tasks.screen.requestedBy",
    DueBefore: "tasks.screen.dueBefore",
    DueDate: "tasks.screen.dueDate",
    Date: "tasks.screen.date",
    HelpPanel: {
      Tasks: {
        Description: "tasks.screen.helpPanel.tasks.description",
        CreateTask: "tasks.screen.helpPanel.tasks.createTask",
        EditTask: "tasks.screen.helpPanel.tasks.editTask",
        CreateTaskVideo: "tasks.screen.helpPanel.tasks.createTaskVideo",
        EditTaskVideo: "tasks.screen.helpPanel.tasks.editTaskVideo",
        CreateDialogTitle: "tasks.screen.helpPanel.tasks.createDialogTitle",
        EditDialogTitle: "tasks.screen.helpPanel.tasks.editDialogTitle"
      },
      WorkOrders: {
        Description: "tasks.screen.helpPanel.workOrders.description",
        CreateOrder: "tasks.screen.helpPanel.workOrders.createOrder",
        UpdateOrder: "tasks.screen.helpPanel.workOrders.updateOrder",
        CreateOrderVideo: "tasks.screen.helpPanel.workOrders.createOrderVideo",
        DialogTitle: "tasks.screen.helpPanel.workOrders.dialogTitle"
      },
      RecurringTasks: {
        Description: "tasks.screen.helpPanel.recurringTasks.description",
        Create: "tasks.screen.helpPanel.recurringTasks.create",
        EditDelete: "tasks.screen.helpPanel.recurringTasks.editDelete",
        DialogTitle: "tasks.screen.helpPanel.recurringTasks.dialogTitle"
      }
    }
  },
  InternalTaskDialog: {
    NewTask: "tasks.internalTaskDialog.newTask",
    TaskInfo: "tasks.internalTaskDialog.taskInfo",
    WorkOrder: "tasks.internalTaskDialog.workOrder",
    Subject: "tasks.internalTaskDialog.subject",
    Priority: "tasks.internalTaskDialog.priority",
    Assignees: "tasks.internalTaskDialog.assignees",
    RelatedTo: "tasks.internalTaskDialog.relatedTo",
    WorkOrderDescriptionTitle: "tasks.internalTaskDialog.workOrderDescriptionTitle",
    WorkOrderDescriptionSubTitle: "tasks.internalTaskDialog.workOrderDescriptionSubTitle",
    AddWorkOrder: "tasks.internalTaskDialog.addWorkOrder",
    CreatingTask: "tasks.internalTaskDialog.creatingTask",
    UpdatingTask: "tasks.internalTaskDialog.updatingTask",
    LoadingTaskData: "tasks.internalTaskDialog.loadingTaskData",
    UpdateTaskSuccessText: "tasks.internalTaskDialog.updateTaskSuccessText",
    CreateTaskSuccessText: "tasks.internalTaskDialog.createTaskSuccessText",
    EditTask: "tasks.internalTaskDialog.editTask",
    EditRecurringTask: "tasks.internalTaskDialog.editRecurringTask",
    LoadingTaskDataSuccessText: "tasks.internalTaskDialog.loadingTaskDataSuccessText"
  },
  TasksList: {
    OwnerRequest: "tasks.tasksList.ownerRequest",
    InternalTask: "tasks.tasksList.internalTask",
    TenantRequest: "tasks.tasksList.tenantRequest",
    Task: "tasks.tasksList.task",
    assignedTo: "tasks.tasksList.assignedTo",
    DueAt: "tasks.tasksList.dueAt",
    NotSet: "tasks.tasksList.notSet",
    EditTask: "tasks.tasksList.editTask",
    ViewTask: "tasks.tasksList.viewTask",
    DeleteTask: "tasks.tasksList.deleteTask",
    DeleteConfirmationText: "tasks.tasksList.deleteConfirmationText",
    DeleteTaskLoadingText: "tasks.tasksList.deleteTaskLoadingText",
    DeleteTaskSuccessText: "tasks.tasksList.deleteTaskSuccessText",
    ReviewTask: "tasks.tasksList.reviewTask"
  },
  OwnerRequestDialog: {
    NewOwnerRequest: "tasks.ownerRequestDialog.newOwnerRequest",
    EditOwnerRequest: "tasks.ownerRequestDialog.editOwnerRequest",
    SelectOwnerValidationText: "tasks.ownerRequestDialog.selectOwnerValidationText"
  },
  TenantRequestDialog: {
    NewTenantRequest: "tasks.tenantRequestDialog.newTenantRequest",
    EditTenantRequest: "tasks.tenantRequestDialog.editTenantRequest",
    PermissionToEnter: "tasks.tenantRequestDialog.permissionToEnter",
    EntryContact: "tasks.tenantRequestDialog.entryContact",
    EntryNotes: "tasks.tenantRequestDialog.entryNotes",
    SelectTenantValidationText: "tasks.tenantRequestDialog.selectTenantValidationText"
  },
  WorkOrderDialog: {
    DescriptionFieldLabel: "tasks.workOrderDialog.descriptionFieldLabel",
    ApprovedByOwner: "tasks.workOrderDialog.approvedByOwner",
    EditWorkOrder: "tasks.workOrderDialog.editWorkOrder",
    EditRecurringWorkOrder: "tasks.workOrderDialog.editRecurringWorkOrder",
    SelectVendorValidationText: "tasks.workOrderDialog.selectVendorValidationText",
    PartsAndLabors: "tasks.workOrderDialog.partsAndLabors",
    PartsAndLaborsDescription: "tasks.workOrderDialog.partsAndLaborsDescription",
    Quantity: "tasks.workOrderDialog.quantity",
    Price: "tasks.workOrderDialog.price",
    PartsAndLaborsItemTitle: "tasks.workOrderDialog.partsAndLaborsItemTitle"
  },
  WorkOrdersScreen: {
    SearchPlaceHolder: "tasks.workOrdersScreen.searchPlaceHolder",
    EmptydataViewInstructionsText: "tasks.workOrdersScreen.emptydataViewInstructionsText",
    Instructions: "tasks.workOrdersScreen.instructions"
  },
  TaskDetails: {
    SubTasks: "tasks.taskDetails.subTasks",
    NewSubTask: "tasks.taskDetails.newSubTask",
    PostUpdate: "tasks.taskDetails.postUpdate",
    Message: "tasks.taskDetails.message",
    NotifyTenant: "tasks.taskDetails.notifyTenant",
    NotifyOwner: "tasks.taskDetails.notifyOwner",
    NotifyAssignees: "tasks.taskDetails.notifyAssignees"
  }
};

export default Tasks;
