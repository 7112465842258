import clsx from "clsx";

import { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "../enums";
import { useStylesContainer, useStylesIcon, useStylesLoadingIcon, useStylesText } from "./styles";

interface UseStylesButtonClassNames {
  container?: string;
  text?: string;
  icon?: string;
  loadingIcon?: string;
}

export interface UseStylesButtonProps {
  variant?: DLButtonVariantsEnum;
  size?: DLButtonSizesEnum;
  color?: DLButtonColorsEnum;
  keepColorIconStart?: boolean;
  keepColorIconEnd?: boolean;
  classNames?: UseStylesButtonClassNames;
}

const useStylesButton = ({
  variant = DLButtonVariantsEnum.CONTAINED,
  size = DLButtonSizesEnum.MEDIUM,
  color = DLButtonColorsEnum.PRIMARY,
  keepColorIconStart = false,
  keepColorIconEnd = false,
  classNames = {}
}: UseStylesButtonProps) => {
  const classesContainer = useStylesContainer();
  const classesText = useStylesText();
  const classesIcon = useStylesIcon();
  const classesLoadingIcon = useStylesLoadingIcon();

  const classKey = `${variant}_${color}`;

  return {
    container: clsx([
      classNames?.container,
      classesContainer.root,
      classesContainer[size],
      classesContainer[variant],
      classesContainer[classKey]
    ]),
    text: clsx([classNames?.text, classesText[size], classesText.color]),
    iconStart: clsx([classNames?.icon, classesIcon[size], !keepColorIconStart && classesIcon[classKey]]),
    iconEnd: clsx([classNames?.icon, classesIcon[size], !keepColorIconEnd && classesIcon[classKey]]),
    loadingIcon: clsx([classNames?.loadingIcon, classesLoadingIcon.root])
  };
};

export default useStylesButton;
