import { useQueryClient } from "@tanstack/react-query";
import { SCREEN_WIDGET_KEY } from "@/components/DLUI/screenWidgets/screenWidget";
import { useCallback } from "react";

export function useWidgetsCurrentQueryRefetch() {
  const queryClient = useQueryClient();

  return {
    refetch: useCallback(async () => {
      await queryClient.refetchQueries([SCREEN_WIDGET_KEY]);
    }, [queryClient])
  } as const;
}
