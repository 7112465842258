"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.conversationMessageAttachmentsValidationMap = exports.mmsSupportedMimeTypes = exports.MMS_MAX_FILES_SIZE = exports.MMS_MAX_FILES_COUNT = void 0;
const conversationMessageType_1 = require("./conversationMessageType");
exports.MMS_MAX_FILES_COUNT = 10;
exports.MMS_MAX_FILES_SIZE = 5 * 1024 * 1024; // 5MB
exports.mmsSupportedMimeTypes = [
    "text/plain",
    "text/vcard",
    "image/jpeg",
    "image/png",
    "image/gif",
    "video/3gpp",
    "video/mp4"
];
exports.conversationMessageAttachmentsValidationMap = {
    [conversationMessageType_1.ConversationMessageType.MMS]: {
        maxFilesCount: exports.MMS_MAX_FILES_COUNT,
        maxFilesSize: exports.MMS_MAX_FILES_SIZE,
        supportedMimeTypes: exports.mmsSupportedMimeTypes
    }
};
