/* eslint-disable array-callback-return */

import DLButton from "DLUI/button/dlButton";
import sortedIndexBy from "lodash/sortedIndexBy";
import React, { useEffect, useMemo, useState } from "react";

import { entityApiStore } from "@/api/entityApiStore/entityApiStore";
import {
  EmailType,
  IntroPersonDto,
  PersonTypeEnum,
  ProspectInfoDto,
  ProspectStatus,
  ServerResponseErrorCode,
  createValidator
} from "@doorloop/dto";
import { FormLabel, Grid } from "@material-ui/core";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { TextField } from "DLUI/form";
import { Form, FormikProvider, useFormik } from "formik";
import { setAutoFreeze } from "immer";
import { useTypedTranslation } from "locale/useTypedTranslation";
import { store } from "store";
import { handleToast } from "store/toast/actions";
import { useContextSelector } from "use-context-selector";
import { useIsMounted } from "usehooks-ts";
import { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "../../../button/dlButton/enums";
import { nullEvent } from "../autoCompleteTyped/autoComplete.utils";
import { defaultPersonQueryFilter } from "./peopleAutoComplete.hooks";
import { narrowPersonDto, personTypeToTranslationKeysMap } from "./peopleAutoComplete.utils";
import { PeopleAutoCompleteContext } from "./peopleAutoCompleteContext";

import type { NarrowedPersonDto, PersonDtoType } from "@doorloop/dto";
import type { FormikHelpers } from "formik";
import type { CSSProperties } from "react";
import type { UseOptimisticCreateOptions } from "../../../../../api/entityApiStore/createMutationStore.types";
import type { AutoCompleteOption } from "../autoCompleteTyped/autoComplete.types";

const validateIntroPerson = createValidator(IntroPersonDto);

const buttonGroupStyle: CSSProperties = {
  display: "flex",
  marginLeft: "auto",
  flexDirection: "row-reverse", // A workaround to get the Submit button to focus before the Cancel button
  gap: 8
};

function validateForm(
  initialValues: IntroPersonDto,
  values: IntroPersonDto,
  formikHelpers: FormikHelpers<IntroPersonDto>,
  personType: PersonTypeEnum
) {
  Object.keys(initialValues).forEach((key) => formikHelpers.setFieldTouched(key, true, false));

  const errors = validateIntroPerson(values, {
    validator: { context: { type: personType } }
  });
  formikHelpers.setErrors(errors);

  return { errors, isValid: Object.keys(errors).length === 0 };
}

export interface AddNewPersonFormProps {
  personType: PersonTypeEnum;
  numPersonTypes: number;
  onCancel: () => void;
}

export const AddNewPersonForm = ({ personType, numPersonTypes, onCancel }: AddNewPersonFormProps) => {
  const { t } = useTypedTranslation();

  const dispatchChange = useContextSelector(PeopleAutoCompleteContext, (context) => context.dispatchChange);
  const getOptionLabel = useContextSelector(PeopleAutoCompleteContext, (context) => context.getOptionLabel);
  const setIsOpen = useContextSelector(PeopleAutoCompleteContext, (context) => context.setIsOpen);

  const autoCompleteValue = useContextSelector(PeopleAutoCompleteContext, (context) => context.value);
  const autoCompleteOptions = useContextSelector(PeopleAutoCompleteContext, (context) => context.options);
  const autoCompleteMeta = useContextSelector(PeopleAutoCompleteContext, (context) => context.meta);

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = useMemo(() => {
    return {
      type: personType,
      firstName: "",
      lastName: "",
      email: "",
      ...(personType === PersonTypeEnum.TENANT && {
        prospectInfo: new ProspectInfoDto({
          status: ProspectStatus.NEW,
          // The `interests` field is not actually required, but we provide it
          // for consistency with the happy path of creating a new Prospect.
          interests: []
        })
      })
    };
  }, [personType]);

  const isMounted = useIsMounted();

  const mutationOptions = useMemo(<TDto extends PersonDtoType>(): UseOptimisticCreateOptions<TDto> => {
    return {
      paginated: true,
      queryFilter: defaultPersonQueryFilter,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onMutateHook: ({ item: newPersonDto, mutationPromise, prevState: _prevState }) => {
        setIsLoading(true);
        store.dispatch(
          handleToast({
            severity: "success",
            translationKey: "common.savingEllipsis"
          })
        );

        // We are updating the cache of a Person API optimistically; While we can
        // update the index of a new person in the cache with simple logic, we
        // can't can't do the same for the actual list of options, because it
        // consists of several groups, which are grouped by `type`, so we have to
        // use binary-search to find it.
        const narrowedPersonDto = narrowPersonDto(
          newPersonDto,
          personType,
          autoCompleteMeta?.orderDictionary[personType]
        );

        // We set `label` to satisfy TS, but it will be overridden later, so we
        // can pass the whole option to `getOptionLabel`. We also set `index` for
        // the same reason.
        const createdOption: AutoCompleteOption<NarrowedPersonDto> = {
          index: -1,
          label: "",
          avatarSrc: narrowedPersonDto.pictureUrl,
          data: narrowedPersonDto
        };
        createdOption.label = getOptionLabel?.(createdOption) || createdOption.label;

        // Use binary search to find the insert _index_ so the new person will
        // appear at the top of its group. Mind that `sortedIndexBy` works with
        // numeric values, it may return unexpected results with anything else.
        // This relies on the fact that the items are sorted in descending order
        // by the `createdAt` field.
        // https://lodash.com/docs/4.17.15#sortedIndexBy
        // https://github.com/lodash/lodash/blob/main/src/.internal/baseSortedIndexBy.ts
        const insertionIdx = autoCompleteOptions.length
          ? sortedIndexBy(autoCompleteOptions, createdOption, (person) => person.data?.orderIndex)
          : 0;
        createdOption.index = insertionIdx;

        dispatchChange(
          nullEvent,
          createdOption,
          "create-option",
          {
            option: createdOption,
            hasError: false
          },
          mutationPromise
        );
        setIsOpen(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onErrorHook: (error, _variables, _context) => {
        // Set the AutoComplete value to `null` or its previous value and open the dropdown
        const prevAutoCompleteValue = autoCompleteValue;
        dispatchChange(nullEvent, prevAutoCompleteValue, prevAutoCompleteValue === null ? "clear" : "select-option", {
          option: prevAutoCompleteValue as AutoCompleteOption<NarrowedPersonDto>,
          hasError: true,
          errorText: t("common.autoComplete.creatable.failedToCreate", {
            value: t(personTypeToTranslationKeysMap[personType].titleSingular)
          })
        });
        setIsOpen(true);

        // TODO this may fail to show the Toast since the server is not returning
        // a unified API error response for every error (tech-debt).
        store.dispatch(
          handleToast({
            severity: "error",
            translationKey:
              error.errorCode === ServerResponseErrorCode.VALIDATION_FAILED
                ? t("common.validation.failed")
                : t("common.generalError")
          })
        );
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSettledHook: (_data, _error, _variables, _context) => {
        if (isMounted()) {
          setIsLoading(false);
        }
      }
    };
  }, [
    autoCompleteMeta?.orderDictionary,
    autoCompleteValue,
    autoCompleteOptions,
    dispatchChange,
    getOptionLabel,
    isMounted,
    setIsOpen,
    personType,
    t
  ]);

  const creatorHooks = {
    vendor: entityApiStore.vendors.mutations.useOptimisticCreate(mutationOptions),
    owner: entityApiStore.owners.mutations.useOptimisticCreate(mutationOptions),
    tenant: entityApiStore.tenants.mutations.useOptimisticCreate(mutationOptions)
  };

  function handleSubmit(values: IntroPersonDto, formikHelpers: FormikHelpers<IntroPersonDto>) {
    if (!validateForm(initialValues, values, formikHelpers, personType).isValid) {
      return;
    }

    const { email, type: _type, ...restValues } = values;
    const emailDto = [{ type: EmailType.PRIMARY, address: email }];

    const newPersonDto: PersonDtoType = {
      ...restValues,
      ...(email && { emails: emailDto }),
      // TODO The `active` field is required in Owner DTO, but the server
      // `validateBody` middleware doesn't catch this. Something else,
      // probably within the `ownerRepository.save()` method does. TODO Fix
      // an (uncaught?) exception in `isPersonPhoneOrEmailArrayNotEmpty` that
      // gets triggered when we try to create an owner with an email DTO
      // that has a blank address (and active field is set to `false`).
      active: true // We should consider adding this field to `PersonDto`.
    };

    // Prevent default Toast message and show a "Saving..." toast on mutate
    // event (see `onMutate`) instead.
    creatorHooks[personType].mutateAsync({
      item: newPersonDto,
      options: {
        toasts: { isHidden: true }
      }
    });
  }

  useEffect(() => {
    // Disable Immer freezing for the duration of this component
    setAutoFreeze(false);
    return () => setAutoFreeze(true);
  }, []);

  // Using `useFormik` instead of `<Formik />` directly fixes an issue where on
  // any `change` event after an initial submit with empty values, other fields
  // are losing their validation state. In addition, it scales better, because
  // we have `formik` in above the render scope.
  const formik = useFormik<IntroPersonDto>({
    initialValues,
    onSubmit: handleSubmit,
    validate: (values) => validateForm(initialValues, values, formik, personType).errors
  });

  return (
    <FormikProvider value={formik}>
      <Form style={{ width: "100%" }} noValidate>
        <FormLabel style={{ fontSize: 14, fontWeight: "bold", color: "rgba(24, 44, 76, 1)" }}>
          {t(numPersonTypes === 1 ? "common.addNew" : personTypeToTranslationKeysMap[personType].creatableTitle)}
        </FormLabel>
        <Grid container spacing={2} style={{ marginTop: 8 }}>
          <Grid item xs={6}>
            {/* The `TextField` component should remain small (`height: 40px`), but it's too difficult to override */}
            <FastFieldSafe
              name="firstName"
              label={t("common.form.fields.firstName")}
              component={TextField}
              useFormikOnBlur={false}
              autoFocus
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FastFieldSafe
              name="lastName"
              label={t("common.form.fields.lastName")}
              component={TextField}
              useFormikOnBlur={false}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FastFieldSafe
              name="email"
              label={t("common.form.fields.email")}
              component={TextField}
              useFormikOnBlur={false}
              required={personType !== PersonTypeEnum.VENDOR}
            />
          </Grid>
          <Grid item xs={12}>
            <div style={buttonGroupStyle}>
              <DLButton
                size={DLButtonSizesEnum.MEDIUM}
                actionText={t("common.save")}
                isLoading={isLoading}
                tabIndex={0}
                style={{ minWidth: 90 }}
              ></DLButton>
              <DLButton
                variant={DLButtonVariantsEnum.TEXT}
                color={DLButtonColorsEnum.NEUTRAL}
                size={DLButtonSizesEnum.MEDIUM}
                actionText={t("common.cancel")}
                onClick={onCancel}
                disabled={isLoading}
                tabIndex={0}
                style={{ minWidth: 90 }}
              />
            </div>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export const AddNewPersonFormMemo = React.memo(AddNewPersonForm);
