import { useQuery } from "@tanstack/react-query";
import { usersApi } from "api/usersApi";
import { compact } from "lodash";
import { createQueryStoreWithDictionary } from "../createDictionaryQueryStore";
import { createMutationStore } from "../createMutationStore";
import isEmpty from "lodash/isEmpty";

export const usersStore = {
  queries: {
    ...createQueryStoreWithDictionary("users", usersApi),
    useSearchFromDictionary: (userIds?: string[]) =>
      useQuery(
        ["users-query-search-from-dictionary", userIds],
        async ({ signal }) => {
          if (!userIds) return null;

          const users = compact(
            await Promise.all(userIds.map(async (userId) => await usersApi.getFromDictionaryOrFetch(userId)))
          );

          if (!users || signal?.aborted) return null;

          return users;
        },
        { enabled: !isEmpty(userIds) }
      )
  },
  mutations: createMutationStore("users", usersApi)
} as const;
