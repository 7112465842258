"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyndicationApartmentsComRHPAmenityType = exports.SyndicationApartmentsComRHPAmenityTypeEnum = void 0;
const class_validator_1 = require("class-validator");
const syndicationBaseSpecificAmenityType_1 = require("../../base/syndicationBaseSpecificAmenityType");
var SyndicationApartmentsComRHPAmenityTypeEnum;
(function (SyndicationApartmentsComRHPAmenityTypeEnum) {
    SyndicationApartmentsComRHPAmenityTypeEnum["TWENTY_FOUR_HOUR_AVAILABILITY"] = "24 Hour Availability";
    SyndicationApartmentsComRHPAmenityTypeEnum["ACCENT_WALLS"] = "Accent Walls";
    SyndicationApartmentsComRHPAmenityTypeEnum["AIR_CONDITIONING"] = "Air Conditioning";
    SyndicationApartmentsComRHPAmenityTypeEnum["ALARM_SYSTEM"] = "AlarmSystem";
    SyndicationApartmentsComRHPAmenityTypeEnum["ALLOW_CATS"] = "AllowCats";
    SyndicationApartmentsComRHPAmenityTypeEnum["ALLOW_LARGE_DOGS"] = "AllowLargeDogs";
    SyndicationApartmentsComRHPAmenityTypeEnum["ALLOW_SMALL_DOGS"] = "AllowSmallDogs";
    SyndicationApartmentsComRHPAmenityTypeEnum["ASSIGNED_PARKING"] = "AssignedParking";
    SyndicationApartmentsComRHPAmenityTypeEnum["ATTACHED_PARKING"] = "AttachedParking";
    SyndicationApartmentsComRHPAmenityTypeEnum["ATTIC"] = "Attic";
    SyndicationApartmentsComRHPAmenityTypeEnum["BALC_DECK_PATIO_PORCH"] = "BalcDeckPatioPorch";
    SyndicationApartmentsComRHPAmenityTypeEnum["BALCONY"] = "Balcony";
    SyndicationApartmentsComRHPAmenityTypeEnum["BARBECUE_AREA"] = "Barbecue Area";
    SyndicationApartmentsComRHPAmenityTypeEnum["BARBECUE_GRILL"] = "Barbecue/Grill";
    SyndicationApartmentsComRHPAmenityTypeEnum["BASEMENT"] = "Basement";
    SyndicationApartmentsComRHPAmenityTypeEnum["BASKETBALL_COURT"] = "Basketball Court";
    SyndicationApartmentsComRHPAmenityTypeEnum["BAY_WINDOW"] = "Bay Window";
    SyndicationApartmentsComRHPAmenityTypeEnum["BIKE_STORAGE"] = "Bike Storage";
    SyndicationApartmentsComRHPAmenityTypeEnum["BILINGUAL"] = "Bilingual";
    SyndicationApartmentsComRHPAmenityTypeEnum["BOAT_DOCKS"] = "Boat Docks";
    SyndicationApartmentsComRHPAmenityTypeEnum["BREAKFAST_NOOK"] = "Breakfast Nook";
    SyndicationApartmentsComRHPAmenityTypeEnum["BREAKFAST_COFFEE_CONCIERGE"] = "Breakfast/Coffee Concierge";
    SyndicationApartmentsComRHPAmenityTypeEnum["BUILT_IN_BOOKSHELVES"] = "Built-In Bookshelves";
    SyndicationApartmentsComRHPAmenityTypeEnum["BUSINESS_CENTER"] = "Business Center";
    SyndicationApartmentsComRHPAmenityTypeEnum["CABANA"] = "Cabana";
    SyndicationApartmentsComRHPAmenityTypeEnum["CABLE_READY"] = "CableReady";
    SyndicationApartmentsComRHPAmenityTypeEnum["CAMPUS_AREA"] = "CampusArea";
    SyndicationApartmentsComRHPAmenityTypeEnum["CAR_CHARGING_STATION"] = "Car Charging Station";
    SyndicationApartmentsComRHPAmenityTypeEnum["CAR_WASH_AREA"] = "Car Wash Area";
    SyndicationApartmentsComRHPAmenityTypeEnum["CARDIO_MACHINES"] = "Cardio Machines";
    SyndicationApartmentsComRHPAmenityTypeEnum["CARPET"] = "Carpet";
    SyndicationApartmentsComRHPAmenityTypeEnum["CEILING_FANS"] = "Ceiling Fans";
    SyndicationApartmentsComRHPAmenityTypeEnum["CLUBHOUSE"] = "Clubhouse";
    SyndicationApartmentsComRHPAmenityTypeEnum["COFFEE_BAR"] = "Coffee Bar";
    SyndicationApartmentsComRHPAmenityTypeEnum["COFFEE_SYSTEM"] = "Coffee System";
    SyndicationApartmentsComRHPAmenityTypeEnum["COMMUNITY_FITNESS"] = "CommunityFitness";
    SyndicationApartmentsComRHPAmenityTypeEnum["COMMUNITY_POOL"] = "CommunityPool";
    SyndicationApartmentsComRHPAmenityTypeEnum["COMMUNITY_WIDE_WIFI"] = "Community-Wide WiFi";
    SyndicationApartmentsComRHPAmenityTypeEnum["COMPOSTING"] = "Composting";
    SyndicationApartmentsComRHPAmenityTypeEnum["CONCIERGE"] = "Concierge";
    SyndicationApartmentsComRHPAmenityTypeEnum["CONFERENCE_ROOM"] = "Conference Room";
    SyndicationApartmentsComRHPAmenityTypeEnum["CONTROLLED_ACCESS"] = "Controlled Access";
    SyndicationApartmentsComRHPAmenityTypeEnum["CORPORATE"] = "Corporate";
    SyndicationApartmentsComRHPAmenityTypeEnum["CORPORATE_SUITES"] = "Corporate Suites";
    SyndicationApartmentsComRHPAmenityTypeEnum["COURTESY_PATROL"] = "Courtesy Patrol";
    SyndicationApartmentsComRHPAmenityTypeEnum["COURTYARD"] = "Courtyard";
    SyndicationApartmentsComRHPAmenityTypeEnum["COVERED_PARKING"] = "CoveredParking";
    SyndicationApartmentsComRHPAmenityTypeEnum["CROWN_MOLDING"] = "Crown Molding";
    SyndicationApartmentsComRHPAmenityTypeEnum["DAY_CARE"] = "Day Care";
    SyndicationApartmentsComRHPAmenityTypeEnum["DECK"] = "Deck";
    SyndicationApartmentsComRHPAmenityTypeEnum["DEN"] = "Den";
    SyndicationApartmentsComRHPAmenityTypeEnum["DINING_ROOM"] = "DiningRoom";
    SyndicationApartmentsComRHPAmenityTypeEnum["DISHWASHER"] = "Dishwasher";
    SyndicationApartmentsComRHPAmenityTypeEnum["DISPOSAL"] = "Disposal";
    SyndicationApartmentsComRHPAmenityTypeEnum["DISPOSAL_CHUTES"] = "Disposal Chutes";
    SyndicationApartmentsComRHPAmenityTypeEnum["DOCK"] = "Dock";
    SyndicationApartmentsComRHPAmenityTypeEnum["DOORMAN"] = "Doorman";
    SyndicationApartmentsComRHPAmenityTypeEnum["DOUBLE_PANE_WINDOWS"] = "Double Pane Windows";
    SyndicationApartmentsComRHPAmenityTypeEnum["DOUBLE_VANITIES"] = "Double Vanities";
    SyndicationApartmentsComRHPAmenityTypeEnum["DRY_CLEANING_SERVICE"] = "Dry Cleaning Service";
    SyndicationApartmentsComRHPAmenityTypeEnum["EAT_IN_KITCHEN"] = "EatInKitchen";
    SyndicationApartmentsComRHPAmenityTypeEnum["ELEVATOR"] = "Elevator";
    SyndicationApartmentsComRHPAmenityTypeEnum["ENERGY_STAR_CERTIFIED"] = "Energy Star Certified";
    SyndicationApartmentsComRHPAmenityTypeEnum["FAMILY_ROOM"] = "FamilyRoom";
    SyndicationApartmentsComRHPAmenityTypeEnum["FENCED_LOT"] = "Fenced Lot";
    SyndicationApartmentsComRHPAmenityTypeEnum["FIREPLACE"] = "Fireplace";
    SyndicationApartmentsComRHPAmenityTypeEnum["FITNESS_CENTER"] = "Fitness Center";
    SyndicationApartmentsComRHPAmenityTypeEnum["FITNESS_PROGRAMS"] = "Fitness Programs";
    SyndicationApartmentsComRHPAmenityTypeEnum["FRAMED_MIRRORS"] = "Framed Mirrors";
    SyndicationApartmentsComRHPAmenityTypeEnum["FREE_WEIGHTS"] = "Free Weights";
    SyndicationApartmentsComRHPAmenityTypeEnum["FREEZER"] = "Freezer";
    SyndicationApartmentsComRHPAmenityTypeEnum["FRIDGE"] = "Fridge";
    SyndicationApartmentsComRHPAmenityTypeEnum["GAME_ROOM"] = "Gameroom";
    SyndicationApartmentsComRHPAmenityTypeEnum["GAMING_STATIONS"] = "Gaming Stations";
    SyndicationApartmentsComRHPAmenityTypeEnum["GARAGE_PARKING"] = "GarageParking";
    SyndicationApartmentsComRHPAmenityTypeEnum["GARDEN"] = "Garden";
    SyndicationApartmentsComRHPAmenityTypeEnum["GATED"] = "Gated";
    SyndicationApartmentsComRHPAmenityTypeEnum["GOLF_COMMUNITY"] = "GolfCommunity";
    SyndicationApartmentsComRHPAmenityTypeEnum["GRANITE_COUNTERTOPS"] = "Granite Countertops";
    SyndicationApartmentsComRHPAmenityTypeEnum["GREENHOUSE"] = "Greenhouse";
    SyndicationApartmentsComRHPAmenityTypeEnum["GRILL"] = "Grill";
    SyndicationApartmentsComRHPAmenityTypeEnum["GROCERY_SERVICE"] = "Grocery Service";
    SyndicationApartmentsComRHPAmenityTypeEnum["HANDRAILS"] = "Handrails";
    SyndicationApartmentsComRHPAmenityTypeEnum["HARDWOOD"] = "Hardwood";
    SyndicationApartmentsComRHPAmenityTypeEnum["HEALTH_CLUB_DISCOUNT"] = "Health Club Discount";
    SyndicationApartmentsComRHPAmenityTypeEnum["HEALTH_CLUB_FACILITY"] = "Health Club Facility";
    SyndicationApartmentsComRHPAmenityTypeEnum["HEARING_IMPAIRED_ACCESSIBLE"] = "Hearing Impaired Accessible";
    SyndicationApartmentsComRHPAmenityTypeEnum["HEATING"] = "Heating";
    SyndicationApartmentsComRHPAmenityTypeEnum["HIGH_SPEED_INTERNET_ACCESS"] = "High Speed Internet Access";
    SyndicationApartmentsComRHPAmenityTypeEnum["HOUSE_SITTER_SERVICES"] = "House Sitter Services";
    SyndicationApartmentsComRHPAmenityTypeEnum["ICE_MAKER"] = "Ice Maker";
    SyndicationApartmentsComRHPAmenityTypeEnum["INSTANT_HOT_WATER"] = "Instant Hot Water";
    SyndicationApartmentsComRHPAmenityTypeEnum["INTERCOM"] = "Intercom";
    SyndicationApartmentsComRHPAmenityTypeEnum["IS_FURNISHED"] = "IsFurnished";
    SyndicationApartmentsComRHPAmenityTypeEnum["ISLAND_KITCHEN"] = "Island Kitchen";
    SyndicationApartmentsComRHPAmenityTypeEnum["KITCHEN"] = "Kitchen";
    SyndicationApartmentsComRHPAmenityTypeEnum["LAKE_ACCESS"] = "Lake Access";
    SyndicationApartmentsComRHPAmenityTypeEnum["LAUNDRY_SERVICE"] = "Laundry Service";
    SyndicationApartmentsComRHPAmenityTypeEnum["LAUNDRY_ON_SITE"] = "LaundryOnSite";
    SyndicationApartmentsComRHPAmenityTypeEnum["LAWN"] = "Lawn";
    SyndicationApartmentsComRHPAmenityTypeEnum["LEASE_12_MONTH"] = "Lease12Month";
    SyndicationApartmentsComRHPAmenityTypeEnum["LEASE_3_MONTH"] = "Lease3Month";
    SyndicationApartmentsComRHPAmenityTypeEnum["LEASE_6_MONTH"] = "Lease6Month";
    SyndicationApartmentsComRHPAmenityTypeEnum["LEASE_9_MONTH"] = "Lease9Month";
    SyndicationApartmentsComRHPAmenityTypeEnum["LEASE_FLEXIBLE"] = "LeaseFlexible";
    SyndicationApartmentsComRHPAmenityTypeEnum["LEASE_MONTHLY"] = "LeaseMonthly";
    SyndicationApartmentsComRHPAmenityTypeEnum["LEASE_OPTION"] = "LeaseOption";
    SyndicationApartmentsComRHPAmenityTypeEnum["LEED_RATING"] = "LEED Rating";
    SyndicationApartmentsComRHPAmenityTypeEnum["LIBRARY"] = "Library";
    SyndicationApartmentsComRHPAmenityTypeEnum["LINEN_CLOSET"] = "Linen Closet";
    SyndicationApartmentsComRHPAmenityTypeEnum["LOCAL_VET_PET_STORE_DISCOUNT"] = "Local Vet / Pet Store Discount";
    SyndicationApartmentsComRHPAmenityTypeEnum["LOFT_LAYOUT"] = "Loft Layout";
    SyndicationApartmentsComRHPAmenityTypeEnum["LOT_PARKING"] = "LotParking";
    SyndicationApartmentsComRHPAmenityTypeEnum["LOUNGE"] = "Lounge";
    SyndicationApartmentsComRHPAmenityTypeEnum["MAID_SERVICE"] = "Maid Service";
    SyndicationApartmentsComRHPAmenityTypeEnum["MAINTENANCE_ON_SITE"] = "Maintenance on site";
    SyndicationApartmentsComRHPAmenityTypeEnum["MEAL_SERVICE"] = "Meal Service";
    SyndicationApartmentsComRHPAmenityTypeEnum["MEDIA_CENTER_MOVIE_THEATRE"] = "Media Center/Movie Theatre";
    SyndicationApartmentsComRHPAmenityTypeEnum["MICROWAVE"] = "Microwave";
    SyndicationApartmentsComRHPAmenityTypeEnum["MILITARY"] = "Military";
    SyndicationApartmentsComRHPAmenityTypeEnum["MOTHER_IN_LAW_UNIT"] = "Mother-in-law Unit";
    SyndicationApartmentsComRHPAmenityTypeEnum["MUD_ROOM"] = "Mud Room";
    SyndicationApartmentsComRHPAmenityTypeEnum["MULTI_USE_ROOM"] = "Multi Use Room";
    SyndicationApartmentsComRHPAmenityTypeEnum["NEW_CONSTRUCTION"] = "NewConstruction";
    SyndicationApartmentsComRHPAmenityTypeEnum["OFFICE"] = "Office";
    SyndicationApartmentsComRHPAmenityTypeEnum["OFFSITE_STREET_PARKING"] = "OffsiteStreetParking";
    SyndicationApartmentsComRHPAmenityTypeEnum["ONLINE_SERVICES"] = "Online Services";
    SyndicationApartmentsComRHPAmenityTypeEnum["ON_SITE_ATM"] = "On-Site ATM";
    SyndicationApartmentsComRHPAmenityTypeEnum["ON_SITE_RETAIL"] = "On-Site Retail";
    SyndicationApartmentsComRHPAmenityTypeEnum["OUTDOOR_AREA"] = "OutdoorArea";
    SyndicationApartmentsComRHPAmenityTypeEnum["OVEN"] = "Oven";
    SyndicationApartmentsComRHPAmenityTypeEnum["PACKAGE_SERVICE"] = "Package Service";
    SyndicationApartmentsComRHPAmenityTypeEnum["PANTRY"] = "Pantry";
    SyndicationApartmentsComRHPAmenityTypeEnum["PARKING_AVAILABLE"] = "ParkingAvailable";
    SyndicationApartmentsComRHPAmenityTypeEnum["PATIO"] = "Patio";
    SyndicationApartmentsComRHPAmenityTypeEnum["PER_DIEM_ACCEPTED"] = "Per Diem Accepted";
    SyndicationApartmentsComRHPAmenityTypeEnum["PET_CARE"] = "Pet Care";
    SyndicationApartmentsComRHPAmenityTypeEnum["PET_PLAY_AREA"] = "Pet Play Area";
    SyndicationApartmentsComRHPAmenityTypeEnum["PET_WASHING_STATION"] = "Pet Washing Station";
    SyndicationApartmentsComRHPAmenityTypeEnum["PETS_NEGOTIABLE"] = "PetsNegotiable";
    SyndicationApartmentsComRHPAmenityTypeEnum["PICNIC_AREA"] = "Picnic Area";
    SyndicationApartmentsComRHPAmenityTypeEnum["PLANNED_SOCIAL_ACTIVITIES"] = "Planned Social Activities";
    SyndicationApartmentsComRHPAmenityTypeEnum["PLAYGROUND"] = "Playground";
    SyndicationApartmentsComRHPAmenityTypeEnum["POND"] = "Pond";
    SyndicationApartmentsComRHPAmenityTypeEnum["PORCH"] = "Porch";
    SyndicationApartmentsComRHPAmenityTypeEnum["PRIVATE_POOL"] = "PrivatePool";
    SyndicationApartmentsComRHPAmenityTypeEnum["PRIVATE_YARD"] = "PrivateYard";
    SyndicationApartmentsComRHPAmenityTypeEnum["PROPERTY_MANAGER_ON_SITE"] = "Property Manager on Site";
    SyndicationApartmentsComRHPAmenityTypeEnum["PUTTING_GREENS"] = "Putting Greens";
    SyndicationApartmentsComRHPAmenityTypeEnum["RACQUETBALL_COURT"] = "Racquetball Court";
    SyndicationApartmentsComRHPAmenityTypeEnum["RANGE"] = "Range";
    SyndicationApartmentsComRHPAmenityTypeEnum["RECREATION_ROOM"] = "Recreation Room";
    SyndicationApartmentsComRHPAmenityTypeEnum["RECYCLING"] = "Recycling";
    SyndicationApartmentsComRHPAmenityTypeEnum["RENTERS_INSURANCE_PROGRAM"] = "Renters Insurance Program";
    SyndicationApartmentsComRHPAmenityTypeEnum["RENT_INCLUDES_AC"] = "RentIncludesAC";
    SyndicationApartmentsComRHPAmenityTypeEnum["RENT_INCLUDES_ELECTRIC"] = "RentIncludesElectric";
    SyndicationApartmentsComRHPAmenityTypeEnum["RENT_INCLUDES_GAS"] = "RentIncludesGas";
    SyndicationApartmentsComRHPAmenityTypeEnum["RENT_INCLUDES_HEAT"] = "RentIncludesHeat";
    SyndicationApartmentsComRHPAmenityTypeEnum["RENT_INCLUDES_SEWER"] = "RentIncludesSewer";
    SyndicationApartmentsComRHPAmenityTypeEnum["RENT_INCLUDES_TRASH"] = "RentIncludesTrash";
    SyndicationApartmentsComRHPAmenityTypeEnum["RENT_INCLUDES_WATER"] = "RentIncludesWater";
    SyndicationApartmentsComRHPAmenityTypeEnum["ROOFTOP_LOUNGE"] = "Rooftop Lounge";
    SyndicationApartmentsComRHPAmenityTypeEnum["SATELLITE_TV"] = "Satellite TV";
    SyndicationApartmentsComRHPAmenityTypeEnum["SAUNA"] = "Sauna";
    SyndicationApartmentsComRHPAmenityTypeEnum["SECURED_PARKING"] = "SecuredParking";
    SyndicationApartmentsComRHPAmenityTypeEnum["SECURITY_SYSTEM"] = "Security System";
    SyndicationApartmentsComRHPAmenityTypeEnum["SENIOR_COMMUNITY"] = "SeniorCommunity";
    SyndicationApartmentsComRHPAmenityTypeEnum["SHARED_YARD"] = "SharedYard";
    SyndicationApartmentsComRHPAmenityTypeEnum["SHORT_TERM"] = "ShortTerm";
    SyndicationApartmentsComRHPAmenityTypeEnum["SHUTTLE_TO_TRAIN"] = "Shuttle to Train";
    SyndicationApartmentsComRHPAmenityTypeEnum["SKYLIGHT"] = "Skylight";
    SyndicationApartmentsComRHPAmenityTypeEnum["SMOKE_FREE"] = "Smoke Free";
    SyndicationApartmentsComRHPAmenityTypeEnum["SPA"] = "Spa";
    SyndicationApartmentsComRHPAmenityTypeEnum["SPORT_COURT"] = "Sport Court";
    SyndicationApartmentsComRHPAmenityTypeEnum["SPRINKLER_SYSTEM"] = "Sprinkler System";
    SyndicationApartmentsComRHPAmenityTypeEnum["STAINLESS_STEEL_APPLIANCES"] = "Stainless Steel Appliances";
    SyndicationApartmentsComRHPAmenityTypeEnum["STORAGE_UNITS"] = "Storage Units";
    SyndicationApartmentsComRHPAmenityTypeEnum["STORAGE_SPACE"] = "StorageSpace";
    SyndicationApartmentsComRHPAmenityTypeEnum["STOVE"] = "Stove";
    SyndicationApartmentsComRHPAmenityTypeEnum["SUNDECK"] = "Sundeck";
    SyndicationApartmentsComRHPAmenityTypeEnum["SUNROOM"] = "Sunroom";
    SyndicationApartmentsComRHPAmenityTypeEnum["SURROUND_SOUND"] = "Surround Sound";
    SyndicationApartmentsComRHPAmenityTypeEnum["TANNING_SALON"] = "Tanning Salon";
    SyndicationApartmentsComRHPAmenityTypeEnum["TENNIS_COURT"] = "Tennis Court";
    SyndicationApartmentsComRHPAmenityTypeEnum["TILE"] = "Tile";
    SyndicationApartmentsComRHPAmenityTypeEnum["TRASH_COMPACTOR"] = "Trash Compactor";
    SyndicationApartmentsComRHPAmenityTypeEnum["TRASH_PICKUP_CURBSIDE"] = "Trash Pickup - Curbside";
    SyndicationApartmentsComRHPAmenityTypeEnum["TRASH_PICKUP_DOOR_TO_DOOR"] = "Trash Pickup - Door to Door";
    SyndicationApartmentsComRHPAmenityTypeEnum["TUB_SHOWER"] = "Tub/Shower";
    SyndicationApartmentsComRHPAmenityTypeEnum["TWO_STORY_LOBBY"] = "Two Story Lobby";
    SyndicationApartmentsComRHPAmenityTypeEnum["VACATION_SEASONAL"] = "VacationSeasonal";
    SyndicationApartmentsComRHPAmenityTypeEnum["VACUUM_SYSTEM"] = "Vacuum System";
    SyndicationApartmentsComRHPAmenityTypeEnum["VAULTED_CEILING"] = "Vaulted Ceiling";
    SyndicationApartmentsComRHPAmenityTypeEnum["VIDEO_PATROL"] = "Video Patrol";
    SyndicationApartmentsComRHPAmenityTypeEnum["VIEWS"] = "Views";
    SyndicationApartmentsComRHPAmenityTypeEnum["VINTAGE_BUILDING"] = "Vintage Building";
    SyndicationApartmentsComRHPAmenityTypeEnum["VINYL_FLOORING"] = "Vinyl Flooring";
    SyndicationApartmentsComRHPAmenityTypeEnum["VISION_IMPAIRED_ACCESSIBLE"] = "Vision Impaired Accessible";
    SyndicationApartmentsComRHPAmenityTypeEnum["VOLLEYBALL_COURT"] = "Volleyball Court";
    SyndicationApartmentsComRHPAmenityTypeEnum["WALK_IN_CLOSETS"] = "Walk-In Closets";
    SyndicationApartmentsComRHPAmenityTypeEnum["WALKING_BIKING_TRAILS"] = "Walking/Biking Trails";
    SyndicationApartmentsComRHPAmenityTypeEnum["WARMING_DRAWER"] = "Warming Drawer";
    SyndicationApartmentsComRHPAmenityTypeEnum["WASHER_DRYER_HOOKUP"] = "WasherDryerHookup";
    SyndicationApartmentsComRHPAmenityTypeEnum["WASHER_DRYER_IN_UNIT"] = "WasherDryerInUnit";
    SyndicationApartmentsComRHPAmenityTypeEnum["WATERFRONT"] = "Waterfront";
    SyndicationApartmentsComRHPAmenityTypeEnum["WEIGHT_MACHINES"] = "Weight Machines";
    SyndicationApartmentsComRHPAmenityTypeEnum["WET_BAR"] = "Wet Bar";
    SyndicationApartmentsComRHPAmenityTypeEnum["WHEELCHAIR_ACCESS"] = "WheelchairAccess";
    SyndicationApartmentsComRHPAmenityTypeEnum["WI_FI"] = "Wi-Fi";
    SyndicationApartmentsComRHPAmenityTypeEnum["WI_FI_AT_POOL_AND_CLUBHOUSE"] = "Wi-Fi at Pool and Clubhouse";
    SyndicationApartmentsComRHPAmenityTypeEnum["WINDOW_COVERINGS"] = "Window Coverings";
    SyndicationApartmentsComRHPAmenityTypeEnum["WORKSHOP"] = "Workshop";
    SyndicationApartmentsComRHPAmenityTypeEnum["ZEN_GARDEN"] = "Zen Garden";
    SyndicationApartmentsComRHPAmenityTypeEnum["OTHER"] = "Other";
})(SyndicationApartmentsComRHPAmenityTypeEnum || (exports.SyndicationApartmentsComRHPAmenityTypeEnum = SyndicationApartmentsComRHPAmenityTypeEnum = {}));
class SyndicationApartmentsComRHPAmenityType extends syndicationBaseSpecificAmenityType_1.SyndicationBaseSpecificAmenityType {
}
exports.SyndicationApartmentsComRHPAmenityType = SyndicationApartmentsComRHPAmenityType;
__decorate([
    (0, class_validator_1.IsNotEmpty)({ message: "AmenityType is required" }),
    (0, class_validator_1.IsEnum)(SyndicationApartmentsComRHPAmenityTypeEnum),
    __metadata("design:type", String)
], SyndicationApartmentsComRHPAmenityType.prototype, "_AmenityType", void 0);
