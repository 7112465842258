import React from "react";

import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import type { RestrictedDeviceAccessTypes } from "DLUI/restrictedAccess/restrictedDeviceAccess";
import RestrictedDeviceAccess from "DLUI/restrictedAccess/restrictedDeviceAccess";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";

import type { DLButtonUIProps } from "./buttonUI";
import ButtonUI from "./buttonUI";

interface ButtonRestrictedAccessWrappersProps {
  clearance?: AnyPermissionClearance;
  excludedDevices?: RestrictedDeviceAccessTypes[];
}

export type DLButtonProps = ButtonRestrictedAccessWrappersProps & DLButtonUIProps;

const DLButton: React.FC<DLButtonProps> = React.forwardRef(({ clearance, excludedDevices, ...propsUI }, ref) => (
  <RestrictedDeviceAccess excludedDevices={excludedDevices} hideDisplayView>
    <RestrictedPermissionAccess clearance={clearance}>
      <ButtonUI ref={ref} {...propsUI} />
    </RestrictedPermissionAccess>
  </RestrictedDeviceAccess>
));
DLButton.displayName = "DLButton";
export default DLButton;
