import React, { useEffect, useMemo } from "react";
import type { StatusStepInterface } from "DLUI/wizard/stepperBase";
import { StepperBase } from "DLUI/wizard/stepperBase";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import useIntercomBubble from "../../../hooks/useIntercomBubble";
import { CheckBoldIcon } from "../../../assets";
import "./styles.css";
import { IconButton } from "DLUI/form";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import { useTheme } from "@material-ui/core/styles";
import type { TextColor } from "DLUI/text/text";

interface ComponentProps {
  wizardSteps: string[];
  activeStep: number;
  setActiveStep: (step: number) => void;
  paddingTop?: number;
  avoidIntercomAlignmentSwitchSides?: boolean;
}

export interface StepperViewProps {
  avoidIntercomAlignmentSwitchSides?: boolean;
  activeStep: number;
  stepIndex: number;
  label?: string;
  setActiveStep: (step: number) => void;
}

const StepView: React.FC<StepperViewProps> = ({
  avoidIntercomAlignmentSwitchSides,
  activeStep,
  stepIndex,
  label,
  setActiveStep
}: StepperViewProps) => {
  const { isMobile } = useResponsiveHelper();
  const isSelected = activeStep === stepIndex;
  const isCompleted = activeStep > stepIndex;
  const showStepLabel = !isMobile;

  const { changeIntercomBubbleAlignment } = useIntercomBubble();

  useEffect(() => {
    !avoidIntercomAlignmentSwitchSides && !isMobile && changeIntercomBubbleAlignment("left");

    return () => {
      changeIntercomBubbleAlignment("right");
    };
  }, []);

  const theme = useTheme();

  const stepStyle: {
    backgroundColor: string;
    border?: string;
    color: TextColor;
  } = useMemo(() => {
    if (isSelected) {
      return {
        backgroundColor: isMobile ? theme.newPalette.success.main : theme.newPalette.background.neutral2,
        border: `2px solid ${theme.newPalette.success.main}`,
        color: "green"
      };
    }

    if (isCompleted) {
      return { backgroundColor: theme.newPalette.success.main, color: "white" };
    }

    return { backgroundColor: theme.newPalette.background.neutral1, color: "gray" };
  }, [theme, isMobile, isSelected, isCompleted]);

  if (isMobile) {
    return (
      <View alignItems={"center"}>
        <View
          width={10}
          height={10}
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={100}
          style={{
            backgroundColor: stepStyle.backgroundColor,
            boxShadow: isSelected ? "0px 0px 5px rgba(55, 193, 134, 0.5)" : "none"
          }}
        />
      </View>
    );
  }

  const handleStepClick = () => {
    if (setActiveStep && isCompleted) {
      setActiveStep(stepIndex);
    }
  };

  return (
    <View
      alignItems={"center"}
      className={isCompleted ? "MuiStep-completed" : ""}
      cursor={isCompleted ? "pointer" : undefined}
      onClick={isCompleted ? handleStepClick : undefined}
    >
      <View width={30} height={30} alignItems={"center"} justifyContent={"center"} borderRadius={100} style={stepStyle}>
        {isCompleted ? (
          <IconButton
            Icon={CheckBoldIcon}
            height={12}
            paddingLeft={8}
            paddingRight={8}
            paddingBottom={8}
            paddingTop={8}
          />
        ) : (
          <Text color={stepStyle.color} fontSize={12}>
            {stepIndex !== undefined ? stepIndex + 1 : ""}
          </Text>
        )}
      </View>
      <View alignItems={"center"}>
        {showStepLabel && (
          <Text fontSize={14} align={"center"} value={label} marginTop={5} paddingLeft={10} paddingRight={10} />
        )}
      </View>
    </View>
  );
};

const WizardStepper: React.FC<ComponentProps> = ({
  wizardSteps,
  activeStep,
  setActiveStep,
  paddingTop = 20,
  avoidIntercomAlignmentSwitchSides
}: ComponentProps) => {
  const { isMobile } = useResponsiveHelper();
  const BackgroundLine = () => (
    <div
      style={{
        position: "absolute",
        width: "100%",
        left: 0,
        top: 15,
        backgroundColor: "rgba(122, 134, 153, 0.1)",
        height: 2,
        zIndex: -10000
      }}
    />
  );

  const steps: StatusStepInterface[] = wizardSteps.map((currentStep, index) => {
    return {
      StepView: (
        <StepView
          label={currentStep}
          activeStep={activeStep}
          stepIndex={index}
          setActiveStep={setActiveStep}
          avoidIntercomAlignmentSwitchSides={avoidIntercomAlignmentSwitchSides}
        />
      ),
      connectorColor: activeStep >= index ? "green" : "transparent"
    };
  });

  const renderSelectedStepLabel = () => (
    <View alignItems={"center"} marginBottom={20} marginTop={20}>
      <Text value={wizardSteps[activeStep]} />
    </View>
  );

  return (
    <View paddingTop={paddingTop}>
      {isMobile && renderSelectedStepLabel()}
      <View flexDirection={"row"}>
        {!isMobile ? <BackgroundLine /> : null}
        <StepperBase steps={steps} />
      </View>
    </View>
  );
};

export default WizardStepper;
