import type { FC } from "react";
import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { ReportSection } from "./reportSection";
import { reportItems } from "./reportItems";
import AppStrings from "locale/keys";

interface Props {}

export const UserRoleReports: FC<Props> = ({}) => (
  <View flex={1}>
    <View paddingLeft={20} paddingRight={20}>
      <Text
        fontSize={16}
        color={"black"}
        value={AppStrings.UserRoles.ReportsHeaderText}
        marginLeft={20}
        marginRight={20}
        marginBottom={20}
      />
      {reportItems.map(({ sectionTitle, permissions }, index) => (
        <ReportSection permissions={permissions} title={sectionTitle} key={index} />
      ))}
    </View>
  </View>
);
