import type { DeepKeys } from "@doorloop/dto";
import { DefaultAccountType } from "@doorloop/dto";
import { accountsApi } from "api/accounts";
import { SectionTitle } from "DLUI/screen";
import { FieldArray } from "formik";
import React, { useState } from "react";
import { ArticleIdsEnum } from "DLUI/screen/helpPanel/types";
import { LearnMoreText } from "DLUI/text/learnMoreText";
import { useUserType } from "hooks/useUserType";
import { useEffectAsync } from "hooks/useEffectAsync";
import { SettingsSection } from "screens/settings/common/settingsSection";
import { TitleWithDescription } from "screens/settings/common/titleWithDescription";
import { useTypedTranslation } from "locale";
import { useGeneralStyles } from "styles/generalStyles";
import clsx from "clsx";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { useLateFeeSettings } from "DLUI/dialogs/settings/common/lateFees/useLateFeeSettings";
import { LateFeeLimitations } from "DLUI/dialogs/settings/common/lateFees/lateFeeLimitations";
import { LateFeeReminder } from "DLUI/dialogs/settings/common/lateFees/lateFeeReminders";
import { LateFeeChargesList } from "DLUI/dialogs/settings/common/lateFees/lateFeeChargesList";
import { useStyles } from "../settings/styles";
import { SampleCalculator } from "DLUI/dialogs/settings/common/lateFees/sampleCalculator/sampleCalculator";
import type { LateFeesPrefixes } from "DLUI/dialogs/settings/common/lateFees/types";

interface LateFeeSettingsProps {
  name?: DeepKeys<LateFeesPrefixes>;
  showSectionTitle?: boolean;
  showDescriptionHeader?: boolean;
  hideSampleCalculator?: boolean;
}

const LateFeeSettings = ({
  name,
  showSectionTitle = false,
  showDescriptionHeader = false,
  hideSampleCalculator = false
}: LateFeeSettingsProps) => {
  const { isHOAUser } = useUserType();
  const [defaultAccount, setDefaultAccount] = useState<string>("");

  const { getFieldPath, lateFeeSettings } = useLateFeeSettings({ formikPathName: name });

  const { t } = useTypedTranslation();

  const generalClasses = useGeneralStyles();
  const settingsClasses = useStyles();

  useEffectAsync(async () => {
    const accountDto = await accountsApi.getDefaultAccountFor(DefaultAccountType.LATE_FEES);
    if (accountDto?.id) {
      setDefaultAccount(accountDto.id);
    }
  }, []);

  if (!lateFeeSettings) return null;

  return (
    <div className={settingsClasses.lateFeesContainer}>
      <FieldArray
        name={getFieldPath(`lateFeeCharges`)}
        render={(arrayHelpers) => (
          <div className={clsx(generalClasses.flexColumn, generalClasses.largeGap)}>
            {showDescriptionHeader && (
              <>
                <LearnMoreText
                  value={"lateFee.descriptionLateFees"}
                  articleId={!isHOAUser ? ArticleIdsEnum.LATE_FEES : ArticleIdsEnum.HOA_LATE_FEES}
                  style={{ marginTop: 20 }}
                />

                <HorizontalSeparationLine />
              </>
            )}

            <div className={clsx(generalClasses.flexRow, generalClasses.largeGap)}>
              <div className={clsx(generalClasses.basicFlex, generalClasses.flexColumn, generalClasses.mediumGap)}>
                <SettingsSection title={t("lateFee.setupStructure")}>
                  <div className={clsx(generalClasses.flexColumn, generalClasses.verticalFlexStart)}>
                    {!showSectionTitle ? null : (
                      <SectionTitle type={"underline"} title={t("lateFee.chargeLateFee")} marginTop={20} />
                    )}
                    <TitleWithDescription
                      title={t("lateFee.addLateFee")}
                      subtitle={t("lateFee.addLateFeeDescription")}
                    />

                    <LateFeeChargesList
                      arrayHelpers={arrayHelpers}
                      formikPathName={name}
                      defaultAccount={defaultAccount}
                    />
                  </div>
                </SettingsSection>

                <LateFeeLimitations formikPathName={name} />

                <LateFeeReminder formikPathName={name} />
              </div>
              {!hideSampleCalculator && (
                <div className={settingsClasses.sampleCalculator}>
                  <SampleCalculator lateFeeSettings={lateFeeSettings} />
                </div>
              )}
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default LateFeeSettings;
