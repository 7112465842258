import { userRolesApi } from "api/userRolesApi";
import type { DialogState } from "DLUI/dialogs/loadingDialog";
import { AnimatedContentDialog } from "DLUI/dialogs";
import AppStrings from "locale/keys";
import React from "react";
import { useTranslation } from "react-i18next";
import { ObjectPermission } from "@doorloop/dto";
import { initFormValues, validateForm, validateUserForm } from "./formikHelper";
import { getUserRoleFormMenuItems } from "./menuItems";

interface ComponentProps {
  dialogState: DialogState;
  onClose: () => void;
  onBackdropClick: () => void;
}

export const UserRoleDialog: React.FC<ComponentProps> = ({ dialogState, onClose, onBackdropClick }: ComponentProps) => {
  const { t } = useTranslation();
  const userRoleDialogItems = getUserRoleFormMenuItems();

  return (
    <AnimatedContentDialog
      dialogState={dialogState}
      onClose={onClose}
      sectionItems={userRoleDialogItems}
      disableBackdropClick
      onBackdropClick={onBackdropClick}
      getformikInitialValues={initFormValues}
      formikValidation={validateForm}
      validationMethod={validateUserForm}
      loadingText={t(AppStrings.Users.NewUser.LoadingText)}
      errorText={t(AppStrings.Users.NewUser.ErrorText)}
      successText={t(AppStrings.Users.NewUser.SuccessText)}
      apiMethod={userRolesApi}
      dialogTitle={t(AppStrings.UserRoles.UserRole)}
      dialogHeight={window.innerHeight}
      dialogWidth={Math.min(window.innerWidth, 1100)}
      permission={ObjectPermission.userRoles}
    />
  );
};
