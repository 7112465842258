import type { FC } from "react";
import React from "react";
import { useTranslation } from "react-i18next";

import { View } from "DLUI/view";
import Text from "DLUI/text";
import { ExclamationAlert } from "DLUI/dialogs/alert/alert";

import AppStrings from "../../../../../../../locale/keys";

interface Props {
  dialogWidth: number;
  onBackdropClick: () => void;
}

const DialogHeightNoAction = Math.min(window.innerHeight, 440);
const StripeAlertNoActionRequired: FC<Props> = ({ dialogWidth, onBackdropClick }) => {
  const { t } = useTranslation();

  return (
    <ExclamationAlert
      title={t(AppStrings.Accounts.StripeNoActionRequiredTitle)}
      subTitle={""}
      onDismissButton={onBackdropClick}
      textMaxWidth={dialogWidth}
    >
      <View
        flex={1}
        alignItems={"center"}
        justifyContent={"space-between"}
        marginTop={20}
        marginBottom={15}
        paddingLeft={20}
        paddingRight={20}
      >
        <Text align={"center"} fontSize={16}>
          {t(AppStrings.Accounts.StripeNoActionRequiredContent)}
        </Text>
      </View>
    </ExclamationAlert>
  );
};

export { DialogHeightNoAction, StripeAlertNoActionRequired };
