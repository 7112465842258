import type { UserTypes } from "@doorloop/dto";
import { SettingPermission } from "@doorloop/dto";
import AppStrings from "locale/keys";
import type { ObjectWithHiddenForUserTypes } from "../../../../../../utils/userTypesUtils";
import { filterObjectsByUserType } from "../../../../../../utils/userTypesUtils";

interface SettingsItemsPermission extends ObjectWithHiddenForUserTypes {
  permission: SettingPermission;
  title: string;
}

interface SettingsItems extends ObjectWithHiddenForUserTypes {
  sectionTitle: string;
  permissions: SettingsItemsPermission[];
}

export const getSettingsItems = (
  hideForUserType?: UserTypes,
  isCommunicationsCenterFeatureFlagEnabled?: boolean
): SettingsItems[] => {
  const companySettingsPermissions: SettingsItemsPermission[] = [
    {
      permission: SettingPermission.companyInformation,
      title: AppStrings.UserRoles.CompanyInfo
    },
    {
      permission: SettingPermission.regionAndCurrency,
      title: AppStrings.UserRoles.RegionAndCurrency
    },
    {
      permission: SettingPermission.defaultAccounts,
      title: AppStrings.UserRoles.DefaultAccounts
    },
    {
      permission: SettingPermission.quickbooks,
      title: AppStrings.Settings.GeneralSettings.QuickbooksIntegrationTitle
    },
    {
      permission: SettingPermission.listings,
      title: AppStrings.Settings.GeneralSettings.ListingsTitle
    },
    {
      permission: SettingPermission.outgoingPayments,
      title: AppStrings.Common.OutgoingPayments.OutgoingPayments
    },
    {
      permission: SettingPermission.ownerEPayInfo,
      title: AppStrings.Common.OutgoingPayments.OwnerOutgoingPaymentInfo,
      hiddenForUserTypes: ["HOAUser"]
    },
    {
      permission: SettingPermission.tenantsEPayInfo,
      title: AppStrings.Common.OutgoingPayments.TenantOutgoingPaymentInfo
    },
    {
      permission: SettingPermission.vendorsEPayInfo,
      title: AppStrings.Common.OutgoingPayments.VendorOutgoingPaymentInfo
    }
  ];

  if (isCommunicationsCenterFeatureFlagEnabled) {
    companySettingsPermissions.push({
      permission: SettingPermission.communicationCenter,
      title: AppStrings.Common.CommunicationCenter
    });
  }

  const propertySettingsPermissions: SettingsItemsPermission[] = [
    {
      permission: SettingPermission.tenantPortal,
      title: AppStrings.UserRoles.TenantPortal
    },
    {
      permission: SettingPermission.ownerPortal,
      title: AppStrings.UserRoles.OwnerPortal
    },
    {
      permission: SettingPermission.rentReminders,
      title: AppStrings.UserRoles.RentRemindersAndReceipts
    },
    {
      permission: SettingPermission.paymentInstructions,
      title: AppStrings.UserRoles.PaymentInstructions
    },
    {
      permission: SettingPermission.tenantRequests,
      title: AppStrings.UserRoles.TenantRequests
    },
    {
      permission: SettingPermission.ownerRequests,
      title: AppStrings.UserRoles.OwnerRequests,
      hiddenForUserTypes: ["HOAUser"]
    },
    {
      permission: SettingPermission.lateFees,
      title: AppStrings.UserRoles.LateFees
    },
    {
      permission: SettingPermission.rentalApplications,
      title: AppStrings.UserRoles.RentalApplications
    },
    {
      permission: SettingPermission.managementFees,
      title: AppStrings.UserRoles.ManagementFees
    },
    {
      permission: SettingPermission.prospectsSSNAndDLFullInfo,
      title: AppStrings.UserRoles.ProspectsSSNAndDLFullInfo,
      hiddenForUserTypes: ["HOAUser"]
    },
    {
      permission: SettingPermission.convenienceFees,
      title: AppStrings.Common.ConvenienceFees
    }
  ];

  const settingsItems: SettingsItems[] = [
    {
      sectionTitle: AppStrings.UserRoles.CompanySettings,
      permissions: hideForUserType
        ? filterObjectsByUserType(companySettingsPermissions, hideForUserType)
        : companySettingsPermissions
    },
    {
      sectionTitle: AppStrings.UserRoles.PropertySettings,
      permissions: hideForUserType
        ? filterObjectsByUserType(propertySettingsPermissions, hideForUserType)
        : propertySettingsPermissions
    }
  ];

  return hideForUserType ? filterObjectsByUserType(settingsItems, hideForUserType) : settingsItems;
};
