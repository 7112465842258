import * as SmartySDK from "smartystreets-javascript-sdk";
import type { internationalStreet, usStreet } from "smartystreets-javascript-sdk";
import type { AddressDisplayDto, AddressDto } from "@doorloop/dto";
import _ from "lodash";

interface GeneralSmartyResponse {
  analysis: {
    verificationStatus?: string;
    dpvMatchCode?: string;
  };
  metadata?: {
    latitude: number;
    longitude: number;
  };
}

export interface LookupArgType {
  country?: string;
  addressId?: string;
  search?: string;
  selected?: string;
}

export type US_SuggestionType = GeneralSmartyResponse & {
  streetLine: string;
  city: string;
  state: string;
  zipcode: string;
  entries?: number;
  secondary?: string;
  displayName?: string;
};

export type InternationalSuggestionType = GeneralSmartyResponse & {
  street: string;
  locality: string;
  countryIso3?: string;
  country_iso3?: string;
  postalCode?: string;
  postal_code?: string;
  administrativeArea?: string;
  administrative_area?: string;
  secondary?: string;
  displayName?: string;
  addressId?: string;
  addressText?: string;
  entries?: number;
};

export type InternationalAndUS_SuggestionsType = US_SuggestionType & InternationalSuggestionType;

export enum LicenceKeysEnum {
  US = "USLicenses",
  INTERNATIONAL = "InternationalLicenses"
}

const serializeUSAddress = (address: AddressDisplayDto) => {
  const lookup: usStreet.Lookup = new SmartySDK.usStreet.Lookup();

  lookup.street = address.street1 || "";
  lookup.city = address.city || "";
  lookup.zipCode = address.zip || "";
  lookup.maxCandidates = SMARTY_MAX_RESULTS;

  address.state && (lookup.state = address.state);
  address.street2 && (lookup.secondary = address.street2);

  return lookup;
};

const serializeInternationalAddress = (address: AddressDto, iso3Code: string) => {
  const lookup: internationalStreet.Lookup = new SmartySDK.internationalStreet.Lookup(iso3Code, "");

  lookup.address1 = address.street1 || "";
  lookup.country = address.country || "";
  lookup.locality = address.city || "";
  lookup.postalCode = address.zip || "";

  address.state && (lookup.administrativeArea = address.state);
  address.street2 && (lookup.address2 = address.street2);

  return lookup;
};

const SMARTY_MAX_RESULTS = 10;

const SmartyCore = SmartySDK.core;
const smartyLicensesKeys = {
  USLicenses: {
    type: LicenceKeysEnum.US,
    autocomplete: {
      licenceKey: "us-autocomplete-pro-cloud",
      Lookup: SmartySDK.usAutocompletePro.Lookup,
      builderFuncName: "buildUsAutocompleteProClient"
    },
    streetVerification: {
      licenceKey: "us-core-cloud",
      serializeAddress: serializeUSAddress,
      builderFuncName: "buildUsStreetApiClient"
    }
  },
  InternationalLicenses: {
    type: LicenceKeysEnum.INTERNATIONAL,
    autocomplete: {
      licenceKey: "international-autocomplete-v2-cloud",
      Lookup: SmartySDK.internationalAddressAutocomplete.Lookup,
      builderFuncName: "buildInternationalAddressAutocompleteClient"
    },
    streetVerification: {
      licenceKey: "international-global-plus-cloud",
      serializeAddress: serializeInternationalAddress,
      builderFuncName: "buildInternationalStreetClient"
    }
  }
} as const;

export interface VerificationResultType {
  isValid: boolean;
  lat?: number;
  lng?: number;
}

interface SmartyResponse {
  lookups?: Array<{ result: US_SuggestionType[] }>;
  result?: InternationalSuggestionType[];
}

class DataSource {
  locationIso3Code?: string;
  smartyService;
  clientAutoComplete;
  clientStreetVerification;
  onAutoCompleteSuggestions: (suggestions: US_SuggestionType[] | InternationalAndUS_SuggestionsType[]) => void;

  constructor(iso3Code: string) {
    if (!process.env.REACT_APP_SMARTY_KEY) {
      throw Error("Smarty api key is missing");
    } else {
      this.locationIso3Code = iso3Code;
      this.smartyService = smartyLicensesKeys[iso3Code === "USA" ? LicenceKeysEnum.US : LicenceKeysEnum.INTERNATIONAL];
      const credentials = new SmartyCore.SharedCredentials(process.env.REACT_APP_SMARTY_KEY);
      const clientAutoCompleteBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses([
        this.smartyService.autocomplete.licenceKey
      ]);
      const clientVerificationBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses([
        this.smartyService.streetVerification.licenceKey
      ]);

      this.clientAutoComplete = clientAutoCompleteBuilder[this.smartyService.autocomplete.builderFuncName]();
      this.clientStreetVerification =
        clientVerificationBuilder[this.smartyService.streetVerification.builderFuncName]();
    }
  }

  init = async (
    onAutoCompleteSuggestions: (suggestions: US_SuggestionType[] | InternationalAndUS_SuggestionsType[]) => void
  ) => {
    this.onAutoCompleteSuggestions = onAutoCompleteSuggestions;
  };

  getOptions = async (searchQuery: string | LookupArgType, onAddressSelected: (selectedAddress: any) => void) => {
    const lookup = new this.smartyService.autocomplete.Lookup();

    if (this.locationIso3Code) {
      lookup.search = typeof searchQuery === "string" ? searchQuery : searchQuery.search;

      if (this.locationIso3Code === "USA") {
        lookup.source = "all";
      } else {
        lookup.country = this.locationIso3Code;
      }

      if (typeof searchQuery !== "string") {
        if (searchQuery.addressId) {
          lookup.addressId = searchQuery.addressId;
        } else if (searchQuery.selected) {
          lookup.selected = searchQuery.selected;
        }
      }

      lookup.maxResults = SMARTY_MAX_RESULTS;
      try {
        const response = await this.clientAutoComplete?.send(lookup);
        let suggestions: US_SuggestionType[] | InternationalAndUS_SuggestionsType[] = response?.result;

        if (
          this.locationIso3Code !== "USA" &&
          suggestions?.length &&
          suggestions.length === 1 &&
          !suggestions[0].entries
        ) {
          onAddressSelected(suggestions[0]);
          suggestions = [];
        }

        this.onAutoCompleteSuggestions?.(suggestions || []);
      } catch (e) {
        throw e;
      }
    }
  };

  verifyAddress = async (selectedAddress: AddressDisplayDto): Promise<VerificationResultType> => {
    const finalResult: VerificationResultType = { isValid: false };

    try {
      const serializedAddress = this.smartyService.streetVerification.serializeAddress(
        selectedAddress,
        this.locationIso3Code
      );
      const response: SmartyResponse = await this.clientStreetVerification.send(serializedAddress);
      const suggestionResults = _.first(response?.lookups)?.result || response?.result;

      if (!suggestionResults) {
        finalResult.isValid = false;
      } else {
        finalResult.isValid = suggestionResults.some(
          ({ analysis }) =>
            analysis?.verificationStatus !== "None" || // INTERNATIONAL VERIFICATION
            (analysis.dpvMatchCode && analysis.dpvMatchCode !== "N") // US VERIFICATION
        );

        if (suggestionResults.length === 1) {
          const firstResult = suggestionResults[0];
          finalResult.lat = firstResult?.metadata?.latitude;
          finalResult.lng = firstResult?.metadata?.longitude;
        }
      }

      return finalResult;
    } catch (e) {
      return finalResult;
    }
  };
}

export default DataSource;
