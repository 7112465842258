import type { LeaseTenantDto, TenantDto } from "@doorloop/dto";
import { BulkExecutionRequestType, DateFormats, PhoneNumberStatus, PortalStatus } from "@doorloop/dto";
import type { RefObject } from "react";
import { useMemo, useState } from "react";
import moment from "moment/moment";
import { useEffectAsync } from "hooks/useEffectAsync";
import _ from "lodash";
import { leasesApi } from "api/leasesApi";
import {
  useDataListCurrentQueryData,
  useDataListCurrentQueryRefetch
} from "DLUI/dataList/hooks/useDataListCurrentQuery";
import type { DataListLeaseTenantsRefType } from "screens/leases/leases/leaseDetails/tenants/leaseTenantsFiltersWidgetsList";
import AppStrings from "locale/keys";
import { InviteIcon } from "@/assets";
import { DLButtonColorsEnum } from "DLUI/button/dlButton";
import { sendWelcomeSmsBulkActionDefinition } from "utils/bulkOperationsUtils";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { useCurrentPhoneNumber } from "screens/communicationsCenter/shared/useCurrentPhoneNumber";
import type { BulkOperationsSettings } from "DLUI/dataList";
import { useWidgetsCurrentQueryRefetch } from "@/components/DLUI/screenWidgets/useScreenWidget";

export const useLeaseTenantsBulkProps = (selectableItem?: boolean) => {
  const { getIsFeatureActiveForDbTenant } = useFeatureFlag();
  const { currentPhoneNumber } = useCurrentPhoneNumber();

  const bulkProps = useMemo<BulkOperationsSettings>(() => {
    return {
      bulkActionDefinitions: selectableItem
        ? undefined
        : [
            {
              actionName: "activateTenantPortal",
              actionNameTranslated: AppStrings.BulkActions.SendPortalInvitation,
              totalAllExecutionRequestType: BulkExecutionRequestType.SEND_TENANT_PORTAL_INVITATION,
              icon: InviteIcon,
              dialogTextColor: "lightBlue",
              confirmHeader: AppStrings.BulkActions.ActivatePortalConfirmation,
              confirmMessageOne: AppStrings.BulkActions.ConfirmActivateTenantPortalMessageOne,
              confirmMessageMultiple: AppStrings.BulkActions.ConfirmActivateTenantPortalMessageMultiple,
              confirmButtonVariant: DLButtonColorsEnum.SECONDARY
            },
            {
              ...sendWelcomeSmsBulkActionDefinition,
              actionName: "sendTenantWelcomeSms",
              confirmMessageOne: AppStrings.BulkActions.SendTenantWelcomeSmsConfirmMessageOne,
              confirmMessageMultiple: AppStrings.BulkActions.SendTenantWelcomeSmsConfirmMessageMultiple,
              confirmButtonVariant: DLButtonColorsEnum.SECONDARY,
              isHidden:
                !getIsFeatureActiveForDbTenant("CommunicationsCenter") ||
                currentPhoneNumber?.status !== PhoneNumberStatus.ACTIVE
            }
          ],
      minHeight: 150
    };
  }, [selectableItem, getIsFeatureActiveForDbTenant, currentPhoneNumber]);

  return { bulkProps };
};

export function useLeaseTenantsDataList<TData extends DataListLeaseTenantsRefType>(queryKeyRef: RefObject<any>) {
  const [hideSkeleton, setHideSkeleton] = useState(false);
  const [leaseIdRentReminderMap, setLeaseIdRentReminderMap] = useState<Record<string, string>>();
  const { allRows } = useDataListCurrentQueryData<LeaseTenantDto>(queryKeyRef);
  const { refetch } = useDataListCurrentQueryRefetch<LeaseTenantDto>();
  const { refetch: referchWidgets } = useWidgetsCurrentQueryRefetch();
  const handleTenantUpdated = async (tenant: TenantDto) => {
    if (tenant) {
      setTimeout(async () => {
        setHideSkeleton(true);
        await refetch();
        await referchWidgets();
        setHideSkeleton(false);
      }, 1500);
    }
  };

  const updateLastRentSendDate = async (leaseId: string) => {
    setLeaseIdRentReminderMap((prev) => {
      return { ...prev, [leaseId]: moment().format(DateFormats.AMERICAN_DATE) };
    });
  };

  useEffectAsync(async () => {
    if (allRows?.length) {
      const leasesId = allRows
        .filter(
          (leaseTenant) =>
            leaseTenant.tenant?.portalInfo?.status === PortalStatus.ACTIVE &&
            leaseTenant.balanceDue &&
            leaseTenant.balanceDue > 0 &&
            leaseTenant.lease &&
            !leaseIdRentReminderMap?.[leaseTenant.lease]
        )
        .map((leaseTenant) => leaseTenant.lease);

      if (!_.isEmpty(leasesId)) {
        const lastRentReminder = await leasesApi.getLastRentReminderSend(_.compact(leasesId));
        setLeaseIdRentReminderMap((old) => {
          return { ...old, ...lastRentReminder?.data?.data };
        });
      }
    }
  }, [allRows]);

  return { allRows, hideSkeleton, leaseIdRentReminderMap, updateLastRentSendDate, handleTenantUpdated };
}
