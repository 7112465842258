enum ColorsEnum {
  LightGreen = "#EBFAE6",
  DarkGreen = "#01cc74",
  BrightGreen = "rgba(1, 204, 116, 0.2)",
  DarkerGreen = "#01A35D",
  DarkerGreenFaded = "rgba(1, 163, 93, 0.2)",
  Transparent = "transparent",
  Gray = "#515F78",
  BrightGray = "rgba(122, 134, 153, 1)",
  LightShadeGray = "#D7DBE4",
  LightGray = "#ECEEF5",
  LightGraySecondary = "#E8EAED",
  LightGrayFaded = "rgba(122, 134, 153, 0.2)",
  GrayLighter = "#7e899a",
  GrayLightest = "#b2b8c3",

  White = "#fff",
  Blue = "#2F3E83",
  DarkBlue = "#25326B",
  LightBlue = "#E8F0FB",
  BrightBlue = "#1665D8",
  BrightBlueFaded = "rgba(22, 101, 216, 0.3)",
  LightBlueFaded = "rgba(22, 101, 216, 0.2)",
  LightBlueFadedMore = "rgba(22, 101, 216, 0.1)",
  Pink = "#FF4998",
  DarkPink = "#992C5B",
  Error = "#ED555E",
  ErrorLight = "rgba(159, 46, 61, .3)",
  ErrorDark = "rgba(159, 46, 61, 1)",
  ErrorFaded = "#FCE5EA",
  Red = "#E44258",
  RedFaded = "rgba(228, 66, 88, 0.4)",
  RedBright = "#F5B9C1",
  LightRed = "rgba(228, 66, 88, 0.1)",
  Black = "#182C4C",
  LightBlack = "#182C4C99",
  LightBlackFaded = "rgba(24, 44, 76, 0.4)",
  LightBlackFadedMore = "rgba(24, 44, 76, 0.1)",
  BluishGray = "rgba(122, 134, 153, 0.2)",
  Yellow = "#F2C94C",
  YellowFaded = "rgba(242, 201, 76, 0.3)",
  DarkYellow = "#C2A13D",
  DarkYellowFaded = "rgba(242, 201, 76, 0.4)",
  LightYellow = "#FEF8E8",
  LightYellowFaded = "#FEFBF2",
  InfoLight = "#E3F2FF",
  InfoDark = "rgba(15,70,151, 0.2)",
  SecondaryGrey = "rgba(24, 44, 76, 0.60)"
}

export default ColorsEnum;
