import type { PropsWithChildren } from "react";
import React from "react";
import type { GridProps as KendoGridProps } from "@progress/kendo-react-grid";
import { Grid as BaseGrid } from "@progress/kendo-react-grid";
import clsx from "clsx";
import type { DataResult } from "@progress/kendo-data-query";

const StaticGrid = ({ children, ...kendoProps }: PropsWithChildren<KendoGridProps> & { data?: DataResult }) => (
  <BaseGrid {...kendoProps} className={clsx("static-grid", kendoProps.className)}>
    {children}
  </BaseGrid>
);

export default StaticGrid;
