"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESignatureRequestStatus = void 0;
var ESignatureRequestStatus;
(function (ESignatureRequestStatus) {
    ESignatureRequestStatus["DRAFT"] = "DRAFT";
    ESignatureRequestStatus["OUT_FOR_SIGNATURE"] = "OUT_FOR_SIGNATURE";
    ESignatureRequestStatus["DECLINED"] = "DECLINED";
    ESignatureRequestStatus["COMPLETED"] = "COMPLETED";
    ESignatureRequestStatus["CANCELED"] = "CANCELED";
})(ESignatureRequestStatus || (exports.ESignatureRequestStatus = ESignatureRequestStatus = {}));
