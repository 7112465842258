import type { OwnerDto } from "@doorloop/dto";
import { ObjectPermission } from "@doorloop/dto";
import DLButton from "DLUI/button/dlButton";
import { DialogSearchPanel } from "DLUI/dialogs";
import { PAGE_SIZE } from "DLUI/infiniteList/utils";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import { View } from "DLUI/view";
import { useDebounce } from "hooks/useDebounce";
import AppStrings from "locale/keys";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { OwnersList, SelectableOwnersListItem } from "screens/owners";
import { getDialogSelectionHeight } from "../../../components/dialogFrame";
import { useResponsiveHelper } from "../../../../../../contexts/responsiveContext";

interface ComponentProps {
  showAddNewOwner?: boolean;
  didPressAddNewOwner?: () => void;
  didSelectOwnerItem: (selectedItem: OwnerDto, itemIndex?: number) => void;
  height?: number;
  filterObj?: any;
}

const OwnersSelectionView: React.FC<ComponentProps> = ({
  didPressAddNewOwner,
  didSelectOwnerItem,
  showAddNewOwner,
  height
}: ComponentProps) => {
  const { t } = useTranslation();
  const { screenContainerPadding } = useResponsiveHelper();
  const [requestInProgress, setRequestInProgress] = useState<boolean>(false);

  const [filterSelection, setFilterSelection] = useState<Record<string, any>>({
    filter_pageSize: PAGE_SIZE
  });

  const debouncedFilterSelection = useDebounce(filterSelection, 300);

  const didChangeSearchQuery = (value: string) => {
    setFilterSelection({
      filter_text: value
    });
  };

  const _didPressAddNewOwner = () => {
    if (didPressAddNewOwner) {
      didPressAddNewOwner();
    }
  };

  const didChangeLoadingDataState = (loadingInProgress: boolean) => {
    setRequestInProgress(loadingInProgress);
  };

  const renderAddNewButton = () => {
    if (showAddNewOwner !== undefined && !showAddNewOwner) {
      return null;
    }
    return (
      <RestrictedPermissionAccess clearance={{ permission: ObjectPermission.owners, field: "create" }}>
        <DLButton
          actionText={AppStrings.Owners.Screen.AddNewOwner}
          onClick={_didPressAddNewOwner}
          icons={{ start: { isHidden: false } }}
        />
      </RestrictedPermissionAccess>
    );
  };

  return (
    <View>
      <View
        paddingLeft={screenContainerPadding}
        paddingRight={screenContainerPadding}
        alignItems={"center"}
        flexDirection={"row"}
      >
        <View autoWidth>
          <DialogSearchPanel
            placeholder={t(AppStrings.Owners.Screen.SearchPlaceHolder)}
            onChange={didChangeSearchQuery}
            requestInProgress={requestInProgress}
            borderRadius={30}
          />
        </View>
        <View flex={1} justifyContent={"center"} alignItems={"flex-end"} marginTop={20}>
          {renderAddNewButton()}
        </View>
      </View>

      <View
        id={"OwnersList"}
        height={height ? getDialogSelectionHeight(height) : undefined}
        overflow={"scroll"}
        marginTop={20}
      >
        <OwnersList
          filterObj={debouncedFilterSelection}
          ListItem={SelectableOwnersListItem}
          fullWidth
          didSelectOwnerItem={didSelectOwnerItem}
          listDirection={"column"}
          selectableListItem
          didPressAddNewButton={_didPressAddNewOwner}
          onLoadingDataStateChange={didChangeLoadingDataState}
          removeDefaultBottomPadding
          scrollableTarget={"OwnersList"}
          stickyHeaderId={"OwnersList"}
        />
      </View>
    </View>
  );
};

export default OwnersSelectionView;
