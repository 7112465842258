"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommunicationCenterSetupSmsClientDto = exports.CommunicationCenterSetupSmsDto = void 0;
const class_validator_1 = require("class-validator");
const common_1 = require("../common");
const merchantAccountStripeAccountType_enum_1 = require("../merchantAccounts/stripe/merchantAccountStripeAccountType.enum");
class CommunicationCenterSetupSmsDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.CommunicationCenterSetupSmsDto = CommunicationCenterSetupSmsDto;
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateIf)((dto) => dto.type === merchantAccountStripeAccountType_enum_1.MerchantAccountStripeAccountType.COMPANY),
    __metadata("design:type", String)
], CommunicationCenterSetupSmsDto.prototype, "merchantAccountId", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(merchantAccountStripeAccountType_enum_1.MerchantAccountStripeAccountType),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], CommunicationCenterSetupSmsDto.prototype, "type", void 0);
__decorate([
    (0, common_1.IsEIN)(),
    (0, class_validator_1.MaxLength)(20),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateIf)((dto) => dto.type === merchantAccountStripeAccountType_enum_1.MerchantAccountStripeAccountType.COMPANY && !dto.isCanada),
    __metadata("design:type", String)
], CommunicationCenterSetupSmsDto.prototype, "ein", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", Boolean)
], CommunicationCenterSetupSmsDto.prototype, "autoPurchaseCredits", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], CommunicationCenterSetupSmsDto.prototype, "isCanada", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, common_1.IsTotp)(3),
    __metadata("design:type", String)
], CommunicationCenterSetupSmsDto.prototype, "areaCode", void 0);
class CommunicationCenterSetupSmsClientDto extends CommunicationCenterSetupSmsDto {
    constructor(init) {
        super(init);
        Object.assign(this, init);
    }
}
exports.CommunicationCenterSetupSmsClientDto = CommunicationCenterSetupSmsClientDto;
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", Boolean)
], CommunicationCenterSetupSmsClientDto.prototype, "isConfirmed", void 0);
