import React from "react";
import { ReactComponent as AddressIcon } from "assets/icons/menuItems/newPersonDialog/address.svg";
import { ReactComponent as AlternativeAddressIcon } from "assets/icons/menuItems/newPersonDialog/alternative_address.svg";
import { ReactComponent as ContactDetailsIcon } from "assets/icons/menuItems/newPersonDialog/contact_details.svg";
import { ReactComponent as ContactInfoIcon } from "assets/icons/menuItems/newPersonDialog/contact_info.svg";
import { FedTaxesIcon, ManagerIcon, OutgoingPaymentsIcon } from "assets/icons";
import type { MenuItem } from "DLUI/dialogs";
import { ContactInfo, PersonAddress } from "DLUI/person";
import { MenegmentAgreement } from "./components";
import AppStrings from "locale/keys";
import { PersonOutgoingPaymentsTab } from "screens/outgoingPayments/personOutgoingPaymentsTab";
import { SettingPermission, SettingsAccessEnum } from "@doorloop/dto";
import FederalTaxes from "DLUI/person/federalTaxes";
import { PersonInfoWithPortal } from "DLUI/person/personInfoWithPortal";

export const getOwnerFormMenuItems = (): MenuItem[] => {
  const menuItems: MenuItem[] = [
    {
      title: AppStrings.Tenants.NewTenant.ContactDetails,
      icon: ContactInfoIcon,
      contentComponent: (formikRef, onFileReceive, onFileDelete) => (
        <PersonInfoWithPortal
          personType={"owner"}
          formikRef={formikRef}
          onFileReceive={onFileReceive}
          onFileDelete={onFileDelete}
        />
      ),
      uploadFile: true
    },
    {
      title: AppStrings.Tenants.NewTenant.ContactInfo,
      icon: ContactDetailsIcon,
      contentComponent: (formikRef) => <ContactInfo formikRef={formikRef} />
    },
    {
      title: AppStrings.Tenants.NewTenant.Address,
      icon: AddressIcon,
      contentComponent: (formikRef) => (
        <PersonAddress
          formikName={"primaryAddress"}
          title={AppStrings.Tenants.NewTenant.PrimaryAddress}
          formikRef={formikRef}
        />
      )
    },
    {
      title: AppStrings.Tenants.NewTenant.AlternateAddress,
      icon: AlternativeAddressIcon,
      contentComponent: (formikRef) => (
        <PersonAddress
          formikName={"alternateAddress"}
          title={AppStrings.Tenants.NewTenant.AlternateAddress}
          formikRef={formikRef}
        />
      )
    },
    {
      title: AppStrings.Owners.NewOwner.MenegmentAgreement,
      icon: ManagerIcon,
      contentComponent: (formikRef) => <MenegmentAgreement formikRef={formikRef} />
    },
    {
      title: AppStrings.Vendors.NewVendor.FederalTaxes,
      icon: FedTaxesIcon,
      contentComponent: (formikRef) => (
        <FederalTaxes
          strings={{
            send1099Forms: AppStrings.Owners.NewOwner.Send1099Forms,
            dontSend1099Forms: AppStrings.Owners.NewOwner.DontSend1099Forms,
            sameAsPrimaryAddress: AppStrings.Owners.NewOwner.SameAsOwnerAddress
          }}
          formikRef={formikRef}
        />
      )
    },
    {
      title: AppStrings.Common.OutgoingPayments.OutgoingPayments,
      icon: OutgoingPaymentsIcon,
      contentComponent: () => <PersonOutgoingPaymentsTab personType={"owner"} />,
      clearances: [
        {
          permission: SettingPermission.ownerEPayInfo,
          requiredLevel: SettingsAccessEnum.VIEW
        },
        {
          permission: SettingPermission.ownerEPayInfo,
          requiredLevel: SettingsAccessEnum.EDIT
        }
      ]
    }
  ];
  return menuItems;
};
