import MomentUtils from "@date-io/moment";
import type { TaskDto } from "@doorloop/dto";
import {
  createValidator,
  LinkedResourceType,
  PostTaskUpdateDto,
  TaskPriority,
  TaskStatus,
  UserStatus
} from "@doorloop/dto";
import { Grid } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { filesApi } from "api/filesApi";
import { tasksApi } from "api/tasksApi";
import { usersApi } from "api/usersApi";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import { FormikCheckBox, FormikDatePicker, Select, TextField } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, Formik } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import FormikCachedMultiSelectAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedMultiSelectAutoComplete";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";

interface ComponentProps {
  onClose: () => void;
  onBackdropClick: () => void;
  dialogTitle: string;
}

let formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

const validateForm = createValidator(PostTaskUpdateDto);

const TaskUpdateDialog: React.FC<ComponentProps> = ({ onClose, onBackdropClick, dialogTitle }: ComponentProps) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();
  const { dialogHorizontalPadding, inputRightPadding } = DialogsHelper();
  const { taskId } = useParams<any>();
  const editMode = taskId !== undefined;
  const [viewIndex, setViewIndex] = useState(0);
  const [taskData, setTaskData] = useState<TaskDto | undefined>();
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    editMode ? DialogState.Show : DialogState.Hidden
  );

  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");

  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState<string>("");

  const [loadingDialogSuccessText, setLoadingDialogSuccessText] = useState<string>(
    t(AppStrings.Tasks.InternalTaskDialog.LoadingTaskDataSuccessText)
  );

  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);

  useEffect(() => {
    if (editMode) {
      loadTaskData();
    }
  }, []);

  const showErrorMessage = (message: string) => {
    setLoadingDialogErrorText(message);
    setLoadingDialogState(DialogState.Error);
  };

  const loadTaskData = async () => {
    if (taskId) {
      setLoadingDialogLoadingText(t(AppStrings.Tasks.InternalTaskDialog.LoadingTaskData));
      setLoadingDialogState(DialogState.Show);
      const response = await tasksApi.get(taskId).catch((e) => {
        showErrorMessage(e);
      });
      if (response && response.data) {
        setLoadingDialogSuccessText(t(AppStrings.Tasks.InternalTaskDialog.LoadingTaskDataSuccessText));
        setLoadingDialogState(DialogState.Success);
        setTaskData(response.data);
        setLoadingDialogState(DialogState.Hidden);
        return;
      }
      if (response) {
        showErrorMessage(response.message);
      }
    }
    showErrorMessage(t(AppStrings.Common.NetworkErrorSubTitle));
  };

  const didPressDismissButton = () => {
    onBackdropClick();
  };

  const renderView = ({ index }: any) => {
    if (index === 0) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={loadTaskData}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }
      return renderForm();
    }
    if (index === 1) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          loadingText={loadingDialogLoadingText}
          errorText={loadingDialogErrorText}
          successText={t(AppStrings.Tasks.InternalTaskDialog.CreateTaskSuccessText)}
          onRetryButtonPress={didPressSaveButton}
          didPressDismissButton={didPressDismissButton}
        />
      );
    }

    return <div />;
  };

  const initFormValues = (): PostTaskUpdateDto => new PostTaskUpdateDto({ ...taskData, taskId: taskData?.id });

  const onFileReceived = (files: FileListItemProps[]) => {
    setAttachments(files);
  };

  const renderNotifyTenant = () => (
    <Grid justifyContent={"flex-start"} item lg={3} md={3} xs={12}>
      <FastFieldSafe
        component={FormikCheckBox}
        labelText={AppStrings.Tasks.TaskDetails.NotifyTenant}
        name={"notifyTenant"}
      />
    </Grid>
  );

  const renderNotifyOwner = () => (
    <Grid justifyContent={"flex-start"} item lg={3} md={3} xs={12}>
      <FastFieldSafe
        component={FormikCheckBox}
        labelText={AppStrings.Tasks.TaskDetails.NotifyOwner}
        name={"notifyOwner"}
      />
    </Grid>
  );

  const renderTaskNotificationsRow = () => (
    <View marginTop={10} flexDirection={"row"}>
      <Grid container item lg={4} md={4} xs={12}>
        <FastFieldSafe
          component={FormikCheckBox}
          labelText={AppStrings.Tasks.TaskDetails.NotifyAssignees}
          name={"notifyAssignees"}
        />
      </Grid>
      {taskData && taskData.requestedByTenant && renderNotifyTenant()}
      {taskData && taskData.requestedByOwner && renderNotifyOwner()}
    </View>
  );

  const renderForm = () => {
    const formInitialValues = initFormValues();
    return (
      <Formik initialValues={formInitialValues} onSubmit={(values, { setSubmitting }) => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;
          return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <View
                marginTop={10}
                alignItems={"center"}
                paddingRight={dialogHorizontalPadding}
                paddingLeft={dialogHorizontalPadding}
              >
                <FastField
                  component={TextField}
                  label={t(AppStrings.Tasks.TaskDetails.Message)}
                  name={`message`}
                  multiline
                  rows="4"
                  marginTop={20}
                />
              </View>
              <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding}>
                <View flexDirection={"row"}>
                  <Grid style={{ paddingRight: 10 }} item lg={6} md={6} xs={12}>
                    <FastField
                      component={FormikDatePicker}
                      uniqueKey={"dueDate"}
                      label={AppStrings.Leases.NewLease.LeaseRent.DueDate}
                      name={"dueDate"}
                      noMargin
                      marginTop={20}
                    />
                  </Grid>
                </View>
                <SeparationLine width={"100%"} height={1} marginTop={20} />
                <View marginTop={20} flexDirection={"row"}>
                  <Grid item lg={6} md={6} xs={12}>
                    <FastField
                      component={Select}
                      name={`status`}
                      label={AppStrings.Overview.Status}
                      required
                      uniqueKey={"taskStatus"}
                      selectionEnum={TaskStatus}
                      translationKey={"taskStatus"}
                      paddingRight={inputRightPadding}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <FastField
                      component={Select}
                      name={`priority`}
                      label={AppStrings.Tasks.InternalTaskDialog.Priority}
                      uniqueKey={"taskPriority"}
                      selectionEnum={TaskPriority}
                      translationKey={"taskPriority"}
                      marginTop={isMobile ? 20 : 0}
                    />
                  </Grid>
                </View>
                <FormikCachedMultiSelectAutoComplete
                  uniqueIndex={"usersApi"}
                  apiHandler={usersApi}
                  displayNameKey={"name"}
                  filterFieldName={"filter_text"}
                  filterFieldValue={"name"}
                  selectionFields={["id", "class"]}
                  name={"assignedToUsers"}
                  queryParams={{ filter_status: UserStatus.ACTIVE }}
                  label={t(AppStrings.Tasks.InternalTaskDialog.Assignees)}
                  marginTop={20}
                />
                <SeparationLine width={"100%"} height={1} marginTop={20} />
                {renderTaskNotificationsRow()}

                <SeparationLine width={"100%"} height={1} marginTop={10} />
                <FormAttachments onFileReceived={onFileReceived} files={attachments} marginTop={20} />
              </View>
            </MuiPickersUtilsProvider>
          );
        }}
      </Formik>
    );
  };

  const _onBackdropClick = () => {
    if (viewIndex > 0) {
      setViewIndex(viewIndex - 1);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const currentTitle = useMemo(() => {
    if (viewIndex === 0) {
      return dialogTitle;
    }

    if (viewIndex === 2) {
      return AppStrings.Vendors.Screen.AddNewVendor;
    }

    if (viewIndex === 1) {
      return AppStrings.Vendors.VendorDetails.SelectVendor;
    }
    return "";
  }, [viewIndex]);

  const isValidForm = async (formikRef: FormikProps<PostTaskUpdateDto>) => {
    const errors = (await formikRef.validateForm()) as any;
    return _.isEmpty(errors);
  };

  const didPressSaveButton = async () => {
    if (formikGlobalRef !== null) {
      const isValid = await isValidForm(formikGlobalRef);
      if (isValid) {
        if (editMode) {
          postUpdate(formikGlobalRef.values);
        }
      }
    }
  };

  const postUpdate = async (values: PostTaskUpdateDto) => {
    setLoadingDialogLoadingText(t(AppStrings.Tasks.InternalTaskDialog.UpdatingTask));
    setLoadingDialogState(DialogState.Show);
    setViewIndex(1);

    const response = await tasksApi.postUpdate(values).catch((e) => {
      showErrorMessage(e);
    });
    if (response && response.data) {
      await filesApi.uploadFiles(attachments, response.data.id!, LinkedResourceType.Task).catch((error: string) => {
        showErrorMessage(error);
      });
      setLoadingDialogSuccessText(t(AppStrings.Tasks.InternalTaskDialog.UpdateTaskSuccessText));
      setLoadingDialogState(DialogState.Success);
      onClose();
    } else if (response) {
      showErrorMessage(response.message);
    }
  };

  const renderActionPanelButtons = () => (
    <FormActionButtons
      propsSubButton={{ onClick: _onBackdropClick }}
      propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
    />
  );

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      if (loadingDialogState !== DialogState.Hidden) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }
    if (viewIndex === 1) {
      return "contentOnly";
    }
    return "contentOnly";
  }, [viewIndex, loadingDialogState]);

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={currentTitle}
      width={760}
      height={710}
      renderView={renderView}
      numViews={2}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={frameType}
      keepViewsMounted={false}
    />
  );
};

export default TaskUpdateDialog;
