"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalApplicationReportResponseDto = exports.RentalApplicationReportResponseDetailsDto = exports.TransunionReportTypes = void 0;
var TransunionReportTypes;
(function (TransunionReportTypes) {
    TransunionReportTypes["Criminal"] = "Criminal";
    TransunionReportTypes["Credit"] = "Credit";
    TransunionReportTypes["Eviction"] = "Eviction";
})(TransunionReportTypes || (exports.TransunionReportTypes = TransunionReportTypes = {}));
class RentalApplicationReportResponseDetailsDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.RentalApplicationReportResponseDetailsDto = RentalApplicationReportResponseDetailsDto;
class RentalApplicationReportResponseDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.RentalApplicationReportResponseDto = RentalApplicationReportResponseDto;
