import type { FilterOption } from "@/components/DLUI/screen/filterPanel/filterInputs/types";
import { toTypedKey } from "@/locale";
import type { GetAllLeasesQuery } from "@doorloop/dto";
import { DataCy, LeaseSituation, LeaseStatus } from "@doorloop/dto";
import { propertiesApi } from "api/propertiesApi";
import { propertyGroupsApi } from "api/propertyGroupsApi";
import AppStrings from "locale/keys";

export const leasesFilterOptions: Array<FilterOption<GetAllLeasesQuery>> = [
  {
    label: toTypedKey("leases.screen.termduring"),
    type: "dateRangePicker",
    period: "all-time",
    startDateFieldKey: "filter_date_from",
    startDateDisplayName: toTypedKey("leases.screen.termFrom"),
    endDateFieldKey: "filter_date_to",
    endDateDisplayName: toTypedKey("leases.screen.termTo")
  },
  {
    name: AppStrings.Common.FilterOptions.Portfolio,
    apiHandler: propertyGroupsApi,
    displayNameKey: "name",
    filterFieldName: "filter_group",
    filterFieldValue: "id",
    type: "remoteFiltering",
    dataCy: DataCy.DLUI.filters.portfolio
  },
  {
    name: AppStrings.Common.FilterOptions.Property,
    apiHandler: propertiesApi,
    displayNameKey: "name",
    filterFieldName: "filter_property",
    filterFieldValue: "id",
    type: "remoteFiltering",
    dataCy: DataCy.DLUI.filters.property
  },
  {
    type: "staticFiltering",
    name: AppStrings.Common.FilterOptions.Status,
    dataCy: DataCy.DLUI.filters.status,
    values: [
      {
        displayName: AppStrings.Leases.LeaseCharge.BalanceDue,
        value: LeaseSituation.BALANCE_DUE,
        filterFieldName: "filter_situation"
      },
      {
        displayName: AppStrings.Common.CreditAvailable,
        value: LeaseSituation.CREDIT_AVAILABLE,
        filterFieldName: "filter_situation"
      },
      {
        displayName: AppStrings.Common.ZeroBalance,
        value: LeaseSituation.ZERO_BALANCE,
        filterFieldName: "filter_situation"
      },
      {
        displayName: AppStrings.Common.OutgoingPayments.Expired,
        value: LeaseSituation.EXPIRED,
        filterFieldName: "filter_situation"
      },
      {
        displayName: AppStrings.Overview.Eviction,
        value: LeaseSituation.EVICTION,
        filterFieldName: "filter_situation"
      }
    ]
  },
  {
    type: "staticFiltering",
    name: AppStrings.Common.FilterOptions.Active,
    dataCy: DataCy.DLUI.filters.active,
    values: [
      {
        displayName: AppStrings.Common.Active,
        value: LeaseStatus.ACTIVE,
        filterFieldName: "filter_status"
      },
      {
        displayName: AppStrings.Common.Inactive,
        value: LeaseStatus.INACTIVE,
        filterFieldName: "filter_status"
      }
    ]
  },
  {
    type: "dateRangePicker",
    label: AppStrings.Leases.NewLease.LeaseOverview.TermsSection.Dates.StartDate,
    period: "all-time",
    periodDateFieldKey: "period_startDate",
    startDateFieldKey: "filter_start_date_from",
    endDateFieldKey: "filter_start_date_to",
    hiddenForUserTypes: ["HOAUser"]
  },
  {
    type: "dateRangePicker",
    label: AppStrings.Leases.NewLease.LeaseOverview.TermsSection.Dates.EndDate,
    period: "all-time",
    periodDateFieldKey: "period_endDate",
    startDateFieldKey: "filter_end_date_from",
    endDateFieldKey: "filter_end_date_to",
    hiddenForUserTypes: ["HOAUser"]
  },
  {
    type: "staticFiltering",
    name: AppStrings.Leases.NewLease.Rollover,
    dataCy: DataCy.DLUI.filters.rollover,
    values: [
      {
        displayName: "Yes",
        value: "true",
        filterFieldName: "filter_rolloverToAtWill"
      },
      {
        displayName: "No",
        value: "false",
        filterFieldName: "filter_rolloverToAtWill"
      }
    ],
    hiddenForUserTypes: ["HOAUser"]
  }
];
