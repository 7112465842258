import React, { useState } from "react";

import { Formik } from "formik";

import { createValidator, RequestsSettingsDto } from "@doorloop/dto";

import AppStrings from "../../../../locale/keys";
import type { ApiResult } from "api/apiResult";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import type { RequestsSettingsDialogProps } from "screens/settings/requestsSettings/requestsSettingsDialog";
import {
  REQUESTS_SETTINGS_DIALOG_HEIGHT,
  REQUESTS_SETTINGS_DIALOG_WIDTH,
  RequestsSettingsDialog
} from "screens/settings/requestsSettings/requestsSettingsDialog";
import { useEffectAsync } from "../../../../hooks/useEffectAsync";
import { View } from "DLUI/view";

export interface RequestsSettingsFormikWrapperProps extends RequestsSettingsDialogProps {
  getRequestsSettingsApi: () => Promise<ApiResult<RequestsSettingsDto>>;
}

const validateForm = createValidator(RequestsSettingsDto);

export const RequestsSettingsFormikWrapper: React.FC<RequestsSettingsFormikWrapperProps> = ({
  didFinishOperation,
  onBackdropClick,
  dialogTitle,
  getRequestsSettingsApi,
  ...props
}: RequestsSettingsFormikWrapperProps) => {
  const [requestsSettings, setRequestsSettings] = useState<RequestsSettingsDto>();
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>(AppStrings.Common.NetworkErrorSubTitle);

  const loadData = async (): Promise<void> => {
    setLoadingDialogState(DialogState.Show);

    const { status, data, message } = await getRequestsSettingsApi();

    if (status && data) {
      setRequestsSettings(data);
      setLoadingDialogState(DialogState.Hidden);
    } else {
      showDialogError(message);
    }
  };

  const showDialogError = (errorMessage?: string): void => {
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(errorMessage || AppStrings.Common.GeneralError);
  };

  useEffectAsync(async (): Promise<void> => {
    await loadData();
  }, []);

  const initFormValues = (): RequestsSettingsDto => requestsSettings ?? new RequestsSettingsDto();

  if (loadingDialogState !== DialogState.Hidden) {
    return (
      <View
        width={getDialogFrameDimension("width", REQUESTS_SETTINGS_DIALOG_WIDTH)}
        height={getDialogFrameDimension("height", REQUESTS_SETTINGS_DIALOG_HEIGHT)}
        alignItems={"center"}
        justifyContent={"center"}
        backgroundColor={"light"}
      >
        <LoadingDialog
          dialogState={loadingDialogState}
          errorText={loadingDialogErrorText}
          didPressDismissButton={onBackdropClick}
          onRetryButtonPress={loadData}
          minHeight={REQUESTS_SETTINGS_DIALOG_HEIGHT}
        />
      </View>
    );
  }

  return (
    <Formik initialValues={initFormValues()} onSubmit={() => {}} validate={validateForm}>
      {() => (
        <RequestsSettingsDialog
          onBackdropClick={onBackdropClick}
          dialogTitle={dialogTitle}
          didFinishOperation={didFinishOperation}
          {...props}
        />
      )}
    </Formik>
  );
};
