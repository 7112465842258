import React, { useEffect } from "react";
import { FileXlsIcon, PrintIcon } from "assets/icons";
import { IconButton } from "DLUI/form";
import { View } from "DLUI/view";
import { isWebView } from "common/native/utils";
import { usePrint } from "hooks/usePrint";
import { useKeyboard } from "hooks/useKeyboard";
import { isPrintingAtom } from "utils/atoms";
import { useAtom } from "jotai";

interface ComponentProps {
  requestInProgress?: boolean;
  onExportExcelPress?: () => void;
  justifyContent?: "center" | "flex-end" | "flex-start";
  noHorizontalPadding?: boolean;
  noMarginTop?: boolean;
  hidePrintButton?: boolean;
}

let isPrintingReportGlobal = false;

const ReportExportPanel: React.FC<ComponentProps> = ({
  requestInProgress,
  onExportExcelPress,
  justifyContent,
  noHorizontalPadding,
  noMarginTop,
  hidePrintButton = false
}: ComponentProps) => {
  const { onPrint } = usePrint();
  const [isPrinting] = useAtom(isPrintingAtom);

  const handlePrintKeyPressed = (e) => {
    e.preventDefault();

    if (!isPrintingReportGlobal) {
      isPrintingReportGlobal = true;
      onPrint?.();
    }
  };

  useKeyboard("p", handlePrintKeyPressed, [], { isCtrlCombination: true });

  useEffect(() => {
    if (!isPrinting && isPrintingReportGlobal) {
      isPrintingReportGlobal = false;
    }
  }, [isPrinting]);

  useEffect(
    () => () => {
      isPrintingReportGlobal = false;
    },
    []
  );

  if (requestInProgress) {
    return null;
  }

  return (
    <View className={"DLUI_reportExportPanel"}>
      {!isWebView() && (
        <View
          paddingRight={noHorizontalPadding ? 0 : 20}
          marginTop={noMarginTop ? 0 : 20}
          justifyContent={justifyContent || "flex-end"}
          flexDirection={"row"}
        >
          {onExportExcelPress && <IconButton onClick={onExportExcelPress} size={16} Icon={FileXlsIcon} />}

          {!hidePrintButton && <IconButton onClick={onPrint} size={16} Icon={PrintIcon} />}
        </View>
      )}
    </View>
  );
};
export default ReportExportPanel;
