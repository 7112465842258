import type { PropsWithChildren } from "react";
import React, { useCallback, useEffect, useState } from "react";

import type { GridEvent, GridSortChangeEvent } from "@progress/kendo-react-grid";
import { Grid as KendoGrid } from "@progress/kendo-react-grid";
import type { GridProps as KendoGridProps } from "@progress/kendo-react-grid/dist/npm/interfaces/GridProps";
import type { DataResult } from "@progress/kendo-data-query";
import clsx from "clsx";
import { SCROLLABLE_GRID_MAX_HEIGHT } from "DLUI/lists/types";

/**
 * Creates a Scrollable Grid with In-Memory
 * data.
 *
 * Will create a grid and have page + buffer
 * ready in the DOM at any one time
 */
const ScrollableGrid = ({
  children,
  data,
  ...kendoProps
}: PropsWithChildren<KendoGridProps> & { data?: DataResult }) => {
  const pageSizeWithBuffer = 50;

  if (!data) {
    return <></>;
  }

  const [sortedItems, setSortedItems] = useState(data.data);
  const [itemsInView, setItemsInView] = useState<any[]>(data.data.slice(0, pageSizeWithBuffer));

  useEffect(() => {
    setSortedItems(data.data);
    setItemsInView(data.data.slice(0, itemsInView.length));
  }, [data.data]);

  const scrollHandler = useCallback(
    (event: GridEvent) => {
      const { scrollTop, scrollHeight, clientHeight } = event.nativeEvent.target;
      if (scrollTop + 10 >= scrollHeight - clientHeight) {
        setItemsInView(sortedItems.slice(0, itemsInView.length + pageSizeWithBuffer));
      }
    },
    [itemsInView.length, sortedItems]
  );

  const refreshData = useCallback(() => {
    setItemsInView(sortedItems.slice(0, pageSizeWithBuffer));
  }, [sortedItems]);

  const onSortChange = useCallback(
    (e: GridSortChangeEvent) => {
      kendoProps.onSortChange?.(e);

      /**
       * The timeout is needed because of a bug in kendo-react-grid
       * where the sort event is fired before the data is actually sorted.
       */
      setTimeout(refreshData, 10);
    },
    [kendoProps.onSortChange, refreshData]
  );
  return (
    <KendoGrid
      {...kendoProps}
      data={itemsInView}
      onSortChange={onSortChange}
      style={{
        height: "auto",
        maxHeight: SCROLLABLE_GRID_MAX_HEIGHT
      }}
      className={clsx("scrollable-grid", kendoProps.className)}
      fixedScroll={true}
      onScroll={scrollHandler}
      onColumnReorder={kendoProps?.onColumnReorder}
    >
      {children}
    </KendoGrid>
  );
};

export default ScrollableGrid;
