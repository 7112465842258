import { ListItemSection } from "DLUI/listItems";
import { EmailAtSignIcon, PhoneIcon } from "assets/icons";
import AppStrings from "locale/keys";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import EditableContactInfo from "DLUI/person/editableContactInfo";
import type { BaseDto, GetAllBaseQueryRequest } from "@doorloop/dto";
import type { RestApiBase } from "api/restApiBase";
import type { FormikProps } from "formik";
import { getIn } from "formik";
import {
  onPortalLoginEmailChange,
  onPortalPhoneNumberChange,
  validatePersonFirstEmail,
  validatePersonFirstPhone,
  validatePortalInfoEmail,
  validatePortalInfoPhone
} from "@/utils/entities/person.utils";
import {
  personFirstEmailAddressPath,
  personFirstPhoneNumberPath,
  portalInfoEmailPath,
  portalInfoPhonePath
} from "@/constants/person.constants";

function setPhoneFromPortalInfo<T>(formikRef: FormikProps<T>) {
  const value = getIn(formikRef.values, portalInfoPhonePath) ?? "";
  return onPortalPhoneNumberChange({ formikRef, value, shouldOverrideCurrentValue: false });
}

function setEmailFromPortalInfo<T>(formikRef: FormikProps<T>) {
  const value = getIn(formikRef.values, portalInfoEmailPath) ?? "";
  return onPortalLoginEmailChange({ formikRef, value, shouldOverrideCurrentValue: false });
}

interface ListItemContactSectionProps<T> {
  disableEdit?: boolean;
  onSaveSuccess: (dto: T & BaseDto) => void;
  dto: T & BaseDto;
  api: RestApiBase<T & BaseDto, GetAllBaseQueryRequest>;
  domRef?: React.RefObject<HTMLElement | null>;
  shouldUpdatePortalInfo: boolean;
}

const ListItemContactSection = <T extends BaseDto>({
  disableEdit,
  onSaveSuccess,
  dto,
  api,
  domRef,
  shouldUpdatePortalInfo = false
}: ListItemContactSectionProps<T>) => {
  const { t } = useTranslation();
  const finalEmailFormikName = shouldUpdatePortalInfo ? portalInfoEmailPath : personFirstEmailAddressPath;
  const finalPhoneFormikName = shouldUpdatePortalInfo ? portalInfoPhonePath : personFirstPhoneNumberPath;

  const validationEmailFunction = useMemo(
    () => (shouldUpdatePortalInfo ? validatePortalInfoEmail : validatePersonFirstEmail),
    [shouldUpdatePortalInfo]
  );

  const validationPhoneFunction = useMemo(
    () => (shouldUpdatePortalInfo ? validatePortalInfoPhone : validatePersonFirstPhone),
    [shouldUpdatePortalInfo]
  );

  const [contactSavingInProgress, setContactSavingInProgress] = useState<boolean>(false);

  const handleSaveSuccess = (result: T) => {
    setContactSavingInProgress(false);
    onSaveSuccess(result);
  };

  const handleError = () => {
    setContactSavingInProgress(false);
  };

  const handlePhoneConfirm = useCallback(
    (formikRef: FormikProps<T>) => {
      setContactSavingInProgress(true);
      if (shouldUpdatePortalInfo) {
        setPhoneFromPortalInfo(formikRef);
      }
    },
    [shouldUpdatePortalInfo]
  );

  const handleEmailConfirm = useCallback(
    (formikRef: FormikProps<T>) => {
      setContactSavingInProgress(true);
      if (shouldUpdatePortalInfo) {
        setEmailFromPortalInfo(formikRef);
      }
    },
    [shouldUpdatePortalInfo]
  );

  return (
    <ListItemSection
      renderTitle={
        <EditableContactInfo
          onSaveSuccess={handleSaveSuccess}
          onError={handleError}
          onConfirm={handleEmailConfirm}
          disableSave={contactSavingInProgress}
          dto={dto}
          api={api}
          formikName={finalEmailFormikName}
          icon={EmailAtSignIcon}
          addValueText={t(AppStrings.Common.AddEmail)}
          disableEdit={disableEdit}
          validate={validationEmailFunction}
          domRef={domRef}
        />
      }
      renderSubTitle={
        <EditableContactInfo
          onSaveSuccess={handleSaveSuccess}
          onError={handleError}
          onConfirm={handlePhoneConfirm}
          disableSave={contactSavingInProgress}
          dto={dto}
          api={api}
          type={"tel"}
          formikName={finalPhoneFormikName}
          icon={PhoneIcon}
          addValueText={t(AppStrings.Common.AddPhone)}
          disableEdit={disableEdit}
          validate={validationPhoneFunction}
        />
      }
    />
  );
};

export default ListItemContactSection;
