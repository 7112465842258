import { makeStyles } from "@material-ui/core/styles";
import ColorsEnum from "utils/colorsEnum";

export const useStyles = makeStyles({
  componentContainer: {
    border: `1px solid ${ColorsEnum.LightGray}`,
    borderRadius: "8px",
    padding: "0.625rem",
    marginRight: "0.5rem"
  }
});
