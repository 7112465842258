import type { GetAllPropertyGroupsQuery, PropertyGroupDto } from "@doorloop/dto";
import { ServerRoutes } from "@doorloop/dto";
import { RestApiBaseWithDictionary } from "./restApiBaseWithDictionary";

export class PropertyGroupsApi extends RestApiBaseWithDictionary<PropertyGroupDto, GetAllPropertyGroupsQuery> {
  constructor() {
    super(ServerRoutes.PROPERTY_GROUPS);
    this.restRoute = ServerRoutes.PROPERTY_GROUPS;
  }
}

export const propertyGroupsApi = new PropertyGroupsApi();
