import React, { useMemo } from "react";
import type { BankAccountDto } from "@doorloop/dto";
import { AccountType, BankAccountMerchantStatus } from "@doorloop/dto";
import { Grid } from "@material-ui/core";
import { View } from "DLUI/view";
import type { ViewComponentProps } from "DLUI/view/view";
import { BankIcon, CreditCardIcon } from "assets/.";
import { ListItemIcon } from "DLUI/listItems";
import Text from "DLUI/text";
import { Icon } from "DLUI/icon";
import { useBankAccountStatus } from "hooks/useBankAccountStatus";
import ActionButton from "screens/onBoarding/components/actionButton";
import AppStrings from "locale/keys";
import { NavigationManager } from "utils/navigation";
import { Routes } from "../../../appRouter";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import Hidden from "@material-ui/core/Hidden";
import { isLocaleSupportedToReceivePayments } from "screens/incomingPayments/tabs/merchantAccountSetup/isLocaleSupportedToReceivePayments";

interface Props extends ViewComponentProps {
  bankAccountDto: BankAccountDto;
}

const OnBoardingBankAccountListItem: React.FC<Props> = ({ bankAccountDto, ...viewProps }) => {
  const {
    status: { statusIcon, statusColor, statusText }
  } = useBankAccountStatus(bankAccountDto);

  const { localeData, currentLoginResponse } = useSelector((state: IRootState) => {
    return { ...state.auth };
  });
  const isBankAccount = bankAccountDto.accountType === AccountType.ASSET_BANK;
  const hasAppliedForMerchantAccount = bankAccountDto.merchantAccountStatus !== undefined;

  const didPressVerifyIdentity = () => {
    bankAccountDto.merchantAccountId &&
      NavigationManager.verifyMerchantAccountIdentity(
        Routes.BOOKKEEPING_TRANSACTIONS,
        bankAccountDto.merchantAccountId
      );
  };

  const didPressApplyNow = () => {
    const subscriptionPlanStatus = currentLoginResponse?.currentDbTenant?.subscriptionPlan?.status;
    const hasSubscription =
      subscriptionPlanStatus && (subscriptionPlanStatus === "trialing" || subscriptionPlanStatus === "active");

    if (!hasSubscription) {
      return NavigationManager.newSubscriptionPage();
    }

    if (!isLocaleSupportedToReceivePayments(localeData)) {
      NavigationManager.showAlertDialog(location.pathname, {
        dialogTitle: AppStrings.Common.OnlinePaymentsNotSupportedTitle,
        dialogSubTitle: AppStrings.Common.OnlinePaymentsNotSupportedSubTitle,
        dismissButtonText: AppStrings.Common.Dismiss,
        textMaxWidth: 500,
        contactSupportText: AppStrings.Common.SendUsMessage
      });
    } else {
      bankAccountDto.id && NavigationManager.createStripeMerchantAccount(bankAccountDto.id);
    }
  };

  const renderActionButton = useMemo(() => {
    if (!isBankAccount) {
      return <></>;
    }

    if (!hasAppliedForMerchantAccount) {
      return <ActionButton onClick={didPressApplyNow} padding={"0 30px"} actionText={AppStrings.Common.ApplyNow} />;
    }

    if (bankAccountDto.merchantAccountStatus == BankAccountMerchantStatus.IDENTITY_VERIFICATION_REQUIRED) {
      return (
        <ActionButton
          onClick={didPressVerifyIdentity}
          padding={"0 30px"}
          actionText={AppStrings.Common.VerifyIdentity}
        />
      );
    }

    return <></>;
  }, [bankAccountDto]);

  return (
    <View
      backgroundColor={"secondary-gray-light"}
      paddingBottom={10}
      paddingTop={10}
      paddingRight={10}
      paddingLeft={10}
      borderRadius={5}
      {...viewProps}
    >
      <Grid container spacing={1} alignItems={"center"}>
        <Hidden smDown>
          <Grid item>
            {bankAccountDto.accountType === AccountType.ASSET_BANK ? (
              <ListItemIcon Icon={BankIcon} />
            ) : (
              <ListItemIcon Icon={CreditCardIcon} />
            )}
          </Grid>
        </Hidden>
        <Grid container item xs sm md lg direction={"column"}>
          <Text>{bankAccountDto.name}</Text>
          {hasAppliedForMerchantAccount && (
            <View marginTop={5} autoWidth flexDirection={"row"} alignItems={"center"} gap={5}>
              <Hidden smDown>
                <Icon Source={statusIcon} size={16} />
              </Hidden>
              <Text fullWidth value={statusText} color={statusColor} fontSize={14} bold />
            </View>
          )}
        </Grid>
        <Grid item>{renderActionButton}</Grid>
      </Grid>
    </View>
  );
};

export default OnBoardingBankAccountListItem;
