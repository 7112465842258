import React from "react";
import { LeaseDraftStatus } from "@doorloop/dto";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import makeStyles from "../styles";
import clsx from "clsx";
import { StepLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";
import { CompletedStepIcon, CurrentStepIcon, NextStepIcon, ProgressSignInactiveSmallIcon } from "assets/icons";
import type { StepIconProps } from "@material-ui/core/StepIcon";

import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  onStepClick?: () => void;
  activeStep: number;
  steps: string[];
  completed: Set<number>;
  status: LeaseDraftStatus;
}

const ColorlibConnector = withStyles({
  alternativeLabel: {
    marginTop: 5
  },
  active: {
    "& $line": {
      backgroundColor: "#01cc74"
    }
  },
  completed: {
    "& $line": {
      backgroundColor: "#01cc74"
    }
  },
  line: {
    height: 4,
    border: 0,
    backgroundColor: "#D8D8D8",
    borderRadius: 2
  }
})(StepConnector);

const ColorlibConnectorCancel = withStyles({
  alternativeLabel: {},
  active: {
    "& $line": {
      backgroundColor: "#ED555E"
    }
  },
  completed: {
    "& $line": {
      backgroundColor: "#01cc74"
    }
  },
  line: {
    height: 4,
    border: 0,
    backgroundColor: "#D8D8D8",
    borderRadius: 2
  }
})(StepConnector);

const MINIMAL_SCREEN_4_ITEMS_INDICATOR = 1900;

const ListIndicator: React.FC<ComponentProps> = ({
  onStepClick,
  activeStep,
  steps,
  completed,
  status
}: ComponentProps) => {
  const classes = makeStyles();
  const { isScreenSizeIn } = useResponsiveHelper();
  const screenWidth = window.innerWidth;
  const isMinimized = screenWidth < MINIMAL_SCREEN_4_ITEMS_INDICATOR;
  const { isMobile } = useResponsiveHelper();
  const minimizedSteps = [steps[activeStep]];
  const selectedSteps = isMinimized && !isMobile ? minimizedSteps : steps;

  const renderStepIcon = (_props: StepIconProps) => {
    const { active, completed } = _props;

    if (active) {
      return status === LeaseDraftStatus.Cancelled ? (
        <ProgressSignInactiveSmallIcon className={classes.iconActive} />
      ) : (
        <CurrentStepIcon className={classes.iconActive} />
      );
    }
    if (completed) {
      return <CompletedStepIcon className={classes.icon} />;
    }
    return <NextStepIcon className={classes.icon} />;
  };

  function isStepComplete(step: number) {
    return completed.has(step);
  }

  return (
    <div className={clsx([classes.root, "StatusStepper", status === LeaseDraftStatus.Cancelled ? "Cancelled" : ""])}>
      <Stepper
        alternativeLabel
        connector={status === LeaseDraftStatus.Cancelled ? <ColorlibConnectorCancel /> : <ColorlibConnector />}
        activeStep={activeStep}
        classes={{
          root:
            isScreenSizeIn(["lg", "md", "sm"]) && !isMobile
              ? classes.StatusStepperRootMobile
              : classes.StatusStepperRoot
        }}
      >
        {selectedSteps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const buttonProps: { optional?: React.ReactNode } = {};
          const _isStepComplete = isStepComplete(index);
          return (
            <Step key={label} {...stepProps} onClick={onStepClick}>
              <StepLabel
                StepIconComponent={renderStepIcon}
                //@ts-ignore
                completed={_isStepComplete}
                classes={{
                  label: isScreenSizeIn(["lg", "md", "sm"]) && !isMobile ? classes.StatusStepperLabelMobile : undefined
                }}
                {...buttonProps}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

export default ListIndicator;
