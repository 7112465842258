import type { Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    tableRowHoverable: {
      "&:hover": {
        /**
         * The editable table hover color is defined dynamically as a prop of EditableTable
         */
        backgroundColor: "var(--editable-table-hover-color) !important"
      }
    }
  })
);
