import React, { useState } from "react";
import { View } from "DLUI/view";
import { Icon } from "DLUI/icon";
import { ExclamationMarkIconPink } from "assets/icons";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { LoadingDialog } from "DLUI/dialogs/components/loading";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  apiMethod: any;
  cancelConfirmationText?: string;
  didPressDismissButton: () => void;
  transactionId?: string;
  didFinishOperation: (values?: any) => void;
}

const CancelSignatureRequestConfirmation = ({
  cancelConfirmationText,
  didPressDismissButton,
  apiMethod,
  transactionId,
  didFinishOperation
}: ComponentProps) => {
  const { isMobile } = useResponsiveHelper();
  const [cancelInProgress, setCancelInProgress] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string | undefined>();
  const didPressCancelButton = async () => {
    if (apiMethod && transactionId) {
      setCancelInProgress(true);
      const response = await apiMethod(transactionId);
      if (response && response.status) {
        didFinishOperation(response.data);
      } else {
        setCancelInProgress(false);
        setErrorText(response.message || AppStrings.Common.GeneralError);
      }
    }
  };

  if (cancelInProgress) {
    return (
      <LoadingDialog
        dialogState={DialogState.Show}
        loadingText={AppStrings.Common.DeletingTransaction}
        errorText={errorText}
        onRetryButtonPress={didPressCancelButton}
        didPressDismissButton={didPressDismissButton}
      />
    );
  }

  if (errorText) {
    return (
      <View alignItems={"center"} height={"100%"}>
        <Icon marginTop={50} Source={ExclamationMarkIconPink} />
        <View flex={1} justifyContent={"center"} alignItems={"center"}>
          <Text color={"black"} fontSize={24} lineHeight={"34px"} fullWidth align={"center"}>
            {errorText}
          </Text>
        </View>
        <View
          flex={1}
          justifyContent={"center"}
          alignItems={"flex-end"}
          flexDirection={"row"}
          marginBottom={50}
          width={"100%"}
        >
          <DLButton
            actionText={AppStrings.Common.Dismiss}
            variant={DLButtonVariantsEnum.OUTLINED}
            color={DLButtonColorsEnum.NEUTRAL}
            size={DLButtonSizesEnum.LARGE}
            onClick={didPressDismissButton}
            style={{ marginRight: 20 }}
          />
        </View>
      </View>
    );
  }

  return (
    <View height={"100%"}>
      <View alignItems={"center"} paddingLeft={20} paddingRight={20}>
        <Icon marginTop={50} Source={ExclamationMarkIconPink} />
        <Text
          color={"black"}
          fontSize={24}
          fontWeight={700}
          value={AppStrings.Esignatures.CancelRequest}
          marginTop={30}
        />
        <Text
          color={"black"}
          fontSize={24}
          value={cancelConfirmationText ? cancelConfirmationText : AppStrings.Common.DeleteTransactionConfirmationText}
          marginTop={isMobile ? 50 : 20}
          lineHeight={"34px"}
          fullWidth
          align={"center"}
        />
      </View>
      <View
        flex={1}
        justifyContent={"center"}
        alignItems={"flex-end"}
        flexDirection={"row"}
        marginBottom={50}
        width={"100%"}
      >
        <DLButton
          actionText={AppStrings.Common.Dismiss}
          variant={DLButtonVariantsEnum.OUTLINED}
          color={DLButtonColorsEnum.NEUTRAL}
          size={DLButtonSizesEnum.LARGE}
          onClick={didPressDismissButton}
          style={{ marginRight: 20 }}
        />
        <DLButton
          actionText={AppStrings.Esignatures.CancelRequest}
          size={DLButtonSizesEnum.LARGE}
          onClick={didPressCancelButton}
        />
      </View>
    </View>
  );
};

export default CancelSignatureRequestConfirmation;
