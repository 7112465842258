import type { PropertyDto, UnitDto } from "@doorloop/dto";
import { createValidator, PaymentInstructionsSettingsDto, SettingPermission } from "@doorloop/dto";
import type { ApiResult } from "api/apiResult";
import { settingsApi } from "api/settingsApi";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import { TextField } from "DLUI/form";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, Formik } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { SettingsReadOnlyScope } from "DLUI/permissionScope/readOnlyScope";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  didFinishOperation: (values: UnitDto) => void;
  onBackdropClick: () => void;
  refreshEvent?: () => void;
  dialogTitle: string;
}

let formikGlobalRef: FormikProps<any> | null = null;

export const getFormikRef = () => formikGlobalRef;

const DialogFrameMinHeight = 450;

const validateForm = createValidator(PaymentInstructionsSettingsDto);

const EditPaymentInstructions: React.FC<ComponentProps> = ({
  didFinishOperation,
  refreshEvent,
  onBackdropClick,
  dialogTitle
}: ComponentProps) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();
  const { dialogHorizontalPadding } = DialogsHelper();
  const [paymentInstructionsData, setPaymentInstructionsData] = useState<PaymentInstructionsSettingsDto | undefined>();
  const [viewIndex, setViewIndex] = useState(0);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);

  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>(AppStrings.Common.NetworkErrorSubTitle);

  const [hideLoadingDialogActionButtons, setHideLoadingDialogActionButtons] = useState<boolean>(false);

  const loadPaymentInstructionsSettings = async () => {
    const response = await settingsApi.getPaymentInstructions().catch((e: string) => {
      showDialogError(e);
    });
    if (response && response.status && response.data) {
      setPaymentInstructionsData(response.data);
      setLoadingDialogState(DialogState.Success);
    } else {
      showDialogError();
    }
  };

  const showDialogError = (errorMessage?: string) => {
    setHideLoadingDialogActionButtons(true);
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(errorMessage || t(AppStrings.Common.GeneralError));
  };

  useEffect(() => {
    loadPaymentInstructionsSettings();
    return () => {
      formikGlobalRef = null;
    };
  }, []);

  const initFormvalues = (): PaymentInstructionsSettingsDto => {
    if (formikGlobalRef && formikGlobalRef.values) {
      return formikGlobalRef.values;
    }
    if (paymentInstructionsData) {
      return paymentInstructionsData;
    }
    return new PaymentInstructionsSettingsDto();
  };

  const isValidForm = async (formikRef: FormikProps<PropertyDto>) => {
    formikRef.setFieldTouched("instructions");

    const errors = (await formikRef.validateForm()) as any;
    return _.isEmpty(errors);
  };

  const updatePaymentInstructions = async (values: PaymentInstructionsSettingsDto) => {
    const response = (await settingsApi.updatePaymentInstructions(values).catch((error) => {
      setLoadingDialogErrorText(error);
      setLoadingDialogState(DialogState.Error);
    })) as ApiResult<any>;

    if (response.status !== undefined && !response.status) {
      setLoadingDialogErrorText(response.message);
      setLoadingDialogState(DialogState.Error);
    } else {
      setLoadingDialogState(DialogState.Success);
      didFinishUpdateProperty();
      formikGlobalRef = null;
    }
  };

  const didPressSaveButton = async () => {
    if (formikGlobalRef !== null && paymentInstructionsData) {
      const isValid = await isValidForm(formikGlobalRef);
      if (isValid) {
        setLoadingDialogState(DialogState.Show);
        setViewIndex(1);
        updatePaymentInstructions(formikGlobalRef.values);
      }
    }
  };

  const renderActionPanelButtons = () => {
    if (viewIndex === 1) {
      return <div />;
    }

    return (
      <FormActionButtons
        propsSubButton={{ onClick: onBackdropClick }}
        propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
      />
    );
  };

  const didPressDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(0);
  };

  const onRetryButtonPress = () => {
    if (formikGlobalRef !== null) {
      setLoadingDialogState(DialogState.Hidden);
      setViewIndex(0);
    }
  };

  const didFinishUpdateProperty = () => {
    if (formikGlobalRef !== null) {
      didFinishOperation(formikGlobalRef.values);
      if (refreshEvent) {
        refreshEvent();
      }
      setLoadingDialogState(DialogState.Hidden);
    }
  };

  const renderForm = () => {
    const formInitialValues = initFormvalues();
    return (
      <Formik initialValues={formInitialValues} onSubmit={(values, { setSubmitting }) => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;

          return (
            <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding}>
              <View alignItems={"flex-start"}>
                <View marginTop={20}>
                  <Text value={AppStrings.Tasks.WorkOrdersScreen.Instructions} fontSize={20} fontWeight={800} />
                  <FastField
                    component={TextField}
                    label={t(AppStrings.Common.PaymentInstructionsLabel)}
                    name={`instructions`}
                    multiline
                    multiLineSize={"large"}
                    rows="4"
                    marginTop={20}
                    maxLength={1500}
                  />
                </View>
                {isMobile ? <View height={20} /> : null}
              </View>
            </View>
          );
        }}
      </Formik>
    );
  };

  const renderView = ({ index }: any) => {
    if (index === 0 && paymentInstructionsData) {
      return renderForm();
    }
    if (index === 1 || !paymentInstructionsData) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={t(AppStrings.Common.Loading)}
            errorText={loadingDialogErrorText}
            successText={t(AppStrings.Common.OperationCompleted)}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
            hideButtons={hideLoadingDialogActionButtons}
          />
        </View>
      );
    }
    return <div />;
  };

  const _onBackdropClick = () => {
    if (viewIndex === 1) {
      setViewIndex(0);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const frameType = useMemo(() => {
    if (paymentInstructionsData === undefined) {
      return "contentOnly";
    }

    if (viewIndex === 0) {
      return "sectionTitleFrame";
    }
    if (viewIndex === 1) {
      return "contentOnly";
    }

    return "topPanel";
  }, [viewIndex, paymentInstructionsData]);

  return (
    <SettingsReadOnlyScope permission={SettingPermission.paymentInstructions}>
      <DialogFrame
        onCloseButtonClick={_onBackdropClick}
        title={dialogTitle}
        width={750}
        height={DialogFrameMinHeight}
        renderView={renderView}
        numViews={2}
        activeView={viewIndex}
        RenderActionPanelButtons={renderActionPanelButtons}
        frameType={frameType}
      />
    </SettingsReadOnlyScope>
  );
};

export default EditPaymentInstructions;
