import type { ElementType } from "react";
import React, { useMemo } from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import ListingSyndicationInstructionsItem from "screens/settings/listingSyndication/listingSyndicationInstructionsItem";
import ListingSyndicationAutoSyncItem from "screens/settings/listingSyndication/ListingSyndicationAutoSyncItem";

import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import type { EnTranslation } from "locale";
import { useTypedTranslation } from "locale";
import { SettingsBlock } from "screens/settings/tenantPortal/components/settingsBlock";
import type { SVGIconComponent } from "@/assets";
import { InfoLightGrayIcon, OneIcon, TwoIcon } from "@/assets";
import { openNewTabAndFocus } from "utils/navigation";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import {
  ElementPrefixedText,
  ElementType as ElementPrefixedTextType
} from "screens/incomingPayments/tabs/merchantAccountSetup/stripeOnbording/components/elementPrefixedText";
import type { TextComponentProps } from "DLUI/text/text";
import type {
  ListingSyndicationByType,
  Syndication
} from "screens/settings/listingSyndication/listingSyndication.constant";
import { SyndicationItems } from "screens/settings/listingSyndication/listingSyndication.constant";

interface ListingItemProps {
  currentItem: Syndication;
}

const ListingItem = ({ currentItem }: ListingItemProps) => {
  if (currentItem.autoSyncEnabled) {
    return <ListingSyndicationAutoSyncItem syndicationItem={currentItem} />;
  }
  return <ListingSyndicationInstructionsItem syndicationItem={currentItem} />;
};

interface HowToAdvertiseBullet {
  text: EnTranslation;
  icon: SVGIconComponent;
  onClick?: VoidFunction;
  textProps?: TextComponentProps;
  as?: ElementType;
}

const LISTINGS_SYNDICATION_PREVIEW = "/images/listingsSyndicationPreview.png";

const howToAdvertiseInfo: HowToAdvertiseBullet[] = [
  { text: "listing.selectTheSitesToAdvertiseOnBelow", icon: OneIcon },
  { text: "listing.goToEachUnitsSettings", icon: TwoIcon },
  {
    text: "listing.learnMore",
    icon: InfoLightGrayIcon,
    onClick: () => {
      // should get the updated link
      openNewTabAndFocus("www.google.co.il");
    },
    textProps: {
      underLine: "solid"
    }
  }
];

export const ListingSyndicationForm = () => {
  const { screenContainerPadding, isMobile } = useResponsiveHelper();
  const { t } = useTypedTranslation();
  const { free, paid, advanced } = useMemo(
    () =>
      SyndicationItems.filter((syndicationItem) => !syndicationItem.comingSoon).reduce(
        (previous, current) => {
          const prevValueType = previous[current.type];
          return {
            ...previous,
            [current.type]: [...prevValueType, current]
          };
        },
        { free: [], paid: [], advanced: [] } as ListingSyndicationByType
      ),
    []
  );

  return (
    <View paddingLeft={screenContainerPadding} paddingRight={screenContainerPadding}>
      <Text value={t("listing.chooseSyndicationsTitle")} marginTop={20} bold />
      <Text value={t("listing.chooseSyndicationsDesc")} fontSize={14} color={"secondary-gray"} marginTop={2} />
      <SettingsBlock marginTop={16} borderRadius={4} paddingBottom={16} paddingTop={16}>
        <View
          flexDirection={"row"}
          noWrap
          justifyContent={"center"}
          alignItems={"center"}
          paddingLeft={16}
          paddingRight={16}
          paddingTop={16}
          paddingBottom={16}
          gap={24}
        >
          {!isMobile && <img src={LISTINGS_SYNDICATION_PREVIEW} width={175} height={140} alt={"syndicationPreview"} />}
          <View autoWidth flex={1} gap={6}>
            <Text
              bold
              fontSize={14}
              value={t("listing.howToAdvertiseYourUnitsOnline")}
              marginBottom={isMobile ? 6 : undefined}
            />
            {howToAdvertiseInfo.map((bullet, index) => (
              <ElementPrefixedText
                key={index}
                text={t(bullet.text)}
                iconSrc={bullet.icon}
                elementType={ElementPrefixedTextType.ICON}
                onClick={bullet.onClick}
                textProps={{ fontSize: 14, color: "secondary-gray", ...bullet.textProps }}
              />
            ))}
          </View>
        </View>
        <HorizontalSeparationLine
          width={"95%"}
          marginBottom={16}
          style={{
            alignSelf: "center"
          }}
        />
        <Text value={t("listing.paidListingPartners")} fontSize={16} bold marginLeft={16} marginBottom={24} />

        {paid.map((currentItem, index) => (
          <ListingItem currentItem={currentItem} key={index} />
        ))}
        <HorizontalSeparationLine
          width={"95%"}
          marginBottom={16}
          marginTop={16}
          style={{
            alignSelf: "center"
          }}
        />
        <Text value={t("listing.freeListingPartners")} fontSize={16} bold marginLeft={16} marginBottom={24} />
        {free.map((currentItem, index) => (
          <React.Fragment key={index}>
            <ListingItem currentItem={currentItem} key={index} />
            {index !== free.length - 1 && (
              <HorizontalSeparationLine
                width={"95%"}
                style={{
                  alignSelf: "center"
                }}
                marginTop={16}
                marginBottom={16}
              />
            )}
          </React.Fragment>
        ))}
      </SettingsBlock>
      <Text value={t("listing.advancedIntegrations")} marginTop={20} bold />
      <Text
        value={t("listing.listYourUnitsOnAnyThirdPartySite")}
        fontSize={14}
        color={"secondary-gray"}
        marginTop={2}
      />
      <SettingsBlock borderRadius={4} marginTop={16} marginBottom={16} paddingBottom={16} paddingTop={16}>
        {advanced.map((currentItem, index) => (
          <ListingItem currentItem={currentItem} key={index} />
        ))}
      </SettingsBlock>
    </View>
  );
};
