import { useContextSelector } from "use-context-selector";
import { FilterChipsContext } from "../context";
import { FilterButton } from "../shared/filterButton";

export interface ToggleProps {
  renderButton?: (props: { onClick: VoidFunction; totalChips: number }) => JSX.Element;
}

export function Toggle({ renderButton }: ToggleProps) {
  const totalChips = useContextSelector(FilterChipsContext, (context) => context.filterChips.length);
  const toggleSidePanelState = useContextSelector(FilterChipsContext, (context) => context.toggleSidePanelState);
  const handleButtonClick = () => {
    toggleSidePanelState();
  };

  if (renderButton) {
    return renderButton({ onClick: handleButtonClick, totalChips });
  }

  return <FilterButton totalChips={totalChips} onClick={handleButtonClick} />;
}
