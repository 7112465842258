import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    componentContainer: {
      width: "100%",
      flexDirection: "column",
      display: "flex"
    },
    contentWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start"
    },
    alignEnd: {
      justifyContent: "flex-end",
      marginRight: "20px"
    },
    titleSection: {
      margin: "20px 20px"
    }
  })
);
