import { DataCy, ReportId, ReportPermission, TransactionReportGroupBy } from "@doorloop/dto";

import AppStrings from "locale/keys";
import { ownerPortalNavigation } from "../../../../../utils/ownerPortalNavigation";
import { ReportType } from "screens/reports/cashFlowStatement/reportType.enum";
import type { ReportScreenItemType } from "DLUI/report/screen/reportScreenItemType";

const didPressProfitAndLoss = (propertyId?: string): void => {
  const params: Record<string, string> = {
    period: "this-year-to-date"
  };

  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  ownerPortalNavigation.runProfitAndLossReport(params);
};

const didPressProfitAndLossByProperty = (propertyId?: string): void => {
  const params: Record<string, string> = {
    groupBy: TransactionReportGroupBy.PROPERTY,
    period: "this-year-to-date"
  };

  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  ownerPortalNavigation.runProfitAndLossReport(params);
};

const didPressProfitAndLossByYear = (propertyId?: string): void => {
  const params: Record<string, string> = {
    groupBy: TransactionReportGroupBy.YEAR,
    period: "all-time"
  };

  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  ownerPortalNavigation.runProfitAndLossReport(params);
};

const didPressProfitAndLossByMonth = (propertyId?: string): void => {
  const params: Record<string, string> = {
    groupBy: TransactionReportGroupBy.MONTH,
    period: "this-year-to-date"
  };

  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  ownerPortalNavigation.runProfitAndLossReport(params);
};

const didPressProfitAndLossByQuarter = (propertyId?: string): void => {
  const params: Record<string, string> = {
    groupBy: TransactionReportGroupBy.QUARTER,
    period: "this-year-to-date"
  };

  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  ownerPortalNavigation.runProfitAndLossReport(params);
};

const didPressBalanceSheet = (propertyId?: string): void => {
  const params = {
    period: "this-year-to-date"
  };

  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  ownerPortalNavigation.runBalanceSheetReport(params);
};

const didPressBalanceSheetByMonth = (propertyId?: string): void => {
  const params = {
    groupBy: TransactionReportGroupBy.MONTH,
    period: "this-year-to-date"
  };

  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  ownerPortalNavigation.runBalanceSheetReport(params);
};

const didPressBalanceSheetByYear = (propertyId?: string): void => {
  const params = {
    groupBy: TransactionReportGroupBy.YEAR,
    period: "all-time"
  };

  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  ownerPortalNavigation.runBalanceSheetReport(params);
};

const didPressBalanceSheetByQuarter = (propertyId?: string): void => {
  const params = {
    groupBy: TransactionReportGroupBy.QUARTER,
    period: "this-year-to-date"
  };

  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  ownerPortalNavigation.runBalanceSheetReport(params);
};

const didPressBalanceSheetByProperty = (propertyId?: string): void => {
  const params = {
    groupBy: TransactionReportGroupBy.PROPERTY,
    period: "this-year-to-date"
  };

  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  ownerPortalNavigation.runBalanceSheetReport(params);
};

const didPressCashFlowStatement = (propertyId?: string): void => {
  const params = {
    period: "this-month"
  };

  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  ownerPortalNavigation.runCashFlowStatementReport(params);
};

const didPressCashFlowStatementByMonth = (propertyId?: string): void => {
  const params = {
    groupBy: TransactionReportGroupBy.MONTH,
    period: "this-year-to-date"
  };

  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  ownerPortalNavigation.runCashFlowStatementReport(params);
};

const didPressOwnerStatement = (propertyId?: string): void => {
  const params: Record<string, string> = {
    groupBy: TransactionReportGroupBy.PROPERTY,
    period: "last-month",
    filter_report_type: ReportType.ALL
  };

  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  ownerPortalNavigation.runOwnerStatementReport(params);
};

const didPressPropertyReserveFunds = () => {
  ownerPortalNavigation.runPropertyReserveFundsReport();
};

const didPressCashFlowStatementByProperty = (propertyId?: string): void => {
  const params = {
    groupBy: TransactionReportGroupBy.PROPERTY,
    period: "this-month"
  };

  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  ownerPortalNavigation.runCashFlowStatementReport(params);
};

const didPressRentRoll = (propertyId?: string): void => {
  const params = {
    groupBy: "property"
  };

  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  ownerPortalNavigation.runRentRollReport(params);
};
const didPressRentRollSummaryByBedsAndBaths = (propertyId?: string): void => {
  const params = {};

  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  ownerPortalNavigation.runRentRollSummaryByBedsAndBathsReport(params);
};

const didPressRentRollSummaryByProperty = (propertyId?: string): void => {
  const params = {};

  if (propertyId) {
    params["filter_property"] = propertyId;
  }

  ownerPortalNavigation.runRentRollSummaryByPropertyReport(params);
};

export const BUSINESS_OVERVIEW_REPORT_ITEMS: ReportScreenItemType[] = [
  {
    description: AppStrings.Reports.ReportsScreen.CashFlowStatementByProperty,
    onPress: didPressCashFlowStatementByProperty,
    permission: ReportPermission.cashFlowStatement,
    id: ReportId.CASH_FLOW,
    dataCy: DataCy.reports.screenItems.businessOverview.cashFlowStatementByProperty
  },
  {
    description: AppStrings.Reports.ReportsScreen.ProfitAndLoss,
    onPress: didPressProfitAndLoss,
    permission: ReportPermission.profitAndLoss,
    id: ReportId.PROFIT_AND_LOST,
    dataCy: DataCy.reports.screenItems.businessOverview.profitAndLoss
  },
  {
    description: AppStrings.Reports.ReportsScreen.ProfitAndLossByMonth,
    onPress: didPressProfitAndLossByMonth,
    permission: ReportPermission.profitAndLoss,
    id: ReportId.PROFIT_AND_LOST_BY_MONTH,
    dataCy: DataCy.reports.screenItems.businessOverview.profitAndLossByMonth
  },
  {
    description: AppStrings.Reports.ReportsScreen.ProfitAndLossByProperty,
    onPress: didPressProfitAndLossByProperty,
    permission: ReportPermission.profitAndLoss,
    id: ReportId.PROFIT_AND_LOST_BY_PROPERTY,
    dataCy: DataCy.reports.screenItems.businessOverview.profitAndLossByProperty
  },
  {
    description: AppStrings.Reports.ReportsScreen.ProfitAndLossByQuarter,
    onPress: didPressProfitAndLossByQuarter,
    permission: ReportPermission.profitAndLoss,
    id: ReportId.PROFIT_AND_LOST_BY_QUARTER,
    dataCy: DataCy.reports.screenItems.businessOverview.profitAndLossByQuarter
  },
  {
    description: AppStrings.Reports.ReportsScreen.ProfitAndLossByYear,
    onPress: didPressProfitAndLossByYear,
    permission: ReportPermission.profitAndLoss,
    id: ReportId.PROFIT_AND_LOST_BY_YEAR,
    dataCy: DataCy.reports.screenItems.businessOverview.profitAndLossByYear
  },
  {
    description: AppStrings.Reports.ReportsScreen.RentRoll,
    onPress: didPressRentRoll,
    permission: ReportPermission.rentRoll,
    id: ReportId.RENT_ROLL,
    dataCy: DataCy.reports.screenItems.businessOverview.rentRoll
  },
  {
    description: AppStrings.Reports.ReportsScreen.RentRollSummaryByBedsAndBaths,
    onPress: didPressRentRollSummaryByBedsAndBaths,
    permission: ReportPermission.rentRoll,
    id: ReportId.RENT_ROLL_SUMMARY_BY_BEDS_BATHS,
    dataCy: DataCy.reports.screenItems.businessOverview.rentRollSummaryByBedsBaths
  },
  {
    description: AppStrings.Reports.ReportsScreen.RentRollSummaryByProperty,
    onPress: didPressRentRollSummaryByProperty,
    permission: ReportPermission.rentRoll,
    id: ReportId.RENT_ROLL_SUMMARY_BY_PROPERTY,
    dataCy: DataCy.reports.screenItems.businessOverview.rentRollSummaryByProperty
  },

  {
    description: AppStrings.Reports.ReportsScreen.BalanceSheet,
    onPress: didPressBalanceSheet,
    permission: ReportPermission.balanceSheet,
    id: ReportId.BALANCE_SHEET,
    dataCy: DataCy.reports.screenItems.businessOverview.balanceSheet
  },
  {
    description: AppStrings.Reports.ReportsScreen.BalanceSheetByMonth,
    onPress: didPressBalanceSheetByMonth,
    permission: ReportPermission.balanceSheet,
    id: ReportId.BALANCE_SHEET_BY_MONTH,
    dataCy: DataCy.reports.screenItems.businessOverview.balanceSheetByMonth
  },
  {
    description: AppStrings.Reports.ReportsScreen.BalanceSheetByProperty,
    onPress: didPressBalanceSheetByProperty,
    permission: ReportPermission.balanceSheet,
    id: ReportId.BALANCE_SHEET_BY_PROPERTY,
    dataCy: DataCy.reports.screenItems.businessOverview.balanceSheetByProperty
  },
  {
    description: AppStrings.Reports.ReportsScreen.BalanceSheetByQuarter,
    onPress: didPressBalanceSheetByQuarter,
    permission: ReportPermission.balanceSheet,
    id: ReportId.BALANCE_SHEET_BY_QUARTER,
    dataCy: DataCy.reports.screenItems.businessOverview.balanceSheetByQuarter
  },
  {
    description: AppStrings.Reports.ReportsScreen.BalanceSheetByYear,
    onPress: didPressBalanceSheetByYear,
    permission: ReportPermission.balanceSheet,
    id: ReportId.BALANCE_SHEET_BY_YEAR,
    dataCy: DataCy.reports.screenItems.businessOverview.balanceSheetByYear
  },
  {
    description: AppStrings.Reports.ReportsScreen.CashFlowStatement,
    onPress: didPressCashFlowStatement,
    permission: ReportPermission.cashFlowStatement,
    id: ReportId.CASH_FLOW_STATEMENT,
    dataCy: DataCy.reports.screenItems.businessOverview.cashFlowStatement
  },
  {
    description: AppStrings.Reports.ReportsScreen.CashFlowStatementByMonth,
    onPress: didPressCashFlowStatementByMonth,
    permission: ReportPermission.cashFlowStatement,
    id: ReportId.CASH_FLOW_STATEMENT_BY_MONTH,
    dataCy: DataCy.reports.screenItems.businessOverview.cashFlowStatementByMonth
  },
  {
    description: AppStrings.Reports.ReportsScreen.OwnerStatement,
    onPress: didPressOwnerStatement,
    permission: ReportPermission.ownerStatement,
    id: ReportId.OWNER_STATEMENT,
    dataCy: DataCy.reports.screenItems.businessOverview.ownerStatement
  },
  {
    description: AppStrings.Reports.ReportsScreen.PropertyReserveFunds,
    onPress: didPressPropertyReserveFunds,
    permission: ReportPermission.propertyReserveFunds,
    id: ReportId.PROPERTY_RESERVE_FUNDS,
    dataCy: DataCy.reports.screenItems.businessOverview.propertyReserveFunds
  }
];
