export default {
  userRoles: "User Roles",
  userRole: "User Role",
  newUserRole: "New User Role",
  roleName: "Role Name",
  description: "Description",
  users: "Users",
  generalInfo: "General Info",
  objects: "Objects",
  reports: "Reports",
  settings: "Settings",
  roleDescription: "Role Description",
  nameDesc: 'What would you like to call this Role? (For example, "Accountant Role")',
  descriptionDesc: "How would you describe this Role? (For example, gives read access only to financial data)",
  generalInfoHeaderText: "Describe this user role?",
  objectsHeaderText:
    "You can set custom access levels for each page in DoorLoop. This will allow or block a user from viewing that page or taking any actions.",
  reportsHeaderText:
    "You can set which reports each user in this role has access to. Please note: Even if they have access to a report, the items on this report will still be limited by the properties and objects this user has access to. Click here to learn more.",
  settingsHeaderText: "Set the general settings for these users",
  viewOnly: "View Only",
  viewOne: "View One",
  viewList: "View List",
  create: "Create",
  edit: "Edit",
  delete: "Delete",
  prospects: "Prospects",
  owners: "Owners",
  vendors: "Vendors",
  tenants: "Tenants",
  tasks: "Tasks",
  workOrders: "Work Orders",
  tenantRequests: "Tenant Requests",
  calendar: "Calendar",
  ownerRequests: "Owner Requests",
  properties: "Properties",
  units: "Units",
  leases: "Leases",
  rentalApplications: "Rental Applications",
  leaseCharges: "Lease Charges & Rent",
  leaseCredits: "Lease Credits",
  leasePayments: "Lease Payments & Reversed Payments",
  leaseRefunds: "Lease Refunds",
  vendorBills: "Vendor Bills",
  vendorBillPayments: "Vendor Bill Payments",
  vendorCredits: "Vendor Credits",
  ownerContributions: "Owner Contributions",
  journalEntries: "Journal Entries",
  bankTransfers: "Bank Transfers",
  bankDeposits: "Bank Deposits",
  expenses: "Expenses",
  applicationFees: "Application Fees",
  accounts: "Accounts",
  bankConnectAccounts: "Bank Connect Accounts",
  budgetVsActual: "Budget Vs Actual",
  bankConnectTransactions: "Bank Connect Transactions",
  merchantAccounts: "Merchant Accounts",
  people: "People",
  tasksAndMaintenance: "Tasks & Maintenance",
  rentals: "Rentals",
  leasing: "Leasing",
  tenantTransactions: "Tenant Transactions",
  vendorTransactions: "Vendor Transactions",
  ownerTransactions: "Owner Transactions",
  otherTransactions: "Other Transactions",
  managementFees: "Management Fees",
  prospectsSSNAndDLFullInfo: "Prospects SSN and DL Full Info (Rental Application)",
  generalObjects: "General Objects",
  businessOverview: "Business Overview",
  accounting: "Accounting",
  balanceSheet: "Balance Sheet",
  cashFlowStatement: "Cash Flow Statement",
  profitAndLoss: "Profit & Loss",
  owners1099: "1099 MISC",
  vendors1099: "1099 NEC",
  rentRoll: "Rent Roll",
  ownerStatement: "Owner Statement",
  chartOfAccounts: "Chart of Accounts",
  banking: "Banking",
  bankingBalanceByProperty: "Bank Balance by Property",
  depositDetail: "Deposit Detail",
  propertyAvailableFunds: "Property Available Funds",
  propertyReserveFunds: "Property Reserve Funds",
  ownerAvailableFunds: "Owner Available Funds",
  leaseTransactions: "Lease Transactions",
  leaseDeposits: "Lease Deposits",
  transactionsList: "Transactions List",
  transactionsDetails: "Transaction Details",
  ARReports: "A/R Reports",
  APReports: "A/P Reports",
  recurringTransactions: "Recurring Transactions",
  trialBalance: "Trial Balance",
  tenantReports: "Tenant Reports",
  prospectReports: "Prospect Reports",
  leasingReports: "Leasing Reports",
  vendorReports: "Vendor Reports",
  taskReports: "Task Reports",
  workOrderReports: "Work Order Reports",
  tenantRequestReports: "Tenant Request Reports",
  companySettings: "Company Settings",
  propertySettings: "Property Settings",
  companyInfo: "Company Info",
  regionAndCurrency: "Region And Currency",
  defaultAccounts: "Default Accounts",
  tenantPortal: "Tenant Portal",
  ownerPortal: "Owner Portal",
  rentRemindersAndReceipts: "Rent & Payment Notifications",
  paymentInstructions: "Payment Instructions",
  lateFees: "Late Fees",
  accessDenied: "Access Denied",
  noAccessSubtext: "It appears like you don’t have access to this page.",
  noAccessSubtext2: "Please contact your administrator if you feel like you should be able to access this page.",
  noAccess: "No Access",
  deleteConfirmationText: "Are you sure you want to delete the role?",
  deleteRoleLoadingText: "Deleting role",
  deleteRoleSuccessText: "Role was deleted successfully",
  files: "Files",
  notes: "Notes",
  portfolios: "Portfolios",
  tags: "Tags",
  printChecks: "Print Checks",
  communications: "Communications Log",
  announcements: "Announcements",
  esignatureRequests: "Signature Requests",
  esignatureTemplates: "Signature Templates",
  bankReconciliation: "Bank Reconciliation"
};
