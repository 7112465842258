import type { ResultsSearchTabComponentProps, SearchResults } from "DLUI/screen/globalSearch/types";
import AppStrings from "../../../../locale/keys";
import { LocalSearchTab } from "DLUI/screen/globalSearch/localSearchTab";
import PropertiesTab from "DLUI/screen/globalSearch/propertiesTab";
import UnitsTab from "DLUI/screen/globalSearch/unitsTab";
import LeasesTab from "DLUI/screen/globalSearch/leasesTab";
import DraftLeasesTab from "DLUI/screen/globalSearch/draftLeasesTab";
import LeaseTenantsTab from "DLUI/screen/globalSearch/leaseTenantsTab";
import ProspectsTab from "DLUI/screen/globalSearch/prospectsTab";
import OwnersTab from "DLUI/screen/globalSearch/ownersTab";
import VendorsTab from "DLUI/screen/globalSearch/vendorsTab";
import GlobalSearchTasksTab from "DLUI/screen/globalSearch/globalSearchTasksTab";
import GlobalSearchFilesTab from "DLUI/screen/globalSearch/globalSearchFilesTab";
import GlobalSearchNotesTab from "DLUI/screen/globalSearch/globalSearchNotesTab";

interface GlobalTab {
  order?: number;
  label: string;
  Component: React.FC<ResultsSearchTabComponentProps<any>>;
}

export const GlobalSearchTabMap: Record<keyof SearchResults, GlobalTab> = {
  localSearchResults: {
    order: 1,
    label: AppStrings.DrawerItems.Shortcuts,
    Component: LocalSearchTab
  },
  properties: {
    label: AppStrings.DrawerItems.Properties,
    Component: PropertiesTab
  },
  units: {
    label: AppStrings.DrawerItems.Units,
    Component: UnitsTab
  },
  leases: {
    label: AppStrings.DrawerItems.Leases,
    Component: LeasesTab
  },
  lease_drafts: {
    label: AppStrings.DrawerItems.DraftLeases,
    Component: DraftLeasesTab
  },
  lease_tenants: {
    label: AppStrings.DrawerItems.Tenants,
    Component: LeaseTenantsTab
  },
  prospect_tenants: {
    label: AppStrings.DrawerItems.Prospects,
    Component: ProspectsTab
  },
  owners: {
    label: AppStrings.DrawerItems.Owners,
    Component: OwnersTab
  },
  vendors: {
    label: AppStrings.DrawerItems.Vendors,
    Component: VendorsTab
  },
  tasks: {
    label: AppStrings.DrawerItems.Tasks,
    Component: GlobalSearchTasksTab
  },
  files: {
    label: AppStrings.DrawerItems.Files,
    Component: GlobalSearchFilesTab
  },
  notes: {
    label: AppStrings.DrawerItems.Notes,
    Component: GlobalSearchNotesTab
  }
};
