import type { AddressDto } from "@doorloop/dto";
import { CANADA, UNITED_STATES } from "@doorloop/dto";

export const useSelectedCountry = (addressDto: AddressDto) => {
  const country = addressDto?.country;

  const isUs = country === UNITED_STATES;
  const isCanada = country === CANADA;
  const isNonNorthAmerica = !isUs && !isCanada;

  return {
    isUs,
    isCanada,
    isNonNorthAmerica
  };
};
