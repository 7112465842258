const DrawerItems = {
  Shortcuts: "drawerItems.shortcuts",
  Dashboard: "drawerItems.dashboard",
  Calendar: "drawerItems.calendar",
  OccupancyOverview: "drawerItems.occupancyOverview",
  Tasks: "drawerItems.tasks",
  Properties: "drawerItems.properties",
  Leases: "drawerItems.leases",
  People: "drawerItems.people",
  Tenants: "drawerItems.tenants",
  Listings: "drawerItems.listings",
  Management: "drawerItems.management",
  Communication: "drawerItems.communication",
  Signatures: "drawerItems.signatures",
  Screenings: "drawerItems.screenings",
  Expensess: "drawerItems.expensess",
  Maintenance: "drawerItems.maintenance",
  Vendors: "drawerItems.vendors",
  Users: "drawerItems.users",
  Leads: "drawerItems.leads",
  Prospects: "drawerItems.prospects",
  Owners: "drawerItems.owners",
  UnassignedTasks: "drawerItems.unassignedTasks",
  MyTasks: "drawerItems.myTasks",
  AllTasks: "drawerItems.allTasks",
  TenantRequests: "drawerItems.tenantRequests",
  OwnerRequests: "drawerItems.ownerRequests",
  AllNotes: "drawerItems.allNotes",
  AllFiles: "drawerItems.allFiles",
  RecurringTasksAndWO: "drawerItems.recurringTasksAndWO",
  Accounting: "drawerItems.accounting",
  Reports: "drawerItems.reports",
  Pages: "drawerItems.pages",
  Actions: "drawerItems.actions",
  Units: "drawerItems.units",
  Notes: "drawerItems.notes",
  Rentals: "drawerItems.rentals",
  RealEstate: "drawerItems.realEstate",
  Directory: "drawerItems.directory",
  Messaging: "drawerItems.messaging",
  Leasing: "drawerItems.leasing",
  Settings: "drawerItems.settings",
  ActiveLeases: "drawerItems.activeLeases",
  InActiveLeases: "drawerItems.inActiveLeases",
  DraftLeases: "drawerItems.draftLeases",
  LeaseRenewals: "drawerItems.leaseRenewals",
  RentalApplications: "drawerItems.rentalApplications",
  Applications: "drawerItems.applications",
  WorkOrders: "drawerItems.workOrders",
  UnpaidRent: "drawerItems.unpaidRent",
  OpenBills: "drawerItems.openBills",
  Reconciliation: "drawerItems.reconciliation",
  Banking: "drawerItems.banking",
  Budgets: "drawerItems.budgets",
  PrintChecks: "drawerItems.printChecks",
  ApprovePayments: "drawerItems.approvePayments",
  chartOfAccounts: "drawerItems.chartOfAccounts",
  RentRoll: "drawerItems.rentRoll",
  ProfitLoss: "drawerItems.profitLoss",
  BalanceSheet: "drawerItems.balanceSheet",
  GeneralLedger: "drawerItems.generalLedger",
  OwnerStatement: "drawerItems.ownerStatement",
  CompanyFinancials: "drawerItems.companyFinancials",
  Overview: "drawerItems.overview",
  Files: "drawerItems.files",
  Statements: "drawerItems.statements",
  Pets: "drawerItems.pets",
  Vehicles: "drawerItems.vehicles",
  Dependents: "drawerItems.dependents",
  BankConnect: "drawerItems.bankConnect",
  Transactions: "drawerItems.transactions"
};

export default DrawerItems;
