import type { ESignaturesGetEmbeddedUrlResponseDto } from "@doorloop/dto";
import { ServerRoutes } from "@doorloop/dto";
import type { ESignatureRequestDto } from "@doorloop/dto";
import type { GetAllESignatureRequestsQuery } from "@doorloop/dto";
import { RestApiBase } from "./restApiBase";
import { apiHelper } from "api/apiHelper";
import type { GetAvailableCreditsForSignatureRequestsResponseDto } from "@doorloop/dto";
import { usersApi } from "api/usersApi";
import { propertiesApi } from "api/propertiesApi";
import { unitsApi } from "api/unitsApi";
import { leasesApi } from "api/leasesApi";
import type { PostSendReminderForESignatureRequestDto } from "@doorloop/dto";
import { tenantsApi } from "api/tenantsApi";
import { vendorsApi } from "api/vendorsApi";
import { ownersApi } from "api/ownersApi";
import AppStrings from "../locale/keys";

class SignatureRequestsApi extends RestApiBase<ESignatureRequestDto, GetAllESignatureRequestsQuery> {
  constructor() {
    super(ServerRoutes.ESIGNATURE_REQUESTS, [
      usersApi,
      propertiesApi,
      unitsApi,
      leasesApi,
      tenantsApi,
      vendorsApi,
      ownersApi
    ]);
    this.restRoute = ServerRoutes.ESIGNATURE_REQUESTS;
  }

  getEmbeddedUrl = async (signatureRequestId: string) =>
    await apiHelper.axiosGet<ESignaturesGetEmbeddedUrlResponseDto>(
      `${ServerRoutes.ESIGNATURE_REQUESTS}/${signatureRequestId}/embedded-url`
    );

  getCredits = async () =>
    await apiHelper.axiosGet<GetAvailableCreditsForSignatureRequestsResponseDto>(
      ServerRoutes.ESIGNATURE_REQUESTS_GET_CREDITS_REMAINING
    );

  send = async (signatureRequestId: string) =>
    await apiHelper.axiosPost({ url: `${ServerRoutes.ESIGNATURE_REQUESTS}/${signatureRequestId}/send` });
  cancelRequest = async (signatureRequestId: string) =>
    await apiHelper.axiosPost({
      url: `${ServerRoutes.ESIGNATURE_REQUESTS}/${signatureRequestId}/cancel`,
      toasts: { translationKey: AppStrings.Toasts.custom.signatureRequest.cancel?.POST }
    });
  sendReminder = async (data: PostSendReminderForESignatureRequestDto) =>
    await apiHelper.axiosPost({ url: ServerRoutes.ESIGNATURE_REQUESTS_POST_SEND_REMINDER, data });
}

export const signatureRequestsApi = new SignatureRequestsApi();
