export const APP_VERSION = "1.0.5 ";

declare global {
  const DOORLOOP_COMMIT_ID: string;
  const DOORLOOP_BRANCH: string;
  const DOORLOOP_BUILD_TIME: string;
  const DOORLOOP_LATEST_TAG: string;
  interface Window {
    __DL_META__: () => Record<string, any>;
    __DL_ANDROID__: { version: string; buildNumber: string };
    DOORLOOP_COMMIT_ID: string;
    DOORLOOP_BRANCH: string;
    DOORLOOP_BUILD_TIME: string;
    DOORLOOP_LATEST_TAG: string;
  }
}

/**
 * Provides a way for internal doorloop employees to see the deployed commit id and branch
 */
export function setupBuildMetadata() {
  window.__DL_META__ = () => {
    return {
      env: process.env.REACT_APP_ENV,
      run: process.env.REACT_APP_RUN_TYPE,
      commit_id: DOORLOOP_COMMIT_ID,
      branch: DOORLOOP_BRANCH,
      build_time: DOORLOOP_BUILD_TIME,
      latest_tag: DOORLOOP_LATEST_TAG
    };
  };
}
