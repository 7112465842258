import type { FC } from "react";
import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { ePayField } from "screens/outgoingPayments/mappings";
import AddressAutoCompleteFormWrapper from "DLUI/form/autoComplete/addressAutoComplete/addressAutoCompleteFormWrapper";

export const PhysicalCheckSection: FC = () => {
  const addressFieldName = ePayField("physicalAddress");

  return (
    <View marginBottom={20}>
      <Text fontSize={18} bold value={AppStrings.Common.OutgoingPayments.AddressSettings} />
      <AddressAutoCompleteFormWrapper formikName={addressFieldName} fullWidthAddresses />
    </View>
  );
};
