"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseAccountsReceivableReportLine = void 0;
class LeaseAccountsReceivableReportLine {
    constructor(account, amount, memo) {
        this.account = account;
        this.amount = amount;
        this.memo = memo;
    }
}
exports.LeaseAccountsReceivableReportLine = LeaseAccountsReceivableReportLine;
