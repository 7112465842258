import type { GetAllUnitsQuery, UnitDto } from "@doorloop/dto";
import { TenantPortalServerRoutes } from "@doorloop/dto";

import { RestApiBaseWithDictionary } from "api/restApiBaseWithDictionary";
import { tenantPortalPropertiesApi } from "api/tenantPortal/tenantPortalPropertiesApi";

export class TenantPortalUnitsApi extends RestApiBaseWithDictionary<UnitDto, GetAllUnitsQuery> {
  constructor() {
    super(TenantPortalServerRoutes.UNITS, [tenantPortalPropertiesApi]);
    this.restRoute = TenantPortalServerRoutes.UNITS;
  }
}

export const tenantPortalUnitsApi = new TenantPortalUnitsApi();
