import type { FC } from "react";
import { useCallback } from "react";
import { DataCy, SegmentEventTypes } from "@doorloop/dto";
import { View } from "DLUI/view";
import DLButton, { DLButtonColorsEnum } from "DLUI/button/dlButton";
import { NavigationManager } from "utils/navigation";
import AppStrings from "locale/keys";
import { PrintIcon, SendPaymentsIcon } from "assets/icons";
import { analyticsService } from "services/analyticsService";

import type { SuccessDialogActionsProps } from "../utils";

const SuccessDialogActions: FC<SuccessDialogActionsProps> = ({ accountPrintChecks, isEpay, disableMobileCollapse }) => {
  const handleNavigateToPrintChecks = useCallback(() => {
    analyticsService.track(SegmentEventTypes.OWNER_DISTRIBUTION_SUCCESS_DIALOG_PRINT_CHECKS_CLICKED, undefined, {
      trackEventInIntercom: true
    });

    NavigationManager.printAccountChecks(accountPrintChecks, true);
  }, []);

  const handleNavigateSendPayments = useCallback(() => {
    analyticsService.track(SegmentEventTypes.OWNER_DISTRIBUTION_SUCCESS_DIALOG_COMPLETE_EPAY_CLICKED, undefined, {
      trackEventInIntercom: true
    });

    NavigationManager.viewSendPayments(true);
  }, []);

  return (
    <View fullWidth flexDirection={"row"} noWrap gap={10}>
      {Boolean(accountPrintChecks) && (
        <DLButton
          onClick={handleNavigateToPrintChecks}
          actionText={AppStrings.Owners.OwnerDistributions.PrintChecks}
          color={DLButtonColorsEnum.NEUTRAL}
          icons={{ start: { src: PrintIcon }, end: { isHidden: false } }}
          style={{ flex: 1 }}
          dataCy={DataCy.common.printChecksButton}
        />
      )}

      {isEpay && (
        <DLButton
          onClick={handleNavigateSendPayments}
          actionText={AppStrings.Owners.OwnerDistributions.CompleteEpay}
          icons={{ start: { src: SendPaymentsIcon }, end: { isHidden: false } }}
          style={{ flex: 1 }}
          disableMobileCollapse={disableMobileCollapse}
          dataCy={DataCy.common.ePayButton}
        />
      )}
    </View>
  );
};

export { SuccessDialogActions };
