import type { FC } from "react";
import React from "react";
import type { GeneralEntryDialogProps } from "./generalEntry";
import GeneralEntryDialog from "./generalEntry";

type ComponentProps = GeneralEntryDialogProps;

export const RecurringGeneralEntryDialog: FC<ComponentProps> = ({ ...props }) => (
  <GeneralEntryDialog editingRecurring {...props} />
);
