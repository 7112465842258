import type { LateFeeChargeDto } from "@doorloop/dto";
import { createValidator, LateFeesPolicyDto, SettingPermission } from "@doorloop/dto";
import type { ApiResult } from "api/apiResult";
import { settingsApi } from "api/settingsApi";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { Formik } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import LateFeeSettings from "../common/lateFees/lateFeesSettings";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import { SettingsReadOnlyScope } from "DLUI/permissionScope/readOnlyScope";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { trackLateFeesSettings } from "DLUI/dialogs/settings/common/analytics/trackLateFeesSettings";
import { entityApiStore } from "api/entityApiStore/entityApiStore";
import { createBlankLateFeesSettings, getChargesWithUniqueKeys } from "DLUI/dialogs/settings/common/lateFees/utils";
import { useEffectAsync } from "hooks/useEffectAsync";
import { editLateFeesFrameWidth } from "DLUI/dialogs/settings/common/lateFees/constants";

interface ComponentProps {
  didFinishOperation: (values: LateFeesPolicyDto) => void;
  onBackdropClick: () => void;
  refreshEvent?: () => void;
  dialogTitle: string;
}

let formikGlobalRef: FormikProps<any> | null = null;

const validateForm = createValidator(LateFeesPolicyDto);

const EditLateFees: React.FC<ComponentProps> = ({
  didFinishOperation,
  refreshEvent,
  onBackdropClick,
  dialogTitle
}: ComponentProps) => {
  const { t } = useTranslation();
  const { dialogHorizontalPadding } = DialogsHelper();
  const [viewIndex, setViewIndex] = useState(0);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);

  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>(AppStrings.Common.NetworkErrorSubTitle);

  const [hideLoadingDialogActionButtons, setHideLoadingDialogActionButtons] = useState<boolean>(false);

  const {
    data: lateFeesData,
    isLoading,
    error: getDataError,
    remove
  } = entityApiStore.globalSettings.queries.useLateFeesSettings();

  useEffect(() => {
    if (getDataError) {
      showDialogError(getDataError.toString());
    }
  }, [getDataError]);

  const loadGlobalSettingsData = async () => {
    if (lateFeesData) {
      setLoadingDialogState(DialogState.Success);
    }
  };

  const showDialogError = (errorMessage?: string) => {
    setHideLoadingDialogActionButtons(true);
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(errorMessage || AppStrings.Common.GeneralError);
  };

  useEffectAsync(async () => {
    await loadGlobalSettingsData();
  }, [lateFeesData]);

  useEffect(
    () => () => {
      formikGlobalRef = null;
    },
    []
  );

  const initFormValues = (): LateFeesPolicyDto => {
    if (formikGlobalRef && formikGlobalRef.values) {
      return formikGlobalRef.values;
    }
    if (lateFeesData?.data) {
      lateFeesData.data.lateFeeCharges = getChargesWithUniqueKeys(lateFeesData.data.lateFeeCharges);
      return lateFeesData.data;
    }

    return createBlankLateFeesSettings(undefined, { onlyChargeLateFeesOnMostRecentCharge: true });
  };

  const isValidForm = async (formikRef: FormikProps<LateFeesPolicyDto>) => {
    const settingsObject = formikRef?.values ? formikRef.values : undefined;

    if (settingsObject) {
      if (settingsObject.lateFeeCharges) {
        settingsObject.lateFeeCharges.forEach((currentItem: LateFeeChargeDto, index: number) => {
          formikRef.setFieldTouched(`lateFeeCharges[${index}].frequency`);
          formikRef.setFieldTouched(`lateFeeCharges[${index}].amount`);
          formikRef.setFieldTouched(`lateFeeCharges[${index}].percentage`);
          formikRef.setFieldTouched(`lateFeeCharges[${index}].daysAfterDueDateToChargeLateFees`);
          formikRef.setFieldTouched(`lateFeeCharges[${index}].account`);
          formikRef.setFieldTouched(`lateFeeCharges[${index}].feeType`);
        });
      }
      formikRef.setFieldTouched("applyOnlyToSpecificItemsOutstandingBalance");
      formikRef.setFieldTouched("lateFeeSettingsAccountsToConsiderSpecificAccounts");
      formikRef.setFieldTouched("minBalanceToApplyLateFeeAmount");
      formikRef.setFieldTouched("maxMonthlyLateFeesAmount");
    }

    const errors = (await formikRef.validateForm()) as any;
    return _.isEmpty(errors);
  };

  const updateCompanySettings = async (values: LateFeesPolicyDto) => {
    const response = (await settingsApi.updateLateFeesSettings(values).catch((error) => {
      setLoadingDialogErrorText(error);
      setLoadingDialogState(DialogState.Error);
    })) as ApiResult<any>;
    if (response.status !== undefined && !response.status) {
      setLoadingDialogErrorText(response.message);
      setLoadingDialogState(DialogState.Error);
    } else {
      trackLateFeesSettings("General Settings", values);

      setLoadingDialogState(DialogState.Success);
      didFinishUpdateProperty();
      formikGlobalRef = null;
    }
  };

  const didPressSaveButton = async (_formikRef: FormikProps<LateFeesPolicyDto> | null) => {
    if (_formikRef !== null && lateFeesData) {
      await _formikRef.submitForm();
      const isValid = await isValidForm(_formikRef);
      if (isValid) {
        setLoadingDialogState(DialogState.Show);
        setViewIndex(1);
        await updateCompanySettings(_formikRef.values);
        remove();
      }
    }
  };

  const renderActionPanelButtons = React.useCallback(() => {
    if (viewIndex === 1) {
      return <div />;
    }

    return (
      <FormActionButtons
        propsMainButton={{ type: "cta", props: { onClick: async () => await didPressSaveButton(formikGlobalRef) } }}
        propsSubButton={{ onClick: onBackdropClick }}
      />
    );
  }, [formikGlobalRef]);

  const didPressDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(0);
  };

  const onRetryButtonPress = () => {
    if (formikGlobalRef !== null) {
      setLoadingDialogState(DialogState.Hidden);
      setViewIndex(0);
    }
  };

  const didFinishUpdateProperty = () => {
    if (formikGlobalRef !== null) {
      didFinishOperation(formikGlobalRef.values);
      if (refreshEvent) {
        refreshEvent();
      }
      setLoadingDialogState(DialogState.Hidden);
    }
  };

  const renderForm = () => {
    const formInitialValues = initFormValues();
    return (
      <Formik initialValues={formInitialValues} onSubmit={() => {}} validate={validateForm}>
        {(formik) => {
          formikGlobalRef = formik;

          return (
            <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding}>
              <LateFeeSettings showDescriptionHeader />
            </View>
          );
        }}
      </Formik>
    );
  };

  const renderView = ({ index }: any) => {
    if (index === 0 && lateFeesData) {
      return renderForm();
    }
    if (index === 1 || isLoading) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={t(AppStrings.Common.Loading)}
            errorText={loadingDialogErrorText}
            successText={t(AppStrings.Common.OperationCompleted)}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
            hideButtons={hideLoadingDialogActionButtons}
          />
        </View>
      );
    }
    return <div />;
  };

  const _onBackdropClick = () => {
    if (viewIndex === 1) {
      setViewIndex(0);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      if (lateFeesData?.data === undefined || loadingDialogState !== DialogState.Success) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }
    if (viewIndex === 1) {
      return "contentOnly";
    }

    return "contentOnly";
  }, [viewIndex, lateFeesData, loadingDialogState]);

  return (
    <SettingsReadOnlyScope permission={SettingPermission.lateFees}>
      <DialogFrame
        onCloseButtonClick={_onBackdropClick}
        title={dialogTitle}
        width={editLateFeesFrameWidth}
        height={800}
        renderView={renderView}
        numViews={2}
        activeView={viewIndex}
        RenderActionPanelButtons={renderActionPanelButtons}
        frameType={frameType}
      />
    </SettingsReadOnlyScope>
  );
};

export default EditLateFees;
