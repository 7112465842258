import React, { useMemo } from "react";
import { View } from "DLUI/view";
import { Grid } from "@material-ui/core";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import type { FormikProps } from "formik";
import { getIn } from "formik";
import type { AccountType, CompanyDefaultAccountsSettingsDto } from "@doorloop/dto";
import { useTranslation } from "react-i18next";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  accountTypes: AccountType[];
  title: string;
  name: string;
  formikRef: FormikProps<CompanyDefaultAccountsSettingsDto>;
  marginTop?: number;
  leaseChargeItem?: boolean;
  isSystemAccountOrLeaseChargeItem?: boolean;
  onChange?: (event: object, value: any) => void;
}

const DefaultAccountItem: React.FC<ComponentProps> = ({
  accountTypes,
  title,
  name,
  formikRef,
  marginTop,
  leaseChargeItem,
  isSystemAccountOrLeaseChargeItem,
  onChange
}) => {
  const { isMobile } = useResponsiveHelper();
  const _marginTop = isMobile ? 20 : marginTop;
  const { t } = useTranslation();
  const { inputRightPadding } = DialogsHelper();
  const defaultValue = getIn(formikRef.values, name);

  const queryParams = useMemo(() => {
    const query: Record<string, any> = { filter_types: accountTypes };

    if (leaseChargeItem) {
      query.filter_leaseChargeItem = leaseChargeItem;
    }

    if (isSystemAccountOrLeaseChargeItem) {
      query.filter_isSystemAccountOrLeaseChargeItem = isSystemAccountOrLeaseChargeItem;
    }

    return query;
  }, [isSystemAccountOrLeaseChargeItem, leaseChargeItem, accountTypes]);

  return (
    <View alignItems={"center"} flexDirection={"row"}>
      <Grid item alignItems={"center"} container xs={12} md={6} lg={6}>
        <Text value={title} marginTop={_marginTop} />
      </Grid>
      <Grid item container xs={12} md={6} lg={6}>
        <BankAccountFormikAutoCompleteField
          addCreateOption
          uniqueIndex={"depositToAccount"}
          name={name}
          onChange={onChange}
          queryParams={queryParams}
          label={t(AppStrings.Settings.GeneralSettings.DefaultAccounts.SelectAccount)}
          marginTop={_marginTop}
          paddingRight={inputRightPadding}
          defaultValue={defaultValue}
        />
      </Grid>
    </View>
  );
};

export default DefaultAccountItem;
