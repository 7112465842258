import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";
import { lazy } from "react";

const TasksListScreen = lazy(() => import("screens/tasks/tasksList/tasksListScreen"));
const TaskDetails = lazy(() => import("screens/tasks/taskDetails/taskDetails"));
const MyTasksListScreen = lazy(() => import("screens/tasks/myTasks/myTasksListScreen"));
const OwnerRequestsListScreen = lazy(() => import("screens/tasks/ownerRequests/ownerRequestsListScreen"));
const TenantRequestsListScreen = lazy(() => import("screens/tasks/tenantRequests/tenantRequestsListScreen"));
const UnassignedTasksListScreen = lazy(() => import("screens/tasks/unassignedTasks/unassignedTasksListScreen"));
const WorkOrdersListScreen = lazy(() => import("screens/tasks/workOrders/workOrdersListScreen"));
const IncompleteTasksListScreen = lazy(() => import("screens/tasks/incompleteTasks/incompleteTasksListScreen"));

const TasksRouter = () => (
  <PageRouter>
    <LazyRoute path={Routes.TASKS_GLOBAL_NEW} render={TasksListScreen} />
    <LazyRoute path={Routes.TASK_DETAILS_NEW_WORK_ORDER} render={TaskDetails} exact />
    <LazyRoute path={Routes.TASK_DETAILS_OVERVIEW_NEW_WORK_ORDER} render={TaskDetails} exact />
    <LazyRoute exact path={Routes.TASKS_NEW_INTERNAL_TASK} render={TasksListScreen} />
    <LazyRoute exact path={Routes.TASKS_NEW_OWNER_REQUEST} render={TasksListScreen} />
    <LazyRoute exact path={Routes.TASKS_NEW_TENANT_REQUEST} render={TasksListScreen} />
    <LazyRoute exact path={Routes.TASKS_NEW_WORK_ORDER} render={TasksListScreen} />
    <LazyRoute exact path={Routes.TASKS_EDIT_INTERNAL_TASK} render={TasksListScreen} />
    <LazyRoute exact path={Routes.TASKS_EDIT_OWNER_REQUEST} render={TasksListScreen} />
    <LazyRoute path={Routes.TASKS_EDIT_TENANT_REQUEST} render={TasksListScreen} />

    <LazyRoute exact path={Routes.TASKS_EDIT_WORK_ORDER} render={TasksListScreen} />
    <LazyRoute exact path={Routes.WORK_ORDERS_EDIT_WORK_ORDER} render={WorkOrdersListScreen} />

    <LazyRoute exact path={Routes.MY_TASKS_EDIT_OWNER_REQUEST} render={MyTasksListScreen} />
    <LazyRoute exact path={Routes.MY_TASKS_EDIT_INTERNAL_TASK} render={MyTasksListScreen} />
    <LazyRoute exact path={Routes.MY_TASKS_EDIT_TENANT_REQUEST} render={MyTasksListScreen} />
    <LazyRoute exact path={Routes.MY_TASKS_EDIT_WORK_ORDER} render={MyTasksListScreen} />

    <LazyRoute exact path={Routes.UNASSIGNED_TASKS_EDIT_OWNER_REQUEST} render={UnassignedTasksListScreen} />
    <LazyRoute exact path={Routes.UNASSIGNED_TASKS_EDIT_INTERNAL_TASK} render={UnassignedTasksListScreen} />
    <LazyRoute exact path={Routes.UNASSIGNED_TASKS_EDIT_TENANT_REQUEST} render={UnassignedTasksListScreen} />
    <LazyRoute exact path={Routes.UNASSIGNED_TASKS_EDIT_WORK_ORDER} render={UnassignedTasksListScreen} />

    <LazyRoute exact path={Routes.INCOMPLETE_TASKS_EDIT_OWNER_REQUEST} component={IncompleteTasksListScreen} />
    <LazyRoute exact path={Routes.INCOMPLETE_TASKS_EDIT_INTERNAL_TASK} component={IncompleteTasksListScreen} />
    <LazyRoute exact path={Routes.INCOMPLETE_TASKS_EDIT_TENANT_REQUEST} component={IncompleteTasksListScreen} />
    <LazyRoute exact path={Routes.INCOMPLETE_TASKS_EDIT_WORK_ORDER} component={IncompleteTasksListScreen} />

    <LazyRoute path={Routes.WORK_ORDERS} render={WorkOrdersListScreen} />
    <LazyRoute path={Routes.MY_TASKS} render={MyTasksListScreen} />
    <LazyRoute path={Routes.TENANT_REQUESTS_EDIT_TENANT_REQUEST} render={TenantRequestsListScreen} />
    <LazyRoute path={Routes.TENANT_REQUESTS} render={TenantRequestsListScreen} />
    <LazyRoute path={Routes.OWNER_REQUESTS_EDIT_OWNER_REQUEST} render={OwnerRequestsListScreen} />
    <LazyRoute path={Routes.OWNER_REQUESTS} render={OwnerRequestsListScreen} />
    <LazyRoute path={Routes.UNASSIGNED_TASKS} render={UnassignedTasksListScreen} />
    <LazyRoute path={Routes.TASK_DETAILS_EDIT_WORK_ORDER} render={TaskDetails} exact />

    <LazyRoute path={Routes.TASK_DETAILS_NEW_INTERNAL_TASK} render={TaskDetails} exact />
    <LazyRoute path={Routes.TASK_DETAILS_EDIT_INTERNAL_TASK} render={TaskDetails} exact />

    <LazyRoute path={Routes.INCOMPLETE_TASKS} render={IncompleteTasksListScreen} />
    <LazyRoute path={Routes.TASK_DETAILS} render={TaskDetails} />

    <LazyRoute path={Routes.TASKS} render={TasksListScreen} />
  </PageRouter>
);

export default TasksRouter;
