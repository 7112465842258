import { createContext } from "use-context-selector";

import type React from "react";
import type { Context } from "use-context-selector";
import type {
  AutoCompleteCloseEventHandler,
  AutoCompleteMetadata,
  AutoCompleteOption,
  AutoCompleteProps,
  AutoCompleteValue
} from "./autoComplete.types";

export interface AutoCompleteContextProps<
  T,
  TMetadata extends AutoCompleteMetadata = AutoCompleteMetadata,
  TMultiple extends boolean | undefined = false,
  TDisableClearable extends boolean | undefined = false,
  TFreeSolo extends boolean | undefined = false
> {
  value: AutoCompleteValue<T, TMultiple, TDisableClearable, TFreeSolo> | null;
  options: Array<AutoCompleteOption<T>>;
  isOpen: boolean;
  isLoading?: boolean;
  paperRef: React.RefObject<HTMLElement>;
  meta?: TMetadata;
  setIsLoading: (value: boolean) => void;
  getOptionLabel?: (option: AutoCompleteOption<T>) => string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOptions: React.Dispatch<React.SetStateAction<Array<AutoCompleteOption<T>>>>;
  dispatchChange: NonNullable<AutoCompleteProps<T>["onChange"]>;
  dispatchClose: AutoCompleteCloseEventHandler<T>;
}

export type AutoCompleteContext<T, TMetadata extends AutoCompleteMetadata> = Context<
  AutoCompleteContextProps<T, TMetadata>
>;

export const AbstractAutoCompleteContext = createContext<AutoCompleteContextProps<unknown> | null>(null);
