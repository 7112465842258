import { useTranslation } from "react-i18next";
import { useRecurringState } from "hooks/useRecurringState";
import { FastField } from "formik";
import { FormikReferenceLabel } from "DLUI/form";
import type { FC } from "react";
import React from "react";
import View from "DLUI/view/view";
import { DataCy } from "@doorloop/dto";

interface ComponentProps {
  referenceLabel: string;
}

export const ReferenceLabelByRecurring: FC<ComponentProps> = ({ referenceLabel }: ComponentProps) => {
  const { t } = useTranslation();
  const { getIsRecurring } = useRecurringState(false, false, true);
  const isRecurring = getIsRecurring();
  return isRecurring ? (
    <View>
      <FastField
        component={FormikReferenceLabel}
        name={"reference"}
        backgroundColor={"dark"}
        label={t(referenceLabel)}
        viewOnly
        dataCy={DataCy.transactions.referenceNumber}
      />
    </View>
  ) : (
    <FastField
      component={FormikReferenceLabel}
      name={"reference"}
      backgroundColor={"dark"}
      label={t(referenceLabel)}
      editable
    />
  );
};
