import React, { useMemo } from "react";
import { View } from "DLUI/view";
import { Lottie } from "DLUI/lottie";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { renderExcelExportColumns } from "DLUI/lists/gridList/excelExportHelper";
import type { GridCellProps, GridProps as KendoGridProps, GridColumnReorderEvent } from "@progress/kendo-react-grid";
import { GridColumn as KendoGridColumn } from "@progress/kendo-react-grid";
import { getHeaderClassForColumn } from "DLUI/lists/gridList/utils";
import { renderGridFooter } from "DLUI/lists/gridList/footers/footerCell";
import ColumnsMenu from "../columnsMenu";
import NativeGrid from "./index";
import type { GridListType, Queryable } from "DLUI/lists/types";
import { EXPANSION_IDENTIFIER, SELECTION_IDENTIFIER } from "DLUI/lists/types";
import loadingDotsLottie from "assets/lottie/loadingDotsLottie.json";
import type { GridColumn, VisibleGridColumn } from "DLUI/lists/gridList/gridList";
import type { DataResult, SortDescriptor } from "@progress/kendo-data-query";
import type { TFunction } from "react-i18next";
import type { GridProps } from "@progress/kendo-react-grid/dist/npm/interfaces/GridProps";
import { GridRow } from "DLUI/lists/gridList/dataGrid/gridRow";
import type { ClassConstructor } from "class-transformer";

const lastItem = (arr) => arr.slice(-1)[0];

const getOptionalCheckboxColumn = (shouldShowCheckboxColumn: boolean) =>
  shouldShowCheckboxColumn
    ? [
        {
          field: SELECTION_IDENTIFIER,
          title: SELECTION_IDENTIFIER,
          formatType: undefined,
          subColumns: undefined,
          className: "grid-checkbox"
        }
      ]
    : [];

export interface GridRenderProps<DtoType extends object, GetAllDtoType extends Queryable> {
  _exportFileName: string;
  currencySymbol: string;
  allGridColumns: GridColumn[];
  visibleGridColumns: VisibleGridColumn[];
  dataSource: DataResult;
  dataTotalLine?: Record<string, number>;
  excelExporterRef: React.Ref<any>;
  getSortedDataSource: (dataSource: DataResult, sort: SortDescriptor[]) => DataResult;
  groupProp: KendoGridProps["group"];
  isMultiSelect?: boolean;
  onColumnsSubmit: (next: GridColumn[]) => void;
  onColumnsReset: () => void;
  onGridRowPress?: (props: GridCellProps) => void;
  renderGridCell?: GridProps["cellRender"];
  renderGridHeaderCell?: GridProps["headerCellRender"];
  reorderable?: boolean;
  requestInProgress: boolean;
  rowHeight?: number;
  setSort: (sort: SortDescriptor[]) => void;
  sort: SortDescriptor[];
  t: TFunction<"translation">;
  onExpandChange: KendoGridProps["onExpandChange"];
  onSelectionChange?: KendoGridProps["onSelectionChange"];
  overrideGridType?: GridListType;
  resetSelection?: () => void;
  resizable?: boolean;
  isTabletOrMobile?: boolean;
  isPrinting?: boolean;
  originalDataHashMap?: Map<string, object>;
  dto?: ClassConstructor<object>;
  onColumnReorder?: (event: GridColumnReorderEvent) => void;
  dataItemKey?: string;
  hideColumnMenu?: boolean;
  columnMenuItemsToHide?: Array<keyof DtoType>;
}

export const GridRender = <DtoType extends object, GetAllDtoType extends Queryable>({
  rowHeight,
  requestInProgress,
  setSort,
  dataSource: originalDataSource,
  getSortedDataSource,
  groupProp,
  sort,
  renderGridCell,
  renderGridHeaderCell,
  resizable,
  reorderable,
  onGridRowPress,
  excelExporterRef,
  _exportFileName,
  allGridColumns,
  visibleGridColumns,
  currencySymbol,
  t,
  dataTotalLine,
  onColumnsSubmit,
  onColumnsReset,
  isMultiSelect,
  onExpandChange,
  isTabletOrMobile,
  isPrinting,
  onSelectionChange,
  overrideGridType,
  originalDataHashMap,
  dto,
  onColumnReorder,
  dataItemKey,
  hideColumnMenu,
  columnMenuItemsToHide = []
}: GridRenderProps<DtoType, GetAllDtoType>) => {
  const sortedDataSource = useMemo(
    () => getSortedDataSource(originalDataSource, sort),
    [originalDataSource, sort, getSortedDataSource]
  );

  const visibleColumnsWithOptionalCheckbox = useMemo(
    () => [...getOptionalCheckboxColumn(Boolean(isMultiSelect)), ...visibleGridColumns] as VisibleGridColumn[],
    [visibleGridColumns, isMultiSelect]
  );

  /**
   * Custom row rendering has only one purpose: editable rows.
   * If we don't have editable rows, this will not do anything
   */
  const rowRender = React.useMemo(() => GridRow({ allGridColumns, dto, originalDataHashMap }), [allGridColumns, dto]);

  const gridProps: KendoGridProps = {
    sortable: true,
    dataItemKey: dataItemKey || "id",
    onSortChange: (e) => setSort(e.sort),
    sort,
    group: groupProp,
    data: sortedDataSource,
    cellRender: renderGridCell,
    reorderable: reorderable || true,
    resizable: resizable || true,
    className: onGridRowPress ? "clickable-row" : "",
    groupable: { footer: "visible", enabled: true },
    expandField: EXPANSION_IDENTIFIER,
    onExpandChange,
    headerCellRender: renderGridHeaderCell,
    onSelectionChange,
    rowHeight,
    rowRender,
    onColumnReorder
  };

  if (isMultiSelect) {
    gridProps.selectable = {
      enabled: !isTabletOrMobile,
      mode: "multiple",
      drag: false
    };
    gridProps.selectedField = SELECTION_IDENTIFIER;
  }

  if (requestInProgress) {
    return (
      <View minHeight={400} justifyContent={"center"} alignItems={"center"} width={"100%"}>
        <Lottie animationData={loadingDotsLottie} height={80} width={80} />
      </View>
    );
  }

  if (originalDataSource.total === 0) {
    return null;
  }

  const filterOutHiddenColumns = (column: VisibleGridColumn) =>
    !columnMenuItemsToHide.includes(column.field as keyof DtoType);

  const columnMenu = (props) => (
    <ColumnsMenu
      onColumnsSubmit={onColumnsSubmit}
      onColumnsReset={onColumnsReset}
      visibleColumns={visibleGridColumns.filter(filterOutHiddenColumns)}
      defaultColumns={allGridColumns.filter(filterOutHiddenColumns)}
      {...props}
    />
  );

  return (
    <>
      <ExcelExport
        data={sortedDataSource.data}
        group={groupProp}
        ref={excelExporterRef}
        collapsible
        filterable
        fileName={_exportFileName}
      >
        {renderExcelExportColumns(visibleGridColumns, currencySymbol, sortedDataSource.data, t, dataTotalLine)}
      </ExcelExport>

      <NativeGrid overrideGridType={overrideGridType} kendoProps={gridProps}>
        {visibleColumnsWithOptionalCheckbox.map((currentColumn: VisibleGridColumn, index) => {
          let columnWidth = currentColumn.width;

          if (isTabletOrMobile && !isPrinting && currentColumn?.field !== "selected") {
            columnWidth = currentColumn.width ? (currentColumn.width > 100 ? currentColumn.width : 100) : 100;
          }

          return (
            <KendoGridColumn
              id={currentColumn.field}
              key={"CI" + index}
              groupable
              width={columnWidth}
              filterable={false}
              field={currentColumn.field}
              title={t(currentColumn.title)}
              footerCell={(props) => renderGridFooter(props, currentColumn, sortedDataSource.data, dataTotalLine)}
              headerClassName={getHeaderClassForColumn({
                field: currentColumn.field,
                formatType: currentColumn.formatType
              })}
              cell={currentColumn?.renderCell}
              {...(!hideColumnMenu && currentColumn.field === lastItem(visibleGridColumns).field && { columnMenu })}
              headerSelectionValue={currentColumn.headerSelectionValue}
              className={currentColumn.className}
            >
              {currentColumn.subColumns?.map((currentSubColumn, index) => (
                <KendoGridColumn
                  key={"subColumn" + index}
                  groupable
                  width={currentSubColumn.width}
                  filterable={false}
                  field={currentSubColumn.field}
                  title={t(currentSubColumn.title)}
                  cell={currentSubColumn?.renderCell}
                  footerCell={(props) =>
                    renderGridFooter(props, currentSubColumn, sortedDataSource.data, dataTotalLine)
                  }
                  headerClassName={getHeaderClassForColumn({
                    field: currentColumn.field,
                    formatType: currentSubColumn.formatType
                  })}
                />
              ))}
            </KendoGridColumn>
          );
        })}
      </NativeGrid>
    </>
  );
};
