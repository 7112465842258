import type { FilterInputComponentProps } from "@/components/DLUI/screen/filterPanel/filterInputs/filterInputComponents";
import {
  AutocompleteFilterInput,
  DatePickerFilterInput,
  DateRangePickerFilterInput,
  RemoteAutocompleteFilterInput,
  RemoteMultiAutocompleteFilterInput,
  TextFilterInput
} from "@/components/DLUI/screen/filterPanel/filterInputs/filterInputComponents";
import type {
  FilterPanelDatePicker,
  FilterPanelOptionItemType,
  FilterPanelRemoteFiltering,
  FilterPanelStaticOptionsFiltering,
  FilterPanelTextFieldFiltering
} from "@/components/DLUI/screen/filterPanel/filterPanel";
import { useContextSelector } from "use-context-selector";
import { FilterChipsContext } from "../context";
import type { FilterOption } from "@/components/DLUI/screen/filterPanel/filterInputs/types";
import { useEffect, type ComponentType } from "react";
import { useSearchParamsSetter } from "@/hooks/useSearchParamsSetter";
import { useTranslation } from "react-i18next";
import Text from "@/components/DLUI/text";
import { DateFormats } from "@doorloop/dto";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import { useSearchParams } from "@/hooks/useSearchParams";
import type { FilterPanelDateRangePicker } from "@/components/DLUI/form/datePicker/dateRangePicker/types";
import { useTypedTranslation } from "@/locale";

function TextFilterRenderer({
  filterOption
}: FilterInputComponentProps<unknown, FilterPanelTextFieldFiltering<unknown>>) {
  const addFilterChip = useContextSelector(FilterChipsContext, (context) => context.addFilterChip);
  const removeFilterChip = useContextSelector(FilterChipsContext, (context) => context.removeFilterChip);
  const [searchParams] = useSearchParams();
  const queryValue = searchParams.get(filterOption.filterFieldName);

  useEffect(() => {
    if (!filterOption.filterFieldName) {
      return;
    }

    if (!queryValue) {
      removeFilterChip(filterOption.filterFieldName);
      return;
    }

    addFilterChip({
      key: filterOption.filterFieldName,
      displayValue: queryValue as string
    });
  }, [queryValue, filterOption.filterFieldName, addFilterChip, removeFilterChip]);

  return <TextFilterInput filterOption={filterOption} />;
}

function RemoteAutocompleteRenderer({
  filterOption
}: FilterInputComponentProps<unknown, FilterPanelRemoteFiltering<unknown>>) {
  const addFilterChip = useContextSelector(FilterChipsContext, (context) => context.addFilterChip);
  const removeFilterChip = useContextSelector(FilterChipsContext, (context) => context.removeFilterChip);
  const [searchParams] = useSearchParams();

  return (
    <RemoteAutocompleteFilterInput
      filterOption={filterOption}
      onValueChange={(props) => {
        if (!searchParams.get(filterOption.filterFieldName)) {
          removeFilterChip(filterOption.filterFieldName);
          return;
        }

        if (!props.value?.displayName) {
          return;
        }

        addFilterChip({
          key: filterOption.filterFieldName,
          displayValue: props.value.displayName,
          displayKey: filterOption.name,
          disabled: props.disableClearable
        });
      }}
    />
  );
}

function RemoteMultiAutocompleteRenderer({
  filterOption
}: FilterInputComponentProps<unknown, FilterPanelRemoteFiltering<unknown>>) {
  const addFilterChip = useContextSelector(FilterChipsContext, (context) => context.addFilterChip);
  const removeFilterChip = useContextSelector(FilterChipsContext, (context) => context.removeFilterChip);
  const { t } = useTypedTranslation();
  return (
    <RemoteMultiAutocompleteFilterInput
      filterOption={filterOption}
      onValueChange={({ defaultValue }) => {
        if (!filterOption.filterFieldName || !defaultValue) {
          return;
        }

        if (defaultValue.length === 0) {
          removeFilterChip(filterOption.filterFieldName);
          return;
        }

        addFilterChip({
          key: filterOption.filterFieldName,
          displayValue: t("common.xSelected", { x: defaultValue.length })
        });
      }}
    />
  );
}

function AutocompleteRenderer({
  filterOption
}: FilterInputComponentProps<unknown, FilterPanelStaticOptionsFiltering<unknown>>) {
  const addFilterChip = useContextSelector(FilterChipsContext, (context) => context.addFilterChip);
  const removeFilterChip = useContextSelector(FilterChipsContext, (context) => context.removeFilterChip);
  const setSearchParams = useSearchParamsSetter();

  return (
    <AutocompleteFilterInput
      filterOption={filterOption}
      onValueChange={(props) => {
        setSearchParams((searchParams) => {
          const key = filterOption.filterFieldName || filterOption.values[0].filterFieldName;
          if (!key) {
            return;
          }

          if (!searchParams.get(key)) {
            removeFilterChip(key);
            return;
          }

          if (!props.value?.displayName) {
            return;
          }

          addFilterChip({
            key,
            displayKey: filterOption.name,
            displayValue: props.value.displayName,
            disabled: props.disableClearable
          });
        });
      }}
    />
  );
}

function DatePickerRenderer({ filterOption }: FilterInputComponentProps<unknown, FilterPanelDatePicker<unknown>>) {
  const addFilterChip = useContextSelector(FilterChipsContext, (context) => context.addFilterChip);
  const removeFilterChip = useContextSelector(FilterChipsContext, (context) => context.removeFilterChip);
  const setSearchParams = useSearchParamsSetter();

  return (
    <DatePickerFilterInput
      filterOption={filterOption}
      onValueChange={(props) => {
        setSearchParams((searchParams) => {
          if (!props.pickerValue) {
            return;
          }

          if (!searchParams.get(filterOption.filterFieldName)) {
            removeFilterChip(filterOption.filterFieldName);
            return;
          }

          addFilterChip({
            key: filterOption.filterFieldName,
            displayValue: props.pickerValue.format(DateFormats.MONTH_AND_DATE_AND_YEAR)
          });
        });
      }}
    />
  );
}

function DateRangePickerRenderer({
  filterOption
}: FilterInputComponentProps<unknown, FilterPanelDateRangePicker<unknown>>) {
  const addFilterChip = useContextSelector(FilterChipsContext, (context) => context.addFilterChip);
  const removeFilterChip = useContextSelector(FilterChipsContext, (context) => context.removeFilterChip);
  const setSearchParams = useSearchParamsSetter();
  const { t } = useTranslation();

  return (
    <DateRangePickerFilterInput
      filterOption={filterOption}
      onValueChange={(props) => {
        setSearchParams((searchParams) => {
          if (!props.startDateValue && !props.endDateValue && !props.selectedPeriod) {
            return;
          }

          const startKeyExists = Boolean(searchParams.get(filterOption.startDateFieldKey));
          const endKeyExists = Boolean(searchParams.get(filterOption.endDateFieldKey));

          if (!startKeyExists) {
            removeFilterChip(filterOption.startDateFieldKey);
          } else {
            addFilterChip({
              key: filterOption.startDateFieldKey,
              displayValue: moment(props.startDateValue).format(DateFormats.MONTH_AND_DATE_AND_YEAR),
              displayKey: filterOption.startDateDisplayName ? t(filterOption.startDateDisplayName) : undefined,
              disabled: true
            });
          }

          if (!endKeyExists) {
            removeFilterChip(filterOption.endDateFieldKey);
          } else {
            addFilterChip({
              key: filterOption.endDateFieldKey,
              displayValue: moment(props.endDateValue).format(DateFormats.MONTH_AND_DATE_AND_YEAR),
              displayKey: filterOption.endDateDisplayName ? t(filterOption.endDateDisplayName) : undefined,
              disabled: true
            });
          }
        });
      }}
    />
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filterTypeToRendererMap: Record<FilterPanelOptionItemType, ComponentType<any>> = {
  staticFiltering: AutocompleteRenderer,
  remoteFiltering: RemoteAutocompleteRenderer,
  remoteMultiFiltering: RemoteMultiAutocompleteRenderer,
  datePicker: DatePickerRenderer,
  dateRangePicker: DateRangePickerRenderer,
  textFieldFiltering: TextFilterRenderer
};

const useStyles = makeStyles({
  filterOptionsList: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 8
  },
  filterOptionsItem: {
    width: "100%"
  }
});

interface FilterInputRendererItemProps<TQuery> {
  filterOption: FilterOption<TQuery>;
  showLabel?: boolean;
}

export function FilterInputRendererItem<TQuery>({
  filterOption,
  showLabel = false
}: FilterInputRendererItemProps<TQuery>) {
  const { t } = useTranslation();
  const classes = useStyles();
  const Component = filterTypeToRendererMap[filterOption.type];

  return (
    <div className={clsx(classes.filterOptionsItem)}>
      {showLabel && filterOption.label && (
        <Text fontSize={14} bold marginTop={10} marginBottom={12}>
          {t(filterOption.label)}
        </Text>
      )}
      <Component filterOption={filterOption} />
    </div>
  );
}

export function FilterInputRendererList() {
  const classes = useStyles();
  const filterOptions = useContextSelector(FilterChipsContext, (context) => context.filterOptions);

  return (
    <ul className={clsx(classes.filterOptionsList, "border-box", "list-reset")}>
      {filterOptions.map((filterOption, index) => (
        <li key={index} className={classes.filterOptionsItem}>
          <FilterInputRendererItem filterOption={filterOption} showLabel />
        </li>
      ))}
    </ul>
  );
}
