"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayrixPaymentTokenBankType = void 0;
var PayrixPaymentTokenBankType;
(function (PayrixPaymentTokenBankType) {
    PayrixPaymentTokenBankType["CHECKING_ACCOUNT"] = "8";
    PayrixPaymentTokenBankType["SAVINGS_ACCOUNT"] = "9";
    PayrixPaymentTokenBankType["CORPORATE_CHECKING_ACCOUNT"] = "10";
    PayrixPaymentTokenBankType["CORPORATE_SAVINGS_ACCOUNT"] = "11";
})(PayrixPaymentTokenBankType || (exports.PayrixPaymentTokenBankType = PayrixPaymentTokenBankType = {}));
