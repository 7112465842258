import Routes from "components/appRouter/routes";
import { CommunicationsScreen } from "screens/communications/communicationsScreen";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";

const CommunicationsRouter = () => (
  <PageRouter>
    <LazyRoute path={[Routes.COMMUNICATIONS_CONFIRM, Routes.COMMUNICATIONS_VIEW]} exact render={CommunicationsScreen} />
    <LazyRoute path={[Routes.COMMUNICATIONS]} render={CommunicationsScreen} />
  </PageRouter>
);

export default CommunicationsRouter;
