import type { PartialKeys, VirtualizerOptions } from "@tanstack/react-virtual";
import { useVirtualizer } from "@tanstack/react-virtual";
import React, { useCallback, useRef } from "react";
import { VirtualScroll } from "../virtualScroll";

type VirtualListVirtualizerOptions = PartialKeys<
  VirtualizerOptions<HTMLDivElement, Element>,
  "count" | "getScrollElement" | "observeElementRect" | "observeElementOffset" | "scrollToFn"
>;

interface VirtualListProps<TListItem> extends VirtualListVirtualizerOptions {
  items: TListItem[];
  children: (item: TListItem, index: number) => React.ReactNode;
  maxHeight?: number;
}

export function VirtualList<TListItem>({ items, children, maxHeight = 500, ...rest }: VirtualListProps<TListItem>) {
  const listRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: useCallback(() => listRef.current, []),
    ...rest
  });

  return (
    <div
      ref={listRef}
      style={{
        maxHeight,
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden"
      }}
    >
      <VirtualScroll virtualizer={virtualizer}>{(index) => children(items[index], index)}</VirtualScroll>
    </div>
  );
}
