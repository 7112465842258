"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAllTenantsQuery = void 0;
const class_validator_1 = require("class-validator");
const isDateStringWithoutTime_validator_1 = require("../common/validators/isDateStringWithoutTime.validator");
const getAllBaseRequest_query_1 = require("../getAllBaseRequest.query");
const prospectStatus_enum_1 = require("./prospectStatus.enum");
const tenantType_enum_1 = require("./tenantType.enum");
class GetAllTenantsQuery extends getAllBaseRequest_query_1.GetAllBaseQueryRequest {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.GetAllTenantsQuery = GetAllTenantsQuery;
__decorate([
    (0, class_validator_1.IsEnum)(tenantType_enum_1.TenantType),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllTenantsQuery.prototype, "filter_type", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], GetAllTenantsQuery.prototype, "filter_properties", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllTenantsQuery.prototype, "filter_property", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], GetAllTenantsQuery.prototype, "filter_group", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllTenantsQuery.prototype, "filter_lease", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllTenantsQuery.prototype, "filter_leaseDraft", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllTenantsQuery.prototype, "filter_withoutLeaseDraft", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllTenantsQuery.prototype, "filter_unit", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], GetAllTenantsQuery.prototype, "filter_units", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Object)
], GetAllTenantsQuery.prototype, "filter_active", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", Object)
], GetAllTenantsQuery.prototype, "filter_createdDate_to", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", Object)
], GetAllTenantsQuery.prototype, "filter_createdDate_from", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(prospectStatus_enum_1.ProspectStatus),
    __metadata("design:type", String)
], GetAllTenantsQuery.prototype, "filter_prospect_status", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllTenantsQuery.prototype, "filter_prospect_assignedToUser", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllTenantsQuery.prototype, "filter_prospect_interests_property", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], GetAllTenantsQuery.prototype, "filter_prospect_interests_properties", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllTenantsQuery.prototype, "filter_prospect_interests_portfolio", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllTenantsQuery.prototype, "filter_prospect_interests_unit", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], GetAllTenantsQuery.prototype, "filter_prospect_interests_units", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(255),
    __metadata("design:type", String)
], GetAllTenantsQuery.prototype, "filter_prospect_campaignSource", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(255),
    __metadata("design:type", String)
], GetAllTenantsQuery.prototype, "filter_prospect_campaignMedium", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(255),
    __metadata("design:type", String)
], GetAllTenantsQuery.prototype, "filter_prospect_campaignName", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(255),
    __metadata("design:type", String)
], GetAllTenantsQuery.prototype, "filter_prospect_campaignContent", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(255),
    __metadata("design:type", String)
], GetAllTenantsQuery.prototype, "filter_prospect_campaignTerm", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(0),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], GetAllTenantsQuery.prototype, "filter_prospect_min_bedrooms", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(0),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], GetAllTenantsQuery.prototype, "filter_prospect_bathrooms", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(0),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], GetAllTenantsQuery.prototype, "filter_prospect_maxRent", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllTenantsQuery.prototype, "filter_prospect_nextTaskDateOnOrBefore", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllTenantsQuery.prototype, "filter_emailExists", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], GetAllTenantsQuery.prototype, "filter_batch", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllTenantsQuery.prototype, "filter_notBoardMember", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], GetAllTenantsQuery.prototype, "filter_name_text", void 0);
