import type { DialogProps } from "./dialogsProvider.types";
import { DialogRoutes } from "./dialogsRoutes";
import { ViewCommunicationDialog } from "screens/communications/communicationDialog/viewCommunicationDialog";
import { EditAnnouncementDialog } from "screens/announcements/announcementDetails/editAnnouncementDialog";

class AnnouncementsDialogsProvider {
  matchRoutes(pathname: string): DialogProps | undefined {
    if (pathname.includes(DialogRoutes.VIEW_COMMUNICATION)) {
      return {
        type: "viewCommunication",
        Content: ViewCommunicationDialog
      };
    }
    if (pathname.match(/announcements\/\w{24}\/(overview|communications|files|notes)\/edit/)) {
      return {
        type: "editAnnouncement",
        Content: EditAnnouncementDialog
      };
    }
  }
}

export const announcementsDialogsProvider = new AnnouncementsDialogsProvider();
