import React, { useEffect, useMemo, useState } from "react";
import loadingLogo from "assets/lottie/loading_logo.json";
import spinnerErrorAnimation from "assets/lottie/spinner-fail.json";
import { Lottie } from "DLUI/lottie";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { ViewBackgroundColor } from "DLUI/view/view";
import type { FormikProps } from "formik";
import AppStrings from "locale/keys";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import makeStyles from "./styles";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";

export interface LoadingDialogProps {
  dialogState: DialogState;
  loadingText?: string;
  errorText?: string;
  successText?: string;
  onRetryButtonPress?: (formikRef?: FormikProps<any>) => void;
  formikRef?: FormikProps<any>;
  didPressDismissButton?: () => void;
  hideDismissButton?: boolean;
  /**
   * @deprecated Please use showRetryButton (opt-in)
   */
  hideRetryButton?: boolean;
  showRetryButton?: boolean;
  hideButtons?: boolean;
  size?: "small";
  onClose?: (values?: any, shouldRefresh?: boolean) => void;
  backgroundColor?: ViewBackgroundColor;
  minHeight?: number;
  height?: number;
  showLoadingText?: boolean;
  showTitle?: boolean;
}

export const LoadingDialog: React.FC<LoadingDialogProps> = ({
  dialogState,
  errorText,
  loadingText,
  showLoadingText,
  onRetryButtonPress,
  formikRef,
  hideDismissButton,
  didPressDismissButton,
  size,
  hideRetryButton,
  showRetryButton,
  hideButtons,
  backgroundColor,
  minHeight,
  height,
  showTitle = true
}: LoadingDialogProps) => {
  const [openDialog, setOpenDialog] = useState(dialogState !== DialogState.Hidden);
  const classes = makeStyles({});
  const [operationFinished, setOperationFinished] = useState(false);

  useEffect(() => {
    if (dialogState === DialogState.Show) {
      setOpenDialog(true);
      setOperationFinished(false);
    }
  }, [dialogState]);

  const { lottieSize, titleFontSize } = useMemo(() => {
    let lottieSize = 0;
    let titleFontSize = 0;
    if (size === "small") {
      lottieSize = 30;
      titleFontSize = 12;
    } else {
      lottieSize = 60;
      titleFontSize = 24;
    }
    return { lottieSize, titleFontSize };
  }, []);

  const getCurrentLottie = () => {
    switch (dialogState) {
      case DialogState.Show:
        return loadingLogo;
      case DialogState.Success:
        return loadingLogo;
      case DialogState.Error:
        return spinnerErrorAnimation;
    }
  };

  const shouldLoopLottie = (): boolean => {
    switch (dialogState) {
      case DialogState.Show:
        return true;
      case DialogState.Error:
        return false;
      case DialogState.Success:
        return false;
      default:
        return false;
    }
  };

  const getDialogContentText = (): string => {
    switch (dialogState) {
      case DialogState.Show:
        return (showLoadingText && loadingText) || "";
      case DialogState.Error:
        return errorText || "";
      case DialogState.Success:
        return ""; //successText || "";
      default:
        return "";
    }
  };

  const lottieAnimationData = getCurrentLottie();
  const shouldLoopLottieFile = shouldLoopLottie();
  const dialogContentText = getDialogContentText();

  const didPressretryButton = () => {
    if (onRetryButtonPress) {
      onRetryButtonPress(formikRef);
    }
  };

  const _didPressDismissButton = () => {
    if (didPressDismissButton) {
      didPressDismissButton();
    } else {
      // didFinishOperation();
    }
  };
  const renderDialogActions = () => {
    if (dialogState !== DialogState.Error || hideButtons) {
      return null;
    }
    return (
      <View marginTop={20} justifyContent={"center"} flexDirection={"row"} gap={10}>
        {hideDismissButton ? null : (
          <DLButton
            onClick={_didPressDismissButton}
            actionText={AppStrings.Common.Dismiss}
            variant={DLButtonVariantsEnum.OUTLINED}
            color={DLButtonColorsEnum.NEUTRAL}
            size={DLButtonSizesEnum.LARGE}
          />
        )}
        {showRetryButton && (
          <DLButton
            onClick={didPressretryButton}
            actionText={AppStrings.Common.Retry}
            variant={DLButtonVariantsEnum.CONTAINED}
            color={DLButtonColorsEnum.SECONDARY}
            size={DLButtonSizesEnum.LARGE}
          />
        )}
      </View>
    );
  };

  const didAnimateLottie = () => {
    if (dialogState === DialogState.Success) {
      if (!operationFinished) {
        setOperationFinished(true);
        // didFinishOperation();
      }
    }
  };

  const renderLottie = () => {
    if (!openDialog) {
      return <div />;
    }
    return (
      <Lottie
        loop={shouldLoopLottieFile}
        animationData={lottieAnimationData}
        width={lottieSize}
        height={lottieSize}
        onAnimationCompleted={didAnimateLottie}
        autoPlay
      />
    );
  };

  const renderTextTitle = () => {
    if (showTitle && dialogState === DialogState.Error) {
      return (
        <Text color={"black"} fontSize={titleFontSize} fontWeight={700} value={AppStrings.Common.NetworkErrorTitle} />
      );
    }
  };

  return (
    <View
      overflow={"scroll"}
      backgroundColor={backgroundColor || "light"}
      className={classes.loadingDialogContainer}
      minHeight={minHeight}
      flex={dialogState === DialogState.Error ? undefined : 1}
      alignItems={"center"}
      overflowWrap={dialogState === DialogState.Error ? "anywhere" : undefined}
      width={"100%"}
      height={height}
      noWrap
    >
      <View
        marginTop={20}
        alignItems={"center"}
        id="alert-dialog-slide-title"
        flex={dialogState === DialogState.Show || dialogState === DialogState.Success ? 1 : undefined}
        flexDirection={"row"}
      >
        <View marginTop={20} justifyContent={"center"} alignItems={"center"} flexDirection={"row"}>
          {renderLottie()}
        </View>
      </View>
      <View flex={dialogContentText ? 1 : undefined} marginTop={20} alignItems={"center"} width={"100%"} noWrap>
        {renderTextTitle ? <View alignItems={"center"}>{renderTextTitle()}</View> : null}
        {dialogContentText ? (
          <Text
            marginTop={20}
            marginBottom={40}
            align={"center"}
            color={"black"}
            fontSize={titleFontSize}
            paddingLeft={20}
            paddingRight={20}
            maxWidth={"90%"}
            whiteSpace={"pre-line"}
          >
            {dialogContentText}
          </Text>
        ) : null}
      </View>
      <View marginBottom={20} noWrap>
        {renderDialogActions()}
      </View>
    </View>
  );
};
