import React, { useMemo, useState } from "react";
import type { LinkedResourceDto } from "@doorloop/dto";
import Grid from "@material-ui/core/Grid";
import { propertiesApi } from "api/propertiesApi";
import { unitsApi } from "api/unitsApi";
import { IconButton } from "DLUI/form";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { getIn } from "formik";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import { DeleteXIcon } from "assets/icons";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";

interface ComponentProps {
  formikRef: FormikProps<any>;
  name: string;
  didPressDeleteLine: (lineIndex: number) => void;
  lineIndex: number;
}

const InterestLine = ({ formikRef, name, didPressDeleteLine, lineIndex }: ComponentProps) => {
  const { t } = useTranslation();

  const { propertyDefaultValue, unitDefaultValue } = useMemo(() => {
    const propertyDefaultValue: LinkedResourceDto | undefined = getIn(formikRef.values, `${name}.property`);
    const unitDefaultValue: LinkedResourceDto | undefined = getIn(formikRef.values, `${name}.unit`);
    return { propertyDefaultValue, unitDefaultValue };
  }, []);

  const [renderUnitField, setRenderUnitField] = useState<boolean>(propertyDefaultValue !== undefined);

  const renderUnitAutoComplete = () => {
    const propertySelection = getIn(formikRef.values, `${name}.property`);
    if (!renderUnitField || propertySelection === undefined || propertySelection === "") {
      return null;
    }
    return (
      <Grid item lg={5} md={5} xs={11}>
        <FormikCachedAsyncAutoComplete
          uniqueIndex={"unit"}
          apiHandler={unitsApi}
          displayNameKey={"name"}
          filterFieldName={"filter_text"}
          filterFieldValue={"name"}
          selectionFields={["id", "class"]}
          name={`${name}.unit`}
          label={t(AppStrings.Leases.NewLease.Unit)}
          queryParams={{
            filter_property: propertySelection
          }}
          defaultValue={unitDefaultValue as any}
          paddingRight={20}
          marginTop={20}
        />
      </Grid>
    );
  };

  const refreshUnitField = () => {
    setRenderUnitField(false);
    setTimeout(() => {
      setRenderUnitField(true);
    }, 0);
  };

  const didSelectProperty = (event: any, selectedValue: any) => {
    refreshUnitField();
  };

  const didPressDeleteButton = () => {
    didPressDeleteLine(lineIndex);
  };

  return (
    <View alignItems={"center"} flexDirection={"row"} justifyContent={"center"}>
      <View flexDirection={"row"} flex={1} alignItems={"center"}>
        <Grid item lg={6} md={6} xs={11}>
          <FormikCachedAsyncAutoComplete
            uniqueIndex={"property"}
            apiHandler={propertiesApi}
            displayNameKey={"name"}
            filterFieldName={"filter_text"}
            filterFieldValue={"name"}
            selectionFields={["id", "class"]}
            name={`${name}.property`}
            label={t(AppStrings.Common.FilterOptions.Property)}
            onChange={didSelectProperty}
            defaultValue={propertyDefaultValue as any}
            paddingRight={20}
            marginTop={20}
          />
        </Grid>

        {renderUnitAutoComplete()}

        <Grid item lg={1} md={1} xs={1}>
          <View marginTop={20}>
            <IconButton onClick={didPressDeleteButton} aria-label="delete" Icon={DeleteXIcon} width={15} height={15} />
          </View>
        </Grid>
      </View>
    </View>
  );
};

export default InterestLine;
