import React from "react";

import type { RequestsSettingsFormikWrapperProps } from "screens/settings/requestsSettings/requestsSettingsFormikWrapper";
import { RequestsSettingsFormikWrapper } from "screens/settings/requestsSettings/requestsSettingsFormikWrapper";
import { settingsApi } from "api/settingsApi";
import AppStrings from "../../../../locale/keys";
import { SettingPermission } from "@doorloop/dto";

export const OwnerRequestsSettings: React.FC<RequestsSettingsFormikWrapperProps> = (
  props: RequestsSettingsFormikWrapperProps
) => (
  <RequestsSettingsFormikWrapper
    {...props}
    getRequestsSettingsApi={settingsApi.getOwnerRequestsNotifications}
    updateRequestsSettingsApi={settingsApi.updateOwnerRequestsNotifications}
    textTitle={AppStrings.Common.OwnerRequestsSettings}
    settingPermission={SettingPermission.ownerRequests}
    notifyRecipientFormikFieldName={"sendEmailToOwnerWhenRequestIsReceived"}
    notifyRecipientFormikFieldLabel={AppStrings.Common.NotifyOwnerOnReceived}
    notifyAssignedOnReceivedLabel={AppStrings.Common.OwnerRequestNotifyAssignedOnReceived}
    automaticallyAssignUsersLabel={AppStrings.Common.OwnerRequestAutomaticallyAssignUsers}
  />
);
