import type { PreviewThemeForDeviceProps } from "DLUI/preview/previewThemeForDevice";
import { PreviewThemeForDevice } from "DLUI/preview/previewThemeForDevice";
import Image from "DLUI/image/image";
import ColorsEnum from "utils/colorsEnum";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { IMAGE_HEIGHT_IN_MOBILE } from "DLUI/preview/constants";
import type { Theme } from "styles/defaultTheme";

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      display: "grid"
    },
    gridOneItem: {
      gridColumn: 1,
      gridRow: 1
    },
    zIndex: {
      zIndex: 1
    },
    screenContainer: {
      display: "flex",
      flexDirection: "column",
      paddingRight: 9,
      paddingLeft: 9,
      paddingTop: 8,
      width: 159,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 14,
        width: 232
      }
    },
    upperBar: {
      backgroundColor: ColorsEnum.LightGray,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      height: 15,
      paddingTop: 0,
      [theme.breakpoints.down("sm")]: {
        paddingTop: 5,
        height: 20
      }
    },
    bottomBar: {
      backgroundColor: ColorsEnum.LightGray,
      height: "100%",
      display: "flex"
    },
    bottomContainer: {
      backgroundColor: "rgba(245, 246, 250, 1)",
      height: "calc(100% - 18px)",
      width: "calc(100% - 32px)",
      marginTop: "auto",
      marginRight: "auto",
      marginLeft: "auto",
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4
    },
    iconsContainer: { display: "flex", flexDirection: "row", gap: 4 },
    time: {
      opacity: 0.1
    }
  };
});

export const PreviewForMobile: React.FC<Omit<PreviewThemeForDeviceProps, "type">> = (props) => {
  const { isMobile } = useResponsiveHelper();
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={clsx([classes.gridOneItem, classes.screenContainer])}>
        <div className={classes.upperBar}>
          <Image
            color={ColorsEnum.LightGray}
            src={isMobile ? "/images/iphone-bar-lg.svg" : "/images/iphone-bar-md.svg"}
          />
        </div>
        <PreviewThemeForDevice
          {...props}
          websiteVerticalPadding={isMobile ? 8 : 4}
          backgroundImageDimensions={{ height: IMAGE_HEIGHT_IN_MOBILE }}
        />
        <div className={classes.bottomBar}>
          <div className={classes.bottomContainer} />
        </div>
      </div>
      <Image
        className={clsx([classes.gridOneItem, classes.zIndex])}
        src={isMobile ? "/images/iphone-large.svg" : "/images/iphone.svg"}
      />
    </div>
  );
};
