import type { OwnerDto, TenantDto } from "@doorloop/dto";
import { DataCy, LeaseTenantStepStatus } from "@doorloop/dto";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import AppStrings from "../../../locale/keys";
import ListItemText from "DLUI/listItems/listItemText";
import React from "react";
import { usePortalStatus } from "screens/tenants/tenantsList/usePortalStatus";
import { useTranslation } from "react-i18next";
import { useUserType } from "../../../hooks/useUserType";

interface ListItemSubTitleProps {
  person: TenantDto | OwnerDto;
  onClick?: VoidFunction;
  lastSentReminder?: string;
  isBalanceDue?: boolean;
}

export const ListItemSubTitle = ({ person, onClick, lastSentReminder, isBalanceDue }: ListItemSubTitleProps) => {
  const { t } = useTranslation();
  const { portalInfo } = person;
  const { statuses, currentStatus } = usePortalStatus(person);
  const { isHOAUser } = useUserType();

  if (statuses.isPassActive && portalInfo && portalInfo.lastSeenAt) {
    const { text, value } = {
      text: isBalanceDue && !isHOAUser ? AppStrings.Common.SentAt : AppStrings.Common.LastSeen,
      value: isBalanceDue && !isHOAUser ? lastSentReminder : portalInfo.lastSeenAt
    };

    return (
      <>
        {value && (
          <View paddingLeft={2} flexDirection={"row"} noWrap>
            <Text fontSize={12} bold color={"secondary-gray"}>
              {t(text)}:
            </Text>
            <Text fontSize={12} bold color={"secondary-gray"} value={value} formatType={"date"} />
          </View>
        )}
      </>
    );
  }
  if (currentStatus === LeaseTenantStepStatus.INVITED && portalInfo && portalInfo.invitationLastSentAt) {
    return (
      <View
        onClick={onClick}
        cursor={"pointer"}
        flexDirection={"row"}
        paddingLeft={2}
        dataCy={DataCy.DLUI.listItem.listItemActionsSubTitle}
      >
        <Text fontSize={12} bold color={"secondary-gray"}>
          {t(AppStrings.Common.SentAt)}:
        </Text>
        <ListItemText
          fontSize={12}
          bold
          color={"secondary-gray"}
          value={portalInfo.invitationLastSentAt}
          formatType={"date"}
        />
      </View>
    );
  }
  return null;
};
