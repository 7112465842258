import { useMemo } from "react";

import {
  calculateSMSMessageCredits,
  conversationMessageCreditsMap,
  ConversationMessageType,
  SMS_MESSAGE_CHARACTERS_PER_CREDITS_PART,
  SMS_MESSAGE_TOTAL_CHARACTERS
} from "@doorloop/dto";

export interface UseConversationMessageCreditsOptions {
  conversationType: ConversationMessageType;
  messageText: string;
}

export const useConversationMessageCredits = ({
  conversationType,
  messageText
}: UseConversationMessageCreditsOptions) =>
  useMemo(() => {
    const isSMS = conversationType === ConversationMessageType.SMS;
    const creditsPerPart = conversationMessageCreditsMap[conversationType];
    const messageLength = messageText.length;
    const inputMaxLength = SMS_MESSAGE_TOTAL_CHARACTERS;

    const messageTotalCredits = isSMS
      ? calculateSMSMessageCredits({
          message: messageText,
          creditsPerPart
        })
      : creditsPerPart;
    const hasMoreCharactersToUse = messageLength < inputMaxLength;

    let lastMessagePartLength = messageLength;

    if (isSMS) {
      lastMessagePartLength =
        messageLength === 0
          ? 0
          : messageLength % SMS_MESSAGE_CHARACTERS_PER_CREDITS_PART || SMS_MESSAGE_CHARACTERS_PER_CREDITS_PART;
    }

    const messageMaxLength = isSMS ? SMS_MESSAGE_CHARACTERS_PER_CREDITS_PART : inputMaxLength;

    return {
      messageTotalCredits,
      hasMoreCharactersToUse,
      lastMessagePartLength,
      messageMaxLength,
      inputMaxLength
    };
  }, [conversationType, messageText]);
