import React from "react";
import { InformationIcon, DocumentIcon } from "assets/icons";
import type { MenuItem } from "DLUI/dialogs";
import { PrintSample } from "screens/printChecks/dialogs/components/printSample";
import { FineTuneAlignment } from "screens/printChecks/dialogs/components/fineTuneAlignment";
import AppStrings from "locale/keys";

export const getPrintSetupFormMenuItems = (): MenuItem[] => [
  {
    title: AppStrings.Common.PrintChecks.Setup.MenuItems.Step1,
    icon: InformationIcon,
    contentComponent: () => <PrintSample />
  },
  {
    title: AppStrings.Common.PrintChecks.Setup.MenuItems.Step2,
    icon: DocumentIcon,
    contentComponent: () => <FineTuneAlignment />
  }
];
