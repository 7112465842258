import { useCallback, useMemo, useState } from "react";
import size from "lodash/size";
import { useListBulkActionsManager } from "hooks/useListBulkActionsManager";
import { analyticsService } from "services/analyticsService";
import { SegmentEventTypes } from "@doorloop/dto";
import type { BulkActionDefinition, BulkProps } from "../types";

export const useListBulkActions = (resourceIds: string[], bulkActionDefinitions?: BulkActionDefinition[]) => {
  const [bulkProps, setBulkProps] = useState<BulkProps>({ shouldShow: false, operations: [] });
  const {
    listBulkActionsInterface,
    buildBulkOperations,
    toggleAll,
    allChecked,
    anyChecked,
    isTotalAllChecked,
    selectTotalAll,
    clearTotalAll,
    checkedIds,
    setCheckedIds
  } = useListBulkActionsManager(Boolean(bulkActionDefinitions), resourceIds);

  const handleDispatchAction = useCallback(
    (actionNameParam: string) => {
      const definition = bulkActionDefinitions?.find(({ actionName }) => actionName === actionNameParam);
      const operations = buildBulkOperations(actionNameParam);
      setBulkProps({
        shouldShow: true,
        definition,
        operations
      });

      analyticsService.track(SegmentEventTypes.BULK_OPERATION_DIALOG_OPENED, {
        bulkOperationName: definition?.actionName,
        numberOfItemsSelected: size(operations)
      });
    },
    [bulkActionDefinitions, buildBulkOperations]
  );

  const actionCount = useMemo(() => size(bulkProps.operations), [bulkProps.operations]);

  return {
    listBulkActionsInterface,
    handleDispatchAction,
    bulkProps,
    setBulkProps,
    toggleAll,
    allChecked,
    anyChecked,
    isTotalAllChecked,
    selectTotalAll,
    clearTotalAll,
    checkedIds,
    setCheckedIds,
    actionCount
  };
};
