import type {
  FileDto,
  GetAllPropertiesQuery,
  HasTenantsAutoPayments,
  ManagementFeesPropertySettingsDto,
  PaymentAllocationsSettingsDto,
  PropertyDto,
  PropertyWithUnitsDto,
  RentalApplicationPropertySettingsDto
} from "@doorloop/dto";
import { SegmentEventTypes, ServerRoutes } from "@doorloop/dto";
import { RestApiBaseWithDictionary } from "./restApiBaseWithDictionary";
import { apiHelper } from "./apiHelper";
import { ownersApi } from "./ownersApi";
import { analyticsService } from "services/analyticsService";
import { usersApi } from "./usersApi";
import type { ApiResult } from "api/apiResult";
import { accountsApi } from "./accounts";

export class PropertiesApi extends RestApiBaseWithDictionary<PropertyDto, GetAllPropertiesQuery> {
  constructor() {
    super(ServerRoutes.PROPERTIES, [ownersApi, accountsApi]);

    this.restRoute = ServerRoutes.PROPERTIES;
  }

  getDetails = async (propertyId: string): Promise<ApiResult<PropertyDto>> => await this.getPropertyDetails(propertyId);

  getPropertyDetails = async (propertyId: string): Promise<ApiResult<PropertyDto>> => {
    this.dictionariesRequiredForGet = [ownersApi, usersApi];
    await this.loadDictionariesRequiredForGet();
    return await apiHelper.axiosGet(ServerRoutes.PROPERTIES + "/" + propertyId);
  };

  createPropertyWithUnits = async (data: PropertyWithUnitsDto) => {
    const result = await apiHelper.axiosPost<PropertyWithUnitsDto>({
      url: ServerRoutes.PROPERTIES_POST_PROPERTY_WITH_UNITS + "/",
      data
    });
    if (result.status && result.data?.property) {
      await this.addOrUpdateDictionaryItem(result.data?.property);
    }

    const newPropertyNumOfUnits = data.units?.length;
    const eventProperties = typeof newPropertyNumOfUnits === "number" && { newPropertyNumOfUnits };
    analyticsService.track(
      SegmentEventTypes.USER_CREATED_PROPERTY,
      { ...eventProperties },
      { trackEventInIntercom: true }
    );

    return result;
  };

  uploadTenantPortalLogo = async (file: File, propertyId: string) => {
    const data = new FormData();
    data.append("file", file);
    data["Property"] = propertyId;

    return await apiHelper.axiosPost<FileDto>({ url: "/api/properties/tenant-portal-logo/" + propertyId, data });
  };

  uploadTenantPortalCoverImage = async (file: File, propertyId) => {
    const data = new FormData();
    data.append("file", file);
    data["Property"] = propertyId;

    return await apiHelper.axiosPost<FileDto>({ url: "/api/properties/tenant-portal-background/" + propertyId, data });
  };

  deleteTenantPortalLogo = async (propertyId) =>
    await apiHelper.axiosDelete({ url: `/api/properties/tenant-portal-logo/${propertyId}` });

  getAllReportData = async (query) => await apiHelper.axiosGet(ServerRoutes.PROPERTIES_GET_ALL_REPORT_DATA, query);

  deleteTenantPortalCover = async (propertyId) =>
    await apiHelper.axiosDelete({ url: `/api/properties/tenant-portal-background/${propertyId}` });

  getRentalApplications = async (propertyId: string) =>
    await apiHelper.axiosGet("/api/properties/settings/rental-applications/" + propertyId);
  updateRentalApplications = async (propertyId: string, data: RentalApplicationPropertySettingsDto) => {
    const result = await apiHelper.axiosPut({
      url: "/api/properties/settings/rental-applications/" + propertyId,
      data
    });
    this.getDictionary(true);
    return result;
  };

  getManagementFees = async (propertyId: string) =>
    await apiHelper.axiosGet("/api/properties/settings/management-fees/" + propertyId);
  updateManagementFees = async (propertyId: string, data: ManagementFeesPropertySettingsDto) =>
    await apiHelper.axiosPut({ url: "/api/properties/settings/management-fees/" + propertyId, data });
  updatePaymentAllocations = async (propertyId: string, data: PaymentAllocationsSettingsDto) =>
    await apiHelper.axiosPut({ url: "/api/properties/settings/payment-allocations/" + propertyId, data });

  async hasTenantsAutoPayments(propertyId: string): Promise<ApiResult<HasTenantsAutoPayments>> {
    return await apiHelper.axiosGet(`/api/properties/${propertyId}/has-tenants-auto-payments`);
  }
}

export const propertiesApi = new PropertiesApi();
