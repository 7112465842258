import React from "react";
import type { FormikProps } from "formik";
import { View } from "DLUI/view";
import AddressAutoCompleteFormWrapper from "DLUI/form/autoComplete/addressAutoComplete/addressAutoCompleteFormWrapper";

interface ComponentProps {
  title: string;
  formikRef: FormikProps<any>;
  formikName: string;
}
const PersonAddress: React.FC<ComponentProps> = ({ formikName }: ComponentProps) => (
  <View flex={1} width={"100%"}>
    <AddressAutoCompleteFormWrapper formikName={formikName} />
  </View>
);

export default PersonAddress;
