import React, { useState, useMemo } from "react";
import type { FormikProps } from "formik";
import { getIn } from "formik";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import Text from "DLUI/text";
import { UserStatus } from "@doorloop/dto";
import { useTranslation } from "react-i18next";
import { useIntl } from "react-intl";
import { Link } from "DLUI/link";
import { CircularProgress } from "@material-ui/core";
import { usersApi } from "api/usersApi";
import { Icon } from "DLUI/icon";
import { CheckmarkCircleIcon, CloseCircleIcon } from "assets/icons";
interface ComponentProps {
  formikRef: FormikProps<any>;
}

const UserStatusDescription: React.FC<ComponentProps> = ({ formikRef }: ComponentProps) => {
  const { t } = useTranslation();
  const { formatDate, formatTime } = useIntl();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [resendInviteStatus, setResendInviteStatus] = useState<"notSent" | "sent" | "failed">("notSent");
  const [resendInviteErrorMessage, setResendInviteErrorMessage] = useState<string | undefined>();
  const userStatus: UserStatus | undefined = getIn(formikRef.values, "status");
  const active = getIn(formikRef.values, "active");
  const userId = getIn(formikRef.values, "id");
  const ownerAccount = getIn(formikRef.values, "owner");

  const setInviteErrorState = (error: string) => {
    setResendInviteErrorMessage(error);
    setResendInviteStatus("failed");
  };

  const didPressResendInvitation = async () => {
    if (requestInProgress) {
      return;
    }
    setRequestInProgress(true);
    const response = await usersApi.resendInvitation(userId!).catch((e) => {
      setInviteErrorState(e);
    });
    if (response && response.status) {
      setResendInviteStatus("sent");
    } else if (response) {
      setInviteErrorState(response.message);
    }

    setRequestInProgress(false);
  };

  const { descriptionText, titleText }: any = useMemo(() => {
    let descriptionText: any = [];
    let titleText = AppStrings.Users.Screen.AddNewUser;

    if (requestInProgress) {
      return {
        descriptionText: "",
        titleText: t(AppStrings.Users.NewUser.SendingInvite)
      };
    }
    if (resendInviteStatus === "sent") {
      return {
        descriptionText: "",
        titleText: AppStrings.Users.NewUser.InviteSent
      };
    }

    if (resendInviteStatus === "failed") {
      return {
        descriptionText: resendInviteErrorMessage || "",
        titleText: AppStrings.Users.NewUser.InviteFailed
      };
    }
    if (ownerAccount) {
      return {
        descriptionText: t(AppStrings.Users.Screen.AccountOwnerDescription),
        titleText: AppStrings.Users.Screen.AccountOwner
      };
    }

    if (active) {
      descriptionText.push(
        <Text
          marginTop={10}
          marginBottom={10}
          value={AppStrings.Users.NewUser.NewUserDescriptionActive}
          fontSize={16}
          lineHeight={"24px"}
        />
      );

      switch (userStatus) {
        case UserStatus.ACTIVE:
          titleText = AppStrings.Users.NewUser.ActiveUser;
          descriptionText = t(AppStrings.Auth.ActiveUserDescription);
          break;
        case UserStatus.INVITED:
          const invitationLastSentAt = getIn(formikRef.values, "invitationLastSentAt");
          titleText = AppStrings.Users.NewUser.InviteSent;
          descriptionText = [];
          if (invitationLastSentAt) {
            descriptionText.push(
              <View>
                <View flexDirection={"row"}>
                  {t(AppStrings.Users.NewUser.InviteSentDescriptionPart1)}
                  {" " + formatDate(invitationLastSentAt) + " "}
                  {" " + formatTime(invitationLastSentAt) + " "}
                </View>
                <View flexDirection={"row"}>
                  <Link onClick={didPressResendInvitation} hoverColor={"lightBlue"}>
                    {t(AppStrings.Common.ClickHere)}
                  </Link>
                  <Text
                    value={AppStrings.Users.NewUser.InviteSentDescriptionPart3}
                    fontSize={16}
                    marginLeft={5}
                    lineHeight={"24px"}
                  />
                </View>
              </View>
            );
          }

          break;
        case UserStatus.SUSPENDED:
          break;
      }
    } else {
      titleText = AppStrings.Users.NewUser.InactiveUser;
      descriptionText = [
        <Text
          marginTop={10}
          marginBottom={10}
          value={AppStrings.Users.NewUser.NewUserDescriptionInactive}
          fontSize={16}
          lineHeight={"24px"}
          key={"NUD"}
        />
      ];
    }
    return { descriptionText, titleText };
  }, [requestInProgress, resendInviteStatus]);

  const renderDescription = () => {
    if (!requestInProgress && descriptionText !== "") {
      return (
        <View>
          <Text marginBottom={10} fontSize={16} lineHeight={"24px"}>
            {descriptionText}
          </Text>
        </View>
      );
    }
  };

  const renderTitleIndicator = () => {
    if (requestInProgress) {
      return (
        <CircularProgress
          style={{
            width: 20,
            height: 20,
            marginLeft: 10,
            marginTop: 10,
            color: "#7A8699"
          }}
        />
      );
    }
    if (resendInviteStatus === "sent") {
      return <Icon marginLeft={10} marginTop={12} Source={CheckmarkCircleIcon} width={15} height={15} />;
    }
    if (resendInviteStatus === "failed") {
      return <Icon marginLeft={10} marginTop={12} Source={CloseCircleIcon} width={15} height={15} />;
    }
  };

  return (
    <View minHeight={40} backgroundColor={"dark"} borderRadius={10}>
      <View paddingLeft={20} paddingRight={20}>
        <View shouldShow showAnimation={"fade-in"} hideAnimation={"fade-out"}>
          <View>
            <View flexDirection={"row"}>
              <Text marginTop={10} value={titleText} fontWeight={700} fontSize={16} />
              {renderTitleIndicator()}
            </View>
            {renderDescription()}
          </View>
        </View>
      </View>
    </View>
  );
};

export default UserStatusDescription;
