import type {
  GetAllBaseQueryResponse,
  GetAllLeaseTenantsQuery,
  GetAllPaymentTokensForTenantQuery,
  GetAllTenantsQuery,
  GetTenantPortalImpersonationTokenRequest,
  GetTenantPortalImpersonationTokenResponseDto,
  LeaseTenantDto,
  PaymentTokenDto,
  ResendPortalInvitationDto,
  TenantDto,
  ScreenWidgets
} from "@doorloop/dto";
import { LoginResponseType, SegmentEventTypes, ServerRoutes } from "@doorloop/dto";
import { propertiesApi } from "./propertiesApi";
import { unitsApi } from "./unitsApi";
import { RestApiBase } from "./restApiBase";
import { RestApiBaseWithDictionary } from "./restApiBaseWithDictionary";
import { apiHelper } from "./apiHelper";
import { usersApi } from "./usersApi";
import { tagsApi } from "api/tagsApi";
import { analyticsService } from "../services/analyticsService";
import AppStrings from "../locale/keys";
import type { ApiResult } from "api/apiResult";

export class TenantsApi extends RestApiBaseWithDictionary<TenantDto, GetAllTenantsQuery> {
  constructor() {
    super(ServerRoutes.TENANTS, [propertiesApi, unitsApi, usersApi, tagsApi]);
    this.restRoute = ServerRoutes.TENANTS;
  }

  getDetails = async (tenantId: string) => await apiHelper.axiosGet(ServerRoutes.TENANTS + "/" + tenantId);

  resendTenantPortalInvite = async (dto: ResendPortalInvitationDto) => {
    analyticsService.track(
      SegmentEventTypes.INVITATION_SENT,
      {
        sentToId: dto.id,
        sentToType: LoginResponseType.TENANT
      },
      { trackEventInIntercom: true }
    );

    return await apiHelper.axiosPost({
      url: ServerRoutes.TENANTS_POST_RESEND_TENANT_PORTAL_INVITATION,
      data: dto,
      toasts: {
        translationKey: AppStrings.Common.PortalInvitationSent
      }
    });
  };

  async getPaymentTokensForTenant(
    query: GetAllPaymentTokensForTenantQuery
  ): Promise<ApiResult<GetAllBaseQueryResponse<PaymentTokenDto>>> {
    return await apiHelper.axiosGet<GetAllBaseQueryResponse<PaymentTokenDto>>(
      ServerRoutes.PAYMENT_TOKENS_FOR_TENANT + "/?",
      query,
      { headers: { "Cache-Control": "no-cache" } }
    );
  }

  getTenantPortalImpersonationToken = async (data: GetTenantPortalImpersonationTokenRequest) =>
    await apiHelper.axiosPost<GetTenantPortalImpersonationTokenResponseDto>({
      url: ServerRoutes.TENANTS_POST_TENANT_PORTAL_IMPERSONATION_TOKEN,
      data
    });
}

export const tenantsApi = new TenantsApi();

export class LeaseTenantsApi extends RestApiBase<LeaseTenantDto, GetAllLeaseTenantsQuery> {
  constructor() {
    super(ServerRoutes.LEASES_GET_LEASE_TENANTS, [propertiesApi, unitsApi]);
    this.restRoute = ServerRoutes.LEASES_GET_LEASE_TENANTS;
  }

  activateTenantPortal = async (tenantId: string, isBulk = false) => {
    analyticsService.track(
      SegmentEventTypes.INVITATION_SENT,
      {
        sentToId: tenantId,
        sentToType: LoginResponseType.TENANT
      },
      { trackEventInIntercom: true }
    );

    return await apiHelper.axiosPost({
      url: ServerRoutes.TENANTS_POST_ACTIVATE_TENANT_PORTAL,
      // typeId is an obscure key/param to notify the backend if running via bulk (to apply different logic)
      data: { id: tenantId, typeId: isBulk ? "f7h73" : undefined }
    });
  };

  getWidgets = async (widgetType?: ScreenWidgets): Promise<ApiResult<{ value: any }>> =>
    await apiHelper.axiosGet(ServerRoutes.LEASES_TENANTS_WIDGETS, {
      widgetType
    });
}

export const leaseTenantsApi = new LeaseTenantsApi();

export class DraftLeaseTenantsApi extends RestApiBase<LeaseTenantDto, GetAllLeaseTenantsQuery> {
  constructor() {
    super(ServerRoutes.LEASES_GET_LEASE_DRAFT_TENANTS, [propertiesApi, unitsApi]);
    this.restRoute = ServerRoutes.LEASES_GET_LEASE_DRAFT_TENANTS;
  }
}

export const draftLeaseTenantsApi = new DraftLeaseTenantsApi();
