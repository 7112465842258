import React from "react";
import { View } from "DLUI/view";
import makeStyles from "./styles";
import Text from "DLUI/text";
import type { TextColor, TextTransformProp } from "DLUI/text/text";

interface ComponentProps {
  title?: string;
  type: "underline" | "rounded" | "none";
  marginLeft?: number;
  marginTop?: number;
  textColor?: TextColor;
  fontSize?: number;
  maxWidth?: number;
  alignItems?: "center" | "flex-end" | "flex-start";
  textTransform?: TextTransformProp;
  marginRight?: number;
  replaceObject?: Record<string, string | number>;
}

const SectionTitle: React.FC<ComponentProps> = ({
  title,
  type,
  marginLeft,
  marginTop,
  textColor,
  fontSize,
  maxWidth,
  alignItems,
  textTransform,
  marginRight,
  replaceObject
}: ComponentProps) => {
  const classes = makeStyles();
  if (type === "rounded") {
    return (
      <div className={classes.componentContainer}>
        <Text marginLeft={60} fontSize={fontSize || 18} fontWeight={700} color={"white"} value={title} />
      </div>
    );
  }

  return (
    <View alignItems={alignItems} marginTop={marginTop} paddingLeft={marginLeft} paddingRight={marginRight} noWrap>
      <Text
        removeDefaultPadding
        fontSize={fontSize || 18}
        fontWeight={700}
        color={textColor || "black"}
        value={title}
        align={alignItems === "flex-start" ? "left" : "center"}
        maxWidth={maxWidth}
        lineHeight={"28px"}
        textTransform={textTransform}
        replaceObject={replaceObject}
      />
      {type === "underline" && <div className={classes.indicatorView} />}
    </View>
  );
};

export default SectionTitle;
