import { PropertyAmenity } from "@doorloop/dto";

interface AmenitiesInterface {
  categoryLabel: string;
  amenities: PropertyAmenity[];
}

export const propertyAmenities: AmenitiesInterface[] = [
  {
    categoryLabel: "",
    amenities: [
      PropertyAmenity.Other,
      PropertyAmenity.Availability24Hours,
      PropertyAmenity.AccentWalls,
      PropertyAmenity.BasketballCourt,
      PropertyAmenity.Bilingual,
      PropertyAmenity.BoatDocks,
      PropertyAmenity.BusinessCenter,
      PropertyAmenity.CarWashArea,
      PropertyAmenity.ChildCare,
      PropertyAmenity.ClubDiscount,
      PropertyAmenity.ClubHouse,
      PropertyAmenity.ConferenceRoom,
      PropertyAmenity.Concierge,
      PropertyAmenity.CoverPark,
      PropertyAmenity.DoorAttendant,
      PropertyAmenity.FitnessCenter,
      PropertyAmenity.Elevator,
      PropertyAmenity.FreeWeights,
      PropertyAmenity.FurnishedAvailable,
      PropertyAmenity.GamingStations,
      PropertyAmenity.Garage_Attached,
      PropertyAmenity.Garage_Detached,
      PropertyAmenity.Garage_SameLevelParking,
      PropertyAmenity.Gate,
      PropertyAmenity.GroceryService,
      PropertyAmenity.GroupExercise,
      PropertyAmenity.GuestRoom,
      PropertyAmenity.HighSpeed,
      PropertyAmenity.Housekeeping,
      PropertyAmenity.HouseSitting,
      PropertyAmenity.JoggingWalkingTrails,
      PropertyAmenity.LakeFront,
      PropertyAmenity.LakeAccess,
      PropertyAmenity.Laundry,
      PropertyAmenity.Library,
      PropertyAmenity.MealService,
      PropertyAmenity.MediaRoom,
      PropertyAmenity.MultiUseRoom,
      PropertyAmenity.NightPatrol,
      PropertyAmenity.OnSiteMaintenance,
      PropertyAmenity.OnSiteManagement,
      PropertyAmenity.PackageReceiving,
      PropertyAmenity.PerDiemAccepted,
      PropertyAmenity.Pool,
      PropertyAmenity.PlayGround,
      PropertyAmenity.Racquetball,
      PropertyAmenity.RecRoom,
      PropertyAmenity.Recycling,
      PropertyAmenity.Sauna,
      PropertyAmenity.ShortTermLease,
      PropertyAmenity.SmokeFree,
      PropertyAmenity.Spa,
      PropertyAmenity.StorageSpace,
      PropertyAmenity.Sundeck,
      PropertyAmenity.TennisCourt,
      PropertyAmenity.Transportation,
      PropertyAmenity.TVLounge,
      PropertyAmenity.ValetTrash,
      PropertyAmenity.Vintage,
      PropertyAmenity.VolleyballCourt,
      PropertyAmenity.WirelessInternet
    ]
  }
];
