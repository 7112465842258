import React from "react";

import { CircularProgress, TextField } from "@material-ui/core";
import { useAtom, useAtomValue } from "jotai";

import type { ConversationDto, ConversationMessageDto } from "@doorloop/dto";
import { ConversationMessageType, CreateConversationDto, CreateConversationMessageDto, DataCy } from "@doorloop/dto";

import AppStrings from "../../../../locale/keys";
import ColorsEnum from "../../../../utils/colorsEnum";
import DLButton, { DLButtonColorsEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import makeStyles from "./styles";
import Text from "DLUI/text";
import type { ApiResult } from "api/apiResult";
import { conversationRecipientInputUtils } from "screens/communicationsCenter/shared/conversationRecipientInputUtils";
import { conversationsApi } from "api/conversationsApi";
import { currentConversationAtom } from "screens/communicationsCenter/shared/currentConversationAtom";
import { currentConversationSubscriptionStatusAtom } from "screens/communicationsCenter/shared/useCurrentConversationSubscriptionStatus";
import { currentRecipientAtom } from "screens/communicationsCenter/shared/currentRecipientAtom";
import { handleToast } from "store/toast/actions";
import { Icon } from "DLUI/icon";
import { NavigationManager } from "utils/navigation";
import { newConversationMethodAtom } from "../shared/newConversationMethodAtom";
import { SendIcon } from "../../../../assets";
import { store } from "store";
import { useAnalyticsService } from "hooks/useAnalyticsService";
import { useCommunicationsCenterCredits } from "screens/communicationsCenter/shared/useCommunicationsCenterCredits";
import { useConversationMessageCredits } from "screens/communicationsCenter/shared/useConversationMessageCredits";
import { useConversationParams } from "screens/communicationsCenter/shared/useConversationParams";
import { useConversationsListDataSource } from "screens/communicationsCenter/shared/useConversationsListDataSource";
import { useEffectAsync } from "hooks/useEffectAsync";
import { useMessagesListDataSource } from "screens/communicationsCenter/shared/useMessagesListDataSource";
import { useOnlyEnter } from "hooks/useOnlyEnter";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { useTypedTranslation } from "locale";
import { View } from "DLUI/view";

const CONVERSATION_TYPE = ConversationMessageType.SMS;

const CONVERSATION_INPUT_DESKTOP_HEIGHT = 209;

export const CONVERSATION_INPUT_DATA_ATTR = "conversationInput";

export const ConversationInput: React.FC = () => {
  const { dispatchAnalytics } = useAnalyticsService();
  const { isTabletOrMobile } = useResponsiveHelper();
  const { t } = useTypedTranslation();
  const classes = makeStyles();

  const { isNewConversation, conversationId } = useConversationParams();
  const [currentConversation, setCurrentConversation] = useAtom(currentConversationAtom);
  const [currentRecipient, setCurrentRecipient] = useAtom(currentRecipientAtom);
  const newConversationMethod = useAtomValue(newConversationMethodAtom);

  const { updateConversationInDataSource, appendToConversationsList } = useConversationsListDataSource();

  const { appendToMessagesList } = useMessagesListDataSource();

  const [messageText, setMessageText] = React.useState<string>("");
  const [isSendingMessage, setIsSendingMessage] = React.useState<boolean>(false);

  const currentConversationSubscriptionStatus = useAtomValue(currentConversationSubscriptionStatusAtom);

  const isRecipientValid = conversationRecipientInputUtils.isRecipientValid(currentRecipient);

  const { credits, fetchCredits } = useCommunicationsCenterCredits();

  const hasNoCreditsRemaining = credits && credits.creditsRemaining <= 0 && !credits.autoPurchaseCredits;

  const isSendDisabled =
    !messageText ||
    isSendingMessage ||
    hasNoCreditsRemaining ||
    (!currentConversation && !isRecipientValid) ||
    currentConversationSubscriptionStatus?.optedOut;

  const { messageTotalCredits, hasMoreCharactersToUse, lastMessagePartLength, messageMaxLength, inputMaxLength } =
    useConversationMessageCredits({
      conversationType: CONVERSATION_TYPE,
      messageText
    });

  useEffectAsync(async () => {
    await fetchCredits();
  }, [conversationId]);

  const handleSendMessage = async () => {
    if (isSendDisabled) {
      return;
    }

    setIsSendingMessage(true);

    const credits = await fetchCredits();

    if (credits && credits.creditsRemaining <= 0 && !credits.autoPurchaseCredits) {
      store.dispatch(handleToast({ severity: "warning", translationKey: AppStrings.Common.InsufficientCredits }));
      setIsSendingMessage(false);
      return;
    }

    const conversationRecipient = currentConversation ? currentConversation.recipient : currentRecipient;

    const messageDto = new CreateConversationMessageDto({
      type: CONVERSATION_TYPE,
      body: messageText
    });

    if (conversationRecipient?.linkedToType) {
      dispatchAnalytics(
        "communications_center_user_sent_message",
        {
          stakeholderType: conversationRecipient.linkedToType,
          messageType: messageDto.type
        },
        { trackEventInIntercom: true }
      );
    }

    if (currentRecipient && isNewConversation) {
      const createConversationDto = new CreateConversationDto({
        ...messageDto,
        ...currentRecipient,
        method: newConversationMethod
      });
      const response = await conversationsApi.createConversation(createConversationDto);

      handleNewConversationResponse(response);
    } else if (currentConversation) {
      const response = await conversationsApi.sendMessage(currentConversation?.id, messageDto);

      handleSendMessageResponse(response);
    }
  };

  useOnlyEnter(handleSendMessage, isTabletOrMobile);

  const handleNewConversationResponse = (response: ApiResult<ConversationDto>) => {
    setIsSendingMessage(false);

    if (response.data) {
      setCurrentConversation(response.data);
      NavigationManager.viewConversation(response.data.id);
      setCurrentRecipient(undefined);

      appendToConversationsList(response.data);
      setMessageText("");
    }
  };

  const handleSendMessageResponse = (response: ApiResult<ConversationMessageDto>) => {
    setIsSendingMessage(false);

    if (response.status && response.data) {
      appendToMessagesList(response.data);

      currentConversation &&
        updateConversationInDataSource({ ...currentConversation, lastMessage: response.data }, true, true);
      setMessageText("");
    }
  };

  return (
    <View
      maxHeight={CONVERSATION_INPUT_DESKTOP_HEIGHT}
      flexDirection={"row"}
      alignItems={"center"}
      noWrap
      marginBottom={16}
      paddingLeft={16}
      paddingRight={16}
    >
      <TextField
        multiline
        maxRows={5}
        minRows={isTabletOrMobile ? 1 : 2}
        className={classes.conversationInputTextArea}
        placeholder={isTabletOrMobile ? t("common.typeAMessageMobile") : t("common.typeAMessage")}
        value={messageText}
        disabled={isSendingMessage}
        onChange={(event) => setMessageText(event.target.value)}
        InputProps={{
          disableUnderline: true
        }}
        inputProps={{
          maxLength: inputMaxLength,
          "data-attr": CONVERSATION_INPUT_DATA_ATTR,
          "data-cy": DataCy.communicationsCenter.messagesPanel.conversationInputTextArea
        }}
      />
      <div style={{ position: "absolute", right: 90, bottom: 16 }}>
        <View flexDirection={"column"} alignItems={"flex-end"} gap={2}>
          <Text
            value={t("communicationsCenter.conversationInputCredits", {
              messageCredits: messageTotalCredits
            })}
            bold
            fontSize={10}
            lineHeight={"14px"}
            color={"secondary-gray"}
          />

          <Text
            value={`${lastMessagePartLength}/${messageMaxLength}`}
            bold
            fontSize={12}
            color={hasMoreCharactersToUse ? "gray" : "error"}
          />
        </View>
      </div>

      {!isSendingMessage ? (
        <DLButton
          dataCy={DataCy.communicationsCenter.messagesPanel.sendMessageButton}
          disabled={isSendDisabled}
          onClick={handleSendMessage}
          variant={DLButtonVariantsEnum.TEXT}
          color={DLButtonColorsEnum.SECONDARY}
          style={{ height: 40, width: 40, marginLeft: 12, paddingLeft: 24, paddingRight: 24 }}
          icons={{
            start: {
              src: () => <Icon Source={SendIcon} pathColor={"current-color"} size={24} />
            }
          }}
        />
      ) : (
        <CircularProgress size={30} style={{ color: ColorsEnum.BrightBlue, marginLeft: 18, marginRight: 18 }} />
      )}
    </View>
  );
};
