export default {
  fileAttachment: "File Attachment",
  tab: {
    addNewFile: "Add New File",
    uploadNewFile: "Upload New File"
  },
  screen: {
    searchPlaceHolder: "Search files"
  },
  dialog: {
    helpDescription: "Help Description",
    noFileSelectedError: "Please select a file to upload.",
    learnAboutFiles: "Learn about creating and editing files",
    fileNotSupportedErrorMsg: "This file type is not supported"
  },
  fileSizeTooLargeError: "File size cannot exceed {{size}}MB",
  totalFilesSizeTooLargeError: "Total files size cannot exceed {{size}}MB"
};
