"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAllFilesQuery = void 0;
const linkedResourceType_enum_1 = require("../common/linkedResourceType.enum");
const isDateStringWithoutTime_validator_1 = require("../common/validators/isDateStringWithoutTime.validator");
const class_validator_1 = require("class-validator");
const getAllBaseRequest_query_1 = require("../getAllBaseRequest.query");
const filePurpose_enum_1 = require("./filePurpose.enum");
class GetAllFilesQuery extends getAllBaseRequest_query_1.GetAllBaseQueryRequest {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.GetAllFilesQuery = GetAllFilesQuery;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(filePurpose_enum_1.FilePurpose),
    __metadata("design:type", String)
], GetAllFilesQuery.prototype, "purpose", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(linkedResourceType_enum_1.LinkedResourceType),
    __metadata("design:type", String)
], GetAllFilesQuery.prototype, "filter_resourceType", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllFilesQuery.prototype, "filter_resourceId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllFilesQuery.prototype, "filter_createdBy", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllFilesQuery.prototype, "filter_updatedBy", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllFilesQuery.prototype, "filter_createdAt_to", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], GetAllFilesQuery.prototype, "filter_createdAt_from", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllFilesQuery.prototype, "filter_tags", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllFilesQuery.prototype, "filter_location", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllFilesQuery.prototype, "filter_portfolio", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllFilesQuery.prototype, "filter_property", void 0);
