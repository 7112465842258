import { RestApiBase } from "api/restApiBase";
import type { TenantPortalGetAllRequestsQuery, TenantPortalRequestPostUpdateDto } from "@doorloop/dto";
import type { TenantPortalRequestDto } from "@doorloop/dto";
import { TenantPortalServerRoutes } from "@doorloop/dto";
import { apiHelper } from "api/apiHelper";

export class TenantPortalRequestsApi extends RestApiBase<TenantPortalRequestDto, TenantPortalGetAllRequestsQuery> {
  constructor() {
    super(TenantPortalServerRoutes.TENANT_REQUESTS);
    this.restRoute = TenantPortalServerRoutes.TENANT_REQUESTS;
  }

  postMessage = async (data: TenantPortalRequestPostUpdateDto) =>
    await apiHelper.axiosPost({
      url: TenantPortalServerRoutes.TENANT_REQUESTS_POST_UPDATE,
      data
    });
}

export const tenantPortalRequestsApi = new TenantPortalRequestsApi();
