import React from "react";
import type { SVGIconComponent } from "assets/icons";
import { DotsIcon } from "assets/icons";
import { Grid } from "DLUI/listItems";
import type { PopoverItem } from "DLUI/popover";
import { Popover } from "DLUI/popover";
import { View } from "DLUI/view";
import Hidden from "@material-ui/core/Hidden";
import type { FillColors } from "DLUI/icon";
import { SeparationLine } from "DLUI/separatorView";
import { DataCy } from "@doorloop/dto";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import { useAnalyticsService } from "../../../hooks/useAnalyticsService";
import type { ViewComponentProps } from "../view/view";

export interface ListItemOptionsProps {
  popoverTopSectionItems?: PopoverItem[];
  popoverBottomSectionItems?: PopoverItem[];
  popoverWidth?: number;
  size?: "small" | "normal" | "medium" | "large";
  showOnMobile?: boolean;
  iconPathColor?: FillColors;
  stopPropagation?: boolean;
  showSeparationLine?: boolean;
  gridItem?: boolean;
  zIndex?: number;
  removePopoverMarginTop?: boolean;
  Icon?: SVGIconComponent;
  marginTop?: number;
  marginRight?: number;
  iconContainerSize?: number;
  useMobileContainer?: boolean;
  mobilePosition?: "absolute" | "fixed" | "relative" | "static" | "sticky" | undefined;
  alignItems?: ViewComponentProps["alignItems"];
}

const ListItemOptions: React.FC<ListItemOptionsProps> = ({
  popoverTopSectionItems,
  popoverBottomSectionItems,
  popoverWidth,
  removePopoverMarginTop,
  size,
  showOnMobile = true,
  iconPathColor,
  stopPropagation,
  showSeparationLine,
  gridItem,
  zIndex,
  Icon = DotsIcon,
  iconContainerSize,
  marginTop = 5,
  marginRight = 5,
  useMobileContainer = true,
  mobilePosition = "absolute",
  alignItems = "center"
}: ListItemOptionsProps) => {
  const { isMobile } = useResponsiveHelper();
  const { dispatchAnalytics, formatAppStringsValue } = useAnalyticsService();

  const onPopoverClick = () => {
    dispatchAnalytics("listItem_kebab_click");
  };

  const onPopoverItemSelection = (item: PopoverItem) => {
    dispatchAnalytics("listItem_kebab_action_click", {
      label: formatAppStringsValue(item.title)
    });
  };

  const mobileContainer = (
    <div
      style={{
        position: mobilePosition,
        top: mobilePosition === "absolute" ? 10 : "unset",
        right: mobilePosition === "absolute" ? 10 : "unset",
        zIndex
      }}
      data-cy={DataCy.DLUI.listItem.listItemOptionsButton}
    >
      <Popover
        onPopoverItemSelection={onPopoverItemSelection}
        onClick={onPopoverClick}
        topSectionItems={popoverTopSectionItems || []}
        bottomSectionItems={popoverBottomSectionItems || []}
        Icon={Icon}
        width={popoverWidth}
        iconPathColor={iconPathColor}
        stopPropagation={stopPropagation}
        iconContainerSize={iconContainerSize}
      />
    </div>
  );

  if (isMobile && useMobileContainer) {
    return mobileContainer;
  }

  if (size && size === "small") {
    return (
      <View height={50} justifyContent={"center"} alignItems={"center"} width={"auto"} flexDirection={"row"}>
        {showSeparationLine ? <SeparationLine height={"50%"} width={1} /> : null}
        <View width={"50px"} flexDirection={"row"} dataCy={DataCy.DLUI.listItem.listItemOptionsButton}>
          <Popover
            onPopoverItemSelection={onPopoverItemSelection}
            onClick={onPopoverClick}
            topSectionItems={popoverTopSectionItems || []}
            bottomSectionItems={popoverBottomSectionItems || []}
            Icon={Icon}
            width={popoverWidth}
            stopPropagation={stopPropagation}
            iconContainerSize={iconContainerSize}
          />
        </View>
      </View>
    );
  }
  if (gridItem) {
    return (
      <Hidden smDown={!showOnMobile}>
        <Grid paddingRight={10} xs={1} md={1} lg={1}>
          <View
            marginTop={marginTop}
            height={"100%"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            flexDirection={"row"}
            dataCy={DataCy.DLUI.listItem.listItemOptionsButton}
          >
            {showSeparationLine ? <SeparationLine height={"50%"} width={1} /> : null}

            <Popover
              onPopoverItemSelection={onPopoverItemSelection}
              onClick={onPopoverClick}
              topSectionItems={popoverTopSectionItems || []}
              bottomSectionItems={popoverBottomSectionItems || []}
              Icon={Icon}
              width={popoverWidth}
              iconPathColor={iconPathColor}
              stopPropagation={stopPropagation}
              iconContainerSize={iconContainerSize}
            />
          </View>
        </Grid>
      </Hidden>
    );
  }
  return (
    <Hidden mdDown={!showOnMobile}>
      <View
        marginTop={removePopoverMarginTop ? 0 : marginTop}
        height={"100%"}
        justifyContent={"flex-end"}
        alignItems={alignItems}
        flexDirection={"row"}
        width={"auto"}
        style={{ alignSelf: "flex-end" }}
        marginRight={marginRight}
        dataCy={DataCy.DLUI.listItem.listItemOptionsButton}
      >
        {showSeparationLine ? <SeparationLine marginRight={5} height={"50%"} width={1} /> : null}

        <Popover
          onPopoverItemSelection={onPopoverItemSelection}
          onClick={onPopoverClick}
          topSectionItems={popoverTopSectionItems || []}
          bottomSectionItems={popoverBottomSectionItems || []}
          Icon={Icon}
          width={popoverWidth}
          iconPathColor={iconPathColor}
          stopPropagation={stopPropagation}
          iconContainerSize={iconContainerSize}
        />
      </View>
    </Hidden>
  );
};

export default ListItemOptions;
