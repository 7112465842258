import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      backgroundColor: theme.palette.screenBackground.main,
      borderRadius: 10,
      overflow: "hidden",
      position: "relative"
    },
    loadingDialogContainer: {
      flexDirection: "column",
      width: "100%",
      height: "100%",
      display: "flex",
      overflow: "scroll",
      justifyContent: "center",
      overflowX: "hidden"
    },

    loadingViewConatiner: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.white.main,
      marginTop: "50px"
    },
    dialogContentText: {
      marginTop: 20
    },
    columnContainer: {
      display: "flex",
      flexDirection: "column"
    },
    dialogRowContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginLeft: "20px"
    },
    errorRowContainer: {
      marginLeft: "20px",
      height: "20px",
      display: "flex",
      alignItems: "center",
      marginTop: "5px"
    },
    contentPadding: {
      width: "20px",
      height: "100%"
    },
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      overflow: "auto"
    },
    contentWrapper: {
      margin: "0px 80px 0px 80px",
      flex: 1,
      backgroundColor: theme.palette.white.main,
      borderRadius: "20px",
      boxShadow: "5px 5px 10px 0px rgba(40,44,97,0.1)",
      paddingBottom: "40px"
    },
    tenantSelectionContainer: {
      width: "100%",
      overflow: "auto",
      marginTop: "20px",
      display: "flex"
    },
    filterInput: {
      minWidth: 200,
      height: "100%"
    },
    actionPanelContainer: {
      height: "100px",
      justifyContent: "flex-end",
      alignItems: "center",
      display: "flex",
      width: "100%",
      marginRight: "160px"
    },
    actionPanelContainerMobile: {
      marginRight: 0,
      justifyContent: "space-around",
      paddingBottom: 40
    },
    inputContainer: {
      width: "100%",
      height: "50px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      marginTop: 20,
      paddingBottom: 30,
      boxShadow: "0px 10px 10px rgba(40, 44, 97, 0.1)"
    },
    inputWrapperRightRadius: {
      borderTopRightRadius: 25,
      borderBottomRightRadius: 25
    },
    inputWrapper: {
      display: "flex",
      flexDirection: "row",
      backgroundColor: theme.palette.white.main,
      height: "50px",
      width: "300px",
      alignItems: "center",
      border: `1px solid ${theme.newPalette.input.outlined.enabledBorder}`,
      "&:hover": {
        border: `1px solid ${theme.newPalette.secondary.main}`
      }
    },
    searchInput: {
      flex: 1,
      paddingLeft: 20
    },
    inputLabel: {
      "&::placeholder": {
        color: "#7C84AC !important"
      },
      fontSize: "14px"
    },
    searchIcon: {
      width: "24px",
      height: "24px",
      marginRight: "30px"
    },
    titleRowContainer: {
      width: "100%",
      height: "100px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      position: "relative"
    },
    titleWrapper: {
      flex: 1,
      position: "relative"
    },
    closeIconContainer: {
      display: "flex",
      position: "absolute",
      right: 65,
      top: -5
    },
    closeIconContainerMobile: {
      right: 20
    },
    closeIcon: {
      color: theme.palette.gray.light,
      width: "25px",
      height: "25px"
    },
    contentWrapperMobile: {
      margin: "0px 20px 20px 20px",
      flex: 1
    },
    selectTenantsDialogContainer: {
      height: "100%",
      width: "100%"
    },
    dialogActionsContainer: {
      display: "flex",
      justifyContent: "center"
    },
    lefDecorationLine: {
      width: "40px",
      position: "absolute",
      backgroundColor: theme.palette.blue.main,
      top: 0,
      left: -20,
      zIndex: 50000
    },
    overflowScroll: {
      overflowY: "auto",
      overflowX: "hidden"
    },
    sidebarTitleContainer: {
      borderStyle: "solid",
      borderColor: "rgba(124, 132, 172, 0.2)",
      borderWidth: "0px",
      borderBottomWidth: "1px"
    },
    sidebarContainer: {
      backgroundColor: theme.palette.blue.main
    },
    headerIconWrapper: {
      backgroundColor: "rgba(365,365,365,0.1)"
    },
    pinkBorderBottom: {
      borderBottomWidth: "5px",
      borderStyle: "solid",
      borderTop: "0px",
      borderLeft: "0px",
      borderRight: "0px",
      borderColor: theme.palette.pink.main
    }
  })
);
