"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isV1 = isV1;
exports.isV2 = isV2;
function isV1(propertyType) {
    return !Array.isArray(propertyType.ILS_Unit) || "ContactAgent" in propertyType || "_ListingID" in propertyType;
}
function isV2(propertyType) {
    return !isV1(propertyType) && Array.isArray(propertyType.ILS_Unit);
}
