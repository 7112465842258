import { IconButton, TextField } from "DLUI/form";
import { CloseIcon, DeleteIcon, VSignIcon } from "assets/icons";
import { ProfileImage } from "DLUI/profileImage";
import Text from "DLUI/text";
import React, { useMemo, useState } from "react";
import makeStyles from "./styles";
import { View } from "DLUI/view";
import { FastField } from "formik";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import "./styles.css";
import { SeparationLine } from "DLUI/separatorView";
import TextEllipsis from "DLUI/text/textEllipsis";

interface SelectionButtonProps {
  pictureUrl?: string;
  name?: string;
  index: number;
  didPressDeleteButton: (itemIndex: number) => void;
  didChangeOwnerDistribution: (e: React.ChangeEvent<any>) => void;
  buttonFormikName: string;
}

const OwnerSelectionButton: React.FC<SelectionButtonProps> = ({
  index,
  pictureUrl,
  name,
  didPressDeleteButton,
  didChangeOwnerDistribution,
  buttonFormikName
}: SelectionButtonProps) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean | undefined>(undefined);

  const classes = makeStyles();
  const { t } = useTranslation();

  const _didPressDeleteButton = () => {
    setShowDeleteConfirmation(true);
  };

  const didPressConfirmDelete = () => {
    didPressDeleteButton(index);
  };

  const didPressCancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  const deleteConfirmationClass = useMemo(() => {
    if (showDeleteConfirmation === undefined) {
      return "";
    }
    if (showDeleteConfirmation) {
      return "slide-in";
    }
    return "slide-out";
  }, [showDeleteConfirmation]);

  return (
    <div className={"ownerSelectionButton"}>
      <div className={classes.selectedOwnerButton}>
        <div className={clsx(["deleteConfirmationContainer", deleteConfirmationClass])}>
          <View height={"100%"} justifyContent={"center"} alignItems={"center"}>
            <View alignItems={"center"} maxWidth={160}>
              <Text
                value={AppStrings.Owners.Details.DeleteConfirmationText}
                color={"black"}
                fontSize={16}
                lineHeight={"26px"}
                align={"center"}
                paddingRight={5}
                paddingLeft={5}
              />
              <View justifyContent={"center"} marginTop={20} flexDirection={"row"} alignItems={"center"}>
                <IconButton Icon={VSignIcon} onClick={didPressConfirmDelete} width={20} height={20} marginRight={20} />
                <SeparationLine marginRight={10} width={1} height={"50%"} />
                <IconButton Icon={CloseIcon} onClick={didPressCancelDelete} width={20} height={20} />
              </View>
            </View>
          </View>
        </div>
        <div className={classes.deleteIconContainer}>
          <IconButton width={20} height={20} Icon={DeleteIcon} onClick={_didPressDeleteButton} aria-label="delete" />
        </div>
        <View width={"100%"} marginTop={20} flexDirection={"column"} alignItems={"center"}>
          <ProfileImage pictureUrl={pictureUrl} size={50} />
          <TextEllipsis
            marginTop={5}
            align={"center"}
            lines={2}
            paddingRight={10}
            paddingLeft={10}
            fontSize={14}
            lineBreak={"auto"}
            text={name || ""}
          />
        </View>
        <View
          paddingLeft={15}
          paddingRight={15}
          marginBottom={8}
          marginTop={"auto"}
          style={{ padding: "0 7.5%", width: "85%" }}
        >
          <FastField
            component={TextField}
            label={t(AppStrings.Owners.EditOwners.OwnershipInputlabel)}
            name={buttonFormikName}
            formatType={"percent"}
            decimalScale={4}
            onBlur={didChangeOwnerDistribution}
            marginTop={10}
            textAlign={"left"}
          />
        </View>
      </div>
    </div>
  );
};

export default OwnerSelectionButton;
