import type { FormikProps } from "formik";
import { UserRoleDto } from "@doorloop/dto";
import { createValidator } from "@doorloop/dto";

export const initFormValues = (): UserRoleDto => new UserRoleDto();

export const validateForm = createValidator(UserRoleDto);

export const validateUserForm = async (
  formikRef: FormikProps<any>
): Promise<{ isValid: boolean; errorStepIndex?: number }> => {
  await formikRef.submitForm();
  formikRef.setFieldTouched("name");
  const errors = (await formikRef.validateForm()) as any;
  if (errors.name) {
    return { isValid: false, errorStepIndex: 0 };
  }

  return { isValid: true };
};
