import clsx from "clsx";
import type { LabelPlacementPosition } from "DLUI/form/label/inputLabel";
import { useStylesContainer, useStylesInput } from "DLUI/form/radioGroup/radio/styles";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";
import type { ToggleInputSize } from "DLUI/form/types/toggleInputBaseTypes";

interface Props {
  size?: ToggleInputSize;
  disabled?: boolean;
  labelPlacement?: LabelPlacementPosition;
}

export const useStylesRadio = ({ size, disabled, labelPlacement = "end" }: Props) => {
  const classesContainer = useStylesContainer();
  const classesInput = useStylesInput();

  const { isTabletOrMobile } = useResponsiveHelper();
  const calculatedSize = size || (isTabletOrMobile ? "large" : "medium");

  return {
    classNameContainer: clsx([
      classesContainer.root,
      classesContainer[calculatedSize],
      classesContainer[labelPlacement]
    ]),
    classesInput: {
      root: clsx([
        classesInput.root,
        classesInput[calculatedSize],
        classesInput[labelPlacement],
        disabled && classesInput.disabled
      ]),
      checked: classesInput.checked,
      disabled: classesInput.disabled
    }
  };
};
