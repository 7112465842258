"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobTitles = void 0;
var JobTitles;
(function (JobTitles) {
    JobTitles["PROPERTY_MANAGER"] = "Property Manager";
    JobTitles["COMPANY_OWNER"] = "Company Owner";
    JobTitles["PROPERTY_OWNER"] = "Property Owner";
    JobTitles["ACCOUNTANT"] = "Accountant";
    JobTitles["HOA_MEMBER_OR_COMMUNITY_MANAGER"] = "HOA Member or Community Manager";
    JobTitles["OTHER"] = "Other";
})(JobTitles || (exports.JobTitles = JobTitles = {}));
