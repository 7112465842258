"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsStates = void 0;
var UsStates;
(function (UsStates) {
    UsStates["AL"] = "AL";
    UsStates["AK"] = "AK";
    UsStates["AR"] = "AR";
    UsStates["AZ"] = "AZ";
    UsStates["CA"] = "CA";
    UsStates["CO"] = "CO";
    UsStates["CT"] = "CT";
    UsStates["DC"] = "DC";
    UsStates["DE"] = "DE";
    UsStates["FL"] = "FL";
    UsStates["GA"] = "GA";
    UsStates["HI"] = "HI";
    UsStates["IA"] = "IA";
    UsStates["ID"] = "ID";
    UsStates["IL"] = "IL";
    UsStates["IN"] = "IN";
    UsStates["KS"] = "KS";
    UsStates["KY"] = "KY";
    UsStates["LA"] = "LA";
    UsStates["MA"] = "MA";
    UsStates["MD"] = "MD";
    UsStates["ME"] = "ME";
    UsStates["MI"] = "MI";
    UsStates["MN"] = "MN";
    UsStates["MO"] = "MO";
    UsStates["MS"] = "MS";
    UsStates["MT"] = "MT";
    UsStates["NC"] = "NC";
    UsStates["ND"] = "ND";
    UsStates["NE"] = "NE";
    UsStates["NH"] = "NH";
    UsStates["NJ"] = "NJ";
    UsStates["NM"] = "NM";
    UsStates["NV"] = "NV";
    UsStates["NY"] = "NY";
    UsStates["OK"] = "OK";
    UsStates["OH"] = "OH";
    UsStates["OR"] = "OR";
    UsStates["PA"] = "PA";
    UsStates["RI"] = "RI";
    UsStates["SC"] = "SC";
    UsStates["SD"] = "SD";
    UsStates["TN"] = "TN";
    UsStates["TX"] = "TX";
    UsStates["UT"] = "UT";
    UsStates["VA"] = "VA";
    UsStates["VT"] = "VT";
    UsStates["WA"] = "WA";
    UsStates["WI"] = "WI";
    UsStates["WV"] = "WV";
    UsStates["WY"] = "WY";
    UsStates["VI"] = "VI";
})(UsStates || (exports.UsStates = UsStates = {}));
