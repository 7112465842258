import React, { Fragment } from "react";

import { FastField } from "formik";
import { useTranslation } from "react-i18next";

import AppStrings from "locale/keys";
import Text from "DLUI/text";
import { TextField } from "DLUI/form";
import { View } from "DLUI/view";

interface ComponentProps {
  titleText: string;
  formikFieldNamePrefix?: string;
  shouldShow?: boolean;
  renderForm?: boolean;
  renderSpecifyRadioButtons?: () => React.ReactNode;
}

export const PortalInvitesForm: React.FC<ComponentProps> = ({
  titleText,
  formikFieldNamePrefix = "emailInvite",
  shouldShow = true,
  renderForm = true,
  renderSpecifyRadioButtons
}: ComponentProps) => {
  const { t } = useTranslation();

  return (
    <View width={"100%"} flex={1}>
      <View>
        <Text color={"black"} fontSize={16} value={titleText} />
        {renderSpecifyRadioButtons?.()}
        <View shouldShow={shouldShow} showAnimation={"fade-in"} hideAnimation={"fade-out"}>
          {renderForm && (
            <Fragment>
              <Text
                fontSize={20}
                marginTop={20}
                fontWeight={700}
                color={"black"}
                value={AppStrings.Common.EmailTemplate}
              />
              <View>
                <FastField
                  component={TextField}
                  name={`${formikFieldNamePrefix}.emailTitle`}
                  label={t(AppStrings.Common.PortalInviteTitle)}
                  marginTop={20}
                />
              </View>
              <View>
                <FastField
                  component={TextField}
                  name={`${formikFieldNamePrefix}.emailSubtitle`}
                  label={t(AppStrings.Common.PortalInviteSubTitle)}
                  marginTop={20}
                />
              </View>
              <View>
                <FastField
                  component={TextField}
                  label={t(AppStrings.Common.EmailBody)}
                  name={`${formikFieldNamePrefix}.emailBody`}
                  multiline
                  rows="4"
                  multiLineSize={"large"}
                  marginTop={20}
                />
              </View>
              <View height={40} />
            </Fragment>
          )}
        </View>
      </View>
    </View>
  );
};
