import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import MomentUtils from "@date-io/moment";
import type { ApiError, DepositLinkedTransactionDto, GetAllDepositsQuery } from "@doorloop/dto";
import {
  createValidator,
  DateFormats,
  DepositAccountTypes,
  DepositDto,
  DepositTransactionLineDto,
  GetAllLeasePaymentsQuery,
  LinkedResourceType,
  LinkedToWhat,
  SegmentEventTypes
} from "@doorloop/dto";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { accountsApi } from "api/accounts";
import { ApiResult } from "api/apiResult";
import { depositsApi } from "api/depositsApi";
import { filesApi } from "api/filesApi";
import { AddIcon, DownloadSimpleIcon, NoOutstandingTransactionIcon } from "assets/icons";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { LoadingDialog } from "DLUI/dialogs/components/loading";
import AccountBalanceLoader from "DLUI/dialogs/refund/accountBalanceLoader";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import { FormikDatePicker, FormikReferenceLabel, ValidationIndicator, ViewOnlyInput } from "DLUI/form";
import { Icon } from "DLUI/icon";
import { Notes } from "DLUI/notes";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import { FastField, FieldArray, FormikContext, useFormik } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DialogFrame from "../../components/dialogFrame";
import DeleteConfirmation from "DLUI/dialogs/components/deleteConfirmation";
import { v4 as uuid } from "uuid";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import ReconciledNotificationView from "DLUI/dialogs/components/reconciledNotificationView";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { ExclamationAlert } from "DLUI/dialogs/alert/alert";
import { useUndepositedLinesHelper } from "./useUndepositedLinesHelper";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";
import { ControlledCheckBox } from "DLUI/form/checkBox/base/controlledCheckBox";
import { analyticsService } from "../../../../../services/analyticsService";
import { history } from "store/history";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { BankDepositDialogFooter } from "DLUI/dialogs/otherTransactions/bankDeposit/bankDepositDialogFooter";
import { useEffectAsync } from "../../../../../hooks/useEffectAsync";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { ReversePaymentNotification } from "./reversePaymentNotification";
import { leasePaymentsApi } from "api/leasePaymentsApi";
import DLButton, { DLButtonColorsEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import { useDataList } from "DLUI/dataList/hooks/useDataList";
import { UndepositsEntryList } from "DLUI/dialogs/otherTransactions/bankDeposit/undepositsEntryList";
import { SectionTitle } from "DLUI/screen";
import DepositsLine from "DLUI/dialogs/otherTransactions/bankDeposit/depositsLine";
import { Button } from "DLUI/button";
import { useLastArrayItemRef } from "@/hooks/useLastArrayItemRef";
import { useAggregatedFilterObject } from "@/hooks/useAggregatedFilterObject";
import { useJournalEntryPrinting } from "hooks/useJournalEntryPrinting";
import { LoadingStatus } from "common/native/types";

interface BankDepositDialogProps {
  didFinishOperation: (values: DepositDto) => void;
  onBackdropClick: () => void;
  dialogTitle?: string;
  permission?: AnyPermissionClearance;
  accountId?: string;
}

interface BankDepositDialogParams {
  transactionId: string;
  accountId: string;
}

interface BankDepositDialogDimensions {
  width: number;
  height: number;
}

enum BankDepositDialogView {
  Form,
  Loading,
  AccountReconciledAlert,
  DeleteConfirmation
}

const BankDepositDialog = ({
  didFinishOperation,
  onBackdropClick = () => {},
  dialogTitle,
  permission,
  accountId
}: BankDepositDialogProps): JSX.Element => {
  const { t } = useTranslation();
  const { isMobile, screenContainerPadding } = useResponsiveHelper();
  const { transactionId, accountId: accountIdFromParams } = useParams<BankDepositDialogParams>();
  const editMode = transactionId !== undefined;
  const [viewIndex, setViewIndex] = useState(BankDepositDialogView.Form);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    editMode ? DialogState.Show : DialogState.Hidden
  );
  const [shouldRenderForm, setShouldRenderForm] = useState<boolean>(!editMode);
  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);
  const [invalidOtherDepositItemsErrorMessage, setInvalidOtherDepositItemsErrorMessage] = useState<
    string | undefined
  >();
  const [createTransferErrorText, setCreateTransferErrorText] = useState<string>(
    t(AppStrings.Common.NetworkErrorTitle)
  );
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const [loadingDialogSuccessText, setLoadingDialogSuccessText] = useState<string>("");
  const [loadingDialogLoadingText, setLoadingDialogLoadingText] = useState<string>("");
  const [hasNegativeLineItems, setHasNegativevLineItems] = useState(false);
  const lastSaveButtonSelectedIndex = useRef(0);
  const [printDepositSlip, setPrintDepositSlip] = useState(false);
  const [isRenderUndeposits, setIsRenderUndeposits] = useState(false);

  const {
    filterObject: aggregatedQueryFilter,
    onFilterChange,
    onFilterRemove
  } = useAggregatedFilterObject<Partial<Omit<GetAllDepositsQuery, "page_number">>>({
    filter_depositToAccount: transactionId ? undefined : accountId || accountIdFromParams
  });

  const lastDepositAccountIdRef = useRef(aggregatedQueryFilter.filter_depositToAccount);

  const validateForm = useMemo(() => createValidator(DepositDto), []);

  const initFormValues = (): DepositDto => {
    const depositDto = new DepositDto();
    depositDto.date = moment().format(DateFormats.ISO_DATE_SERVER_FORMAT);
    depositDto.depositToAccount = lastDepositAccountIdRef.current;
    return depositDto;
  };

  const formik = useFormik({
    initialValues: initFormValues(),
    onSubmit: _.noop,
    validate: validateForm
  });

  const getAll = async (query) => {
    if (editMode) {
      setLoadingDialogState(DialogState.Hidden);
      return new ApiResult({
        data: formik.values.linkedTransactions,
        total: 1
      });
    }

    if (!query.filter_depositToAccount) {
      return new ApiResult({ data: [], total: 0 });
    }

    if (isInitialLoading) {
      setLoadingDialogLoadingText(t(AppStrings.OtherTransactions.BankDeposit.LoadingUndepositEntries));
      setLoadingDialogState(DialogState.Show);
    }

    const response = await depositsApi.getUndepositedEntriesForAccount(query);
    if (response && response.status) {
      if (query.page_number === 1 || query.filter_text) {
        if (!editMode && formik && response.data.total === 0) {
          const line = new DepositTransactionLineDto({});
          line.uniqueIndex = uuid();
          await formik.setFieldValue("lines", [line]);
        }
      }

      setTimeout(() => {
        setLoadingDialogState(DialogState.Hidden);
      }, 0);
    } else {
      setLoadingDialogState(DialogState.Error);
      showLoadingDialogError(response && response.message ? response.message : t(AppStrings.Common.GeneralError));
    }

    return response;
  };

  const useDataListUniqueKey = useMemo(() => uuid(), []);

  const { allRows, infiniteQuery, totalAllData } = useDataList<DepositLinkedTransactionDto, GetAllDepositsQuery>({
    uniqueKey: useDataListUniqueKey,
    infinite: true,
    queryFilter: aggregatedQueryFilter,
    restApi: { getAll }
  });
  const { hasNextPage, isFetchingNextPage, fetchNextPage, refetch, isInitialLoading, isLoading, hasPreviousPage } =
    infiniteQuery;

  useEffect(
    () => {
      if (formik.values.depositToAccount && !isInitialLoading && !aggregatedQueryFilter.filter_text) {
        setIsRenderUndeposits(Boolean(allRows.length));
      }

      if (!isLoading && !hasPreviousPage && formik.values.linkedTransactions?.length) {
        const linkedTransactions = formik.values.linkedTransactions?.filter((item) =>
          allRows.find((row) => row.linkedTransaction === item.linkedTransaction)
        );

        if (!_.eq(linkedTransactions, formik.values.linkedTransactions)) {
          formik.setFieldValue("linkedTransactions", linkedTransactions?.length ? linkedTransactions : undefined);
        }
      }
    },
    [allRows] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleChangeDepositAccountId = (accountId?: string) => {
    setIsRenderUndeposits(false);
    if (accountId) {
      onFilterChange({
        filter_depositToAccount: accountId,
        filter_text: ""
      });
    } else {
      onFilterRemove(["filter_depositToAccount", "filter_text"]);
    }
  };

  const handleClose = (values: DepositDto, isAddNew?: boolean) => {
    if (isAddNew) {
      lastDepositAccountIdRef.current = formik.values.depositToAccount;
      formik.resetForm?.();

      setShouldRenderForm(false);
      handleChangeDepositAccountId();
      setAttachments([]);
      setLoadingDialogState(DialogState.Hidden);
      setViewIndex(0);

      setTimeout(() => {
        setShouldRenderForm(true);
        handleChangeDepositAccountId(lastDepositAccountIdRef.current);
      }, 100);
    } else {
      didFinishOperation(values);
    }
  };

  const [isAccountReconciled, setIsAccountReconciled] = useState<boolean>(false);

  const [dialogDimensions, setDialogDimensions] = useState<BankDepositDialogDimensions>({
    width: 1350,
    height: 920
  });

  const {
    areAllLinesChecked,
    areAnyLinesChecked,
    onToggleLine,
    onToggleAllLines,
    linesTotalAmount,
    totalLinesChecked
  } = useUndepositedLinesHelper({ allRows, formik });

  useEffectAsync(async () => {
    if (editMode) {
      await loadDepositData();
    }
  }, [editMode]);

  const isValidForm = async () => {
    formik.setFieldTouched("date");
    formik.setFieldTouched("depositToAccount");

    if (formik.values.lines) {
      formik.values.lines.forEach((currentLine, lineIndex) => {
        formik.setFieldTouched(`lines[${lineIndex}].amount`);
        formik.setFieldTouched(`lines[${lineIndex}].account`);
        formik.setFieldTouched(`lines[${lineIndex}].receivedFromResourceId`);
        formik.setFieldTouched(`lines[${lineIndex}].property`);
      });
    }

    if (!formik.values.lines?.length && !formik.values.linkedTransactions?.length) {
      setInvalidOtherDepositItemsErrorMessage(t(AppStrings.OtherTransactions.BankDeposit.InvalidLinkDeposit));
      return false;
    }

    const errors = (await formik.validateForm()) as any;
    return _.isEmpty(errors);
  };

  const { printLoadingStatus, printJournalEntry } = useJournalEntryPrinting({
    journalEntryId: transactionId
  });

  const createDeposit = async (isAddNew?: boolean) => {
    setViewIndex(BankDepositDialogView.Loading);
    setLoadingDialogState(DialogState.Show);
    const { values } = formik;
    const response = (await depositsApi.create(values).catch((error) => {
      setLoadingDialogState(DialogState.Error);
      setCreateTransferErrorText(error);
    })) as ApiResult<any>;

    if (response.status) {
      if (printDepositSlip) {
        await printJournalEntry(response.data.id);
      }
      await filesApi.uploadFiles(attachments, response.data.id!, LinkedResourceType.Deposit).catch((error: string) => {
        setLoadingDialogState(DialogState.Error);
        setCreateTransferErrorText(error);
      });
      analyticsService.track(SegmentEventTypes.PAYABLE_TRANSACTION_CREATED, {
        transactionType: "Bank Deposit",
        payeeType: "Owner",
        isRecurring: Boolean(values.recurringTransaction),
        isUnitEmpty: !values.lines?.some(({ unit }) => unit),
        totalAmount: values.totalAmount || 0,
        numOfLineItems: _.size(values.lines),
        isPrintPdfChecked: printDepositSlip
      });
      setLoadingDialogState(DialogState.Success);
      handleClose(values, isAddNew);
    } else {
      setCreateTransferErrorText(response.message);
      setLoadingDialogState(DialogState.Error);
    }
  };

  const showLoadingDialogError = (errorMsg: string) => {
    setLoadingDialogErrorText(errorMsg);
    setLoadingDialogState(DialogState.Error);
  };

  const checkDepositHasReversedPayments = async (deposit: DepositDto) => {
    if (!deposit?.lines) {
      return;
    }

    const negativeLineRefs = deposit.lines
      .filter((line) => line.reference && line.amount && line.amount < 0)
      .map((item) => item.reference || "");

    if (!negativeLineRefs.length) {
      return;
    }

    const leasePaymentApiResponse = await leasePaymentsApi.getAll(
      new GetAllLeasePaymentsQuery({ filter_reversed: negativeLineRefs })
    );

    setHasNegativevLineItems((leasePaymentApiResponse.data?.total && leasePaymentApiResponse.data.total > 0) || false);
  };

  const loadDepositData = async () => {
    if (!editMode || !transactionId) {
      return;
    }

    setLoadingDialogState(DialogState.Show);

    const canContinueToLoad = await loadIsAccountReconciledAndCanContinueToLoad();

    if (!canContinueToLoad) {
      return;
    }

    setDialogDimensions({
      width: 1350,
      height: window.innerHeight
    });

    const response = await depositsApi.get(transactionId).catch((error) => {
      setLoadingDialogState(DialogState.Error);
      setLoadingDialogErrorText(error);
    });

    if (response && response.status && response.data) {
      await formik.resetForm({
        values: {
          ...response.data,
          linkedTransactions: response.data?.linkedTransactions?.map((item) => {
            return { ...item, selected: editMode };
          })
        }
      });

      checkDepositHasReversedPayments(response.data);

      handleChangeDepositAccountId(response.data.depositToAccount);
      setShouldRenderForm(true);
    } else {
      setLoadingDialogState(DialogState.Error);
      setLoadingDialogErrorText(response ? response.message : "");
    }
  };

  const loadIsAccountReconciledAndCanContinueToLoad = async () => {
    try {
      const isAccountReconciledResponse = await depositsApi.getIsAccountReconciled(transactionId);
      const isAccountReconciled = Boolean(isAccountReconciledResponse.data);

      setIsAccountReconciled(isAccountReconciled);

      if (isAccountReconciled) {
        setViewIndex(BankDepositDialogView.AccountReconciledAlert);
      }

      return !isAccountReconciled;
    } catch (error) {
      setViewIndex(BankDepositDialogView.Loading);
      setLoadingDialogState(DialogState.Error);
      setLoadingDialogErrorText((error as ApiError).message);

      return false;
    }
  };

  const updateDeposit = async () => {
    if (transactionId) {
      setViewIndex(BankDepositDialogView.Loading);
      setShouldRenderForm(false);
      setLoadingDialogState(DialogState.Show);
      setLoadingDialogLoadingText(t(AppStrings.OtherTransactions.BankDeposit.UpdatingDeposit));

      const results = await depositsApi.update(transactionId, formik.values).catch((error) => {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(error);
      });

      if (results && results.status && results.data) {
        await filesApi.uploadFiles(attachments, results.data.id!, LinkedResourceType.Deposit).catch((error: string) => {
          setLoadingDialogState(DialogState.Error);
          setLoadingDialogErrorText(error);
        });
        setLoadingDialogSuccessText(t(AppStrings.Common.OperationCompleted));
        setLoadingDialogState(DialogState.Success);
        setTimeout(() => {
          didFinishOperation(results.data || new DepositDto());
        }, 500);
      } else {
        setCreateTransferErrorText(results ? results.message : "");
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const didPressSaveButton = async (saveButtonIndex?: number, isAddNew?: boolean) => {
    const isValid = await isValidForm();
    if (isValid) {
      if (editMode) {
        await updateDeposit();
      } else {
        lastSaveButtonSelectedIndex.current = saveButtonIndex || 0;
        await createDeposit(isAddNew);
      }
    }
  };

  const totalLinesSelected = useMemo(
    () => _.size(formik.values.lines) + _.size(formik.values.linkedTransactions),
    [formik.values.lines, formik.values.linkedTransactions]
  );

  const calculateTotalAmount = useMemo(() => {
    if (!formik.values.depositToAccount || loadingDialogState !== DialogState.Hidden) {
      return 0;
    }

    return linesTotalAmount;
  }, [formik.values.depositToAccount, loadingDialogState, linesTotalAmount]);

  const handleTogglePrintDepositCheckBox = () => setPrintDepositSlip((prev) => !prev);

  const handlePrintClick = async () => {
    await printJournalEntry();
  };

  const depositSlipCheckboxOrDownloadButton = useMemo(
    () =>
      !editMode ? (
        <ControlledCheckBox
          checked={printDepositSlip}
          disabled={totalLinesSelected === 0}
          onChange={handleTogglePrintDepositCheckBox}
          labelValueProps={{
            text: AppStrings.OtherTransactions.BankDeposit.PrintDepositSlip,
            color: "black"
          }}
        />
      ) : (
        <DLButton
          actionText={AppStrings.OtherTransactions.BankDeposit.PrintDepositSlip}
          onClick={handlePrintClick}
          variant={DLButtonVariantsEnum.TEXT}
          color={DLButtonColorsEnum.NEUTRAL}
          icons={{ start: { src: DownloadSimpleIcon } }}
          isLoading={printLoadingStatus === LoadingStatus.loading}
        />
      ),
    [editMode, totalLinesSelected, printDepositSlip, printLoadingStatus]
  );

  const renderActionPanelButtons = () => {
    if (viewIndex === BankDepositDialogView.Loading) {
      return <div />;
    }
    const didPressDeleteButton = () => {
      setViewIndex(BankDepositDialogView.DeleteConfirmation);
    };

    return (
      <View noWrap>
        {!isMobile && (
          <>
            <BankDepositDialogFooter
              totalSelectedLabel={AppStrings.Common.NumberOfPayments}
              totalSelectedValue={_.toString(totalLinesSelected)}
              totalLabel={AppStrings.OtherTransactions.BankDeposit.TotalDeposit}
              totalValue={_.toString(calculateTotalAmount)}
            >
              {depositSlipCheckboxOrDownloadButton}
            </BankDepositDialogFooter>
            <HorizontalSeparationLine />
          </>
        )}
        <FormActionButtons
          clearance={permission}
          propsActionPanel={{
            editMode,
            onDeleteButtonPress: didPressDeleteButton,
            paddingRight: isMobile ? 0 : 40
          }}
          propsSubButton={{ onClick: onBackdropClick }}
          propsMainButton={
            editMode
              ? { type: "cta", props: { onClick: async () => await didPressSaveButton() } }
              : {
                  type: "split",
                  props: {
                    lastSaveButtonIndex: lastSaveButtonSelectedIndex.current,
                    options: [
                      { text: AppStrings.Common.Save, onClick: didPressSaveButton },
                      {
                        text: AppStrings.Common.SaveAndNew,
                        onClick: async (saveButtonIndex) => await didPressSaveButton(saveButtonIndex, true)
                      }
                    ]
                  }
                }
          }
        />
      </View>
    );
  };

  const didPressDismissButton = () => {
    if (isAccountReconciled) {
      history.goBack();
      return;
    }

    setShouldRenderForm(true);
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(BankDepositDialogView.Form);
  };

  const onRetryButtonPress = async () => {
    if (editMode) {
      await updateDeposit();
    } else {
      await didPressSaveButton();
    }
  };

  const onFileReceived = (files: FileListItemProps[]) => {
    setAttachments(files);
  };

  const renderAttachments = () => {
    if (!formik.values.depositToAccount || loadingDialogState !== DialogState.Hidden) {
      return null;
    }
    return (
      <View marginBottom={20} marginTop={20}>
        <View paddingLeft={40} paddingRight={40}>
          <FormAttachments
            onFileReceived={onFileReceived}
            files={attachments}
            editMode={editMode}
            resourceType={LinkedResourceType.Deposit}
            resourceId={formik.values?.id}
          />
        </View>
      </View>
    );
  };

  const didSelectDepositAccount = (event: object, value: any) => {
    formik.setFieldValue("linkedTransactions", undefined, false);

    if (value === null) {
      handleChangeDepositAccountId();
      formik.setFieldValue("depositToAccount", value);
    } else {
      handleChangeDepositAccountId(value.id);
    }
  };

  const addLine = useCallback(async () => {
    const depositTransactionLineDto = new DepositTransactionLineDto();
    depositTransactionLineDto.property = LinkedToWhat.PROPERTY;
    depositTransactionLineDto.uniqueIndex = uuid();
    const lines = formik.values.lines || [];
    await formik.setFieldValue("lines", [...lines, depositTransactionLineDto], false);
  }, [formik]);

  const lastLineRef = useLastArrayItemRef<HTMLDivElement>(addLine, []);

  const renderInstructionsView = () => (
    <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"} className={"InstructionsView"}>
      <Icon Source={NoOutstandingTransactionIcon} pathColor={"black"} />
      <Text
        align={"center"}
        fontSize={22}
        color={"black"}
        marginTop={20}
        value={AppStrings.OtherTransactions.BankDeposit.InstructionsText}
      />
    </View>
  );

  const rows = useMemo(
    () =>
      allRows.map((item) => {
        return {
          ...item,
          selected: Boolean(
            formik.values.linkedTransactions?.find((row) => row.linkedTransaction === item.linkedTransaction)
          )
        };
      }),
    [allRows, formik.values.linkedTransactions]
  );

  const onRefetch = useCallback(async () => await refetch(), [refetch]);

  const renderFields = () => {
    if (!formik.values.depositToAccount) {
      return renderInstructionsView();
    }

    if (loadingDialogState !== DialogState.Hidden && !isRenderUndeposits) {
      return (
        <View flex={1} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={onRefetch}
            hideDismissButton
          />
        </View>
      );
    }

    return (
      <View fullWidth>
        {isRenderUndeposits && (
          <UndepositsEntryList
            accountId={formik.values.depositToAccount}
            allRows={rows}
            areAllLinesChecked={areAllLinesChecked}
            areAnyLinesChecked={areAnyLinesChecked}
            onToggleLine={onToggleLine}
            onToggleAllLines={onToggleAllLines}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            loadingDialogState={loadingDialogState}
            fetchNextPage={fetchNextPage}
            searchProps={
              transactionId
                ? undefined
                : {
                    onChange: (newValue) =>
                      onFilterChange({
                        filter_text: newValue
                      }),
                    defaultValue: aggregatedQueryFilter.filter_text
                  }
            }
            totalAllData={totalAllData || 0}
            totalChecked={totalLinesChecked || 0}
          />
        )}

        <View marginTop={20}>
          <View shouldShow showAnimation={"fade-in"} hideAnimation={"fade-out"}>
            <SectionTitle
              fontSize={16}
              title={t("otherTransactions.bankDeposit.otherDepositItems")}
              type={"underline"}
            />

            <FieldArray
              name={"lines"}
              render={(arrayHelpers) => {
                const didPressDeleteDepositLine = (lineIndex: number) => {
                  arrayHelpers.remove(lineIndex);
                };
                if (formik.values.lines) {
                  const lines = formik.values.lines.map((currentLine: DepositTransactionLineDto, lineIndex: number) => {
                    const key = editMode ? currentLine.id : currentLine.uniqueIndex;
                    return (
                      <Fragment key={"DTL" + key}>
                        <DepositsLine
                          domRef={lineIndex === _.size(formik.values.lines) - 1 ? lastLineRef : undefined}
                          didPressDeleteDepositLine={didPressDeleteDepositLine}
                          lineIndex={lineIndex}
                        />
                      </Fragment>
                    );
                  });
                  return (
                    <View>
                      {lines}
                      <ButtonAddOtherDeposit onClick={addLine} />
                    </View>
                  );
                }
                return (
                  <View>
                    <ButtonAddOtherDeposit onClick={addLine} />
                  </View>
                );
              }}
            />
          </View>
        </View>

        <ValidationIndicator
          shouldShow={invalidOtherDepositItemsErrorMessage !== undefined}
          maxWidth={600}
          displayText={invalidOtherDepositItemsErrorMessage || ""}
        />
      </View>
    );
  };

  const renderAccountField = () => {
    if (editMode && formik.values.depositToAccount) {
      let accountName = "";
      const accountsDictionary = accountsApi.getItemFromDictionary(formik.values.depositToAccount);
      if (accountsDictionary) {
        accountName = accountsDictionary.name;
      }
      return (
        <View>
          <ViewOnlyInput
            label={AppStrings.Leases.LeaseTransaction.Deposit.DepositAccount}
            value={accountName}
            alignItems={"flex-start"}
            marginTop={20}
            width={"100%"}
            fullWidth
          />
        </View>
      );
    }
    return (
      <BankAccountFormikAutoCompleteField
        addCreateOption
        onChange={didSelectDepositAccount}
        required
        uniqueIndex={"depositToAccount"}
        name={"depositToAccount"}
        queryParams={{
          filter_types: DepositAccountTypes,
          filter_active: true
        }}
        label={t(AppStrings.Leases.LeaseTransaction.Deposit.DepositAccount)}
        marginTop={20}
        defaultValue={formik.values.depositToAccount}
      />
    );
  };

  const renderNotes = () => {
    if (!formik.values.depositToAccount) {
      return null;
    }
    return (
      <View marginTop={20} justifyContent={"center"} height={50}>
        <FastFieldSafe component={Notes} height={50} name={"memo"} />
      </View>
    );
  };

  const renderForm = () => (
    <FormikContext.Provider value={formik}>
      <RestrictedPermissionAccess clearance={permission} showNoAccess>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <View
            width={"100%"}
            flex={1}
            paddingLeft={screenContainerPadding}
            paddingRight={screenContainerPadding}
            noWrap
          >
            {hasNegativeLineItems && <ReversePaymentNotification />}
            <ReconciledNotificationView register={formik.values?.register} />
            <View flexDirection={"row"} alignItems={"flex-end"}>
              <Grid item xs={12} sm={5} md={4} lg={4}>
                {renderAccountField()}
              </Grid>

              {isMobile ? null : (
                <Grid item sm={2} md={4} lg={4}>
                  <div />
                </Grid>
              )}

              <Grid item xs={12} sm={5} md={4} lg={4}>
                <View marginTop={20}>
                  <View>
                    <FastField component={FormikReferenceLabel} name={"reference"} backgroundColor={"dark"} />
                  </View>
                </View>
              </Grid>
              <SeparationLine marginTop={20} width={"100%"} height={1} />
            </View>
            <View flexDirection={"row"}>
              <Grid item xs={12} sm={5} md={4} lg={4}>
                <FastField
                  component={FormikDatePicker}
                  uniqueKey={"date"}
                  label={AppStrings.OtherTransactions.BankTransfer.TransferDate}
                  name={"date"}
                  noMargin
                  required
                  marginTop={20}
                />
              </Grid>

              {isMobile ? null : (
                <Grid item sm={2} md={4} lg={4}>
                  <div />
                </Grid>
              )}

              <Grid item xs={12} sm={5} md={4} lg={4}>
                <View>
                  <AccountBalanceLoader depositAccountId={formik.values.depositToAccount} marginTop={20} />
                </View>
              </Grid>
            </View>
            <HorizontalSeparationLine marginTop={20} />
            {renderFields()}
            {renderNotes()}
          </View>
          {renderAttachments()}
        </MuiPickersUtilsProvider>
      </RestrictedPermissionAccess>
    </FormikContext.Provider>
  );

  const renderView = ({ index }: any) => {
    if (index === BankDepositDialogView.Form) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        return (
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={loadingDialogLoadingText}
            errorText={loadingDialogErrorText}
            successText={loadingDialogSuccessText}
            onRetryButtonPress={loadDepositData}
            didPressDismissButton={didPressDismissButton}
          />
        );
      }
      if (shouldRenderForm) {
        return renderForm();
      }
      return <div />;
    }

    if (index === BankDepositDialogView.Loading) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={t(AppStrings.OtherTransactions.BankTransfer.LoadingText)}
            errorText={createTransferErrorText}
            successText={t(AppStrings.OtherTransactions.BankTransfer.CreateTransferSuccessText)}
            onRetryButtonPress={isAccountReconciled ? undefined : onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
          />
        </View>
      );
    }

    if (index === BankDepositDialogView.AccountReconciledAlert) {
      if (isAccountReconciled) {
        return (
          <ExclamationAlert
            title={t(AppStrings.Accounts.AdjustOpeningBalance)}
            subTitle={t(AppStrings.Accounts.AdjustOpeningBalanceDescription)}
            onDismissButton={didPressDismissButton}
          />
        );
      }

      return <div />;
    }

    if (index === BankDepositDialogView.DeleteConfirmation) {
      return (
        <DeleteConfirmation
          apiMethod={depositsApi}
          didPressDismissButton={didPressDismissDeleteButton}
          didFinishOperation={didFinishOperation}
          transactionId={transactionId}
          attachments={attachments}
        />
      );
    }

    return <div />;
  };

  const didPressDismissDeleteButton = () => {
    setViewIndex(0);
  };

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      if (editMode && loadingDialogState !== DialogState.Hidden) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }

    return "contentOnly";
  }, [viewIndex, loadingDialogState]);

  return (
    <DialogFrame
      onCloseButtonClick={onBackdropClick}
      title={dialogTitle}
      width={window.innerWidth < dialogDimensions.width ? window.innerWidth : dialogDimensions.width}
      height={window.innerHeight}
      renderView={renderView}
      numViews={4}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={frameType}
      useStickyFooter
    />
  );
};

const ButtonAddOtherDeposit = ({ onClick }: { onClick: () => void }) => (
  <Button
    color={"lightBlue"}
    type={"inlineText"}
    actionText={AppStrings.Leases.LeaseCharge.AddLineItem}
    onClick={onClick}
    LeftIcon={AddIcon}
    marginTop={20}
    applyColorForIcons
    iconSize={15}
  />
);

export default BankDepositDialog;
