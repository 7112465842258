"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecurringOrOneTimeTransactionFrequency = exports.RecurringTransactionFrequency = void 0;
var RecurringTransactionFrequency;
(function (RecurringTransactionFrequency) {
    RecurringTransactionFrequency["Daily"] = "Daily";
    RecurringTransactionFrequency["Weekly"] = "Weekly";
    RecurringTransactionFrequency["Every2Weeks"] = "Every2Weeks";
    RecurringTransactionFrequency["Monthly"] = "Monthly";
    RecurringTransactionFrequency["Every2Months"] = "Every2Months";
    RecurringTransactionFrequency["Quarterly"] = "Quarterly";
    RecurringTransactionFrequency["Every6Months"] = "Every6Months";
    RecurringTransactionFrequency["Annually"] = "Annually";
})(RecurringTransactionFrequency || (exports.RecurringTransactionFrequency = RecurringTransactionFrequency = {}));
var RecurringOrOneTimeTransactionFrequency;
(function (RecurringOrOneTimeTransactionFrequency) {
    RecurringOrOneTimeTransactionFrequency["OneTime"] = "OneTime";
    RecurringOrOneTimeTransactionFrequency["Weekly"] = "Weekly";
    RecurringOrOneTimeTransactionFrequency["Every2Weeks"] = "Every2Weeks";
    RecurringOrOneTimeTransactionFrequency["Monthly"] = "Monthly";
    RecurringOrOneTimeTransactionFrequency["Every2Months"] = "Every2Months";
    RecurringOrOneTimeTransactionFrequency["Quarterly"] = "Quarterly";
    RecurringOrOneTimeTransactionFrequency["Every6Months"] = "Every6Months";
    RecurringOrOneTimeTransactionFrequency["Annually"] = "Annually";
})(RecurringOrOneTimeTransactionFrequency || (exports.RecurringOrOneTimeTransactionFrequency = RecurringOrOneTimeTransactionFrequency = {}));
