import type { FieldSettingsProps, RentalApplicationFieldSettings } from "@doorloop/dto";
import { View } from "DLUI/view";
import { ControlledCheckBox, SwitchButton } from "DLUI/form";
import type { FC } from "react";
import React, { useMemo } from "react";
import AppStrings from "../../../../locale/keys";
import { getIn } from "formik";

interface FieldsGeneratorProps {
  fieldsConfigObject: Record<string, FieldSettingsProps>;
  formik: any;
  formName: keyof typeof RentalApplicationFieldSettings;
}

export const RentalAppFieldsGenerator: FC<FieldsGeneratorProps> = ({ fieldsConfigObject, formik, formName }) => {
  const {
    values: { [formName]: formValues },
    setFieldValue
  } = formik;
  const fieldsNames = useMemo(() => Object.keys(fieldsConfigObject), [fieldsConfigObject]);

  const handleFieldChange = ({ fieldName, type, value }) => {
    if (fieldName && type) {
      setFieldValue(`${formName}.fieldsSettings.${fieldName}.${type}`, value);

      if (type === "isMandatory" && !formValues?.fieldsSettings?.[fieldName]?.isVisible) {
        setFieldValue(`${formName}.fieldsSettings.${fieldName}.isVisible`, true);
      } else if (type === "isVisible" && !value && formValues?.fieldsSettings?.[fieldName]?.isMandatory) {
        setFieldValue(`${formName}.fieldsSettings.${fieldName}.isMandatory`, false);
      }
    }
  };

  return (
    <>
      {fieldsNames.map((fieldName) => {
        const { label, rawLabelText, isMandatory } = fieldsConfigObject[fieldName];

        const labelText: string = useMemo(() => {
          if (rawLabelText) {
            return rawLabelText;
          }
          if (label) {
            return getIn(AppStrings, label);
          }
          return undefined;
        }, [rawLabelText, label]);

        return (
          <View
            height={36}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            noWrap
            key={fieldName}
          >
            <SwitchButton
              label={labelText}
              onChange={(value) => handleFieldChange({ fieldName, type: "isVisible", value })}
              checked={isMandatory || formValues?.fieldsSettings?.[fieldName]?.isVisible}
              disabled={isMandatory}
            />
            <ControlledCheckBox
              checked={isMandatory || formValues?.fieldsSettings?.[fieldName]?.isMandatory || false}
              disabled={isMandatory}
              onChange={(value) => handleFieldChange({ fieldName, type: "isMandatory", value })}
            />
          </View>
        );
      })}
    </>
  );
};
