export default {
  Screen: {
    Owner: "owners.screen.owner",
    OwnerPortal: "owners.screen.ownerPortal",
    AddNewOwner: "owners.screen.addNewOwner",
    SearchPlaceHolder: "owners.screen.searchPlaceHolder",
    EmptydataAddNew: "owners.screen.emptydataAddNew",
    EmptydataViewInstructionsText: "owners.screen.emptydataViewInstructionsText",
    Agreement: "owners.screen.agreement",
    EditOwner: "owners.screen.editOwner",
    Owners: "owners.screen.owners",
    Ownership: "owners.screen.ownership",
    agreementExpires: "owners.screen.agreementExpires",
    Address: "owners.screen.address",
    HelpPanel: {
      DialogTitle: "owners.screen.helpPanel.dialogTitle",
      Description: "owners.screen.helpPanel.description",
      ConnectProperties: "owners.screen.helpPanel.connectProperties",
      CreateOwner: "owners.screen.helpPanel.createOwner",
      DistributeFunds: "owners.screen.helpPanel.distributeFunds"
    },
    SendingInvite: "owners.screen.sendingInvite",
    LoginToPortal: "owners.screen.loginToPortal",
    EditOwnerPortalSettings: "owners.screen.editOwnerPortalSettings"
  },
  Details: {
    DeleteConfirmationText: "owners.details.deleteConfirmationText",
    DeleteLoadingText: "owners.details.deleteLoadingText",
    DeleteOwnerSuccessText: "owners.details.deleteOwnerSuccessText",
    Transactions: {
      NewOwnerContribution: "owners.details.transactions.newOwnerContribution",
      NewOwnerDistribution: "owners.details.transactions.newOwnerDistribution"
    },
    PropertyOwners: "owners.details.propertyOwners"
  },
  NewOwner: {
    SameAsOwnerAddress: "owners.newOwner.sameAsOwnerAddress",
    CreateNewOwner: "owners.newOwner.createNewOwner",
    LoadingText: "owners.newOwner.loadingText",
    ErrorText: "owners.newOwner.errorText",
    SuccessText: "owners.newOwner.successText",
    SelectOwner: "owners.newOwner.selectOwner",
    UseElectronicPayments: "owners.newOwner.useElectronicPayments",
    DontUseElectronicPayments: "owners.newOwner.dontUseElectronicPayments",
    DontSend1099Forms: "owners.newOwner.dontSend1099Forms",
    Send1099Forms: "owners.newOwner.send1099Forms",
    MenegmentAgreement: "owners.newOwner.menegmentAgreement",
    MenegmentAgreementDescription: "owners.newOwner.menegmentAgreementDescription",
    AgreementStartDate: "owners.newOwner.agreementStartDate",
    AgreementEndDate: "owners.newOwner.agreementEndDate",
    OwnerLoginEmailPhoneDescription: "owners.newOwner.ownerLoginEmailPhoneDescription"
  },
  EditOwners: {
    Instructions: "owners.editOwners.instructions",
    AddOwner: "owners.editOwners.addOwner",
    OwnershipInputlabel: "owners.editOwners.ownershipInputlabel",
    OwnerPortalEnabled: "owners.editOwners.ownerPortalEnabled",
    OwnershipValidationText: "owners.editOwners.ownershipValidationText",
    OwnershipValidationNoOwnerSelected: "owners.editOwners.ownershipValidationNoOwnerSelected",
    OwnerAddingHelpText: "owners.editOwners.ownerAddingHelpText"
  },
  OwnerContribution: {
    NewOwnerContribution: "owners.ownerContribution.newOwnerContribution",
    ContributionDate: "owners.ownerContribution.contributionDate",
    ContributionAmount: "owners.ownerContribution.contributionAmount",
    OwnerContributions: "owners.ownerContribution.ownerContributions",
    AmountReceived: "owners.ownerContribution.amountReceived",
    MultipleProperties: "owners.ownerContribution.multipleProperties",
    DeleteContributionConfirmationText: "owners.ownerContribution.deleteContributionConfirmationText",
    DeleteContributionLoadingText: "owners.ownerContribution.deleteContributionLoadingText",
    DeleteContributionSuccessText: "owners.ownerContribution.deleteContributionSuccessText"
  },
  OwnerDistributions: {
    HelpPanel: {
      Description: "owners.ownerDistributions.helpPanel.description",
      RecordDistribution: "owners.ownerDistributions.helpPanel.recordDistribution",
      EditDeleteDistribution: "owners.ownerDistributions.helpPanel.editDeleteDistribution"
    },
    WizardSteps: {
      Recipients: "owners.ownerDistributions.wizardSteps.recipients",
      AccountReview: "owners.ownerDistributions.wizardSteps.accountReview",
      Allocate: "owners.ownerDistributions.wizardSteps.allocate",
      Distribute: "owners.ownerDistributions.wizardSteps.distribute"
    },
    AccountReviewStep: {
      Title: "owners.ownerDistributions.accountReviewStep.title",
      Description: "owners.ownerDistributions.accountReviewStep.description",
      ListHeader: {
        Property: "owners.ownerDistributions.accountReviewStep.listHeader.property",
        Owners: "owners.ownerDistributions.accountReviewStep.listHeader.owners",
        OpeningBalance: "owners.ownerDistributions.accountReviewStep.listHeader.openingBalance",
        UndepositedFunds: "owners.ownerDistributions.accountReviewStep.listHeader.undepositedFunds"
      },
      DepositFunds: "owners.ownerDistributions.accountReviewStep.depositFunds",
      MissingInfo: "owners.ownerDistributions.accountReviewStep.missingInfo",
      OwnersAssigned: "owners.ownerDistributions.accountReviewStep.ownersAssigned",
      OpeningBalanceSet: "owners.ownerDistributions.accountReviewStep.openingBalanceSet",
      NotAllOwnersFilledError: "owners.ownerDistributions.accountReviewStep.notAllOwnersFilledError",
      OpeningBalanceToastText: "owners.ownerDistributions.accountReviewStep.openingBalanceToastText",
      SetOwnersToastText: "owners.ownerDistributions.accountReviewStep.setOwnersToastText",
      AllDoneToastText: "owners.ownerDistributions.accountReviewStep.allDoneToastText",
      ConfirmationDialogTitle: "owners.ownerDistributions.accountReviewStep.confirmationDialogTitle",
      ConfirmationDialogDescription: "owners.ownerDistributions.accountReviewStep.confirmationDialogDescription",
      Continue: "owners.ownerDistributions.accountReviewStep.continue",
      ReviewAccounts: "owners.ownerDistributions.accountReviewStep.reviewAccounts"
    },
    SelectOwnersAndProperties: "owners.ownerDistributions.selectOwnersAndProperties",
    SelectOwners: "owners.ownerDistributions.selectOwners",
    Distribution: "owners.ownerDistributions.distribution",
    WhichProperties: "owners.ownerDistributions.whichProperties",
    WhichOwners: "owners.ownerDistributions.whichOwners",
    AllOwners: "owners.ownerDistributions.allOwners",
    SomeOwners: "owners.ownerDistributions.someOwners",
    NoOutstandingBalanceFound: "owners.ownerDistributions.noOutstandingBalanceFound",
    EnterDistributionAmounts: "owners.ownerDistributions.enterDistributionAmounts",
    CashBalance: "owners.ownerDistributions.cashBalance",
    Reserve: "owners.ownerDistributions.reserve",
    MaxDistribution: "owners.ownerDistributions.maxDistribution",
    AutomaticDistribution: "owners.ownerDistributions.automaticDistribution",
    DistributionAmount: "owners.ownerDistributions.distributionAmount",
    DistributeMaxAmount: "owners.ownerDistributions.distributeMaxAmount",
    availableDistributeForProperty: "owners.ownerDistributions.availableDistributeForProperty",
    AvailableDistributeToOwners: "owners.ownerDistributions.availableDistributeToOwners",
    DistributeFounds: "owners.ownerDistributions.distributeFounds",
    DistributeAmount: "owners.ownerDistributions.distributeAmount",
    DistributeMethod: "owners.ownerDistributions.distributeMethod",
    DistributeFoundsDescription: "owners.ownerDistributions.distributeFoundsDescription",
    CreatingExpenses: "owners.ownerDistributions.creatingExpenses",
    ExpensesCreated: "owners.ownerDistributions.expensesCreated",
    OwnerPropertiesNoResults: "owners.ownerDistributions.ownerPropertiesNoResults",
    OwnerDistribution: "owners.ownerDistributions.ownerDistribution",
    DeleteDistributionConfirmationText: "owners.ownerDistributions.deleteDistributionConfirmationText",
    DeleteDistributionLoadingText: "owners.ownerDistributions.deleteDistributionLoadingText",
    DeleteDistributionSuccessText: "owners.ownerDistributions.deleteDistributionSuccessText",
    EditOwnerDistribution: "owners.ownerDistributions.editOwnerDistribution",
    SelectPortfolios: "owners.ownerDistributions.selectPortfolios",
    AccountAvailableCash: "owners.ownerDistributions.accountAvailableCash",
    AccountAvailableCashToDistribute: "owners.ownerDistributions.accountAvailableCashToDistribute",
    RemainsToDistribute: "owners.ownerDistributions.remainsToDistribute",
    PropertyAccountsSummary: "owners.ownerDistributions.propertyAccountsSummary",
    UndepositedFunds: "owners.ownerDistributions.undepositedFunds",
    SecurityDepositLiabilities: "owners.ownerDistributions.securityDepositLiabilities",
    PropertyReserve: "owners.ownerDistributions.propertyReserve",
    CashAvailableToDistributeForProperty: "owners.ownerDistributions.cashAvailableToDistributeForProperty",
    InsufficientFundsWarning: "owners.ownerDistributions.insufficientFundsWarning",
    NumberOfPayments: "owners.ownerDistributions.numberOfPayments",
    WarningTotalDistributeAmount: "owners.ownerDistributions.warningTotalDistributeAmount",
    PaymentsDistributeDate: "owners.ownerDistributions.paymentsDistributeDate",
    TotalDistributeAmount: "owners.ownerDistributions.totalDistributeAmount",
    Accounts: "owners.ownerDistributions.accounts",
    AddMemo: "owners.ownerDistributions.addMemo",
    AddToPrintQueue: "owners.ownerDistributions.addToPrintQueue",
    AccountReviewEmptyStateTitle: "owners.ownerDistributions.accountReviewEmptyStateTitle",
    AccountReviewEmptyStateSubTitle: "owners.ownerDistributions.accountReviewEmptyStateSubTitle",
    CheckNumber: "owners.ownerDistributions.checkNumber",
    Consolidated: "owners.ownerDistributions.consolidated",
    UnableToRemoveProperty: "owners.ownerDistributions.unableToRemoveProperty",
    MustSelectOneProperty: "owners.ownerDistributions.mustSelectOneProperty",
    MustSelectOneOwner: "owners.ownerDistributions.mustSelectOneOwner",
    Completed: "owners.ownerDistributions.completed",
    ViewTransactionsReport: "owners.ownerDistributions.viewTransactionsReport",
    ViewOwnerStatement: "owners.ownerDistributions.viewOwnerStatement",
    PrintChecks: "owners.ownerDistributions.printChecks",
    CompleteEpay: "owners.ownerDistributions.completeEpay",
    TooltipResetOwnerDistributionAmount: "owners.ownerDistributions.tooltipResetOwnerDistributionAmount",
    TooltipRemoveFromDistribution: "owners.ownerDistributions.tooltipRemoveFromDistribution"
  }
};
