import { InputBase } from "@material-ui/core";
import { View } from "../../view";
import makeStyles from "./globalSearchInputStyles";
import clsx from "clsx";
import { isMacOs } from "react-device-detect";
import type { FC } from "react";
import React from "react";
import { SearchIcon } from "@/assets";
import { Icon } from "DLUI/icon";
import { useTypedTranslation } from "locale";

interface GlobalSearchInputProps {
  onGlobalSearchPress: () => void;
}

export const GlobalSearchInput: FC<GlobalSearchInputProps> = ({ onGlobalSearchPress }) => {
  const { t } = useTypedTranslation();
  const classes = makeStyles();
  const searchShortcut = isMacOs ? "⌘K" : "CTRL+K";

  return (
    <View
      className={clsx(["global-search-input-container", classes.globalSearchInputContainer])}
      flexDirection={"row"}
      noWrap
      alignItems={"center"}
      onClick={onGlobalSearchPress}
    >
      <InputBase
        placeholder={t("common.globalSearchPlaceholder", { searchShortcut })}
        inputProps={{ "aria-label": t("common.globalSearchPlaceholder", { searchShortcut }) }}
        className={clsx(["global-search-input", classes.globalSearchInput])}
        autoComplete={"off"}
      />
      <Icon
        className={clsx(["global-search-input-icon", classes.globalSearchInputIcon])}
        Source={SearchIcon}
        width={16}
        height={16}
      />
    </View>
  );
};
