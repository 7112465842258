"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnitDto = void 0;
const address_dto_1 = require("../common/address.dto");
const class_validator_1 = require("class-validator");
const base_dto_1 = require("../base.dto");
const unitListingInfo_dto_1 = require("../units/unitListingInfo.dto");
const unitAmenity_enum_1 = require("../units/unitAmenity.enum");
const class_transformer_1 = require("class-transformer");
const UnitRentalAppListingInfo_dto_1 = require("./UnitRentalAppListingInfo.dto");
class UnitDto extends base_dto_1.BaseDto {
    constructor(init) {
        super();
        this.active = true;
        this.addressSameAsProperty = true;
        this.rentalApplicationListing = new UnitRentalAppListingInfo_dto_1.UnitRentalAppListingInfoDto({ activeListing: true });
        Object.assign(this, init);
    }
}
exports.UnitDto = UnitDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], UnitDto.prototype, "active", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((o) => !o.addressSameAsProperty),
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsDefined)(),
    __metadata("design:type", address_dto_1.AddressDto)
], UnitDto.prototype, "address", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], UnitDto.prototype, "addressSameAsProperty", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.MaxLength)(255),
    __metadata("design:type", String)
], UnitDto.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], UnitDto.prototype, "beds", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], UnitDto.prototype, "baths", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], UnitDto.prototype, "size", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], UnitDto.prototype, "marketRent", void 0);
__decorate([
    (0, class_validator_1.Length)(24, 24),
    (0, class_validator_1.IsHexadecimal)(),
    __metadata("design:type", String)
], UnitDto.prototype, "property", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(1000),
    __metadata("design:type", String)
], UnitDto.prototype, "description", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsObject)(),
    (0, class_transformer_1.Type)(() => unitListingInfo_dto_1.UnitListingInfoDto),
    __metadata("design:type", unitListingInfo_dto_1.UnitListingInfoDto)
], UnitDto.prototype, "listing", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsObject)(),
    (0, class_transformer_1.Type)(() => UnitRentalAppListingInfo_dto_1.UnitRentalAppListingInfoDto),
    __metadata("design:type", UnitRentalAppListingInfo_dto_1.UnitRentalAppListingInfoDto)
], UnitDto.prototype, "rentalApplicationListing", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsEnum)(unitAmenity_enum_1.UnitAmenity, { each: true }),
    __metadata("design:type", Array)
], UnitDto.prototype, "amenities", void 0);
