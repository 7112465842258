import React, { useCallback, useState } from "react";
import View from "DLUI/view/view";
import DrawerNew from "DLUI/drawer/drawer";
import MobileDrawer from "DLUI/drawer/mobile.drawer";
import { useKeyboard } from "../../hooks/useKeyboard";
import CreateNewMenu from "DLUI/screen/createNewMenu/createNewMenu";
import GlobalSearch from "DLUI/screen/globalSearch/globalSearch";
import { NotificationsPanel } from "DLUI/screen";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import { useResponsiveHelper } from "../../contexts/responsiveContext";
import { useAnalyticsService } from "../../hooks/useAnalyticsService";
import { getSideBarResponsiveStyle } from "./layoutHelpers";
import MobileTopNavigationBar from "DLUI/screen/mobile/mobileTopNavigationBar";
import { SidePanelLayout } from "components/layouts/sidePanelLayout";
import { notificationApi } from "api/notificationApi";
import type { LayoutProps } from "components/layouts/layouts.types";
import { sideMenuCollapsingAtom } from "components/layouts/layoutAtom";
import { useAtom } from "jotai";
import { globalSearchContstants } from "DLUI/screen/globalSearch/globalSearch.constants";
import { TopNavigationBar } from "DLUI/screen/topNavigationBar/topNavigationBar";

const SideBarLayout: React.FC<LayoutProps> = ({ children, notificationsPanelType }) => {
  const loginData = useSelector((state: IRootState) => state.auth.currentLoginResponse);
  const [showCreateNewMenu, setShowCreateNewMenu] = useState<boolean>(false);
  const [showGlobalSearch, setShowGlobalSearch] = useState<boolean>(false);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const { isTabletOrMobile, isOnlyTablet } = useResponsiveHelper();
  const [isGlobalSearchInActiveAnimation, setIsGlobalSearchInActiveAnimation] = useState(false);
  const { dispatchAnalytics } = useAnalyticsService();
  const [isSideMenuIsCollapsed] = useAtom(sideMenuCollapsingAtom);
  const { layoutStyle, sideBarStyle, topBarStyle, outerContentStyle, innerContentStyle } = getSideBarResponsiveStyle(
    isTabletOrMobile,
    isOnlyTablet,
    isSideMenuIsCollapsed
  );

  const closeCreateNew = () => {
    setShowCreateNewMenu(false);
  };

  const toggleCreateNew = () => {
    setShowCreateNewMenu(!showCreateNewMenu);
  };

  const openGlobalSearch = useCallback(() => {
    if (!showCreateNewMenu && !isGlobalSearchInActiveAnimation) {
      setShowGlobalSearch(true);
    }
  }, [showCreateNewMenu, isGlobalSearchInActiveAnimation]);

  const closeGlobalSearch = () => {
    setIsGlobalSearchInActiveAnimation(true);
    setShowGlobalSearch(false);
  };

  const onGlobalSearchCloseCompleted = () => {
    setIsGlobalSearchInActiveAnimation(true);

    setTimeout(() => {
      setShowGlobalSearch(false);
      setIsGlobalSearchInActiveAnimation(false);
    }, globalSearchContstants.animationDuration);
  };

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  useKeyboard(
    "Escape",
    () => {
      closeCreateNew();
    },
    []
  );

  useKeyboard(
    "k",
    () => {
      dispatchAnalytics("global_search_keybind_open");
      openGlobalSearch();
    },
    [openGlobalSearch],
    { isCtrlCombination: true }
  );

  return (
    <View
      className="sidebarLayout"
      height={"100%"}
      width={"100%"}
      flexDirection={"row"}
      noWrap
      overflow={"hidden"}
      {...layoutStyle}
    >
      {loginData && (
        <>
          <CreateNewMenu onBackdropClick={toggleCreateNew} shouldShow={showCreateNewMenu} />
          {isTabletOrMobile && (
            <MobileDrawer
              onGlobalNewPress={toggleCreateNew}
              onGlobalSearchPress={openGlobalSearch}
              showDrawer={showDrawer}
              onDrawerClosePress={closeDrawer}
              viewProps={sideBarStyle}
            />
          )}
        </>
      )}
      <GlobalSearch
        onCloseCompleted={onGlobalSearchCloseCompleted}
        onBackdropClick={closeGlobalSearch}
        shouldShow={showGlobalSearch}
      >
        <NotificationsPanel type={notificationsPanelType}>
          <MobileTopNavigationBar
            didPressToggleMenu={toggleDrawer}
            didPressSearchMenu={openGlobalSearch}
            hasNotificationCenter
            viewProps={topBarStyle}
          />
          <View flexDirection={"row"} height={"100%"} className={"outer-layout-screen-wrapper"} {...outerContentStyle}>
            {loginData && (
              <View
                autoWidth
                height={"100%"}
                className="sidebar"
                flexDirection={"row"}
                {...sideBarStyle}
                style={{ zIndex: 9999 }}
              >
                <DrawerNew onGlobalNewPress={toggleCreateNew} onGlobalSearchPress={openGlobalSearch} />
              </View>
            )}
            <View flex={1} className={"inner-layout-screen-wrapper"} height={"100%"} {...innerContentStyle}>
              {!isTabletOrMobile && <TopNavigationBar onGlobalSearchPress={openGlobalSearch} />}
              {children}
            </View>
          </View>
        </NotificationsPanel>
      </GlobalSearch>
      <SidePanelLayout apiMethod={notificationApi} />
    </View>
  );
};

export default SideBarLayout;
