import type { SVGIconComponent } from "../../../assets";
import type Routes from "../../appRouter/routes";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import type { RestrictedDeviceAccessTypes } from "DLUI/restrictedAccess/restrictedDeviceAccess";
import type { AnyPermission, Features, LoginResponseDto } from "@doorloop/dto";
import type { PopoverItem } from "DLUI/popover";
import type React from "react";
import type { CustomNavComponentProps } from "DLUI/drawer/navigationItem";
import type { ValueOf } from "screens/listing/components/filterSelectionView";
import type { ProjectFeatureIds } from "../../../config/featureFlags/features";

export const DrawerItemName = {
  MainMenu: "mainMenu",
  Rentals: "SubMenuRentalsLayers",
  Leasing: "SubMenuLeasingLayers",
  Tasks: "SubMenuTasksLayers",
  Accounting: "SubMenuAccountingLayers",
  People: "SubMenuPeopleLayers",
  Communications: "SubMenuCommunicationsLayers",
  Calendar: "SubMenuCalendarLayers"
};

export interface NavGroupProps {
  groupName?: string;
  showGroupName?: boolean;
  parentNavigationKey?: string;
  items: DrawerItemType[];
  clearance?: AnyPermissionClearance[];
  allClearance?: AnyPermissionClearance[];
  customClearanceHandler?: (loginResponse?: LoginResponseDto) => boolean;
  excludedDevices?: RestrictedDeviceAccessTypes[];
  featureFlag?: ProjectFeatureIds;
}

export enum IndicationTag {
  NEW = "New"
}

export enum NavLevels {
  MAIN_MENU,
  SUB_MENU
}

export interface NavConfigProps {
  title?: string;
  showLayerTitle?: boolean;
  subMenuLayerKey?: ValueOf<typeof DrawerItemName>;
  level: NavLevels;
  groups: NavGroupProps[];
}

export enum SpecialDrawerItemTypes {
  ONBOARDING = "onboarding"
}

export interface DrawerItemRenderLabelComponentProps {
  isActive: boolean;
  tag?: IndicationTag;
}

export interface NavPathItem {
  name: Routes | string;
  queryParams?: Record<string, string>;
  matchMethod?: "exact" | "startWith" | "endsWith" | "anyMatch" | "previousSelection" | "splitIndex";
}

export interface DrawerItemType {
  label?: string;
  labelComponent?: React.FC<DrawerItemRenderLabelComponentProps>;
  icon?: SVGIconComponent;
  navPath?: NavPathItem[];
  clearance?: AnyPermissionClearance[];
  allClearance?: AnyPermissionClearance[];
  customClearanceHandler?: (loginResponse?: LoginResponseDto) => boolean;
  excludedDevices?: RestrictedDeviceAccessTypes[];
  inactive?: boolean;
  specialType?: SpecialDrawerItemTypes;
  navKey?: string;
  dataCy?: string;
  tag?: IndicationTag;
  inactiveTag?: { featureName: Features; permission?: AnyPermission };
  subNavigationName?: string;
  popOverItems?: PopoverItem[];
  customComponent?: React.FC<CustomNavComponentProps>;
  showTooltipWithCustomComponent?: boolean;
  featureFlag?: ProjectFeatureIds;
}

export interface NavSelectionProps {
  subMenuSelected?: string;
  menuItemSelection?: string;
  mainMenuSelected?: string;
  level?: NavLevels;
}
