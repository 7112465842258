import React from "react";

import type { FormikProps } from "formik";
import { FastField } from "formik";
import { AddressAutoCompleteForm, TextField } from "DLUI/form";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";

import { ImageUpload } from "DLUI/imageUpload";
import Grid from "@material-ui/core/Grid";
import { View } from "DLUI/view";
import { SeparationLine } from "DLUI/separatorView";
import FieldWrapper from "DLUI/form/textField/fieldWrapper";

interface ComponentProps {
  onFileReceive?: (receivedFile: File) => void;
  onFileDelete?: () => void;
  formikRef: FormikProps<any>;
  hideNotesSection?: boolean;
}

const CompanyInfo: React.FC<ComponentProps> = ({ onFileReceive, onFileDelete, formikRef }: ComponentProps) => {
  const { t } = useTranslation();

  const _onFileReceive = (receivedFile: File) => {
    formikRef.setFieldValue("logoUrl", URL.createObjectURL(receivedFile));

    if (onFileReceive) {
      onFileReceive(receivedFile);
    }
  };

  const _onFileDelete = () => {
    formikRef.setFieldValue("logoUrl", undefined);

    if (onFileDelete) {
      onFileDelete();
    }
  };

  return (
    <View width={"100%"} flex={1}>
      <View>
        <View flexDirection={"row"}>
          <ImageUpload
            didReceivedFile={_onFileReceive}
            didDeleteFile={_onFileDelete}
            selectedImageUrl={formikRef.values ? formikRef.values.logoUrl : undefined}
            companyImage
          />
        </View>
        <View flexDirection={"row"}>
          <Grid item container xs={12} md={12} lg={12}>
            <FastField
              component={TextField}
              label={t(AppStrings.Auth.CompanyName)}
              name={`companyName`}
              marginTop={20}
            />
          </Grid>
        </View>
        <View flexDirection={"row"}>
          <Grid item xs={12} md={6} lg={6}>
            <FieldWrapper type={"endElement"}>
              <FastField
                component={TextField}
                label={t(AppStrings.Settings.GeneralSettings.CompanyInfo.PrimaryEmailAddress)}
                name={`billingEmail`}
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <FieldWrapper type={"startElement"}>
              <FastField
                component={TextField}
                label={t(AppStrings.Settings.GeneralSettings.CompanyInfo.PrimaryPhoneNumber)}
                name={`companyPhone`}
              />
            </FieldWrapper>
          </Grid>
        </View>
        <SeparationLine marginTop={20} width={"100%"} height={1} />

        <AddressAutoCompleteForm
          formikName={"billingAddress"}
          withAddressVerification
          mustAcceptInvalidAddress
          checkAddressVerificationOnFirstRun
        />

        <SeparationLine marginTop={20} width={"100%"} height={1} />
        <View>
          <Grid item container xs={12} md={6} lg={6}>
            <FieldWrapper type={"singleField"}>
              <FastField
                component={TextField}
                label={t(AppStrings.Settings.GeneralSettings.CompanyInfo.CompanyWebsite)}
                name={`companyWebsite`}
              />
            </FieldWrapper>
          </Grid>
        </View>
      </View>
    </View>
  );
};

export default CompanyInfo;
