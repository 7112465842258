import { ObjectPermission } from "@doorloop/dto";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import BulkTransaction from "DLUI/dialogs/transactions/bulkTransaction";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";
import { ArticleIdsEnum, HelpTypeEnum, VideoUrlsEnum } from "DLUI/screen/helpPanel/types";
import AppStrings from "locale/keys";
import React from "react";

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitleActions?: React.ReactNode;
}

const helpObject: HelpPanelProps = {
  description: AppStrings.Leases.LeaseCharge.OneTimeChargeHelpPanel.Description,
  actionItems: [
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Leases.LeaseCharge.OneTimeChargeHelpPanel.Create,
      articleId: ArticleIdsEnum.POSTING_A_CHARGE
    },
    {
      type: HelpTypeEnum.INTERCOM_ARTICLE,
      topic: AppStrings.Leases.LeaseCharge.OneTimeChargeHelpPanel.EditDelete,
      articleId: ArticleIdsEnum.EDIT_DELETE_LEASE_TRANSACTIONS
    },
    {
      type: HelpTypeEnum.WATCH_VIDEO,
      topic: AppStrings.Common.WatchExample,
      href: VideoUrlsEnum.LEASE_OVERVIEW,
      dialogTitle: AppStrings.Leases.LeaseCharge.OneTimeChargeHelpPanel.DialogTitle,
      startTime: 95
    }
  ]
};

const BulkChargeDialog: React.FC<ComponentProps> = ({
  onBackdropClick,
  onClose,
  dialogTitleActions
}: ComponentProps) => (
  <BulkTransaction
    onBackdropClick={onBackdropClick}
    onClose={onClose}
    dialogTitle={AppStrings.Leases.LeaseCharge.BulkPostCharges}
    dialogTitleActions={dialogTitleActions}
    type={"charge"}
    dialogFrameWidth={getDialogFrameDimension("width", 1540)}
    dialogFrameHeight={getDialogFrameDimension("height", 1040)}
    permission={ObjectPermission.leaseCharges}
    helpObject={helpObject}
  />
);

export default BulkChargeDialog;
