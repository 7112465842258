import {
  AccountType,
  BankAccountMerchantStatus,
  createValidator,
  DataCy,
  PropertyDefaultAccountsSettingsDto,
  PropertyDto
} from "@doorloop/dto";
import type { ApiResult } from "api/apiResult";
import { propertiesApi } from "api/propertiesApi";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { RadioGroup } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import { FastField, FormikContext, getIn, useFormik } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";
import BankAccountFormikAutoCompleteField from "DLUI/form/autoComplete/bankAccountFormikAutoComplete/bankAccountFormikAutoCompleteField";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { useEffectAsync } from "../../../../../hooks/useEffectAsync";
import { entityApiStore } from "api/entityApiStore/entityApiStore";
import WarningView from "DLUI/form/warningView/warningView";
import { useTypedTranslation } from "locale";
import { Link } from "DLUI/link";
import ColorsEnum from "utils/colorsEnum";
import { NavigationManager } from "utils/navigation";

interface ComponentProps {
  onBackdropClick: () => void;
  refreshEvent?: () => void;
  dialogTitle: string;
  shouldLoadData?: boolean;
  onSaveButtonPress?: (values: PropertyDto) => Promise<unknown>;
  didFinishOperation?: (values: PropertyDto) => void;
  initialData?: PropertyDto;
  dataCy?: string;
}

const validateForm = createValidator(PropertyDto);

const EditBankAccounts: React.FC<ComponentProps> = ({
  didFinishOperation,
  refreshEvent,
  onBackdropClick,
  dialogTitle,
  shouldLoadData = true,
  onSaveButtonPress,
  initialData,
  dataCy
}: ComponentProps) => {
  const { t } = useTranslation();
  const { t: tt } = useTypedTranslation();
  const { dialogHorizontalPadding } = DialogsHelper();
  const { propertyId } = useParams<any>();
  const [propertyData, setPropertyData] = useState<PropertyDto | undefined>(initialData);
  const [showOperatingAccountChangedNotificationPanel, setShowOperatingAccountChangedNotificationPanel] =
    useState<boolean>(false);

  const initFormValues = useMemo(() => {
    if (propertyData) {
      return propertyData;
    }
    return new PropertyDto();
  }, [propertyData]);

  const formik = useFormik<PropertyDto>({
    initialValues: initFormValues,
    onSubmit: _.noop,
    validate: validateForm,
    enableReinitialize: true
  });
  const operatingBankAccount = formik.values?.settings?.defaultAccounts?.bank_operating;
  const { data: merchantAccountsData, isFetching: isFetchingMerchantAccounts } =
    entityApiStore.merchantAccounts.queries.useGetAll(
      {
        filter_account: operatingBankAccount
      },
      { refetchOnWindowFocus: false }
    );

  const isMerchantAccountAcceptOnlinePayments =
    _.first(merchantAccountsData?.data?.data)?.status === BankAccountMerchantStatus.ACCEPTING_ONLINE_PAYMENTS;

  const [viewIndex, setViewIndex] = useState(0);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);

  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>(AppStrings.Common.NetworkErrorSubTitle);

  const [hideLoadingDialogActionButtons, setHideLoadingDialogActionButtons] = useState<boolean>(false);

  const [operatingAccountDefaultValue, setOperatingAccountDefaultValue] = useState<string | undefined>(
    () => propertyData?.settings?.defaultAccounts?.bank_operating
  );

  const [trustAccountDefaultValue, setTrustAccountDefaultValue] = useState<string | undefined>(
    () => propertyData?.settings?.defaultAccounts?.bank_trust
  );

  const [bankAccountSelectionType, setBankAccountSelectionType] = useState<"companyDefault" | "specifyAccount">(
    _.isEmpty(propertyData?.settings?.defaultAccounts) ? "companyDefault" : "specifyAccount"
  );

  useEffectAsync(async () => {
    if (!shouldLoadData) {
      setLoadingDialogState(DialogState.Success);
      return;
    }
    loadPropertyData();
  }, [shouldLoadData]);

  const loadPropertyData = async () => {
    if (propertyId) {
      const response = await propertiesApi.get(propertyId).catch((e: string) => {
        showDialogError(e);
      });
      if (response && response.status) {
        if (response.data && response.data.settings && !_.isEmpty(response.data.settings.defaultAccounts)) {
          const defaultAccounts = response.data.settings.defaultAccounts;
          setBankAccountSelectionType("specifyAccount");
          if (defaultAccounts.bank_operating || defaultAccounts.bank_trust) {
            if (defaultAccounts.bank_operating) {
              setOperatingAccountDefaultValue(defaultAccounts.bank_operating);
            }
            if (defaultAccounts.bank_trust) {
              setTrustAccountDefaultValue(defaultAccounts.bank_trust);
            }
          }
        } else {
          setBankAccountSelectionType("companyDefault");
        }
        setPropertyData(response.data);
        setLoadingDialogState(DialogState.Success);
      } else {
        showDialogError();
      }
    } else {
      showDialogError();
    }
  };

  const showDialogError = (errorMessage?: string) => {
    setHideLoadingDialogActionButtons(true);
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(errorMessage || AppStrings.Common.GeneralError);
  };

  const isValidForm = async () => {
    const errors = await formik.validateForm();
    const defaultAccountsErrors = getIn(errors, "settings.defaultAccounts");
    return _.isEmpty(defaultAccountsErrors);
  };

  const updateProperty = async (values: PropertyDto) => {
    const response = (await propertiesApi.update(values.id!, values).catch((error) => {
      setLoadingDialogErrorText(error);
      setLoadingDialogState(DialogState.Error);
    })) as ApiResult<any>;

    if (response.status !== undefined && !response.status) {
      setLoadingDialogErrorText(response.message);
      setLoadingDialogState(DialogState.Error);
    } else {
      setLoadingDialogState(DialogState.Success);
      didFinishUpdateProperty();
    }
  };

  const didPressSaveButton = async () => {
    if (shouldLoadData && !propertyData) {
      return;
    }
    const isValid = await isValidForm();
    if (!isValid) {
      return;
    }
    const newValues = formik.values;
    if (typeof onSaveButtonPress === "function") {
      return await onSaveButtonPress(newValues);
    }
    setLoadingDialogState(DialogState.Show);
    setViewIndex(1);
    await updateProperty(newValues);
  };

  const renderActionPanelButtons = () => {
    if (viewIndex === 1) {
      return <div />;
    }

    return (
      <FormActionButtons
        propsSubButton={{ onClick: onBackdropClick }}
        propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
      />
    );
  };

  const didPressDismissButton = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(0);
  };

  const onRetryButtonPress = () => {
    setLoadingDialogState(DialogState.Hidden);
    setViewIndex(0);
  };

  const didFinishUpdateProperty = () => {
    didFinishOperation?.(formik.values);
    refreshEvent?.();
    setLoadingDialogState(DialogState.Hidden);
  };

  const didChangeBankAccountSelection = (selectedValue: string) => {
    const nextSelection = selectedValue === "false" ? "companyDefault" : "specifyAccount";
    if (nextSelection === "companyDefault") {
      formik.setFieldValue("settings.defaultAccounts", undefined);
    } else {
      formik.setFieldValue("settings.defaultAccounts", new PropertyDefaultAccountsSettingsDto());
    }
    setBankAccountSelectionType(nextSelection);
  };

  const handleClickSetupAccount = () => {
    if (operatingBankAccount) {
      NavigationManager.navigateToSetupMerchantAccount(operatingBankAccount);
    }
  };

  const renderForm = () => (
    <FormikContext.Provider value={formik}>
      <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding}>
        <View alignItems={"flex-start"}>
          <Text
            value={AppStrings.Properties.Settings.PropertyBankAccountsDescription}
            fontSize={16}
            color={"black"}
            maxWidth={550}
            lineHeight={"26px"}
            marginTop={20}
          />
          <FastField
            uniqueKey={"LF"}
            component={RadioGroup}
            name={"automaticLateFees"}
            defaultValue={bankAccountSelectionType === "companyDefault" ? "false" : "true"}
            didChangeSelection={didChangeBankAccountSelection}
            radioGroupItems={[
              {
                label: AppStrings.Properties.Settings.UseCompanyDefaultBankAccounts,
                value: "false",
                dataCy: DataCy.propertyDetails.settings.editPropertyBankAccounts.useDefaultRadioButton
              },
              {
                label: AppStrings.Properties.Settings.SpecifyBankAccountForProperty,
                value: "true",
                dataCy: DataCy.propertyDetails.settings.editPropertyBankAccounts.specifyAccountRadioButton
              }
            ]}
            marginTop={10}
          />
          <View
            shouldShow={bankAccountSelectionType === "specifyAccount"}
            showAnimation={"fade-in"}
            hideAnimation={"fade-out"}
          >
            <View>
              <SeparationLine marginTop={20} width={"100%"} height={1} />

              <Text
                value={AppStrings.Properties.Settings.WhichOperatingAccount}
                fontSize={16}
                color={"black"}
                marginTop={20}
              />
              <BankAccountFormikAutoCompleteField
                addCreateOption
                uniqueIndex={"OA"}
                name={"settings.defaultAccounts.bank_operating"}
                onChange={() => {
                  setShowOperatingAccountChangedNotificationPanel(true);
                }}
                queryParams={{
                  filter_type: AccountType.ASSET_BANK,
                  filter_active: true
                }}
                label={t(AppStrings.Settings.GeneralSettings.DefaultAccounts.SelectAccount)}
                defaultValue={operatingAccountDefaultValue}
                marginTop={20}
                size={"300px"}
              />
              {propertyId && !isFetchingMerchantAccounts && !isMerchantAccountAcceptOnlinePayments && (
                <WarningView
                  marginTop={20}
                  iconSize={24}
                  type={"danger"}
                  subTitle={tt("common.youNeedToCompleteTheSet")}
                >
                  <Link
                    textColor={ColorsEnum.BrightBlue}
                    underline={"always"}
                    onClick={handleClickSetupAccount}
                    fontSize={14}
                  >
                    {tt("common.setUpAccount")}
                  </Link>
                </WarningView>
              )}
              <Text
                value={AppStrings.Properties.Settings.WhichEscrowAccount}
                fontSize={16}
                color={"black"}
                marginTop={20}
              />
              <Text
                value={AppStrings.Properties.Settings.WhichEscrowDescription}
                fontSize={12}
                color={"black"}
                marginTop={10}
              />
              <BankAccountFormikAutoCompleteField
                addCreateOption
                uniqueIndex={"OA"}
                name={"settings.defaultAccounts.bank_trust"}
                queryParams={{
                  filter_type: AccountType.ASSET_BANK,
                  filter_active: true
                }}
                label={t(AppStrings.Settings.GeneralSettings.DefaultAccounts.SelectAccount)}
                defaultValue={trustAccountDefaultValue}
                marginTop={20}
                size={"300px"}
              />
            </View>
          </View>
        </View>
      </View>
    </FormikContext.Provider>
  );

  const renderView = ({ index }: any) => {
    if (index === 1 || (shouldLoadData && !propertyData)) {
      return (
        <View flex={1} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={t(AppStrings.Common.Loading)}
            errorText={loadingDialogErrorText}
            successText={t(AppStrings.Common.OperationCompleted)}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={didPressDismissButton}
            hideButtons={hideLoadingDialogActionButtons}
          />
        </View>
      );
    }

    return renderForm();
  };

  const _onBackdropClick = () => {
    if (viewIndex === 1) {
      setViewIndex(0);
      return;
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const frameType = useMemo(() => {
    if (shouldLoadData && propertyData === undefined) {
      return "contentOnly";
    }

    if (viewIndex === 0) {
      return "sectionTitleFrame";
    }
    if (viewIndex === 1) {
      return "contentOnly";
    }

    return "topPanel";
  }, [viewIndex, propertyData]);

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={dialogTitle}
      width={800}
      height={800}
      renderView={renderView}
      numViews={2}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={frameType}
    />
  );
};

export default EditBankAccounts;
