import Routes from "./routes";
import { OutgoingPaymentsScreen } from "screens/outgoingPayments/outgoingPaymentsScreen";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";

export const OutgoingPaymentsRouter = () => (
  <PageRouter>
    <LazyRoute
      path={[
        Routes.OUTGOING_PAYMENTS_EDIT_OWNER,
        Routes.OUTGOING_PAYMENTS_EDIT_TENANT,
        Routes.OUTGOING_PAYMENTS_EDIT_VENDOR,
        Routes.OUTGOING_PAYMENTS_EDIT_EXPENSE,
        Routes.OUTGOING_PAYMENTS_EDIT_REFUND,
        Routes.OUTGOING_PAYMENTS_EDIT_BILL_PAYMENT
      ]}
      render={OutgoingPaymentsScreen}
    />
    <LazyRoute path={Routes.OUTGOING_PAYMENTS} render={OutgoingPaymentsScreen} />

    <LazyRoute exact path={Routes.INCOMING_PAYMENTS_OUTGOING_PAYMENTS_SETTINGS} render={OutgoingPaymentsScreen} />
  </PageRouter>
);
