"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConversationMessageStatus = void 0;
var ConversationMessageStatus;
(function (ConversationMessageStatus) {
    ConversationMessageStatus["DRAFT"] = "DRAFT";
    ConversationMessageStatus["SENT"] = "SENT";
    ConversationMessageStatus["FAILED"] = "FAILED";
    ConversationMessageStatus["BOUNCED"] = "BOUNCED";
    ConversationMessageStatus["DELIVERED"] = "DELIVERED";
    ConversationMessageStatus["OPENED"] = "OPENED";
})(ConversationMessageStatus || (exports.ConversationMessageStatus = ConversationMessageStatus = {}));
