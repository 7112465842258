import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { MOBILE_TOP_NAVIGATION_BAR_HEIGHT } from "DLUI/screen/mobile/mobileTopNavigationBar";
import { DESKTOP_TOP_BAR_HEIGHT } from "DLUI/screen/topNavigationBar/topNavigationBar";

export default makeStyles((theme: Theme) =>
  createStyles({
    screenContainer: {
      flex: 1,
      display: "flex",
      backgroundColor: theme.palette.screenBackground?.main
    },
    contentContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "column"
    },
    screenContent: {
      display: "flex",
      flexDirection: "row",
      backgroundColor: theme.palette.screenBackground?.main,
      width: "100%"
    },
    pageContainer: {
      width: "100%",
      flex: 1,
      flexDirection: "column",
      display: "flex"
    },
    detailsScreenContainer: {
      width: "100%",
      height: "100%"
    },
    screenContentContainer: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
      width: "100%",
      height: `calc(100% - ${DESKTOP_TOP_BAR_HEIGHT}px)`
    },
    overflowScroll: {
      overflow: "auto"
    },
    loadingContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    screenLayer: {
      position: "absolute",
      left: 0,
      display: "flex",
      flexDirection: "column",
      width: "100%"
    },
    backgroundLayer: {
      zIndex: 10,
      top: 0
    },
    contentLayer: {
      width: "100%",
      height: "100%",
      zIndex: 1400,
      top: 0,
      position: "relative",
      display: "flex",
      flexDirection: "column"
    },
    contentLayerMobile: {
      maxHeight: `calc(100vh - ${MOBILE_TOP_NAVIGATION_BAR_HEIGHT}px)`
    },
    frontLayer: {
      zIndex: 1500,
      bottom: 0
    },
    headerContainer: {
      position: "absolute",
      width: "100%"
    },
    screenHeaderContainer: {
      position: "relative"
    },
    screenHeaderContainerShadow: {
      boxShadow: "0px 5px 10px rgba(40, 44, 97, 0.1)"
    },
    searchPanelIcon: {
      position: "absolute",
      bottom: 16,
      right: 20,
      zIndex: -10000
    },
    loadingIndicatorContainer: {
      justifyContent: "center",
      alignItems: "center",
      margin: "15px 15px 15px 0px",
      borderRadius: 30
    }
  })
);
