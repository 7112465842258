export enum DialogRoutes {
  PAYMENT = "new-payment",
  AUTO_PAYMENT_EXISTS = "auto-payment-exists",
  EDIT_PAYMENT = "payment/edit",

  RECURRING_PAYMENT = "recurring-payment/new",
  EDIT_RECURRING_PAYMENT = "recurring-payment/edit",

  REQUEST = "new-request",
  EDIT_REQUEST = "requests/edit",
  VIEW_REQUEST = "requests/view",

  CONFIRM = "/confirm",
  ALERT = "/alert",

  EDIT_LOGIN_AND_PASSWORD = "/edit/login-and-password",

  INSURANCE_POLICY = "new-insurance-policy",
  EDIT_INSURANCE_POLICY = "edit/insurance-policy",
  VIEW_ANNOUNCEMENT = "announcement-details",
  MY_LEASE = "my-lease",

  MERCHANT_ACCOUNT_NOT_FOUND = "merchant-account-not-found",
  FINANCIALS = "financials",

  CASH_PAYMENTS_VIEW_PAYMENT_DETAILS = "cash-payments/view-payment-details",

  CONTACT_INFO = "/contact-info"
}
