import { useState } from "react";
import { useTranslation } from "react-i18next";
import type { SVGIconComponent } from "../assets";
import {
  AnnouncementIcon,
  CircleUserProfileIcon,
  DollarIcon,
  ElectronicSignatureIcon,
  HomeIcon,
  LeasingIcon,
  RentalApplicationIcon,
  RentalsIconNew,
  TaskPadIcon,
  UnitDoorIcon
} from "../assets";
import type { AddressDto, LinkedResourceDto } from "@doorloop/dto";
import { LinkedResourceType, RecurringTransactionType, TenantType } from "@doorloop/dto";
import { propertiesApi } from "api/propertiesApi";
import { Routes } from "../components/appRouter";
import { unitsApi } from "api/unitsApi";
import { leasesApi, leasesRecurringTransactionsApi } from "api/leasesApi";
import AppStrings from "../locale/keys";
import { tasksApi } from "api/tasksApi";
import { vendorBillsApi, vendorCreditsApi, vendorsApi } from "api/vendorsApi";
import { tenantsApi } from "api/tenantsApi";
import { useEffectAsync } from "./useEffectAsync";
import { leaseDraftApi } from "api/leasesDraftApi";
import { ownersApi } from "api/ownersApi";
import { announcementsApi } from "api/announcementsApi";
import { leaseChargeApi } from "api/leaseChargeApi";
import { leasePaymentsApi } from "api/leasePaymentsApi";
import { leaseReversedPaymentsApi } from "api/leaseReversedPaymentsApi";
import { leaseRefundApi } from "api/leaseRefundApi";
import { leaseCreditApi } from "api/leaseCreditApi";
import { expensesApi } from "api/expensesApi";
import { depositsApi } from "api/depositsApi";
import { transferApi } from "api/transferApi";
import { ownerContributionsApi } from "api/ownerContributionsApi";
import { signatureRequestsApi } from "api/signatureRequestsApi";
import { signatureTemplatesApi } from "api/signatureTemplatesApi";
import { vendorBillPaymentsApi } from "api/outstandingTransactionsApi";
import { convertLinkedResourceTypeToTypeName } from "../utils/linkedResourceHelper";

const recurringTransactionTypeMap = {
  [RecurringTransactionType.LEASE_RENT]: "rent",
  [RecurringTransactionType.LEASE_CHARGE]: "charge",
  [RecurringTransactionType.LEASE_CREDIT]: "credit",
  [RecurringTransactionType.LEASE_PAYMENT]: "payment"
};

export const useLinkedResource = (linkedResource?: LinkedResourceDto, hrefSuffix = "/overview") => {
  const { t } = useTranslation();
  const [linkedResourceIcon, setLinkedResourceIcon] = useState<SVGIconComponent | undefined>();
  const [linkedResourceText, setLinkedResourceText] = useState<string | undefined>();
  const [linkedResourceName, setLinkedResourceName] = useState<string | undefined>();
  const [linkedResourceHref, setLinkedResourceHref] = useState<string | undefined>();
  const [relatedToAddress, setRelatedToAddress] = useState<AddressDto | undefined>();

  const setIconTextFromLinkedResource = async (linkedResource: LinkedResourceDto) => {
    let elementIcon = HomeIcon;
    let elementText;
    let href: string | undefined = undefined;
    let resourceName: string | undefined = undefined;
    switch (linkedResource.resourceType) {
      case LinkedResourceType.Property:
        const propertyDictionary = propertiesApi.getItemFromDictionary(linkedResource.resourceId);
        elementIcon = RentalsIconNew;

        if (propertyDictionary?.name) {
          setRelatedToAddress(propertyDictionary.address);
          elementText = propertyDictionary.name;
          href = Routes.PROPERTIES + "/" + linkedResource.resourceId + hrefSuffix;
        }

        break;
      case LinkedResourceType.Unit:
        elementIcon = UnitDoorIcon;
        const unitsDictionary = unitsApi.getItemFromDictionary(linkedResource.resourceId);
        if (unitsDictionary?.property) {
          href = Routes.UNITS + "/" + linkedResource.resourceId + hrefSuffix;
          const propertyDictionary = propertiesApi.getItemFromDictionary(unitsDictionary.property);
          if (propertyDictionary) {
            elementText = propertyDictionary.name;
            if (unitsDictionary.name) {
              elementText += " > " + unitsDictionary.name;
            }
            setRelatedToAddress(propertyDictionary.address);
          } else if (unitsDictionary.name) {
            elementText = unitsDictionary.name;
          }
        }
        break;
      case LinkedResourceType.Lease:
        const leaseDictionary = leasesApi.getItemFromDictionary(linkedResource.resourceId);
        elementIcon = LeasingIcon;
        href = Routes.ACTIVE_LEASES + "/" + linkedResource.resourceId + hrefSuffix;
        elementText = leaseDictionary && leaseDictionary.name ? leaseDictionary.name : "";
        if (leaseDictionary?.property) {
          const propertyDictionary = propertiesApi.getItemFromDictionary(leaseDictionary.property);
          if (propertyDictionary) {
            setRelatedToAddress(propertyDictionary.address);
          }
        }
        break;
      case LinkedResourceType.RentalApplication:
        elementIcon = RentalApplicationIcon;
        href = `${Routes.RENTAL_APPLICATIONS}/${linkedResource.resourceId}${hrefSuffix}`;
        elementText = t(AppStrings.Common.RentalApplication);
        break;
      case LinkedResourceType.Task:
        const { data, status } = await tasksApi.get(linkedResource.resourceId);
        elementIcon = TaskPadIcon;
        href = Routes.TASKS + "/" + linkedResource.resourceId + hrefSuffix;
        elementText = status && data && data.subject ? data.subject : "";
        break;
      case LinkedResourceType.Vendor:
        const vendorDictionary = vendorsApi.getItemFromDictionary(linkedResource.resourceId);
        elementIcon = CircleUserProfileIcon;

        if (vendorDictionary?.name) {
          href = Routes.VENDORS + "/" + linkedResource.resourceId + hrefSuffix;
          elementText = vendorDictionary.name;
        }
        break;
      case LinkedResourceType.Owner:
        const ownerDictionary = ownersApi.getItemFromDictionary(linkedResource.resourceId);
        elementIcon = CircleUserProfileIcon;

        if (ownerDictionary?.name) {
          href = Routes.OWNERS + "/" + linkedResource.resourceId + hrefSuffix;
          elementText = ownerDictionary.name;
        }
        break;
      case LinkedResourceType.Tenant:
        const tenantDictionary = tenantsApi.getItemFromDictionary(linkedResource.resourceId);
        elementIcon = CircleUserProfileIcon;

        if (tenantDictionary?.name) {
          const tenantRouteByType =
            tenantDictionary.type === TenantType.PROSPECT_TENANT ? Routes.PROSPECTS : Routes.TENANTS;
          href = `${tenantRouteByType}/${linkedResource.resourceId}${hrefSuffix}`;
          elementText = tenantDictionary.name;
          resourceName = convertLinkedResourceTypeToTypeName(
            tenantDictionary.type === TenantType.PROSPECT_TENANT
              ? LinkedResourceType.Prospect
              : LinkedResourceType.Tenant
          );
        }
        break;
      case LinkedResourceType.LeaseDraft:
        const leaseDraftResult = await leaseDraftApi.get(linkedResource.resourceId);
        elementIcon = LeasingIcon;

        if (leaseDraftResult.data?.name) {
          elementText = leaseDraftResult.data.name;
          href = `${Routes.LEASES}/draft-leases/${leaseDraftResult.data.id}${hrefSuffix}`;
        }
        break;
      case LinkedResourceType.Announcement:
        const AnnouncementResult = await announcementsApi.get(linkedResource.resourceId);
        elementIcon = AnnouncementIcon;

        if (AnnouncementResult.data?.subject) {
          elementText = AnnouncementResult.data.subject;
          href = `${Routes.ANNOUNCEMENTS}/${AnnouncementResult.data.id}${hrefSuffix}`;
        }
        break;
      case LinkedResourceType.LeaseCharge:
        const LeaseChargeResult = await leaseChargeApi.get(linkedResource.resourceId);
        elementIcon = DollarIcon;

        if (LeaseChargeResult.data?.reference) {
          elementText = LeaseChargeResult.data.reference;
          href = `${Routes.REPORTS}/transactions-list-report/charge/edit/${LeaseChargeResult.data.id}`;
        }
        break;
      case LinkedResourceType.LeasePayment:
        const LeasePaymentsResult = await leasePaymentsApi.get(linkedResource.resourceId);
        elementIcon = DollarIcon;

        if (LeasePaymentsResult.data?.reference) {
          elementText = LeasePaymentsResult.data.reference;
          href = `${Routes.REPORTS}/transactions-list-report/payment/edit/${LeasePaymentsResult.data.id}`;
        }
        break;
      case LinkedResourceType.LeaseReversedPayment:
        const LeaseReversedPaymentResult = await leaseReversedPaymentsApi.get(linkedResource.resourceId);
        elementIcon = DollarIcon;

        if (LeaseReversedPaymentResult.data?.reference) {
          elementText = LeaseReversedPaymentResult.data.reference;
          href = `${Routes.REPORTS}/transactions-list-report/payment/edit/${LeaseReversedPaymentResult.data.id}`;
        }
        break;
      case LinkedResourceType.LeaseRefund:
        const LeaseRefundResult = await leaseRefundApi.get(linkedResource.resourceId);
        elementIcon = DollarIcon;

        if (LeaseRefundResult.data?.reference) {
          elementText = LeaseRefundResult.data.reference;
          href = `${Routes.REPORTS}/transactions-list-report/refund/edit/${LeaseRefundResult.data.id}`;
        }
        break;
      case LinkedResourceType.LeaseCredit:
        const LeaseCreditResult = await leaseCreditApi.get(linkedResource.resourceId);
        elementIcon = DollarIcon;

        if (LeaseCreditResult.data?.reference) {
          elementText = LeaseCreditResult.data.reference;
          href = `${Routes.REPORTS}/transactions-list-report/credit/edit/${LeaseCreditResult.data.id}`;
        }
        break;
      case LinkedResourceType.Expense:
        const ExpenseResult = await expensesApi.get(linkedResource.resourceId);
        elementIcon = DollarIcon;

        if (ExpenseResult.data?.reference) {
          elementText = ExpenseResult.data.reference;
          href = `${Routes.REPORTS}/transactions-list-report/expense/edit/${ExpenseResult.data.id}`;
        }
        break;
      case LinkedResourceType.VendorBill:
        const VendorsBillsResult = await vendorBillsApi.get(linkedResource.resourceId);
        elementIcon = DollarIcon;

        if (VendorsBillsResult.data?.reference) {
          elementText = `#${VendorsBillsResult.data.reference}`;
          href = `${Routes.REPORTS}/transactions-list-report/bill/edit/${VendorsBillsResult.data.id}`;
        }
        break;
      case LinkedResourceType.VendorBillPayment:
        const VendorBillPaymentResult = await vendorBillPaymentsApi.get(linkedResource.resourceId);

        if (VendorBillPaymentResult.data?.reference) {
          elementIcon = DollarIcon;
          elementText = VendorBillPaymentResult.data.reference;
          href = `${Routes.REPORTS}/transactions-list-report/bill-payment/edit/${VendorBillPaymentResult.data.id}`;
        }
        break;
      case LinkedResourceType.Deposit:
        const DepositResult = await depositsApi.get(linkedResource.resourceId);
        elementIcon = DollarIcon;

        if (DepositResult.data?.reference) {
          elementText = DepositResult.data.reference;
          href = `${Routes.REPORTS}/transactions-list-report/bank-deposit/edit/${DepositResult.data.id}`;
        }
        break;
      case LinkedResourceType.Transfer:
        const TransferResult = await transferApi.get(linkedResource.resourceId);
        elementIcon = DollarIcon;

        if (TransferResult.data?.reference) {
          elementText = TransferResult.data.reference;
          href = `${Routes.REPORTS}/transactions-list-report/bank-transfer/edit/${TransferResult.data.id}`;
        }
        break;
      case LinkedResourceType.OwnerContribution:
        const OwnerContributionResult = await ownerContributionsApi.get(linkedResource.resourceId);
        elementIcon = DollarIcon;

        if (OwnerContributionResult.data?.reference) {
          elementText = OwnerContributionResult.data.reference;
          href = `${Routes.REPORTS}/transactions-list-report/owner-contribution/edit/${OwnerContributionResult.data.id}`;
        }
        break;
      case LinkedResourceType.VendorCredit:
        const VendorCreditResult = await vendorCreditsApi.get(linkedResource.resourceId);
        elementIcon = DollarIcon;

        if (VendorCreditResult.data?.reference) {
          elementText = VendorCreditResult.data.reference;
          href = `${Routes.REPORTS}/transactions-list-report/vendor-credit/edit/${VendorCreditResult.data.id}`;
        }
        break;
      case LinkedResourceType.RecurringTransaction:
        const RecurringTransactionResult = await leasesRecurringTransactionsApi.get(linkedResource.resourceId);
        elementIcon = DollarIcon;

        if (RecurringTransactionResult.data?.reference && RecurringTransactionResult.data?.type) {
          const recurringType = recurringTransactionTypeMap[RecurringTransactionResult.data.type];
          elementText = RecurringTransactionResult.data.reference;

          if (recurringType) {
            href = `${Routes.ACTIVE_LEASES}/${RecurringTransactionResult.data.lease}/rent/recurring-${recurringType}/edit/${RecurringTransactionResult.data.id}`;
          }
        }
        break;
      case LinkedResourceType.RecurringTransactionExpense:
        const RecurringTransactionExpenseResult = await leasesRecurringTransactionsApi.get(linkedResource.resourceId);
        elementIcon = DollarIcon;

        if (RecurringTransactionExpenseResult.data?.reference) {
          elementText = RecurringTransactionExpenseResult.data.reference;
          href = `${Routes.RECURRING_BILLS_AND_EXPENSES}/edit-recurring-expense/${RecurringTransactionExpenseResult.data.id}`;
        }
        break;
      case LinkedResourceType.RecurringTransactionVendorBill:
        const RecurringTransactionVendorBillResult = await leasesRecurringTransactionsApi.get(
          linkedResource.resourceId
        );
        elementIcon = DollarIcon;

        if (RecurringTransactionVendorBillResult.data?.reference) {
          elementText = RecurringTransactionVendorBillResult.data.reference;
          href = `${Routes.RECURRING_BILLS_AND_EXPENSES}/edit-recurring-bill/${RecurringTransactionVendorBillResult.data.id}`;
        }
        break;
      case LinkedResourceType.ESignatureRequest:
        const SignatureRequestsResult = await signatureRequestsApi.get(linkedResource.resourceId);
        elementIcon = ElectronicSignatureIcon;

        if (SignatureRequestsResult.data?.title) {
          elementText = SignatureRequestsResult.data.title;
          href = `${Routes.SIGNATURE_REQUESTS}/view/${SignatureRequestsResult.data.id}`;
        }
        break;
      case LinkedResourceType.ESignatureTemplate:
        const SignatureTemplateResult = await signatureTemplatesApi.get(linkedResource.resourceId);
        elementIcon = ElectronicSignatureIcon;

        if (SignatureTemplateResult.data?.title) {
          elementText = SignatureTemplateResult.data.title;
          href = `${Routes.SIGNATURE_TEMPLATES}/edit/${SignatureTemplateResult.data.id}`;
        }
        break;
    }

    if (!resourceName) {
      resourceName = convertLinkedResourceTypeToTypeName(linkedResource.resourceType);
    }

    setLinkedResourceIcon(elementIcon);
    setLinkedResourceText(elementText);
    setLinkedResourceHref(href);
    setLinkedResourceName(resourceName);
  };

  useEffectAsync(async () => {
    if (linkedResource?.resourceId && linkedResource?.resourceType) {
      await setIconTextFromLinkedResource(linkedResource);
    }
  }, [linkedResource?.resourceId, linkedResource?.resourceType]);

  return {
    linkedResourceIcon,
    linkedResourceText,
    linkedResourceName,
    linkedResourceHref,
    relatedToAddress
  };
};
