import React, { useEffect, useMemo } from "react";
import { View } from "DLUI/view";
import TenantPortalBackgroundView from "screens/tenantPortal/components/tenantPortalBackgroundView";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { useResponsiveHelper } from "contexts/responsiveContext";
import CreditCardFormStripe from "screens/tenantPortal/rentalApplication/components/payment/creditCardFormStripe";
import { Icon } from "DLUI/icon";
import { GreenCheckIcon } from "@/assets";
import moment from "moment";
import type { EPayReceivableInfoDto } from "@doorloop/dto";
import { ApplicantPostChargePayment, createValidator, DateFormats } from "@doorloop/dto";
import { rentalApplicationStore } from "api/entityApiStore/stores/rentalApplicationStore";
import { useSearchParams } from "hooks/useSearchParams";
import { Dialog } from "DLUI/dialogs";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import { authApi } from "api/authApi";
import { useSelector } from "react-redux";
import type { IRootState } from "store";
import noop from "lodash/noop";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { FormMaxWidth } from "DLUI/screen/tenantPortalScreen.constants";
import _ from "lodash";
import { FormikProvider, useFormik } from "formik";
import { RentalApplicationPaymentSummary } from "screens/tenantPortal/rentalApplication/rentalApplicationPaymentSummary";
import { RentalApplicationLoadingScreen } from "screens/tenantPortal/rentalApplication/rentalApplicationLoadingScreen";
const HEIGHT = 92;

const validateForm = createValidator(ApplicantPostChargePayment);

export const RentalApplicationPostSubmissionPaymentScreen: React.FC = () => {
  const loadingDialogSize = {
    width: getDialogFrameDimension("width", 720),
    height: getDialogFrameDimension("height", 740)
  };
  const userData = useSelector((state: IRootState) => state.auth.currentLoginResponse);
  const { isMobile } = useResponsiveHelper();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams((prevSearchParams) => {
      if (!prevSearchParams.has("companyId") && userData?.currentDbTenant.id) {
        prevSearchParams.set("companyId", userData?.currentDbTenant.id);
      }
      return prevSearchParams;
    });
  }, []);

  const rentalApplicationId = useMemo(() => searchParams.get("applicationId") || undefined, [searchParams]);

  const {
    data,
    error: getDataError,
    isLoading: getDataIsLoading
  } = rentalApplicationStore.queries.useGetRentalApplicationPostChargeData(rentalApplicationId);
  const {
    mutateAsync: sendApplicantPostChargePayment,
    isLoading: paymentIsLoading,
    error: paymentError,
    isSuccess: isPaymentSuccess
  } = rentalApplicationStore.mutations.useApplicantPostChargePayment();

  const initialValues = useMemo<ApplicantPostChargePayment>(() => {
    return {
      rentalApplicationId
    };
  }, [rentalApplicationId]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: _.noop,
    validate: validateForm
  });

  const paymentDialogState = paymentIsLoading
    ? DialogState.Show
    : paymentError
      ? DialogState.Error
      : DialogState.Hidden;

  if (!rentalApplicationId || getDataIsLoading || getDataError) {
    const error = rentalApplicationId ? getDataError : new Error(AppStrings.Common.NoApplicationIdError);
    return (
      <RentalApplicationLoadingScreen
        state={getDataError || !rentalApplicationId ? DialogState.Error : DialogState.Show}
        error={error?.message}
        dismissButtonProps={{
          onClickDismiss: noop,
          hideDismissButton: true
        }}
      />
    );
  }

  if (isPaymentSuccess) {
    return (
      <View
        backgroundColor={"light"}
        alignItems={"center"}
        justifyContent={"center"}
        overflow={"scroll"}
        height={window.innerHeight}
        noWrap
        paddingRight={isMobile ? 8 : undefined}
        paddingLeft={isMobile ? 8 : undefined}
        gap={12}
      >
        <Icon Source={GreenCheckIcon} size={96} />
        <Text value={AppStrings.Common.PaymentSuccessful} fontFamily={"Poppins"} fontSize={20} bold />
        <View alignItems={"center"} justifyContent={"center"}>
          {data?.rentalApplication?.applicationReference && (
            <Text
              color={"secondary-gray"}
              value={AppStrings.Common.ConfirmationNumberObject}
              replaceObject={{ confirmationNumber: _.toUpper(data?.rentalApplication?.applicationReference) }}
            />
          )}
          <Text color={"secondary-gray"} value={AppStrings.Common.YouCanNowCloseTheWindow} />
        </View>
      </View>
    );
  }

  const handlePaymentComplete = async (cardInfo: EPayReceivableInfoDto) => {
    if (!formik.values.rentalApplicationId) {
      return;
    }
    cardInfo.termsAndConditionsVersion = "1.0";
    cardInfo.termsAndConditionsAccepted = true;
    cardInfo.fee = data?.settings?.applicationFee;

    const dto: ApplicantPostChargePayment = {
      ...formik.values,
      cardInfo
    };
    formik.setValues(dto);
    await sendApplicantPostChargePayment(dto);
    await authApi.logout(false);
  };
  const handleCloseDialog = async () => {
    await authApi.logout(false);
    location.reload();
  };

  return (
    <>
      <View
        backgroundColor={"light"}
        alignItems={"center"}
        overflow={"scroll"}
        height={window.innerHeight}
        noWrap
        paddingRight={isMobile ? 8 : undefined}
        paddingLeft={isMobile ? 8 : undefined}
      >
        <View noWrap style={{ position: "fixed", zIndex: 10 }}>
          <TenantPortalBackgroundView height={HEIGHT} maxWidth={"none"}>
            <View
              noWrap
              paddingLeft={isMobile ? 0 : 24}
              paddingTop={24}
              paddingBottom={24}
              justifyContent={"center"}
              maxWidth={FormMaxWidth}
              gap={2}
            >
              <Text
                color={"white"}
                fontSize={isMobile ? 16 : 14}
                fontFamily={"Poppins"}
                value={AppStrings.Common.BackgroundCheckPaymentPage}
              />
              <Text
                style={{
                  color: "rgba(255,255,255,0.7)"
                }}
                fontSize={isMobile ? 16 : 14}
                fontFamily={"Poppins"}
                value={data?.property?.name}
              />
            </View>
          </TenantPortalBackgroundView>
        </View>
        <View noWrap alignItems={"center"} justifyContent={"center"} maxWidth={895} marginTop={HEIGHT + 30}>
          <View noWrap alignItems={"center"}>
            <Text
              value={AppStrings.Common.SecureYourRental}
              bold
              align={"center"}
              fontSize={24}
              fontFamily={"Poppins"}
            />
            <Text
              value={AppStrings.Common.AfterPaymentWellConduct}
              align={"center"}
              fontFamily={"Poppins"}
              paddingTop={4}
            />
            <Text value={AppStrings.Common.LetsMakeYourDreamAReality} bold fontFamily={"Poppins"} paddingTop={12} />
            <CreditCardFormStripe
              merchantAccountData={data?.settings?.merchantAccountData}
              applicationFee={data?.settings?.applicationFee}
              onPaymentCompleted={handlePaymentComplete}
              stripePaymentKey={data?.settings?.stripePaymentKey}
              PaymentSummaryDetailsComponent={
                <FormikProvider value={formik}>
                  <RentalApplicationPaymentSummary
                    rentalApplicationData={{
                      applicationId: data?.rentalApplication?.applicationReference
                        ? `#${data?.rentalApplication?.applicationReference}`
                        : "",
                      submittedDate: moment(data?.rentalApplication?.submittedAt).format(DateFormats.AMERICAN_DATE),
                      email: data?.rentalApplication?.email ?? ""
                    }}
                  />
                </FormikProvider>
              }
              showTitle={false}
            />
          </View>
        </View>
        <View height={80} />
      </View>

      <Dialog
        dialogState={paymentDialogState}
        onClose={handleCloseDialog}
        {...loadingDialogSize}
        Content={() => (
          <RentalApplicationLoadingScreen
            state={paymentDialogState}
            error={paymentError?.message}
            dismissButtonProps={{
              onClickDismiss: handleCloseDialog,
              hideDismissButton: false
            }}
            {...loadingDialogSize}
          />
        )}
      />
      <div id={"dialogContainer"} />
    </>
  );
};
