import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import type {
  AnyPermission,
  ObjectPermission,
  SettingPermission,
  TaskViewObjectAccess,
  UserRoleDto
} from "@doorloop/dto";
import { SettingsAccessEnum, shouldBlockFeatureForSubscriptionPriceId } from "@doorloop/dto";
import { getIn } from "formik";
import type { AnyPermissionClearance, ObjectPermissionField } from "screens/settings/userRoles/clearanceTypes";
import { makePermissionKey } from "screens/settings/userRoles/clearanceTypes";
import _ from "lodash";

type GrantLevel = TaskViewObjectAccess | SettingsAccessEnum;
type HasPermissionType = (
  permission?: AnyPermission, // ReportPermission has only permission
  field?: ObjectPermissionField, // ObjectPermission has field
  grantLevel?: GrantLevel // SettingsPermission has level
) => boolean;

interface UsePermissionHookInterface {
  hasPermission: HasPermissionType;
  hasClearance: (clearance?: AnyPermissionClearance) => boolean;
  hasSettingsEditPermission: (permission?: SettingPermission) => boolean;
  hasAnyPermission: (clearances?: readonly AnyPermissionClearance[]) => boolean;
  hasAllPermissions: (clearances: readonly AnyPermissionClearance[]) => boolean;
}

const getPermissionValue = (
  curUserRole: UserRoleDto,
  permission: AnyPermission,
  field?: ObjectPermissionField
): boolean | GrantLevel => {
  const permissionKey = field ? makePermissionKey(permission as ObjectPermission, field) : permission;
  return getIn(curUserRole, permissionKey) as boolean | GrantLevel;
};

const buildStaticResponseObject = (staticAnswer: boolean): UsePermissionHookInterface => {
  return {
    hasPermission: () => staticAnswer,
    hasClearance: () => staticAnswer,
    hasSettingsEditPermission: () => staticAnswer,
    hasAnyPermission: () => staticAnswer,
    hasAllPermissions: () => staticAnswer
  };
};

const staticTrueObject = buildStaticResponseObject(true);

export const usePermission: () => UsePermissionHookInterface = () => {
  const curUserRole = useSelector((state: IRootState) => state.auth.currentLoginResponse?.currentUserRole);

  let subscriptionPriceId = useSelector(
    (state: IRootState) => state.auth.currentLoginResponse?.currentDbTenant?.subscriptionPlan?.price_id
  );

  // ignore subscription price ids for accounts created before 3/1/2022 until 4/1/2022 (excluding test accounts)
  const shouldIgnoreSubscriptionPriceId = useSelector(
    (state: IRootState) =>
      !state?.auth?.currentLoginResponse?.currentDbTenant?.testAccount &&
      state?.auth?.currentLoginResponse?.currentDbTenant?.createdAt &&
      new Date(state?.auth?.currentLoginResponse?.currentDbTenant?.createdAt) <= new Date(2022, 2, 1)
    //&& new Date() < new Date(2022, 3, 15)
  );

  if (shouldIgnoreSubscriptionPriceId) {
    subscriptionPriceId = undefined;
  }

  if (!curUserRole) {
    return staticTrueObject;
  }

  const hasPermission: HasPermissionType = (permission, field, grantLevel): boolean => {
    if (permission && shouldBlockFeatureForSubscriptionPriceId(permission, subscriptionPriceId)) {
      return false;
    }

    if (curUserRole?.fullAccess || !permission) {
      return true;
    }

    const permissionValue = getPermissionValue(curUserRole, permission, field);

    return grantLevel ? permissionValue === grantLevel : Boolean(permissionValue);
  };

  return {
    hasPermission,
    hasClearance(clearance) {
      return !clearance || hasPermission(clearance.permission, clearance.field, clearance.requiredLevel);
    },
    hasSettingsEditPermission(permission) {
      return !permission || curUserRole.fullAccess || getIn(curUserRole, permission) === SettingsAccessEnum.EDIT; // On no definition, defaults to false
    },
    hasAnyPermission(clearances) {
      return (
        !clearances ||
        _.isEmpty(clearances) ||
        clearances.reduce<boolean>(
          (previous, { permission, field, requiredLevel }) =>
            previous || hasPermission(permission, field, requiredLevel),
          false
        )
      );
    },
    hasAllPermissions(clearances) {
      return clearances.reduce<boolean>(
        (previous, { permission, field, requiredLevel }) => previous && hasPermission(permission, field, requiredLevel),
        true
      );
    }
  };
};
