import { SeparationLine } from "DLUI/separatorView";
import { makeStyles } from "@material-ui/styles";
import ColorsEnum from "utils/colorsEnum";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import { ColorPicker } from "DLUI/colorPicker/colorPicker";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { usePopupContext } from "screens/calendar/popup/context";

const useStyles = makeStyles(() => {
  return {
    separationLine: {
      padding: "8px 16px 8px 16px"
    }
  };
});

interface ColorPickerPopupProps {
  onThemeColorChange: (selectedColor: string) => void;
  themeColor: string;
}

export const ColorPickerPopup = ({ onThemeColorChange, themeColor }: ColorPickerPopupProps) => {
  const { isMobile } = useResponsiveHelper();
  const classes = useStyles();
  const { onClose } = usePopupContext();
  return (
    <>
      <ColorPicker onSelectionChange={onThemeColorChange} selectedColor={themeColor} />
      {isMobile && (
        <>
          <div className={classes.separationLine}>
            <SeparationLine height={1} color={ColorsEnum.LightBlackFadedMore} width="100%" />
          </div>
          <DLButton
            onClick={onClose}
            color={DLButtonColorsEnum.SECONDARY}
            variant={DLButtonVariantsEnum.TEXT}
            size={DLButtonSizesEnum.LARGE}
            actionText={"Close"}
          />
        </>
      )}
    </>
  );
};
