import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import type { FC } from "react";
import React from "react";
import type { Theme } from "styles/defaultTheme";

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      cursor: "pointer",
      position: "relative",
      marginLeft: "-1px",
      display: "inline-flex",
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 4,
      fontSize: 12,
      padding: "8px 12px",
      fontWeight: 500
    },
    unselected: {
      color: theme.newPalette.text.primary,
      backgroundColor: theme.newPalette.background.paper,
      "&:hover": {
        backgroundColor: theme.newPalette.secondary.states.hover
      }
    },
    selected: {
      color: theme.newPalette.text.white,
      backgroundColor: theme.newPalette.secondary.main
    }
  };
});

interface TabButtonProps {
  isSelected?: boolean;
  onClick?: VoidFunction;
}

export const TabButton: FC<TabButtonProps> = ({ children, onClick, isSelected }) => {
  const { root, selected, unselected } = useStyles();

  return (
    <button
      onClick={onClick}
      type={"button"}
      aria-selected={isSelected}
      className={clsx("button-reset", root, {
        [selected]: isSelected,
        [unselected]: !isSelected
      })}
    >
      {children}
    </button>
  );
};
