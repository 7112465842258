"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenantPetsReportItemDto = void 0;
const leases_1 = require("../../leases");
class TenantPetsReportItemDto extends leases_1.LeaseTenantDto {
    constructor(init) {
        super(init);
        Object.assign(this, init);
    }
}
exports.TenantPetsReportItemDto = TenantPetsReportItemDto;
