import React from "react";
import type { FieldInputProps } from "formik";
import { useTranslation } from "react-i18next";
import AppStrings from "../../../../../locale/keys";
import type { OptionType } from "DLUI/form/autoComplete/autoComplete";
import { DataCy } from "@doorloop/dto";
import AddressAutoComplete from "DLUI/form/autoComplete/addressAutoComplete/addressAutoComplete";

interface StateAutoCompleteProps {
  values: OptionType[];
  field: FieldInputProps<string>;
  label: string;
  uniqueId: string;
  defaultValue?: OptionType;
  marginTop?: number;
  isAddressDefined?: boolean;
  handleChangeSearchAddressEngine?: (engineType: "google" | "smarty") => void;
}

const StateAutoComplete = ({
  values,
  uniqueId,
  field,
  defaultValue,
  isAddressDefined,
  label,
  ...rest
}: StateAutoCompleteProps) => {
  const { t } = useTranslation();

  const filterOptions = (options, { inputValue }) =>
    options.filter(
      (option) =>
        (option.value && option.value.toLowerCase().includes(inputValue.toLowerCase())) ||
        (option.name && option.name.toLowerCase().includes(inputValue.toLowerCase()))
    );

  return (
    <AddressAutoComplete
      uniqueId={uniqueId}
      values={values}
      noOptionsText={t(AppStrings.Common.NoStatesFound)}
      label={label}
      dataCy={DataCy.addressInformation.state}
      filterOptions={filterOptions}
      field={field}
      defaultValue={defaultValue}
      isAddressDefined={isAddressDefined}
      {...rest}
    />
  );
};

export default StateAutoComplete;
