"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenantPortalServerRoutes = void 0;
var TenantPortalServerRoutes;
(function (TenantPortalServerRoutes) {
    TenantPortalServerRoutes["LEASES"] = "/api/tenant-portal/leases";
    TenantPortalServerRoutes["GET_CONVENIENCE_FEES_FOR_LEASE"] = "/api/tenant-portal/leases/convenience-fees";
    TenantPortalServerRoutes["FILES"] = "/api/tenant-portal/files";
    TenantPortalServerRoutes["SETTINGS_LOGIN_AND_PASSWORD"] = "/api/tenant-portal/settings/login-and-password";
    TenantPortalServerRoutes["PAYMENTS"] = "/api/tenant-portal/payments";
    TenantPortalServerRoutes["PAYMENT_TOKENS"] = "/api/tenant-portal/payment-tokens";
    TenantPortalServerRoutes["PAYMENT_TOKENS_FOR_TENANT"] = "/api/tenant-portal/payment-tokens-for-tenant";
    TenantPortalServerRoutes["MERCHANT_ACCOUNTS_POST_CREATE_SETUP_INTENT"] = "/api/tenant-portal/payment-tokens/create-setup-intent";
    TenantPortalServerRoutes["ANNOUNCEMENTS"] = "/api/tenant-portal/announcements";
    TenantPortalServerRoutes["GET_MERCHANT_ACCOUNT"] = "/api/tenant-portal/merchant-account";
    TenantPortalServerRoutes["GET_LIMITS_FOR_MERCHANT_ACCOUNT"] = "/api/tenant-portal/limits-for-merchant-account";
    TenantPortalServerRoutes["RECURRING_PAYMENTS"] = "/api/tenant-portal/recurring-payments";
    TenantPortalServerRoutes["CANCEL_RECURRING_PAYMENT"] = "/api/tenant-portal/recurring-payments/:id/cancel";
    TenantPortalServerRoutes["TENANT_REQUESTS"] = "/api/tenant-portal/tenant-requests";
    TenantPortalServerRoutes["TENANT_REQUESTS_POST_UPDATE"] = "/api/tenant-portal/tenant-requests/update";
    TenantPortalServerRoutes["TENANT_REQUESTS_GET_ACTIVITY_FEED"] = "/api/tenant-portal/tenant-requests/activity-feed";
    TenantPortalServerRoutes["INSURANCE_POLICIES"] = "/api/tenant-portal/insurance-policies";
    TenantPortalServerRoutes["INSURANCE_COMPANY_BY_STATE"] = "/api/tenant-portal/insurance-policies/insurance-company-by-state";
    TenantPortalServerRoutes["REPORTS_LEASE_ACCOUNTS_RECEIVABLE"] = "/api/tenant-portal/reports/accounts-receivable";
    TenantPortalServerRoutes["REPORTS_LEASE_ACCOUNTS_RECEIVABLE_PDF"] = "/api/tenant-portal/reports/accounts-receivable/pdf";
    TenantPortalServerRoutes["REPORTS_LEASE_ACCOUNTS_RECEIVABLE_PDF_TOKEN"] = "/api/tenant-portal/reports/accounts-receivable/pdf-token";
    TenantPortalServerRoutes["REPORTS_LEASE_ACCOUNTS_RECEIVABLE_PDF_DOWNLOAD_BY_TOKEN"] = "/api/tenant-portal/reports/accounts-receivable/pdf-download-by-token/:id";
    TenantPortalServerRoutes["AUTH_GET_CURRENT_LOGIN_RESPONSE"] = "/api/tenant-portal/auth/me";
    TenantPortalServerRoutes["AUTH_GET_CURRENT_PROSPECT_LOGIN_RESPONSE"] = "/api/tenant-portal/prospect/auth/me";
    TenantPortalServerRoutes["AUTH_POST_CREATE_IMPERSONATION_TOKEN"] = "/api/tenant-portal/auth/create-impersonation-token";
    TenantPortalServerRoutes["TENANTS_NOTIFICATION_SETTINGS"] = "/api/tenant-portal/notification-settings";
    TenantPortalServerRoutes["GENERATE_PUSH_TOKEN"] = "/api/tenant-portal/notifications/generate-push-token";
    TenantPortalServerRoutes["GENERATE_CHANNEL_TOKEN"] = "/api/tenant-portal/notifications/generate-channel-token";
    TenantPortalServerRoutes["NOTIFICATIONS"] = "/api/tenant-portal/notifications";
    TenantPortalServerRoutes["TOTAL_UNREAD_NOTIFICATIONS"] = "/api/tenant-portal/unread-notifications";
    TenantPortalServerRoutes["MARK_ALL_UNREAD_NOTIFICATIONS"] = "/api/tenant-portal/mark-all-unread-notifications";
    TenantPortalServerRoutes["GET_SETUP_INTENT"] = "/api/tenant-portal/setup-intent";
    TenantPortalServerRoutes["REPORTS_BALANCE_SHEET_SUMMARY"] = "/api/tenant-portal/reports/balance-sheet-summary";
    TenantPortalServerRoutes["REPORTS_CASH_FLOW_STATEMENT"] = "/api/tenant-portal/reports/cash-flow-statement";
    TenantPortalServerRoutes["REPORTS_PROFIT_AND_LOSS_SUMMARY"] = "/api/tenant-portal/reports/profit-and-loss-summary";
    TenantPortalServerRoutes["REPORTS_ACCOUNTS_RECEIVABLE_AGING_SUMMARY"] = "/api/tenant-portal/reports/accounts-receivable-aging-summary";
    TenantPortalServerRoutes["PROPERTIES"] = "/api/tenant-portal/properties";
    TenantPortalServerRoutes["UNITS"] = "/api/tenant-portal/units";
    TenantPortalServerRoutes["VENDORS"] = "/api/tenant-portal/vendors";
    TenantPortalServerRoutes["TENANTS"] = "/api/tenant-portal/tenants";
    TenantPortalServerRoutes["ACCOUNTS"] = "/api/tenant-portal/accounts";
    TenantPortalServerRoutes["PROPERTY_GROUPS"] = "/api/tenant-portal/property-groups";
    TenantPortalServerRoutes["PHONE_NUMBERS_COMMUNICATION_PHONE_NUMBER"] = "/api/tenant-portal/phone-numbers/communication-phone-number";
})(TenantPortalServerRoutes || (exports.TenantPortalServerRoutes = TenantPortalServerRoutes = {}));
