import React from "react";
import { ContactIcon, EnvelopeIcon, PaletteIcon, UnlockIcon } from "assets/icons";
import type { MenuItem } from "DLUI/dialogs";
import AppStrings from "locale/keys";
import ContactInfo from "./components/contactInfo";
import TenantPortalSettingsSection from "./components/tenantPortalSettingsSection";
import PortalInvites from "./components/portalInvites";
import ThemeSettings from "./components/themeSettings";
import type { Translation } from "locale";

export const getResidentPortalFormMenuItems = (): MenuItem[] => [
  {
    title: AppStrings.Common.TenantPortalSettingsSections.Permissions,
    icon: UnlockIcon,
    contentComponent: (
      formikRef,
      onFileReceive,
      onFileDelete,
      onCompanyLogoReceive,
      onCompanyLogoDelete,
      onCoverImageFileReceive,
      onCoverImageFileDelete,
      merchantAccount,
      transunionEligibilityMessage,
      transunionFee,
      hasTenantsAutoPayments
    ) => (
      <TenantPortalSettingsSection
        formikRef={formikRef}
        hasTenantsAutoPayments={hasTenantsAutoPayments || { hasAutoPayments: false }}
      />
    )
  },
  {
    title: AppStrings.Common.TenantPortalSettingsSections.ContactInfo,
    icon: ContactIcon,
    contentComponent: () => <ContactInfo />,
    uploadFile: true
  },
  {
    title: "common.tenantPortalSettingsSections.portalCustomization" as Translation,
    icon: PaletteIcon,
    contentComponent: (
      formikRef,
      _onFileReceive,
      _onFileDelete,
      onTenantPortalLogoFileReceive,
      onTenantPortalLogoFileDelete,
      onCoverImageFileReceive,
      onCoverImageFileDelete
    ) => (
      <ThemeSettings
        onTenantPortalLogoFileReceive={onTenantPortalLogoFileReceive}
        onTenantPortalLogoFileDelete={onTenantPortalLogoFileDelete}
        onCoverImageFileReceive={onCoverImageFileReceive}
        onCoverImageFileDelete={onCoverImageFileDelete}
        formikRef={formikRef}
      />
    ),
    uploadFile: true
  },
  {
    title: AppStrings.Common.TenantPortalSettingsSections.PortalInvites,
    icon: EnvelopeIcon,
    contentComponent: (formikRef) => <PortalInvites formikRef={formikRef} />
  }
];
