import { atom } from "jotai";

import { ConversationsStatsDto } from "@doorloop/dto";

interface ConversationsStatsAtom {
  lastDate: Date;
  conversationsStats: ConversationsStatsDto;
}

export const conversationsStatsAtom = atom<ConversationsStatsAtom>({
  lastDate: new Date(),
  conversationsStats: new ConversationsStatsDto({
    totalUnreadAssigned: 0,
    total: 0,
    totalUnassigned: 0,
    totalArchived: 0
  })
});

export const conversationsStatsLastDateAtom = atom((get) => get(conversationsStatsAtom).lastDate);

export const conversationsInboxCountAtom = atom(
  (get) => get(conversationsStatsAtom).conversationsStats.totalUnreadAssigned
);

export const conversationsUnassignedCountAtom = atom(
  (get) => get(conversationsStatsAtom).conversationsStats.totalUnassigned
);
