import React from "react";
import { View } from "DLUI/view";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { LoadingDialog } from "DLUI/dialogs";
import { useTranslation } from "react-i18next";
import type { DialogState } from "DLUI/dialogs/loadingDialog";
const HEIGHT = 92;

interface RentalApplicationLoadingScreen {
  error?: string;
  state: DialogState;
  dismissButtonProps?: {
    hideDismissButton: boolean;
    onClickDismiss: VoidFunction;
  };
  width?: number | string;
  height?: number | string;
}
export const RentalApplicationLoadingScreen = ({
  state,
  error = "",
  dismissButtonProps,
  width = "100%",
  height = window.innerHeight
}: RentalApplicationLoadingScreen) => {
  const { isMobile } = useResponsiveHelper();
  const { t } = useTranslation();

  return (
    <View
      noWrap
      height={isMobile ? "100%" : height}
      width={isMobile ? "100%" : width}
      justifyContent={"center"}
      alignItems={"center"}
      backgroundColor={"light"}
    >
      <LoadingDialog
        dialogState={state}
        errorText={t(error)}
        hideDismissButton={dismissButtonProps?.hideDismissButton}
        didPressDismissButton={dismissButtonProps?.onClickDismiss}
      />
    </View>
  );
};
