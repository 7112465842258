import React, { useEffect, useMemo, useState } from "react";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { getIn, useFormikContext } from "formik";
import type { InsurancePolicyDto } from "@doorloop/dto";
import { ObjectPermission, LinkedResourceType } from "@doorloop/dto";
import AppStrings from "../../../../locale/keys";
import { useTranslation } from "react-i18next";
import DialogFrame from "../components/dialogFrame";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import InsurancePolicyForm from "DLUI/dialogs/insurancePolicy/insurancePolicyForm";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import _ from "lodash";
import InsurancePolicyLeaseSelection from "DLUI/dialogs/insurancePolicy/leaseSelection";
import { insurancePoliciesApi } from "api/insurancePoliciesApi";
import {
  frameTypeMap,
  InsurancePolicyDialogViews
} from "DLUI/dialogs/insurancePolicy/insurancePolicyFormikContextWrapper";
import type { FileListItemProps } from "DLUI/dropZone";
import { filesApi } from "api/filesApi";
import { useParams } from "react-router-dom";
import DeleteConfirmation from "DLUI/dialogs/components/deleteConfirmation";
import InsurancePolicyLoadingView from "DLUI/dialogs/insurancePolicy/loadingDialog";
import { tenantsApi } from "api/tenantsApi";
import { useEffectAsync } from "../../../../hooks/useEffectAsync";

const clearance: AnyPermissionClearance = {
  permission: ObjectPermission.insurancePolicies,
  field: "create"
};

const isValidForm = async (formikRef) => {
  const { setFieldTouched, validateForm } = formikRef;

  setFieldTouched("lease");
  setFieldTouched("tenant");
  setFieldTouched("provider");
  setFieldTouched("policyNumber");
  setFieldTouched("effectiveDate");
  setFieldTouched("expirationDate");
  setFieldTouched("coverage");
  const errors = (await validateForm()) as any;

  return _.isEmpty(errors);
};

interface ComponentProps {
  loadingDialogState: DialogState;
  setLoadingDialogState: (dialogState: DialogState) => void;
  loadingDialogErrorText: string;
  setLoadingDialogErrorText: (value: string) => void;
  viewIndex: number;
  setViewIndex: (index: number) => void;
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
  permission?: AnyPermissionClearance;
}

const InsurancePolicyDialog: React.FC<ComponentProps> = ({
  loadingDialogState,
  setLoadingDialogState,
  loadingDialogErrorText,
  setLoadingDialogErrorText,
  viewIndex,
  setViewIndex,
  dialogTitle,
  onClose,
  onBackdropClick
}: ComponentProps) => {
  const numViews = Object.keys(InsurancePolicyDialogViews).length;
  const frameWidth = Math.min(window.innerWidth - 80, 790);
  const frameHeight = Math.min(window.innerHeight, 666);
  const { policyId, leaseId } = useParams<any>();
  const isEditMode = policyId !== undefined;
  const { t } = useTranslation();
  const formikRef = useFormikContext<InsurancePolicyDto>();
  const [currentLeaseId, setCurrentLeaseId] = useState<string | undefined>(leaseId || getIn(formikRef.values, "lease"));
  const [selectLeaseFilterObj, setSelectLeaseFilterObj] = useState({
    filter_text: ""
  });
  const [errorText, setErrorText] = useState<string | undefined>();
  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);
  const [fetchInProgress, setFetchInProgress] = useState<boolean>(false);

  useEffect(() => {
    const errorText = getIn(formikRef.errors, "lease");
    const touchedVal = getIn(formikRef.touched, "lease");
    errorText && touchedVal && setErrorText(errorText);
  }, [formikRef.errors, formikRef.values]);

  useEffectAsync(async () => {
    if (leaseId && !formikRef.values.lease) {
      formikRef.setFieldValue("lease", leaseId);
      await fetchAndDefineTenant(leaseId);
    }
  }, [leaseId]);

  const currentTitle = useMemo(
    () =>
      viewIndex === InsurancePolicyDialogViews.DialogForm
        ? dialogTitle
        : t(AppStrings.Leases.InsurancePolicy.SelectLease),
    [viewIndex]
  );

  const frameType = useMemo(() => frameTypeMap[viewIndex], [viewIndex]);

  const finishedRequest = async ({ status, data, message }) => {
    if (status && data) {
      if (attachments) {
        await filesApi
          .uploadFiles(attachments, data.id, LinkedResourceType.InsurancePolicy)
          .catch((error: string) => setLoadingDialogErrorText(error || t(AppStrings.Common.GeneralError)));
      }
      onClose();
    } else {
      setLoadingDialogErrorText(message || t(AppStrings.Common.GeneralError));
      setLoadingDialogState(DialogState.Error);
    }
  };

  const createNewPolicy = async () => {
    const response = await insurancePoliciesApi.create(formikRef.values);
    await finishedRequest(response);
  };

  const editPolicy = async (policyId) => {
    if (!policyId) return;

    const response = await insurancePoliciesApi.update(policyId, formikRef.values);
    await finishedRequest(response);
  };

  const didPressSaveButton = async () => {
    const isValid = await isValidForm(formikRef);

    if (isValid) {
      setLoadingDialogState(DialogState.Show);
      setViewIndex(InsurancePolicyDialogViews.LoadingView);

      const policyId = getIn(formikRef.values, "id") as string | undefined;

      isEditMode ? await editPolicy(policyId) : await createNewPolicy();
    }
  };

  const _onBackdropClick = () => {
    if (viewIndex === InsurancePolicyDialogViews.SelectLease) {
      setCurrentLeaseId(undefined);
      setViewIndex(InsurancePolicyDialogViews.DialogForm);
      return;
    }
    if (viewIndex === InsurancePolicyDialogViews.ConfirmDelete) {
      setViewIndex(InsurancePolicyDialogViews.DialogForm);
    } else {
      onBackdropClick && onBackdropClick();
    }
  };

  const didChangeSearchQuery = (filter_text: string) => {
    setSelectLeaseFilterObj({
      filter_text
    });
  };

  const fetchAndDefineTenant = async (leaseId: string) => {
    setFetchInProgress(true);
    const {
      data: tenantsData,
      status,
      message
    } = await tenantsApi.getAll({
      filter_lease: leaseId
    });
    if (status) {
      const firstTenantId = tenantsData?.data[0].id;
      firstTenantId && formikRef.setFieldValue("tenant", firstTenantId);
      setFetchInProgress(false);
    } else {
      setLoadingDialogErrorText(message || t(AppStrings.Common.GeneralError));
      setLoadingDialogState(DialogState.Error);
    }
  };

  const onLeaseListItemPress = async (leaseId: string) => {
    setCurrentLeaseId(leaseId);
    formikRef.setFieldValue("lease", leaseId);
    await fetchAndDefineTenant(leaseId);

    setViewIndex(InsurancePolicyDialogViews.DialogForm);
  };

  const didPressDeleteButton = () => {
    setViewIndex(InsurancePolicyDialogViews.ConfirmDelete);
  };

  const renderActionPanelButtons = () => (
    <FormActionButtons
      clearance={clearance}
      propsActionPanel={{
        onDeleteButtonPress: didPressDeleteButton,
        editMode: isEditMode
      }}
      propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
      propsSubButton={{ onClick: onBackdropClick }}
    />
  );

  const renderView = ({ index }) => {
    switch (index) {
      case InsurancePolicyDialogViews.DialogForm:
        if (fetchInProgress) {
          return <div />;
        }
        return (
          <InsurancePolicyForm
            attachments={attachments}
            setAttachments={setAttachments}
            setViewIndex={setViewIndex}
            setCurrentLeaseId={setCurrentLeaseId}
            showLeaseSelectionError={errorText !== undefined}
            leaseSelectionErrorText={errorText || ""}
            currentLeaseId={currentLeaseId}
          />
        );
      case InsurancePolicyDialogViews.SelectLease:
        return (
          <InsurancePolicyLeaseSelection
            selectLeaseFilterObj={selectLeaseFilterObj}
            didChangeSearchQuery={didChangeSearchQuery}
            onLeaseListItemPress={onLeaseListItemPress}
          />
        );
      case InsurancePolicyDialogViews.LoadingView:
        return (
          <InsurancePolicyLoadingView
            loadingDialogState={loadingDialogState}
            loadingDialogErrorText={loadingDialogErrorText}
            onBackdropClick={onBackdropClick}
            didPressSubmit={didPressSaveButton}
          />
        );
      case InsurancePolicyDialogViews.ConfirmDelete:
        return (
          <DeleteConfirmation
            deleteConfirmationText={t(AppStrings.Leases.InsurancePolicy.DeletePolicyConfirm)}
            apiMethod={insurancePoliciesApi}
            didPressDismissButton={_onBackdropClick}
            didFinishOperation={onClose}
            transactionId={policyId}
            attachments={attachments}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={currentTitle}
      width={frameWidth}
      height={frameHeight}
      renderView={renderView}
      numViews={numViews}
      activeView={viewIndex}
      frameType={frameType}
      RenderActionPanelButtons={renderActionPanelButtons}
      useExperimentalDialogFrame
    />
  );
};

export default InsurancePolicyDialog;
