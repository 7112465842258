"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommunicationDeliveryStatus = void 0;
var CommunicationDeliveryStatus;
(function (CommunicationDeliveryStatus) {
    CommunicationDeliveryStatus["DRAFT"] = "DRAFT";
    CommunicationDeliveryStatus["FAILED"] = "FAILED";
    CommunicationDeliveryStatus["BOUNCED"] = "BOUNCED";
    CommunicationDeliveryStatus["COMPLAINT"] = "COMPLAINT";
    CommunicationDeliveryStatus["SENT"] = "SENT";
    CommunicationDeliveryStatus["DELIVERED"] = "DELIVERED";
    CommunicationDeliveryStatus["OPENED"] = "OPENED";
    CommunicationDeliveryStatus["CLICKED"] = "CLICKED";
})(CommunicationDeliveryStatus || (exports.CommunicationDeliveryStatus = CommunicationDeliveryStatus = {}));
