import type { FC } from "react";
import React from "react";
import { FastField } from "formik";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { SeparationLine } from "DLUI/separatorView";
import { TextField } from "DLUI/form";
import { useTranslation } from "react-i18next";
import AppStrings from "locale/keys";
import { SetupStep } from "./setupStep";
import "./styles.css";

const PrintAlignDescription: FC = () => (
  <Text value={AppStrings.Common.PrintChecks.Setup.Sample.PrintAlign.SubHeader} />
);

export const FineTuneAlignment: FC<{}> = () => {
  const { t } = useTranslation();
  const printSampleImageUrl = "/images/print-sample.png";
  return (
    <View noWrap flex={1} paddingLeft={40} paddingRight={40}>
      <View>
        <Text value={AppStrings.Common.PrintChecks.Setup.Adjust.Description.Part2} />
      </View>
      <View marginTop={20} marginBottom={20}>
        <SeparationLine width={"100%"} height={1} dividerColor={"gray"} />
      </View>
      <View>
        <Text fontSize={18} fontWeight={700} value={AppStrings.Common.PrintChecks.Setup.Adjust.Settings.Header} />
        <Text
          marginTop={10}
          fontSize={16}
          fontWeight={400}
          value={AppStrings.Common.PrintChecks.Setup.Adjust.Settings.Text}
        />
      </View>
      <View marginTop={20} marginBottom={20}>
        <SeparationLine width={"100%"} height={1} dividerColor={"gray"} />
      </View>
      <View flexDirection={"row"}>
        <View flex={6}>
          <View>
            <Text
              value={AppStrings.Common.PrintChecks.Setup.Adjust.Settings.GridPreset}
              fontSize={16}
              fontWeight={700}
            />
          </View>

          <View className={"input-container"} flexDirection={"row"}>
            <FastField
              component={TextField}
              label={t(AppStrings.Common.PrintChecks.Setup.Adjust.Settings.Vertical)}
              name={"verticalMargin"}
              marginTop={20}
              size={"100%"}
              formatType={"number"}
              decimalScale={2}
              allowNegative
              maxNumber={100}
              minNumber={-20}
            />
          </View>
        </View>
        <View flex={6} />
      </View>
      <View marginTop={20} marginBottom={20}>
        <SeparationLine width={"100%"} height={1} dividerColor={"gray"} />
      </View>
      <View>
        <SetupStep
          imgUrl={printSampleImageUrl}
          stepHeader={t(AppStrings.Common.PrintChecks.Setup.Adjust.Settings.PrintTest)}
          StepDescription={PrintAlignDescription}
        />
      </View>
    </View>
  );
};
