import { LoginResponseType } from "@doorloop/dto";
import { filesApi, ownerPortalFilesApi, tenantPortalFilesApi } from "api/filesApi";

export const loginTypeToFilesApiMap: {
  [key in LoginResponseType]: typeof filesApi | typeof tenantPortalFilesApi | typeof ownerPortalFilesApi;
} = {
  [LoginResponseType.TENANT]: tenantPortalFilesApi,
  [LoginResponseType.OWNER]: ownerPortalFilesApi,
  [LoginResponseType.USER]: filesApi,
  [LoginResponseType.PROSPECT]: tenantPortalFilesApi,
  [LoginResponseType.API]: filesApi
};
