import React from "react";

import _ from "lodash";
import moment from "moment";
import qs from "query-string";
import type { FormikProps } from "formik";
import { Formik } from "formik";
import { Grid } from "@material-ui/core";
import { isPhoneNumber } from "class-validator";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import type { LoginAndPasswordSettingsDto } from "@doorloop/dto";
import {
  createValidator,
  LoginAndPasswordSettingsDisplayDto,
  LoginMfaType,
  PhoneType,
  SegmentEventTypes
} from "@doorloop/dto";

import AppStrings from "locale/keys";
import ColorsEnum from "../../../../utils/colorsEnum";
import DLButton, { DLButtonColorsEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import PhoneVerificationForm from "./phoneVerificationForm";
import Text from "DLUI/text";
import TextInput from "DLUI/form/textField/textInput";
import UnlockSettingsForm from "./unlockSettingsForm";
import WarningView from "DLUI/form/warningView/warningView";
import { analyticsService } from "../../../../services/analyticsService";
import type { ApiResult } from "api/apiResult";
import { CloseIcon } from "../../../../assets";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { FieldSafe } from "DLUI/fieldSafe/fieldSafe";
import { formatPhoneNumber } from "../../../../utils/formatPhoneNumber";
import { FormikSwitchButton, RadioGroup, TextField } from "DLUI/form";
import { Link } from "DLUI/link";
import { Routes } from "components/appRouter";
import { SeparationLine } from "DLUI/separatorView";
import { SESSION_STORAGE_COHERE_RECORDING_KEY } from "../../../../utils/appScriptsLauncher";
import { settingsApi } from "api/settingsApi";
import { tenantPortalSettingsApi } from "api/tenantPortal/tenantPortalSettingsApi";
import { useEffectAsync } from "../../../../hooks/useEffectAsync";
import { View } from "DLUI/view";
import { ownerPortalSettingsApi } from "api/ownerPortal/ownerPortalSettingsApi";

interface LoginAndPasswordProps {
  onClose: () => void;
  onBackdropClick: () => void;
  dialogTitle?: string;
}

interface LoginAndPasswordParams {
  leaseId?: string;
}

enum LoginAndPasswordView {
  UnlockSettingsForm,
  SettingsForm,
  PhoneVerificationForm,
  ExtraInformation
}

const LOGIN_AND_PASSWORD_VIEWS_LENGTH = Object.values(LoginAndPasswordView).length;

let formikGlobalRef: FormikProps<LoginAndPasswordSettingsDisplayDto> | null = null;

export const getFormikRef = () => formikGlobalRef;

const LoginAndPassword = ({ onClose, onBackdropClick, dialogTitle }: LoginAndPasswordProps): JSX.Element => {
  const { t } = useTranslation();
  const { leaseId } = useParams<LoginAndPasswordParams>();

  const [viewIndex, setViewIndex] = React.useState<LoginAndPasswordView>(LoginAndPasswordView.UnlockSettingsForm);
  const [loadingDialogState, setLoadingDialogState] = React.useState<DialogState>(DialogState.Show);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = React.useState<string>("");
  const [currentPassword, setCurrentPassword] = React.useState<string>();
  const [loginAndPasswordData, setLoginAndPasswordData] = React.useState<LoginAndPasswordSettingsDisplayDto | null>(
    null
  );
  const [isEditLoginEmail, setIsEditLoginEmail] = React.useState<boolean>(false);
  const [isEditPassword, setIsEditPassword] = React.useState<boolean>(false);
  const [defaultMobileNumber, setDefaultMobileNumber] = React.useState<string>("");
  const [technicianAccessExpiresDate, setExtraInformationDate] = React.useState<Date | undefined>();

  const isTenantPortal = React.useMemo(() => window.location.pathname.indexOf(Routes.TENANT_PORTAL_BASE_URL) > -1, []);
  const isOwnerPortal = React.useMemo(() => window.location.pathname.indexOf(Routes.OWNER_PORTAL_BASE_URL) > -1, []);

  const mfaMobileNumber = loginAndPasswordData?.mfaMobileNumber;

  useEffectAsync(async (): Promise<void> => {
    await loadSettingsData();
  }, []);

  const initFormValues = (): LoginAndPasswordSettingsDisplayDto => {
    if (loginAndPasswordData) {
      return loginAndPasswordData;
    }

    return new LoginAndPasswordSettingsDisplayDto();
  };

  const validateForm = createValidator(LoginAndPasswordSettingsDisplayDto);

  const showErrorMessage = (message: string): void => {
    setLoadingDialogErrorText(message);
    setLoadingDialogState(DialogState.Error);
  };

  const showLoading = (): void => {
    setLoadingDialogErrorText("");
    setLoadingDialogState(DialogState.Show);
  };

  const hideLoadingOrErrorMessage = (): void => {
    setLoadingDialogErrorText("");
    setLoadingDialogState(DialogState.Hidden);
  };

  const loadSettingsData = async (): Promise<void> => {
    const searchQuery = qs.parse(location.search);
    if (searchQuery && searchQuery[SESSION_STORAGE_COHERE_RECORDING_KEY]) {
      sessionStorage.setItem(SESSION_STORAGE_COHERE_RECORDING_KEY, "true");
      await settingsApi.grantTechAccess(true);
      document.location.href = document.location.href.replace(
        "settings/general-settings/general-settings/edit/login-and-password?techAccess=true",
        "home"
      );
    }

    showLoading();

    let response;

    try {
      if (isTenantPortal) {
        response = await tenantPortalSettingsApi.getAll({ lease: leaseId });
      } else if (isOwnerPortal) {
        response = await ownerPortalSettingsApi.getAll({});
      } else {
        response = await settingsApi.getLoginAndPasswordData();
      }
    } catch (error) {
      showErrorMessage(t(AppStrings.Common.GeneralError));
      return;
    }

    if (response.status) {
      setLoginAndPasswordData(response.data);

      if (!response.data?.mfaMobileNumber) {
        await loadDefaultMobileNumber();
      }

      hideLoadingOrErrorMessage();
    } else if (response.message) {
      showErrorMessage(response.message);
    } else {
      showErrorMessage(t(AppStrings.Common.NetworkErrorSubTitle));
    }
  };

  const loadDefaultMobileNumber = async (): Promise<void> => {
    if (isTenantPortal || isOwnerPortal) {
      return;
    }

    const personalInformation = await settingsApi.getPersonalInformation();

    if (personalInformation?.data?.phones?.length > 0) {
      const phones = personalInformation.data.phones;

      const phone = phones.find((phone) => phone.type === PhoneType.MOBILE && isPhoneNumber(phone.number, "US"));

      if (phone) {
        setDefaultMobileNumber(phone.number);
      }
    }
  };

  const isValidForm = async (formikRef: FormikProps<LoginAndPasswordSettingsDisplayDto>): Promise<boolean> => {
    if (!formikRef.values.currentPassword && currentPassword) {
      await formikRef.setFieldValue("currentPassword", currentPassword);
    }

    formikRef.setFieldTouched("loginEmail");
    formikRef.setFieldTouched("newPassword");
    formikRef.setFieldTouched("newPasswordConfirmation");

    const errors = await formikRef.validateForm();

    return _.isEmpty(errors);
  };

  const handleUnlocked = (currentPassword: string): void => {
    setCurrentPassword(currentPassword);
    setViewIndex(LoginAndPasswordView.SettingsForm);
  };

  const handleCloseButtonClick = (): void => {
    if (viewIndex === LoginAndPasswordView.PhoneVerificationForm) {
      setViewIndex(LoginAndPasswordView.SettingsForm);
      return;
    }

    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const handleEditLoginEmail = (): void => {
    if (isEditLoginEmail) {
      formikGlobalRef?.setFieldValue("loginEmail", loginAndPasswordData?.loginEmail);
    }

    setIsEditLoginEmail((isEditLoginEmail) => !isEditLoginEmail);
  };

  const handleEditPassword = (): void => {
    if (isEditPassword) {
      formikGlobalRef?.setFieldValue("newPassword", undefined);
      formikGlobalRef?.setFieldTouched("newPassword", false);

      formikGlobalRef?.setFieldValue("newPasswordConfirmation", undefined);
      formikGlobalRef?.setFieldTouched("newPasswordConfirmation", false);
    }

    setIsEditPassword((isEditPassword) => !isEditPassword);
  };

  const handleIsMfaRequiredChange = (isOn: boolean): void => {
    analyticsService.track(SegmentEventTypes.GENERAL_2FA_TOGGLE, { status: isOn ? "ON" : "OFF" });
  };

  const handleMfaTypeChangeSelection = (mfaType: string): void => {
    if (!formikGlobalRef) {
      return;
    }

    formikGlobalRef.setFieldValue("mfaType", mfaType);

    const emailStatus = mfaType === LoginMfaType.Email ? "ON" : "OFF";
    const smsStatus = mfaType === LoginMfaType.SMS ? "ON" : "OFF";

    analyticsService.track(SegmentEventTypes.EMAIL_2FA_RADIO_BUTTON, { status: emailStatus });
    analyticsService.track(SegmentEventTypes.SMS_2FA_RADIO_BUTTON, { status: smsStatus });
  };

  const handleVerifyYourPhoneNumberClick = (): void => {
    setViewIndex(LoginAndPasswordView.PhoneVerificationForm);
  };

  const handleVerifiedMobileNumber = async (mfaMobileNumber: string): Promise<void> => {
    setViewIndex(LoginAndPasswordView.SettingsForm);

    setLoginAndPasswordData((loginAndPasswordData) => {
      return {
        ...loginAndPasswordData,
        mfaMobileNumber
      };
    });
  };

  const handleSave = async (): Promise<void> => {
    if (!formikGlobalRef) {
      return;
    }

    if (!(await isValidForm(formikGlobalRef))) {
      return;
    }

    analyticsService.track(SegmentEventTypes.LOGINPASSWORDDIALOG_SAVE_CLICKED, {
      mfaEnabled: formikGlobalRef?.values.isMfaRequired,
      mfaType: formikGlobalRef?.values.mfaType
    });

    await updateLoginAndPasswordSettings(formikGlobalRef.values);
  };

  const handleDismissButton = (): void => {
    if (loginAndPasswordData) {
      hideLoadingOrErrorMessage();

      return;
    }

    onBackdropClick();
  };

  const updateLoginAndPasswordSettings = async (values: LoginAndPasswordSettingsDto): Promise<void> => {
    showLoading();

    let response: ApiResult<LoginAndPasswordSettingsDto>;

    if (isTenantPortal) {
      response = await tenantPortalSettingsApi.updateLoginAndPassword(values);
    } else if (isOwnerPortal) {
      response = await ownerPortalSettingsApi.updateLoginAndPassword(values);
    } else {
      response = await settingsApi.updateLoginAndPasswordData(values);
    }

    if (response.status && response.data) {
      hideLoadingOrErrorMessage();

      const techAccessEnabled =
        !loginAndPasswordData?.allowTechnicianAccess &&
        response.data.allowTechnicianAccess &&
        response.data.technicianAccessExpiresAt;

      if (!techAccessEnabled) {
        onClose();
      }

      setExtraInformationDate(response.data.technicianAccessExpiresAt || moment().add(30, "days").toDate());

      setViewIndex(LoginAndPasswordView.ExtraInformation);
    } else if (response) {
      showErrorMessage(response.message);
    }
  };

  const renderLoading = (): JSX.Element => (
    <LoadingDialog
      dialogState={loadingDialogState}
      errorText={loadingDialogErrorText}
      onRetryButtonPress={loadSettingsData}
      didPressDismissButton={handleDismissButton}
    />
  );

  const isUnlockSettingsFormActive = viewIndex === LoginAndPasswordView.UnlockSettingsForm;

  const renderUnlockSettingsForm = (): JSX.Element => (
    <View style={{ height: isUnlockSettingsFormActive ? "100%" : 0 }}>
      <UnlockSettingsForm isStepActive={isUnlockSettingsFormActive} onClose={onClose} onUnlocked={handleUnlocked} />
    </View>
  );

  const renderSettingsForm = (): JSX.Element => {
    const formInitialValues = initFormValues();

    return (
      <View style={{ height: viewIndex === LoginAndPasswordView.SettingsForm ? "100%" : 0 }}>
        <Formik initialValues={formInitialValues} onSubmit={() => {}} validate={validateForm}>
          {(formik) => {
            formikGlobalRef = formik;

            const renderFormMfaType = (): JSX.Element => (
              <>
                <SeparationLine marginTop={20} marginBottom={20} width="100%" height={1} />
                <View
                  borderRadius={5}
                  paddingLeft={15}
                  paddingRight={15}
                  paddingBottom={15}
                  paddingTop={15}
                  backgroundColor="white"
                >
                  <View flexDirection="row" noWrap alignItems="center" justifyContent="space-between">
                    <View flex={1} flexDirection="row">
                      <Text fontSize={14} fontWeight={700}>
                        {t(AppStrings.Auth.TwoFactorAuthentication)}
                      </Text>
                    </View>
                    <View flexDirection={"row"} flex={1} justifyContent={"flex-end"}>
                      <FieldSafe
                        onChange={handleIsMfaRequiredChange}
                        component={FormikSwitchButton}
                        name="isMfaRequired"
                      />
                    </View>
                  </View>
                  <View paddingTop={15}>
                    <Text fontSize={14} fontWeight={400} color="secondary-gray">
                      {t(AppStrings.Auth.TwoFactorAuthenticationDescription)}
                    </Text>
                  </View>
                  <View
                    shouldShow={Boolean(formik.values?.isMfaRequired)}
                    showAnimation="fade-in"
                    hideAnimation="fade-out"
                  >
                    <View paddingTop={15} paddingBottom={15}>
                      <SeparationLine width="100%" height={1} />
                    </View>
                    <View>
                      <FieldSafe
                        fullWidth
                        name="mfaType"
                        uniqueKey="mfaType"
                        component={RadioGroup}
                        defaultValue={formInitialValues?.mfaType || LoginMfaType.Email}
                        didChangeSelection={handleMfaTypeChangeSelection}
                        radioGroupItems={[
                          {
                            label: () => <Text fontSize={14}>{t(AppStrings.Auth.EmailAuthentication)}</Text>,
                            renderLabelContent: () => (
                              <WarningView iconSize={30} type="success">
                                <Text fontSize={14} fontWeight={400}>
                                  {t(AppStrings.Auth.EmailAuthenticationIsActivated, {
                                    email: loginAndPasswordData?.loginEmail
                                  })}
                                </Text>
                              </WarningView>
                            ),
                            value: LoginMfaType.Email
                          },
                          {
                            label: () => (
                              <View flex={1} flexDirection="row" gap={2}>
                                <Text fontSize={14}>{t(AppStrings.Auth.SmsAuthentication)}</Text>
                                <Text fontSize={14} color="pink">
                                  ({t(AppStrings.Common.Recommended)})
                                </Text>
                              </View>
                            ),
                            renderLabelContent: () => {
                              if (mfaMobileNumber) {
                                return (
                                  <WarningView iconSize={30} type="success">
                                    <View gap={5}>
                                      <View flex={1}>
                                        <Text fontSize={14} fontWeight={400}>
                                          {t(AppStrings.Auth.SmsAuthenticationIsActivated, {
                                            phoneNumber: formatPhoneNumber(mfaMobileNumber)
                                          })}
                                        </Text>
                                      </View>
                                      <View width="auto" onClick={handleVerifyYourPhoneNumberClick}>
                                        <Link
                                          fontSize={14}
                                          underline="always"
                                          textColor={ColorsEnum.BrightBlue}
                                          hoverColor="lightBlue"
                                        >
                                          {t(AppStrings.Auth.ChangeNumber)}
                                        </Link>
                                      </View>
                                    </View>
                                  </WarningView>
                                );
                              }

                              return (
                                <View>
                                  <DLButton
                                    onClick={handleVerifyYourPhoneNumberClick}
                                    color={DLButtonColorsEnum.SECONDARY}
                                    variant={DLButtonVariantsEnum.OUTLINED}
                                    actionText={t(AppStrings.Auth.VerifyYourPhoneNumber)}
                                  />
                                </View>
                              );
                            },
                            value: LoginMfaType.SMS
                          }
                        ]}
                      ></FieldSafe>
                    </View>
                  </View>
                </View>
              </>
            );

            const renderTechnicianAccessExpiresText = () => {
              const allowTechnicianAccess = formik.values?.allowTechnicianAccess;
              const technicianAccessExpiresAt =
                formik.values?.technicianAccessExpiresAt || moment().add(30, "days").toDate();

              return (
                <View shouldShow={Boolean(allowTechnicianAccess)} showAnimation="fade-in" hideAnimation="fade-out">
                  <View paddingTop={15} paddingBottom={15}>
                    <SeparationLine width="100%" height={1} />
                  </View>
                  <WarningView iconSize={30} type="success">
                    <View gap={2} flexDirection="row">
                      <Text
                        value={AppStrings.Settings.GeneralSettings.TechnicianAccessEnabldUntil}
                        fontSize={14}
                        fontWeight={400}
                      />
                      <Text value={technicianAccessExpiresAt} fontSize={14} fontWeight={400} formatType="date-time" />
                    </View>
                  </WarningView>
                </View>
              );
            };

            const renderTechnicianAccessForm = (): JSX.Element => (
              <>
                <SeparationLine marginTop={20} marginBottom={20} width="100%" height={1} />
                <View
                  borderRadius={5}
                  paddingLeft={15}
                  paddingRight={15}
                  paddingBottom={15}
                  paddingTop={15}
                  backgroundColor="white"
                >
                  <View flexDirection="row" noWrap alignItems="center" justifyContent="space-between">
                    <View flex={1} flexDirection="row" gap={2}>
                      <Text fontSize={14} fontWeight={700}>
                        {t(AppStrings.Auth.DoorLoopTechnicianAccess)}
                      </Text>
                      <Text fontSize={14} fontWeight={700} color="secondary-gray">
                        {t(AppStrings.Auth.DoorLoopTechnicianAccessDays, {
                          days: 30
                        })}
                      </Text>
                    </View>
                    <View flexDirection={"row"} flex={1} justifyContent={"flex-end"}>
                      <FieldSafe component={FormikSwitchButton} name="allowTechnicianAccess" />
                    </View>
                  </View>
                  <Text fontSize={14} fontWeight={400} paddingTop={15} color="secondary-gray">
                    {t(AppStrings.Auth.DoorLoopTechnicianAccessDescription, {
                      days: 30
                    })}
                  </Text>
                  {renderTechnicianAccessExpiresText()}
                </View>
              </>
            );

            return (
              <View height="100%" justifyContent="space-between">
                <View paddingTop={20} paddingRight={20} paddingBottom={20} paddingLeft={20}>
                  <Text marginBottom={20} fontSize={14} fontWeight={700}>
                    {t(AppStrings.Auth.LoginInformation)}
                  </Text>
                  <View gap={10}>
                    <View flexDirection="row" alignItems="flex-start" gap={10}>
                      <View flex={1}>
                        <FieldSafe
                          component={TextField}
                          label={t(AppStrings.Users.NewUser.LoginEmailAddress)}
                          name="loginEmail"
                          disabled={!isEditLoginEmail}
                        />
                      </View>
                      <DLButton
                        variant={DLButtonVariantsEnum.TEXT}
                        actionText={isEditLoginEmail ? undefined : t(AppStrings.Common.Edit)}
                        icons={isEditLoginEmail ? { start: { src: CloseIcon } } : undefined}
                        color={isEditLoginEmail ? DLButtonColorsEnum.NEUTRAL : DLButtonColorsEnum.SECONDARY}
                        onClick={handleEditLoginEmail}
                        style={{ minWidth: 0 }}
                      />
                    </View>
                    <View flexDirection="row" alignItems="flex-start" noWrap gap={10}>
                      {isEditPassword ? (
                        <>
                          <Grid item xs={6}>
                            <FieldSafe
                              component={TextField}
                              label={t(AppStrings.Auth.NewPassword)}
                              name="newPassword"
                              TextFieldType="password"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FieldSafe
                              component={TextField}
                              label={t(AppStrings.Auth.ConfirmPassword)}
                              name="newPasswordConfirmation"
                              TextFieldType="password"
                            />
                          </Grid>
                        </>
                      ) : (
                        <TextInput label={t(AppStrings.Auth.Password)} value="●●●●●●●●●●●●●" disabled />
                      )}
                      <DLButton
                        variant={DLButtonVariantsEnum.TEXT}
                        actionText={isEditPassword ? undefined : t(AppStrings.Common.Edit)}
                        icons={isEditPassword ? { start: { src: CloseIcon } } : undefined}
                        color={isEditPassword ? DLButtonColorsEnum.NEUTRAL : DLButtonColorsEnum.SECONDARY}
                        onClick={handleEditPassword}
                        style={{ minWidth: 0 }}
                      />
                    </View>
                  </View>
                  {!isTenantPortal && !isOwnerPortal && (
                    <>
                      {renderFormMfaType()}
                      {renderTechnicianAccessForm()}
                    </>
                  )}
                </View>
                <View>
                  <SeparationLine width="100%" height={1} />
                  <View
                    flexDirection="row"
                    justifyContent="flex-end"
                    gap={5}
                    paddingLeft={20}
                    paddingRight={20}
                    paddingBottom={20}
                    paddingTop={20}
                  >
                    <DLButton
                      onClick={onClose}
                      color={DLButtonColorsEnum.NEUTRAL}
                      actionText={t(AppStrings.Common.Close)}
                    />
                    <DLButton
                      onClick={handleSave}
                      color={DLButtonColorsEnum.PRIMARY}
                      actionText={t(AppStrings.Common.Save)}
                    />
                  </View>
                </View>
              </View>
            );
          }}
        </Formik>
      </View>
    );
  };

  const isPhoneVerificationFormActive = viewIndex === LoginAndPasswordView.PhoneVerificationForm;

  const renderPhoneVerificationForm = (): JSX.Element => (
    <View style={{ height: isPhoneVerificationFormActive ? "100%" : 0 }}>
      <PhoneVerificationForm
        isStepActive={isPhoneVerificationFormActive}
        defaultMobileNumber={defaultMobileNumber}
        onVerifiedMobileNumber={handleVerifiedMobileNumber}
      />
    </View>
  );

  const renderExtraInformation = (): JSX.Element => (
    <View
      style={{ height: viewIndex === LoginAndPasswordView.ExtraInformation ? "100%" : 0, minHeight: 150 }}
      paddingRight={8}
      paddingLeft={8}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <View gap={10} flexDirection="column" justifyContent="center" alignItems="center" height="100%">
        <Text value={AppStrings.Settings.GeneralSettings.TechnicianAccessEnabled} fontSize={16} bold />
        <View flexDirection="row" noWrap gap={4} justifyContent="center" alignItems="center">
          <Text value={AppStrings.Settings.GeneralSettings.ExpiresOn} fontSize={16} />
          <Text value={technicianAccessExpiresDate} fontSize={16} fontWeight={400} formatType="date-time" />
        </View>
      </View>
    </View>
  );

  const renderView = ({ index }: { index: LoginAndPasswordView }): JSX.Element => {
    if (loadingDialogState !== DialogState.Hidden) {
      return renderLoading();
    }

    switch (index) {
      case LoginAndPasswordView.UnlockSettingsForm:
        return renderUnlockSettingsForm();
      case LoginAndPasswordView.SettingsForm:
        return renderSettingsForm();
      case LoginAndPasswordView.PhoneVerificationForm:
        return renderPhoneVerificationForm();
      case LoginAndPasswordView.ExtraInformation:
        return renderExtraInformation();
      default:
        return <></>;
    }
  };

  const dialogFrameTitle = React.useMemo(() => {
    if (viewIndex === LoginAndPasswordView.PhoneVerificationForm) {
      return t(AppStrings.Auth.PhoneVerification);
    }

    return dialogTitle;
  }, [viewIndex]);

  const frameType = React.useMemo(() => {
    if (loadingDialogState !== DialogState.Hidden) {
      return "contentOnly";
    }

    return "sectionTitleFrame";
  }, [viewIndex, loadingDialogState]);

  return (
    <DialogFrame
      onCloseButtonClick={handleCloseButtonClick}
      title={dialogFrameTitle}
      width={540}
      height="auto"
      renderView={renderView}
      numViews={LOGIN_AND_PASSWORD_VIEWS_LENGTH}
      activeView={viewIndex}
      frameType={frameType}
      keepViewsMounted={false}
    />
  );
};

export default LoginAndPassword;
