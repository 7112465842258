"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseRefundDepositDto = exports.LeaseRefundDepositLineDto = void 0;
const checkPayableInfo_dto_1 = require("../../common/checkPayableInfo.dto");
const ePayPayableInfo_dto_1 = require("../../common/ePayPayableInfo.dto");
const isSmallerThan_validator_1 = require("../../common/validators/isSmallerThan.validator");
const class_validator_1 = require("class-validator");
const journalEntry_dto_1 = require("../journalEntry.dto");
const paymentMethod_enum_1 = require("../paymentMethod.enum");
const class_transformer_1 = require("class-transformer");
class LeaseRefundDepositLineDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.LeaseRefundDepositLineDto = LeaseRefundDepositLineDto;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], LeaseRefundDepositLineDto.prototype, "account", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(255),
    __metadata("design:type", String)
], LeaseRefundDepositLineDto.prototype, "memo", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsNumber)({ maxDecimalPlaces: 2 }),
    (0, isSmallerThan_validator_1.IsSmallerThan)("amountHeld", {
        message: "amount To Withold must be smaller than amount Held"
    }),
    __metadata("design:type", Number)
], LeaseRefundDepositLineDto.prototype, "amountToWithhold", void 0);
class LeaseRefundDepositDto extends journalEntry_dto_1.JournalEntryDto {
    constructor(init) {
        super();
        this.lines = [];
    }
    populateLines(depositsHeldReport) {
        depositsHeldReport.forEach((item) => {
            if (this.lines) {
                this.lines.push(new LeaseRefundDepositLineDto({
                    amountHeld: item.total,
                    account: item.account,
                    accountName: item.accountName
                }));
            }
        });
    }
}
exports.LeaseRefundDepositDto = LeaseRefundDepositDto;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], LeaseRefundDepositDto.prototype, "payFromAccount", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(paymentMethod_enum_1.PaymentMethod),
    __metadata("design:type", String)
], LeaseRefundDepositDto.prototype, "paymentMethod", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], LeaseRefundDepositDto.prototype, "payToTenant", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.paymentMethod === paymentMethod_enum_1.PaymentMethod.CHECK),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => checkPayableInfo_dto_1.CheckPayableInfoDto),
    __metadata("design:type", checkPayableInfo_dto_1.CheckPayableInfoDto)
], LeaseRefundDepositDto.prototype, "checkInfo", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.paymentMethod === paymentMethod_enum_1.PaymentMethod.EPAY),
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", ePayPayableInfo_dto_1.EPayPayableInfoDto)
], LeaseRefundDepositDto.prototype, "ePayInfo", void 0);
__decorate([
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => LeaseRefundDepositLineDto),
    __metadata("design:type", Array)
], LeaseRefundDepositDto.prototype, "lines", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], LeaseRefundDepositDto.prototype, "lease", void 0);
