import type { GetAllBaseQueryRequest } from "@doorloop/dto";
import type { RestApiBaseWithDictionary } from "api/restApiBaseWithDictionary";
import { CacheFilterItems } from "./constants";

let autoCompleteCacheInstance;

type QueryParamsType = GetAllBaseQueryRequest;

type DictionaryItem = Record<string, unknown>;

type DictionaryRawDataType = Record<string, { id?: string; name?: string; deleted?: boolean }>;

class AutoCompleteCacheBase {
  constructor() {
    if (autoCompleteCacheInstance) {
      throw new Error("You can only create one instance of AutoCompleteCache!");
    }
    autoCompleteCacheInstance = this;
  }

  private filterCacheItem = (queryParams: QueryParamsType, dictItem: DictionaryItem): boolean => {
    let includeItem = true;

    for (const filterItem in queryParams) {
      if (CacheFilterItems[filterItem]) {
        const { name: shortFilterName, type, convertFunc } = CacheFilterItems[filterItem];
        let filterValue = queryParams[filterItem];

        // convert data types if not matching the expectations
        // example: "true" => true
        if (typeof filterValue !== type && convertFunc) {
          filterValue = convertFunc(filterValue);
        }

        if (dictItem[shortFilterName] !== undefined) {
          if (Array.isArray(filterValue)) {
            if (!filterValue.includes(dictItem[shortFilterName])) {
              includeItem = false;

              break;
            }
          } else if (dictItem[shortFilterName] !== filterValue) {
            includeItem = false;

            break;
          }
        }
      }
    }

    return includeItem;
  };

  private checkIfFiltersSupported = (queryParams: QueryParamsType): boolean => {
    let allFiltersSupported = true;

    for (const filterItem in queryParams) {
      if (!CacheFilterItems[filterItem] && queryParams[filterItem] !== undefined) {
        console.warn(`${filterItem} filter is not yet supported. please add it in autoCompleteCache.ts`);
        allFiltersSupported = false;

        break;
      }
    }

    return allFiltersSupported;
  };

  // searches the given apiHandler dictionary for existing data
  // if not exist, pulls data from server
  // supports listed above filters on cached data
  async getCachedOptions(
    apiHandler: RestApiBaseWithDictionary<any, any>,
    queryParams?: QueryParamsType
  ): Promise<any[] | null> {
    // check if filters are supported. if not, return null to get response from api.
    if (queryParams && !this.checkIfFiltersSupported(queryParams)) {
      return null;
    }

    if (!apiHandler?.getDictionary) {
      return null;
    }

    const dictionaryRawData: DictionaryRawDataType = await apiHandler.getDictionary();
    const dictionaryData: any[] = [];

    if (!dictionaryRawData) {
      return null;
    }

    for (const dictId in dictionaryRawData) {
      const dictItem = dictionaryRawData[dictId];
      const dictItemId = dictItem.id || dictId;
      let includeItem = true;

      // prevent bad data items and UI crashes
      if (!dictItem.name) {
        continue;
      }

      // filter items if queryParams exist
      if (queryParams && dictItem?.deleted !== true) {
        includeItem = this.filterCacheItem(queryParams, dictItem);
      }

      if (includeItem) {
        dictionaryData.push({
          id: dictItemId,
          ...dictItem
        });
      }
    }

    return dictionaryData.length ? dictionaryData : null;
  }
}

const AutoCompleteCache = Object.freeze(new AutoCompleteCacheBase());

export default AutoCompleteCache;
