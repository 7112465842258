import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

const SCROLL_OFFSET = 100;

export default class Scroller {
  private scrollerContainer: HTMLDivElement;

  constructor(scrollerrRef: HTMLDivElement) {
    this.scrollerContainer = scrollerrRef;
  }

  scrollTop = (topPosition: number, delay?: number, scrollOffset?: number) => {
    setTimeout(() => {
      this.scrollerContainer.scroll({
        top: topPosition - (scrollOffset || SCROLL_OFFSET),
        behavior: "smooth"
      });
    }, delay || 0);
  };

  scrollBottom = (delay?: number) => {
    setTimeout(
      () => {
        this.scrollerContainer.scrollTo({
          left: 0,
          top: document.getElementById("screenScroll")!.scrollHeight,
          behavior: "smooth"
        });
      },
      delay ? delay : 0
    );
  };
}
