import React, { useCallback, useMemo } from "react";
import { View } from "DLUI/view";
import { Icon } from "DLUI/icon";
import { RoundInformationIcon } from "@/assets";
import Text from "DLUI/text";
import AppStrings from "@/locale/keys";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import Grid from "@material-ui/core/Grid";
import FieldWrapper from "DLUI/form/textField/fieldWrapper";
import type { FormikProps } from "formik";
import { FastField } from "formik";
import { TextField, ValidationIndicator } from "DLUI/form";
import { useTranslation } from "react-i18next";
import { useResponsiveHelper } from "@/contexts/responsiveContext";
import _ from "lodash";
import { onPortalLoginEmailChange, onPortalPhoneNumberChange } from "@/utils/entities/person.utils";
import { portalInfoEmailPath, portalInfoPhonePath } from "@/constants/person.constants";

interface Props {
  formikRef: FormikProps<any>;
  personType?: PersonContactInfoRowPersonType;
}

export type PersonContactInfoRowPersonType = "tenant" | "owner";

const PersonContactInfoRow: React.FC<Props> = ({ formikRef, personType = "tenant" }: Props) => {
  const { t } = useTranslation();
  const { screenContainerPadding } = useResponsiveHelper();

  const warningAppString = useMemo(
    () =>
      personType === "owner"
        ? AppStrings.Owners.NewOwner.OwnerLoginEmailPhoneDescription
        : AppStrings.Tenants.NewTenant.TenantLoginEmailPhoneDescription,
    [personType]
  );

  const handleLoginEmailChange = useCallback((value) => onPortalLoginEmailChange({ formikRef, value }), [formikRef]);

  const handlePhoneNumberChange = useCallback((value) => onPortalPhoneNumberChange({ formikRef, value }), [formikRef]);

  return (
    <View>
      <Text value={AppStrings.Common.ContactInfo} fontSize={18} marginTop={20} bold />
      <View flexDirection={"row"} justifyContent={"center"}>
        <Grid item xs={12} md={6} sm={6}>
          <FieldWrapper type={"endElement"}>
            <FastField
              component={TextField}
              label={t(AppStrings.Common.PrimaryEmail)}
              name={portalInfoEmailPath}
              onBlur={handleLoginEmailChange}
            />
          </FieldWrapper>
        </Grid>
        <Grid item xs={12} md={6} sm={6}>
          <FieldWrapper type={"startElement"}>
            <FastField
              component={TextField}
              label={t(AppStrings.Common.MobilePhone)}
              name={portalInfoPhonePath}
              onBlur={handlePhoneNumberChange}
            />
          </FieldWrapper>
        </Grid>

        {!formikRef?.values?.disablePersonDetailsValidation &&
          _.isEmpty(formikRef.values.portalInfo?.loginEmail) &&
          _.isEmpty(formikRef.values.portalInfo?.mobilePhone) &&
          (formikRef.errors.portalInfo || formikRef.touched.portalInfo) && (
            <ValidationIndicator
              shouldShow
              justifyContent={"center"}
              displayText={t(AppStrings.Common.PortalInfoError)}
            />
          )}
      </View>
      <View
        backgroundColor={"yellow-light"}
        flexDirection={"row"}
        alignItems={"center"}
        height={50}
        borderRadius={10}
        marginTop={20}
        paddingRight={screenContainerPadding}
        paddingLeft={screenContainerPadding}
        paddingBottom={10}
        paddingTop={10}
      >
        <Icon Source={RoundInformationIcon} rectFill={"yellow"} pathColor={"yellow"} width={25} marginRight={10} />
        <Text fullWidth fontSize={14} value={warningAppString} />
      </View>
      <HorizontalSeparationLine marginTop={20} />
    </View>
  );
};

export default PersonContactInfoRow;
