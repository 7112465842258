import { DialogFrame } from "DLUI/dialogs";
import AppStrings from "locale/keys";
import React, { useCallback, useState } from "react";
import { buildRenderView, getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import { SimpleLoading } from "DLUI/loading/simpleLoading";
import { useEffectAsync } from "hooks/useEffectAsync";
import { settingsApi } from "api/settingsApi";
import { CheckbookSettingsDialog } from "screens/settings/outgoingPayments/checkbookSettingsDialog";
import ConnectedToCheckbookFormikWrapper from "screens/settings/outgoingPayments/ConnectedToCheckbookFormikWrapper";
import type { FormikContextType, FormikErrors } from "formik";
import type { CheckbookApiKeysSettingsDto } from "@doorloop/dto";
import _ from "lodash";
import { accountsApi } from "api/accounts";

interface Dimensions {
  width: number;
  height: number;
}

interface ComponentProps {
  onClose: () => void;
  onBackdropClick: () => void;
}

enum DialogViews {
  LOADING,
  UNCONNECTED,
  CONNECTED
}

const midSizeDimensions: Dimensions = {
  width: getDialogFrameDimension("width", 600),
  height: getDialogFrameDimension("height", 887)
};

const dimensionsPerView: Record<DialogViews, Dimensions> = {
  [DialogViews.LOADING]: midSizeDimensions,
  [DialogViews.UNCONNECTED]: midSizeDimensions,
  [DialogViews.CONNECTED]: {
    width: getDialogFrameDimension("width", 1100),
    height: window.innerHeight
  }
};

export const OutgoingPaymentsSettingsDialogLauncher: React.FC<ComponentProps> = ({
  onClose,
  onBackdropClick
}: ComponentProps) => {
  const [currentView, setCurrentView] = useState<DialogViews>(DialogViews.LOADING);
  const [errorText, setErrorText] = useState<string>("");
  const [currentRetryMethod, setCurrentRetryMethod] = useState<"checkConnection" | "updateKeys">("checkConnection");

  const checkConnection = useCallback(async () => {
    setCurrentView(DialogViews.LOADING);
    setErrorText("");
    const { data, status, message } = await settingsApi.getCheckbookSettings();
    if (status && data) {
      if (data.enabled) {
        setCurrentView(DialogViews.CONNECTED);
      } else {
        setCurrentView(DialogViews.UNCONNECTED);
      }
    } else {
      setErrorText(message);
    }
  }, []);

  useEffectAsync(checkConnection, []);

  const validateConnectForm = async (
    formikRef: FormikContextType<CheckbookApiKeysSettingsDto>
  ): Promise<FormikErrors<CheckbookApiKeysSettingsDto>> => {
    formikRef.setFieldTouched("apiKey");
    formikRef.setFieldTouched("apiSecret");
    return await formikRef.validateForm();
  };

  const updateApiKeys = async (formikRef: FormikContextType<CheckbookApiKeysSettingsDto>) => {
    setCurrentView(DialogViews.LOADING);
    setCurrentRetryMethod("updateKeys");
    const { status, message } = await settingsApi.putCheckbookApiKeys(formikRef.values);
    if (status) {
      setCurrentView(DialogViews.CONNECTED);
    } else {
      setErrorText(message);
    }
  };

  const didPressConnectButton = async (formikRef: FormikContextType<CheckbookApiKeysSettingsDto>) => {
    const errors = await validateConnectForm(formikRef);
    if (_.isEmpty(errors)) {
      await updateApiKeys(formikRef);
    }
  };

  const handleOutgoingPaymentsSaveSettings = async () => {
    await accountsApi.getDictionary(true);
    onClose?.();
  };

  const { renderView, numViews } = buildRenderView([
    () => (
      <SimpleLoading
        errorText={errorText}
        retryHandler={currentRetryMethod === "checkConnection" ? checkConnection : undefined}
        dismissHandler={
          currentRetryMethod === "checkConnection" ? onBackdropClick : () => setCurrentView(DialogViews.UNCONNECTED)
        }
        hideRetryButton={currentRetryMethod === "updateKeys"}
      />
    ),
    () => (
      <ConnectedToCheckbookFormikWrapper
        didPressConnectButton={didPressConnectButton}
        onBackdropClick={onBackdropClick}
      />
    )
  ]);

  return currentView === DialogViews.CONNECTED ? (
    <CheckbookSettingsDialog onClose={handleOutgoingPaymentsSaveSettings} onBackdropClick={onBackdropClick} />
  ) : (
    <DialogFrame
      onCloseButtonClick={onBackdropClick}
      title={AppStrings.Common.OutgoingPayments.OutgoingPayments}
      {...dimensionsPerView[currentView]}
      renderView={renderView}
      numViews={numViews}
      activeView={currentView}
      frameType={"contentOnly"}
      keepViewsMounted={false}
    />
  );
};
