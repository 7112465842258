import { useMemo } from "react";
import { type NavConfigProps } from "../types";
import { matchNavPath } from "../utils/matchNavPath";

interface UseSelectedSubItemParams {
  menuLayers: Record<string, NavConfigProps>;
  pathToMatch: string;
}

export function useSelectedSubItem({ menuLayers, pathToMatch }: UseSelectedSubItemParams) {
  return useMemo(() => {
    const matchedGroup = Object.values(menuLayers)
      .flatMap((config) => config.groups)
      .find((group) => group.items.some((item) => item.navPath?.some((navPath) => matchNavPath(pathToMatch, navPath))));

    const navigationItem = matchedGroup?.items.find((item) =>
      item.navPath?.some((navPath) => matchNavPath(pathToMatch, navPath))
    );

    return { navigationItem, group: matchedGroup };
  }, [menuLayers, pathToMatch]);
}
