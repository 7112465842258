"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateConversationDto = void 0;
const class_validator_1 = require("class-validator");
const conversationLinkedToType_1 = require("./conversationLinkedToType");
const conversationMessageType_1 = require("./conversationMessageType");
const conversationMethod_1 = require("./conversationMethod");
const createConversationMessage_dto_1 = require("./createConversationMessage.dto");
class CreateConversationDto extends createConversationMessage_dto_1.CreateConversationMessageDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.CreateConversationDto = CreateConversationDto;
__decorate([
    (0, class_validator_1.IsEnum)(conversationLinkedToType_1.ConversationLinkedToType),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], CreateConversationDto.prototype, "linkedToType", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], CreateConversationDto.prototype, "linkedToId", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(255),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateIf)((dto) => dto.type === conversationMessageType_1.ConversationMessageType.EMAIL),
    __metadata("design:type", String)
], CreateConversationDto.prototype, "subject", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(conversationMethod_1.ConversationMethod),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], CreateConversationDto.prototype, "method", void 0);
