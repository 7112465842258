import {
  AgendaIcon,
  BadgeIcon,
  FedTaxesIcon,
  InsuranceIcon,
  LocationIcon,
  MenuExtraLocationIcon,
  MenuPropertiesIcon,
  OutgoingPaymentsIcon,
  ServicesIcon
} from "assets/icons";
import type { MenuItem } from "DLUI/dialogs";
import { ContactInfo, PersonAddress, PersonInfo } from "DLUI/person";
import AppStrings from "locale/keys";
import React from "react";
import { Insurance, Properties, Services } from "./components";
import { PersonOutgoingPaymentsTab } from "screens/outgoingPayments/personOutgoingPaymentsTab";
import { ConversationLinkedToType, SettingPermission, SettingsAccessEnum } from "@doorloop/dto";
import FederalTaxes from "DLUI/person/federalTaxes";
import { SendWelcomeSms } from "DLUI/person/sendWelcomeSms";

export const getVendorFormMenuItems = (): MenuItem[] => {
  const menuItems: MenuItem[] = [
    {
      title: AppStrings.Tenants.NewTenant.PersonalInfo,
      icon: BadgeIcon,
      contentComponent: (formikRef, onFileReceive, onFileDelete) => (
        <>
          <PersonInfo formikRef={formikRef} onFileReceive={onFileReceive} onFileDelete={onFileDelete} />
          <SendWelcomeSms personType={ConversationLinkedToType.VENDOR} />
        </>
      ),
      uploadFile: true
    },
    {
      title: AppStrings.Tenants.NewTenant.ContactInfo,
      icon: AgendaIcon,
      contentComponent: (formikRef) => <ContactInfo formikRef={formikRef} />
    },
    {
      title: AppStrings.Tenants.NewTenant.Address,
      icon: LocationIcon,
      contentComponent: (formikRef) => (
        <PersonAddress
          formikName={"primaryAddress"}
          title={AppStrings.Tenants.NewTenant.PrimaryAddress}
          formikRef={formikRef}
        />
      )
    },
    {
      title: AppStrings.Tenants.NewTenant.AlternateAddress,
      icon: MenuExtraLocationIcon,
      contentComponent: (formikRef) => (
        <PersonAddress
          formikName={"alternateAddress"}
          title={AppStrings.Tenants.NewTenant.AlternateAddress}
          formikRef={formikRef}
        />
      )
    },
    {
      title: AppStrings.Vendors.NewVendor.Services,
      icon: ServicesIcon,
      contentComponent: (formikRef) => <Services formikRef={formikRef} />
    },
    {
      title: AppStrings.Vendors.NewVendor.Insurance,
      icon: InsuranceIcon,
      contentComponent: (formikRef) => <Insurance formikRef={formikRef} />
    },
    {
      title: AppStrings.Vendors.NewVendor.Properties,
      icon: MenuPropertiesIcon,
      contentComponent: (formikRef) => <Properties formikRef={formikRef} />
    },
    {
      title: AppStrings.Vendors.NewVendor.FederalTaxes,
      icon: FedTaxesIcon,
      contentComponent: (formikRef) => (
        <FederalTaxes
          strings={{
            send1099Forms: AppStrings.Vendors.NewVendor.Send1099Forms,
            dontSend1099Forms: AppStrings.Vendors.NewVendor.DontSend1099Forms,
            sameAsPrimaryAddress: AppStrings.Vendors.NewVendor.SameAsVendorAddress
          }}
          formikRef={formikRef}
        />
      )
    },
    {
      title: AppStrings.Common.OutgoingPayments.OutgoingPayments,
      icon: OutgoingPaymentsIcon,
      contentComponent: () => <PersonOutgoingPaymentsTab personType={"vendor"} />,
      clearances: [
        {
          permission: SettingPermission.vendorsEPayInfo,
          requiredLevel: SettingsAccessEnum.VIEW
        },
        {
          permission: SettingPermission.vendorsEPayInfo,
          requiredLevel: SettingsAccessEnum.EDIT
        }
      ]
    }
  ];
  return menuItems;
};
