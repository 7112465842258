import { RestApiBase } from "api/restApiBase";
import type { ApiKeyDto, GetAllApiKeysQuery } from "@doorloop/dto";
import { ServerRoutes } from "@doorloop/dto";

class ApiKeysApi extends RestApiBase<ApiKeyDto, GetAllApiKeysQuery> {
  constructor() {
    super(ServerRoutes.API_KEYS);
    this.restRoute = ServerRoutes.API_KEYS;
  }
}

export const apiKeysApi = new ApiKeysApi();
