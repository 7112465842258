import type { ReportPermission, SettingPermission } from "@doorloop/dto";
import { ObjectPermission } from "@doorloop/dto";
import { TaskViewObjectAccess } from "@doorloop/dto";
import { SettingsAccessEnum } from "@doorloop/dto";
import { JournalEntryType } from "@doorloop/dto";
import { TaskType } from "@doorloop/dto";

export type ObjectPermissionField = "viewList" | "viewOne" | "create" | "edit" | "delete";

export interface ObjectPermissionClearance {
  permission: ObjectPermission;
  field: ObjectPermissionField;
  requiredLevel?: TaskViewObjectAccess;
}

interface ReportPermissionClearance {
  permission: ReportPermission;
  field?: never;
  requiredLevel?: never;
}

interface SettingsPermissionClearance {
  permission: SettingPermission;
  field?: never;
  requiredLevel?: SettingsAccessEnum;
}

export type AnyPermissionClearance =
  | ObjectPermissionClearance
  | ReportPermissionClearance
  | SettingsPermissionClearance;

export const makePermissionKey = (permission: ObjectPermission, field: ObjectPermissionField) =>
  `${permission}.${field}`;

const taskPermissionByTypeMap: { [taskType in TaskType]: ObjectPermission } = {
  [TaskType.INTERNAL_TASK]: ObjectPermission.tasks,
  [TaskType.WORK_ORDER]: ObjectPermission.workOrders,
  [TaskType.OWNER_REQUEST]: ObjectPermission.ownerRequests,
  [TaskType.TENANT_REQUEST]: ObjectPermission.tenantRequests
};
export const getTaskPermissionByType = (taskType: TaskType = TaskType.INTERNAL_TASK): ObjectPermission =>
  taskPermissionByTypeMap[taskType];

export const AnyTaskViewListPermissions: AnyPermissionClearance[] = [
  {
    permission: ObjectPermission.tasks,
    field: "viewList",
    requiredLevel: TaskViewObjectAccess.ALL
  },
  {
    permission: ObjectPermission.tasks,
    field: "viewList",
    requiredLevel: TaskViewObjectAccess.ASSIGNED_ONLY
  },
  {
    permission: ObjectPermission.ownerRequests,
    field: "viewList",
    requiredLevel: TaskViewObjectAccess.ALL
  },
  {
    permission: ObjectPermission.ownerRequests,
    field: "viewList",
    requiredLevel: TaskViewObjectAccess.ASSIGNED_ONLY
  },
  {
    permission: ObjectPermission.tenantRequests,
    field: "viewList",
    requiredLevel: TaskViewObjectAccess.ALL
  },
  {
    permission: ObjectPermission.tenantRequests,
    field: "viewList",
    requiredLevel: TaskViewObjectAccess.ASSIGNED_ONLY
  },
  {
    permission: ObjectPermission.workOrders,
    field: "viewList",
    requiredLevel: TaskViewObjectAccess.ALL
  },
  {
    permission: ObjectPermission.workOrders,
    field: "viewList",
    requiredLevel: TaskViewObjectAccess.ASSIGNED_ONLY
  }
];

const transactionPermissionByTypeMap: {
  [transactionType in JournalEntryType]: ObjectPermission;
} = {
  [JournalEntryType.LEASE_CHARGE]: ObjectPermission.leaseCharges,
  [JournalEntryType.LEASE_PAYMENT]: ObjectPermission.leasePayments,
  [JournalEntryType.LEASE_REVERSED_PAYMENT]: ObjectPermission.leasePayments,
  [JournalEntryType.LEASE_REFUND]: ObjectPermission.leaseRefunds,
  [JournalEntryType.LEASE_CREDIT]: ObjectPermission.leaseCredits,
  [JournalEntryType.VENDOR_BILL]: ObjectPermission.vendorBills,
  [JournalEntryType.VENDOR_BILL_PAYMENT]: ObjectPermission.vendorBillPayments,
  [JournalEntryType.VENDOR_CREDIT]: ObjectPermission.vendorCredits,
  [JournalEntryType.EXPENSE]: ObjectPermission.expenses,
  [JournalEntryType.DEPOSIT]: ObjectPermission.bankDeposits,
  [JournalEntryType.GENERAL_ENTRY]: ObjectPermission.journalEntries,
  [JournalEntryType.TRANSFER]: ObjectPermission.bankTransfers,
  [JournalEntryType.OWNER_CONTRIBUTION]: ObjectPermission.ownerContributions,
  [JournalEntryType.OWNER_DISTRIBUTION]: ObjectPermission.expenses,
  [JournalEntryType.APPLICATION_FEE]: ObjectPermission.applicationFees
};
export const getTransactionPermissionByType = (transactionType: JournalEntryType): ObjectPermission =>
  transactionPermissionByTypeMap[transactionType];

export const allCreateClearances: AnyPermissionClearance[] = [
  ObjectPermission.prospects,
  ObjectPermission.owners,
  ObjectPermission.vendors,
  ObjectPermission.users,

  ObjectPermission.tasks,
  ObjectPermission.workOrders,
  ObjectPermission.ownerRequests,
  ObjectPermission.tenantRequests,

  ObjectPermission.properties,
  ObjectPermission.units,

  ObjectPermission.leases,

  ObjectPermission.leaseCharges,
  ObjectPermission.leasePayments,
  ObjectPermission.leaseCredits,
  ObjectPermission.leaseRefunds,

  ObjectPermission.vendorBills,
  ObjectPermission.vendorBillPayments,
  ObjectPermission.vendorCredits,

  ObjectPermission.ownerContributions,
  ObjectPermission.expenses,

  ObjectPermission.journalEntries,
  ObjectPermission.bankTransfers,
  ObjectPermission.bankDeposits,
  ObjectPermission.expenses
].map((permission) => {
  return { permission, field: "create" };
});

export const buildViewOrEditSettingsClearances = (permission: SettingPermission): AnyPermissionClearance[] => [
  { permission, requiredLevel: SettingsAccessEnum.VIEW },
  { permission, requiredLevel: SettingsAccessEnum.EDIT }
];
