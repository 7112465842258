import moment from "moment";
import Text from "DLUI/text";
import type { FC } from "react";
import React from "react";
import { DateFormats } from "@doorloop/dto";
import type { TextComponentProps } from "DLUI/text/text";
import { TextTooltip } from "DLUI/popover/textTooltip";

interface Props {
  date?: Date | string;
  format?: DateFormats;
  relative?: boolean;
  noTooltip?: boolean;
  tooltipFormat?: DateFormats;
  textProps?: TextComponentProps;
  fontSize?: number;
  dataCy?: string;
}

export const DateLabel: FC<Props> = ({
  date,
  format = DateFormats.ISO_DATE_SERVER_FORMAT,
  relative,
  noTooltip,
  tooltipFormat = DateFormats.THREE_LETTER_MONTH_DATE_AND_TIME,
  textProps,
  fontSize,
  dataCy
}: Props) => {
  if (!date) {
    return null;
  }
  const momentDate = moment(date);
  const renderedText = (
    <Text fontSize={fontSize} {...textProps} data-cy={dataCy}>
      {relative ? momentDate.fromNow() : momentDate.format(format)}
    </Text>
  );
  return noTooltip || format === tooltipFormat ? (
    renderedText
  ) : (
    <TextTooltip value={moment(date).format(tooltipFormat)} rawText>
      {renderedText}
    </TextTooltip>
  );
};
