import { useCallback, useEffect, useRef } from "react";
import { setRef } from "utils/react";

export type RefEffect<T> = (node: T, prev: T | null) => void | ((node: T) => void);

/**
 * This hook allows an effect to be applied when a ref's current value changes, or when the dependencies change.
 *
 * If the effect returns a teardown function, that function will be called with the ref's current value before the next effect is applied.
 *
 * @example
 * ```tsx
 * const ref = useRefEffect((node) => {
 *  node.addEventListener(...)
 *  return () => node.removeEventListener(...)
 * });
 *
 * return <div ref={ref} />
 * ```
 * ```
 *
 * @param effect A callback
 * @returns A React ref. E.g. <div ref={useRefEffect(onTab)} />
 */
export function useRefEffect<T extends HTMLElement = HTMLElement>(effect: RefEffect<T>, deps: any[] = []) {
  const internalRef = useRef<T | null>(null);
  const teardownRef = useRef<void | ((node: T) => void)>();

  // Call teardown when the component unmounts
  useEffect(
    () => () => {
      if (internalRef.current) {
        teardownRef.current?.(internalRef.current);
      }
    },
    []
  );

  // This callback acts as a ref, but also calls the effect when the ref's current value changes
  const ref = useCallback((node: T) => {
    if (internalRef.current) {
      teardownRef.current?.(internalRef.current);
      setRef(teardownRef, undefined);
    }

    // Call the effect
    if (node) {
      teardownRef.current = effect(node, internalRef.current);

      // Callback effects are called with null when the component unmounts, before a new value is assigned
      // We only update the internal ref if the node is not null so we can pass it as the second argument to the effect
      setRef(internalRef, node);
    } else if (!internalRef.current) {
      // If both the node and the internal ref are null, then we've unmounted and assigned null
      setRef(internalRef, null);
    }
  }, deps);

  return ref;
}
