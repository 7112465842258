import { apiHelper } from "./apiHelper";
import { usersApi } from "api/usersApi";
import { accountsApi } from "./accounts";
import type {
  CheckbookApiKeysSettingsDto,
  CheckbookSettingsDto,
  CommunicationCenterSettingsDto,
  CompanyDefaultAccountsSettingsDto,
  CompanyGlobalSettingsDto,
  CompanyInfoSettingsDto,
  CompanyStartDateDto,
  HasTenantsAutoPayments,
  LateFeesPolicyDto,
  LinkedResourceDto,
  ListingsSettingsDto,
  LoginAndPasswordSettingsDto,
  ManagementFeesCompanySettingsDto,
  OwnerPortalSettingsDto,
  PaymentAllocationsSettingsDto,
  PaymentInstructionsSettingsDto,
  PersonalInfoSettingsDto,
  QuickbooksSettingsDto,
  RegionAndCurrencySettingsDto,
  RentalApplicationCompanySettingsDto,
  RentRemindersSettingsDto,
  RequestsSettingsDto,
  TagDto,
  TagType,
  TenantPortalGeneralSettingsDto,
  ConvenienceFeesPolicyDto
} from "@doorloop/dto";
import { FileDto, ServerRoutes } from "@doorloop/dto";
import { store } from "store/index";
import { updateIsFeatureEnabled } from "store/auth/actions";
import type { ApiResult } from "api/apiResult";

export class SettingsApi {
  constructor() {}

  getPersonalInformation = async () => await apiHelper.axiosGet(ServerRoutes.SETTINGS_PERSONAL_INFO);

  updatePersonalInformation = async (data: PersonalInfoSettingsDto) =>
    await apiHelper.axiosPut({ url: ServerRoutes.SETTINGS_PERSONAL_INFO, data });

  getLoginAndPasswordData = async () => await apiHelper.axiosGet(ServerRoutes.SETTINGS_LOGIN_AND_PASSWORD);

  updateLoginAndPasswordData = async (data: LoginAndPasswordSettingsDto) =>
    await apiHelper.axiosPut({ url: ServerRoutes.SETTINGS_LOGIN_AND_PASSWORD, data });

  grantTechAccess = async (allowTechnicianAccess: boolean) =>
    await apiHelper.axiosPut({ url: ServerRoutes.SETTINGS_GRANT_TECH_ACCESS, data: { allowTechnicianAccess } });

  getCompanyInformation = async () => await apiHelper.axiosGet(ServerRoutes.SETTINGS_COMPANY_INFO);

  getTenantPortalSettings = async () => await apiHelper.axiosGet(ServerRoutes.SETTINGS_TENANT_PORTAL);

  async getOwnerPortalSettings(): Promise<ApiResult<OwnerPortalSettingsDto>> {
    return await apiHelper.axiosGet(ServerRoutes.SETTINGS_OWNER_PORTAL);
  }

  async getCommunicationCenterSettings(): Promise<ApiResult<CommunicationCenterSettingsDto>> {
    return await apiHelper.axiosGet(ServerRoutes.SETTINGS_COMMUNICATION_CENTER);
  }

  updateCompanyInformation = async (data: CompanyInfoSettingsDto) =>
    await apiHelper.axiosPut({ url: ServerRoutes.SETTINGS_COMPANY_INFO, data });

  updateTenantPortalSettings = async (data: TenantPortalGeneralSettingsDto) =>
    await apiHelper.axiosPut({ url: ServerRoutes.SETTINGS_TENANT_PORTAL, data });

  async updateOwnerPortalSettings(data: OwnerPortalSettingsDto) {
    return await apiHelper.axiosPut({ url: ServerRoutes.SETTINGS_OWNER_PORTAL, data });
  }

  async updateCommunicationCenterSettings(
    data: CommunicationCenterSettingsDto
  ): Promise<ApiResult<CommunicationCenterSettingsDto>> {
    return await apiHelper.axiosPut({ url: ServerRoutes.SETTINGS_COMMUNICATION_CENTER, data });
  }

  getRegionAndCurrencyData = async () => await apiHelper.axiosGet(ServerRoutes.SETTINGS_REGION_AND_CURRENCY);

  updateRegionAndCurrencyData = async (data: RegionAndCurrencySettingsDto) =>
    await apiHelper.axiosPut({ url: ServerRoutes.SETTINGS_REGION_AND_CURRENCY, data });

  getDefaultAccountsData = async () => {
    await apiHelper.loadDictionariesRequiredForGet([accountsApi]);
    return await apiHelper.axiosGet(ServerRoutes.SETTINGS_DEFAULT_ACCOUNTS);
  };

  updateDefaultAccountsData = async (data: CompanyDefaultAccountsSettingsDto) =>
    await apiHelper.axiosPut({ url: ServerRoutes.SETTINGS_DEFAULT_ACCOUNTS, data });

  getTagsData = async (tagType: TagType) =>
    await apiHelper.axiosGet(ServerRoutes.TAGS, {
      filter_type: tagType
    });

  updateTagsData = async (nextTags: TagDto) =>
    await apiHelper.axiosPut({ url: ServerRoutes.TAGS_POST_CREATE_OR_UPDATE_MANY, data: [nextTags] });

  getCompanyGlobalSettingsData = async () => {
    await apiHelper.loadDictionariesRequiredForGet([usersApi]);

    return await apiHelper.axiosGet(ServerRoutes.SETTINGS_COMPANY_INFO, {});
  };

  getRentReminders = async () => {
    await apiHelper.loadDictionariesRequiredForGet([usersApi]);

    return await apiHelper.axiosGet(ServerRoutes.SETTINGS_RENT_REMINDERS, {});
  };

  updateRentReminders = async (data: RentRemindersSettingsDto) =>
    await apiHelper.axiosPut({ url: ServerRoutes.SETTINGS_RENT_REMINDERS, data });

  getPaymentInstructions = async () => await apiHelper.axiosGet(ServerRoutes.SETTINGS_PAYMENT_INSTRUCTIONS, {});

  getPaymentAllocations = async () => await apiHelper.axiosGet(ServerRoutes.SETTINGS_PAYMENT_ALLOCATIONS, {});

  updatePaymentInstructions = async (data: PaymentInstructionsSettingsDto) =>
    await apiHelper.axiosPut({ url: ServerRoutes.SETTINGS_PAYMENT_INSTRUCTIONS, data });

  updatePaymentAllocations = async (data: PaymentAllocationsSettingsDto) =>
    await apiHelper.axiosPut({ url: ServerRoutes.SETTINGS_PAYMENT_ALLOCATIONS, data });

  getRentalApplications = async () => {
    await apiHelper.loadDictionariesRequiredForGet([usersApi]);
    return await apiHelper.axiosGet(ServerRoutes.SETTINGS_RENTAL_APPLICATIONS, {});
  };

  updateRentalApplications = async (data: RentalApplicationCompanySettingsDto) =>
    await apiHelper.axiosPut({ url: ServerRoutes.SETTINGS_RENTAL_APPLICATIONS, data });

  getTenantRequestsNotifications = async (): Promise<ApiResult<RequestsSettingsDto>> => {
    await apiHelper.loadDictionariesRequiredForGet([usersApi]);

    return await apiHelper.axiosGet(ServerRoutes.SETTINGS_TENANT_REQUESTS, {});
  };

  updateTenantRequestsNotifications = async (data: RequestsSettingsDto) =>
    await apiHelper.axiosPut({ url: ServerRoutes.SETTINGS_TENANT_REQUESTS, data });

  getOwnerRequestsNotifications = async (): Promise<ApiResult<RequestsSettingsDto>> => {
    await apiHelper.loadDictionariesRequiredForGet([usersApi]);

    return await apiHelper.axiosGet(ServerRoutes.SETTINGS_OWNER_REQUESTS, {});
  };

  updateOwnerRequestsNotifications = async (data: RequestsSettingsDto) =>
    await apiHelper.axiosPut({ url: ServerRoutes.SETTINGS_OWNER_REQUESTS, data });

  getLateFeesSettings = async () => await apiHelper.axiosGet(ServerRoutes.SETTINGS_LATE_FEES, {});

  updateLateFeesSettings = async (data: LateFeesPolicyDto) =>
    await apiHelper.axiosPut({ url: ServerRoutes.SETTINGS_LATE_FEES, data });

  getConvenienceFeesSettings = async () => await apiHelper.axiosGet(ServerRoutes.SETTINGS_CONVENIENCE_FEES);

  updateConvenienceFeesSettings = async (data: ConvenienceFeesPolicyDto) =>
    await apiHelper.axiosPut({ url: ServerRoutes.SETTINGS_CONVENIENCE_FEES, data });

  updateCompanyGlobalSettingsData = async (data: CompanyGlobalSettingsDto) =>
    await apiHelper.axiosPut({ url: ServerRoutes.SETTINGS_COMPANY_INFO, data });

  async uploadCompanyLogo(file: File, linkedResourceDto: LinkedResourceDto, serverRoute?: string) {
    const data = new FormData();
    data.append("file", file);
    const fileDto = new FileDto({ rank: 1 });
    fileDto.name = file.name;
    fileDto.linkedResource = linkedResourceDto;

    for (const key of Object.keys(fileDto)) {
      // @ts-ignore
      const value = fileDto[key];
      if (typeof value === "object" && value !== null) {
        for (const objectKey of Object.keys(value)) {
          data.append(key + "[" + objectKey + "]", value[objectKey]);
        }
      } else {
        // @ts-ignore
        data.append(key, fileDto[key]);
      }
    }

    return await apiHelper.axiosPost<FileDto>({ url: serverRoute || ServerRoutes.SETTINGS_COMPANY_LOGO, data });
  }

  async uploadTenantPortalLogo(file: File) {
    const data = new FormData();
    data.append("file", file);

    return await apiHelper.axiosPost<FileDto>({ url: ServerRoutes.SETTINGS_TENANT_PORTAL_LOGO, data });
  }

  async uploadTenantPortalCoverImage(file: File) {
    const data = new FormData();
    data.append("file", file);

    return await apiHelper.axiosPost<FileDto>({ url: ServerRoutes.SETTINGS_TENANT_PORTAL_BACKGROUND, data });
  }

  async uploadOwnerPortalLogo(file: File) {
    const data = new FormData();
    data.append("file", file);

    return await apiHelper.axiosPost<FileDto>({ url: ServerRoutes.SETTINGS_OWNER_PORTAL_LOGO, data });
  }

  async uploadOwnerPortalCoverImage(file: File) {
    const data = new FormData();
    data.append("file", file);

    return await apiHelper.axiosPost<FileDto>({ url: ServerRoutes.SETTINGS_OWNER_PORTAL_BACKGROUND, data });
  }

  async deletePicture(id: string) {
    return await apiHelper.axiosDelete({ url: ServerRoutes.SETTINGS_COMPANY_LOGO });
  }

  async deleteTenantPortalLogo() {
    return await apiHelper.axiosDelete({ url: ServerRoutes.SETTINGS_TENANT_PORTAL_LOGO });
  }

  async deleteTenantPortalCover() {
    return await apiHelper.axiosDelete({ url: ServerRoutes.SETTINGS_TENANT_PORTAL_BACKGROUND });
  }

  async deleteOwnerPortalLogo() {
    return await apiHelper.axiosDelete({ url: ServerRoutes.SETTINGS_OWNER_PORTAL_LOGO });
  }

  async deleteOwnerPortalCover() {
    return await apiHelper.axiosDelete({ url: ServerRoutes.SETTINGS_OWNER_PORTAL_BACKGROUND });
  }

  async getQuickbooksSettings() {
    return await apiHelper.axiosGet(ServerRoutes.SETTINGS_QUICKBOOKS);
  }

  async updateQuickbooksSettings(data: QuickbooksSettingsDto) {
    return await apiHelper.axiosPut({ url: ServerRoutes.SETTINGS_QUICKBOOKS, data });
  }

  async getListingSettings() {
    return await apiHelper.axiosGet<ListingsSettingsDto>(ServerRoutes.SETTINGS_LISTINGS);
  }

  async updateListingSettings(data: ListingsSettingsDto) {
    return await apiHelper.axiosPut({ url: ServerRoutes.SETTINGS_LISTINGS, data });
  }

  async getManagementFeesSettings() {
    return await apiHelper.axiosGet<ManagementFeesCompanySettingsDto>(ServerRoutes.SETTINGS_MANAGEMENT_FEES);
  }

  async getCheckbookIORedirectLink() {
    return await apiHelper.axiosGet<{ redirectUri: string }>(ServerRoutes.SETTINGS_CHECKBOOK_GET_AUTH_URI);
  }

  async getCheckbookSettings() {
    return await apiHelper.axiosGet<CheckbookSettingsDto>(ServerRoutes.SETTINGS_CHECKBOOK);
  }

  async putCheckbookApiKeys(data: CheckbookApiKeysSettingsDto) {
    const response = await apiHelper.axiosPut<CheckbookApiKeysSettingsDto>({
      url: ServerRoutes.SETTINGS_CHECKBOOK_PUT_API_KEYS,
      data
    });

    if (response.status) {
      store.dispatch(updateIsFeatureEnabled(true, "SendPayments"));
    }

    return response;
  }

  async updateManagementFeesSettings(data: ManagementFeesCompanySettingsDto) {
    return await apiHelper.axiosPut({ url: ServerRoutes.SETTINGS_MANAGEMENT_FEES, data });
  }

  async updateCompanyStartDate(data: CompanyStartDateDto) {
    return await apiHelper.axiosPut({ url: ServerRoutes.SETTINGS_COMPANY_START_DATE, data });
  }

  async hasTenantsAutoPayments(): Promise<ApiResult<HasTenantsAutoPayments>> {
    return await apiHelper.axiosGet(ServerRoutes.SETTINGS_TENANT_PORTAL_HAS_TENANTS_AUTO_PAYMENTS);
  }
}

export const settingsApi = new SettingsApi();
