import type { FC, PropsWithChildren } from "react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import type { RecurringContextInterface } from "contexts/recurringContext";
import { RecurringContext, useRecurringContext } from "contexts/recurringContext";
import type { RecurringTransactionDto } from "@doorloop/dto";
import { EventBus } from "utils/eventBus";

type Validator = () => Promise<boolean>;

interface HookInterface {
  setIsRecurring: (isRecurring: boolean) => void;
  setValidator: (validator: Validator) => void;
  setRecurringData: (recurringData: RecurringTransactionDto) => void;
  getIsRecurring: () => boolean | undefined;
  getValidator: () => Validator | undefined;
  getRecurringData: () => RecurringTransactionDto | undefined;
  editMode?: boolean;
  RecurringContextWrapper: FC;
}

export const useRecurringState = (
  editMode = false,
  editingRecurring = false,
  subscribeToIsRecurringChange = false
): HookInterface => {
  const contextObject = useRecurringContext();
  const [, setIsRecurringState] = useState<boolean>(Boolean(contextObject?.isRecurring));
  const payloadCarrierRef = useRef<RecurringContextInterface>({
    editMode,
    isRecurring: editingRecurring,
    eventBus: new EventBus()
  });

  useEffect(() => {
    if (subscribeToIsRecurringChange) {
      contextObject?.eventBus?.on("isRecurringChange", setIsRecurringState);
      return () => contextObject?.eventBus?.off("isRecurringChange", setIsRecurringState);
    }
  }, [setIsRecurringState]);

  return {
    setIsRecurring: (isRecurring) => {
      contextObject?.eventBus?.trigger("isRecurringChange", isRecurring);
      setIsRecurringState(isRecurring);
      if (contextObject) {
        contextObject.isRecurring = isRecurring;
      }
    },
    setValidator: useCallback(
      (validator) => {
        if (contextObject) {
          contextObject.validator = validator;
        }
      },
      [contextObject]
    ),
    setRecurringData: useCallback(
      (recurringData) => {
        if (contextObject) {
          contextObject.recurringData = recurringData;
        } else {
          payloadCarrierRef.current.recurringData = recurringData;
        }
      },
      [contextObject]
    ),
    getIsRecurring: () => contextObject?.isRecurring ?? payloadCarrierRef.current.isRecurring,
    getValidator: () => contextObject?.validator ?? payloadCarrierRef.current.validator,
    getRecurringData: () => contextObject?.recurringData ?? payloadCarrierRef.current.recurringData,
    editMode: contextObject?.editMode,
    RecurringContextWrapper: useCallback(
      ({ children }: PropsWithChildren<{}>) => (
        <RecurringContext.Provider value={payloadCarrierRef.current}>{children}</RecurringContext.Provider>
      ),
      []
    )
  };
};
