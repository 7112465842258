import { propertiesApi } from "api/propertiesApi";
import { unitsApi } from "api/unitsApi";
import {
  ChargeIcon,
  CloseIcon,
  CreaditAddIcon,
  LeaseListIcon,
  MoneyIcon,
  RefoundIcon,
  ResidentPortalIcon,
  TenantIcon
} from "assets/icons";
import { Icon } from "DLUI/icon";
import { Grid, ListItemContainer, ListItemIcon, ListItemSection } from "DLUI/listItems";
import type { PopoverItem } from "DLUI/popover";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { GetAllLeasesQuery, LeaseDto } from "@doorloop/dto";
import { DataCy, ObjectPermission, ServerRoutes } from "@doorloop/dto";
import AppStrings from "locale/keys";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavigationManager } from "utils/navigation";
import { usePermission } from "screens/settings/userRoles/usePermission";
import ListItemText from "DLUI/listItems/listItemText";
import { useParams } from "react-router-dom";
import { authApi } from "api/authApi";
import { Routes } from "@/components/appRouter";
import type { SubscribedActions } from "@/hooks/useListBulkActionsManager";
import { getDatesFromPeriod } from "@/utils/stringsConverter";
import { apiHelper } from "api/apiHelper";
import type { Periods } from "@doorloop/dto";
import { useResponsiveHelper } from "@/contexts/responsiveContext";
import moment from "moment";
import _ from "lodash";
import { Chip, ChipColorsEnum, ChipVariantsEnum } from "DLUI/chip";
import { useGrid } from "@/hooks/useGrid";
import { DaysCounter } from "screens/leases/common/daysCounter";
import { checkDaysLeft } from "@/utils/dateUtils";
import { useConfirmationDialog } from "@/hooks/useConfirmationDialog";

interface ComponentProps {
  leaseDto: LeaseDto;
  resourceId?: string;
  filterObj?: GetAllLeasesQuery;
  onItemPress?: (leaseId: string) => void;
  stickyHeaderId?: string;
  disableListItemOptions?: boolean;
}

export const DefaultListItemIconSize = 20;
export const DefaultListItemFontSize = 14;
export const DefaultCardItemFontSize = 20;
export const DefaultListStatusIconSize = 25;

const defaultPeriodForBulkReport: Periods = "last-90-days";

const LeaseListItem: React.FC<ComponentProps> = ({
  leaseDto,
  stickyHeaderId,
  onItemPress,
  disableListItemOptions,
  resourceId
}: ComponentProps) => {
  const { showConfirmationDialog } = useConfirmationDialog();
  const { tenantId } = useParams<any>();
  const { hasPermission } = usePermission();
  const { isMobile, isOnlyTablet } = useResponsiveHelper();

  const {
    responsiveSizes: { balanceSize, depositSize, leaseSize, rentSize, endDateSize, startDateSize }
  } = useGrid({
    lease: { size: { tablet: 4, lg: 3, xl: 4 } },
    startDate: { size: { tablet: 2 } },
    endDate: { size: { tablet: 2 } },
    rent: { size: { tablet: 2, lg: 1 } },
    deposit: { size: { lg: 2, xl: 1 }, hideIn: ["tablet", "md"] },
    balance: { size: { tablet: 2 } }
  });

  const { t } = useTranslation();

  const didPressReceivePayment = () => {
    NavigationManager.addNewPayment(location.pathname + "/" + leaseDto.id);
  };

  const didPressAddCharge = () => {
    NavigationManager.addNewCharge(location.pathname + "/" + leaseDto.id);
  };

  const didPressGiveRefund = () => {
    NavigationManager.addNewRefund(location.pathname + "/" + leaseDto.id);
  };

  const didPressIssueCredit = () => {
    NavigationManager.addNewCredit(location.pathname + "/" + leaseDto.id);
  };

  const didPressDeleteButton = () => {
    if (leaseDto.id) {
      showConfirmationDialog(location.pathname, {
        dialogTitle: AppStrings.Common.DeleteConfirmation,
        dialogSubTitle: AppStrings.Leases.Screen.DeleteConfirmationText,
        loadingText: t(AppStrings.Leases.Screen.DeleteLoadingText),
        successText: t(AppStrings.Leases.Screen.DeleteSuccessText),
        confirmButtonText: AppStrings.Common.Delete,
        dismissButtonText: AppStrings.Common.Dismiss,
        apiMethod: "leasesApi",
        confirmOperation: "delete",
        itemId: leaseDto.id
      });
    }
  };

  const didPressLoginAsTenantButton = async () => {
    if (leaseDto.id && tenantId) {
      await authApi.getImpersonationTokenForTenantAndLeaseAndOpenNewTabToLogin(tenantId, leaseDto.id);
    }
  };

  const popoverItems: PopoverItem[] = useMemo(() => {
    const popoverItems: PopoverItem[] = [
      {
        Icon: MoneyIcon,
        title: AppStrings.Leases.DraftLease.ReceivePayment,
        onClick: didPressReceivePayment,
        clearance: {
          permission: ObjectPermission.leasePayments,
          field: "create"
        },
        dataCy: DataCy.DLUI.listItem.listItemReceivePayment
      },
      {
        Icon: ChargeIcon,
        title: AppStrings.Leases.DraftLease.AddCharge,
        onClick: didPressAddCharge,
        clearance: {
          permission: ObjectPermission.leaseCharges,
          field: "create"
        },
        dataCy: DataCy.DLUI.listItem.listItemAddCharge
      },
      {
        Icon: RefoundIcon,
        title: AppStrings.Leases.DraftLease.GiveRefund,
        onClick: didPressGiveRefund,
        clearance: {
          permission: ObjectPermission.leaseRefunds,
          field: "create"
        },
        dataCy: DataCy.DLUI.listItem.listItemGiveRefund
      },
      {
        Icon: CreaditAddIcon,
        title: AppStrings.Leases.DraftLease.IssueCredit,
        onClick: didPressIssueCredit,
        clearance: {
          permission: ObjectPermission.leaseCredits,
          field: "create"
        },
        showSeparator: !tenantId,
        dataCy: DataCy.DLUI.listItem.listItemIssueCredit
      }
    ];

    if (tenantId) {
      popoverItems.push({
        Icon: ResidentPortalIcon,
        title: AppStrings.Tenants.Screen.LoginToPortal,
        onClick: didPressLoginAsTenantButton,
        clearance: { permission: ObjectPermission.tenants, field: "edit" },
        showSeparator: true
      });
    }
    popoverItems.push({
      Icon: CloseIcon,
      title: AppStrings.Common.Delete,
      clearance: { permission: ObjectPermission.leases, field: "delete" },
      iconPathColor: "error",
      textColor: "error",
      onClick: didPressDeleteButton,
      dataCy: DataCy.DLUI.listItem.listItemDelete
    });

    return popoverItems;
  }, [tenantId]);

  const didPressViewLeaseButton = hasPermission(ObjectPermission.leases, "viewOne")
    ? () => {
        if (onItemPress) {
          onItemPress(leaseDto.id!);
        } else {
          NavigationManager.viewLeaseDetails(leaseDto.id!);
        }
      }
    : undefined;

  const propertyAndUnitValues = useMemo(
    () =>
      leaseDto.units.map((unitId) => {
        const unitDictionaryItem = unitsApi.tryGetItemFromDictionary(unitId);
        if (!unitDictionaryItem.cacheHit || !unitDictionaryItem.property) {
          return "";
        }

        const propertyDictionaryItem = propertiesApi.tryGetItemFromDictionary(unitDictionaryItem.property as string);
        if (!propertyDictionaryItem.cacheHit || !propertyDictionaryItem.name) {
          return unitDictionaryItem.name as string;
        }

        return `${propertyDictionaryItem?.name} | ${unitDictionaryItem?.name}`;
      }),
    [leaseDto.units]
  );

  const bulkActions: SubscribedActions | undefined = resourceId
    ? {
        printLeaseAccountsReceivable: {
          [resourceId]: {
            action: async () => {
              const { startDate, endDate } = getDatesFromPeriod(defaultPeriodForBulkReport);

              const response = await apiHelper.axiosGet(
                ServerRoutes.REPORTS_LEASE_ACCOUNTS_RECEIVABLE_PDF,
                {
                  filter_lease: leaseDto.id,
                  filter_date_from: startDate,
                  filter_date_to: endDate
                },
                {
                  responseType: "blob"
                }
              );

              return {
                data: response.data,
                message: t(AppStrings.Common.Completed),
                fileName: `${leaseDto.name} - ${propertyAndUnitValues[0]} - Account Statement.pdf`
              };
            },
            ongoingMessage: "Generating reports for " + leaseDto.name
          }
        }
      }
    : undefined;

  const isMovingInSoon =
    !_.isEmpty(leaseDto.start) &&
    moment().isBefore(leaseDto.start) &&
    moment().isAfter(moment(leaseDto.start).subtract(30, "days"));

  const { isEndingSoon, isExpired, isMonthToMonth } = useMemo(() => {
    const { daysCount, isPastDate, noDate: isMonthToMonth } = checkDaysLeft(leaseDto.end);

    return { isEndingSoon: daysCount <= 90 && daysCount >= 0, isExpired: isPastDate, isMonthToMonth };
  }, [leaseDto.end]);

  const balanceLink = useMemo(
    () => `${Routes.ACTIVE_LEASES}/${leaseDto.id}/transactions?period=all-time`,
    [leaseDto.id, leaseDto.units]
  );

  const justifyContentByMode = isMobile ? "flex-end" : "flex-start";

  return (
    <ListItemContainer
      popoverItems={disableListItemOptions ? undefined : popoverItems}
      removeVerticalPadding={isMobile}
      id={stickyHeaderId}
      popoverWidth={220}
      bulkActions={bulkActions}
      resourceId={resourceId}
    >
      <Grid
        mobileMarginBottom={20}
        columnSize={leaseSize}
        title={AppStrings.Leases.ListHeader.Lease}
        showDivider
        onClick={didPressViewLeaseButton}
        sortColumn={"name"}
        isMobileTitleInlineWithContent
      >
        <View flexDirection={"row"} marginTop={isMobile ? 16 : undefined} alignItems={"center"}>
          {!isOnlyTablet && <ListItemIcon Icon={LeaseListIcon} />}

          <ListItemSection
            renderTitle={
              <ListItemText
                bold
                overflow={"ellipsis"}
                numberOfLines={1}
                href={`${Routes.ACTIVE_LEASES}/${leaseDto.id}/overview`}
              >
                {/* If we have more than 1 unit, we show (X units) where X is the count */}
                {propertyAndUnitValues.length > 1 ? (
                  <Text
                    bold
                    color={"black"}
                    fontSize={DefaultListItemFontSize}
                    value={AppStrings.Common.XUnits}
                    replaceObject={{ x: propertyAndUnitValues.length }}
                  />
                ) : (
                  <Text bold color={"black"} fontSize={DefaultListItemFontSize}>
                    {propertyAndUnitValues?.[0] || ""}
                  </Text>
                )}
              </ListItemText>
            }
            renderSubTitle={
              <View justifyContent={"flex-start"} alignItems={"center"} flexDirection={"row"} noWrap>
                <Icon
                  Source={TenantIcon}
                  width={DefaultListItemIconSize}
                  height={DefaultListItemIconSize}
                  marginRight={5}
                />
                <View flex={1} flexDirection={"row"} noWrap>
                  <ListItemText color={"gray"} overflow={"ellipsis"} numberOfLines={1}>
                    {leaseDto.name}
                  </ListItemText>
                </View>
              </View>
            }
          />
        </View>
      </Grid>

      <Grid
        mobileMarginBottom={20}
        title={AppStrings.Leases.NewLease.LeaseOverview.TermsSection.Dates.StartDate}
        showDivider
        columnSize={startDateSize}
        onClick={didPressViewLeaseButton}
        sortColumn={"start"}
        isMobileTitleInlineWithContent
      >
        <View marginBottom={isMovingInSoon ? 0 : -15} width={"auto"}>
          <ListItemSection
            fullWidth={false}
            renderTitle={
              <View flexDirection={"row"} justifyContent={justifyContentByMode} alignItems={"center"}>
                <Text
                  color={"black"}
                  align="left"
                  fontSize={DefaultListItemFontSize}
                  value={leaseDto.start}
                  formatType={"date"}
                />
              </View>
            }
            renderSubTitle={
              <View flexDirection={"row"} justifyContent={isMobile ? "flex-end" : undefined} minHeight={10}>
                {isMovingInSoon && (
                  <View justifyContent={justifyContentByMode} alignItems={"center"} flexDirection={"row"}>
                    <DaysCounter
                      dueDate={leaseDto.start}
                      prefixText={AppStrings.Leases.Screen.MoveIn}
                      color={"lightBlue"}
                    />
                  </View>
                )}
              </View>
            }
          />
        </View>
      </Grid>
      <Grid
        mobileMarginBottom={20}
        title={AppStrings.Leases.NewLease.LeaseOverview.TermsSection.Dates.EndDate}
        showDivider
        columnSize={endDateSize}
        onClick={didPressViewLeaseButton}
        sortColumn={"end"}
        isMobileTitleInlineWithContent
      >
        <View
          flexDirection={"row"}
          marginBottom={isEndingSoon || isExpired ? 0 : -15}
          width={"auto"}
          justifyContent={justifyContentByMode}
        >
          <ListItemSection
            fullWidth={false}
            renderTitle={
              <View
                flexDirection={"row"}
                justifyContent={leaseDto.end ? justifyContentByMode : "center"}
                alignItems={"center"}
              >
                {leaseDto.end ? (
                  <Text
                    color={"black"}
                    align="left"
                    fontSize={DefaultListItemFontSize}
                    value={leaseDto.end}
                    formatType={"date"}
                  />
                ) : (
                  <Text
                    align="center"
                    fontSize={12}
                    fontWeight={400}
                    color={"black"}
                    variant={"span"}
                    marginTop={3}
                    value={AppStrings.Leases.ExistingLease.MonthToMonth}
                  />
                )}
              </View>
            }
            renderSubTitle={
              <View flexDirection={"row"} justifyContent={isMobile ? "flex-end" : undefined} minHeight={10}>
                {isEndingSoon && !isMonthToMonth ? (
                  <DaysCounter
                    dueDate={leaseDto.end}
                    prefixText={AppStrings.Leases.ExistingLease.Expiring}
                    color={"orange"}
                  />
                ) : (
                  isExpired && (
                    <Text color={"error"} bold fontSize={14} value={AppStrings.Common.OutgoingPayments.Expired} />
                  )
                )}
              </View>
            }
          />
        </View>
      </Grid>
      <Grid
        mobileMarginBottom={20}
        title={AppStrings.Leases.ListHeader.Rent}
        showDivider
        columnSize={rentSize}
        onClick={didPressViewLeaseButton}
        sortColumn={"totalRecurringRent"}
        alignTitle={"end"}
        isMobileTitleInlineWithContent
      >
        <View
          flexDirection={"row"}
          marginRight={isMobile ? 0 : 10}
          alignItems={"center"}
          justifyContent={"flex-end"}
          flex={1}
        >
          {leaseDto.recurringRentFrequency && (
            <ListItemText
              fullWidth={false}
              color={"black"}
              value={leaseDto.totalRecurringRent?.toString()}
              formatType={"currency"}
              overflow={"visible"}
            />
          )}
        </View>
      </Grid>
      <Grid
        mobileMarginBottom={20}
        title={AppStrings.Leases.ListHeader.Deposits}
        showDivider
        columnSize={depositSize}
        onClick={didPressViewLeaseButton}
        sortColumn={"totalDepositsHeld"}
        alignTitle={"end"}
        isMobileTitleInlineWithContent
      >
        <View marginRight={isMobile ? 0 : 10} justifyContent={"flex-end"} flex={1} flexDirection={"row"} noWrap>
          <Text
            color={"black"}
            fontSize={DefaultListItemFontSize}
            value={leaseDto.totalDepositsHeld?.toString()}
            formatType={"currency"}
          />
        </View>
      </Grid>

      <Grid
        mobileMarginBottom={20}
        title={AppStrings.Leases.ListHeader.Balance}
        columnSize={balanceSize}
        sortColumn={"totalBalanceDue"}
        alignTitle={"end"}
        isMobileTitleInlineWithContent
      >
        <View
          flexDirection={"column"}
          width={isMobile ? "auto" : undefined}
          noWrap
          alignItems={"flex-end"}
          justifyContent={"center"}
          height={"100%"}
        >
          <ListItemText
            autoWidth
            openInThisTab
            href={balanceLink}
            color={leaseDto.totalBalanceDue && leaseDto.totalBalanceDue > 0 ? "error" : "green"}
            fontWeight={700}
            value={leaseDto.totalBalanceDue?.toString() || ""}
            formatType={"currency"}
          />
          {leaseDto.evictionPending && (
            <Chip
              isRectangle
              style={{ marginTop: 5, height: 22 }}
              variant={ChipVariantsEnum.DEFAULT}
              color={ChipColorsEnum.DANGER}
              labelProps={{
                isBold: true,
                translationKey: AppStrings.Overview.Eviction
              }}
            />
          )}
        </View>
      </Grid>
    </ListItemContainer>
  );
};

export { LeaseListItem };
