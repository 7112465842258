"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyndicationILS_Identification = exports.SyndicationILS_IdentificationType = void 0;
const class_validator_1 = require("class-validator");
const syndicationAvailabilityDateType_1 = require("./syndicationAvailabilityDateType");
const class_transformer_1 = require("class-transformer");
var SyndicationILS_IdentificationType;
(function (SyndicationILS_IdentificationType) {
    SyndicationILS_IdentificationType["APARTMENT"] = "Apartment";
    SyndicationILS_IdentificationType["CONDO"] = "Condo";
    SyndicationILS_IdentificationType["DUPLEX"] = "Duplex";
    SyndicationILS_IdentificationType["TRIPLEX"] = "Triplex";
    SyndicationILS_IdentificationType["FOURPLEX"] = "4plex";
    SyndicationILS_IdentificationType["SENIOR"] = "Senior";
    SyndicationILS_IdentificationType["ASSISTED_LIVING"] = "Assisted Living";
    SyndicationILS_IdentificationType["SUBSIDIZED"] = "Subsidized";
    SyndicationILS_IdentificationType["HIGH_RISE"] = "High Rise";
    SyndicationILS_IdentificationType["GARDEN_STYLE"] = "Garden Style";
    SyndicationILS_IdentificationType["MID_RISE"] = "Mid Rise";
    SyndicationILS_IdentificationType["MIXED_USE"] = "Mixed Use";
    SyndicationILS_IdentificationType["TOWNHOUSE"] = "Townhouse";
    SyndicationILS_IdentificationType["VACATION"] = "Vacation";
    SyndicationILS_IdentificationType["CAMPUS"] = "Campus";
    SyndicationILS_IdentificationType["MILITARY"] = "Military";
    SyndicationILS_IdentificationType["HOUSE_FOR_RENT"] = "House for Rent";
    SyndicationILS_IdentificationType["CORPORATE"] = "Corporate";
    SyndicationILS_IdentificationType["UNSPECIFIED"] = "Unspecified";
    SyndicationILS_IdentificationType["RESIDENTIAL_SINGLE_FAMILY"] = "Single-Family";
    SyndicationILS_IdentificationType["RESIDENTIAL_MULTI_FAMILY"] = "Multi-Family";
    SyndicationILS_IdentificationType["RESIDENTIAL_CONDO"] = "Condo";
    SyndicationILS_IdentificationType["RESIDENTIAL_TOWNHOME"] = "Townhome";
    SyndicationILS_IdentificationType["RESIDENTIAL_OTHER"] = "Other";
    SyndicationILS_IdentificationType["COMMERCIAL_OTHER"] = "Other";
    SyndicationILS_IdentificationType["COMMERCIAL_INDUSTRIAL"] = "Industrial";
    SyndicationILS_IdentificationType["COMMERCIAL_OFFICE"] = "Office";
    SyndicationILS_IdentificationType["COMMERCIAL_RETAIL"] = "Retail";
    SyndicationILS_IdentificationType["COMMERCIAL_SHOPPING_CENTER"] = "Shopping Center";
    SyndicationILS_IdentificationType["COMMERCIAL_STORAGE"] = "Storage";
    SyndicationILS_IdentificationType["COMMERCIAL_PARKING"] = "Parking";
    SyndicationILS_IdentificationType["COMMERCIAL_CO_WORKING"] = "Co Working";
})(SyndicationILS_IdentificationType || (exports.SyndicationILS_IdentificationType = SyndicationILS_IdentificationType = {}));
class SyndicationILS_Identification {
}
exports.SyndicationILS_Identification = SyndicationILS_Identification;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(SyndicationILS_IdentificationType),
    __metadata("design:type", String)
], SyndicationILS_Identification.prototype, "_ILS_IdentificationType", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], SyndicationILS_Identification.prototype, "_RentalType", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], SyndicationILS_Identification.prototype, "Latitude", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], SyndicationILS_Identification.prototype, "Longitude", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], SyndicationILS_Identification.prototype, "DaylightSaving", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SyndicationILS_Identification.prototype, "TimeZone", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => syndicationAvailabilityDateType_1.SyndicationAvailabilityDateType),
    __metadata("design:type", syndicationAvailabilityDateType_1.SyndicationAvailabilityDateType)
], SyndicationILS_Identification.prototype, "LastUpdate", void 0);
