import { outstandingTransactionsApi } from "api/outstandingTransactionsApi";
import { NoOutstandingTransactionIcon } from "assets/icons";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { LoadingDialog } from "DLUI/dialogs/components/loading";
import { Icon } from "DLUI/icon";
import { SectionTitle } from "DLUI/screen";
import { StickyHeader } from "DLUI/stickyHeader";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { LeaseOutstandingTransactionDto, LeasePaymentDto } from "@doorloop/dto";
import type { FormikProps } from "formik";
import { getIn } from "formik";
import AppStrings from "locale/keys";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OutstandingTransactionsListItem from "./outstandingTransactionsListItem";
import { DataCy } from "@doorloop/dto";
import { OutstandingChargeTransactions } from "./outstandingChargeTransactions";

interface OutstandingTransactionsListProps {
  shouldLoad: boolean;
  leaseId?: string;
  leasePaymentId?: string;
  amountReceived: number;
  formikRef: FormikProps<LeasePaymentDto>;
}

const OutstandingTransactionsList: React.FC<OutstandingTransactionsListProps> = ({
  shouldLoad,
  leaseId,
  leasePaymentId,
  amountReceived,
  formikRef
}) => {
  const { t } = useTranslation();
  let outstandingCharges: LeaseOutstandingTransactionDto[] = [];
  let outstandingCredits: LeaseOutstandingTransactionDto[] = [];

  outstandingCharges = getIn(formikRef.values, "linkedCharges");
  outstandingCredits = getIn(formikRef.values, "linkedCredits");

  const [loadingDataDialogState, setDataLoadingDialogState] = useState<DialogState>(DialogState.Show);
  const [loadTransactionErrorText, setLoadTransactionErrorText] = useState<string>(
    t(AppStrings.Units.NewUnitDialog.ErrorText)
  );

  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [renderLines, setRenderLines] = useState<boolean>(true);

  useEffect(() => {
    if (Boolean(shouldLoad) && !dataLoaded) {
      setTimeout(() => {
        loadTransactions();
      }, 300);
    }
  }, [shouldLoad]);

  useEffect(() => {
    if (dataLoaded) {
      formikRef.setFieldValue("linkedCharges", undefined);
      formikRef.setFieldValue("linkedCredits", undefined);
      if (leaseId) {
        setDataLoaded(false);
        loadTransactions();
      }
    }
  }, [leaseId]);

  useEffect(() => {
    if (loadingDataDialogState === DialogState.Success) {
      setTimeout(() => {
        setDataLoadingDialogState(DialogState.Hidden);
      }, 300);
    }
  }, [loadingDataDialogState]);

  useEffect(() => {
    if (dataLoaded) {
      setRenderLines(false);
      setTimeout(() => {
        setRenderLines(true);
      }, 0);
    }
  }, [dataLoaded]);

  const loadTransactions = async () => {
    if (leaseId) {
      setDataLoadingDialogState(DialogState.Show);
      const response = await outstandingTransactionsApi
        .getOutstandingTransactions(leaseId, leasePaymentId)
        .catch((e) => {
          setLoadTransactionErrorText(e);
        });
      if (response && response.status && response.data) {
        setDataLoadingDialogState(DialogState.Success);
        formikRef.setFieldValue("linkedCharges", response.data.outstandingCharges);
        formikRef.setFieldValue("linkedCredits", response.data.outstandingCredits);

        setDataLoaded(true);
      } else {
        setDataLoadingDialogState(DialogState.Error);
        setLoadTransactionErrorText(response && response.message ? response.message : "");
      }
    }
  };

  const onRetryLoadInfoButtonPress = () => {};

  const renderLoadingView = () => {
    if (loadingDataDialogState === DialogState.Hidden) {
      return null;
    }
    return (
      <LoadingDialog
        dialogState={loadingDataDialogState}
        loadingText={t(AppStrings.Leases.LeaseTransaction.Payment.LoadingOutstandingTransactions)}
        errorText={loadTransactionErrorText}
        successText={""}
        onRetryButtonPress={onRetryLoadInfoButtonPress}
        hideDismissButton
      />
    );
  };

  const renderCreditLines = () => {
    if (!renderLines) {
      return null;
    }
    if (outstandingCredits && outstandingCredits.length > 0) {
      let outstandingCreditsLines: JSX.Element[] = [];
      outstandingCreditsLines = outstandingCredits.map((currentItem: LeaseOutstandingTransactionDto, index: number) => (
        <div style={{ width: "100%" }} key={"OCL" + index}>
          <OutstandingTransactionsListItem
            type={"credit"}
            id={"outstandingRentTransactions"}
            accountId={currentItem.account || ""}
            transactionDate={currentItem.linkedTransactionDate || ""}
            name={`linkedCredits[${index}]`}
            formikRef={formikRef}
            transactionId={currentItem.linkedTransaction!}
            leaseId={leaseId!}
            reference={currentItem.linkedTransactionReference}
          />
        </div>
      ));
      return (
        <View marginTop={20}>
          <StickyHeader removeVerticalPadding id={"outstandingRentTransactions"} shouldShow />
          {outstandingCreditsLines}
        </View>
      );
    }
  };

  const getLinesSum = () => {
    let amountToApply = 0;
    let amountToCredit = 0;

    let sumLinkedCredits = 0;
    let sumLinkedCharges = 0;

    const outstandingCharges = formikRef.values.linkedCharges;
    const outstandingCredits = formikRef.values.linkedCredits;
    if (outstandingCharges) {
      outstandingCharges.forEach(
        (currentBill: LeaseOutstandingTransactionDto) => (sumLinkedCharges += currentBill.amount || 0)
      );
    }
    if (outstandingCredits) {
      outstandingCredits.forEach(
        (currentCredit: LeaseOutstandingTransactionDto) => (sumLinkedCredits += currentCredit.amount || 0)
      );
    }
    amountToApply = amountReceived + sumLinkedCredits;
    amountToCredit = amountToApply - sumLinkedCharges;

    return { amountToApply, amountToCredit };
  };

  const renderSummary = () => {
    if (
      outstandingCharges &&
      outstandingCharges.length === 0 &&
      outstandingCredits &&
      outstandingCredits.length === 0
    ) {
      return null;
    }

    const { amountToApply, amountToCredit } = getLinesSum();

    return (
      <View marginTop={20} paddingRight={20}>
        <View marginBottom={20} justifyContent={"flex-end"} flexDirection={"row"}>
          <Text value={AppStrings.Leases.LeaseTransaction.Payment.AmountToApply} color={"black"} fontSize={16} />
          <View alignItems={"flex-end"} width={120}>
            <Text value={String(amountToApply)} color={"black"} fontSize={16} formatType={"currency"} />
          </View>
        </View>
        <View marginBottom={20} justifyContent={"flex-end"} flexDirection={"row"}>
          <Text value={AppStrings.Leases.LeaseTransaction.Payment.AmountToCredit} color={"black"} fontSize={16} />
          <View alignItems={"flex-end"} width={120}>
            <Text value={String(amountToCredit)} color={"black"} fontSize={16} formatType={"currency"} />
          </View>
        </View>
      </View>
    );
  };

  if (!shouldLoad) {
    return null;
  }

  if (!leaseId) {
    return (
      <View
        justifyContent={"center"}
        alignItems={"center"}
        minHeight={250}
        dataCy={DataCy.DLUI.noOutstandingTransactionsMessage}
      >
        <Icon Source={NoOutstandingTransactionIcon} />
        <Text
          marginTop={20}
          fontSize={24}
          fontWeight={700}
          color={"black"}
          value={AppStrings.Leases.LeaseCharge.SelectLease}
        />
      </View>
    );
  }

  if (
    shouldLoad &&
    dataLoaded &&
    outstandingCharges &&
    outstandingCharges.length === 0 &&
    outstandingCredits &&
    outstandingCredits.length === 0
  ) {
    // data was loaded with no transactions to display
    // let's show the empty data view
    return (
      <View
        justifyContent={"center"}
        alignItems={"center"}
        minHeight={250}
        dataCy={DataCy.DLUI.noOutstandingTransactionsMessage}
      >
        <Icon Source={NoOutstandingTransactionIcon} />
        <Text
          marginTop={20}
          fontSize={24}
          fontWeight={700}
          color={"black"}
          value={AppStrings.Leases.LeaseTransaction.Payment.NoOutstandingTransactions}
        />
      </View>
    );
  }

  return (
    <View alignItems={"center"} justifyContent={"center"} minHeight={250}>
      <View>
        {loadingDataDialogState === DialogState.Show ? null : (
          <SectionTitle
            title={AppStrings.Leases.LeaseTransaction.Payment.OutstandingTransactions}
            type={"underline"}
            marginTop={20}
          />
        )}
      </View>
      {renderLoadingView()}
      {renderLines && (
        <OutstandingChargeTransactions
          formikRef={formikRef}
          leaseId={leaseId}
          outstandingCharges={outstandingCharges}
        />
      )}
      {renderCreditLines()}
      {renderSummary()}
    </View>
  );
};

export default OutstandingTransactionsList;
