import {
  AgendaIcon,
  BadgeIcon,
  ClockLightGrayIcon,
  EmergencyIcon,
  MarketingIcon,
  MenuExtraLocationIcon,
  MenuLocationIcon,
  OutgoingPaymentsIcon,
  PeopleIcon,
  PetsIcon,
  VehicleIcon
} from "assets/icons";
import type { MenuItem } from "DLUI/dialogs";
import { ContactInfo, EmergencyContact, PersonAddress } from "DLUI/person";
import AppStrings from "locale/keys";
import React from "react";
import { MarketingProfile } from "screens/prospects/newProspect/components";
import { PersonOutgoingPaymentsTab } from "screens/outgoingPayments/personOutgoingPaymentsTab";
import { SettingPermission, SettingsAccessEnum } from "@doorloop/dto";
import { PetsListForm } from "DLUI/person/pets/petsListForm";
import { VehicleListForm } from "DLUI/person/vehicles/vehicleListFields";
import { DependentListForm } from "DLUI/person/dependents/dependentListFields";
import BoardMembershipForm from "screens/tenants/boardMember/boardMembershipForm";
import { filterObjectsByUserType } from "../../../../utils/userTypesUtils";
import { PersonInfoWithPortal } from "DLUI/person/personInfoWithPortal";

export const SHARED_TENANT_FIELDS: MenuItem[] = [
  {
    title: AppStrings.Tenants.NewTenant.Pets,
    icon: PetsIcon,
    contentComponent: () => <PetsListForm name={"pets"} isControlled={false} />
  },
  {
    title: AppStrings.Tenants.NewTenant.Vehicles,
    icon: VehicleIcon,
    contentComponent: () => <VehicleListForm name={"vehicles"} isControlled={false} />
  },
  {
    title: AppStrings.Tenants.NewTenant.Dependents,
    icon: PeopleIcon,
    contentComponent: () => <DependentListForm name={"dependants"} isControlled={false} />
  }
];

export const getTenantFormMenuItems = (hideTenantPortalInvite?: boolean, isHOAUser?: boolean): MenuItem[] => {
  const menuItems: MenuItem[] = [
    {
      title: AppStrings.Tenants.NewTenant.PersonalInfo,
      icon: BadgeIcon,
      contentComponent: (formikRef, onFileReceive, onFileDelete) => (
        <PersonInfoWithPortal
          personType={"tenant"}
          hidePortalInvite={hideTenantPortalInvite}
          formikRef={formikRef}
          onFileReceive={onFileReceive}
          onFileDelete={onFileDelete}
        />
      ),
      uploadFile: true
    },
    {
      title: AppStrings.Tenants.NewTenant.ContactInfo,
      icon: AgendaIcon,
      contentComponent: (formikRef) => <ContactInfo formikRef={formikRef} />
    },
    {
      title: AppStrings.Tenants.NewTenant.Address,
      icon: MenuLocationIcon,
      contentComponent: (formikRef) => (
        <PersonAddress
          formikName={"primaryAddress"}
          title={AppStrings.Tenants.NewTenant.PrimaryAddress}
          formikRef={formikRef}
        />
      )
    },
    {
      title: AppStrings.Tenants.NewTenant.AlternateAddress,
      icon: MenuExtraLocationIcon,
      contentComponent: (formikRef) => (
        <PersonAddress
          formikName={"alternateAddress"}
          title={AppStrings.Tenants.NewTenant.AlternateAddress}
          formikRef={formikRef}
        />
      )
    },
    {
      title: AppStrings.Tenants.NewTenant.EmergencyContact,
      icon: EmergencyIcon,
      contentComponent: (formikRef) => <EmergencyContact formikRef={formikRef} />
    },
    ...(isHOAUser
      ? [
          {
            title: AppStrings.Common.Hoa.BoardMembership,
            icon: OutgoingPaymentsIcon,
            contentComponent: (formikRef) => <BoardMembershipForm isEdit />
          }
        ]
      : []),
    {
      title: AppStrings.Prospects.NewProspect.MarketingProfile,
      icon: MarketingIcon,
      hiddenForUserTypes: ["HOAUser"],
      contentComponent: (formikRef) => <MarketingProfile formikRef={formikRef} />
    },
    ...SHARED_TENANT_FIELDS,
    {
      title: AppStrings.Common.OutgoingPayments.OutgoingPayments,
      icon: ClockLightGrayIcon,
      contentComponent: () => <PersonOutgoingPaymentsTab personType={"tenant"} />,
      clearances: [
        {
          permission: SettingPermission.tenantsEPayInfo,
          requiredLevel: SettingsAccessEnum.VIEW
        },
        {
          permission: SettingPermission.tenantsEPayInfo,
          requiredLevel: SettingsAccessEnum.EDIT
        }
      ]
    }
  ];

  return !isHOAUser ? menuItems : filterObjectsByUserType(menuItems, "HOAUser");
};
