import React, { useMemo } from "react";
import { View } from "DLUI/view";
import type { FillColors } from "DLUI/icon";
import { Icon } from "DLUI/icon";
import type { SVGIconComponent } from "assets/icons";
import { AlertDangerIcon, AlertInfoIcon, AlertSuccessIcon, AlertWarningIcon } from "assets/icons";
import type { ViewBackgroundColor } from "DLUI/view/view";

import type { ReplaceObjectInterface, TextComponentProps } from "DLUI/text/text";
import Text from "DLUI/text/text";
import ColorsEnum from "utils/colorsEnum";
import { Grid } from "@material-ui/core";
import { SeparationLine } from "DLUI/separatorView";
import type { JustifyContentFlexType } from "DLUI/text/types";

interface ComponentProps {
  containerWidth?: string | number;
  /**
   * @deprecated Use title, subtitle, or children instead
   */
  renderContent?: () => React.ReactNode;
  minHeight?: number;
  marginTop?: number;
  marginBottom?: number;
  dataCy?: string;
  children?: React.ReactNode;
  type?: "warning" | "info" | "danger" | "success";
  title?: string;
  subTitle?: string;
  subTitleReplaceObject?: ReplaceObjectInterface;
  iconSize?: number;
  pathColor?: FillColors;
  /**
   * It is not recommended to use this prop. Please use the type prop instead.
   */
  overrideTextColor?: ColorsEnum;
  justifyContent?: JustifyContentFlexType;
  separationLineHeight?: string | number;
  separationLineColor?: ColorsEnum;
  borderRadius?: number;
  titleTextProps?: TextComponentProps;
}

const defaultType = "warning";

const WarningView: React.FC<ComponentProps> = ({
  containerWidth,
  renderContent,
  children,
  minHeight,
  type = defaultType,
  marginTop,
  marginBottom,
  dataCy,
  title,
  subTitle,
  subTitleReplaceObject,
  overrideTextColor,
  iconSize = 40,
  pathColor,
  justifyContent = "flex-start",
  separationLineHeight,
  separationLineColor,
  borderRadius = 10,
  titleTextProps
}: ComponentProps) => {
  const backgroundColor = useMemo((): ViewBackgroundColor => {
    const map: Record<Exclude<ComponentProps["type"], undefined>, ViewBackgroundColor> = {
      warning: "yellow-light",
      info: "blue-lighter",
      danger: "error-red",
      success: "lighter-green"
    };
    return map?.[type ?? defaultType];
  }, [type]);

  const icon = useMemo((): SVGIconComponent => {
    const map: Record<Exclude<ComponentProps["type"], undefined>, SVGIconComponent> = {
      warning: AlertWarningIcon,
      info: AlertInfoIcon,
      danger: AlertDangerIcon,
      success: AlertSuccessIcon
    };
    return map?.[type ?? defaultType];
  }, [type]);

  const textColor = useMemo((): ColorsEnum => {
    if (overrideTextColor) return overrideTextColor;

    const map: Record<Exclude<ComponentProps["type"], undefined>, ColorsEnum> = {
      warning: ColorsEnum.Black,
      info: ColorsEnum.DarkBlue,
      danger: ColorsEnum.Red,
      success: ColorsEnum.Black
    };
    return map?.[type ?? defaultType];
  }, [type, overrideTextColor]);

  return (
    <View
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      width={containerWidth || "100%"}
      minHeight={minHeight}
      marginTop={marginTop || 0}
      marginBottom={marginBottom}
      paddingBottom={12}
      paddingTop={12}
      paddingLeft={12}
      paddingRight={12}
      justifyContent={justifyContent}
    >
      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={"auto"}>
          <Grid container item justifyContent="center" alignItems="center" xs style={{ height: "100%" }}>
            <Icon Source={icon} size={iconSize} pathColor={pathColor} />
          </Grid>
        </Grid>
        <SeparationLine
          color={separationLineColor}
          height={separationLineHeight}
          width={1}
          marginTop={10}
          marginBottom={10}
        />
        <Grid container item direction="column" justifyContent="center" xs style={{ gap: 3 }} data-cy={dataCy}>
          {title && <Text fullWidth colorEnum={textColor} value={title} fontSize={16} bold {...titleTextProps} />}
          {subTitle && (
            <Text
              fullWidth
              whiteSpace="pre-line"
              colorEnum={textColor}
              value={subTitle}
              fontSize={14}
              replaceObject={subTitleReplaceObject}
            />
          )}
          {renderContent ? renderContent() : children}
        </Grid>
      </Grid>
    </View>
  );
};

export default WarningView;
