import React, { useCallback, useMemo, useRef } from "react";
import { TextField } from "DLUI/form";
import type { FormikProps } from "formik";
import { Field, useFormikContext } from "formik";
import AppStrings from "../../../../../../locale/keys";
import { useTranslation } from "react-i18next";
import AddressAutoComplete from "./addressAutoComplete";
import Grid from "@material-ui/core/Grid";
import { View } from "DLUI/view";
import FieldWrapper from "DLUI/form/textField/fieldWrapper";
import type { AddressDto } from "@doorloop/dto";
import { DataCy, regionNames } from "@doorloop/dto";
import { autoSelectAddress } from "./utils";
import _, { get } from "lodash";
import { useDebounce } from "../../../../../../hooks/useDebounce";
import type {
  InternationalAndUS_SuggestionsType,
  LookupArgType,
  VerificationResultType
} from "DLUI/form/autoComplete/addressAutoComplete/smarty/dataSource";
import DataSource from "DLUI/form/autoComplete/addressAutoComplete/smarty/dataSource";
import { shallowEqual, useSelector } from "react-redux";
import type { IRootState } from "store/index";
import WarningView from "DLUI/form/warningView/warningView";
import Text from "DLUI/text";
import ColorsEnum from "../../../../../../utils/colorsEnum";
import { ActionIconLink } from "DLUI/link";
import CountryAutoComplete from "DLUI/form/autoComplete/addressAutoComplete/smarty/countryAutoComplete";
import type { OptionType } from "DLUI/form/autoComplete/autoComplete";
import { useEffectAsync } from "../../../../../../hooks/useEffectAsync";
import { countriesOptions } from "DLUI/form/autoComplete/addressAutoComplete/addressAutoCompleteFormWrapper";
import { StateAddress } from "DLUI/form/autoComplete/addressAutoComplete/stateAddress";

export interface SmartyAddressAutoCompleteFormProps {
  formikName: string;
  formikRef?: FormikProps<any>;
  disabled?: boolean;
  didSelectAutoCompleteAddress?: (selectedPlace: any) => void;
  fullWidthAddresses?: boolean;
  dataCy?: string;
  showOnlyStreet?: boolean;
  street1PlaceHolderLabel?: string;
  withAddressVerification?: boolean;
  mustAcceptInvalidAddress?: boolean;
  checkAddressVerificationOnFirstRun?: boolean;
  street1IsRequired?: boolean;
  required?: boolean;
  handleChangeSearchAddressEngine?: (engineType: "google" | "smarty") => void;
}

export interface CountriesListInterface {
  name: string;
  language: string;
  country: string;
  iso3: string;
}

export const smartySupportedCountriesOptions: OptionType[] = (
  _.uniqBy(regionNames, "country") as CountriesListInterface[]
)
  .filter(({ iso3 }) => iso3)
  .map(({ country, iso3 }) => {
    return {
      displayName: country,
      value: iso3
    };
  });

const getCountryObj = (formikValues, formikPath): OptionType | undefined => {
  const currentFormikCountry = get(formikValues, `${formikPath}.country`);

  return (
    currentFormikCountry &&
    smartySupportedCountriesOptions.find(({ displayName }) => displayName === currentFormikCountry)
  );
};

export const TYPING_DEBOUNCE_AMOUNT = 300;

const SmartyAddressAutoCompleteForm: React.FC<SmartyAddressAutoCompleteFormProps> = ({
  disabled = false,
  didSelectAutoCompleteAddress,
  formikName,
  fullWidthAddresses,
  dataCy,
  showOnlyStreet,
  street1PlaceHolderLabel = AppStrings.Properties.Address.Labels.Street1,
  withAddressVerification,
  mustAcceptInvalidAddress,
  required = true,
  checkAddressVerificationOnFirstRun = false,
  street1IsRequired = true,
  handleChangeSearchAddressEngine
}: SmartyAddressAutoCompleteFormProps) => {
  const streetsGrid = fullWidthAddresses ? 12 : 6;
  const cityStateCountryZipGrid = fullWidthAddresses ? 3 : 6;

  const isFirstRunRef = useRef(!checkAddressVerificationOnFirstRun);
  const { t } = useTranslation();
  const formik = useFormikContext();
  const debounceAddressValue: AddressDto = useDebounce(get(formik.values, formikName), TYPING_DEBOUNCE_AMOUNT);
  const smartyIso3Code: string = useSelector((state: IRootState) => {
    let iso3Code = "USA";
    const countryObj = getCountryObj(formik.values, formikName);

    if (countryObj) {
      iso3Code = countryObj.value;
    } else {
      const iso3 = (regionNames as CountriesListInterface[]).find(
        ({ iso3, name }) => iso3 && name === state.auth.currentLoginResponse?.currentDbTenant.language
      )?.iso3;

      if (iso3) {
        iso3Code = iso3;
      }
    }

    return iso3Code;
  }, shallowEqual);

  const initialCountry = useMemo(() => {
    const countryObj = getCountryObj(formik.values, formikName)?.value;

    return countryObj || smartySupportedCountriesOptions.find(({ value }) => value === smartyIso3Code);
  }, []);

  const autoCompleteDataSource = useMemo<DataSource>(() => {
    const selectedCountry = getCountryObj(formik.values, formikName);

    return new DataSource(selectedCountry?.value || smartyIso3Code);
  }, [smartyIso3Code, get(formik.values, `${formikName}.country`)]);
  const { street1, zip, country, city, street2 } = debounceAddressValue || {};

  const acceptInvalidAddress = get(formik.values, `${formikName}.acceptInvalidAddress`);
  const shouldShowAcceptInvalidAddressNotification = acceptInvalidAddress !== undefined && !acceptInvalidAddress;

  const handleAddressChange = useCallback(
    async (selectedAddress: InternationalAndUS_SuggestionsType & LookupArgType) => {
      if (selectedAddress && formik) {
        autoSelectAddress(selectedAddress, formik, formikName, smartyIso3Code);
      }

      didSelectAutoCompleteAddress?.(selectedAddress);
    },
    [smartyIso3Code]
  );

  const handleAddressVerification = async (): Promise<VerificationResultType> =>
    await autoCompleteDataSource.verifyAddress(debounceAddressValue);

  const handleAcceptNotVerifyAddress = () => {
    formik.setFieldValue(`${formikName}.acceptInvalidAddress`, true);
  };

  useEffectAsync(async () => {
    if (withAddressVerification) {
      if (!isFirstRunRef.current) {
        if (street1 && zip && country && city) {
          const { isValid, lat, lng } = await handleAddressVerification();

          formik.setFieldValue(`${formikName}.isValidAddress`, isValid);
          formik.setFieldValue(`${formikName}.lat`, lat);
          formik.setFieldValue(`${formikName}.lng`, lng);

          if (mustAcceptInvalidAddress) {
            formik.setFieldValue(`${formikName}.acceptInvalidAddress`, isValid);
          }
        }
      } else {
        isFirstRunRef.current = false;
      }
    }
  }, [street1, street2, zip, country, city, isFirstRunRef.current]);

  return (
    <View>
      {showOnlyStreet ? (
        <Field
          component={AddressAutoComplete}
          disabled={disabled}
          showSelectedOptionFullAddress
          paddingRight={0}
          paddingLeft={0}
          mainObjFormikName={formikName}
          name={`${formikName}.street1`}
          label={t(street1PlaceHolderLabel)}
          required={street1IsRequired}
          fullWidth
          onAddressAutocomplete={handleAddressChange}
          serviceApi={autoCompleteDataSource}
          size={"100%"}
          data-cy={DataCy.addressInformation.street1}
        />
      ) : (
        <>
          <View flexDirection={"row"} data-cy={dataCy}>
            <Grid item xs={12} sm={streetsGrid} md={streetsGrid} lg={streetsGrid}>
              <FieldWrapper marginTop={0} type={fullWidthAddresses ? "singleField" : "endElement"}>
                <Field
                  component={AddressAutoComplete}
                  paddingRight={0}
                  paddingLeft={0}
                  mainObjFormikName={formikName}
                  name={`${formikName}.street1`}
                  label={t(AppStrings.Properties.Address.Labels.Street1)}
                  required
                  fullWidth
                  onAddressAutocomplete={handleAddressChange}
                  serviceApi={autoCompleteDataSource}
                  size={"100%"}
                  data-cy={DataCy.addressInformation.street1}
                  disabled={disabled}
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={12} sm={streetsGrid} md={streetsGrid} lg={streetsGrid}>
              <FieldWrapper type={fullWidthAddresses ? "singleField" : "startElement"}>
                <Field
                  component={TextField}
                  name={`${formikName}.street2`}
                  label={t(AppStrings.Properties.Address.Labels.Street2)}
                  fullWidth
                  size={"100%"}
                  data-cy={DataCy.addressInformation.street2}
                  disabled={disabled}
                />
              </FieldWrapper>
            </Grid>
          </View>
          <View flexDirection={"row"}>
            <Grid item xs={12} sm={cityStateCountryZipGrid} md={cityStateCountryZipGrid} lg={cityStateCountryZipGrid}>
              <FieldWrapper type={"endElement"}>
                <Field
                  component={TextField}
                  name={`${formikName}.city`}
                  label={t(AppStrings.Properties.Address.Labels.City)}
                  required
                  size={"100%"}
                  data-cy={DataCy.addressInformation.city}
                  disabled={disabled}
                />
              </FieldWrapper>
            </Grid>

            <Grid item xs={12} sm={cityStateCountryZipGrid} md={cityStateCountryZipGrid} lg={cityStateCountryZipGrid}>
              <FieldWrapper type={fullWidthAddresses ? "middleElement" : "startElement"}>
                <StateAddress
                  formikName={formikName}
                  disabled={disabled}
                  handleChangeSearchAddressEngine={handleChangeSearchAddressEngine}
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={12} sm={cityStateCountryZipGrid} md={cityStateCountryZipGrid} lg={cityStateCountryZipGrid}>
              <FieldWrapper type={fullWidthAddresses ? "middleElement" : "endElement"}>
                <Field
                  component={CountryAutoComplete}
                  handleChangeSearchAddressEngine={handleChangeSearchAddressEngine}
                  uniqueId={"AutoCompleteCountryAddress"}
                  name={`${formikName}.country`}
                  label={t(AppStrings.Properties.Address.Labels.Country)}
                  required
                  data-cy={DataCy.addressInformation.country}
                  values={countriesOptions}
                  defaultValue={initialCountry}
                  isAddressDefined={Boolean(formik.values?.[formikName])}
                  disabled={disabled}
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={12} sm={cityStateCountryZipGrid} md={cityStateCountryZipGrid} lg={cityStateCountryZipGrid}>
              <FieldWrapper type={"startElement"}>
                <Field
                  component={TextField}
                  name={`${formikName}.zip`}
                  label={t(AppStrings.Properties.Address.Labels.ZipCode)}
                  size={"100%"}
                  data-cy={DataCy.addressInformation.zip}
                  required
                  disabled={disabled}
                />
              </FieldWrapper>
            </Grid>
          </View>
          <View flexDirection={"row"}>
            {shouldShowAcceptInvalidAddressNotification && (
              <WarningView type={"danger"} iconSize={25} marginTop={20}>
                <View>
                  <Text
                    marginBottom={5}
                    colorEnum={ColorsEnum.Red}
                    bold
                    value={AppStrings.Properties.Address.AcceptInvalidAddressWarningTitle}
                  />
                  <Text
                    colorEnum={ColorsEnum.Red}
                    value={AppStrings.Properties.Address.AcceptInvalidAddressWarningDescription}
                  />
                  <ActionIconLink
                    linkText={AppStrings.Properties.Address.UseThisAddressAnyway}
                    onClick={handleAcceptNotVerifyAddress}
                  />
                </View>
              </WarningView>
            )}
          </View>
        </>
      )}
    </View>
  );
};

export default SmartyAddressAutoCompleteForm;
