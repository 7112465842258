import React, { useMemo } from "react";

import _ from "lodash";

import type { Props as ReactPhoneNumberInputProps } from "react-phone-number-input/input";
import ReactPhoneInput from "react-phone-number-input/input";
import type { Country } from "react-phone-number-input";
import type { CountryCode } from "libphonenumber-js";
import { parsePhoneNumber } from "libphonenumber-js";
import { useSelector } from "react-redux";

import TextInput from "../textField/textInput/textInput";
import type { IRootState } from "store/index";
import type { TextInputProps } from "../textField/textInput";

export type PhoneNumberInputProps = Omit<ReactPhoneNumberInputProps<TextInputProps>, "onChange"> & {
  onChange: (value: string | undefined) => void;
};

const parsePhoneNumberInputValue = (value = "", defaultCountry = "") => {
  try {
    const parsed = parsePhoneNumber(value, defaultCountry as CountryCode);

    const number = parsed?.number || "";

    const isValid = parsed?.isValid() || false;

    return {
      isValid,
      parsedValue: isValid && !_.isEmpty(number) ? number : value
    };
  } catch (error) {
    return {
      isValid: false,
      parsedValue: value
    };
  }
};

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = (props) => {
  const localeData = useSelector((state: IRootState) => state.auth.localeData);

  const defaultCountry = localeData?.locale?.split("-")?.[1] as Country;

  const { parsedValue, isValid } = useMemo(
    () => parsePhoneNumberInputValue(props.value?.toString(), defaultCountry),
    []
  );

  return (
    <ReactPhoneInput
      {...props}
      value={parsedValue}
      defaultCountry={_.isEmpty(parsedValue) || isValid ? defaultCountry : undefined}
      onChange={(value) => {
        props.onChange(value);
      }}
      inputComponent={TextInput}
    />
  );
};
