"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPropertyTitle = void 0;
const getPropertyTitle = (propertyName, unitName) => {
    const property = propertyName || "";
    const unit = unitName || "";
    const separator = propertyName && unitName ? " > " : "";
    return `${property}${separator}${unit}`;
};
exports.getPropertyTitle = getPropertyTitle;
