"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAllLeaseCreditsQuery = void 0;
const getAllLeaseTransactionsBase_query_1 = require("../shared/getAllLeaseTransactionsBase.query");
class GetAllLeaseCreditsQuery extends getAllLeaseTransactionsBase_query_1.GetAllLeaseTransactionsBaseQuery {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.GetAllLeaseCreditsQuery = GetAllLeaseCreditsQuery;
