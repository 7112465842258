"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OnetimeToRecurringMapper = void 0;
const tasks_1 = require("../tasks");
const recurringTransaction_dto_1 = require("../recurringTransactions/recurringTransaction.dto");
const recurringTaskInfo_dto_1 = require("../recurringTransactions/recurringTaskInfo.dto");
const recurringVendorBillInfo_dto_1 = require("../recurringTransactions/recurringVendorBillInfo.dto");
const recurringExpenseInfo_dto_1 = require("../recurringTransactions/recurringExpenseInfo.dto");
const vendorBill_dto_1 = require("../journalEntries/vendorBills/vendorBill.dto");
const expense_dto_1 = require("../journalEntries/expenses/expense.dto");
const generalEntryInfo_dto_1 = require("./generalEntryInfo.dto");
exports.OnetimeToRecurringMapper = {
    buildRecurring: {
        task: (taskDto, recurringData) => new recurringTransaction_dto_1.RecurringTransactionDto(Object.assign(Object.assign({}, recurringData), { reference: taskDto.reference, taskInfo: new recurringTaskInfo_dto_1.RecurringTaskInfoDto(Object.assign({}, taskDto)) })),
        generalEntry: (generalEntryDto, recurringData) => new recurringTransaction_dto_1.RecurringTransactionDto(Object.assign(Object.assign({}, recurringData), { reference: generalEntryDto.reference, generalEntryInfo: new generalEntryInfo_dto_1.GeneralEntryInfoDto(generalEntryDto), memo: generalEntryDto === null || generalEntryDto === void 0 ? void 0 : generalEntryDto.memo })),
        vendorBill: (vendorBillDto, recurringData) => new recurringTransaction_dto_1.RecurringTransactionDto(Object.assign(Object.assign({}, recurringData), { reference: vendorBillDto.reference, vendorBillInfo: new recurringVendorBillInfo_dto_1.RecurringVendorBillInfoDto(Object.assign({}, vendorBillDto)) })),
        expense: (expenseDto, recurringData) => new recurringTransaction_dto_1.RecurringTransactionDto(Object.assign(Object.assign({}, recurringData), { reference: expenseDto.reference, expenseInfo: new recurringExpenseInfo_dto_1.RecurringExpenseInfoDto(Object.assign({}, expenseDto)) }))
    },
    buildOnetime: {
        task: (recurringTask) => new tasks_1.TaskDto(Object.assign(Object.assign({}, recurringTask.taskInfo), { reference: recurringTask.reference })),
        vendorBill: (recurringVendorBill) => new vendorBill_dto_1.VendorBillDto(Object.assign(Object.assign({}, recurringVendorBill.vendorBillInfo), { reference: recurringVendorBill.reference })),
        expense: (recurringExpense) => new expense_dto_1.ExpenseDto(Object.assign(Object.assign({}, recurringExpense.expenseInfo), { reference: recurringExpense.reference }))
    }
};
