import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { ListingsSettingsDto } from "@doorloop/dto";
import { ListingSyndicationSettingsDialog } from "screens/settings/listingSyndication/listingSyndicationSettingsDialog";
import { settingsApi } from "api/settingsApi";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import AppStrings from "../../../../locale/keys";
import { useTranslation } from "react-i18next";
import { View } from "DLUI/view";
import { createValidator } from "@doorloop/dto";

export const ListingSyndicationDialogFrameHeight = Math.min(window.innerHeight, 900);
export const ListingSyndicationDialogFrameWidth = Math.min(window.innerWidth, 640);

interface Props {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
}

const validateForm = createValidator(ListingsSettingsDto);

const ListingSyndicationFormikContextWrapper: React.FC<Props> = ({ onClose, onBackdropClick, dialogTitle }: Props) => {
  const { t } = useTranslation();
  const [settingsData, setSettingsData] = useState<ListingsSettingsDto>(new ListingsSettingsDto());
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.Show);
  const [errorText, setErrorText] = useState<string>("");

  const showErrorMessage = (error: string) => {
    setDialogState(DialogState.Error);
    setErrorText(error);
  };

  const loadData = async () => {
    const { data, message, status } = await settingsApi.getListingSettings();
    if (data && status) {
      setSettingsData(data);
      setDialogState(DialogState.Hidden);
    } else {
      setDialogState(DialogState.Hidden);
      showErrorMessage(message || t(AppStrings.Common.GeneralError));
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  if (dialogState !== DialogState.Hidden) {
    return (
      <View
        width={ListingSyndicationDialogFrameWidth}
        height={ListingSyndicationDialogFrameHeight}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <LoadingDialog
          dialogState={dialogState}
          errorText={errorText}
          didPressDismissButton={onBackdropClick}
          onRetryButtonPress={loadData}
          minHeight={ListingSyndicationDialogFrameHeight}
        />
      </View>
    );
  }

  return (
    <Formik initialValues={settingsData} onSubmit={() => {}} validate={validateForm}>
      <ListingSyndicationSettingsDialog onBackdropClick={onBackdropClick} dialogTitle={dialogTitle} onClose={onClose} />
    </Formik>
  );
};

export default ListingSyndicationFormikContextWrapper;
