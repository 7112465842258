import { propertiesApi } from "api/propertiesApi";
import type { DialogState } from "DLUI/dialogs/loadingDialog";
import { AnimatedContentDialog } from "DLUI/dialogs";
import AppStrings from "locale/keys";
import React, { useCallback, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { UnitDto } from "@doorloop/dto";
import { PropertyClass, ObjectPermission, LinkedResourceType } from "@doorloop/dto";
import { unitsApi } from "api/unitsApi";
import { getUnitFormMenuItems } from "DLUI/dialogs/components/editUnit/editUnitMenuItems";
import type { OperationsBuilder } from "engines/bulkOperationsEngine";
import { initFormValues, validateForm, validateUnitForm } from "./formikHelper";
import { useParams } from "react-router-dom";
import { getDialogFrameDimension } from "../dialogFrame";
import { useUserType } from "../../../../../hooks/useUserType";
import { useRUG, RUGContext } from "@/components/common/pictureGallery/pictureGallery.hooks";

interface ComponentProps {
  dialogState: DialogState;
  onBackdropClick: () => void;
  onClose: () => void;
  title?: string;
}

const EditUnitDialog: React.FC<ComponentProps> = ({ dialogState, onBackdropClick, onClose, title }: ComponentProps) => {
  const { t } = useTranslation();
  const { propertyId, unitId } = useParams<{ propertyId: string; unitId: string }>();
  const [propertyClass, setPropertyClass] = useState<PropertyClass | undefined>();
  const [operationsBuilder, setOperationsBuilder] = useState<OperationsBuilder | undefined>();

  const operationsBuilderSetter = useCallback(
    (builder: OperationsBuilder) => setOperationsBuilder(() => builder),
    [setOperationsBuilder]
  );

  const rugValue = useRUG({
    resourceId: unitId,
    resourceType: LinkedResourceType.Unit,
    onOperationsChange: operationsBuilderSetter
  });

  const { isHOAUser } = useUserType();

  const updatePropertyClass = useCallback(
    async (propertyId: string) => {
      const { data } = await propertiesApi.getPropertyDetails(propertyId);
      setPropertyClass(data?.class);
    },
    [setPropertyClass]
  );

  const editUnitDialogItems = useMemo(
    () =>
      getUnitFormMenuItems(updatePropertyClass, propertyClass, isHOAUser).filter(
        ({ category }) => propertyClass !== PropertyClass.COMMERCIAL || !category
      ),
    [isHOAUser, propertyClass, updatePropertyClass]
  );

  return (
    <RUGContext.Provider value={rugValue}>
      <AnimatedContentDialog
        dialogState={dialogState}
        onClose={onClose}
        sectionItems={editUnitDialogItems}
        disableBackdropClick
        onBackdropClick={onBackdropClick}
        getformikInitialValues={() => initFormValues(propertyId, unitId)}
        formikValidation={validateForm}
        validationMethod={validateUnitForm}
        loadingText={t(AppStrings.Users.NewUser.LoadingText)}
        errorText={t(AppStrings.Users.NewUser.ErrorText)}
        successText={t(AppStrings.Users.NewUser.SuccessText)}
        apiMethod={unitsApi}
        dialogTitle={title || ""}
        dialogHeight={getDialogFrameDimension("height", 810)}
        dialogWidth={getDialogFrameDimension("width", 1200)}
        permission={ObjectPermission.units}
        onDataLoad={async (data: UnitDto) => {
          data.property && (await updatePropertyClass(data.property));
        }}
        bulkOperationsBuilder={operationsBuilder}
      />
    </RUGContext.Provider>
  );
};

export default EditUnitDialog;
