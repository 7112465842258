import type { FC } from "react";
import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import {
  AnnouncementEmailIcon,
  AnnouncementPhoneIcon,
  AnnouncementPortalIcon,
  AnnouncementSmsIcon,
  RoundDollarIcon2
} from "assets/icons";
import type { AnnouncementReviewDto } from "@doorloop/dto";
import { Notification } from "DLUI/notification/notification";
import { ReviewRow } from "screens/announcements/newAnnouncement/review/reviewRow";
import { ValidationIndicator } from "DLUI/form";
import { useTranslation } from "react-i18next";
import CreditNotification from "screens/announcements/newAnnouncement/review/creditNotification";
import ColorsEnum from "../../../../../utils/colorsEnum";

interface Props {
  reviewData: AnnouncementReviewDto;
}

export const ReviewAnnouncement: FC<Props> = ({
  reviewData: {
    emails = 0,
    texts = 0,
    phoneCalls = 0,
    portalMessages = 0,
    chargeAmount = 0,
    chargeQuantity = 0,
    creditsLeft = 0,
    creditsToBeUsed = 0
  }
}: Props) => {
  const { t } = useTranslation();
  const nothingToSend = emails + texts + phoneCalls + portalMessages === 0;

  return (
    <View>
      <Text value={AppStrings.Announcements.reviewAnnouncement} marginBottom={10} fontSize={18} bold />
      <Text value={AppStrings.Announcements.thisAnnouncementWillBeDeliveredVia} fontSize={16} marginBottom={20} />
      <View marginBottom={20}>
        <ReviewRow
          RowIcon={AnnouncementEmailIcon}
          title={AppStrings.Announcements.email}
          summaryText={AppStrings.Announcements.xEmailsWillBeSent}
          summaryAmount={emails}
        />
        <ReviewRow
          RowIcon={AnnouncementSmsIcon}
          title={AppStrings.Announcements.textMessage}
          summaryText={AppStrings.Announcements.xTextMessagesWillBeSent}
          summaryAmount={texts}
        />
        <ReviewRow
          RowIcon={AnnouncementPhoneIcon}
          title={AppStrings.Announcements.phoneCall}
          summaryText={AppStrings.Announcements.xPhoneCallsWillBeMade}
          summaryAmount={phoneCalls}
        />
        <ReviewRow
          RowIcon={AnnouncementPortalIcon}
          title={AppStrings.Announcements.tenantPortal}
          summaryText={AppStrings.Announcements.xMessagesWillBePostedOnTenantPortals}
          summaryAmount={portalMessages}
          showPortalIcon
          isLast
        />
      </View>
      {creditsToBeUsed > creditsLeft && (
        <CreditNotification creditsLeft={creditsLeft} creditsToBeUsed={creditsToBeUsed} nothingToSend={nothingToSend} />
      )}
      <Notification
        label={AppStrings.Announcements.creditsAdditionalChargeMessage}
        replaceObject={{
          chargeAmount,
          chargeQuantity
        }}
        fontSize={16}
        background={ColorsEnum.LightYellowFaded}
        IconComponent={RoundDollarIcon2}
        iconSize={50}
        separatorColor={ColorsEnum.YellowFaded}
        shouldShow={creditsToBeUsed > creditsLeft}
      />
      <ValidationIndicator
        fullWidth
        shouldShow={nothingToSend}
        displayText={t(AppStrings.Announcements.nothingToSendError)}
        justifyContent={"center"}
      />
    </View>
  );
};
