import React, { useMemo } from "react";
import { stripeApi } from "api/stripeApi";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";

import { IMessageBubble } from "../../../../../../assets";
import { StripeNeedAssistance } from "./components/StripeNeedAssistance";

import { useDialogStripeOnboarding } from "./hooks/useDialogStripeOnboarding";
import { StripeConnectDialogContent } from "screens/incomingPayments/tabs/merchantAccountSetup/stripeOnbording/components/stripeConnectDialogContent";
import { useTypedTranslation } from "locale";
import { NavigationManager } from "utils/navigation";
import type { StripeConnectPoints } from "screens/incomingPayments/tabs/merchantAccountSetup/stripeOnboarding.types";

export const StripeUpdateMerchantAccount = () => {
  const { t } = useTypedTranslation();
  const {
    DialogViews,
    bankAccount,
    loadingDialogState,
    loadingDialogErrorText,
    viewIndex,
    setLoadingDialogState,
    showErrorMessage,
    dialogHeight,
    dialogWidth
  } = useDialogStripeOnboarding();

  const handleBackdropClick = () => {
    NavigationManager.viewMerchantAccountSetup();
  };

  const handleSubmit = async () => {
    setLoadingDialogState(DialogState.Show);

    if (bankAccount?.id) {
      const response = await stripeApi.postStripeAccountLink(bankAccount.id);

      if (response?.data?.url) {
        window.open(response?.data?.url, "_self");
      } else {
        showErrorMessage(response.message || t("common.generalError"));
        setLoadingDialogState(DialogState.Error);
      }
    } else {
      showErrorMessage(t("accounts.merchantAccountNotFound"));
      setLoadingDialogState(DialogState.Error);
    }
  };

  const updatePoints: StripeConnectPoints[] = useMemo(
    () => [
      {
        translation: "accounts.stripeActionRequiredOtherBulletI",
        translationOptions: {
          firstName: bankAccount?.merchantAccountRepresentativeFirstName,
          lastName: bankAccount?.merchantAccountRepresentativeLastName
        }
      },
      {
        translation: "accounts.stripeActionRequiredOtherBulletII",
        translationOptions: {
          firstName: bankAccount?.merchantAccountRepresentativeFirstName
        }
      },
      {
        component: <StripeNeedAssistance />,
        iconSrc: IMessageBubble
      }
    ],
    [bankAccount?.merchantAccountRepresentativeFirstName, bankAccount?.merchantAccountRepresentativeLastName]
  );

  const renderView = ({ index }: { index: number }) => {
    if (index === DialogViews.LoadingView) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          errorText={loadingDialogErrorText}
          onRetryButtonPress={handleSubmit}
          didPressDismissButton={handleBackdropClick}
        />
      );
    }

    if (index === DialogViews.ContentView && bankAccount) {
      return (
        <StripeConnectDialogContent
          onBackdropClick={handleBackdropClick}
          onSubmit={handleSubmit}
          isLoading={loadingDialogState === DialogState.Show}
          account={bankAccount}
          points={updatePoints}
          mode={"update"}
        />
      );
    }

    return null;
  };

  return (
    <DialogFrame
      useExperimentalDialogFrame
      onCloseButtonClick={handleBackdropClick}
      renderView={renderView}
      height={dialogHeight}
      width={dialogWidth}
      numViews={2}
      activeView={viewIndex}
      frameType={"contentOnly"}
    />
  );
};
