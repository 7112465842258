import type { FC } from "react";
import React from "react";
import clsx from "clsx";
import { View } from "DLUI/view";
import ColorsEnum from "../../../../utils/colorsEnum";
import { DataCy } from "@doorloop/dto";

interface Props {
  totalNotifications: number;
  maxTotalNotificationToShow?: number;
  onTheBell?: boolean;
  isSmallScreen?: boolean;
  classNames?: any[];
}

const UnreadNotificationsBubble: FC<Props> = ({
  onTheBell,
  isSmallScreen,
  totalNotifications,
  classNames = [],
  maxTotalNotificationToShow
}: Props) => (
  <View
    className={clsx(classNames)}
    backgroundColorEnum={ColorsEnum.Pink}
    flexDirection={"row"}
    autoWidth
    height={20}
    justifyContent={"center"}
    alignItems={"center"}
    borderRadius={50}
    dataCy={DataCy.DLUI.notifications.unreadNotificationBubble}
    style={{
      color: ColorsEnum.White,
      marginLeft: "auto",
      fontWeight: "900",
      fontSize: 10,
      position: onTheBell ? "absolute" : "static",
      top: isSmallScreen ? 5 : 0,
      padding: "0 6.5px",
      textAlign: "center"
    }}
  >
    {maxTotalNotificationToShow && totalNotifications > maxTotalNotificationToShow
      ? `${maxTotalNotificationToShow}+`
      : totalNotifications}
  </View>
);

export default UnreadNotificationsBubble;
