import Text from "DLUI/text";
import AppStrings from "locale/keys";
import _ from "lodash";

import { View } from "DLUI/view";
import { useFormikContext } from "formik";
import { useEffect, useRef } from "react";
import { PropertySubTypeItem } from "screens/properties/newProperty/components/propertyType/propertySubTypeItem";

import type { SubTypeSelectorProps } from "./propertyType.types";

export function ResidentialSubTypeSelector({
  propertyTypeItems,
  onPropertySubTypeSelectionRef,
  name,
  typeDescriptionName
}: SubTypeSelectorProps) {
  const formikContext = useFormikContext();

  const clearSelection = () => {
    formikContext.setFieldValue(name, undefined);
    formikContext.setFieldValue(typeDescriptionName, undefined);
  };

  const componentRef = useRef({
    clearSelection
  });

  useEffect(() => {
    onPropertySubTypeSelectionRef(componentRef);
  }, [onPropertySubTypeSelectionRef]);

  const onSelectItem = (itemIndex: number) => {
    formikContext.setFieldValue(name, propertyTypeItems[itemIndex].value);
  };

  if (_.isEmpty(propertyTypeItems)) {
    return null;
  }

  return (
    <View marginTop={20} alignItems={"center"} noWrap>
      <Text
        align={"left"}
        value={AppStrings.Properties.Screen.ChoosePropertySubTypelabel}
        color={"gray"}
        fontSize={16}
      />
      <View noWrap gap={8} marginTop={16}>
        {propertyTypeItems.map((item, index) => (
          <PropertySubTypeItem
            key={index}
            itemIndex={index}
            onItemPress={onSelectItem}
            name={name}
            typeDescriptionName={typeDescriptionName}
            item={item}
          />
        ))}
      </View>
    </View>
  );
}
