import { useEffect, useState } from "react";
import type { LocalSearchItem } from "../constants/localSearchData";
import { getLocalSearchData } from "../constants/localSearchData";
import { useTranslation } from "react-i18next";
import { usePermission } from "screens/settings/userRoles/usePermission";
import _ from "lodash";
import { useUserType } from "./useUserType";
import AppStrings from "../locale/keys";
import { atom, useAtom } from "jotai";
import type { NavSelectionProps } from "DLUI/drawer/types";

const itemTypeToPrefixMap: Record<LocalSearchItem["type"], string | null> = {
  action: AppStrings.Common.CreateNew,
  report: null,
  page: null
};

const newInTheMiddleLocalSearchItemTitles: Record<string, string> = {
  [AppStrings.Leases.LeaseCharge.OneTimeCharge]: AppStrings.Leases.LeaseCharge.CreateNewOneTimeCharge,
  [AppStrings.Leases.DraftLease.ReceivePayment]: AppStrings.Leases.DraftLease.CreateNewReceivePayment,
  [AppStrings.Leases.LeaseCharge.OneTimeCredit]: AppStrings.Leases.LeaseCharge.CreateNewOneTimeCredit,
  [AppStrings.Leases.LeaseCharge.OneTimeRefund]: AppStrings.Leases.LeaseCharge.CreateNewOneTimeRefund,
  [AppStrings.Leases.LeaseTransaction.Refund.WithholdDeposit]:
    AppStrings.Leases.LeaseTransaction.Refund.CreateNewWithholdDeposit,
  [AppStrings.Vendors.VendorDetails.CreateBill]: AppStrings.Vendors.VendorDetails.CreateNewBill,
  [AppStrings.Vendors.VendorDetails.PayBill]: AppStrings.Vendors.VendorDetails.CreateNewPayBill,
  [AppStrings.Vendors.VendorDetails.AddCredit]: AppStrings.Vendors.VendorDetails.CreateNewAddCredit
};

export const selectedLocalNavigation = atom<NavSelectionProps | null>(null);

export const useLocalGlobalSearch = (searchTerm?: string) => {
  const { isHOAUser } = useUserType();
  const searchItems = getLocalSearchData(isHOAUser);
  const { t } = useTranslation();
  const [selectedNavigationKey, setSelectedNavigationKey] = useAtom(selectedLocalNavigation);

  const { hasPermission, hasClearance } = usePermission();

  const [filteredData, setFilteredData] = useState<LocalSearchItem[]>([]);

  const getItemTitle = (title: string, type: LocalSearchItem["type"]) => {
    const newInTheMiddleTitle = newInTheMiddleLocalSearchItemTitles[title];
    if (newInTheMiddleTitle) {
      return t(newInTheMiddleTitle);
    }

    const prefix = itemTypeToPrefixMap[type];
    if (prefix) {
      return `${t(prefix)} ${t(title)}`;
    }

    return t(title);
  };

  const checkIsAllowed = (item: LocalSearchItem) => {
    if (item.clearances) {
      const clearance = _.isEmpty(item.clearances) || !item.clearances.some((clearance) => !hasClearance(clearance));

      if (!clearance) {
        return false;
      }
    }

    if (item.permissions) {
      return _.isEmpty(item.permissions) || !item.permissions.some((permissions) => !hasPermission(permissions));
    }

    return true;
  };

  const checkIsMatch = (item: LocalSearchItem, searchTerm: string): boolean =>
    getItemTitle(item.title, item.type).toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.tags.some((tag) => t(tag).toLowerCase().includes(searchTerm.toLowerCase()));

  useEffect(() => {
    getSearchedData(searchTerm);
  }, [searchTerm]);

  const resetResults = () => {
    setFilteredData([]);
  };

  const getSearchedData = (searchTerm?: string) => {
    let data: LocalSearchItem[] = [];

    if (!_.isEmpty(searchTerm)) {
      data = searchItems.filter((item) => checkIsMatch(item, searchTerm!)).filter(checkIsAllowed);
    }

    setFilteredData(data);
    return data;
  };

  const selectItem = (item: LocalSearchItem) => {
    if (item.navigation) {
      setSelectedNavigationKey(item.navigation);
    }
  };

  return {
    searchTerm,
    selectItem,
    filteredData,
    resetResults,
    getSearchedData,
    getItemTitle
  };
};
