import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    componentContainer: {
      boxShadow: "0px -4px 14px rgba(0, 0, 0, 0.04)",
      backgroundColor: theme.palette.screenBackground.main
    },
    navigationPanelHelperContainer: {
      position: "relative",
      userSelect: "none"
    },
    navigationPanelBannerHelperContainer: {
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
      transition: "all .3s",
      textAlign: "center",
      position: "absolute",
      bottom: "20px",
      zIndex: 11,
      [theme.breakpoints.down(1025)]: {
        left: "10px",
        bottom: "40px"
      }
    },
    navigationPanelBannerHelperOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: 10,
      opacity: 0.9
    },
    navigationPanelHelperSentence: {
      transition: "all .3s",
      "&:hover": {
        opacity: 0.8
      }
    },
    bannerHelperFadeIn: {
      transform: "translateY(0)",
      opacity: 1
    },
    bannerHelperFadeOut: {
      transform: "translateY(20px)",
      opacity: 0
    }
  })
);
