"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountOpeningBalanceInfo = exports.AccountOpeningBalanceByPropertyInfo = exports.PropertyBalanceEqualsAccountBalance = void 0;
const isDateStringWithoutTime_validator_1 = require("../common/validators/isDateStringWithoutTime.validator");
const mathUtils_1 = require("../utils/mathUtils");
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
let PropertyBalanceEqualsAccountBalance = class PropertyBalanceEqualsAccountBalance {
    validate(balanceByProperty, args) {
        return args.object.openingBalance === mathUtils_1.mathUtils.sumBy(balanceByProperty, (x) => x.openingBalance || 0);
    }
    defaultMessage(args) {
        // here you can provide default error message if validation failed
        return "The account balance must be equal to the sum of all property balances.";
    }
};
exports.PropertyBalanceEqualsAccountBalance = PropertyBalanceEqualsAccountBalance;
exports.PropertyBalanceEqualsAccountBalance = PropertyBalanceEqualsAccountBalance = __decorate([
    (0, class_validator_1.ValidatorConstraint)({ name: "customText", async: false })
], PropertyBalanceEqualsAccountBalance);
class AccountOpeningBalanceByPropertyInfo {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.AccountOpeningBalanceByPropertyInfo = AccountOpeningBalanceByPropertyInfo;
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], AccountOpeningBalanceByPropertyInfo.prototype, "property", void 0);
__decorate([
    (0, class_validator_1.IsNumber)({ maxDecimalPlaces: 2 }),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", Number)
], AccountOpeningBalanceByPropertyInfo.prototype, "openingBalance", void 0);
class AccountOpeningBalanceInfo {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.AccountOpeningBalanceInfo = AccountOpeningBalanceInfo;
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.openingBalanceDate),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsNumber)({ maxDecimalPlaces: 2 }),
    __metadata("design:type", Number)
], AccountOpeningBalanceInfo.prototype, "openingBalance", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.balance),
    (0, class_validator_1.IsNotEmpty)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], AccountOpeningBalanceInfo.prototype, "openingBalanceDate", void 0);
__decorate([
    (0, class_validator_1.IsArray)(),
    (0, class_transformer_1.Type)(() => AccountOpeningBalanceByPropertyInfo),
    (0, class_validator_1.Validate)(PropertyBalanceEqualsAccountBalance),
    (0, class_validator_1.ValidateNested)({ each: true }),
    __metadata("design:type", Array)
], AccountOpeningBalanceInfo.prototype, "openingBalanceByProperty", void 0);
