import type { PropertyTypeItemType } from "screens/properties/newProperty/components/propertyType/propertyTypes";
import type React from "react";

export enum PropertyTypeKey {
  Residential = "RESIDENTIAL",
  Commercial = "COMMERCIAL"
}

export interface SubTypeSelectorProps {
  onPropertySubTypeSelectionRef: (ref: React.MutableRefObject<any>) => void;
  propertyTypeItems: PropertyTypeItemType[];
  name: string;
  typeDescriptionName: string;
}
