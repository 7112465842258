import type { LeaseDraftDto, LeaseDraftOneTimeChargeDto } from "@doorloop/dto";
import { LeaseDraftStatus, mathUtils, ObjectPermission } from "@doorloop/dto";
import { propertiesApi } from "api/propertiesApi";
import { unitsApi } from "api/unitsApi";
import {
  CalendarIcon,
  CloseIcon,
  DraftLeaseListIcon,
  EditBlue,
  LocationIcon,
  ViewBlueIcon,
  VSignIcon
} from "assets/icons";
import { Icon } from "DLUI/icon";
import { Grid, ListItemContainer, ListItemIcon, ListItemSection } from "DLUI/listItems";
import type { PopoverItem } from "DLUI/popover";
import { StatusStepIndicator } from "DLUI/statusStepIndicator";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import moment from "moment";
import React, { Fragment, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { NavigationManager } from "utils/navigation";
import { usePermission } from "screens/settings/userRoles/usePermission";
import type { AnyPermissionClearance } from "screens/settings/userRoles/clearanceTypes";
import { DefaultListItemIconSize } from "screens/leases/leases/leasesList/leaseListItem";
import ListItemText from "DLUI/listItems/listItemText";
import { Routes } from "../../../../appRouter";
import { ProgressDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { leaseDraftApi } from "api/leasesDraftApi";
import { useTranslation } from "react-i18next";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";
import { useConfirmationDialog } from "@/hooks/useConfirmationDialog";

interface ComponentProps {
  leaseDraftDto: LeaseDraftDto;
  onItemPress?: (leaseId: string, draftLeaseDto?: LeaseDraftDto) => void;
  disableListItemOptions?: boolean;
  refreshScreen?: () => void;
}

const deleteLeaseClearance: AnyPermissionClearance = {
  permission: ObjectPermission.leases,
  field: "delete"
};

export const getTotalSecurityDeposits = (securityDeposits?: LeaseDraftOneTimeChargeDto[]) => {
  if (securityDeposits === undefined || (securityDeposits && securityDeposits.length === 0)) {
    return "0";
  }
  return mathUtils.sumBy(securityDeposits, (x) => mathUtils.sumBy(x.lines, (line) => line.amount || 0));
};

const DraftLeaseListItem: React.FC<ComponentProps> = ({
  leaseDraftDto,
  onItemPress,
  disableListItemOptions,
  refreshScreen
}: ComponentProps) => {
  const location = useLocation<any>();
  const { t } = useTranslation();
  const { showConfirmationDialog } = useConfirmationDialog();
  const { hasClearance, hasPermission } = usePermission();
  const { isScreenSizeIn, isTabletOrMobile, isMobile } = useResponsiveHelper();
  const propertyAndUnitValue = useMemo(() => {
    const propertyName = propertiesApi.getItemFromDictionary(leaseDraftDto.property!).name;
    const unitName = unitsApi.getItemFromDictionary(leaseDraftDto.units![0]).name;
    return propertyName + " > " + unitName;
  }, []);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const daysToMoveIn = useMemo(() => {
    const duration = moment.duration(moment(leaseDraftDto.start).diff(moment()));
    let days = duration.asDays();
    if (days > 0) {
      days = Math.ceil(duration.asDays());
    } else {
      days = Math.floor(duration.asDays());
    }
    return days;
  }, []);

  const totalSecurityDeposits = useMemo(() => getTotalSecurityDeposits(leaseDraftDto.securityDeposits), []);

  const didPressDraftLeaseItem = hasPermission(ObjectPermission.leases, "viewOne")
    ? () => {
        if (leaseDraftDto.id) {
          if (onItemPress) {
            onItemPress(leaseDraftDto.id, leaseDraftDto);
          } else {
            NavigationManager.viewDraftLeaseDetails(leaseDraftDto.id);
          }
        }
      }
    : undefined;

  const renderMoveInText = () => {
    const pastMoveInDate = daysToMoveIn < 0;
    const isOverdue =
      pastMoveInDate &&
      leaseDraftDto.status !== LeaseDraftStatus.Activated &&
      leaseDraftDto.status !== LeaseDraftStatus.Cancelled;
    if (pastMoveInDate) {
      return (
        <Fragment>
          <ListItemText
            color={isOverdue ? "error" : "gray"}
            fontWeight={isOverdue ? 600 : 400}
            marginTop={3}
            marginLeft={25}
            marginRight={3}
            value={AppStrings.Leases.DraftLease.Started}
            overflow={"visible"}
          />
          <ListItemText
            color={isOverdue ? "error" : "gray"}
            fontWeight={isOverdue ? 600 : 400}
            marginTop={3}
            formatType={"relative-time"}
            value={String(daysToMoveIn)}
            overflow={"visible"}
          />
        </Fragment>
      );
    }
    return (
      <Fragment>
        <ListItemText
          color={"gray"}
          marginTop={3}
          marginLeft={25}
          marginRight={3}
          value={AppStrings.Leases.DraftLease.Starts}
          overflow={"visible"}
        />
        <ListItemText
          color={"gray"}
          marginTop={3}
          formatType={"relative-time"}
          value={String(daysToMoveIn)}
          overflow={"visible"}
        />
      </Fragment>
    );
  };

  const renderEndDate = () => {
    if (leaseDraftDto.end) {
      return (
        <Fragment>
          <ListItemText marginLeft={4} marginRight={4} color={"black"} align="left" overflow={"visible"}>
            -
          </ListItemText>
          <ListItemText
            color={"black"}
            align="left"
            value={leaseDraftDto.end}
            formatType={"date"}
            overflow={"visible"}
          />
        </Fragment>
      );
    }
    return null;
  };

  const didPressEditDraftLease = () => {
    NavigationManager.editDraftLease(leaseDraftDto.id!);
  };

  const didPressDeleteLeaseButton = () => {
    showConfirmationDialog(location.pathname, {
      dialogTitle: AppStrings.Common.DeleteConfirmation,
      confirmButtonText: AppStrings.Common.Delete,
      dismissButtonText: AppStrings.Common.Dismiss,
      dialogSubTitle: AppStrings.Leases.DraftLease.DeleteDraftLeasConfirmationText,
      loadingText: AppStrings.Leases.DraftLease.DeleteDraftLeaseLoadingText,
      successText: AppStrings.Leases.DraftLease.DeleteDraftLeaseSuccessText,
      apiMethod: "leaseDraftApi",
      confirmOperation: "delete",
      itemId: leaseDraftDto.id
    });
  };

  const didPressActivateLease = async () => {
    if (leaseDraftDto.id) {
      setLoadingDialogState(DialogState.Show);
      const { status, message } = await leaseDraftApi.activateLease(leaseDraftDto.id);
      if (status) {
        refreshScreen && refreshScreen();
      } else {
        setLoadingDialogState(DialogState.Error);
        setLoadingDialogErrorText(message || t(AppStrings.Common.GeneralError));
      }
    }
  };

  const popoverOptions: PopoverItem[] = useMemo(() => {
    const popoverOptions: PopoverItem[] = [
      {
        Icon: ViewBlueIcon,
        title: AppStrings.Common.View,
        onClick: didPressDraftLeaseItem,
        clearance: { permission: ObjectPermission.leases, field: "viewOne" }
      },
      {
        Icon: EditBlue,
        title: AppStrings.Common.Edit,
        onClick: didPressEditDraftLease,
        showSeparator: hasClearance(deleteLeaseClearance) && leaseDraftDto.status === LeaseDraftStatus.Cancelled,
        clearance: { permission: ObjectPermission.leases, field: "edit" }
      }
    ];

    if (leaseDraftDto.status !== LeaseDraftStatus.Cancelled) {
      popoverOptions.push({
        Icon: VSignIcon,
        title: AppStrings.Leases.Screen.MakeActive,
        onClick: didPressActivateLease,
        clearance: { permission: ObjectPermission.leases, field: "edit" },
        showSeparator: status !== LeaseDraftStatus.Cancelled
      });
    }

    popoverOptions.push({
      Icon: CloseIcon,
      title: AppStrings.Common.Delete,
      onClick: didPressDeleteLeaseButton,
      iconPathColor: "error",
      textColor: "error",
      clearance: deleteLeaseClearance
    });
    return popoverOptions;
  }, []);

  const screenWidth = window.innerWidth;
  const isMinimized = screenWidth < 1900;

  return (
    <>
      <ListItemContainer popoverWidth={200} popoverItems={disableListItemOptions ? undefined : popoverOptions}>
        <Grid
          title={AppStrings.Leases.ListHeader.Lease}
          showDivider
          xs={isMobile ? 12 : 6}
          md={6}
          lg={isMinimized ? (isTabletOrMobile ? 6 : 5) : 4}
          onClick={didPressDraftLeaseItem}
          sortColumn={"name"}
        >
          <View flexDirection={"row"} alignItems={"center"}>
            <ListItemIcon Icon={DraftLeaseListIcon} />
            <ListItemSection
              renderTitle={
                <ListItemText
                  href={leaseDraftDto.id ? `${Routes.DRAFT_LEASES}/${leaseDraftDto.id}/overview` : undefined}
                  color={"black"}
                  align="left"
                  bold
                >
                  {leaseDraftDto.name}
                </ListItemText>
              }
              renderSubTitle={
                <View justifyContent={"flex-start"} alignItems={"center"} flexDirection={"row"}>
                  <Icon Source={LocationIcon} width={20} height={20} marginRight={5} />
                  <View flex={1} paddingRight={10}>
                    <ListItemText fontWeight={500} color={"gray"} overflow={"ellipsis"}>
                      {propertyAndUnitValue}
                    </ListItemText>
                  </View>
                </View>
              }
            />
          </View>
        </Grid>
        <Grid
          title={AppStrings.Leases.ListHeader.Term}
          showDivider
          xs={isMobile ? 12 : 4}
          md={4}
          lg={isMinimized ? (isTabletOrMobile ? 4 : 3) : 4}
          xl={isMinimized ? (isTabletOrMobile ? 4 : 3) : 3}
          onClick={didPressDraftLeaseItem}
          sortColumn={"start"}
        >
          <ListItemSection
            renderTitle={
              <Fragment>
                <View flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"}>
                  <Icon
                    Source={CalendarIcon}
                    width={DefaultListItemIconSize}
                    height={DefaultListItemIconSize}
                    marginRight={10}
                    pathColor={"black"}
                  />
                  <Text color={"black"} align="left" fontSize={14} value={leaseDraftDto.start} formatType={"date"} />

                  {renderEndDate()}
                </View>
              </Fragment>
            }
            renderSubTitle={
              <View justifyContent={"flex-start"} alignItems={"center"} flexDirection={"row"}>
                {renderMoveInText()}
              </View>
            }
          />
        </Grid>
        <Grid
          title={AppStrings.Leases.ListHeader.Status}
          showDivider={!isTabletOrMobile}
          xs={isMobile ? 12 : 2}
          md={2}
          lg={isMinimized ? 2 : 4}
          xl={isMinimized ? 2 : 3}
          onClick={didPressDraftLeaseItem}
          sortColumn={"status"}
        >
          <View flex={1} justifyContent={"center"} alignItems={"flex-start"}>
            <StatusStepIndicator status={leaseDraftDto.status!} />
          </View>
        </Grid>
        {!isScreenSizeIn(["md", "sm", "xs"]) && (
          <>
            <Grid
              title={!isTabletOrMobile ? AppStrings.Leases.ListHeader.Rent : undefined}
              showDivider
              xs={1}
              md={1}
              lg={1}
              onClick={didPressDraftLeaseItem}
              sortColumn={"totalRecurringRent"}
              alignTitle={"end"}
            >
              <View marginRight={10} alignItems={"flex-end"}>
                <ListItemText
                  color={"black"}
                  value={`${leaseDraftDto.totalRecurringRent || ""}`}
                  formatType={"currency"}
                />
              </View>
            </Grid>
            <Grid
              title={!isTabletOrMobile ? AppStrings.Leases.ListHeader.Deposits : undefined}
              xs={1}
              md={1}
              lg={1}
              xl={1}
              sortColumn={"totalDepositsHeld"}
              alignTitle={"end"}
            >
              <View flexDirection={"row"} marginRight={10} alignItems={"center"} height={"100%"}>
                <View
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  onClick={didPressDraftLeaseItem}
                  height={"100%"}
                  flexDirection={"row"}
                >
                  <ListItemText
                    color={"black"}
                    value={`${totalSecurityDeposits || ""}`}
                    formatType={"currency"}
                    marginRight={10}
                  />
                </View>
              </View>
            </Grid>
          </>
        )}
      </ListItemContainer>
      {loadingDialogState !== DialogState.Hidden && (
        <ProgressDialog
          dialogState={loadingDialogState}
          errorText={loadingDialogErrorText}
          onRetryButtonPress={didPressActivateLease}
        />
      )}
    </>
  );
};

export default DraftLeaseListItem;
