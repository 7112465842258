"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskDto = void 0;
const linkedResource_dto_1 = require("../common/linkedResource.dto");
const isDateStringWithoutTime_validator_1 = require("../common/validators/isDateStringWithoutTime.validator");
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const base_dto_1 = require("../base.dto");
const workOrder_dto_1 = require("./workOrder.dto");
const taskDateType_enum_1 = require("./taskDateType.enum");
const taskPriority_enum_1 = require("./taskPriority.enum");
const taskStatus_enum_1 = require("./taskStatus.enum");
const taskType_enum_1 = require("./taskType.enum");
const workOrderPermissionToEnter_enum_1 = require("./workOrderPermissionToEnter.enum");
const tenantPortalRequestType_enum_1 = require("../tenantPortal/requests/tenantPortalRequestType.enum");
class TaskDto extends base_dto_1.BaseDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.TaskDto = TaskDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(255),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], TaskDto.prototype, "reference", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(255),
    __metadata("design:type", String)
], TaskDto.prototype, "subject", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(10000),
    __metadata("design:type", String)
], TaskDto.prototype, "description", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], TaskDto.prototype, "dueDate", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsDate)(),
    __metadata("design:type", Date)
], TaskDto.prototype, "dueDateTime", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], TaskDto.prototype, "startDate", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsDate)(),
    __metadata("design:type", Date)
], TaskDto.prototype, "startDateTime", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], TaskDto.prototype, "category", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => linkedResource_dto_1.LinkedResourceDto),
    __metadata("design:type", linkedResource_dto_1.LinkedResourceDto)
], TaskDto.prototype, "linkedResource", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => workOrder_dto_1.WorkOrderDto),
    __metadata("design:type", workOrder_dto_1.WorkOrderDto)
], TaskDto.prototype, "workOrder", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(workOrderPermissionToEnter_enum_1.WorkOrderPermissionToEnter),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], TaskDto.prototype, "entryPermission", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], TaskDto.prototype, "entryContact", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(10000),
    __metadata("design:type", String)
], TaskDto.prototype, "entryNotes", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(taskDateType_enum_1.TaskDateType),
    (0, class_validator_1.ValidateIf)((x) => x.dueDate || x.dueDateTime),
    __metadata("design:type", String)
], TaskDto.prototype, "dateType", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(taskPriority_enum_1.TaskPriority),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], TaskDto.prototype, "priority", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(taskStatus_enum_1.TaskStatus),
    __metadata("design:type", String)
], TaskDto.prototype, "status", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(taskType_enum_1.TaskType),
    __metadata("design:type", String)
], TaskDto.prototype, "type", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], TaskDto.prototype, "requestedByUser", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.type === taskType_enum_1.TaskType.TENANT_REQUEST),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], TaskDto.prototype, "requestedByTenant", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.type === taskType_enum_1.TaskType.OWNER_REQUEST),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], TaskDto.prototype, "requestedByOwner", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], TaskDto.prototype, "assignedToUsers", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], TaskDto.prototype, "notifyTenant", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], TaskDto.prototype, "notifyOwner", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], TaskDto.prototype, "notifyAssignees", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], TaskDto.prototype, "recurringTransaction", void 0);
__decorate([
    (0, class_validator_1.IsMongoId)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], TaskDto.prototype, "conversationMessage", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(tenantPortalRequestType_enum_1.TenantRequestType),
    (0, class_validator_1.ValidateIf)((values) => values.type === taskType_enum_1.TaskType.TENANT_REQUEST),
    __metadata("design:type", String)
], TaskDto.prototype, "tenantRequestType", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(tenantPortalRequestType_enum_1.TenantRequestMaintenanceCategory),
    (0, class_validator_1.ValidateIf)((values) => values.tenantRequestType === tenantPortalRequestType_enum_1.TenantRequestType.MAINTENANCE && values.type === taskType_enum_1.TaskType.TENANT_REQUEST),
    __metadata("design:type", String)
], TaskDto.prototype, "tenantRequestMaintenanceCategory", void 0);
