import { View } from "DLUI/view";
import { BreadCrumbs } from "DLUI/screenTitle";
import DlButton from "DLUI/button/dlButton/dlButton";
import { DLButtonColorsEnum, DLButtonSizesEnum } from "DLUI/button/dlButton";
import React from "react";
import type { HelpPanelProps } from "DLUI/screen/helpPanel/types";

interface BankingScreenHeaderProps {
  actionProps: {
    onClick: () => void;
    text: string;
  };
  helpPanel?: HelpPanelProps;
  titleTextProps: {
    text: string;
    href?: string;
  };
}

export const BankingScreenHeader = ({ actionProps, helpPanel, titleTextProps }: BankingScreenHeaderProps) => (
  <View fullWidth noWrap gap={5} paddingBottom={10} paddingTop={10}>
    <BreadCrumbs
      ActionPanel={() => (
        <DlButton
          actionText={actionProps.text}
          onClick={actionProps.onClick}
          size={DLButtonSizesEnum.SMALL}
          icons={{ start: { isHidden: false } }}
          color={DLButtonColorsEnum.PRIMARY}
        />
      )}
      helpPanel={helpPanel}
      subTitleText={{ text: titleTextProps.text, href: titleTextProps?.href }}
    />
  </View>
);
