import React from "react";
import type { FormikProps } from "formik";
import AppStrings from "locale/keys";

import ContactInfoElement, { ContactInfoType, MAX_EMAIL_NUMBERS, MAX_PHONE_NUMBERS } from "./contactInfoElement";
import { View } from "DLUI/view";
import { ValidationIndicator } from "DLUI/form";
import { useTranslation } from "react-i18next";
import _ from "lodash";

interface ComponentProps {
  formikRef: FormikProps<any>;
}

export const ContactInfo: React.FC<ComponentProps> = ({ formikRef }: ComponentProps) => {
  const { t } = useTranslation();

  return (
    <View flex={1} marginTop={10} alignItems={"center"}>
      <ContactInfoElement
        type={ContactInfoType.Phone}
        maxElementsToRender={MAX_PHONE_NUMBERS}
        fieldsArrayName={"phones"}
        titleText={AppStrings.Tenants.NewTenant.Phone}
        formikRef={formikRef}
      />
      <ContactInfoElement
        type={ContactInfoType.Email}
        formikRef={formikRef}
        maxElementsToRender={MAX_EMAIL_NUMBERS}
        fieldsArrayName={"emails"}
        titleText={AppStrings.Tenants.NewTenant.Email}
        marginTop={20}
      />

      {_.isEmpty(formikRef.values.phones?.[0]?.number) &&
        _.isEmpty(formikRef.values.emails?.[0]?.address) &&
        (formikRef.errors.phones || formikRef.errors.emails) && (
          <ValidationIndicator
            shouldShow
            justifyContent={"center"}
            displayText={t(AppStrings.Common.PortalInfoError)}
          />
        )}
    </View>
  );
};

export default ContactInfo;
