"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplicantPostChargePayment = exports.ApplicantPostChargePaymentDataDto = exports.GetProspectPostChargeDto = void 0;
const class_validator_1 = require("class-validator");
const common_1 = require("../common");
const rentalApplicationProspectScreeningShare_dto_1 = require("./rentalApplicationProspectScreeningShare.dto");
class GetProspectPostChargeDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.GetProspectPostChargeDto = GetProspectPostChargeDto;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetProspectPostChargeDto.prototype, "rentalApplicationId", void 0);
class ApplicantPostChargePaymentDataDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.ApplicantPostChargePaymentDataDto = ApplicantPostChargePaymentDataDto;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", Object)
], ApplicantPostChargePaymentDataDto.prototype, "settings", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", Object)
], ApplicantPostChargePaymentDataDto.prototype, "rentalApplication", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", Object)
], ApplicantPostChargePaymentDataDto.prototype, "property", void 0);
class ApplicantPostChargePayment {
}
exports.ApplicantPostChargePayment = ApplicantPostChargePayment;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], ApplicantPostChargePayment.prototype, "rentalApplicationId", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    __metadata("design:type", common_1.EPayReceivableInfoDto)
], ApplicantPostChargePayment.prototype, "cardInfo", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    __metadata("design:type", rentalApplicationProspectScreeningShare_dto_1.RentalApplicationProspectScreeningShareDto)
], ApplicantPostChargePayment.prototype, "prospectScreeningShare", void 0);
