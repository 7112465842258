enum DLButtonSizesEnum {
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small"
}

enum DLButtonColorsEnum {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  NEUTRAL = "neutral",
  DANGER = "error",
  WARNING = "warning"
}

enum DLButtonVariantsEnum {
  CONTAINED = "contained",
  OUTLINED = "outlined",
  TEXT = "text"
}

export { DLButtonSizesEnum, DLButtonColorsEnum, DLButtonVariantsEnum };
