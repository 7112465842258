import type { PictureDto } from "@doorloop/dto";
import type { RUGFileState } from "shared/pictureGallery/types";
import type { PictureDtoWithFile } from "./types";
import type { OperationsBuilder } from "@/engines/bulkOperationsEngine";

export const mapRUGFileStateToPictureDto = (fileState: RUGFileState, index: number): PictureDtoWithFile => {
  return {
    fileId: fileState.fileId,
    url: fileState.source,
    rank: index,
    file: fileState.file
  };
};

export const mapPictureDtoToRUGFileState = (picture: PictureDto): RUGFileState => {
  return {
    source: picture.url || "",
    fileId: picture.fileId
  };
};

export function mapRUGFileStateToOrderIndex(addedItem: RUGFileState, allItems: RUGFileState[] | null) {
  const index = allItems?.findIndex((item) => item.uid === addedItem.uid);

  if (index === undefined || index === -1) {
    console.warn(`Unable to find added item: ${addedItem}`);
    return addedItem;
  }

  return mapRUGFileStateToPictureDto(addedItem, index);
}

export type OperationsBuilderSetter = (operationsBuilder: OperationsBuilder) => void;
