import React, { useEffect, useState, useRef, Fragment } from "react";
import { Skeleton } from "@material-ui/lab";
import { Avatar } from "@material-ui/core";
import { ProfileAvatarEmpty } from "assets/icons/index";
import { useIntersectionObserver, useIsMounted } from "usehooks-ts";

import type { CSSProperties } from "react";

function getInitials(label = "") {
  const words = label.split(" ");
  const firstChar = words[0].charAt(0).toUpperCase();
  const lastChar = words[words.length - 1].charAt(0).toUpperCase();
  return firstChar + (words.length > 1 ? lastChar : "");
}

// This gives the users a sense of loading when the image is being fetched. If
// the image is fetched faster than the skeleton animation time, the skeleton
// appears for a brief moment and feels glitchy, so we add a delay to the
// skeleton. Ideally, it should be at least 80% of the animation duration time.
const skeletonAnimationTimeMs = 1000;

const baseAvatarStyle = {
  fontSize: 14,
  fontWeight: "bold",
  backgroundColor: "rgba(236, 238, 245, 1)",
  color: "rgba(126, 137, 154, 1)"
};

const baseImgStyle: CSSProperties = {
  objectFit: "scale-down",
  opacity: 0
};

const skeletonStyle = {
  backgroundColor: "rgba(236, 238, 245, 0.8)"
};

export const SkeletonAvatar = ({
  src,
  size = 44,
  label,
  asInitials = true,
  forceInitials = false,
  fadeInMs = 250,
  rootStyle,
  avatarStyle,
  imgStyle
}: {
  src?: string;
  size?: number;
  label?: string;
  asInitials?: boolean;
  forceInitials?: boolean;
  fadeInMs?: number;
  rootStyle?: CSSProperties;
  avatarStyle?: CSSProperties;
  imgStyle?: CSSProperties;
}) => {
  const [showSkeleton, setShowSkeleton] = useState(Boolean(src));

  // https://github.com/mui/material-ui/blob/v4.x/packages/material-ui/src/Avatar/Avatar.js#L61
  const [status, setStatus] = useState<false | "loaded" | "error">(false);

  const { isIntersecting, ref } = useIntersectionObserver();

  const isMounted = useIsMounted();
  const timerRef = useRef<number | null>(null);

  useEffect(() => {
    if (isIntersecting && showSkeleton && !forceInitials) {
      // Ensure the skeleton is shown for at least the amount of time set above
      // https://stackoverflow.com/a/3943624/4106263
      timerRef.current = window.setTimeout(() => {
        if (isMounted()) {
          setShowSkeleton(false);
        }
      }, skeletonAnimationTimeMs);
    }
    return () => {
      timerRef.current && window.clearTimeout(timerRef.current);
    };
  }, [isIntersecting, isMounted, status, showSkeleton, src, forceInitials]);

  function renderAvatarChildren() {
    if (!src || forceInitials) {
      return asInitials ? getInitials(label) : <ProfileAvatarEmpty width={size} height={size} />;
    }
    return <Fragment>{/* Prevent `Avatar` default when children are `null` */}</Fragment>;
  }

  function handleLoad() {
    if (isMounted()) {
      setStatus("loaded");
    }
  }

  return (
    <div ref={ref} style={{ ...rootStyle, position: "relative", width: size, height: size }}>
      {!forceInitials && (
        <Skeleton
          variant="circle"
          animation="wave"
          width={size}
          height={size}
          style={{
            ...skeletonStyle,
            position: "absolute",
            ...(!showSkeleton && { visibility: "hidden" })
          }}
        />
      )}
      <Avatar
        src={(isIntersecting || status === "loaded") && !forceInitials ? src : ""}
        imgProps={{
          style: {
            ...baseImgStyle,
            ...imgStyle,
            // Avoid a flash of image loading when the image has already loaded
            // by always rendering `Avatar`
            opacity: status === "loaded" && !showSkeleton ? 1 : 0,
            transition: `opacity ${fadeInMs * 0.001}s`
          },
          onLoad: handleLoad
        }}
        style={{
          width: size,
          height: size,
          ...baseAvatarStyle,
          ...avatarStyle,
          ...(src && showSkeleton && !forceInitials && { visibility: "hidden" })
        }}
      >
        {renderAvatarChildren()}
      </Avatar>
    </div>
  );
};
