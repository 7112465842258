"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingPermission = exports.ReportPermission = exports.ObjectPermission = void 0;
var ObjectPermission;
(function (ObjectPermission) {
    ObjectPermission["budgets"] = "objects.budgets";
    ObjectPermission["ownerRequests"] = "objects.ownerRequests";
    ObjectPermission["tasks"] = "objects.tasks";
    ObjectPermission["tenantRequests"] = "objects.tenantRequests";
    ObjectPermission["workOrders"] = "objects.workOrders";
    ObjectPermission["accounts"] = "objects.accounts";
    ObjectPermission["insurancePolicies"] = "objects.insurancePolicies";
    ObjectPermission["plaidAccounts"] = "objects.bankAccountsPlaidAccounts";
    ObjectPermission["plaidTransactions"] = "objects.bankAccountsPlaidTransactions";
    ObjectPermission["applicationFees"] = "objects.applicationFees";
    ObjectPermission["bankDeposits"] = "objects.bankDeposits";
    ObjectPermission["bankTransfers"] = "objects.bankTransfers";
    ObjectPermission["expenses"] = "objects.expenses";
    ObjectPermission["files"] = "objects.files";
    ObjectPermission["journalEntries"] = "objects.journalEntries";
    ObjectPermission["leaseCharges"] = "objects.leaseCharges";
    ObjectPermission["leaseCredits"] = "objects.leaseCredits";
    ObjectPermission["leasePayments"] = "objects.leasePayments";
    ObjectPermission["leaseRefunds"] = "objects.leaseRefunds";
    ObjectPermission["calendar"] = "objects.calendar";
    ObjectPermission["bulkReceivePayments"] = "objects.bulkReceivePayments";
    ObjectPermission["leases"] = "objects.leases";
    ObjectPermission["merchantAccounts"] = "objects.merchantAccounts";
    ObjectPermission["notes"] = "objects.notes";
    ObjectPermission["ownerContributions"] = "objects.ownerContributions";
    ObjectPermission["owners"] = "objects.owners";
    ObjectPermission["properties"] = "objects.properties";
    ObjectPermission["propertyGroups"] = "objects.propertyGroups";
    ObjectPermission["prospects"] = "objects.prospects";
    ObjectPermission["rentalApplications"] = "objects.rentalApplications";
    ObjectPermission["tags"] = "objects.tags";
    ObjectPermission["tenants"] = "objects.tenants";
    ObjectPermission["units"] = "objects.units";
    ObjectPermission["userRoles"] = "objects.userRoles";
    ObjectPermission["users"] = "objects.users";
    ObjectPermission["vendorBillPayments"] = "objects.vendorBillPayments";
    ObjectPermission["vendorBills"] = "objects.vendorBills";
    ObjectPermission["vendorCredits"] = "objects.vendorCredits";
    ObjectPermission["vendors"] = "objects.vendors";
    ObjectPermission["announcements"] = "objects.announcements";
    ObjectPermission["communications"] = "objects.communications";
    ObjectPermission["esignatureRequests"] = "objects.esignatureRequests";
    ObjectPermission["esignatureTemplates"] = "objects.esignatureTemplates";
    ObjectPermission["managementFees"] = "objects.managementFees";
    ObjectPermission["paymentAllocations"] = "objects.paymentAllocations";
    ObjectPermission["bankReconciliation"] = "objects.reconciliations";
    ObjectPermission["outgoingPayments"] = "objects.outgoingPayments";
    ObjectPermission["apiKeys"] = "objects.apiKeys";
    ObjectPermission["bulkOperations"] = "objects.bulkOperations";
    ObjectPermission["customizeDashboard"] = "objects.customizeDashboard";
    ObjectPermission["unitEditListing"] = "objects.unitEditListing";
    ObjectPermission["ePayPayments"] = "objects.ePayPayments";
    ObjectPermission["phoneNumbers"] = "objects.phoneNumbers";
    ObjectPermission["conversations"] = "objects.conversations";
})(ObjectPermission || (exports.ObjectPermission = ObjectPermission = {}));
var ReportPermission;
(function (ReportPermission) {
    ReportPermission["accountsPayable"] = "reports.accountsPayable";
    ReportPermission["accountsReceivable"] = "reports.accountsReceivable";
    ReportPermission["balanceSheet"] = "reports.balanceSheet";
    ReportPermission["bankBalanceByProperty"] = "reports.bankBalanceByProperty";
    ReportPermission["budgetVsActual"] = "reports.budgetVsActual";
    ReportPermission["banking"] = "reports.banking";
    ReportPermission["cashFlowStatement"] = "reports.cashFlowStatement";
    ReportPermission["chartOfAccounts"] = "reports.chartOfAccounts";
    ReportPermission["depositDetail"] = "reports.depositDetail";
    ReportPermission["leaseDeposits"] = "reports.leaseDeposits";
    ReportPermission["leaseTransactions"] = "reports.leaseTransactions";
    ReportPermission["leasing"] = "reports.leasing";
    ReportPermission["owners1099"] = "reports.owners1099";
    ReportPermission["ownerAvailableFunds"] = "reports.ownerAvailableFunds";
    ReportPermission["ownerRequests"] = "reports.ownerRequests";
    ReportPermission["ownerTransactions"] = "reports.ownerTransactions";
    ReportPermission["profitAndLoss"] = "reports.profitAndLoss";
    ReportPermission["propertyAvailableFunds"] = "reports.propertyAvailableFunds";
    ReportPermission["prospect"] = "reports.prospect";
    ReportPermission["recurringTransactions"] = "reports.recurringTransactions";
    ReportPermission["rentRoll"] = "reports.rentRoll";
    ReportPermission["ownerStatement"] = "reports.ownerStatement";
    ReportPermission["propertyReserveFunds"] = "reports.propertyReserveFunds";
    ReportPermission["managementFees"] = "reports.managementFees";
    ReportPermission["tasks"] = "reports.tasks";
    ReportPermission["tenantRequests"] = "reports.tenantRequests";
    ReportPermission["tenants"] = "reports.tenants";
    ReportPermission["transactionsDetails"] = "reports.transactionsDetails";
    ReportPermission["transactionsList"] = "reports.transactionsList";
    ReportPermission["trialBalance"] = "reports.trialBalance";
    ReportPermission["vendors1099"] = "reports.vendors1099";
    ReportPermission["vendorTransactions"] = "reports.vendorTransactions";
    ReportPermission["vendors"] = "reports.vendors";
    ReportPermission["workOrders"] = "reports.workOrders";
    ReportPermission["accountRegister"] = "reports.accountRegister";
    ReportPermission["printChecks"] = "reports.printChecks";
})(ReportPermission || (exports.ReportPermission = ReportPermission = {}));
var SettingPermission;
(function (SettingPermission) {
    SettingPermission["companyInformation"] = "globalSettings.companyInformation";
    SettingPermission["regionAndCurrency"] = "globalSettings.regionAndCurrency";
    SettingPermission["defaultAccounts"] = "globalSettings.defaultAccounts";
    SettingPermission["tenantPortal"] = "globalSettings.tenantPortal";
    SettingPermission["ownerPortal"] = "globalSettings.ownerPortal";
    SettingPermission["rentReminders"] = "globalSettings.rentReminders";
    SettingPermission["paymentInstructions"] = "globalSettings.paymentInstructions";
    SettingPermission["tenantRequests"] = "globalSettings.tenantRequests";
    SettingPermission["ownerRequests"] = "globalSettings.ownerRequests";
    SettingPermission["lateFees"] = "globalSettings.lateFees";
    SettingPermission["rentalApplications"] = "globalSettings.rentalApplications";
    SettingPermission["doorloopSubscription"] = "globalSettings.doorloopSubscription";
    SettingPermission["paymentAllocations"] = "globalSettings.paymentAllocations";
    SettingPermission["quickbooks"] = "globalSettings.quickbooks";
    SettingPermission["listings"] = "globalSettings.listings";
    SettingPermission["managementFees"] = "globalSettings.managementFees";
    SettingPermission["outgoingPayments"] = "globalSettings.outgoingPayments";
    SettingPermission["ownerEPayInfo"] = "globalSettings.ownerEPayInfo";
    SettingPermission["tenantsEPayInfo"] = "globalSettings.tenantsEPayInfo";
    SettingPermission["vendorsEPayInfo"] = "globalSettings.vendorsEPayInfo";
    SettingPermission["customAllocations"] = "globalSettings.customAllocations";
    SettingPermission["prospectsSSNAndDLFullInfo"] = "globalSettings.prospectsSSNAndDLFullInfo";
    SettingPermission["convenienceFees"] = "globalSettings.convenienceFees";
    SettingPermission["communicationCenter"] = "globalSettings.communicationCenter";
})(SettingPermission || (exports.SettingPermission = SettingPermission = {}));
