import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { history } from "store/history";
import "./styles.css";
import { DialogRoutes } from "../dialogsProvider";
import { View } from "DLUI/view";
import CreateNewMenuSections from "./createNewMenuItems";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { usePermission } from "screens/settings/userRoles/usePermission";
import { isEmpty } from "lodash";
import qs from "query-string";
import MenuSectionItem from "DLUI/screen/createNewMenu/menuSectionItem";
import TenantPortalDialogHeader from "DLUI/dialogs/components/tenantPortalDialogHeader";
import useIntercomBubble from "../../../../hooks/useIntercomBubble";
import { analyticsService } from "../../../../services/analyticsService";
import { SegmentEventTypes } from "@doorloop/dto";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { useUserType } from "../../../../hooks/useUserType";
import { filterObjectsByUserType } from "../../../../utils/userTypesUtils";

const filterInactive = (item) => !item.inactive;

interface ComponentProps {
  shouldShow: boolean;
  onBackdropClick: () => void;
}

const AnimationDuration = 400;

const CreateNewMenu: React.FC<ComponentProps> = React.memo(({ shouldShow, onBackdropClick }: ComponentProps) => {
  const { isTabletOrMobile, isMobile, screenContainerPadding } = useResponsiveHelper();

  const { showIntercomBubble, hideIntercomBubble } = useIntercomBubble();

  const [runSlideLeftAnimation, setRunSlideLeftAnimation] = useState(false);

  useEffect(() => {
    if (shouldShow) {
      setRunSlideLeftAnimation(true);
      hideIntercomBubble();
      return;
    }
    !isMobile && showIntercomBubble();
  }, [shouldShow]);

  const { hasPermission } = usePermission();
  const { isHOAUser } = useUserType();

  const menuContent = useMemo(() => {
    const menuSections = CreateNewMenuSections.filter(filterInactive).map((currentSection, sectionIndex) => {
      let combinedSearchParams;

      const allowedSectionItems = currentSection.items
        .filter(({ permission }) => hasPermission(permission, "create"))
        .filter(filterInactive);
      const allowedSectionItemsForUserType = !isHOAUser
        ? allowedSectionItems
        : filterObjectsByUserType(allowedSectionItems, "HOAUser");

      const renderedSectionItems = allowedSectionItemsForUserType.map(
        ({ icon, title, navigationMethod, permission, dataCy }, itemIndex) => {
          const didPressItem = () => {
            onBackdropClick();
            analyticsService.track(SegmentEventTypes.USER_CLICKED_CREATE_NEW_MENU_ITEM, { title });
            setTimeout(
              () => {
                if (navigationMethod) {
                  if (navigationMethod.method === "append") {
                    if (navigationMethod.queryParams) {
                      combinedSearchParams = qs.stringify({
                        ...navigationMethod.queryParams,
                        ...qs.parse(location.search)
                      });
                    }
                    history.push({
                      pathname: location.pathname + "/" + DialogRoutes.GLOBAL_NEW + "/" + navigationMethod.actionPath,
                      search: combinedSearchParams ? combinedSearchParams : location.search,
                      state: {}
                    });
                  } else {
                    history.push({
                      pathname: navigationMethod.actionPath,
                      search: qs.stringify({
                        ...navigationMethod.queryParams
                      })
                    });
                  }
                }
              },
              isTabletOrMobile ? AnimationDuration : 0
            );
          };
          return (
            <MenuSectionItem icon={icon} onItemPress={didPressItem} title={title} key={itemIndex} dataCy={dataCy} />
          );
        }
      );

      return (
        !isEmpty(renderedSectionItems) && (
          <View
            key={"MS" + sectionIndex}
            justifyContent={"flex-start"}
            width={isMobile ? "100%" : isTabletOrMobile ? "50%" : "auto"}
            marginTop={isMobile ? 0 : 20}
            style={isTabletOrMobile ? {} : { borderTop: "1px solid #ECEEF5" }}
          >
            <Text marginTop={20} fontSize={14} bold value={currentSection.title} marginLeft={screenContainerPadding} />

            <View noWrap flexDirection={"row"}>
              <View flexDirection={isTabletOrMobile ? "row" : "column"} marginTop={10}>
                {renderedSectionItems}
              </View>
            </View>
          </View>
        )
      );
    });
    return (
      <View flexDirection={"row"}>
        <View
          maxWidth={isTabletOrMobile ? "100%" : Math.min(window.innerWidth - 20, 1200)}
          backgroundColor={"white"}
          height={window.innerHeight}
          overflow={"scroll"}
          paddingLeft={isTabletOrMobile ? 0 : 20}
          paddingRight={isTabletOrMobile ? 0 : 20}
        >
          <View flexDirection={"row"}>
            <TenantPortalDialogHeader onClose={onBackdropClick} title={AppStrings.Common.CreateNew} />

            {menuSections}
            <View height={20} />
          </View>
        </View>
        {!isMobile && (
          <View
            className={clsx(["opacityLayer", shouldShow ? "showOpacity" : "hideOpacity"])}
            flex={1}
            height={window.innerHeight}
          />
        )}
      </View>
    );
  }, [shouldShow, window.innerHeight]);

  return (
    <div
      style={{ overflow: isTabletOrMobile ? "scroll" : "hidden" }}
      className={clsx([
        "globalNewContainer",
        shouldShow ? "drawer-slide-in-left" : runSlideLeftAnimation ? "drawer-slide-out-left" : ""
      ])}
      onClick={onBackdropClick}
    >
      {menuContent}
    </div>
  );
});

export default CreateNewMenu;
