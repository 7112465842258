const Files = {
  FileAttachment: "files.fileAttachment",
  Tab: {
    AddNewFile: "files.tab.addNewFile",
    UploadNewFile: "files.tab.uploadNewFile"
  },
  Screen: {
    SearchPlaceHolder: "files.screen.searchPlaceHolder"
  },
  Dialog: {
    HelpDescription: "files.dialog.helpDescription",
    NoFileSelectedError: "files.dialog.noFileSelectedError",
    LearnAboutFiles: "files.dialog.learnAboutFiles",
    FileNotSupportedErrorMsg: "files.dialog.fileNotSupportedErrorMsg"
  },
  FileSizeTooLargeError: "files.fileSizeTooLargeError",
  TotalFilesSizeTooLargeError: "files.totalFilesSizeTooLargeError"
};

export default Files;
