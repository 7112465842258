import DialogFrame, { getDialogFrameDimension } from "../../../../DLUI/dialogs/components/dialogFrame";
import type { ManagementFeeItemFormMobileProps } from "screens/settings/managementFees/formElements/managementFeeItemFormMobile";
import { ManagementFeeItemFormMobile } from "screens/settings/managementFees/formElements/managementFeeItemFormMobile";
import DLUI_Dialog from "DLUI/dialogs/dialog";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { useCallback } from "react";
import type { ManagementFeeRuleDto, ManagementFeesCompanySettingsDto } from "@doorloop/dto";
import { getIn, useFormikContext } from "formik";

type ManagementFeeItemFormMobileDialogProps = Omit<ManagementFeeItemFormMobileProps, "handleClose"> & {
  isOpen: boolean;
  handleStateChange: (isOpen: boolean) => void;
};

export const ManagementFeeItemFormMobileDialog = ({
  isOpen,
  handleStateChange,
  ...props
}: ManagementFeeItemFormMobileDialogProps) => {
  const formikRef = useFormikContext<ManagementFeesCompanySettingsDto>();
  const rules: ManagementFeeRuleDto[] = getIn(formikRef.values, props.rulesPath) ?? [];
  const { isMobile } = useResponsiveHelper();

  const handleClose = useCallback(() => {
    handleStateChange(false);
  }, [handleStateChange]);

  if (!isMobile || rules.length === 0 || !isOpen) return null;

  return (
    <DLUI_Dialog open={isMobile && isOpen} onClose={handleClose}>
      <DialogFrame
        width={getDialogFrameDimension("width", 1000)}
        height={getDialogFrameDimension("height", 1000)}
        frameType={"contentOnly"}
        onCloseButtonClick={handleClose}
        numViews={1}
        renderView={() => <ManagementFeeItemFormMobile {...props} handleClose={handleClose} />}
        activeView={0}
      />
    </DLUI_Dialog>
  );
};
