import type { FC } from "react";
import { AnimatedView } from "DLUI/view/animatedView";
import { useTypedTranslation } from "@/locale";
import { Notification } from "DLUI/notification/notification";
import ColorsEnum from "utils/colorsEnum";
import Text from "DLUI/text";
import { ErrorSignIcon } from "assets/icons";
import { HorizontalSeparationLine } from "@/components/DLUI/separatorView/horizontalSeparationLine";

interface ComponentProps {
  show: boolean;
}

export const EvictionStatusCancelAlert: FC<ComponentProps> = ({ show }) => {
  const { t } = useTypedTranslation();

  return (
    <AnimatedView shouldShow={show} minHeight={100}>
      <HorizontalSeparationLine marginTop={10} />
      <Notification
        marginTop={10}
        padding={10}
        LabelComponent={() => (
          <Text
            fontWeight={400}
            fontSize={14}
            color={"secondary-yellow"}
            value={t("leases.settings.cancelEvictionNote")}
          />
        )}
        background={ColorsEnum.LightYellow}
        separatorColor={ColorsEnum.DarkYellow}
        iconPathColor={"yellow"}
        IconComponent={ErrorSignIcon}
      />
    </AnimatedView>
  );
};
