import React from "react";

import type { FormikProps } from "formik";

import type { PersonContactInfoRowPersonType } from "DLUI/person/personContactInfoRow";
import { PersonInfo } from "DLUI/person";
import { PortalInvite } from "DLUI/portal/portalInvite";
import { View } from "DLUI/view";
import { SendWelcomeSms } from "DLUI/person/sendWelcomeSms";
import { ConversationLinkedToType } from "@doorloop/dto";

interface ComponentProps {
  personType: PersonContactInfoRowPersonType;
  formikRef: FormikProps<any>;
  onFileReceive?: (receivedFile: File) => void;
  onFileDelete?: () => void;
  hidePortalInvite?: boolean;
}

export const PersonInfoWithPortal: React.FC<ComponentProps> = ({
  personType,
  onFileReceive,
  onFileDelete,
  formikRef,
  hidePortalInvite = false
}: ComponentProps) => (
  <View flex={1} width={"100%"}>
    <PersonInfo
      formikRef={formikRef}
      onFileReceive={onFileReceive}
      onFileDelete={onFileDelete}
      showContactInfo
      personType={personType}
    />
    {!hidePortalInvite && <PortalInvite personType={personType} />}
    <SendWelcomeSms
      personType={personType === "owner" ? ConversationLinkedToType.OWNER : ConversationLinkedToType.TENANT}
    />
    <View flex={1} />
  </View>
);
