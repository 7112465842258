export default {
  screen: {
    emptydataViewInstructionsText: "No Tasks Found",
    emptydataAddNew: "Add your first task",
    searchPaneltitle: "Manage your tasks",
    searchPlaceHolder: "Search tasks",
    addNewTask: "New Task",
    subtask: "Subtask",
    priorities: {
      low: "Low Priority",
      medium: "Medium Priority",
      high: "High Priority"
    },
    filterOptions: {
      completed: "Completed",
      notCompleted: "Not Completed",
      notStarted: "Not Started",
      inProgress: "In Progress",
      low: "Low",
      medium: "Medium",
      high: "High",
      assignedToVendor: "Assigned to Vendor",
      approvedByOwner: "Approved by Owner",
      approved: "Approved",
      notApproved: "Not Approved",
      billCreated: "Bill Created",
      created: "Created",
      notCreated: "Not Created"
    },
    newInternalTask: "New Task",
    newTenantRequest: "New Tenant Request",
    newOwnerRequest: "New Owner Request",
    newWorkOrder: "New Work Order",
    completeTask: "Complete Task",
    reopenTask: "Reopen Task",
    createdAt: "Created At",
    completedAt: "Completed At",
    requestedBy: "Requested By",
    requestedByTenant: "Requested By Tenant",
    requestedByOwner: "Requested By Owner",
    dueDate: "Due Date:",
    date: "Date:",
    helpPanel: {
      tasks: {
        description: "Organize and keep track of your tasks and work orders",
        createTask: "How do I create a task?",
        editTask: "How do I update and edit tasks?",
        createTaskVideo: "Watch an example of creating a task",
        editTaskVideo: "Watch an example of updating a task",
        createDialogTitle: "Task Creation",
        editDialogTitle: "Task Modification"
      },
      workOrders: {
        description: "Track vendor & contractor work on your properties.",
        createOrder: "How do I create a work order?",
        updateOrder: "How do I update work orders?",
        createOrderVideo: "Watch an example of creating a work order",
        dialogTitle: "Creating a Work Order"
      },
      recurringTasks: {
        description: "Schedule recurring tasks & work orders and automatically assign them to any user.",
        create: "How do I create a recurring task?",
        editDelete: "How do I edit or delete recurring tasks?",
        dialogTitle: "Recurring Tasks and Work Orders"
      }
    }
  },
  internalTaskDialog: {
    newTask: "New Task",
    taskInfo: "Task Info",
    workOrder: "Work Order",
    subject: "Subject",
    priority: "Priority",
    assignees: "Assignees",
    relatedTo: "Related To",
    workOrderDescriptionTitle:
      "Work Orders track work related to this task. These need to be completed by you or a vendor.",
    workOrderDescriptionSubTitle:
      "You can assign this to a vendor, keep track of parts and labor costs, or get owner approval. When you’re done, we can automatically generate a bill.",
    addWorkOrder: "Add Work Order",
    creatingTask: "Creating Task",
    updatingTask: "Updating Task",
    loadingTaskData: "Loading Task Data",
    createTaskSuccessText: "Task was created successfully",
    updateTaskSuccessText: "Task was updated successfully",
    editTask: "Edit Task",
    editRecurringTask: "Edit Recurring Task",
    loadingTaskDataSuccessText: "data was loaded"
  },
  tasksList: {
    ownerRequest: "Owner Request",
    internalTask: "Task",
    tenantRequest: "Tenant Request",
    task: "Task",
    assignedTo: "Assigned To",
    notAssigned: "Unassigned",
    user: "User",
    dueAt: "Due at",
    notSet: "Not set",
    editTask: "Edit Task",
    viewTask: "View Task",
    deleteTask: "Delete Task",
    deleteConfirmationText: "Are you sure you want to delete this task?",
    deleteTaskLoadingText: "Deleting Task...",
    deleteTaskSuccessText: "This task was deleted successfully",
    reviewTask: "View Request"
  },
  ownerRequestDialog: {
    newOwnerRequest: "New Owner Request",
    editOwnerRequest: "Edit Owner Request",
    selectOwnerValidationText: "Please click here to select an owner"
  },
  tenantRequestDialog: {
    newTenantRequest: "New Tenant Request",
    editTenantRequest: "Edit Tenant Request",
    permissionToEnter: "Permission To Enter",
    entryContact: "Entry Contact",
    entryNotes: "Please provide instructions on how to access your property",
    selectTenantValidationText: "Click here to select a tenant"
  },
  workOrderDialog: {
    descriptionFieldLabel: "Work Description",
    approvedByOwner: "Approved By Owner",
    editWorkOrder: "Edit Work Order",
    editRecurringWorkOrder: "Edit Recurring Work Order",
    selectVendorValidationText: "Please Select Vendor",
    partsAndLabors: "Parts And Labor",
    partsAndLaborsDescription: "Keep track of parts and labor costs, then generate a bill for this work order",
    quantity: "Quantity",
    price: "Price",
    partsAndLaborsItemTitle: "Item"
  },
  workOrdersScreen: {
    searchPlaceHolder: "Search work orders",
    emptydataViewInstructionsText: "No Work Orders Found",
    instructions: "Instructions"
  },
  taskDetails: {
    subTasks: "Subtasks",
    newSubTask: "New Subtask",
    postUpdate: "Post An Update",
    message: "Message",
    notifyTenant: "Notify Tenant",
    notifyOwner: "Notify Owner",
    notifyAssignees: "Notify Assignee(s)"
  }
};
