import type {
  EndLeaseDto,
  GetAllLeasesQuery,
  GetAllRecurringTransactionsQuery,
  HasTenantsAutoPayments,
  LeaseDto,
  MoveInTenantDto,
  NotRenewingDto,
  RecurringTransactionDto
} from "@doorloop/dto";
import { RecurringTransactionType, ServerRoutes } from "@doorloop/dto";
import { accountsApi } from "./accounts";
import { propertiesApi } from "./propertiesApi";
import { RestApiBase } from "./restApiBase";
import { RestApiBaseWithDictionary } from "./restApiBaseWithDictionary";
import { unitsApi } from "./unitsApi";
import { usersApi } from "./usersApi";
import { vendorsApi } from "api/vendorsApi";
import { tenantsApi } from "api/tenantsApi";
import type { ApiResult } from "api/apiResult";
import type { ApiToastsProps } from "./apiHelper";
import AppStrings from "../locale/keys";
import _ from "lodash";
import type { Method } from "axios";
import type { ToastStateValid } from "store/toast/actions";
import { NavigationActionType } from "store/toast/enums";
import { apiHelper } from "api/apiHelper";

class LeasesApi extends RestApiBaseWithDictionary<LeaseDto, GetAllLeasesQuery> {
  constructor() {
    super(ServerRoutes.LEASES, [propertiesApi, unitsApi, accountsApi, usersApi]);
    this.restRoute = ServerRoutes.LEASES;
  }

  /**
   * Checks if there are conflicts with any two leases in the database.
   * @param property - The property to check for conflicts.
   * @param unit - The unit to check for conflicts.
   * @param start - The start date of the lease.
   * @param end - The end date of the lease.
   * @param excludeIds - The ids of the leases to exclude from the check.
   */
  async checkForLeaseConflicts({
    property,
    unit,
    units,
    start,
    end,
    excludeIds = []
  }: {
    property?: string;
    unit?: string;
    units?: string[];
    start?: string;
    end?: string;
    excludeIds?: string[];
  }): Promise<{
    conflictsExist: boolean;
    conflictingLeases?: LeaseDto[];
  }> {
    if (!(property && start)) {
      throw new Error("Missing required parameters for lease conflict check.");
    }

    // Make sure either unit or units is defined
    if (!unit && !units) {
      throw new Error("Either unit or units must be defined for lease conflict check.");
    }

    const leases = await leasesApi.getAll({
      filter_property: property,
      filter_units: unit ? [unit] : units,
      filter_date_from: start,
      filter_date_to: end
    });
    const conflictingLeases = leases.data?.data
      ?.filter((lease) => lease["id"] && !excludeIds.includes(lease["id"]))
      .filter((x) => x.start !== end && x.end !== start); // allow leases that start or end on the same date

    if (conflictingLeases && conflictingLeases.length > 0) {
      return { conflictsExist: true, conflictingLeases };
    }
    return { conflictsExist: false };
  }

  getLeaseDetails = async (leaseId: string) => await this.get(leaseId);

  async hasTenantsAutoPayments(leaseId: string): Promise<ApiResult<HasTenantsAutoPayments>> {
    return await apiHelper.axiosGet(`/api/leases/${leaseId}/has-tenants-auto-payments`);
  }

  async cancelTenantsAutoPayments(leaseId: string): Promise<ApiResult<unknown>> {
    return await apiHelper.axiosPost({
      url: `/api/leases/${leaseId}/cancel-tenants-auto-payments`
    });
  }

  async sendPaymentReminder(leaseId: string, tenantId: string) {
    return await apiHelper.axiosPost({
      url: ServerRoutes.LEASES_SEND_RENT_REMINDER,
      data: { leaseId, tenantId },
      toasts: { translationKey: AppStrings.Common.PaymentReminderSent }
    });
  }

  async getLastRentReminderSend(leasesId: string[]) {
    return await apiHelper.axiosPost({
      url: ServerRoutes.LEASES_GET_RENT_REMINDER_DATE,
      data: { leasesId }
    });
  }
}

export const leasesApi = new LeasesApi();

class LeasesRecurringTransactionsApi extends RestApiBase<RecurringTransactionDto, GetAllRecurringTransactionsQuery> {
  constructor() {
    super(ServerRoutes.LEASE_RECURRING_TRANSACTIONS, [
      leasesApi,
      usersApi,
      vendorsApi,
      tenantsApi,
      propertiesApi,
      accountsApi
    ]);
    this.restRoute = ServerRoutes.LEASE_RECURRING_TRANSACTIONS;
  }

  private getRecurringToastData(
    method: Method,
    data: RecurringTransactionDto,
    toasts?: ApiToastsProps<RecurringTransactionDto>
  ) {
    let toastData = _.clone(toasts);

    if (data.type) {
      let translationKey: string | undefined;
      let action: ToastStateValid["action"] | undefined;

      switch (data.type) {
        case RecurringTransactionType.TASK: {
          if (data.taskInfo?.type) {
            translationKey =
              AppStrings.Toasts.custom.recurring[RecurringTransactionType.TASK]?.[data.taskInfo?.type]?.[method];
          }

          break;
        }
        case RecurringTransactionType.EXPENSE: {
          translationKey = AppStrings.Toasts.custom.recurring[RecurringTransactionType.EXPENSE]?.[method];
          action = { type: NavigationActionType.REPORT, name: "runRecurringExpensesReport" };

          break;
        }
        default:
          translationKey = AppStrings.Toasts.custom.recurring[data.type]?.[method];
      }

      if (translationKey || action) {
        if (toastData) {
          toastData.translationKey = toastData.translationKey || translationKey;
          toastData.action = { ...action, ...toastData.action };
        } else {
          toastData = { translationKey, action };
        }
      }
    }

    return toastData;
  }

  async create(
    data: RecurringTransactionDto,
    toasts?: ApiToastsProps<RecurringTransactionDto>
  ): Promise<ApiResult<RecurringTransactionDto>> {
    const toastData = this.getRecurringToastData("POST", data, toasts);

    return await super.create(data, toastData);
  }

  async update(
    id: string,
    data: RecurringTransactionDto,
    toasts?: ApiToastsProps<RecurringTransactionDto>
  ): Promise<ApiResult<RecurringTransactionDto>> {
    const toastData = this.getRecurringToastData("PUT", data, toasts);

    return await super.update(id, data, toastData);
  }
}

export const leasesRecurringTransactionsApi = new LeasesRecurringTransactionsApi();

class LeasesMoveInTenantApi extends RestApiBase<MoveInTenantDto, {}> {
  constructor() {
    super(ServerRoutes.LEASES_POST_MOVEIN_TENANT);
    this.restRoute = ServerRoutes.LEASES_POST_MOVEIN_TENANT;
  }
}

export const leasesMoveInTenantApi = new LeasesMoveInTenantApi();

class LeasesMoveOutTenantApi extends RestApiBase<MoveInTenantDto, {}> {
  constructor() {
    super(ServerRoutes.LEASES_POST_MOVEOUT_TENANT);
    this.restRoute = ServerRoutes.LEASES_POST_MOVEOUT_TENANT;
  }
}

export const leasesMoveOutTenantApi = new LeasesMoveOutTenantApi();

class LeasesNotRenewingApi extends RestApiBase<NotRenewingDto, {}> {
  constructor() {
    super(ServerRoutes.LEASES_POST_NOT_RENEWING);
    this.restRoute = ServerRoutes.LEASES_POST_NOT_RENEWING;
  }
}

export const leasesNotRenewingApi = new LeasesNotRenewingApi();

class EndLeaseApi extends RestApiBase<EndLeaseDto, {}> {
  constructor() {
    super(ServerRoutes.LEASES_POST_END_LEASE);
    this.restRoute = ServerRoutes.LEASES_POST_END_LEASE;
  }
}

export const leasesEndApi = new EndLeaseApi();
