import AppStrings from "../../../../locale/keys";
import { MainMenuLayersKeys } from "DLUI/drawer/items/mainMenuItems";
import Routes from "../../../appRouter/routes";
import { DataCy, ObjectPermission, TaskStatusForQuery, TaskType } from "@doorloop/dto";
import { AnyTaskViewListPermissions } from "screens/settings/userRoles/clearanceTypes";
import { v4 as uuid } from "uuid";
import type { DrawerItemType, NavConfigProps } from "DLUI/drawer/types";
import { DrawerItemName, NavLevels } from "DLUI/drawer/types";
import type { Translation } from "locale";

const unassignedTasksItem: DrawerItemType = {
  label: AppStrings.DrawerItems.UnassignedTasks,
  navKey: uuid(),
  navPath: [
    {
      name: Routes.UNASSIGNED_TASKS,
      matchMethod: "exact",
      queryParams: {
        filter_status: TaskStatusForQuery.NOT_COMPLETED
      }
    }
  ],
  dataCy: DataCy.sideMenu.tasks.unassignedTasks
};
const myTasksItem: DrawerItemType = {
  label: AppStrings.DrawerItems.MyTasks,
  navKey: uuid(),
  navPath: [
    {
      name: Routes.MY_TASKS,
      queryParams: {
        filter_status: TaskStatusForQuery.NOT_COMPLETED
      }
    }
  ],
  dataCy: DataCy.sideMenu.tasks.myTasks
};
const tenantRequestsItem: DrawerItemType = {
  label: AppStrings.DrawerItems.TenantRequests,
  navKey: uuid(),
  navPath: [
    {
      name: Routes.TENANT_REQUESTS,
      queryParams: {
        filter_status: TaskStatusForQuery.NOT_COMPLETED,
        filter_type: TaskType.TENANT_REQUEST
      }
    }
  ],
  dataCy: DataCy.sideMenu.tasks.tenantRequests
};
const ownerRequestsItem: DrawerItemType = {
  label: AppStrings.DrawerItems.OwnerRequests,
  navKey: uuid(),
  navPath: [
    {
      name: Routes.OWNER_REQUESTS,
      queryParams: {
        filter_status: TaskStatusForQuery.NOT_COMPLETED,
        filter_type: TaskType.OWNER_REQUEST
      }
    }
  ],
  dataCy: DataCy.sideMenu.tasks.ownerRequests,
  clearance: [{ permission: ObjectPermission.ownerRequests, field: "viewList" }]
};
const recurringTasksItem: DrawerItemType = {
  label: AppStrings.Common.Recurring.RecurringTasks,
  navKey: uuid(),
  navPath: [
    {
      name: Routes.RECURRING_TASKS
    }
  ],
  dataCy: DataCy.sideMenu.tasks.recurringTasks,
  clearance: AnyTaskViewListPermissions
};
const incompleteTasksItem: DrawerItemType = {
  label: "drawerItems.incompleteTasks" as Translation,
  navKey: uuid(),
  navPath: [
    {
      name: Routes.INCOMPLETE_TASKS
    }
  ],
  dataCy: DataCy.sideMenu.tasks.incompleteTasks
};
const allTasksItem: DrawerItemType = {
  label: AppStrings.DrawerItems.AllTasks,
  navKey: uuid(),
  navPath: [
    {
      name: Routes.TASKS
    }
  ],
  dataCy: DataCy.sideMenu.tasks.allTasks
};
const workOrdersItem: DrawerItemType = {
  label: AppStrings.DrawerItems.WorkOrders,
  navKey: uuid(),
  navPath: [
    {
      name: Routes.WORK_ORDERS,
      matchMethod: "anyMatch",
      queryParams: {
        filter_status: TaskStatusForQuery.NOT_COMPLETED
      }
    }
  ],
  dataCy: DataCy.sideMenu.tasks.workOrders,
  clearance: [
    {
      permission: ObjectPermission.workOrders,
      field: "viewList"
    }
  ]
};
export const SubMenuTasksLayers: NavConfigProps = {
  title: AppStrings.Common.CreateNewMenu.TasksAndMaintenance,
  level: NavLevels.SUB_MENU,
  subMenuLayerKey: DrawerItemName.Tasks,
  groups: [
    {
      groupName: AppStrings.DrawerItems.Tasks,
      parentNavigationKey: MainMenuLayersKeys.Tasks,
      items: [
        unassignedTasksItem,
        myTasksItem,
        tenantRequestsItem,
        ownerRequestsItem,
        recurringTasksItem,
        incompleteTasksItem,
        allTasksItem
      ]
    },
    {
      groupName: AppStrings.DrawerItems.Maintenance,
      parentNavigationKey: MainMenuLayersKeys.Tasks,
      items: [workOrdersItem]
    }
  ]
};

export const SubMenuHOAUserTasksLayers: NavConfigProps = {
  title: AppStrings.Common.CreateNewMenu.TasksAndMaintenance,
  level: NavLevels.SUB_MENU,
  groups: [
    {
      groupName: AppStrings.DrawerItems.Tasks,
      parentNavigationKey: MainMenuLayersKeys.Tasks,
      items: [
        unassignedTasksItem,
        myTasksItem,
        tenantRequestsItem,
        recurringTasksItem,
        incompleteTasksItem,
        allTasksItem
      ]
    },
    {
      groupName: AppStrings.DrawerItems.Maintenance,
      parentNavigationKey: MainMenuLayersKeys.Tasks,
      items: [workOrdersItem]
    }
  ]
};
