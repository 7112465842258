import React from "react";

import { FastField, type FormikProps } from "formik";
import type { OwnerPortalSettingsDto } from "@doorloop/dto";
import { useTranslation } from "react-i18next";
import AppStrings from "../../../../../locale/keys";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import { TextField } from "DLUI/form";

interface ComponentProps {
  formikRef: FormikProps<OwnerPortalSettingsDto>;
}

export const OwnerRequests: React.FC<ComponentProps> = () => {
  const { t } = useTranslation();

  return (
    <View noWrap>
      <Text fontSize={16} color={"black"} value={AppStrings.Common.OwnerRequestSettingsInstructionsDescription} />
      <Text
        fontSize={20}
        marginTop={16}
        marginBottom={16}
        fontWeight={700}
        color={"black"}
        value={AppStrings.Common.OwnerRequestSettingsInstructions}
      />
      <FastField
        component={TextField}
        label={t(AppStrings.Tasks.WorkOrdersScreen.Instructions)}
        name={`requestSettings.instructions`}
        multiline
        rows="4"
        multiLineSize={"large"}
      />
    </View>
  );
};
