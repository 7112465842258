import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import AppStrings from "locale/keys";

interface ComponentProps {
  totalBills: number;
  totalCredits: number;
}

const OutstandingBillsAllocationLinesSummary: React.FC<ComponentProps> = ({
  totalBills,
  totalCredits
}: ComponentProps) => {
  const totalPayments = totalBills - totalCredits;

  return (
    <View paddingRight={20} flexDirection={"row"} marginTop={20} gap={40} justifyContent={"flex-end"}>
      <View marginBottom={20} flexDirection={"row"} width={"auto"}>
        <Text value={AppStrings.Bills.PayBills.Screen.TotalBills} color={"black"} fontSize={16} />
        <View alignItems={"flex-end"} width={120}>
          <Text value={String(totalBills)} color={"black"} fontSize={16} formatType={"currency"} />
        </View>
      </View>

      <View marginBottom={20} flexDirection={"row"} width={"auto"}>
        <Text value={AppStrings.Bills.PayBills.Screen.TotalCredits} color={"black"} fontSize={16} />
        <View alignItems={"flex-end"} width={120}>
          <Text value={String(totalCredits)} color={"black"} fontSize={16} formatType={"currency"} />
        </View>
      </View>

      <View marginBottom={20} flexDirection={"row"} width={"auto"}>
        <Text value={AppStrings.Bills.PayBills.Screen.TotalPayments} color={"black"} fontWeight={700} fontSize={16} />
        <View alignItems={"flex-end"} width={120}>
          <Text value={String(totalPayments)} color={"black"} fontSize={16} fontWeight={700} formatType={"currency"} />
        </View>
      </View>
    </View>
  );
};

export default OutstandingBillsAllocationLinesSummary;
