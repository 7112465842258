"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LateFeesPolicyDto = void 0;
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const common_1 = require("../../../common");
const lateFeeCharge_dto_1 = require("./lateFeeCharge.dto");
const lateFeeSettingsAccountsToConsider_enum_1 = require("./lateFeeSettingsAccountsToConsider.enum");
const reminderTypesStatus_dto_1 = require("./reminderTypesStatus.dto");
class LateFeesPolicyDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.LateFeesPolicyDto = LateFeesPolicyDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(lateFeeSettingsAccountsToConsider_enum_1.LateFeeSettingsAccountsToConsider),
    __metadata("design:type", String)
], LateFeesPolicyDto.prototype, "lateFeeSettingsAccountsToConsider", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.lateFeeSettingsAccountsToConsider === lateFeeSettingsAccountsToConsider_enum_1.LateFeeSettingsAccountsToConsider.SPECIFIC_ACCOUNTS),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ArrayMinSize)(1, { message: "please select accounts" }),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], LateFeesPolicyDto.prototype, "lateFeeSettingsAccountsToConsiderSpecificAccounts", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, common_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], LateFeesPolicyDto.prototype, "lateFeeProcessedBefore", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], LateFeesPolicyDto.prototype, "minBalanceToApplyLateFee", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.minBalanceToApplyLateFee),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsNumber)({ maxDecimalPlaces: 2 }),
    (0, class_validator_1.Min)(0),
    __metadata("design:type", Number)
], LateFeesPolicyDto.prototype, "minBalanceToApplyLateFeeAmount", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], LateFeesPolicyDto.prototype, "maxMonthlyLateFees", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.maxMonthlyLateFees),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsNumber)({ maxDecimalPlaces: 2 }),
    (0, class_validator_1.Min)(0),
    __metadata("design:type", Number)
], LateFeesPolicyDto.prototype, "maxMonthlyLateFeesAmount", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], LateFeesPolicyDto.prototype, "maxDailyLateFees", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => x.maxDailyLateFees),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsNumber)({ maxDecimalPlaces: 2 }),
    (0, class_validator_1.Min)(0),
    __metadata("design:type", Number)
], LateFeesPolicyDto.prototype, "maxDailyLateFeesAmount", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => lateFeeCharge_dto_1.LateFeeChargeDto),
    __metadata("design:type", Array)
], LateFeesPolicyDto.prototype, "lateFeeCharges", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], LateFeesPolicyDto.prototype, "onlyChargeLateFeesOnMostRecentCharge", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => reminderTypesStatus_dto_1.ReminderTypesStatusDto),
    __metadata("design:type", reminderTypesStatus_dto_1.ReminderTypesStatusDto)
], LateFeesPolicyDto.prototype, "reminderTypesBeforeCharge", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => reminderTypesStatus_dto_1.ReminderTypesStatusDto),
    __metadata("design:type", reminderTypesStatus_dto_1.ReminderTypesStatusDto)
], LateFeesPolicyDto.prototype, "reminderTypesAfterCharge", void 0);
