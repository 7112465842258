import type { GetAllBaseQueryRequest, GetAllBaseQueryResponse } from "@doorloop/dto";
import type { ApiResult } from "api/apiResult";
import { FilterEmpty } from "assets/icons";
import { AnimateMarginTop } from "DLUI/animatableView";
import { BulkExecutionDialog } from "DLUI/bulkExecution/bulkExecutionDialog";
import EmptyDataView from "DLUI/emptyDataView";
import { ListBulkActionsContext } from "DLUI/infiniteList/listBulkActionsContext";
import { applySort, getCurrentSort } from "DLUI/infiniteList/sortManager";
import { PAGE_SIZE } from "DLUI/infiniteList/utils";
import { LoadingSkeleton } from "DLUI/loadingSkeleton";
import DefaultSkeletonItem from "DLUI/skeletonItem/defaultSkeletonItem";
import { StickyHeader } from "DLUI/stickyHeader";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { ViewBackgroundColor } from "DLUI/view/view";
import { DefaultDebounceDelays, useDebounce } from "hooks/useDebounce";
import { useListBulkActionsManager } from "hooks/useListBulkActionsManager";
import AppStrings from "locale/keys";
import _ from "lodash";
import type { ReactElement } from "react";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingComponent from "./loadingComponent";
import makeStyles from "./styles";
import NetworkError from "DLUI/lists/gridList/dataGrid/networkError";
import { useIsMounted } from "hooks/useIsMounted";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import type { BulkActionDefinition, BulkProps } from "../dataList/types";
import { useAnalyticsService } from "../../../hooks/useAnalyticsService";
import { asyncUtils } from "@doorloop/utils";

export type FilterSelectionInterface = GetAllBaseQueryRequest;

export interface InfiniteListProps {
  renderItem: (currentItem: any, index: number, resourceId?: string) => Promise<JSX.Element>;
  apiMethod: {
    getAll: (filterSelection: FilterSelectionInterface) => Promise<ApiResult<GetAllBaseQueryResponse<any>>>;
  };
  skeletonItem?: React.FC<any>;
  emptyListView?: ReactElement;
  searchPanelPlaceHolderText?: string;
  filterObj: Record<string, any>;
  listDirection?: "row" | "column" | "column-reverse";
  scrollableTarget?: string;
  renderListHeader?: (listData: any[]) => JSX.Element;
  showStickyHeader?: boolean;
  stickyHeaderId?: string;
  stickyHeaderFullWidth?: boolean;
  alignItems?: "flex-start" | "flex-end" | "center";
  size?: "small" | "normal";
  onDataChange?: (nextData: any[]) => void;
  newItem?: any;
  onLoadingDataStateChange?: (loadingInProgress: boolean) => void;
  animateMarginTop?: number;
  minWidth?: number;
  maxWidth?: number;
  justifyContent?: "flex-start" | "flex-end" | "center";
  stickyHeaderBackgroundColor?: ViewBackgroundColor;
  bulkResourceIdGetter?: (item: unknown, index: number) => string;
  bulkActionDefinitions?: BulkActionDefinition[];
  bulkExecutionDialogMinHeight?: number;
  bulkExecutionDialogTimeout?: number;
  hideBulkSelectAll?: boolean;
  removeDefaultBottomPadding?: boolean;
  _alwaysShowLoadingSkeleton?: boolean;
  overrideDataSource?: any[];
  dataCy?: string;
  hideNoMoreResultsIndicator?: boolean;
}

/**
 * @deprecated use {@link DataList} instead
 * */
const InfiniteList: React.FC<InfiniteListProps> = ({
  renderItem,
  apiMethod,
  skeletonItem,
  emptyListView,
  filterObj,
  listDirection,
  scrollableTarget,
  renderListHeader,
  showStickyHeader,
  stickyHeaderId,
  alignItems,
  onDataChange,
  newItem,
  onLoadingDataStateChange,
  animateMarginTop,
  maxWidth,
  justifyContent,
  stickyHeaderBackgroundColor,
  bulkActionDefinitions,
  bulkResourceIdGetter = (_, index) => `${index}`,
  bulkExecutionDialogMinHeight,
  hideBulkSelectAll,
  removeDefaultBottomPadding,
  _alwaysShowLoadingSkeleton,
  overrideDataSource,
  bulkExecutionDialogTimeout,
  dataCy,
  hideNoMoreResultsIndicator
}: InfiniteListProps) => {
  const { dispatchAnalytics } = useAnalyticsService();
  const classes = makeStyles();
  const { isMobile } = useResponsiveHelper();
  const { isMountedRef } = useIsMounted();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [showInitLoading, setShowInitLoading] = useState<boolean>(true);
  const [showEmptyListView, setShowEmptyListview] = useState(false);
  const [hasMoreResults, setHasMoreResults] = useState(true);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [networkError, setNetworkError] = useState<boolean>(false);
  const [networkErrorText, setNetworkErrorText] = useState<string | undefined>();
  const [listItems, setListItems] = useState<any[]>([]);
  const [filterInProgress, setFilterInProgress] = useState<boolean>(false);
  const [filterSelection, setFilterSelection] = useState<FilterSelectionInterface>();
  const [bulkProps, setBulkProps] = useState<BulkProps>({ shouldShow: false, operations: [] });
  const [totalAllData, setTotalAllData] = useState<number>(0);

  const { sort_by, sort_descending } = getCurrentSort();

  const resourceIds = useMemo(
    () => (bulkActionDefinitions ? dataSource.map((item, index) => bulkResourceIdGetter(item, index)) : []),
    [dataSource, bulkResourceIdGetter, bulkActionDefinitions]
  );
  const {
    listBulkActionsInterface,
    buildBulkOperations,
    toggleAll,
    allChecked,
    anyChecked,
    isTotalAllChecked,
    selectTotalAll,
    clearTotalAll,
    checkedIds
  } = useListBulkActionsManager(Boolean(bulkActionDefinitions), resourceIds);

  const fireAction = (actionNameParam: string) => {
    const definition = bulkActionDefinitions?.find(({ actionName }) => actionName === actionNameParam);
    const operations = buildBulkOperations(actionNameParam);
    setBulkProps({
      shouldShow: true,
      definition,
      operations
    });

    dispatchAnalytics("bulk_operation_dialog_opened", {
      bulkOperationName: definition?.actionName || "",
      numberOfItemsSelected: _.size(operations)
    });
  };

  const onChangeRequestInProgress = (value: boolean) => {
    setRequestInProgress(value);
    onLoadingDataStateChange?.(value);
  };

  const debouncedFilterSelection = useDebounce(filterSelection, DefaultDebounceDelays.FILTER_CHANGED);

  useEffect(() => {
    if (newItem !== undefined) {
      setShowInitLoading(false);
      setShowEmptyListview(false);
      const updateIndex = dataSource.find((x) => x.id === newItem.id);
      if (updateIndex) {
        const _nextDatasource = dataSource.splice(1, updateIndex, newItem);
        setDataSource(_nextDatasource);
      } else {
        setDataSource([newItem, ...dataSource]);
      }
    }
  }, [newItem]);

  useEffect(() => {
    if (debouncedFilterSelection) {
      fetchData();
    }
  }, [debouncedFilterSelection]);

  useEffect(() => {
    if (filterObj) {
      setShowEmptyListview(false);
      setDataSource([]);
      const nextFilterObject: FilterSelectionInterface = {
        page_size: filterObj.page_size ? filterObj.page_size : PAGE_SIZE,
        ...filterSelection,
        page_number: 1,
        sort_by,
        sort_descending: sort_descending == "yes" ? true : undefined,
        ...filterObj
      };
      Object.keys(filterObj).forEach((currentkey: string) => {
        if (filterObj[currentkey] === "") {
          delete nextFilterObject[currentkey];
        }
      });

      if (!_.isEqual(nextFilterObject, filterSelection)) {
        setFilterSelection({ ...nextFilterObject, page_number: 1 });
        setFilterInProgress(true);
      }
    }
  }, [filterObj, sort_by, sort_descending]);

  useEffect(() => {
    if (overrideDataSource) setDataSource(overrideDataSource);
  }, [overrideDataSource]);

  useEffect(() => {
    asyncUtils
      .map(dataSource, async (item, index) => await renderItem(item, index, bulkResourceIdGetter(item, index)))
      .then(setListItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderItem, dataSource]);

  const fetchData = async () => {
    if (requestInProgress) {
      return;
    }
    setNetworkError(false);
    onChangeRequestInProgress(true);

    if (!filterSelection) {
      throw new Error("Filter selection cannot be undefined");
    }

    const response = await apiMethod.getAll(filterSelection);
    if (isMountedRef.current && response && response.status) {
      onChangeRequestInProgress(false);
      if (response.data) {
        setNetworkError(false);
        const responseData = response.data.data as [];
        const totalAllData = response.data.total;
        let nextDatasource;
        if (filterSelection.page_number === 1) {
          nextDatasource = responseData;
        } else {
          nextDatasource = dataSource.concat(responseData);
        }
        if (showInitLoading && responseData && responseData.length === 0) {
          setShowEmptyListview(true);
        }
        setHasMoreResults(nextDatasource.length < response.data.total);
        setFilterInProgress(false);
        setDataSource(nextDatasource);
        setTotalAllData(totalAllData);

        if (onDataChange) {
          onDataChange(nextDatasource);
        }
        setShowInitLoading(false);
      }
    } else if (response && response.message) {
      setNetworkErrorText(response.message);
      onChangeRequestInProgress(false);
      setNetworkError(true);
    } else {
      onChangeRequestInProgress(false);
      setNetworkError(true);
    }
  };

  const _renderListHeader = () => {
    if (renderListHeader && !filterInProgress) {
      return renderListHeader(dataSource);
    }
    return null;
  };

  const getJustifyContent = () => {
    if (justifyContent) {
      return justifyContent;
    }

    return listDirection === "row" && alignItems && alignItems === "center" ? alignItems : "flex-start";
  };

  const renderList = () => {
    if (dataSource.length === 0 && !requestInProgress && !filterInProgress) {
      return (
        <View justifyContent={"center"} alignItems={"center"} flexDirection={"row"}>
          <EmptyDataView
            instructionsText={AppStrings.Common.NoResultsFoundMainText}
            instructionsSubText={AppStrings.Common.NoResultsFoundSubText}
            displayIcon={FilterEmpty}
          />
        </View>
      );
    }

    const fetchMoreData = () => {
      if (!filterInProgress) {
        setFilterSelection((prevState) => {
          return {
            ...prevState,
            page_number: (prevState?.page_number || 0) + 1
          };
        });
      }
    };

    const renderNoMoreDataIndicator = (hasMoreResults: boolean) => {
      if (!hasMoreResults && dataSource.length > 10) {
        return (
          <div className={classes.ListEndComponentContainer}>
            <Text color={"black"} variant={"listItemTitle"} value={AppStrings.Common.NoMoreResults} marginTop={20} />
          </div>
        );
      }
    };

    let _showStickyHeader = showStickyHeader === undefined ? true : showStickyHeader;
    if (filterInProgress || isMobile) {
      _showStickyHeader = false;
    }

    const renderContent = () => {
      if (animateMarginTop) {
        return (
          <AnimateMarginTop alignItems={"center"} marginTop={animateMarginTop}>
            <div
              className={classes.listItemsContainer}
              style={{
                alignItems: alignItems || "center",
                flexDirection: listDirection || "column",
                justifyContent: getJustifyContent()
              }}
              id={"listItemsContainer"}
            >
              <ListBulkActionsContext.Provider value={listBulkActionsInterface}>
                {listItems}
              </ListBulkActionsContext.Provider>
            </div>
            {!hideNoMoreResultsIndicator && renderNoMoreDataIndicator(hasMoreResults)}
          </AnimateMarginTop>
        );
      }
      return (
        <Fragment>
          <div
            className={classes.listItemsContainer}
            style={{
              alignItems: alignItems || "center",
              flexDirection: listDirection || "column",
              justifyContent: getJustifyContent()
            }}
            id={"listItemsContainer"}
          >
            <ListBulkActionsContext.Provider value={listBulkActionsInterface}>
              {listItems}
            </ListBulkActionsContext.Provider>
          </div>
          {!hideNoMoreResultsIndicator && renderNoMoreDataIndicator(hasMoreResults)}
        </Fragment>
      );
    };

    const actionCount = _.size(bulkProps.operations);

    const totalChecked = checkedIds.length;

    return (
      <View alignItems={"center"} noWrap>
        {bulkProps.shouldShow && bulkProps.definition ? (
          <BulkExecutionDialog
            operations={bulkProps.operations}
            minHeight={bulkExecutionDialogMinHeight}
            confirm={{
              header: bulkProps.definition.confirmHeader || "",
              message:
                (actionCount === 1
                  ? bulkProps.definition.confirmMessageOne
                  : bulkProps.definition.confirmMessageMultiple) || "",
              color: bulkProps.definition.dialogTextColor,
              actionName: bulkProps.definition.actionNameTranslated,
              confirmButtonVariant: bulkProps.definition.confirmButtonVariant,
              actionCount: isTotalAllChecked ? totalAllData : actionCount
            }}
            onFinish={(resultAggregate, finishResult) => {
              dispatchAnalytics("bulk_operation_finished", {
                bulkOperationName: bulkProps.definition?.actionName || "",
                numberOfCompleted: _.size(resultAggregate),
                numberOfFailed: _.size(finishResult?.failedOperations),
                numberOfSkipped: _.size(finishResult?.skippedOperations)
              });

              bulkProps.definition?.onFinishAction?.(resultAggregate);
            }}
            closeHandler={async (refresh) => {
              setBulkProps({ shouldShow: false, operations: [] });

              dispatchAnalytics("bulk_operation_dialog_closed", {
                bulkOperationName: bulkProps.definition?.actionName || ""
              });

              if (refresh) {
                toggleAll(false);
                setShowInitLoading(true);
                await asyncUtils.sleep(100);
                window.location.reload();
              } else if (isTotalAllChecked && bulkProps.definition?.totalAllExecutionRequestType) {
                toggleAll(false);
              }
            }}
            bulkExecutionDialogTimeout={bulkExecutionDialogTimeout}
            totalAllExecutionRequestType={bulkProps.definition?.totalAllExecutionRequestType}
            isTotalAllChecked={isTotalAllChecked}
            queryFilter={filterSelection}
          />
        ) : null}

        <div
          style={{
            width: "100%",
            maxWidth,
            position: "relative"
          }}
        >
          {_renderListHeader()}
          <StickyHeader
            shouldShow={_showStickyHeader}
            id={stickyHeaderId}
            backgroundColor={stickyHeaderBackgroundColor}
            bulkActions={
              bulkActionDefinitions
                ? {
                    isAllChecked: allChecked,
                    isAnyChecked: anyChecked,
                    totalChecked,
                    totalViewable: dataSource?.length || 0,
                    totalAll: totalAllData,
                    isTotalAllChecked,
                    selectTotalAll: () => selectTotalAll(),
                    clearTotalAll: () => clearTotalAll(),
                    toggleAll,
                    bulkActionDefinitions,
                    fireAction,
                    hideBulkSelectAll
                  }
                : undefined
            }
            sortHandler={applySort}
          />
          <InfiniteScroll
            style={listDirection === "column-reverse" ? { display: "flex", flexDirection: listDirection } : undefined}
            dataLength={dataSource.length}
            next={fetchMoreData}
            hasMore={hasMoreResults}
            loader={<LoadingComponent />}
            scrollableTarget={scrollableTarget || "screenScroll"}
            scrollThreshold={0.5}
            inverse={listDirection === "column-reverse"}
          >
            {renderContent()}
          </InfiniteScroll>
        </div>
      </View>
    );
  };

  if (networkError) {
    return <NetworkError fetchData={fetchData} errorMessage={networkErrorText} />;
  }

  if (_alwaysShowLoadingSkeleton || showInitLoading) {
    return <LoadingSkeleton maxWidth={maxWidth} SkeletonItem={skeletonItem || DefaultSkeletonItem} />;
  }

  if (showEmptyListView && emptyListView) {
    return (
      <View width={"100%"} flex={1} justifyContent={"flex-start"} alignItems={"center"} flexDirection={"row"}>
        {_renderListHeader()}
        {emptyListView}
      </View>
    );
  }

  return (
    <div className={classes.listContainer} data-cy={dataCy}>
      {renderList()}
      {removeDefaultBottomPadding ? null : <View height={80} flexDirection={"row"} />}
    </div>
  );
};

export default InfiniteList;
