import type { ConversationMessageDto, TaskDto } from "@doorloop/dto";
import { TaskStatus, TaskType } from "@doorloop/dto";
import Text from "DLUI/text";
import React from "react";
import _ from "lodash";
import AppStrings from "../../../../locale/keys";
import { View } from "DLUI/view";
import FiberManualRecordRoundedIcon from "@material-ui/icons/FiberManualRecordRounded";
import type { TextColor } from "DLUI/text/text";
import makeStyles from "./styles";
import { TextTooltip } from "DLUI/popover/textTooltip";
import { useTranslation } from "react-i18next";

const statusColorMap: Record<TaskStatus, TextColor> = {
  [TaskStatus.COMPLETED]: "green",
  [TaskStatus.IN_PROGRESS]: "orange",
  [TaskStatus.RECEIVED]: "lightBlue",
  [TaskStatus.NOT_STARTED]: "secondary-gray",
  [TaskStatus.ARCHIVED]: "secondary-gray"
};

interface ComponentProps {
  messageDto: ConversationMessageDto;
  didPressEditTask: (task: TaskDto) => void;
}

export const MessageListItemAdditionalInfo: React.FC<ComponentProps> = ({ messageDto, didPressEditTask }) => {
  const { t } = useTranslation();
  const classes = makeStyles();
  const task = _.first(messageDto.tasks);

  if (!task) {
    return null;
  }

  const color = statusColorMap[task.status || TaskStatus.NOT_STARTED];

  return (
    <View
      flexDirection={"row"}
      alignItems={"center"}
      onClick={() => didPressEditTask(task)}
      className={classes.messageTaskContainer}
    >
      <Text
        value={t(AppStrings.Common.HasBeenCreated, {
          task: t(AppStrings.Common.Enums.TaskType[task?.type || TaskType.INTERNAL_TASK])
        })}
        fontSize={10}
        color={"secondary-gray"}
      />
      <FiberManualRecordRoundedIcon style={{ width: 8, height: 8, marginLeft: 5, marginRight: 5, color }} />
      <TextTooltip
        rawText
        value={t(AppStrings.Common.TaskStatus, {
          status: t(AppStrings.Common.Enums.TaskStatus[task?.status || TaskStatus.NOT_STARTED])
        })}
        useButton={false}
        useDark
      >
        <Text
          value={AppStrings.Common.Enums.TaskStatus[task?.status || TaskStatus.NOT_STARTED]}
          fontSize={10}
          color={color}
        />
      </TextTooltip>
    </View>
  );
};
