import { ServerRoutes } from "@doorloop/dto";
import { propertiesApi } from "./propertiesApi";
import { RestApiBase } from "./restApiBase";
import { unitsApi } from "./unitsApi";
import type { LeaseRenewalOfferDto, GetAllLeaseRenewalOffersQuery } from "@doorloop/dto";

class LeaseRenewalOfferApi extends RestApiBase<LeaseRenewalOfferDto, GetAllLeaseRenewalOffersQuery> {
  constructor() {
    super(ServerRoutes.LEASE_RENEWAL_OFFERS, [propertiesApi, unitsApi]);
    this.restRoute = ServerRoutes.LEASE_RENEWAL_OFFERS;
  }
}

export const leaseRenewalOfferApi = new LeaseRenewalOfferApi();
