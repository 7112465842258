import { useState, useMemo } from "react";
import type { LeaseDto } from "@doorloop/dto";
import { ObjectPermission } from "@doorloop/dto";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import DialogFrame from "../../../components/dialogFrame";
import {
  EvictionDialogFrameHeight,
  EvictionDialogFrameWidth
} from "DLUI/dialogs/settings/leases/evictionStatusDialog/evictionStatusFormikContextWrapper";
import EvictionStatusForm from "DLUI/dialogs/settings/leases/evictionStatusDialog/evictionStatusForm";
import { leasesApi } from "api/leasesApi";
import { useFormikContext } from "formik";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { useTypedTranslation } from "@/locale";

interface Props {
  onBackdropClick: () => void;
  onClose: () => void;
  dialogTitle: string;
}

const DialogViews = {
  LoadingView: 0,
  DialogForm: 1
};

const EvictionStatusDialog: React.FC<Props> = ({ onBackdropClick, onClose, dialogTitle }: Props) => {
  const formikRef = useFormikContext<LeaseDto>();
  const [viewIndex, setViewIndex] = useState<number>(DialogViews.DialogForm);
  const { t } = useTypedTranslation();

  const [isEvictionNoteConfirmed, setIsEvictionNoteConfirmed] = useState(formikRef.values.evictionPending || false);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const initialEvictionStatus = useMemo(() => formikRef.values.evictionPending, []);
  const isUnableToSaveForm = useMemo(
    () => !isEvictionNoteConfirmed || (initialEvictionStatus && Boolean(formikRef.values.evictionPending)),
    [formikRef.values.evictionPending, isEvictionNoteConfirmed]
  );

  const didPressSaveButton = async () => {
    if (formikRef.values && formikRef.values.id) {
      setLoadingDialogState(DialogState.Show);
      setViewIndex(DialogViews.LoadingView);
      const { status, message } = await leasesApi.update(formikRef.values.id, formikRef.values);
      if (status) {
        onClose();
      } else {
        setLoadingDialogErrorText(message || t("common.generalError"));
        setLoadingDialogState(DialogState.Error);
      }
    }
  };

  const renderView = ({ index }) => {
    if (index === DialogViews.DialogForm) {
      return (
        <EvictionStatusForm
          isEvictionNoteConfirmed={isEvictionNoteConfirmed}
          handleEvictionNoteConfirmation={(value) => setIsEvictionNoteConfirmed(value)}
        />
      );
    }
    if (index === DialogViews.LoadingView) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          errorText={loadingDialogErrorText}
          didPressDismissButton={() => setViewIndex(DialogViews.DialogForm)}
          onRetryButtonPress={didPressSaveButton}
        />
      );
    }
    return <div />;
  };

  const renderActionPanelButtons = () => (
    <FormActionButtons
      clearance={{ permission: ObjectPermission.leases, field: "edit" }}
      propsSubButton={{ onClick: onBackdropClick }}
      propsMainButton={{
        type: "cta",
        props: {
          onClick: didPressSaveButton,
          disabled: isUnableToSaveForm
        }
      }}
    />
  );

  return (
    <DialogFrame
      onCloseButtonClick={onBackdropClick}
      title={dialogTitle}
      width={EvictionDialogFrameWidth}
      height={EvictionDialogFrameHeight}
      renderView={renderView}
      numViews={6}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={viewIndex === DialogViews.DialogForm ? "sectionTitleFrame" : "contentOnlyNoFrame"}
      useExperimentalDialogFrame
    />
  );
};

export default EvictionStatusDialog;
