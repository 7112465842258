import type { RentalApplicationCompanySettingsDto, RentalApplicationSettingsProps } from "@doorloop/dto";
import { RadioGroup } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import { FastField, useFormikContext } from "formik";
import AppStrings from "../../../../../../locale/keys";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { RichTextField } from "DLUI/form/richTextField/richTextField";
import { RentalAppFieldsGenerator } from "screens/settings/rentalApplications/rentalAppFieldsGenerator";
import { RentalApplicationWizardSteps } from "screens/tenantPortal/rentalApplication/types";

interface ComponentProps {
  rentalApplicationFieldSettings: RentalApplicationSettingsProps;
}

const AboutYou: React.FC<ComponentProps> = ({ rentalApplicationFieldSettings }) => {
  const { t } = useTranslation();
  const { propertyId } = useParams<any>();
  const [renderForm, setRenderForm] = useState<boolean>(true);

  const formik = useFormikContext<RentalApplicationCompanySettingsDto>();
  const {
    values: { aboutYou },
    setFieldValue
  } = formik;

  const aboutYouSelectionDefaultValue = useMemo(() => {
    const emptyObject = _.keys(aboutYou).length <= 1;

    if (!aboutYou?.isShown && emptyObject && propertyId) {
      return "companyDefault";
    }
    return "specifyAboutYouInfo";
  }, []);

  const [aboutYouSelectionType, setAboutYouSelectionType] = useState<"companyDefault" | "specifyAboutYouInfo">(
    aboutYouSelectionDefaultValue
  );

  const didChangeSpecifyAboutYouSelection = (selectedValue: string) => {
    setFieldValue("aboutYou.isShown", selectedValue === "true");

    if (selectedValue === "false") {
      setAboutYouSelectionType("companyDefault");

      setTimeout(() => {
        refreshForm();
      }, 500);
    } else {
      setAboutYouSelectionType("specifyAboutYouInfo");
    }
  };

  const refreshForm = () => {
    setRenderForm(false);
    setFieldValue("aboutYou", undefined);
    setTimeout(() => {
      setRenderForm(true);
    }, 0);
  };

  const renderSpecifyRadioButtons = () => {
    if (propertyId) {
      return (
        <View>
          <RadioGroup
            didChangeSelection={didChangeSpecifyAboutYouSelection}
            uniqueKey={"FRB"}
            defaultValue={aboutYouSelectionType === "companyDefault" ? "false" : "true"}
            marginTop={20}
            radioGroupItems={[
              {
                label: AppStrings.Common.HideSectionForProperty,
                value: "false"
              },
              {
                label: AppStrings.Common.SpecifySettingsForProperty,
                value: "true"
              }
            ]}
          />
          <SeparationLine marginTop={20} width={"100%"} height={1} />
        </View>
      );
    }
  };

  return (
    <>
      <Text
        value={AppStrings.Settings.GeneralSettings.RentalApplications.AboutYouSectionDescription}
        lineHeight={"26px"}
      />
      {renderSpecifyRadioButtons()}
      <View
        shouldShow={aboutYouSelectionType === "specifyAboutYouInfo"}
        showAnimation={"fade-in"}
        hideAnimation={"fade-out"}
      >
        {renderForm ? (
          <View>
            <Text
              value={AppStrings.Tasks.WorkOrdersScreen.Instructions}
              fontSize={20}
              fontWeight={700}
              marginTop={20}
            />
            <FastField
              component={RichTextField}
              label={t(AppStrings.Tasks.WorkOrdersScreen.Instructions)}
              name={`aboutYou.instructions`}
              styles={{ container: { marginTop: 20 } }}
              maxLength={5000}
              html={{ name: "aboutYou.instructionsHTML", value: aboutYou?.instructionsHTML }}
            />
            <View flexDirection={"row"} justifyContent={"space-between"} noWrap marginTop={30} marginBottom={12}>
              <Text value={AppStrings.Common.Field} fontSize={12} color={"secondary-gray"} />
              <Text value={AppStrings.Common.Required} fontSize={12} color={"secondary-gray"} />
            </View>
            <View noWrap>
              <RentalAppFieldsGenerator
                fieldsConfigObject={rentalApplicationFieldSettings.aboutYou.fields}
                formik={formik}
                formName={RentalApplicationWizardSteps.AboutYou}
              />
            </View>
          </View>
        ) : null}
      </View>
    </>
  );
};

export default AboutYou;
