import { Grid } from "@material-ui/core";
import { usersApi } from "api/usersApi";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { getIn } from "formik";
import AppStrings from "locale/keys";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import type { IRootState } from "store";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";
import { UserStatus } from "@doorloop/dto";

interface ComponentProps {
  formikRef: FormikProps<any>;
}

const AccountOwner: React.FC<ComponentProps> = ({ formikRef }: ComponentProps) => {
  const { t } = useTranslation();
  const currentUser = useSelector((state: IRootState) => state.auth.currentLoginResponse);
  const accountOwnerDefaultValue = useMemo(() => getIn(formikRef.values, "owner"), []);
  return (
    <View flex={1}>
      <View>
        <Text
          fontSize={16}
          lineHeight={"26px"}
          color={"black"}
          value={AppStrings.Settings.GeneralSettings.AccountOwner.AccountOwnerDescription}
        />
        <View>
          <Grid container item xs={12} md={6} lg={6}>
            <FormikCachedAsyncAutoComplete
              uniqueIndex={"accountOwner"}
              apiHandler={usersApi}
              displayNameKey={"name"}
              filterFieldName={"filter_text"}
              filterFieldValue={"name"}
              selectionFields={["id", "class"]}
              name={"owner"}
              label={t(AppStrings.Users.Screen.AccountOwner)}
              marginTop={20}
              defaultValue={accountOwnerDefaultValue}
              disabled={currentUser && currentUser.id !== accountOwnerDefaultValue}
              queryParams={{ filter_status: UserStatus.ACTIVE }}
            />
          </Grid>
        </View>
        <View>
          <Text
            value={AppStrings.Settings.GeneralSettings.AccountOwner.accountOwnerModify}
            fontSize={16}
            lineHeight={"26px"}
            marginTop={20}
          />
        </View>
      </View>
    </View>
  );
};

export default AccountOwner;
