import type { FC } from "react";
import React from "react";
import type { ReportPermission } from "@doorloop/dto";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { Grid } from "@material-ui/core";
import { SeparationLine } from "DLUI/separatorView";
import { ReportPermissionToggle } from "./reportPermissionToggle";
import { SinglePermissionSection } from "./SinglePermissionSection";
import { useTranslation } from "react-i18next";

interface Props {
  title?: string;
  permissions: Array<{
    permission: ReportPermission;
    title: string;
  }>;
}

export const ReportSection: FC<Props> = ({ title, permissions }: Props) => {
  const { t } = useTranslation();
  return (
    <View
      borderRadius={10}
      marginBottom={20}
      paddingLeft={20}
      paddingRight={20}
      backgroundColor={"white"}
      justifyContent={"center"}
    >
      {!title ? (
        <SinglePermissionSection permission={permissions[0]} />
      ) : (
        <>
          <View height={60} justifyContent={"center"}>
            <Text fontWeight={700} value={title} />
          </View>
          <SeparationLine width={"100%"} height={1} />
          <Grid container>
            {permissions.map(({ permission, title }, i) => (
              <Grid item lg={4} xs={12} md={4} key={i}>
                <View alignItems={"flex-start"} marginTop={5} justifyContent={"flex-start"}>
                  <ReportPermissionToggle permission={permission} label={t(title)} />
                </View>
              </Grid>
            ))}
          </Grid>
          <View height={20} />
        </>
      )}
    </View>
  );
};
