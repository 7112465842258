import React, { Fragment } from "react";
import { AddIcon } from "assets/icons";
import { AnimatableView } from "DLUI/animatableView";
import { Button } from "DLUI/button";
import { DisplayEmergencyContactDto } from "@doorloop/dto";
import type { FieldArrayRenderProps, FormikProps } from "formik";
import { FieldArray } from "formik";
import AppStrings from "locale/keys";
import EmergencyContactItem from "./emergencyContactItem";
import MakeStyles from "./styles";
import { View } from "DLUI/view";

const MAX_EMEGENCY_CONTACTS_NUMBERS = 5;
interface ComponentProps {
  formikRef: FormikProps<any>;
}

const EmergencyContact: React.FC<ComponentProps> = ({ formikRef }: ComponentProps) => {
  const classes = MakeStyles();
  const renderEmergencyContactItem = (index: number, arrayHelpers: FieldArrayRenderProps) => (
    <div style={{ marginTop: index === 0 ? 0 : 20 }} key={"EC" + index}>
      <EmergencyContactItem formikRef={formikRef} arrayHelpers={arrayHelpers} index={index} />
    </div>
  );

  return (
    <View flex={1} fullWidth>
      <FieldArray
        name={"emergencyContacts"}
        render={(arrayHelpers) => {
          const didPressAddNewContact = () => {
            arrayHelpers.push(new DisplayEmergencyContactDto());
          };
          const shouldShowAddNewButton = (): boolean => {
            let numOfAddedItems = 0;
            if (formikRef.values.emergencyContacts) {
              formikRef.values.emergencyContacts.forEach((currentElement: DisplayEmergencyContactDto) => {
                if (currentElement.shouldShow) {
                  numOfAddedItems += 1;
                }
              });
            }
            return numOfAddedItems < MAX_EMEGENCY_CONTACTS_NUMBERS;
          };

          const renderAddNewButton = () => {
            const renderButton = shouldShowAddNewButton();
            return (
              <AnimatableView shouldShow={renderButton}>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 40,
                    justifySelf: "flex-end"
                  }}
                >
                  <Button
                    color={"lightBlue"}
                    type={"inlineText"}
                    actionText={AppStrings.Tenants.NewTenant.AddAnother}
                    onClick={didPressAddNewContact}
                    LeftIcon={AddIcon}
                    applyColorForIcons
                    iconSize={15}
                  />
                </div>
              </AnimatableView>
            );
          };

          return (
            <Fragment>
              <div className={classes.emergencyContactsItemsContainer}>
                {formikRef.values.emergencyContacts
                  ? formikRef.values.emergencyContacts.map((currentItem: DisplayEmergencyContactDto, index: number) =>
                      renderEmergencyContactItem(index, arrayHelpers)
                    )
                  : null}
              </div>
              {renderAddNewButton()}
            </Fragment>
          );
        }}
      />
    </View>
  );
};

export default EmergencyContact;
