import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { lazy } from "react";
import { LazyRoute } from "./lazyRoute";

const OwnersListScreen = lazy(() => import("screens/owners/ownersList/ownersListScreen"));
const OwnerDetails = lazy(() => import("screens/owners/ownerDetails/ownerDetails"));
const OwnerDistributionsScreen = lazy(() => import("screens/owners/ownerDistributions/ownerDistributionsScreen"));

const OwnersRouter = () => (
  <PageRouter>
    <LazyRoute path={Routes.OWNERS_GLOBAL_NEW} render={OwnersListScreen} />
    <LazyRoute path={Routes.OWNER_DETAILS_GLOBAL_NEW} render={OwnerDetails} />
    <LazyRoute exact path={Routes.OWNER_DETAILS_EDIT_CONTRIBUTION} render={OwnerDetails} />
    <LazyRoute exact path={Routes.OWNER_DETAILS_EDIT_EXPENSE} render={OwnerDetails} />
    <LazyRoute exact path={Routes.OWNER_DETAILS_EDIT_OWNER_REQUEST} render={OwnerDetails} />
    <LazyRoute exact path={Routes.OWNER_DETAILS_EDIT_INTERNAL_TASK} render={OwnerDetails} />
    <LazyRoute path={Routes.OWNERS_DISTRIBUTIONS} render={OwnerDistributionsScreen} />
    <LazyRoute exact path={Routes.EDIT_OWNER} render={OwnersListScreen} />
    <LazyRoute path={Routes.OWNER_TRANSACTIONS_EDIT_BANK_DEPOSIT} render={OwnerDetails} />
    <LazyRoute path={Routes.OWNER_DETAILS} render={OwnerDetails} />
    <LazyRoute path={Routes.OWNERS} render={OwnersListScreen} />
  </PageRouter>
);

export default OwnersRouter;
