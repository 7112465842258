"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mathUtils = void 0;
const bignumber_js_1 = require("bignumber.js");
class MathUtils {
    _op(op, ...numbers) {
        return numbers.reduce((acc = 0, num = 0) => new bignumber_js_1.BigNumber(acc)[op](num).decimalPlaces(2).toNumber()) || 0;
    }
    add(...numbers) {
        return this._op("plus", ...numbers);
    }
    substract(...numbers) {
        return this._op("minus", ...numbers);
    }
    multiply(...numbers) {
        return this._op("times", ...numbers);
    }
    divide(...numbers) {
        return this._op("dividedBy", ...numbers);
    }
    sum(collection) {
        if (collection != null && collection.length) {
            return this.add(...collection);
        }
        return 0;
    }
    sumBy(collection, iteratee) {
        if (collection != null && collection.length) {
            return this.baseSum(collection, iteratee);
        }
        return 0;
    }
    round(num) {
        if (num) {
            return Math.round(num);
        }
        return num;
    }
    abs(num) {
        if (num) {
            return Math.abs(num);
        }
        return num;
    }
    baseSum(collection, iteratee) {
        let result;
        for (const value of collection) {
            const current = iteratee(value);
            if (current !== undefined) {
                result = result === undefined ? current : result + current;
            }
        }
        return this.format(result) || 0;
    }
    format(number) {
        try {
            if (number !== 0 && !number) {
                return undefined;
            }
            return new bignumber_js_1.BigNumber(number).decimalPlaces(2).toNumber();
        }
        catch (e) {
            return undefined;
        }
    }
    formatNumberToString(num, decimalPlaces = 2) {
        return new bignumber_js_1.BigNumber(num).toFormat(decimalPlaces);
    }
}
exports.mathUtils = new MathUtils();
