import React, { Fragment } from "react";
import { OwnerDto } from "@doorloop/dto";
import { MailIcon, PhoneIcon } from "assets/icons";
import { Icon } from "DLUI/icon";
import { Grid, ListItemContainer } from "DLUI/listItems";
import { ProfileImage } from "DLUI/profileImage";
import { View } from "DLUI/view";
import ListItemText from "DLUI/listItems/listItemText";
import AppStrings from "locale/keys";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps extends OwnerDto {
  didSelectOwnerItem: (selectedItem: OwnerDto) => void;
  owner: OwnerDto;
}

const SelectableOwnersListItem: React.FC<ComponentProps> = ({ didSelectOwnerItem, owner }: ComponentProps) => {
  const { name, id, pictureUrl, emails, phones } = owner;
  const { isMobile } = useResponsiveHelper();
  const _didSelectTenantItem = () => {
    const selectedOwner = new OwnerDto();
    selectedOwner.emails = emails;
    selectedOwner.name = name;
    selectedOwner.id = id;
    selectedOwner.pictureUrl = pictureUrl;
    if (didSelectOwnerItem) {
      didSelectOwnerItem(selectedOwner);
    }
  };

  const renderEmail = () => {
    if (emails && emails.length > 0) {
      return (
        <Fragment>
          <Icon Source={MailIcon} pathColor={"black"} />

          <View flex={1}>
            <ListItemText marginLeft={10} align={"left"} color={"black"}>
              {emails[0].address ? emails[0].address : "--"}
            </ListItemText>
          </View>
        </Fragment>
      );
    }
  };

  const renderPhone = () => {
    if (phones && phones.length > 0) {
      return (
        <View noWrap flexDirection={"row"}>
          <Icon Source={PhoneIcon} pathColor={"black"} />
          <ListItemText marginLeft={10} align={"left"} color={"black"}>
            {phones[0].number ? phones[0].number : "--"}
          </ListItemText>
        </View>
      );
    }
  };

  return (
    <View>
      <ListItemContainer>
        <Grid
          onClick={_didSelectTenantItem}
          showDivider
          xs={isMobile ? 6 : 4}
          md={4}
          lg={4}
          title={AppStrings.Common.FilterOptions.Name}
        >
          <View paddingLeft={20} paddingRight={20} alignItems={"center"} flexDirection={"row"} noWrap>
            <ProfileImage pictureUrl={pictureUrl} />
            <ListItemText marginLeft={10} numberOfLines={2}>
              {name}
            </ListItemText>
          </View>
        </Grid>
        <Grid
          onClick={_didSelectTenantItem}
          showDivider
          xs={12}
          sm={4}
          md={4}
          lg={4}
          title={AppStrings.Tenants.NewTenant.Phone}
        >
          <View paddingLeft={10} alignItems={"center"} flexDirection={"row"}>
            {renderPhone()}
          </View>
        </Grid>
        <Grid title={AppStrings.Tenants.NewTenant.Email} onClick={_didSelectTenantItem} xs={12} sm={4} md={4} lg={4}>
          <View paddingLeft={10} alignItems={"center"} flexDirection={"row"}>
            {renderEmail()}
          </View>
        </Grid>
      </ListItemContainer>
    </View>
  );
};

export default SelectableOwnersListItem;
