import {
  AnnouncementIcon,
  ApplicationIcon,
  BillGrayIcon,
  CashIcon,
  CheckIcon,
  CreditIcon,
  DepositIcon,
  DistributionIcon,
  ElectronicSignatureIcon,
  LeadsIcon,
  LeaseListIcon,
  MaintenanceIcon,
  ManagementFeesSettingsIcon,
  MoneyIcon,
  OwnerRequestIcon,
  OwnersIcon,
  PaymentIcon,
  PropertiesIcon,
  RefundIcon,
  TransferIcon,
  UnitIcon,
  UsersIcon,
  VendorsIcon,
  WorkOrderIcon
} from "assets/icons";
import AppStrings from "locale/keys";
import Routes from "../../../appRouter/routes";
import { DialogRoutes } from "../dialogsProvider";
import type { AnyPermission } from "@doorloop/dto";
import { DataCy, ManagementFeeBasedType, ObjectPermission, PaymentMethod, ReportPermission } from "@doorloop/dto";
import type { SVGIconComponent } from "assets/icons/types";
import type qs from "query-string";
import type { ObjectWithHiddenForUserTypes } from "../../../../utils/userTypesUtils";

interface NavigationMethod {
  method: "append" | "replace";
  actionPath: string;
  queryParams?: qs.ParsedQuery<string>;
}

type CreateNewMenuItem = {
  title: string;
  icon: SVGIconComponent;
  navigationMethod?: NavigationMethod;
  permission?: AnyPermission;
  inactive?: boolean;
  dataCy?: string;
} & ObjectWithHiddenForUserTypes;

interface CreateNewMenuSection {
  title: string;
  items: CreateNewMenuItem[];
  showSeparationLine?: boolean;
  inactive?: boolean;
}

const CreateNewMenuSections: CreateNewMenuSection[] = [
  {
    title: AppStrings.DrawerItems.People,
    showSeparationLine: true,
    items: [
      {
        title: AppStrings.Prospects.Screen.Prospect,
        icon: LeadsIcon,
        navigationMethod: {
          method: "append",
          actionPath: DialogRoutes.NEW_PROSPECT
        },
        permission: ObjectPermission.prospects,
        dataCy: DataCy.sideMenu.createNewMenu.prospect,
        hiddenForUserTypes: ["HOAUser"]
      },
      // {
      //   title: AppStrings.Tenants.Screen.Tenant,
      //   icon: TenantsIcon,
      //   navigationMethod: {
      //     method: "append",
      //     actionPath: DialogRoutes.NEW_TENANT
      //   }
      // },
      {
        title: AppStrings.Owners.Screen.Owner,
        icon: OwnersIcon,
        navigationMethod: {
          method: "append",
          actionPath: DialogRoutes.NEW_OWNER
        },
        permission: ObjectPermission.owners,
        dataCy: DataCy.sideMenu.createNewMenu.owner,
        hiddenForUserTypes: ["HOAUser"]
      },
      {
        title: AppStrings.Vendors.Screen.Vendor,
        icon: VendorsIcon,
        navigationMethod: {
          method: "append",
          actionPath: DialogRoutes.NEW_VENDOR
        },
        permission: ObjectPermission.vendors,
        dataCy: DataCy.sideMenu.createNewMenu.vendor
      },
      {
        title: AppStrings.Common.CreateNewMenu.user,
        icon: UsersIcon,
        navigationMethod: {
          method: "append",
          actionPath: DialogRoutes.NEW_USER
        },
        permission: ObjectPermission.users,
        dataCy: DataCy.sideMenu.createNewMenu.user
      }
    ]
  },
  {
    title: AppStrings.Common.CreateNewMenu.TasksAndMaintenance,
    showSeparationLine: true,
    items: [
      {
        title: AppStrings.Tasks.TasksList.Task,
        icon: MaintenanceIcon,
        navigationMethod: {
          method: "append",
          actionPath: DialogRoutes.NEW_INTERNAL_TASK
        },
        permission: ObjectPermission.tasks,
        dataCy: DataCy.sideMenu.createNewMenu.task
      },
      {
        title: AppStrings.Tasks.InternalTaskDialog.WorkOrder,
        icon: WorkOrderIcon,
        navigationMethod: {
          method: "append",
          actionPath: DialogRoutes.NEW_WORK_ORDER
        },
        permission: ObjectPermission.workOrders,
        dataCy: DataCy.sideMenu.createNewMenu.workOrder
      },
      {
        title: AppStrings.Tasks.TasksList.OwnerRequest,
        icon: OwnerRequestIcon,
        navigationMethod: {
          method: "append",
          actionPath: DialogRoutes.NEW_OWNER_REQUEST
        },
        permission: ObjectPermission.ownerRequests,
        dataCy: DataCy.sideMenu.createNewMenu.ownerRequest,
        hiddenForUserTypes: ["HOAUser"]
      },
      {
        title: AppStrings.Tasks.TasksList.TenantRequest,
        icon: OwnerRequestIcon,
        navigationMethod: {
          method: "append",
          actionPath: DialogRoutes.NEW_TENANT_REQUEST
        },
        permission: ObjectPermission.tenantRequests,
        dataCy: DataCy.sideMenu.createNewMenu.tenantRequest
      }
    ]
  },
  {
    title: AppStrings.DrawerItems.Rentals,
    showSeparationLine: true,
    items: [
      {
        title: AppStrings.Properties.ListHeader.Property,
        icon: PropertiesIcon,
        navigationMethod: {
          method: "replace",
          actionPath: Routes.NEW_PROPERTY
        },
        permission: ObjectPermission.properties,
        dataCy: DataCy.sideMenu.createNewMenu.property
      },
      {
        title: AppStrings.Units.ListHeader.Unit,
        icon: UnitIcon,
        navigationMethod: {
          method: "append",
          actionPath: DialogRoutes.NEW_UNIT
        },
        permission: ObjectPermission.units,
        dataCy: DataCy.sideMenu.createNewMenu.unit
      }
    ]
  },
  {
    title: AppStrings.DrawerItems.Leasing,
    items: [
      {
        title: AppStrings.Common.CreateNewMenu.Lease,
        icon: LeaseListIcon,
        navigationMethod: {
          method: "replace",
          actionPath: Routes.NEW_DRAFT_LEASE
        },
        permission: ObjectPermission.leases,
        dataCy: DataCy.sideMenu.createNewMenu.lease
      },
      {
        title: AppStrings.DrawerItems.RentalApplications,
        icon: ApplicationIcon,
        navigationMethod: {
          method: "append",
          actionPath: DialogRoutes.NEW_RENTAL_APPLICATION
        },
        permission: ObjectPermission.rentalApplications,
        dataCy: DataCy.sideMenu.createNewMenu.rentalApplications,
        hiddenForUserTypes: ["HOAUser"]
      }
    ]
  },
  {
    title: AppStrings.Common.CreateNewMenu.TenantTransactions,
    showSeparationLine: true,
    items: [
      {
        title: AppStrings.Leases.LeaseCharge.OneTimeCharge,
        icon: CashIcon,
        navigationMethod: {
          method: "append",
          actionPath: DialogRoutes.NEW_CHARGE
        },
        permission: ObjectPermission.leaseCharges,
        dataCy: DataCy.sideMenu.createNewMenu.oneTimeCharge
      },
      {
        title: AppStrings.Leases.DraftLease.ReceivePayment,
        icon: PaymentIcon,
        navigationMethod: {
          method: "append",
          actionPath: DialogRoutes.NEW_PAYMENT
        },
        permission: ObjectPermission.leasePayments,
        dataCy: DataCy.sideMenu.createNewMenu.receivePayment
      },
      {
        title: AppStrings.Leases.LeaseCharge.OneTimeCredit,
        icon: CreditIcon,
        navigationMethod: {
          method: "append",
          actionPath: DialogRoutes.NEW_CREDIT
        },
        permission: ObjectPermission.leaseCredits,
        dataCy: DataCy.sideMenu.createNewMenu.oneTimeCredit
      },
      {
        title: AppStrings.Leases.LeaseCharge.OneTimeRefund,
        icon: RefundIcon,
        navigationMethod: {
          method: "append",
          actionPath: DialogRoutes.NEW_REFUND
        },
        permission: ObjectPermission.leaseRefunds,
        dataCy: DataCy.sideMenu.createNewMenu.oneTimeRefund
      },
      {
        title: AppStrings.Leases.LeaseTransaction.Refund.WithholdDeposit,
        icon: DepositIcon,
        navigationMethod: {
          method: "append",
          actionPath: DialogRoutes.WITHHOLD_DEPOSIT
        },
        permission: ObjectPermission.leaseRefunds,
        dataCy: DataCy.sideMenu.createNewMenu.withholdDeposit,
        hiddenForUserTypes: ["HOAUser"]
      }
    ]
  },
  {
    title: AppStrings.Common.CreateNewMenu.VendorTransactions,
    showSeparationLine: true,
    items: [
      {
        title: AppStrings.Vendors.VendorDetails.CreateBill,
        icon: BillGrayIcon,
        navigationMethod: {
          method: "append",
          actionPath: DialogRoutes.NEW_VENDOR_BILL
        },
        permission: ObjectPermission.vendorBills,
        dataCy: DataCy.sideMenu.createNewMenu.createBill
      },
      {
        title: AppStrings.Vendors.VendorDetails.PayBill,
        icon: PaymentIcon,
        navigationMethod: {
          method: "replace",
          actionPath: Routes.PAY_BILLS
        },
        permission: ObjectPermission.vendorBillPayments,
        dataCy: DataCy.sideMenu.createNewMenu.payBill
      },
      {
        title: AppStrings.Vendors.VendorDetails.AddCredit,
        icon: CreditIcon,
        navigationMethod: {
          method: "append",
          actionPath: DialogRoutes.NEW_VENDOR_CREDIT
        },
        permission: ObjectPermission.vendorCredits,
        dataCy: DataCy.sideMenu.createNewMenu.addCredit
      },
      {
        title: AppStrings.Settings.GeneralSettings.ManagementFees.ManagementFees,
        icon: ManagementFeesSettingsIcon,
        navigationMethod: {
          method: "replace",
          actionPath: Routes.MANAGEMENT_FEES,
          queryParams: {
            filter_feeBasedType: ManagementFeeBasedType.MANAGEMENT_FEES_AND_ACCOUNTS,
            period: "last-month"
          }
        },
        permission: ObjectPermission.managementFees,
        dataCy: DataCy.sideMenu.createNewMenu.managementFees
      }
    ]
  },
  {
    title: AppStrings.Common.CreateNewMenu.OwnerTransactions,
    showSeparationLine: true,
    items: [
      {
        title: AppStrings.Common.CreateNewMenu.OwnerContribution,
        icon: CashIcon,
        navigationMethod: {
          method: "append",
          actionPath: DialogRoutes.NEW_OWNER_CONTRIBUTION
        },
        permission: ObjectPermission.ownerContributions,
        dataCy: DataCy.sideMenu.createNewMenu.ownerContribution,
        hiddenForUserTypes: ["HOAUser"]
      },
      {
        title: AppStrings.Common.CreateNewMenu.OwnerDistribution,
        icon: DistributionIcon,
        navigationMethod: {
          method: "replace",
          actionPath: Routes.OWNERS_DISTRIBUTIONS
        },
        permission: ObjectPermission.ownerContributions,
        dataCy: DataCy.sideMenu.createNewMenu.ownerDistribution,
        hiddenForUserTypes: ["HOAUser"]
      }
    ]
  },
  {
    title: AppStrings.Common.CreateNewMenu.OtherTransactions,
    items: [
      {
        title: AppStrings.Common.CreateNewMenu.JournalEntry,
        icon: MaintenanceIcon,
        navigationMethod: {
          method: "append",
          actionPath: DialogRoutes.NEW_GENERAL_ENTRY
        },
        permission: ObjectPermission.journalEntries,
        dataCy: DataCy.sideMenu.createNewMenu.journalEntry
      },
      {
        title: AppStrings.Common.CreateNewMenu.BankTransfer,
        icon: TransferIcon,
        navigationMethod: {
          method: "append",
          actionPath: DialogRoutes.NEW_BANK_TRANSFER
        },
        permission: ObjectPermission.bankTransfers,
        dataCy: DataCy.sideMenu.createNewMenu.bankTransfer
      },
      {
        title: AppStrings.Common.CreateNewMenu.BankDeposit,
        icon: MoneyIcon,
        navigationMethod: {
          method: "append",
          actionPath: DialogRoutes.NEW_BANK_DEPOSIT
        },
        permission: ObjectPermission.bankDeposits,
        dataCy: DataCy.sideMenu.createNewMenu.bankDeposit
      },
      {
        title: AppStrings.Common.Enums.JournalEntryType.Expense,
        icon: CashIcon,
        navigationMethod: {
          method: "append",
          actionPath: DialogRoutes.NEW_VENDOR_EXPENSE
        },
        permission: ObjectPermission.expenses,
        dataCy: DataCy.sideMenu.createNewMenu.expense
      },
      {
        title: AppStrings.Common.CreateNewMenu.Check,
        icon: CheckIcon,
        navigationMethod: {
          method: "append",
          actionPath: DialogRoutes.NEW_VENDOR_EXPENSE,
          queryParams: {
            paymentMethod: PaymentMethod.CHECK
          }
        },
        permission: ReportPermission.printChecks,
        dataCy: DataCy.sideMenu.createNewMenu.check
      }
    ]
  },
  {
    title: AppStrings.Announcements.communications,
    items: [
      {
        title: AppStrings.Announcements.announcements,
        icon: AnnouncementIcon,
        navigationMethod: {
          method: "replace",
          actionPath: Routes.ANNOUNCEMENTS_NEW
        },
        permission: ObjectPermission.announcements,
        dataCy: DataCy.sideMenu.createNewMenu.announcement
      },
      {
        title: AppStrings.Esignatures.SignatureRequest,
        icon: ElectronicSignatureIcon,
        navigationMethod: {
          method: "replace",
          actionPath: Routes.NEW_SIGNATURE_REQUESTS
        },
        permission: ObjectPermission.esignatureRequests,
        dataCy: DataCy.sideMenu.createNewMenu.signatureRequest
      }
    ]
  }
];

export default CreateNewMenuSections;
