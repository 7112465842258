import type { FC } from "react";
import React from "react";
import { Dialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import type { BulkActionConfirmation } from "DLUI/bulkExecution/bulkConfirmation";
import type { ExecutionResult, Operation } from "engines/bulkOperationsEngine";
import { BulkExecutionDialogFrame } from "DLUI/bulkExecution/bulkExecutionDialogFrame";
import type { BulkExecutionRequestType, GetAllBaseQueryRequest } from "@doorloop/dto";

export interface BulkExecutionDialogProps {
  operations: Operation[];
  confirm?: BulkActionConfirmation;
  onFinish?: (resultAggregate?: any[], finish?: ExecutionResult) => void;
  closeHandler: (shouldRefresh?: boolean) => void;
  minHeight?: number;
  skipConfirmation?: boolean;
  bulkExecutionDialogTimeout?: number;
  totalAllExecutionRequestType?: BulkExecutionRequestType;
  isTotalAllChecked?: boolean;
  queryFilter?: GetAllBaseQueryRequest;
}

export const BulkExecutionDialog: FC<BulkExecutionDialogProps> = (props) => (
  <Dialog dialogState={DialogState.Show} onClose={() => {}} Content={() => <BulkExecutionDialogFrame {...props} />} />
);
