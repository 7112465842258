import type { EditableInputProps } from "DLUI/form/editableInput/editableInput";
import { EditableInput } from "DLUI/form";
import type { BaseDto } from "@doorloop/dto";
import type { SVGIconComponent } from "../../../../assets";
import React from "react";
import TextFieldInput from "DLUI/form/textFieldInput/textFieldInput";

export interface EditableTextFieldProps<T> extends Omit<EditableInputProps<T>, "renderInput"> {
  icon: SVGIconComponent;
  formikName: string;
  type?: HTMLInputElement["type"];
  isMobilePhone?: boolean;
}

const EditableTextField = <T extends BaseDto>({
  icon,
  formikName,
  type,
  isMobilePhone,
  ...rest
}: EditableTextFieldProps<T>) => (
  <EditableInput
    renderInput={(isLoading) => (
      <TextFieldInput
        icon={icon}
        formikName={formikName}
        type={type}
        disabled={isLoading}
        isMobilePhone={isMobilePhone}
        autoFocus
      />
    )}
    {...rest}
  />
);

export default EditableTextField;
