import type { LoginAndPasswordSettingsDisplayDto, LoginAndPasswordSettingsDto } from "@doorloop/dto";
import type { GetTenantPortalSettings } from "@doorloop/dto";
import { apiHelper } from "api/apiHelper";
import { RestApiBase } from "api/restApiBase";
import { TenantPortalServerRoutes } from "@doorloop/dto";

export class TenantPortalSettingsApi extends RestApiBase<LoginAndPasswordSettingsDisplayDto, GetTenantPortalSettings> {
  constructor() {
    super(TenantPortalServerRoutes.SETTINGS_LOGIN_AND_PASSWORD);
    this.restRoute = TenantPortalServerRoutes.SETTINGS_LOGIN_AND_PASSWORD;
  }

  updateLoginAndPassword = async (data: LoginAndPasswordSettingsDto) =>
    await apiHelper.axiosPost<LoginAndPasswordSettingsDto>({
      url: TenantPortalServerRoutes.SETTINGS_LOGIN_AND_PASSWORD + "/",
      data
    });
}

export const tenantPortalSettingsApi = new TenantPortalSettingsApi();
