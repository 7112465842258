import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { Link } from "DLUI/link";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { useTypedTranslation } from "locale";
import { CopyLink } from "screens/settings/listingSyndication/copyLink";
import { useSelector } from "react-redux";
import type { IRootState } from "store";
import { InfoLightGrayIcon } from "@/assets";
import { SyndicationItems } from "screens/settings/listingSyndication/listingSyndication.constant";

export const ZillowExpandedDetails = () => {
  const { rentalListingsURL, copyButtonText, urlTextFieldLabel } =
    SyndicationItems.find((partner) => partner.key === "zillow") || {};
  const { isMobile } = useResponsiveHelper();
  const { t } = useTypedTranslation();
  const userData = useSelector((state: IRootState) => state.auth.currentLoginResponse);
  const listingURL = `${rentalListingsURL}${userData?.currentDbTenant.id}`;

  return (
    <View noWrap alignSelf={isMobile ? undefined : "flex-end"} width={isMobile ? "100%" : "91%"}>
      <CopyLink label={urlTextFieldLabel} link={listingURL} copyButtonText={copyButtonText} />
      <View
        flexDirection={"row"}
        alignItems={"center"}
        marginTop={16}
        noWrap={isMobile}
        style={{ display: isMobile ? "block" : "flex" }}
        flex={1}
      >
        {!isMobile && <InfoLightGrayIcon />}
        <Text
          marginLeft={8}
          value={t("listing.zillowMayContact")}
          color={"secondary-gray"}
          fontSize={14}
          as={"span"}
          style={{ display: isMobile ? "contents" : "flex" }}
        />
        <Link onClick={() => console.log("should get link")} marginLeft={3}>
          <Text value={t("common.learnMore")} color={"secondary-gray"} fontSize={14} underLine={"solid"} as={"span"} />
        </Link>
      </View>
    </View>
  );
};

export default ZillowExpandedDetails;
