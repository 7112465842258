import type { FilterPanelOptionItem, FilterPanelRemoteFiltering } from "DLUI/screen/filterPanel/filterPanel";
import type { AutocompleteProps } from "@material-ui/lab/Autocomplete";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import TextInput from "DLUI/form/textField/textInput";
import { useQuery } from "@tanstack/react-query";
import type { RestApiBase } from "api/restApiBase";
import { type BaseDto } from "@doorloop/dto";
import AppStrings from "locale/keys";
import { LoadingAnimation } from "DLUI/animations/loadingAnimation";
import { useSyncWithSearchParams } from "hooks/useSyncWithSearchParams";
import { AUTOCOMPLETE_INPUT_PAGE_SIZE } from "shared/constants";
import { useRemoteDefaultValue } from "./useRemoteDefaultValue";
import { useSearchParams } from "@/hooks/useSearchParams";

export function useRemoteAutocompleteFilterInput<
  TQuery,
  TAutocomplete extends AutocompleteProps<FilterPanelOptionItem<TQuery>, false, boolean, false> = AutocompleteProps<
    FilterPanelOptionItem<TQuery>,
    false,
    boolean,
    false
  >
>({
  apiHandler,
  name,
  displayNameKey,
  filterFieldValue,
  filterFieldName,
  disableClearable,
  defaultValue,
  dataCy,
  queryParamsGetter,
  queryParams = {},
  inputProps = {}
}: Pick<
  FilterPanelRemoteFiltering<TQuery>,
  | "name"
  | "apiHandler"
  | "displayNameKey"
  | "filterFieldValue"
  | "filterFieldName"
  | "disableClearable"
  | "queryParams"
  | "queryParamsGetter"
  | "inputProps"
  | "defaultValue"
  | "dataCy"
>): TAutocomplete {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const query = queryParams["page_size"] ? queryParams : { ...queryParams, page_size: AUTOCOMPLETE_INPUT_PAGE_SIZE };
  const customQuery = queryParamsGetter?.(searchParams);
  const combinedQuery = customQuery ? { ...query, ...customQuery } : query;
  const apiQueryKey = apiHandler?.restRoute || filterFieldName;

  const {
    data: options = [],
    isLoading,
    isInitialLoading
  } = useQuery(
    ["filter-input-remote-autocomplete", apiQueryKey, combinedQuery],
    async ({ signal }) => {
      if (signal?.aborted) {
        return;
      }
      const response = await (apiHandler.getAll as RestApiBase<BaseDto, TQuery>["getAll"])(combinedQuery as TQuery);
      return response;
    },
    {
      select: (response) => {
        if (!response?.data?.data) return [];

        return response.data.data.map<FilterPanelOptionItem<TQuery>>((item) => {
          return {
            value: item[filterFieldValue],
            displayName: item[displayNameKey],
            filterFieldName
          };
        });
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  );

  const queryDefaultValue = useRemoteDefaultValue(defaultValue);
  const { onValueChange, queryValue } = useSyncWithSearchParams(filterFieldName as string, queryDefaultValue);
  const inputValue = options.find((option) => option.value === queryValue) ?? null;

  useEffect(() => {
    if (isInitialLoading || inputValue) {
      return;
    }
    if (!inputValue && !queryValue) {
      return;
    }

    onValueChange(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, isInitialLoading, queryValue]);

  const onChange = useCallback<NonNullable<TAutocomplete["onChange"]>>(
    (_, value) => {
      onValueChange(value?.value as string);
    },
    [onValueChange]
  );

  return useMemo<TAutocomplete>(
    () =>
      ({
        renderInput: (params) => (
          <TextInput
            label={t(name)}
            icons={
              isLoading
                ? {
                    end: {
                      Icon: LoadingSpinner
                    }
                  }
                : undefined
            }
            dataCy={dataCy}
            {...params}
            {...inputProps}
          />
        ),
        onChange,
        options,
        value: inputValue,
        disableClearable,
        getOptionLabel: (option) => t(option.displayName),
        getOptionSelected: (option, selectedItem) => option.value === selectedItem.value,
        noOptionsText: t(AppStrings.Common.NoResultsFound)
      }) as TAutocomplete,
    [name, t, options, disableClearable, onChange, inputValue, isLoading, inputProps, dataCy]
  );
}

const LoadingSpinner = memo(() => (
  <div
    style={{
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingRight: 14
    }}
  >
    <LoadingAnimation type="spinner" width={14} height={14} />
  </div>
));
LoadingSpinner.displayName = "LoadingSpinner";
