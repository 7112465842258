import type { FC } from "react";
import React from "react";
import { View } from "DLUI/view";
import type { FillColors } from "DLUI/icon";
import { Icon } from "DLUI/icon";
import type { SVGIconComponent } from "assets/icons";
import { InformationCircleLightBlueIcon } from "assets/icons";
import type { ReplaceObjectInterface } from "DLUI/text/text";
import Text from "DLUI/text/text";
import SeparationLine from "components/DLUI/separatorView/separationLine";
import type { Alignment } from "DLUI/text/types";
import ColorsEnum from "utils/colorsEnum";
import { useResponsiveHelper } from "contexts/responsiveContext";

interface Props {
  label?: string;
  title?: string;
  replaceObject?: ReplaceObjectInterface;
  background?: ColorsEnum;
  IconComponent?: SVGIconComponent;
  hideIcon?: boolean;
  AppendComponent?: FC;
  LabelComponent?: FC;
  shouldShow?: boolean;
  fontSize?: number;
  iconSize?: number;
  iconWidth?: number | string;
  iconHeight?: number | string;
  minHeight?: number;
  marginTop?: number;
  hideVerticalSeparator?: boolean;
  separatorColor?: ColorsEnum;
  iconPathColor?: FillColors;
  titleAlignment?: Alignment;
  labelAlignment?: Alignment;
  dataCy?: string;
  padding?: number;
}

export const Notification: FC<Props> = ({
  label,
  title,
  replaceObject,
  background = ColorsEnum.LightBlueFadedMore,
  IconComponent = InformationCircleLightBlueIcon,
  hideIcon,
  AppendComponent,
  LabelComponent,
  shouldShow = true,
  fontSize = 14,
  minHeight = 50,
  marginTop,
  iconSize,
  iconWidth,
  iconHeight,
  hideVerticalSeparator = false,
  separatorColor = ColorsEnum.BrightBlueFaded,
  iconPathColor,
  titleAlignment = "center",
  labelAlignment = "left",
  dataCy,
  padding = 20
}: Props) => {
  const { isMobile } = useResponsiveHelper();

  return shouldShow ? (
    <View
      flexDirection={isMobile ? "column" : "row"}
      minHeight={minHeight}
      alignItems={"center"}
      borderRadius={7}
      marginBottom={20}
      style={{ background }}
      marginTop={marginTop}
      paddingRight={padding}
      paddingLeft={padding}
      paddingTop={padding}
      paddingBottom={padding}
      noWrap
      dataCy={dataCy}
    >
      {!hideIcon && (
        <View autoWidth>
          <Icon
            Source={IconComponent}
            width={iconWidth || 25}
            height={iconHeight || 25}
            marginRight={isMobile ? 0 : hideVerticalSeparator ? 10 : 20}
            size={iconSize}
            pathColor={iconPathColor}
          />
        </View>
      )}
      {!isMobile && !hideVerticalSeparator && (
        <SeparationLine marginRight={20} height={minHeight - 20} width={1} color={separatorColor} />
      )}
      {LabelComponent ? (
        <LabelComponent />
      ) : (
        <View flex={1} marginTop={isMobile ? 10 : 0}>
          {title && (
            <Text
              alignSelf={isMobile ? "center" : "flex-start"}
              value={title}
              fontSize={fontSize}
              fontWeight={600}
              marginBottom={5}
              align={titleAlignment}
            />
          )}
          {label && <Text align={labelAlignment} value={label} replaceObject={replaceObject} fontSize={fontSize} />}
        </View>
      )}
      {AppendComponent && <AppendComponent />}
    </View>
  ) : null;
};
