import { DataCy, ReportId, ReportPermission, TaskStatus, TaskType } from "@doorloop/dto";
import AppStrings from "locale/keys";
import moment from "moment";
import { NavigationManager } from "utils/navigation";
import type { ReportScreenItemType } from "DLUI/report/screen/reportScreenItemType";
import { filterObjectsByUserType } from "../../../../../utils/userTypesUtils";

const didPressTasksByPriority = () => {
  NavigationManager.runTasksReport({
    groupBy: "priority",
    filter_type: TaskType.INTERNAL_TASK
  });
};

const didPressTasksByProperty = () => {
  NavigationManager.runTasksReport({
    groupBy: "property",
    filter_type: TaskType.INTERNAL_TASK
  });
};

const didPressTasksByStatus = () => {
  NavigationManager.runTasksReport({
    groupBy: "status",
    filter_type: TaskType.INTERNAL_TASK
  });
};

const didPressCompletedTasks = () => {
  NavigationManager.runTasksReport({
    filter_status: TaskStatus.COMPLETED,
    filter_type: TaskType.INTERNAL_TASK
  });
};

const didPressOpenTasks = () => {
  NavigationManager.runTasksReport({
    filter_status: TaskStatus.IN_PROGRESS,
    filter_type: TaskType.INTERNAL_TASK
  });
};

const didPressOverdueTasks = () => {
  NavigationManager.runTasksReport({
    filter_dueBefore_from: moment().format("YYYY-MM-DD").toString(),
    filter_dueBefore_to: moment().add("90", "days").format("YYYY-MM-DD").toString(),
    filter_type: TaskType.INTERNAL_TASK
  });
};
const didPressWorkOrderReport = () => {
  NavigationManager.runWorkOrdersReport({});
};

const didPressTenantRequestReport = () => {
  NavigationManager.runTasksReport({
    filter_type: TaskType.TENANT_REQUEST
  });
};

const items: ReportScreenItemType[] = [
  {
    description: AppStrings.Reports.ReportsScreen.CompletedTasks,
    onPress: didPressCompletedTasks,
    permission: ReportPermission.tasks,
    id: ReportId.COMPLETE_TASKS,
    dataCy: DataCy.reports.screenItems.tasks.completedTasks
  },
  {
    description: AppStrings.Reports.ReportsScreen.OpenTasks,
    onPress: didPressOpenTasks,
    permission: ReportPermission.tasks,
    id: ReportId.OPEN_TASKS,
    dataCy: DataCy.reports.screenItems.tasks.openTasks
  },
  {
    description: AppStrings.Reports.ReportsScreen.OverdueTasks,
    onPress: didPressOverdueTasks,
    permission: ReportPermission.tasks,
    id: ReportId.OVERDUE_TASKS,
    dataCy: DataCy.reports.screenItems.tasks.overdueTasks
  },
  {
    description: AppStrings.Reports.ReportsScreen.TasksByPriority,
    onPress: didPressTasksByPriority,
    permission: ReportPermission.tasks,
    id: ReportId.TASKS_BY_PRIORITY,
    dataCy: DataCy.reports.screenItems.tasks.tasksByPriority
  },
  {
    description: AppStrings.Reports.ReportsScreen.TasksByProperty,
    onPress: didPressTasksByProperty,
    permission: ReportPermission.tasks,
    id: ReportId.TASKS_BY_PROPERTY,
    dataCy: DataCy.reports.screenItems.tasks.tasksByProperty
  },
  {
    description: AppStrings.Reports.ReportsScreen.TasksByStatus,
    onPress: didPressTasksByStatus,
    permission: ReportPermission.tasks,
    id: ReportId.TASKS_BY_STATUS,
    dataCy: DataCy.reports.screenItems.tasks.tasksByStatus
  },
  {
    description: AppStrings.Tasks.InternalTaskDialog.WorkOrder,
    onPress: didPressWorkOrderReport,
    permission: ReportPermission.workOrders,
    id: ReportId.WORK_ORDERS,
    dataCy: DataCy.reports.screenItems.tasks.workOrder
  },
  {
    description: AppStrings.Common.Recurring.RecurringTasks,
    onPress: () => NavigationManager.runRecurringTasksReport(),
    permission: ReportPermission.tasks,
    id: ReportId.RECURRING_TASKS,
    dataCy: DataCy.reports.screenItems.tasks.recurringTasks
  },
  {
    description: AppStrings.Common.TenantRequestsSettingItemTitle,
    onPress: didPressTenantRequestReport,
    permission: ReportPermission.tasks,
    id: ReportId.TENANT_REQUESTS,
    dataCy: DataCy.reports.screenItems.tasks.tenantRequests
  }
];

const getTasksItems = (isHOAUser: boolean): ReportScreenItemType[] => {
  if (isHOAUser) {
    return filterObjectsByUserType(items, "HOAUser");
  }

  return items;
};

export default getTasksItems;
