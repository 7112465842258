import { makeStyles } from "@material-ui/core";

export const useClasses = makeStyles({
  closeIconButton: {
    "& button": {
      marginTop: -10
    },
    position: "absolute",
    right: 0
  },
  actionList: {
    counterReset: "section",
    display: "flex",
    flexDirection: "column",
    margin: 0,
    padding: 0,
    borderTop: "1px solid rgba(122,134,153,0.2)",
    "& li": {
      "&::before": {
        counterIncrement: "section",
        content: "counter(section)",
        height: "40px",
        width: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "20px",
        fontWeight: 700,
        backgroundColor: "rgba(122, 134, 153, 0.1)"
      },
      padding: "30px 0px",
      borderBottom: "1px solid rgba(122,134,153,0.2)",
      gap: "10px",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      width: "100%"
    }
  }
});
