"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.merchantAccountUtils = void 0;
const ePayPaymentMethod_enum_1 = require("../../common/ePayPaymentMethod.enum");
const mathUtils_1 = require("../../utils/mathUtils");
class MerchantAccountUtils {
    calculateFeesFromTotalWithFees(dto, totalWithFees, epayMethod) {
        const minAndPercentFees = this.getMinFeeAndPercentFeeForMerchantAccountAndMethod(dto, epayMethod);
        let fees = mathUtils_1.mathUtils.multiply(totalWithFees, mathUtils_1.mathUtils.divide(minAndPercentFees.percentFee, 100));
        let subtotal = mathUtils_1.mathUtils.substract(totalWithFees, fees);
        if (fees < minAndPercentFees.minFee) {
            fees = minAndPercentFees.minFee;
            subtotal = mathUtils_1.mathUtils.substract(totalWithFees, minAndPercentFees.minFee);
        }
        return {
            subtotal,
            fees,
            totalWithFees
        };
    }
    calculateFees(dto, subTotal, epayMethod) {
        const minAndPercentFees = this.getMinFeeAndPercentFeeForMerchantAccountAndMethod(dto, epayMethod);
        let fees = mathUtils_1.mathUtils.divide(mathUtils_1.mathUtils.multiply(subTotal, minAndPercentFees.percentFee), 100);
        if (fees < minAndPercentFees.minFee) {
            fees = minAndPercentFees.minFee;
        }
        return {
            subTotal,
            fees,
            totalWithFees: subTotal + fees
        };
    }
    getMinFeeAndPercentFeeForMerchantAccountAndMethod(dto, epayMethod) {
        let minFee = 0;
        let percentFee = 0;
        switch (epayMethod) {
            case ePayPaymentMethod_enum_1.EPayPaymentMethod.CARD: {
                if (!(dto === null || dto === void 0 ? void 0 : dto.cardEnabled)) {
                    throw new Error("Card payments are not supported for this merchant account");
                }
                minFee = dto.cardMin || 0;
                percentFee = dto.cardPercent || 0;
                break;
            }
            case ePayPaymentMethod_enum_1.EPayPaymentMethod.ECHECK: {
                if (!(dto === null || dto === void 0 ? void 0 : dto.eCheckEnabled)) {
                    throw new Error("Card payments are not supported for this merchant account");
                }
                minFee = dto.eCheckMin || 0;
                percentFee = dto.eCheckPercent || 0;
                break;
            }
        }
        return { minFee, percentFee };
    }
}
exports.merchantAccountUtils = new MerchantAccountUtils();
