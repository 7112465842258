"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AtLeastOneTrueConstraint = void 0;
exports.AtLeastOneTrue = AtLeastOneTrue;
const class_validator_1 = require("class-validator");
let AtLeastOneTrueConstraint = class AtLeastOneTrueConstraint {
    validate(value, args) {
        const keys = args.constraints[0];
        // Check each key, and consider only `true` values. Ignore `undefined`.
        return keys.some((key) => value[key] === true);
    }
    defaultMessage(args) {
        const keys = args.constraints[0];
        return `At least one of ${keys.join(", ")} must be true.`;
    }
};
exports.AtLeastOneTrueConstraint = AtLeastOneTrueConstraint;
exports.AtLeastOneTrueConstraint = AtLeastOneTrueConstraint = __decorate([
    (0, class_validator_1.ValidatorConstraint)({ name: "atLeastOneTrue", async: false })
], AtLeastOneTrueConstraint);
function AtLeastOneTrue(keys) {
    return (0, class_validator_1.Validate)(AtLeastOneTrueConstraint, [keys], {
        message: `At least one of ${keys.join(", ")} must be true.`
    });
}
