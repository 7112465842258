import { ObjectPermission } from "@doorloop/dto";
import DLButton from "DLUI/button/dlButton";
import EmptyDataView from "DLUI/emptyDataView";
import AppStrings from "locale/keys";
import type { FC } from "react";
import React from "react";
import { NavigationManager } from "utils/navigation";

interface Props {
  isCommunication?: boolean;
  tenantId?: string;
}

export const EmptyAnnouncement: FC<Props> = ({ isCommunication, tenantId }: Props) => (
  <EmptyDataView
    instructionsText={
      isCommunication ? AppStrings.Announcements.noCommunicationsFound : AppStrings.Announcements.noAnnouncementsFound
    }
    actionButton={
      <DLButton
        actionText={AppStrings.Announcements.newAnnouncement}
        onClick={() => NavigationManager.createNewAnnouncement(tenantId)}
        icons={{ start: { isHidden: false } }}
        disableMobileCollapse
        clearance={{
          permission: ObjectPermission.announcements,
          field: "create"
        }}
      />
    }
  />
);
