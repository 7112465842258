export function composeRefs<T>(...refs: Array<React.Ref<T>>) {
  return (value: T) => {
    for (const ref of refs) {
      setRef(ref, value);
    }
  };
}

export function setRef<T>(ref: React.Ref<T>, value: T) {
  if (typeof ref === "function") {
    ref(value);
  } else if (ref) {
    (ref as React.MutableRefObject<T>).current = value;
  }
}
