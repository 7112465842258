import { createStyles, makeStyles } from "@material-ui/styles";

import type { Theme } from "styles/defaultTheme";

const PADDING_HORIZONTAL = 12;

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: `calc(100% - ${PADDING_HORIZONTAL * 2}px)`,
      height: 30,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: 4,
      padding: `8px ${PADDING_HORIZONTAL}px`,
      marginBottom: 16,
      gap: 12,
      backgroundColor: theme.newPalette.background.neutral2
    }
  })
);
