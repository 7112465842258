import { MOBILE_TOP_NAVIGATION_BAR_HEIGHT } from "DLUI/screen/mobile/mobileTopNavigationBar";
import { DRAWER_WIDTH, MINIMIZED_DRAWER_WIDTH } from "DLUI/drawer/drawer";
import type { ViewComponentProps } from "DLUI/view/view";

interface SideBarStyleProps {
  layoutStyle: ViewComponentProps;
  outerContentStyle: ViewComponentProps;
  innerContentStyle: ViewComponentProps;
  sideBarStyle: ViewComponentProps;
  topBarStyle: ViewComponentProps;
}

export const getSideBarResponsiveStyle = (
  isTabletOrMobile: boolean,
  isOnlyTablet: boolean,
  isSideMenuIsCollapsed: boolean
): SideBarStyleProps => {
  const drawerWidth = isSideMenuIsCollapsed ? MINIMIZED_DRAWER_WIDTH : DRAWER_WIDTH;

  const responsiveStyle: SideBarStyleProps = {
    layoutStyle: {
      height: "100%",
      overflow: "hidden",
      minHeight: "initial"
    },
    outerContentStyle: {
      flexDirection: "row",
      noWrap: true
    },
    innerContentStyle: {
      height: "100%",
      flexDirection: "row",
      width: `calc(100% - ${drawerWidth}px`
    },
    sideBarStyle: {
      marginLeft: 0
    },
    topBarStyle: {
      marginTop: -MOBILE_TOP_NAVIGATION_BAR_HEIGHT
    }
  };

  if (isTabletOrMobile) {
    responsiveStyle.layoutStyle.height = "auto";
    responsiveStyle.layoutStyle.minHeight = "100vh";
    responsiveStyle.layoutStyle.overflow = undefined;
    responsiveStyle.outerContentStyle.flexDirection = "column";
    responsiveStyle.outerContentStyle.noWrap = false;
    responsiveStyle.outerContentStyle.height = `calc(100% - ${MOBILE_TOP_NAVIGATION_BAR_HEIGHT}px)`;
    responsiveStyle.innerContentStyle.width = `100%`;
    responsiveStyle.innerContentStyle.flexDirection = "column";
    responsiveStyle.sideBarStyle.marginLeft = -DRAWER_WIDTH;
    responsiveStyle.topBarStyle.marginTop = 0;
  }

  if (isOnlyTablet) {
    responsiveStyle.innerContentStyle.flexDirection = "row";
  }

  return responsiveStyle;
};
