import type { GetAllLeaseReversedPaymentsQuery } from "@doorloop/dto";
import type { LeaseReversedPaymentDto } from "@doorloop/dto";
import { ServerRoutes } from "@doorloop/dto";
import { RestApiBase } from "./restApiBase";

export class LeaseReversedPaymentsApi extends RestApiBase<LeaseReversedPaymentDto, GetAllLeaseReversedPaymentsQuery> {
  constructor() {
    super(ServerRoutes.LEASE_REVERSED_PAYMENTS);
    this.restRoute = ServerRoutes.LEASE_REVERSED_PAYMENTS;
  }
}

export const leaseReversedPaymentsApi = new LeaseReversedPaymentsApi();
