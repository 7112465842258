import { useTranslation } from "react-i18next";
import type { FilterChipInfo } from "../types";
import { useSearchParamsSetter } from "@/hooks/useSearchParamsSetter";
import startCase from "lodash/startCase";
import { makeStyles } from "@material-ui/core";
import type { Theme } from "@/styles/defaultTheme";
import Text from "@/components/DLUI/text";
import clsx from "clsx";
import { XGrayIcon } from "@/assets";
import { FilterChipsContext } from "../context";
import { useContextSelector } from "use-context-selector";
import { useAnalyticsService } from "@/hooks/useAnalyticsService";

function filterKeyToDisplayValue(filterKey: string) {
  return startCase(filterKey.replace("filter_", ""));
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    chipItem: {
      display: "flex",
      alignItems: "center",
      gap: 6,
      border: "1px solid",
      borderColor: theme.newPalette.other.divider,
      borderRadius: 4,
      height: 32,
      padding: "0px 6px 0px 8px"
    },
    chipItemButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& path": {
        fill: theme.newPalette.text.secondary
      },
      "&:disabled": {
        display: "none"
      },
      "&:not(:disabled)": {
        "&:hover": {
          "& path": {
            fill: theme.newPalette.text.primary
          }
        }
      }
    },
    chipsItemTextContainer: {
      display: "flex",
      gap: 4
    }
  };
});

interface ChipItemProps {
  chip: FilterChipInfo;
}

export function ChipItem(props: ChipItemProps) {
  const { chip } = props;
  const removeFilterChip = useContextSelector(FilterChipsContext, (context) => context.removeFilterChip);
  const { t } = useTranslation();
  const searchParamsSetter = useSearchParamsSetter();
  const classes = useStyles();
  const { dispatchAnalytics } = useAnalyticsService();

  const handleDeleteFromQuery = () => {
    removeFilterChip(chip.key);

    setTimeout(() => {
      searchParamsSetter((searchParams) => {
        searchParams.delete(chip.key);
        return searchParams;
      }, "replace");
      dispatchAnalytics(
        "filter_chip_x_remove",
        {
          filterType: chip.key,
          filterValue: t(chip.displayValue)
        },
        {
          addPlatformLocation: true
        }
      );
    }, 0);
  };

  const displayKey = chip.displayKey ? t(chip.displayKey) : filterKeyToDisplayValue(chip.key);

  return (
    <li className={classes.chipItem}>
      <div className={classes.chipsItemTextContainer}>
        <Text fontSize={12} bold color="secondary-gray" value={`${displayKey}:`} as="span" />
        <Text fontSize={12} bold value={chip.displayValue} as="span" />
      </div>
      <button
        className={clsx(classes.chipItemButton, "button-reset")}
        onClick={handleDeleteFromQuery}
        disabled={chip.disabled}
      >
        <XGrayIcon width={24} height={24} />
      </button>
    </li>
  );
}
