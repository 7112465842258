"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsYearBeforeNowPlusConstraint = void 0;
exports.IsYearBeforeNowPlus = IsYearBeforeNowPlus;
const class_validator_1 = require("class-validator");
let IsYearBeforeNowPlusConstraint = class IsYearBeforeNowPlusConstraint {
    getDate(args) {
        const [yearsToAdd] = args.constraints;
        const now = new Date(Date.now());
        return new Date(now.getFullYear() + yearsToAdd, now.getMonth(), now.getDate());
    }
    validate(year, args) {
        const plusDate = this.getDate(args);
        return year < plusDate.getFullYear();
    }
    defaultMessage(args) {
        const plusDate = this.getDate(args);
        return `year must be before ${plusDate.getFullYear()}`;
    }
};
exports.IsYearBeforeNowPlusConstraint = IsYearBeforeNowPlusConstraint;
exports.IsYearBeforeNowPlusConstraint = IsYearBeforeNowPlusConstraint = __decorate([
    (0, class_validator_1.ValidatorConstraint)()
], IsYearBeforeNowPlusConstraint);
function IsYearBeforeNowPlus(yearsToAdd, validationOptions) {
    // eslint-disable-next-line @typescript-eslint/ban-types
    return function (object, propertyName) {
        (0, class_validator_1.registerDecorator)({
            target: object.constructor,
            propertyName,
            constraints: [yearsToAdd],
            options: validationOptions,
            validator: IsYearBeforeNowPlusConstraint
        });
    };
}
