import BugSnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import type { FC } from "react";
import React from "react";
import PageNotFoundScreen from "screens/pageNotFound";
import { useSelector } from "react-redux";
import type { IRootState } from "store";
import { clientEnvironment, isProductionEnv } from "utils/environmentHelper";
import { createErrorService, ErrorServiceContext } from "utils/errorBoundary/errorService";

interface ExpectedError {
  err: string | RegExp;
  handler?: () => void;
}

const apiKey = process.env.REACT_APP_BUGSNAG_API_KEY;
const appType = "client";

const dispatchToBugSnag = isProductionEnv && apiKey;

const getVersion = () => window.__DL_META__()?.latest_tag || "0.0.0";

const expectedErrors: ExpectedError[] = [
  { err: "registration failed - permission denied" },
  { err: /Failed to register a ServiceWorker for scope .* An SSL certificate error occurred when fetching the script/i }
];

const initBugSnag = () => {
  if (apiKey) {
    console.log("Initializing BS");
    BugSnag.start({
      apiKey,
      appType,
      releaseStage: clientEnvironment,
      appVersion: window.DOORLOOP_LATEST_TAG || "0.0.0",
      plugins: [new BugsnagPluginReact()],
      onError: (e) => {
        e.app.version = getVersion();
      }
    });
  }
  return BugSnag.getPlugin("react")?.createErrorBoundary(React);
};

const ErrorBoundaryComponent = initBugSnag();
console.log("ErrorBoundaryComponent", ErrorBoundaryComponent);
export const ErrorBoundary: FC = ({ children }) => {
  const { currentLoginResponse } = useSelector((state: IRootState) => state.auth);
  if (!ErrorBoundaryComponent) {
    return <>{children}</>;
  }
  if (currentLoginResponse && dispatchToBugSnag) {
    BugSnag.setUser(currentLoginResponse.id, currentLoginResponse.loginEmail, currentLoginResponse.name);
  }

  return (
    <ErrorServiceContext.Provider
      value={createErrorService({
        bugsnag: BugSnag
      })}
    >
      <ErrorBoundaryComponent
        FallbackComponent={() => (
          <div style={{ height: "100vh" }}>
            <PageNotFoundScreen errorView />
          </div>
        )}
      >
        {children}
      </ErrorBoundaryComponent>
    </ErrorServiceContext.Provider>
  );
};
