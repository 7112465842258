import { useAnalyticsService } from "@/hooks/useAnalyticsService";
import { useEffect, useRef } from "react";
import { SEARCH_QUERY_KEY } from "../filterPanel.constants";
import isEqual from "lodash/isEqual";

interface UseDispatchFilterAnalyticsOptions {
  onInputChange?: (key: string, value: string) => void;
  searchKey?: string;
}

const ANALYTICS_TIMEOUT = 1000;
const DEFAULT_OPTIONS: UseDispatchFilterAnalyticsOptions = { searchKey: SEARCH_QUERY_KEY };

function getKeysDiff<TQuery>(queryFilter: TQuery, previousQueryFilter: TQuery) {
  const previousKeys = Object.keys(previousQueryFilter as Record<string, string>);
  const currentKeys = Object.keys(queryFilter as Record<string, string>);

  return previousKeys.filter((key) => !currentKeys.includes(key));
}

export function useDispatchFilterAnalytics<TQuery>(queryFilter: TQuery, options = DEFAULT_OPTIONS) {
  const { onInputChange = DEFAULT_OPTIONS.onInputChange, searchKey = DEFAULT_OPTIONS.searchKey } =
    options ?? DEFAULT_OPTIONS;

  const { dispatchAnalytics } = useAnalyticsService();
  const previousQueryFilterRef = useRef<TQuery | null>(null);
  const isFirstChangeRef = useRef(true);

  useEffect(() => {
    if (isFirstChangeRef.current) {
      isFirstChangeRef.current = false;
      return;
    }

    const timeout = setTimeout(() => {
      const { current: previous } = previousQueryFilterRef;

      if (isEqual(queryFilter, previousQueryFilterRef.current)) {
        return;
      }

      if (previous) {
        Object.keys(queryFilter as Record<string, string>).forEach((filterKey) => {
          if (isEqual(queryFilter[filterKey], previous?.[filterKey])) {
            return;
          }

          if (filterKey === searchKey) {
            dispatchAnalytics("inpage_search_applied", {
              userSearchQuery: queryFilter[filterKey]
            });
            return;
          }

          onInputChange?.(filterKey, queryFilter[filterKey]);
          dispatchAnalytics("filter_applied", {
            filterType: filterKey,
            filterValue: queryFilter[filterKey]
          });
        });

        const keysDiff = getKeysDiff(queryFilter, previous);

        keysDiff.forEach((key) => {
          if (key === searchKey) {
            dispatchAnalytics("inpage_search_applied", {
              userSearchQuery: ""
            });
            return;
          }

          onInputChange?.(key, "");
          dispatchAnalytics("filter_applied", {
            filterType: key,
            filterValue: ""
          });
        });
      }

      previousQueryFilterRef.current = queryFilter;
    }, ANALYTICS_TIMEOUT);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryFilter, searchKey]);
}
