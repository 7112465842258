"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConversationMessageDto = void 0;
const class_validator_1 = require("class-validator");
const base_dto_1 = require("../base.dto");
const communicationSubjectType_enum_1 = require("../communications/communicationSubjectType.enum");
const conversationCommunicationType_1 = require("./conversationCommunicationType");
const conversationMessageDirection_1 = require("./conversationMessageDirection");
const conversationMessageStatus_1 = require("./conversationMessageStatus");
const conversationMessageType_1 = require("./conversationMessageType");
const conversationParticipant_dto_1 = require("./conversationParticipant.dto");
class ConversationMessageDto extends base_dto_1.BaseDto {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.ConversationMessageDto = ConversationMessageDto;
__decorate([
    (0, class_validator_1.IsEnum)(conversationCommunicationType_1.ConversationCommunicationType),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], ConversationMessageDto.prototype, "communicationType", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(communicationSubjectType_enum_1.CommunicationSubjectType),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], ConversationMessageDto.prototype, "subjectType", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(10000),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], ConversationMessageDto.prototype, "bodyText", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(10000),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], ConversationMessageDto.prototype, "bodyHTML", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({
        each: true
    }),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Array)
], ConversationMessageDto.prototype, "attachments", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(conversationMessageType_1.ConversationMessageType),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], ConversationMessageDto.prototype, "type", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(conversationMessageStatus_1.ConversationMessageStatus),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], ConversationMessageDto.prototype, "status", void 0);
__decorate([
    (0, class_validator_1.IsDate)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", Date)
], ConversationMessageDto.prototype, "sentAt", void 0);
__decorate([
    (0, class_validator_1.IsDate)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Date)
], ConversationMessageDto.prototype, "deliveredAt", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", conversationParticipant_dto_1.ConversationParticipantDto)
], ConversationMessageDto.prototype, "from", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", conversationParticipant_dto_1.ConversationParticipantDto)
], ConversationMessageDto.prototype, "to", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(conversationMessageDirection_1.ConversationMessageDirection),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], ConversationMessageDto.prototype, "direction", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({
        each: true
    }),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Array)
], ConversationMessageDto.prototype, "tasks", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], ConversationMessageDto.prototype, "isRead", void 0);
