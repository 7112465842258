import React, { Fragment } from "react";
import { View } from "DLUI/view";
import { Icon } from "DLUI/icon";
import Text from "DLUI/text";
import type { SVGIconComponent } from "../../../../assets";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  icon: SVGIconComponent;
  onItemPress: () => void;
  title: string;
  dataCy?: string;
}

const SectionItem = ({ onItemPress, icon, dataCy, title }) => (
  <View
    marginTop={10}
    flexDirection={"row"}
    className={"createNewMenuItem"}
    alignItems={"center"}
    width={"auto"}
    borderRadius={10}
    onClick={onItemPress}
    minHeight={40}
    minWidth={288}
  >
    <View alignItems={"center"} flexDirection={"row"} paddingLeft={20} paddingRight={20} dataCy={dataCy}>
      <Icon width={25} height={25} Source={icon} pathColor={"black"} />
      <Text value={title} color={"black"} marginLeft={20} paddingRight={20} fontSize={14} />
    </View>
  </View>
);

const SectionItemMobile = ({ onItemPress, title, icon }) => (
  <View marginTop={20} width={"50%"} className={"createNewMenuMobileItem"}>
    <View paddingRight={10} paddingLeft={10}>
      <View
        alignItems={"center"}
        justifyContent={"center"}
        height={75}
        backgroundColor={"light"}
        borderRadius={5}
        onClick={onItemPress}
      >
        <Icon width={25} height={25} Source={icon} pathColor={"black"} />
        <Text value={title} color={"black"} marginLeft={20} paddingRight={20} fontSize={14} marginTop={10} />
      </View>
    </View>
  </View>
);

const MenuSectionItem: React.FC<ComponentProps> = ({ icon, onItemPress, title, dataCy }: ComponentProps) => {
  const { isTabletOrMobile } = useResponsiveHelper();

  return (
    <Fragment>
      {isTabletOrMobile ? (
        <SectionItemMobile onItemPress={onItemPress} icon={icon} title={title} />
      ) : (
        <SectionItem onItemPress={onItemPress} dataCy={dataCy} icon={icon} title={title} />
      )}
    </Fragment>
  );
};

export default MenuSectionItem;
