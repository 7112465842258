import { View } from "DLUI/view";
import React from "react";
import Text from "DLUI/text";
import ColorsEnum from "../../../../utils/colorsEnum";

interface ComponentProps {
  title?: string;
  amount?: number;
  autoWidth?: boolean;
  underLineColor?: ColorsEnum;
  marginRight?: number;
}

const DialogAmountView: React.FC<ComponentProps> = ({
  title,
  amount,
  autoWidth,
  underLineColor,
  marginRight
}: ComponentProps) => {
  const borderBottomColor = underLineColor ? underLineColor : ColorsEnum.Pink;
  return (
    <View autoWidth={autoWidth} flexDirection={"row"} justifyContent={"flex-end"} marginRight={marginRight}>
      <View
        width={"auto"}
        minWidth={150}
        height={80}
        justifyContent={"center"}
        alignItems={"flex-start"}
        style={{
          borderBottom: `3px solid ${borderBottomColor}`
        }}
      >
        <Text value={title} color={"black"} fontSize={16} fontWeight={700} />
        <Text value={String(amount)} color={"black"} fontSize={35} formatType={"currency"} marginTop={5} />
      </View>
    </View>
  );
};

export default DialogAmountView;
