import type { DeepKeys, LateFeesPolicyDto } from "@doorloop/dto";
import { useCallback, useMemo } from "react";
import { getIn, useFormikContext } from "formik";
import type { LateFeesFormTypes, LateFeesPrefixes } from "DLUI/dialogs/settings/common/lateFees/types";

interface UseLateFeeSettingsProps {
  formikPathName?: DeepKeys<LateFeesPrefixes>;
}

type UseLateFeeSettingsHook = (props: UseLateFeeSettingsProps) => {
  getFieldPath: (suffix: DeepKeys<LateFeesPolicyDto>) => DeepKeys<LateFeesFormTypes>;
  lateFeeSettings: LateFeesPolicyDto | undefined;
};

export const useLateFeeSettings: UseLateFeeSettingsHook = ({ formikPathName }) => {
  const formikRef = useFormikContext<LateFeesFormTypes>();
  const getFieldPath = useCallback(
    (suffix: DeepKeys<LateFeesPolicyDto>) =>
      formikPathName ? (`${formikPathName}.${suffix}` as DeepKeys<LateFeesFormTypes>) : suffix,
    [formikPathName]
  );

  const lateFeeSettings: LateFeesPolicyDto | undefined = useMemo(
    () => (formikPathName ? getIn(formikRef.values, formikPathName) : formikRef.values),
    [formikPathName, formikRef.values]
  );

  return { getFieldPath, lateFeeSettings };
};
