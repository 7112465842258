import React from "react";
import Slide from "@material-ui/core/Slide";
import type { TransitionProps } from "@material-ui/core/transitions";

const Transition = React.forwardRef<unknown, TransitionProps>(({ children, ...props }, ref) => (
  <Slide {...props} direction="up" ref={ref}>
    {children as React.ReactElement}
  </Slide>
));

export { Transition };
