import type {
  ManagementFeesCompanySettingsClientDto,
  ManagementFeesPropertySettingsClientDto,
  ManagementFeesPropertySettingsDto
} from "@doorloop/dto";

export const prepareManagementFeesValuesForSubmit = (
  values: ManagementFeesPropertySettingsDto | ManagementFeesCompanySettingsClientDto
): ManagementFeesPropertySettingsClientDto => {
  const _values = {
    ...values
  } as ManagementFeesPropertySettingsClientDto;
  if (_values.useCompanyDefaultManagementFees) {
    _values.fees = undefined;
  }
  if (_values && _values.fees) {
    _values.fees.managementAccounts = [];
    _values.payOutAccounts?.forEach(({ incomeAccount }) => {
      incomeAccount && _values.fees?.managementAccounts?.push(incomeAccount);
    });
  }
  delete _values.useCompanyDefaultManagementFees;
  delete _values.payOutAccounts;

  return _values;
};
