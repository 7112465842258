import React from "react";
import { View } from "DLUI/view";
import Skeleton from "@material-ui/lab/Skeleton";
import { SeparationLine } from "DLUI/separatorView";

const CardSkeletonItem: React.FC<{}> = () => (
  <View marginTop={20} width={320} height={350} overflow={"hidden"} alignItems={"center"}>
    <View width={300}>
      <View
        width={"100%"}
        alignItems={"center"}
        flexDirection={"row"}
        height={60}
        backgroundColor={"dark"}
        paddingLeft={20}
        borderTopLeftRadius={10}
        borderTopRightRadius={10}
      >
        <View paddingLeft={20} justifyContent={"center"} alignItems={"flex-start"} flex={1}>
          <Skeleton variant="text" width={"80%"} />
        </View>
      </View>
      <View
        borderBottomLeftRadius={10}
        borderBottomRightRadius={10}
        backgroundColor={"white"}
        cursor={"pointer"}
        alignItems={"center"}
      >
        <View
          marginTop={10}
          overflow={"hidden"}
          paddingLeft={20}
          paddingRight={20}
          height={200}
          style={{ lineBreak: "anywhere" }}
          justifyContent={"space-around"}
        >
          <Skeleton variant="text" width={"100%"} />
          <Skeleton variant="text" width={"100%"} />
          <Skeleton variant="text" width={"100%"} />
          <Skeleton variant="text" width={"100%"} />
        </View>
        <SeparationLine marginTop={10} width={"90%"} height={1} />

        <View flexDirection={"row"} paddingLeft={20} paddingRight={20} height={65} alignItems={"center"}>
          <View paddingLeft={20} flex={1}>
            <Skeleton variant="text" width={"98%"} />
          </View>
        </View>
      </View>
    </View>
  </View>
);

export default CardSkeletonItem;
