import DLButton from "DLUI/button/dlButton";
import { Icon } from "DLUI/icon";
import { SectionTitle } from "DLUI/screen";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import React from "react";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { VerificationSubmitted } from "@/assets";
import AppStrings from "locale/keys";

interface Props {
  onBackdropClick: () => void;
}

const PendingApproval: React.FC<Props> = ({ onBackdropClick }: Props) => {
  const { screenContainerPadding } = useResponsiveHelper();
  return (
    <View
      paddingLeft={screenContainerPadding}
      paddingRight={screenContainerPadding}
      alignItems={"center"}
      height={"100%"}
    >
      <Icon marginTop={40} Source={VerificationSubmitted} />
      <SectionTitle
        type={"underline"}
        alignItems={"center"}
        title={AppStrings.Accounts.IdentityVerificationSubmitted}
        marginTop={20}
      />
      <Text
        value={AppStrings.Accounts.IdentityVerificationSubmittedDescription}
        lineHeight={"22px"}
        align={"center"}
        marginTop={20}
        maxWidth={450}
      />

      <View flex={1} />
      <View alignItems={"center"} marginBottom={40}>
        <DLButton onClick={onBackdropClick} actionText={AppStrings.Common.Done} style={{ minWidth: 180 }} />
      </View>
    </View>
  );
};

export default PendingApproval;
