// store/index.ts
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";

import type { IAuthState } from "./auth/reducers";
import authReducer from "./auth/reducers";
import type { ITenantPortalState } from "./auth/tenantPortalReducer";
import tenantPortalReducer from "./auth/tenantPortalReducer";
import type { ToastState } from "./toast/toastReducer";
import { toastReducer } from "./toast/toastReducer";

export interface IRootState {
  toast: ToastState;
  auth: IAuthState;
  tenantPortal: ITenantPortalState;
}

export const store = createStore(
  combineReducers<IRootState>({
    toast: toastReducer,
    auth: authReducer,
    tenantPortal: tenantPortalReducer
  }),
  compose(
    applyMiddleware(thunk)
    //@ts-ignore
    //process.env.NODE_ENV === "production" ? f => f : window.devToolsExtension()
  )
);
