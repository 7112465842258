import type { FC } from "react";
import React from "react";

import _ from "lodash";

import AppStrings from "locale/keys";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import Text from "DLUI/text";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { ExclamationMarkIconPink } from "assets/icons";
import { Icon } from "DLUI/icon";
import { LoadingDialog } from "./loading";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { View } from "DLUI/view";

interface VoidConfirmationProps {
  onCancel?: VoidFunction;
  onSubmit?: VoidFunction;
  isLoading?: boolean;
  errorText?: string;
}

export const VoidConfirmation: FC<VoidConfirmationProps> = ({
  onCancel = _.noop,
  onSubmit = _.noop,
  isLoading = false,
  errorText
}) => {
  const { isMobile } = useResponsiveHelper();

  if (isLoading) {
    return (
      <LoadingDialog
        dialogState={DialogState.Show}
        loadingText={AppStrings.Common.DeletingTransaction}
        errorText={errorText}
        onRetryButtonPress={onSubmit}
        didPressDismissButton={onCancel}
      />
    );
  }

  return (
    <View height={"100%"} noWrap>
      <View alignItems={"center"} paddingLeft={20} paddingRight={20} noWrap>
        <Icon marginTop={50} Source={ExclamationMarkIconPink} size={80} />
        <Text
          color={"black"}
          fontSize={24}
          fontWeight={700}
          value={AppStrings.Bills.Checks.VoidActionConfirmationTitle}
          marginTop={30}
        />
        <Text
          color={"black"}
          fontSize={24}
          value={AppStrings.Bills.Checks.VoidActionConfirmation}
          marginTop={isMobile ? 50 : 20}
          lineHeight={"34px"}
          fullWidth
          align={"center"}
        />
      </View>
      <View
        flex={1}
        justifyContent={"center"}
        alignItems={"flex-end"}
        flexDirection={"row"}
        marginBottom={50}
        width={"100%"}
      >
        <DLButton
          actionText={AppStrings.Common.Cancel}
          variant={DLButtonVariantsEnum.OUTLINED}
          color={DLButtonColorsEnum.NEUTRAL}
          size={DLButtonSizesEnum.LARGE}
          onClick={onCancel}
          style={{ marginRight: 20 }}
        />
        <DLButton actionText={AppStrings.Common.Yes} size={DLButtonSizesEnum.LARGE} onClick={onSubmit} />
      </View>
    </View>
  );
};
