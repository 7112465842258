export default {
  payBills: {
    screen: {
      payBills: "Pay Bills",
      selectBills: "Select Bills",
      selectBillsDescription: "Which bills do you want to pay?",
      whichProperties: "Which properties would you like to pay bills for?",
      whichVendors: "Which vendors would you like to pay?",
      whichBills: "Which bills do you want to pay?",
      allProperties: "All Properties",
      someProperties: "Some Properties",
      allVendors: "All Vendors",
      someVendors: "Some Vendors",
      selectVendors: "Select Vendors",
      allBills: "All Bills",
      someBills: "Some Bills",
      dueBefore: "Only Pay Bills Due Before",
      selectProperties: "Allocations",
      selectPropertiesDescription:
        "Automatically pay each bill using available funds and credits, or edit each payment.",
      availableFunds: "Available Funds",
      outstandingBills: "Bills Due",
      outstandingCredits: "Credits",
      totalPayments: "Payment Amount",
      allocatePayments: "Edit Payment",
      propertiesNoResultsText: "No bills found. Please change your filter and try again.",
      billDue: "Due",
      totalBills: "Total Bills",
      totalCredits: "Total Credits",
      transaction: "Transaction",
      billsAllocationSummaryLineValidation: "Total Credits must be equal to or less than Total Bills",
      billsAllocationDialogSummaryValidation: "Insufficient Funds",
      billsAllocationDialogSummaryValidationDescription:
        "The bank account selected does not have enough funds to cover this payment. Would you still like to proceed?",
      noOutstandingTransactionsFound:
        "You either did not select a bill, don’t have any unpaid bills, or you don’t have enough available funds for payment. \n\nPlease review your bills and try again.",
      createPayments: "Create Payments",
      createPaymentsDescription: "Please select a payment method for each vendor.",
      vendor: "Vendor",
      consolidateChecks: "Consolidate Payments",
      creatingPayments: "Creating payments",
      paymentsCreated: "Payments were created successfully.",
      billPayment: "Bill Payment",
      paymentAccount: "Payment Account",
      onlyOpenBills: "Only Open Bills",
      helpPanel: {
        description: "Pay bills for your properties and print checks directly from DoorLoop.",
        payBills: "How do I pay a bill?",
        printChecks: "How do I print checks?",
        insufficientFunds: "Why are there insufficient property funds to pay a bill?"
      },
      portfolios: "Portfolios"
    },
    wizardSteps: {
      bills: "Bills",
      properties: "Allocations",
      payments: "Payments"
    }
  },
  openBills: {
    searchPlaceHolder: "Search bills",
    addNewBill: "New Bill",
    bill: "Bill",
    emptydataViewInstructionsText: "No Open Bills Found"
  },
  checks: {
    void: "Void",
    voidActionConfirmation: "Are you sure you want to void this check?",
    voidActionConfirmationTitle: "Void Confirmation"
  }
};
