import type { ChangeEvent, Dispatch } from "react";
import React, { useEffect } from "react";
import View from "../../../DLUI/view/view";
import ShakeEffectView from "../../../DLUI/animatableView/shakeEffectView";
import AppStrings from "../../../../locale/keys";
import { useTranslation } from "react-i18next";
import TextInput from "DLUI/form/textField/textInput";

export interface ApiKeysFieldsItemInterface {
  defaultValue: string;
  setIsValid?: Dispatch<boolean>;
  isValid?: boolean;
  label?: string;
  onChange?: (ev: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

const ApiKeysFieldsItem: React.FC<ApiKeysFieldsItemInterface> = ({
  label,
  isValid,
  setIsValid,
  defaultValue,
  onChange
}: ApiKeysFieldsItemInterface) => {
  const { t } = useTranslation();

  useEffect(() => () => setIsValid && setIsValid(true), []);

  return (
    <View>
      <ShakeEffectView showEffect={!isValid} fullWidth>
        <TextInput
          helperText={!isValid && t(AppStrings.Settings.GeneralSettings.ApiKeys.ApiKeysNameTextError)}
          error={!isValid}
          fullWidth
          defaultValue={defaultValue}
          onChange={onChange}
          variant={"outlined"}
          label={label}
        />
      </ShakeEffectView>
    </View>
  );
};

export default ApiKeysFieldsItem;
