"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VerificationSessionStatus = void 0;
var VerificationSessionStatus;
(function (VerificationSessionStatus) {
    VerificationSessionStatus["requires_input"] = "requires_input";
    VerificationSessionStatus["processing"] = "processing";
    VerificationSessionStatus["verified"] = "verified";
    VerificationSessionStatus["failed"] = "failed";
    VerificationSessionStatus["canceled"] = "canceled";
})(VerificationSessionStatus || (exports.VerificationSessionStatus = VerificationSessionStatus = {}));
