"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientJournalEntryType = exports.JournalEntryType = void 0;
var JournalEntryType;
(function (JournalEntryType) {
    JournalEntryType["LEASE_CHARGE"] = "leaseCharge";
    JournalEntryType["LEASE_PAYMENT"] = "leasePayment";
    JournalEntryType["LEASE_REVERSED_PAYMENT"] = "leaseReversedPayment";
    JournalEntryType["LEASE_REFUND"] = "leaseRefund";
    JournalEntryType["LEASE_CREDIT"] = "leaseCredit";
    JournalEntryType["VENDOR_BILL"] = "vendorBill";
    JournalEntryType["VENDOR_BILL_PAYMENT"] = "vendorBillPayment";
    JournalEntryType["VENDOR_CREDIT"] = "vendorCredit";
    JournalEntryType["EXPENSE"] = "expense";
    JournalEntryType["DEPOSIT"] = "deposit";
    JournalEntryType["GENERAL_ENTRY"] = "generalEntry";
    JournalEntryType["TRANSFER"] = "transfer";
    JournalEntryType["OWNER_CONTRIBUTION"] = "ownerContribution";
    JournalEntryType["OWNER_DISTRIBUTION"] = "ownerDistribution";
    JournalEntryType["APPLICATION_FEE"] = "applicationFee";
})(JournalEntryType || (exports.JournalEntryType = JournalEntryType = {}));
var ClientJournalEntryType;
(function (ClientJournalEntryType) {
    ClientJournalEntryType["APPLIED_CREDIT"] = "appliedCredit";
    ClientJournalEntryType["RECEIVED_PAYMENT"] = "receivedPayment";
    ClientJournalEntryType["LINE_ITEM"] = "lineItem";
})(ClientJournalEntryType || (exports.ClientJournalEntryType = ClientJournalEntryType = {}));
