import React from "react";
import View from "DLUI/view/view";

const FullWidthLayout: React.FC<{}> = ({ children }) => (
  <View className="fullWidthLayout" height={"100dvh"} width={"100vw"} overflow={"hidden"} flexDirection={"row"}>
    {children}
  </View>
);

export default FullWidthLayout;
