"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Purposes = void 0;
var Purposes;
(function (Purposes) {
    Purposes["FINANCIAL_MANAGEMENT"] = "Financial Management, Accounting and Reporting";
    Purposes["AUTOMATE_RENT_COLLECTION"] = "Automate rent collection";
    Purposes["MARKETING"] = "Marketing and leasing my properties";
    Purposes["MAINTENANCE"] = "Streamline maintenance & vendor requests";
    Purposes["IMPROVE_COMMUNICATION"] = "Improve communications with stakeholders";
    Purposes["OTHER"] = "Other";
})(Purposes || (exports.Purposes = Purposes = {}));
