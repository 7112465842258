import { DateFormats } from "@doorloop/dto";
import { useCallback, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import { useSyncWithSearchParams } from "hooks/useSyncWithSearchParams";
import { useResponsiveHelper } from "@/contexts/responsiveContext";
import type {
  DateRangePickerSize,
  FilterPanelDateRangePicker
} from "@/components/DLUI/form/datePicker/dateRangePicker/types";
import type { DateRangePickerProps } from "@/components/DLUI/form/datePicker/dateRangePicker/dateRangePicker";
import { resolveDatesFromPeriodString } from "@/components/DLUI/form/datePicker/utils";
import { useSearchParams } from "@/hooks/useSearchParams";
import type { Moment } from "moment/moment";

function formatDate(date: Moment | null): string | null {
  if (!date) {
    return null;
  }

  return date.format(DateFormats.ISO_DATE_SERVER_FORMAT);
}

export function useDateRangePickerFilterInput<TQuery>({
  period,
  startDateFieldKey,
  endDateFieldKey,
  showPickerFields,
  hidePeriodField,
  customPeriods,
  alwaysVisible,
  variant,
  periodDateFieldKey = "period"
}: Pick<
  FilterPanelDateRangePicker<TQuery>,
  | "period"
  | "startDateFieldKey"
  | "endDateFieldKey"
  | "showPickerFields"
  | "customPeriods"
  | "periodDateFieldKey"
  | "alwaysVisible"
  | "hidePeriodField"
  | "variant"
>): DateRangePickerProps {
  const { isTabletOrMobile } = useResponsiveHelper();
  const [searchParams] = useSearchParams();
  const { isMobile } = useResponsiveHelper();
  const [defaultValues] = useState<{
    startDate?: string;
    endDate?: string;
  }>(() => {
    const currentPeriod = searchParams.get(periodDateFieldKey) || period;
    if (currentPeriod === "all-time") {
      return {};
    }

    const { startDate, endDate } = resolveDatesFromPeriodString(
      currentPeriod,
      startDateFieldKey as string,
      endDateFieldKey as string
    );

    return {
      startDate: startDate?.format(DateFormats.ISO_DATE_SERVER_FORMAT),
      endDate: endDate?.format(DateFormats.ISO_DATE_SERVER_FORMAT)
    };
  });

  const { onValueChange: onStartDateChange, queryValue: startDateValue } = useSyncWithSearchParams(
    startDateFieldKey as string,
    defaultValues.startDate
  );
  const { onValueChange: onEndDateChange, queryValue: endDateValue } = useSyncWithSearchParams(
    endDateFieldKey as string,
    defaultValues.endDate
  );
  const { onValueChange: onPeriodChange, queryValue: selectedPeriod } = useSyncWithSearchParams(
    periodDateFieldKey,
    period,
    true
  );

  const uniqueKey = useMemo(() => uuid(), []);

  const calculatedSize = useMemo<DateRangePickerSize>(() => {
    if (alwaysVisible && !isMobile) {
      return "full-width";
    }

    return "2-rows";
  }, [isMobile, alwaysVisible]);

  const handleStartDateChange = useCallback<NonNullable<DateRangePickerProps["onStartDateChange"]>>(
    (value) => {
      onStartDateChange(formatDate(value));
      onPeriodChange("custom");
    },
    [onStartDateChange, onPeriodChange]
  );

  const handleEndDateChange = useCallback<NonNullable<DateRangePickerProps["onStartDateChange"]>>(
    (value) => {
      onEndDateChange(formatDate(value));
      onPeriodChange("custom");
    },
    [onEndDateChange, onPeriodChange]
  );

  const handlePeriodChange = useCallback<NonNullable<DateRangePickerProps["onPeriodChange"]>>(
    (value) => {
      const { startDate, endDate } = resolveDatesFromPeriodString(
        value,
        startDateFieldKey as string,
        endDateFieldKey as string
      );
      onStartDateChange(formatDate(startDate));
      onEndDateChange(formatDate(endDate));
      onPeriodChange(value);
    },
    [onPeriodChange, startDateFieldKey, endDateFieldKey, onStartDateChange, onEndDateChange]
  );

  return useMemo<DateRangePickerProps>(() => {
    return {
      onStartDateChange: handleStartDateChange,
      onEndDateChange: handleEndDateChange,
      onPeriodChange: handlePeriodChange,
      defaultPeriod: period,
      maxWidth: isTabletOrMobile ? 0 : 700,
      uniqueKey,
      hidePeriodField,
      size: calculatedSize,
      showPickerFields,
      customPeriods,
      ignoreQueryChange: true,
      startDateValue,
      endDateValue,
      selectedPeriod,
      variant
    };
  }, [
    handleStartDateChange,
    handleEndDateChange,
    handlePeriodChange,
    period,
    hidePeriodField,
    isTabletOrMobile,
    uniqueKey,
    calculatedSize,
    showPickerFields,
    customPeriods,
    startDateValue,
    endDateValue,
    selectedPeriod,
    variant
  ]);
}
