import { ObjectPermission } from "@doorloop/dto";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import { View } from "DLUI/view";
import { useParams } from "react-router-dom";
import { PictureGallery } from "shared/pictureGallery/pictureGallery";

import Text from "DLUI/text";
import AppStrings from "locale/keys";

export const EditPropertyPhotos = () => {
  const { propertyId } = useParams<{ propertyId?: string }>();

  return (
    <View flex={1} marginBottom={40} paddingRight={5} paddingLeft={5}>
      <RestrictedPermissionAccess clearance={{ permission: ObjectPermission.properties, field: "edit" }} showNoAccess>
        <View marginBottom={40}>
          <Text fontSize={16} value={AppStrings.Listing.PhotosDescription} />
        </View>
        {propertyId && <PictureGallery />}
      </RestrictedPermissionAccess>
    </View>
  );
};
