import { DataCy, JournalEntryType, ReportId, ReportPermission } from "@doorloop/dto";
import AppStrings from "locale/keys";
import { NavigationManager } from "utils/navigation";
import type { ReportScreenItemType } from "DLUI/report/screen/reportScreenItemType";
import { filterObjectsByUserType } from "utils/userTypesUtils";

const didPressUnpaidBills = () => {
  NavigationManager.runAccountPayableAgingSummaryReport();
};

const didPressAccountsPayableAgingDetail = () => {
  NavigationManager.runAccountPayableAgingDetailReport();
};

const didPressAccountsPayableAgingSummary = () => {
  NavigationManager.runAccountPayableAgingSummaryReport();
};

const didPressAccountsPayableAgingDetailByAccount = () => {
  NavigationManager.runAccountPayableAgingDetailByAccountReport();
};

const didPressBillPaymentsList = (propertyId?: string) => {
  const params: Record<string, string> = {
    filter_type: JournalEntryType.VENDOR_BILL_PAYMENT
  };
  if (propertyId) {
    params["filter_property"] = propertyId;
  }
  NavigationManager.runTransactionsListReport(params);
};

const items: ReportScreenItemType[] = [
  {
    description: AppStrings.Reports.ReportsScreen.AccountsPayableAgingDetail,
    onPress: didPressAccountsPayableAgingDetail,
    permission: ReportPermission.accountsPayable,
    id: ReportId.ACCOUNT_PAYABLE_AGING_DETAILS,
    dataCy: DataCy.reports.screenItems.whatYouOwe.apAgingDetail
  },
  {
    description: AppStrings.Reports.ReportsScreen.AccountsPayableAgingDetailByAccount,
    onPress: didPressAccountsPayableAgingDetailByAccount,
    permission: ReportPermission.accountsPayable,
    id: ReportId.ACCOUNT_PAYABLE_AGING_DETAILS_BY_ACCOUNT,
    dataCy: DataCy.reports.screenItems.whatYouOwe.apAgingDetailByAccount
  },
  {
    description: AppStrings.Reports.ReportsScreen.AccountsPayableAgingSummary,
    onPress: didPressAccountsPayableAgingSummary,
    permission: ReportPermission.accountsPayable,
    id: ReportId.ACCOUNT_PAYABLE_AGING_SUMMARY,
    dataCy: DataCy.reports.screenItems.whatYouOwe.apAgingSummary
  },

  {
    description: AppStrings.Reports.ReportsScreen.BillPaymentsList,
    onPress: didPressBillPaymentsList,
    permission: ReportPermission.accountsPayable,
    id: ReportId.BILL_PAYMENTS,
    dataCy: DataCy.reports.screenItems.whatYouOwe.billPaymentsList
  },
  {
    description: AppStrings.Reports.ReportsScreen.UnpaidBills,
    onPress: didPressUnpaidBills,
    permission: ReportPermission.accountsPayable,
    id: ReportId.UNPAID_BILLS,
    dataCy: DataCy.reports.screenItems.whatYouOwe.unpaidBills
  }
];

const getWhatYouOwe = (isHOAUser: boolean): ReportScreenItemType[] => {
  if (isHOAUser) {
    return filterObjectsByUserType(items, "HOAUser");
  }

  return items;
};

export default getWhatYouOwe;
