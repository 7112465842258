import type { Ref } from "react";
import { useRefEffect } from "./useRefEffect";

/**
 * Calls the provided callback whenever the provided event is fired on the element attached to the returned ref
 *
 * @param type Event type to listen for
 * @param cb Callback to call when the event is fired. The event is passed as the first argument, and the element as the second
 * @param deps Dependencies for the callback
 * @returns A ref which should be attached to the element
 *
 * @example
 * ```tsx
 * const ref = useEventEffect('focusin', (event) => {
 *  // Some effect, like an animation, or adding a new item to a list
 * });
 * // Whenever div is gaining focus, the callback will be called
 * return <div ref={ref}>...</div>
 * ```
 */
export function useEventEffect<
  TEvent extends keyof GlobalEventHandlersEventMap,
  TElement extends HTMLElement = HTMLElement
>(
  type: TEvent,
  cb: (event: GlobalEventHandlersEventMap[TEvent], element: TElement) => void,
  deps: any[] = []
): Ref<TElement> {
  return useRefEffect<TElement>((element) => {
    const onEvent = (event: GlobalEventHandlersEventMap[TEvent]) => cb(event, element);
    element.addEventListener(type, onEvent);
    return () => element?.removeEventListener(type, onEvent);
  }, deps);
}
