import React, { useEffect, useMemo, useState } from "react";

import type { OwnerDto, PersonType, TenantDto, VendorDto } from "@doorloop/dto";
import type { ApiResult } from "api/apiResult";

import { DataCy } from "@doorloop/dto";
import { ownersApi } from "api/ownersApi";
import { tenantsApi } from "api/tenantsApi";
import { vendorsApi } from "api/vendorsApi";
import { EntitySelector, EntityType } from "DLUI/dialogs/components/entitySelector";

interface ComponentProps {
  payeeId?: string;
  didPressSelectPayee?: (payeeType?: PersonType) => void;
  setPayeeSupportsEPay?: (payeeSupportsEPay: boolean | null) => void;
  hasError?: boolean;
  errorTex?: string;
  payeeType?: PersonType;
  required?: boolean;
}

const PayeeSelection: React.FC<ComponentProps> = ({
  payeeId,
  didPressSelectPayee,
  setPayeeSupportsEPay,
  hasError,
  errorTex,
  payeeType,
  required = true
}: ComponentProps) => {
  const [loadingInProgress, setLoadingInProgress] = useState<boolean>(false);
  const [selectedPayee, setSelectedPayee] = useState<VendorDto | OwnerDto | TenantDto | undefined>();

  const { payeeName, payeeInfo } = useMemo(() => {
    let payeeName = "";
    let payeeInfo = "";

    if (selectedPayee) {
      payeeName = selectedPayee.name || "";
      payeeInfo += selectedPayee.jobTitle || "";
      if (selectedPayee.jobTitle) {
        payeeInfo += ", ";
      }
      payeeInfo += selectedPayee.companyName || "";
    }
    return { payeeName, payeeInfo };
  }, [selectedPayee, payeeId]);

  const getPayee = async () => {
    if (payeeId && payeeType) {
      setLoadingInProgress(true);
      let results;
      switch (payeeType) {
        case "vendor":
          results = (await vendorsApi.getDetails(payeeId).catch(() => {
            // in case of failure loading unit data just hide the loading indicator
            setLoadingInProgress(false);
          })) as ApiResult<any>;
          break;
        case "tenant":
          results = (await tenantsApi.getDetails(payeeId).catch(() => {
            // in case of failure loading unit data just hide the loading indicator
            setLoadingInProgress(false);
          })) as ApiResult<any>;
          break;
        case "owner":
          results = (await ownersApi.getDetails(payeeId).catch(() => {
            // in case of failure loading unit data just hide the loading indicator
            setLoadingInProgress(false);
          })) as ApiResult<any>;
          break;
      }

      if (results?.status) {
        setSelectedPayee(results.data);
        setLoadingInProgress(false);
        setPayeeSupportsEPay?.(Boolean(results.data.outgoingEPay?.enabled));
      } else {
        setLoadingInProgress(false);
      }
    }
  };

  useEffect(() => {
    if (payeeType) {
      getPayee();
    }
  }, [payeeId, payeeType]);

  return (
    <EntitySelector
      type={EntityType.PAYEE}
      onClick={didPressSelectPayee}
      isLoading={loadingInProgress}
      valueText={payeeName}
      required={required}
      dataCy={DataCy.DLUI.entitySelector.listContainer}
      error={hasError ? errorTex : undefined}
    />
  );
};

export default PayeeSelection;
