import type { FormikProps } from "formik";
import type { AddressDisplayDto } from "@doorloop/dto";
import { regionNames } from "@doorloop/dto";
import type { InternationalAndUS_SuggestionsType } from "DLUI/form/autoComplete/addressAutoComplete/smarty/dataSource";
import type { CountriesListInterface } from "DLUI/form/autoComplete/addressAutoComplete/smarty/addressAutoCompleteForm";

interface SmartyPropsMapType {
  externalKeyName: string | string[];
  internalKeyName: keyof AddressDisplayDto;
}

const usSmartyPropsMap: SmartyPropsMapType[] = [
  { externalKeyName: "city", internalKeyName: "city" },
  { externalKeyName: "streetLine", internalKeyName: "street1" },
  { externalKeyName: "secondary", internalKeyName: "street2" },
  { externalKeyName: "state", internalKeyName: "state" },
  { externalKeyName: "country", internalKeyName: "country" },
  { externalKeyName: "entries", internalKeyName: "entries" },
  { externalKeyName: "zipcode", internalKeyName: "zip" }
];

const internationalSmartyPropsMap: SmartyPropsMapType[] = [
  { externalKeyName: "locality", internalKeyName: "city" },
  { externalKeyName: "street", internalKeyName: "street1" },
  { externalKeyName: "secondary", internalKeyName: "street2" },
  { externalKeyName: ["administrativeArea", "administrative_area"], internalKeyName: "state" },
  { externalKeyName: ["countryIso3", "country_iso3"], internalKeyName: "country" },
  { externalKeyName: ["postalCode", "postal_code"], internalKeyName: "zip" }
];

export const autoSelectAddress = (
  selectedPlace: InternationalAndUS_SuggestionsType,
  formikRef: FormikProps<any>,
  formikFieldName: string,
  countryIso3Code?: string
) => {
  if (selectedPlace) {
    if (countryIso3Code !== "USA") {
      if (selectedPlace.addressId) {
        formikRef.setFieldValue(`${formikFieldName}.addressId`, selectedPlace.addressId);
        formikRef.setFieldValue(`${formikFieldName}.street1`, selectedPlace.addressText);

        return;
      }
      formikRef.setFieldValue(`${formikFieldName}.addressId`, undefined);
      formikRef.setFieldValue(`${formikFieldName}.addressText`, undefined);
    }

    (countryIso3Code === "USA" ? usSmartyPropsMap : internationalSmartyPropsMap).forEach(
      ({ externalKeyName, internalKeyName }) => {
        const currentExternalKeyName: string | undefined = Array.isArray(externalKeyName)
          ? externalKeyName.find((str) => selectedPlace[str])
          : externalKeyName;
        let addressPropVal: string | undefined = currentExternalKeyName && selectedPlace[currentExternalKeyName];

        if (internalKeyName === "country") {
          addressPropVal = (regionNames as CountriesListInterface[]).find(
            ({ iso3 }) => iso3 === (countryIso3Code || selectedPlace.countryIso3 || selectedPlace.country_iso3 || "USA")
          )?.country;
        }

        const fieldName = `${formikFieldName}.${internalKeyName}`;
        formikRef.setFieldValue(fieldName, addressPropVal);
      }
    );

    let selectedEntriesAddress;
    if (countryIso3Code === "USA" && selectedPlace.entries) {
      const { streetLine, secondary, entries, city, state, zipcode } = selectedPlace;
      selectedEntriesAddress = `${streetLine} ${secondary} (${entries}) ${city} ${state} ${zipcode}`;
    } else {
      selectedEntriesAddress = undefined;
    }

    formikRef.setFieldValue(`${formikFieldName}.displayUS_Selected`, selectedEntriesAddress);
  }
};
