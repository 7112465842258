import React from "react";
import Grid from "@material-ui/core/Grid";
import { propertiesApi } from "api/propertiesApi";
import { DeleteXIcon } from "assets/icons";
import { IconButton, TextField } from "DLUI/form";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, getIn } from "formik";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import { useIntl } from "react-intl";
import moment from "moment";
import FormikCachedAsyncAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedAsyncAutoComplete";

import { useResponsiveHelper } from "../../../../../../contexts/responsiveContext";

interface ComponentProps {
  formikRef: FormikProps<any>;
  name: string;
  didPressDeleteLine?: (lineIndex: number) => void;
  lineIndex: number;
  accountingStartDate?: string;
  didChangeOpeningBalanceFiled: (e: React.ChangeEvent<any>) => void;
}

const OpeningBalanceByPropertyLine = ({
  formikRef,
  name,
  didPressDeleteLine,
  lineIndex,
  accountingStartDate,
  didChangeOpeningBalanceFiled
}: ComponentProps) => {
  const { t } = useTranslation();
  const { formatDate } = useIntl();
  const { isMobile } = useResponsiveHelper();

  const renderBalanceField = () => {
    let fieldLabel = t(AppStrings.Accounts.ChartOfAccounts.BalanceOn);
    fieldLabel += " " + formatDate(moment(accountingStartDate).toDate());

    return (
      <Grid item xs={11} md={5}>
        <FastField
          component={TextField}
          uniqueKey={"openingBalance"}
          label={fieldLabel}
          name={`${name}.openingBalance`}
          noMargin
          required
          formatType={"currency"}
          onBlur={didChangeOpeningBalanceFiled}
        />
      </Grid>
    );
  };

  if (!accountingStartDate) {
    return null;
  }

  return (
    <View marginTop={20} alignItems={"center"} flexDirection={"row"}>
      <View alignItems="center" flexDirection={"row"} flex={1}>
        <Grid item xs={11} md={5}>
          <View marginBottom={isMobile ? 20 : 0}>
            <FormikCachedAsyncAutoComplete
              uniqueIndex={"property"}
              apiHandler={propertiesApi}
              displayNameKey={"name"}
              filterFieldName={"filter_text"}
              filterFieldValue={"name"}
              selectionFields={["id", "class"]}
              name={`${name}.property`}
              label={t(AppStrings.Common.FilterOptions.Property)}
              defaultValue={getIn(formikRef.values, `${name}.property`)}
              paddingRight={isMobile ? 0 : 20}
            />
          </View>
        </Grid>

        {renderBalanceField()}

        <Grid item xs={1} md={2}>
          <View justifyContent={"center"} alignItems={"center"}>
            {didPressDeleteLine && (
              <IconButton
                onClick={() => didPressDeleteLine(lineIndex)}
                aria-label="delete"
                Icon={DeleteXIcon}
                width={15}
                height={15}
              />
            )}
          </View>
        </Grid>
      </View>
    </View>
  );
};

export default OpeningBalanceByPropertyLine;
