import type { LeaseTenantDto } from "@doorloop/dto";
import { LeaseTenantStatus, PhoneType } from "@doorloop/dto";
import { propertiesApi } from "api/propertiesApi";
import { unitsApi } from "api/unitsApi";
import { CheckmarkCircleIcon, ClockOrangeIcon, CloseCircleIcon, MailIcon, MobileIcon, PhoneIcon } from "assets/icons";
import { Icon } from "DLUI/icon";
import { Grid, ListItemContainer, ListItemSection } from "DLUI/listItems";
import { ContactInfoItem } from "DLUI/person";
import { ProfileImage } from "DLUI/profileImage";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import type { TextColor } from "DLUI/text/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import ListItemText from "DLUI/listItems/listItemText";

import { useResponsiveHelper } from "../../../../contexts/responsiveContext";

interface ComponentProps {
  tenant: LeaseTenantDto;
  onItemPress?: (tenant: LeaseTenantDto) => void;
  stickyHeaderId?: string;
}

const SelectableLeaseTenantsListItem: React.FC<ComponentProps> = ({
  tenant,
  onItemPress,
  stickyHeaderId
}: ComponentProps) => {
  const { phones, emails, pictureUrl, name } = tenant.tenant!;
  const { isMobile } = useResponsiveHelper();
  const [propertyName, setPropertyName] = useState<string | undefined>();

  useEffect(() => {
    getPropertyName();
  }, []);

  const getPropertyName = async () => {
    const propertiesDictonary = await propertiesApi.getDictionary();
    if (tenant.property) {
      let propertyName = propertiesDictonary[tenant.property].name;
      if (tenant.units && tenant.units.length > 0) {
        const unitDictionary = unitsApi.getItemFromDictionary(tenant.units[0]);
        if (unitDictionary) {
          propertyName += " > " + unitDictionary.name;
        }
      }
      setPropertyName(propertyName);
    }
  };

  const { statusIcon, statusTextColor, statusText } = useMemo(() => {
    let statusIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> = CheckmarkCircleIcon;
    let statusTextColor: TextColor = "black";
    let statusText = "";
    switch (tenant.status) {
      case LeaseTenantStatus.CURRENT:
        statusIcon = CheckmarkCircleIcon;
        statusTextColor = "green";
        statusText = AppStrings.Tenants.Status.Current;
        break;
      case LeaseTenantStatus.FUTURE:
        statusIcon = ClockOrangeIcon;
        statusTextColor = "orange";
        statusText = AppStrings.Tenants.Status.Future;
        break;
      case LeaseTenantStatus.PAST:
        statusIcon = CloseCircleIcon;
        statusTextColor = "gray";
        statusText = AppStrings.Tenants.Status.Past;
        break;
    }

    return { statusIcon, statusTextColor, statusText };
  }, []);

  const renderContactInformation = () => {
    const phonesInfoItems: any = [];
    const emailsInfoItems: any = [];
    let displayItems: any = [];
    if (phones) {
      phones.forEach((currentItem) => {
        if (currentItem.number) {
          const phoneIcon = currentItem.type === PhoneType.MOBILE ? MobileIcon : PhoneIcon;
          phonesInfoItems.push(
            <ContactInfoItem marginRight={10} marginTop={10} type={"tel"} icon={phoneIcon} text={currentItem.number} />
          );
        }
      });
    }
    if (emails) {
      emails.forEach((currentItem) => {
        if (currentItem.address) {
          emailsInfoItems.push(
            <ContactInfoItem marginRight={10} marginTop={10} type={"mail"} icon={MailIcon} text={currentItem.address} />
          );
        }
      });
    }
    // we want to display at least one mail
    displayItems = _.slice(emailsInfoItems, 0, 1);
    // now let's add the phones elements
    displayItems = [...displayItems, ...phonesInfoItems];
    // check if we still have less than 3 items to display
    if (displayItems.length < 3) {
      // let's try to fill the gap with emails elements
      displayItems = [...displayItems, ..._.slice(emailsInfoItems, 1, emailsInfoItems.length)];
    }
    // return only the first 2 elements
    return <Fragment>{_.slice(displayItems, 0, 2)}</Fragment>;
  };

  // TODO add mobile support for selectable lease tenant item
  // if (isMobile) {
  //   return (
  //     <MobileLeaseTenantsListItem
  //       onItemPress={onItemPress}
  //       status={tenant.status}
  //       propertyName={propertyName}
  //       tenant={tenant.tenant!}
  //     />
  //   );
  // }

  const _onClick = () => {
    if (onItemPress) {
      onItemPress(tenant);
    }
  };

  return (
    <ListItemContainer id={stickyHeaderId}>
      <Grid title={AppStrings.Tenants.Screen.Tenant} showDivider xs={12} sm={4} md={4} lg={4} onClick={_onClick}>
        <View flex={1} alignItems={"center"} flexDirection={"row"}>
          <ProfileImage marginLeft={isMobile ? 0 : 20} pictureUrl={pictureUrl} />
          <ListItemSection
            renderTitle={
              <View alignItems={"flex-start"}>
                <ListItemText marginRight={20} marginLeft={20} fontWeight={700} value={name} />
              </View>
            }
            renderSubTitle={
              <View justifyContent={"flex-start"} alignItems={"center"} flexDirection={"row"} paddingLeft={20}>
                <Icon Source={statusIcon} width={15} height={15} />
                <Text marginLeft={5} color={statusTextColor} fontSize={16} value={statusText} />
              </View>
            }
          />
        </View>
      </Grid>
      <Grid onClick={_onClick} title={AppStrings.DrawerItems.Properties} xs={12} sm={5} md={4} lg={4} showDivider>
        <View height={"100%"} alignItems={"center"} flexDirection={"row"}>
          <ListItemText marginLeft={isMobile ? 0 : 10}>{propertyName}</ListItemText>
          <SeparationLine height={"50%"} />
        </View>
      </Grid>
      <Grid title={AppStrings.Users.Screen.ContactInformation} xs={12} sm={3} md={4} lg={4} onClick={_onClick}>
        <View justifyContent={"flex-start"} flexDirection={"row"}>
          {renderContactInformation()}
        </View>
      </Grid>
    </ListItemContainer>
  );
};

export default SelectableLeaseTenantsListItem;
