import React, { useMemo } from "react";
import { View } from "DLUI/view";
import type { ImageButtonGroupProps } from "DLUI/form";
import { FormikImageButtonGroup } from "DLUI/form";
import AppStrings from "locale/keys";
import { BankIcon, BankIconSpecify } from "assets/icons";
import { FastField } from "formik";
import { DataCy } from "@doorloop/dto";

export enum PropertyBankSelection {
  Default = "default",
  Specify = "specify"
}

interface ComponentProps {
  onBankSelection: (selectedValue) => void;
  fontSize?: number;
  groupSize?: number;
}

interface AccountSelectionItem extends ImageButtonGroupProps {
  value: PropertyBankSelection;
}

const BankSelectionImageButtonGroup: React.FC<ComponentProps> = ({
  onBankSelection,
  fontSize = 16,
  groupSize = 150
}: ComponentProps) => {
  const accountTypeSelection: AccountSelectionItem[] = useMemo(
    () => [
      {
        label: AppStrings.Properties.BankAccounts.CompanyDefault,
        icon: BankIcon,
        dataCy: DataCy.newProperty.bankAccount.defaultBankButton,
        isSelected: true,
        value: PropertyBankSelection.Default
      },
      {
        label: AppStrings.Properties.BankAccounts.Specify,
        icon: BankIconSpecify,
        dataCy: DataCy.newProperty.bankAccount.specifyBankButton,
        isSelected: false,
        value: PropertyBankSelection.Specify
      }
    ],
    []
  );

  return (
    <View alignItems={"center"} justifyContent={"center"}>
      <FastField
        component={FormikImageButtonGroup}
        name={"specifyAccounts"}
        uniqueId={"bankSelection"}
        buttonItemsArray={accountTypeSelection}
        size={groupSize}
        fontSize={fontSize}
        marginRight={0}
        flexDirection={"center"}
        onSelect={onBankSelection}
      />
    </View>
  );
};

export default BankSelectionImageButtonGroup;
