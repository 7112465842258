import type { LateFeeChargeDto, LateFeePolicyFrequency } from "@doorloop/dto";
import { DataCy, LateFeePolicyFeeType } from "@doorloop/dto";
import { ItemLabelChip } from "screens/settings/common/itemLabelChip";
import { useGeneralStyles } from "styles/generalStyles";
import { type EnTranslation, useTypedTranslation } from "locale";
import { useResponsiveHelper } from "contexts/responsiveContext";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import { useStyles } from "../settings/styles";
import clsx from "clsx";
import type { TextColor } from "DLUI/text/text";
import Text from "DLUI/text/text";

interface LateFeeItemTitleProps {
  index: number;
  rule: LateFeeChargeDto;
}

function getFeeRateText(rule: LateFeeChargeDto): string {
  const { amount, percentage, feeType } = rule;
  const defaultSum = "0.00";
  return feeType === LateFeePolicyFeeType.FIXED_AMOUNT ? `$${amount ?? defaultSum}` : `${percentage ?? defaultSum}%`;
}

function getFrequency(frequency: LateFeePolicyFrequency | undefined): EnTranslation {
  if (!frequency) return "common.frequency";

  return `common.enums.lateFeePolicyFrequency.${frequency}`;
}

function getColorByCondition(condition: boolean): TextColor {
  return condition ? "black" : "secondary-gray";
}

export const LateFeeItemTitle = ({ index, rule }: LateFeeItemTitleProps) => {
  const generalClasses = useGeneralStyles();
  const settingsClasses = useStyles();
  const { t } = useTypedTranslation();
  const { isDesktop } = useResponsiveHelper();

  const isFixedAmount = rule.feeType === LateFeePolicyFeeType.FIXED_AMOUNT;
  const isNumberDefined = !isNil(isFixedAmount ? rule.amount : rule.percentage);

  return (
    <div data-cy={DataCy.globalSettings.lateFees.rules.rule.title} className={settingsClasses.lateFeeChargeTitle}>
      <div className={clsx(generalClasses.flexRow, generalClasses.verticalAlign)}>
        <ItemLabelChip value={index + 1} />
        &nbsp;
        <Text fontSize={14} bold value={getFeeRateText(rule)} color={getColorByCondition(isNumberDefined)} />
        &nbsp;
        <Text fontSize={14} bold value={`·`} color={getColorByCondition(isNumberDefined && Boolean(rule.frequency))} />
        &nbsp;
        {rule.frequency && (
          <Text
            bold
            fontSize={14}
            value={getFrequency(rule.frequency)}
            color={getColorByCondition(isNumberDefined && Boolean(rule.frequency))}
          />
        )}
        {isDesktop && <div className={settingsClasses.lateFeeChargeSeparator} />}
      </div>
      <Text
        value={!isEmpty(rule.memo) ? rule.memo : t("common.description")}
        maxWidth={400}
        fontSize={14}
        overFlow={"ellipsis"}
        color={getColorByCondition(Boolean(rule.memo))}
      />
    </div>
  );
};
