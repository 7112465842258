import { View } from "DLUI/view";
import Text from "DLUI/text";
import { FieldSafe } from "DLUI/fieldSafe/fieldSafe";
import { FormikSwitchButton } from "DLUI/form";
import React from "react";
import { useTranslation } from "react-i18next";
import type { FormikSwitchProps } from "DLUI/form/switchButton/formikSwitch";
import type { TextComponentProps } from "DLUI/text/text";
import type { ViewComponentProps } from "DLUI/view/view";

interface FormikSwitchButtonRow extends FormikSwitchProps {
  textProps?: Partial<TextComponentProps>;
  viewProps?: Partial<ViewComponentProps>;
}

export const FormikSwitchButtonRow = ({
  label: labelText,
  field,
  textProps,
  size,
  onChange,
  flipValue,
  disabled = false,
  viewProps,
  dataCy
}: FormikSwitchButtonRow) => {
  const { t } = useTranslation();

  return (
    <View
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      paddingLeft={16}
      paddingRight={26}
      paddingBottom={16}
      paddingTop={16}
      noWrap
      {...viewProps}
    >
      {labelText && (
        <Text
          fontSize={14}
          bold
          maxWidth={"80%"}
          as={"label"}
          htmlFor={labelText}
          style={{ cursor: "pointer" }}
          {...textProps}
        >
          {t(labelText)}
        </Text>
      )}

      <FieldSafe
        onChange={onChange}
        component={FormikSwitchButton}
        name={field.name}
        size={size}
        flipValue={flipValue}
        id={labelText}
        disabled={disabled}
        dataCy={dataCy}
      />
    </View>
  );
};
