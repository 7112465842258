import type { BulkReceiveSessionDto, GetLeaseSearchResultsQuery, LeaseDto } from "@doorloop/dto";
import { LeaseStatus, SegmentEventTypes } from "@doorloop/dto";
import { Grid } from "@material-ui/core";
import { globalSearchApi } from "api/globalSearchApi";
import { SearchIcon } from "assets/.";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum } from "DLUI/button/dlButton";
import { DialogSearchPanel } from "DLUI/dialogs";
import EmptyDataView from "DLUI/emptyDataView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import { useFormikContext } from "formik";
import { DefaultDebounceDelays } from "hooks/useDebounce";
import { useEffectAsync } from "hooks/useEffectAsync";
import AppStrings from "locale/keys";
import debounce from "lodash/debounce";
import React, { useCallback, useMemo } from "react";
import { analyticsService } from "services/analyticsService";
import EditablePaymentTable from "DLUI/dialogs/payment/bulkPayment/editablePaymentTable/columns";

interface ComponentProps {}

const SearchAndFill = (props: ComponentProps) => {
  const formikContext = useFormikContext<BulkReceiveSessionDto>();
  const [isLoading, setIsLoading] = React.useState(false);

  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(undefined);
  const [lastSearchQuery, setLastSearchQuery] = React.useState<string | undefined>(undefined);
  const [searchResultLeases, setSearchResultLeases] = React.useState<LeaseDto[] | undefined>(undefined);
  const [errorWhileSearching, setErrorWhileSearching] = React.useState<string | undefined>(undefined);
  const [hideSearchCounter, setHideSearchCounter] = React.useState(false);

  const hasSearchResults = !errorWhileSearching && Array.isArray(searchResultLeases) && searchResultLeases.length > 0;
  const persistDeleteIcon = useMemo(
    () =>
      searchResultLeases &&
      searchResultLeases?.length > 0 &&
      Object.values(formikContext.values.payments).some((payment) => payment.isFinished),
    [searchResultLeases, formikContext.values.payments]
  );

  function didChangeSearchQuery(value: string) {
    if (!value) {
      setSearchQuery(undefined);
      return;
    }

    setSearchQuery(value);
  }

  async function executeSearch(queryStr: string | undefined) {
    if (isLoading || !queryStr || queryStr === lastSearchQuery) return;

    const filterQuery: GetLeaseSearchResultsQuery = {
      filter_text: queryStr,
      filter_leaseStatus: LeaseStatus.ACTIVE
    };

    // cancel any pending search requests
    debouncedSearch.cancel();

    // reset the search state
    setIsLoading(true);
    setLastSearchQuery(queryStr);
    try {
      const result = await globalSearchApi.searchLeaseByLeasePropertyTenant(filterQuery);

      if (result.statusCode !== 200) {
        throw new Error(result.message);
      }
      setErrorWhileSearching(undefined);
      setSearchResultLeases(result.data?.leases ?? []);
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorWhileSearching(error.message);
      }
      setSearchResultLeases([]);
    } finally {
      setIsLoading(false);
      setHideSearchCounter(false);
    }
  }

  function onSearchKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      executeSearch(searchQuery);
      analyticsService.track(SegmentEventTypes.BULK_RP_SEARCH_ENTER_PRESSED);
    }
  }

  /**
   * Memoized debounced search function
   * We memoize the debounced function to avoid creating a new debounced function on every render
   */
  const debouncedSearch = useCallback(
    debounce((queryStr: string | undefined) => {
      executeSearch(queryStr);
    }, DefaultDebounceDelays.GLOBAL_TEXT_SEARCH_CHANGED),
    []
  );

  /**
   * Search for leases when the search query changes
   */
  useEffectAsync(async () => {
    debouncedSearch(searchQuery);
  }, [searchQuery]);

  const onSearchDeleteIconPress = useCallback(() => {
    // set the search query to undefined
    setSearchQuery(undefined);
    // reset the search state
    setIsLoading(false);
    setLastSearchQuery(undefined);
    setHideSearchCounter(false);

    const payments = formikContext.values.payments;
    const finishedPaymentsExist = Object.values(payments).some((payment) => payment.isFinished);

    // if there are no finished payments, return
    if (!finishedPaymentsExist) return;

    // set the search result to all the finished payments in the formik context.payments
    const newSearchResults = Object.entries(payments)
      .filter(([_, payment]) => payment.isFinished)
      .map(([leaseId, payment]): LeaseDto => {
        return {
          id: leaseId,
          units: payment.unit ? [payment.unit] : [],
          name: payment.leaseName,
          property: payment.property,
          totalBalanceDue: payment.totalBalanceDue
        };
      });

    setSearchResultLeases(newSearchResults);
    setHideSearchCounter(true);
  }, [formikContext.values.payments]);

  function renderSearchResults() {
    if (searchResultLeases === undefined) {
      return (
        <EmptyDataView
          defaultHeight={300}
          instructionsText={AppStrings.BulkPayments.SearchToBegin}
          instructionsSubText={AppStrings.BulkPayments.ReceivePaymentsIntroText}
        />
      );
    }

    if (!hasSearchResults || errorWhileSearching) {
      return (
        <EmptyDataView
          defaultHeight={300}
          instructionsText={errorWhileSearching ?? AppStrings.Common.NoMatchesFound}
          instructionsSubText={AppStrings.Common.InputNewSearchAndTryAgain}
        />
      );
    }

    return (
      <View paddingBottom={20}>
        <EditablePaymentTable searchResultLeases={searchResultLeases} />
      </View>
    );
  }

  return (
    <View paddingLeft={20} paddingRight={20} marginTop={20} gap={10} noWrap height="calc(100% - 150px)">
      <View flexDirection="row" gap={20}>
        <View autoWidth>
          <DialogSearchPanel
            showDeleteTextIcon
            placeholder={AppStrings.BulkPayments.SearchPlaceholder}
            persistDeleteIcon={persistDeleteIcon}
            onChange={didChangeSearchQuery}
            borderRadius={30}
            marginTop={0}
            onDeleteIconPress={onSearchDeleteIconPress}
            requestInProgress={isLoading}
            hideSearchIcon
            onKeyDown={onSearchKeyDown}
          />
        </View>
        <DLButton
          onClick={() => {
            executeSearch(searchQuery);
          }}
          actionText={AppStrings.Common.Search}
          size={DLButtonSizesEnum.MEDIUM}
          color={DLButtonColorsEnum.SECONDARY}
          icons={{ start: { src: SearchIcon } }}
        />
      </View>

      {/* X Results found */}
      {!hideSearchCounter && searchResultLeases !== undefined && (
        <Text
          color="secondary-gray"
          value={searchResultLeases?.length === 1 ? AppStrings.Common.XResultFound : AppStrings.Common.XResultsFound}
          replaceObject={{ x: searchResultLeases?.length ?? 0 }}
          marginLeft={5}
        />
      )}

      <Grid container direction="column" style={{ height: "100%" }}>
        <Grid
          item
          xs={true}
          container
          alignItems={
            // if there are no results, we want to center the empty data view
            hasSearchResults ? "flex-start" : "center"
          }
        >
          {renderSearchResults()}
        </Grid>
      </Grid>
    </View>
  );
};

export default SearchAndFill;
