"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectOwnersAndPortfoliosDto = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const constants_1 = require("../payBills/constants");
class SelectOwnersAndPortfoliosDto {
    constructor(init) {
        Object.assign(this, init);
        this.propertiesButtonSelection = (init === null || init === void 0 ? void 0 : init.propertiesButtonSelection) || constants_1.SelectPropertiesType.ALL_PROPERTIES;
        this.ownersButtonSelection = (init === null || init === void 0 ? void 0 : init.ownersButtonSelection) || constants_1.SelectVendorsType.AllVendors;
    }
}
exports.SelectOwnersAndPortfoliosDto = SelectOwnersAndPortfoliosDto;
__decorate([
    (0, class_transformer_1.Type)(() => String),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateIf)((o, v) => o.ownersButtonSelection === constants_1.SelectVendorsType.SomeVendors),
    __metadata("design:type", Array)
], SelectOwnersAndPortfoliosDto.prototype, "filter_owners", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => String),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateIf)((o, v) => o.propertiesButtonSelection === constants_1.SelectPropertiesType.SOME_PROPERTIES),
    __metadata("design:type", Array)
], SelectOwnersAndPortfoliosDto.prototype, "filter_properties", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => String),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.ValidateIf)((o, v) => o.propertiesButtonSelection === constants_1.SelectPropertiesType.PORTFOLIO),
    __metadata("design:type", Array)
], SelectOwnersAndPortfoliosDto.prototype, "filter_groups", void 0);
