import React, { useCallback, useMemo, useState } from "react";

import { entityApiStore } from "@/api/entityApiStore/entityApiStore";
import { useResponsiveHelper } from "@/contexts/responsiveContext";
import { useTypedTranslation } from "@/locale";
import { ApiResult, PersonTypeEnum } from "@doorloop/dto";
import { useDebounceCallback } from "usehooks-ts";
import { usePaginatedGroupRenderer, useRemoteAutoComplete } from "../autoCompleteTyped/autoComplete.hooks";

import { useQueryClient } from "@tanstack/react-query";
import {
  DefaultCustomListboxComponentMemo,
  DefaultCustomPaperComponent,
  createOptionsGetter
} from "../autoCompleteTyped/autoComplete.utils";
import { AddNewPersonMemo } from "./addNewPerson";
import {
  MIN_SEARCH_LENGTH,
  NEXT_PAGE_SIZE,
  TEXT_FILTER_DEBOUNCE_MS,
  groupToPersonTypeMap
} from "./peopleAutoComplete.constants";
import { useGetPeople } from "./peopleAutoComplete.hooks";
import { groupByPersonType, narrowPersonDto } from "./peopleAutoComplete.utils";

import type { NarrowedPersonDto, PersonDtoType } from "@doorloop/dto";
import type { TextFieldProps } from "formik-material-ui";
import type { AutoCompleteRemoteState } from "../autoCompleteTyped/autoComplete.hooks";
import type { AutoCompleteProps, CustomPaperComponent } from "../autoCompleteTyped/autoComplete.types";
import type { CustomListboxComponent } from "../autoCompleteTyped/autoComplete.utils";
import type { PeopleAutoCompleteProps } from "./peopleAutoComplete";
import type { UseGetPeopleProps } from "./peopleAutoComplete.hooks";

const AddNewPersonMobileStyle = {
  marginTop: 0,
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 12
};

export interface UsePeopleAutoCompleteProps<TFilter extends readonly PersonTypeEnum[], TOrderFilter extends TFilter> {
  props: PickRequired<Partial<PeopleAutoCompleteProps>, "onChange">;
  queryOptions?: UseGetPeopleProps<TFilter, TOrderFilter>;
  currentType?: PersonTypeEnum;
}

export function usePeopleAutoComplete<TFilter extends readonly PersonTypeEnum[], TOrderFilter extends TFilter>({
  props,
  currentType: currentTypeBase,
  queryOptions
}: UsePeopleAutoCompleteProps<TFilter, TOrderFilter>): {
  state: AutoCompleteRemoteState;
  props: PeopleAutoCompleteProps;
} {
  const [textFilter, setTextFilter] = useState("");

  const handleInputChangeDebounced = useDebounceCallback((value: string) => {
    setTextFilter(value);
  }, TEXT_FILTER_DEBOUNCE_MS);

  const handleInputChange: AutoCompleteProps<NarrowedPersonDto>["onInputChange"] = (_event, valueBase, _reason) => {
    // Reset the text filter immediately when it's empty so we can render the
    // default query.
    const value = valueBase.trim();

    if (value === "" || value.length < MIN_SEARCH_LENGTH) {
      handleInputChangeDebounced.cancel();
      setTextFilter("");
      return;
    }

    // Do _NOT_ fetch when the search length is less than the minimum.
    handleInputChangeDebounced(value);
  };

  const handleOpen = (event: React.ChangeEvent<object>) => {
    handleInputChange(event, "", "input");
  };

  const onChangeProp = props.onChange;

  const handleChange: PeopleAutoCompleteProps["onChange"] = useCallback(
    (event, value, reason, details, promise) => {
      if (value === null) {
        setTextFilter("");
      }
      onChangeProp(event, value, reason, details, promise);
    },
    [onChangeProp]
  );

  const {
    data: baseData,
    queries,
    orderDictionary,
    isFetching
  } = useGetPeople({
    nextPageSize: NEXT_PAGE_SIZE,
    queryFilter: { filter_text: textFilter },
    staleTime: Infinity,
    ...queryOptions
  });

  const { entityId: personId } = props;

  const queryClient = useQueryClient();
  const currentType = currentTypeBase?.toLowerCase() as PersonTypeEnum;

  const getOneQueries = {
    [PersonTypeEnum.VENDOR]: entityApiStore.vendors.queries.useGetOne(personId, {
      enabled: currentType === PersonTypeEnum.VENDOR,
      staleTime: Infinity,
      initialData: () => queryClient.getQueryData(["vendors", "item", personId])
    }),
    [PersonTypeEnum.OWNER]: entityApiStore.owners.queries.useGetOne(personId, {
      enabled: currentType === PersonTypeEnum.OWNER,
      staleTime: Infinity,
      initialData: () => queryClient.getQueryData(["owners", "item", personId])
    }),
    [PersonTypeEnum.TENANT]: entityApiStore.tenants.queries.useGetOne(personId, {
      enabled: currentType === PersonTypeEnum.TENANT,
      staleTime: Infinity,
      initialData: () => queryClient.getQueryData(["tenants", "item", personId])
    })
  };

  const activeGetOneQuery = currentType && getOneQueries[currentType];

  // Explicit optional chaining at the first variable is required because some
  // queries are disabled and will return `undefined`.
  const isFetchingOne = currentType ? activeGetOneQuery?.isFetching : false;

  const { t } = useTypedTranslation();

  const selectedGroupTitle = t("common.autoComplete.selectedGroup");

  const dataForOptions = useMemo(() => {
    const data = [...baseData];

    const personDto =
      activeGetOneQuery?.data instanceof ApiResult
        ? activeGetOneQuery.data.data
        : (activeGetOneQuery?.data as unknown as PersonDtoType);

    if (personDto) {
      // The `index` parameter is set to `-1` arbitrarily, as it will be ignored
      // anyway since the AutoComplete looks up the option index in the options
      // array.

      data.unshift(narrowPersonDto(personDto, currentType as PersonTypeEnum, -1, selectedGroupTitle));
    }

    return data;
  }, [baseData, currentType, activeGetOneQuery, selectedGroupTitle]);

  const getOptions = useMemo(() => createOptionsGetter<NarrowedPersonDto>(dataForOptions), [dataForOptions]);

  const { isMobile } = useResponsiveHelper();

  // Do _NOT_ pass non-primitive props, or it will cause noticeable re-renders.
  // Instead use the `meta` prop to pass arbitrary data to children.
  const PersonPaperComponent = useCallback<CustomPaperComponent>(
    (props) => (
      <DefaultCustomPaperComponent {...props}>
        {(!isMobile || (isMobile && dataForOptions.length === 0)) && <AddNewPersonMemo />}
        {props.children}
      </DefaultCustomPaperComponent>
    ),
    [dataForOptions.length, isMobile]
  );

  // The `Listbox` component isn't rendered when there are no options, so we
  // need to render the `AddNewPerson` component in the `Paper` component, even
  // when on mobile.
  const PersonListboxComponent = useMemo<CustomListboxComponent>(
    () =>
      // eslint-disable-next-line prefer-arrow-callback
      React.forwardRef(function PersonListboxComponent(props, ref) {
        return (
          <DefaultCustomListboxComponentMemo ref={ref} {...props}>
            {isMobile && <AddNewPersonMemo style={AddNewPersonMobileStyle} />}
            {props.children}
          </DefaultCustomListboxComponentMemo>
        );
      }),
    [isMobile]
  );

  const renderGroup = usePaginatedGroupRenderer({
    queries,
    groupToQueryMap: groupToPersonTypeMap,
    excludeGroups: [selectedGroupTitle]
  });

  const [remoteState, remoteProps] = useRemoteAutoComplete<NarrowedPersonDto, PersonDtoType>({
    ...props,
    onChange: handleChange
  });

  const groupBy = props.groupBy || groupByPersonType;

  const textInputProps: Partial<TextFieldProps> = {
    ...props.textFieldProps
  };

  const metaProp = { orderDictionary, typeFilter: queryOptions?.typeFilter };

  return {
    state: remoteState,
    props: {
      ...props,
      ...remoteProps,
      textFieldProps: textInputProps,
      loading: isFetching || isFetchingOne,
      meta: metaProp,
      groupBy,
      getOptions,
      renderGroup,
      ListboxComponent: PersonListboxComponent,
      PaperComponent: PersonPaperComponent,
      onInputChange: handleInputChange,
      onOpen: handleOpen,
      getOptionDisabled: (option) => option.data?.groupName === selectedGroupTitle
    }
  };
}
