import type { FC } from "react";
import React from "react";

import { DataCy } from "@doorloop/dto";

import { BackgroundImageView, View } from "DLUI/view";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";
import { IconButton } from "DLUI/form";

import AppStrings from "../../../../../../../locale/keys";
import { DialogCloseIcon } from "../../../../../../../assets";
import { useResponsiveHelper } from "contexts/responsiveContext";

interface Props {
  onBackdropClick: () => void;
  onSubmit: () => void;
  ctaActionText?: string;
  isLoading: boolean;
  dialogHeight: number;
  backgroundMobileOffset: number;
  backgroundHeight: number;
  disabled?: boolean;
  actionButtonWrapper?: FC;
}

const StripeDialogContainer: FC<Props> = ({
  onBackdropClick,
  onSubmit,
  ctaActionText,
  dialogHeight,
  backgroundHeight,
  backgroundMobileOffset,
  isLoading,
  children,
  disabled,
  actionButtonWrapper
}) => {
  const { isMobile } = useResponsiveHelper();

  return (
    <View height={"100%"} fullWidth noWrap>
      <BackgroundImageView
        backgroundSize={"cover"}
        height={backgroundHeight + (isMobile ? backgroundMobileOffset : 0)}
        publicFileName={
          isMobile
            ? `url(/images/stripe-onboarding-background-mobile.png)`
            : `url(/images/stripe-onboarding-background-web.png)`
        }
      />

      <View noWrap height={isMobile ? "100%" : dialogHeight} style={{ position: "absolute" }}>
        <View flex={1}>
          <IconButton
            Icon={DialogCloseIcon}
            width={30}
            height={30}
            onClick={onBackdropClick}
            dataCy={DataCy.dialog.xButton}
            alignSelf={"flex-end"}
            marginTop={22}
            marginRight={22}
            removePadding
          />

          <BackgroundImageView publicFileName={`url(/images/doorloop+stripe.png)`} height={40} />

          {children}
        </View>

        <FormActionButtons
          propsActionPanel={{ marginTop: 10 }}
          propsSubButton={{
            onClick: onBackdropClick,
            actionText: AppStrings.Common.Close
          }}
          propsMainButton={{
            type: "cta",
            Wrapper: actionButtonWrapper,
            props: {
              onClick: onSubmit,
              actionText: ctaActionText,
              isLoading,
              disabled
            }
          }}
        />
      </View>
    </View>
  );
};

export { StripeDialogContainer };
