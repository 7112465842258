import React, { useState } from "react";
import { useNavigationContext } from "../../contexts/appLayoutContext";
import { AppLayouts } from "./index";
import type { NotificationsPanelType } from "DLUI/screen/notificationsPanel/notificationsPanel";
import { ToastRenderer } from "./toastRenderer/toastRenderer";

const LayoutRenderer = ({ children }) => {
  const { currentLayout } = useNavigationContext();
  const [notificationsPanelType] = useState<NotificationsPanelType>("emailConfirmation");
  const SelectedLayout = AppLayouts?.[currentLayout] || AppLayouts.SideBarLayout;

  return (
    <ToastRenderer>
      <SelectedLayout notificationsPanelType={notificationsPanelType}>{children}</SelectedLayout>
    </ToastRenderer>
  );
};

export default LayoutRenderer;
