export default {
  shortcuts: "Shortcuts",
  dashboard: "Dashboard",
  calendar: "Calendar",
  occupancyOverview: "Occupancy Overview",
  tasks: "Tasks",
  properties: "Properties",
  people: "People",
  leases: "Leases",
  tenants: "Tenants",
  listings: "Listings",
  communication: "Communication",
  signatures: "Signatures",
  management: "Management",
  screenings: "Screenings",
  expensess: "Expenses",
  maintenance: "Maintenance",
  vendors: "Vendors",
  users: "Users",
  leads: "Leads",
  prospects: "Prospects",
  owners: "Owners",
  unassignedTasks: "Unassigned Tasks",
  myTasks: "My Tasks",
  allTasks: "All Tasks",
  incompleteTasks: "Incomplete Tasks",
  tenantRequests: "Tenant Requests",
  ownerRequests: "Owner Requests",
  allNotes: "All Notes",
  allFiles: "All Files",
  recurringTasksAndWO: "Recurring Tasks & Work Orders",
  accounting: "Accounting",
  reports: "Reports",
  pages: "Pages",
  actions: "Actions",
  units: "Units",
  notes: "Notes",
  rentals: "Rentals",
  realEstate: "Real Estate",
  directory: "Directory",
  messaging: "Messaging",
  leasing: "Leasing",
  settings: "Settings",
  activeLeases: "Active Leases",
  inActiveLeases: "Inactive Leases",
  draftLeases: "Draft Leases",
  leaseRenewals: "Upcoming Renewals",
  rentalApplications: "Rental Applications",
  applications: "Applications",
  workOrders: "Work Orders",
  unpaidRent: "Unpaid Rent",
  openBills: "Open Bills",
  reconciliation: "Reconciliation",
  banking: "Banking",
  budgets: "Budgeting",
  printChecks: "Print Checks",
  billsAndExpenses: "Bills & Expenses",
  chargesAndPayment: "Charges & Payment",
  approvePayments: "Approve Payments",
  chartOfAccounts: "Chart of Accounts",
  rentRoll: "Rent Roll",
  profitLoss: "Profit & Loss",
  balanceSheet: "Balance Sheet",
  generalLedger: "General Ledger",
  ownerStatement: "Owner Statement",
  companyFinancials: "Company Financials",
  overview: "Overview",
  files: "Files",
  statements: "Statements",
  pets: "Pets",
  vehicles: "Vehicles",
  dependents: "Dependents",
  bankConnect: "BankConnect",
  transactions: "Transactions"
};
