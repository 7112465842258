import React from "react";
import type { ArticleIdsEnum } from "DLUI/screen/helpPanel/types";
import { LearnMoreText } from "DLUI/text/learnMoreText";
import { useManagementFeesStyles } from "screens/settings/managementFees/useStyles";

interface InstructionsTextProps {
  articleId: ArticleIdsEnum;
}

export const InstructionsText = ({ articleId }: InstructionsTextProps) => {
  const classes = useManagementFeesStyles();
  return (
    <div className={classes.instructionsText}>
      <LearnMoreText
        articleId={articleId}
        value={"settings.generalSettings.managementFees.managementFeesDialogDescription"}
      />
    </div>
  );
};
