"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenantPortalPaymentMethods = exports.EPayPaymentMethod = void 0;
var EPayPaymentMethod;
(function (EPayPaymentMethod) {
    EPayPaymentMethod["CARD"] = "CARD";
    EPayPaymentMethod["ECHECK"] = "ECHECK";
    EPayPaymentMethod["WU_CASH"] = "WESTERN_UNION_CASH";
})(EPayPaymentMethod || (exports.EPayPaymentMethod = EPayPaymentMethod = {}));
var TenantPortalPaymentMethods;
(function (TenantPortalPaymentMethods) {
    TenantPortalPaymentMethods["CARD"] = "CARD";
    TenantPortalPaymentMethods["ECHECK"] = "ECHECK";
    TenantPortalPaymentMethods["OTHER"] = "OTHER";
})(TenantPortalPaymentMethods || (exports.TenantPortalPaymentMethods = TenantPortalPaymentMethods = {}));
