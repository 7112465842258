import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    padding: {
      "padding-left": "5px",
      "padding-right": "5px"
    },
    titleFontSize: {
      fontSize: "32px",
      fontWeight: "bold"
    },
    subTitleFontSize: {
      fontSize: "24px"
    },
    lightTitleFontSize: {
      fontSize: "32px",
      fontWeight: 300,
      lineHeight: "38px"
    },
    listItemTitleFontSize: {
      fontSize: "16px",
      margin: "0px",
      lineHeight: "28px"
    },
    spanFontSize: {
      fontSize: "16px",
      lineHeight: "20px"
    },
    largeTitleFontSize: {
      fontSize: "44px",
      lineHeight: "60px",
      fontWeight: 900
    },
    labelFontSize: {
      fontSize: "12px",
      lineHeight: "12px"
    },

    baseFontSize: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "16px"
    },

    hyperLinkTextContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    currentColor: {
      color: "currentColor",
      textDecorationColor: `currentColor !important`
    },
    blueColor: {
      color: theme.palette.blue.main,
      textDecorationColor: `${theme.palette.blue.main} !important`
    },
    semiWhiteColor: {
      color: theme.palette.white.semi,
      textDecorationColor: `${theme.palette.white.semi} !important`
    },
    lightBlueColor: {
      color: theme.palette.blue.light,
      textDecorationColor: `${theme.palette.blue.light} !important`
    },
    boldBlueColor: {
      color: theme.palette.blue.dark,
      textDecorationColor: `${theme.palette.blue.dark} !important`
    },
    whiteColor: {
      color: theme.palette.white.main,
      textDecorationColor: `${theme.palette.white.main} !important`
    },
    spanColor: {
      color: theme.palette.gray.main,
      textDecorationColor: `${theme.palette.gray.main} !important`
    },
    secondaryGray: {
      color: theme.palette.gray.light,
      textDecorationColor: `${theme.palette.gray.light} !important`
    },
    lightPurpleColor: {
      color: theme.palette.purple.light,
      textDecorationColor: `${theme.palette.purple.light} !important`
    },
    purpleColor: {
      color: theme.palette.purple.main,
      textDecorationColor: `${theme.palette.purple.main} !important`
    },
    errorColor: {
      color: theme.palette.error.main,
      textDecorationColor: `${theme.palette.error.main} !important`
    },
    pinkColor: {
      color: theme.palette.pink.main,
      textDecorationColor: `${theme.palette.pink.main} !important`
    },
    blackColor: {
      color: theme.palette.black.main,
      textDecorationColor: `${theme.palette.black.main} !important`
    },
    greenColor: {
      color: theme.palette.darkGreen.main,
      textDecorationColor: `${theme.palette.darkGreen.main} !important`
    },
    orangeColor: {
      color: theme.palette.orange.main,
      textDecorationColor: `${theme.palette.orange.main} !important`
    },
    yellowColor: {
      color: theme.palette.yellow.main,
      textDecorationColor: `${theme.palette.yellow.main} !important`
    },
    secondaryYellowColor: {
      color: theme.palette.yellow.dark,
      textDecorationColor: `${theme.palette.yellow.dark} !important`
    },
    lightBlackFaded: {
      color: theme.newPalette.text.disabled,
      textDecorationColor: `${theme.newPalette.text.disabled} !important`
    },
    blueOverColor: {
      "&:hover": {
        color: theme.palette.blue.main
      }
    },
    lightBlueOverColor: {
      "&:hover": {
        color: theme.palette.blue.light
      }
    },
    whiteOverColor: {
      "&:hover": {
        color: theme.palette.white.main
      }
    },
    robotoSlabFont: {
      fontFamily: '"Roboto Slab" !important'
    },
    poppinsFont: {
      fontFamily: "Poppins !important"
    }
  };
});
