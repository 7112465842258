import type { BulkReceiveSessionDto, LeaseDto } from "@doorloop/dto";
import { BulkPaymentWrapperDto, createValidator } from "@doorloop/dto";
import { propertiesApi } from "api/propertiesApi";
import { unitsApi } from "api/unitsApi";
import type { EditableTableColumn } from "DLUI/editableTable/editableTable";
import { EditableTable } from "DLUI/editableTable/editableTable";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { FormikText } from "DLUI/text/formikText";
import type { TextColor } from "DLUI/text/text";
import { Formik, useFormikContext } from "formik";
import AppStrings from "locale/keys";
import moment from "moment";
import React, { useEffect } from "react";
import { DateFormats } from "@doorloop/dto";
import ExpandedCard from "./expandedCard";
import { useTranslation } from "react-i18next";

interface ComponentProps {
  searchResultLeases: LeaseDto[];
}

const tableRowColors = {
  default: "white",
  hover: "#F8F9FA",
  finished: "#DFF3F1",
  finishedHover: "#CDEDE9"
} as const;

// Property Unit Lease Balance Amount Received
// sizes must add up to 12
const Columns: ReadonlyArray<EditableTableColumn<LeaseDto>> = [
  {
    field: "checkbox",
    headerName: "",
    gridSize: "auto"
  },
  {
    field: "property",
    headerName: AppStrings.Common.FilterOptions.Property,
    gridSize: 2,
    valueGetter: (dto, t) =>
      dto.property
        ? propertiesApi.getItemFromDictionary(dto.property!)?.name ?? t(AppStrings.Common.NotAvailableAbbreviated)
        : ""
  },
  {
    field: (dto) => (dto.units?.length > 1 ? "multipleUnits" : "unit"),
    headerName: AppStrings.Common.FilterOptions.Unit,
    gridSize: 2,
    valueGetter: (dto, t) =>
      dto.units?.length
        ? dto.units.length > 1
          ? `${dto.units.length} units`
          : unitsApi.getItemFromDictionary(dto.units[0]!)?.name ?? t(AppStrings.Common.NotAvailableAbbreviated)
        : ""
  },
  {
    field: "lease",
    headerName: AppStrings.Common.FilterOptions.Lease,
    gridSize: 3,
    valueGetter: (dto, t) => dto.name || ""
  },
  {
    field: "balance",
    headerName: AppStrings.BulkPayments.Balance,
    gridSize: 2,
    align: "right",
    formatType: "currency",
    valueGetter: (dto, t) => dto.totalBalanceDue?.toString() || ""
  },
  {
    field: "amountReceived",
    headerName: AppStrings.BulkPayments.AmountReceived,
    gridSize: true,
    align: "right",
    formatType: "currency",
    renderEditableCell: (dto, t) => (
      <FastFieldSafe
        formatType="currency"
        fontSize={14}
        component={FormikText}
        name={`payments[${dto.id}].amountReceived`}
      />
    )
  }
] as const;

const EditablePaymentTable = ({ searchResultLeases }: ComponentProps) => {
  const formikContext = useFormikContext<BulkReceiveSessionDto>();
  const [selectedIndices, setSelectedIndices] = React.useState<Record<number, boolean>>({});
  const [expandedIndices, setExpandedIndices] = React.useState<Record<number, boolean>>({});
  const { t } = useTranslation();

  useEffect(() => {
    // We check the formikContext payments and unselect the rows that are not in the payments
    // This happens when we delete a payment from the formik context (void payment)
    const newSelectedIndices = { ...selectedIndices };

    Object.keys(selectedIndices).forEach((index) => {
      const leaseId = searchResultLeases[Number(index)].id!;
      // we dont want to delete the row if it exists in search results and its expanded
      // because the user might be editing the payment
      if (expandedIndices?.[index]) {
        return;
      }

      if (!formikContext.values.payments?.[leaseId]) {
        delete newSelectedIndices[index];
      }
    });
    setSelectedIndices(newSelectedIndices);
  }, [formikContext.values.payments]);

  useEffect(() => {
    const preselectedIndices = {};
    // reset the selected and expanded indices when the search result changes
    // check if we already have finished payments in the current context
    if (formikContext.values.payments) {
      for (let i = 0; i < searchResultLeases.length; i++) {
        const lease = searchResultLeases[i];
        // if the lease is in payments, and the payment is finished, we select the row
        if (formikContext.values.payments?.[lease.id!]?.isFinished) {
          preselectedIndices[i] = true;
        }
      }
    }
    setSelectedIndices(preselectedIndices);
    setExpandedIndices({});
  }, [searchResultLeases]);

  const changeRowSelection = (selected: boolean, index: number) => {
    setSelectedIndices((prev) => {
      return { ...prev, [index]: selected };
    });
  };

  const changeRowExpansion = (expanded: boolean, index: number) => {
    setExpandedIndices((prev) => {
      return { ...prev, [index]: expanded };
    });
  };

  const voidPayment = (leaseId: string) => {
    formikContext.setFieldValue(`payments[${leaseId}]`, undefined, false);
  };

  const onSelectionChanged = (selected: boolean, index: number) => {
    const isRowExpanded = Boolean(expandedIndices?.[index]);

    if (!selected) {
      // if we unselect the row - remove the payment from the formik context
      voidPayment(searchResultLeases[index].id!);
    }

    if (selected !== isRowExpanded) {
      changeRowExpansion(selected, index);
    }

    changeRowSelection(selected, index);
  };

  const onSelectAllClicked = () => {
    const selectedKeys = Object.keys(selectedIndices);
    const allSelected =
      selectedKeys.length === searchResultLeases.length && selectedKeys.every((key) => selectedIndices[key]);

    // check if there are finished payments in the current searchResultLeases
    const hasFinishedPayments = searchResultLeases.some(
      (lease) => formikContext.values.payments?.[lease.id!]?.isFinished
    );

    if (allSelected) {
      if (hasFinishedPayments && !window.confirm(t(AppStrings.BulkPayments.UserConfirmUnselectRows))) return;
      setSelectedIndices({});
      setExpandedIndices({});
      // void all payments
      searchResultLeases.forEach((lease) => voidPayment(lease.id!));
      return;
    }

    // if not all selected, we expand all the rows
    const newExpandedIndices = { ...expandedIndices };
    const newSelectedIndices = { ...selectedIndices };

    searchResultLeases.forEach((lease, index) => {
      if (formikContext.values.payments?.[lease.id!]?.isFinished) {
        return;
      }
      newExpandedIndices[index] = newSelectedIndices[index] = true;
    });

    setExpandedIndices(newExpandedIndices);
    setSelectedIndices(newSelectedIndices);
  };

  /**
   * This function will expand and unexpand the card view of the row
   */
  const onRowClicked = (dto: LeaseDto, index: number, isDisabled: boolean) => {
    // we do not allow clicking on the row if the row is disabled
    if (isDisabled) return;

    const isRowSelected = Boolean(selectedIndices?.[index]);
    const isRowExpanded = Boolean(expandedIndices?.[index]);

    if (!isRowSelected) {
      changeRowSelection(true, index);
      changeRowExpansion(true, index);
    } else if (isRowExpanded) {
      // we only allow collapsing the row if the payment is finished
      const payment = formikContext.values.payments?.[dto.id!];
      if (payment?.isFinished) {
        changeRowExpansion(false, index);
      }
    } else {
      changeRowExpansion(true, index);
    }
  };

  const onCancelPaymentClicked = (dto: LeaseDto, index: number) => {
    changeRowExpansion(false, index);
    // check if the payment is finished
    const payment = formikContext.values.payments?.[dto.id!];
    if (!payment?.isFinished) {
      changeRowSelection(false, index);
    }
  };

  const renderExpandedCard = (dto: LeaseDto, index: number): JSX.Element => {
    if (!dto.id) return <></>;
    const initialValues =
      formikContext.values.payments?.[dto.id] ||
      new BulkPaymentWrapperDto({
        lease: dto.id,
        leaseName: dto.name,
        isFinished: false,
        date: moment().format(DateFormats.ISO_DATE_SERVER_FORMAT),
        autoApplyPaymentOnCharges: true,
        amountReceived: 0,
        property: dto.property,
        unit: dto.units?.[0],
        totalBalanceDue: dto.totalBalanceDue
      });

    const onExpandedCardFormikSubmitted = (values: BulkPaymentWrapperDto) => {
      values.isFinished = true;
      formikContext.setFieldValue(`payments[${dto.id}]`, values, false);
      changeRowExpansion(false, index);
      changeRowSelection(true, index);
    };

    const validator = createValidator(BulkPaymentWrapperDto);

    return (
      <Formik initialValues={initialValues} onSubmit={onExpandedCardFormikSubmitted} validate={validator}>
        {(formik) => (
          <ExpandedCard
            dto={dto}
            index={index}
            onAddClick={formik.submitForm}
            onCancelClick={() => onCancelPaymentClicked(dto, index)}
          />
        )}
      </Formik>
    );
  };

  const rowColorPredicate = (
    dto: LeaseDto,
    index: number,
    isSelected: boolean
  ): React.CSSProperties["backgroundColor"] => {
    if (!dto.id || expandedIndices?.[index]) return tableRowColors.default;

    return formikContext.values.payments?.[dto.id]?.isFinished ? tableRowColors.finished : tableRowColors.default;
  };

  const rowHoverColorPredicate = (
    dto: LeaseDto,
    index: number,
    isSelected: boolean
  ): React.CSSProperties["backgroundColor"] => {
    if (!dto.id || expandedIndices?.[index]) return tableRowColors.default;

    return formikContext.values.payments?.[dto.id]?.isFinished ? tableRowColors.finishedHover : tableRowColors.hover;
  };

  const rowTextColor = (dto: LeaseDto, index: number, isSelected: boolean): TextColor =>
    isSelected ? "black" : "gray";

  return (
    <EditableTable
      isLoading={false}
      data={searchResultLeases}
      columns={Columns}
      rowHeight={"auto"}
      headerRowHeight={45}
      fontSize={14}
      checkboxSize={25}
      style={{
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0
      }}
      hideFooter
      displayMode="card"
      rowHoverColor={rowHoverColorPredicate}
      rowColor={rowColorPredicate}
      rowTextColor={rowTextColor}
      singularItemName={""}
      pluralItemName={""}
      onSelectionChanged={onSelectionChanged}
      onSelectAllClicked={onSelectAllClicked}
      selectedIndices={selectedIndices}
      expandedIndices={expandedIndices}
      onRowClicked={onRowClicked}
      noDataComponent={<></>}
      renderExpandedCard={renderExpandedCard}
    />
  );
};

export default EditablePaymentTable;
