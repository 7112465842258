import MomentUtils from "@date-io/moment";
import type { GetAllLeaseDraftsQuery, LeaseDto, PropertyDto } from "@doorloop/dto";
import { LeaseDraftDto, LeaseTermType, PropertyClass } from "@doorloop/dto";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { FieldSafe } from "DLUI/fieldSafe/fieldSafe";
import {
  FormikDatePicker,
  FormikImageButtonGroup,
  FormikMultiSelectAutoComplete,
  FormikSwitchButton,
  ValidationIndicator
} from "DLUI/form";
import WarningView from "DLUI/form/warningView/warningView";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import { leasesApi } from "api/leasesApi";
import { unitsApi } from "api/unitsApi";
import { useFormikContext } from "formik";
import { useEffectAsync } from "hooks/useEffectAsync";
import AppStrings from "locale/keys";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConflictingLeaseError } from "screens/leases/newLease/wizard/components/errors/conflictingLeaseError";
import { termTypes } from "screens/leases/newLease/wizard/components/leaseOveriew/types";
import { PropertyListItem } from "./propertyListItem";
import { propertiesApi } from "@/api/propertiesApi";
import { useStyles } from "./styles";

interface ComponentProps {
  onLeaseConflict?: (hasConflict: boolean) => void;
  property: PropertyDto;
}

export const LeaseSettingsForm: React.FC<ComponentProps> = ({ onLeaseConflict, property }: ComponentProps) => {
  const classes = useStyles();
  const formikRef = useFormikContext<LeaseDto>();
  const { t } = useTranslation();
  const termDefaultValue = formikRef.values.term;

  const [imageButtonGroupRef, setImageButtonGroupRef] = useState<any | undefined>();

  const [termTypesValue, setTermTypesValue] = useState<LeaseTermType | undefined>(termDefaultValue);
  const [termConflictingLeases, setTermConflictingLeases] = useState<LeaseDto[]>([]);

  const errorText = formikRef.errors.term;
  const touchedVal = formikRef.touched.term;
  const hasError = Boolean(touchedVal && errorText !== undefined);

  const didSelectTermType = (value: string | boolean, itemIndex: number) => {
    setTermTypesValue(value as LeaseTermType);
    formikRef.setFieldValue("term", value);
    formikRef.setFieldValue("end", moment().add(1, "years").format("YYYY-MM-DD").toString());
  };

  const checkForLeaseConflicts = async () => {
    if (!formikRef.values.units) return;

    const units = Array.isArray(formikRef.values.units) ? formikRef.values.units : [formikRef.values.units];
    const start = formikRef.values.start;
    const end = formikRef.values.end;

    if (!(property && units && start)) {
      return false;
    }

    return await leasesApi.checkForLeaseConflicts({
      property: property.id,
      units,
      start,
      end,
      excludeIds: [formikRef.values.id!]
    });
  };

  useEffectAsync(async () => {
    if (!formikRef.values.units || formikRef.values.units.length === 0) return;
    if (!formikRef.values.start && !formikRef.values.end) return;

    const conflictCheck = await checkForLeaseConflicts();
    if (conflictCheck && conflictCheck.conflictsExist) {
      setTermConflictingLeases(conflictCheck.conflictingLeases || []);
      onLeaseConflict?.(true);
    } else {
      setTermConflictingLeases([]);
      onLeaseConflict?.(false);
    }
  }, [formikRef.values.units, formikRef.values.start, formikRef.values.end]);

  const conflictingUnits = useMemo(
    () =>
      formikRef.values.units
        .filter((unit) => unit !== undefined && termConflictingLeases.some((lease) => lease.units.includes(unit)))
        .map((unit) => {
          const unitValues = unitsApi.getItemFromDictionary(unit);
          return {
            ...unitValues,
            lease: termConflictingLeases.find((lease) => lease.units.includes(unit)),
            propertyType: propertiesApi.getItemFromDictionary(unitValues?.property)?.type
          };
        })
        .filter((unit) => unit),
    [termConflictingLeases]
  );

  const renderTermsType = () => {
    const onImageButtonGroupRef = (ref: React.MutableRefObject<{ clearSelection: () => void }>) => {
      setImageButtonGroupRef(ref);
    };

    return (
      <View>
        <View paddingTop={20} alignItems={"center"} gap={10}>
          <Text
            align={"center"}
            value={AppStrings.Leases.NewLease.LeaseTermsTitle}
            bold
            fontSize={20}
            color={"black"}
          />
          <Text align={"center"} value={AppStrings.Leases.NewLease.LeaseTerm} fontSize={16} color={"gray"} />
        </View>
        <View marginTop={10}>
          <FastFieldSafe
            component={FormikImageButtonGroup}
            marginRight={0}
            uniqueId={"TermsType"}
            buttonItemsArray={termTypes}
            onSelect={didSelectTermType}
            onRef={onImageButtonGroupRef}
            name={"term"}
            size={135}
          />
        </View>
      </View>
    );
  };

  const renderDateInputs = () => {
    if (termTypesValue) {
      const shouldShowStartDate = Boolean(termTypesValue);
      const shouldShowEndDate = shouldShowStartDate && termTypesValue === LeaseTermType.Fixed;

      return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid justifyContent={"center"} container spacing={2}>
            <Grid
              container
              justifyContent={"flex-end"}
              className={classes.inputTopMargin}
              item
              xs={12}
              md={6}
              lg={shouldShowEndDate ? 5 : 6}
            >
              <View
                shouldShow={shouldShowStartDate}
                showAnimation={termDefaultValue === undefined ? "fade-in" : "slide-in-left"}
                hideAnimation={"slide-out-left"}
                paddingTop={5}
              >
                <FastFieldSafe
                  dto={LeaseDraftDto}
                  component={FormikDatePicker}
                  uniqueKey={"termStartDate"}
                  label={AppStrings.Leases.NewLease.StartDate}
                  name={"start"}
                  noMargin
                />
              </View>
            </Grid>
            {shouldShowEndDate ? (
              <Grid container justifyContent={"flex-end"} className={classes.inputTopMargin} item xs={12} md={6} lg={5}>
                <View
                  shouldShow={shouldShowEndDate}
                  showAnimation={termDefaultValue === undefined ? "fade-in" : "slide-in-left"}
                  hideAnimation={"slide-out-left"}
                  paddingTop={5}
                >
                  <FastFieldSafe
                    dto={LeaseDraftDto}
                    component={FormikDatePicker}
                    uniqueKey={"termEndDate"}
                    label={AppStrings.Leases.NewLease.EndDate}
                    name={"end"}
                    noMargin
                    minDate={formikRef.values.start ? moment(formikRef.values.start).toDate() : undefined}
                  />
                </View>
              </Grid>
            ) : null}
            <Grid container justifyContent={"flex-end"} className={classes.inputTopMargin} item xs={12} md={12} lg={12}>
              {shouldShowEndDate && (
                <View paddingTop={10} justifyContent="center" alignItems="center" flexDirection="row">
                  <span>
                    <FastFieldSafe
                      dto={LeaseDraftDto}
                      labelNoWrap
                      component={FormikSwitchButton}
                      name={"rolloverToAtWill"}
                      label={AppStrings.Leases.NewLease.SwitchToAtWill}
                    />
                  </span>
                </View>
              )}
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      );
    }
  };

  const renderCommercialPropertySettings = () => {
    const isCommercialProperty = property.class === PropertyClass.COMMERCIAL;

    if (!isCommercialProperty) {
      return null;
    }

    return (
      <View alignItems="center" marginTop={20} gap={20}>
        <Text
          color="gray"
          fontSize={16}
          value={AppStrings.Leases.EditLease.SelectUnitsAddRemoveFromXLease}
          replaceObject={{ x: formikRef.values.name ?? "" }}
        />
        <PropertyListItem property={property} />
        <FieldSafe
          // key is used to force re-render when conflicting units change (layout shift fix)
          key={"leaseEditUnits_" + conflictingUnits?.length.toString()}
          component={FormikMultiSelectAutoComplete<LeaseDraftDto, GetAllLeaseDraftsQuery>}
          label={t(AppStrings.Leases.NewLease.LeaseOverview.PropertySection.UnitsPlaceholder)}
          uniqueIndex={"leaseEditUnits"}
          apiHandler={unitsApi}
          displayNameKey={"name"}
          queryParams={property.id ? { filter_property: property.id } : undefined}
          selectionFields={["id"]}
          name={"units"}
          chips="counterOnly"
        />
        <WarningView
          type="info"
          title={AppStrings.Leases.NewLease.PleaseNote}
          subTitle={AppStrings.Leases.NewLease.MultipleUnitsLeaseTotalsInfo}
        />
        <HorizontalSeparationLine height={2} />
      </View>
    );
  };

  return (
    <View alignItems={"center"} paddingRight={20} paddingLeft={20} paddingBottom={50}>
      <View alignItems={"center"}>
        {renderCommercialPropertySettings()}
        {renderTermsType()}
        <ValidationIndicator shouldShow={hasError} maxWidth={400} displayText={errorText} />
        {renderDateInputs()}
        <ConflictingLeaseError units={conflictingUnits} shouldShow={termConflictingLeases.length > 0} />
      </View>
    </View>
  );
};
