import React, { useEffect, useState } from "react";
import type { FieldProps } from "formik";
import { getIn } from "formik";
import { ControlledCheckBox } from "DLUI/form/checkBox/base/controlledCheckBox";
import type { ToggleInputSize } from "DLUI/form/types/toggleInputBaseTypes";
import type { CheckBoxColor } from "DLUI/form/checkBox/base/types";
import type { TextColor } from "DLUI/text/text";

export interface FormikCheckBoxComponentProps extends FieldProps<any> {
  color?: CheckBoxColor;
  size?: ToggleInputSize;
  indeterminate?: boolean;
  didChangeCheckBoxSelection?: (checked: boolean) => void;
  labelText?: string;
  href?: string;
  disabled?: boolean;
  textColor?: TextColor;
  fullWidth?: boolean;
  marginTop?: number;
  marginLeft?: number;
  overrideIconSize?: number;
  dataCy?: string;
}

const FormikCheckBox: React.FC<FormikCheckBoxComponentProps> = ({
  didChangeCheckBoxSelection,
  labelText,
  href,
  disabled,
  textColor,
  indeterminate,
  fullWidth,
  color,
  marginTop,
  form,
  field,
  dataCy,
  marginLeft,
  size,
  overrideIconSize
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    const fieldValue = getIn(form.values, field.name);
    setIsChecked(fieldValue || false);
  }, [form.values]);

  const _didChangeCheckBoxSelection = () => {
    if (didChangeCheckBoxSelection) {
      setIsChecked(!isChecked);
      didChangeCheckBoxSelection(!isChecked);
    }
    setTimeout(() => {
      form.setFieldValue(field.name, !isChecked);
    }, 0);
  };

  return (
    <ControlledCheckBox
      labelValueProps={{
        href,
        text: labelText,
        placement: "end",
        clickable: true,
        color: textColor || "blue"
      }}
      size={size}
      color={color}
      fullWidth={fullWidth}
      dataCy={dataCy || field.name}
      onChange={_didChangeCheckBoxSelection}
      disabled={disabled}
      checked={isChecked}
      indeterminate={indeterminate}
      style={{
        marginTop,
        marginLeft
      }}
    />
  );
};

export default FormikCheckBox;
