import React from "react";
import type { NotifiableError } from "@bugsnag/js";
import type BugSnag from "@bugsnag/js";
import _ from "lodash";

interface CreateErrorServiceParams {
  bugsnag?: typeof BugSnag;
}

export const createErrorService = ({ bugsnag }: CreateErrorServiceParams) => {
  return {
    notifyError(error: NotifiableError, data: Record<string, any>) {
      if (bugsnag) {
        const metadata = {
          custom: {
            ...data
          }
        };

        bugsnag.notify(
          error,
          (error) => {
            console.log("Error reported to Bugsnag", error);
          },
          (err, event) => {
            if (err) {
              console.log("Error reporting to Bugsnag", err);
            }

            Object.entries(metadata).forEach(([sectionName, section]) => {
              Object.entries(section || {}).forEach(([metadataKey, metadataItem]) => {
                if (_.isObject(metadataItem)) {
                  metadataItem = JSON.stringify(metadataItem);
                }

                event.addMetadata(sectionName, metadataKey, metadataItem);
              });
            });
          }
        );
      }
    }
  };
};

export const ErrorServiceContext = React.createContext<ReturnType<typeof createErrorService>>(createErrorService({}));

export const useErrorService = () => React.useContext(ErrorServiceContext);
