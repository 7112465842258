import React from "react";
import { TextField } from "DLUI/form";
import { Field, useFormikContext } from "formik";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import AddressAutoComplete from "./addressAutoComplete";
import Grid from "@material-ui/core/Grid";
import { View } from "DLUI/view";
import FieldWrapper from "DLUI/form/textField/fieldWrapper";
import { DataCy } from "@doorloop/dto";
import { autoSelectAddress } from "./utils";
import CountryAutoComplete from "DLUI/form/autoComplete/addressAutoComplete/smarty/countryAutoComplete";
import { countriesOptions } from "DLUI/form/autoComplete/addressAutoComplete/addressAutoCompleteFormWrapper";
import { StateAddress } from "DLUI/form/autoComplete/addressAutoComplete/stateAddress";

export interface GoogleAddressAutoCompleteFormProps {
  formikName: string;
  didSelectAutoCompleteAddress?: (selectedPlace: any) => void;
  fullWidthAddresses?: boolean;
  dataCy?: string;
  required?: boolean;
  showOnlyStreet?: boolean;
  handleChangeSearchAddressEngine?: (engineType: "google" | "smarty") => void;
  street1PlaceHolderLabel?: string;
  disabled?: boolean;
}

const GoogleAddressAutoCompleteForm: React.FC<GoogleAddressAutoCompleteFormProps> = ({
  didSelectAutoCompleteAddress,
  formikName,
  fullWidthAddresses,
  dataCy,
  required = true,
  showOnlyStreet,
  handleChangeSearchAddressEngine,
  street1PlaceHolderLabel = AppStrings.Properties.Address.Labels.Street1,
  disabled = false
}: GoogleAddressAutoCompleteFormProps) => {
  const formik = useFormikContext();
  const { t } = useTranslation();
  const streetsGrid = fullWidthAddresses || showOnlyStreet ? 12 : 6;
  const cityStateCountryZipGrid = fullWidthAddresses ? 3 : 6;

  const handleAddressChange = (selectedPlace: any) => {
    autoSelectAddress(selectedPlace, formik, formikName);
    didSelectAutoCompleteAddress?.(selectedPlace);
  };

  return (
    <View>
      <View flexDirection={"row"} data-cy={dataCy}>
        <Grid item xs={12} sm={streetsGrid} md={streetsGrid} lg={streetsGrid}>
          <FieldWrapper type={fullWidthAddresses || showOnlyStreet ? "singleField" : "endElement"}>
            <Field
              component={AddressAutoComplete}
              name={`${formikName}.street1`}
              label={t(street1PlaceHolderLabel)}
              required={required}
              fullWidth
              onAddressAutocomplete={handleAddressChange}
              formikName={formikName}
              size={"100%"}
              data-cy={DataCy.addressInformation.street1}
              disabled={disabled}
            />
          </FieldWrapper>
        </Grid>
        {!showOnlyStreet && (
          <Grid item xs={12} sm={streetsGrid} md={streetsGrid} lg={streetsGrid}>
            <FieldWrapper type={fullWidthAddresses ? "singleField" : "startElement"}>
              <Field
                component={TextField}
                name={`${formikName}.street2`}
                label={t(AppStrings.Properties.Address.Labels.Street2)}
                fullWidth
                size={"100%"}
                data-cy={DataCy.addressInformation.street2}
                disabled={disabled}
              />
            </FieldWrapper>
          </Grid>
        )}
      </View>
      {!showOnlyStreet && (
        <View flexDirection={"row"}>
          <Grid item xs={12} sm={cityStateCountryZipGrid} md={cityStateCountryZipGrid} lg={cityStateCountryZipGrid}>
            <FieldWrapper type={"endElement"}>
              <Field
                component={TextField}
                name={`${formikName}.city`}
                label={t(AppStrings.Properties.Address.Labels.City)}
                required={required}
                size={"100%"}
                data-cy={DataCy.addressInformation.city}
                disabled={disabled}
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={12} sm={cityStateCountryZipGrid} md={cityStateCountryZipGrid} lg={cityStateCountryZipGrid}>
            <FieldWrapper type={fullWidthAddresses ? "middleElement" : "startElement"}>
              <StateAddress
                formikName={formikName}
                disabled={disabled}
                handleChangeSearchAddressEngine={handleChangeSearchAddressEngine}
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={12} sm={cityStateCountryZipGrid} md={cityStateCountryZipGrid} lg={cityStateCountryZipGrid}>
            <FieldWrapper type={fullWidthAddresses ? "middleElement" : "endElement"}>
              <Field
                component={CountryAutoComplete}
                handleChangeSearchAddressEngine={handleChangeSearchAddressEngine}
                uniqueId={"AutoCompleteCountryAddress"}
                name={`${formikName}.country`}
                label={t(AppStrings.Properties.Address.Labels.Country)}
                required
                data-cy={DataCy.addressInformation.country}
                values={countriesOptions}
                isAddressDefined={Boolean(formik.values?.[formikName])}
                disabled={disabled}
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={12} sm={cityStateCountryZipGrid} md={cityStateCountryZipGrid} lg={cityStateCountryZipGrid}>
            <FieldWrapper type={"startElement"}>
              <Field
                component={TextField}
                name={`${formikName}.zip`}
                label={t(AppStrings.Properties.Address.Labels.ZipCode)}
                size={"100%"}
                data-cy={DataCy.addressInformation.zip}
                required={required}
                disabled={disabled}
              />
            </FieldWrapper>
          </Grid>
        </View>
      )}
    </View>
  );
};

export default GoogleAddressAutoCompleteForm;
