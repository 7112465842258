import React, { useMemo, useState } from "react";
import { View } from "DLUI/view";
import { FastField, getIn, useFormikContext } from "formik";
import type { RentalApplicationCompanySettingsDto } from "@doorloop/dto";
import Text from "DLUI/text";
import AppStrings from "../../../../../locale/keys";
import { FormikSwitchButton, RadioGroup } from "DLUI/form";
import { useTranslation } from "react-i18next";
import { AnimatedView } from "DLUI/view/animatedView";
import { SeparationLine } from "DLUI/separatorView";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { RichTextField } from "DLUI/form/richTextField/richTextField";
import WarningView from "DLUI/form/warningView/warningView";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";

const Terms: React.FC<{}> = () => {
  const { t } = useTranslation();
  const {
    values: { landlordTermsAndConditions },
    setFieldValue,
    setFieldTouched,
    errors
  } = useFormikContext<RentalApplicationCompanySettingsDto>();

  const { propertyId } = useParams<any>();
  const [requireTermsAndConditions, setRequireTermsAndConditions] = useState<boolean>(
    getIn(landlordTermsAndConditions, "enabled") || false
  );

  const didChangeRequireTerms = (nextSwitchState: boolean) => {
    const toggleOff = !nextSwitchState;
    if (toggleOff) {
      setFieldValue("landlordTermsAndConditions.termsAndConditions", undefined);
    }
    setRequireTermsAndConditions(nextSwitchState);
  };
  const [requireTermsSelectionType, setRequireTermsSelectionType] = useState<"companyDefault" | "specifyTerms">(
    _.isEmpty(landlordTermsAndConditions) ? "companyDefault" : "specifyTerms"
  );

  const didChangeSpecifyTerms = (selectedValue: string) => {
    const useCompanyDefault = selectedValue == "false";
    setFieldTouched("landlordTermsAndConditions.termsAndConditions");

    if (useCompanyDefault) {
      setFieldValue("landlordTermsAndConditions", undefined);
      setRequireTermsAndConditions(false);
    } else {
      setFieldValue("landlordTermsAndConditions.enabled", false);
      setFieldValue("landlordTermsAndConditions.isShown", false);
    }
    setRequireTermsSelectionType(useCompanyDefault ? "companyDefault" : "specifyTerms");
  };

  const isValid = useMemo(() => !errors.landlordTermsAndConditions, [errors]);

  const renderSpecifyRadioButtons = () => {
    if (propertyId) {
      return (
        <View>
          <RadioGroup
            didChangeSelection={didChangeSpecifyTerms}
            uniqueKey={"FRB"}
            defaultValue={requireTermsSelectionType === "companyDefault" ? "false" : "true"}
            marginTop={20}
            radioGroupItems={[
              {
                label: AppStrings.Common.HideSectionForProperty,
                value: "false"
              },
              {
                label: AppStrings.Common.SpecifySettingsForProperty,
                value: "true"
              }
            ]}
          />
          <SeparationLine marginTop={20} width={"100%"} height={1} />
        </View>
      );
    }
  };

  return (
    <>
      <Text
        lineHeight={"22px"}
        value={AppStrings.Settings.GeneralSettings.RentalApplications.TermsAndConditionsDescription}
      />
      {renderSpecifyRadioButtons()}
      <View height={20} />
      <AnimatedView shouldShow={requireTermsSelectionType === "specifyTerms" || !propertyId}>
        <FastField
          component={FormikSwitchButton}
          name={"landlordTermsAndConditions.enabled"}
          label={AppStrings.Settings.GeneralSettings.RentalApplications.RequireTermsAndConditions}
          onChange={didChangeRequireTerms}
        />
        <AnimatedView shouldShow={requireTermsAndConditions}>
          <View height={20} />
          <FastFieldSafe
            component={RichTextField}
            label={t(AppStrings.Settings.GeneralSettings.RentalApplications.TermsAndConditions)}
            name={`landlordTermsAndConditions.termsAndConditions`}
            maxLength={10000}
            required
            html={{
              name: "landlordTermsAndConditions.termsAndConditionsHTML",
              value: landlordTermsAndConditions?.termsAndConditionsHTML || ""
            }}
          />
        </AnimatedView>
        {!isValid && (
          <View marginBottom={20} marginTop={20}>
            <WarningView iconSize={20} type={"danger"} subTitle={AppStrings.Common.FillRequiredFields} />
          </View>
        )}
      </AnimatedView>
    </>
  );
};

export default Terms;
