import type { FC } from "react";
import React from "react";
import { View } from "DLUI/view";
import { NoAccessHouse } from "assets/icons";
import { Icon } from "DLUI/icon";
import Text from "DLUI/text";
import { useTranslation } from "react-i18next";
import AppStrings from "locale/keys";

export const NoAccessDisplay: FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <View
      flex={1}
      paddingLeft={20}
      paddingRight={20}
      alignItems={"center"}
      justifyContent={"center"}
      width={"100%"}
      noWrap
    >
      <Icon Source={NoAccessHouse} />
      <Text marginTop={20} fontSize={45} lineHeight={"45px"}>
        {t(AppStrings.UserRoles.AccessDenied)}
      </Text>
      <Text marginTop={20} fontSize={20} lineHeight={"30px"}>
        {t(AppStrings.UserRoles.NoAccessSubtext)}
      </Text>
      <Text fontSize={20} lineHeight={"30px"} align={"center"}>
        {t(AppStrings.UserRoles.NoAccessSubtext2)}
      </Text>
    </View>
  );
};
