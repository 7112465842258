/* eslint-disable @typescript-eslint/no-use-before-define */
import compact from "lodash/compact";

import { queryClient } from "@/utils/queryClientProvider";
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiResult } from "api/apiResult";

import type { BaseDto, GetAllBaseQueryRequest, GetAllBaseQueryResponse } from "@doorloop/dto";
import type { QueryKey } from "@tanstack/react-query";
import type { InfiniteData } from "../../../../node_modules/@tanstack/query-core/src/types";
import type { RestApiBase } from "../restApiBase";
import type { PageParams, QueryStoreReturn } from "./createQueryStore.types";

export const EmptyApiResult = new ApiResult({ data: [], total: 0 });

export const DEFAULT_STALE_TIME_MS = 60 * 1000; // 1 Minute;
export const DEFAULT_INITIAL_PAGE_SIZE = 50;

export function createQueryStore<TDto extends BaseDto, TGetAllDto extends GetAllBaseQueryRequest>(
  baseKey: string,
  api: RestApiBase<TDto, TGetAllDto>
): QueryStoreReturn<TDto, TGetAllDto> {
  return {
    useGetAll: (query, options) =>
      useQuery({
        queryKey: [`${baseKey}-query-get-all`, query],
        queryFn: async ({ signal }) => {
          const response = await api.getAll(query);
          if (!response || signal?.aborted) {
            return null;
          }
          // https://tkdodo.eu/blog/effective-react-query-keys
          // https://tkdodo.eu/blog/seeding-the-query-cache#seeding-details-from-lists
          response.data?.data.forEach((item) => {
            queryClient.setQueryData([baseKey, "item", item.id], item);
          });
          return response;
        },
        ...options
      }),
    useGetAllPaginated: (query, options) => {
      const queryKey = buildPaginatedQueryKey(baseKey, query);
      const queryClient = useQueryClient();

      const initialPageSize = query?.page_size ?? DEFAULT_INITIAL_PAGE_SIZE;
      const nextPageSize = options?.nextPageSize ?? initialPageSize;

      const infiniteQuery = useInfiniteQuery<ApiResult<GetAllBaseQueryResponse<TDto>>>(queryKey, {
        staleTime: DEFAULT_STALE_TIME_MS,
        // Keep previous data to prevent flash of empty state. This is useful
        // for queries that may return overlapping data.
        keepPreviousData: true,
        ...options,
        queryFn: async ({
          signal,
          pageParam: { pageNumber, pageSize, pageOffset } = {
            pageOffset: 0,
            pageNumber: 1,
            pageSize: initialPageSize
          }
        }) => {
          const response = await api.getAll({
            ...query,
            page_offset: pageOffset,
            page_number: pageNumber,
            page_size: pageSize
          });
          if (!response || signal?.aborted) {
            return EmptyApiResult;
          }
          // https://tkdodo.eu/blog/effective-react-query-keys
          // https://tkdodo.eu/blog/seeding-the-query-cache#seeding-details-from-lists
          response.data?.data.forEach((item) => {
            queryClient.setQueryData([baseKey, "item", item.id], item);
          });
          return response;
        },
        // https://tanstack.com/query/v4/docs/framework/react/reference/useInfiniteQuery
        getNextPageParam: (lastPage, allPages) => {
          const pages = queryClient.getQueryData<InfiniteData<TDto>>(queryKey);

          // Initial fetching has no next page
          if (!lastPage.data?.total) {
            // Replicate the behavior of `react-query`'s `infiniteQuery`'s `getNextPageParam`
            return undefined;
          }

          const { total } = lastPage.data;
          const totalFetched = allPages.flatMap((page) => page.data?.data).length;

          // Are we done fetching?
          if (totalFetched >= total) {
            return undefined;
          }

          // Nope, we need to fetch more
          const currPageNum = Math.ceil(totalFetched / nextPageSize);
          const nextPageNum = currPageNum + 1;
          const lastPageParams = pages?.pageParams[pages.pageParams.length - 1] as PageParams;

          // Support variable page sizes
          const { pageOffset: lastPageOffset, pageSize: lastPageSize } = lastPageParams || {
            pageOffset: 0,
            pageSize: initialPageSize
          };

          return {
            pageNumber: nextPageNum,
            pageSize: nextPageSize,
            pageOffset: lastPageOffset + lastPageSize
          };
        }
      });

      const { data } = infiniteQuery;
      const allData = data ? compact(data.pages.flatMap((page) => page.data?.data)) : [];

      return {
        queryKey,
        query: infiniteQuery,
        totalFetched: allData.length,
        total: data?.pages[0].data?.total,
        allData
      };
    },
    useGetOne: (id, options) =>
      useQuery({
        queryKey: [`${baseKey}-query-get-one`, id],
        queryFn: async ({ signal }) => {
          if (!id) {
            return null;
          }
          const response = await api.get(id);
          if (!response || signal?.aborted) {
            return null;
          }
          return response;
        },
        initialData: () => queryClient.getQueryData<TDto>([baseKey, "item", id]),
        ...options
      })
  };
}

export function buildQueryKey(baseKey: string, queryFilter: GetAllBaseQueryRequest, paginated?: boolean): QueryKey {
  const queryKey = [`${baseKey}-query-get-all`, queryFilter];

  if (paginated) {
    queryKey[0] = `${queryKey[0]}-paginated`;
  }

  return queryKey;
}

export function buildPaginatedQueryKey(baseKey: string, queryFilter: GetAllBaseQueryRequest): QueryKey {
  return buildQueryKey(baseKey, queryFilter, true);
}
