import _ from "lodash";
import fileDownload from "js-file-download";
import type { InputWithMeta } from "client-zip";
import { downloadZip } from "client-zip";

import { SegmentEventTypes } from "@doorloop/dto";

import { analyticsService } from "services/analyticsService";

const REGEX_REPORT_URL = /[^\w\s-)(][^.pdf]/gi;

const handleBulkPrintReportFinish = (resultAggregate?: Array<{ fileName: string; data: any }>) => {
  const inputs = _.compact(
    resultAggregate?.map((result, index) => {
      const fileNameWithoutSpecialChars = result.fileName.replace(REGEX_REPORT_URL, "");

      return {
        input: result.data,
        name: `${index + 1} - ${fileNameWithoutSpecialChars}`
      } as InputWithMeta;
    })
  );

  if (inputs.length) {
    downloadZip(inputs)
      .blob()
      .then((content) => {
        fileDownload(content, `Bulk account statement.zip`);
      });
  }

  analyticsService.track(SegmentEventTypes.BULK_ACCOUNT_STATEMENT_DOWNLOAD_CLICKED, {
    count: inputs.length
  });
};
export { handleBulkPrintReportFinish };
