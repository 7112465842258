import type { FileDto, LinkedResourceType } from "@doorloop/dto";
import { FileFilterTypes, mathUtils, ServerRoutes } from "@doorloop/dto";
import { FilesApi } from "api/filesApi";
import { RefreshIcon } from "assets/icons";
import spinnerErrorAnimation from "assets/lottie/spinner-fail.json";
import spinnerAnimation from "assets/lottie/spinner.json";
import { FormikCheckBox, IconButton } from "DLUI/form";
import { Lottie } from "DLUI/lottie";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { getFileIcon } from "shared/files/fileListItem";
import type { FileListItemProps, FileRetryMethod } from "./fileListItem";
import type { FormAttachmentDropZoneBackgroundColor } from "./formAttachmentDropZone";
import FormAttachmentDropZone from "./formAttachmentDropZone";
import { DROP_ZONE_MOBILE_HEIGHT, DROP_ZONE_WEB_HEIGHT } from "DLUI/dropZone/styles";
import { DEFAULT_MAX_FILE_UPLOAD_SIZE_ALLOWED_IN_MB, fileExtensionsMap } from "DLUI/dropZone/utils";
import { useResponsiveHelper } from "../../../contexts/responsiveContext";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { useDispatch } from "react-redux";
import { handleToast } from "store/toast/actions";
import { useTranslation } from "react-i18next";
import { generalUtils } from "@doorloop/utils";

interface ComponentProps {
  title?: string;
  files: FileListItemProps[];
  setFiles?: (files) => void;
  onFileReceived: (files: FileListItemProps[]) => void;
  marginTop?: number | string;
  editMode?: boolean;
  resourceId?: string;
  resourceType?: LinkedResourceType;
  baseUrl?: string;
  backgroundColor?: FormAttachmentDropZoneBackgroundColor;
  getAttachmentsByLinkedResource?: boolean;
  allowDelete?: boolean;
  isRequired?: boolean;
  error?: string;
  maxFiles?: number;
  dataCy?: string;
  accept?: string;
  filesSharedFormikFieldName?: string;
  errorText?: string;
  maxFileSizeInMB?: number;
  maxTotalFilesSizeInMB?: number;
}

const FormAttachments: React.FC<ComponentProps> = ({
  files,
  marginTop = 10,
  editMode,
  resourceId,
  resourceType,
  onFileReceived,
  baseUrl,
  backgroundColor,
  getAttachmentsByLinkedResource = true,
  allowDelete = true,
  maxFiles = 0,
  isRequired = true,
  error,
  setFiles,
  accept = fileExtensionsMap[FileFilterTypes.GENERAL],
  dataCy,
  filesSharedFormikFieldName,
  errorText,
  maxFileSizeInMB = DEFAULT_MAX_FILE_UPLOAD_SIZE_ALLOWED_IN_MB,
  maxTotalFilesSizeInMB
}: ComponentProps) => {
  const [attachments, setAttachments] = useState<FileListItemProps[]>(files);
  const [showLoadFilesError, setShowLoadFilesError] = useState<boolean>(false);
  const [loadingInProgress, setLoadingInProgress] = useState<boolean>(false);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { isMobile } = useResponsiveHelper();

  const dropZoneHeight = isMobile ? DROP_ZONE_MOBILE_HEIGHT : DROP_ZONE_WEB_HEIGHT;
  const dropZoneLottieHeight = dropZoneHeight - 10;

  useEffect(() => {
    if (editMode) {
      loadFiles();
    }
  }, []);

  const deleteFile = async (fileIndex: number, fileId?: string) => {
    const filesArray = [...attachments];
    if (!fileId) {
      filesArray.splice(fileIndex, 1);
      setTimeout(() => {
        setAttachments(filesArray);
        onFileReceived(filesArray);
      }, 300);
      return;
    }
    let filesApi = new FilesApi(ServerRoutes.FILES);
    if (baseUrl) {
      filesApi = new FilesApi(baseUrl);
    }
    const response = await filesApi.delete(fileId);
    if (response && response.data) {
      filesArray.splice(fileIndex, 1);
      setAttachments(filesArray);
    } else {
      const filesArray = _.cloneDeep(attachments);
      filesArray[fileIndex].fileUploadState = "DeleteFailed";
      setAttachments(filesArray);
    }

    onFileReceived(filesArray);
  };

  useEffect(() => {
    attachments.forEach((element: FileListItemProps, index: number) => {
      if (element.fileUploadState === "WaitingForDelete") {
        element.fileUploadState = "DeleteInProgress";
        deleteFile(index, element.id);
      }
    });
    setAttachments(attachments);
    setFiles?.(attachments);
  }, [attachments]);

  const loadFilesByResource = async () => {
    if (loadingInProgress) {
      return;
    }
    setLoadingInProgress(true);
    if (resourceId && resourceType) {
      let filesApi: FilesApi;

      if (baseUrl) {
        filesApi = new FilesApi(baseUrl);
      } else {
        filesApi = new FilesApi(ServerRoutes.FILES);
      }

      const response = await filesApi
        .getAll({
          filter_resourceId: resourceId,
          filter_resourceType: resourceType
        })
        .catch(() => {
          setShowLoadFilesError(true);
        });
      if (response && response.data && response.data.data) {
        const filesItems: FileListItemProps[] = [];

        response.data.data.forEach((currentFile: FileDto) => {
          const fileIcon = getFileIcon(currentFile.mimeType);

          filesItems.push({
            didPressDeleteIcon: onDeleteItem,
            itemIndex: filesItems.length,
            name: currentFile.name!,
            icon: fileIcon,
            fileUploadState: "WaitingForUpload",
            didPressRetry: onRetryPress,
            id: currentFile.id
          });
        });
        setLoadingInProgress(false);
        setAttachments(filesItems);
        onFileReceived(filesItems);
      } else {
        setShowLoadFilesError(true);
      }
    }
    setLoadingInProgress(false);
  };

  const loadFilesById = async () => {
    if (loadingInProgress) return;
    setLoadingInProgress(true);
    try {
      const filesApi = new FilesApi(baseUrl || ServerRoutes.FILES);
      if (!resourceId) throw new Error();

      const response = await filesApi.get(resourceId).catch(() => {
        setShowLoadFilesError(true);
      });
      const file = response?.data;
      if (!file) throw new Error();

      const fileIcon = getFileIcon(file.mimeType);
      const fileListItems: FileListItemProps[] = [
        {
          didPressDeleteIcon: onDeleteItem,
          itemIndex: 0,
          name: file.name!,
          icon: fileIcon,
          fileUploadState: "WaitingForUpload",
          didPressRetry: onRetryPress,
          id: file.id
        }
      ];
      setAttachments(fileListItems);
      onFileReceived(fileListItems);
    } catch {
      setShowLoadFilesError(true);
    } finally {
      setLoadingInProgress(false);
    }
  };

  const loadFiles = getAttachmentsByLinkedResource ? loadFilesByResource : loadFilesById;

  const onRetryPress = (fileIndex: number, retryMethod: FileRetryMethod) => {
    if (retryMethod === "Upload") {
      const filesItems: FileListItemProps[] = [...attachments];
      filesItems[fileIndex].fileUploadState = "WaitingForUpload";
    } else {
      const nextlistFiles = _.cloneDeep(attachments);
      nextlistFiles[fileIndex].fileUploadState = "WaitingForDelete";
      setAttachments(nextlistFiles);
    }
  };

  const onDeleteItem = (fileIndex: number) => {
    const nextlistFiles = _.cloneDeep(attachments);
    nextlistFiles[fileIndex].fileUploadState = "WaitingForDelete";
    setAttachments(nextlistFiles);
  };

  const maxFileSize = generalUtils.convertToBytes(maxFileSizeInMB, "mb");

  const _onFileReceived = (receiveFiles: File[]): void => {
    if (maxTotalFilesSizeInMB) {
      const maxTotalFilesSize = generalUtils.convertToBytes(maxTotalFilesSizeInMB, "mb");

      const totalFilesSize = mathUtils.sumBy(receiveFiles, (file) => file.size);

      if (totalFilesSize > maxTotalFilesSize) {
        dispatch(
          handleToast({
            severity: "error",
            translationKey: t(AppStrings.Files.TotalFilesSizeTooLargeError, {
              size: maxTotalFilesSizeInMB
            })
          })
        );

        return;
      }
    }

    const filesItems: FileListItemProps[] = [...attachments];

    receiveFiles.forEach((currentFile: File) => {
      const fileIcon = getFileIcon(currentFile.type);

      let okToUpload = true;
      let fileUploadErrorMessage: string | undefined = undefined;
      if (currentFile.size > maxFileSize) {
        okToUpload = false;
        fileUploadErrorMessage = t(AppStrings.Files.FileSizeTooLargeError, {
          size: maxFileSizeInMB
        });
      }
      filesItems.push({
        didPressDeleteIcon: onDeleteItem,
        itemIndex: filesItems.length,
        name: currentFile.name,
        icon: fileIcon,
        fileUploadState: okToUpload ? "WaitingForUpload" : "UploadFailed",
        fileUploadErrorMessage,
        fileData: currentFile,
        didPressRetry: onRetryPress
      });
    });
    setAttachments(filesItems);
    onFileReceived(filesItems);
  };

  if (loadingInProgress) {
    return (
      <View
        height={60}
        border={"1px dashed #ADB3CE"}
        marginTop={marginTop || 10}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <View
          borderRadius={10}
          backgroundColor={"dark"}
          height={"80%"}
          flexDirection={"row"}
          width={"auto"}
          alignItems={"center"}
        >
          <View height={"100%"} width={60} justifyContent={"center"} alignItems={"center"}>
            <Lottie loop animationData={spinnerAnimation} width={30} height={30} />
          </View>
          <SeparationLine marginRight={10} width={1} height={"80%"} />
          <Text
            value={AppStrings.Common.Dropzone.LoadingFile}
            color={"gray"}
            fontSize={14}
            marginLeft={10}
            marginRight={20}
          />
        </View>
      </View>
    );
  }

  if (showLoadFilesError) {
    return (
      <View
        height={maxFiles === 0 ? "auto" : 60}
        border={"1px dashed #ADB3CE"}
        marginTop={marginTop || 10}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"row"}
        noWrap
      >
        <View
          borderRadius={10}
          backgroundColor={"dark"}
          height={"80%"}
          flexDirection={"row"}
          width={"auto"}
          alignItems={"center"}
        >
          <IconButton width={15} height={15} onClick={loadFiles} Icon={RefreshIcon} marginRight={5} marginLeft={5} />
          <SeparationLine marginRight={10} width={1} height={"80%"} />
          <Lottie loop={false} animationData={spinnerErrorAnimation} width={30} height={30} />

          <Text
            value={AppStrings.Common.Dropzone.FailedToLoadFiles}
            color={"gray"}
            fontSize={14}
            marginLeft={10}
            marginRight={10}
          />
        </View>
      </View>
    );
  }

  return (
    <View marginTop={marginTop} flexDirection={"column"} noWrap>
      <FormAttachmentDropZone
        onFileReceive={_onFileReceived}
        files={attachments}
        onDeleteItem={onDeleteItem}
        maxFiles={maxFiles}
        onRetryPress={onRetryPress}
        height={dropZoneHeight}
        isRequired={isRequired}
        dataCy={dataCy}
        allowDelete={allowDelete}
        lottieHeight={dropZoneLottieHeight}
        instructionsViewDirection={"row"}
        backgroundColor={backgroundColor || "dark"}
        displayType={"small"}
        baseUrl={baseUrl}
        error={error}
        accept={accept}
        maxFileSizeInMB={maxFileSizeInMB}
      />
      {Boolean(files.length) && Boolean(filesSharedFormikFieldName?.length) && (
        <View style={{ transform: "translateX(-12px)" }}>
          <FastFieldSafe
            component={FormikCheckBox}
            name={filesSharedFormikFieldName ?? ""}
            labelText={AppStrings.Common.Dropzone.SharedAllFilesWithTenants}
          />
        </View>
      )}
      {errorText && <Text color="error" fontSize={14} value={errorText} marginTop={8} />}
    </View>
  );
};

export default FormAttachments;
