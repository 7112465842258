import Routes from "./routes";
import { PageRouter } from "./pageRouter";
import { LazyRoute } from "./lazyRoute";
import { lazy } from "react";

const ProspectsListScreen = lazy(() => import("screens/prospects/prospectsList/prospectsListScreen"));
const ProspectDetails = lazy(() => import("screens/prospects/prospectDetails/prospectDetails"));

const ProspectsRouter = () => (
  <PageRouter>
    <LazyRoute path={Routes.PROSPECTS_GLOBAL_NEW} render={ProspectsListScreen} />
    <LazyRoute path={Routes.PROSPECT_DETAILS_GLOBAL_NEW} render={ProspectDetails} />
    <LazyRoute exact path={Routes.EDIT_PROSPECT} render={ProspectsListScreen} />

    <LazyRoute exact path={Routes.PROSPECT_DETAILS_EDIT_RENTAL_APPLICATION} render={ProspectDetails} />
    <LazyRoute
      exact
      path={Routes.PROSPECT_DETAILS_UPDATE_RENTAL_APPLICATION_DECISION_STATUS}
      render={ProspectDetails}
    />
    <LazyRoute path={Routes.PROSPECT_DETAILS} render={ProspectDetails} />
    <LazyRoute path={Routes.PROSPECTS} render={ProspectsListScreen} />
  </PageRouter>
);

export default ProspectsRouter;
