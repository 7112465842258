"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAllPropertiesQuery = void 0;
const class_validator_1 = require("class-validator");
const getAllBaseRequest_query_1 = require("../getAllBaseRequest.query");
const types_1 = require("./types");
class GetAllPropertiesQuery extends getAllBaseRequest_query_1.GetAllBaseQueryRequest {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.GetAllPropertiesQuery = GetAllPropertiesQuery;
__decorate([
    (0, class_validator_1.IsEnum)(types_1.PropertyClass),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], GetAllPropertiesQuery.prototype, "filter_class", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(types_1.PropertyType),
    __metadata("design:type", String)
], GetAllPropertiesQuery.prototype, "filter_type", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Object)
], GetAllPropertiesQuery.prototype, "filter_active", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllPropertiesQuery.prototype, "filter_group", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], GetAllPropertiesQuery.prototype, "filter_groups", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllPropertiesQuery.prototype, "filter_owner", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllPropertiesQuery.prototype, "filter_tenant", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], GetAllPropertiesQuery.prototype, "filter_owners", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    __metadata("design:type", Array)
], GetAllPropertiesQuery.prototype, "filter_properties", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllPropertiesQuery.prototype, "filter_collectManagementFees", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], GetAllPropertiesQuery.prototype, "filter_batch", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsMongoId)(),
    __metadata("design:type", String)
], GetAllPropertiesQuery.prototype, "filter_account", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsEnum)(types_1.PropertyDefaultAccountType, { each: true }),
    __metadata("design:type", Array)
], GetAllPropertiesQuery.prototype, "filter_account_types", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], GetAllPropertiesQuery.prototype, "filter_transunion_property_id", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsString)({ each: true }),
    __metadata("design:type", Array)
], GetAllPropertiesQuery.prototype, "filter_multipleRecordsByText", void 0);
