import { makeStyles } from "@material-ui/core/styles";

export const loginBackgroundImage = "url(/images/login_background_c.jpg)";

export default makeStyles((theme) => {
  return {
    backgroundImage: {
      backgroundImage: loginBackgroundImage,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center"
    },
    signup_backgroundImage: {
      backgroundImage: "url(/images/signup_background.jpg)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      backgroundAttachment: "fixed",
      backgroundColor: theme.palette.blue.main
    },
    demoSignupLayout: {
      transition: "all 0.3s"
    }
  };
});
