import type { FunctionComponent } from "react";
import React, { useMemo } from "react";

import Text from "DLUI/text";
import AppStrings from "../../../../../locale/keys";
import { NotificationSettingsView } from "DLUI/dialogs/notifications/components/notificationSettingsView";
import type { UserNotificationSettingsDto } from "@doorloop/dto";
import { LoginResponseType } from "@doorloop/dto";
import type { SVGIconComponent } from "../../../../../assets";
import {
  NotificationSettingsBell,
  NotificationSettingsEmail,
  NotificationSettingsSmartPhone
} from "../../../../../assets";
import invert from "lodash/invert";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";
import { useFormikContext } from "formik";
import { useUserType } from "hooks/useUserType";
import { TenantPortalNotificationSettingsPrefNames } from "../types";
import { hiddenSettingsForHOA } from "../constants";
import { NotificationsSettingsMobileView } from "./notificationsSettingsMobileView";

export enum ColumnGridSizeEnum {
  COLUMN_KEY = 6,
  COLUMN_KEY_XL = 8,
  COLUMN_VALUE = 2
}

export interface NotificationSettingsRowInterface {
  Component: FunctionComponent;
  sm: ColumnGridSizeEnum;
  type?: "text" | "email" | "push";
  icon?: SVGIconComponent;
}

export const NotificationSettingsContextWrapper = ({ userType }: { userType: LoginResponseType }) => {
  const { isMobile } = useResponsiveHelper();
  const { values } = useFormikContext<UserNotificationSettingsDto>();
  const { isHOAUser } = useUserType();

  const allowedSettings = useMemo(() => {
    const valuesToCheck = Object.keys(
      userType === LoginResponseType.TENANT ? invert(TenantPortalNotificationSettingsPrefNames) : values
    ) as Array<keyof UserNotificationSettingsDto>;

    if (values && !isHOAUser) {
      return valuesToCheck;
    }

    return valuesToCheck.filter((key) => !hiddenSettingsForHOA.includes(key));
  }, [userType, values, isHOAUser]);

  const headerRows: NotificationSettingsRowInterface[] = useMemo(() => {
    const NotificationSettingsRowInterfaceArray: NotificationSettingsRowInterface[] = [
      {
        Component: () => <Text fontSize={16} color={"black"} value={AppStrings.Notifications.Settings.Tasks} bold />,
        sm: userType === LoginResponseType.USER ? ColumnGridSizeEnum.COLUMN_KEY_XL : ColumnGridSizeEnum.COLUMN_KEY
      },
      {
        Component: () => <Text fontSize={16} color={"gray"} value={AppStrings.Notifications.Settings.Text.Title} />,
        sm: ColumnGridSizeEnum.COLUMN_VALUE,
        type: "text",
        icon: NotificationSettingsSmartPhone
      },
      {
        Component: () => <Text fontSize={16} color={"gray"} value={AppStrings.Notifications.Settings.Push.Title} />,
        sm: ColumnGridSizeEnum.COLUMN_VALUE,
        type: "push",
        icon: NotificationSettingsBell
      },
      {
        Component: () => <Text fontSize={16} color={"gray"} value={AppStrings.Notifications.Settings.Email.Title} />,
        sm: ColumnGridSizeEnum.COLUMN_VALUE,
        type: "email",
        icon: NotificationSettingsEmail
      }
    ];

    return userType === LoginResponseType.USER
      ? NotificationSettingsRowInterfaceArray.filter((item) => item.type !== "text")
      : NotificationSettingsRowInterfaceArray;
  }, [userType]);

  return (
    <>
      {!isMobile ? (
        <NotificationSettingsView
          userType={userType}
          headerRows={headerRows}
          values={values}
          allowedSettings={allowedSettings}
        />
      ) : (
        <NotificationsSettingsMobileView userType={userType} values={values} allowedSettings={allowedSettings} />
      )}
    </>
  );
};
