import type { Action } from "redux";
import type {
  AuthResponseDto,
  Features,
  LoginThemeDto,
  OnBoardingDto,
  PersonalInfoSettingsDto,
  SubscriptionLoginResponseDto
} from "@doorloop/dto";
import * as Types from "./types";

export interface LocaleData {
  thousandSeparator: string;
  decimalSeparator: string;
  currencySymbol: string;
  percentageSymbol: string;
  currency?: string;
  locale: string;
  dateFormat: string;
  currencySymbolPosition: "prefix" | "suffix";
  percentSymbolPosition: "prefix" | "suffix";
}

// Action Definition
export interface ILogoutAction extends Action {
  type: typeof Types.LOGOUT;
}

export interface ILoginSuccessAction extends Action {
  type: typeof Types.LOGIN_SUCCESS;
  authResponse: AuthResponseDto;
}

export interface ILoginUpdateLocale extends Action {
  type: typeof Types.UPDATE_LOCALE_DATA;
  localeData: LocaleData;
}

export interface IUpdateSubscriptionData extends Action {
  type: typeof Types.UPDATE_SUBSCRIPTION_DATA;
  subscription: SubscriptionLoginResponseDto;
}

export interface UpdateUserInfo extends Action {
  type: typeof Types.UPDATE_USER_INFORMATION;
  personalInfoSettings: PersonalInfoSettingsDto;
}

export interface IUpdateDBTenantCompanyStartDate extends Action {
  type: typeof Types.UPDATE_COMPANY_START_DATE;
  startDate: string;
}

export interface UpdateLoginTheme extends Action {
  type: typeof Types.UPDATE_LOGIN_THEME;
  theme: LoginThemeDto;
}

export interface IUpdateOnBoardingProgress extends Action {
  type: typeof Types.UPDATE_ONBOARDING_PROGRESS;
  onBoardingDto: OnBoardingDto;
}

export interface IUpdateShowOnBoardingOnLogin extends Action {
  type: typeof Types.UPDATE_SHOW_ONBOARDING_ON_LOGIN;
  showOnBoardingOnLogin: boolean;
}

export interface IUpdateTotalUnreadNotification extends Action {
  type: typeof Types.UPDATE_TOTAL_UNREAD_NOTIFICATIONS;
  totalUnread?: number;
}

export interface IUpdateIsFeatureEnabled extends Action {
  type: typeof Types.UPDATE_IS_FEATURE_ENABLED;
  isEnabled: boolean;
  featureName: Features;
}

// Union Action Types
export type AuthAction =
  | ILoginSuccessAction
  | ILogoutAction
  | ILoginUpdateLocale
  | UpdateUserInfo
  | UpdateLoginTheme
  | IUpdateDBTenantCompanyStartDate
  | IUpdateSubscriptionData
  | IUpdateOnBoardingProgress
  | IUpdateTotalUnreadNotification
  | IUpdateShowOnBoardingOnLogin
  | IUpdateIsFeatureEnabled;
// Action Creators
export const loginSuccess = (authResponse: AuthResponseDto): ILoginSuccessAction => {
  return { type: Types.LOGIN_SUCCESS, authResponse };
};

export const logout = () => {
  return { type: Types.LOGOUT };
};

export const updateSubscriptionData = (subscription: SubscriptionLoginResponseDto) => {
  return { type: Types.UPDATE_SUBSCRIPTION_DATA, subscription };
};

export const updateLocaleData = (localeData: LocaleData) => {
  return { type: Types.UPDATE_LOCALE_DATA, localeData };
};

export const updateLoginTheme = (theme: LoginThemeDto) => {
  return { type: Types.UPDATE_LOGIN_THEME, theme };
};

export const updateUserInformation = (personalInfoSettings: PersonalInfoSettingsDto) => {
  return { type: Types.UPDATE_USER_INFORMATION, personalInfoSettings };
};

export const updateCompanyStartDate = (startDate: string): IUpdateDBTenantCompanyStartDate => {
  return { type: Types.UPDATE_COMPANY_START_DATE, startDate };
};

export const updateOnBoardingDto = (onBoardingDto: OnBoardingDto): IUpdateOnBoardingProgress => {
  return { type: Types.UPDATE_ONBOARDING_PROGRESS, onBoardingDto };
};

export const updateShowOnBoardingOnLogin = (showOnBoardingOnLogin: boolean) => {
  return { type: Types.UPDATE_SHOW_ONBOARDING_ON_LOGIN, showOnBoardingOnLogin };
};

export const updateTotalUnreadNotification = (totalUnread?: number) => {
  return { type: Types.UPDATE_TOTAL_UNREAD_NOTIFICATIONS, totalUnread };
};

export const updateIsFeatureEnabled = (isEnabled: boolean, featureName: Features): IUpdateIsFeatureEnabled => {
  return { type: Types.UPDATE_IS_FEATURE_ENABLED, isEnabled, featureName };
};
