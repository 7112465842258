export default {
  reportsScreen: {
    favorites: "Favorites",
    preparingYourReportFullText: "Preparing file…We will notify you once it's ready",
    reportIsReadyToDownload: "Your report is ready to download",
    reportIsReadyToDownloadDescription: "Click the download button below to get your report",
    downloadReport: "Download report",
    reportFiltersAndDataNotInSync:
      "Your current filter selection is not reflected in the report below. Rerun the report to show updated results.",
    chooseReportSettings: "Choose Report Settings",
    runReportSubTitle: "Run Report to generate data for this report",
    reportSettingsMissing: "Report settings missing",
    reportSettingsMissingDescription: "Select properties or owners for which the report will be generated.",
    reportSettingsMissingDescriptionOwnerPortal: "Select properties for which the report will be generated.",
    preparingYourReport: "Your report is being generated",
    preparingYourReportForPrint: "A report is being generated for you to print",
    preparingYourReportDescription:
      "Keep this tab open to check for updates. Meanwhile, if you wish to continue working in Doorloop on other tasks, click the link below to open a new tab.",
    preparingYourReportForPrintDescription:
      "Keep this tab open to check for updates. When ready, simply download the file to your computer to open and print.",
    loadingReports: "Loading Reports",
    moreReportsAvailableViaPrint: "Please use the print button to see all generated reports.",
    loadingReportsPrint: "Preparing reports for printing",
    emptyReportDataMessage:
      "This report does not contain any relevant data based on your filter selection.\n Please check your filters to generate a report for this owner.",
    searchForReports: "Search for a report (Ctrl+F)",
    noReportsFound: "No reports found for this search",
    propertyBankAccounts: "Property Bank Accounts",
    businessOverview: "Business Overview",
    leaseLateFeesReport: "Lease Late Fees Report",
    leaseAccountStatementsReport: "Lease Account Statements Report",
    profitAndLoss: "Profit and Loss",
    profitAndLossDetails: "Profit and Loss Details",
    balanceSheetDetails: "Balance Sheet Details",
    profitAndLossByProperty: "Profit and Loss by Property",
    profitAndLossByMonth: "Profit and Loss by Month",
    profitAndLossByYear: "Profit and Loss by Year",
    profitAndLossByQuarter: "Profit and Loss by Quarter",
    owners1099: "1099-MISC",
    vendors1099: "1099-NEC",
    balanceSheet: "Balance Sheet",
    balanceSheetByProperty: "Balance Sheet by Property",
    balanceSheetByMonth: "Balance Sheet by Month",
    balanceSheetByYear: "Balance Sheet by Year",
    balanceSheetByQuarter: "Balance Sheet by Quarter",
    cashFlowStatement: "Cash Flow Statement",
    cashFlowStatementByMonth: "Cash Flow Statement by Month",
    ownerStatement: "Owner Statement",
    reportSettings: "Report Settings",
    showAdvancedSettings: "Show Advanced Settings",
    hideAdvancedSettings: "Hide Advanced Settings",
    runReport: "Run Report",
    runReportBy: "Run report by",
    showNonZeroRows: "Show Non Zero Rows",
    propertyReserveFunds: "Property Reserve Funds",
    managementFeesReport: "Management Fees Settings Report",
    ownerStatementDetailed: "Owner Statement Detailed",
    ownerStatementSummary: "Owner Statement Summary",
    statementSummary: "Statement Summary",
    transactionDetails: "Transaction Details",
    ownerStatementExportFileName: "Report-Owner-Statement",
    cashFlowStatementByProperty: "Cash Flow Statement by Property",
    currentTenants: "Current Tenants",
    previousTenants: "Previous Tenants",
    futureTenants: "Future Tenants",
    beginningBalance: "Beginning Balance",
    tenantStatement: "Tenant Statement",
    draftLeasesbyStatus: "Draft Leases by Status",
    draftLeasesbyProperty: "Draft Leases by Property",
    cancelledDraftLeases: "Canceled Draft Leases",
    activatedDraftLeases: "Activated Draft Leases",
    renewalStatusbyProperty: "Renewal Status by Property",
    leaseInsuranceSummaryReport: "Insurance Summary Report",
    leaseInsuranceDetailsReport: "Insurance Details Report",
    policyNumber: "Policy Number",
    status: "Status",
    notRequired: "Not Required",
    required: "Required",
    provided: "Provided",
    providedDate: "Provided Date",
    expirationDate: "Expiration Date",
    effectiveDate: "Effective Date",
    upcomingMoveOuts: "Upcoming Move-Outs",
    upcomingMoveIns: "Upcoming Move-Ins",
    rentRoll: "Rent Roll",
    rentRollSummaryByBedsAndBaths: "Rent Roll Summary by Beds/Baths",
    rentRollSummaryByProperty: "Rent Roll Summary by Property",
    debit: "Debit",
    credit: "Credit",
    transactionsReportFileName: "Report-Transactions",
    depositDetailReportFileName: "Deposit-Detail",
    billsAndExpensesReportFileName: "Report-Bill-And-Expenses",
    chargesAndPaymentReportFileName: "Report-Charges-And-Payment",
    rentRollFileName: "Report-Rent-Roll",
    applicationsReportFileName: "Report-Applications",
    prospectsFileName: "Report-Prospects",
    leaseInsuranceSummaryFileName: "Report-Insurance-Summary",
    leaseInsuranceDetailsFileName: "Report-Insurance-Details",
    tenantsReport: "Tenants Report",
    tenantVehicles: "Tenant Vehicles Report",
    tenantPets: "Tenant Pets Report",
    leasingReport: "Leasing Report",
    prospectsReport: "Prospects Report",
    activeProspects: "Active Prospects",
    movedInProspects: "Moved-In Prospects",
    lostProspects: "Lost Prospects",
    rentalApplicationsReport: "Rental Applications Report",
    prospectsbySource: "Prospects by Source",
    prospectsbyLeasingAgent: "Prospects by Leasing Agent",
    leasingReportFileName: "Report-Leasing",
    leaseLateFeesReportFileName: "Report-Lease-Late-Fees",
    vendorsReportFileName: "Report-Vendors",
    vendorsDirectory: "Vendors Directory",
    vendorsByProperty: "Vendors by Property",
    vendorByService: "Vendor by Service",
    vendorsByInsuranceStatus: "Vendors by Insurance Status",
    allWorkOrdersByVendor: "All Work Orders by Vendor",
    openWorkOrdersByVendor: "Open Work Orders by Vendor",
    vendorLedger: "Vendor Ledger",
    transactionsByVendor: "Transactions by Vendor",
    selectVendorInstructions: "Select vendor to view the report",
    workOrdersReport: "Work orders report",
    workOrdersReportFileName: "Report-Work-Orders",
    tasksReport: "Tasks Report",
    tasksReportFileName: "Report-Tasks",
    completedTasks: "Completed Tasks",
    openTasks: "Open Tasks",
    overdueTasks: "Overdue Tasks",
    tasksByProperty: "Tasks by Property",
    tasksByStatus: "Tasks by Status",
    tasksByPriority: "Tasks by Priority",
    trialBalance: "Trial Balance",
    generalLedger: "General Ledger",
    transactionsDetails: "Transaction Details",
    ownerStatementNReports: "Owner Statement ({{nReports}} Reports Presented)",
    ownerStatementNReportsOutOfNReports: "Owner Statement ({{nReports}}/{{oReports}} Reports Created)",
    depositDetail: "Deposit Detail",
    runBudgetVsActual: "Budget vs Actual",
    transactionsList: "Transactions List",
    transactionListRecent: "Transaction List Recent",
    transactionsListByDate: "Transactions List by Date",
    transactionListByType: "Transactions List by Type",
    depositTransactions: "Deposit Transactions",
    checkTransactions: "Check Transactions",
    expenseTransactions: "Expense Transactions",
    refundTransactions: "Refund Transactions",
    bankbalanceByProperty: "Bank Balance by Property",
    BankbalanceByPropertyFileName: "bank-balance-by-property",
    bankbalanceByPropertyReport: "Bank Balance by Property",
    trialBalanceFileName: "report-trial-balance",
    transactionsListReport: "Transactions List Report",
    ownersDirectory: "Owners Directory",
    ownersReport: "Owners Report",
    ownersByProperty: "Owners by Property",
    propertyOwnership: "Property Ownership",
    ownersReportFileName: "Report-Owners",
    whoOwesYou: "Who Owes You",
    accountsReceivableAgingDetail: "A/R Aging Detail",
    accountsReceivableAgingDetailByAccount: "A/R Aging Detail by Account",
    accountsReceivableAgingSummary: "A/R Aging Summary",
    accountsPayableAgingDetail: "A/P Aging Detail",
    accountsPayableAgingDetailByAccount: "A/P Aging Detail by Account",
    accountsPayableAgingSummary: "A/P Aging Summary",
    agingDetailFileName: "Report-Aging-Detail",
    agingDetailReportFileName: "Report-Aging-Details",
    agingSummaryFileName: "Report-Aging-Summary",
    agingPeriod: "Aging Period",
    asOfDate: "As of date",
    minDaysPastDue: "Min. Days Past Due",
    agingPeriodDays: "Days per aging period",
    numberOfAgingPeriods: "Number of periods",
    agingSummaryReportFileName: "Report-Aging-Summary",
    recurringTransactions: "Recurring Transactions",
    recurringTransactionsDetail: "Recurring Transactions Detail",
    recurringRentTransactions: "Recurring Rent Transactions",
    recurringTransactionsReport: "Recurring Transactions",
    recurringPayments: "Recurring Payments (Auto-Pay)",
    recurringPaymentsReport: "Recurring Payments Report",
    recurringPaymentsReportFileName: "Report-Recurring-Payments",
    recurringCharges: "Recurring Charges",
    recurringChargesReport: "Recurring Charges Report",
    recurringChargesReportFileName: "Report-Recurring-Charges",
    recurringCredits: "Recurring Credits",
    recurringCreditsReport: "Recurring Credits Report",
    recurringCreditsReportFileName: "Report-Recurring-Credits",
    recurringTransactionsByType: "Recurring Transactions by Type",
    recurringTransactionsSummary: "Recurring Transactions Summary",
    whatYouOwe: "What You Owe",
    efileWithTrack1099: "EFile with Track1099.com",
    billsAndExpenses: "Bills & Expenses",
    sendOwnerStatementReport: "Send Owner Statement Report",
    ownerStatementConfirmation: "Are you sure you want to email this report to the {{ownersCount}} listed owner(s)?",
    ownerStatementMissingEmailsConfirmation:
      "The following owners do not have email addresses and will not receive the report. To ensure delivery,\nplease update their email addresses",
    ownerStatementSentSuccessfully: "Owner Statement Sent Successfully",
    chargesAndPayment: "Charges & Payment",
    credits: "Credits",
    show: "Show",
    hide: "Hide",

    billPaymentsList: "Bill Payments List",
    unpaidBills: "Unpaid Bills",
    accountsReceivable: "Accounts Receivable",
    accountsPayable: "Accounts Payable",

    undepositedFunds: "Undeposited Funds",
    ePayDepositsReport: "ePay Transactions",
    addToFavorites: "Add to Favorites",
    removeFromFavorites: "Remove",
    viewReport: "View Report",
    filterWarning:
      "Note: When filtering reports by unit you are not presenting transactions that are not associated with a specific unit."
  },
  profitAndLoss: {
    showRows: "Show Rows",
    allRows: "All Rows",
    nonZero: "Non Zero",
    rowType: "Row Type",

    INCOME: "Income",
    GROSS_PROFIT: "GROSS PROFIT",
    EXPENSES: "Expenses",
    NET_OPERATING_INCOME: "NET OPERATING INCOME",
    NET_INCOME: "NET INCOME",
    COST_OF_GOODS_SOLD: "Cost of Goods Sold",
    OTHER_INCOME: "Other Income",
    OTHER_EXPENSES: "Other Expenses",
    NET_OTHER_INCOME: "NET OTHER INCOME"
  },
  taxForms1099: {
    showRows: "Show Rows",
    nonZero: "Non Zero",
    allRows: "All Rows",
    aboveMinimum: "Payments >= $600",
    rowType: "Row Type",
    sendForms: "Send Forms",
    sendFormsYes: "Yes",
    sendFormsNo: "No",
    dialogTitle: "The easiest way to e-file 1099 forms",
    dialogSubtitle1: "Export your 1099 forms from DoorLoop into Track1099",
    dialogSubtitle2: "Easily E-file forms with the IRS",
    dialogSubtitle3: "Secure e-delivery to recipients",
    infoLink: "Learn more about Track1099",
    createAccountInstruction: "Create your free Track1099 account",
    createAccountLink: "Create Account",
    exportInstruction: "Download the CSV file and upload it to your Track1099 account",
    exportLink: "Download CSV"
  },
  vendors1099: {
    showRows: "Show Rows",
    nonZero: "Non Zero",
    allRows: "All Rows",
    aboveMinimum: "Payments >= $600",
    rowType: "Row Type",
    sendForms: "Send Forms",
    sendFormsYes: "Yes",
    sendFormsNo: "No"
  },
  balanceSheet: {
    ASSETS: "ASSETS",
    CURRENT_ASSETS: "Current Assets",
    ASSET_ACCOUNTS_RECEIVABLE: "Assets Accounts Receivable",
    ASSET_BANK: "Asset Bank",
    ASSET_OTHER_CURRENT_ASSETS: "Asset Other Current Assets",
    ASSET_FIXED_ASSETS: "Asset Fixed Assets",
    ASSET_OTHER_ASSETS: "Asset Other Assets",
    LIABILITIES_AND_EQUITY: "LIABILITIES AND EQUITY",
    LIABILITIES: "Liabilities",
    CURRENT_LIABILITIES: "Current Liabilities",
    LIABILITY_ACCOUNTS_PAYABLE: "Liability Accounts Payable",
    LIABILITY_OTHER_CURRENT_LIABILIY: "Liability - Other Current Liability",
    LIABILITY_LONG_TERM_LIABILITY: "Liability - Long Term Liability",
    EQUITY: "Equity",
    EQUITY_RETAINED_EARNINGS: "Equity Retained Earnings",
    EQUITY_NET_INCOME: "Equity Net Income"
  },
  cashFlowStatement: {
    OPERATING_ACTIVITIES: "OPERATING ACTIVITIES",
    NET_INCOME: "Net Income",
    ADJUSTMENTS_TO_RECONCILE_NET_INCOME_TO_NET_CASH_PROVIDED_BY_OPERATIONS:
      "Adjustments to reconcile Net Income to Net Cash", // provided by operations",
    INVESTING_ACTIVITIES: "INVESTING ACTIVITIES",
    FINANCING_ACTIVITIES: "FINANCING ACTIVITIES",
    NET_CASH_INCREASE_FOR_PERIOD: "NET CASH INCREASE FOR PERIOD",
    CASH_AT_END_OF_PERIOD: "CASH AT END OF PERIOD",
    CASH_AT_BEGNINNING_OF_PERIOD: "CASH AT BEGINNING OF PERIOD",
    SECURITY_DEPOSITS_HELD: "Current Liabilities",
    PROPERTY_RESERVES: "Property Reserves",
    CASH_AVAILABLE_TO_WITHDRAW: "CASH AVAILABLE",
    INCOME: "Income",
    EXPENSES: "Expenses",
    OTHER_INCOME: "Other Income",
    OTHER_EXPENSES: "Other Expenses",
    UNDEPOSITED_FUNDS: "Undeposited Funds"
  },
  budgetVsActualReport: {
    accountClasses: "Account Classes"
  },
  transactionsReport: {
    transactionReportGroupBy: {
      account: "Account",
      type: "Type",
      vendor: "Vendor",
      property: "Property",
      unit: "Unit",
      tenant: "Tenant",
      lease: "Lease",
      owner: "Owner",
      day: "Day",
      week: "Week",
      month: "Month",
      quarter: "Quarter",
      year: "Year"
    },
    accountingMethod: {
      accountingMethod: "Accounting Method",
      both: "Both",
      cash: "Cash",
      accrual: "Accrual",
      basis: "basis"
    },
    transactionsReport: "Transaction Details Report",
    reportPeriod: "Report Period",
    groupBy: "Group by",
    totalFor: "Total",
    emptyDataText: "This report does not contain any data.",
    runningBalance: "Running Balance",
    beginningBalance: "Beginning Balance"
  },
  accountRegister: {
    accountRegister: "Account Register",
    selectAccountInstructions: "Select account to view the report",
    accountRegisterReport: "Account Register Report",
    accountRegisterReportFileName: "Account-Register-Report",
    viewRegister: "View Register"
  },
  rentRoll: {
    summaryByBedAndBath: "Summary by bed/bath",
    totalsAndAverages: "Totals and averages",
    noOfUnits: "No. of Units",
    occupancy: "Occupancy",
    vacant: "Vacant",
    occupied: "Occupied",
    occupiedPercent: "% Occupied",
    unitSize: "Unit Size",
    SummaryByProperty: "Summary by property",
    avgPerSize: "Avg. Per Size"
  },
  tenantPets: {},
  depositDetail: {
    depositAccount: "Deposit Account"
  },
  treeList: {
    accounts: {
      INCOME: "Income",
      GROSS_PROFIT: "Gross Profit",
      EXPENSES: "Expenses",
      NET_OPERATING_INCOME: "Net Operating Income",
      NET_INCOME: "Net Income",
      COST_OF_GOODS_SOLD: "Cost of Goods Sold",
      OTHER_INCOME: "Other Income",
      OTHER_EXPENSES: "Other Expenses",
      NET_OTHER_INCOME: "Net Other Income",
      ASSETS: "Assets",
      CURRENT_ASSETS: "Current Assets",
      ASSET_ACCOUNTS_RECEIVABLE: "Assets Accounts Receivable",
      ASSET_BANK: "Asset Bank",
      ASSET_OTHER_CURRENT_ASSETS: "Asset Other Current Assets",
      ASSET_FIXED_ASSETS: "Asset Fixed Assets",
      ASSET_OTHER_ASSETS: "Asset Other Assets",
      LIABILITIES_AND_EQUITY: "Liabilities and Equity",
      LIABILITIES: "Liabilities",
      CURRENT_LIABILITIES: "Current Liabilities",
      LIABILITY_ACCOUNTS_PAYABLE: "Liability Accounts Payable",
      LIABILITY_OTHER_CURRENT_LIABILIY: "Liability - Other Current Liability",
      LIABILITY_LONG_TERM_LIABILITY: "Liability - Long Term Liability",
      EQUITY: "Equity",
      EQUITY_RETAINED_EARNINGS: "Equity Retained Earnings",
      EQUITY_NET_INCOME: "Equity Net Income",
      OPERATING_ACTIVITIES: "Operating Activities",
      ADJUSTMENTS_TO_RECONCILE_NET_INCOME_TO_NET_CASH_PROVIDED_BY_OPERATIONS:
        "Adjustments to reconcile Net Income to Net Cash", // provided by operations",
      INVESTING_ACTIVITIES: "Investing Activities",
      FINANCING_ACTIVITIES: "Financing Activities",
      NET_CASH_INCREASE_FOR_PERIOD: "Net Cash Increase for Period",
      CASH_AT_END_OF_PERIOD: "Cash at End of Period",
      CASH_AT_BEGNINNING_OF_PERIOD: "Cash at Beginning of Period",
      SECURITY_DEPOSITS_HELD: "Current Liabilities",
      PROPERTY_RESERVES: "Property Reserves",
      CASH_AVAILABLE_TO_WITHDRAW: "Cash Available",
      UNDEPOSITED_FUNDS: "Undeposited Funds"
    },
    xSelected: "{{ x }} Selected",
    bulkActions: {
      clearData: "Clear Data",
      fillCustomTotalAmount: "Fill Totals",
      fillWithReferenceData: "Fill with Reference Data"
    },
    clearDataConfirmationTitle: "Are you sure you want to delete the row's data?",
    overwriteConfirmationTitle: "Overwrite existing data?",
    overwriteConfirmationSubmit: "Overwrite",
    fillTotalInputLabel: "Total amount",
    fillTotalInputTitle: "Enter total amount",
    fillTotalSubmitButton: "Fill Totals",
    tooltipInlineActionCopy: "Click to copy the value across the row.",
    tooltipInlineActionDistribute: "Click to distribute the value across the row.",
    headers: {
      account: "Account",
      property: "Property",
      tenant: "Tenant",
      vendor: "Vendor",
      owner: "Owner",
      lease: "Lease",
      unit: "Unit",
      journalEntryType: "Type",
      paymentMethod: "Payment Method",
      reference: "Reference",
      memo: "Description",
      date: "Date",
      amount: "Amount",
      total: "Total",
      runningBalance: "Running Balance",
      debit: "Debit",
      credit: "Credit",
      name: "Name"
    }
  }
};
