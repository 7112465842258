import { ShareApplicationIcon } from "assets/icons";
import { Routes } from "components/appRouter";
import CopyToClipboardButton from "DLUI/button/copyToClipboardButton";
import { Icon } from "DLUI/icon";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import type { FC } from "react";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import type { IRootState } from "store/index";
import { useResponsiveHelper } from "../../../../../../contexts/responsiveContext";
import { rentalApplicationEventSource } from "../../../../../../hooks/useRentalApplicationEvents";

interface ComponentProps {
  shouldShow?: boolean;
  groupId?: string;
}

interface ApplicationUrlProps {
  applicationId?: string;
  propertyId?: string;
  unitId?: string;
  groupId?: string;
  dbTenant?: string;
  source?: rentalApplicationEventSource;
  isPostCharge?: boolean;
}

enum RedirectType {
  LISTING = "listing",
  RENTAL_APPLICATION = "rental_application",
  POST_CHARGE = "post_charge"
}

const getRedirectType = (applicationId, propertyId, unitId, dbTenant, isPostCharge): RedirectType | null => {
  if (!dbTenant) {
    return null;
  }
  if (isPostCharge) {
    return RedirectType.POST_CHARGE;
  }
  if ((propertyId && unitId) || applicationId) {
    return RedirectType.RENTAL_APPLICATION;
  }
  return RedirectType.LISTING;
};

export const getShareApplicationUrl = ({
  applicationId,
  propertyId,
  unitId,
  groupId,
  dbTenant,
  source,
  isPostCharge
}: ApplicationUrlProps): string => {
  const routeUrlType = getRedirectType(applicationId, propertyId, unitId, dbTenant, isPostCharge);

  if (!routeUrlType) {
    return "";
  }

  let url = `${location.origin}${
    routeUrlType === RedirectType.LISTING
      ? Routes.TENANT_PORTAL_LISTING_RENTAL_APPLICATION
      : routeUrlType === RedirectType.POST_CHARGE
        ? Routes.TENANT_PORTAL_RENTAL_APPLICATION_POST_SUBMISSION_PAYMENT
        : Routes.TENANT_PORTAL_RENTAL_APPLICATION
  }?`;

  if (dbTenant) {
    url += `companyId=${dbTenant}`;
  }
  if (applicationId) {
    url += `&applicationId=${applicationId}`;
  }
  if (propertyId) {
    url += `&propertyId=${propertyId}`;
  }
  if (groupId) {
    url += `&groupId=${groupId}`;
  }

  if (unitId) {
    url += `&${routeUrlType === RedirectType.LISTING ? "listingId" : "unitId"}=${unitId}`;
  }

  if (source) {
    url += `&source=${source}`;
  }

  return url;
};

const ShareApplicationView: FC<ComponentProps> = ({ shouldShow, groupId }: ComponentProps) => {
  const { isMobile } = useResponsiveHelper();
  const userData = useSelector((state: IRootState) => state.auth.currentLoginResponse);

  const shareApplicationUrl = useMemo(
    () =>
      getShareApplicationUrl({
        applicationId: undefined,
        propertyId: undefined,
        unitId: undefined,
        dbTenant: userData?.currentDbTenant?.id,
        groupId,
        source: rentalApplicationEventSource.companylink
      }),
    []
  );

  return (
    <View showAnimation={"fade-in"} hideAnimation={"fade-out"} shouldShow={shouldShow}>
      <View borderRadius={isMobile ? 0 : 30} backgroundColor={"dark"}>
        <View paddingLeft={20} paddingRight={20}>
          <Text fontSize={18} fontWeight={700} marginTop={20} value={AppStrings.Common.ShareApplication} />
          <Text
            marginTop={20}
            value={AppStrings.Common.ShareApplicationDescription}
            lineHeight={"24px"}
            maxWidth={500}
          />
          <View alignItems={"center"} marginTop={20} flexDirection={"row"}>
            <Icon Source={ShareApplicationIcon} />
            <Text marginLeft={20} fontSize={16} fontWeight={700} value={AppStrings.Common.PublicLink} />
          </View>
          <View flexDirection={"row"}>
            <View overflowWrap={"anywhere"} flex={1}>
              <Text paddingRight={10} lineHeight={"24px"} marginTop={20}>
                {shareApplicationUrl}
              </Text>
              <Text value={AppStrings.Common.PublicLinkDescription} color={"gray"} />
            </View>
            <View alignItems={"center"} width={isMobile ? "100%" : "auto"}>
              <CopyToClipboardButton stringToCopy={shareApplicationUrl} />
            </View>
          </View>
          <View height={40} />
        </View>
      </View>
    </View>
  );
};

export default ShareApplicationView;
