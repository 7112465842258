import { View } from "@/components/DLUI/view";
import { Grid } from "@material-ui/core";
import type { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { DatePickerField } from "./datePickerField";
import { DataCy } from "@doorloop/dto";
import { useTypedTranslation } from "@/locale";

interface DateRangePickerFieldsParams {
  uniqueKey: string;
  startDateOnChange: (selectedDate: MaterialUiPickersDate | null) => void;
  endDateOnChange: (selectedDate: MaterialUiPickersDate | null) => void;
  startDatePickerValue: moment.Moment | null;
  startMinDate?: string;
  startMaxDate?: string;
  endDatePickerValue: moment.Moment | null;
  endMinDate?: string;
  endMaxDate?: string;
}

export function DateRangePickerFields({
  endDatePickerValue,
  startDatePickerValue,
  startDateOnChange,
  endDateOnChange,
  endMinDate,
  startMinDate,
  startMaxDate,
  endMaxDate,
  uniqueKey
}: DateRangePickerFieldsParams) {
  const { t } = useTypedTranslation();

  return (
    <>
      <Grid xs={6} md={6} item>
        <View noWrap>
          <DatePickerField
            label={t("common.filterPanelDatePicker.from")}
            onChange={startDateOnChange}
            pickerValue={startDatePickerValue}
            minDate={startMinDate}
            maxDate={startMaxDate}
            uniqueKey={uniqueKey}
            dataCy={DataCy.DLUI.filters.fromDateFilter}
          />
        </View>
      </Grid>
      <Grid xs={6} md={6} item>
        <View noWrap>
          <DatePickerField
            label={t("common.filterPanelDatePicker.to")}
            onChange={endDateOnChange}
            pickerValue={endDatePickerValue}
            minDate={endMinDate}
            maxDate={endMaxDate}
            uniqueKey={uniqueKey}
            dataCy={DataCy.DLUI.filters.toDateFilter}
          />
        </View>
      </Grid>
    </>
  );
}
