import { DLButtonColorsEnum } from "DLUI/button/dlButton";

export type ConfirmationDialogTypes = "natural" | "info" | "warning" | "error" | "primary";

export interface ConfirmationTextConfiguration {
  titleText?: string;
  descriptionText?: string;
  closeBtnActionText?: string;
  confirmBtnActionText?: string;
  checkboxText?: string;
  errorMessage?: string;
}

export const confirmButtonColorsMap: Record<ConfirmationDialogTypes, DLButtonColorsEnum> = {
  natural: DLButtonColorsEnum.NEUTRAL,
  info: DLButtonColorsEnum.SECONDARY,
  warning: DLButtonColorsEnum.WARNING,
  error: DLButtonColorsEnum.DANGER,
  primary: DLButtonColorsEnum.PRIMARY
};

export const MOBILE_DIALOG_WIDTH = 272;
export const CHECKBOX_DIALOG_WIDTH = 618;
export const DEFAULT_DIALOG_WIDTH = 432;
