import type { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ColorsEnum from "../../../utils/colorsEnum";

export default makeStyles((theme: Theme) =>
  createStyles({
    mobileDownloadVersionBanner: {
      position: "fixed",
      zIndex: 999999,
      backgroundColor: ColorsEnum.White,
      height: "100vh",
      width: "100vw",
      top: 0,
      left: 0,
      transition: "all .3s 1s"
    },
    rocket: {
      animation: "animateRocket 1s linear alternate infinite"
    }
  })
);
