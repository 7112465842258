import type { SidePanelFilterPanelProps } from "@/components/DLUI/screen/searchPanel/sidePanelFilterPanel";
import SidePanelFilterPanel from "@/components/DLUI/screen/searchPanel/sidePanelFilterPanel";
import { FilterInputRendererList } from "../shared/filterRenderers";
import { useContextSelector } from "use-context-selector";
import { FilterChipsContext } from "../context";

export type SidePanelProps = Pick<
  SidePanelFilterPanelProps,
  "endAdornment" | "hideCloseButton" | "style" | "portalElement"
>;

export function SidePanel(props: SidePanelProps) {
  const innerState = useContextSelector(FilterChipsContext, (context) => context.sidePanelState);
  const toggleInnerState = useContextSelector(FilterChipsContext, (context) => context.toggleSidePanelState);
  const handleCloseSidePanel = () => {
    toggleInnerState("closed");
  };

  return (
    <SidePanelFilterPanel isOpen={innerState === "open"} onCloseClick={handleCloseSidePanel} {...props}>
      <FilterInputRendererList />
    </SidePanelFilterPanel>
  );
}
