import TextInput from "DLUI/form/textField/textInput";
import type { ManagementFeeRuleDto } from "@doorloop/dto";
import { DataCy, isPercentageBasedFee } from "@doorloop/dto";
import { ItemLabelChip } from "screens/settings/common/itemLabelChip";
import clsx from "clsx";
import Text from "DLUI/text";
import { useTypedTranslation } from "locale";
import type { TextColor } from "DLUI/text/text";
import type { CSSProperties } from "react";
import { useGeneralStyles } from "styles/generalStyles";
import { formatNumberToFractionWithCommas } from "utils/formatNumberToFractionWithCommas";
import type { AmountsForKey } from "screens/settings/managementFees/sampleCalculator/sampleCalculator";
import { entityApiStore } from "api/entityApiStore/entityApiStore";
import { useCalculatorStyles } from "DLUI/dialogs/settings/common/settings/calculatorStyles";

interface RuleDisplayProps {
  rule: ManagementFeeRuleDto;
  index: number;
  isEditable: boolean;
  textColor: TextColor;
  amounts: AmountsForKey;
  setAmounts: React.Dispatch<React.SetStateAction<AmountsForKey>>;
}

const RULE_STYLE: CSSProperties = {
  width: 150
};

export const RuleDisplay: React.FC<RuleDisplayProps> = ({
  rule,
  index,
  isEditable,
  textColor,
  amounts,
  setAmounts
}) => {
  const { t } = useTypedTranslation();
  const { fee, _id, uniqueKey, ruleType, baseAccount } = rule;
  const { data: accounts } = entityApiStore.accounts.queries.useGetDictionary();
  const formattedFee = fee ?? 0;
  const calculatorClasses = useCalculatorStyles();
  const generalClasses = useGeneralStyles();
  const key = _id ?? uniqueKey!;
  const result = isPercentageBasedFee(ruleType) ? (formattedFee * Number(amounts[key])) / 100 : formattedFee;

  return (
    <div
      className={calculatorClasses.calculatorRuleRow}
      data-cy={DataCy.globalSettings.managementFees.sampleCalculator.rule.container}
    >
      <div className={clsx(generalClasses.flexRow, generalClasses.verticalAlign, generalClasses.mediumGap)}>
        <ItemLabelChip value={index + 1} textColor={textColor} />
        {ruleType &&
          (isPercentageBasedFee(ruleType) && accounts ? (
            <>
              <TextInput
                label={accounts[baseAccount!]?.name ?? t("common.amount")}
                InputLabelProps={{
                  className: calculatorClasses.calculatorInputLabel
                }}
                formatType={"number"}
                TextFieldType={"number"}
                disabled={!isEditable}
                style={RULE_STYLE}
                data-cy={DataCy.globalSettings.managementFees.sampleCalculator.rule.amountInput}
                value={amounts[key] ?? 1000}
                onChange={({ target }) => setAmounts({ ...amounts, [key]: target.value })}
              />
              <Text value={`*${formattedFee.toFixed(2)}% =`} fontSize={12} color={textColor} />
            </>
          ) : (
            <Text value={t("settings.generalSettings.managementFees.flatFeeCharge")} fontSize={14} color={textColor} />
          ))}
      </div>
      <Text
        value={formatNumberToFractionWithCommas(result)}
        fontSize={12}
        fontWeight={700}
        color={textColor}
        dataCy={DataCy.globalSettings.managementFees.sampleCalculator.rule.result}
      />
    </div>
  );
};
