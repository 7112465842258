import type { LeaseTenantDto } from "@doorloop/dto";
import { ObjectPermission, OwnerDto, PhoneNumberStatus } from "@doorloop/dto";
import { ownersApi } from "api/ownersApi";
import AppStrings from "locale/keys";
import React from "react";
import OwnersEmptyDataView from "./ownersEmptyDataView";
import { RestrictedPermissionAccess } from "DLUI/restrictedAccess/restrictedPermissionAccess";
import { PortalIcon, TrashIconOutline } from "assets/icons";
import { DataList } from "DLUI/dataList";
import noop from "lodash/noop";
import { sendWelcomeSmsBulkActionDefinition } from "../../../../utils/bulkOperationsUtils";
import { useCurrentPhoneNumber } from "screens/communicationsCenter/shared/useCurrentPhoneNumber";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { DLButtonColorsEnum } from "DLUI/button/dlButton";

interface ComponentProps {
  multiSelection?: boolean;
  didSelectOwnerItem?: (selectedItem: OwnerDto, itemIndex?: number) => void;
  ListItem: React.ComponentType<any>;
  filterObj: Record<string, any>;
  fullWidth?: boolean;
  scrollableTarget?: string;
  listDirection?: "row" | "column";
  didPressAddNewButton?: () => void;
  selectableListItem?: boolean;
  onLoadingDataStateChange?: (loadingInProgress: boolean) => void;
  listItemMinWidth?: number;
  stickyHeaderId?: string;
  paddingLeft?: number;
  paddingRight?: number;
  removeDefaultBottomPadding?: boolean;
}

const OwnersList: React.FC<ComponentProps> = ({
  didSelectOwnerItem,
  ListItem,
  filterObj,
  fullWidth,
  scrollableTarget,
  didPressAddNewButton = noop,
  selectableListItem,
  onLoadingDataStateChange,
  stickyHeaderId,
  paddingLeft,
  paddingRight,
  removeDefaultBottomPadding
}: ComponentProps) => {
  const { getIsFeatureActiveForDbTenant } = useFeatureFlag();
  const { currentPhoneNumber } = useCurrentPhoneNumber();

  return (
    <RestrictedPermissionAccess clearance={{ permission: ObjectPermission.owners, field: "viewList" }} showNoAccess>
      <DataList
        infinite
        restApi={ownersApi}
        queryFilter={filterObj}
        emptyListView={() => <OwnersEmptyDataView didPressAddNewButton={didPressAddNewButton} />}
        onLoadingDataStateChange={onLoadingDataStateChange}
        paddingEnd={removeDefaultBottomPadding ? 0 : undefined}
        scrollElement={scrollableTarget ? document.getElementById(scrollableTarget) : undefined}
        stickyHeaderProps={{
          id: stickyHeaderId
        }}
        bulkOperationResourceIdGetter={(item, index) => item.id ?? `${item.name} - ${index}`}
        bulkOperationsProps={{
          bulkActionDefinitions: selectableListItem
            ? undefined
            : [
                {
                  actionName: "activateOwnerPortal",
                  actionNameTranslated: AppStrings.BulkActions.SendPortalInvitation,
                  icon: PortalIcon,
                  dialogTextColor: "lightBlue",
                  confirmHeader: AppStrings.BulkActions.ActivatePortalConfirmation,
                  confirmMessageOne: AppStrings.BulkActions.ConfirmActivateOwnerPortalMessageOne,
                  confirmMessageMultiple: AppStrings.BulkActions.ConfirmActivateOwnerPortalMessageMultiple,
                  confirmButtonVariant: DLButtonColorsEnum.SECONDARY
                },
                {
                  ...sendWelcomeSmsBulkActionDefinition,
                  actionName: "sendOwnerWelcomeSms",
                  confirmMessageOne: AppStrings.BulkActions.SendOwnerWelcomeSmsConfirmMessageOne,
                  confirmMessageMultiple: AppStrings.BulkActions.SendOwnerWelcomeSmsConfirmMessageMultiple,
                  isHidden:
                    !getIsFeatureActiveForDbTenant("CommunicationsCenter") ||
                    currentPhoneNumber?.status !== PhoneNumberStatus.ACTIVE
                },
                {
                  actionName: "deleteOwners",
                  actionNameTranslated: AppStrings.Common.Delete,
                  actionButtonColor: DLButtonColorsEnum.DANGER,
                  icon: TrashIconOutline,
                  dialogTextColor: "error",
                  confirmHeader: AppStrings.Common.DeleteConfirmation,
                  confirmMessageOne: AppStrings.BulkActions.ConfirmDeleteOneOwner,
                  confirmMessageMultiple: AppStrings.BulkActions.ConfirmDeleteMultipleOwners
                }
              ],
          minHeight: 150
        }}
      >
        {(item, index, resourceId) => {
          if (selectableListItem) {
            const didSelectTenantItem = (selectedItem: LeaseTenantDto) => {
              const tenantInfo = selectedItem.tenant;
              if (tenantInfo) {
                const _selectedOwner = new OwnerDto();
                _selectedOwner.id = tenantInfo.id;
                _selectedOwner.jobTitle = tenantInfo.jobTitle;
                _selectedOwner.lastName = tenantInfo.lastName;
                _selectedOwner.middleName = tenantInfo.middleName;
                _selectedOwner.name = tenantInfo.name;
                _selectedOwner.pictureUrl = tenantInfo.pictureUrl;
                _selectedOwner.fullName = tenantInfo.fullName;
                if (didSelectOwnerItem) {
                  didSelectOwnerItem(_selectedOwner);
                }
              }
            };

            return (
              <div style={{ width: fullWidth ? "100%" : "auto" }} key={"TL" + index}>
                <ListItem
                  owner={item}
                  didSelectOwnerItem={didSelectOwnerItem}
                  didSelectTenantItem={didSelectTenantItem}
                  paddingLeft={paddingLeft}
                  paddingRight={paddingRight}
                  resourceId={resourceId}
                  {...item}
                />
              </div>
            );
          }
          return (
            <div style={{ width: fullWidth ? "100%" : "auto" }} key={"TL" + index}>
              <ListItem owner={item} resourceId={resourceId} />
            </div>
          );
        }}
      </DataList>
    </RestrictedPermissionAccess>
  );
};

export default OwnersList;
