import type { TenantPortalGetAllInsurancePoliciesQuery } from "@doorloop/dto";
import type { TenantPortalInsurancePolicyDto } from "@doorloop/dto";
import { RestApiBase } from "api/restApiBase";
import { TenantPortalServerRoutes } from "@doorloop/dto";
import { apiHelper } from "api/apiHelper";
import type { InsuranceCompanyByStatedRequestDto } from "@doorloop/dto";

export class TenantPortalInsuranceApi extends RestApiBase<
  TenantPortalInsurancePolicyDto,
  TenantPortalGetAllInsurancePoliciesQuery
> {
  constructor() {
    super(TenantPortalServerRoutes.INSURANCE_POLICIES);
    this.restRoute = TenantPortalServerRoutes.INSURANCE_POLICIES;
  }

  getInsuranceCompanyByState = async (dto: InsuranceCompanyByStatedRequestDto) =>
    await apiHelper.axiosGet(TenantPortalServerRoutes.INSURANCE_COMPANY_BY_STATE, dto);
}

export const tenantPortalInsuranceApi = new TenantPortalInsuranceApi();
