import { usePrint } from "hooks/usePrint";
import { journalEntryApi } from "api/journalEntryApi";

interface UseJournalEntryPrintingParams {
  journalEntryId?: string;
}

export const useJournalEntryPrinting = ({ journalEntryId }: UseJournalEntryPrintingParams) => {
  const { printDocumentFromServer, printLoadingStatus } = usePrint();

  const printJournalEntry = async (id = journalEntryId) => {
    if (!id) {
      return;
    }

    await printDocumentFromServer(async () => await journalEntryApi.getJournalEntryPdf(id));
  };

  return {
    printJournalEntry,
    printLoadingStatus
  };
};
