"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyndicationGeneralAmenitySubTypeEnum = exports.SyndicationGeneralAmenityTypeEnum = void 0;
var SyndicationGeneralAmenityTypeEnum;
(function (SyndicationGeneralAmenityTypeEnum) {
    SyndicationGeneralAmenityTypeEnum["OTHER"] = "Other";
    SyndicationGeneralAmenityTypeEnum["AVAILABILITY_24_HOURS"] = "Availability24Hours";
    SyndicationGeneralAmenityTypeEnum["ACCENT_WALLS"] = "AccentWalls";
    SyndicationGeneralAmenityTypeEnum["BASKETBALL_COURT"] = "BasketballCourt";
    SyndicationGeneralAmenityTypeEnum["BILINGUAL"] = "Bilingual";
    SyndicationGeneralAmenityTypeEnum["BOAT_DOCKS"] = "BoatDocks";
    SyndicationGeneralAmenityTypeEnum["BUSINESS_CENTER"] = "BusinessCenter";
    SyndicationGeneralAmenityTypeEnum["CAR_WASH_AREA"] = "CarWashArea";
    SyndicationGeneralAmenityTypeEnum["CHILD_CARE"] = "ChildCare";
    SyndicationGeneralAmenityTypeEnum["CLUB_DISCOUNT"] = "ClubDiscount";
    SyndicationGeneralAmenityTypeEnum["CLUB_HOUSE"] = "ClubHouse";
    SyndicationGeneralAmenityTypeEnum["CONFERENCE_ROOM"] = "ConferenceRoom";
    SyndicationGeneralAmenityTypeEnum["CONCIERGE"] = "Concierge";
    SyndicationGeneralAmenityTypeEnum["COVER_PARK"] = "CoverPark";
    SyndicationGeneralAmenityTypeEnum["DOOR_ATTENDANT"] = "DoorAttendant";
    SyndicationGeneralAmenityTypeEnum["FITNESS_CENTER"] = "FitnessCenter";
    SyndicationGeneralAmenityTypeEnum["ELEVATOR"] = "Elevator";
    SyndicationGeneralAmenityTypeEnum["FREE_WEIGHTS"] = "FreeWeights";
    SyndicationGeneralAmenityTypeEnum["FURNISHED_AVAILABLE"] = "FurnishedAvailable";
    SyndicationGeneralAmenityTypeEnum["GAMING_STATIONS"] = "GamingStations";
    SyndicationGeneralAmenityTypeEnum["GARAGE"] = "Garage";
    SyndicationGeneralAmenityTypeEnum["GATE"] = "Gate";
    SyndicationGeneralAmenityTypeEnum["GROCERY_SERVICE"] = "GroceryService";
    SyndicationGeneralAmenityTypeEnum["GROUP_EXERCISE"] = "GroupExercise";
    SyndicationGeneralAmenityTypeEnum["GUEST_ROOM"] = "GuestRoom";
    SyndicationGeneralAmenityTypeEnum["HIGH_SPEED"] = "HighSpeed";
    SyndicationGeneralAmenityTypeEnum["HOUSEKEEPING"] = "Housekeeping";
    SyndicationGeneralAmenityTypeEnum["HOUSE_SITTING"] = "HouseSitting";
    SyndicationGeneralAmenityTypeEnum["JOGGING_WALKING_TRAILS"] = "JoggingWalkingTrails";
    SyndicationGeneralAmenityTypeEnum["LAKE_FRONT"] = "LakeFront";
    SyndicationGeneralAmenityTypeEnum["LAKE_ACCESS"] = "LakeAccess";
    SyndicationGeneralAmenityTypeEnum["LAUNDRY"] = "Laundry";
    SyndicationGeneralAmenityTypeEnum["LIBRARY"] = "Library";
    SyndicationGeneralAmenityTypeEnum["MEAL_SERVICE"] = "MealService";
    SyndicationGeneralAmenityTypeEnum["MEDIA_ROOM"] = "MediaRoom";
    SyndicationGeneralAmenityTypeEnum["MULTI_USE_ROOM"] = "MultiUseRoom";
    SyndicationGeneralAmenityTypeEnum["NIGHT_PATROL"] = "NightPatrol";
    SyndicationGeneralAmenityTypeEnum["ON_SITE_MAINTENANCE"] = "OnSiteMaintenance";
    SyndicationGeneralAmenityTypeEnum["ON_SITE_MANAGEMENT"] = "OnSiteManagement";
    SyndicationGeneralAmenityTypeEnum["PACKAGE_RECEIVING"] = "PackageReceiving";
    SyndicationGeneralAmenityTypeEnum["PER_DIEM_ACCEPTED"] = "PerDiemAccepted";
    SyndicationGeneralAmenityTypeEnum["POOL"] = "Pool";
    SyndicationGeneralAmenityTypeEnum["PLAY_GROUND"] = "PlayGround";
    SyndicationGeneralAmenityTypeEnum["RACQUETBALL"] = "Racquetball";
    SyndicationGeneralAmenityTypeEnum["REC_ROOM"] = "RecRoom";
    SyndicationGeneralAmenityTypeEnum["RECYCLING"] = "Recycling";
    SyndicationGeneralAmenityTypeEnum["SAUNA"] = "Sauna";
    SyndicationGeneralAmenityTypeEnum["SHORT_TERM_LEASE"] = "ShortTermLease";
    SyndicationGeneralAmenityTypeEnum["SMOKE_FREE"] = "SmokeFree";
    SyndicationGeneralAmenityTypeEnum["SPA"] = "Spa";
    SyndicationGeneralAmenityTypeEnum["STORAGE_SPACE"] = "StorageSpace";
    SyndicationGeneralAmenityTypeEnum["SUNDECK"] = "Sundeck";
    SyndicationGeneralAmenityTypeEnum["TENNIS_COURT"] = "TennisCourt";
    SyndicationGeneralAmenityTypeEnum["TRANSPORTATION"] = "Transportation";
    SyndicationGeneralAmenityTypeEnum["TV_LOUNGE"] = "TVLounge";
    SyndicationGeneralAmenityTypeEnum["VALET_TRASH"] = "ValetTrash";
    SyndicationGeneralAmenityTypeEnum["VINTAGE"] = "Vintage";
    SyndicationGeneralAmenityTypeEnum["VOLLEYBALL_COURT"] = "VolleyballCourt";
    SyndicationGeneralAmenityTypeEnum["WIRELESS_INTERNET"] = "WirelessInternet";
})(SyndicationGeneralAmenityTypeEnum || (exports.SyndicationGeneralAmenityTypeEnum = SyndicationGeneralAmenityTypeEnum = {}));
var SyndicationGeneralAmenitySubTypeEnum;
(function (SyndicationGeneralAmenitySubTypeEnum) {
    SyndicationGeneralAmenitySubTypeEnum["ATTACHED"] = "Attached";
    SyndicationGeneralAmenitySubTypeEnum["DETACHED"] = "Detached";
    SyndicationGeneralAmenitySubTypeEnum["BOTH"] = "Both";
    SyndicationGeneralAmenitySubTypeEnum["SAME_LEVEL_PARKING"] = "SameLevelParking";
})(SyndicationGeneralAmenitySubTypeEnum || (exports.SyndicationGeneralAmenitySubTypeEnum = SyndicationGeneralAmenitySubTypeEnum = {}));
