"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionReportItem = void 0;
class TransactionReportItem {
    constructor(date, beginningBalance, id, account, type, amount, credit, debit, reference, memo, runningBalance, property, propertyName, unit, unitName, lease, vendor, owner, tenant, managementCompany, nameType, name, fullName, paymentMethod, notes, cleared, reconciled, journalEntryId, accountFullName, tenantName, ownerName, leaseName, vendorName) {
        this.id = id;
        this.journalEntryId = journalEntryId;
        this.beginningBalance = beginningBalance;
        this.account = account;
        this.accountFullName = accountFullName;
        this.type = type;
        this.amount = amount;
        this.credit = credit;
        this.debit = debit;
        this.runningBalance = runningBalance;
        this.date = date;
        this.reference = reference;
        this.memo = memo;
        this.property = property;
        this.propertyName = propertyName;
        this.unit = unit;
        this.unitName = unitName;
        this.lease = lease;
        this.leaseName = leaseName;
        this.vendor = vendor;
        this.vendorName = vendorName;
        this.owner = owner;
        this.ownerName = ownerName;
        this.tenant = tenant;
        this.tenantName = tenantName;
        this.managementCompany = managementCompany;
        this.name = name;
        this.fullName = fullName;
        this.nameType = nameType;
        this.paymentMethod = paymentMethod;
        this.notes = notes;
        this.cleared = cleared;
        this.reconciled = reconciled;
    }
}
exports.TransactionReportItem = TransactionReportItem;
