import { DialogState } from "DLUI/dialogs/loadingDialog";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { View } from "DLUI/view";
import AppStrings from "locale/keys";
import React, { useState } from "react";
import { useEffectAsync } from "hooks/useEffectAsync";
import { useTranslation } from "react-i18next";
import { usersApi } from "api/usersApi";
import { getPrintSetupFormMenuItems } from "./menuItems";
import Dialog from "DLUI/dialogs/dialog";
import AnimatedContent, { getAnimatedContentFormikRef } from "DLUI/dialogs/components/animatedContent";
import { UserPrintSetupConfigDto } from "@doorloop/dto";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum, DLButtonVariantsEnum } from "DLUI/button/dlButton";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import { getInitialPrintConfig } from "../../utils/getInitialPrintConfig";
import { PrintSetupLocalStorageKey } from "./printSetupDialog.constants";

interface PrintSetupDialog {
  dialogState: DialogState;
  onClose: () => void;
  onBackdropClick: () => void;
  title?: string;
}

/**
 * @deprecated This key is going out of service - the config it used to store is being
 * moved to server-side, and users that still has it - their config will be moved to
 * the server and this key will be deleted from their browser
 * https://doorloop.atlassian.net/browse/DLD-7611
 */

const removePrintSetupLocalStorageKeyIfExists = () => {
  localStorage.removeItem(PrintSetupLocalStorageKey);
};

const DialogHeight = 900;

export const PrintSetupDialog: React.FC<PrintSetupDialog> = ({ onClose }: PrintSetupDialog) => {
  const { t } = useTranslation();
  const printSetupDialogItems = getPrintSetupFormMenuItems();
  const [currentTitle, setCurrentTitle] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const [printConfig, setPrintConfig] = useState<UserPrintSetupConfigDto>();

  useEffectAsync(async () => {
    setPrintConfig(await getInitialPrintConfig());
    setIsLoading(false);
  }, []);

  const handleSaveSetupData = async () => {
    const formikRef = getAnimatedContentFormikRef();
    if (formikRef) {
      const setupConfig = new UserPrintSetupConfigDto(formikRef.values);
      await usersApi.updateUserPrintSetup(setupConfig);
      removePrintSetupLocalStorageKeyIfExists();
    }
    onClose();
  };

  const onMenuItemSelection = (title: string) => {
    setCurrentTitle(title);
  };

  const renderActionPanelButtons = () => (
    <View flexDirection={"row"} justifyContent={"flex-end"}>
      <DLButton
        actionText={t(AppStrings.Common.PrintChecks.Setup.Cancel)}
        variant={DLButtonVariantsEnum.OUTLINED}
        color={DLButtonColorsEnum.NEUTRAL}
        size={DLButtonSizesEnum.LARGE}
        onClick={onClose}
        style={{ marginRight: 20 }}
      />
      <DLButton
        actionText={t(AppStrings.Common.PrintChecks.Setup.Action)}
        size={DLButtonSizesEnum.LARGE}
        onClick={handleSaveSetupData}
      />
    </View>
  );

  const emptyCallback = () => {};

  const renderView = () =>
    isLoading ? (
      <View flex={1} width={"100%"} height={"100%"} justifyContent={"center"} alignItems={"center"}>
        <LoadingDialog dialogState={DialogState.Show} />
      </View>
    ) : (
      <AnimatedContent
        formikInitialValues={() => printConfig}
        onMenuItemSelection={onMenuItemSelection}
        sectionItems={printSetupDialogItems}
        formikValidation={emptyCallback}
        dialogHeight={DialogHeight}
        renderActionPanelButtons={renderActionPanelButtons}
      />
    );

  return (
    <Dialog onClose={onClose} open disableBackdropClick>
      <DialogFrame
        onCloseButtonClick={onClose}
        width={getDialogFrameDimension("width", 1100)}
        height={getDialogFrameDimension("height", DialogHeight)}
        renderView={renderView}
        numViews={1}
        activeView={0}
        frameType={isLoading ? "contentOnly" : "sideMenu"}
        title={t(AppStrings.Common.PrintChecks.ActionPanel.Setup)}
        sectionTitle={currentTitle}
      />
    </Dialog>
  );
};
