"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseDto = void 0;
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const leaseStatus_enum_1 = require("./leaseStatus.enum");
const leaseBase_dto_1 = require("./leaseBase.dto");
const leaseKeyAndFobInfo_dto_1 = require("./leaseKeyAndFobInfo.dto");
const leaseVehicleInfo_dto_1 = require("./leaseVehicleInfo.dto");
const leaseSettings_dto_1 = require("../settings/leaseSettings.dto");
class LeaseDto extends leaseBase_dto_1.LeaseBaseDto {
    constructor(init) {
        super();
        this.units = new Array();
        Object.assign(this, init);
    }
}
exports.LeaseDto = LeaseDto;
__decorate([
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsMongoId)({ each: true }),
    (0, class_validator_1.ArrayMinSize)(1),
    __metadata("design:type", Array)
], LeaseDto.prototype, "units", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(leaseStatus_enum_1.LeaseStatus),
    __metadata("design:type", String)
], LeaseDto.prototype, "status", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], LeaseDto.prototype, "evictionPending", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => leaseSettings_dto_1.LeaseSettingsDto),
    __metadata("design:type", leaseSettings_dto_1.LeaseSettingsDto)
], LeaseDto.prototype, "settings", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], LeaseDto.prototype, "proofOfInsuranceRequired", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => leaseVehicleInfo_dto_1.LeaseVehicleInfoDto),
    (0, class_validator_1.ValidateNested)({ each: true }),
    __metadata("design:type", Array)
], LeaseDto.prototype, "vehicles", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => leaseKeyAndFobInfo_dto_1.LeaseKeyAndFobInfoDto),
    (0, class_validator_1.ValidateNested)({ each: true }),
    __metadata("design:type", Array)
], LeaseDto.prototype, "keysAndFobs", void 0);
