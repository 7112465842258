import React, { useCallback, useEffect, useMemo, useState } from "react";

import _ from "lodash";
import type { FormikProps } from "formik";
import { FastField, getIn } from "formik";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import type { HasTenantsAutoPayments, TenantPortalSettingsDto } from "@doorloop/dto";
import { TenantPortalAccessDto } from "@doorloop/dto";

import AppStrings from "locale/keys";
import Text from "DLUI/text";
import WarningView from "DLUI/form/warningView/warningView";
import { RadioGroup } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import { useCancelLeaseAutoPaymentsTexts } from "screens/settings/tenantPortal/hooks/useCancelLeaseAutoPaymentsTexts";
import { useTenantPortalAccessObjectPath } from "screens/settings/tenantPortal/hooks/useTenantPortalAccessObjectPath";
import { View } from "DLUI/view";
import { useUserType } from "../../../../../hooks/useUserType";
import { useResponsiveHelper } from "../../../../../contexts/responsiveContext";
import { AnimatedView } from "DLUI/view/animatedView";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { useFeatureFlag } from "../../../../../hooks/useFeatureFlag";
import { FormikSwitchButtonRow } from "DLUI/form/switchButton/FormikSwitchButtonRow";
import { SettingsBlock } from "screens/settings/tenantPortal/components/settingsBlock";
import { TenantPortalAccessObjectPath } from "screens/settings/tenantPortal/hooks/tenantPortalAccessObjectPath";
import { SettingsLevels } from "screens/settings/tenantPortal/components/getTenantPortalDynamicSettingLevelFieldsPath";
import CompanyTenantRequests from "screens/settings/tenantPortal/components/companyTenantRequests";

interface FeaturesProps {
  formikRef: FormikProps<TenantPortalSettingsDto>;
  hasTenantsAutoPayments: HasTenantsAutoPayments;
}

interface FeaturesParams {
  leaseId?: string;
  propertyId?: string;
}

type FeaturesSelectionType = "companyDefault" | "specifyFeaturesInfo";

const TenantPortalSettingsSection = ({ formikRef, hasTenantsAutoPayments }: FeaturesProps): JSX.Element => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();
  const { leaseId, propertyId } = useParams<FeaturesParams>();
  const { isHOAUser } = useUserType();
  const { isFeatureFlagActive } = useFeatureFlag("CashPayments");

  const formikFieldName = useTenantPortalAccessObjectPath({
    leaseId,
    propertyId
  });

  const cancelAutoPaymentsTexts = useCancelLeaseAutoPaymentsTexts();

  const featuresSelectionDefaultValue = useMemo(() => {
    const fieldValues = getIn(formikRef.values, formikFieldName);

    const isEmptyObject = _.values(fieldValues).every((value) => value === undefined);

    if (isEmptyObject) {
      return "companyDefault";
    }
    return "specifyFeaturesInfo";
  }, []);

  const [featuresSelectionType, setFeaturesSelectionType] =
    useState<FeaturesSelectionType>(featuresSelectionDefaultValue);

  const [renderForm, setRenderForm] = useState<boolean>(true);

  const getFeaturesFieldKey = useCallback(
    (field: keyof TenantPortalAccessDto): string => `${formikFieldName}.${field}`,
    [formikFieldName]
  );

  const {
    onlinePaymentsFeatureKey,
    partialPaymentsFeatureKey,
    disableACHPaymentFeatureKey,
    disableCreditCardPaymentFeatureKey,
    enableCashPaymentFeatureKey,
    uploadProofOfInsuranceFeatureKey,
    viewLeaseLedgerFeatureKey,
    portalEnabledFeatureKey,
    submitOnlineRequestsFeatureKey,
    viewOpenRequestsFeatureKey,
    viewClosedRequestsFeatureKey
  } = useMemo(() => {
    return {
      onlinePaymentsFeatureKey: getFeaturesFieldKey("onlinePayments"),
      partialPaymentsFeatureKey: getFeaturesFieldKey("partialPayments"),
      disableACHPaymentFeatureKey: getFeaturesFieldKey("disableACHPayment"),
      disableCreditCardPaymentFeatureKey: getFeaturesFieldKey("disableCreditCardPayment"),
      enableCashPaymentFeatureKey: getFeaturesFieldKey("enableCashPayment"),
      uploadProofOfInsuranceFeatureKey: getFeaturesFieldKey("uploadProofOfInsurance"),
      viewLeaseLedgerFeatureKey: getFeaturesFieldKey("viewLeaseLedger"),
      portalEnabledFeatureKey: getFeaturesFieldKey("portalEnabled"),
      submitOnlineRequestsFeatureKey: getFeaturesFieldKey("submitOnlineRequests"),
      viewOpenRequestsFeatureKey: getFeaturesFieldKey("viewOpenRequests"),
      viewClosedRequestsFeatureKey: getFeaturesFieldKey("viewClosedRequests")
    };
  }, [getFeaturesFieldKey]);

  const isDisableCreditCardPayment = Boolean(getIn(formikRef.values, disableCreditCardPaymentFeatureKey));
  const isDisableACHPayment = Boolean(getIn(formikRef.values, disableACHPaymentFeatureKey));
  const isCashPaymentEnabled = Boolean(getIn(formikRef.values, enableCashPaymentFeatureKey));
  const isPartialPayments = Boolean(getIn(formikRef.values, partialPaymentsFeatureKey));
  const isOnlinePayments = Boolean(getIn(formikRef.values, onlinePaymentsFeatureKey));

  const refreshForm = (): void => {
    setRenderForm(false);

    formikRef.setFieldValue(formikFieldName, undefined);

    setTimeout(() => {
      setRenderForm(true);
    }, 0);
  };

  const didChangeSpecifyFeaturesSelection = (selectedValue: string): void => {
    if (selectedValue === "companyDefault") {
      setFeaturesSelectionType("companyDefault");

      setTimeout(() => {
        refreshForm();
      }, 500);
    } else {
      setFeaturesSelectionType("specifyFeaturesInfo");

      formikRef.setFieldValue(
        formikFieldName,
        new TenantPortalAccessDto({
          portalEnabled: true,
          onlinePayments: true,
          partialPayments: false,
          viewLeaseLedger: true,
          uploadProofOfInsurance: true,
          purchaseRentersInsurance: true,
          viewRenewalOffers: true,
          viewAnnouncements: true,
          disableCreditCardPayment: false,
          disableACHPayment: false,
          enableCashPayment: false
        })
      );
    }
  };

  const renderSpecifyRadioButtons = (): JSX.Element | null => {
    if (!leaseId && !propertyId) {
      return null;
    }

    return (
      <>
        <RadioGroup
          key={featuresSelectionType}
          didChangeSelection={didChangeSpecifyFeaturesSelection}
          uniqueKey="FRB"
          defaultValue={featuresSelectionType}
          radioGroupItems={[
            {
              label: leaseId ? AppStrings.Common.HideFeaturesForLease : AppStrings.Common.HideFeaturesForProperty,
              value: "companyDefault"
            },
            {
              label: leaseId ? AppStrings.Common.SpecifyFeaturesForLease : AppStrings.Common.SpecifyFeaturesForProperty,
              value: "specifyFeaturesInfo"
            }
          ]}
        />
        <SeparationLine width="100%" height={1} />
      </>
    );
  };

  const handleOnlinePaymentsChange = useCallback((isEnabled: boolean): void => {
    formikRef.setFieldValue(disableACHPaymentFeatureKey, !isEnabled);
    formikRef.setFieldValue(disableCreditCardPaymentFeatureKey, !isEnabled);
    formikRef.setFieldValue(partialPaymentsFeatureKey, isEnabled);
  }, []);

  useEffect(() => {
    if (featuresSelectionType === "specifyFeaturesInfo") {
      formikRef.setFieldValue(
        onlinePaymentsFeatureKey,
        !(isDisableCreditCardPayment && isDisableACHPayment && !isPartialPayments && !isCashPaymentEnabled)
      );
    }
  }, [
    featuresSelectionType,
    isDisableCreditCardPayment,
    isDisableACHPayment,
    isCashPaymentEnabled,
    isPartialPayments,
    onlinePaymentsFeatureKey
  ]);

  const renderOnlinePaymentsCancelWarning = () => {
    const showWarning = Boolean(leaseId && hasTenantsAutoPayments.hasAutoPayments && !isOnlinePayments);

    if (!showWarning) {
      return null;
    }

    return (
      <View flexDirection={"row"}>
        <WarningView iconSize={20} type="warning">
          <Text fontSize={14} fontWeight={400}>
            {cancelAutoPaymentsTexts.cancelWarning}
          </Text>
        </WarningView>
      </View>
    );
  };
  const allowSubmitOnlineRequests = Boolean(getIn(formikRef.values, submitOnlineRequestsFeatureKey));
  const allowAccessToPortal = Boolean(getIn(formikRef.values, portalEnabledFeatureKey));
  const allowOnlinePayment = Boolean(getIn(formikRef.values, onlinePaymentsFeatureKey));

  const renderDefaultSettingsCancelWarning = () => {
    const onlinePayments = getIn(formikRef.values, onlinePaymentsFeatureKey);

    const showWarning = Boolean(
      leaseId &&
        onlinePayments === undefined &&
        hasTenantsAutoPayments.hasAutoPayments &&
        hasTenantsAutoPayments.hasParentOnlinePayments === false
    );

    if (!showWarning) {
      return null;
    }

    return (
      <View flexDirection={"row"}>
        <WarningView iconSize={20} type="warning">
          <Text fontSize={14} fontWeight={400}>
            {t(AppStrings.Common.TenantPortalFeaturesOnlinePayments.DefaultSettingsCancelWarning)}
          </Text>
        </WarningView>
      </View>
    );
  };

  return (
    <View flex={1} gap={16} noWrap>
      <Text color="black" fontSize={16} value={AppStrings.Common.TenantsPortalSettingsDescription} />
      <SeparationLine width="100%" height={1} />
      {renderSpecifyRadioButtons()}
      {renderDefaultSettingsCancelWarning()}
      <AnimatedView shouldShow={featuresSelectionType === "specifyFeaturesInfo"}>
        {renderForm && (
          <View>
            <SettingsBlock>
              <FastField
                component={FormikSwitchButtonRow}
                name={portalEnabledFeatureKey}
                label={AppStrings.Common.TenantPortalSettingsSections.AllowTenantToAccessTheTenantPortal}
                textProps={{
                  fontSize: 16
                }}
              />
            </SettingsBlock>
            <AnimatedView shouldShow={allowAccessToPortal}>
              <SettingsBlock marginTop={isMobile ? 16 : 8}>
                <FastField
                  component={FormikSwitchButtonRow}
                  name={onlinePaymentsFeatureKey}
                  label={AppStrings.Common.EnableOnlinePayments}
                  onChange={handleOnlinePaymentsChange}
                  size={isMobile ? "large" : "small"}
                />
                <AnimatedView shouldShow={allowOnlinePayment}>
                  <SeparationLine width="100%" height={1} />
                  <FastField
                    component={FormikSwitchButtonRow}
                    name={partialPaymentsFeatureKey}
                    label={AppStrings.Common.AllowPartialPayments}
                    textProps={{
                      color: "secondary-gray"
                    }}
                    size={isMobile ? "large" : "small"}
                  />
                  <CenteredSeparationLine />
                  <FastField
                    component={FormikSwitchButtonRow}
                    name={disableACHPaymentFeatureKey}
                    label={AppStrings.Common.BankTransferAch}
                    textProps={{
                      color: "secondary-gray"
                    }}
                    flipValue
                    size={isMobile ? "large" : "small"}
                  />
                  <CenteredSeparationLine />
                  <FastField
                    component={FormikSwitchButtonRow}
                    name={disableCreditCardPaymentFeatureKey}
                    label={AppStrings.Common.TenantPortalSettingsSections.TenantCanPayWithCreditCard}
                    textProps={{
                      color: "secondary-gray"
                    }}
                    flipValue
                    size={isMobile ? "large" : "small"}
                  />

                  {isFeatureFlagActive && (
                    <>
                      <CenteredSeparationLine />
                      <FastField
                        component={FormikSwitchButtonRow}
                        name={enableCashPaymentFeatureKey}
                        label={AppStrings.Common.CashPayments.Title}
                        textProps={{
                          color: "secondary-gray"
                        }}
                        size={isMobile ? "large" : "small"}
                      />
                    </>
                  )}
                  {renderOnlinePaymentsCancelWarning()}
                </AnimatedView>
              </SettingsBlock>
              <SettingsBlock marginTop={isMobile ? 16 : 8}>
                <FastField
                  component={FormikSwitchButtonRow}
                  name={viewLeaseLedgerFeatureKey}
                  label={AppStrings.Common.ViewLeaseLedger}
                  size={isMobile ? "large" : "small"}
                />
              </SettingsBlock>
              <SettingsBlock marginTop={isMobile ? 16 : 8}>
                <FastField
                  component={FormikSwitchButtonRow}
                  name={uploadProofOfInsuranceFeatureKey}
                  label={AppStrings.Common.RequireInsuranceDocuments}
                  size={isMobile ? "large" : "small"}
                />
              </SettingsBlock>
              <SettingsBlock marginTop={isMobile ? 16 : 8}>
                <FastField
                  component={FormikSwitchButtonRow}
                  name={submitOnlineRequestsFeatureKey}
                  label={AppStrings.Common.AllowTenantOnlineRequests}
                  size={isMobile ? "large" : "small"}
                />

                <AnimatedView shouldShow={allowSubmitOnlineRequests}>
                  <SeparationLine width="100%" height={1} />
                  <FastFieldSafe
                    component={FormikSwitchButtonRow}
                    name={viewOpenRequestsFeatureKey}
                    label={AppStrings.Common.ViewOpenRequests}
                    textProps={{
                      color: "secondary-gray"
                    }}
                    size={isMobile ? "large" : "small"}
                  />
                  <View justifyContent={"center"}>
                    <CenteredSeparationLine />
                  </View>
                  <FastFieldSafe
                    component={FormikSwitchButtonRow}
                    name={viewClosedRequestsFeatureKey}
                    label={AppStrings.Common.ViewClosedRequests}
                    textProps={{
                      color: "secondary-gray"
                    }}
                    size={isMobile ? "large" : "small"}
                  />
                  <CenteredSeparationLine />
                  <CompanyTenantRequests
                    settingLevel={
                      formikFieldName === TenantPortalAccessObjectPath.Lease
                        ? SettingsLevels.Lease
                        : formikFieldName === TenantPortalAccessObjectPath.Property
                          ? SettingsLevels.Property
                          : SettingsLevels.Company
                    }
                    textProps={{
                      color: "secondary-gray",
                      value: AppStrings.Common.TenantPortalSettingsSections.AddInstructions,
                      bold: true
                    }}
                    viewProps={{
                      paddingTop: 16,
                      paddingRight: 16,
                      paddingLeft: 16,
                      paddingBottom: 16
                    }}
                    label={AppStrings.Common.TenantPortalSettingsSections.Instructions}
                  />
                </AnimatedView>
              </SettingsBlock>
              <View height={20} />
            </AnimatedView>
          </View>
        )}
      </AnimatedView>
    </View>
  );
};

const CenteredSeparationLine = () => (
  <View justifyContent={"center"} alignItems={"center"}>
    <SeparationLine width="95%" height={1} />
  </View>
);

export default TenantPortalSettingsSection;
