import type { FC } from "react";
import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import { isAndroid } from "react-device-detect";
import { View } from "DLUI/view";
import makeStyles from "./styles";
import clsx from "clsx";
import AppStrings from "../../../locale/keys";
import { useLocation } from "react-router";
import { Icon } from "DLUI/icon";
import { DLRocket } from "../../../assets";
import Text from "DLUI/text";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum } from "DLUI/button/dlButton";
import { analyticsService } from "../../../services/analyticsService";
import { SegmentEventTypes } from "@doorloop/dto";
import { devicesMethods, MobileDeviceBridgeMessages } from "../../../services/mobileDeviceBridgeService";
import isUndefined from "lodash/isUndefined";
import { Routes } from "../../../components/appRouter";
import { getAndroidMetadata, isIOSWebView, isWebView } from "common/native/utils";
import { ANDROID_APP_MANDATORY_VERSION, IOS_APP_MANDATORY_VERSION } from "common/native/constants";

const navigationPathsBlockList: string[] = [Routes.RENTAL_APPLICATIONS, Routes.TENANT_PORTAL_RENTAL_APPLICATION];
function isBlockedPath(pathname: string) {
  return navigationPathsBlockList.includes(pathname);
}

const googlePlayLink = process.env.REACT_APP_GOOGLE_PLAY_LINK;
const appStoreLink = process.env.REACT_APP_APP_STORE_LINK;
const DefaultTimeOutToForceDownload = 3000;

const DownloadMobileVersionsBanner: FC = () => {
  const currentUserNativeVersion = useRef<number | undefined>();
  const { pathname } = useLocation();
  const [forceDownload, setForceDownload] = useState<boolean>(false);
  const { mobileDownloadVersionBanner, rocket } = makeStyles();

  const getCurrentNativeVersion = () => {
    const { current: nativeVersion } = currentUserNativeVersion;
    const androidVersion = getAndroidMetadata()?.buildNumber;
    const finalVersion = isUndefined(androidVersion) ? nativeVersion : Number(androidVersion);

    return finalVersion;
  };

  const getMobileVersions = () => {
    const nativeVersion = getCurrentNativeVersion();
    if (isUndefined(nativeVersion)) {
      setForceDownload(true);
      return;
    }

    const mandatoryVersion = isIOSWebView() ? IOS_APP_MANDATORY_VERSION : ANDROID_APP_MANDATORY_VERSION;

    setForceDownload(mandatoryVersion > nativeVersion);
  };

  useEffect(() => {
    devicesMethods[MobileDeviceBridgeMessages.DidReceiveBuildNumber](currentUserNativeVersion);
    devicesMethods[MobileDeviceBridgeMessages.GetAppBuildNumber]();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getMobileVersions();
    }, DefaultTimeOutToForceDownload);
  }, [currentUserNativeVersion.current]);

  useEffect(() => {
    if (forceDownload && isWebView()) analyticsService.track(SegmentEventTypes.MOBILE_APP_FORCE_UPDATE_VIEW);
  }, [forceDownload]);

  const onUpdateButtonClick = () => {
    const storeLink = isAndroid ? googlePlayLink : appStoreLink;
    analyticsService.track(SegmentEventTypes.MOBILE_APP_FORCE_UPDATE_CLICKED);
    window.open(storeLink, "_blank");
  };

  if (!isWebView() || isBlockedPath(pathname) || !forceDownload) return null;

  return (
    <View
      flexDirection={"row"}
      className={clsx(mobileDownloadVersionBanner)}
      style={{ boxSizing: "border-box", height: "100vh" }}
      fullWidth
      justifyContent={"center"}
      paddingTop={isAndroid ? 45 : 0}
      alignItems={"flex-end"}
    >
      <View
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        height={295}
        width={265}
        alignSelf={"center"}
        marginTop={66}
      >
        <View flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
          <Icon Source={DLRocket} height={130} width={100} className={clsx([rocket])} />
        </View>

        <View alignItems={"center"} justifyContent={"center"}>
          <Text
            align={"center"}
            bold
            value={AppStrings.Common.MobileDownloadVersion.MandatoryTitle}
            fontFamily={"Roboto Slab"}
            fontSize={24}
            lineHeight={"31px"}
          />
          <Text
            align={"center"}
            marginTop={16}
            value={AppStrings.Common.MobileDownloadVersion.MandatoryText}
            fontSize={16}
            lineHeight={"19px"}
            color={"secondary-gray"}
          />
        </View>
      </View>
      <View
        paddingLeft={24}
        paddingRight={24}
        height={92}
        alignItems={"center"}
        flexDirection={"row"}
        style={{
          borderTop: "1px solid rgba(122, 134, 153, 0.2)"
        }}
      >
        <DLButton
          onClick={onUpdateButtonClick}
          actionText={AppStrings.Navigation.UpdateNow}
          color={DLButtonColorsEnum.PRIMARY}
          style={{ width: "100%" }}
          size={DLButtonSizesEnum.LARGE}
        />
      </View>
    </View>
  );
};

export default DownloadMobileVersionsBanner;
