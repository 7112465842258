import type { BankAccountDto } from "@doorloop/dto";
import { AccountType, BankAccountMerchantStatus, SettingPermission } from "@doorloop/dto";
import { ArrowUpIcon, BankIcon, CheckIconCircle, CreditCardIcon, QuestionMarkBlackIcon } from "assets/icons";
import { Grid, ListItemContainer, ListItemIcon, ListItemSection } from "DLUI/listItems";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { NavigationManager } from "utils/navigation";
import { usePermission } from "screens/settings/userRoles/usePermission";
import { Icon } from "DLUI/icon";
import ListItemText from "DLUI/listItems/listItemText";
import { buildViewOrEditSettingsClearances } from "screens/settings/userRoles/clearanceTypes";
import ColorsEnum from "../../../../utils/colorsEnum";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { useTypedTranslation } from "locale";
import { useAnalyticsService } from "hooks/useAnalyticsService";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum } from "DLUI/button/dlButton";
import { DefaultLearnMoreTooltipContent, LearnMoreTooltip } from "DLUI/popover/learnMoreTooltip";

interface PaymentAccountSetupAccountListItemProps {
  bankAccountDto: BankAccountDto;
  stickyHeaderId: string;
}

export const OutgoingPaymentsAccountSetupAccountListItem = ({
  bankAccountDto,
  stickyHeaderId
}: PaymentAccountSetupAccountListItemProps) => {
  const { isMobile } = useResponsiveHelper();
  const { t } = useTypedTranslation();
  const location = useLocation<any>();
  const { name, description, id, merchantAccountStatus, merchantAccountId } = bankAccountDto;
  const { dispatchAnalytics } = useAnalyticsService();
  const { hasAnyPermission } = usePermission();

  const handleAccountClick = () => {
    NavigationManager.routeToMAOPage(
      bankAccountDto.merchantAccountId ? { filter_merchant_account: bankAccountDto.merchantAccountId } : {}
    );
  };

  const handleEnableOutgoingPayments = () => {
    dispatchAnalytics(
      "receive_payments_send_payments_status",
      {
        action: "enable outgoing payments"
      },
      { trackEventInIntercom: true }
    );
    NavigationManager.editOutgoingPaymentsSettings(location.pathname);
  };

  const outgoingPaymentsEnabled = Boolean(bankAccountDto.outgoingPaymentsInfo?.enabled);

  const sendPaymentsCell = useMemo(() => {
    const acceptingPaymentsCompleted = merchantAccountStatus === BankAccountMerchantStatus.ACCEPTING_ONLINE_PAYMENTS;
    const hasPermissionToEnableSendingPayments = hasAnyPermission(
      buildViewOrEditSettingsClearances(SettingPermission.outgoingPayments)
    );

    if (outgoingPaymentsEnabled) {
      return !isMobile ? (
        <View flexDirection={"row"} alignItems={"center"} gap={5} fullWidth>
          <Icon size={14} Source={CheckIconCircle} pathColor={"dark-green"} circleStroke={"dark-green"} />
          <Text color={"green"} fontSize={14} value={t("common.connected")} />
        </View>
      ) : null;
    }

    if (hasPermissionToEnableSendingPayments && acceptingPaymentsCompleted) {
      return (
        <View noWrap fullWidth>
          <DLButton
            onClick={handleEnableOutgoingPayments}
            style={{ width: "100%" }}
            size={DLButtonSizesEnum.SMALL}
            color={DLButtonColorsEnum.SECONDARY}
            actionText={t("common.connect")}
          />
        </View>
      );
    }

    return (
      <View flexDirection={"row"} alignItems={"center"}>
        {isMobile && (
          <>
            <Icon Source={ArrowUpIcon} width={12} height={14} marginRight={5} />
            <Text fontSize={14} value={`${t("common.outgoingPayments.outgoingPayments")}:`} marginRight={5} />
          </>
        )}
        <Text fontSize={14} value={t("tenants.status.inactive")} color={"secondary-gray"} />
        <LearnMoreTooltip
          iconSource={QuestionMarkBlackIcon}
          contentView={
            <DefaultLearnMoreTooltipContent
              description={
                hasPermissionToEnableSendingPayments
                  ? t("common.onlinePaymentsAccountsList.inactiveMessage")
                  : t("common.onlinePaymentsAccountsList.inactivePermissionMessage")
              }
              header={t("common.sendPayment")}
            />
          }
          marginLeft={3}
        />
      </View>
    );
  }, [merchantAccountStatus]);

  const listItemNameTitle = useMemo(
    () => (
      <ListItemText
        overflow={"ellipsis"}
        numberOfLines={2}
        color={"black"}
        align="left"
        fontWeight={700}
        marginRight={10}
      >
        {name}
      </ListItemText>
    ),
    [name]
  );

  const listItemNameSubtitle = useMemo(
    () => (
      <View alignItems={"flex-start"} noWrap>
        {isMobile && (
          <ListItemText
            color={outgoingPaymentsEnabled ? "green" : "error"}
            overflow={"ellipsis"}
            numberOfLines={2}
            value={outgoingPaymentsEnabled ? t("common.connected") : t("common.notConnected")}
            fontSize={12}
            bold
          />
        )}
        <ListItemText color={"gray"} overflow={"ellipsis"} numberOfLines={2} value={description} />
      </View>
    ),
    [isMobile, outgoingPaymentsEnabled, description]
  );

  return (
    <ListItemContainer id={stickyHeaderId} popoverWidth={200}>
      <Grid
        title={isMobile ? undefined : t("common.settings.lateFees.account")}
        xs={12}
        sm={10}
        md={10}
        lg={10}
        onClick={handleAccountClick}
      >
        <View flexDirection={"row"} alignItems={"center"}>
          {bankAccountDto.accountType === AccountType.ASSET_BANK ? (
            <ListItemIcon
              Icon={BankIcon}
              containerStyle={{
                backgroundColor: outgoingPaymentsEnabled ? ColorsEnum.LightGreen : ColorsEnum.LightGray
              }}
              pathColor={outgoingPaymentsEnabled ? "dark-green" : "light-gray"}
            />
          ) : (
            <ListItemIcon Icon={CreditCardIcon} />
          )}
          <ListItemSection renderTitle={listItemNameTitle} renderSubTitle={listItemNameSubtitle} />
        </View>
      </Grid>

      <Grid title={isMobile ? undefined : t("common.requiredAction")} xs={12} sm={2} md={2} lg={2}>
        {sendPaymentsCell}
      </Grid>
    </ListItemContainer>
  );
};
