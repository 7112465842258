import type { Moment } from "moment";
import moment from "moment";
import qs from "query-string";

export const resolveDatesFromPeriodString = (
  selectedPeriod: string,
  pickerFromFiledName?: string,
  pickerToFiledName?: string
): { startDate: Moment | null; endDate: Moment | null } => {
  let startDate: Moment | null = null;
  let endDate: Moment | null = null;
  const dateFromField = pickerFromFiledName || "filter_date_from";
  const dateToField = pickerToFiledName || "filter_date_to";
  switch (selectedPeriod) {
    case "custom":
      const urlParams = qs.parse(location.search);

      if (urlParams[dateFromField]) {
        startDate = moment(urlParams[dateFromField]!);
      }
      if (urlParams[dateToField]) {
        endDate = moment(urlParams[dateToField]!);
      }
      break;
    case "this-week":
      startDate = moment().startOf("week");
      endDate = moment().endOf("week");
      break;
    case "this-week-to-date":
      startDate = moment().startOf("week");
      endDate = moment();
      break;
    case "this-month":
      startDate = moment().startOf("month");
      endDate = moment().endOf("month");
      break;
    case "this-month-to-date":
      startDate = moment().startOf("month");
      endDate = moment();
      break;
    case "this-year":
      startDate = moment().startOf("year");
      endDate = moment().endOf("year");
      break;
    case "last-week":
      startDate = moment().add(-1, "week").startOf("week");
      endDate = moment().add(-1, "week").endOf("week");
      break;
    case "last-month":
      startDate = moment().add(-1, "month").startOf("month");
      endDate = moment().add(-1, "month").endOf("month");
      break;
    case "last-month-to-date":
      startDate = moment().add(-1, "month").startOf("month");
      endDate = moment().add(-1, "month");
      break;
    case "this-year-to-date":
      startDate = moment().startOf("year");
      endDate = moment();
      break;
    case "this-year-to-last-month":
      startDate = moment().startOf("year");
      endDate = moment().add(-1, "month").endOf("month");
      break;
    case "this-quarter":
      startDate = moment().startOf("quarter");
      endDate = moment().endOf("quarter");
      break;
    case "last-quarter":
      startDate = moment().add(-1, "quarter").startOf("quarter");
      endDate = moment().add(-1, "quarter").endOf("quarter");
      break;
    case "last-quarter-to-date":
      startDate = moment().add(-1, "quarter").startOf("quarter");
      endDate = moment().add(-1, "quarter");
      break;
    case "this-quarter-to-date":
      startDate = moment().startOf("quarter");
      endDate = moment();
      break;
    case "today":
      startDate = moment();
      endDate = moment();
      break;
    case "last-week-to-date":
      startDate = moment().add(-1, "week").startOf("week");
      endDate = moment().add(-1, "week");
      break;
    case "yesterday":
      startDate = moment().add(-1, "days");
      endDate = moment().add(-1, "days");
      break;
    case "next-120-days":
      startDate = moment();
      endDate = moment().add(119, "days");
      break;
    case "next-90-days":
      startDate = moment();
      endDate = moment().add(89, "days");
      break;
    case "next-60-days":
      startDate = moment();
      endDate = moment().add(59, "days");
      break;
    case "next-7-days":
      startDate = moment();
      endDate = moment().add(6, "days");
      break;
    case "next-30-days":
      startDate = moment();
      endDate = moment().add(29, "days");
      break;
    case "last-120-days":
      startDate = moment().add(-119, "days");
      endDate = moment();
      break;
    case "last-180-days":
      startDate = moment().add(-179, "days");
      endDate = moment();
      break;
    case "last-90-days":
      startDate = moment().add(-89, "days");
      endDate = moment();
      break;
    case "last-60-days":
      startDate = moment().add(-59, "days");
      endDate = moment();
      break;
    case "last-30-days":
      startDate = moment().add(-29, "days");
      endDate = moment();
      break;
    case "last-7-days":
      startDate = moment().add(-6, "days");
      endDate = moment();
      break;
    case "last-year":
      startDate = moment().add(-1, "year").startOf("year");
      endDate = moment().add(-1, "year").endOf("year");
      break;
    case "all-time":
      startDate = null;
      endDate = null;
      break;
    default:
      startDate = null;
      endDate = null;
  }
  return { startDate, endDate };
};
