import React, { Fragment, useEffect, useRef, useState } from "react";
import type { ImageButtonGroupProps } from "DLUI/form";
import { ImageButton, ValidationIndicator } from "DLUI/form";
import makeStyles from "./styles";
import _ from "lodash";
import type { FieldProps } from "formik";
import { getIn } from "formik";
import { animated, useTrail } from "react-spring";
import { Link } from "DLUI/link";
import { useTranslation } from "react-i18next";

interface ComponentProps extends FieldProps<any> {
  uniqueId: string;
  buttonItemsArray: ImageButtonGroupProps[];
  onSelect?: (selectedValue: string, itemIndex: number) => void;
  onRef?: (ref: React.MutableRefObject<any>) => void;
  size?: number;
  flexDirection?: "flex-start" | "center";
  marginRight?: number;
  labelMaxWidth?: number;
  hideAnimation?: boolean;
  validationIndicatorJustifyContent?: "flex-start" | "center";
  maxWidth?: number;
  noWrap?: boolean;
  showValidationIndicator?: boolean;
  hasGridStyle?: boolean;
  expandOptionsByDefault?: boolean;
  fontSize?: number;
  dataCy?: string;
  widthItem?: number;
  allowSetFieldValue?: boolean;
}

const config = { mass: 5, tension: 2000, friction: 200, duration: 500 };

const FormikImageButtonGroup: React.FC<ComponentProps> = ({
  buttonItemsArray,
  onSelect,
  onRef,
  form,
  field,
  size,
  flexDirection,
  marginRight,
  labelMaxWidth,
  hideAnimation,
  validationIndicatorJustifyContent,
  maxWidth,
  noWrap,
  showValidationIndicator,
  hasGridStyle,
  expandOptionsByDefault = true,
  dataCy,
  fontSize,
  widthItem,
  allowSetFieldValue = true
}: ComponentProps) => {
  const classes = makeStyles();
  const { t } = useTranslation();
  const [isOptionsMinimized, setIsOptionsMinimized] = useState(!expandOptionsByDefault);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState<number | undefined>();
  let errorText = undefined;
  let touchedVal = undefined;
  let fieldValue: string | undefined = undefined;
  if (field.name) {
    fieldValue = getIn(form.values, field.name);
    errorText = getIn(form.errors, field.name);
    touchedVal = getIn(form.touched, field.name);
  }
  const hasError = touchedVal && errorText !== undefined;

  const [currentSelection, setCurrentSelection] = useState<ImageButtonGroupProps[]>(buttonItemsArray);

  useEffect(() => {
    setCurrentSelection(buttonItemsArray);
  }, [buttonItemsArray]);

  useEffect(() => {
    onRef?.(componentRef);

    return () => {
      if (field.name) {
        currentSelection.forEach((currentButton) => {
          currentButton.isSelected = false;
        });
      }
    };
  }, []);

  useEffect(() => {
    if (isOptionsMinimized && expandOptionsByDefault) {
      setIsOptionsMinimized(false);
    }
  }, [expandOptionsByDefault]);

  const clearSelection = () => {
    const nextSelection = _.clone(currentSelection);
    nextSelection.forEach((currentButton) => (currentButton.isSelected = false));
    form.setFieldValue(field.name, undefined);
    setCurrentSelectedIndex(undefined);
  };

  const componentRef = useRef({
    clearSelection
  });

  const buttonsTransition = useTrail(currentSelection.length, {
    config,
    from: { opacity: 0, height: 0 },
    to: { opacity: 1, height: size ? size : 100 }
  });

  const didPressButton = (itemIndex: number) => {
    if (itemIndex === currentSelectedIndex) {
      return;
    }

    const nextSelection = _.clone(currentSelection);
    let selectionValue;

    nextSelection.forEach((currentButton, index) => {
      if (index === itemIndex) {
        currentButton.isSelected = true;
        selectionValue = currentButton.value;
      } else {
        currentButton.isSelected = false;
      }
    });
    setCurrentSelection(nextSelection);
    setCurrentSelectedIndex(itemIndex);
    if (onSelect) {
      onSelect(selectionValue, itemIndex);
    }
    if (field.name && allowSetFieldValue) {
      form.setFieldValue(field.name, selectionValue);
    }
  };

  const imageButtons = buttonsTransition.map(({ ...rest }, index) => {
    if (isOptionsMinimized && buttonItemsArray[index]?.isMinimized) {
      return <Fragment key={"IMB" + index}></Fragment>;
    }

    return (
      <div
        key={"IMB" + index}
        style={{
          marginTop: 20
        }}
      >
        <animated.div key={"uniqueId" + index} data-cy={dataCy || field.name} style={hideAnimation ? {} : { ...rest }}>
          <ImageButton
            label={buttonItemsArray[index].label}
            icon={buttonItemsArray[index].icon}
            dataCy={buttonItemsArray[index].dataCy}
            onPress={didPressButton}
            value={buttonItemsArray[index].value}
            isSelected={fieldValue ? buttonItemsArray[index].value === fieldValue : buttonItemsArray[index].isSelected}
            itemIndex={index}
            size={size}
            fontSize={fontSize}
            labelMaxWidth={labelMaxWidth}
            width={widthItem}
            borderRadius={buttonItemsArray[index].borderRadius}
            iconPathColor={buttonItemsArray[index].iconPathColor}
          />
        </animated.div>
      </div>
    );
  });

  const handleExpandOptions = () => {
    setIsOptionsMinimized(false);
  };

  return (
    <div style={{ alignItems: flexDirection || "center" }} className={classes.buttonGroupContainer}>
      <div
        style={{
          justifyContent: flexDirection || "center",
          flexWrap: noWrap ? "nowrap" : "wrap",
          columnGap: "20px"
        }}
        className={`${classes.buttonGroupItems} ${hasGridStyle ? "image-button-grid" : ""}`}
      >
        {imageButtons}
      </div>
      <br />
      {hasGridStyle && isOptionsMinimized && (
        <Link onClick={handleExpandOptions}>{t("common.expandOptionsLinkText")}</Link>
      )}
      {showValidationIndicator && (
        <ValidationIndicator
          shouldShow={hasError || false}
          maxWidth={maxWidth || 650}
          marginTop={"0px"}
          displayText={errorText || ""}
          justifyContent={validationIndicatorJustifyContent || "flex-start"}
        />
      )}
    </div>
  );
};

export default FormikImageButtonGroup;
