import MomentUtils from "@date-io/moment";
import type { SelectProps } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import type { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import moment from "moment";
import React, { useMemo } from "react";
import { PeriodSelectionOptions } from "../periodSelectionOptions";
import "../styles.css";
import { useTranslation } from "react-i18next";
import type { PeriodType } from "../datePicker.types";
import noop from "lodash/noop";
import type { DateRangePickerSize, DateRangePickerVariant } from "./types";
import { DatePickerSelectInput } from "./components/datePickerSelectInput";
import { DateRangePickerFields } from "./components/dateRangePickerFields";
import { DateRangeFieldsFlat } from "./components/datePickerFieldsFlat";

export interface DateRangePickerProps {
  size?: DateRangePickerSize;
  panelTitle?: string;
  defaultPeriod: PeriodType;
  uniqueKey: string;
  pickerPeriodFiledName?: string;
  onStartDateChange?: (value: MaterialUiPickersDate | null) => void;
  onEndDateChange?: (value: MaterialUiPickersDate | null) => void;
  onPeriodChange?: (value: PeriodType) => void;
  dataCy?: string;
  maxWidth?: number;
  showPickerFields?: boolean;
  variant?: DateRangePickerVariant;
  hidePeriodField?: boolean;
  customPeriods?: PeriodType[];
  dateBoundary?: {
    startMaxDate?: string;
    startMinDate?: string;
    endMaxDate?: string;
    endMinDate?: string;
  };
  startDateValue: string | null;
  endDateValue: string | null;
  selectedPeriod: string | null;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  panelTitle,
  onStartDateChange = noop,
  onEndDateChange = noop,
  onPeriodChange = noop,
  uniqueKey,
  dataCy,
  maxWidth,
  variant,
  showPickerFields = true,
  hidePeriodField = false,
  customPeriods,
  size,
  dateBoundary,
  startDateValue,
  endDateValue,
  selectedPeriod
}) => {
  const { t } = useTranslation();

  const formattedStartDate = startDateValue ? moment(startDateValue) : null;
  const formattedEndDate = endDateValue ? moment(endDateValue) : null;
  const formattedSelectedPeriod = selectedPeriod as PeriodType;
  const handleChangePeriod: SelectProps["onChange"] = (event) => {
    onPeriodChange(event.target.value as PeriodType);
  };

  const selectionEnum = useMemo(() => {
    let options = PeriodSelectionOptions;

    if (customPeriods && customPeriods.length > 0) {
      options = options.filter((option) => customPeriods?.includes(option.key));
    }

    return options.reduce((acc, current) => {
      acc[current.key] = t(current.name);

      return acc;
    }, {});
  }, [customPeriods, t]);

  const shouldShowPickerFields = useMemo(() => {
    if (!showPickerFields) {
      return false;
    }

    if (variant === "showPickerFieldsOnCustom") {
      return formattedSelectedPeriod === "custom";
    }

    return true;
  }, [showPickerFields, variant, formattedSelectedPeriod]);

  if (size === "full-width") {
    return (
      <DateRangeFieldsFlat
        selectionEnum={selectionEnum}
        formattedSelectedPeriod={formattedSelectedPeriod}
        onChange={handleChangePeriod}
        showPickerFields={shouldShowPickerFields}
        hidePeriodField={hidePeriodField}
        uniqueKey={uniqueKey}
        startDateOnChange={onStartDateChange}
        startDatePickerValue={formattedStartDate}
        endDateOnChange={onEndDateChange}
        endDatePickerValue={formattedEndDate}
        {...dateBoundary}
      />
    );
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      {shouldShowPickerFields ? (
        <View className={"DLUI_dateRangePicker"} width={"100%"} maxWidth={maxWidth ?? 700} gap={10} data-cy={dataCy}>
          {panelTitle && <Text value={panelTitle} fontSize={14} bold />}

          <Grid container spacing={1} alignItems={"flex-start"}>
            {!hidePeriodField && (
              <Grid xs={12} item>
                <DatePickerSelectInput
                  selectionEnum={selectionEnum}
                  formattedSelectedPeriod={formattedSelectedPeriod}
                  onChange={handleChangePeriod}
                />
              </Grid>
            )}
            <DateRangePickerFields
              uniqueKey={uniqueKey}
              startDateOnChange={onStartDateChange}
              startDatePickerValue={formattedStartDate}
              endDateOnChange={onEndDateChange}
              endDatePickerValue={formattedEndDate}
              {...dateBoundary}
            />
          </Grid>
        </View>
      ) : (
        <>
          <div data-cy={dataCy}>
            <DatePickerSelectInput
              selectionEnum={selectionEnum}
              formattedSelectedPeriod={formattedSelectedPeriod}
              onChange={handleChangePeriod}
            />
          </div>
        </>
      )}
    </MuiPickersUtilsProvider>
  );
};
