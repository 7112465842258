import type { CSSProperties } from "react";
import React, { useMemo } from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useTranslation } from "react-i18next";
import type { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { CalendarIconLight } from "assets/icons";
import clsx from "clsx";
import "./styles.css";
import { useSelector } from "react-redux";
import type { IRootState } from "../../../../store";
import { Icon } from "DLUI/icon";
import { fieldSizesMap, useStylesTextInput } from "DLUI/form/textField/textInput";
import { FieldSizes } from "DLUI/form/textField/types";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { getCleanUnicodeString } from "utils/stringsConverter";
import type { InputBaseComponentProps } from "@material-ui/core";

export interface DatePickerProps {
  label: string;
  uniqueKey: string;
  style?: CSSProperties;
  marginTop?: number;
  onChange?: (selectedDate: MaterialUiPickersDate | null) => void;
  disabled?: boolean;
  maxDate?: Date;
  minDate?: Date;
  pickerValue: MaterialUiPickersDate | null;
  dataCy?: string;
  required?: boolean;
  inputProps?: InputBaseComponentProps;
}

const DatePicker: React.FC<DatePickerProps> = ({
  label,
  uniqueKey,
  style,
  marginTop,
  onChange,
  disabled,
  inputProps,
  maxDate,
  minDate,
  pickerValue,
  dataCy,
  required
}) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveHelper();

  const classes = useStylesTextInput();
  const deviceClassName = clsx(["DL_DatePicker", isMobile ? "MOBILE" : "WEB"]);

  const _fieldSize: FieldSizes = useMemo(() => (isMobile ? FieldSizes.MOBILE : FieldSizes.WEB), [isMobile]);
  const inputPropsStyle = fieldSizesMap[_fieldSize].InputProps.style;
  const inputLabelPropsStyle = fieldSizesMap[_fieldSize].InputLabelProps.style;

  const localeData = useSelector((state: IRootState) => state.auth.localeData);
  const dateFormat = getCleanUnicodeString(localeData.dateFormat);

  const _onChange = (selectedDate: MaterialUiPickersDate | null) => {
    if (onChange && selectedDate !== null) {
      onChange(selectedDate);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        marginTop: marginTop || 0
      }}
      className={clsx(["DL_DatePicker", "noMargin"])}
    >
      <KeyboardDatePicker
        required={required}
        allowKeyboardControl
        inputVariant={"outlined"}
        variant="inline"
        format={dateFormat}
        margin={isMobile ? undefined : "dense"}
        autoOk
        data-cy={dataCy}
        id={"date-picker" + uniqueKey}
        label={t(label)}
        value={pickerValue}
        views={["year", "month", "date"]}
        onChange={_onChange}
        KeyboardButtonProps={{
          "aria-label": t(label)
        }}
        className={classes.root}
        InputLabelProps={{ className: clsx([deviceClassName, classes.label]), style: inputLabelPropsStyle }}
        InputProps={{ className: deviceClassName, style: inputPropsStyle, inputProps }}
        style={{
          width: "100%",
          ...style
        }}
        keyboardIcon={<Icon Source={CalendarIconLight} pathColor={"light-gray"} size={isMobile ? 20 : 16} />}
        disabled={disabled}
        maxDate={maxDate}
        minDate={minDate}
      />
    </div>
  );
};

export default DatePicker;
