"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalApplicationAboutInfoDto = exports.RentalApplicationAboutInfoPersonalInfo = void 0;
const class_validator_1 = require("class-validator");
const common_1 = require("../common");
const isDateStringWithoutTime_validator_1 = require("../common/validators/isDateStringWithoutTime.validator");
const gender_enum_1 = require("../common/gender.enum");
const isITIN_validator_1 = require("../common/validators/isITIN.validator");
class RentalApplicationAboutInfoPersonalInfo {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.RentalApplicationAboutInfoPersonalInfo = RentalApplicationAboutInfoPersonalInfo;
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.Matches)(new RegExp("^[A-Za-z- ]+$")),
    (0, class_validator_1.MaxLength)(15),
    __metadata("design:type", String)
], RentalApplicationAboutInfoPersonalInfo.prototype, "firstName", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.Matches)(new RegExp("^[A-Za-z- ]+$")),
    (0, class_validator_1.MaxLength)(15),
    __metadata("design:type", String)
], RentalApplicationAboutInfoPersonalInfo.prototype, "middleName", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", Boolean)
], RentalApplicationAboutInfoPersonalInfo.prototype, "noMiddleName", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.Matches)(new RegExp("^[A-Za-z- ]+$")),
    (0, class_validator_1.MaxLength)(25),
    __metadata("design:type", String)
], RentalApplicationAboutInfoPersonalInfo.prototype, "lastName", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsPhoneNumber)("US"),
    (0, class_validator_1.Length)(5, 20),
    __metadata("design:type", String)
], RentalApplicationAboutInfoPersonalInfo.prototype, "mobileNumber", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEmail)(),
    __metadata("design:type", String)
], RentalApplicationAboutInfoPersonalInfo.prototype, "emailAddress", void 0);
class RentalApplicationAboutInfoDto extends RentalApplicationAboutInfoPersonalInfo {
    constructor(init) {
        super();
        Object.assign(this, init);
    }
}
exports.RentalApplicationAboutInfoDto = RentalApplicationAboutInfoDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, common_1.IsSSN)({
        message: "Invalid SSN. Format: ###-##-####"
    }),
    __metadata("design:type", String)
], RentalApplicationAboutInfoDto.prototype, "ssn", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isITIN_validator_1.IsITIN)({
        message: "Invalid ITIN. Format: ###-##-####"
    }),
    __metadata("design:type", String)
], RentalApplicationAboutInfoDto.prototype, "itin", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(gender_enum_1.Gender),
    __metadata("design:type", String)
], RentalApplicationAboutInfoDto.prototype, "gender", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], RentalApplicationAboutInfoDto.prototype, "isNoSsn", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], RentalApplicationAboutInfoDto.prototype, "driversLicenseNumber", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], RentalApplicationAboutInfoDto.prototype, "driversLicenseState", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(common_1.MaritalStatus),
    __metadata("design:type", String)
], RentalApplicationAboutInfoDto.prototype, "maritalStatus", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, isDateStringWithoutTime_validator_1.IsDateStringWithoutTime)(),
    __metadata("design:type", String)
], RentalApplicationAboutInfoDto.prototype, "dateOfBirth", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(950, {
        message: "Field must contain less than 950 characters"
    }),
    __metadata("design:type", String)
], RentalApplicationAboutInfoDto.prototype, "bio", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], RentalApplicationAboutInfoDto.prototype, "stepCompleted", void 0);
