import type { ComponentType } from "react";
import React from "react";

import { Field, getIn, useFormikContext } from "formik";
import Text from "DLUI/text";
import { TextField } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import { View } from "DLUI/view";
import AddressAutoCompleteFormWrapper from "DLUI/form/autoComplete/addressAutoComplete/addressAutoCompleteFormWrapper";
import { LoadingAnimation } from "DLUI/animations/loadingAnimation";
import { FormikSwitchButtonRow } from "DLUI/form/switchButton/FormikSwitchButtonRow";
import { SettingsBlock } from "screens/settings/tenantPortal/components/settingsBlock";
import { useTypedTranslation } from "locale";
import { TextTooltip } from "DLUI/popover/textTooltip";
import type { PhoneNumberDto } from "@doorloop/dto";
import ColorsEnum from "utils/colorsEnum";
import { useResponsiveHelper } from "contexts/responsiveContext";

interface ComponentProps {
  SpecifyRadioButtons?: ComponentType;
  allowEdit?: boolean;
  doorLoopPhoneNumber?: PhoneNumberDto | undefined | null;
  formikFieldNamePrefix?: string;
  isLoading?: boolean;
  textTitle: string;
}

export const TenantPortalContactInfoForm: React.FC<ComponentProps> = ({
  textTitle,
  formikFieldNamePrefix = "contactInfo",
  allowEdit = true,
  SpecifyRadioButtons,
  isLoading = false,
  doorLoopPhoneNumber
}: ComponentProps) => {
  const { t } = useTypedTranslation();

  const formik = useFormikContext();
  const { isMobile } = useResponsiveHelper();

  const showAllUncollapsed = getIn(formik.values, `${formikFieldNamePrefix}.showAll`);
  const hideEmail = getIn(formik.values, `${formikFieldNamePrefix}.isEmailShownInPortal`);
  const hidePhone = getIn(formik.values, `${formikFieldNamePrefix}.isPhoneShownInPortal`);
  const hideAddress = getIn(formik.values, `${formikFieldNamePrefix}.isAddressShownInPortal`);

  return (
    <View width={"100%"} flex={1}>
      <View>
        <Text color={"black"} fontSize={16} value={textTitle} />
        <SeparationLine
          marginLeft={16}
          marginTop={20}
          width={isMobile ? "90%" : "95%"}
          height={2}
          color={ColorsEnum.LightGray}
        />
        {SpecifyRadioButtons && <SpecifyRadioButtons />}
        {isLoading ? (
          <View justifyContent={"center"} alignItems={"center"} marginTop={"30%"}>
            <LoadingAnimation type={"spinner"} />
          </View>
        ) : (
          <View shouldShow={true} showAnimation={"fade-in"} hideAnimation={"fade-out"} marginTop={10}>
            <View noWrap>
              <SettingsBlock marginBottom={10}>
                <View
                  paddingLeft={16}
                  paddingRight={16}
                  style={{
                    // flexDirection: "row-reverse",
                    justifyContent: "space-between"
                  }}
                >
                  <Text value={t("auth.companyName")} fontSize={16} fontWeight={700} marginTop={20} marginBottom={16} />
                  <Field
                    component={TextField}
                    label={t("auth.companyName")}
                    name={`${formikFieldNamePrefix}.companyName`}
                    disabled={!allowEdit}
                    marginTop={8}
                  />
                </View>
                <SeparationLine marginLeft={16} marginTop={20} width={isMobile ? "90%" : "95%"} height={1} />
                <View
                  paddingLeft={16}
                  paddingRight={16}
                  style={{
                    flexDirection: "row-reverse",
                    justifyContent: "space-between"
                  }}
                >
                  <TextTooltip
                    placement={"bottom-end"}
                    value={t("common.tenantPortalSettingsSections.showHideToggle")}
                    useButton={false}
                    containerStyle={{ marginRight: -22 }}
                  >
                    <Field
                      component={FormikSwitchButtonRow}
                      name={`${formikFieldNamePrefix}.isEmailShownInPortal`}
                      disabled={!allowEdit}
                    />
                  </TextTooltip>
                  <Text
                    value={t("common.tenantPortalSettingsSections.companyEmail")}
                    fontSize={16}
                    fontWeight={700}
                    marginTop={20}
                  />
                  {(showAllUncollapsed || hideEmail) && (
                    <Field
                      component={TextField}
                      label={t("common.tenantPortalSettingsSections.email")}
                      name={`${formikFieldNamePrefix}.email`}
                      disabled={!allowEdit}
                      marginTop={8}
                    />
                  )}
                </View>
                <SeparationLine marginLeft={16} marginTop={20} width={isMobile ? "90%" : "95%"} height={1} />

                <View
                  paddingLeft={16}
                  paddingRight={16}
                  style={{
                    flexDirection: "row-reverse",
                    justifyContent: "space-between"
                  }}
                >
                  <TextTooltip
                    placement={"bottom-end"}
                    value={t("common.tenantPortalSettingsSections.showHideToggle")}
                    useButton={false}
                    containerStyle={{ marginRight: -22 }}
                  >
                    <Field
                      component={FormikSwitchButtonRow}
                      name={`${formikFieldNamePrefix}.isPhoneShownInPortal`}
                      disabled={!allowEdit}
                      marginTop={8}
                    />
                  </TextTooltip>
                  <Text
                    value={t("common.tenantPortalSettingsSections.phoneNumberAllCapitalized")}
                    fontSize={16}
                    fontWeight={700}
                    marginTop={20}
                  />
                  {(showAllUncollapsed || hidePhone) && (
                    <Field
                      component={TextField}
                      label={
                        doorLoopPhoneNumber
                          ? t("common.tenantPortalSettingsSections.doorloopPhoneNumber")
                          : t("common.tenantPortalSettingsSections.phone")
                      }
                      name={`${formikFieldNamePrefix}.phone`}
                      disabled={!allowEdit || doorLoopPhoneNumber}
                      marginTop={8}
                    />
                  )}
                </View>

                <SeparationLine marginLeft={16} marginTop={20} width={isMobile ? "90%" : "95%"} height={1} />

                <View
                  paddingLeft={16}
                  paddingRight={16}
                  paddingBottom={16}
                  style={{
                    flexDirection: "row-reverse",
                    justifyContent: "space-between"
                  }}
                >
                  <TextTooltip
                    placement={"bottom-end"}
                    value={t("common.tenantPortalSettingsSections.showHideToggle")}
                    useButton={false}
                    containerStyle={{ marginRight: -22 }}
                  >
                    <Field
                      component={FormikSwitchButtonRow}
                      name={`${formikFieldNamePrefix}.isAddressShownInPortal`}
                      disabled={!allowEdit}
                    />
                  </TextTooltip>
                  <Text
                    value={t("common.tenantPortalSettingsSections.companyAddress")}
                    fontSize={16}
                    fontWeight={700}
                    marginTop={20}
                  />
                  {(showAllUncollapsed || hideAddress) && (
                    <View marginBottom={10} marginTop={-10}>
                      <AddressAutoCompleteFormWrapper
                        withAddressVerification
                        mustAcceptInvalidAddress
                        formikName={`${formikFieldNamePrefix}.address`}
                        disabled={!allowEdit}
                      />
                    </View>
                  )}
                </View>
              </SettingsBlock>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};
