import React from "react";
import type { FC, ReactNode } from "react";
import { Grid } from "@material-ui/core";
import type { FillColors } from "DLUI/icon";
import { Icon } from "DLUI/icon";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { ViewBackgroundColor } from "DLUI/view/view";
import type { SVGIconComponent } from "@/assets/icons/types";
import type { TextColor, TextComponentProps } from "DLUI/text/text";
import { SeparationLine } from "DLUI/separatorView";
import ColorsEnum from "@/utils/colorsEnum";
import { useResponsiveHelper } from "@/contexts/responsiveContext";
import loadingDotsLottie from "assets/lottie/loadingDotsLottie.json";
import { Lottie } from "@/components/DLUI/lottie";

interface ComponentProps {
  title: string;
  titleTextTransform?: TextComponentProps["textTransform"];
  notificationText: string;
  RightLabel?: FC;
  icon: SVGIconComponent;
  iconPathColor?: FillColors;
  image?: ReactNode;
  backgroundColor?: ViewBackgroundColor;
  hideBoxShadow?: boolean;
  marginTop?: number;
  renderActionPanel?: () => JSX.Element;
  borderRadius?: number;
  border?: string;
  minHeight?: number;
  viewHeight?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  gap?: number;
  textColor?: TextColor;
  styleOverride?: React.CSSProperties;
  separationLine?: boolean;
  dataCy?: string;
  isLoading?: boolean;
}

export const TenantPortalNotificationPanel: React.FC<ComponentProps> = ({
  title,
  titleTextTransform = "uppercase",
  notificationText,
  RightLabel,
  icon,
  iconPathColor,
  image,
  backgroundColor,
  hideBoxShadow,
  marginTop,
  renderActionPanel,
  borderRadius,
  border,
  paddingTop,
  paddingLeft = 20,
  paddingRight = 20,
  gap,
  minHeight,
  viewHeight,
  textColor,
  styleOverride = {},
  separationLine,
  dataCy,
  isLoading = false
}: ComponentProps) => {
  const { isMobile } = useResponsiveHelper();
  const hideActionPanel = renderActionPanel === undefined;
  const viewMinHeight = viewHeight || minHeight || 130;

  const hasBackgroundOverride = Boolean(styleOverride?.background || styleOverride?.backgroundColor);

  if (isLoading)
    return (
      <View height={"100%"} justifyContent={"center"} alignItems={"center"}>
        <Lottie loop animationData={loadingDotsLottie} width={50} height={50} />
      </View>
    );
  return (
    <View
      marginTop={marginTop || 0}
      minHeight={viewMinHeight}
      paddingTop={paddingTop}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      backgroundColor={hasBackgroundOverride ? undefined : backgroundColor || "yellow-light"}
      borderRadius={borderRadius || 8}
      justifyContent={"center"}
      style={{
        boxShadow: hideBoxShadow ? "" : "0px 4px 5px rgba(0, 0, 0, 0.05)",
        border: border ? border : "",
        ...styleOverride
      }}
      dataCy={dataCy}
    >
      <View
        alignItems={"center"}
        flexDirection={"row"}
        paddingLeft={paddingLeft}
        paddingRight={paddingRight}
        noWrap={!isMobile}
        gap={gap}
      >
        <Grid style={{ minHeight }} container justify={"center"} item lg={1} sm={1} xs={12}>
          {image || (
            <View alignItems={"center"} justifyContent={"center"} minHeight={80}>
              <Icon width={45} height={45} pathColor={iconPathColor || "yellow"} Source={icon} />
            </View>
          )}
        </Grid>
        <Grid style={{ minHeight }} container item lg={hideActionPanel ? 11 : 7} sm={hideActionPanel ? 10 : 6} xs={12}>
          <View
            minHeight={minHeight}
            justifyContent={"center"}
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            alignItems={isMobile ? "center" : "flex-start"}
          >
            <View flexDirection={"row"}>
              <View flex={1} alignItems={RightLabel || !isMobile ? "flex-start" : "center"}>
                <Text
                  textTransform={titleTextTransform}
                  fontSize={16}
                  fontWeight={700}
                  value={title}
                  align={RightLabel || !isMobile ? "left" : "center"}
                  color={textColor}
                />
              </View>
              {RightLabel && <RightLabel />}
            </View>
            {separationLine && (
              <SeparationLine width={"100%"} height={1} marginTop={10} color={ColorsEnum.LightBlueFaded} />
            )}
            <Text
              marginTop={4}
              fontSize={14}
              lineHeight={"22px"}
              value={notificationText}
              align={isMobile ? "center" : "left"}
              color={textColor}
            />
          </View>
        </Grid>
        {hideActionPanel ? null : (
          <Grid style={{ minHeight }} container justify={"center"} item lg={5} sm={5} xs={12}>
            <View
              justifyContent={"center"}
              alignItems={isMobile ? "center" : "flex-end"}
              paddingLeft={paddingLeft}
              paddingRight={paddingRight}
              marginTop={isMobile ? 20 : 0}
            >
              {renderActionPanel ? renderActionPanel() : null}
            </View>
          </Grid>
        )}
      </View>
      {isMobile ? <View height={20} /> : null}
    </View>
  );
};

export default TenantPortalNotificationPanel;
