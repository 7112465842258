import DLButton from "DLUI/button/dlButton";
import EmptyDataView from "DLUI/emptyDataView";
import AppStrings from "locale/keys";
import React from "react";
import { useLocation } from "react-router-dom";
import { NavigationManager } from "utils/navigation";

const BankAccountsListEmptyDataView = () => {
  const location = useLocation<any>();
  const didPressAddNewAccount = () => {
    NavigationManager.newAccount(location.pathname);
  };

  return (
    <EmptyDataView
      instructionsText={AppStrings.Common.BankAccountsEmptyDataViewInstructionsText}
      actionButton={
        <DLButton
          actionText={AppStrings.Accounts.ChartOfAccounts.NewAccount}
          onClick={didPressAddNewAccount}
          disableMobileCollapse
          icons={{ start: { isHidden: false } }}
        />
      }
    />
  );
};

export default BankAccountsListEmptyDataView;
