"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyAvailableFundsReportItem = void 0;
const mathUtils_1 = require("../../utils/mathUtils");
class PropertyAvailableFundsReportItem {
    constructor(property, propertyOperatingAcount, bankBalance, propertyReserve, depositsHeld) {
        this.property = property;
        this.cashBalance = bankBalance;
        this.propertyReserve = propertyReserve;
        this.depositsHeld = depositsHeld;
        this.propertyOperatingAccount = propertyOperatingAcount;
        this.availableFunds = mathUtils_1.mathUtils.substract(this.cashBalance, this.depositsHeld);
    }
    getAvailableFundsIncludingPropertyReserve() {
        return this.cashBalance - this.depositsHeld;
    }
    getAvailableFundsExcludingPropertyReserve() {
        return this.cashBalance - this.propertyReserve - this.depositsHeld;
    }
}
exports.PropertyAvailableFundsReportItem = PropertyAvailableFundsReportItem;
