"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthRedirectType = void 0;
var AuthRedirectType;
(function (AuthRedirectType) {
    AuthRedirectType["AcceptInviteTenant"] = "j";
    AuthRedirectType["LoginByLease"] = "l";
    AuthRedirectType["AcceptInviteOwner"] = "i";
    AuthRedirectType["LoginByOwner"] = "o";
    AuthRedirectType["AcceptInviteProspect"] = "p";
})(AuthRedirectType || (exports.AuthRedirectType = AuthRedirectType = {}));
