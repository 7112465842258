import React, { useState } from "react";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import AppStrings from "locale/keys";
import EditNameForm from "DLUI/dialogs/settings/leases/editNameDialog/editNameForm";
import { leasesApi } from "api/leasesApi";
import { useFormikContext } from "formik";
import type { LeaseDto } from "@doorloop/dto";
import { useTranslation } from "react-i18next";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";

interface ComponentProps {
  dialogTitle: string;
  onBackdropClick: () => void;
  onClose: () => void;
}

const DialogViews = {
  LoadingView: 0,
  DialogForm: 1
};

const EditLeaseName: React.FC<ComponentProps> = ({ dialogTitle, onBackdropClick, onClose }: ComponentProps) => {
  const formikRef = useFormikContext<LeaseDto>();
  const { t } = useTranslation();
  const [viewIndex, setViewIndex] = useState<number>(DialogViews.DialogForm);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");

  const renderView = ({ index }) => {
    if (index === DialogViews.DialogForm) {
      return <EditNameForm />;
    }
    if (index === DialogViews.LoadingView) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          errorText={loadingDialogErrorText}
          didPressDismissButton={() => setViewIndex(DialogViews.DialogForm)}
          onRetryButtonPress={didPressSaveButton}
        />
      );
    }
    return <div />;
  };

  const didPressSaveButton = async () => {
    if (formikRef.values && formikRef.values.id) {
      setLoadingDialogState(DialogState.Show);
      setViewIndex(DialogViews.LoadingView);
      const { status, message, data } = await leasesApi.update(formikRef.values.id, formikRef.values);
      if (status && data) {
        onClose();
      } else {
        setLoadingDialogErrorText(message || t(AppStrings.Common.GeneralError));
        setLoadingDialogState(DialogState.Error);
      }
    }
  };
  const isLoadingView = viewIndex === DialogViews.LoadingView;
  const frameType = isLoadingView ? "contentOnly" : "sectionTitleFrame";

  const renderActionPanelButtons = () =>
    viewIndex === DialogViews.LoadingView ? null : (
      <FormActionButtons
        propsSubButton={{ onClick: onBackdropClick }}
        propsMainButton={{ type: "cta", props: { onClick: didPressSaveButton } }}
      />
    );

  return (
    <DialogFrame
      onCloseButtonClick={onBackdropClick}
      title={dialogTitle}
      width={Math.min(window.innerWidth, 790)}
      height={Math.min(window.innerHeight - 80, 515)}
      renderView={renderView}
      numViews={2}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={frameType}
    />
  );
};

export default EditLeaseName;
