"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.retryFn = void 0;
const sleep_1 = require("./sleep");
/**
 * @example retryFn(() => doSomething(), {
 *   max: 5,
 *   delay: 1000,
 *   delayWithRandomness: true,
 *   increaseDelayEachTime: true,
 *   shouldKeepRetryFn: (error) => error instanceof SomeError,
 *   logBetweenFn: (index) => console.log(`Retrying ${index} of 5 times.`)
 * });
 */
const retryFn = (fn_1, _a) => __awaiter(void 0, [fn_1, _a], void 0, function* (fn, { max, delay, delayWithRandomness, increaseDelayEachTime, shouldKeepRetryFn, logBetweenFn }) {
    for (let index = 0; index < max; index++) {
        try {
            return yield fn();
        }
        catch (error) {
            // Got to max retries.
            if (index === max - 1) {
                throw error;
            }
            if (shouldKeepRetryFn && !shouldKeepRetryFn(error, index)) {
                // Stop retrying.
                throw error;
            }
            logBetweenFn === null || logBetweenFn === void 0 ? void 0 : logBetweenFn(index, error);
            if (delay) {
                let delayTime = increaseDelayEachTime ? delay * (index + 1) : delay;
                if (delayWithRandomness) {
                    delayTime = Math.floor(Math.random() * delayTime + delayTime);
                }
                yield (0, sleep_1.sleep)(delayTime);
            }
        }
    }
    return yield fn();
});
exports.retryFn = retryFn;
