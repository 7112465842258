import React, { useCallback } from "react";

import { getIn, useFormikContext } from "formik";
import { ReportSettingsRadioGroup } from "./reportSettingsRadioGroup";
import { View } from "DLUI/view";
import { useResponsiveHelper } from "../../../../contexts/responsiveContext";
import { ControlledCheckBox } from "DLUI/form";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";

interface ComponentProps {
  ternary?: boolean;
  label?: string;
  fieldName: string;
  applyDependency?: (newValue: any) => void;
  fontSize?: number;
  fontWeight?: number;
}

export const ReportSettingsRadioToggle: React.FC<ComponentProps> = React.memo(
  ({ ternary, label, fieldName, applyDependency, fontSize, fontWeight }: ComponentProps) => {
    const { isMobile } = useResponsiveHelper();

    const formikRef = useFormikContext();

    const currentValue = getIn(formikRef.values, fieldName);

    const onChange = useCallback((newValue: boolean | string) => {
      formikRef.setFieldValue(fieldName, newValue);

      applyDependency?.(newValue);
    }, []);

    const textColor = currentValue ? "black" : "secondary-gray";

    return (
      <View flex={1} alignItems={isMobile ? "flex-start" : "center"} justifyContent={"center"}>
        {ternary ? (
          <ReportSettingsRadioGroup
            label={label || ""}
            name={fieldName}
            currentValue={currentValue}
            onChange={onChange}
          />
        ) : (
          <FastFieldSafe
            onChange={onChange}
            value={currentValue}
            component={ControlledCheckBox}
            name={fieldName}
            checked={Boolean(currentValue)}
            labelValueProps={{
              text: label,
              color: textColor
            }}
          />
        )}
      </View>
    );
  }
);
