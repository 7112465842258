import React, { useEffect, useState } from "react";
import { PaymentAllocationsSettingsDto } from "@doorloop/dto";
import Grid from "@material-ui/core/Grid";
import { FormikSwitchButton, RadioGroup, TextField } from "DLUI/form";
import { SeparationLine } from "DLUI/separatorView";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField } from "formik";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import { DialogsHelper } from "DLUI/dialogs/dialogsHelper";

interface ComponentProps {
  formikRef: FormikProps<any>;
  initialValue: "companyDefault" | "specifyFees";
  specifyPaymentAllocationText?: string;
}
const PaymentAllocationForm: React.FC<ComponentProps> = ({
  initialValue,
  formikRef,
  specifyPaymentAllocationText
}: ComponentProps) => {
  const { t } = useTranslation();
  const { dialogHorizontalPadding } = DialogsHelper();
  const [paymentAllocationSelectionType, setPaymentAllocationSelectionType] = useState<
    "companyDefault" | "specifyFees"
  >("companyDefault");

  useEffect(() => {
    setPaymentAllocationSelectionType(initialValue);
  }, [initialValue]);

  const didChangePaymentAllocationSelection = (selectedValue: string) => {
    if (formikRef !== null) {
      if (selectedValue === "false") {
        formikRef.setFieldValue("settings.paymentAllocations", undefined);
      } else {
        const paymentAllocationsSettings = new PaymentAllocationsSettingsDto();
        //paymentAllocationsSettings.sendEmailToTenantWhenChargesArePosted = true;
        formikRef.setFieldValue("settings.paymentAllocations", paymentAllocationsSettings);
      }
    }
    setPaymentAllocationSelectionType(selectedValue === "false" ? "companyDefault" : "specifyFees");
  };

  return (
    <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding}>
      <View alignItems={"flex-start"}>
        <Text
          value={AppStrings.Properties.Settings.PropertyPaymentsAllocationDescription}
          fontSize={16}
          color={"black"}
          maxWidth={550}
          lineHeight={"26px"}
        />
        <FastField
          uniqueKey={"LF"}
          component={RadioGroup}
          name={"automaticLateFees"}
          defaultValue={initialValue === "companyDefault" ? "false" : "true"}
          didChangeSelection={didChangePaymentAllocationSelection}
          radioGroupItems={[
            {
              label: AppStrings.Properties.Settings.UseCompanyDefaultPaymentAllocation,
              value: "false"
            },
            {
              label: specifyPaymentAllocationText || AppStrings.Properties.Settings.SpecifyPaymentAllocationForProperty,
              value: "true"
            }
          ]}
          marginTop={10}
        />
        <View
          shouldShow={paymentAllocationSelectionType === "specifyFees"}
          showAnimation={"fade-in"}
          hideAnimation={"fade-out"}
        >
          <View>
            <SeparationLine marginTop={20} width={"100%"} height={1} />
            <FastField
              component={FormikSwitchButton}
              name={"settings.paymentAllocations.sendEmailToTenantWhenChargesArePosted"}
              label={AppStrings.Properties.Settings.SendEmailToTenantWhenChargesArePosted}
              marginTop={20}
            />
            <Text
              align={"left"}
              value={AppStrings.Properties.Settings.NumOfDaysBeforeDueDateToPostRecurringTransactions}
              color={"black"}
              variant={"listItemTitle"}
              marginTop={20}
            />
            <View alignItems={"stretch"} marginTop={20}>
              <Grid item xs={12} md={6} lg={6}>
                <FastField
                  component={TextField}
                  label={t(AppStrings.Properties.Settings.NumOfDaysBeforeDueDateToPostRecurringTransactionsLabel)}
                  name={"settings.paymentAllocations.numOfDaysBeforeDueDateToPostRecurringTransactions"}
                  formatType={"number"}
                />
              </Grid>
              <Text
                color={"black"}
                fontSize={16}
                marginTop={20}
                value={AppStrings.Properties.Settings.PaymentInstructionsDescription}
              />
              <FastField
                component={TextField}
                label={t(AppStrings.Properties.Settings.PaymentInstructionsLabel)}
                name={`settings.paymentAllocations.paymentInstructionsToIncludeInStatement`}
                multiline
                rows="4"
                variant="outlined"
                marginTop={20}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default PaymentAllocationForm;
