const Budgets = {
  Form: {
    TooltipBulkActionClearData: "budgets.form.tooltipBulkActionClearData",
    TooltipBulkActionFillReferenceData: "budgets.form.tooltipBulkActionFillReferenceData",
    TooltipBulkActionFillTotal: "budgets.form.tooltipBulkActionFillTotal",
    DisabledButtons: {
      FillWithReferenceData: "budgets.form.disabledButtons.fillWithReferenceData",
      CustomTotalAmount: "budgets.form.disabledButtons.customTotalAmount",
      ClearData: "budgets.form.disabledButtons.clearData"
    },
    NewBudget: "budgets.form.newBudget",
    EditBudget: "budgets.form.editBudget",
    BudgetName: "budgets.form.budgetName",
    ReferenceData: "budgets.form.referenceData",
    ShowReferenceData: "budgets.form.showReferenceData",
    BudgetTypes: {
      Actual: `budgets.form.budgetTypes.actual`,
      Budget: `budgets.form.budgetTypes.budget`
    },
    Errors: {
      NameExists: "budgets.form.errors.nameExists",
      ReferenceDataFetchError: "budgets.form.errors.referenceDataFetchError"
    },
    PeriodType: {
      Month: `budgets.form.periodType.month`,
      Quarter: `budgets.form.periodType.quarter`,
      Year: `budgets.form.periodType.year`
    },
    ResetFormConfirmationTitle: "budgets.form.resetFormConfirmationTitle",
    ResetFormConfirmationSubTitle: "budgets.form.resetFormConfirmationSubTitle",
    SubmitNotBalancedConfirmationTitle: "budgets.form.submitNotBalancedConfirmationTitle",
    SubmitNotBalancedConfirmationSubTitle: "budgets.form.submitNotBalancedConfirmationSubTitle"
  },
  Screen: {
    Title: "budgets.screen.title",
    CreateNew: "budgets.screen.createNew",
    SearchPlaceHolder: "budgets.screen.searchPlaceHolder",
    EmptyDataViewInstructionsText: "budgets.screen.emptyDataViewInstructionsText",
    EmptyDataViewInstructionsSubText: "budgets.screen.emptyDataViewInstructionsSubText",
    HelpPanel: {
      Description: "budgets.screen.helpPanel.description"
    }
  },
  ListItem: {
    Columns: {
      Name: "budgets.listItem.columns.name",
      Period: "budgets.listItem.columns.period",
      BudgetType: "budgets.listItem.columns.budgetType",
      LinkedEntity: "budgets.listItem.columns.linkedEntity"
    },
    Actions: {
      RunReport: "budgets.listItem.actions.runReport",
      Edit: "budgets.listItem.actions.edit",
      Duplicate: "budgets.listItem.actions.duplicate",
      Delete: "budgets.listItem.actions.delete"
    },
    DeleteConfirmationDialog: {
      SubTitle: "budgets.listItem.deleteConfirmationDialog.subTitle",
      ConfirmButton: "budgets.listItem.deleteConfirmationDialog.confirmButton"
    },
    LinkedType: {
      Property: `budgets.listItem.linkedType.property`,
      Portfolio: `budgets.listItem.linkedType.portfolio`
    }
  }
};

export default Budgets;
