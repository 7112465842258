import type { AnalyticsMetadata, AnalyticsMetadataMapKeys } from "@doorloop/dto";
import { analyticsService } from "../services/analyticsService";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

export interface DispatchAnalyticsOptions {
  addPlatformLocation?: boolean;
  trackEventInIntercom?: boolean;
}

function dispatchAnalytics<T extends AnalyticsMetadataMapKeys>(
  name: T,
  data?: AnalyticsMetadata<T> | null,
  options?: DispatchAnalyticsOptions
): void {
  const metadata = data ?? ({} as AnalyticsMetadata<T>);
  const addPlatformLocation = options?.addPlatformLocation ?? true;
  const trackEventInIntercom = options?.trackEventInIntercom ?? false;

  if (addPlatformLocation) {
    metadata.platformLocation = analyticsService.splitUrl({ transformNameForAnalytics: true });
  }

  analyticsService.track(name, metadata, { trackEventInIntercom });
}

const startSessionRecordingWithoutFeatureFlag = (): void => {
  window.posthog?.startSessionRecording?.();
};

export const useAnalyticsService = () => {
  const { t } = useTranslation();

  const formatAppStringsValue = useCallback(
    <TTranslation extends string = string>(value?: TTranslation) => (value ? `${t(value)} (${value})` : ""),
    [t]
  );

  return {
    dispatchAnalytics,
    formatAppStringsValue,
    startSessionRecordingWithoutFeatureFlag
  };
};
