import type { FC } from "react";
import React from "react";
import { Grid } from "@material-ui/core";
import type { NotificationSettingsRowInterface } from "DLUI/dialogs/notifications/components/notificationSettingsContextWrapper";
import { Icon } from "DLUI/icon";

export interface NotificationSettingsTableRowProps {
  row: NotificationSettingsRowInterface[] | readonly NotificationSettingsRowInterface[];
}

export const NotificationSettingsTableRow: FC<NotificationSettingsTableRowProps> = ({ row }) => (
  <Grid container alignItems={"center"} style={{ height: "40px" }}>
    {row.map(({ Component, sm, icon }: NotificationSettingsRowInterface, index) => (
      <Grid
        container={index !== 0}
        item
        key={index}
        sm={sm}
        justifyContent={index === 0 ? "center" : "flex-end"}
        alignItems={"center"}
      >
        {icon && <Icon marginTop={2} Source={icon} size={16} marginRight={4} />}
        <Component />
      </Grid>
    ))}
  </Grid>
);
