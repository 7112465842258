import React, { useMemo, useState } from "react";

import _ from "lodash";
import { CircularProgress } from "@material-ui/core";
import type { FormikProps } from "formik";
import { getIn } from "formik";
import { useTranslation } from "react-i18next";

import { PortalStatus, ResendPortalInvitationDto } from "@doorloop/dto";

import AppStrings from "locale/keys";
import Text from "DLUI/text";
import type { ApiResult } from "api/apiResult";
import { CheckmarkCircleIcon, CloseCircleIcon } from "assets/icons";
import { Icon } from "DLUI/icon";
import { Link } from "DLUI/link";
import type { PersonContactInfoRowPersonType } from "DLUI/person/personContactInfoRow";
import { View } from "DLUI/view";
import { portalInfoEmailPath } from "@/constants/person.constants";

interface PortalInviteStatusTextMap {
  accessActiveDescription: string;
  invitedDescription: string;
  accessActive: string;
}

const personTypeTextMap: Record<PersonContactInfoRowPersonType, PortalInviteStatusTextMap> = {
  tenant: {
    accessActiveDescription: AppStrings.Common.TenantPortalAccessActiveDescription,
    invitedDescription: AppStrings.Common.TenantPortalInvitedDescription,
    accessActive: AppStrings.Common.TenantPortalAccessActive
  },
  owner: {
    accessActiveDescription: AppStrings.Common.OwnerPortalAccessActiveDescription,
    invitedDescription: AppStrings.Common.OwnerPortalInvitedDescription,
    accessActive: AppStrings.Common.OwnerPortalAccessActive
  }
};

interface ComponentProps {
  formikRef: FormikProps<any>;
  resendPortalInviteApi: (dto: ResendPortalInvitationDto) => Promise<ApiResult<any>>;
  personType: PersonContactInfoRowPersonType;
}

type PortalInviteStatusType = "notSent" | "sent" | "failed";

export const PortalInviteStatus: React.FC<ComponentProps> = ({
  formikRef,
  resendPortalInviteApi,
  personType
}: ComponentProps) => {
  const [isResendInviteInProgress, setIsResendInviteInProgress] = useState<boolean>(false);
  const [resendInviteStatus, setResendInviteStatus] = useState<PortalInviteStatusType>("notSent");

  const [errorText, setErrorText] = useState<string | null>(null);

  const formInitialValues = useMemo(() => formikRef.values, []);

  const { t } = useTranslation();

  const textMap = personTypeTextMap[personType];

  const renderTitleIndicator = () => {
    if (isResendInviteInProgress) {
      return (
        <CircularProgress
          style={{
            width: 20,
            height: 20,
            marginLeft: 10,
            marginTop: 10,
            marginRight: 10,
            color: "#7A8699"
          }}
        />
      );
    }

    if (resendInviteStatus === "sent") {
      return <Icon marginRight={10} marginTop={12} Source={CheckmarkCircleIcon} width={15} height={15} />;
    }

    if (resendInviteStatus === "failed") {
      return <Icon marginTop={12} marginRight={10} Source={CloseCircleIcon} width={15} height={15} />;
    }
  };

  const didPressResendInvite = async (): Promise<void> => {
    if (isResendInviteInProgress) {
      return;
    }

    const id = getIn(formikRef.values, "id");

    if (!id) {
      return;
    }

    const dto = new ResendPortalInvitationDto({
      id
    });

    setIsResendInviteInProgress(true);

    const result = await resendPortalInviteApi(dto);

    setIsResendInviteInProgress(false);

    if (result.status && result.data) {
      setResendInviteStatus("sent");
    } else {
      setResendInviteStatus("failed");
      setErrorText(result.message || AppStrings.Common.GeneralError);
    }
  };

  const renderDescription = (): JSX.Element | null => {
    let descriptionAppString = AppStrings.Common.NewPortalInviteDescription;

    const status = getIn(formikRef.values, "portalInfo.status");

    let resendInviteButton: JSX.Element | null = null;

    switch (status) {
      case PortalStatus.ACTIVE:
        descriptionAppString = textMap.accessActiveDescription;
        break;
      case PortalStatus.INVITED:
        descriptionAppString = textMap.invitedDescription;

        if (resendInviteStatus !== "sent" && _.isEqual(formInitialValues, formikRef.values)) {
          resendInviteButton = (
            <Link onClick={didPressResendInvite} underline="always" hoverColor="lightBlue">
              <Text color="lightBlue" marginLeft={5} value={AppStrings.Common.ResendInvitation} />
            </Link>
          );
        }
        break;
    }

    if (isResendInviteInProgress) {
      return null;
    }

    if (errorText) {
      descriptionAppString = errorText;
    }

    return (
      <View marginBottom={10} marginTop={10} flexDirection={"row"}>
        <Text fontSize={16} lineHeight={"24px"} fullWidth>
          {t(descriptionAppString)} {resendInviteButton}
        </Text>
      </View>
    );
  };

  const renderTitleText = useMemo(() => {
    let titleText = "";
    const status = getIn(formikRef.values, "portalInfo.status");
    const allowAccess = getIn(formikRef.values, "portalInfo.allowAccess");
    const loginEmail = getIn(formikRef.values, portalInfoEmailPath);

    switch (status) {
      case PortalStatus.ACTIVE:
        titleText = textMap.accessActive;
        break;
      case PortalStatus.INVITED: {
        titleText = AppStrings.Common.InvitationSent;
        break;
      }
    }

    if (status && allowAccess && loginEmail) {
      titleText = textMap.accessActive;
    }

    if (!status) {
      titleText = AppStrings.Common.NewPortalInvite;
    }

    if (errorText || resendInviteStatus === "failed") {
      titleText = errorText || AppStrings.Common.GeneralError;
    }

    if (isResendInviteInProgress) {
      titleText = AppStrings.Common.SendingInvitation;
    }

    return <Text marginTop={10} value={titleText} bold />;
  }, [errorText, isResendInviteInProgress]);

  return (
    <View marginTop={20} minHeight={40} backgroundColor="dark" borderRadius={10}>
      <View paddingLeft={20} paddingRight={20}>
        <View shouldShow showAnimation="fade-in" hideAnimation="fade-out">
          <View>
            <View flexDirection="row">
              {renderTitleIndicator()}
              {renderTitleText}
            </View>
            {renderDescription()}
          </View>
        </View>
      </View>
    </View>
  );
};
