export default {
  signIn: "Sign In",
  signUp: "Sign Up",
  forgotPassword: "Forgot password?",
  enterYourEmail: "Please enter your email address below:",
  getFreeAccess: "Get Free Access",
  thanksForSingup: "Thanks for signing up!",
  singupInstructions:
    "We sent you an email with a link to complete the signup process. Please go to your inbox (or spam folder) to get free access.",
  emailConfirmationTitle: "Email confirmation",
  emailConfirmation: "Thanks for confirming your email address!",
  emailConfirmationButtonText: "Confirm Email",
  confirmEmailInProgressText:
    "Please wait while we confirm your email address. You will be automatically redirected once your account is ready.",
  pleaseWaitCreateAccount: "Please wait while we create your DoorLoop account.",
  welcomeMessage: "Welcome Back!",
  welcomeMessageTo: "Login to",
  signUpWelcomeMessage: "Create Your Account",
  signUpWelcomeMessageSubtitle: "Your all-in-one solution for property management",
  thanks: "Thanks!",
  welcomeWizardAboutyou: "About You",
  resetPasswordInstructions: "Please enter your new password",
  resetPasswordButton: "Reset Password",
  selectAccount: "Select Account",
  login: "Login",
  createYourAccount: "Create your account",
  accountInfo: "Account Information",
  passwordResetSuccessfully: "Password reset successfully for {0}",
  wizardStep2About: "About Your Business",
  wizardStep2NumberOfUnits: "Number of Units Managed",
  wizardStep2HowDidYouHear: "How did you hear about us?",
  wizardStep2HowDidYouHearOther: "I heard from...",
  forgotPasswordEmailSent:
    "We sent you an email with a link to reset your password. Please check your inbox or spam folder.",
  promoTextTitle: "Questions?",
  promoTextSubtitle: "Search our tutorials",
  sendUsAMessage: "Visit The Help Center",
  promoDescriptionText: "Read our most frequently asked questions and view our support articles.",
  visitHelpCenter: "Visit The Help Center",
  credentialsInstructionsText: "To continue, log in to DoorLoop",
  continueWithGoogle: "Continue with Google",
  or: "or",
  emailAddress: "Email Address",
  password: "Password",
  confirmPassword: "Confirm Password",
  rememberMe: "Remember Me",
  resetPasswordEmailSentTitle: "Reset Password Email Sent",
  register: "Register",
  resetPasswordEmailSentSubTitle: "We sent you an email with a link to reset your password",
  newPassword: "New Password",
  confirmNewPassword: "Confirm New Password",
  missingTokenKey: "Something is wrong with your username. Please contact us.",
  recaptchaFailed: "Recaptcha Failed",
  createAccountTitle: "Let's create your account",
  createAccountSubTitle: "Please enter your information below",
  createAccountStep2Title: "Company Information",
  createAccountStep2SubTitle: "Answer a few short questions so we can personalize and optimize your experience.",
  step2AddressChooseOptionError: "Please choose an option from the provided list",
  createAccountStep3Title: "Tell us about your goals",
  createAccountStep3SubTitle: "Start by telling us about your business",
  createAccountStep4Title: "Choose your business type",
  termsConfirmationText: "By using DoorLoop you are agreeing to our",
  termsOfUse: "Terms of Use",
  and: "and",
  privacyPolicy: "Privacy Policy",
  companyName: "Company Name",
  portfolioType: "What kind of portfolio do you have?",
  howManyUnits: "How many units do you manage?",
  skipCreateAccount: "I’ll do this later",
  cantFindInvitationToken: "Can't find invitation token. Please contact us.",
  joinAccount: "Accept Invitation",
  completeDetails: "Complete your details",
  activeUserDescription: "This user is part of your account",
  loginWithExistingAccount: "Please login with your existing DoorLoop account",
  selectLoginAccount: "Which account would you like to log into?",
  twoStepAuthentication: "2-Step Verification",
  twoStepAuthenticationToggleDescription: "Require 2-step verification code to log in (recommended)",
  twoStepAuthenticationEmailDescription:
    "Please check your email for a 2-step verification code and enter it below to login.",
  twoStepAuthenticationMobileNumberDescription:
    "Please check your mobile number for a 2-step verification code and enter it below to login.",
  oneTimePassword: "Verification Code",
  invalidTotp: "Invalid Verification Code",
  loginToPortal: "login to your portal",
  loginToPortalDescription: "Please enter your email address to login to your tenant portal",
  invalidEmailAddress: "Invalid email address",
  unlock: "Unlock",
  unlockSettings: "Unlock Settings",
  unlockSettingsDescription: "To enter the login and password settings, please provide your current password.",
  currentPassword: "Current Password",
  loginInformation: "Login Information",
  twoFactorAuthentication: "Two Factor Authentication (2FA)",
  twoFactorAuthenticationDescription:
    "Two-factor authentication protects your account by adding an extra security step when you sign in.",
  emailAuthentication: "Email Authentication",
  emailAuthenticationIsActivated: "Email authentication is activated ({{email}})",
  smsAuthenticationIsActivated: "SMS authentication is activated ({{phoneNumber}})",
  smsAuthentication: "SMS Authentication",
  verifyYourPhoneNumber: "Verify Your Phone Number",
  enterYourPhoneNumber: "Enter your phone number",
  enterYourPhoneNumberDescription: "We’ll send you a code to verify your number",
  sendCode: "Send Code",
  enterYourConfirmationCode: "Enter your confirmation code",
  enterYourConfirmationCodeDescription: "Enter the 6-digit code DoorLoop just sent to {{phoneNumber}}",
  verifyCode: "Verify Code",
  resendCode: "Resend Code",
  invalidVerificationCode: "Invalid verification code. Please check your code and try again.",
  phoneVerification: "Phone Verification",
  changeNumber: "Change Number",
  doorLoopTechnicianAccess: "DoorLoop Technician Access",
  doorLoopTechnicianAccessDays: "({{days}} Days)",
  doorLoopTechnicianAccessDescription:
    "Enabling DoorLoop technician access will allow the DoorLoop Support team to access your account for the next {{days}} days.",
  passwordIsIncorrect: "Password is incorrect"
};
