import _ from "lodash";
import qs from "query-string";
import type { FormikProps } from "formik";

import AppStrings from "../../../../locale/keys";
import type { ApiRedirectResult } from "api/apiResult";
import { ApiResult } from "api/apiResult";
import { authApi } from "api/authApi";
import { history } from "store/history";
import type { LoginDto } from "@doorloop/dto";
import { LoginMfaType, ServerResponseErrorCode } from "@doorloop/dto";
import { Routes } from "../../../appRouter";

export interface LoginRequestError {
  errorMessage?: string;
  requiredOtp?: LoginRequestErrorRequiredOtp;
}

export interface LoginRequestErrorRequiredOtp {
  type: LoginMfaType;
}

export const getIsOAuth2Login = () => {
  const query = qs.parse(window.location.search);
  return Boolean(query.client_id && query.redirect_uri && query.state);
};

export const submitLoginRequest = async (values: LoginDto, rememberMe?: boolean): Promise<LoginRequestError | null> => {
  const loginResult = await authApi.login({ ...values, rememberMe: rememberMe || true }).catch(
    (error) =>
      new ApiResult({
        errorMessage: error
      })
  );

  if (loginResult && loginResult.status && loginResult.data) {
    const resultsData = loginResult.data as ApiRedirectResult;

    if (resultsData.redirectUrl) {
      window.location.replace(resultsData.redirectUrl);
    } else {
      const queryString = qs.parse(window.location.search);

      let nextPath: string | string[] = Routes.PROPERTIES;
      let nextSearchParams: string | string[] | undefined;

      if (queryString["returnUrl"]) {
        nextPath = queryString["returnUrl"];
      }

      if (queryString["returnUrlSearchPath"]) {
        nextSearchParams = queryString["returnUrlSearchPath"];
      }

      history.replace({
        pathname: Array.isArray(nextPath) ? nextPath[0] : nextPath,
        search: Array.isArray(nextSearchParams) ? nextSearchParams[0] : nextSearchParams
      });
    }

    return null;
  }
  if (
    loginResult &&
    (loginResult.errorCode === ServerResponseErrorCode.TOTP_REQUIRED_EMAIL ||
      loginResult.errorCode === ServerResponseErrorCode.TOTP_REQUIRED_SMS)
  ) {
    return {
      requiredOtp: {
        type:
          loginResult.errorCode === ServerResponseErrorCode.TOTP_REQUIRED_EMAIL ? LoginMfaType.Email : LoginMfaType.SMS
      }
    };
  }

  return {
    errorMessage: loginResult?.message || AppStrings.Common.GeneralError
  };
};

export const isLoginFormValid = async (formikRef: FormikProps<LoginDto>): Promise<boolean> => {
  formikRef.setFieldTouched("email");
  formikRef.setFieldTouched("password");
  formikRef.setFieldTouched("totp");

  const errors = await formikRef.validateForm();

  return _.isEmpty(errors);
};
