import React, { useState } from "react";
import { View } from "DLUI/view";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { Formik } from "formik";
import { EditSignatureTemplateDialog } from "screens/esignatures/editSignatureTemplate/editSignatureTemplateDialog";
import { ESignatureTemplateDto } from "@doorloop/dto";
import { useParams } from "react-router-dom";
import { useEffectAsync } from "../../../../hooks/useEffectAsync";
import { signatureTemplatesApi } from "api/signatureTemplatesApi";
import AppStrings from "locale/keys";
import { useTranslation } from "react-i18next";
import { getDialogFrameDimension } from "DLUI/dialogs/components/dialogFrame";
import { createValidator } from "@doorloop/dto";

interface ComponentProps {
  onBackdropClick: () => void;
  onClose: () => void;
}

const validateForm = createValidator(ESignatureTemplateDto);

const SignatureTemplateFormikWrapper: React.FC<ComponentProps> = ({ onBackdropClick, onClose }: ComponentProps) => {
  const dialogFrameHeight = getDialogFrameDimension("height", 600);
  const dialogFrameWidth = getDialogFrameDimension("height", 600);
  const { t } = useTranslation();
  const { signatureTemplateId } = useParams<{
    signatureTemplateId: string;
  }>();
  const editMode = signatureTemplateId !== undefined;
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");

  useEffectAsync(async () => {
    await loadData();
  }, []);

  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(
    editMode ? DialogState.Show : DialogState.Hidden
  );

  const [esignatureTemplateData, setEsignatureTemplateData] = useState<ESignatureTemplateDto | undefined>();

  const initFormValues = (): ESignatureTemplateDto =>
    esignatureTemplateData ? esignatureTemplateData : new ESignatureTemplateDto();

  const showError = (error: string) => {
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(error);
  };

  const loadData = async () => {
    if (signatureTemplateId) {
      setLoadingDialogState(DialogState.Show);
      const { data, message } = await signatureTemplatesApi.get(signatureTemplateId);
      if (data) {
        setEsignatureTemplateData(data);
        setLoadingDialogState(DialogState.Hidden);
      } else {
        showError(message);
      }
    } else {
      showError(t(AppStrings.Esignatures.EditSignatureTemplateNoIdFoundMessage));
    }
  };

  if (loadingDialogState !== DialogState.Hidden) {
    return (
      <View width={dialogFrameWidth} height={dialogFrameHeight} alignItems={"center"} justifyContent={"center"}>
        <LoadingDialog
          dialogState={loadingDialogState}
          errorText={loadingDialogErrorText}
          didPressDismissButton={onBackdropClick}
          onRetryButtonPress={loadData}
          minHeight={dialogFrameHeight}
        />
      </View>
    );
  }

  return (
    <Formik initialValues={initFormValues()} onSubmit={() => {}} validate={validateForm}>
      {() => (
        <EditSignatureTemplateDialog
          onClose={onClose}
          onBackdropClick={onBackdropClick}
          dialogFrameHeight={dialogFrameHeight}
          dialogFrameWidth={dialogFrameWidth}
        />
      )}
    </Formik>
  );
};

export default SignatureTemplateFormikWrapper;
