import type { FC } from "react";
import React from "react";
import { DialogFrame } from "DLUI/dialogs";
import { View } from "DLUI/view";
import { Icon } from "DLUI/icon";
import { AnnouncementSentIcon } from "assets/icons";
import { SectionTitle } from "DLUI/screen";
import AppStrings from "locale/keys";
import DLButton from "DLUI/button/dlButton";

interface Props {
  onDoneClick: () => void;
}

export const SuccessDialog: FC<Props> = ({ onDoneClick }: Props) => (
  <DialogFrame
    frameType={"contentOnlyNoFrame"}
    numViews={1}
    activeView={0}
    onCloseButtonClick={onDoneClick}
    width={540}
    height={380}
    useExperimentalDialogFrame
    renderView={() => (
      <View
        backgroundColor={"blue"}
        height={"calc(100% - 80px)"}
        paddingTop={40}
        paddingBottom={40}
        paddingRight={40}
        paddingLeft={40}
        alignItems={"center"}
      >
        <Icon Source={AnnouncementSentIcon} />
        <View marginTop={20} width={230}>
          <SectionTitle
            type={"underline"}
            title={AppStrings.Announcements.yourAnnouncementHasBeenSent}
            textColor={"white"}
            alignItems={"center"}
            fontSize={22}
          />
        </View>
        <View flex={1} />
        <View marginTop={20} alignItems={"center"}>
          <DLButton onClick={onDoneClick} actionText={AppStrings.Common.Done} style={{ minWidth: 200 }} />
        </View>
      </View>
    )}
  />
);
