import type { StaticRemoteTextOrDateFilter } from "DLUI/screen/filterPanel/filterPanel";
import AppStrings from "locale/keys";
import { CheckbookPaymentStatusEnum } from "@doorloop/dto";
import type { GetOutgoingPaymentsReportQuery } from "@doorloop/dto";

const FilterOptions: Array<StaticRemoteTextOrDateFilter<GetOutgoingPaymentsReportQuery>> = [
  {
    label: AppStrings.Tasks.Screen.Date,
    type: "dateRangePicker",
    period: "last-30-days",
    startDateFieldKey: "filter_date_from",
    endDateFieldKey: "filter_date_to",
    size: "2-rows"
  },
  {
    marginTop: 20,
    name: AppStrings.Overview.Status,
    type: "staticFiltering",
    values: [
      {
        displayName: AppStrings.Common.OutgoingPayments.Paid,
        value: CheckbookPaymentStatusEnum.PAID,
        filterFieldName: "filter_payableInfoEPayInfoStatus"
      },
      {
        displayName: AppStrings.Common.OutgoingPayments.InProcess,
        value: CheckbookPaymentStatusEnum.IN_PROCESS,
        filterFieldName: "filter_payableInfoEPayInfoStatus"
      },
      {
        displayName: AppStrings.Common.OutgoingPayments.Unpaid,
        value: CheckbookPaymentStatusEnum.UNPAID,
        filterFieldName: "filter_payableInfoEPayInfoStatus"
      },
      {
        displayName: AppStrings.Common.OutgoingPayments.Voided,
        value: CheckbookPaymentStatusEnum.VOID,
        filterFieldName: "filter_payableInfoEPayInfoStatus"
      },
      {
        displayName: AppStrings.Common.OutgoingPayments.Expired,
        value: CheckbookPaymentStatusEnum.EXPIRED,
        filterFieldName: "filter_payableInfoEPayInfoStatus"
      },
      {
        displayName: AppStrings.Common.OutgoingPayments.Printed,
        value: CheckbookPaymentStatusEnum.PRINTED,
        filterFieldName: "filter_payableInfoEPayInfoStatus"
      },
      {
        displayName: AppStrings.Common.OutgoingPayments.Mailed,
        value: CheckbookPaymentStatusEnum.MAILED,
        filterFieldName: "filter_payableInfoEPayInfoStatus"
      },
      {
        displayName: AppStrings.Common.OutgoingPayments.Failed,
        value: CheckbookPaymentStatusEnum.FAILED,
        filterFieldName: "filter_payableInfoEPayInfoStatus"
      },
      {
        displayName: AppStrings.Common.OutgoingPayments.Refunded,
        value: CheckbookPaymentStatusEnum.REFUNDED,
        filterFieldName: "filter_payableInfoEPayInfoStatus"
      }
    ]
  }
];

export default FilterOptions;
