import React from "react";

import type { FieldProps } from "formik";
import { Field } from "formik";
import type { Path } from "react-hook-form";

type FieldSafeProps<T, TDto extends any = null> = Omit<
  T extends React.ComponentType<infer P> ? P : never,
  keyof FieldProps
> & {
  name: TDto extends { prototype: infer K } ? Path<K> : string;
  dto?: TDto;
  component: T;
  required?: boolean;
};

export const FieldSafe = <T extends React.ComponentType<any>, TDto extends any = null>(
  props: FieldSafeProps<T, TDto>
) => {
  const { component, dto, ...rest } = props;
  return <Field {...rest} component={component} />;
};
