import AppStrings from "locale/keys";
import { DialogRoutes } from "./dialogsRoutes";
import Routes from "../../../appRouter/routes";
import type { DialogProps } from "./dialogsProvider.types";
import { isEditFromDetailScreen } from "./dialogsProvider.utils";
import BoardMembershipDialog from "screens/tenants/boardMember/boardMembershipDialog";
import RemoveBoardMemberTermDialog from "screens/tenants/boardMember/removeBoardMemberTermDialog";

class TenantsDialogsProvider {
  matchRoutes(pathname: string): DialogProps | undefined {
    if (pathname.indexOf(DialogRoutes.NEW_TENANT) > -1) {
      return {
        type: "newTenant",
        title: AppStrings.Tenants.Screen.AddNewTenant
      };
    }
    if (
      (pathname.includes(DialogRoutes.EDIT_TENANT) && !pathname.includes(DialogRoutes.EDIT_LEASE)) ||
      isEditFromDetailScreen("tenants", 2, 2, Routes.TENANT_DETAILS_EDIT, pathname)
    ) {
      return {
        type: "newTenant",
        title: AppStrings.Tenants.Screen.EditTenant
      };
    }
    if (pathname.includes(DialogRoutes.NEW_BOARD_MEMBER) || pathname.includes(DialogRoutes.EDIT_BOARD_MEMBER)) {
      return {
        Content: BoardMembershipDialog,
        title: AppStrings.Common.Hoa.NewBoardMember
      };
    }
    if (pathname.includes(DialogRoutes.REMOVE_BOARD_TERM)) {
      return {
        Content: RemoveBoardMemberTermDialog,
        title: AppStrings.Common.Hoa.RemoveTerm
      };
    }
  }
}

export const tenantsDialogsProvider = new TenantsDialogsProvider();
