import { View } from "@/components/DLUI/view";
import { useTypedTranslation } from "@/locale";
import type { IRootState } from "@/store";
import { ExpressCheckoutElement } from "@stripe/react-stripe-js";
import type {
  StripeExpressCheckoutElementConfirmEvent,
  ClickResolveDetails,
  StripeExpressCheckoutElementClickEvent,
  StripeExpressCheckoutElementReadyEvent
} from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import "./stripePayment.css";
import { useAnalyticsService } from "@/hooks/useAnalyticsService";

interface ComponentProps {
  handleConfirm: (event: StripeExpressCheckoutElementConfirmEvent) => void;
  handleOnReady?: (event: StripeExpressCheckoutElementReadyEvent) => void;
}

export const ExpressCheckout = ({ handleConfirm, handleOnReady }: ComponentProps) => {
  const { dispatchAnalytics } = useAnalyticsService();
  const { t } = useTypedTranslation();
  const currentLoginResponse = useSelector((state: IRootState) => state.auth.currentLoginResponse);

  const handleExpressCheckout = ({ expressPaymentType, resolve }: StripeExpressCheckoutElementClickEvent) => {
    dispatchAnalytics("tenantpayment_payment_express_checkout_clicked", { paymentMethodType: expressPaymentType });

    const options: ClickResolveDetails = {
      business: { name: currentLoginResponse?.currentDbTenant.companyName || "" }
    };

    resolve(options);
  };

  return (
    <View>
      <ExpressCheckoutElement
        onConfirm={handleConfirm}
        onReady={handleOnReady}
        options={{
          paymentMethods: { googlePay: "always", applePay: "always", link: "auto" },
          buttonType: { googlePay: "plain" },
          buttonHeight: 40,
          layout: {
            maxColumns: 1,
            overflow: "never"
          }
        }}
        onClick={handleExpressCheckout}
      />
    </View>
  );
};
