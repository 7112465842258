import TextInput from "DLUI/form/textField/textInput";
import clsx from "clsx";
import type { AccountDto, PayOutManagementAccounts } from "@doorloop/dto";
import { DataCy } from "@doorloop/dto";
import type { TextColor } from "DLUI/text/text";
import Text from "DLUI/text";
import { useManagementFeesStyles } from "screens/settings/managementFees/useStyles";
import { useTypedTranslation } from "locale";
import type { CSSProperties } from "react";
import { useMemo } from "react";
import { useGeneralStyles } from "styles/generalStyles";
import { HorizontalSeparationLine } from "DLUI/separatorView/horizontalSeparationLine";
import { formatNumberToFractionWithCommas } from "utils/formatNumberToFractionWithCommas";
import { entityApiStore } from "api/entityApiStore/entityApiStore";
import type { AmountsForKey } from "screens/settings/managementFees/sampleCalculator/sampleCalculator";

interface PayoutAccountsDisplayProps {
  payOutAccountsKeys?: PayOutManagementAccounts[];
  isEditable: boolean;
  payoutAccountsAmounts: AmountsForKey;
  setPayoutAccountsAmounts: React.Dispatch<React.SetStateAction<AmountsForKey>>;
  textColor: TextColor;
}

const INPUT_STYLE: CSSProperties = {
  width: 180
};

export const PayoutAccountsDisplay: React.FC<PayoutAccountsDisplayProps> = ({
  payOutAccountsKeys = [],
  isEditable,
  payoutAccountsAmounts,
  setPayoutAccountsAmounts,
  textColor
}) => {
  const classes = useManagementFeesStyles();
  const generalClasses = useGeneralStyles();
  const { data: accounts } = entityApiStore.accounts.queries.useGetDictionary();
  const { t } = useTypedTranslation();

  const payoutAccounts: Array<AccountDto & { uniqueKey: string | undefined }> = useMemo(() => {
    if (!accounts) return [];
    return payOutAccountsKeys.map(({ uniqueKey, incomeAccount }) => {
      return { ...accounts[incomeAccount!], uniqueKey };
    });
  }, [accounts, payOutAccountsKeys]);

  return (
    <>
      {payoutAccounts.length > 0 && (
        <>
          <HorizontalSeparationLine marginTop={16} marginBottom={16} />
          <div
            className={classes.columnContainer}
            data-cy={DataCy.globalSettings.managementFees.sampleCalculator.payoutAccounts.container}
          >
            <Text
              value={t("settings.generalSettings.managementFees.sampleCalculator.payoutAccounts")}
              fontSize={12}
              fontWeight={700}
              color={textColor}
            />
            {payoutAccounts.map((account) => (
              <div
                key={account.uniqueKey}
                className={clsx(generalClasses.flexRow, generalClasses.verticalAlign, generalClasses.spaceBetween)}
              >
                <TextInput
                  label={account.name ?? t("settings.generalSettings.managementFees.sampleCalculator.expenseAccount")}
                  disabled={!isEditable}
                  InputLabelProps={{
                    className: classes.calculatorInputLabel
                  }}
                  formatType={"number"}
                  TextFieldType={"number"}
                  style={INPUT_STYLE}
                  data-cy={DataCy.globalSettings.managementFees.sampleCalculator.payoutAccounts.accountInput}
                  value={payoutAccountsAmounts[account.uniqueKey!]}
                  onChange={({ target }) =>
                    setPayoutAccountsAmounts({
                      ...payoutAccountsAmounts,
                      ...(account.uniqueKey ? { [account.uniqueKey]: target.value } : {})
                    })
                  }
                />
                <Text
                  value={formatNumberToFractionWithCommas(payoutAccountsAmounts[account.uniqueKey!])}
                  fontSize={12}
                  fontWeight={700}
                  color={textColor}
                  dataCy={DataCy.globalSettings.managementFees.sampleCalculator.payoutAccounts.accountResult}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};
