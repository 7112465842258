import React from "react";
import { View } from "DLUI/view";
import DialogFrameHeader from "screens/transactions/components/bankConnect/dialogs/bankConnect/dialogFrameHeader";
import Text from "DLUI/text";
import AppStrings from "locale/keys";
import { NavigationManager } from "utils/navigation";
import { Routes } from "../../../../../../appRouter";
import DLButton, { DLButtonColorsEnum, DLButtonSizesEnum } from "DLUI/button/dlButton";
import { BigMoneyCircleIcon } from "@/assets";
import { Icon } from "DLUI/icon";

interface ComponentProps {
  onClose: () => void;
  accountId: string;
}

const OpeningBalancesRequiredView: React.FC<ComponentProps> = ({ onClose, accountId }: ComponentProps) => {
  const didPressEditOpeningBalance = () => {
    NavigationManager.editAccount(Routes.BOOKKEEPING_TRANSACTIONS, accountId, "opening balances");
  };
  return (
    <View height={"100%"}>
      <DialogFrameHeader onCloseButtonPress={onClose} />
      <View width={"100%"} justifyContent={"center"} alignItems={"center"} flex={1} noWrap>
        <View noWrap paddingRight={20} paddingLeft={20}>
          <View noWrap borderRadius={10} alignItems={"center"}>
            <Icon Source={BigMoneyCircleIcon} />
            <Text
              value={AppStrings.Common.OpeningBalanceRequired}
              fontSize={30}
              fontWeight={700}
              marginTop={20}
              align={"center"}
            />
            <Text
              lineHeight={"24px"}
              align={"center"}
              marginTop={20}
              fontSize={16}
              value={AppStrings.Common.OpeningBalanceRequiredDescription}
            />

            <DLButton
              actionText={AppStrings.Common.SetOpeningBalance}
              onClick={didPressEditOpeningBalance}
              color={DLButtonColorsEnum.PRIMARY}
              size={DLButtonSizesEnum.LARGE}
              style={{ minWidth: 240, marginTop: 20 }}
            />
            <View height={20} />
          </View>
        </View>
      </View>
    </View>
  );
};

export default OpeningBalancesRequiredView;
