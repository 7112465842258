"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentalApplicationResidentialHistoryDto = void 0;
const common_1 = require("../common");
const validators_1 = require("../common/validators");
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const rentalApplicationOwnOrRent_enum_1 = require("./rentalApplicationOwnOrRent.enum");
const regionValidations_1 = require("./regionValidations");
class RentalApplicationResidentialHistoryDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.RentalApplicationResidentialHistoryDto = RentalApplicationResidentialHistoryDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => common_1.AddressDto),
    (0, class_validator_1.ValidateIf)((x) => { var _a; return ((_a = x.address) === null || _a === void 0 ? void 0 : _a.street1) || x.mandatoryAddress; }),
    __metadata("design:type", common_1.AddressDto)
], RentalApplicationResidentialHistoryDto.prototype, "address", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], RentalApplicationResidentialHistoryDto.prototype, "mandatoryAddress", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", Boolean)
], RentalApplicationResidentialHistoryDto.prototype, "currentlyLivingHere", void 0);
__decorate([
    (0, validators_1.IsDateStringWithoutTime)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], RentalApplicationResidentialHistoryDto.prototype, "moveInDate", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => !x.currentlyLivingHere),
    (0, validators_1.IsDateStringWithoutTime)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, validators_1.IsAfter)("moveInDate"),
    __metadata("design:type", String)
], RentalApplicationResidentialHistoryDto.prototype, "moveOutDate", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => { var _a, _b; return regionValidations_1.usCountryList.includes((_b = (_a = x === null || x === void 0 ? void 0 : x.address) === null || _a === void 0 ? void 0 : _a.country) === null || _b === void 0 ? void 0 : _b.toLowerCase()); }),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsEnum)(rentalApplicationOwnOrRent_enum_1.RentalApplicationOwnOrRent),
    __metadata("design:type", String)
], RentalApplicationResidentialHistoryDto.prototype, "ownOrRent", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.Min)(0),
    __metadata("design:type", Number)
], RentalApplicationResidentialHistoryDto.prototype, "monthlyPayment", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((x) => !x.currentlyLivingHere),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(1000),
    __metadata("design:type", String)
], RentalApplicationResidentialHistoryDto.prototype, "reasonForLeaving", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(250),
    __metadata("design:type", String)
], RentalApplicationResidentialHistoryDto.prototype, "landlordName", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(20),
    (0, class_validator_1.MinLength)(5),
    __metadata("design:type", String)
], RentalApplicationResidentialHistoryDto.prototype, "landlordPhone", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEmail)(),
    (0, class_validator_1.MaxLength)(250),
    __metadata("design:type", String)
], RentalApplicationResidentialHistoryDto.prototype, "landlordEmail", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], RentalApplicationResidentialHistoryDto.prototype, "uniqueIndex", void 0);
