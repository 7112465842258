import { AutoCompleteTyped } from "DLUI/form";
import { getPersonAvatarSrc, getPersonOptionLabel, renderPersonOption } from "./peopleAutoComplete.utils";

import type { NarrowedPersonDto, PersonTypeEnum } from "@doorloop/dto";
import type { AutoCompleteProps } from "../autoCompleteTyped/autoComplete.types";
import type { PeopleOrderDictionary } from "./peopleAutoComplete.hooks";

import "./peopleAutoComplete.styles.css";

// The keys to search for in an option, based on `AutoCompleteOption`.
const defaultFilterKeys = [
  "type",
  "firstName",
  "middleName",
  "lastName",
  "companyName",
  "email.address",
  "phone.number"
];

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type PeopleAutoCompleteMetadata = {
  orderDictionary: PeopleOrderDictionary;
  // eslint-disable-next-line @typescript-eslint/array-type
  typeFilter?: ReadonlyArray<PersonTypeEnum>;
};
export type PeopleAutoCompleteProps = AutoCompleteProps<NarrowedPersonDto, PeopleAutoCompleteMetadata>;

export function PeopleAutoComplete(props: PeopleAutoCompleteProps) {
  return (
    <AutoCompleteTyped<NarrowedPersonDto, PeopleAutoCompleteMetadata>
      getOptionLabel={getPersonOptionLabel}
      renderOption={renderPersonOption}
      filterKeys={defaultFilterKeys}
      classes={{ root: "PeopleAutoComplete-root", popper: "PeopleAutoComplete-root" }}
      getAvatarSrc={getPersonAvatarSrc}
      selectOnFocus={false}
      {...props}
    />
  );
}
