import { LearnMoreText } from "DLUI/text/learnMoreText";
import { CollapsibleWithSwitchButton } from "screens/settings/common/collapsibleWithSwitchButton";
import { TitleWithDescription } from "screens/settings/common/titleWithDescription";
import type { DeepKeys } from "@doorloop/dto";
import { DataCy, LateFeeSettingsAccountsToConsider } from "@doorloop/dto";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { FormikSwitchButton, Select, TextField } from "DLUI/form";
import { SettingsSection } from "screens/settings/common/settingsSection";
import React, { useState } from "react";
import { useTypedTranslation } from "locale";
import clsx from "clsx";
import { useGeneralStyles } from "styles/generalStyles";
import { useStyles } from "DLUI/dialogs/settings/common/settings/styles";
import { FastField, useFormikContext } from "formik";
import { useLateFeeSettings } from "DLUI/dialogs/settings/common/lateFees/useLateFeeSettings";
import type { LateFeesFormTypes, LateFeesPrefixes } from "DLUI/dialogs/settings/common/lateFees/types";
import { accountsApi } from "api/accounts";
import FormikCachedMultiSelectAutoComplete from "DLUI/form/autoComplete/formikCachedAsyncAutoComplete/formikCachedMultiSelectAutoComplete";

export interface LateFeeLimitationsProps {
  formikPathName?: DeepKeys<LateFeesPrefixes>;
}

export const LateFeeLimitations = ({ formikPathName }: LateFeeLimitationsProps) => {
  const { t } = useTypedTranslation();
  const formikRef = useFormikContext<LateFeesFormTypes>();
  const generalClasses = useGeneralStyles();
  const settingsClasses = useStyles();
  const { getFieldPath, lateFeeSettings } = useLateFeeSettings({ formikPathName });
  const [specifyAccountsToConsider, setSpecifyAccountsToConsider] = useState<boolean>(
    Boolean(lateFeeSettings?.lateFeeSettingsAccountsToConsider)
  );

  const handleChangeAccountToConsiderSelection = (nextValue: string) => {
    const propName = getFieldPath("lateFeeSettingsAccountsToConsiderSpecificAccounts");
    if (nextValue === LateFeeSettingsAccountsToConsider.ALL_ACCOUNTS) {
      formikRef.setFieldValue(propName, undefined);
    } else if (!lateFeeSettings?.lateFeeSettingsAccountsToConsiderSpecificAccounts?.length) {
      formikRef.setFieldValue(propName, []);
    }
  };

  const handleMaximumFeesToggle = (isOpen: boolean, type: "daily" | "monthly") => {
    formikRef.setFieldValue(getFieldPath(type === "daily" ? "maxDailyLateFees" : "maxMonthlyLateFees"), isOpen);
    if (!isOpen) {
      formikRef.setFieldValue(
        getFieldPath(type === "daily" ? "maxDailyLateFeesAmount" : "maxMonthlyLateFeesAmount"),
        null
      );
    }
  };

  const handleOutstandingAccountsToggle = (isOpen: boolean) => {
    if (!isOpen) {
      formikRef.setFieldValue(getFieldPath("lateFeeSettingsAccountsToConsider"), undefined);
      formikRef.setFieldValue(getFieldPath("lateFeeSettingsAccountsToConsiderSpecificAccounts"), null);
    } else {
      formikRef.setFieldValue(
        getFieldPath("lateFeeSettingsAccountsToConsider"),
        LateFeeSettingsAccountsToConsider.ALL_ACCOUNTS
      );
    }
    setSpecifyAccountsToConsider(isOpen);
  };

  if (!lateFeeSettings) return null;

  return (
    <SettingsSection title={t("lateFee.feeLimitations")}>
      <div className={clsx(generalClasses.flexColumn, generalClasses.largeGap)}>
        <LearnMoreText value={t("lateFee.feeLimitationsDescription")} />
        <div className={settingsClasses.settingContainer}>
          <CollapsibleWithSwitchButton
            title={
              <TitleWithDescription
                title={t("lateFee.maximumFees.titleDaily")}
                subtitle={t("lateFee.maximumFees.descriptionDaily")}
              />
            }
            dataCyButton={DataCy.globalSettings.lateFees.limitations.maximumDailyFee.toggle}
            defaultState={lateFeeSettings.maxDailyLateFees}
            onToggleChange={(isOpen) => handleMaximumFeesToggle(isOpen, "daily")}
          >
            <div className={clsx(settingsClasses.settingInputs, settingsClasses.limitationsInputSmall)}>
              <FastFieldSafe
                component={TextField}
                label={t("common.amount")}
                name={getFieldPath("maxDailyLateFeesAmount")}
                formatType={"currency"}
                autoWidth={false}
                dataCy={DataCy.globalSettings.lateFees.limitations.maximumDailyFee.amount}
              />
            </div>
          </CollapsibleWithSwitchButton>
        </div>
        <div className={settingsClasses.settingContainer}>
          <CollapsibleWithSwitchButton
            title={
              <TitleWithDescription
                title={t("lateFee.maximumFees.titleMonthly")}
                subtitle={t("lateFee.maximumFees.descriptionMonthly")}
              />
            }
            dataCyButton={DataCy.globalSettings.lateFees.limitations.maximumMonthlyFee.toggle}
            defaultState={lateFeeSettings.maxMonthlyLateFees}
            onToggleChange={(isOpen) => handleMaximumFeesToggle(isOpen, "monthly")}
          >
            <div className={clsx(settingsClasses.settingInputs, settingsClasses.limitationsInputSmall)}>
              <FastFieldSafe
                component={TextField}
                label={t("common.amount")}
                name={getFieldPath("maxMonthlyLateFeesAmount")}
                formatType={"currency"}
                autoWidth={false}
                dataCy={DataCy.globalSettings.lateFees.limitations.maximumMonthlyFee.amount}
              />
            </div>
          </CollapsibleWithSwitchButton>
        </div>
        <div className={settingsClasses.settingContainer}>
          <CollapsibleWithSwitchButton
            title={
              <TitleWithDescription
                title={t("lateFee.setMinBalanceToApplyLateFeeAmount.title")}
                subtitle={t("lateFee.setMinBalanceToApplyLateFeeAmount.description")}
              />
            }
            defaultState={lateFeeSettings.minBalanceToApplyLateFee}
            dataCyButton={DataCy.globalSettings.lateFees.limitations.minBalance.toggle}
            onToggleChange={(isOpen) => formikRef.setFieldValue(getFieldPath("minBalanceToApplyLateFee"), isOpen)}
          >
            <div className={clsx(settingsClasses.settingInputs, settingsClasses.limitationsInputSmall)}>
              <FastFieldSafe
                component={TextField}
                label={t("common.settings.lateFees.minimumBalance")}
                name={getFieldPath("minBalanceToApplyLateFeeAmount")}
                formatType={"currency"}
                autoWidth={false}
                dataCy={DataCy.globalSettings.lateFees.limitations.minBalance.amount}
              />
            </div>
          </CollapsibleWithSwitchButton>
        </div>
        <div className={settingsClasses.settingContainer}>
          <div className={clsx(generalClasses.flexRow, generalClasses.spaceBetween)}>
            <TitleWithDescription
              title={t("lateFee.onlyChargeLateFeesOnMostRecentCharge.title")}
              subtitle={t("lateFee.onlyChargeLateFeesOnMostRecentCharge.description")}
            />
            <FastField
              component={FormikSwitchButton}
              name={getFieldPath("onlyChargeLateFeesOnMostRecentCharge")}
              dataCy={DataCy.globalSettings.lateFees.limitations.applyToMostRecent.toggle}
            />
          </div>
        </div>
        <div className={settingsClasses.settingContainer}>
          <CollapsibleWithSwitchButton
            title={
              <TitleWithDescription
                title={t("lateFee.specifyAccountsToConsider.title")}
                subtitle={t("lateFee.specifyAccountsToConsider.description")}
              />
            }
            defaultState={specifyAccountsToConsider}
            dataCyButton={DataCy.globalSettings.lateFees.limitations.outstandingCharges.toggle}
            onToggleChange={handleOutstandingAccountsToggle}
          >
            <div className={clsx(generalClasses.flexColumn, generalClasses.largeGap, settingsClasses.settingInputs)}>
              {lateFeeSettings.lateFeeSettingsAccountsToConsider && (
                <div className={settingsClasses.limitationsInputLarge}>
                  <FastFieldSafe
                    component={Select}
                    name={getFieldPath("lateFeeSettingsAccountsToConsider")}
                    uniqueKey={"lateFeeSettingsAccountsToConsider"}
                    selectionEnum={LateFeeSettingsAccountsToConsider}
                    translationKey={"lateFeeSettingsAccountsToConsider"}
                    defaultValue={lateFeeSettings.lateFeeSettingsAccountsToConsider}
                    onChange={handleChangeAccountToConsiderSelection}
                    dataCy={DataCy.globalSettings.lateFees.limitations.outstandingCharges.type}
                  />
                </div>
              )}
              {lateFeeSettings.lateFeeSettingsAccountsToConsider ===
                LateFeeSettingsAccountsToConsider.SPECIFIC_ACCOUNTS && (
                <div className={settingsClasses.limitationsInputLarge}>
                  <FormikCachedMultiSelectAutoComplete
                    uniqueIndex={"specifyAccountsToConsider"}
                    apiHandler={accountsApi}
                    displayNameKey={"fullyQualifiedName"}
                    filterFieldName={"filter_text"}
                    filterFieldValue={"fullyQualifiedName"}
                    selectionFields={["id", "class"]}
                    defaultValue={lateFeeSettings.lateFeeSettingsAccountsToConsiderSpecificAccounts}
                    name={getFieldPath("lateFeeSettingsAccountsToConsiderSpecificAccounts")}
                    label={t("common.selectAccounts")}
                    dataCy={DataCy.globalSettings.lateFees.limitations.outstandingCharges.accounts}
                    queryParams={{ filter_isSystemAccountOrLeaseChargeItem: true, filter_active: true }}
                  />
                </div>
              )}
            </div>
          </CollapsibleWithSwitchButton>
        </div>
      </div>
    </SettingsSection>
  );
};
