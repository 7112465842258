import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DialogFrame, LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import { View } from "DLUI/view";
import { useParams } from "react-router-dom";
import { bankAccountsApi } from "api/bankAccountsApi";
import type { MerchantAccountIdentityVerificationDto } from "@doorloop/dto";
import { BankAccountMerchantStatus } from "@doorloop/dto";
import { NavigationManager } from "utils/navigation";
import AppStrings from "locale/keys";
import VerifyIdentity from "screens/incomingPayments/identityVerification/verifyIdentity";
import VerificationFailed from "screens/incomingPayments/identityVerification/verificationFailed";
import PendingApproval from "screens/incomingPayments/identityVerification/pendingApproval";

interface ComponentProps {
  onClose: () => void;
}

const DialogHeight = Math.min(window.innerHeight, 560);
const DialogWidth = Math.min(window.innerWidth, 640);

const DialogCompactHeight = Math.min(window.innerHeight, 400);
const DialogCompactWidth = Math.min(window.innerWidth, 540);

const DialogViews = {
  LoadingView: 0,
  VerifyIdentity: 1,
  PendingApproval: 2,
  VerificationFailed: 3
};

const IdentityVerificationDialog: React.FC<ComponentProps> = ({}: ComponentProps) => {
  const { t } = useTranslation();
  const { merchantAccountId } = useParams<any>();

  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Show);
  const [loadingDialogErrorText, setLoadingDialogErrorText] = useState<string>("");
  const [viewIndex, setViewIndex] = useState(DialogViews.LoadingView);
  const [identityVerificationData, setIdentityVerificationData] = useState<
    MerchantAccountIdentityVerificationDto | undefined
  >();

  const [dialogHeight, setDialogHeight] = useState<number>(DialogCompactHeight);
  const [dialogWidth, setDialogWidth] = useState<number>(DialogCompactWidth);

  useEffect(() => {
    getMerchantAccountIdentityStatus();
  }, []);

  const onBackdropClick = () => {
    NavigationManager.viewOnlinePayments();
  };

  const showErrorMessage = (errMessage) => {
    setLoadingDialogState(DialogState.Error);
    setLoadingDialogErrorText(errMessage);
  };

  const showVerifyIdentityView = () => {
    setDialogWidth(DialogWidth);
    setDialogHeight(DialogHeight);
    setTimeout(() => {
      setViewIndex(DialogViews.VerifyIdentity);
    }, 300);
  };

  const getMerchantAccountIdentityStatus = async () => {
    if (merchantAccountId) {
      const { data, status, message } = await bankAccountsApi.getMerchantAccountIdentityStatus(merchantAccountId);
      if (status && data) {
        setIdentityVerificationData(data);
        setLoadingDialogState(DialogState.Hidden);
        const { identity_status } = data;
        switch (identity_status) {
          case BankAccountMerchantStatus.IDENTITY_VERIFICATION_REQUIRED:
            showVerifyIdentityView();
            break;
          case BankAccountMerchantStatus.PENDING_UNDERWRITING_APPROVAL:
            setViewIndex(DialogViews.PendingApproval);
            break;
          case BankAccountMerchantStatus.IDENTITY_VERIFICATION_FAILED:
            setViewIndex(DialogViews.VerificationFailed);
            break;
          default:
            showVerifyIdentityView();
        }
      } else {
        showErrorMessage(message || t(AppStrings.Common.GeneralError));
      }
    } else {
      showErrorMessage(t(AppStrings.Accounts.MerchantAccountNotFound));
    }
  };

  const didPressBackButton = () => {
    onBackdropClick();
  };

  const renderView = ({ index }: any) => {
    if (index === DialogViews.LoadingView) {
      return (
        <LoadingDialog
          dialogState={loadingDialogState}
          errorText={loadingDialogErrorText}
          onRetryButtonPress={getMerchantAccountIdentityStatus}
          didPressDismissButton={onBackdropClick}
        />
      );
    }
    if (index === DialogViews.VerifyIdentity) {
      return <VerifyIdentity onBackdropClick={onBackdropClick} identityVerificationData={identityVerificationData} />;
    }
    if (index === DialogViews.VerificationFailed) {
      return <VerificationFailed onBackdropClick={onBackdropClick} />;
    }
    if (index === DialogViews.PendingApproval) {
      return <PendingApproval onBackdropClick={onBackdropClick} />;
    }

    return <View />;
  };

  const frameType = useMemo(() => {
    if (viewIndex === DialogViews.VerifyIdentity) {
      return "topPanel";
    }
    return "contentOnlyNoFrame";
  }, [viewIndex]);

  return (
    <DialogFrame
      useExperimentalDialogFrame
      onCloseButtonClick={didPressBackButton}
      width={dialogWidth}
      height={dialogHeight}
      renderView={renderView}
      numViews={2}
      activeView={viewIndex}
      frameType={frameType}
      hideActionPanel
      title={AppStrings.Accounts.IdentityVerification}
    />
  );
};

export default IdentityVerificationDialog;
