import React from "react";
import { View } from "DLUI/view";
import Text from "DLUI/text";
import { FastField, useFormikContext } from "formik";
import { FormikSwitchButton } from "DLUI/form";
import type { LeaseDto } from "@doorloop/dto";
import { DataCy } from "@doorloop/dto";
import { useResponsiveHelper } from "contexts/responsiveContext";
import { useMemo, useState } from "react";
import { EvictionStatusCancelAlert } from "./evictionStatusCancelAlert";
import { EvictionStatusConfirmationAlert } from "./evictionStatusConfirmationAlert";
import { useTypedTranslation } from "@/locale";

interface Props {
  isEvictionNoteConfirmed: boolean;
  handleEvictionNoteConfirmation: (value: boolean) => void;
}

const EvictionStatusForm: React.FC<Props> = ({
  isEvictionNoteConfirmed,
  handleEvictionNoteConfirmation: handleToggleEvictionNoteConfirmation
}: Props) => {
  const formikRef = useFormikContext<LeaseDto>();
  const { t } = useTypedTranslation();
  const initialEvictionStatus = useMemo(() => formikRef.values.evictionPending, []);
  const { screenContainerPadding } = useResponsiveHelper();
  const [isEvictionPending, setIsEvictionPending] = useState<boolean>(formikRef.values.evictionPending || false);

  const showCancelAlert = useMemo(
    () => !formikRef.values.evictionPending && Boolean(initialEvictionStatus),
    [formikRef.values.evictionPending]
  );

  return (
    <View paddingLeft={screenContainerPadding} paddingRight={screenContainerPadding}>
      <Text marginTop={20} value={t("leases.settings.evictionDialogDescription")} />
      <View minHeight={65} backgroundColor={"white"} borderRadius={10} marginTop={20}>
        <View paddingLeft={screenContainerPadding} marginTop={20} paddingRight={screenContainerPadding}>
          <FastField
            component={FormikSwitchButton}
            name={"evictionPending"}
            label={t("leases.settings.leaseInEviction")}
            onChange={setIsEvictionPending}
            dataCy={DataCy.leaseDetails.settings.evictionInProgressToggle}
          />
          <EvictionStatusConfirmationAlert
            show={isEvictionPending}
            showCheckbox={!initialEvictionStatus}
            message={
              initialEvictionStatus ? t("leases.settings.evictionNote") : t("leases.settings.evictionNoteBefore")
            }
            isEvictionNoteConfirmed={isEvictionNoteConfirmed}
            handleToggleEvictionCheckboxConfirmation={handleToggleEvictionNoteConfirmation}
          />
          <EvictionStatusCancelAlert show={showCancelAlert} />
        </View>
      </View>
    </View>
  );
};

export default EvictionStatusForm;
