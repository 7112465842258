import { TitleWithDescription } from "screens/settings/common/titleWithDescription";
import type { DeepKeys, ReminderTypesStatusDto } from "@doorloop/dto";
import { DataCy } from "@doorloop/dto";
import { type LateFeesPolicyDto } from "@doorloop/dto";
import { FastFieldSafe } from "DLUI/fastFieldSafe/fastFieldSafe";
import { FormikCheckBox } from "DLUI/form";
import React, { useMemo } from "react";
import type { EnTranslation } from "locale";
import { useTypedTranslation } from "locale";
import clsx from "clsx";
import { useStyles } from "DLUI/dialogs/settings/common/settings/styles";
import { useGeneralStyles } from "styles/generalStyles";
import type { LateFeesFormTypes } from "DLUI/dialogs/settings/common/lateFees/types";

export const ReminderTypeBeforeData: Record<keyof ReminderTypesStatusDto, DeepKeys<LateFeesPolicyDto>> = {
  email: "reminderTypesBeforeCharge.email",
  text: "reminderTypesBeforeCharge.text",
  push: "reminderTypesBeforeCharge.push"
};

export const ReminderTypeAfterData: Record<keyof ReminderTypesStatusDto, DeepKeys<LateFeesPolicyDto>> = {
  email: "reminderTypesAfterCharge.email",
  text: "reminderTypesAfterCharge.text",
  push: "reminderTypesAfterCharge.push"
};

type ReminderTypesTiming = "before" | "after";

function getReminderTypesData(
  timing: ReminderTypesTiming
): Record<keyof ReminderTypesStatusDto, DeepKeys<LateFeesPolicyDto>> {
  const dataPaths: Record<ReminderTypesTiming, Record<keyof ReminderTypesStatusDto, DeepKeys<LateFeesPolicyDto>>> = {
    before: ReminderTypeBeforeData,
    after: ReminderTypeAfterData
  };
  return dataPaths[timing];
}

interface RemindersSelectors {
  email: string;
  push: string;
  text: string;
}

function getDataCySelectors(timing: ReminderTypesTiming): RemindersSelectors {
  const attributes: Record<ReminderTypesTiming, RemindersSelectors> = {
    before: DataCy.globalSettings.lateFees.reminder.beforeCharge,
    after: DataCy.globalSettings.lateFees.reminder.afterCharge
  };
  return attributes[timing];
}

export const ReminderTypeLabels: Record<keyof ReminderTypesStatusDto, EnTranslation> = {
  email: "common.enums.communicationType.EMAIL",
  text: "common.enums.communicationType.TEXT",
  push: "common.enums.communicationType.PUSH"
};

export const remindersKeys = Object.keys(ReminderTypeLabels).map((type) => type as keyof ReminderTypesStatusDto);

interface ReminderTypeCheckboxesProps {
  getFieldPath: (suffix: DeepKeys<LateFeesPolicyDto>) => DeepKeys<LateFeesFormTypes>;
  timing: ReminderTypesTiming;
}

export const ReminderTypeCheckboxes = ({ getFieldPath, timing }: ReminderTypeCheckboxesProps) => {
  const { t } = useTypedTranslation();
  const settingsClasses = useStyles();

  const fieldPaths = useMemo(() => getReminderTypesData(timing), [timing]);

  const attributes = useMemo(() => getDataCySelectors(timing), [timing]);

  return (
    <div className={clsx(settingsClasses.reminderTypeContainer)}>
      {remindersKeys.map((key) => (
        <FastFieldSafe
          key={key}
          component={FormikCheckBox}
          labelText={t(ReminderTypeLabels[key])}
          name={getFieldPath(fieldPaths[key])}
          textColor="black"
          dataCy={attributes[key]}
        />
      ))}
    </div>
  );
};

interface ReminderSectionProps {
  title: EnTranslation;
  subtitle: EnTranslation;
  getFieldPath: (suffix: DeepKeys<LateFeesPolicyDto>) => DeepKeys<LateFeesFormTypes>;
  timing: ReminderTypesTiming;
}

export const ReminderSection = ({ title, subtitle, getFieldPath, timing }: ReminderSectionProps) => {
  const { t } = useTypedTranslation();
  const settingsClasses = useStyles();
  const generalClasses = useGeneralStyles();

  return (
    <div className={clsx(settingsClasses.settingContainer, generalClasses.flexColumn, generalClasses.largeGap)}>
      <TitleWithDescription title={t(title)} subtitle={t(subtitle)} />
      <ReminderTypeCheckboxes timing={timing} getFieldPath={getFieldPath} />
    </div>
  );
};
