import React, { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import type { OwnerDto } from "@doorloop/dto";
import { LeaseTenantStepStatus, LoginResponseType, SegmentEventTypes } from "@doorloop/dto";

import AppStrings from "../../../../locale/keys";
import type { ApiResult } from "api/apiResult";
import type { ActiveCtaProps } from "DLUI/listItems/listItemPortalStatusContent";
import { ListItemPortalStatusContent } from "DLUI/listItems/listItemPortalStatusContent";
import { ownersApi } from "api/ownersApi";
import { useShakeEffect } from "../../../../hooks/useShakeEffect";
import { analyticsService } from "../../../../services/analyticsService";
import { usePortalStatus } from "screens/tenants/tenantsList/usePortalStatus";
import { HotCoffieIcon } from "../../../../assets";

interface ComponentProps {
  owner: OwnerDto;
  onInviteSent: (owner: OwnerDto) => void;
  renderOnlyInvitationLink?: boolean;
  onClickMissingContactInfo?: (e: React.MouseEvent) => void;
  onClickCta?: (id: string, value: boolean) => void;
  actionDisableObj?: Record<string, boolean>;
}

export const OwnerListItemPortalStatus = ({
  owner,
  onInviteSent,
  renderOnlyInvitationLink = false,
  onClickMissingContactInfo,
  onClickCta,
  actionDisableObj
}: ComponentProps) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const { ShakeEffectWrapper, runShakeEffect } = useShakeEffect({ fullWidth: true });
  const { currentStatus, statuses } = usePortalStatus(owner);

  const activeCtaProps: ActiveCtaProps | undefined = useMemo(() => {
    if (currentStatus && [LeaseTenantStepStatus.ACTIVE].includes(currentStatus)) {
      return {
        textColor: "secondary-gray",
        Icon: HotCoffieIcon,
        text: AppStrings.Common.AllSetForNow
      };
    }
  }, [owner]);

  const { portalInfo } = owner;

  const onInviteToPortalClick = async (): Promise<void> => {
    const ownerId = owner.id;

    if (!ownerId) {
      return;
    }

    if ((portalInfo?.loginEmail || portalInfo?.mobilePhone) && !isLoading) {
      setIsLoading(true);

      let result: ApiResult<OwnerDto>;

      if (statuses.isInvited) {
        result = await ownersApi.resendOwnerPortalInvitation({
          id: ownerId
        });
      } else {
        result = await ownersApi.update(ownerId, {
          ...owner,
          portalInfo: {
            ...owner.portalInfo,
            allowAccess: true
          }
        });

        if (result.status && result.data) {
          analyticsService.track(
            SegmentEventTypes.INVITATION_SENT,
            {
              sentToId: ownerId,
              sentToType: LoginResponseType.OWNER
            },
            { trackEventInIntercom: true }
          );
        }
      }

      setIsLoading(false);

      if (result.status && result.data) {
        onInviteSent(result.data);
      } else {
        runShakeEffect();
      }
    }
  };

  return (
    <ShakeEffectWrapper>
      <ListItemPortalStatusContent
        onInviteToPortalClick={onInviteToPortalClick}
        sendingInviteText={t(AppStrings.Owners.Screen.SendingInvite)}
        missingInfoTooltipText={t(AppStrings.Common.OwnerPortalMissingInfoTooltip)}
        portalInfo={portalInfo}
        renderOnlyInvitationLink={renderOnlyInvitationLink}
        isLoading={isLoading}
        activeCtaProps={activeCtaProps}
        onClickMissingContactInfo={onClickMissingContactInfo}
        id={owner.id}
        onClickCta={onClickCta}
        actionDisableObj={actionDisableObj}
        dto={owner}
      />
    </ShakeEffectWrapper>
  );
};
