import { type FC } from "react";

import { View } from "DLUI/view";
import { Icon } from "DLUI/icon";
import { Link } from "DLUI/link";
import Text from "DLUI/text";

import type { ActionItem, VideoItem } from "./types";
import { ActionItemIconMap, HelpTypeEnum } from "./types";
import { DataCy } from "@doorloop/dto";
import { useHelpActionItem } from "@/hooks/useHelpActionItem";

interface Props {
  actionItem: ActionItem;
  noWrapActionItem?: boolean;
  onOpenHelpVideo: (video: VideoItem) => void;
}

export const HelpPanelActionItem: FC<Props> = ({ actionItem, noWrapActionItem, onOpenHelpVideo }: Props) => {
  const { type, topic } = actionItem;
  const iconSource = ActionItemIconMap[type];

  const additionalProps = noWrapActionItem ? { flex: 1, noWrap: true } : {};

  const { clickHandler } = useHelpActionItem(actionItem, onOpenHelpVideo);

  return (
    <View
      marginRight={20}
      marginTop={10}
      flexDirection={"row"}
      alignItems={"center"}
      width={"auto"}
      {...additionalProps}
    >
      <Icon Source={iconSource} />
      <Link
        marginLeft={5}
        type={"blank"}
        onClick={clickHandler}
        underline={"always"}
        dataCy={actionItem.type === HelpTypeEnum.WATCH_VIDEO ? DataCy.watchVideoHelpLink : undefined}
      >
        <Text value={topic} fontSize={14} />
      </Link>
    </View>
  );
};
