import { BankTransferDialog, GeneralEntryDialog } from "DLUI/dialogs/otherTransactions";
import AppStrings from "locale/keys";
import { DialogRoutes } from "./dialogsRoutes";
import type { DialogProps } from "./dialogsProvider.types";
import BankDepositDialog from "DLUI/dialogs/otherTransactions/bankDeposit/bankDeposit";
import { RecurringGeneralEntryDialog } from "DLUI/dialogs/otherTransactions/generalEntry/recurringGeneralEntry";

class OtherTransactionsDialogsProvider {
  matchRoutes(pathname: string): DialogProps | undefined {
    if (
      pathname.indexOf(DialogRoutes.NEW_BANK_TRANSFER) > -1 ||
      pathname.indexOf(DialogRoutes.EDIT_BANK_TRANSFER) > -1
    ) {
      return {
        type: "bankTransfer",
        title: AppStrings.Common.CreateNewMenu.BankTransfer,
        Content: BankTransferDialog
      };
    }

    if (pathname.indexOf(DialogRoutes.EDIT_RECURRING_GENERAL_ENTRY) > -1) {
      return {
        type: "bankTransfer",
        title: AppStrings.OtherTransactions.GeneralEntry.GeneralEntryTitle,
        Content: RecurringGeneralEntryDialog
      };
    }

    if (
      pathname.indexOf(DialogRoutes.NEW_GENERAL_ENTRY) > -1 ||
      pathname.indexOf(DialogRoutes.EDIT_GENERAL_ENTRY) > -1
    ) {
      return {
        type: "bankTransfer",
        title: AppStrings.OtherTransactions.GeneralEntry.GeneralEntryTitle,
        Content: GeneralEntryDialog
      };
    }

    if (pathname.indexOf(DialogRoutes.NEW_BANK_DEPOSIT) > -1) {
      return {
        type: "bankDeposit",
        title: AppStrings.OtherTransactions.BankDeposit.DialogTitle,
        Content: BankDepositDialog
      };
    }

    return undefined;
  }
}

export const otherTransactionsDialogsProvider = new OtherTransactionsDialogsProvider();
