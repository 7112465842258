import type { ToastReducerAction, ToastStateValid } from "./actions";
import { ACTION_TYPE_SET_TOAST, ACTION_TYPE_CLEAR_TOAST } from "./utils";

export type ToastState = ToastStateValid | null;

const toastReducer = (state: ToastState = null, action: ToastReducerAction): ToastState => {
  switch (action.type) {
    case ACTION_TYPE_SET_TOAST: {
      return action.state;
    }
    case ACTION_TYPE_CLEAR_TOAST: {
      return null;
    }
    default: {
      return state;
    }
  }
};

export { toastReducer };
