"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExamQuestionChoice = exports.ExamQuestion = exports.ExamResponseDto = void 0;
class ExamResponseDto {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.ExamResponseDto = ExamResponseDto;
class ExamQuestion {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.ExamQuestion = ExamQuestion;
class ExamQuestionChoice {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.ExamQuestionChoice = ExamQuestionChoice;
