import type { GridColumn } from "DLUI/lists/gridList/gridList";
import {
  getNameFromDictionaryByType,
  translatePaymentMethodField,
  translateReference,
  translateTypeField
} from "DLUI/lists/utils";
import AppStrings from "locale/keys";
import { NavigationManager } from "utils/navigation";
import type { DepositDetailReportItem } from "@doorloop/dto/dist/reports/depositDetailReport";
import type { TranslateFn } from "locale";
import type { IntlFormatters } from "@formatjs/intl/src/types";
import _ from "lodash";
import moment from "moment";

interface DepositDetailGridColumnsParams {
  t: TranslateFn;
  formatDate: IntlFormatters["formatDate"];
}

export const buildDepositDetailGridColumns = ({
  t,
  formatDate
}: DepositDetailGridColumnsParams): Array<GridColumn<DepositDetailReportItem>> => [
  {
    field: "date",
    title: AppStrings.Common.ListHeader.DepositAndPaymentDate,
    formatType: "date",
    show: true,
    footerCell: {
      text: AppStrings.Common.ListHeader.Total
    },
    groupFooterCell: {
      customCell: (dataItems) => [
        AppStrings.Common.ListHeader.Total,
        translateReference(_.first(dataItems)?.["_depositReference"])
      ]
    }
  },
  {
    field: "account",
    title: AppStrings.Leases.LeaseCharge.DepositAccount,
    show: true
  },
  {
    field: "category",
    title: AppStrings.Common.ListHeader.Category,
    show: false
  },
  {
    field: "property",
    title: AppStrings.Common.FilterOptions.Property,
    show: true,
    getRoute: (itemData) => {
      const propertyId = itemData["_property"];
      return propertyId ? NavigationManager.getPropertyDetailsOverviewRoute(propertyId) : "";
    }
  },
  {
    field: "type",
    title: AppStrings.Common.FilterOptions.Type,
    show: true,
    translate: translateTypeField
  },
  {
    field: "receivedFrom",
    title: AppStrings.Common.ListHeader.ReceivedFrom,
    show: true,
    translate: (cellValue, dataItem) => getNameFromDictionaryByType(cellValue, dataItem.receivedFromType)
  },
  {
    field: "paymentMethod",
    title: AppStrings.Common.FilterOptions.PaymentMethod,
    show: true,
    translate: translatePaymentMethodField
  },
  {
    field: "amount",
    title: AppStrings.Leases.NewLease.LeaseRent.Amount,
    show: true,
    formatType: "currency",
    aggregate: "sum",
    groupFooterCell: {
      sum: true
    },
    groupHeader: {
      customCell: () => AppStrings.Common.Settings.LateFees.Account
    },
    footerCell: {
      sum: true
    }
  },
  {
    field: "reference",
    title: AppStrings.Common.ListHeader.Reference,
    show: false,
    translate: translateReference
  },
  {
    field: "lease",
    title: AppStrings.Reports.TransactionsReport.TransactionReportGroupBy.Lease,
    show: false,
    getRoute: (itemData) => {
      const leaseId = itemData["_lease"];
      return leaseId ? NavigationManager.getLeaseDetailsOverviewRoute(leaseId) : "";
    }
  },
  {
    field: "unit",
    title: AppStrings.Reports.TransactionsReport.TransactionReportGroupBy.Unit,
    show: false,
    getRoute: (itemData) => {
      const unitId = itemData["_unit"];
      return unitId ? NavigationManager.getUnitDetailsOverviewRoute(unitId) : "";
    }
  },
  {
    field: "memo",
    title: AppStrings.Common.ListHeader.Description,
    show: false
  },
  {
    field: "depositReference",
    title: AppStrings.Common.ListHeader.Reference,
    show: false,
    translateIgnoreLocaleStrings: true,
    translate: (cellValue, dataItem) =>
      `${t("common.enums.journalEntryType.deposit")} ${translateReference(cellValue)} · ${formatDate(moment(dataItem.depositDate).toDate())}`
  },
  {
    field: "notes",
    title: AppStrings.Common.ListHeader.Memo,
    show: false
  },
  {
    field: "cleared",
    title: AppStrings.Common.Cleared,
    show: false,
    translate: (fieldValue) => (fieldValue ? "C" : "")
  },
  {
    field: "reconciled",
    title: AppStrings.Common.BankReconciliation.Reconciled,
    show: false,
    translate: (fieldValue) => (fieldValue ? "R" : "")
  }
];
