import type { FC } from "react";
import React, { useState } from "react";

import { View } from "DLUI/view";
import { Icon } from "DLUI/icon";
import Text from "DLUI/text";

import type { SVGIconComponent } from "../../../assets";
import ColorsEnum from "../../../utils/colorsEnum";
import { TextTooltip } from "DLUI/popover/textTooltip";

interface GenericActionComponentProps {
  ActionDialog?: FC<{ onClose: () => void }>;
  actionHandler?: () => void;
  IconSource: SVGIconComponent;
  label: string;
  iconTooltipText?: string;
}

const GenericActionComponent: FC<GenericActionComponentProps> = ({
  ActionDialog,
  actionHandler,
  IconSource,
  label,
  iconTooltipText
}) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  return (
    <>
      <View
        flexDirection={"row"}
        noWrap
        onClick={() => (ActionDialog ? setShowDialog(true) : actionHandler && actionHandler())}
        alignItems={"center"}
      >
        <TextTooltip show={Boolean(iconTooltipText)} value={iconTooltipText} useDark maxWidth={300}>
          <Icon Source={IconSource} size={17} marginRight={5} />
        </TextTooltip>
        <Text colorEnum={ColorsEnum.BrightBlue} value={label} underLine />
      </View>
      {ActionDialog && showDialog && <ActionDialog onClose={() => setShowDialog(false)} />}
    </>
  );
};

export { GenericActionComponent };
