import { View } from "DLUI/view";
import React, { useMemo } from "react";
import { SectionTitle } from "DLUI/screen";
import AppStrings from "locale/keys";
import Text from "DLUI/text";
import { useLocation, useParams } from "react-router-dom";
import { CloseCircleIcon } from "assets/icons";
import { IconButton } from "DLUI/form";
import { accountsApi } from "api/accounts";
import { DialogRoutes } from "DLUI/screen/dialogsProvider";

interface ComponentProps {
  onCloseButtonPress: () => void;
}

export const DialogFrameHeaderHeight = 180;

const DialogFrameHeader: React.FC<ComponentProps> = ({ onCloseButtonPress }: ComponentProps) => {
  const { accountId } = useParams<any>();
  const location = useLocation();

  const accountDisplayName = useMemo(() => accountsApi.getItemFromDictionary(accountId)?.name, []);
  const isRepairConnectionMode = location.pathname.includes(DialogRoutes.BANK_ACCOUNT_REPAIR_CONNECTION);

  return (
    <View height={DialogFrameHeaderHeight}>
      <View borderTopLeftRadius={10} borderTopRightRadius={10} backgroundColor={"blue"}>
        <View paddingLeft={20} paddingRight={20}>
          <View
            width={"auto"}
            style={{
              position: "absolute",
              right: "20px",
              top: "20px",
              zIndex: 5000
            }}
          >
            <IconButton
              Icon={CloseCircleIcon}
              width={40}
              height={40}
              onClick={onCloseButtonPress}
              circleFill={"black"}
            />
          </View>
          <SectionTitle
            type={"underline"}
            title={AppStrings.Common.BankConnect.Title}
            marginTop={20}
            textColor={"white"}
            fontSize={20}
          />
          <Text
            value={
              isRepairConnectionMode
                ? AppStrings.Common.BankConnectRepairConnection
                : AppStrings.Common.BankConnectDialogHeaderDescription
            }
            fontSize={16}
            color={"white"}
            marginTop={20}
          />
          <Text marginBottom={20} fontSize={16} bold color={"white"} marginTop={10}>
            {accountDisplayName}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default DialogFrameHeader;
