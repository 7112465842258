import OptionItem, { CreateOptionItem } from "DLUI/form/autoComplete/formikAsyncAutoComplete/optionItem";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { TextField } from "DLUI/form";
import { getReactEventHandlersByElement } from "../../../../../utils/reactEventHandlers";
import { addCreateNewOption } from "DLUI/form/autoComplete/formikAsyncAutoComplete/utils";
import type { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import AppStrings from "../../../../../locale/keys";

export const filter = createFilterOptions<any>();

export const handleRenderOption = (props, queryParams, valueFilter) =>
  props.CreateNewOptionComponent ? (
    <CreateOptionItem
      inputValue={valueFilter || ""}
      option={{
        ...props,
        filterOptions: queryParams
      }}
    />
  ) : (
    <OptionItem {...props} />
  );

export const renderEndAdornmentIcon = (params, isLoading: boolean) => {
  if (isLoading) {
    return <CircularProgress color="inherit" size={20} />;
  }
  return params.InputProps.endAdornment;
};

const onKeyDownPopper = (ev) => {
  const optionId = ev.target.getAttribute("aria-activedescendant");
  const isCreateOption = document.getElementById(optionId)?.children[0];
  if (isCreateOption?.classList.contains("create-option")) {
    if (ev.key === "Enter") {
      ev.stopPropagation();
      ev.nativeEvent.stopPropagation();

      const createOptionReactEvents = getReactEventHandlersByElement(isCreateOption);
      createOptionReactEvents?.onKeyDown && createOptionReactEvents.onKeyDown(ev);

      //TODO: find some how move it to the dynamic component
      setTimeout(() => {
        isCreateOption.querySelector("input")?.focus();
      });
    }
  }
};

export const handleRenderInput = (params: AutocompleteRenderInputParams, props) => {
  const { marginTop, field, form, label, size, isLoading, valueFilter, restProps } = props;
  let paramsProps = params;

  if (valueFilter) {
    paramsProps = { ...params, inputProps: { ...params.inputProps, value: valueFilter ? valueFilter : field.value } };
  }

  return (
    <TextField
      marginTop={marginTop}
      asyncAutoCompleteInstance
      field={field}
      form={form}
      {...paramsProps}
      {...restProps}
      label={label}
      variant="outlined"
      size={size || "100%"}
      EndAdornmentIcon={() => renderEndAdornmentIcon(params, isLoading)}
      InputProps={{
        ...params.InputProps,
        onKeyDownCapture: onKeyDownPopper
      }}
    />
  );
};

export const handleFilterOptions = (options, params, props) => {
  const {
    CreateNewOptionComponent,
    isLoading,
    groupNameProp,
    createNewOptionTitle,
    createError,
    onCreateError,
    queryParams,
    createNewOptionType,
    onCreate,
    multiple,
    setShowCreateOption,
    disableSelectAll,
    t
  } = props;

  const filteredOptions = filter(options, params);
  const optionsWithSelectAll =
    multiple && !disableSelectAll && options?.length
      ? [
          {
            name: t(AppStrings.Common.SelectAll),
            id: "select-all",
            type: options?.[0]?.type
          },
          ...filteredOptions
        ]
      : filteredOptions;

  return CreateNewOptionComponent
    ? addCreateNewOption(optionsWithSelectAll, {
        groupNameProp,
        CreateNewOptionComponent,
        optionTitle: createNewOptionTitle,
        onCreate,
        createError,
        onCreateError,
        setShowCreateOption,
        queryParams: queryParams as Record<string, any>,
        createNewOptionType
      })
    : optionsWithSelectAll;
};

export const findSelectedOptionsIndex = (options, value) => {
  const _value = value?.id || value;
  let isFound = false;
  let loopIndex: number | undefined = undefined;

  if (options?.length) {
    loopIndex = 0;
    for (const currentOption of options) {
      if (_value && currentOption && currentOption?.id === _value) {
        isFound = true;
        break;
      }
      loopIndex += 1;
    }
  }

  return isFound ? loopIndex : undefined;
};

export const findMultiSelectedOptions = (options, values) => {
  if (values?.length >= 1 && options?.length) {
    const [firstItem] = values;
    return options?.filter((opt) => {
      if (typeof firstItem === "string") {
        return values.includes(opt.id);
      }
      return Boolean(values.some((val) => val.id === opt.id));
    });
  }

  return [];
};
