import MomentUtils from "@date-io/moment";
import type { LeaseDto } from "@doorloop/dto";
import { LinkedResourceType, NotRenewingDto, createValidator } from "@doorloop/dto";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { filesApi } from "api/filesApi";
import { leasesNotRenewingApi } from "api/leasesApi";
import { LoadingDialog } from "DLUI/dialogs";
import { DialogState } from "DLUI/dialogs/loadingDialog";
import type { FileListItemProps } from "DLUI/dropZone";
import { FormAttachments } from "DLUI/dropZone";
import { FormikDatePicker, TextField } from "DLUI/form";
import Text from "DLUI/text";
import { View } from "DLUI/view";
import type { FormikProps } from "formik";
import { FastField, useFormikContext } from "formik";
import AppStrings from "locale/keys";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DialogFrame from "../components/dialogFrame";
import { DialogsHelper } from "../dialogsHelper";
import CurrentTermsItem from "./currentTermsItem";
import makeStyles from "./styles";
import { FormActionButtons } from "DLUI/actionButtons/formActionButtons";

interface ComponentProps {
  onClose: (leaseData: LeaseDto) => void;
  onBackdropClick: () => void;
  refreshEvent?: () => void;
  leaseData: LeaseDto;
  propertyName: string;
}

const validateForm = createValidator(NotRenewingDto);

const NotRenewing: React.FC<ComponentProps> = ({
  onBackdropClick,
  onClose,
  leaseData,
  propertyName
}: ComponentProps) => {
  const formikRef = useFormikContext<NotRenewingDto>();
  const classes = makeStyles();
  const { t } = useTranslation();
  const [viewIndex, setViewIndex] = useState(0);
  const [attachments, setAttachments] = useState<FileListItemProps[]>([]);
  const [loadingDialogState, setLoadingDialogState] = useState<DialogState>(DialogState.Hidden);
  const { dialogHorizontalPadding } = DialogsHelper();

  const _onBackdropClick = () => {
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  const isValidForm = async (formikRef: FormikProps<NotRenewingDto>) => {
    formikRef.setFieldTouched("leaseId");
    formikRef.setFieldTouched("notRenewingNoticeDate");

    const errors = (await formikRef.validateForm()) as any;
    return _.isEmpty(errors);
  };

  const postNotRenewing = async (formikRef: FormikProps<NotRenewingDto>) => {
    const formValues = _.cloneDeep(formikRef.values);
    setLoadingDialogState(DialogState.Show);
    setViewIndex(1);
    const response = await leasesNotRenewingApi.create(formValues).catch((error) => {
      setLoadingDialogState(DialogState.Error);
    });
    if (response && response.data) {
      await filesApi.uploadFiles(attachments, formValues.leaseId!, LinkedResourceType.Lease).catch((error: string) => {
        setLoadingDialogState(DialogState.Error);
      });
      setLoadingDialogState(DialogState.Success);
      setTimeout(() => {
        onClose(response.data as LeaseDto);
      }, 300);
    } else {
      setLoadingDialogState(DialogState.Error);
    }
  };

  const didPressNotRenewingButton = () => {
    isValidForm(formikRef).then((isValid) => {
      if (isValid) {
        postNotRenewing(formikRef);
      }
    });
  };

  const renderActionPanelButtons = () => {
    if (viewIndex === 1) {
      return <div />;
    }

    return (
      <FormActionButtons
        propsSubButton={{ onClick: onBackdropClick }}
        propsMainButton={{ type: "cta", props: { onClick: didPressNotRenewingButton } }}
      />
    );
  };

  const onFileReceived = (files: FileListItemProps[]) => {
    setAttachments(files);
  };

  const renderForm = () => (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <View className={"here_stop"} width={"100%"} flex={1}>
        <View paddingLeft={dialogHorizontalPadding} paddingRight={dialogHorizontalPadding}>
          {leaseData ? <CurrentTermsItem leaseData={leaseData} propertyName={propertyName} hideRentSection /> : null}

          <View marginTop={40} alignItems={"center"} justifyContent={"center"}>
            <Text color={"black"} fontSize={18} value={AppStrings.Leases.LeaseRenewals.NoticeDate} />
            <View marginTop={20} maxWidth={280}>
              <FastField
                component={FormikDatePicker}
                uniqueKey={"notRenewingNoticeDate"}
                label={AppStrings.Leases.LeaseRenewals.NonRenewalNotice}
                name={`notRenewingNoticeDate`}
                noMargin
              />
            </View>
            <Text color={"black"} fontSize={18} value={AppStrings.Leases.LeaseRenewals.NoticeNotes} marginTop={40} />
            <View marginTop={20} maxWidth={420}>
              <FastField
                component={TextField}
                className={classes.MultiLineInput}
                label={t(AppStrings.Tenants.NewTenant.Notes)}
                name={`notes`}
                multiline
                rows="4"
                variant="outlined"
              />
            </View>
            <View marginBottom={20} marginTop={20}>
              <FormAttachments onFileReceived={onFileReceived} files={attachments} />
            </View>
          </View>
        </View>
      </View>
    </MuiPickersUtilsProvider>
  );

  const onRetryButtonPress = () => {
    postNotRenewing(formikRef);
  };

  const renderView = ({ index }: any) => {
    if (index === 0) {
      return renderForm();
    }
    if (index === 1) {
      return (
        <View flex={1}>
          <LoadingDialog
            dialogState={loadingDialogState}
            loadingText={t(AppStrings.Common.Loading)}
            errorText={t(AppStrings.Common.NetworkErrorSubTitle)}
            successText={t(AppStrings.Common.OperationCompleted)}
            onRetryButtonPress={onRetryButtonPress}
            didPressDismissButton={onBackdropClick}
          />
        </View>
      );
    }
    return <div />;
  };

  const frameType = useMemo(() => {
    if (viewIndex === 0) {
      if (loadingDialogState !== DialogState.Hidden) {
        return "contentOnly";
      }
      return "sectionTitleFrame";
    }

    return "contentOnly";
  }, [viewIndex, loadingDialogState]);

  return (
    <DialogFrame
      onCloseButtonClick={_onBackdropClick}
      title={AppStrings.Leases.LeaseRenewals.NonRenewalNotice}
      width={800}
      height={720}
      renderView={renderView}
      numViews={2}
      activeView={viewIndex}
      RenderActionPanelButtons={renderActionPanelButtons}
      frameType={frameType}
    />
  );
};

export default NotRenewing;
