import { UserDto } from "@doorloop/dto";
import { AddressDto, DisplayEmailDto, DisplayPhoneDto, EmailType, PhoneType } from "@doorloop/dto";
import type { FormikProps } from "formik";
import { createValidator } from "@doorloop/dto";

const NUM_ADDRESS_VALIDATION_FIELDS = 3;
const NUM_EMERGENCY_CONTACTS = 3;

export const initFormValues = (): UserDto => {
  const userDto = new UserDto();
  // TODO replace with server value
  userDto.active = true;
  const emailItem = new DisplayEmailDto();
  emailItem.type = EmailType.PRIMARY;
  userDto.emails = [emailItem];
  const phone = new DisplayPhoneDto();
  phone.type = PhoneType.MOBILE;
  userDto.phones = [phone];
  userDto.primaryAddress = new AddressDto();
  userDto.alternateAddress = new AddressDto();

  return userDto;
};

export const validateForm = createValidator(UserDto);

const validateArrayAndClean = (errors: any, formikValues: any) => {
  // since each row contains shouldDisplay prop which hides the row on delete
  // we need to clear and ignore those elements
  const errorsArray: any[] = [];
  const submissionArray: any[] = [];
  // iterate through the formik values
  for (const prop in formikValues) {
    // make sure the current key exsists on the parent object
    if (Object.prototype.hasOwnProperty.call(formikValues, prop)) {
      if (errors && errors[prop]) {
        // the key exists in the errors array and the row is displayed
        // we should count this item as error
        errorsArray.push(formikValues[prop]);
      } else {
        // the row dosen't exists in the errors array
        // we should count this item as submission item
        submissionArray.push(formikValues[prop]);
      }
    }
  }
  // return the results object
  return {
    errorsArray,
    submissionArray
  };
};

const isValidateContactInfo = (errors: any) => {
  // check fro valid first and last name
  if (errors.firstName || errors.lastName) {
    return false;
  }
  return true;
};

const isValidPhones = (errors: any, formikRef: FormikProps<any>) => {
  // validate and clean the phones array
  const validationResults = validateArrayAndClean(errors.phones, formikRef.values.phones);
  if (validationResults.errorsArray.length > 0) {
    return false;
  }
  // validation pass, set the phones array to the submissionArray
  formikRef.values.phones = validationResults.submissionArray;
  return true;
};

const isValidEmails = (errors: any, formikRef: FormikProps<any>) => {
  // validate and clean the emalis array
  const validationResults = validateArrayAndClean(errors.emails, formikRef.values.emails);
  if (validationResults.errorsArray.length > 0) {
    // error was found, scroll to the emails section
    return false;
  }
  // validation pass, set the emails array to the submissionArray
  formikRef.values.emails = validationResults.submissionArray;
  return true;
};

const isValidEmergencyContacts = (errors: any, formikRef: FormikProps<any>) => {
  // get the emergency contacts errors
  const emergencyContactsErrors = errors.emergencyContacts;
  // validate and clean the contacts array
  const validationResults = validateArrayAndClean(emergencyContactsErrors, formikRef.values.emergencyContacts);

  if (validationResults.errorsArray.length > 0) {
    // get the number of errors from the first field
    const firstFieldsNumOfErrors =
      errors.emergencyContacts && errors.emergencyContacts[0] ? Object.keys(errors.emergencyContacts[0]).length : 0;
    if (validationResults.errorsArray.length === 1 && firstFieldsNumOfErrors === NUM_EMERGENCY_CONTACTS) {
      // validation pass if we have an empty field or no errors were found
      formikRef.values.emergencyContacts = [];
      return true;
    }
    // error was found, scroll to the emergency contacts section

    return false;
  }
  formikRef.values.emergencyContacts = validationResults.submissionArray;
  return true;
};

const isValidAddress = (
  errors: any,
  allowEmptyAddress: boolean,
  formikRef: FormikProps<any>,
  primaryAddress?: boolean
): boolean => {
  if (!errors) {
    // no errors found
    const currentAddressValues = primaryAddress ? formikRef.values.primaryAddress : formikRef.values.alternateAddress;
    if (
      currentAddressValues &&
      currentAddressValues.street1 === "" &&
      currentAddressValues.city === "" &&
      currentAddressValues.country === ""
    ) {
      if (primaryAddress) {
        formikRef.values.primaryAddress = undefined;
      } else {
        formikRef.values.alternateAddress = undefined;
      }
    }
    return true;
  }
  // get the number of fields which has error
  const numOfErrors = Object.keys(errors).length;
  // check if we allow empty address on the field
  // verify that all the fields are with error

  if (allowEmptyAddress && numOfErrors === NUM_ADDRESS_VALIDATION_FIELDS) {
    if (formikRef) {
      // since we allow empty address igone the errors and empty the field
      formikRef.values.alternateAddress = undefined;
    }
    return true;
  }

  return false;
};

export const validateUserForm = async (
  formikRef: FormikProps<any>
): Promise<{ isValid: boolean; errorStepIndex?: number }> => {
  // first set the  for submition
  formikRef.submitForm();
  // validate the form
  formikRef.setFieldTouched("loginEmail");
  formikRef.setFieldTouched("role");
  const errors = (await formikRef.validateForm()) as any;
  if (errors.role || errors.loginEmail) {
    return { isValid: false, errorStepIndex: 0 };
  }
  // validate the contact info section
  const validContactInfo = isValidateContactInfo(errors);
  if (!validContactInfo) {
    // invalid contact info return and stop the validation
    formikRef.setSubmitting(false);
    return { isValid: false, errorStepIndex: 0 };
  }
  // validate the phones section
  const validPhones = isValidPhones(errors, formikRef);
  if (!validPhones) {
    // invalid phones data return and stop the validation
    formikRef.setSubmitting(false);
    return { isValid: false, errorStepIndex: 1 };
  }
  // validate the emails section
  const validEmails = isValidEmails(errors, formikRef);
  if (!validEmails) {
    // invalid emails data return and stop the validation
    formikRef.setSubmitting(false);
    return { isValid: false, errorStepIndex: 1 };
  }
  // validate the primary address section
  const validAddress = isValidAddress(errors.primaryAddress, true, formikRef, true);
  if (!validAddress) {
    // invalid address data return and stop the validation
    formikRef.setSubmitting(false);
    return { isValid: false, errorStepIndex: 2 };
  }
  // validate the alternative address section
  const validAlternateAddress = isValidAddress(errors.alternateAddress, true, formikRef, false);
  if (!validAlternateAddress) {
    // invalid address data return and stop the validation
    formikRef.setSubmitting(false);
    return { isValid: false, errorStepIndex: 3 };
  }
  // validate the emergency contact section
  const validEmergencyContacts = isValidEmergencyContacts(errors, formikRef);
  if (!validEmergencyContacts) {
    // invalid emergency data
    formikRef.setSubmitting(false);
    return { isValid: false, errorStepIndex: 4 };
  }

  return { isValid: true };
};
