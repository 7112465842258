export default {
  screen: {
    addNewUser: "New User",
    searchPlaceHolder: "Search users",
    emptydataAddNew: "Add your first user",
    emptydataViewInstructionsText: "No Users Found",
    users: "Users",
    accountOwner: "Account Owner",
    accountOwnerDescription:
      "This user is the owner of this DoorLoop account. It will always have full access and cannot be deactivated.",
    user: "User",
    contactInformation: "Contact Information",
    role: "Role"
  },
  newUser: {
    loadingText: "Creating user...",
    errorText: "Something went wrong. Please try again later or contact us.",
    successText: "user created successfully",
    loginEmailAddress: "Login Email Address",
    loginEmailAddressDescription: "This is the email address they will use to login to DoorLoop.",
    userRole: "User Role",
    userRoleDescription: "What parts of DoorLoop they can access.",
    editUser: "Edit User",
    activeUser: "Active User",
    inactiveUser: "Inactive User",
    inviteSent: "Invite Sent",
    inviteFailed: "Invite Failed",
    sendingInvite: "Sending invite",
    inviteSentDescriptionPart1: "We emailed this user to join your DoorLoop account on",
    inviteSentDescriptionPart2:
      ". They have not accepted the invitation yet. Click here to send them a new invitation.",
    inviteSentDescriptionPart3: "to send them a new invitation.",
    newUserDescriptionActive:
      "Once you click “Save”, they will get an email to join your account and create their password.",
    newUserDescriptionInactive:
      "This user cannot access your DoorLoop account. To invite them, please make sure the Active User button is green.",
    resetLogin: "Change Email",
    accessAllProperties: "User has access to all of your properties",
    accessSomeProperties: "User will have access only to some properties",
    propertiesSelectionDescription: "Properties the user will gain access to:",
    groupSelectionDescription: "Portfolios the user will gain access to:",
    propertyGroups: "Portfolios",
    accessAllBankAccounts: "User has access to all of your bank and credit card accounts",
    accessSomeBankAccounts: "User will have access only to some bank and credit card accounts",
    bankAccountsSelectionDescription: "Accounts the user will gain access to:",
    setUserRoles: "Set User Roles"
  },
  userStatus: {
    active: "Active",
    suspended: "Suspended",
    invited: "Invited"
  }
};
